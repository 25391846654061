import React from 'react';
import {
	Col,
	Container,
	Row,
} from 'react-bootstrap';

export default function BorderLayout(props) {
	const {
		top,
		topStyle,
		left,
		leftStyle,
		center,
		centerStyle,
		right,
		rightStyle,
		bottom,
		bottomStyle,
		...otherProps
	} = props;

	return (
		<>
			<Container fluid className="px-0" {...otherProps}>
				{
					top && (
						<Row className="mx-0" style={topStyle}>
							<Col className="px-0">
								{top}
							</Col>
						</Row>
					)
				}
				{
					(left || center || right) && (
						<Row className="mx-0 border-bottom border-primary">
							{
								left && (
									<Col sm={4} className="px-0 border-end border-primary" style={{ backgroundColor: 'rgba(29, 32, 72, 0.06)' }}>
										{left}
									</Col>
								)
							}
							{
								center && (
									<Col sm={5 + (!left && 4) + (!right && 3)} className="px-0 order-2 order-sm-1">
										{center}
									</Col>
								)
							}
							{
								right && (
									<Col sm={3} className="px-0 border-start border-primary order-1 order-sm-2" style={{ backgroundColor: 'rgba(29, 32, 72, 0.06)' }}>
										{right}
									</Col>
								)
							}
						</Row>
					)
				}
				{
					bottom && (
						<Row className="mx-0">
							<Col className="px-0">
								{bottom}
							</Col>
						</Row>
					)
				}
			</Container>
		</>
	);
}