const datas = JSON.parse(`[
[7,4,3,0],
[7,4,3,1],
[7,4,3,2],
[7,5,4,0],
[7,5,4,1],
[7,5,4,2],
[7,6,4,0],
[7,6,4,1],
[7,6,4,2],
[8,7,4,0],
[8,7,4,1],
[8,7,4,2],
[9,4,3,0],
[9,4,3,1],
[9,4,3,2],
[9,5,4,0],
[9,5,4,1],
[9,5,4,2],
[9,6,4,0],
[9,6,4,1],
[9,6,4,2],
[9,7,3,0],
[9,7,3,1],
[9,7,3,2],
[9,7,4,0],
[9,7,4,1],
[9,7,4,2],
[9,7,4,3],
[9,7,5,0],
[9,7,5,1],
[9,7,5,2],
[9,7,5,4],
[9,7,6,0],
[9,7,6,1],
[9,7,6,2],
[9,7,6,4],
[9,8,4,0],
[9,8,4,1],
[9,8,4,2],
[9,8,7,0],
[9,8,7,1],
[9,8,7,2],
[9,8,7,4],
[10,4,3,0],
[10,4,3,1],
[10,4,3,2],
[10,5,4,0],
[10,5,4,1],
[10,5,4,2],
[10,6,4,0],
[10,6,4,1],
[10,6,4,2],
[10,7,3,0],
[10,7,3,1],
[10,7,3,2],
[10,7,4,0],
[10,7,4,1],
[10,7,4,2],
[10,7,4,3],
[10,7,5,0],
[10,7,5,1],
[10,7,5,2],
[10,7,5,4],
[10,7,6,0],
[10,7,6,1],
[10,7,6,2],
[10,7,6,4],
[10,8,4,0],
[10,8,4,1],
[10,8,4,2],
[10,8,7,0],
[10,8,7,1],
[10,8,7,2],
[10,8,7,4],
[10,9,3,0],
[10,9,3,1],
[10,9,3,2],
[10,9,4,0],
[10,9,4,1],
[10,9,4,2],
[10,9,4,3],
[10,9,5,0],
[10,9,5,1],
[10,9,5,2],
[10,9,5,4],
[10,9,6,0],
[10,9,6,1],
[10,9,6,2],
[10,9,6,4],
[10,9,7,0],
[10,9,7,1],
[10,9,7,2],
[10,9,7,3],
[10,9,7,4],
[10,9,7,5],
[10,9,7,6],
[10,9,8,0],
[10,9,8,1],
[10,9,8,2],
[10,9,8,4],
[11,4,3,0],
[11,4,3,1],
[11,4,3,2],
[11,5,4,0],
[11,5,4,1],
[11,5,4,2],
[11,6,4,0],
[11,6,4,1],
[11,6,4,2],
[11,7,3,0],
[11,7,3,1],
[11,7,3,2],
[11,7,4,0],
[11,7,4,1],
[11,7,4,2],
[11,7,4,3],
[11,7,5,0],
[11,7,5,1],
[11,7,5,2],
[11,7,5,4],
[11,7,6,0],
[11,7,6,1],
[11,7,6,2],
[11,7,6,4],
[11,8,4,0],
[11,8,4,1],
[11,8,4,2],
[11,8,7,0],
[11,8,7,1],
[11,8,7,2],
[11,8,7,4],
[11,9,3,0],
[11,9,3,1],
[11,9,3,2],
[11,9,4,0],
[11,9,4,1],
[11,9,4,2],
[11,9,4,3],
[11,9,5,0],
[11,9,5,1],
[11,9,5,2],
[11,9,5,4],
[11,9,6,0],
[11,9,6,1],
[11,9,6,2],
[11,9,6,4],
[11,9,7,0],
[11,9,7,1],
[11,9,7,2],
[11,9,7,3],
[11,9,7,4],
[11,9,7,5],
[11,9,7,6],
[11,9,8,0],
[11,9,8,1],
[11,9,8,2],
[11,9,8,4],
[11,10,3,0],
[11,10,3,1],
[11,10,3,2],
[11,10,4,0],
[11,10,4,1],
[11,10,4,2],
[11,10,4,3],
[11,10,5,0],
[11,10,5,1],
[11,10,5,2],
[11,10,5,4],
[11,10,6,0],
[11,10,6,1],
[11,10,6,2],
[11,10,6,4],
[11,10,7,0],
[11,10,7,1],
[11,10,7,2],
[11,10,7,3],
[11,10,7,4],
[11,10,7,5],
[11,10,8,0],
[11,10,8,1],
[11,10,8,2],
[11,10,8,4],
[11,10,8,7],
[11,10,9,0],
[11,10,9,1],
[11,10,9,2],
[11,10,9,3],
[11,10,9,4],
[11,10,9,6],
[11,10,9,7],
[11,10,9,8],
[12,4,3,0],
[12,4,3,1],
[12,4,3,2],
[12,5,4,0],
[12,5,4,1],
[12,5,4,2],
[12,6,4,0],
[12,6,4,1],
[12,6,4,2],
[12,7,3,0],
[12,7,3,1],
[12,7,3,2],
[12,7,4,0],
[12,7,4,1],
[12,7,4,2],
[12,7,4,3],
[12,7,5,0],
[12,7,5,1],
[12,7,5,2],
[12,7,5,4],
[12,7,6,0],
[12,7,6,1],
[12,7,6,2],
[12,7,6,4],
[12,8,4,0],
[12,8,4,1],
[12,8,4,2],
[12,8,7,0],
[12,8,7,1],
[12,8,7,2],
[12,8,7,4],
[12,9,3,0],
[12,9,3,1],
[12,9,3,2],
[12,9,4,0],
[12,9,4,1],
[12,9,4,2],
[12,9,4,3],
[12,9,5,0],
[12,9,5,1],
[12,9,5,2],
[12,9,5,4],
[12,9,6,0],
[12,9,6,1],
[12,9,6,2],
[12,9,6,4],
[12,9,7,0],
[12,9,7,1],
[12,9,7,2],
[12,9,7,3],
[12,9,7,4],
[12,9,7,5],
[12,9,8,0],
[12,9,8,1],
[12,9,8,2],
[12,9,8,4],
[12,9,8,7],
[12,10,3,0],
[12,10,3,1],
[12,10,3,2],
[12,10,4,0],
[12,10,4,1],
[12,10,4,2],
[12,10,4,3],
[12,10,5,0],
[12,10,5,1],
[12,10,5,2],
[12,10,5,4],
[12,10,6,0],
[12,10,6,1],
[12,10,6,2],
[12,10,6,4],
[12,10,7,0],
[12,10,7,1],
[12,10,7,2],
[12,10,7,3],
[12,10,7,4],
[12,10,7,6],
[12,10,8,0],
[12,10,8,1],
[12,10,8,2],
[12,10,8,7],
[12,10,9,0],
[12,10,9,1],
[12,10,9,2],
[12,10,9,5],
[12,10,9,6],
[12,10,9,7],
[12,10,9,8],
[13,4,3,0],
[13,4,3,1],
[13,4,3,2],
[13,5,4,0],
[13,5,4,1],
[13,5,4,2],
[13,6,4,0],
[13,6,4,1],
[13,6,4,2],
[13,7,3,0],
[13,7,3,1],
[13,7,3,2],
[13,7,4,0],
[13,7,4,1],
[13,7,4,2],
[13,7,4,3],
[13,7,5,0],
[13,7,5,1],
[13,7,5,2],
[13,7,5,4],
[13,7,6,0],
[13,7,6,1],
[13,7,6,2],
[13,7,6,4],
[13,8,4,0],
[13,8,4,1],
[13,8,4,2],
[13,8,7,0],
[13,8,7,1],
[13,8,7,2],
[13,8,7,4],
[13,9,3,0],
[13,9,3,1],
[13,9,3,2],
[13,9,4,0],
[13,9,4,1],
[13,9,4,2],
[13,9,4,3],
[13,9,5,0],
[13,9,5,1],
[13,9,5,2],
[13,9,5,4],
[13,9,6,0],
[13,9,6,1],
[13,9,6,2],
[13,9,6,4],
[13,9,7,0],
[13,9,7,1],
[13,9,7,2],
[13,9,7,3],
[13,9,7,4],
[13,9,7,5],
[13,9,8,0],
[13,9,8,1],
[13,9,8,2],
[13,9,8,4],
[13,9,8,7],
[13,10,3,0],
[13,10,3,1],
[13,10,3,2],
[13,10,4,0],
[13,10,4,1],
[13,10,4,2],
[13,10,4,3],
[13,10,5,0],
[13,10,5,1],
[13,10,5,2],
[13,10,5,4],
[13,10,6,0],
[13,10,6,1],
[13,10,6,2],
[13,10,6,4],
[13,10,7,0],
[13,10,7,1],
[13,10,7,2],
[13,10,7,3],
[13,10,7,4],
[13,10,7,6],
[13,10,8,0],
[13,10,8,1],
[13,10,8,2],
[13,10,8,7],
[13,10,9,0],
[13,10,9,1],
[13,10,9,2],
[13,10,9,5],
[13,10,9,6],
[13,10,9,7],
[13,10,9,8],
[13,11,3,0],
[13,11,3,1],
[13,11,3,2],
[13,11,4,0],
[13,11,4,1],
[13,11,4,2],
[13,11,4,3],
[13,11,5,0],
[13,11,5,1],
[13,11,5,2],
[13,11,5,4],
[13,11,6,0],
[13,11,6,1],
[13,11,6,2],
[13,11,6,4],
[13,11,7,0],
[13,11,7,1],
[13,11,7,2],
[13,11,7,3],
[13,11,7,4],
[13,11,7,6],
[13,11,8,0],
[13,11,8,1],
[13,11,8,2],
[13,11,8,7],
[13,11,9,0],
[13,11,9,1],
[13,11,9,2],
[13,11,9,5],
[13,11,9,6],
[13,11,9,7],
[13,11,9,8],
[13,11,10,3],
[13,11,10,4],
[13,11,10,5],
[13,11,10,6],
[13,11,10,7],
[13,11,10,8],
[13,11,10,9],
[13,12,3,0],
[13,12,3,1],
[13,12,3,2],
[13,12,4,0],
[13,12,4,1],
[13,12,4,2],
[13,12,4,3],
[13,12,5,0],
[13,12,5,1],
[13,12,5,2],
[13,12,5,4],
[13,12,6,0],
[13,12,6,1],
[13,12,6,2],
[13,12,7,0],
[13,12,7,1],
[13,12,7,2],
[13,12,7,5],
[13,12,7,6],
[13,12,8,4],
[13,12,8,7],
[13,12,9,3],
[13,12,9,4],
[13,12,9,5],
[13,12,9,6],
[13,12,9,7],
[13,12,9,8],
[13,12,10,0],
[13,12,10,1],
[13,12,10,2],
[13,12,10,3],
[13,12,10,4],
[13,12,10,5],
[13,12,10,6],
[13,12,10,7],
[13,12,10,8],
[13,12,10,9],
[14,4,3,0],
[14,4,3,1],
[14,4,3,2],
[14,5,4,0],
[14,5,4,1],
[14,5,4,2],
[14,6,4,0],
[14,6,4,1],
[14,6,4,2],
[14,7,3,0],
[14,7,3,1],
[14,7,3,2],
[14,7,4,0],
[14,7,4,1],
[14,7,4,2],
[14,7,4,3],
[14,7,5,0],
[14,7,5,1],
[14,7,5,2],
[14,7,5,4],
[14,7,6,0],
[14,7,6,1],
[14,7,6,2],
[14,7,6,4],
[14,8,4,0],
[14,8,4,1],
[14,8,4,2],
[14,8,7,0],
[14,8,7,1],
[14,8,7,2],
[14,8,7,4],
[14,9,3,0],
[14,9,3,1],
[14,9,3,2],
[14,9,4,0],
[14,9,4,1],
[14,9,4,2],
[14,9,4,3],
[14,9,5,0],
[14,9,5,1],
[14,9,5,2],
[14,9,5,4],
[14,9,6,0],
[14,9,6,1],
[14,9,6,2],
[14,9,6,4],
[14,9,7,0],
[14,9,7,1],
[14,9,7,2],
[14,9,7,3],
[14,9,7,4],
[14,9,7,6],
[14,9,8,0],
[14,9,8,1],
[14,9,8,2],
[14,9,8,7],
[14,10,3,0],
[14,10,3,1],
[14,10,3,2],
[14,10,4,0],
[14,10,4,1],
[14,10,4,2],
[14,10,4,3],
[14,10,5,0],
[14,10,5,1],
[14,10,5,2],
[14,10,5,4],
[14,10,6,0],
[14,10,6,1],
[14,10,6,2],
[14,10,7,0],
[14,10,7,1],
[14,10,7,2],
[14,10,7,5],
[14,10,7,6],
[14,10,8,4],
[14,10,8,7],
[14,10,9,3],
[14,10,9,4],
[14,10,9,5],
[14,10,9,6],
[14,10,9,7],
[14,10,9,8],
[14,11,3,0],
[14,11,3,1],
[14,11,3,2],
[14,11,4,0],
[14,11,4,1],
[14,11,4,2],
[14,11,4,3],
[14,11,5,0],
[14,11,5,1],
[14,11,5,2],
[14,11,5,4],
[14,11,6,0],
[14,11,6,1],
[14,11,6,2],
[14,11,7,0],
[14,11,7,1],
[14,11,7,2],
[14,11,7,5],
[14,11,7,6],
[14,11,8,4],
[14,11,8,7],
[14,11,9,3],
[14,11,9,4],
[14,11,9,5],
[14,11,9,6],
[14,11,9,7],
[14,11,9,8],
[14,11,10,0],
[14,11,10,1],
[14,11,10,2],
[14,11,10,3],
[14,11,10,4],
[14,11,10,5],
[14,11,10,6],
[14,11,10,7],
[14,11,10,8],
[14,11,10,9],
[14,12,3,0],
[14,12,3,1],
[14,12,3,2],
[14,12,4,0],
[14,12,4,1],
[14,12,4,2],
[14,12,4,3],
[14,12,5,0],
[14,12,5,1],
[14,12,5,2],
[14,12,6,4],
[14,12,7,3],
[14,12,7,4],
[14,12,7,5],
[14,12,7,6],
[14,12,8,0],
[14,12,8,1],
[14,12,8,2],
[14,12,8,4],
[14,12,8,7],
[14,12,9,0],
[14,12,9,1],
[14,12,9,2],
[14,12,9,3],
[14,12,9,4],
[14,12,9,5],
[14,12,9,6],
[14,12,9,7],
[14,12,9,8],
[14,12,10,0],
[14,12,10,1],
[14,12,10,2],
[14,12,10,3],
[14,12,10,4],
[14,12,10,5],
[14,12,10,6],
[14,12,10,7],
[14,12,10,8],
[14,12,10,9],
[15,4,3,0],
[15,4,3,1],
[15,4,3,2],
[15,5,4,0],
[15,5,4,1],
[15,5,4,2],
[15,6,4,0],
[15,6,4,1],
[15,6,4,2],
[15,7,3,0],
[15,7,3,1],
[15,7,3,2],
[15,7,4,0],
[15,7,4,1],
[15,7,4,2],
[15,7,4,3],
[15,7,5,0],
[15,7,5,1],
[15,7,5,2],
[15,7,5,4],
[15,7,6,0],
[15,7,6,1],
[15,7,6,2],
[15,7,6,4],
[15,8,4,0],
[15,8,4,1],
[15,8,4,2],
[15,8,7,0],
[15,8,7,1],
[15,8,7,2],
[15,10,3,0],
[15,10,3,1],
[15,10,3,2],
[15,10,4,0],
[15,10,4,1],
[15,10,4,2],
[15,10,4,3],
[15,10,5,0],
[15,10,5,1],
[15,10,5,2],
[15,10,6,4],
[15,10,7,3],
[15,10,7,4],
[15,10,7,5],
[15,10,7,6],
[15,10,8,0],
[15,10,8,1],
[15,10,8,2],
[15,10,8,4],
[15,10,8,7],
[15,11,3,0],
[15,11,3,1],
[15,11,3,2],
[15,11,4,0],
[15,11,4,1],
[15,11,4,2],
[15,11,4,3],
[15,11,5,0],
[15,11,5,1],
[15,11,5,2],
[15,11,6,4],
[15,11,7,3],
[15,11,7,4],
[15,11,7,5],
[15,11,7,6],
[15,11,8,0],
[15,11,8,1],
[15,11,8,2],
[15,11,8,4],
[15,11,8,7],
[15,11,10,0],
[15,11,10,1],
[15,11,10,2],
[15,11,10,3],
[15,11,10,4],
[15,11,10,5],
[15,11,10,6],
[15,11,10,7],
[15,11,10,8],
[15,12,3,0],
[15,12,3,1],
[15,12,3,2],
[15,12,4,0],
[15,12,4,1],
[15,12,4,2],
[15,12,5,4],
[15,12,6,0],
[15,12,6,1],
[15,12,6,2],
[15,12,6,4],
[15,12,7,0],
[15,12,7,1],
[15,12,7,2],
[15,12,7,3],
[15,12,7,4],
[15,12,7,5],
[15,12,7,6],
[15,12,8,0],
[15,12,8,1],
[15,12,8,2],
[15,12,8,4],
[15,12,8,7],
[15,12,10,0],
[15,12,10,1],
[15,12,10,2],
[15,12,10,3],
[15,12,10,4],
[15,12,10,5],
[15,12,10,6],
[15,12,10,7],
[15,12,10,8],
[15,13,3,0],
[15,13,3,1],
[15,13,3,2],
[15,13,4,0],
[15,13,4,1],
[15,13,4,2],
[15,13,5,4],
[15,13,6,0],
[15,13,6,1],
[15,13,6,2],
[15,13,6,4],
[15,13,7,0],
[15,13,7,1],
[15,13,7,2],
[15,13,7,3],
[15,13,7,4],
[15,13,7,5],
[15,13,7,6],
[15,13,8,0],
[15,13,8,1],
[15,13,8,2],
[15,13,8,4],
[15,13,8,7],
[15,13,10,0],
[15,13,10,1],
[15,13,10,2],
[15,13,10,3],
[15,13,10,4],
[15,13,10,5],
[15,13,10,6],
[15,13,10,7],
[15,13,10,8],
[15,13,11,0],
[15,13,11,1],
[15,13,11,2],
[15,13,11,3],
[15,13,11,4],
[15,13,11,5],
[15,13,11,6],
[15,13,11,7],
[15,13,11,8],
[15,13,11,10],
[15,13,12,0],
[15,13,12,1],
[15,13,12,2],
[15,13,12,3],
[15,13,12,4],
[15,13,12,5],
[15,13,12,6],
[15,13,12,7],
[15,13,12,8],
[15,13,12,10],
[15,14,4,3],
[15,14,5,0],
[15,14,5,1],
[15,14,5,2],
[15,14,5,4],
[15,14,6,0],
[15,14,6,1],
[15,14,6,2],
[15,14,6,4],
[15,14,7,0],
[15,14,7,1],
[15,14,7,2],
[15,14,7,3],
[15,14,7,4],
[15,14,7,5],
[15,14,7,6],
[15,14,8,0],
[15,14,8,1],
[15,14,8,2],
[15,14,8,4],
[15,14,8,7],
[15,14,10,0],
[15,14,10,1],
[15,14,10,2],
[15,14,10,3],
[15,14,10,4],
[15,14,10,5],
[15,14,10,6],
[15,14,10,7],
[15,14,10,8],
[15,14,11,0],
[15,14,11,1],
[15,14,11,2],
[15,14,11,3],
[15,14,11,4],
[15,14,11,5],
[15,14,11,6],
[15,14,11,7],
[15,14,11,8],
[15,14,11,10],
[15,14,12,0],
[15,14,12,1],
[15,14,12,2],
[15,14,12,3],
[15,14,12,4],
[15,14,12,5],
[15,14,12,6],
[15,14,12,7],
[15,14,12,8],
[15,14,12,10],
[16,4,3,0],
[16,4,3,1],
[16,4,3,2],
[16,5,4,0],
[16,5,4,1],
[16,5,4,2],
[16,6,4,0],
[16,6,4,1],
[16,6,4,2],
[16,7,3,0],
[16,7,3,1],
[16,7,3,2],
[16,7,4,0],
[16,7,4,1],
[16,7,4,2],
[16,7,4,3],
[16,7,5,0],
[16,7,5,1],
[16,7,5,2],
[16,7,5,4],
[16,7,6,0],
[16,7,6,1],
[16,7,6,2],
[16,8,4,0],
[16,8,4,1],
[16,8,4,2],
[16,8,7,4],
[16,9,3,0],
[16,9,3,1],
[16,9,3,2],
[16,9,4,0],
[16,9,4,1],
[16,9,4,2],
[16,9,4,3],
[16,9,5,0],
[16,9,5,1],
[16,9,5,2],
[16,9,6,4],
[16,9,7,3],
[16,9,7,4],
[16,9,7,5],
[16,9,7,6],
[16,9,8,0],
[16,9,8,1],
[16,9,8,2],
[16,9,8,4],
[16,9,8,7],
[16,10,3,0],
[16,10,3,1],
[16,10,3,2],
[16,10,4,0],
[16,10,4,1],
[16,10,4,2],
[16,10,5,4],
[16,10,6,0],
[16,10,6,1],
[16,10,6,2],
[16,10,6,4],
[16,10,7,0],
[16,10,7,1],
[16,10,7,2],
[16,10,7,3],
[16,10,7,4],
[16,10,7,5],
[16,10,7,6],
[16,10,8,0],
[16,10,8,1],
[16,10,8,2],
[16,10,8,4],
[16,10,8,7],
[16,10,9,0],
[16,10,9,1],
[16,10,9,2],
[16,10,9,3],
[16,10,9,4],
[16,10,9,5],
[16,10,9,6],
[16,10,9,7],
[16,10,9,8],
[16,11,3,0],
[16,11,3,1],
[16,11,3,2],
[16,11,4,0],
[16,11,4,1],
[16,11,4,2],
[16,11,5,4],
[16,11,6,0],
[16,11,6,1],
[16,11,6,2],
[16,11,6,4],
[16,11,7,0],
[16,11,7,1],
[16,11,7,2],
[16,11,7,3],
[16,11,7,4],
[16,11,7,5],
[16,11,7,6],
[16,11,8,0],
[16,11,8,1],
[16,11,8,2],
[16,11,8,4],
[16,11,8,7],
[16,11,9,0],
[16,11,9,1],
[16,11,9,2],
[16,11,9,3],
[16,11,9,4],
[16,11,9,5],
[16,11,9,6],
[16,11,9,7],
[16,11,9,8],
[16,11,10,0],
[16,11,10,1],
[16,11,10,2],
[16,11,10,3],
[16,11,10,4],
[16,11,10,5],
[16,11,10,6],
[16,11,10,7],
[16,11,10,8],
[16,11,10,9],
[16,12,4,3],
[16,12,5,0],
[16,12,5,1],
[16,12,5,2],
[16,12,5,4],
[16,12,6,0],
[16,12,6,1],
[16,12,6,2],
[16,12,6,4],
[16,12,7,0],
[16,12,7,1],
[16,12,7,2],
[16,12,7,3],
[16,12,7,4],
[16,12,7,5],
[16,12,7,6],
[16,12,8,0],
[16,12,8,1],
[16,12,8,2],
[16,12,8,4],
[16,12,8,7],
[16,12,9,0],
[16,12,9,1],
[16,12,9,2],
[16,12,9,3],
[16,12,9,4],
[16,12,9,5],
[16,12,9,6],
[16,12,9,7],
[16,12,9,8],
[16,12,10,0],
[16,12,10,1],
[16,12,10,2],
[16,12,10,3],
[16,12,10,4],
[16,12,10,5],
[16,12,10,6],
[16,12,10,7],
[16,12,10,8],
[16,12,10,9],
[16,13,4,3],
[16,13,5,0],
[16,13,5,1],
[16,13,5,2],
[16,13,5,4],
[16,13,6,0],
[16,13,6,1],
[16,13,6,2],
[16,13,6,4],
[16,13,7,0],
[16,13,7,1],
[16,13,7,2],
[16,13,7,3],
[16,13,7,4],
[16,13,7,5],
[16,13,7,6],
[16,13,8,0],
[16,13,8,1],
[16,13,8,2],
[16,13,8,4],
[16,13,8,7],
[16,13,9,0],
[16,13,9,1],
[16,13,9,2],
[16,13,9,3],
[16,13,9,4],
[16,13,9,5],
[16,13,9,6],
[16,13,9,7],
[16,13,9,8],
[16,13,10,0],
[16,13,10,1],
[16,13,10,2],
[16,13,10,3],
[16,13,10,4],
[16,13,10,5],
[16,13,10,6],
[16,13,10,7],
[16,13,10,8],
[16,13,10,9],
[16,13,11,0],
[16,13,11,1],
[16,13,11,2],
[16,13,11,3],
[16,13,11,4],
[16,13,11,5],
[16,13,11,6],
[16,13,11,7],
[16,13,11,8],
[16,13,11,9],
[16,13,11,10],
[16,13,12,0],
[16,13,12,1],
[16,13,12,2],
[16,13,12,3],
[16,13,12,4],
[16,13,12,5],
[16,13,12,6],
[16,13,12,7],
[16,13,12,8],
[16,13,12,9],
[16,13,12,10],
[16,14,3,0],
[16,14,3,1],
[16,14,3,2],
[16,14,4,0],
[16,14,4,1],
[16,14,4,2],
[16,14,4,3],
[16,14,5,0],
[16,14,5,1],
[16,14,5,2],
[16,14,5,4],
[16,14,6,0],
[16,14,6,1],
[16,14,6,2],
[16,14,6,4],
[16,14,7,0],
[16,14,7,1],
[16,14,7,2],
[16,14,7,3],
[16,14,7,4],
[16,14,7,5],
[16,14,7,6],
[16,14,8,0],
[16,14,8,1],
[16,14,8,2],
[16,14,8,4],
[16,14,8,7],
[16,14,9,0],
[16,14,9,1],
[16,14,9,2],
[16,14,9,3],
[16,14,9,4],
[16,14,9,5],
[16,14,9,6],
[16,14,9,7],
[16,14,9,8],
[16,14,10,0],
[16,14,10,1],
[16,14,10,2],
[16,14,10,3],
[16,14,10,4],
[16,14,10,5],
[16,14,10,6],
[16,14,10,7],
[16,14,10,8],
[16,14,10,9],
[16,14,11,0],
[16,14,11,1],
[16,14,11,2],
[16,14,11,3],
[16,14,11,4],
[16,14,11,5],
[16,14,11,6],
[16,14,11,7],
[16,14,11,8],
[16,14,11,9],
[16,14,11,10],
[16,14,12,0],
[16,14,12,1],
[16,14,12,2],
[16,14,12,3],
[16,14,12,4],
[16,14,12,5],
[16,14,12,6],
[16,14,12,7],
[16,14,12,8],
[16,14,12,9],
[16,14,12,10],
[16,15,3,0],
[16,15,3,1],
[16,15,3,2],
[16,15,4,0],
[16,15,4,1],
[16,15,4,2],
[16,15,4,3],
[16,15,5,0],
[16,15,5,1],
[16,15,5,2],
[16,15,5,4],
[16,15,6,0],
[16,15,6,1],
[16,15,6,2],
[16,15,6,4],
[16,15,7,0],
[16,15,7,1],
[16,15,7,2],
[16,15,7,3],
[16,15,7,4],
[16,15,7,5],
[16,15,7,6],
[16,15,8,0],
[16,15,8,1],
[16,15,8,2],
[16,15,8,4],
[16,15,8,7],
[16,15,10,0],
[16,15,10,1],
[16,15,10,2],
[16,15,10,3],
[16,15,10,4],
[16,15,10,5],
[16,15,10,6],
[16,15,10,7],
[16,15,10,8],
[16,15,11,0],
[16,15,11,1],
[16,15,11,2],
[16,15,11,3],
[16,15,11,4],
[16,15,11,5],
[16,15,11,6],
[16,15,11,7],
[16,15,11,8],
[16,15,11,10],
[16,15,12,0],
[16,15,12,1],
[16,15,12,2],
[16,15,12,3],
[16,15,12,4],
[16,15,12,5],
[16,15,12,6],
[16,15,12,7],
[16,15,12,8],
[16,15,12,10],
[16,15,13,0],
[16,15,13,1],
[16,15,13,2],
[16,15,13,3],
[16,15,13,4],
[16,15,13,5],
[16,15,13,6],
[16,15,13,7],
[16,15,13,8],
[16,15,13,10],
[16,15,13,11],
[16,15,13,12],
[16,15,14,0],
[16,15,14,1],
[16,15,14,2],
[16,15,14,3],
[16,15,14,4],
[16,15,14,5],
[16,15,14,6],
[16,15,14,7],
[16,15,14,8],
[16,15,14,10],
[16,15,14,11],
[16,15,14,12],
[17,4,3,0],
[17,4,3,1],
[17,4,3,2],
[17,5,4,0],
[17,5,4,1],
[17,5,4,2],
[17,6,4,0],
[17,6,4,1],
[17,6,4,2],
[17,7,3,0],
[17,7,3,1],
[17,7,3,2],
[17,7,4,0],
[17,7,4,1],
[17,7,4,2],
[17,7,4,3],
[17,7,5,0],
[17,7,5,1],
[17,7,5,2],
[17,7,5,4],
[17,7,6,0],
[17,7,6,1],
[17,7,6,2],
[17,8,4,0],
[17,8,4,1],
[17,8,4,2],
[17,8,7,4],
[17,9,3,0],
[17,9,3,1],
[17,9,3,2],
[17,9,4,0],
[17,9,4,1],
[17,9,4,2],
[17,9,4,3],
[17,9,5,0],
[17,9,5,1],
[17,9,5,2],
[17,9,6,4],
[17,9,7,3],
[17,9,7,4],
[17,9,7,5],
[17,9,7,6],
[17,9,8,0],
[17,9,8,1],
[17,9,8,2],
[17,9,8,4],
[17,9,8,7],
[17,10,3,0],
[17,10,3,1],
[17,10,3,2],
[17,10,4,0],
[17,10,4,1],
[17,10,4,2],
[17,10,5,4],
[17,10,6,0],
[17,10,6,1],
[17,10,6,2],
[17,10,6,4],
[17,10,7,0],
[17,10,7,1],
[17,10,7,2],
[17,10,7,3],
[17,10,7,4],
[17,10,7,5],
[17,10,7,6],
[17,10,8,0],
[17,10,8,1],
[17,10,8,2],
[17,10,8,4],
[17,10,8,7],
[17,10,9,0],
[17,10,9,1],
[17,10,9,2],
[17,10,9,3],
[17,10,9,4],
[17,10,9,5],
[17,10,9,6],
[17,10,9,7],
[17,10,9,8],
[17,11,3,0],
[17,11,3,1],
[17,11,3,2],
[17,11,4,0],
[17,11,4,1],
[17,11,4,2],
[17,11,5,4],
[17,11,6,0],
[17,11,6,1],
[17,11,6,2],
[17,11,6,4],
[17,11,7,0],
[17,11,7,1],
[17,11,7,2],
[17,11,7,3],
[17,11,7,4],
[17,11,7,5],
[17,11,7,6],
[17,11,8,0],
[17,11,8,1],
[17,11,8,2],
[17,11,8,4],
[17,11,8,7],
[17,11,9,0],
[17,11,9,1],
[17,11,9,2],
[17,11,9,3],
[17,11,9,4],
[17,11,9,5],
[17,11,9,6],
[17,11,9,7],
[17,11,9,8],
[17,11,10,0],
[17,11,10,1],
[17,11,10,2],
[17,11,10,3],
[17,11,10,4],
[17,11,10,5],
[17,11,10,6],
[17,11,10,7],
[17,11,10,8],
[17,11,10,9],
[17,12,4,3],
[17,12,5,0],
[17,12,5,1],
[17,12,5,2],
[17,12,5,4],
[17,12,6,0],
[17,12,6,1],
[17,12,6,2],
[17,12,6,4],
[17,12,7,0],
[17,12,7,1],
[17,12,7,2],
[17,12,7,3],
[17,12,7,4],
[17,12,7,5],
[17,12,7,6],
[17,12,8,0],
[17,12,8,1],
[17,12,8,2],
[17,12,8,4],
[17,12,8,7],
[17,12,9,0],
[17,12,9,1],
[17,12,9,2],
[17,12,9,3],
[17,12,9,4],
[17,12,9,5],
[17,12,9,6],
[17,12,9,7],
[17,12,9,8],
[17,12,10,0],
[17,12,10,1],
[17,12,10,2],
[17,12,10,3],
[17,12,10,4],
[17,12,10,5],
[17,12,10,6],
[17,12,10,7],
[17,12,10,8],
[17,12,10,9],
[17,13,4,3],
[17,13,5,0],
[17,13,5,1],
[17,13,5,2],
[17,13,5,4],
[17,13,6,0],
[17,13,6,1],
[17,13,6,2],
[17,13,6,4],
[17,13,7,0],
[17,13,7,1],
[17,13,7,2],
[17,13,7,3],
[17,13,7,4],
[17,13,7,5],
[17,13,7,6],
[17,13,8,0],
[17,13,8,1],
[17,13,8,2],
[17,13,8,4],
[17,13,8,7],
[17,13,9,0],
[17,13,9,1],
[17,13,9,2],
[17,13,9,3],
[17,13,9,4],
[17,13,9,5],
[17,13,9,6],
[17,13,9,7],
[17,13,9,8],
[17,13,10,0],
[17,13,10,1],
[17,13,10,2],
[17,13,10,3],
[17,13,10,4],
[17,13,10,5],
[17,13,10,6],
[17,13,10,7],
[17,13,10,8],
[17,13,10,9],
[17,13,11,0],
[17,13,11,1],
[17,13,11,2],
[17,13,11,3],
[17,13,11,4],
[17,13,11,5],
[17,13,11,6],
[17,13,11,7],
[17,13,11,8],
[17,13,11,9],
[17,13,11,10],
[17,13,12,0],
[17,13,12,1],
[17,13,12,2],
[17,13,12,3],
[17,13,12,4],
[17,13,12,5],
[17,13,12,6],
[17,13,12,7],
[17,13,12,8],
[17,13,12,9],
[17,13,12,10],
[17,14,3,0],
[17,14,3,1],
[17,14,3,2],
[17,14,4,0],
[17,14,4,1],
[17,14,4,2],
[17,14,4,3],
[17,14,5,0],
[17,14,5,1],
[17,14,5,2],
[17,14,5,4],
[17,14,6,0],
[17,14,6,1],
[17,14,6,2],
[17,14,6,4],
[17,14,7,0],
[17,14,7,1],
[17,14,7,2],
[17,14,7,3],
[17,14,7,4],
[17,14,7,5],
[17,14,7,6],
[17,14,8,0],
[17,14,8,1],
[17,14,8,2],
[17,14,8,4],
[17,14,8,7],
[17,14,9,0],
[17,14,9,1],
[17,14,9,2],
[17,14,9,3],
[17,14,9,4],
[17,14,9,5],
[17,14,9,6],
[17,14,9,7],
[17,14,9,8],
[17,14,10,0],
[17,14,10,1],
[17,14,10,2],
[17,14,10,3],
[17,14,10,4],
[17,14,10,5],
[17,14,10,6],
[17,14,10,7],
[17,14,10,8],
[17,14,10,9],
[17,14,11,0],
[17,14,11,1],
[17,14,11,2],
[17,14,11,3],
[17,14,11,4],
[17,14,11,5],
[17,14,11,6],
[17,14,11,7],
[17,14,11,8],
[17,14,11,9],
[17,14,11,10],
[17,14,12,0],
[17,14,12,1],
[17,14,12,2],
[17,14,12,3],
[17,14,12,4],
[17,14,12,5],
[17,14,12,6],
[17,14,12,7],
[17,14,12,8],
[17,14,12,9],
[17,14,12,10],
[17,15,3,0],
[17,15,3,1],
[17,15,3,2],
[17,15,4,0],
[17,15,4,1],
[17,15,4,2],
[17,15,4,3],
[17,15,5,0],
[17,15,5,1],
[17,15,5,2],
[17,15,5,4],
[17,15,6,0],
[17,15,6,1],
[17,15,6,2],
[17,15,6,4],
[17,15,7,0],
[17,15,7,1],
[17,15,7,2],
[17,15,7,3],
[17,15,7,4],
[17,15,7,5],
[17,15,7,6],
[17,15,8,0],
[17,15,8,1],
[17,15,8,2],
[17,15,8,4],
[17,15,8,7],
[17,15,10,0],
[17,15,10,1],
[17,15,10,2],
[17,15,10,3],
[17,15,10,4],
[17,15,10,5],
[17,15,10,6],
[17,15,10,7],
[17,15,10,8],
[17,15,11,0],
[17,15,11,1],
[17,15,11,2],
[17,15,11,3],
[17,15,11,4],
[17,15,11,5],
[17,15,11,6],
[17,15,11,7],
[17,15,11,8],
[17,15,11,10],
[17,15,12,0],
[17,15,12,1],
[17,15,12,2],
[17,15,12,3],
[17,15,12,4],
[17,15,12,5],
[17,15,12,6],
[17,15,12,7],
[17,15,12,8],
[17,15,12,10],
[17,15,13,0],
[17,15,13,1],
[17,15,13,2],
[17,15,13,3],
[17,15,13,4],
[17,15,13,5],
[17,15,13,6],
[17,15,13,7],
[17,15,13,8],
[17,15,13,10],
[17,15,13,11],
[17,15,13,12],
[17,15,14,0],
[17,15,14,1],
[17,15,14,2],
[17,15,14,3],
[17,15,14,4],
[17,15,14,5],
[17,15,14,6],
[17,15,14,7],
[17,15,14,8],
[17,15,14,10],
[17,15,14,11],
[17,15,14,12],
[17,16,3,0],
[17,16,3,1],
[17,16,3,2],
[17,16,4,0],
[17,16,4,1],
[17,16,4,2],
[17,16,4,3],
[17,16,5,0],
[17,16,5,1],
[17,16,5,2],
[17,16,5,4],
[17,16,6,0],
[17,16,6,1],
[17,16,6,2],
[17,16,6,4],
[17,16,7,0],
[17,16,7,1],
[17,16,7,2],
[17,16,7,3],
[17,16,7,4],
[17,16,7,5],
[17,16,7,6],
[17,16,8,0],
[17,16,8,1],
[17,16,8,2],
[17,16,8,4],
[17,16,8,7],
[17,16,9,0],
[17,16,9,1],
[17,16,9,2],
[17,16,9,3],
[17,16,9,4],
[17,16,9,5],
[17,16,9,6],
[17,16,9,7],
[17,16,9,8],
[17,16,10,0],
[17,16,10,1],
[17,16,10,2],
[17,16,10,3],
[17,16,10,4],
[17,16,10,5],
[17,16,10,6],
[17,16,10,7],
[17,16,10,8],
[17,16,10,9],
[17,16,11,0],
[17,16,11,1],
[17,16,11,2],
[17,16,11,3],
[17,16,11,4],
[17,16,11,5],
[17,16,11,6],
[17,16,11,7],
[17,16,11,8],
[17,16,11,9],
[17,16,11,10],
[17,16,12,0],
[17,16,12,1],
[17,16,12,2],
[17,16,12,3],
[17,16,12,4],
[17,16,12,5],
[17,16,12,6],
[17,16,12,7],
[17,16,12,8],
[17,16,12,9],
[17,16,12,10],
[17,16,13,0],
[17,16,13,1],
[17,16,13,2],
[17,16,13,3],
[17,16,13,4],
[17,16,13,5],
[17,16,13,6],
[17,16,13,7],
[17,16,13,8],
[17,16,13,9],
[17,16,13,10],
[17,16,13,11],
[17,16,13,12],
[17,16,14,0],
[17,16,14,1],
[17,16,14,2],
[17,16,14,3],
[17,16,14,4],
[17,16,14,5],
[17,16,14,6],
[17,16,14,7],
[17,16,14,8],
[17,16,14,9],
[17,16,14,10],
[17,16,14,11],
[17,16,14,12],
[17,16,15,0],
[17,16,15,1],
[17,16,15,2],
[17,16,15,3],
[17,16,15,4],
[17,16,15,5],
[17,16,15,6],
[17,16,15,7],
[17,16,15,8],
[17,16,15,10],
[17,16,15,11],
[17,16,15,12],
[17,16,15,13],
[17,16,15,14],
[18,4,3,0],
[18,4,3,1],
[18,4,3,2],
[18,5,4,0],
[18,5,4,1],
[18,5,4,2],
[18,6,4,0],
[18,6,4,1],
[18,6,4,2],
[18,7,3,0],
[18,7,3,1],
[18,7,3,2],
[18,7,4,0],
[18,7,4,1],
[18,7,4,2],
[18,7,4,3],
[18,7,5,0],
[18,7,5,1],
[18,7,5,2],
[18,7,6,4],
[18,8,4,0],
[18,8,4,1],
[18,8,4,2],
[18,8,7,0],
[18,8,7,1],
[18,8,7,2],
[18,8,7,4],
[18,10,4,3],
[18,10,5,0],
[18,10,5,1],
[18,10,5,2],
[18,10,5,4],
[18,10,6,0],
[18,10,6,1],
[18,10,6,2],
[18,10,6,4],
[18,10,7,0],
[18,10,7,1],
[18,10,7,2],
[18,10,7,3],
[18,10,7,4],
[18,10,7,5],
[18,10,7,6],
[18,10,8,0],
[18,10,8,1],
[18,10,8,2],
[18,10,8,4],
[18,10,8,7],
[18,11,4,3],
[18,11,5,0],
[18,11,5,1],
[18,11,5,2],
[18,11,5,4],
[18,11,6,0],
[18,11,6,1],
[18,11,6,2],
[18,11,6,4],
[18,11,7,0],
[18,11,7,1],
[18,11,7,2],
[18,11,7,3],
[18,11,7,4],
[18,11,7,5],
[18,11,7,6],
[18,11,8,0],
[18,11,8,1],
[18,11,8,2],
[18,11,8,4],
[18,11,8,7],
[18,11,10,0],
[18,11,10,1],
[18,11,10,2],
[18,11,10,3],
[18,11,10,4],
[18,11,10,5],
[18,11,10,6],
[18,11,10,7],
[18,11,10,8],
[18,12,3,0],
[18,12,3,1],
[18,12,3,2],
[18,12,4,0],
[18,12,4,1],
[18,12,4,2],
[18,12,4,3],
[18,12,5,0],
[18,12,5,1],
[18,12,5,2],
[18,12,5,4],
[18,12,6,0],
[18,12,6,1],
[18,12,6,2],
[18,12,6,4],
[18,12,7,0],
[18,12,7,1],
[18,12,7,2],
[18,12,7,3],
[18,12,7,4],
[18,12,7,5],
[18,12,7,6],
[18,12,8,0],
[18,12,8,1],
[18,12,8,2],
[18,12,8,4],
[18,12,8,7],
[18,12,10,0],
[18,12,10,1],
[18,12,10,2],
[18,12,10,3],
[18,12,10,4],
[18,12,10,5],
[18,12,10,6],
[18,12,10,7],
[18,12,10,8],
[18,13,3,0],
[18,13,3,1],
[18,13,3,2],
[18,13,4,0],
[18,13,4,1],
[18,13,4,2],
[18,13,4,3],
[18,13,5,0],
[18,13,5,1],
[18,13,5,2],
[18,13,5,4],
[18,13,6,0],
[18,13,6,1],
[18,13,6,2],
[18,13,6,4],
[18,13,7,0],
[18,13,7,1],
[18,13,7,2],
[18,13,7,3],
[18,13,7,4],
[18,13,7,5],
[18,13,7,6],
[18,13,8,0],
[18,13,8,1],
[18,13,8,2],
[18,13,8,4],
[18,13,8,7],
[18,13,10,0],
[18,13,10,1],
[18,13,10,2],
[18,13,10,3],
[18,13,10,4],
[18,13,10,5],
[18,13,10,6],
[18,13,10,7],
[18,13,10,8],
[18,13,11,0],
[18,13,11,1],
[18,13,11,2],
[18,13,11,3],
[18,13,11,4],
[18,13,11,5],
[18,13,11,6],
[18,13,11,7],
[18,13,11,8],
[18,13,11,10],
[18,13,12,0],
[18,13,12,1],
[18,13,12,2],
[18,13,12,3],
[18,13,12,4],
[18,13,12,5],
[18,13,12,6],
[18,13,12,7],
[18,13,12,8],
[18,13,12,10],
[18,14,3,0],
[18,14,3,1],
[18,14,3,2],
[18,14,4,0],
[18,14,4,1],
[18,14,4,2],
[18,14,4,3],
[18,14,5,0],
[18,14,5,1],
[18,14,5,2],
[18,14,5,4],
[18,14,6,0],
[18,14,6,1],
[18,14,6,2],
[18,14,6,4],
[18,14,7,0],
[18,14,7,1],
[18,14,7,2],
[18,14,7,3],
[18,14,7,4],
[18,14,7,5],
[18,14,7,6],
[18,14,8,0],
[18,14,8,1],
[18,14,8,2],
[18,14,8,4],
[18,14,8,7],
[18,14,10,0],
[18,14,10,1],
[18,14,10,2],
[18,14,10,3],
[18,14,10,4],
[18,14,10,5],
[18,14,10,6],
[18,14,10,7],
[18,14,10,8],
[18,14,11,0],
[18,14,11,1],
[18,14,11,2],
[18,14,11,3],
[18,14,11,4],
[18,14,11,5],
[18,14,11,6],
[18,14,11,7],
[18,14,11,8],
[18,14,11,10],
[18,14,12,0],
[18,14,12,1],
[18,14,12,2],
[18,14,12,3],
[18,14,12,4],
[18,14,12,5],
[18,14,12,6],
[18,14,12,7],
[18,14,12,8],
[18,14,12,10],
[18,16,3,0],
[18,16,3,1],
[18,16,3,2],
[18,16,4,0],
[18,16,4,1],
[18,16,4,2],
[18,16,4,3],
[18,16,5,0],
[18,16,5,1],
[18,16,5,2],
[18,16,5,4],
[18,16,6,0],
[18,16,6,1],
[18,16,6,2],
[18,16,6,4],
[18,16,7,0],
[18,16,7,1],
[18,16,7,2],
[18,16,7,3],
[18,16,7,4],
[18,16,7,5],
[18,16,7,6],
[18,16,8,0],
[18,16,8,1],
[18,16,8,2],
[18,16,8,4],
[18,16,8,7],
[18,16,10,0],
[18,16,10,1],
[18,16,10,2],
[18,16,10,3],
[18,16,10,4],
[18,16,10,5],
[18,16,10,6],
[18,16,10,7],
[18,16,10,8],
[18,16,11,0],
[18,16,11,1],
[18,16,11,2],
[18,16,11,3],
[18,16,11,4],
[18,16,11,5],
[18,16,11,6],
[18,16,11,7],
[18,16,11,8],
[18,16,11,10],
[18,16,12,0],
[18,16,12,1],
[18,16,12,2],
[18,16,12,3],
[18,16,12,4],
[18,16,12,5],
[18,16,12,6],
[18,16,12,7],
[18,16,12,8],
[18,16,12,10],
[18,16,13,0],
[18,16,13,1],
[18,16,13,2],
[18,16,13,3],
[18,16,13,4],
[18,16,13,5],
[18,16,13,6],
[18,16,13,7],
[18,16,13,8],
[18,16,13,10],
[18,16,13,11],
[18,16,13,12],
[18,16,14,0],
[18,16,14,1],
[18,16,14,2],
[18,16,14,3],
[18,16,14,4],
[18,16,14,5],
[18,16,14,6],
[18,16,14,7],
[18,16,14,8],
[18,16,14,10],
[18,16,14,11],
[18,16,14,12],
[18,17,3,0],
[18,17,3,1],
[18,17,3,2],
[18,17,4,0],
[18,17,4,1],
[18,17,4,2],
[18,17,4,3],
[18,17,5,0],
[18,17,5,1],
[18,17,5,2],
[18,17,5,4],
[18,17,6,0],
[18,17,6,1],
[18,17,6,2],
[18,17,6,4],
[18,17,7,0],
[18,17,7,1],
[18,17,7,2],
[18,17,7,3],
[18,17,7,4],
[18,17,7,5],
[18,17,7,6],
[18,17,8,0],
[18,17,8,1],
[18,17,8,2],
[18,17,8,4],
[18,17,8,7],
[18,17,10,0],
[18,17,10,1],
[18,17,10,2],
[18,17,10,3],
[18,17,10,4],
[18,17,10,5],
[18,17,10,6],
[18,17,10,7],
[18,17,10,8],
[18,17,11,0],
[18,17,11,1],
[18,17,11,2],
[18,17,11,3],
[18,17,11,4],
[18,17,11,5],
[18,17,11,6],
[18,17,11,7],
[18,17,11,8],
[18,17,11,10],
[18,17,12,0],
[18,17,12,1],
[18,17,12,2],
[18,17,12,3],
[18,17,12,4],
[18,17,12,5],
[18,17,12,6],
[18,17,12,7],
[18,17,12,8],
[18,17,12,10],
[18,17,13,0],
[18,17,13,1],
[18,17,13,2],
[18,17,13,3],
[18,17,13,4],
[18,17,13,5],
[18,17,13,6],
[18,17,13,7],
[18,17,13,8],
[18,17,13,10],
[18,17,13,11],
[18,17,13,12],
[18,17,14,0],
[18,17,14,1],
[18,17,14,2],
[18,17,14,3],
[18,17,14,4],
[18,17,14,5],
[18,17,14,6],
[18,17,14,7],
[18,17,14,8],
[18,17,14,10],
[18,17,14,11],
[18,17,14,12],
[18,17,16,0],
[18,17,16,1],
[18,17,16,2],
[18,17,16,3],
[18,17,16,4],
[18,17,16,5],
[18,17,16,6],
[18,17,16,7],
[18,17,16,8],
[18,17,16,10],
[18,17,16,11],
[18,17,16,12],
[18,17,16,13],
[18,17,16,14],
[19,4,3,0],
[19,4,3,1],
[19,4,3,2],
[19,5,4,0],
[19,5,4,1],
[19,5,4,2],
[19,6,4,0],
[19,6,4,1],
[19,6,4,2],
[19,7,3,0],
[19,7,3,1],
[19,7,3,2],
[19,7,4,0],
[19,7,4,1],
[19,7,4,2],
[19,7,5,4],
[19,7,6,0],
[19,7,6,1],
[19,7,6,2],
[19,7,6,4],
[19,8,7,0],
[19,8,7,1],
[19,8,7,2],
[19,8,7,4],
[19,9,4,3],
[19,9,5,0],
[19,9,5,1],
[19,9,5,2],
[19,9,5,4],
[19,9,6,0],
[19,9,6,1],
[19,9,6,2],
[19,9,6,4],
[19,9,7,0],
[19,9,7,1],
[19,9,7,2],
[19,9,7,3],
[19,9,7,4],
[19,9,7,5],
[19,9,7,6],
[19,9,8,0],
[19,9,8,1],
[19,9,8,2],
[19,9,8,4],
[19,9,8,7],
[19,10,3,0],
[19,10,3,1],
[19,10,3,2],
[19,10,4,0],
[19,10,4,1],
[19,10,4,2],
[19,10,4,3],
[19,10,5,0],
[19,10,5,1],
[19,10,5,2],
[19,10,5,4],
[19,10,6,0],
[19,10,6,1],
[19,10,6,2],
[19,10,6,4],
[19,10,7,0],
[19,10,7,1],
[19,10,7,2],
[19,10,7,3],
[19,10,7,4],
[19,10,7,5],
[19,10,7,6],
[19,10,8,0],
[19,10,8,1],
[19,10,8,2],
[19,10,8,4],
[19,10,8,7],
[19,10,9,0],
[19,10,9,1],
[19,10,9,2],
[19,10,9,3],
[19,10,9,4],
[19,10,9,5],
[19,10,9,6],
[19,10,9,7],
[19,10,9,8],
[19,11,3,0],
[19,11,3,1],
[19,11,3,2],
[19,11,4,0],
[19,11,4,1],
[19,11,4,2],
[19,11,4,3],
[19,11,5,0],
[19,11,5,1],
[19,11,5,2],
[19,11,5,4],
[19,11,6,0],
[19,11,6,1],
[19,11,6,2],
[19,11,6,4],
[19,11,7,0],
[19,11,7,1],
[19,11,7,2],
[19,11,7,3],
[19,11,7,4],
[19,11,7,5],
[19,11,7,6],
[19,11,8,0],
[19,11,8,1],
[19,11,8,2],
[19,11,8,4],
[19,11,8,7],
[19,11,9,0],
[19,11,9,1],
[19,11,9,2],
[19,11,9,3],
[19,11,9,4],
[19,11,9,5],
[19,11,9,6],
[19,11,9,7],
[19,11,9,8],
[19,11,10,0],
[19,11,10,1],
[19,11,10,2],
[19,11,10,3],
[19,11,10,4],
[19,11,10,5],
[19,11,10,6],
[19,11,10,7],
[19,11,10,8],
[19,11,10,9],
[19,12,3,0],
[19,12,3,1],
[19,12,3,2],
[19,12,4,0],
[19,12,4,1],
[19,12,4,2],
[19,12,4,3],
[19,12,5,0],
[19,12,5,1],
[19,12,5,2],
[19,12,5,4],
[19,12,6,0],
[19,12,6,1],
[19,12,6,2],
[19,12,6,4],
[19,12,7,0],
[19,12,7,1],
[19,12,7,2],
[19,12,7,3],
[19,12,7,4],
[19,12,7,5],
[19,12,7,6],
[19,12,8,0],
[19,12,8,1],
[19,12,8,2],
[19,12,8,4],
[19,12,8,7],
[19,12,9,0],
[19,12,9,1],
[19,12,9,2],
[19,12,9,3],
[19,12,9,4],
[19,12,9,5],
[19,12,9,6],
[19,12,9,7],
[19,12,9,8],
[19,12,10,0],
[19,12,10,1],
[19,12,10,2],
[19,12,10,3],
[19,12,10,4],
[19,12,10,5],
[19,12,10,6],
[19,12,10,7],
[19,12,10,8],
[19,12,10,9],
[19,15,3,0],
[19,15,3,1],
[19,15,3,2],
[19,15,4,0],
[19,15,4,1],
[19,15,4,2],
[19,15,4,3],
[19,15,5,0],
[19,15,5,1],
[19,15,5,2],
[19,15,5,4],
[19,15,6,0],
[19,15,6,1],
[19,15,6,2],
[19,15,6,4],
[19,15,7,0],
[19,15,7,1],
[19,15,7,2],
[19,15,7,3],
[19,15,7,4],
[19,15,7,5],
[19,15,7,6],
[19,15,8,0],
[19,15,8,1],
[19,15,8,2],
[19,15,8,4],
[19,15,8,7],
[19,15,10,0],
[19,15,10,1],
[19,15,10,2],
[19,15,10,3],
[19,15,10,4],
[19,15,10,5],
[19,15,10,6],
[19,15,10,7],
[19,15,10,8],
[19,15,11,0],
[19,15,11,1],
[19,15,11,2],
[19,15,11,3],
[19,15,11,4],
[19,15,11,5],
[19,15,11,6],
[19,15,11,7],
[19,15,11,8],
[19,15,11,10],
[19,15,12,0],
[19,15,12,1],
[19,15,12,2],
[19,15,12,3],
[19,15,12,4],
[19,15,12,5],
[19,15,12,6],
[19,15,12,7],
[19,15,12,8],
[19,15,12,10],
[19,16,3,0],
[19,16,3,1],
[19,16,3,2],
[19,16,4,0],
[19,16,4,1],
[19,16,4,2],
[19,16,4,3],
[19,16,5,0],
[19,16,5,1],
[19,16,5,2],
[19,16,5,4],
[19,16,6,0],
[19,16,6,1],
[19,16,6,2],
[19,16,6,4],
[19,16,7,0],
[19,16,7,1],
[19,16,7,2],
[19,16,7,3],
[19,16,7,4],
[19,16,7,5],
[19,16,7,6],
[19,16,8,0],
[19,16,8,1],
[19,16,8,2],
[19,16,8,4],
[19,16,8,7],
[19,16,9,0],
[19,16,9,1],
[19,16,9,2],
[19,16,9,3],
[19,16,9,4],
[19,16,9,5],
[19,16,9,6],
[19,16,9,7],
[19,16,9,8],
[19,16,10,0],
[19,16,10,1],
[19,16,10,2],
[19,16,10,3],
[19,16,10,4],
[19,16,10,5],
[19,16,10,6],
[19,16,10,7],
[19,16,10,8],
[19,16,10,9],
[19,16,11,0],
[19,16,11,1],
[19,16,11,2],
[19,16,11,3],
[19,16,11,4],
[19,16,11,5],
[19,16,11,6],
[19,16,11,7],
[19,16,11,8],
[19,16,11,9],
[19,16,11,10],
[19,16,12,0],
[19,16,12,1],
[19,16,12,2],
[19,16,12,3],
[19,16,12,4],
[19,16,12,5],
[19,16,12,6],
[19,16,12,7],
[19,16,12,8],
[19,16,12,9],
[19,16,12,10],
[19,16,15,0],
[19,16,15,1],
[19,16,15,2],
[19,16,15,3],
[19,16,15,4],
[19,16,15,5],
[19,16,15,6],
[19,16,15,7],
[19,16,15,8],
[19,16,15,10],
[19,16,15,11],
[19,16,15,12],
[19,17,3,0],
[19,17,3,1],
[19,17,3,2],
[19,17,4,0],
[19,17,4,1],
[19,17,4,2],
[19,17,4,3],
[19,17,5,0],
[19,17,5,1],
[19,17,5,2],
[19,17,5,4],
[19,17,6,0],
[19,17,6,1],
[19,17,6,2],
[19,17,6,4],
[19,17,7,0],
[19,17,7,1],
[19,17,7,2],
[19,17,7,3],
[19,17,7,4],
[19,17,7,5],
[19,17,7,6],
[19,17,8,0],
[19,17,8,1],
[19,17,8,2],
[19,17,8,4],
[19,17,8,7],
[19,17,9,0],
[19,17,9,1],
[19,17,9,2],
[19,17,9,3],
[19,17,9,4],
[19,17,9,5],
[19,17,9,6],
[19,17,9,7],
[19,17,9,8],
[19,17,10,0],
[19,17,10,1],
[19,17,10,2],
[19,17,10,3],
[19,17,10,4],
[19,17,10,5],
[19,17,10,6],
[19,17,10,7],
[19,17,10,8],
[19,17,10,9],
[19,17,11,0],
[19,17,11,1],
[19,17,11,2],
[19,17,11,3],
[19,17,11,4],
[19,17,11,5],
[19,17,11,6],
[19,17,11,7],
[19,17,11,8],
[19,17,11,9],
[19,17,11,10],
[19,17,12,0],
[19,17,12,1],
[19,17,12,2],
[19,17,12,3],
[19,17,12,4],
[19,17,12,5],
[19,17,12,6],
[19,17,12,7],
[19,17,12,8],
[19,17,12,9],
[19,17,12,10],
[19,17,15,0],
[19,17,15,1],
[19,17,15,2],
[19,17,15,3],
[19,17,15,4],
[19,17,15,5],
[19,17,15,6],
[19,17,15,7],
[19,17,15,8],
[19,17,15,10],
[19,17,15,11],
[19,17,15,12],
[19,17,16,0],
[19,17,16,1],
[19,17,16,2],
[19,17,16,3],
[19,17,16,4],
[19,17,16,5],
[19,17,16,6],
[19,17,16,7],
[19,17,16,8],
[19,17,16,9],
[19,17,16,10],
[19,17,16,11],
[19,17,16,12],
[19,17,16,15],
[19,18,3,0],
[19,18,3,1],
[19,18,3,2],
[19,18,4,0],
[19,18,4,1],
[19,18,4,2],
[19,18,4,3],
[19,18,5,0],
[19,18,5,1],
[19,18,5,2],
[19,18,5,4],
[19,18,6,0],
[19,18,6,1],
[19,18,6,2],
[19,18,6,4],
[19,18,7,0],
[19,18,7,1],
[19,18,7,2],
[19,18,7,3],
[19,18,7,4],
[19,18,7,5],
[19,18,7,6],
[19,18,8,0],
[19,18,8,1],
[19,18,8,2],
[19,18,8,4],
[19,18,8,7],
[19,18,10,0],
[19,18,10,1],
[19,18,10,2],
[19,18,10,3],
[19,18,10,4],
[19,18,10,5],
[19,18,10,6],
[19,18,10,7],
[19,18,10,8],
[19,18,11,0],
[19,18,11,1],
[19,18,11,2],
[19,18,11,3],
[19,18,11,4],
[19,18,11,5],
[19,18,11,6],
[19,18,11,7],
[19,18,11,8],
[19,18,11,10],
[19,18,12,0],
[19,18,12,1],
[19,18,12,2],
[19,18,12,3],
[19,18,12,4],
[19,18,12,5],
[19,18,12,6],
[19,18,12,7],
[19,18,12,8],
[19,18,12,10],
[19,18,16,0],
[19,18,16,1],
[19,18,16,2],
[19,18,16,3],
[19,18,16,4],
[19,18,16,5],
[19,18,16,6],
[19,18,16,7],
[19,18,16,8],
[19,18,16,10],
[19,18,16,11],
[19,18,16,12],
[19,18,17,0],
[19,18,17,1],
[19,18,17,2],
[19,18,17,3],
[19,18,17,4],
[19,18,17,5],
[19,18,17,6],
[19,18,17,7],
[19,18,17,8],
[19,18,17,10],
[19,18,17,11],
[19,18,17,12],
[19,18,17,16],
[20,4,3,0],
[20,4,3,1],
[20,4,3,2],
[20,5,4,0],
[20,5,4,1],
[20,5,4,2],
[20,7,4,3],
[20,7,5,0],
[20,7,5,1],
[20,7,5,2],
[20,7,5,4],
[20,7,6,0],
[20,7,6,1],
[20,7,6,2],
[20,7,6,4],
[20,8,4,0],
[20,8,4,1],
[20,8,4,2],
[20,8,7,0],
[20,8,7,1],
[20,8,7,2],
[20,8,7,4],
[20,9,3,0],
[20,9,3,1],
[20,9,3,2],
[20,9,4,0],
[20,9,4,1],
[20,9,4,2],
[20,9,4,3],
[20,9,5,0],
[20,9,5,1],
[20,9,5,2],
[20,9,5,4],
[20,9,6,0],
[20,9,6,1],
[20,9,6,2],
[20,9,6,4],
[20,9,7,0],
[20,9,7,1],
[20,9,7,2],
[20,9,7,3],
[20,9,7,4],
[20,9,7,5],
[20,9,7,6],
[20,9,8,0],
[20,9,8,1],
[20,9,8,2],
[20,9,8,4],
[20,9,8,7],
[20,11,3,0],
[20,11,3,1],
[20,11,3,2],
[20,11,4,0],
[20,11,4,1],
[20,11,4,2],
[20,11,4,3],
[20,11,5,0],
[20,11,5,1],
[20,11,5,2],
[20,11,5,4],
[20,11,6,0],
[20,11,6,1],
[20,11,6,2],
[20,11,6,4],
[20,11,7,0],
[20,11,7,1],
[20,11,7,2],
[20,11,7,3],
[20,11,7,4],
[20,11,7,5],
[20,11,7,6],
[20,11,8,0],
[20,11,8,1],
[20,11,8,2],
[20,11,8,4],
[20,11,8,7],
[20,11,9,0],
[20,11,9,1],
[20,11,9,2],
[20,11,9,3],
[20,11,9,4],
[20,11,9,5],
[20,11,9,6],
[20,11,9,7],
[20,11,9,8],
[20,12,3,0],
[20,12,3,1],
[20,12,3,2],
[20,12,4,0],
[20,12,4,1],
[20,12,4,2],
[20,12,4,3],
[20,12,5,0],
[20,12,5,1],
[20,12,5,2],
[20,12,5,4],
[20,12,6,0],
[20,12,6,1],
[20,12,6,2],
[20,12,6,4],
[20,12,7,0],
[20,12,7,1],
[20,12,7,2],
[20,12,7,3],
[20,12,7,4],
[20,12,7,5],
[20,12,7,6],
[20,12,8,0],
[20,12,8,1],
[20,12,8,2],
[20,12,8,4],
[20,12,8,7],
[20,12,9,0],
[20,12,9,1],
[20,12,9,2],
[20,12,9,3],
[20,12,9,4],
[20,12,9,5],
[20,12,9,6],
[20,12,9,7],
[20,12,9,8],
[20,13,3,0],
[20,13,3,1],
[20,13,3,2],
[20,13,4,0],
[20,13,4,1],
[20,13,4,2],
[20,13,4,3],
[20,13,5,0],
[20,13,5,1],
[20,13,5,2],
[20,13,5,4],
[20,13,6,0],
[20,13,6,1],
[20,13,6,2],
[20,13,6,4],
[20,13,7,0],
[20,13,7,1],
[20,13,7,2],
[20,13,7,3],
[20,13,7,4],
[20,13,7,5],
[20,13,7,6],
[20,13,8,0],
[20,13,8,1],
[20,13,8,2],
[20,13,8,4],
[20,13,8,7],
[20,13,9,0],
[20,13,9,1],
[20,13,9,2],
[20,13,9,3],
[20,13,9,4],
[20,13,9,5],
[20,13,9,6],
[20,13,9,7],
[20,13,9,8],
[20,13,11,0],
[20,13,11,1],
[20,13,11,2],
[20,13,11,3],
[20,13,11,4],
[20,13,11,5],
[20,13,11,6],
[20,13,11,7],
[20,13,11,8],
[20,13,11,9],
[20,13,12,0],
[20,13,12,1],
[20,13,12,2],
[20,13,12,3],
[20,13,12,4],
[20,13,12,5],
[20,13,12,6],
[20,13,12,7],
[20,13,12,8],
[20,13,12,9],
[20,14,3,0],
[20,14,3,1],
[20,14,3,2],
[20,14,4,0],
[20,14,4,1],
[20,14,4,2],
[20,14,4,3],
[20,14,5,0],
[20,14,5,1],
[20,14,5,2],
[20,14,5,4],
[20,14,6,0],
[20,14,6,1],
[20,14,6,2],
[20,14,6,4],
[20,14,7,0],
[20,14,7,1],
[20,14,7,2],
[20,14,7,3],
[20,14,7,4],
[20,14,7,5],
[20,14,7,6],
[20,14,8,0],
[20,14,8,1],
[20,14,8,2],
[20,14,8,4],
[20,14,8,7],
[20,14,9,0],
[20,14,9,1],
[20,14,9,2],
[20,14,9,3],
[20,14,9,4],
[20,14,9,5],
[20,14,9,6],
[20,14,9,7],
[20,14,9,8],
[20,14,11,0],
[20,14,11,1],
[20,14,11,2],
[20,14,11,3],
[20,14,11,4],
[20,14,11,5],
[20,14,11,6],
[20,14,11,7],
[20,14,11,8],
[20,14,11,9],
[20,14,12,0],
[20,14,12,1],
[20,14,12,2],
[20,14,12,3],
[20,14,12,4],
[20,14,12,5],
[20,14,12,6],
[20,14,12,7],
[20,14,12,8],
[20,14,12,9],
[20,15,3,0],
[20,15,3,1],
[20,15,3,2],
[20,15,4,0],
[20,15,4,1],
[20,15,4,2],
[20,15,4,3],
[20,15,5,0],
[20,15,5,1],
[20,15,5,2],
[20,15,5,4],
[20,15,6,0],
[20,15,6,1],
[20,15,6,2],
[20,15,6,4],
[20,15,7,0],
[20,15,7,1],
[20,15,7,2],
[20,15,7,3],
[20,15,7,4],
[20,15,7,5],
[20,15,7,6],
[20,15,8,0],
[20,15,8,1],
[20,15,8,2],
[20,15,8,4],
[20,15,8,7],
[20,15,11,0],
[20,15,11,1],
[20,15,11,2],
[20,15,11,3],
[20,15,11,4],
[20,15,11,5],
[20,15,11,6],
[20,15,11,7],
[20,15,11,8],
[20,15,12,0],
[20,15,12,1],
[20,15,12,2],
[20,15,12,3],
[20,15,12,4],
[20,15,12,5],
[20,15,12,6],
[20,15,12,7],
[20,15,12,8],
[20,15,13,0],
[20,15,13,1],
[20,15,13,2],
[20,15,13,3],
[20,15,13,4],
[20,15,13,5],
[20,15,13,6],
[20,15,13,7],
[20,15,13,8],
[20,15,13,11],
[20,15,13,12],
[20,15,14,0],
[20,15,14,1],
[20,15,14,2],
[20,15,14,3],
[20,15,14,4],
[20,15,14,5],
[20,15,14,6],
[20,15,14,7],
[20,15,14,8],
[20,15,14,11],
[20,15,14,12],
[20,16,3,0],
[20,16,3,1],
[20,16,3,2],
[20,16,4,0],
[20,16,4,1],
[20,16,4,2],
[20,16,4,3],
[20,16,5,0],
[20,16,5,1],
[20,16,5,2],
[20,16,5,4],
[20,16,6,0],
[20,16,6,1],
[20,16,6,2],
[20,16,6,4],
[20,16,7,0],
[20,16,7,1],
[20,16,7,2],
[20,16,7,3],
[20,16,7,4],
[20,16,7,5],
[20,16,7,6],
[20,16,8,0],
[20,16,8,1],
[20,16,8,2],
[20,16,8,4],
[20,16,8,7],
[20,16,9,0],
[20,16,9,1],
[20,16,9,2],
[20,16,9,3],
[20,16,9,4],
[20,16,9,5],
[20,16,9,6],
[20,16,9,7],
[20,16,9,8],
[20,16,11,0],
[20,16,11,1],
[20,16,11,2],
[20,16,11,3],
[20,16,11,4],
[20,16,11,5],
[20,16,11,6],
[20,16,11,7],
[20,16,11,8],
[20,16,11,9],
[20,16,12,0],
[20,16,12,1],
[20,16,12,2],
[20,16,12,3],
[20,16,12,4],
[20,16,12,5],
[20,16,12,6],
[20,16,12,7],
[20,16,12,8],
[20,16,12,9],
[20,16,13,0],
[20,16,13,1],
[20,16,13,2],
[20,16,13,3],
[20,16,13,4],
[20,16,13,5],
[20,16,13,6],
[20,16,13,7],
[20,16,13,8],
[20,16,13,9],
[20,16,13,11],
[20,16,13,12],
[20,16,14,0],
[20,16,14,1],
[20,16,14,2],
[20,16,14,3],
[20,16,14,4],
[20,16,14,5],
[20,16,14,6],
[20,16,14,7],
[20,16,14,8],
[20,16,14,9],
[20,16,14,11],
[20,16,14,12],
[20,16,15,0],
[20,16,15,1],
[20,16,15,2],
[20,16,15,3],
[20,16,15,4],
[20,16,15,5],
[20,16,15,6],
[20,16,15,7],
[20,16,15,8],
[20,16,15,11],
[20,16,15,12],
[20,16,15,13],
[20,16,15,14],
[20,17,3,0],
[20,17,3,1],
[20,17,3,2],
[20,17,4,0],
[20,17,4,1],
[20,17,4,2],
[20,17,4,3],
[20,17,5,0],
[20,17,5,1],
[20,17,5,2],
[20,17,5,4],
[20,17,6,0],
[20,17,6,1],
[20,17,6,2],
[20,17,6,4],
[20,17,7,0],
[20,17,7,1],
[20,17,7,2],
[20,17,7,3],
[20,17,7,4],
[20,17,7,5],
[20,17,7,6],
[20,17,8,0],
[20,17,8,1],
[20,17,8,2],
[20,17,8,4],
[20,17,8,7],
[20,17,9,0],
[20,17,9,1],
[20,17,9,2],
[20,17,9,3],
[20,17,9,4],
[20,17,9,5],
[20,17,9,6],
[20,17,9,7],
[20,17,9,8],
[20,17,11,0],
[20,17,11,1],
[20,17,11,2],
[20,17,11,3],
[20,17,11,4],
[20,17,11,5],
[20,17,11,6],
[20,17,11,7],
[20,17,11,8],
[20,17,11,9],
[20,17,12,0],
[20,17,12,1],
[20,17,12,2],
[20,17,12,3],
[20,17,12,4],
[20,17,12,5],
[20,17,12,6],
[20,17,12,7],
[20,17,12,8],
[20,17,12,9],
[20,17,13,0],
[20,17,13,1],
[20,17,13,2],
[20,17,13,3],
[20,17,13,4],
[20,17,13,5],
[20,17,13,6],
[20,17,13,7],
[20,17,13,8],
[20,17,13,9],
[20,17,13,11],
[20,17,13,12],
[20,17,14,0],
[20,17,14,1],
[20,17,14,2],
[20,17,14,3],
[20,17,14,4],
[20,17,14,5],
[20,17,14,6],
[20,17,14,7],
[20,17,14,8],
[20,17,14,9],
[20,17,14,11],
[20,17,14,12],
[20,17,15,0],
[20,17,15,1],
[20,17,15,2],
[20,17,15,3],
[20,17,15,4],
[20,17,15,5],
[20,17,15,6],
[20,17,15,7],
[20,17,15,8],
[20,17,15,11],
[20,17,15,12],
[20,17,15,13],
[20,17,15,14],
[20,17,16,0],
[20,17,16,1],
[20,17,16,2],
[20,17,16,3],
[20,17,16,4],
[20,17,16,5],
[20,17,16,6],
[20,17,16,7],
[20,17,16,8],
[20,17,16,9],
[20,17,16,11],
[20,17,16,12],
[20,17,16,13],
[20,17,16,14],
[20,17,16,15],
[20,18,3,0],
[20,18,3,1],
[20,18,3,2],
[20,18,4,0],
[20,18,4,1],
[20,18,4,2],
[20,18,4,3],
[20,18,5,0],
[20,18,5,1],
[20,18,5,2],
[20,18,5,4],
[20,18,6,0],
[20,18,6,1],
[20,18,6,2],
[20,18,6,4],
[20,18,7,0],
[20,18,7,1],
[20,18,7,2],
[20,18,7,3],
[20,18,7,4],
[20,18,7,5],
[20,18,7,6],
[20,18,8,0],
[20,18,8,1],
[20,18,8,2],
[20,18,8,4],
[20,18,8,7],
[20,18,11,0],
[20,18,11,1],
[20,18,11,2],
[20,18,11,3],
[20,18,11,4],
[20,18,11,5],
[20,18,11,6],
[20,18,11,7],
[20,18,11,8],
[20,18,12,0],
[20,18,12,1],
[20,18,12,2],
[20,18,12,3],
[20,18,12,4],
[20,18,12,5],
[20,18,12,6],
[20,18,12,7],
[20,18,12,8],
[20,18,13,0],
[20,18,13,1],
[20,18,13,2],
[20,18,13,3],
[20,18,13,4],
[20,18,13,5],
[20,18,13,6],
[20,18,13,7],
[20,18,13,8],
[20,18,13,11],
[20,18,13,12],
[20,18,14,0],
[20,18,14,1],
[20,18,14,2],
[20,18,14,3],
[20,18,14,4],
[20,18,14,5],
[20,18,14,6],
[20,18,14,7],
[20,18,14,8],
[20,18,14,11],
[20,18,14,12],
[20,18,16,0],
[20,18,16,1],
[20,18,16,2],
[20,18,16,3],
[20,18,16,4],
[20,18,16,5],
[20,18,16,6],
[20,18,16,7],
[20,18,16,8],
[20,18,16,11],
[20,18,16,12],
[20,18,16,13],
[20,18,16,14],
[20,18,17,0],
[20,18,17,1],
[20,18,17,2],
[20,18,17,3],
[20,18,17,4],
[20,18,17,5],
[20,18,17,6],
[20,18,17,7],
[20,18,17,8],
[20,18,17,11],
[20,18,17,12],
[20,18,17,13],
[20,18,17,14],
[20,18,17,16],
[20,19,3,0],
[20,19,3,1],
[20,19,3,2],
[20,19,4,0],
[20,19,4,1],
[20,19,4,2],
[20,19,4,3],
[20,19,5,0],
[20,19,5,1],
[20,19,5,2],
[20,19,5,4],
[20,19,6,0],
[20,19,6,1],
[20,19,6,2],
[20,19,6,4],
[20,19,7,0],
[20,19,7,1],
[20,19,7,2],
[20,19,7,3],
[20,19,7,4],
[20,19,7,5],
[20,19,7,6],
[20,19,8,0],
[20,19,8,1],
[20,19,8,2],
[20,19,8,4],
[20,19,8,7],
[20,19,9,0],
[20,19,9,1],
[20,19,9,2],
[20,19,9,3],
[20,19,9,4],
[20,19,9,5],
[20,19,9,6],
[20,19,9,7],
[20,19,9,8],
[20,19,11,0],
[20,19,11,1],
[20,19,11,2],
[20,19,11,3],
[20,19,11,4],
[20,19,11,5],
[20,19,11,6],
[20,19,11,7],
[20,19,11,8],
[20,19,11,9],
[20,19,12,0],
[20,19,12,1],
[20,19,12,2],
[20,19,12,3],
[20,19,12,4],
[20,19,12,5],
[20,19,12,6],
[20,19,12,7],
[20,19,12,8],
[20,19,12,9],
[20,19,15,0],
[20,19,15,1],
[20,19,15,2],
[20,19,15,3],
[20,19,15,4],
[20,19,15,5],
[20,19,15,6],
[20,19,15,7],
[20,19,15,8],
[20,19,15,11],
[20,19,15,12],
[20,19,16,0],
[20,19,16,1],
[20,19,16,2],
[20,19,16,3],
[20,19,16,4],
[20,19,16,5],
[20,19,16,6],
[20,19,16,7],
[20,19,16,8],
[20,19,16,9],
[20,19,16,11],
[20,19,16,12],
[20,19,16,15],
[20,19,17,0],
[20,19,17,1],
[20,19,17,2],
[20,19,17,3],
[20,19,17,4],
[20,19,17,5],
[20,19,17,6],
[20,19,17,7],
[20,19,17,8],
[20,19,17,9],
[20,19,17,11],
[20,19,17,12],
[20,19,17,15],
[20,19,17,16],
[20,19,18,0],
[20,19,18,1],
[20,19,18,2],
[20,19,18,3],
[20,19,18,4],
[20,19,18,5],
[20,19,18,6],
[20,19,18,7],
[20,19,18,8],
[20,19,18,11],
[20,19,18,12],
[20,19,18,16],
[20,19,18,17],
[21,7,4,0],
[21,7,4,1],
[21,7,4,2],
[21,9,4,0],
[21,9,4,1],
[21,9,4,2],
[21,9,7,0],
[21,9,7,1],
[21,9,7,2],
[21,9,7,4],
[21,10,4,0],
[21,10,4,1],
[21,10,4,2],
[21,10,7,0],
[21,10,7,1],
[21,10,7,2],
[21,10,7,4],
[21,10,9,0],
[21,10,9,1],
[21,10,9,2],
[21,10,9,4],
[21,10,9,7],
[21,11,4,0],
[21,11,4,1],
[21,11,4,2],
[21,11,7,0],
[21,11,7,1],
[21,11,7,2],
[21,11,7,4],
[21,11,9,0],
[21,11,9,1],
[21,11,9,2],
[21,11,9,4],
[21,11,9,7],
[21,11,10,0],
[21,11,10,1],
[21,11,10,2],
[21,11,10,4],
[21,11,10,7],
[21,11,10,9],
[21,12,4,0],
[21,12,4,1],
[21,12,4,2],
[21,12,7,0],
[21,12,7,1],
[21,12,7,2],
[21,12,7,4],
[21,12,9,0],
[21,12,9,1],
[21,12,9,2],
[21,12,9,4],
[21,12,9,7],
[21,12,10,0],
[21,12,10,1],
[21,12,10,2],
[21,12,10,4],
[21,12,10,7],
[21,12,10,9],
[21,13,4,0],
[21,13,4,1],
[21,13,4,2],
[21,13,7,0],
[21,13,7,1],
[21,13,7,2],
[21,13,7,4],
[21,13,9,0],
[21,13,9,1],
[21,13,9,2],
[21,13,9,4],
[21,13,9,7],
[21,13,10,0],
[21,13,10,1],
[21,13,10,2],
[21,13,10,4],
[21,13,10,7],
[21,13,10,9],
[21,13,11,0],
[21,13,11,1],
[21,13,11,2],
[21,13,11,4],
[21,13,11,7],
[21,13,11,9],
[21,13,11,10],
[21,13,12,0],
[21,13,12,1],
[21,13,12,2],
[21,13,12,4],
[21,13,12,7],
[21,13,12,9],
[21,13,12,10],
[21,14,4,0],
[21,14,4,1],
[21,14,4,2],
[21,14,7,0],
[21,14,7,1],
[21,14,7,2],
[21,14,7,4],
[21,14,9,0],
[21,14,9,1],
[21,14,9,2],
[21,14,9,4],
[21,14,9,7],
[21,14,10,0],
[21,14,10,1],
[21,14,10,2],
[21,14,10,4],
[21,14,10,7],
[21,14,10,9],
[21,14,11,0],
[21,14,11,1],
[21,14,11,2],
[21,14,11,4],
[21,14,11,7],
[21,14,11,9],
[21,14,11,10],
[21,14,12,0],
[21,14,12,1],
[21,14,12,2],
[21,14,12,4],
[21,14,12,7],
[21,14,12,9],
[21,14,12,10],
[21,15,4,0],
[21,15,4,1],
[21,15,4,2],
[21,15,7,0],
[21,15,7,1],
[21,15,7,2],
[21,15,7,4],
[21,15,10,0],
[21,15,10,1],
[21,15,10,2],
[21,15,10,4],
[21,15,10,7],
[21,15,11,0],
[21,15,11,1],
[21,15,11,2],
[21,15,11,4],
[21,15,11,7],
[21,15,11,10],
[21,15,12,0],
[21,15,12,1],
[21,15,12,2],
[21,15,12,4],
[21,15,12,7],
[21,15,12,10],
[21,15,13,0],
[21,15,13,1],
[21,15,13,2],
[21,15,13,4],
[21,15,13,7],
[21,15,13,10],
[21,15,13,11],
[21,15,13,12],
[21,15,14,0],
[21,15,14,1],
[21,15,14,2],
[21,15,14,4],
[21,15,14,7],
[21,15,14,10],
[21,15,14,11],
[21,15,14,12],
[21,16,4,0],
[21,16,4,1],
[21,16,4,2],
[21,16,7,0],
[21,16,7,1],
[21,16,7,2],
[21,16,7,4],
[21,16,9,0],
[21,16,9,1],
[21,16,9,2],
[21,16,9,4],
[21,16,9,7],
[21,16,10,0],
[21,16,10,1],
[21,16,10,2],
[21,16,10,4],
[21,16,10,7],
[21,16,10,9],
[21,16,11,0],
[21,16,11,1],
[21,16,11,2],
[21,16,11,4],
[21,16,11,7],
[21,16,11,9],
[21,16,11,10],
[21,16,12,0],
[21,16,12,1],
[21,16,12,2],
[21,16,12,4],
[21,16,12,7],
[21,16,12,9],
[21,16,12,10],
[21,16,13,0],
[21,16,13,1],
[21,16,13,2],
[21,16,13,4],
[21,16,13,7],
[21,16,13,9],
[21,16,13,10],
[21,16,13,11],
[21,16,13,12],
[21,16,14,0],
[21,16,14,1],
[21,16,14,2],
[21,16,14,4],
[21,16,14,7],
[21,16,14,9],
[21,16,14,10],
[21,16,14,11],
[21,16,14,12],
[21,16,15,0],
[21,16,15,1],
[21,16,15,2],
[21,16,15,4],
[21,16,15,7],
[21,16,15,10],
[21,16,15,11],
[21,16,15,12],
[21,16,15,13],
[21,16,15,14],
[21,17,4,0],
[21,17,4,1],
[21,17,4,2],
[21,17,7,0],
[21,17,7,1],
[21,17,7,2],
[21,17,7,4],
[21,17,9,0],
[21,17,9,1],
[21,17,9,2],
[21,17,9,4],
[21,17,9,7],
[21,17,10,0],
[21,17,10,1],
[21,17,10,2],
[21,17,10,4],
[21,17,10,7],
[21,17,10,9],
[21,17,11,0],
[21,17,11,1],
[21,17,11,2],
[21,17,11,4],
[21,17,11,7],
[21,17,11,9],
[21,17,11,10],
[21,17,12,0],
[21,17,12,1],
[21,17,12,2],
[21,17,12,4],
[21,17,12,7],
[21,17,12,9],
[21,17,12,10],
[21,17,13,0],
[21,17,13,1],
[21,17,13,2],
[21,17,13,4],
[21,17,13,7],
[21,17,13,9],
[21,17,13,10],
[21,17,13,11],
[21,17,13,12],
[21,17,14,0],
[21,17,14,1],
[21,17,14,2],
[21,17,14,4],
[21,17,14,7],
[21,17,14,9],
[21,17,14,10],
[21,17,14,11],
[21,17,14,12],
[21,17,15,0],
[21,17,15,1],
[21,17,15,2],
[21,17,15,4],
[21,17,15,7],
[21,17,15,10],
[21,17,15,11],
[21,17,15,12],
[21,17,15,13],
[21,17,15,14],
[21,17,16,0],
[21,17,16,1],
[21,17,16,2],
[21,17,16,4],
[21,17,16,7],
[21,17,16,9],
[21,17,16,10],
[21,17,16,11],
[21,17,16,12],
[21,17,16,13],
[21,17,16,14],
[21,17,16,15],
[21,18,4,0],
[21,18,4,1],
[21,18,4,2],
[21,18,7,0],
[21,18,7,1],
[21,18,7,2],
[21,18,7,4],
[21,18,10,0],
[21,18,10,1],
[21,18,10,2],
[21,18,10,4],
[21,18,10,7],
[21,18,11,0],
[21,18,11,1],
[21,18,11,2],
[21,18,11,4],
[21,18,11,7],
[21,18,11,10],
[21,18,12,0],
[21,18,12,1],
[21,18,12,2],
[21,18,12,4],
[21,18,12,7],
[21,18,12,10],
[21,18,13,0],
[21,18,13,1],
[21,18,13,2],
[21,18,13,4],
[21,18,13,7],
[21,18,13,10],
[21,18,13,11],
[21,18,13,12],
[21,18,14,0],
[21,18,14,1],
[21,18,14,2],
[21,18,14,4],
[21,18,14,7],
[21,18,14,10],
[21,18,14,11],
[21,18,14,12],
[21,18,16,0],
[21,18,16,1],
[21,18,16,2],
[21,18,16,4],
[21,18,16,7],
[21,18,16,10],
[21,18,16,11],
[21,18,16,12],
[21,18,16,13],
[21,18,16,14],
[21,18,17,0],
[21,18,17,1],
[21,18,17,2],
[21,18,17,4],
[21,18,17,7],
[21,18,17,10],
[21,18,17,11],
[21,18,17,12],
[21,18,17,13],
[21,18,17,14],
[21,18,17,16],
[21,19,4,0],
[21,19,4,1],
[21,19,4,2],
[21,19,7,0],
[21,19,7,1],
[21,19,7,2],
[21,19,7,4],
[21,19,9,0],
[21,19,9,1],
[21,19,9,2],
[21,19,9,4],
[21,19,9,7],
[21,19,10,0],
[21,19,10,1],
[21,19,10,2],
[21,19,10,4],
[21,19,10,7],
[21,19,10,9],
[21,19,11,0],
[21,19,11,1],
[21,19,11,2],
[21,19,11,4],
[21,19,11,7],
[21,19,11,9],
[21,19,11,10],
[21,19,12,0],
[21,19,12,1],
[21,19,12,2],
[21,19,12,4],
[21,19,12,7],
[21,19,12,9],
[21,19,12,10],
[21,19,15,0],
[21,19,15,1],
[21,19,15,2],
[21,19,15,4],
[21,19,15,7],
[21,19,15,10],
[21,19,15,11],
[21,19,15,12],
[21,19,16,0],
[21,19,16,1],
[21,19,16,2],
[21,19,16,4],
[21,19,16,7],
[21,19,16,9],
[21,19,16,10],
[21,19,16,11],
[21,19,16,12],
[21,19,16,15],
[21,19,17,0],
[21,19,17,1],
[21,19,17,2],
[21,19,17,4],
[21,19,17,7],
[21,19,17,9],
[21,19,17,10],
[21,19,17,11],
[21,19,17,12],
[21,19,17,15],
[21,19,17,16],
[21,19,18,0],
[21,19,18,1],
[21,19,18,2],
[21,19,18,4],
[21,19,18,7],
[21,19,18,10],
[21,19,18,11],
[21,19,18,12],
[21,19,18,16],
[21,19,18,17],
[21,20,4,0],
[21,20,4,1],
[21,20,4,2],
[21,20,7,0],
[21,20,7,1],
[21,20,7,2],
[21,20,7,4],
[21,20,9,0],
[21,20,9,1],
[21,20,9,2],
[21,20,9,4],
[21,20,9,7],
[21,20,11,0],
[21,20,11,1],
[21,20,11,2],
[21,20,11,4],
[21,20,11,7],
[21,20,11,9],
[21,20,12,0],
[21,20,12,1],
[21,20,12,2],
[21,20,12,4],
[21,20,12,7],
[21,20,12,9],
[21,20,13,0],
[21,20,13,1],
[21,20,13,2],
[21,20,13,4],
[21,20,13,7],
[21,20,13,9],
[21,20,13,11],
[21,20,13,12],
[21,20,14,0],
[21,20,14,1],
[21,20,14,2],
[21,20,14,4],
[21,20,14,7],
[21,20,14,9],
[21,20,14,11],
[21,20,14,12],
[21,20,15,0],
[21,20,15,1],
[21,20,15,2],
[21,20,15,4],
[21,20,15,7],
[21,20,15,11],
[21,20,15,12],
[21,20,15,13],
[21,20,15,14],
[21,20,16,0],
[21,20,16,1],
[21,20,16,2],
[21,20,16,4],
[21,20,16,7],
[21,20,16,9],
[21,20,16,11],
[21,20,16,12],
[21,20,16,13],
[21,20,16,14],
[21,20,16,15],
[21,20,17,0],
[21,20,17,1],
[21,20,17,2],
[21,20,17,4],
[21,20,17,7],
[21,20,17,9],
[21,20,17,11],
[21,20,17,12],
[21,20,17,13],
[21,20,17,14],
[21,20,17,15],
[21,20,17,16],
[21,20,18,0],
[21,20,18,1],
[21,20,18,2],
[21,20,18,4],
[21,20,18,7],
[21,20,18,11],
[21,20,18,12],
[21,20,18,13],
[21,20,18,14],
[21,20,18,16],
[21,20,18,17],
[21,20,19,0],
[21,20,19,1],
[21,20,19,2],
[21,20,19,4],
[21,20,19,7],
[21,20,19,9],
[21,20,19,11],
[21,20,19,12],
[21,20,19,15],
[21,20,19,16],
[21,20,19,17],
[21,20,19,18],
[22,5,4,0],
[22,5,4,1],
[22,5,4,2],
[22,6,4,0],
[22,6,4,1],
[22,6,4,2],
[22,7,3,0],
[22,7,3,1],
[22,7,3,2],
[22,7,4,0],
[22,7,4,1],
[22,7,4,2],
[22,7,4,3],
[22,7,5,0],
[22,7,5,1],
[22,7,5,2],
[22,7,5,4],
[22,7,6,0],
[22,7,6,1],
[22,7,6,2],
[22,7,6,4],
[22,8,4,0],
[22,8,4,1],
[22,8,4,2],
[22,8,7,0],
[22,8,7,1],
[22,8,7,2],
[22,8,7,4],
[22,9,3,0],
[22,9,3,1],
[22,9,3,2],
[22,9,4,0],
[22,9,4,1],
[22,9,4,2],
[22,9,4,3],
[22,9,5,0],
[22,9,5,1],
[22,9,5,2],
[22,9,5,4],
[22,9,6,0],
[22,9,6,1],
[22,9,6,2],
[22,9,6,4],
[22,9,7,0],
[22,9,7,1],
[22,9,7,2],
[22,9,7,3],
[22,9,7,4],
[22,9,7,5],
[22,9,7,6],
[22,9,8,0],
[22,9,8,1],
[22,9,8,2],
[22,9,8,4],
[22,9,8,7],
[22,11,3,0],
[22,11,3,1],
[22,11,3,2],
[22,11,4,0],
[22,11,4,1],
[22,11,4,2],
[22,11,4,3],
[22,11,5,0],
[22,11,5,1],
[22,11,5,2],
[22,11,5,4],
[22,11,6,0],
[22,11,6,1],
[22,11,6,2],
[22,11,6,4],
[22,11,7,0],
[22,11,7,1],
[22,11,7,2],
[22,11,7,3],
[22,11,7,4],
[22,11,7,5],
[22,11,7,6],
[22,11,8,0],
[22,11,8,1],
[22,11,8,2],
[22,11,8,4],
[22,11,8,7],
[22,11,9,0],
[22,11,9,1],
[22,11,9,2],
[22,11,9,3],
[22,11,9,4],
[22,11,9,5],
[22,11,9,6],
[22,11,9,7],
[22,11,9,8],
[22,12,3,0],
[22,12,3,1],
[22,12,3,2],
[22,12,4,0],
[22,12,4,1],
[22,12,4,2],
[22,12,4,3],
[22,12,5,0],
[22,12,5,1],
[22,12,5,2],
[22,12,5,4],
[22,12,6,0],
[22,12,6,1],
[22,12,6,2],
[22,12,6,4],
[22,12,7,0],
[22,12,7,1],
[22,12,7,2],
[22,12,7,3],
[22,12,7,4],
[22,12,7,5],
[22,12,7,6],
[22,12,8,0],
[22,12,8,1],
[22,12,8,2],
[22,12,8,4],
[22,12,8,7],
[22,12,9,0],
[22,12,9,1],
[22,12,9,2],
[22,12,9,3],
[22,12,9,4],
[22,12,9,5],
[22,12,9,6],
[22,12,9,7],
[22,12,9,8],
[22,13,3,0],
[22,13,3,1],
[22,13,3,2],
[22,13,4,0],
[22,13,4,1],
[22,13,4,2],
[22,13,4,3],
[22,13,5,0],
[22,13,5,1],
[22,13,5,2],
[22,13,5,4],
[22,13,6,0],
[22,13,6,1],
[22,13,6,2],
[22,13,6,4],
[22,13,7,0],
[22,13,7,1],
[22,13,7,2],
[22,13,7,3],
[22,13,7,4],
[22,13,7,5],
[22,13,7,6],
[22,13,8,0],
[22,13,8,1],
[22,13,8,2],
[22,13,8,4],
[22,13,8,7],
[22,13,9,0],
[22,13,9,1],
[22,13,9,2],
[22,13,9,3],
[22,13,9,4],
[22,13,9,5],
[22,13,9,6],
[22,13,9,7],
[22,13,9,8],
[22,13,11,0],
[22,13,11,1],
[22,13,11,2],
[22,13,11,3],
[22,13,11,4],
[22,13,11,5],
[22,13,11,6],
[22,13,11,7],
[22,13,11,8],
[22,13,11,9],
[22,13,12,0],
[22,13,12,1],
[22,13,12,2],
[22,13,12,3],
[22,13,12,4],
[22,13,12,5],
[22,13,12,6],
[22,13,12,7],
[22,13,12,8],
[22,13,12,9],
[22,14,3,0],
[22,14,3,1],
[22,14,3,2],
[22,14,4,0],
[22,14,4,1],
[22,14,4,2],
[22,14,4,3],
[22,14,5,0],
[22,14,5,1],
[22,14,5,2],
[22,14,5,4],
[22,14,6,0],
[22,14,6,1],
[22,14,6,2],
[22,14,6,4],
[22,14,7,0],
[22,14,7,1],
[22,14,7,2],
[22,14,7,3],
[22,14,7,4],
[22,14,7,5],
[22,14,7,6],
[22,14,8,0],
[22,14,8,1],
[22,14,8,2],
[22,14,8,4],
[22,14,8,7],
[22,14,9,0],
[22,14,9,1],
[22,14,9,2],
[22,14,9,3],
[22,14,9,4],
[22,14,9,5],
[22,14,9,6],
[22,14,9,7],
[22,14,9,8],
[22,14,11,0],
[22,14,11,1],
[22,14,11,2],
[22,14,11,3],
[22,14,11,4],
[22,14,11,5],
[22,14,11,6],
[22,14,11,7],
[22,14,11,8],
[22,14,11,9],
[22,14,12,0],
[22,14,12,1],
[22,14,12,2],
[22,14,12,3],
[22,14,12,4],
[22,14,12,5],
[22,14,12,6],
[22,14,12,7],
[22,14,12,8],
[22,14,12,9],
[22,15,3,0],
[22,15,3,1],
[22,15,3,2],
[22,15,4,0],
[22,15,4,1],
[22,15,4,2],
[22,15,4,3],
[22,15,5,0],
[22,15,5,1],
[22,15,5,2],
[22,15,5,4],
[22,15,6,0],
[22,15,6,1],
[22,15,6,2],
[22,15,6,4],
[22,15,7,0],
[22,15,7,1],
[22,15,7,2],
[22,15,7,3],
[22,15,7,4],
[22,15,7,5],
[22,15,7,6],
[22,15,8,0],
[22,15,8,1],
[22,15,8,2],
[22,15,8,4],
[22,15,8,7],
[22,15,11,0],
[22,15,11,1],
[22,15,11,2],
[22,15,11,3],
[22,15,11,4],
[22,15,11,5],
[22,15,11,6],
[22,15,11,7],
[22,15,11,8],
[22,15,12,0],
[22,15,12,1],
[22,15,12,2],
[22,15,12,3],
[22,15,12,4],
[22,15,12,5],
[22,15,12,6],
[22,15,12,7],
[22,15,12,8],
[22,15,13,0],
[22,15,13,1],
[22,15,13,2],
[22,15,13,3],
[22,15,13,4],
[22,15,13,5],
[22,15,13,6],
[22,15,13,7],
[22,15,13,8],
[22,15,13,11],
[22,15,13,12],
[22,15,14,0],
[22,15,14,1],
[22,15,14,2],
[22,15,14,3],
[22,15,14,4],
[22,15,14,5],
[22,15,14,6],
[22,15,14,7],
[22,15,14,8],
[22,15,14,11],
[22,15,14,12],
[22,16,3,0],
[22,16,3,1],
[22,16,3,2],
[22,16,4,0],
[22,16,4,1],
[22,16,4,2],
[22,16,4,3],
[22,16,5,0],
[22,16,5,1],
[22,16,5,2],
[22,16,5,4],
[22,16,6,0],
[22,16,6,1],
[22,16,6,2],
[22,16,6,4],
[22,16,7,0],
[22,16,7,1],
[22,16,7,2],
[22,16,7,3],
[22,16,7,4],
[22,16,7,5],
[22,16,7,6],
[22,16,8,0],
[22,16,8,1],
[22,16,8,2],
[22,16,8,4],
[22,16,8,7],
[22,16,9,0],
[22,16,9,1],
[22,16,9,2],
[22,16,9,3],
[22,16,9,4],
[22,16,9,5],
[22,16,9,6],
[22,16,9,7],
[22,16,9,8],
[22,16,11,0],
[22,16,11,1],
[22,16,11,2],
[22,16,11,3],
[22,16,11,4],
[22,16,11,5],
[22,16,11,6],
[22,16,11,7],
[22,16,11,8],
[22,16,11,9],
[22,16,12,0],
[22,16,12,1],
[22,16,12,2],
[22,16,12,3],
[22,16,12,4],
[22,16,12,5],
[22,16,12,6],
[22,16,12,7],
[22,16,12,8],
[22,16,12,9],
[22,16,13,0],
[22,16,13,1],
[22,16,13,2],
[22,16,13,3],
[22,16,13,4],
[22,16,13,5],
[22,16,13,6],
[22,16,13,7],
[22,16,13,8],
[22,16,13,9],
[22,16,13,11],
[22,16,13,12],
[22,16,14,0],
[22,16,14,1],
[22,16,14,2],
[22,16,14,3],
[22,16,14,4],
[22,16,14,5],
[22,16,14,6],
[22,16,14,7],
[22,16,14,8],
[22,16,14,9],
[22,16,14,11],
[22,16,14,12],
[22,16,15,0],
[22,16,15,1],
[22,16,15,2],
[22,16,15,3],
[22,16,15,4],
[22,16,15,5],
[22,16,15,6],
[22,16,15,7],
[22,16,15,8],
[22,16,15,11],
[22,16,15,12],
[22,16,15,13],
[22,16,15,14],
[22,17,3,0],
[22,17,3,1],
[22,17,3,2],
[22,17,4,0],
[22,17,4,1],
[22,17,4,2],
[22,17,4,3],
[22,17,5,0],
[22,17,5,1],
[22,17,5,2],
[22,17,5,4],
[22,17,6,0],
[22,17,6,1],
[22,17,6,2],
[22,17,6,4],
[22,17,7,0],
[22,17,7,1],
[22,17,7,2],
[22,17,7,3],
[22,17,7,4],
[22,17,7,5],
[22,17,7,6],
[22,17,8,0],
[22,17,8,1],
[22,17,8,2],
[22,17,8,4],
[22,17,8,7],
[22,17,9,0],
[22,17,9,1],
[22,17,9,2],
[22,17,9,3],
[22,17,9,4],
[22,17,9,5],
[22,17,9,6],
[22,17,9,7],
[22,17,9,8],
[22,17,11,0],
[22,17,11,1],
[22,17,11,2],
[22,17,11,3],
[22,17,11,4],
[22,17,11,5],
[22,17,11,6],
[22,17,11,7],
[22,17,11,8],
[22,17,11,9],
[22,17,12,0],
[22,17,12,1],
[22,17,12,2],
[22,17,12,3],
[22,17,12,4],
[22,17,12,5],
[22,17,12,6],
[22,17,12,7],
[22,17,12,8],
[22,17,12,9],
[22,17,13,0],
[22,17,13,1],
[22,17,13,2],
[22,17,13,3],
[22,17,13,4],
[22,17,13,5],
[22,17,13,6],
[22,17,13,7],
[22,17,13,8],
[22,17,13,9],
[22,17,13,11],
[22,17,13,12],
[22,17,14,0],
[22,17,14,1],
[22,17,14,2],
[22,17,14,3],
[22,17,14,4],
[22,17,14,5],
[22,17,14,6],
[22,17,14,7],
[22,17,14,8],
[22,17,14,9],
[22,17,14,11],
[22,17,14,12],
[22,17,15,0],
[22,17,15,1],
[22,17,15,2],
[22,17,15,3],
[22,17,15,4],
[22,17,15,5],
[22,17,15,6],
[22,17,15,7],
[22,17,15,8],
[22,17,15,11],
[22,17,15,12],
[22,17,15,13],
[22,17,15,14],
[22,17,16,0],
[22,17,16,1],
[22,17,16,2],
[22,17,16,3],
[22,17,16,4],
[22,17,16,5],
[22,17,16,6],
[22,17,16,7],
[22,17,16,8],
[22,17,16,9],
[22,17,16,11],
[22,17,16,12],
[22,17,16,13],
[22,17,16,14],
[22,17,16,15],
[22,18,3,0],
[22,18,3,1],
[22,18,3,2],
[22,18,4,0],
[22,18,4,1],
[22,18,4,2],
[22,18,4,3],
[22,18,5,0],
[22,18,5,1],
[22,18,5,2],
[22,18,5,4],
[22,18,6,0],
[22,18,6,1],
[22,18,6,2],
[22,18,6,4],
[22,18,7,0],
[22,18,7,1],
[22,18,7,2],
[22,18,7,3],
[22,18,7,4],
[22,18,7,5],
[22,18,7,6],
[22,18,8,0],
[22,18,8,1],
[22,18,8,2],
[22,18,8,4],
[22,18,8,7],
[22,18,11,0],
[22,18,11,1],
[22,18,11,2],
[22,18,11,3],
[22,18,11,4],
[22,18,11,5],
[22,18,11,6],
[22,18,11,7],
[22,18,11,8],
[22,18,12,0],
[22,18,12,1],
[22,18,12,2],
[22,18,12,3],
[22,18,12,4],
[22,18,12,5],
[22,18,12,6],
[22,18,12,7],
[22,18,12,8],
[22,18,13,0],
[22,18,13,1],
[22,18,13,2],
[22,18,13,3],
[22,18,13,4],
[22,18,13,5],
[22,18,13,6],
[22,18,13,7],
[22,18,13,8],
[22,18,13,11],
[22,18,13,12],
[22,18,14,0],
[22,18,14,1],
[22,18,14,2],
[22,18,14,3],
[22,18,14,4],
[22,18,14,5],
[22,18,14,6],
[22,18,14,7],
[22,18,14,8],
[22,18,14,11],
[22,18,14,12],
[22,18,16,0],
[22,18,16,1],
[22,18,16,2],
[22,18,16,3],
[22,18,16,4],
[22,18,16,5],
[22,18,16,6],
[22,18,16,7],
[22,18,16,8],
[22,18,16,11],
[22,18,16,12],
[22,18,16,13],
[22,18,16,14],
[22,18,17,0],
[22,18,17,1],
[22,18,17,2],
[22,18,17,3],
[22,18,17,4],
[22,18,17,5],
[22,18,17,6],
[22,18,17,7],
[22,18,17,8],
[22,18,17,11],
[22,18,17,12],
[22,18,17,13],
[22,18,17,14],
[22,18,17,16],
[22,19,3,0],
[22,19,3,1],
[22,19,3,2],
[22,19,4,0],
[22,19,4,1],
[22,19,4,2],
[22,19,4,3],
[22,19,5,0],
[22,19,5,1],
[22,19,5,2],
[22,19,5,4],
[22,19,6,0],
[22,19,6,1],
[22,19,6,2],
[22,19,6,4],
[22,19,7,0],
[22,19,7,1],
[22,19,7,2],
[22,19,7,3],
[22,19,7,4],
[22,19,7,5],
[22,19,7,6],
[22,19,8,0],
[22,19,8,1],
[22,19,8,2],
[22,19,8,4],
[22,19,8,7],
[22,19,9,0],
[22,19,9,1],
[22,19,9,2],
[22,19,9,3],
[22,19,9,4],
[22,19,9,5],
[22,19,9,6],
[22,19,9,7],
[22,19,9,8],
[22,19,11,0],
[22,19,11,1],
[22,19,11,2],
[22,19,11,3],
[22,19,11,4],
[22,19,11,5],
[22,19,11,6],
[22,19,11,7],
[22,19,11,8],
[22,19,11,9],
[22,19,12,0],
[22,19,12,1],
[22,19,12,2],
[22,19,12,3],
[22,19,12,4],
[22,19,12,5],
[22,19,12,6],
[22,19,12,7],
[22,19,12,8],
[22,19,12,9],
[22,19,15,0],
[22,19,15,1],
[22,19,15,2],
[22,19,15,3],
[22,19,15,4],
[22,19,15,5],
[22,19,15,6],
[22,19,15,7],
[22,19,15,8],
[22,19,15,11],
[22,19,15,12],
[22,19,16,0],
[22,19,16,1],
[22,19,16,2],
[22,19,16,3],
[22,19,16,4],
[22,19,16,5],
[22,19,16,6],
[22,19,16,7],
[22,19,16,8],
[22,19,16,9],
[22,19,16,11],
[22,19,16,12],
[22,19,16,15],
[22,19,17,0],
[22,19,17,1],
[22,19,17,2],
[22,19,17,3],
[22,19,17,4],
[22,19,17,5],
[22,19,17,6],
[22,19,17,7],
[22,19,17,8],
[22,19,17,9],
[22,19,17,11],
[22,19,17,12],
[22,19,17,15],
[22,19,17,16],
[22,19,18,0],
[22,19,18,1],
[22,19,18,2],
[22,19,18,3],
[22,19,18,4],
[22,19,18,5],
[22,19,18,6],
[22,19,18,7],
[22,19,18,8],
[22,19,18,11],
[22,19,18,12],
[22,19,18,16],
[22,19,18,17],
[22,21,4,0],
[22,21,4,1],
[22,21,4,2],
[22,21,7,0],
[22,21,7,1],
[22,21,7,2],
[22,21,7,4],
[22,21,9,0],
[22,21,9,1],
[22,21,9,2],
[22,21,9,4],
[22,21,9,7],
[22,21,11,0],
[22,21,11,1],
[22,21,11,2],
[22,21,11,4],
[22,21,11,7],
[22,21,11,9],
[22,21,12,0],
[22,21,12,1],
[22,21,12,2],
[22,21,12,4],
[22,21,12,7],
[22,21,12,9],
[22,21,13,0],
[22,21,13,1],
[22,21,13,2],
[22,21,13,4],
[22,21,13,7],
[22,21,13,9],
[22,21,13,11],
[22,21,13,12],
[22,21,14,0],
[22,21,14,1],
[22,21,14,2],
[22,21,14,4],
[22,21,14,7],
[22,21,14,9],
[22,21,14,11],
[22,21,14,12],
[22,21,15,0],
[22,21,15,1],
[22,21,15,2],
[22,21,15,4],
[22,21,15,7],
[22,21,15,11],
[22,21,15,12],
[22,21,15,13],
[22,21,15,14],
[22,21,16,0],
[22,21,16,1],
[22,21,16,2],
[22,21,16,4],
[22,21,16,7],
[22,21,16,9],
[22,21,16,11],
[22,21,16,12],
[22,21,16,13],
[22,21,16,14],
[22,21,16,15],
[22,21,17,0],
[22,21,17,1],
[22,21,17,2],
[22,21,17,4],
[22,21,17,7],
[22,21,17,9],
[22,21,17,11],
[22,21,17,12],
[22,21,17,13],
[22,21,17,14],
[22,21,17,15],
[22,21,17,16],
[22,21,18,0],
[22,21,18,1],
[22,21,18,2],
[22,21,18,4],
[22,21,18,7],
[22,21,18,11],
[22,21,18,12],
[22,21,18,13],
[22,21,18,14],
[22,21,18,16],
[22,21,18,17],
[22,21,19,0],
[22,21,19,1],
[22,21,19,2],
[22,21,19,4],
[22,21,19,7],
[22,21,19,9],
[22,21,19,11],
[22,21,19,12],
[22,21,19,15],
[22,21,19,16],
[22,21,19,17],
[22,21,19,18],
[23,4,3,0],
[23,4,3,1],
[23,4,3,2],
[23,5,4,0],
[23,5,4,1],
[23,5,4,2],
[23,6,4,0],
[23,6,4,1],
[23,6,4,2],
[23,7,3,0],
[23,7,3,1],
[23,7,3,2],
[23,7,4,0],
[23,7,4,1],
[23,7,4,2],
[23,7,4,3],
[23,7,5,0],
[23,7,5,1],
[23,7,5,2],
[23,7,5,4],
[23,7,6,0],
[23,7,6,1],
[23,7,6,2],
[23,7,6,4],
[23,8,4,0],
[23,8,4,1],
[23,8,4,2],
[23,8,7,0],
[23,8,7,1],
[23,8,7,2],
[23,8,7,4],
[23,9,3,0],
[23,9,3,1],
[23,9,3,2],
[23,9,4,0],
[23,9,4,1],
[23,9,4,2],
[23,9,4,3],
[23,9,5,0],
[23,9,5,1],
[23,9,5,2],
[23,9,5,4],
[23,9,6,0],
[23,9,6,1],
[23,9,6,2],
[23,9,6,4],
[23,9,7,0],
[23,9,7,1],
[23,9,7,2],
[23,9,7,3],
[23,9,7,4],
[23,9,7,5],
[23,9,7,6],
[23,9,8,0],
[23,9,8,1],
[23,9,8,2],
[23,9,8,4],
[23,9,8,7],
[23,10,3,0],
[23,10,3,1],
[23,10,3,2],
[23,10,4,0],
[23,10,4,1],
[23,10,4,2],
[23,10,4,3],
[23,10,5,0],
[23,10,5,1],
[23,10,5,2],
[23,10,5,4],
[23,10,6,0],
[23,10,6,1],
[23,10,6,2],
[23,10,6,4],
[23,10,7,0],
[23,10,7,1],
[23,10,7,2],
[23,10,7,3],
[23,10,7,4],
[23,10,7,5],
[23,10,7,6],
[23,10,8,0],
[23,10,8,1],
[23,10,8,2],
[23,10,8,4],
[23,10,8,7],
[23,10,9,0],
[23,10,9,1],
[23,10,9,2],
[23,10,9,3],
[23,10,9,4],
[23,10,9,5],
[23,10,9,6],
[23,10,9,7],
[23,10,9,8],
[23,11,3,0],
[23,11,3,1],
[23,11,3,2],
[23,11,4,0],
[23,11,4,1],
[23,11,4,2],
[23,11,4,3],
[23,11,5,0],
[23,11,5,1],
[23,11,5,2],
[23,11,5,4],
[23,11,6,0],
[23,11,6,1],
[23,11,6,2],
[23,11,6,4],
[23,11,7,0],
[23,11,7,1],
[23,11,7,2],
[23,11,7,3],
[23,11,7,4],
[23,11,7,5],
[23,11,7,6],
[23,11,8,0],
[23,11,8,1],
[23,11,8,2],
[23,11,8,4],
[23,11,8,7],
[23,11,9,0],
[23,11,9,1],
[23,11,9,2],
[23,11,9,3],
[23,11,9,4],
[23,11,9,5],
[23,11,9,6],
[23,11,9,7],
[23,11,9,8],
[23,11,10,0],
[23,11,10,1],
[23,11,10,2],
[23,11,10,3],
[23,11,10,4],
[23,11,10,5],
[23,11,10,6],
[23,11,10,7],
[23,11,10,8],
[23,11,10,9],
[23,12,3,0],
[23,12,3,1],
[23,12,3,2],
[23,12,4,0],
[23,12,4,1],
[23,12,4,2],
[23,12,4,3],
[23,12,5,0],
[23,12,5,1],
[23,12,5,2],
[23,12,5,4],
[23,12,6,0],
[23,12,6,1],
[23,12,6,2],
[23,12,6,4],
[23,12,7,0],
[23,12,7,1],
[23,12,7,2],
[23,12,7,3],
[23,12,7,4],
[23,12,7,5],
[23,12,7,6],
[23,12,8,0],
[23,12,8,1],
[23,12,8,2],
[23,12,8,4],
[23,12,8,7],
[23,12,9,0],
[23,12,9,1],
[23,12,9,2],
[23,12,9,3],
[23,12,9,4],
[23,12,9,5],
[23,12,9,6],
[23,12,9,7],
[23,12,9,8],
[23,12,10,0],
[23,12,10,1],
[23,12,10,2],
[23,12,10,3],
[23,12,10,4],
[23,12,10,5],
[23,12,10,6],
[23,12,10,7],
[23,12,10,8],
[23,12,10,9],
[23,13,3,0],
[23,13,3,1],
[23,13,3,2],
[23,13,4,0],
[23,13,4,1],
[23,13,4,2],
[23,13,4,3],
[23,13,5,0],
[23,13,5,1],
[23,13,5,2],
[23,13,5,4],
[23,13,6,0],
[23,13,6,1],
[23,13,6,2],
[23,13,6,4],
[23,13,7,0],
[23,13,7,1],
[23,13,7,2],
[23,13,7,3],
[23,13,7,4],
[23,13,7,5],
[23,13,7,6],
[23,13,8,0],
[23,13,8,1],
[23,13,8,2],
[23,13,8,4],
[23,13,8,7],
[23,13,9,0],
[23,13,9,1],
[23,13,9,2],
[23,13,9,3],
[23,13,9,4],
[23,13,9,5],
[23,13,9,6],
[23,13,9,7],
[23,13,9,8],
[23,13,10,0],
[23,13,10,1],
[23,13,10,2],
[23,13,10,3],
[23,13,10,4],
[23,13,10,5],
[23,13,10,6],
[23,13,10,7],
[23,13,10,8],
[23,13,10,9],
[23,13,11,0],
[23,13,11,1],
[23,13,11,2],
[23,13,11,3],
[23,13,11,4],
[23,13,11,5],
[23,13,11,6],
[23,13,11,7],
[23,13,11,8],
[23,13,11,9],
[23,13,11,10],
[23,13,12,0],
[23,13,12,1],
[23,13,12,2],
[23,13,12,3],
[23,13,12,4],
[23,13,12,5],
[23,13,12,6],
[23,13,12,7],
[23,13,12,8],
[23,13,12,9],
[23,13,12,10],
[23,14,3,0],
[23,14,3,1],
[23,14,3,2],
[23,14,4,0],
[23,14,4,1],
[23,14,4,2],
[23,14,4,3],
[23,14,5,0],
[23,14,5,1],
[23,14,5,2],
[23,14,5,4],
[23,14,6,0],
[23,14,6,1],
[23,14,6,2],
[23,14,6,4],
[23,14,7,0],
[23,14,7,1],
[23,14,7,2],
[23,14,7,3],
[23,14,7,4],
[23,14,7,5],
[23,14,7,6],
[23,14,8,0],
[23,14,8,1],
[23,14,8,2],
[23,14,8,4],
[23,14,8,7],
[23,14,9,0],
[23,14,9,1],
[23,14,9,2],
[23,14,9,3],
[23,14,9,4],
[23,14,9,5],
[23,14,9,6],
[23,14,9,7],
[23,14,9,8],
[23,14,10,0],
[23,14,10,1],
[23,14,10,2],
[23,14,10,3],
[23,14,10,4],
[23,14,10,5],
[23,14,10,6],
[23,14,10,7],
[23,14,10,8],
[23,14,10,9],
[23,14,11,0],
[23,14,11,1],
[23,14,11,2],
[23,14,11,3],
[23,14,11,4],
[23,14,11,5],
[23,14,11,6],
[23,14,11,7],
[23,14,11,8],
[23,14,11,9],
[23,14,11,10],
[23,14,12,0],
[23,14,12,1],
[23,14,12,2],
[23,14,12,3],
[23,14,12,4],
[23,14,12,5],
[23,14,12,6],
[23,14,12,7],
[23,14,12,8],
[23,14,12,9],
[23,14,12,10],
[23,15,3,0],
[23,15,3,1],
[23,15,3,2],
[23,15,4,0],
[23,15,4,1],
[23,15,4,2],
[23,15,4,3],
[23,15,5,0],
[23,15,5,1],
[23,15,5,2],
[23,15,5,4],
[23,15,6,0],
[23,15,6,1],
[23,15,6,2],
[23,15,6,4],
[23,15,7,0],
[23,15,7,1],
[23,15,7,2],
[23,15,7,3],
[23,15,7,4],
[23,15,7,5],
[23,15,7,6],
[23,15,8,0],
[23,15,8,1],
[23,15,8,2],
[23,15,8,4],
[23,15,8,7],
[23,15,10,0],
[23,15,10,1],
[23,15,10,2],
[23,15,10,3],
[23,15,10,4],
[23,15,10,5],
[23,15,10,6],
[23,15,10,7],
[23,15,10,8],
[23,15,11,0],
[23,15,11,1],
[23,15,11,2],
[23,15,11,3],
[23,15,11,4],
[23,15,11,5],
[23,15,11,6],
[23,15,11,7],
[23,15,11,8],
[23,15,11,10],
[23,15,12,0],
[23,15,12,1],
[23,15,12,2],
[23,15,12,3],
[23,15,12,4],
[23,15,12,5],
[23,15,12,6],
[23,15,12,7],
[23,15,12,8],
[23,15,12,10],
[23,15,13,0],
[23,15,13,1],
[23,15,13,2],
[23,15,13,3],
[23,15,13,4],
[23,15,13,5],
[23,15,13,6],
[23,15,13,7],
[23,15,13,8],
[23,15,13,10],
[23,15,13,11],
[23,15,13,12],
[23,15,14,0],
[23,15,14,1],
[23,15,14,2],
[23,15,14,3],
[23,15,14,4],
[23,15,14,5],
[23,15,14,6],
[23,15,14,7],
[23,15,14,8],
[23,15,14,10],
[23,15,14,11],
[23,15,14,12],
[23,16,3,0],
[23,16,3,1],
[23,16,3,2],
[23,16,4,0],
[23,16,4,1],
[23,16,4,2],
[23,16,4,3],
[23,16,5,0],
[23,16,5,1],
[23,16,5,2],
[23,16,5,4],
[23,16,6,0],
[23,16,6,1],
[23,16,6,2],
[23,16,6,4],
[23,16,7,0],
[23,16,7,1],
[23,16,7,2],
[23,16,7,3],
[23,16,7,4],
[23,16,7,5],
[23,16,7,6],
[23,16,8,0],
[23,16,8,1],
[23,16,8,2],
[23,16,8,4],
[23,16,8,7],
[23,16,9,0],
[23,16,9,1],
[23,16,9,2],
[23,16,9,3],
[23,16,9,4],
[23,16,9,5],
[23,16,9,6],
[23,16,9,7],
[23,16,9,8],
[23,16,10,0],
[23,16,10,1],
[23,16,10,2],
[23,16,10,3],
[23,16,10,4],
[23,16,10,5],
[23,16,10,6],
[23,16,10,7],
[23,16,10,8],
[23,16,10,9],
[23,16,11,0],
[23,16,11,1],
[23,16,11,2],
[23,16,11,3],
[23,16,11,4],
[23,16,11,5],
[23,16,11,6],
[23,16,11,7],
[23,16,11,8],
[23,16,11,9],
[23,16,11,10],
[23,16,12,0],
[23,16,12,1],
[23,16,12,2],
[23,16,12,3],
[23,16,12,4],
[23,16,12,5],
[23,16,12,6],
[23,16,12,7],
[23,16,12,8],
[23,16,12,9],
[23,16,12,10],
[23,16,13,0],
[23,16,13,1],
[23,16,13,2],
[23,16,13,3],
[23,16,13,4],
[23,16,13,5],
[23,16,13,6],
[23,16,13,7],
[23,16,13,8],
[23,16,13,9],
[23,16,13,10],
[23,16,13,11],
[23,16,13,12],
[23,16,14,0],
[23,16,14,1],
[23,16,14,2],
[23,16,14,3],
[23,16,14,4],
[23,16,14,5],
[23,16,14,6],
[23,16,14,7],
[23,16,14,8],
[23,16,14,9],
[23,16,14,10],
[23,16,14,11],
[23,16,14,12],
[23,16,15,0],
[23,16,15,1],
[23,16,15,2],
[23,16,15,3],
[23,16,15,4],
[23,16,15,5],
[23,16,15,6],
[23,16,15,7],
[23,16,15,8],
[23,16,15,10],
[23,16,15,11],
[23,16,15,12],
[23,16,15,13],
[23,16,15,14],
[23,17,3,0],
[23,17,3,1],
[23,17,3,2],
[23,17,4,0],
[23,17,4,1],
[23,17,4,2],
[23,17,4,3],
[23,17,5,0],
[23,17,5,1],
[23,17,5,2],
[23,17,5,4],
[23,17,6,0],
[23,17,6,1],
[23,17,6,2],
[23,17,6,4],
[23,17,7,0],
[23,17,7,1],
[23,17,7,2],
[23,17,7,3],
[23,17,7,4],
[23,17,7,5],
[23,17,7,6],
[23,17,8,0],
[23,17,8,1],
[23,17,8,2],
[23,17,8,4],
[23,17,8,7],
[23,17,9,0],
[23,17,9,1],
[23,17,9,2],
[23,17,9,3],
[23,17,9,4],
[23,17,9,5],
[23,17,9,6],
[23,17,9,7],
[23,17,9,8],
[23,17,10,0],
[23,17,10,1],
[23,17,10,2],
[23,17,10,3],
[23,17,10,4],
[23,17,10,5],
[23,17,10,6],
[23,17,10,7],
[23,17,10,8],
[23,17,10,9],
[23,17,11,0],
[23,17,11,1],
[23,17,11,2],
[23,17,11,3],
[23,17,11,4],
[23,17,11,5],
[23,17,11,6],
[23,17,11,7],
[23,17,11,8],
[23,17,11,9],
[23,17,11,10],
[23,17,12,0],
[23,17,12,1],
[23,17,12,2],
[23,17,12,3],
[23,17,12,4],
[23,17,12,5],
[23,17,12,6],
[23,17,12,7],
[23,17,12,8],
[23,17,12,9],
[23,17,12,10],
[23,17,13,0],
[23,17,13,1],
[23,17,13,2],
[23,17,13,3],
[23,17,13,4],
[23,17,13,5],
[23,17,13,6],
[23,17,13,7],
[23,17,13,8],
[23,17,13,9],
[23,17,13,10],
[23,17,13,11],
[23,17,13,12],
[23,17,14,0],
[23,17,14,1],
[23,17,14,2],
[23,17,14,3],
[23,17,14,4],
[23,17,14,5],
[23,17,14,6],
[23,17,14,7],
[23,17,14,8],
[23,17,14,9],
[23,17,14,10],
[23,17,14,11],
[23,17,14,12],
[23,17,15,0],
[23,17,15,1],
[23,17,15,2],
[23,17,15,3],
[23,17,15,4],
[23,17,15,5],
[23,17,15,6],
[23,17,15,7],
[23,17,15,8],
[23,17,15,10],
[23,17,15,11],
[23,17,15,12],
[23,17,15,13],
[23,17,15,14],
[23,17,16,0],
[23,17,16,1],
[23,17,16,2],
[23,17,16,3],
[23,17,16,4],
[23,17,16,5],
[23,17,16,6],
[23,17,16,7],
[23,17,16,8],
[23,17,16,9],
[23,17,16,10],
[23,17,16,11],
[23,17,16,12],
[23,17,16,13],
[23,17,16,14],
[23,17,16,15],
[23,18,3,0],
[23,18,3,1],
[23,18,3,2],
[23,18,4,0],
[23,18,4,1],
[23,18,4,2],
[23,18,4,3],
[23,18,5,0],
[23,18,5,1],
[23,18,5,2],
[23,18,5,4],
[23,18,6,0],
[23,18,6,1],
[23,18,6,2],
[23,18,6,4],
[23,18,7,0],
[23,18,7,1],
[23,18,7,2],
[23,18,7,3],
[23,18,7,4],
[23,18,7,5],
[23,18,7,6],
[23,18,8,0],
[23,18,8,1],
[23,18,8,2],
[23,18,8,4],
[23,18,8,7],
[23,18,10,0],
[23,18,10,1],
[23,18,10,2],
[23,18,10,3],
[23,18,10,4],
[23,18,10,5],
[23,18,10,6],
[23,18,10,7],
[23,18,10,8],
[23,18,11,0],
[23,18,11,1],
[23,18,11,2],
[23,18,11,3],
[23,18,11,4],
[23,18,11,5],
[23,18,11,6],
[23,18,11,7],
[23,18,11,8],
[23,18,11,10],
[23,18,12,0],
[23,18,12,1],
[23,18,12,2],
[23,18,12,3],
[23,18,12,4],
[23,18,12,5],
[23,18,12,6],
[23,18,12,7],
[23,18,12,8],
[23,18,12,10],
[23,18,13,0],
[23,18,13,1],
[23,18,13,2],
[23,18,13,3],
[23,18,13,4],
[23,18,13,5],
[23,18,13,6],
[23,18,13,7],
[23,18,13,8],
[23,18,13,10],
[23,18,13,11],
[23,18,13,12],
[23,18,14,0],
[23,18,14,1],
[23,18,14,2],
[23,18,14,3],
[23,18,14,4],
[23,18,14,5],
[23,18,14,6],
[23,18,14,7],
[23,18,14,8],
[23,18,14,10],
[23,18,14,11],
[23,18,14,12],
[23,18,16,0],
[23,18,16,1],
[23,18,16,2],
[23,18,16,3],
[23,18,16,4],
[23,18,16,5],
[23,18,16,6],
[23,18,16,7],
[23,18,16,8],
[23,18,16,10],
[23,18,16,11],
[23,18,16,12],
[23,18,16,13],
[23,18,16,14],
[23,18,17,0],
[23,18,17,1],
[23,18,17,2],
[23,18,17,3],
[23,18,17,4],
[23,18,17,5],
[23,18,17,6],
[23,18,17,7],
[23,18,17,8],
[23,18,17,10],
[23,18,17,11],
[23,18,17,12],
[23,18,17,13],
[23,18,17,14],
[23,18,17,16],
[23,19,3,0],
[23,19,3,1],
[23,19,3,2],
[23,19,4,0],
[23,19,4,1],
[23,19,4,2],
[23,19,4,3],
[23,19,5,0],
[23,19,5,1],
[23,19,5,2],
[23,19,5,4],
[23,19,6,0],
[23,19,6,1],
[23,19,6,2],
[23,19,6,4],
[23,19,7,0],
[23,19,7,1],
[23,19,7,2],
[23,19,7,3],
[23,19,7,4],
[23,19,7,5],
[23,19,7,6],
[23,19,8,0],
[23,19,8,1],
[23,19,8,2],
[23,19,8,4],
[23,19,8,7],
[23,19,9,0],
[23,19,9,1],
[23,19,9,2],
[23,19,9,3],
[23,19,9,4],
[23,19,9,5],
[23,19,9,6],
[23,19,9,7],
[23,19,9,8],
[23,19,10,0],
[23,19,10,1],
[23,19,10,2],
[23,19,10,3],
[23,19,10,4],
[23,19,10,5],
[23,19,10,6],
[23,19,10,7],
[23,19,10,8],
[23,19,10,9],
[23,19,11,0],
[23,19,11,1],
[23,19,11,2],
[23,19,11,3],
[23,19,11,4],
[23,19,11,5],
[23,19,11,6],
[23,19,11,7],
[23,19,11,8],
[23,19,11,9],
[23,19,11,10],
[23,19,12,0],
[23,19,12,1],
[23,19,12,2],
[23,19,12,3],
[23,19,12,4],
[23,19,12,5],
[23,19,12,6],
[23,19,12,7],
[23,19,12,8],
[23,19,12,9],
[23,19,12,10],
[23,19,15,0],
[23,19,15,1],
[23,19,15,2],
[23,19,15,3],
[23,19,15,4],
[23,19,15,5],
[23,19,15,6],
[23,19,15,7],
[23,19,15,8],
[23,19,15,10],
[23,19,15,11],
[23,19,15,12],
[23,19,16,0],
[23,19,16,1],
[23,19,16,2],
[23,19,16,3],
[23,19,16,4],
[23,19,16,5],
[23,19,16,6],
[23,19,16,7],
[23,19,16,8],
[23,19,16,9],
[23,19,16,10],
[23,19,16,11],
[23,19,16,12],
[23,19,16,15],
[23,19,17,0],
[23,19,17,1],
[23,19,17,2],
[23,19,17,3],
[23,19,17,4],
[23,19,17,5],
[23,19,17,6],
[23,19,17,7],
[23,19,17,8],
[23,19,17,9],
[23,19,17,10],
[23,19,17,11],
[23,19,17,12],
[23,19,17,15],
[23,19,17,16],
[23,19,18,0],
[23,19,18,1],
[23,19,18,2],
[23,19,18,3],
[23,19,18,4],
[23,19,18,5],
[23,19,18,6],
[23,19,18,7],
[23,19,18,8],
[23,19,18,10],
[23,19,18,11],
[23,19,18,12],
[23,19,18,16],
[23,19,18,17],
[23,20,3,0],
[23,20,3,1],
[23,20,3,2],
[23,20,4,0],
[23,20,4,1],
[23,20,4,2],
[23,20,4,3],
[23,20,5,0],
[23,20,5,1],
[23,20,5,2],
[23,20,5,4],
[23,20,6,0],
[23,20,6,1],
[23,20,6,2],
[23,20,6,4],
[23,20,7,0],
[23,20,7,1],
[23,20,7,2],
[23,20,7,3],
[23,20,7,4],
[23,20,7,5],
[23,20,7,6],
[23,20,8,0],
[23,20,8,1],
[23,20,8,2],
[23,20,8,4],
[23,20,8,7],
[23,20,9,0],
[23,20,9,1],
[23,20,9,2],
[23,20,9,3],
[23,20,9,4],
[23,20,9,5],
[23,20,9,6],
[23,20,9,7],
[23,20,9,8],
[23,20,11,0],
[23,20,11,1],
[23,20,11,2],
[23,20,11,3],
[23,20,11,4],
[23,20,11,5],
[23,20,11,6],
[23,20,11,7],
[23,20,11,8],
[23,20,11,9],
[23,20,12,0],
[23,20,12,1],
[23,20,12,2],
[23,20,12,3],
[23,20,12,4],
[23,20,12,5],
[23,20,12,6],
[23,20,12,7],
[23,20,12,8],
[23,20,12,9],
[23,20,13,0],
[23,20,13,1],
[23,20,13,2],
[23,20,13,3],
[23,20,13,4],
[23,20,13,5],
[23,20,13,6],
[23,20,13,7],
[23,20,13,8],
[23,20,13,9],
[23,20,13,11],
[23,20,13,12],
[23,20,14,0],
[23,20,14,1],
[23,20,14,2],
[23,20,14,3],
[23,20,14,4],
[23,20,14,5],
[23,20,14,6],
[23,20,14,7],
[23,20,14,8],
[23,20,14,9],
[23,20,14,11],
[23,20,14,12],
[23,20,15,0],
[23,20,15,1],
[23,20,15,2],
[23,20,15,3],
[23,20,15,4],
[23,20,15,5],
[23,20,15,6],
[23,20,15,7],
[23,20,15,8],
[23,20,15,11],
[23,20,15,12],
[23,20,15,13],
[23,20,15,14],
[23,20,16,0],
[23,20,16,1],
[23,20,16,2],
[23,20,16,3],
[23,20,16,4],
[23,20,16,5],
[23,20,16,6],
[23,20,16,7],
[23,20,16,8],
[23,20,16,9],
[23,20,16,11],
[23,20,16,12],
[23,20,16,13],
[23,20,16,14],
[23,20,16,15],
[23,20,17,0],
[23,20,17,1],
[23,20,17,2],
[23,20,17,3],
[23,20,17,4],
[23,20,17,5],
[23,20,17,6],
[23,20,17,7],
[23,20,17,8],
[23,20,17,9],
[23,20,17,11],
[23,20,17,12],
[23,20,17,13],
[23,20,17,14],
[23,20,17,15],
[23,20,17,16],
[23,20,18,0],
[23,20,18,1],
[23,20,18,2],
[23,20,18,3],
[23,20,18,4],
[23,20,18,5],
[23,20,18,6],
[23,20,18,7],
[23,20,18,8],
[23,20,18,11],
[23,20,18,12],
[23,20,18,13],
[23,20,18,14],
[23,20,18,16],
[23,20,18,17],
[23,20,19,0],
[23,20,19,1],
[23,20,19,2],
[23,20,19,3],
[23,20,19,4],
[23,20,19,5],
[23,20,19,6],
[23,20,19,7],
[23,20,19,8],
[23,20,19,9],
[23,20,19,11],
[23,20,19,12],
[23,20,19,15],
[23,20,19,16],
[23,20,19,17],
[23,20,19,18],
[23,21,4,0],
[23,21,4,1],
[23,21,4,2],
[23,21,7,0],
[23,21,7,1],
[23,21,7,2],
[23,21,7,4],
[23,21,9,0],
[23,21,9,1],
[23,21,9,2],
[23,21,9,4],
[23,21,9,7],
[23,21,10,0],
[23,21,10,1],
[23,21,10,2],
[23,21,10,4],
[23,21,10,7],
[23,21,10,9],
[23,21,11,0],
[23,21,11,1],
[23,21,11,2],
[23,21,11,4],
[23,21,11,7],
[23,21,11,9],
[23,21,11,10],
[23,21,12,0],
[23,21,12,1],
[23,21,12,2],
[23,21,12,4],
[23,21,12,7],
[23,21,12,9],
[23,21,12,10],
[23,21,13,0],
[23,21,13,1],
[23,21,13,2],
[23,21,13,4],
[23,21,13,7],
[23,21,13,9],
[23,21,13,10],
[23,21,13,11],
[23,21,13,12],
[23,21,14,0],
[23,21,14,1],
[23,21,14,2],
[23,21,14,4],
[23,21,14,7],
[23,21,14,9],
[23,21,14,10],
[23,21,14,11],
[23,21,14,12],
[23,21,15,0],
[23,21,15,1],
[23,21,15,2],
[23,21,15,4],
[23,21,15,7],
[23,21,15,10],
[23,21,15,11],
[23,21,15,12],
[23,21,15,13],
[23,21,15,14],
[23,21,16,0],
[23,21,16,1],
[23,21,16,2],
[23,21,16,4],
[23,21,16,7],
[23,21,16,9],
[23,21,16,10],
[23,21,16,11],
[23,21,16,12],
[23,21,16,13],
[23,21,16,14],
[23,21,16,15],
[23,21,17,0],
[23,21,17,1],
[23,21,17,2],
[23,21,17,4],
[23,21,17,7],
[23,21,17,9],
[23,21,17,10],
[23,21,17,11],
[23,21,17,12],
[23,21,17,13],
[23,21,17,14],
[23,21,17,15],
[23,21,17,16],
[23,21,18,0],
[23,21,18,1],
[23,21,18,2],
[23,21,18,4],
[23,21,18,7],
[23,21,18,10],
[23,21,18,11],
[23,21,18,12],
[23,21,18,13],
[23,21,18,14],
[23,21,18,16],
[23,21,18,17],
[23,21,19,0],
[23,21,19,1],
[23,21,19,2],
[23,21,19,4],
[23,21,19,7],
[23,21,19,9],
[23,21,19,10],
[23,21,19,11],
[23,21,19,12],
[23,21,19,15],
[23,21,19,16],
[23,21,19,17],
[23,21,19,18],
[23,21,20,0],
[23,21,20,1],
[23,21,20,2],
[23,21,20,4],
[23,21,20,7],
[23,21,20,9],
[23,21,20,11],
[23,21,20,12],
[23,21,20,13],
[23,21,20,14],
[23,21,20,15],
[23,21,20,16],
[23,21,20,17],
[23,21,20,18],
[23,21,20,19],
[23,22,3,0],
[23,22,3,1],
[23,22,3,2],
[23,22,4,0],
[23,22,4,1],
[23,22,4,2],
[23,22,4,3],
[23,22,5,0],
[23,22,5,1],
[23,22,5,2],
[23,22,5,4],
[23,22,6,0],
[23,22,6,1],
[23,22,6,2],
[23,22,6,4],
[23,22,7,0],
[23,22,7,1],
[23,22,7,2],
[23,22,7,3],
[23,22,7,4],
[23,22,7,5],
[23,22,7,6],
[23,22,8,0],
[23,22,8,1],
[23,22,8,2],
[23,22,8,4],
[23,22,8,7],
[23,22,9,0],
[23,22,9,1],
[23,22,9,2],
[23,22,9,3],
[23,22,9,4],
[23,22,9,5],
[23,22,9,6],
[23,22,9,7],
[23,22,9,8],
[23,22,11,0],
[23,22,11,1],
[23,22,11,2],
[23,22,11,3],
[23,22,11,4],
[23,22,11,5],
[23,22,11,6],
[23,22,11,7],
[23,22,11,8],
[23,22,11,9],
[23,22,12,0],
[23,22,12,1],
[23,22,12,2],
[23,22,12,3],
[23,22,12,4],
[23,22,12,5],
[23,22,12,6],
[23,22,12,7],
[23,22,12,8],
[23,22,12,9],
[23,22,13,0],
[23,22,13,1],
[23,22,13,2],
[23,22,13,3],
[23,22,13,4],
[23,22,13,5],
[23,22,13,6],
[23,22,13,7],
[23,22,13,8],
[23,22,13,9],
[23,22,13,11],
[23,22,13,12],
[23,22,14,0],
[23,22,14,1],
[23,22,14,2],
[23,22,14,3],
[23,22,14,4],
[23,22,14,5],
[23,22,14,6],
[23,22,14,7],
[23,22,14,8],
[23,22,14,9],
[23,22,14,11],
[23,22,14,12],
[23,22,15,0],
[23,22,15,1],
[23,22,15,2],
[23,22,15,3],
[23,22,15,4],
[23,22,15,5],
[23,22,15,6],
[23,22,15,7],
[23,22,15,8],
[23,22,15,11],
[23,22,15,12],
[23,22,15,13],
[23,22,15,14],
[23,22,16,0],
[23,22,16,1],
[23,22,16,2],
[23,22,16,3],
[23,22,16,4],
[23,22,16,5],
[23,22,16,6],
[23,22,16,7],
[23,22,16,8],
[23,22,16,9],
[23,22,16,11],
[23,22,16,12],
[23,22,16,13],
[23,22,16,14],
[23,22,16,15],
[23,22,17,0],
[23,22,17,1],
[23,22,17,2],
[23,22,17,3],
[23,22,17,4],
[23,22,17,5],
[23,22,17,6],
[23,22,17,7],
[23,22,17,8],
[23,22,17,9],
[23,22,17,11],
[23,22,17,12],
[23,22,17,13],
[23,22,17,14],
[23,22,17,15],
[23,22,17,16],
[23,22,18,0],
[23,22,18,1],
[23,22,18,2],
[23,22,18,3],
[23,22,18,4],
[23,22,18,5],
[23,22,18,6],
[23,22,18,7],
[23,22,18,8],
[23,22,18,11],
[23,22,18,12],
[23,22,18,13],
[23,22,18,14],
[23,22,18,16],
[23,22,18,17],
[23,22,19,0],
[23,22,19,1],
[23,22,19,2],
[23,22,19,3],
[23,22,19,4],
[23,22,19,5],
[23,22,19,6],
[23,22,19,7],
[23,22,19,8],
[23,22,19,9],
[23,22,19,11],
[23,22,19,12],
[23,22,19,15],
[23,22,19,16],
[23,22,19,17],
[23,22,19,18],
[23,22,21,0],
[23,22,21,1],
[23,22,21,2],
[23,22,21,4],
[23,22,21,7],
[23,22,21,9],
[23,22,21,11],
[23,22,21,12],
[23,22,21,13],
[23,22,21,14],
[23,22,21,15],
[23,22,21,16],
[23,22,21,17],
[23,22,21,18],
[23,22,21,19],
[24,4,3,0],
[24,4,3,1],
[24,4,3,2],
[24,5,4,0],
[24,5,4,1],
[24,5,4,2],
[24,6,4,0],
[24,6,4,1],
[24,6,4,2],
[24,7,3,0],
[24,7,3,1],
[24,7,3,2],
[24,7,4,0],
[24,7,4,1],
[24,7,4,2],
[24,7,4,3],
[24,7,5,0],
[24,7,5,1],
[24,7,5,2],
[24,7,5,4],
[24,7,6,0],
[24,7,6,1],
[24,7,6,2],
[24,7,6,4],
[24,8,4,0],
[24,8,4,1],
[24,8,4,2],
[24,8,7,0],
[24,8,7,1],
[24,8,7,2],
[24,8,7,4],
[24,9,3,0],
[24,9,3,1],
[24,9,3,2],
[24,9,4,0],
[24,9,4,1],
[24,9,4,2],
[24,9,4,3],
[24,9,5,0],
[24,9,5,1],
[24,9,5,2],
[24,9,5,4],
[24,9,6,0],
[24,9,6,1],
[24,9,6,2],
[24,9,6,4],
[24,9,7,0],
[24,9,7,1],
[24,9,7,2],
[24,9,7,3],
[24,9,7,4],
[24,9,7,5],
[24,9,7,6],
[24,9,8,0],
[24,9,8,1],
[24,9,8,2],
[24,9,8,4],
[24,9,8,7],
[24,10,3,0],
[24,10,3,1],
[24,10,3,2],
[24,10,4,0],
[24,10,4,1],
[24,10,4,2],
[24,10,4,3],
[24,10,5,0],
[24,10,5,1],
[24,10,5,2],
[24,10,5,4],
[24,10,6,0],
[24,10,6,1],
[24,10,6,2],
[24,10,6,4],
[24,10,7,0],
[24,10,7,1],
[24,10,7,2],
[24,10,7,3],
[24,10,7,4],
[24,10,7,5],
[24,10,7,6],
[24,10,8,0],
[24,10,8,1],
[24,10,8,2],
[24,10,8,4],
[24,10,8,7],
[24,10,9,0],
[24,10,9,1],
[24,10,9,2],
[24,10,9,3],
[24,10,9,4],
[24,10,9,5],
[24,10,9,6],
[24,10,9,7],
[24,10,9,8],
[24,11,3,0],
[24,11,3,1],
[24,11,3,2],
[24,11,4,0],
[24,11,4,1],
[24,11,4,2],
[24,11,4,3],
[24,11,5,0],
[24,11,5,1],
[24,11,5,2],
[24,11,5,4],
[24,11,6,0],
[24,11,6,1],
[24,11,6,2],
[24,11,6,4],
[24,11,7,0],
[24,11,7,1],
[24,11,7,2],
[24,11,7,3],
[24,11,7,4],
[24,11,7,5],
[24,11,7,6],
[24,11,8,0],
[24,11,8,1],
[24,11,8,2],
[24,11,8,4],
[24,11,8,7],
[24,11,9,0],
[24,11,9,1],
[24,11,9,2],
[24,11,9,3],
[24,11,9,4],
[24,11,9,5],
[24,11,9,6],
[24,11,9,7],
[24,11,9,8],
[24,11,10,0],
[24,11,10,1],
[24,11,10,2],
[24,11,10,3],
[24,11,10,4],
[24,11,10,5],
[24,11,10,6],
[24,11,10,7],
[24,11,10,8],
[24,11,10,9],
[24,12,3,0],
[24,12,3,1],
[24,12,3,2],
[24,12,4,0],
[24,12,4,1],
[24,12,4,2],
[24,12,4,3],
[24,12,5,0],
[24,12,5,1],
[24,12,5,2],
[24,12,5,4],
[24,12,6,0],
[24,12,6,1],
[24,12,6,2],
[24,12,6,4],
[24,12,7,0],
[24,12,7,1],
[24,12,7,2],
[24,12,7,3],
[24,12,7,4],
[24,12,7,5],
[24,12,7,6],
[24,12,8,0],
[24,12,8,1],
[24,12,8,2],
[24,12,8,4],
[24,12,8,7],
[24,12,9,0],
[24,12,9,1],
[24,12,9,2],
[24,12,9,3],
[24,12,9,4],
[24,12,9,5],
[24,12,9,6],
[24,12,9,7],
[24,12,9,8],
[24,12,10,0],
[24,12,10,1],
[24,12,10,2],
[24,12,10,3],
[24,12,10,4],
[24,12,10,5],
[24,12,10,6],
[24,12,10,7],
[24,12,10,8],
[24,12,10,9],
[24,13,3,0],
[24,13,3,1],
[24,13,3,2],
[24,13,4,0],
[24,13,4,1],
[24,13,4,2],
[24,13,4,3],
[24,13,5,0],
[24,13,5,1],
[24,13,5,2],
[24,13,5,4],
[24,13,6,0],
[24,13,6,1],
[24,13,6,2],
[24,13,6,4],
[24,13,7,0],
[24,13,7,1],
[24,13,7,2],
[24,13,7,3],
[24,13,7,4],
[24,13,7,5],
[24,13,7,6],
[24,13,8,0],
[24,13,8,1],
[24,13,8,2],
[24,13,8,4],
[24,13,8,7],
[24,13,9,0],
[24,13,9,1],
[24,13,9,2],
[24,13,9,3],
[24,13,9,4],
[24,13,9,5],
[24,13,9,6],
[24,13,9,7],
[24,13,9,8],
[24,13,10,0],
[24,13,10,1],
[24,13,10,2],
[24,13,10,3],
[24,13,10,4],
[24,13,10,5],
[24,13,10,6],
[24,13,10,7],
[24,13,10,8],
[24,13,10,9],
[24,13,11,0],
[24,13,11,1],
[24,13,11,2],
[24,13,11,3],
[24,13,11,4],
[24,13,11,5],
[24,13,11,6],
[24,13,11,7],
[24,13,11,8],
[24,13,11,9],
[24,13,11,10],
[24,13,12,0],
[24,13,12,1],
[24,13,12,2],
[24,13,12,3],
[24,13,12,4],
[24,13,12,5],
[24,13,12,6],
[24,13,12,7],
[24,13,12,8],
[24,13,12,9],
[24,13,12,10],
[24,14,3,0],
[24,14,3,1],
[24,14,3,2],
[24,14,4,0],
[24,14,4,1],
[24,14,4,2],
[24,14,4,3],
[24,14,5,0],
[24,14,5,1],
[24,14,5,2],
[24,14,5,4],
[24,14,6,0],
[24,14,6,1],
[24,14,6,2],
[24,14,6,4],
[24,14,7,0],
[24,14,7,1],
[24,14,7,2],
[24,14,7,3],
[24,14,7,4],
[24,14,7,5],
[24,14,7,6],
[24,14,8,0],
[24,14,8,1],
[24,14,8,2],
[24,14,8,4],
[24,14,8,7],
[24,14,9,0],
[24,14,9,1],
[24,14,9,2],
[24,14,9,3],
[24,14,9,4],
[24,14,9,5],
[24,14,9,6],
[24,14,9,7],
[24,14,9,8],
[24,14,10,0],
[24,14,10,1],
[24,14,10,2],
[24,14,10,3],
[24,14,10,4],
[24,14,10,5],
[24,14,10,6],
[24,14,10,7],
[24,14,10,8],
[24,14,10,9],
[24,14,11,0],
[24,14,11,1],
[24,14,11,2],
[24,14,11,3],
[24,14,11,4],
[24,14,11,5],
[24,14,11,6],
[24,14,11,7],
[24,14,11,8],
[24,14,11,9],
[24,14,11,10],
[24,14,12,0],
[24,14,12,1],
[24,14,12,2],
[24,14,12,3],
[24,14,12,4],
[24,14,12,5],
[24,14,12,6],
[24,14,12,7],
[24,14,12,8],
[24,14,12,9],
[24,14,12,10],
[24,15,3,0],
[24,15,3,1],
[24,15,3,2],
[24,15,4,0],
[24,15,4,1],
[24,15,4,2],
[24,15,4,3],
[24,15,5,0],
[24,15,5,1],
[24,15,5,2],
[24,15,5,4],
[24,15,6,0],
[24,15,6,1],
[24,15,6,2],
[24,15,6,4],
[24,15,7,0],
[24,15,7,1],
[24,15,7,2],
[24,15,7,3],
[24,15,7,4],
[24,15,7,5],
[24,15,7,6],
[24,15,8,0],
[24,15,8,1],
[24,15,8,2],
[24,15,8,4],
[24,15,8,7],
[24,15,10,0],
[24,15,10,1],
[24,15,10,2],
[24,15,10,3],
[24,15,10,4],
[24,15,10,5],
[24,15,10,6],
[24,15,10,7],
[24,15,10,8],
[24,15,11,0],
[24,15,11,1],
[24,15,11,2],
[24,15,11,3],
[24,15,11,4],
[24,15,11,5],
[24,15,11,6],
[24,15,11,7],
[24,15,11,8],
[24,15,11,10],
[24,15,12,0],
[24,15,12,1],
[24,15,12,2],
[24,15,12,3],
[24,15,12,4],
[24,15,12,5],
[24,15,12,6],
[24,15,12,7],
[24,15,12,8],
[24,15,12,10],
[24,15,13,0],
[24,15,13,1],
[24,15,13,2],
[24,15,13,3],
[24,15,13,4],
[24,15,13,5],
[24,15,13,6],
[24,15,13,7],
[24,15,13,8],
[24,15,13,10],
[24,15,13,11],
[24,15,13,12],
[24,15,14,0],
[24,15,14,1],
[24,15,14,2],
[24,15,14,3],
[24,15,14,4],
[24,15,14,5],
[24,15,14,6],
[24,15,14,7],
[24,15,14,8],
[24,15,14,10],
[24,15,14,11],
[24,15,14,12],
[24,17,3,0],
[24,17,3,1],
[24,17,3,2],
[24,17,4,0],
[24,17,4,1],
[24,17,4,2],
[24,17,4,3],
[24,17,5,0],
[24,17,5,1],
[24,17,5,2],
[24,17,5,4],
[24,17,6,0],
[24,17,6,1],
[24,17,6,2],
[24,17,6,4],
[24,17,7,0],
[24,17,7,1],
[24,17,7,2],
[24,17,7,3],
[24,17,7,4],
[24,17,7,5],
[24,17,7,6],
[24,17,8,0],
[24,17,8,1],
[24,17,8,2],
[24,17,8,4],
[24,17,8,7],
[24,17,9,0],
[24,17,9,1],
[24,17,9,2],
[24,17,9,3],
[24,17,9,4],
[24,17,9,5],
[24,17,9,6],
[24,17,9,7],
[24,17,9,8],
[24,17,10,0],
[24,17,10,1],
[24,17,10,2],
[24,17,10,3],
[24,17,10,4],
[24,17,10,5],
[24,17,10,6],
[24,17,10,7],
[24,17,10,8],
[24,17,10,9],
[24,17,11,0],
[24,17,11,1],
[24,17,11,2],
[24,17,11,3],
[24,17,11,4],
[24,17,11,5],
[24,17,11,6],
[24,17,11,7],
[24,17,11,8],
[24,17,11,9],
[24,17,11,10],
[24,17,12,0],
[24,17,12,1],
[24,17,12,2],
[24,17,12,3],
[24,17,12,4],
[24,17,12,5],
[24,17,12,6],
[24,17,12,7],
[24,17,12,8],
[24,17,12,9],
[24,17,12,10],
[24,17,13,0],
[24,17,13,1],
[24,17,13,2],
[24,17,13,3],
[24,17,13,4],
[24,17,13,5],
[24,17,13,6],
[24,17,13,7],
[24,17,13,8],
[24,17,13,9],
[24,17,13,10],
[24,17,13,11],
[24,17,13,12],
[24,17,14,0],
[24,17,14,1],
[24,17,14,2],
[24,17,14,3],
[24,17,14,4],
[24,17,14,5],
[24,17,14,6],
[24,17,14,7],
[24,17,14,8],
[24,17,14,9],
[24,17,14,10],
[24,17,14,11],
[24,17,14,12],
[24,17,15,0],
[24,17,15,1],
[24,17,15,2],
[24,17,15,3],
[24,17,15,4],
[24,17,15,5],
[24,17,15,6],
[24,17,15,7],
[24,17,15,8],
[24,17,15,10],
[24,17,15,11],
[24,17,15,12],
[24,17,15,13],
[24,17,15,14],
[24,18,3,0],
[24,18,3,1],
[24,18,3,2],
[24,18,4,0],
[24,18,4,1],
[24,18,4,2],
[24,18,4,3],
[24,18,5,0],
[24,18,5,1],
[24,18,5,2],
[24,18,5,4],
[24,18,6,0],
[24,18,6,1],
[24,18,6,2],
[24,18,6,4],
[24,18,7,0],
[24,18,7,1],
[24,18,7,2],
[24,18,7,3],
[24,18,7,4],
[24,18,7,5],
[24,18,7,6],
[24,18,8,0],
[24,18,8,1],
[24,18,8,2],
[24,18,8,4],
[24,18,8,7],
[24,18,10,0],
[24,18,10,1],
[24,18,10,2],
[24,18,10,3],
[24,18,10,4],
[24,18,10,5],
[24,18,10,6],
[24,18,10,7],
[24,18,10,8],
[24,18,11,0],
[24,18,11,1],
[24,18,11,2],
[24,18,11,3],
[24,18,11,4],
[24,18,11,5],
[24,18,11,6],
[24,18,11,7],
[24,18,11,8],
[24,18,11,10],
[24,18,12,0],
[24,18,12,1],
[24,18,12,2],
[24,18,12,3],
[24,18,12,4],
[24,18,12,5],
[24,18,12,6],
[24,18,12,7],
[24,18,12,8],
[24,18,12,10],
[24,18,13,0],
[24,18,13,1],
[24,18,13,2],
[24,18,13,3],
[24,18,13,4],
[24,18,13,5],
[24,18,13,6],
[24,18,13,7],
[24,18,13,8],
[24,18,13,10],
[24,18,13,11],
[24,18,13,12],
[24,18,14,0],
[24,18,14,1],
[24,18,14,2],
[24,18,14,3],
[24,18,14,4],
[24,18,14,5],
[24,18,14,6],
[24,18,14,7],
[24,18,14,8],
[24,18,14,10],
[24,18,14,11],
[24,18,14,12],
[24,18,17,0],
[24,18,17,1],
[24,18,17,2],
[24,18,17,3],
[24,18,17,4],
[24,18,17,5],
[24,18,17,6],
[24,18,17,7],
[24,18,17,8],
[24,18,17,10],
[24,18,17,11],
[24,18,17,12],
[24,18,17,13],
[24,18,17,14],
[24,19,3,0],
[24,19,3,1],
[24,19,3,2],
[24,19,4,0],
[24,19,4,1],
[24,19,4,2],
[24,19,4,3],
[24,19,5,0],
[24,19,5,1],
[24,19,5,2],
[24,19,5,4],
[24,19,6,0],
[24,19,6,1],
[24,19,6,2],
[24,19,6,4],
[24,19,7,0],
[24,19,7,1],
[24,19,7,2],
[24,19,7,3],
[24,19,7,4],
[24,19,7,5],
[24,19,7,6],
[24,19,8,0],
[24,19,8,1],
[24,19,8,2],
[24,19,8,4],
[24,19,8,7],
[24,19,9,0],
[24,19,9,1],
[24,19,9,2],
[24,19,9,3],
[24,19,9,4],
[24,19,9,5],
[24,19,9,6],
[24,19,9,7],
[24,19,9,8],
[24,19,10,0],
[24,19,10,1],
[24,19,10,2],
[24,19,10,3],
[24,19,10,4],
[24,19,10,5],
[24,19,10,6],
[24,19,10,7],
[24,19,10,8],
[24,19,10,9],
[24,19,11,0],
[24,19,11,1],
[24,19,11,2],
[24,19,11,3],
[24,19,11,4],
[24,19,11,5],
[24,19,11,6],
[24,19,11,7],
[24,19,11,8],
[24,19,11,9],
[24,19,11,10],
[24,19,12,0],
[24,19,12,1],
[24,19,12,2],
[24,19,12,3],
[24,19,12,4],
[24,19,12,5],
[24,19,12,6],
[24,19,12,7],
[24,19,12,8],
[24,19,12,9],
[24,19,12,10],
[24,19,15,0],
[24,19,15,1],
[24,19,15,2],
[24,19,15,3],
[24,19,15,4],
[24,19,15,5],
[24,19,15,6],
[24,19,15,7],
[24,19,15,8],
[24,19,15,10],
[24,19,15,11],
[24,19,15,12],
[24,19,17,0],
[24,19,17,1],
[24,19,17,2],
[24,19,17,3],
[24,19,17,4],
[24,19,17,5],
[24,19,17,6],
[24,19,17,7],
[24,19,17,8],
[24,19,17,9],
[24,19,17,10],
[24,19,17,11],
[24,19,17,12],
[24,19,17,15],
[24,19,18,0],
[24,19,18,1],
[24,19,18,2],
[24,19,18,3],
[24,19,18,4],
[24,19,18,5],
[24,19,18,6],
[24,19,18,7],
[24,19,18,8],
[24,19,18,10],
[24,19,18,11],
[24,19,18,12],
[24,19,18,17],
[24,20,3,0],
[24,20,3,1],
[24,20,3,2],
[24,20,4,0],
[24,20,4,1],
[24,20,4,2],
[24,20,4,3],
[24,20,5,0],
[24,20,5,1],
[24,20,5,2],
[24,20,5,4],
[24,20,6,0],
[24,20,6,1],
[24,20,6,2],
[24,20,6,4],
[24,20,7,0],
[24,20,7,1],
[24,20,7,2],
[24,20,7,3],
[24,20,7,4],
[24,20,7,5],
[24,20,7,6],
[24,20,8,0],
[24,20,8,1],
[24,20,8,2],
[24,20,8,4],
[24,20,8,7],
[24,20,9,0],
[24,20,9,1],
[24,20,9,2],
[24,20,9,3],
[24,20,9,4],
[24,20,9,5],
[24,20,9,6],
[24,20,9,7],
[24,20,9,8],
[24,20,11,0],
[24,20,11,1],
[24,20,11,2],
[24,20,11,3],
[24,20,11,4],
[24,20,11,5],
[24,20,11,6],
[24,20,11,7],
[24,20,11,8],
[24,20,11,9],
[24,20,12,0],
[24,20,12,1],
[24,20,12,2],
[24,20,12,3],
[24,20,12,4],
[24,20,12,5],
[24,20,12,6],
[24,20,12,7],
[24,20,12,8],
[24,20,12,9],
[24,20,13,0],
[24,20,13,1],
[24,20,13,2],
[24,20,13,3],
[24,20,13,4],
[24,20,13,5],
[24,20,13,6],
[24,20,13,7],
[24,20,13,8],
[24,20,13,9],
[24,20,13,11],
[24,20,13,12],
[24,20,14,0],
[24,20,14,1],
[24,20,14,2],
[24,20,14,3],
[24,20,14,4],
[24,20,14,5],
[24,20,14,6],
[24,20,14,7],
[24,20,14,8],
[24,20,14,9],
[24,20,14,11],
[24,20,14,12],
[24,20,15,0],
[24,20,15,1],
[24,20,15,2],
[24,20,15,3],
[24,20,15,4],
[24,20,15,5],
[24,20,15,6],
[24,20,15,7],
[24,20,15,8],
[24,20,15,11],
[24,20,15,12],
[24,20,15,13],
[24,20,15,14],
[24,20,17,0],
[24,20,17,1],
[24,20,17,2],
[24,20,17,3],
[24,20,17,4],
[24,20,17,5],
[24,20,17,6],
[24,20,17,7],
[24,20,17,8],
[24,20,17,9],
[24,20,17,11],
[24,20,17,12],
[24,20,17,13],
[24,20,17,14],
[24,20,17,15],
[24,20,18,0],
[24,20,18,1],
[24,20,18,2],
[24,20,18,3],
[24,20,18,4],
[24,20,18,5],
[24,20,18,6],
[24,20,18,7],
[24,20,18,8],
[24,20,18,11],
[24,20,18,12],
[24,20,18,13],
[24,20,18,14],
[24,20,18,17],
[24,20,19,0],
[24,20,19,1],
[24,20,19,2],
[24,20,19,3],
[24,20,19,4],
[24,20,19,5],
[24,20,19,6],
[24,20,19,7],
[24,20,19,8],
[24,20,19,9],
[24,20,19,11],
[24,20,19,12],
[24,20,19,15],
[24,20,19,17],
[24,20,19,18],
[24,21,4,0],
[24,21,4,1],
[24,21,4,2],
[24,21,7,0],
[24,21,7,1],
[24,21,7,2],
[24,21,7,4],
[24,21,9,0],
[24,21,9,1],
[24,21,9,2],
[24,21,9,4],
[24,21,9,7],
[24,21,10,0],
[24,21,10,1],
[24,21,10,2],
[24,21,10,4],
[24,21,10,7],
[24,21,10,9],
[24,21,11,0],
[24,21,11,1],
[24,21,11,2],
[24,21,11,4],
[24,21,11,7],
[24,21,11,9],
[24,21,11,10],
[24,21,12,0],
[24,21,12,1],
[24,21,12,2],
[24,21,12,4],
[24,21,12,7],
[24,21,12,9],
[24,21,12,10],
[24,21,13,0],
[24,21,13,1],
[24,21,13,2],
[24,21,13,4],
[24,21,13,7],
[24,21,13,9],
[24,21,13,10],
[24,21,13,11],
[24,21,13,12],
[24,21,14,0],
[24,21,14,1],
[24,21,14,2],
[24,21,14,4],
[24,21,14,7],
[24,21,14,9],
[24,21,14,10],
[24,21,14,11],
[24,21,14,12],
[24,21,15,0],
[24,21,15,1],
[24,21,15,2],
[24,21,15,4],
[24,21,15,7],
[24,21,15,10],
[24,21,15,11],
[24,21,15,12],
[24,21,15,13],
[24,21,15,14],
[24,21,17,0],
[24,21,17,1],
[24,21,17,2],
[24,21,17,4],
[24,21,17,7],
[24,21,17,9],
[24,21,17,10],
[24,21,17,11],
[24,21,17,12],
[24,21,17,13],
[24,21,17,14],
[24,21,17,15],
[24,21,18,0],
[24,21,18,1],
[24,21,18,2],
[24,21,18,4],
[24,21,18,7],
[24,21,18,10],
[24,21,18,11],
[24,21,18,12],
[24,21,18,13],
[24,21,18,14],
[24,21,18,17],
[24,21,19,0],
[24,21,19,1],
[24,21,19,2],
[24,21,19,4],
[24,21,19,7],
[24,21,19,9],
[24,21,19,10],
[24,21,19,11],
[24,21,19,12],
[24,21,19,15],
[24,21,19,17],
[24,21,19,18],
[24,21,20,0],
[24,21,20,1],
[24,21,20,2],
[24,21,20,4],
[24,21,20,7],
[24,21,20,9],
[24,21,20,11],
[24,21,20,12],
[24,21,20,13],
[24,21,20,14],
[24,21,20,15],
[24,21,20,17],
[24,21,20,18],
[24,21,20,19],
[24,22,3,0],
[24,22,3,1],
[24,22,3,2],
[24,22,4,0],
[24,22,4,1],
[24,22,4,2],
[24,22,4,3],
[24,22,5,0],
[24,22,5,1],
[24,22,5,2],
[24,22,5,4],
[24,22,6,0],
[24,22,6,1],
[24,22,6,2],
[24,22,6,4],
[24,22,7,0],
[24,22,7,1],
[24,22,7,2],
[24,22,7,3],
[24,22,7,4],
[24,22,7,5],
[24,22,7,6],
[24,22,8,0],
[24,22,8,1],
[24,22,8,2],
[24,22,8,4],
[24,22,8,7],
[24,22,9,0],
[24,22,9,1],
[24,22,9,2],
[24,22,9,3],
[24,22,9,4],
[24,22,9,5],
[24,22,9,6],
[24,22,9,7],
[24,22,9,8],
[24,22,11,0],
[24,22,11,1],
[24,22,11,2],
[24,22,11,3],
[24,22,11,4],
[24,22,11,5],
[24,22,11,6],
[24,22,11,7],
[24,22,11,8],
[24,22,11,9],
[24,22,12,0],
[24,22,12,1],
[24,22,12,2],
[24,22,12,3],
[24,22,12,4],
[24,22,12,5],
[24,22,12,6],
[24,22,12,7],
[24,22,12,8],
[24,22,12,9],
[24,22,13,0],
[24,22,13,1],
[24,22,13,2],
[24,22,13,3],
[24,22,13,4],
[24,22,13,5],
[24,22,13,6],
[24,22,13,7],
[24,22,13,8],
[24,22,13,9],
[24,22,13,11],
[24,22,13,12],
[24,22,14,0],
[24,22,14,1],
[24,22,14,2],
[24,22,14,3],
[24,22,14,4],
[24,22,14,5],
[24,22,14,6],
[24,22,14,7],
[24,22,14,8],
[24,22,14,9],
[24,22,14,11],
[24,22,14,12],
[24,22,15,0],
[24,22,15,1],
[24,22,15,2],
[24,22,15,3],
[24,22,15,4],
[24,22,15,5],
[24,22,15,6],
[24,22,15,7],
[24,22,15,8],
[24,22,15,11],
[24,22,15,12],
[24,22,15,13],
[24,22,15,14],
[24,22,17,0],
[24,22,17,1],
[24,22,17,2],
[24,22,17,3],
[24,22,17,4],
[24,22,17,5],
[24,22,17,6],
[24,22,17,7],
[24,22,17,8],
[24,22,17,9],
[24,22,17,11],
[24,22,17,12],
[24,22,17,13],
[24,22,17,14],
[24,22,17,15],
[24,22,18,0],
[24,22,18,1],
[24,22,18,2],
[24,22,18,3],
[24,22,18,4],
[24,22,18,5],
[24,22,18,6],
[24,22,18,7],
[24,22,18,8],
[24,22,18,11],
[24,22,18,12],
[24,22,18,13],
[24,22,18,14],
[24,22,18,17],
[24,22,19,0],
[24,22,19,1],
[24,22,19,2],
[24,22,19,3],
[24,22,19,4],
[24,22,19,5],
[24,22,19,6],
[24,22,19,7],
[24,22,19,8],
[24,22,19,9],
[24,22,19,11],
[24,22,19,12],
[24,22,19,15],
[24,22,19,17],
[24,22,19,18],
[24,22,21,0],
[24,22,21,1],
[24,22,21,2],
[24,22,21,4],
[24,22,21,7],
[24,22,21,9],
[24,22,21,11],
[24,22,21,12],
[24,22,21,13],
[24,22,21,14],
[24,22,21,15],
[24,22,21,17],
[24,22,21,18],
[24,22,21,19],
[24,23,3,0],
[24,23,3,1],
[24,23,3,2],
[24,23,4,0],
[24,23,4,1],
[24,23,4,2],
[24,23,4,3],
[24,23,5,0],
[24,23,5,1],
[24,23,5,2],
[24,23,5,4],
[24,23,6,0],
[24,23,6,1],
[24,23,6,2],
[24,23,6,4],
[24,23,7,0],
[24,23,7,1],
[24,23,7,2],
[24,23,7,3],
[24,23,7,4],
[24,23,7,5],
[24,23,7,6],
[24,23,8,0],
[24,23,8,1],
[24,23,8,2],
[24,23,8,4],
[24,23,8,7],
[24,23,9,0],
[24,23,9,1],
[24,23,9,2],
[24,23,9,3],
[24,23,9,4],
[24,23,9,5],
[24,23,9,6],
[24,23,9,7],
[24,23,9,8],
[24,23,10,0],
[24,23,10,1],
[24,23,10,2],
[24,23,10,3],
[24,23,10,4],
[24,23,10,5],
[24,23,10,6],
[24,23,10,7],
[24,23,10,8],
[24,23,10,9],
[24,23,11,0],
[24,23,11,1],
[24,23,11,2],
[24,23,11,3],
[24,23,11,4],
[24,23,11,5],
[24,23,11,6],
[24,23,11,7],
[24,23,11,8],
[24,23,11,9],
[24,23,11,10],
[24,23,12,0],
[24,23,12,1],
[24,23,12,2],
[24,23,12,3],
[24,23,12,4],
[24,23,12,5],
[24,23,12,6],
[24,23,12,7],
[24,23,12,8],
[24,23,12,9],
[24,23,12,10],
[24,23,13,0],
[24,23,13,1],
[24,23,13,2],
[24,23,13,3],
[24,23,13,4],
[24,23,13,5],
[24,23,13,6],
[24,23,13,7],
[24,23,13,8],
[24,23,13,9],
[24,23,13,10],
[24,23,13,11],
[24,23,13,12],
[24,23,14,0],
[24,23,14,1],
[24,23,14,2],
[24,23,14,3],
[24,23,14,4],
[24,23,14,5],
[24,23,14,6],
[24,23,14,7],
[24,23,14,8],
[24,23,14,9],
[24,23,14,10],
[24,23,14,11],
[24,23,14,12],
[24,23,15,0],
[24,23,15,1],
[24,23,15,2],
[24,23,15,3],
[24,23,15,4],
[24,23,15,5],
[24,23,15,6],
[24,23,15,7],
[24,23,15,8],
[24,23,15,10],
[24,23,15,11],
[24,23,15,12],
[24,23,15,13],
[24,23,15,14],
[24,23,17,0],
[24,23,17,1],
[24,23,17,2],
[24,23,17,3],
[24,23,17,4],
[24,23,17,5],
[24,23,17,6],
[24,23,17,7],
[24,23,17,8],
[24,23,17,9],
[24,23,17,10],
[24,23,17,11],
[24,23,17,12],
[24,23,17,13],
[24,23,17,14],
[24,23,17,15],
[24,23,18,0],
[24,23,18,1],
[24,23,18,2],
[24,23,18,3],
[24,23,18,4],
[24,23,18,5],
[24,23,18,6],
[24,23,18,7],
[24,23,18,8],
[24,23,18,10],
[24,23,18,11],
[24,23,18,12],
[24,23,18,13],
[24,23,18,14],
[24,23,18,17],
[24,23,19,0],
[24,23,19,1],
[24,23,19,2],
[24,23,19,3],
[24,23,19,4],
[24,23,19,5],
[24,23,19,6],
[24,23,19,7],
[24,23,19,8],
[24,23,19,9],
[24,23,19,10],
[24,23,19,11],
[24,23,19,12],
[24,23,19,15],
[24,23,19,17],
[24,23,19,18],
[24,23,20,0],
[24,23,20,1],
[24,23,20,2],
[24,23,20,3],
[24,23,20,4],
[24,23,20,5],
[24,23,20,6],
[24,23,20,7],
[24,23,20,8],
[24,23,20,9],
[24,23,20,11],
[24,23,20,12],
[24,23,20,13],
[24,23,20,14],
[24,23,20,15],
[24,23,20,17],
[24,23,20,18],
[24,23,20,19],
[24,23,21,0],
[24,23,21,1],
[24,23,21,2],
[24,23,21,4],
[24,23,21,7],
[24,23,21,9],
[24,23,21,10],
[24,23,21,11],
[24,23,21,12],
[24,23,21,13],
[24,23,21,14],
[24,23,21,15],
[24,23,21,17],
[24,23,21,18],
[24,23,21,19],
[24,23,21,20],
[24,23,22,0],
[24,23,22,1],
[24,23,22,2],
[24,23,22,3],
[24,23,22,4],
[24,23,22,5],
[24,23,22,6],
[24,23,22,7],
[24,23,22,8],
[24,23,22,9],
[24,23,22,11],
[24,23,22,12],
[24,23,22,13],
[24,23,22,14],
[24,23,22,15],
[24,23,22,17],
[24,23,22,18],
[24,23,22,19],
[24,23,22,21],
[25,4,3,0],
[25,4,3,1],
[25,4,3,2],
[25,5,4,0],
[25,5,4,1],
[25,5,4,2],
[25,6,4,0],
[25,6,4,1],
[25,6,4,2],
[25,8,4,0],
[25,8,4,1],
[25,8,4,2],
[25,9,3,0],
[25,9,3,1],
[25,9,3,2],
[25,9,4,0],
[25,9,4,1],
[25,9,4,2],
[25,9,4,3],
[25,9,5,0],
[25,9,5,1],
[25,9,5,2],
[25,9,5,4],
[25,9,6,0],
[25,9,6,1],
[25,9,6,2],
[25,9,6,4],
[25,9,8,0],
[25,9,8,1],
[25,9,8,2],
[25,9,8,4],
[25,10,3,0],
[25,10,3,1],
[25,10,3,2],
[25,10,4,0],
[25,10,4,1],
[25,10,4,2],
[25,10,4,3],
[25,10,5,0],
[25,10,5,1],
[25,10,5,2],
[25,10,5,4],
[25,10,6,0],
[25,10,6,1],
[25,10,6,2],
[25,10,6,4],
[25,10,8,0],
[25,10,8,1],
[25,10,8,2],
[25,10,8,4],
[25,10,9,0],
[25,10,9,1],
[25,10,9,2],
[25,10,9,3],
[25,10,9,4],
[25,10,9,5],
[25,10,9,6],
[25,10,9,8],
[25,11,3,0],
[25,11,3,1],
[25,11,3,2],
[25,11,4,0],
[25,11,4,1],
[25,11,4,2],
[25,11,4,3],
[25,11,5,0],
[25,11,5,1],
[25,11,5,2],
[25,11,5,4],
[25,11,6,0],
[25,11,6,1],
[25,11,6,2],
[25,11,6,4],
[25,11,8,0],
[25,11,8,1],
[25,11,8,2],
[25,11,8,4],
[25,11,9,0],
[25,11,9,1],
[25,11,9,2],
[25,11,9,3],
[25,11,9,4],
[25,11,9,5],
[25,11,9,6],
[25,11,9,8],
[25,11,10,0],
[25,11,10,1],
[25,11,10,2],
[25,11,10,3],
[25,11,10,4],
[25,11,10,5],
[25,11,10,6],
[25,11,10,8],
[25,11,10,9],
[25,12,3,0],
[25,12,3,1],
[25,12,3,2],
[25,12,4,0],
[25,12,4,1],
[25,12,4,2],
[25,12,4,3],
[25,12,5,0],
[25,12,5,1],
[25,12,5,2],
[25,12,5,4],
[25,12,6,0],
[25,12,6,1],
[25,12,6,2],
[25,12,6,4],
[25,12,8,0],
[25,12,8,1],
[25,12,8,2],
[25,12,8,4],
[25,12,9,0],
[25,12,9,1],
[25,12,9,2],
[25,12,9,3],
[25,12,9,4],
[25,12,9,5],
[25,12,9,6],
[25,12,9,8],
[25,12,10,0],
[25,12,10,1],
[25,12,10,2],
[25,12,10,3],
[25,12,10,4],
[25,12,10,5],
[25,12,10,6],
[25,12,10,8],
[25,12,10,9],
[25,13,3,0],
[25,13,3,1],
[25,13,3,2],
[25,13,4,0],
[25,13,4,1],
[25,13,4,2],
[25,13,4,3],
[25,13,5,0],
[25,13,5,1],
[25,13,5,2],
[25,13,5,4],
[25,13,6,0],
[25,13,6,1],
[25,13,6,2],
[25,13,6,4],
[25,13,8,0],
[25,13,8,1],
[25,13,8,2],
[25,13,8,4],
[25,13,9,0],
[25,13,9,1],
[25,13,9,2],
[25,13,9,3],
[25,13,9,4],
[25,13,9,5],
[25,13,9,6],
[25,13,9,8],
[25,13,10,0],
[25,13,10,1],
[25,13,10,2],
[25,13,10,3],
[25,13,10,4],
[25,13,10,5],
[25,13,10,6],
[25,13,10,8],
[25,13,10,9],
[25,13,11,0],
[25,13,11,1],
[25,13,11,2],
[25,13,11,3],
[25,13,11,4],
[25,13,11,5],
[25,13,11,6],
[25,13,11,8],
[25,13,11,9],
[25,13,11,10],
[25,13,12,0],
[25,13,12,1],
[25,13,12,2],
[25,13,12,3],
[25,13,12,4],
[25,13,12,5],
[25,13,12,6],
[25,13,12,8],
[25,13,12,9],
[25,13,12,10],
[25,14,3,0],
[25,14,3,1],
[25,14,3,2],
[25,14,4,0],
[25,14,4,1],
[25,14,4,2],
[25,14,4,3],
[25,14,5,0],
[25,14,5,1],
[25,14,5,2],
[25,14,5,4],
[25,14,6,0],
[25,14,6,1],
[25,14,6,2],
[25,14,6,4],
[25,14,8,0],
[25,14,8,1],
[25,14,8,2],
[25,14,8,4],
[25,14,9,0],
[25,14,9,1],
[25,14,9,2],
[25,14,9,3],
[25,14,9,4],
[25,14,9,5],
[25,14,9,6],
[25,14,9,8],
[25,14,10,0],
[25,14,10,1],
[25,14,10,2],
[25,14,10,3],
[25,14,10,4],
[25,14,10,5],
[25,14,10,6],
[25,14,10,8],
[25,14,10,9],
[25,14,11,0],
[25,14,11,1],
[25,14,11,2],
[25,14,11,3],
[25,14,11,4],
[25,14,11,5],
[25,14,11,6],
[25,14,11,8],
[25,14,11,9],
[25,14,11,10],
[25,14,12,0],
[25,14,12,1],
[25,14,12,2],
[25,14,12,3],
[25,14,12,4],
[25,14,12,5],
[25,14,12,6],
[25,14,12,8],
[25,14,12,9],
[25,14,12,10],
[25,15,3,0],
[25,15,3,1],
[25,15,3,2],
[25,15,4,0],
[25,15,4,1],
[25,15,4,2],
[25,15,4,3],
[25,15,5,0],
[25,15,5,1],
[25,15,5,2],
[25,15,5,4],
[25,15,6,0],
[25,15,6,1],
[25,15,6,2],
[25,15,6,4],
[25,15,8,0],
[25,15,8,1],
[25,15,8,2],
[25,15,8,4],
[25,15,10,0],
[25,15,10,1],
[25,15,10,2],
[25,15,10,3],
[25,15,10,4],
[25,15,10,5],
[25,15,10,6],
[25,15,10,8],
[25,15,11,0],
[25,15,11,1],
[25,15,11,2],
[25,15,11,3],
[25,15,11,4],
[25,15,11,5],
[25,15,11,6],
[25,15,11,8],
[25,15,11,10],
[25,15,12,0],
[25,15,12,1],
[25,15,12,2],
[25,15,12,3],
[25,15,12,4],
[25,15,12,5],
[25,15,12,6],
[25,15,12,8],
[25,15,12,10],
[25,15,13,0],
[25,15,13,1],
[25,15,13,2],
[25,15,13,3],
[25,15,13,4],
[25,15,13,5],
[25,15,13,6],
[25,15,13,8],
[25,15,13,10],
[25,15,13,11],
[25,15,13,12],
[25,15,14,0],
[25,15,14,1],
[25,15,14,2],
[25,15,14,3],
[25,15,14,4],
[25,15,14,5],
[25,15,14,6],
[25,15,14,8],
[25,15,14,10],
[25,15,14,11],
[25,15,14,12],
[25,16,3,0],
[25,16,3,1],
[25,16,3,2],
[25,16,4,0],
[25,16,4,1],
[25,16,4,2],
[25,16,4,3],
[25,16,5,0],
[25,16,5,1],
[25,16,5,2],
[25,16,5,4],
[25,16,6,0],
[25,16,6,1],
[25,16,6,2],
[25,16,6,4],
[25,16,8,0],
[25,16,8,1],
[25,16,8,2],
[25,16,8,4],
[25,16,9,0],
[25,16,9,1],
[25,16,9,2],
[25,16,9,3],
[25,16,9,4],
[25,16,9,5],
[25,16,9,6],
[25,16,9,8],
[25,16,10,0],
[25,16,10,1],
[25,16,10,2],
[25,16,10,3],
[25,16,10,4],
[25,16,10,5],
[25,16,10,6],
[25,16,10,8],
[25,16,10,9],
[25,16,11,0],
[25,16,11,1],
[25,16,11,2],
[25,16,11,3],
[25,16,11,4],
[25,16,11,5],
[25,16,11,6],
[25,16,11,8],
[25,16,11,9],
[25,16,11,10],
[25,16,12,0],
[25,16,12,1],
[25,16,12,2],
[25,16,12,3],
[25,16,12,4],
[25,16,12,5],
[25,16,12,6],
[25,16,12,8],
[25,16,12,9],
[25,16,12,10],
[25,16,13,0],
[25,16,13,1],
[25,16,13,2],
[25,16,13,3],
[25,16,13,4],
[25,16,13,5],
[25,16,13,6],
[25,16,13,8],
[25,16,13,9],
[25,16,13,10],
[25,16,13,11],
[25,16,13,12],
[25,16,14,0],
[25,16,14,1],
[25,16,14,2],
[25,16,14,3],
[25,16,14,4],
[25,16,14,5],
[25,16,14,6],
[25,16,14,8],
[25,16,14,9],
[25,16,14,10],
[25,16,14,11],
[25,16,14,12],
[25,16,15,0],
[25,16,15,1],
[25,16,15,2],
[25,16,15,3],
[25,16,15,4],
[25,16,15,5],
[25,16,15,6],
[25,16,15,8],
[25,16,15,10],
[25,16,15,11],
[25,16,15,12],
[25,16,15,13],
[25,16,15,14],
[25,17,3,0],
[25,17,3,1],
[25,17,3,2],
[25,17,4,0],
[25,17,4,1],
[25,17,4,2],
[25,17,4,3],
[25,17,5,0],
[25,17,5,1],
[25,17,5,2],
[25,17,5,4],
[25,17,6,0],
[25,17,6,1],
[25,17,6,2],
[25,17,6,4],
[25,17,8,0],
[25,17,8,1],
[25,17,8,2],
[25,17,8,4],
[25,17,9,0],
[25,17,9,1],
[25,17,9,2],
[25,17,9,3],
[25,17,9,4],
[25,17,9,5],
[25,17,9,6],
[25,17,9,8],
[25,17,10,0],
[25,17,10,1],
[25,17,10,2],
[25,17,10,3],
[25,17,10,4],
[25,17,10,5],
[25,17,10,6],
[25,17,10,8],
[25,17,10,9],
[25,17,11,0],
[25,17,11,1],
[25,17,11,2],
[25,17,11,3],
[25,17,11,4],
[25,17,11,5],
[25,17,11,6],
[25,17,11,8],
[25,17,11,9],
[25,17,11,10],
[25,17,12,0],
[25,17,12,1],
[25,17,12,2],
[25,17,12,3],
[25,17,12,4],
[25,17,12,5],
[25,17,12,6],
[25,17,12,8],
[25,17,12,9],
[25,17,12,10],
[25,17,13,0],
[25,17,13,1],
[25,17,13,2],
[25,17,13,3],
[25,17,13,4],
[25,17,13,5],
[25,17,13,6],
[25,17,13,8],
[25,17,13,9],
[25,17,13,10],
[25,17,13,11],
[25,17,13,12],
[25,17,14,0],
[25,17,14,1],
[25,17,14,2],
[25,17,14,3],
[25,17,14,4],
[25,17,14,5],
[25,17,14,6],
[25,17,14,8],
[25,17,14,9],
[25,17,14,10],
[25,17,14,11],
[25,17,14,12],
[25,17,15,0],
[25,17,15,1],
[25,17,15,2],
[25,17,15,3],
[25,17,15,4],
[25,17,15,5],
[25,17,15,6],
[25,17,15,8],
[25,17,15,10],
[25,17,15,11],
[25,17,15,12],
[25,17,15,13],
[25,17,15,14],
[25,17,16,0],
[25,17,16,1],
[25,17,16,2],
[25,17,16,3],
[25,17,16,4],
[25,17,16,5],
[25,17,16,6],
[25,17,16,8],
[25,17,16,9],
[25,17,16,10],
[25,17,16,11],
[25,17,16,12],
[25,17,16,13],
[25,17,16,14],
[25,17,16,15],
[25,18,3,0],
[25,18,3,1],
[25,18,3,2],
[25,18,4,0],
[25,18,4,1],
[25,18,4,2],
[25,18,4,3],
[25,18,5,0],
[25,18,5,1],
[25,18,5,2],
[25,18,5,4],
[25,18,6,0],
[25,18,6,1],
[25,18,6,2],
[25,18,6,4],
[25,18,8,0],
[25,18,8,1],
[25,18,8,2],
[25,18,8,4],
[25,18,10,0],
[25,18,10,1],
[25,18,10,2],
[25,18,10,3],
[25,18,10,4],
[25,18,10,5],
[25,18,10,6],
[25,18,10,8],
[25,18,11,0],
[25,18,11,1],
[25,18,11,2],
[25,18,11,3],
[25,18,11,4],
[25,18,11,5],
[25,18,11,6],
[25,18,11,8],
[25,18,11,10],
[25,18,12,0],
[25,18,12,1],
[25,18,12,2],
[25,18,12,3],
[25,18,12,4],
[25,18,12,5],
[25,18,12,6],
[25,18,12,8],
[25,18,12,10],
[25,18,13,0],
[25,18,13,1],
[25,18,13,2],
[25,18,13,3],
[25,18,13,4],
[25,18,13,5],
[25,18,13,6],
[25,18,13,8],
[25,18,13,10],
[25,18,13,11],
[25,18,13,12],
[25,18,14,0],
[25,18,14,1],
[25,18,14,2],
[25,18,14,3],
[25,18,14,4],
[25,18,14,5],
[25,18,14,6],
[25,18,14,8],
[25,18,14,10],
[25,18,14,11],
[25,18,14,12],
[25,18,16,0],
[25,18,16,1],
[25,18,16,2],
[25,18,16,3],
[25,18,16,4],
[25,18,16,5],
[25,18,16,6],
[25,18,16,8],
[25,18,16,10],
[25,18,16,11],
[25,18,16,12],
[25,18,16,13],
[25,18,16,14],
[25,18,17,0],
[25,18,17,1],
[25,18,17,2],
[25,18,17,3],
[25,18,17,4],
[25,18,17,5],
[25,18,17,6],
[25,18,17,8],
[25,18,17,10],
[25,18,17,11],
[25,18,17,12],
[25,18,17,13],
[25,18,17,14],
[25,18,17,16],
[25,19,3,0],
[25,19,3,1],
[25,19,3,2],
[25,19,4,0],
[25,19,4,1],
[25,19,4,2],
[25,19,4,3],
[25,19,5,0],
[25,19,5,1],
[25,19,5,2],
[25,19,5,4],
[25,19,6,0],
[25,19,6,1],
[25,19,6,2],
[25,19,6,4],
[25,19,8,0],
[25,19,8,1],
[25,19,8,2],
[25,19,8,4],
[25,19,9,0],
[25,19,9,1],
[25,19,9,2],
[25,19,9,3],
[25,19,9,4],
[25,19,9,5],
[25,19,9,6],
[25,19,9,8],
[25,19,10,0],
[25,19,10,1],
[25,19,10,2],
[25,19,10,3],
[25,19,10,4],
[25,19,10,5],
[25,19,10,6],
[25,19,10,8],
[25,19,10,9],
[25,19,11,0],
[25,19,11,1],
[25,19,11,2],
[25,19,11,3],
[25,19,11,4],
[25,19,11,5],
[25,19,11,6],
[25,19,11,8],
[25,19,11,9],
[25,19,11,10],
[25,19,12,0],
[25,19,12,1],
[25,19,12,2],
[25,19,12,3],
[25,19,12,4],
[25,19,12,5],
[25,19,12,6],
[25,19,12,8],
[25,19,12,9],
[25,19,12,10],
[25,19,15,0],
[25,19,15,1],
[25,19,15,2],
[25,19,15,3],
[25,19,15,4],
[25,19,15,5],
[25,19,15,6],
[25,19,15,8],
[25,19,15,10],
[25,19,15,11],
[25,19,15,12],
[25,19,16,0],
[25,19,16,1],
[25,19,16,2],
[25,19,16,3],
[25,19,16,4],
[25,19,16,5],
[25,19,16,6],
[25,19,16,8],
[25,19,16,9],
[25,19,16,10],
[25,19,16,11],
[25,19,16,12],
[25,19,16,15],
[25,19,17,0],
[25,19,17,1],
[25,19,17,2],
[25,19,17,3],
[25,19,17,4],
[25,19,17,5],
[25,19,17,6],
[25,19,17,8],
[25,19,17,9],
[25,19,17,10],
[25,19,17,11],
[25,19,17,12],
[25,19,17,15],
[25,19,17,16],
[25,19,18,0],
[25,19,18,1],
[25,19,18,2],
[25,19,18,3],
[25,19,18,4],
[25,19,18,5],
[25,19,18,6],
[25,19,18,8],
[25,19,18,10],
[25,19,18,11],
[25,19,18,12],
[25,19,18,16],
[25,19,18,17],
[25,20,3,0],
[25,20,3,1],
[25,20,3,2],
[25,20,4,0],
[25,20,4,1],
[25,20,4,2],
[25,20,4,3],
[25,20,5,0],
[25,20,5,1],
[25,20,5,2],
[25,20,5,4],
[25,20,6,0],
[25,20,6,1],
[25,20,6,2],
[25,20,6,4],
[25,20,8,0],
[25,20,8,1],
[25,20,8,2],
[25,20,8,4],
[25,20,9,0],
[25,20,9,1],
[25,20,9,2],
[25,20,9,3],
[25,20,9,4],
[25,20,9,5],
[25,20,9,6],
[25,20,9,8],
[25,20,11,0],
[25,20,11,1],
[25,20,11,2],
[25,20,11,3],
[25,20,11,4],
[25,20,11,5],
[25,20,11,6],
[25,20,11,8],
[25,20,11,9],
[25,20,12,0],
[25,20,12,1],
[25,20,12,2],
[25,20,12,3],
[25,20,12,4],
[25,20,12,5],
[25,20,12,6],
[25,20,12,8],
[25,20,12,9],
[25,20,13,0],
[25,20,13,1],
[25,20,13,2],
[25,20,13,3],
[25,20,13,4],
[25,20,13,5],
[25,20,13,6],
[25,20,13,8],
[25,20,13,9],
[25,20,13,11],
[25,20,13,12],
[25,20,14,0],
[25,20,14,1],
[25,20,14,2],
[25,20,14,3],
[25,20,14,4],
[25,20,14,5],
[25,20,14,6],
[25,20,14,8],
[25,20,14,9],
[25,20,14,11],
[25,20,14,12],
[25,20,15,0],
[25,20,15,1],
[25,20,15,2],
[25,20,15,3],
[25,20,15,4],
[25,20,15,5],
[25,20,15,6],
[25,20,15,8],
[25,20,15,11],
[25,20,15,12],
[25,20,15,13],
[25,20,15,14],
[25,20,16,0],
[25,20,16,1],
[25,20,16,2],
[25,20,16,3],
[25,20,16,4],
[25,20,16,5],
[25,20,16,6],
[25,20,16,8],
[25,20,16,9],
[25,20,16,11],
[25,20,16,12],
[25,20,16,13],
[25,20,16,14],
[25,20,16,15],
[25,20,17,0],
[25,20,17,1],
[25,20,17,2],
[25,20,17,3],
[25,20,17,4],
[25,20,17,5],
[25,20,17,6],
[25,20,17,8],
[25,20,17,9],
[25,20,17,11],
[25,20,17,12],
[25,20,17,13],
[25,20,17,14],
[25,20,17,15],
[25,20,17,16],
[25,20,18,0],
[25,20,18,1],
[25,20,18,2],
[25,20,18,3],
[25,20,18,4],
[25,20,18,5],
[25,20,18,6],
[25,20,18,8],
[25,20,18,11],
[25,20,18,12],
[25,20,18,13],
[25,20,18,14],
[25,20,18,16],
[25,20,18,17],
[25,20,19,0],
[25,20,19,1],
[25,20,19,2],
[25,20,19,3],
[25,20,19,4],
[25,20,19,5],
[25,20,19,6],
[25,20,19,8],
[25,20,19,9],
[25,20,19,11],
[25,20,19,12],
[25,20,19,15],
[25,20,19,16],
[25,20,19,17],
[25,20,19,18],
[25,21,4,0],
[25,21,4,1],
[25,21,4,2],
[25,21,9,0],
[25,21,9,1],
[25,21,9,2],
[25,21,9,4],
[25,21,10,0],
[25,21,10,1],
[25,21,10,2],
[25,21,10,4],
[25,21,10,9],
[25,21,11,0],
[25,21,11,1],
[25,21,11,2],
[25,21,11,4],
[25,21,11,9],
[25,21,11,10],
[25,21,12,0],
[25,21,12,1],
[25,21,12,2],
[25,21,12,4],
[25,21,12,9],
[25,21,12,10],
[25,21,13,0],
[25,21,13,1],
[25,21,13,2],
[25,21,13,4],
[25,21,13,9],
[25,21,13,10],
[25,21,13,11],
[25,21,13,12],
[25,21,14,0],
[25,21,14,1],
[25,21,14,2],
[25,21,14,4],
[25,21,14,9],
[25,21,14,10],
[25,21,14,11],
[25,21,14,12],
[25,21,15,0],
[25,21,15,1],
[25,21,15,2],
[25,21,15,4],
[25,21,15,10],
[25,21,15,11],
[25,21,15,12],
[25,21,15,13],
[25,21,15,14],
[25,21,16,0],
[25,21,16,1],
[25,21,16,2],
[25,21,16,4],
[25,21,16,9],
[25,21,16,10],
[25,21,16,11],
[25,21,16,12],
[25,21,16,13],
[25,21,16,14],
[25,21,16,15],
[25,21,17,0],
[25,21,17,1],
[25,21,17,2],
[25,21,17,4],
[25,21,17,9],
[25,21,17,10],
[25,21,17,11],
[25,21,17,12],
[25,21,17,13],
[25,21,17,14],
[25,21,17,15],
[25,21,17,16],
[25,21,18,0],
[25,21,18,1],
[25,21,18,2],
[25,21,18,4],
[25,21,18,10],
[25,21,18,11],
[25,21,18,12],
[25,21,18,13],
[25,21,18,14],
[25,21,18,16],
[25,21,18,17],
[25,21,19,0],
[25,21,19,1],
[25,21,19,2],
[25,21,19,4],
[25,21,19,9],
[25,21,19,10],
[25,21,19,11],
[25,21,19,12],
[25,21,19,15],
[25,21,19,16],
[25,21,19,17],
[25,21,19,18],
[25,21,20,0],
[25,21,20,1],
[25,21,20,2],
[25,21,20,4],
[25,21,20,9],
[25,21,20,11],
[25,21,20,12],
[25,21,20,13],
[25,21,20,14],
[25,21,20,15],
[25,21,20,16],
[25,21,20,17],
[25,21,20,18],
[25,21,20,19],
[25,22,3,0],
[25,22,3,1],
[25,22,3,2],
[25,22,4,0],
[25,22,4,1],
[25,22,4,2],
[25,22,4,3],
[25,22,5,0],
[25,22,5,1],
[25,22,5,2],
[25,22,5,4],
[25,22,6,0],
[25,22,6,1],
[25,22,6,2],
[25,22,6,4],
[25,22,8,0],
[25,22,8,1],
[25,22,8,2],
[25,22,8,4],
[25,22,9,0],
[25,22,9,1],
[25,22,9,2],
[25,22,9,3],
[25,22,9,4],
[25,22,9,5],
[25,22,9,6],
[25,22,9,8],
[25,22,11,0],
[25,22,11,1],
[25,22,11,2],
[25,22,11,3],
[25,22,11,4],
[25,22,11,5],
[25,22,11,6],
[25,22,11,8],
[25,22,11,9],
[25,22,12,0],
[25,22,12,1],
[25,22,12,2],
[25,22,12,3],
[25,22,12,4],
[25,22,12,5],
[25,22,12,6],
[25,22,12,8],
[25,22,12,9],
[25,22,13,0],
[25,22,13,1],
[25,22,13,2],
[25,22,13,3],
[25,22,13,4],
[25,22,13,5],
[25,22,13,6],
[25,22,13,8],
[25,22,13,9],
[25,22,13,11],
[25,22,13,12],
[25,22,14,0],
[25,22,14,1],
[25,22,14,2],
[25,22,14,3],
[25,22,14,4],
[25,22,14,5],
[25,22,14,6],
[25,22,14,8],
[25,22,14,9],
[25,22,14,11],
[25,22,14,12],
[25,22,15,0],
[25,22,15,1],
[25,22,15,2],
[25,22,15,3],
[25,22,15,4],
[25,22,15,5],
[25,22,15,6],
[25,22,15,8],
[25,22,15,11],
[25,22,15,12],
[25,22,15,13],
[25,22,15,14],
[25,22,16,0],
[25,22,16,1],
[25,22,16,2],
[25,22,16,3],
[25,22,16,4],
[25,22,16,5],
[25,22,16,6],
[25,22,16,8],
[25,22,16,9],
[25,22,16,11],
[25,22,16,12],
[25,22,16,13],
[25,22,16,14],
[25,22,16,15],
[25,22,17,0],
[25,22,17,1],
[25,22,17,2],
[25,22,17,3],
[25,22,17,4],
[25,22,17,5],
[25,22,17,6],
[25,22,17,8],
[25,22,17,9],
[25,22,17,11],
[25,22,17,12],
[25,22,17,13],
[25,22,17,14],
[25,22,17,15],
[25,22,17,16],
[25,22,18,0],
[25,22,18,1],
[25,22,18,2],
[25,22,18,3],
[25,22,18,4],
[25,22,18,5],
[25,22,18,6],
[25,22,18,8],
[25,22,18,11],
[25,22,18,12],
[25,22,18,13],
[25,22,18,14],
[25,22,18,16],
[25,22,18,17],
[25,22,19,0],
[25,22,19,1],
[25,22,19,2],
[25,22,19,3],
[25,22,19,4],
[25,22,19,5],
[25,22,19,6],
[25,22,19,8],
[25,22,19,9],
[25,22,19,11],
[25,22,19,12],
[25,22,19,15],
[25,22,19,16],
[25,22,19,17],
[25,22,19,18],
[25,22,21,0],
[25,22,21,1],
[25,22,21,2],
[25,22,21,4],
[25,22,21,9],
[25,22,21,11],
[25,22,21,12],
[25,22,21,13],
[25,22,21,14],
[25,22,21,15],
[25,22,21,16],
[25,22,21,17],
[25,22,21,18],
[25,22,21,19],
[25,23,3,0],
[25,23,3,1],
[25,23,3,2],
[25,23,4,0],
[25,23,4,1],
[25,23,4,2],
[25,23,4,3],
[25,23,5,0],
[25,23,5,1],
[25,23,5,2],
[25,23,5,4],
[25,23,6,0],
[25,23,6,1],
[25,23,6,2],
[25,23,6,4],
[25,23,8,0],
[25,23,8,1],
[25,23,8,2],
[25,23,8,4],
[25,23,9,0],
[25,23,9,1],
[25,23,9,2],
[25,23,9,3],
[25,23,9,4],
[25,23,9,5],
[25,23,9,6],
[25,23,9,8],
[25,23,10,0],
[25,23,10,1],
[25,23,10,2],
[25,23,10,3],
[25,23,10,4],
[25,23,10,5],
[25,23,10,6],
[25,23,10,8],
[25,23,10,9],
[25,23,11,0],
[25,23,11,1],
[25,23,11,2],
[25,23,11,3],
[25,23,11,4],
[25,23,11,5],
[25,23,11,6],
[25,23,11,8],
[25,23,11,9],
[25,23,11,10],
[25,23,12,0],
[25,23,12,1],
[25,23,12,2],
[25,23,12,3],
[25,23,12,4],
[25,23,12,5],
[25,23,12,6],
[25,23,12,8],
[25,23,12,9],
[25,23,12,10],
[25,23,13,0],
[25,23,13,1],
[25,23,13,2],
[25,23,13,3],
[25,23,13,4],
[25,23,13,5],
[25,23,13,6],
[25,23,13,8],
[25,23,13,9],
[25,23,13,10],
[25,23,13,11],
[25,23,13,12],
[25,23,14,0],
[25,23,14,1],
[25,23,14,2],
[25,23,14,3],
[25,23,14,4],
[25,23,14,5],
[25,23,14,6],
[25,23,14,8],
[25,23,14,9],
[25,23,14,10],
[25,23,14,11],
[25,23,14,12],
[25,23,15,0],
[25,23,15,1],
[25,23,15,2],
[25,23,15,3],
[25,23,15,4],
[25,23,15,5],
[25,23,15,6],
[25,23,15,8],
[25,23,15,10],
[25,23,15,11],
[25,23,15,12],
[25,23,15,13],
[25,23,15,14],
[25,23,16,0],
[25,23,16,1],
[25,23,16,2],
[25,23,16,3],
[25,23,16,4],
[25,23,16,5],
[25,23,16,6],
[25,23,16,8],
[25,23,16,9],
[25,23,16,10],
[25,23,16,11],
[25,23,16,12],
[25,23,16,13],
[25,23,16,14],
[25,23,16,15],
[25,23,17,0],
[25,23,17,1],
[25,23,17,2],
[25,23,17,3],
[25,23,17,4],
[25,23,17,5],
[25,23,17,6],
[25,23,17,8],
[25,23,17,9],
[25,23,17,10],
[25,23,17,11],
[25,23,17,12],
[25,23,17,13],
[25,23,17,14],
[25,23,17,15],
[25,23,17,16],
[25,23,18,0],
[25,23,18,1],
[25,23,18,2],
[25,23,18,3],
[25,23,18,4],
[25,23,18,5],
[25,23,18,6],
[25,23,18,8],
[25,23,18,10],
[25,23,18,11],
[25,23,18,12],
[25,23,18,13],
[25,23,18,14],
[25,23,18,16],
[25,23,18,17],
[25,23,19,0],
[25,23,19,1],
[25,23,19,2],
[25,23,19,3],
[25,23,19,4],
[25,23,19,5],
[25,23,19,6],
[25,23,19,8],
[25,23,19,9],
[25,23,19,10],
[25,23,19,11],
[25,23,19,12],
[25,23,19,15],
[25,23,19,16],
[25,23,19,17],
[25,23,19,18],
[25,23,20,0],
[25,23,20,1],
[25,23,20,2],
[25,23,20,3],
[25,23,20,4],
[25,23,20,5],
[25,23,20,6],
[25,23,20,8],
[25,23,20,9],
[25,23,20,11],
[25,23,20,12],
[25,23,20,13],
[25,23,20,14],
[25,23,20,15],
[25,23,20,16],
[25,23,20,17],
[25,23,20,18],
[25,23,20,19],
[25,23,21,0],
[25,23,21,1],
[25,23,21,2],
[25,23,21,4],
[25,23,21,9],
[25,23,21,10],
[25,23,21,11],
[25,23,21,12],
[25,23,21,13],
[25,23,21,14],
[25,23,21,15],
[25,23,21,16],
[25,23,21,17],
[25,23,21,18],
[25,23,21,19],
[25,23,21,20],
[25,23,22,0],
[25,23,22,1],
[25,23,22,2],
[25,23,22,3],
[25,23,22,4],
[25,23,22,5],
[25,23,22,6],
[25,23,22,8],
[25,23,22,9],
[25,23,22,11],
[25,23,22,12],
[25,23,22,13],
[25,23,22,14],
[25,23,22,15],
[25,23,22,16],
[25,23,22,17],
[25,23,22,18],
[25,23,22,19],
[25,23,22,21],
[25,24,3,0],
[25,24,3,1],
[25,24,3,2],
[25,24,4,0],
[25,24,4,1],
[25,24,4,2],
[25,24,4,3],
[25,24,5,0],
[25,24,5,1],
[25,24,5,2],
[25,24,5,4],
[25,24,6,0],
[25,24,6,1],
[25,24,6,2],
[25,24,6,4],
[25,24,8,0],
[25,24,8,1],
[25,24,8,2],
[25,24,8,4],
[25,24,9,0],
[25,24,9,1],
[25,24,9,2],
[25,24,9,3],
[25,24,9,4],
[25,24,9,5],
[25,24,9,6],
[25,24,9,8],
[25,24,10,0],
[25,24,10,1],
[25,24,10,2],
[25,24,10,3],
[25,24,10,4],
[25,24,10,5],
[25,24,10,6],
[25,24,10,8],
[25,24,10,9],
[25,24,11,0],
[25,24,11,1],
[25,24,11,2],
[25,24,11,3],
[25,24,11,4],
[25,24,11,5],
[25,24,11,6],
[25,24,11,8],
[25,24,11,9],
[25,24,11,10],
[25,24,12,0],
[25,24,12,1],
[25,24,12,2],
[25,24,12,3],
[25,24,12,4],
[25,24,12,5],
[25,24,12,6],
[25,24,12,8],
[25,24,12,9],
[25,24,12,10],
[25,24,13,0],
[25,24,13,1],
[25,24,13,2],
[25,24,13,3],
[25,24,13,4],
[25,24,13,5],
[25,24,13,6],
[25,24,13,8],
[25,24,13,9],
[25,24,13,10],
[25,24,13,11],
[25,24,13,12],
[25,24,14,0],
[25,24,14,1],
[25,24,14,2],
[25,24,14,3],
[25,24,14,4],
[25,24,14,5],
[25,24,14,6],
[25,24,14,8],
[25,24,14,9],
[25,24,14,10],
[25,24,14,11],
[25,24,14,12],
[25,24,15,0],
[25,24,15,1],
[25,24,15,2],
[25,24,15,3],
[25,24,15,4],
[25,24,15,5],
[25,24,15,6],
[25,24,15,8],
[25,24,15,10],
[25,24,15,11],
[25,24,15,12],
[25,24,15,13],
[25,24,15,14],
[25,24,17,0],
[25,24,17,1],
[25,24,17,2],
[25,24,17,3],
[25,24,17,4],
[25,24,17,5],
[25,24,17,6],
[25,24,17,8],
[25,24,17,9],
[25,24,17,10],
[25,24,17,11],
[25,24,17,12],
[25,24,17,13],
[25,24,17,14],
[25,24,17,15],
[25,24,18,0],
[25,24,18,1],
[25,24,18,2],
[25,24,18,3],
[25,24,18,4],
[25,24,18,5],
[25,24,18,6],
[25,24,18,8],
[25,24,18,10],
[25,24,18,11],
[25,24,18,12],
[25,24,18,13],
[25,24,18,14],
[25,24,18,17],
[25,24,19,0],
[25,24,19,1],
[25,24,19,2],
[25,24,19,3],
[25,24,19,4],
[25,24,19,5],
[25,24,19,6],
[25,24,19,8],
[25,24,19,9],
[25,24,19,10],
[25,24,19,11],
[25,24,19,12],
[25,24,19,15],
[25,24,19,17],
[25,24,19,18],
[25,24,20,0],
[25,24,20,1],
[25,24,20,2],
[25,24,20,3],
[25,24,20,4],
[25,24,20,5],
[25,24,20,6],
[25,24,20,8],
[25,24,20,9],
[25,24,20,11],
[25,24,20,12],
[25,24,20,13],
[25,24,20,14],
[25,24,20,15],
[25,24,20,17],
[25,24,20,18],
[25,24,20,19],
[25,24,21,0],
[25,24,21,1],
[25,24,21,2],
[25,24,21,4],
[25,24,21,9],
[25,24,21,10],
[25,24,21,11],
[25,24,21,12],
[25,24,21,13],
[25,24,21,14],
[25,24,21,15],
[25,24,21,17],
[25,24,21,18],
[25,24,21,19],
[25,24,21,20],
[25,24,22,0],
[25,24,22,1],
[25,24,22,2],
[25,24,22,3],
[25,24,22,4],
[25,24,22,5],
[25,24,22,6],
[25,24,22,8],
[25,24,22,9],
[25,24,22,11],
[25,24,22,12],
[25,24,22,13],
[25,24,22,14],
[25,24,22,15],
[25,24,22,17],
[25,24,22,18],
[25,24,22,19],
[25,24,22,21],
[25,24,23,0],
[25,24,23,1],
[25,24,23,2],
[25,24,23,3],
[25,24,23,4],
[25,24,23,5],
[25,24,23,6],
[25,24,23,8],
[25,24,23,9],
[25,24,23,10],
[25,24,23,11],
[25,24,23,12],
[25,24,23,13],
[25,24,23,14],
[25,24,23,15],
[25,24,23,17],
[25,24,23,18],
[25,24,23,19],
[25,24,23,20],
[25,24,23,21],
[25,24,23,22],
[26,4,3,0],
[26,4,3,1],
[26,4,3,2],
[26,5,4,0],
[26,5,4,1],
[26,5,4,2],
[26,6,4,0],
[26,6,4,1],
[26,6,4,2],
[26,7,3,0],
[26,7,3,1],
[26,7,3,2],
[26,7,4,0],
[26,7,4,1],
[26,7,4,2],
[26,7,4,3],
[26,7,5,0],
[26,7,5,1],
[26,7,5,2],
[26,7,5,4],
[26,7,6,0],
[26,7,6,1],
[26,7,6,2],
[26,7,6,4],
[26,8,4,0],
[26,8,4,1],
[26,8,4,2],
[26,8,7,0],
[26,8,7,1],
[26,8,7,2],
[26,8,7,4],
[26,10,3,0],
[26,10,3,1],
[26,10,3,2],
[26,10,4,0],
[26,10,4,1],
[26,10,4,2],
[26,10,4,3],
[26,10,5,0],
[26,10,5,1],
[26,10,5,2],
[26,10,5,4],
[26,10,6,0],
[26,10,6,1],
[26,10,6,2],
[26,10,6,4],
[26,10,7,0],
[26,10,7,1],
[26,10,7,2],
[26,10,7,3],
[26,10,7,4],
[26,10,7,5],
[26,10,7,6],
[26,10,8,0],
[26,10,8,1],
[26,10,8,2],
[26,10,8,4],
[26,10,8,7],
[26,11,3,0],
[26,11,3,1],
[26,11,3,2],
[26,11,4,0],
[26,11,4,1],
[26,11,4,2],
[26,11,4,3],
[26,11,5,0],
[26,11,5,1],
[26,11,5,2],
[26,11,5,4],
[26,11,6,0],
[26,11,6,1],
[26,11,6,2],
[26,11,6,4],
[26,11,7,0],
[26,11,7,1],
[26,11,7,2],
[26,11,7,3],
[26,11,7,4],
[26,11,7,5],
[26,11,7,6],
[26,11,8,0],
[26,11,8,1],
[26,11,8,2],
[26,11,8,4],
[26,11,8,7],
[26,11,10,0],
[26,11,10,1],
[26,11,10,2],
[26,11,10,3],
[26,11,10,4],
[26,11,10,5],
[26,11,10,6],
[26,11,10,7],
[26,11,10,8],
[26,12,3,0],
[26,12,3,1],
[26,12,3,2],
[26,12,4,0],
[26,12,4,1],
[26,12,4,2],
[26,12,4,3],
[26,12,5,0],
[26,12,5,1],
[26,12,5,2],
[26,12,5,4],
[26,12,6,0],
[26,12,6,1],
[26,12,6,2],
[26,12,6,4],
[26,12,7,0],
[26,12,7,1],
[26,12,7,2],
[26,12,7,3],
[26,12,7,4],
[26,12,7,5],
[26,12,7,6],
[26,12,8,0],
[26,12,8,1],
[26,12,8,2],
[26,12,8,4],
[26,12,8,7],
[26,12,10,0],
[26,12,10,1],
[26,12,10,2],
[26,12,10,3],
[26,12,10,4],
[26,12,10,5],
[26,12,10,6],
[26,12,10,7],
[26,12,10,8],
[26,13,3,0],
[26,13,3,1],
[26,13,3,2],
[26,13,4,0],
[26,13,4,1],
[26,13,4,2],
[26,13,4,3],
[26,13,5,0],
[26,13,5,1],
[26,13,5,2],
[26,13,5,4],
[26,13,6,0],
[26,13,6,1],
[26,13,6,2],
[26,13,6,4],
[26,13,7,0],
[26,13,7,1],
[26,13,7,2],
[26,13,7,3],
[26,13,7,4],
[26,13,7,5],
[26,13,7,6],
[26,13,8,0],
[26,13,8,1],
[26,13,8,2],
[26,13,8,4],
[26,13,8,7],
[26,13,10,0],
[26,13,10,1],
[26,13,10,2],
[26,13,10,3],
[26,13,10,4],
[26,13,10,5],
[26,13,10,6],
[26,13,10,7],
[26,13,10,8],
[26,13,11,0],
[26,13,11,1],
[26,13,11,2],
[26,13,11,3],
[26,13,11,4],
[26,13,11,5],
[26,13,11,6],
[26,13,11,7],
[26,13,11,8],
[26,13,11,10],
[26,13,12,0],
[26,13,12,1],
[26,13,12,2],
[26,13,12,3],
[26,13,12,4],
[26,13,12,5],
[26,13,12,6],
[26,13,12,7],
[26,13,12,8],
[26,13,12,10],
[26,14,3,0],
[26,14,3,1],
[26,14,3,2],
[26,14,4,0],
[26,14,4,1],
[26,14,4,2],
[26,14,4,3],
[26,14,5,0],
[26,14,5,1],
[26,14,5,2],
[26,14,5,4],
[26,14,6,0],
[26,14,6,1],
[26,14,6,2],
[26,14,6,4],
[26,14,7,0],
[26,14,7,1],
[26,14,7,2],
[26,14,7,3],
[26,14,7,4],
[26,14,7,5],
[26,14,7,6],
[26,14,8,0],
[26,14,8,1],
[26,14,8,2],
[26,14,8,4],
[26,14,8,7],
[26,14,10,0],
[26,14,10,1],
[26,14,10,2],
[26,14,10,3],
[26,14,10,4],
[26,14,10,5],
[26,14,10,6],
[26,14,10,7],
[26,14,10,8],
[26,14,11,0],
[26,14,11,1],
[26,14,11,2],
[26,14,11,3],
[26,14,11,4],
[26,14,11,5],
[26,14,11,6],
[26,14,11,7],
[26,14,11,8],
[26,14,11,10],
[26,14,12,0],
[26,14,12,1],
[26,14,12,2],
[26,14,12,3],
[26,14,12,4],
[26,14,12,5],
[26,14,12,6],
[26,14,12,7],
[26,14,12,8],
[26,14,12,10],
[26,16,3,0],
[26,16,3,1],
[26,16,3,2],
[26,16,4,0],
[26,16,4,1],
[26,16,4,2],
[26,16,4,3],
[26,16,5,0],
[26,16,5,1],
[26,16,5,2],
[26,16,5,4],
[26,16,6,0],
[26,16,6,1],
[26,16,6,2],
[26,16,6,4],
[26,16,7,0],
[26,16,7,1],
[26,16,7,2],
[26,16,7,3],
[26,16,7,4],
[26,16,7,5],
[26,16,7,6],
[26,16,8,0],
[26,16,8,1],
[26,16,8,2],
[26,16,8,4],
[26,16,8,7],
[26,16,10,0],
[26,16,10,1],
[26,16,10,2],
[26,16,10,3],
[26,16,10,4],
[26,16,10,5],
[26,16,10,6],
[26,16,10,7],
[26,16,10,8],
[26,16,11,0],
[26,16,11,1],
[26,16,11,2],
[26,16,11,3],
[26,16,11,4],
[26,16,11,5],
[26,16,11,6],
[26,16,11,7],
[26,16,11,8],
[26,16,11,10],
[26,16,12,0],
[26,16,12,1],
[26,16,12,2],
[26,16,12,3],
[26,16,12,4],
[26,16,12,5],
[26,16,12,6],
[26,16,12,7],
[26,16,12,8],
[26,16,12,10],
[26,16,13,0],
[26,16,13,1],
[26,16,13,2],
[26,16,13,3],
[26,16,13,4],
[26,16,13,5],
[26,16,13,6],
[26,16,13,7],
[26,16,13,8],
[26,16,13,10],
[26,16,13,11],
[26,16,13,12],
[26,16,14,0],
[26,16,14,1],
[26,16,14,2],
[26,16,14,3],
[26,16,14,4],
[26,16,14,5],
[26,16,14,6],
[26,16,14,7],
[26,16,14,8],
[26,16,14,10],
[26,16,14,11],
[26,16,14,12],
[26,17,3,0],
[26,17,3,1],
[26,17,3,2],
[26,17,4,0],
[26,17,4,1],
[26,17,4,2],
[26,17,4,3],
[26,17,5,0],
[26,17,5,1],
[26,17,5,2],
[26,17,5,4],
[26,17,6,0],
[26,17,6,1],
[26,17,6,2],
[26,17,6,4],
[26,17,7,0],
[26,17,7,1],
[26,17,7,2],
[26,17,7,3],
[26,17,7,4],
[26,17,7,5],
[26,17,7,6],
[26,17,8,0],
[26,17,8,1],
[26,17,8,2],
[26,17,8,4],
[26,17,8,7],
[26,17,10,0],
[26,17,10,1],
[26,17,10,2],
[26,17,10,3],
[26,17,10,4],
[26,17,10,5],
[26,17,10,6],
[26,17,10,7],
[26,17,10,8],
[26,17,11,0],
[26,17,11,1],
[26,17,11,2],
[26,17,11,3],
[26,17,11,4],
[26,17,11,5],
[26,17,11,6],
[26,17,11,7],
[26,17,11,8],
[26,17,11,10],
[26,17,12,0],
[26,17,12,1],
[26,17,12,2],
[26,17,12,3],
[26,17,12,4],
[26,17,12,5],
[26,17,12,6],
[26,17,12,7],
[26,17,12,8],
[26,17,12,10],
[26,17,13,0],
[26,17,13,1],
[26,17,13,2],
[26,17,13,3],
[26,17,13,4],
[26,17,13,5],
[26,17,13,6],
[26,17,13,7],
[26,17,13,8],
[26,17,13,10],
[26,17,13,11],
[26,17,13,12],
[26,17,14,0],
[26,17,14,1],
[26,17,14,2],
[26,17,14,3],
[26,17,14,4],
[26,17,14,5],
[26,17,14,6],
[26,17,14,7],
[26,17,14,8],
[26,17,14,10],
[26,17,14,11],
[26,17,14,12],
[26,17,16,0],
[26,17,16,1],
[26,17,16,2],
[26,17,16,3],
[26,17,16,4],
[26,17,16,5],
[26,17,16,6],
[26,17,16,7],
[26,17,16,8],
[26,17,16,10],
[26,17,16,11],
[26,17,16,12],
[26,17,16,13],
[26,17,16,14],
[26,19,3,0],
[26,19,3,1],
[26,19,3,2],
[26,19,4,0],
[26,19,4,1],
[26,19,4,2],
[26,19,4,3],
[26,19,5,0],
[26,19,5,1],
[26,19,5,2],
[26,19,5,4],
[26,19,6,0],
[26,19,6,1],
[26,19,6,2],
[26,19,6,4],
[26,19,7,0],
[26,19,7,1],
[26,19,7,2],
[26,19,7,3],
[26,19,7,4],
[26,19,7,5],
[26,19,7,6],
[26,19,8,0],
[26,19,8,1],
[26,19,8,2],
[26,19,8,4],
[26,19,8,7],
[26,19,10,0],
[26,19,10,1],
[26,19,10,2],
[26,19,10,3],
[26,19,10,4],
[26,19,10,5],
[26,19,10,6],
[26,19,10,7],
[26,19,10,8],
[26,19,11,0],
[26,19,11,1],
[26,19,11,2],
[26,19,11,3],
[26,19,11,4],
[26,19,11,5],
[26,19,11,6],
[26,19,11,7],
[26,19,11,8],
[26,19,11,10],
[26,19,12,0],
[26,19,12,1],
[26,19,12,2],
[26,19,12,3],
[26,19,12,4],
[26,19,12,5],
[26,19,12,6],
[26,19,12,7],
[26,19,12,8],
[26,19,12,10],
[26,19,16,0],
[26,19,16,1],
[26,19,16,2],
[26,19,16,3],
[26,19,16,4],
[26,19,16,5],
[26,19,16,6],
[26,19,16,7],
[26,19,16,8],
[26,19,16,10],
[26,19,16,11],
[26,19,16,12],
[26,19,17,0],
[26,19,17,1],
[26,19,17,2],
[26,19,17,3],
[26,19,17,4],
[26,19,17,5],
[26,19,17,6],
[26,19,17,7],
[26,19,17,8],
[26,19,17,10],
[26,19,17,11],
[26,19,17,12],
[26,19,17,16],
[26,20,3,0],
[26,20,3,1],
[26,20,3,2],
[26,20,4,0],
[26,20,4,1],
[26,20,4,2],
[26,20,4,3],
[26,20,5,0],
[26,20,5,1],
[26,20,5,2],
[26,20,5,4],
[26,20,6,0],
[26,20,6,1],
[26,20,6,2],
[26,20,6,4],
[26,20,7,0],
[26,20,7,1],
[26,20,7,2],
[26,20,7,3],
[26,20,7,4],
[26,20,7,5],
[26,20,7,6],
[26,20,8,0],
[26,20,8,1],
[26,20,8,2],
[26,20,8,4],
[26,20,8,7],
[26,20,11,0],
[26,20,11,1],
[26,20,11,2],
[26,20,11,3],
[26,20,11,4],
[26,20,11,5],
[26,20,11,6],
[26,20,11,7],
[26,20,11,8],
[26,20,12,0],
[26,20,12,1],
[26,20,12,2],
[26,20,12,3],
[26,20,12,4],
[26,20,12,5],
[26,20,12,6],
[26,20,12,7],
[26,20,12,8],
[26,20,13,0],
[26,20,13,1],
[26,20,13,2],
[26,20,13,3],
[26,20,13,4],
[26,20,13,5],
[26,20,13,6],
[26,20,13,7],
[26,20,13,8],
[26,20,13,11],
[26,20,13,12],
[26,20,14,0],
[26,20,14,1],
[26,20,14,2],
[26,20,14,3],
[26,20,14,4],
[26,20,14,5],
[26,20,14,6],
[26,20,14,7],
[26,20,14,8],
[26,20,14,11],
[26,20,14,12],
[26,20,16,0],
[26,20,16,1],
[26,20,16,2],
[26,20,16,3],
[26,20,16,4],
[26,20,16,5],
[26,20,16,6],
[26,20,16,7],
[26,20,16,8],
[26,20,16,11],
[26,20,16,12],
[26,20,16,13],
[26,20,16,14],
[26,20,17,0],
[26,20,17,1],
[26,20,17,2],
[26,20,17,3],
[26,20,17,4],
[26,20,17,5],
[26,20,17,6],
[26,20,17,7],
[26,20,17,8],
[26,20,17,11],
[26,20,17,12],
[26,20,17,13],
[26,20,17,14],
[26,20,17,16],
[26,20,19,0],
[26,20,19,1],
[26,20,19,2],
[26,20,19,3],
[26,20,19,4],
[26,20,19,5],
[26,20,19,6],
[26,20,19,7],
[26,20,19,8],
[26,20,19,11],
[26,20,19,12],
[26,20,19,16],
[26,20,19,17],
[26,21,4,0],
[26,21,4,1],
[26,21,4,2],
[26,21,7,0],
[26,21,7,1],
[26,21,7,2],
[26,21,7,4],
[26,21,10,0],
[26,21,10,1],
[26,21,10,2],
[26,21,10,4],
[26,21,10,7],
[26,21,11,0],
[26,21,11,1],
[26,21,11,2],
[26,21,11,4],
[26,21,11,7],
[26,21,11,10],
[26,21,12,0],
[26,21,12,1],
[26,21,12,2],
[26,21,12,4],
[26,21,12,7],
[26,21,12,10],
[26,21,13,0],
[26,21,13,1],
[26,21,13,2],
[26,21,13,4],
[26,21,13,7],
[26,21,13,10],
[26,21,13,11],
[26,21,13,12],
[26,21,14,0],
[26,21,14,1],
[26,21,14,2],
[26,21,14,4],
[26,21,14,7],
[26,21,14,10],
[26,21,14,11],
[26,21,14,12],
[26,21,16,0],
[26,21,16,1],
[26,21,16,2],
[26,21,16,4],
[26,21,16,7],
[26,21,16,10],
[26,21,16,11],
[26,21,16,12],
[26,21,16,13],
[26,21,16,14],
[26,21,17,0],
[26,21,17,1],
[26,21,17,2],
[26,21,17,4],
[26,21,17,7],
[26,21,17,10],
[26,21,17,11],
[26,21,17,12],
[26,21,17,13],
[26,21,17,14],
[26,21,17,16],
[26,21,19,0],
[26,21,19,1],
[26,21,19,2],
[26,21,19,4],
[26,21,19,7],
[26,21,19,10],
[26,21,19,11],
[26,21,19,12],
[26,21,19,16],
[26,21,19,17],
[26,21,20,0],
[26,21,20,1],
[26,21,20,2],
[26,21,20,4],
[26,21,20,7],
[26,21,20,11],
[26,21,20,12],
[26,21,20,13],
[26,21,20,14],
[26,21,20,16],
[26,21,20,17],
[26,21,20,19],
[26,22,3,0],
[26,22,3,1],
[26,22,3,2],
[26,22,4,0],
[26,22,4,1],
[26,22,4,2],
[26,22,4,3],
[26,22,5,0],
[26,22,5,1],
[26,22,5,2],
[26,22,5,4],
[26,22,6,0],
[26,22,6,1],
[26,22,6,2],
[26,22,6,4],
[26,22,7,0],
[26,22,7,1],
[26,22,7,2],
[26,22,7,3],
[26,22,7,4],
[26,22,7,5],
[26,22,7,6],
[26,22,8,0],
[26,22,8,1],
[26,22,8,2],
[26,22,8,4],
[26,22,8,7],
[26,22,11,0],
[26,22,11,1],
[26,22,11,2],
[26,22,11,3],
[26,22,11,4],
[26,22,11,5],
[26,22,11,6],
[26,22,11,7],
[26,22,11,8],
[26,22,12,0],
[26,22,12,1],
[26,22,12,2],
[26,22,12,3],
[26,22,12,4],
[26,22,12,5],
[26,22,12,6],
[26,22,12,7],
[26,22,12,8],
[26,22,13,0],
[26,22,13,1],
[26,22,13,2],
[26,22,13,3],
[26,22,13,4],
[26,22,13,5],
[26,22,13,6],
[26,22,13,7],
[26,22,13,8],
[26,22,13,11],
[26,22,13,12],
[26,22,14,0],
[26,22,14,1],
[26,22,14,2],
[26,22,14,3],
[26,22,14,4],
[26,22,14,5],
[26,22,14,6],
[26,22,14,7],
[26,22,14,8],
[26,22,14,11],
[26,22,14,12],
[26,22,16,0],
[26,22,16,1],
[26,22,16,2],
[26,22,16,3],
[26,22,16,4],
[26,22,16,5],
[26,22,16,6],
[26,22,16,7],
[26,22,16,8],
[26,22,16,11],
[26,22,16,12],
[26,22,16,13],
[26,22,16,14],
[26,22,17,0],
[26,22,17,1],
[26,22,17,2],
[26,22,17,3],
[26,22,17,4],
[26,22,17,5],
[26,22,17,6],
[26,22,17,7],
[26,22,17,8],
[26,22,17,11],
[26,22,17,12],
[26,22,17,13],
[26,22,17,14],
[26,22,17,16],
[26,22,19,0],
[26,22,19,1],
[26,22,19,2],
[26,22,19,3],
[26,22,19,4],
[26,22,19,5],
[26,22,19,6],
[26,22,19,7],
[26,22,19,8],
[26,22,19,11],
[26,22,19,12],
[26,22,19,16],
[26,22,19,17],
[26,22,21,0],
[26,22,21,1],
[26,22,21,2],
[26,22,21,4],
[26,22,21,7],
[26,22,21,11],
[26,22,21,12],
[26,22,21,13],
[26,22,21,14],
[26,22,21,16],
[26,22,21,17],
[26,22,21,19],
[26,23,3,0],
[26,23,3,1],
[26,23,3,2],
[26,23,4,0],
[26,23,4,1],
[26,23,4,2],
[26,23,4,3],
[26,23,5,0],
[26,23,5,1],
[26,23,5,2],
[26,23,5,4],
[26,23,6,0],
[26,23,6,1],
[26,23,6,2],
[26,23,6,4],
[26,23,7,0],
[26,23,7,1],
[26,23,7,2],
[26,23,7,3],
[26,23,7,4],
[26,23,7,5],
[26,23,7,6],
[26,23,8,0],
[26,23,8,1],
[26,23,8,2],
[26,23,8,4],
[26,23,8,7],
[26,23,10,0],
[26,23,10,1],
[26,23,10,2],
[26,23,10,3],
[26,23,10,4],
[26,23,10,5],
[26,23,10,6],
[26,23,10,7],
[26,23,10,8],
[26,23,11,0],
[26,23,11,1],
[26,23,11,2],
[26,23,11,3],
[26,23,11,4],
[26,23,11,5],
[26,23,11,6],
[26,23,11,7],
[26,23,11,8],
[26,23,11,10],
[26,23,12,0],
[26,23,12,1],
[26,23,12,2],
[26,23,12,3],
[26,23,12,4],
[26,23,12,5],
[26,23,12,6],
[26,23,12,7],
[26,23,12,8],
[26,23,12,10],
[26,23,13,0],
[26,23,13,1],
[26,23,13,2],
[26,23,13,3],
[26,23,13,4],
[26,23,13,5],
[26,23,13,6],
[26,23,13,7],
[26,23,13,8],
[26,23,13,10],
[26,23,13,11],
[26,23,13,12],
[26,23,14,0],
[26,23,14,1],
[26,23,14,2],
[26,23,14,3],
[26,23,14,4],
[26,23,14,5],
[26,23,14,6],
[26,23,14,7],
[26,23,14,8],
[26,23,14,10],
[26,23,14,11],
[26,23,14,12],
[26,23,16,0],
[26,23,16,1],
[26,23,16,2],
[26,23,16,3],
[26,23,16,4],
[26,23,16,5],
[26,23,16,6],
[26,23,16,7],
[26,23,16,8],
[26,23,16,10],
[26,23,16,11],
[26,23,16,12],
[26,23,16,13],
[26,23,16,14],
[26,23,17,0],
[26,23,17,1],
[26,23,17,2],
[26,23,17,3],
[26,23,17,4],
[26,23,17,5],
[26,23,17,6],
[26,23,17,7],
[26,23,17,8],
[26,23,17,10],
[26,23,17,11],
[26,23,17,12],
[26,23,17,13],
[26,23,17,14],
[26,23,17,16],
[26,23,19,0],
[26,23,19,1],
[26,23,19,2],
[26,23,19,3],
[26,23,19,4],
[26,23,19,5],
[26,23,19,6],
[26,23,19,7],
[26,23,19,8],
[26,23,19,10],
[26,23,19,11],
[26,23,19,12],
[26,23,19,16],
[26,23,19,17],
[26,23,20,0],
[26,23,20,1],
[26,23,20,2],
[26,23,20,3],
[26,23,20,4],
[26,23,20,5],
[26,23,20,6],
[26,23,20,7],
[26,23,20,8],
[26,23,20,11],
[26,23,20,12],
[26,23,20,13],
[26,23,20,14],
[26,23,20,16],
[26,23,20,17],
[26,23,20,19],
[26,23,21,0],
[26,23,21,1],
[26,23,21,2],
[26,23,21,4],
[26,23,21,7],
[26,23,21,10],
[26,23,21,11],
[26,23,21,12],
[26,23,21,13],
[26,23,21,14],
[26,23,21,16],
[26,23,21,17],
[26,23,21,19],
[26,23,21,20],
[26,23,22,0],
[26,23,22,1],
[26,23,22,2],
[26,23,22,3],
[26,23,22,4],
[26,23,22,5],
[26,23,22,6],
[26,23,22,7],
[26,23,22,8],
[26,23,22,11],
[26,23,22,12],
[26,23,22,13],
[26,23,22,14],
[26,23,22,16],
[26,23,22,17],
[26,23,22,19],
[26,23,22,21],
[26,24,3,0],
[26,24,3,1],
[26,24,3,2],
[26,24,4,0],
[26,24,4,1],
[26,24,4,2],
[26,24,4,3],
[26,24,5,0],
[26,24,5,1],
[26,24,5,2],
[26,24,5,4],
[26,24,6,0],
[26,24,6,1],
[26,24,6,2],
[26,24,6,4],
[26,24,7,0],
[26,24,7,1],
[26,24,7,2],
[26,24,7,3],
[26,24,7,4],
[26,24,7,5],
[26,24,7,6],
[26,24,8,0],
[26,24,8,1],
[26,24,8,2],
[26,24,8,4],
[26,24,8,7],
[26,24,10,0],
[26,24,10,1],
[26,24,10,2],
[26,24,10,3],
[26,24,10,4],
[26,24,10,5],
[26,24,10,6],
[26,24,10,7],
[26,24,10,8],
[26,24,11,0],
[26,24,11,1],
[26,24,11,2],
[26,24,11,3],
[26,24,11,4],
[26,24,11,5],
[26,24,11,6],
[26,24,11,7],
[26,24,11,8],
[26,24,11,10],
[26,24,12,0],
[26,24,12,1],
[26,24,12,2],
[26,24,12,3],
[26,24,12,4],
[26,24,12,5],
[26,24,12,6],
[26,24,12,7],
[26,24,12,8],
[26,24,12,10],
[26,24,13,0],
[26,24,13,1],
[26,24,13,2],
[26,24,13,3],
[26,24,13,4],
[26,24,13,5],
[26,24,13,6],
[26,24,13,7],
[26,24,13,8],
[26,24,13,10],
[26,24,13,11],
[26,24,13,12],
[26,24,14,0],
[26,24,14,1],
[26,24,14,2],
[26,24,14,3],
[26,24,14,4],
[26,24,14,5],
[26,24,14,6],
[26,24,14,7],
[26,24,14,8],
[26,24,14,10],
[26,24,14,11],
[26,24,14,12],
[26,24,17,0],
[26,24,17,1],
[26,24,17,2],
[26,24,17,3],
[26,24,17,4],
[26,24,17,5],
[26,24,17,6],
[26,24,17,7],
[26,24,17,8],
[26,24,17,10],
[26,24,17,11],
[26,24,17,12],
[26,24,17,13],
[26,24,17,14],
[26,24,19,0],
[26,24,19,1],
[26,24,19,2],
[26,24,19,3],
[26,24,19,4],
[26,24,19,5],
[26,24,19,6],
[26,24,19,7],
[26,24,19,8],
[26,24,19,10],
[26,24,19,11],
[26,24,19,12],
[26,24,19,17],
[26,24,20,0],
[26,24,20,1],
[26,24,20,2],
[26,24,20,3],
[26,24,20,4],
[26,24,20,5],
[26,24,20,6],
[26,24,20,7],
[26,24,20,8],
[26,24,20,11],
[26,24,20,12],
[26,24,20,13],
[26,24,20,14],
[26,24,20,17],
[26,24,20,19],
[26,24,21,0],
[26,24,21,1],
[26,24,21,2],
[26,24,21,4],
[26,24,21,7],
[26,24,21,10],
[26,24,21,11],
[26,24,21,12],
[26,24,21,13],
[26,24,21,14],
[26,24,21,17],
[26,24,21,19],
[26,24,21,20],
[26,24,22,0],
[26,24,22,1],
[26,24,22,2],
[26,24,22,3],
[26,24,22,4],
[26,24,22,5],
[26,24,22,6],
[26,24,22,7],
[26,24,22,8],
[26,24,22,11],
[26,24,22,12],
[26,24,22,13],
[26,24,22,14],
[26,24,22,17],
[26,24,22,19],
[26,24,22,21],
[26,24,23,0],
[26,24,23,1],
[26,24,23,2],
[26,24,23,3],
[26,24,23,4],
[26,24,23,5],
[26,24,23,6],
[26,24,23,7],
[26,24,23,8],
[26,24,23,10],
[26,24,23,11],
[26,24,23,12],
[26,24,23,13],
[26,24,23,14],
[26,24,23,17],
[26,24,23,19],
[26,24,23,20],
[26,24,23,21],
[26,24,23,22],
[26,25,3,0],
[26,25,3,1],
[26,25,3,2],
[26,25,4,0],
[26,25,4,1],
[26,25,4,2],
[26,25,4,3],
[26,25,5,0],
[26,25,5,1],
[26,25,5,2],
[26,25,5,4],
[26,25,6,0],
[26,25,6,1],
[26,25,6,2],
[26,25,6,4],
[26,25,8,0],
[26,25,8,1],
[26,25,8,2],
[26,25,8,4],
[26,25,10,0],
[26,25,10,1],
[26,25,10,2],
[26,25,10,3],
[26,25,10,4],
[26,25,10,5],
[26,25,10,6],
[26,25,10,8],
[26,25,11,0],
[26,25,11,1],
[26,25,11,2],
[26,25,11,3],
[26,25,11,4],
[26,25,11,5],
[26,25,11,6],
[26,25,11,8],
[26,25,11,10],
[26,25,12,0],
[26,25,12,1],
[26,25,12,2],
[26,25,12,3],
[26,25,12,4],
[26,25,12,5],
[26,25,12,6],
[26,25,12,8],
[26,25,12,10],
[26,25,13,0],
[26,25,13,1],
[26,25,13,2],
[26,25,13,3],
[26,25,13,4],
[26,25,13,5],
[26,25,13,6],
[26,25,13,8],
[26,25,13,10],
[26,25,13,11],
[26,25,13,12],
[26,25,14,0],
[26,25,14,1],
[26,25,14,2],
[26,25,14,3],
[26,25,14,4],
[26,25,14,5],
[26,25,14,6],
[26,25,14,8],
[26,25,14,10],
[26,25,14,11],
[26,25,14,12],
[26,25,16,0],
[26,25,16,1],
[26,25,16,2],
[26,25,16,3],
[26,25,16,4],
[26,25,16,5],
[26,25,16,6],
[26,25,16,8],
[26,25,16,10],
[26,25,16,11],
[26,25,16,12],
[26,25,16,13],
[26,25,16,14],
[26,25,17,0],
[26,25,17,1],
[26,25,17,2],
[26,25,17,3],
[26,25,17,4],
[26,25,17,5],
[26,25,17,6],
[26,25,17,8],
[26,25,17,10],
[26,25,17,11],
[26,25,17,12],
[26,25,17,13],
[26,25,17,14],
[26,25,17,16],
[26,25,19,0],
[26,25,19,1],
[26,25,19,2],
[26,25,19,3],
[26,25,19,4],
[26,25,19,5],
[26,25,19,6],
[26,25,19,8],
[26,25,19,10],
[26,25,19,11],
[26,25,19,12],
[26,25,19,16],
[26,25,19,17],
[26,25,20,0],
[26,25,20,1],
[26,25,20,2],
[26,25,20,3],
[26,25,20,4],
[26,25,20,5],
[26,25,20,6],
[26,25,20,8],
[26,25,20,11],
[26,25,20,12],
[26,25,20,13],
[26,25,20,14],
[26,25,20,16],
[26,25,20,17],
[26,25,20,19],
[26,25,21,0],
[26,25,21,1],
[26,25,21,2],
[26,25,21,4],
[26,25,21,10],
[26,25,21,11],
[26,25,21,12],
[26,25,21,13],
[26,25,21,14],
[26,25,21,16],
[26,25,21,17],
[26,25,21,19],
[26,25,21,20],
[26,25,22,0],
[26,25,22,1],
[26,25,22,2],
[26,25,22,3],
[26,25,22,4],
[26,25,22,5],
[26,25,22,6],
[26,25,22,8],
[26,25,22,11],
[26,25,22,12],
[26,25,22,13],
[26,25,22,14],
[26,25,22,16],
[26,25,22,17],
[26,25,22,19],
[26,25,22,21],
[26,25,23,0],
[26,25,23,1],
[26,25,23,2],
[26,25,23,3],
[26,25,23,4],
[26,25,23,5],
[26,25,23,6],
[26,25,23,8],
[26,25,23,10],
[26,25,23,11],
[26,25,23,12],
[26,25,23,13],
[26,25,23,14],
[26,25,23,16],
[26,25,23,17],
[26,25,23,19],
[26,25,23,20],
[26,25,23,21],
[26,25,23,22],
[26,25,24,0],
[26,25,24,1],
[26,25,24,2],
[26,25,24,3],
[26,25,24,4],
[26,25,24,5],
[26,25,24,6],
[26,25,24,8],
[26,25,24,10],
[26,25,24,11],
[26,25,24,12],
[26,25,24,13],
[26,25,24,14],
[26,25,24,17],
[26,25,24,19],
[26,25,24,20],
[26,25,24,21],
[26,25,24,22],
[26,25,24,23],
[27,4,3,0],
[27,4,3,1],
[27,4,3,2],
[27,5,4,0],
[27,5,4,1],
[27,5,4,2],
[27,6,4,0],
[27,6,4,1],
[27,6,4,2],
[27,7,3,0],
[27,7,3,1],
[27,7,3,2],
[27,7,4,0],
[27,7,4,1],
[27,7,4,2],
[27,7,4,3],
[27,7,5,0],
[27,7,5,1],
[27,7,5,2],
[27,7,5,4],
[27,7,6,0],
[27,7,6,1],
[27,7,6,2],
[27,7,6,4],
[27,8,4,0],
[27,8,4,1],
[27,8,4,2],
[27,8,7,0],
[27,8,7,1],
[27,8,7,2],
[27,8,7,4],
[27,9,3,0],
[27,9,3,1],
[27,9,3,2],
[27,9,4,0],
[27,9,4,1],
[27,9,4,2],
[27,9,4,3],
[27,9,5,0],
[27,9,5,1],
[27,9,5,2],
[27,9,5,4],
[27,9,6,0],
[27,9,6,1],
[27,9,6,2],
[27,9,6,4],
[27,9,7,0],
[27,9,7,1],
[27,9,7,2],
[27,9,7,3],
[27,9,7,4],
[27,9,7,5],
[27,9,7,6],
[27,9,8,0],
[27,9,8,1],
[27,9,8,2],
[27,9,8,4],
[27,9,8,7],
[27,11,3,0],
[27,11,3,1],
[27,11,3,2],
[27,11,4,0],
[27,11,4,1],
[27,11,4,2],
[27,11,4,3],
[27,11,5,0],
[27,11,5,1],
[27,11,5,2],
[27,11,5,4],
[27,11,6,0],
[27,11,6,1],
[27,11,6,2],
[27,11,6,4],
[27,11,7,0],
[27,11,7,1],
[27,11,7,2],
[27,11,7,3],
[27,11,7,4],
[27,11,7,5],
[27,11,7,6],
[27,11,8,0],
[27,11,8,1],
[27,11,8,2],
[27,11,8,4],
[27,11,8,7],
[27,11,9,0],
[27,11,9,1],
[27,11,9,2],
[27,11,9,3],
[27,11,9,4],
[27,11,9,5],
[27,11,9,6],
[27,11,9,7],
[27,11,9,8],
[27,12,3,0],
[27,12,3,1],
[27,12,3,2],
[27,12,4,0],
[27,12,4,1],
[27,12,4,2],
[27,12,4,3],
[27,12,5,0],
[27,12,5,1],
[27,12,5,2],
[27,12,5,4],
[27,12,6,0],
[27,12,6,1],
[27,12,6,2],
[27,12,6,4],
[27,12,7,0],
[27,12,7,1],
[27,12,7,2],
[27,12,7,3],
[27,12,7,4],
[27,12,7,5],
[27,12,7,6],
[27,12,8,0],
[27,12,8,1],
[27,12,8,2],
[27,12,8,4],
[27,12,8,7],
[27,12,9,0],
[27,12,9,1],
[27,12,9,2],
[27,12,9,3],
[27,12,9,4],
[27,12,9,5],
[27,12,9,6],
[27,12,9,7],
[27,12,9,8],
[27,13,3,0],
[27,13,3,1],
[27,13,3,2],
[27,13,4,0],
[27,13,4,1],
[27,13,4,2],
[27,13,4,3],
[27,13,5,0],
[27,13,5,1],
[27,13,5,2],
[27,13,5,4],
[27,13,6,0],
[27,13,6,1],
[27,13,6,2],
[27,13,6,4],
[27,13,7,0],
[27,13,7,1],
[27,13,7,2],
[27,13,7,3],
[27,13,7,4],
[27,13,7,5],
[27,13,7,6],
[27,13,8,0],
[27,13,8,1],
[27,13,8,2],
[27,13,8,4],
[27,13,8,7],
[27,13,9,0],
[27,13,9,1],
[27,13,9,2],
[27,13,9,3],
[27,13,9,4],
[27,13,9,5],
[27,13,9,6],
[27,13,9,7],
[27,13,9,8],
[27,13,11,0],
[27,13,11,1],
[27,13,11,2],
[27,13,11,3],
[27,13,11,4],
[27,13,11,5],
[27,13,11,6],
[27,13,11,7],
[27,13,11,8],
[27,13,11,9],
[27,13,12,0],
[27,13,12,1],
[27,13,12,2],
[27,13,12,3],
[27,13,12,4],
[27,13,12,5],
[27,13,12,6],
[27,13,12,7],
[27,13,12,8],
[27,13,12,9],
[27,14,3,0],
[27,14,3,1],
[27,14,3,2],
[27,14,4,0],
[27,14,4,1],
[27,14,4,2],
[27,14,4,3],
[27,14,5,0],
[27,14,5,1],
[27,14,5,2],
[27,14,5,4],
[27,14,6,0],
[27,14,6,1],
[27,14,6,2],
[27,14,6,4],
[27,14,7,0],
[27,14,7,1],
[27,14,7,2],
[27,14,7,3],
[27,14,7,4],
[27,14,7,5],
[27,14,7,6],
[27,14,8,0],
[27,14,8,1],
[27,14,8,2],
[27,14,8,4],
[27,14,8,7],
[27,14,9,0],
[27,14,9,1],
[27,14,9,2],
[27,14,9,3],
[27,14,9,4],
[27,14,9,5],
[27,14,9,6],
[27,14,9,7],
[27,14,9,8],
[27,14,11,0],
[27,14,11,1],
[27,14,11,2],
[27,14,11,3],
[27,14,11,4],
[27,14,11,5],
[27,14,11,6],
[27,14,11,7],
[27,14,11,8],
[27,14,11,9],
[27,14,12,0],
[27,14,12,1],
[27,14,12,2],
[27,14,12,3],
[27,14,12,4],
[27,14,12,5],
[27,14,12,6],
[27,14,12,7],
[27,14,12,8],
[27,14,12,9],
[27,15,3,0],
[27,15,3,1],
[27,15,3,2],
[27,15,4,0],
[27,15,4,1],
[27,15,4,2],
[27,15,4,3],
[27,15,5,0],
[27,15,5,1],
[27,15,5,2],
[27,15,5,4],
[27,15,6,0],
[27,15,6,1],
[27,15,6,2],
[27,15,6,4],
[27,15,7,0],
[27,15,7,1],
[27,15,7,2],
[27,15,7,3],
[27,15,7,4],
[27,15,7,5],
[27,15,7,6],
[27,15,8,0],
[27,15,8,1],
[27,15,8,2],
[27,15,8,4],
[27,15,8,7],
[27,15,11,0],
[27,15,11,1],
[27,15,11,2],
[27,15,11,3],
[27,15,11,4],
[27,15,11,5],
[27,15,11,6],
[27,15,11,7],
[27,15,11,8],
[27,15,12,0],
[27,15,12,1],
[27,15,12,2],
[27,15,12,3],
[27,15,12,4],
[27,15,12,5],
[27,15,12,6],
[27,15,12,7],
[27,15,12,8],
[27,15,13,0],
[27,15,13,1],
[27,15,13,2],
[27,15,13,3],
[27,15,13,4],
[27,15,13,5],
[27,15,13,6],
[27,15,13,7],
[27,15,13,8],
[27,15,13,11],
[27,15,13,12],
[27,15,14,0],
[27,15,14,1],
[27,15,14,2],
[27,15,14,3],
[27,15,14,4],
[27,15,14,5],
[27,15,14,6],
[27,15,14,7],
[27,15,14,8],
[27,15,14,11],
[27,15,14,12],
[27,16,3,0],
[27,16,3,1],
[27,16,3,2],
[27,16,4,0],
[27,16,4,1],
[27,16,4,2],
[27,16,4,3],
[27,16,5,0],
[27,16,5,1],
[27,16,5,2],
[27,16,5,4],
[27,16,6,0],
[27,16,6,1],
[27,16,6,2],
[27,16,6,4],
[27,16,7,0],
[27,16,7,1],
[27,16,7,2],
[27,16,7,3],
[27,16,7,4],
[27,16,7,5],
[27,16,7,6],
[27,16,8,0],
[27,16,8,1],
[27,16,8,2],
[27,16,8,4],
[27,16,8,7],
[27,16,9,0],
[27,16,9,1],
[27,16,9,2],
[27,16,9,3],
[27,16,9,4],
[27,16,9,5],
[27,16,9,6],
[27,16,9,7],
[27,16,9,8],
[27,16,11,0],
[27,16,11,1],
[27,16,11,2],
[27,16,11,3],
[27,16,11,4],
[27,16,11,5],
[27,16,11,6],
[27,16,11,7],
[27,16,11,8],
[27,16,11,9],
[27,16,12,0],
[27,16,12,1],
[27,16,12,2],
[27,16,12,3],
[27,16,12,4],
[27,16,12,5],
[27,16,12,6],
[27,16,12,7],
[27,16,12,8],
[27,16,12,9],
[27,16,13,0],
[27,16,13,1],
[27,16,13,2],
[27,16,13,3],
[27,16,13,4],
[27,16,13,5],
[27,16,13,6],
[27,16,13,7],
[27,16,13,8],
[27,16,13,9],
[27,16,13,11],
[27,16,13,12],
[27,16,14,0],
[27,16,14,1],
[27,16,14,2],
[27,16,14,3],
[27,16,14,4],
[27,16,14,5],
[27,16,14,6],
[27,16,14,7],
[27,16,14,8],
[27,16,14,9],
[27,16,14,11],
[27,16,14,12],
[27,16,15,0],
[27,16,15,1],
[27,16,15,2],
[27,16,15,3],
[27,16,15,4],
[27,16,15,5],
[27,16,15,6],
[27,16,15,7],
[27,16,15,8],
[27,16,15,11],
[27,16,15,12],
[27,16,15,13],
[27,16,15,14],
[27,17,3,0],
[27,17,3,1],
[27,17,3,2],
[27,17,4,0],
[27,17,4,1],
[27,17,4,2],
[27,17,4,3],
[27,17,5,0],
[27,17,5,1],
[27,17,5,2],
[27,17,5,4],
[27,17,6,0],
[27,17,6,1],
[27,17,6,2],
[27,17,6,4],
[27,17,7,0],
[27,17,7,1],
[27,17,7,2],
[27,17,7,3],
[27,17,7,4],
[27,17,7,5],
[27,17,7,6],
[27,17,8,0],
[27,17,8,1],
[27,17,8,2],
[27,17,8,4],
[27,17,8,7],
[27,17,9,0],
[27,17,9,1],
[27,17,9,2],
[27,17,9,3],
[27,17,9,4],
[27,17,9,5],
[27,17,9,6],
[27,17,9,7],
[27,17,9,8],
[27,17,11,0],
[27,17,11,1],
[27,17,11,2],
[27,17,11,3],
[27,17,11,4],
[27,17,11,5],
[27,17,11,6],
[27,17,11,7],
[27,17,11,8],
[27,17,11,9],
[27,17,12,0],
[27,17,12,1],
[27,17,12,2],
[27,17,12,3],
[27,17,12,4],
[27,17,12,5],
[27,17,12,6],
[27,17,12,7],
[27,17,12,8],
[27,17,12,9],
[27,17,13,0],
[27,17,13,1],
[27,17,13,2],
[27,17,13,3],
[27,17,13,4],
[27,17,13,5],
[27,17,13,6],
[27,17,13,7],
[27,17,13,8],
[27,17,13,9],
[27,17,13,11],
[27,17,13,12],
[27,17,14,0],
[27,17,14,1],
[27,17,14,2],
[27,17,14,3],
[27,17,14,4],
[27,17,14,5],
[27,17,14,6],
[27,17,14,7],
[27,17,14,8],
[27,17,14,9],
[27,17,14,11],
[27,17,14,12],
[27,17,15,0],
[27,17,15,1],
[27,17,15,2],
[27,17,15,3],
[27,17,15,4],
[27,17,15,5],
[27,17,15,6],
[27,17,15,7],
[27,17,15,8],
[27,17,15,11],
[27,17,15,12],
[27,17,15,13],
[27,17,15,14],
[27,17,16,0],
[27,17,16,1],
[27,17,16,2],
[27,17,16,3],
[27,17,16,4],
[27,17,16,5],
[27,17,16,6],
[27,17,16,7],
[27,17,16,8],
[27,17,16,9],
[27,17,16,11],
[27,17,16,12],
[27,17,16,13],
[27,17,16,14],
[27,17,16,15],
[27,18,3,0],
[27,18,3,1],
[27,18,3,2],
[27,18,4,0],
[27,18,4,1],
[27,18,4,2],
[27,18,4,3],
[27,18,5,0],
[27,18,5,1],
[27,18,5,2],
[27,18,5,4],
[27,18,6,0],
[27,18,6,1],
[27,18,6,2],
[27,18,6,4],
[27,18,7,0],
[27,18,7,1],
[27,18,7,2],
[27,18,7,3],
[27,18,7,4],
[27,18,7,5],
[27,18,7,6],
[27,18,8,0],
[27,18,8,1],
[27,18,8,2],
[27,18,8,4],
[27,18,8,7],
[27,18,11,0],
[27,18,11,1],
[27,18,11,2],
[27,18,11,3],
[27,18,11,4],
[27,18,11,5],
[27,18,11,6],
[27,18,11,7],
[27,18,11,8],
[27,18,12,0],
[27,18,12,1],
[27,18,12,2],
[27,18,12,3],
[27,18,12,4],
[27,18,12,5],
[27,18,12,6],
[27,18,12,7],
[27,18,12,8],
[27,18,13,0],
[27,18,13,1],
[27,18,13,2],
[27,18,13,3],
[27,18,13,4],
[27,18,13,5],
[27,18,13,6],
[27,18,13,7],
[27,18,13,8],
[27,18,13,11],
[27,18,13,12],
[27,18,14,0],
[27,18,14,1],
[27,18,14,2],
[27,18,14,3],
[27,18,14,4],
[27,18,14,5],
[27,18,14,6],
[27,18,14,7],
[27,18,14,8],
[27,18,14,11],
[27,18,14,12],
[27,18,16,0],
[27,18,16,1],
[27,18,16,2],
[27,18,16,3],
[27,18,16,4],
[27,18,16,5],
[27,18,16,6],
[27,18,16,7],
[27,18,16,8],
[27,18,16,11],
[27,18,16,12],
[27,18,16,13],
[27,18,16,14],
[27,18,17,0],
[27,18,17,1],
[27,18,17,2],
[27,18,17,3],
[27,18,17,4],
[27,18,17,5],
[27,18,17,6],
[27,18,17,7],
[27,18,17,8],
[27,18,17,11],
[27,18,17,12],
[27,18,17,13],
[27,18,17,14],
[27,18,17,16],
[27,19,3,0],
[27,19,3,1],
[27,19,3,2],
[27,19,4,0],
[27,19,4,1],
[27,19,4,2],
[27,19,4,3],
[27,19,5,0],
[27,19,5,1],
[27,19,5,2],
[27,19,5,4],
[27,19,6,0],
[27,19,6,1],
[27,19,6,2],
[27,19,6,4],
[27,19,7,0],
[27,19,7,1],
[27,19,7,2],
[27,19,7,3],
[27,19,7,4],
[27,19,7,5],
[27,19,7,6],
[27,19,8,0],
[27,19,8,1],
[27,19,8,2],
[27,19,8,4],
[27,19,8,7],
[27,19,9,0],
[27,19,9,1],
[27,19,9,2],
[27,19,9,3],
[27,19,9,4],
[27,19,9,5],
[27,19,9,6],
[27,19,9,7],
[27,19,9,8],
[27,19,11,0],
[27,19,11,1],
[27,19,11,2],
[27,19,11,3],
[27,19,11,4],
[27,19,11,5],
[27,19,11,6],
[27,19,11,7],
[27,19,11,8],
[27,19,11,9],
[27,19,12,0],
[27,19,12,1],
[27,19,12,2],
[27,19,12,3],
[27,19,12,4],
[27,19,12,5],
[27,19,12,6],
[27,19,12,7],
[27,19,12,8],
[27,19,12,9],
[27,19,15,0],
[27,19,15,1],
[27,19,15,2],
[27,19,15,3],
[27,19,15,4],
[27,19,15,5],
[27,19,15,6],
[27,19,15,7],
[27,19,15,8],
[27,19,15,11],
[27,19,15,12],
[27,19,16,0],
[27,19,16,1],
[27,19,16,2],
[27,19,16,3],
[27,19,16,4],
[27,19,16,5],
[27,19,16,6],
[27,19,16,7],
[27,19,16,8],
[27,19,16,9],
[27,19,16,11],
[27,19,16,12],
[27,19,16,15],
[27,19,17,0],
[27,19,17,1],
[27,19,17,2],
[27,19,17,3],
[27,19,17,4],
[27,19,17,5],
[27,19,17,6],
[27,19,17,7],
[27,19,17,8],
[27,19,17,9],
[27,19,17,11],
[27,19,17,12],
[27,19,17,15],
[27,19,17,16],
[27,19,18,0],
[27,19,18,1],
[27,19,18,2],
[27,19,18,3],
[27,19,18,4],
[27,19,18,5],
[27,19,18,6],
[27,19,18,7],
[27,19,18,8],
[27,19,18,11],
[27,19,18,12],
[27,19,18,16],
[27,19,18,17],
[27,21,4,0],
[27,21,4,1],
[27,21,4,2],
[27,21,7,0],
[27,21,7,1],
[27,21,7,2],
[27,21,7,4],
[27,21,9,0],
[27,21,9,1],
[27,21,9,2],
[27,21,9,4],
[27,21,9,7],
[27,21,11,0],
[27,21,11,1],
[27,21,11,2],
[27,21,11,4],
[27,21,11,7],
[27,21,11,9],
[27,21,12,0],
[27,21,12,1],
[27,21,12,2],
[27,21,12,4],
[27,21,12,7],
[27,21,12,9],
[27,21,13,0],
[27,21,13,1],
[27,21,13,2],
[27,21,13,4],
[27,21,13,7],
[27,21,13,9],
[27,21,13,11],
[27,21,13,12],
[27,21,14,0],
[27,21,14,1],
[27,21,14,2],
[27,21,14,4],
[27,21,14,7],
[27,21,14,9],
[27,21,14,11],
[27,21,14,12],
[27,21,15,0],
[27,21,15,1],
[27,21,15,2],
[27,21,15,4],
[27,21,15,7],
[27,21,15,11],
[27,21,15,12],
[27,21,15,13],
[27,21,15,14],
[27,21,16,0],
[27,21,16,1],
[27,21,16,2],
[27,21,16,4],
[27,21,16,7],
[27,21,16,9],
[27,21,16,11],
[27,21,16,12],
[27,21,16,13],
[27,21,16,14],
[27,21,16,15],
[27,21,17,0],
[27,21,17,1],
[27,21,17,2],
[27,21,17,4],
[27,21,17,7],
[27,21,17,9],
[27,21,17,11],
[27,21,17,12],
[27,21,17,13],
[27,21,17,14],
[27,21,17,15],
[27,21,17,16],
[27,21,18,0],
[27,21,18,1],
[27,21,18,2],
[27,21,18,4],
[27,21,18,7],
[27,21,18,11],
[27,21,18,12],
[27,21,18,13],
[27,21,18,14],
[27,21,18,16],
[27,21,18,17],
[27,21,19,0],
[27,21,19,1],
[27,21,19,2],
[27,21,19,4],
[27,21,19,7],
[27,21,19,9],
[27,21,19,11],
[27,21,19,12],
[27,21,19,15],
[27,21,19,16],
[27,21,19,17],
[27,21,19,18],
[27,23,3,0],
[27,23,3,1],
[27,23,3,2],
[27,23,4,0],
[27,23,4,1],
[27,23,4,2],
[27,23,4,3],
[27,23,5,0],
[27,23,5,1],
[27,23,5,2],
[27,23,5,4],
[27,23,6,0],
[27,23,6,1],
[27,23,6,2],
[27,23,6,4],
[27,23,7,0],
[27,23,7,1],
[27,23,7,2],
[27,23,7,3],
[27,23,7,4],
[27,23,7,5],
[27,23,7,6],
[27,23,8,0],
[27,23,8,1],
[27,23,8,2],
[27,23,8,4],
[27,23,8,7],
[27,23,9,0],
[27,23,9,1],
[27,23,9,2],
[27,23,9,3],
[27,23,9,4],
[27,23,9,5],
[27,23,9,6],
[27,23,9,7],
[27,23,9,8],
[27,23,11,0],
[27,23,11,1],
[27,23,11,2],
[27,23,11,3],
[27,23,11,4],
[27,23,11,5],
[27,23,11,6],
[27,23,11,7],
[27,23,11,8],
[27,23,11,9],
[27,23,12,0],
[27,23,12,1],
[27,23,12,2],
[27,23,12,3],
[27,23,12,4],
[27,23,12,5],
[27,23,12,6],
[27,23,12,7],
[27,23,12,8],
[27,23,12,9],
[27,23,13,0],
[27,23,13,1],
[27,23,13,2],
[27,23,13,3],
[27,23,13,4],
[27,23,13,5],
[27,23,13,6],
[27,23,13,7],
[27,23,13,8],
[27,23,13,9],
[27,23,13,11],
[27,23,13,12],
[27,23,14,0],
[27,23,14,1],
[27,23,14,2],
[27,23,14,3],
[27,23,14,4],
[27,23,14,5],
[27,23,14,6],
[27,23,14,7],
[27,23,14,8],
[27,23,14,9],
[27,23,14,11],
[27,23,14,12],
[27,23,15,0],
[27,23,15,1],
[27,23,15,2],
[27,23,15,3],
[27,23,15,4],
[27,23,15,5],
[27,23,15,6],
[27,23,15,7],
[27,23,15,8],
[27,23,15,11],
[27,23,15,12],
[27,23,15,13],
[27,23,15,14],
[27,23,16,0],
[27,23,16,1],
[27,23,16,2],
[27,23,16,3],
[27,23,16,4],
[27,23,16,5],
[27,23,16,6],
[27,23,16,7],
[27,23,16,8],
[27,23,16,9],
[27,23,16,11],
[27,23,16,12],
[27,23,16,13],
[27,23,16,14],
[27,23,16,15],
[27,23,17,0],
[27,23,17,1],
[27,23,17,2],
[27,23,17,3],
[27,23,17,4],
[27,23,17,5],
[27,23,17,6],
[27,23,17,7],
[27,23,17,8],
[27,23,17,9],
[27,23,17,11],
[27,23,17,12],
[27,23,17,13],
[27,23,17,14],
[27,23,17,15],
[27,23,17,16],
[27,23,18,0],
[27,23,18,1],
[27,23,18,2],
[27,23,18,3],
[27,23,18,4],
[27,23,18,5],
[27,23,18,6],
[27,23,18,7],
[27,23,18,8],
[27,23,18,11],
[27,23,18,12],
[27,23,18,13],
[27,23,18,14],
[27,23,18,16],
[27,23,18,17],
[27,23,19,0],
[27,23,19,1],
[27,23,19,2],
[27,23,19,3],
[27,23,19,4],
[27,23,19,5],
[27,23,19,6],
[27,23,19,7],
[27,23,19,8],
[27,23,19,9],
[27,23,19,11],
[27,23,19,12],
[27,23,19,15],
[27,23,19,16],
[27,23,19,17],
[27,23,19,18],
[27,23,21,0],
[27,23,21,1],
[27,23,21,2],
[27,23,21,4],
[27,23,21,7],
[27,23,21,9],
[27,23,21,11],
[27,23,21,12],
[27,23,21,13],
[27,23,21,14],
[27,23,21,15],
[27,23,21,16],
[27,23,21,17],
[27,23,21,18],
[27,23,21,19],
[27,24,3,0],
[27,24,3,1],
[27,24,3,2],
[27,24,4,0],
[27,24,4,1],
[27,24,4,2],
[27,24,4,3],
[27,24,5,0],
[27,24,5,1],
[27,24,5,2],
[27,24,5,4],
[27,24,6,0],
[27,24,6,1],
[27,24,6,2],
[27,24,6,4],
[27,24,7,0],
[27,24,7,1],
[27,24,7,2],
[27,24,7,3],
[27,24,7,4],
[27,24,7,5],
[27,24,7,6],
[27,24,8,0],
[27,24,8,1],
[27,24,8,2],
[27,24,8,4],
[27,24,8,7],
[27,24,9,0],
[27,24,9,1],
[27,24,9,2],
[27,24,9,3],
[27,24,9,4],
[27,24,9,5],
[27,24,9,6],
[27,24,9,7],
[27,24,9,8],
[27,24,11,0],
[27,24,11,1],
[27,24,11,2],
[27,24,11,3],
[27,24,11,4],
[27,24,11,5],
[27,24,11,6],
[27,24,11,7],
[27,24,11,8],
[27,24,11,9],
[27,24,12,0],
[27,24,12,1],
[27,24,12,2],
[27,24,12,3],
[27,24,12,4],
[27,24,12,5],
[27,24,12,6],
[27,24,12,7],
[27,24,12,8],
[27,24,12,9],
[27,24,13,0],
[27,24,13,1],
[27,24,13,2],
[27,24,13,3],
[27,24,13,4],
[27,24,13,5],
[27,24,13,6],
[27,24,13,7],
[27,24,13,8],
[27,24,13,9],
[27,24,13,11],
[27,24,13,12],
[27,24,14,0],
[27,24,14,1],
[27,24,14,2],
[27,24,14,3],
[27,24,14,4],
[27,24,14,5],
[27,24,14,6],
[27,24,14,7],
[27,24,14,8],
[27,24,14,9],
[27,24,14,11],
[27,24,14,12],
[27,24,15,0],
[27,24,15,1],
[27,24,15,2],
[27,24,15,3],
[27,24,15,4],
[27,24,15,5],
[27,24,15,6],
[27,24,15,7],
[27,24,15,8],
[27,24,15,11],
[27,24,15,12],
[27,24,15,13],
[27,24,15,14],
[27,24,17,0],
[27,24,17,1],
[27,24,17,2],
[27,24,17,3],
[27,24,17,4],
[27,24,17,5],
[27,24,17,6],
[27,24,17,7],
[27,24,17,8],
[27,24,17,9],
[27,24,17,11],
[27,24,17,12],
[27,24,17,13],
[27,24,17,14],
[27,24,17,15],
[27,24,18,0],
[27,24,18,1],
[27,24,18,2],
[27,24,18,3],
[27,24,18,4],
[27,24,18,5],
[27,24,18,6],
[27,24,18,7],
[27,24,18,8],
[27,24,18,11],
[27,24,18,12],
[27,24,18,13],
[27,24,18,14],
[27,24,18,17],
[27,24,19,0],
[27,24,19,1],
[27,24,19,2],
[27,24,19,3],
[27,24,19,4],
[27,24,19,5],
[27,24,19,6],
[27,24,19,7],
[27,24,19,8],
[27,24,19,9],
[27,24,19,11],
[27,24,19,12],
[27,24,19,15],
[27,24,19,17],
[27,24,19,18],
[27,24,21,0],
[27,24,21,1],
[27,24,21,2],
[27,24,21,4],
[27,24,21,7],
[27,24,21,9],
[27,24,21,11],
[27,24,21,12],
[27,24,21,13],
[27,24,21,14],
[27,24,21,15],
[27,24,21,17],
[27,24,21,18],
[27,24,21,19],
[27,24,23,0],
[27,24,23,1],
[27,24,23,2],
[27,24,23,3],
[27,24,23,4],
[27,24,23,5],
[27,24,23,6],
[27,24,23,7],
[27,24,23,8],
[27,24,23,9],
[27,24,23,11],
[27,24,23,12],
[27,24,23,13],
[27,24,23,14],
[27,24,23,15],
[27,24,23,17],
[27,24,23,18],
[27,24,23,19],
[27,24,23,21],
[27,25,3,0],
[27,25,3,1],
[27,25,3,2],
[27,25,4,0],
[27,25,4,1],
[27,25,4,2],
[27,25,4,3],
[27,25,5,0],
[27,25,5,1],
[27,25,5,2],
[27,25,5,4],
[27,25,6,0],
[27,25,6,1],
[27,25,6,2],
[27,25,6,4],
[27,25,8,0],
[27,25,8,1],
[27,25,8,2],
[27,25,8,4],
[27,25,9,0],
[27,25,9,1],
[27,25,9,2],
[27,25,9,3],
[27,25,9,4],
[27,25,9,5],
[27,25,9,6],
[27,25,9,8],
[27,25,11,0],
[27,25,11,1],
[27,25,11,2],
[27,25,11,3],
[27,25,11,4],
[27,25,11,5],
[27,25,11,6],
[27,25,11,8],
[27,25,11,9],
[27,25,12,0],
[27,25,12,1],
[27,25,12,2],
[27,25,12,3],
[27,25,12,4],
[27,25,12,5],
[27,25,12,6],
[27,25,12,8],
[27,25,12,9],
[27,25,13,0],
[27,25,13,1],
[27,25,13,2],
[27,25,13,3],
[27,25,13,4],
[27,25,13,5],
[27,25,13,6],
[27,25,13,8],
[27,25,13,9],
[27,25,13,11],
[27,25,13,12],
[27,25,14,0],
[27,25,14,1],
[27,25,14,2],
[27,25,14,3],
[27,25,14,4],
[27,25,14,5],
[27,25,14,6],
[27,25,14,8],
[27,25,14,9],
[27,25,14,11],
[27,25,14,12],
[27,25,15,0],
[27,25,15,1],
[27,25,15,2],
[27,25,15,3],
[27,25,15,4],
[27,25,15,5],
[27,25,15,6],
[27,25,15,8],
[27,25,15,11],
[27,25,15,12],
[27,25,15,13],
[27,25,15,14],
[27,25,16,0],
[27,25,16,1],
[27,25,16,2],
[27,25,16,3],
[27,25,16,4],
[27,25,16,5],
[27,25,16,6],
[27,25,16,8],
[27,25,16,9],
[27,25,16,11],
[27,25,16,12],
[27,25,16,13],
[27,25,16,14],
[27,25,16,15],
[27,25,17,0],
[27,25,17,1],
[27,25,17,2],
[27,25,17,3],
[27,25,17,4],
[27,25,17,5],
[27,25,17,6],
[27,25,17,8],
[27,25,17,9],
[27,25,17,11],
[27,25,17,12],
[27,25,17,13],
[27,25,17,14],
[27,25,17,15],
[27,25,17,16],
[27,25,18,0],
[27,25,18,1],
[27,25,18,2],
[27,25,18,3],
[27,25,18,4],
[27,25,18,5],
[27,25,18,6],
[27,25,18,8],
[27,25,18,11],
[27,25,18,12],
[27,25,18,13],
[27,25,18,14],
[27,25,18,16],
[27,25,18,17],
[27,25,19,0],
[27,25,19,1],
[27,25,19,2],
[27,25,19,3],
[27,25,19,4],
[27,25,19,5],
[27,25,19,6],
[27,25,19,8],
[27,25,19,9],
[27,25,19,11],
[27,25,19,12],
[27,25,19,15],
[27,25,19,16],
[27,25,19,17],
[27,25,19,18],
[27,25,21,0],
[27,25,21,1],
[27,25,21,2],
[27,25,21,4],
[27,25,21,9],
[27,25,21,11],
[27,25,21,12],
[27,25,21,13],
[27,25,21,14],
[27,25,21,15],
[27,25,21,16],
[27,25,21,17],
[27,25,21,18],
[27,25,21,19],
[27,25,23,0],
[27,25,23,1],
[27,25,23,2],
[27,25,23,3],
[27,25,23,4],
[27,25,23,5],
[27,25,23,6],
[27,25,23,8],
[27,25,23,9],
[27,25,23,11],
[27,25,23,12],
[27,25,23,13],
[27,25,23,14],
[27,25,23,15],
[27,25,23,16],
[27,25,23,17],
[27,25,23,18],
[27,25,23,19],
[27,25,23,21],
[27,25,24,0],
[27,25,24,1],
[27,25,24,2],
[27,25,24,3],
[27,25,24,4],
[27,25,24,5],
[27,25,24,6],
[27,25,24,8],
[27,25,24,9],
[27,25,24,11],
[27,25,24,12],
[27,25,24,13],
[27,25,24,14],
[27,25,24,15],
[27,25,24,17],
[27,25,24,18],
[27,25,24,19],
[27,25,24,21],
[27,25,24,23],
[27,26,3,0],
[27,26,3,1],
[27,26,3,2],
[27,26,4,0],
[27,26,4,1],
[27,26,4,2],
[27,26,4,3],
[27,26,5,0],
[27,26,5,1],
[27,26,5,2],
[27,26,5,4],
[27,26,6,0],
[27,26,6,1],
[27,26,6,2],
[27,26,6,4],
[27,26,7,0],
[27,26,7,1],
[27,26,7,2],
[27,26,7,3],
[27,26,7,4],
[27,26,7,5],
[27,26,7,6],
[27,26,8,0],
[27,26,8,1],
[27,26,8,2],
[27,26,8,4],
[27,26,8,7],
[27,26,11,0],
[27,26,11,1],
[27,26,11,2],
[27,26,11,3],
[27,26,11,4],
[27,26,11,5],
[27,26,11,6],
[27,26,11,7],
[27,26,11,8],
[27,26,12,0],
[27,26,12,1],
[27,26,12,2],
[27,26,12,3],
[27,26,12,4],
[27,26,12,5],
[27,26,12,6],
[27,26,12,7],
[27,26,12,8],
[27,26,13,0],
[27,26,13,1],
[27,26,13,2],
[27,26,13,3],
[27,26,13,4],
[27,26,13,5],
[27,26,13,6],
[27,26,13,7],
[27,26,13,8],
[27,26,13,11],
[27,26,13,12],
[27,26,14,0],
[27,26,14,1],
[27,26,14,2],
[27,26,14,3],
[27,26,14,4],
[27,26,14,5],
[27,26,14,6],
[27,26,14,7],
[27,26,14,8],
[27,26,14,11],
[27,26,14,12],
[27,26,16,0],
[27,26,16,1],
[27,26,16,2],
[27,26,16,3],
[27,26,16,4],
[27,26,16,5],
[27,26,16,6],
[27,26,16,7],
[27,26,16,8],
[27,26,16,11],
[27,26,16,12],
[27,26,16,13],
[27,26,16,14],
[27,26,17,0],
[27,26,17,1],
[27,26,17,2],
[27,26,17,3],
[27,26,17,4],
[27,26,17,5],
[27,26,17,6],
[27,26,17,7],
[27,26,17,8],
[27,26,17,11],
[27,26,17,12],
[27,26,17,13],
[27,26,17,14],
[27,26,17,16],
[27,26,19,0],
[27,26,19,1],
[27,26,19,2],
[27,26,19,3],
[27,26,19,4],
[27,26,19,5],
[27,26,19,6],
[27,26,19,7],
[27,26,19,8],
[27,26,19,11],
[27,26,19,12],
[27,26,19,16],
[27,26,19,17],
[27,26,21,0],
[27,26,21,1],
[27,26,21,2],
[27,26,21,4],
[27,26,21,7],
[27,26,21,11],
[27,26,21,12],
[27,26,21,13],
[27,26,21,14],
[27,26,21,16],
[27,26,21,17],
[27,26,21,19],
[27,26,23,0],
[27,26,23,1],
[27,26,23,2],
[27,26,23,3],
[27,26,23,4],
[27,26,23,5],
[27,26,23,6],
[27,26,23,7],
[27,26,23,8],
[27,26,23,11],
[27,26,23,12],
[27,26,23,13],
[27,26,23,14],
[27,26,23,16],
[27,26,23,17],
[27,26,23,19],
[27,26,23,21],
[27,26,24,0],
[27,26,24,1],
[27,26,24,2],
[27,26,24,3],
[27,26,24,4],
[27,26,24,5],
[27,26,24,6],
[27,26,24,7],
[27,26,24,8],
[27,26,24,11],
[27,26,24,12],
[27,26,24,13],
[27,26,24,14],
[27,26,24,17],
[27,26,24,19],
[27,26,24,21],
[27,26,24,23],
[27,26,25,0],
[27,26,25,1],
[27,26,25,2],
[27,26,25,3],
[27,26,25,4],
[27,26,25,5],
[27,26,25,6],
[27,26,25,8],
[27,26,25,11],
[27,26,25,12],
[27,26,25,13],
[27,26,25,14],
[27,26,25,16],
[27,26,25,17],
[27,26,25,19],
[27,26,25,21],
[27,26,25,23],
[27,26,25,24],
[28,4,3,0],
[28,4,3,1],
[28,4,3,2],
[28,5,4,0],
[28,5,4,1],
[28,5,4,2],
[28,6,4,0],
[28,6,4,1],
[28,6,4,2],
[28,8,4,0],
[28,8,4,1],
[28,8,4,2],
[28,9,3,0],
[28,9,3,1],
[28,9,3,2],
[28,9,4,0],
[28,9,4,1],
[28,9,4,2],
[28,9,4,3],
[28,9,5,0],
[28,9,5,1],
[28,9,5,2],
[28,9,5,4],
[28,9,6,0],
[28,9,6,1],
[28,9,6,2],
[28,9,6,4],
[28,9,8,0],
[28,9,8,1],
[28,9,8,2],
[28,9,8,4],
[28,10,3,0],
[28,10,3,1],
[28,10,3,2],
[28,10,4,0],
[28,10,4,1],
[28,10,4,2],
[28,10,4,3],
[28,10,5,0],
[28,10,5,1],
[28,10,5,2],
[28,10,5,4],
[28,10,6,0],
[28,10,6,1],
[28,10,6,2],
[28,10,6,4],
[28,10,8,0],
[28,10,8,1],
[28,10,8,2],
[28,10,8,4],
[28,10,9,0],
[28,10,9,1],
[28,10,9,2],
[28,10,9,3],
[28,10,9,4],
[28,10,9,5],
[28,10,9,6],
[28,10,9,8],
[28,11,3,0],
[28,11,3,1],
[28,11,3,2],
[28,11,4,0],
[28,11,4,1],
[28,11,4,2],
[28,11,4,3],
[28,11,5,0],
[28,11,5,1],
[28,11,5,2],
[28,11,5,4],
[28,11,6,0],
[28,11,6,1],
[28,11,6,2],
[28,11,6,4],
[28,11,8,0],
[28,11,8,1],
[28,11,8,2],
[28,11,8,4],
[28,11,9,0],
[28,11,9,1],
[28,11,9,2],
[28,11,9,3],
[28,11,9,4],
[28,11,9,5],
[28,11,9,6],
[28,11,9,8],
[28,11,10,0],
[28,11,10,1],
[28,11,10,2],
[28,11,10,3],
[28,11,10,4],
[28,11,10,5],
[28,11,10,6],
[28,11,10,8],
[28,11,10,9],
[28,12,3,0],
[28,12,3,1],
[28,12,3,2],
[28,12,4,0],
[28,12,4,1],
[28,12,4,2],
[28,12,4,3],
[28,12,5,0],
[28,12,5,1],
[28,12,5,2],
[28,12,5,4],
[28,12,6,0],
[28,12,6,1],
[28,12,6,2],
[28,12,6,4],
[28,12,8,0],
[28,12,8,1],
[28,12,8,2],
[28,12,8,4],
[28,12,9,0],
[28,12,9,1],
[28,12,9,2],
[28,12,9,3],
[28,12,9,4],
[28,12,9,5],
[28,12,9,6],
[28,12,9,8],
[28,12,10,0],
[28,12,10,1],
[28,12,10,2],
[28,12,10,3],
[28,12,10,4],
[28,12,10,5],
[28,12,10,6],
[28,12,10,8],
[28,12,10,9],
[28,13,3,0],
[28,13,3,1],
[28,13,3,2],
[28,13,4,0],
[28,13,4,1],
[28,13,4,2],
[28,13,4,3],
[28,13,5,0],
[28,13,5,1],
[28,13,5,2],
[28,13,5,4],
[28,13,6,0],
[28,13,6,1],
[28,13,6,2],
[28,13,6,4],
[28,13,8,0],
[28,13,8,1],
[28,13,8,2],
[28,13,8,4],
[28,13,9,0],
[28,13,9,1],
[28,13,9,2],
[28,13,9,3],
[28,13,9,4],
[28,13,9,5],
[28,13,9,6],
[28,13,9,8],
[28,13,10,0],
[28,13,10,1],
[28,13,10,2],
[28,13,10,3],
[28,13,10,4],
[28,13,10,5],
[28,13,10,6],
[28,13,10,8],
[28,13,10,9],
[28,13,11,0],
[28,13,11,1],
[28,13,11,2],
[28,13,11,3],
[28,13,11,4],
[28,13,11,5],
[28,13,11,6],
[28,13,11,8],
[28,13,11,9],
[28,13,11,10],
[28,13,12,0],
[28,13,12,1],
[28,13,12,2],
[28,13,12,3],
[28,13,12,4],
[28,13,12,5],
[28,13,12,6],
[28,13,12,8],
[28,13,12,9],
[28,13,12,10],
[28,14,3,0],
[28,14,3,1],
[28,14,3,2],
[28,14,4,0],
[28,14,4,1],
[28,14,4,2],
[28,14,4,3],
[28,14,5,0],
[28,14,5,1],
[28,14,5,2],
[28,14,5,4],
[28,14,6,0],
[28,14,6,1],
[28,14,6,2],
[28,14,6,4],
[28,14,8,0],
[28,14,8,1],
[28,14,8,2],
[28,14,8,4],
[28,14,9,0],
[28,14,9,1],
[28,14,9,2],
[28,14,9,3],
[28,14,9,4],
[28,14,9,5],
[28,14,9,6],
[28,14,9,8],
[28,14,10,0],
[28,14,10,1],
[28,14,10,2],
[28,14,10,3],
[28,14,10,4],
[28,14,10,5],
[28,14,10,6],
[28,14,10,8],
[28,14,10,9],
[28,14,11,0],
[28,14,11,1],
[28,14,11,2],
[28,14,11,3],
[28,14,11,4],
[28,14,11,5],
[28,14,11,6],
[28,14,11,8],
[28,14,11,9],
[28,14,11,10],
[28,14,12,0],
[28,14,12,1],
[28,14,12,2],
[28,14,12,3],
[28,14,12,4],
[28,14,12,5],
[28,14,12,6],
[28,14,12,8],
[28,14,12,9],
[28,14,12,10],
[28,15,3,0],
[28,15,3,1],
[28,15,3,2],
[28,15,4,0],
[28,15,4,1],
[28,15,4,2],
[28,15,4,3],
[28,15,5,0],
[28,15,5,1],
[28,15,5,2],
[28,15,5,4],
[28,15,6,0],
[28,15,6,1],
[28,15,6,2],
[28,15,6,4],
[28,15,8,0],
[28,15,8,1],
[28,15,8,2],
[28,15,8,4],
[28,15,10,0],
[28,15,10,1],
[28,15,10,2],
[28,15,10,3],
[28,15,10,4],
[28,15,10,5],
[28,15,10,6],
[28,15,10,8],
[28,15,11,0],
[28,15,11,1],
[28,15,11,2],
[28,15,11,3],
[28,15,11,4],
[28,15,11,5],
[28,15,11,6],
[28,15,11,8],
[28,15,11,10],
[28,15,12,0],
[28,15,12,1],
[28,15,12,2],
[28,15,12,3],
[28,15,12,4],
[28,15,12,5],
[28,15,12,6],
[28,15,12,8],
[28,15,12,10],
[28,15,13,0],
[28,15,13,1],
[28,15,13,2],
[28,15,13,3],
[28,15,13,4],
[28,15,13,5],
[28,15,13,6],
[28,15,13,8],
[28,15,13,10],
[28,15,13,11],
[28,15,13,12],
[28,15,14,0],
[28,15,14,1],
[28,15,14,2],
[28,15,14,3],
[28,15,14,4],
[28,15,14,5],
[28,15,14,6],
[28,15,14,8],
[28,15,14,10],
[28,15,14,11],
[28,15,14,12],
[28,16,3,0],
[28,16,3,1],
[28,16,3,2],
[28,16,4,0],
[28,16,4,1],
[28,16,4,2],
[28,16,4,3],
[28,16,5,0],
[28,16,5,1],
[28,16,5,2],
[28,16,5,4],
[28,16,6,0],
[28,16,6,1],
[28,16,6,2],
[28,16,6,4],
[28,16,8,0],
[28,16,8,1],
[28,16,8,2],
[28,16,8,4],
[28,16,9,0],
[28,16,9,1],
[28,16,9,2],
[28,16,9,3],
[28,16,9,4],
[28,16,9,5],
[28,16,9,6],
[28,16,9,8],
[28,16,10,0],
[28,16,10,1],
[28,16,10,2],
[28,16,10,3],
[28,16,10,4],
[28,16,10,5],
[28,16,10,6],
[28,16,10,8],
[28,16,10,9],
[28,16,11,0],
[28,16,11,1],
[28,16,11,2],
[28,16,11,3],
[28,16,11,4],
[28,16,11,5],
[28,16,11,6],
[28,16,11,8],
[28,16,11,9],
[28,16,11,10],
[28,16,12,0],
[28,16,12,1],
[28,16,12,2],
[28,16,12,3],
[28,16,12,4],
[28,16,12,5],
[28,16,12,6],
[28,16,12,8],
[28,16,12,9],
[28,16,12,10],
[28,16,13,0],
[28,16,13,1],
[28,16,13,2],
[28,16,13,3],
[28,16,13,4],
[28,16,13,5],
[28,16,13,6],
[28,16,13,8],
[28,16,13,9],
[28,16,13,10],
[28,16,13,11],
[28,16,13,12],
[28,16,14,0],
[28,16,14,1],
[28,16,14,2],
[28,16,14,3],
[28,16,14,4],
[28,16,14,5],
[28,16,14,6],
[28,16,14,8],
[28,16,14,9],
[28,16,14,10],
[28,16,14,11],
[28,16,14,12],
[28,16,15,0],
[28,16,15,1],
[28,16,15,2],
[28,16,15,3],
[28,16,15,4],
[28,16,15,5],
[28,16,15,6],
[28,16,15,8],
[28,16,15,10],
[28,16,15,11],
[28,16,15,12],
[28,16,15,13],
[28,16,15,14],
[28,17,3,0],
[28,17,3,1],
[28,17,3,2],
[28,17,4,0],
[28,17,4,1],
[28,17,4,2],
[28,17,4,3],
[28,17,5,0],
[28,17,5,1],
[28,17,5,2],
[28,17,5,4],
[28,17,6,0],
[28,17,6,1],
[28,17,6,2],
[28,17,6,4],
[28,17,8,0],
[28,17,8,1],
[28,17,8,2],
[28,17,8,4],
[28,17,9,0],
[28,17,9,1],
[28,17,9,2],
[28,17,9,3],
[28,17,9,4],
[28,17,9,5],
[28,17,9,6],
[28,17,9,8],
[28,17,10,0],
[28,17,10,1],
[28,17,10,2],
[28,17,10,3],
[28,17,10,4],
[28,17,10,5],
[28,17,10,6],
[28,17,10,8],
[28,17,10,9],
[28,17,11,0],
[28,17,11,1],
[28,17,11,2],
[28,17,11,3],
[28,17,11,4],
[28,17,11,5],
[28,17,11,6],
[28,17,11,8],
[28,17,11,9],
[28,17,11,10],
[28,17,12,0],
[28,17,12,1],
[28,17,12,2],
[28,17,12,3],
[28,17,12,4],
[28,17,12,5],
[28,17,12,6],
[28,17,12,8],
[28,17,12,9],
[28,17,12,10],
[28,17,13,0],
[28,17,13,1],
[28,17,13,2],
[28,17,13,3],
[28,17,13,4],
[28,17,13,5],
[28,17,13,6],
[28,17,13,8],
[28,17,13,9],
[28,17,13,10],
[28,17,13,11],
[28,17,13,12],
[28,17,14,0],
[28,17,14,1],
[28,17,14,2],
[28,17,14,3],
[28,17,14,4],
[28,17,14,5],
[28,17,14,6],
[28,17,14,8],
[28,17,14,9],
[28,17,14,10],
[28,17,14,11],
[28,17,14,12],
[28,17,15,0],
[28,17,15,1],
[28,17,15,2],
[28,17,15,3],
[28,17,15,4],
[28,17,15,5],
[28,17,15,6],
[28,17,15,8],
[28,17,15,10],
[28,17,15,11],
[28,17,15,12],
[28,17,15,13],
[28,17,15,14],
[28,17,16,0],
[28,17,16,1],
[28,17,16,2],
[28,17,16,3],
[28,17,16,4],
[28,17,16,5],
[28,17,16,6],
[28,17,16,8],
[28,17,16,9],
[28,17,16,10],
[28,17,16,11],
[28,17,16,12],
[28,17,16,13],
[28,17,16,14],
[28,17,16,15],
[28,18,3,0],
[28,18,3,1],
[28,18,3,2],
[28,18,4,0],
[28,18,4,1],
[28,18,4,2],
[28,18,4,3],
[28,18,5,0],
[28,18,5,1],
[28,18,5,2],
[28,18,5,4],
[28,18,6,0],
[28,18,6,1],
[28,18,6,2],
[28,18,6,4],
[28,18,8,0],
[28,18,8,1],
[28,18,8,2],
[28,18,8,4],
[28,18,10,0],
[28,18,10,1],
[28,18,10,2],
[28,18,10,3],
[28,18,10,4],
[28,18,10,5],
[28,18,10,6],
[28,18,10,8],
[28,18,11,0],
[28,18,11,1],
[28,18,11,2],
[28,18,11,3],
[28,18,11,4],
[28,18,11,5],
[28,18,11,6],
[28,18,11,8],
[28,18,11,10],
[28,18,12,0],
[28,18,12,1],
[28,18,12,2],
[28,18,12,3],
[28,18,12,4],
[28,18,12,5],
[28,18,12,6],
[28,18,12,8],
[28,18,12,10],
[28,18,13,0],
[28,18,13,1],
[28,18,13,2],
[28,18,13,3],
[28,18,13,4],
[28,18,13,5],
[28,18,13,6],
[28,18,13,8],
[28,18,13,10],
[28,18,13,11],
[28,18,13,12],
[28,18,14,0],
[28,18,14,1],
[28,18,14,2],
[28,18,14,3],
[28,18,14,4],
[28,18,14,5],
[28,18,14,6],
[28,18,14,8],
[28,18,14,10],
[28,18,14,11],
[28,18,14,12],
[28,18,16,0],
[28,18,16,1],
[28,18,16,2],
[28,18,16,3],
[28,18,16,4],
[28,18,16,5],
[28,18,16,6],
[28,18,16,8],
[28,18,16,10],
[28,18,16,11],
[28,18,16,12],
[28,18,16,13],
[28,18,16,14],
[28,18,17,0],
[28,18,17,1],
[28,18,17,2],
[28,18,17,3],
[28,18,17,4],
[28,18,17,5],
[28,18,17,6],
[28,18,17,8],
[28,18,17,10],
[28,18,17,11],
[28,18,17,12],
[28,18,17,13],
[28,18,17,14],
[28,18,17,16],
[28,19,3,0],
[28,19,3,1],
[28,19,3,2],
[28,19,4,0],
[28,19,4,1],
[28,19,4,2],
[28,19,4,3],
[28,19,5,0],
[28,19,5,1],
[28,19,5,2],
[28,19,5,4],
[28,19,6,0],
[28,19,6,1],
[28,19,6,2],
[28,19,6,4],
[28,19,8,0],
[28,19,8,1],
[28,19,8,2],
[28,19,8,4],
[28,19,9,0],
[28,19,9,1],
[28,19,9,2],
[28,19,9,3],
[28,19,9,4],
[28,19,9,5],
[28,19,9,6],
[28,19,9,8],
[28,19,10,0],
[28,19,10,1],
[28,19,10,2],
[28,19,10,3],
[28,19,10,4],
[28,19,10,5],
[28,19,10,6],
[28,19,10,8],
[28,19,10,9],
[28,19,11,0],
[28,19,11,1],
[28,19,11,2],
[28,19,11,3],
[28,19,11,4],
[28,19,11,5],
[28,19,11,6],
[28,19,11,8],
[28,19,11,9],
[28,19,11,10],
[28,19,12,0],
[28,19,12,1],
[28,19,12,2],
[28,19,12,3],
[28,19,12,4],
[28,19,12,5],
[28,19,12,6],
[28,19,12,8],
[28,19,12,9],
[28,19,12,10],
[28,19,15,0],
[28,19,15,1],
[28,19,15,2],
[28,19,15,3],
[28,19,15,4],
[28,19,15,5],
[28,19,15,6],
[28,19,15,8],
[28,19,15,10],
[28,19,15,11],
[28,19,15,12],
[28,19,16,0],
[28,19,16,1],
[28,19,16,2],
[28,19,16,3],
[28,19,16,4],
[28,19,16,5],
[28,19,16,6],
[28,19,16,8],
[28,19,16,9],
[28,19,16,10],
[28,19,16,11],
[28,19,16,12],
[28,19,16,15],
[28,19,17,0],
[28,19,17,1],
[28,19,17,2],
[28,19,17,3],
[28,19,17,4],
[28,19,17,5],
[28,19,17,6],
[28,19,17,8],
[28,19,17,9],
[28,19,17,10],
[28,19,17,11],
[28,19,17,12],
[28,19,17,15],
[28,19,17,16],
[28,19,18,0],
[28,19,18,1],
[28,19,18,2],
[28,19,18,3],
[28,19,18,4],
[28,19,18,5],
[28,19,18,6],
[28,19,18,8],
[28,19,18,10],
[28,19,18,11],
[28,19,18,12],
[28,19,18,16],
[28,19,18,17],
[28,20,3,0],
[28,20,3,1],
[28,20,3,2],
[28,20,4,0],
[28,20,4,1],
[28,20,4,2],
[28,20,4,3],
[28,20,5,0],
[28,20,5,1],
[28,20,5,2],
[28,20,5,4],
[28,20,6,0],
[28,20,6,1],
[28,20,6,2],
[28,20,6,4],
[28,20,8,0],
[28,20,8,1],
[28,20,8,2],
[28,20,8,4],
[28,20,9,0],
[28,20,9,1],
[28,20,9,2],
[28,20,9,3],
[28,20,9,4],
[28,20,9,5],
[28,20,9,6],
[28,20,9,8],
[28,20,11,0],
[28,20,11,1],
[28,20,11,2],
[28,20,11,3],
[28,20,11,4],
[28,20,11,5],
[28,20,11,6],
[28,20,11,8],
[28,20,11,9],
[28,20,12,0],
[28,20,12,1],
[28,20,12,2],
[28,20,12,3],
[28,20,12,4],
[28,20,12,5],
[28,20,12,6],
[28,20,12,8],
[28,20,12,9],
[28,20,13,0],
[28,20,13,1],
[28,20,13,2],
[28,20,13,3],
[28,20,13,4],
[28,20,13,5],
[28,20,13,6],
[28,20,13,8],
[28,20,13,9],
[28,20,13,11],
[28,20,13,12],
[28,20,14,0],
[28,20,14,1],
[28,20,14,2],
[28,20,14,3],
[28,20,14,4],
[28,20,14,5],
[28,20,14,6],
[28,20,14,8],
[28,20,14,9],
[28,20,14,11],
[28,20,14,12],
[28,20,15,0],
[28,20,15,1],
[28,20,15,2],
[28,20,15,3],
[28,20,15,4],
[28,20,15,5],
[28,20,15,6],
[28,20,15,8],
[28,20,15,11],
[28,20,15,12],
[28,20,15,13],
[28,20,15,14],
[28,20,16,0],
[28,20,16,1],
[28,20,16,2],
[28,20,16,3],
[28,20,16,4],
[28,20,16,5],
[28,20,16,6],
[28,20,16,8],
[28,20,16,9],
[28,20,16,11],
[28,20,16,12],
[28,20,16,13],
[28,20,16,14],
[28,20,16,15],
[28,20,17,0],
[28,20,17,1],
[28,20,17,2],
[28,20,17,3],
[28,20,17,4],
[28,20,17,5],
[28,20,17,6],
[28,20,17,8],
[28,20,17,9],
[28,20,17,11],
[28,20,17,12],
[28,20,17,13],
[28,20,17,14],
[28,20,17,15],
[28,20,17,16],
[28,20,18,0],
[28,20,18,1],
[28,20,18,2],
[28,20,18,3],
[28,20,18,4],
[28,20,18,5],
[28,20,18,6],
[28,20,18,8],
[28,20,18,11],
[28,20,18,12],
[28,20,18,13],
[28,20,18,14],
[28,20,18,16],
[28,20,18,17],
[28,20,19,0],
[28,20,19,1],
[28,20,19,2],
[28,20,19,3],
[28,20,19,4],
[28,20,19,5],
[28,20,19,6],
[28,20,19,8],
[28,20,19,9],
[28,20,19,11],
[28,20,19,12],
[28,20,19,15],
[28,20,19,16],
[28,20,19,17],
[28,20,19,18],
[28,21,4,0],
[28,21,4,1],
[28,21,4,2],
[28,21,9,0],
[28,21,9,1],
[28,21,9,2],
[28,21,9,4],
[28,21,10,0],
[28,21,10,1],
[28,21,10,2],
[28,21,10,4],
[28,21,10,9],
[28,21,11,0],
[28,21,11,1],
[28,21,11,2],
[28,21,11,4],
[28,21,11,9],
[28,21,11,10],
[28,21,12,0],
[28,21,12,1],
[28,21,12,2],
[28,21,12,4],
[28,21,12,9],
[28,21,12,10],
[28,21,13,0],
[28,21,13,1],
[28,21,13,2],
[28,21,13,4],
[28,21,13,9],
[28,21,13,10],
[28,21,13,11],
[28,21,13,12],
[28,21,14,0],
[28,21,14,1],
[28,21,14,2],
[28,21,14,4],
[28,21,14,9],
[28,21,14,10],
[28,21,14,11],
[28,21,14,12],
[28,21,15,0],
[28,21,15,1],
[28,21,15,2],
[28,21,15,4],
[28,21,15,10],
[28,21,15,11],
[28,21,15,12],
[28,21,15,13],
[28,21,15,14],
[28,21,16,0],
[28,21,16,1],
[28,21,16,2],
[28,21,16,4],
[28,21,16,9],
[28,21,16,10],
[28,21,16,11],
[28,21,16,12],
[28,21,16,13],
[28,21,16,14],
[28,21,16,15],
[28,21,17,0],
[28,21,17,1],
[28,21,17,2],
[28,21,17,4],
[28,21,17,9],
[28,21,17,10],
[28,21,17,11],
[28,21,17,12],
[28,21,17,13],
[28,21,17,14],
[28,21,17,15],
[28,21,17,16],
[28,21,18,0],
[28,21,18,1],
[28,21,18,2],
[28,21,18,4],
[28,21,18,10],
[28,21,18,11],
[28,21,18,12],
[28,21,18,13],
[28,21,18,14],
[28,21,18,16],
[28,21,18,17],
[28,21,19,0],
[28,21,19,1],
[28,21,19,2],
[28,21,19,4],
[28,21,19,9],
[28,21,19,10],
[28,21,19,11],
[28,21,19,12],
[28,21,19,15],
[28,21,19,16],
[28,21,19,17],
[28,21,19,18],
[28,21,20,0],
[28,21,20,1],
[28,21,20,2],
[28,21,20,4],
[28,21,20,9],
[28,21,20,11],
[28,21,20,12],
[28,21,20,13],
[28,21,20,14],
[28,21,20,15],
[28,21,20,16],
[28,21,20,17],
[28,21,20,18],
[28,21,20,19],
[28,22,3,0],
[28,22,3,1],
[28,22,3,2],
[28,22,4,0],
[28,22,4,1],
[28,22,4,2],
[28,22,4,3],
[28,22,5,0],
[28,22,5,1],
[28,22,5,2],
[28,22,5,4],
[28,22,6,0],
[28,22,6,1],
[28,22,6,2],
[28,22,6,4],
[28,22,8,0],
[28,22,8,1],
[28,22,8,2],
[28,22,8,4],
[28,22,9,0],
[28,22,9,1],
[28,22,9,2],
[28,22,9,3],
[28,22,9,4],
[28,22,9,5],
[28,22,9,6],
[28,22,9,8],
[28,22,11,0],
[28,22,11,1],
[28,22,11,2],
[28,22,11,3],
[28,22,11,4],
[28,22,11,5],
[28,22,11,6],
[28,22,11,8],
[28,22,11,9],
[28,22,12,0],
[28,22,12,1],
[28,22,12,2],
[28,22,12,3],
[28,22,12,4],
[28,22,12,5],
[28,22,12,6],
[28,22,12,8],
[28,22,12,9],
[28,22,13,0],
[28,22,13,1],
[28,22,13,2],
[28,22,13,3],
[28,22,13,4],
[28,22,13,5],
[28,22,13,6],
[28,22,13,8],
[28,22,13,9],
[28,22,13,11],
[28,22,13,12],
[28,22,14,0],
[28,22,14,1],
[28,22,14,2],
[28,22,14,3],
[28,22,14,4],
[28,22,14,5],
[28,22,14,6],
[28,22,14,8],
[28,22,14,9],
[28,22,14,11],
[28,22,14,12],
[28,22,15,0],
[28,22,15,1],
[28,22,15,2],
[28,22,15,3],
[28,22,15,4],
[28,22,15,5],
[28,22,15,6],
[28,22,15,8],
[28,22,15,11],
[28,22,15,12],
[28,22,15,13],
[28,22,15,14],
[28,22,16,0],
[28,22,16,1],
[28,22,16,2],
[28,22,16,3],
[28,22,16,4],
[28,22,16,5],
[28,22,16,6],
[28,22,16,8],
[28,22,16,9],
[28,22,16,11],
[28,22,16,12],
[28,22,16,13],
[28,22,16,14],
[28,22,16,15],
[28,22,17,0],
[28,22,17,1],
[28,22,17,2],
[28,22,17,3],
[28,22,17,4],
[28,22,17,5],
[28,22,17,6],
[28,22,17,8],
[28,22,17,9],
[28,22,17,11],
[28,22,17,12],
[28,22,17,13],
[28,22,17,14],
[28,22,17,15],
[28,22,17,16],
[28,22,18,0],
[28,22,18,1],
[28,22,18,2],
[28,22,18,3],
[28,22,18,4],
[28,22,18,5],
[28,22,18,6],
[28,22,18,8],
[28,22,18,11],
[28,22,18,12],
[28,22,18,13],
[28,22,18,14],
[28,22,18,16],
[28,22,18,17],
[28,22,19,0],
[28,22,19,1],
[28,22,19,2],
[28,22,19,3],
[28,22,19,4],
[28,22,19,5],
[28,22,19,6],
[28,22,19,8],
[28,22,19,9],
[28,22,19,11],
[28,22,19,12],
[28,22,19,15],
[28,22,19,16],
[28,22,19,17],
[28,22,19,18],
[28,22,21,0],
[28,22,21,1],
[28,22,21,2],
[28,22,21,4],
[28,22,21,9],
[28,22,21,11],
[28,22,21,12],
[28,22,21,13],
[28,22,21,14],
[28,22,21,15],
[28,22,21,16],
[28,22,21,17],
[28,22,21,18],
[28,22,21,19],
[28,23,3,0],
[28,23,3,1],
[28,23,3,2],
[28,23,4,0],
[28,23,4,1],
[28,23,4,2],
[28,23,4,3],
[28,23,5,0],
[28,23,5,1],
[28,23,5,2],
[28,23,5,4],
[28,23,6,0],
[28,23,6,1],
[28,23,6,2],
[28,23,6,4],
[28,23,8,0],
[28,23,8,1],
[28,23,8,2],
[28,23,8,4],
[28,23,9,0],
[28,23,9,1],
[28,23,9,2],
[28,23,9,3],
[28,23,9,4],
[28,23,9,5],
[28,23,9,6],
[28,23,9,8],
[28,23,10,0],
[28,23,10,1],
[28,23,10,2],
[28,23,10,3],
[28,23,10,4],
[28,23,10,5],
[28,23,10,6],
[28,23,10,8],
[28,23,10,9],
[28,23,11,0],
[28,23,11,1],
[28,23,11,2],
[28,23,11,3],
[28,23,11,4],
[28,23,11,5],
[28,23,11,6],
[28,23,11,8],
[28,23,11,9],
[28,23,11,10],
[28,23,12,0],
[28,23,12,1],
[28,23,12,2],
[28,23,12,3],
[28,23,12,4],
[28,23,12,5],
[28,23,12,6],
[28,23,12,8],
[28,23,12,9],
[28,23,12,10],
[28,23,13,0],
[28,23,13,1],
[28,23,13,2],
[28,23,13,3],
[28,23,13,4],
[28,23,13,5],
[28,23,13,6],
[28,23,13,8],
[28,23,13,9],
[28,23,13,10],
[28,23,13,11],
[28,23,13,12],
[28,23,14,0],
[28,23,14,1],
[28,23,14,2],
[28,23,14,3],
[28,23,14,4],
[28,23,14,5],
[28,23,14,6],
[28,23,14,8],
[28,23,14,9],
[28,23,14,10],
[28,23,14,11],
[28,23,14,12],
[28,23,15,0],
[28,23,15,1],
[28,23,15,2],
[28,23,15,3],
[28,23,15,4],
[28,23,15,5],
[28,23,15,6],
[28,23,15,8],
[28,23,15,10],
[28,23,15,11],
[28,23,15,12],
[28,23,15,13],
[28,23,15,14],
[28,23,16,0],
[28,23,16,1],
[28,23,16,2],
[28,23,16,3],
[28,23,16,4],
[28,23,16,5],
[28,23,16,6],
[28,23,16,8],
[28,23,16,9],
[28,23,16,10],
[28,23,16,11],
[28,23,16,12],
[28,23,16,13],
[28,23,16,14],
[28,23,16,15],
[28,23,17,0],
[28,23,17,1],
[28,23,17,2],
[28,23,17,3],
[28,23,17,4],
[28,23,17,5],
[28,23,17,6],
[28,23,17,8],
[28,23,17,9],
[28,23,17,10],
[28,23,17,11],
[28,23,17,12],
[28,23,17,13],
[28,23,17,14],
[28,23,17,15],
[28,23,17,16],
[28,23,18,0],
[28,23,18,1],
[28,23,18,2],
[28,23,18,3],
[28,23,18,4],
[28,23,18,5],
[28,23,18,6],
[28,23,18,8],
[28,23,18,10],
[28,23,18,11],
[28,23,18,12],
[28,23,18,13],
[28,23,18,14],
[28,23,18,16],
[28,23,18,17],
[28,23,19,0],
[28,23,19,1],
[28,23,19,2],
[28,23,19,3],
[28,23,19,4],
[28,23,19,5],
[28,23,19,6],
[28,23,19,8],
[28,23,19,9],
[28,23,19,10],
[28,23,19,11],
[28,23,19,12],
[28,23,19,15],
[28,23,19,16],
[28,23,19,17],
[28,23,19,18],
[28,23,20,0],
[28,23,20,1],
[28,23,20,2],
[28,23,20,3],
[28,23,20,4],
[28,23,20,5],
[28,23,20,6],
[28,23,20,8],
[28,23,20,9],
[28,23,20,11],
[28,23,20,12],
[28,23,20,13],
[28,23,20,14],
[28,23,20,15],
[28,23,20,16],
[28,23,20,17],
[28,23,20,18],
[28,23,20,19],
[28,23,21,0],
[28,23,21,1],
[28,23,21,2],
[28,23,21,4],
[28,23,21,9],
[28,23,21,10],
[28,23,21,11],
[28,23,21,12],
[28,23,21,13],
[28,23,21,14],
[28,23,21,15],
[28,23,21,16],
[28,23,21,17],
[28,23,21,18],
[28,23,21,19],
[28,23,21,20],
[28,23,22,0],
[28,23,22,1],
[28,23,22,2],
[28,23,22,3],
[28,23,22,4],
[28,23,22,5],
[28,23,22,6],
[28,23,22,8],
[28,23,22,9],
[28,23,22,11],
[28,23,22,12],
[28,23,22,13],
[28,23,22,14],
[28,23,22,15],
[28,23,22,16],
[28,23,22,17],
[28,23,22,18],
[28,23,22,19],
[28,23,22,21],
[28,24,3,0],
[28,24,3,1],
[28,24,3,2],
[28,24,4,0],
[28,24,4,1],
[28,24,4,2],
[28,24,4,3],
[28,24,5,0],
[28,24,5,1],
[28,24,5,2],
[28,24,5,4],
[28,24,6,0],
[28,24,6,1],
[28,24,6,2],
[28,24,6,4],
[28,24,8,0],
[28,24,8,1],
[28,24,8,2],
[28,24,8,4],
[28,24,9,0],
[28,24,9,1],
[28,24,9,2],
[28,24,9,3],
[28,24,9,4],
[28,24,9,5],
[28,24,9,6],
[28,24,9,8],
[28,24,10,0],
[28,24,10,1],
[28,24,10,2],
[28,24,10,3],
[28,24,10,4],
[28,24,10,5],
[28,24,10,6],
[28,24,10,8],
[28,24,10,9],
[28,24,11,0],
[28,24,11,1],
[28,24,11,2],
[28,24,11,3],
[28,24,11,4],
[28,24,11,5],
[28,24,11,6],
[28,24,11,8],
[28,24,11,9],
[28,24,11,10],
[28,24,12,0],
[28,24,12,1],
[28,24,12,2],
[28,24,12,3],
[28,24,12,4],
[28,24,12,5],
[28,24,12,6],
[28,24,12,8],
[28,24,12,9],
[28,24,12,10],
[28,24,13,0],
[28,24,13,1],
[28,24,13,2],
[28,24,13,3],
[28,24,13,4],
[28,24,13,5],
[28,24,13,6],
[28,24,13,8],
[28,24,13,9],
[28,24,13,10],
[28,24,13,11],
[28,24,13,12],
[28,24,14,0],
[28,24,14,1],
[28,24,14,2],
[28,24,14,3],
[28,24,14,4],
[28,24,14,5],
[28,24,14,6],
[28,24,14,8],
[28,24,14,9],
[28,24,14,10],
[28,24,14,11],
[28,24,14,12],
[28,24,15,0],
[28,24,15,1],
[28,24,15,2],
[28,24,15,3],
[28,24,15,4],
[28,24,15,5],
[28,24,15,6],
[28,24,15,8],
[28,24,15,10],
[28,24,15,11],
[28,24,15,12],
[28,24,15,13],
[28,24,15,14],
[28,24,17,0],
[28,24,17,1],
[28,24,17,2],
[28,24,17,3],
[28,24,17,4],
[28,24,17,5],
[28,24,17,6],
[28,24,17,8],
[28,24,17,9],
[28,24,17,10],
[28,24,17,11],
[28,24,17,12],
[28,24,17,13],
[28,24,17,14],
[28,24,17,15],
[28,24,18,0],
[28,24,18,1],
[28,24,18,2],
[28,24,18,3],
[28,24,18,4],
[28,24,18,5],
[28,24,18,6],
[28,24,18,8],
[28,24,18,10],
[28,24,18,11],
[28,24,18,12],
[28,24,18,13],
[28,24,18,14],
[28,24,18,17],
[28,24,19,0],
[28,24,19,1],
[28,24,19,2],
[28,24,19,3],
[28,24,19,4],
[28,24,19,5],
[28,24,19,6],
[28,24,19,8],
[28,24,19,9],
[28,24,19,10],
[28,24,19,11],
[28,24,19,12],
[28,24,19,15],
[28,24,19,17],
[28,24,19,18],
[28,24,20,0],
[28,24,20,1],
[28,24,20,2],
[28,24,20,3],
[28,24,20,4],
[28,24,20,5],
[28,24,20,6],
[28,24,20,8],
[28,24,20,9],
[28,24,20,11],
[28,24,20,12],
[28,24,20,13],
[28,24,20,14],
[28,24,20,15],
[28,24,20,17],
[28,24,20,18],
[28,24,20,19],
[28,24,21,0],
[28,24,21,1],
[28,24,21,2],
[28,24,21,4],
[28,24,21,9],
[28,24,21,10],
[28,24,21,11],
[28,24,21,12],
[28,24,21,13],
[28,24,21,14],
[28,24,21,15],
[28,24,21,17],
[28,24,21,18],
[28,24,21,19],
[28,24,21,20],
[28,24,22,0],
[28,24,22,1],
[28,24,22,2],
[28,24,22,3],
[28,24,22,4],
[28,24,22,5],
[28,24,22,6],
[28,24,22,8],
[28,24,22,9],
[28,24,22,11],
[28,24,22,12],
[28,24,22,13],
[28,24,22,14],
[28,24,22,15],
[28,24,22,17],
[28,24,22,18],
[28,24,22,19],
[28,24,22,21],
[28,24,23,0],
[28,24,23,1],
[28,24,23,2],
[28,24,23,3],
[28,24,23,4],
[28,24,23,5],
[28,24,23,6],
[28,24,23,8],
[28,24,23,9],
[28,24,23,10],
[28,24,23,11],
[28,24,23,12],
[28,24,23,13],
[28,24,23,14],
[28,24,23,15],
[28,24,23,17],
[28,24,23,18],
[28,24,23,19],
[28,24,23,20],
[28,24,23,21],
[28,24,23,22],
[28,26,3,0],
[28,26,3,1],
[28,26,3,2],
[28,26,4,0],
[28,26,4,1],
[28,26,4,2],
[28,26,4,3],
[28,26,5,0],
[28,26,5,1],
[28,26,5,2],
[28,26,5,4],
[28,26,6,0],
[28,26,6,1],
[28,26,6,2],
[28,26,6,4],
[28,26,8,0],
[28,26,8,1],
[28,26,8,2],
[28,26,8,4],
[28,26,10,0],
[28,26,10,1],
[28,26,10,2],
[28,26,10,3],
[28,26,10,4],
[28,26,10,5],
[28,26,10,6],
[28,26,10,8],
[28,26,11,0],
[28,26,11,1],
[28,26,11,2],
[28,26,11,3],
[28,26,11,4],
[28,26,11,5],
[28,26,11,6],
[28,26,11,8],
[28,26,11,10],
[28,26,12,0],
[28,26,12,1],
[28,26,12,2],
[28,26,12,3],
[28,26,12,4],
[28,26,12,5],
[28,26,12,6],
[28,26,12,8],
[28,26,12,10],
[28,26,13,0],
[28,26,13,1],
[28,26,13,2],
[28,26,13,3],
[28,26,13,4],
[28,26,13,5],
[28,26,13,6],
[28,26,13,8],
[28,26,13,10],
[28,26,13,11],
[28,26,13,12],
[28,26,14,0],
[28,26,14,1],
[28,26,14,2],
[28,26,14,3],
[28,26,14,4],
[28,26,14,5],
[28,26,14,6],
[28,26,14,8],
[28,26,14,10],
[28,26,14,11],
[28,26,14,12],
[28,26,16,0],
[28,26,16,1],
[28,26,16,2],
[28,26,16,3],
[28,26,16,4],
[28,26,16,5],
[28,26,16,6],
[28,26,16,8],
[28,26,16,10],
[28,26,16,11],
[28,26,16,12],
[28,26,16,13],
[28,26,16,14],
[28,26,17,0],
[28,26,17,1],
[28,26,17,2],
[28,26,17,3],
[28,26,17,4],
[28,26,17,5],
[28,26,17,6],
[28,26,17,8],
[28,26,17,10],
[28,26,17,11],
[28,26,17,12],
[28,26,17,13],
[28,26,17,14],
[28,26,17,16],
[28,26,19,0],
[28,26,19,1],
[28,26,19,2],
[28,26,19,3],
[28,26,19,4],
[28,26,19,5],
[28,26,19,6],
[28,26,19,8],
[28,26,19,10],
[28,26,19,11],
[28,26,19,12],
[28,26,19,16],
[28,26,19,17],
[28,26,20,0],
[28,26,20,1],
[28,26,20,2],
[28,26,20,3],
[28,26,20,4],
[28,26,20,5],
[28,26,20,6],
[28,26,20,8],
[28,26,20,11],
[28,26,20,12],
[28,26,20,13],
[28,26,20,14],
[28,26,20,16],
[28,26,20,17],
[28,26,20,19],
[28,26,21,0],
[28,26,21,1],
[28,26,21,2],
[28,26,21,4],
[28,26,21,10],
[28,26,21,11],
[28,26,21,12],
[28,26,21,13],
[28,26,21,14],
[28,26,21,16],
[28,26,21,17],
[28,26,21,19],
[28,26,21,20],
[28,26,22,0],
[28,26,22,1],
[28,26,22,2],
[28,26,22,3],
[28,26,22,4],
[28,26,22,5],
[28,26,22,6],
[28,26,22,8],
[28,26,22,11],
[28,26,22,12],
[28,26,22,13],
[28,26,22,14],
[28,26,22,16],
[28,26,22,17],
[28,26,22,19],
[28,26,22,21],
[28,26,23,0],
[28,26,23,1],
[28,26,23,2],
[28,26,23,3],
[28,26,23,4],
[28,26,23,5],
[28,26,23,6],
[28,26,23,8],
[28,26,23,10],
[28,26,23,11],
[28,26,23,12],
[28,26,23,13],
[28,26,23,14],
[28,26,23,16],
[28,26,23,17],
[28,26,23,19],
[28,26,23,20],
[28,26,23,21],
[28,26,23,22],
[28,26,24,0],
[28,26,24,1],
[28,26,24,2],
[28,26,24,3],
[28,26,24,4],
[28,26,24,5],
[28,26,24,6],
[28,26,24,8],
[28,26,24,10],
[28,26,24,11],
[28,26,24,12],
[28,26,24,13],
[28,26,24,14],
[28,26,24,17],
[28,26,24,19],
[28,26,24,20],
[28,26,24,21],
[28,26,24,22],
[28,26,24,23],
[28,27,3,0],
[28,27,3,1],
[28,27,3,2],
[28,27,4,0],
[28,27,4,1],
[28,27,4,2],
[28,27,4,3],
[28,27,5,0],
[28,27,5,1],
[28,27,5,2],
[28,27,5,4],
[28,27,6,0],
[28,27,6,1],
[28,27,6,2],
[28,27,6,4],
[28,27,8,0],
[28,27,8,1],
[28,27,8,2],
[28,27,8,4],
[28,27,9,0],
[28,27,9,1],
[28,27,9,2],
[28,27,9,3],
[28,27,9,4],
[28,27,9,5],
[28,27,9,6],
[28,27,9,8],
[28,27,11,0],
[28,27,11,1],
[28,27,11,2],
[28,27,11,3],
[28,27,11,4],
[28,27,11,5],
[28,27,11,6],
[28,27,11,8],
[28,27,11,9],
[28,27,12,0],
[28,27,12,1],
[28,27,12,2],
[28,27,12,3],
[28,27,12,4],
[28,27,12,5],
[28,27,12,6],
[28,27,12,8],
[28,27,12,9],
[28,27,13,0],
[28,27,13,1],
[28,27,13,2],
[28,27,13,3],
[28,27,13,4],
[28,27,13,5],
[28,27,13,6],
[28,27,13,8],
[28,27,13,9],
[28,27,13,11],
[28,27,13,12],
[28,27,14,0],
[28,27,14,1],
[28,27,14,2],
[28,27,14,3],
[28,27,14,4],
[28,27,14,5],
[28,27,14,6],
[28,27,14,8],
[28,27,14,9],
[28,27,14,11],
[28,27,14,12],
[28,27,15,0],
[28,27,15,1],
[28,27,15,2],
[28,27,15,3],
[28,27,15,4],
[28,27,15,5],
[28,27,15,6],
[28,27,15,8],
[28,27,15,11],
[28,27,15,12],
[28,27,15,13],
[28,27,15,14],
[28,27,16,0],
[28,27,16,1],
[28,27,16,2],
[28,27,16,3],
[28,27,16,4],
[28,27,16,5],
[28,27,16,6],
[28,27,16,8],
[28,27,16,9],
[28,27,16,11],
[28,27,16,12],
[28,27,16,13],
[28,27,16,14],
[28,27,16,15],
[28,27,17,0],
[28,27,17,1],
[28,27,17,2],
[28,27,17,3],
[28,27,17,4],
[28,27,17,5],
[28,27,17,6],
[28,27,17,8],
[28,27,17,9],
[28,27,17,11],
[28,27,17,12],
[28,27,17,13],
[28,27,17,14],
[28,27,17,15],
[28,27,17,16],
[28,27,18,0],
[28,27,18,1],
[28,27,18,2],
[28,27,18,3],
[28,27,18,4],
[28,27,18,5],
[28,27,18,6],
[28,27,18,8],
[28,27,18,11],
[28,27,18,12],
[28,27,18,13],
[28,27,18,14],
[28,27,18,16],
[28,27,18,17],
[28,27,19,0],
[28,27,19,1],
[28,27,19,2],
[28,27,19,3],
[28,27,19,4],
[28,27,19,5],
[28,27,19,6],
[28,27,19,8],
[28,27,19,9],
[28,27,19,11],
[28,27,19,12],
[28,27,19,15],
[28,27,19,16],
[28,27,19,17],
[28,27,19,18],
[28,27,21,0],
[28,27,21,1],
[28,27,21,2],
[28,27,21,4],
[28,27,21,9],
[28,27,21,11],
[28,27,21,12],
[28,27,21,13],
[28,27,21,14],
[28,27,21,15],
[28,27,21,16],
[28,27,21,17],
[28,27,21,18],
[28,27,21,19],
[28,27,23,0],
[28,27,23,1],
[28,27,23,2],
[28,27,23,3],
[28,27,23,4],
[28,27,23,5],
[28,27,23,6],
[28,27,23,8],
[28,27,23,9],
[28,27,23,11],
[28,27,23,12],
[28,27,23,13],
[28,27,23,14],
[28,27,23,15],
[28,27,23,16],
[28,27,23,17],
[28,27,23,18],
[28,27,23,19],
[28,27,23,21],
[28,27,24,0],
[28,27,24,1],
[28,27,24,2],
[28,27,24,3],
[28,27,24,4],
[28,27,24,5],
[28,27,24,6],
[28,27,24,8],
[28,27,24,9],
[28,27,24,11],
[28,27,24,12],
[28,27,24,13],
[28,27,24,14],
[28,27,24,15],
[28,27,24,17],
[28,27,24,18],
[28,27,24,19],
[28,27,24,21],
[28,27,24,23],
[28,27,26,0],
[28,27,26,1],
[28,27,26,2],
[28,27,26,3],
[28,27,26,4],
[28,27,26,5],
[28,27,26,6],
[28,27,26,8],
[28,27,26,11],
[28,27,26,12],
[28,27,26,13],
[28,27,26,14],
[28,27,26,16],
[28,27,26,17],
[28,27,26,19],
[28,27,26,21],
[28,27,26,23],
[28,27,26,24],
[29,4,3,0],
[29,4,3,1],
[29,4,3,2],
[29,5,4,0],
[29,5,4,1],
[29,5,4,2],
[29,6,4,0],
[29,6,4,1],
[29,6,4,2],
[29,7,3,0],
[29,7,3,1],
[29,7,3,2],
[29,7,4,0],
[29,7,4,1],
[29,7,4,2],
[29,7,4,3],
[29,7,5,0],
[29,7,5,1],
[29,7,5,2],
[29,7,5,4],
[29,7,6,0],
[29,7,6,1],
[29,7,6,2],
[29,7,6,4],
[29,8,4,0],
[29,8,4,1],
[29,8,4,2],
[29,8,7,0],
[29,8,7,1],
[29,8,7,2],
[29,8,7,4],
[29,9,3,0],
[29,9,3,1],
[29,9,3,2],
[29,9,4,0],
[29,9,4,1],
[29,9,4,2],
[29,9,4,3],
[29,9,5,0],
[29,9,5,1],
[29,9,5,2],
[29,9,5,4],
[29,9,6,0],
[29,9,6,1],
[29,9,6,2],
[29,9,6,4],
[29,9,7,0],
[29,9,7,1],
[29,9,7,2],
[29,9,7,3],
[29,9,7,4],
[29,9,7,5],
[29,9,7,6],
[29,9,8,0],
[29,9,8,1],
[29,9,8,2],
[29,9,8,4],
[29,9,8,7],
[29,10,3,0],
[29,10,3,1],
[29,10,3,2],
[29,10,4,0],
[29,10,4,1],
[29,10,4,2],
[29,10,4,3],
[29,10,5,0],
[29,10,5,1],
[29,10,5,2],
[29,10,5,4],
[29,10,6,0],
[29,10,6,1],
[29,10,6,2],
[29,10,6,4],
[29,10,7,0],
[29,10,7,1],
[29,10,7,2],
[29,10,7,3],
[29,10,7,4],
[29,10,7,5],
[29,10,7,6],
[29,10,8,0],
[29,10,8,1],
[29,10,8,2],
[29,10,8,4],
[29,10,8,7],
[29,10,9,0],
[29,10,9,1],
[29,10,9,2],
[29,10,9,3],
[29,10,9,4],
[29,10,9,5],
[29,10,9,6],
[29,10,9,7],
[29,10,9,8],
[29,13,3,0],
[29,13,3,1],
[29,13,3,2],
[29,13,4,0],
[29,13,4,1],
[29,13,4,2],
[29,13,4,3],
[29,13,5,0],
[29,13,5,1],
[29,13,5,2],
[29,13,5,4],
[29,13,6,0],
[29,13,6,1],
[29,13,6,2],
[29,13,6,4],
[29,13,7,0],
[29,13,7,1],
[29,13,7,2],
[29,13,7,3],
[29,13,7,4],
[29,13,7,5],
[29,13,7,6],
[29,13,8,0],
[29,13,8,1],
[29,13,8,2],
[29,13,8,4],
[29,13,8,7],
[29,13,9,0],
[29,13,9,1],
[29,13,9,2],
[29,13,9,3],
[29,13,9,4],
[29,13,9,5],
[29,13,9,6],
[29,13,9,7],
[29,13,9,8],
[29,13,10,0],
[29,13,10,1],
[29,13,10,2],
[29,13,10,3],
[29,13,10,4],
[29,13,10,5],
[29,13,10,6],
[29,13,10,7],
[29,13,10,8],
[29,13,10,9],
[29,14,3,0],
[29,14,3,1],
[29,14,3,2],
[29,14,4,0],
[29,14,4,1],
[29,14,4,2],
[29,14,4,3],
[29,14,5,0],
[29,14,5,1],
[29,14,5,2],
[29,14,5,4],
[29,14,6,0],
[29,14,6,1],
[29,14,6,2],
[29,14,6,4],
[29,14,7,0],
[29,14,7,1],
[29,14,7,2],
[29,14,7,3],
[29,14,7,4],
[29,14,7,5],
[29,14,7,6],
[29,14,8,0],
[29,14,8,1],
[29,14,8,2],
[29,14,8,4],
[29,14,8,7],
[29,14,9,0],
[29,14,9,1],
[29,14,9,2],
[29,14,9,3],
[29,14,9,4],
[29,14,9,5],
[29,14,9,6],
[29,14,9,7],
[29,14,9,8],
[29,14,10,0],
[29,14,10,1],
[29,14,10,2],
[29,14,10,3],
[29,14,10,4],
[29,14,10,5],
[29,14,10,6],
[29,14,10,7],
[29,14,10,8],
[29,14,10,9],
[29,15,3,0],
[29,15,3,1],
[29,15,3,2],
[29,15,4,0],
[29,15,4,1],
[29,15,4,2],
[29,15,4,3],
[29,15,5,0],
[29,15,5,1],
[29,15,5,2],
[29,15,5,4],
[29,15,6,0],
[29,15,6,1],
[29,15,6,2],
[29,15,6,4],
[29,15,7,0],
[29,15,7,1],
[29,15,7,2],
[29,15,7,3],
[29,15,7,4],
[29,15,7,5],
[29,15,7,6],
[29,15,8,0],
[29,15,8,1],
[29,15,8,2],
[29,15,8,4],
[29,15,8,7],
[29,15,10,0],
[29,15,10,1],
[29,15,10,2],
[29,15,10,3],
[29,15,10,4],
[29,15,10,5],
[29,15,10,6],
[29,15,10,7],
[29,15,10,8],
[29,15,13,0],
[29,15,13,1],
[29,15,13,2],
[29,15,13,3],
[29,15,13,4],
[29,15,13,5],
[29,15,13,6],
[29,15,13,7],
[29,15,13,8],
[29,15,13,10],
[29,15,14,0],
[29,15,14,1],
[29,15,14,2],
[29,15,14,3],
[29,15,14,4],
[29,15,14,5],
[29,15,14,6],
[29,15,14,7],
[29,15,14,8],
[29,15,14,10],
[29,16,3,0],
[29,16,3,1],
[29,16,3,2],
[29,16,4,0],
[29,16,4,1],
[29,16,4,2],
[29,16,4,3],
[29,16,5,0],
[29,16,5,1],
[29,16,5,2],
[29,16,5,4],
[29,16,6,0],
[29,16,6,1],
[29,16,6,2],
[29,16,6,4],
[29,16,7,0],
[29,16,7,1],
[29,16,7,2],
[29,16,7,3],
[29,16,7,4],
[29,16,7,5],
[29,16,7,6],
[29,16,8,0],
[29,16,8,1],
[29,16,8,2],
[29,16,8,4],
[29,16,8,7],
[29,16,9,0],
[29,16,9,1],
[29,16,9,2],
[29,16,9,3],
[29,16,9,4],
[29,16,9,5],
[29,16,9,6],
[29,16,9,7],
[29,16,9,8],
[29,16,10,0],
[29,16,10,1],
[29,16,10,2],
[29,16,10,3],
[29,16,10,4],
[29,16,10,5],
[29,16,10,6],
[29,16,10,7],
[29,16,10,8],
[29,16,10,9],
[29,16,13,0],
[29,16,13,1],
[29,16,13,2],
[29,16,13,3],
[29,16,13,4],
[29,16,13,5],
[29,16,13,6],
[29,16,13,7],
[29,16,13,8],
[29,16,13,9],
[29,16,13,10],
[29,16,14,0],
[29,16,14,1],
[29,16,14,2],
[29,16,14,3],
[29,16,14,4],
[29,16,14,5],
[29,16,14,6],
[29,16,14,7],
[29,16,14,8],
[29,16,14,9],
[29,16,14,10],
[29,16,15,0],
[29,16,15,1],
[29,16,15,2],
[29,16,15,3],
[29,16,15,4],
[29,16,15,5],
[29,16,15,6],
[29,16,15,7],
[29,16,15,8],
[29,16,15,10],
[29,16,15,13],
[29,16,15,14],
[29,17,3,0],
[29,17,3,1],
[29,17,3,2],
[29,17,4,0],
[29,17,4,1],
[29,17,4,2],
[29,17,4,3],
[29,17,5,0],
[29,17,5,1],
[29,17,5,2],
[29,17,5,4],
[29,17,6,0],
[29,17,6,1],
[29,17,6,2],
[29,17,6,4],
[29,17,7,0],
[29,17,7,1],
[29,17,7,2],
[29,17,7,3],
[29,17,7,4],
[29,17,7,5],
[29,17,7,6],
[29,17,8,0],
[29,17,8,1],
[29,17,8,2],
[29,17,8,4],
[29,17,8,7],
[29,17,9,0],
[29,17,9,1],
[29,17,9,2],
[29,17,9,3],
[29,17,9,4],
[29,17,9,5],
[29,17,9,6],
[29,17,9,7],
[29,17,9,8],
[29,17,10,0],
[29,17,10,1],
[29,17,10,2],
[29,17,10,3],
[29,17,10,4],
[29,17,10,5],
[29,17,10,6],
[29,17,10,7],
[29,17,10,8],
[29,17,10,9],
[29,17,13,0],
[29,17,13,1],
[29,17,13,2],
[29,17,13,3],
[29,17,13,4],
[29,17,13,5],
[29,17,13,6],
[29,17,13,7],
[29,17,13,8],
[29,17,13,9],
[29,17,13,10],
[29,17,14,0],
[29,17,14,1],
[29,17,14,2],
[29,17,14,3],
[29,17,14,4],
[29,17,14,5],
[29,17,14,6],
[29,17,14,7],
[29,17,14,8],
[29,17,14,9],
[29,17,14,10],
[29,17,15,0],
[29,17,15,1],
[29,17,15,2],
[29,17,15,3],
[29,17,15,4],
[29,17,15,5],
[29,17,15,6],
[29,17,15,7],
[29,17,15,8],
[29,17,15,10],
[29,17,15,13],
[29,17,15,14],
[29,17,16,0],
[29,17,16,1],
[29,17,16,2],
[29,17,16,3],
[29,17,16,4],
[29,17,16,5],
[29,17,16,6],
[29,17,16,7],
[29,17,16,8],
[29,17,16,9],
[29,17,16,10],
[29,17,16,13],
[29,17,16,14],
[29,17,16,15],
[29,18,3,0],
[29,18,3,1],
[29,18,3,2],
[29,18,4,0],
[29,18,4,1],
[29,18,4,2],
[29,18,4,3],
[29,18,5,0],
[29,18,5,1],
[29,18,5,2],
[29,18,5,4],
[29,18,6,0],
[29,18,6,1],
[29,18,6,2],
[29,18,6,4],
[29,18,7,0],
[29,18,7,1],
[29,18,7,2],
[29,18,7,3],
[29,18,7,4],
[29,18,7,5],
[29,18,7,6],
[29,18,8,0],
[29,18,8,1],
[29,18,8,2],
[29,18,8,4],
[29,18,8,7],
[29,18,10,0],
[29,18,10,1],
[29,18,10,2],
[29,18,10,3],
[29,18,10,4],
[29,18,10,5],
[29,18,10,6],
[29,18,10,7],
[29,18,10,8],
[29,18,13,0],
[29,18,13,1],
[29,18,13,2],
[29,18,13,3],
[29,18,13,4],
[29,18,13,5],
[29,18,13,6],
[29,18,13,7],
[29,18,13,8],
[29,18,13,10],
[29,18,14,0],
[29,18,14,1],
[29,18,14,2],
[29,18,14,3],
[29,18,14,4],
[29,18,14,5],
[29,18,14,6],
[29,18,14,7],
[29,18,14,8],
[29,18,14,10],
[29,18,16,0],
[29,18,16,1],
[29,18,16,2],
[29,18,16,3],
[29,18,16,4],
[29,18,16,5],
[29,18,16,6],
[29,18,16,7],
[29,18,16,8],
[29,18,16,10],
[29,18,16,13],
[29,18,16,14],
[29,18,17,0],
[29,18,17,1],
[29,18,17,2],
[29,18,17,3],
[29,18,17,4],
[29,18,17,5],
[29,18,17,6],
[29,18,17,7],
[29,18,17,8],
[29,18,17,10],
[29,18,17,13],
[29,18,17,14],
[29,18,17,16],
[29,19,3,0],
[29,19,3,1],
[29,19,3,2],
[29,19,4,0],
[29,19,4,1],
[29,19,4,2],
[29,19,4,3],
[29,19,5,0],
[29,19,5,1],
[29,19,5,2],
[29,19,5,4],
[29,19,6,0],
[29,19,6,1],
[29,19,6,2],
[29,19,6,4],
[29,19,7,0],
[29,19,7,1],
[29,19,7,2],
[29,19,7,3],
[29,19,7,4],
[29,19,7,5],
[29,19,7,6],
[29,19,8,0],
[29,19,8,1],
[29,19,8,2],
[29,19,8,4],
[29,19,8,7],
[29,19,9,0],
[29,19,9,1],
[29,19,9,2],
[29,19,9,3],
[29,19,9,4],
[29,19,9,5],
[29,19,9,6],
[29,19,9,7],
[29,19,9,8],
[29,19,10,0],
[29,19,10,1],
[29,19,10,2],
[29,19,10,3],
[29,19,10,4],
[29,19,10,5],
[29,19,10,6],
[29,19,10,7],
[29,19,10,8],
[29,19,10,9],
[29,19,15,0],
[29,19,15,1],
[29,19,15,2],
[29,19,15,3],
[29,19,15,4],
[29,19,15,5],
[29,19,15,6],
[29,19,15,7],
[29,19,15,8],
[29,19,15,10],
[29,19,16,0],
[29,19,16,1],
[29,19,16,2],
[29,19,16,3],
[29,19,16,4],
[29,19,16,5],
[29,19,16,6],
[29,19,16,7],
[29,19,16,8],
[29,19,16,9],
[29,19,16,10],
[29,19,16,15],
[29,19,17,0],
[29,19,17,1],
[29,19,17,2],
[29,19,17,3],
[29,19,17,4],
[29,19,17,5],
[29,19,17,6],
[29,19,17,7],
[29,19,17,8],
[29,19,17,9],
[29,19,17,10],
[29,19,17,15],
[29,19,17,16],
[29,19,18,0],
[29,19,18,1],
[29,19,18,2],
[29,19,18,3],
[29,19,18,4],
[29,19,18,5],
[29,19,18,6],
[29,19,18,7],
[29,19,18,8],
[29,19,18,10],
[29,19,18,16],
[29,19,18,17],
[29,20,3,0],
[29,20,3,1],
[29,20,3,2],
[29,20,4,0],
[29,20,4,1],
[29,20,4,2],
[29,20,4,3],
[29,20,5,0],
[29,20,5,1],
[29,20,5,2],
[29,20,5,4],
[29,20,6,0],
[29,20,6,1],
[29,20,6,2],
[29,20,6,4],
[29,20,7,0],
[29,20,7,1],
[29,20,7,2],
[29,20,7,3],
[29,20,7,4],
[29,20,7,5],
[29,20,7,6],
[29,20,8,0],
[29,20,8,1],
[29,20,8,2],
[29,20,8,4],
[29,20,8,7],
[29,20,9,0],
[29,20,9,1],
[29,20,9,2],
[29,20,9,3],
[29,20,9,4],
[29,20,9,5],
[29,20,9,6],
[29,20,9,7],
[29,20,9,8],
[29,20,13,0],
[29,20,13,1],
[29,20,13,2],
[29,20,13,3],
[29,20,13,4],
[29,20,13,5],
[29,20,13,6],
[29,20,13,7],
[29,20,13,8],
[29,20,13,9],
[29,20,14,0],
[29,20,14,1],
[29,20,14,2],
[29,20,14,3],
[29,20,14,4],
[29,20,14,5],
[29,20,14,6],
[29,20,14,7],
[29,20,14,8],
[29,20,14,9],
[29,20,15,0],
[29,20,15,1],
[29,20,15,2],
[29,20,15,3],
[29,20,15,4],
[29,20,15,5],
[29,20,15,6],
[29,20,15,7],
[29,20,15,8],
[29,20,15,13],
[29,20,15,14],
[29,20,16,0],
[29,20,16,1],
[29,20,16,2],
[29,20,16,3],
[29,20,16,4],
[29,20,16,5],
[29,20,16,6],
[29,20,16,7],
[29,20,16,8],
[29,20,16,9],
[29,20,16,13],
[29,20,16,14],
[29,20,16,15],
[29,20,17,0],
[29,20,17,1],
[29,20,17,2],
[29,20,17,3],
[29,20,17,4],
[29,20,17,5],
[29,20,17,6],
[29,20,17,7],
[29,20,17,8],
[29,20,17,9],
[29,20,17,13],
[29,20,17,14],
[29,20,17,15],
[29,20,17,16],
[29,20,18,0],
[29,20,18,1],
[29,20,18,2],
[29,20,18,3],
[29,20,18,4],
[29,20,18,5],
[29,20,18,6],
[29,20,18,7],
[29,20,18,8],
[29,20,18,13],
[29,20,18,14],
[29,20,18,16],
[29,20,18,17],
[29,20,19,0],
[29,20,19,1],
[29,20,19,2],
[29,20,19,3],
[29,20,19,4],
[29,20,19,5],
[29,20,19,6],
[29,20,19,7],
[29,20,19,8],
[29,20,19,9],
[29,20,19,15],
[29,20,19,16],
[29,20,19,17],
[29,20,19,18],
[29,21,4,0],
[29,21,4,1],
[29,21,4,2],
[29,21,7,0],
[29,21,7,1],
[29,21,7,2],
[29,21,7,4],
[29,21,9,0],
[29,21,9,1],
[29,21,9,2],
[29,21,9,4],
[29,21,9,7],
[29,21,10,0],
[29,21,10,1],
[29,21,10,2],
[29,21,10,4],
[29,21,10,7],
[29,21,10,9],
[29,21,13,0],
[29,21,13,1],
[29,21,13,2],
[29,21,13,4],
[29,21,13,7],
[29,21,13,9],
[29,21,13,10],
[29,21,14,0],
[29,21,14,1],
[29,21,14,2],
[29,21,14,4],
[29,21,14,7],
[29,21,14,9],
[29,21,14,10],
[29,21,15,0],
[29,21,15,1],
[29,21,15,2],
[29,21,15,4],
[29,21,15,7],
[29,21,15,10],
[29,21,15,13],
[29,21,15,14],
[29,21,16,0],
[29,21,16,1],
[29,21,16,2],
[29,21,16,4],
[29,21,16,7],
[29,21,16,9],
[29,21,16,10],
[29,21,16,13],
[29,21,16,14],
[29,21,16,15],
[29,21,17,0],
[29,21,17,1],
[29,21,17,2],
[29,21,17,4],
[29,21,17,7],
[29,21,17,9],
[29,21,17,10],
[29,21,17,13],
[29,21,17,14],
[29,21,17,15],
[29,21,17,16],
[29,21,18,0],
[29,21,18,1],
[29,21,18,2],
[29,21,18,4],
[29,21,18,7],
[29,21,18,10],
[29,21,18,13],
[29,21,18,14],
[29,21,18,16],
[29,21,18,17],
[29,21,19,0],
[29,21,19,1],
[29,21,19,2],
[29,21,19,4],
[29,21,19,7],
[29,21,19,9],
[29,21,19,10],
[29,21,19,15],
[29,21,19,16],
[29,21,19,17],
[29,21,19,18],
[29,21,20,0],
[29,21,20,1],
[29,21,20,2],
[29,21,20,4],
[29,21,20,7],
[29,21,20,9],
[29,21,20,13],
[29,21,20,14],
[29,21,20,15],
[29,21,20,16],
[29,21,20,17],
[29,21,20,18],
[29,21,20,19],
[29,22,3,0],
[29,22,3,1],
[29,22,3,2],
[29,22,4,0],
[29,22,4,1],
[29,22,4,2],
[29,22,4,3],
[29,22,5,0],
[29,22,5,1],
[29,22,5,2],
[29,22,5,4],
[29,22,6,0],
[29,22,6,1],
[29,22,6,2],
[29,22,6,4],
[29,22,7,0],
[29,22,7,1],
[29,22,7,2],
[29,22,7,3],
[29,22,7,4],
[29,22,7,5],
[29,22,7,6],
[29,22,8,0],
[29,22,8,1],
[29,22,8,2],
[29,22,8,4],
[29,22,8,7],
[29,22,9,0],
[29,22,9,1],
[29,22,9,2],
[29,22,9,3],
[29,22,9,4],
[29,22,9,5],
[29,22,9,6],
[29,22,9,7],
[29,22,9,8],
[29,22,13,0],
[29,22,13,1],
[29,22,13,2],
[29,22,13,3],
[29,22,13,4],
[29,22,13,5],
[29,22,13,6],
[29,22,13,7],
[29,22,13,8],
[29,22,13,9],
[29,22,14,0],
[29,22,14,1],
[29,22,14,2],
[29,22,14,3],
[29,22,14,4],
[29,22,14,5],
[29,22,14,6],
[29,22,14,7],
[29,22,14,8],
[29,22,14,9],
[29,22,15,0],
[29,22,15,1],
[29,22,15,2],
[29,22,15,3],
[29,22,15,4],
[29,22,15,5],
[29,22,15,6],
[29,22,15,7],
[29,22,15,8],
[29,22,15,13],
[29,22,15,14],
[29,22,16,0],
[29,22,16,1],
[29,22,16,2],
[29,22,16,3],
[29,22,16,4],
[29,22,16,5],
[29,22,16,6],
[29,22,16,7],
[29,22,16,8],
[29,22,16,9],
[29,22,16,13],
[29,22,16,14],
[29,22,16,15],
[29,22,17,0],
[29,22,17,1],
[29,22,17,2],
[29,22,17,3],
[29,22,17,4],
[29,22,17,5],
[29,22,17,6],
[29,22,17,7],
[29,22,17,8],
[29,22,17,9],
[29,22,17,13],
[29,22,17,14],
[29,22,17,15],
[29,22,17,16],
[29,22,18,0],
[29,22,18,1],
[29,22,18,2],
[29,22,18,3],
[29,22,18,4],
[29,22,18,5],
[29,22,18,6],
[29,22,18,7],
[29,22,18,8],
[29,22,18,13],
[29,22,18,14],
[29,22,18,16],
[29,22,18,17],
[29,22,19,0],
[29,22,19,1],
[29,22,19,2],
[29,22,19,3],
[29,22,19,4],
[29,22,19,5],
[29,22,19,6],
[29,22,19,7],
[29,22,19,8],
[29,22,19,9],
[29,22,19,15],
[29,22,19,16],
[29,22,19,17],
[29,22,19,18],
[29,22,21,0],
[29,22,21,1],
[29,22,21,2],
[29,22,21,4],
[29,22,21,7],
[29,22,21,9],
[29,22,21,13],
[29,22,21,14],
[29,22,21,15],
[29,22,21,16],
[29,22,21,17],
[29,22,21,18],
[29,22,21,19],
[29,23,3,0],
[29,23,3,1],
[29,23,3,2],
[29,23,4,0],
[29,23,4,1],
[29,23,4,2],
[29,23,4,3],
[29,23,5,0],
[29,23,5,1],
[29,23,5,2],
[29,23,5,4],
[29,23,6,0],
[29,23,6,1],
[29,23,6,2],
[29,23,6,4],
[29,23,7,0],
[29,23,7,1],
[29,23,7,2],
[29,23,7,3],
[29,23,7,4],
[29,23,7,5],
[29,23,7,6],
[29,23,8,0],
[29,23,8,1],
[29,23,8,2],
[29,23,8,4],
[29,23,8,7],
[29,23,9,0],
[29,23,9,1],
[29,23,9,2],
[29,23,9,3],
[29,23,9,4],
[29,23,9,5],
[29,23,9,6],
[29,23,9,7],
[29,23,9,8],
[29,23,10,0],
[29,23,10,1],
[29,23,10,2],
[29,23,10,3],
[29,23,10,4],
[29,23,10,5],
[29,23,10,6],
[29,23,10,7],
[29,23,10,8],
[29,23,10,9],
[29,23,13,0],
[29,23,13,1],
[29,23,13,2],
[29,23,13,3],
[29,23,13,4],
[29,23,13,5],
[29,23,13,6],
[29,23,13,7],
[29,23,13,8],
[29,23,13,9],
[29,23,13,10],
[29,23,14,0],
[29,23,14,1],
[29,23,14,2],
[29,23,14,3],
[29,23,14,4],
[29,23,14,5],
[29,23,14,6],
[29,23,14,7],
[29,23,14,8],
[29,23,14,9],
[29,23,14,10],
[29,23,15,0],
[29,23,15,1],
[29,23,15,2],
[29,23,15,3],
[29,23,15,4],
[29,23,15,5],
[29,23,15,6],
[29,23,15,7],
[29,23,15,8],
[29,23,15,10],
[29,23,15,13],
[29,23,15,14],
[29,23,16,0],
[29,23,16,1],
[29,23,16,2],
[29,23,16,3],
[29,23,16,4],
[29,23,16,5],
[29,23,16,6],
[29,23,16,7],
[29,23,16,8],
[29,23,16,9],
[29,23,16,10],
[29,23,16,13],
[29,23,16,14],
[29,23,16,15],
[29,23,17,0],
[29,23,17,1],
[29,23,17,2],
[29,23,17,3],
[29,23,17,4],
[29,23,17,5],
[29,23,17,6],
[29,23,17,7],
[29,23,17,8],
[29,23,17,9],
[29,23,17,10],
[29,23,17,13],
[29,23,17,14],
[29,23,17,15],
[29,23,17,16],
[29,23,18,0],
[29,23,18,1],
[29,23,18,2],
[29,23,18,3],
[29,23,18,4],
[29,23,18,5],
[29,23,18,6],
[29,23,18,7],
[29,23,18,8],
[29,23,18,10],
[29,23,18,13],
[29,23,18,14],
[29,23,18,16],
[29,23,18,17],
[29,23,19,0],
[29,23,19,1],
[29,23,19,2],
[29,23,19,3],
[29,23,19,4],
[29,23,19,5],
[29,23,19,6],
[29,23,19,7],
[29,23,19,8],
[29,23,19,9],
[29,23,19,10],
[29,23,19,15],
[29,23,19,16],
[29,23,19,17],
[29,23,19,18],
[29,23,20,0],
[29,23,20,1],
[29,23,20,2],
[29,23,20,3],
[29,23,20,4],
[29,23,20,5],
[29,23,20,6],
[29,23,20,7],
[29,23,20,8],
[29,23,20,9],
[29,23,20,13],
[29,23,20,14],
[29,23,20,15],
[29,23,20,16],
[29,23,20,17],
[29,23,20,18],
[29,23,20,19],
[29,23,21,0],
[29,23,21,1],
[29,23,21,2],
[29,23,21,4],
[29,23,21,7],
[29,23,21,9],
[29,23,21,10],
[29,23,21,13],
[29,23,21,14],
[29,23,21,15],
[29,23,21,16],
[29,23,21,17],
[29,23,21,18],
[29,23,21,19],
[29,23,21,20],
[29,23,22,0],
[29,23,22,1],
[29,23,22,2],
[29,23,22,3],
[29,23,22,4],
[29,23,22,5],
[29,23,22,6],
[29,23,22,7],
[29,23,22,8],
[29,23,22,9],
[29,23,22,13],
[29,23,22,14],
[29,23,22,15],
[29,23,22,16],
[29,23,22,17],
[29,23,22,18],
[29,23,22,19],
[29,23,22,21],
[29,24,3,0],
[29,24,3,1],
[29,24,3,2],
[29,24,4,0],
[29,24,4,1],
[29,24,4,2],
[29,24,4,3],
[29,24,5,0],
[29,24,5,1],
[29,24,5,2],
[29,24,5,4],
[29,24,6,0],
[29,24,6,1],
[29,24,6,2],
[29,24,6,4],
[29,24,7,0],
[29,24,7,1],
[29,24,7,2],
[29,24,7,3],
[29,24,7,4],
[29,24,7,5],
[29,24,7,6],
[29,24,8,0],
[29,24,8,1],
[29,24,8,2],
[29,24,8,4],
[29,24,8,7],
[29,24,9,0],
[29,24,9,1],
[29,24,9,2],
[29,24,9,3],
[29,24,9,4],
[29,24,9,5],
[29,24,9,6],
[29,24,9,7],
[29,24,9,8],
[29,24,10,0],
[29,24,10,1],
[29,24,10,2],
[29,24,10,3],
[29,24,10,4],
[29,24,10,5],
[29,24,10,6],
[29,24,10,7],
[29,24,10,8],
[29,24,10,9],
[29,24,13,0],
[29,24,13,1],
[29,24,13,2],
[29,24,13,3],
[29,24,13,4],
[29,24,13,5],
[29,24,13,6],
[29,24,13,7],
[29,24,13,8],
[29,24,13,9],
[29,24,13,10],
[29,24,14,0],
[29,24,14,1],
[29,24,14,2],
[29,24,14,3],
[29,24,14,4],
[29,24,14,5],
[29,24,14,6],
[29,24,14,7],
[29,24,14,8],
[29,24,14,9],
[29,24,14,10],
[29,24,15,0],
[29,24,15,1],
[29,24,15,2],
[29,24,15,3],
[29,24,15,4],
[29,24,15,5],
[29,24,15,6],
[29,24,15,7],
[29,24,15,8],
[29,24,15,10],
[29,24,15,13],
[29,24,15,14],
[29,24,17,0],
[29,24,17,1],
[29,24,17,2],
[29,24,17,3],
[29,24,17,4],
[29,24,17,5],
[29,24,17,6],
[29,24,17,7],
[29,24,17,8],
[29,24,17,9],
[29,24,17,10],
[29,24,17,13],
[29,24,17,14],
[29,24,17,15],
[29,24,18,0],
[29,24,18,1],
[29,24,18,2],
[29,24,18,3],
[29,24,18,4],
[29,24,18,5],
[29,24,18,6],
[29,24,18,7],
[29,24,18,8],
[29,24,18,10],
[29,24,18,13],
[29,24,18,14],
[29,24,18,17],
[29,24,19,0],
[29,24,19,1],
[29,24,19,2],
[29,24,19,3],
[29,24,19,4],
[29,24,19,5],
[29,24,19,6],
[29,24,19,7],
[29,24,19,8],
[29,24,19,9],
[29,24,19,10],
[29,24,19,15],
[29,24,19,17],
[29,24,19,18],
[29,24,20,0],
[29,24,20,1],
[29,24,20,2],
[29,24,20,3],
[29,24,20,4],
[29,24,20,5],
[29,24,20,6],
[29,24,20,7],
[29,24,20,8],
[29,24,20,9],
[29,24,20,13],
[29,24,20,14],
[29,24,20,15],
[29,24,20,17],
[29,24,20,18],
[29,24,20,19],
[29,24,21,0],
[29,24,21,1],
[29,24,21,2],
[29,24,21,4],
[29,24,21,7],
[29,24,21,9],
[29,24,21,10],
[29,24,21,13],
[29,24,21,14],
[29,24,21,15],
[29,24,21,17],
[29,24,21,18],
[29,24,21,19],
[29,24,21,20],
[29,24,22,0],
[29,24,22,1],
[29,24,22,2],
[29,24,22,3],
[29,24,22,4],
[29,24,22,5],
[29,24,22,6],
[29,24,22,7],
[29,24,22,8],
[29,24,22,9],
[29,24,22,13],
[29,24,22,14],
[29,24,22,15],
[29,24,22,17],
[29,24,22,18],
[29,24,22,19],
[29,24,22,21],
[29,24,23,0],
[29,24,23,1],
[29,24,23,2],
[29,24,23,3],
[29,24,23,4],
[29,24,23,5],
[29,24,23,6],
[29,24,23,7],
[29,24,23,8],
[29,24,23,9],
[29,24,23,10],
[29,24,23,13],
[29,24,23,14],
[29,24,23,15],
[29,24,23,17],
[29,24,23,18],
[29,24,23,19],
[29,24,23,20],
[29,24,23,21],
[29,24,23,22],
[29,25,3,0],
[29,25,3,1],
[29,25,3,2],
[29,25,4,0],
[29,25,4,1],
[29,25,4,2],
[29,25,4,3],
[29,25,5,0],
[29,25,5,1],
[29,25,5,2],
[29,25,5,4],
[29,25,6,0],
[29,25,6,1],
[29,25,6,2],
[29,25,6,4],
[29,25,8,0],
[29,25,8,1],
[29,25,8,2],
[29,25,8,4],
[29,25,9,0],
[29,25,9,1],
[29,25,9,2],
[29,25,9,3],
[29,25,9,4],
[29,25,9,5],
[29,25,9,6],
[29,25,9,8],
[29,25,10,0],
[29,25,10,1],
[29,25,10,2],
[29,25,10,3],
[29,25,10,4],
[29,25,10,5],
[29,25,10,6],
[29,25,10,8],
[29,25,10,9],
[29,25,13,0],
[29,25,13,1],
[29,25,13,2],
[29,25,13,3],
[29,25,13,4],
[29,25,13,5],
[29,25,13,6],
[29,25,13,8],
[29,25,13,9],
[29,25,13,10],
[29,25,14,0],
[29,25,14,1],
[29,25,14,2],
[29,25,14,3],
[29,25,14,4],
[29,25,14,5],
[29,25,14,6],
[29,25,14,8],
[29,25,14,9],
[29,25,14,10],
[29,25,15,0],
[29,25,15,1],
[29,25,15,2],
[29,25,15,3],
[29,25,15,4],
[29,25,15,5],
[29,25,15,6],
[29,25,15,8],
[29,25,15,10],
[29,25,15,13],
[29,25,15,14],
[29,25,16,0],
[29,25,16,1],
[29,25,16,2],
[29,25,16,3],
[29,25,16,4],
[29,25,16,5],
[29,25,16,6],
[29,25,16,8],
[29,25,16,9],
[29,25,16,10],
[29,25,16,13],
[29,25,16,14],
[29,25,16,15],
[29,25,17,0],
[29,25,17,1],
[29,25,17,2],
[29,25,17,3],
[29,25,17,4],
[29,25,17,5],
[29,25,17,6],
[29,25,17,8],
[29,25,17,9],
[29,25,17,10],
[29,25,17,13],
[29,25,17,14],
[29,25,17,15],
[29,25,17,16],
[29,25,18,0],
[29,25,18,1],
[29,25,18,2],
[29,25,18,3],
[29,25,18,4],
[29,25,18,5],
[29,25,18,6],
[29,25,18,8],
[29,25,18,10],
[29,25,18,13],
[29,25,18,14],
[29,25,18,16],
[29,25,18,17],
[29,25,19,0],
[29,25,19,1],
[29,25,19,2],
[29,25,19,3],
[29,25,19,4],
[29,25,19,5],
[29,25,19,6],
[29,25,19,8],
[29,25,19,9],
[29,25,19,10],
[29,25,19,15],
[29,25,19,16],
[29,25,19,17],
[29,25,19,18],
[29,25,20,0],
[29,25,20,1],
[29,25,20,2],
[29,25,20,3],
[29,25,20,4],
[29,25,20,5],
[29,25,20,6],
[29,25,20,8],
[29,25,20,9],
[29,25,20,13],
[29,25,20,14],
[29,25,20,15],
[29,25,20,16],
[29,25,20,17],
[29,25,20,18],
[29,25,20,19],
[29,25,21,0],
[29,25,21,1],
[29,25,21,2],
[29,25,21,4],
[29,25,21,9],
[29,25,21,10],
[29,25,21,13],
[29,25,21,14],
[29,25,21,15],
[29,25,21,16],
[29,25,21,17],
[29,25,21,18],
[29,25,21,19],
[29,25,21,20],
[29,25,22,0],
[29,25,22,1],
[29,25,22,2],
[29,25,22,3],
[29,25,22,4],
[29,25,22,5],
[29,25,22,6],
[29,25,22,8],
[29,25,22,9],
[29,25,22,13],
[29,25,22,14],
[29,25,22,15],
[29,25,22,16],
[29,25,22,17],
[29,25,22,18],
[29,25,22,19],
[29,25,22,21],
[29,25,23,0],
[29,25,23,1],
[29,25,23,2],
[29,25,23,3],
[29,25,23,4],
[29,25,23,5],
[29,25,23,6],
[29,25,23,8],
[29,25,23,9],
[29,25,23,10],
[29,25,23,13],
[29,25,23,14],
[29,25,23,15],
[29,25,23,16],
[29,25,23,17],
[29,25,23,18],
[29,25,23,19],
[29,25,23,20],
[29,25,23,21],
[29,25,23,22],
[29,25,24,0],
[29,25,24,1],
[29,25,24,2],
[29,25,24,3],
[29,25,24,4],
[29,25,24,5],
[29,25,24,6],
[29,25,24,8],
[29,25,24,9],
[29,25,24,10],
[29,25,24,13],
[29,25,24,14],
[29,25,24,15],
[29,25,24,17],
[29,25,24,18],
[29,25,24,19],
[29,25,24,20],
[29,25,24,21],
[29,25,24,22],
[29,25,24,23],
[29,26,3,0],
[29,26,3,1],
[29,26,3,2],
[29,26,4,0],
[29,26,4,1],
[29,26,4,2],
[29,26,4,3],
[29,26,5,0],
[29,26,5,1],
[29,26,5,2],
[29,26,5,4],
[29,26,6,0],
[29,26,6,1],
[29,26,6,2],
[29,26,6,4],
[29,26,7,0],
[29,26,7,1],
[29,26,7,2],
[29,26,7,3],
[29,26,7,4],
[29,26,7,5],
[29,26,7,6],
[29,26,8,0],
[29,26,8,1],
[29,26,8,2],
[29,26,8,4],
[29,26,8,7],
[29,26,10,0],
[29,26,10,1],
[29,26,10,2],
[29,26,10,3],
[29,26,10,4],
[29,26,10,5],
[29,26,10,6],
[29,26,10,7],
[29,26,10,8],
[29,26,13,0],
[29,26,13,1],
[29,26,13,2],
[29,26,13,3],
[29,26,13,4],
[29,26,13,5],
[29,26,13,6],
[29,26,13,7],
[29,26,13,8],
[29,26,13,10],
[29,26,14,0],
[29,26,14,1],
[29,26,14,2],
[29,26,14,3],
[29,26,14,4],
[29,26,14,5],
[29,26,14,6],
[29,26,14,7],
[29,26,14,8],
[29,26,14,10],
[29,26,16,0],
[29,26,16,1],
[29,26,16,2],
[29,26,16,3],
[29,26,16,4],
[29,26,16,5],
[29,26,16,6],
[29,26,16,7],
[29,26,16,8],
[29,26,16,10],
[29,26,16,13],
[29,26,16,14],
[29,26,17,0],
[29,26,17,1],
[29,26,17,2],
[29,26,17,3],
[29,26,17,4],
[29,26,17,5],
[29,26,17,6],
[29,26,17,7],
[29,26,17,8],
[29,26,17,10],
[29,26,17,13],
[29,26,17,14],
[29,26,17,16],
[29,26,19,0],
[29,26,19,1],
[29,26,19,2],
[29,26,19,3],
[29,26,19,4],
[29,26,19,5],
[29,26,19,6],
[29,26,19,7],
[29,26,19,8],
[29,26,19,10],
[29,26,19,16],
[29,26,19,17],
[29,26,20,0],
[29,26,20,1],
[29,26,20,2],
[29,26,20,3],
[29,26,20,4],
[29,26,20,5],
[29,26,20,6],
[29,26,20,7],
[29,26,20,8],
[29,26,20,13],
[29,26,20,14],
[29,26,20,16],
[29,26,20,17],
[29,26,20,19],
[29,26,21,0],
[29,26,21,1],
[29,26,21,2],
[29,26,21,4],
[29,26,21,7],
[29,26,21,10],
[29,26,21,13],
[29,26,21,14],
[29,26,21,16],
[29,26,21,17],
[29,26,21,19],
[29,26,21,20],
[29,26,22,0],
[29,26,22,1],
[29,26,22,2],
[29,26,22,3],
[29,26,22,4],
[29,26,22,5],
[29,26,22,6],
[29,26,22,7],
[29,26,22,8],
[29,26,22,13],
[29,26,22,14],
[29,26,22,16],
[29,26,22,17],
[29,26,22,19],
[29,26,22,21],
[29,26,23,0],
[29,26,23,1],
[29,26,23,2],
[29,26,23,3],
[29,26,23,4],
[29,26,23,5],
[29,26,23,6],
[29,26,23,7],
[29,26,23,8],
[29,26,23,10],
[29,26,23,13],
[29,26,23,14],
[29,26,23,16],
[29,26,23,17],
[29,26,23,19],
[29,26,23,20],
[29,26,23,21],
[29,26,23,22],
[29,26,24,0],
[29,26,24,1],
[29,26,24,2],
[29,26,24,3],
[29,26,24,4],
[29,26,24,5],
[29,26,24,6],
[29,26,24,7],
[29,26,24,8],
[29,26,24,10],
[29,26,24,13],
[29,26,24,14],
[29,26,24,17],
[29,26,24,19],
[29,26,24,20],
[29,26,24,21],
[29,26,24,22],
[29,26,24,23],
[29,26,25,0],
[29,26,25,1],
[29,26,25,2],
[29,26,25,3],
[29,26,25,4],
[29,26,25,5],
[29,26,25,6],
[29,26,25,8],
[29,26,25,10],
[29,26,25,13],
[29,26,25,14],
[29,26,25,16],
[29,26,25,17],
[29,26,25,19],
[29,26,25,20],
[29,26,25,21],
[29,26,25,22],
[29,26,25,23],
[29,26,25,24],
[29,27,3,0],
[29,27,3,1],
[29,27,3,2],
[29,27,4,0],
[29,27,4,1],
[29,27,4,2],
[29,27,4,3],
[29,27,5,0],
[29,27,5,1],
[29,27,5,2],
[29,27,5,4],
[29,27,6,0],
[29,27,6,1],
[29,27,6,2],
[29,27,6,4],
[29,27,7,0],
[29,27,7,1],
[29,27,7,2],
[29,27,7,3],
[29,27,7,4],
[29,27,7,5],
[29,27,7,6],
[29,27,8,0],
[29,27,8,1],
[29,27,8,2],
[29,27,8,4],
[29,27,8,7],
[29,27,9,0],
[29,27,9,1],
[29,27,9,2],
[29,27,9,3],
[29,27,9,4],
[29,27,9,5],
[29,27,9,6],
[29,27,9,7],
[29,27,9,8],
[29,27,13,0],
[29,27,13,1],
[29,27,13,2],
[29,27,13,3],
[29,27,13,4],
[29,27,13,5],
[29,27,13,6],
[29,27,13,7],
[29,27,13,8],
[29,27,13,9],
[29,27,14,0],
[29,27,14,1],
[29,27,14,2],
[29,27,14,3],
[29,27,14,4],
[29,27,14,5],
[29,27,14,6],
[29,27,14,7],
[29,27,14,8],
[29,27,14,9],
[29,27,15,0],
[29,27,15,1],
[29,27,15,2],
[29,27,15,3],
[29,27,15,4],
[29,27,15,5],
[29,27,15,6],
[29,27,15,7],
[29,27,15,8],
[29,27,15,13],
[29,27,15,14],
[29,27,16,0],
[29,27,16,1],
[29,27,16,2],
[29,27,16,3],
[29,27,16,4],
[29,27,16,5],
[29,27,16,6],
[29,27,16,7],
[29,27,16,8],
[29,27,16,9],
[29,27,16,13],
[29,27,16,14],
[29,27,16,15],
[29,27,17,0],
[29,27,17,1],
[29,27,17,2],
[29,27,17,3],
[29,27,17,4],
[29,27,17,5],
[29,27,17,6],
[29,27,17,7],
[29,27,17,8],
[29,27,17,9],
[29,27,17,13],
[29,27,17,14],
[29,27,17,15],
[29,27,17,16],
[29,27,18,0],
[29,27,18,1],
[29,27,18,2],
[29,27,18,3],
[29,27,18,4],
[29,27,18,5],
[29,27,18,6],
[29,27,18,7],
[29,27,18,8],
[29,27,18,13],
[29,27,18,14],
[29,27,18,16],
[29,27,18,17],
[29,27,19,0],
[29,27,19,1],
[29,27,19,2],
[29,27,19,3],
[29,27,19,4],
[29,27,19,5],
[29,27,19,6],
[29,27,19,7],
[29,27,19,8],
[29,27,19,9],
[29,27,19,15],
[29,27,19,16],
[29,27,19,17],
[29,27,19,18],
[29,27,21,0],
[29,27,21,1],
[29,27,21,2],
[29,27,21,4],
[29,27,21,7],
[29,27,21,9],
[29,27,21,13],
[29,27,21,14],
[29,27,21,15],
[29,27,21,16],
[29,27,21,17],
[29,27,21,18],
[29,27,21,19],
[29,27,23,0],
[29,27,23,1],
[29,27,23,2],
[29,27,23,3],
[29,27,23,4],
[29,27,23,5],
[29,27,23,6],
[29,27,23,7],
[29,27,23,8],
[29,27,23,9],
[29,27,23,13],
[29,27,23,14],
[29,27,23,15],
[29,27,23,16],
[29,27,23,17],
[29,27,23,18],
[29,27,23,19],
[29,27,23,21],
[29,27,24,0],
[29,27,24,1],
[29,27,24,2],
[29,27,24,3],
[29,27,24,4],
[29,27,24,5],
[29,27,24,6],
[29,27,24,7],
[29,27,24,8],
[29,27,24,9],
[29,27,24,13],
[29,27,24,14],
[29,27,24,15],
[29,27,24,17],
[29,27,24,18],
[29,27,24,19],
[29,27,24,21],
[29,27,24,23],
[29,27,25,0],
[29,27,25,1],
[29,27,25,2],
[29,27,25,3],
[29,27,25,4],
[29,27,25,5],
[29,27,25,6],
[29,27,25,8],
[29,27,25,9],
[29,27,25,13],
[29,27,25,14],
[29,27,25,15],
[29,27,25,16],
[29,27,25,17],
[29,27,25,18],
[29,27,25,19],
[29,27,25,21],
[29,27,25,23],
[29,27,25,24],
[29,27,26,0],
[29,27,26,1],
[29,27,26,2],
[29,27,26,3],
[29,27,26,4],
[29,27,26,5],
[29,27,26,6],
[29,27,26,7],
[29,27,26,8],
[29,27,26,13],
[29,27,26,14],
[29,27,26,16],
[29,27,26,17],
[29,27,26,19],
[29,27,26,21],
[29,27,26,23],
[29,27,26,24],
[29,27,26,25],
[29,28,3,0],
[29,28,3,1],
[29,28,3,2],
[29,28,4,0],
[29,28,4,1],
[29,28,4,2],
[29,28,4,3],
[29,28,5,0],
[29,28,5,1],
[29,28,5,2],
[29,28,5,4],
[29,28,6,0],
[29,28,6,1],
[29,28,6,2],
[29,28,6,4],
[29,28,8,0],
[29,28,8,1],
[29,28,8,2],
[29,28,8,4],
[29,28,9,0],
[29,28,9,1],
[29,28,9,2],
[29,28,9,3],
[29,28,9,4],
[29,28,9,5],
[29,28,9,6],
[29,28,9,8],
[29,28,10,0],
[29,28,10,1],
[29,28,10,2],
[29,28,10,3],
[29,28,10,4],
[29,28,10,5],
[29,28,10,6],
[29,28,10,8],
[29,28,10,9],
[29,28,13,0],
[29,28,13,1],
[29,28,13,2],
[29,28,13,3],
[29,28,13,4],
[29,28,13,5],
[29,28,13,6],
[29,28,13,8],
[29,28,13,9],
[29,28,13,10],
[29,28,14,0],
[29,28,14,1],
[29,28,14,2],
[29,28,14,3],
[29,28,14,4],
[29,28,14,5],
[29,28,14,6],
[29,28,14,8],
[29,28,14,9],
[29,28,14,10],
[29,28,15,0],
[29,28,15,1],
[29,28,15,2],
[29,28,15,3],
[29,28,15,4],
[29,28,15,5],
[29,28,15,6],
[29,28,15,8],
[29,28,15,10],
[29,28,15,13],
[29,28,15,14],
[29,28,16,0],
[29,28,16,1],
[29,28,16,2],
[29,28,16,3],
[29,28,16,4],
[29,28,16,5],
[29,28,16,6],
[29,28,16,8],
[29,28,16,9],
[29,28,16,10],
[29,28,16,13],
[29,28,16,14],
[29,28,16,15],
[29,28,17,0],
[29,28,17,1],
[29,28,17,2],
[29,28,17,3],
[29,28,17,4],
[29,28,17,5],
[29,28,17,6],
[29,28,17,8],
[29,28,17,9],
[29,28,17,10],
[29,28,17,13],
[29,28,17,14],
[29,28,17,15],
[29,28,17,16],
[29,28,18,0],
[29,28,18,1],
[29,28,18,2],
[29,28,18,3],
[29,28,18,4],
[29,28,18,5],
[29,28,18,6],
[29,28,18,8],
[29,28,18,10],
[29,28,18,13],
[29,28,18,14],
[29,28,18,16],
[29,28,18,17],
[29,28,19,0],
[29,28,19,1],
[29,28,19,2],
[29,28,19,3],
[29,28,19,4],
[29,28,19,5],
[29,28,19,6],
[29,28,19,8],
[29,28,19,9],
[29,28,19,10],
[29,28,19,15],
[29,28,19,16],
[29,28,19,17],
[29,28,19,18],
[29,28,20,0],
[29,28,20,1],
[29,28,20,2],
[29,28,20,3],
[29,28,20,4],
[29,28,20,5],
[29,28,20,6],
[29,28,20,8],
[29,28,20,9],
[29,28,20,13],
[29,28,20,14],
[29,28,20,15],
[29,28,20,16],
[29,28,20,17],
[29,28,20,18],
[29,28,20,19],
[29,28,21,0],
[29,28,21,1],
[29,28,21,2],
[29,28,21,4],
[29,28,21,9],
[29,28,21,10],
[29,28,21,13],
[29,28,21,14],
[29,28,21,15],
[29,28,21,16],
[29,28,21,17],
[29,28,21,18],
[29,28,21,19],
[29,28,21,20],
[29,28,22,0],
[29,28,22,1],
[29,28,22,2],
[29,28,22,3],
[29,28,22,4],
[29,28,22,5],
[29,28,22,6],
[29,28,22,8],
[29,28,22,9],
[29,28,22,13],
[29,28,22,14],
[29,28,22,15],
[29,28,22,16],
[29,28,22,17],
[29,28,22,18],
[29,28,22,19],
[29,28,22,21],
[29,28,23,0],
[29,28,23,1],
[29,28,23,2],
[29,28,23,3],
[29,28,23,4],
[29,28,23,5],
[29,28,23,6],
[29,28,23,8],
[29,28,23,9],
[29,28,23,10],
[29,28,23,13],
[29,28,23,14],
[29,28,23,15],
[29,28,23,16],
[29,28,23,17],
[29,28,23,18],
[29,28,23,19],
[29,28,23,20],
[29,28,23,21],
[29,28,23,22],
[29,28,24,0],
[29,28,24,1],
[29,28,24,2],
[29,28,24,3],
[29,28,24,4],
[29,28,24,5],
[29,28,24,6],
[29,28,24,8],
[29,28,24,9],
[29,28,24,10],
[29,28,24,13],
[29,28,24,14],
[29,28,24,15],
[29,28,24,17],
[29,28,24,18],
[29,28,24,19],
[29,28,24,20],
[29,28,24,21],
[29,28,24,22],
[29,28,24,23],
[29,28,26,0],
[29,28,26,1],
[29,28,26,2],
[29,28,26,3],
[29,28,26,4],
[29,28,26,5],
[29,28,26,6],
[29,28,26,8],
[29,28,26,10],
[29,28,26,13],
[29,28,26,14],
[29,28,26,16],
[29,28,26,17],
[29,28,26,19],
[29,28,26,20],
[29,28,26,21],
[29,28,26,22],
[29,28,26,23],
[29,28,26,24],
[29,28,27,0],
[29,28,27,1],
[29,28,27,2],
[29,28,27,3],
[29,28,27,4],
[29,28,27,5],
[29,28,27,6],
[29,28,27,8],
[29,28,27,9],
[29,28,27,13],
[29,28,27,14],
[29,28,27,15],
[29,28,27,16],
[29,28,27,17],
[29,28,27,18],
[29,28,27,19],
[29,28,27,21],
[29,28,27,23],
[29,28,27,24],
[29,28,27,26],
[30,4,3,0],
[30,4,3,1],
[30,4,3,2],
[30,5,4,0],
[30,5,4,1],
[30,5,4,2],
[30,6,4,0],
[30,6,4,1],
[30,6,4,2],
[30,7,3,0],
[30,7,3,1],
[30,7,3,2],
[30,7,4,0],
[30,7,4,1],
[30,7,4,2],
[30,7,4,3],
[30,7,5,0],
[30,7,5,1],
[30,7,5,2],
[30,7,5,4],
[30,7,6,0],
[30,7,6,1],
[30,7,6,2],
[30,7,6,4],
[30,8,4,0],
[30,8,4,1],
[30,8,4,2],
[30,8,7,0],
[30,8,7,1],
[30,8,7,2],
[30,8,7,4],
[30,9,3,0],
[30,9,3,1],
[30,9,3,2],
[30,9,4,0],
[30,9,4,1],
[30,9,4,2],
[30,9,4,3],
[30,9,5,0],
[30,9,5,1],
[30,9,5,2],
[30,9,5,4],
[30,9,6,0],
[30,9,6,1],
[30,9,6,2],
[30,9,6,4],
[30,9,7,0],
[30,9,7,1],
[30,9,7,2],
[30,9,7,3],
[30,9,7,4],
[30,9,7,5],
[30,9,7,6],
[30,9,8,0],
[30,9,8,1],
[30,9,8,2],
[30,9,8,4],
[30,9,8,7],
[30,10,3,0],
[30,10,3,1],
[30,10,3,2],
[30,10,4,0],
[30,10,4,1],
[30,10,4,2],
[30,10,4,3],
[30,10,5,0],
[30,10,5,1],
[30,10,5,2],
[30,10,5,4],
[30,10,6,0],
[30,10,6,1],
[30,10,6,2],
[30,10,6,4],
[30,10,7,0],
[30,10,7,1],
[30,10,7,2],
[30,10,7,3],
[30,10,7,4],
[30,10,7,5],
[30,10,7,6],
[30,10,8,0],
[30,10,8,1],
[30,10,8,2],
[30,10,8,4],
[30,10,8,7],
[30,10,9,0],
[30,10,9,1],
[30,10,9,2],
[30,10,9,3],
[30,10,9,4],
[30,10,9,5],
[30,10,9,6],
[30,10,9,7],
[30,10,9,8],
[30,11,3,0],
[30,11,3,1],
[30,11,3,2],
[30,11,4,0],
[30,11,4,1],
[30,11,4,2],
[30,11,4,3],
[30,11,5,0],
[30,11,5,1],
[30,11,5,2],
[30,11,5,4],
[30,11,6,0],
[30,11,6,1],
[30,11,6,2],
[30,11,6,4],
[30,11,7,0],
[30,11,7,1],
[30,11,7,2],
[30,11,7,3],
[30,11,7,4],
[30,11,7,5],
[30,11,7,6],
[30,11,8,0],
[30,11,8,1],
[30,11,8,2],
[30,11,8,4],
[30,11,8,7],
[30,11,9,0],
[30,11,9,1],
[30,11,9,2],
[30,11,9,3],
[30,11,9,4],
[30,11,9,5],
[30,11,9,6],
[30,11,9,7],
[30,11,9,8],
[30,11,10,0],
[30,11,10,1],
[30,11,10,2],
[30,11,10,3],
[30,11,10,4],
[30,11,10,5],
[30,11,10,6],
[30,11,10,7],
[30,11,10,8],
[30,11,10,9],
[30,12,3,0],
[30,12,3,1],
[30,12,3,2],
[30,12,4,0],
[30,12,4,1],
[30,12,4,2],
[30,12,4,3],
[30,12,5,0],
[30,12,5,1],
[30,12,5,2],
[30,12,5,4],
[30,12,6,0],
[30,12,6,1],
[30,12,6,2],
[30,12,6,4],
[30,12,7,0],
[30,12,7,1],
[30,12,7,2],
[30,12,7,3],
[30,12,7,4],
[30,12,7,5],
[30,12,7,6],
[30,12,8,0],
[30,12,8,1],
[30,12,8,2],
[30,12,8,4],
[30,12,8,7],
[30,12,9,0],
[30,12,9,1],
[30,12,9,2],
[30,12,9,3],
[30,12,9,4],
[30,12,9,5],
[30,12,9,6],
[30,12,9,7],
[30,12,9,8],
[30,12,10,0],
[30,12,10,1],
[30,12,10,2],
[30,12,10,3],
[30,12,10,4],
[30,12,10,5],
[30,12,10,6],
[30,12,10,7],
[30,12,10,8],
[30,12,10,9],
[30,13,3,0],
[30,13,3,1],
[30,13,3,2],
[30,13,4,0],
[30,13,4,1],
[30,13,4,2],
[30,13,4,3],
[30,13,5,0],
[30,13,5,1],
[30,13,5,2],
[30,13,5,4],
[30,13,6,0],
[30,13,6,1],
[30,13,6,2],
[30,13,6,4],
[30,13,7,0],
[30,13,7,1],
[30,13,7,2],
[30,13,7,3],
[30,13,7,4],
[30,13,7,5],
[30,13,7,6],
[30,13,8,0],
[30,13,8,1],
[30,13,8,2],
[30,13,8,4],
[30,13,8,7],
[30,13,9,0],
[30,13,9,1],
[30,13,9,2],
[30,13,9,3],
[30,13,9,4],
[30,13,9,5],
[30,13,9,6],
[30,13,9,7],
[30,13,9,8],
[30,13,10,0],
[30,13,10,1],
[30,13,10,2],
[30,13,10,3],
[30,13,10,4],
[30,13,10,5],
[30,13,10,6],
[30,13,10,7],
[30,13,10,8],
[30,13,10,9],
[30,13,11,0],
[30,13,11,1],
[30,13,11,2],
[30,13,11,3],
[30,13,11,4],
[30,13,11,5],
[30,13,11,6],
[30,13,11,7],
[30,13,11,8],
[30,13,11,9],
[30,13,11,10],
[30,13,12,0],
[30,13,12,1],
[30,13,12,2],
[30,13,12,3],
[30,13,12,4],
[30,13,12,5],
[30,13,12,6],
[30,13,12,7],
[30,13,12,8],
[30,13,12,9],
[30,13,12,10],
[30,14,3,0],
[30,14,3,1],
[30,14,3,2],
[30,14,4,0],
[30,14,4,1],
[30,14,4,2],
[30,14,4,3],
[30,14,5,0],
[30,14,5,1],
[30,14,5,2],
[30,14,5,4],
[30,14,6,0],
[30,14,6,1],
[30,14,6,2],
[30,14,6,4],
[30,14,7,0],
[30,14,7,1],
[30,14,7,2],
[30,14,7,3],
[30,14,7,4],
[30,14,7,5],
[30,14,7,6],
[30,14,8,0],
[30,14,8,1],
[30,14,8,2],
[30,14,8,4],
[30,14,8,7],
[30,14,9,0],
[30,14,9,1],
[30,14,9,2],
[30,14,9,3],
[30,14,9,4],
[30,14,9,5],
[30,14,9,6],
[30,14,9,7],
[30,14,9,8],
[30,14,10,0],
[30,14,10,1],
[30,14,10,2],
[30,14,10,3],
[30,14,10,4],
[30,14,10,5],
[30,14,10,6],
[30,14,10,7],
[30,14,10,8],
[30,14,10,9],
[30,14,11,0],
[30,14,11,1],
[30,14,11,2],
[30,14,11,3],
[30,14,11,4],
[30,14,11,5],
[30,14,11,6],
[30,14,11,7],
[30,14,11,8],
[30,14,11,9],
[30,14,11,10],
[30,14,12,0],
[30,14,12,1],
[30,14,12,2],
[30,14,12,3],
[30,14,12,4],
[30,14,12,5],
[30,14,12,6],
[30,14,12,7],
[30,14,12,8],
[30,14,12,9],
[30,14,12,10],
[30,15,3,0],
[30,15,3,1],
[30,15,3,2],
[30,15,4,0],
[30,15,4,1],
[30,15,4,2],
[30,15,4,3],
[30,15,5,0],
[30,15,5,1],
[30,15,5,2],
[30,15,5,4],
[30,15,6,0],
[30,15,6,1],
[30,15,6,2],
[30,15,6,4],
[30,15,7,0],
[30,15,7,1],
[30,15,7,2],
[30,15,7,3],
[30,15,7,4],
[30,15,7,5],
[30,15,7,6],
[30,15,8,0],
[30,15,8,1],
[30,15,8,2],
[30,15,8,4],
[30,15,8,7],
[30,15,10,0],
[30,15,10,1],
[30,15,10,2],
[30,15,10,3],
[30,15,10,4],
[30,15,10,5],
[30,15,10,6],
[30,15,10,7],
[30,15,10,8],
[30,15,11,0],
[30,15,11,1],
[30,15,11,2],
[30,15,11,3],
[30,15,11,4],
[30,15,11,5],
[30,15,11,6],
[30,15,11,7],
[30,15,11,8],
[30,15,11,10],
[30,15,12,0],
[30,15,12,1],
[30,15,12,2],
[30,15,12,3],
[30,15,12,4],
[30,15,12,5],
[30,15,12,6],
[30,15,12,7],
[30,15,12,8],
[30,15,12,10],
[30,15,13,0],
[30,15,13,1],
[30,15,13,2],
[30,15,13,3],
[30,15,13,4],
[30,15,13,5],
[30,15,13,6],
[30,15,13,7],
[30,15,13,8],
[30,15,13,10],
[30,15,13,11],
[30,15,13,12],
[30,15,14,0],
[30,15,14,1],
[30,15,14,2],
[30,15,14,3],
[30,15,14,4],
[30,15,14,5],
[30,15,14,6],
[30,15,14,7],
[30,15,14,8],
[30,15,14,10],
[30,15,14,11],
[30,15,14,12],
[30,16,3,0],
[30,16,3,1],
[30,16,3,2],
[30,16,4,0],
[30,16,4,1],
[30,16,4,2],
[30,16,4,3],
[30,16,5,0],
[30,16,5,1],
[30,16,5,2],
[30,16,5,4],
[30,16,6,0],
[30,16,6,1],
[30,16,6,2],
[30,16,6,4],
[30,16,7,0],
[30,16,7,1],
[30,16,7,2],
[30,16,7,3],
[30,16,7,4],
[30,16,7,5],
[30,16,7,6],
[30,16,8,0],
[30,16,8,1],
[30,16,8,2],
[30,16,8,4],
[30,16,8,7],
[30,16,9,0],
[30,16,9,1],
[30,16,9,2],
[30,16,9,3],
[30,16,9,4],
[30,16,9,5],
[30,16,9,6],
[30,16,9,7],
[30,16,9,8],
[30,16,10,0],
[30,16,10,1],
[30,16,10,2],
[30,16,10,3],
[30,16,10,4],
[30,16,10,5],
[30,16,10,6],
[30,16,10,7],
[30,16,10,8],
[30,16,10,9],
[30,16,11,0],
[30,16,11,1],
[30,16,11,2],
[30,16,11,3],
[30,16,11,4],
[30,16,11,5],
[30,16,11,6],
[30,16,11,7],
[30,16,11,8],
[30,16,11,9],
[30,16,11,10],
[30,16,12,0],
[30,16,12,1],
[30,16,12,2],
[30,16,12,3],
[30,16,12,4],
[30,16,12,5],
[30,16,12,6],
[30,16,12,7],
[30,16,12,8],
[30,16,12,9],
[30,16,12,10],
[30,16,13,0],
[30,16,13,1],
[30,16,13,2],
[30,16,13,3],
[30,16,13,4],
[30,16,13,5],
[30,16,13,6],
[30,16,13,7],
[30,16,13,8],
[30,16,13,9],
[30,16,13,10],
[30,16,13,11],
[30,16,13,12],
[30,16,14,0],
[30,16,14,1],
[30,16,14,2],
[30,16,14,3],
[30,16,14,4],
[30,16,14,5],
[30,16,14,6],
[30,16,14,7],
[30,16,14,8],
[30,16,14,9],
[30,16,14,10],
[30,16,14,11],
[30,16,14,12],
[30,16,15,0],
[30,16,15,1],
[30,16,15,2],
[30,16,15,3],
[30,16,15,4],
[30,16,15,5],
[30,16,15,6],
[30,16,15,7],
[30,16,15,8],
[30,16,15,10],
[30,16,15,11],
[30,16,15,12],
[30,16,15,13],
[30,16,15,14],
[30,17,3,0],
[30,17,3,1],
[30,17,3,2],
[30,17,4,0],
[30,17,4,1],
[30,17,4,2],
[30,17,4,3],
[30,17,5,0],
[30,17,5,1],
[30,17,5,2],
[30,17,5,4],
[30,17,6,0],
[30,17,6,1],
[30,17,6,2],
[30,17,6,4],
[30,17,7,0],
[30,17,7,1],
[30,17,7,2],
[30,17,7,3],
[30,17,7,4],
[30,17,7,5],
[30,17,7,6],
[30,17,8,0],
[30,17,8,1],
[30,17,8,2],
[30,17,8,4],
[30,17,8,7],
[30,17,9,0],
[30,17,9,1],
[30,17,9,2],
[30,17,9,3],
[30,17,9,4],
[30,17,9,5],
[30,17,9,6],
[30,17,9,7],
[30,17,9,8],
[30,17,10,0],
[30,17,10,1],
[30,17,10,2],
[30,17,10,3],
[30,17,10,4],
[30,17,10,5],
[30,17,10,6],
[30,17,10,7],
[30,17,10,8],
[30,17,10,9],
[30,17,11,0],
[30,17,11,1],
[30,17,11,2],
[30,17,11,3],
[30,17,11,4],
[30,17,11,5],
[30,17,11,6],
[30,17,11,7],
[30,17,11,8],
[30,17,11,9],
[30,17,11,10],
[30,17,12,0],
[30,17,12,1],
[30,17,12,2],
[30,17,12,3],
[30,17,12,4],
[30,17,12,5],
[30,17,12,6],
[30,17,12,7],
[30,17,12,8],
[30,17,12,9],
[30,17,12,10],
[30,17,13,0],
[30,17,13,1],
[30,17,13,2],
[30,17,13,3],
[30,17,13,4],
[30,17,13,5],
[30,17,13,6],
[30,17,13,7],
[30,17,13,8],
[30,17,13,9],
[30,17,13,10],
[30,17,13,11],
[30,17,13,12],
[30,17,14,0],
[30,17,14,1],
[30,17,14,2],
[30,17,14,3],
[30,17,14,4],
[30,17,14,5],
[30,17,14,6],
[30,17,14,7],
[30,17,14,8],
[30,17,14,9],
[30,17,14,10],
[30,17,14,11],
[30,17,14,12],
[30,17,15,0],
[30,17,15,1],
[30,17,15,2],
[30,17,15,3],
[30,17,15,4],
[30,17,15,5],
[30,17,15,6],
[30,17,15,7],
[30,17,15,8],
[30,17,15,10],
[30,17,15,11],
[30,17,15,12],
[30,17,15,13],
[30,17,15,14],
[30,17,16,0],
[30,17,16,1],
[30,17,16,2],
[30,17,16,3],
[30,17,16,4],
[30,17,16,5],
[30,17,16,6],
[30,17,16,7],
[30,17,16,8],
[30,17,16,9],
[30,17,16,10],
[30,17,16,11],
[30,17,16,12],
[30,17,16,13],
[30,17,16,14],
[30,17,16,15],
[30,18,3,0],
[30,18,3,1],
[30,18,3,2],
[30,18,4,0],
[30,18,4,1],
[30,18,4,2],
[30,18,4,3],
[30,18,5,0],
[30,18,5,1],
[30,18,5,2],
[30,18,5,4],
[30,18,6,0],
[30,18,6,1],
[30,18,6,2],
[30,18,6,4],
[30,18,7,0],
[30,18,7,1],
[30,18,7,2],
[30,18,7,3],
[30,18,7,4],
[30,18,7,5],
[30,18,7,6],
[30,18,8,0],
[30,18,8,1],
[30,18,8,2],
[30,18,8,4],
[30,18,8,7],
[30,18,10,0],
[30,18,10,1],
[30,18,10,2],
[30,18,10,3],
[30,18,10,4],
[30,18,10,5],
[30,18,10,6],
[30,18,10,7],
[30,18,10,8],
[30,18,11,0],
[30,18,11,1],
[30,18,11,2],
[30,18,11,3],
[30,18,11,4],
[30,18,11,5],
[30,18,11,6],
[30,18,11,7],
[30,18,11,8],
[30,18,11,10],
[30,18,12,0],
[30,18,12,1],
[30,18,12,2],
[30,18,12,3],
[30,18,12,4],
[30,18,12,5],
[30,18,12,6],
[30,18,12,7],
[30,18,12,8],
[30,18,12,10],
[30,18,13,0],
[30,18,13,1],
[30,18,13,2],
[30,18,13,3],
[30,18,13,4],
[30,18,13,5],
[30,18,13,6],
[30,18,13,7],
[30,18,13,8],
[30,18,13,10],
[30,18,13,11],
[30,18,13,12],
[30,18,14,0],
[30,18,14,1],
[30,18,14,2],
[30,18,14,3],
[30,18,14,4],
[30,18,14,5],
[30,18,14,6],
[30,18,14,7],
[30,18,14,8],
[30,18,14,10],
[30,18,14,11],
[30,18,14,12],
[30,18,16,0],
[30,18,16,1],
[30,18,16,2],
[30,18,16,3],
[30,18,16,4],
[30,18,16,5],
[30,18,16,6],
[30,18,16,7],
[30,18,16,8],
[30,18,16,10],
[30,18,16,11],
[30,18,16,12],
[30,18,16,13],
[30,18,16,14],
[30,18,17,0],
[30,18,17,1],
[30,18,17,2],
[30,18,17,3],
[30,18,17,4],
[30,18,17,5],
[30,18,17,6],
[30,18,17,7],
[30,18,17,8],
[30,18,17,10],
[30,18,17,11],
[30,18,17,12],
[30,18,17,13],
[30,18,17,14],
[30,18,17,16],
[30,19,3,0],
[30,19,3,1],
[30,19,3,2],
[30,19,4,0],
[30,19,4,1],
[30,19,4,2],
[30,19,4,3],
[30,19,5,0],
[30,19,5,1],
[30,19,5,2],
[30,19,5,4],
[30,19,6,0],
[30,19,6,1],
[30,19,6,2],
[30,19,6,4],
[30,19,7,0],
[30,19,7,1],
[30,19,7,2],
[30,19,7,3],
[30,19,7,4],
[30,19,7,5],
[30,19,7,6],
[30,19,8,0],
[30,19,8,1],
[30,19,8,2],
[30,19,8,4],
[30,19,8,7],
[30,19,9,0],
[30,19,9,1],
[30,19,9,2],
[30,19,9,3],
[30,19,9,4],
[30,19,9,5],
[30,19,9,6],
[30,19,9,7],
[30,19,9,8],
[30,19,10,0],
[30,19,10,1],
[30,19,10,2],
[30,19,10,3],
[30,19,10,4],
[30,19,10,5],
[30,19,10,6],
[30,19,10,7],
[30,19,10,8],
[30,19,10,9],
[30,19,11,0],
[30,19,11,1],
[30,19,11,2],
[30,19,11,3],
[30,19,11,4],
[30,19,11,5],
[30,19,11,6],
[30,19,11,7],
[30,19,11,8],
[30,19,11,9],
[30,19,11,10],
[30,19,12,0],
[30,19,12,1],
[30,19,12,2],
[30,19,12,3],
[30,19,12,4],
[30,19,12,5],
[30,19,12,6],
[30,19,12,7],
[30,19,12,8],
[30,19,12,9],
[30,19,12,10],
[30,19,15,0],
[30,19,15,1],
[30,19,15,2],
[30,19,15,3],
[30,19,15,4],
[30,19,15,5],
[30,19,15,6],
[30,19,15,7],
[30,19,15,8],
[30,19,15,10],
[30,19,15,11],
[30,19,15,12],
[30,19,16,0],
[30,19,16,1],
[30,19,16,2],
[30,19,16,3],
[30,19,16,4],
[30,19,16,5],
[30,19,16,6],
[30,19,16,7],
[30,19,16,8],
[30,19,16,9],
[30,19,16,10],
[30,19,16,11],
[30,19,16,12],
[30,19,16,15],
[30,19,17,0],
[30,19,17,1],
[30,19,17,2],
[30,19,17,3],
[30,19,17,4],
[30,19,17,5],
[30,19,17,6],
[30,19,17,7],
[30,19,17,8],
[30,19,17,9],
[30,19,17,10],
[30,19,17,11],
[30,19,17,12],
[30,19,17,15],
[30,19,17,16],
[30,19,18,0],
[30,19,18,1],
[30,19,18,2],
[30,19,18,3],
[30,19,18,4],
[30,19,18,5],
[30,19,18,6],
[30,19,18,7],
[30,19,18,8],
[30,19,18,10],
[30,19,18,11],
[30,19,18,12],
[30,19,18,16],
[30,19,18,17],
[30,20,3,0],
[30,20,3,1],
[30,20,3,2],
[30,20,4,0],
[30,20,4,1],
[30,20,4,2],
[30,20,4,3],
[30,20,5,0],
[30,20,5,1],
[30,20,5,2],
[30,20,5,4],
[30,20,6,0],
[30,20,6,1],
[30,20,6,2],
[30,20,6,4],
[30,20,7,0],
[30,20,7,1],
[30,20,7,2],
[30,20,7,3],
[30,20,7,4],
[30,20,7,5],
[30,20,7,6],
[30,20,8,0],
[30,20,8,1],
[30,20,8,2],
[30,20,8,4],
[30,20,8,7],
[30,20,9,0],
[30,20,9,1],
[30,20,9,2],
[30,20,9,3],
[30,20,9,4],
[30,20,9,5],
[30,20,9,6],
[30,20,9,7],
[30,20,9,8],
[30,20,11,0],
[30,20,11,1],
[30,20,11,2],
[30,20,11,3],
[30,20,11,4],
[30,20,11,5],
[30,20,11,6],
[30,20,11,7],
[30,20,11,8],
[30,20,11,9],
[30,20,12,0],
[30,20,12,1],
[30,20,12,2],
[30,20,12,3],
[30,20,12,4],
[30,20,12,5],
[30,20,12,6],
[30,20,12,7],
[30,20,12,8],
[30,20,12,9],
[30,20,13,0],
[30,20,13,1],
[30,20,13,2],
[30,20,13,3],
[30,20,13,4],
[30,20,13,5],
[30,20,13,6],
[30,20,13,7],
[30,20,13,8],
[30,20,13,9],
[30,20,13,11],
[30,20,13,12],
[30,20,14,0],
[30,20,14,1],
[30,20,14,2],
[30,20,14,3],
[30,20,14,4],
[30,20,14,5],
[30,20,14,6],
[30,20,14,7],
[30,20,14,8],
[30,20,14,9],
[30,20,14,11],
[30,20,14,12],
[30,20,15,0],
[30,20,15,1],
[30,20,15,2],
[30,20,15,3],
[30,20,15,4],
[30,20,15,5],
[30,20,15,6],
[30,20,15,7],
[30,20,15,8],
[30,20,15,11],
[30,20,15,12],
[30,20,15,13],
[30,20,15,14],
[30,20,16,0],
[30,20,16,1],
[30,20,16,2],
[30,20,16,3],
[30,20,16,4],
[30,20,16,5],
[30,20,16,6],
[30,20,16,7],
[30,20,16,8],
[30,20,16,9],
[30,20,16,11],
[30,20,16,12],
[30,20,16,13],
[30,20,16,14],
[30,20,16,15],
[30,20,17,0],
[30,20,17,1],
[30,20,17,2],
[30,20,17,3],
[30,20,17,4],
[30,20,17,5],
[30,20,17,6],
[30,20,17,7],
[30,20,17,8],
[30,20,17,9],
[30,20,17,11],
[30,20,17,12],
[30,20,17,13],
[30,20,17,14],
[30,20,17,15],
[30,20,17,16],
[30,20,18,0],
[30,20,18,1],
[30,20,18,2],
[30,20,18,3],
[30,20,18,4],
[30,20,18,5],
[30,20,18,6],
[30,20,18,7],
[30,20,18,8],
[30,20,18,11],
[30,20,18,12],
[30,20,18,13],
[30,20,18,14],
[30,20,18,16],
[30,20,18,17],
[30,20,19,0],
[30,20,19,1],
[30,20,19,2],
[30,20,19,3],
[30,20,19,4],
[30,20,19,5],
[30,20,19,6],
[30,20,19,7],
[30,20,19,8],
[30,20,19,9],
[30,20,19,11],
[30,20,19,12],
[30,20,19,15],
[30,20,19,16],
[30,20,19,17],
[30,20,19,18],
[30,21,4,0],
[30,21,4,1],
[30,21,4,2],
[30,21,7,0],
[30,21,7,1],
[30,21,7,2],
[30,21,7,4],
[30,21,9,0],
[30,21,9,1],
[30,21,9,2],
[30,21,9,4],
[30,21,9,7],
[30,21,10,0],
[30,21,10,1],
[30,21,10,2],
[30,21,10,4],
[30,21,10,7],
[30,21,10,9],
[30,21,11,0],
[30,21,11,1],
[30,21,11,2],
[30,21,11,4],
[30,21,11,7],
[30,21,11,9],
[30,21,11,10],
[30,21,12,0],
[30,21,12,1],
[30,21,12,2],
[30,21,12,4],
[30,21,12,7],
[30,21,12,9],
[30,21,12,10],
[30,21,13,0],
[30,21,13,1],
[30,21,13,2],
[30,21,13,4],
[30,21,13,7],
[30,21,13,9],
[30,21,13,10],
[30,21,13,11],
[30,21,13,12],
[30,21,14,0],
[30,21,14,1],
[30,21,14,2],
[30,21,14,4],
[30,21,14,7],
[30,21,14,9],
[30,21,14,10],
[30,21,14,11],
[30,21,14,12],
[30,21,15,0],
[30,21,15,1],
[30,21,15,2],
[30,21,15,4],
[30,21,15,7],
[30,21,15,10],
[30,21,15,11],
[30,21,15,12],
[30,21,15,13],
[30,21,15,14],
[30,21,16,0],
[30,21,16,1],
[30,21,16,2],
[30,21,16,4],
[30,21,16,7],
[30,21,16,9],
[30,21,16,10],
[30,21,16,11],
[30,21,16,12],
[30,21,16,13],
[30,21,16,14],
[30,21,16,15],
[30,21,17,0],
[30,21,17,1],
[30,21,17,2],
[30,21,17,4],
[30,21,17,7],
[30,21,17,9],
[30,21,17,10],
[30,21,17,11],
[30,21,17,12],
[30,21,17,13],
[30,21,17,14],
[30,21,17,15],
[30,21,17,16],
[30,21,18,0],
[30,21,18,1],
[30,21,18,2],
[30,21,18,4],
[30,21,18,7],
[30,21,18,10],
[30,21,18,11],
[30,21,18,12],
[30,21,18,13],
[30,21,18,14],
[30,21,18,16],
[30,21,18,17],
[30,21,19,0],
[30,21,19,1],
[30,21,19,2],
[30,21,19,4],
[30,21,19,7],
[30,21,19,9],
[30,21,19,10],
[30,21,19,11],
[30,21,19,12],
[30,21,19,15],
[30,21,19,16],
[30,21,19,17],
[30,21,19,18],
[30,21,20,0],
[30,21,20,1],
[30,21,20,2],
[30,21,20,4],
[30,21,20,7],
[30,21,20,9],
[30,21,20,11],
[30,21,20,12],
[30,21,20,13],
[30,21,20,14],
[30,21,20,15],
[30,21,20,16],
[30,21,20,17],
[30,21,20,18],
[30,21,20,19],
[30,22,3,0],
[30,22,3,1],
[30,22,3,2],
[30,22,4,0],
[30,22,4,1],
[30,22,4,2],
[30,22,4,3],
[30,22,5,0],
[30,22,5,1],
[30,22,5,2],
[30,22,5,4],
[30,22,6,0],
[30,22,6,1],
[30,22,6,2],
[30,22,6,4],
[30,22,7,0],
[30,22,7,1],
[30,22,7,2],
[30,22,7,3],
[30,22,7,4],
[30,22,7,5],
[30,22,7,6],
[30,22,8,0],
[30,22,8,1],
[30,22,8,2],
[30,22,8,4],
[30,22,8,7],
[30,22,9,0],
[30,22,9,1],
[30,22,9,2],
[30,22,9,3],
[30,22,9,4],
[30,22,9,5],
[30,22,9,6],
[30,22,9,7],
[30,22,9,8],
[30,22,11,0],
[30,22,11,1],
[30,22,11,2],
[30,22,11,3],
[30,22,11,4],
[30,22,11,5],
[30,22,11,6],
[30,22,11,7],
[30,22,11,8],
[30,22,11,9],
[30,22,12,0],
[30,22,12,1],
[30,22,12,2],
[30,22,12,3],
[30,22,12,4],
[30,22,12,5],
[30,22,12,6],
[30,22,12,7],
[30,22,12,8],
[30,22,12,9],
[30,22,13,0],
[30,22,13,1],
[30,22,13,2],
[30,22,13,3],
[30,22,13,4],
[30,22,13,5],
[30,22,13,6],
[30,22,13,7],
[30,22,13,8],
[30,22,13,9],
[30,22,13,11],
[30,22,13,12],
[30,22,14,0],
[30,22,14,1],
[30,22,14,2],
[30,22,14,3],
[30,22,14,4],
[30,22,14,5],
[30,22,14,6],
[30,22,14,7],
[30,22,14,8],
[30,22,14,9],
[30,22,14,11],
[30,22,14,12],
[30,22,15,0],
[30,22,15,1],
[30,22,15,2],
[30,22,15,3],
[30,22,15,4],
[30,22,15,5],
[30,22,15,6],
[30,22,15,7],
[30,22,15,8],
[30,22,15,11],
[30,22,15,12],
[30,22,15,13],
[30,22,15,14],
[30,22,16,0],
[30,22,16,1],
[30,22,16,2],
[30,22,16,3],
[30,22,16,4],
[30,22,16,5],
[30,22,16,6],
[30,22,16,7],
[30,22,16,8],
[30,22,16,9],
[30,22,16,11],
[30,22,16,12],
[30,22,16,13],
[30,22,16,14],
[30,22,16,15],
[30,22,17,0],
[30,22,17,1],
[30,22,17,2],
[30,22,17,3],
[30,22,17,4],
[30,22,17,5],
[30,22,17,6],
[30,22,17,7],
[30,22,17,8],
[30,22,17,9],
[30,22,17,11],
[30,22,17,12],
[30,22,17,13],
[30,22,17,14],
[30,22,17,15],
[30,22,17,16],
[30,22,18,0],
[30,22,18,1],
[30,22,18,2],
[30,22,18,3],
[30,22,18,4],
[30,22,18,5],
[30,22,18,6],
[30,22,18,7],
[30,22,18,8],
[30,22,18,11],
[30,22,18,12],
[30,22,18,13],
[30,22,18,14],
[30,22,18,16],
[30,22,18,17],
[30,22,19,0],
[30,22,19,1],
[30,22,19,2],
[30,22,19,3],
[30,22,19,4],
[30,22,19,5],
[30,22,19,6],
[30,22,19,7],
[30,22,19,8],
[30,22,19,9],
[30,22,19,11],
[30,22,19,12],
[30,22,19,15],
[30,22,19,16],
[30,22,19,17],
[30,22,19,18],
[30,22,21,0],
[30,22,21,1],
[30,22,21,2],
[30,22,21,4],
[30,22,21,7],
[30,22,21,9],
[30,22,21,11],
[30,22,21,12],
[30,22,21,13],
[30,22,21,14],
[30,22,21,15],
[30,22,21,16],
[30,22,21,17],
[30,22,21,18],
[30,22,21,19],
[30,24,3,0],
[30,24,3,1],
[30,24,3,2],
[30,24,4,0],
[30,24,4,1],
[30,24,4,2],
[30,24,4,3],
[30,24,5,0],
[30,24,5,1],
[30,24,5,2],
[30,24,5,4],
[30,24,6,0],
[30,24,6,1],
[30,24,6,2],
[30,24,6,4],
[30,24,7,0],
[30,24,7,1],
[30,24,7,2],
[30,24,7,3],
[30,24,7,4],
[30,24,7,5],
[30,24,7,6],
[30,24,8,0],
[30,24,8,1],
[30,24,8,2],
[30,24,8,4],
[30,24,8,7],
[30,24,9,0],
[30,24,9,1],
[30,24,9,2],
[30,24,9,3],
[30,24,9,4],
[30,24,9,5],
[30,24,9,6],
[30,24,9,7],
[30,24,9,8],
[30,24,10,0],
[30,24,10,1],
[30,24,10,2],
[30,24,10,3],
[30,24,10,4],
[30,24,10,5],
[30,24,10,6],
[30,24,10,7],
[30,24,10,8],
[30,24,10,9],
[30,24,11,0],
[30,24,11,1],
[30,24,11,2],
[30,24,11,3],
[30,24,11,4],
[30,24,11,5],
[30,24,11,6],
[30,24,11,7],
[30,24,11,8],
[30,24,11,9],
[30,24,11,10],
[30,24,12,0],
[30,24,12,1],
[30,24,12,2],
[30,24,12,3],
[30,24,12,4],
[30,24,12,5],
[30,24,12,6],
[30,24,12,7],
[30,24,12,8],
[30,24,12,9],
[30,24,12,10],
[30,24,13,0],
[30,24,13,1],
[30,24,13,2],
[30,24,13,3],
[30,24,13,4],
[30,24,13,5],
[30,24,13,6],
[30,24,13,7],
[30,24,13,8],
[30,24,13,9],
[30,24,13,10],
[30,24,13,11],
[30,24,13,12],
[30,24,14,0],
[30,24,14,1],
[30,24,14,2],
[30,24,14,3],
[30,24,14,4],
[30,24,14,5],
[30,24,14,6],
[30,24,14,7],
[30,24,14,8],
[30,24,14,9],
[30,24,14,10],
[30,24,14,11],
[30,24,14,12],
[30,24,15,0],
[30,24,15,1],
[30,24,15,2],
[30,24,15,3],
[30,24,15,4],
[30,24,15,5],
[30,24,15,6],
[30,24,15,7],
[30,24,15,8],
[30,24,15,10],
[30,24,15,11],
[30,24,15,12],
[30,24,15,13],
[30,24,15,14],
[30,24,17,0],
[30,24,17,1],
[30,24,17,2],
[30,24,17,3],
[30,24,17,4],
[30,24,17,5],
[30,24,17,6],
[30,24,17,7],
[30,24,17,8],
[30,24,17,9],
[30,24,17,10],
[30,24,17,11],
[30,24,17,12],
[30,24,17,13],
[30,24,17,14],
[30,24,17,15],
[30,24,18,0],
[30,24,18,1],
[30,24,18,2],
[30,24,18,3],
[30,24,18,4],
[30,24,18,5],
[30,24,18,6],
[30,24,18,7],
[30,24,18,8],
[30,24,18,10],
[30,24,18,11],
[30,24,18,12],
[30,24,18,13],
[30,24,18,14],
[30,24,18,17],
[30,24,19,0],
[30,24,19,1],
[30,24,19,2],
[30,24,19,3],
[30,24,19,4],
[30,24,19,5],
[30,24,19,6],
[30,24,19,7],
[30,24,19,8],
[30,24,19,9],
[30,24,19,10],
[30,24,19,11],
[30,24,19,12],
[30,24,19,15],
[30,24,19,17],
[30,24,19,18],
[30,24,20,0],
[30,24,20,1],
[30,24,20,2],
[30,24,20,3],
[30,24,20,4],
[30,24,20,5],
[30,24,20,6],
[30,24,20,7],
[30,24,20,8],
[30,24,20,9],
[30,24,20,11],
[30,24,20,12],
[30,24,20,13],
[30,24,20,14],
[30,24,20,15],
[30,24,20,17],
[30,24,20,18],
[30,24,20,19],
[30,24,21,0],
[30,24,21,1],
[30,24,21,2],
[30,24,21,4],
[30,24,21,7],
[30,24,21,9],
[30,24,21,10],
[30,24,21,11],
[30,24,21,12],
[30,24,21,13],
[30,24,21,14],
[30,24,21,15],
[30,24,21,17],
[30,24,21,18],
[30,24,21,19],
[30,24,21,20],
[30,24,22,0],
[30,24,22,1],
[30,24,22,2],
[30,24,22,3],
[30,24,22,4],
[30,24,22,5],
[30,24,22,6],
[30,24,22,7],
[30,24,22,8],
[30,24,22,9],
[30,24,22,11],
[30,24,22,12],
[30,24,22,13],
[30,24,22,14],
[30,24,22,15],
[30,24,22,17],
[30,24,22,18],
[30,24,22,19],
[30,24,22,21],
[30,25,3,0],
[30,25,3,1],
[30,25,3,2],
[30,25,4,0],
[30,25,4,1],
[30,25,4,2],
[30,25,4,3],
[30,25,5,0],
[30,25,5,1],
[30,25,5,2],
[30,25,5,4],
[30,25,6,0],
[30,25,6,1],
[30,25,6,2],
[30,25,6,4],
[30,25,8,0],
[30,25,8,1],
[30,25,8,2],
[30,25,8,4],
[30,25,9,0],
[30,25,9,1],
[30,25,9,2],
[30,25,9,3],
[30,25,9,4],
[30,25,9,5],
[30,25,9,6],
[30,25,9,8],
[30,25,10,0],
[30,25,10,1],
[30,25,10,2],
[30,25,10,3],
[30,25,10,4],
[30,25,10,5],
[30,25,10,6],
[30,25,10,8],
[30,25,10,9],
[30,25,11,0],
[30,25,11,1],
[30,25,11,2],
[30,25,11,3],
[30,25,11,4],
[30,25,11,5],
[30,25,11,6],
[30,25,11,8],
[30,25,11,9],
[30,25,11,10],
[30,25,12,0],
[30,25,12,1],
[30,25,12,2],
[30,25,12,3],
[30,25,12,4],
[30,25,12,5],
[30,25,12,6],
[30,25,12,8],
[30,25,12,9],
[30,25,12,10],
[30,25,13,0],
[30,25,13,1],
[30,25,13,2],
[30,25,13,3],
[30,25,13,4],
[30,25,13,5],
[30,25,13,6],
[30,25,13,8],
[30,25,13,9],
[30,25,13,10],
[30,25,13,11],
[30,25,13,12],
[30,25,14,0],
[30,25,14,1],
[30,25,14,2],
[30,25,14,3],
[30,25,14,4],
[30,25,14,5],
[30,25,14,6],
[30,25,14,8],
[30,25,14,9],
[30,25,14,10],
[30,25,14,11],
[30,25,14,12],
[30,25,15,0],
[30,25,15,1],
[30,25,15,2],
[30,25,15,3],
[30,25,15,4],
[30,25,15,5],
[30,25,15,6],
[30,25,15,8],
[30,25,15,10],
[30,25,15,11],
[30,25,15,12],
[30,25,15,13],
[30,25,15,14],
[30,25,16,0],
[30,25,16,1],
[30,25,16,2],
[30,25,16,3],
[30,25,16,4],
[30,25,16,5],
[30,25,16,6],
[30,25,16,8],
[30,25,16,9],
[30,25,16,10],
[30,25,16,11],
[30,25,16,12],
[30,25,16,13],
[30,25,16,14],
[30,25,16,15],
[30,25,17,0],
[30,25,17,1],
[30,25,17,2],
[30,25,17,3],
[30,25,17,4],
[30,25,17,5],
[30,25,17,6],
[30,25,17,8],
[30,25,17,9],
[30,25,17,10],
[30,25,17,11],
[30,25,17,12],
[30,25,17,13],
[30,25,17,14],
[30,25,17,15],
[30,25,17,16],
[30,25,18,0],
[30,25,18,1],
[30,25,18,2],
[30,25,18,3],
[30,25,18,4],
[30,25,18,5],
[30,25,18,6],
[30,25,18,8],
[30,25,18,10],
[30,25,18,11],
[30,25,18,12],
[30,25,18,13],
[30,25,18,14],
[30,25,18,16],
[30,25,18,17],
[30,25,19,0],
[30,25,19,1],
[30,25,19,2],
[30,25,19,3],
[30,25,19,4],
[30,25,19,5],
[30,25,19,6],
[30,25,19,8],
[30,25,19,9],
[30,25,19,10],
[30,25,19,11],
[30,25,19,12],
[30,25,19,15],
[30,25,19,16],
[30,25,19,17],
[30,25,19,18],
[30,25,20,0],
[30,25,20,1],
[30,25,20,2],
[30,25,20,3],
[30,25,20,4],
[30,25,20,5],
[30,25,20,6],
[30,25,20,8],
[30,25,20,9],
[30,25,20,11],
[30,25,20,12],
[30,25,20,13],
[30,25,20,14],
[30,25,20,15],
[30,25,20,16],
[30,25,20,17],
[30,25,20,18],
[30,25,20,19],
[30,25,21,0],
[30,25,21,1],
[30,25,21,2],
[30,25,21,4],
[30,25,21,9],
[30,25,21,10],
[30,25,21,11],
[30,25,21,12],
[30,25,21,13],
[30,25,21,14],
[30,25,21,15],
[30,25,21,16],
[30,25,21,17],
[30,25,21,18],
[30,25,21,19],
[30,25,21,20],
[30,25,22,0],
[30,25,22,1],
[30,25,22,2],
[30,25,22,3],
[30,25,22,4],
[30,25,22,5],
[30,25,22,6],
[30,25,22,8],
[30,25,22,9],
[30,25,22,11],
[30,25,22,12],
[30,25,22,13],
[30,25,22,14],
[30,25,22,15],
[30,25,22,16],
[30,25,22,17],
[30,25,22,18],
[30,25,22,19],
[30,25,22,21],
[30,25,24,0],
[30,25,24,1],
[30,25,24,2],
[30,25,24,3],
[30,25,24,4],
[30,25,24,5],
[30,25,24,6],
[30,25,24,8],
[30,25,24,9],
[30,25,24,10],
[30,25,24,11],
[30,25,24,12],
[30,25,24,13],
[30,25,24,14],
[30,25,24,15],
[30,25,24,17],
[30,25,24,18],
[30,25,24,19],
[30,25,24,20],
[30,25,24,21],
[30,25,24,22],
[30,26,3,0],
[30,26,3,1],
[30,26,3,2],
[30,26,4,0],
[30,26,4,1],
[30,26,4,2],
[30,26,4,3],
[30,26,5,0],
[30,26,5,1],
[30,26,5,2],
[30,26,5,4],
[30,26,6,0],
[30,26,6,1],
[30,26,6,2],
[30,26,6,4],
[30,26,7,0],
[30,26,7,1],
[30,26,7,2],
[30,26,7,3],
[30,26,7,4],
[30,26,7,5],
[30,26,7,6],
[30,26,8,0],
[30,26,8,1],
[30,26,8,2],
[30,26,8,4],
[30,26,8,7],
[30,26,10,0],
[30,26,10,1],
[30,26,10,2],
[30,26,10,3],
[30,26,10,4],
[30,26,10,5],
[30,26,10,6],
[30,26,10,7],
[30,26,10,8],
[30,26,11,0],
[30,26,11,1],
[30,26,11,2],
[30,26,11,3],
[30,26,11,4],
[30,26,11,5],
[30,26,11,6],
[30,26,11,7],
[30,26,11,8],
[30,26,11,10],
[30,26,12,0],
[30,26,12,1],
[30,26,12,2],
[30,26,12,3],
[30,26,12,4],
[30,26,12,5],
[30,26,12,6],
[30,26,12,7],
[30,26,12,8],
[30,26,12,10],
[30,26,13,0],
[30,26,13,1],
[30,26,13,2],
[30,26,13,3],
[30,26,13,4],
[30,26,13,5],
[30,26,13,6],
[30,26,13,7],
[30,26,13,8],
[30,26,13,10],
[30,26,13,11],
[30,26,13,12],
[30,26,14,0],
[30,26,14,1],
[30,26,14,2],
[30,26,14,3],
[30,26,14,4],
[30,26,14,5],
[30,26,14,6],
[30,26,14,7],
[30,26,14,8],
[30,26,14,10],
[30,26,14,11],
[30,26,14,12],
[30,26,16,0],
[30,26,16,1],
[30,26,16,2],
[30,26,16,3],
[30,26,16,4],
[30,26,16,5],
[30,26,16,6],
[30,26,16,7],
[30,26,16,8],
[30,26,16,10],
[30,26,16,11],
[30,26,16,12],
[30,26,16,13],
[30,26,16,14],
[30,26,17,0],
[30,26,17,1],
[30,26,17,2],
[30,26,17,3],
[30,26,17,4],
[30,26,17,5],
[30,26,17,6],
[30,26,17,7],
[30,26,17,8],
[30,26,17,10],
[30,26,17,11],
[30,26,17,12],
[30,26,17,13],
[30,26,17,14],
[30,26,17,16],
[30,26,19,0],
[30,26,19,1],
[30,26,19,2],
[30,26,19,3],
[30,26,19,4],
[30,26,19,5],
[30,26,19,6],
[30,26,19,7],
[30,26,19,8],
[30,26,19,10],
[30,26,19,11],
[30,26,19,12],
[30,26,19,16],
[30,26,19,17],
[30,26,20,0],
[30,26,20,1],
[30,26,20,2],
[30,26,20,3],
[30,26,20,4],
[30,26,20,5],
[30,26,20,6],
[30,26,20,7],
[30,26,20,8],
[30,26,20,11],
[30,26,20,12],
[30,26,20,13],
[30,26,20,14],
[30,26,20,16],
[30,26,20,17],
[30,26,20,19],
[30,26,21,0],
[30,26,21,1],
[30,26,21,2],
[30,26,21,4],
[30,26,21,7],
[30,26,21,10],
[30,26,21,11],
[30,26,21,12],
[30,26,21,13],
[30,26,21,14],
[30,26,21,16],
[30,26,21,17],
[30,26,21,19],
[30,26,21,20],
[30,26,22,0],
[30,26,22,1],
[30,26,22,2],
[30,26,22,3],
[30,26,22,4],
[30,26,22,5],
[30,26,22,6],
[30,26,22,7],
[30,26,22,8],
[30,26,22,11],
[30,26,22,12],
[30,26,22,13],
[30,26,22,14],
[30,26,22,16],
[30,26,22,17],
[30,26,22,19],
[30,26,22,21],
[30,26,24,0],
[30,26,24,1],
[30,26,24,2],
[30,26,24,3],
[30,26,24,4],
[30,26,24,5],
[30,26,24,6],
[30,26,24,7],
[30,26,24,8],
[30,26,24,10],
[30,26,24,11],
[30,26,24,12],
[30,26,24,13],
[30,26,24,14],
[30,26,24,17],
[30,26,24,19],
[30,26,24,20],
[30,26,24,21],
[30,26,24,22],
[30,26,25,0],
[30,26,25,1],
[30,26,25,2],
[30,26,25,3],
[30,26,25,4],
[30,26,25,5],
[30,26,25,6],
[30,26,25,8],
[30,26,25,10],
[30,26,25,11],
[30,26,25,12],
[30,26,25,13],
[30,26,25,14],
[30,26,25,16],
[30,26,25,17],
[30,26,25,19],
[30,26,25,20],
[30,26,25,21],
[30,26,25,22],
[30,26,25,24],
[30,27,3,0],
[30,27,3,1],
[30,27,3,2],
[30,27,4,0],
[30,27,4,1],
[30,27,4,2],
[30,27,4,3],
[30,27,5,0],
[30,27,5,1],
[30,27,5,2],
[30,27,5,4],
[30,27,6,0],
[30,27,6,1],
[30,27,6,2],
[30,27,6,4],
[30,27,7,0],
[30,27,7,1],
[30,27,7,2],
[30,27,7,3],
[30,27,7,4],
[30,27,7,5],
[30,27,7,6],
[30,27,8,0],
[30,27,8,1],
[30,27,8,2],
[30,27,8,4],
[30,27,8,7],
[30,27,9,0],
[30,27,9,1],
[30,27,9,2],
[30,27,9,3],
[30,27,9,4],
[30,27,9,5],
[30,27,9,6],
[30,27,9,7],
[30,27,9,8],
[30,27,11,0],
[30,27,11,1],
[30,27,11,2],
[30,27,11,3],
[30,27,11,4],
[30,27,11,5],
[30,27,11,6],
[30,27,11,7],
[30,27,11,8],
[30,27,11,9],
[30,27,12,0],
[30,27,12,1],
[30,27,12,2],
[30,27,12,3],
[30,27,12,4],
[30,27,12,5],
[30,27,12,6],
[30,27,12,7],
[30,27,12,8],
[30,27,12,9],
[30,27,13,0],
[30,27,13,1],
[30,27,13,2],
[30,27,13,3],
[30,27,13,4],
[30,27,13,5],
[30,27,13,6],
[30,27,13,7],
[30,27,13,8],
[30,27,13,9],
[30,27,13,11],
[30,27,13,12],
[30,27,14,0],
[30,27,14,1],
[30,27,14,2],
[30,27,14,3],
[30,27,14,4],
[30,27,14,5],
[30,27,14,6],
[30,27,14,7],
[30,27,14,8],
[30,27,14,9],
[30,27,14,11],
[30,27,14,12],
[30,27,15,0],
[30,27,15,1],
[30,27,15,2],
[30,27,15,3],
[30,27,15,4],
[30,27,15,5],
[30,27,15,6],
[30,27,15,7],
[30,27,15,8],
[30,27,15,11],
[30,27,15,12],
[30,27,15,13],
[30,27,15,14],
[30,27,16,0],
[30,27,16,1],
[30,27,16,2],
[30,27,16,3],
[30,27,16,4],
[30,27,16,5],
[30,27,16,6],
[30,27,16,7],
[30,27,16,8],
[30,27,16,9],
[30,27,16,11],
[30,27,16,12],
[30,27,16,13],
[30,27,16,14],
[30,27,16,15],
[30,27,17,0],
[30,27,17,1],
[30,27,17,2],
[30,27,17,3],
[30,27,17,4],
[30,27,17,5],
[30,27,17,6],
[30,27,17,7],
[30,27,17,8],
[30,27,17,9],
[30,27,17,11],
[30,27,17,12],
[30,27,17,13],
[30,27,17,14],
[30,27,17,15],
[30,27,17,16],
[30,27,18,0],
[30,27,18,1],
[30,27,18,2],
[30,27,18,3],
[30,27,18,4],
[30,27,18,5],
[30,27,18,6],
[30,27,18,7],
[30,27,18,8],
[30,27,18,11],
[30,27,18,12],
[30,27,18,13],
[30,27,18,14],
[30,27,18,16],
[30,27,18,17],
[30,27,19,0],
[30,27,19,1],
[30,27,19,2],
[30,27,19,3],
[30,27,19,4],
[30,27,19,5],
[30,27,19,6],
[30,27,19,7],
[30,27,19,8],
[30,27,19,9],
[30,27,19,11],
[30,27,19,12],
[30,27,19,15],
[30,27,19,16],
[30,27,19,17],
[30,27,19,18],
[30,27,21,0],
[30,27,21,1],
[30,27,21,2],
[30,27,21,4],
[30,27,21,7],
[30,27,21,9],
[30,27,21,11],
[30,27,21,12],
[30,27,21,13],
[30,27,21,14],
[30,27,21,15],
[30,27,21,16],
[30,27,21,17],
[30,27,21,18],
[30,27,21,19],
[30,27,24,0],
[30,27,24,1],
[30,27,24,2],
[30,27,24,3],
[30,27,24,4],
[30,27,24,5],
[30,27,24,6],
[30,27,24,7],
[30,27,24,8],
[30,27,24,9],
[30,27,24,11],
[30,27,24,12],
[30,27,24,13],
[30,27,24,14],
[30,27,24,15],
[30,27,24,17],
[30,27,24,18],
[30,27,24,19],
[30,27,24,21],
[30,27,25,0],
[30,27,25,1],
[30,27,25,2],
[30,27,25,3],
[30,27,25,4],
[30,27,25,5],
[30,27,25,6],
[30,27,25,8],
[30,27,25,9],
[30,27,25,11],
[30,27,25,12],
[30,27,25,13],
[30,27,25,14],
[30,27,25,15],
[30,27,25,16],
[30,27,25,17],
[30,27,25,18],
[30,27,25,19],
[30,27,25,21],
[30,27,25,24],
[30,27,26,0],
[30,27,26,1],
[30,27,26,2],
[30,27,26,3],
[30,27,26,4],
[30,27,26,5],
[30,27,26,6],
[30,27,26,7],
[30,27,26,8],
[30,27,26,11],
[30,27,26,12],
[30,27,26,13],
[30,27,26,14],
[30,27,26,16],
[30,27,26,17],
[30,27,26,19],
[30,27,26,21],
[30,27,26,24],
[30,27,26,25],
[30,28,3,0],
[30,28,3,1],
[30,28,3,2],
[30,28,4,0],
[30,28,4,1],
[30,28,4,2],
[30,28,4,3],
[30,28,5,0],
[30,28,5,1],
[30,28,5,2],
[30,28,5,4],
[30,28,6,0],
[30,28,6,1],
[30,28,6,2],
[30,28,6,4],
[30,28,8,0],
[30,28,8,1],
[30,28,8,2],
[30,28,8,4],
[30,28,9,0],
[30,28,9,1],
[30,28,9,2],
[30,28,9,3],
[30,28,9,4],
[30,28,9,5],
[30,28,9,6],
[30,28,9,8],
[30,28,10,0],
[30,28,10,1],
[30,28,10,2],
[30,28,10,3],
[30,28,10,4],
[30,28,10,5],
[30,28,10,6],
[30,28,10,8],
[30,28,10,9],
[30,28,11,0],
[30,28,11,1],
[30,28,11,2],
[30,28,11,3],
[30,28,11,4],
[30,28,11,5],
[30,28,11,6],
[30,28,11,8],
[30,28,11,9],
[30,28,11,10],
[30,28,12,0],
[30,28,12,1],
[30,28,12,2],
[30,28,12,3],
[30,28,12,4],
[30,28,12,5],
[30,28,12,6],
[30,28,12,8],
[30,28,12,9],
[30,28,12,10],
[30,28,13,0],
[30,28,13,1],
[30,28,13,2],
[30,28,13,3],
[30,28,13,4],
[30,28,13,5],
[30,28,13,6],
[30,28,13,8],
[30,28,13,9],
[30,28,13,10],
[30,28,13,11],
[30,28,13,12],
[30,28,14,0],
[30,28,14,1],
[30,28,14,2],
[30,28,14,3],
[30,28,14,4],
[30,28,14,5],
[30,28,14,6],
[30,28,14,8],
[30,28,14,9],
[30,28,14,10],
[30,28,14,11],
[30,28,14,12],
[30,28,15,0],
[30,28,15,1],
[30,28,15,2],
[30,28,15,3],
[30,28,15,4],
[30,28,15,5],
[30,28,15,6],
[30,28,15,8],
[30,28,15,10],
[30,28,15,11],
[30,28,15,12],
[30,28,15,13],
[30,28,15,14],
[30,28,16,0],
[30,28,16,1],
[30,28,16,2],
[30,28,16,3],
[30,28,16,4],
[30,28,16,5],
[30,28,16,6],
[30,28,16,8],
[30,28,16,9],
[30,28,16,10],
[30,28,16,11],
[30,28,16,12],
[30,28,16,13],
[30,28,16,14],
[30,28,16,15],
[30,28,17,0],
[30,28,17,1],
[30,28,17,2],
[30,28,17,3],
[30,28,17,4],
[30,28,17,5],
[30,28,17,6],
[30,28,17,8],
[30,28,17,9],
[30,28,17,10],
[30,28,17,11],
[30,28,17,12],
[30,28,17,13],
[30,28,17,14],
[30,28,17,15],
[30,28,17,16],
[30,28,18,0],
[30,28,18,1],
[30,28,18,2],
[30,28,18,3],
[30,28,18,4],
[30,28,18,5],
[30,28,18,6],
[30,28,18,8],
[30,28,18,10],
[30,28,18,11],
[30,28,18,12],
[30,28,18,13],
[30,28,18,14],
[30,28,18,16],
[30,28,18,17],
[30,28,19,0],
[30,28,19,1],
[30,28,19,2],
[30,28,19,3],
[30,28,19,4],
[30,28,19,5],
[30,28,19,6],
[30,28,19,8],
[30,28,19,9],
[30,28,19,10],
[30,28,19,11],
[30,28,19,12],
[30,28,19,15],
[30,28,19,16],
[30,28,19,17],
[30,28,19,18],
[30,28,20,0],
[30,28,20,1],
[30,28,20,2],
[30,28,20,3],
[30,28,20,4],
[30,28,20,5],
[30,28,20,6],
[30,28,20,8],
[30,28,20,9],
[30,28,20,11],
[30,28,20,12],
[30,28,20,13],
[30,28,20,14],
[30,28,20,15],
[30,28,20,16],
[30,28,20,17],
[30,28,20,18],
[30,28,20,19],
[30,28,21,0],
[30,28,21,1],
[30,28,21,2],
[30,28,21,4],
[30,28,21,9],
[30,28,21,10],
[30,28,21,11],
[30,28,21,12],
[30,28,21,13],
[30,28,21,14],
[30,28,21,15],
[30,28,21,16],
[30,28,21,17],
[30,28,21,18],
[30,28,21,19],
[30,28,21,20],
[30,28,22,0],
[30,28,22,1],
[30,28,22,2],
[30,28,22,3],
[30,28,22,4],
[30,28,22,5],
[30,28,22,6],
[30,28,22,8],
[30,28,22,9],
[30,28,22,11],
[30,28,22,12],
[30,28,22,13],
[30,28,22,14],
[30,28,22,15],
[30,28,22,16],
[30,28,22,17],
[30,28,22,18],
[30,28,22,19],
[30,28,22,21],
[30,28,24,0],
[30,28,24,1],
[30,28,24,2],
[30,28,24,3],
[30,28,24,4],
[30,28,24,5],
[30,28,24,6],
[30,28,24,8],
[30,28,24,9],
[30,28,24,10],
[30,28,24,11],
[30,28,24,12],
[30,28,24,13],
[30,28,24,14],
[30,28,24,15],
[30,28,24,17],
[30,28,24,18],
[30,28,24,19],
[30,28,24,20],
[30,28,24,21],
[30,28,24,22],
[30,28,26,0],
[30,28,26,1],
[30,28,26,2],
[30,28,26,3],
[30,28,26,4],
[30,28,26,5],
[30,28,26,6],
[30,28,26,8],
[30,28,26,10],
[30,28,26,11],
[30,28,26,12],
[30,28,26,13],
[30,28,26,14],
[30,28,26,16],
[30,28,26,17],
[30,28,26,19],
[30,28,26,20],
[30,28,26,21],
[30,28,26,22],
[30,28,26,24],
[30,28,27,0],
[30,28,27,1],
[30,28,27,2],
[30,28,27,3],
[30,28,27,4],
[30,28,27,5],
[30,28,27,6],
[30,28,27,8],
[30,28,27,9],
[30,28,27,11],
[30,28,27,12],
[30,28,27,13],
[30,28,27,14],
[30,28,27,15],
[30,28,27,16],
[30,28,27,17],
[30,28,27,18],
[30,28,27,19],
[30,28,27,21],
[30,28,27,24],
[30,28,27,26],
[30,29,3,0],
[30,29,3,1],
[30,29,3,2],
[30,29,4,0],
[30,29,4,1],
[30,29,4,2],
[30,29,4,3],
[30,29,5,0],
[30,29,5,1],
[30,29,5,2],
[30,29,5,4],
[30,29,6,0],
[30,29,6,1],
[30,29,6,2],
[30,29,6,4],
[30,29,7,0],
[30,29,7,1],
[30,29,7,2],
[30,29,7,3],
[30,29,7,4],
[30,29,7,5],
[30,29,7,6],
[30,29,8,0],
[30,29,8,1],
[30,29,8,2],
[30,29,8,4],
[30,29,8,7],
[30,29,9,0],
[30,29,9,1],
[30,29,9,2],
[30,29,9,3],
[30,29,9,4],
[30,29,9,5],
[30,29,9,6],
[30,29,9,7],
[30,29,9,8],
[30,29,10,0],
[30,29,10,1],
[30,29,10,2],
[30,29,10,3],
[30,29,10,4],
[30,29,10,5],
[30,29,10,6],
[30,29,10,7],
[30,29,10,8],
[30,29,10,9],
[30,29,13,0],
[30,29,13,1],
[30,29,13,2],
[30,29,13,3],
[30,29,13,4],
[30,29,13,5],
[30,29,13,6],
[30,29,13,7],
[30,29,13,8],
[30,29,13,9],
[30,29,13,10],
[30,29,14,0],
[30,29,14,1],
[30,29,14,2],
[30,29,14,3],
[30,29,14,4],
[30,29,14,5],
[30,29,14,6],
[30,29,14,7],
[30,29,14,8],
[30,29,14,9],
[30,29,14,10],
[30,29,15,0],
[30,29,15,1],
[30,29,15,2],
[30,29,15,3],
[30,29,15,4],
[30,29,15,5],
[30,29,15,6],
[30,29,15,7],
[30,29,15,8],
[30,29,15,10],
[30,29,15,13],
[30,29,15,14],
[30,29,16,0],
[30,29,16,1],
[30,29,16,2],
[30,29,16,3],
[30,29,16,4],
[30,29,16,5],
[30,29,16,6],
[30,29,16,7],
[30,29,16,8],
[30,29,16,9],
[30,29,16,10],
[30,29,16,13],
[30,29,16,14],
[30,29,16,15],
[30,29,17,0],
[30,29,17,1],
[30,29,17,2],
[30,29,17,3],
[30,29,17,4],
[30,29,17,5],
[30,29,17,6],
[30,29,17,7],
[30,29,17,8],
[30,29,17,9],
[30,29,17,10],
[30,29,17,13],
[30,29,17,14],
[30,29,17,15],
[30,29,17,16],
[30,29,18,0],
[30,29,18,1],
[30,29,18,2],
[30,29,18,3],
[30,29,18,4],
[30,29,18,5],
[30,29,18,6],
[30,29,18,7],
[30,29,18,8],
[30,29,18,10],
[30,29,18,13],
[30,29,18,14],
[30,29,18,16],
[30,29,18,17],
[30,29,19,0],
[30,29,19,1],
[30,29,19,2],
[30,29,19,3],
[30,29,19,4],
[30,29,19,5],
[30,29,19,6],
[30,29,19,7],
[30,29,19,8],
[30,29,19,9],
[30,29,19,10],
[30,29,19,15],
[30,29,19,16],
[30,29,19,17],
[30,29,19,18],
[30,29,20,0],
[30,29,20,1],
[30,29,20,2],
[30,29,20,3],
[30,29,20,4],
[30,29,20,5],
[30,29,20,6],
[30,29,20,7],
[30,29,20,8],
[30,29,20,9],
[30,29,20,13],
[30,29,20,14],
[30,29,20,15],
[30,29,20,16],
[30,29,20,17],
[30,29,20,18],
[30,29,20,19],
[30,29,21,0],
[30,29,21,1],
[30,29,21,2],
[30,29,21,4],
[30,29,21,7],
[30,29,21,9],
[30,29,21,10],
[30,29,21,13],
[30,29,21,14],
[30,29,21,15],
[30,29,21,16],
[30,29,21,17],
[30,29,21,18],
[30,29,21,19],
[30,29,21,20],
[30,29,22,0],
[30,29,22,1],
[30,29,22,2],
[30,29,22,3],
[30,29,22,4],
[30,29,22,5],
[30,29,22,6],
[30,29,22,7],
[30,29,22,8],
[30,29,22,9],
[30,29,22,13],
[30,29,22,14],
[30,29,22,15],
[30,29,22,16],
[30,29,22,17],
[30,29,22,18],
[30,29,22,19],
[30,29,22,21],
[30,29,24,0],
[30,29,24,1],
[30,29,24,2],
[30,29,24,3],
[30,29,24,4],
[30,29,24,5],
[30,29,24,6],
[30,29,24,7],
[30,29,24,8],
[30,29,24,9],
[30,29,24,10],
[30,29,24,13],
[30,29,24,14],
[30,29,24,15],
[30,29,24,17],
[30,29,24,18],
[30,29,24,19],
[30,29,24,20],
[30,29,24,21],
[30,29,24,22],
[30,29,25,0],
[30,29,25,1],
[30,29,25,2],
[30,29,25,3],
[30,29,25,4],
[30,29,25,5],
[30,29,25,6],
[30,29,25,8],
[30,29,25,9],
[30,29,25,10],
[30,29,25,13],
[30,29,25,14],
[30,29,25,15],
[30,29,25,16],
[30,29,25,17],
[30,29,25,18],
[30,29,25,19],
[30,29,25,20],
[30,29,25,21],
[30,29,25,22],
[30,29,25,24],
[30,29,26,0],
[30,29,26,1],
[30,29,26,2],
[30,29,26,3],
[30,29,26,4],
[30,29,26,5],
[30,29,26,6],
[30,29,26,7],
[30,29,26,8],
[30,29,26,10],
[30,29,26,13],
[30,29,26,14],
[30,29,26,16],
[30,29,26,17],
[30,29,26,19],
[30,29,26,20],
[30,29,26,21],
[30,29,26,22],
[30,29,26,24],
[30,29,26,25],
[30,29,27,0],
[30,29,27,1],
[30,29,27,2],
[30,29,27,3],
[30,29,27,4],
[30,29,27,5],
[30,29,27,6],
[30,29,27,7],
[30,29,27,8],
[30,29,27,9],
[30,29,27,13],
[30,29,27,14],
[30,29,27,15],
[30,29,27,16],
[30,29,27,17],
[30,29,27,18],
[30,29,27,19],
[30,29,27,21],
[30,29,27,24],
[30,29,27,25],
[30,29,27,26],
[30,29,28,0],
[30,29,28,1],
[30,29,28,2],
[30,29,28,3],
[30,29,28,4],
[30,29,28,5],
[30,29,28,6],
[30,29,28,8],
[30,29,28,9],
[30,29,28,10],
[30,29,28,13],
[30,29,28,14],
[30,29,28,15],
[30,29,28,16],
[30,29,28,17],
[30,29,28,18],
[30,29,28,19],
[30,29,28,20],
[30,29,28,21],
[30,29,28,22],
[30,29,28,24],
[30,29,28,26],
[30,29,28,27],
[31,4,3,0],
[31,4,3,1],
[31,4,3,2],
[31,5,4,0],
[31,5,4,1],
[31,5,4,2],
[31,6,4,0],
[31,6,4,1],
[31,6,4,2],
[31,7,3,0],
[31,7,3,1],
[31,7,3,2],
[31,7,4,0],
[31,7,4,1],
[31,7,4,2],
[31,7,4,3],
[31,7,5,0],
[31,7,5,1],
[31,7,5,2],
[31,7,5,4],
[31,7,6,0],
[31,7,6,1],
[31,7,6,2],
[31,7,6,4],
[31,8,4,0],
[31,8,4,1],
[31,8,4,2],
[31,8,7,0],
[31,8,7,1],
[31,8,7,2],
[31,8,7,4],
[31,9,3,0],
[31,9,3,1],
[31,9,3,2],
[31,9,4,0],
[31,9,4,1],
[31,9,4,2],
[31,9,4,3],
[31,9,5,0],
[31,9,5,1],
[31,9,5,2],
[31,9,5,4],
[31,9,6,0],
[31,9,6,1],
[31,9,6,2],
[31,9,6,4],
[31,9,7,0],
[31,9,7,1],
[31,9,7,2],
[31,9,7,3],
[31,9,7,4],
[31,9,7,5],
[31,9,7,6],
[31,9,8,0],
[31,9,8,1],
[31,9,8,2],
[31,9,8,4],
[31,9,8,7],
[31,10,3,0],
[31,10,3,1],
[31,10,3,2],
[31,10,4,0],
[31,10,4,1],
[31,10,4,2],
[31,10,4,3],
[31,10,5,0],
[31,10,5,1],
[31,10,5,2],
[31,10,5,4],
[31,10,6,0],
[31,10,6,1],
[31,10,6,2],
[31,10,6,4],
[31,10,7,0],
[31,10,7,1],
[31,10,7,2],
[31,10,7,3],
[31,10,7,4],
[31,10,7,5],
[31,10,7,6],
[31,10,8,0],
[31,10,8,1],
[31,10,8,2],
[31,10,8,4],
[31,10,8,7],
[31,10,9,0],
[31,10,9,1],
[31,10,9,2],
[31,10,9,3],
[31,10,9,4],
[31,10,9,5],
[31,10,9,6],
[31,10,9,7],
[31,10,9,8],
[31,11,3,0],
[31,11,3,1],
[31,11,3,2],
[31,11,4,0],
[31,11,4,1],
[31,11,4,2],
[31,11,4,3],
[31,11,5,0],
[31,11,5,1],
[31,11,5,2],
[31,11,5,4],
[31,11,6,0],
[31,11,6,1],
[31,11,6,2],
[31,11,6,4],
[31,11,7,0],
[31,11,7,1],
[31,11,7,2],
[31,11,7,3],
[31,11,7,4],
[31,11,7,5],
[31,11,7,6],
[31,11,8,0],
[31,11,8,1],
[31,11,8,2],
[31,11,8,4],
[31,11,8,7],
[31,11,9,0],
[31,11,9,1],
[31,11,9,2],
[31,11,9,3],
[31,11,9,4],
[31,11,9,5],
[31,11,9,6],
[31,11,9,7],
[31,11,9,8],
[31,11,10,0],
[31,11,10,1],
[31,11,10,2],
[31,11,10,3],
[31,11,10,4],
[31,11,10,5],
[31,11,10,6],
[31,11,10,7],
[31,11,10,8],
[31,11,10,9],
[31,12,3,0],
[31,12,3,1],
[31,12,3,2],
[31,12,4,0],
[31,12,4,1],
[31,12,4,2],
[31,12,4,3],
[31,12,5,0],
[31,12,5,1],
[31,12,5,2],
[31,12,5,4],
[31,12,6,0],
[31,12,6,1],
[31,12,6,2],
[31,12,6,4],
[31,12,7,0],
[31,12,7,1],
[31,12,7,2],
[31,12,7,3],
[31,12,7,4],
[31,12,7,5],
[31,12,7,6],
[31,12,8,0],
[31,12,8,1],
[31,12,8,2],
[31,12,8,4],
[31,12,8,7],
[31,12,9,0],
[31,12,9,1],
[31,12,9,2],
[31,12,9,3],
[31,12,9,4],
[31,12,9,5],
[31,12,9,6],
[31,12,9,7],
[31,12,9,8],
[31,12,10,0],
[31,12,10,1],
[31,12,10,2],
[31,12,10,3],
[31,12,10,4],
[31,12,10,5],
[31,12,10,6],
[31,12,10,7],
[31,12,10,8],
[31,12,10,9],
[31,13,3,0],
[31,13,3,1],
[31,13,3,2],
[31,13,4,0],
[31,13,4,1],
[31,13,4,2],
[31,13,4,3],
[31,13,5,0],
[31,13,5,1],
[31,13,5,2],
[31,13,5,4],
[31,13,6,0],
[31,13,6,1],
[31,13,6,2],
[31,13,6,4],
[31,13,7,0],
[31,13,7,1],
[31,13,7,2],
[31,13,7,3],
[31,13,7,4],
[31,13,7,5],
[31,13,7,6],
[31,13,8,0],
[31,13,8,1],
[31,13,8,2],
[31,13,8,4],
[31,13,8,7],
[31,13,9,0],
[31,13,9,1],
[31,13,9,2],
[31,13,9,3],
[31,13,9,4],
[31,13,9,5],
[31,13,9,6],
[31,13,9,7],
[31,13,9,8],
[31,13,10,0],
[31,13,10,1],
[31,13,10,2],
[31,13,10,3],
[31,13,10,4],
[31,13,10,5],
[31,13,10,6],
[31,13,10,7],
[31,13,10,8],
[31,13,10,9],
[31,13,11,0],
[31,13,11,1],
[31,13,11,2],
[31,13,11,3],
[31,13,11,4],
[31,13,11,5],
[31,13,11,6],
[31,13,11,7],
[31,13,11,8],
[31,13,11,9],
[31,13,11,10],
[31,13,12,0],
[31,13,12,1],
[31,13,12,2],
[31,13,12,3],
[31,13,12,4],
[31,13,12,5],
[31,13,12,6],
[31,13,12,7],
[31,13,12,8],
[31,13,12,9],
[31,13,12,10],
[31,14,3,0],
[31,14,3,1],
[31,14,3,2],
[31,14,4,0],
[31,14,4,1],
[31,14,4,2],
[31,14,4,3],
[31,14,5,0],
[31,14,5,1],
[31,14,5,2],
[31,14,5,4],
[31,14,6,0],
[31,14,6,1],
[31,14,6,2],
[31,14,6,4],
[31,14,7,0],
[31,14,7,1],
[31,14,7,2],
[31,14,7,3],
[31,14,7,4],
[31,14,7,5],
[31,14,7,6],
[31,14,8,0],
[31,14,8,1],
[31,14,8,2],
[31,14,8,4],
[31,14,8,7],
[31,14,9,0],
[31,14,9,1],
[31,14,9,2],
[31,14,9,3],
[31,14,9,4],
[31,14,9,5],
[31,14,9,6],
[31,14,9,7],
[31,14,9,8],
[31,14,10,0],
[31,14,10,1],
[31,14,10,2],
[31,14,10,3],
[31,14,10,4],
[31,14,10,5],
[31,14,10,6],
[31,14,10,7],
[31,14,10,8],
[31,14,10,9],
[31,14,11,0],
[31,14,11,1],
[31,14,11,2],
[31,14,11,3],
[31,14,11,4],
[31,14,11,5],
[31,14,11,6],
[31,14,11,7],
[31,14,11,8],
[31,14,11,9],
[31,14,11,10],
[31,14,12,0],
[31,14,12,1],
[31,14,12,2],
[31,14,12,3],
[31,14,12,4],
[31,14,12,5],
[31,14,12,6],
[31,14,12,7],
[31,14,12,8],
[31,14,12,9],
[31,14,12,10],
[31,15,3,0],
[31,15,3,1],
[31,15,3,2],
[31,15,4,0],
[31,15,4,1],
[31,15,4,2],
[31,15,4,3],
[31,15,5,0],
[31,15,5,1],
[31,15,5,2],
[31,15,5,4],
[31,15,6,0],
[31,15,6,1],
[31,15,6,2],
[31,15,6,4],
[31,15,7,0],
[31,15,7,1],
[31,15,7,2],
[31,15,7,3],
[31,15,7,4],
[31,15,7,5],
[31,15,7,6],
[31,15,8,0],
[31,15,8,1],
[31,15,8,2],
[31,15,8,4],
[31,15,8,7],
[31,15,10,0],
[31,15,10,1],
[31,15,10,2],
[31,15,10,3],
[31,15,10,4],
[31,15,10,5],
[31,15,10,6],
[31,15,10,7],
[31,15,10,8],
[31,15,11,0],
[31,15,11,1],
[31,15,11,2],
[31,15,11,3],
[31,15,11,4],
[31,15,11,5],
[31,15,11,6],
[31,15,11,7],
[31,15,11,8],
[31,15,11,10],
[31,15,12,0],
[31,15,12,1],
[31,15,12,2],
[31,15,12,3],
[31,15,12,4],
[31,15,12,5],
[31,15,12,6],
[31,15,12,7],
[31,15,12,8],
[31,15,12,10],
[31,15,13,0],
[31,15,13,1],
[31,15,13,2],
[31,15,13,3],
[31,15,13,4],
[31,15,13,5],
[31,15,13,6],
[31,15,13,7],
[31,15,13,8],
[31,15,13,10],
[31,15,13,11],
[31,15,13,12],
[31,15,14,0],
[31,15,14,1],
[31,15,14,2],
[31,15,14,3],
[31,15,14,4],
[31,15,14,5],
[31,15,14,6],
[31,15,14,7],
[31,15,14,8],
[31,15,14,10],
[31,15,14,11],
[31,15,14,12],
[31,16,3,0],
[31,16,3,1],
[31,16,3,2],
[31,16,4,0],
[31,16,4,1],
[31,16,4,2],
[31,16,4,3],
[31,16,5,0],
[31,16,5,1],
[31,16,5,2],
[31,16,5,4],
[31,16,6,0],
[31,16,6,1],
[31,16,6,2],
[31,16,6,4],
[31,16,7,0],
[31,16,7,1],
[31,16,7,2],
[31,16,7,3],
[31,16,7,4],
[31,16,7,5],
[31,16,7,6],
[31,16,8,0],
[31,16,8,1],
[31,16,8,2],
[31,16,8,4],
[31,16,8,7],
[31,16,9,0],
[31,16,9,1],
[31,16,9,2],
[31,16,9,3],
[31,16,9,4],
[31,16,9,5],
[31,16,9,6],
[31,16,9,7],
[31,16,9,8],
[31,16,10,0],
[31,16,10,1],
[31,16,10,2],
[31,16,10,3],
[31,16,10,4],
[31,16,10,5],
[31,16,10,6],
[31,16,10,7],
[31,16,10,8],
[31,16,10,9],
[31,16,11,0],
[31,16,11,1],
[31,16,11,2],
[31,16,11,3],
[31,16,11,4],
[31,16,11,5],
[31,16,11,6],
[31,16,11,7],
[31,16,11,8],
[31,16,11,9],
[31,16,11,10],
[31,16,12,0],
[31,16,12,1],
[31,16,12,2],
[31,16,12,3],
[31,16,12,4],
[31,16,12,5],
[31,16,12,6],
[31,16,12,7],
[31,16,12,8],
[31,16,12,9],
[31,16,12,10],
[31,16,13,0],
[31,16,13,1],
[31,16,13,2],
[31,16,13,3],
[31,16,13,4],
[31,16,13,5],
[31,16,13,6],
[31,16,13,7],
[31,16,13,8],
[31,16,13,9],
[31,16,13,10],
[31,16,13,11],
[31,16,13,12],
[31,16,14,0],
[31,16,14,1],
[31,16,14,2],
[31,16,14,3],
[31,16,14,4],
[31,16,14,5],
[31,16,14,6],
[31,16,14,7],
[31,16,14,8],
[31,16,14,9],
[31,16,14,10],
[31,16,14,11],
[31,16,14,12],
[31,16,15,0],
[31,16,15,1],
[31,16,15,2],
[31,16,15,3],
[31,16,15,4],
[31,16,15,5],
[31,16,15,6],
[31,16,15,7],
[31,16,15,8],
[31,16,15,10],
[31,16,15,11],
[31,16,15,12],
[31,16,15,13],
[31,16,15,14],
[31,17,3,0],
[31,17,3,1],
[31,17,3,2],
[31,17,4,0],
[31,17,4,1],
[31,17,4,2],
[31,17,4,3],
[31,17,5,0],
[31,17,5,1],
[31,17,5,2],
[31,17,5,4],
[31,17,6,0],
[31,17,6,1],
[31,17,6,2],
[31,17,6,4],
[31,17,7,0],
[31,17,7,1],
[31,17,7,2],
[31,17,7,3],
[31,17,7,4],
[31,17,7,5],
[31,17,7,6],
[31,17,8,0],
[31,17,8,1],
[31,17,8,2],
[31,17,8,4],
[31,17,8,7],
[31,17,9,0],
[31,17,9,1],
[31,17,9,2],
[31,17,9,3],
[31,17,9,4],
[31,17,9,5],
[31,17,9,6],
[31,17,9,7],
[31,17,9,8],
[31,17,10,0],
[31,17,10,1],
[31,17,10,2],
[31,17,10,3],
[31,17,10,4],
[31,17,10,5],
[31,17,10,6],
[31,17,10,7],
[31,17,10,8],
[31,17,10,9],
[31,17,11,0],
[31,17,11,1],
[31,17,11,2],
[31,17,11,3],
[31,17,11,4],
[31,17,11,5],
[31,17,11,6],
[31,17,11,7],
[31,17,11,8],
[31,17,11,9],
[31,17,11,10],
[31,17,12,0],
[31,17,12,1],
[31,17,12,2],
[31,17,12,3],
[31,17,12,4],
[31,17,12,5],
[31,17,12,6],
[31,17,12,7],
[31,17,12,8],
[31,17,12,9],
[31,17,12,10],
[31,17,13,0],
[31,17,13,1],
[31,17,13,2],
[31,17,13,3],
[31,17,13,4],
[31,17,13,5],
[31,17,13,6],
[31,17,13,7],
[31,17,13,8],
[31,17,13,9],
[31,17,13,10],
[31,17,13,11],
[31,17,13,12],
[31,17,14,0],
[31,17,14,1],
[31,17,14,2],
[31,17,14,3],
[31,17,14,4],
[31,17,14,5],
[31,17,14,6],
[31,17,14,7],
[31,17,14,8],
[31,17,14,9],
[31,17,14,10],
[31,17,14,11],
[31,17,14,12],
[31,17,15,0],
[31,17,15,1],
[31,17,15,2],
[31,17,15,3],
[31,17,15,4],
[31,17,15,5],
[31,17,15,6],
[31,17,15,7],
[31,17,15,8],
[31,17,15,10],
[31,17,15,11],
[31,17,15,12],
[31,17,15,13],
[31,17,15,14],
[31,17,16,0],
[31,17,16,1],
[31,17,16,2],
[31,17,16,3],
[31,17,16,4],
[31,17,16,5],
[31,17,16,6],
[31,17,16,7],
[31,17,16,8],
[31,17,16,9],
[31,17,16,10],
[31,17,16,11],
[31,17,16,12],
[31,17,16,13],
[31,17,16,14],
[31,17,16,15],
[31,18,3,0],
[31,18,3,1],
[31,18,3,2],
[31,18,4,0],
[31,18,4,1],
[31,18,4,2],
[31,18,4,3],
[31,18,5,0],
[31,18,5,1],
[31,18,5,2],
[31,18,5,4],
[31,18,6,0],
[31,18,6,1],
[31,18,6,2],
[31,18,6,4],
[31,18,7,0],
[31,18,7,1],
[31,18,7,2],
[31,18,7,3],
[31,18,7,4],
[31,18,7,5],
[31,18,7,6],
[31,18,8,0],
[31,18,8,1],
[31,18,8,2],
[31,18,8,4],
[31,18,8,7],
[31,18,10,0],
[31,18,10,1],
[31,18,10,2],
[31,18,10,3],
[31,18,10,4],
[31,18,10,5],
[31,18,10,6],
[31,18,10,7],
[31,18,10,8],
[31,18,11,0],
[31,18,11,1],
[31,18,11,2],
[31,18,11,3],
[31,18,11,4],
[31,18,11,5],
[31,18,11,6],
[31,18,11,7],
[31,18,11,8],
[31,18,11,10],
[31,18,12,0],
[31,18,12,1],
[31,18,12,2],
[31,18,12,3],
[31,18,12,4],
[31,18,12,5],
[31,18,12,6],
[31,18,12,7],
[31,18,12,8],
[31,18,12,10],
[31,18,13,0],
[31,18,13,1],
[31,18,13,2],
[31,18,13,3],
[31,18,13,4],
[31,18,13,5],
[31,18,13,6],
[31,18,13,7],
[31,18,13,8],
[31,18,13,10],
[31,18,13,11],
[31,18,13,12],
[31,18,14,0],
[31,18,14,1],
[31,18,14,2],
[31,18,14,3],
[31,18,14,4],
[31,18,14,5],
[31,18,14,6],
[31,18,14,7],
[31,18,14,8],
[31,18,14,10],
[31,18,14,11],
[31,18,14,12],
[31,18,16,0],
[31,18,16,1],
[31,18,16,2],
[31,18,16,3],
[31,18,16,4],
[31,18,16,5],
[31,18,16,6],
[31,18,16,7],
[31,18,16,8],
[31,18,16,10],
[31,18,16,11],
[31,18,16,12],
[31,18,16,13],
[31,18,16,14],
[31,18,17,0],
[31,18,17,1],
[31,18,17,2],
[31,18,17,3],
[31,18,17,4],
[31,18,17,5],
[31,18,17,6],
[31,18,17,7],
[31,18,17,8],
[31,18,17,10],
[31,18,17,11],
[31,18,17,12],
[31,18,17,13],
[31,18,17,14],
[31,18,17,16],
[31,19,3,0],
[31,19,3,1],
[31,19,3,2],
[31,19,4,0],
[31,19,4,1],
[31,19,4,2],
[31,19,4,3],
[31,19,5,0],
[31,19,5,1],
[31,19,5,2],
[31,19,5,4],
[31,19,6,0],
[31,19,6,1],
[31,19,6,2],
[31,19,6,4],
[31,19,7,0],
[31,19,7,1],
[31,19,7,2],
[31,19,7,3],
[31,19,7,4],
[31,19,7,5],
[31,19,7,6],
[31,19,8,0],
[31,19,8,1],
[31,19,8,2],
[31,19,8,4],
[31,19,8,7],
[31,19,9,0],
[31,19,9,1],
[31,19,9,2],
[31,19,9,3],
[31,19,9,4],
[31,19,9,5],
[31,19,9,6],
[31,19,9,7],
[31,19,9,8],
[31,19,10,0],
[31,19,10,1],
[31,19,10,2],
[31,19,10,3],
[31,19,10,4],
[31,19,10,5],
[31,19,10,6],
[31,19,10,7],
[31,19,10,8],
[31,19,10,9],
[31,19,11,0],
[31,19,11,1],
[31,19,11,2],
[31,19,11,3],
[31,19,11,4],
[31,19,11,5],
[31,19,11,6],
[31,19,11,7],
[31,19,11,8],
[31,19,11,9],
[31,19,11,10],
[31,19,12,0],
[31,19,12,1],
[31,19,12,2],
[31,19,12,3],
[31,19,12,4],
[31,19,12,5],
[31,19,12,6],
[31,19,12,7],
[31,19,12,8],
[31,19,12,9],
[31,19,12,10],
[31,19,15,0],
[31,19,15,1],
[31,19,15,2],
[31,19,15,3],
[31,19,15,4],
[31,19,15,5],
[31,19,15,6],
[31,19,15,7],
[31,19,15,8],
[31,19,15,10],
[31,19,15,11],
[31,19,15,12],
[31,19,16,0],
[31,19,16,1],
[31,19,16,2],
[31,19,16,3],
[31,19,16,4],
[31,19,16,5],
[31,19,16,6],
[31,19,16,7],
[31,19,16,8],
[31,19,16,9],
[31,19,16,10],
[31,19,16,11],
[31,19,16,12],
[31,19,16,15],
[31,19,17,0],
[31,19,17,1],
[31,19,17,2],
[31,19,17,3],
[31,19,17,4],
[31,19,17,5],
[31,19,17,6],
[31,19,17,7],
[31,19,17,8],
[31,19,17,9],
[31,19,17,10],
[31,19,17,11],
[31,19,17,12],
[31,19,17,15],
[31,19,17,16],
[31,19,18,0],
[31,19,18,1],
[31,19,18,2],
[31,19,18,3],
[31,19,18,4],
[31,19,18,5],
[31,19,18,6],
[31,19,18,7],
[31,19,18,8],
[31,19,18,10],
[31,19,18,11],
[31,19,18,12],
[31,19,18,16],
[31,19,18,17],
[31,20,3,0],
[31,20,3,1],
[31,20,3,2],
[31,20,4,0],
[31,20,4,1],
[31,20,4,2],
[31,20,4,3],
[31,20,5,0],
[31,20,5,1],
[31,20,5,2],
[31,20,5,4],
[31,20,6,0],
[31,20,6,1],
[31,20,6,2],
[31,20,6,4],
[31,20,7,0],
[31,20,7,1],
[31,20,7,2],
[31,20,7,3],
[31,20,7,4],
[31,20,7,5],
[31,20,7,6],
[31,20,8,0],
[31,20,8,1],
[31,20,8,2],
[31,20,8,4],
[31,20,8,7],
[31,20,9,0],
[31,20,9,1],
[31,20,9,2],
[31,20,9,3],
[31,20,9,4],
[31,20,9,5],
[31,20,9,6],
[31,20,9,7],
[31,20,9,8],
[31,20,11,0],
[31,20,11,1],
[31,20,11,2],
[31,20,11,3],
[31,20,11,4],
[31,20,11,5],
[31,20,11,6],
[31,20,11,7],
[31,20,11,8],
[31,20,11,9],
[31,20,12,0],
[31,20,12,1],
[31,20,12,2],
[31,20,12,3],
[31,20,12,4],
[31,20,12,5],
[31,20,12,6],
[31,20,12,7],
[31,20,12,8],
[31,20,12,9],
[31,20,13,0],
[31,20,13,1],
[31,20,13,2],
[31,20,13,3],
[31,20,13,4],
[31,20,13,5],
[31,20,13,6],
[31,20,13,7],
[31,20,13,8],
[31,20,13,9],
[31,20,13,11],
[31,20,13,12],
[31,20,14,0],
[31,20,14,1],
[31,20,14,2],
[31,20,14,3],
[31,20,14,4],
[31,20,14,5],
[31,20,14,6],
[31,20,14,7],
[31,20,14,8],
[31,20,14,9],
[31,20,14,11],
[31,20,14,12],
[31,20,15,0],
[31,20,15,1],
[31,20,15,2],
[31,20,15,3],
[31,20,15,4],
[31,20,15,5],
[31,20,15,6],
[31,20,15,7],
[31,20,15,8],
[31,20,15,11],
[31,20,15,12],
[31,20,15,13],
[31,20,15,14],
[31,20,16,0],
[31,20,16,1],
[31,20,16,2],
[31,20,16,3],
[31,20,16,4],
[31,20,16,5],
[31,20,16,6],
[31,20,16,7],
[31,20,16,8],
[31,20,16,9],
[31,20,16,11],
[31,20,16,12],
[31,20,16,13],
[31,20,16,14],
[31,20,16,15],
[31,20,17,0],
[31,20,17,1],
[31,20,17,2],
[31,20,17,3],
[31,20,17,4],
[31,20,17,5],
[31,20,17,6],
[31,20,17,7],
[31,20,17,8],
[31,20,17,9],
[31,20,17,11],
[31,20,17,12],
[31,20,17,13],
[31,20,17,14],
[31,20,17,15],
[31,20,17,16],
[31,20,18,0],
[31,20,18,1],
[31,20,18,2],
[31,20,18,3],
[31,20,18,4],
[31,20,18,5],
[31,20,18,6],
[31,20,18,7],
[31,20,18,8],
[31,20,18,11],
[31,20,18,12],
[31,20,18,13],
[31,20,18,14],
[31,20,18,16],
[31,20,18,17],
[31,20,19,0],
[31,20,19,1],
[31,20,19,2],
[31,20,19,3],
[31,20,19,4],
[31,20,19,5],
[31,20,19,6],
[31,20,19,7],
[31,20,19,8],
[31,20,19,9],
[31,20,19,11],
[31,20,19,12],
[31,20,19,15],
[31,20,19,16],
[31,20,19,17],
[31,20,19,18],
[31,21,4,0],
[31,21,4,1],
[31,21,4,2],
[31,21,7,0],
[31,21,7,1],
[31,21,7,2],
[31,21,7,4],
[31,21,9,0],
[31,21,9,1],
[31,21,9,2],
[31,21,9,4],
[31,21,9,7],
[31,21,10,0],
[31,21,10,1],
[31,21,10,2],
[31,21,10,4],
[31,21,10,7],
[31,21,10,9],
[31,21,11,0],
[31,21,11,1],
[31,21,11,2],
[31,21,11,4],
[31,21,11,7],
[31,21,11,9],
[31,21,11,10],
[31,21,12,0],
[31,21,12,1],
[31,21,12,2],
[31,21,12,4],
[31,21,12,7],
[31,21,12,9],
[31,21,12,10],
[31,21,13,0],
[31,21,13,1],
[31,21,13,2],
[31,21,13,4],
[31,21,13,7],
[31,21,13,9],
[31,21,13,10],
[31,21,13,11],
[31,21,13,12],
[31,21,14,0],
[31,21,14,1],
[31,21,14,2],
[31,21,14,4],
[31,21,14,7],
[31,21,14,9],
[31,21,14,10],
[31,21,14,11],
[31,21,14,12],
[31,21,15,0],
[31,21,15,1],
[31,21,15,2],
[31,21,15,4],
[31,21,15,7],
[31,21,15,10],
[31,21,15,11],
[31,21,15,12],
[31,21,15,13],
[31,21,15,14],
[31,21,16,0],
[31,21,16,1],
[31,21,16,2],
[31,21,16,4],
[31,21,16,7],
[31,21,16,9],
[31,21,16,10],
[31,21,16,11],
[31,21,16,12],
[31,21,16,13],
[31,21,16,14],
[31,21,16,15],
[31,21,17,0],
[31,21,17,1],
[31,21,17,2],
[31,21,17,4],
[31,21,17,7],
[31,21,17,9],
[31,21,17,10],
[31,21,17,11],
[31,21,17,12],
[31,21,17,13],
[31,21,17,14],
[31,21,17,15],
[31,21,17,16],
[31,21,18,0],
[31,21,18,1],
[31,21,18,2],
[31,21,18,4],
[31,21,18,7],
[31,21,18,10],
[31,21,18,11],
[31,21,18,12],
[31,21,18,13],
[31,21,18,14],
[31,21,18,16],
[31,21,18,17],
[31,21,19,0],
[31,21,19,1],
[31,21,19,2],
[31,21,19,4],
[31,21,19,7],
[31,21,19,9],
[31,21,19,10],
[31,21,19,11],
[31,21,19,12],
[31,21,19,15],
[31,21,19,16],
[31,21,19,17],
[31,21,19,18],
[31,21,20,0],
[31,21,20,1],
[31,21,20,2],
[31,21,20,4],
[31,21,20,7],
[31,21,20,9],
[31,21,20,11],
[31,21,20,12],
[31,21,20,13],
[31,21,20,14],
[31,21,20,15],
[31,21,20,16],
[31,21,20,17],
[31,21,20,18],
[31,21,20,19],
[31,22,3,0],
[31,22,3,1],
[31,22,3,2],
[31,22,4,0],
[31,22,4,1],
[31,22,4,2],
[31,22,4,3],
[31,22,5,0],
[31,22,5,1],
[31,22,5,2],
[31,22,5,4],
[31,22,6,0],
[31,22,6,1],
[31,22,6,2],
[31,22,6,4],
[31,22,7,0],
[31,22,7,1],
[31,22,7,2],
[31,22,7,3],
[31,22,7,4],
[31,22,7,5],
[31,22,7,6],
[31,22,8,0],
[31,22,8,1],
[31,22,8,2],
[31,22,8,4],
[31,22,8,7],
[31,22,9,0],
[31,22,9,1],
[31,22,9,2],
[31,22,9,3],
[31,22,9,4],
[31,22,9,5],
[31,22,9,6],
[31,22,9,7],
[31,22,9,8],
[31,22,11,0],
[31,22,11,1],
[31,22,11,2],
[31,22,11,3],
[31,22,11,4],
[31,22,11,5],
[31,22,11,6],
[31,22,11,7],
[31,22,11,8],
[31,22,11,9],
[31,22,12,0],
[31,22,12,1],
[31,22,12,2],
[31,22,12,3],
[31,22,12,4],
[31,22,12,5],
[31,22,12,6],
[31,22,12,7],
[31,22,12,8],
[31,22,12,9],
[31,22,13,0],
[31,22,13,1],
[31,22,13,2],
[31,22,13,3],
[31,22,13,4],
[31,22,13,5],
[31,22,13,6],
[31,22,13,7],
[31,22,13,8],
[31,22,13,9],
[31,22,13,11],
[31,22,13,12],
[31,22,14,0],
[31,22,14,1],
[31,22,14,2],
[31,22,14,3],
[31,22,14,4],
[31,22,14,5],
[31,22,14,6],
[31,22,14,7],
[31,22,14,8],
[31,22,14,9],
[31,22,14,11],
[31,22,14,12],
[31,22,15,0],
[31,22,15,1],
[31,22,15,2],
[31,22,15,3],
[31,22,15,4],
[31,22,15,5],
[31,22,15,6],
[31,22,15,7],
[31,22,15,8],
[31,22,15,11],
[31,22,15,12],
[31,22,15,13],
[31,22,15,14],
[31,22,16,0],
[31,22,16,1],
[31,22,16,2],
[31,22,16,3],
[31,22,16,4],
[31,22,16,5],
[31,22,16,6],
[31,22,16,7],
[31,22,16,8],
[31,22,16,9],
[31,22,16,11],
[31,22,16,12],
[31,22,16,13],
[31,22,16,14],
[31,22,16,15],
[31,22,17,0],
[31,22,17,1],
[31,22,17,2],
[31,22,17,3],
[31,22,17,4],
[31,22,17,5],
[31,22,17,6],
[31,22,17,7],
[31,22,17,8],
[31,22,17,9],
[31,22,17,11],
[31,22,17,12],
[31,22,17,13],
[31,22,17,14],
[31,22,17,15],
[31,22,17,16],
[31,22,18,0],
[31,22,18,1],
[31,22,18,2],
[31,22,18,3],
[31,22,18,4],
[31,22,18,5],
[31,22,18,6],
[31,22,18,7],
[31,22,18,8],
[31,22,18,11],
[31,22,18,12],
[31,22,18,13],
[31,22,18,14],
[31,22,18,16],
[31,22,18,17],
[31,22,19,0],
[31,22,19,1],
[31,22,19,2],
[31,22,19,3],
[31,22,19,4],
[31,22,19,5],
[31,22,19,6],
[31,22,19,7],
[31,22,19,8],
[31,22,19,9],
[31,22,19,11],
[31,22,19,12],
[31,22,19,15],
[31,22,19,16],
[31,22,19,17],
[31,22,19,18],
[31,22,21,0],
[31,22,21,1],
[31,22,21,2],
[31,22,21,4],
[31,22,21,7],
[31,22,21,9],
[31,22,21,11],
[31,22,21,12],
[31,22,21,13],
[31,22,21,14],
[31,22,21,15],
[31,22,21,16],
[31,22,21,17],
[31,22,21,18],
[31,22,21,19],
[31,24,3,0],
[31,24,3,1],
[31,24,3,2],
[31,24,4,0],
[31,24,4,1],
[31,24,4,2],
[31,24,4,3],
[31,24,5,0],
[31,24,5,1],
[31,24,5,2],
[31,24,5,4],
[31,24,6,0],
[31,24,6,1],
[31,24,6,2],
[31,24,6,4],
[31,24,7,0],
[31,24,7,1],
[31,24,7,2],
[31,24,7,3],
[31,24,7,4],
[31,24,7,5],
[31,24,7,6],
[31,24,8,0],
[31,24,8,1],
[31,24,8,2],
[31,24,8,4],
[31,24,8,7],
[31,24,9,0],
[31,24,9,1],
[31,24,9,2],
[31,24,9,3],
[31,24,9,4],
[31,24,9,5],
[31,24,9,6],
[31,24,9,7],
[31,24,9,8],
[31,24,10,0],
[31,24,10,1],
[31,24,10,2],
[31,24,10,3],
[31,24,10,4],
[31,24,10,5],
[31,24,10,6],
[31,24,10,7],
[31,24,10,8],
[31,24,10,9],
[31,24,11,0],
[31,24,11,1],
[31,24,11,2],
[31,24,11,3],
[31,24,11,4],
[31,24,11,5],
[31,24,11,6],
[31,24,11,7],
[31,24,11,8],
[31,24,11,9],
[31,24,11,10],
[31,24,12,0],
[31,24,12,1],
[31,24,12,2],
[31,24,12,3],
[31,24,12,4],
[31,24,12,5],
[31,24,12,6],
[31,24,12,7],
[31,24,12,8],
[31,24,12,9],
[31,24,12,10],
[31,24,13,0],
[31,24,13,1],
[31,24,13,2],
[31,24,13,3],
[31,24,13,4],
[31,24,13,5],
[31,24,13,6],
[31,24,13,7],
[31,24,13,8],
[31,24,13,9],
[31,24,13,10],
[31,24,13,11],
[31,24,13,12],
[31,24,14,0],
[31,24,14,1],
[31,24,14,2],
[31,24,14,3],
[31,24,14,4],
[31,24,14,5],
[31,24,14,6],
[31,24,14,7],
[31,24,14,8],
[31,24,14,9],
[31,24,14,10],
[31,24,14,11],
[31,24,14,12],
[31,24,15,0],
[31,24,15,1],
[31,24,15,2],
[31,24,15,3],
[31,24,15,4],
[31,24,15,5],
[31,24,15,6],
[31,24,15,7],
[31,24,15,8],
[31,24,15,10],
[31,24,15,11],
[31,24,15,12],
[31,24,15,13],
[31,24,15,14],
[31,24,17,0],
[31,24,17,1],
[31,24,17,2],
[31,24,17,3],
[31,24,17,4],
[31,24,17,5],
[31,24,17,6],
[31,24,17,7],
[31,24,17,8],
[31,24,17,9],
[31,24,17,10],
[31,24,17,11],
[31,24,17,12],
[31,24,17,13],
[31,24,17,14],
[31,24,17,15],
[31,24,18,0],
[31,24,18,1],
[31,24,18,2],
[31,24,18,3],
[31,24,18,4],
[31,24,18,5],
[31,24,18,6],
[31,24,18,7],
[31,24,18,8],
[31,24,18,10],
[31,24,18,11],
[31,24,18,12],
[31,24,18,13],
[31,24,18,14],
[31,24,18,17],
[31,24,19,0],
[31,24,19,1],
[31,24,19,2],
[31,24,19,3],
[31,24,19,4],
[31,24,19,5],
[31,24,19,6],
[31,24,19,7],
[31,24,19,8],
[31,24,19,9],
[31,24,19,10],
[31,24,19,11],
[31,24,19,12],
[31,24,19,15],
[31,24,19,17],
[31,24,19,18],
[31,24,20,0],
[31,24,20,1],
[31,24,20,2],
[31,24,20,3],
[31,24,20,4],
[31,24,20,5],
[31,24,20,6],
[31,24,20,7],
[31,24,20,8],
[31,24,20,9],
[31,24,20,11],
[31,24,20,12],
[31,24,20,13],
[31,24,20,14],
[31,24,20,15],
[31,24,20,17],
[31,24,20,18],
[31,24,20,19],
[31,24,21,0],
[31,24,21,1],
[31,24,21,2],
[31,24,21,4],
[31,24,21,7],
[31,24,21,9],
[31,24,21,10],
[31,24,21,11],
[31,24,21,12],
[31,24,21,13],
[31,24,21,14],
[31,24,21,15],
[31,24,21,17],
[31,24,21,18],
[31,24,21,19],
[31,24,21,20],
[31,24,22,0],
[31,24,22,1],
[31,24,22,2],
[31,24,22,3],
[31,24,22,4],
[31,24,22,5],
[31,24,22,6],
[31,24,22,7],
[31,24,22,8],
[31,24,22,9],
[31,24,22,11],
[31,24,22,12],
[31,24,22,13],
[31,24,22,14],
[31,24,22,15],
[31,24,22,17],
[31,24,22,18],
[31,24,22,19],
[31,24,22,21],
[31,25,3,0],
[31,25,3,1],
[31,25,3,2],
[31,25,4,0],
[31,25,4,1],
[31,25,4,2],
[31,25,4,3],
[31,25,5,0],
[31,25,5,1],
[31,25,5,2],
[31,25,5,4],
[31,25,6,0],
[31,25,6,1],
[31,25,6,2],
[31,25,6,4],
[31,25,8,0],
[31,25,8,1],
[31,25,8,2],
[31,25,8,4],
[31,25,9,0],
[31,25,9,1],
[31,25,9,2],
[31,25,9,3],
[31,25,9,4],
[31,25,9,5],
[31,25,9,6],
[31,25,9,8],
[31,25,10,0],
[31,25,10,1],
[31,25,10,2],
[31,25,10,3],
[31,25,10,4],
[31,25,10,5],
[31,25,10,6],
[31,25,10,8],
[31,25,10,9],
[31,25,11,0],
[31,25,11,1],
[31,25,11,2],
[31,25,11,3],
[31,25,11,4],
[31,25,11,5],
[31,25,11,6],
[31,25,11,8],
[31,25,11,9],
[31,25,11,10],
[31,25,12,0],
[31,25,12,1],
[31,25,12,2],
[31,25,12,3],
[31,25,12,4],
[31,25,12,5],
[31,25,12,6],
[31,25,12,8],
[31,25,12,9],
[31,25,12,10],
[31,25,13,0],
[31,25,13,1],
[31,25,13,2],
[31,25,13,3],
[31,25,13,4],
[31,25,13,5],
[31,25,13,6],
[31,25,13,8],
[31,25,13,9],
[31,25,13,10],
[31,25,13,11],
[31,25,13,12],
[31,25,14,0],
[31,25,14,1],
[31,25,14,2],
[31,25,14,3],
[31,25,14,4],
[31,25,14,5],
[31,25,14,6],
[31,25,14,8],
[31,25,14,9],
[31,25,14,10],
[31,25,14,11],
[31,25,14,12],
[31,25,15,0],
[31,25,15,1],
[31,25,15,2],
[31,25,15,3],
[31,25,15,4],
[31,25,15,5],
[31,25,15,6],
[31,25,15,8],
[31,25,15,10],
[31,25,15,11],
[31,25,15,12],
[31,25,15,13],
[31,25,15,14],
[31,25,16,0],
[31,25,16,1],
[31,25,16,2],
[31,25,16,3],
[31,25,16,4],
[31,25,16,5],
[31,25,16,6],
[31,25,16,8],
[31,25,16,9],
[31,25,16,10],
[31,25,16,11],
[31,25,16,12],
[31,25,16,13],
[31,25,16,14],
[31,25,16,15],
[31,25,17,0],
[31,25,17,1],
[31,25,17,2],
[31,25,17,3],
[31,25,17,4],
[31,25,17,5],
[31,25,17,6],
[31,25,17,8],
[31,25,17,9],
[31,25,17,10],
[31,25,17,11],
[31,25,17,12],
[31,25,17,13],
[31,25,17,14],
[31,25,17,15],
[31,25,17,16],
[31,25,18,0],
[31,25,18,1],
[31,25,18,2],
[31,25,18,3],
[31,25,18,4],
[31,25,18,5],
[31,25,18,6],
[31,25,18,8],
[31,25,18,10],
[31,25,18,11],
[31,25,18,12],
[31,25,18,13],
[31,25,18,14],
[31,25,18,16],
[31,25,18,17],
[31,25,19,0],
[31,25,19,1],
[31,25,19,2],
[31,25,19,3],
[31,25,19,4],
[31,25,19,5],
[31,25,19,6],
[31,25,19,8],
[31,25,19,9],
[31,25,19,10],
[31,25,19,11],
[31,25,19,12],
[31,25,19,15],
[31,25,19,16],
[31,25,19,17],
[31,25,19,18],
[31,25,20,0],
[31,25,20,1],
[31,25,20,2],
[31,25,20,3],
[31,25,20,4],
[31,25,20,5],
[31,25,20,6],
[31,25,20,8],
[31,25,20,9],
[31,25,20,11],
[31,25,20,12],
[31,25,20,13],
[31,25,20,14],
[31,25,20,15],
[31,25,20,16],
[31,25,20,17],
[31,25,20,18],
[31,25,20,19],
[31,25,21,0],
[31,25,21,1],
[31,25,21,2],
[31,25,21,4],
[31,25,21,9],
[31,25,21,10],
[31,25,21,11],
[31,25,21,12],
[31,25,21,13],
[31,25,21,14],
[31,25,21,15],
[31,25,21,16],
[31,25,21,17],
[31,25,21,18],
[31,25,21,19],
[31,25,21,20],
[31,25,22,0],
[31,25,22,1],
[31,25,22,2],
[31,25,22,3],
[31,25,22,4],
[31,25,22,5],
[31,25,22,6],
[31,25,22,8],
[31,25,22,9],
[31,25,22,11],
[31,25,22,12],
[31,25,22,13],
[31,25,22,14],
[31,25,22,15],
[31,25,22,16],
[31,25,22,17],
[31,25,22,18],
[31,25,22,19],
[31,25,22,21],
[31,25,24,0],
[31,25,24,1],
[31,25,24,2],
[31,25,24,3],
[31,25,24,4],
[31,25,24,5],
[31,25,24,6],
[31,25,24,8],
[31,25,24,9],
[31,25,24,10],
[31,25,24,11],
[31,25,24,12],
[31,25,24,13],
[31,25,24,14],
[31,25,24,15],
[31,25,24,17],
[31,25,24,18],
[31,25,24,19],
[31,25,24,20],
[31,25,24,21],
[31,25,24,22],
[31,26,3,0],
[31,26,3,1],
[31,26,3,2],
[31,26,4,0],
[31,26,4,1],
[31,26,4,2],
[31,26,4,3],
[31,26,5,0],
[31,26,5,1],
[31,26,5,2],
[31,26,5,4],
[31,26,6,0],
[31,26,6,1],
[31,26,6,2],
[31,26,6,4],
[31,26,7,0],
[31,26,7,1],
[31,26,7,2],
[31,26,7,3],
[31,26,7,4],
[31,26,7,5],
[31,26,7,6],
[31,26,8,0],
[31,26,8,1],
[31,26,8,2],
[31,26,8,4],
[31,26,8,7],
[31,26,10,0],
[31,26,10,1],
[31,26,10,2],
[31,26,10,3],
[31,26,10,4],
[31,26,10,5],
[31,26,10,6],
[31,26,10,7],
[31,26,10,8],
[31,26,11,0],
[31,26,11,1],
[31,26,11,2],
[31,26,11,3],
[31,26,11,4],
[31,26,11,5],
[31,26,11,6],
[31,26,11,7],
[31,26,11,8],
[31,26,11,10],
[31,26,12,0],
[31,26,12,1],
[31,26,12,2],
[31,26,12,3],
[31,26,12,4],
[31,26,12,5],
[31,26,12,6],
[31,26,12,7],
[31,26,12,8],
[31,26,12,10],
[31,26,13,0],
[31,26,13,1],
[31,26,13,2],
[31,26,13,3],
[31,26,13,4],
[31,26,13,5],
[31,26,13,6],
[31,26,13,7],
[31,26,13,8],
[31,26,13,10],
[31,26,13,11],
[31,26,13,12],
[31,26,14,0],
[31,26,14,1],
[31,26,14,2],
[31,26,14,3],
[31,26,14,4],
[31,26,14,5],
[31,26,14,6],
[31,26,14,7],
[31,26,14,8],
[31,26,14,10],
[31,26,14,11],
[31,26,14,12],
[31,26,16,0],
[31,26,16,1],
[31,26,16,2],
[31,26,16,3],
[31,26,16,4],
[31,26,16,5],
[31,26,16,6],
[31,26,16,7],
[31,26,16,8],
[31,26,16,10],
[31,26,16,11],
[31,26,16,12],
[31,26,16,13],
[31,26,16,14],
[31,26,17,0],
[31,26,17,1],
[31,26,17,2],
[31,26,17,3],
[31,26,17,4],
[31,26,17,5],
[31,26,17,6],
[31,26,17,7],
[31,26,17,8],
[31,26,17,10],
[31,26,17,11],
[31,26,17,12],
[31,26,17,13],
[31,26,17,14],
[31,26,17,16],
[31,26,19,0],
[31,26,19,1],
[31,26,19,2],
[31,26,19,3],
[31,26,19,4],
[31,26,19,5],
[31,26,19,6],
[31,26,19,7],
[31,26,19,8],
[31,26,19,10],
[31,26,19,11],
[31,26,19,12],
[31,26,19,16],
[31,26,19,17],
[31,26,20,0],
[31,26,20,1],
[31,26,20,2],
[31,26,20,3],
[31,26,20,4],
[31,26,20,5],
[31,26,20,6],
[31,26,20,7],
[31,26,20,8],
[31,26,20,11],
[31,26,20,12],
[31,26,20,13],
[31,26,20,14],
[31,26,20,16],
[31,26,20,17],
[31,26,20,19],
[31,26,21,0],
[31,26,21,1],
[31,26,21,2],
[31,26,21,4],
[31,26,21,7],
[31,26,21,10],
[31,26,21,11],
[31,26,21,12],
[31,26,21,13],
[31,26,21,14],
[31,26,21,16],
[31,26,21,17],
[31,26,21,19],
[31,26,21,20],
[31,26,22,0],
[31,26,22,1],
[31,26,22,2],
[31,26,22,3],
[31,26,22,4],
[31,26,22,5],
[31,26,22,6],
[31,26,22,7],
[31,26,22,8],
[31,26,22,11],
[31,26,22,12],
[31,26,22,13],
[31,26,22,14],
[31,26,22,16],
[31,26,22,17],
[31,26,22,19],
[31,26,22,21],
[31,26,24,0],
[31,26,24,1],
[31,26,24,2],
[31,26,24,3],
[31,26,24,4],
[31,26,24,5],
[31,26,24,6],
[31,26,24,7],
[31,26,24,8],
[31,26,24,10],
[31,26,24,11],
[31,26,24,12],
[31,26,24,13],
[31,26,24,14],
[31,26,24,17],
[31,26,24,19],
[31,26,24,20],
[31,26,24,21],
[31,26,24,22],
[31,26,25,0],
[31,26,25,1],
[31,26,25,2],
[31,26,25,3],
[31,26,25,4],
[31,26,25,5],
[31,26,25,6],
[31,26,25,8],
[31,26,25,10],
[31,26,25,11],
[31,26,25,12],
[31,26,25,13],
[31,26,25,14],
[31,26,25,16],
[31,26,25,17],
[31,26,25,19],
[31,26,25,20],
[31,26,25,21],
[31,26,25,22],
[31,26,25,24],
[31,27,3,0],
[31,27,3,1],
[31,27,3,2],
[31,27,4,0],
[31,27,4,1],
[31,27,4,2],
[31,27,4,3],
[31,27,5,0],
[31,27,5,1],
[31,27,5,2],
[31,27,5,4],
[31,27,6,0],
[31,27,6,1],
[31,27,6,2],
[31,27,6,4],
[31,27,7,0],
[31,27,7,1],
[31,27,7,2],
[31,27,7,3],
[31,27,7,4],
[31,27,7,5],
[31,27,7,6],
[31,27,8,0],
[31,27,8,1],
[31,27,8,2],
[31,27,8,4],
[31,27,8,7],
[31,27,9,0],
[31,27,9,1],
[31,27,9,2],
[31,27,9,3],
[31,27,9,4],
[31,27,9,5],
[31,27,9,6],
[31,27,9,7],
[31,27,9,8],
[31,27,11,0],
[31,27,11,1],
[31,27,11,2],
[31,27,11,3],
[31,27,11,4],
[31,27,11,5],
[31,27,11,6],
[31,27,11,7],
[31,27,11,8],
[31,27,11,9],
[31,27,12,0],
[31,27,12,1],
[31,27,12,2],
[31,27,12,3],
[31,27,12,4],
[31,27,12,5],
[31,27,12,6],
[31,27,12,7],
[31,27,12,8],
[31,27,12,9],
[31,27,13,0],
[31,27,13,1],
[31,27,13,2],
[31,27,13,3],
[31,27,13,4],
[31,27,13,5],
[31,27,13,6],
[31,27,13,7],
[31,27,13,8],
[31,27,13,9],
[31,27,13,11],
[31,27,13,12],
[31,27,14,0],
[31,27,14,1],
[31,27,14,2],
[31,27,14,3],
[31,27,14,4],
[31,27,14,5],
[31,27,14,6],
[31,27,14,7],
[31,27,14,8],
[31,27,14,9],
[31,27,14,11],
[31,27,14,12],
[31,27,15,0],
[31,27,15,1],
[31,27,15,2],
[31,27,15,3],
[31,27,15,4],
[31,27,15,5],
[31,27,15,6],
[31,27,15,7],
[31,27,15,8],
[31,27,15,11],
[31,27,15,12],
[31,27,15,13],
[31,27,15,14],
[31,27,16,0],
[31,27,16,1],
[31,27,16,2],
[31,27,16,3],
[31,27,16,4],
[31,27,16,5],
[31,27,16,6],
[31,27,16,7],
[31,27,16,8],
[31,27,16,9],
[31,27,16,11],
[31,27,16,12],
[31,27,16,13],
[31,27,16,14],
[31,27,16,15],
[31,27,17,0],
[31,27,17,1],
[31,27,17,2],
[31,27,17,3],
[31,27,17,4],
[31,27,17,5],
[31,27,17,6],
[31,27,17,7],
[31,27,17,8],
[31,27,17,9],
[31,27,17,11],
[31,27,17,12],
[31,27,17,13],
[31,27,17,14],
[31,27,17,15],
[31,27,17,16],
[31,27,18,0],
[31,27,18,1],
[31,27,18,2],
[31,27,18,3],
[31,27,18,4],
[31,27,18,5],
[31,27,18,6],
[31,27,18,7],
[31,27,18,8],
[31,27,18,11],
[31,27,18,12],
[31,27,18,13],
[31,27,18,14],
[31,27,18,16],
[31,27,18,17],
[31,27,19,0],
[31,27,19,1],
[31,27,19,2],
[31,27,19,3],
[31,27,19,4],
[31,27,19,5],
[31,27,19,6],
[31,27,19,7],
[31,27,19,8],
[31,27,19,9],
[31,27,19,11],
[31,27,19,12],
[31,27,19,15],
[31,27,19,16],
[31,27,19,17],
[31,27,19,18],
[31,27,21,0],
[31,27,21,1],
[31,27,21,2],
[31,27,21,4],
[31,27,21,7],
[31,27,21,9],
[31,27,21,11],
[31,27,21,12],
[31,27,21,13],
[31,27,21,14],
[31,27,21,15],
[31,27,21,16],
[31,27,21,17],
[31,27,21,18],
[31,27,21,19],
[31,27,24,0],
[31,27,24,1],
[31,27,24,2],
[31,27,24,3],
[31,27,24,4],
[31,27,24,5],
[31,27,24,6],
[31,27,24,7],
[31,27,24,8],
[31,27,24,9],
[31,27,24,11],
[31,27,24,12],
[31,27,24,13],
[31,27,24,14],
[31,27,24,15],
[31,27,24,17],
[31,27,24,18],
[31,27,24,19],
[31,27,24,21],
[31,27,25,0],
[31,27,25,1],
[31,27,25,2],
[31,27,25,3],
[31,27,25,4],
[31,27,25,5],
[31,27,25,6],
[31,27,25,8],
[31,27,25,9],
[31,27,25,11],
[31,27,25,12],
[31,27,25,13],
[31,27,25,14],
[31,27,25,15],
[31,27,25,16],
[31,27,25,17],
[31,27,25,18],
[31,27,25,19],
[31,27,25,21],
[31,27,25,24],
[31,27,26,0],
[31,27,26,1],
[31,27,26,2],
[31,27,26,3],
[31,27,26,4],
[31,27,26,5],
[31,27,26,6],
[31,27,26,7],
[31,27,26,8],
[31,27,26,11],
[31,27,26,12],
[31,27,26,13],
[31,27,26,14],
[31,27,26,16],
[31,27,26,17],
[31,27,26,19],
[31,27,26,21],
[31,27,26,24],
[31,27,26,25],
[31,28,3,0],
[31,28,3,1],
[31,28,3,2],
[31,28,4,0],
[31,28,4,1],
[31,28,4,2],
[31,28,4,3],
[31,28,5,0],
[31,28,5,1],
[31,28,5,2],
[31,28,5,4],
[31,28,6,0],
[31,28,6,1],
[31,28,6,2],
[31,28,6,4],
[31,28,8,0],
[31,28,8,1],
[31,28,8,2],
[31,28,8,4],
[31,28,9,0],
[31,28,9,1],
[31,28,9,2],
[31,28,9,3],
[31,28,9,4],
[31,28,9,5],
[31,28,9,6],
[31,28,9,8],
[31,28,10,0],
[31,28,10,1],
[31,28,10,2],
[31,28,10,3],
[31,28,10,4],
[31,28,10,5],
[31,28,10,6],
[31,28,10,8],
[31,28,10,9],
[31,28,11,0],
[31,28,11,1],
[31,28,11,2],
[31,28,11,3],
[31,28,11,4],
[31,28,11,5],
[31,28,11,6],
[31,28,11,8],
[31,28,11,9],
[31,28,11,10],
[31,28,12,0],
[31,28,12,1],
[31,28,12,2],
[31,28,12,3],
[31,28,12,4],
[31,28,12,5],
[31,28,12,6],
[31,28,12,8],
[31,28,12,9],
[31,28,12,10],
[31,28,13,0],
[31,28,13,1],
[31,28,13,2],
[31,28,13,3],
[31,28,13,4],
[31,28,13,5],
[31,28,13,6],
[31,28,13,8],
[31,28,13,9],
[31,28,13,10],
[31,28,13,11],
[31,28,13,12],
[31,28,14,0],
[31,28,14,1],
[31,28,14,2],
[31,28,14,3],
[31,28,14,4],
[31,28,14,5],
[31,28,14,6],
[31,28,14,8],
[31,28,14,9],
[31,28,14,10],
[31,28,14,11],
[31,28,14,12],
[31,28,15,0],
[31,28,15,1],
[31,28,15,2],
[31,28,15,3],
[31,28,15,4],
[31,28,15,5],
[31,28,15,6],
[31,28,15,8],
[31,28,15,10],
[31,28,15,11],
[31,28,15,12],
[31,28,15,13],
[31,28,15,14],
[31,28,16,0],
[31,28,16,1],
[31,28,16,2],
[31,28,16,3],
[31,28,16,4],
[31,28,16,5],
[31,28,16,6],
[31,28,16,8],
[31,28,16,9],
[31,28,16,10],
[31,28,16,11],
[31,28,16,12],
[31,28,16,13],
[31,28,16,14],
[31,28,16,15],
[31,28,17,0],
[31,28,17,1],
[31,28,17,2],
[31,28,17,3],
[31,28,17,4],
[31,28,17,5],
[31,28,17,6],
[31,28,17,8],
[31,28,17,9],
[31,28,17,10],
[31,28,17,11],
[31,28,17,12],
[31,28,17,13],
[31,28,17,14],
[31,28,17,15],
[31,28,17,16],
[31,28,18,0],
[31,28,18,1],
[31,28,18,2],
[31,28,18,3],
[31,28,18,4],
[31,28,18,5],
[31,28,18,6],
[31,28,18,8],
[31,28,18,10],
[31,28,18,11],
[31,28,18,12],
[31,28,18,13],
[31,28,18,14],
[31,28,18,16],
[31,28,18,17],
[31,28,19,0],
[31,28,19,1],
[31,28,19,2],
[31,28,19,3],
[31,28,19,4],
[31,28,19,5],
[31,28,19,6],
[31,28,19,8],
[31,28,19,9],
[31,28,19,10],
[31,28,19,11],
[31,28,19,12],
[31,28,19,15],
[31,28,19,16],
[31,28,19,17],
[31,28,19,18],
[31,28,20,0],
[31,28,20,1],
[31,28,20,2],
[31,28,20,3],
[31,28,20,4],
[31,28,20,5],
[31,28,20,6],
[31,28,20,8],
[31,28,20,9],
[31,28,20,11],
[31,28,20,12],
[31,28,20,13],
[31,28,20,14],
[31,28,20,15],
[31,28,20,16],
[31,28,20,17],
[31,28,20,18],
[31,28,20,19],
[31,28,21,0],
[31,28,21,1],
[31,28,21,2],
[31,28,21,4],
[31,28,21,9],
[31,28,21,10],
[31,28,21,11],
[31,28,21,12],
[31,28,21,13],
[31,28,21,14],
[31,28,21,15],
[31,28,21,16],
[31,28,21,17],
[31,28,21,18],
[31,28,21,19],
[31,28,21,20],
[31,28,22,0],
[31,28,22,1],
[31,28,22,2],
[31,28,22,3],
[31,28,22,4],
[31,28,22,5],
[31,28,22,6],
[31,28,22,8],
[31,28,22,9],
[31,28,22,11],
[31,28,22,12],
[31,28,22,13],
[31,28,22,14],
[31,28,22,15],
[31,28,22,16],
[31,28,22,17],
[31,28,22,18],
[31,28,22,19],
[31,28,22,21],
[31,28,24,0],
[31,28,24,1],
[31,28,24,2],
[31,28,24,3],
[31,28,24,4],
[31,28,24,5],
[31,28,24,6],
[31,28,24,8],
[31,28,24,9],
[31,28,24,10],
[31,28,24,11],
[31,28,24,12],
[31,28,24,13],
[31,28,24,14],
[31,28,24,15],
[31,28,24,17],
[31,28,24,18],
[31,28,24,19],
[31,28,24,20],
[31,28,24,21],
[31,28,24,22],
[31,28,26,0],
[31,28,26,1],
[31,28,26,2],
[31,28,26,3],
[31,28,26,4],
[31,28,26,5],
[31,28,26,6],
[31,28,26,8],
[31,28,26,10],
[31,28,26,11],
[31,28,26,12],
[31,28,26,13],
[31,28,26,14],
[31,28,26,16],
[31,28,26,17],
[31,28,26,19],
[31,28,26,20],
[31,28,26,21],
[31,28,26,22],
[31,28,26,24],
[31,28,27,0],
[31,28,27,1],
[31,28,27,2],
[31,28,27,3],
[31,28,27,4],
[31,28,27,5],
[31,28,27,6],
[31,28,27,8],
[31,28,27,9],
[31,28,27,11],
[31,28,27,12],
[31,28,27,13],
[31,28,27,14],
[31,28,27,15],
[31,28,27,16],
[31,28,27,17],
[31,28,27,18],
[31,28,27,19],
[31,28,27,21],
[31,28,27,24],
[31,28,27,26],
[31,29,3,0],
[31,29,3,1],
[31,29,3,2],
[31,29,4,0],
[31,29,4,1],
[31,29,4,2],
[31,29,4,3],
[31,29,5,0],
[31,29,5,1],
[31,29,5,2],
[31,29,5,4],
[31,29,6,0],
[31,29,6,1],
[31,29,6,2],
[31,29,6,4],
[31,29,7,0],
[31,29,7,1],
[31,29,7,2],
[31,29,7,3],
[31,29,7,4],
[31,29,7,5],
[31,29,7,6],
[31,29,8,0],
[31,29,8,1],
[31,29,8,2],
[31,29,8,4],
[31,29,8,7],
[31,29,9,0],
[31,29,9,1],
[31,29,9,2],
[31,29,9,3],
[31,29,9,4],
[31,29,9,5],
[31,29,9,6],
[31,29,9,7],
[31,29,9,8],
[31,29,10,0],
[31,29,10,1],
[31,29,10,2],
[31,29,10,3],
[31,29,10,4],
[31,29,10,5],
[31,29,10,6],
[31,29,10,7],
[31,29,10,8],
[31,29,10,9],
[31,29,13,0],
[31,29,13,1],
[31,29,13,2],
[31,29,13,3],
[31,29,13,4],
[31,29,13,5],
[31,29,13,6],
[31,29,13,7],
[31,29,13,8],
[31,29,13,9],
[31,29,13,10],
[31,29,14,0],
[31,29,14,1],
[31,29,14,2],
[31,29,14,3],
[31,29,14,4],
[31,29,14,5],
[31,29,14,6],
[31,29,14,7],
[31,29,14,8],
[31,29,14,9],
[31,29,14,10],
[31,29,15,0],
[31,29,15,1],
[31,29,15,2],
[31,29,15,3],
[31,29,15,4],
[31,29,15,5],
[31,29,15,6],
[31,29,15,7],
[31,29,15,8],
[31,29,15,10],
[31,29,15,13],
[31,29,15,14],
[31,29,16,0],
[31,29,16,1],
[31,29,16,2],
[31,29,16,3],
[31,29,16,4],
[31,29,16,5],
[31,29,16,6],
[31,29,16,7],
[31,29,16,8],
[31,29,16,9],
[31,29,16,10],
[31,29,16,13],
[31,29,16,14],
[31,29,16,15],
[31,29,17,0],
[31,29,17,1],
[31,29,17,2],
[31,29,17,3],
[31,29,17,4],
[31,29,17,5],
[31,29,17,6],
[31,29,17,7],
[31,29,17,8],
[31,29,17,9],
[31,29,17,10],
[31,29,17,13],
[31,29,17,14],
[31,29,17,15],
[31,29,17,16],
[31,29,18,0],
[31,29,18,1],
[31,29,18,2],
[31,29,18,3],
[31,29,18,4],
[31,29,18,5],
[31,29,18,6],
[31,29,18,7],
[31,29,18,8],
[31,29,18,10],
[31,29,18,13],
[31,29,18,14],
[31,29,18,16],
[31,29,18,17],
[31,29,19,0],
[31,29,19,1],
[31,29,19,2],
[31,29,19,3],
[31,29,19,4],
[31,29,19,5],
[31,29,19,6],
[31,29,19,7],
[31,29,19,8],
[31,29,19,9],
[31,29,19,10],
[31,29,19,15],
[31,29,19,16],
[31,29,19,17],
[31,29,19,18],
[31,29,20,0],
[31,29,20,1],
[31,29,20,2],
[31,29,20,3],
[31,29,20,4],
[31,29,20,5],
[31,29,20,6],
[31,29,20,7],
[31,29,20,8],
[31,29,20,9],
[31,29,20,13],
[31,29,20,14],
[31,29,20,15],
[31,29,20,16],
[31,29,20,17],
[31,29,20,18],
[31,29,20,19],
[31,29,21,0],
[31,29,21,1],
[31,29,21,2],
[31,29,21,4],
[31,29,21,7],
[31,29,21,9],
[31,29,21,10],
[31,29,21,13],
[31,29,21,14],
[31,29,21,15],
[31,29,21,16],
[31,29,21,17],
[31,29,21,18],
[31,29,21,19],
[31,29,21,20],
[31,29,22,0],
[31,29,22,1],
[31,29,22,2],
[31,29,22,3],
[31,29,22,4],
[31,29,22,5],
[31,29,22,6],
[31,29,22,7],
[31,29,22,8],
[31,29,22,9],
[31,29,22,13],
[31,29,22,14],
[31,29,22,15],
[31,29,22,16],
[31,29,22,17],
[31,29,22,18],
[31,29,22,19],
[31,29,22,21],
[31,29,24,0],
[31,29,24,1],
[31,29,24,2],
[31,29,24,3],
[31,29,24,4],
[31,29,24,5],
[31,29,24,6],
[31,29,24,7],
[31,29,24,8],
[31,29,24,9],
[31,29,24,10],
[31,29,24,13],
[31,29,24,14],
[31,29,24,15],
[31,29,24,17],
[31,29,24,18],
[31,29,24,19],
[31,29,24,20],
[31,29,24,21],
[31,29,24,22],
[31,29,25,0],
[31,29,25,1],
[31,29,25,2],
[31,29,25,3],
[31,29,25,4],
[31,29,25,5],
[31,29,25,6],
[31,29,25,8],
[31,29,25,9],
[31,29,25,10],
[31,29,25,13],
[31,29,25,14],
[31,29,25,15],
[31,29,25,16],
[31,29,25,17],
[31,29,25,18],
[31,29,25,19],
[31,29,25,20],
[31,29,25,21],
[31,29,25,22],
[31,29,25,24],
[31,29,26,0],
[31,29,26,1],
[31,29,26,2],
[31,29,26,3],
[31,29,26,4],
[31,29,26,5],
[31,29,26,6],
[31,29,26,7],
[31,29,26,8],
[31,29,26,10],
[31,29,26,13],
[31,29,26,14],
[31,29,26,16],
[31,29,26,17],
[31,29,26,19],
[31,29,26,20],
[31,29,26,21],
[31,29,26,22],
[31,29,26,24],
[31,29,26,25],
[31,29,27,0],
[31,29,27,1],
[31,29,27,2],
[31,29,27,3],
[31,29,27,4],
[31,29,27,5],
[31,29,27,6],
[31,29,27,7],
[31,29,27,8],
[31,29,27,9],
[31,29,27,13],
[31,29,27,14],
[31,29,27,15],
[31,29,27,16],
[31,29,27,17],
[31,29,27,18],
[31,29,27,19],
[31,29,27,21],
[31,29,27,24],
[31,29,27,25],
[31,29,27,26],
[31,29,28,0],
[31,29,28,1],
[31,29,28,2],
[31,29,28,3],
[31,29,28,4],
[31,29,28,5],
[31,29,28,6],
[31,29,28,8],
[31,29,28,9],
[31,29,28,10],
[31,29,28,13],
[31,29,28,14],
[31,29,28,15],
[31,29,28,16],
[31,29,28,17],
[31,29,28,18],
[31,29,28,19],
[31,29,28,20],
[31,29,28,21],
[31,29,28,22],
[31,29,28,24],
[31,29,28,26],
[31,29,28,27],
[32,4,3,0],
[32,4,3,1],
[32,4,3,2],
[32,5,4,0],
[32,5,4,1],
[32,5,4,2],
[32,6,4,0],
[32,6,4,1],
[32,6,4,2],
[32,7,3,0],
[32,7,3,1],
[32,7,3,2],
[32,7,4,0],
[32,7,4,1],
[32,7,4,2],
[32,7,4,3],
[32,7,5,0],
[32,7,5,1],
[32,7,5,2],
[32,7,5,4],
[32,7,6,0],
[32,7,6,1],
[32,7,6,2],
[32,7,6,4],
[32,8,4,0],
[32,8,4,1],
[32,8,4,2],
[32,8,7,0],
[32,8,7,1],
[32,8,7,2],
[32,8,7,4],
[32,9,3,0],
[32,9,3,1],
[32,9,3,2],
[32,9,4,0],
[32,9,4,1],
[32,9,4,2],
[32,9,4,3],
[32,9,5,0],
[32,9,5,1],
[32,9,5,2],
[32,9,5,4],
[32,9,6,0],
[32,9,6,1],
[32,9,6,2],
[32,9,6,4],
[32,9,7,0],
[32,9,7,1],
[32,9,7,2],
[32,9,7,3],
[32,9,7,4],
[32,9,7,5],
[32,9,7,6],
[32,9,8,0],
[32,9,8,1],
[32,9,8,2],
[32,9,8,4],
[32,9,8,7],
[32,10,3,0],
[32,10,3,1],
[32,10,3,2],
[32,10,4,0],
[32,10,4,1],
[32,10,4,2],
[32,10,4,3],
[32,10,5,0],
[32,10,5,1],
[32,10,5,2],
[32,10,5,4],
[32,10,6,0],
[32,10,6,1],
[32,10,6,2],
[32,10,6,4],
[32,10,7,0],
[32,10,7,1],
[32,10,7,2],
[32,10,7,3],
[32,10,7,4],
[32,10,7,5],
[32,10,7,6],
[32,10,8,0],
[32,10,8,1],
[32,10,8,2],
[32,10,8,4],
[32,10,8,7],
[32,10,9,0],
[32,10,9,1],
[32,10,9,2],
[32,10,9,3],
[32,10,9,4],
[32,10,9,5],
[32,10,9,6],
[32,10,9,7],
[32,10,9,8],
[32,13,3,0],
[32,13,3,1],
[32,13,3,2],
[32,13,4,0],
[32,13,4,1],
[32,13,4,2],
[32,13,4,3],
[32,13,5,0],
[32,13,5,1],
[32,13,5,2],
[32,13,5,4],
[32,13,6,0],
[32,13,6,1],
[32,13,6,2],
[32,13,6,4],
[32,13,7,0],
[32,13,7,1],
[32,13,7,2],
[32,13,7,3],
[32,13,7,4],
[32,13,7,5],
[32,13,7,6],
[32,13,8,0],
[32,13,8,1],
[32,13,8,2],
[32,13,8,4],
[32,13,8,7],
[32,13,9,0],
[32,13,9,1],
[32,13,9,2],
[32,13,9,3],
[32,13,9,4],
[32,13,9,5],
[32,13,9,6],
[32,13,9,7],
[32,13,9,8],
[32,13,10,0],
[32,13,10,1],
[32,13,10,2],
[32,13,10,3],
[32,13,10,4],
[32,13,10,5],
[32,13,10,6],
[32,13,10,7],
[32,13,10,8],
[32,13,10,9],
[32,14,3,0],
[32,14,3,1],
[32,14,3,2],
[32,14,4,0],
[32,14,4,1],
[32,14,4,2],
[32,14,4,3],
[32,14,5,0],
[32,14,5,1],
[32,14,5,2],
[32,14,5,4],
[32,14,6,0],
[32,14,6,1],
[32,14,6,2],
[32,14,6,4],
[32,14,7,0],
[32,14,7,1],
[32,14,7,2],
[32,14,7,3],
[32,14,7,4],
[32,14,7,5],
[32,14,7,6],
[32,14,8,0],
[32,14,8,1],
[32,14,8,2],
[32,14,8,4],
[32,14,8,7],
[32,14,9,0],
[32,14,9,1],
[32,14,9,2],
[32,14,9,3],
[32,14,9,4],
[32,14,9,5],
[32,14,9,6],
[32,14,9,7],
[32,14,9,8],
[32,14,10,0],
[32,14,10,1],
[32,14,10,2],
[32,14,10,3],
[32,14,10,4],
[32,14,10,5],
[32,14,10,6],
[32,14,10,7],
[32,14,10,8],
[32,14,10,9],
[32,15,3,0],
[32,15,3,1],
[32,15,3,2],
[32,15,4,0],
[32,15,4,1],
[32,15,4,2],
[32,15,4,3],
[32,15,5,0],
[32,15,5,1],
[32,15,5,2],
[32,15,5,4],
[32,15,6,0],
[32,15,6,1],
[32,15,6,2],
[32,15,6,4],
[32,15,7,0],
[32,15,7,1],
[32,15,7,2],
[32,15,7,3],
[32,15,7,4],
[32,15,7,5],
[32,15,7,6],
[32,15,8,0],
[32,15,8,1],
[32,15,8,2],
[32,15,8,4],
[32,15,8,7],
[32,15,10,0],
[32,15,10,1],
[32,15,10,2],
[32,15,10,3],
[32,15,10,4],
[32,15,10,5],
[32,15,10,6],
[32,15,10,7],
[32,15,10,8],
[32,15,13,0],
[32,15,13,1],
[32,15,13,2],
[32,15,13,3],
[32,15,13,4],
[32,15,13,5],
[32,15,13,6],
[32,15,13,7],
[32,15,13,8],
[32,15,13,10],
[32,15,14,0],
[32,15,14,1],
[32,15,14,2],
[32,15,14,3],
[32,15,14,4],
[32,15,14,5],
[32,15,14,6],
[32,15,14,7],
[32,15,14,8],
[32,15,14,10],
[32,16,3,0],
[32,16,3,1],
[32,16,3,2],
[32,16,4,0],
[32,16,4,1],
[32,16,4,2],
[32,16,4,3],
[32,16,5,0],
[32,16,5,1],
[32,16,5,2],
[32,16,5,4],
[32,16,6,0],
[32,16,6,1],
[32,16,6,2],
[32,16,6,4],
[32,16,7,0],
[32,16,7,1],
[32,16,7,2],
[32,16,7,3],
[32,16,7,4],
[32,16,7,5],
[32,16,7,6],
[32,16,8,0],
[32,16,8,1],
[32,16,8,2],
[32,16,8,4],
[32,16,8,7],
[32,16,9,0],
[32,16,9,1],
[32,16,9,2],
[32,16,9,3],
[32,16,9,4],
[32,16,9,5],
[32,16,9,6],
[32,16,9,7],
[32,16,9,8],
[32,16,10,0],
[32,16,10,1],
[32,16,10,2],
[32,16,10,3],
[32,16,10,4],
[32,16,10,5],
[32,16,10,6],
[32,16,10,7],
[32,16,10,8],
[32,16,10,9],
[32,16,13,0],
[32,16,13,1],
[32,16,13,2],
[32,16,13,3],
[32,16,13,4],
[32,16,13,5],
[32,16,13,6],
[32,16,13,7],
[32,16,13,8],
[32,16,13,9],
[32,16,13,10],
[32,16,14,0],
[32,16,14,1],
[32,16,14,2],
[32,16,14,3],
[32,16,14,4],
[32,16,14,5],
[32,16,14,6],
[32,16,14,7],
[32,16,14,8],
[32,16,14,9],
[32,16,14,10],
[32,16,15,0],
[32,16,15,1],
[32,16,15,2],
[32,16,15,3],
[32,16,15,4],
[32,16,15,5],
[32,16,15,6],
[32,16,15,7],
[32,16,15,8],
[32,16,15,10],
[32,16,15,13],
[32,16,15,14],
[32,17,3,0],
[32,17,3,1],
[32,17,3,2],
[32,17,4,0],
[32,17,4,1],
[32,17,4,2],
[32,17,4,3],
[32,17,5,0],
[32,17,5,1],
[32,17,5,2],
[32,17,5,4],
[32,17,6,0],
[32,17,6,1],
[32,17,6,2],
[32,17,6,4],
[32,17,7,0],
[32,17,7,1],
[32,17,7,2],
[32,17,7,3],
[32,17,7,4],
[32,17,7,5],
[32,17,7,6],
[32,17,8,0],
[32,17,8,1],
[32,17,8,2],
[32,17,8,4],
[32,17,8,7],
[32,17,9,0],
[32,17,9,1],
[32,17,9,2],
[32,17,9,3],
[32,17,9,4],
[32,17,9,5],
[32,17,9,6],
[32,17,9,7],
[32,17,9,8],
[32,17,10,0],
[32,17,10,1],
[32,17,10,2],
[32,17,10,3],
[32,17,10,4],
[32,17,10,5],
[32,17,10,6],
[32,17,10,7],
[32,17,10,8],
[32,17,10,9],
[32,17,13,0],
[32,17,13,1],
[32,17,13,2],
[32,17,13,3],
[32,17,13,4],
[32,17,13,5],
[32,17,13,6],
[32,17,13,7],
[32,17,13,8],
[32,17,13,9],
[32,17,13,10],
[32,17,14,0],
[32,17,14,1],
[32,17,14,2],
[32,17,14,3],
[32,17,14,4],
[32,17,14,5],
[32,17,14,6],
[32,17,14,7],
[32,17,14,8],
[32,17,14,9],
[32,17,14,10],
[32,17,15,0],
[32,17,15,1],
[32,17,15,2],
[32,17,15,3],
[32,17,15,4],
[32,17,15,5],
[32,17,15,6],
[32,17,15,7],
[32,17,15,8],
[32,17,15,10],
[32,17,15,13],
[32,17,15,14],
[32,17,16,0],
[32,17,16,1],
[32,17,16,2],
[32,17,16,3],
[32,17,16,4],
[32,17,16,5],
[32,17,16,6],
[32,17,16,7],
[32,17,16,8],
[32,17,16,9],
[32,17,16,10],
[32,17,16,13],
[32,17,16,14],
[32,17,16,15],
[32,18,3,0],
[32,18,3,1],
[32,18,3,2],
[32,18,4,0],
[32,18,4,1],
[32,18,4,2],
[32,18,4,3],
[32,18,5,0],
[32,18,5,1],
[32,18,5,2],
[32,18,5,4],
[32,18,6,0],
[32,18,6,1],
[32,18,6,2],
[32,18,6,4],
[32,18,7,0],
[32,18,7,1],
[32,18,7,2],
[32,18,7,3],
[32,18,7,4],
[32,18,7,5],
[32,18,7,6],
[32,18,8,0],
[32,18,8,1],
[32,18,8,2],
[32,18,8,4],
[32,18,8,7],
[32,18,10,0],
[32,18,10,1],
[32,18,10,2],
[32,18,10,3],
[32,18,10,4],
[32,18,10,5],
[32,18,10,6],
[32,18,10,7],
[32,18,10,8],
[32,18,13,0],
[32,18,13,1],
[32,18,13,2],
[32,18,13,3],
[32,18,13,4],
[32,18,13,5],
[32,18,13,6],
[32,18,13,7],
[32,18,13,8],
[32,18,13,10],
[32,18,14,0],
[32,18,14,1],
[32,18,14,2],
[32,18,14,3],
[32,18,14,4],
[32,18,14,5],
[32,18,14,6],
[32,18,14,7],
[32,18,14,8],
[32,18,14,10],
[32,18,16,0],
[32,18,16,1],
[32,18,16,2],
[32,18,16,3],
[32,18,16,4],
[32,18,16,5],
[32,18,16,6],
[32,18,16,7],
[32,18,16,8],
[32,18,16,10],
[32,18,16,13],
[32,18,16,14],
[32,18,17,0],
[32,18,17,1],
[32,18,17,2],
[32,18,17,3],
[32,18,17,4],
[32,18,17,5],
[32,18,17,6],
[32,18,17,7],
[32,18,17,8],
[32,18,17,10],
[32,18,17,13],
[32,18,17,14],
[32,18,17,16],
[32,19,3,0],
[32,19,3,1],
[32,19,3,2],
[32,19,4,0],
[32,19,4,1],
[32,19,4,2],
[32,19,4,3],
[32,19,5,0],
[32,19,5,1],
[32,19,5,2],
[32,19,5,4],
[32,19,6,0],
[32,19,6,1],
[32,19,6,2],
[32,19,6,4],
[32,19,7,0],
[32,19,7,1],
[32,19,7,2],
[32,19,7,3],
[32,19,7,4],
[32,19,7,5],
[32,19,7,6],
[32,19,8,0],
[32,19,8,1],
[32,19,8,2],
[32,19,8,4],
[32,19,8,7],
[32,19,9,0],
[32,19,9,1],
[32,19,9,2],
[32,19,9,3],
[32,19,9,4],
[32,19,9,5],
[32,19,9,6],
[32,19,9,7],
[32,19,9,8],
[32,19,10,0],
[32,19,10,1],
[32,19,10,2],
[32,19,10,3],
[32,19,10,4],
[32,19,10,5],
[32,19,10,6],
[32,19,10,7],
[32,19,10,8],
[32,19,10,9],
[32,19,15,0],
[32,19,15,1],
[32,19,15,2],
[32,19,15,3],
[32,19,15,4],
[32,19,15,5],
[32,19,15,6],
[32,19,15,7],
[32,19,15,8],
[32,19,15,10],
[32,19,16,0],
[32,19,16,1],
[32,19,16,2],
[32,19,16,3],
[32,19,16,4],
[32,19,16,5],
[32,19,16,6],
[32,19,16,7],
[32,19,16,8],
[32,19,16,9],
[32,19,16,10],
[32,19,16,15],
[32,19,17,0],
[32,19,17,1],
[32,19,17,2],
[32,19,17,3],
[32,19,17,4],
[32,19,17,5],
[32,19,17,6],
[32,19,17,7],
[32,19,17,8],
[32,19,17,9],
[32,19,17,10],
[32,19,17,15],
[32,19,17,16],
[32,19,18,0],
[32,19,18,1],
[32,19,18,2],
[32,19,18,3],
[32,19,18,4],
[32,19,18,5],
[32,19,18,6],
[32,19,18,7],
[32,19,18,8],
[32,19,18,10],
[32,19,18,16],
[32,19,18,17],
[32,20,3,0],
[32,20,3,1],
[32,20,3,2],
[32,20,4,0],
[32,20,4,1],
[32,20,4,2],
[32,20,4,3],
[32,20,5,0],
[32,20,5,1],
[32,20,5,2],
[32,20,5,4],
[32,20,6,0],
[32,20,6,1],
[32,20,6,2],
[32,20,6,4],
[32,20,7,0],
[32,20,7,1],
[32,20,7,2],
[32,20,7,3],
[32,20,7,4],
[32,20,7,5],
[32,20,7,6],
[32,20,8,0],
[32,20,8,1],
[32,20,8,2],
[32,20,8,4],
[32,20,8,7],
[32,20,9,0],
[32,20,9,1],
[32,20,9,2],
[32,20,9,3],
[32,20,9,4],
[32,20,9,5],
[32,20,9,6],
[32,20,9,7],
[32,20,9,8],
[32,20,13,0],
[32,20,13,1],
[32,20,13,2],
[32,20,13,3],
[32,20,13,4],
[32,20,13,5],
[32,20,13,6],
[32,20,13,7],
[32,20,13,8],
[32,20,13,9],
[32,20,14,0],
[32,20,14,1],
[32,20,14,2],
[32,20,14,3],
[32,20,14,4],
[32,20,14,5],
[32,20,14,6],
[32,20,14,7],
[32,20,14,8],
[32,20,14,9],
[32,20,15,0],
[32,20,15,1],
[32,20,15,2],
[32,20,15,3],
[32,20,15,4],
[32,20,15,5],
[32,20,15,6],
[32,20,15,7],
[32,20,15,8],
[32,20,15,13],
[32,20,15,14],
[32,20,16,0],
[32,20,16,1],
[32,20,16,2],
[32,20,16,3],
[32,20,16,4],
[32,20,16,5],
[32,20,16,6],
[32,20,16,7],
[32,20,16,8],
[32,20,16,9],
[32,20,16,13],
[32,20,16,14],
[32,20,16,15],
[32,20,17,0],
[32,20,17,1],
[32,20,17,2],
[32,20,17,3],
[32,20,17,4],
[32,20,17,5],
[32,20,17,6],
[32,20,17,7],
[32,20,17,8],
[32,20,17,9],
[32,20,17,13],
[32,20,17,14],
[32,20,17,15],
[32,20,17,16],
[32,20,18,0],
[32,20,18,1],
[32,20,18,2],
[32,20,18,3],
[32,20,18,4],
[32,20,18,5],
[32,20,18,6],
[32,20,18,7],
[32,20,18,8],
[32,20,18,13],
[32,20,18,14],
[32,20,18,16],
[32,20,18,17],
[32,20,19,0],
[32,20,19,1],
[32,20,19,2],
[32,20,19,3],
[32,20,19,4],
[32,20,19,5],
[32,20,19,6],
[32,20,19,7],
[32,20,19,8],
[32,20,19,9],
[32,20,19,15],
[32,20,19,16],
[32,20,19,17],
[32,20,19,18],
[32,21,4,0],
[32,21,4,1],
[32,21,4,2],
[32,21,7,0],
[32,21,7,1],
[32,21,7,2],
[32,21,7,4],
[32,21,9,0],
[32,21,9,1],
[32,21,9,2],
[32,21,9,4],
[32,21,9,7],
[32,21,10,0],
[32,21,10,1],
[32,21,10,2],
[32,21,10,4],
[32,21,10,7],
[32,21,10,9],
[32,21,13,0],
[32,21,13,1],
[32,21,13,2],
[32,21,13,4],
[32,21,13,7],
[32,21,13,9],
[32,21,13,10],
[32,21,14,0],
[32,21,14,1],
[32,21,14,2],
[32,21,14,4],
[32,21,14,7],
[32,21,14,9],
[32,21,14,10],
[32,21,15,0],
[32,21,15,1],
[32,21,15,2],
[32,21,15,4],
[32,21,15,7],
[32,21,15,10],
[32,21,15,13],
[32,21,15,14],
[32,21,16,0],
[32,21,16,1],
[32,21,16,2],
[32,21,16,4],
[32,21,16,7],
[32,21,16,9],
[32,21,16,10],
[32,21,16,13],
[32,21,16,14],
[32,21,16,15],
[32,21,17,0],
[32,21,17,1],
[32,21,17,2],
[32,21,17,4],
[32,21,17,7],
[32,21,17,9],
[32,21,17,10],
[32,21,17,13],
[32,21,17,14],
[32,21,17,15],
[32,21,17,16],
[32,21,18,0],
[32,21,18,1],
[32,21,18,2],
[32,21,18,4],
[32,21,18,7],
[32,21,18,10],
[32,21,18,13],
[32,21,18,14],
[32,21,18,16],
[32,21,18,17],
[32,21,19,0],
[32,21,19,1],
[32,21,19,2],
[32,21,19,4],
[32,21,19,7],
[32,21,19,9],
[32,21,19,10],
[32,21,19,15],
[32,21,19,16],
[32,21,19,17],
[32,21,19,18],
[32,21,20,0],
[32,21,20,1],
[32,21,20,2],
[32,21,20,4],
[32,21,20,7],
[32,21,20,9],
[32,21,20,13],
[32,21,20,14],
[32,21,20,15],
[32,21,20,16],
[32,21,20,17],
[32,21,20,18],
[32,21,20,19],
[32,22,3,0],
[32,22,3,1],
[32,22,3,2],
[32,22,4,0],
[32,22,4,1],
[32,22,4,2],
[32,22,4,3],
[32,22,5,0],
[32,22,5,1],
[32,22,5,2],
[32,22,5,4],
[32,22,6,0],
[32,22,6,1],
[32,22,6,2],
[32,22,6,4],
[32,22,7,0],
[32,22,7,1],
[32,22,7,2],
[32,22,7,3],
[32,22,7,4],
[32,22,7,5],
[32,22,7,6],
[32,22,8,0],
[32,22,8,1],
[32,22,8,2],
[32,22,8,4],
[32,22,8,7],
[32,22,9,0],
[32,22,9,1],
[32,22,9,2],
[32,22,9,3],
[32,22,9,4],
[32,22,9,5],
[32,22,9,6],
[32,22,9,7],
[32,22,9,8],
[32,22,13,0],
[32,22,13,1],
[32,22,13,2],
[32,22,13,3],
[32,22,13,4],
[32,22,13,5],
[32,22,13,6],
[32,22,13,7],
[32,22,13,8],
[32,22,13,9],
[32,22,14,0],
[32,22,14,1],
[32,22,14,2],
[32,22,14,3],
[32,22,14,4],
[32,22,14,5],
[32,22,14,6],
[32,22,14,7],
[32,22,14,8],
[32,22,14,9],
[32,22,15,0],
[32,22,15,1],
[32,22,15,2],
[32,22,15,3],
[32,22,15,4],
[32,22,15,5],
[32,22,15,6],
[32,22,15,7],
[32,22,15,8],
[32,22,15,13],
[32,22,15,14],
[32,22,16,0],
[32,22,16,1],
[32,22,16,2],
[32,22,16,3],
[32,22,16,4],
[32,22,16,5],
[32,22,16,6],
[32,22,16,7],
[32,22,16,8],
[32,22,16,9],
[32,22,16,13],
[32,22,16,14],
[32,22,16,15],
[32,22,17,0],
[32,22,17,1],
[32,22,17,2],
[32,22,17,3],
[32,22,17,4],
[32,22,17,5],
[32,22,17,6],
[32,22,17,7],
[32,22,17,8],
[32,22,17,9],
[32,22,17,13],
[32,22,17,14],
[32,22,17,15],
[32,22,17,16],
[32,22,18,0],
[32,22,18,1],
[32,22,18,2],
[32,22,18,3],
[32,22,18,4],
[32,22,18,5],
[32,22,18,6],
[32,22,18,7],
[32,22,18,8],
[32,22,18,13],
[32,22,18,14],
[32,22,18,16],
[32,22,18,17],
[32,22,19,0],
[32,22,19,1],
[32,22,19,2],
[32,22,19,3],
[32,22,19,4],
[32,22,19,5],
[32,22,19,6],
[32,22,19,7],
[32,22,19,8],
[32,22,19,9],
[32,22,19,15],
[32,22,19,16],
[32,22,19,17],
[32,22,19,18],
[32,22,21,0],
[32,22,21,1],
[32,22,21,2],
[32,22,21,4],
[32,22,21,7],
[32,22,21,9],
[32,22,21,13],
[32,22,21,14],
[32,22,21,15],
[32,22,21,16],
[32,22,21,17],
[32,22,21,18],
[32,22,21,19],
[32,23,3,0],
[32,23,3,1],
[32,23,3,2],
[32,23,4,0],
[32,23,4,1],
[32,23,4,2],
[32,23,4,3],
[32,23,5,0],
[32,23,5,1],
[32,23,5,2],
[32,23,5,4],
[32,23,6,0],
[32,23,6,1],
[32,23,6,2],
[32,23,6,4],
[32,23,7,0],
[32,23,7,1],
[32,23,7,2],
[32,23,7,3],
[32,23,7,4],
[32,23,7,5],
[32,23,7,6],
[32,23,8,0],
[32,23,8,1],
[32,23,8,2],
[32,23,8,4],
[32,23,8,7],
[32,23,9,0],
[32,23,9,1],
[32,23,9,2],
[32,23,9,3],
[32,23,9,4],
[32,23,9,5],
[32,23,9,6],
[32,23,9,7],
[32,23,9,8],
[32,23,10,0],
[32,23,10,1],
[32,23,10,2],
[32,23,10,3],
[32,23,10,4],
[32,23,10,5],
[32,23,10,6],
[32,23,10,7],
[32,23,10,8],
[32,23,10,9],
[32,23,13,0],
[32,23,13,1],
[32,23,13,2],
[32,23,13,3],
[32,23,13,4],
[32,23,13,5],
[32,23,13,6],
[32,23,13,7],
[32,23,13,8],
[32,23,13,9],
[32,23,13,10],
[32,23,14,0],
[32,23,14,1],
[32,23,14,2],
[32,23,14,3],
[32,23,14,4],
[32,23,14,5],
[32,23,14,6],
[32,23,14,7],
[32,23,14,8],
[32,23,14,9],
[32,23,14,10],
[32,23,15,0],
[32,23,15,1],
[32,23,15,2],
[32,23,15,3],
[32,23,15,4],
[32,23,15,5],
[32,23,15,6],
[32,23,15,7],
[32,23,15,8],
[32,23,15,10],
[32,23,15,13],
[32,23,15,14],
[32,23,16,0],
[32,23,16,1],
[32,23,16,2],
[32,23,16,3],
[32,23,16,4],
[32,23,16,5],
[32,23,16,6],
[32,23,16,7],
[32,23,16,8],
[32,23,16,9],
[32,23,16,10],
[32,23,16,13],
[32,23,16,14],
[32,23,16,15],
[32,23,17,0],
[32,23,17,1],
[32,23,17,2],
[32,23,17,3],
[32,23,17,4],
[32,23,17,5],
[32,23,17,6],
[32,23,17,7],
[32,23,17,8],
[32,23,17,9],
[32,23,17,10],
[32,23,17,13],
[32,23,17,14],
[32,23,17,15],
[32,23,17,16],
[32,23,18,0],
[32,23,18,1],
[32,23,18,2],
[32,23,18,3],
[32,23,18,4],
[32,23,18,5],
[32,23,18,6],
[32,23,18,7],
[32,23,18,8],
[32,23,18,10],
[32,23,18,13],
[32,23,18,14],
[32,23,18,16],
[32,23,18,17],
[32,23,19,0],
[32,23,19,1],
[32,23,19,2],
[32,23,19,3],
[32,23,19,4],
[32,23,19,5],
[32,23,19,6],
[32,23,19,7],
[32,23,19,8],
[32,23,19,9],
[32,23,19,10],
[32,23,19,15],
[32,23,19,16],
[32,23,19,17],
[32,23,19,18],
[32,23,20,0],
[32,23,20,1],
[32,23,20,2],
[32,23,20,3],
[32,23,20,4],
[32,23,20,5],
[32,23,20,6],
[32,23,20,7],
[32,23,20,8],
[32,23,20,9],
[32,23,20,13],
[32,23,20,14],
[32,23,20,15],
[32,23,20,16],
[32,23,20,17],
[32,23,20,18],
[32,23,20,19],
[32,23,21,0],
[32,23,21,1],
[32,23,21,2],
[32,23,21,4],
[32,23,21,7],
[32,23,21,9],
[32,23,21,10],
[32,23,21,13],
[32,23,21,14],
[32,23,21,15],
[32,23,21,16],
[32,23,21,17],
[32,23,21,18],
[32,23,21,19],
[32,23,21,20],
[32,23,22,0],
[32,23,22,1],
[32,23,22,2],
[32,23,22,3],
[32,23,22,4],
[32,23,22,5],
[32,23,22,6],
[32,23,22,7],
[32,23,22,8],
[32,23,22,9],
[32,23,22,13],
[32,23,22,14],
[32,23,22,15],
[32,23,22,16],
[32,23,22,17],
[32,23,22,18],
[32,23,22,19],
[32,23,22,21],
[32,24,3,0],
[32,24,3,1],
[32,24,3,2],
[32,24,4,0],
[32,24,4,1],
[32,24,4,2],
[32,24,4,3],
[32,24,5,0],
[32,24,5,1],
[32,24,5,2],
[32,24,5,4],
[32,24,6,0],
[32,24,6,1],
[32,24,6,2],
[32,24,6,4],
[32,24,7,0],
[32,24,7,1],
[32,24,7,2],
[32,24,7,3],
[32,24,7,4],
[32,24,7,5],
[32,24,7,6],
[32,24,8,0],
[32,24,8,1],
[32,24,8,2],
[32,24,8,4],
[32,24,8,7],
[32,24,9,0],
[32,24,9,1],
[32,24,9,2],
[32,24,9,3],
[32,24,9,4],
[32,24,9,5],
[32,24,9,6],
[32,24,9,7],
[32,24,9,8],
[32,24,10,0],
[32,24,10,1],
[32,24,10,2],
[32,24,10,3],
[32,24,10,4],
[32,24,10,5],
[32,24,10,6],
[32,24,10,7],
[32,24,10,8],
[32,24,10,9],
[32,24,13,0],
[32,24,13,1],
[32,24,13,2],
[32,24,13,3],
[32,24,13,4],
[32,24,13,5],
[32,24,13,6],
[32,24,13,7],
[32,24,13,8],
[32,24,13,9],
[32,24,13,10],
[32,24,14,0],
[32,24,14,1],
[32,24,14,2],
[32,24,14,3],
[32,24,14,4],
[32,24,14,5],
[32,24,14,6],
[32,24,14,7],
[32,24,14,8],
[32,24,14,9],
[32,24,14,10],
[32,24,15,0],
[32,24,15,1],
[32,24,15,2],
[32,24,15,3],
[32,24,15,4],
[32,24,15,5],
[32,24,15,6],
[32,24,15,7],
[32,24,15,8],
[32,24,15,10],
[32,24,15,13],
[32,24,15,14],
[32,24,17,0],
[32,24,17,1],
[32,24,17,2],
[32,24,17,3],
[32,24,17,4],
[32,24,17,5],
[32,24,17,6],
[32,24,17,7],
[32,24,17,8],
[32,24,17,9],
[32,24,17,10],
[32,24,17,13],
[32,24,17,14],
[32,24,17,15],
[32,24,18,0],
[32,24,18,1],
[32,24,18,2],
[32,24,18,3],
[32,24,18,4],
[32,24,18,5],
[32,24,18,6],
[32,24,18,7],
[32,24,18,8],
[32,24,18,10],
[32,24,18,13],
[32,24,18,14],
[32,24,18,17],
[32,24,19,0],
[32,24,19,1],
[32,24,19,2],
[32,24,19,3],
[32,24,19,4],
[32,24,19,5],
[32,24,19,6],
[32,24,19,7],
[32,24,19,8],
[32,24,19,9],
[32,24,19,10],
[32,24,19,15],
[32,24,19,17],
[32,24,19,18],
[32,24,20,0],
[32,24,20,1],
[32,24,20,2],
[32,24,20,3],
[32,24,20,4],
[32,24,20,5],
[32,24,20,6],
[32,24,20,7],
[32,24,20,8],
[32,24,20,9],
[32,24,20,13],
[32,24,20,14],
[32,24,20,15],
[32,24,20,17],
[32,24,20,18],
[32,24,20,19],
[32,24,21,0],
[32,24,21,1],
[32,24,21,2],
[32,24,21,4],
[32,24,21,7],
[32,24,21,9],
[32,24,21,10],
[32,24,21,13],
[32,24,21,14],
[32,24,21,15],
[32,24,21,17],
[32,24,21,18],
[32,24,21,19],
[32,24,21,20],
[32,24,22,0],
[32,24,22,1],
[32,24,22,2],
[32,24,22,3],
[32,24,22,4],
[32,24,22,5],
[32,24,22,6],
[32,24,22,7],
[32,24,22,8],
[32,24,22,9],
[32,24,22,13],
[32,24,22,14],
[32,24,22,15],
[32,24,22,17],
[32,24,22,18],
[32,24,22,19],
[32,24,22,21],
[32,24,23,0],
[32,24,23,1],
[32,24,23,2],
[32,24,23,3],
[32,24,23,4],
[32,24,23,5],
[32,24,23,6],
[32,24,23,7],
[32,24,23,8],
[32,24,23,9],
[32,24,23,10],
[32,24,23,13],
[32,24,23,14],
[32,24,23,15],
[32,24,23,17],
[32,24,23,18],
[32,24,23,19],
[32,24,23,20],
[32,24,23,21],
[32,24,23,22],
[32,25,3,0],
[32,25,3,1],
[32,25,3,2],
[32,25,4,0],
[32,25,4,1],
[32,25,4,2],
[32,25,4,3],
[32,25,5,0],
[32,25,5,1],
[32,25,5,2],
[32,25,5,4],
[32,25,6,0],
[32,25,6,1],
[32,25,6,2],
[32,25,6,4],
[32,25,8,0],
[32,25,8,1],
[32,25,8,2],
[32,25,8,4],
[32,25,9,0],
[32,25,9,1],
[32,25,9,2],
[32,25,9,3],
[32,25,9,4],
[32,25,9,5],
[32,25,9,6],
[32,25,9,8],
[32,25,10,0],
[32,25,10,1],
[32,25,10,2],
[32,25,10,3],
[32,25,10,4],
[32,25,10,5],
[32,25,10,6],
[32,25,10,8],
[32,25,10,9],
[32,25,13,0],
[32,25,13,1],
[32,25,13,2],
[32,25,13,3],
[32,25,13,4],
[32,25,13,5],
[32,25,13,6],
[32,25,13,8],
[32,25,13,9],
[32,25,13,10],
[32,25,14,0],
[32,25,14,1],
[32,25,14,2],
[32,25,14,3],
[32,25,14,4],
[32,25,14,5],
[32,25,14,6],
[32,25,14,8],
[32,25,14,9],
[32,25,14,10],
[32,25,15,0],
[32,25,15,1],
[32,25,15,2],
[32,25,15,3],
[32,25,15,4],
[32,25,15,5],
[32,25,15,6],
[32,25,15,8],
[32,25,15,10],
[32,25,15,13],
[32,25,15,14],
[32,25,16,0],
[32,25,16,1],
[32,25,16,2],
[32,25,16,3],
[32,25,16,4],
[32,25,16,5],
[32,25,16,6],
[32,25,16,8],
[32,25,16,9],
[32,25,16,10],
[32,25,16,13],
[32,25,16,14],
[32,25,16,15],
[32,25,17,0],
[32,25,17,1],
[32,25,17,2],
[32,25,17,3],
[32,25,17,4],
[32,25,17,5],
[32,25,17,6],
[32,25,17,8],
[32,25,17,9],
[32,25,17,10],
[32,25,17,13],
[32,25,17,14],
[32,25,17,15],
[32,25,17,16],
[32,25,18,0],
[32,25,18,1],
[32,25,18,2],
[32,25,18,3],
[32,25,18,4],
[32,25,18,5],
[32,25,18,6],
[32,25,18,8],
[32,25,18,10],
[32,25,18,13],
[32,25,18,14],
[32,25,18,16],
[32,25,18,17],
[32,25,19,0],
[32,25,19,1],
[32,25,19,2],
[32,25,19,3],
[32,25,19,4],
[32,25,19,5],
[32,25,19,6],
[32,25,19,8],
[32,25,19,9],
[32,25,19,10],
[32,25,19,15],
[32,25,19,16],
[32,25,19,17],
[32,25,19,18],
[32,25,20,0],
[32,25,20,1],
[32,25,20,2],
[32,25,20,3],
[32,25,20,4],
[32,25,20,5],
[32,25,20,6],
[32,25,20,8],
[32,25,20,9],
[32,25,20,13],
[32,25,20,14],
[32,25,20,15],
[32,25,20,16],
[32,25,20,17],
[32,25,20,18],
[32,25,20,19],
[32,25,21,0],
[32,25,21,1],
[32,25,21,2],
[32,25,21,4],
[32,25,21,9],
[32,25,21,10],
[32,25,21,13],
[32,25,21,14],
[32,25,21,15],
[32,25,21,16],
[32,25,21,17],
[32,25,21,18],
[32,25,21,19],
[32,25,21,20],
[32,25,22,0],
[32,25,22,1],
[32,25,22,2],
[32,25,22,3],
[32,25,22,4],
[32,25,22,5],
[32,25,22,6],
[32,25,22,8],
[32,25,22,9],
[32,25,22,13],
[32,25,22,14],
[32,25,22,15],
[32,25,22,16],
[32,25,22,17],
[32,25,22,18],
[32,25,22,19],
[32,25,22,21],
[32,25,23,0],
[32,25,23,1],
[32,25,23,2],
[32,25,23,3],
[32,25,23,4],
[32,25,23,5],
[32,25,23,6],
[32,25,23,8],
[32,25,23,9],
[32,25,23,10],
[32,25,23,13],
[32,25,23,14],
[32,25,23,15],
[32,25,23,16],
[32,25,23,17],
[32,25,23,18],
[32,25,23,19],
[32,25,23,20],
[32,25,23,21],
[32,25,23,22],
[32,25,24,0],
[32,25,24,1],
[32,25,24,2],
[32,25,24,3],
[32,25,24,4],
[32,25,24,5],
[32,25,24,6],
[32,25,24,8],
[32,25,24,9],
[32,25,24,10],
[32,25,24,13],
[32,25,24,14],
[32,25,24,15],
[32,25,24,17],
[32,25,24,18],
[32,25,24,19],
[32,25,24,20],
[32,25,24,21],
[32,25,24,22],
[32,25,24,23],
[32,26,3,0],
[32,26,3,1],
[32,26,3,2],
[32,26,4,0],
[32,26,4,1],
[32,26,4,2],
[32,26,4,3],
[32,26,5,0],
[32,26,5,1],
[32,26,5,2],
[32,26,5,4],
[32,26,6,0],
[32,26,6,1],
[32,26,6,2],
[32,26,6,4],
[32,26,7,0],
[32,26,7,1],
[32,26,7,2],
[32,26,7,3],
[32,26,7,4],
[32,26,7,5],
[32,26,7,6],
[32,26,8,0],
[32,26,8,1],
[32,26,8,2],
[32,26,8,4],
[32,26,8,7],
[32,26,10,0],
[32,26,10,1],
[32,26,10,2],
[32,26,10,3],
[32,26,10,4],
[32,26,10,5],
[32,26,10,6],
[32,26,10,7],
[32,26,10,8],
[32,26,13,0],
[32,26,13,1],
[32,26,13,2],
[32,26,13,3],
[32,26,13,4],
[32,26,13,5],
[32,26,13,6],
[32,26,13,7],
[32,26,13,8],
[32,26,13,10],
[32,26,14,0],
[32,26,14,1],
[32,26,14,2],
[32,26,14,3],
[32,26,14,4],
[32,26,14,5],
[32,26,14,6],
[32,26,14,7],
[32,26,14,8],
[32,26,14,10],
[32,26,16,0],
[32,26,16,1],
[32,26,16,2],
[32,26,16,3],
[32,26,16,4],
[32,26,16,5],
[32,26,16,6],
[32,26,16,7],
[32,26,16,8],
[32,26,16,10],
[32,26,16,13],
[32,26,16,14],
[32,26,17,0],
[32,26,17,1],
[32,26,17,2],
[32,26,17,3],
[32,26,17,4],
[32,26,17,5],
[32,26,17,6],
[32,26,17,7],
[32,26,17,8],
[32,26,17,10],
[32,26,17,13],
[32,26,17,14],
[32,26,17,16],
[32,26,19,0],
[32,26,19,1],
[32,26,19,2],
[32,26,19,3],
[32,26,19,4],
[32,26,19,5],
[32,26,19,6],
[32,26,19,7],
[32,26,19,8],
[32,26,19,10],
[32,26,19,16],
[32,26,19,17],
[32,26,20,0],
[32,26,20,1],
[32,26,20,2],
[32,26,20,3],
[32,26,20,4],
[32,26,20,5],
[32,26,20,6],
[32,26,20,7],
[32,26,20,8],
[32,26,20,13],
[32,26,20,14],
[32,26,20,16],
[32,26,20,17],
[32,26,20,19],
[32,26,21,0],
[32,26,21,1],
[32,26,21,2],
[32,26,21,4],
[32,26,21,7],
[32,26,21,10],
[32,26,21,13],
[32,26,21,14],
[32,26,21,16],
[32,26,21,17],
[32,26,21,19],
[32,26,21,20],
[32,26,22,0],
[32,26,22,1],
[32,26,22,2],
[32,26,22,3],
[32,26,22,4],
[32,26,22,5],
[32,26,22,6],
[32,26,22,7],
[32,26,22,8],
[32,26,22,13],
[32,26,22,14],
[32,26,22,16],
[32,26,22,17],
[32,26,22,19],
[32,26,22,21],
[32,26,23,0],
[32,26,23,1],
[32,26,23,2],
[32,26,23,3],
[32,26,23,4],
[32,26,23,5],
[32,26,23,6],
[32,26,23,7],
[32,26,23,8],
[32,26,23,10],
[32,26,23,13],
[32,26,23,14],
[32,26,23,16],
[32,26,23,17],
[32,26,23,19],
[32,26,23,20],
[32,26,23,21],
[32,26,23,22],
[32,26,24,0],
[32,26,24,1],
[32,26,24,2],
[32,26,24,3],
[32,26,24,4],
[32,26,24,5],
[32,26,24,6],
[32,26,24,7],
[32,26,24,8],
[32,26,24,10],
[32,26,24,13],
[32,26,24,14],
[32,26,24,17],
[32,26,24,19],
[32,26,24,20],
[32,26,24,21],
[32,26,24,22],
[32,26,24,23],
[32,26,25,0],
[32,26,25,1],
[32,26,25,2],
[32,26,25,3],
[32,26,25,4],
[32,26,25,5],
[32,26,25,6],
[32,26,25,8],
[32,26,25,10],
[32,26,25,13],
[32,26,25,14],
[32,26,25,16],
[32,26,25,17],
[32,26,25,19],
[32,26,25,20],
[32,26,25,21],
[32,26,25,22],
[32,26,25,23],
[32,26,25,24],
[32,27,3,0],
[32,27,3,1],
[32,27,3,2],
[32,27,4,0],
[32,27,4,1],
[32,27,4,2],
[32,27,4,3],
[32,27,5,0],
[32,27,5,1],
[32,27,5,2],
[32,27,5,4],
[32,27,6,0],
[32,27,6,1],
[32,27,6,2],
[32,27,6,4],
[32,27,7,0],
[32,27,7,1],
[32,27,7,2],
[32,27,7,3],
[32,27,7,4],
[32,27,7,5],
[32,27,7,6],
[32,27,8,0],
[32,27,8,1],
[32,27,8,2],
[32,27,8,4],
[32,27,8,7],
[32,27,9,0],
[32,27,9,1],
[32,27,9,2],
[32,27,9,3],
[32,27,9,4],
[32,27,9,5],
[32,27,9,6],
[32,27,9,7],
[32,27,9,8],
[32,27,13,0],
[32,27,13,1],
[32,27,13,2],
[32,27,13,3],
[32,27,13,4],
[32,27,13,5],
[32,27,13,6],
[32,27,13,7],
[32,27,13,8],
[32,27,13,9],
[32,27,14,0],
[32,27,14,1],
[32,27,14,2],
[32,27,14,3],
[32,27,14,4],
[32,27,14,5],
[32,27,14,6],
[32,27,14,7],
[32,27,14,8],
[32,27,14,9],
[32,27,15,0],
[32,27,15,1],
[32,27,15,2],
[32,27,15,3],
[32,27,15,4],
[32,27,15,5],
[32,27,15,6],
[32,27,15,7],
[32,27,15,8],
[32,27,15,13],
[32,27,15,14],
[32,27,16,0],
[32,27,16,1],
[32,27,16,2],
[32,27,16,3],
[32,27,16,4],
[32,27,16,5],
[32,27,16,6],
[32,27,16,7],
[32,27,16,8],
[32,27,16,9],
[32,27,16,13],
[32,27,16,14],
[32,27,16,15],
[32,27,17,0],
[32,27,17,1],
[32,27,17,2],
[32,27,17,3],
[32,27,17,4],
[32,27,17,5],
[32,27,17,6],
[32,27,17,7],
[32,27,17,8],
[32,27,17,9],
[32,27,17,13],
[32,27,17,14],
[32,27,17,15],
[32,27,17,16],
[32,27,18,0],
[32,27,18,1],
[32,27,18,2],
[32,27,18,3],
[32,27,18,4],
[32,27,18,5],
[32,27,18,6],
[32,27,18,7],
[32,27,18,8],
[32,27,18,13],
[32,27,18,14],
[32,27,18,16],
[32,27,18,17],
[32,27,19,0],
[32,27,19,1],
[32,27,19,2],
[32,27,19,3],
[32,27,19,4],
[32,27,19,5],
[32,27,19,6],
[32,27,19,7],
[32,27,19,8],
[32,27,19,9],
[32,27,19,15],
[32,27,19,16],
[32,27,19,17],
[32,27,19,18],
[32,27,21,0],
[32,27,21,1],
[32,27,21,2],
[32,27,21,4],
[32,27,21,7],
[32,27,21,9],
[32,27,21,13],
[32,27,21,14],
[32,27,21,15],
[32,27,21,16],
[32,27,21,17],
[32,27,21,18],
[32,27,21,19],
[32,27,23,0],
[32,27,23,1],
[32,27,23,2],
[32,27,23,3],
[32,27,23,4],
[32,27,23,5],
[32,27,23,6],
[32,27,23,7],
[32,27,23,8],
[32,27,23,9],
[32,27,23,13],
[32,27,23,14],
[32,27,23,15],
[32,27,23,16],
[32,27,23,17],
[32,27,23,18],
[32,27,23,19],
[32,27,23,21],
[32,27,24,0],
[32,27,24,1],
[32,27,24,2],
[32,27,24,3],
[32,27,24,4],
[32,27,24,5],
[32,27,24,6],
[32,27,24,7],
[32,27,24,8],
[32,27,24,9],
[32,27,24,13],
[32,27,24,14],
[32,27,24,15],
[32,27,24,17],
[32,27,24,18],
[32,27,24,19],
[32,27,24,21],
[32,27,24,23],
[32,27,25,0],
[32,27,25,1],
[32,27,25,2],
[32,27,25,3],
[32,27,25,4],
[32,27,25,5],
[32,27,25,6],
[32,27,25,8],
[32,27,25,9],
[32,27,25,13],
[32,27,25,14],
[32,27,25,15],
[32,27,25,16],
[32,27,25,17],
[32,27,25,18],
[32,27,25,19],
[32,27,25,21],
[32,27,25,23],
[32,27,25,24],
[32,27,26,0],
[32,27,26,1],
[32,27,26,2],
[32,27,26,3],
[32,27,26,4],
[32,27,26,5],
[32,27,26,6],
[32,27,26,7],
[32,27,26,8],
[32,27,26,13],
[32,27,26,14],
[32,27,26,16],
[32,27,26,17],
[32,27,26,19],
[32,27,26,21],
[32,27,26,23],
[32,27,26,24],
[32,27,26,25],
[32,28,3,0],
[32,28,3,1],
[32,28,3,2],
[32,28,4,0],
[32,28,4,1],
[32,28,4,2],
[32,28,4,3],
[32,28,5,0],
[32,28,5,1],
[32,28,5,2],
[32,28,5,4],
[32,28,6,0],
[32,28,6,1],
[32,28,6,2],
[32,28,6,4],
[32,28,8,0],
[32,28,8,1],
[32,28,8,2],
[32,28,8,4],
[32,28,9,0],
[32,28,9,1],
[32,28,9,2],
[32,28,9,3],
[32,28,9,4],
[32,28,9,5],
[32,28,9,6],
[32,28,9,8],
[32,28,10,0],
[32,28,10,1],
[32,28,10,2],
[32,28,10,3],
[32,28,10,4],
[32,28,10,5],
[32,28,10,6],
[32,28,10,8],
[32,28,10,9],
[32,28,13,0],
[32,28,13,1],
[32,28,13,2],
[32,28,13,3],
[32,28,13,4],
[32,28,13,5],
[32,28,13,6],
[32,28,13,8],
[32,28,13,9],
[32,28,13,10],
[32,28,14,0],
[32,28,14,1],
[32,28,14,2],
[32,28,14,3],
[32,28,14,4],
[32,28,14,5],
[32,28,14,6],
[32,28,14,8],
[32,28,14,9],
[32,28,14,10],
[32,28,15,0],
[32,28,15,1],
[32,28,15,2],
[32,28,15,3],
[32,28,15,4],
[32,28,15,5],
[32,28,15,6],
[32,28,15,8],
[32,28,15,10],
[32,28,15,13],
[32,28,15,14],
[32,28,16,0],
[32,28,16,1],
[32,28,16,2],
[32,28,16,3],
[32,28,16,4],
[32,28,16,5],
[32,28,16,6],
[32,28,16,8],
[32,28,16,9],
[32,28,16,10],
[32,28,16,13],
[32,28,16,14],
[32,28,16,15],
[32,28,17,0],
[32,28,17,1],
[32,28,17,2],
[32,28,17,3],
[32,28,17,4],
[32,28,17,5],
[32,28,17,6],
[32,28,17,8],
[32,28,17,9],
[32,28,17,10],
[32,28,17,13],
[32,28,17,14],
[32,28,17,15],
[32,28,17,16],
[32,28,18,0],
[32,28,18,1],
[32,28,18,2],
[32,28,18,3],
[32,28,18,4],
[32,28,18,5],
[32,28,18,6],
[32,28,18,8],
[32,28,18,10],
[32,28,18,13],
[32,28,18,14],
[32,28,18,16],
[32,28,18,17],
[32,28,19,0],
[32,28,19,1],
[32,28,19,2],
[32,28,19,3],
[32,28,19,4],
[32,28,19,5],
[32,28,19,6],
[32,28,19,8],
[32,28,19,9],
[32,28,19,10],
[32,28,19,15],
[32,28,19,16],
[32,28,19,17],
[32,28,19,18],
[32,28,20,0],
[32,28,20,1],
[32,28,20,2],
[32,28,20,3],
[32,28,20,4],
[32,28,20,5],
[32,28,20,6],
[32,28,20,8],
[32,28,20,9],
[32,28,20,13],
[32,28,20,14],
[32,28,20,15],
[32,28,20,16],
[32,28,20,17],
[32,28,20,18],
[32,28,20,19],
[32,28,21,0],
[32,28,21,1],
[32,28,21,2],
[32,28,21,4],
[32,28,21,9],
[32,28,21,10],
[32,28,21,13],
[32,28,21,14],
[32,28,21,15],
[32,28,21,16],
[32,28,21,17],
[32,28,21,18],
[32,28,21,19],
[32,28,21,20],
[32,28,22,0],
[32,28,22,1],
[32,28,22,2],
[32,28,22,3],
[32,28,22,4],
[32,28,22,5],
[32,28,22,6],
[32,28,22,8],
[32,28,22,9],
[32,28,22,13],
[32,28,22,14],
[32,28,22,15],
[32,28,22,16],
[32,28,22,17],
[32,28,22,18],
[32,28,22,19],
[32,28,22,21],
[32,28,23,0],
[32,28,23,1],
[32,28,23,2],
[32,28,23,3],
[32,28,23,4],
[32,28,23,5],
[32,28,23,6],
[32,28,23,8],
[32,28,23,9],
[32,28,23,10],
[32,28,23,13],
[32,28,23,14],
[32,28,23,15],
[32,28,23,16],
[32,28,23,17],
[32,28,23,18],
[32,28,23,19],
[32,28,23,20],
[32,28,23,21],
[32,28,23,22],
[32,28,24,0],
[32,28,24,1],
[32,28,24,2],
[32,28,24,3],
[32,28,24,4],
[32,28,24,5],
[32,28,24,6],
[32,28,24,8],
[32,28,24,9],
[32,28,24,10],
[32,28,24,13],
[32,28,24,14],
[32,28,24,15],
[32,28,24,17],
[32,28,24,18],
[32,28,24,19],
[32,28,24,20],
[32,28,24,21],
[32,28,24,22],
[32,28,24,23],
[32,28,26,0],
[32,28,26,1],
[32,28,26,2],
[32,28,26,3],
[32,28,26,4],
[32,28,26,5],
[32,28,26,6],
[32,28,26,8],
[32,28,26,10],
[32,28,26,13],
[32,28,26,14],
[32,28,26,16],
[32,28,26,17],
[32,28,26,19],
[32,28,26,20],
[32,28,26,21],
[32,28,26,22],
[32,28,26,23],
[32,28,26,24],
[32,28,27,0],
[32,28,27,1],
[32,28,27,2],
[32,28,27,3],
[32,28,27,4],
[32,28,27,5],
[32,28,27,6],
[32,28,27,8],
[32,28,27,9],
[32,28,27,13],
[32,28,27,14],
[32,28,27,15],
[32,28,27,16],
[32,28,27,17],
[32,28,27,18],
[32,28,27,19],
[32,28,27,21],
[32,28,27,23],
[32,28,27,24],
[32,28,27,26],
[32,30,3,0],
[32,30,3,1],
[32,30,3,2],
[32,30,4,0],
[32,30,4,1],
[32,30,4,2],
[32,30,4,3],
[32,30,5,0],
[32,30,5,1],
[32,30,5,2],
[32,30,5,4],
[32,30,6,0],
[32,30,6,1],
[32,30,6,2],
[32,30,6,4],
[32,30,7,0],
[32,30,7,1],
[32,30,7,2],
[32,30,7,3],
[32,30,7,4],
[32,30,7,5],
[32,30,7,6],
[32,30,8,0],
[32,30,8,1],
[32,30,8,2],
[32,30,8,4],
[32,30,8,7],
[32,30,9,0],
[32,30,9,1],
[32,30,9,2],
[32,30,9,3],
[32,30,9,4],
[32,30,9,5],
[32,30,9,6],
[32,30,9,7],
[32,30,9,8],
[32,30,10,0],
[32,30,10,1],
[32,30,10,2],
[32,30,10,3],
[32,30,10,4],
[32,30,10,5],
[32,30,10,6],
[32,30,10,7],
[32,30,10,8],
[32,30,10,9],
[32,30,13,0],
[32,30,13,1],
[32,30,13,2],
[32,30,13,3],
[32,30,13,4],
[32,30,13,5],
[32,30,13,6],
[32,30,13,7],
[32,30,13,8],
[32,30,13,9],
[32,30,13,10],
[32,30,14,0],
[32,30,14,1],
[32,30,14,2],
[32,30,14,3],
[32,30,14,4],
[32,30,14,5],
[32,30,14,6],
[32,30,14,7],
[32,30,14,8],
[32,30,14,9],
[32,30,14,10],
[32,30,15,0],
[32,30,15,1],
[32,30,15,2],
[32,30,15,3],
[32,30,15,4],
[32,30,15,5],
[32,30,15,6],
[32,30,15,7],
[32,30,15,8],
[32,30,15,10],
[32,30,15,13],
[32,30,15,14],
[32,30,16,0],
[32,30,16,1],
[32,30,16,2],
[32,30,16,3],
[32,30,16,4],
[32,30,16,5],
[32,30,16,6],
[32,30,16,7],
[32,30,16,8],
[32,30,16,9],
[32,30,16,10],
[32,30,16,13],
[32,30,16,14],
[32,30,16,15],
[32,30,17,0],
[32,30,17,1],
[32,30,17,2],
[32,30,17,3],
[32,30,17,4],
[32,30,17,5],
[32,30,17,6],
[32,30,17,7],
[32,30,17,8],
[32,30,17,9],
[32,30,17,10],
[32,30,17,13],
[32,30,17,14],
[32,30,17,15],
[32,30,17,16],
[32,30,18,0],
[32,30,18,1],
[32,30,18,2],
[32,30,18,3],
[32,30,18,4],
[32,30,18,5],
[32,30,18,6],
[32,30,18,7],
[32,30,18,8],
[32,30,18,10],
[32,30,18,13],
[32,30,18,14],
[32,30,18,16],
[32,30,18,17],
[32,30,19,0],
[32,30,19,1],
[32,30,19,2],
[32,30,19,3],
[32,30,19,4],
[32,30,19,5],
[32,30,19,6],
[32,30,19,7],
[32,30,19,8],
[32,30,19,9],
[32,30,19,10],
[32,30,19,15],
[32,30,19,16],
[32,30,19,17],
[32,30,19,18],
[32,30,20,0],
[32,30,20,1],
[32,30,20,2],
[32,30,20,3],
[32,30,20,4],
[32,30,20,5],
[32,30,20,6],
[32,30,20,7],
[32,30,20,8],
[32,30,20,9],
[32,30,20,13],
[32,30,20,14],
[32,30,20,15],
[32,30,20,16],
[32,30,20,17],
[32,30,20,18],
[32,30,20,19],
[32,30,21,0],
[32,30,21,1],
[32,30,21,2],
[32,30,21,4],
[32,30,21,7],
[32,30,21,9],
[32,30,21,10],
[32,30,21,13],
[32,30,21,14],
[32,30,21,15],
[32,30,21,16],
[32,30,21,17],
[32,30,21,18],
[32,30,21,19],
[32,30,21,20],
[32,30,22,0],
[32,30,22,1],
[32,30,22,2],
[32,30,22,3],
[32,30,22,4],
[32,30,22,5],
[32,30,22,6],
[32,30,22,7],
[32,30,22,8],
[32,30,22,9],
[32,30,22,13],
[32,30,22,14],
[32,30,22,15],
[32,30,22,16],
[32,30,22,17],
[32,30,22,18],
[32,30,22,19],
[32,30,22,21],
[32,30,24,0],
[32,30,24,1],
[32,30,24,2],
[32,30,24,3],
[32,30,24,4],
[32,30,24,5],
[32,30,24,6],
[32,30,24,7],
[32,30,24,8],
[32,30,24,9],
[32,30,24,10],
[32,30,24,13],
[32,30,24,14],
[32,30,24,15],
[32,30,24,17],
[32,30,24,18],
[32,30,24,19],
[32,30,24,20],
[32,30,24,21],
[32,30,24,22],
[32,30,25,0],
[32,30,25,1],
[32,30,25,2],
[32,30,25,3],
[32,30,25,4],
[32,30,25,5],
[32,30,25,6],
[32,30,25,8],
[32,30,25,9],
[32,30,25,10],
[32,30,25,13],
[32,30,25,14],
[32,30,25,15],
[32,30,25,16],
[32,30,25,17],
[32,30,25,18],
[32,30,25,19],
[32,30,25,20],
[32,30,25,21],
[32,30,25,22],
[32,30,25,24],
[32,30,26,0],
[32,30,26,1],
[32,30,26,2],
[32,30,26,3],
[32,30,26,4],
[32,30,26,5],
[32,30,26,6],
[32,30,26,7],
[32,30,26,8],
[32,30,26,10],
[32,30,26,13],
[32,30,26,14],
[32,30,26,16],
[32,30,26,17],
[32,30,26,19],
[32,30,26,20],
[32,30,26,21],
[32,30,26,22],
[32,30,26,24],
[32,30,26,25],
[32,30,27,0],
[32,30,27,1],
[32,30,27,2],
[32,30,27,3],
[32,30,27,4],
[32,30,27,5],
[32,30,27,6],
[32,30,27,7],
[32,30,27,8],
[32,30,27,9],
[32,30,27,13],
[32,30,27,14],
[32,30,27,15],
[32,30,27,16],
[32,30,27,17],
[32,30,27,18],
[32,30,27,19],
[32,30,27,21],
[32,30,27,24],
[32,30,27,25],
[32,30,27,26],
[32,30,28,0],
[32,30,28,1],
[32,30,28,2],
[32,30,28,3],
[32,30,28,4],
[32,30,28,5],
[32,30,28,6],
[32,30,28,8],
[32,30,28,9],
[32,30,28,10],
[32,30,28,13],
[32,30,28,14],
[32,30,28,15],
[32,30,28,16],
[32,30,28,17],
[32,30,28,18],
[32,30,28,19],
[32,30,28,20],
[32,30,28,21],
[32,30,28,22],
[32,30,28,24],
[32,30,28,26],
[32,30,28,27],
[32,31,3,0],
[32,31,3,1],
[32,31,3,2],
[32,31,4,0],
[32,31,4,1],
[32,31,4,2],
[32,31,4,3],
[32,31,5,0],
[32,31,5,1],
[32,31,5,2],
[32,31,5,4],
[32,31,6,0],
[32,31,6,1],
[32,31,6,2],
[32,31,6,4],
[32,31,7,0],
[32,31,7,1],
[32,31,7,2],
[32,31,7,3],
[32,31,7,4],
[32,31,7,5],
[32,31,7,6],
[32,31,8,0],
[32,31,8,1],
[32,31,8,2],
[32,31,8,4],
[32,31,8,7],
[32,31,9,0],
[32,31,9,1],
[32,31,9,2],
[32,31,9,3],
[32,31,9,4],
[32,31,9,5],
[32,31,9,6],
[32,31,9,7],
[32,31,9,8],
[32,31,10,0],
[32,31,10,1],
[32,31,10,2],
[32,31,10,3],
[32,31,10,4],
[32,31,10,5],
[32,31,10,6],
[32,31,10,7],
[32,31,10,8],
[32,31,10,9],
[32,31,13,0],
[32,31,13,1],
[32,31,13,2],
[32,31,13,3],
[32,31,13,4],
[32,31,13,5],
[32,31,13,6],
[32,31,13,7],
[32,31,13,8],
[32,31,13,9],
[32,31,13,10],
[32,31,14,0],
[32,31,14,1],
[32,31,14,2],
[32,31,14,3],
[32,31,14,4],
[32,31,14,5],
[32,31,14,6],
[32,31,14,7],
[32,31,14,8],
[32,31,14,9],
[32,31,14,10],
[32,31,15,0],
[32,31,15,1],
[32,31,15,2],
[32,31,15,3],
[32,31,15,4],
[32,31,15,5],
[32,31,15,6],
[32,31,15,7],
[32,31,15,8],
[32,31,15,10],
[32,31,15,13],
[32,31,15,14],
[32,31,16,0],
[32,31,16,1],
[32,31,16,2],
[32,31,16,3],
[32,31,16,4],
[32,31,16,5],
[32,31,16,6],
[32,31,16,7],
[32,31,16,8],
[32,31,16,9],
[32,31,16,10],
[32,31,16,13],
[32,31,16,14],
[32,31,16,15],
[32,31,17,0],
[32,31,17,1],
[32,31,17,2],
[32,31,17,3],
[32,31,17,4],
[32,31,17,5],
[32,31,17,6],
[32,31,17,7],
[32,31,17,8],
[32,31,17,9],
[32,31,17,10],
[32,31,17,13],
[32,31,17,14],
[32,31,17,15],
[32,31,17,16],
[32,31,18,0],
[32,31,18,1],
[32,31,18,2],
[32,31,18,3],
[32,31,18,4],
[32,31,18,5],
[32,31,18,6],
[32,31,18,7],
[32,31,18,8],
[32,31,18,10],
[32,31,18,13],
[32,31,18,14],
[32,31,18,16],
[32,31,18,17],
[32,31,19,0],
[32,31,19,1],
[32,31,19,2],
[32,31,19,3],
[32,31,19,4],
[32,31,19,5],
[32,31,19,6],
[32,31,19,7],
[32,31,19,8],
[32,31,19,9],
[32,31,19,10],
[32,31,19,15],
[32,31,19,16],
[32,31,19,17],
[32,31,19,18],
[32,31,20,0],
[32,31,20,1],
[32,31,20,2],
[32,31,20,3],
[32,31,20,4],
[32,31,20,5],
[32,31,20,6],
[32,31,20,7],
[32,31,20,8],
[32,31,20,9],
[32,31,20,13],
[32,31,20,14],
[32,31,20,15],
[32,31,20,16],
[32,31,20,17],
[32,31,20,18],
[32,31,20,19],
[32,31,21,0],
[32,31,21,1],
[32,31,21,2],
[32,31,21,4],
[32,31,21,7],
[32,31,21,9],
[32,31,21,10],
[32,31,21,13],
[32,31,21,14],
[32,31,21,15],
[32,31,21,16],
[32,31,21,17],
[32,31,21,18],
[32,31,21,19],
[32,31,21,20],
[32,31,22,0],
[32,31,22,1],
[32,31,22,2],
[32,31,22,3],
[32,31,22,4],
[32,31,22,5],
[32,31,22,6],
[32,31,22,7],
[32,31,22,8],
[32,31,22,9],
[32,31,22,13],
[32,31,22,14],
[32,31,22,15],
[32,31,22,16],
[32,31,22,17],
[32,31,22,18],
[32,31,22,19],
[32,31,22,21],
[32,31,24,0],
[32,31,24,1],
[32,31,24,2],
[32,31,24,3],
[32,31,24,4],
[32,31,24,5],
[32,31,24,6],
[32,31,24,7],
[32,31,24,8],
[32,31,24,9],
[32,31,24,10],
[32,31,24,13],
[32,31,24,14],
[32,31,24,15],
[32,31,24,17],
[32,31,24,18],
[32,31,24,19],
[32,31,24,20],
[32,31,24,21],
[32,31,24,22],
[32,31,25,0],
[32,31,25,1],
[32,31,25,2],
[32,31,25,3],
[32,31,25,4],
[32,31,25,5],
[32,31,25,6],
[32,31,25,8],
[32,31,25,9],
[32,31,25,10],
[32,31,25,13],
[32,31,25,14],
[32,31,25,15],
[32,31,25,16],
[32,31,25,17],
[32,31,25,18],
[32,31,25,19],
[32,31,25,20],
[32,31,25,21],
[32,31,25,22],
[32,31,25,24],
[32,31,26,0],
[32,31,26,1],
[32,31,26,2],
[32,31,26,3],
[32,31,26,4],
[32,31,26,5],
[32,31,26,6],
[32,31,26,7],
[32,31,26,8],
[32,31,26,10],
[32,31,26,13],
[32,31,26,14],
[32,31,26,16],
[32,31,26,17],
[32,31,26,19],
[32,31,26,20],
[32,31,26,21],
[32,31,26,22],
[32,31,26,24],
[32,31,26,25],
[32,31,27,0],
[32,31,27,1],
[32,31,27,2],
[32,31,27,3],
[32,31,27,4],
[32,31,27,5],
[32,31,27,6],
[32,31,27,7],
[32,31,27,8],
[32,31,27,9],
[32,31,27,13],
[32,31,27,14],
[32,31,27,15],
[32,31,27,16],
[32,31,27,17],
[32,31,27,18],
[32,31,27,19],
[32,31,27,21],
[32,31,27,24],
[32,31,27,25],
[32,31,27,26],
[32,31,28,0],
[32,31,28,1],
[32,31,28,2],
[32,31,28,3],
[32,31,28,4],
[32,31,28,5],
[32,31,28,6],
[32,31,28,8],
[32,31,28,9],
[32,31,28,10],
[32,31,28,13],
[32,31,28,14],
[32,31,28,15],
[32,31,28,16],
[32,31,28,17],
[32,31,28,18],
[32,31,28,19],
[32,31,28,20],
[32,31,28,21],
[32,31,28,22],
[32,31,28,24],
[32,31,28,26],
[32,31,28,27],
[33,4,3,0],
[33,4,3,1],
[33,4,3,2],
[33,5,4,0],
[33,5,4,1],
[33,5,4,2],
[33,6,4,0],
[33,6,4,1],
[33,6,4,2],
[33,7,3,0],
[33,7,3,1],
[33,7,3,2],
[33,7,4,0],
[33,7,4,1],
[33,7,4,2],
[33,7,4,3],
[33,7,5,0],
[33,7,5,1],
[33,7,5,2],
[33,7,5,4],
[33,7,6,0],
[33,7,6,1],
[33,7,6,2],
[33,7,6,4],
[33,8,4,0],
[33,8,4,1],
[33,8,4,2],
[33,8,7,0],
[33,8,7,1],
[33,8,7,2],
[33,8,7,4],
[33,9,3,0],
[33,9,3,1],
[33,9,3,2],
[33,9,4,0],
[33,9,4,1],
[33,9,4,2],
[33,9,4,3],
[33,9,5,0],
[33,9,5,1],
[33,9,5,2],
[33,9,5,4],
[33,9,6,0],
[33,9,6,1],
[33,9,6,2],
[33,9,6,4],
[33,9,7,0],
[33,9,7,1],
[33,9,7,2],
[33,9,7,3],
[33,9,7,4],
[33,9,7,5],
[33,9,7,6],
[33,9,8,0],
[33,9,8,1],
[33,9,8,2],
[33,9,8,4],
[33,9,8,7],
[33,10,3,0],
[33,10,3,1],
[33,10,3,2],
[33,10,4,0],
[33,10,4,1],
[33,10,4,2],
[33,10,4,3],
[33,10,5,0],
[33,10,5,1],
[33,10,5,2],
[33,10,5,4],
[33,10,6,0],
[33,10,6,1],
[33,10,6,2],
[33,10,6,4],
[33,10,7,0],
[33,10,7,1],
[33,10,7,2],
[33,10,7,3],
[33,10,7,4],
[33,10,7,5],
[33,10,7,6],
[33,10,8,0],
[33,10,8,1],
[33,10,8,2],
[33,10,8,4],
[33,10,8,7],
[33,10,9,0],
[33,10,9,1],
[33,10,9,2],
[33,10,9,3],
[33,10,9,4],
[33,10,9,5],
[33,10,9,6],
[33,10,9,7],
[33,10,9,8],
[33,13,3,0],
[33,13,3,1],
[33,13,3,2],
[33,13,4,0],
[33,13,4,1],
[33,13,4,2],
[33,13,4,3],
[33,13,5,0],
[33,13,5,1],
[33,13,5,2],
[33,13,5,4],
[33,13,6,0],
[33,13,6,1],
[33,13,6,2],
[33,13,6,4],
[33,13,7,0],
[33,13,7,1],
[33,13,7,2],
[33,13,7,3],
[33,13,7,4],
[33,13,7,5],
[33,13,7,6],
[33,13,8,0],
[33,13,8,1],
[33,13,8,2],
[33,13,8,4],
[33,13,8,7],
[33,13,9,0],
[33,13,9,1],
[33,13,9,2],
[33,13,9,3],
[33,13,9,4],
[33,13,9,5],
[33,13,9,6],
[33,13,9,7],
[33,13,9,8],
[33,13,10,0],
[33,13,10,1],
[33,13,10,2],
[33,13,10,3],
[33,13,10,4],
[33,13,10,5],
[33,13,10,6],
[33,13,10,7],
[33,13,10,8],
[33,13,10,9],
[33,14,3,0],
[33,14,3,1],
[33,14,3,2],
[33,14,4,0],
[33,14,4,1],
[33,14,4,2],
[33,14,4,3],
[33,14,5,0],
[33,14,5,1],
[33,14,5,2],
[33,14,5,4],
[33,14,6,0],
[33,14,6,1],
[33,14,6,2],
[33,14,6,4],
[33,14,7,0],
[33,14,7,1],
[33,14,7,2],
[33,14,7,3],
[33,14,7,4],
[33,14,7,5],
[33,14,7,6],
[33,14,8,0],
[33,14,8,1],
[33,14,8,2],
[33,14,8,4],
[33,14,8,7],
[33,14,9,0],
[33,14,9,1],
[33,14,9,2],
[33,14,9,3],
[33,14,9,4],
[33,14,9,5],
[33,14,9,6],
[33,14,9,7],
[33,14,9,8],
[33,14,10,0],
[33,14,10,1],
[33,14,10,2],
[33,14,10,3],
[33,14,10,4],
[33,14,10,5],
[33,14,10,6],
[33,14,10,7],
[33,14,10,8],
[33,14,10,9],
[33,15,3,0],
[33,15,3,1],
[33,15,3,2],
[33,15,4,0],
[33,15,4,1],
[33,15,4,2],
[33,15,4,3],
[33,15,5,0],
[33,15,5,1],
[33,15,5,2],
[33,15,5,4],
[33,15,6,0],
[33,15,6,1],
[33,15,6,2],
[33,15,6,4],
[33,15,7,0],
[33,15,7,1],
[33,15,7,2],
[33,15,7,3],
[33,15,7,4],
[33,15,7,5],
[33,15,7,6],
[33,15,8,0],
[33,15,8,1],
[33,15,8,2],
[33,15,8,4],
[33,15,8,7],
[33,15,10,0],
[33,15,10,1],
[33,15,10,2],
[33,15,10,3],
[33,15,10,4],
[33,15,10,5],
[33,15,10,6],
[33,15,10,7],
[33,15,10,8],
[33,15,13,0],
[33,15,13,1],
[33,15,13,2],
[33,15,13,3],
[33,15,13,4],
[33,15,13,5],
[33,15,13,6],
[33,15,13,7],
[33,15,13,8],
[33,15,13,10],
[33,15,14,0],
[33,15,14,1],
[33,15,14,2],
[33,15,14,3],
[33,15,14,4],
[33,15,14,5],
[33,15,14,6],
[33,15,14,7],
[33,15,14,8],
[33,15,14,10],
[33,16,3,0],
[33,16,3,1],
[33,16,3,2],
[33,16,4,0],
[33,16,4,1],
[33,16,4,2],
[33,16,4,3],
[33,16,5,0],
[33,16,5,1],
[33,16,5,2],
[33,16,5,4],
[33,16,6,0],
[33,16,6,1],
[33,16,6,2],
[33,16,6,4],
[33,16,7,0],
[33,16,7,1],
[33,16,7,2],
[33,16,7,3],
[33,16,7,4],
[33,16,7,5],
[33,16,7,6],
[33,16,8,0],
[33,16,8,1],
[33,16,8,2],
[33,16,8,4],
[33,16,8,7],
[33,16,9,0],
[33,16,9,1],
[33,16,9,2],
[33,16,9,3],
[33,16,9,4],
[33,16,9,5],
[33,16,9,6],
[33,16,9,7],
[33,16,9,8],
[33,16,10,0],
[33,16,10,1],
[33,16,10,2],
[33,16,10,3],
[33,16,10,4],
[33,16,10,5],
[33,16,10,6],
[33,16,10,7],
[33,16,10,8],
[33,16,10,9],
[33,16,13,0],
[33,16,13,1],
[33,16,13,2],
[33,16,13,3],
[33,16,13,4],
[33,16,13,5],
[33,16,13,6],
[33,16,13,7],
[33,16,13,8],
[33,16,13,9],
[33,16,13,10],
[33,16,14,0],
[33,16,14,1],
[33,16,14,2],
[33,16,14,3],
[33,16,14,4],
[33,16,14,5],
[33,16,14,6],
[33,16,14,7],
[33,16,14,8],
[33,16,14,9],
[33,16,14,10],
[33,16,15,0],
[33,16,15,1],
[33,16,15,2],
[33,16,15,3],
[33,16,15,4],
[33,16,15,5],
[33,16,15,6],
[33,16,15,7],
[33,16,15,8],
[33,16,15,10],
[33,16,15,13],
[33,16,15,14],
[33,17,3,0],
[33,17,3,1],
[33,17,3,2],
[33,17,4,0],
[33,17,4,1],
[33,17,4,2],
[33,17,4,3],
[33,17,5,0],
[33,17,5,1],
[33,17,5,2],
[33,17,5,4],
[33,17,6,0],
[33,17,6,1],
[33,17,6,2],
[33,17,6,4],
[33,17,7,0],
[33,17,7,1],
[33,17,7,2],
[33,17,7,3],
[33,17,7,4],
[33,17,7,5],
[33,17,7,6],
[33,17,8,0],
[33,17,8,1],
[33,17,8,2],
[33,17,8,4],
[33,17,8,7],
[33,17,9,0],
[33,17,9,1],
[33,17,9,2],
[33,17,9,3],
[33,17,9,4],
[33,17,9,5],
[33,17,9,6],
[33,17,9,7],
[33,17,9,8],
[33,17,10,0],
[33,17,10,1],
[33,17,10,2],
[33,17,10,3],
[33,17,10,4],
[33,17,10,5],
[33,17,10,6],
[33,17,10,7],
[33,17,10,8],
[33,17,10,9],
[33,17,13,0],
[33,17,13,1],
[33,17,13,2],
[33,17,13,3],
[33,17,13,4],
[33,17,13,5],
[33,17,13,6],
[33,17,13,7],
[33,17,13,8],
[33,17,13,9],
[33,17,13,10],
[33,17,14,0],
[33,17,14,1],
[33,17,14,2],
[33,17,14,3],
[33,17,14,4],
[33,17,14,5],
[33,17,14,6],
[33,17,14,7],
[33,17,14,8],
[33,17,14,9],
[33,17,14,10],
[33,17,15,0],
[33,17,15,1],
[33,17,15,2],
[33,17,15,3],
[33,17,15,4],
[33,17,15,5],
[33,17,15,6],
[33,17,15,7],
[33,17,15,8],
[33,17,15,10],
[33,17,15,13],
[33,17,15,14],
[33,17,16,0],
[33,17,16,1],
[33,17,16,2],
[33,17,16,3],
[33,17,16,4],
[33,17,16,5],
[33,17,16,6],
[33,17,16,7],
[33,17,16,8],
[33,17,16,9],
[33,17,16,10],
[33,17,16,13],
[33,17,16,14],
[33,17,16,15],
[33,18,3,0],
[33,18,3,1],
[33,18,3,2],
[33,18,4,0],
[33,18,4,1],
[33,18,4,2],
[33,18,4,3],
[33,18,5,0],
[33,18,5,1],
[33,18,5,2],
[33,18,5,4],
[33,18,6,0],
[33,18,6,1],
[33,18,6,2],
[33,18,6,4],
[33,18,7,0],
[33,18,7,1],
[33,18,7,2],
[33,18,7,3],
[33,18,7,4],
[33,18,7,5],
[33,18,7,6],
[33,18,8,0],
[33,18,8,1],
[33,18,8,2],
[33,18,8,4],
[33,18,8,7],
[33,18,10,0],
[33,18,10,1],
[33,18,10,2],
[33,18,10,3],
[33,18,10,4],
[33,18,10,5],
[33,18,10,6],
[33,18,10,7],
[33,18,10,8],
[33,18,13,0],
[33,18,13,1],
[33,18,13,2],
[33,18,13,3],
[33,18,13,4],
[33,18,13,5],
[33,18,13,6],
[33,18,13,7],
[33,18,13,8],
[33,18,13,10],
[33,18,14,0],
[33,18,14,1],
[33,18,14,2],
[33,18,14,3],
[33,18,14,4],
[33,18,14,5],
[33,18,14,6],
[33,18,14,7],
[33,18,14,8],
[33,18,14,10],
[33,18,16,0],
[33,18,16,1],
[33,18,16,2],
[33,18,16,3],
[33,18,16,4],
[33,18,16,5],
[33,18,16,6],
[33,18,16,7],
[33,18,16,8],
[33,18,16,10],
[33,18,16,13],
[33,18,16,14],
[33,18,17,0],
[33,18,17,1],
[33,18,17,2],
[33,18,17,3],
[33,18,17,4],
[33,18,17,5],
[33,18,17,6],
[33,18,17,7],
[33,18,17,8],
[33,18,17,10],
[33,18,17,13],
[33,18,17,14],
[33,18,17,16],
[33,19,3,0],
[33,19,3,1],
[33,19,3,2],
[33,19,4,0],
[33,19,4,1],
[33,19,4,2],
[33,19,4,3],
[33,19,5,0],
[33,19,5,1],
[33,19,5,2],
[33,19,5,4],
[33,19,6,0],
[33,19,6,1],
[33,19,6,2],
[33,19,6,4],
[33,19,7,0],
[33,19,7,1],
[33,19,7,2],
[33,19,7,3],
[33,19,7,4],
[33,19,7,5],
[33,19,7,6],
[33,19,8,0],
[33,19,8,1],
[33,19,8,2],
[33,19,8,4],
[33,19,8,7],
[33,19,9,0],
[33,19,9,1],
[33,19,9,2],
[33,19,9,3],
[33,19,9,4],
[33,19,9,5],
[33,19,9,6],
[33,19,9,7],
[33,19,9,8],
[33,19,10,0],
[33,19,10,1],
[33,19,10,2],
[33,19,10,3],
[33,19,10,4],
[33,19,10,5],
[33,19,10,6],
[33,19,10,7],
[33,19,10,8],
[33,19,10,9],
[33,19,15,0],
[33,19,15,1],
[33,19,15,2],
[33,19,15,3],
[33,19,15,4],
[33,19,15,5],
[33,19,15,6],
[33,19,15,7],
[33,19,15,8],
[33,19,15,10],
[33,19,16,0],
[33,19,16,1],
[33,19,16,2],
[33,19,16,3],
[33,19,16,4],
[33,19,16,5],
[33,19,16,6],
[33,19,16,7],
[33,19,16,8],
[33,19,16,9],
[33,19,16,10],
[33,19,16,15],
[33,19,17,0],
[33,19,17,1],
[33,19,17,2],
[33,19,17,3],
[33,19,17,4],
[33,19,17,5],
[33,19,17,6],
[33,19,17,7],
[33,19,17,8],
[33,19,17,9],
[33,19,17,10],
[33,19,17,15],
[33,19,17,16],
[33,19,18,0],
[33,19,18,1],
[33,19,18,2],
[33,19,18,3],
[33,19,18,4],
[33,19,18,5],
[33,19,18,6],
[33,19,18,7],
[33,19,18,8],
[33,19,18,10],
[33,19,18,16],
[33,19,18,17],
[33,20,3,0],
[33,20,3,1],
[33,20,3,2],
[33,20,4,0],
[33,20,4,1],
[33,20,4,2],
[33,20,4,3],
[33,20,5,0],
[33,20,5,1],
[33,20,5,2],
[33,20,5,4],
[33,20,6,0],
[33,20,6,1],
[33,20,6,2],
[33,20,6,4],
[33,20,7,0],
[33,20,7,1],
[33,20,7,2],
[33,20,7,3],
[33,20,7,4],
[33,20,7,5],
[33,20,7,6],
[33,20,8,0],
[33,20,8,1],
[33,20,8,2],
[33,20,8,4],
[33,20,8,7],
[33,20,9,0],
[33,20,9,1],
[33,20,9,2],
[33,20,9,3],
[33,20,9,4],
[33,20,9,5],
[33,20,9,6],
[33,20,9,7],
[33,20,9,8],
[33,20,13,0],
[33,20,13,1],
[33,20,13,2],
[33,20,13,3],
[33,20,13,4],
[33,20,13,5],
[33,20,13,6],
[33,20,13,7],
[33,20,13,8],
[33,20,13,9],
[33,20,14,0],
[33,20,14,1],
[33,20,14,2],
[33,20,14,3],
[33,20,14,4],
[33,20,14,5],
[33,20,14,6],
[33,20,14,7],
[33,20,14,8],
[33,20,14,9],
[33,20,15,0],
[33,20,15,1],
[33,20,15,2],
[33,20,15,3],
[33,20,15,4],
[33,20,15,5],
[33,20,15,6],
[33,20,15,7],
[33,20,15,8],
[33,20,15,13],
[33,20,15,14],
[33,20,16,0],
[33,20,16,1],
[33,20,16,2],
[33,20,16,3],
[33,20,16,4],
[33,20,16,5],
[33,20,16,6],
[33,20,16,7],
[33,20,16,8],
[33,20,16,9],
[33,20,16,13],
[33,20,16,14],
[33,20,16,15],
[33,20,17,0],
[33,20,17,1],
[33,20,17,2],
[33,20,17,3],
[33,20,17,4],
[33,20,17,5],
[33,20,17,6],
[33,20,17,7],
[33,20,17,8],
[33,20,17,9],
[33,20,17,13],
[33,20,17,14],
[33,20,17,15],
[33,20,17,16],
[33,20,18,0],
[33,20,18,1],
[33,20,18,2],
[33,20,18,3],
[33,20,18,4],
[33,20,18,5],
[33,20,18,6],
[33,20,18,7],
[33,20,18,8],
[33,20,18,13],
[33,20,18,14],
[33,20,18,16],
[33,20,18,17],
[33,20,19,0],
[33,20,19,1],
[33,20,19,2],
[33,20,19,3],
[33,20,19,4],
[33,20,19,5],
[33,20,19,6],
[33,20,19,7],
[33,20,19,8],
[33,20,19,9],
[33,20,19,15],
[33,20,19,16],
[33,20,19,17],
[33,20,19,18],
[33,21,4,0],
[33,21,4,1],
[33,21,4,2],
[33,21,7,0],
[33,21,7,1],
[33,21,7,2],
[33,21,7,4],
[33,21,9,0],
[33,21,9,1],
[33,21,9,2],
[33,21,9,4],
[33,21,9,7],
[33,21,10,0],
[33,21,10,1],
[33,21,10,2],
[33,21,10,4],
[33,21,10,7],
[33,21,10,9],
[33,21,13,0],
[33,21,13,1],
[33,21,13,2],
[33,21,13,4],
[33,21,13,7],
[33,21,13,9],
[33,21,13,10],
[33,21,14,0],
[33,21,14,1],
[33,21,14,2],
[33,21,14,4],
[33,21,14,7],
[33,21,14,9],
[33,21,14,10],
[33,21,15,0],
[33,21,15,1],
[33,21,15,2],
[33,21,15,4],
[33,21,15,7],
[33,21,15,10],
[33,21,15,13],
[33,21,15,14],
[33,21,16,0],
[33,21,16,1],
[33,21,16,2],
[33,21,16,4],
[33,21,16,7],
[33,21,16,9],
[33,21,16,10],
[33,21,16,13],
[33,21,16,14],
[33,21,16,15],
[33,21,17,0],
[33,21,17,1],
[33,21,17,2],
[33,21,17,4],
[33,21,17,7],
[33,21,17,9],
[33,21,17,10],
[33,21,17,13],
[33,21,17,14],
[33,21,17,15],
[33,21,17,16],
[33,21,18,0],
[33,21,18,1],
[33,21,18,2],
[33,21,18,4],
[33,21,18,7],
[33,21,18,10],
[33,21,18,13],
[33,21,18,14],
[33,21,18,16],
[33,21,18,17],
[33,21,19,0],
[33,21,19,1],
[33,21,19,2],
[33,21,19,4],
[33,21,19,7],
[33,21,19,9],
[33,21,19,10],
[33,21,19,15],
[33,21,19,16],
[33,21,19,17],
[33,21,19,18],
[33,21,20,0],
[33,21,20,1],
[33,21,20,2],
[33,21,20,4],
[33,21,20,7],
[33,21,20,9],
[33,21,20,13],
[33,21,20,14],
[33,21,20,15],
[33,21,20,16],
[33,21,20,17],
[33,21,20,18],
[33,21,20,19],
[33,22,3,0],
[33,22,3,1],
[33,22,3,2],
[33,22,4,0],
[33,22,4,1],
[33,22,4,2],
[33,22,4,3],
[33,22,5,0],
[33,22,5,1],
[33,22,5,2],
[33,22,5,4],
[33,22,6,0],
[33,22,6,1],
[33,22,6,2],
[33,22,6,4],
[33,22,7,0],
[33,22,7,1],
[33,22,7,2],
[33,22,7,3],
[33,22,7,4],
[33,22,7,5],
[33,22,7,6],
[33,22,8,0],
[33,22,8,1],
[33,22,8,2],
[33,22,8,4],
[33,22,8,7],
[33,22,9,0],
[33,22,9,1],
[33,22,9,2],
[33,22,9,3],
[33,22,9,4],
[33,22,9,5],
[33,22,9,6],
[33,22,9,7],
[33,22,9,8],
[33,22,13,0],
[33,22,13,1],
[33,22,13,2],
[33,22,13,3],
[33,22,13,4],
[33,22,13,5],
[33,22,13,6],
[33,22,13,7],
[33,22,13,8],
[33,22,13,9],
[33,22,14,0],
[33,22,14,1],
[33,22,14,2],
[33,22,14,3],
[33,22,14,4],
[33,22,14,5],
[33,22,14,6],
[33,22,14,7],
[33,22,14,8],
[33,22,14,9],
[33,22,15,0],
[33,22,15,1],
[33,22,15,2],
[33,22,15,3],
[33,22,15,4],
[33,22,15,5],
[33,22,15,6],
[33,22,15,7],
[33,22,15,8],
[33,22,15,13],
[33,22,15,14],
[33,22,16,0],
[33,22,16,1],
[33,22,16,2],
[33,22,16,3],
[33,22,16,4],
[33,22,16,5],
[33,22,16,6],
[33,22,16,7],
[33,22,16,8],
[33,22,16,9],
[33,22,16,13],
[33,22,16,14],
[33,22,16,15],
[33,22,17,0],
[33,22,17,1],
[33,22,17,2],
[33,22,17,3],
[33,22,17,4],
[33,22,17,5],
[33,22,17,6],
[33,22,17,7],
[33,22,17,8],
[33,22,17,9],
[33,22,17,13],
[33,22,17,14],
[33,22,17,15],
[33,22,17,16],
[33,22,18,0],
[33,22,18,1],
[33,22,18,2],
[33,22,18,3],
[33,22,18,4],
[33,22,18,5],
[33,22,18,6],
[33,22,18,7],
[33,22,18,8],
[33,22,18,13],
[33,22,18,14],
[33,22,18,16],
[33,22,18,17],
[33,22,19,0],
[33,22,19,1],
[33,22,19,2],
[33,22,19,3],
[33,22,19,4],
[33,22,19,5],
[33,22,19,6],
[33,22,19,7],
[33,22,19,8],
[33,22,19,9],
[33,22,19,15],
[33,22,19,16],
[33,22,19,17],
[33,22,19,18],
[33,22,21,0],
[33,22,21,1],
[33,22,21,2],
[33,22,21,4],
[33,22,21,7],
[33,22,21,9],
[33,22,21,13],
[33,22,21,14],
[33,22,21,15],
[33,22,21,16],
[33,22,21,17],
[33,22,21,18],
[33,22,21,19],
[33,23,3,0],
[33,23,3,1],
[33,23,3,2],
[33,23,4,0],
[33,23,4,1],
[33,23,4,2],
[33,23,4,3],
[33,23,5,0],
[33,23,5,1],
[33,23,5,2],
[33,23,5,4],
[33,23,6,0],
[33,23,6,1],
[33,23,6,2],
[33,23,6,4],
[33,23,7,0],
[33,23,7,1],
[33,23,7,2],
[33,23,7,3],
[33,23,7,4],
[33,23,7,5],
[33,23,7,6],
[33,23,8,0],
[33,23,8,1],
[33,23,8,2],
[33,23,8,4],
[33,23,8,7],
[33,23,9,0],
[33,23,9,1],
[33,23,9,2],
[33,23,9,3],
[33,23,9,4],
[33,23,9,5],
[33,23,9,6],
[33,23,9,7],
[33,23,9,8],
[33,23,10,0],
[33,23,10,1],
[33,23,10,2],
[33,23,10,3],
[33,23,10,4],
[33,23,10,5],
[33,23,10,6],
[33,23,10,7],
[33,23,10,8],
[33,23,10,9],
[33,23,13,0],
[33,23,13,1],
[33,23,13,2],
[33,23,13,3],
[33,23,13,4],
[33,23,13,5],
[33,23,13,6],
[33,23,13,7],
[33,23,13,8],
[33,23,13,9],
[33,23,13,10],
[33,23,14,0],
[33,23,14,1],
[33,23,14,2],
[33,23,14,3],
[33,23,14,4],
[33,23,14,5],
[33,23,14,6],
[33,23,14,7],
[33,23,14,8],
[33,23,14,9],
[33,23,14,10],
[33,23,15,0],
[33,23,15,1],
[33,23,15,2],
[33,23,15,3],
[33,23,15,4],
[33,23,15,5],
[33,23,15,6],
[33,23,15,7],
[33,23,15,8],
[33,23,15,10],
[33,23,15,13],
[33,23,15,14],
[33,23,16,0],
[33,23,16,1],
[33,23,16,2],
[33,23,16,3],
[33,23,16,4],
[33,23,16,5],
[33,23,16,6],
[33,23,16,7],
[33,23,16,8],
[33,23,16,9],
[33,23,16,10],
[33,23,16,13],
[33,23,16,14],
[33,23,16,15],
[33,23,17,0],
[33,23,17,1],
[33,23,17,2],
[33,23,17,3],
[33,23,17,4],
[33,23,17,5],
[33,23,17,6],
[33,23,17,7],
[33,23,17,8],
[33,23,17,9],
[33,23,17,10],
[33,23,17,13],
[33,23,17,14],
[33,23,17,15],
[33,23,17,16],
[33,23,18,0],
[33,23,18,1],
[33,23,18,2],
[33,23,18,3],
[33,23,18,4],
[33,23,18,5],
[33,23,18,6],
[33,23,18,7],
[33,23,18,8],
[33,23,18,10],
[33,23,18,13],
[33,23,18,14],
[33,23,18,16],
[33,23,18,17],
[33,23,19,0],
[33,23,19,1],
[33,23,19,2],
[33,23,19,3],
[33,23,19,4],
[33,23,19,5],
[33,23,19,6],
[33,23,19,7],
[33,23,19,8],
[33,23,19,9],
[33,23,19,10],
[33,23,19,15],
[33,23,19,16],
[33,23,19,17],
[33,23,19,18],
[33,23,20,0],
[33,23,20,1],
[33,23,20,2],
[33,23,20,3],
[33,23,20,4],
[33,23,20,5],
[33,23,20,6],
[33,23,20,7],
[33,23,20,8],
[33,23,20,9],
[33,23,20,13],
[33,23,20,14],
[33,23,20,15],
[33,23,20,16],
[33,23,20,17],
[33,23,20,18],
[33,23,20,19],
[33,23,21,0],
[33,23,21,1],
[33,23,21,2],
[33,23,21,4],
[33,23,21,7],
[33,23,21,9],
[33,23,21,10],
[33,23,21,13],
[33,23,21,14],
[33,23,21,15],
[33,23,21,16],
[33,23,21,17],
[33,23,21,18],
[33,23,21,19],
[33,23,21,20],
[33,23,22,0],
[33,23,22,1],
[33,23,22,2],
[33,23,22,3],
[33,23,22,4],
[33,23,22,5],
[33,23,22,6],
[33,23,22,7],
[33,23,22,8],
[33,23,22,9],
[33,23,22,13],
[33,23,22,14],
[33,23,22,15],
[33,23,22,16],
[33,23,22,17],
[33,23,22,18],
[33,23,22,19],
[33,23,22,21],
[33,24,3,0],
[33,24,3,1],
[33,24,3,2],
[33,24,4,0],
[33,24,4,1],
[33,24,4,2],
[33,24,4,3],
[33,24,5,0],
[33,24,5,1],
[33,24,5,2],
[33,24,5,4],
[33,24,6,0],
[33,24,6,1],
[33,24,6,2],
[33,24,6,4],
[33,24,7,0],
[33,24,7,1],
[33,24,7,2],
[33,24,7,3],
[33,24,7,4],
[33,24,7,5],
[33,24,7,6],
[33,24,8,0],
[33,24,8,1],
[33,24,8,2],
[33,24,8,4],
[33,24,8,7],
[33,24,9,0],
[33,24,9,1],
[33,24,9,2],
[33,24,9,3],
[33,24,9,4],
[33,24,9,5],
[33,24,9,6],
[33,24,9,7],
[33,24,9,8],
[33,24,10,0],
[33,24,10,1],
[33,24,10,2],
[33,24,10,3],
[33,24,10,4],
[33,24,10,5],
[33,24,10,6],
[33,24,10,7],
[33,24,10,8],
[33,24,10,9],
[33,24,13,0],
[33,24,13,1],
[33,24,13,2],
[33,24,13,3],
[33,24,13,4],
[33,24,13,5],
[33,24,13,6],
[33,24,13,7],
[33,24,13,8],
[33,24,13,9],
[33,24,13,10],
[33,24,14,0],
[33,24,14,1],
[33,24,14,2],
[33,24,14,3],
[33,24,14,4],
[33,24,14,5],
[33,24,14,6],
[33,24,14,7],
[33,24,14,8],
[33,24,14,9],
[33,24,14,10],
[33,24,15,0],
[33,24,15,1],
[33,24,15,2],
[33,24,15,3],
[33,24,15,4],
[33,24,15,5],
[33,24,15,6],
[33,24,15,7],
[33,24,15,8],
[33,24,15,10],
[33,24,15,13],
[33,24,15,14],
[33,24,17,0],
[33,24,17,1],
[33,24,17,2],
[33,24,17,3],
[33,24,17,4],
[33,24,17,5],
[33,24,17,6],
[33,24,17,7],
[33,24,17,8],
[33,24,17,9],
[33,24,17,10],
[33,24,17,13],
[33,24,17,14],
[33,24,17,15],
[33,24,18,0],
[33,24,18,1],
[33,24,18,2],
[33,24,18,3],
[33,24,18,4],
[33,24,18,5],
[33,24,18,6],
[33,24,18,7],
[33,24,18,8],
[33,24,18,10],
[33,24,18,13],
[33,24,18,14],
[33,24,18,17],
[33,24,19,0],
[33,24,19,1],
[33,24,19,2],
[33,24,19,3],
[33,24,19,4],
[33,24,19,5],
[33,24,19,6],
[33,24,19,7],
[33,24,19,8],
[33,24,19,9],
[33,24,19,10],
[33,24,19,15],
[33,24,19,17],
[33,24,19,18],
[33,24,20,0],
[33,24,20,1],
[33,24,20,2],
[33,24,20,3],
[33,24,20,4],
[33,24,20,5],
[33,24,20,6],
[33,24,20,7],
[33,24,20,8],
[33,24,20,9],
[33,24,20,13],
[33,24,20,14],
[33,24,20,15],
[33,24,20,17],
[33,24,20,18],
[33,24,20,19],
[33,24,21,0],
[33,24,21,1],
[33,24,21,2],
[33,24,21,4],
[33,24,21,7],
[33,24,21,9],
[33,24,21,10],
[33,24,21,13],
[33,24,21,14],
[33,24,21,15],
[33,24,21,17],
[33,24,21,18],
[33,24,21,19],
[33,24,21,20],
[33,24,22,0],
[33,24,22,1],
[33,24,22,2],
[33,24,22,3],
[33,24,22,4],
[33,24,22,5],
[33,24,22,6],
[33,24,22,7],
[33,24,22,8],
[33,24,22,9],
[33,24,22,13],
[33,24,22,14],
[33,24,22,15],
[33,24,22,17],
[33,24,22,18],
[33,24,22,19],
[33,24,22,21],
[33,24,23,0],
[33,24,23,1],
[33,24,23,2],
[33,24,23,3],
[33,24,23,4],
[33,24,23,5],
[33,24,23,6],
[33,24,23,7],
[33,24,23,8],
[33,24,23,9],
[33,24,23,10],
[33,24,23,13],
[33,24,23,14],
[33,24,23,15],
[33,24,23,17],
[33,24,23,18],
[33,24,23,19],
[33,24,23,20],
[33,24,23,21],
[33,24,23,22],
[33,25,3,0],
[33,25,3,1],
[33,25,3,2],
[33,25,4,0],
[33,25,4,1],
[33,25,4,2],
[33,25,4,3],
[33,25,5,0],
[33,25,5,1],
[33,25,5,2],
[33,25,5,4],
[33,25,6,0],
[33,25,6,1],
[33,25,6,2],
[33,25,6,4],
[33,25,8,0],
[33,25,8,1],
[33,25,8,2],
[33,25,8,4],
[33,25,9,0],
[33,25,9,1],
[33,25,9,2],
[33,25,9,3],
[33,25,9,4],
[33,25,9,5],
[33,25,9,6],
[33,25,9,8],
[33,25,10,0],
[33,25,10,1],
[33,25,10,2],
[33,25,10,3],
[33,25,10,4],
[33,25,10,5],
[33,25,10,6],
[33,25,10,8],
[33,25,10,9],
[33,25,13,0],
[33,25,13,1],
[33,25,13,2],
[33,25,13,3],
[33,25,13,4],
[33,25,13,5],
[33,25,13,6],
[33,25,13,8],
[33,25,13,9],
[33,25,13,10],
[33,25,14,0],
[33,25,14,1],
[33,25,14,2],
[33,25,14,3],
[33,25,14,4],
[33,25,14,5],
[33,25,14,6],
[33,25,14,8],
[33,25,14,9],
[33,25,14,10],
[33,25,15,0],
[33,25,15,1],
[33,25,15,2],
[33,25,15,3],
[33,25,15,4],
[33,25,15,5],
[33,25,15,6],
[33,25,15,8],
[33,25,15,10],
[33,25,15,13],
[33,25,15,14],
[33,25,16,0],
[33,25,16,1],
[33,25,16,2],
[33,25,16,3],
[33,25,16,4],
[33,25,16,5],
[33,25,16,6],
[33,25,16,8],
[33,25,16,9],
[33,25,16,10],
[33,25,16,13],
[33,25,16,14],
[33,25,16,15],
[33,25,17,0],
[33,25,17,1],
[33,25,17,2],
[33,25,17,3],
[33,25,17,4],
[33,25,17,5],
[33,25,17,6],
[33,25,17,8],
[33,25,17,9],
[33,25,17,10],
[33,25,17,13],
[33,25,17,14],
[33,25,17,15],
[33,25,17,16],
[33,25,18,0],
[33,25,18,1],
[33,25,18,2],
[33,25,18,3],
[33,25,18,4],
[33,25,18,5],
[33,25,18,6],
[33,25,18,8],
[33,25,18,10],
[33,25,18,13],
[33,25,18,14],
[33,25,18,16],
[33,25,18,17],
[33,25,19,0],
[33,25,19,1],
[33,25,19,2],
[33,25,19,3],
[33,25,19,4],
[33,25,19,5],
[33,25,19,6],
[33,25,19,8],
[33,25,19,9],
[33,25,19,10],
[33,25,19,15],
[33,25,19,16],
[33,25,19,17],
[33,25,19,18],
[33,25,20,0],
[33,25,20,1],
[33,25,20,2],
[33,25,20,3],
[33,25,20,4],
[33,25,20,5],
[33,25,20,6],
[33,25,20,8],
[33,25,20,9],
[33,25,20,13],
[33,25,20,14],
[33,25,20,15],
[33,25,20,16],
[33,25,20,17],
[33,25,20,18],
[33,25,20,19],
[33,25,21,0],
[33,25,21,1],
[33,25,21,2],
[33,25,21,4],
[33,25,21,9],
[33,25,21,10],
[33,25,21,13],
[33,25,21,14],
[33,25,21,15],
[33,25,21,16],
[33,25,21,17],
[33,25,21,18],
[33,25,21,19],
[33,25,21,20],
[33,25,22,0],
[33,25,22,1],
[33,25,22,2],
[33,25,22,3],
[33,25,22,4],
[33,25,22,5],
[33,25,22,6],
[33,25,22,8],
[33,25,22,9],
[33,25,22,13],
[33,25,22,14],
[33,25,22,15],
[33,25,22,16],
[33,25,22,17],
[33,25,22,18],
[33,25,22,19],
[33,25,22,21],
[33,25,23,0],
[33,25,23,1],
[33,25,23,2],
[33,25,23,3],
[33,25,23,4],
[33,25,23,5],
[33,25,23,6],
[33,25,23,8],
[33,25,23,9],
[33,25,23,10],
[33,25,23,13],
[33,25,23,14],
[33,25,23,15],
[33,25,23,16],
[33,25,23,17],
[33,25,23,18],
[33,25,23,19],
[33,25,23,20],
[33,25,23,21],
[33,25,23,22],
[33,25,24,0],
[33,25,24,1],
[33,25,24,2],
[33,25,24,3],
[33,25,24,4],
[33,25,24,5],
[33,25,24,6],
[33,25,24,8],
[33,25,24,9],
[33,25,24,10],
[33,25,24,13],
[33,25,24,14],
[33,25,24,15],
[33,25,24,17],
[33,25,24,18],
[33,25,24,19],
[33,25,24,20],
[33,25,24,21],
[33,25,24,22],
[33,25,24,23],
[33,26,3,0],
[33,26,3,1],
[33,26,3,2],
[33,26,4,0],
[33,26,4,1],
[33,26,4,2],
[33,26,4,3],
[33,26,5,0],
[33,26,5,1],
[33,26,5,2],
[33,26,5,4],
[33,26,6,0],
[33,26,6,1],
[33,26,6,2],
[33,26,6,4],
[33,26,7,0],
[33,26,7,1],
[33,26,7,2],
[33,26,7,3],
[33,26,7,4],
[33,26,7,5],
[33,26,7,6],
[33,26,8,0],
[33,26,8,1],
[33,26,8,2],
[33,26,8,4],
[33,26,8,7],
[33,26,10,0],
[33,26,10,1],
[33,26,10,2],
[33,26,10,3],
[33,26,10,4],
[33,26,10,5],
[33,26,10,6],
[33,26,10,7],
[33,26,10,8],
[33,26,13,0],
[33,26,13,1],
[33,26,13,2],
[33,26,13,3],
[33,26,13,4],
[33,26,13,5],
[33,26,13,6],
[33,26,13,7],
[33,26,13,8],
[33,26,13,10],
[33,26,14,0],
[33,26,14,1],
[33,26,14,2],
[33,26,14,3],
[33,26,14,4],
[33,26,14,5],
[33,26,14,6],
[33,26,14,7],
[33,26,14,8],
[33,26,14,10],
[33,26,16,0],
[33,26,16,1],
[33,26,16,2],
[33,26,16,3],
[33,26,16,4],
[33,26,16,5],
[33,26,16,6],
[33,26,16,7],
[33,26,16,8],
[33,26,16,10],
[33,26,16,13],
[33,26,16,14],
[33,26,17,0],
[33,26,17,1],
[33,26,17,2],
[33,26,17,3],
[33,26,17,4],
[33,26,17,5],
[33,26,17,6],
[33,26,17,7],
[33,26,17,8],
[33,26,17,10],
[33,26,17,13],
[33,26,17,14],
[33,26,17,16],
[33,26,19,0],
[33,26,19,1],
[33,26,19,2],
[33,26,19,3],
[33,26,19,4],
[33,26,19,5],
[33,26,19,6],
[33,26,19,7],
[33,26,19,8],
[33,26,19,10],
[33,26,19,16],
[33,26,19,17],
[33,26,20,0],
[33,26,20,1],
[33,26,20,2],
[33,26,20,3],
[33,26,20,4],
[33,26,20,5],
[33,26,20,6],
[33,26,20,7],
[33,26,20,8],
[33,26,20,13],
[33,26,20,14],
[33,26,20,16],
[33,26,20,17],
[33,26,20,19],
[33,26,21,0],
[33,26,21,1],
[33,26,21,2],
[33,26,21,4],
[33,26,21,7],
[33,26,21,10],
[33,26,21,13],
[33,26,21,14],
[33,26,21,16],
[33,26,21,17],
[33,26,21,19],
[33,26,21,20],
[33,26,22,0],
[33,26,22,1],
[33,26,22,2],
[33,26,22,3],
[33,26,22,4],
[33,26,22,5],
[33,26,22,6],
[33,26,22,7],
[33,26,22,8],
[33,26,22,13],
[33,26,22,14],
[33,26,22,16],
[33,26,22,17],
[33,26,22,19],
[33,26,22,21],
[33,26,23,0],
[33,26,23,1],
[33,26,23,2],
[33,26,23,3],
[33,26,23,4],
[33,26,23,5],
[33,26,23,6],
[33,26,23,7],
[33,26,23,8],
[33,26,23,10],
[33,26,23,13],
[33,26,23,14],
[33,26,23,16],
[33,26,23,17],
[33,26,23,19],
[33,26,23,20],
[33,26,23,21],
[33,26,23,22],
[33,26,24,0],
[33,26,24,1],
[33,26,24,2],
[33,26,24,3],
[33,26,24,4],
[33,26,24,5],
[33,26,24,6],
[33,26,24,7],
[33,26,24,8],
[33,26,24,10],
[33,26,24,13],
[33,26,24,14],
[33,26,24,17],
[33,26,24,19],
[33,26,24,20],
[33,26,24,21],
[33,26,24,22],
[33,26,24,23],
[33,26,25,0],
[33,26,25,1],
[33,26,25,2],
[33,26,25,3],
[33,26,25,4],
[33,26,25,5],
[33,26,25,6],
[33,26,25,8],
[33,26,25,10],
[33,26,25,13],
[33,26,25,14],
[33,26,25,16],
[33,26,25,17],
[33,26,25,19],
[33,26,25,20],
[33,26,25,21],
[33,26,25,22],
[33,26,25,23],
[33,26,25,24],
[33,27,3,0],
[33,27,3,1],
[33,27,3,2],
[33,27,4,0],
[33,27,4,1],
[33,27,4,2],
[33,27,4,3],
[33,27,5,0],
[33,27,5,1],
[33,27,5,2],
[33,27,5,4],
[33,27,6,0],
[33,27,6,1],
[33,27,6,2],
[33,27,6,4],
[33,27,7,0],
[33,27,7,1],
[33,27,7,2],
[33,27,7,3],
[33,27,7,4],
[33,27,7,5],
[33,27,7,6],
[33,27,8,0],
[33,27,8,1],
[33,27,8,2],
[33,27,8,4],
[33,27,8,7],
[33,27,9,0],
[33,27,9,1],
[33,27,9,2],
[33,27,9,3],
[33,27,9,4],
[33,27,9,5],
[33,27,9,6],
[33,27,9,7],
[33,27,9,8],
[33,27,13,0],
[33,27,13,1],
[33,27,13,2],
[33,27,13,3],
[33,27,13,4],
[33,27,13,5],
[33,27,13,6],
[33,27,13,7],
[33,27,13,8],
[33,27,13,9],
[33,27,14,0],
[33,27,14,1],
[33,27,14,2],
[33,27,14,3],
[33,27,14,4],
[33,27,14,5],
[33,27,14,6],
[33,27,14,7],
[33,27,14,8],
[33,27,14,9],
[33,27,15,0],
[33,27,15,1],
[33,27,15,2],
[33,27,15,3],
[33,27,15,4],
[33,27,15,5],
[33,27,15,6],
[33,27,15,7],
[33,27,15,8],
[33,27,15,13],
[33,27,15,14],
[33,27,16,0],
[33,27,16,1],
[33,27,16,2],
[33,27,16,3],
[33,27,16,4],
[33,27,16,5],
[33,27,16,6],
[33,27,16,7],
[33,27,16,8],
[33,27,16,9],
[33,27,16,13],
[33,27,16,14],
[33,27,16,15],
[33,27,17,0],
[33,27,17,1],
[33,27,17,2],
[33,27,17,3],
[33,27,17,4],
[33,27,17,5],
[33,27,17,6],
[33,27,17,7],
[33,27,17,8],
[33,27,17,9],
[33,27,17,13],
[33,27,17,14],
[33,27,17,15],
[33,27,17,16],
[33,27,18,0],
[33,27,18,1],
[33,27,18,2],
[33,27,18,3],
[33,27,18,4],
[33,27,18,5],
[33,27,18,6],
[33,27,18,7],
[33,27,18,8],
[33,27,18,13],
[33,27,18,14],
[33,27,18,16],
[33,27,18,17],
[33,27,19,0],
[33,27,19,1],
[33,27,19,2],
[33,27,19,3],
[33,27,19,4],
[33,27,19,5],
[33,27,19,6],
[33,27,19,7],
[33,27,19,8],
[33,27,19,9],
[33,27,19,15],
[33,27,19,16],
[33,27,19,17],
[33,27,19,18],
[33,27,21,0],
[33,27,21,1],
[33,27,21,2],
[33,27,21,4],
[33,27,21,7],
[33,27,21,9],
[33,27,21,13],
[33,27,21,14],
[33,27,21,15],
[33,27,21,16],
[33,27,21,17],
[33,27,21,18],
[33,27,21,19],
[33,27,23,0],
[33,27,23,1],
[33,27,23,2],
[33,27,23,3],
[33,27,23,4],
[33,27,23,5],
[33,27,23,6],
[33,27,23,7],
[33,27,23,8],
[33,27,23,9],
[33,27,23,13],
[33,27,23,14],
[33,27,23,15],
[33,27,23,16],
[33,27,23,17],
[33,27,23,18],
[33,27,23,19],
[33,27,23,21],
[33,27,24,0],
[33,27,24,1],
[33,27,24,2],
[33,27,24,3],
[33,27,24,4],
[33,27,24,5],
[33,27,24,6],
[33,27,24,7],
[33,27,24,8],
[33,27,24,9],
[33,27,24,13],
[33,27,24,14],
[33,27,24,15],
[33,27,24,17],
[33,27,24,18],
[33,27,24,19],
[33,27,24,21],
[33,27,24,23],
[33,27,25,0],
[33,27,25,1],
[33,27,25,2],
[33,27,25,3],
[33,27,25,4],
[33,27,25,5],
[33,27,25,6],
[33,27,25,8],
[33,27,25,9],
[33,27,25,13],
[33,27,25,14],
[33,27,25,15],
[33,27,25,16],
[33,27,25,17],
[33,27,25,18],
[33,27,25,19],
[33,27,25,21],
[33,27,25,23],
[33,27,25,24],
[33,27,26,0],
[33,27,26,1],
[33,27,26,2],
[33,27,26,3],
[33,27,26,4],
[33,27,26,5],
[33,27,26,6],
[33,27,26,7],
[33,27,26,8],
[33,27,26,13],
[33,27,26,14],
[33,27,26,16],
[33,27,26,17],
[33,27,26,19],
[33,27,26,21],
[33,27,26,23],
[33,27,26,24],
[33,27,26,25],
[33,28,3,0],
[33,28,3,1],
[33,28,3,2],
[33,28,4,0],
[33,28,4,1],
[33,28,4,2],
[33,28,4,3],
[33,28,5,0],
[33,28,5,1],
[33,28,5,2],
[33,28,5,4],
[33,28,6,0],
[33,28,6,1],
[33,28,6,2],
[33,28,6,4],
[33,28,8,0],
[33,28,8,1],
[33,28,8,2],
[33,28,8,4],
[33,28,9,0],
[33,28,9,1],
[33,28,9,2],
[33,28,9,3],
[33,28,9,4],
[33,28,9,5],
[33,28,9,6],
[33,28,9,8],
[33,28,10,0],
[33,28,10,1],
[33,28,10,2],
[33,28,10,3],
[33,28,10,4],
[33,28,10,5],
[33,28,10,6],
[33,28,10,8],
[33,28,10,9],
[33,28,13,0],
[33,28,13,1],
[33,28,13,2],
[33,28,13,3],
[33,28,13,4],
[33,28,13,5],
[33,28,13,6],
[33,28,13,8],
[33,28,13,9],
[33,28,13,10],
[33,28,14,0],
[33,28,14,1],
[33,28,14,2],
[33,28,14,3],
[33,28,14,4],
[33,28,14,5],
[33,28,14,6],
[33,28,14,8],
[33,28,14,9],
[33,28,14,10],
[33,28,15,0],
[33,28,15,1],
[33,28,15,2],
[33,28,15,3],
[33,28,15,4],
[33,28,15,5],
[33,28,15,6],
[33,28,15,8],
[33,28,15,10],
[33,28,15,13],
[33,28,15,14],
[33,28,16,0],
[33,28,16,1],
[33,28,16,2],
[33,28,16,3],
[33,28,16,4],
[33,28,16,5],
[33,28,16,6],
[33,28,16,8],
[33,28,16,9],
[33,28,16,10],
[33,28,16,13],
[33,28,16,14],
[33,28,16,15],
[33,28,17,0],
[33,28,17,1],
[33,28,17,2],
[33,28,17,3],
[33,28,17,4],
[33,28,17,5],
[33,28,17,6],
[33,28,17,8],
[33,28,17,9],
[33,28,17,10],
[33,28,17,13],
[33,28,17,14],
[33,28,17,15],
[33,28,17,16],
[33,28,18,0],
[33,28,18,1],
[33,28,18,2],
[33,28,18,3],
[33,28,18,4],
[33,28,18,5],
[33,28,18,6],
[33,28,18,8],
[33,28,18,10],
[33,28,18,13],
[33,28,18,14],
[33,28,18,16],
[33,28,18,17],
[33,28,19,0],
[33,28,19,1],
[33,28,19,2],
[33,28,19,3],
[33,28,19,4],
[33,28,19,5],
[33,28,19,6],
[33,28,19,8],
[33,28,19,9],
[33,28,19,10],
[33,28,19,15],
[33,28,19,16],
[33,28,19,17],
[33,28,19,18],
[33,28,20,0],
[33,28,20,1],
[33,28,20,2],
[33,28,20,3],
[33,28,20,4],
[33,28,20,5],
[33,28,20,6],
[33,28,20,8],
[33,28,20,9],
[33,28,20,13],
[33,28,20,14],
[33,28,20,15],
[33,28,20,16],
[33,28,20,17],
[33,28,20,18],
[33,28,20,19],
[33,28,21,0],
[33,28,21,1],
[33,28,21,2],
[33,28,21,4],
[33,28,21,9],
[33,28,21,10],
[33,28,21,13],
[33,28,21,14],
[33,28,21,15],
[33,28,21,16],
[33,28,21,17],
[33,28,21,18],
[33,28,21,19],
[33,28,21,20],
[33,28,22,0],
[33,28,22,1],
[33,28,22,2],
[33,28,22,3],
[33,28,22,4],
[33,28,22,5],
[33,28,22,6],
[33,28,22,8],
[33,28,22,9],
[33,28,22,13],
[33,28,22,14],
[33,28,22,15],
[33,28,22,16],
[33,28,22,17],
[33,28,22,18],
[33,28,22,19],
[33,28,22,21],
[33,28,23,0],
[33,28,23,1],
[33,28,23,2],
[33,28,23,3],
[33,28,23,4],
[33,28,23,5],
[33,28,23,6],
[33,28,23,8],
[33,28,23,9],
[33,28,23,10],
[33,28,23,13],
[33,28,23,14],
[33,28,23,15],
[33,28,23,16],
[33,28,23,17],
[33,28,23,18],
[33,28,23,19],
[33,28,23,20],
[33,28,23,21],
[33,28,23,22],
[33,28,24,0],
[33,28,24,1],
[33,28,24,2],
[33,28,24,3],
[33,28,24,4],
[33,28,24,5],
[33,28,24,6],
[33,28,24,8],
[33,28,24,9],
[33,28,24,10],
[33,28,24,13],
[33,28,24,14],
[33,28,24,15],
[33,28,24,17],
[33,28,24,18],
[33,28,24,19],
[33,28,24,20],
[33,28,24,21],
[33,28,24,22],
[33,28,24,23],
[33,28,26,0],
[33,28,26,1],
[33,28,26,2],
[33,28,26,3],
[33,28,26,4],
[33,28,26,5],
[33,28,26,6],
[33,28,26,8],
[33,28,26,10],
[33,28,26,13],
[33,28,26,14],
[33,28,26,16],
[33,28,26,17],
[33,28,26,19],
[33,28,26,20],
[33,28,26,21],
[33,28,26,22],
[33,28,26,23],
[33,28,26,24],
[33,28,27,0],
[33,28,27,1],
[33,28,27,2],
[33,28,27,3],
[33,28,27,4],
[33,28,27,5],
[33,28,27,6],
[33,28,27,8],
[33,28,27,9],
[33,28,27,13],
[33,28,27,14],
[33,28,27,15],
[33,28,27,16],
[33,28,27,17],
[33,28,27,18],
[33,28,27,19],
[33,28,27,21],
[33,28,27,23],
[33,28,27,24],
[33,28,27,26],
[33,30,3,0],
[33,30,3,1],
[33,30,3,2],
[33,30,4,0],
[33,30,4,1],
[33,30,4,2],
[33,30,4,3],
[33,30,5,0],
[33,30,5,1],
[33,30,5,2],
[33,30,5,4],
[33,30,6,0],
[33,30,6,1],
[33,30,6,2],
[33,30,6,4],
[33,30,7,0],
[33,30,7,1],
[33,30,7,2],
[33,30,7,3],
[33,30,7,4],
[33,30,7,5],
[33,30,7,6],
[33,30,8,0],
[33,30,8,1],
[33,30,8,2],
[33,30,8,4],
[33,30,8,7],
[33,30,9,0],
[33,30,9,1],
[33,30,9,2],
[33,30,9,3],
[33,30,9,4],
[33,30,9,5],
[33,30,9,6],
[33,30,9,7],
[33,30,9,8],
[33,30,10,0],
[33,30,10,1],
[33,30,10,2],
[33,30,10,3],
[33,30,10,4],
[33,30,10,5],
[33,30,10,6],
[33,30,10,7],
[33,30,10,8],
[33,30,10,9],
[33,30,13,0],
[33,30,13,1],
[33,30,13,2],
[33,30,13,3],
[33,30,13,4],
[33,30,13,5],
[33,30,13,6],
[33,30,13,7],
[33,30,13,8],
[33,30,13,9],
[33,30,13,10],
[33,30,14,0],
[33,30,14,1],
[33,30,14,2],
[33,30,14,3],
[33,30,14,4],
[33,30,14,5],
[33,30,14,6],
[33,30,14,7],
[33,30,14,8],
[33,30,14,9],
[33,30,14,10],
[33,30,15,0],
[33,30,15,1],
[33,30,15,2],
[33,30,15,3],
[33,30,15,4],
[33,30,15,5],
[33,30,15,6],
[33,30,15,7],
[33,30,15,8],
[33,30,15,10],
[33,30,15,13],
[33,30,15,14],
[33,30,16,0],
[33,30,16,1],
[33,30,16,2],
[33,30,16,3],
[33,30,16,4],
[33,30,16,5],
[33,30,16,6],
[33,30,16,7],
[33,30,16,8],
[33,30,16,9],
[33,30,16,10],
[33,30,16,13],
[33,30,16,14],
[33,30,16,15],
[33,30,17,0],
[33,30,17,1],
[33,30,17,2],
[33,30,17,3],
[33,30,17,4],
[33,30,17,5],
[33,30,17,6],
[33,30,17,7],
[33,30,17,8],
[33,30,17,9],
[33,30,17,10],
[33,30,17,13],
[33,30,17,14],
[33,30,17,15],
[33,30,17,16],
[33,30,18,0],
[33,30,18,1],
[33,30,18,2],
[33,30,18,3],
[33,30,18,4],
[33,30,18,5],
[33,30,18,6],
[33,30,18,7],
[33,30,18,8],
[33,30,18,10],
[33,30,18,13],
[33,30,18,14],
[33,30,18,16],
[33,30,18,17],
[33,30,19,0],
[33,30,19,1],
[33,30,19,2],
[33,30,19,3],
[33,30,19,4],
[33,30,19,5],
[33,30,19,6],
[33,30,19,7],
[33,30,19,8],
[33,30,19,9],
[33,30,19,10],
[33,30,19,15],
[33,30,19,16],
[33,30,19,17],
[33,30,19,18],
[33,30,20,0],
[33,30,20,1],
[33,30,20,2],
[33,30,20,3],
[33,30,20,4],
[33,30,20,5],
[33,30,20,6],
[33,30,20,7],
[33,30,20,8],
[33,30,20,9],
[33,30,20,13],
[33,30,20,14],
[33,30,20,15],
[33,30,20,16],
[33,30,20,17],
[33,30,20,18],
[33,30,20,19],
[33,30,21,0],
[33,30,21,1],
[33,30,21,2],
[33,30,21,4],
[33,30,21,7],
[33,30,21,9],
[33,30,21,10],
[33,30,21,13],
[33,30,21,14],
[33,30,21,15],
[33,30,21,16],
[33,30,21,17],
[33,30,21,18],
[33,30,21,19],
[33,30,21,20],
[33,30,22,0],
[33,30,22,1],
[33,30,22,2],
[33,30,22,3],
[33,30,22,4],
[33,30,22,5],
[33,30,22,6],
[33,30,22,7],
[33,30,22,8],
[33,30,22,9],
[33,30,22,13],
[33,30,22,14],
[33,30,22,15],
[33,30,22,16],
[33,30,22,17],
[33,30,22,18],
[33,30,22,19],
[33,30,22,21],
[33,30,24,0],
[33,30,24,1],
[33,30,24,2],
[33,30,24,3],
[33,30,24,4],
[33,30,24,5],
[33,30,24,6],
[33,30,24,7],
[33,30,24,8],
[33,30,24,9],
[33,30,24,10],
[33,30,24,13],
[33,30,24,14],
[33,30,24,15],
[33,30,24,17],
[33,30,24,18],
[33,30,24,19],
[33,30,24,20],
[33,30,24,21],
[33,30,24,22],
[33,30,25,0],
[33,30,25,1],
[33,30,25,2],
[33,30,25,3],
[33,30,25,4],
[33,30,25,5],
[33,30,25,6],
[33,30,25,8],
[33,30,25,9],
[33,30,25,10],
[33,30,25,13],
[33,30,25,14],
[33,30,25,15],
[33,30,25,16],
[33,30,25,17],
[33,30,25,18],
[33,30,25,19],
[33,30,25,20],
[33,30,25,21],
[33,30,25,22],
[33,30,25,24],
[33,30,26,0],
[33,30,26,1],
[33,30,26,2],
[33,30,26,3],
[33,30,26,4],
[33,30,26,5],
[33,30,26,6],
[33,30,26,7],
[33,30,26,8],
[33,30,26,10],
[33,30,26,13],
[33,30,26,14],
[33,30,26,16],
[33,30,26,17],
[33,30,26,19],
[33,30,26,20],
[33,30,26,21],
[33,30,26,22],
[33,30,26,24],
[33,30,26,25],
[33,30,27,0],
[33,30,27,1],
[33,30,27,2],
[33,30,27,3],
[33,30,27,4],
[33,30,27,5],
[33,30,27,6],
[33,30,27,7],
[33,30,27,8],
[33,30,27,9],
[33,30,27,13],
[33,30,27,14],
[33,30,27,15],
[33,30,27,16],
[33,30,27,17],
[33,30,27,18],
[33,30,27,19],
[33,30,27,21],
[33,30,27,24],
[33,30,27,25],
[33,30,27,26],
[33,30,28,0],
[33,30,28,1],
[33,30,28,2],
[33,30,28,3],
[33,30,28,4],
[33,30,28,5],
[33,30,28,6],
[33,30,28,8],
[33,30,28,9],
[33,30,28,10],
[33,30,28,13],
[33,30,28,14],
[33,30,28,15],
[33,30,28,16],
[33,30,28,17],
[33,30,28,18],
[33,30,28,19],
[33,30,28,20],
[33,30,28,21],
[33,30,28,22],
[33,30,28,24],
[33,30,28,26],
[33,30,28,27],
[33,31,3,0],
[33,31,3,1],
[33,31,3,2],
[33,31,4,0],
[33,31,4,1],
[33,31,4,2],
[33,31,4,3],
[33,31,5,0],
[33,31,5,1],
[33,31,5,2],
[33,31,5,4],
[33,31,6,0],
[33,31,6,1],
[33,31,6,2],
[33,31,6,4],
[33,31,7,0],
[33,31,7,1],
[33,31,7,2],
[33,31,7,3],
[33,31,7,4],
[33,31,7,5],
[33,31,7,6],
[33,31,8,0],
[33,31,8,1],
[33,31,8,2],
[33,31,8,4],
[33,31,8,7],
[33,31,9,0],
[33,31,9,1],
[33,31,9,2],
[33,31,9,3],
[33,31,9,4],
[33,31,9,5],
[33,31,9,6],
[33,31,9,7],
[33,31,9,8],
[33,31,10,0],
[33,31,10,1],
[33,31,10,2],
[33,31,10,3],
[33,31,10,4],
[33,31,10,5],
[33,31,10,6],
[33,31,10,7],
[33,31,10,8],
[33,31,10,9],
[33,31,13,0],
[33,31,13,1],
[33,31,13,2],
[33,31,13,3],
[33,31,13,4],
[33,31,13,5],
[33,31,13,6],
[33,31,13,7],
[33,31,13,8],
[33,31,13,9],
[33,31,13,10],
[33,31,14,0],
[33,31,14,1],
[33,31,14,2],
[33,31,14,3],
[33,31,14,4],
[33,31,14,5],
[33,31,14,6],
[33,31,14,7],
[33,31,14,8],
[33,31,14,9],
[33,31,14,10],
[33,31,15,0],
[33,31,15,1],
[33,31,15,2],
[33,31,15,3],
[33,31,15,4],
[33,31,15,5],
[33,31,15,6],
[33,31,15,7],
[33,31,15,8],
[33,31,15,10],
[33,31,15,13],
[33,31,15,14],
[33,31,16,0],
[33,31,16,1],
[33,31,16,2],
[33,31,16,3],
[33,31,16,4],
[33,31,16,5],
[33,31,16,6],
[33,31,16,7],
[33,31,16,8],
[33,31,16,9],
[33,31,16,10],
[33,31,16,13],
[33,31,16,14],
[33,31,16,15],
[33,31,17,0],
[33,31,17,1],
[33,31,17,2],
[33,31,17,3],
[33,31,17,4],
[33,31,17,5],
[33,31,17,6],
[33,31,17,7],
[33,31,17,8],
[33,31,17,9],
[33,31,17,10],
[33,31,17,13],
[33,31,17,14],
[33,31,17,15],
[33,31,17,16],
[33,31,18,0],
[33,31,18,1],
[33,31,18,2],
[33,31,18,3],
[33,31,18,4],
[33,31,18,5],
[33,31,18,6],
[33,31,18,7],
[33,31,18,8],
[33,31,18,10],
[33,31,18,13],
[33,31,18,14],
[33,31,18,16],
[33,31,18,17],
[33,31,19,0],
[33,31,19,1],
[33,31,19,2],
[33,31,19,3],
[33,31,19,4],
[33,31,19,5],
[33,31,19,6],
[33,31,19,7],
[33,31,19,8],
[33,31,19,9],
[33,31,19,10],
[33,31,19,15],
[33,31,19,16],
[33,31,19,17],
[33,31,19,18],
[33,31,20,0],
[33,31,20,1],
[33,31,20,2],
[33,31,20,3],
[33,31,20,4],
[33,31,20,5],
[33,31,20,6],
[33,31,20,7],
[33,31,20,8],
[33,31,20,9],
[33,31,20,13],
[33,31,20,14],
[33,31,20,15],
[33,31,20,16],
[33,31,20,17],
[33,31,20,18],
[33,31,20,19],
[33,31,21,0],
[33,31,21,1],
[33,31,21,2],
[33,31,21,4],
[33,31,21,7],
[33,31,21,9],
[33,31,21,10],
[33,31,21,13],
[33,31,21,14],
[33,31,21,15],
[33,31,21,16],
[33,31,21,17],
[33,31,21,18],
[33,31,21,19],
[33,31,21,20],
[33,31,22,0],
[33,31,22,1],
[33,31,22,2],
[33,31,22,3],
[33,31,22,4],
[33,31,22,5],
[33,31,22,6],
[33,31,22,7],
[33,31,22,8],
[33,31,22,9],
[33,31,22,13],
[33,31,22,14],
[33,31,22,15],
[33,31,22,16],
[33,31,22,17],
[33,31,22,18],
[33,31,22,19],
[33,31,22,21],
[33,31,24,0],
[33,31,24,1],
[33,31,24,2],
[33,31,24,3],
[33,31,24,4],
[33,31,24,5],
[33,31,24,6],
[33,31,24,7],
[33,31,24,8],
[33,31,24,9],
[33,31,24,10],
[33,31,24,13],
[33,31,24,14],
[33,31,24,15],
[33,31,24,17],
[33,31,24,18],
[33,31,24,19],
[33,31,24,20],
[33,31,24,21],
[33,31,24,22],
[33,31,25,0],
[33,31,25,1],
[33,31,25,2],
[33,31,25,3],
[33,31,25,4],
[33,31,25,5],
[33,31,25,6],
[33,31,25,8],
[33,31,25,9],
[33,31,25,10],
[33,31,25,13],
[33,31,25,14],
[33,31,25,15],
[33,31,25,16],
[33,31,25,17],
[33,31,25,18],
[33,31,25,19],
[33,31,25,20],
[33,31,25,21],
[33,31,25,22],
[33,31,25,24],
[33,31,26,0],
[33,31,26,1],
[33,31,26,2],
[33,31,26,3],
[33,31,26,4],
[33,31,26,5],
[33,31,26,6],
[33,31,26,7],
[33,31,26,8],
[33,31,26,10],
[33,31,26,13],
[33,31,26,14],
[33,31,26,16],
[33,31,26,17],
[33,31,26,19],
[33,31,26,20],
[33,31,26,21],
[33,31,26,22],
[33,31,26,24],
[33,31,26,25],
[33,31,27,0],
[33,31,27,1],
[33,31,27,2],
[33,31,27,3],
[33,31,27,4],
[33,31,27,5],
[33,31,27,6],
[33,31,27,7],
[33,31,27,8],
[33,31,27,9],
[33,31,27,13],
[33,31,27,14],
[33,31,27,15],
[33,31,27,16],
[33,31,27,17],
[33,31,27,18],
[33,31,27,19],
[33,31,27,21],
[33,31,27,24],
[33,31,27,25],
[33,31,27,26],
[33,31,28,0],
[33,31,28,1],
[33,31,28,2],
[33,31,28,3],
[33,31,28,4],
[33,31,28,5],
[33,31,28,6],
[33,31,28,8],
[33,31,28,9],
[33,31,28,10],
[33,31,28,13],
[33,31,28,14],
[33,31,28,15],
[33,31,28,16],
[33,31,28,17],
[33,31,28,18],
[33,31,28,19],
[33,31,28,20],
[33,31,28,21],
[33,31,28,22],
[33,31,28,24],
[33,31,28,26],
[33,31,28,27],
[34,4,3,0],
[34,4,3,1],
[34,4,3,2],
[34,5,4,0],
[34,5,4,1],
[34,5,4,2],
[34,6,4,0],
[34,6,4,1],
[34,6,4,2],
[34,8,4,0],
[34,8,4,1],
[34,8,4,2],
[34,9,3,0],
[34,9,3,1],
[34,9,3,2],
[34,9,4,0],
[34,9,4,1],
[34,9,4,2],
[34,9,4,3],
[34,9,5,0],
[34,9,5,1],
[34,9,5,2],
[34,9,5,4],
[34,9,6,0],
[34,9,6,1],
[34,9,6,2],
[34,9,6,4],
[34,9,8,0],
[34,9,8,1],
[34,9,8,2],
[34,9,8,4],
[34,10,3,0],
[34,10,3,1],
[34,10,3,2],
[34,10,4,0],
[34,10,4,1],
[34,10,4,2],
[34,10,4,3],
[34,10,5,0],
[34,10,5,1],
[34,10,5,2],
[34,10,5,4],
[34,10,6,0],
[34,10,6,1],
[34,10,6,2],
[34,10,6,4],
[34,10,8,0],
[34,10,8,1],
[34,10,8,2],
[34,10,8,4],
[34,10,9,0],
[34,10,9,1],
[34,10,9,2],
[34,10,9,3],
[34,10,9,4],
[34,10,9,5],
[34,10,9,6],
[34,10,9,8],
[34,11,3,0],
[34,11,3,1],
[34,11,3,2],
[34,11,4,0],
[34,11,4,1],
[34,11,4,2],
[34,11,4,3],
[34,11,5,0],
[34,11,5,1],
[34,11,5,2],
[34,11,5,4],
[34,11,6,0],
[34,11,6,1],
[34,11,6,2],
[34,11,6,4],
[34,11,8,0],
[34,11,8,1],
[34,11,8,2],
[34,11,8,4],
[34,11,9,0],
[34,11,9,1],
[34,11,9,2],
[34,11,9,3],
[34,11,9,4],
[34,11,9,5],
[34,11,9,6],
[34,11,9,8],
[34,11,10,0],
[34,11,10,1],
[34,11,10,2],
[34,11,10,3],
[34,11,10,4],
[34,11,10,5],
[34,11,10,6],
[34,11,10,8],
[34,11,10,9],
[34,12,3,0],
[34,12,3,1],
[34,12,3,2],
[34,12,4,0],
[34,12,4,1],
[34,12,4,2],
[34,12,4,3],
[34,12,5,0],
[34,12,5,1],
[34,12,5,2],
[34,12,5,4],
[34,12,6,0],
[34,12,6,1],
[34,12,6,2],
[34,12,6,4],
[34,12,8,0],
[34,12,8,1],
[34,12,8,2],
[34,12,8,4],
[34,12,9,0],
[34,12,9,1],
[34,12,9,2],
[34,12,9,3],
[34,12,9,4],
[34,12,9,5],
[34,12,9,6],
[34,12,9,8],
[34,12,10,0],
[34,12,10,1],
[34,12,10,2],
[34,12,10,3],
[34,12,10,4],
[34,12,10,5],
[34,12,10,6],
[34,12,10,8],
[34,12,10,9],
[34,13,3,0],
[34,13,3,1],
[34,13,3,2],
[34,13,4,0],
[34,13,4,1],
[34,13,4,2],
[34,13,4,3],
[34,13,5,0],
[34,13,5,1],
[34,13,5,2],
[34,13,5,4],
[34,13,6,0],
[34,13,6,1],
[34,13,6,2],
[34,13,6,4],
[34,13,8,0],
[34,13,8,1],
[34,13,8,2],
[34,13,8,4],
[34,13,9,0],
[34,13,9,1],
[34,13,9,2],
[34,13,9,3],
[34,13,9,4],
[34,13,9,5],
[34,13,9,6],
[34,13,9,8],
[34,13,10,0],
[34,13,10,1],
[34,13,10,2],
[34,13,10,3],
[34,13,10,4],
[34,13,10,5],
[34,13,10,6],
[34,13,10,8],
[34,13,10,9],
[34,13,11,0],
[34,13,11,1],
[34,13,11,2],
[34,13,11,3],
[34,13,11,4],
[34,13,11,5],
[34,13,11,6],
[34,13,11,8],
[34,13,11,9],
[34,13,11,10],
[34,13,12,0],
[34,13,12,1],
[34,13,12,2],
[34,13,12,3],
[34,13,12,4],
[34,13,12,5],
[34,13,12,6],
[34,13,12,8],
[34,13,12,9],
[34,13,12,10],
[34,14,3,0],
[34,14,3,1],
[34,14,3,2],
[34,14,4,0],
[34,14,4,1],
[34,14,4,2],
[34,14,4,3],
[34,14,5,0],
[34,14,5,1],
[34,14,5,2],
[34,14,5,4],
[34,14,6,0],
[34,14,6,1],
[34,14,6,2],
[34,14,6,4],
[34,14,8,0],
[34,14,8,1],
[34,14,8,2],
[34,14,8,4],
[34,14,9,0],
[34,14,9,1],
[34,14,9,2],
[34,14,9,3],
[34,14,9,4],
[34,14,9,5],
[34,14,9,6],
[34,14,9,8],
[34,14,10,0],
[34,14,10,1],
[34,14,10,2],
[34,14,10,3],
[34,14,10,4],
[34,14,10,5],
[34,14,10,6],
[34,14,10,8],
[34,14,10,9],
[34,14,11,0],
[34,14,11,1],
[34,14,11,2],
[34,14,11,3],
[34,14,11,4],
[34,14,11,5],
[34,14,11,6],
[34,14,11,8],
[34,14,11,9],
[34,14,11,10],
[34,14,12,0],
[34,14,12,1],
[34,14,12,2],
[34,14,12,3],
[34,14,12,4],
[34,14,12,5],
[34,14,12,6],
[34,14,12,8],
[34,14,12,9],
[34,14,12,10],
[34,15,3,0],
[34,15,3,1],
[34,15,3,2],
[34,15,4,0],
[34,15,4,1],
[34,15,4,2],
[34,15,4,3],
[34,15,5,0],
[34,15,5,1],
[34,15,5,2],
[34,15,5,4],
[34,15,6,0],
[34,15,6,1],
[34,15,6,2],
[34,15,6,4],
[34,15,8,0],
[34,15,8,1],
[34,15,8,2],
[34,15,8,4],
[34,15,10,0],
[34,15,10,1],
[34,15,10,2],
[34,15,10,3],
[34,15,10,4],
[34,15,10,5],
[34,15,10,6],
[34,15,10,8],
[34,15,11,0],
[34,15,11,1],
[34,15,11,2],
[34,15,11,3],
[34,15,11,4],
[34,15,11,5],
[34,15,11,6],
[34,15,11,8],
[34,15,11,10],
[34,15,12,0],
[34,15,12,1],
[34,15,12,2],
[34,15,12,3],
[34,15,12,4],
[34,15,12,5],
[34,15,12,6],
[34,15,12,8],
[34,15,12,10],
[34,15,13,0],
[34,15,13,1],
[34,15,13,2],
[34,15,13,3],
[34,15,13,4],
[34,15,13,5],
[34,15,13,6],
[34,15,13,8],
[34,15,13,10],
[34,15,13,11],
[34,15,13,12],
[34,15,14,0],
[34,15,14,1],
[34,15,14,2],
[34,15,14,3],
[34,15,14,4],
[34,15,14,5],
[34,15,14,6],
[34,15,14,8],
[34,15,14,10],
[34,15,14,11],
[34,15,14,12],
[34,16,3,0],
[34,16,3,1],
[34,16,3,2],
[34,16,4,0],
[34,16,4,1],
[34,16,4,2],
[34,16,4,3],
[34,16,5,0],
[34,16,5,1],
[34,16,5,2],
[34,16,5,4],
[34,16,6,0],
[34,16,6,1],
[34,16,6,2],
[34,16,6,4],
[34,16,8,0],
[34,16,8,1],
[34,16,8,2],
[34,16,8,4],
[34,16,9,0],
[34,16,9,1],
[34,16,9,2],
[34,16,9,3],
[34,16,9,4],
[34,16,9,5],
[34,16,9,6],
[34,16,9,8],
[34,16,10,0],
[34,16,10,1],
[34,16,10,2],
[34,16,10,3],
[34,16,10,4],
[34,16,10,5],
[34,16,10,6],
[34,16,10,8],
[34,16,10,9],
[34,16,11,0],
[34,16,11,1],
[34,16,11,2],
[34,16,11,3],
[34,16,11,4],
[34,16,11,5],
[34,16,11,6],
[34,16,11,8],
[34,16,11,9],
[34,16,11,10],
[34,16,12,0],
[34,16,12,1],
[34,16,12,2],
[34,16,12,3],
[34,16,12,4],
[34,16,12,5],
[34,16,12,6],
[34,16,12,8],
[34,16,12,9],
[34,16,12,10],
[34,16,13,0],
[34,16,13,1],
[34,16,13,2],
[34,16,13,3],
[34,16,13,4],
[34,16,13,5],
[34,16,13,6],
[34,16,13,8],
[34,16,13,9],
[34,16,13,10],
[34,16,13,11],
[34,16,13,12],
[34,16,14,0],
[34,16,14,1],
[34,16,14,2],
[34,16,14,3],
[34,16,14,4],
[34,16,14,5],
[34,16,14,6],
[34,16,14,8],
[34,16,14,9],
[34,16,14,10],
[34,16,14,11],
[34,16,14,12],
[34,16,15,0],
[34,16,15,1],
[34,16,15,2],
[34,16,15,3],
[34,16,15,4],
[34,16,15,5],
[34,16,15,6],
[34,16,15,8],
[34,16,15,10],
[34,16,15,11],
[34,16,15,12],
[34,16,15,13],
[34,16,15,14],
[34,17,3,0],
[34,17,3,1],
[34,17,3,2],
[34,17,4,0],
[34,17,4,1],
[34,17,4,2],
[34,17,4,3],
[34,17,5,0],
[34,17,5,1],
[34,17,5,2],
[34,17,5,4],
[34,17,6,0],
[34,17,6,1],
[34,17,6,2],
[34,17,6,4],
[34,17,8,0],
[34,17,8,1],
[34,17,8,2],
[34,17,8,4],
[34,17,9,0],
[34,17,9,1],
[34,17,9,2],
[34,17,9,3],
[34,17,9,4],
[34,17,9,5],
[34,17,9,6],
[34,17,9,8],
[34,17,10,0],
[34,17,10,1],
[34,17,10,2],
[34,17,10,3],
[34,17,10,4],
[34,17,10,5],
[34,17,10,6],
[34,17,10,8],
[34,17,10,9],
[34,17,11,0],
[34,17,11,1],
[34,17,11,2],
[34,17,11,3],
[34,17,11,4],
[34,17,11,5],
[34,17,11,6],
[34,17,11,8],
[34,17,11,9],
[34,17,11,10],
[34,17,12,0],
[34,17,12,1],
[34,17,12,2],
[34,17,12,3],
[34,17,12,4],
[34,17,12,5],
[34,17,12,6],
[34,17,12,8],
[34,17,12,9],
[34,17,12,10],
[34,17,13,0],
[34,17,13,1],
[34,17,13,2],
[34,17,13,3],
[34,17,13,4],
[34,17,13,5],
[34,17,13,6],
[34,17,13,8],
[34,17,13,9],
[34,17,13,10],
[34,17,13,11],
[34,17,13,12],
[34,17,14,0],
[34,17,14,1],
[34,17,14,2],
[34,17,14,3],
[34,17,14,4],
[34,17,14,5],
[34,17,14,6],
[34,17,14,8],
[34,17,14,9],
[34,17,14,10],
[34,17,14,11],
[34,17,14,12],
[34,17,15,0],
[34,17,15,1],
[34,17,15,2],
[34,17,15,3],
[34,17,15,4],
[34,17,15,5],
[34,17,15,6],
[34,17,15,8],
[34,17,15,10],
[34,17,15,11],
[34,17,15,12],
[34,17,15,13],
[34,17,15,14],
[34,17,16,0],
[34,17,16,1],
[34,17,16,2],
[34,17,16,3],
[34,17,16,4],
[34,17,16,5],
[34,17,16,6],
[34,17,16,8],
[34,17,16,9],
[34,17,16,10],
[34,17,16,11],
[34,17,16,12],
[34,17,16,13],
[34,17,16,14],
[34,17,16,15],
[34,18,3,0],
[34,18,3,1],
[34,18,3,2],
[34,18,4,0],
[34,18,4,1],
[34,18,4,2],
[34,18,4,3],
[34,18,5,0],
[34,18,5,1],
[34,18,5,2],
[34,18,5,4],
[34,18,6,0],
[34,18,6,1],
[34,18,6,2],
[34,18,6,4],
[34,18,8,0],
[34,18,8,1],
[34,18,8,2],
[34,18,8,4],
[34,18,10,0],
[34,18,10,1],
[34,18,10,2],
[34,18,10,3],
[34,18,10,4],
[34,18,10,5],
[34,18,10,6],
[34,18,10,8],
[34,18,11,0],
[34,18,11,1],
[34,18,11,2],
[34,18,11,3],
[34,18,11,4],
[34,18,11,5],
[34,18,11,6],
[34,18,11,8],
[34,18,11,10],
[34,18,12,0],
[34,18,12,1],
[34,18,12,2],
[34,18,12,3],
[34,18,12,4],
[34,18,12,5],
[34,18,12,6],
[34,18,12,8],
[34,18,12,10],
[34,18,13,0],
[34,18,13,1],
[34,18,13,2],
[34,18,13,3],
[34,18,13,4],
[34,18,13,5],
[34,18,13,6],
[34,18,13,8],
[34,18,13,10],
[34,18,13,11],
[34,18,13,12],
[34,18,14,0],
[34,18,14,1],
[34,18,14,2],
[34,18,14,3],
[34,18,14,4],
[34,18,14,5],
[34,18,14,6],
[34,18,14,8],
[34,18,14,10],
[34,18,14,11],
[34,18,14,12],
[34,18,16,0],
[34,18,16,1],
[34,18,16,2],
[34,18,16,3],
[34,18,16,4],
[34,18,16,5],
[34,18,16,6],
[34,18,16,8],
[34,18,16,10],
[34,18,16,11],
[34,18,16,12],
[34,18,16,13],
[34,18,16,14],
[34,18,17,0],
[34,18,17,1],
[34,18,17,2],
[34,18,17,3],
[34,18,17,4],
[34,18,17,5],
[34,18,17,6],
[34,18,17,8],
[34,18,17,10],
[34,18,17,11],
[34,18,17,12],
[34,18,17,13],
[34,18,17,14],
[34,18,17,16],
[34,19,3,0],
[34,19,3,1],
[34,19,3,2],
[34,19,4,0],
[34,19,4,1],
[34,19,4,2],
[34,19,4,3],
[34,19,5,0],
[34,19,5,1],
[34,19,5,2],
[34,19,5,4],
[34,19,6,0],
[34,19,6,1],
[34,19,6,2],
[34,19,6,4],
[34,19,8,0],
[34,19,8,1],
[34,19,8,2],
[34,19,8,4],
[34,19,9,0],
[34,19,9,1],
[34,19,9,2],
[34,19,9,3],
[34,19,9,4],
[34,19,9,5],
[34,19,9,6],
[34,19,9,8],
[34,19,10,0],
[34,19,10,1],
[34,19,10,2],
[34,19,10,3],
[34,19,10,4],
[34,19,10,5],
[34,19,10,6],
[34,19,10,8],
[34,19,10,9],
[34,19,11,0],
[34,19,11,1],
[34,19,11,2],
[34,19,11,3],
[34,19,11,4],
[34,19,11,5],
[34,19,11,6],
[34,19,11,8],
[34,19,11,9],
[34,19,11,10],
[34,19,12,0],
[34,19,12,1],
[34,19,12,2],
[34,19,12,3],
[34,19,12,4],
[34,19,12,5],
[34,19,12,6],
[34,19,12,8],
[34,19,12,9],
[34,19,12,10],
[34,19,15,0],
[34,19,15,1],
[34,19,15,2],
[34,19,15,3],
[34,19,15,4],
[34,19,15,5],
[34,19,15,6],
[34,19,15,8],
[34,19,15,10],
[34,19,15,11],
[34,19,15,12],
[34,19,16,0],
[34,19,16,1],
[34,19,16,2],
[34,19,16,3],
[34,19,16,4],
[34,19,16,5],
[34,19,16,6],
[34,19,16,8],
[34,19,16,9],
[34,19,16,10],
[34,19,16,11],
[34,19,16,12],
[34,19,16,15],
[34,19,17,0],
[34,19,17,1],
[34,19,17,2],
[34,19,17,3],
[34,19,17,4],
[34,19,17,5],
[34,19,17,6],
[34,19,17,8],
[34,19,17,9],
[34,19,17,10],
[34,19,17,11],
[34,19,17,12],
[34,19,17,15],
[34,19,17,16],
[34,19,18,0],
[34,19,18,1],
[34,19,18,2],
[34,19,18,3],
[34,19,18,4],
[34,19,18,5],
[34,19,18,6],
[34,19,18,8],
[34,19,18,10],
[34,19,18,11],
[34,19,18,12],
[34,19,18,16],
[34,19,18,17],
[34,20,3,0],
[34,20,3,1],
[34,20,3,2],
[34,20,4,0],
[34,20,4,1],
[34,20,4,2],
[34,20,4,3],
[34,20,5,0],
[34,20,5,1],
[34,20,5,2],
[34,20,5,4],
[34,20,6,0],
[34,20,6,1],
[34,20,6,2],
[34,20,6,4],
[34,20,8,0],
[34,20,8,1],
[34,20,8,2],
[34,20,8,4],
[34,20,9,0],
[34,20,9,1],
[34,20,9,2],
[34,20,9,3],
[34,20,9,4],
[34,20,9,5],
[34,20,9,6],
[34,20,9,8],
[34,20,11,0],
[34,20,11,1],
[34,20,11,2],
[34,20,11,3],
[34,20,11,4],
[34,20,11,5],
[34,20,11,6],
[34,20,11,8],
[34,20,11,9],
[34,20,12,0],
[34,20,12,1],
[34,20,12,2],
[34,20,12,3],
[34,20,12,4],
[34,20,12,5],
[34,20,12,6],
[34,20,12,8],
[34,20,12,9],
[34,20,13,0],
[34,20,13,1],
[34,20,13,2],
[34,20,13,3],
[34,20,13,4],
[34,20,13,5],
[34,20,13,6],
[34,20,13,8],
[34,20,13,9],
[34,20,13,11],
[34,20,13,12],
[34,20,14,0],
[34,20,14,1],
[34,20,14,2],
[34,20,14,3],
[34,20,14,4],
[34,20,14,5],
[34,20,14,6],
[34,20,14,8],
[34,20,14,9],
[34,20,14,11],
[34,20,14,12],
[34,20,15,0],
[34,20,15,1],
[34,20,15,2],
[34,20,15,3],
[34,20,15,4],
[34,20,15,5],
[34,20,15,6],
[34,20,15,8],
[34,20,15,11],
[34,20,15,12],
[34,20,15,13],
[34,20,15,14],
[34,20,16,0],
[34,20,16,1],
[34,20,16,2],
[34,20,16,3],
[34,20,16,4],
[34,20,16,5],
[34,20,16,6],
[34,20,16,8],
[34,20,16,9],
[34,20,16,11],
[34,20,16,12],
[34,20,16,13],
[34,20,16,14],
[34,20,16,15],
[34,20,17,0],
[34,20,17,1],
[34,20,17,2],
[34,20,17,3],
[34,20,17,4],
[34,20,17,5],
[34,20,17,6],
[34,20,17,8],
[34,20,17,9],
[34,20,17,11],
[34,20,17,12],
[34,20,17,13],
[34,20,17,14],
[34,20,17,15],
[34,20,17,16],
[34,20,18,0],
[34,20,18,1],
[34,20,18,2],
[34,20,18,3],
[34,20,18,4],
[34,20,18,5],
[34,20,18,6],
[34,20,18,8],
[34,20,18,11],
[34,20,18,12],
[34,20,18,13],
[34,20,18,14],
[34,20,18,16],
[34,20,18,17],
[34,20,19,0],
[34,20,19,1],
[34,20,19,2],
[34,20,19,3],
[34,20,19,4],
[34,20,19,5],
[34,20,19,6],
[34,20,19,8],
[34,20,19,9],
[34,20,19,11],
[34,20,19,12],
[34,20,19,15],
[34,20,19,16],
[34,20,19,17],
[34,20,19,18],
[34,21,4,0],
[34,21,4,1],
[34,21,4,2],
[34,21,9,0],
[34,21,9,1],
[34,21,9,2],
[34,21,9,4],
[34,21,10,0],
[34,21,10,1],
[34,21,10,2],
[34,21,10,4],
[34,21,10,9],
[34,21,11,0],
[34,21,11,1],
[34,21,11,2],
[34,21,11,4],
[34,21,11,9],
[34,21,11,10],
[34,21,12,0],
[34,21,12,1],
[34,21,12,2],
[34,21,12,4],
[34,21,12,9],
[34,21,12,10],
[34,21,13,0],
[34,21,13,1],
[34,21,13,2],
[34,21,13,4],
[34,21,13,9],
[34,21,13,10],
[34,21,13,11],
[34,21,13,12],
[34,21,14,0],
[34,21,14,1],
[34,21,14,2],
[34,21,14,4],
[34,21,14,9],
[34,21,14,10],
[34,21,14,11],
[34,21,14,12],
[34,21,15,0],
[34,21,15,1],
[34,21,15,2],
[34,21,15,4],
[34,21,15,10],
[34,21,15,11],
[34,21,15,12],
[34,21,15,13],
[34,21,15,14],
[34,21,16,0],
[34,21,16,1],
[34,21,16,2],
[34,21,16,4],
[34,21,16,9],
[34,21,16,10],
[34,21,16,11],
[34,21,16,12],
[34,21,16,13],
[34,21,16,14],
[34,21,16,15],
[34,21,17,0],
[34,21,17,1],
[34,21,17,2],
[34,21,17,4],
[34,21,17,9],
[34,21,17,10],
[34,21,17,11],
[34,21,17,12],
[34,21,17,13],
[34,21,17,14],
[34,21,17,15],
[34,21,17,16],
[34,21,18,0],
[34,21,18,1],
[34,21,18,2],
[34,21,18,4],
[34,21,18,10],
[34,21,18,11],
[34,21,18,12],
[34,21,18,13],
[34,21,18,14],
[34,21,18,16],
[34,21,18,17],
[34,21,19,0],
[34,21,19,1],
[34,21,19,2],
[34,21,19,4],
[34,21,19,9],
[34,21,19,10],
[34,21,19,11],
[34,21,19,12],
[34,21,19,15],
[34,21,19,16],
[34,21,19,17],
[34,21,19,18],
[34,21,20,0],
[34,21,20,1],
[34,21,20,2],
[34,21,20,4],
[34,21,20,9],
[34,21,20,11],
[34,21,20,12],
[34,21,20,13],
[34,21,20,14],
[34,21,20,15],
[34,21,20,16],
[34,21,20,17],
[34,21,20,18],
[34,21,20,19],
[34,22,3,0],
[34,22,3,1],
[34,22,3,2],
[34,22,4,0],
[34,22,4,1],
[34,22,4,2],
[34,22,4,3],
[34,22,5,0],
[34,22,5,1],
[34,22,5,2],
[34,22,5,4],
[34,22,6,0],
[34,22,6,1],
[34,22,6,2],
[34,22,6,4],
[34,22,8,0],
[34,22,8,1],
[34,22,8,2],
[34,22,8,4],
[34,22,9,0],
[34,22,9,1],
[34,22,9,2],
[34,22,9,3],
[34,22,9,4],
[34,22,9,5],
[34,22,9,6],
[34,22,9,8],
[34,22,11,0],
[34,22,11,1],
[34,22,11,2],
[34,22,11,3],
[34,22,11,4],
[34,22,11,5],
[34,22,11,6],
[34,22,11,8],
[34,22,11,9],
[34,22,12,0],
[34,22,12,1],
[34,22,12,2],
[34,22,12,3],
[34,22,12,4],
[34,22,12,5],
[34,22,12,6],
[34,22,12,8],
[34,22,12,9],
[34,22,13,0],
[34,22,13,1],
[34,22,13,2],
[34,22,13,3],
[34,22,13,4],
[34,22,13,5],
[34,22,13,6],
[34,22,13,8],
[34,22,13,9],
[34,22,13,11],
[34,22,13,12],
[34,22,14,0],
[34,22,14,1],
[34,22,14,2],
[34,22,14,3],
[34,22,14,4],
[34,22,14,5],
[34,22,14,6],
[34,22,14,8],
[34,22,14,9],
[34,22,14,11],
[34,22,14,12],
[34,22,15,0],
[34,22,15,1],
[34,22,15,2],
[34,22,15,3],
[34,22,15,4],
[34,22,15,5],
[34,22,15,6],
[34,22,15,8],
[34,22,15,11],
[34,22,15,12],
[34,22,15,13],
[34,22,15,14],
[34,22,16,0],
[34,22,16,1],
[34,22,16,2],
[34,22,16,3],
[34,22,16,4],
[34,22,16,5],
[34,22,16,6],
[34,22,16,8],
[34,22,16,9],
[34,22,16,11],
[34,22,16,12],
[34,22,16,13],
[34,22,16,14],
[34,22,16,15],
[34,22,17,0],
[34,22,17,1],
[34,22,17,2],
[34,22,17,3],
[34,22,17,4],
[34,22,17,5],
[34,22,17,6],
[34,22,17,8],
[34,22,17,9],
[34,22,17,11],
[34,22,17,12],
[34,22,17,13],
[34,22,17,14],
[34,22,17,15],
[34,22,17,16],
[34,22,18,0],
[34,22,18,1],
[34,22,18,2],
[34,22,18,3],
[34,22,18,4],
[34,22,18,5],
[34,22,18,6],
[34,22,18,8],
[34,22,18,11],
[34,22,18,12],
[34,22,18,13],
[34,22,18,14],
[34,22,18,16],
[34,22,18,17],
[34,22,19,0],
[34,22,19,1],
[34,22,19,2],
[34,22,19,3],
[34,22,19,4],
[34,22,19,5],
[34,22,19,6],
[34,22,19,8],
[34,22,19,9],
[34,22,19,11],
[34,22,19,12],
[34,22,19,15],
[34,22,19,16],
[34,22,19,17],
[34,22,19,18],
[34,22,21,0],
[34,22,21,1],
[34,22,21,2],
[34,22,21,4],
[34,22,21,9],
[34,22,21,11],
[34,22,21,12],
[34,22,21,13],
[34,22,21,14],
[34,22,21,15],
[34,22,21,16],
[34,22,21,17],
[34,22,21,18],
[34,22,21,19],
[34,23,3,0],
[34,23,3,1],
[34,23,3,2],
[34,23,4,0],
[34,23,4,1],
[34,23,4,2],
[34,23,4,3],
[34,23,5,0],
[34,23,5,1],
[34,23,5,2],
[34,23,5,4],
[34,23,6,0],
[34,23,6,1],
[34,23,6,2],
[34,23,6,4],
[34,23,8,0],
[34,23,8,1],
[34,23,8,2],
[34,23,8,4],
[34,23,9,0],
[34,23,9,1],
[34,23,9,2],
[34,23,9,3],
[34,23,9,4],
[34,23,9,5],
[34,23,9,6],
[34,23,9,8],
[34,23,10,0],
[34,23,10,1],
[34,23,10,2],
[34,23,10,3],
[34,23,10,4],
[34,23,10,5],
[34,23,10,6],
[34,23,10,8],
[34,23,10,9],
[34,23,11,0],
[34,23,11,1],
[34,23,11,2],
[34,23,11,3],
[34,23,11,4],
[34,23,11,5],
[34,23,11,6],
[34,23,11,8],
[34,23,11,9],
[34,23,11,10],
[34,23,12,0],
[34,23,12,1],
[34,23,12,2],
[34,23,12,3],
[34,23,12,4],
[34,23,12,5],
[34,23,12,6],
[34,23,12,8],
[34,23,12,9],
[34,23,12,10],
[34,23,13,0],
[34,23,13,1],
[34,23,13,2],
[34,23,13,3],
[34,23,13,4],
[34,23,13,5],
[34,23,13,6],
[34,23,13,8],
[34,23,13,9],
[34,23,13,10],
[34,23,13,11],
[34,23,13,12],
[34,23,14,0],
[34,23,14,1],
[34,23,14,2],
[34,23,14,3],
[34,23,14,4],
[34,23,14,5],
[34,23,14,6],
[34,23,14,8],
[34,23,14,9],
[34,23,14,10],
[34,23,14,11],
[34,23,14,12],
[34,23,15,0],
[34,23,15,1],
[34,23,15,2],
[34,23,15,3],
[34,23,15,4],
[34,23,15,5],
[34,23,15,6],
[34,23,15,8],
[34,23,15,10],
[34,23,15,11],
[34,23,15,12],
[34,23,15,13],
[34,23,15,14],
[34,23,16,0],
[34,23,16,1],
[34,23,16,2],
[34,23,16,3],
[34,23,16,4],
[34,23,16,5],
[34,23,16,6],
[34,23,16,8],
[34,23,16,9],
[34,23,16,10],
[34,23,16,11],
[34,23,16,12],
[34,23,16,13],
[34,23,16,14],
[34,23,16,15],
[34,23,17,0],
[34,23,17,1],
[34,23,17,2],
[34,23,17,3],
[34,23,17,4],
[34,23,17,5],
[34,23,17,6],
[34,23,17,8],
[34,23,17,9],
[34,23,17,10],
[34,23,17,11],
[34,23,17,12],
[34,23,17,13],
[34,23,17,14],
[34,23,17,15],
[34,23,17,16],
[34,23,18,0],
[34,23,18,1],
[34,23,18,2],
[34,23,18,3],
[34,23,18,4],
[34,23,18,5],
[34,23,18,6],
[34,23,18,8],
[34,23,18,10],
[34,23,18,11],
[34,23,18,12],
[34,23,18,13],
[34,23,18,14],
[34,23,18,16],
[34,23,18,17],
[34,23,19,0],
[34,23,19,1],
[34,23,19,2],
[34,23,19,3],
[34,23,19,4],
[34,23,19,5],
[34,23,19,6],
[34,23,19,8],
[34,23,19,9],
[34,23,19,10],
[34,23,19,11],
[34,23,19,12],
[34,23,19,15],
[34,23,19,16],
[34,23,19,17],
[34,23,19,18],
[34,23,20,0],
[34,23,20,1],
[34,23,20,2],
[34,23,20,3],
[34,23,20,4],
[34,23,20,5],
[34,23,20,6],
[34,23,20,8],
[34,23,20,9],
[34,23,20,11],
[34,23,20,12],
[34,23,20,13],
[34,23,20,14],
[34,23,20,15],
[34,23,20,16],
[34,23,20,17],
[34,23,20,18],
[34,23,20,19],
[34,23,21,0],
[34,23,21,1],
[34,23,21,2],
[34,23,21,4],
[34,23,21,9],
[34,23,21,10],
[34,23,21,11],
[34,23,21,12],
[34,23,21,13],
[34,23,21,14],
[34,23,21,15],
[34,23,21,16],
[34,23,21,17],
[34,23,21,18],
[34,23,21,19],
[34,23,21,20],
[34,23,22,0],
[34,23,22,1],
[34,23,22,2],
[34,23,22,3],
[34,23,22,4],
[34,23,22,5],
[34,23,22,6],
[34,23,22,8],
[34,23,22,9],
[34,23,22,11],
[34,23,22,12],
[34,23,22,13],
[34,23,22,14],
[34,23,22,15],
[34,23,22,16],
[34,23,22,17],
[34,23,22,18],
[34,23,22,19],
[34,23,22,21],
[34,24,3,0],
[34,24,3,1],
[34,24,3,2],
[34,24,4,0],
[34,24,4,1],
[34,24,4,2],
[34,24,4,3],
[34,24,5,0],
[34,24,5,1],
[34,24,5,2],
[34,24,5,4],
[34,24,6,0],
[34,24,6,1],
[34,24,6,2],
[34,24,6,4],
[34,24,8,0],
[34,24,8,1],
[34,24,8,2],
[34,24,8,4],
[34,24,9,0],
[34,24,9,1],
[34,24,9,2],
[34,24,9,3],
[34,24,9,4],
[34,24,9,5],
[34,24,9,6],
[34,24,9,8],
[34,24,10,0],
[34,24,10,1],
[34,24,10,2],
[34,24,10,3],
[34,24,10,4],
[34,24,10,5],
[34,24,10,6],
[34,24,10,8],
[34,24,10,9],
[34,24,11,0],
[34,24,11,1],
[34,24,11,2],
[34,24,11,3],
[34,24,11,4],
[34,24,11,5],
[34,24,11,6],
[34,24,11,8],
[34,24,11,9],
[34,24,11,10],
[34,24,12,0],
[34,24,12,1],
[34,24,12,2],
[34,24,12,3],
[34,24,12,4],
[34,24,12,5],
[34,24,12,6],
[34,24,12,8],
[34,24,12,9],
[34,24,12,10],
[34,24,13,0],
[34,24,13,1],
[34,24,13,2],
[34,24,13,3],
[34,24,13,4],
[34,24,13,5],
[34,24,13,6],
[34,24,13,8],
[34,24,13,9],
[34,24,13,10],
[34,24,13,11],
[34,24,13,12],
[34,24,14,0],
[34,24,14,1],
[34,24,14,2],
[34,24,14,3],
[34,24,14,4],
[34,24,14,5],
[34,24,14,6],
[34,24,14,8],
[34,24,14,9],
[34,24,14,10],
[34,24,14,11],
[34,24,14,12],
[34,24,15,0],
[34,24,15,1],
[34,24,15,2],
[34,24,15,3],
[34,24,15,4],
[34,24,15,5],
[34,24,15,6],
[34,24,15,8],
[34,24,15,10],
[34,24,15,11],
[34,24,15,12],
[34,24,15,13],
[34,24,15,14],
[34,24,17,0],
[34,24,17,1],
[34,24,17,2],
[34,24,17,3],
[34,24,17,4],
[34,24,17,5],
[34,24,17,6],
[34,24,17,8],
[34,24,17,9],
[34,24,17,10],
[34,24,17,11],
[34,24,17,12],
[34,24,17,13],
[34,24,17,14],
[34,24,17,15],
[34,24,18,0],
[34,24,18,1],
[34,24,18,2],
[34,24,18,3],
[34,24,18,4],
[34,24,18,5],
[34,24,18,6],
[34,24,18,8],
[34,24,18,10],
[34,24,18,11],
[34,24,18,12],
[34,24,18,13],
[34,24,18,14],
[34,24,18,17],
[34,24,19,0],
[34,24,19,1],
[34,24,19,2],
[34,24,19,3],
[34,24,19,4],
[34,24,19,5],
[34,24,19,6],
[34,24,19,8],
[34,24,19,9],
[34,24,19,10],
[34,24,19,11],
[34,24,19,12],
[34,24,19,15],
[34,24,19,17],
[34,24,19,18],
[34,24,20,0],
[34,24,20,1],
[34,24,20,2],
[34,24,20,3],
[34,24,20,4],
[34,24,20,5],
[34,24,20,6],
[34,24,20,8],
[34,24,20,9],
[34,24,20,11],
[34,24,20,12],
[34,24,20,13],
[34,24,20,14],
[34,24,20,15],
[34,24,20,17],
[34,24,20,18],
[34,24,20,19],
[34,24,21,0],
[34,24,21,1],
[34,24,21,2],
[34,24,21,4],
[34,24,21,9],
[34,24,21,10],
[34,24,21,11],
[34,24,21,12],
[34,24,21,13],
[34,24,21,14],
[34,24,21,15],
[34,24,21,17],
[34,24,21,18],
[34,24,21,19],
[34,24,21,20],
[34,24,22,0],
[34,24,22,1],
[34,24,22,2],
[34,24,22,3],
[34,24,22,4],
[34,24,22,5],
[34,24,22,6],
[34,24,22,8],
[34,24,22,9],
[34,24,22,11],
[34,24,22,12],
[34,24,22,13],
[34,24,22,14],
[34,24,22,15],
[34,24,22,17],
[34,24,22,18],
[34,24,22,19],
[34,24,22,21],
[34,24,23,0],
[34,24,23,1],
[34,24,23,2],
[34,24,23,3],
[34,24,23,4],
[34,24,23,5],
[34,24,23,6],
[34,24,23,8],
[34,24,23,9],
[34,24,23,10],
[34,24,23,11],
[34,24,23,12],
[34,24,23,13],
[34,24,23,14],
[34,24,23,15],
[34,24,23,17],
[34,24,23,18],
[34,24,23,19],
[34,24,23,20],
[34,24,23,21],
[34,24,23,22],
[34,26,3,0],
[34,26,3,1],
[34,26,3,2],
[34,26,4,0],
[34,26,4,1],
[34,26,4,2],
[34,26,4,3],
[34,26,5,0],
[34,26,5,1],
[34,26,5,2],
[34,26,5,4],
[34,26,6,0],
[34,26,6,1],
[34,26,6,2],
[34,26,6,4],
[34,26,8,0],
[34,26,8,1],
[34,26,8,2],
[34,26,8,4],
[34,26,10,0],
[34,26,10,1],
[34,26,10,2],
[34,26,10,3],
[34,26,10,4],
[34,26,10,5],
[34,26,10,6],
[34,26,10,8],
[34,26,11,0],
[34,26,11,1],
[34,26,11,2],
[34,26,11,3],
[34,26,11,4],
[34,26,11,5],
[34,26,11,6],
[34,26,11,8],
[34,26,11,10],
[34,26,12,0],
[34,26,12,1],
[34,26,12,2],
[34,26,12,3],
[34,26,12,4],
[34,26,12,5],
[34,26,12,6],
[34,26,12,8],
[34,26,12,10],
[34,26,13,0],
[34,26,13,1],
[34,26,13,2],
[34,26,13,3],
[34,26,13,4],
[34,26,13,5],
[34,26,13,6],
[34,26,13,8],
[34,26,13,10],
[34,26,13,11],
[34,26,13,12],
[34,26,14,0],
[34,26,14,1],
[34,26,14,2],
[34,26,14,3],
[34,26,14,4],
[34,26,14,5],
[34,26,14,6],
[34,26,14,8],
[34,26,14,10],
[34,26,14,11],
[34,26,14,12],
[34,26,16,0],
[34,26,16,1],
[34,26,16,2],
[34,26,16,3],
[34,26,16,4],
[34,26,16,5],
[34,26,16,6],
[34,26,16,8],
[34,26,16,10],
[34,26,16,11],
[34,26,16,12],
[34,26,16,13],
[34,26,16,14],
[34,26,17,0],
[34,26,17,1],
[34,26,17,2],
[34,26,17,3],
[34,26,17,4],
[34,26,17,5],
[34,26,17,6],
[34,26,17,8],
[34,26,17,10],
[34,26,17,11],
[34,26,17,12],
[34,26,17,13],
[34,26,17,14],
[34,26,17,16],
[34,26,19,0],
[34,26,19,1],
[34,26,19,2],
[34,26,19,3],
[34,26,19,4],
[34,26,19,5],
[34,26,19,6],
[34,26,19,8],
[34,26,19,10],
[34,26,19,11],
[34,26,19,12],
[34,26,19,16],
[34,26,19,17],
[34,26,20,0],
[34,26,20,1],
[34,26,20,2],
[34,26,20,3],
[34,26,20,4],
[34,26,20,5],
[34,26,20,6],
[34,26,20,8],
[34,26,20,11],
[34,26,20,12],
[34,26,20,13],
[34,26,20,14],
[34,26,20,16],
[34,26,20,17],
[34,26,20,19],
[34,26,21,0],
[34,26,21,1],
[34,26,21,2],
[34,26,21,4],
[34,26,21,10],
[34,26,21,11],
[34,26,21,12],
[34,26,21,13],
[34,26,21,14],
[34,26,21,16],
[34,26,21,17],
[34,26,21,19],
[34,26,21,20],
[34,26,22,0],
[34,26,22,1],
[34,26,22,2],
[34,26,22,3],
[34,26,22,4],
[34,26,22,5],
[34,26,22,6],
[34,26,22,8],
[34,26,22,11],
[34,26,22,12],
[34,26,22,13],
[34,26,22,14],
[34,26,22,16],
[34,26,22,17],
[34,26,22,19],
[34,26,22,21],
[34,26,23,0],
[34,26,23,1],
[34,26,23,2],
[34,26,23,3],
[34,26,23,4],
[34,26,23,5],
[34,26,23,6],
[34,26,23,8],
[34,26,23,10],
[34,26,23,11],
[34,26,23,12],
[34,26,23,13],
[34,26,23,14],
[34,26,23,16],
[34,26,23,17],
[34,26,23,19],
[34,26,23,20],
[34,26,23,21],
[34,26,23,22],
[34,26,24,0],
[34,26,24,1],
[34,26,24,2],
[34,26,24,3],
[34,26,24,4],
[34,26,24,5],
[34,26,24,6],
[34,26,24,8],
[34,26,24,10],
[34,26,24,11],
[34,26,24,12],
[34,26,24,13],
[34,26,24,14],
[34,26,24,17],
[34,26,24,19],
[34,26,24,20],
[34,26,24,21],
[34,26,24,22],
[34,26,24,23],
[34,27,3,0],
[34,27,3,1],
[34,27,3,2],
[34,27,4,0],
[34,27,4,1],
[34,27,4,2],
[34,27,4,3],
[34,27,5,0],
[34,27,5,1],
[34,27,5,2],
[34,27,5,4],
[34,27,6,0],
[34,27,6,1],
[34,27,6,2],
[34,27,6,4],
[34,27,8,0],
[34,27,8,1],
[34,27,8,2],
[34,27,8,4],
[34,27,9,0],
[34,27,9,1],
[34,27,9,2],
[34,27,9,3],
[34,27,9,4],
[34,27,9,5],
[34,27,9,6],
[34,27,9,8],
[34,27,11,0],
[34,27,11,1],
[34,27,11,2],
[34,27,11,3],
[34,27,11,4],
[34,27,11,5],
[34,27,11,6],
[34,27,11,8],
[34,27,11,9],
[34,27,12,0],
[34,27,12,1],
[34,27,12,2],
[34,27,12,3],
[34,27,12,4],
[34,27,12,5],
[34,27,12,6],
[34,27,12,8],
[34,27,12,9],
[34,27,13,0],
[34,27,13,1],
[34,27,13,2],
[34,27,13,3],
[34,27,13,4],
[34,27,13,5],
[34,27,13,6],
[34,27,13,8],
[34,27,13,9],
[34,27,13,11],
[34,27,13,12],
[34,27,14,0],
[34,27,14,1],
[34,27,14,2],
[34,27,14,3],
[34,27,14,4],
[34,27,14,5],
[34,27,14,6],
[34,27,14,8],
[34,27,14,9],
[34,27,14,11],
[34,27,14,12],
[34,27,15,0],
[34,27,15,1],
[34,27,15,2],
[34,27,15,3],
[34,27,15,4],
[34,27,15,5],
[34,27,15,6],
[34,27,15,8],
[34,27,15,11],
[34,27,15,12],
[34,27,15,13],
[34,27,15,14],
[34,27,16,0],
[34,27,16,1],
[34,27,16,2],
[34,27,16,3],
[34,27,16,4],
[34,27,16,5],
[34,27,16,6],
[34,27,16,8],
[34,27,16,9],
[34,27,16,11],
[34,27,16,12],
[34,27,16,13],
[34,27,16,14],
[34,27,16,15],
[34,27,17,0],
[34,27,17,1],
[34,27,17,2],
[34,27,17,3],
[34,27,17,4],
[34,27,17,5],
[34,27,17,6],
[34,27,17,8],
[34,27,17,9],
[34,27,17,11],
[34,27,17,12],
[34,27,17,13],
[34,27,17,14],
[34,27,17,15],
[34,27,17,16],
[34,27,18,0],
[34,27,18,1],
[34,27,18,2],
[34,27,18,3],
[34,27,18,4],
[34,27,18,5],
[34,27,18,6],
[34,27,18,8],
[34,27,18,11],
[34,27,18,12],
[34,27,18,13],
[34,27,18,14],
[34,27,18,16],
[34,27,18,17],
[34,27,19,0],
[34,27,19,1],
[34,27,19,2],
[34,27,19,3],
[34,27,19,4],
[34,27,19,5],
[34,27,19,6],
[34,27,19,8],
[34,27,19,9],
[34,27,19,11],
[34,27,19,12],
[34,27,19,15],
[34,27,19,16],
[34,27,19,17],
[34,27,19,18],
[34,27,21,0],
[34,27,21,1],
[34,27,21,2],
[34,27,21,4],
[34,27,21,9],
[34,27,21,11],
[34,27,21,12],
[34,27,21,13],
[34,27,21,14],
[34,27,21,15],
[34,27,21,16],
[34,27,21,17],
[34,27,21,18],
[34,27,21,19],
[34,27,23,0],
[34,27,23,1],
[34,27,23,2],
[34,27,23,3],
[34,27,23,4],
[34,27,23,5],
[34,27,23,6],
[34,27,23,8],
[34,27,23,9],
[34,27,23,11],
[34,27,23,12],
[34,27,23,13],
[34,27,23,14],
[34,27,23,15],
[34,27,23,16],
[34,27,23,17],
[34,27,23,18],
[34,27,23,19],
[34,27,23,21],
[34,27,24,0],
[34,27,24,1],
[34,27,24,2],
[34,27,24,3],
[34,27,24,4],
[34,27,24,5],
[34,27,24,6],
[34,27,24,8],
[34,27,24,9],
[34,27,24,11],
[34,27,24,12],
[34,27,24,13],
[34,27,24,14],
[34,27,24,15],
[34,27,24,17],
[34,27,24,18],
[34,27,24,19],
[34,27,24,21],
[34,27,24,23],
[34,27,26,0],
[34,27,26,1],
[34,27,26,2],
[34,27,26,3],
[34,27,26,4],
[34,27,26,5],
[34,27,26,6],
[34,27,26,8],
[34,27,26,11],
[34,27,26,12],
[34,27,26,13],
[34,27,26,14],
[34,27,26,16],
[34,27,26,17],
[34,27,26,19],
[34,27,26,21],
[34,27,26,23],
[34,27,26,24],
[34,29,3,0],
[34,29,3,1],
[34,29,3,2],
[34,29,4,0],
[34,29,4,1],
[34,29,4,2],
[34,29,4,3],
[34,29,5,0],
[34,29,5,1],
[34,29,5,2],
[34,29,5,4],
[34,29,6,0],
[34,29,6,1],
[34,29,6,2],
[34,29,6,4],
[34,29,8,0],
[34,29,8,1],
[34,29,8,2],
[34,29,8,4],
[34,29,9,0],
[34,29,9,1],
[34,29,9,2],
[34,29,9,3],
[34,29,9,4],
[34,29,9,5],
[34,29,9,6],
[34,29,9,8],
[34,29,10,0],
[34,29,10,1],
[34,29,10,2],
[34,29,10,3],
[34,29,10,4],
[34,29,10,5],
[34,29,10,6],
[34,29,10,8],
[34,29,10,9],
[34,29,13,0],
[34,29,13,1],
[34,29,13,2],
[34,29,13,3],
[34,29,13,4],
[34,29,13,5],
[34,29,13,6],
[34,29,13,8],
[34,29,13,9],
[34,29,13,10],
[34,29,14,0],
[34,29,14,1],
[34,29,14,2],
[34,29,14,3],
[34,29,14,4],
[34,29,14,5],
[34,29,14,6],
[34,29,14,8],
[34,29,14,9],
[34,29,14,10],
[34,29,15,0],
[34,29,15,1],
[34,29,15,2],
[34,29,15,3],
[34,29,15,4],
[34,29,15,5],
[34,29,15,6],
[34,29,15,8],
[34,29,15,10],
[34,29,15,13],
[34,29,15,14],
[34,29,16,0],
[34,29,16,1],
[34,29,16,2],
[34,29,16,3],
[34,29,16,4],
[34,29,16,5],
[34,29,16,6],
[34,29,16,8],
[34,29,16,9],
[34,29,16,10],
[34,29,16,13],
[34,29,16,14],
[34,29,16,15],
[34,29,17,0],
[34,29,17,1],
[34,29,17,2],
[34,29,17,3],
[34,29,17,4],
[34,29,17,5],
[34,29,17,6],
[34,29,17,8],
[34,29,17,9],
[34,29,17,10],
[34,29,17,13],
[34,29,17,14],
[34,29,17,15],
[34,29,17,16],
[34,29,18,0],
[34,29,18,1],
[34,29,18,2],
[34,29,18,3],
[34,29,18,4],
[34,29,18,5],
[34,29,18,6],
[34,29,18,8],
[34,29,18,10],
[34,29,18,13],
[34,29,18,14],
[34,29,18,16],
[34,29,18,17],
[34,29,19,0],
[34,29,19,1],
[34,29,19,2],
[34,29,19,3],
[34,29,19,4],
[34,29,19,5],
[34,29,19,6],
[34,29,19,8],
[34,29,19,9],
[34,29,19,10],
[34,29,19,15],
[34,29,19,16],
[34,29,19,17],
[34,29,19,18],
[34,29,20,0],
[34,29,20,1],
[34,29,20,2],
[34,29,20,3],
[34,29,20,4],
[34,29,20,5],
[34,29,20,6],
[34,29,20,8],
[34,29,20,9],
[34,29,20,13],
[34,29,20,14],
[34,29,20,15],
[34,29,20,16],
[34,29,20,17],
[34,29,20,18],
[34,29,20,19],
[34,29,21,0],
[34,29,21,1],
[34,29,21,2],
[34,29,21,4],
[34,29,21,9],
[34,29,21,10],
[34,29,21,13],
[34,29,21,14],
[34,29,21,15],
[34,29,21,16],
[34,29,21,17],
[34,29,21,18],
[34,29,21,19],
[34,29,21,20],
[34,29,22,0],
[34,29,22,1],
[34,29,22,2],
[34,29,22,3],
[34,29,22,4],
[34,29,22,5],
[34,29,22,6],
[34,29,22,8],
[34,29,22,9],
[34,29,22,13],
[34,29,22,14],
[34,29,22,15],
[34,29,22,16],
[34,29,22,17],
[34,29,22,18],
[34,29,22,19],
[34,29,22,21],
[34,29,23,0],
[34,29,23,1],
[34,29,23,2],
[34,29,23,3],
[34,29,23,4],
[34,29,23,5],
[34,29,23,6],
[34,29,23,8],
[34,29,23,9],
[34,29,23,10],
[34,29,23,13],
[34,29,23,14],
[34,29,23,15],
[34,29,23,16],
[34,29,23,17],
[34,29,23,18],
[34,29,23,19],
[34,29,23,20],
[34,29,23,21],
[34,29,23,22],
[34,29,24,0],
[34,29,24,1],
[34,29,24,2],
[34,29,24,3],
[34,29,24,4],
[34,29,24,5],
[34,29,24,6],
[34,29,24,8],
[34,29,24,9],
[34,29,24,10],
[34,29,24,13],
[34,29,24,14],
[34,29,24,15],
[34,29,24,17],
[34,29,24,18],
[34,29,24,19],
[34,29,24,20],
[34,29,24,21],
[34,29,24,22],
[34,29,24,23],
[34,29,26,0],
[34,29,26,1],
[34,29,26,2],
[34,29,26,3],
[34,29,26,4],
[34,29,26,5],
[34,29,26,6],
[34,29,26,8],
[34,29,26,10],
[34,29,26,13],
[34,29,26,14],
[34,29,26,16],
[34,29,26,17],
[34,29,26,19],
[34,29,26,20],
[34,29,26,21],
[34,29,26,22],
[34,29,26,23],
[34,29,26,24],
[34,29,27,0],
[34,29,27,1],
[34,29,27,2],
[34,29,27,3],
[34,29,27,4],
[34,29,27,5],
[34,29,27,6],
[34,29,27,8],
[34,29,27,9],
[34,29,27,13],
[34,29,27,14],
[34,29,27,15],
[34,29,27,16],
[34,29,27,17],
[34,29,27,18],
[34,29,27,19],
[34,29,27,21],
[34,29,27,23],
[34,29,27,24],
[34,29,27,26],
[34,30,3,0],
[34,30,3,1],
[34,30,3,2],
[34,30,4,0],
[34,30,4,1],
[34,30,4,2],
[34,30,4,3],
[34,30,5,0],
[34,30,5,1],
[34,30,5,2],
[34,30,5,4],
[34,30,6,0],
[34,30,6,1],
[34,30,6,2],
[34,30,6,4],
[34,30,8,0],
[34,30,8,1],
[34,30,8,2],
[34,30,8,4],
[34,30,9,0],
[34,30,9,1],
[34,30,9,2],
[34,30,9,3],
[34,30,9,4],
[34,30,9,5],
[34,30,9,6],
[34,30,9,8],
[34,30,10,0],
[34,30,10,1],
[34,30,10,2],
[34,30,10,3],
[34,30,10,4],
[34,30,10,5],
[34,30,10,6],
[34,30,10,8],
[34,30,10,9],
[34,30,11,0],
[34,30,11,1],
[34,30,11,2],
[34,30,11,3],
[34,30,11,4],
[34,30,11,5],
[34,30,11,6],
[34,30,11,8],
[34,30,11,9],
[34,30,11,10],
[34,30,12,0],
[34,30,12,1],
[34,30,12,2],
[34,30,12,3],
[34,30,12,4],
[34,30,12,5],
[34,30,12,6],
[34,30,12,8],
[34,30,12,9],
[34,30,12,10],
[34,30,13,0],
[34,30,13,1],
[34,30,13,2],
[34,30,13,3],
[34,30,13,4],
[34,30,13,5],
[34,30,13,6],
[34,30,13,8],
[34,30,13,9],
[34,30,13,10],
[34,30,13,11],
[34,30,13,12],
[34,30,14,0],
[34,30,14,1],
[34,30,14,2],
[34,30,14,3],
[34,30,14,4],
[34,30,14,5],
[34,30,14,6],
[34,30,14,8],
[34,30,14,9],
[34,30,14,10],
[34,30,14,11],
[34,30,14,12],
[34,30,15,0],
[34,30,15,1],
[34,30,15,2],
[34,30,15,3],
[34,30,15,4],
[34,30,15,5],
[34,30,15,6],
[34,30,15,8],
[34,30,15,10],
[34,30,15,11],
[34,30,15,12],
[34,30,15,13],
[34,30,15,14],
[34,30,16,0],
[34,30,16,1],
[34,30,16,2],
[34,30,16,3],
[34,30,16,4],
[34,30,16,5],
[34,30,16,6],
[34,30,16,8],
[34,30,16,9],
[34,30,16,10],
[34,30,16,11],
[34,30,16,12],
[34,30,16,13],
[34,30,16,14],
[34,30,16,15],
[34,30,17,0],
[34,30,17,1],
[34,30,17,2],
[34,30,17,3],
[34,30,17,4],
[34,30,17,5],
[34,30,17,6],
[34,30,17,8],
[34,30,17,9],
[34,30,17,10],
[34,30,17,11],
[34,30,17,12],
[34,30,17,13],
[34,30,17,14],
[34,30,17,15],
[34,30,17,16],
[34,30,18,0],
[34,30,18,1],
[34,30,18,2],
[34,30,18,3],
[34,30,18,4],
[34,30,18,5],
[34,30,18,6],
[34,30,18,8],
[34,30,18,10],
[34,30,18,11],
[34,30,18,12],
[34,30,18,13],
[34,30,18,14],
[34,30,18,16],
[34,30,18,17],
[34,30,19,0],
[34,30,19,1],
[34,30,19,2],
[34,30,19,3],
[34,30,19,4],
[34,30,19,5],
[34,30,19,6],
[34,30,19,8],
[34,30,19,9],
[34,30,19,10],
[34,30,19,11],
[34,30,19,12],
[34,30,19,15],
[34,30,19,16],
[34,30,19,17],
[34,30,19,18],
[34,30,20,0],
[34,30,20,1],
[34,30,20,2],
[34,30,20,3],
[34,30,20,4],
[34,30,20,5],
[34,30,20,6],
[34,30,20,8],
[34,30,20,9],
[34,30,20,11],
[34,30,20,12],
[34,30,20,13],
[34,30,20,14],
[34,30,20,15],
[34,30,20,16],
[34,30,20,17],
[34,30,20,18],
[34,30,20,19],
[34,30,21,0],
[34,30,21,1],
[34,30,21,2],
[34,30,21,4],
[34,30,21,9],
[34,30,21,10],
[34,30,21,11],
[34,30,21,12],
[34,30,21,13],
[34,30,21,14],
[34,30,21,15],
[34,30,21,16],
[34,30,21,17],
[34,30,21,18],
[34,30,21,19],
[34,30,21,20],
[34,30,22,0],
[34,30,22,1],
[34,30,22,2],
[34,30,22,3],
[34,30,22,4],
[34,30,22,5],
[34,30,22,6],
[34,30,22,8],
[34,30,22,9],
[34,30,22,11],
[34,30,22,12],
[34,30,22,13],
[34,30,22,14],
[34,30,22,15],
[34,30,22,16],
[34,30,22,17],
[34,30,22,18],
[34,30,22,19],
[34,30,22,21],
[34,30,24,0],
[34,30,24,1],
[34,30,24,2],
[34,30,24,3],
[34,30,24,4],
[34,30,24,5],
[34,30,24,6],
[34,30,24,8],
[34,30,24,9],
[34,30,24,10],
[34,30,24,11],
[34,30,24,12],
[34,30,24,13],
[34,30,24,14],
[34,30,24,15],
[34,30,24,17],
[34,30,24,18],
[34,30,24,19],
[34,30,24,20],
[34,30,24,21],
[34,30,24,22],
[34,30,26,0],
[34,30,26,1],
[34,30,26,2],
[34,30,26,3],
[34,30,26,4],
[34,30,26,5],
[34,30,26,6],
[34,30,26,8],
[34,30,26,10],
[34,30,26,11],
[34,30,26,12],
[34,30,26,13],
[34,30,26,14],
[34,30,26,16],
[34,30,26,17],
[34,30,26,19],
[34,30,26,20],
[34,30,26,21],
[34,30,26,22],
[34,30,26,24],
[34,30,27,0],
[34,30,27,1],
[34,30,27,2],
[34,30,27,3],
[34,30,27,4],
[34,30,27,5],
[34,30,27,6],
[34,30,27,8],
[34,30,27,9],
[34,30,27,11],
[34,30,27,12],
[34,30,27,13],
[34,30,27,14],
[34,30,27,15],
[34,30,27,16],
[34,30,27,17],
[34,30,27,18],
[34,30,27,19],
[34,30,27,21],
[34,30,27,24],
[34,30,27,26],
[34,30,29,0],
[34,30,29,1],
[34,30,29,2],
[34,30,29,3],
[34,30,29,4],
[34,30,29,5],
[34,30,29,6],
[34,30,29,8],
[34,30,29,9],
[34,30,29,10],
[34,30,29,13],
[34,30,29,14],
[34,30,29,15],
[34,30,29,16],
[34,30,29,17],
[34,30,29,18],
[34,30,29,19],
[34,30,29,20],
[34,30,29,21],
[34,30,29,22],
[34,30,29,24],
[34,30,29,26],
[34,30,29,27],
[34,31,3,0],
[34,31,3,1],
[34,31,3,2],
[34,31,4,0],
[34,31,4,1],
[34,31,4,2],
[34,31,4,3],
[34,31,5,0],
[34,31,5,1],
[34,31,5,2],
[34,31,5,4],
[34,31,6,0],
[34,31,6,1],
[34,31,6,2],
[34,31,6,4],
[34,31,8,0],
[34,31,8,1],
[34,31,8,2],
[34,31,8,4],
[34,31,9,0],
[34,31,9,1],
[34,31,9,2],
[34,31,9,3],
[34,31,9,4],
[34,31,9,5],
[34,31,9,6],
[34,31,9,8],
[34,31,10,0],
[34,31,10,1],
[34,31,10,2],
[34,31,10,3],
[34,31,10,4],
[34,31,10,5],
[34,31,10,6],
[34,31,10,8],
[34,31,10,9],
[34,31,11,0],
[34,31,11,1],
[34,31,11,2],
[34,31,11,3],
[34,31,11,4],
[34,31,11,5],
[34,31,11,6],
[34,31,11,8],
[34,31,11,9],
[34,31,11,10],
[34,31,12,0],
[34,31,12,1],
[34,31,12,2],
[34,31,12,3],
[34,31,12,4],
[34,31,12,5],
[34,31,12,6],
[34,31,12,8],
[34,31,12,9],
[34,31,12,10],
[34,31,13,0],
[34,31,13,1],
[34,31,13,2],
[34,31,13,3],
[34,31,13,4],
[34,31,13,5],
[34,31,13,6],
[34,31,13,8],
[34,31,13,9],
[34,31,13,10],
[34,31,13,11],
[34,31,13,12],
[34,31,14,0],
[34,31,14,1],
[34,31,14,2],
[34,31,14,3],
[34,31,14,4],
[34,31,14,5],
[34,31,14,6],
[34,31,14,8],
[34,31,14,9],
[34,31,14,10],
[34,31,14,11],
[34,31,14,12],
[34,31,15,0],
[34,31,15,1],
[34,31,15,2],
[34,31,15,3],
[34,31,15,4],
[34,31,15,5],
[34,31,15,6],
[34,31,15,8],
[34,31,15,10],
[34,31,15,11],
[34,31,15,12],
[34,31,15,13],
[34,31,15,14],
[34,31,16,0],
[34,31,16,1],
[34,31,16,2],
[34,31,16,3],
[34,31,16,4],
[34,31,16,5],
[34,31,16,6],
[34,31,16,8],
[34,31,16,9],
[34,31,16,10],
[34,31,16,11],
[34,31,16,12],
[34,31,16,13],
[34,31,16,14],
[34,31,16,15],
[34,31,17,0],
[34,31,17,1],
[34,31,17,2],
[34,31,17,3],
[34,31,17,4],
[34,31,17,5],
[34,31,17,6],
[34,31,17,8],
[34,31,17,9],
[34,31,17,10],
[34,31,17,11],
[34,31,17,12],
[34,31,17,13],
[34,31,17,14],
[34,31,17,15],
[34,31,17,16],
[34,31,18,0],
[34,31,18,1],
[34,31,18,2],
[34,31,18,3],
[34,31,18,4],
[34,31,18,5],
[34,31,18,6],
[34,31,18,8],
[34,31,18,10],
[34,31,18,11],
[34,31,18,12],
[34,31,18,13],
[34,31,18,14],
[34,31,18,16],
[34,31,18,17],
[34,31,19,0],
[34,31,19,1],
[34,31,19,2],
[34,31,19,3],
[34,31,19,4],
[34,31,19,5],
[34,31,19,6],
[34,31,19,8],
[34,31,19,9],
[34,31,19,10],
[34,31,19,11],
[34,31,19,12],
[34,31,19,15],
[34,31,19,16],
[34,31,19,17],
[34,31,19,18],
[34,31,20,0],
[34,31,20,1],
[34,31,20,2],
[34,31,20,3],
[34,31,20,4],
[34,31,20,5],
[34,31,20,6],
[34,31,20,8],
[34,31,20,9],
[34,31,20,11],
[34,31,20,12],
[34,31,20,13],
[34,31,20,14],
[34,31,20,15],
[34,31,20,16],
[34,31,20,17],
[34,31,20,18],
[34,31,20,19],
[34,31,21,0],
[34,31,21,1],
[34,31,21,2],
[34,31,21,4],
[34,31,21,9],
[34,31,21,10],
[34,31,21,11],
[34,31,21,12],
[34,31,21,13],
[34,31,21,14],
[34,31,21,15],
[34,31,21,16],
[34,31,21,17],
[34,31,21,18],
[34,31,21,19],
[34,31,21,20],
[34,31,22,0],
[34,31,22,1],
[34,31,22,2],
[34,31,22,3],
[34,31,22,4],
[34,31,22,5],
[34,31,22,6],
[34,31,22,8],
[34,31,22,9],
[34,31,22,11],
[34,31,22,12],
[34,31,22,13],
[34,31,22,14],
[34,31,22,15],
[34,31,22,16],
[34,31,22,17],
[34,31,22,18],
[34,31,22,19],
[34,31,22,21],
[34,31,24,0],
[34,31,24,1],
[34,31,24,2],
[34,31,24,3],
[34,31,24,4],
[34,31,24,5],
[34,31,24,6],
[34,31,24,8],
[34,31,24,9],
[34,31,24,10],
[34,31,24,11],
[34,31,24,12],
[34,31,24,13],
[34,31,24,14],
[34,31,24,15],
[34,31,24,17],
[34,31,24,18],
[34,31,24,19],
[34,31,24,20],
[34,31,24,21],
[34,31,24,22],
[34,31,26,0],
[34,31,26,1],
[34,31,26,2],
[34,31,26,3],
[34,31,26,4],
[34,31,26,5],
[34,31,26,6],
[34,31,26,8],
[34,31,26,10],
[34,31,26,11],
[34,31,26,12],
[34,31,26,13],
[34,31,26,14],
[34,31,26,16],
[34,31,26,17],
[34,31,26,19],
[34,31,26,20],
[34,31,26,21],
[34,31,26,22],
[34,31,26,24],
[34,31,27,0],
[34,31,27,1],
[34,31,27,2],
[34,31,27,3],
[34,31,27,4],
[34,31,27,5],
[34,31,27,6],
[34,31,27,8],
[34,31,27,9],
[34,31,27,11],
[34,31,27,12],
[34,31,27,13],
[34,31,27,14],
[34,31,27,15],
[34,31,27,16],
[34,31,27,17],
[34,31,27,18],
[34,31,27,19],
[34,31,27,21],
[34,31,27,24],
[34,31,27,26],
[34,31,29,0],
[34,31,29,1],
[34,31,29,2],
[34,31,29,3],
[34,31,29,4],
[34,31,29,5],
[34,31,29,6],
[34,31,29,8],
[34,31,29,9],
[34,31,29,10],
[34,31,29,13],
[34,31,29,14],
[34,31,29,15],
[34,31,29,16],
[34,31,29,17],
[34,31,29,18],
[34,31,29,19],
[34,31,29,20],
[34,31,29,21],
[34,31,29,22],
[34,31,29,24],
[34,31,29,26],
[34,31,29,27],
[34,32,3,0],
[34,32,3,1],
[34,32,3,2],
[34,32,4,0],
[34,32,4,1],
[34,32,4,2],
[34,32,4,3],
[34,32,5,0],
[34,32,5,1],
[34,32,5,2],
[34,32,5,4],
[34,32,6,0],
[34,32,6,1],
[34,32,6,2],
[34,32,6,4],
[34,32,8,0],
[34,32,8,1],
[34,32,8,2],
[34,32,8,4],
[34,32,9,0],
[34,32,9,1],
[34,32,9,2],
[34,32,9,3],
[34,32,9,4],
[34,32,9,5],
[34,32,9,6],
[34,32,9,8],
[34,32,10,0],
[34,32,10,1],
[34,32,10,2],
[34,32,10,3],
[34,32,10,4],
[34,32,10,5],
[34,32,10,6],
[34,32,10,8],
[34,32,10,9],
[34,32,13,0],
[34,32,13,1],
[34,32,13,2],
[34,32,13,3],
[34,32,13,4],
[34,32,13,5],
[34,32,13,6],
[34,32,13,8],
[34,32,13,9],
[34,32,13,10],
[34,32,14,0],
[34,32,14,1],
[34,32,14,2],
[34,32,14,3],
[34,32,14,4],
[34,32,14,5],
[34,32,14,6],
[34,32,14,8],
[34,32,14,9],
[34,32,14,10],
[34,32,15,0],
[34,32,15,1],
[34,32,15,2],
[34,32,15,3],
[34,32,15,4],
[34,32,15,5],
[34,32,15,6],
[34,32,15,8],
[34,32,15,10],
[34,32,15,13],
[34,32,15,14],
[34,32,16,0],
[34,32,16,1],
[34,32,16,2],
[34,32,16,3],
[34,32,16,4],
[34,32,16,5],
[34,32,16,6],
[34,32,16,8],
[34,32,16,9],
[34,32,16,10],
[34,32,16,13],
[34,32,16,14],
[34,32,16,15],
[34,32,17,0],
[34,32,17,1],
[34,32,17,2],
[34,32,17,3],
[34,32,17,4],
[34,32,17,5],
[34,32,17,6],
[34,32,17,8],
[34,32,17,9],
[34,32,17,10],
[34,32,17,13],
[34,32,17,14],
[34,32,17,15],
[34,32,17,16],
[34,32,18,0],
[34,32,18,1],
[34,32,18,2],
[34,32,18,3],
[34,32,18,4],
[34,32,18,5],
[34,32,18,6],
[34,32,18,8],
[34,32,18,10],
[34,32,18,13],
[34,32,18,14],
[34,32,18,16],
[34,32,18,17],
[34,32,19,0],
[34,32,19,1],
[34,32,19,2],
[34,32,19,3],
[34,32,19,4],
[34,32,19,5],
[34,32,19,6],
[34,32,19,8],
[34,32,19,9],
[34,32,19,10],
[34,32,19,15],
[34,32,19,16],
[34,32,19,17],
[34,32,19,18],
[34,32,20,0],
[34,32,20,1],
[34,32,20,2],
[34,32,20,3],
[34,32,20,4],
[34,32,20,5],
[34,32,20,6],
[34,32,20,8],
[34,32,20,9],
[34,32,20,13],
[34,32,20,14],
[34,32,20,15],
[34,32,20,16],
[34,32,20,17],
[34,32,20,18],
[34,32,20,19],
[34,32,21,0],
[34,32,21,1],
[34,32,21,2],
[34,32,21,4],
[34,32,21,9],
[34,32,21,10],
[34,32,21,13],
[34,32,21,14],
[34,32,21,15],
[34,32,21,16],
[34,32,21,17],
[34,32,21,18],
[34,32,21,19],
[34,32,21,20],
[34,32,22,0],
[34,32,22,1],
[34,32,22,2],
[34,32,22,3],
[34,32,22,4],
[34,32,22,5],
[34,32,22,6],
[34,32,22,8],
[34,32,22,9],
[34,32,22,13],
[34,32,22,14],
[34,32,22,15],
[34,32,22,16],
[34,32,22,17],
[34,32,22,18],
[34,32,22,19],
[34,32,22,21],
[34,32,23,0],
[34,32,23,1],
[34,32,23,2],
[34,32,23,3],
[34,32,23,4],
[34,32,23,5],
[34,32,23,6],
[34,32,23,8],
[34,32,23,9],
[34,32,23,10],
[34,32,23,13],
[34,32,23,14],
[34,32,23,15],
[34,32,23,16],
[34,32,23,17],
[34,32,23,18],
[34,32,23,19],
[34,32,23,20],
[34,32,23,21],
[34,32,23,22],
[34,32,24,0],
[34,32,24,1],
[34,32,24,2],
[34,32,24,3],
[34,32,24,4],
[34,32,24,5],
[34,32,24,6],
[34,32,24,8],
[34,32,24,9],
[34,32,24,10],
[34,32,24,13],
[34,32,24,14],
[34,32,24,15],
[34,32,24,17],
[34,32,24,18],
[34,32,24,19],
[34,32,24,20],
[34,32,24,21],
[34,32,24,22],
[34,32,24,23],
[34,32,26,0],
[34,32,26,1],
[34,32,26,2],
[34,32,26,3],
[34,32,26,4],
[34,32,26,5],
[34,32,26,6],
[34,32,26,8],
[34,32,26,10],
[34,32,26,13],
[34,32,26,14],
[34,32,26,16],
[34,32,26,17],
[34,32,26,19],
[34,32,26,20],
[34,32,26,21],
[34,32,26,22],
[34,32,26,23],
[34,32,26,24],
[34,32,27,0],
[34,32,27,1],
[34,32,27,2],
[34,32,27,3],
[34,32,27,4],
[34,32,27,5],
[34,32,27,6],
[34,32,27,8],
[34,32,27,9],
[34,32,27,13],
[34,32,27,14],
[34,32,27,15],
[34,32,27,16],
[34,32,27,17],
[34,32,27,18],
[34,32,27,19],
[34,32,27,21],
[34,32,27,23],
[34,32,27,24],
[34,32,27,26],
[34,32,30,0],
[34,32,30,1],
[34,32,30,2],
[34,32,30,3],
[34,32,30,4],
[34,32,30,5],
[34,32,30,6],
[34,32,30,8],
[34,32,30,9],
[34,32,30,10],
[34,32,30,13],
[34,32,30,14],
[34,32,30,15],
[34,32,30,16],
[34,32,30,17],
[34,32,30,18],
[34,32,30,19],
[34,32,30,20],
[34,32,30,21],
[34,32,30,22],
[34,32,30,24],
[34,32,30,26],
[34,32,30,27],
[34,32,31,0],
[34,32,31,1],
[34,32,31,2],
[34,32,31,3],
[34,32,31,4],
[34,32,31,5],
[34,32,31,6],
[34,32,31,8],
[34,32,31,9],
[34,32,31,10],
[34,32,31,13],
[34,32,31,14],
[34,32,31,15],
[34,32,31,16],
[34,32,31,17],
[34,32,31,18],
[34,32,31,19],
[34,32,31,20],
[34,32,31,21],
[34,32,31,22],
[34,32,31,24],
[34,32,31,26],
[34,32,31,27],
[34,33,3,0],
[34,33,3,1],
[34,33,3,2],
[34,33,4,0],
[34,33,4,1],
[34,33,4,2],
[34,33,4,3],
[34,33,5,0],
[34,33,5,1],
[34,33,5,2],
[34,33,5,4],
[34,33,6,0],
[34,33,6,1],
[34,33,6,2],
[34,33,6,4],
[34,33,8,0],
[34,33,8,1],
[34,33,8,2],
[34,33,8,4],
[34,33,9,0],
[34,33,9,1],
[34,33,9,2],
[34,33,9,3],
[34,33,9,4],
[34,33,9,5],
[34,33,9,6],
[34,33,9,8],
[34,33,10,0],
[34,33,10,1],
[34,33,10,2],
[34,33,10,3],
[34,33,10,4],
[34,33,10,5],
[34,33,10,6],
[34,33,10,8],
[34,33,10,9],
[34,33,13,0],
[34,33,13,1],
[34,33,13,2],
[34,33,13,3],
[34,33,13,4],
[34,33,13,5],
[34,33,13,6],
[34,33,13,8],
[34,33,13,9],
[34,33,13,10],
[34,33,14,0],
[34,33,14,1],
[34,33,14,2],
[34,33,14,3],
[34,33,14,4],
[34,33,14,5],
[34,33,14,6],
[34,33,14,8],
[34,33,14,9],
[34,33,14,10],
[34,33,15,0],
[34,33,15,1],
[34,33,15,2],
[34,33,15,3],
[34,33,15,4],
[34,33,15,5],
[34,33,15,6],
[34,33,15,8],
[34,33,15,10],
[34,33,15,13],
[34,33,15,14],
[34,33,16,0],
[34,33,16,1],
[34,33,16,2],
[34,33,16,3],
[34,33,16,4],
[34,33,16,5],
[34,33,16,6],
[34,33,16,8],
[34,33,16,9],
[34,33,16,10],
[34,33,16,13],
[34,33,16,14],
[34,33,16,15],
[34,33,17,0],
[34,33,17,1],
[34,33,17,2],
[34,33,17,3],
[34,33,17,4],
[34,33,17,5],
[34,33,17,6],
[34,33,17,8],
[34,33,17,9],
[34,33,17,10],
[34,33,17,13],
[34,33,17,14],
[34,33,17,15],
[34,33,17,16],
[34,33,18,0],
[34,33,18,1],
[34,33,18,2],
[34,33,18,3],
[34,33,18,4],
[34,33,18,5],
[34,33,18,6],
[34,33,18,8],
[34,33,18,10],
[34,33,18,13],
[34,33,18,14],
[34,33,18,16],
[34,33,18,17],
[34,33,19,0],
[34,33,19,1],
[34,33,19,2],
[34,33,19,3],
[34,33,19,4],
[34,33,19,5],
[34,33,19,6],
[34,33,19,8],
[34,33,19,9],
[34,33,19,10],
[34,33,19,15],
[34,33,19,16],
[34,33,19,17],
[34,33,19,18],
[34,33,20,0],
[34,33,20,1],
[34,33,20,2],
[34,33,20,3],
[34,33,20,4],
[34,33,20,5],
[34,33,20,6],
[34,33,20,8],
[34,33,20,9],
[34,33,20,13],
[34,33,20,14],
[34,33,20,15],
[34,33,20,16],
[34,33,20,17],
[34,33,20,18],
[34,33,20,19],
[34,33,21,0],
[34,33,21,1],
[34,33,21,2],
[34,33,21,4],
[34,33,21,9],
[34,33,21,10],
[34,33,21,13],
[34,33,21,14],
[34,33,21,15],
[34,33,21,16],
[34,33,21,17],
[34,33,21,18],
[34,33,21,19],
[34,33,21,20],
[34,33,22,0],
[34,33,22,1],
[34,33,22,2],
[34,33,22,3],
[34,33,22,4],
[34,33,22,5],
[34,33,22,6],
[34,33,22,8],
[34,33,22,9],
[34,33,22,13],
[34,33,22,14],
[34,33,22,15],
[34,33,22,16],
[34,33,22,17],
[34,33,22,18],
[34,33,22,19],
[34,33,22,21],
[34,33,23,0],
[34,33,23,1],
[34,33,23,2],
[34,33,23,3],
[34,33,23,4],
[34,33,23,5],
[34,33,23,6],
[34,33,23,8],
[34,33,23,9],
[34,33,23,10],
[34,33,23,13],
[34,33,23,14],
[34,33,23,15],
[34,33,23,16],
[34,33,23,17],
[34,33,23,18],
[34,33,23,19],
[34,33,23,20],
[34,33,23,21],
[34,33,23,22],
[34,33,24,0],
[34,33,24,1],
[34,33,24,2],
[34,33,24,3],
[34,33,24,4],
[34,33,24,5],
[34,33,24,6],
[34,33,24,8],
[34,33,24,9],
[34,33,24,10],
[34,33,24,13],
[34,33,24,14],
[34,33,24,15],
[34,33,24,17],
[34,33,24,18],
[34,33,24,19],
[34,33,24,20],
[34,33,24,21],
[34,33,24,22],
[34,33,24,23],
[34,33,26,0],
[34,33,26,1],
[34,33,26,2],
[34,33,26,3],
[34,33,26,4],
[34,33,26,5],
[34,33,26,6],
[34,33,26,8],
[34,33,26,10],
[34,33,26,13],
[34,33,26,14],
[34,33,26,16],
[34,33,26,17],
[34,33,26,19],
[34,33,26,20],
[34,33,26,21],
[34,33,26,22],
[34,33,26,23],
[34,33,26,24],
[34,33,27,0],
[34,33,27,1],
[34,33,27,2],
[34,33,27,3],
[34,33,27,4],
[34,33,27,5],
[34,33,27,6],
[34,33,27,8],
[34,33,27,9],
[34,33,27,13],
[34,33,27,14],
[34,33,27,15],
[34,33,27,16],
[34,33,27,17],
[34,33,27,18],
[34,33,27,19],
[34,33,27,21],
[34,33,27,23],
[34,33,27,24],
[34,33,27,26],
[34,33,30,0],
[34,33,30,1],
[34,33,30,2],
[34,33,30,3],
[34,33,30,4],
[34,33,30,5],
[34,33,30,6],
[34,33,30,8],
[34,33,30,9],
[34,33,30,10],
[34,33,30,13],
[34,33,30,14],
[34,33,30,15],
[34,33,30,16],
[34,33,30,17],
[34,33,30,18],
[34,33,30,19],
[34,33,30,20],
[34,33,30,21],
[34,33,30,22],
[34,33,30,24],
[34,33,30,26],
[34,33,30,27],
[34,33,31,0],
[34,33,31,1],
[34,33,31,2],
[34,33,31,3],
[34,33,31,4],
[34,33,31,5],
[34,33,31,6],
[34,33,31,8],
[34,33,31,9],
[34,33,31,10],
[34,33,31,13],
[34,33,31,14],
[34,33,31,15],
[34,33,31,16],
[34,33,31,17],
[34,33,31,18],
[34,33,31,19],
[34,33,31,20],
[34,33,31,21],
[34,33,31,22],
[34,33,31,24],
[34,33,31,26],
[34,33,31,27],
[35,4,3,0],
[35,4,3,1],
[35,4,3,2],
[35,5,4,0],
[35,5,4,1],
[35,5,4,2],
[35,6,4,0],
[35,6,4,1],
[35,6,4,2],
[35,7,3,0],
[35,7,3,1],
[35,7,3,2],
[35,7,4,0],
[35,7,4,1],
[35,7,4,2],
[35,7,4,3],
[35,7,5,0],
[35,7,5,1],
[35,7,5,2],
[35,7,5,4],
[35,7,6,0],
[35,7,6,1],
[35,7,6,2],
[35,7,6,4],
[35,8,4,0],
[35,8,4,1],
[35,8,4,2],
[35,8,7,0],
[35,8,7,1],
[35,8,7,2],
[35,8,7,4],
[35,10,3,0],
[35,10,3,1],
[35,10,3,2],
[35,10,4,0],
[35,10,4,1],
[35,10,4,2],
[35,10,4,3],
[35,10,5,0],
[35,10,5,1],
[35,10,5,2],
[35,10,5,4],
[35,10,6,0],
[35,10,6,1],
[35,10,6,2],
[35,10,6,4],
[35,10,7,0],
[35,10,7,1],
[35,10,7,2],
[35,10,7,3],
[35,10,7,4],
[35,10,7,5],
[35,10,7,6],
[35,10,8,0],
[35,10,8,1],
[35,10,8,2],
[35,10,8,4],
[35,10,8,7],
[35,11,3,0],
[35,11,3,1],
[35,11,3,2],
[35,11,4,0],
[35,11,4,1],
[35,11,4,2],
[35,11,4,3],
[35,11,5,0],
[35,11,5,1],
[35,11,5,2],
[35,11,5,4],
[35,11,6,0],
[35,11,6,1],
[35,11,6,2],
[35,11,6,4],
[35,11,7,0],
[35,11,7,1],
[35,11,7,2],
[35,11,7,3],
[35,11,7,4],
[35,11,7,5],
[35,11,7,6],
[35,11,8,0],
[35,11,8,1],
[35,11,8,2],
[35,11,8,4],
[35,11,8,7],
[35,11,10,0],
[35,11,10,1],
[35,11,10,2],
[35,11,10,3],
[35,11,10,4],
[35,11,10,5],
[35,11,10,6],
[35,11,10,7],
[35,11,10,8],
[35,12,3,0],
[35,12,3,1],
[35,12,3,2],
[35,12,4,0],
[35,12,4,1],
[35,12,4,2],
[35,12,4,3],
[35,12,5,0],
[35,12,5,1],
[35,12,5,2],
[35,12,5,4],
[35,12,6,0],
[35,12,6,1],
[35,12,6,2],
[35,12,6,4],
[35,12,7,0],
[35,12,7,1],
[35,12,7,2],
[35,12,7,3],
[35,12,7,4],
[35,12,7,5],
[35,12,7,6],
[35,12,8,0],
[35,12,8,1],
[35,12,8,2],
[35,12,8,4],
[35,12,8,7],
[35,12,10,0],
[35,12,10,1],
[35,12,10,2],
[35,12,10,3],
[35,12,10,4],
[35,12,10,5],
[35,12,10,6],
[35,12,10,7],
[35,12,10,8],
[35,13,3,0],
[35,13,3,1],
[35,13,3,2],
[35,13,4,0],
[35,13,4,1],
[35,13,4,2],
[35,13,4,3],
[35,13,5,0],
[35,13,5,1],
[35,13,5,2],
[35,13,5,4],
[35,13,6,0],
[35,13,6,1],
[35,13,6,2],
[35,13,6,4],
[35,13,7,0],
[35,13,7,1],
[35,13,7,2],
[35,13,7,3],
[35,13,7,4],
[35,13,7,5],
[35,13,7,6],
[35,13,8,0],
[35,13,8,1],
[35,13,8,2],
[35,13,8,4],
[35,13,8,7],
[35,13,10,0],
[35,13,10,1],
[35,13,10,2],
[35,13,10,3],
[35,13,10,4],
[35,13,10,5],
[35,13,10,6],
[35,13,10,7],
[35,13,10,8],
[35,13,11,0],
[35,13,11,1],
[35,13,11,2],
[35,13,11,3],
[35,13,11,4],
[35,13,11,5],
[35,13,11,6],
[35,13,11,7],
[35,13,11,8],
[35,13,11,10],
[35,13,12,0],
[35,13,12,1],
[35,13,12,2],
[35,13,12,3],
[35,13,12,4],
[35,13,12,5],
[35,13,12,6],
[35,13,12,7],
[35,13,12,8],
[35,13,12,10],
[35,14,3,0],
[35,14,3,1],
[35,14,3,2],
[35,14,4,0],
[35,14,4,1],
[35,14,4,2],
[35,14,4,3],
[35,14,5,0],
[35,14,5,1],
[35,14,5,2],
[35,14,5,4],
[35,14,6,0],
[35,14,6,1],
[35,14,6,2],
[35,14,6,4],
[35,14,7,0],
[35,14,7,1],
[35,14,7,2],
[35,14,7,3],
[35,14,7,4],
[35,14,7,5],
[35,14,7,6],
[35,14,8,0],
[35,14,8,1],
[35,14,8,2],
[35,14,8,4],
[35,14,8,7],
[35,14,10,0],
[35,14,10,1],
[35,14,10,2],
[35,14,10,3],
[35,14,10,4],
[35,14,10,5],
[35,14,10,6],
[35,14,10,7],
[35,14,10,8],
[35,14,11,0],
[35,14,11,1],
[35,14,11,2],
[35,14,11,3],
[35,14,11,4],
[35,14,11,5],
[35,14,11,6],
[35,14,11,7],
[35,14,11,8],
[35,14,11,10],
[35,14,12,0],
[35,14,12,1],
[35,14,12,2],
[35,14,12,3],
[35,14,12,4],
[35,14,12,5],
[35,14,12,6],
[35,14,12,7],
[35,14,12,8],
[35,14,12,10],
[35,16,3,0],
[35,16,3,1],
[35,16,3,2],
[35,16,4,0],
[35,16,4,1],
[35,16,4,2],
[35,16,4,3],
[35,16,5,0],
[35,16,5,1],
[35,16,5,2],
[35,16,5,4],
[35,16,6,0],
[35,16,6,1],
[35,16,6,2],
[35,16,6,4],
[35,16,7,0],
[35,16,7,1],
[35,16,7,2],
[35,16,7,3],
[35,16,7,4],
[35,16,7,5],
[35,16,7,6],
[35,16,8,0],
[35,16,8,1],
[35,16,8,2],
[35,16,8,4],
[35,16,8,7],
[35,16,10,0],
[35,16,10,1],
[35,16,10,2],
[35,16,10,3],
[35,16,10,4],
[35,16,10,5],
[35,16,10,6],
[35,16,10,7],
[35,16,10,8],
[35,16,11,0],
[35,16,11,1],
[35,16,11,2],
[35,16,11,3],
[35,16,11,4],
[35,16,11,5],
[35,16,11,6],
[35,16,11,7],
[35,16,11,8],
[35,16,11,10],
[35,16,12,0],
[35,16,12,1],
[35,16,12,2],
[35,16,12,3],
[35,16,12,4],
[35,16,12,5],
[35,16,12,6],
[35,16,12,7],
[35,16,12,8],
[35,16,12,10],
[35,16,13,0],
[35,16,13,1],
[35,16,13,2],
[35,16,13,3],
[35,16,13,4],
[35,16,13,5],
[35,16,13,6],
[35,16,13,7],
[35,16,13,8],
[35,16,13,10],
[35,16,13,11],
[35,16,13,12],
[35,16,14,0],
[35,16,14,1],
[35,16,14,2],
[35,16,14,3],
[35,16,14,4],
[35,16,14,5],
[35,16,14,6],
[35,16,14,7],
[35,16,14,8],
[35,16,14,10],
[35,16,14,11],
[35,16,14,12],
[35,17,3,0],
[35,17,3,1],
[35,17,3,2],
[35,17,4,0],
[35,17,4,1],
[35,17,4,2],
[35,17,4,3],
[35,17,5,0],
[35,17,5,1],
[35,17,5,2],
[35,17,5,4],
[35,17,6,0],
[35,17,6,1],
[35,17,6,2],
[35,17,6,4],
[35,17,7,0],
[35,17,7,1],
[35,17,7,2],
[35,17,7,3],
[35,17,7,4],
[35,17,7,5],
[35,17,7,6],
[35,17,8,0],
[35,17,8,1],
[35,17,8,2],
[35,17,8,4],
[35,17,8,7],
[35,17,10,0],
[35,17,10,1],
[35,17,10,2],
[35,17,10,3],
[35,17,10,4],
[35,17,10,5],
[35,17,10,6],
[35,17,10,7],
[35,17,10,8],
[35,17,11,0],
[35,17,11,1],
[35,17,11,2],
[35,17,11,3],
[35,17,11,4],
[35,17,11,5],
[35,17,11,6],
[35,17,11,7],
[35,17,11,8],
[35,17,11,10],
[35,17,12,0],
[35,17,12,1],
[35,17,12,2],
[35,17,12,3],
[35,17,12,4],
[35,17,12,5],
[35,17,12,6],
[35,17,12,7],
[35,17,12,8],
[35,17,12,10],
[35,17,13,0],
[35,17,13,1],
[35,17,13,2],
[35,17,13,3],
[35,17,13,4],
[35,17,13,5],
[35,17,13,6],
[35,17,13,7],
[35,17,13,8],
[35,17,13,10],
[35,17,13,11],
[35,17,13,12],
[35,17,14,0],
[35,17,14,1],
[35,17,14,2],
[35,17,14,3],
[35,17,14,4],
[35,17,14,5],
[35,17,14,6],
[35,17,14,7],
[35,17,14,8],
[35,17,14,10],
[35,17,14,11],
[35,17,14,12],
[35,17,16,0],
[35,17,16,1],
[35,17,16,2],
[35,17,16,3],
[35,17,16,4],
[35,17,16,5],
[35,17,16,6],
[35,17,16,7],
[35,17,16,8],
[35,17,16,10],
[35,17,16,11],
[35,17,16,12],
[35,17,16,13],
[35,17,16,14],
[35,19,3,0],
[35,19,3,1],
[35,19,3,2],
[35,19,4,0],
[35,19,4,1],
[35,19,4,2],
[35,19,4,3],
[35,19,5,0],
[35,19,5,1],
[35,19,5,2],
[35,19,5,4],
[35,19,6,0],
[35,19,6,1],
[35,19,6,2],
[35,19,6,4],
[35,19,7,0],
[35,19,7,1],
[35,19,7,2],
[35,19,7,3],
[35,19,7,4],
[35,19,7,5],
[35,19,7,6],
[35,19,8,0],
[35,19,8,1],
[35,19,8,2],
[35,19,8,4],
[35,19,8,7],
[35,19,10,0],
[35,19,10,1],
[35,19,10,2],
[35,19,10,3],
[35,19,10,4],
[35,19,10,5],
[35,19,10,6],
[35,19,10,7],
[35,19,10,8],
[35,19,11,0],
[35,19,11,1],
[35,19,11,2],
[35,19,11,3],
[35,19,11,4],
[35,19,11,5],
[35,19,11,6],
[35,19,11,7],
[35,19,11,8],
[35,19,11,10],
[35,19,12,0],
[35,19,12,1],
[35,19,12,2],
[35,19,12,3],
[35,19,12,4],
[35,19,12,5],
[35,19,12,6],
[35,19,12,7],
[35,19,12,8],
[35,19,12,10],
[35,19,16,0],
[35,19,16,1],
[35,19,16,2],
[35,19,16,3],
[35,19,16,4],
[35,19,16,5],
[35,19,16,6],
[35,19,16,7],
[35,19,16,8],
[35,19,16,10],
[35,19,16,11],
[35,19,16,12],
[35,19,17,0],
[35,19,17,1],
[35,19,17,2],
[35,19,17,3],
[35,19,17,4],
[35,19,17,5],
[35,19,17,6],
[35,19,17,7],
[35,19,17,8],
[35,19,17,10],
[35,19,17,11],
[35,19,17,12],
[35,19,17,16],
[35,20,3,0],
[35,20,3,1],
[35,20,3,2],
[35,20,4,0],
[35,20,4,1],
[35,20,4,2],
[35,20,4,3],
[35,20,5,0],
[35,20,5,1],
[35,20,5,2],
[35,20,5,4],
[35,20,6,0],
[35,20,6,1],
[35,20,6,2],
[35,20,6,4],
[35,20,7,0],
[35,20,7,1],
[35,20,7,2],
[35,20,7,3],
[35,20,7,4],
[35,20,7,5],
[35,20,7,6],
[35,20,8,0],
[35,20,8,1],
[35,20,8,2],
[35,20,8,4],
[35,20,8,7],
[35,20,11,0],
[35,20,11,1],
[35,20,11,2],
[35,20,11,3],
[35,20,11,4],
[35,20,11,5],
[35,20,11,6],
[35,20,11,7],
[35,20,11,8],
[35,20,12,0],
[35,20,12,1],
[35,20,12,2],
[35,20,12,3],
[35,20,12,4],
[35,20,12,5],
[35,20,12,6],
[35,20,12,7],
[35,20,12,8],
[35,20,13,0],
[35,20,13,1],
[35,20,13,2],
[35,20,13,3],
[35,20,13,4],
[35,20,13,5],
[35,20,13,6],
[35,20,13,7],
[35,20,13,8],
[35,20,13,11],
[35,20,13,12],
[35,20,14,0],
[35,20,14,1],
[35,20,14,2],
[35,20,14,3],
[35,20,14,4],
[35,20,14,5],
[35,20,14,6],
[35,20,14,7],
[35,20,14,8],
[35,20,14,11],
[35,20,14,12],
[35,20,16,0],
[35,20,16,1],
[35,20,16,2],
[35,20,16,3],
[35,20,16,4],
[35,20,16,5],
[35,20,16,6],
[35,20,16,7],
[35,20,16,8],
[35,20,16,11],
[35,20,16,12],
[35,20,16,13],
[35,20,16,14],
[35,20,17,0],
[35,20,17,1],
[35,20,17,2],
[35,20,17,3],
[35,20,17,4],
[35,20,17,5],
[35,20,17,6],
[35,20,17,7],
[35,20,17,8],
[35,20,17,11],
[35,20,17,12],
[35,20,17,13],
[35,20,17,14],
[35,20,17,16],
[35,20,19,0],
[35,20,19,1],
[35,20,19,2],
[35,20,19,3],
[35,20,19,4],
[35,20,19,5],
[35,20,19,6],
[35,20,19,7],
[35,20,19,8],
[35,20,19,11],
[35,20,19,12],
[35,20,19,16],
[35,20,19,17],
[35,21,4,0],
[35,21,4,1],
[35,21,4,2],
[35,21,7,0],
[35,21,7,1],
[35,21,7,2],
[35,21,7,4],
[35,21,10,0],
[35,21,10,1],
[35,21,10,2],
[35,21,10,4],
[35,21,10,7],
[35,21,11,0],
[35,21,11,1],
[35,21,11,2],
[35,21,11,4],
[35,21,11,7],
[35,21,11,10],
[35,21,12,0],
[35,21,12,1],
[35,21,12,2],
[35,21,12,4],
[35,21,12,7],
[35,21,12,10],
[35,21,13,0],
[35,21,13,1],
[35,21,13,2],
[35,21,13,4],
[35,21,13,7],
[35,21,13,10],
[35,21,13,11],
[35,21,13,12],
[35,21,14,0],
[35,21,14,1],
[35,21,14,2],
[35,21,14,4],
[35,21,14,7],
[35,21,14,10],
[35,21,14,11],
[35,21,14,12],
[35,21,16,0],
[35,21,16,1],
[35,21,16,2],
[35,21,16,4],
[35,21,16,7],
[35,21,16,10],
[35,21,16,11],
[35,21,16,12],
[35,21,16,13],
[35,21,16,14],
[35,21,17,0],
[35,21,17,1],
[35,21,17,2],
[35,21,17,4],
[35,21,17,7],
[35,21,17,10],
[35,21,17,11],
[35,21,17,12],
[35,21,17,13],
[35,21,17,14],
[35,21,17,16],
[35,21,19,0],
[35,21,19,1],
[35,21,19,2],
[35,21,19,4],
[35,21,19,7],
[35,21,19,10],
[35,21,19,11],
[35,21,19,12],
[35,21,19,16],
[35,21,19,17],
[35,21,20,0],
[35,21,20,1],
[35,21,20,2],
[35,21,20,4],
[35,21,20,7],
[35,21,20,11],
[35,21,20,12],
[35,21,20,13],
[35,21,20,14],
[35,21,20,16],
[35,21,20,17],
[35,21,20,19],
[35,22,3,0],
[35,22,3,1],
[35,22,3,2],
[35,22,4,0],
[35,22,4,1],
[35,22,4,2],
[35,22,4,3],
[35,22,5,0],
[35,22,5,1],
[35,22,5,2],
[35,22,5,4],
[35,22,6,0],
[35,22,6,1],
[35,22,6,2],
[35,22,6,4],
[35,22,7,0],
[35,22,7,1],
[35,22,7,2],
[35,22,7,3],
[35,22,7,4],
[35,22,7,5],
[35,22,7,6],
[35,22,8,0],
[35,22,8,1],
[35,22,8,2],
[35,22,8,4],
[35,22,8,7],
[35,22,11,0],
[35,22,11,1],
[35,22,11,2],
[35,22,11,3],
[35,22,11,4],
[35,22,11,5],
[35,22,11,6],
[35,22,11,7],
[35,22,11,8],
[35,22,12,0],
[35,22,12,1],
[35,22,12,2],
[35,22,12,3],
[35,22,12,4],
[35,22,12,5],
[35,22,12,6],
[35,22,12,7],
[35,22,12,8],
[35,22,13,0],
[35,22,13,1],
[35,22,13,2],
[35,22,13,3],
[35,22,13,4],
[35,22,13,5],
[35,22,13,6],
[35,22,13,7],
[35,22,13,8],
[35,22,13,11],
[35,22,13,12],
[35,22,14,0],
[35,22,14,1],
[35,22,14,2],
[35,22,14,3],
[35,22,14,4],
[35,22,14,5],
[35,22,14,6],
[35,22,14,7],
[35,22,14,8],
[35,22,14,11],
[35,22,14,12],
[35,22,16,0],
[35,22,16,1],
[35,22,16,2],
[35,22,16,3],
[35,22,16,4],
[35,22,16,5],
[35,22,16,6],
[35,22,16,7],
[35,22,16,8],
[35,22,16,11],
[35,22,16,12],
[35,22,16,13],
[35,22,16,14],
[35,22,17,0],
[35,22,17,1],
[35,22,17,2],
[35,22,17,3],
[35,22,17,4],
[35,22,17,5],
[35,22,17,6],
[35,22,17,7],
[35,22,17,8],
[35,22,17,11],
[35,22,17,12],
[35,22,17,13],
[35,22,17,14],
[35,22,17,16],
[35,22,19,0],
[35,22,19,1],
[35,22,19,2],
[35,22,19,3],
[35,22,19,4],
[35,22,19,5],
[35,22,19,6],
[35,22,19,7],
[35,22,19,8],
[35,22,19,11],
[35,22,19,12],
[35,22,19,16],
[35,22,19,17],
[35,22,21,0],
[35,22,21,1],
[35,22,21,2],
[35,22,21,4],
[35,22,21,7],
[35,22,21,11],
[35,22,21,12],
[35,22,21,13],
[35,22,21,14],
[35,22,21,16],
[35,22,21,17],
[35,22,21,19],
[35,23,3,0],
[35,23,3,1],
[35,23,3,2],
[35,23,4,0],
[35,23,4,1],
[35,23,4,2],
[35,23,4,3],
[35,23,5,0],
[35,23,5,1],
[35,23,5,2],
[35,23,5,4],
[35,23,6,0],
[35,23,6,1],
[35,23,6,2],
[35,23,6,4],
[35,23,7,0],
[35,23,7,1],
[35,23,7,2],
[35,23,7,3],
[35,23,7,4],
[35,23,7,5],
[35,23,7,6],
[35,23,8,0],
[35,23,8,1],
[35,23,8,2],
[35,23,8,4],
[35,23,8,7],
[35,23,10,0],
[35,23,10,1],
[35,23,10,2],
[35,23,10,3],
[35,23,10,4],
[35,23,10,5],
[35,23,10,6],
[35,23,10,7],
[35,23,10,8],
[35,23,11,0],
[35,23,11,1],
[35,23,11,2],
[35,23,11,3],
[35,23,11,4],
[35,23,11,5],
[35,23,11,6],
[35,23,11,7],
[35,23,11,8],
[35,23,11,10],
[35,23,12,0],
[35,23,12,1],
[35,23,12,2],
[35,23,12,3],
[35,23,12,4],
[35,23,12,5],
[35,23,12,6],
[35,23,12,7],
[35,23,12,8],
[35,23,12,10],
[35,23,13,0],
[35,23,13,1],
[35,23,13,2],
[35,23,13,3],
[35,23,13,4],
[35,23,13,5],
[35,23,13,6],
[35,23,13,7],
[35,23,13,8],
[35,23,13,10],
[35,23,13,11],
[35,23,13,12],
[35,23,14,0],
[35,23,14,1],
[35,23,14,2],
[35,23,14,3],
[35,23,14,4],
[35,23,14,5],
[35,23,14,6],
[35,23,14,7],
[35,23,14,8],
[35,23,14,10],
[35,23,14,11],
[35,23,14,12],
[35,23,16,0],
[35,23,16,1],
[35,23,16,2],
[35,23,16,3],
[35,23,16,4],
[35,23,16,5],
[35,23,16,6],
[35,23,16,7],
[35,23,16,8],
[35,23,16,10],
[35,23,16,11],
[35,23,16,12],
[35,23,16,13],
[35,23,16,14],
[35,23,17,0],
[35,23,17,1],
[35,23,17,2],
[35,23,17,3],
[35,23,17,4],
[35,23,17,5],
[35,23,17,6],
[35,23,17,7],
[35,23,17,8],
[35,23,17,10],
[35,23,17,11],
[35,23,17,12],
[35,23,17,13],
[35,23,17,14],
[35,23,17,16],
[35,23,19,0],
[35,23,19,1],
[35,23,19,2],
[35,23,19,3],
[35,23,19,4],
[35,23,19,5],
[35,23,19,6],
[35,23,19,7],
[35,23,19,8],
[35,23,19,10],
[35,23,19,11],
[35,23,19,12],
[35,23,19,16],
[35,23,19,17],
[35,23,20,0],
[35,23,20,1],
[35,23,20,2],
[35,23,20,3],
[35,23,20,4],
[35,23,20,5],
[35,23,20,6],
[35,23,20,7],
[35,23,20,8],
[35,23,20,11],
[35,23,20,12],
[35,23,20,13],
[35,23,20,14],
[35,23,20,16],
[35,23,20,17],
[35,23,20,19],
[35,23,21,0],
[35,23,21,1],
[35,23,21,2],
[35,23,21,4],
[35,23,21,7],
[35,23,21,10],
[35,23,21,11],
[35,23,21,12],
[35,23,21,13],
[35,23,21,14],
[35,23,21,16],
[35,23,21,17],
[35,23,21,19],
[35,23,21,20],
[35,23,22,0],
[35,23,22,1],
[35,23,22,2],
[35,23,22,3],
[35,23,22,4],
[35,23,22,5],
[35,23,22,6],
[35,23,22,7],
[35,23,22,8],
[35,23,22,11],
[35,23,22,12],
[35,23,22,13],
[35,23,22,14],
[35,23,22,16],
[35,23,22,17],
[35,23,22,19],
[35,23,22,21],
[35,24,3,0],
[35,24,3,1],
[35,24,3,2],
[35,24,4,0],
[35,24,4,1],
[35,24,4,2],
[35,24,4,3],
[35,24,5,0],
[35,24,5,1],
[35,24,5,2],
[35,24,5,4],
[35,24,6,0],
[35,24,6,1],
[35,24,6,2],
[35,24,6,4],
[35,24,7,0],
[35,24,7,1],
[35,24,7,2],
[35,24,7,3],
[35,24,7,4],
[35,24,7,5],
[35,24,7,6],
[35,24,8,0],
[35,24,8,1],
[35,24,8,2],
[35,24,8,4],
[35,24,8,7],
[35,24,10,0],
[35,24,10,1],
[35,24,10,2],
[35,24,10,3],
[35,24,10,4],
[35,24,10,5],
[35,24,10,6],
[35,24,10,7],
[35,24,10,8],
[35,24,11,0],
[35,24,11,1],
[35,24,11,2],
[35,24,11,3],
[35,24,11,4],
[35,24,11,5],
[35,24,11,6],
[35,24,11,7],
[35,24,11,8],
[35,24,11,10],
[35,24,12,0],
[35,24,12,1],
[35,24,12,2],
[35,24,12,3],
[35,24,12,4],
[35,24,12,5],
[35,24,12,6],
[35,24,12,7],
[35,24,12,8],
[35,24,12,10],
[35,24,13,0],
[35,24,13,1],
[35,24,13,2],
[35,24,13,3],
[35,24,13,4],
[35,24,13,5],
[35,24,13,6],
[35,24,13,7],
[35,24,13,8],
[35,24,13,10],
[35,24,13,11],
[35,24,13,12],
[35,24,14,0],
[35,24,14,1],
[35,24,14,2],
[35,24,14,3],
[35,24,14,4],
[35,24,14,5],
[35,24,14,6],
[35,24,14,7],
[35,24,14,8],
[35,24,14,10],
[35,24,14,11],
[35,24,14,12],
[35,24,17,0],
[35,24,17,1],
[35,24,17,2],
[35,24,17,3],
[35,24,17,4],
[35,24,17,5],
[35,24,17,6],
[35,24,17,7],
[35,24,17,8],
[35,24,17,10],
[35,24,17,11],
[35,24,17,12],
[35,24,17,13],
[35,24,17,14],
[35,24,19,0],
[35,24,19,1],
[35,24,19,2],
[35,24,19,3],
[35,24,19,4],
[35,24,19,5],
[35,24,19,6],
[35,24,19,7],
[35,24,19,8],
[35,24,19,10],
[35,24,19,11],
[35,24,19,12],
[35,24,19,17],
[35,24,20,0],
[35,24,20,1],
[35,24,20,2],
[35,24,20,3],
[35,24,20,4],
[35,24,20,5],
[35,24,20,6],
[35,24,20,7],
[35,24,20,8],
[35,24,20,11],
[35,24,20,12],
[35,24,20,13],
[35,24,20,14],
[35,24,20,17],
[35,24,20,19],
[35,24,21,0],
[35,24,21,1],
[35,24,21,2],
[35,24,21,4],
[35,24,21,7],
[35,24,21,10],
[35,24,21,11],
[35,24,21,12],
[35,24,21,13],
[35,24,21,14],
[35,24,21,17],
[35,24,21,19],
[35,24,21,20],
[35,24,22,0],
[35,24,22,1],
[35,24,22,2],
[35,24,22,3],
[35,24,22,4],
[35,24,22,5],
[35,24,22,6],
[35,24,22,7],
[35,24,22,8],
[35,24,22,11],
[35,24,22,12],
[35,24,22,13],
[35,24,22,14],
[35,24,22,17],
[35,24,22,19],
[35,24,22,21],
[35,24,23,0],
[35,24,23,1],
[35,24,23,2],
[35,24,23,3],
[35,24,23,4],
[35,24,23,5],
[35,24,23,6],
[35,24,23,7],
[35,24,23,8],
[35,24,23,10],
[35,24,23,11],
[35,24,23,12],
[35,24,23,13],
[35,24,23,14],
[35,24,23,17],
[35,24,23,19],
[35,24,23,20],
[35,24,23,21],
[35,24,23,22],
[35,25,3,0],
[35,25,3,1],
[35,25,3,2],
[35,25,4,0],
[35,25,4,1],
[35,25,4,2],
[35,25,4,3],
[35,25,5,0],
[35,25,5,1],
[35,25,5,2],
[35,25,5,4],
[35,25,6,0],
[35,25,6,1],
[35,25,6,2],
[35,25,6,4],
[35,25,8,0],
[35,25,8,1],
[35,25,8,2],
[35,25,8,4],
[35,25,10,0],
[35,25,10,1],
[35,25,10,2],
[35,25,10,3],
[35,25,10,4],
[35,25,10,5],
[35,25,10,6],
[35,25,10,8],
[35,25,11,0],
[35,25,11,1],
[35,25,11,2],
[35,25,11,3],
[35,25,11,4],
[35,25,11,5],
[35,25,11,6],
[35,25,11,8],
[35,25,11,10],
[35,25,12,0],
[35,25,12,1],
[35,25,12,2],
[35,25,12,3],
[35,25,12,4],
[35,25,12,5],
[35,25,12,6],
[35,25,12,8],
[35,25,12,10],
[35,25,13,0],
[35,25,13,1],
[35,25,13,2],
[35,25,13,3],
[35,25,13,4],
[35,25,13,5],
[35,25,13,6],
[35,25,13,8],
[35,25,13,10],
[35,25,13,11],
[35,25,13,12],
[35,25,14,0],
[35,25,14,1],
[35,25,14,2],
[35,25,14,3],
[35,25,14,4],
[35,25,14,5],
[35,25,14,6],
[35,25,14,8],
[35,25,14,10],
[35,25,14,11],
[35,25,14,12],
[35,25,16,0],
[35,25,16,1],
[35,25,16,2],
[35,25,16,3],
[35,25,16,4],
[35,25,16,5],
[35,25,16,6],
[35,25,16,8],
[35,25,16,10],
[35,25,16,11],
[35,25,16,12],
[35,25,16,13],
[35,25,16,14],
[35,25,17,0],
[35,25,17,1],
[35,25,17,2],
[35,25,17,3],
[35,25,17,4],
[35,25,17,5],
[35,25,17,6],
[35,25,17,8],
[35,25,17,10],
[35,25,17,11],
[35,25,17,12],
[35,25,17,13],
[35,25,17,14],
[35,25,17,16],
[35,25,19,0],
[35,25,19,1],
[35,25,19,2],
[35,25,19,3],
[35,25,19,4],
[35,25,19,5],
[35,25,19,6],
[35,25,19,8],
[35,25,19,10],
[35,25,19,11],
[35,25,19,12],
[35,25,19,16],
[35,25,19,17],
[35,25,20,0],
[35,25,20,1],
[35,25,20,2],
[35,25,20,3],
[35,25,20,4],
[35,25,20,5],
[35,25,20,6],
[35,25,20,8],
[35,25,20,11],
[35,25,20,12],
[35,25,20,13],
[35,25,20,14],
[35,25,20,16],
[35,25,20,17],
[35,25,20,19],
[35,25,21,0],
[35,25,21,1],
[35,25,21,2],
[35,25,21,4],
[35,25,21,10],
[35,25,21,11],
[35,25,21,12],
[35,25,21,13],
[35,25,21,14],
[35,25,21,16],
[35,25,21,17],
[35,25,21,19],
[35,25,21,20],
[35,25,22,0],
[35,25,22,1],
[35,25,22,2],
[35,25,22,3],
[35,25,22,4],
[35,25,22,5],
[35,25,22,6],
[35,25,22,8],
[35,25,22,11],
[35,25,22,12],
[35,25,22,13],
[35,25,22,14],
[35,25,22,16],
[35,25,22,17],
[35,25,22,19],
[35,25,22,21],
[35,25,23,0],
[35,25,23,1],
[35,25,23,2],
[35,25,23,3],
[35,25,23,4],
[35,25,23,5],
[35,25,23,6],
[35,25,23,8],
[35,25,23,10],
[35,25,23,11],
[35,25,23,12],
[35,25,23,13],
[35,25,23,14],
[35,25,23,16],
[35,25,23,17],
[35,25,23,19],
[35,25,23,20],
[35,25,23,21],
[35,25,23,22],
[35,25,24,0],
[35,25,24,1],
[35,25,24,2],
[35,25,24,3],
[35,25,24,4],
[35,25,24,5],
[35,25,24,6],
[35,25,24,8],
[35,25,24,10],
[35,25,24,11],
[35,25,24,12],
[35,25,24,13],
[35,25,24,14],
[35,25,24,17],
[35,25,24,19],
[35,25,24,20],
[35,25,24,21],
[35,25,24,22],
[35,25,24,23],
[35,27,3,0],
[35,27,3,1],
[35,27,3,2],
[35,27,4,0],
[35,27,4,1],
[35,27,4,2],
[35,27,4,3],
[35,27,5,0],
[35,27,5,1],
[35,27,5,2],
[35,27,5,4],
[35,27,6,0],
[35,27,6,1],
[35,27,6,2],
[35,27,6,4],
[35,27,7,0],
[35,27,7,1],
[35,27,7,2],
[35,27,7,3],
[35,27,7,4],
[35,27,7,5],
[35,27,7,6],
[35,27,8,0],
[35,27,8,1],
[35,27,8,2],
[35,27,8,4],
[35,27,8,7],
[35,27,11,0],
[35,27,11,1],
[35,27,11,2],
[35,27,11,3],
[35,27,11,4],
[35,27,11,5],
[35,27,11,6],
[35,27,11,7],
[35,27,11,8],
[35,27,12,0],
[35,27,12,1],
[35,27,12,2],
[35,27,12,3],
[35,27,12,4],
[35,27,12,5],
[35,27,12,6],
[35,27,12,7],
[35,27,12,8],
[35,27,13,0],
[35,27,13,1],
[35,27,13,2],
[35,27,13,3],
[35,27,13,4],
[35,27,13,5],
[35,27,13,6],
[35,27,13,7],
[35,27,13,8],
[35,27,13,11],
[35,27,13,12],
[35,27,14,0],
[35,27,14,1],
[35,27,14,2],
[35,27,14,3],
[35,27,14,4],
[35,27,14,5],
[35,27,14,6],
[35,27,14,7],
[35,27,14,8],
[35,27,14,11],
[35,27,14,12],
[35,27,16,0],
[35,27,16,1],
[35,27,16,2],
[35,27,16,3],
[35,27,16,4],
[35,27,16,5],
[35,27,16,6],
[35,27,16,7],
[35,27,16,8],
[35,27,16,11],
[35,27,16,12],
[35,27,16,13],
[35,27,16,14],
[35,27,17,0],
[35,27,17,1],
[35,27,17,2],
[35,27,17,3],
[35,27,17,4],
[35,27,17,5],
[35,27,17,6],
[35,27,17,7],
[35,27,17,8],
[35,27,17,11],
[35,27,17,12],
[35,27,17,13],
[35,27,17,14],
[35,27,17,16],
[35,27,19,0],
[35,27,19,1],
[35,27,19,2],
[35,27,19,3],
[35,27,19,4],
[35,27,19,5],
[35,27,19,6],
[35,27,19,7],
[35,27,19,8],
[35,27,19,11],
[35,27,19,12],
[35,27,19,16],
[35,27,19,17],
[35,27,21,0],
[35,27,21,1],
[35,27,21,2],
[35,27,21,4],
[35,27,21,7],
[35,27,21,11],
[35,27,21,12],
[35,27,21,13],
[35,27,21,14],
[35,27,21,16],
[35,27,21,17],
[35,27,21,19],
[35,27,23,0],
[35,27,23,1],
[35,27,23,2],
[35,27,23,3],
[35,27,23,4],
[35,27,23,5],
[35,27,23,6],
[35,27,23,7],
[35,27,23,8],
[35,27,23,11],
[35,27,23,12],
[35,27,23,13],
[35,27,23,14],
[35,27,23,16],
[35,27,23,17],
[35,27,23,19],
[35,27,23,21],
[35,27,24,0],
[35,27,24,1],
[35,27,24,2],
[35,27,24,3],
[35,27,24,4],
[35,27,24,5],
[35,27,24,6],
[35,27,24,7],
[35,27,24,8],
[35,27,24,11],
[35,27,24,12],
[35,27,24,13],
[35,27,24,14],
[35,27,24,17],
[35,27,24,19],
[35,27,24,21],
[35,27,24,23],
[35,27,25,0],
[35,27,25,1],
[35,27,25,2],
[35,27,25,3],
[35,27,25,4],
[35,27,25,5],
[35,27,25,6],
[35,27,25,8],
[35,27,25,11],
[35,27,25,12],
[35,27,25,13],
[35,27,25,14],
[35,27,25,16],
[35,27,25,17],
[35,27,25,19],
[35,27,25,21],
[35,27,25,23],
[35,27,25,24],
[35,28,3,0],
[35,28,3,1],
[35,28,3,2],
[35,28,4,0],
[35,28,4,1],
[35,28,4,2],
[35,28,4,3],
[35,28,5,0],
[35,28,5,1],
[35,28,5,2],
[35,28,5,4],
[35,28,6,0],
[35,28,6,1],
[35,28,6,2],
[35,28,6,4],
[35,28,8,0],
[35,28,8,1],
[35,28,8,2],
[35,28,8,4],
[35,28,10,0],
[35,28,10,1],
[35,28,10,2],
[35,28,10,3],
[35,28,10,4],
[35,28,10,5],
[35,28,10,6],
[35,28,10,8],
[35,28,11,0],
[35,28,11,1],
[35,28,11,2],
[35,28,11,3],
[35,28,11,4],
[35,28,11,5],
[35,28,11,6],
[35,28,11,8],
[35,28,11,10],
[35,28,12,0],
[35,28,12,1],
[35,28,12,2],
[35,28,12,3],
[35,28,12,4],
[35,28,12,5],
[35,28,12,6],
[35,28,12,8],
[35,28,12,10],
[35,28,13,0],
[35,28,13,1],
[35,28,13,2],
[35,28,13,3],
[35,28,13,4],
[35,28,13,5],
[35,28,13,6],
[35,28,13,8],
[35,28,13,10],
[35,28,13,11],
[35,28,13,12],
[35,28,14,0],
[35,28,14,1],
[35,28,14,2],
[35,28,14,3],
[35,28,14,4],
[35,28,14,5],
[35,28,14,6],
[35,28,14,8],
[35,28,14,10],
[35,28,14,11],
[35,28,14,12],
[35,28,16,0],
[35,28,16,1],
[35,28,16,2],
[35,28,16,3],
[35,28,16,4],
[35,28,16,5],
[35,28,16,6],
[35,28,16,8],
[35,28,16,10],
[35,28,16,11],
[35,28,16,12],
[35,28,16,13],
[35,28,16,14],
[35,28,17,0],
[35,28,17,1],
[35,28,17,2],
[35,28,17,3],
[35,28,17,4],
[35,28,17,5],
[35,28,17,6],
[35,28,17,8],
[35,28,17,10],
[35,28,17,11],
[35,28,17,12],
[35,28,17,13],
[35,28,17,14],
[35,28,17,16],
[35,28,19,0],
[35,28,19,1],
[35,28,19,2],
[35,28,19,3],
[35,28,19,4],
[35,28,19,5],
[35,28,19,6],
[35,28,19,8],
[35,28,19,10],
[35,28,19,11],
[35,28,19,12],
[35,28,19,16],
[35,28,19,17],
[35,28,20,0],
[35,28,20,1],
[35,28,20,2],
[35,28,20,3],
[35,28,20,4],
[35,28,20,5],
[35,28,20,6],
[35,28,20,8],
[35,28,20,11],
[35,28,20,12],
[35,28,20,13],
[35,28,20,14],
[35,28,20,16],
[35,28,20,17],
[35,28,20,19],
[35,28,21,0],
[35,28,21,1],
[35,28,21,2],
[35,28,21,4],
[35,28,21,10],
[35,28,21,11],
[35,28,21,12],
[35,28,21,13],
[35,28,21,14],
[35,28,21,16],
[35,28,21,17],
[35,28,21,19],
[35,28,21,20],
[35,28,22,0],
[35,28,22,1],
[35,28,22,2],
[35,28,22,3],
[35,28,22,4],
[35,28,22,5],
[35,28,22,6],
[35,28,22,8],
[35,28,22,11],
[35,28,22,12],
[35,28,22,13],
[35,28,22,14],
[35,28,22,16],
[35,28,22,17],
[35,28,22,19],
[35,28,22,21],
[35,28,23,0],
[35,28,23,1],
[35,28,23,2],
[35,28,23,3],
[35,28,23,4],
[35,28,23,5],
[35,28,23,6],
[35,28,23,8],
[35,28,23,10],
[35,28,23,11],
[35,28,23,12],
[35,28,23,13],
[35,28,23,14],
[35,28,23,16],
[35,28,23,17],
[35,28,23,19],
[35,28,23,20],
[35,28,23,21],
[35,28,23,22],
[35,28,24,0],
[35,28,24,1],
[35,28,24,2],
[35,28,24,3],
[35,28,24,4],
[35,28,24,5],
[35,28,24,6],
[35,28,24,8],
[35,28,24,10],
[35,28,24,11],
[35,28,24,12],
[35,28,24,13],
[35,28,24,14],
[35,28,24,17],
[35,28,24,19],
[35,28,24,20],
[35,28,24,21],
[35,28,24,22],
[35,28,24,23],
[35,28,27,0],
[35,28,27,1],
[35,28,27,2],
[35,28,27,3],
[35,28,27,4],
[35,28,27,5],
[35,28,27,6],
[35,28,27,8],
[35,28,27,11],
[35,28,27,12],
[35,28,27,13],
[35,28,27,14],
[35,28,27,16],
[35,28,27,17],
[35,28,27,19],
[35,28,27,21],
[35,28,27,23],
[35,28,27,24],
[35,29,3,0],
[35,29,3,1],
[35,29,3,2],
[35,29,4,0],
[35,29,4,1],
[35,29,4,2],
[35,29,4,3],
[35,29,5,0],
[35,29,5,1],
[35,29,5,2],
[35,29,5,4],
[35,29,6,0],
[35,29,6,1],
[35,29,6,2],
[35,29,6,4],
[35,29,7,0],
[35,29,7,1],
[35,29,7,2],
[35,29,7,3],
[35,29,7,4],
[35,29,7,5],
[35,29,7,6],
[35,29,8,0],
[35,29,8,1],
[35,29,8,2],
[35,29,8,4],
[35,29,8,7],
[35,29,10,0],
[35,29,10,1],
[35,29,10,2],
[35,29,10,3],
[35,29,10,4],
[35,29,10,5],
[35,29,10,6],
[35,29,10,7],
[35,29,10,8],
[35,29,13,0],
[35,29,13,1],
[35,29,13,2],
[35,29,13,3],
[35,29,13,4],
[35,29,13,5],
[35,29,13,6],
[35,29,13,7],
[35,29,13,8],
[35,29,13,10],
[35,29,14,0],
[35,29,14,1],
[35,29,14,2],
[35,29,14,3],
[35,29,14,4],
[35,29,14,5],
[35,29,14,6],
[35,29,14,7],
[35,29,14,8],
[35,29,14,10],
[35,29,16,0],
[35,29,16,1],
[35,29,16,2],
[35,29,16,3],
[35,29,16,4],
[35,29,16,5],
[35,29,16,6],
[35,29,16,7],
[35,29,16,8],
[35,29,16,10],
[35,29,16,13],
[35,29,16,14],
[35,29,17,0],
[35,29,17,1],
[35,29,17,2],
[35,29,17,3],
[35,29,17,4],
[35,29,17,5],
[35,29,17,6],
[35,29,17,7],
[35,29,17,8],
[35,29,17,10],
[35,29,17,13],
[35,29,17,14],
[35,29,17,16],
[35,29,19,0],
[35,29,19,1],
[35,29,19,2],
[35,29,19,3],
[35,29,19,4],
[35,29,19,5],
[35,29,19,6],
[35,29,19,7],
[35,29,19,8],
[35,29,19,10],
[35,29,19,16],
[35,29,19,17],
[35,29,20,0],
[35,29,20,1],
[35,29,20,2],
[35,29,20,3],
[35,29,20,4],
[35,29,20,5],
[35,29,20,6],
[35,29,20,7],
[35,29,20,8],
[35,29,20,13],
[35,29,20,14],
[35,29,20,16],
[35,29,20,17],
[35,29,20,19],
[35,29,21,0],
[35,29,21,1],
[35,29,21,2],
[35,29,21,4],
[35,29,21,7],
[35,29,21,10],
[35,29,21,13],
[35,29,21,14],
[35,29,21,16],
[35,29,21,17],
[35,29,21,19],
[35,29,21,20],
[35,29,22,0],
[35,29,22,1],
[35,29,22,2],
[35,29,22,3],
[35,29,22,4],
[35,29,22,5],
[35,29,22,6],
[35,29,22,7],
[35,29,22,8],
[35,29,22,13],
[35,29,22,14],
[35,29,22,16],
[35,29,22,17],
[35,29,22,19],
[35,29,22,21],
[35,29,23,0],
[35,29,23,1],
[35,29,23,2],
[35,29,23,3],
[35,29,23,4],
[35,29,23,5],
[35,29,23,6],
[35,29,23,7],
[35,29,23,8],
[35,29,23,10],
[35,29,23,13],
[35,29,23,14],
[35,29,23,16],
[35,29,23,17],
[35,29,23,19],
[35,29,23,20],
[35,29,23,21],
[35,29,23,22],
[35,29,24,0],
[35,29,24,1],
[35,29,24,2],
[35,29,24,3],
[35,29,24,4],
[35,29,24,5],
[35,29,24,6],
[35,29,24,7],
[35,29,24,8],
[35,29,24,10],
[35,29,24,13],
[35,29,24,14],
[35,29,24,17],
[35,29,24,19],
[35,29,24,20],
[35,29,24,21],
[35,29,24,22],
[35,29,24,23],
[35,29,25,0],
[35,29,25,1],
[35,29,25,2],
[35,29,25,3],
[35,29,25,4],
[35,29,25,5],
[35,29,25,6],
[35,29,25,8],
[35,29,25,10],
[35,29,25,13],
[35,29,25,14],
[35,29,25,16],
[35,29,25,17],
[35,29,25,19],
[35,29,25,20],
[35,29,25,21],
[35,29,25,22],
[35,29,25,23],
[35,29,25,24],
[35,29,27,0],
[35,29,27,1],
[35,29,27,2],
[35,29,27,3],
[35,29,27,4],
[35,29,27,5],
[35,29,27,6],
[35,29,27,7],
[35,29,27,8],
[35,29,27,13],
[35,29,27,14],
[35,29,27,16],
[35,29,27,17],
[35,29,27,19],
[35,29,27,21],
[35,29,27,23],
[35,29,27,24],
[35,29,27,25],
[35,29,28,0],
[35,29,28,1],
[35,29,28,2],
[35,29,28,3],
[35,29,28,4],
[35,29,28,5],
[35,29,28,6],
[35,29,28,8],
[35,29,28,10],
[35,29,28,13],
[35,29,28,14],
[35,29,28,16],
[35,29,28,17],
[35,29,28,19],
[35,29,28,20],
[35,29,28,21],
[35,29,28,22],
[35,29,28,23],
[35,29,28,24],
[35,29,28,27],
[35,30,3,0],
[35,30,3,1],
[35,30,3,2],
[35,30,4,0],
[35,30,4,1],
[35,30,4,2],
[35,30,4,3],
[35,30,5,0],
[35,30,5,1],
[35,30,5,2],
[35,30,5,4],
[35,30,6,0],
[35,30,6,1],
[35,30,6,2],
[35,30,6,4],
[35,30,7,0],
[35,30,7,1],
[35,30,7,2],
[35,30,7,3],
[35,30,7,4],
[35,30,7,5],
[35,30,7,6],
[35,30,8,0],
[35,30,8,1],
[35,30,8,2],
[35,30,8,4],
[35,30,8,7],
[35,30,10,0],
[35,30,10,1],
[35,30,10,2],
[35,30,10,3],
[35,30,10,4],
[35,30,10,5],
[35,30,10,6],
[35,30,10,7],
[35,30,10,8],
[35,30,11,0],
[35,30,11,1],
[35,30,11,2],
[35,30,11,3],
[35,30,11,4],
[35,30,11,5],
[35,30,11,6],
[35,30,11,7],
[35,30,11,8],
[35,30,11,10],
[35,30,12,0],
[35,30,12,1],
[35,30,12,2],
[35,30,12,3],
[35,30,12,4],
[35,30,12,5],
[35,30,12,6],
[35,30,12,7],
[35,30,12,8],
[35,30,12,10],
[35,30,13,0],
[35,30,13,1],
[35,30,13,2],
[35,30,13,3],
[35,30,13,4],
[35,30,13,5],
[35,30,13,6],
[35,30,13,7],
[35,30,13,8],
[35,30,13,10],
[35,30,13,11],
[35,30,13,12],
[35,30,14,0],
[35,30,14,1],
[35,30,14,2],
[35,30,14,3],
[35,30,14,4],
[35,30,14,5],
[35,30,14,6],
[35,30,14,7],
[35,30,14,8],
[35,30,14,10],
[35,30,14,11],
[35,30,14,12],
[35,30,16,0],
[35,30,16,1],
[35,30,16,2],
[35,30,16,3],
[35,30,16,4],
[35,30,16,5],
[35,30,16,6],
[35,30,16,7],
[35,30,16,8],
[35,30,16,10],
[35,30,16,11],
[35,30,16,12],
[35,30,16,13],
[35,30,16,14],
[35,30,17,0],
[35,30,17,1],
[35,30,17,2],
[35,30,17,3],
[35,30,17,4],
[35,30,17,5],
[35,30,17,6],
[35,30,17,7],
[35,30,17,8],
[35,30,17,10],
[35,30,17,11],
[35,30,17,12],
[35,30,17,13],
[35,30,17,14],
[35,30,17,16],
[35,30,19,0],
[35,30,19,1],
[35,30,19,2],
[35,30,19,3],
[35,30,19,4],
[35,30,19,5],
[35,30,19,6],
[35,30,19,7],
[35,30,19,8],
[35,30,19,10],
[35,30,19,11],
[35,30,19,12],
[35,30,19,16],
[35,30,19,17],
[35,30,20,0],
[35,30,20,1],
[35,30,20,2],
[35,30,20,3],
[35,30,20,4],
[35,30,20,5],
[35,30,20,6],
[35,30,20,7],
[35,30,20,8],
[35,30,20,11],
[35,30,20,12],
[35,30,20,13],
[35,30,20,14],
[35,30,20,16],
[35,30,20,17],
[35,30,20,19],
[35,30,21,0],
[35,30,21,1],
[35,30,21,2],
[35,30,21,4],
[35,30,21,7],
[35,30,21,10],
[35,30,21,11],
[35,30,21,12],
[35,30,21,13],
[35,30,21,14],
[35,30,21,16],
[35,30,21,17],
[35,30,21,19],
[35,30,21,20],
[35,30,22,0],
[35,30,22,1],
[35,30,22,2],
[35,30,22,3],
[35,30,22,4],
[35,30,22,5],
[35,30,22,6],
[35,30,22,7],
[35,30,22,8],
[35,30,22,11],
[35,30,22,12],
[35,30,22,13],
[35,30,22,14],
[35,30,22,16],
[35,30,22,17],
[35,30,22,19],
[35,30,22,21],
[35,30,24,0],
[35,30,24,1],
[35,30,24,2],
[35,30,24,3],
[35,30,24,4],
[35,30,24,5],
[35,30,24,6],
[35,30,24,7],
[35,30,24,8],
[35,30,24,10],
[35,30,24,11],
[35,30,24,12],
[35,30,24,13],
[35,30,24,14],
[35,30,24,17],
[35,30,24,19],
[35,30,24,20],
[35,30,24,21],
[35,30,24,22],
[35,30,25,0],
[35,30,25,1],
[35,30,25,2],
[35,30,25,3],
[35,30,25,4],
[35,30,25,5],
[35,30,25,6],
[35,30,25,8],
[35,30,25,10],
[35,30,25,11],
[35,30,25,12],
[35,30,25,13],
[35,30,25,14],
[35,30,25,16],
[35,30,25,17],
[35,30,25,19],
[35,30,25,20],
[35,30,25,21],
[35,30,25,22],
[35,30,25,24],
[35,30,27,0],
[35,30,27,1],
[35,30,27,2],
[35,30,27,3],
[35,30,27,4],
[35,30,27,5],
[35,30,27,6],
[35,30,27,7],
[35,30,27,8],
[35,30,27,11],
[35,30,27,12],
[35,30,27,13],
[35,30,27,14],
[35,30,27,16],
[35,30,27,17],
[35,30,27,19],
[35,30,27,21],
[35,30,27,24],
[35,30,27,25],
[35,30,28,0],
[35,30,28,1],
[35,30,28,2],
[35,30,28,3],
[35,30,28,4],
[35,30,28,5],
[35,30,28,6],
[35,30,28,8],
[35,30,28,10],
[35,30,28,11],
[35,30,28,12],
[35,30,28,13],
[35,30,28,14],
[35,30,28,16],
[35,30,28,17],
[35,30,28,19],
[35,30,28,20],
[35,30,28,21],
[35,30,28,22],
[35,30,28,24],
[35,30,28,27],
[35,30,29,0],
[35,30,29,1],
[35,30,29,2],
[35,30,29,3],
[35,30,29,4],
[35,30,29,5],
[35,30,29,6],
[35,30,29,7],
[35,30,29,8],
[35,30,29,10],
[35,30,29,13],
[35,30,29,14],
[35,30,29,16],
[35,30,29,17],
[35,30,29,19],
[35,30,29,20],
[35,30,29,21],
[35,30,29,22],
[35,30,29,24],
[35,30,29,25],
[35,30,29,27],
[35,30,29,28],
[35,31,3,0],
[35,31,3,1],
[35,31,3,2],
[35,31,4,0],
[35,31,4,1],
[35,31,4,2],
[35,31,4,3],
[35,31,5,0],
[35,31,5,1],
[35,31,5,2],
[35,31,5,4],
[35,31,6,0],
[35,31,6,1],
[35,31,6,2],
[35,31,6,4],
[35,31,7,0],
[35,31,7,1],
[35,31,7,2],
[35,31,7,3],
[35,31,7,4],
[35,31,7,5],
[35,31,7,6],
[35,31,8,0],
[35,31,8,1],
[35,31,8,2],
[35,31,8,4],
[35,31,8,7],
[35,31,10,0],
[35,31,10,1],
[35,31,10,2],
[35,31,10,3],
[35,31,10,4],
[35,31,10,5],
[35,31,10,6],
[35,31,10,7],
[35,31,10,8],
[35,31,11,0],
[35,31,11,1],
[35,31,11,2],
[35,31,11,3],
[35,31,11,4],
[35,31,11,5],
[35,31,11,6],
[35,31,11,7],
[35,31,11,8],
[35,31,11,10],
[35,31,12,0],
[35,31,12,1],
[35,31,12,2],
[35,31,12,3],
[35,31,12,4],
[35,31,12,5],
[35,31,12,6],
[35,31,12,7],
[35,31,12,8],
[35,31,12,10],
[35,31,13,0],
[35,31,13,1],
[35,31,13,2],
[35,31,13,3],
[35,31,13,4],
[35,31,13,5],
[35,31,13,6],
[35,31,13,7],
[35,31,13,8],
[35,31,13,10],
[35,31,13,11],
[35,31,13,12],
[35,31,14,0],
[35,31,14,1],
[35,31,14,2],
[35,31,14,3],
[35,31,14,4],
[35,31,14,5],
[35,31,14,6],
[35,31,14,7],
[35,31,14,8],
[35,31,14,10],
[35,31,14,11],
[35,31,14,12],
[35,31,16,0],
[35,31,16,1],
[35,31,16,2],
[35,31,16,3],
[35,31,16,4],
[35,31,16,5],
[35,31,16,6],
[35,31,16,7],
[35,31,16,8],
[35,31,16,10],
[35,31,16,11],
[35,31,16,12],
[35,31,16,13],
[35,31,16,14],
[35,31,17,0],
[35,31,17,1],
[35,31,17,2],
[35,31,17,3],
[35,31,17,4],
[35,31,17,5],
[35,31,17,6],
[35,31,17,7],
[35,31,17,8],
[35,31,17,10],
[35,31,17,11],
[35,31,17,12],
[35,31,17,13],
[35,31,17,14],
[35,31,17,16],
[35,31,19,0],
[35,31,19,1],
[35,31,19,2],
[35,31,19,3],
[35,31,19,4],
[35,31,19,5],
[35,31,19,6],
[35,31,19,7],
[35,31,19,8],
[35,31,19,10],
[35,31,19,11],
[35,31,19,12],
[35,31,19,16],
[35,31,19,17],
[35,31,20,0],
[35,31,20,1],
[35,31,20,2],
[35,31,20,3],
[35,31,20,4],
[35,31,20,5],
[35,31,20,6],
[35,31,20,7],
[35,31,20,8],
[35,31,20,11],
[35,31,20,12],
[35,31,20,13],
[35,31,20,14],
[35,31,20,16],
[35,31,20,17],
[35,31,20,19],
[35,31,21,0],
[35,31,21,1],
[35,31,21,2],
[35,31,21,4],
[35,31,21,7],
[35,31,21,10],
[35,31,21,11],
[35,31,21,12],
[35,31,21,13],
[35,31,21,14],
[35,31,21,16],
[35,31,21,17],
[35,31,21,19],
[35,31,21,20],
[35,31,22,0],
[35,31,22,1],
[35,31,22,2],
[35,31,22,3],
[35,31,22,4],
[35,31,22,5],
[35,31,22,6],
[35,31,22,7],
[35,31,22,8],
[35,31,22,11],
[35,31,22,12],
[35,31,22,13],
[35,31,22,14],
[35,31,22,16],
[35,31,22,17],
[35,31,22,19],
[35,31,22,21],
[35,31,24,0],
[35,31,24,1],
[35,31,24,2],
[35,31,24,3],
[35,31,24,4],
[35,31,24,5],
[35,31,24,6],
[35,31,24,7],
[35,31,24,8],
[35,31,24,10],
[35,31,24,11],
[35,31,24,12],
[35,31,24,13],
[35,31,24,14],
[35,31,24,17],
[35,31,24,19],
[35,31,24,20],
[35,31,24,21],
[35,31,24,22],
[35,31,25,0],
[35,31,25,1],
[35,31,25,2],
[35,31,25,3],
[35,31,25,4],
[35,31,25,5],
[35,31,25,6],
[35,31,25,8],
[35,31,25,10],
[35,31,25,11],
[35,31,25,12],
[35,31,25,13],
[35,31,25,14],
[35,31,25,16],
[35,31,25,17],
[35,31,25,19],
[35,31,25,20],
[35,31,25,21],
[35,31,25,22],
[35,31,25,24],
[35,31,27,0],
[35,31,27,1],
[35,31,27,2],
[35,31,27,3],
[35,31,27,4],
[35,31,27,5],
[35,31,27,6],
[35,31,27,7],
[35,31,27,8],
[35,31,27,11],
[35,31,27,12],
[35,31,27,13],
[35,31,27,14],
[35,31,27,16],
[35,31,27,17],
[35,31,27,19],
[35,31,27,21],
[35,31,27,24],
[35,31,27,25],
[35,31,28,0],
[35,31,28,1],
[35,31,28,2],
[35,31,28,3],
[35,31,28,4],
[35,31,28,5],
[35,31,28,6],
[35,31,28,8],
[35,31,28,10],
[35,31,28,11],
[35,31,28,12],
[35,31,28,13],
[35,31,28,14],
[35,31,28,16],
[35,31,28,17],
[35,31,28,19],
[35,31,28,20],
[35,31,28,21],
[35,31,28,22],
[35,31,28,24],
[35,31,28,27],
[35,31,29,0],
[35,31,29,1],
[35,31,29,2],
[35,31,29,3],
[35,31,29,4],
[35,31,29,5],
[35,31,29,6],
[35,31,29,7],
[35,31,29,8],
[35,31,29,10],
[35,31,29,13],
[35,31,29,14],
[35,31,29,16],
[35,31,29,17],
[35,31,29,19],
[35,31,29,20],
[35,31,29,21],
[35,31,29,22],
[35,31,29,24],
[35,31,29,25],
[35,31,29,27],
[35,31,29,28],
[35,32,3,0],
[35,32,3,1],
[35,32,3,2],
[35,32,4,0],
[35,32,4,1],
[35,32,4,2],
[35,32,4,3],
[35,32,5,0],
[35,32,5,1],
[35,32,5,2],
[35,32,5,4],
[35,32,6,0],
[35,32,6,1],
[35,32,6,2],
[35,32,6,4],
[35,32,7,0],
[35,32,7,1],
[35,32,7,2],
[35,32,7,3],
[35,32,7,4],
[35,32,7,5],
[35,32,7,6],
[35,32,8,0],
[35,32,8,1],
[35,32,8,2],
[35,32,8,4],
[35,32,8,7],
[35,32,10,0],
[35,32,10,1],
[35,32,10,2],
[35,32,10,3],
[35,32,10,4],
[35,32,10,5],
[35,32,10,6],
[35,32,10,7],
[35,32,10,8],
[35,32,13,0],
[35,32,13,1],
[35,32,13,2],
[35,32,13,3],
[35,32,13,4],
[35,32,13,5],
[35,32,13,6],
[35,32,13,7],
[35,32,13,8],
[35,32,13,10],
[35,32,14,0],
[35,32,14,1],
[35,32,14,2],
[35,32,14,3],
[35,32,14,4],
[35,32,14,5],
[35,32,14,6],
[35,32,14,7],
[35,32,14,8],
[35,32,14,10],
[35,32,16,0],
[35,32,16,1],
[35,32,16,2],
[35,32,16,3],
[35,32,16,4],
[35,32,16,5],
[35,32,16,6],
[35,32,16,7],
[35,32,16,8],
[35,32,16,10],
[35,32,16,13],
[35,32,16,14],
[35,32,17,0],
[35,32,17,1],
[35,32,17,2],
[35,32,17,3],
[35,32,17,4],
[35,32,17,5],
[35,32,17,6],
[35,32,17,7],
[35,32,17,8],
[35,32,17,10],
[35,32,17,13],
[35,32,17,14],
[35,32,17,16],
[35,32,19,0],
[35,32,19,1],
[35,32,19,2],
[35,32,19,3],
[35,32,19,4],
[35,32,19,5],
[35,32,19,6],
[35,32,19,7],
[35,32,19,8],
[35,32,19,10],
[35,32,19,16],
[35,32,19,17],
[35,32,20,0],
[35,32,20,1],
[35,32,20,2],
[35,32,20,3],
[35,32,20,4],
[35,32,20,5],
[35,32,20,6],
[35,32,20,7],
[35,32,20,8],
[35,32,20,13],
[35,32,20,14],
[35,32,20,16],
[35,32,20,17],
[35,32,20,19],
[35,32,21,0],
[35,32,21,1],
[35,32,21,2],
[35,32,21,4],
[35,32,21,7],
[35,32,21,10],
[35,32,21,13],
[35,32,21,14],
[35,32,21,16],
[35,32,21,17],
[35,32,21,19],
[35,32,21,20],
[35,32,22,0],
[35,32,22,1],
[35,32,22,2],
[35,32,22,3],
[35,32,22,4],
[35,32,22,5],
[35,32,22,6],
[35,32,22,7],
[35,32,22,8],
[35,32,22,13],
[35,32,22,14],
[35,32,22,16],
[35,32,22,17],
[35,32,22,19],
[35,32,22,21],
[35,32,23,0],
[35,32,23,1],
[35,32,23,2],
[35,32,23,3],
[35,32,23,4],
[35,32,23,5],
[35,32,23,6],
[35,32,23,7],
[35,32,23,8],
[35,32,23,10],
[35,32,23,13],
[35,32,23,14],
[35,32,23,16],
[35,32,23,17],
[35,32,23,19],
[35,32,23,20],
[35,32,23,21],
[35,32,23,22],
[35,32,24,0],
[35,32,24,1],
[35,32,24,2],
[35,32,24,3],
[35,32,24,4],
[35,32,24,5],
[35,32,24,6],
[35,32,24,7],
[35,32,24,8],
[35,32,24,10],
[35,32,24,13],
[35,32,24,14],
[35,32,24,17],
[35,32,24,19],
[35,32,24,20],
[35,32,24,21],
[35,32,24,22],
[35,32,24,23],
[35,32,25,0],
[35,32,25,1],
[35,32,25,2],
[35,32,25,3],
[35,32,25,4],
[35,32,25,5],
[35,32,25,6],
[35,32,25,8],
[35,32,25,10],
[35,32,25,13],
[35,32,25,14],
[35,32,25,16],
[35,32,25,17],
[35,32,25,19],
[35,32,25,20],
[35,32,25,21],
[35,32,25,22],
[35,32,25,23],
[35,32,25,24],
[35,32,27,0],
[35,32,27,1],
[35,32,27,2],
[35,32,27,3],
[35,32,27,4],
[35,32,27,5],
[35,32,27,6],
[35,32,27,7],
[35,32,27,8],
[35,32,27,13],
[35,32,27,14],
[35,32,27,16],
[35,32,27,17],
[35,32,27,19],
[35,32,27,21],
[35,32,27,23],
[35,32,27,24],
[35,32,27,25],
[35,32,28,0],
[35,32,28,1],
[35,32,28,2],
[35,32,28,3],
[35,32,28,4],
[35,32,28,5],
[35,32,28,6],
[35,32,28,8],
[35,32,28,10],
[35,32,28,13],
[35,32,28,14],
[35,32,28,16],
[35,32,28,17],
[35,32,28,19],
[35,32,28,20],
[35,32,28,21],
[35,32,28,22],
[35,32,28,23],
[35,32,28,24],
[35,32,28,27],
[35,32,30,0],
[35,32,30,1],
[35,32,30,2],
[35,32,30,3],
[35,32,30,4],
[35,32,30,5],
[35,32,30,6],
[35,32,30,7],
[35,32,30,8],
[35,32,30,10],
[35,32,30,13],
[35,32,30,14],
[35,32,30,16],
[35,32,30,17],
[35,32,30,19],
[35,32,30,20],
[35,32,30,21],
[35,32,30,22],
[35,32,30,24],
[35,32,30,25],
[35,32,30,27],
[35,32,30,28],
[35,32,31,0],
[35,32,31,1],
[35,32,31,2],
[35,32,31,3],
[35,32,31,4],
[35,32,31,5],
[35,32,31,6],
[35,32,31,7],
[35,32,31,8],
[35,32,31,10],
[35,32,31,13],
[35,32,31,14],
[35,32,31,16],
[35,32,31,17],
[35,32,31,19],
[35,32,31,20],
[35,32,31,21],
[35,32,31,22],
[35,32,31,24],
[35,32,31,25],
[35,32,31,27],
[35,32,31,28],
[35,33,3,0],
[35,33,3,1],
[35,33,3,2],
[35,33,4,0],
[35,33,4,1],
[35,33,4,2],
[35,33,4,3],
[35,33,5,0],
[35,33,5,1],
[35,33,5,2],
[35,33,5,4],
[35,33,6,0],
[35,33,6,1],
[35,33,6,2],
[35,33,6,4],
[35,33,7,0],
[35,33,7,1],
[35,33,7,2],
[35,33,7,3],
[35,33,7,4],
[35,33,7,5],
[35,33,7,6],
[35,33,8,0],
[35,33,8,1],
[35,33,8,2],
[35,33,8,4],
[35,33,8,7],
[35,33,10,0],
[35,33,10,1],
[35,33,10,2],
[35,33,10,3],
[35,33,10,4],
[35,33,10,5],
[35,33,10,6],
[35,33,10,7],
[35,33,10,8],
[35,33,13,0],
[35,33,13,1],
[35,33,13,2],
[35,33,13,3],
[35,33,13,4],
[35,33,13,5],
[35,33,13,6],
[35,33,13,7],
[35,33,13,8],
[35,33,13,10],
[35,33,14,0],
[35,33,14,1],
[35,33,14,2],
[35,33,14,3],
[35,33,14,4],
[35,33,14,5],
[35,33,14,6],
[35,33,14,7],
[35,33,14,8],
[35,33,14,10],
[35,33,16,0],
[35,33,16,1],
[35,33,16,2],
[35,33,16,3],
[35,33,16,4],
[35,33,16,5],
[35,33,16,6],
[35,33,16,7],
[35,33,16,8],
[35,33,16,10],
[35,33,16,13],
[35,33,16,14],
[35,33,17,0],
[35,33,17,1],
[35,33,17,2],
[35,33,17,3],
[35,33,17,4],
[35,33,17,5],
[35,33,17,6],
[35,33,17,7],
[35,33,17,8],
[35,33,17,10],
[35,33,17,13],
[35,33,17,14],
[35,33,17,16],
[35,33,19,0],
[35,33,19,1],
[35,33,19,2],
[35,33,19,3],
[35,33,19,4],
[35,33,19,5],
[35,33,19,6],
[35,33,19,7],
[35,33,19,8],
[35,33,19,10],
[35,33,19,16],
[35,33,19,17],
[35,33,20,0],
[35,33,20,1],
[35,33,20,2],
[35,33,20,3],
[35,33,20,4],
[35,33,20,5],
[35,33,20,6],
[35,33,20,7],
[35,33,20,8],
[35,33,20,13],
[35,33,20,14],
[35,33,20,16],
[35,33,20,17],
[35,33,20,19],
[35,33,21,0],
[35,33,21,1],
[35,33,21,2],
[35,33,21,4],
[35,33,21,7],
[35,33,21,10],
[35,33,21,13],
[35,33,21,14],
[35,33,21,16],
[35,33,21,17],
[35,33,21,19],
[35,33,21,20],
[35,33,22,0],
[35,33,22,1],
[35,33,22,2],
[35,33,22,3],
[35,33,22,4],
[35,33,22,5],
[35,33,22,6],
[35,33,22,7],
[35,33,22,8],
[35,33,22,13],
[35,33,22,14],
[35,33,22,16],
[35,33,22,17],
[35,33,22,19],
[35,33,22,21],
[35,33,23,0],
[35,33,23,1],
[35,33,23,2],
[35,33,23,3],
[35,33,23,4],
[35,33,23,5],
[35,33,23,6],
[35,33,23,7],
[35,33,23,8],
[35,33,23,10],
[35,33,23,13],
[35,33,23,14],
[35,33,23,16],
[35,33,23,17],
[35,33,23,19],
[35,33,23,20],
[35,33,23,21],
[35,33,23,22],
[35,33,24,0],
[35,33,24,1],
[35,33,24,2],
[35,33,24,3],
[35,33,24,4],
[35,33,24,5],
[35,33,24,6],
[35,33,24,7],
[35,33,24,8],
[35,33,24,10],
[35,33,24,13],
[35,33,24,14],
[35,33,24,17],
[35,33,24,19],
[35,33,24,20],
[35,33,24,21],
[35,33,24,22],
[35,33,24,23],
[35,33,25,0],
[35,33,25,1],
[35,33,25,2],
[35,33,25,3],
[35,33,25,4],
[35,33,25,5],
[35,33,25,6],
[35,33,25,8],
[35,33,25,10],
[35,33,25,13],
[35,33,25,14],
[35,33,25,16],
[35,33,25,17],
[35,33,25,19],
[35,33,25,20],
[35,33,25,21],
[35,33,25,22],
[35,33,25,23],
[35,33,25,24],
[35,33,27,0],
[35,33,27,1],
[35,33,27,2],
[35,33,27,3],
[35,33,27,4],
[35,33,27,5],
[35,33,27,6],
[35,33,27,7],
[35,33,27,8],
[35,33,27,13],
[35,33,27,14],
[35,33,27,16],
[35,33,27,17],
[35,33,27,19],
[35,33,27,21],
[35,33,27,23],
[35,33,27,24],
[35,33,27,25],
[35,33,28,0],
[35,33,28,1],
[35,33,28,2],
[35,33,28,3],
[35,33,28,4],
[35,33,28,5],
[35,33,28,6],
[35,33,28,8],
[35,33,28,10],
[35,33,28,13],
[35,33,28,14],
[35,33,28,16],
[35,33,28,17],
[35,33,28,19],
[35,33,28,20],
[35,33,28,21],
[35,33,28,22],
[35,33,28,23],
[35,33,28,24],
[35,33,28,27],
[35,33,30,0],
[35,33,30,1],
[35,33,30,2],
[35,33,30,3],
[35,33,30,4],
[35,33,30,5],
[35,33,30,6],
[35,33,30,7],
[35,33,30,8],
[35,33,30,10],
[35,33,30,13],
[35,33,30,14],
[35,33,30,16],
[35,33,30,17],
[35,33,30,19],
[35,33,30,20],
[35,33,30,21],
[35,33,30,22],
[35,33,30,24],
[35,33,30,25],
[35,33,30,27],
[35,33,30,28],
[35,33,31,0],
[35,33,31,1],
[35,33,31,2],
[35,33,31,3],
[35,33,31,4],
[35,33,31,5],
[35,33,31,6],
[35,33,31,7],
[35,33,31,8],
[35,33,31,10],
[35,33,31,13],
[35,33,31,14],
[35,33,31,16],
[35,33,31,17],
[35,33,31,19],
[35,33,31,20],
[35,33,31,21],
[35,33,31,22],
[35,33,31,24],
[35,33,31,25],
[35,33,31,27],
[35,34,3,0],
[35,34,3,1],
[35,34,3,2],
[35,34,4,0],
[35,34,4,1],
[35,34,4,2],
[35,34,4,3],
[35,34,5,0],
[35,34,5,1],
[35,34,5,2],
[35,34,5,4],
[35,34,6,0],
[35,34,6,1],
[35,34,6,2],
[35,34,6,4],
[35,34,8,0],
[35,34,8,1],
[35,34,8,2],
[35,34,8,4],
[35,34,10,0],
[35,34,10,1],
[35,34,10,2],
[35,34,10,3],
[35,34,10,4],
[35,34,10,5],
[35,34,10,6],
[35,34,10,8],
[35,34,11,0],
[35,34,11,1],
[35,34,11,2],
[35,34,11,3],
[35,34,11,4],
[35,34,11,5],
[35,34,11,6],
[35,34,11,8],
[35,34,11,10],
[35,34,12,0],
[35,34,12,1],
[35,34,12,2],
[35,34,12,3],
[35,34,12,4],
[35,34,12,5],
[35,34,12,6],
[35,34,12,8],
[35,34,12,10],
[35,34,13,0],
[35,34,13,1],
[35,34,13,2],
[35,34,13,3],
[35,34,13,4],
[35,34,13,5],
[35,34,13,6],
[35,34,13,8],
[35,34,13,10],
[35,34,13,11],
[35,34,13,12],
[35,34,14,0],
[35,34,14,1],
[35,34,14,2],
[35,34,14,3],
[35,34,14,4],
[35,34,14,5],
[35,34,14,6],
[35,34,14,8],
[35,34,14,10],
[35,34,14,11],
[35,34,14,12],
[35,34,16,0],
[35,34,16,1],
[35,34,16,2],
[35,34,16,3],
[35,34,16,4],
[35,34,16,5],
[35,34,16,6],
[35,34,16,8],
[35,34,16,10],
[35,34,16,11],
[35,34,16,12],
[35,34,16,13],
[35,34,16,14],
[35,34,17,0],
[35,34,17,1],
[35,34,17,2],
[35,34,17,3],
[35,34,17,4],
[35,34,17,5],
[35,34,17,6],
[35,34,17,8],
[35,34,17,10],
[35,34,17,11],
[35,34,17,12],
[35,34,17,13],
[35,34,17,14],
[35,34,17,16],
[35,34,19,0],
[35,34,19,1],
[35,34,19,2],
[35,34,19,3],
[35,34,19,4],
[35,34,19,5],
[35,34,19,6],
[35,34,19,8],
[35,34,19,10],
[35,34,19,11],
[35,34,19,12],
[35,34,19,16],
[35,34,19,17],
[35,34,20,0],
[35,34,20,1],
[35,34,20,2],
[35,34,20,3],
[35,34,20,4],
[35,34,20,5],
[35,34,20,6],
[35,34,20,8],
[35,34,20,11],
[35,34,20,12],
[35,34,20,13],
[35,34,20,14],
[35,34,20,16],
[35,34,20,17],
[35,34,20,19],
[35,34,21,0],
[35,34,21,1],
[35,34,21,2],
[35,34,21,4],
[35,34,21,10],
[35,34,21,11],
[35,34,21,12],
[35,34,21,13],
[35,34,21,14],
[35,34,21,16],
[35,34,21,17],
[35,34,21,19],
[35,34,21,20],
[35,34,22,0],
[35,34,22,1],
[35,34,22,2],
[35,34,22,3],
[35,34,22,4],
[35,34,22,5],
[35,34,22,6],
[35,34,22,8],
[35,34,22,11],
[35,34,22,12],
[35,34,22,13],
[35,34,22,14],
[35,34,22,16],
[35,34,22,17],
[35,34,22,19],
[35,34,22,21],
[35,34,23,0],
[35,34,23,1],
[35,34,23,2],
[35,34,23,3],
[35,34,23,4],
[35,34,23,5],
[35,34,23,6],
[35,34,23,8],
[35,34,23,10],
[35,34,23,11],
[35,34,23,12],
[35,34,23,13],
[35,34,23,14],
[35,34,23,16],
[35,34,23,17],
[35,34,23,19],
[35,34,23,20],
[35,34,23,21],
[35,34,23,22],
[35,34,24,0],
[35,34,24,1],
[35,34,24,2],
[35,34,24,3],
[35,34,24,4],
[35,34,24,5],
[35,34,24,6],
[35,34,24,8],
[35,34,24,10],
[35,34,24,11],
[35,34,24,12],
[35,34,24,13],
[35,34,24,14],
[35,34,24,17],
[35,34,24,19],
[35,34,24,20],
[35,34,24,21],
[35,34,24,22],
[35,34,24,23],
[35,34,27,0],
[35,34,27,1],
[35,34,27,2],
[35,34,27,3],
[35,34,27,4],
[35,34,27,5],
[35,34,27,6],
[35,34,27,8],
[35,34,27,11],
[35,34,27,12],
[35,34,27,13],
[35,34,27,14],
[35,34,27,16],
[35,34,27,17],
[35,34,27,19],
[35,34,27,21],
[35,34,27,23],
[35,34,27,24],
[35,34,29,0],
[35,34,29,1],
[35,34,29,2],
[35,34,29,3],
[35,34,29,4],
[35,34,29,5],
[35,34,29,6],
[35,34,29,8],
[35,34,29,10],
[35,34,29,13],
[35,34,29,14],
[35,34,29,16],
[35,34,29,17],
[35,34,29,19],
[35,34,29,20],
[35,34,29,21],
[35,34,29,22],
[35,34,29,23],
[35,34,29,24],
[35,34,29,27],
[35,34,30,0],
[35,34,30,1],
[35,34,30,2],
[35,34,30,3],
[35,34,30,4],
[35,34,30,5],
[35,34,30,6],
[35,34,30,8],
[35,34,30,10],
[35,34,30,11],
[35,34,30,12],
[35,34,30,13],
[35,34,30,14],
[35,34,30,16],
[35,34,30,17],
[35,34,30,19],
[35,34,30,20],
[35,34,30,21],
[35,34,30,22],
[35,34,30,24],
[35,34,30,27],
[35,34,30,29],
[35,34,31,0],
[35,34,31,1],
[35,34,31,2],
[35,34,31,3],
[35,34,31,4],
[35,34,31,5],
[35,34,31,6],
[35,34,31,8],
[35,34,31,10],
[35,34,31,11],
[35,34,31,12],
[35,34,31,13],
[35,34,31,14],
[35,34,31,16],
[35,34,31,17],
[35,34,31,19],
[35,34,31,20],
[35,34,31,21],
[35,34,31,22],
[35,34,31,24],
[35,34,31,29],
[35,34,32,0],
[35,34,32,1],
[35,34,32,2],
[35,34,32,3],
[35,34,32,4],
[35,34,32,5],
[35,34,32,6],
[35,34,32,8],
[35,34,32,10],
[35,34,32,13],
[35,34,32,14],
[35,34,32,16],
[35,34,32,17],
[35,34,32,19],
[35,34,32,20],
[35,34,32,21],
[35,34,32,22],
[35,34,32,23],
[35,34,32,24],
[35,34,32,27],
[35,34,32,30],
[35,34,32,31],
[35,34,33,0],
[35,34,33,1],
[35,34,33,2],
[35,34,33,3],
[35,34,33,4],
[35,34,33,5],
[35,34,33,6],
[35,34,33,8],
[35,34,33,10],
[35,34,33,13],
[35,34,33,14],
[35,34,33,16],
[35,34,33,17],
[35,34,33,19],
[35,34,33,20],
[35,34,33,21],
[35,34,33,22],
[35,34,33,23],
[35,34,33,24],
[35,34,33,27],
[35,34,33,30],
[35,34,33,31],
[36,4,3,0],
[36,4,3,1],
[36,4,3,2],
[36,5,4,0],
[36,5,4,1],
[36,5,4,2],
[36,6,4,0],
[36,6,4,1],
[36,6,4,2],
[36,7,3,0],
[36,7,3,1],
[36,7,3,2],
[36,7,4,0],
[36,7,4,1],
[36,7,4,2],
[36,7,4,3],
[36,7,5,0],
[36,7,5,1],
[36,7,5,2],
[36,7,5,4],
[36,7,6,0],
[36,7,6,1],
[36,7,6,2],
[36,7,6,4],
[36,8,4,0],
[36,8,4,1],
[36,8,4,2],
[36,8,7,0],
[36,8,7,1],
[36,8,7,2],
[36,8,7,4],
[36,9,3,0],
[36,9,3,1],
[36,9,3,2],
[36,9,4,0],
[36,9,4,1],
[36,9,4,2],
[36,9,4,3],
[36,9,5,0],
[36,9,5,1],
[36,9,5,2],
[36,9,5,4],
[36,9,6,0],
[36,9,6,1],
[36,9,6,2],
[36,9,6,4],
[36,9,7,0],
[36,9,7,1],
[36,9,7,2],
[36,9,7,3],
[36,9,7,4],
[36,9,7,5],
[36,9,7,6],
[36,9,8,0],
[36,9,8,1],
[36,9,8,2],
[36,9,8,4],
[36,9,8,7],
[36,10,3,0],
[36,10,3,1],
[36,10,3,2],
[36,10,4,0],
[36,10,4,1],
[36,10,4,2],
[36,10,4,3],
[36,10,5,0],
[36,10,5,1],
[36,10,5,2],
[36,10,5,4],
[36,10,6,0],
[36,10,6,1],
[36,10,6,2],
[36,10,6,4],
[36,10,7,0],
[36,10,7,1],
[36,10,7,2],
[36,10,7,3],
[36,10,7,4],
[36,10,7,5],
[36,10,7,6],
[36,10,8,0],
[36,10,8,1],
[36,10,8,2],
[36,10,8,4],
[36,10,8,7],
[36,10,9,0],
[36,10,9,1],
[36,10,9,2],
[36,10,9,3],
[36,10,9,4],
[36,10,9,5],
[36,10,9,6],
[36,10,9,7],
[36,10,9,8],
[36,11,3,0],
[36,11,3,1],
[36,11,3,2],
[36,11,4,0],
[36,11,4,1],
[36,11,4,2],
[36,11,4,3],
[36,11,5,0],
[36,11,5,1],
[36,11,5,2],
[36,11,5,4],
[36,11,6,0],
[36,11,6,1],
[36,11,6,2],
[36,11,6,4],
[36,11,7,0],
[36,11,7,1],
[36,11,7,2],
[36,11,7,3],
[36,11,7,4],
[36,11,7,5],
[36,11,7,6],
[36,11,8,0],
[36,11,8,1],
[36,11,8,2],
[36,11,8,4],
[36,11,8,7],
[36,11,9,0],
[36,11,9,1],
[36,11,9,2],
[36,11,9,3],
[36,11,9,4],
[36,11,9,5],
[36,11,9,6],
[36,11,9,7],
[36,11,9,8],
[36,11,10,0],
[36,11,10,1],
[36,11,10,2],
[36,11,10,3],
[36,11,10,4],
[36,11,10,5],
[36,11,10,6],
[36,11,10,7],
[36,11,10,8],
[36,11,10,9],
[36,12,3,0],
[36,12,3,1],
[36,12,3,2],
[36,12,4,0],
[36,12,4,1],
[36,12,4,2],
[36,12,4,3],
[36,12,5,0],
[36,12,5,1],
[36,12,5,2],
[36,12,5,4],
[36,12,6,0],
[36,12,6,1],
[36,12,6,2],
[36,12,6,4],
[36,12,7,0],
[36,12,7,1],
[36,12,7,2],
[36,12,7,3],
[36,12,7,4],
[36,12,7,5],
[36,12,7,6],
[36,12,8,0],
[36,12,8,1],
[36,12,8,2],
[36,12,8,4],
[36,12,8,7],
[36,12,9,0],
[36,12,9,1],
[36,12,9,2],
[36,12,9,3],
[36,12,9,4],
[36,12,9,5],
[36,12,9,6],
[36,12,9,7],
[36,12,9,8],
[36,12,10,0],
[36,12,10,1],
[36,12,10,2],
[36,12,10,3],
[36,12,10,4],
[36,12,10,5],
[36,12,10,6],
[36,12,10,7],
[36,12,10,8],
[36,12,10,9],
[36,15,3,0],
[36,15,3,1],
[36,15,3,2],
[36,15,4,0],
[36,15,4,1],
[36,15,4,2],
[36,15,4,3],
[36,15,5,0],
[36,15,5,1],
[36,15,5,2],
[36,15,5,4],
[36,15,6,0],
[36,15,6,1],
[36,15,6,2],
[36,15,6,4],
[36,15,7,0],
[36,15,7,1],
[36,15,7,2],
[36,15,7,3],
[36,15,7,4],
[36,15,7,5],
[36,15,7,6],
[36,15,8,0],
[36,15,8,1],
[36,15,8,2],
[36,15,8,4],
[36,15,8,7],
[36,15,10,0],
[36,15,10,1],
[36,15,10,2],
[36,15,10,3],
[36,15,10,4],
[36,15,10,5],
[36,15,10,6],
[36,15,10,7],
[36,15,10,8],
[36,15,11,0],
[36,15,11,1],
[36,15,11,2],
[36,15,11,3],
[36,15,11,4],
[36,15,11,5],
[36,15,11,6],
[36,15,11,7],
[36,15,11,8],
[36,15,11,10],
[36,15,12,0],
[36,15,12,1],
[36,15,12,2],
[36,15,12,3],
[36,15,12,4],
[36,15,12,5],
[36,15,12,6],
[36,15,12,7],
[36,15,12,8],
[36,15,12,10],
[36,16,3,0],
[36,16,3,1],
[36,16,3,2],
[36,16,4,0],
[36,16,4,1],
[36,16,4,2],
[36,16,4,3],
[36,16,5,0],
[36,16,5,1],
[36,16,5,2],
[36,16,5,4],
[36,16,6,0],
[36,16,6,1],
[36,16,6,2],
[36,16,6,4],
[36,16,7,0],
[36,16,7,1],
[36,16,7,2],
[36,16,7,3],
[36,16,7,4],
[36,16,7,5],
[36,16,7,6],
[36,16,8,0],
[36,16,8,1],
[36,16,8,2],
[36,16,8,4],
[36,16,8,7],
[36,16,9,0],
[36,16,9,1],
[36,16,9,2],
[36,16,9,3],
[36,16,9,4],
[36,16,9,5],
[36,16,9,6],
[36,16,9,7],
[36,16,9,8],
[36,16,10,0],
[36,16,10,1],
[36,16,10,2],
[36,16,10,3],
[36,16,10,4],
[36,16,10,5],
[36,16,10,6],
[36,16,10,7],
[36,16,10,8],
[36,16,10,9],
[36,16,11,0],
[36,16,11,1],
[36,16,11,2],
[36,16,11,3],
[36,16,11,4],
[36,16,11,5],
[36,16,11,6],
[36,16,11,7],
[36,16,11,8],
[36,16,11,9],
[36,16,11,10],
[36,16,12,0],
[36,16,12,1],
[36,16,12,2],
[36,16,12,3],
[36,16,12,4],
[36,16,12,5],
[36,16,12,6],
[36,16,12,7],
[36,16,12,8],
[36,16,12,9],
[36,16,12,10],
[36,16,15,0],
[36,16,15,1],
[36,16,15,2],
[36,16,15,3],
[36,16,15,4],
[36,16,15,5],
[36,16,15,6],
[36,16,15,7],
[36,16,15,8],
[36,16,15,10],
[36,16,15,11],
[36,16,15,12],
[36,17,3,0],
[36,17,3,1],
[36,17,3,2],
[36,17,4,0],
[36,17,4,1],
[36,17,4,2],
[36,17,4,3],
[36,17,5,0],
[36,17,5,1],
[36,17,5,2],
[36,17,5,4],
[36,17,6,0],
[36,17,6,1],
[36,17,6,2],
[36,17,6,4],
[36,17,7,0],
[36,17,7,1],
[36,17,7,2],
[36,17,7,3],
[36,17,7,4],
[36,17,7,5],
[36,17,7,6],
[36,17,8,0],
[36,17,8,1],
[36,17,8,2],
[36,17,8,4],
[36,17,8,7],
[36,17,9,0],
[36,17,9,1],
[36,17,9,2],
[36,17,9,3],
[36,17,9,4],
[36,17,9,5],
[36,17,9,6],
[36,17,9,7],
[36,17,9,8],
[36,17,10,0],
[36,17,10,1],
[36,17,10,2],
[36,17,10,3],
[36,17,10,4],
[36,17,10,5],
[36,17,10,6],
[36,17,10,7],
[36,17,10,8],
[36,17,10,9],
[36,17,11,0],
[36,17,11,1],
[36,17,11,2],
[36,17,11,3],
[36,17,11,4],
[36,17,11,5],
[36,17,11,6],
[36,17,11,7],
[36,17,11,8],
[36,17,11,9],
[36,17,11,10],
[36,17,12,0],
[36,17,12,1],
[36,17,12,2],
[36,17,12,3],
[36,17,12,4],
[36,17,12,5],
[36,17,12,6],
[36,17,12,7],
[36,17,12,8],
[36,17,12,9],
[36,17,12,10],
[36,17,15,0],
[36,17,15,1],
[36,17,15,2],
[36,17,15,3],
[36,17,15,4],
[36,17,15,5],
[36,17,15,6],
[36,17,15,7],
[36,17,15,8],
[36,17,15,10],
[36,17,15,11],
[36,17,15,12],
[36,17,16,0],
[36,17,16,1],
[36,17,16,2],
[36,17,16,3],
[36,17,16,4],
[36,17,16,5],
[36,17,16,6],
[36,17,16,7],
[36,17,16,8],
[36,17,16,9],
[36,17,16,10],
[36,17,16,11],
[36,17,16,12],
[36,17,16,15],
[36,18,3,0],
[36,18,3,1],
[36,18,3,2],
[36,18,4,0],
[36,18,4,1],
[36,18,4,2],
[36,18,4,3],
[36,18,5,0],
[36,18,5,1],
[36,18,5,2],
[36,18,5,4],
[36,18,6,0],
[36,18,6,1],
[36,18,6,2],
[36,18,6,4],
[36,18,7,0],
[36,18,7,1],
[36,18,7,2],
[36,18,7,3],
[36,18,7,4],
[36,18,7,5],
[36,18,7,6],
[36,18,8,0],
[36,18,8,1],
[36,18,8,2],
[36,18,8,4],
[36,18,8,7],
[36,18,10,0],
[36,18,10,1],
[36,18,10,2],
[36,18,10,3],
[36,18,10,4],
[36,18,10,5],
[36,18,10,6],
[36,18,10,7],
[36,18,10,8],
[36,18,11,0],
[36,18,11,1],
[36,18,11,2],
[36,18,11,3],
[36,18,11,4],
[36,18,11,5],
[36,18,11,6],
[36,18,11,7],
[36,18,11,8],
[36,18,11,10],
[36,18,12,0],
[36,18,12,1],
[36,18,12,2],
[36,18,12,3],
[36,18,12,4],
[36,18,12,5],
[36,18,12,6],
[36,18,12,7],
[36,18,12,8],
[36,18,12,10],
[36,18,16,0],
[36,18,16,1],
[36,18,16,2],
[36,18,16,3],
[36,18,16,4],
[36,18,16,5],
[36,18,16,6],
[36,18,16,7],
[36,18,16,8],
[36,18,16,10],
[36,18,16,11],
[36,18,16,12],
[36,18,17,0],
[36,18,17,1],
[36,18,17,2],
[36,18,17,3],
[36,18,17,4],
[36,18,17,5],
[36,18,17,6],
[36,18,17,7],
[36,18,17,8],
[36,18,17,10],
[36,18,17,11],
[36,18,17,12],
[36,18,17,16],
[36,20,3,0],
[36,20,3,1],
[36,20,3,2],
[36,20,4,0],
[36,20,4,1],
[36,20,4,2],
[36,20,4,3],
[36,20,5,0],
[36,20,5,1],
[36,20,5,2],
[36,20,5,4],
[36,20,6,0],
[36,20,6,1],
[36,20,6,2],
[36,20,6,4],
[36,20,7,0],
[36,20,7,1],
[36,20,7,2],
[36,20,7,3],
[36,20,7,4],
[36,20,7,5],
[36,20,7,6],
[36,20,8,0],
[36,20,8,1],
[36,20,8,2],
[36,20,8,4],
[36,20,8,7],
[36,20,9,0],
[36,20,9,1],
[36,20,9,2],
[36,20,9,3],
[36,20,9,4],
[36,20,9,5],
[36,20,9,6],
[36,20,9,7],
[36,20,9,8],
[36,20,11,0],
[36,20,11,1],
[36,20,11,2],
[36,20,11,3],
[36,20,11,4],
[36,20,11,5],
[36,20,11,6],
[36,20,11,7],
[36,20,11,8],
[36,20,11,9],
[36,20,12,0],
[36,20,12,1],
[36,20,12,2],
[36,20,12,3],
[36,20,12,4],
[36,20,12,5],
[36,20,12,6],
[36,20,12,7],
[36,20,12,8],
[36,20,12,9],
[36,20,15,0],
[36,20,15,1],
[36,20,15,2],
[36,20,15,3],
[36,20,15,4],
[36,20,15,5],
[36,20,15,6],
[36,20,15,7],
[36,20,15,8],
[36,20,15,11],
[36,20,15,12],
[36,20,16,0],
[36,20,16,1],
[36,20,16,2],
[36,20,16,3],
[36,20,16,4],
[36,20,16,5],
[36,20,16,6],
[36,20,16,7],
[36,20,16,8],
[36,20,16,9],
[36,20,16,11],
[36,20,16,12],
[36,20,16,15],
[36,20,17,0],
[36,20,17,1],
[36,20,17,2],
[36,20,17,3],
[36,20,17,4],
[36,20,17,5],
[36,20,17,6],
[36,20,17,7],
[36,20,17,8],
[36,20,17,9],
[36,20,17,11],
[36,20,17,12],
[36,20,17,15],
[36,20,17,16],
[36,20,18,0],
[36,20,18,1],
[36,20,18,2],
[36,20,18,3],
[36,20,18,4],
[36,20,18,5],
[36,20,18,6],
[36,20,18,7],
[36,20,18,8],
[36,20,18,11],
[36,20,18,12],
[36,20,18,16],
[36,20,18,17],
[36,21,4,0],
[36,21,4,1],
[36,21,4,2],
[36,21,7,0],
[36,21,7,1],
[36,21,7,2],
[36,21,7,4],
[36,21,9,0],
[36,21,9,1],
[36,21,9,2],
[36,21,9,4],
[36,21,9,7],
[36,21,10,0],
[36,21,10,1],
[36,21,10,2],
[36,21,10,4],
[36,21,10,7],
[36,21,10,9],
[36,21,11,0],
[36,21,11,1],
[36,21,11,2],
[36,21,11,4],
[36,21,11,7],
[36,21,11,9],
[36,21,11,10],
[36,21,12,0],
[36,21,12,1],
[36,21,12,2],
[36,21,12,4],
[36,21,12,7],
[36,21,12,9],
[36,21,12,10],
[36,21,15,0],
[36,21,15,1],
[36,21,15,2],
[36,21,15,4],
[36,21,15,7],
[36,21,15,10],
[36,21,15,11],
[36,21,15,12],
[36,21,16,0],
[36,21,16,1],
[36,21,16,2],
[36,21,16,4],
[36,21,16,7],
[36,21,16,9],
[36,21,16,10],
[36,21,16,11],
[36,21,16,12],
[36,21,16,15],
[36,21,17,0],
[36,21,17,1],
[36,21,17,2],
[36,21,17,4],
[36,21,17,7],
[36,21,17,9],
[36,21,17,10],
[36,21,17,11],
[36,21,17,12],
[36,21,17,15],
[36,21,17,16],
[36,21,18,0],
[36,21,18,1],
[36,21,18,2],
[36,21,18,4],
[36,21,18,7],
[36,21,18,10],
[36,21,18,11],
[36,21,18,12],
[36,21,18,16],
[36,21,18,17],
[36,21,20,0],
[36,21,20,1],
[36,21,20,2],
[36,21,20,4],
[36,21,20,7],
[36,21,20,9],
[36,21,20,11],
[36,21,20,12],
[36,21,20,15],
[36,21,20,16],
[36,21,20,17],
[36,21,20,18],
[36,22,3,0],
[36,22,3,1],
[36,22,3,2],
[36,22,4,0],
[36,22,4,1],
[36,22,4,2],
[36,22,4,3],
[36,22,5,0],
[36,22,5,1],
[36,22,5,2],
[36,22,5,4],
[36,22,6,0],
[36,22,6,1],
[36,22,6,2],
[36,22,6,4],
[36,22,7,0],
[36,22,7,1],
[36,22,7,2],
[36,22,7,3],
[36,22,7,4],
[36,22,7,5],
[36,22,7,6],
[36,22,8,0],
[36,22,8,1],
[36,22,8,2],
[36,22,8,4],
[36,22,8,7],
[36,22,9,0],
[36,22,9,1],
[36,22,9,2],
[36,22,9,3],
[36,22,9,4],
[36,22,9,5],
[36,22,9,6],
[36,22,9,7],
[36,22,9,8],
[36,22,11,0],
[36,22,11,1],
[36,22,11,2],
[36,22,11,3],
[36,22,11,4],
[36,22,11,5],
[36,22,11,6],
[36,22,11,7],
[36,22,11,8],
[36,22,11,9],
[36,22,12,0],
[36,22,12,1],
[36,22,12,2],
[36,22,12,3],
[36,22,12,4],
[36,22,12,5],
[36,22,12,6],
[36,22,12,7],
[36,22,12,8],
[36,22,12,9],
[36,22,15,0],
[36,22,15,1],
[36,22,15,2],
[36,22,15,3],
[36,22,15,4],
[36,22,15,5],
[36,22,15,6],
[36,22,15,7],
[36,22,15,8],
[36,22,15,11],
[36,22,15,12],
[36,22,16,0],
[36,22,16,1],
[36,22,16,2],
[36,22,16,3],
[36,22,16,4],
[36,22,16,5],
[36,22,16,6],
[36,22,16,7],
[36,22,16,8],
[36,22,16,9],
[36,22,16,11],
[36,22,16,12],
[36,22,16,15],
[36,22,17,0],
[36,22,17,1],
[36,22,17,2],
[36,22,17,3],
[36,22,17,4],
[36,22,17,5],
[36,22,17,6],
[36,22,17,7],
[36,22,17,8],
[36,22,17,9],
[36,22,17,11],
[36,22,17,12],
[36,22,17,15],
[36,22,17,16],
[36,22,18,0],
[36,22,18,1],
[36,22,18,2],
[36,22,18,3],
[36,22,18,4],
[36,22,18,5],
[36,22,18,6],
[36,22,18,7],
[36,22,18,8],
[36,22,18,11],
[36,22,18,12],
[36,22,18,16],
[36,22,18,17],
[36,22,21,0],
[36,22,21,1],
[36,22,21,2],
[36,22,21,4],
[36,22,21,7],
[36,22,21,9],
[36,22,21,11],
[36,22,21,12],
[36,22,21,15],
[36,22,21,16],
[36,22,21,17],
[36,22,21,18],
[36,23,3,0],
[36,23,3,1],
[36,23,3,2],
[36,23,4,0],
[36,23,4,1],
[36,23,4,2],
[36,23,4,3],
[36,23,5,0],
[36,23,5,1],
[36,23,5,2],
[36,23,5,4],
[36,23,6,0],
[36,23,6,1],
[36,23,6,2],
[36,23,6,4],
[36,23,7,0],
[36,23,7,1],
[36,23,7,2],
[36,23,7,3],
[36,23,7,4],
[36,23,7,5],
[36,23,7,6],
[36,23,8,0],
[36,23,8,1],
[36,23,8,2],
[36,23,8,4],
[36,23,8,7],
[36,23,9,0],
[36,23,9,1],
[36,23,9,2],
[36,23,9,3],
[36,23,9,4],
[36,23,9,5],
[36,23,9,6],
[36,23,9,7],
[36,23,9,8],
[36,23,10,0],
[36,23,10,1],
[36,23,10,2],
[36,23,10,3],
[36,23,10,4],
[36,23,10,5],
[36,23,10,6],
[36,23,10,7],
[36,23,10,8],
[36,23,10,9],
[36,23,11,0],
[36,23,11,1],
[36,23,11,2],
[36,23,11,3],
[36,23,11,4],
[36,23,11,5],
[36,23,11,6],
[36,23,11,7],
[36,23,11,8],
[36,23,11,9],
[36,23,11,10],
[36,23,12,0],
[36,23,12,1],
[36,23,12,2],
[36,23,12,3],
[36,23,12,4],
[36,23,12,5],
[36,23,12,6],
[36,23,12,7],
[36,23,12,8],
[36,23,12,9],
[36,23,12,10],
[36,23,15,0],
[36,23,15,1],
[36,23,15,2],
[36,23,15,3],
[36,23,15,4],
[36,23,15,5],
[36,23,15,6],
[36,23,15,7],
[36,23,15,8],
[36,23,15,10],
[36,23,15,11],
[36,23,15,12],
[36,23,16,0],
[36,23,16,1],
[36,23,16,2],
[36,23,16,3],
[36,23,16,4],
[36,23,16,5],
[36,23,16,6],
[36,23,16,7],
[36,23,16,8],
[36,23,16,9],
[36,23,16,10],
[36,23,16,11],
[36,23,16,12],
[36,23,16,15],
[36,23,17,0],
[36,23,17,1],
[36,23,17,2],
[36,23,17,3],
[36,23,17,4],
[36,23,17,5],
[36,23,17,6],
[36,23,17,7],
[36,23,17,8],
[36,23,17,9],
[36,23,17,10],
[36,23,17,11],
[36,23,17,12],
[36,23,17,15],
[36,23,17,16],
[36,23,18,0],
[36,23,18,1],
[36,23,18,2],
[36,23,18,3],
[36,23,18,4],
[36,23,18,5],
[36,23,18,6],
[36,23,18,7],
[36,23,18,8],
[36,23,18,10],
[36,23,18,11],
[36,23,18,12],
[36,23,18,16],
[36,23,18,17],
[36,23,20,0],
[36,23,20,1],
[36,23,20,2],
[36,23,20,3],
[36,23,20,4],
[36,23,20,5],
[36,23,20,6],
[36,23,20,7],
[36,23,20,8],
[36,23,20,9],
[36,23,20,11],
[36,23,20,12],
[36,23,20,15],
[36,23,20,16],
[36,23,20,17],
[36,23,20,18],
[36,23,21,0],
[36,23,21,1],
[36,23,21,2],
[36,23,21,4],
[36,23,21,7],
[36,23,21,9],
[36,23,21,10],
[36,23,21,11],
[36,23,21,12],
[36,23,21,15],
[36,23,21,16],
[36,23,21,17],
[36,23,21,18],
[36,23,21,20],
[36,23,22,0],
[36,23,22,1],
[36,23,22,2],
[36,23,22,3],
[36,23,22,4],
[36,23,22,5],
[36,23,22,6],
[36,23,22,7],
[36,23,22,8],
[36,23,22,9],
[36,23,22,11],
[36,23,22,12],
[36,23,22,15],
[36,23,22,16],
[36,23,22,17],
[36,23,22,18],
[36,23,22,21],
[36,24,3,0],
[36,24,3,1],
[36,24,3,2],
[36,24,4,0],
[36,24,4,1],
[36,24,4,2],
[36,24,4,3],
[36,24,5,0],
[36,24,5,1],
[36,24,5,2],
[36,24,5,4],
[36,24,6,0],
[36,24,6,1],
[36,24,6,2],
[36,24,6,4],
[36,24,7,0],
[36,24,7,1],
[36,24,7,2],
[36,24,7,3],
[36,24,7,4],
[36,24,7,5],
[36,24,7,6],
[36,24,8,0],
[36,24,8,1],
[36,24,8,2],
[36,24,8,4],
[36,24,8,7],
[36,24,9,0],
[36,24,9,1],
[36,24,9,2],
[36,24,9,3],
[36,24,9,4],
[36,24,9,5],
[36,24,9,6],
[36,24,9,7],
[36,24,9,8],
[36,24,10,0],
[36,24,10,1],
[36,24,10,2],
[36,24,10,3],
[36,24,10,4],
[36,24,10,5],
[36,24,10,6],
[36,24,10,7],
[36,24,10,8],
[36,24,10,9],
[36,24,11,0],
[36,24,11,1],
[36,24,11,2],
[36,24,11,3],
[36,24,11,4],
[36,24,11,5],
[36,24,11,6],
[36,24,11,7],
[36,24,11,8],
[36,24,11,9],
[36,24,11,10],
[36,24,12,0],
[36,24,12,1],
[36,24,12,2],
[36,24,12,3],
[36,24,12,4],
[36,24,12,5],
[36,24,12,6],
[36,24,12,7],
[36,24,12,8],
[36,24,12,9],
[36,24,12,10],
[36,24,15,0],
[36,24,15,1],
[36,24,15,2],
[36,24,15,3],
[36,24,15,4],
[36,24,15,5],
[36,24,15,6],
[36,24,15,7],
[36,24,15,8],
[36,24,15,10],
[36,24,15,11],
[36,24,15,12],
[36,24,17,0],
[36,24,17,1],
[36,24,17,2],
[36,24,17,3],
[36,24,17,4],
[36,24,17,5],
[36,24,17,6],
[36,24,17,7],
[36,24,17,8],
[36,24,17,9],
[36,24,17,10],
[36,24,17,11],
[36,24,17,12],
[36,24,17,15],
[36,24,18,0],
[36,24,18,1],
[36,24,18,2],
[36,24,18,3],
[36,24,18,4],
[36,24,18,5],
[36,24,18,6],
[36,24,18,7],
[36,24,18,8],
[36,24,18,10],
[36,24,18,11],
[36,24,18,12],
[36,24,18,17],
[36,24,20,0],
[36,24,20,1],
[36,24,20,2],
[36,24,20,3],
[36,24,20,4],
[36,24,20,5],
[36,24,20,6],
[36,24,20,7],
[36,24,20,8],
[36,24,20,9],
[36,24,20,11],
[36,24,20,12],
[36,24,20,15],
[36,24,20,17],
[36,24,20,18],
[36,24,21,0],
[36,24,21,1],
[36,24,21,2],
[36,24,21,4],
[36,24,21,7],
[36,24,21,9],
[36,24,21,10],
[36,24,21,11],
[36,24,21,12],
[36,24,21,15],
[36,24,21,17],
[36,24,21,18],
[36,24,21,20],
[36,24,22,0],
[36,24,22,1],
[36,24,22,2],
[36,24,22,3],
[36,24,22,4],
[36,24,22,5],
[36,24,22,6],
[36,24,22,7],
[36,24,22,8],
[36,24,22,9],
[36,24,22,11],
[36,24,22,12],
[36,24,22,15],
[36,24,22,17],
[36,24,22,18],
[36,24,22,21],
[36,24,23,0],
[36,24,23,1],
[36,24,23,2],
[36,24,23,3],
[36,24,23,4],
[36,24,23,5],
[36,24,23,6],
[36,24,23,7],
[36,24,23,8],
[36,24,23,9],
[36,24,23,10],
[36,24,23,11],
[36,24,23,12],
[36,24,23,15],
[36,24,23,17],
[36,24,23,18],
[36,24,23,20],
[36,24,23,21],
[36,24,23,22],
[36,25,3,0],
[36,25,3,1],
[36,25,3,2],
[36,25,4,0],
[36,25,4,1],
[36,25,4,2],
[36,25,4,3],
[36,25,5,0],
[36,25,5,1],
[36,25,5,2],
[36,25,5,4],
[36,25,6,0],
[36,25,6,1],
[36,25,6,2],
[36,25,6,4],
[36,25,8,0],
[36,25,8,1],
[36,25,8,2],
[36,25,8,4],
[36,25,9,0],
[36,25,9,1],
[36,25,9,2],
[36,25,9,3],
[36,25,9,4],
[36,25,9,5],
[36,25,9,6],
[36,25,9,8],
[36,25,10,0],
[36,25,10,1],
[36,25,10,2],
[36,25,10,3],
[36,25,10,4],
[36,25,10,5],
[36,25,10,6],
[36,25,10,8],
[36,25,10,9],
[36,25,11,0],
[36,25,11,1],
[36,25,11,2],
[36,25,11,3],
[36,25,11,4],
[36,25,11,5],
[36,25,11,6],
[36,25,11,8],
[36,25,11,9],
[36,25,11,10],
[36,25,12,0],
[36,25,12,1],
[36,25,12,2],
[36,25,12,3],
[36,25,12,4],
[36,25,12,5],
[36,25,12,6],
[36,25,12,8],
[36,25,12,9],
[36,25,12,10],
[36,25,15,0],
[36,25,15,1],
[36,25,15,2],
[36,25,15,3],
[36,25,15,4],
[36,25,15,5],
[36,25,15,6],
[36,25,15,8],
[36,25,15,10],
[36,25,15,11],
[36,25,15,12],
[36,25,16,0],
[36,25,16,1],
[36,25,16,2],
[36,25,16,3],
[36,25,16,4],
[36,25,16,5],
[36,25,16,6],
[36,25,16,8],
[36,25,16,9],
[36,25,16,10],
[36,25,16,11],
[36,25,16,12],
[36,25,16,15],
[36,25,17,0],
[36,25,17,1],
[36,25,17,2],
[36,25,17,3],
[36,25,17,4],
[36,25,17,5],
[36,25,17,6],
[36,25,17,8],
[36,25,17,9],
[36,25,17,10],
[36,25,17,11],
[36,25,17,12],
[36,25,17,15],
[36,25,17,16],
[36,25,18,0],
[36,25,18,1],
[36,25,18,2],
[36,25,18,3],
[36,25,18,4],
[36,25,18,5],
[36,25,18,6],
[36,25,18,8],
[36,25,18,10],
[36,25,18,11],
[36,25,18,12],
[36,25,18,16],
[36,25,18,17],
[36,25,20,0],
[36,25,20,1],
[36,25,20,2],
[36,25,20,3],
[36,25,20,4],
[36,25,20,5],
[36,25,20,6],
[36,25,20,8],
[36,25,20,9],
[36,25,20,11],
[36,25,20,12],
[36,25,20,15],
[36,25,20,16],
[36,25,20,17],
[36,25,20,18],
[36,25,21,0],
[36,25,21,1],
[36,25,21,2],
[36,25,21,4],
[36,25,21,9],
[36,25,21,10],
[36,25,21,11],
[36,25,21,12],
[36,25,21,15],
[36,25,21,16],
[36,25,21,17],
[36,25,21,18],
[36,25,21,20],
[36,25,22,0],
[36,25,22,1],
[36,25,22,2],
[36,25,22,3],
[36,25,22,4],
[36,25,22,5],
[36,25,22,6],
[36,25,22,8],
[36,25,22,9],
[36,25,22,11],
[36,25,22,12],
[36,25,22,15],
[36,25,22,16],
[36,25,22,17],
[36,25,22,18],
[36,25,22,21],
[36,25,23,0],
[36,25,23,1],
[36,25,23,2],
[36,25,23,3],
[36,25,23,4],
[36,25,23,5],
[36,25,23,6],
[36,25,23,8],
[36,25,23,9],
[36,25,23,10],
[36,25,23,11],
[36,25,23,12],
[36,25,23,15],
[36,25,23,16],
[36,25,23,17],
[36,25,23,18],
[36,25,23,20],
[36,25,23,21],
[36,25,23,22],
[36,25,24,0],
[36,25,24,1],
[36,25,24,2],
[36,25,24,3],
[36,25,24,4],
[36,25,24,5],
[36,25,24,6],
[36,25,24,8],
[36,25,24,9],
[36,25,24,10],
[36,25,24,11],
[36,25,24,12],
[36,25,24,15],
[36,25,24,17],
[36,25,24,18],
[36,25,24,20],
[36,25,24,21],
[36,25,24,22],
[36,25,24,23],
[36,26,3,0],
[36,26,3,1],
[36,26,3,2],
[36,26,4,0],
[36,26,4,1],
[36,26,4,2],
[36,26,4,3],
[36,26,5,0],
[36,26,5,1],
[36,26,5,2],
[36,26,5,4],
[36,26,6,0],
[36,26,6,1],
[36,26,6,2],
[36,26,6,4],
[36,26,7,0],
[36,26,7,1],
[36,26,7,2],
[36,26,7,3],
[36,26,7,4],
[36,26,7,5],
[36,26,7,6],
[36,26,8,0],
[36,26,8,1],
[36,26,8,2],
[36,26,8,4],
[36,26,8,7],
[36,26,10,0],
[36,26,10,1],
[36,26,10,2],
[36,26,10,3],
[36,26,10,4],
[36,26,10,5],
[36,26,10,6],
[36,26,10,7],
[36,26,10,8],
[36,26,11,0],
[36,26,11,1],
[36,26,11,2],
[36,26,11,3],
[36,26,11,4],
[36,26,11,5],
[36,26,11,6],
[36,26,11,7],
[36,26,11,8],
[36,26,11,10],
[36,26,12,0],
[36,26,12,1],
[36,26,12,2],
[36,26,12,3],
[36,26,12,4],
[36,26,12,5],
[36,26,12,6],
[36,26,12,7],
[36,26,12,8],
[36,26,12,10],
[36,26,16,0],
[36,26,16,1],
[36,26,16,2],
[36,26,16,3],
[36,26,16,4],
[36,26,16,5],
[36,26,16,6],
[36,26,16,7],
[36,26,16,8],
[36,26,16,10],
[36,26,16,11],
[36,26,16,12],
[36,26,17,0],
[36,26,17,1],
[36,26,17,2],
[36,26,17,3],
[36,26,17,4],
[36,26,17,5],
[36,26,17,6],
[36,26,17,7],
[36,26,17,8],
[36,26,17,10],
[36,26,17,11],
[36,26,17,12],
[36,26,17,16],
[36,26,20,0],
[36,26,20,1],
[36,26,20,2],
[36,26,20,3],
[36,26,20,4],
[36,26,20,5],
[36,26,20,6],
[36,26,20,7],
[36,26,20,8],
[36,26,20,11],
[36,26,20,12],
[36,26,20,16],
[36,26,20,17],
[36,26,21,0],
[36,26,21,1],
[36,26,21,2],
[36,26,21,4],
[36,26,21,7],
[36,26,21,10],
[36,26,21,11],
[36,26,21,12],
[36,26,21,16],
[36,26,21,17],
[36,26,21,20],
[36,26,22,0],
[36,26,22,1],
[36,26,22,2],
[36,26,22,3],
[36,26,22,4],
[36,26,22,5],
[36,26,22,6],
[36,26,22,7],
[36,26,22,8],
[36,26,22,11],
[36,26,22,12],
[36,26,22,16],
[36,26,22,17],
[36,26,22,21],
[36,26,23,0],
[36,26,23,1],
[36,26,23,2],
[36,26,23,3],
[36,26,23,4],
[36,26,23,5],
[36,26,23,6],
[36,26,23,7],
[36,26,23,8],
[36,26,23,10],
[36,26,23,11],
[36,26,23,12],
[36,26,23,16],
[36,26,23,17],
[36,26,23,20],
[36,26,23,21],
[36,26,23,22],
[36,26,24,0],
[36,26,24,1],
[36,26,24,2],
[36,26,24,3],
[36,26,24,4],
[36,26,24,5],
[36,26,24,6],
[36,26,24,7],
[36,26,24,8],
[36,26,24,10],
[36,26,24,11],
[36,26,24,12],
[36,26,24,17],
[36,26,24,20],
[36,26,24,21],
[36,26,24,22],
[36,26,24,23],
[36,26,25,0],
[36,26,25,1],
[36,26,25,2],
[36,26,25,3],
[36,26,25,4],
[36,26,25,5],
[36,26,25,6],
[36,26,25,8],
[36,26,25,10],
[36,26,25,11],
[36,26,25,12],
[36,26,25,16],
[36,26,25,17],
[36,26,25,20],
[36,26,25,21],
[36,26,25,22],
[36,26,25,23],
[36,26,25,24],
[36,27,3,0],
[36,27,3,1],
[36,27,3,2],
[36,27,4,0],
[36,27,4,1],
[36,27,4,2],
[36,27,4,3],
[36,27,5,0],
[36,27,5,1],
[36,27,5,2],
[36,27,5,4],
[36,27,6,0],
[36,27,6,1],
[36,27,6,2],
[36,27,6,4],
[36,27,7,0],
[36,27,7,1],
[36,27,7,2],
[36,27,7,3],
[36,27,7,4],
[36,27,7,5],
[36,27,7,6],
[36,27,8,0],
[36,27,8,1],
[36,27,8,2],
[36,27,8,4],
[36,27,8,7],
[36,27,9,0],
[36,27,9,1],
[36,27,9,2],
[36,27,9,3],
[36,27,9,4],
[36,27,9,5],
[36,27,9,6],
[36,27,9,7],
[36,27,9,8],
[36,27,11,0],
[36,27,11,1],
[36,27,11,2],
[36,27,11,3],
[36,27,11,4],
[36,27,11,5],
[36,27,11,6],
[36,27,11,7],
[36,27,11,8],
[36,27,11,9],
[36,27,12,0],
[36,27,12,1],
[36,27,12,2],
[36,27,12,3],
[36,27,12,4],
[36,27,12,5],
[36,27,12,6],
[36,27,12,7],
[36,27,12,8],
[36,27,12,9],
[36,27,15,0],
[36,27,15,1],
[36,27,15,2],
[36,27,15,3],
[36,27,15,4],
[36,27,15,5],
[36,27,15,6],
[36,27,15,7],
[36,27,15,8],
[36,27,15,11],
[36,27,15,12],
[36,27,16,0],
[36,27,16,1],
[36,27,16,2],
[36,27,16,3],
[36,27,16,4],
[36,27,16,5],
[36,27,16,6],
[36,27,16,7],
[36,27,16,8],
[36,27,16,9],
[36,27,16,11],
[36,27,16,12],
[36,27,16,15],
[36,27,17,0],
[36,27,17,1],
[36,27,17,2],
[36,27,17,3],
[36,27,17,4],
[36,27,17,5],
[36,27,17,6],
[36,27,17,7],
[36,27,17,8],
[36,27,17,9],
[36,27,17,11],
[36,27,17,12],
[36,27,17,15],
[36,27,17,16],
[36,27,18,0],
[36,27,18,1],
[36,27,18,2],
[36,27,18,3],
[36,27,18,4],
[36,27,18,5],
[36,27,18,6],
[36,27,18,7],
[36,27,18,8],
[36,27,18,11],
[36,27,18,12],
[36,27,18,16],
[36,27,18,17],
[36,27,21,0],
[36,27,21,1],
[36,27,21,2],
[36,27,21,4],
[36,27,21,7],
[36,27,21,9],
[36,27,21,11],
[36,27,21,12],
[36,27,21,15],
[36,27,21,16],
[36,27,21,17],
[36,27,21,18],
[36,27,23,0],
[36,27,23,1],
[36,27,23,2],
[36,27,23,3],
[36,27,23,4],
[36,27,23,5],
[36,27,23,6],
[36,27,23,7],
[36,27,23,8],
[36,27,23,9],
[36,27,23,11],
[36,27,23,12],
[36,27,23,15],
[36,27,23,16],
[36,27,23,17],
[36,27,23,18],
[36,27,23,21],
[36,27,24,0],
[36,27,24,1],
[36,27,24,2],
[36,27,24,3],
[36,27,24,4],
[36,27,24,5],
[36,27,24,6],
[36,27,24,7],
[36,27,24,8],
[36,27,24,9],
[36,27,24,11],
[36,27,24,12],
[36,27,24,15],
[36,27,24,17],
[36,27,24,18],
[36,27,24,21],
[36,27,24,23],
[36,27,25,0],
[36,27,25,1],
[36,27,25,2],
[36,27,25,3],
[36,27,25,4],
[36,27,25,5],
[36,27,25,6],
[36,27,25,8],
[36,27,25,9],
[36,27,25,11],
[36,27,25,12],
[36,27,25,15],
[36,27,25,16],
[36,27,25,17],
[36,27,25,18],
[36,27,25,21],
[36,27,25,23],
[36,27,25,24],
[36,27,26,0],
[36,27,26,1],
[36,27,26,2],
[36,27,26,3],
[36,27,26,4],
[36,27,26,5],
[36,27,26,6],
[36,27,26,7],
[36,27,26,8],
[36,27,26,11],
[36,27,26,12],
[36,27,26,16],
[36,27,26,17],
[36,27,26,21],
[36,27,26,23],
[36,27,26,24],
[36,27,26,25],
[36,28,3,0],
[36,28,3,1],
[36,28,3,2],
[36,28,4,0],
[36,28,4,1],
[36,28,4,2],
[36,28,4,3],
[36,28,5,0],
[36,28,5,1],
[36,28,5,2],
[36,28,5,4],
[36,28,6,0],
[36,28,6,1],
[36,28,6,2],
[36,28,6,4],
[36,28,8,0],
[36,28,8,1],
[36,28,8,2],
[36,28,8,4],
[36,28,9,0],
[36,28,9,1],
[36,28,9,2],
[36,28,9,3],
[36,28,9,4],
[36,28,9,5],
[36,28,9,6],
[36,28,9,8],
[36,28,10,0],
[36,28,10,1],
[36,28,10,2],
[36,28,10,3],
[36,28,10,4],
[36,28,10,5],
[36,28,10,6],
[36,28,10,8],
[36,28,10,9],
[36,28,11,0],
[36,28,11,1],
[36,28,11,2],
[36,28,11,3],
[36,28,11,4],
[36,28,11,5],
[36,28,11,6],
[36,28,11,8],
[36,28,11,9],
[36,28,11,10],
[36,28,12,0],
[36,28,12,1],
[36,28,12,2],
[36,28,12,3],
[36,28,12,4],
[36,28,12,5],
[36,28,12,6],
[36,28,12,8],
[36,28,12,9],
[36,28,12,10],
[36,28,15,0],
[36,28,15,1],
[36,28,15,2],
[36,28,15,3],
[36,28,15,4],
[36,28,15,5],
[36,28,15,6],
[36,28,15,8],
[36,28,15,10],
[36,28,15,11],
[36,28,15,12],
[36,28,16,0],
[36,28,16,1],
[36,28,16,2],
[36,28,16,3],
[36,28,16,4],
[36,28,16,5],
[36,28,16,6],
[36,28,16,8],
[36,28,16,9],
[36,28,16,10],
[36,28,16,11],
[36,28,16,12],
[36,28,16,15],
[36,28,17,0],
[36,28,17,1],
[36,28,17,2],
[36,28,17,3],
[36,28,17,4],
[36,28,17,5],
[36,28,17,6],
[36,28,17,8],
[36,28,17,9],
[36,28,17,10],
[36,28,17,11],
[36,28,17,12],
[36,28,17,15],
[36,28,17,16],
[36,28,18,0],
[36,28,18,1],
[36,28,18,2],
[36,28,18,3],
[36,28,18,4],
[36,28,18,5],
[36,28,18,6],
[36,28,18,8],
[36,28,18,10],
[36,28,18,11],
[36,28,18,12],
[36,28,18,16],
[36,28,18,17],
[36,28,20,0],
[36,28,20,1],
[36,28,20,2],
[36,28,20,3],
[36,28,20,4],
[36,28,20,5],
[36,28,20,6],
[36,28,20,8],
[36,28,20,9],
[36,28,20,11],
[36,28,20,12],
[36,28,20,15],
[36,28,20,16],
[36,28,20,17],
[36,28,20,18],
[36,28,21,0],
[36,28,21,1],
[36,28,21,2],
[36,28,21,4],
[36,28,21,9],
[36,28,21,10],
[36,28,21,11],
[36,28,21,12],
[36,28,21,15],
[36,28,21,16],
[36,28,21,17],
[36,28,21,18],
[36,28,21,20],
[36,28,22,0],
[36,28,22,1],
[36,28,22,2],
[36,28,22,3],
[36,28,22,4],
[36,28,22,5],
[36,28,22,6],
[36,28,22,8],
[36,28,22,9],
[36,28,22,11],
[36,28,22,12],
[36,28,22,15],
[36,28,22,16],
[36,28,22,17],
[36,28,22,18],
[36,28,22,21],
[36,28,23,0],
[36,28,23,1],
[36,28,23,2],
[36,28,23,3],
[36,28,23,4],
[36,28,23,5],
[36,28,23,6],
[36,28,23,8],
[36,28,23,9],
[36,28,23,10],
[36,28,23,11],
[36,28,23,12],
[36,28,23,15],
[36,28,23,16],
[36,28,23,17],
[36,28,23,18],
[36,28,23,20],
[36,28,23,21],
[36,28,23,22],
[36,28,24,0],
[36,28,24,1],
[36,28,24,2],
[36,28,24,3],
[36,28,24,4],
[36,28,24,5],
[36,28,24,6],
[36,28,24,8],
[36,28,24,9],
[36,28,24,10],
[36,28,24,11],
[36,28,24,12],
[36,28,24,15],
[36,28,24,17],
[36,28,24,18],
[36,28,24,20],
[36,28,24,21],
[36,28,24,22],
[36,28,24,23],
[36,28,26,0],
[36,28,26,1],
[36,28,26,2],
[36,28,26,3],
[36,28,26,4],
[36,28,26,5],
[36,28,26,6],
[36,28,26,8],
[36,28,26,10],
[36,28,26,11],
[36,28,26,12],
[36,28,26,16],
[36,28,26,17],
[36,28,26,20],
[36,28,26,21],
[36,28,26,22],
[36,28,26,23],
[36,28,26,24],
[36,28,27,0],
[36,28,27,1],
[36,28,27,2],
[36,28,27,3],
[36,28,27,4],
[36,28,27,5],
[36,28,27,6],
[36,28,27,8],
[36,28,27,9],
[36,28,27,11],
[36,28,27,12],
[36,28,27,15],
[36,28,27,16],
[36,28,27,17],
[36,28,27,18],
[36,28,27,21],
[36,28,27,23],
[36,28,27,24],
[36,28,27,26],
[36,29,3,0],
[36,29,3,1],
[36,29,3,2],
[36,29,4,0],
[36,29,4,1],
[36,29,4,2],
[36,29,4,3],
[36,29,5,0],
[36,29,5,1],
[36,29,5,2],
[36,29,5,4],
[36,29,6,0],
[36,29,6,1],
[36,29,6,2],
[36,29,6,4],
[36,29,7,0],
[36,29,7,1],
[36,29,7,2],
[36,29,7,3],
[36,29,7,4],
[36,29,7,5],
[36,29,7,6],
[36,29,8,0],
[36,29,8,1],
[36,29,8,2],
[36,29,8,4],
[36,29,8,7],
[36,29,9,0],
[36,29,9,1],
[36,29,9,2],
[36,29,9,3],
[36,29,9,4],
[36,29,9,5],
[36,29,9,6],
[36,29,9,7],
[36,29,9,8],
[36,29,10,0],
[36,29,10,1],
[36,29,10,2],
[36,29,10,3],
[36,29,10,4],
[36,29,10,5],
[36,29,10,6],
[36,29,10,7],
[36,29,10,8],
[36,29,10,9],
[36,29,15,0],
[36,29,15,1],
[36,29,15,2],
[36,29,15,3],
[36,29,15,4],
[36,29,15,5],
[36,29,15,6],
[36,29,15,7],
[36,29,15,8],
[36,29,15,10],
[36,29,16,0],
[36,29,16,1],
[36,29,16,2],
[36,29,16,3],
[36,29,16,4],
[36,29,16,5],
[36,29,16,6],
[36,29,16,7],
[36,29,16,8],
[36,29,16,9],
[36,29,16,10],
[36,29,16,15],
[36,29,17,0],
[36,29,17,1],
[36,29,17,2],
[36,29,17,3],
[36,29,17,4],
[36,29,17,5],
[36,29,17,6],
[36,29,17,7],
[36,29,17,8],
[36,29,17,9],
[36,29,17,10],
[36,29,17,15],
[36,29,17,16],
[36,29,18,0],
[36,29,18,1],
[36,29,18,2],
[36,29,18,3],
[36,29,18,4],
[36,29,18,5],
[36,29,18,6],
[36,29,18,7],
[36,29,18,8],
[36,29,18,10],
[36,29,18,16],
[36,29,18,17],
[36,29,20,0],
[36,29,20,1],
[36,29,20,2],
[36,29,20,3],
[36,29,20,4],
[36,29,20,5],
[36,29,20,6],
[36,29,20,7],
[36,29,20,8],
[36,29,20,9],
[36,29,20,15],
[36,29,20,16],
[36,29,20,17],
[36,29,20,18],
[36,29,21,0],
[36,29,21,1],
[36,29,21,2],
[36,29,21,4],
[36,29,21,7],
[36,29,21,9],
[36,29,21,10],
[36,29,21,15],
[36,29,21,16],
[36,29,21,17],
[36,29,21,18],
[36,29,21,20],
[36,29,22,0],
[36,29,22,1],
[36,29,22,2],
[36,29,22,3],
[36,29,22,4],
[36,29,22,5],
[36,29,22,6],
[36,29,22,7],
[36,29,22,8],
[36,29,22,9],
[36,29,22,15],
[36,29,22,16],
[36,29,22,17],
[36,29,22,18],
[36,29,22,21],
[36,29,23,0],
[36,29,23,1],
[36,29,23,2],
[36,29,23,3],
[36,29,23,4],
[36,29,23,5],
[36,29,23,6],
[36,29,23,7],
[36,29,23,8],
[36,29,23,9],
[36,29,23,10],
[36,29,23,15],
[36,29,23,16],
[36,29,23,17],
[36,29,23,18],
[36,29,23,20],
[36,29,23,21],
[36,29,23,22],
[36,29,24,0],
[36,29,24,1],
[36,29,24,2],
[36,29,24,3],
[36,29,24,4],
[36,29,24,5],
[36,29,24,6],
[36,29,24,7],
[36,29,24,8],
[36,29,24,9],
[36,29,24,10],
[36,29,24,15],
[36,29,24,17],
[36,29,24,18],
[36,29,24,20],
[36,29,24,21],
[36,29,24,22],
[36,29,24,23],
[36,29,25,0],
[36,29,25,1],
[36,29,25,2],
[36,29,25,3],
[36,29,25,4],
[36,29,25,5],
[36,29,25,6],
[36,29,25,8],
[36,29,25,9],
[36,29,25,10],
[36,29,25,15],
[36,29,25,16],
[36,29,25,17],
[36,29,25,18],
[36,29,25,20],
[36,29,25,21],
[36,29,25,22],
[36,29,25,23],
[36,29,25,24],
[36,29,26,0],
[36,29,26,1],
[36,29,26,2],
[36,29,26,3],
[36,29,26,4],
[36,29,26,5],
[36,29,26,6],
[36,29,26,7],
[36,29,26,8],
[36,29,26,10],
[36,29,26,16],
[36,29,26,17],
[36,29,26,20],
[36,29,26,21],
[36,29,26,22],
[36,29,26,23],
[36,29,26,24],
[36,29,26,25],
[36,29,27,0],
[36,29,27,1],
[36,29,27,2],
[36,29,27,3],
[36,29,27,4],
[36,29,27,5],
[36,29,27,6],
[36,29,27,7],
[36,29,27,8],
[36,29,27,9],
[36,29,27,15],
[36,29,27,16],
[36,29,27,17],
[36,29,27,18],
[36,29,27,21],
[36,29,27,23],
[36,29,27,24],
[36,29,27,25],
[36,29,27,26],
[36,29,28,0],
[36,29,28,1],
[36,29,28,2],
[36,29,28,3],
[36,29,28,4],
[36,29,28,5],
[36,29,28,6],
[36,29,28,8],
[36,29,28,9],
[36,29,28,10],
[36,29,28,15],
[36,29,28,16],
[36,29,28,17],
[36,29,28,18],
[36,29,28,20],
[36,29,28,21],
[36,29,28,22],
[36,29,28,23],
[36,29,28,24],
[36,29,28,26],
[36,29,28,27],
[36,30,3,0],
[36,30,3,1],
[36,30,3,2],
[36,30,4,0],
[36,30,4,1],
[36,30,4,2],
[36,30,4,3],
[36,30,5,0],
[36,30,5,1],
[36,30,5,2],
[36,30,5,4],
[36,30,6,0],
[36,30,6,1],
[36,30,6,2],
[36,30,6,4],
[36,30,7,0],
[36,30,7,1],
[36,30,7,2],
[36,30,7,3],
[36,30,7,4],
[36,30,7,5],
[36,30,7,6],
[36,30,8,0],
[36,30,8,1],
[36,30,8,2],
[36,30,8,4],
[36,30,8,7],
[36,30,9,0],
[36,30,9,1],
[36,30,9,2],
[36,30,9,3],
[36,30,9,4],
[36,30,9,5],
[36,30,9,6],
[36,30,9,7],
[36,30,9,8],
[36,30,10,0],
[36,30,10,1],
[36,30,10,2],
[36,30,10,3],
[36,30,10,4],
[36,30,10,5],
[36,30,10,6],
[36,30,10,7],
[36,30,10,8],
[36,30,10,9],
[36,30,11,0],
[36,30,11,1],
[36,30,11,2],
[36,30,11,3],
[36,30,11,4],
[36,30,11,5],
[36,30,11,6],
[36,30,11,7],
[36,30,11,8],
[36,30,11,9],
[36,30,11,10],
[36,30,12,0],
[36,30,12,1],
[36,30,12,2],
[36,30,12,3],
[36,30,12,4],
[36,30,12,5],
[36,30,12,6],
[36,30,12,7],
[36,30,12,8],
[36,30,12,9],
[36,30,12,10],
[36,30,15,0],
[36,30,15,1],
[36,30,15,2],
[36,30,15,3],
[36,30,15,4],
[36,30,15,5],
[36,30,15,6],
[36,30,15,7],
[36,30,15,8],
[36,30,15,10],
[36,30,15,11],
[36,30,15,12],
[36,30,16,0],
[36,30,16,1],
[36,30,16,2],
[36,30,16,3],
[36,30,16,4],
[36,30,16,5],
[36,30,16,6],
[36,30,16,7],
[36,30,16,8],
[36,30,16,9],
[36,30,16,10],
[36,30,16,11],
[36,30,16,12],
[36,30,16,15],
[36,30,17,0],
[36,30,17,1],
[36,30,17,2],
[36,30,17,3],
[36,30,17,4],
[36,30,17,5],
[36,30,17,6],
[36,30,17,7],
[36,30,17,8],
[36,30,17,9],
[36,30,17,10],
[36,30,17,11],
[36,30,17,12],
[36,30,17,15],
[36,30,17,16],
[36,30,18,0],
[36,30,18,1],
[36,30,18,2],
[36,30,18,3],
[36,30,18,4],
[36,30,18,5],
[36,30,18,6],
[36,30,18,7],
[36,30,18,8],
[36,30,18,10],
[36,30,18,11],
[36,30,18,12],
[36,30,18,16],
[36,30,18,17],
[36,30,20,0],
[36,30,20,1],
[36,30,20,2],
[36,30,20,3],
[36,30,20,4],
[36,30,20,5],
[36,30,20,6],
[36,30,20,7],
[36,30,20,8],
[36,30,20,9],
[36,30,20,11],
[36,30,20,12],
[36,30,20,15],
[36,30,20,16],
[36,30,20,17],
[36,30,20,18],
[36,30,21,0],
[36,30,21,1],
[36,30,21,2],
[36,30,21,4],
[36,30,21,7],
[36,30,21,9],
[36,30,21,10],
[36,30,21,11],
[36,30,21,12],
[36,30,21,15],
[36,30,21,16],
[36,30,21,17],
[36,30,21,18],
[36,30,21,20],
[36,30,22,0],
[36,30,22,1],
[36,30,22,2],
[36,30,22,3],
[36,30,22,4],
[36,30,22,5],
[36,30,22,6],
[36,30,22,7],
[36,30,22,8],
[36,30,22,9],
[36,30,22,11],
[36,30,22,12],
[36,30,22,15],
[36,30,22,16],
[36,30,22,17],
[36,30,22,18],
[36,30,22,21],
[36,30,24,0],
[36,30,24,1],
[36,30,24,2],
[36,30,24,3],
[36,30,24,4],
[36,30,24,5],
[36,30,24,6],
[36,30,24,7],
[36,30,24,8],
[36,30,24,9],
[36,30,24,10],
[36,30,24,11],
[36,30,24,12],
[36,30,24,15],
[36,30,24,17],
[36,30,24,18],
[36,30,24,20],
[36,30,24,21],
[36,30,24,22],
[36,30,25,0],
[36,30,25,1],
[36,30,25,2],
[36,30,25,3],
[36,30,25,4],
[36,30,25,5],
[36,30,25,6],
[36,30,25,8],
[36,30,25,9],
[36,30,25,10],
[36,30,25,11],
[36,30,25,12],
[36,30,25,15],
[36,30,25,16],
[36,30,25,17],
[36,30,25,18],
[36,30,25,20],
[36,30,25,21],
[36,30,25,22],
[36,30,25,24],
[36,30,26,0],
[36,30,26,1],
[36,30,26,2],
[36,30,26,3],
[36,30,26,4],
[36,30,26,5],
[36,30,26,6],
[36,30,26,7],
[36,30,26,8],
[36,30,26,10],
[36,30,26,11],
[36,30,26,12],
[36,30,26,16],
[36,30,26,17],
[36,30,26,20],
[36,30,26,21],
[36,30,26,22],
[36,30,26,24],
[36,30,26,25],
[36,30,27,0],
[36,30,27,1],
[36,30,27,2],
[36,30,27,3],
[36,30,27,4],
[36,30,27,5],
[36,30,27,6],
[36,30,27,7],
[36,30,27,8],
[36,30,27,9],
[36,30,27,11],
[36,30,27,12],
[36,30,27,15],
[36,30,27,16],
[36,30,27,17],
[36,30,27,18],
[36,30,27,21],
[36,30,27,24],
[36,30,27,25],
[36,30,27,26],
[36,30,28,0],
[36,30,28,1],
[36,30,28,2],
[36,30,28,3],
[36,30,28,4],
[36,30,28,5],
[36,30,28,6],
[36,30,28,8],
[36,30,28,9],
[36,30,28,10],
[36,30,28,11],
[36,30,28,12],
[36,30,28,15],
[36,30,28,16],
[36,30,28,17],
[36,30,28,18],
[36,30,28,20],
[36,30,28,21],
[36,30,28,22],
[36,30,28,24],
[36,30,28,26],
[36,30,28,27],
[36,30,29,0],
[36,30,29,1],
[36,30,29,2],
[36,30,29,3],
[36,30,29,4],
[36,30,29,5],
[36,30,29,6],
[36,30,29,7],
[36,30,29,8],
[36,30,29,9],
[36,30,29,10],
[36,30,29,15],
[36,30,29,16],
[36,30,29,17],
[36,30,29,18],
[36,30,29,20],
[36,30,29,21],
[36,30,29,22],
[36,30,29,24],
[36,30,29,25],
[36,30,29,26],
[36,30,29,27],
[36,30,29,28],
[36,31,3,0],
[36,31,3,1],
[36,31,3,2],
[36,31,4,0],
[36,31,4,1],
[36,31,4,2],
[36,31,4,3],
[36,31,5,0],
[36,31,5,1],
[36,31,5,2],
[36,31,5,4],
[36,31,6,0],
[36,31,6,1],
[36,31,6,2],
[36,31,6,4],
[36,31,7,0],
[36,31,7,1],
[36,31,7,2],
[36,31,7,3],
[36,31,7,4],
[36,31,7,5],
[36,31,7,6],
[36,31,8,0],
[36,31,8,1],
[36,31,8,2],
[36,31,8,4],
[36,31,8,7],
[36,31,9,0],
[36,31,9,1],
[36,31,9,2],
[36,31,9,3],
[36,31,9,4],
[36,31,9,5],
[36,31,9,6],
[36,31,9,7],
[36,31,9,8],
[36,31,10,0],
[36,31,10,1],
[36,31,10,2],
[36,31,10,3],
[36,31,10,4],
[36,31,10,5],
[36,31,10,6],
[36,31,10,7],
[36,31,10,8],
[36,31,10,9],
[36,31,11,0],
[36,31,11,1],
[36,31,11,2],
[36,31,11,3],
[36,31,11,4],
[36,31,11,5],
[36,31,11,6],
[36,31,11,7],
[36,31,11,8],
[36,31,11,9],
[36,31,11,10],
[36,31,12,0],
[36,31,12,1],
[36,31,12,2],
[36,31,12,3],
[36,31,12,4],
[36,31,12,5],
[36,31,12,6],
[36,31,12,7],
[36,31,12,8],
[36,31,12,9],
[36,31,12,10],
[36,31,15,0],
[36,31,15,1],
[36,31,15,2],
[36,31,15,3],
[36,31,15,4],
[36,31,15,5],
[36,31,15,6],
[36,31,15,7],
[36,31,15,8],
[36,31,15,10],
[36,31,15,11],
[36,31,15,12],
[36,31,16,0],
[36,31,16,1],
[36,31,16,2],
[36,31,16,3],
[36,31,16,4],
[36,31,16,5],
[36,31,16,6],
[36,31,16,7],
[36,31,16,8],
[36,31,16,9],
[36,31,16,10],
[36,31,16,11],
[36,31,16,12],
[36,31,16,15],
[36,31,17,0],
[36,31,17,1],
[36,31,17,2],
[36,31,17,3],
[36,31,17,4],
[36,31,17,5],
[36,31,17,6],
[36,31,17,7],
[36,31,17,8],
[36,31,17,9],
[36,31,17,10],
[36,31,17,11],
[36,31,17,12],
[36,31,17,15],
[36,31,17,16],
[36,31,18,0],
[36,31,18,1],
[36,31,18,2],
[36,31,18,3],
[36,31,18,4],
[36,31,18,5],
[36,31,18,6],
[36,31,18,7],
[36,31,18,8],
[36,31,18,10],
[36,31,18,11],
[36,31,18,12],
[36,31,18,16],
[36,31,18,17],
[36,31,20,0],
[36,31,20,1],
[36,31,20,2],
[36,31,20,3],
[36,31,20,4],
[36,31,20,5],
[36,31,20,6],
[36,31,20,7],
[36,31,20,8],
[36,31,20,9],
[36,31,20,11],
[36,31,20,12],
[36,31,20,15],
[36,31,20,16],
[36,31,20,17],
[36,31,20,18],
[36,31,21,0],
[36,31,21,1],
[36,31,21,2],
[36,31,21,4],
[36,31,21,7],
[36,31,21,9],
[36,31,21,10],
[36,31,21,11],
[36,31,21,12],
[36,31,21,15],
[36,31,21,16],
[36,31,21,17],
[36,31,21,18],
[36,31,21,20],
[36,31,22,0],
[36,31,22,1],
[36,31,22,2],
[36,31,22,3],
[36,31,22,4],
[36,31,22,5],
[36,31,22,6],
[36,31,22,7],
[36,31,22,8],
[36,31,22,9],
[36,31,22,11],
[36,31,22,12],
[36,31,22,15],
[36,31,22,16],
[36,31,22,17],
[36,31,22,18],
[36,31,22,21],
[36,31,24,0],
[36,31,24,1],
[36,31,24,2],
[36,31,24,3],
[36,31,24,4],
[36,31,24,5],
[36,31,24,6],
[36,31,24,7],
[36,31,24,8],
[36,31,24,9],
[36,31,24,10],
[36,31,24,11],
[36,31,24,12],
[36,31,24,15],
[36,31,24,17],
[36,31,24,18],
[36,31,24,20],
[36,31,24,21],
[36,31,24,22],
[36,31,25,0],
[36,31,25,1],
[36,31,25,2],
[36,31,25,3],
[36,31,25,4],
[36,31,25,5],
[36,31,25,6],
[36,31,25,8],
[36,31,25,9],
[36,31,25,10],
[36,31,25,11],
[36,31,25,12],
[36,31,25,15],
[36,31,25,16],
[36,31,25,17],
[36,31,25,18],
[36,31,25,20],
[36,31,25,21],
[36,31,25,22],
[36,31,25,24],
[36,31,26,0],
[36,31,26,1],
[36,31,26,2],
[36,31,26,3],
[36,31,26,4],
[36,31,26,5],
[36,31,26,6],
[36,31,26,7],
[36,31,26,8],
[36,31,26,10],
[36,31,26,11],
[36,31,26,12],
[36,31,26,16],
[36,31,26,17],
[36,31,26,20],
[36,31,26,21],
[36,31,26,22],
[36,31,26,24],
[36,31,26,25],
[36,31,27,0],
[36,31,27,1],
[36,31,27,2],
[36,31,27,3],
[36,31,27,4],
[36,31,27,5],
[36,31,27,6],
[36,31,27,7],
[36,31,27,8],
[36,31,27,9],
[36,31,27,11],
[36,31,27,12],
[36,31,27,15],
[36,31,27,16],
[36,31,27,17],
[36,31,27,18],
[36,31,27,21],
[36,31,27,24],
[36,31,27,25],
[36,31,27,26],
[36,31,28,0],
[36,31,28,1],
[36,31,28,2],
[36,31,28,3],
[36,31,28,4],
[36,31,28,5],
[36,31,28,6],
[36,31,28,8],
[36,31,28,9],
[36,31,28,10],
[36,31,28,11],
[36,31,28,12],
[36,31,28,15],
[36,31,28,16],
[36,31,28,17],
[36,31,28,18],
[36,31,28,20],
[36,31,28,21],
[36,31,28,22],
[36,31,28,24],
[36,31,28,26],
[36,31,28,27],
[36,31,29,0],
[36,31,29,1],
[36,31,29,2],
[36,31,29,3],
[36,31,29,4],
[36,31,29,5],
[36,31,29,6],
[36,31,29,7],
[36,31,29,8],
[36,31,29,9],
[36,31,29,10],
[36,31,29,15],
[36,31,29,16],
[36,31,29,17],
[36,31,29,18],
[36,31,29,20],
[36,31,29,21],
[36,31,29,22],
[36,31,29,24],
[36,31,29,25],
[36,31,29,26],
[36,31,29,27],
[36,31,29,28],
[36,32,3,0],
[36,32,3,1],
[36,32,3,2],
[36,32,4,0],
[36,32,4,1],
[36,32,4,2],
[36,32,4,3],
[36,32,5,0],
[36,32,5,1],
[36,32,5,2],
[36,32,5,4],
[36,32,6,0],
[36,32,6,1],
[36,32,6,2],
[36,32,6,4],
[36,32,7,0],
[36,32,7,1],
[36,32,7,2],
[36,32,7,3],
[36,32,7,4],
[36,32,7,5],
[36,32,7,6],
[36,32,8,0],
[36,32,8,1],
[36,32,8,2],
[36,32,8,4],
[36,32,8,7],
[36,32,9,0],
[36,32,9,1],
[36,32,9,2],
[36,32,9,3],
[36,32,9,4],
[36,32,9,5],
[36,32,9,6],
[36,32,9,7],
[36,32,9,8],
[36,32,10,0],
[36,32,10,1],
[36,32,10,2],
[36,32,10,3],
[36,32,10,4],
[36,32,10,5],
[36,32,10,6],
[36,32,10,7],
[36,32,10,8],
[36,32,10,9],
[36,32,15,0],
[36,32,15,1],
[36,32,15,2],
[36,32,15,3],
[36,32,15,4],
[36,32,15,5],
[36,32,15,6],
[36,32,15,7],
[36,32,15,8],
[36,32,15,10],
[36,32,16,0],
[36,32,16,1],
[36,32,16,2],
[36,32,16,3],
[36,32,16,4],
[36,32,16,5],
[36,32,16,6],
[36,32,16,7],
[36,32,16,8],
[36,32,16,9],
[36,32,16,10],
[36,32,16,15],
[36,32,17,0],
[36,32,17,1],
[36,32,17,2],
[36,32,17,3],
[36,32,17,4],
[36,32,17,5],
[36,32,17,6],
[36,32,17,7],
[36,32,17,8],
[36,32,17,9],
[36,32,17,10],
[36,32,17,15],
[36,32,17,16],
[36,32,18,0],
[36,32,18,1],
[36,32,18,2],
[36,32,18,3],
[36,32,18,4],
[36,32,18,5],
[36,32,18,6],
[36,32,18,7],
[36,32,18,8],
[36,32,18,10],
[36,32,18,16],
[36,32,18,17],
[36,32,20,0],
[36,32,20,1],
[36,32,20,2],
[36,32,20,3],
[36,32,20,4],
[36,32,20,5],
[36,32,20,6],
[36,32,20,7],
[36,32,20,8],
[36,32,20,9],
[36,32,20,15],
[36,32,20,16],
[36,32,20,17],
[36,32,20,18],
[36,32,21,0],
[36,32,21,1],
[36,32,21,2],
[36,32,21,4],
[36,32,21,7],
[36,32,21,9],
[36,32,21,10],
[36,32,21,15],
[36,32,21,16],
[36,32,21,17],
[36,32,21,18],
[36,32,21,20],
[36,32,22,0],
[36,32,22,1],
[36,32,22,2],
[36,32,22,3],
[36,32,22,4],
[36,32,22,5],
[36,32,22,6],
[36,32,22,7],
[36,32,22,8],
[36,32,22,9],
[36,32,22,15],
[36,32,22,16],
[36,32,22,17],
[36,32,22,18],
[36,32,22,21],
[36,32,23,0],
[36,32,23,1],
[36,32,23,2],
[36,32,23,3],
[36,32,23,4],
[36,32,23,5],
[36,32,23,6],
[36,32,23,7],
[36,32,23,8],
[36,32,23,9],
[36,32,23,10],
[36,32,23,15],
[36,32,23,16],
[36,32,23,17],
[36,32,23,18],
[36,32,23,20],
[36,32,23,21],
[36,32,23,22],
[36,32,24,0],
[36,32,24,1],
[36,32,24,2],
[36,32,24,3],
[36,32,24,4],
[36,32,24,5],
[36,32,24,6],
[36,32,24,7],
[36,32,24,8],
[36,32,24,9],
[36,32,24,10],
[36,32,24,15],
[36,32,24,17],
[36,32,24,18],
[36,32,24,20],
[36,32,24,21],
[36,32,24,22],
[36,32,24,23],
[36,32,25,0],
[36,32,25,1],
[36,32,25,2],
[36,32,25,3],
[36,32,25,4],
[36,32,25,5],
[36,32,25,6],
[36,32,25,8],
[36,32,25,9],
[36,32,25,10],
[36,32,25,15],
[36,32,25,16],
[36,32,25,17],
[36,32,25,18],
[36,32,25,20],
[36,32,25,21],
[36,32,25,22],
[36,32,25,23],
[36,32,25,24],
[36,32,26,0],
[36,32,26,1],
[36,32,26,2],
[36,32,26,3],
[36,32,26,4],
[36,32,26,5],
[36,32,26,6],
[36,32,26,7],
[36,32,26,8],
[36,32,26,10],
[36,32,26,16],
[36,32,26,17],
[36,32,26,20],
[36,32,26,21],
[36,32,26,22],
[36,32,26,23],
[36,32,26,24],
[36,32,26,25],
[36,32,27,0],
[36,32,27,1],
[36,32,27,2],
[36,32,27,3],
[36,32,27,4],
[36,32,27,5],
[36,32,27,6],
[36,32,27,7],
[36,32,27,8],
[36,32,27,9],
[36,32,27,15],
[36,32,27,16],
[36,32,27,17],
[36,32,27,18],
[36,32,27,21],
[36,32,27,23],
[36,32,27,24],
[36,32,27,25],
[36,32,27,26],
[36,32,28,0],
[36,32,28,1],
[36,32,28,2],
[36,32,28,3],
[36,32,28,4],
[36,32,28,5],
[36,32,28,6],
[36,32,28,8],
[36,32,28,9],
[36,32,28,10],
[36,32,28,15],
[36,32,28,16],
[36,32,28,17],
[36,32,28,18],
[36,32,28,20],
[36,32,28,21],
[36,32,28,22],
[36,32,28,23],
[36,32,28,24],
[36,32,28,26],
[36,32,28,27],
[36,32,30,0],
[36,32,30,1],
[36,32,30,2],
[36,32,30,3],
[36,32,30,4],
[36,32,30,5],
[36,32,30,6],
[36,32,30,7],
[36,32,30,8],
[36,32,30,9],
[36,32,30,10],
[36,32,30,15],
[36,32,30,16],
[36,32,30,17],
[36,32,30,18],
[36,32,30,20],
[36,32,30,21],
[36,32,30,22],
[36,32,30,24],
[36,32,30,25],
[36,32,30,26],
[36,32,30,27],
[36,32,30,28],
[36,32,31,0],
[36,32,31,1],
[36,32,31,2],
[36,32,31,3],
[36,32,31,4],
[36,32,31,5],
[36,32,31,6],
[36,32,31,7],
[36,32,31,8],
[36,32,31,9],
[36,32,31,10],
[36,32,31,15],
[36,32,31,16],
[36,32,31,17],
[36,32,31,18],
[36,32,31,20],
[36,32,31,21],
[36,32,31,22],
[36,32,31,24],
[36,32,31,25],
[36,32,31,26],
[36,32,31,27],
[36,33,3,0],
[36,33,3,1],
[36,33,3,2],
[36,33,4,0],
[36,33,4,1],
[36,33,4,2],
[36,33,4,3],
[36,33,5,0],
[36,33,5,1],
[36,33,5,2],
[36,33,5,4],
[36,33,6,0],
[36,33,6,1],
[36,33,6,2],
[36,33,6,4],
[36,33,7,0],
[36,33,7,1],
[36,33,7,2],
[36,33,7,3],
[36,33,7,4],
[36,33,7,5],
[36,33,7,6],
[36,33,8,0],
[36,33,8,1],
[36,33,8,2],
[36,33,8,4],
[36,33,8,7],
[36,33,9,0],
[36,33,9,1],
[36,33,9,2],
[36,33,9,3],
[36,33,9,4],
[36,33,9,5],
[36,33,9,6],
[36,33,9,7],
[36,33,9,8],
[36,33,10,0],
[36,33,10,1],
[36,33,10,2],
[36,33,10,3],
[36,33,10,4],
[36,33,10,5],
[36,33,10,6],
[36,33,10,7],
[36,33,10,8],
[36,33,10,9],
[36,33,15,0],
[36,33,15,1],
[36,33,15,2],
[36,33,15,3],
[36,33,15,4],
[36,33,15,5],
[36,33,15,6],
[36,33,15,7],
[36,33,15,8],
[36,33,15,10],
[36,33,16,0],
[36,33,16,1],
[36,33,16,2],
[36,33,16,3],
[36,33,16,4],
[36,33,16,5],
[36,33,16,6],
[36,33,16,7],
[36,33,16,8],
[36,33,16,9],
[36,33,16,10],
[36,33,16,15],
[36,33,17,0],
[36,33,17,1],
[36,33,17,2],
[36,33,17,3],
[36,33,17,4],
[36,33,17,5],
[36,33,17,6],
[36,33,17,7],
[36,33,17,8],
[36,33,17,9],
[36,33,17,10],
[36,33,17,15],
[36,33,17,16],
[36,33,18,0],
[36,33,18,1],
[36,33,18,2],
[36,33,18,3],
[36,33,18,4],
[36,33,18,5],
[36,33,18,6],
[36,33,18,7],
[36,33,18,8],
[36,33,18,10],
[36,33,18,16],
[36,33,18,17],
[36,33,20,0],
[36,33,20,1],
[36,33,20,2],
[36,33,20,3],
[36,33,20,4],
[36,33,20,5],
[36,33,20,6],
[36,33,20,7],
[36,33,20,8],
[36,33,20,9],
[36,33,20,15],
[36,33,20,16],
[36,33,20,17],
[36,33,20,18],
[36,33,21,0],
[36,33,21,1],
[36,33,21,2],
[36,33,21,4],
[36,33,21,7],
[36,33,21,9],
[36,33,21,10],
[36,33,21,15],
[36,33,21,16],
[36,33,21,17],
[36,33,21,18],
[36,33,21,20],
[36,33,22,0],
[36,33,22,1],
[36,33,22,2],
[36,33,22,3],
[36,33,22,4],
[36,33,22,5],
[36,33,22,6],
[36,33,22,7],
[36,33,22,8],
[36,33,22,9],
[36,33,22,15],
[36,33,22,16],
[36,33,22,17],
[36,33,22,18],
[36,33,22,21],
[36,33,23,0],
[36,33,23,1],
[36,33,23,2],
[36,33,23,3],
[36,33,23,4],
[36,33,23,5],
[36,33,23,6],
[36,33,23,7],
[36,33,23,8],
[36,33,23,9],
[36,33,23,10],
[36,33,23,15],
[36,33,23,16],
[36,33,23,17],
[36,33,23,18],
[36,33,23,20],
[36,33,23,21],
[36,33,23,22],
[36,33,24,0],
[36,33,24,1],
[36,33,24,2],
[36,33,24,3],
[36,33,24,4],
[36,33,24,5],
[36,33,24,6],
[36,33,24,7],
[36,33,24,8],
[36,33,24,9],
[36,33,24,10],
[36,33,24,15],
[36,33,24,17],
[36,33,24,18],
[36,33,24,20],
[36,33,24,21],
[36,33,24,22],
[36,33,24,23],
[36,33,25,0],
[36,33,25,1],
[36,33,25,2],
[36,33,25,3],
[36,33,25,4],
[36,33,25,5],
[36,33,25,6],
[36,33,25,8],
[36,33,25,9],
[36,33,25,10],
[36,33,25,15],
[36,33,25,16],
[36,33,25,17],
[36,33,25,18],
[36,33,25,20],
[36,33,25,21],
[36,33,25,22],
[36,33,25,23],
[36,33,25,24],
[36,33,26,0],
[36,33,26,1],
[36,33,26,2],
[36,33,26,3],
[36,33,26,4],
[36,33,26,5],
[36,33,26,6],
[36,33,26,7],
[36,33,26,8],
[36,33,26,10],
[36,33,26,16],
[36,33,26,17],
[36,33,26,20],
[36,33,26,21],
[36,33,26,22],
[36,33,26,23],
[36,33,26,24],
[36,33,26,25],
[36,33,27,0],
[36,33,27,1],
[36,33,27,2],
[36,33,27,3],
[36,33,27,4],
[36,33,27,5],
[36,33,27,6],
[36,33,27,7],
[36,33,27,8],
[36,33,27,9],
[36,33,27,15],
[36,33,27,16],
[36,33,27,17],
[36,33,27,18],
[36,33,27,21],
[36,33,27,23],
[36,33,27,24],
[36,33,27,25],
[36,33,27,26],
[36,33,28,0],
[36,33,28,1],
[36,33,28,2],
[36,33,28,3],
[36,33,28,4],
[36,33,28,5],
[36,33,28,6],
[36,33,28,8],
[36,33,28,9],
[36,33,28,10],
[36,33,28,15],
[36,33,28,16],
[36,33,28,17],
[36,33,28,18],
[36,33,28,20],
[36,33,28,21],
[36,33,28,22],
[36,33,28,23],
[36,33,28,24],
[36,33,28,26],
[36,33,28,27],
[36,33,30,0],
[36,33,30,1],
[36,33,30,2],
[36,33,30,3],
[36,33,30,4],
[36,33,30,5],
[36,33,30,6],
[36,33,30,7],
[36,33,30,8],
[36,33,30,9],
[36,33,30,10],
[36,33,30,15],
[36,33,30,16],
[36,33,30,17],
[36,33,30,18],
[36,33,30,20],
[36,33,30,21],
[36,33,30,22],
[36,33,30,24],
[36,33,30,25],
[36,33,30,26],
[36,33,30,27],
[36,33,31,0],
[36,33,31,1],
[36,33,31,2],
[36,33,31,3],
[36,33,31,4],
[36,33,31,5],
[36,33,31,6],
[36,33,31,7],
[36,33,31,8],
[36,33,31,9],
[36,33,31,10],
[36,33,31,15],
[36,33,31,16],
[36,33,31,17],
[36,33,31,18],
[36,33,31,20],
[36,33,31,21],
[36,33,31,22],
[36,33,31,24],
[36,33,31,25],
[36,33,31,26],
[36,33,31,28],
[36,34,3,0],
[36,34,3,1],
[36,34,3,2],
[36,34,4,0],
[36,34,4,1],
[36,34,4,2],
[36,34,4,3],
[36,34,5,0],
[36,34,5,1],
[36,34,5,2],
[36,34,5,4],
[36,34,6,0],
[36,34,6,1],
[36,34,6,2],
[36,34,6,4],
[36,34,8,0],
[36,34,8,1],
[36,34,8,2],
[36,34,8,4],
[36,34,9,0],
[36,34,9,1],
[36,34,9,2],
[36,34,9,3],
[36,34,9,4],
[36,34,9,5],
[36,34,9,6],
[36,34,9,8],
[36,34,10,0],
[36,34,10,1],
[36,34,10,2],
[36,34,10,3],
[36,34,10,4],
[36,34,10,5],
[36,34,10,6],
[36,34,10,8],
[36,34,10,9],
[36,34,11,0],
[36,34,11,1],
[36,34,11,2],
[36,34,11,3],
[36,34,11,4],
[36,34,11,5],
[36,34,11,6],
[36,34,11,8],
[36,34,11,9],
[36,34,11,10],
[36,34,12,0],
[36,34,12,1],
[36,34,12,2],
[36,34,12,3],
[36,34,12,4],
[36,34,12,5],
[36,34,12,6],
[36,34,12,8],
[36,34,12,9],
[36,34,12,10],
[36,34,15,0],
[36,34,15,1],
[36,34,15,2],
[36,34,15,3],
[36,34,15,4],
[36,34,15,5],
[36,34,15,6],
[36,34,15,8],
[36,34,15,10],
[36,34,15,11],
[36,34,15,12],
[36,34,16,0],
[36,34,16,1],
[36,34,16,2],
[36,34,16,3],
[36,34,16,4],
[36,34,16,5],
[36,34,16,6],
[36,34,16,8],
[36,34,16,9],
[36,34,16,10],
[36,34,16,11],
[36,34,16,12],
[36,34,16,15],
[36,34,17,0],
[36,34,17,1],
[36,34,17,2],
[36,34,17,3],
[36,34,17,4],
[36,34,17,5],
[36,34,17,6],
[36,34,17,8],
[36,34,17,9],
[36,34,17,10],
[36,34,17,11],
[36,34,17,12],
[36,34,17,15],
[36,34,17,16],
[36,34,18,0],
[36,34,18,1],
[36,34,18,2],
[36,34,18,3],
[36,34,18,4],
[36,34,18,5],
[36,34,18,6],
[36,34,18,8],
[36,34,18,10],
[36,34,18,11],
[36,34,18,12],
[36,34,18,16],
[36,34,18,17],
[36,34,20,0],
[36,34,20,1],
[36,34,20,2],
[36,34,20,3],
[36,34,20,4],
[36,34,20,5],
[36,34,20,6],
[36,34,20,8],
[36,34,20,9],
[36,34,20,11],
[36,34,20,12],
[36,34,20,15],
[36,34,20,16],
[36,34,20,17],
[36,34,20,18],
[36,34,21,0],
[36,34,21,1],
[36,34,21,2],
[36,34,21,4],
[36,34,21,9],
[36,34,21,10],
[36,34,21,11],
[36,34,21,12],
[36,34,21,15],
[36,34,21,16],
[36,34,21,17],
[36,34,21,18],
[36,34,21,20],
[36,34,22,0],
[36,34,22,1],
[36,34,22,2],
[36,34,22,3],
[36,34,22,4],
[36,34,22,5],
[36,34,22,6],
[36,34,22,8],
[36,34,22,9],
[36,34,22,11],
[36,34,22,12],
[36,34,22,15],
[36,34,22,16],
[36,34,22,17],
[36,34,22,18],
[36,34,22,21],
[36,34,23,0],
[36,34,23,1],
[36,34,23,2],
[36,34,23,3],
[36,34,23,4],
[36,34,23,5],
[36,34,23,6],
[36,34,23,8],
[36,34,23,9],
[36,34,23,10],
[36,34,23,11],
[36,34,23,12],
[36,34,23,15],
[36,34,23,16],
[36,34,23,17],
[36,34,23,18],
[36,34,23,20],
[36,34,23,21],
[36,34,23,22],
[36,34,24,0],
[36,34,24,1],
[36,34,24,2],
[36,34,24,3],
[36,34,24,4],
[36,34,24,5],
[36,34,24,6],
[36,34,24,8],
[36,34,24,9],
[36,34,24,10],
[36,34,24,11],
[36,34,24,12],
[36,34,24,15],
[36,34,24,17],
[36,34,24,18],
[36,34,24,20],
[36,34,24,21],
[36,34,24,22],
[36,34,24,23],
[36,34,26,0],
[36,34,26,1],
[36,34,26,2],
[36,34,26,3],
[36,34,26,4],
[36,34,26,5],
[36,34,26,6],
[36,34,26,8],
[36,34,26,10],
[36,34,26,11],
[36,34,26,12],
[36,34,26,16],
[36,34,26,17],
[36,34,26,20],
[36,34,26,21],
[36,34,26,22],
[36,34,26,23],
[36,34,26,24],
[36,34,27,0],
[36,34,27,1],
[36,34,27,2],
[36,34,27,3],
[36,34,27,4],
[36,34,27,5],
[36,34,27,6],
[36,34,27,8],
[36,34,27,9],
[36,34,27,11],
[36,34,27,12],
[36,34,27,15],
[36,34,27,16],
[36,34,27,17],
[36,34,27,18],
[36,34,27,21],
[36,34,27,23],
[36,34,27,24],
[36,34,27,26],
[36,34,29,0],
[36,34,29,1],
[36,34,29,2],
[36,34,29,3],
[36,34,29,4],
[36,34,29,5],
[36,34,29,6],
[36,34,29,8],
[36,34,29,9],
[36,34,29,10],
[36,34,29,15],
[36,34,29,16],
[36,34,29,17],
[36,34,29,18],
[36,34,29,20],
[36,34,29,21],
[36,34,29,22],
[36,34,29,23],
[36,34,29,24],
[36,34,29,26],
[36,34,29,27],
[36,34,30,0],
[36,34,30,1],
[36,34,30,2],
[36,34,30,3],
[36,34,30,4],
[36,34,30,5],
[36,34,30,6],
[36,34,30,8],
[36,34,30,9],
[36,34,30,10],
[36,34,30,11],
[36,34,30,12],
[36,34,30,15],
[36,34,30,16],
[36,34,30,17],
[36,34,30,18],
[36,34,30,20],
[36,34,30,21],
[36,34,30,22],
[36,34,30,24],
[36,34,30,26],
[36,34,30,29],
[36,34,31,0],
[36,34,31,1],
[36,34,31,2],
[36,34,31,3],
[36,34,31,4],
[36,34,31,5],
[36,34,31,6],
[36,34,31,8],
[36,34,31,9],
[36,34,31,10],
[36,34,31,11],
[36,34,31,12],
[36,34,31,15],
[36,34,31,16],
[36,34,31,17],
[36,34,31,18],
[36,34,31,20],
[36,34,31,21],
[36,34,31,22],
[36,34,31,24],
[36,34,31,27],
[36,34,31,29],
[36,34,32,0],
[36,34,32,1],
[36,34,32,2],
[36,34,32,3],
[36,34,32,4],
[36,34,32,5],
[36,34,32,6],
[36,34,32,8],
[36,34,32,9],
[36,34,32,10],
[36,34,32,15],
[36,34,32,16],
[36,34,32,17],
[36,34,32,18],
[36,34,32,20],
[36,34,32,21],
[36,34,32,22],
[36,34,32,23],
[36,34,32,24],
[36,34,32,26],
[36,34,32,27],
[36,34,32,30],
[36,34,32,31],
[36,34,33,0],
[36,34,33,1],
[36,34,33,2],
[36,34,33,3],
[36,34,33,4],
[36,34,33,5],
[36,34,33,6],
[36,34,33,8],
[36,34,33,9],
[36,34,33,10],
[36,34,33,15],
[36,34,33,16],
[36,34,33,17],
[36,34,33,18],
[36,34,33,20],
[36,34,33,21],
[36,34,33,22],
[36,34,33,23],
[36,34,33,24],
[36,34,33,26],
[36,34,33,27],
[36,34,33,30],
[36,34,33,31],
[36,35,3,0],
[36,35,3,1],
[36,35,3,2],
[36,35,4,0],
[36,35,4,1],
[36,35,4,2],
[36,35,4,3],
[36,35,5,0],
[36,35,5,1],
[36,35,5,2],
[36,35,5,4],
[36,35,6,0],
[36,35,6,1],
[36,35,6,2],
[36,35,6,4],
[36,35,7,0],
[36,35,7,1],
[36,35,7,2],
[36,35,7,3],
[36,35,7,4],
[36,35,7,5],
[36,35,7,6],
[36,35,8,0],
[36,35,8,1],
[36,35,8,2],
[36,35,8,4],
[36,35,8,7],
[36,35,10,0],
[36,35,10,1],
[36,35,10,2],
[36,35,10,3],
[36,35,10,4],
[36,35,10,5],
[36,35,10,6],
[36,35,10,7],
[36,35,10,8],
[36,35,11,0],
[36,35,11,1],
[36,35,11,2],
[36,35,11,3],
[36,35,11,4],
[36,35,11,5],
[36,35,11,6],
[36,35,11,7],
[36,35,11,8],
[36,35,11,10],
[36,35,12,0],
[36,35,12,1],
[36,35,12,2],
[36,35,12,3],
[36,35,12,4],
[36,35,12,5],
[36,35,12,6],
[36,35,12,7],
[36,35,12,8],
[36,35,12,10],
[36,35,16,0],
[36,35,16,1],
[36,35,16,2],
[36,35,16,3],
[36,35,16,4],
[36,35,16,5],
[36,35,16,6],
[36,35,16,7],
[36,35,16,8],
[36,35,16,10],
[36,35,16,11],
[36,35,16,12],
[36,35,17,0],
[36,35,17,1],
[36,35,17,2],
[36,35,17,3],
[36,35,17,4],
[36,35,17,5],
[36,35,17,6],
[36,35,17,7],
[36,35,17,8],
[36,35,17,10],
[36,35,17,11],
[36,35,17,12],
[36,35,17,16],
[36,35,20,0],
[36,35,20,1],
[36,35,20,2],
[36,35,20,3],
[36,35,20,4],
[36,35,20,5],
[36,35,20,6],
[36,35,20,7],
[36,35,20,8],
[36,35,20,11],
[36,35,20,12],
[36,35,20,16],
[36,35,20,17],
[36,35,21,0],
[36,35,21,1],
[36,35,21,2],
[36,35,21,4],
[36,35,21,7],
[36,35,21,10],
[36,35,21,11],
[36,35,21,12],
[36,35,21,16],
[36,35,21,17],
[36,35,21,20],
[36,35,22,0],
[36,35,22,1],
[36,35,22,2],
[36,35,22,3],
[36,35,22,4],
[36,35,22,5],
[36,35,22,6],
[36,35,22,7],
[36,35,22,8],
[36,35,22,11],
[36,35,22,12],
[36,35,22,16],
[36,35,22,17],
[36,35,22,21],
[36,35,23,0],
[36,35,23,1],
[36,35,23,2],
[36,35,23,3],
[36,35,23,4],
[36,35,23,5],
[36,35,23,6],
[36,35,23,7],
[36,35,23,8],
[36,35,23,10],
[36,35,23,11],
[36,35,23,12],
[36,35,23,16],
[36,35,23,17],
[36,35,23,20],
[36,35,23,21],
[36,35,23,22],
[36,35,24,0],
[36,35,24,1],
[36,35,24,2],
[36,35,24,3],
[36,35,24,4],
[36,35,24,5],
[36,35,24,6],
[36,35,24,7],
[36,35,24,8],
[36,35,24,10],
[36,35,24,11],
[36,35,24,12],
[36,35,24,17],
[36,35,24,20],
[36,35,24,21],
[36,35,24,22],
[36,35,24,23],
[36,35,25,0],
[36,35,25,1],
[36,35,25,2],
[36,35,25,3],
[36,35,25,4],
[36,35,25,5],
[36,35,25,6],
[36,35,25,8],
[36,35,25,10],
[36,35,25,11],
[36,35,25,12],
[36,35,25,16],
[36,35,25,17],
[36,35,25,20],
[36,35,25,21],
[36,35,25,22],
[36,35,25,23],
[36,35,25,24],
[36,35,27,0],
[36,35,27,1],
[36,35,27,2],
[36,35,27,3],
[36,35,27,4],
[36,35,27,5],
[36,35,27,6],
[36,35,27,7],
[36,35,27,8],
[36,35,27,11],
[36,35,27,12],
[36,35,27,16],
[36,35,27,17],
[36,35,27,21],
[36,35,27,23],
[36,35,27,24],
[36,35,27,25],
[36,35,28,0],
[36,35,28,1],
[36,35,28,2],
[36,35,28,3],
[36,35,28,4],
[36,35,28,5],
[36,35,28,6],
[36,35,28,8],
[36,35,28,10],
[36,35,28,11],
[36,35,28,12],
[36,35,28,16],
[36,35,28,17],
[36,35,28,20],
[36,35,28,21],
[36,35,28,22],
[36,35,28,23],
[36,35,28,24],
[36,35,28,27],
[36,35,29,0],
[36,35,29,1],
[36,35,29,2],
[36,35,29,3],
[36,35,29,4],
[36,35,29,5],
[36,35,29,6],
[36,35,29,7],
[36,35,29,8],
[36,35,29,10],
[36,35,29,16],
[36,35,29,17],
[36,35,29,20],
[36,35,29,21],
[36,35,29,22],
[36,35,29,23],
[36,35,29,24],
[36,35,29,25],
[36,35,29,28],
[36,35,30,0],
[36,35,30,1],
[36,35,30,2],
[36,35,30,3],
[36,35,30,4],
[36,35,30,5],
[36,35,30,6],
[36,35,30,7],
[36,35,30,8],
[36,35,30,10],
[36,35,30,11],
[36,35,30,12],
[36,35,30,16],
[36,35,30,17],
[36,35,30,20],
[36,35,30,21],
[36,35,30,22],
[36,35,30,24],
[36,35,30,25],
[36,35,30,27],
[36,35,30,28],
[36,35,30,29],
[36,35,31,0],
[36,35,31,1],
[36,35,31,2],
[36,35,31,3],
[36,35,31,4],
[36,35,31,5],
[36,35,31,6],
[36,35,31,7],
[36,35,31,8],
[36,35,31,10],
[36,35,31,11],
[36,35,31,12],
[36,35,31,16],
[36,35,31,17],
[36,35,31,20],
[36,35,31,21],
[36,35,31,22],
[36,35,31,24],
[36,35,31,27],
[36,35,31,28],
[36,35,31,29],
[36,35,32,0],
[36,35,32,1],
[36,35,32,2],
[36,35,32,3],
[36,35,32,4],
[36,35,32,5],
[36,35,32,6],
[36,35,32,7],
[36,35,32,8],
[36,35,32,10],
[36,35,32,16],
[36,35,32,17],
[36,35,32,20],
[36,35,32,21],
[36,35,32,22],
[36,35,32,23],
[36,35,32,24],
[36,35,32,25],
[36,35,32,27],
[36,35,32,28],
[36,35,32,30],
[36,35,32,31],
[36,35,33,0],
[36,35,33,1],
[36,35,33,2],
[36,35,33,3],
[36,35,33,4],
[36,35,33,5],
[36,35,33,6],
[36,35,33,7],
[36,35,33,8],
[36,35,33,10],
[36,35,33,16],
[36,35,33,17],
[36,35,33,20],
[36,35,33,21],
[36,35,33,22],
[36,35,33,25],
[36,35,33,27],
[36,35,33,28],
[36,35,33,30],
[36,35,33,31],
[36,35,34,0],
[36,35,34,1],
[36,35,34,2],
[36,35,34,3],
[36,35,34,4],
[36,35,34,5],
[36,35,34,6],
[36,35,34,8],
[36,35,34,10],
[36,35,34,11],
[36,35,34,12],
[36,35,34,16],
[36,35,34,17],
[36,35,34,20],
[36,35,34,21],
[36,35,34,23],
[36,35,34,24],
[36,35,34,27],
[36,35,34,29],
[36,35,34,30],
[36,35,34,31],
[36,35,34,32],
[36,35,34,33],
[37,7,3,0],
[37,7,3,1],
[37,7,3,2],
[37,7,5,0],
[37,7,5,1],
[37,7,5,2],
[37,7,6,0],
[37,7,6,1],
[37,7,6,2],
[37,8,7,0],
[37,8,7,1],
[37,8,7,2],
[37,9,3,0],
[37,9,3,1],
[37,9,3,2],
[37,9,5,0],
[37,9,5,1],
[37,9,5,2],
[37,9,6,0],
[37,9,6,1],
[37,9,6,2],
[37,9,7,0],
[37,9,7,1],
[37,9,7,2],
[37,9,7,3],
[37,9,7,5],
[37,9,7,6],
[37,9,8,0],
[37,9,8,1],
[37,9,8,2],
[37,9,8,7],
[37,10,3,0],
[37,10,3,1],
[37,10,3,2],
[37,10,5,0],
[37,10,5,1],
[37,10,5,2],
[37,10,6,0],
[37,10,6,1],
[37,10,6,2],
[37,10,7,0],
[37,10,7,1],
[37,10,7,2],
[37,10,7,3],
[37,10,7,5],
[37,10,7,6],
[37,10,8,0],
[37,10,8,1],
[37,10,8,2],
[37,10,8,7],
[37,10,9,0],
[37,10,9,1],
[37,10,9,2],
[37,10,9,3],
[37,10,9,5],
[37,10,9,6],
[37,10,9,7],
[37,10,9,8],
[37,11,3,0],
[37,11,3,1],
[37,11,3,2],
[37,11,5,0],
[37,11,5,1],
[37,11,5,2],
[37,11,6,0],
[37,11,6,1],
[37,11,6,2],
[37,11,7,0],
[37,11,7,1],
[37,11,7,2],
[37,11,7,3],
[37,11,7,5],
[37,11,7,6],
[37,11,8,0],
[37,11,8,1],
[37,11,8,2],
[37,11,8,7],
[37,11,9,0],
[37,11,9,1],
[37,11,9,2],
[37,11,9,3],
[37,11,9,5],
[37,11,9,6],
[37,11,9,7],
[37,11,9,8],
[37,11,10,0],
[37,11,10,1],
[37,11,10,2],
[37,11,10,3],
[37,11,10,5],
[37,11,10,6],
[37,11,10,7],
[37,11,10,8],
[37,11,10,9],
[37,12,3,0],
[37,12,3,1],
[37,12,3,2],
[37,12,5,0],
[37,12,5,1],
[37,12,5,2],
[37,12,6,0],
[37,12,6,1],
[37,12,6,2],
[37,12,7,0],
[37,12,7,1],
[37,12,7,2],
[37,12,7,3],
[37,12,7,5],
[37,12,7,6],
[37,12,8,0],
[37,12,8,1],
[37,12,8,2],
[37,12,8,7],
[37,12,9,0],
[37,12,9,1],
[37,12,9,2],
[37,12,9,3],
[37,12,9,5],
[37,12,9,6],
[37,12,9,7],
[37,12,9,8],
[37,12,10,0],
[37,12,10,1],
[37,12,10,2],
[37,12,10,3],
[37,12,10,5],
[37,12,10,6],
[37,12,10,7],
[37,12,10,8],
[37,12,10,9],
[37,13,3,0],
[37,13,3,1],
[37,13,3,2],
[37,13,5,0],
[37,13,5,1],
[37,13,5,2],
[37,13,6,0],
[37,13,6,1],
[37,13,6,2],
[37,13,7,0],
[37,13,7,1],
[37,13,7,2],
[37,13,7,3],
[37,13,7,5],
[37,13,7,6],
[37,13,8,0],
[37,13,8,1],
[37,13,8,2],
[37,13,8,7],
[37,13,9,0],
[37,13,9,1],
[37,13,9,2],
[37,13,9,3],
[37,13,9,5],
[37,13,9,6],
[37,13,9,7],
[37,13,9,8],
[37,13,10,0],
[37,13,10,1],
[37,13,10,2],
[37,13,10,3],
[37,13,10,5],
[37,13,10,6],
[37,13,10,7],
[37,13,10,8],
[37,13,10,9],
[37,13,11,0],
[37,13,11,1],
[37,13,11,2],
[37,13,11,3],
[37,13,11,5],
[37,13,11,6],
[37,13,11,7],
[37,13,11,8],
[37,13,11,9],
[37,13,11,10],
[37,13,12,0],
[37,13,12,1],
[37,13,12,2],
[37,13,12,3],
[37,13,12,5],
[37,13,12,6],
[37,13,12,7],
[37,13,12,8],
[37,13,12,9],
[37,13,12,10],
[37,14,3,0],
[37,14,3,1],
[37,14,3,2],
[37,14,5,0],
[37,14,5,1],
[37,14,5,2],
[37,14,6,0],
[37,14,6,1],
[37,14,6,2],
[37,14,7,0],
[37,14,7,1],
[37,14,7,2],
[37,14,7,3],
[37,14,7,5],
[37,14,7,6],
[37,14,8,0],
[37,14,8,1],
[37,14,8,2],
[37,14,8,7],
[37,14,9,0],
[37,14,9,1],
[37,14,9,2],
[37,14,9,3],
[37,14,9,5],
[37,14,9,6],
[37,14,9,7],
[37,14,9,8],
[37,14,10,0],
[37,14,10,1],
[37,14,10,2],
[37,14,10,3],
[37,14,10,5],
[37,14,10,6],
[37,14,10,7],
[37,14,10,8],
[37,14,10,9],
[37,14,11,0],
[37,14,11,1],
[37,14,11,2],
[37,14,11,3],
[37,14,11,5],
[37,14,11,6],
[37,14,11,7],
[37,14,11,8],
[37,14,11,9],
[37,14,11,10],
[37,14,12,0],
[37,14,12,1],
[37,14,12,2],
[37,14,12,3],
[37,14,12,5],
[37,14,12,6],
[37,14,12,7],
[37,14,12,8],
[37,14,12,9],
[37,14,12,10],
[37,15,3,0],
[37,15,3,1],
[37,15,3,2],
[37,15,5,0],
[37,15,5,1],
[37,15,5,2],
[37,15,6,0],
[37,15,6,1],
[37,15,6,2],
[37,15,7,0],
[37,15,7,1],
[37,15,7,2],
[37,15,7,3],
[37,15,7,5],
[37,15,7,6],
[37,15,8,0],
[37,15,8,1],
[37,15,8,2],
[37,15,8,7],
[37,15,10,0],
[37,15,10,1],
[37,15,10,2],
[37,15,10,3],
[37,15,10,5],
[37,15,10,6],
[37,15,10,7],
[37,15,10,8],
[37,15,11,0],
[37,15,11,1],
[37,15,11,2],
[37,15,11,3],
[37,15,11,5],
[37,15,11,6],
[37,15,11,7],
[37,15,11,8],
[37,15,11,10],
[37,15,12,0],
[37,15,12,1],
[37,15,12,2],
[37,15,12,3],
[37,15,12,5],
[37,15,12,6],
[37,15,12,7],
[37,15,12,8],
[37,15,12,10],
[37,15,13,0],
[37,15,13,1],
[37,15,13,2],
[37,15,13,3],
[37,15,13,5],
[37,15,13,6],
[37,15,13,7],
[37,15,13,8],
[37,15,13,10],
[37,15,13,11],
[37,15,13,12],
[37,15,14,0],
[37,15,14,1],
[37,15,14,2],
[37,15,14,3],
[37,15,14,5],
[37,15,14,6],
[37,15,14,7],
[37,15,14,8],
[37,15,14,10],
[37,15,14,11],
[37,15,14,12],
[37,16,3,0],
[37,16,3,1],
[37,16,3,2],
[37,16,5,0],
[37,16,5,1],
[37,16,5,2],
[37,16,6,0],
[37,16,6,1],
[37,16,6,2],
[37,16,7,0],
[37,16,7,1],
[37,16,7,2],
[37,16,7,3],
[37,16,7,5],
[37,16,7,6],
[37,16,8,0],
[37,16,8,1],
[37,16,8,2],
[37,16,8,7],
[37,16,9,0],
[37,16,9,1],
[37,16,9,2],
[37,16,9,3],
[37,16,9,5],
[37,16,9,6],
[37,16,9,7],
[37,16,9,8],
[37,16,10,0],
[37,16,10,1],
[37,16,10,2],
[37,16,10,3],
[37,16,10,5],
[37,16,10,6],
[37,16,10,7],
[37,16,10,8],
[37,16,10,9],
[37,16,11,0],
[37,16,11,1],
[37,16,11,2],
[37,16,11,3],
[37,16,11,5],
[37,16,11,6],
[37,16,11,7],
[37,16,11,8],
[37,16,11,9],
[37,16,11,10],
[37,16,12,0],
[37,16,12,1],
[37,16,12,2],
[37,16,12,3],
[37,16,12,5],
[37,16,12,6],
[37,16,12,7],
[37,16,12,8],
[37,16,12,9],
[37,16,12,10],
[37,16,13,0],
[37,16,13,1],
[37,16,13,2],
[37,16,13,3],
[37,16,13,5],
[37,16,13,6],
[37,16,13,7],
[37,16,13,8],
[37,16,13,9],
[37,16,13,10],
[37,16,13,11],
[37,16,13,12],
[37,16,14,0],
[37,16,14,1],
[37,16,14,2],
[37,16,14,3],
[37,16,14,5],
[37,16,14,6],
[37,16,14,7],
[37,16,14,8],
[37,16,14,9],
[37,16,14,10],
[37,16,14,11],
[37,16,14,12],
[37,16,15,0],
[37,16,15,1],
[37,16,15,2],
[37,16,15,3],
[37,16,15,5],
[37,16,15,6],
[37,16,15,7],
[37,16,15,8],
[37,16,15,10],
[37,16,15,11],
[37,16,15,12],
[37,16,15,13],
[37,16,15,14],
[37,17,3,0],
[37,17,3,1],
[37,17,3,2],
[37,17,5,0],
[37,17,5,1],
[37,17,5,2],
[37,17,6,0],
[37,17,6,1],
[37,17,6,2],
[37,17,7,0],
[37,17,7,1],
[37,17,7,2],
[37,17,7,3],
[37,17,7,5],
[37,17,7,6],
[37,17,8,0],
[37,17,8,1],
[37,17,8,2],
[37,17,8,7],
[37,17,9,0],
[37,17,9,1],
[37,17,9,2],
[37,17,9,3],
[37,17,9,5],
[37,17,9,6],
[37,17,9,7],
[37,17,9,8],
[37,17,10,0],
[37,17,10,1],
[37,17,10,2],
[37,17,10,3],
[37,17,10,5],
[37,17,10,6],
[37,17,10,7],
[37,17,10,8],
[37,17,10,9],
[37,17,11,0],
[37,17,11,1],
[37,17,11,2],
[37,17,11,3],
[37,17,11,5],
[37,17,11,6],
[37,17,11,7],
[37,17,11,8],
[37,17,11,9],
[37,17,11,10],
[37,17,12,0],
[37,17,12,1],
[37,17,12,2],
[37,17,12,3],
[37,17,12,5],
[37,17,12,6],
[37,17,12,7],
[37,17,12,8],
[37,17,12,9],
[37,17,12,10],
[37,17,13,0],
[37,17,13,1],
[37,17,13,2],
[37,17,13,3],
[37,17,13,5],
[37,17,13,6],
[37,17,13,7],
[37,17,13,8],
[37,17,13,9],
[37,17,13,10],
[37,17,13,11],
[37,17,13,12],
[37,17,14,0],
[37,17,14,1],
[37,17,14,2],
[37,17,14,3],
[37,17,14,5],
[37,17,14,6],
[37,17,14,7],
[37,17,14,8],
[37,17,14,9],
[37,17,14,10],
[37,17,14,11],
[37,17,14,12],
[37,17,15,0],
[37,17,15,1],
[37,17,15,2],
[37,17,15,3],
[37,17,15,5],
[37,17,15,6],
[37,17,15,7],
[37,17,15,8],
[37,17,15,10],
[37,17,15,11],
[37,17,15,12],
[37,17,15,13],
[37,17,15,14],
[37,17,16,0],
[37,17,16,1],
[37,17,16,2],
[37,17,16,3],
[37,17,16,5],
[37,17,16,6],
[37,17,16,7],
[37,17,16,8],
[37,17,16,9],
[37,17,16,10],
[37,17,16,11],
[37,17,16,12],
[37,17,16,13],
[37,17,16,14],
[37,17,16,15],
[37,18,3,0],
[37,18,3,1],
[37,18,3,2],
[37,18,5,0],
[37,18,5,1],
[37,18,5,2],
[37,18,6,0],
[37,18,6,1],
[37,18,6,2],
[37,18,7,0],
[37,18,7,1],
[37,18,7,2],
[37,18,7,3],
[37,18,7,5],
[37,18,7,6],
[37,18,8,0],
[37,18,8,1],
[37,18,8,2],
[37,18,8,7],
[37,18,10,0],
[37,18,10,1],
[37,18,10,2],
[37,18,10,3],
[37,18,10,5],
[37,18,10,6],
[37,18,10,7],
[37,18,10,8],
[37,18,11,0],
[37,18,11,1],
[37,18,11,2],
[37,18,11,3],
[37,18,11,5],
[37,18,11,6],
[37,18,11,7],
[37,18,11,8],
[37,18,11,10],
[37,18,12,0],
[37,18,12,1],
[37,18,12,2],
[37,18,12,3],
[37,18,12,5],
[37,18,12,6],
[37,18,12,7],
[37,18,12,8],
[37,18,12,10],
[37,18,13,0],
[37,18,13,1],
[37,18,13,2],
[37,18,13,3],
[37,18,13,5],
[37,18,13,6],
[37,18,13,7],
[37,18,13,8],
[37,18,13,10],
[37,18,13,11],
[37,18,13,12],
[37,18,14,0],
[37,18,14,1],
[37,18,14,2],
[37,18,14,3],
[37,18,14,5],
[37,18,14,6],
[37,18,14,7],
[37,18,14,8],
[37,18,14,10],
[37,18,14,11],
[37,18,14,12],
[37,18,16,0],
[37,18,16,1],
[37,18,16,2],
[37,18,16,3],
[37,18,16,5],
[37,18,16,6],
[37,18,16,7],
[37,18,16,8],
[37,18,16,10],
[37,18,16,11],
[37,18,16,12],
[37,18,16,13],
[37,18,16,14],
[37,18,17,0],
[37,18,17,1],
[37,18,17,2],
[37,18,17,3],
[37,18,17,5],
[37,18,17,6],
[37,18,17,7],
[37,18,17,8],
[37,18,17,10],
[37,18,17,11],
[37,18,17,12],
[37,18,17,13],
[37,18,17,14],
[37,18,17,16],
[37,19,3,0],
[37,19,3,1],
[37,19,3,2],
[37,19,5,0],
[37,19,5,1],
[37,19,5,2],
[37,19,6,0],
[37,19,6,1],
[37,19,6,2],
[37,19,7,0],
[37,19,7,1],
[37,19,7,2],
[37,19,7,3],
[37,19,7,5],
[37,19,7,6],
[37,19,8,0],
[37,19,8,1],
[37,19,8,2],
[37,19,8,7],
[37,19,9,0],
[37,19,9,1],
[37,19,9,2],
[37,19,9,3],
[37,19,9,5],
[37,19,9,6],
[37,19,9,7],
[37,19,9,8],
[37,19,10,0],
[37,19,10,1],
[37,19,10,2],
[37,19,10,3],
[37,19,10,5],
[37,19,10,6],
[37,19,10,7],
[37,19,10,8],
[37,19,10,9],
[37,19,11,0],
[37,19,11,1],
[37,19,11,2],
[37,19,11,3],
[37,19,11,5],
[37,19,11,6],
[37,19,11,7],
[37,19,11,8],
[37,19,11,9],
[37,19,11,10],
[37,19,12,0],
[37,19,12,1],
[37,19,12,2],
[37,19,12,3],
[37,19,12,5],
[37,19,12,6],
[37,19,12,7],
[37,19,12,8],
[37,19,12,9],
[37,19,12,10],
[37,19,15,0],
[37,19,15,1],
[37,19,15,2],
[37,19,15,3],
[37,19,15,5],
[37,19,15,6],
[37,19,15,7],
[37,19,15,8],
[37,19,15,10],
[37,19,15,11],
[37,19,15,12],
[37,19,16,0],
[37,19,16,1],
[37,19,16,2],
[37,19,16,3],
[37,19,16,5],
[37,19,16,6],
[37,19,16,7],
[37,19,16,8],
[37,19,16,9],
[37,19,16,10],
[37,19,16,11],
[37,19,16,12],
[37,19,16,15],
[37,19,17,0],
[37,19,17,1],
[37,19,17,2],
[37,19,17,3],
[37,19,17,5],
[37,19,17,6],
[37,19,17,7],
[37,19,17,8],
[37,19,17,9],
[37,19,17,10],
[37,19,17,11],
[37,19,17,12],
[37,19,17,15],
[37,19,17,16],
[37,19,18,0],
[37,19,18,1],
[37,19,18,2],
[37,19,18,3],
[37,19,18,5],
[37,19,18,6],
[37,19,18,7],
[37,19,18,8],
[37,19,18,10],
[37,19,18,11],
[37,19,18,12],
[37,19,18,16],
[37,19,18,17],
[37,20,3,0],
[37,20,3,1],
[37,20,3,2],
[37,20,5,0],
[37,20,5,1],
[37,20,5,2],
[37,20,6,0],
[37,20,6,1],
[37,20,6,2],
[37,20,7,0],
[37,20,7,1],
[37,20,7,2],
[37,20,7,3],
[37,20,7,5],
[37,20,7,6],
[37,20,8,0],
[37,20,8,1],
[37,20,8,2],
[37,20,8,7],
[37,20,9,0],
[37,20,9,1],
[37,20,9,2],
[37,20,9,3],
[37,20,9,5],
[37,20,9,6],
[37,20,9,7],
[37,20,9,8],
[37,20,11,0],
[37,20,11,1],
[37,20,11,2],
[37,20,11,3],
[37,20,11,5],
[37,20,11,6],
[37,20,11,7],
[37,20,11,8],
[37,20,11,9],
[37,20,12,0],
[37,20,12,1],
[37,20,12,2],
[37,20,12,3],
[37,20,12,5],
[37,20,12,6],
[37,20,12,7],
[37,20,12,8],
[37,20,12,9],
[37,20,13,0],
[37,20,13,1],
[37,20,13,2],
[37,20,13,3],
[37,20,13,5],
[37,20,13,6],
[37,20,13,7],
[37,20,13,8],
[37,20,13,9],
[37,20,13,11],
[37,20,13,12],
[37,20,14,0],
[37,20,14,1],
[37,20,14,2],
[37,20,14,3],
[37,20,14,5],
[37,20,14,6],
[37,20,14,7],
[37,20,14,8],
[37,20,14,9],
[37,20,14,11],
[37,20,14,12],
[37,20,15,0],
[37,20,15,1],
[37,20,15,2],
[37,20,15,3],
[37,20,15,5],
[37,20,15,6],
[37,20,15,7],
[37,20,15,8],
[37,20,15,11],
[37,20,15,12],
[37,20,15,13],
[37,20,15,14],
[37,20,16,0],
[37,20,16,1],
[37,20,16,2],
[37,20,16,3],
[37,20,16,5],
[37,20,16,6],
[37,20,16,7],
[37,20,16,8],
[37,20,16,9],
[37,20,16,11],
[37,20,16,12],
[37,20,16,13],
[37,20,16,14],
[37,20,16,15],
[37,20,17,0],
[37,20,17,1],
[37,20,17,2],
[37,20,17,3],
[37,20,17,5],
[37,20,17,6],
[37,20,17,7],
[37,20,17,8],
[37,20,17,9],
[37,20,17,11],
[37,20,17,12],
[37,20,17,13],
[37,20,17,14],
[37,20,17,15],
[37,20,17,16],
[37,20,18,0],
[37,20,18,1],
[37,20,18,2],
[37,20,18,3],
[37,20,18,5],
[37,20,18,6],
[37,20,18,7],
[37,20,18,8],
[37,20,18,11],
[37,20,18,12],
[37,20,18,13],
[37,20,18,14],
[37,20,18,16],
[37,20,18,17],
[37,20,19,0],
[37,20,19,1],
[37,20,19,2],
[37,20,19,3],
[37,20,19,5],
[37,20,19,6],
[37,20,19,7],
[37,20,19,8],
[37,20,19,9],
[37,20,19,11],
[37,20,19,12],
[37,20,19,15],
[37,20,19,16],
[37,20,19,17],
[37,20,19,18],
[37,21,7,0],
[37,21,7,1],
[37,21,7,2],
[37,21,9,0],
[37,21,9,1],
[37,21,9,2],
[37,21,9,7],
[37,21,10,0],
[37,21,10,1],
[37,21,10,2],
[37,21,10,7],
[37,21,10,9],
[37,21,11,0],
[37,21,11,1],
[37,21,11,2],
[37,21,11,7],
[37,21,11,9],
[37,21,11,10],
[37,21,12,0],
[37,21,12,1],
[37,21,12,2],
[37,21,12,7],
[37,21,12,9],
[37,21,12,10],
[37,21,13,0],
[37,21,13,1],
[37,21,13,2],
[37,21,13,7],
[37,21,13,9],
[37,21,13,10],
[37,21,13,11],
[37,21,13,12],
[37,21,14,0],
[37,21,14,1],
[37,21,14,2],
[37,21,14,7],
[37,21,14,9],
[37,21,14,10],
[37,21,14,11],
[37,21,14,12],
[37,21,15,0],
[37,21,15,1],
[37,21,15,2],
[37,21,15,7],
[37,21,15,10],
[37,21,15,11],
[37,21,15,12],
[37,21,15,13],
[37,21,15,14],
[37,21,16,0],
[37,21,16,1],
[37,21,16,2],
[37,21,16,7],
[37,21,16,9],
[37,21,16,10],
[37,21,16,11],
[37,21,16,12],
[37,21,16,13],
[37,21,16,14],
[37,21,16,15],
[37,21,17,0],
[37,21,17,1],
[37,21,17,2],
[37,21,17,7],
[37,21,17,9],
[37,21,17,10],
[37,21,17,11],
[37,21,17,12],
[37,21,17,13],
[37,21,17,14],
[37,21,17,15],
[37,21,17,16],
[37,21,18,0],
[37,21,18,1],
[37,21,18,2],
[37,21,18,7],
[37,21,18,10],
[37,21,18,11],
[37,21,18,12],
[37,21,18,13],
[37,21,18,14],
[37,21,18,16],
[37,21,18,17],
[37,21,19,0],
[37,21,19,1],
[37,21,19,2],
[37,21,19,7],
[37,21,19,9],
[37,21,19,10],
[37,21,19,11],
[37,21,19,12],
[37,21,19,15],
[37,21,19,16],
[37,21,19,17],
[37,21,19,18],
[37,21,20,0],
[37,21,20,1],
[37,21,20,2],
[37,21,20,7],
[37,21,20,9],
[37,21,20,11],
[37,21,20,12],
[37,21,20,13],
[37,21,20,14],
[37,21,20,15],
[37,21,20,16],
[37,21,20,17],
[37,21,20,18],
[37,21,20,19],
[37,22,3,0],
[37,22,3,1],
[37,22,3,2],
[37,22,5,0],
[37,22,5,1],
[37,22,5,2],
[37,22,6,0],
[37,22,6,1],
[37,22,6,2],
[37,22,7,0],
[37,22,7,1],
[37,22,7,2],
[37,22,7,3],
[37,22,7,5],
[37,22,7,6],
[37,22,8,0],
[37,22,8,1],
[37,22,8,2],
[37,22,8,7],
[37,22,9,0],
[37,22,9,1],
[37,22,9,2],
[37,22,9,3],
[37,22,9,5],
[37,22,9,6],
[37,22,9,7],
[37,22,9,8],
[37,22,11,0],
[37,22,11,1],
[37,22,11,2],
[37,22,11,3],
[37,22,11,5],
[37,22,11,6],
[37,22,11,7],
[37,22,11,8],
[37,22,11,9],
[37,22,12,0],
[37,22,12,1],
[37,22,12,2],
[37,22,12,3],
[37,22,12,5],
[37,22,12,6],
[37,22,12,7],
[37,22,12,8],
[37,22,12,9],
[37,22,13,0],
[37,22,13,1],
[37,22,13,2],
[37,22,13,3],
[37,22,13,5],
[37,22,13,6],
[37,22,13,7],
[37,22,13,8],
[37,22,13,9],
[37,22,13,11],
[37,22,13,12],
[37,22,14,0],
[37,22,14,1],
[37,22,14,2],
[37,22,14,3],
[37,22,14,5],
[37,22,14,6],
[37,22,14,7],
[37,22,14,8],
[37,22,14,9],
[37,22,14,11],
[37,22,14,12],
[37,22,15,0],
[37,22,15,1],
[37,22,15,2],
[37,22,15,3],
[37,22,15,5],
[37,22,15,6],
[37,22,15,7],
[37,22,15,8],
[37,22,15,11],
[37,22,15,12],
[37,22,15,13],
[37,22,15,14],
[37,22,16,0],
[37,22,16,1],
[37,22,16,2],
[37,22,16,3],
[37,22,16,5],
[37,22,16,6],
[37,22,16,7],
[37,22,16,8],
[37,22,16,9],
[37,22,16,11],
[37,22,16,12],
[37,22,16,13],
[37,22,16,14],
[37,22,16,15],
[37,22,17,0],
[37,22,17,1],
[37,22,17,2],
[37,22,17,3],
[37,22,17,5],
[37,22,17,6],
[37,22,17,7],
[37,22,17,8],
[37,22,17,9],
[37,22,17,11],
[37,22,17,12],
[37,22,17,13],
[37,22,17,14],
[37,22,17,15],
[37,22,17,16],
[37,22,18,0],
[37,22,18,1],
[37,22,18,2],
[37,22,18,3],
[37,22,18,5],
[37,22,18,6],
[37,22,18,7],
[37,22,18,8],
[37,22,18,11],
[37,22,18,12],
[37,22,18,13],
[37,22,18,14],
[37,22,18,16],
[37,22,18,17],
[37,22,19,0],
[37,22,19,1],
[37,22,19,2],
[37,22,19,3],
[37,22,19,5],
[37,22,19,6],
[37,22,19,7],
[37,22,19,8],
[37,22,19,9],
[37,22,19,11],
[37,22,19,12],
[37,22,19,15],
[37,22,19,16],
[37,22,19,17],
[37,22,19,18],
[37,22,21,0],
[37,22,21,1],
[37,22,21,2],
[37,22,21,7],
[37,22,21,9],
[37,22,21,11],
[37,22,21,12],
[37,22,21,13],
[37,22,21,14],
[37,22,21,15],
[37,22,21,16],
[37,22,21,17],
[37,22,21,18],
[37,22,21,19],
[37,23,3,0],
[37,23,3,1],
[37,23,3,2],
[37,23,5,0],
[37,23,5,1],
[37,23,5,2],
[37,23,6,0],
[37,23,6,1],
[37,23,6,2],
[37,23,7,0],
[37,23,7,1],
[37,23,7,2],
[37,23,7,3],
[37,23,7,5],
[37,23,7,6],
[37,23,8,0],
[37,23,8,1],
[37,23,8,2],
[37,23,8,7],
[37,23,9,0],
[37,23,9,1],
[37,23,9,2],
[37,23,9,3],
[37,23,9,5],
[37,23,9,6],
[37,23,9,7],
[37,23,9,8],
[37,23,10,0],
[37,23,10,1],
[37,23,10,2],
[37,23,10,3],
[37,23,10,5],
[37,23,10,6],
[37,23,10,7],
[37,23,10,8],
[37,23,10,9],
[37,23,11,0],
[37,23,11,1],
[37,23,11,2],
[37,23,11,3],
[37,23,11,5],
[37,23,11,6],
[37,23,11,7],
[37,23,11,8],
[37,23,11,9],
[37,23,11,10],
[37,23,12,0],
[37,23,12,1],
[37,23,12,2],
[37,23,12,3],
[37,23,12,5],
[37,23,12,6],
[37,23,12,7],
[37,23,12,8],
[37,23,12,9],
[37,23,12,10],
[37,23,13,0],
[37,23,13,1],
[37,23,13,2],
[37,23,13,3],
[37,23,13,5],
[37,23,13,6],
[37,23,13,7],
[37,23,13,8],
[37,23,13,9],
[37,23,13,10],
[37,23,13,11],
[37,23,13,12],
[37,23,14,0],
[37,23,14,1],
[37,23,14,2],
[37,23,14,3],
[37,23,14,5],
[37,23,14,6],
[37,23,14,7],
[37,23,14,8],
[37,23,14,9],
[37,23,14,10],
[37,23,14,11],
[37,23,14,12],
[37,23,15,0],
[37,23,15,1],
[37,23,15,2],
[37,23,15,3],
[37,23,15,5],
[37,23,15,6],
[37,23,15,7],
[37,23,15,8],
[37,23,15,10],
[37,23,15,11],
[37,23,15,12],
[37,23,15,13],
[37,23,15,14],
[37,23,16,0],
[37,23,16,1],
[37,23,16,2],
[37,23,16,3],
[37,23,16,5],
[37,23,16,6],
[37,23,16,7],
[37,23,16,8],
[37,23,16,9],
[37,23,16,10],
[37,23,16,11],
[37,23,16,12],
[37,23,16,13],
[37,23,16,14],
[37,23,16,15],
[37,23,17,0],
[37,23,17,1],
[37,23,17,2],
[37,23,17,3],
[37,23,17,5],
[37,23,17,6],
[37,23,17,7],
[37,23,17,8],
[37,23,17,9],
[37,23,17,10],
[37,23,17,11],
[37,23,17,12],
[37,23,17,13],
[37,23,17,14],
[37,23,17,15],
[37,23,17,16],
[37,23,18,0],
[37,23,18,1],
[37,23,18,2],
[37,23,18,3],
[37,23,18,5],
[37,23,18,6],
[37,23,18,7],
[37,23,18,8],
[37,23,18,10],
[37,23,18,11],
[37,23,18,12],
[37,23,18,13],
[37,23,18,14],
[37,23,18,16],
[37,23,18,17],
[37,23,19,0],
[37,23,19,1],
[37,23,19,2],
[37,23,19,3],
[37,23,19,5],
[37,23,19,6],
[37,23,19,7],
[37,23,19,8],
[37,23,19,9],
[37,23,19,10],
[37,23,19,11],
[37,23,19,12],
[37,23,19,15],
[37,23,19,16],
[37,23,19,17],
[37,23,19,18],
[37,23,20,0],
[37,23,20,1],
[37,23,20,2],
[37,23,20,3],
[37,23,20,5],
[37,23,20,6],
[37,23,20,7],
[37,23,20,8],
[37,23,20,9],
[37,23,20,11],
[37,23,20,12],
[37,23,20,13],
[37,23,20,14],
[37,23,20,15],
[37,23,20,16],
[37,23,20,17],
[37,23,20,18],
[37,23,20,19],
[37,23,21,0],
[37,23,21,1],
[37,23,21,2],
[37,23,21,7],
[37,23,21,9],
[37,23,21,10],
[37,23,21,11],
[37,23,21,12],
[37,23,21,13],
[37,23,21,14],
[37,23,21,15],
[37,23,21,16],
[37,23,21,17],
[37,23,21,18],
[37,23,21,19],
[37,23,21,20],
[37,23,22,0],
[37,23,22,1],
[37,23,22,2],
[37,23,22,3],
[37,23,22,5],
[37,23,22,6],
[37,23,22,7],
[37,23,22,8],
[37,23,22,9],
[37,23,22,11],
[37,23,22,12],
[37,23,22,13],
[37,23,22,14],
[37,23,22,15],
[37,23,22,16],
[37,23,22,17],
[37,23,22,18],
[37,23,22,19],
[37,23,22,21],
[37,24,3,0],
[37,24,3,1],
[37,24,3,2],
[37,24,5,0],
[37,24,5,1],
[37,24,5,2],
[37,24,6,0],
[37,24,6,1],
[37,24,6,2],
[37,24,7,0],
[37,24,7,1],
[37,24,7,2],
[37,24,7,3],
[37,24,7,5],
[37,24,7,6],
[37,24,8,0],
[37,24,8,1],
[37,24,8,2],
[37,24,8,7],
[37,24,9,0],
[37,24,9,1],
[37,24,9,2],
[37,24,9,3],
[37,24,9,5],
[37,24,9,6],
[37,24,9,7],
[37,24,9,8],
[37,24,10,0],
[37,24,10,1],
[37,24,10,2],
[37,24,10,3],
[37,24,10,5],
[37,24,10,6],
[37,24,10,7],
[37,24,10,8],
[37,24,10,9],
[37,24,11,0],
[37,24,11,1],
[37,24,11,2],
[37,24,11,3],
[37,24,11,5],
[37,24,11,6],
[37,24,11,7],
[37,24,11,8],
[37,24,11,9],
[37,24,11,10],
[37,24,12,0],
[37,24,12,1],
[37,24,12,2],
[37,24,12,3],
[37,24,12,5],
[37,24,12,6],
[37,24,12,7],
[37,24,12,8],
[37,24,12,9],
[37,24,12,10],
[37,24,13,0],
[37,24,13,1],
[37,24,13,2],
[37,24,13,3],
[37,24,13,5],
[37,24,13,6],
[37,24,13,7],
[37,24,13,8],
[37,24,13,9],
[37,24,13,10],
[37,24,13,11],
[37,24,13,12],
[37,24,14,0],
[37,24,14,1],
[37,24,14,2],
[37,24,14,3],
[37,24,14,5],
[37,24,14,6],
[37,24,14,7],
[37,24,14,8],
[37,24,14,9],
[37,24,14,10],
[37,24,14,11],
[37,24,14,12],
[37,24,15,0],
[37,24,15,1],
[37,24,15,2],
[37,24,15,3],
[37,24,15,5],
[37,24,15,6],
[37,24,15,7],
[37,24,15,8],
[37,24,15,10],
[37,24,15,11],
[37,24,15,12],
[37,24,15,13],
[37,24,15,14],
[37,24,17,0],
[37,24,17,1],
[37,24,17,2],
[37,24,17,3],
[37,24,17,5],
[37,24,17,6],
[37,24,17,7],
[37,24,17,8],
[37,24,17,9],
[37,24,17,10],
[37,24,17,11],
[37,24,17,12],
[37,24,17,13],
[37,24,17,14],
[37,24,17,15],
[37,24,18,0],
[37,24,18,1],
[37,24,18,2],
[37,24,18,3],
[37,24,18,5],
[37,24,18,6],
[37,24,18,7],
[37,24,18,8],
[37,24,18,10],
[37,24,18,11],
[37,24,18,12],
[37,24,18,13],
[37,24,18,14],
[37,24,18,17],
[37,24,19,0],
[37,24,19,1],
[37,24,19,2],
[37,24,19,3],
[37,24,19,5],
[37,24,19,6],
[37,24,19,7],
[37,24,19,8],
[37,24,19,9],
[37,24,19,10],
[37,24,19,11],
[37,24,19,12],
[37,24,19,15],
[37,24,19,17],
[37,24,19,18],
[37,24,20,0],
[37,24,20,1],
[37,24,20,2],
[37,24,20,3],
[37,24,20,5],
[37,24,20,6],
[37,24,20,7],
[37,24,20,8],
[37,24,20,9],
[37,24,20,11],
[37,24,20,12],
[37,24,20,13],
[37,24,20,14],
[37,24,20,15],
[37,24,20,17],
[37,24,20,18],
[37,24,20,19],
[37,24,21,0],
[37,24,21,1],
[37,24,21,2],
[37,24,21,7],
[37,24,21,9],
[37,24,21,10],
[37,24,21,11],
[37,24,21,12],
[37,24,21,13],
[37,24,21,14],
[37,24,21,15],
[37,24,21,17],
[37,24,21,18],
[37,24,21,19],
[37,24,21,20],
[37,24,22,0],
[37,24,22,1],
[37,24,22,2],
[37,24,22,3],
[37,24,22,5],
[37,24,22,6],
[37,24,22,7],
[37,24,22,8],
[37,24,22,9],
[37,24,22,11],
[37,24,22,12],
[37,24,22,13],
[37,24,22,14],
[37,24,22,15],
[37,24,22,17],
[37,24,22,18],
[37,24,22,19],
[37,24,22,21],
[37,24,23,0],
[37,24,23,1],
[37,24,23,2],
[37,24,23,3],
[37,24,23,5],
[37,24,23,6],
[37,24,23,7],
[37,24,23,8],
[37,24,23,9],
[37,24,23,10],
[37,24,23,11],
[37,24,23,12],
[37,24,23,13],
[37,24,23,14],
[37,24,23,15],
[37,24,23,17],
[37,24,23,18],
[37,24,23,19],
[37,24,23,20],
[37,24,23,21],
[37,24,23,22],
[37,25,3,0],
[37,25,3,1],
[37,25,3,2],
[37,25,5,0],
[37,25,5,1],
[37,25,5,2],
[37,25,6,0],
[37,25,6,1],
[37,25,6,2],
[37,25,8,0],
[37,25,8,1],
[37,25,8,2],
[37,25,9,0],
[37,25,9,1],
[37,25,9,2],
[37,25,9,3],
[37,25,9,5],
[37,25,9,6],
[37,25,9,8],
[37,25,10,0],
[37,25,10,1],
[37,25,10,2],
[37,25,10,3],
[37,25,10,5],
[37,25,10,6],
[37,25,10,8],
[37,25,10,9],
[37,25,11,0],
[37,25,11,1],
[37,25,11,2],
[37,25,11,3],
[37,25,11,5],
[37,25,11,6],
[37,25,11,8],
[37,25,11,9],
[37,25,11,10],
[37,25,12,0],
[37,25,12,1],
[37,25,12,2],
[37,25,12,3],
[37,25,12,5],
[37,25,12,6],
[37,25,12,8],
[37,25,12,9],
[37,25,12,10],
[37,25,13,0],
[37,25,13,1],
[37,25,13,2],
[37,25,13,3],
[37,25,13,5],
[37,25,13,6],
[37,25,13,8],
[37,25,13,9],
[37,25,13,10],
[37,25,13,11],
[37,25,13,12],
[37,25,14,0],
[37,25,14,1],
[37,25,14,2],
[37,25,14,3],
[37,25,14,5],
[37,25,14,6],
[37,25,14,8],
[37,25,14,9],
[37,25,14,10],
[37,25,14,11],
[37,25,14,12],
[37,25,15,0],
[37,25,15,1],
[37,25,15,2],
[37,25,15,3],
[37,25,15,5],
[37,25,15,6],
[37,25,15,8],
[37,25,15,10],
[37,25,15,11],
[37,25,15,12],
[37,25,15,13],
[37,25,15,14],
[37,25,16,0],
[37,25,16,1],
[37,25,16,2],
[37,25,16,3],
[37,25,16,5],
[37,25,16,6],
[37,25,16,8],
[37,25,16,9],
[37,25,16,10],
[37,25,16,11],
[37,25,16,12],
[37,25,16,13],
[37,25,16,14],
[37,25,16,15],
[37,25,17,0],
[37,25,17,1],
[37,25,17,2],
[37,25,17,3],
[37,25,17,5],
[37,25,17,6],
[37,25,17,8],
[37,25,17,9],
[37,25,17,10],
[37,25,17,11],
[37,25,17,12],
[37,25,17,13],
[37,25,17,14],
[37,25,17,15],
[37,25,17,16],
[37,25,18,0],
[37,25,18,1],
[37,25,18,2],
[37,25,18,3],
[37,25,18,5],
[37,25,18,6],
[37,25,18,8],
[37,25,18,10],
[37,25,18,11],
[37,25,18,12],
[37,25,18,13],
[37,25,18,14],
[37,25,18,16],
[37,25,18,17],
[37,25,19,0],
[37,25,19,1],
[37,25,19,2],
[37,25,19,3],
[37,25,19,5],
[37,25,19,6],
[37,25,19,8],
[37,25,19,9],
[37,25,19,10],
[37,25,19,11],
[37,25,19,12],
[37,25,19,15],
[37,25,19,16],
[37,25,19,17],
[37,25,19,18],
[37,25,20,0],
[37,25,20,1],
[37,25,20,2],
[37,25,20,3],
[37,25,20,5],
[37,25,20,6],
[37,25,20,8],
[37,25,20,9],
[37,25,20,11],
[37,25,20,12],
[37,25,20,13],
[37,25,20,14],
[37,25,20,15],
[37,25,20,16],
[37,25,20,17],
[37,25,20,18],
[37,25,20,19],
[37,25,21,0],
[37,25,21,1],
[37,25,21,2],
[37,25,21,9],
[37,25,21,10],
[37,25,21,11],
[37,25,21,12],
[37,25,21,13],
[37,25,21,14],
[37,25,21,15],
[37,25,21,16],
[37,25,21,17],
[37,25,21,18],
[37,25,21,19],
[37,25,21,20],
[37,25,22,0],
[37,25,22,1],
[37,25,22,2],
[37,25,22,3],
[37,25,22,5],
[37,25,22,6],
[37,25,22,8],
[37,25,22,9],
[37,25,22,11],
[37,25,22,12],
[37,25,22,13],
[37,25,22,14],
[37,25,22,15],
[37,25,22,16],
[37,25,22,17],
[37,25,22,18],
[37,25,22,19],
[37,25,22,21],
[37,25,23,0],
[37,25,23,1],
[37,25,23,2],
[37,25,23,3],
[37,25,23,5],
[37,25,23,6],
[37,25,23,8],
[37,25,23,9],
[37,25,23,10],
[37,25,23,11],
[37,25,23,12],
[37,25,23,13],
[37,25,23,14],
[37,25,23,15],
[37,25,23,16],
[37,25,23,17],
[37,25,23,18],
[37,25,23,19],
[37,25,23,20],
[37,25,23,21],
[37,25,23,22],
[37,25,24,0],
[37,25,24,1],
[37,25,24,2],
[37,25,24,3],
[37,25,24,5],
[37,25,24,6],
[37,25,24,8],
[37,25,24,9],
[37,25,24,10],
[37,25,24,11],
[37,25,24,12],
[37,25,24,13],
[37,25,24,14],
[37,25,24,15],
[37,25,24,17],
[37,25,24,18],
[37,25,24,19],
[37,25,24,20],
[37,25,24,21],
[37,25,24,22],
[37,25,24,23],
[37,26,3,0],
[37,26,3,1],
[37,26,3,2],
[37,26,5,0],
[37,26,5,1],
[37,26,5,2],
[37,26,6,0],
[37,26,6,1],
[37,26,6,2],
[37,26,7,0],
[37,26,7,1],
[37,26,7,2],
[37,26,7,3],
[37,26,7,5],
[37,26,7,6],
[37,26,8,0],
[37,26,8,1],
[37,26,8,2],
[37,26,8,7],
[37,26,10,0],
[37,26,10,1],
[37,26,10,2],
[37,26,10,3],
[37,26,10,5],
[37,26,10,6],
[37,26,10,7],
[37,26,10,8],
[37,26,11,0],
[37,26,11,1],
[37,26,11,2],
[37,26,11,3],
[37,26,11,5],
[37,26,11,6],
[37,26,11,7],
[37,26,11,8],
[37,26,11,10],
[37,26,12,0],
[37,26,12,1],
[37,26,12,2],
[37,26,12,3],
[37,26,12,5],
[37,26,12,6],
[37,26,12,7],
[37,26,12,8],
[37,26,12,10],
[37,26,13,0],
[37,26,13,1],
[37,26,13,2],
[37,26,13,3],
[37,26,13,5],
[37,26,13,6],
[37,26,13,7],
[37,26,13,8],
[37,26,13,10],
[37,26,13,11],
[37,26,13,12],
[37,26,14,0],
[37,26,14,1],
[37,26,14,2],
[37,26,14,3],
[37,26,14,5],
[37,26,14,6],
[37,26,14,7],
[37,26,14,8],
[37,26,14,10],
[37,26,14,11],
[37,26,14,12],
[37,26,16,0],
[37,26,16,1],
[37,26,16,2],
[37,26,16,3],
[37,26,16,5],
[37,26,16,6],
[37,26,16,7],
[37,26,16,8],
[37,26,16,10],
[37,26,16,11],
[37,26,16,12],
[37,26,16,13],
[37,26,16,14],
[37,26,17,0],
[37,26,17,1],
[37,26,17,2],
[37,26,17,3],
[37,26,17,5],
[37,26,17,6],
[37,26,17,7],
[37,26,17,8],
[37,26,17,10],
[37,26,17,11],
[37,26,17,12],
[37,26,17,13],
[37,26,17,14],
[37,26,17,16],
[37,26,19,0],
[37,26,19,1],
[37,26,19,2],
[37,26,19,3],
[37,26,19,5],
[37,26,19,6],
[37,26,19,7],
[37,26,19,8],
[37,26,19,10],
[37,26,19,11],
[37,26,19,12],
[37,26,19,16],
[37,26,19,17],
[37,26,20,0],
[37,26,20,1],
[37,26,20,2],
[37,26,20,3],
[37,26,20,5],
[37,26,20,6],
[37,26,20,7],
[37,26,20,8],
[37,26,20,11],
[37,26,20,12],
[37,26,20,13],
[37,26,20,14],
[37,26,20,16],
[37,26,20,17],
[37,26,20,19],
[37,26,21,0],
[37,26,21,1],
[37,26,21,2],
[37,26,21,7],
[37,26,21,10],
[37,26,21,11],
[37,26,21,12],
[37,26,21,13],
[37,26,21,14],
[37,26,21,16],
[37,26,21,17],
[37,26,21,19],
[37,26,21,20],
[37,26,22,0],
[37,26,22,1],
[37,26,22,2],
[37,26,22,3],
[37,26,22,5],
[37,26,22,6],
[37,26,22,7],
[37,26,22,8],
[37,26,22,11],
[37,26,22,12],
[37,26,22,13],
[37,26,22,14],
[37,26,22,16],
[37,26,22,17],
[37,26,22,19],
[37,26,22,21],
[37,26,23,0],
[37,26,23,1],
[37,26,23,2],
[37,26,23,3],
[37,26,23,5],
[37,26,23,6],
[37,26,23,7],
[37,26,23,8],
[37,26,23,10],
[37,26,23,11],
[37,26,23,12],
[37,26,23,13],
[37,26,23,14],
[37,26,23,16],
[37,26,23,17],
[37,26,23,19],
[37,26,23,20],
[37,26,23,21],
[37,26,23,22],
[37,26,24,0],
[37,26,24,1],
[37,26,24,2],
[37,26,24,3],
[37,26,24,5],
[37,26,24,6],
[37,26,24,7],
[37,26,24,8],
[37,26,24,10],
[37,26,24,11],
[37,26,24,12],
[37,26,24,13],
[37,26,24,14],
[37,26,24,17],
[37,26,24,19],
[37,26,24,20],
[37,26,24,21],
[37,26,24,22],
[37,26,24,23],
[37,26,25,0],
[37,26,25,1],
[37,26,25,2],
[37,26,25,3],
[37,26,25,5],
[37,26,25,6],
[37,26,25,8],
[37,26,25,10],
[37,26,25,11],
[37,26,25,12],
[37,26,25,13],
[37,26,25,14],
[37,26,25,16],
[37,26,25,17],
[37,26,25,19],
[37,26,25,20],
[37,26,25,21],
[37,26,25,22],
[37,26,25,23],
[37,26,25,24],
[37,27,3,0],
[37,27,3,1],
[37,27,3,2],
[37,27,5,0],
[37,27,5,1],
[37,27,5,2],
[37,27,6,0],
[37,27,6,1],
[37,27,6,2],
[37,27,7,0],
[37,27,7,1],
[37,27,7,2],
[37,27,7,3],
[37,27,7,5],
[37,27,7,6],
[37,27,8,0],
[37,27,8,1],
[37,27,8,2],
[37,27,8,7],
[37,27,9,0],
[37,27,9,1],
[37,27,9,2],
[37,27,9,3],
[37,27,9,5],
[37,27,9,6],
[37,27,9,7],
[37,27,9,8],
[37,27,11,0],
[37,27,11,1],
[37,27,11,2],
[37,27,11,3],
[37,27,11,5],
[37,27,11,6],
[37,27,11,7],
[37,27,11,8],
[37,27,11,9],
[37,27,12,0],
[37,27,12,1],
[37,27,12,2],
[37,27,12,3],
[37,27,12,5],
[37,27,12,6],
[37,27,12,7],
[37,27,12,8],
[37,27,12,9],
[37,27,13,0],
[37,27,13,1],
[37,27,13,2],
[37,27,13,3],
[37,27,13,5],
[37,27,13,6],
[37,27,13,7],
[37,27,13,8],
[37,27,13,9],
[37,27,13,11],
[37,27,13,12],
[37,27,14,0],
[37,27,14,1],
[37,27,14,2],
[37,27,14,3],
[37,27,14,5],
[37,27,14,6],
[37,27,14,7],
[37,27,14,8],
[37,27,14,9],
[37,27,14,11],
[37,27,14,12],
[37,27,15,0],
[37,27,15,1],
[37,27,15,2],
[37,27,15,3],
[37,27,15,5],
[37,27,15,6],
[37,27,15,7],
[37,27,15,8],
[37,27,15,11],
[37,27,15,12],
[37,27,15,13],
[37,27,15,14],
[37,27,16,0],
[37,27,16,1],
[37,27,16,2],
[37,27,16,3],
[37,27,16,5],
[37,27,16,6],
[37,27,16,7],
[37,27,16,8],
[37,27,16,9],
[37,27,16,11],
[37,27,16,12],
[37,27,16,13],
[37,27,16,14],
[37,27,16,15],
[37,27,17,0],
[37,27,17,1],
[37,27,17,2],
[37,27,17,3],
[37,27,17,5],
[37,27,17,6],
[37,27,17,7],
[37,27,17,8],
[37,27,17,9],
[37,27,17,11],
[37,27,17,12],
[37,27,17,13],
[37,27,17,14],
[37,27,17,15],
[37,27,17,16],
[37,27,18,0],
[37,27,18,1],
[37,27,18,2],
[37,27,18,3],
[37,27,18,5],
[37,27,18,6],
[37,27,18,7],
[37,27,18,8],
[37,27,18,11],
[37,27,18,12],
[37,27,18,13],
[37,27,18,14],
[37,27,18,16],
[37,27,18,17],
[37,27,19,0],
[37,27,19,1],
[37,27,19,2],
[37,27,19,3],
[37,27,19,5],
[37,27,19,6],
[37,27,19,7],
[37,27,19,8],
[37,27,19,9],
[37,27,19,11],
[37,27,19,12],
[37,27,19,15],
[37,27,19,16],
[37,27,19,17],
[37,27,19,18],
[37,27,21,0],
[37,27,21,1],
[37,27,21,2],
[37,27,21,7],
[37,27,21,9],
[37,27,21,11],
[37,27,21,12],
[37,27,21,13],
[37,27,21,14],
[37,27,21,15],
[37,27,21,16],
[37,27,21,17],
[37,27,21,18],
[37,27,21,19],
[37,27,23,0],
[37,27,23,1],
[37,27,23,2],
[37,27,23,3],
[37,27,23,5],
[37,27,23,6],
[37,27,23,7],
[37,27,23,8],
[37,27,23,9],
[37,27,23,11],
[37,27,23,12],
[37,27,23,13],
[37,27,23,14],
[37,27,23,15],
[37,27,23,16],
[37,27,23,17],
[37,27,23,18],
[37,27,23,19],
[37,27,23,21],
[37,27,24,0],
[37,27,24,1],
[37,27,24,2],
[37,27,24,3],
[37,27,24,5],
[37,27,24,6],
[37,27,24,7],
[37,27,24,8],
[37,27,24,9],
[37,27,24,11],
[37,27,24,12],
[37,27,24,13],
[37,27,24,14],
[37,27,24,15],
[37,27,24,17],
[37,27,24,18],
[37,27,24,19],
[37,27,24,21],
[37,27,24,23],
[37,27,25,0],
[37,27,25,1],
[37,27,25,2],
[37,27,25,3],
[37,27,25,5],
[37,27,25,6],
[37,27,25,8],
[37,27,25,9],
[37,27,25,11],
[37,27,25,12],
[37,27,25,13],
[37,27,25,14],
[37,27,25,15],
[37,27,25,16],
[37,27,25,17],
[37,27,25,18],
[37,27,25,19],
[37,27,25,21],
[37,27,25,23],
[37,27,25,24],
[37,27,26,0],
[37,27,26,1],
[37,27,26,2],
[37,27,26,3],
[37,27,26,5],
[37,27,26,6],
[37,27,26,7],
[37,27,26,8],
[37,27,26,11],
[37,27,26,12],
[37,27,26,13],
[37,27,26,14],
[37,27,26,16],
[37,27,26,17],
[37,27,26,19],
[37,27,26,21],
[37,27,26,23],
[37,27,26,24],
[37,27,26,25],
[37,28,3,0],
[37,28,3,1],
[37,28,3,2],
[37,28,5,0],
[37,28,5,1],
[37,28,5,2],
[37,28,6,0],
[37,28,6,1],
[37,28,6,2],
[37,28,8,0],
[37,28,8,1],
[37,28,8,2],
[37,28,9,0],
[37,28,9,1],
[37,28,9,2],
[37,28,9,3],
[37,28,9,5],
[37,28,9,6],
[37,28,9,8],
[37,28,10,0],
[37,28,10,1],
[37,28,10,2],
[37,28,10,3],
[37,28,10,5],
[37,28,10,6],
[37,28,10,8],
[37,28,10,9],
[37,28,11,0],
[37,28,11,1],
[37,28,11,2],
[37,28,11,3],
[37,28,11,5],
[37,28,11,6],
[37,28,11,8],
[37,28,11,9],
[37,28,11,10],
[37,28,12,0],
[37,28,12,1],
[37,28,12,2],
[37,28,12,3],
[37,28,12,5],
[37,28,12,6],
[37,28,12,8],
[37,28,12,9],
[37,28,12,10],
[37,28,13,0],
[37,28,13,1],
[37,28,13,2],
[37,28,13,3],
[37,28,13,5],
[37,28,13,6],
[37,28,13,8],
[37,28,13,9],
[37,28,13,10],
[37,28,13,11],
[37,28,13,12],
[37,28,14,0],
[37,28,14,1],
[37,28,14,2],
[37,28,14,3],
[37,28,14,5],
[37,28,14,6],
[37,28,14,8],
[37,28,14,9],
[37,28,14,10],
[37,28,14,11],
[37,28,14,12],
[37,28,15,0],
[37,28,15,1],
[37,28,15,2],
[37,28,15,3],
[37,28,15,5],
[37,28,15,6],
[37,28,15,8],
[37,28,15,10],
[37,28,15,11],
[37,28,15,12],
[37,28,15,13],
[37,28,15,14],
[37,28,16,0],
[37,28,16,1],
[37,28,16,2],
[37,28,16,3],
[37,28,16,5],
[37,28,16,6],
[37,28,16,8],
[37,28,16,9],
[37,28,16,10],
[37,28,16,11],
[37,28,16,12],
[37,28,16,13],
[37,28,16,14],
[37,28,16,15],
[37,28,17,0],
[37,28,17,1],
[37,28,17,2],
[37,28,17,3],
[37,28,17,5],
[37,28,17,6],
[37,28,17,8],
[37,28,17,9],
[37,28,17,10],
[37,28,17,11],
[37,28,17,12],
[37,28,17,13],
[37,28,17,14],
[37,28,17,15],
[37,28,17,16],
[37,28,18,0],
[37,28,18,1],
[37,28,18,2],
[37,28,18,3],
[37,28,18,5],
[37,28,18,6],
[37,28,18,8],
[37,28,18,10],
[37,28,18,11],
[37,28,18,12],
[37,28,18,13],
[37,28,18,14],
[37,28,18,16],
[37,28,18,17],
[37,28,19,0],
[37,28,19,1],
[37,28,19,2],
[37,28,19,3],
[37,28,19,5],
[37,28,19,6],
[37,28,19,8],
[37,28,19,9],
[37,28,19,10],
[37,28,19,11],
[37,28,19,12],
[37,28,19,15],
[37,28,19,16],
[37,28,19,17],
[37,28,19,18],
[37,28,20,0],
[37,28,20,1],
[37,28,20,2],
[37,28,20,3],
[37,28,20,5],
[37,28,20,6],
[37,28,20,8],
[37,28,20,9],
[37,28,20,11],
[37,28,20,12],
[37,28,20,13],
[37,28,20,14],
[37,28,20,15],
[37,28,20,16],
[37,28,20,17],
[37,28,20,18],
[37,28,20,19],
[37,28,21,0],
[37,28,21,1],
[37,28,21,2],
[37,28,21,9],
[37,28,21,10],
[37,28,21,11],
[37,28,21,12],
[37,28,21,13],
[37,28,21,14],
[37,28,21,15],
[37,28,21,16],
[37,28,21,17],
[37,28,21,18],
[37,28,21,19],
[37,28,21,20],
[37,28,22,0],
[37,28,22,1],
[37,28,22,2],
[37,28,22,3],
[37,28,22,5],
[37,28,22,6],
[37,28,22,8],
[37,28,22,9],
[37,28,22,11],
[37,28,22,12],
[37,28,22,13],
[37,28,22,14],
[37,28,22,15],
[37,28,22,16],
[37,28,22,17],
[37,28,22,18],
[37,28,22,19],
[37,28,22,21],
[37,28,23,0],
[37,28,23,1],
[37,28,23,2],
[37,28,23,3],
[37,28,23,5],
[37,28,23,6],
[37,28,23,8],
[37,28,23,9],
[37,28,23,10],
[37,28,23,11],
[37,28,23,12],
[37,28,23,13],
[37,28,23,14],
[37,28,23,15],
[37,28,23,16],
[37,28,23,17],
[37,28,23,18],
[37,28,23,19],
[37,28,23,20],
[37,28,23,21],
[37,28,23,22],
[37,28,24,0],
[37,28,24,1],
[37,28,24,2],
[37,28,24,3],
[37,28,24,5],
[37,28,24,6],
[37,28,24,8],
[37,28,24,9],
[37,28,24,10],
[37,28,24,11],
[37,28,24,12],
[37,28,24,13],
[37,28,24,14],
[37,28,24,15],
[37,28,24,17],
[37,28,24,18],
[37,28,24,19],
[37,28,24,20],
[37,28,24,21],
[37,28,24,22],
[37,28,24,23],
[37,28,26,0],
[37,28,26,1],
[37,28,26,2],
[37,28,26,3],
[37,28,26,5],
[37,28,26,6],
[37,28,26,8],
[37,28,26,10],
[37,28,26,11],
[37,28,26,12],
[37,28,26,13],
[37,28,26,14],
[37,28,26,16],
[37,28,26,17],
[37,28,26,19],
[37,28,26,20],
[37,28,26,21],
[37,28,26,22],
[37,28,26,23],
[37,28,26,24],
[37,28,27,0],
[37,28,27,1],
[37,28,27,2],
[37,28,27,3],
[37,28,27,5],
[37,28,27,6],
[37,28,27,8],
[37,28,27,9],
[37,28,27,11],
[37,28,27,12],
[37,28,27,13],
[37,28,27,14],
[37,28,27,15],
[37,28,27,16],
[37,28,27,17],
[37,28,27,18],
[37,28,27,19],
[37,28,27,21],
[37,28,27,23],
[37,28,27,24],
[37,28,27,26],
[37,29,3,0],
[37,29,3,1],
[37,29,3,2],
[37,29,5,0],
[37,29,5,1],
[37,29,5,2],
[37,29,6,0],
[37,29,6,1],
[37,29,6,2],
[37,29,7,0],
[37,29,7,1],
[37,29,7,2],
[37,29,7,3],
[37,29,7,5],
[37,29,7,6],
[37,29,8,0],
[37,29,8,1],
[37,29,8,2],
[37,29,8,7],
[37,29,9,0],
[37,29,9,1],
[37,29,9,2],
[37,29,9,3],
[37,29,9,5],
[37,29,9,6],
[37,29,9,7],
[37,29,9,8],
[37,29,10,0],
[37,29,10,1],
[37,29,10,2],
[37,29,10,3],
[37,29,10,5],
[37,29,10,6],
[37,29,10,7],
[37,29,10,8],
[37,29,10,9],
[37,29,13,0],
[37,29,13,1],
[37,29,13,2],
[37,29,13,3],
[37,29,13,5],
[37,29,13,6],
[37,29,13,7],
[37,29,13,8],
[37,29,13,9],
[37,29,13,10],
[37,29,14,0],
[37,29,14,1],
[37,29,14,2],
[37,29,14,3],
[37,29,14,5],
[37,29,14,6],
[37,29,14,7],
[37,29,14,8],
[37,29,14,9],
[37,29,14,10],
[37,29,15,0],
[37,29,15,1],
[37,29,15,2],
[37,29,15,3],
[37,29,15,5],
[37,29,15,6],
[37,29,15,7],
[37,29,15,8],
[37,29,15,10],
[37,29,15,13],
[37,29,15,14],
[37,29,16,0],
[37,29,16,1],
[37,29,16,2],
[37,29,16,3],
[37,29,16,5],
[37,29,16,6],
[37,29,16,7],
[37,29,16,8],
[37,29,16,9],
[37,29,16,10],
[37,29,16,13],
[37,29,16,14],
[37,29,16,15],
[37,29,17,0],
[37,29,17,1],
[37,29,17,2],
[37,29,17,3],
[37,29,17,5],
[37,29,17,6],
[37,29,17,7],
[37,29,17,8],
[37,29,17,9],
[37,29,17,10],
[37,29,17,13],
[37,29,17,14],
[37,29,17,15],
[37,29,17,16],
[37,29,18,0],
[37,29,18,1],
[37,29,18,2],
[37,29,18,3],
[37,29,18,5],
[37,29,18,6],
[37,29,18,7],
[37,29,18,8],
[37,29,18,10],
[37,29,18,13],
[37,29,18,14],
[37,29,18,16],
[37,29,18,17],
[37,29,19,0],
[37,29,19,1],
[37,29,19,2],
[37,29,19,3],
[37,29,19,5],
[37,29,19,6],
[37,29,19,7],
[37,29,19,8],
[37,29,19,9],
[37,29,19,10],
[37,29,19,15],
[37,29,19,16],
[37,29,19,17],
[37,29,19,18],
[37,29,20,0],
[37,29,20,1],
[37,29,20,2],
[37,29,20,3],
[37,29,20,5],
[37,29,20,6],
[37,29,20,7],
[37,29,20,8],
[37,29,20,9],
[37,29,20,13],
[37,29,20,14],
[37,29,20,15],
[37,29,20,16],
[37,29,20,17],
[37,29,20,18],
[37,29,20,19],
[37,29,21,0],
[37,29,21,1],
[37,29,21,2],
[37,29,21,7],
[37,29,21,9],
[37,29,21,10],
[37,29,21,13],
[37,29,21,14],
[37,29,21,15],
[37,29,21,16],
[37,29,21,17],
[37,29,21,18],
[37,29,21,19],
[37,29,21,20],
[37,29,22,0],
[37,29,22,1],
[37,29,22,2],
[37,29,22,3],
[37,29,22,5],
[37,29,22,6],
[37,29,22,7],
[37,29,22,8],
[37,29,22,9],
[37,29,22,13],
[37,29,22,14],
[37,29,22,15],
[37,29,22,16],
[37,29,22,17],
[37,29,22,18],
[37,29,22,19],
[37,29,22,21],
[37,29,23,0],
[37,29,23,1],
[37,29,23,2],
[37,29,23,3],
[37,29,23,5],
[37,29,23,6],
[37,29,23,7],
[37,29,23,8],
[37,29,23,9],
[37,29,23,10],
[37,29,23,13],
[37,29,23,14],
[37,29,23,15],
[37,29,23,16],
[37,29,23,17],
[37,29,23,18],
[37,29,23,19],
[37,29,23,20],
[37,29,23,21],
[37,29,23,22],
[37,29,24,0],
[37,29,24,1],
[37,29,24,2],
[37,29,24,3],
[37,29,24,5],
[37,29,24,6],
[37,29,24,7],
[37,29,24,8],
[37,29,24,9],
[37,29,24,10],
[37,29,24,13],
[37,29,24,14],
[37,29,24,15],
[37,29,24,17],
[37,29,24,18],
[37,29,24,19],
[37,29,24,20],
[37,29,24,21],
[37,29,24,22],
[37,29,24,23],
[37,29,25,0],
[37,29,25,1],
[37,29,25,2],
[37,29,25,3],
[37,29,25,5],
[37,29,25,6],
[37,29,25,8],
[37,29,25,9],
[37,29,25,10],
[37,29,25,13],
[37,29,25,14],
[37,29,25,15],
[37,29,25,16],
[37,29,25,17],
[37,29,25,18],
[37,29,25,19],
[37,29,25,20],
[37,29,25,21],
[37,29,25,22],
[37,29,25,23],
[37,29,25,24],
[37,29,26,0],
[37,29,26,1],
[37,29,26,2],
[37,29,26,3],
[37,29,26,5],
[37,29,26,6],
[37,29,26,7],
[37,29,26,8],
[37,29,26,10],
[37,29,26,13],
[37,29,26,14],
[37,29,26,16],
[37,29,26,17],
[37,29,26,19],
[37,29,26,20],
[37,29,26,21],
[37,29,26,22],
[37,29,26,23],
[37,29,26,24],
[37,29,26,25],
[37,29,27,0],
[37,29,27,1],
[37,29,27,2],
[37,29,27,3],
[37,29,27,5],
[37,29,27,6],
[37,29,27,7],
[37,29,27,8],
[37,29,27,9],
[37,29,27,13],
[37,29,27,14],
[37,29,27,15],
[37,29,27,16],
[37,29,27,17],
[37,29,27,18],
[37,29,27,19],
[37,29,27,21],
[37,29,27,23],
[37,29,27,24],
[37,29,27,25],
[37,29,27,26],
[37,29,28,0],
[37,29,28,1],
[37,29,28,2],
[37,29,28,3],
[37,29,28,5],
[37,29,28,6],
[37,29,28,8],
[37,29,28,9],
[37,29,28,10],
[37,29,28,13],
[37,29,28,14],
[37,29,28,15],
[37,29,28,16],
[37,29,28,17],
[37,29,28,18],
[37,29,28,19],
[37,29,28,20],
[37,29,28,21],
[37,29,28,22],
[37,29,28,23],
[37,29,28,24],
[37,29,28,26],
[37,29,28,27],
[37,30,3,0],
[37,30,3,1],
[37,30,3,2],
[37,30,5,0],
[37,30,5,1],
[37,30,5,2],
[37,30,6,0],
[37,30,6,1],
[37,30,6,2],
[37,30,7,0],
[37,30,7,1],
[37,30,7,2],
[37,30,7,3],
[37,30,7,5],
[37,30,7,6],
[37,30,8,0],
[37,30,8,1],
[37,30,8,2],
[37,30,8,7],
[37,30,9,0],
[37,30,9,1],
[37,30,9,2],
[37,30,9,3],
[37,30,9,5],
[37,30,9,6],
[37,30,9,7],
[37,30,9,8],
[37,30,10,0],
[37,30,10,1],
[37,30,10,2],
[37,30,10,3],
[37,30,10,5],
[37,30,10,6],
[37,30,10,7],
[37,30,10,8],
[37,30,10,9],
[37,30,11,0],
[37,30,11,1],
[37,30,11,2],
[37,30,11,3],
[37,30,11,5],
[37,30,11,6],
[37,30,11,7],
[37,30,11,8],
[37,30,11,9],
[37,30,11,10],
[37,30,12,0],
[37,30,12,1],
[37,30,12,2],
[37,30,12,3],
[37,30,12,5],
[37,30,12,6],
[37,30,12,7],
[37,30,12,8],
[37,30,12,9],
[37,30,12,10],
[37,30,13,0],
[37,30,13,1],
[37,30,13,2],
[37,30,13,3],
[37,30,13,5],
[37,30,13,6],
[37,30,13,7],
[37,30,13,8],
[37,30,13,9],
[37,30,13,10],
[37,30,13,11],
[37,30,13,12],
[37,30,14,0],
[37,30,14,1],
[37,30,14,2],
[37,30,14,3],
[37,30,14,5],
[37,30,14,6],
[37,30,14,7],
[37,30,14,8],
[37,30,14,9],
[37,30,14,10],
[37,30,14,11],
[37,30,14,12],
[37,30,15,0],
[37,30,15,1],
[37,30,15,2],
[37,30,15,3],
[37,30,15,5],
[37,30,15,6],
[37,30,15,7],
[37,30,15,8],
[37,30,15,10],
[37,30,15,11],
[37,30,15,12],
[37,30,15,13],
[37,30,15,14],
[37,30,16,0],
[37,30,16,1],
[37,30,16,2],
[37,30,16,3],
[37,30,16,5],
[37,30,16,6],
[37,30,16,7],
[37,30,16,8],
[37,30,16,9],
[37,30,16,10],
[37,30,16,11],
[37,30,16,12],
[37,30,16,13],
[37,30,16,14],
[37,30,16,15],
[37,30,17,0],
[37,30,17,1],
[37,30,17,2],
[37,30,17,3],
[37,30,17,5],
[37,30,17,6],
[37,30,17,7],
[37,30,17,8],
[37,30,17,9],
[37,30,17,10],
[37,30,17,11],
[37,30,17,12],
[37,30,17,13],
[37,30,17,14],
[37,30,17,15],
[37,30,17,16],
[37,30,18,0],
[37,30,18,1],
[37,30,18,2],
[37,30,18,3],
[37,30,18,5],
[37,30,18,6],
[37,30,18,7],
[37,30,18,8],
[37,30,18,10],
[37,30,18,11],
[37,30,18,12],
[37,30,18,13],
[37,30,18,14],
[37,30,18,16],
[37,30,18,17],
[37,30,19,0],
[37,30,19,1],
[37,30,19,2],
[37,30,19,3],
[37,30,19,5],
[37,30,19,6],
[37,30,19,7],
[37,30,19,8],
[37,30,19,9],
[37,30,19,10],
[37,30,19,11],
[37,30,19,12],
[37,30,19,15],
[37,30,19,16],
[37,30,19,17],
[37,30,19,18],
[37,30,20,0],
[37,30,20,1],
[37,30,20,2],
[37,30,20,3],
[37,30,20,5],
[37,30,20,6],
[37,30,20,7],
[37,30,20,8],
[37,30,20,9],
[37,30,20,11],
[37,30,20,12],
[37,30,20,13],
[37,30,20,14],
[37,30,20,15],
[37,30,20,16],
[37,30,20,17],
[37,30,20,18],
[37,30,20,19],
[37,30,21,0],
[37,30,21,1],
[37,30,21,2],
[37,30,21,7],
[37,30,21,9],
[37,30,21,10],
[37,30,21,11],
[37,30,21,12],
[37,30,21,13],
[37,30,21,14],
[37,30,21,15],
[37,30,21,16],
[37,30,21,17],
[37,30,21,18],
[37,30,21,19],
[37,30,21,20],
[37,30,22,0],
[37,30,22,1],
[37,30,22,2],
[37,30,22,3],
[37,30,22,5],
[37,30,22,6],
[37,30,22,7],
[37,30,22,8],
[37,30,22,9],
[37,30,22,11],
[37,30,22,12],
[37,30,22,13],
[37,30,22,14],
[37,30,22,15],
[37,30,22,16],
[37,30,22,17],
[37,30,22,18],
[37,30,22,19],
[37,30,22,21],
[37,30,24,0],
[37,30,24,1],
[37,30,24,2],
[37,30,24,3],
[37,30,24,5],
[37,30,24,6],
[37,30,24,7],
[37,30,24,8],
[37,30,24,9],
[37,30,24,10],
[37,30,24,11],
[37,30,24,12],
[37,30,24,13],
[37,30,24,14],
[37,30,24,15],
[37,30,24,17],
[37,30,24,18],
[37,30,24,19],
[37,30,24,20],
[37,30,24,21],
[37,30,24,22],
[37,30,25,0],
[37,30,25,1],
[37,30,25,2],
[37,30,25,3],
[37,30,25,5],
[37,30,25,6],
[37,30,25,8],
[37,30,25,9],
[37,30,25,10],
[37,30,25,11],
[37,30,25,12],
[37,30,25,13],
[37,30,25,14],
[37,30,25,15],
[37,30,25,16],
[37,30,25,17],
[37,30,25,18],
[37,30,25,19],
[37,30,25,20],
[37,30,25,21],
[37,30,25,22],
[37,30,25,24],
[37,30,26,0],
[37,30,26,1],
[37,30,26,2],
[37,30,26,3],
[37,30,26,5],
[37,30,26,6],
[37,30,26,7],
[37,30,26,8],
[37,30,26,10],
[37,30,26,11],
[37,30,26,12],
[37,30,26,13],
[37,30,26,14],
[37,30,26,16],
[37,30,26,17],
[37,30,26,19],
[37,30,26,20],
[37,30,26,21],
[37,30,26,22],
[37,30,26,24],
[37,30,26,25],
[37,30,27,0],
[37,30,27,1],
[37,30,27,2],
[37,30,27,3],
[37,30,27,5],
[37,30,27,6],
[37,30,27,7],
[37,30,27,8],
[37,30,27,9],
[37,30,27,11],
[37,30,27,12],
[37,30,27,13],
[37,30,27,14],
[37,30,27,15],
[37,30,27,16],
[37,30,27,17],
[37,30,27,18],
[37,30,27,19],
[37,30,27,21],
[37,30,27,24],
[37,30,27,25],
[37,30,27,26],
[37,30,28,0],
[37,30,28,1],
[37,30,28,2],
[37,30,28,3],
[37,30,28,5],
[37,30,28,6],
[37,30,28,8],
[37,30,28,9],
[37,30,28,10],
[37,30,28,11],
[37,30,28,12],
[37,30,28,13],
[37,30,28,14],
[37,30,28,15],
[37,30,28,16],
[37,30,28,17],
[37,30,28,18],
[37,30,28,19],
[37,30,28,20],
[37,30,28,21],
[37,30,28,22],
[37,30,28,24],
[37,30,28,26],
[37,30,28,27],
[37,30,29,0],
[37,30,29,1],
[37,30,29,2],
[37,30,29,3],
[37,30,29,5],
[37,30,29,6],
[37,30,29,7],
[37,30,29,8],
[37,30,29,9],
[37,30,29,10],
[37,30,29,13],
[37,30,29,14],
[37,30,29,15],
[37,30,29,16],
[37,30,29,17],
[37,30,29,18],
[37,30,29,19],
[37,30,29,20],
[37,30,29,21],
[37,30,29,22],
[37,30,29,24],
[37,30,29,25],
[37,30,29,26],
[37,30,29,27],
[37,30,29,28],
[37,31,3,0],
[37,31,3,1],
[37,31,3,2],
[37,31,5,0],
[37,31,5,1],
[37,31,5,2],
[37,31,6,0],
[37,31,6,1],
[37,31,6,2],
[37,31,7,0],
[37,31,7,1],
[37,31,7,2],
[37,31,7,3],
[37,31,7,5],
[37,31,7,6],
[37,31,8,0],
[37,31,8,1],
[37,31,8,2],
[37,31,8,7],
[37,31,9,0],
[37,31,9,1],
[37,31,9,2],
[37,31,9,3],
[37,31,9,5],
[37,31,9,6],
[37,31,9,7],
[37,31,9,8],
[37,31,10,0],
[37,31,10,1],
[37,31,10,2],
[37,31,10,3],
[37,31,10,5],
[37,31,10,6],
[37,31,10,7],
[37,31,10,8],
[37,31,10,9],
[37,31,11,0],
[37,31,11,1],
[37,31,11,2],
[37,31,11,3],
[37,31,11,5],
[37,31,11,6],
[37,31,11,7],
[37,31,11,8],
[37,31,11,9],
[37,31,11,10],
[37,31,12,0],
[37,31,12,1],
[37,31,12,2],
[37,31,12,3],
[37,31,12,5],
[37,31,12,6],
[37,31,12,7],
[37,31,12,8],
[37,31,12,9],
[37,31,12,10],
[37,31,13,0],
[37,31,13,1],
[37,31,13,2],
[37,31,13,3],
[37,31,13,5],
[37,31,13,6],
[37,31,13,7],
[37,31,13,8],
[37,31,13,9],
[37,31,13,10],
[37,31,13,11],
[37,31,13,12],
[37,31,14,0],
[37,31,14,1],
[37,31,14,2],
[37,31,14,3],
[37,31,14,5],
[37,31,14,6],
[37,31,14,7],
[37,31,14,8],
[37,31,14,9],
[37,31,14,10],
[37,31,14,11],
[37,31,14,12],
[37,31,15,0],
[37,31,15,1],
[37,31,15,2],
[37,31,15,3],
[37,31,15,5],
[37,31,15,6],
[37,31,15,7],
[37,31,15,8],
[37,31,15,10],
[37,31,15,11],
[37,31,15,12],
[37,31,15,13],
[37,31,15,14],
[37,31,16,0],
[37,31,16,1],
[37,31,16,2],
[37,31,16,3],
[37,31,16,5],
[37,31,16,6],
[37,31,16,7],
[37,31,16,8],
[37,31,16,9],
[37,31,16,10],
[37,31,16,11],
[37,31,16,12],
[37,31,16,13],
[37,31,16,14],
[37,31,16,15],
[37,31,17,0],
[37,31,17,1],
[37,31,17,2],
[37,31,17,3],
[37,31,17,5],
[37,31,17,6],
[37,31,17,7],
[37,31,17,8],
[37,31,17,9],
[37,31,17,10],
[37,31,17,11],
[37,31,17,12],
[37,31,17,13],
[37,31,17,14],
[37,31,17,15],
[37,31,17,16],
[37,31,18,0],
[37,31,18,1],
[37,31,18,2],
[37,31,18,3],
[37,31,18,5],
[37,31,18,6],
[37,31,18,7],
[37,31,18,8],
[37,31,18,10],
[37,31,18,11],
[37,31,18,12],
[37,31,18,13],
[37,31,18,14],
[37,31,18,16],
[37,31,18,17],
[37,31,19,0],
[37,31,19,1],
[37,31,19,2],
[37,31,19,3],
[37,31,19,5],
[37,31,19,6],
[37,31,19,7],
[37,31,19,8],
[37,31,19,9],
[37,31,19,10],
[37,31,19,11],
[37,31,19,12],
[37,31,19,15],
[37,31,19,16],
[37,31,19,17],
[37,31,19,18],
[37,31,20,0],
[37,31,20,1],
[37,31,20,2],
[37,31,20,3],
[37,31,20,5],
[37,31,20,6],
[37,31,20,7],
[37,31,20,8],
[37,31,20,9],
[37,31,20,11],
[37,31,20,12],
[37,31,20,13],
[37,31,20,14],
[37,31,20,15],
[37,31,20,16],
[37,31,20,17],
[37,31,20,18],
[37,31,20,19],
[37,31,21,0],
[37,31,21,1],
[37,31,21,2],
[37,31,21,7],
[37,31,21,9],
[37,31,21,10],
[37,31,21,11],
[37,31,21,12],
[37,31,21,13],
[37,31,21,14],
[37,31,21,15],
[37,31,21,16],
[37,31,21,17],
[37,31,21,18],
[37,31,21,19],
[37,31,21,20],
[37,31,22,0],
[37,31,22,1],
[37,31,22,2],
[37,31,22,3],
[37,31,22,5],
[37,31,22,6],
[37,31,22,7],
[37,31,22,8],
[37,31,22,9],
[37,31,22,11],
[37,31,22,12],
[37,31,22,13],
[37,31,22,14],
[37,31,22,15],
[37,31,22,16],
[37,31,22,17],
[37,31,22,18],
[37,31,22,19],
[37,31,22,21],
[37,31,24,0],
[37,31,24,1],
[37,31,24,2],
[37,31,24,3],
[37,31,24,5],
[37,31,24,6],
[37,31,24,7],
[37,31,24,8],
[37,31,24,9],
[37,31,24,10],
[37,31,24,11],
[37,31,24,12],
[37,31,24,13],
[37,31,24,14],
[37,31,24,15],
[37,31,24,17],
[37,31,24,18],
[37,31,24,19],
[37,31,24,20],
[37,31,24,21],
[37,31,24,22],
[37,31,25,0],
[37,31,25,1],
[37,31,25,2],
[37,31,25,3],
[37,31,25,5],
[37,31,25,6],
[37,31,25,8],
[37,31,25,9],
[37,31,25,10],
[37,31,25,11],
[37,31,25,12],
[37,31,25,13],
[37,31,25,14],
[37,31,25,15],
[37,31,25,16],
[37,31,25,17],
[37,31,25,18],
[37,31,25,19],
[37,31,25,20],
[37,31,25,21],
[37,31,25,22],
[37,31,25,24],
[37,31,26,0],
[37,31,26,1],
[37,31,26,2],
[37,31,26,3],
[37,31,26,5],
[37,31,26,6],
[37,31,26,7],
[37,31,26,8],
[37,31,26,10],
[37,31,26,11],
[37,31,26,12],
[37,31,26,13],
[37,31,26,14],
[37,31,26,16],
[37,31,26,17],
[37,31,26,19],
[37,31,26,20],
[37,31,26,21],
[37,31,26,22],
[37,31,26,24],
[37,31,26,25],
[37,31,27,0],
[37,31,27,1],
[37,31,27,2],
[37,31,27,3],
[37,31,27,5],
[37,31,27,6],
[37,31,27,7],
[37,31,27,8],
[37,31,27,9],
[37,31,27,11],
[37,31,27,12],
[37,31,27,13],
[37,31,27,14],
[37,31,27,15],
[37,31,27,16],
[37,31,27,17],
[37,31,27,18],
[37,31,27,19],
[37,31,27,21],
[37,31,27,24],
[37,31,27,25],
[37,31,27,26],
[37,31,28,0],
[37,31,28,1],
[37,31,28,2],
[37,31,28,3],
[37,31,28,5],
[37,31,28,6],
[37,31,28,8],
[37,31,28,9],
[37,31,28,10],
[37,31,28,11],
[37,31,28,12],
[37,31,28,13],
[37,31,28,14],
[37,31,28,15],
[37,31,28,16],
[37,31,28,17],
[37,31,28,18],
[37,31,28,19],
[37,31,28,20],
[37,31,28,21],
[37,31,28,22],
[37,31,28,24],
[37,31,28,26],
[37,31,28,27],
[37,31,29,0],
[37,31,29,1],
[37,31,29,2],
[37,31,29,3],
[37,31,29,5],
[37,31,29,6],
[37,31,29,7],
[37,31,29,8],
[37,31,29,9],
[37,31,29,10],
[37,31,29,13],
[37,31,29,14],
[37,31,29,15],
[37,31,29,16],
[37,31,29,17],
[37,31,29,18],
[37,31,29,19],
[37,31,29,20],
[37,31,29,21],
[37,31,29,22],
[37,31,29,24],
[37,31,29,25],
[37,31,29,26],
[37,31,29,27],
[37,31,29,28],
[37,32,3,0],
[37,32,3,1],
[37,32,3,2],
[37,32,5,0],
[37,32,5,1],
[37,32,5,2],
[37,32,6,0],
[37,32,6,1],
[37,32,6,2],
[37,32,7,0],
[37,32,7,1],
[37,32,7,2],
[37,32,7,3],
[37,32,7,5],
[37,32,7,6],
[37,32,8,0],
[37,32,8,1],
[37,32,8,2],
[37,32,8,7],
[37,32,9,0],
[37,32,9,1],
[37,32,9,2],
[37,32,9,3],
[37,32,9,5],
[37,32,9,6],
[37,32,9,7],
[37,32,9,8],
[37,32,10,0],
[37,32,10,1],
[37,32,10,2],
[37,32,10,3],
[37,32,10,5],
[37,32,10,6],
[37,32,10,7],
[37,32,10,8],
[37,32,10,9],
[37,32,13,0],
[37,32,13,1],
[37,32,13,2],
[37,32,13,3],
[37,32,13,5],
[37,32,13,6],
[37,32,13,7],
[37,32,13,8],
[37,32,13,9],
[37,32,13,10],
[37,32,14,0],
[37,32,14,1],
[37,32,14,2],
[37,32,14,3],
[37,32,14,5],
[37,32,14,6],
[37,32,14,7],
[37,32,14,8],
[37,32,14,9],
[37,32,14,10],
[37,32,15,0],
[37,32,15,1],
[37,32,15,2],
[37,32,15,3],
[37,32,15,5],
[37,32,15,6],
[37,32,15,7],
[37,32,15,8],
[37,32,15,10],
[37,32,15,13],
[37,32,15,14],
[37,32,16,0],
[37,32,16,1],
[37,32,16,2],
[37,32,16,3],
[37,32,16,5],
[37,32,16,6],
[37,32,16,7],
[37,32,16,8],
[37,32,16,9],
[37,32,16,10],
[37,32,16,13],
[37,32,16,14],
[37,32,16,15],
[37,32,17,0],
[37,32,17,1],
[37,32,17,2],
[37,32,17,3],
[37,32,17,5],
[37,32,17,6],
[37,32,17,7],
[37,32,17,8],
[37,32,17,9],
[37,32,17,10],
[37,32,17,13],
[37,32,17,14],
[37,32,17,15],
[37,32,17,16],
[37,32,18,0],
[37,32,18,1],
[37,32,18,2],
[37,32,18,3],
[37,32,18,5],
[37,32,18,6],
[37,32,18,7],
[37,32,18,8],
[37,32,18,10],
[37,32,18,13],
[37,32,18,14],
[37,32,18,16],
[37,32,18,17],
[37,32,19,0],
[37,32,19,1],
[37,32,19,2],
[37,32,19,3],
[37,32,19,5],
[37,32,19,6],
[37,32,19,7],
[37,32,19,8],
[37,32,19,9],
[37,32,19,10],
[37,32,19,15],
[37,32,19,16],
[37,32,19,17],
[37,32,19,18],
[37,32,20,0],
[37,32,20,1],
[37,32,20,2],
[37,32,20,3],
[37,32,20,5],
[37,32,20,6],
[37,32,20,7],
[37,32,20,8],
[37,32,20,9],
[37,32,20,13],
[37,32,20,14],
[37,32,20,15],
[37,32,20,16],
[37,32,20,17],
[37,32,20,18],
[37,32,20,19],
[37,32,21,0],
[37,32,21,1],
[37,32,21,2],
[37,32,21,7],
[37,32,21,9],
[37,32,21,10],
[37,32,21,13],
[37,32,21,14],
[37,32,21,15],
[37,32,21,16],
[37,32,21,17],
[37,32,21,18],
[37,32,21,19],
[37,32,21,20],
[37,32,22,0],
[37,32,22,1],
[37,32,22,2],
[37,32,22,3],
[37,32,22,5],
[37,32,22,6],
[37,32,22,7],
[37,32,22,8],
[37,32,22,9],
[37,32,22,13],
[37,32,22,14],
[37,32,22,15],
[37,32,22,16],
[37,32,22,17],
[37,32,22,18],
[37,32,22,19],
[37,32,22,21],
[37,32,23,0],
[37,32,23,1],
[37,32,23,2],
[37,32,23,3],
[37,32,23,5],
[37,32,23,6],
[37,32,23,7],
[37,32,23,8],
[37,32,23,9],
[37,32,23,10],
[37,32,23,13],
[37,32,23,14],
[37,32,23,15],
[37,32,23,16],
[37,32,23,17],
[37,32,23,18],
[37,32,23,19],
[37,32,23,20],
[37,32,23,21],
[37,32,23,22],
[37,32,24,0],
[37,32,24,1],
[37,32,24,2],
[37,32,24,3],
[37,32,24,5],
[37,32,24,6],
[37,32,24,7],
[37,32,24,8],
[37,32,24,9],
[37,32,24,10],
[37,32,24,13],
[37,32,24,14],
[37,32,24,15],
[37,32,24,17],
[37,32,24,18],
[37,32,24,19],
[37,32,24,20],
[37,32,24,21],
[37,32,24,22],
[37,32,24,23],
[37,32,25,0],
[37,32,25,1],
[37,32,25,2],
[37,32,25,3],
[37,32,25,5],
[37,32,25,6],
[37,32,25,8],
[37,32,25,9],
[37,32,25,10],
[37,32,25,13],
[37,32,25,14],
[37,32,25,15],
[37,32,25,16],
[37,32,25,17],
[37,32,25,18],
[37,32,25,19],
[37,32,25,20],
[37,32,25,21],
[37,32,25,22],
[37,32,25,23],
[37,32,25,24],
[37,32,26,0],
[37,32,26,1],
[37,32,26,2],
[37,32,26,3],
[37,32,26,5],
[37,32,26,6],
[37,32,26,7],
[37,32,26,8],
[37,32,26,10],
[37,32,26,13],
[37,32,26,14],
[37,32,26,16],
[37,32,26,17],
[37,32,26,19],
[37,32,26,20],
[37,32,26,21],
[37,32,26,22],
[37,32,26,23],
[37,32,26,24],
[37,32,26,25],
[37,32,27,0],
[37,32,27,1],
[37,32,27,2],
[37,32,27,3],
[37,32,27,5],
[37,32,27,6],
[37,32,27,7],
[37,32,27,8],
[37,32,27,9],
[37,32,27,13],
[37,32,27,14],
[37,32,27,15],
[37,32,27,16],
[37,32,27,17],
[37,32,27,18],
[37,32,27,19],
[37,32,27,21],
[37,32,27,23],
[37,32,27,24],
[37,32,27,25],
[37,32,27,26],
[37,32,28,0],
[37,32,28,1],
[37,32,28,2],
[37,32,28,3],
[37,32,28,5],
[37,32,28,6],
[37,32,28,8],
[37,32,28,9],
[37,32,28,10],
[37,32,28,13],
[37,32,28,14],
[37,32,28,15],
[37,32,28,16],
[37,32,28,17],
[37,32,28,18],
[37,32,28,19],
[37,32,28,20],
[37,32,28,21],
[37,32,28,22],
[37,32,28,23],
[37,32,28,24],
[37,32,28,26],
[37,32,28,27],
[37,32,30,0],
[37,32,30,1],
[37,32,30,2],
[37,32,30,3],
[37,32,30,5],
[37,32,30,6],
[37,32,30,7],
[37,32,30,8],
[37,32,30,9],
[37,32,30,10],
[37,32,30,13],
[37,32,30,14],
[37,32,30,15],
[37,32,30,16],
[37,32,30,17],
[37,32,30,18],
[37,32,30,19],
[37,32,30,20],
[37,32,30,21],
[37,32,30,22],
[37,32,30,24],
[37,32,30,25],
[37,32,30,26],
[37,32,30,27],
[37,32,31,0],
[37,32,31,1],
[37,32,31,2],
[37,32,31,3],
[37,32,31,5],
[37,32,31,6],
[37,32,31,7],
[37,32,31,8],
[37,32,31,9],
[37,32,31,10],
[37,32,31,13],
[37,32,31,14],
[37,32,31,15],
[37,32,31,16],
[37,32,31,17],
[37,32,31,18],
[37,32,31,19],
[37,32,31,20],
[37,32,31,21],
[37,32,31,22],
[37,32,31,24],
[37,32,31,25],
[37,32,31,26],
[37,32,31,28],
[37,33,3,0],
[37,33,3,1],
[37,33,3,2],
[37,33,5,0],
[37,33,5,1],
[37,33,5,2],
[37,33,6,0],
[37,33,6,1],
[37,33,6,2],
[37,33,7,0],
[37,33,7,1],
[37,33,7,2],
[37,33,7,3],
[37,33,7,5],
[37,33,7,6],
[37,33,8,0],
[37,33,8,1],
[37,33,8,2],
[37,33,8,7],
[37,33,9,0],
[37,33,9,1],
[37,33,9,2],
[37,33,9,3],
[37,33,9,5],
[37,33,9,6],
[37,33,9,7],
[37,33,9,8],
[37,33,10,0],
[37,33,10,1],
[37,33,10,2],
[37,33,10,3],
[37,33,10,5],
[37,33,10,6],
[37,33,10,7],
[37,33,10,8],
[37,33,10,9],
[37,33,13,0],
[37,33,13,1],
[37,33,13,2],
[37,33,13,3],
[37,33,13,5],
[37,33,13,6],
[37,33,13,7],
[37,33,13,8],
[37,33,13,9],
[37,33,13,10],
[37,33,14,0],
[37,33,14,1],
[37,33,14,2],
[37,33,14,3],
[37,33,14,5],
[37,33,14,6],
[37,33,14,7],
[37,33,14,8],
[37,33,14,9],
[37,33,14,10],
[37,33,15,0],
[37,33,15,1],
[37,33,15,2],
[37,33,15,3],
[37,33,15,5],
[37,33,15,6],
[37,33,15,7],
[37,33,15,8],
[37,33,15,10],
[37,33,15,13],
[37,33,15,14],
[37,33,16,0],
[37,33,16,1],
[37,33,16,2],
[37,33,16,3],
[37,33,16,5],
[37,33,16,6],
[37,33,16,7],
[37,33,16,8],
[37,33,16,9],
[37,33,16,10],
[37,33,16,13],
[37,33,16,14],
[37,33,16,15],
[37,33,17,0],
[37,33,17,1],
[37,33,17,2],
[37,33,17,3],
[37,33,17,5],
[37,33,17,6],
[37,33,17,7],
[37,33,17,8],
[37,33,17,9],
[37,33,17,10],
[37,33,17,13],
[37,33,17,14],
[37,33,17,15],
[37,33,17,16],
[37,33,18,0],
[37,33,18,1],
[37,33,18,2],
[37,33,18,3],
[37,33,18,5],
[37,33,18,6],
[37,33,18,7],
[37,33,18,8],
[37,33,18,10],
[37,33,18,13],
[37,33,18,14],
[37,33,18,16],
[37,33,18,17],
[37,33,19,0],
[37,33,19,1],
[37,33,19,2],
[37,33,19,3],
[37,33,19,5],
[37,33,19,6],
[37,33,19,7],
[37,33,19,8],
[37,33,19,9],
[37,33,19,10],
[37,33,19,15],
[37,33,19,16],
[37,33,19,17],
[37,33,19,18],
[37,33,20,0],
[37,33,20,1],
[37,33,20,2],
[37,33,20,3],
[37,33,20,5],
[37,33,20,6],
[37,33,20,7],
[37,33,20,8],
[37,33,20,9],
[37,33,20,13],
[37,33,20,14],
[37,33,20,15],
[37,33,20,16],
[37,33,20,17],
[37,33,20,18],
[37,33,20,19],
[37,33,21,0],
[37,33,21,1],
[37,33,21,2],
[37,33,21,7],
[37,33,21,9],
[37,33,21,10],
[37,33,21,13],
[37,33,21,14],
[37,33,21,15],
[37,33,21,16],
[37,33,21,17],
[37,33,21,18],
[37,33,21,19],
[37,33,21,20],
[37,33,22,0],
[37,33,22,1],
[37,33,22,2],
[37,33,22,3],
[37,33,22,5],
[37,33,22,6],
[37,33,22,7],
[37,33,22,8],
[37,33,22,9],
[37,33,22,13],
[37,33,22,14],
[37,33,22,15],
[37,33,22,16],
[37,33,22,17],
[37,33,22,18],
[37,33,22,19],
[37,33,22,21],
[37,33,23,0],
[37,33,23,1],
[37,33,23,2],
[37,33,23,3],
[37,33,23,5],
[37,33,23,6],
[37,33,23,7],
[37,33,23,8],
[37,33,23,9],
[37,33,23,10],
[37,33,23,13],
[37,33,23,14],
[37,33,23,15],
[37,33,23,16],
[37,33,23,17],
[37,33,23,18],
[37,33,23,19],
[37,33,23,20],
[37,33,23,21],
[37,33,23,22],
[37,33,24,0],
[37,33,24,1],
[37,33,24,2],
[37,33,24,3],
[37,33,24,5],
[37,33,24,6],
[37,33,24,7],
[37,33,24,8],
[37,33,24,9],
[37,33,24,10],
[37,33,24,13],
[37,33,24,14],
[37,33,24,15],
[37,33,24,17],
[37,33,24,18],
[37,33,24,19],
[37,33,24,20],
[37,33,24,21],
[37,33,24,22],
[37,33,24,23],
[37,33,25,0],
[37,33,25,1],
[37,33,25,2],
[37,33,25,3],
[37,33,25,5],
[37,33,25,6],
[37,33,25,8],
[37,33,25,9],
[37,33,25,10],
[37,33,25,13],
[37,33,25,14],
[37,33,25,15],
[37,33,25,16],
[37,33,25,17],
[37,33,25,18],
[37,33,25,19],
[37,33,25,20],
[37,33,25,21],
[37,33,25,22],
[37,33,25,23],
[37,33,25,24],
[37,33,26,0],
[37,33,26,1],
[37,33,26,2],
[37,33,26,3],
[37,33,26,5],
[37,33,26,6],
[37,33,26,7],
[37,33,26,8],
[37,33,26,10],
[37,33,26,13],
[37,33,26,14],
[37,33,26,16],
[37,33,26,17],
[37,33,26,19],
[37,33,26,20],
[37,33,26,21],
[37,33,26,22],
[37,33,26,23],
[37,33,26,24],
[37,33,26,25],
[37,33,27,0],
[37,33,27,1],
[37,33,27,2],
[37,33,27,3],
[37,33,27,5],
[37,33,27,6],
[37,33,27,7],
[37,33,27,8],
[37,33,27,9],
[37,33,27,13],
[37,33,27,14],
[37,33,27,15],
[37,33,27,16],
[37,33,27,17],
[37,33,27,18],
[37,33,27,19],
[37,33,27,21],
[37,33,27,23],
[37,33,27,24],
[37,33,27,25],
[37,33,27,26],
[37,33,28,0],
[37,33,28,1],
[37,33,28,2],
[37,33,28,3],
[37,33,28,5],
[37,33,28,6],
[37,33,28,8],
[37,33,28,9],
[37,33,28,10],
[37,33,28,13],
[37,33,28,14],
[37,33,28,15],
[37,33,28,16],
[37,33,28,17],
[37,33,28,18],
[37,33,28,19],
[37,33,28,20],
[37,33,28,21],
[37,33,28,22],
[37,33,28,23],
[37,33,28,24],
[37,33,28,26],
[37,33,28,27],
[37,33,30,0],
[37,33,30,1],
[37,33,30,2],
[37,33,30,3],
[37,33,30,5],
[37,33,30,6],
[37,33,30,7],
[37,33,30,8],
[37,33,30,9],
[37,33,30,10],
[37,33,30,13],
[37,33,30,14],
[37,33,30,15],
[37,33,30,16],
[37,33,30,17],
[37,33,30,18],
[37,33,30,19],
[37,33,30,20],
[37,33,30,21],
[37,33,30,22],
[37,33,30,24],
[37,33,30,25],
[37,33,30,26],
[37,33,30,28],
[37,33,31,0],
[37,33,31,1],
[37,33,31,2],
[37,33,31,3],
[37,33,31,5],
[37,33,31,6],
[37,33,31,7],
[37,33,31,8],
[37,33,31,9],
[37,33,31,10],
[37,33,31,13],
[37,33,31,14],
[37,33,31,15],
[37,33,31,16],
[37,33,31,17],
[37,33,31,18],
[37,33,31,19],
[37,33,31,20],
[37,33,31,21],
[37,33,31,22],
[37,33,31,24],
[37,33,31,25],
[37,33,31,27],
[37,33,31,28],
[37,34,3,0],
[37,34,3,1],
[37,34,3,2],
[37,34,5,0],
[37,34,5,1],
[37,34,5,2],
[37,34,6,0],
[37,34,6,1],
[37,34,6,2],
[37,34,8,0],
[37,34,8,1],
[37,34,8,2],
[37,34,9,0],
[37,34,9,1],
[37,34,9,2],
[37,34,9,3],
[37,34,9,5],
[37,34,9,6],
[37,34,9,8],
[37,34,10,0],
[37,34,10,1],
[37,34,10,2],
[37,34,10,3],
[37,34,10,5],
[37,34,10,6],
[37,34,10,8],
[37,34,10,9],
[37,34,11,0],
[37,34,11,1],
[37,34,11,2],
[37,34,11,3],
[37,34,11,5],
[37,34,11,6],
[37,34,11,8],
[37,34,11,9],
[37,34,11,10],
[37,34,12,0],
[37,34,12,1],
[37,34,12,2],
[37,34,12,3],
[37,34,12,5],
[37,34,12,6],
[37,34,12,8],
[37,34,12,9],
[37,34,12,10],
[37,34,13,0],
[37,34,13,1],
[37,34,13,2],
[37,34,13,3],
[37,34,13,5],
[37,34,13,6],
[37,34,13,8],
[37,34,13,9],
[37,34,13,10],
[37,34,13,11],
[37,34,13,12],
[37,34,14,0],
[37,34,14,1],
[37,34,14,2],
[37,34,14,3],
[37,34,14,5],
[37,34,14,6],
[37,34,14,8],
[37,34,14,9],
[37,34,14,10],
[37,34,14,11],
[37,34,14,12],
[37,34,15,0],
[37,34,15,1],
[37,34,15,2],
[37,34,15,3],
[37,34,15,5],
[37,34,15,6],
[37,34,15,8],
[37,34,15,10],
[37,34,15,11],
[37,34,15,12],
[37,34,15,13],
[37,34,15,14],
[37,34,16,0],
[37,34,16,1],
[37,34,16,2],
[37,34,16,3],
[37,34,16,5],
[37,34,16,6],
[37,34,16,8],
[37,34,16,9],
[37,34,16,10],
[37,34,16,11],
[37,34,16,12],
[37,34,16,13],
[37,34,16,14],
[37,34,16,15],
[37,34,17,0],
[37,34,17,1],
[37,34,17,2],
[37,34,17,3],
[37,34,17,5],
[37,34,17,6],
[37,34,17,8],
[37,34,17,9],
[37,34,17,10],
[37,34,17,11],
[37,34,17,12],
[37,34,17,13],
[37,34,17,14],
[37,34,17,15],
[37,34,17,16],
[37,34,18,0],
[37,34,18,1],
[37,34,18,2],
[37,34,18,3],
[37,34,18,5],
[37,34,18,6],
[37,34,18,8],
[37,34,18,10],
[37,34,18,11],
[37,34,18,12],
[37,34,18,13],
[37,34,18,14],
[37,34,18,16],
[37,34,18,17],
[37,34,19,0],
[37,34,19,1],
[37,34,19,2],
[37,34,19,3],
[37,34,19,5],
[37,34,19,6],
[37,34,19,8],
[37,34,19,9],
[37,34,19,10],
[37,34,19,11],
[37,34,19,12],
[37,34,19,15],
[37,34,19,16],
[37,34,19,17],
[37,34,19,18],
[37,34,20,0],
[37,34,20,1],
[37,34,20,2],
[37,34,20,3],
[37,34,20,5],
[37,34,20,6],
[37,34,20,8],
[37,34,20,9],
[37,34,20,11],
[37,34,20,12],
[37,34,20,13],
[37,34,20,14],
[37,34,20,15],
[37,34,20,16],
[37,34,20,17],
[37,34,20,18],
[37,34,20,19],
[37,34,21,0],
[37,34,21,1],
[37,34,21,2],
[37,34,21,9],
[37,34,21,10],
[37,34,21,11],
[37,34,21,12],
[37,34,21,13],
[37,34,21,14],
[37,34,21,15],
[37,34,21,16],
[37,34,21,17],
[37,34,21,18],
[37,34,21,19],
[37,34,21,20],
[37,34,22,0],
[37,34,22,1],
[37,34,22,2],
[37,34,22,3],
[37,34,22,5],
[37,34,22,6],
[37,34,22,8],
[37,34,22,9],
[37,34,22,11],
[37,34,22,12],
[37,34,22,13],
[37,34,22,14],
[37,34,22,15],
[37,34,22,16],
[37,34,22,17],
[37,34,22,18],
[37,34,22,19],
[37,34,22,21],
[37,34,23,0],
[37,34,23,1],
[37,34,23,2],
[37,34,23,3],
[37,34,23,5],
[37,34,23,6],
[37,34,23,8],
[37,34,23,9],
[37,34,23,10],
[37,34,23,11],
[37,34,23,12],
[37,34,23,13],
[37,34,23,14],
[37,34,23,15],
[37,34,23,16],
[37,34,23,17],
[37,34,23,18],
[37,34,23,19],
[37,34,23,20],
[37,34,23,21],
[37,34,23,22],
[37,34,24,0],
[37,34,24,1],
[37,34,24,2],
[37,34,24,3],
[37,34,24,5],
[37,34,24,6],
[37,34,24,8],
[37,34,24,9],
[37,34,24,10],
[37,34,24,11],
[37,34,24,12],
[37,34,24,13],
[37,34,24,14],
[37,34,24,15],
[37,34,24,17],
[37,34,24,18],
[37,34,24,19],
[37,34,24,20],
[37,34,24,21],
[37,34,24,22],
[37,34,24,23],
[37,34,26,0],
[37,34,26,1],
[37,34,26,2],
[37,34,26,3],
[37,34,26,5],
[37,34,26,6],
[37,34,26,8],
[37,34,26,10],
[37,34,26,11],
[37,34,26,12],
[37,34,26,13],
[37,34,26,14],
[37,34,26,16],
[37,34,26,17],
[37,34,26,19],
[37,34,26,20],
[37,34,26,21],
[37,34,26,22],
[37,34,26,23],
[37,34,26,24],
[37,34,27,0],
[37,34,27,1],
[37,34,27,2],
[37,34,27,3],
[37,34,27,5],
[37,34,27,6],
[37,34,27,8],
[37,34,27,9],
[37,34,27,11],
[37,34,27,12],
[37,34,27,13],
[37,34,27,14],
[37,34,27,15],
[37,34,27,16],
[37,34,27,17],
[37,34,27,18],
[37,34,27,19],
[37,34,27,21],
[37,34,27,23],
[37,34,27,24],
[37,34,27,26],
[37,34,29,0],
[37,34,29,1],
[37,34,29,2],
[37,34,29,3],
[37,34,29,5],
[37,34,29,6],
[37,34,29,8],
[37,34,29,9],
[37,34,29,10],
[37,34,29,13],
[37,34,29,14],
[37,34,29,15],
[37,34,29,16],
[37,34,29,17],
[37,34,29,18],
[37,34,29,19],
[37,34,29,20],
[37,34,29,21],
[37,34,29,22],
[37,34,29,23],
[37,34,29,24],
[37,34,29,26],
[37,34,30,0],
[37,34,30,1],
[37,34,30,2],
[37,34,30,3],
[37,34,30,5],
[37,34,30,6],
[37,34,30,8],
[37,34,30,9],
[37,34,30,10],
[37,34,30,11],
[37,34,30,12],
[37,34,30,13],
[37,34,30,14],
[37,34,30,15],
[37,34,30,16],
[37,34,30,17],
[37,34,30,18],
[37,34,30,19],
[37,34,30,20],
[37,34,30,21],
[37,34,30,22],
[37,34,30,24],
[37,34,30,27],
[37,34,30,29],
[37,34,31,0],
[37,34,31,1],
[37,34,31,2],
[37,34,31,3],
[37,34,31,5],
[37,34,31,6],
[37,34,31,8],
[37,34,31,9],
[37,34,31,10],
[37,34,31,11],
[37,34,31,12],
[37,34,31,13],
[37,34,31,14],
[37,34,31,15],
[37,34,31,16],
[37,34,31,17],
[37,34,31,18],
[37,34,31,19],
[37,34,31,20],
[37,34,31,21],
[37,34,31,22],
[37,34,31,24],
[37,34,31,26],
[37,34,31,27],
[37,34,31,29],
[37,34,32,0],
[37,34,32,1],
[37,34,32,2],
[37,34,32,3],
[37,34,32,5],
[37,34,32,6],
[37,34,32,8],
[37,34,32,9],
[37,34,32,10],
[37,34,32,13],
[37,34,32,14],
[37,34,32,15],
[37,34,32,16],
[37,34,32,17],
[37,34,32,18],
[37,34,32,19],
[37,34,32,20],
[37,34,32,21],
[37,34,32,22],
[37,34,32,23],
[37,34,32,24],
[37,34,32,26],
[37,34,32,27],
[37,34,32,30],
[37,34,32,31],
[37,34,33,0],
[37,34,33,1],
[37,34,33,2],
[37,34,33,3],
[37,34,33,5],
[37,34,33,6],
[37,34,33,8],
[37,34,33,9],
[37,34,33,10],
[37,34,33,13],
[37,34,33,14],
[37,34,33,15],
[37,34,33,16],
[37,34,33,17],
[37,34,33,18],
[37,34,33,19],
[37,34,33,20],
[37,34,33,21],
[37,34,33,22],
[37,34,33,26],
[37,34,33,27],
[37,34,33,30],
[37,34,33,31],
[37,35,3,0],
[37,35,3,1],
[37,35,3,2],
[37,35,5,0],
[37,35,5,1],
[37,35,5,2],
[37,35,6,0],
[37,35,6,1],
[37,35,6,2],
[37,35,7,0],
[37,35,7,1],
[37,35,7,2],
[37,35,7,3],
[37,35,7,5],
[37,35,7,6],
[37,35,8,0],
[37,35,8,1],
[37,35,8,2],
[37,35,8,7],
[37,35,10,0],
[37,35,10,1],
[37,35,10,2],
[37,35,10,3],
[37,35,10,5],
[37,35,10,6],
[37,35,10,7],
[37,35,10,8],
[37,35,11,0],
[37,35,11,1],
[37,35,11,2],
[37,35,11,3],
[37,35,11,5],
[37,35,11,6],
[37,35,11,7],
[37,35,11,8],
[37,35,11,10],
[37,35,12,0],
[37,35,12,1],
[37,35,12,2],
[37,35,12,3],
[37,35,12,5],
[37,35,12,6],
[37,35,12,7],
[37,35,12,8],
[37,35,12,10],
[37,35,13,0],
[37,35,13,1],
[37,35,13,2],
[37,35,13,3],
[37,35,13,5],
[37,35,13,6],
[37,35,13,7],
[37,35,13,8],
[37,35,13,10],
[37,35,13,11],
[37,35,13,12],
[37,35,14,0],
[37,35,14,1],
[37,35,14,2],
[37,35,14,3],
[37,35,14,5],
[37,35,14,6],
[37,35,14,7],
[37,35,14,8],
[37,35,14,10],
[37,35,14,11],
[37,35,14,12],
[37,35,16,0],
[37,35,16,1],
[37,35,16,2],
[37,35,16,3],
[37,35,16,5],
[37,35,16,6],
[37,35,16,7],
[37,35,16,8],
[37,35,16,10],
[37,35,16,11],
[37,35,16,12],
[37,35,16,13],
[37,35,16,14],
[37,35,17,0],
[37,35,17,1],
[37,35,17,2],
[37,35,17,3],
[37,35,17,5],
[37,35,17,6],
[37,35,17,7],
[37,35,17,8],
[37,35,17,10],
[37,35,17,11],
[37,35,17,12],
[37,35,17,13],
[37,35,17,14],
[37,35,17,16],
[37,35,19,0],
[37,35,19,1],
[37,35,19,2],
[37,35,19,3],
[37,35,19,5],
[37,35,19,6],
[37,35,19,7],
[37,35,19,8],
[37,35,19,10],
[37,35,19,11],
[37,35,19,12],
[37,35,19,16],
[37,35,19,17],
[37,35,20,0],
[37,35,20,1],
[37,35,20,2],
[37,35,20,3],
[37,35,20,5],
[37,35,20,6],
[37,35,20,7],
[37,35,20,8],
[37,35,20,11],
[37,35,20,12],
[37,35,20,13],
[37,35,20,14],
[37,35,20,16],
[37,35,20,17],
[37,35,20,19],
[37,35,21,0],
[37,35,21,1],
[37,35,21,2],
[37,35,21,7],
[37,35,21,10],
[37,35,21,11],
[37,35,21,12],
[37,35,21,13],
[37,35,21,14],
[37,35,21,16],
[37,35,21,17],
[37,35,21,19],
[37,35,21,20],
[37,35,22,0],
[37,35,22,1],
[37,35,22,2],
[37,35,22,3],
[37,35,22,5],
[37,35,22,6],
[37,35,22,7],
[37,35,22,8],
[37,35,22,11],
[37,35,22,12],
[37,35,22,13],
[37,35,22,14],
[37,35,22,16],
[37,35,22,17],
[37,35,22,19],
[37,35,22,21],
[37,35,23,0],
[37,35,23,1],
[37,35,23,2],
[37,35,23,3],
[37,35,23,5],
[37,35,23,6],
[37,35,23,7],
[37,35,23,8],
[37,35,23,10],
[37,35,23,11],
[37,35,23,12],
[37,35,23,13],
[37,35,23,14],
[37,35,23,16],
[37,35,23,17],
[37,35,23,19],
[37,35,23,20],
[37,35,23,21],
[37,35,23,22],
[37,35,24,0],
[37,35,24,1],
[37,35,24,2],
[37,35,24,3],
[37,35,24,5],
[37,35,24,6],
[37,35,24,7],
[37,35,24,8],
[37,35,24,10],
[37,35,24,11],
[37,35,24,12],
[37,35,24,13],
[37,35,24,14],
[37,35,24,17],
[37,35,24,19],
[37,35,24,20],
[37,35,24,21],
[37,35,24,22],
[37,35,24,23],
[37,35,25,0],
[37,35,25,1],
[37,35,25,2],
[37,35,25,3],
[37,35,25,5],
[37,35,25,6],
[37,35,25,8],
[37,35,25,10],
[37,35,25,11],
[37,35,25,12],
[37,35,25,13],
[37,35,25,14],
[37,35,25,16],
[37,35,25,17],
[37,35,25,19],
[37,35,25,20],
[37,35,25,21],
[37,35,25,22],
[37,35,25,23],
[37,35,25,24],
[37,35,27,0],
[37,35,27,1],
[37,35,27,2],
[37,35,27,3],
[37,35,27,5],
[37,35,27,6],
[37,35,27,7],
[37,35,27,8],
[37,35,27,11],
[37,35,27,12],
[37,35,27,13],
[37,35,27,14],
[37,35,27,16],
[37,35,27,17],
[37,35,27,19],
[37,35,27,21],
[37,35,27,23],
[37,35,27,24],
[37,35,27,25],
[37,35,28,0],
[37,35,28,1],
[37,35,28,2],
[37,35,28,3],
[37,35,28,5],
[37,35,28,6],
[37,35,28,8],
[37,35,28,10],
[37,35,28,11],
[37,35,28,12],
[37,35,28,13],
[37,35,28,14],
[37,35,28,16],
[37,35,28,17],
[37,35,28,19],
[37,35,28,20],
[37,35,28,21],
[37,35,28,22],
[37,35,28,23],
[37,35,28,24],
[37,35,29,0],
[37,35,29,1],
[37,35,29,2],
[37,35,29,3],
[37,35,29,5],
[37,35,29,6],
[37,35,29,7],
[37,35,29,8],
[37,35,29,10],
[37,35,29,13],
[37,35,29,14],
[37,35,29,16],
[37,35,29,17],
[37,35,29,19],
[37,35,29,20],
[37,35,29,21],
[37,35,29,22],
[37,35,29,23],
[37,35,29,24],
[37,35,29,25],
[37,35,29,27],
[37,35,29,28],
[37,35,30,0],
[37,35,30,1],
[37,35,30,2],
[37,35,30,3],
[37,35,30,5],
[37,35,30,6],
[37,35,30,7],
[37,35,30,8],
[37,35,30,10],
[37,35,30,11],
[37,35,30,12],
[37,35,30,13],
[37,35,30,14],
[37,35,30,16],
[37,35,30,17],
[37,35,30,19],
[37,35,30,20],
[37,35,30,21],
[37,35,30,22],
[37,35,30,24],
[37,35,30,27],
[37,35,30,28],
[37,35,30,29],
[37,35,31,0],
[37,35,31,1],
[37,35,31,2],
[37,35,31,3],
[37,35,31,5],
[37,35,31,6],
[37,35,31,7],
[37,35,31,8],
[37,35,31,10],
[37,35,31,11],
[37,35,31,12],
[37,35,31,13],
[37,35,31,14],
[37,35,31,16],
[37,35,31,17],
[37,35,31,19],
[37,35,31,20],
[37,35,31,21],
[37,35,31,22],
[37,35,31,24],
[37,35,31,25],
[37,35,31,27],
[37,35,31,28],
[37,35,31,29],
[37,35,32,0],
[37,35,32,1],
[37,35,32,2],
[37,35,32,3],
[37,35,32,5],
[37,35,32,6],
[37,35,32,7],
[37,35,32,8],
[37,35,32,10],
[37,35,32,13],
[37,35,32,14],
[37,35,32,16],
[37,35,32,17],
[37,35,32,19],
[37,35,32,20],
[37,35,32,21],
[37,35,32,22],
[37,35,32,25],
[37,35,32,27],
[37,35,32,28],
[37,35,32,30],
[37,35,32,31],
[37,35,33,0],
[37,35,33,1],
[37,35,33,2],
[37,35,33,3],
[37,35,33,5],
[37,35,33,6],
[37,35,33,7],
[37,35,33,8],
[37,35,33,10],
[37,35,33,13],
[37,35,33,14],
[37,35,33,16],
[37,35,33,17],
[37,35,33,19],
[37,35,33,20],
[37,35,33,21],
[37,35,33,23],
[37,35,33,24],
[37,35,33,25],
[37,35,33,27],
[37,35,33,28],
[37,35,33,30],
[37,35,33,31],
[37,35,34,0],
[37,35,34,1],
[37,35,34,2],
[37,35,34,3],
[37,35,34,5],
[37,35,34,6],
[37,35,34,8],
[37,35,34,10],
[37,35,34,11],
[37,35,34,12],
[37,35,34,13],
[37,35,34,14],
[37,35,34,16],
[37,35,34,17],
[37,35,34,19],
[37,35,34,20],
[37,35,34,22],
[37,35,34,23],
[37,35,34,24],
[37,35,34,27],
[37,35,34,29],
[37,35,34,30],
[37,35,34,31],
[37,35,34,32],
[37,35,34,33],
[37,36,3,0],
[37,36,3,1],
[37,36,3,2],
[37,36,5,0],
[37,36,5,1],
[37,36,5,2],
[37,36,6,0],
[37,36,6,1],
[37,36,6,2],
[37,36,7,0],
[37,36,7,1],
[37,36,7,2],
[37,36,7,3],
[37,36,7,5],
[37,36,7,6],
[37,36,8,0],
[37,36,8,1],
[37,36,8,2],
[37,36,8,7],
[37,36,9,0],
[37,36,9,1],
[37,36,9,2],
[37,36,9,3],
[37,36,9,5],
[37,36,9,6],
[37,36,9,7],
[37,36,9,8],
[37,36,10,0],
[37,36,10,1],
[37,36,10,2],
[37,36,10,3],
[37,36,10,5],
[37,36,10,6],
[37,36,10,7],
[37,36,10,8],
[37,36,10,9],
[37,36,11,0],
[37,36,11,1],
[37,36,11,2],
[37,36,11,3],
[37,36,11,5],
[37,36,11,6],
[37,36,11,7],
[37,36,11,8],
[37,36,11,9],
[37,36,11,10],
[37,36,12,0],
[37,36,12,1],
[37,36,12,2],
[37,36,12,3],
[37,36,12,5],
[37,36,12,6],
[37,36,12,7],
[37,36,12,8],
[37,36,12,9],
[37,36,12,10],
[37,36,15,0],
[37,36,15,1],
[37,36,15,2],
[37,36,15,3],
[37,36,15,5],
[37,36,15,6],
[37,36,15,7],
[37,36,15,8],
[37,36,15,10],
[37,36,15,11],
[37,36,15,12],
[37,36,16,0],
[37,36,16,1],
[37,36,16,2],
[37,36,16,3],
[37,36,16,5],
[37,36,16,6],
[37,36,16,7],
[37,36,16,8],
[37,36,16,9],
[37,36,16,10],
[37,36,16,11],
[37,36,16,12],
[37,36,16,15],
[37,36,17,0],
[37,36,17,1],
[37,36,17,2],
[37,36,17,3],
[37,36,17,5],
[37,36,17,6],
[37,36,17,7],
[37,36,17,8],
[37,36,17,9],
[37,36,17,10],
[37,36,17,11],
[37,36,17,12],
[37,36,17,15],
[37,36,17,16],
[37,36,18,0],
[37,36,18,1],
[37,36,18,2],
[37,36,18,3],
[37,36,18,5],
[37,36,18,6],
[37,36,18,7],
[37,36,18,8],
[37,36,18,10],
[37,36,18,11],
[37,36,18,12],
[37,36,18,16],
[37,36,18,17],
[37,36,20,0],
[37,36,20,1],
[37,36,20,2],
[37,36,20,3],
[37,36,20,5],
[37,36,20,6],
[37,36,20,7],
[37,36,20,8],
[37,36,20,9],
[37,36,20,11],
[37,36,20,12],
[37,36,20,15],
[37,36,20,16],
[37,36,20,17],
[37,36,20,18],
[37,36,21,0],
[37,36,21,1],
[37,36,21,2],
[37,36,21,7],
[37,36,21,9],
[37,36,21,10],
[37,36,21,11],
[37,36,21,12],
[37,36,21,15],
[37,36,21,16],
[37,36,21,17],
[37,36,21,18],
[37,36,21,20],
[37,36,22,0],
[37,36,22,1],
[37,36,22,2],
[37,36,22,3],
[37,36,22,5],
[37,36,22,6],
[37,36,22,7],
[37,36,22,8],
[37,36,22,9],
[37,36,22,11],
[37,36,22,12],
[37,36,22,15],
[37,36,22,16],
[37,36,22,17],
[37,36,22,18],
[37,36,22,21],
[37,36,23,0],
[37,36,23,1],
[37,36,23,2],
[37,36,23,3],
[37,36,23,5],
[37,36,23,6],
[37,36,23,7],
[37,36,23,8],
[37,36,23,9],
[37,36,23,10],
[37,36,23,11],
[37,36,23,12],
[37,36,23,15],
[37,36,23,16],
[37,36,23,17],
[37,36,23,18],
[37,36,23,20],
[37,36,23,21],
[37,36,23,22],
[37,36,24,0],
[37,36,24,1],
[37,36,24,2],
[37,36,24,3],
[37,36,24,5],
[37,36,24,6],
[37,36,24,7],
[37,36,24,8],
[37,36,24,9],
[37,36,24,10],
[37,36,24,11],
[37,36,24,12],
[37,36,24,15],
[37,36,24,17],
[37,36,24,18],
[37,36,24,20],
[37,36,24,21],
[37,36,24,22],
[37,36,24,23],
[37,36,25,0],
[37,36,25,1],
[37,36,25,2],
[37,36,25,3],
[37,36,25,5],
[37,36,25,6],
[37,36,25,8],
[37,36,25,9],
[37,36,25,10],
[37,36,25,11],
[37,36,25,12],
[37,36,25,15],
[37,36,25,16],
[37,36,25,17],
[37,36,25,18],
[37,36,25,20],
[37,36,25,21],
[37,36,25,22],
[37,36,25,23],
[37,36,25,24],
[37,36,26,0],
[37,36,26,1],
[37,36,26,2],
[37,36,26,3],
[37,36,26,5],
[37,36,26,6],
[37,36,26,7],
[37,36,26,8],
[37,36,26,10],
[37,36,26,11],
[37,36,26,12],
[37,36,26,16],
[37,36,26,17],
[37,36,26,20],
[37,36,26,21],
[37,36,26,22],
[37,36,26,23],
[37,36,26,24],
[37,36,26,25],
[37,36,27,0],
[37,36,27,1],
[37,36,27,2],
[37,36,27,3],
[37,36,27,5],
[37,36,27,6],
[37,36,27,7],
[37,36,27,8],
[37,36,27,9],
[37,36,27,11],
[37,36,27,12],
[37,36,27,15],
[37,36,27,16],
[37,36,27,17],
[37,36,27,18],
[37,36,27,21],
[37,36,27,23],
[37,36,27,24],
[37,36,27,25],
[37,36,27,26],
[37,36,28,0],
[37,36,28,1],
[37,36,28,2],
[37,36,28,3],
[37,36,28,5],
[37,36,28,6],
[37,36,28,8],
[37,36,28,9],
[37,36,28,10],
[37,36,28,11],
[37,36,28,12],
[37,36,28,15],
[37,36,28,16],
[37,36,28,17],
[37,36,28,18],
[37,36,28,20],
[37,36,28,21],
[37,36,28,22],
[37,36,28,23],
[37,36,28,24],
[37,36,28,27],
[37,36,29,0],
[37,36,29,1],
[37,36,29,2],
[37,36,29,3],
[37,36,29,5],
[37,36,29,6],
[37,36,29,7],
[37,36,29,8],
[37,36,29,9],
[37,36,29,10],
[37,36,29,15],
[37,36,29,16],
[37,36,29,17],
[37,36,29,18],
[37,36,29,20],
[37,36,29,21],
[37,36,29,22],
[37,36,29,23],
[37,36,29,24],
[37,36,29,26],
[37,36,29,27],
[37,36,29,28],
[37,36,30,0],
[37,36,30,1],
[37,36,30,2],
[37,36,30,3],
[37,36,30,5],
[37,36,30,6],
[37,36,30,7],
[37,36,30,8],
[37,36,30,9],
[37,36,30,10],
[37,36,30,11],
[37,36,30,12],
[37,36,30,15],
[37,36,30,16],
[37,36,30,17],
[37,36,30,18],
[37,36,30,20],
[37,36,30,21],
[37,36,30,22],
[37,36,30,24],
[37,36,30,25],
[37,36,30,26],
[37,36,30,27],
[37,36,30,28],
[37,36,30,29],
[37,36,31,0],
[37,36,31,1],
[37,36,31,2],
[37,36,31,3],
[37,36,31,5],
[37,36,31,6],
[37,36,31,7],
[37,36,31,8],
[37,36,31,9],
[37,36,31,10],
[37,36,31,11],
[37,36,31,12],
[37,36,31,15],
[37,36,31,16],
[37,36,31,17],
[37,36,31,18],
[37,36,31,20],
[37,36,31,21],
[37,36,31,22],
[37,36,31,25],
[37,36,31,26],
[37,36,31,27],
[37,36,31,28],
[37,36,31,29],
[37,36,32,0],
[37,36,32,1],
[37,36,32,2],
[37,36,32,3],
[37,36,32,5],
[37,36,32,6],
[37,36,32,7],
[37,36,32,8],
[37,36,32,9],
[37,36,32,10],
[37,36,32,15],
[37,36,32,16],
[37,36,32,17],
[37,36,32,18],
[37,36,32,20],
[37,36,32,21],
[37,36,32,23],
[37,36,32,24],
[37,36,32,25],
[37,36,32,26],
[37,36,32,27],
[37,36,32,28],
[37,36,32,30],
[37,36,32,31],
[37,36,33,0],
[37,36,33,1],
[37,36,33,2],
[37,36,33,3],
[37,36,33,5],
[37,36,33,6],
[37,36,33,7],
[37,36,33,8],
[37,36,33,9],
[37,36,33,10],
[37,36,33,15],
[37,36,33,16],
[37,36,33,17],
[37,36,33,18],
[37,36,33,20],
[37,36,33,22],
[37,36,33,23],
[37,36,33,24],
[37,36,33,25],
[37,36,33,26],
[37,36,33,27],
[37,36,33,28],
[37,36,33,30],
[37,36,33,31],
[37,36,34,0],
[37,36,34,1],
[37,36,34,2],
[37,36,34,3],
[37,36,34,5],
[37,36,34,6],
[37,36,34,8],
[37,36,34,9],
[37,36,34,10],
[37,36,34,11],
[37,36,34,12],
[37,36,34,15],
[37,36,34,16],
[37,36,34,17],
[37,36,34,18],
[37,36,34,21],
[37,36,34,22],
[37,36,34,23],
[37,36,34,24],
[37,36,34,26],
[37,36,34,27],
[37,36,34,29],
[37,36,34,30],
[37,36,34,31],
[37,36,34,32],
[37,36,35,0],
[37,36,35,1],
[37,36,35,2],
[37,36,35,3],
[37,36,35,5],
[37,36,35,6],
[37,36,35,7],
[37,36,35,8],
[37,36,35,10],
[37,36,35,11],
[37,36,35,12],
[37,36,35,16],
[37,36,35,17],
[37,36,35,20],
[37,36,35,21],
[37,36,35,22],
[37,36,35,23],
[37,36,35,24],
[37,36,35,25],
[37,36,35,27],
[37,36,35,28],
[37,36,35,29],
[37,36,35,30],
[37,36,35,31],
[38,4,3,0],
[38,4,3,1],
[38,4,3,2],
[38,5,4,0],
[38,5,4,1],
[38,5,4,2],
[38,6,4,0],
[38,6,4,1],
[38,6,4,2],
[38,8,4,0],
[38,8,4,1],
[38,8,4,2],
[38,9,3,0],
[38,9,3,1],
[38,9,3,2],
[38,9,4,0],
[38,9,4,1],
[38,9,4,2],
[38,9,4,3],
[38,9,5,0],
[38,9,5,1],
[38,9,5,2],
[38,9,5,4],
[38,9,6,0],
[38,9,6,1],
[38,9,6,2],
[38,9,6,4],
[38,9,8,0],
[38,9,8,1],
[38,9,8,2],
[38,9,8,4],
[38,10,3,0],
[38,10,3,1],
[38,10,3,2],
[38,10,4,0],
[38,10,4,1],
[38,10,4,2],
[38,10,4,3],
[38,10,5,0],
[38,10,5,1],
[38,10,5,2],
[38,10,5,4],
[38,10,6,0],
[38,10,6,1],
[38,10,6,2],
[38,10,6,4],
[38,10,8,0],
[38,10,8,1],
[38,10,8,2],
[38,10,8,4],
[38,10,9,0],
[38,10,9,1],
[38,10,9,2],
[38,10,9,3],
[38,10,9,4],
[38,10,9,5],
[38,10,9,6],
[38,10,9,8],
[38,11,3,0],
[38,11,3,1],
[38,11,3,2],
[38,11,4,0],
[38,11,4,1],
[38,11,4,2],
[38,11,4,3],
[38,11,5,0],
[38,11,5,1],
[38,11,5,2],
[38,11,5,4],
[38,11,6,0],
[38,11,6,1],
[38,11,6,2],
[38,11,6,4],
[38,11,8,0],
[38,11,8,1],
[38,11,8,2],
[38,11,8,4],
[38,11,9,0],
[38,11,9,1],
[38,11,9,2],
[38,11,9,3],
[38,11,9,4],
[38,11,9,5],
[38,11,9,6],
[38,11,9,8],
[38,11,10,0],
[38,11,10,1],
[38,11,10,2],
[38,11,10,3],
[38,11,10,4],
[38,11,10,5],
[38,11,10,6],
[38,11,10,8],
[38,11,10,9],
[38,12,3,0],
[38,12,3,1],
[38,12,3,2],
[38,12,4,0],
[38,12,4,1],
[38,12,4,2],
[38,12,4,3],
[38,12,5,0],
[38,12,5,1],
[38,12,5,2],
[38,12,5,4],
[38,12,6,0],
[38,12,6,1],
[38,12,6,2],
[38,12,6,4],
[38,12,8,0],
[38,12,8,1],
[38,12,8,2],
[38,12,8,4],
[38,12,9,0],
[38,12,9,1],
[38,12,9,2],
[38,12,9,3],
[38,12,9,4],
[38,12,9,5],
[38,12,9,6],
[38,12,9,8],
[38,12,10,0],
[38,12,10,1],
[38,12,10,2],
[38,12,10,3],
[38,12,10,4],
[38,12,10,5],
[38,12,10,6],
[38,12,10,8],
[38,12,10,9],
[38,13,3,0],
[38,13,3,1],
[38,13,3,2],
[38,13,4,0],
[38,13,4,1],
[38,13,4,2],
[38,13,4,3],
[38,13,5,0],
[38,13,5,1],
[38,13,5,2],
[38,13,5,4],
[38,13,6,0],
[38,13,6,1],
[38,13,6,2],
[38,13,6,4],
[38,13,8,0],
[38,13,8,1],
[38,13,8,2],
[38,13,8,4],
[38,13,9,0],
[38,13,9,1],
[38,13,9,2],
[38,13,9,3],
[38,13,9,4],
[38,13,9,5],
[38,13,9,6],
[38,13,9,8],
[38,13,10,0],
[38,13,10,1],
[38,13,10,2],
[38,13,10,3],
[38,13,10,4],
[38,13,10,5],
[38,13,10,6],
[38,13,10,8],
[38,13,10,9],
[38,13,11,0],
[38,13,11,1],
[38,13,11,2],
[38,13,11,3],
[38,13,11,4],
[38,13,11,5],
[38,13,11,6],
[38,13,11,8],
[38,13,11,9],
[38,13,11,10],
[38,13,12,0],
[38,13,12,1],
[38,13,12,2],
[38,13,12,3],
[38,13,12,4],
[38,13,12,5],
[38,13,12,6],
[38,13,12,8],
[38,13,12,9],
[38,13,12,10],
[38,14,3,0],
[38,14,3,1],
[38,14,3,2],
[38,14,4,0],
[38,14,4,1],
[38,14,4,2],
[38,14,4,3],
[38,14,5,0],
[38,14,5,1],
[38,14,5,2],
[38,14,5,4],
[38,14,6,0],
[38,14,6,1],
[38,14,6,2],
[38,14,6,4],
[38,14,8,0],
[38,14,8,1],
[38,14,8,2],
[38,14,8,4],
[38,14,9,0],
[38,14,9,1],
[38,14,9,2],
[38,14,9,3],
[38,14,9,4],
[38,14,9,5],
[38,14,9,6],
[38,14,9,8],
[38,14,10,0],
[38,14,10,1],
[38,14,10,2],
[38,14,10,3],
[38,14,10,4],
[38,14,10,5],
[38,14,10,6],
[38,14,10,8],
[38,14,10,9],
[38,14,11,0],
[38,14,11,1],
[38,14,11,2],
[38,14,11,3],
[38,14,11,4],
[38,14,11,5],
[38,14,11,6],
[38,14,11,8],
[38,14,11,9],
[38,14,11,10],
[38,14,12,0],
[38,14,12,1],
[38,14,12,2],
[38,14,12,3],
[38,14,12,4],
[38,14,12,5],
[38,14,12,6],
[38,14,12,8],
[38,14,12,9],
[38,14,12,10],
[38,15,3,0],
[38,15,3,1],
[38,15,3,2],
[38,15,4,0],
[38,15,4,1],
[38,15,4,2],
[38,15,4,3],
[38,15,5,0],
[38,15,5,1],
[38,15,5,2],
[38,15,5,4],
[38,15,6,0],
[38,15,6,1],
[38,15,6,2],
[38,15,6,4],
[38,15,8,0],
[38,15,8,1],
[38,15,8,2],
[38,15,8,4],
[38,15,10,0],
[38,15,10,1],
[38,15,10,2],
[38,15,10,3],
[38,15,10,4],
[38,15,10,5],
[38,15,10,6],
[38,15,10,8],
[38,15,11,0],
[38,15,11,1],
[38,15,11,2],
[38,15,11,3],
[38,15,11,4],
[38,15,11,5],
[38,15,11,6],
[38,15,11,8],
[38,15,11,10],
[38,15,12,0],
[38,15,12,1],
[38,15,12,2],
[38,15,12,3],
[38,15,12,4],
[38,15,12,5],
[38,15,12,6],
[38,15,12,8],
[38,15,12,10],
[38,15,13,0],
[38,15,13,1],
[38,15,13,2],
[38,15,13,3],
[38,15,13,4],
[38,15,13,5],
[38,15,13,6],
[38,15,13,8],
[38,15,13,10],
[38,15,13,11],
[38,15,13,12],
[38,15,14,0],
[38,15,14,1],
[38,15,14,2],
[38,15,14,3],
[38,15,14,4],
[38,15,14,5],
[38,15,14,6],
[38,15,14,8],
[38,15,14,10],
[38,15,14,11],
[38,15,14,12],
[38,16,3,0],
[38,16,3,1],
[38,16,3,2],
[38,16,4,0],
[38,16,4,1],
[38,16,4,2],
[38,16,4,3],
[38,16,5,0],
[38,16,5,1],
[38,16,5,2],
[38,16,5,4],
[38,16,6,0],
[38,16,6,1],
[38,16,6,2],
[38,16,6,4],
[38,16,8,0],
[38,16,8,1],
[38,16,8,2],
[38,16,8,4],
[38,16,9,0],
[38,16,9,1],
[38,16,9,2],
[38,16,9,3],
[38,16,9,4],
[38,16,9,5],
[38,16,9,6],
[38,16,9,8],
[38,16,10,0],
[38,16,10,1],
[38,16,10,2],
[38,16,10,3],
[38,16,10,4],
[38,16,10,5],
[38,16,10,6],
[38,16,10,8],
[38,16,10,9],
[38,16,11,0],
[38,16,11,1],
[38,16,11,2],
[38,16,11,3],
[38,16,11,4],
[38,16,11,5],
[38,16,11,6],
[38,16,11,8],
[38,16,11,9],
[38,16,11,10],
[38,16,12,0],
[38,16,12,1],
[38,16,12,2],
[38,16,12,3],
[38,16,12,4],
[38,16,12,5],
[38,16,12,6],
[38,16,12,8],
[38,16,12,9],
[38,16,12,10],
[38,16,13,0],
[38,16,13,1],
[38,16,13,2],
[38,16,13,3],
[38,16,13,4],
[38,16,13,5],
[38,16,13,6],
[38,16,13,8],
[38,16,13,9],
[38,16,13,10],
[38,16,13,11],
[38,16,13,12],
[38,16,14,0],
[38,16,14,1],
[38,16,14,2],
[38,16,14,3],
[38,16,14,4],
[38,16,14,5],
[38,16,14,6],
[38,16,14,8],
[38,16,14,9],
[38,16,14,10],
[38,16,14,11],
[38,16,14,12],
[38,16,15,0],
[38,16,15,1],
[38,16,15,2],
[38,16,15,3],
[38,16,15,4],
[38,16,15,5],
[38,16,15,6],
[38,16,15,8],
[38,16,15,10],
[38,16,15,11],
[38,16,15,12],
[38,16,15,13],
[38,16,15,14],
[38,17,3,0],
[38,17,3,1],
[38,17,3,2],
[38,17,4,0],
[38,17,4,1],
[38,17,4,2],
[38,17,4,3],
[38,17,5,0],
[38,17,5,1],
[38,17,5,2],
[38,17,5,4],
[38,17,6,0],
[38,17,6,1],
[38,17,6,2],
[38,17,6,4],
[38,17,8,0],
[38,17,8,1],
[38,17,8,2],
[38,17,8,4],
[38,17,9,0],
[38,17,9,1],
[38,17,9,2],
[38,17,9,3],
[38,17,9,4],
[38,17,9,5],
[38,17,9,6],
[38,17,9,8],
[38,17,10,0],
[38,17,10,1],
[38,17,10,2],
[38,17,10,3],
[38,17,10,4],
[38,17,10,5],
[38,17,10,6],
[38,17,10,8],
[38,17,10,9],
[38,17,11,0],
[38,17,11,1],
[38,17,11,2],
[38,17,11,3],
[38,17,11,4],
[38,17,11,5],
[38,17,11,6],
[38,17,11,8],
[38,17,11,9],
[38,17,11,10],
[38,17,12,0],
[38,17,12,1],
[38,17,12,2],
[38,17,12,3],
[38,17,12,4],
[38,17,12,5],
[38,17,12,6],
[38,17,12,8],
[38,17,12,9],
[38,17,12,10],
[38,17,13,0],
[38,17,13,1],
[38,17,13,2],
[38,17,13,3],
[38,17,13,4],
[38,17,13,5],
[38,17,13,6],
[38,17,13,8],
[38,17,13,9],
[38,17,13,10],
[38,17,13,11],
[38,17,13,12],
[38,17,14,0],
[38,17,14,1],
[38,17,14,2],
[38,17,14,3],
[38,17,14,4],
[38,17,14,5],
[38,17,14,6],
[38,17,14,8],
[38,17,14,9],
[38,17,14,10],
[38,17,14,11],
[38,17,14,12],
[38,17,15,0],
[38,17,15,1],
[38,17,15,2],
[38,17,15,3],
[38,17,15,4],
[38,17,15,5],
[38,17,15,6],
[38,17,15,8],
[38,17,15,10],
[38,17,15,11],
[38,17,15,12],
[38,17,15,13],
[38,17,15,14],
[38,17,16,0],
[38,17,16,1],
[38,17,16,2],
[38,17,16,3],
[38,17,16,4],
[38,17,16,5],
[38,17,16,6],
[38,17,16,8],
[38,17,16,9],
[38,17,16,10],
[38,17,16,11],
[38,17,16,12],
[38,17,16,13],
[38,17,16,14],
[38,17,16,15],
[38,18,3,0],
[38,18,3,1],
[38,18,3,2],
[38,18,4,0],
[38,18,4,1],
[38,18,4,2],
[38,18,4,3],
[38,18,5,0],
[38,18,5,1],
[38,18,5,2],
[38,18,5,4],
[38,18,6,0],
[38,18,6,1],
[38,18,6,2],
[38,18,6,4],
[38,18,8,0],
[38,18,8,1],
[38,18,8,2],
[38,18,8,4],
[38,18,10,0],
[38,18,10,1],
[38,18,10,2],
[38,18,10,3],
[38,18,10,4],
[38,18,10,5],
[38,18,10,6],
[38,18,10,8],
[38,18,11,0],
[38,18,11,1],
[38,18,11,2],
[38,18,11,3],
[38,18,11,4],
[38,18,11,5],
[38,18,11,6],
[38,18,11,8],
[38,18,11,10],
[38,18,12,0],
[38,18,12,1],
[38,18,12,2],
[38,18,12,3],
[38,18,12,4],
[38,18,12,5],
[38,18,12,6],
[38,18,12,8],
[38,18,12,10],
[38,18,13,0],
[38,18,13,1],
[38,18,13,2],
[38,18,13,3],
[38,18,13,4],
[38,18,13,5],
[38,18,13,6],
[38,18,13,8],
[38,18,13,10],
[38,18,13,11],
[38,18,13,12],
[38,18,14,0],
[38,18,14,1],
[38,18,14,2],
[38,18,14,3],
[38,18,14,4],
[38,18,14,5],
[38,18,14,6],
[38,18,14,8],
[38,18,14,10],
[38,18,14,11],
[38,18,14,12],
[38,18,16,0],
[38,18,16,1],
[38,18,16,2],
[38,18,16,3],
[38,18,16,4],
[38,18,16,5],
[38,18,16,6],
[38,18,16,8],
[38,18,16,10],
[38,18,16,11],
[38,18,16,12],
[38,18,16,13],
[38,18,16,14],
[38,18,17,0],
[38,18,17,1],
[38,18,17,2],
[38,18,17,3],
[38,18,17,4],
[38,18,17,5],
[38,18,17,6],
[38,18,17,8],
[38,18,17,10],
[38,18,17,11],
[38,18,17,12],
[38,18,17,13],
[38,18,17,14],
[38,18,17,16],
[38,19,3,0],
[38,19,3,1],
[38,19,3,2],
[38,19,4,0],
[38,19,4,1],
[38,19,4,2],
[38,19,4,3],
[38,19,5,0],
[38,19,5,1],
[38,19,5,2],
[38,19,5,4],
[38,19,6,0],
[38,19,6,1],
[38,19,6,2],
[38,19,6,4],
[38,19,8,0],
[38,19,8,1],
[38,19,8,2],
[38,19,8,4],
[38,19,9,0],
[38,19,9,1],
[38,19,9,2],
[38,19,9,3],
[38,19,9,4],
[38,19,9,5],
[38,19,9,6],
[38,19,9,8],
[38,19,10,0],
[38,19,10,1],
[38,19,10,2],
[38,19,10,3],
[38,19,10,4],
[38,19,10,5],
[38,19,10,6],
[38,19,10,8],
[38,19,10,9],
[38,19,11,0],
[38,19,11,1],
[38,19,11,2],
[38,19,11,3],
[38,19,11,4],
[38,19,11,5],
[38,19,11,6],
[38,19,11,8],
[38,19,11,9],
[38,19,11,10],
[38,19,12,0],
[38,19,12,1],
[38,19,12,2],
[38,19,12,3],
[38,19,12,4],
[38,19,12,5],
[38,19,12,6],
[38,19,12,8],
[38,19,12,9],
[38,19,12,10],
[38,19,15,0],
[38,19,15,1],
[38,19,15,2],
[38,19,15,3],
[38,19,15,4],
[38,19,15,5],
[38,19,15,6],
[38,19,15,8],
[38,19,15,10],
[38,19,15,11],
[38,19,15,12],
[38,19,16,0],
[38,19,16,1],
[38,19,16,2],
[38,19,16,3],
[38,19,16,4],
[38,19,16,5],
[38,19,16,6],
[38,19,16,8],
[38,19,16,9],
[38,19,16,10],
[38,19,16,11],
[38,19,16,12],
[38,19,16,15],
[38,19,17,0],
[38,19,17,1],
[38,19,17,2],
[38,19,17,3],
[38,19,17,4],
[38,19,17,5],
[38,19,17,6],
[38,19,17,8],
[38,19,17,9],
[38,19,17,10],
[38,19,17,11],
[38,19,17,12],
[38,19,17,15],
[38,19,17,16],
[38,19,18,0],
[38,19,18,1],
[38,19,18,2],
[38,19,18,3],
[38,19,18,4],
[38,19,18,5],
[38,19,18,6],
[38,19,18,8],
[38,19,18,10],
[38,19,18,11],
[38,19,18,12],
[38,19,18,16],
[38,19,18,17],
[38,20,3,0],
[38,20,3,1],
[38,20,3,2],
[38,20,4,0],
[38,20,4,1],
[38,20,4,2],
[38,20,4,3],
[38,20,5,0],
[38,20,5,1],
[38,20,5,2],
[38,20,5,4],
[38,20,6,0],
[38,20,6,1],
[38,20,6,2],
[38,20,6,4],
[38,20,8,0],
[38,20,8,1],
[38,20,8,2],
[38,20,8,4],
[38,20,9,0],
[38,20,9,1],
[38,20,9,2],
[38,20,9,3],
[38,20,9,4],
[38,20,9,5],
[38,20,9,6],
[38,20,9,8],
[38,20,11,0],
[38,20,11,1],
[38,20,11,2],
[38,20,11,3],
[38,20,11,4],
[38,20,11,5],
[38,20,11,6],
[38,20,11,8],
[38,20,11,9],
[38,20,12,0],
[38,20,12,1],
[38,20,12,2],
[38,20,12,3],
[38,20,12,4],
[38,20,12,5],
[38,20,12,6],
[38,20,12,8],
[38,20,12,9],
[38,20,13,0],
[38,20,13,1],
[38,20,13,2],
[38,20,13,3],
[38,20,13,4],
[38,20,13,5],
[38,20,13,6],
[38,20,13,8],
[38,20,13,9],
[38,20,13,11],
[38,20,13,12],
[38,20,14,0],
[38,20,14,1],
[38,20,14,2],
[38,20,14,3],
[38,20,14,4],
[38,20,14,5],
[38,20,14,6],
[38,20,14,8],
[38,20,14,9],
[38,20,14,11],
[38,20,14,12],
[38,20,15,0],
[38,20,15,1],
[38,20,15,2],
[38,20,15,3],
[38,20,15,4],
[38,20,15,5],
[38,20,15,6],
[38,20,15,8],
[38,20,15,11],
[38,20,15,12],
[38,20,15,13],
[38,20,15,14],
[38,20,16,0],
[38,20,16,1],
[38,20,16,2],
[38,20,16,3],
[38,20,16,4],
[38,20,16,5],
[38,20,16,6],
[38,20,16,8],
[38,20,16,9],
[38,20,16,11],
[38,20,16,12],
[38,20,16,13],
[38,20,16,14],
[38,20,16,15],
[38,20,17,0],
[38,20,17,1],
[38,20,17,2],
[38,20,17,3],
[38,20,17,4],
[38,20,17,5],
[38,20,17,6],
[38,20,17,8],
[38,20,17,9],
[38,20,17,11],
[38,20,17,12],
[38,20,17,13],
[38,20,17,14],
[38,20,17,15],
[38,20,17,16],
[38,20,18,0],
[38,20,18,1],
[38,20,18,2],
[38,20,18,3],
[38,20,18,4],
[38,20,18,5],
[38,20,18,6],
[38,20,18,8],
[38,20,18,11],
[38,20,18,12],
[38,20,18,13],
[38,20,18,14],
[38,20,18,16],
[38,20,18,17],
[38,20,19,0],
[38,20,19,1],
[38,20,19,2],
[38,20,19,3],
[38,20,19,4],
[38,20,19,5],
[38,20,19,6],
[38,20,19,8],
[38,20,19,9],
[38,20,19,11],
[38,20,19,12],
[38,20,19,15],
[38,20,19,16],
[38,20,19,17],
[38,20,19,18],
[38,21,4,0],
[38,21,4,1],
[38,21,4,2],
[38,21,9,0],
[38,21,9,1],
[38,21,9,2],
[38,21,9,4],
[38,21,10,0],
[38,21,10,1],
[38,21,10,2],
[38,21,10,4],
[38,21,10,9],
[38,21,11,0],
[38,21,11,1],
[38,21,11,2],
[38,21,11,4],
[38,21,11,9],
[38,21,11,10],
[38,21,12,0],
[38,21,12,1],
[38,21,12,2],
[38,21,12,4],
[38,21,12,9],
[38,21,12,10],
[38,21,13,0],
[38,21,13,1],
[38,21,13,2],
[38,21,13,4],
[38,21,13,9],
[38,21,13,10],
[38,21,13,11],
[38,21,13,12],
[38,21,14,0],
[38,21,14,1],
[38,21,14,2],
[38,21,14,4],
[38,21,14,9],
[38,21,14,10],
[38,21,14,11],
[38,21,14,12],
[38,21,15,0],
[38,21,15,1],
[38,21,15,2],
[38,21,15,4],
[38,21,15,10],
[38,21,15,11],
[38,21,15,12],
[38,21,15,13],
[38,21,15,14],
[38,21,16,0],
[38,21,16,1],
[38,21,16,2],
[38,21,16,4],
[38,21,16,9],
[38,21,16,10],
[38,21,16,11],
[38,21,16,12],
[38,21,16,13],
[38,21,16,14],
[38,21,16,15],
[38,21,17,0],
[38,21,17,1],
[38,21,17,2],
[38,21,17,4],
[38,21,17,9],
[38,21,17,10],
[38,21,17,11],
[38,21,17,12],
[38,21,17,13],
[38,21,17,14],
[38,21,17,15],
[38,21,17,16],
[38,21,18,0],
[38,21,18,1],
[38,21,18,2],
[38,21,18,4],
[38,21,18,10],
[38,21,18,11],
[38,21,18,12],
[38,21,18,13],
[38,21,18,14],
[38,21,18,16],
[38,21,18,17],
[38,21,19,0],
[38,21,19,1],
[38,21,19,2],
[38,21,19,4],
[38,21,19,9],
[38,21,19,10],
[38,21,19,11],
[38,21,19,12],
[38,21,19,15],
[38,21,19,16],
[38,21,19,17],
[38,21,19,18],
[38,21,20,0],
[38,21,20,1],
[38,21,20,2],
[38,21,20,4],
[38,21,20,9],
[38,21,20,11],
[38,21,20,12],
[38,21,20,13],
[38,21,20,14],
[38,21,20,15],
[38,21,20,16],
[38,21,20,17],
[38,21,20,18],
[38,21,20,19],
[38,22,3,0],
[38,22,3,1],
[38,22,3,2],
[38,22,4,0],
[38,22,4,1],
[38,22,4,2],
[38,22,4,3],
[38,22,5,0],
[38,22,5,1],
[38,22,5,2],
[38,22,5,4],
[38,22,6,0],
[38,22,6,1],
[38,22,6,2],
[38,22,6,4],
[38,22,8,0],
[38,22,8,1],
[38,22,8,2],
[38,22,8,4],
[38,22,9,0],
[38,22,9,1],
[38,22,9,2],
[38,22,9,3],
[38,22,9,4],
[38,22,9,5],
[38,22,9,6],
[38,22,9,8],
[38,22,11,0],
[38,22,11,1],
[38,22,11,2],
[38,22,11,3],
[38,22,11,4],
[38,22,11,5],
[38,22,11,6],
[38,22,11,8],
[38,22,11,9],
[38,22,12,0],
[38,22,12,1],
[38,22,12,2],
[38,22,12,3],
[38,22,12,4],
[38,22,12,5],
[38,22,12,6],
[38,22,12,8],
[38,22,12,9],
[38,22,13,0],
[38,22,13,1],
[38,22,13,2],
[38,22,13,3],
[38,22,13,4],
[38,22,13,5],
[38,22,13,6],
[38,22,13,8],
[38,22,13,9],
[38,22,13,11],
[38,22,13,12],
[38,22,14,0],
[38,22,14,1],
[38,22,14,2],
[38,22,14,3],
[38,22,14,4],
[38,22,14,5],
[38,22,14,6],
[38,22,14,8],
[38,22,14,9],
[38,22,14,11],
[38,22,14,12],
[38,22,15,0],
[38,22,15,1],
[38,22,15,2],
[38,22,15,3],
[38,22,15,4],
[38,22,15,5],
[38,22,15,6],
[38,22,15,8],
[38,22,15,11],
[38,22,15,12],
[38,22,15,13],
[38,22,15,14],
[38,22,16,0],
[38,22,16,1],
[38,22,16,2],
[38,22,16,3],
[38,22,16,4],
[38,22,16,5],
[38,22,16,6],
[38,22,16,8],
[38,22,16,9],
[38,22,16,11],
[38,22,16,12],
[38,22,16,13],
[38,22,16,14],
[38,22,16,15],
[38,22,17,0],
[38,22,17,1],
[38,22,17,2],
[38,22,17,3],
[38,22,17,4],
[38,22,17,5],
[38,22,17,6],
[38,22,17,8],
[38,22,17,9],
[38,22,17,11],
[38,22,17,12],
[38,22,17,13],
[38,22,17,14],
[38,22,17,15],
[38,22,17,16],
[38,22,18,0],
[38,22,18,1],
[38,22,18,2],
[38,22,18,3],
[38,22,18,4],
[38,22,18,5],
[38,22,18,6],
[38,22,18,8],
[38,22,18,11],
[38,22,18,12],
[38,22,18,13],
[38,22,18,14],
[38,22,18,16],
[38,22,18,17],
[38,22,19,0],
[38,22,19,1],
[38,22,19,2],
[38,22,19,3],
[38,22,19,4],
[38,22,19,5],
[38,22,19,6],
[38,22,19,8],
[38,22,19,9],
[38,22,19,11],
[38,22,19,12],
[38,22,19,15],
[38,22,19,16],
[38,22,19,17],
[38,22,19,18],
[38,22,21,0],
[38,22,21,1],
[38,22,21,2],
[38,22,21,4],
[38,22,21,9],
[38,22,21,11],
[38,22,21,12],
[38,22,21,13],
[38,22,21,14],
[38,22,21,15],
[38,22,21,16],
[38,22,21,17],
[38,22,21,18],
[38,22,21,19],
[38,23,3,0],
[38,23,3,1],
[38,23,3,2],
[38,23,4,0],
[38,23,4,1],
[38,23,4,2],
[38,23,4,3],
[38,23,5,0],
[38,23,5,1],
[38,23,5,2],
[38,23,5,4],
[38,23,6,0],
[38,23,6,1],
[38,23,6,2],
[38,23,6,4],
[38,23,8,0],
[38,23,8,1],
[38,23,8,2],
[38,23,8,4],
[38,23,9,0],
[38,23,9,1],
[38,23,9,2],
[38,23,9,3],
[38,23,9,4],
[38,23,9,5],
[38,23,9,6],
[38,23,9,8],
[38,23,10,0],
[38,23,10,1],
[38,23,10,2],
[38,23,10,3],
[38,23,10,4],
[38,23,10,5],
[38,23,10,6],
[38,23,10,8],
[38,23,10,9],
[38,23,11,0],
[38,23,11,1],
[38,23,11,2],
[38,23,11,3],
[38,23,11,4],
[38,23,11,5],
[38,23,11,6],
[38,23,11,8],
[38,23,11,9],
[38,23,11,10],
[38,23,12,0],
[38,23,12,1],
[38,23,12,2],
[38,23,12,3],
[38,23,12,4],
[38,23,12,5],
[38,23,12,6],
[38,23,12,8],
[38,23,12,9],
[38,23,12,10],
[38,23,13,0],
[38,23,13,1],
[38,23,13,2],
[38,23,13,3],
[38,23,13,4],
[38,23,13,5],
[38,23,13,6],
[38,23,13,8],
[38,23,13,9],
[38,23,13,10],
[38,23,13,11],
[38,23,13,12],
[38,23,14,0],
[38,23,14,1],
[38,23,14,2],
[38,23,14,3],
[38,23,14,4],
[38,23,14,5],
[38,23,14,6],
[38,23,14,8],
[38,23,14,9],
[38,23,14,10],
[38,23,14,11],
[38,23,14,12],
[38,23,15,0],
[38,23,15,1],
[38,23,15,2],
[38,23,15,3],
[38,23,15,4],
[38,23,15,5],
[38,23,15,6],
[38,23,15,8],
[38,23,15,10],
[38,23,15,11],
[38,23,15,12],
[38,23,15,13],
[38,23,15,14],
[38,23,16,0],
[38,23,16,1],
[38,23,16,2],
[38,23,16,3],
[38,23,16,4],
[38,23,16,5],
[38,23,16,6],
[38,23,16,8],
[38,23,16,9],
[38,23,16,10],
[38,23,16,11],
[38,23,16,12],
[38,23,16,13],
[38,23,16,14],
[38,23,16,15],
[38,23,17,0],
[38,23,17,1],
[38,23,17,2],
[38,23,17,3],
[38,23,17,4],
[38,23,17,5],
[38,23,17,6],
[38,23,17,8],
[38,23,17,9],
[38,23,17,10],
[38,23,17,11],
[38,23,17,12],
[38,23,17,13],
[38,23,17,14],
[38,23,17,15],
[38,23,17,16],
[38,23,18,0],
[38,23,18,1],
[38,23,18,2],
[38,23,18,3],
[38,23,18,4],
[38,23,18,5],
[38,23,18,6],
[38,23,18,8],
[38,23,18,10],
[38,23,18,11],
[38,23,18,12],
[38,23,18,13],
[38,23,18,14],
[38,23,18,16],
[38,23,18,17],
[38,23,19,0],
[38,23,19,1],
[38,23,19,2],
[38,23,19,3],
[38,23,19,4],
[38,23,19,5],
[38,23,19,6],
[38,23,19,8],
[38,23,19,9],
[38,23,19,10],
[38,23,19,11],
[38,23,19,12],
[38,23,19,15],
[38,23,19,16],
[38,23,19,17],
[38,23,19,18],
[38,23,20,0],
[38,23,20,1],
[38,23,20,2],
[38,23,20,3],
[38,23,20,4],
[38,23,20,5],
[38,23,20,6],
[38,23,20,8],
[38,23,20,9],
[38,23,20,11],
[38,23,20,12],
[38,23,20,13],
[38,23,20,14],
[38,23,20,15],
[38,23,20,16],
[38,23,20,17],
[38,23,20,18],
[38,23,20,19],
[38,23,21,0],
[38,23,21,1],
[38,23,21,2],
[38,23,21,4],
[38,23,21,9],
[38,23,21,10],
[38,23,21,11],
[38,23,21,12],
[38,23,21,13],
[38,23,21,14],
[38,23,21,15],
[38,23,21,16],
[38,23,21,17],
[38,23,21,18],
[38,23,21,19],
[38,23,21,20],
[38,23,22,0],
[38,23,22,1],
[38,23,22,2],
[38,23,22,3],
[38,23,22,4],
[38,23,22,5],
[38,23,22,6],
[38,23,22,8],
[38,23,22,9],
[38,23,22,11],
[38,23,22,12],
[38,23,22,13],
[38,23,22,14],
[38,23,22,15],
[38,23,22,16],
[38,23,22,17],
[38,23,22,18],
[38,23,22,19],
[38,23,22,21],
[38,24,3,0],
[38,24,3,1],
[38,24,3,2],
[38,24,4,0],
[38,24,4,1],
[38,24,4,2],
[38,24,4,3],
[38,24,5,0],
[38,24,5,1],
[38,24,5,2],
[38,24,5,4],
[38,24,6,0],
[38,24,6,1],
[38,24,6,2],
[38,24,6,4],
[38,24,8,0],
[38,24,8,1],
[38,24,8,2],
[38,24,8,4],
[38,24,9,0],
[38,24,9,1],
[38,24,9,2],
[38,24,9,3],
[38,24,9,4],
[38,24,9,5],
[38,24,9,6],
[38,24,9,8],
[38,24,10,0],
[38,24,10,1],
[38,24,10,2],
[38,24,10,3],
[38,24,10,4],
[38,24,10,5],
[38,24,10,6],
[38,24,10,8],
[38,24,10,9],
[38,24,11,0],
[38,24,11,1],
[38,24,11,2],
[38,24,11,3],
[38,24,11,4],
[38,24,11,5],
[38,24,11,6],
[38,24,11,8],
[38,24,11,9],
[38,24,11,10],
[38,24,12,0],
[38,24,12,1],
[38,24,12,2],
[38,24,12,3],
[38,24,12,4],
[38,24,12,5],
[38,24,12,6],
[38,24,12,8],
[38,24,12,9],
[38,24,12,10],
[38,24,13,0],
[38,24,13,1],
[38,24,13,2],
[38,24,13,3],
[38,24,13,4],
[38,24,13,5],
[38,24,13,6],
[38,24,13,8],
[38,24,13,9],
[38,24,13,10],
[38,24,13,11],
[38,24,13,12],
[38,24,14,0],
[38,24,14,1],
[38,24,14,2],
[38,24,14,3],
[38,24,14,4],
[38,24,14,5],
[38,24,14,6],
[38,24,14,8],
[38,24,14,9],
[38,24,14,10],
[38,24,14,11],
[38,24,14,12],
[38,24,15,0],
[38,24,15,1],
[38,24,15,2],
[38,24,15,3],
[38,24,15,4],
[38,24,15,5],
[38,24,15,6],
[38,24,15,8],
[38,24,15,10],
[38,24,15,11],
[38,24,15,12],
[38,24,15,13],
[38,24,15,14],
[38,24,17,0],
[38,24,17,1],
[38,24,17,2],
[38,24,17,3],
[38,24,17,4],
[38,24,17,5],
[38,24,17,6],
[38,24,17,8],
[38,24,17,9],
[38,24,17,10],
[38,24,17,11],
[38,24,17,12],
[38,24,17,13],
[38,24,17,14],
[38,24,17,15],
[38,24,18,0],
[38,24,18,1],
[38,24,18,2],
[38,24,18,3],
[38,24,18,4],
[38,24,18,5],
[38,24,18,6],
[38,24,18,8],
[38,24,18,10],
[38,24,18,11],
[38,24,18,12],
[38,24,18,13],
[38,24,18,14],
[38,24,18,17],
[38,24,19,0],
[38,24,19,1],
[38,24,19,2],
[38,24,19,3],
[38,24,19,4],
[38,24,19,5],
[38,24,19,6],
[38,24,19,8],
[38,24,19,9],
[38,24,19,10],
[38,24,19,11],
[38,24,19,12],
[38,24,19,15],
[38,24,19,17],
[38,24,19,18],
[38,24,20,0],
[38,24,20,1],
[38,24,20,2],
[38,24,20,3],
[38,24,20,4],
[38,24,20,5],
[38,24,20,6],
[38,24,20,8],
[38,24,20,9],
[38,24,20,11],
[38,24,20,12],
[38,24,20,13],
[38,24,20,14],
[38,24,20,15],
[38,24,20,17],
[38,24,20,18],
[38,24,20,19],
[38,24,21,0],
[38,24,21,1],
[38,24,21,2],
[38,24,21,4],
[38,24,21,9],
[38,24,21,10],
[38,24,21,11],
[38,24,21,12],
[38,24,21,13],
[38,24,21,14],
[38,24,21,15],
[38,24,21,17],
[38,24,21,18],
[38,24,21,19],
[38,24,21,20],
[38,24,22,0],
[38,24,22,1],
[38,24,22,2],
[38,24,22,3],
[38,24,22,4],
[38,24,22,5],
[38,24,22,6],
[38,24,22,8],
[38,24,22,9],
[38,24,22,11],
[38,24,22,12],
[38,24,22,13],
[38,24,22,14],
[38,24,22,15],
[38,24,22,17],
[38,24,22,18],
[38,24,22,19],
[38,24,22,21],
[38,24,23,0],
[38,24,23,1],
[38,24,23,2],
[38,24,23,3],
[38,24,23,4],
[38,24,23,5],
[38,24,23,6],
[38,24,23,8],
[38,24,23,9],
[38,24,23,10],
[38,24,23,11],
[38,24,23,12],
[38,24,23,13],
[38,24,23,14],
[38,24,23,15],
[38,24,23,17],
[38,24,23,18],
[38,24,23,19],
[38,24,23,20],
[38,24,23,21],
[38,24,23,22],
[38,26,3,0],
[38,26,3,1],
[38,26,3,2],
[38,26,4,0],
[38,26,4,1],
[38,26,4,2],
[38,26,4,3],
[38,26,5,0],
[38,26,5,1],
[38,26,5,2],
[38,26,5,4],
[38,26,6,0],
[38,26,6,1],
[38,26,6,2],
[38,26,6,4],
[38,26,8,0],
[38,26,8,1],
[38,26,8,2],
[38,26,8,4],
[38,26,10,0],
[38,26,10,1],
[38,26,10,2],
[38,26,10,3],
[38,26,10,4],
[38,26,10,5],
[38,26,10,6],
[38,26,10,8],
[38,26,11,0],
[38,26,11,1],
[38,26,11,2],
[38,26,11,3],
[38,26,11,4],
[38,26,11,5],
[38,26,11,6],
[38,26,11,8],
[38,26,11,10],
[38,26,12,0],
[38,26,12,1],
[38,26,12,2],
[38,26,12,3],
[38,26,12,4],
[38,26,12,5],
[38,26,12,6],
[38,26,12,8],
[38,26,12,10],
[38,26,13,0],
[38,26,13,1],
[38,26,13,2],
[38,26,13,3],
[38,26,13,4],
[38,26,13,5],
[38,26,13,6],
[38,26,13,8],
[38,26,13,10],
[38,26,13,11],
[38,26,13,12],
[38,26,14,0],
[38,26,14,1],
[38,26,14,2],
[38,26,14,3],
[38,26,14,4],
[38,26,14,5],
[38,26,14,6],
[38,26,14,8],
[38,26,14,10],
[38,26,14,11],
[38,26,14,12],
[38,26,16,0],
[38,26,16,1],
[38,26,16,2],
[38,26,16,3],
[38,26,16,4],
[38,26,16,5],
[38,26,16,6],
[38,26,16,8],
[38,26,16,10],
[38,26,16,11],
[38,26,16,12],
[38,26,16,13],
[38,26,16,14],
[38,26,17,0],
[38,26,17,1],
[38,26,17,2],
[38,26,17,3],
[38,26,17,4],
[38,26,17,5],
[38,26,17,6],
[38,26,17,8],
[38,26,17,10],
[38,26,17,11],
[38,26,17,12],
[38,26,17,13],
[38,26,17,14],
[38,26,17,16],
[38,26,19,0],
[38,26,19,1],
[38,26,19,2],
[38,26,19,3],
[38,26,19,4],
[38,26,19,5],
[38,26,19,6],
[38,26,19,8],
[38,26,19,10],
[38,26,19,11],
[38,26,19,12],
[38,26,19,16],
[38,26,19,17],
[38,26,20,0],
[38,26,20,1],
[38,26,20,2],
[38,26,20,3],
[38,26,20,4],
[38,26,20,5],
[38,26,20,6],
[38,26,20,8],
[38,26,20,11],
[38,26,20,12],
[38,26,20,13],
[38,26,20,14],
[38,26,20,16],
[38,26,20,17],
[38,26,20,19],
[38,26,21,0],
[38,26,21,1],
[38,26,21,2],
[38,26,21,4],
[38,26,21,10],
[38,26,21,11],
[38,26,21,12],
[38,26,21,13],
[38,26,21,14],
[38,26,21,16],
[38,26,21,17],
[38,26,21,19],
[38,26,21,20],
[38,26,22,0],
[38,26,22,1],
[38,26,22,2],
[38,26,22,3],
[38,26,22,4],
[38,26,22,5],
[38,26,22,6],
[38,26,22,8],
[38,26,22,11],
[38,26,22,12],
[38,26,22,13],
[38,26,22,14],
[38,26,22,16],
[38,26,22,17],
[38,26,22,19],
[38,26,22,21],
[38,26,23,0],
[38,26,23,1],
[38,26,23,2],
[38,26,23,3],
[38,26,23,4],
[38,26,23,5],
[38,26,23,6],
[38,26,23,8],
[38,26,23,10],
[38,26,23,11],
[38,26,23,12],
[38,26,23,13],
[38,26,23,14],
[38,26,23,16],
[38,26,23,17],
[38,26,23,19],
[38,26,23,20],
[38,26,23,21],
[38,26,23,22],
[38,26,24,0],
[38,26,24,1],
[38,26,24,2],
[38,26,24,3],
[38,26,24,4],
[38,26,24,5],
[38,26,24,6],
[38,26,24,8],
[38,26,24,10],
[38,26,24,11],
[38,26,24,12],
[38,26,24,13],
[38,26,24,14],
[38,26,24,17],
[38,26,24,19],
[38,26,24,20],
[38,26,24,21],
[38,26,24,22],
[38,26,24,23],
[38,27,3,0],
[38,27,3,1],
[38,27,3,2],
[38,27,4,0],
[38,27,4,1],
[38,27,4,2],
[38,27,4,3],
[38,27,5,0],
[38,27,5,1],
[38,27,5,2],
[38,27,5,4],
[38,27,6,0],
[38,27,6,1],
[38,27,6,2],
[38,27,6,4],
[38,27,8,0],
[38,27,8,1],
[38,27,8,2],
[38,27,8,4],
[38,27,9,0],
[38,27,9,1],
[38,27,9,2],
[38,27,9,3],
[38,27,9,4],
[38,27,9,5],
[38,27,9,6],
[38,27,9,8],
[38,27,11,0],
[38,27,11,1],
[38,27,11,2],
[38,27,11,3],
[38,27,11,4],
[38,27,11,5],
[38,27,11,6],
[38,27,11,8],
[38,27,11,9],
[38,27,12,0],
[38,27,12,1],
[38,27,12,2],
[38,27,12,3],
[38,27,12,4],
[38,27,12,5],
[38,27,12,6],
[38,27,12,8],
[38,27,12,9],
[38,27,13,0],
[38,27,13,1],
[38,27,13,2],
[38,27,13,3],
[38,27,13,4],
[38,27,13,5],
[38,27,13,6],
[38,27,13,8],
[38,27,13,9],
[38,27,13,11],
[38,27,13,12],
[38,27,14,0],
[38,27,14,1],
[38,27,14,2],
[38,27,14,3],
[38,27,14,4],
[38,27,14,5],
[38,27,14,6],
[38,27,14,8],
[38,27,14,9],
[38,27,14,11],
[38,27,14,12],
[38,27,15,0],
[38,27,15,1],
[38,27,15,2],
[38,27,15,3],
[38,27,15,4],
[38,27,15,5],
[38,27,15,6],
[38,27,15,8],
[38,27,15,11],
[38,27,15,12],
[38,27,15,13],
[38,27,15,14],
[38,27,16,0],
[38,27,16,1],
[38,27,16,2],
[38,27,16,3],
[38,27,16,4],
[38,27,16,5],
[38,27,16,6],
[38,27,16,8],
[38,27,16,9],
[38,27,16,11],
[38,27,16,12],
[38,27,16,13],
[38,27,16,14],
[38,27,16,15],
[38,27,17,0],
[38,27,17,1],
[38,27,17,2],
[38,27,17,3],
[38,27,17,4],
[38,27,17,5],
[38,27,17,6],
[38,27,17,8],
[38,27,17,9],
[38,27,17,11],
[38,27,17,12],
[38,27,17,13],
[38,27,17,14],
[38,27,17,15],
[38,27,17,16],
[38,27,18,0],
[38,27,18,1],
[38,27,18,2],
[38,27,18,3],
[38,27,18,4],
[38,27,18,5],
[38,27,18,6],
[38,27,18,8],
[38,27,18,11],
[38,27,18,12],
[38,27,18,13],
[38,27,18,14],
[38,27,18,16],
[38,27,18,17],
[38,27,19,0],
[38,27,19,1],
[38,27,19,2],
[38,27,19,3],
[38,27,19,4],
[38,27,19,5],
[38,27,19,6],
[38,27,19,8],
[38,27,19,9],
[38,27,19,11],
[38,27,19,12],
[38,27,19,15],
[38,27,19,16],
[38,27,19,17],
[38,27,19,18],
[38,27,21,0],
[38,27,21,1],
[38,27,21,2],
[38,27,21,4],
[38,27,21,9],
[38,27,21,11],
[38,27,21,12],
[38,27,21,13],
[38,27,21,14],
[38,27,21,15],
[38,27,21,16],
[38,27,21,17],
[38,27,21,18],
[38,27,21,19],
[38,27,23,0],
[38,27,23,1],
[38,27,23,2],
[38,27,23,3],
[38,27,23,4],
[38,27,23,5],
[38,27,23,6],
[38,27,23,8],
[38,27,23,9],
[38,27,23,11],
[38,27,23,12],
[38,27,23,13],
[38,27,23,14],
[38,27,23,15],
[38,27,23,16],
[38,27,23,17],
[38,27,23,18],
[38,27,23,19],
[38,27,23,21],
[38,27,24,0],
[38,27,24,1],
[38,27,24,2],
[38,27,24,3],
[38,27,24,4],
[38,27,24,5],
[38,27,24,6],
[38,27,24,8],
[38,27,24,9],
[38,27,24,11],
[38,27,24,12],
[38,27,24,13],
[38,27,24,14],
[38,27,24,15],
[38,27,24,17],
[38,27,24,18],
[38,27,24,19],
[38,27,24,21],
[38,27,24,23],
[38,27,26,0],
[38,27,26,1],
[38,27,26,2],
[38,27,26,3],
[38,27,26,4],
[38,27,26,5],
[38,27,26,6],
[38,27,26,8],
[38,27,26,11],
[38,27,26,12],
[38,27,26,13],
[38,27,26,14],
[38,27,26,16],
[38,27,26,17],
[38,27,26,19],
[38,27,26,21],
[38,27,26,23],
[38,27,26,24],
[38,29,3,0],
[38,29,3,1],
[38,29,3,2],
[38,29,4,0],
[38,29,4,1],
[38,29,4,2],
[38,29,4,3],
[38,29,5,0],
[38,29,5,1],
[38,29,5,2],
[38,29,5,4],
[38,29,6,0],
[38,29,6,1],
[38,29,6,2],
[38,29,6,4],
[38,29,8,0],
[38,29,8,1],
[38,29,8,2],
[38,29,8,4],
[38,29,9,0],
[38,29,9,1],
[38,29,9,2],
[38,29,9,3],
[38,29,9,4],
[38,29,9,5],
[38,29,9,6],
[38,29,9,8],
[38,29,10,0],
[38,29,10,1],
[38,29,10,2],
[38,29,10,3],
[38,29,10,4],
[38,29,10,5],
[38,29,10,6],
[38,29,10,8],
[38,29,10,9],
[38,29,13,0],
[38,29,13,1],
[38,29,13,2],
[38,29,13,3],
[38,29,13,4],
[38,29,13,5],
[38,29,13,6],
[38,29,13,8],
[38,29,13,9],
[38,29,13,10],
[38,29,14,0],
[38,29,14,1],
[38,29,14,2],
[38,29,14,3],
[38,29,14,4],
[38,29,14,5],
[38,29,14,6],
[38,29,14,8],
[38,29,14,9],
[38,29,14,10],
[38,29,15,0],
[38,29,15,1],
[38,29,15,2],
[38,29,15,3],
[38,29,15,4],
[38,29,15,5],
[38,29,15,6],
[38,29,15,8],
[38,29,15,10],
[38,29,15,13],
[38,29,15,14],
[38,29,16,0],
[38,29,16,1],
[38,29,16,2],
[38,29,16,3],
[38,29,16,4],
[38,29,16,5],
[38,29,16,6],
[38,29,16,8],
[38,29,16,9],
[38,29,16,10],
[38,29,16,13],
[38,29,16,14],
[38,29,16,15],
[38,29,17,0],
[38,29,17,1],
[38,29,17,2],
[38,29,17,3],
[38,29,17,4],
[38,29,17,5],
[38,29,17,6],
[38,29,17,8],
[38,29,17,9],
[38,29,17,10],
[38,29,17,13],
[38,29,17,14],
[38,29,17,15],
[38,29,17,16],
[38,29,18,0],
[38,29,18,1],
[38,29,18,2],
[38,29,18,3],
[38,29,18,4],
[38,29,18,5],
[38,29,18,6],
[38,29,18,8],
[38,29,18,10],
[38,29,18,13],
[38,29,18,14],
[38,29,18,16],
[38,29,18,17],
[38,29,19,0],
[38,29,19,1],
[38,29,19,2],
[38,29,19,3],
[38,29,19,4],
[38,29,19,5],
[38,29,19,6],
[38,29,19,8],
[38,29,19,9],
[38,29,19,10],
[38,29,19,15],
[38,29,19,16],
[38,29,19,17],
[38,29,19,18],
[38,29,20,0],
[38,29,20,1],
[38,29,20,2],
[38,29,20,3],
[38,29,20,4],
[38,29,20,5],
[38,29,20,6],
[38,29,20,8],
[38,29,20,9],
[38,29,20,13],
[38,29,20,14],
[38,29,20,15],
[38,29,20,16],
[38,29,20,17],
[38,29,20,18],
[38,29,20,19],
[38,29,21,0],
[38,29,21,1],
[38,29,21,2],
[38,29,21,4],
[38,29,21,9],
[38,29,21,10],
[38,29,21,13],
[38,29,21,14],
[38,29,21,15],
[38,29,21,16],
[38,29,21,17],
[38,29,21,18],
[38,29,21,19],
[38,29,21,20],
[38,29,22,0],
[38,29,22,1],
[38,29,22,2],
[38,29,22,3],
[38,29,22,4],
[38,29,22,5],
[38,29,22,6],
[38,29,22,8],
[38,29,22,9],
[38,29,22,13],
[38,29,22,14],
[38,29,22,15],
[38,29,22,16],
[38,29,22,17],
[38,29,22,18],
[38,29,22,19],
[38,29,22,21],
[38,29,23,0],
[38,29,23,1],
[38,29,23,2],
[38,29,23,3],
[38,29,23,4],
[38,29,23,5],
[38,29,23,6],
[38,29,23,8],
[38,29,23,9],
[38,29,23,10],
[38,29,23,13],
[38,29,23,14],
[38,29,23,15],
[38,29,23,16],
[38,29,23,17],
[38,29,23,18],
[38,29,23,19],
[38,29,23,20],
[38,29,23,21],
[38,29,23,22],
[38,29,24,0],
[38,29,24,1],
[38,29,24,2],
[38,29,24,3],
[38,29,24,4],
[38,29,24,5],
[38,29,24,6],
[38,29,24,8],
[38,29,24,9],
[38,29,24,10],
[38,29,24,13],
[38,29,24,14],
[38,29,24,15],
[38,29,24,17],
[38,29,24,18],
[38,29,24,19],
[38,29,24,20],
[38,29,24,21],
[38,29,24,22],
[38,29,24,23],
[38,29,26,0],
[38,29,26,1],
[38,29,26,2],
[38,29,26,3],
[38,29,26,4],
[38,29,26,5],
[38,29,26,6],
[38,29,26,8],
[38,29,26,10],
[38,29,26,13],
[38,29,26,14],
[38,29,26,16],
[38,29,26,17],
[38,29,26,19],
[38,29,26,20],
[38,29,26,21],
[38,29,26,22],
[38,29,26,23],
[38,29,26,24],
[38,29,27,0],
[38,29,27,1],
[38,29,27,2],
[38,29,27,3],
[38,29,27,4],
[38,29,27,5],
[38,29,27,6],
[38,29,27,8],
[38,29,27,9],
[38,29,27,13],
[38,29,27,14],
[38,29,27,15],
[38,29,27,16],
[38,29,27,17],
[38,29,27,18],
[38,29,27,19],
[38,29,27,21],
[38,29,27,23],
[38,29,27,24],
[38,29,27,26],
[38,30,3,0],
[38,30,3,1],
[38,30,3,2],
[38,30,4,0],
[38,30,4,1],
[38,30,4,2],
[38,30,4,3],
[38,30,5,0],
[38,30,5,1],
[38,30,5,2],
[38,30,5,4],
[38,30,6,0],
[38,30,6,1],
[38,30,6,2],
[38,30,6,4],
[38,30,8,0],
[38,30,8,1],
[38,30,8,2],
[38,30,8,4],
[38,30,9,0],
[38,30,9,1],
[38,30,9,2],
[38,30,9,3],
[38,30,9,4],
[38,30,9,5],
[38,30,9,6],
[38,30,9,8],
[38,30,10,0],
[38,30,10,1],
[38,30,10,2],
[38,30,10,3],
[38,30,10,4],
[38,30,10,5],
[38,30,10,6],
[38,30,10,8],
[38,30,10,9],
[38,30,11,0],
[38,30,11,1],
[38,30,11,2],
[38,30,11,3],
[38,30,11,4],
[38,30,11,5],
[38,30,11,6],
[38,30,11,8],
[38,30,11,9],
[38,30,11,10],
[38,30,12,0],
[38,30,12,1],
[38,30,12,2],
[38,30,12,3],
[38,30,12,4],
[38,30,12,5],
[38,30,12,6],
[38,30,12,8],
[38,30,12,9],
[38,30,12,10],
[38,30,13,0],
[38,30,13,1],
[38,30,13,2],
[38,30,13,3],
[38,30,13,4],
[38,30,13,5],
[38,30,13,6],
[38,30,13,8],
[38,30,13,9],
[38,30,13,10],
[38,30,13,11],
[38,30,13,12],
[38,30,14,0],
[38,30,14,1],
[38,30,14,2],
[38,30,14,3],
[38,30,14,4],
[38,30,14,5],
[38,30,14,6],
[38,30,14,8],
[38,30,14,9],
[38,30,14,10],
[38,30,14,11],
[38,30,14,12],
[38,30,15,0],
[38,30,15,1],
[38,30,15,2],
[38,30,15,3],
[38,30,15,4],
[38,30,15,5],
[38,30,15,6],
[38,30,15,8],
[38,30,15,10],
[38,30,15,11],
[38,30,15,12],
[38,30,15,13],
[38,30,15,14],
[38,30,16,0],
[38,30,16,1],
[38,30,16,2],
[38,30,16,3],
[38,30,16,4],
[38,30,16,5],
[38,30,16,6],
[38,30,16,8],
[38,30,16,9],
[38,30,16,10],
[38,30,16,11],
[38,30,16,12],
[38,30,16,13],
[38,30,16,14],
[38,30,16,15],
[38,30,17,0],
[38,30,17,1],
[38,30,17,2],
[38,30,17,3],
[38,30,17,4],
[38,30,17,5],
[38,30,17,6],
[38,30,17,8],
[38,30,17,9],
[38,30,17,10],
[38,30,17,11],
[38,30,17,12],
[38,30,17,13],
[38,30,17,14],
[38,30,17,15],
[38,30,17,16],
[38,30,18,0],
[38,30,18,1],
[38,30,18,2],
[38,30,18,3],
[38,30,18,4],
[38,30,18,5],
[38,30,18,6],
[38,30,18,8],
[38,30,18,10],
[38,30,18,11],
[38,30,18,12],
[38,30,18,13],
[38,30,18,14],
[38,30,18,16],
[38,30,18,17],
[38,30,19,0],
[38,30,19,1],
[38,30,19,2],
[38,30,19,3],
[38,30,19,4],
[38,30,19,5],
[38,30,19,6],
[38,30,19,8],
[38,30,19,9],
[38,30,19,10],
[38,30,19,11],
[38,30,19,12],
[38,30,19,15],
[38,30,19,16],
[38,30,19,17],
[38,30,19,18],
[38,30,20,0],
[38,30,20,1],
[38,30,20,2],
[38,30,20,3],
[38,30,20,4],
[38,30,20,5],
[38,30,20,6],
[38,30,20,8],
[38,30,20,9],
[38,30,20,11],
[38,30,20,12],
[38,30,20,13],
[38,30,20,14],
[38,30,20,15],
[38,30,20,16],
[38,30,20,17],
[38,30,20,18],
[38,30,20,19],
[38,30,21,0],
[38,30,21,1],
[38,30,21,2],
[38,30,21,4],
[38,30,21,9],
[38,30,21,10],
[38,30,21,11],
[38,30,21,12],
[38,30,21,13],
[38,30,21,14],
[38,30,21,15],
[38,30,21,16],
[38,30,21,17],
[38,30,21,18],
[38,30,21,19],
[38,30,21,20],
[38,30,22,0],
[38,30,22,1],
[38,30,22,2],
[38,30,22,3],
[38,30,22,4],
[38,30,22,5],
[38,30,22,6],
[38,30,22,8],
[38,30,22,9],
[38,30,22,11],
[38,30,22,12],
[38,30,22,13],
[38,30,22,14],
[38,30,22,15],
[38,30,22,16],
[38,30,22,17],
[38,30,22,18],
[38,30,22,19],
[38,30,22,21],
[38,30,24,0],
[38,30,24,1],
[38,30,24,2],
[38,30,24,3],
[38,30,24,4],
[38,30,24,5],
[38,30,24,6],
[38,30,24,8],
[38,30,24,9],
[38,30,24,10],
[38,30,24,11],
[38,30,24,12],
[38,30,24,13],
[38,30,24,14],
[38,30,24,15],
[38,30,24,17],
[38,30,24,18],
[38,30,24,19],
[38,30,24,20],
[38,30,24,21],
[38,30,24,22],
[38,30,26,0],
[38,30,26,1],
[38,30,26,2],
[38,30,26,3],
[38,30,26,4],
[38,30,26,5],
[38,30,26,6],
[38,30,26,8],
[38,30,26,10],
[38,30,26,11],
[38,30,26,12],
[38,30,26,13],
[38,30,26,14],
[38,30,26,16],
[38,30,26,17],
[38,30,26,19],
[38,30,26,20],
[38,30,26,21],
[38,30,26,22],
[38,30,26,24],
[38,30,27,0],
[38,30,27,1],
[38,30,27,2],
[38,30,27,3],
[38,30,27,4],
[38,30,27,5],
[38,30,27,6],
[38,30,27,8],
[38,30,27,9],
[38,30,27,11],
[38,30,27,12],
[38,30,27,13],
[38,30,27,14],
[38,30,27,15],
[38,30,27,16],
[38,30,27,17],
[38,30,27,18],
[38,30,27,19],
[38,30,27,21],
[38,30,27,24],
[38,30,27,26],
[38,30,29,0],
[38,30,29,1],
[38,30,29,2],
[38,30,29,3],
[38,30,29,4],
[38,30,29,5],
[38,30,29,6],
[38,30,29,8],
[38,30,29,9],
[38,30,29,10],
[38,30,29,13],
[38,30,29,14],
[38,30,29,15],
[38,30,29,16],
[38,30,29,17],
[38,30,29,18],
[38,30,29,19],
[38,30,29,20],
[38,30,29,21],
[38,30,29,22],
[38,30,29,24],
[38,30,29,26],
[38,30,29,27],
[38,31,3,0],
[38,31,3,1],
[38,31,3,2],
[38,31,4,0],
[38,31,4,1],
[38,31,4,2],
[38,31,4,3],
[38,31,5,0],
[38,31,5,1],
[38,31,5,2],
[38,31,5,4],
[38,31,6,0],
[38,31,6,1],
[38,31,6,2],
[38,31,6,4],
[38,31,8,0],
[38,31,8,1],
[38,31,8,2],
[38,31,8,4],
[38,31,9,0],
[38,31,9,1],
[38,31,9,2],
[38,31,9,3],
[38,31,9,4],
[38,31,9,5],
[38,31,9,6],
[38,31,9,8],
[38,31,10,0],
[38,31,10,1],
[38,31,10,2],
[38,31,10,3],
[38,31,10,4],
[38,31,10,5],
[38,31,10,6],
[38,31,10,8],
[38,31,10,9],
[38,31,11,0],
[38,31,11,1],
[38,31,11,2],
[38,31,11,3],
[38,31,11,4],
[38,31,11,5],
[38,31,11,6],
[38,31,11,8],
[38,31,11,9],
[38,31,11,10],
[38,31,12,0],
[38,31,12,1],
[38,31,12,2],
[38,31,12,3],
[38,31,12,4],
[38,31,12,5],
[38,31,12,6],
[38,31,12,8],
[38,31,12,9],
[38,31,12,10],
[38,31,13,0],
[38,31,13,1],
[38,31,13,2],
[38,31,13,3],
[38,31,13,4],
[38,31,13,5],
[38,31,13,6],
[38,31,13,8],
[38,31,13,9],
[38,31,13,10],
[38,31,13,11],
[38,31,13,12],
[38,31,14,0],
[38,31,14,1],
[38,31,14,2],
[38,31,14,3],
[38,31,14,4],
[38,31,14,5],
[38,31,14,6],
[38,31,14,8],
[38,31,14,9],
[38,31,14,10],
[38,31,14,11],
[38,31,14,12],
[38,31,15,0],
[38,31,15,1],
[38,31,15,2],
[38,31,15,3],
[38,31,15,4],
[38,31,15,5],
[38,31,15,6],
[38,31,15,8],
[38,31,15,10],
[38,31,15,11],
[38,31,15,12],
[38,31,15,13],
[38,31,15,14],
[38,31,16,0],
[38,31,16,1],
[38,31,16,2],
[38,31,16,3],
[38,31,16,4],
[38,31,16,5],
[38,31,16,6],
[38,31,16,8],
[38,31,16,9],
[38,31,16,10],
[38,31,16,11],
[38,31,16,12],
[38,31,16,13],
[38,31,16,14],
[38,31,16,15],
[38,31,17,0],
[38,31,17,1],
[38,31,17,2],
[38,31,17,3],
[38,31,17,4],
[38,31,17,5],
[38,31,17,6],
[38,31,17,8],
[38,31,17,9],
[38,31,17,10],
[38,31,17,11],
[38,31,17,12],
[38,31,17,13],
[38,31,17,14],
[38,31,17,15],
[38,31,17,16],
[38,31,18,0],
[38,31,18,1],
[38,31,18,2],
[38,31,18,3],
[38,31,18,4],
[38,31,18,5],
[38,31,18,6],
[38,31,18,8],
[38,31,18,10],
[38,31,18,11],
[38,31,18,12],
[38,31,18,13],
[38,31,18,14],
[38,31,18,16],
[38,31,18,17],
[38,31,19,0],
[38,31,19,1],
[38,31,19,2],
[38,31,19,3],
[38,31,19,4],
[38,31,19,5],
[38,31,19,6],
[38,31,19,8],
[38,31,19,9],
[38,31,19,10],
[38,31,19,11],
[38,31,19,12],
[38,31,19,15],
[38,31,19,16],
[38,31,19,17],
[38,31,19,18],
[38,31,20,0],
[38,31,20,1],
[38,31,20,2],
[38,31,20,3],
[38,31,20,4],
[38,31,20,5],
[38,31,20,6],
[38,31,20,8],
[38,31,20,9],
[38,31,20,11],
[38,31,20,12],
[38,31,20,13],
[38,31,20,14],
[38,31,20,15],
[38,31,20,16],
[38,31,20,17],
[38,31,20,18],
[38,31,20,19],
[38,31,21,0],
[38,31,21,1],
[38,31,21,2],
[38,31,21,4],
[38,31,21,9],
[38,31,21,10],
[38,31,21,11],
[38,31,21,12],
[38,31,21,13],
[38,31,21,14],
[38,31,21,15],
[38,31,21,16],
[38,31,21,17],
[38,31,21,18],
[38,31,21,19],
[38,31,21,20],
[38,31,22,0],
[38,31,22,1],
[38,31,22,2],
[38,31,22,3],
[38,31,22,4],
[38,31,22,5],
[38,31,22,6],
[38,31,22,8],
[38,31,22,9],
[38,31,22,11],
[38,31,22,12],
[38,31,22,13],
[38,31,22,14],
[38,31,22,15],
[38,31,22,16],
[38,31,22,17],
[38,31,22,18],
[38,31,22,19],
[38,31,22,21],
[38,31,24,0],
[38,31,24,1],
[38,31,24,2],
[38,31,24,3],
[38,31,24,4],
[38,31,24,5],
[38,31,24,6],
[38,31,24,8],
[38,31,24,9],
[38,31,24,10],
[38,31,24,11],
[38,31,24,12],
[38,31,24,13],
[38,31,24,14],
[38,31,24,15],
[38,31,24,17],
[38,31,24,18],
[38,31,24,19],
[38,31,24,20],
[38,31,24,21],
[38,31,24,22],
[38,31,26,0],
[38,31,26,1],
[38,31,26,2],
[38,31,26,3],
[38,31,26,4],
[38,31,26,5],
[38,31,26,6],
[38,31,26,8],
[38,31,26,10],
[38,31,26,11],
[38,31,26,12],
[38,31,26,13],
[38,31,26,14],
[38,31,26,16],
[38,31,26,17],
[38,31,26,19],
[38,31,26,20],
[38,31,26,21],
[38,31,26,22],
[38,31,26,24],
[38,31,27,0],
[38,31,27,1],
[38,31,27,2],
[38,31,27,3],
[38,31,27,4],
[38,31,27,5],
[38,31,27,6],
[38,31,27,8],
[38,31,27,9],
[38,31,27,11],
[38,31,27,12],
[38,31,27,13],
[38,31,27,14],
[38,31,27,15],
[38,31,27,16],
[38,31,27,17],
[38,31,27,18],
[38,31,27,19],
[38,31,27,21],
[38,31,27,24],
[38,31,27,26],
[38,31,29,0],
[38,31,29,1],
[38,31,29,2],
[38,31,29,3],
[38,31,29,4],
[38,31,29,5],
[38,31,29,6],
[38,31,29,8],
[38,31,29,9],
[38,31,29,10],
[38,31,29,13],
[38,31,29,14],
[38,31,29,15],
[38,31,29,16],
[38,31,29,17],
[38,31,29,18],
[38,31,29,19],
[38,31,29,20],
[38,31,29,21],
[38,31,29,22],
[38,31,29,24],
[38,31,29,26],
[38,31,29,27],
[38,32,3,0],
[38,32,3,1],
[38,32,3,2],
[38,32,4,0],
[38,32,4,1],
[38,32,4,2],
[38,32,4,3],
[38,32,5,0],
[38,32,5,1],
[38,32,5,2],
[38,32,5,4],
[38,32,6,0],
[38,32,6,1],
[38,32,6,2],
[38,32,6,4],
[38,32,8,0],
[38,32,8,1],
[38,32,8,2],
[38,32,8,4],
[38,32,9,0],
[38,32,9,1],
[38,32,9,2],
[38,32,9,3],
[38,32,9,4],
[38,32,9,5],
[38,32,9,6],
[38,32,9,8],
[38,32,10,0],
[38,32,10,1],
[38,32,10,2],
[38,32,10,3],
[38,32,10,4],
[38,32,10,5],
[38,32,10,6],
[38,32,10,8],
[38,32,10,9],
[38,32,13,0],
[38,32,13,1],
[38,32,13,2],
[38,32,13,3],
[38,32,13,4],
[38,32,13,5],
[38,32,13,6],
[38,32,13,8],
[38,32,13,9],
[38,32,13,10],
[38,32,14,0],
[38,32,14,1],
[38,32,14,2],
[38,32,14,3],
[38,32,14,4],
[38,32,14,5],
[38,32,14,6],
[38,32,14,8],
[38,32,14,9],
[38,32,14,10],
[38,32,15,0],
[38,32,15,1],
[38,32,15,2],
[38,32,15,3],
[38,32,15,4],
[38,32,15,5],
[38,32,15,6],
[38,32,15,8],
[38,32,15,10],
[38,32,15,13],
[38,32,15,14],
[38,32,16,0],
[38,32,16,1],
[38,32,16,2],
[38,32,16,3],
[38,32,16,4],
[38,32,16,5],
[38,32,16,6],
[38,32,16,8],
[38,32,16,9],
[38,32,16,10],
[38,32,16,13],
[38,32,16,14],
[38,32,16,15],
[38,32,17,0],
[38,32,17,1],
[38,32,17,2],
[38,32,17,3],
[38,32,17,4],
[38,32,17,5],
[38,32,17,6],
[38,32,17,8],
[38,32,17,9],
[38,32,17,10],
[38,32,17,13],
[38,32,17,14],
[38,32,17,15],
[38,32,17,16],
[38,32,18,0],
[38,32,18,1],
[38,32,18,2],
[38,32,18,3],
[38,32,18,4],
[38,32,18,5],
[38,32,18,6],
[38,32,18,8],
[38,32,18,10],
[38,32,18,13],
[38,32,18,14],
[38,32,18,16],
[38,32,18,17],
[38,32,19,0],
[38,32,19,1],
[38,32,19,2],
[38,32,19,3],
[38,32,19,4],
[38,32,19,5],
[38,32,19,6],
[38,32,19,8],
[38,32,19,9],
[38,32,19,10],
[38,32,19,15],
[38,32,19,16],
[38,32,19,17],
[38,32,19,18],
[38,32,20,0],
[38,32,20,1],
[38,32,20,2],
[38,32,20,3],
[38,32,20,4],
[38,32,20,5],
[38,32,20,6],
[38,32,20,8],
[38,32,20,9],
[38,32,20,13],
[38,32,20,14],
[38,32,20,15],
[38,32,20,16],
[38,32,20,17],
[38,32,20,18],
[38,32,20,19],
[38,32,21,0],
[38,32,21,1],
[38,32,21,2],
[38,32,21,4],
[38,32,21,9],
[38,32,21,10],
[38,32,21,13],
[38,32,21,14],
[38,32,21,15],
[38,32,21,16],
[38,32,21,17],
[38,32,21,18],
[38,32,21,19],
[38,32,21,20],
[38,32,22,0],
[38,32,22,1],
[38,32,22,2],
[38,32,22,3],
[38,32,22,4],
[38,32,22,5],
[38,32,22,6],
[38,32,22,8],
[38,32,22,9],
[38,32,22,13],
[38,32,22,14],
[38,32,22,15],
[38,32,22,16],
[38,32,22,17],
[38,32,22,18],
[38,32,22,19],
[38,32,22,21],
[38,32,23,0],
[38,32,23,1],
[38,32,23,2],
[38,32,23,3],
[38,32,23,4],
[38,32,23,5],
[38,32,23,6],
[38,32,23,8],
[38,32,23,9],
[38,32,23,10],
[38,32,23,13],
[38,32,23,14],
[38,32,23,15],
[38,32,23,16],
[38,32,23,17],
[38,32,23,18],
[38,32,23,19],
[38,32,23,20],
[38,32,23,21],
[38,32,23,22],
[38,32,24,0],
[38,32,24,1],
[38,32,24,2],
[38,32,24,3],
[38,32,24,4],
[38,32,24,5],
[38,32,24,6],
[38,32,24,8],
[38,32,24,9],
[38,32,24,10],
[38,32,24,13],
[38,32,24,14],
[38,32,24,15],
[38,32,24,17],
[38,32,24,18],
[38,32,24,19],
[38,32,24,20],
[38,32,24,21],
[38,32,24,22],
[38,32,24,23],
[38,32,26,0],
[38,32,26,1],
[38,32,26,2],
[38,32,26,3],
[38,32,26,4],
[38,32,26,5],
[38,32,26,6],
[38,32,26,8],
[38,32,26,10],
[38,32,26,13],
[38,32,26,14],
[38,32,26,16],
[38,32,26,17],
[38,32,26,19],
[38,32,26,20],
[38,32,26,21],
[38,32,26,22],
[38,32,26,23],
[38,32,26,24],
[38,32,27,0],
[38,32,27,1],
[38,32,27,2],
[38,32,27,3],
[38,32,27,4],
[38,32,27,5],
[38,32,27,6],
[38,32,27,8],
[38,32,27,9],
[38,32,27,13],
[38,32,27,14],
[38,32,27,15],
[38,32,27,16],
[38,32,27,17],
[38,32,27,18],
[38,32,27,19],
[38,32,27,21],
[38,32,27,23],
[38,32,27,24],
[38,32,27,26],
[38,32,30,0],
[38,32,30,1],
[38,32,30,2],
[38,32,30,3],
[38,32,30,4],
[38,32,30,5],
[38,32,30,6],
[38,32,30,8],
[38,32,30,9],
[38,32,30,10],
[38,32,30,13],
[38,32,30,14],
[38,32,30,15],
[38,32,30,16],
[38,32,30,17],
[38,32,30,18],
[38,32,30,19],
[38,32,30,20],
[38,32,30,21],
[38,32,30,22],
[38,32,30,24],
[38,32,30,26],
[38,32,31,0],
[38,32,31,1],
[38,32,31,2],
[38,32,31,3],
[38,32,31,4],
[38,32,31,5],
[38,32,31,6],
[38,32,31,8],
[38,32,31,9],
[38,32,31,10],
[38,32,31,13],
[38,32,31,14],
[38,32,31,15],
[38,32,31,16],
[38,32,31,17],
[38,32,31,18],
[38,32,31,19],
[38,32,31,20],
[38,32,31,21],
[38,32,31,22],
[38,32,31,24],
[38,32,31,27],
[38,33,3,0],
[38,33,3,1],
[38,33,3,2],
[38,33,4,0],
[38,33,4,1],
[38,33,4,2],
[38,33,4,3],
[38,33,5,0],
[38,33,5,1],
[38,33,5,2],
[38,33,5,4],
[38,33,6,0],
[38,33,6,1],
[38,33,6,2],
[38,33,6,4],
[38,33,8,0],
[38,33,8,1],
[38,33,8,2],
[38,33,8,4],
[38,33,9,0],
[38,33,9,1],
[38,33,9,2],
[38,33,9,3],
[38,33,9,4],
[38,33,9,5],
[38,33,9,6],
[38,33,9,8],
[38,33,10,0],
[38,33,10,1],
[38,33,10,2],
[38,33,10,3],
[38,33,10,4],
[38,33,10,5],
[38,33,10,6],
[38,33,10,8],
[38,33,10,9],
[38,33,13,0],
[38,33,13,1],
[38,33,13,2],
[38,33,13,3],
[38,33,13,4],
[38,33,13,5],
[38,33,13,6],
[38,33,13,8],
[38,33,13,9],
[38,33,13,10],
[38,33,14,0],
[38,33,14,1],
[38,33,14,2],
[38,33,14,3],
[38,33,14,4],
[38,33,14,5],
[38,33,14,6],
[38,33,14,8],
[38,33,14,9],
[38,33,14,10],
[38,33,15,0],
[38,33,15,1],
[38,33,15,2],
[38,33,15,3],
[38,33,15,4],
[38,33,15,5],
[38,33,15,6],
[38,33,15,8],
[38,33,15,10],
[38,33,15,13],
[38,33,15,14],
[38,33,16,0],
[38,33,16,1],
[38,33,16,2],
[38,33,16,3],
[38,33,16,4],
[38,33,16,5],
[38,33,16,6],
[38,33,16,8],
[38,33,16,9],
[38,33,16,10],
[38,33,16,13],
[38,33,16,14],
[38,33,16,15],
[38,33,17,0],
[38,33,17,1],
[38,33,17,2],
[38,33,17,3],
[38,33,17,4],
[38,33,17,5],
[38,33,17,6],
[38,33,17,8],
[38,33,17,9],
[38,33,17,10],
[38,33,17,13],
[38,33,17,14],
[38,33,17,15],
[38,33,17,16],
[38,33,18,0],
[38,33,18,1],
[38,33,18,2],
[38,33,18,3],
[38,33,18,4],
[38,33,18,5],
[38,33,18,6],
[38,33,18,8],
[38,33,18,10],
[38,33,18,13],
[38,33,18,14],
[38,33,18,16],
[38,33,18,17],
[38,33,19,0],
[38,33,19,1],
[38,33,19,2],
[38,33,19,3],
[38,33,19,4],
[38,33,19,5],
[38,33,19,6],
[38,33,19,8],
[38,33,19,9],
[38,33,19,10],
[38,33,19,15],
[38,33,19,16],
[38,33,19,17],
[38,33,19,18],
[38,33,20,0],
[38,33,20,1],
[38,33,20,2],
[38,33,20,3],
[38,33,20,4],
[38,33,20,5],
[38,33,20,6],
[38,33,20,8],
[38,33,20,9],
[38,33,20,13],
[38,33,20,14],
[38,33,20,15],
[38,33,20,16],
[38,33,20,17],
[38,33,20,18],
[38,33,20,19],
[38,33,21,0],
[38,33,21,1],
[38,33,21,2],
[38,33,21,4],
[38,33,21,9],
[38,33,21,10],
[38,33,21,13],
[38,33,21,14],
[38,33,21,15],
[38,33,21,16],
[38,33,21,17],
[38,33,21,18],
[38,33,21,19],
[38,33,21,20],
[38,33,22,0],
[38,33,22,1],
[38,33,22,2],
[38,33,22,3],
[38,33,22,4],
[38,33,22,5],
[38,33,22,6],
[38,33,22,8],
[38,33,22,9],
[38,33,22,13],
[38,33,22,14],
[38,33,22,15],
[38,33,22,16],
[38,33,22,17],
[38,33,22,18],
[38,33,22,19],
[38,33,22,21],
[38,33,23,0],
[38,33,23,1],
[38,33,23,2],
[38,33,23,3],
[38,33,23,4],
[38,33,23,5],
[38,33,23,6],
[38,33,23,8],
[38,33,23,9],
[38,33,23,10],
[38,33,23,13],
[38,33,23,14],
[38,33,23,15],
[38,33,23,16],
[38,33,23,17],
[38,33,23,18],
[38,33,23,19],
[38,33,23,20],
[38,33,23,21],
[38,33,23,22],
[38,33,24,0],
[38,33,24,1],
[38,33,24,2],
[38,33,24,3],
[38,33,24,4],
[38,33,24,5],
[38,33,24,6],
[38,33,24,8],
[38,33,24,9],
[38,33,24,10],
[38,33,24,13],
[38,33,24,14],
[38,33,24,15],
[38,33,24,17],
[38,33,24,18],
[38,33,24,19],
[38,33,24,20],
[38,33,24,21],
[38,33,24,22],
[38,33,24,23],
[38,33,26,0],
[38,33,26,1],
[38,33,26,2],
[38,33,26,3],
[38,33,26,4],
[38,33,26,5],
[38,33,26,6],
[38,33,26,8],
[38,33,26,10],
[38,33,26,13],
[38,33,26,14],
[38,33,26,16],
[38,33,26,17],
[38,33,26,19],
[38,33,26,20],
[38,33,26,21],
[38,33,26,22],
[38,33,26,23],
[38,33,26,24],
[38,33,27,0],
[38,33,27,1],
[38,33,27,2],
[38,33,27,3],
[38,33,27,4],
[38,33,27,5],
[38,33,27,6],
[38,33,27,8],
[38,33,27,9],
[38,33,27,13],
[38,33,27,14],
[38,33,27,15],
[38,33,27,16],
[38,33,27,17],
[38,33,27,18],
[38,33,27,19],
[38,33,27,21],
[38,33,27,23],
[38,33,27,24],
[38,33,27,26],
[38,33,30,0],
[38,33,30,1],
[38,33,30,2],
[38,33,30,3],
[38,33,30,4],
[38,33,30,5],
[38,33,30,6],
[38,33,30,8],
[38,33,30,9],
[38,33,30,10],
[38,33,30,13],
[38,33,30,14],
[38,33,30,15],
[38,33,30,16],
[38,33,30,17],
[38,33,30,18],
[38,33,30,19],
[38,33,30,20],
[38,33,30,21],
[38,33,30,22],
[38,33,30,24],
[38,33,30,27],
[38,33,31,0],
[38,33,31,1],
[38,33,31,2],
[38,33,31,3],
[38,33,31,4],
[38,33,31,5],
[38,33,31,6],
[38,33,31,8],
[38,33,31,9],
[38,33,31,10],
[38,33,31,13],
[38,33,31,14],
[38,33,31,15],
[38,33,31,16],
[38,33,31,17],
[38,33,31,18],
[38,33,31,19],
[38,33,31,20],
[38,33,31,21],
[38,33,31,22],
[38,33,31,24],
[38,33,31,26],
[38,33,31,27],
[38,35,3,0],
[38,35,3,1],
[38,35,3,2],
[38,35,4,0],
[38,35,4,1],
[38,35,4,2],
[38,35,4,3],
[38,35,5,0],
[38,35,5,1],
[38,35,5,2],
[38,35,5,4],
[38,35,6,0],
[38,35,6,1],
[38,35,6,2],
[38,35,6,4],
[38,35,8,0],
[38,35,8,1],
[38,35,8,2],
[38,35,8,4],
[38,35,10,0],
[38,35,10,1],
[38,35,10,2],
[38,35,10,3],
[38,35,10,4],
[38,35,10,5],
[38,35,10,6],
[38,35,10,8],
[38,35,11,0],
[38,35,11,1],
[38,35,11,2],
[38,35,11,3],
[38,35,11,4],
[38,35,11,5],
[38,35,11,6],
[38,35,11,8],
[38,35,11,10],
[38,35,12,0],
[38,35,12,1],
[38,35,12,2],
[38,35,12,3],
[38,35,12,4],
[38,35,12,5],
[38,35,12,6],
[38,35,12,8],
[38,35,12,10],
[38,35,13,0],
[38,35,13,1],
[38,35,13,2],
[38,35,13,3],
[38,35,13,4],
[38,35,13,5],
[38,35,13,6],
[38,35,13,8],
[38,35,13,10],
[38,35,13,11],
[38,35,13,12],
[38,35,14,0],
[38,35,14,1],
[38,35,14,2],
[38,35,14,3],
[38,35,14,4],
[38,35,14,5],
[38,35,14,6],
[38,35,14,8],
[38,35,14,10],
[38,35,14,11],
[38,35,14,12],
[38,35,16,0],
[38,35,16,1],
[38,35,16,2],
[38,35,16,3],
[38,35,16,4],
[38,35,16,5],
[38,35,16,6],
[38,35,16,8],
[38,35,16,10],
[38,35,16,11],
[38,35,16,12],
[38,35,16,13],
[38,35,16,14],
[38,35,17,0],
[38,35,17,1],
[38,35,17,2],
[38,35,17,3],
[38,35,17,4],
[38,35,17,5],
[38,35,17,6],
[38,35,17,8],
[38,35,17,10],
[38,35,17,11],
[38,35,17,12],
[38,35,17,13],
[38,35,17,14],
[38,35,17,16],
[38,35,19,0],
[38,35,19,1],
[38,35,19,2],
[38,35,19,3],
[38,35,19,4],
[38,35,19,5],
[38,35,19,6],
[38,35,19,8],
[38,35,19,10],
[38,35,19,11],
[38,35,19,12],
[38,35,19,16],
[38,35,19,17],
[38,35,20,0],
[38,35,20,1],
[38,35,20,2],
[38,35,20,3],
[38,35,20,4],
[38,35,20,5],
[38,35,20,6],
[38,35,20,8],
[38,35,20,11],
[38,35,20,12],
[38,35,20,13],
[38,35,20,14],
[38,35,20,16],
[38,35,20,17],
[38,35,20,19],
[38,35,21,0],
[38,35,21,1],
[38,35,21,2],
[38,35,21,4],
[38,35,21,10],
[38,35,21,11],
[38,35,21,12],
[38,35,21,13],
[38,35,21,14],
[38,35,21,16],
[38,35,21,17],
[38,35,21,19],
[38,35,21,20],
[38,35,22,0],
[38,35,22,1],
[38,35,22,2],
[38,35,22,3],
[38,35,22,4],
[38,35,22,5],
[38,35,22,6],
[38,35,22,8],
[38,35,22,11],
[38,35,22,12],
[38,35,22,13],
[38,35,22,14],
[38,35,22,16],
[38,35,22,17],
[38,35,22,19],
[38,35,22,21],
[38,35,23,0],
[38,35,23,1],
[38,35,23,2],
[38,35,23,3],
[38,35,23,4],
[38,35,23,5],
[38,35,23,6],
[38,35,23,8],
[38,35,23,10],
[38,35,23,11],
[38,35,23,12],
[38,35,23,13],
[38,35,23,14],
[38,35,23,16],
[38,35,23,17],
[38,35,23,19],
[38,35,23,20],
[38,35,23,21],
[38,35,23,22],
[38,35,24,0],
[38,35,24,1],
[38,35,24,2],
[38,35,24,3],
[38,35,24,4],
[38,35,24,5],
[38,35,24,6],
[38,35,24,8],
[38,35,24,10],
[38,35,24,11],
[38,35,24,12],
[38,35,24,13],
[38,35,24,14],
[38,35,24,17],
[38,35,24,19],
[38,35,24,20],
[38,35,24,21],
[38,35,24,22],
[38,35,24,23],
[38,35,27,0],
[38,35,27,1],
[38,35,27,2],
[38,35,27,3],
[38,35,27,4],
[38,35,27,5],
[38,35,27,6],
[38,35,27,8],
[38,35,27,11],
[38,35,27,12],
[38,35,27,13],
[38,35,27,14],
[38,35,27,16],
[38,35,27,17],
[38,35,27,19],
[38,35,27,21],
[38,35,27,23],
[38,35,27,24],
[38,35,29,0],
[38,35,29,1],
[38,35,29,2],
[38,35,29,3],
[38,35,29,4],
[38,35,29,5],
[38,35,29,6],
[38,35,29,8],
[38,35,29,10],
[38,35,29,13],
[38,35,29,14],
[38,35,29,16],
[38,35,29,17],
[38,35,29,19],
[38,35,29,20],
[38,35,29,21],
[38,35,29,22],
[38,35,29,23],
[38,35,29,24],
[38,35,29,27],
[38,35,30,0],
[38,35,30,1],
[38,35,30,2],
[38,35,30,3],
[38,35,30,4],
[38,35,30,5],
[38,35,30,6],
[38,35,30,8],
[38,35,30,10],
[38,35,30,11],
[38,35,30,12],
[38,35,30,13],
[38,35,30,14],
[38,35,30,16],
[38,35,30,17],
[38,35,30,19],
[38,35,30,20],
[38,35,30,21],
[38,35,30,22],
[38,35,30,24],
[38,35,30,27],
[38,35,30,29],
[38,35,31,0],
[38,35,31,1],
[38,35,31,2],
[38,35,31,3],
[38,35,31,4],
[38,35,31,5],
[38,35,31,6],
[38,35,31,8],
[38,35,31,10],
[38,35,31,11],
[38,35,31,12],
[38,35,31,13],
[38,35,31,14],
[38,35,31,16],
[38,35,31,17],
[38,35,31,19],
[38,35,31,20],
[38,35,31,21],
[38,35,31,22],
[38,35,31,27],
[38,35,31,29],
[38,35,32,0],
[38,35,32,1],
[38,35,32,2],
[38,35,32,3],
[38,35,32,4],
[38,35,32,5],
[38,35,32,6],
[38,35,32,8],
[38,35,32,10],
[38,35,32,13],
[38,35,32,14],
[38,35,32,16],
[38,35,32,17],
[38,35,32,19],
[38,35,32,20],
[38,35,32,21],
[38,35,32,23],
[38,35,32,24],
[38,35,32,27],
[38,35,32,30],
[38,35,32,31],
[38,35,33,0],
[38,35,33,1],
[38,35,33,2],
[38,35,33,3],
[38,35,33,4],
[38,35,33,5],
[38,35,33,6],
[38,35,33,8],
[38,35,33,10],
[38,35,33,13],
[38,35,33,14],
[38,35,33,16],
[38,35,33,17],
[38,35,33,19],
[38,35,33,20],
[38,35,33,22],
[38,35,33,23],
[38,35,33,24],
[38,35,33,27],
[38,35,33,30],
[38,35,33,31],
[38,36,3,0],
[38,36,3,1],
[38,36,3,2],
[38,36,4,0],
[38,36,4,1],
[38,36,4,2],
[38,36,4,3],
[38,36,5,0],
[38,36,5,1],
[38,36,5,2],
[38,36,5,4],
[38,36,6,0],
[38,36,6,1],
[38,36,6,2],
[38,36,6,4],
[38,36,8,0],
[38,36,8,1],
[38,36,8,2],
[38,36,8,4],
[38,36,9,0],
[38,36,9,1],
[38,36,9,2],
[38,36,9,3],
[38,36,9,4],
[38,36,9,5],
[38,36,9,6],
[38,36,9,8],
[38,36,10,0],
[38,36,10,1],
[38,36,10,2],
[38,36,10,3],
[38,36,10,4],
[38,36,10,5],
[38,36,10,6],
[38,36,10,8],
[38,36,10,9],
[38,36,11,0],
[38,36,11,1],
[38,36,11,2],
[38,36,11,3],
[38,36,11,4],
[38,36,11,5],
[38,36,11,6],
[38,36,11,8],
[38,36,11,9],
[38,36,11,10],
[38,36,12,0],
[38,36,12,1],
[38,36,12,2],
[38,36,12,3],
[38,36,12,4],
[38,36,12,5],
[38,36,12,6],
[38,36,12,8],
[38,36,12,9],
[38,36,12,10],
[38,36,15,0],
[38,36,15,1],
[38,36,15,2],
[38,36,15,3],
[38,36,15,4],
[38,36,15,5],
[38,36,15,6],
[38,36,15,8],
[38,36,15,10],
[38,36,15,11],
[38,36,15,12],
[38,36,16,0],
[38,36,16,1],
[38,36,16,2],
[38,36,16,3],
[38,36,16,4],
[38,36,16,5],
[38,36,16,6],
[38,36,16,8],
[38,36,16,9],
[38,36,16,10],
[38,36,16,11],
[38,36,16,12],
[38,36,16,15],
[38,36,17,0],
[38,36,17,1],
[38,36,17,2],
[38,36,17,3],
[38,36,17,4],
[38,36,17,5],
[38,36,17,6],
[38,36,17,8],
[38,36,17,9],
[38,36,17,10],
[38,36,17,11],
[38,36,17,12],
[38,36,17,15],
[38,36,17,16],
[38,36,18,0],
[38,36,18,1],
[38,36,18,2],
[38,36,18,3],
[38,36,18,4],
[38,36,18,5],
[38,36,18,6],
[38,36,18,8],
[38,36,18,10],
[38,36,18,11],
[38,36,18,12],
[38,36,18,16],
[38,36,18,17],
[38,36,20,0],
[38,36,20,1],
[38,36,20,2],
[38,36,20,3],
[38,36,20,4],
[38,36,20,5],
[38,36,20,6],
[38,36,20,8],
[38,36,20,9],
[38,36,20,11],
[38,36,20,12],
[38,36,20,15],
[38,36,20,16],
[38,36,20,17],
[38,36,20,18],
[38,36,21,0],
[38,36,21,1],
[38,36,21,2],
[38,36,21,4],
[38,36,21,9],
[38,36,21,10],
[38,36,21,11],
[38,36,21,12],
[38,36,21,15],
[38,36,21,16],
[38,36,21,17],
[38,36,21,18],
[38,36,21,20],
[38,36,22,0],
[38,36,22,1],
[38,36,22,2],
[38,36,22,3],
[38,36,22,4],
[38,36,22,5],
[38,36,22,6],
[38,36,22,8],
[38,36,22,9],
[38,36,22,11],
[38,36,22,12],
[38,36,22,15],
[38,36,22,16],
[38,36,22,17],
[38,36,22,18],
[38,36,22,21],
[38,36,23,0],
[38,36,23,1],
[38,36,23,2],
[38,36,23,3],
[38,36,23,4],
[38,36,23,5],
[38,36,23,6],
[38,36,23,8],
[38,36,23,9],
[38,36,23,10],
[38,36,23,11],
[38,36,23,12],
[38,36,23,15],
[38,36,23,16],
[38,36,23,17],
[38,36,23,18],
[38,36,23,20],
[38,36,23,21],
[38,36,23,22],
[38,36,24,0],
[38,36,24,1],
[38,36,24,2],
[38,36,24,3],
[38,36,24,4],
[38,36,24,5],
[38,36,24,6],
[38,36,24,8],
[38,36,24,9],
[38,36,24,10],
[38,36,24,11],
[38,36,24,12],
[38,36,24,15],
[38,36,24,17],
[38,36,24,18],
[38,36,24,20],
[38,36,24,21],
[38,36,24,22],
[38,36,24,23],
[38,36,26,0],
[38,36,26,1],
[38,36,26,2],
[38,36,26,3],
[38,36,26,4],
[38,36,26,5],
[38,36,26,6],
[38,36,26,8],
[38,36,26,10],
[38,36,26,11],
[38,36,26,12],
[38,36,26,16],
[38,36,26,17],
[38,36,26,20],
[38,36,26,21],
[38,36,26,22],
[38,36,26,23],
[38,36,26,24],
[38,36,27,0],
[38,36,27,1],
[38,36,27,2],
[38,36,27,3],
[38,36,27,4],
[38,36,27,5],
[38,36,27,6],
[38,36,27,8],
[38,36,27,9],
[38,36,27,11],
[38,36,27,12],
[38,36,27,15],
[38,36,27,16],
[38,36,27,17],
[38,36,27,18],
[38,36,27,21],
[38,36,27,23],
[38,36,27,24],
[38,36,29,0],
[38,36,29,1],
[38,36,29,2],
[38,36,29,3],
[38,36,29,4],
[38,36,29,5],
[38,36,29,6],
[38,36,29,8],
[38,36,29,9],
[38,36,29,10],
[38,36,29,15],
[38,36,29,16],
[38,36,29,17],
[38,36,29,18],
[38,36,29,20],
[38,36,29,21],
[38,36,29,22],
[38,36,29,23],
[38,36,29,24],
[38,36,29,26],
[38,36,29,27],
[38,36,30,0],
[38,36,30,1],
[38,36,30,2],
[38,36,30,3],
[38,36,30,4],
[38,36,30,5],
[38,36,30,6],
[38,36,30,8],
[38,36,30,9],
[38,36,30,10],
[38,36,30,11],
[38,36,30,12],
[38,36,30,15],
[38,36,30,16],
[38,36,30,17],
[38,36,30,18],
[38,36,30,20],
[38,36,30,21],
[38,36,30,22],
[38,36,30,26],
[38,36,30,27],
[38,36,30,29],
[38,36,31,0],
[38,36,31,1],
[38,36,31,2],
[38,36,31,3],
[38,36,31,4],
[38,36,31,5],
[38,36,31,6],
[38,36,31,8],
[38,36,31,9],
[38,36,31,10],
[38,36,31,11],
[38,36,31,12],
[38,36,31,15],
[38,36,31,16],
[38,36,31,17],
[38,36,31,18],
[38,36,31,20],
[38,36,31,21],
[38,36,31,24],
[38,36,31,26],
[38,36,31,27],
[38,36,31,29],
[38,36,32,0],
[38,36,32,1],
[38,36,32,2],
[38,36,32,3],
[38,36,32,4],
[38,36,32,5],
[38,36,32,6],
[38,36,32,8],
[38,36,32,9],
[38,36,32,10],
[38,36,32,15],
[38,36,32,16],
[38,36,32,17],
[38,36,32,18],
[38,36,32,20],
[38,36,32,22],
[38,36,32,23],
[38,36,32,24],
[38,36,32,26],
[38,36,32,27],
[38,36,32,30],
[38,36,32,31],
[38,36,33,0],
[38,36,33,1],
[38,36,33,2],
[38,36,33,3],
[38,36,33,4],
[38,36,33,5],
[38,36,33,6],
[38,36,33,8],
[38,36,33,9],
[38,36,33,10],
[38,36,33,15],
[38,36,33,16],
[38,36,33,17],
[38,36,33,18],
[38,36,33,21],
[38,36,33,22],
[38,36,33,23],
[38,36,33,24],
[38,36,33,26],
[38,36,33,27],
[38,36,33,30],
[38,36,33,31],
[38,36,35,0],
[38,36,35,1],
[38,36,35,2],
[38,36,35,3],
[38,36,35,4],
[38,36,35,5],
[38,36,35,6],
[38,36,35,8],
[38,36,35,10],
[38,36,35,11],
[38,36,35,12],
[38,36,35,16],
[38,36,35,17],
[38,36,35,20],
[38,36,35,21],
[38,36,35,22],
[38,36,35,23],
[38,36,35,24],
[38,36,35,27],
[38,36,35,29],
[38,36,35,30],
[38,37,3,0],
[38,37,3,1],
[38,37,3,2],
[38,37,5,0],
[38,37,5,1],
[38,37,5,2],
[38,37,6,0],
[38,37,6,1],
[38,37,6,2],
[38,37,8,0],
[38,37,8,1],
[38,37,8,2],
[38,37,9,0],
[38,37,9,1],
[38,37,9,2],
[38,37,9,3],
[38,37,9,5],
[38,37,9,6],
[38,37,9,8],
[38,37,10,0],
[38,37,10,1],
[38,37,10,2],
[38,37,10,3],
[38,37,10,5],
[38,37,10,6],
[38,37,10,8],
[38,37,10,9],
[38,37,11,0],
[38,37,11,1],
[38,37,11,2],
[38,37,11,3],
[38,37,11,5],
[38,37,11,6],
[38,37,11,8],
[38,37,11,9],
[38,37,11,10],
[38,37,12,0],
[38,37,12,1],
[38,37,12,2],
[38,37,12,3],
[38,37,12,5],
[38,37,12,6],
[38,37,12,8],
[38,37,12,9],
[38,37,12,10],
[38,37,13,0],
[38,37,13,1],
[38,37,13,2],
[38,37,13,3],
[38,37,13,5],
[38,37,13,6],
[38,37,13,8],
[38,37,13,9],
[38,37,13,10],
[38,37,13,11],
[38,37,13,12],
[38,37,14,0],
[38,37,14,1],
[38,37,14,2],
[38,37,14,3],
[38,37,14,5],
[38,37,14,6],
[38,37,14,8],
[38,37,14,9],
[38,37,14,10],
[38,37,14,11],
[38,37,14,12],
[38,37,15,0],
[38,37,15,1],
[38,37,15,2],
[38,37,15,3],
[38,37,15,5],
[38,37,15,6],
[38,37,15,8],
[38,37,15,10],
[38,37,15,11],
[38,37,15,12],
[38,37,15,13],
[38,37,15,14],
[38,37,16,0],
[38,37,16,1],
[38,37,16,2],
[38,37,16,3],
[38,37,16,5],
[38,37,16,6],
[38,37,16,8],
[38,37,16,9],
[38,37,16,10],
[38,37,16,11],
[38,37,16,12],
[38,37,16,13],
[38,37,16,14],
[38,37,16,15],
[38,37,17,0],
[38,37,17,1],
[38,37,17,2],
[38,37,17,3],
[38,37,17,5],
[38,37,17,6],
[38,37,17,8],
[38,37,17,9],
[38,37,17,10],
[38,37,17,11],
[38,37,17,12],
[38,37,17,13],
[38,37,17,14],
[38,37,17,15],
[38,37,17,16],
[38,37,18,0],
[38,37,18,1],
[38,37,18,2],
[38,37,18,3],
[38,37,18,5],
[38,37,18,6],
[38,37,18,8],
[38,37,18,10],
[38,37,18,11],
[38,37,18,12],
[38,37,18,13],
[38,37,18,14],
[38,37,18,16],
[38,37,18,17],
[38,37,19,0],
[38,37,19,1],
[38,37,19,2],
[38,37,19,3],
[38,37,19,5],
[38,37,19,6],
[38,37,19,8],
[38,37,19,9],
[38,37,19,10],
[38,37,19,11],
[38,37,19,12],
[38,37,19,15],
[38,37,19,16],
[38,37,19,17],
[38,37,19,18],
[38,37,20,0],
[38,37,20,1],
[38,37,20,2],
[38,37,20,3],
[38,37,20,5],
[38,37,20,6],
[38,37,20,8],
[38,37,20,9],
[38,37,20,11],
[38,37,20,12],
[38,37,20,13],
[38,37,20,14],
[38,37,20,15],
[38,37,20,16],
[38,37,20,17],
[38,37,20,18],
[38,37,20,19],
[38,37,21,0],
[38,37,21,1],
[38,37,21,2],
[38,37,21,9],
[38,37,21,10],
[38,37,21,11],
[38,37,21,12],
[38,37,21,13],
[38,37,21,14],
[38,37,21,15],
[38,37,21,16],
[38,37,21,17],
[38,37,21,18],
[38,37,21,19],
[38,37,21,20],
[38,37,22,0],
[38,37,22,1],
[38,37,22,2],
[38,37,22,3],
[38,37,22,5],
[38,37,22,6],
[38,37,22,8],
[38,37,22,9],
[38,37,22,11],
[38,37,22,12],
[38,37,22,13],
[38,37,22,14],
[38,37,22,15],
[38,37,22,16],
[38,37,22,17],
[38,37,22,18],
[38,37,22,19],
[38,37,22,21],
[38,37,23,0],
[38,37,23,1],
[38,37,23,2],
[38,37,23,3],
[38,37,23,5],
[38,37,23,6],
[38,37,23,8],
[38,37,23,9],
[38,37,23,10],
[38,37,23,11],
[38,37,23,12],
[38,37,23,13],
[38,37,23,14],
[38,37,23,15],
[38,37,23,16],
[38,37,23,17],
[38,37,23,18],
[38,37,23,19],
[38,37,23,20],
[38,37,23,21],
[38,37,23,22],
[38,37,24,0],
[38,37,24,1],
[38,37,24,2],
[38,37,24,3],
[38,37,24,5],
[38,37,24,6],
[38,37,24,8],
[38,37,24,9],
[38,37,24,10],
[38,37,24,11],
[38,37,24,12],
[38,37,24,13],
[38,37,24,14],
[38,37,24,15],
[38,37,24,17],
[38,37,24,18],
[38,37,24,19],
[38,37,24,20],
[38,37,24,21],
[38,37,24,22],
[38,37,24,23],
[38,37,26,0],
[38,37,26,1],
[38,37,26,2],
[38,37,26,3],
[38,37,26,5],
[38,37,26,6],
[38,37,26,8],
[38,37,26,10],
[38,37,26,11],
[38,37,26,12],
[38,37,26,13],
[38,37,26,14],
[38,37,26,16],
[38,37,26,17],
[38,37,26,19],
[38,37,26,20],
[38,37,26,21],
[38,37,26,22],
[38,37,26,23],
[38,37,26,24],
[38,37,27,0],
[38,37,27,1],
[38,37,27,2],
[38,37,27,3],
[38,37,27,5],
[38,37,27,6],
[38,37,27,8],
[38,37,27,9],
[38,37,27,11],
[38,37,27,12],
[38,37,27,13],
[38,37,27,14],
[38,37,27,15],
[38,37,27,16],
[38,37,27,17],
[38,37,27,18],
[38,37,27,19],
[38,37,27,21],
[38,37,27,23],
[38,37,27,24],
[38,37,27,26],
[38,37,29,0],
[38,37,29,1],
[38,37,29,2],
[38,37,29,3],
[38,37,29,5],
[38,37,29,6],
[38,37,29,8],
[38,37,29,9],
[38,37,29,10],
[38,37,29,13],
[38,37,29,14],
[38,37,29,15],
[38,37,29,16],
[38,37,29,17],
[38,37,29,18],
[38,37,29,19],
[38,37,29,20],
[38,37,29,21],
[38,37,29,22],
[38,37,29,26],
[38,37,29,27],
[38,37,30,0],
[38,37,30,1],
[38,37,30,2],
[38,37,30,3],
[38,37,30,5],
[38,37,30,6],
[38,37,30,8],
[38,37,30,9],
[38,37,30,10],
[38,37,30,11],
[38,37,30,12],
[38,37,30,13],
[38,37,30,14],
[38,37,30,15],
[38,37,30,16],
[38,37,30,17],
[38,37,30,18],
[38,37,30,19],
[38,37,30,20],
[38,37,30,21],
[38,37,30,24],
[38,37,30,26],
[38,37,30,27],
[38,37,30,29],
[38,37,31,0],
[38,37,31,1],
[38,37,31,2],
[38,37,31,3],
[38,37,31,5],
[38,37,31,6],
[38,37,31,8],
[38,37,31,9],
[38,37,31,10],
[38,37,31,11],
[38,37,31,12],
[38,37,31,13],
[38,37,31,14],
[38,37,31,15],
[38,37,31,16],
[38,37,31,17],
[38,37,31,18],
[38,37,31,19],
[38,37,31,20],
[38,37,31,22],
[38,37,31,24],
[38,37,31,26],
[38,37,31,27],
[38,37,31,29],
[38,37,32,0],
[38,37,32,1],
[38,37,32,2],
[38,37,32,3],
[38,37,32,5],
[38,37,32,6],
[38,37,32,8],
[38,37,32,9],
[38,37,32,10],
[38,37,32,13],
[38,37,32,14],
[38,37,32,15],
[38,37,32,16],
[38,37,32,17],
[38,37,32,18],
[38,37,32,19],
[38,37,32,21],
[38,37,32,22],
[38,37,32,23],
[38,37,32,24],
[38,37,32,26],
[38,37,32,27],
[38,37,32,30],
[38,37,32,31],
[38,37,33,0],
[38,37,33,1],
[38,37,33,2],
[38,37,33,3],
[38,37,33,5],
[38,37,33,6],
[38,37,33,8],
[38,37,33,9],
[38,37,33,10],
[38,37,33,13],
[38,37,33,14],
[38,37,33,15],
[38,37,33,16],
[38,37,33,17],
[38,37,33,18],
[38,37,33,20],
[38,37,33,21],
[38,37,33,22],
[38,37,33,23],
[38,37,33,24],
[38,37,33,26],
[38,37,33,27],
[38,37,33,30],
[38,37,33,31],
[38,37,35,0],
[38,37,35,1],
[38,37,35,2],
[38,37,35,3],
[38,37,35,5],
[38,37,35,6],
[38,37,35,8],
[38,37,35,10],
[38,37,35,11],
[38,37,35,12],
[38,37,35,13],
[38,37,35,14],
[38,37,35,19],
[38,37,35,20],
[38,37,35,21],
[38,37,35,22],
[38,37,35,23],
[38,37,35,24],
[38,37,35,27],
[38,37,35,29],
[38,37,36,0],
[38,37,36,1],
[38,37,36,2],
[38,37,36,3],
[38,37,36,5],
[38,37,36,6],
[38,37,36,8],
[38,37,36,9],
[38,37,36,10],
[38,37,36,11],
[38,37,36,12],
[38,37,36,16],
[38,37,36,17],
[38,37,36,18],
[38,37,36,20],
[38,37,36,21],
[38,37,36,22],
[38,37,36,23],
[38,37,36,24],
[38,37,36,26],
[38,37,36,27],
[39,7,4,0],
[39,7,4,1],
[39,7,4,2],
[39,9,4,0],
[39,9,4,1],
[39,9,4,2],
[39,9,7,0],
[39,9,7,1],
[39,9,7,2],
[39,9,7,4],
[39,10,4,0],
[39,10,4,1],
[39,10,4,2],
[39,10,7,0],
[39,10,7,1],
[39,10,7,2],
[39,10,7,4],
[39,10,9,0],
[39,10,9,1],
[39,10,9,2],
[39,10,9,4],
[39,10,9,7],
[39,11,4,0],
[39,11,4,1],
[39,11,4,2],
[39,11,7,0],
[39,11,7,1],
[39,11,7,2],
[39,11,7,4],
[39,11,9,0],
[39,11,9,1],
[39,11,9,2],
[39,11,9,4],
[39,11,9,7],
[39,11,10,0],
[39,11,10,1],
[39,11,10,2],
[39,11,10,4],
[39,11,10,7],
[39,11,10,9],
[39,12,4,0],
[39,12,4,1],
[39,12,4,2],
[39,12,7,0],
[39,12,7,1],
[39,12,7,2],
[39,12,7,4],
[39,12,9,0],
[39,12,9,1],
[39,12,9,2],
[39,12,9,4],
[39,12,9,7],
[39,12,10,0],
[39,12,10,1],
[39,12,10,2],
[39,12,10,4],
[39,12,10,7],
[39,12,10,9],
[39,13,4,0],
[39,13,4,1],
[39,13,4,2],
[39,13,7,0],
[39,13,7,1],
[39,13,7,2],
[39,13,7,4],
[39,13,9,0],
[39,13,9,1],
[39,13,9,2],
[39,13,9,4],
[39,13,9,7],
[39,13,10,0],
[39,13,10,1],
[39,13,10,2],
[39,13,10,4],
[39,13,10,7],
[39,13,10,9],
[39,13,11,0],
[39,13,11,1],
[39,13,11,2],
[39,13,11,4],
[39,13,11,7],
[39,13,11,9],
[39,13,11,10],
[39,13,12,0],
[39,13,12,1],
[39,13,12,2],
[39,13,12,4],
[39,13,12,7],
[39,13,12,9],
[39,13,12,10],
[39,14,4,0],
[39,14,4,1],
[39,14,4,2],
[39,14,7,0],
[39,14,7,1],
[39,14,7,2],
[39,14,7,4],
[39,14,9,0],
[39,14,9,1],
[39,14,9,2],
[39,14,9,4],
[39,14,9,7],
[39,14,10,0],
[39,14,10,1],
[39,14,10,2],
[39,14,10,4],
[39,14,10,7],
[39,14,10,9],
[39,14,11,0],
[39,14,11,1],
[39,14,11,2],
[39,14,11,4],
[39,14,11,7],
[39,14,11,9],
[39,14,11,10],
[39,14,12,0],
[39,14,12,1],
[39,14,12,2],
[39,14,12,4],
[39,14,12,7],
[39,14,12,9],
[39,14,12,10],
[39,15,4,0],
[39,15,4,1],
[39,15,4,2],
[39,15,7,0],
[39,15,7,1],
[39,15,7,2],
[39,15,7,4],
[39,15,10,0],
[39,15,10,1],
[39,15,10,2],
[39,15,10,4],
[39,15,10,7],
[39,15,11,0],
[39,15,11,1],
[39,15,11,2],
[39,15,11,4],
[39,15,11,7],
[39,15,11,10],
[39,15,12,0],
[39,15,12,1],
[39,15,12,2],
[39,15,12,4],
[39,15,12,7],
[39,15,12,10],
[39,15,13,0],
[39,15,13,1],
[39,15,13,2],
[39,15,13,4],
[39,15,13,7],
[39,15,13,10],
[39,15,13,11],
[39,15,13,12],
[39,15,14,0],
[39,15,14,1],
[39,15,14,2],
[39,15,14,4],
[39,15,14,7],
[39,15,14,10],
[39,15,14,11],
[39,15,14,12],
[39,16,4,0],
[39,16,4,1],
[39,16,4,2],
[39,16,7,0],
[39,16,7,1],
[39,16,7,2],
[39,16,7,4],
[39,16,9,0],
[39,16,9,1],
[39,16,9,2],
[39,16,9,4],
[39,16,9,7],
[39,16,10,0],
[39,16,10,1],
[39,16,10,2],
[39,16,10,4],
[39,16,10,7],
[39,16,10,9],
[39,16,11,0],
[39,16,11,1],
[39,16,11,2],
[39,16,11,4],
[39,16,11,7],
[39,16,11,9],
[39,16,11,10],
[39,16,12,0],
[39,16,12,1],
[39,16,12,2],
[39,16,12,4],
[39,16,12,7],
[39,16,12,9],
[39,16,12,10],
[39,16,13,0],
[39,16,13,1],
[39,16,13,2],
[39,16,13,4],
[39,16,13,7],
[39,16,13,9],
[39,16,13,10],
[39,16,13,11],
[39,16,13,12],
[39,16,14,0],
[39,16,14,1],
[39,16,14,2],
[39,16,14,4],
[39,16,14,7],
[39,16,14,9],
[39,16,14,10],
[39,16,14,11],
[39,16,14,12],
[39,16,15,0],
[39,16,15,1],
[39,16,15,2],
[39,16,15,4],
[39,16,15,7],
[39,16,15,10],
[39,16,15,11],
[39,16,15,12],
[39,16,15,13],
[39,16,15,14],
[39,17,4,0],
[39,17,4,1],
[39,17,4,2],
[39,17,7,0],
[39,17,7,1],
[39,17,7,2],
[39,17,7,4],
[39,17,9,0],
[39,17,9,1],
[39,17,9,2],
[39,17,9,4],
[39,17,9,7],
[39,17,10,0],
[39,17,10,1],
[39,17,10,2],
[39,17,10,4],
[39,17,10,7],
[39,17,10,9],
[39,17,11,0],
[39,17,11,1],
[39,17,11,2],
[39,17,11,4],
[39,17,11,7],
[39,17,11,9],
[39,17,11,10],
[39,17,12,0],
[39,17,12,1],
[39,17,12,2],
[39,17,12,4],
[39,17,12,7],
[39,17,12,9],
[39,17,12,10],
[39,17,13,0],
[39,17,13,1],
[39,17,13,2],
[39,17,13,4],
[39,17,13,7],
[39,17,13,9],
[39,17,13,10],
[39,17,13,11],
[39,17,13,12],
[39,17,14,0],
[39,17,14,1],
[39,17,14,2],
[39,17,14,4],
[39,17,14,7],
[39,17,14,9],
[39,17,14,10],
[39,17,14,11],
[39,17,14,12],
[39,17,15,0],
[39,17,15,1],
[39,17,15,2],
[39,17,15,4],
[39,17,15,7],
[39,17,15,10],
[39,17,15,11],
[39,17,15,12],
[39,17,15,13],
[39,17,15,14],
[39,17,16,0],
[39,17,16,1],
[39,17,16,2],
[39,17,16,4],
[39,17,16,7],
[39,17,16,9],
[39,17,16,10],
[39,17,16,11],
[39,17,16,12],
[39,17,16,13],
[39,17,16,14],
[39,17,16,15],
[39,18,4,0],
[39,18,4,1],
[39,18,4,2],
[39,18,7,0],
[39,18,7,1],
[39,18,7,2],
[39,18,7,4],
[39,18,10,0],
[39,18,10,1],
[39,18,10,2],
[39,18,10,4],
[39,18,10,7],
[39,18,11,0],
[39,18,11,1],
[39,18,11,2],
[39,18,11,4],
[39,18,11,7],
[39,18,11,10],
[39,18,12,0],
[39,18,12,1],
[39,18,12,2],
[39,18,12,4],
[39,18,12,7],
[39,18,12,10],
[39,18,13,0],
[39,18,13,1],
[39,18,13,2],
[39,18,13,4],
[39,18,13,7],
[39,18,13,10],
[39,18,13,11],
[39,18,13,12],
[39,18,14,0],
[39,18,14,1],
[39,18,14,2],
[39,18,14,4],
[39,18,14,7],
[39,18,14,10],
[39,18,14,11],
[39,18,14,12],
[39,18,16,0],
[39,18,16,1],
[39,18,16,2],
[39,18,16,4],
[39,18,16,7],
[39,18,16,10],
[39,18,16,11],
[39,18,16,12],
[39,18,16,13],
[39,18,16,14],
[39,18,17,0],
[39,18,17,1],
[39,18,17,2],
[39,18,17,4],
[39,18,17,7],
[39,18,17,10],
[39,18,17,11],
[39,18,17,12],
[39,18,17,13],
[39,18,17,14],
[39,18,17,16],
[39,19,4,0],
[39,19,4,1],
[39,19,4,2],
[39,19,7,0],
[39,19,7,1],
[39,19,7,2],
[39,19,7,4],
[39,19,9,0],
[39,19,9,1],
[39,19,9,2],
[39,19,9,4],
[39,19,9,7],
[39,19,10,0],
[39,19,10,1],
[39,19,10,2],
[39,19,10,4],
[39,19,10,7],
[39,19,10,9],
[39,19,11,0],
[39,19,11,1],
[39,19,11,2],
[39,19,11,4],
[39,19,11,7],
[39,19,11,9],
[39,19,11,10],
[39,19,12,0],
[39,19,12,1],
[39,19,12,2],
[39,19,12,4],
[39,19,12,7],
[39,19,12,9],
[39,19,12,10],
[39,19,15,0],
[39,19,15,1],
[39,19,15,2],
[39,19,15,4],
[39,19,15,7],
[39,19,15,10],
[39,19,15,11],
[39,19,15,12],
[39,19,16,0],
[39,19,16,1],
[39,19,16,2],
[39,19,16,4],
[39,19,16,7],
[39,19,16,9],
[39,19,16,10],
[39,19,16,11],
[39,19,16,12],
[39,19,16,15],
[39,19,17,0],
[39,19,17,1],
[39,19,17,2],
[39,19,17,4],
[39,19,17,7],
[39,19,17,9],
[39,19,17,10],
[39,19,17,11],
[39,19,17,12],
[39,19,17,15],
[39,19,17,16],
[39,19,18,0],
[39,19,18,1],
[39,19,18,2],
[39,19,18,4],
[39,19,18,7],
[39,19,18,10],
[39,19,18,11],
[39,19,18,12],
[39,19,18,16],
[39,19,18,17],
[39,20,4,0],
[39,20,4,1],
[39,20,4,2],
[39,20,7,0],
[39,20,7,1],
[39,20,7,2],
[39,20,7,4],
[39,20,9,0],
[39,20,9,1],
[39,20,9,2],
[39,20,9,4],
[39,20,9,7],
[39,20,11,0],
[39,20,11,1],
[39,20,11,2],
[39,20,11,4],
[39,20,11,7],
[39,20,11,9],
[39,20,12,0],
[39,20,12,1],
[39,20,12,2],
[39,20,12,4],
[39,20,12,7],
[39,20,12,9],
[39,20,13,0],
[39,20,13,1],
[39,20,13,2],
[39,20,13,4],
[39,20,13,7],
[39,20,13,9],
[39,20,13,11],
[39,20,13,12],
[39,20,14,0],
[39,20,14,1],
[39,20,14,2],
[39,20,14,4],
[39,20,14,7],
[39,20,14,9],
[39,20,14,11],
[39,20,14,12],
[39,20,15,0],
[39,20,15,1],
[39,20,15,2],
[39,20,15,4],
[39,20,15,7],
[39,20,15,11],
[39,20,15,12],
[39,20,15,13],
[39,20,15,14],
[39,20,16,0],
[39,20,16,1],
[39,20,16,2],
[39,20,16,4],
[39,20,16,7],
[39,20,16,9],
[39,20,16,11],
[39,20,16,12],
[39,20,16,13],
[39,20,16,14],
[39,20,16,15],
[39,20,17,0],
[39,20,17,1],
[39,20,17,2],
[39,20,17,4],
[39,20,17,7],
[39,20,17,9],
[39,20,17,11],
[39,20,17,12],
[39,20,17,13],
[39,20,17,14],
[39,20,17,15],
[39,20,17,16],
[39,20,18,0],
[39,20,18,1],
[39,20,18,2],
[39,20,18,4],
[39,20,18,7],
[39,20,18,11],
[39,20,18,12],
[39,20,18,13],
[39,20,18,14],
[39,20,18,16],
[39,20,18,17],
[39,20,19,0],
[39,20,19,1],
[39,20,19,2],
[39,20,19,4],
[39,20,19,7],
[39,20,19,9],
[39,20,19,11],
[39,20,19,12],
[39,20,19,15],
[39,20,19,16],
[39,20,19,17],
[39,20,19,18],
[39,22,4,0],
[39,22,4,1],
[39,22,4,2],
[39,22,7,0],
[39,22,7,1],
[39,22,7,2],
[39,22,7,4],
[39,22,9,0],
[39,22,9,1],
[39,22,9,2],
[39,22,9,4],
[39,22,9,7],
[39,22,11,0],
[39,22,11,1],
[39,22,11,2],
[39,22,11,4],
[39,22,11,7],
[39,22,11,9],
[39,22,12,0],
[39,22,12,1],
[39,22,12,2],
[39,22,12,4],
[39,22,12,7],
[39,22,12,9],
[39,22,13,0],
[39,22,13,1],
[39,22,13,2],
[39,22,13,4],
[39,22,13,7],
[39,22,13,9],
[39,22,13,11],
[39,22,13,12],
[39,22,14,0],
[39,22,14,1],
[39,22,14,2],
[39,22,14,4],
[39,22,14,7],
[39,22,14,9],
[39,22,14,11],
[39,22,14,12],
[39,22,15,0],
[39,22,15,1],
[39,22,15,2],
[39,22,15,4],
[39,22,15,7],
[39,22,15,11],
[39,22,15,12],
[39,22,15,13],
[39,22,15,14],
[39,22,16,0],
[39,22,16,1],
[39,22,16,2],
[39,22,16,4],
[39,22,16,7],
[39,22,16,9],
[39,22,16,11],
[39,22,16,12],
[39,22,16,13],
[39,22,16,14],
[39,22,16,15],
[39,22,17,0],
[39,22,17,1],
[39,22,17,2],
[39,22,17,4],
[39,22,17,7],
[39,22,17,9],
[39,22,17,11],
[39,22,17,12],
[39,22,17,13],
[39,22,17,14],
[39,22,17,15],
[39,22,17,16],
[39,22,18,0],
[39,22,18,1],
[39,22,18,2],
[39,22,18,4],
[39,22,18,7],
[39,22,18,11],
[39,22,18,12],
[39,22,18,13],
[39,22,18,14],
[39,22,18,16],
[39,22,18,17],
[39,22,19,0],
[39,22,19,1],
[39,22,19,2],
[39,22,19,4],
[39,22,19,7],
[39,22,19,9],
[39,22,19,11],
[39,22,19,12],
[39,22,19,15],
[39,22,19,16],
[39,22,19,17],
[39,22,19,18],
[39,23,4,0],
[39,23,4,1],
[39,23,4,2],
[39,23,7,0],
[39,23,7,1],
[39,23,7,2],
[39,23,7,4],
[39,23,9,0],
[39,23,9,1],
[39,23,9,2],
[39,23,9,4],
[39,23,9,7],
[39,23,10,0],
[39,23,10,1],
[39,23,10,2],
[39,23,10,4],
[39,23,10,7],
[39,23,10,9],
[39,23,11,0],
[39,23,11,1],
[39,23,11,2],
[39,23,11,4],
[39,23,11,7],
[39,23,11,9],
[39,23,11,10],
[39,23,12,0],
[39,23,12,1],
[39,23,12,2],
[39,23,12,4],
[39,23,12,7],
[39,23,12,9],
[39,23,12,10],
[39,23,13,0],
[39,23,13,1],
[39,23,13,2],
[39,23,13,4],
[39,23,13,7],
[39,23,13,9],
[39,23,13,10],
[39,23,13,11],
[39,23,13,12],
[39,23,14,0],
[39,23,14,1],
[39,23,14,2],
[39,23,14,4],
[39,23,14,7],
[39,23,14,9],
[39,23,14,10],
[39,23,14,11],
[39,23,14,12],
[39,23,15,0],
[39,23,15,1],
[39,23,15,2],
[39,23,15,4],
[39,23,15,7],
[39,23,15,10],
[39,23,15,11],
[39,23,15,12],
[39,23,15,13],
[39,23,15,14],
[39,23,16,0],
[39,23,16,1],
[39,23,16,2],
[39,23,16,4],
[39,23,16,7],
[39,23,16,9],
[39,23,16,10],
[39,23,16,11],
[39,23,16,12],
[39,23,16,13],
[39,23,16,14],
[39,23,16,15],
[39,23,17,0],
[39,23,17,1],
[39,23,17,2],
[39,23,17,4],
[39,23,17,7],
[39,23,17,9],
[39,23,17,10],
[39,23,17,11],
[39,23,17,12],
[39,23,17,13],
[39,23,17,14],
[39,23,17,15],
[39,23,17,16],
[39,23,18,0],
[39,23,18,1],
[39,23,18,2],
[39,23,18,4],
[39,23,18,7],
[39,23,18,10],
[39,23,18,11],
[39,23,18,12],
[39,23,18,13],
[39,23,18,14],
[39,23,18,16],
[39,23,18,17],
[39,23,19,0],
[39,23,19,1],
[39,23,19,2],
[39,23,19,4],
[39,23,19,7],
[39,23,19,9],
[39,23,19,10],
[39,23,19,11],
[39,23,19,12],
[39,23,19,15],
[39,23,19,16],
[39,23,19,17],
[39,23,19,18],
[39,23,20,0],
[39,23,20,1],
[39,23,20,2],
[39,23,20,4],
[39,23,20,7],
[39,23,20,9],
[39,23,20,11],
[39,23,20,12],
[39,23,20,13],
[39,23,20,14],
[39,23,20,15],
[39,23,20,16],
[39,23,20,17],
[39,23,20,18],
[39,23,20,19],
[39,23,22,0],
[39,23,22,1],
[39,23,22,2],
[39,23,22,4],
[39,23,22,7],
[39,23,22,9],
[39,23,22,11],
[39,23,22,12],
[39,23,22,13],
[39,23,22,14],
[39,23,22,15],
[39,23,22,16],
[39,23,22,17],
[39,23,22,18],
[39,23,22,19],
[39,24,4,0],
[39,24,4,1],
[39,24,4,2],
[39,24,7,0],
[39,24,7,1],
[39,24,7,2],
[39,24,7,4],
[39,24,9,0],
[39,24,9,1],
[39,24,9,2],
[39,24,9,4],
[39,24,9,7],
[39,24,10,0],
[39,24,10,1],
[39,24,10,2],
[39,24,10,4],
[39,24,10,7],
[39,24,10,9],
[39,24,11,0],
[39,24,11,1],
[39,24,11,2],
[39,24,11,4],
[39,24,11,7],
[39,24,11,9],
[39,24,11,10],
[39,24,12,0],
[39,24,12,1],
[39,24,12,2],
[39,24,12,4],
[39,24,12,7],
[39,24,12,9],
[39,24,12,10],
[39,24,13,0],
[39,24,13,1],
[39,24,13,2],
[39,24,13,4],
[39,24,13,7],
[39,24,13,9],
[39,24,13,10],
[39,24,13,11],
[39,24,13,12],
[39,24,14,0],
[39,24,14,1],
[39,24,14,2],
[39,24,14,4],
[39,24,14,7],
[39,24,14,9],
[39,24,14,10],
[39,24,14,11],
[39,24,14,12],
[39,24,15,0],
[39,24,15,1],
[39,24,15,2],
[39,24,15,4],
[39,24,15,7],
[39,24,15,10],
[39,24,15,11],
[39,24,15,12],
[39,24,15,13],
[39,24,15,14],
[39,24,17,0],
[39,24,17,1],
[39,24,17,2],
[39,24,17,4],
[39,24,17,7],
[39,24,17,9],
[39,24,17,10],
[39,24,17,11],
[39,24,17,12],
[39,24,17,13],
[39,24,17,14],
[39,24,17,15],
[39,24,18,0],
[39,24,18,1],
[39,24,18,2],
[39,24,18,4],
[39,24,18,7],
[39,24,18,10],
[39,24,18,11],
[39,24,18,12],
[39,24,18,13],
[39,24,18,14],
[39,24,18,17],
[39,24,19,0],
[39,24,19,1],
[39,24,19,2],
[39,24,19,4],
[39,24,19,7],
[39,24,19,9],
[39,24,19,10],
[39,24,19,11],
[39,24,19,12],
[39,24,19,15],
[39,24,19,17],
[39,24,19,18],
[39,24,20,0],
[39,24,20,1],
[39,24,20,2],
[39,24,20,4],
[39,24,20,7],
[39,24,20,9],
[39,24,20,11],
[39,24,20,12],
[39,24,20,13],
[39,24,20,14],
[39,24,20,15],
[39,24,20,17],
[39,24,20,18],
[39,24,20,19],
[39,24,22,0],
[39,24,22,1],
[39,24,22,2],
[39,24,22,4],
[39,24,22,7],
[39,24,22,9],
[39,24,22,11],
[39,24,22,12],
[39,24,22,13],
[39,24,22,14],
[39,24,22,15],
[39,24,22,17],
[39,24,22,18],
[39,24,22,19],
[39,24,23,0],
[39,24,23,1],
[39,24,23,2],
[39,24,23,4],
[39,24,23,7],
[39,24,23,9],
[39,24,23,10],
[39,24,23,11],
[39,24,23,12],
[39,24,23,13],
[39,24,23,14],
[39,24,23,15],
[39,24,23,17],
[39,24,23,18],
[39,24,23,19],
[39,24,23,20],
[39,24,23,22],
[39,25,4,0],
[39,25,4,1],
[39,25,4,2],
[39,25,9,0],
[39,25,9,1],
[39,25,9,2],
[39,25,9,4],
[39,25,10,0],
[39,25,10,1],
[39,25,10,2],
[39,25,10,4],
[39,25,10,9],
[39,25,11,0],
[39,25,11,1],
[39,25,11,2],
[39,25,11,4],
[39,25,11,9],
[39,25,11,10],
[39,25,12,0],
[39,25,12,1],
[39,25,12,2],
[39,25,12,4],
[39,25,12,9],
[39,25,12,10],
[39,25,13,0],
[39,25,13,1],
[39,25,13,2],
[39,25,13,4],
[39,25,13,9],
[39,25,13,10],
[39,25,13,11],
[39,25,13,12],
[39,25,14,0],
[39,25,14,1],
[39,25,14,2],
[39,25,14,4],
[39,25,14,9],
[39,25,14,10],
[39,25,14,11],
[39,25,14,12],
[39,25,15,0],
[39,25,15,1],
[39,25,15,2],
[39,25,15,4],
[39,25,15,10],
[39,25,15,11],
[39,25,15,12],
[39,25,15,13],
[39,25,15,14],
[39,25,16,0],
[39,25,16,1],
[39,25,16,2],
[39,25,16,4],
[39,25,16,9],
[39,25,16,10],
[39,25,16,11],
[39,25,16,12],
[39,25,16,13],
[39,25,16,14],
[39,25,16,15],
[39,25,17,0],
[39,25,17,1],
[39,25,17,2],
[39,25,17,4],
[39,25,17,9],
[39,25,17,10],
[39,25,17,11],
[39,25,17,12],
[39,25,17,13],
[39,25,17,14],
[39,25,17,15],
[39,25,17,16],
[39,25,18,0],
[39,25,18,1],
[39,25,18,2],
[39,25,18,4],
[39,25,18,10],
[39,25,18,11],
[39,25,18,12],
[39,25,18,13],
[39,25,18,14],
[39,25,18,16],
[39,25,18,17],
[39,25,19,0],
[39,25,19,1],
[39,25,19,2],
[39,25,19,4],
[39,25,19,9],
[39,25,19,10],
[39,25,19,11],
[39,25,19,12],
[39,25,19,15],
[39,25,19,16],
[39,25,19,17],
[39,25,19,18],
[39,25,20,0],
[39,25,20,1],
[39,25,20,2],
[39,25,20,4],
[39,25,20,9],
[39,25,20,11],
[39,25,20,12],
[39,25,20,13],
[39,25,20,14],
[39,25,20,15],
[39,25,20,16],
[39,25,20,17],
[39,25,20,18],
[39,25,20,19],
[39,25,22,0],
[39,25,22,1],
[39,25,22,2],
[39,25,22,4],
[39,25,22,9],
[39,25,22,11],
[39,25,22,12],
[39,25,22,13],
[39,25,22,14],
[39,25,22,15],
[39,25,22,16],
[39,25,22,17],
[39,25,22,18],
[39,25,22,19],
[39,25,23,0],
[39,25,23,1],
[39,25,23,2],
[39,25,23,4],
[39,25,23,9],
[39,25,23,10],
[39,25,23,11],
[39,25,23,12],
[39,25,23,13],
[39,25,23,14],
[39,25,23,15],
[39,25,23,16],
[39,25,23,17],
[39,25,23,18],
[39,25,23,19],
[39,25,23,20],
[39,25,23,22],
[39,25,24,0],
[39,25,24,1],
[39,25,24,2],
[39,25,24,4],
[39,25,24,9],
[39,25,24,10],
[39,25,24,11],
[39,25,24,12],
[39,25,24,13],
[39,25,24,14],
[39,25,24,15],
[39,25,24,17],
[39,25,24,18],
[39,25,24,19],
[39,25,24,20],
[39,25,24,22],
[39,25,24,23],
[39,26,4,0],
[39,26,4,1],
[39,26,4,2],
[39,26,7,0],
[39,26,7,1],
[39,26,7,2],
[39,26,7,4],
[39,26,10,0],
[39,26,10,1],
[39,26,10,2],
[39,26,10,4],
[39,26,10,7],
[39,26,11,0],
[39,26,11,1],
[39,26,11,2],
[39,26,11,4],
[39,26,11,7],
[39,26,11,10],
[39,26,12,0],
[39,26,12,1],
[39,26,12,2],
[39,26,12,4],
[39,26,12,7],
[39,26,12,10],
[39,26,13,0],
[39,26,13,1],
[39,26,13,2],
[39,26,13,4],
[39,26,13,7],
[39,26,13,10],
[39,26,13,11],
[39,26,13,12],
[39,26,14,0],
[39,26,14,1],
[39,26,14,2],
[39,26,14,4],
[39,26,14,7],
[39,26,14,10],
[39,26,14,11],
[39,26,14,12],
[39,26,16,0],
[39,26,16,1],
[39,26,16,2],
[39,26,16,4],
[39,26,16,7],
[39,26,16,10],
[39,26,16,11],
[39,26,16,12],
[39,26,16,13],
[39,26,16,14],
[39,26,17,0],
[39,26,17,1],
[39,26,17,2],
[39,26,17,4],
[39,26,17,7],
[39,26,17,10],
[39,26,17,11],
[39,26,17,12],
[39,26,17,13],
[39,26,17,14],
[39,26,17,16],
[39,26,19,0],
[39,26,19,1],
[39,26,19,2],
[39,26,19,4],
[39,26,19,7],
[39,26,19,10],
[39,26,19,11],
[39,26,19,12],
[39,26,19,16],
[39,26,19,17],
[39,26,20,0],
[39,26,20,1],
[39,26,20,2],
[39,26,20,4],
[39,26,20,7],
[39,26,20,11],
[39,26,20,12],
[39,26,20,13],
[39,26,20,14],
[39,26,20,16],
[39,26,20,17],
[39,26,20,19],
[39,26,22,0],
[39,26,22,1],
[39,26,22,2],
[39,26,22,4],
[39,26,22,7],
[39,26,22,11],
[39,26,22,12],
[39,26,22,13],
[39,26,22,14],
[39,26,22,16],
[39,26,22,17],
[39,26,22,19],
[39,26,23,0],
[39,26,23,1],
[39,26,23,2],
[39,26,23,4],
[39,26,23,7],
[39,26,23,10],
[39,26,23,11],
[39,26,23,12],
[39,26,23,13],
[39,26,23,14],
[39,26,23,16],
[39,26,23,17],
[39,26,23,19],
[39,26,23,20],
[39,26,23,22],
[39,26,24,0],
[39,26,24,1],
[39,26,24,2],
[39,26,24,4],
[39,26,24,7],
[39,26,24,10],
[39,26,24,11],
[39,26,24,12],
[39,26,24,13],
[39,26,24,14],
[39,26,24,17],
[39,26,24,19],
[39,26,24,20],
[39,26,24,22],
[39,26,24,23],
[39,26,25,0],
[39,26,25,1],
[39,26,25,2],
[39,26,25,4],
[39,26,25,10],
[39,26,25,11],
[39,26,25,12],
[39,26,25,13],
[39,26,25,14],
[39,26,25,16],
[39,26,25,17],
[39,26,25,19],
[39,26,25,20],
[39,26,25,22],
[39,26,25,23],
[39,26,25,24],
[39,27,4,0],
[39,27,4,1],
[39,27,4,2],
[39,27,7,0],
[39,27,7,1],
[39,27,7,2],
[39,27,7,4],
[39,27,9,0],
[39,27,9,1],
[39,27,9,2],
[39,27,9,4],
[39,27,9,7],
[39,27,11,0],
[39,27,11,1],
[39,27,11,2],
[39,27,11,4],
[39,27,11,7],
[39,27,11,9],
[39,27,12,0],
[39,27,12,1],
[39,27,12,2],
[39,27,12,4],
[39,27,12,7],
[39,27,12,9],
[39,27,13,0],
[39,27,13,1],
[39,27,13,2],
[39,27,13,4],
[39,27,13,7],
[39,27,13,9],
[39,27,13,11],
[39,27,13,12],
[39,27,14,0],
[39,27,14,1],
[39,27,14,2],
[39,27,14,4],
[39,27,14,7],
[39,27,14,9],
[39,27,14,11],
[39,27,14,12],
[39,27,15,0],
[39,27,15,1],
[39,27,15,2],
[39,27,15,4],
[39,27,15,7],
[39,27,15,11],
[39,27,15,12],
[39,27,15,13],
[39,27,15,14],
[39,27,16,0],
[39,27,16,1],
[39,27,16,2],
[39,27,16,4],
[39,27,16,7],
[39,27,16,9],
[39,27,16,11],
[39,27,16,12],
[39,27,16,13],
[39,27,16,14],
[39,27,16,15],
[39,27,17,0],
[39,27,17,1],
[39,27,17,2],
[39,27,17,4],
[39,27,17,7],
[39,27,17,9],
[39,27,17,11],
[39,27,17,12],
[39,27,17,13],
[39,27,17,14],
[39,27,17,15],
[39,27,17,16],
[39,27,18,0],
[39,27,18,1],
[39,27,18,2],
[39,27,18,4],
[39,27,18,7],
[39,27,18,11],
[39,27,18,12],
[39,27,18,13],
[39,27,18,14],
[39,27,18,16],
[39,27,18,17],
[39,27,19,0],
[39,27,19,1],
[39,27,19,2],
[39,27,19,4],
[39,27,19,7],
[39,27,19,9],
[39,27,19,11],
[39,27,19,12],
[39,27,19,15],
[39,27,19,16],
[39,27,19,17],
[39,27,19,18],
[39,27,23,0],
[39,27,23,1],
[39,27,23,2],
[39,27,23,4],
[39,27,23,7],
[39,27,23,9],
[39,27,23,11],
[39,27,23,12],
[39,27,23,13],
[39,27,23,14],
[39,27,23,15],
[39,27,23,16],
[39,27,23,17],
[39,27,23,18],
[39,27,23,19],
[39,27,24,0],
[39,27,24,1],
[39,27,24,2],
[39,27,24,4],
[39,27,24,7],
[39,27,24,9],
[39,27,24,11],
[39,27,24,12],
[39,27,24,13],
[39,27,24,14],
[39,27,24,15],
[39,27,24,17],
[39,27,24,18],
[39,27,24,19],
[39,27,24,23],
[39,27,25,0],
[39,27,25,1],
[39,27,25,2],
[39,27,25,4],
[39,27,25,9],
[39,27,25,11],
[39,27,25,12],
[39,27,25,13],
[39,27,25,14],
[39,27,25,15],
[39,27,25,16],
[39,27,25,17],
[39,27,25,18],
[39,27,25,19],
[39,27,25,23],
[39,27,25,24],
[39,27,26,0],
[39,27,26,1],
[39,27,26,2],
[39,27,26,4],
[39,27,26,7],
[39,27,26,11],
[39,27,26,12],
[39,27,26,13],
[39,27,26,14],
[39,27,26,16],
[39,27,26,17],
[39,27,26,19],
[39,27,26,23],
[39,27,26,24],
[39,27,26,25],
[39,28,4,0],
[39,28,4,1],
[39,28,4,2],
[39,28,9,0],
[39,28,9,1],
[39,28,9,2],
[39,28,9,4],
[39,28,10,0],
[39,28,10,1],
[39,28,10,2],
[39,28,10,4],
[39,28,10,9],
[39,28,11,0],
[39,28,11,1],
[39,28,11,2],
[39,28,11,4],
[39,28,11,9],
[39,28,11,10],
[39,28,12,0],
[39,28,12,1],
[39,28,12,2],
[39,28,12,4],
[39,28,12,9],
[39,28,12,10],
[39,28,13,0],
[39,28,13,1],
[39,28,13,2],
[39,28,13,4],
[39,28,13,9],
[39,28,13,10],
[39,28,13,11],
[39,28,13,12],
[39,28,14,0],
[39,28,14,1],
[39,28,14,2],
[39,28,14,4],
[39,28,14,9],
[39,28,14,10],
[39,28,14,11],
[39,28,14,12],
[39,28,15,0],
[39,28,15,1],
[39,28,15,2],
[39,28,15,4],
[39,28,15,10],
[39,28,15,11],
[39,28,15,12],
[39,28,15,13],
[39,28,15,14],
[39,28,16,0],
[39,28,16,1],
[39,28,16,2],
[39,28,16,4],
[39,28,16,9],
[39,28,16,10],
[39,28,16,11],
[39,28,16,12],
[39,28,16,13],
[39,28,16,14],
[39,28,16,15],
[39,28,17,0],
[39,28,17,1],
[39,28,17,2],
[39,28,17,4],
[39,28,17,9],
[39,28,17,10],
[39,28,17,11],
[39,28,17,12],
[39,28,17,13],
[39,28,17,14],
[39,28,17,15],
[39,28,17,16],
[39,28,18,0],
[39,28,18,1],
[39,28,18,2],
[39,28,18,4],
[39,28,18,10],
[39,28,18,11],
[39,28,18,12],
[39,28,18,13],
[39,28,18,14],
[39,28,18,16],
[39,28,18,17],
[39,28,19,0],
[39,28,19,1],
[39,28,19,2],
[39,28,19,4],
[39,28,19,9],
[39,28,19,10],
[39,28,19,11],
[39,28,19,12],
[39,28,19,15],
[39,28,19,16],
[39,28,19,17],
[39,28,19,18],
[39,28,20,0],
[39,28,20,1],
[39,28,20,2],
[39,28,20,4],
[39,28,20,9],
[39,28,20,11],
[39,28,20,12],
[39,28,20,13],
[39,28,20,14],
[39,28,20,15],
[39,28,20,16],
[39,28,20,17],
[39,28,20,18],
[39,28,20,19],
[39,28,22,0],
[39,28,22,1],
[39,28,22,2],
[39,28,22,4],
[39,28,22,9],
[39,28,22,11],
[39,28,22,12],
[39,28,22,13],
[39,28,22,14],
[39,28,22,15],
[39,28,22,16],
[39,28,22,17],
[39,28,22,18],
[39,28,22,19],
[39,28,23,0],
[39,28,23,1],
[39,28,23,2],
[39,28,23,4],
[39,28,23,9],
[39,28,23,10],
[39,28,23,11],
[39,28,23,12],
[39,28,23,13],
[39,28,23,14],
[39,28,23,15],
[39,28,23,16],
[39,28,23,17],
[39,28,23,18],
[39,28,23,19],
[39,28,23,20],
[39,28,23,22],
[39,28,24,0],
[39,28,24,1],
[39,28,24,2],
[39,28,24,4],
[39,28,24,9],
[39,28,24,10],
[39,28,24,11],
[39,28,24,12],
[39,28,24,13],
[39,28,24,14],
[39,28,24,15],
[39,28,24,17],
[39,28,24,18],
[39,28,24,19],
[39,28,24,20],
[39,28,24,22],
[39,28,24,23],
[39,28,26,0],
[39,28,26,1],
[39,28,26,2],
[39,28,26,4],
[39,28,26,10],
[39,28,26,11],
[39,28,26,12],
[39,28,26,13],
[39,28,26,14],
[39,28,26,16],
[39,28,26,17],
[39,28,26,19],
[39,28,26,20],
[39,28,26,22],
[39,28,26,23],
[39,28,26,24],
[39,28,27,0],
[39,28,27,1],
[39,28,27,2],
[39,28,27,4],
[39,28,27,9],
[39,28,27,11],
[39,28,27,12],
[39,28,27,13],
[39,28,27,14],
[39,28,27,15],
[39,28,27,16],
[39,28,27,17],
[39,28,27,18],
[39,28,27,19],
[39,28,27,23],
[39,28,27,24],
[39,28,27,26],
[39,29,4,0],
[39,29,4,1],
[39,29,4,2],
[39,29,7,0],
[39,29,7,1],
[39,29,7,2],
[39,29,7,4],
[39,29,9,0],
[39,29,9,1],
[39,29,9,2],
[39,29,9,4],
[39,29,9,7],
[39,29,10,0],
[39,29,10,1],
[39,29,10,2],
[39,29,10,4],
[39,29,10,7],
[39,29,10,9],
[39,29,13,0],
[39,29,13,1],
[39,29,13,2],
[39,29,13,4],
[39,29,13,7],
[39,29,13,9],
[39,29,13,10],
[39,29,14,0],
[39,29,14,1],
[39,29,14,2],
[39,29,14,4],
[39,29,14,7],
[39,29,14,9],
[39,29,14,10],
[39,29,15,0],
[39,29,15,1],
[39,29,15,2],
[39,29,15,4],
[39,29,15,7],
[39,29,15,10],
[39,29,15,13],
[39,29,15,14],
[39,29,16,0],
[39,29,16,1],
[39,29,16,2],
[39,29,16,4],
[39,29,16,7],
[39,29,16,9],
[39,29,16,10],
[39,29,16,13],
[39,29,16,14],
[39,29,16,15],
[39,29,17,0],
[39,29,17,1],
[39,29,17,2],
[39,29,17,4],
[39,29,17,7],
[39,29,17,9],
[39,29,17,10],
[39,29,17,13],
[39,29,17,14],
[39,29,17,15],
[39,29,17,16],
[39,29,18,0],
[39,29,18,1],
[39,29,18,2],
[39,29,18,4],
[39,29,18,7],
[39,29,18,10],
[39,29,18,13],
[39,29,18,14],
[39,29,18,16],
[39,29,18,17],
[39,29,19,0],
[39,29,19,1],
[39,29,19,2],
[39,29,19,4],
[39,29,19,7],
[39,29,19,9],
[39,29,19,10],
[39,29,19,15],
[39,29,19,16],
[39,29,19,17],
[39,29,19,18],
[39,29,20,0],
[39,29,20,1],
[39,29,20,2],
[39,29,20,4],
[39,29,20,7],
[39,29,20,9],
[39,29,20,13],
[39,29,20,14],
[39,29,20,15],
[39,29,20,16],
[39,29,20,17],
[39,29,20,18],
[39,29,20,19],
[39,29,22,0],
[39,29,22,1],
[39,29,22,2],
[39,29,22,4],
[39,29,22,7],
[39,29,22,9],
[39,29,22,13],
[39,29,22,14],
[39,29,22,15],
[39,29,22,16],
[39,29,22,17],
[39,29,22,18],
[39,29,22,19],
[39,29,23,0],
[39,29,23,1],
[39,29,23,2],
[39,29,23,4],
[39,29,23,7],
[39,29,23,9],
[39,29,23,10],
[39,29,23,13],
[39,29,23,14],
[39,29,23,15],
[39,29,23,16],
[39,29,23,17],
[39,29,23,18],
[39,29,23,19],
[39,29,23,20],
[39,29,23,22],
[39,29,24,0],
[39,29,24,1],
[39,29,24,2],
[39,29,24,4],
[39,29,24,7],
[39,29,24,9],
[39,29,24,10],
[39,29,24,13],
[39,29,24,14],
[39,29,24,15],
[39,29,24,17],
[39,29,24,18],
[39,29,24,19],
[39,29,24,20],
[39,29,24,22],
[39,29,24,23],
[39,29,25,0],
[39,29,25,1],
[39,29,25,2],
[39,29,25,4],
[39,29,25,9],
[39,29,25,10],
[39,29,25,13],
[39,29,25,14],
[39,29,25,15],
[39,29,25,16],
[39,29,25,17],
[39,29,25,18],
[39,29,25,19],
[39,29,25,20],
[39,29,25,22],
[39,29,25,23],
[39,29,25,24],
[39,29,26,0],
[39,29,26,1],
[39,29,26,2],
[39,29,26,4],
[39,29,26,7],
[39,29,26,10],
[39,29,26,13],
[39,29,26,14],
[39,29,26,16],
[39,29,26,17],
[39,29,26,19],
[39,29,26,20],
[39,29,26,22],
[39,29,26,23],
[39,29,26,24],
[39,29,26,25],
[39,29,27,0],
[39,29,27,1],
[39,29,27,2],
[39,29,27,4],
[39,29,27,7],
[39,29,27,9],
[39,29,27,13],
[39,29,27,14],
[39,29,27,15],
[39,29,27,16],
[39,29,27,17],
[39,29,27,18],
[39,29,27,19],
[39,29,27,23],
[39,29,27,24],
[39,29,27,25],
[39,29,27,26],
[39,29,28,0],
[39,29,28,1],
[39,29,28,2],
[39,29,28,4],
[39,29,28,9],
[39,29,28,10],
[39,29,28,13],
[39,29,28,14],
[39,29,28,15],
[39,29,28,16],
[39,29,28,17],
[39,29,28,18],
[39,29,28,19],
[39,29,28,20],
[39,29,28,22],
[39,29,28,23],
[39,29,28,24],
[39,29,28,26],
[39,29,28,27],
[39,30,4,0],
[39,30,4,1],
[39,30,4,2],
[39,30,7,0],
[39,30,7,1],
[39,30,7,2],
[39,30,7,4],
[39,30,9,0],
[39,30,9,1],
[39,30,9,2],
[39,30,9,4],
[39,30,9,7],
[39,30,10,0],
[39,30,10,1],
[39,30,10,2],
[39,30,10,4],
[39,30,10,7],
[39,30,10,9],
[39,30,11,0],
[39,30,11,1],
[39,30,11,2],
[39,30,11,4],
[39,30,11,7],
[39,30,11,9],
[39,30,11,10],
[39,30,12,0],
[39,30,12,1],
[39,30,12,2],
[39,30,12,4],
[39,30,12,7],
[39,30,12,9],
[39,30,12,10],
[39,30,13,0],
[39,30,13,1],
[39,30,13,2],
[39,30,13,4],
[39,30,13,7],
[39,30,13,9],
[39,30,13,10],
[39,30,13,11],
[39,30,13,12],
[39,30,14,0],
[39,30,14,1],
[39,30,14,2],
[39,30,14,4],
[39,30,14,7],
[39,30,14,9],
[39,30,14,10],
[39,30,14,11],
[39,30,14,12],
[39,30,15,0],
[39,30,15,1],
[39,30,15,2],
[39,30,15,4],
[39,30,15,7],
[39,30,15,10],
[39,30,15,11],
[39,30,15,12],
[39,30,15,13],
[39,30,15,14],
[39,30,16,0],
[39,30,16,1],
[39,30,16,2],
[39,30,16,4],
[39,30,16,7],
[39,30,16,9],
[39,30,16,10],
[39,30,16,11],
[39,30,16,12],
[39,30,16,13],
[39,30,16,14],
[39,30,16,15],
[39,30,17,0],
[39,30,17,1],
[39,30,17,2],
[39,30,17,4],
[39,30,17,7],
[39,30,17,9],
[39,30,17,10],
[39,30,17,11],
[39,30,17,12],
[39,30,17,13],
[39,30,17,14],
[39,30,17,15],
[39,30,17,16],
[39,30,18,0],
[39,30,18,1],
[39,30,18,2],
[39,30,18,4],
[39,30,18,7],
[39,30,18,10],
[39,30,18,11],
[39,30,18,12],
[39,30,18,13],
[39,30,18,14],
[39,30,18,16],
[39,30,18,17],
[39,30,19,0],
[39,30,19,1],
[39,30,19,2],
[39,30,19,4],
[39,30,19,7],
[39,30,19,9],
[39,30,19,10],
[39,30,19,11],
[39,30,19,12],
[39,30,19,15],
[39,30,19,16],
[39,30,19,17],
[39,30,19,18],
[39,30,20,0],
[39,30,20,1],
[39,30,20,2],
[39,30,20,4],
[39,30,20,7],
[39,30,20,9],
[39,30,20,11],
[39,30,20,12],
[39,30,20,13],
[39,30,20,14],
[39,30,20,15],
[39,30,20,16],
[39,30,20,17],
[39,30,20,18],
[39,30,20,19],
[39,30,22,0],
[39,30,22,1],
[39,30,22,2],
[39,30,22,4],
[39,30,22,7],
[39,30,22,9],
[39,30,22,11],
[39,30,22,12],
[39,30,22,13],
[39,30,22,14],
[39,30,22,15],
[39,30,22,16],
[39,30,22,17],
[39,30,22,18],
[39,30,22,19],
[39,30,24,0],
[39,30,24,1],
[39,30,24,2],
[39,30,24,4],
[39,30,24,7],
[39,30,24,9],
[39,30,24,10],
[39,30,24,11],
[39,30,24,12],
[39,30,24,13],
[39,30,24,14],
[39,30,24,15],
[39,30,24,17],
[39,30,24,18],
[39,30,24,19],
[39,30,24,20],
[39,30,24,22],
[39,30,25,0],
[39,30,25,1],
[39,30,25,2],
[39,30,25,4],
[39,30,25,9],
[39,30,25,10],
[39,30,25,11],
[39,30,25,12],
[39,30,25,13],
[39,30,25,14],
[39,30,25,15],
[39,30,25,16],
[39,30,25,17],
[39,30,25,18],
[39,30,25,19],
[39,30,25,20],
[39,30,25,22],
[39,30,25,24],
[39,30,26,0],
[39,30,26,1],
[39,30,26,2],
[39,30,26,4],
[39,30,26,7],
[39,30,26,10],
[39,30,26,11],
[39,30,26,12],
[39,30,26,13],
[39,30,26,14],
[39,30,26,16],
[39,30,26,17],
[39,30,26,19],
[39,30,26,20],
[39,30,26,22],
[39,30,26,24],
[39,30,26,25],
[39,30,27,0],
[39,30,27,1],
[39,30,27,2],
[39,30,27,4],
[39,30,27,7],
[39,30,27,9],
[39,30,27,11],
[39,30,27,12],
[39,30,27,13],
[39,30,27,14],
[39,30,27,15],
[39,30,27,16],
[39,30,27,17],
[39,30,27,18],
[39,30,27,19],
[39,30,27,24],
[39,30,27,25],
[39,30,27,26],
[39,30,28,0],
[39,30,28,1],
[39,30,28,2],
[39,30,28,4],
[39,30,28,9],
[39,30,28,10],
[39,30,28,11],
[39,30,28,12],
[39,30,28,13],
[39,30,28,14],
[39,30,28,15],
[39,30,28,16],
[39,30,28,17],
[39,30,28,18],
[39,30,28,19],
[39,30,28,20],
[39,30,28,22],
[39,30,28,24],
[39,30,28,26],
[39,30,28,27],
[39,30,29,0],
[39,30,29,1],
[39,30,29,2],
[39,30,29,4],
[39,30,29,7],
[39,30,29,9],
[39,30,29,10],
[39,30,29,13],
[39,30,29,14],
[39,30,29,15],
[39,30,29,16],
[39,30,29,17],
[39,30,29,18],
[39,30,29,19],
[39,30,29,20],
[39,30,29,22],
[39,30,29,24],
[39,30,29,25],
[39,30,29,26],
[39,30,29,27],
[39,30,29,28],
[39,31,4,0],
[39,31,4,1],
[39,31,4,2],
[39,31,7,0],
[39,31,7,1],
[39,31,7,2],
[39,31,7,4],
[39,31,9,0],
[39,31,9,1],
[39,31,9,2],
[39,31,9,4],
[39,31,9,7],
[39,31,10,0],
[39,31,10,1],
[39,31,10,2],
[39,31,10,4],
[39,31,10,7],
[39,31,10,9],
[39,31,11,0],
[39,31,11,1],
[39,31,11,2],
[39,31,11,4],
[39,31,11,7],
[39,31,11,9],
[39,31,11,10],
[39,31,12,0],
[39,31,12,1],
[39,31,12,2],
[39,31,12,4],
[39,31,12,7],
[39,31,12,9],
[39,31,12,10],
[39,31,13,0],
[39,31,13,1],
[39,31,13,2],
[39,31,13,4],
[39,31,13,7],
[39,31,13,9],
[39,31,13,10],
[39,31,13,11],
[39,31,13,12],
[39,31,14,0],
[39,31,14,1],
[39,31,14,2],
[39,31,14,4],
[39,31,14,7],
[39,31,14,9],
[39,31,14,10],
[39,31,14,11],
[39,31,14,12],
[39,31,15,0],
[39,31,15,1],
[39,31,15,2],
[39,31,15,4],
[39,31,15,7],
[39,31,15,10],
[39,31,15,11],
[39,31,15,12],
[39,31,15,13],
[39,31,15,14],
[39,31,16,0],
[39,31,16,1],
[39,31,16,2],
[39,31,16,4],
[39,31,16,7],
[39,31,16,9],
[39,31,16,10],
[39,31,16,11],
[39,31,16,12],
[39,31,16,13],
[39,31,16,14],
[39,31,16,15],
[39,31,17,0],
[39,31,17,1],
[39,31,17,2],
[39,31,17,4],
[39,31,17,7],
[39,31,17,9],
[39,31,17,10],
[39,31,17,11],
[39,31,17,12],
[39,31,17,13],
[39,31,17,14],
[39,31,17,15],
[39,31,17,16],
[39,31,18,0],
[39,31,18,1],
[39,31,18,2],
[39,31,18,4],
[39,31,18,7],
[39,31,18,10],
[39,31,18,11],
[39,31,18,12],
[39,31,18,13],
[39,31,18,14],
[39,31,18,16],
[39,31,18,17],
[39,31,19,0],
[39,31,19,1],
[39,31,19,2],
[39,31,19,4],
[39,31,19,7],
[39,31,19,9],
[39,31,19,10],
[39,31,19,11],
[39,31,19,12],
[39,31,19,15],
[39,31,19,16],
[39,31,19,17],
[39,31,19,18],
[39,31,20,0],
[39,31,20,1],
[39,31,20,2],
[39,31,20,4],
[39,31,20,7],
[39,31,20,9],
[39,31,20,11],
[39,31,20,12],
[39,31,20,13],
[39,31,20,14],
[39,31,20,15],
[39,31,20,16],
[39,31,20,17],
[39,31,20,18],
[39,31,20,19],
[39,31,22,0],
[39,31,22,1],
[39,31,22,2],
[39,31,22,4],
[39,31,22,7],
[39,31,22,9],
[39,31,22,11],
[39,31,22,12],
[39,31,22,13],
[39,31,22,14],
[39,31,22,15],
[39,31,22,16],
[39,31,22,17],
[39,31,22,18],
[39,31,22,19],
[39,31,24,0],
[39,31,24,1],
[39,31,24,2],
[39,31,24,4],
[39,31,24,7],
[39,31,24,9],
[39,31,24,10],
[39,31,24,11],
[39,31,24,12],
[39,31,24,13],
[39,31,24,14],
[39,31,24,15],
[39,31,24,17],
[39,31,24,18],
[39,31,24,19],
[39,31,24,20],
[39,31,24,22],
[39,31,25,0],
[39,31,25,1],
[39,31,25,2],
[39,31,25,4],
[39,31,25,9],
[39,31,25,10],
[39,31,25,11],
[39,31,25,12],
[39,31,25,13],
[39,31,25,14],
[39,31,25,15],
[39,31,25,16],
[39,31,25,17],
[39,31,25,18],
[39,31,25,19],
[39,31,25,20],
[39,31,25,22],
[39,31,25,24],
[39,31,26,0],
[39,31,26,1],
[39,31,26,2],
[39,31,26,4],
[39,31,26,7],
[39,31,26,10],
[39,31,26,11],
[39,31,26,12],
[39,31,26,13],
[39,31,26,14],
[39,31,26,16],
[39,31,26,17],
[39,31,26,19],
[39,31,26,20],
[39,31,26,22],
[39,31,26,24],
[39,31,26,25],
[39,31,27,0],
[39,31,27,1],
[39,31,27,2],
[39,31,27,4],
[39,31,27,7],
[39,31,27,9],
[39,31,27,11],
[39,31,27,12],
[39,31,27,13],
[39,31,27,14],
[39,31,27,15],
[39,31,27,16],
[39,31,27,17],
[39,31,27,18],
[39,31,27,19],
[39,31,27,24],
[39,31,27,25],
[39,31,27,26],
[39,31,28,0],
[39,31,28,1],
[39,31,28,2],
[39,31,28,4],
[39,31,28,9],
[39,31,28,10],
[39,31,28,11],
[39,31,28,12],
[39,31,28,13],
[39,31,28,14],
[39,31,28,15],
[39,31,28,16],
[39,31,28,17],
[39,31,28,18],
[39,31,28,19],
[39,31,28,20],
[39,31,28,22],
[39,31,28,24],
[39,31,28,26],
[39,31,28,27],
[39,31,29,0],
[39,31,29,1],
[39,31,29,2],
[39,31,29,4],
[39,31,29,7],
[39,31,29,9],
[39,31,29,10],
[39,31,29,13],
[39,31,29,14],
[39,31,29,15],
[39,31,29,16],
[39,31,29,17],
[39,31,29,18],
[39,31,29,19],
[39,31,29,20],
[39,31,29,22],
[39,31,29,24],
[39,31,29,25],
[39,31,29,26],
[39,31,29,27],
[39,32,4,0],
[39,32,4,1],
[39,32,4,2],
[39,32,7,0],
[39,32,7,1],
[39,32,7,2],
[39,32,7,4],
[39,32,9,0],
[39,32,9,1],
[39,32,9,2],
[39,32,9,4],
[39,32,9,7],
[39,32,10,0],
[39,32,10,1],
[39,32,10,2],
[39,32,10,4],
[39,32,10,7],
[39,32,10,9],
[39,32,13,0],
[39,32,13,1],
[39,32,13,2],
[39,32,13,4],
[39,32,13,7],
[39,32,13,9],
[39,32,13,10],
[39,32,14,0],
[39,32,14,1],
[39,32,14,2],
[39,32,14,4],
[39,32,14,7],
[39,32,14,9],
[39,32,14,10],
[39,32,15,0],
[39,32,15,1],
[39,32,15,2],
[39,32,15,4],
[39,32,15,7],
[39,32,15,10],
[39,32,15,13],
[39,32,15,14],
[39,32,16,0],
[39,32,16,1],
[39,32,16,2],
[39,32,16,4],
[39,32,16,7],
[39,32,16,9],
[39,32,16,10],
[39,32,16,13],
[39,32,16,14],
[39,32,16,15],
[39,32,17,0],
[39,32,17,1],
[39,32,17,2],
[39,32,17,4],
[39,32,17,7],
[39,32,17,9],
[39,32,17,10],
[39,32,17,13],
[39,32,17,14],
[39,32,17,15],
[39,32,17,16],
[39,32,18,0],
[39,32,18,1],
[39,32,18,2],
[39,32,18,4],
[39,32,18,7],
[39,32,18,10],
[39,32,18,13],
[39,32,18,14],
[39,32,18,16],
[39,32,18,17],
[39,32,19,0],
[39,32,19,1],
[39,32,19,2],
[39,32,19,4],
[39,32,19,7],
[39,32,19,9],
[39,32,19,10],
[39,32,19,15],
[39,32,19,16],
[39,32,19,17],
[39,32,19,18],
[39,32,20,0],
[39,32,20,1],
[39,32,20,2],
[39,32,20,4],
[39,32,20,7],
[39,32,20,9],
[39,32,20,13],
[39,32,20,14],
[39,32,20,15],
[39,32,20,16],
[39,32,20,17],
[39,32,20,18],
[39,32,20,19],
[39,32,22,0],
[39,32,22,1],
[39,32,22,2],
[39,32,22,4],
[39,32,22,7],
[39,32,22,9],
[39,32,22,13],
[39,32,22,14],
[39,32,22,15],
[39,32,22,16],
[39,32,22,17],
[39,32,22,18],
[39,32,22,19],
[39,32,23,0],
[39,32,23,1],
[39,32,23,2],
[39,32,23,4],
[39,32,23,7],
[39,32,23,9],
[39,32,23,10],
[39,32,23,13],
[39,32,23,14],
[39,32,23,15],
[39,32,23,16],
[39,32,23,17],
[39,32,23,18],
[39,32,23,19],
[39,32,23,20],
[39,32,23,22],
[39,32,24,0],
[39,32,24,1],
[39,32,24,2],
[39,32,24,4],
[39,32,24,7],
[39,32,24,9],
[39,32,24,10],
[39,32,24,13],
[39,32,24,14],
[39,32,24,15],
[39,32,24,17],
[39,32,24,18],
[39,32,24,19],
[39,32,24,20],
[39,32,24,22],
[39,32,24,23],
[39,32,25,0],
[39,32,25,1],
[39,32,25,2],
[39,32,25,4],
[39,32,25,9],
[39,32,25,10],
[39,32,25,13],
[39,32,25,14],
[39,32,25,15],
[39,32,25,16],
[39,32,25,17],
[39,32,25,18],
[39,32,25,19],
[39,32,25,20],
[39,32,25,22],
[39,32,25,23],
[39,32,25,24],
[39,32,26,0],
[39,32,26,1],
[39,32,26,2],
[39,32,26,4],
[39,32,26,7],
[39,32,26,10],
[39,32,26,13],
[39,32,26,14],
[39,32,26,16],
[39,32,26,17],
[39,32,26,19],
[39,32,26,20],
[39,32,26,22],
[39,32,26,23],
[39,32,26,24],
[39,32,26,25],
[39,32,27,0],
[39,32,27,1],
[39,32,27,2],
[39,32,27,4],
[39,32,27,7],
[39,32,27,9],
[39,32,27,13],
[39,32,27,14],
[39,32,27,15],
[39,32,27,16],
[39,32,27,17],
[39,32,27,18],
[39,32,27,19],
[39,32,27,23],
[39,32,27,24],
[39,32,27,25],
[39,32,27,26],
[39,32,28,0],
[39,32,28,1],
[39,32,28,2],
[39,32,28,4],
[39,32,28,9],
[39,32,28,10],
[39,32,28,13],
[39,32,28,14],
[39,32,28,15],
[39,32,28,16],
[39,32,28,17],
[39,32,28,18],
[39,32,28,19],
[39,32,28,20],
[39,32,28,22],
[39,32,28,23],
[39,32,28,24],
[39,32,28,26],
[39,32,28,27],
[39,32,30,0],
[39,32,30,1],
[39,32,30,2],
[39,32,30,4],
[39,32,30,7],
[39,32,30,9],
[39,32,30,10],
[39,32,30,13],
[39,32,30,14],
[39,32,30,15],
[39,32,30,16],
[39,32,30,17],
[39,32,30,18],
[39,32,30,19],
[39,32,30,20],
[39,32,30,22],
[39,32,30,24],
[39,32,30,25],
[39,32,30,27],
[39,32,30,28],
[39,32,31,0],
[39,32,31,1],
[39,32,31,2],
[39,32,31,4],
[39,32,31,7],
[39,32,31,9],
[39,32,31,10],
[39,32,31,13],
[39,32,31,14],
[39,32,31,15],
[39,32,31,16],
[39,32,31,17],
[39,32,31,18],
[39,32,31,19],
[39,32,31,20],
[39,32,31,22],
[39,32,31,24],
[39,32,31,26],
[39,32,31,27],
[39,32,31,28],
[39,33,4,0],
[39,33,4,1],
[39,33,4,2],
[39,33,7,0],
[39,33,7,1],
[39,33,7,2],
[39,33,7,4],
[39,33,9,0],
[39,33,9,1],
[39,33,9,2],
[39,33,9,4],
[39,33,9,7],
[39,33,10,0],
[39,33,10,1],
[39,33,10,2],
[39,33,10,4],
[39,33,10,7],
[39,33,10,9],
[39,33,13,0],
[39,33,13,1],
[39,33,13,2],
[39,33,13,4],
[39,33,13,7],
[39,33,13,9],
[39,33,13,10],
[39,33,14,0],
[39,33,14,1],
[39,33,14,2],
[39,33,14,4],
[39,33,14,7],
[39,33,14,9],
[39,33,14,10],
[39,33,15,0],
[39,33,15,1],
[39,33,15,2],
[39,33,15,4],
[39,33,15,7],
[39,33,15,10],
[39,33,15,13],
[39,33,15,14],
[39,33,16,0],
[39,33,16,1],
[39,33,16,2],
[39,33,16,4],
[39,33,16,7],
[39,33,16,9],
[39,33,16,10],
[39,33,16,13],
[39,33,16,14],
[39,33,16,15],
[39,33,17,0],
[39,33,17,1],
[39,33,17,2],
[39,33,17,4],
[39,33,17,7],
[39,33,17,9],
[39,33,17,10],
[39,33,17,13],
[39,33,17,14],
[39,33,17,15],
[39,33,17,16],
[39,33,18,0],
[39,33,18,1],
[39,33,18,2],
[39,33,18,4],
[39,33,18,7],
[39,33,18,10],
[39,33,18,13],
[39,33,18,14],
[39,33,18,16],
[39,33,18,17],
[39,33,19,0],
[39,33,19,1],
[39,33,19,2],
[39,33,19,4],
[39,33,19,7],
[39,33,19,9],
[39,33,19,10],
[39,33,19,15],
[39,33,19,16],
[39,33,19,17],
[39,33,19,18],
[39,33,20,0],
[39,33,20,1],
[39,33,20,2],
[39,33,20,4],
[39,33,20,7],
[39,33,20,9],
[39,33,20,13],
[39,33,20,14],
[39,33,20,15],
[39,33,20,16],
[39,33,20,17],
[39,33,20,18],
[39,33,20,19],
[39,33,22,0],
[39,33,22,1],
[39,33,22,2],
[39,33,22,4],
[39,33,22,7],
[39,33,22,9],
[39,33,22,13],
[39,33,22,14],
[39,33,22,15],
[39,33,22,16],
[39,33,22,17],
[39,33,22,18],
[39,33,22,19],
[39,33,23,0],
[39,33,23,1],
[39,33,23,2],
[39,33,23,4],
[39,33,23,7],
[39,33,23,9],
[39,33,23,10],
[39,33,23,13],
[39,33,23,14],
[39,33,23,15],
[39,33,23,16],
[39,33,23,17],
[39,33,23,18],
[39,33,23,19],
[39,33,23,20],
[39,33,23,22],
[39,33,24,0],
[39,33,24,1],
[39,33,24,2],
[39,33,24,4],
[39,33,24,7],
[39,33,24,9],
[39,33,24,10],
[39,33,24,13],
[39,33,24,14],
[39,33,24,15],
[39,33,24,17],
[39,33,24,18],
[39,33,24,19],
[39,33,24,20],
[39,33,24,22],
[39,33,24,23],
[39,33,25,0],
[39,33,25,1],
[39,33,25,2],
[39,33,25,4],
[39,33,25,9],
[39,33,25,10],
[39,33,25,13],
[39,33,25,14],
[39,33,25,15],
[39,33,25,16],
[39,33,25,17],
[39,33,25,18],
[39,33,25,19],
[39,33,25,20],
[39,33,25,22],
[39,33,25,23],
[39,33,25,24],
[39,33,26,0],
[39,33,26,1],
[39,33,26,2],
[39,33,26,4],
[39,33,26,7],
[39,33,26,10],
[39,33,26,13],
[39,33,26,14],
[39,33,26,16],
[39,33,26,17],
[39,33,26,19],
[39,33,26,20],
[39,33,26,22],
[39,33,26,23],
[39,33,26,24],
[39,33,26,25],
[39,33,27,0],
[39,33,27,1],
[39,33,27,2],
[39,33,27,4],
[39,33,27,7],
[39,33,27,9],
[39,33,27,13],
[39,33,27,14],
[39,33,27,15],
[39,33,27,16],
[39,33,27,17],
[39,33,27,18],
[39,33,27,19],
[39,33,27,23],
[39,33,27,24],
[39,33,27,25],
[39,33,27,26],
[39,33,28,0],
[39,33,28,1],
[39,33,28,2],
[39,33,28,4],
[39,33,28,9],
[39,33,28,10],
[39,33,28,13],
[39,33,28,14],
[39,33,28,15],
[39,33,28,16],
[39,33,28,17],
[39,33,28,18],
[39,33,28,19],
[39,33,28,20],
[39,33,28,22],
[39,33,28,23],
[39,33,28,24],
[39,33,28,26],
[39,33,30,0],
[39,33,30,1],
[39,33,30,2],
[39,33,30,4],
[39,33,30,7],
[39,33,30,9],
[39,33,30,10],
[39,33,30,13],
[39,33,30,14],
[39,33,30,15],
[39,33,30,16],
[39,33,30,17],
[39,33,30,18],
[39,33,30,19],
[39,33,30,20],
[39,33,30,22],
[39,33,30,24],
[39,33,30,26],
[39,33,30,27],
[39,33,30,28],
[39,33,31,0],
[39,33,31,1],
[39,33,31,2],
[39,33,31,4],
[39,33,31,7],
[39,33,31,9],
[39,33,31,10],
[39,33,31,13],
[39,33,31,14],
[39,33,31,15],
[39,33,31,16],
[39,33,31,17],
[39,33,31,18],
[39,33,31,19],
[39,33,31,20],
[39,33,31,22],
[39,33,31,24],
[39,33,31,25],
[39,33,31,26],
[39,33,31,27],
[39,33,31,28],
[39,34,4,0],
[39,34,4,1],
[39,34,4,2],
[39,34,9,0],
[39,34,9,1],
[39,34,9,2],
[39,34,9,4],
[39,34,10,0],
[39,34,10,1],
[39,34,10,2],
[39,34,10,4],
[39,34,10,9],
[39,34,11,0],
[39,34,11,1],
[39,34,11,2],
[39,34,11,4],
[39,34,11,9],
[39,34,11,10],
[39,34,12,0],
[39,34,12,1],
[39,34,12,2],
[39,34,12,4],
[39,34,12,9],
[39,34,12,10],
[39,34,13,0],
[39,34,13,1],
[39,34,13,2],
[39,34,13,4],
[39,34,13,9],
[39,34,13,10],
[39,34,13,11],
[39,34,13,12],
[39,34,14,0],
[39,34,14,1],
[39,34,14,2],
[39,34,14,4],
[39,34,14,9],
[39,34,14,10],
[39,34,14,11],
[39,34,14,12],
[39,34,15,0],
[39,34,15,1],
[39,34,15,2],
[39,34,15,4],
[39,34,15,10],
[39,34,15,11],
[39,34,15,12],
[39,34,15,13],
[39,34,15,14],
[39,34,16,0],
[39,34,16,1],
[39,34,16,2],
[39,34,16,4],
[39,34,16,9],
[39,34,16,10],
[39,34,16,11],
[39,34,16,12],
[39,34,16,13],
[39,34,16,14],
[39,34,16,15],
[39,34,17,0],
[39,34,17,1],
[39,34,17,2],
[39,34,17,4],
[39,34,17,9],
[39,34,17,10],
[39,34,17,11],
[39,34,17,12],
[39,34,17,13],
[39,34,17,14],
[39,34,17,15],
[39,34,17,16],
[39,34,18,0],
[39,34,18,1],
[39,34,18,2],
[39,34,18,4],
[39,34,18,10],
[39,34,18,11],
[39,34,18,12],
[39,34,18,13],
[39,34,18,14],
[39,34,18,16],
[39,34,18,17],
[39,34,19,0],
[39,34,19,1],
[39,34,19,2],
[39,34,19,4],
[39,34,19,9],
[39,34,19,10],
[39,34,19,11],
[39,34,19,12],
[39,34,19,15],
[39,34,19,16],
[39,34,19,17],
[39,34,19,18],
[39,34,20,0],
[39,34,20,1],
[39,34,20,2],
[39,34,20,4],
[39,34,20,9],
[39,34,20,11],
[39,34,20,12],
[39,34,20,13],
[39,34,20,14],
[39,34,20,15],
[39,34,20,16],
[39,34,20,17],
[39,34,20,18],
[39,34,20,19],
[39,34,22,0],
[39,34,22,1],
[39,34,22,2],
[39,34,22,4],
[39,34,22,9],
[39,34,22,11],
[39,34,22,12],
[39,34,22,13],
[39,34,22,14],
[39,34,22,15],
[39,34,22,16],
[39,34,22,17],
[39,34,22,18],
[39,34,22,19],
[39,34,23,0],
[39,34,23,1],
[39,34,23,2],
[39,34,23,4],
[39,34,23,9],
[39,34,23,10],
[39,34,23,11],
[39,34,23,12],
[39,34,23,13],
[39,34,23,14],
[39,34,23,15],
[39,34,23,16],
[39,34,23,17],
[39,34,23,18],
[39,34,23,19],
[39,34,23,20],
[39,34,23,22],
[39,34,24,0],
[39,34,24,1],
[39,34,24,2],
[39,34,24,4],
[39,34,24,9],
[39,34,24,10],
[39,34,24,11],
[39,34,24,12],
[39,34,24,13],
[39,34,24,14],
[39,34,24,15],
[39,34,24,17],
[39,34,24,18],
[39,34,24,19],
[39,34,24,20],
[39,34,24,22],
[39,34,24,23],
[39,34,26,0],
[39,34,26,1],
[39,34,26,2],
[39,34,26,4],
[39,34,26,10],
[39,34,26,11],
[39,34,26,12],
[39,34,26,13],
[39,34,26,14],
[39,34,26,16],
[39,34,26,17],
[39,34,26,19],
[39,34,26,20],
[39,34,26,22],
[39,34,26,23],
[39,34,26,24],
[39,34,27,0],
[39,34,27,1],
[39,34,27,2],
[39,34,27,4],
[39,34,27,9],
[39,34,27,11],
[39,34,27,12],
[39,34,27,13],
[39,34,27,14],
[39,34,27,15],
[39,34,27,16],
[39,34,27,17],
[39,34,27,18],
[39,34,27,19],
[39,34,27,23],
[39,34,27,24],
[39,34,27,26],
[39,34,29,0],
[39,34,29,1],
[39,34,29,2],
[39,34,29,4],
[39,34,29,9],
[39,34,29,10],
[39,34,29,13],
[39,34,29,14],
[39,34,29,15],
[39,34,29,16],
[39,34,29,17],
[39,34,29,18],
[39,34,29,19],
[39,34,29,20],
[39,34,29,22],
[39,34,29,23],
[39,34,29,24],
[39,34,29,26],
[39,34,29,27],
[39,34,30,0],
[39,34,30,1],
[39,34,30,2],
[39,34,30,4],
[39,34,30,9],
[39,34,30,10],
[39,34,30,11],
[39,34,30,12],
[39,34,30,13],
[39,34,30,14],
[39,34,30,15],
[39,34,30,16],
[39,34,30,17],
[39,34,30,18],
[39,34,30,19],
[39,34,30,20],
[39,34,30,22],
[39,34,30,24],
[39,34,30,26],
[39,34,30,27],
[39,34,30,29],
[39,34,31,0],
[39,34,31,1],
[39,34,31,2],
[39,34,31,4],
[39,34,31,9],
[39,34,31,10],
[39,34,31,11],
[39,34,31,12],
[39,34,31,13],
[39,34,31,14],
[39,34,31,15],
[39,34,31,16],
[39,34,31,17],
[39,34,31,18],
[39,34,31,19],
[39,34,31,20],
[39,34,31,22],
[39,34,31,26],
[39,34,31,27],
[39,34,31,29],
[39,34,32,0],
[39,34,32,1],
[39,34,32,2],
[39,34,32,4],
[39,34,32,9],
[39,34,32,10],
[39,34,32,13],
[39,34,32,14],
[39,34,32,15],
[39,34,32,16],
[39,34,32,17],
[39,34,32,18],
[39,34,32,19],
[39,34,32,20],
[39,34,32,23],
[39,34,32,24],
[39,34,32,26],
[39,34,32,27],
[39,34,32,30],
[39,34,32,31],
[39,34,33,0],
[39,34,33,1],
[39,34,33,2],
[39,34,33,4],
[39,34,33,9],
[39,34,33,10],
[39,34,33,13],
[39,34,33,14],
[39,34,33,15],
[39,34,33,16],
[39,34,33,17],
[39,34,33,18],
[39,34,33,19],
[39,34,33,20],
[39,34,33,22],
[39,34,33,23],
[39,34,33,24],
[39,34,33,26],
[39,34,33,27],
[39,34,33,30],
[39,34,33,31],
[39,35,4,0],
[39,35,4,1],
[39,35,4,2],
[39,35,7,0],
[39,35,7,1],
[39,35,7,2],
[39,35,7,4],
[39,35,10,0],
[39,35,10,1],
[39,35,10,2],
[39,35,10,4],
[39,35,10,7],
[39,35,11,0],
[39,35,11,1],
[39,35,11,2],
[39,35,11,4],
[39,35,11,7],
[39,35,11,10],
[39,35,12,0],
[39,35,12,1],
[39,35,12,2],
[39,35,12,4],
[39,35,12,7],
[39,35,12,10],
[39,35,13,0],
[39,35,13,1],
[39,35,13,2],
[39,35,13,4],
[39,35,13,7],
[39,35,13,10],
[39,35,13,11],
[39,35,13,12],
[39,35,14,0],
[39,35,14,1],
[39,35,14,2],
[39,35,14,4],
[39,35,14,7],
[39,35,14,10],
[39,35,14,11],
[39,35,14,12],
[39,35,16,0],
[39,35,16,1],
[39,35,16,2],
[39,35,16,4],
[39,35,16,7],
[39,35,16,10],
[39,35,16,11],
[39,35,16,12],
[39,35,16,13],
[39,35,16,14],
[39,35,17,0],
[39,35,17,1],
[39,35,17,2],
[39,35,17,4],
[39,35,17,7],
[39,35,17,10],
[39,35,17,11],
[39,35,17,12],
[39,35,17,13],
[39,35,17,14],
[39,35,17,16],
[39,35,19,0],
[39,35,19,1],
[39,35,19,2],
[39,35,19,4],
[39,35,19,7],
[39,35,19,10],
[39,35,19,11],
[39,35,19,12],
[39,35,19,16],
[39,35,19,17],
[39,35,20,0],
[39,35,20,1],
[39,35,20,2],
[39,35,20,4],
[39,35,20,7],
[39,35,20,11],
[39,35,20,12],
[39,35,20,13],
[39,35,20,14],
[39,35,20,16],
[39,35,20,17],
[39,35,20,19],
[39,35,22,0],
[39,35,22,1],
[39,35,22,2],
[39,35,22,4],
[39,35,22,7],
[39,35,22,11],
[39,35,22,12],
[39,35,22,13],
[39,35,22,14],
[39,35,22,16],
[39,35,22,17],
[39,35,22,19],
[39,35,23,0],
[39,35,23,1],
[39,35,23,2],
[39,35,23,4],
[39,35,23,7],
[39,35,23,10],
[39,35,23,11],
[39,35,23,12],
[39,35,23,13],
[39,35,23,14],
[39,35,23,16],
[39,35,23,17],
[39,35,23,19],
[39,35,23,20],
[39,35,23,22],
[39,35,24,0],
[39,35,24,1],
[39,35,24,2],
[39,35,24,4],
[39,35,24,7],
[39,35,24,10],
[39,35,24,11],
[39,35,24,12],
[39,35,24,13],
[39,35,24,14],
[39,35,24,17],
[39,35,24,19],
[39,35,24,20],
[39,35,24,22],
[39,35,24,23],
[39,35,25,0],
[39,35,25,1],
[39,35,25,2],
[39,35,25,4],
[39,35,25,10],
[39,35,25,11],
[39,35,25,12],
[39,35,25,13],
[39,35,25,14],
[39,35,25,16],
[39,35,25,17],
[39,35,25,19],
[39,35,25,20],
[39,35,25,22],
[39,35,25,23],
[39,35,25,24],
[39,35,27,0],
[39,35,27,1],
[39,35,27,2],
[39,35,27,4],
[39,35,27,7],
[39,35,27,11],
[39,35,27,12],
[39,35,27,13],
[39,35,27,14],
[39,35,27,16],
[39,35,27,17],
[39,35,27,19],
[39,35,27,23],
[39,35,27,24],
[39,35,27,25],
[39,35,28,0],
[39,35,28,1],
[39,35,28,2],
[39,35,28,4],
[39,35,28,10],
[39,35,28,11],
[39,35,28,12],
[39,35,28,13],
[39,35,28,14],
[39,35,28,16],
[39,35,28,17],
[39,35,28,19],
[39,35,28,20],
[39,35,28,22],
[39,35,28,23],
[39,35,28,24],
[39,35,28,27],
[39,35,29,0],
[39,35,29,1],
[39,35,29,2],
[39,35,29,4],
[39,35,29,7],
[39,35,29,10],
[39,35,29,13],
[39,35,29,14],
[39,35,29,16],
[39,35,29,17],
[39,35,29,19],
[39,35,29,20],
[39,35,29,22],
[39,35,29,23],
[39,35,29,24],
[39,35,29,25],
[39,35,29,27],
[39,35,29,28],
[39,35,30,0],
[39,35,30,1],
[39,35,30,2],
[39,35,30,4],
[39,35,30,7],
[39,35,30,10],
[39,35,30,11],
[39,35,30,12],
[39,35,30,13],
[39,35,30,14],
[39,35,30,16],
[39,35,30,17],
[39,35,30,19],
[39,35,30,20],
[39,35,30,22],
[39,35,30,25],
[39,35,30,27],
[39,35,30,28],
[39,35,30,29],
[39,35,31,0],
[39,35,31,1],
[39,35,31,2],
[39,35,31,4],
[39,35,31,7],
[39,35,31,10],
[39,35,31,11],
[39,35,31,12],
[39,35,31,13],
[39,35,31,14],
[39,35,31,16],
[39,35,31,17],
[39,35,31,19],
[39,35,31,20],
[39,35,31,24],
[39,35,31,25],
[39,35,31,27],
[39,35,31,28],
[39,35,31,29],
[39,35,32,0],
[39,35,32,1],
[39,35,32,2],
[39,35,32,4],
[39,35,32,7],
[39,35,32,10],
[39,35,32,13],
[39,35,32,14],
[39,35,32,16],
[39,35,32,17],
[39,35,32,19],
[39,35,32,20],
[39,35,32,22],
[39,35,32,23],
[39,35,32,24],
[39,35,32,25],
[39,35,32,27],
[39,35,32,28],
[39,35,32,30],
[39,35,32,31],
[39,35,33,0],
[39,35,33,1],
[39,35,33,2],
[39,35,33,4],
[39,35,33,7],
[39,35,33,10],
[39,35,33,13],
[39,35,33,14],
[39,35,33,16],
[39,35,33,17],
[39,35,33,19],
[39,35,33,22],
[39,35,33,23],
[39,35,33,24],
[39,35,33,25],
[39,35,33,27],
[39,35,33,28],
[39,35,33,30],
[39,35,33,31],
[39,35,34,0],
[39,35,34,1],
[39,35,34,2],
[39,35,34,4],
[39,35,34,10],
[39,35,34,11],
[39,35,34,12],
[39,35,34,13],
[39,35,34,14],
[39,35,34,16],
[39,35,34,17],
[39,35,34,20],
[39,35,34,22],
[39,35,34,23],
[39,35,34,24],
[39,35,34,27],
[39,35,34,29],
[39,35,34,30],
[39,35,34,31],
[39,36,4,0],
[39,36,4,1],
[39,36,4,2],
[39,36,7,0],
[39,36,7,1],
[39,36,7,2],
[39,36,7,4],
[39,36,9,0],
[39,36,9,1],
[39,36,9,2],
[39,36,9,4],
[39,36,9,7],
[39,36,10,0],
[39,36,10,1],
[39,36,10,2],
[39,36,10,4],
[39,36,10,7],
[39,36,10,9],
[39,36,11,0],
[39,36,11,1],
[39,36,11,2],
[39,36,11,4],
[39,36,11,7],
[39,36,11,9],
[39,36,11,10],
[39,36,12,0],
[39,36,12,1],
[39,36,12,2],
[39,36,12,4],
[39,36,12,7],
[39,36,12,9],
[39,36,12,10],
[39,36,15,0],
[39,36,15,1],
[39,36,15,2],
[39,36,15,4],
[39,36,15,7],
[39,36,15,10],
[39,36,15,11],
[39,36,15,12],
[39,36,16,0],
[39,36,16,1],
[39,36,16,2],
[39,36,16,4],
[39,36,16,7],
[39,36,16,9],
[39,36,16,10],
[39,36,16,11],
[39,36,16,12],
[39,36,16,15],
[39,36,17,0],
[39,36,17,1],
[39,36,17,2],
[39,36,17,4],
[39,36,17,7],
[39,36,17,9],
[39,36,17,10],
[39,36,17,11],
[39,36,17,12],
[39,36,17,15],
[39,36,17,16],
[39,36,18,0],
[39,36,18,1],
[39,36,18,2],
[39,36,18,4],
[39,36,18,7],
[39,36,18,10],
[39,36,18,11],
[39,36,18,12],
[39,36,18,16],
[39,36,18,17],
[39,36,20,0],
[39,36,20,1],
[39,36,20,2],
[39,36,20,4],
[39,36,20,7],
[39,36,20,9],
[39,36,20,11],
[39,36,20,12],
[39,36,20,15],
[39,36,20,16],
[39,36,20,17],
[39,36,20,18],
[39,36,22,0],
[39,36,22,1],
[39,36,22,2],
[39,36,22,4],
[39,36,22,7],
[39,36,22,9],
[39,36,22,11],
[39,36,22,12],
[39,36,22,15],
[39,36,22,16],
[39,36,22,17],
[39,36,22,18],
[39,36,23,0],
[39,36,23,1],
[39,36,23,2],
[39,36,23,4],
[39,36,23,7],
[39,36,23,9],
[39,36,23,10],
[39,36,23,11],
[39,36,23,12],
[39,36,23,15],
[39,36,23,16],
[39,36,23,17],
[39,36,23,18],
[39,36,23,20],
[39,36,23,22],
[39,36,24,0],
[39,36,24,1],
[39,36,24,2],
[39,36,24,4],
[39,36,24,7],
[39,36,24,9],
[39,36,24,10],
[39,36,24,11],
[39,36,24,12],
[39,36,24,15],
[39,36,24,17],
[39,36,24,18],
[39,36,24,20],
[39,36,24,22],
[39,36,24,23],
[39,36,25,0],
[39,36,25,1],
[39,36,25,2],
[39,36,25,4],
[39,36,25,9],
[39,36,25,10],
[39,36,25,11],
[39,36,25,12],
[39,36,25,15],
[39,36,25,16],
[39,36,25,17],
[39,36,25,18],
[39,36,25,20],
[39,36,25,22],
[39,36,25,23],
[39,36,25,24],
[39,36,26,0],
[39,36,26,1],
[39,36,26,2],
[39,36,26,4],
[39,36,26,7],
[39,36,26,10],
[39,36,26,11],
[39,36,26,12],
[39,36,26,16],
[39,36,26,17],
[39,36,26,20],
[39,36,26,22],
[39,36,26,23],
[39,36,26,24],
[39,36,26,25],
[39,36,27,0],
[39,36,27,1],
[39,36,27,2],
[39,36,27,4],
[39,36,27,7],
[39,36,27,9],
[39,36,27,11],
[39,36,27,12],
[39,36,27,15],
[39,36,27,16],
[39,36,27,17],
[39,36,27,18],
[39,36,27,23],
[39,36,27,24],
[39,36,27,26],
[39,36,28,0],
[39,36,28,1],
[39,36,28,2],
[39,36,28,4],
[39,36,28,9],
[39,36,28,10],
[39,36,28,11],
[39,36,28,12],
[39,36,28,15],
[39,36,28,16],
[39,36,28,17],
[39,36,28,18],
[39,36,28,20],
[39,36,28,22],
[39,36,28,23],
[39,36,28,24],
[39,36,28,26],
[39,36,28,27],
[39,36,29,0],
[39,36,29,1],
[39,36,29,2],
[39,36,29,4],
[39,36,29,7],
[39,36,29,9],
[39,36,29,10],
[39,36,29,15],
[39,36,29,16],
[39,36,29,17],
[39,36,29,18],
[39,36,29,20],
[39,36,29,22],
[39,36,29,25],
[39,36,29,26],
[39,36,29,27],
[39,36,29,28],
[39,36,30,0],
[39,36,30,1],
[39,36,30,2],
[39,36,30,4],
[39,36,30,7],
[39,36,30,9],
[39,36,30,10],
[39,36,30,11],
[39,36,30,12],
[39,36,30,15],
[39,36,30,16],
[39,36,30,17],
[39,36,30,18],
[39,36,30,20],
[39,36,30,24],
[39,36,30,25],
[39,36,30,26],
[39,36,30,27],
[39,36,30,28],
[39,36,30,29],
[39,36,31,0],
[39,36,31,1],
[39,36,31,2],
[39,36,31,4],
[39,36,31,7],
[39,36,31,9],
[39,36,31,10],
[39,36,31,11],
[39,36,31,12],
[39,36,31,15],
[39,36,31,16],
[39,36,31,17],
[39,36,31,18],
[39,36,31,20],
[39,36,31,22],
[39,36,31,24],
[39,36,31,25],
[39,36,31,26],
[39,36,31,27],
[39,36,31,28],
[39,36,31,29],
[39,36,32,0],
[39,36,32,1],
[39,36,32,2],
[39,36,32,4],
[39,36,32,7],
[39,36,32,9],
[39,36,32,10],
[39,36,32,15],
[39,36,32,16],
[39,36,32,17],
[39,36,32,18],
[39,36,32,22],
[39,36,32,23],
[39,36,32,24],
[39,36,32,25],
[39,36,32,26],
[39,36,32,27],
[39,36,32,28],
[39,36,32,30],
[39,36,32,31],
[39,36,33,0],
[39,36,33,1],
[39,36,33,2],
[39,36,33,4],
[39,36,33,7],
[39,36,33,9],
[39,36,33,10],
[39,36,33,15],
[39,36,33,16],
[39,36,33,17],
[39,36,33,18],
[39,36,33,20],
[39,36,33,22],
[39,36,33,23],
[39,36,33,24],
[39,36,33,25],
[39,36,33,26],
[39,36,33,27],
[39,36,33,28],
[39,36,33,30],
[39,36,33,31],
[39,36,34,0],
[39,36,34,1],
[39,36,34,2],
[39,36,34,4],
[39,36,34,9],
[39,36,34,10],
[39,36,34,11],
[39,36,34,12],
[39,36,34,15],
[39,36,34,16],
[39,36,34,17],
[39,36,34,20],
[39,36,34,22],
[39,36,34,23],
[39,36,34,24],
[39,36,34,26],
[39,36,34,27],
[39,36,34,29],
[39,36,34,30],
[39,36,35,0],
[39,36,35,1],
[39,36,35,2],
[39,36,35,4],
[39,36,35,7],
[39,36,35,10],
[39,36,35,11],
[39,36,35,12],
[39,36,35,20],
[39,36,35,22],
[39,36,35,23],
[39,36,35,24],
[39,36,35,25],
[39,36,35,27],
[39,36,35,28],
[39,36,35,29],
[39,37,7,0],
[39,37,7,1],
[39,37,7,2],
[39,37,9,0],
[39,37,9,1],
[39,37,9,2],
[39,37,9,7],
[39,37,10,0],
[39,37,10,1],
[39,37,10,2],
[39,37,10,7],
[39,37,10,9],
[39,37,11,0],
[39,37,11,1],
[39,37,11,2],
[39,37,11,7],
[39,37,11,9],
[39,37,11,10],
[39,37,12,0],
[39,37,12,1],
[39,37,12,2],
[39,37,12,7],
[39,37,12,9],
[39,37,12,10],
[39,37,13,0],
[39,37,13,1],
[39,37,13,2],
[39,37,13,7],
[39,37,13,9],
[39,37,13,10],
[39,37,13,11],
[39,37,13,12],
[39,37,14,0],
[39,37,14,1],
[39,37,14,2],
[39,37,14,7],
[39,37,14,9],
[39,37,14,10],
[39,37,14,11],
[39,37,14,12],
[39,37,15,0],
[39,37,15,1],
[39,37,15,2],
[39,37,15,7],
[39,37,15,10],
[39,37,15,11],
[39,37,15,12],
[39,37,15,13],
[39,37,15,14],
[39,37,16,0],
[39,37,16,1],
[39,37,16,2],
[39,37,16,7],
[39,37,16,9],
[39,37,16,10],
[39,37,16,11],
[39,37,16,12],
[39,37,16,13],
[39,37,16,14],
[39,37,16,15],
[39,37,17,0],
[39,37,17,1],
[39,37,17,2],
[39,37,17,7],
[39,37,17,9],
[39,37,17,10],
[39,37,17,11],
[39,37,17,12],
[39,37,17,13],
[39,37,17,14],
[39,37,17,15],
[39,37,17,16],
[39,37,18,0],
[39,37,18,1],
[39,37,18,2],
[39,37,18,7],
[39,37,18,10],
[39,37,18,11],
[39,37,18,12],
[39,37,18,13],
[39,37,18,14],
[39,37,18,16],
[39,37,18,17],
[39,37,19,0],
[39,37,19,1],
[39,37,19,2],
[39,37,19,7],
[39,37,19,9],
[39,37,19,10],
[39,37,19,11],
[39,37,19,12],
[39,37,19,15],
[39,37,19,16],
[39,37,19,17],
[39,37,19,18],
[39,37,20,0],
[39,37,20,1],
[39,37,20,2],
[39,37,20,7],
[39,37,20,9],
[39,37,20,11],
[39,37,20,12],
[39,37,20,13],
[39,37,20,14],
[39,37,20,15],
[39,37,20,16],
[39,37,20,17],
[39,37,20,18],
[39,37,20,19],
[39,37,22,0],
[39,37,22,1],
[39,37,22,2],
[39,37,22,7],
[39,37,22,9],
[39,37,22,11],
[39,37,22,12],
[39,37,22,13],
[39,37,22,14],
[39,37,22,15],
[39,37,22,16],
[39,37,22,17],
[39,37,22,18],
[39,37,22,19],
[39,37,23,0],
[39,37,23,1],
[39,37,23,2],
[39,37,23,7],
[39,37,23,9],
[39,37,23,10],
[39,37,23,11],
[39,37,23,12],
[39,37,23,13],
[39,37,23,14],
[39,37,23,15],
[39,37,23,16],
[39,37,23,17],
[39,37,23,18],
[39,37,23,19],
[39,37,23,20],
[39,37,23,22],
[39,37,24,0],
[39,37,24,1],
[39,37,24,2],
[39,37,24,7],
[39,37,24,9],
[39,37,24,10],
[39,37,24,11],
[39,37,24,12],
[39,37,24,13],
[39,37,24,14],
[39,37,24,15],
[39,37,24,17],
[39,37,24,18],
[39,37,24,19],
[39,37,24,20],
[39,37,24,22],
[39,37,24,23],
[39,37,25,0],
[39,37,25,1],
[39,37,25,2],
[39,37,25,9],
[39,37,25,10],
[39,37,25,11],
[39,37,25,12],
[39,37,25,13],
[39,37,25,14],
[39,37,25,15],
[39,37,25,16],
[39,37,25,17],
[39,37,25,18],
[39,37,25,19],
[39,37,25,20],
[39,37,25,22],
[39,37,25,23],
[39,37,25,24],
[39,37,26,0],
[39,37,26,1],
[39,37,26,2],
[39,37,26,7],
[39,37,26,10],
[39,37,26,11],
[39,37,26,12],
[39,37,26,13],
[39,37,26,14],
[39,37,26,16],
[39,37,26,17],
[39,37,26,19],
[39,37,26,20],
[39,37,26,22],
[39,37,26,23],
[39,37,26,24],
[39,37,27,0],
[39,37,27,1],
[39,37,27,2],
[39,37,27,7],
[39,37,27,9],
[39,37,27,11],
[39,37,27,12],
[39,37,27,13],
[39,37,27,14],
[39,37,27,15],
[39,37,27,16],
[39,37,27,17],
[39,37,27,18],
[39,37,27,19],
[39,37,27,23],
[39,37,27,24],
[39,37,27,25],
[39,37,27,26],
[39,37,28,0],
[39,37,28,1],
[39,37,28,2],
[39,37,28,9],
[39,37,28,10],
[39,37,28,11],
[39,37,28,12],
[39,37,28,13],
[39,37,28,14],
[39,37,28,15],
[39,37,28,16],
[39,37,28,17],
[39,37,28,18],
[39,37,28,19],
[39,37,28,20],
[39,37,28,22],
[39,37,28,26],
[39,37,28,27],
[39,37,29,0],
[39,37,29,1],
[39,37,29,2],
[39,37,29,7],
[39,37,29,9],
[39,37,29,10],
[39,37,29,13],
[39,37,29,14],
[39,37,29,15],
[39,37,29,16],
[39,37,29,17],
[39,37,29,18],
[39,37,29,19],
[39,37,29,20],
[39,37,29,23],
[39,37,29,24],
[39,37,29,25],
[39,37,29,26],
[39,37,29,27],
[39,37,29,28],
[39,37,30,0],
[39,37,30,1],
[39,37,30,2],
[39,37,30,7],
[39,37,30,9],
[39,37,30,10],
[39,37,30,11],
[39,37,30,12],
[39,37,30,13],
[39,37,30,14],
[39,37,30,15],
[39,37,30,16],
[39,37,30,17],
[39,37,30,18],
[39,37,30,19],
[39,37,30,20],
[39,37,30,22],
[39,37,30,24],
[39,37,30,25],
[39,37,30,26],
[39,37,30,27],
[39,37,30,28],
[39,37,30,29],
[39,37,31,0],
[39,37,31,1],
[39,37,31,2],
[39,37,31,7],
[39,37,31,9],
[39,37,31,10],
[39,37,31,11],
[39,37,31,12],
[39,37,31,13],
[39,37,31,14],
[39,37,31,15],
[39,37,31,16],
[39,37,31,17],
[39,37,31,18],
[39,37,31,19],
[39,37,31,22],
[39,37,31,24],
[39,37,31,25],
[39,37,31,26],
[39,37,31,27],
[39,37,31,28],
[39,37,31,29],
[39,37,32,0],
[39,37,32,1],
[39,37,32,2],
[39,37,32,7],
[39,37,32,9],
[39,37,32,10],
[39,37,32,13],
[39,37,32,14],
[39,37,32,15],
[39,37,32,16],
[39,37,32,17],
[39,37,32,18],
[39,37,32,20],
[39,37,32,22],
[39,37,32,23],
[39,37,32,24],
[39,37,32,25],
[39,37,32,26],
[39,37,32,27],
[39,37,32,28],
[39,37,32,30],
[39,37,32,31],
[39,37,33,0],
[39,37,33,1],
[39,37,33,2],
[39,37,33,7],
[39,37,33,9],
[39,37,33,10],
[39,37,33,13],
[39,37,33,14],
[39,37,33,15],
[39,37,33,16],
[39,37,33,17],
[39,37,33,19],
[39,37,33,20],
[39,37,33,22],
[39,37,33,23],
[39,37,33,24],
[39,37,33,25],
[39,37,33,26],
[39,37,33,27],
[39,37,33,28],
[39,37,33,30],
[39,37,34,0],
[39,37,34,1],
[39,37,34,2],
[39,37,34,9],
[39,37,34,10],
[39,37,34,11],
[39,37,34,12],
[39,37,34,13],
[39,37,34,14],
[39,37,34,15],
[39,37,34,18],
[39,37,34,19],
[39,37,34,20],
[39,37,34,22],
[39,37,34,23],
[39,37,34,24],
[39,37,34,26],
[39,37,34,27],
[39,37,34,29],
[39,37,35,0],
[39,37,35,1],
[39,37,35,2],
[39,37,35,7],
[39,37,35,10],
[39,37,35,11],
[39,37,35,12],
[39,37,35,13],
[39,37,35,14],
[39,37,35,16],
[39,37,35,17],
[39,37,35,19],
[39,37,35,20],
[39,37,35,22],
[39,37,35,23],
[39,37,35,24],
[39,37,35,25],
[39,37,35,27],
[39,37,35,28],
[39,37,36,0],
[39,37,36,1],
[39,37,36,2],
[39,37,36,7],
[39,37,36,9],
[39,37,36,10],
[39,37,36,11],
[39,37,36,12],
[39,37,36,15],
[39,37,36,16],
[39,37,36,17],
[39,37,36,18],
[39,37,36,20],
[39,37,36,22],
[39,37,36,23],
[39,37,36,24],
[39,37,36,25],
[39,37,36,26],
[39,37,36,27],
[39,38,4,0],
[39,38,4,1],
[39,38,4,2],
[39,38,9,0],
[39,38,9,1],
[39,38,9,2],
[39,38,9,4],
[39,38,10,0],
[39,38,10,1],
[39,38,10,2],
[39,38,10,4],
[39,38,10,9],
[39,38,11,0],
[39,38,11,1],
[39,38,11,2],
[39,38,11,4],
[39,38,11,9],
[39,38,11,10],
[39,38,12,0],
[39,38,12,1],
[39,38,12,2],
[39,38,12,4],
[39,38,12,9],
[39,38,12,10],
[39,38,13,0],
[39,38,13,1],
[39,38,13,2],
[39,38,13,4],
[39,38,13,9],
[39,38,13,10],
[39,38,13,11],
[39,38,13,12],
[39,38,14,0],
[39,38,14,1],
[39,38,14,2],
[39,38,14,4],
[39,38,14,9],
[39,38,14,10],
[39,38,14,11],
[39,38,14,12],
[39,38,15,0],
[39,38,15,1],
[39,38,15,2],
[39,38,15,4],
[39,38,15,10],
[39,38,15,11],
[39,38,15,12],
[39,38,15,13],
[39,38,15,14],
[39,38,16,0],
[39,38,16,1],
[39,38,16,2],
[39,38,16,4],
[39,38,16,9],
[39,38,16,10],
[39,38,16,11],
[39,38,16,12],
[39,38,16,13],
[39,38,16,14],
[39,38,16,15],
[39,38,17,0],
[39,38,17,1],
[39,38,17,2],
[39,38,17,4],
[39,38,17,9],
[39,38,17,10],
[39,38,17,11],
[39,38,17,12],
[39,38,17,13],
[39,38,17,14],
[39,38,17,15],
[39,38,17,16],
[39,38,18,0],
[39,38,18,1],
[39,38,18,2],
[39,38,18,4],
[39,38,18,10],
[39,38,18,11],
[39,38,18,12],
[39,38,18,13],
[39,38,18,14],
[39,38,18,16],
[39,38,18,17],
[39,38,19,0],
[39,38,19,1],
[39,38,19,2],
[39,38,19,4],
[39,38,19,9],
[39,38,19,10],
[39,38,19,11],
[39,38,19,12],
[39,38,19,15],
[39,38,19,16],
[39,38,19,17],
[39,38,19,18],
[39,38,20,0],
[39,38,20,1],
[39,38,20,2],
[39,38,20,4],
[39,38,20,9],
[39,38,20,11],
[39,38,20,12],
[39,38,20,13],
[39,38,20,14],
[39,38,20,15],
[39,38,20,16],
[39,38,20,17],
[39,38,20,18],
[39,38,20,19],
[39,38,22,0],
[39,38,22,1],
[39,38,22,2],
[39,38,22,4],
[39,38,22,9],
[39,38,22,11],
[39,38,22,12],
[39,38,22,13],
[39,38,22,14],
[39,38,22,15],
[39,38,22,16],
[39,38,22,17],
[39,38,22,18],
[39,38,22,19],
[39,38,23,0],
[39,38,23,1],
[39,38,23,2],
[39,38,23,4],
[39,38,23,9],
[39,38,23,10],
[39,38,23,11],
[39,38,23,12],
[39,38,23,13],
[39,38,23,14],
[39,38,23,15],
[39,38,23,16],
[39,38,23,17],
[39,38,23,18],
[39,38,23,19],
[39,38,23,20],
[39,38,23,22],
[39,38,24,0],
[39,38,24,1],
[39,38,24,2],
[39,38,24,4],
[39,38,24,9],
[39,38,24,10],
[39,38,24,11],
[39,38,24,12],
[39,38,24,13],
[39,38,24,14],
[39,38,24,15],
[39,38,24,17],
[39,38,24,18],
[39,38,24,19],
[39,38,24,20],
[39,38,24,22],
[39,38,24,23],
[39,38,26,0],
[39,38,26,1],
[39,38,26,2],
[39,38,26,4],
[39,38,26,10],
[39,38,26,11],
[39,38,26,12],
[39,38,26,13],
[39,38,26,14],
[39,38,26,16],
[39,38,26,17],
[39,38,26,19],
[39,38,26,20],
[39,38,26,22],
[39,38,26,23],
[39,38,26,24],
[39,38,27,0],
[39,38,27,1],
[39,38,27,2],
[39,38,27,4],
[39,38,27,9],
[39,38,27,11],
[39,38,27,12],
[39,38,27,13],
[39,38,27,14],
[39,38,27,15],
[39,38,27,16],
[39,38,27,17],
[39,38,27,18],
[39,38,27,19],
[39,38,27,26],
[39,38,29,0],
[39,38,29,1],
[39,38,29,2],
[39,38,29,4],
[39,38,29,9],
[39,38,29,10],
[39,38,29,13],
[39,38,29,14],
[39,38,29,15],
[39,38,29,16],
[39,38,29,17],
[39,38,29,18],
[39,38,29,19],
[39,38,29,20],
[39,38,29,22],
[39,38,29,23],
[39,38,29,24],
[39,38,29,26],
[39,38,29,27],
[39,38,30,0],
[39,38,30,1],
[39,38,30,2],
[39,38,30,4],
[39,38,30,9],
[39,38,30,10],
[39,38,30,11],
[39,38,30,12],
[39,38,30,13],
[39,38,30,14],
[39,38,30,15],
[39,38,30,16],
[39,38,30,17],
[39,38,30,18],
[39,38,30,19],
[39,38,30,22],
[39,38,30,24],
[39,38,30,26],
[39,38,30,27],
[39,38,30,29],
[39,38,31,0],
[39,38,31,1],
[39,38,31,2],
[39,38,31,4],
[39,38,31,9],
[39,38,31,10],
[39,38,31,11],
[39,38,31,12],
[39,38,31,13],
[39,38,31,14],
[39,38,31,15],
[39,38,31,16],
[39,38,31,17],
[39,38,31,18],
[39,38,31,20],
[39,38,31,22],
[39,38,31,24],
[39,38,31,26],
[39,38,31,27],
[39,38,31,29],
[39,38,32,0],
[39,38,32,1],
[39,38,32,2],
[39,38,32,4],
[39,38,32,9],
[39,38,32,10],
[39,38,32,13],
[39,38,32,14],
[39,38,32,15],
[39,38,32,16],
[39,38,32,17],
[39,38,32,19],
[39,38,32,20],
[39,38,32,22],
[39,38,32,23],
[39,38,32,24],
[39,38,32,26],
[39,38,32,27],
[39,38,32,30],
[39,38,33,0],
[39,38,33,1],
[39,38,33,2],
[39,38,33,4],
[39,38,33,9],
[39,38,33,10],
[39,38,33,13],
[39,38,33,14],
[39,38,33,15],
[39,38,33,18],
[39,38,33,19],
[39,38,33,20],
[39,38,33,22],
[39,38,33,23],
[39,38,33,24],
[39,38,33,26],
[39,38,33,27],
[39,38,35,0],
[39,38,35,1],
[39,38,35,2],
[39,38,35,4],
[39,38,35,10],
[39,38,35,11],
[39,38,35,12],
[39,38,35,13],
[39,38,35,16],
[39,38,35,17],
[39,38,35,19],
[39,38,35,20],
[39,38,35,22],
[39,38,35,23],
[39,38,35,24],
[39,38,35,27],
[39,38,36,0],
[39,38,36,1],
[39,38,36,2],
[39,38,36,4],
[39,38,36,9],
[39,38,36,10],
[39,38,36,11],
[39,38,36,15],
[39,38,36,16],
[39,38,36,17],
[39,38,36,18],
[39,38,36,20],
[39,38,36,22],
[39,38,36,23],
[39,38,36,24],
[39,38,36,26],
[39,38,37,0],
[39,38,37,1],
[39,38,37,2],
[39,38,37,9],
[39,38,37,12],
[39,38,37,13],
[39,38,37,14],
[39,38,37,15],
[39,38,37,16],
[39,38,37,17],
[39,38,37,18],
[39,38,37,19],
[39,38,37,20],
[39,38,37,22],
[39,38,37,23],
[39,38,37,24],
[40,4,3,0],
[40,4,3,1],
[40,4,3,2],
[40,5,4,0],
[40,5,4,1],
[40,5,4,2],
[40,6,4,0],
[40,6,4,1],
[40,6,4,2],
[40,7,3,0],
[40,7,3,1],
[40,7,3,2],
[40,7,4,0],
[40,7,4,1],
[40,7,4,2],
[40,7,4,3],
[40,7,5,0],
[40,7,5,1],
[40,7,5,2],
[40,7,5,4],
[40,7,6,0],
[40,7,6,1],
[40,7,6,2],
[40,7,6,4],
[40,8,4,0],
[40,8,4,1],
[40,8,4,2],
[40,8,7,0],
[40,8,7,1],
[40,8,7,2],
[40,8,7,4],
[40,10,3,0],
[40,10,3,1],
[40,10,3,2],
[40,10,4,0],
[40,10,4,1],
[40,10,4,2],
[40,10,4,3],
[40,10,5,0],
[40,10,5,1],
[40,10,5,2],
[40,10,5,4],
[40,10,6,0],
[40,10,6,1],
[40,10,6,2],
[40,10,6,4],
[40,10,7,0],
[40,10,7,1],
[40,10,7,2],
[40,10,7,3],
[40,10,7,4],
[40,10,7,5],
[40,10,7,6],
[40,10,8,0],
[40,10,8,1],
[40,10,8,2],
[40,10,8,4],
[40,10,8,7],
[40,11,3,0],
[40,11,3,1],
[40,11,3,2],
[40,11,4,0],
[40,11,4,1],
[40,11,4,2],
[40,11,4,3],
[40,11,5,0],
[40,11,5,1],
[40,11,5,2],
[40,11,5,4],
[40,11,6,0],
[40,11,6,1],
[40,11,6,2],
[40,11,6,4],
[40,11,7,0],
[40,11,7,1],
[40,11,7,2],
[40,11,7,3],
[40,11,7,4],
[40,11,7,5],
[40,11,7,6],
[40,11,8,0],
[40,11,8,1],
[40,11,8,2],
[40,11,8,4],
[40,11,8,7],
[40,11,10,0],
[40,11,10,1],
[40,11,10,2],
[40,11,10,3],
[40,11,10,4],
[40,11,10,5],
[40,11,10,6],
[40,11,10,7],
[40,11,10,8],
[40,12,3,0],
[40,12,3,1],
[40,12,3,2],
[40,12,4,0],
[40,12,4,1],
[40,12,4,2],
[40,12,4,3],
[40,12,5,0],
[40,12,5,1],
[40,12,5,2],
[40,12,5,4],
[40,12,6,0],
[40,12,6,1],
[40,12,6,2],
[40,12,6,4],
[40,12,7,0],
[40,12,7,1],
[40,12,7,2],
[40,12,7,3],
[40,12,7,4],
[40,12,7,5],
[40,12,7,6],
[40,12,8,0],
[40,12,8,1],
[40,12,8,2],
[40,12,8,4],
[40,12,8,7],
[40,12,10,0],
[40,12,10,1],
[40,12,10,2],
[40,12,10,3],
[40,12,10,4],
[40,12,10,5],
[40,12,10,6],
[40,12,10,7],
[40,12,10,8],
[40,13,3,0],
[40,13,3,1],
[40,13,3,2],
[40,13,4,0],
[40,13,4,1],
[40,13,4,2],
[40,13,4,3],
[40,13,5,0],
[40,13,5,1],
[40,13,5,2],
[40,13,5,4],
[40,13,6,0],
[40,13,6,1],
[40,13,6,2],
[40,13,6,4],
[40,13,7,0],
[40,13,7,1],
[40,13,7,2],
[40,13,7,3],
[40,13,7,4],
[40,13,7,5],
[40,13,7,6],
[40,13,8,0],
[40,13,8,1],
[40,13,8,2],
[40,13,8,4],
[40,13,8,7],
[40,13,10,0],
[40,13,10,1],
[40,13,10,2],
[40,13,10,3],
[40,13,10,4],
[40,13,10,5],
[40,13,10,6],
[40,13,10,7],
[40,13,10,8],
[40,13,11,0],
[40,13,11,1],
[40,13,11,2],
[40,13,11,3],
[40,13,11,4],
[40,13,11,5],
[40,13,11,6],
[40,13,11,7],
[40,13,11,8],
[40,13,11,10],
[40,13,12,0],
[40,13,12,1],
[40,13,12,2],
[40,13,12,3],
[40,13,12,4],
[40,13,12,5],
[40,13,12,6],
[40,13,12,7],
[40,13,12,8],
[40,13,12,10],
[40,14,3,0],
[40,14,3,1],
[40,14,3,2],
[40,14,4,0],
[40,14,4,1],
[40,14,4,2],
[40,14,4,3],
[40,14,5,0],
[40,14,5,1],
[40,14,5,2],
[40,14,5,4],
[40,14,6,0],
[40,14,6,1],
[40,14,6,2],
[40,14,6,4],
[40,14,7,0],
[40,14,7,1],
[40,14,7,2],
[40,14,7,3],
[40,14,7,4],
[40,14,7,5],
[40,14,7,6],
[40,14,8,0],
[40,14,8,1],
[40,14,8,2],
[40,14,8,4],
[40,14,8,7],
[40,14,10,0],
[40,14,10,1],
[40,14,10,2],
[40,14,10,3],
[40,14,10,4],
[40,14,10,5],
[40,14,10,6],
[40,14,10,7],
[40,14,10,8],
[40,14,11,0],
[40,14,11,1],
[40,14,11,2],
[40,14,11,3],
[40,14,11,4],
[40,14,11,5],
[40,14,11,6],
[40,14,11,7],
[40,14,11,8],
[40,14,11,10],
[40,14,12,0],
[40,14,12,1],
[40,14,12,2],
[40,14,12,3],
[40,14,12,4],
[40,14,12,5],
[40,14,12,6],
[40,14,12,7],
[40,14,12,8],
[40,14,12,10],
[40,16,3,0],
[40,16,3,1],
[40,16,3,2],
[40,16,4,0],
[40,16,4,1],
[40,16,4,2],
[40,16,4,3],
[40,16,5,0],
[40,16,5,1],
[40,16,5,2],
[40,16,5,4],
[40,16,6,0],
[40,16,6,1],
[40,16,6,2],
[40,16,6,4],
[40,16,7,0],
[40,16,7,1],
[40,16,7,2],
[40,16,7,3],
[40,16,7,4],
[40,16,7,5],
[40,16,7,6],
[40,16,8,0],
[40,16,8,1],
[40,16,8,2],
[40,16,8,4],
[40,16,8,7],
[40,16,10,0],
[40,16,10,1],
[40,16,10,2],
[40,16,10,3],
[40,16,10,4],
[40,16,10,5],
[40,16,10,6],
[40,16,10,7],
[40,16,10,8],
[40,16,11,0],
[40,16,11,1],
[40,16,11,2],
[40,16,11,3],
[40,16,11,4],
[40,16,11,5],
[40,16,11,6],
[40,16,11,7],
[40,16,11,8],
[40,16,11,10],
[40,16,12,0],
[40,16,12,1],
[40,16,12,2],
[40,16,12,3],
[40,16,12,4],
[40,16,12,5],
[40,16,12,6],
[40,16,12,7],
[40,16,12,8],
[40,16,12,10],
[40,16,13,0],
[40,16,13,1],
[40,16,13,2],
[40,16,13,3],
[40,16,13,4],
[40,16,13,5],
[40,16,13,6],
[40,16,13,7],
[40,16,13,8],
[40,16,13,10],
[40,16,13,11],
[40,16,13,12],
[40,16,14,0],
[40,16,14,1],
[40,16,14,2],
[40,16,14,3],
[40,16,14,4],
[40,16,14,5],
[40,16,14,6],
[40,16,14,7],
[40,16,14,8],
[40,16,14,10],
[40,16,14,11],
[40,16,14,12],
[40,17,3,0],
[40,17,3,1],
[40,17,3,2],
[40,17,4,0],
[40,17,4,1],
[40,17,4,2],
[40,17,4,3],
[40,17,5,0],
[40,17,5,1],
[40,17,5,2],
[40,17,5,4],
[40,17,6,0],
[40,17,6,1],
[40,17,6,2],
[40,17,6,4],
[40,17,7,0],
[40,17,7,1],
[40,17,7,2],
[40,17,7,3],
[40,17,7,4],
[40,17,7,5],
[40,17,7,6],
[40,17,8,0],
[40,17,8,1],
[40,17,8,2],
[40,17,8,4],
[40,17,8,7],
[40,17,10,0],
[40,17,10,1],
[40,17,10,2],
[40,17,10,3],
[40,17,10,4],
[40,17,10,5],
[40,17,10,6],
[40,17,10,7],
[40,17,10,8],
[40,17,11,0],
[40,17,11,1],
[40,17,11,2],
[40,17,11,3],
[40,17,11,4],
[40,17,11,5],
[40,17,11,6],
[40,17,11,7],
[40,17,11,8],
[40,17,11,10],
[40,17,12,0],
[40,17,12,1],
[40,17,12,2],
[40,17,12,3],
[40,17,12,4],
[40,17,12,5],
[40,17,12,6],
[40,17,12,7],
[40,17,12,8],
[40,17,12,10],
[40,17,13,0],
[40,17,13,1],
[40,17,13,2],
[40,17,13,3],
[40,17,13,4],
[40,17,13,5],
[40,17,13,6],
[40,17,13,7],
[40,17,13,8],
[40,17,13,10],
[40,17,13,11],
[40,17,13,12],
[40,17,14,0],
[40,17,14,1],
[40,17,14,2],
[40,17,14,3],
[40,17,14,4],
[40,17,14,5],
[40,17,14,6],
[40,17,14,7],
[40,17,14,8],
[40,17,14,10],
[40,17,14,11],
[40,17,14,12],
[40,17,16,0],
[40,17,16,1],
[40,17,16,2],
[40,17,16,3],
[40,17,16,4],
[40,17,16,5],
[40,17,16,6],
[40,17,16,7],
[40,17,16,8],
[40,17,16,10],
[40,17,16,11],
[40,17,16,12],
[40,17,16,13],
[40,17,16,14],
[40,19,3,0],
[40,19,3,1],
[40,19,3,2],
[40,19,4,0],
[40,19,4,1],
[40,19,4,2],
[40,19,4,3],
[40,19,5,0],
[40,19,5,1],
[40,19,5,2],
[40,19,5,4],
[40,19,6,0],
[40,19,6,1],
[40,19,6,2],
[40,19,6,4],
[40,19,7,0],
[40,19,7,1],
[40,19,7,2],
[40,19,7,3],
[40,19,7,4],
[40,19,7,5],
[40,19,7,6],
[40,19,8,0],
[40,19,8,1],
[40,19,8,2],
[40,19,8,4],
[40,19,8,7],
[40,19,10,0],
[40,19,10,1],
[40,19,10,2],
[40,19,10,3],
[40,19,10,4],
[40,19,10,5],
[40,19,10,6],
[40,19,10,7],
[40,19,10,8],
[40,19,11,0],
[40,19,11,1],
[40,19,11,2],
[40,19,11,3],
[40,19,11,4],
[40,19,11,5],
[40,19,11,6],
[40,19,11,7],
[40,19,11,8],
[40,19,11,10],
[40,19,12,0],
[40,19,12,1],
[40,19,12,2],
[40,19,12,3],
[40,19,12,4],
[40,19,12,5],
[40,19,12,6],
[40,19,12,7],
[40,19,12,8],
[40,19,12,10],
[40,19,16,0],
[40,19,16,1],
[40,19,16,2],
[40,19,16,3],
[40,19,16,4],
[40,19,16,5],
[40,19,16,6],
[40,19,16,7],
[40,19,16,8],
[40,19,16,10],
[40,19,16,11],
[40,19,16,12],
[40,19,17,0],
[40,19,17,1],
[40,19,17,2],
[40,19,17,3],
[40,19,17,4],
[40,19,17,5],
[40,19,17,6],
[40,19,17,7],
[40,19,17,8],
[40,19,17,10],
[40,19,17,11],
[40,19,17,12],
[40,19,17,16],
[40,20,3,0],
[40,20,3,1],
[40,20,3,2],
[40,20,4,0],
[40,20,4,1],
[40,20,4,2],
[40,20,4,3],
[40,20,5,0],
[40,20,5,1],
[40,20,5,2],
[40,20,5,4],
[40,20,6,0],
[40,20,6,1],
[40,20,6,2],
[40,20,6,4],
[40,20,7,0],
[40,20,7,1],
[40,20,7,2],
[40,20,7,3],
[40,20,7,4],
[40,20,7,5],
[40,20,7,6],
[40,20,8,0],
[40,20,8,1],
[40,20,8,2],
[40,20,8,4],
[40,20,8,7],
[40,20,11,0],
[40,20,11,1],
[40,20,11,2],
[40,20,11,3],
[40,20,11,4],
[40,20,11,5],
[40,20,11,6],
[40,20,11,7],
[40,20,11,8],
[40,20,12,0],
[40,20,12,1],
[40,20,12,2],
[40,20,12,3],
[40,20,12,4],
[40,20,12,5],
[40,20,12,6],
[40,20,12,7],
[40,20,12,8],
[40,20,13,0],
[40,20,13,1],
[40,20,13,2],
[40,20,13,3],
[40,20,13,4],
[40,20,13,5],
[40,20,13,6],
[40,20,13,7],
[40,20,13,8],
[40,20,13,11],
[40,20,13,12],
[40,20,14,0],
[40,20,14,1],
[40,20,14,2],
[40,20,14,3],
[40,20,14,4],
[40,20,14,5],
[40,20,14,6],
[40,20,14,7],
[40,20,14,8],
[40,20,14,11],
[40,20,14,12],
[40,20,16,0],
[40,20,16,1],
[40,20,16,2],
[40,20,16,3],
[40,20,16,4],
[40,20,16,5],
[40,20,16,6],
[40,20,16,7],
[40,20,16,8],
[40,20,16,11],
[40,20,16,12],
[40,20,16,13],
[40,20,16,14],
[40,20,17,0],
[40,20,17,1],
[40,20,17,2],
[40,20,17,3],
[40,20,17,4],
[40,20,17,5],
[40,20,17,6],
[40,20,17,7],
[40,20,17,8],
[40,20,17,11],
[40,20,17,12],
[40,20,17,13],
[40,20,17,14],
[40,20,17,16],
[40,20,19,0],
[40,20,19,1],
[40,20,19,2],
[40,20,19,3],
[40,20,19,4],
[40,20,19,5],
[40,20,19,6],
[40,20,19,7],
[40,20,19,8],
[40,20,19,11],
[40,20,19,12],
[40,20,19,16],
[40,20,19,17],
[40,21,4,0],
[40,21,4,1],
[40,21,4,2],
[40,21,7,0],
[40,21,7,1],
[40,21,7,2],
[40,21,7,4],
[40,21,10,0],
[40,21,10,1],
[40,21,10,2],
[40,21,10,4],
[40,21,10,7],
[40,21,11,0],
[40,21,11,1],
[40,21,11,2],
[40,21,11,4],
[40,21,11,7],
[40,21,11,10],
[40,21,12,0],
[40,21,12,1],
[40,21,12,2],
[40,21,12,4],
[40,21,12,7],
[40,21,12,10],
[40,21,13,0],
[40,21,13,1],
[40,21,13,2],
[40,21,13,4],
[40,21,13,7],
[40,21,13,10],
[40,21,13,11],
[40,21,13,12],
[40,21,14,0],
[40,21,14,1],
[40,21,14,2],
[40,21,14,4],
[40,21,14,7],
[40,21,14,10],
[40,21,14,11],
[40,21,14,12],
[40,21,16,0],
[40,21,16,1],
[40,21,16,2],
[40,21,16,4],
[40,21,16,7],
[40,21,16,10],
[40,21,16,11],
[40,21,16,12],
[40,21,16,13],
[40,21,16,14],
[40,21,17,0],
[40,21,17,1],
[40,21,17,2],
[40,21,17,4],
[40,21,17,7],
[40,21,17,10],
[40,21,17,11],
[40,21,17,12],
[40,21,17,13],
[40,21,17,14],
[40,21,17,16],
[40,21,19,0],
[40,21,19,1],
[40,21,19,2],
[40,21,19,4],
[40,21,19,7],
[40,21,19,10],
[40,21,19,11],
[40,21,19,12],
[40,21,19,16],
[40,21,19,17],
[40,21,20,0],
[40,21,20,1],
[40,21,20,2],
[40,21,20,4],
[40,21,20,7],
[40,21,20,11],
[40,21,20,12],
[40,21,20,13],
[40,21,20,14],
[40,21,20,16],
[40,21,20,17],
[40,21,20,19],
[40,22,3,0],
[40,22,3,1],
[40,22,3,2],
[40,22,4,0],
[40,22,4,1],
[40,22,4,2],
[40,22,4,3],
[40,22,5,0],
[40,22,5,1],
[40,22,5,2],
[40,22,5,4],
[40,22,6,0],
[40,22,6,1],
[40,22,6,2],
[40,22,6,4],
[40,22,7,0],
[40,22,7,1],
[40,22,7,2],
[40,22,7,3],
[40,22,7,4],
[40,22,7,5],
[40,22,7,6],
[40,22,8,0],
[40,22,8,1],
[40,22,8,2],
[40,22,8,4],
[40,22,8,7],
[40,22,11,0],
[40,22,11,1],
[40,22,11,2],
[40,22,11,3],
[40,22,11,4],
[40,22,11,5],
[40,22,11,6],
[40,22,11,7],
[40,22,11,8],
[40,22,12,0],
[40,22,12,1],
[40,22,12,2],
[40,22,12,3],
[40,22,12,4],
[40,22,12,5],
[40,22,12,6],
[40,22,12,7],
[40,22,12,8],
[40,22,13,0],
[40,22,13,1],
[40,22,13,2],
[40,22,13,3],
[40,22,13,4],
[40,22,13,5],
[40,22,13,6],
[40,22,13,7],
[40,22,13,8],
[40,22,13,11],
[40,22,13,12],
[40,22,14,0],
[40,22,14,1],
[40,22,14,2],
[40,22,14,3],
[40,22,14,4],
[40,22,14,5],
[40,22,14,6],
[40,22,14,7],
[40,22,14,8],
[40,22,14,11],
[40,22,14,12],
[40,22,16,0],
[40,22,16,1],
[40,22,16,2],
[40,22,16,3],
[40,22,16,4],
[40,22,16,5],
[40,22,16,6],
[40,22,16,7],
[40,22,16,8],
[40,22,16,11],
[40,22,16,12],
[40,22,16,13],
[40,22,16,14],
[40,22,17,0],
[40,22,17,1],
[40,22,17,2],
[40,22,17,3],
[40,22,17,4],
[40,22,17,5],
[40,22,17,6],
[40,22,17,7],
[40,22,17,8],
[40,22,17,11],
[40,22,17,12],
[40,22,17,13],
[40,22,17,14],
[40,22,17,16],
[40,22,19,0],
[40,22,19,1],
[40,22,19,2],
[40,22,19,3],
[40,22,19,4],
[40,22,19,5],
[40,22,19,6],
[40,22,19,7],
[40,22,19,8],
[40,22,19,11],
[40,22,19,12],
[40,22,19,16],
[40,22,19,17],
[40,22,21,0],
[40,22,21,1],
[40,22,21,2],
[40,22,21,4],
[40,22,21,7],
[40,22,21,11],
[40,22,21,12],
[40,22,21,13],
[40,22,21,14],
[40,22,21,16],
[40,22,21,17],
[40,22,21,19],
[40,23,3,0],
[40,23,3,1],
[40,23,3,2],
[40,23,4,0],
[40,23,4,1],
[40,23,4,2],
[40,23,4,3],
[40,23,5,0],
[40,23,5,1],
[40,23,5,2],
[40,23,5,4],
[40,23,6,0],
[40,23,6,1],
[40,23,6,2],
[40,23,6,4],
[40,23,7,0],
[40,23,7,1],
[40,23,7,2],
[40,23,7,3],
[40,23,7,4],
[40,23,7,5],
[40,23,7,6],
[40,23,8,0],
[40,23,8,1],
[40,23,8,2],
[40,23,8,4],
[40,23,8,7],
[40,23,10,0],
[40,23,10,1],
[40,23,10,2],
[40,23,10,3],
[40,23,10,4],
[40,23,10,5],
[40,23,10,6],
[40,23,10,7],
[40,23,10,8],
[40,23,11,0],
[40,23,11,1],
[40,23,11,2],
[40,23,11,3],
[40,23,11,4],
[40,23,11,5],
[40,23,11,6],
[40,23,11,7],
[40,23,11,8],
[40,23,11,10],
[40,23,12,0],
[40,23,12,1],
[40,23,12,2],
[40,23,12,3],
[40,23,12,4],
[40,23,12,5],
[40,23,12,6],
[40,23,12,7],
[40,23,12,8],
[40,23,12,10],
[40,23,13,0],
[40,23,13,1],
[40,23,13,2],
[40,23,13,3],
[40,23,13,4],
[40,23,13,5],
[40,23,13,6],
[40,23,13,7],
[40,23,13,8],
[40,23,13,10],
[40,23,13,11],
[40,23,13,12],
[40,23,14,0],
[40,23,14,1],
[40,23,14,2],
[40,23,14,3],
[40,23,14,4],
[40,23,14,5],
[40,23,14,6],
[40,23,14,7],
[40,23,14,8],
[40,23,14,10],
[40,23,14,11],
[40,23,14,12],
[40,23,16,0],
[40,23,16,1],
[40,23,16,2],
[40,23,16,3],
[40,23,16,4],
[40,23,16,5],
[40,23,16,6],
[40,23,16,7],
[40,23,16,8],
[40,23,16,10],
[40,23,16,11],
[40,23,16,12],
[40,23,16,13],
[40,23,16,14],
[40,23,17,0],
[40,23,17,1],
[40,23,17,2],
[40,23,17,3],
[40,23,17,4],
[40,23,17,5],
[40,23,17,6],
[40,23,17,7],
[40,23,17,8],
[40,23,17,10],
[40,23,17,11],
[40,23,17,12],
[40,23,17,13],
[40,23,17,14],
[40,23,17,16],
[40,23,19,0],
[40,23,19,1],
[40,23,19,2],
[40,23,19,3],
[40,23,19,4],
[40,23,19,5],
[40,23,19,6],
[40,23,19,7],
[40,23,19,8],
[40,23,19,10],
[40,23,19,11],
[40,23,19,12],
[40,23,19,16],
[40,23,19,17],
[40,23,20,0],
[40,23,20,1],
[40,23,20,2],
[40,23,20,3],
[40,23,20,4],
[40,23,20,5],
[40,23,20,6],
[40,23,20,7],
[40,23,20,8],
[40,23,20,11],
[40,23,20,12],
[40,23,20,13],
[40,23,20,14],
[40,23,20,16],
[40,23,20,17],
[40,23,20,19],
[40,23,21,0],
[40,23,21,1],
[40,23,21,2],
[40,23,21,4],
[40,23,21,7],
[40,23,21,10],
[40,23,21,11],
[40,23,21,12],
[40,23,21,13],
[40,23,21,14],
[40,23,21,16],
[40,23,21,17],
[40,23,21,19],
[40,23,21,20],
[40,23,22,0],
[40,23,22,1],
[40,23,22,2],
[40,23,22,3],
[40,23,22,4],
[40,23,22,5],
[40,23,22,6],
[40,23,22,7],
[40,23,22,8],
[40,23,22,11],
[40,23,22,12],
[40,23,22,13],
[40,23,22,14],
[40,23,22,16],
[40,23,22,17],
[40,23,22,19],
[40,23,22,21],
[40,24,3,0],
[40,24,3,1],
[40,24,3,2],
[40,24,4,0],
[40,24,4,1],
[40,24,4,2],
[40,24,4,3],
[40,24,5,0],
[40,24,5,1],
[40,24,5,2],
[40,24,5,4],
[40,24,6,0],
[40,24,6,1],
[40,24,6,2],
[40,24,6,4],
[40,24,7,0],
[40,24,7,1],
[40,24,7,2],
[40,24,7,3],
[40,24,7,4],
[40,24,7,5],
[40,24,7,6],
[40,24,8,0],
[40,24,8,1],
[40,24,8,2],
[40,24,8,4],
[40,24,8,7],
[40,24,10,0],
[40,24,10,1],
[40,24,10,2],
[40,24,10,3],
[40,24,10,4],
[40,24,10,5],
[40,24,10,6],
[40,24,10,7],
[40,24,10,8],
[40,24,11,0],
[40,24,11,1],
[40,24,11,2],
[40,24,11,3],
[40,24,11,4],
[40,24,11,5],
[40,24,11,6],
[40,24,11,7],
[40,24,11,8],
[40,24,11,10],
[40,24,12,0],
[40,24,12,1],
[40,24,12,2],
[40,24,12,3],
[40,24,12,4],
[40,24,12,5],
[40,24,12,6],
[40,24,12,7],
[40,24,12,8],
[40,24,12,10],
[40,24,13,0],
[40,24,13,1],
[40,24,13,2],
[40,24,13,3],
[40,24,13,4],
[40,24,13,5],
[40,24,13,6],
[40,24,13,7],
[40,24,13,8],
[40,24,13,10],
[40,24,13,11],
[40,24,13,12],
[40,24,14,0],
[40,24,14,1],
[40,24,14,2],
[40,24,14,3],
[40,24,14,4],
[40,24,14,5],
[40,24,14,6],
[40,24,14,7],
[40,24,14,8],
[40,24,14,10],
[40,24,14,11],
[40,24,14,12],
[40,24,17,0],
[40,24,17,1],
[40,24,17,2],
[40,24,17,3],
[40,24,17,4],
[40,24,17,5],
[40,24,17,6],
[40,24,17,7],
[40,24,17,8],
[40,24,17,10],
[40,24,17,11],
[40,24,17,12],
[40,24,17,13],
[40,24,17,14],
[40,24,19,0],
[40,24,19,1],
[40,24,19,2],
[40,24,19,3],
[40,24,19,4],
[40,24,19,5],
[40,24,19,6],
[40,24,19,7],
[40,24,19,8],
[40,24,19,10],
[40,24,19,11],
[40,24,19,12],
[40,24,19,17],
[40,24,20,0],
[40,24,20,1],
[40,24,20,2],
[40,24,20,3],
[40,24,20,4],
[40,24,20,5],
[40,24,20,6],
[40,24,20,7],
[40,24,20,8],
[40,24,20,11],
[40,24,20,12],
[40,24,20,13],
[40,24,20,14],
[40,24,20,17],
[40,24,20,19],
[40,24,21,0],
[40,24,21,1],
[40,24,21,2],
[40,24,21,4],
[40,24,21,7],
[40,24,21,10],
[40,24,21,11],
[40,24,21,12],
[40,24,21,13],
[40,24,21,14],
[40,24,21,17],
[40,24,21,19],
[40,24,21,20],
[40,24,22,0],
[40,24,22,1],
[40,24,22,2],
[40,24,22,3],
[40,24,22,4],
[40,24,22,5],
[40,24,22,6],
[40,24,22,7],
[40,24,22,8],
[40,24,22,11],
[40,24,22,12],
[40,24,22,13],
[40,24,22,14],
[40,24,22,17],
[40,24,22,19],
[40,24,22,21],
[40,24,23,0],
[40,24,23,1],
[40,24,23,2],
[40,24,23,3],
[40,24,23,4],
[40,24,23,5],
[40,24,23,6],
[40,24,23,7],
[40,24,23,8],
[40,24,23,10],
[40,24,23,11],
[40,24,23,12],
[40,24,23,13],
[40,24,23,14],
[40,24,23,17],
[40,24,23,19],
[40,24,23,20],
[40,24,23,21],
[40,24,23,22],
[40,25,3,0],
[40,25,3,1],
[40,25,3,2],
[40,25,4,0],
[40,25,4,1],
[40,25,4,2],
[40,25,4,3],
[40,25,5,0],
[40,25,5,1],
[40,25,5,2],
[40,25,5,4],
[40,25,6,0],
[40,25,6,1],
[40,25,6,2],
[40,25,6,4],
[40,25,8,0],
[40,25,8,1],
[40,25,8,2],
[40,25,8,4],
[40,25,10,0],
[40,25,10,1],
[40,25,10,2],
[40,25,10,3],
[40,25,10,4],
[40,25,10,5],
[40,25,10,6],
[40,25,10,8],
[40,25,11,0],
[40,25,11,1],
[40,25,11,2],
[40,25,11,3],
[40,25,11,4],
[40,25,11,5],
[40,25,11,6],
[40,25,11,8],
[40,25,11,10],
[40,25,12,0],
[40,25,12,1],
[40,25,12,2],
[40,25,12,3],
[40,25,12,4],
[40,25,12,5],
[40,25,12,6],
[40,25,12,8],
[40,25,12,10],
[40,25,13,0],
[40,25,13,1],
[40,25,13,2],
[40,25,13,3],
[40,25,13,4],
[40,25,13,5],
[40,25,13,6],
[40,25,13,8],
[40,25,13,10],
[40,25,13,11],
[40,25,13,12],
[40,25,14,0],
[40,25,14,1],
[40,25,14,2],
[40,25,14,3],
[40,25,14,4],
[40,25,14,5],
[40,25,14,6],
[40,25,14,8],
[40,25,14,10],
[40,25,14,11],
[40,25,14,12],
[40,25,16,0],
[40,25,16,1],
[40,25,16,2],
[40,25,16,3],
[40,25,16,4],
[40,25,16,5],
[40,25,16,6],
[40,25,16,8],
[40,25,16,10],
[40,25,16,11],
[40,25,16,12],
[40,25,16,13],
[40,25,16,14],
[40,25,17,0],
[40,25,17,1],
[40,25,17,2],
[40,25,17,3],
[40,25,17,4],
[40,25,17,5],
[40,25,17,6],
[40,25,17,8],
[40,25,17,10],
[40,25,17,11],
[40,25,17,12],
[40,25,17,13],
[40,25,17,14],
[40,25,17,16],
[40,25,19,0],
[40,25,19,1],
[40,25,19,2],
[40,25,19,3],
[40,25,19,4],
[40,25,19,5],
[40,25,19,6],
[40,25,19,8],
[40,25,19,10],
[40,25,19,11],
[40,25,19,12],
[40,25,19,16],
[40,25,19,17],
[40,25,20,0],
[40,25,20,1],
[40,25,20,2],
[40,25,20,3],
[40,25,20,4],
[40,25,20,5],
[40,25,20,6],
[40,25,20,8],
[40,25,20,11],
[40,25,20,12],
[40,25,20,13],
[40,25,20,14],
[40,25,20,16],
[40,25,20,17],
[40,25,20,19],
[40,25,21,0],
[40,25,21,1],
[40,25,21,2],
[40,25,21,4],
[40,25,21,10],
[40,25,21,11],
[40,25,21,12],
[40,25,21,13],
[40,25,21,14],
[40,25,21,16],
[40,25,21,17],
[40,25,21,19],
[40,25,21,20],
[40,25,22,0],
[40,25,22,1],
[40,25,22,2],
[40,25,22,3],
[40,25,22,4],
[40,25,22,5],
[40,25,22,6],
[40,25,22,8],
[40,25,22,11],
[40,25,22,12],
[40,25,22,13],
[40,25,22,14],
[40,25,22,16],
[40,25,22,17],
[40,25,22,19],
[40,25,22,21],
[40,25,23,0],
[40,25,23,1],
[40,25,23,2],
[40,25,23,3],
[40,25,23,4],
[40,25,23,5],
[40,25,23,6],
[40,25,23,8],
[40,25,23,10],
[40,25,23,11],
[40,25,23,12],
[40,25,23,13],
[40,25,23,14],
[40,25,23,16],
[40,25,23,17],
[40,25,23,19],
[40,25,23,20],
[40,25,23,21],
[40,25,23,22],
[40,25,24,0],
[40,25,24,1],
[40,25,24,2],
[40,25,24,3],
[40,25,24,4],
[40,25,24,5],
[40,25,24,6],
[40,25,24,8],
[40,25,24,10],
[40,25,24,11],
[40,25,24,12],
[40,25,24,13],
[40,25,24,14],
[40,25,24,17],
[40,25,24,19],
[40,25,24,20],
[40,25,24,21],
[40,25,24,22],
[40,25,24,23],
[40,27,3,0],
[40,27,3,1],
[40,27,3,2],
[40,27,4,0],
[40,27,4,1],
[40,27,4,2],
[40,27,4,3],
[40,27,5,0],
[40,27,5,1],
[40,27,5,2],
[40,27,5,4],
[40,27,6,0],
[40,27,6,1],
[40,27,6,2],
[40,27,6,4],
[40,27,7,0],
[40,27,7,1],
[40,27,7,2],
[40,27,7,3],
[40,27,7,4],
[40,27,7,5],
[40,27,7,6],
[40,27,8,0],
[40,27,8,1],
[40,27,8,2],
[40,27,8,4],
[40,27,8,7],
[40,27,11,0],
[40,27,11,1],
[40,27,11,2],
[40,27,11,3],
[40,27,11,4],
[40,27,11,5],
[40,27,11,6],
[40,27,11,7],
[40,27,11,8],
[40,27,12,0],
[40,27,12,1],
[40,27,12,2],
[40,27,12,3],
[40,27,12,4],
[40,27,12,5],
[40,27,12,6],
[40,27,12,7],
[40,27,12,8],
[40,27,13,0],
[40,27,13,1],
[40,27,13,2],
[40,27,13,3],
[40,27,13,4],
[40,27,13,5],
[40,27,13,6],
[40,27,13,7],
[40,27,13,8],
[40,27,13,11],
[40,27,13,12],
[40,27,14,0],
[40,27,14,1],
[40,27,14,2],
[40,27,14,3],
[40,27,14,4],
[40,27,14,5],
[40,27,14,6],
[40,27,14,7],
[40,27,14,8],
[40,27,14,11],
[40,27,14,12],
[40,27,16,0],
[40,27,16,1],
[40,27,16,2],
[40,27,16,3],
[40,27,16,4],
[40,27,16,5],
[40,27,16,6],
[40,27,16,7],
[40,27,16,8],
[40,27,16,11],
[40,27,16,12],
[40,27,16,13],
[40,27,16,14],
[40,27,17,0],
[40,27,17,1],
[40,27,17,2],
[40,27,17,3],
[40,27,17,4],
[40,27,17,5],
[40,27,17,6],
[40,27,17,7],
[40,27,17,8],
[40,27,17,11],
[40,27,17,12],
[40,27,17,13],
[40,27,17,14],
[40,27,17,16],
[40,27,19,0],
[40,27,19,1],
[40,27,19,2],
[40,27,19,3],
[40,27,19,4],
[40,27,19,5],
[40,27,19,6],
[40,27,19,7],
[40,27,19,8],
[40,27,19,11],
[40,27,19,12],
[40,27,19,16],
[40,27,19,17],
[40,27,21,0],
[40,27,21,1],
[40,27,21,2],
[40,27,21,4],
[40,27,21,7],
[40,27,21,11],
[40,27,21,12],
[40,27,21,13],
[40,27,21,14],
[40,27,21,16],
[40,27,21,17],
[40,27,21,19],
[40,27,23,0],
[40,27,23,1],
[40,27,23,2],
[40,27,23,3],
[40,27,23,4],
[40,27,23,5],
[40,27,23,6],
[40,27,23,7],
[40,27,23,8],
[40,27,23,11],
[40,27,23,12],
[40,27,23,13],
[40,27,23,14],
[40,27,23,16],
[40,27,23,17],
[40,27,23,19],
[40,27,23,21],
[40,27,24,0],
[40,27,24,1],
[40,27,24,2],
[40,27,24,3],
[40,27,24,4],
[40,27,24,5],
[40,27,24,6],
[40,27,24,7],
[40,27,24,8],
[40,27,24,11],
[40,27,24,12],
[40,27,24,13],
[40,27,24,14],
[40,27,24,17],
[40,27,24,19],
[40,27,24,21],
[40,27,24,23],
[40,27,25,0],
[40,27,25,1],
[40,27,25,2],
[40,27,25,3],
[40,27,25,4],
[40,27,25,5],
[40,27,25,6],
[40,27,25,8],
[40,27,25,11],
[40,27,25,12],
[40,27,25,13],
[40,27,25,14],
[40,27,25,16],
[40,27,25,17],
[40,27,25,19],
[40,27,25,21],
[40,27,25,23],
[40,27,25,24],
[40,28,3,0],
[40,28,3,1],
[40,28,3,2],
[40,28,4,0],
[40,28,4,1],
[40,28,4,2],
[40,28,4,3],
[40,28,5,0],
[40,28,5,1],
[40,28,5,2],
[40,28,5,4],
[40,28,6,0],
[40,28,6,1],
[40,28,6,2],
[40,28,6,4],
[40,28,8,0],
[40,28,8,1],
[40,28,8,2],
[40,28,8,4],
[40,28,10,0],
[40,28,10,1],
[40,28,10,2],
[40,28,10,3],
[40,28,10,4],
[40,28,10,5],
[40,28,10,6],
[40,28,10,8],
[40,28,11,0],
[40,28,11,1],
[40,28,11,2],
[40,28,11,3],
[40,28,11,4],
[40,28,11,5],
[40,28,11,6],
[40,28,11,8],
[40,28,11,10],
[40,28,12,0],
[40,28,12,1],
[40,28,12,2],
[40,28,12,3],
[40,28,12,4],
[40,28,12,5],
[40,28,12,6],
[40,28,12,8],
[40,28,12,10],
[40,28,13,0],
[40,28,13,1],
[40,28,13,2],
[40,28,13,3],
[40,28,13,4],
[40,28,13,5],
[40,28,13,6],
[40,28,13,8],
[40,28,13,10],
[40,28,13,11],
[40,28,13,12],
[40,28,14,0],
[40,28,14,1],
[40,28,14,2],
[40,28,14,3],
[40,28,14,4],
[40,28,14,5],
[40,28,14,6],
[40,28,14,8],
[40,28,14,10],
[40,28,14,11],
[40,28,14,12],
[40,28,16,0],
[40,28,16,1],
[40,28,16,2],
[40,28,16,3],
[40,28,16,4],
[40,28,16,5],
[40,28,16,6],
[40,28,16,8],
[40,28,16,10],
[40,28,16,11],
[40,28,16,12],
[40,28,16,13],
[40,28,16,14],
[40,28,17,0],
[40,28,17,1],
[40,28,17,2],
[40,28,17,3],
[40,28,17,4],
[40,28,17,5],
[40,28,17,6],
[40,28,17,8],
[40,28,17,10],
[40,28,17,11],
[40,28,17,12],
[40,28,17,13],
[40,28,17,14],
[40,28,17,16],
[40,28,19,0],
[40,28,19,1],
[40,28,19,2],
[40,28,19,3],
[40,28,19,4],
[40,28,19,5],
[40,28,19,6],
[40,28,19,8],
[40,28,19,10],
[40,28,19,11],
[40,28,19,12],
[40,28,19,16],
[40,28,19,17],
[40,28,20,0],
[40,28,20,1],
[40,28,20,2],
[40,28,20,3],
[40,28,20,4],
[40,28,20,5],
[40,28,20,6],
[40,28,20,8],
[40,28,20,11],
[40,28,20,12],
[40,28,20,13],
[40,28,20,14],
[40,28,20,16],
[40,28,20,17],
[40,28,20,19],
[40,28,21,0],
[40,28,21,1],
[40,28,21,2],
[40,28,21,4],
[40,28,21,10],
[40,28,21,11],
[40,28,21,12],
[40,28,21,13],
[40,28,21,14],
[40,28,21,16],
[40,28,21,17],
[40,28,21,19],
[40,28,21,20],
[40,28,22,0],
[40,28,22,1],
[40,28,22,2],
[40,28,22,3],
[40,28,22,4],
[40,28,22,5],
[40,28,22,6],
[40,28,22,8],
[40,28,22,11],
[40,28,22,12],
[40,28,22,13],
[40,28,22,14],
[40,28,22,16],
[40,28,22,17],
[40,28,22,19],
[40,28,22,21],
[40,28,23,0],
[40,28,23,1],
[40,28,23,2],
[40,28,23,3],
[40,28,23,4],
[40,28,23,5],
[40,28,23,6],
[40,28,23,8],
[40,28,23,10],
[40,28,23,11],
[40,28,23,12],
[40,28,23,13],
[40,28,23,14],
[40,28,23,16],
[40,28,23,17],
[40,28,23,19],
[40,28,23,20],
[40,28,23,21],
[40,28,23,22],
[40,28,24,0],
[40,28,24,1],
[40,28,24,2],
[40,28,24,3],
[40,28,24,4],
[40,28,24,5],
[40,28,24,6],
[40,28,24,8],
[40,28,24,10],
[40,28,24,11],
[40,28,24,12],
[40,28,24,13],
[40,28,24,14],
[40,28,24,17],
[40,28,24,19],
[40,28,24,20],
[40,28,24,21],
[40,28,24,22],
[40,28,24,23],
[40,28,27,0],
[40,28,27,1],
[40,28,27,2],
[40,28,27,3],
[40,28,27,4],
[40,28,27,5],
[40,28,27,6],
[40,28,27,8],
[40,28,27,11],
[40,28,27,12],
[40,28,27,13],
[40,28,27,14],
[40,28,27,16],
[40,28,27,17],
[40,28,27,19],
[40,28,27,21],
[40,28,27,23],
[40,28,27,24],
[40,29,3,0],
[40,29,3,1],
[40,29,3,2],
[40,29,4,0],
[40,29,4,1],
[40,29,4,2],
[40,29,4,3],
[40,29,5,0],
[40,29,5,1],
[40,29,5,2],
[40,29,5,4],
[40,29,6,0],
[40,29,6,1],
[40,29,6,2],
[40,29,6,4],
[40,29,7,0],
[40,29,7,1],
[40,29,7,2],
[40,29,7,3],
[40,29,7,4],
[40,29,7,5],
[40,29,7,6],
[40,29,8,0],
[40,29,8,1],
[40,29,8,2],
[40,29,8,4],
[40,29,8,7],
[40,29,10,0],
[40,29,10,1],
[40,29,10,2],
[40,29,10,3],
[40,29,10,4],
[40,29,10,5],
[40,29,10,6],
[40,29,10,7],
[40,29,10,8],
[40,29,13,0],
[40,29,13,1],
[40,29,13,2],
[40,29,13,3],
[40,29,13,4],
[40,29,13,5],
[40,29,13,6],
[40,29,13,7],
[40,29,13,8],
[40,29,13,10],
[40,29,14,0],
[40,29,14,1],
[40,29,14,2],
[40,29,14,3],
[40,29,14,4],
[40,29,14,5],
[40,29,14,6],
[40,29,14,7],
[40,29,14,8],
[40,29,14,10],
[40,29,16,0],
[40,29,16,1],
[40,29,16,2],
[40,29,16,3],
[40,29,16,4],
[40,29,16,5],
[40,29,16,6],
[40,29,16,7],
[40,29,16,8],
[40,29,16,10],
[40,29,16,13],
[40,29,16,14],
[40,29,17,0],
[40,29,17,1],
[40,29,17,2],
[40,29,17,3],
[40,29,17,4],
[40,29,17,5],
[40,29,17,6],
[40,29,17,7],
[40,29,17,8],
[40,29,17,10],
[40,29,17,13],
[40,29,17,14],
[40,29,17,16],
[40,29,19,0],
[40,29,19,1],
[40,29,19,2],
[40,29,19,3],
[40,29,19,4],
[40,29,19,5],
[40,29,19,6],
[40,29,19,7],
[40,29,19,8],
[40,29,19,10],
[40,29,19,16],
[40,29,19,17],
[40,29,20,0],
[40,29,20,1],
[40,29,20,2],
[40,29,20,3],
[40,29,20,4],
[40,29,20,5],
[40,29,20,6],
[40,29,20,7],
[40,29,20,8],
[40,29,20,13],
[40,29,20,14],
[40,29,20,16],
[40,29,20,17],
[40,29,20,19],
[40,29,21,0],
[40,29,21,1],
[40,29,21,2],
[40,29,21,4],
[40,29,21,7],
[40,29,21,10],
[40,29,21,13],
[40,29,21,14],
[40,29,21,16],
[40,29,21,17],
[40,29,21,19],
[40,29,21,20],
[40,29,22,0],
[40,29,22,1],
[40,29,22,2],
[40,29,22,3],
[40,29,22,4],
[40,29,22,5],
[40,29,22,6],
[40,29,22,7],
[40,29,22,8],
[40,29,22,13],
[40,29,22,14],
[40,29,22,16],
[40,29,22,17],
[40,29,22,19],
[40,29,22,21],
[40,29,23,0],
[40,29,23,1],
[40,29,23,2],
[40,29,23,3],
[40,29,23,4],
[40,29,23,5],
[40,29,23,6],
[40,29,23,7],
[40,29,23,8],
[40,29,23,10],
[40,29,23,13],
[40,29,23,14],
[40,29,23,16],
[40,29,23,17],
[40,29,23,19],
[40,29,23,20],
[40,29,23,21],
[40,29,23,22],
[40,29,24,0],
[40,29,24,1],
[40,29,24,2],
[40,29,24,3],
[40,29,24,4],
[40,29,24,5],
[40,29,24,6],
[40,29,24,7],
[40,29,24,8],
[40,29,24,10],
[40,29,24,13],
[40,29,24,14],
[40,29,24,17],
[40,29,24,19],
[40,29,24,20],
[40,29,24,21],
[40,29,24,22],
[40,29,24,23],
[40,29,25,0],
[40,29,25,1],
[40,29,25,2],
[40,29,25,3],
[40,29,25,4],
[40,29,25,5],
[40,29,25,6],
[40,29,25,8],
[40,29,25,10],
[40,29,25,13],
[40,29,25,14],
[40,29,25,16],
[40,29,25,17],
[40,29,25,19],
[40,29,25,20],
[40,29,25,21],
[40,29,25,22],
[40,29,25,23],
[40,29,25,24],
[40,29,27,0],
[40,29,27,1],
[40,29,27,2],
[40,29,27,3],
[40,29,27,4],
[40,29,27,5],
[40,29,27,6],
[40,29,27,7],
[40,29,27,8],
[40,29,27,13],
[40,29,27,14],
[40,29,27,16],
[40,29,27,17],
[40,29,27,19],
[40,29,27,21],
[40,29,27,23],
[40,29,27,24],
[40,29,27,25],
[40,29,28,0],
[40,29,28,1],
[40,29,28,2],
[40,29,28,3],
[40,29,28,4],
[40,29,28,5],
[40,29,28,6],
[40,29,28,8],
[40,29,28,10],
[40,29,28,13],
[40,29,28,14],
[40,29,28,16],
[40,29,28,17],
[40,29,28,19],
[40,29,28,20],
[40,29,28,21],
[40,29,28,22],
[40,29,28,23],
[40,29,28,24],
[40,29,28,27],
[40,30,3,0],
[40,30,3,1],
[40,30,3,2],
[40,30,4,0],
[40,30,4,1],
[40,30,4,2],
[40,30,4,3],
[40,30,5,0],
[40,30,5,1],
[40,30,5,2],
[40,30,5,4],
[40,30,6,0],
[40,30,6,1],
[40,30,6,2],
[40,30,6,4],
[40,30,7,0],
[40,30,7,1],
[40,30,7,2],
[40,30,7,3],
[40,30,7,4],
[40,30,7,5],
[40,30,7,6],
[40,30,8,0],
[40,30,8,1],
[40,30,8,2],
[40,30,8,4],
[40,30,8,7],
[40,30,10,0],
[40,30,10,1],
[40,30,10,2],
[40,30,10,3],
[40,30,10,4],
[40,30,10,5],
[40,30,10,6],
[40,30,10,7],
[40,30,10,8],
[40,30,11,0],
[40,30,11,1],
[40,30,11,2],
[40,30,11,3],
[40,30,11,4],
[40,30,11,5],
[40,30,11,6],
[40,30,11,7],
[40,30,11,8],
[40,30,11,10],
[40,30,12,0],
[40,30,12,1],
[40,30,12,2],
[40,30,12,3],
[40,30,12,4],
[40,30,12,5],
[40,30,12,6],
[40,30,12,7],
[40,30,12,8],
[40,30,12,10],
[40,30,13,0],
[40,30,13,1],
[40,30,13,2],
[40,30,13,3],
[40,30,13,4],
[40,30,13,5],
[40,30,13,6],
[40,30,13,7],
[40,30,13,8],
[40,30,13,10],
[40,30,13,11],
[40,30,13,12],
[40,30,14,0],
[40,30,14,1],
[40,30,14,2],
[40,30,14,3],
[40,30,14,4],
[40,30,14,5],
[40,30,14,6],
[40,30,14,7],
[40,30,14,8],
[40,30,14,10],
[40,30,14,11],
[40,30,14,12],
[40,30,16,0],
[40,30,16,1],
[40,30,16,2],
[40,30,16,3],
[40,30,16,4],
[40,30,16,5],
[40,30,16,6],
[40,30,16,7],
[40,30,16,8],
[40,30,16,10],
[40,30,16,11],
[40,30,16,12],
[40,30,16,13],
[40,30,16,14],
[40,30,17,0],
[40,30,17,1],
[40,30,17,2],
[40,30,17,3],
[40,30,17,4],
[40,30,17,5],
[40,30,17,6],
[40,30,17,7],
[40,30,17,8],
[40,30,17,10],
[40,30,17,11],
[40,30,17,12],
[40,30,17,13],
[40,30,17,14],
[40,30,17,16],
[40,30,19,0],
[40,30,19,1],
[40,30,19,2],
[40,30,19,3],
[40,30,19,4],
[40,30,19,5],
[40,30,19,6],
[40,30,19,7],
[40,30,19,8],
[40,30,19,10],
[40,30,19,11],
[40,30,19,12],
[40,30,19,16],
[40,30,19,17],
[40,30,20,0],
[40,30,20,1],
[40,30,20,2],
[40,30,20,3],
[40,30,20,4],
[40,30,20,5],
[40,30,20,6],
[40,30,20,7],
[40,30,20,8],
[40,30,20,11],
[40,30,20,12],
[40,30,20,13],
[40,30,20,14],
[40,30,20,16],
[40,30,20,17],
[40,30,20,19],
[40,30,21,0],
[40,30,21,1],
[40,30,21,2],
[40,30,21,4],
[40,30,21,7],
[40,30,21,10],
[40,30,21,11],
[40,30,21,12],
[40,30,21,13],
[40,30,21,14],
[40,30,21,16],
[40,30,21,17],
[40,30,21,19],
[40,30,21,20],
[40,30,22,0],
[40,30,22,1],
[40,30,22,2],
[40,30,22,3],
[40,30,22,4],
[40,30,22,5],
[40,30,22,6],
[40,30,22,7],
[40,30,22,8],
[40,30,22,11],
[40,30,22,12],
[40,30,22,13],
[40,30,22,14],
[40,30,22,16],
[40,30,22,17],
[40,30,22,19],
[40,30,22,21],
[40,30,24,0],
[40,30,24,1],
[40,30,24,2],
[40,30,24,3],
[40,30,24,4],
[40,30,24,5],
[40,30,24,6],
[40,30,24,7],
[40,30,24,8],
[40,30,24,10],
[40,30,24,11],
[40,30,24,12],
[40,30,24,13],
[40,30,24,14],
[40,30,24,17],
[40,30,24,19],
[40,30,24,20],
[40,30,24,21],
[40,30,24,22],
[40,30,25,0],
[40,30,25,1],
[40,30,25,2],
[40,30,25,3],
[40,30,25,4],
[40,30,25,5],
[40,30,25,6],
[40,30,25,8],
[40,30,25,10],
[40,30,25,11],
[40,30,25,12],
[40,30,25,13],
[40,30,25,14],
[40,30,25,16],
[40,30,25,17],
[40,30,25,19],
[40,30,25,20],
[40,30,25,21],
[40,30,25,22],
[40,30,25,24],
[40,30,27,0],
[40,30,27,1],
[40,30,27,2],
[40,30,27,3],
[40,30,27,4],
[40,30,27,5],
[40,30,27,6],
[40,30,27,7],
[40,30,27,8],
[40,30,27,11],
[40,30,27,12],
[40,30,27,13],
[40,30,27,14],
[40,30,27,16],
[40,30,27,17],
[40,30,27,19],
[40,30,27,21],
[40,30,27,24],
[40,30,27,25],
[40,30,28,0],
[40,30,28,1],
[40,30,28,2],
[40,30,28,3],
[40,30,28,4],
[40,30,28,5],
[40,30,28,6],
[40,30,28,8],
[40,30,28,10],
[40,30,28,11],
[40,30,28,12],
[40,30,28,13],
[40,30,28,14],
[40,30,28,16],
[40,30,28,17],
[40,30,28,19],
[40,30,28,20],
[40,30,28,21],
[40,30,28,22],
[40,30,28,24],
[40,30,28,27],
[40,30,29,0],
[40,30,29,1],
[40,30,29,2],
[40,30,29,3],
[40,30,29,4],
[40,30,29,5],
[40,30,29,6],
[40,30,29,7],
[40,30,29,8],
[40,30,29,10],
[40,30,29,13],
[40,30,29,14],
[40,30,29,16],
[40,30,29,17],
[40,30,29,19],
[40,30,29,20],
[40,30,29,21],
[40,30,29,22],
[40,30,29,24],
[40,30,29,25],
[40,30,29,27],
[40,31,3,0],
[40,31,3,1],
[40,31,3,2],
[40,31,4,0],
[40,31,4,1],
[40,31,4,2],
[40,31,4,3],
[40,31,5,0],
[40,31,5,1],
[40,31,5,2],
[40,31,5,4],
[40,31,6,0],
[40,31,6,1],
[40,31,6,2],
[40,31,6,4],
[40,31,7,0],
[40,31,7,1],
[40,31,7,2],
[40,31,7,3],
[40,31,7,4],
[40,31,7,5],
[40,31,7,6],
[40,31,8,0],
[40,31,8,1],
[40,31,8,2],
[40,31,8,4],
[40,31,8,7],
[40,31,10,0],
[40,31,10,1],
[40,31,10,2],
[40,31,10,3],
[40,31,10,4],
[40,31,10,5],
[40,31,10,6],
[40,31,10,7],
[40,31,10,8],
[40,31,11,0],
[40,31,11,1],
[40,31,11,2],
[40,31,11,3],
[40,31,11,4],
[40,31,11,5],
[40,31,11,6],
[40,31,11,7],
[40,31,11,8],
[40,31,11,10],
[40,31,12,0],
[40,31,12,1],
[40,31,12,2],
[40,31,12,3],
[40,31,12,4],
[40,31,12,5],
[40,31,12,6],
[40,31,12,7],
[40,31,12,8],
[40,31,12,10],
[40,31,13,0],
[40,31,13,1],
[40,31,13,2],
[40,31,13,3],
[40,31,13,4],
[40,31,13,5],
[40,31,13,6],
[40,31,13,7],
[40,31,13,8],
[40,31,13,10],
[40,31,13,11],
[40,31,13,12],
[40,31,14,0],
[40,31,14,1],
[40,31,14,2],
[40,31,14,3],
[40,31,14,4],
[40,31,14,5],
[40,31,14,6],
[40,31,14,7],
[40,31,14,8],
[40,31,14,10],
[40,31,14,11],
[40,31,14,12],
[40,31,16,0],
[40,31,16,1],
[40,31,16,2],
[40,31,16,3],
[40,31,16,4],
[40,31,16,5],
[40,31,16,6],
[40,31,16,7],
[40,31,16,8],
[40,31,16,10],
[40,31,16,11],
[40,31,16,12],
[40,31,16,13],
[40,31,16,14],
[40,31,17,0],
[40,31,17,1],
[40,31,17,2],
[40,31,17,3],
[40,31,17,4],
[40,31,17,5],
[40,31,17,6],
[40,31,17,7],
[40,31,17,8],
[40,31,17,10],
[40,31,17,11],
[40,31,17,12],
[40,31,17,13],
[40,31,17,14],
[40,31,17,16],
[40,31,19,0],
[40,31,19,1],
[40,31,19,2],
[40,31,19,3],
[40,31,19,4],
[40,31,19,5],
[40,31,19,6],
[40,31,19,7],
[40,31,19,8],
[40,31,19,10],
[40,31,19,11],
[40,31,19,12],
[40,31,19,16],
[40,31,19,17],
[40,31,20,0],
[40,31,20,1],
[40,31,20,2],
[40,31,20,3],
[40,31,20,4],
[40,31,20,5],
[40,31,20,6],
[40,31,20,7],
[40,31,20,8],
[40,31,20,11],
[40,31,20,12],
[40,31,20,13],
[40,31,20,14],
[40,31,20,16],
[40,31,20,17],
[40,31,20,19],
[40,31,21,0],
[40,31,21,1],
[40,31,21,2],
[40,31,21,4],
[40,31,21,7],
[40,31,21,10],
[40,31,21,11],
[40,31,21,12],
[40,31,21,13],
[40,31,21,14],
[40,31,21,16],
[40,31,21,17],
[40,31,21,19],
[40,31,21,20],
[40,31,22,0],
[40,31,22,1],
[40,31,22,2],
[40,31,22,3],
[40,31,22,4],
[40,31,22,5],
[40,31,22,6],
[40,31,22,7],
[40,31,22,8],
[40,31,22,11],
[40,31,22,12],
[40,31,22,13],
[40,31,22,14],
[40,31,22,16],
[40,31,22,17],
[40,31,22,19],
[40,31,22,21],
[40,31,24,0],
[40,31,24,1],
[40,31,24,2],
[40,31,24,3],
[40,31,24,4],
[40,31,24,5],
[40,31,24,6],
[40,31,24,7],
[40,31,24,8],
[40,31,24,10],
[40,31,24,11],
[40,31,24,12],
[40,31,24,13],
[40,31,24,14],
[40,31,24,17],
[40,31,24,19],
[40,31,24,20],
[40,31,24,21],
[40,31,24,22],
[40,31,25,0],
[40,31,25,1],
[40,31,25,2],
[40,31,25,3],
[40,31,25,4],
[40,31,25,5],
[40,31,25,6],
[40,31,25,8],
[40,31,25,10],
[40,31,25,11],
[40,31,25,12],
[40,31,25,13],
[40,31,25,14],
[40,31,25,16],
[40,31,25,17],
[40,31,25,19],
[40,31,25,20],
[40,31,25,21],
[40,31,25,22],
[40,31,25,24],
[40,31,27,0],
[40,31,27,1],
[40,31,27,2],
[40,31,27,3],
[40,31,27,4],
[40,31,27,5],
[40,31,27,6],
[40,31,27,7],
[40,31,27,8],
[40,31,27,11],
[40,31,27,12],
[40,31,27,13],
[40,31,27,14],
[40,31,27,16],
[40,31,27,17],
[40,31,27,19],
[40,31,27,21],
[40,31,27,24],
[40,31,27,25],
[40,31,28,0],
[40,31,28,1],
[40,31,28,2],
[40,31,28,3],
[40,31,28,4],
[40,31,28,5],
[40,31,28,6],
[40,31,28,8],
[40,31,28,10],
[40,31,28,11],
[40,31,28,12],
[40,31,28,13],
[40,31,28,14],
[40,31,28,16],
[40,31,28,17],
[40,31,28,19],
[40,31,28,20],
[40,31,28,21],
[40,31,28,22],
[40,31,28,24],
[40,31,28,27],
[40,31,29,0],
[40,31,29,1],
[40,31,29,2],
[40,31,29,3],
[40,31,29,4],
[40,31,29,5],
[40,31,29,6],
[40,31,29,7],
[40,31,29,8],
[40,31,29,10],
[40,31,29,13],
[40,31,29,14],
[40,31,29,16],
[40,31,29,17],
[40,31,29,19],
[40,31,29,20],
[40,31,29,21],
[40,31,29,22],
[40,31,29,24],
[40,31,29,25],
[40,31,29,28],
[40,32,3,0],
[40,32,3,1],
[40,32,3,2],
[40,32,4,0],
[40,32,4,1],
[40,32,4,2],
[40,32,4,3],
[40,32,5,0],
[40,32,5,1],
[40,32,5,2],
[40,32,5,4],
[40,32,6,0],
[40,32,6,1],
[40,32,6,2],
[40,32,6,4],
[40,32,7,0],
[40,32,7,1],
[40,32,7,2],
[40,32,7,3],
[40,32,7,4],
[40,32,7,5],
[40,32,7,6],
[40,32,8,0],
[40,32,8,1],
[40,32,8,2],
[40,32,8,4],
[40,32,8,7],
[40,32,10,0],
[40,32,10,1],
[40,32,10,2],
[40,32,10,3],
[40,32,10,4],
[40,32,10,5],
[40,32,10,6],
[40,32,10,7],
[40,32,10,8],
[40,32,13,0],
[40,32,13,1],
[40,32,13,2],
[40,32,13,3],
[40,32,13,4],
[40,32,13,5],
[40,32,13,6],
[40,32,13,7],
[40,32,13,8],
[40,32,13,10],
[40,32,14,0],
[40,32,14,1],
[40,32,14,2],
[40,32,14,3],
[40,32,14,4],
[40,32,14,5],
[40,32,14,6],
[40,32,14,7],
[40,32,14,8],
[40,32,14,10],
[40,32,16,0],
[40,32,16,1],
[40,32,16,2],
[40,32,16,3],
[40,32,16,4],
[40,32,16,5],
[40,32,16,6],
[40,32,16,7],
[40,32,16,8],
[40,32,16,10],
[40,32,16,13],
[40,32,16,14],
[40,32,17,0],
[40,32,17,1],
[40,32,17,2],
[40,32,17,3],
[40,32,17,4],
[40,32,17,5],
[40,32,17,6],
[40,32,17,7],
[40,32,17,8],
[40,32,17,10],
[40,32,17,13],
[40,32,17,14],
[40,32,17,16],
[40,32,19,0],
[40,32,19,1],
[40,32,19,2],
[40,32,19,3],
[40,32,19,4],
[40,32,19,5],
[40,32,19,6],
[40,32,19,7],
[40,32,19,8],
[40,32,19,10],
[40,32,19,16],
[40,32,19,17],
[40,32,20,0],
[40,32,20,1],
[40,32,20,2],
[40,32,20,3],
[40,32,20,4],
[40,32,20,5],
[40,32,20,6],
[40,32,20,7],
[40,32,20,8],
[40,32,20,13],
[40,32,20,14],
[40,32,20,16],
[40,32,20,17],
[40,32,20,19],
[40,32,21,0],
[40,32,21,1],
[40,32,21,2],
[40,32,21,4],
[40,32,21,7],
[40,32,21,10],
[40,32,21,13],
[40,32,21,14],
[40,32,21,16],
[40,32,21,17],
[40,32,21,19],
[40,32,21,20],
[40,32,22,0],
[40,32,22,1],
[40,32,22,2],
[40,32,22,3],
[40,32,22,4],
[40,32,22,5],
[40,32,22,6],
[40,32,22,7],
[40,32,22,8],
[40,32,22,13],
[40,32,22,14],
[40,32,22,16],
[40,32,22,17],
[40,32,22,19],
[40,32,22,21],
[40,32,23,0],
[40,32,23,1],
[40,32,23,2],
[40,32,23,3],
[40,32,23,4],
[40,32,23,5],
[40,32,23,6],
[40,32,23,7],
[40,32,23,8],
[40,32,23,10],
[40,32,23,13],
[40,32,23,14],
[40,32,23,16],
[40,32,23,17],
[40,32,23,19],
[40,32,23,20],
[40,32,23,21],
[40,32,23,22],
[40,32,24,0],
[40,32,24,1],
[40,32,24,2],
[40,32,24,3],
[40,32,24,4],
[40,32,24,5],
[40,32,24,6],
[40,32,24,7],
[40,32,24,8],
[40,32,24,10],
[40,32,24,13],
[40,32,24,14],
[40,32,24,17],
[40,32,24,19],
[40,32,24,20],
[40,32,24,21],
[40,32,24,22],
[40,32,24,23],
[40,32,25,0],
[40,32,25,1],
[40,32,25,2],
[40,32,25,3],
[40,32,25,4],
[40,32,25,5],
[40,32,25,6],
[40,32,25,8],
[40,32,25,10],
[40,32,25,13],
[40,32,25,14],
[40,32,25,16],
[40,32,25,17],
[40,32,25,19],
[40,32,25,20],
[40,32,25,21],
[40,32,25,22],
[40,32,25,23],
[40,32,25,24],
[40,32,27,0],
[40,32,27,1],
[40,32,27,2],
[40,32,27,3],
[40,32,27,4],
[40,32,27,5],
[40,32,27,6],
[40,32,27,7],
[40,32,27,8],
[40,32,27,13],
[40,32,27,14],
[40,32,27,16],
[40,32,27,17],
[40,32,27,19],
[40,32,27,21],
[40,32,27,23],
[40,32,27,24],
[40,32,27,25],
[40,32,28,0],
[40,32,28,1],
[40,32,28,2],
[40,32,28,3],
[40,32,28,4],
[40,32,28,5],
[40,32,28,6],
[40,32,28,8],
[40,32,28,10],
[40,32,28,13],
[40,32,28,14],
[40,32,28,16],
[40,32,28,17],
[40,32,28,19],
[40,32,28,20],
[40,32,28,21],
[40,32,28,22],
[40,32,28,23],
[40,32,28,24],
[40,32,30,0],
[40,32,30,1],
[40,32,30,2],
[40,32,30,3],
[40,32,30,4],
[40,32,30,5],
[40,32,30,6],
[40,32,30,7],
[40,32,30,8],
[40,32,30,10],
[40,32,30,13],
[40,32,30,14],
[40,32,30,16],
[40,32,30,17],
[40,32,30,19],
[40,32,30,20],
[40,32,30,21],
[40,32,30,22],
[40,32,30,24],
[40,32,30,27],
[40,32,30,28],
[40,32,31,0],
[40,32,31,1],
[40,32,31,2],
[40,32,31,3],
[40,32,31,4],
[40,32,31,5],
[40,32,31,6],
[40,32,31,7],
[40,32,31,8],
[40,32,31,10],
[40,32,31,13],
[40,32,31,14],
[40,32,31,16],
[40,32,31,17],
[40,32,31,19],
[40,32,31,20],
[40,32,31,21],
[40,32,31,22],
[40,32,31,24],
[40,32,31,25],
[40,32,31,27],
[40,32,31,28],
[40,33,3,0],
[40,33,3,1],
[40,33,3,2],
[40,33,4,0],
[40,33,4,1],
[40,33,4,2],
[40,33,4,3],
[40,33,5,0],
[40,33,5,1],
[40,33,5,2],
[40,33,5,4],
[40,33,6,0],
[40,33,6,1],
[40,33,6,2],
[40,33,6,4],
[40,33,7,0],
[40,33,7,1],
[40,33,7,2],
[40,33,7,3],
[40,33,7,4],
[40,33,7,5],
[40,33,7,6],
[40,33,8,0],
[40,33,8,1],
[40,33,8,2],
[40,33,8,4],
[40,33,8,7],
[40,33,10,0],
[40,33,10,1],
[40,33,10,2],
[40,33,10,3],
[40,33,10,4],
[40,33,10,5],
[40,33,10,6],
[40,33,10,7],
[40,33,10,8],
[40,33,13,0],
[40,33,13,1],
[40,33,13,2],
[40,33,13,3],
[40,33,13,4],
[40,33,13,5],
[40,33,13,6],
[40,33,13,7],
[40,33,13,8],
[40,33,13,10],
[40,33,14,0],
[40,33,14,1],
[40,33,14,2],
[40,33,14,3],
[40,33,14,4],
[40,33,14,5],
[40,33,14,6],
[40,33,14,7],
[40,33,14,8],
[40,33,14,10],
[40,33,16,0],
[40,33,16,1],
[40,33,16,2],
[40,33,16,3],
[40,33,16,4],
[40,33,16,5],
[40,33,16,6],
[40,33,16,7],
[40,33,16,8],
[40,33,16,10],
[40,33,16,13],
[40,33,16,14],
[40,33,17,0],
[40,33,17,1],
[40,33,17,2],
[40,33,17,3],
[40,33,17,4],
[40,33,17,5],
[40,33,17,6],
[40,33,17,7],
[40,33,17,8],
[40,33,17,10],
[40,33,17,13],
[40,33,17,14],
[40,33,17,16],
[40,33,19,0],
[40,33,19,1],
[40,33,19,2],
[40,33,19,3],
[40,33,19,4],
[40,33,19,5],
[40,33,19,6],
[40,33,19,7],
[40,33,19,8],
[40,33,19,10],
[40,33,19,16],
[40,33,19,17],
[40,33,20,0],
[40,33,20,1],
[40,33,20,2],
[40,33,20,3],
[40,33,20,4],
[40,33,20,5],
[40,33,20,6],
[40,33,20,7],
[40,33,20,8],
[40,33,20,13],
[40,33,20,14],
[40,33,20,16],
[40,33,20,17],
[40,33,20,19],
[40,33,21,0],
[40,33,21,1],
[40,33,21,2],
[40,33,21,4],
[40,33,21,7],
[40,33,21,10],
[40,33,21,13],
[40,33,21,14],
[40,33,21,16],
[40,33,21,17],
[40,33,21,19],
[40,33,21,20],
[40,33,22,0],
[40,33,22,1],
[40,33,22,2],
[40,33,22,3],
[40,33,22,4],
[40,33,22,5],
[40,33,22,6],
[40,33,22,7],
[40,33,22,8],
[40,33,22,13],
[40,33,22,14],
[40,33,22,16],
[40,33,22,17],
[40,33,22,19],
[40,33,22,21],
[40,33,23,0],
[40,33,23,1],
[40,33,23,2],
[40,33,23,3],
[40,33,23,4],
[40,33,23,5],
[40,33,23,6],
[40,33,23,7],
[40,33,23,8],
[40,33,23,10],
[40,33,23,13],
[40,33,23,14],
[40,33,23,16],
[40,33,23,17],
[40,33,23,19],
[40,33,23,20],
[40,33,23,21],
[40,33,23,22],
[40,33,24,0],
[40,33,24,1],
[40,33,24,2],
[40,33,24,3],
[40,33,24,4],
[40,33,24,5],
[40,33,24,6],
[40,33,24,7],
[40,33,24,8],
[40,33,24,10],
[40,33,24,13],
[40,33,24,14],
[40,33,24,17],
[40,33,24,19],
[40,33,24,20],
[40,33,24,21],
[40,33,24,22],
[40,33,24,23],
[40,33,25,0],
[40,33,25,1],
[40,33,25,2],
[40,33,25,3],
[40,33,25,4],
[40,33,25,5],
[40,33,25,6],
[40,33,25,8],
[40,33,25,10],
[40,33,25,13],
[40,33,25,14],
[40,33,25,16],
[40,33,25,17],
[40,33,25,19],
[40,33,25,20],
[40,33,25,21],
[40,33,25,22],
[40,33,25,23],
[40,33,25,24],
[40,33,27,0],
[40,33,27,1],
[40,33,27,2],
[40,33,27,3],
[40,33,27,4],
[40,33,27,5],
[40,33,27,6],
[40,33,27,7],
[40,33,27,8],
[40,33,27,13],
[40,33,27,14],
[40,33,27,16],
[40,33,27,17],
[40,33,27,19],
[40,33,27,21],
[40,33,27,23],
[40,33,27,24],
[40,33,27,25],
[40,33,28,0],
[40,33,28,1],
[40,33,28,2],
[40,33,28,3],
[40,33,28,4],
[40,33,28,5],
[40,33,28,6],
[40,33,28,8],
[40,33,28,10],
[40,33,28,13],
[40,33,28,14],
[40,33,28,16],
[40,33,28,17],
[40,33,28,19],
[40,33,28,20],
[40,33,28,21],
[40,33,28,22],
[40,33,28,23],
[40,33,28,24],
[40,33,28,27],
[40,33,30,0],
[40,33,30,1],
[40,33,30,2],
[40,33,30,3],
[40,33,30,4],
[40,33,30,5],
[40,33,30,6],
[40,33,30,7],
[40,33,30,8],
[40,33,30,10],
[40,33,30,13],
[40,33,30,14],
[40,33,30,16],
[40,33,30,17],
[40,33,30,19],
[40,33,30,20],
[40,33,30,21],
[40,33,30,22],
[40,33,30,24],
[40,33,30,25],
[40,33,30,27],
[40,33,30,28],
[40,33,31,0],
[40,33,31,1],
[40,33,31,2],
[40,33,31,3],
[40,33,31,4],
[40,33,31,5],
[40,33,31,6],
[40,33,31,7],
[40,33,31,8],
[40,33,31,10],
[40,33,31,13],
[40,33,31,14],
[40,33,31,16],
[40,33,31,17],
[40,33,31,19],
[40,33,31,20],
[40,33,31,21],
[40,33,31,22],
[40,33,31,25],
[40,33,31,27],
[40,33,31,28],
[40,34,3,0],
[40,34,3,1],
[40,34,3,2],
[40,34,4,0],
[40,34,4,1],
[40,34,4,2],
[40,34,4,3],
[40,34,5,0],
[40,34,5,1],
[40,34,5,2],
[40,34,5,4],
[40,34,6,0],
[40,34,6,1],
[40,34,6,2],
[40,34,6,4],
[40,34,8,0],
[40,34,8,1],
[40,34,8,2],
[40,34,8,4],
[40,34,10,0],
[40,34,10,1],
[40,34,10,2],
[40,34,10,3],
[40,34,10,4],
[40,34,10,5],
[40,34,10,6],
[40,34,10,8],
[40,34,11,0],
[40,34,11,1],
[40,34,11,2],
[40,34,11,3],
[40,34,11,4],
[40,34,11,5],
[40,34,11,6],
[40,34,11,8],
[40,34,11,10],
[40,34,12,0],
[40,34,12,1],
[40,34,12,2],
[40,34,12,3],
[40,34,12,4],
[40,34,12,5],
[40,34,12,6],
[40,34,12,8],
[40,34,12,10],
[40,34,13,0],
[40,34,13,1],
[40,34,13,2],
[40,34,13,3],
[40,34,13,4],
[40,34,13,5],
[40,34,13,6],
[40,34,13,8],
[40,34,13,10],
[40,34,13,11],
[40,34,13,12],
[40,34,14,0],
[40,34,14,1],
[40,34,14,2],
[40,34,14,3],
[40,34,14,4],
[40,34,14,5],
[40,34,14,6],
[40,34,14,8],
[40,34,14,10],
[40,34,14,11],
[40,34,14,12],
[40,34,16,0],
[40,34,16,1],
[40,34,16,2],
[40,34,16,3],
[40,34,16,4],
[40,34,16,5],
[40,34,16,6],
[40,34,16,8],
[40,34,16,10],
[40,34,16,11],
[40,34,16,12],
[40,34,16,13],
[40,34,16,14],
[40,34,17,0],
[40,34,17,1],
[40,34,17,2],
[40,34,17,3],
[40,34,17,4],
[40,34,17,5],
[40,34,17,6],
[40,34,17,8],
[40,34,17,10],
[40,34,17,11],
[40,34,17,12],
[40,34,17,13],
[40,34,17,14],
[40,34,17,16],
[40,34,19,0],
[40,34,19,1],
[40,34,19,2],
[40,34,19,3],
[40,34,19,4],
[40,34,19,5],
[40,34,19,6],
[40,34,19,8],
[40,34,19,10],
[40,34,19,11],
[40,34,19,12],
[40,34,19,16],
[40,34,19,17],
[40,34,20,0],
[40,34,20,1],
[40,34,20,2],
[40,34,20,3],
[40,34,20,4],
[40,34,20,5],
[40,34,20,6],
[40,34,20,8],
[40,34,20,11],
[40,34,20,12],
[40,34,20,13],
[40,34,20,14],
[40,34,20,16],
[40,34,20,17],
[40,34,20,19],
[40,34,21,0],
[40,34,21,1],
[40,34,21,2],
[40,34,21,4],
[40,34,21,10],
[40,34,21,11],
[40,34,21,12],
[40,34,21,13],
[40,34,21,14],
[40,34,21,16],
[40,34,21,17],
[40,34,21,19],
[40,34,21,20],
[40,34,22,0],
[40,34,22,1],
[40,34,22,2],
[40,34,22,3],
[40,34,22,4],
[40,34,22,5],
[40,34,22,6],
[40,34,22,8],
[40,34,22,11],
[40,34,22,12],
[40,34,22,13],
[40,34,22,14],
[40,34,22,16],
[40,34,22,17],
[40,34,22,19],
[40,34,22,21],
[40,34,23,0],
[40,34,23,1],
[40,34,23,2],
[40,34,23,3],
[40,34,23,4],
[40,34,23,5],
[40,34,23,6],
[40,34,23,8],
[40,34,23,10],
[40,34,23,11],
[40,34,23,12],
[40,34,23,13],
[40,34,23,14],
[40,34,23,16],
[40,34,23,17],
[40,34,23,19],
[40,34,23,20],
[40,34,23,21],
[40,34,23,22],
[40,34,24,0],
[40,34,24,1],
[40,34,24,2],
[40,34,24,3],
[40,34,24,4],
[40,34,24,5],
[40,34,24,6],
[40,34,24,8],
[40,34,24,10],
[40,34,24,11],
[40,34,24,12],
[40,34,24,13],
[40,34,24,14],
[40,34,24,17],
[40,34,24,19],
[40,34,24,20],
[40,34,24,21],
[40,34,24,22],
[40,34,24,23],
[40,34,27,0],
[40,34,27,1],
[40,34,27,2],
[40,34,27,3],
[40,34,27,4],
[40,34,27,5],
[40,34,27,6],
[40,34,27,8],
[40,34,27,11],
[40,34,27,12],
[40,34,27,13],
[40,34,27,14],
[40,34,27,16],
[40,34,27,17],
[40,34,27,19],
[40,34,27,21],
[40,34,27,23],
[40,34,27,24],
[40,34,29,0],
[40,34,29,1],
[40,34,29,2],
[40,34,29,3],
[40,34,29,4],
[40,34,29,5],
[40,34,29,6],
[40,34,29,8],
[40,34,29,10],
[40,34,29,13],
[40,34,29,14],
[40,34,29,16],
[40,34,29,17],
[40,34,29,19],
[40,34,29,20],
[40,34,29,21],
[40,34,29,22],
[40,34,29,23],
[40,34,29,24],
[40,34,29,27],
[40,34,30,0],
[40,34,30,1],
[40,34,30,2],
[40,34,30,3],
[40,34,30,4],
[40,34,30,5],
[40,34,30,6],
[40,34,30,8],
[40,34,30,10],
[40,34,30,11],
[40,34,30,12],
[40,34,30,13],
[40,34,30,14],
[40,34,30,16],
[40,34,30,17],
[40,34,30,19],
[40,34,30,20],
[40,34,30,21],
[40,34,30,22],
[40,34,30,27],
[40,34,30,29],
[40,34,31,0],
[40,34,31,1],
[40,34,31,2],
[40,34,31,3],
[40,34,31,4],
[40,34,31,5],
[40,34,31,6],
[40,34,31,8],
[40,34,31,10],
[40,34,31,11],
[40,34,31,12],
[40,34,31,13],
[40,34,31,14],
[40,34,31,16],
[40,34,31,17],
[40,34,31,19],
[40,34,31,20],
[40,34,31,21],
[40,34,31,24],
[40,34,31,27],
[40,34,31,29],
[40,34,32,0],
[40,34,32,1],
[40,34,32,2],
[40,34,32,3],
[40,34,32,4],
[40,34,32,5],
[40,34,32,6],
[40,34,32,8],
[40,34,32,10],
[40,34,32,13],
[40,34,32,14],
[40,34,32,16],
[40,34,32,17],
[40,34,32,19],
[40,34,32,20],
[40,34,32,22],
[40,34,32,23],
[40,34,32,24],
[40,34,32,27],
[40,34,32,30],
[40,34,32,31],
[40,34,33,0],
[40,34,33,1],
[40,34,33,2],
[40,34,33,3],
[40,34,33,4],
[40,34,33,5],
[40,34,33,6],
[40,34,33,8],
[40,34,33,10],
[40,34,33,13],
[40,34,33,14],
[40,34,33,16],
[40,34,33,17],
[40,34,33,19],
[40,34,33,21],
[40,34,33,22],
[40,34,33,23],
[40,34,33,24],
[40,34,33,27],
[40,34,33,30],
[40,34,33,31],
[40,36,3,0],
[40,36,3,1],
[40,36,3,2],
[40,36,4,0],
[40,36,4,1],
[40,36,4,2],
[40,36,4,3],
[40,36,5,0],
[40,36,5,1],
[40,36,5,2],
[40,36,5,4],
[40,36,6,0],
[40,36,6,1],
[40,36,6,2],
[40,36,6,4],
[40,36,7,0],
[40,36,7,1],
[40,36,7,2],
[40,36,7,3],
[40,36,7,4],
[40,36,7,5],
[40,36,7,6],
[40,36,8,0],
[40,36,8,1],
[40,36,8,2],
[40,36,8,4],
[40,36,8,7],
[40,36,10,0],
[40,36,10,1],
[40,36,10,2],
[40,36,10,3],
[40,36,10,4],
[40,36,10,5],
[40,36,10,6],
[40,36,10,7],
[40,36,10,8],
[40,36,11,0],
[40,36,11,1],
[40,36,11,2],
[40,36,11,3],
[40,36,11,4],
[40,36,11,5],
[40,36,11,6],
[40,36,11,7],
[40,36,11,8],
[40,36,11,10],
[40,36,12,0],
[40,36,12,1],
[40,36,12,2],
[40,36,12,3],
[40,36,12,4],
[40,36,12,5],
[40,36,12,6],
[40,36,12,7],
[40,36,12,8],
[40,36,12,10],
[40,36,16,0],
[40,36,16,1],
[40,36,16,2],
[40,36,16,3],
[40,36,16,4],
[40,36,16,5],
[40,36,16,6],
[40,36,16,7],
[40,36,16,8],
[40,36,16,10],
[40,36,16,11],
[40,36,16,12],
[40,36,17,0],
[40,36,17,1],
[40,36,17,2],
[40,36,17,3],
[40,36,17,4],
[40,36,17,5],
[40,36,17,6],
[40,36,17,7],
[40,36,17,8],
[40,36,17,10],
[40,36,17,11],
[40,36,17,12],
[40,36,17,16],
[40,36,20,0],
[40,36,20,1],
[40,36,20,2],
[40,36,20,3],
[40,36,20,4],
[40,36,20,5],
[40,36,20,6],
[40,36,20,7],
[40,36,20,8],
[40,36,20,11],
[40,36,20,12],
[40,36,20,16],
[40,36,20,17],
[40,36,21,0],
[40,36,21,1],
[40,36,21,2],
[40,36,21,4],
[40,36,21,7],
[40,36,21,10],
[40,36,21,11],
[40,36,21,12],
[40,36,21,16],
[40,36,21,17],
[40,36,21,20],
[40,36,22,0],
[40,36,22,1],
[40,36,22,2],
[40,36,22,3],
[40,36,22,4],
[40,36,22,5],
[40,36,22,6],
[40,36,22,7],
[40,36,22,8],
[40,36,22,11],
[40,36,22,12],
[40,36,22,16],
[40,36,22,17],
[40,36,22,21],
[40,36,23,0],
[40,36,23,1],
[40,36,23,2],
[40,36,23,3],
[40,36,23,4],
[40,36,23,5],
[40,36,23,6],
[40,36,23,7],
[40,36,23,8],
[40,36,23,10],
[40,36,23,11],
[40,36,23,12],
[40,36,23,16],
[40,36,23,17],
[40,36,23,20],
[40,36,23,21],
[40,36,23,22],
[40,36,24,0],
[40,36,24,1],
[40,36,24,2],
[40,36,24,3],
[40,36,24,4],
[40,36,24,5],
[40,36,24,6],
[40,36,24,7],
[40,36,24,8],
[40,36,24,10],
[40,36,24,11],
[40,36,24,12],
[40,36,24,17],
[40,36,24,20],
[40,36,24,21],
[40,36,24,22],
[40,36,24,23],
[40,36,25,0],
[40,36,25,1],
[40,36,25,2],
[40,36,25,3],
[40,36,25,4],
[40,36,25,5],
[40,36,25,6],
[40,36,25,8],
[40,36,25,10],
[40,36,25,11],
[40,36,25,12],
[40,36,25,16],
[40,36,25,17],
[40,36,25,20],
[40,36,25,21],
[40,36,25,22],
[40,36,25,23],
[40,36,25,24],
[40,36,27,0],
[40,36,27,1],
[40,36,27,2],
[40,36,27,3],
[40,36,27,4],
[40,36,27,5],
[40,36,27,6],
[40,36,27,7],
[40,36,27,8],
[40,36,27,11],
[40,36,27,12],
[40,36,27,16],
[40,36,27,17],
[40,36,27,21],
[40,36,27,23],
[40,36,27,24],
[40,36,27,25],
[40,36,28,0],
[40,36,28,1],
[40,36,28,2],
[40,36,28,3],
[40,36,28,4],
[40,36,28,5],
[40,36,28,6],
[40,36,28,8],
[40,36,28,10],
[40,36,28,11],
[40,36,28,12],
[40,36,28,16],
[40,36,28,17],
[40,36,28,20],
[40,36,28,21],
[40,36,28,22],
[40,36,28,27],
[40,36,29,0],
[40,36,29,1],
[40,36,29,2],
[40,36,29,3],
[40,36,29,4],
[40,36,29,5],
[40,36,29,6],
[40,36,29,7],
[40,36,29,8],
[40,36,29,10],
[40,36,29,16],
[40,36,29,17],
[40,36,29,20],
[40,36,29,21],
[40,36,29,23],
[40,36,29,24],
[40,36,29,25],
[40,36,29,27],
[40,36,29,28],
[40,36,30,0],
[40,36,30,1],
[40,36,30,2],
[40,36,30,3],
[40,36,30,4],
[40,36,30,5],
[40,36,30,6],
[40,36,30,7],
[40,36,30,8],
[40,36,30,10],
[40,36,30,11],
[40,36,30,12],
[40,36,30,16],
[40,36,30,17],
[40,36,30,20],
[40,36,30,22],
[40,36,30,24],
[40,36,30,25],
[40,36,30,27],
[40,36,30,28],
[40,36,30,29],
[40,36,31,0],
[40,36,31,1],
[40,36,31,2],
[40,36,31,3],
[40,36,31,4],
[40,36,31,5],
[40,36,31,6],
[40,36,31,7],
[40,36,31,8],
[40,36,31,10],
[40,36,31,11],
[40,36,31,12],
[40,36,31,16],
[40,36,31,17],
[40,36,31,21],
[40,36,31,22],
[40,36,31,24],
[40,36,31,25],
[40,36,31,27],
[40,36,31,28],
[40,36,31,29],
[40,36,32,0],
[40,36,32,1],
[40,36,32,2],
[40,36,32,3],
[40,36,32,4],
[40,36,32,5],
[40,36,32,6],
[40,36,32,7],
[40,36,32,8],
[40,36,32,10],
[40,36,32,16],
[40,36,32,17],
[40,36,32,20],
[40,36,32,21],
[40,36,32,22],
[40,36,32,23],
[40,36,32,24],
[40,36,32,25],
[40,36,32,27],
[40,36,32,28],
[40,36,32,30],
[40,36,32,31],
[40,36,33,0],
[40,36,33,1],
[40,36,33,2],
[40,36,33,3],
[40,36,33,4],
[40,36,33,5],
[40,36,33,6],
[40,36,33,7],
[40,36,33,8],
[40,36,33,10],
[40,36,33,16],
[40,36,33,17],
[40,36,33,20],
[40,36,33,21],
[40,36,33,22],
[40,36,33,23],
[40,36,33,24],
[40,36,33,25],
[40,36,33,27],
[40,36,33,28],
[40,36,33,30],
[40,36,34,0],
[40,36,34,1],
[40,36,34,2],
[40,36,34,3],
[40,36,34,4],
[40,36,34,5],
[40,36,34,6],
[40,36,34,8],
[40,36,34,10],
[40,36,34,11],
[40,36,34,12],
[40,36,34,20],
[40,36,34,21],
[40,36,34,22],
[40,36,34,23],
[40,36,34,24],
[40,36,34,27],
[40,36,34,29],
[40,37,3,0],
[40,37,3,1],
[40,37,3,2],
[40,37,5,0],
[40,37,5,1],
[40,37,5,2],
[40,37,6,0],
[40,37,6,1],
[40,37,6,2],
[40,37,7,0],
[40,37,7,1],
[40,37,7,2],
[40,37,7,3],
[40,37,7,5],
[40,37,7,6],
[40,37,8,0],
[40,37,8,1],
[40,37,8,2],
[40,37,8,7],
[40,37,10,0],
[40,37,10,1],
[40,37,10,2],
[40,37,10,3],
[40,37,10,5],
[40,37,10,6],
[40,37,10,7],
[40,37,10,8],
[40,37,11,0],
[40,37,11,1],
[40,37,11,2],
[40,37,11,3],
[40,37,11,5],
[40,37,11,6],
[40,37,11,7],
[40,37,11,8],
[40,37,11,10],
[40,37,12,0],
[40,37,12,1],
[40,37,12,2],
[40,37,12,3],
[40,37,12,5],
[40,37,12,6],
[40,37,12,7],
[40,37,12,8],
[40,37,12,10],
[40,37,13,0],
[40,37,13,1],
[40,37,13,2],
[40,37,13,3],
[40,37,13,5],
[40,37,13,6],
[40,37,13,7],
[40,37,13,8],
[40,37,13,10],
[40,37,13,11],
[40,37,13,12],
[40,37,14,0],
[40,37,14,1],
[40,37,14,2],
[40,37,14,3],
[40,37,14,5],
[40,37,14,6],
[40,37,14,7],
[40,37,14,8],
[40,37,14,10],
[40,37,14,11],
[40,37,14,12],
[40,37,16,0],
[40,37,16,1],
[40,37,16,2],
[40,37,16,3],
[40,37,16,5],
[40,37,16,6],
[40,37,16,7],
[40,37,16,8],
[40,37,16,10],
[40,37,16,11],
[40,37,16,12],
[40,37,16,13],
[40,37,16,14],
[40,37,17,0],
[40,37,17,1],
[40,37,17,2],
[40,37,17,3],
[40,37,17,5],
[40,37,17,6],
[40,37,17,7],
[40,37,17,8],
[40,37,17,10],
[40,37,17,11],
[40,37,17,12],
[40,37,17,13],
[40,37,17,14],
[40,37,17,16],
[40,37,19,0],
[40,37,19,1],
[40,37,19,2],
[40,37,19,3],
[40,37,19,5],
[40,37,19,6],
[40,37,19,7],
[40,37,19,8],
[40,37,19,10],
[40,37,19,11],
[40,37,19,12],
[40,37,19,16],
[40,37,19,17],
[40,37,20,0],
[40,37,20,1],
[40,37,20,2],
[40,37,20,3],
[40,37,20,5],
[40,37,20,6],
[40,37,20,7],
[40,37,20,8],
[40,37,20,11],
[40,37,20,12],
[40,37,20,13],
[40,37,20,14],
[40,37,20,16],
[40,37,20,17],
[40,37,20,19],
[40,37,21,0],
[40,37,21,1],
[40,37,21,2],
[40,37,21,7],
[40,37,21,10],
[40,37,21,11],
[40,37,21,12],
[40,37,21,13],
[40,37,21,14],
[40,37,21,16],
[40,37,21,17],
[40,37,21,19],
[40,37,21,20],
[40,37,22,0],
[40,37,22,1],
[40,37,22,2],
[40,37,22,3],
[40,37,22,5],
[40,37,22,6],
[40,37,22,7],
[40,37,22,8],
[40,37,22,11],
[40,37,22,12],
[40,37,22,13],
[40,37,22,14],
[40,37,22,16],
[40,37,22,17],
[40,37,22,19],
[40,37,22,21],
[40,37,23,0],
[40,37,23,1],
[40,37,23,2],
[40,37,23,3],
[40,37,23,5],
[40,37,23,6],
[40,37,23,7],
[40,37,23,8],
[40,37,23,10],
[40,37,23,11],
[40,37,23,12],
[40,37,23,13],
[40,37,23,14],
[40,37,23,16],
[40,37,23,17],
[40,37,23,19],
[40,37,23,20],
[40,37,23,21],
[40,37,23,22],
[40,37,24,0],
[40,37,24,1],
[40,37,24,2],
[40,37,24,3],
[40,37,24,5],
[40,37,24,6],
[40,37,24,7],
[40,37,24,8],
[40,37,24,10],
[40,37,24,11],
[40,37,24,12],
[40,37,24,13],
[40,37,24,14],
[40,37,24,17],
[40,37,24,19],
[40,37,24,20],
[40,37,24,21],
[40,37,24,22],
[40,37,24,23],
[40,37,25,0],
[40,37,25,1],
[40,37,25,2],
[40,37,25,3],
[40,37,25,5],
[40,37,25,6],
[40,37,25,8],
[40,37,25,10],
[40,37,25,11],
[40,37,25,12],
[40,37,25,13],
[40,37,25,14],
[40,37,25,16],
[40,37,25,17],
[40,37,25,19],
[40,37,25,20],
[40,37,25,21],
[40,37,25,22],
[40,37,25,23],
[40,37,25,24],
[40,37,27,0],
[40,37,27,1],
[40,37,27,2],
[40,37,27,3],
[40,37,27,5],
[40,37,27,6],
[40,37,27,7],
[40,37,27,8],
[40,37,27,11],
[40,37,27,12],
[40,37,27,13],
[40,37,27,14],
[40,37,27,16],
[40,37,27,17],
[40,37,27,19],
[40,37,27,21],
[40,37,27,25],
[40,37,28,0],
[40,37,28,1],
[40,37,28,2],
[40,37,28,3],
[40,37,28,5],
[40,37,28,6],
[40,37,28,8],
[40,37,28,10],
[40,37,28,11],
[40,37,28,12],
[40,37,28,13],
[40,37,28,14],
[40,37,28,16],
[40,37,28,17],
[40,37,28,19],
[40,37,28,20],
[40,37,28,21],
[40,37,28,23],
[40,37,28,24],
[40,37,28,27],
[40,37,29,0],
[40,37,29,1],
[40,37,29,2],
[40,37,29,3],
[40,37,29,5],
[40,37,29,6],
[40,37,29,7],
[40,37,29,8],
[40,37,29,10],
[40,37,29,13],
[40,37,29,14],
[40,37,29,16],
[40,37,29,17],
[40,37,29,19],
[40,37,29,20],
[40,37,29,22],
[40,37,29,23],
[40,37,29,24],
[40,37,29,25],
[40,37,29,27],
[40,37,29,28],
[40,37,30,0],
[40,37,30,1],
[40,37,30,2],
[40,37,30,3],
[40,37,30,5],
[40,37,30,6],
[40,37,30,7],
[40,37,30,8],
[40,37,30,10],
[40,37,30,11],
[40,37,30,12],
[40,37,30,13],
[40,37,30,14],
[40,37,30,16],
[40,37,30,17],
[40,37,30,19],
[40,37,30,21],
[40,37,30,22],
[40,37,30,24],
[40,37,30,25],
[40,37,30,27],
[40,37,30,28],
[40,37,30,29],
[40,37,31,0],
[40,37,31,1],
[40,37,31,2],
[40,37,31,3],
[40,37,31,5],
[40,37,31,6],
[40,37,31,7],
[40,37,31,8],
[40,37,31,10],
[40,37,31,11],
[40,37,31,12],
[40,37,31,13],
[40,37,31,14],
[40,37,31,16],
[40,37,31,17],
[40,37,31,20],
[40,37,31,21],
[40,37,31,22],
[40,37,31,24],
[40,37,31,25],
[40,37,31,27],
[40,37,31,28],
[40,37,31,29],
[40,37,32,0],
[40,37,32,1],
[40,37,32,2],
[40,37,32,3],
[40,37,32,5],
[40,37,32,6],
[40,37,32,7],
[40,37,32,8],
[40,37,32,10],
[40,37,32,13],
[40,37,32,14],
[40,37,32,16],
[40,37,32,17],
[40,37,32,19],
[40,37,32,20],
[40,37,32,21],
[40,37,32,22],
[40,37,32,23],
[40,37,32,24],
[40,37,32,25],
[40,37,32,27],
[40,37,32,28],
[40,37,32,30],
[40,37,33,0],
[40,37,33,1],
[40,37,33,2],
[40,37,33,3],
[40,37,33,5],
[40,37,33,6],
[40,37,33,7],
[40,37,33,8],
[40,37,33,10],
[40,37,33,13],
[40,37,33,14],
[40,37,33,19],
[40,37,33,20],
[40,37,33,21],
[40,37,33,22],
[40,37,33,23],
[40,37,33,24],
[40,37,33,25],
[40,37,33,27],
[40,37,33,28],
[40,37,34,0],
[40,37,34,1],
[40,37,34,2],
[40,37,34,3],
[40,37,34,5],
[40,37,34,6],
[40,37,34,8],
[40,37,34,10],
[40,37,34,11],
[40,37,34,12],
[40,37,34,13],
[40,37,34,14],
[40,37,34,16],
[40,37,34,17],
[40,37,34,19],
[40,37,34,20],
[40,37,34,21],
[40,37,34,22],
[40,37,34,23],
[40,37,34,24],
[40,37,34,27],
[40,37,36,0],
[40,37,36,1],
[40,37,36,2],
[40,37,36,3],
[40,37,36,5],
[40,37,36,6],
[40,37,36,7],
[40,37,36,8],
[40,37,36,10],
[40,37,36,11],
[40,37,36,16],
[40,37,36,17],
[40,37,36,20],
[40,37,36,21],
[40,37,36,22],
[40,37,36,23],
[40,37,36,24],
[40,37,36,25],
[40,38,3,0],
[40,38,3,1],
[40,38,3,2],
[40,38,4,0],
[40,38,4,1],
[40,38,4,2],
[40,38,4,3],
[40,38,5,0],
[40,38,5,1],
[40,38,5,2],
[40,38,5,4],
[40,38,6,0],
[40,38,6,1],
[40,38,6,2],
[40,38,6,4],
[40,38,8,0],
[40,38,8,1],
[40,38,8,2],
[40,38,8,4],
[40,38,10,0],
[40,38,10,1],
[40,38,10,2],
[40,38,10,3],
[40,38,10,4],
[40,38,10,5],
[40,38,10,6],
[40,38,10,8],
[40,38,11,0],
[40,38,11,1],
[40,38,11,2],
[40,38,11,3],
[40,38,11,4],
[40,38,11,5],
[40,38,11,6],
[40,38,11,8],
[40,38,11,10],
[40,38,12,0],
[40,38,12,1],
[40,38,12,2],
[40,38,12,3],
[40,38,12,4],
[40,38,12,5],
[40,38,12,6],
[40,38,12,8],
[40,38,12,10],
[40,38,13,0],
[40,38,13,1],
[40,38,13,2],
[40,38,13,3],
[40,38,13,4],
[40,38,13,5],
[40,38,13,6],
[40,38,13,8],
[40,38,13,10],
[40,38,13,11],
[40,38,13,12],
[40,38,14,0],
[40,38,14,1],
[40,38,14,2],
[40,38,14,3],
[40,38,14,4],
[40,38,14,5],
[40,38,14,6],
[40,38,14,8],
[40,38,14,10],
[40,38,14,11],
[40,38,14,12],
[40,38,16,0],
[40,38,16,1],
[40,38,16,2],
[40,38,16,3],
[40,38,16,4],
[40,38,16,5],
[40,38,16,6],
[40,38,16,8],
[40,38,16,10],
[40,38,16,11],
[40,38,16,12],
[40,38,16,13],
[40,38,16,14],
[40,38,17,0],
[40,38,17,1],
[40,38,17,2],
[40,38,17,3],
[40,38,17,4],
[40,38,17,5],
[40,38,17,6],
[40,38,17,8],
[40,38,17,10],
[40,38,17,11],
[40,38,17,12],
[40,38,17,13],
[40,38,17,14],
[40,38,17,16],
[40,38,19,0],
[40,38,19,1],
[40,38,19,2],
[40,38,19,3],
[40,38,19,4],
[40,38,19,5],
[40,38,19,6],
[40,38,19,8],
[40,38,19,10],
[40,38,19,11],
[40,38,19,12],
[40,38,19,16],
[40,38,19,17],
[40,38,20,0],
[40,38,20,1],
[40,38,20,2],
[40,38,20,3],
[40,38,20,4],
[40,38,20,5],
[40,38,20,6],
[40,38,20,8],
[40,38,20,11],
[40,38,20,12],
[40,38,20,13],
[40,38,20,14],
[40,38,20,16],
[40,38,20,17],
[40,38,20,19],
[40,38,21,0],
[40,38,21,1],
[40,38,21,2],
[40,38,21,4],
[40,38,21,10],
[40,38,21,11],
[40,38,21,12],
[40,38,21,13],
[40,38,21,14],
[40,38,21,16],
[40,38,21,17],
[40,38,21,19],
[40,38,21,20],
[40,38,22,0],
[40,38,22,1],
[40,38,22,2],
[40,38,22,3],
[40,38,22,4],
[40,38,22,5],
[40,38,22,6],
[40,38,22,8],
[40,38,22,11],
[40,38,22,12],
[40,38,22,13],
[40,38,22,14],
[40,38,22,16],
[40,38,22,17],
[40,38,22,19],
[40,38,22,21],
[40,38,23,0],
[40,38,23,1],
[40,38,23,2],
[40,38,23,3],
[40,38,23,4],
[40,38,23,5],
[40,38,23,6],
[40,38,23,8],
[40,38,23,10],
[40,38,23,11],
[40,38,23,12],
[40,38,23,13],
[40,38,23,14],
[40,38,23,16],
[40,38,23,17],
[40,38,23,19],
[40,38,23,20],
[40,38,23,21],
[40,38,23,22],
[40,38,24,0],
[40,38,24,1],
[40,38,24,2],
[40,38,24,3],
[40,38,24,4],
[40,38,24,5],
[40,38,24,6],
[40,38,24,8],
[40,38,24,10],
[40,38,24,11],
[40,38,24,12],
[40,38,24,13],
[40,38,24,14],
[40,38,24,17],
[40,38,24,19],
[40,38,24,20],
[40,38,24,21],
[40,38,24,22],
[40,38,24,23],
[40,38,27,0],
[40,38,27,1],
[40,38,27,2],
[40,38,27,3],
[40,38,27,4],
[40,38,27,5],
[40,38,27,6],
[40,38,27,8],
[40,38,27,11],
[40,38,27,12],
[40,38,27,13],
[40,38,27,14],
[40,38,27,16],
[40,38,27,17],
[40,38,27,19],
[40,38,27,21],
[40,38,27,23],
[40,38,27,24],
[40,38,29,0],
[40,38,29,1],
[40,38,29,2],
[40,38,29,3],
[40,38,29,4],
[40,38,29,5],
[40,38,29,6],
[40,38,29,8],
[40,38,29,10],
[40,38,29,13],
[40,38,29,14],
[40,38,29,16],
[40,38,29,17],
[40,38,29,19],
[40,38,29,21],
[40,38,29,22],
[40,38,29,23],
[40,38,29,24],
[40,38,29,27],
[40,38,30,0],
[40,38,30,1],
[40,38,30,2],
[40,38,30,3],
[40,38,30,4],
[40,38,30,5],
[40,38,30,6],
[40,38,30,8],
[40,38,30,10],
[40,38,30,11],
[40,38,30,12],
[40,38,30,13],
[40,38,30,14],
[40,38,30,16],
[40,38,30,17],
[40,38,30,20],
[40,38,30,21],
[40,38,30,22],
[40,38,30,24],
[40,38,30,27],
[40,38,30,29],
[40,38,31,0],
[40,38,31,1],
[40,38,31,2],
[40,38,31,3],
[40,38,31,4],
[40,38,31,5],
[40,38,31,6],
[40,38,31,8],
[40,38,31,10],
[40,38,31,11],
[40,38,31,12],
[40,38,31,13],
[40,38,31,14],
[40,38,31,16],
[40,38,31,17],
[40,38,31,19],
[40,38,31,20],
[40,38,31,21],
[40,38,31,22],
[40,38,31,24],
[40,38,31,27],
[40,38,31,29],
[40,38,32,0],
[40,38,32,1],
[40,38,32,2],
[40,38,32,3],
[40,38,32,4],
[40,38,32,5],
[40,38,32,6],
[40,38,32,8],
[40,38,32,10],
[40,38,32,13],
[40,38,32,14],
[40,38,32,19],
[40,38,32,20],
[40,38,32,21],
[40,38,32,22],
[40,38,32,23],
[40,38,32,24],
[40,38,32,27],
[40,38,33,0],
[40,38,33,1],
[40,38,33,2],
[40,38,33,3],
[40,38,33,4],
[40,38,33,5],
[40,38,33,6],
[40,38,33,8],
[40,38,33,10],
[40,38,33,13],
[40,38,33,14],
[40,38,33,16],
[40,38,33,17],
[40,38,33,19],
[40,38,33,20],
[40,38,33,21],
[40,38,33,22],
[40,38,33,23],
[40,38,33,24],
[40,38,33,27],
[40,38,36,0],
[40,38,36,1],
[40,38,36,2],
[40,38,36,3],
[40,38,36,4],
[40,38,36,5],
[40,38,36,6],
[40,38,36,8],
[40,38,36,12],
[40,38,36,16],
[40,38,36,17],
[40,38,36,20],
[40,38,36,21],
[40,38,36,22],
[40,38,36,23],
[40,38,36,24],
[40,38,37,0],
[40,38,37,1],
[40,38,37,2],
[40,38,37,3],
[40,38,37,5],
[40,38,37,6],
[40,38,37,10],
[40,38,37,11],
[40,38,37,12],
[40,38,37,13],
[40,38,37,14],
[40,38,37,16],
[40,38,37,17],
[40,38,37,19],
[40,38,37,20],
[40,38,37,21],
[40,38,37,22],
[40,38,37,23],
[40,38,37,24],
[40,39,4,0],
[40,39,4,1],
[40,39,4,2],
[40,39,7,0],
[40,39,7,1],
[40,39,7,2],
[40,39,7,4],
[40,39,10,0],
[40,39,10,1],
[40,39,10,2],
[40,39,10,4],
[40,39,10,7],
[40,39,11,0],
[40,39,11,1],
[40,39,11,2],
[40,39,11,4],
[40,39,11,7],
[40,39,11,10],
[40,39,12,0],
[40,39,12,1],
[40,39,12,2],
[40,39,12,4],
[40,39,12,7],
[40,39,12,10],
[40,39,13,0],
[40,39,13,1],
[40,39,13,2],
[40,39,13,4],
[40,39,13,7],
[40,39,13,10],
[40,39,13,11],
[40,39,13,12],
[40,39,14,0],
[40,39,14,1],
[40,39,14,2],
[40,39,14,4],
[40,39,14,7],
[40,39,14,10],
[40,39,14,11],
[40,39,14,12],
[40,39,16,0],
[40,39,16,1],
[40,39,16,2],
[40,39,16,4],
[40,39,16,7],
[40,39,16,10],
[40,39,16,11],
[40,39,16,12],
[40,39,16,13],
[40,39,16,14],
[40,39,17,0],
[40,39,17,1],
[40,39,17,2],
[40,39,17,4],
[40,39,17,7],
[40,39,17,10],
[40,39,17,11],
[40,39,17,12],
[40,39,17,13],
[40,39,17,14],
[40,39,17,16],
[40,39,19,0],
[40,39,19,1],
[40,39,19,2],
[40,39,19,4],
[40,39,19,7],
[40,39,19,10],
[40,39,19,11],
[40,39,19,12],
[40,39,19,16],
[40,39,19,17],
[40,39,20,0],
[40,39,20,1],
[40,39,20,2],
[40,39,20,4],
[40,39,20,7],
[40,39,20,11],
[40,39,20,12],
[40,39,20,13],
[40,39,20,14],
[40,39,20,16],
[40,39,20,17],
[40,39,20,19],
[40,39,22,0],
[40,39,22,1],
[40,39,22,2],
[40,39,22,4],
[40,39,22,7],
[40,39,22,11],
[40,39,22,12],
[40,39,22,13],
[40,39,22,14],
[40,39,22,16],
[40,39,22,17],
[40,39,22,19],
[40,39,23,0],
[40,39,23,1],
[40,39,23,2],
[40,39,23,4],
[40,39,23,7],
[40,39,23,10],
[40,39,23,11],
[40,39,23,12],
[40,39,23,13],
[40,39,23,14],
[40,39,23,16],
[40,39,23,17],
[40,39,23,19],
[40,39,23,20],
[40,39,23,22],
[40,39,24,0],
[40,39,24,1],
[40,39,24,2],
[40,39,24,4],
[40,39,24,7],
[40,39,24,10],
[40,39,24,11],
[40,39,24,12],
[40,39,24,13],
[40,39,24,14],
[40,39,24,17],
[40,39,24,19],
[40,39,24,20],
[40,39,24,22],
[40,39,24,23],
[40,39,25,0],
[40,39,25,1],
[40,39,25,2],
[40,39,25,4],
[40,39,25,10],
[40,39,25,11],
[40,39,25,12],
[40,39,25,13],
[40,39,25,14],
[40,39,25,16],
[40,39,25,17],
[40,39,25,19],
[40,39,25,20],
[40,39,25,22],
[40,39,27,0],
[40,39,27,1],
[40,39,27,2],
[40,39,27,4],
[40,39,27,7],
[40,39,27,11],
[40,39,27,12],
[40,39,27,13],
[40,39,27,14],
[40,39,27,16],
[40,39,27,17],
[40,39,27,19],
[40,39,27,23],
[40,39,27,24],
[40,39,27,25],
[40,39,28,0],
[40,39,28,1],
[40,39,28,2],
[40,39,28,4],
[40,39,28,10],
[40,39,28,11],
[40,39,28,12],
[40,39,28,13],
[40,39,28,14],
[40,39,28,16],
[40,39,28,17],
[40,39,28,19],
[40,39,28,22],
[40,39,28,23],
[40,39,28,24],
[40,39,28,27],
[40,39,29,0],
[40,39,29,1],
[40,39,29,2],
[40,39,29,4],
[40,39,29,7],
[40,39,29,10],
[40,39,29,13],
[40,39,29,14],
[40,39,29,16],
[40,39,29,17],
[40,39,29,20],
[40,39,29,22],
[40,39,29,23],
[40,39,29,24],
[40,39,29,25],
[40,39,29,27],
[40,39,29,28],
[40,39,30,0],
[40,39,30,1],
[40,39,30,2],
[40,39,30,4],
[40,39,30,7],
[40,39,30,10],
[40,39,30,11],
[40,39,30,12],
[40,39,30,13],
[40,39,30,14],
[40,39,30,16],
[40,39,30,17],
[40,39,30,19],
[40,39,30,20],
[40,39,30,22],
[40,39,30,24],
[40,39,30,25],
[40,39,30,27],
[40,39,30,28],
[40,39,30,29],
[40,39,31,0],
[40,39,31,1],
[40,39,31,2],
[40,39,31,4],
[40,39,31,7],
[40,39,31,10],
[40,39,31,11],
[40,39,31,12],
[40,39,31,13],
[40,39,31,14],
[40,39,31,19],
[40,39,31,20],
[40,39,31,22],
[40,39,31,24],
[40,39,31,25],
[40,39,31,27],
[40,39,31,28],
[40,39,31,29],
[40,39,32,0],
[40,39,32,1],
[40,39,32,2],
[40,39,32,4],
[40,39,32,7],
[40,39,32,10],
[40,39,32,13],
[40,39,32,14],
[40,39,32,16],
[40,39,32,17],
[40,39,32,19],
[40,39,32,20],
[40,39,32,22],
[40,39,32,23],
[40,39,32,24],
[40,39,32,25],
[40,39,32,27],
[40,39,32,28],
[40,39,33,0],
[40,39,33,1],
[40,39,33,2],
[40,39,33,4],
[40,39,33,7],
[40,39,33,10],
[40,39,33,13],
[40,39,33,16],
[40,39,33,17],
[40,39,33,19],
[40,39,33,20],
[40,39,33,22],
[40,39,33,23],
[40,39,33,24],
[40,39,33,25],
[40,39,33,27],
[40,39,34,0],
[40,39,34,1],
[40,39,34,2],
[40,39,34,4],
[40,39,34,10],
[40,39,34,11],
[40,39,34,14],
[40,39,34,16],
[40,39,34,17],
[40,39,34,19],
[40,39,34,20],
[40,39,34,22],
[40,39,34,23],
[40,39,34,24],
[40,39,36,0],
[40,39,36,1],
[40,39,36,2],
[40,39,36,4],
[40,39,36,7],
[40,39,36,10],
[40,39,36,11],
[40,39,36,12],
[40,39,36,16],
[40,39,36,17],
[40,39,36,20],
[40,39,36,22],
[40,39,36,23],
[40,39,36,24],
[40,39,37,0],
[40,39,37,1],
[40,39,37,2],
[40,39,37,10],
[40,39,37,11],
[40,39,37,12],
[40,39,37,13],
[40,39,37,14],
[40,39,37,16],
[40,39,37,17],
[40,39,37,19],
[40,39,37,20],
[40,39,37,22],
[40,39,37,23],
[40,39,37,24],
[40,39,38,0],
[40,39,38,1],
[40,39,38,2],
[40,39,38,4],
[40,39,38,10],
[40,39,38,11],
[40,39,38,12],
[40,39,38,13],
[40,39,38,14],
[40,39,38,16],
[40,39,38,17],
[40,39,38,19],
[40,39,38,20],
[40,39,38,22],
[41,4,3,0],
[41,4,3,1],
[41,4,3,2],
[41,5,4,0],
[41,5,4,1],
[41,5,4,2],
[41,6,4,0],
[41,6,4,1],
[41,6,4,2],
[41,7,3,0],
[41,7,3,1],
[41,7,3,2],
[41,7,4,0],
[41,7,4,1],
[41,7,4,2],
[41,7,4,3],
[41,7,5,0],
[41,7,5,1],
[41,7,5,2],
[41,7,5,4],
[41,7,6,0],
[41,7,6,1],
[41,7,6,2],
[41,7,6,4],
[41,8,4,0],
[41,8,4,1],
[41,8,4,2],
[41,8,7,0],
[41,8,7,1],
[41,8,7,2],
[41,8,7,4],
[41,10,3,0],
[41,10,3,1],
[41,10,3,2],
[41,10,4,0],
[41,10,4,1],
[41,10,4,2],
[41,10,4,3],
[41,10,5,0],
[41,10,5,1],
[41,10,5,2],
[41,10,5,4],
[41,10,6,0],
[41,10,6,1],
[41,10,6,2],
[41,10,6,4],
[41,10,7,0],
[41,10,7,1],
[41,10,7,2],
[41,10,7,3],
[41,10,7,4],
[41,10,7,5],
[41,10,7,6],
[41,10,8,0],
[41,10,8,1],
[41,10,8,2],
[41,10,8,4],
[41,10,8,7],
[41,11,3,0],
[41,11,3,1],
[41,11,3,2],
[41,11,4,0],
[41,11,4,1],
[41,11,4,2],
[41,11,4,3],
[41,11,5,0],
[41,11,5,1],
[41,11,5,2],
[41,11,5,4],
[41,11,6,0],
[41,11,6,1],
[41,11,6,2],
[41,11,6,4],
[41,11,7,0],
[41,11,7,1],
[41,11,7,2],
[41,11,7,3],
[41,11,7,4],
[41,11,7,5],
[41,11,7,6],
[41,11,8,0],
[41,11,8,1],
[41,11,8,2],
[41,11,8,4],
[41,11,8,7],
[41,11,10,0],
[41,11,10,1],
[41,11,10,2],
[41,11,10,3],
[41,11,10,4],
[41,11,10,5],
[41,11,10,6],
[41,11,10,7],
[41,11,10,8],
[41,12,3,0],
[41,12,3,1],
[41,12,3,2],
[41,12,4,0],
[41,12,4,1],
[41,12,4,2],
[41,12,4,3],
[41,12,5,0],
[41,12,5,1],
[41,12,5,2],
[41,12,5,4],
[41,12,6,0],
[41,12,6,1],
[41,12,6,2],
[41,12,6,4],
[41,12,7,0],
[41,12,7,1],
[41,12,7,2],
[41,12,7,3],
[41,12,7,4],
[41,12,7,5],
[41,12,7,6],
[41,12,8,0],
[41,12,8,1],
[41,12,8,2],
[41,12,8,4],
[41,12,8,7],
[41,12,10,0],
[41,12,10,1],
[41,12,10,2],
[41,12,10,3],
[41,12,10,4],
[41,12,10,5],
[41,12,10,6],
[41,12,10,7],
[41,12,10,8],
[41,13,3,0],
[41,13,3,1],
[41,13,3,2],
[41,13,4,0],
[41,13,4,1],
[41,13,4,2],
[41,13,4,3],
[41,13,5,0],
[41,13,5,1],
[41,13,5,2],
[41,13,5,4],
[41,13,6,0],
[41,13,6,1],
[41,13,6,2],
[41,13,6,4],
[41,13,7,0],
[41,13,7,1],
[41,13,7,2],
[41,13,7,3],
[41,13,7,4],
[41,13,7,5],
[41,13,7,6],
[41,13,8,0],
[41,13,8,1],
[41,13,8,2],
[41,13,8,4],
[41,13,8,7],
[41,13,10,0],
[41,13,10,1],
[41,13,10,2],
[41,13,10,3],
[41,13,10,4],
[41,13,10,5],
[41,13,10,6],
[41,13,10,7],
[41,13,10,8],
[41,13,11,0],
[41,13,11,1],
[41,13,11,2],
[41,13,11,3],
[41,13,11,4],
[41,13,11,5],
[41,13,11,6],
[41,13,11,7],
[41,13,11,8],
[41,13,11,10],
[41,13,12,0],
[41,13,12,1],
[41,13,12,2],
[41,13,12,3],
[41,13,12,4],
[41,13,12,5],
[41,13,12,6],
[41,13,12,7],
[41,13,12,8],
[41,13,12,10],
[41,14,3,0],
[41,14,3,1],
[41,14,3,2],
[41,14,4,0],
[41,14,4,1],
[41,14,4,2],
[41,14,4,3],
[41,14,5,0],
[41,14,5,1],
[41,14,5,2],
[41,14,5,4],
[41,14,6,0],
[41,14,6,1],
[41,14,6,2],
[41,14,6,4],
[41,14,7,0],
[41,14,7,1],
[41,14,7,2],
[41,14,7,3],
[41,14,7,4],
[41,14,7,5],
[41,14,7,6],
[41,14,8,0],
[41,14,8,1],
[41,14,8,2],
[41,14,8,4],
[41,14,8,7],
[41,14,10,0],
[41,14,10,1],
[41,14,10,2],
[41,14,10,3],
[41,14,10,4],
[41,14,10,5],
[41,14,10,6],
[41,14,10,7],
[41,14,10,8],
[41,14,11,0],
[41,14,11,1],
[41,14,11,2],
[41,14,11,3],
[41,14,11,4],
[41,14,11,5],
[41,14,11,6],
[41,14,11,7],
[41,14,11,8],
[41,14,11,10],
[41,14,12,0],
[41,14,12,1],
[41,14,12,2],
[41,14,12,3],
[41,14,12,4],
[41,14,12,5],
[41,14,12,6],
[41,14,12,7],
[41,14,12,8],
[41,14,12,10],
[41,16,3,0],
[41,16,3,1],
[41,16,3,2],
[41,16,4,0],
[41,16,4,1],
[41,16,4,2],
[41,16,4,3],
[41,16,5,0],
[41,16,5,1],
[41,16,5,2],
[41,16,5,4],
[41,16,6,0],
[41,16,6,1],
[41,16,6,2],
[41,16,6,4],
[41,16,7,0],
[41,16,7,1],
[41,16,7,2],
[41,16,7,3],
[41,16,7,4],
[41,16,7,5],
[41,16,7,6],
[41,16,8,0],
[41,16,8,1],
[41,16,8,2],
[41,16,8,4],
[41,16,8,7],
[41,16,10,0],
[41,16,10,1],
[41,16,10,2],
[41,16,10,3],
[41,16,10,4],
[41,16,10,5],
[41,16,10,6],
[41,16,10,7],
[41,16,10,8],
[41,16,11,0],
[41,16,11,1],
[41,16,11,2],
[41,16,11,3],
[41,16,11,4],
[41,16,11,5],
[41,16,11,6],
[41,16,11,7],
[41,16,11,8],
[41,16,11,10],
[41,16,12,0],
[41,16,12,1],
[41,16,12,2],
[41,16,12,3],
[41,16,12,4],
[41,16,12,5],
[41,16,12,6],
[41,16,12,7],
[41,16,12,8],
[41,16,12,10],
[41,16,13,0],
[41,16,13,1],
[41,16,13,2],
[41,16,13,3],
[41,16,13,4],
[41,16,13,5],
[41,16,13,6],
[41,16,13,7],
[41,16,13,8],
[41,16,13,10],
[41,16,13,11],
[41,16,13,12],
[41,16,14,0],
[41,16,14,1],
[41,16,14,2],
[41,16,14,3],
[41,16,14,4],
[41,16,14,5],
[41,16,14,6],
[41,16,14,7],
[41,16,14,8],
[41,16,14,10],
[41,16,14,11],
[41,16,14,12],
[41,17,3,0],
[41,17,3,1],
[41,17,3,2],
[41,17,4,0],
[41,17,4,1],
[41,17,4,2],
[41,17,4,3],
[41,17,5,0],
[41,17,5,1],
[41,17,5,2],
[41,17,5,4],
[41,17,6,0],
[41,17,6,1],
[41,17,6,2],
[41,17,6,4],
[41,17,7,0],
[41,17,7,1],
[41,17,7,2],
[41,17,7,3],
[41,17,7,4],
[41,17,7,5],
[41,17,7,6],
[41,17,8,0],
[41,17,8,1],
[41,17,8,2],
[41,17,8,4],
[41,17,8,7],
[41,17,10,0],
[41,17,10,1],
[41,17,10,2],
[41,17,10,3],
[41,17,10,4],
[41,17,10,5],
[41,17,10,6],
[41,17,10,7],
[41,17,10,8],
[41,17,11,0],
[41,17,11,1],
[41,17,11,2],
[41,17,11,3],
[41,17,11,4],
[41,17,11,5],
[41,17,11,6],
[41,17,11,7],
[41,17,11,8],
[41,17,11,10],
[41,17,12,0],
[41,17,12,1],
[41,17,12,2],
[41,17,12,3],
[41,17,12,4],
[41,17,12,5],
[41,17,12,6],
[41,17,12,7],
[41,17,12,8],
[41,17,12,10],
[41,17,13,0],
[41,17,13,1],
[41,17,13,2],
[41,17,13,3],
[41,17,13,4],
[41,17,13,5],
[41,17,13,6],
[41,17,13,7],
[41,17,13,8],
[41,17,13,10],
[41,17,13,11],
[41,17,13,12],
[41,17,14,0],
[41,17,14,1],
[41,17,14,2],
[41,17,14,3],
[41,17,14,4],
[41,17,14,5],
[41,17,14,6],
[41,17,14,7],
[41,17,14,8],
[41,17,14,10],
[41,17,14,11],
[41,17,14,12],
[41,17,16,0],
[41,17,16,1],
[41,17,16,2],
[41,17,16,3],
[41,17,16,4],
[41,17,16,5],
[41,17,16,6],
[41,17,16,7],
[41,17,16,8],
[41,17,16,10],
[41,17,16,11],
[41,17,16,12],
[41,17,16,13],
[41,17,16,14],
[41,19,3,0],
[41,19,3,1],
[41,19,3,2],
[41,19,4,0],
[41,19,4,1],
[41,19,4,2],
[41,19,4,3],
[41,19,5,0],
[41,19,5,1],
[41,19,5,2],
[41,19,5,4],
[41,19,6,0],
[41,19,6,1],
[41,19,6,2],
[41,19,6,4],
[41,19,7,0],
[41,19,7,1],
[41,19,7,2],
[41,19,7,3],
[41,19,7,4],
[41,19,7,5],
[41,19,7,6],
[41,19,8,0],
[41,19,8,1],
[41,19,8,2],
[41,19,8,4],
[41,19,8,7],
[41,19,10,0],
[41,19,10,1],
[41,19,10,2],
[41,19,10,3],
[41,19,10,4],
[41,19,10,5],
[41,19,10,6],
[41,19,10,7],
[41,19,10,8],
[41,19,11,0],
[41,19,11,1],
[41,19,11,2],
[41,19,11,3],
[41,19,11,4],
[41,19,11,5],
[41,19,11,6],
[41,19,11,7],
[41,19,11,8],
[41,19,11,10],
[41,19,12,0],
[41,19,12,1],
[41,19,12,2],
[41,19,12,3],
[41,19,12,4],
[41,19,12,5],
[41,19,12,6],
[41,19,12,7],
[41,19,12,8],
[41,19,12,10],
[41,19,16,0],
[41,19,16,1],
[41,19,16,2],
[41,19,16,3],
[41,19,16,4],
[41,19,16,5],
[41,19,16,6],
[41,19,16,7],
[41,19,16,8],
[41,19,16,10],
[41,19,16,11],
[41,19,16,12],
[41,19,17,0],
[41,19,17,1],
[41,19,17,2],
[41,19,17,3],
[41,19,17,4],
[41,19,17,5],
[41,19,17,6],
[41,19,17,7],
[41,19,17,8],
[41,19,17,10],
[41,19,17,11],
[41,19,17,12],
[41,19,17,16],
[41,20,3,0],
[41,20,3,1],
[41,20,3,2],
[41,20,4,0],
[41,20,4,1],
[41,20,4,2],
[41,20,4,3],
[41,20,5,0],
[41,20,5,1],
[41,20,5,2],
[41,20,5,4],
[41,20,6,0],
[41,20,6,1],
[41,20,6,2],
[41,20,6,4],
[41,20,7,0],
[41,20,7,1],
[41,20,7,2],
[41,20,7,3],
[41,20,7,4],
[41,20,7,5],
[41,20,7,6],
[41,20,8,0],
[41,20,8,1],
[41,20,8,2],
[41,20,8,4],
[41,20,8,7],
[41,20,11,0],
[41,20,11,1],
[41,20,11,2],
[41,20,11,3],
[41,20,11,4],
[41,20,11,5],
[41,20,11,6],
[41,20,11,7],
[41,20,11,8],
[41,20,12,0],
[41,20,12,1],
[41,20,12,2],
[41,20,12,3],
[41,20,12,4],
[41,20,12,5],
[41,20,12,6],
[41,20,12,7],
[41,20,12,8],
[41,20,13,0],
[41,20,13,1],
[41,20,13,2],
[41,20,13,3],
[41,20,13,4],
[41,20,13,5],
[41,20,13,6],
[41,20,13,7],
[41,20,13,8],
[41,20,13,11],
[41,20,13,12],
[41,20,14,0],
[41,20,14,1],
[41,20,14,2],
[41,20,14,3],
[41,20,14,4],
[41,20,14,5],
[41,20,14,6],
[41,20,14,7],
[41,20,14,8],
[41,20,14,11],
[41,20,14,12],
[41,20,16,0],
[41,20,16,1],
[41,20,16,2],
[41,20,16,3],
[41,20,16,4],
[41,20,16,5],
[41,20,16,6],
[41,20,16,7],
[41,20,16,8],
[41,20,16,11],
[41,20,16,12],
[41,20,16,13],
[41,20,16,14],
[41,20,17,0],
[41,20,17,1],
[41,20,17,2],
[41,20,17,3],
[41,20,17,4],
[41,20,17,5],
[41,20,17,6],
[41,20,17,7],
[41,20,17,8],
[41,20,17,11],
[41,20,17,12],
[41,20,17,13],
[41,20,17,14],
[41,20,17,16],
[41,20,19,0],
[41,20,19,1],
[41,20,19,2],
[41,20,19,3],
[41,20,19,4],
[41,20,19,5],
[41,20,19,6],
[41,20,19,7],
[41,20,19,8],
[41,20,19,11],
[41,20,19,12],
[41,20,19,16],
[41,20,19,17],
[41,21,4,0],
[41,21,4,1],
[41,21,4,2],
[41,21,7,0],
[41,21,7,1],
[41,21,7,2],
[41,21,7,4],
[41,21,10,0],
[41,21,10,1],
[41,21,10,2],
[41,21,10,4],
[41,21,10,7],
[41,21,11,0],
[41,21,11,1],
[41,21,11,2],
[41,21,11,4],
[41,21,11,7],
[41,21,11,10],
[41,21,12,0],
[41,21,12,1],
[41,21,12,2],
[41,21,12,4],
[41,21,12,7],
[41,21,12,10],
[41,21,13,0],
[41,21,13,1],
[41,21,13,2],
[41,21,13,4],
[41,21,13,7],
[41,21,13,10],
[41,21,13,11],
[41,21,13,12],
[41,21,14,0],
[41,21,14,1],
[41,21,14,2],
[41,21,14,4],
[41,21,14,7],
[41,21,14,10],
[41,21,14,11],
[41,21,14,12],
[41,21,16,0],
[41,21,16,1],
[41,21,16,2],
[41,21,16,4],
[41,21,16,7],
[41,21,16,10],
[41,21,16,11],
[41,21,16,12],
[41,21,16,13],
[41,21,16,14],
[41,21,17,0],
[41,21,17,1],
[41,21,17,2],
[41,21,17,4],
[41,21,17,7],
[41,21,17,10],
[41,21,17,11],
[41,21,17,12],
[41,21,17,13],
[41,21,17,14],
[41,21,17,16],
[41,21,19,0],
[41,21,19,1],
[41,21,19,2],
[41,21,19,4],
[41,21,19,7],
[41,21,19,10],
[41,21,19,11],
[41,21,19,12],
[41,21,19,16],
[41,21,19,17],
[41,21,20,0],
[41,21,20,1],
[41,21,20,2],
[41,21,20,4],
[41,21,20,7],
[41,21,20,11],
[41,21,20,12],
[41,21,20,13],
[41,21,20,14],
[41,21,20,16],
[41,21,20,17],
[41,21,20,19],
[41,22,3,0],
[41,22,3,1],
[41,22,3,2],
[41,22,4,0],
[41,22,4,1],
[41,22,4,2],
[41,22,4,3],
[41,22,5,0],
[41,22,5,1],
[41,22,5,2],
[41,22,5,4],
[41,22,6,0],
[41,22,6,1],
[41,22,6,2],
[41,22,6,4],
[41,22,7,0],
[41,22,7,1],
[41,22,7,2],
[41,22,7,3],
[41,22,7,4],
[41,22,7,5],
[41,22,7,6],
[41,22,8,0],
[41,22,8,1],
[41,22,8,2],
[41,22,8,4],
[41,22,8,7],
[41,22,11,0],
[41,22,11,1],
[41,22,11,2],
[41,22,11,3],
[41,22,11,4],
[41,22,11,5],
[41,22,11,6],
[41,22,11,7],
[41,22,11,8],
[41,22,12,0],
[41,22,12,1],
[41,22,12,2],
[41,22,12,3],
[41,22,12,4],
[41,22,12,5],
[41,22,12,6],
[41,22,12,7],
[41,22,12,8],
[41,22,13,0],
[41,22,13,1],
[41,22,13,2],
[41,22,13,3],
[41,22,13,4],
[41,22,13,5],
[41,22,13,6],
[41,22,13,7],
[41,22,13,8],
[41,22,13,11],
[41,22,13,12],
[41,22,14,0],
[41,22,14,1],
[41,22,14,2],
[41,22,14,3],
[41,22,14,4],
[41,22,14,5],
[41,22,14,6],
[41,22,14,7],
[41,22,14,8],
[41,22,14,11],
[41,22,14,12],
[41,22,16,0],
[41,22,16,1],
[41,22,16,2],
[41,22,16,3],
[41,22,16,4],
[41,22,16,5],
[41,22,16,6],
[41,22,16,7],
[41,22,16,8],
[41,22,16,11],
[41,22,16,12],
[41,22,16,13],
[41,22,16,14],
[41,22,17,0],
[41,22,17,1],
[41,22,17,2],
[41,22,17,3],
[41,22,17,4],
[41,22,17,5],
[41,22,17,6],
[41,22,17,7],
[41,22,17,8],
[41,22,17,11],
[41,22,17,12],
[41,22,17,13],
[41,22,17,14],
[41,22,17,16],
[41,22,19,0],
[41,22,19,1],
[41,22,19,2],
[41,22,19,3],
[41,22,19,4],
[41,22,19,5],
[41,22,19,6],
[41,22,19,7],
[41,22,19,8],
[41,22,19,11],
[41,22,19,12],
[41,22,19,16],
[41,22,19,17],
[41,22,21,0],
[41,22,21,1],
[41,22,21,2],
[41,22,21,4],
[41,22,21,7],
[41,22,21,11],
[41,22,21,12],
[41,22,21,13],
[41,22,21,14],
[41,22,21,16],
[41,22,21,17],
[41,22,21,19],
[41,23,3,0],
[41,23,3,1],
[41,23,3,2],
[41,23,4,0],
[41,23,4,1],
[41,23,4,2],
[41,23,4,3],
[41,23,5,0],
[41,23,5,1],
[41,23,5,2],
[41,23,5,4],
[41,23,6,0],
[41,23,6,1],
[41,23,6,2],
[41,23,6,4],
[41,23,7,0],
[41,23,7,1],
[41,23,7,2],
[41,23,7,3],
[41,23,7,4],
[41,23,7,5],
[41,23,7,6],
[41,23,8,0],
[41,23,8,1],
[41,23,8,2],
[41,23,8,4],
[41,23,8,7],
[41,23,10,0],
[41,23,10,1],
[41,23,10,2],
[41,23,10,3],
[41,23,10,4],
[41,23,10,5],
[41,23,10,6],
[41,23,10,7],
[41,23,10,8],
[41,23,11,0],
[41,23,11,1],
[41,23,11,2],
[41,23,11,3],
[41,23,11,4],
[41,23,11,5],
[41,23,11,6],
[41,23,11,7],
[41,23,11,8],
[41,23,11,10],
[41,23,12,0],
[41,23,12,1],
[41,23,12,2],
[41,23,12,3],
[41,23,12,4],
[41,23,12,5],
[41,23,12,6],
[41,23,12,7],
[41,23,12,8],
[41,23,12,10],
[41,23,13,0],
[41,23,13,1],
[41,23,13,2],
[41,23,13,3],
[41,23,13,4],
[41,23,13,5],
[41,23,13,6],
[41,23,13,7],
[41,23,13,8],
[41,23,13,10],
[41,23,13,11],
[41,23,13,12],
[41,23,14,0],
[41,23,14,1],
[41,23,14,2],
[41,23,14,3],
[41,23,14,4],
[41,23,14,5],
[41,23,14,6],
[41,23,14,7],
[41,23,14,8],
[41,23,14,10],
[41,23,14,11],
[41,23,14,12],
[41,23,16,0],
[41,23,16,1],
[41,23,16,2],
[41,23,16,3],
[41,23,16,4],
[41,23,16,5],
[41,23,16,6],
[41,23,16,7],
[41,23,16,8],
[41,23,16,10],
[41,23,16,11],
[41,23,16,12],
[41,23,16,13],
[41,23,16,14],
[41,23,17,0],
[41,23,17,1],
[41,23,17,2],
[41,23,17,3],
[41,23,17,4],
[41,23,17,5],
[41,23,17,6],
[41,23,17,7],
[41,23,17,8],
[41,23,17,10],
[41,23,17,11],
[41,23,17,12],
[41,23,17,13],
[41,23,17,14],
[41,23,17,16],
[41,23,19,0],
[41,23,19,1],
[41,23,19,2],
[41,23,19,3],
[41,23,19,4],
[41,23,19,5],
[41,23,19,6],
[41,23,19,7],
[41,23,19,8],
[41,23,19,10],
[41,23,19,11],
[41,23,19,12],
[41,23,19,16],
[41,23,19,17],
[41,23,20,0],
[41,23,20,1],
[41,23,20,2],
[41,23,20,3],
[41,23,20,4],
[41,23,20,5],
[41,23,20,6],
[41,23,20,7],
[41,23,20,8],
[41,23,20,11],
[41,23,20,12],
[41,23,20,13],
[41,23,20,14],
[41,23,20,16],
[41,23,20,17],
[41,23,20,19],
[41,23,21,0],
[41,23,21,1],
[41,23,21,2],
[41,23,21,4],
[41,23,21,7],
[41,23,21,10],
[41,23,21,11],
[41,23,21,12],
[41,23,21,13],
[41,23,21,14],
[41,23,21,16],
[41,23,21,17],
[41,23,21,19],
[41,23,21,20],
[41,23,22,0],
[41,23,22,1],
[41,23,22,2],
[41,23,22,3],
[41,23,22,4],
[41,23,22,5],
[41,23,22,6],
[41,23,22,7],
[41,23,22,8],
[41,23,22,11],
[41,23,22,12],
[41,23,22,13],
[41,23,22,14],
[41,23,22,16],
[41,23,22,17],
[41,23,22,19],
[41,23,22,21],
[41,24,3,0],
[41,24,3,1],
[41,24,3,2],
[41,24,4,0],
[41,24,4,1],
[41,24,4,2],
[41,24,4,3],
[41,24,5,0],
[41,24,5,1],
[41,24,5,2],
[41,24,5,4],
[41,24,6,0],
[41,24,6,1],
[41,24,6,2],
[41,24,6,4],
[41,24,7,0],
[41,24,7,1],
[41,24,7,2],
[41,24,7,3],
[41,24,7,4],
[41,24,7,5],
[41,24,7,6],
[41,24,8,0],
[41,24,8,1],
[41,24,8,2],
[41,24,8,4],
[41,24,8,7],
[41,24,10,0],
[41,24,10,1],
[41,24,10,2],
[41,24,10,3],
[41,24,10,4],
[41,24,10,5],
[41,24,10,6],
[41,24,10,7],
[41,24,10,8],
[41,24,11,0],
[41,24,11,1],
[41,24,11,2],
[41,24,11,3],
[41,24,11,4],
[41,24,11,5],
[41,24,11,6],
[41,24,11,7],
[41,24,11,8],
[41,24,11,10],
[41,24,12,0],
[41,24,12,1],
[41,24,12,2],
[41,24,12,3],
[41,24,12,4],
[41,24,12,5],
[41,24,12,6],
[41,24,12,7],
[41,24,12,8],
[41,24,12,10],
[41,24,13,0],
[41,24,13,1],
[41,24,13,2],
[41,24,13,3],
[41,24,13,4],
[41,24,13,5],
[41,24,13,6],
[41,24,13,7],
[41,24,13,8],
[41,24,13,10],
[41,24,13,11],
[41,24,13,12],
[41,24,14,0],
[41,24,14,1],
[41,24,14,2],
[41,24,14,3],
[41,24,14,4],
[41,24,14,5],
[41,24,14,6],
[41,24,14,7],
[41,24,14,8],
[41,24,14,10],
[41,24,14,11],
[41,24,14,12],
[41,24,17,0],
[41,24,17,1],
[41,24,17,2],
[41,24,17,3],
[41,24,17,4],
[41,24,17,5],
[41,24,17,6],
[41,24,17,7],
[41,24,17,8],
[41,24,17,10],
[41,24,17,11],
[41,24,17,12],
[41,24,17,13],
[41,24,17,14],
[41,24,19,0],
[41,24,19,1],
[41,24,19,2],
[41,24,19,3],
[41,24,19,4],
[41,24,19,5],
[41,24,19,6],
[41,24,19,7],
[41,24,19,8],
[41,24,19,10],
[41,24,19,11],
[41,24,19,12],
[41,24,19,17],
[41,24,20,0],
[41,24,20,1],
[41,24,20,2],
[41,24,20,3],
[41,24,20,4],
[41,24,20,5],
[41,24,20,6],
[41,24,20,7],
[41,24,20,8],
[41,24,20,11],
[41,24,20,12],
[41,24,20,13],
[41,24,20,14],
[41,24,20,17],
[41,24,20,19],
[41,24,21,0],
[41,24,21,1],
[41,24,21,2],
[41,24,21,4],
[41,24,21,7],
[41,24,21,10],
[41,24,21,11],
[41,24,21,12],
[41,24,21,13],
[41,24,21,14],
[41,24,21,17],
[41,24,21,19],
[41,24,21,20],
[41,24,22,0],
[41,24,22,1],
[41,24,22,2],
[41,24,22,3],
[41,24,22,4],
[41,24,22,5],
[41,24,22,6],
[41,24,22,7],
[41,24,22,8],
[41,24,22,11],
[41,24,22,12],
[41,24,22,13],
[41,24,22,14],
[41,24,22,17],
[41,24,22,19],
[41,24,22,21],
[41,24,23,0],
[41,24,23,1],
[41,24,23,2],
[41,24,23,3],
[41,24,23,4],
[41,24,23,5],
[41,24,23,6],
[41,24,23,7],
[41,24,23,8],
[41,24,23,10],
[41,24,23,11],
[41,24,23,12],
[41,24,23,13],
[41,24,23,14],
[41,24,23,17],
[41,24,23,19],
[41,24,23,20],
[41,24,23,21],
[41,24,23,22],
[41,25,3,0],
[41,25,3,1],
[41,25,3,2],
[41,25,4,0],
[41,25,4,1],
[41,25,4,2],
[41,25,4,3],
[41,25,5,0],
[41,25,5,1],
[41,25,5,2],
[41,25,5,4],
[41,25,6,0],
[41,25,6,1],
[41,25,6,2],
[41,25,6,4],
[41,25,8,0],
[41,25,8,1],
[41,25,8,2],
[41,25,8,4],
[41,25,10,0],
[41,25,10,1],
[41,25,10,2],
[41,25,10,3],
[41,25,10,4],
[41,25,10,5],
[41,25,10,6],
[41,25,10,8],
[41,25,11,0],
[41,25,11,1],
[41,25,11,2],
[41,25,11,3],
[41,25,11,4],
[41,25,11,5],
[41,25,11,6],
[41,25,11,8],
[41,25,11,10],
[41,25,12,0],
[41,25,12,1],
[41,25,12,2],
[41,25,12,3],
[41,25,12,4],
[41,25,12,5],
[41,25,12,6],
[41,25,12,8],
[41,25,12,10],
[41,25,13,0],
[41,25,13,1],
[41,25,13,2],
[41,25,13,3],
[41,25,13,4],
[41,25,13,5],
[41,25,13,6],
[41,25,13,8],
[41,25,13,10],
[41,25,13,11],
[41,25,13,12],
[41,25,14,0],
[41,25,14,1],
[41,25,14,2],
[41,25,14,3],
[41,25,14,4],
[41,25,14,5],
[41,25,14,6],
[41,25,14,8],
[41,25,14,10],
[41,25,14,11],
[41,25,14,12],
[41,25,16,0],
[41,25,16,1],
[41,25,16,2],
[41,25,16,3],
[41,25,16,4],
[41,25,16,5],
[41,25,16,6],
[41,25,16,8],
[41,25,16,10],
[41,25,16,11],
[41,25,16,12],
[41,25,16,13],
[41,25,16,14],
[41,25,17,0],
[41,25,17,1],
[41,25,17,2],
[41,25,17,3],
[41,25,17,4],
[41,25,17,5],
[41,25,17,6],
[41,25,17,8],
[41,25,17,10],
[41,25,17,11],
[41,25,17,12],
[41,25,17,13],
[41,25,17,14],
[41,25,17,16],
[41,25,19,0],
[41,25,19,1],
[41,25,19,2],
[41,25,19,3],
[41,25,19,4],
[41,25,19,5],
[41,25,19,6],
[41,25,19,8],
[41,25,19,10],
[41,25,19,11],
[41,25,19,12],
[41,25,19,16],
[41,25,19,17],
[41,25,20,0],
[41,25,20,1],
[41,25,20,2],
[41,25,20,3],
[41,25,20,4],
[41,25,20,5],
[41,25,20,6],
[41,25,20,8],
[41,25,20,11],
[41,25,20,12],
[41,25,20,13],
[41,25,20,14],
[41,25,20,16],
[41,25,20,17],
[41,25,20,19],
[41,25,21,0],
[41,25,21,1],
[41,25,21,2],
[41,25,21,4],
[41,25,21,10],
[41,25,21,11],
[41,25,21,12],
[41,25,21,13],
[41,25,21,14],
[41,25,21,16],
[41,25,21,17],
[41,25,21,19],
[41,25,21,20],
[41,25,22,0],
[41,25,22,1],
[41,25,22,2],
[41,25,22,3],
[41,25,22,4],
[41,25,22,5],
[41,25,22,6],
[41,25,22,8],
[41,25,22,11],
[41,25,22,12],
[41,25,22,13],
[41,25,22,14],
[41,25,22,16],
[41,25,22,17],
[41,25,22,19],
[41,25,22,21],
[41,25,23,0],
[41,25,23,1],
[41,25,23,2],
[41,25,23,3],
[41,25,23,4],
[41,25,23,5],
[41,25,23,6],
[41,25,23,8],
[41,25,23,10],
[41,25,23,11],
[41,25,23,12],
[41,25,23,13],
[41,25,23,14],
[41,25,23,16],
[41,25,23,17],
[41,25,23,19],
[41,25,23,20],
[41,25,23,21],
[41,25,23,22],
[41,25,24,0],
[41,25,24,1],
[41,25,24,2],
[41,25,24,3],
[41,25,24,4],
[41,25,24,5],
[41,25,24,6],
[41,25,24,8],
[41,25,24,10],
[41,25,24,11],
[41,25,24,12],
[41,25,24,13],
[41,25,24,14],
[41,25,24,17],
[41,25,24,19],
[41,25,24,20],
[41,25,24,21],
[41,25,24,22],
[41,25,24,23],
[41,27,3,0],
[41,27,3,1],
[41,27,3,2],
[41,27,4,0],
[41,27,4,1],
[41,27,4,2],
[41,27,4,3],
[41,27,5,0],
[41,27,5,1],
[41,27,5,2],
[41,27,5,4],
[41,27,6,0],
[41,27,6,1],
[41,27,6,2],
[41,27,6,4],
[41,27,7,0],
[41,27,7,1],
[41,27,7,2],
[41,27,7,3],
[41,27,7,4],
[41,27,7,5],
[41,27,7,6],
[41,27,8,0],
[41,27,8,1],
[41,27,8,2],
[41,27,8,4],
[41,27,8,7],
[41,27,11,0],
[41,27,11,1],
[41,27,11,2],
[41,27,11,3],
[41,27,11,4],
[41,27,11,5],
[41,27,11,6],
[41,27,11,7],
[41,27,11,8],
[41,27,12,0],
[41,27,12,1],
[41,27,12,2],
[41,27,12,3],
[41,27,12,4],
[41,27,12,5],
[41,27,12,6],
[41,27,12,7],
[41,27,12,8],
[41,27,13,0],
[41,27,13,1],
[41,27,13,2],
[41,27,13,3],
[41,27,13,4],
[41,27,13,5],
[41,27,13,6],
[41,27,13,7],
[41,27,13,8],
[41,27,13,11],
[41,27,13,12],
[41,27,14,0],
[41,27,14,1],
[41,27,14,2],
[41,27,14,3],
[41,27,14,4],
[41,27,14,5],
[41,27,14,6],
[41,27,14,7],
[41,27,14,8],
[41,27,14,11],
[41,27,14,12],
[41,27,16,0],
[41,27,16,1],
[41,27,16,2],
[41,27,16,3],
[41,27,16,4],
[41,27,16,5],
[41,27,16,6],
[41,27,16,7],
[41,27,16,8],
[41,27,16,11],
[41,27,16,12],
[41,27,16,13],
[41,27,16,14],
[41,27,17,0],
[41,27,17,1],
[41,27,17,2],
[41,27,17,3],
[41,27,17,4],
[41,27,17,5],
[41,27,17,6],
[41,27,17,7],
[41,27,17,8],
[41,27,17,11],
[41,27,17,12],
[41,27,17,13],
[41,27,17,14],
[41,27,17,16],
[41,27,19,0],
[41,27,19,1],
[41,27,19,2],
[41,27,19,3],
[41,27,19,4],
[41,27,19,5],
[41,27,19,6],
[41,27,19,7],
[41,27,19,8],
[41,27,19,11],
[41,27,19,12],
[41,27,19,16],
[41,27,19,17],
[41,27,21,0],
[41,27,21,1],
[41,27,21,2],
[41,27,21,4],
[41,27,21,7],
[41,27,21,11],
[41,27,21,12],
[41,27,21,13],
[41,27,21,14],
[41,27,21,16],
[41,27,21,17],
[41,27,21,19],
[41,27,23,0],
[41,27,23,1],
[41,27,23,2],
[41,27,23,3],
[41,27,23,4],
[41,27,23,5],
[41,27,23,6],
[41,27,23,7],
[41,27,23,8],
[41,27,23,11],
[41,27,23,12],
[41,27,23,13],
[41,27,23,14],
[41,27,23,16],
[41,27,23,17],
[41,27,23,19],
[41,27,23,21],
[41,27,24,0],
[41,27,24,1],
[41,27,24,2],
[41,27,24,3],
[41,27,24,4],
[41,27,24,5],
[41,27,24,6],
[41,27,24,7],
[41,27,24,8],
[41,27,24,11],
[41,27,24,12],
[41,27,24,13],
[41,27,24,14],
[41,27,24,17],
[41,27,24,19],
[41,27,24,21],
[41,27,24,23],
[41,27,25,0],
[41,27,25,1],
[41,27,25,2],
[41,27,25,3],
[41,27,25,4],
[41,27,25,5],
[41,27,25,6],
[41,27,25,8],
[41,27,25,11],
[41,27,25,12],
[41,27,25,13],
[41,27,25,14],
[41,27,25,16],
[41,27,25,17],
[41,27,25,19],
[41,27,25,21],
[41,27,25,23],
[41,27,25,24],
[41,28,3,0],
[41,28,3,1],
[41,28,3,2],
[41,28,4,0],
[41,28,4,1],
[41,28,4,2],
[41,28,4,3],
[41,28,5,0],
[41,28,5,1],
[41,28,5,2],
[41,28,5,4],
[41,28,6,0],
[41,28,6,1],
[41,28,6,2],
[41,28,6,4],
[41,28,8,0],
[41,28,8,1],
[41,28,8,2],
[41,28,8,4],
[41,28,10,0],
[41,28,10,1],
[41,28,10,2],
[41,28,10,3],
[41,28,10,4],
[41,28,10,5],
[41,28,10,6],
[41,28,10,8],
[41,28,11,0],
[41,28,11,1],
[41,28,11,2],
[41,28,11,3],
[41,28,11,4],
[41,28,11,5],
[41,28,11,6],
[41,28,11,8],
[41,28,11,10],
[41,28,12,0],
[41,28,12,1],
[41,28,12,2],
[41,28,12,3],
[41,28,12,4],
[41,28,12,5],
[41,28,12,6],
[41,28,12,8],
[41,28,12,10],
[41,28,13,0],
[41,28,13,1],
[41,28,13,2],
[41,28,13,3],
[41,28,13,4],
[41,28,13,5],
[41,28,13,6],
[41,28,13,8],
[41,28,13,10],
[41,28,13,11],
[41,28,13,12],
[41,28,14,0],
[41,28,14,1],
[41,28,14,2],
[41,28,14,3],
[41,28,14,4],
[41,28,14,5],
[41,28,14,6],
[41,28,14,8],
[41,28,14,10],
[41,28,14,11],
[41,28,14,12],
[41,28,16,0],
[41,28,16,1],
[41,28,16,2],
[41,28,16,3],
[41,28,16,4],
[41,28,16,5],
[41,28,16,6],
[41,28,16,8],
[41,28,16,10],
[41,28,16,11],
[41,28,16,12],
[41,28,16,13],
[41,28,16,14],
[41,28,17,0],
[41,28,17,1],
[41,28,17,2],
[41,28,17,3],
[41,28,17,4],
[41,28,17,5],
[41,28,17,6],
[41,28,17,8],
[41,28,17,10],
[41,28,17,11],
[41,28,17,12],
[41,28,17,13],
[41,28,17,14],
[41,28,17,16],
[41,28,19,0],
[41,28,19,1],
[41,28,19,2],
[41,28,19,3],
[41,28,19,4],
[41,28,19,5],
[41,28,19,6],
[41,28,19,8],
[41,28,19,10],
[41,28,19,11],
[41,28,19,12],
[41,28,19,16],
[41,28,19,17],
[41,28,20,0],
[41,28,20,1],
[41,28,20,2],
[41,28,20,3],
[41,28,20,4],
[41,28,20,5],
[41,28,20,6],
[41,28,20,8],
[41,28,20,11],
[41,28,20,12],
[41,28,20,13],
[41,28,20,14],
[41,28,20,16],
[41,28,20,17],
[41,28,20,19],
[41,28,21,0],
[41,28,21,1],
[41,28,21,2],
[41,28,21,4],
[41,28,21,10],
[41,28,21,11],
[41,28,21,12],
[41,28,21,13],
[41,28,21,14],
[41,28,21,16],
[41,28,21,17],
[41,28,21,19],
[41,28,21,20],
[41,28,22,0],
[41,28,22,1],
[41,28,22,2],
[41,28,22,3],
[41,28,22,4],
[41,28,22,5],
[41,28,22,6],
[41,28,22,8],
[41,28,22,11],
[41,28,22,12],
[41,28,22,13],
[41,28,22,14],
[41,28,22,16],
[41,28,22,17],
[41,28,22,19],
[41,28,22,21],
[41,28,23,0],
[41,28,23,1],
[41,28,23,2],
[41,28,23,3],
[41,28,23,4],
[41,28,23,5],
[41,28,23,6],
[41,28,23,8],
[41,28,23,10],
[41,28,23,11],
[41,28,23,12],
[41,28,23,13],
[41,28,23,14],
[41,28,23,16],
[41,28,23,17],
[41,28,23,19],
[41,28,23,20],
[41,28,23,21],
[41,28,23,22],
[41,28,24,0],
[41,28,24,1],
[41,28,24,2],
[41,28,24,3],
[41,28,24,4],
[41,28,24,5],
[41,28,24,6],
[41,28,24,8],
[41,28,24,10],
[41,28,24,11],
[41,28,24,12],
[41,28,24,13],
[41,28,24,14],
[41,28,24,17],
[41,28,24,19],
[41,28,24,20],
[41,28,24,21],
[41,28,24,22],
[41,28,24,23],
[41,28,27,0],
[41,28,27,1],
[41,28,27,2],
[41,28,27,3],
[41,28,27,4],
[41,28,27,5],
[41,28,27,6],
[41,28,27,8],
[41,28,27,11],
[41,28,27,12],
[41,28,27,13],
[41,28,27,14],
[41,28,27,16],
[41,28,27,17],
[41,28,27,19],
[41,28,27,21],
[41,28,27,23],
[41,28,27,24],
[41,29,3,0],
[41,29,3,1],
[41,29,3,2],
[41,29,4,0],
[41,29,4,1],
[41,29,4,2],
[41,29,4,3],
[41,29,5,0],
[41,29,5,1],
[41,29,5,2],
[41,29,5,4],
[41,29,6,0],
[41,29,6,1],
[41,29,6,2],
[41,29,6,4],
[41,29,7,0],
[41,29,7,1],
[41,29,7,2],
[41,29,7,3],
[41,29,7,4],
[41,29,7,5],
[41,29,7,6],
[41,29,8,0],
[41,29,8,1],
[41,29,8,2],
[41,29,8,4],
[41,29,8,7],
[41,29,10,0],
[41,29,10,1],
[41,29,10,2],
[41,29,10,3],
[41,29,10,4],
[41,29,10,5],
[41,29,10,6],
[41,29,10,7],
[41,29,10,8],
[41,29,13,0],
[41,29,13,1],
[41,29,13,2],
[41,29,13,3],
[41,29,13,4],
[41,29,13,5],
[41,29,13,6],
[41,29,13,7],
[41,29,13,8],
[41,29,13,10],
[41,29,14,0],
[41,29,14,1],
[41,29,14,2],
[41,29,14,3],
[41,29,14,4],
[41,29,14,5],
[41,29,14,6],
[41,29,14,7],
[41,29,14,8],
[41,29,14,10],
[41,29,16,0],
[41,29,16,1],
[41,29,16,2],
[41,29,16,3],
[41,29,16,4],
[41,29,16,5],
[41,29,16,6],
[41,29,16,7],
[41,29,16,8],
[41,29,16,10],
[41,29,16,13],
[41,29,16,14],
[41,29,17,0],
[41,29,17,1],
[41,29,17,2],
[41,29,17,3],
[41,29,17,4],
[41,29,17,5],
[41,29,17,6],
[41,29,17,7],
[41,29,17,8],
[41,29,17,10],
[41,29,17,13],
[41,29,17,14],
[41,29,17,16],
[41,29,19,0],
[41,29,19,1],
[41,29,19,2],
[41,29,19,3],
[41,29,19,4],
[41,29,19,5],
[41,29,19,6],
[41,29,19,7],
[41,29,19,8],
[41,29,19,10],
[41,29,19,16],
[41,29,19,17],
[41,29,20,0],
[41,29,20,1],
[41,29,20,2],
[41,29,20,3],
[41,29,20,4],
[41,29,20,5],
[41,29,20,6],
[41,29,20,7],
[41,29,20,8],
[41,29,20,13],
[41,29,20,14],
[41,29,20,16],
[41,29,20,17],
[41,29,20,19],
[41,29,21,0],
[41,29,21,1],
[41,29,21,2],
[41,29,21,4],
[41,29,21,7],
[41,29,21,10],
[41,29,21,13],
[41,29,21,14],
[41,29,21,16],
[41,29,21,17],
[41,29,21,19],
[41,29,21,20],
[41,29,22,0],
[41,29,22,1],
[41,29,22,2],
[41,29,22,3],
[41,29,22,4],
[41,29,22,5],
[41,29,22,6],
[41,29,22,7],
[41,29,22,8],
[41,29,22,13],
[41,29,22,14],
[41,29,22,16],
[41,29,22,17],
[41,29,22,19],
[41,29,22,21],
[41,29,23,0],
[41,29,23,1],
[41,29,23,2],
[41,29,23,3],
[41,29,23,4],
[41,29,23,5],
[41,29,23,6],
[41,29,23,7],
[41,29,23,8],
[41,29,23,10],
[41,29,23,13],
[41,29,23,14],
[41,29,23,16],
[41,29,23,17],
[41,29,23,19],
[41,29,23,20],
[41,29,23,21],
[41,29,23,22],
[41,29,24,0],
[41,29,24,1],
[41,29,24,2],
[41,29,24,3],
[41,29,24,4],
[41,29,24,5],
[41,29,24,6],
[41,29,24,7],
[41,29,24,8],
[41,29,24,10],
[41,29,24,13],
[41,29,24,14],
[41,29,24,17],
[41,29,24,19],
[41,29,24,20],
[41,29,24,21],
[41,29,24,22],
[41,29,24,23],
[41,29,25,0],
[41,29,25,1],
[41,29,25,2],
[41,29,25,3],
[41,29,25,4],
[41,29,25,5],
[41,29,25,6],
[41,29,25,8],
[41,29,25,10],
[41,29,25,13],
[41,29,25,14],
[41,29,25,16],
[41,29,25,17],
[41,29,25,19],
[41,29,25,20],
[41,29,25,21],
[41,29,25,22],
[41,29,25,23],
[41,29,25,24],
[41,29,27,0],
[41,29,27,1],
[41,29,27,2],
[41,29,27,3],
[41,29,27,4],
[41,29,27,5],
[41,29,27,6],
[41,29,27,7],
[41,29,27,8],
[41,29,27,13],
[41,29,27,14],
[41,29,27,16],
[41,29,27,17],
[41,29,27,19],
[41,29,27,21],
[41,29,27,23],
[41,29,27,24],
[41,29,27,25],
[41,29,28,0],
[41,29,28,1],
[41,29,28,2],
[41,29,28,3],
[41,29,28,4],
[41,29,28,5],
[41,29,28,6],
[41,29,28,8],
[41,29,28,10],
[41,29,28,13],
[41,29,28,14],
[41,29,28,16],
[41,29,28,17],
[41,29,28,19],
[41,29,28,20],
[41,29,28,21],
[41,29,28,22],
[41,29,28,23],
[41,29,28,24],
[41,29,28,27],
[41,30,3,0],
[41,30,3,1],
[41,30,3,2],
[41,30,4,0],
[41,30,4,1],
[41,30,4,2],
[41,30,4,3],
[41,30,5,0],
[41,30,5,1],
[41,30,5,2],
[41,30,5,4],
[41,30,6,0],
[41,30,6,1],
[41,30,6,2],
[41,30,6,4],
[41,30,7,0],
[41,30,7,1],
[41,30,7,2],
[41,30,7,3],
[41,30,7,4],
[41,30,7,5],
[41,30,7,6],
[41,30,8,0],
[41,30,8,1],
[41,30,8,2],
[41,30,8,4],
[41,30,8,7],
[41,30,10,0],
[41,30,10,1],
[41,30,10,2],
[41,30,10,3],
[41,30,10,4],
[41,30,10,5],
[41,30,10,6],
[41,30,10,7],
[41,30,10,8],
[41,30,11,0],
[41,30,11,1],
[41,30,11,2],
[41,30,11,3],
[41,30,11,4],
[41,30,11,5],
[41,30,11,6],
[41,30,11,7],
[41,30,11,8],
[41,30,11,10],
[41,30,12,0],
[41,30,12,1],
[41,30,12,2],
[41,30,12,3],
[41,30,12,4],
[41,30,12,5],
[41,30,12,6],
[41,30,12,7],
[41,30,12,8],
[41,30,12,10],
[41,30,13,0],
[41,30,13,1],
[41,30,13,2],
[41,30,13,3],
[41,30,13,4],
[41,30,13,5],
[41,30,13,6],
[41,30,13,7],
[41,30,13,8],
[41,30,13,10],
[41,30,13,11],
[41,30,13,12],
[41,30,14,0],
[41,30,14,1],
[41,30,14,2],
[41,30,14,3],
[41,30,14,4],
[41,30,14,5],
[41,30,14,6],
[41,30,14,7],
[41,30,14,8],
[41,30,14,10],
[41,30,14,11],
[41,30,14,12],
[41,30,16,0],
[41,30,16,1],
[41,30,16,2],
[41,30,16,3],
[41,30,16,4],
[41,30,16,5],
[41,30,16,6],
[41,30,16,7],
[41,30,16,8],
[41,30,16,10],
[41,30,16,11],
[41,30,16,12],
[41,30,16,13],
[41,30,16,14],
[41,30,17,0],
[41,30,17,1],
[41,30,17,2],
[41,30,17,3],
[41,30,17,4],
[41,30,17,5],
[41,30,17,6],
[41,30,17,7],
[41,30,17,8],
[41,30,17,10],
[41,30,17,11],
[41,30,17,12],
[41,30,17,13],
[41,30,17,14],
[41,30,17,16],
[41,30,19,0],
[41,30,19,1],
[41,30,19,2],
[41,30,19,3],
[41,30,19,4],
[41,30,19,5],
[41,30,19,6],
[41,30,19,7],
[41,30,19,8],
[41,30,19,10],
[41,30,19,11],
[41,30,19,12],
[41,30,19,16],
[41,30,19,17],
[41,30,20,0],
[41,30,20,1],
[41,30,20,2],
[41,30,20,3],
[41,30,20,4],
[41,30,20,5],
[41,30,20,6],
[41,30,20,7],
[41,30,20,8],
[41,30,20,11],
[41,30,20,12],
[41,30,20,13],
[41,30,20,14],
[41,30,20,16],
[41,30,20,17],
[41,30,20,19],
[41,30,21,0],
[41,30,21,1],
[41,30,21,2],
[41,30,21,4],
[41,30,21,7],
[41,30,21,10],
[41,30,21,11],
[41,30,21,12],
[41,30,21,13],
[41,30,21,14],
[41,30,21,16],
[41,30,21,17],
[41,30,21,19],
[41,30,21,20],
[41,30,22,0],
[41,30,22,1],
[41,30,22,2],
[41,30,22,3],
[41,30,22,4],
[41,30,22,5],
[41,30,22,6],
[41,30,22,7],
[41,30,22,8],
[41,30,22,11],
[41,30,22,12],
[41,30,22,13],
[41,30,22,14],
[41,30,22,16],
[41,30,22,17],
[41,30,22,19],
[41,30,22,21],
[41,30,24,0],
[41,30,24,1],
[41,30,24,2],
[41,30,24,3],
[41,30,24,4],
[41,30,24,5],
[41,30,24,6],
[41,30,24,7],
[41,30,24,8],
[41,30,24,10],
[41,30,24,11],
[41,30,24,12],
[41,30,24,13],
[41,30,24,14],
[41,30,24,17],
[41,30,24,19],
[41,30,24,20],
[41,30,24,21],
[41,30,24,22],
[41,30,25,0],
[41,30,25,1],
[41,30,25,2],
[41,30,25,3],
[41,30,25,4],
[41,30,25,5],
[41,30,25,6],
[41,30,25,8],
[41,30,25,10],
[41,30,25,11],
[41,30,25,12],
[41,30,25,13],
[41,30,25,14],
[41,30,25,16],
[41,30,25,17],
[41,30,25,19],
[41,30,25,20],
[41,30,25,21],
[41,30,25,22],
[41,30,25,24],
[41,30,27,0],
[41,30,27,1],
[41,30,27,2],
[41,30,27,3],
[41,30,27,4],
[41,30,27,5],
[41,30,27,6],
[41,30,27,7],
[41,30,27,8],
[41,30,27,11],
[41,30,27,12],
[41,30,27,13],
[41,30,27,14],
[41,30,27,16],
[41,30,27,17],
[41,30,27,19],
[41,30,27,21],
[41,30,27,24],
[41,30,27,25],
[41,30,28,0],
[41,30,28,1],
[41,30,28,2],
[41,30,28,3],
[41,30,28,4],
[41,30,28,5],
[41,30,28,6],
[41,30,28,8],
[41,30,28,10],
[41,30,28,11],
[41,30,28,12],
[41,30,28,13],
[41,30,28,14],
[41,30,28,16],
[41,30,28,17],
[41,30,28,19],
[41,30,28,20],
[41,30,28,21],
[41,30,28,22],
[41,30,28,24],
[41,30,28,27],
[41,30,29,0],
[41,30,29,1],
[41,30,29,2],
[41,30,29,3],
[41,30,29,4],
[41,30,29,5],
[41,30,29,6],
[41,30,29,7],
[41,30,29,8],
[41,30,29,10],
[41,30,29,13],
[41,30,29,14],
[41,30,29,16],
[41,30,29,17],
[41,30,29,19],
[41,30,29,20],
[41,30,29,21],
[41,30,29,22],
[41,30,29,24],
[41,30,29,25],
[41,30,29,27],
[41,31,3,0],
[41,31,3,1],
[41,31,3,2],
[41,31,4,0],
[41,31,4,1],
[41,31,4,2],
[41,31,4,3],
[41,31,5,0],
[41,31,5,1],
[41,31,5,2],
[41,31,5,4],
[41,31,6,0],
[41,31,6,1],
[41,31,6,2],
[41,31,6,4],
[41,31,7,0],
[41,31,7,1],
[41,31,7,2],
[41,31,7,3],
[41,31,7,4],
[41,31,7,5],
[41,31,7,6],
[41,31,8,0],
[41,31,8,1],
[41,31,8,2],
[41,31,8,4],
[41,31,8,7],
[41,31,10,0],
[41,31,10,1],
[41,31,10,2],
[41,31,10,3],
[41,31,10,4],
[41,31,10,5],
[41,31,10,6],
[41,31,10,7],
[41,31,10,8],
[41,31,11,0],
[41,31,11,1],
[41,31,11,2],
[41,31,11,3],
[41,31,11,4],
[41,31,11,5],
[41,31,11,6],
[41,31,11,7],
[41,31,11,8],
[41,31,11,10],
[41,31,12,0],
[41,31,12,1],
[41,31,12,2],
[41,31,12,3],
[41,31,12,4],
[41,31,12,5],
[41,31,12,6],
[41,31,12,7],
[41,31,12,8],
[41,31,12,10],
[41,31,13,0],
[41,31,13,1],
[41,31,13,2],
[41,31,13,3],
[41,31,13,4],
[41,31,13,5],
[41,31,13,6],
[41,31,13,7],
[41,31,13,8],
[41,31,13,10],
[41,31,13,11],
[41,31,13,12],
[41,31,14,0],
[41,31,14,1],
[41,31,14,2],
[41,31,14,3],
[41,31,14,4],
[41,31,14,5],
[41,31,14,6],
[41,31,14,7],
[41,31,14,8],
[41,31,14,10],
[41,31,14,11],
[41,31,14,12],
[41,31,16,0],
[41,31,16,1],
[41,31,16,2],
[41,31,16,3],
[41,31,16,4],
[41,31,16,5],
[41,31,16,6],
[41,31,16,7],
[41,31,16,8],
[41,31,16,10],
[41,31,16,11],
[41,31,16,12],
[41,31,16,13],
[41,31,16,14],
[41,31,17,0],
[41,31,17,1],
[41,31,17,2],
[41,31,17,3],
[41,31,17,4],
[41,31,17,5],
[41,31,17,6],
[41,31,17,7],
[41,31,17,8],
[41,31,17,10],
[41,31,17,11],
[41,31,17,12],
[41,31,17,13],
[41,31,17,14],
[41,31,17,16],
[41,31,19,0],
[41,31,19,1],
[41,31,19,2],
[41,31,19,3],
[41,31,19,4],
[41,31,19,5],
[41,31,19,6],
[41,31,19,7],
[41,31,19,8],
[41,31,19,10],
[41,31,19,11],
[41,31,19,12],
[41,31,19,16],
[41,31,19,17],
[41,31,20,0],
[41,31,20,1],
[41,31,20,2],
[41,31,20,3],
[41,31,20,4],
[41,31,20,5],
[41,31,20,6],
[41,31,20,7],
[41,31,20,8],
[41,31,20,11],
[41,31,20,12],
[41,31,20,13],
[41,31,20,14],
[41,31,20,16],
[41,31,20,17],
[41,31,20,19],
[41,31,21,0],
[41,31,21,1],
[41,31,21,2],
[41,31,21,4],
[41,31,21,7],
[41,31,21,10],
[41,31,21,11],
[41,31,21,12],
[41,31,21,13],
[41,31,21,14],
[41,31,21,16],
[41,31,21,17],
[41,31,21,19],
[41,31,21,20],
[41,31,22,0],
[41,31,22,1],
[41,31,22,2],
[41,31,22,3],
[41,31,22,4],
[41,31,22,5],
[41,31,22,6],
[41,31,22,7],
[41,31,22,8],
[41,31,22,11],
[41,31,22,12],
[41,31,22,13],
[41,31,22,14],
[41,31,22,16],
[41,31,22,17],
[41,31,22,19],
[41,31,22,21],
[41,31,24,0],
[41,31,24,1],
[41,31,24,2],
[41,31,24,3],
[41,31,24,4],
[41,31,24,5],
[41,31,24,6],
[41,31,24,7],
[41,31,24,8],
[41,31,24,10],
[41,31,24,11],
[41,31,24,12],
[41,31,24,13],
[41,31,24,14],
[41,31,24,17],
[41,31,24,19],
[41,31,24,20],
[41,31,24,21],
[41,31,24,22],
[41,31,25,0],
[41,31,25,1],
[41,31,25,2],
[41,31,25,3],
[41,31,25,4],
[41,31,25,5],
[41,31,25,6],
[41,31,25,8],
[41,31,25,10],
[41,31,25,11],
[41,31,25,12],
[41,31,25,13],
[41,31,25,14],
[41,31,25,16],
[41,31,25,17],
[41,31,25,19],
[41,31,25,20],
[41,31,25,21],
[41,31,25,22],
[41,31,25,24],
[41,31,27,0],
[41,31,27,1],
[41,31,27,2],
[41,31,27,3],
[41,31,27,4],
[41,31,27,5],
[41,31,27,6],
[41,31,27,7],
[41,31,27,8],
[41,31,27,11],
[41,31,27,12],
[41,31,27,13],
[41,31,27,14],
[41,31,27,16],
[41,31,27,17],
[41,31,27,19],
[41,31,27,21],
[41,31,27,24],
[41,31,27,25],
[41,31,28,0],
[41,31,28,1],
[41,31,28,2],
[41,31,28,3],
[41,31,28,4],
[41,31,28,5],
[41,31,28,6],
[41,31,28,8],
[41,31,28,10],
[41,31,28,11],
[41,31,28,12],
[41,31,28,13],
[41,31,28,14],
[41,31,28,16],
[41,31,28,17],
[41,31,28,19],
[41,31,28,20],
[41,31,28,21],
[41,31,28,22],
[41,31,28,24],
[41,31,28,27],
[41,31,29,0],
[41,31,29,1],
[41,31,29,2],
[41,31,29,3],
[41,31,29,4],
[41,31,29,5],
[41,31,29,6],
[41,31,29,7],
[41,31,29,8],
[41,31,29,10],
[41,31,29,13],
[41,31,29,14],
[41,31,29,16],
[41,31,29,17],
[41,31,29,19],
[41,31,29,20],
[41,31,29,21],
[41,31,29,22],
[41,31,29,24],
[41,31,29,25],
[41,31,29,28],
[41,32,3,0],
[41,32,3,1],
[41,32,3,2],
[41,32,4,0],
[41,32,4,1],
[41,32,4,2],
[41,32,4,3],
[41,32,5,0],
[41,32,5,1],
[41,32,5,2],
[41,32,5,4],
[41,32,6,0],
[41,32,6,1],
[41,32,6,2],
[41,32,6,4],
[41,32,7,0],
[41,32,7,1],
[41,32,7,2],
[41,32,7,3],
[41,32,7,4],
[41,32,7,5],
[41,32,7,6],
[41,32,8,0],
[41,32,8,1],
[41,32,8,2],
[41,32,8,4],
[41,32,8,7],
[41,32,10,0],
[41,32,10,1],
[41,32,10,2],
[41,32,10,3],
[41,32,10,4],
[41,32,10,5],
[41,32,10,6],
[41,32,10,7],
[41,32,10,8],
[41,32,13,0],
[41,32,13,1],
[41,32,13,2],
[41,32,13,3],
[41,32,13,4],
[41,32,13,5],
[41,32,13,6],
[41,32,13,7],
[41,32,13,8],
[41,32,13,10],
[41,32,14,0],
[41,32,14,1],
[41,32,14,2],
[41,32,14,3],
[41,32,14,4],
[41,32,14,5],
[41,32,14,6],
[41,32,14,7],
[41,32,14,8],
[41,32,14,10],
[41,32,16,0],
[41,32,16,1],
[41,32,16,2],
[41,32,16,3],
[41,32,16,4],
[41,32,16,5],
[41,32,16,6],
[41,32,16,7],
[41,32,16,8],
[41,32,16,10],
[41,32,16,13],
[41,32,16,14],
[41,32,17,0],
[41,32,17,1],
[41,32,17,2],
[41,32,17,3],
[41,32,17,4],
[41,32,17,5],
[41,32,17,6],
[41,32,17,7],
[41,32,17,8],
[41,32,17,10],
[41,32,17,13],
[41,32,17,14],
[41,32,17,16],
[41,32,19,0],
[41,32,19,1],
[41,32,19,2],
[41,32,19,3],
[41,32,19,4],
[41,32,19,5],
[41,32,19,6],
[41,32,19,7],
[41,32,19,8],
[41,32,19,10],
[41,32,19,16],
[41,32,19,17],
[41,32,20,0],
[41,32,20,1],
[41,32,20,2],
[41,32,20,3],
[41,32,20,4],
[41,32,20,5],
[41,32,20,6],
[41,32,20,7],
[41,32,20,8],
[41,32,20,13],
[41,32,20,14],
[41,32,20,16],
[41,32,20,17],
[41,32,20,19],
[41,32,21,0],
[41,32,21,1],
[41,32,21,2],
[41,32,21,4],
[41,32,21,7],
[41,32,21,10],
[41,32,21,13],
[41,32,21,14],
[41,32,21,16],
[41,32,21,17],
[41,32,21,19],
[41,32,21,20],
[41,32,22,0],
[41,32,22,1],
[41,32,22,2],
[41,32,22,3],
[41,32,22,4],
[41,32,22,5],
[41,32,22,6],
[41,32,22,7],
[41,32,22,8],
[41,32,22,13],
[41,32,22,14],
[41,32,22,16],
[41,32,22,17],
[41,32,22,19],
[41,32,22,21],
[41,32,23,0],
[41,32,23,1],
[41,32,23,2],
[41,32,23,3],
[41,32,23,4],
[41,32,23,5],
[41,32,23,6],
[41,32,23,7],
[41,32,23,8],
[41,32,23,10],
[41,32,23,13],
[41,32,23,14],
[41,32,23,16],
[41,32,23,17],
[41,32,23,19],
[41,32,23,20],
[41,32,23,21],
[41,32,23,22],
[41,32,24,0],
[41,32,24,1],
[41,32,24,2],
[41,32,24,3],
[41,32,24,4],
[41,32,24,5],
[41,32,24,6],
[41,32,24,7],
[41,32,24,8],
[41,32,24,10],
[41,32,24,13],
[41,32,24,14],
[41,32,24,17],
[41,32,24,19],
[41,32,24,20],
[41,32,24,21],
[41,32,24,22],
[41,32,24,23],
[41,32,25,0],
[41,32,25,1],
[41,32,25,2],
[41,32,25,3],
[41,32,25,4],
[41,32,25,5],
[41,32,25,6],
[41,32,25,8],
[41,32,25,10],
[41,32,25,13],
[41,32,25,14],
[41,32,25,16],
[41,32,25,17],
[41,32,25,19],
[41,32,25,20],
[41,32,25,21],
[41,32,25,22],
[41,32,25,23],
[41,32,25,24],
[41,32,27,0],
[41,32,27,1],
[41,32,27,2],
[41,32,27,3],
[41,32,27,4],
[41,32,27,5],
[41,32,27,6],
[41,32,27,7],
[41,32,27,8],
[41,32,27,13],
[41,32,27,14],
[41,32,27,16],
[41,32,27,17],
[41,32,27,19],
[41,32,27,21],
[41,32,27,23],
[41,32,27,24],
[41,32,27,25],
[41,32,28,0],
[41,32,28,1],
[41,32,28,2],
[41,32,28,3],
[41,32,28,4],
[41,32,28,5],
[41,32,28,6],
[41,32,28,8],
[41,32,28,10],
[41,32,28,13],
[41,32,28,14],
[41,32,28,16],
[41,32,28,17],
[41,32,28,19],
[41,32,28,20],
[41,32,28,21],
[41,32,28,22],
[41,32,28,23],
[41,32,28,24],
[41,32,30,0],
[41,32,30,1],
[41,32,30,2],
[41,32,30,3],
[41,32,30,4],
[41,32,30,5],
[41,32,30,6],
[41,32,30,7],
[41,32,30,8],
[41,32,30,10],
[41,32,30,13],
[41,32,30,14],
[41,32,30,16],
[41,32,30,17],
[41,32,30,19],
[41,32,30,20],
[41,32,30,21],
[41,32,30,22],
[41,32,30,24],
[41,32,30,27],
[41,32,30,28],
[41,32,31,0],
[41,32,31,1],
[41,32,31,2],
[41,32,31,3],
[41,32,31,4],
[41,32,31,5],
[41,32,31,6],
[41,32,31,7],
[41,32,31,8],
[41,32,31,10],
[41,32,31,13],
[41,32,31,14],
[41,32,31,16],
[41,32,31,17],
[41,32,31,19],
[41,32,31,20],
[41,32,31,21],
[41,32,31,22],
[41,32,31,24],
[41,32,31,25],
[41,32,31,27],
[41,32,31,28],
[41,33,3,0],
[41,33,3,1],
[41,33,3,2],
[41,33,4,0],
[41,33,4,1],
[41,33,4,2],
[41,33,4,3],
[41,33,5,0],
[41,33,5,1],
[41,33,5,2],
[41,33,5,4],
[41,33,6,0],
[41,33,6,1],
[41,33,6,2],
[41,33,6,4],
[41,33,7,0],
[41,33,7,1],
[41,33,7,2],
[41,33,7,3],
[41,33,7,4],
[41,33,7,5],
[41,33,7,6],
[41,33,8,0],
[41,33,8,1],
[41,33,8,2],
[41,33,8,4],
[41,33,8,7],
[41,33,10,0],
[41,33,10,1],
[41,33,10,2],
[41,33,10,3],
[41,33,10,4],
[41,33,10,5],
[41,33,10,6],
[41,33,10,7],
[41,33,10,8],
[41,33,13,0],
[41,33,13,1],
[41,33,13,2],
[41,33,13,3],
[41,33,13,4],
[41,33,13,5],
[41,33,13,6],
[41,33,13,7],
[41,33,13,8],
[41,33,13,10],
[41,33,14,0],
[41,33,14,1],
[41,33,14,2],
[41,33,14,3],
[41,33,14,4],
[41,33,14,5],
[41,33,14,6],
[41,33,14,7],
[41,33,14,8],
[41,33,14,10],
[41,33,16,0],
[41,33,16,1],
[41,33,16,2],
[41,33,16,3],
[41,33,16,4],
[41,33,16,5],
[41,33,16,6],
[41,33,16,7],
[41,33,16,8],
[41,33,16,10],
[41,33,16,13],
[41,33,16,14],
[41,33,17,0],
[41,33,17,1],
[41,33,17,2],
[41,33,17,3],
[41,33,17,4],
[41,33,17,5],
[41,33,17,6],
[41,33,17,7],
[41,33,17,8],
[41,33,17,10],
[41,33,17,13],
[41,33,17,14],
[41,33,17,16],
[41,33,19,0],
[41,33,19,1],
[41,33,19,2],
[41,33,19,3],
[41,33,19,4],
[41,33,19,5],
[41,33,19,6],
[41,33,19,7],
[41,33,19,8],
[41,33,19,10],
[41,33,19,16],
[41,33,19,17],
[41,33,20,0],
[41,33,20,1],
[41,33,20,2],
[41,33,20,3],
[41,33,20,4],
[41,33,20,5],
[41,33,20,6],
[41,33,20,7],
[41,33,20,8],
[41,33,20,13],
[41,33,20,14],
[41,33,20,16],
[41,33,20,17],
[41,33,20,19],
[41,33,21,0],
[41,33,21,1],
[41,33,21,2],
[41,33,21,4],
[41,33,21,7],
[41,33,21,10],
[41,33,21,13],
[41,33,21,14],
[41,33,21,16],
[41,33,21,17],
[41,33,21,19],
[41,33,21,20],
[41,33,22,0],
[41,33,22,1],
[41,33,22,2],
[41,33,22,3],
[41,33,22,4],
[41,33,22,5],
[41,33,22,6],
[41,33,22,7],
[41,33,22,8],
[41,33,22,13],
[41,33,22,14],
[41,33,22,16],
[41,33,22,17],
[41,33,22,19],
[41,33,22,21],
[41,33,23,0],
[41,33,23,1],
[41,33,23,2],
[41,33,23,3],
[41,33,23,4],
[41,33,23,5],
[41,33,23,6],
[41,33,23,7],
[41,33,23,8],
[41,33,23,10],
[41,33,23,13],
[41,33,23,14],
[41,33,23,16],
[41,33,23,17],
[41,33,23,19],
[41,33,23,20],
[41,33,23,21],
[41,33,23,22],
[41,33,24,0],
[41,33,24,1],
[41,33,24,2],
[41,33,24,3],
[41,33,24,4],
[41,33,24,5],
[41,33,24,6],
[41,33,24,7],
[41,33,24,8],
[41,33,24,10],
[41,33,24,13],
[41,33,24,14],
[41,33,24,17],
[41,33,24,19],
[41,33,24,20],
[41,33,24,21],
[41,33,24,22],
[41,33,24,23],
[41,33,25,0],
[41,33,25,1],
[41,33,25,2],
[41,33,25,3],
[41,33,25,4],
[41,33,25,5],
[41,33,25,6],
[41,33,25,8],
[41,33,25,10],
[41,33,25,13],
[41,33,25,14],
[41,33,25,16],
[41,33,25,17],
[41,33,25,19],
[41,33,25,20],
[41,33,25,21],
[41,33,25,22],
[41,33,25,23],
[41,33,25,24],
[41,33,27,0],
[41,33,27,1],
[41,33,27,2],
[41,33,27,3],
[41,33,27,4],
[41,33,27,5],
[41,33,27,6],
[41,33,27,7],
[41,33,27,8],
[41,33,27,13],
[41,33,27,14],
[41,33,27,16],
[41,33,27,17],
[41,33,27,19],
[41,33,27,21],
[41,33,27,23],
[41,33,27,24],
[41,33,27,25],
[41,33,28,0],
[41,33,28,1],
[41,33,28,2],
[41,33,28,3],
[41,33,28,4],
[41,33,28,5],
[41,33,28,6],
[41,33,28,8],
[41,33,28,10],
[41,33,28,13],
[41,33,28,14],
[41,33,28,16],
[41,33,28,17],
[41,33,28,19],
[41,33,28,20],
[41,33,28,21],
[41,33,28,22],
[41,33,28,23],
[41,33,28,24],
[41,33,28,27],
[41,33,30,0],
[41,33,30,1],
[41,33,30,2],
[41,33,30,3],
[41,33,30,4],
[41,33,30,5],
[41,33,30,6],
[41,33,30,7],
[41,33,30,8],
[41,33,30,10],
[41,33,30,13],
[41,33,30,14],
[41,33,30,16],
[41,33,30,17],
[41,33,30,19],
[41,33,30,20],
[41,33,30,21],
[41,33,30,22],
[41,33,30,24],
[41,33,30,25],
[41,33,30,27],
[41,33,30,28],
[41,33,31,0],
[41,33,31,1],
[41,33,31,2],
[41,33,31,3],
[41,33,31,4],
[41,33,31,5],
[41,33,31,6],
[41,33,31,7],
[41,33,31,8],
[41,33,31,10],
[41,33,31,13],
[41,33,31,14],
[41,33,31,16],
[41,33,31,17],
[41,33,31,19],
[41,33,31,20],
[41,33,31,21],
[41,33,31,22],
[41,33,31,25],
[41,33,31,27],
[41,33,31,28],
[41,34,3,0],
[41,34,3,1],
[41,34,3,2],
[41,34,4,0],
[41,34,4,1],
[41,34,4,2],
[41,34,4,3],
[41,34,5,0],
[41,34,5,1],
[41,34,5,2],
[41,34,5,4],
[41,34,6,0],
[41,34,6,1],
[41,34,6,2],
[41,34,6,4],
[41,34,8,0],
[41,34,8,1],
[41,34,8,2],
[41,34,8,4],
[41,34,10,0],
[41,34,10,1],
[41,34,10,2],
[41,34,10,3],
[41,34,10,4],
[41,34,10,5],
[41,34,10,6],
[41,34,10,8],
[41,34,11,0],
[41,34,11,1],
[41,34,11,2],
[41,34,11,3],
[41,34,11,4],
[41,34,11,5],
[41,34,11,6],
[41,34,11,8],
[41,34,11,10],
[41,34,12,0],
[41,34,12,1],
[41,34,12,2],
[41,34,12,3],
[41,34,12,4],
[41,34,12,5],
[41,34,12,6],
[41,34,12,8],
[41,34,12,10],
[41,34,13,0],
[41,34,13,1],
[41,34,13,2],
[41,34,13,3],
[41,34,13,4],
[41,34,13,5],
[41,34,13,6],
[41,34,13,8],
[41,34,13,10],
[41,34,13,11],
[41,34,13,12],
[41,34,14,0],
[41,34,14,1],
[41,34,14,2],
[41,34,14,3],
[41,34,14,4],
[41,34,14,5],
[41,34,14,6],
[41,34,14,8],
[41,34,14,10],
[41,34,14,11],
[41,34,14,12],
[41,34,16,0],
[41,34,16,1],
[41,34,16,2],
[41,34,16,3],
[41,34,16,4],
[41,34,16,5],
[41,34,16,6],
[41,34,16,8],
[41,34,16,10],
[41,34,16,11],
[41,34,16,12],
[41,34,16,13],
[41,34,16,14],
[41,34,17,0],
[41,34,17,1],
[41,34,17,2],
[41,34,17,3],
[41,34,17,4],
[41,34,17,5],
[41,34,17,6],
[41,34,17,8],
[41,34,17,10],
[41,34,17,11],
[41,34,17,12],
[41,34,17,13],
[41,34,17,14],
[41,34,17,16],
[41,34,19,0],
[41,34,19,1],
[41,34,19,2],
[41,34,19,3],
[41,34,19,4],
[41,34,19,5],
[41,34,19,6],
[41,34,19,8],
[41,34,19,10],
[41,34,19,11],
[41,34,19,12],
[41,34,19,16],
[41,34,19,17],
[41,34,20,0],
[41,34,20,1],
[41,34,20,2],
[41,34,20,3],
[41,34,20,4],
[41,34,20,5],
[41,34,20,6],
[41,34,20,8],
[41,34,20,11],
[41,34,20,12],
[41,34,20,13],
[41,34,20,14],
[41,34,20,16],
[41,34,20,17],
[41,34,20,19],
[41,34,21,0],
[41,34,21,1],
[41,34,21,2],
[41,34,21,4],
[41,34,21,10],
[41,34,21,11],
[41,34,21,12],
[41,34,21,13],
[41,34,21,14],
[41,34,21,16],
[41,34,21,17],
[41,34,21,19],
[41,34,21,20],
[41,34,22,0],
[41,34,22,1],
[41,34,22,2],
[41,34,22,3],
[41,34,22,4],
[41,34,22,5],
[41,34,22,6],
[41,34,22,8],
[41,34,22,11],
[41,34,22,12],
[41,34,22,13],
[41,34,22,14],
[41,34,22,16],
[41,34,22,17],
[41,34,22,19],
[41,34,22,21],
[41,34,23,0],
[41,34,23,1],
[41,34,23,2],
[41,34,23,3],
[41,34,23,4],
[41,34,23,5],
[41,34,23,6],
[41,34,23,8],
[41,34,23,10],
[41,34,23,11],
[41,34,23,12],
[41,34,23,13],
[41,34,23,14],
[41,34,23,16],
[41,34,23,17],
[41,34,23,19],
[41,34,23,20],
[41,34,23,21],
[41,34,23,22],
[41,34,24,0],
[41,34,24,1],
[41,34,24,2],
[41,34,24,3],
[41,34,24,4],
[41,34,24,5],
[41,34,24,6],
[41,34,24,8],
[41,34,24,10],
[41,34,24,11],
[41,34,24,12],
[41,34,24,13],
[41,34,24,14],
[41,34,24,17],
[41,34,24,19],
[41,34,24,20],
[41,34,24,21],
[41,34,24,22],
[41,34,24,23],
[41,34,27,0],
[41,34,27,1],
[41,34,27,2],
[41,34,27,3],
[41,34,27,4],
[41,34,27,5],
[41,34,27,6],
[41,34,27,8],
[41,34,27,11],
[41,34,27,12],
[41,34,27,13],
[41,34,27,14],
[41,34,27,16],
[41,34,27,17],
[41,34,27,19],
[41,34,27,21],
[41,34,27,23],
[41,34,27,24],
[41,34,29,0],
[41,34,29,1],
[41,34,29,2],
[41,34,29,3],
[41,34,29,4],
[41,34,29,5],
[41,34,29,6],
[41,34,29,8],
[41,34,29,10],
[41,34,29,13],
[41,34,29,14],
[41,34,29,16],
[41,34,29,17],
[41,34,29,19],
[41,34,29,20],
[41,34,29,21],
[41,34,29,22],
[41,34,29,23],
[41,34,29,24],
[41,34,29,27],
[41,34,30,0],
[41,34,30,1],
[41,34,30,2],
[41,34,30,3],
[41,34,30,4],
[41,34,30,5],
[41,34,30,6],
[41,34,30,8],
[41,34,30,10],
[41,34,30,11],
[41,34,30,12],
[41,34,30,13],
[41,34,30,14],
[41,34,30,16],
[41,34,30,17],
[41,34,30,19],
[41,34,30,20],
[41,34,30,21],
[41,34,30,22],
[41,34,30,27],
[41,34,30,29],
[41,34,31,0],
[41,34,31,1],
[41,34,31,2],
[41,34,31,3],
[41,34,31,4],
[41,34,31,5],
[41,34,31,6],
[41,34,31,8],
[41,34,31,10],
[41,34,31,11],
[41,34,31,12],
[41,34,31,13],
[41,34,31,14],
[41,34,31,16],
[41,34,31,17],
[41,34,31,19],
[41,34,31,20],
[41,34,31,21],
[41,34,31,24],
[41,34,31,27],
[41,34,31,29],
[41,34,32,0],
[41,34,32,1],
[41,34,32,2],
[41,34,32,3],
[41,34,32,4],
[41,34,32,5],
[41,34,32,6],
[41,34,32,8],
[41,34,32,10],
[41,34,32,13],
[41,34,32,14],
[41,34,32,16],
[41,34,32,17],
[41,34,32,19],
[41,34,32,20],
[41,34,32,22],
[41,34,32,23],
[41,34,32,24],
[41,34,32,27],
[41,34,32,30],
[41,34,32,31],
[41,34,33,0],
[41,34,33,1],
[41,34,33,2],
[41,34,33,3],
[41,34,33,4],
[41,34,33,5],
[41,34,33,6],
[41,34,33,8],
[41,34,33,10],
[41,34,33,13],
[41,34,33,14],
[41,34,33,16],
[41,34,33,17],
[41,34,33,19],
[41,34,33,21],
[41,34,33,22],
[41,34,33,23],
[41,34,33,24],
[41,34,33,27],
[41,34,33,30],
[41,34,33,31],
[41,36,3,0],
[41,36,3,1],
[41,36,3,2],
[41,36,4,0],
[41,36,4,1],
[41,36,4,2],
[41,36,4,3],
[41,36,5,0],
[41,36,5,1],
[41,36,5,2],
[41,36,5,4],
[41,36,6,0],
[41,36,6,1],
[41,36,6,2],
[41,36,6,4],
[41,36,7,0],
[41,36,7,1],
[41,36,7,2],
[41,36,7,3],
[41,36,7,4],
[41,36,7,5],
[41,36,7,6],
[41,36,8,0],
[41,36,8,1],
[41,36,8,2],
[41,36,8,4],
[41,36,8,7],
[41,36,10,0],
[41,36,10,1],
[41,36,10,2],
[41,36,10,3],
[41,36,10,4],
[41,36,10,5],
[41,36,10,6],
[41,36,10,7],
[41,36,10,8],
[41,36,11,0],
[41,36,11,1],
[41,36,11,2],
[41,36,11,3],
[41,36,11,4],
[41,36,11,5],
[41,36,11,6],
[41,36,11,7],
[41,36,11,8],
[41,36,11,10],
[41,36,12,0],
[41,36,12,1],
[41,36,12,2],
[41,36,12,3],
[41,36,12,4],
[41,36,12,5],
[41,36,12,6],
[41,36,12,7],
[41,36,12,8],
[41,36,12,10],
[41,36,16,0],
[41,36,16,1],
[41,36,16,2],
[41,36,16,3],
[41,36,16,4],
[41,36,16,5],
[41,36,16,6],
[41,36,16,7],
[41,36,16,8],
[41,36,16,10],
[41,36,16,11],
[41,36,16,12],
[41,36,17,0],
[41,36,17,1],
[41,36,17,2],
[41,36,17,3],
[41,36,17,4],
[41,36,17,5],
[41,36,17,6],
[41,36,17,7],
[41,36,17,8],
[41,36,17,10],
[41,36,17,11],
[41,36,17,12],
[41,36,17,16],
[41,36,20,0],
[41,36,20,1],
[41,36,20,2],
[41,36,20,3],
[41,36,20,4],
[41,36,20,5],
[41,36,20,6],
[41,36,20,7],
[41,36,20,8],
[41,36,20,11],
[41,36,20,12],
[41,36,20,16],
[41,36,20,17],
[41,36,21,0],
[41,36,21,1],
[41,36,21,2],
[41,36,21,4],
[41,36,21,7],
[41,36,21,10],
[41,36,21,11],
[41,36,21,12],
[41,36,21,16],
[41,36,21,17],
[41,36,21,20],
[41,36,22,0],
[41,36,22,1],
[41,36,22,2],
[41,36,22,3],
[41,36,22,4],
[41,36,22,5],
[41,36,22,6],
[41,36,22,7],
[41,36,22,8],
[41,36,22,11],
[41,36,22,12],
[41,36,22,16],
[41,36,22,17],
[41,36,22,21],
[41,36,23,0],
[41,36,23,1],
[41,36,23,2],
[41,36,23,3],
[41,36,23,4],
[41,36,23,5],
[41,36,23,6],
[41,36,23,7],
[41,36,23,8],
[41,36,23,10],
[41,36,23,11],
[41,36,23,12],
[41,36,23,16],
[41,36,23,17],
[41,36,23,20],
[41,36,23,21],
[41,36,23,22],
[41,36,24,0],
[41,36,24,1],
[41,36,24,2],
[41,36,24,3],
[41,36,24,4],
[41,36,24,5],
[41,36,24,6],
[41,36,24,7],
[41,36,24,8],
[41,36,24,10],
[41,36,24,11],
[41,36,24,12],
[41,36,24,17],
[41,36,24,20],
[41,36,24,21],
[41,36,24,22],
[41,36,24,23],
[41,36,25,0],
[41,36,25,1],
[41,36,25,2],
[41,36,25,3],
[41,36,25,4],
[41,36,25,5],
[41,36,25,6],
[41,36,25,8],
[41,36,25,10],
[41,36,25,11],
[41,36,25,12],
[41,36,25,16],
[41,36,25,17],
[41,36,25,20],
[41,36,25,21],
[41,36,25,22],
[41,36,25,23],
[41,36,25,24],
[41,36,27,0],
[41,36,27,1],
[41,36,27,2],
[41,36,27,3],
[41,36,27,4],
[41,36,27,5],
[41,36,27,6],
[41,36,27,7],
[41,36,27,8],
[41,36,27,11],
[41,36,27,12],
[41,36,27,16],
[41,36,27,17],
[41,36,27,21],
[41,36,27,23],
[41,36,27,24],
[41,36,27,25],
[41,36,28,0],
[41,36,28,1],
[41,36,28,2],
[41,36,28,3],
[41,36,28,4],
[41,36,28,5],
[41,36,28,6],
[41,36,28,8],
[41,36,28,10],
[41,36,28,11],
[41,36,28,12],
[41,36,28,16],
[41,36,28,17],
[41,36,28,20],
[41,36,28,21],
[41,36,28,22],
[41,36,28,27],
[41,36,29,0],
[41,36,29,1],
[41,36,29,2],
[41,36,29,3],
[41,36,29,4],
[41,36,29,5],
[41,36,29,6],
[41,36,29,7],
[41,36,29,8],
[41,36,29,10],
[41,36,29,16],
[41,36,29,17],
[41,36,29,20],
[41,36,29,21],
[41,36,29,23],
[41,36,29,24],
[41,36,29,25],
[41,36,29,27],
[41,36,29,28],
[41,36,30,0],
[41,36,30,1],
[41,36,30,2],
[41,36,30,3],
[41,36,30,4],
[41,36,30,5],
[41,36,30,6],
[41,36,30,7],
[41,36,30,8],
[41,36,30,10],
[41,36,30,11],
[41,36,30,12],
[41,36,30,16],
[41,36,30,17],
[41,36,30,20],
[41,36,30,22],
[41,36,30,24],
[41,36,30,25],
[41,36,30,27],
[41,36,30,28],
[41,36,30,29],
[41,36,31,0],
[41,36,31,1],
[41,36,31,2],
[41,36,31,3],
[41,36,31,4],
[41,36,31,5],
[41,36,31,6],
[41,36,31,7],
[41,36,31,8],
[41,36,31,10],
[41,36,31,11],
[41,36,31,12],
[41,36,31,16],
[41,36,31,17],
[41,36,31,21],
[41,36,31,22],
[41,36,31,24],
[41,36,31,25],
[41,36,31,27],
[41,36,31,28],
[41,36,31,29],
[41,36,32,0],
[41,36,32,1],
[41,36,32,2],
[41,36,32,3],
[41,36,32,4],
[41,36,32,5],
[41,36,32,6],
[41,36,32,7],
[41,36,32,8],
[41,36,32,10],
[41,36,32,16],
[41,36,32,17],
[41,36,32,20],
[41,36,32,21],
[41,36,32,22],
[41,36,32,23],
[41,36,32,24],
[41,36,32,25],
[41,36,32,27],
[41,36,32,28],
[41,36,32,30],
[41,36,32,31],
[41,36,33,0],
[41,36,33,1],
[41,36,33,2],
[41,36,33,3],
[41,36,33,4],
[41,36,33,5],
[41,36,33,6],
[41,36,33,7],
[41,36,33,8],
[41,36,33,10],
[41,36,33,16],
[41,36,33,17],
[41,36,33,20],
[41,36,33,21],
[41,36,33,22],
[41,36,33,23],
[41,36,33,24],
[41,36,33,25],
[41,36,33,27],
[41,36,33,28],
[41,36,33,30],
[41,36,34,0],
[41,36,34,1],
[41,36,34,2],
[41,36,34,3],
[41,36,34,4],
[41,36,34,5],
[41,36,34,6],
[41,36,34,8],
[41,36,34,10],
[41,36,34,11],
[41,36,34,12],
[41,36,34,20],
[41,36,34,21],
[41,36,34,22],
[41,36,34,23],
[41,36,34,24],
[41,36,34,27],
[41,36,34,29],
[41,37,3,0],
[41,37,3,1],
[41,37,3,2],
[41,37,5,0],
[41,37,5,1],
[41,37,5,2],
[41,37,6,0],
[41,37,6,1],
[41,37,6,2],
[41,37,7,0],
[41,37,7,1],
[41,37,7,2],
[41,37,7,3],
[41,37,7,5],
[41,37,7,6],
[41,37,8,0],
[41,37,8,1],
[41,37,8,2],
[41,37,8,7],
[41,37,10,0],
[41,37,10,1],
[41,37,10,2],
[41,37,10,3],
[41,37,10,5],
[41,37,10,6],
[41,37,10,7],
[41,37,10,8],
[41,37,11,0],
[41,37,11,1],
[41,37,11,2],
[41,37,11,3],
[41,37,11,5],
[41,37,11,6],
[41,37,11,7],
[41,37,11,8],
[41,37,11,10],
[41,37,12,0],
[41,37,12,1],
[41,37,12,2],
[41,37,12,3],
[41,37,12,5],
[41,37,12,6],
[41,37,12,7],
[41,37,12,8],
[41,37,12,10],
[41,37,13,0],
[41,37,13,1],
[41,37,13,2],
[41,37,13,3],
[41,37,13,5],
[41,37,13,6],
[41,37,13,7],
[41,37,13,8],
[41,37,13,10],
[41,37,13,11],
[41,37,13,12],
[41,37,14,0],
[41,37,14,1],
[41,37,14,2],
[41,37,14,3],
[41,37,14,5],
[41,37,14,6],
[41,37,14,7],
[41,37,14,8],
[41,37,14,10],
[41,37,14,11],
[41,37,14,12],
[41,37,16,0],
[41,37,16,1],
[41,37,16,2],
[41,37,16,3],
[41,37,16,5],
[41,37,16,6],
[41,37,16,7],
[41,37,16,8],
[41,37,16,10],
[41,37,16,11],
[41,37,16,12],
[41,37,16,13],
[41,37,16,14],
[41,37,17,0],
[41,37,17,1],
[41,37,17,2],
[41,37,17,3],
[41,37,17,5],
[41,37,17,6],
[41,37,17,7],
[41,37,17,8],
[41,37,17,10],
[41,37,17,11],
[41,37,17,12],
[41,37,17,13],
[41,37,17,14],
[41,37,17,16],
[41,37,19,0],
[41,37,19,1],
[41,37,19,2],
[41,37,19,3],
[41,37,19,5],
[41,37,19,6],
[41,37,19,7],
[41,37,19,8],
[41,37,19,10],
[41,37,19,11],
[41,37,19,12],
[41,37,19,16],
[41,37,19,17],
[41,37,20,0],
[41,37,20,1],
[41,37,20,2],
[41,37,20,3],
[41,37,20,5],
[41,37,20,6],
[41,37,20,7],
[41,37,20,8],
[41,37,20,11],
[41,37,20,12],
[41,37,20,13],
[41,37,20,14],
[41,37,20,16],
[41,37,20,17],
[41,37,20,19],
[41,37,21,0],
[41,37,21,1],
[41,37,21,2],
[41,37,21,7],
[41,37,21,10],
[41,37,21,11],
[41,37,21,12],
[41,37,21,13],
[41,37,21,14],
[41,37,21,16],
[41,37,21,17],
[41,37,21,19],
[41,37,21,20],
[41,37,22,0],
[41,37,22,1],
[41,37,22,2],
[41,37,22,3],
[41,37,22,5],
[41,37,22,6],
[41,37,22,7],
[41,37,22,8],
[41,37,22,11],
[41,37,22,12],
[41,37,22,13],
[41,37,22,14],
[41,37,22,16],
[41,37,22,17],
[41,37,22,19],
[41,37,22,21],
[41,37,23,0],
[41,37,23,1],
[41,37,23,2],
[41,37,23,3],
[41,37,23,5],
[41,37,23,6],
[41,37,23,7],
[41,37,23,8],
[41,37,23,10],
[41,37,23,11],
[41,37,23,12],
[41,37,23,13],
[41,37,23,14],
[41,37,23,16],
[41,37,23,17],
[41,37,23,19],
[41,37,23,20],
[41,37,23,21],
[41,37,23,22],
[41,37,24,0],
[41,37,24,1],
[41,37,24,2],
[41,37,24,3],
[41,37,24,5],
[41,37,24,6],
[41,37,24,7],
[41,37,24,8],
[41,37,24,10],
[41,37,24,11],
[41,37,24,12],
[41,37,24,13],
[41,37,24,14],
[41,37,24,17],
[41,37,24,19],
[41,37,24,20],
[41,37,24,21],
[41,37,24,22],
[41,37,24,23],
[41,37,25,0],
[41,37,25,1],
[41,37,25,2],
[41,37,25,3],
[41,37,25,5],
[41,37,25,6],
[41,37,25,8],
[41,37,25,10],
[41,37,25,11],
[41,37,25,12],
[41,37,25,13],
[41,37,25,14],
[41,37,25,16],
[41,37,25,17],
[41,37,25,19],
[41,37,25,20],
[41,37,25,21],
[41,37,25,22],
[41,37,25,23],
[41,37,25,24],
[41,37,27,0],
[41,37,27,1],
[41,37,27,2],
[41,37,27,3],
[41,37,27,5],
[41,37,27,6],
[41,37,27,7],
[41,37,27,8],
[41,37,27,11],
[41,37,27,12],
[41,37,27,13],
[41,37,27,14],
[41,37,27,16],
[41,37,27,17],
[41,37,27,19],
[41,37,27,21],
[41,37,27,25],
[41,37,28,0],
[41,37,28,1],
[41,37,28,2],
[41,37,28,3],
[41,37,28,5],
[41,37,28,6],
[41,37,28,8],
[41,37,28,10],
[41,37,28,11],
[41,37,28,12],
[41,37,28,13],
[41,37,28,14],
[41,37,28,16],
[41,37,28,17],
[41,37,28,19],
[41,37,28,20],
[41,37,28,21],
[41,37,28,23],
[41,37,28,24],
[41,37,28,27],
[41,37,29,0],
[41,37,29,1],
[41,37,29,2],
[41,37,29,3],
[41,37,29,5],
[41,37,29,6],
[41,37,29,7],
[41,37,29,8],
[41,37,29,10],
[41,37,29,13],
[41,37,29,14],
[41,37,29,16],
[41,37,29,17],
[41,37,29,19],
[41,37,29,20],
[41,37,29,22],
[41,37,29,23],
[41,37,29,24],
[41,37,29,25],
[41,37,29,27],
[41,37,29,28],
[41,37,30,0],
[41,37,30,1],
[41,37,30,2],
[41,37,30,3],
[41,37,30,5],
[41,37,30,6],
[41,37,30,7],
[41,37,30,8],
[41,37,30,10],
[41,37,30,11],
[41,37,30,12],
[41,37,30,13],
[41,37,30,14],
[41,37,30,16],
[41,37,30,17],
[41,37,30,19],
[41,37,30,21],
[41,37,30,22],
[41,37,30,24],
[41,37,30,25],
[41,37,30,27],
[41,37,30,28],
[41,37,30,29],
[41,37,31,0],
[41,37,31,1],
[41,37,31,2],
[41,37,31,3],
[41,37,31,5],
[41,37,31,6],
[41,37,31,7],
[41,37,31,8],
[41,37,31,10],
[41,37,31,11],
[41,37,31,12],
[41,37,31,13],
[41,37,31,14],
[41,37,31,16],
[41,37,31,17],
[41,37,31,20],
[41,37,31,21],
[41,37,31,22],
[41,37,31,24],
[41,37,31,25],
[41,37,31,27],
[41,37,31,28],
[41,37,31,29],
[41,37,32,0],
[41,37,32,1],
[41,37,32,2],
[41,37,32,3],
[41,37,32,5],
[41,37,32,6],
[41,37,32,7],
[41,37,32,8],
[41,37,32,10],
[41,37,32,13],
[41,37,32,14],
[41,37,32,16],
[41,37,32,17],
[41,37,32,19],
[41,37,32,20],
[41,37,32,21],
[41,37,32,22],
[41,37,32,23],
[41,37,32,24],
[41,37,32,25],
[41,37,32,27],
[41,37,32,28],
[41,37,32,30],
[41,37,33,0],
[41,37,33,1],
[41,37,33,2],
[41,37,33,3],
[41,37,33,5],
[41,37,33,6],
[41,37,33,7],
[41,37,33,8],
[41,37,33,10],
[41,37,33,13],
[41,37,33,14],
[41,37,33,19],
[41,37,33,20],
[41,37,33,21],
[41,37,33,22],
[41,37,33,23],
[41,37,33,24],
[41,37,33,25],
[41,37,33,27],
[41,37,33,28],
[41,37,34,0],
[41,37,34,1],
[41,37,34,2],
[41,37,34,3],
[41,37,34,5],
[41,37,34,6],
[41,37,34,8],
[41,37,34,10],
[41,37,34,11],
[41,37,34,12],
[41,37,34,13],
[41,37,34,14],
[41,37,34,16],
[41,37,34,17],
[41,37,34,19],
[41,37,34,20],
[41,37,34,21],
[41,37,34,22],
[41,37,34,23],
[41,37,34,24],
[41,37,34,27],
[41,37,36,0],
[41,37,36,1],
[41,37,36,2],
[41,37,36,3],
[41,37,36,5],
[41,37,36,6],
[41,37,36,7],
[41,37,36,8],
[41,37,36,10],
[41,37,36,11],
[41,37,36,16],
[41,37,36,17],
[41,37,36,20],
[41,37,36,21],
[41,37,36,22],
[41,37,36,23],
[41,37,36,24],
[41,37,36,25],
[41,38,3,0],
[41,38,3,1],
[41,38,3,2],
[41,38,4,0],
[41,38,4,1],
[41,38,4,2],
[41,38,4,3],
[41,38,5,0],
[41,38,5,1],
[41,38,5,2],
[41,38,5,4],
[41,38,6,0],
[41,38,6,1],
[41,38,6,2],
[41,38,6,4],
[41,38,8,0],
[41,38,8,1],
[41,38,8,2],
[41,38,8,4],
[41,38,10,0],
[41,38,10,1],
[41,38,10,2],
[41,38,10,3],
[41,38,10,4],
[41,38,10,5],
[41,38,10,6],
[41,38,10,8],
[41,38,11,0],
[41,38,11,1],
[41,38,11,2],
[41,38,11,3],
[41,38,11,4],
[41,38,11,5],
[41,38,11,6],
[41,38,11,8],
[41,38,11,10],
[41,38,12,0],
[41,38,12,1],
[41,38,12,2],
[41,38,12,3],
[41,38,12,4],
[41,38,12,5],
[41,38,12,6],
[41,38,12,8],
[41,38,12,10],
[41,38,13,0],
[41,38,13,1],
[41,38,13,2],
[41,38,13,3],
[41,38,13,4],
[41,38,13,5],
[41,38,13,6],
[41,38,13,8],
[41,38,13,10],
[41,38,13,11],
[41,38,13,12],
[41,38,14,0],
[41,38,14,1],
[41,38,14,2],
[41,38,14,3],
[41,38,14,4],
[41,38,14,5],
[41,38,14,6],
[41,38,14,8],
[41,38,14,10],
[41,38,14,11],
[41,38,14,12],
[41,38,16,0],
[41,38,16,1],
[41,38,16,2],
[41,38,16,3],
[41,38,16,4],
[41,38,16,5],
[41,38,16,6],
[41,38,16,8],
[41,38,16,10],
[41,38,16,11],
[41,38,16,12],
[41,38,16,13],
[41,38,16,14],
[41,38,17,0],
[41,38,17,1],
[41,38,17,2],
[41,38,17,3],
[41,38,17,4],
[41,38,17,5],
[41,38,17,6],
[41,38,17,8],
[41,38,17,10],
[41,38,17,11],
[41,38,17,12],
[41,38,17,13],
[41,38,17,14],
[41,38,17,16],
[41,38,19,0],
[41,38,19,1],
[41,38,19,2],
[41,38,19,3],
[41,38,19,4],
[41,38,19,5],
[41,38,19,6],
[41,38,19,8],
[41,38,19,10],
[41,38,19,11],
[41,38,19,12],
[41,38,19,16],
[41,38,19,17],
[41,38,20,0],
[41,38,20,1],
[41,38,20,2],
[41,38,20,3],
[41,38,20,4],
[41,38,20,5],
[41,38,20,6],
[41,38,20,8],
[41,38,20,11],
[41,38,20,12],
[41,38,20,13],
[41,38,20,14],
[41,38,20,16],
[41,38,20,17],
[41,38,20,19],
[41,38,21,0],
[41,38,21,1],
[41,38,21,2],
[41,38,21,4],
[41,38,21,10],
[41,38,21,11],
[41,38,21,12],
[41,38,21,13],
[41,38,21,14],
[41,38,21,16],
[41,38,21,17],
[41,38,21,19],
[41,38,21,20],
[41,38,22,0],
[41,38,22,1],
[41,38,22,2],
[41,38,22,3],
[41,38,22,4],
[41,38,22,5],
[41,38,22,6],
[41,38,22,8],
[41,38,22,11],
[41,38,22,12],
[41,38,22,13],
[41,38,22,14],
[41,38,22,16],
[41,38,22,17],
[41,38,22,19],
[41,38,22,21],
[41,38,23,0],
[41,38,23,1],
[41,38,23,2],
[41,38,23,3],
[41,38,23,4],
[41,38,23,5],
[41,38,23,6],
[41,38,23,8],
[41,38,23,10],
[41,38,23,11],
[41,38,23,12],
[41,38,23,13],
[41,38,23,14],
[41,38,23,16],
[41,38,23,17],
[41,38,23,19],
[41,38,23,20],
[41,38,23,21],
[41,38,23,22],
[41,38,24,0],
[41,38,24,1],
[41,38,24,2],
[41,38,24,3],
[41,38,24,4],
[41,38,24,5],
[41,38,24,6],
[41,38,24,8],
[41,38,24,10],
[41,38,24,11],
[41,38,24,12],
[41,38,24,13],
[41,38,24,14],
[41,38,24,17],
[41,38,24,19],
[41,38,24,20],
[41,38,24,21],
[41,38,24,22],
[41,38,24,23],
[41,38,27,0],
[41,38,27,1],
[41,38,27,2],
[41,38,27,3],
[41,38,27,4],
[41,38,27,5],
[41,38,27,6],
[41,38,27,8],
[41,38,27,11],
[41,38,27,12],
[41,38,27,13],
[41,38,27,14],
[41,38,27,16],
[41,38,27,17],
[41,38,27,19],
[41,38,27,21],
[41,38,27,23],
[41,38,27,24],
[41,38,29,0],
[41,38,29,1],
[41,38,29,2],
[41,38,29,3],
[41,38,29,4],
[41,38,29,5],
[41,38,29,6],
[41,38,29,8],
[41,38,29,10],
[41,38,29,13],
[41,38,29,14],
[41,38,29,16],
[41,38,29,17],
[41,38,29,19],
[41,38,29,21],
[41,38,29,22],
[41,38,29,23],
[41,38,29,24],
[41,38,29,27],
[41,38,30,0],
[41,38,30,1],
[41,38,30,2],
[41,38,30,3],
[41,38,30,4],
[41,38,30,5],
[41,38,30,6],
[41,38,30,8],
[41,38,30,10],
[41,38,30,11],
[41,38,30,12],
[41,38,30,13],
[41,38,30,14],
[41,38,30,16],
[41,38,30,17],
[41,38,30,20],
[41,38,30,21],
[41,38,30,22],
[41,38,30,24],
[41,38,30,27],
[41,38,30,29],
[41,38,31,0],
[41,38,31,1],
[41,38,31,2],
[41,38,31,3],
[41,38,31,4],
[41,38,31,5],
[41,38,31,6],
[41,38,31,8],
[41,38,31,10],
[41,38,31,11],
[41,38,31,12],
[41,38,31,13],
[41,38,31,14],
[41,38,31,16],
[41,38,31,17],
[41,38,31,19],
[41,38,31,20],
[41,38,31,21],
[41,38,31,22],
[41,38,31,24],
[41,38,31,27],
[41,38,31,29],
[41,38,32,0],
[41,38,32,1],
[41,38,32,2],
[41,38,32,3],
[41,38,32,4],
[41,38,32,5],
[41,38,32,6],
[41,38,32,8],
[41,38,32,10],
[41,38,32,13],
[41,38,32,14],
[41,38,32,19],
[41,38,32,20],
[41,38,32,21],
[41,38,32,22],
[41,38,32,23],
[41,38,32,24],
[41,38,32,27],
[41,38,33,0],
[41,38,33,1],
[41,38,33,2],
[41,38,33,3],
[41,38,33,4],
[41,38,33,5],
[41,38,33,6],
[41,38,33,8],
[41,38,33,10],
[41,38,33,13],
[41,38,33,14],
[41,38,33,16],
[41,38,33,17],
[41,38,33,19],
[41,38,33,20],
[41,38,33,21],
[41,38,33,22],
[41,38,33,23],
[41,38,33,24],
[41,38,33,27],
[41,38,36,0],
[41,38,36,1],
[41,38,36,2],
[41,38,36,3],
[41,38,36,4],
[41,38,36,5],
[41,38,36,6],
[41,38,36,8],
[41,38,36,12],
[41,38,36,16],
[41,38,36,17],
[41,38,36,20],
[41,38,36,21],
[41,38,36,22],
[41,38,36,23],
[41,38,36,24],
[41,38,37,0],
[41,38,37,1],
[41,38,37,2],
[41,38,37,3],
[41,38,37,5],
[41,38,37,6],
[41,38,37,10],
[41,38,37,11],
[41,38,37,12],
[41,38,37,13],
[41,38,37,14],
[41,38,37,16],
[41,38,37,17],
[41,38,37,19],
[41,38,37,20],
[41,38,37,21],
[41,38,37,22],
[41,38,37,23],
[41,38,37,24],
[41,39,4,0],
[41,39,4,1],
[41,39,4,2],
[41,39,7,0],
[41,39,7,1],
[41,39,7,2],
[41,39,7,4],
[41,39,10,0],
[41,39,10,1],
[41,39,10,2],
[41,39,10,4],
[41,39,10,7],
[41,39,11,0],
[41,39,11,1],
[41,39,11,2],
[41,39,11,4],
[41,39,11,7],
[41,39,11,10],
[41,39,12,0],
[41,39,12,1],
[41,39,12,2],
[41,39,12,4],
[41,39,12,7],
[41,39,12,10],
[41,39,13,0],
[41,39,13,1],
[41,39,13,2],
[41,39,13,4],
[41,39,13,7],
[41,39,13,10],
[41,39,13,11],
[41,39,13,12],
[41,39,14,0],
[41,39,14,1],
[41,39,14,2],
[41,39,14,4],
[41,39,14,7],
[41,39,14,10],
[41,39,14,11],
[41,39,14,12],
[41,39,16,0],
[41,39,16,1],
[41,39,16,2],
[41,39,16,4],
[41,39,16,7],
[41,39,16,10],
[41,39,16,11],
[41,39,16,12],
[41,39,16,13],
[41,39,16,14],
[41,39,17,0],
[41,39,17,1],
[41,39,17,2],
[41,39,17,4],
[41,39,17,7],
[41,39,17,10],
[41,39,17,11],
[41,39,17,12],
[41,39,17,13],
[41,39,17,14],
[41,39,17,16],
[41,39,19,0],
[41,39,19,1],
[41,39,19,2],
[41,39,19,4],
[41,39,19,7],
[41,39,19,10],
[41,39,19,11],
[41,39,19,12],
[41,39,19,16],
[41,39,19,17],
[41,39,20,0],
[41,39,20,1],
[41,39,20,2],
[41,39,20,4],
[41,39,20,7],
[41,39,20,11],
[41,39,20,12],
[41,39,20,13],
[41,39,20,14],
[41,39,20,16],
[41,39,20,17],
[41,39,20,19],
[41,39,22,0],
[41,39,22,1],
[41,39,22,2],
[41,39,22,4],
[41,39,22,7],
[41,39,22,11],
[41,39,22,12],
[41,39,22,13],
[41,39,22,14],
[41,39,22,16],
[41,39,22,17],
[41,39,22,19],
[41,39,23,0],
[41,39,23,1],
[41,39,23,2],
[41,39,23,4],
[41,39,23,7],
[41,39,23,10],
[41,39,23,11],
[41,39,23,12],
[41,39,23,13],
[41,39,23,14],
[41,39,23,16],
[41,39,23,17],
[41,39,23,19],
[41,39,23,20],
[41,39,23,22],
[41,39,24,0],
[41,39,24,1],
[41,39,24,2],
[41,39,24,4],
[41,39,24,7],
[41,39,24,10],
[41,39,24,11],
[41,39,24,12],
[41,39,24,13],
[41,39,24,14],
[41,39,24,17],
[41,39,24,19],
[41,39,24,20],
[41,39,24,22],
[41,39,24,23],
[41,39,25,0],
[41,39,25,1],
[41,39,25,2],
[41,39,25,4],
[41,39,25,10],
[41,39,25,11],
[41,39,25,12],
[41,39,25,13],
[41,39,25,14],
[41,39,25,16],
[41,39,25,17],
[41,39,25,19],
[41,39,25,20],
[41,39,25,22],
[41,39,27,0],
[41,39,27,1],
[41,39,27,2],
[41,39,27,4],
[41,39,27,7],
[41,39,27,11],
[41,39,27,12],
[41,39,27,13],
[41,39,27,14],
[41,39,27,16],
[41,39,27,17],
[41,39,27,19],
[41,39,27,23],
[41,39,27,24],
[41,39,27,25],
[41,39,28,0],
[41,39,28,1],
[41,39,28,2],
[41,39,28,4],
[41,39,28,10],
[41,39,28,11],
[41,39,28,12],
[41,39,28,13],
[41,39,28,14],
[41,39,28,16],
[41,39,28,17],
[41,39,28,19],
[41,39,28,22],
[41,39,28,23],
[41,39,28,24],
[41,39,28,27],
[41,39,29,0],
[41,39,29,1],
[41,39,29,2],
[41,39,29,4],
[41,39,29,7],
[41,39,29,10],
[41,39,29,13],
[41,39,29,14],
[41,39,29,16],
[41,39,29,17],
[41,39,29,20],
[41,39,29,22],
[41,39,29,23],
[41,39,29,24],
[41,39,29,25],
[41,39,29,27],
[41,39,29,28],
[41,39,30,0],
[41,39,30,1],
[41,39,30,2],
[41,39,30,4],
[41,39,30,7],
[41,39,30,10],
[41,39,30,11],
[41,39,30,12],
[41,39,30,13],
[41,39,30,14],
[41,39,30,16],
[41,39,30,17],
[41,39,30,19],
[41,39,30,20],
[41,39,30,22],
[41,39,30,24],
[41,39,30,25],
[41,39,30,27],
[41,39,30,28],
[41,39,30,29],
[41,39,31,0],
[41,39,31,1],
[41,39,31,2],
[41,39,31,4],
[41,39,31,7],
[41,39,31,10],
[41,39,31,11],
[41,39,31,12],
[41,39,31,13],
[41,39,31,14],
[41,39,31,19],
[41,39,31,20],
[41,39,31,22],
[41,39,31,24],
[41,39,31,25],
[41,39,31,27],
[41,39,31,28],
[41,39,31,29],
[41,39,32,0],
[41,39,32,1],
[41,39,32,2],
[41,39,32,4],
[41,39,32,7],
[41,39,32,10],
[41,39,32,13],
[41,39,32,14],
[41,39,32,16],
[41,39,32,17],
[41,39,32,19],
[41,39,32,20],
[41,39,32,22],
[41,39,32,23],
[41,39,32,24],
[41,39,32,25],
[41,39,32,27],
[41,39,32,28],
[41,39,33,0],
[41,39,33,1],
[41,39,33,2],
[41,39,33,4],
[41,39,33,7],
[41,39,33,10],
[41,39,33,13],
[41,39,33,16],
[41,39,33,17],
[41,39,33,19],
[41,39,33,20],
[41,39,33,22],
[41,39,33,23],
[41,39,33,24],
[41,39,33,25],
[41,39,33,27],
[41,39,34,0],
[41,39,34,1],
[41,39,34,2],
[41,39,34,4],
[41,39,34,10],
[41,39,34,11],
[41,39,34,14],
[41,39,34,16],
[41,39,34,17],
[41,39,34,19],
[41,39,34,20],
[41,39,34,22],
[41,39,34,23],
[41,39,34,24],
[41,39,36,0],
[41,39,36,1],
[41,39,36,2],
[41,39,36,4],
[41,39,36,7],
[41,39,36,10],
[41,39,36,11],
[41,39,36,12],
[41,39,36,16],
[41,39,36,17],
[41,39,36,20],
[41,39,36,22],
[41,39,36,23],
[41,39,36,24],
[41,39,37,0],
[41,39,37,1],
[41,39,37,2],
[41,39,37,10],
[41,39,37,11],
[41,39,37,12],
[41,39,37,13],
[41,39,37,14],
[41,39,37,16],
[41,39,37,17],
[41,39,37,19],
[41,39,37,20],
[41,39,37,22],
[41,39,37,23],
[41,39,37,24],
[41,39,38,0],
[41,39,38,1],
[41,39,38,2],
[41,39,38,4],
[41,39,38,10],
[41,39,38,11],
[41,39,38,12],
[41,39,38,13],
[41,39,38,14],
[41,39,38,16],
[41,39,38,17],
[41,39,38,19],
[41,39,38,20],
[41,39,38,22],
[42,4,3,0],
[42,4,3,1],
[42,4,3,2],
[42,5,4,0],
[42,5,4,1],
[42,5,4,2],
[42,6,4,0],
[42,6,4,1],
[42,6,4,2],
[42,7,3,0],
[42,7,3,1],
[42,7,3,2],
[42,7,4,0],
[42,7,4,1],
[42,7,4,2],
[42,7,4,3],
[42,7,5,0],
[42,7,5,1],
[42,7,5,2],
[42,7,5,4],
[42,7,6,0],
[42,7,6,1],
[42,7,6,2],
[42,7,6,4],
[42,8,4,0],
[42,8,4,1],
[42,8,4,2],
[42,8,7,0],
[42,8,7,1],
[42,8,7,2],
[42,8,7,4],
[42,9,3,0],
[42,9,3,1],
[42,9,3,2],
[42,9,4,0],
[42,9,4,1],
[42,9,4,2],
[42,9,4,3],
[42,9,5,0],
[42,9,5,1],
[42,9,5,2],
[42,9,5,4],
[42,9,6,0],
[42,9,6,1],
[42,9,6,2],
[42,9,6,4],
[42,9,7,0],
[42,9,7,1],
[42,9,7,2],
[42,9,7,3],
[42,9,7,4],
[42,9,7,5],
[42,9,7,6],
[42,9,8,0],
[42,9,8,1],
[42,9,8,2],
[42,9,8,4],
[42,9,8,7],
[42,11,3,0],
[42,11,3,1],
[42,11,3,2],
[42,11,4,0],
[42,11,4,1],
[42,11,4,2],
[42,11,4,3],
[42,11,5,0],
[42,11,5,1],
[42,11,5,2],
[42,11,5,4],
[42,11,6,0],
[42,11,6,1],
[42,11,6,2],
[42,11,6,4],
[42,11,7,0],
[42,11,7,1],
[42,11,7,2],
[42,11,7,3],
[42,11,7,4],
[42,11,7,5],
[42,11,7,6],
[42,11,8,0],
[42,11,8,1],
[42,11,8,2],
[42,11,8,4],
[42,11,8,7],
[42,11,9,0],
[42,11,9,1],
[42,11,9,2],
[42,11,9,3],
[42,11,9,4],
[42,11,9,5],
[42,11,9,6],
[42,11,9,7],
[42,11,9,8],
[42,12,3,0],
[42,12,3,1],
[42,12,3,2],
[42,12,4,0],
[42,12,4,1],
[42,12,4,2],
[42,12,4,3],
[42,12,5,0],
[42,12,5,1],
[42,12,5,2],
[42,12,5,4],
[42,12,6,0],
[42,12,6,1],
[42,12,6,2],
[42,12,6,4],
[42,12,7,0],
[42,12,7,1],
[42,12,7,2],
[42,12,7,3],
[42,12,7,4],
[42,12,7,5],
[42,12,7,6],
[42,12,8,0],
[42,12,8,1],
[42,12,8,2],
[42,12,8,4],
[42,12,8,7],
[42,12,9,0],
[42,12,9,1],
[42,12,9,2],
[42,12,9,3],
[42,12,9,4],
[42,12,9,5],
[42,12,9,6],
[42,12,9,7],
[42,12,9,8],
[42,13,3,0],
[42,13,3,1],
[42,13,3,2],
[42,13,4,0],
[42,13,4,1],
[42,13,4,2],
[42,13,4,3],
[42,13,5,0],
[42,13,5,1],
[42,13,5,2],
[42,13,5,4],
[42,13,6,0],
[42,13,6,1],
[42,13,6,2],
[42,13,6,4],
[42,13,7,0],
[42,13,7,1],
[42,13,7,2],
[42,13,7,3],
[42,13,7,4],
[42,13,7,5],
[42,13,7,6],
[42,13,8,0],
[42,13,8,1],
[42,13,8,2],
[42,13,8,4],
[42,13,8,7],
[42,13,9,0],
[42,13,9,1],
[42,13,9,2],
[42,13,9,3],
[42,13,9,4],
[42,13,9,5],
[42,13,9,6],
[42,13,9,7],
[42,13,9,8],
[42,13,11,0],
[42,13,11,1],
[42,13,11,2],
[42,13,11,3],
[42,13,11,4],
[42,13,11,5],
[42,13,11,6],
[42,13,11,7],
[42,13,11,8],
[42,13,11,9],
[42,13,12,0],
[42,13,12,1],
[42,13,12,2],
[42,13,12,3],
[42,13,12,4],
[42,13,12,5],
[42,13,12,6],
[42,13,12,7],
[42,13,12,8],
[42,13,12,9],
[42,14,3,0],
[42,14,3,1],
[42,14,3,2],
[42,14,4,0],
[42,14,4,1],
[42,14,4,2],
[42,14,4,3],
[42,14,5,0],
[42,14,5,1],
[42,14,5,2],
[42,14,5,4],
[42,14,6,0],
[42,14,6,1],
[42,14,6,2],
[42,14,6,4],
[42,14,7,0],
[42,14,7,1],
[42,14,7,2],
[42,14,7,3],
[42,14,7,4],
[42,14,7,5],
[42,14,7,6],
[42,14,8,0],
[42,14,8,1],
[42,14,8,2],
[42,14,8,4],
[42,14,8,7],
[42,14,9,0],
[42,14,9,1],
[42,14,9,2],
[42,14,9,3],
[42,14,9,4],
[42,14,9,5],
[42,14,9,6],
[42,14,9,7],
[42,14,9,8],
[42,14,11,0],
[42,14,11,1],
[42,14,11,2],
[42,14,11,3],
[42,14,11,4],
[42,14,11,5],
[42,14,11,6],
[42,14,11,7],
[42,14,11,8],
[42,14,11,9],
[42,14,12,0],
[42,14,12,1],
[42,14,12,2],
[42,14,12,3],
[42,14,12,4],
[42,14,12,5],
[42,14,12,6],
[42,14,12,7],
[42,14,12,8],
[42,14,12,9],
[42,15,3,0],
[42,15,3,1],
[42,15,3,2],
[42,15,4,0],
[42,15,4,1],
[42,15,4,2],
[42,15,4,3],
[42,15,5,0],
[42,15,5,1],
[42,15,5,2],
[42,15,5,4],
[42,15,6,0],
[42,15,6,1],
[42,15,6,2],
[42,15,6,4],
[42,15,7,0],
[42,15,7,1],
[42,15,7,2],
[42,15,7,3],
[42,15,7,4],
[42,15,7,5],
[42,15,7,6],
[42,15,8,0],
[42,15,8,1],
[42,15,8,2],
[42,15,8,4],
[42,15,8,7],
[42,15,11,0],
[42,15,11,1],
[42,15,11,2],
[42,15,11,3],
[42,15,11,4],
[42,15,11,5],
[42,15,11,6],
[42,15,11,7],
[42,15,11,8],
[42,15,12,0],
[42,15,12,1],
[42,15,12,2],
[42,15,12,3],
[42,15,12,4],
[42,15,12,5],
[42,15,12,6],
[42,15,12,7],
[42,15,12,8],
[42,15,13,0],
[42,15,13,1],
[42,15,13,2],
[42,15,13,3],
[42,15,13,4],
[42,15,13,5],
[42,15,13,6],
[42,15,13,7],
[42,15,13,8],
[42,15,13,11],
[42,15,13,12],
[42,15,14,0],
[42,15,14,1],
[42,15,14,2],
[42,15,14,3],
[42,15,14,4],
[42,15,14,5],
[42,15,14,6],
[42,15,14,7],
[42,15,14,8],
[42,15,14,11],
[42,15,14,12],
[42,16,3,0],
[42,16,3,1],
[42,16,3,2],
[42,16,4,0],
[42,16,4,1],
[42,16,4,2],
[42,16,4,3],
[42,16,5,0],
[42,16,5,1],
[42,16,5,2],
[42,16,5,4],
[42,16,6,0],
[42,16,6,1],
[42,16,6,2],
[42,16,6,4],
[42,16,7,0],
[42,16,7,1],
[42,16,7,2],
[42,16,7,3],
[42,16,7,4],
[42,16,7,5],
[42,16,7,6],
[42,16,8,0],
[42,16,8,1],
[42,16,8,2],
[42,16,8,4],
[42,16,8,7],
[42,16,9,0],
[42,16,9,1],
[42,16,9,2],
[42,16,9,3],
[42,16,9,4],
[42,16,9,5],
[42,16,9,6],
[42,16,9,7],
[42,16,9,8],
[42,16,11,0],
[42,16,11,1],
[42,16,11,2],
[42,16,11,3],
[42,16,11,4],
[42,16,11,5],
[42,16,11,6],
[42,16,11,7],
[42,16,11,8],
[42,16,11,9],
[42,16,12,0],
[42,16,12,1],
[42,16,12,2],
[42,16,12,3],
[42,16,12,4],
[42,16,12,5],
[42,16,12,6],
[42,16,12,7],
[42,16,12,8],
[42,16,12,9],
[42,16,13,0],
[42,16,13,1],
[42,16,13,2],
[42,16,13,3],
[42,16,13,4],
[42,16,13,5],
[42,16,13,6],
[42,16,13,7],
[42,16,13,8],
[42,16,13,9],
[42,16,13,11],
[42,16,13,12],
[42,16,14,0],
[42,16,14,1],
[42,16,14,2],
[42,16,14,3],
[42,16,14,4],
[42,16,14,5],
[42,16,14,6],
[42,16,14,7],
[42,16,14,8],
[42,16,14,9],
[42,16,14,11],
[42,16,14,12],
[42,16,15,0],
[42,16,15,1],
[42,16,15,2],
[42,16,15,3],
[42,16,15,4],
[42,16,15,5],
[42,16,15,6],
[42,16,15,7],
[42,16,15,8],
[42,16,15,11],
[42,16,15,12],
[42,16,15,13],
[42,16,15,14],
[42,17,3,0],
[42,17,3,1],
[42,17,3,2],
[42,17,4,0],
[42,17,4,1],
[42,17,4,2],
[42,17,4,3],
[42,17,5,0],
[42,17,5,1],
[42,17,5,2],
[42,17,5,4],
[42,17,6,0],
[42,17,6,1],
[42,17,6,2],
[42,17,6,4],
[42,17,7,0],
[42,17,7,1],
[42,17,7,2],
[42,17,7,3],
[42,17,7,4],
[42,17,7,5],
[42,17,7,6],
[42,17,8,0],
[42,17,8,1],
[42,17,8,2],
[42,17,8,4],
[42,17,8,7],
[42,17,9,0],
[42,17,9,1],
[42,17,9,2],
[42,17,9,3],
[42,17,9,4],
[42,17,9,5],
[42,17,9,6],
[42,17,9,7],
[42,17,9,8],
[42,17,11,0],
[42,17,11,1],
[42,17,11,2],
[42,17,11,3],
[42,17,11,4],
[42,17,11,5],
[42,17,11,6],
[42,17,11,7],
[42,17,11,8],
[42,17,11,9],
[42,17,12,0],
[42,17,12,1],
[42,17,12,2],
[42,17,12,3],
[42,17,12,4],
[42,17,12,5],
[42,17,12,6],
[42,17,12,7],
[42,17,12,8],
[42,17,12,9],
[42,17,13,0],
[42,17,13,1],
[42,17,13,2],
[42,17,13,3],
[42,17,13,4],
[42,17,13,5],
[42,17,13,6],
[42,17,13,7],
[42,17,13,8],
[42,17,13,9],
[42,17,13,11],
[42,17,13,12],
[42,17,14,0],
[42,17,14,1],
[42,17,14,2],
[42,17,14,3],
[42,17,14,4],
[42,17,14,5],
[42,17,14,6],
[42,17,14,7],
[42,17,14,8],
[42,17,14,9],
[42,17,14,11],
[42,17,14,12],
[42,17,15,0],
[42,17,15,1],
[42,17,15,2],
[42,17,15,3],
[42,17,15,4],
[42,17,15,5],
[42,17,15,6],
[42,17,15,7],
[42,17,15,8],
[42,17,15,11],
[42,17,15,12],
[42,17,15,13],
[42,17,15,14],
[42,17,16,0],
[42,17,16,1],
[42,17,16,2],
[42,17,16,3],
[42,17,16,4],
[42,17,16,5],
[42,17,16,6],
[42,17,16,7],
[42,17,16,8],
[42,17,16,9],
[42,17,16,11],
[42,17,16,12],
[42,17,16,13],
[42,17,16,14],
[42,17,16,15],
[42,18,3,0],
[42,18,3,1],
[42,18,3,2],
[42,18,4,0],
[42,18,4,1],
[42,18,4,2],
[42,18,4,3],
[42,18,5,0],
[42,18,5,1],
[42,18,5,2],
[42,18,5,4],
[42,18,6,0],
[42,18,6,1],
[42,18,6,2],
[42,18,6,4],
[42,18,7,0],
[42,18,7,1],
[42,18,7,2],
[42,18,7,3],
[42,18,7,4],
[42,18,7,5],
[42,18,7,6],
[42,18,8,0],
[42,18,8,1],
[42,18,8,2],
[42,18,8,4],
[42,18,8,7],
[42,18,11,0],
[42,18,11,1],
[42,18,11,2],
[42,18,11,3],
[42,18,11,4],
[42,18,11,5],
[42,18,11,6],
[42,18,11,7],
[42,18,11,8],
[42,18,12,0],
[42,18,12,1],
[42,18,12,2],
[42,18,12,3],
[42,18,12,4],
[42,18,12,5],
[42,18,12,6],
[42,18,12,7],
[42,18,12,8],
[42,18,13,0],
[42,18,13,1],
[42,18,13,2],
[42,18,13,3],
[42,18,13,4],
[42,18,13,5],
[42,18,13,6],
[42,18,13,7],
[42,18,13,8],
[42,18,13,11],
[42,18,13,12],
[42,18,14,0],
[42,18,14,1],
[42,18,14,2],
[42,18,14,3],
[42,18,14,4],
[42,18,14,5],
[42,18,14,6],
[42,18,14,7],
[42,18,14,8],
[42,18,14,11],
[42,18,14,12],
[42,18,16,0],
[42,18,16,1],
[42,18,16,2],
[42,18,16,3],
[42,18,16,4],
[42,18,16,5],
[42,18,16,6],
[42,18,16,7],
[42,18,16,8],
[42,18,16,11],
[42,18,16,12],
[42,18,16,13],
[42,18,16,14],
[42,18,17,0],
[42,18,17,1],
[42,18,17,2],
[42,18,17,3],
[42,18,17,4],
[42,18,17,5],
[42,18,17,6],
[42,18,17,7],
[42,18,17,8],
[42,18,17,11],
[42,18,17,12],
[42,18,17,13],
[42,18,17,14],
[42,18,17,16],
[42,19,3,0],
[42,19,3,1],
[42,19,3,2],
[42,19,4,0],
[42,19,4,1],
[42,19,4,2],
[42,19,4,3],
[42,19,5,0],
[42,19,5,1],
[42,19,5,2],
[42,19,5,4],
[42,19,6,0],
[42,19,6,1],
[42,19,6,2],
[42,19,6,4],
[42,19,7,0],
[42,19,7,1],
[42,19,7,2],
[42,19,7,3],
[42,19,7,4],
[42,19,7,5],
[42,19,7,6],
[42,19,8,0],
[42,19,8,1],
[42,19,8,2],
[42,19,8,4],
[42,19,8,7],
[42,19,9,0],
[42,19,9,1],
[42,19,9,2],
[42,19,9,3],
[42,19,9,4],
[42,19,9,5],
[42,19,9,6],
[42,19,9,7],
[42,19,9,8],
[42,19,11,0],
[42,19,11,1],
[42,19,11,2],
[42,19,11,3],
[42,19,11,4],
[42,19,11,5],
[42,19,11,6],
[42,19,11,7],
[42,19,11,8],
[42,19,11,9],
[42,19,12,0],
[42,19,12,1],
[42,19,12,2],
[42,19,12,3],
[42,19,12,4],
[42,19,12,5],
[42,19,12,6],
[42,19,12,7],
[42,19,12,8],
[42,19,12,9],
[42,19,15,0],
[42,19,15,1],
[42,19,15,2],
[42,19,15,3],
[42,19,15,4],
[42,19,15,5],
[42,19,15,6],
[42,19,15,7],
[42,19,15,8],
[42,19,15,11],
[42,19,15,12],
[42,19,16,0],
[42,19,16,1],
[42,19,16,2],
[42,19,16,3],
[42,19,16,4],
[42,19,16,5],
[42,19,16,6],
[42,19,16,7],
[42,19,16,8],
[42,19,16,9],
[42,19,16,11],
[42,19,16,12],
[42,19,16,15],
[42,19,17,0],
[42,19,17,1],
[42,19,17,2],
[42,19,17,3],
[42,19,17,4],
[42,19,17,5],
[42,19,17,6],
[42,19,17,7],
[42,19,17,8],
[42,19,17,9],
[42,19,17,11],
[42,19,17,12],
[42,19,17,15],
[42,19,17,16],
[42,19,18,0],
[42,19,18,1],
[42,19,18,2],
[42,19,18,3],
[42,19,18,4],
[42,19,18,5],
[42,19,18,6],
[42,19,18,7],
[42,19,18,8],
[42,19,18,11],
[42,19,18,12],
[42,19,18,16],
[42,19,18,17],
[42,21,4,0],
[42,21,4,1],
[42,21,4,2],
[42,21,7,0],
[42,21,7,1],
[42,21,7,2],
[42,21,7,4],
[42,21,9,0],
[42,21,9,1],
[42,21,9,2],
[42,21,9,4],
[42,21,9,7],
[42,21,11,0],
[42,21,11,1],
[42,21,11,2],
[42,21,11,4],
[42,21,11,7],
[42,21,11,9],
[42,21,12,0],
[42,21,12,1],
[42,21,12,2],
[42,21,12,4],
[42,21,12,7],
[42,21,12,9],
[42,21,13,0],
[42,21,13,1],
[42,21,13,2],
[42,21,13,4],
[42,21,13,7],
[42,21,13,9],
[42,21,13,11],
[42,21,13,12],
[42,21,14,0],
[42,21,14,1],
[42,21,14,2],
[42,21,14,4],
[42,21,14,7],
[42,21,14,9],
[42,21,14,11],
[42,21,14,12],
[42,21,15,0],
[42,21,15,1],
[42,21,15,2],
[42,21,15,4],
[42,21,15,7],
[42,21,15,11],
[42,21,15,12],
[42,21,15,13],
[42,21,15,14],
[42,21,16,0],
[42,21,16,1],
[42,21,16,2],
[42,21,16,4],
[42,21,16,7],
[42,21,16,9],
[42,21,16,11],
[42,21,16,12],
[42,21,16,13],
[42,21,16,14],
[42,21,16,15],
[42,21,17,0],
[42,21,17,1],
[42,21,17,2],
[42,21,17,4],
[42,21,17,7],
[42,21,17,9],
[42,21,17,11],
[42,21,17,12],
[42,21,17,13],
[42,21,17,14],
[42,21,17,15],
[42,21,17,16],
[42,21,18,0],
[42,21,18,1],
[42,21,18,2],
[42,21,18,4],
[42,21,18,7],
[42,21,18,11],
[42,21,18,12],
[42,21,18,13],
[42,21,18,14],
[42,21,18,16],
[42,21,18,17],
[42,21,19,0],
[42,21,19,1],
[42,21,19,2],
[42,21,19,4],
[42,21,19,7],
[42,21,19,9],
[42,21,19,11],
[42,21,19,12],
[42,21,19,15],
[42,21,19,16],
[42,21,19,17],
[42,21,19,18],
[42,23,3,0],
[42,23,3,1],
[42,23,3,2],
[42,23,4,0],
[42,23,4,1],
[42,23,4,2],
[42,23,4,3],
[42,23,5,0],
[42,23,5,1],
[42,23,5,2],
[42,23,5,4],
[42,23,6,0],
[42,23,6,1],
[42,23,6,2],
[42,23,6,4],
[42,23,7,0],
[42,23,7,1],
[42,23,7,2],
[42,23,7,3],
[42,23,7,4],
[42,23,7,5],
[42,23,7,6],
[42,23,8,0],
[42,23,8,1],
[42,23,8,2],
[42,23,8,4],
[42,23,8,7],
[42,23,9,0],
[42,23,9,1],
[42,23,9,2],
[42,23,9,3],
[42,23,9,4],
[42,23,9,5],
[42,23,9,6],
[42,23,9,7],
[42,23,9,8],
[42,23,11,0],
[42,23,11,1],
[42,23,11,2],
[42,23,11,3],
[42,23,11,4],
[42,23,11,5],
[42,23,11,6],
[42,23,11,7],
[42,23,11,8],
[42,23,11,9],
[42,23,12,0],
[42,23,12,1],
[42,23,12,2],
[42,23,12,3],
[42,23,12,4],
[42,23,12,5],
[42,23,12,6],
[42,23,12,7],
[42,23,12,8],
[42,23,12,9],
[42,23,13,0],
[42,23,13,1],
[42,23,13,2],
[42,23,13,3],
[42,23,13,4],
[42,23,13,5],
[42,23,13,6],
[42,23,13,7],
[42,23,13,8],
[42,23,13,9],
[42,23,13,11],
[42,23,13,12],
[42,23,14,0],
[42,23,14,1],
[42,23,14,2],
[42,23,14,3],
[42,23,14,4],
[42,23,14,5],
[42,23,14,6],
[42,23,14,7],
[42,23,14,8],
[42,23,14,9],
[42,23,14,11],
[42,23,14,12],
[42,23,15,0],
[42,23,15,1],
[42,23,15,2],
[42,23,15,3],
[42,23,15,4],
[42,23,15,5],
[42,23,15,6],
[42,23,15,7],
[42,23,15,8],
[42,23,15,11],
[42,23,15,12],
[42,23,15,13],
[42,23,15,14],
[42,23,16,0],
[42,23,16,1],
[42,23,16,2],
[42,23,16,3],
[42,23,16,4],
[42,23,16,5],
[42,23,16,6],
[42,23,16,7],
[42,23,16,8],
[42,23,16,9],
[42,23,16,11],
[42,23,16,12],
[42,23,16,13],
[42,23,16,14],
[42,23,16,15],
[42,23,17,0],
[42,23,17,1],
[42,23,17,2],
[42,23,17,3],
[42,23,17,4],
[42,23,17,5],
[42,23,17,6],
[42,23,17,7],
[42,23,17,8],
[42,23,17,9],
[42,23,17,11],
[42,23,17,12],
[42,23,17,13],
[42,23,17,14],
[42,23,17,15],
[42,23,17,16],
[42,23,18,0],
[42,23,18,1],
[42,23,18,2],
[42,23,18,3],
[42,23,18,4],
[42,23,18,5],
[42,23,18,6],
[42,23,18,7],
[42,23,18,8],
[42,23,18,11],
[42,23,18,12],
[42,23,18,13],
[42,23,18,14],
[42,23,18,16],
[42,23,18,17],
[42,23,19,0],
[42,23,19,1],
[42,23,19,2],
[42,23,19,3],
[42,23,19,4],
[42,23,19,5],
[42,23,19,6],
[42,23,19,7],
[42,23,19,8],
[42,23,19,9],
[42,23,19,11],
[42,23,19,12],
[42,23,19,15],
[42,23,19,16],
[42,23,19,17],
[42,23,19,18],
[42,23,21,0],
[42,23,21,1],
[42,23,21,2],
[42,23,21,4],
[42,23,21,7],
[42,23,21,9],
[42,23,21,11],
[42,23,21,12],
[42,23,21,13],
[42,23,21,14],
[42,23,21,15],
[42,23,21,16],
[42,23,21,17],
[42,23,21,18],
[42,23,21,19],
[42,24,3,0],
[42,24,3,1],
[42,24,3,2],
[42,24,4,0],
[42,24,4,1],
[42,24,4,2],
[42,24,4,3],
[42,24,5,0],
[42,24,5,1],
[42,24,5,2],
[42,24,5,4],
[42,24,6,0],
[42,24,6,1],
[42,24,6,2],
[42,24,6,4],
[42,24,7,0],
[42,24,7,1],
[42,24,7,2],
[42,24,7,3],
[42,24,7,4],
[42,24,7,5],
[42,24,7,6],
[42,24,8,0],
[42,24,8,1],
[42,24,8,2],
[42,24,8,4],
[42,24,8,7],
[42,24,9,0],
[42,24,9,1],
[42,24,9,2],
[42,24,9,3],
[42,24,9,4],
[42,24,9,5],
[42,24,9,6],
[42,24,9,7],
[42,24,9,8],
[42,24,11,0],
[42,24,11,1],
[42,24,11,2],
[42,24,11,3],
[42,24,11,4],
[42,24,11,5],
[42,24,11,6],
[42,24,11,7],
[42,24,11,8],
[42,24,11,9],
[42,24,12,0],
[42,24,12,1],
[42,24,12,2],
[42,24,12,3],
[42,24,12,4],
[42,24,12,5],
[42,24,12,6],
[42,24,12,7],
[42,24,12,8],
[42,24,12,9],
[42,24,13,0],
[42,24,13,1],
[42,24,13,2],
[42,24,13,3],
[42,24,13,4],
[42,24,13,5],
[42,24,13,6],
[42,24,13,7],
[42,24,13,8],
[42,24,13,9],
[42,24,13,11],
[42,24,13,12],
[42,24,14,0],
[42,24,14,1],
[42,24,14,2],
[42,24,14,3],
[42,24,14,4],
[42,24,14,5],
[42,24,14,6],
[42,24,14,7],
[42,24,14,8],
[42,24,14,9],
[42,24,14,11],
[42,24,14,12],
[42,24,15,0],
[42,24,15,1],
[42,24,15,2],
[42,24,15,3],
[42,24,15,4],
[42,24,15,5],
[42,24,15,6],
[42,24,15,7],
[42,24,15,8],
[42,24,15,11],
[42,24,15,12],
[42,24,15,13],
[42,24,15,14],
[42,24,17,0],
[42,24,17,1],
[42,24,17,2],
[42,24,17,3],
[42,24,17,4],
[42,24,17,5],
[42,24,17,6],
[42,24,17,7],
[42,24,17,8],
[42,24,17,9],
[42,24,17,11],
[42,24,17,12],
[42,24,17,13],
[42,24,17,14],
[42,24,17,15],
[42,24,18,0],
[42,24,18,1],
[42,24,18,2],
[42,24,18,3],
[42,24,18,4],
[42,24,18,5],
[42,24,18,6],
[42,24,18,7],
[42,24,18,8],
[42,24,18,11],
[42,24,18,12],
[42,24,18,13],
[42,24,18,14],
[42,24,18,17],
[42,24,19,0],
[42,24,19,1],
[42,24,19,2],
[42,24,19,3],
[42,24,19,4],
[42,24,19,5],
[42,24,19,6],
[42,24,19,7],
[42,24,19,8],
[42,24,19,9],
[42,24,19,11],
[42,24,19,12],
[42,24,19,15],
[42,24,19,17],
[42,24,19,18],
[42,24,21,0],
[42,24,21,1],
[42,24,21,2],
[42,24,21,4],
[42,24,21,7],
[42,24,21,9],
[42,24,21,11],
[42,24,21,12],
[42,24,21,13],
[42,24,21,14],
[42,24,21,15],
[42,24,21,17],
[42,24,21,18],
[42,24,21,19],
[42,24,23,0],
[42,24,23,1],
[42,24,23,2],
[42,24,23,3],
[42,24,23,4],
[42,24,23,5],
[42,24,23,6],
[42,24,23,7],
[42,24,23,8],
[42,24,23,9],
[42,24,23,11],
[42,24,23,12],
[42,24,23,13],
[42,24,23,14],
[42,24,23,15],
[42,24,23,17],
[42,24,23,18],
[42,24,23,19],
[42,24,23,21],
[42,25,3,0],
[42,25,3,1],
[42,25,3,2],
[42,25,4,0],
[42,25,4,1],
[42,25,4,2],
[42,25,4,3],
[42,25,5,0],
[42,25,5,1],
[42,25,5,2],
[42,25,5,4],
[42,25,6,0],
[42,25,6,1],
[42,25,6,2],
[42,25,6,4],
[42,25,8,0],
[42,25,8,1],
[42,25,8,2],
[42,25,8,4],
[42,25,9,0],
[42,25,9,1],
[42,25,9,2],
[42,25,9,3],
[42,25,9,4],
[42,25,9,5],
[42,25,9,6],
[42,25,9,8],
[42,25,11,0],
[42,25,11,1],
[42,25,11,2],
[42,25,11,3],
[42,25,11,4],
[42,25,11,5],
[42,25,11,6],
[42,25,11,8],
[42,25,11,9],
[42,25,12,0],
[42,25,12,1],
[42,25,12,2],
[42,25,12,3],
[42,25,12,4],
[42,25,12,5],
[42,25,12,6],
[42,25,12,8],
[42,25,12,9],
[42,25,13,0],
[42,25,13,1],
[42,25,13,2],
[42,25,13,3],
[42,25,13,4],
[42,25,13,5],
[42,25,13,6],
[42,25,13,8],
[42,25,13,9],
[42,25,13,11],
[42,25,13,12],
[42,25,14,0],
[42,25,14,1],
[42,25,14,2],
[42,25,14,3],
[42,25,14,4],
[42,25,14,5],
[42,25,14,6],
[42,25,14,8],
[42,25,14,9],
[42,25,14,11],
[42,25,14,12],
[42,25,15,0],
[42,25,15,1],
[42,25,15,2],
[42,25,15,3],
[42,25,15,4],
[42,25,15,5],
[42,25,15,6],
[42,25,15,8],
[42,25,15,11],
[42,25,15,12],
[42,25,15,13],
[42,25,15,14],
[42,25,16,0],
[42,25,16,1],
[42,25,16,2],
[42,25,16,3],
[42,25,16,4],
[42,25,16,5],
[42,25,16,6],
[42,25,16,8],
[42,25,16,9],
[42,25,16,11],
[42,25,16,12],
[42,25,16,13],
[42,25,16,14],
[42,25,16,15],
[42,25,17,0],
[42,25,17,1],
[42,25,17,2],
[42,25,17,3],
[42,25,17,4],
[42,25,17,5],
[42,25,17,6],
[42,25,17,8],
[42,25,17,9],
[42,25,17,11],
[42,25,17,12],
[42,25,17,13],
[42,25,17,14],
[42,25,17,15],
[42,25,17,16],
[42,25,18,0],
[42,25,18,1],
[42,25,18,2],
[42,25,18,3],
[42,25,18,4],
[42,25,18,5],
[42,25,18,6],
[42,25,18,8],
[42,25,18,11],
[42,25,18,12],
[42,25,18,13],
[42,25,18,14],
[42,25,18,16],
[42,25,18,17],
[42,25,19,0],
[42,25,19,1],
[42,25,19,2],
[42,25,19,3],
[42,25,19,4],
[42,25,19,5],
[42,25,19,6],
[42,25,19,8],
[42,25,19,9],
[42,25,19,11],
[42,25,19,12],
[42,25,19,15],
[42,25,19,16],
[42,25,19,17],
[42,25,19,18],
[42,25,21,0],
[42,25,21,1],
[42,25,21,2],
[42,25,21,4],
[42,25,21,9],
[42,25,21,11],
[42,25,21,12],
[42,25,21,13],
[42,25,21,14],
[42,25,21,15],
[42,25,21,16],
[42,25,21,17],
[42,25,21,18],
[42,25,21,19],
[42,25,23,0],
[42,25,23,1],
[42,25,23,2],
[42,25,23,3],
[42,25,23,4],
[42,25,23,5],
[42,25,23,6],
[42,25,23,8],
[42,25,23,9],
[42,25,23,11],
[42,25,23,12],
[42,25,23,13],
[42,25,23,14],
[42,25,23,15],
[42,25,23,16],
[42,25,23,17],
[42,25,23,18],
[42,25,23,19],
[42,25,23,21],
[42,25,24,0],
[42,25,24,1],
[42,25,24,2],
[42,25,24,3],
[42,25,24,4],
[42,25,24,5],
[42,25,24,6],
[42,25,24,8],
[42,25,24,9],
[42,25,24,11],
[42,25,24,12],
[42,25,24,13],
[42,25,24,14],
[42,25,24,15],
[42,25,24,17],
[42,25,24,18],
[42,25,24,19],
[42,25,24,21],
[42,25,24,23],
[42,26,3,0],
[42,26,3,1],
[42,26,3,2],
[42,26,4,0],
[42,26,4,1],
[42,26,4,2],
[42,26,4,3],
[42,26,5,0],
[42,26,5,1],
[42,26,5,2],
[42,26,5,4],
[42,26,6,0],
[42,26,6,1],
[42,26,6,2],
[42,26,6,4],
[42,26,7,0],
[42,26,7,1],
[42,26,7,2],
[42,26,7,3],
[42,26,7,4],
[42,26,7,5],
[42,26,7,6],
[42,26,8,0],
[42,26,8,1],
[42,26,8,2],
[42,26,8,4],
[42,26,8,7],
[42,26,11,0],
[42,26,11,1],
[42,26,11,2],
[42,26,11,3],
[42,26,11,4],
[42,26,11,5],
[42,26,11,6],
[42,26,11,7],
[42,26,11,8],
[42,26,12,0],
[42,26,12,1],
[42,26,12,2],
[42,26,12,3],
[42,26,12,4],
[42,26,12,5],
[42,26,12,6],
[42,26,12,7],
[42,26,12,8],
[42,26,13,0],
[42,26,13,1],
[42,26,13,2],
[42,26,13,3],
[42,26,13,4],
[42,26,13,5],
[42,26,13,6],
[42,26,13,7],
[42,26,13,8],
[42,26,13,11],
[42,26,13,12],
[42,26,14,0],
[42,26,14,1],
[42,26,14,2],
[42,26,14,3],
[42,26,14,4],
[42,26,14,5],
[42,26,14,6],
[42,26,14,7],
[42,26,14,8],
[42,26,14,11],
[42,26,14,12],
[42,26,16,0],
[42,26,16,1],
[42,26,16,2],
[42,26,16,3],
[42,26,16,4],
[42,26,16,5],
[42,26,16,6],
[42,26,16,7],
[42,26,16,8],
[42,26,16,11],
[42,26,16,12],
[42,26,16,13],
[42,26,16,14],
[42,26,17,0],
[42,26,17,1],
[42,26,17,2],
[42,26,17,3],
[42,26,17,4],
[42,26,17,5],
[42,26,17,6],
[42,26,17,7],
[42,26,17,8],
[42,26,17,11],
[42,26,17,12],
[42,26,17,13],
[42,26,17,14],
[42,26,17,16],
[42,26,19,0],
[42,26,19,1],
[42,26,19,2],
[42,26,19,3],
[42,26,19,4],
[42,26,19,5],
[42,26,19,6],
[42,26,19,7],
[42,26,19,8],
[42,26,19,11],
[42,26,19,12],
[42,26,19,16],
[42,26,19,17],
[42,26,21,0],
[42,26,21,1],
[42,26,21,2],
[42,26,21,4],
[42,26,21,7],
[42,26,21,11],
[42,26,21,12],
[42,26,21,13],
[42,26,21,14],
[42,26,21,16],
[42,26,21,17],
[42,26,21,19],
[42,26,23,0],
[42,26,23,1],
[42,26,23,2],
[42,26,23,3],
[42,26,23,4],
[42,26,23,5],
[42,26,23,6],
[42,26,23,7],
[42,26,23,8],
[42,26,23,11],
[42,26,23,12],
[42,26,23,13],
[42,26,23,14],
[42,26,23,16],
[42,26,23,17],
[42,26,23,19],
[42,26,23,21],
[42,26,24,0],
[42,26,24,1],
[42,26,24,2],
[42,26,24,3],
[42,26,24,4],
[42,26,24,5],
[42,26,24,6],
[42,26,24,7],
[42,26,24,8],
[42,26,24,11],
[42,26,24,12],
[42,26,24,13],
[42,26,24,14],
[42,26,24,17],
[42,26,24,19],
[42,26,24,21],
[42,26,24,23],
[42,26,25,0],
[42,26,25,1],
[42,26,25,2],
[42,26,25,3],
[42,26,25,4],
[42,26,25,5],
[42,26,25,6],
[42,26,25,8],
[42,26,25,11],
[42,26,25,12],
[42,26,25,13],
[42,26,25,14],
[42,26,25,16],
[42,26,25,17],
[42,26,25,19],
[42,26,25,21],
[42,26,25,23],
[42,26,25,24],
[42,28,3,0],
[42,28,3,1],
[42,28,3,2],
[42,28,4,0],
[42,28,4,1],
[42,28,4,2],
[42,28,4,3],
[42,28,5,0],
[42,28,5,1],
[42,28,5,2],
[42,28,5,4],
[42,28,6,0],
[42,28,6,1],
[42,28,6,2],
[42,28,6,4],
[42,28,8,0],
[42,28,8,1],
[42,28,8,2],
[42,28,8,4],
[42,28,9,0],
[42,28,9,1],
[42,28,9,2],
[42,28,9,3],
[42,28,9,4],
[42,28,9,5],
[42,28,9,6],
[42,28,9,8],
[42,28,11,0],
[42,28,11,1],
[42,28,11,2],
[42,28,11,3],
[42,28,11,4],
[42,28,11,5],
[42,28,11,6],
[42,28,11,8],
[42,28,11,9],
[42,28,12,0],
[42,28,12,1],
[42,28,12,2],
[42,28,12,3],
[42,28,12,4],
[42,28,12,5],
[42,28,12,6],
[42,28,12,8],
[42,28,12,9],
[42,28,13,0],
[42,28,13,1],
[42,28,13,2],
[42,28,13,3],
[42,28,13,4],
[42,28,13,5],
[42,28,13,6],
[42,28,13,8],
[42,28,13,9],
[42,28,13,11],
[42,28,13,12],
[42,28,14,0],
[42,28,14,1],
[42,28,14,2],
[42,28,14,3],
[42,28,14,4],
[42,28,14,5],
[42,28,14,6],
[42,28,14,8],
[42,28,14,9],
[42,28,14,11],
[42,28,14,12],
[42,28,15,0],
[42,28,15,1],
[42,28,15,2],
[42,28,15,3],
[42,28,15,4],
[42,28,15,5],
[42,28,15,6],
[42,28,15,8],
[42,28,15,11],
[42,28,15,12],
[42,28,15,13],
[42,28,15,14],
[42,28,16,0],
[42,28,16,1],
[42,28,16,2],
[42,28,16,3],
[42,28,16,4],
[42,28,16,5],
[42,28,16,6],
[42,28,16,8],
[42,28,16,9],
[42,28,16,11],
[42,28,16,12],
[42,28,16,13],
[42,28,16,14],
[42,28,16,15],
[42,28,17,0],
[42,28,17,1],
[42,28,17,2],
[42,28,17,3],
[42,28,17,4],
[42,28,17,5],
[42,28,17,6],
[42,28,17,8],
[42,28,17,9],
[42,28,17,11],
[42,28,17,12],
[42,28,17,13],
[42,28,17,14],
[42,28,17,15],
[42,28,17,16],
[42,28,18,0],
[42,28,18,1],
[42,28,18,2],
[42,28,18,3],
[42,28,18,4],
[42,28,18,5],
[42,28,18,6],
[42,28,18,8],
[42,28,18,11],
[42,28,18,12],
[42,28,18,13],
[42,28,18,14],
[42,28,18,16],
[42,28,18,17],
[42,28,19,0],
[42,28,19,1],
[42,28,19,2],
[42,28,19,3],
[42,28,19,4],
[42,28,19,5],
[42,28,19,6],
[42,28,19,8],
[42,28,19,9],
[42,28,19,11],
[42,28,19,12],
[42,28,19,15],
[42,28,19,16],
[42,28,19,17],
[42,28,19,18],
[42,28,21,0],
[42,28,21,1],
[42,28,21,2],
[42,28,21,4],
[42,28,21,9],
[42,28,21,11],
[42,28,21,12],
[42,28,21,13],
[42,28,21,14],
[42,28,21,15],
[42,28,21,16],
[42,28,21,17],
[42,28,21,18],
[42,28,21,19],
[42,28,23,0],
[42,28,23,1],
[42,28,23,2],
[42,28,23,3],
[42,28,23,4],
[42,28,23,5],
[42,28,23,6],
[42,28,23,8],
[42,28,23,9],
[42,28,23,11],
[42,28,23,12],
[42,28,23,13],
[42,28,23,14],
[42,28,23,15],
[42,28,23,16],
[42,28,23,17],
[42,28,23,18],
[42,28,23,19],
[42,28,23,21],
[42,28,24,0],
[42,28,24,1],
[42,28,24,2],
[42,28,24,3],
[42,28,24,4],
[42,28,24,5],
[42,28,24,6],
[42,28,24,8],
[42,28,24,9],
[42,28,24,11],
[42,28,24,12],
[42,28,24,13],
[42,28,24,14],
[42,28,24,15],
[42,28,24,17],
[42,28,24,18],
[42,28,24,19],
[42,28,24,21],
[42,28,24,23],
[42,28,26,0],
[42,28,26,1],
[42,28,26,2],
[42,28,26,3],
[42,28,26,4],
[42,28,26,5],
[42,28,26,6],
[42,28,26,8],
[42,28,26,11],
[42,28,26,12],
[42,28,26,13],
[42,28,26,14],
[42,28,26,16],
[42,28,26,17],
[42,28,26,19],
[42,28,26,21],
[42,28,26,23],
[42,28,26,24],
[42,29,3,0],
[42,29,3,1],
[42,29,3,2],
[42,29,4,0],
[42,29,4,1],
[42,29,4,2],
[42,29,4,3],
[42,29,5,0],
[42,29,5,1],
[42,29,5,2],
[42,29,5,4],
[42,29,6,0],
[42,29,6,1],
[42,29,6,2],
[42,29,6,4],
[42,29,7,0],
[42,29,7,1],
[42,29,7,2],
[42,29,7,3],
[42,29,7,4],
[42,29,7,5],
[42,29,7,6],
[42,29,8,0],
[42,29,8,1],
[42,29,8,2],
[42,29,8,4],
[42,29,8,7],
[42,29,9,0],
[42,29,9,1],
[42,29,9,2],
[42,29,9,3],
[42,29,9,4],
[42,29,9,5],
[42,29,9,6],
[42,29,9,7],
[42,29,9,8],
[42,29,13,0],
[42,29,13,1],
[42,29,13,2],
[42,29,13,3],
[42,29,13,4],
[42,29,13,5],
[42,29,13,6],
[42,29,13,7],
[42,29,13,8],
[42,29,13,9],
[42,29,14,0],
[42,29,14,1],
[42,29,14,2],
[42,29,14,3],
[42,29,14,4],
[42,29,14,5],
[42,29,14,6],
[42,29,14,7],
[42,29,14,8],
[42,29,14,9],
[42,29,15,0],
[42,29,15,1],
[42,29,15,2],
[42,29,15,3],
[42,29,15,4],
[42,29,15,5],
[42,29,15,6],
[42,29,15,7],
[42,29,15,8],
[42,29,15,13],
[42,29,15,14],
[42,29,16,0],
[42,29,16,1],
[42,29,16,2],
[42,29,16,3],
[42,29,16,4],
[42,29,16,5],
[42,29,16,6],
[42,29,16,7],
[42,29,16,8],
[42,29,16,9],
[42,29,16,13],
[42,29,16,14],
[42,29,16,15],
[42,29,17,0],
[42,29,17,1],
[42,29,17,2],
[42,29,17,3],
[42,29,17,4],
[42,29,17,5],
[42,29,17,6],
[42,29,17,7],
[42,29,17,8],
[42,29,17,9],
[42,29,17,13],
[42,29,17,14],
[42,29,17,15],
[42,29,17,16],
[42,29,18,0],
[42,29,18,1],
[42,29,18,2],
[42,29,18,3],
[42,29,18,4],
[42,29,18,5],
[42,29,18,6],
[42,29,18,7],
[42,29,18,8],
[42,29,18,13],
[42,29,18,14],
[42,29,18,16],
[42,29,18,17],
[42,29,19,0],
[42,29,19,1],
[42,29,19,2],
[42,29,19,3],
[42,29,19,4],
[42,29,19,5],
[42,29,19,6],
[42,29,19,7],
[42,29,19,8],
[42,29,19,9],
[42,29,19,15],
[42,29,19,16],
[42,29,19,17],
[42,29,19,18],
[42,29,21,0],
[42,29,21,1],
[42,29,21,2],
[42,29,21,4],
[42,29,21,7],
[42,29,21,9],
[42,29,21,13],
[42,29,21,14],
[42,29,21,15],
[42,29,21,16],
[42,29,21,17],
[42,29,21,18],
[42,29,21,19],
[42,29,23,0],
[42,29,23,1],
[42,29,23,2],
[42,29,23,3],
[42,29,23,4],
[42,29,23,5],
[42,29,23,6],
[42,29,23,7],
[42,29,23,8],
[42,29,23,9],
[42,29,23,13],
[42,29,23,14],
[42,29,23,15],
[42,29,23,16],
[42,29,23,17],
[42,29,23,18],
[42,29,23,19],
[42,29,23,21],
[42,29,24,0],
[42,29,24,1],
[42,29,24,2],
[42,29,24,3],
[42,29,24,4],
[42,29,24,5],
[42,29,24,6],
[42,29,24,7],
[42,29,24,8],
[42,29,24,9],
[42,29,24,13],
[42,29,24,14],
[42,29,24,15],
[42,29,24,17],
[42,29,24,18],
[42,29,24,19],
[42,29,24,21],
[42,29,24,23],
[42,29,25,0],
[42,29,25,1],
[42,29,25,2],
[42,29,25,3],
[42,29,25,4],
[42,29,25,5],
[42,29,25,6],
[42,29,25,8],
[42,29,25,9],
[42,29,25,13],
[42,29,25,14],
[42,29,25,15],
[42,29,25,16],
[42,29,25,17],
[42,29,25,18],
[42,29,25,19],
[42,29,25,21],
[42,29,25,23],
[42,29,25,24],
[42,29,26,0],
[42,29,26,1],
[42,29,26,2],
[42,29,26,3],
[42,29,26,4],
[42,29,26,5],
[42,29,26,6],
[42,29,26,7],
[42,29,26,8],
[42,29,26,13],
[42,29,26,14],
[42,29,26,16],
[42,29,26,17],
[42,29,26,19],
[42,29,26,21],
[42,29,26,23],
[42,29,26,24],
[42,29,26,25],
[42,29,28,0],
[42,29,28,1],
[42,29,28,2],
[42,29,28,3],
[42,29,28,4],
[42,29,28,5],
[42,29,28,6],
[42,29,28,8],
[42,29,28,9],
[42,29,28,13],
[42,29,28,14],
[42,29,28,15],
[42,29,28,16],
[42,29,28,17],
[42,29,28,18],
[42,29,28,19],
[42,29,28,21],
[42,29,28,23],
[42,29,28,24],
[42,29,28,26],
[42,30,3,0],
[42,30,3,1],
[42,30,3,2],
[42,30,4,0],
[42,30,4,1],
[42,30,4,2],
[42,30,4,3],
[42,30,5,0],
[42,30,5,1],
[42,30,5,2],
[42,30,5,4],
[42,30,6,0],
[42,30,6,1],
[42,30,6,2],
[42,30,6,4],
[42,30,7,0],
[42,30,7,1],
[42,30,7,2],
[42,30,7,3],
[42,30,7,4],
[42,30,7,5],
[42,30,7,6],
[42,30,8,0],
[42,30,8,1],
[42,30,8,2],
[42,30,8,4],
[42,30,8,7],
[42,30,9,0],
[42,30,9,1],
[42,30,9,2],
[42,30,9,3],
[42,30,9,4],
[42,30,9,5],
[42,30,9,6],
[42,30,9,7],
[42,30,9,8],
[42,30,11,0],
[42,30,11,1],
[42,30,11,2],
[42,30,11,3],
[42,30,11,4],
[42,30,11,5],
[42,30,11,6],
[42,30,11,7],
[42,30,11,8],
[42,30,11,9],
[42,30,12,0],
[42,30,12,1],
[42,30,12,2],
[42,30,12,3],
[42,30,12,4],
[42,30,12,5],
[42,30,12,6],
[42,30,12,7],
[42,30,12,8],
[42,30,12,9],
[42,30,13,0],
[42,30,13,1],
[42,30,13,2],
[42,30,13,3],
[42,30,13,4],
[42,30,13,5],
[42,30,13,6],
[42,30,13,7],
[42,30,13,8],
[42,30,13,9],
[42,30,13,11],
[42,30,13,12],
[42,30,14,0],
[42,30,14,1],
[42,30,14,2],
[42,30,14,3],
[42,30,14,4],
[42,30,14,5],
[42,30,14,6],
[42,30,14,7],
[42,30,14,8],
[42,30,14,9],
[42,30,14,11],
[42,30,14,12],
[42,30,15,0],
[42,30,15,1],
[42,30,15,2],
[42,30,15,3],
[42,30,15,4],
[42,30,15,5],
[42,30,15,6],
[42,30,15,7],
[42,30,15,8],
[42,30,15,11],
[42,30,15,12],
[42,30,15,13],
[42,30,15,14],
[42,30,16,0],
[42,30,16,1],
[42,30,16,2],
[42,30,16,3],
[42,30,16,4],
[42,30,16,5],
[42,30,16,6],
[42,30,16,7],
[42,30,16,8],
[42,30,16,9],
[42,30,16,11],
[42,30,16,12],
[42,30,16,13],
[42,30,16,14],
[42,30,16,15],
[42,30,17,0],
[42,30,17,1],
[42,30,17,2],
[42,30,17,3],
[42,30,17,4],
[42,30,17,5],
[42,30,17,6],
[42,30,17,7],
[42,30,17,8],
[42,30,17,9],
[42,30,17,11],
[42,30,17,12],
[42,30,17,13],
[42,30,17,14],
[42,30,17,15],
[42,30,17,16],
[42,30,18,0],
[42,30,18,1],
[42,30,18,2],
[42,30,18,3],
[42,30,18,4],
[42,30,18,5],
[42,30,18,6],
[42,30,18,7],
[42,30,18,8],
[42,30,18,11],
[42,30,18,12],
[42,30,18,13],
[42,30,18,14],
[42,30,18,16],
[42,30,18,17],
[42,30,19,0],
[42,30,19,1],
[42,30,19,2],
[42,30,19,3],
[42,30,19,4],
[42,30,19,5],
[42,30,19,6],
[42,30,19,7],
[42,30,19,8],
[42,30,19,9],
[42,30,19,11],
[42,30,19,12],
[42,30,19,15],
[42,30,19,16],
[42,30,19,17],
[42,30,19,18],
[42,30,21,0],
[42,30,21,1],
[42,30,21,2],
[42,30,21,4],
[42,30,21,7],
[42,30,21,9],
[42,30,21,11],
[42,30,21,12],
[42,30,21,13],
[42,30,21,14],
[42,30,21,15],
[42,30,21,16],
[42,30,21,17],
[42,30,21,18],
[42,30,21,19],
[42,30,24,0],
[42,30,24,1],
[42,30,24,2],
[42,30,24,3],
[42,30,24,4],
[42,30,24,5],
[42,30,24,6],
[42,30,24,7],
[42,30,24,8],
[42,30,24,9],
[42,30,24,11],
[42,30,24,12],
[42,30,24,13],
[42,30,24,14],
[42,30,24,15],
[42,30,24,17],
[42,30,24,18],
[42,30,24,19],
[42,30,24,21],
[42,30,25,0],
[42,30,25,1],
[42,30,25,2],
[42,30,25,3],
[42,30,25,4],
[42,30,25,5],
[42,30,25,6],
[42,30,25,8],
[42,30,25,9],
[42,30,25,11],
[42,30,25,12],
[42,30,25,13],
[42,30,25,14],
[42,30,25,15],
[42,30,25,16],
[42,30,25,17],
[42,30,25,18],
[42,30,25,19],
[42,30,25,21],
[42,30,25,24],
[42,30,26,0],
[42,30,26,1],
[42,30,26,2],
[42,30,26,3],
[42,30,26,4],
[42,30,26,5],
[42,30,26,6],
[42,30,26,7],
[42,30,26,8],
[42,30,26,11],
[42,30,26,12],
[42,30,26,13],
[42,30,26,14],
[42,30,26,16],
[42,30,26,17],
[42,30,26,19],
[42,30,26,21],
[42,30,26,24],
[42,30,26,25],
[42,30,28,0],
[42,30,28,1],
[42,30,28,2],
[42,30,28,3],
[42,30,28,4],
[42,30,28,5],
[42,30,28,6],
[42,30,28,8],
[42,30,28,9],
[42,30,28,11],
[42,30,28,12],
[42,30,28,13],
[42,30,28,14],
[42,30,28,15],
[42,30,28,16],
[42,30,28,17],
[42,30,28,18],
[42,30,28,19],
[42,30,28,21],
[42,30,28,24],
[42,30,28,26],
[42,30,29,0],
[42,30,29,1],
[42,30,29,2],
[42,30,29,3],
[42,30,29,4],
[42,30,29,5],
[42,30,29,6],
[42,30,29,7],
[42,30,29,8],
[42,30,29,9],
[42,30,29,13],
[42,30,29,14],
[42,30,29,15],
[42,30,29,16],
[42,30,29,17],
[42,30,29,18],
[42,30,29,19],
[42,30,29,21],
[42,30,29,24],
[42,30,29,25],
[42,30,29,26],
[42,30,29,28],
[42,31,3,0],
[42,31,3,1],
[42,31,3,2],
[42,31,4,0],
[42,31,4,1],
[42,31,4,2],
[42,31,4,3],
[42,31,5,0],
[42,31,5,1],
[42,31,5,2],
[42,31,5,4],
[42,31,6,0],
[42,31,6,1],
[42,31,6,2],
[42,31,6,4],
[42,31,7,0],
[42,31,7,1],
[42,31,7,2],
[42,31,7,3],
[42,31,7,4],
[42,31,7,5],
[42,31,7,6],
[42,31,8,0],
[42,31,8,1],
[42,31,8,2],
[42,31,8,4],
[42,31,8,7],
[42,31,9,0],
[42,31,9,1],
[42,31,9,2],
[42,31,9,3],
[42,31,9,4],
[42,31,9,5],
[42,31,9,6],
[42,31,9,7],
[42,31,9,8],
[42,31,11,0],
[42,31,11,1],
[42,31,11,2],
[42,31,11,3],
[42,31,11,4],
[42,31,11,5],
[42,31,11,6],
[42,31,11,7],
[42,31,11,8],
[42,31,11,9],
[42,31,12,0],
[42,31,12,1],
[42,31,12,2],
[42,31,12,3],
[42,31,12,4],
[42,31,12,5],
[42,31,12,6],
[42,31,12,7],
[42,31,12,8],
[42,31,12,9],
[42,31,13,0],
[42,31,13,1],
[42,31,13,2],
[42,31,13,3],
[42,31,13,4],
[42,31,13,5],
[42,31,13,6],
[42,31,13,7],
[42,31,13,8],
[42,31,13,9],
[42,31,13,11],
[42,31,13,12],
[42,31,14,0],
[42,31,14,1],
[42,31,14,2],
[42,31,14,3],
[42,31,14,4],
[42,31,14,5],
[42,31,14,6],
[42,31,14,7],
[42,31,14,8],
[42,31,14,9],
[42,31,14,11],
[42,31,14,12],
[42,31,15,0],
[42,31,15,1],
[42,31,15,2],
[42,31,15,3],
[42,31,15,4],
[42,31,15,5],
[42,31,15,6],
[42,31,15,7],
[42,31,15,8],
[42,31,15,11],
[42,31,15,12],
[42,31,15,13],
[42,31,15,14],
[42,31,16,0],
[42,31,16,1],
[42,31,16,2],
[42,31,16,3],
[42,31,16,4],
[42,31,16,5],
[42,31,16,6],
[42,31,16,7],
[42,31,16,8],
[42,31,16,9],
[42,31,16,11],
[42,31,16,12],
[42,31,16,13],
[42,31,16,14],
[42,31,16,15],
[42,31,17,0],
[42,31,17,1],
[42,31,17,2],
[42,31,17,3],
[42,31,17,4],
[42,31,17,5],
[42,31,17,6],
[42,31,17,7],
[42,31,17,8],
[42,31,17,9],
[42,31,17,11],
[42,31,17,12],
[42,31,17,13],
[42,31,17,14],
[42,31,17,15],
[42,31,17,16],
[42,31,18,0],
[42,31,18,1],
[42,31,18,2],
[42,31,18,3],
[42,31,18,4],
[42,31,18,5],
[42,31,18,6],
[42,31,18,7],
[42,31,18,8],
[42,31,18,11],
[42,31,18,12],
[42,31,18,13],
[42,31,18,14],
[42,31,18,16],
[42,31,18,17],
[42,31,19,0],
[42,31,19,1],
[42,31,19,2],
[42,31,19,3],
[42,31,19,4],
[42,31,19,5],
[42,31,19,6],
[42,31,19,7],
[42,31,19,8],
[42,31,19,9],
[42,31,19,11],
[42,31,19,12],
[42,31,19,15],
[42,31,19,16],
[42,31,19,17],
[42,31,19,18],
[42,31,21,0],
[42,31,21,1],
[42,31,21,2],
[42,31,21,4],
[42,31,21,7],
[42,31,21,9],
[42,31,21,11],
[42,31,21,12],
[42,31,21,13],
[42,31,21,14],
[42,31,21,15],
[42,31,21,16],
[42,31,21,17],
[42,31,21,18],
[42,31,21,19],
[42,31,24,0],
[42,31,24,1],
[42,31,24,2],
[42,31,24,3],
[42,31,24,4],
[42,31,24,5],
[42,31,24,6],
[42,31,24,7],
[42,31,24,8],
[42,31,24,9],
[42,31,24,11],
[42,31,24,12],
[42,31,24,13],
[42,31,24,14],
[42,31,24,15],
[42,31,24,17],
[42,31,24,18],
[42,31,24,19],
[42,31,24,21],
[42,31,25,0],
[42,31,25,1],
[42,31,25,2],
[42,31,25,3],
[42,31,25,4],
[42,31,25,5],
[42,31,25,6],
[42,31,25,8],
[42,31,25,9],
[42,31,25,11],
[42,31,25,12],
[42,31,25,13],
[42,31,25,14],
[42,31,25,15],
[42,31,25,16],
[42,31,25,17],
[42,31,25,18],
[42,31,25,19],
[42,31,25,21],
[42,31,25,24],
[42,31,26,0],
[42,31,26,1],
[42,31,26,2],
[42,31,26,3],
[42,31,26,4],
[42,31,26,5],
[42,31,26,6],
[42,31,26,7],
[42,31,26,8],
[42,31,26,11],
[42,31,26,12],
[42,31,26,13],
[42,31,26,14],
[42,31,26,16],
[42,31,26,17],
[42,31,26,19],
[42,31,26,21],
[42,31,26,24],
[42,31,26,25],
[42,31,28,0],
[42,31,28,1],
[42,31,28,2],
[42,31,28,3],
[42,31,28,4],
[42,31,28,5],
[42,31,28,6],
[42,31,28,8],
[42,31,28,9],
[42,31,28,11],
[42,31,28,12],
[42,31,28,13],
[42,31,28,14],
[42,31,28,15],
[42,31,28,16],
[42,31,28,17],
[42,31,28,18],
[42,31,28,19],
[42,31,28,21],
[42,31,28,24],
[42,31,28,26],
[42,31,29,0],
[42,31,29,1],
[42,31,29,2],
[42,31,29,3],
[42,31,29,4],
[42,31,29,5],
[42,31,29,6],
[42,31,29,7],
[42,31,29,8],
[42,31,29,9],
[42,31,29,13],
[42,31,29,14],
[42,31,29,15],
[42,31,29,16],
[42,31,29,17],
[42,31,29,18],
[42,31,29,19],
[42,31,29,21],
[42,31,29,24],
[42,31,29,25],
[42,31,29,28],
[42,32,3,0],
[42,32,3,1],
[42,32,3,2],
[42,32,4,0],
[42,32,4,1],
[42,32,4,2],
[42,32,4,3],
[42,32,5,0],
[42,32,5,1],
[42,32,5,2],
[42,32,5,4],
[42,32,6,0],
[42,32,6,1],
[42,32,6,2],
[42,32,6,4],
[42,32,7,0],
[42,32,7,1],
[42,32,7,2],
[42,32,7,3],
[42,32,7,4],
[42,32,7,5],
[42,32,7,6],
[42,32,8,0],
[42,32,8,1],
[42,32,8,2],
[42,32,8,4],
[42,32,8,7],
[42,32,9,0],
[42,32,9,1],
[42,32,9,2],
[42,32,9,3],
[42,32,9,4],
[42,32,9,5],
[42,32,9,6],
[42,32,9,7],
[42,32,9,8],
[42,32,13,0],
[42,32,13,1],
[42,32,13,2],
[42,32,13,3],
[42,32,13,4],
[42,32,13,5],
[42,32,13,6],
[42,32,13,7],
[42,32,13,8],
[42,32,13,9],
[42,32,14,0],
[42,32,14,1],
[42,32,14,2],
[42,32,14,3],
[42,32,14,4],
[42,32,14,5],
[42,32,14,6],
[42,32,14,7],
[42,32,14,8],
[42,32,14,9],
[42,32,15,0],
[42,32,15,1],
[42,32,15,2],
[42,32,15,3],
[42,32,15,4],
[42,32,15,5],
[42,32,15,6],
[42,32,15,7],
[42,32,15,8],
[42,32,15,13],
[42,32,15,14],
[42,32,16,0],
[42,32,16,1],
[42,32,16,2],
[42,32,16,3],
[42,32,16,4],
[42,32,16,5],
[42,32,16,6],
[42,32,16,7],
[42,32,16,8],
[42,32,16,9],
[42,32,16,13],
[42,32,16,14],
[42,32,16,15],
[42,32,17,0],
[42,32,17,1],
[42,32,17,2],
[42,32,17,3],
[42,32,17,4],
[42,32,17,5],
[42,32,17,6],
[42,32,17,7],
[42,32,17,8],
[42,32,17,9],
[42,32,17,13],
[42,32,17,14],
[42,32,17,15],
[42,32,17,16],
[42,32,18,0],
[42,32,18,1],
[42,32,18,2],
[42,32,18,3],
[42,32,18,4],
[42,32,18,5],
[42,32,18,6],
[42,32,18,7],
[42,32,18,8],
[42,32,18,13],
[42,32,18,14],
[42,32,18,16],
[42,32,18,17],
[42,32,19,0],
[42,32,19,1],
[42,32,19,2],
[42,32,19,3],
[42,32,19,4],
[42,32,19,5],
[42,32,19,6],
[42,32,19,7],
[42,32,19,8],
[42,32,19,9],
[42,32,19,15],
[42,32,19,16],
[42,32,19,17],
[42,32,19,18],
[42,32,21,0],
[42,32,21,1],
[42,32,21,2],
[42,32,21,4],
[42,32,21,7],
[42,32,21,9],
[42,32,21,13],
[42,32,21,14],
[42,32,21,15],
[42,32,21,16],
[42,32,21,17],
[42,32,21,18],
[42,32,21,19],
[42,32,23,0],
[42,32,23,1],
[42,32,23,2],
[42,32,23,3],
[42,32,23,4],
[42,32,23,5],
[42,32,23,6],
[42,32,23,7],
[42,32,23,8],
[42,32,23,9],
[42,32,23,13],
[42,32,23,14],
[42,32,23,15],
[42,32,23,16],
[42,32,23,17],
[42,32,23,18],
[42,32,23,19],
[42,32,23,21],
[42,32,24,0],
[42,32,24,1],
[42,32,24,2],
[42,32,24,3],
[42,32,24,4],
[42,32,24,5],
[42,32,24,6],
[42,32,24,7],
[42,32,24,8],
[42,32,24,9],
[42,32,24,13],
[42,32,24,14],
[42,32,24,15],
[42,32,24,17],
[42,32,24,18],
[42,32,24,19],
[42,32,24,21],
[42,32,24,23],
[42,32,25,0],
[42,32,25,1],
[42,32,25,2],
[42,32,25,3],
[42,32,25,4],
[42,32,25,5],
[42,32,25,6],
[42,32,25,8],
[42,32,25,9],
[42,32,25,13],
[42,32,25,14],
[42,32,25,15],
[42,32,25,16],
[42,32,25,17],
[42,32,25,18],
[42,32,25,19],
[42,32,25,21],
[42,32,25,23],
[42,32,25,24],
[42,32,26,0],
[42,32,26,1],
[42,32,26,2],
[42,32,26,3],
[42,32,26,4],
[42,32,26,5],
[42,32,26,6],
[42,32,26,7],
[42,32,26,8],
[42,32,26,13],
[42,32,26,14],
[42,32,26,16],
[42,32,26,17],
[42,32,26,19],
[42,32,26,21],
[42,32,26,23],
[42,32,26,24],
[42,32,26,25],
[42,32,28,0],
[42,32,28,1],
[42,32,28,2],
[42,32,28,3],
[42,32,28,4],
[42,32,28,5],
[42,32,28,6],
[42,32,28,8],
[42,32,28,9],
[42,32,28,13],
[42,32,28,14],
[42,32,28,15],
[42,32,28,16],
[42,32,28,17],
[42,32,28,18],
[42,32,28,19],
[42,32,28,21],
[42,32,28,23],
[42,32,28,24],
[42,32,30,0],
[42,32,30,1],
[42,32,30,2],
[42,32,30,3],
[42,32,30,4],
[42,32,30,5],
[42,32,30,6],
[42,32,30,7],
[42,32,30,8],
[42,32,30,9],
[42,32,30,13],
[42,32,30,14],
[42,32,30,15],
[42,32,30,16],
[42,32,30,17],
[42,32,30,18],
[42,32,30,19],
[42,32,30,21],
[42,32,30,24],
[42,32,30,25],
[42,32,30,26],
[42,32,30,28],
[42,32,31,0],
[42,32,31,1],
[42,32,31,2],
[42,32,31,3],
[42,32,31,4],
[42,32,31,5],
[42,32,31,6],
[42,32,31,7],
[42,32,31,8],
[42,32,31,9],
[42,32,31,13],
[42,32,31,14],
[42,32,31,15],
[42,32,31,16],
[42,32,31,17],
[42,32,31,18],
[42,32,31,19],
[42,32,31,21],
[42,32,31,25],
[42,32,31,26],
[42,32,31,28],
[42,33,3,0],
[42,33,3,1],
[42,33,3,2],
[42,33,4,0],
[42,33,4,1],
[42,33,4,2],
[42,33,4,3],
[42,33,5,0],
[42,33,5,1],
[42,33,5,2],
[42,33,5,4],
[42,33,6,0],
[42,33,6,1],
[42,33,6,2],
[42,33,6,4],
[42,33,7,0],
[42,33,7,1],
[42,33,7,2],
[42,33,7,3],
[42,33,7,4],
[42,33,7,5],
[42,33,7,6],
[42,33,8,0],
[42,33,8,1],
[42,33,8,2],
[42,33,8,4],
[42,33,8,7],
[42,33,9,0],
[42,33,9,1],
[42,33,9,2],
[42,33,9,3],
[42,33,9,4],
[42,33,9,5],
[42,33,9,6],
[42,33,9,7],
[42,33,9,8],
[42,33,13,0],
[42,33,13,1],
[42,33,13,2],
[42,33,13,3],
[42,33,13,4],
[42,33,13,5],
[42,33,13,6],
[42,33,13,7],
[42,33,13,8],
[42,33,13,9],
[42,33,14,0],
[42,33,14,1],
[42,33,14,2],
[42,33,14,3],
[42,33,14,4],
[42,33,14,5],
[42,33,14,6],
[42,33,14,7],
[42,33,14,8],
[42,33,14,9],
[42,33,15,0],
[42,33,15,1],
[42,33,15,2],
[42,33,15,3],
[42,33,15,4],
[42,33,15,5],
[42,33,15,6],
[42,33,15,7],
[42,33,15,8],
[42,33,15,13],
[42,33,15,14],
[42,33,16,0],
[42,33,16,1],
[42,33,16,2],
[42,33,16,3],
[42,33,16,4],
[42,33,16,5],
[42,33,16,6],
[42,33,16,7],
[42,33,16,8],
[42,33,16,9],
[42,33,16,13],
[42,33,16,14],
[42,33,16,15],
[42,33,17,0],
[42,33,17,1],
[42,33,17,2],
[42,33,17,3],
[42,33,17,4],
[42,33,17,5],
[42,33,17,6],
[42,33,17,7],
[42,33,17,8],
[42,33,17,9],
[42,33,17,13],
[42,33,17,14],
[42,33,17,15],
[42,33,17,16],
[42,33,18,0],
[42,33,18,1],
[42,33,18,2],
[42,33,18,3],
[42,33,18,4],
[42,33,18,5],
[42,33,18,6],
[42,33,18,7],
[42,33,18,8],
[42,33,18,13],
[42,33,18,14],
[42,33,18,16],
[42,33,18,17],
[42,33,19,0],
[42,33,19,1],
[42,33,19,2],
[42,33,19,3],
[42,33,19,4],
[42,33,19,5],
[42,33,19,6],
[42,33,19,7],
[42,33,19,8],
[42,33,19,9],
[42,33,19,15],
[42,33,19,16],
[42,33,19,17],
[42,33,19,18],
[42,33,21,0],
[42,33,21,1],
[42,33,21,2],
[42,33,21,4],
[42,33,21,7],
[42,33,21,9],
[42,33,21,13],
[42,33,21,14],
[42,33,21,15],
[42,33,21,16],
[42,33,21,17],
[42,33,21,18],
[42,33,21,19],
[42,33,23,0],
[42,33,23,1],
[42,33,23,2],
[42,33,23,3],
[42,33,23,4],
[42,33,23,5],
[42,33,23,6],
[42,33,23,7],
[42,33,23,8],
[42,33,23,9],
[42,33,23,13],
[42,33,23,14],
[42,33,23,15],
[42,33,23,16],
[42,33,23,17],
[42,33,23,18],
[42,33,23,19],
[42,33,23,21],
[42,33,24,0],
[42,33,24,1],
[42,33,24,2],
[42,33,24,3],
[42,33,24,4],
[42,33,24,5],
[42,33,24,6],
[42,33,24,7],
[42,33,24,8],
[42,33,24,9],
[42,33,24,13],
[42,33,24,14],
[42,33,24,15],
[42,33,24,17],
[42,33,24,18],
[42,33,24,19],
[42,33,24,21],
[42,33,24,23],
[42,33,25,0],
[42,33,25,1],
[42,33,25,2],
[42,33,25,3],
[42,33,25,4],
[42,33,25,5],
[42,33,25,6],
[42,33,25,8],
[42,33,25,9],
[42,33,25,13],
[42,33,25,14],
[42,33,25,15],
[42,33,25,16],
[42,33,25,17],
[42,33,25,18],
[42,33,25,19],
[42,33,25,21],
[42,33,25,23],
[42,33,25,24],
[42,33,26,0],
[42,33,26,1],
[42,33,26,2],
[42,33,26,3],
[42,33,26,4],
[42,33,26,5],
[42,33,26,6],
[42,33,26,7],
[42,33,26,8],
[42,33,26,13],
[42,33,26,14],
[42,33,26,16],
[42,33,26,17],
[42,33,26,19],
[42,33,26,21],
[42,33,26,23],
[42,33,26,24],
[42,33,26,25],
[42,33,28,0],
[42,33,28,1],
[42,33,28,2],
[42,33,28,3],
[42,33,28,4],
[42,33,28,5],
[42,33,28,6],
[42,33,28,8],
[42,33,28,9],
[42,33,28,13],
[42,33,28,14],
[42,33,28,15],
[42,33,28,16],
[42,33,28,17],
[42,33,28,18],
[42,33,28,19],
[42,33,28,21],
[42,33,28,23],
[42,33,28,24],
[42,33,28,26],
[42,33,30,0],
[42,33,30,1],
[42,33,30,2],
[42,33,30,3],
[42,33,30,4],
[42,33,30,5],
[42,33,30,6],
[42,33,30,7],
[42,33,30,8],
[42,33,30,9],
[42,33,30,13],
[42,33,30,14],
[42,33,30,15],
[42,33,30,16],
[42,33,30,17],
[42,33,30,18],
[42,33,30,19],
[42,33,30,21],
[42,33,30,25],
[42,33,30,26],
[42,33,30,28],
[42,33,31,0],
[42,33,31,1],
[42,33,31,2],
[42,33,31,3],
[42,33,31,4],
[42,33,31,5],
[42,33,31,6],
[42,33,31,7],
[42,33,31,8],
[42,33,31,9],
[42,33,31,13],
[42,33,31,14],
[42,33,31,15],
[42,33,31,16],
[42,33,31,17],
[42,33,31,18],
[42,33,31,19],
[42,33,31,21],
[42,33,31,24],
[42,33,31,25],
[42,33,31,26],
[42,33,31,28],
[42,34,3,0],
[42,34,3,1],
[42,34,3,2],
[42,34,4,0],
[42,34,4,1],
[42,34,4,2],
[42,34,4,3],
[42,34,5,0],
[42,34,5,1],
[42,34,5,2],
[42,34,5,4],
[42,34,6,0],
[42,34,6,1],
[42,34,6,2],
[42,34,6,4],
[42,34,8,0],
[42,34,8,1],
[42,34,8,2],
[42,34,8,4],
[42,34,9,0],
[42,34,9,1],
[42,34,9,2],
[42,34,9,3],
[42,34,9,4],
[42,34,9,5],
[42,34,9,6],
[42,34,9,8],
[42,34,11,0],
[42,34,11,1],
[42,34,11,2],
[42,34,11,3],
[42,34,11,4],
[42,34,11,5],
[42,34,11,6],
[42,34,11,8],
[42,34,11,9],
[42,34,12,0],
[42,34,12,1],
[42,34,12,2],
[42,34,12,3],
[42,34,12,4],
[42,34,12,5],
[42,34,12,6],
[42,34,12,8],
[42,34,12,9],
[42,34,13,0],
[42,34,13,1],
[42,34,13,2],
[42,34,13,3],
[42,34,13,4],
[42,34,13,5],
[42,34,13,6],
[42,34,13,8],
[42,34,13,9],
[42,34,13,11],
[42,34,13,12],
[42,34,14,0],
[42,34,14,1],
[42,34,14,2],
[42,34,14,3],
[42,34,14,4],
[42,34,14,5],
[42,34,14,6],
[42,34,14,8],
[42,34,14,9],
[42,34,14,11],
[42,34,14,12],
[42,34,15,0],
[42,34,15,1],
[42,34,15,2],
[42,34,15,3],
[42,34,15,4],
[42,34,15,5],
[42,34,15,6],
[42,34,15,8],
[42,34,15,11],
[42,34,15,12],
[42,34,15,13],
[42,34,15,14],
[42,34,16,0],
[42,34,16,1],
[42,34,16,2],
[42,34,16,3],
[42,34,16,4],
[42,34,16,5],
[42,34,16,6],
[42,34,16,8],
[42,34,16,9],
[42,34,16,11],
[42,34,16,12],
[42,34,16,13],
[42,34,16,14],
[42,34,16,15],
[42,34,17,0],
[42,34,17,1],
[42,34,17,2],
[42,34,17,3],
[42,34,17,4],
[42,34,17,5],
[42,34,17,6],
[42,34,17,8],
[42,34,17,9],
[42,34,17,11],
[42,34,17,12],
[42,34,17,13],
[42,34,17,14],
[42,34,17,15],
[42,34,17,16],
[42,34,18,0],
[42,34,18,1],
[42,34,18,2],
[42,34,18,3],
[42,34,18,4],
[42,34,18,5],
[42,34,18,6],
[42,34,18,8],
[42,34,18,11],
[42,34,18,12],
[42,34,18,13],
[42,34,18,14],
[42,34,18,16],
[42,34,18,17],
[42,34,19,0],
[42,34,19,1],
[42,34,19,2],
[42,34,19,3],
[42,34,19,4],
[42,34,19,5],
[42,34,19,6],
[42,34,19,8],
[42,34,19,9],
[42,34,19,11],
[42,34,19,12],
[42,34,19,15],
[42,34,19,16],
[42,34,19,17],
[42,34,19,18],
[42,34,21,0],
[42,34,21,1],
[42,34,21,2],
[42,34,21,4],
[42,34,21,9],
[42,34,21,11],
[42,34,21,12],
[42,34,21,13],
[42,34,21,14],
[42,34,21,15],
[42,34,21,16],
[42,34,21,17],
[42,34,21,18],
[42,34,21,19],
[42,34,23,0],
[42,34,23,1],
[42,34,23,2],
[42,34,23,3],
[42,34,23,4],
[42,34,23,5],
[42,34,23,6],
[42,34,23,8],
[42,34,23,9],
[42,34,23,11],
[42,34,23,12],
[42,34,23,13],
[42,34,23,14],
[42,34,23,15],
[42,34,23,16],
[42,34,23,17],
[42,34,23,18],
[42,34,23,19],
[42,34,23,21],
[42,34,24,0],
[42,34,24,1],
[42,34,24,2],
[42,34,24,3],
[42,34,24,4],
[42,34,24,5],
[42,34,24,6],
[42,34,24,8],
[42,34,24,9],
[42,34,24,11],
[42,34,24,12],
[42,34,24,13],
[42,34,24,14],
[42,34,24,15],
[42,34,24,17],
[42,34,24,18],
[42,34,24,19],
[42,34,24,21],
[42,34,24,23],
[42,34,26,0],
[42,34,26,1],
[42,34,26,2],
[42,34,26,3],
[42,34,26,4],
[42,34,26,5],
[42,34,26,6],
[42,34,26,8],
[42,34,26,11],
[42,34,26,12],
[42,34,26,13],
[42,34,26,14],
[42,34,26,16],
[42,34,26,17],
[42,34,26,19],
[42,34,26,21],
[42,34,26,23],
[42,34,26,24],
[42,34,29,0],
[42,34,29,1],
[42,34,29,2],
[42,34,29,3],
[42,34,29,4],
[42,34,29,5],
[42,34,29,6],
[42,34,29,8],
[42,34,29,9],
[42,34,29,13],
[42,34,29,14],
[42,34,29,15],
[42,34,29,16],
[42,34,29,17],
[42,34,29,18],
[42,34,29,19],
[42,34,29,21],
[42,34,29,26],
[42,34,30,0],
[42,34,30,1],
[42,34,30,2],
[42,34,30,3],
[42,34,30,4],
[42,34,30,5],
[42,34,30,6],
[42,34,30,8],
[42,34,30,9],
[42,34,30,11],
[42,34,30,12],
[42,34,30,13],
[42,34,30,14],
[42,34,30,15],
[42,34,30,16],
[42,34,30,17],
[42,34,30,18],
[42,34,30,19],
[42,34,30,21],
[42,34,30,24],
[42,34,30,26],
[42,34,30,29],
[42,34,31,0],
[42,34,31,1],
[42,34,31,2],
[42,34,31,3],
[42,34,31,4],
[42,34,31,5],
[42,34,31,6],
[42,34,31,8],
[42,34,31,9],
[42,34,31,11],
[42,34,31,12],
[42,34,31,13],
[42,34,31,14],
[42,34,31,15],
[42,34,31,16],
[42,34,31,17],
[42,34,31,18],
[42,34,31,19],
[42,34,31,24],
[42,34,31,26],
[42,34,31,29],
[42,34,32,0],
[42,34,32,1],
[42,34,32,2],
[42,34,32,3],
[42,34,32,4],
[42,34,32,5],
[42,34,32,6],
[42,34,32,8],
[42,34,32,9],
[42,34,32,13],
[42,34,32,14],
[42,34,32,15],
[42,34,32,16],
[42,34,32,17],
[42,34,32,18],
[42,34,32,19],
[42,34,32,21],
[42,34,32,23],
[42,34,32,24],
[42,34,32,26],
[42,34,32,30],
[42,34,32,31],
[42,34,33,0],
[42,34,33,1],
[42,34,33,2],
[42,34,33,3],
[42,34,33,4],
[42,34,33,5],
[42,34,33,6],
[42,34,33,8],
[42,34,33,9],
[42,34,33,13],
[42,34,33,14],
[42,34,33,15],
[42,34,33,16],
[42,34,33,17],
[42,34,33,18],
[42,34,33,21],
[42,34,33,23],
[42,34,33,24],
[42,34,33,26],
[42,34,33,30],
[42,34,33,31],
[42,35,3,0],
[42,35,3,1],
[42,35,3,2],
[42,35,4,0],
[42,35,4,1],
[42,35,4,2],
[42,35,4,3],
[42,35,5,0],
[42,35,5,1],
[42,35,5,2],
[42,35,5,4],
[42,35,6,0],
[42,35,6,1],
[42,35,6,2],
[42,35,6,4],
[42,35,7,0],
[42,35,7,1],
[42,35,7,2],
[42,35,7,3],
[42,35,7,4],
[42,35,7,5],
[42,35,7,6],
[42,35,8,0],
[42,35,8,1],
[42,35,8,2],
[42,35,8,4],
[42,35,8,7],
[42,35,11,0],
[42,35,11,1],
[42,35,11,2],
[42,35,11,3],
[42,35,11,4],
[42,35,11,5],
[42,35,11,6],
[42,35,11,7],
[42,35,11,8],
[42,35,12,0],
[42,35,12,1],
[42,35,12,2],
[42,35,12,3],
[42,35,12,4],
[42,35,12,5],
[42,35,12,6],
[42,35,12,7],
[42,35,12,8],
[42,35,13,0],
[42,35,13,1],
[42,35,13,2],
[42,35,13,3],
[42,35,13,4],
[42,35,13,5],
[42,35,13,6],
[42,35,13,7],
[42,35,13,8],
[42,35,13,11],
[42,35,13,12],
[42,35,14,0],
[42,35,14,1],
[42,35,14,2],
[42,35,14,3],
[42,35,14,4],
[42,35,14,5],
[42,35,14,6],
[42,35,14,7],
[42,35,14,8],
[42,35,14,11],
[42,35,14,12],
[42,35,16,0],
[42,35,16,1],
[42,35,16,2],
[42,35,16,3],
[42,35,16,4],
[42,35,16,5],
[42,35,16,6],
[42,35,16,7],
[42,35,16,8],
[42,35,16,11],
[42,35,16,12],
[42,35,16,13],
[42,35,16,14],
[42,35,17,0],
[42,35,17,1],
[42,35,17,2],
[42,35,17,3],
[42,35,17,4],
[42,35,17,5],
[42,35,17,6],
[42,35,17,7],
[42,35,17,8],
[42,35,17,11],
[42,35,17,12],
[42,35,17,13],
[42,35,17,14],
[42,35,17,16],
[42,35,19,0],
[42,35,19,1],
[42,35,19,2],
[42,35,19,3],
[42,35,19,4],
[42,35,19,5],
[42,35,19,6],
[42,35,19,7],
[42,35,19,8],
[42,35,19,11],
[42,35,19,12],
[42,35,19,16],
[42,35,19,17],
[42,35,21,0],
[42,35,21,1],
[42,35,21,2],
[42,35,21,4],
[42,35,21,7],
[42,35,21,11],
[42,35,21,12],
[42,35,21,13],
[42,35,21,14],
[42,35,21,16],
[42,35,21,17],
[42,35,21,19],
[42,35,23,0],
[42,35,23,1],
[42,35,23,2],
[42,35,23,3],
[42,35,23,4],
[42,35,23,5],
[42,35,23,6],
[42,35,23,7],
[42,35,23,8],
[42,35,23,11],
[42,35,23,12],
[42,35,23,13],
[42,35,23,14],
[42,35,23,16],
[42,35,23,17],
[42,35,23,19],
[42,35,23,21],
[42,35,24,0],
[42,35,24,1],
[42,35,24,2],
[42,35,24,3],
[42,35,24,4],
[42,35,24,5],
[42,35,24,6],
[42,35,24,7],
[42,35,24,8],
[42,35,24,11],
[42,35,24,12],
[42,35,24,13],
[42,35,24,14],
[42,35,24,17],
[42,35,24,19],
[42,35,24,21],
[42,35,24,23],
[42,35,25,0],
[42,35,25,1],
[42,35,25,2],
[42,35,25,3],
[42,35,25,4],
[42,35,25,5],
[42,35,25,6],
[42,35,25,8],
[42,35,25,11],
[42,35,25,12],
[42,35,25,13],
[42,35,25,14],
[42,35,25,16],
[42,35,25,17],
[42,35,25,19],
[42,35,25,21],
[42,35,25,23],
[42,35,25,24],
[42,35,28,0],
[42,35,28,1],
[42,35,28,2],
[42,35,28,3],
[42,35,28,4],
[42,35,28,5],
[42,35,28,6],
[42,35,28,8],
[42,35,28,11],
[42,35,28,12],
[42,35,28,13],
[42,35,28,14],
[42,35,28,16],
[42,35,28,17],
[42,35,28,19],
[42,35,28,21],
[42,35,29,0],
[42,35,29,1],
[42,35,29,2],
[42,35,29,3],
[42,35,29,4],
[42,35,29,5],
[42,35,29,6],
[42,35,29,7],
[42,35,29,8],
[42,35,29,13],
[42,35,29,14],
[42,35,29,16],
[42,35,29,17],
[42,35,29,19],
[42,35,29,21],
[42,35,29,23],
[42,35,29,24],
[42,35,29,25],
[42,35,29,28],
[42,35,30,0],
[42,35,30,1],
[42,35,30,2],
[42,35,30,3],
[42,35,30,4],
[42,35,30,5],
[42,35,30,6],
[42,35,30,7],
[42,35,30,8],
[42,35,30,11],
[42,35,30,12],
[42,35,30,13],
[42,35,30,14],
[42,35,30,16],
[42,35,30,17],
[42,35,30,19],
[42,35,30,24],
[42,35,30,25],
[42,35,30,28],
[42,35,30,29],
[42,35,31,0],
[42,35,31,1],
[42,35,31,2],
[42,35,31,3],
[42,35,31,4],
[42,35,31,5],
[42,35,31,6],
[42,35,31,7],
[42,35,31,8],
[42,35,31,11],
[42,35,31,12],
[42,35,31,13],
[42,35,31,14],
[42,35,31,16],
[42,35,31,17],
[42,35,31,19],
[42,35,31,21],
[42,35,31,24],
[42,35,31,25],
[42,35,31,28],
[42,35,31,29],
[42,35,32,0],
[42,35,32,1],
[42,35,32,2],
[42,35,32,3],
[42,35,32,4],
[42,35,32,5],
[42,35,32,6],
[42,35,32,7],
[42,35,32,8],
[42,35,32,13],
[42,35,32,14],
[42,35,32,16],
[42,35,32,17],
[42,35,32,21],
[42,35,32,23],
[42,35,32,24],
[42,35,32,25],
[42,35,32,28],
[42,35,32,30],
[42,35,32,31],
[42,35,33,0],
[42,35,33,1],
[42,35,33,2],
[42,35,33,3],
[42,35,33,4],
[42,35,33,5],
[42,35,33,6],
[42,35,33,7],
[42,35,33,8],
[42,35,33,13],
[42,35,33,14],
[42,35,33,16],
[42,35,33,17],
[42,35,33,19],
[42,35,33,21],
[42,35,33,23],
[42,35,33,24],
[42,35,33,25],
[42,35,33,28],
[42,35,33,30],
[42,35,34,0],
[42,35,34,1],
[42,35,34,2],
[42,35,34,3],
[42,35,34,4],
[42,35,34,5],
[42,35,34,6],
[42,35,34,8],
[42,35,34,11],
[42,35,34,12],
[42,35,34,13],
[42,35,34,14],
[42,35,34,19],
[42,35,34,21],
[42,35,34,23],
[42,35,34,24],
[42,35,34,29],
[42,36,3,0],
[42,36,3,1],
[42,36,3,2],
[42,36,4,0],
[42,36,4,1],
[42,36,4,2],
[42,36,4,3],
[42,36,5,0],
[42,36,5,1],
[42,36,5,2],
[42,36,5,4],
[42,36,6,0],
[42,36,6,1],
[42,36,6,2],
[42,36,6,4],
[42,36,7,0],
[42,36,7,1],
[42,36,7,2],
[42,36,7,3],
[42,36,7,4],
[42,36,7,5],
[42,36,7,6],
[42,36,8,0],
[42,36,8,1],
[42,36,8,2],
[42,36,8,4],
[42,36,8,7],
[42,36,9,0],
[42,36,9,1],
[42,36,9,2],
[42,36,9,3],
[42,36,9,4],
[42,36,9,5],
[42,36,9,6],
[42,36,9,7],
[42,36,9,8],
[42,36,11,0],
[42,36,11,1],
[42,36,11,2],
[42,36,11,3],
[42,36,11,4],
[42,36,11,5],
[42,36,11,6],
[42,36,11,7],
[42,36,11,8],
[42,36,11,9],
[42,36,12,0],
[42,36,12,1],
[42,36,12,2],
[42,36,12,3],
[42,36,12,4],
[42,36,12,5],
[42,36,12,6],
[42,36,12,7],
[42,36,12,8],
[42,36,12,9],
[42,36,15,0],
[42,36,15,1],
[42,36,15,2],
[42,36,15,3],
[42,36,15,4],
[42,36,15,5],
[42,36,15,6],
[42,36,15,7],
[42,36,15,8],
[42,36,15,11],
[42,36,15,12],
[42,36,16,0],
[42,36,16,1],
[42,36,16,2],
[42,36,16,3],
[42,36,16,4],
[42,36,16,5],
[42,36,16,6],
[42,36,16,7],
[42,36,16,8],
[42,36,16,9],
[42,36,16,11],
[42,36,16,12],
[42,36,16,15],
[42,36,17,0],
[42,36,17,1],
[42,36,17,2],
[42,36,17,3],
[42,36,17,4],
[42,36,17,5],
[42,36,17,6],
[42,36,17,7],
[42,36,17,8],
[42,36,17,9],
[42,36,17,11],
[42,36,17,12],
[42,36,17,15],
[42,36,17,16],
[42,36,18,0],
[42,36,18,1],
[42,36,18,2],
[42,36,18,3],
[42,36,18,4],
[42,36,18,5],
[42,36,18,6],
[42,36,18,7],
[42,36,18,8],
[42,36,18,11],
[42,36,18,12],
[42,36,18,16],
[42,36,18,17],
[42,36,21,0],
[42,36,21,1],
[42,36,21,2],
[42,36,21,4],
[42,36,21,7],
[42,36,21,9],
[42,36,21,11],
[42,36,21,12],
[42,36,21,15],
[42,36,21,16],
[42,36,21,17],
[42,36,21,18],
[42,36,23,0],
[42,36,23,1],
[42,36,23,2],
[42,36,23,3],
[42,36,23,4],
[42,36,23,5],
[42,36,23,6],
[42,36,23,7],
[42,36,23,8],
[42,36,23,9],
[42,36,23,11],
[42,36,23,12],
[42,36,23,15],
[42,36,23,16],
[42,36,23,17],
[42,36,23,18],
[42,36,23,21],
[42,36,24,0],
[42,36,24,1],
[42,36,24,2],
[42,36,24,3],
[42,36,24,4],
[42,36,24,5],
[42,36,24,6],
[42,36,24,7],
[42,36,24,8],
[42,36,24,9],
[42,36,24,11],
[42,36,24,12],
[42,36,24,15],
[42,36,24,17],
[42,36,24,18],
[42,36,24,21],
[42,36,24,23],
[42,36,25,0],
[42,36,25,1],
[42,36,25,2],
[42,36,25,3],
[42,36,25,4],
[42,36,25,5],
[42,36,25,6],
[42,36,25,8],
[42,36,25,9],
[42,36,25,11],
[42,36,25,12],
[42,36,25,15],
[42,36,25,16],
[42,36,25,17],
[42,36,25,18],
[42,36,25,21],
[42,36,25,23],
[42,36,25,24],
[42,36,26,0],
[42,36,26,1],
[42,36,26,2],
[42,36,26,3],
[42,36,26,4],
[42,36,26,5],
[42,36,26,6],
[42,36,26,7],
[42,36,26,8],
[42,36,26,11],
[42,36,26,12],
[42,36,26,16],
[42,36,26,17],
[42,36,26,21],
[42,36,26,23],
[42,36,26,24],
[42,36,26,25],
[42,36,28,0],
[42,36,28,1],
[42,36,28,2],
[42,36,28,3],
[42,36,28,4],
[42,36,28,5],
[42,36,28,6],
[42,36,28,8],
[42,36,28,9],
[42,36,28,11],
[42,36,28,12],
[42,36,28,15],
[42,36,28,16],
[42,36,28,17],
[42,36,28,18],
[42,36,28,21],
[42,36,28,23],
[42,36,28,24],
[42,36,28,26],
[42,36,29,0],
[42,36,29,1],
[42,36,29,2],
[42,36,29,3],
[42,36,29,4],
[42,36,29,5],
[42,36,29,6],
[42,36,29,7],
[42,36,29,8],
[42,36,29,9],
[42,36,29,15],
[42,36,29,16],
[42,36,29,17],
[42,36,29,18],
[42,36,29,23],
[42,36,29,24],
[42,36,29,25],
[42,36,29,26],
[42,36,29,28],
[42,36,30,0],
[42,36,30,1],
[42,36,30,2],
[42,36,30,3],
[42,36,30,4],
[42,36,30,5],
[42,36,30,6],
[42,36,30,7],
[42,36,30,8],
[42,36,30,9],
[42,36,30,11],
[42,36,30,12],
[42,36,30,15],
[42,36,30,16],
[42,36,30,17],
[42,36,30,18],
[42,36,30,21],
[42,36,30,24],
[42,36,30,25],
[42,36,30,26],
[42,36,30,28],
[42,36,30,29],
[42,36,31,0],
[42,36,31,1],
[42,36,31,2],
[42,36,31,3],
[42,36,31,4],
[42,36,31,5],
[42,36,31,6],
[42,36,31,7],
[42,36,31,8],
[42,36,31,9],
[42,36,31,11],
[42,36,31,12],
[42,36,31,15],
[42,36,31,16],
[42,36,31,17],
[42,36,31,18],
[42,36,31,21],
[42,36,31,24],
[42,36,31,25],
[42,36,31,26],
[42,36,31,28],
[42,36,31,29],
[42,36,32,0],
[42,36,32,1],
[42,36,32,2],
[42,36,32,3],
[42,36,32,4],
[42,36,32,5],
[42,36,32,6],
[42,36,32,7],
[42,36,32,8],
[42,36,32,9],
[42,36,32,15],
[42,36,32,16],
[42,36,32,17],
[42,36,32,21],
[42,36,32,23],
[42,36,32,24],
[42,36,32,25],
[42,36,32,26],
[42,36,32,28],
[42,36,32,30],
[42,36,33,0],
[42,36,33,1],
[42,36,33,2],
[42,36,33,3],
[42,36,33,4],
[42,36,33,5],
[42,36,33,6],
[42,36,33,7],
[42,36,33,8],
[42,36,33,9],
[42,36,33,15],
[42,36,33,18],
[42,36,33,21],
[42,36,33,23],
[42,36,33,24],
[42,36,33,25],
[42,36,33,26],
[42,36,33,28],
[42,36,34,0],
[42,36,34,1],
[42,36,34,2],
[42,36,34,3],
[42,36,34,4],
[42,36,34,5],
[42,36,34,6],
[42,36,34,8],
[42,36,34,9],
[42,36,34,11],
[42,36,34,12],
[42,36,34,16],
[42,36,34,17],
[42,36,34,18],
[42,36,34,21],
[42,36,34,23],
[42,36,34,24],
[42,36,34,26],
[42,36,35,0],
[42,36,35,1],
[42,36,35,2],
[42,36,35,3],
[42,36,35,4],
[42,36,35,5],
[42,36,35,6],
[42,36,35,7],
[42,36,35,8],
[42,36,35,11],
[42,36,35,12],
[42,36,35,16],
[42,36,35,17],
[42,36,35,21],
[42,36,35,23],
[42,36,35,24],
[42,36,35,25],
[42,37,3,0],
[42,37,3,1],
[42,37,3,2],
[42,37,5,0],
[42,37,5,1],
[42,37,5,2],
[42,37,6,0],
[42,37,6,1],
[42,37,6,2],
[42,37,7,0],
[42,37,7,1],
[42,37,7,2],
[42,37,7,3],
[42,37,7,5],
[42,37,7,6],
[42,37,8,0],
[42,37,8,1],
[42,37,8,2],
[42,37,8,7],
[42,37,9,0],
[42,37,9,1],
[42,37,9,2],
[42,37,9,3],
[42,37,9,5],
[42,37,9,6],
[42,37,9,7],
[42,37,9,8],
[42,37,11,0],
[42,37,11,1],
[42,37,11,2],
[42,37,11,3],
[42,37,11,5],
[42,37,11,6],
[42,37,11,7],
[42,37,11,8],
[42,37,11,9],
[42,37,12,0],
[42,37,12,1],
[42,37,12,2],
[42,37,12,3],
[42,37,12,5],
[42,37,12,6],
[42,37,12,7],
[42,37,12,8],
[42,37,12,9],
[42,37,13,0],
[42,37,13,1],
[42,37,13,2],
[42,37,13,3],
[42,37,13,5],
[42,37,13,6],
[42,37,13,7],
[42,37,13,8],
[42,37,13,9],
[42,37,13,11],
[42,37,13,12],
[42,37,14,0],
[42,37,14,1],
[42,37,14,2],
[42,37,14,3],
[42,37,14,5],
[42,37,14,6],
[42,37,14,7],
[42,37,14,8],
[42,37,14,9],
[42,37,14,11],
[42,37,14,12],
[42,37,15,0],
[42,37,15,1],
[42,37,15,2],
[42,37,15,3],
[42,37,15,5],
[42,37,15,6],
[42,37,15,7],
[42,37,15,8],
[42,37,15,11],
[42,37,15,12],
[42,37,15,13],
[42,37,15,14],
[42,37,16,0],
[42,37,16,1],
[42,37,16,2],
[42,37,16,3],
[42,37,16,5],
[42,37,16,6],
[42,37,16,7],
[42,37,16,8],
[42,37,16,9],
[42,37,16,11],
[42,37,16,12],
[42,37,16,13],
[42,37,16,14],
[42,37,16,15],
[42,37,17,0],
[42,37,17,1],
[42,37,17,2],
[42,37,17,3],
[42,37,17,5],
[42,37,17,6],
[42,37,17,7],
[42,37,17,8],
[42,37,17,9],
[42,37,17,11],
[42,37,17,12],
[42,37,17,13],
[42,37,17,14],
[42,37,17,15],
[42,37,17,16],
[42,37,18,0],
[42,37,18,1],
[42,37,18,2],
[42,37,18,3],
[42,37,18,5],
[42,37,18,6],
[42,37,18,7],
[42,37,18,8],
[42,37,18,11],
[42,37,18,12],
[42,37,18,13],
[42,37,18,14],
[42,37,18,16],
[42,37,18,17],
[42,37,19,0],
[42,37,19,1],
[42,37,19,2],
[42,37,19,3],
[42,37,19,5],
[42,37,19,6],
[42,37,19,7],
[42,37,19,8],
[42,37,19,9],
[42,37,19,11],
[42,37,19,12],
[42,37,19,15],
[42,37,19,16],
[42,37,19,17],
[42,37,19,18],
[42,37,21,0],
[42,37,21,1],
[42,37,21,2],
[42,37,21,7],
[42,37,21,9],
[42,37,21,11],
[42,37,21,12],
[42,37,21,13],
[42,37,21,14],
[42,37,21,15],
[42,37,21,16],
[42,37,21,17],
[42,37,21,18],
[42,37,21,19],
[42,37,23,0],
[42,37,23,1],
[42,37,23,2],
[42,37,23,3],
[42,37,23,5],
[42,37,23,6],
[42,37,23,7],
[42,37,23,8],
[42,37,23,9],
[42,37,23,11],
[42,37,23,12],
[42,37,23,13],
[42,37,23,14],
[42,37,23,15],
[42,37,23,16],
[42,37,23,17],
[42,37,23,18],
[42,37,23,19],
[42,37,23,21],
[42,37,24,0],
[42,37,24,1],
[42,37,24,2],
[42,37,24,3],
[42,37,24,5],
[42,37,24,6],
[42,37,24,7],
[42,37,24,8],
[42,37,24,9],
[42,37,24,11],
[42,37,24,12],
[42,37,24,13],
[42,37,24,14],
[42,37,24,15],
[42,37,24,17],
[42,37,24,18],
[42,37,24,19],
[42,37,24,21],
[42,37,24,23],
[42,37,25,0],
[42,37,25,1],
[42,37,25,2],
[42,37,25,3],
[42,37,25,5],
[42,37,25,6],
[42,37,25,8],
[42,37,25,9],
[42,37,25,11],
[42,37,25,12],
[42,37,25,13],
[42,37,25,14],
[42,37,25,15],
[42,37,25,16],
[42,37,25,17],
[42,37,25,18],
[42,37,25,19],
[42,37,25,21],
[42,37,25,23],
[42,37,25,24],
[42,37,26,0],
[42,37,26,1],
[42,37,26,2],
[42,37,26,3],
[42,37,26,5],
[42,37,26,6],
[42,37,26,7],
[42,37,26,8],
[42,37,26,11],
[42,37,26,12],
[42,37,26,13],
[42,37,26,14],
[42,37,26,16],
[42,37,26,17],
[42,37,26,19],
[42,37,26,21],
[42,37,26,25],
[42,37,28,0],
[42,37,28,1],
[42,37,28,2],
[42,37,28,3],
[42,37,28,5],
[42,37,28,6],
[42,37,28,8],
[42,37,28,9],
[42,37,28,11],
[42,37,28,12],
[42,37,28,13],
[42,37,28,14],
[42,37,28,15],
[42,37,28,16],
[42,37,28,17],
[42,37,28,18],
[42,37,28,19],
[42,37,28,23],
[42,37,28,24],
[42,37,28,26],
[42,37,29,0],
[42,37,29,1],
[42,37,29,2],
[42,37,29,3],
[42,37,29,5],
[42,37,29,6],
[42,37,29,7],
[42,37,29,8],
[42,37,29,9],
[42,37,29,13],
[42,37,29,14],
[42,37,29,15],
[42,37,29,16],
[42,37,29,17],
[42,37,29,18],
[42,37,29,19],
[42,37,29,21],
[42,37,29,23],
[42,37,29,24],
[42,37,29,25],
[42,37,29,26],
[42,37,29,28],
[42,37,30,0],
[42,37,30,1],
[42,37,30,2],
[42,37,30,3],
[42,37,30,5],
[42,37,30,6],
[42,37,30,7],
[42,37,30,8],
[42,37,30,9],
[42,37,30,11],
[42,37,30,12],
[42,37,30,13],
[42,37,30,14],
[42,37,30,15],
[42,37,30,16],
[42,37,30,17],
[42,37,30,18],
[42,37,30,21],
[42,37,30,24],
[42,37,30,25],
[42,37,30,26],
[42,37,30,28],
[42,37,30,29],
[42,37,31,0],
[42,37,31,1],
[42,37,31,2],
[42,37,31,3],
[42,37,31,5],
[42,37,31,6],
[42,37,31,7],
[42,37,31,8],
[42,37,31,9],
[42,37,31,11],
[42,37,31,12],
[42,37,31,13],
[42,37,31,14],
[42,37,31,15],
[42,37,31,16],
[42,37,31,17],
[42,37,31,19],
[42,37,31,21],
[42,37,31,24],
[42,37,31,25],
[42,37,31,26],
[42,37,31,28],
[42,37,31,29],
[42,37,32,0],
[42,37,32,1],
[42,37,32,2],
[42,37,32,3],
[42,37,32,5],
[42,37,32,6],
[42,37,32,7],
[42,37,32,8],
[42,37,32,9],
[42,37,32,13],
[42,37,32,14],
[42,37,32,15],
[42,37,32,18],
[42,37,32,19],
[42,37,32,21],
[42,37,32,23],
[42,37,32,24],
[42,37,32,25],
[42,37,32,26],
[42,37,32,28],
[42,37,33,0],
[42,37,33,1],
[42,37,33,2],
[42,37,33,3],
[42,37,33,5],
[42,37,33,6],
[42,37,33,7],
[42,37,33,8],
[42,37,33,9],
[42,37,33,13],
[42,37,33,14],
[42,37,33,16],
[42,37,33,17],
[42,37,33,18],
[42,37,33,19],
[42,37,33,21],
[42,37,33,23],
[42,37,33,24],
[42,37,33,25],
[42,37,33,26],
[42,37,33,28],
[42,37,34,0],
[42,37,34,1],
[42,37,34,2],
[42,37,34,3],
[42,37,34,5],
[42,37,34,6],
[42,37,34,8],
[42,37,34,9],
[42,37,34,11],
[42,37,34,12],
[42,37,34,13],
[42,37,34,15],
[42,37,34,16],
[42,37,34,17],
[42,37,34,18],
[42,37,34,19],
[42,37,34,21],
[42,37,34,23],
[42,37,34,24],
[42,37,34,26],
[42,37,35,0],
[42,37,35,1],
[42,37,35,2],
[42,37,35,3],
[42,37,35,5],
[42,37,35,6],
[42,37,35,7],
[42,37,35,8],
[42,37,35,11],
[42,37,35,14],
[42,37,35,16],
[42,37,35,17],
[42,37,35,19],
[42,37,35,21],
[42,37,35,23],
[42,37,35,24],
[42,37,35,25],
[42,37,36,0],
[42,37,36,1],
[42,37,36,2],
[42,37,36,3],
[42,37,36,5],
[42,37,36,6],
[42,37,36,7],
[42,37,36,8],
[42,37,36,9],
[42,37,36,12],
[42,37,36,15],
[42,37,36,16],
[42,37,36,17],
[42,37,36,18],
[42,37,36,21],
[42,37,36,23],
[42,37,36,24],
[42,37,36,25],
[42,38,3,0],
[42,38,3,1],
[42,38,3,2],
[42,38,4,0],
[42,38,4,1],
[42,38,4,2],
[42,38,4,3],
[42,38,5,0],
[42,38,5,1],
[42,38,5,2],
[42,38,5,4],
[42,38,6,0],
[42,38,6,1],
[42,38,6,2],
[42,38,6,4],
[42,38,8,0],
[42,38,8,1],
[42,38,8,2],
[42,38,8,4],
[42,38,9,0],
[42,38,9,1],
[42,38,9,2],
[42,38,9,3],
[42,38,9,4],
[42,38,9,5],
[42,38,9,6],
[42,38,9,8],
[42,38,11,0],
[42,38,11,1],
[42,38,11,2],
[42,38,11,3],
[42,38,11,4],
[42,38,11,5],
[42,38,11,6],
[42,38,11,8],
[42,38,11,9],
[42,38,12,0],
[42,38,12,1],
[42,38,12,2],
[42,38,12,3],
[42,38,12,4],
[42,38,12,5],
[42,38,12,6],
[42,38,12,8],
[42,38,12,9],
[42,38,13,0],
[42,38,13,1],
[42,38,13,2],
[42,38,13,3],
[42,38,13,4],
[42,38,13,5],
[42,38,13,6],
[42,38,13,8],
[42,38,13,9],
[42,38,13,11],
[42,38,13,12],
[42,38,14,0],
[42,38,14,1],
[42,38,14,2],
[42,38,14,3],
[42,38,14,4],
[42,38,14,5],
[42,38,14,6],
[42,38,14,8],
[42,38,14,9],
[42,38,14,11],
[42,38,14,12],
[42,38,15,0],
[42,38,15,1],
[42,38,15,2],
[42,38,15,3],
[42,38,15,4],
[42,38,15,5],
[42,38,15,6],
[42,38,15,8],
[42,38,15,11],
[42,38,15,12],
[42,38,15,13],
[42,38,15,14],
[42,38,16,0],
[42,38,16,1],
[42,38,16,2],
[42,38,16,3],
[42,38,16,4],
[42,38,16,5],
[42,38,16,6],
[42,38,16,8],
[42,38,16,9],
[42,38,16,11],
[42,38,16,12],
[42,38,16,13],
[42,38,16,14],
[42,38,16,15],
[42,38,17,0],
[42,38,17,1],
[42,38,17,2],
[42,38,17,3],
[42,38,17,4],
[42,38,17,5],
[42,38,17,6],
[42,38,17,8],
[42,38,17,9],
[42,38,17,11],
[42,38,17,12],
[42,38,17,13],
[42,38,17,14],
[42,38,17,15],
[42,38,17,16],
[42,38,18,0],
[42,38,18,1],
[42,38,18,2],
[42,38,18,3],
[42,38,18,4],
[42,38,18,5],
[42,38,18,6],
[42,38,18,8],
[42,38,18,11],
[42,38,18,12],
[42,38,18,13],
[42,38,18,14],
[42,38,18,16],
[42,38,18,17],
[42,38,19,0],
[42,38,19,1],
[42,38,19,2],
[42,38,19,3],
[42,38,19,4],
[42,38,19,5],
[42,38,19,6],
[42,38,19,8],
[42,38,19,9],
[42,38,19,11],
[42,38,19,12],
[42,38,19,15],
[42,38,19,16],
[42,38,19,17],
[42,38,19,18],
[42,38,21,0],
[42,38,21,1],
[42,38,21,2],
[42,38,21,4],
[42,38,21,9],
[42,38,21,11],
[42,38,21,12],
[42,38,21,13],
[42,38,21,14],
[42,38,21,15],
[42,38,21,16],
[42,38,21,17],
[42,38,21,18],
[42,38,21,19],
[42,38,23,0],
[42,38,23,1],
[42,38,23,2],
[42,38,23,3],
[42,38,23,4],
[42,38,23,5],
[42,38,23,6],
[42,38,23,8],
[42,38,23,9],
[42,38,23,11],
[42,38,23,12],
[42,38,23,13],
[42,38,23,14],
[42,38,23,15],
[42,38,23,16],
[42,38,23,17],
[42,38,23,18],
[42,38,23,19],
[42,38,23,21],
[42,38,24,0],
[42,38,24,1],
[42,38,24,2],
[42,38,24,3],
[42,38,24,4],
[42,38,24,5],
[42,38,24,6],
[42,38,24,8],
[42,38,24,9],
[42,38,24,11],
[42,38,24,12],
[42,38,24,13],
[42,38,24,14],
[42,38,24,15],
[42,38,24,17],
[42,38,24,18],
[42,38,24,19],
[42,38,24,21],
[42,38,24,23],
[42,38,26,0],
[42,38,26,1],
[42,38,26,2],
[42,38,26,3],
[42,38,26,4],
[42,38,26,5],
[42,38,26,6],
[42,38,26,8],
[42,38,26,11],
[42,38,26,12],
[42,38,26,13],
[42,38,26,14],
[42,38,26,16],
[42,38,26,17],
[42,38,26,19],
[42,38,26,21],
[42,38,26,23],
[42,38,26,24],
[42,38,29,0],
[42,38,29,1],
[42,38,29,2],
[42,38,29,3],
[42,38,29,4],
[42,38,29,5],
[42,38,29,6],
[42,38,29,8],
[42,38,29,9],
[42,38,29,13],
[42,38,29,14],
[42,38,29,15],
[42,38,29,16],
[42,38,29,17],
[42,38,29,18],
[42,38,29,21],
[42,38,29,23],
[42,38,29,24],
[42,38,29,26],
[42,38,30,0],
[42,38,30,1],
[42,38,30,2],
[42,38,30,3],
[42,38,30,4],
[42,38,30,5],
[42,38,30,6],
[42,38,30,8],
[42,38,30,9],
[42,38,30,11],
[42,38,30,12],
[42,38,30,13],
[42,38,30,14],
[42,38,30,15],
[42,38,30,16],
[42,38,30,17],
[42,38,30,19],
[42,38,30,21],
[42,38,30,24],
[42,38,30,26],
[42,38,30,29],
[42,38,31,0],
[42,38,31,1],
[42,38,31,2],
[42,38,31,3],
[42,38,31,4],
[42,38,31,5],
[42,38,31,6],
[42,38,31,8],
[42,38,31,9],
[42,38,31,11],
[42,38,31,12],
[42,38,31,13],
[42,38,31,14],
[42,38,31,15],
[42,38,31,18],
[42,38,31,19],
[42,38,31,21],
[42,38,31,24],
[42,38,31,26],
[42,38,31,29],
[42,38,32,0],
[42,38,32,1],
[42,38,32,2],
[42,38,32,3],
[42,38,32,4],
[42,38,32,5],
[42,38,32,6],
[42,38,32,8],
[42,38,32,9],
[42,38,32,13],
[42,38,32,14],
[42,38,32,16],
[42,38,32,17],
[42,38,32,18],
[42,38,32,19],
[42,38,32,21],
[42,38,32,23],
[42,38,32,24],
[42,38,32,26],
[42,38,33,0],
[42,38,33,1],
[42,38,33,2],
[42,38,33,3],
[42,38,33,4],
[42,38,33,5],
[42,38,33,6],
[42,38,33,8],
[42,38,33,9],
[42,38,33,13],
[42,38,33,15],
[42,38,33,16],
[42,38,33,17],
[42,38,33,18],
[42,38,33,19],
[42,38,33,21],
[42,38,33,23],
[42,38,33,24],
[42,38,33,26],
[42,38,35,0],
[42,38,35,1],
[42,38,35,2],
[42,38,35,3],
[42,38,35,4],
[42,38,35,5],
[42,38,35,6],
[42,38,35,8],
[42,38,35,12],
[42,38,35,13],
[42,38,35,14],
[42,38,35,16],
[42,38,35,17],
[42,38,35,19],
[42,38,35,21],
[42,38,35,23],
[42,38,35,24],
[42,38,36,0],
[42,38,36,1],
[42,38,36,2],
[42,38,36,3],
[42,38,36,4],
[42,38,36,5],
[42,38,36,6],
[42,38,36,11],
[42,38,36,12],
[42,38,36,15],
[42,38,36,16],
[42,38,36,17],
[42,38,36,18],
[42,38,36,21],
[42,38,36,23],
[42,38,36,24],
[42,38,37,0],
[42,38,37,1],
[42,38,37,2],
[42,38,37,3],
[42,38,37,5],
[42,38,37,8],
[42,38,37,9],
[42,38,37,11],
[42,38,37,12],
[42,38,37,13],
[42,38,37,14],
[42,38,37,15],
[42,38,37,16],
[42,38,37,17],
[42,38,37,18],
[42,38,37,19],
[42,38,37,21],
[42,38,37,23],
[42,38,37,24],
[42,39,4,0],
[42,39,4,1],
[42,39,4,2],
[42,39,7,0],
[42,39,7,1],
[42,39,7,2],
[42,39,7,4],
[42,39,9,0],
[42,39,9,1],
[42,39,9,2],
[42,39,9,4],
[42,39,9,7],
[42,39,11,0],
[42,39,11,1],
[42,39,11,2],
[42,39,11,4],
[42,39,11,7],
[42,39,11,9],
[42,39,12,0],
[42,39,12,1],
[42,39,12,2],
[42,39,12,4],
[42,39,12,7],
[42,39,12,9],
[42,39,13,0],
[42,39,13,1],
[42,39,13,2],
[42,39,13,4],
[42,39,13,7],
[42,39,13,9],
[42,39,13,11],
[42,39,13,12],
[42,39,14,0],
[42,39,14,1],
[42,39,14,2],
[42,39,14,4],
[42,39,14,7],
[42,39,14,9],
[42,39,14,11],
[42,39,14,12],
[42,39,15,0],
[42,39,15,1],
[42,39,15,2],
[42,39,15,4],
[42,39,15,7],
[42,39,15,11],
[42,39,15,12],
[42,39,15,13],
[42,39,15,14],
[42,39,16,0],
[42,39,16,1],
[42,39,16,2],
[42,39,16,4],
[42,39,16,7],
[42,39,16,9],
[42,39,16,11],
[42,39,16,12],
[42,39,16,13],
[42,39,16,14],
[42,39,16,15],
[42,39,17,0],
[42,39,17,1],
[42,39,17,2],
[42,39,17,4],
[42,39,17,7],
[42,39,17,9],
[42,39,17,11],
[42,39,17,12],
[42,39,17,13],
[42,39,17,14],
[42,39,17,15],
[42,39,17,16],
[42,39,18,0],
[42,39,18,1],
[42,39,18,2],
[42,39,18,4],
[42,39,18,7],
[42,39,18,11],
[42,39,18,12],
[42,39,18,13],
[42,39,18,14],
[42,39,18,16],
[42,39,18,17],
[42,39,19,0],
[42,39,19,1],
[42,39,19,2],
[42,39,19,4],
[42,39,19,7],
[42,39,19,9],
[42,39,19,11],
[42,39,19,12],
[42,39,19,15],
[42,39,19,16],
[42,39,19,17],
[42,39,19,18],
[42,39,23,0],
[42,39,23,1],
[42,39,23,2],
[42,39,23,4],
[42,39,23,7],
[42,39,23,9],
[42,39,23,11],
[42,39,23,12],
[42,39,23,13],
[42,39,23,14],
[42,39,23,15],
[42,39,23,16],
[42,39,23,17],
[42,39,23,18],
[42,39,23,19],
[42,39,24,0],
[42,39,24,1],
[42,39,24,2],
[42,39,24,4],
[42,39,24,7],
[42,39,24,9],
[42,39,24,11],
[42,39,24,12],
[42,39,24,13],
[42,39,24,14],
[42,39,24,15],
[42,39,24,17],
[42,39,24,18],
[42,39,24,19],
[42,39,24,23],
[42,39,25,0],
[42,39,25,1],
[42,39,25,2],
[42,39,25,4],
[42,39,25,9],
[42,39,25,11],
[42,39,25,12],
[42,39,25,13],
[42,39,25,14],
[42,39,25,15],
[42,39,25,16],
[42,39,25,17],
[42,39,25,18],
[42,39,25,19],
[42,39,25,23],
[42,39,25,24],
[42,39,26,0],
[42,39,26,1],
[42,39,26,2],
[42,39,26,4],
[42,39,26,7],
[42,39,26,11],
[42,39,26,12],
[42,39,26,13],
[42,39,26,14],
[42,39,26,16],
[42,39,26,17],
[42,39,26,19],
[42,39,26,23],
[42,39,26,24],
[42,39,26,25],
[42,39,28,0],
[42,39,28,1],
[42,39,28,2],
[42,39,28,4],
[42,39,28,9],
[42,39,28,11],
[42,39,28,12],
[42,39,28,13],
[42,39,28,14],
[42,39,28,15],
[42,39,28,16],
[42,39,28,17],
[42,39,28,18],
[42,39,28,23],
[42,39,28,24],
[42,39,28,26],
[42,39,29,0],
[42,39,29,1],
[42,39,29,2],
[42,39,29,4],
[42,39,29,7],
[42,39,29,9],
[42,39,29,13],
[42,39,29,14],
[42,39,29,15],
[42,39,29,16],
[42,39,29,17],
[42,39,29,19],
[42,39,29,23],
[42,39,29,24],
[42,39,29,25],
[42,39,29,26],
[42,39,29,28],
[42,39,30,0],
[42,39,30,1],
[42,39,30,2],
[42,39,30,4],
[42,39,30,7],
[42,39,30,9],
[42,39,30,11],
[42,39,30,12],
[42,39,30,13],
[42,39,30,14],
[42,39,30,15],
[42,39,30,18],
[42,39,30,19],
[42,39,30,24],
[42,39,30,25],
[42,39,30,26],
[42,39,30,28],
[42,39,30,29],
[42,39,31,0],
[42,39,31,1],
[42,39,31,2],
[42,39,31,4],
[42,39,31,7],
[42,39,31,9],
[42,39,31,11],
[42,39,31,12],
[42,39,31,13],
[42,39,31,14],
[42,39,31,16],
[42,39,31,17],
[42,39,31,18],
[42,39,31,19],
[42,39,31,24],
[42,39,31,25],
[42,39,31,26],
[42,39,31,28],
[42,39,32,0],
[42,39,32,1],
[42,39,32,2],
[42,39,32,4],
[42,39,32,7],
[42,39,32,9],
[42,39,32,13],
[42,39,32,15],
[42,39,32,16],
[42,39,32,17],
[42,39,32,18],
[42,39,32,19],
[42,39,32,23],
[42,39,32,24],
[42,39,32,25],
[42,39,32,26],
[42,39,33,0],
[42,39,33,1],
[42,39,33,2],
[42,39,33,4],
[42,39,33,7],
[42,39,33,9],
[42,39,33,14],
[42,39,33,15],
[42,39,33,16],
[42,39,33,17],
[42,39,33,18],
[42,39,33,19],
[42,39,33,23],
[42,39,33,24],
[42,39,33,25],
[42,39,33,26],
[42,39,34,0],
[42,39,34,1],
[42,39,34,2],
[42,39,34,4],
[42,39,34,9],
[42,39,34,12],
[42,39,34,13],
[42,39,34,14],
[42,39,34,15],
[42,39,34,16],
[42,39,34,17],
[42,39,34,18],
[42,39,34,19],
[42,39,34,23],
[42,39,34,24],
[42,39,35,0],
[42,39,35,1],
[42,39,35,2],
[42,39,35,4],
[42,39,35,7],
[42,39,35,11],
[42,39,35,12],
[42,39,35,13],
[42,39,35,14],
[42,39,35,16],
[42,39,35,17],
[42,39,35,19],
[42,39,35,23],
[42,39,35,24],
[42,39,36,0],
[42,39,36,1],
[42,39,36,2],
[42,39,36,4],
[42,39,36,9],
[42,39,36,11],
[42,39,36,12],
[42,39,36,15],
[42,39,36,16],
[42,39,36,17],
[42,39,36,18],
[42,39,36,23],
[42,39,36,24],
[42,39,37,0],
[42,39,37,1],
[42,39,37,2],
[42,39,37,7],
[42,39,37,9],
[42,39,37,11],
[42,39,37,12],
[42,39,37,13],
[42,39,37,14],
[42,39,37,15],
[42,39,37,16],
[42,39,37,17],
[42,39,37,18],
[42,39,37,19],
[42,39,38,0],
[42,39,38,1],
[42,39,38,2],
[42,39,38,9],
[42,39,38,11],
[42,39,38,12],
[42,39,38,13],
[42,39,38,14],
[42,39,38,15],
[42,39,38,16],
[42,39,38,17],
[42,39,38,18],
[42,39,38,19],
[42,40,3,0],
[42,40,3,1],
[42,40,3,2],
[42,40,4,0],
[42,40,4,1],
[42,40,4,2],
[42,40,4,3],
[42,40,5,0],
[42,40,5,1],
[42,40,5,2],
[42,40,5,4],
[42,40,6,0],
[42,40,6,1],
[42,40,6,2],
[42,40,6,4],
[42,40,7,0],
[42,40,7,1],
[42,40,7,2],
[42,40,7,3],
[42,40,7,4],
[42,40,7,5],
[42,40,7,6],
[42,40,8,0],
[42,40,8,1],
[42,40,8,2],
[42,40,8,4],
[42,40,8,7],
[42,40,11,0],
[42,40,11,1],
[42,40,11,2],
[42,40,11,3],
[42,40,11,4],
[42,40,11,5],
[42,40,11,6],
[42,40,11,7],
[42,40,11,8],
[42,40,12,0],
[42,40,12,1],
[42,40,12,2],
[42,40,12,3],
[42,40,12,4],
[42,40,12,5],
[42,40,12,6],
[42,40,12,7],
[42,40,12,8],
[42,40,13,0],
[42,40,13,1],
[42,40,13,2],
[42,40,13,3],
[42,40,13,4],
[42,40,13,5],
[42,40,13,6],
[42,40,13,7],
[42,40,13,8],
[42,40,13,11],
[42,40,13,12],
[42,40,14,0],
[42,40,14,1],
[42,40,14,2],
[42,40,14,3],
[42,40,14,4],
[42,40,14,5],
[42,40,14,6],
[42,40,14,7],
[42,40,14,8],
[42,40,14,11],
[42,40,14,12],
[42,40,16,0],
[42,40,16,1],
[42,40,16,2],
[42,40,16,3],
[42,40,16,4],
[42,40,16,5],
[42,40,16,6],
[42,40,16,7],
[42,40,16,8],
[42,40,16,11],
[42,40,16,12],
[42,40,16,13],
[42,40,16,14],
[42,40,17,0],
[42,40,17,1],
[42,40,17,2],
[42,40,17,3],
[42,40,17,4],
[42,40,17,5],
[42,40,17,6],
[42,40,17,7],
[42,40,17,8],
[42,40,17,11],
[42,40,17,12],
[42,40,17,13],
[42,40,17,14],
[42,40,17,16],
[42,40,19,0],
[42,40,19,1],
[42,40,19,2],
[42,40,19,3],
[42,40,19,4],
[42,40,19,5],
[42,40,19,6],
[42,40,19,7],
[42,40,19,8],
[42,40,19,11],
[42,40,19,12],
[42,40,19,16],
[42,40,19,17],
[42,40,21,0],
[42,40,21,1],
[42,40,21,2],
[42,40,21,4],
[42,40,21,7],
[42,40,21,11],
[42,40,21,12],
[42,40,21,13],
[42,40,21,14],
[42,40,21,16],
[42,40,21,17],
[42,40,21,19],
[42,40,23,0],
[42,40,23,1],
[42,40,23,2],
[42,40,23,3],
[42,40,23,4],
[42,40,23,5],
[42,40,23,6],
[42,40,23,7],
[42,40,23,8],
[42,40,23,11],
[42,40,23,12],
[42,40,23,13],
[42,40,23,14],
[42,40,23,16],
[42,40,23,17],
[42,40,23,19],
[42,40,23,21],
[42,40,24,0],
[42,40,24,1],
[42,40,24,2],
[42,40,24,3],
[42,40,24,4],
[42,40,24,5],
[42,40,24,6],
[42,40,24,7],
[42,40,24,8],
[42,40,24,11],
[42,40,24,12],
[42,40,24,13],
[42,40,24,14],
[42,40,24,17],
[42,40,24,19],
[42,40,24,21],
[42,40,25,0],
[42,40,25,1],
[42,40,25,2],
[42,40,25,3],
[42,40,25,4],
[42,40,25,5],
[42,40,25,6],
[42,40,25,8],
[42,40,25,11],
[42,40,25,12],
[42,40,25,13],
[42,40,25,14],
[42,40,25,16],
[42,40,25,17],
[42,40,25,19],
[42,40,25,23],
[42,40,25,24],
[42,40,28,0],
[42,40,28,1],
[42,40,28,2],
[42,40,28,3],
[42,40,28,4],
[42,40,28,5],
[42,40,28,6],
[42,40,28,8],
[42,40,28,11],
[42,40,28,12],
[42,40,28,13],
[42,40,28,14],
[42,40,28,16],
[42,40,28,17],
[42,40,28,19],
[42,40,28,21],
[42,40,28,23],
[42,40,28,24],
[42,40,29,0],
[42,40,29,1],
[42,40,29,2],
[42,40,29,3],
[42,40,29,4],
[42,40,29,5],
[42,40,29,6],
[42,40,29,7],
[42,40,29,8],
[42,40,29,13],
[42,40,29,14],
[42,40,29,19],
[42,40,29,21],
[42,40,29,23],
[42,40,29,24],
[42,40,29,25],
[42,40,29,28],
[42,40,30,0],
[42,40,30,1],
[42,40,30,2],
[42,40,30,3],
[42,40,30,4],
[42,40,30,5],
[42,40,30,6],
[42,40,30,7],
[42,40,30,8],
[42,40,30,11],
[42,40,30,12],
[42,40,30,13],
[42,40,30,14],
[42,40,30,16],
[42,40,30,17],
[42,40,30,19],
[42,40,30,21],
[42,40,30,24],
[42,40,30,25],
[42,40,30,28],
[42,40,31,0],
[42,40,31,1],
[42,40,31,2],
[42,40,31,3],
[42,40,31,4],
[42,40,31,5],
[42,40,31,6],
[42,40,31,7],
[42,40,31,8],
[42,40,31,11],
[42,40,31,12],
[42,40,31,13],
[42,40,31,16],
[42,40,31,17],
[42,40,31,19],
[42,40,31,21],
[42,40,31,24],
[42,40,31,25],
[42,40,32,0],
[42,40,32,1],
[42,40,32,2],
[42,40,32,3],
[42,40,32,4],
[42,40,32,5],
[42,40,32,6],
[42,40,32,7],
[42,40,32,8],
[42,40,32,14],
[42,40,32,16],
[42,40,32,17],
[42,40,32,19],
[42,40,32,21],
[42,40,32,23],
[42,40,32,24],
[42,40,32,25],
[42,40,33,0],
[42,40,33,1],
[42,40,33,2],
[42,40,33,3],
[42,40,33,4],
[42,40,33,5],
[42,40,33,6],
[42,40,33,7],
[42,40,33,8],
[42,40,33,13],
[42,40,33,14],
[42,40,33,16],
[42,40,33,17],
[42,40,33,19],
[42,40,33,21],
[42,40,33,23],
[42,40,33,24],
[42,40,33,25],
[42,40,34,0],
[42,40,34,1],
[42,40,34,2],
[42,40,34,3],
[42,40,34,4],
[42,40,34,5],
[42,40,34,6],
[42,40,34,11],
[42,40,34,12],
[42,40,34,13],
[42,40,34,14],
[42,40,34,16],
[42,40,34,17],
[42,40,34,19],
[42,40,34,21],
[42,40,34,23],
[42,40,34,24],
[42,40,36,0],
[42,40,36,1],
[42,40,36,2],
[42,40,36,3],
[42,40,36,4],
[42,40,36,6],
[42,40,36,7],
[42,40,36,8],
[42,40,36,11],
[42,40,36,12],
[42,40,36,16],
[42,40,36,17],
[42,40,36,21],
[42,40,37,0],
[42,40,37,1],
[42,40,37,2],
[42,40,37,5],
[42,40,37,6],
[42,40,37,7],
[42,40,37,8],
[42,40,37,11],
[42,40,37,12],
[42,40,37,13],
[42,40,37,14],
[42,40,37,16],
[42,40,37,17],
[42,40,37,19],
[42,40,37,21],
[42,40,38,3],
[42,40,38,4],
[42,40,38,5],
[42,40,38,6],
[42,40,38,8],
[42,40,38,11],
[42,40,38,12],
[42,40,38,13],
[42,40,38,14],
[42,40,38,16],
[42,40,38,17],
[42,40,38,19],
[42,40,39,0],
[42,40,39,1],
[42,40,39,2],
[42,40,39,4],
[42,40,39,7],
[42,40,39,11],
[42,40,39,12],
[42,40,39,13],
[42,40,39,14],
[42,40,39,16],
[42,40,39,17],
[42,40,39,19],
[42,41,3,0],
[42,41,3,1],
[42,41,3,2],
[42,41,4,0],
[42,41,4,1],
[42,41,4,2],
[42,41,4,3],
[42,41,5,0],
[42,41,5,1],
[42,41,5,2],
[42,41,5,4],
[42,41,6,0],
[42,41,6,1],
[42,41,6,2],
[42,41,6,4],
[42,41,7,0],
[42,41,7,1],
[42,41,7,2],
[42,41,7,3],
[42,41,7,4],
[42,41,7,5],
[42,41,7,6],
[42,41,8,0],
[42,41,8,1],
[42,41,8,2],
[42,41,8,4],
[42,41,8,7],
[42,41,11,0],
[42,41,11,1],
[42,41,11,2],
[42,41,11,3],
[42,41,11,4],
[42,41,11,5],
[42,41,11,6],
[42,41,11,7],
[42,41,11,8],
[42,41,12,0],
[42,41,12,1],
[42,41,12,2],
[42,41,12,3],
[42,41,12,4],
[42,41,12,5],
[42,41,12,6],
[42,41,12,7],
[42,41,12,8],
[42,41,13,0],
[42,41,13,1],
[42,41,13,2],
[42,41,13,3],
[42,41,13,4],
[42,41,13,5],
[42,41,13,6],
[42,41,13,7],
[42,41,13,8],
[42,41,13,11],
[42,41,13,12],
[42,41,14,0],
[42,41,14,1],
[42,41,14,2],
[42,41,14,3],
[42,41,14,4],
[42,41,14,5],
[42,41,14,6],
[42,41,14,7],
[42,41,14,8],
[42,41,14,11],
[42,41,14,12],
[42,41,16,0],
[42,41,16,1],
[42,41,16,2],
[42,41,16,3],
[42,41,16,4],
[42,41,16,5],
[42,41,16,6],
[42,41,16,7],
[42,41,16,8],
[42,41,16,11],
[42,41,16,12],
[42,41,16,13],
[42,41,16,14],
[42,41,17,0],
[42,41,17,1],
[42,41,17,2],
[42,41,17,3],
[42,41,17,4],
[42,41,17,5],
[42,41,17,6],
[42,41,17,7],
[42,41,17,8],
[42,41,17,11],
[42,41,17,12],
[42,41,17,13],
[42,41,17,14],
[42,41,17,16],
[42,41,19,0],
[42,41,19,1],
[42,41,19,2],
[42,41,19,3],
[42,41,19,4],
[42,41,19,5],
[42,41,19,6],
[42,41,19,7],
[42,41,19,8],
[42,41,19,11],
[42,41,19,12],
[42,41,19,16],
[42,41,19,17],
[42,41,21,0],
[42,41,21,1],
[42,41,21,2],
[42,41,21,4],
[42,41,21,7],
[42,41,21,11],
[42,41,21,12],
[42,41,21,13],
[42,41,21,14],
[42,41,21,16],
[42,41,21,17],
[42,41,21,19],
[42,41,23,0],
[42,41,23,1],
[42,41,23,2],
[42,41,23,3],
[42,41,23,4],
[42,41,23,5],
[42,41,23,6],
[42,41,23,7],
[42,41,23,8],
[42,41,23,11],
[42,41,23,12],
[42,41,23,13],
[42,41,23,14],
[42,41,23,16],
[42,41,23,17],
[42,41,23,19],
[42,41,23,21],
[42,41,24,0],
[42,41,24,1],
[42,41,24,2],
[42,41,24,3],
[42,41,24,4],
[42,41,24,5],
[42,41,24,6],
[42,41,24,7],
[42,41,24,8],
[42,41,24,11],
[42,41,24,12],
[42,41,24,13],
[42,41,24,14],
[42,41,24,17],
[42,41,24,19],
[42,41,24,21],
[42,41,25,0],
[42,41,25,1],
[42,41,25,2],
[42,41,25,3],
[42,41,25,4],
[42,41,25,5],
[42,41,25,6],
[42,41,25,8],
[42,41,25,11],
[42,41,25,12],
[42,41,25,13],
[42,41,25,14],
[42,41,25,16],
[42,41,25,17],
[42,41,25,19],
[42,41,25,23],
[42,41,25,24],
[42,41,28,0],
[42,41,28,1],
[42,41,28,2],
[42,41,28,3],
[42,41,28,4],
[42,41,28,5],
[42,41,28,6],
[42,41,28,8],
[42,41,28,11],
[42,41,28,12],
[42,41,28,13],
[42,41,28,14],
[42,41,28,16],
[42,41,28,17],
[42,41,28,19],
[42,41,28,21],
[42,41,28,23],
[42,41,28,24],
[42,41,29,0],
[42,41,29,1],
[42,41,29,2],
[42,41,29,3],
[42,41,29,4],
[42,41,29,5],
[42,41,29,6],
[42,41,29,7],
[42,41,29,8],
[42,41,29,13],
[42,41,29,14],
[42,41,29,19],
[42,41,29,21],
[42,41,29,23],
[42,41,29,24],
[42,41,29,25],
[42,41,29,28],
[42,41,30,0],
[42,41,30,1],
[42,41,30,2],
[42,41,30,3],
[42,41,30,4],
[42,41,30,5],
[42,41,30,6],
[42,41,30,7],
[42,41,30,8],
[42,41,30,11],
[42,41,30,12],
[42,41,30,13],
[42,41,30,14],
[42,41,30,16],
[42,41,30,17],
[42,41,30,19],
[42,41,30,21],
[42,41,30,24],
[42,41,30,25],
[42,41,30,28],
[42,41,31,0],
[42,41,31,1],
[42,41,31,2],
[42,41,31,3],
[42,41,31,4],
[42,41,31,5],
[42,41,31,6],
[42,41,31,7],
[42,41,31,8],
[42,41,31,11],
[42,41,31,12],
[42,41,31,13],
[42,41,31,16],
[42,41,31,17],
[42,41,31,19],
[42,41,31,21],
[42,41,31,24],
[42,41,31,25],
[42,41,32,0],
[42,41,32,1],
[42,41,32,2],
[42,41,32,3],
[42,41,32,4],
[42,41,32,5],
[42,41,32,6],
[42,41,32,7],
[42,41,32,8],
[42,41,32,14],
[42,41,32,16],
[42,41,32,17],
[42,41,32,19],
[42,41,32,21],
[42,41,32,23],
[42,41,32,24],
[42,41,32,25],
[42,41,33,0],
[42,41,33,1],
[42,41,33,2],
[42,41,33,3],
[42,41,33,4],
[42,41,33,5],
[42,41,33,6],
[42,41,33,7],
[42,41,33,8],
[42,41,33,13],
[42,41,33,14],
[42,41,33,16],
[42,41,33,17],
[42,41,33,19],
[42,41,33,21],
[42,41,33,23],
[42,41,33,24],
[42,41,33,25],
[42,41,34,0],
[42,41,34,1],
[42,41,34,2],
[42,41,34,3],
[42,41,34,4],
[42,41,34,5],
[42,41,34,6],
[42,41,34,11],
[42,41,34,12],
[42,41,34,13],
[42,41,34,14],
[42,41,34,16],
[42,41,34,17],
[42,41,34,19],
[42,41,34,21],
[42,41,34,23],
[42,41,34,24],
[42,41,36,0],
[42,41,36,1],
[42,41,36,2],
[42,41,36,3],
[42,41,36,4],
[42,41,36,6],
[42,41,36,7],
[42,41,36,8],
[42,41,36,11],
[42,41,36,12],
[42,41,36,16],
[42,41,36,17],
[42,41,36,21],
[42,41,37,0],
[42,41,37,1],
[42,41,37,2],
[42,41,37,5],
[42,41,37,6],
[42,41,37,7],
[42,41,37,8],
[42,41,37,11],
[42,41,37,12],
[42,41,37,13],
[42,41,37,14],
[42,41,37,16],
[42,41,37,17],
[42,41,37,19],
[42,41,37,21],
[42,41,38,3],
[42,41,38,4],
[42,41,38,5],
[42,41,38,6],
[42,41,38,8],
[42,41,38,11],
[42,41,38,12],
[42,41,38,13],
[42,41,38,14],
[42,41,38,16],
[42,41,38,17],
[42,41,38,19],
[42,41,39,0],
[42,41,39,1],
[42,41,39,2],
[42,41,39,4],
[42,41,39,7],
[42,41,39,11],
[42,41,39,12],
[42,41,39,13],
[42,41,39,14],
[42,41,39,16],
[42,41,39,17],
[42,41,39,19],
[43,4,3,0],
[43,4,3,1],
[43,4,3,2],
[43,5,4,0],
[43,5,4,1],
[43,5,4,2],
[43,6,4,0],
[43,6,4,1],
[43,6,4,2],
[43,8,4,0],
[43,8,4,1],
[43,8,4,2],
[43,9,3,0],
[43,9,3,1],
[43,9,3,2],
[43,9,4,0],
[43,9,4,1],
[43,9,4,2],
[43,9,4,3],
[43,9,5,0],
[43,9,5,1],
[43,9,5,2],
[43,9,5,4],
[43,9,6,0],
[43,9,6,1],
[43,9,6,2],
[43,9,6,4],
[43,9,8,0],
[43,9,8,1],
[43,9,8,2],
[43,9,8,4],
[43,10,3,0],
[43,10,3,1],
[43,10,3,2],
[43,10,4,0],
[43,10,4,1],
[43,10,4,2],
[43,10,4,3],
[43,10,5,0],
[43,10,5,1],
[43,10,5,2],
[43,10,5,4],
[43,10,6,0],
[43,10,6,1],
[43,10,6,2],
[43,10,6,4],
[43,10,8,0],
[43,10,8,1],
[43,10,8,2],
[43,10,8,4],
[43,10,9,0],
[43,10,9,1],
[43,10,9,2],
[43,10,9,3],
[43,10,9,4],
[43,10,9,5],
[43,10,9,6],
[43,10,9,8],
[43,11,3,0],
[43,11,3,1],
[43,11,3,2],
[43,11,4,0],
[43,11,4,1],
[43,11,4,2],
[43,11,4,3],
[43,11,5,0],
[43,11,5,1],
[43,11,5,2],
[43,11,5,4],
[43,11,6,0],
[43,11,6,1],
[43,11,6,2],
[43,11,6,4],
[43,11,8,0],
[43,11,8,1],
[43,11,8,2],
[43,11,8,4],
[43,11,9,0],
[43,11,9,1],
[43,11,9,2],
[43,11,9,3],
[43,11,9,4],
[43,11,9,5],
[43,11,9,6],
[43,11,9,8],
[43,11,10,0],
[43,11,10,1],
[43,11,10,2],
[43,11,10,3],
[43,11,10,4],
[43,11,10,5],
[43,11,10,6],
[43,11,10,8],
[43,11,10,9],
[43,12,3,0],
[43,12,3,1],
[43,12,3,2],
[43,12,4,0],
[43,12,4,1],
[43,12,4,2],
[43,12,4,3],
[43,12,5,0],
[43,12,5,1],
[43,12,5,2],
[43,12,5,4],
[43,12,6,0],
[43,12,6,1],
[43,12,6,2],
[43,12,6,4],
[43,12,8,0],
[43,12,8,1],
[43,12,8,2],
[43,12,8,4],
[43,12,9,0],
[43,12,9,1],
[43,12,9,2],
[43,12,9,3],
[43,12,9,4],
[43,12,9,5],
[43,12,9,6],
[43,12,9,8],
[43,12,10,0],
[43,12,10,1],
[43,12,10,2],
[43,12,10,3],
[43,12,10,4],
[43,12,10,5],
[43,12,10,6],
[43,12,10,8],
[43,12,10,9],
[43,13,3,0],
[43,13,3,1],
[43,13,3,2],
[43,13,4,0],
[43,13,4,1],
[43,13,4,2],
[43,13,4,3],
[43,13,5,0],
[43,13,5,1],
[43,13,5,2],
[43,13,5,4],
[43,13,6,0],
[43,13,6,1],
[43,13,6,2],
[43,13,6,4],
[43,13,8,0],
[43,13,8,1],
[43,13,8,2],
[43,13,8,4],
[43,13,9,0],
[43,13,9,1],
[43,13,9,2],
[43,13,9,3],
[43,13,9,4],
[43,13,9,5],
[43,13,9,6],
[43,13,9,8],
[43,13,10,0],
[43,13,10,1],
[43,13,10,2],
[43,13,10,3],
[43,13,10,4],
[43,13,10,5],
[43,13,10,6],
[43,13,10,8],
[43,13,10,9],
[43,13,11,0],
[43,13,11,1],
[43,13,11,2],
[43,13,11,3],
[43,13,11,4],
[43,13,11,5],
[43,13,11,6],
[43,13,11,8],
[43,13,11,9],
[43,13,11,10],
[43,13,12,0],
[43,13,12,1],
[43,13,12,2],
[43,13,12,3],
[43,13,12,4],
[43,13,12,5],
[43,13,12,6],
[43,13,12,8],
[43,13,12,9],
[43,13,12,10],
[43,14,3,0],
[43,14,3,1],
[43,14,3,2],
[43,14,4,0],
[43,14,4,1],
[43,14,4,2],
[43,14,4,3],
[43,14,5,0],
[43,14,5,1],
[43,14,5,2],
[43,14,5,4],
[43,14,6,0],
[43,14,6,1],
[43,14,6,2],
[43,14,6,4],
[43,14,8,0],
[43,14,8,1],
[43,14,8,2],
[43,14,8,4],
[43,14,9,0],
[43,14,9,1],
[43,14,9,2],
[43,14,9,3],
[43,14,9,4],
[43,14,9,5],
[43,14,9,6],
[43,14,9,8],
[43,14,10,0],
[43,14,10,1],
[43,14,10,2],
[43,14,10,3],
[43,14,10,4],
[43,14,10,5],
[43,14,10,6],
[43,14,10,8],
[43,14,10,9],
[43,14,11,0],
[43,14,11,1],
[43,14,11,2],
[43,14,11,3],
[43,14,11,4],
[43,14,11,5],
[43,14,11,6],
[43,14,11,8],
[43,14,11,9],
[43,14,11,10],
[43,14,12,0],
[43,14,12,1],
[43,14,12,2],
[43,14,12,3],
[43,14,12,4],
[43,14,12,5],
[43,14,12,6],
[43,14,12,8],
[43,14,12,9],
[43,14,12,10],
[43,15,3,0],
[43,15,3,1],
[43,15,3,2],
[43,15,4,0],
[43,15,4,1],
[43,15,4,2],
[43,15,4,3],
[43,15,5,0],
[43,15,5,1],
[43,15,5,2],
[43,15,5,4],
[43,15,6,0],
[43,15,6,1],
[43,15,6,2],
[43,15,6,4],
[43,15,8,0],
[43,15,8,1],
[43,15,8,2],
[43,15,8,4],
[43,15,10,0],
[43,15,10,1],
[43,15,10,2],
[43,15,10,3],
[43,15,10,4],
[43,15,10,5],
[43,15,10,6],
[43,15,10,8],
[43,15,11,0],
[43,15,11,1],
[43,15,11,2],
[43,15,11,3],
[43,15,11,4],
[43,15,11,5],
[43,15,11,6],
[43,15,11,8],
[43,15,11,10],
[43,15,12,0],
[43,15,12,1],
[43,15,12,2],
[43,15,12,3],
[43,15,12,4],
[43,15,12,5],
[43,15,12,6],
[43,15,12,8],
[43,15,12,10],
[43,15,13,0],
[43,15,13,1],
[43,15,13,2],
[43,15,13,3],
[43,15,13,4],
[43,15,13,5],
[43,15,13,6],
[43,15,13,8],
[43,15,13,10],
[43,15,13,11],
[43,15,13,12],
[43,15,14,0],
[43,15,14,1],
[43,15,14,2],
[43,15,14,3],
[43,15,14,4],
[43,15,14,5],
[43,15,14,6],
[43,15,14,8],
[43,15,14,10],
[43,15,14,11],
[43,15,14,12],
[43,16,3,0],
[43,16,3,1],
[43,16,3,2],
[43,16,4,0],
[43,16,4,1],
[43,16,4,2],
[43,16,4,3],
[43,16,5,0],
[43,16,5,1],
[43,16,5,2],
[43,16,5,4],
[43,16,6,0],
[43,16,6,1],
[43,16,6,2],
[43,16,6,4],
[43,16,8,0],
[43,16,8,1],
[43,16,8,2],
[43,16,8,4],
[43,16,9,0],
[43,16,9,1],
[43,16,9,2],
[43,16,9,3],
[43,16,9,4],
[43,16,9,5],
[43,16,9,6],
[43,16,9,8],
[43,16,10,0],
[43,16,10,1],
[43,16,10,2],
[43,16,10,3],
[43,16,10,4],
[43,16,10,5],
[43,16,10,6],
[43,16,10,8],
[43,16,10,9],
[43,16,11,0],
[43,16,11,1],
[43,16,11,2],
[43,16,11,3],
[43,16,11,4],
[43,16,11,5],
[43,16,11,6],
[43,16,11,8],
[43,16,11,9],
[43,16,11,10],
[43,16,12,0],
[43,16,12,1],
[43,16,12,2],
[43,16,12,3],
[43,16,12,4],
[43,16,12,5],
[43,16,12,6],
[43,16,12,8],
[43,16,12,9],
[43,16,12,10],
[43,16,13,0],
[43,16,13,1],
[43,16,13,2],
[43,16,13,3],
[43,16,13,4],
[43,16,13,5],
[43,16,13,6],
[43,16,13,8],
[43,16,13,9],
[43,16,13,10],
[43,16,13,11],
[43,16,13,12],
[43,16,14,0],
[43,16,14,1],
[43,16,14,2],
[43,16,14,3],
[43,16,14,4],
[43,16,14,5],
[43,16,14,6],
[43,16,14,8],
[43,16,14,9],
[43,16,14,10],
[43,16,14,11],
[43,16,14,12],
[43,16,15,0],
[43,16,15,1],
[43,16,15,2],
[43,16,15,3],
[43,16,15,4],
[43,16,15,5],
[43,16,15,6],
[43,16,15,8],
[43,16,15,10],
[43,16,15,11],
[43,16,15,12],
[43,16,15,13],
[43,16,15,14],
[43,17,3,0],
[43,17,3,1],
[43,17,3,2],
[43,17,4,0],
[43,17,4,1],
[43,17,4,2],
[43,17,4,3],
[43,17,5,0],
[43,17,5,1],
[43,17,5,2],
[43,17,5,4],
[43,17,6,0],
[43,17,6,1],
[43,17,6,2],
[43,17,6,4],
[43,17,8,0],
[43,17,8,1],
[43,17,8,2],
[43,17,8,4],
[43,17,9,0],
[43,17,9,1],
[43,17,9,2],
[43,17,9,3],
[43,17,9,4],
[43,17,9,5],
[43,17,9,6],
[43,17,9,8],
[43,17,10,0],
[43,17,10,1],
[43,17,10,2],
[43,17,10,3],
[43,17,10,4],
[43,17,10,5],
[43,17,10,6],
[43,17,10,8],
[43,17,10,9],
[43,17,11,0],
[43,17,11,1],
[43,17,11,2],
[43,17,11,3],
[43,17,11,4],
[43,17,11,5],
[43,17,11,6],
[43,17,11,8],
[43,17,11,9],
[43,17,11,10],
[43,17,12,0],
[43,17,12,1],
[43,17,12,2],
[43,17,12,3],
[43,17,12,4],
[43,17,12,5],
[43,17,12,6],
[43,17,12,8],
[43,17,12,9],
[43,17,12,10],
[43,17,13,0],
[43,17,13,1],
[43,17,13,2],
[43,17,13,3],
[43,17,13,4],
[43,17,13,5],
[43,17,13,6],
[43,17,13,8],
[43,17,13,9],
[43,17,13,10],
[43,17,13,11],
[43,17,13,12],
[43,17,14,0],
[43,17,14,1],
[43,17,14,2],
[43,17,14,3],
[43,17,14,4],
[43,17,14,5],
[43,17,14,6],
[43,17,14,8],
[43,17,14,9],
[43,17,14,10],
[43,17,14,11],
[43,17,14,12],
[43,17,15,0],
[43,17,15,1],
[43,17,15,2],
[43,17,15,3],
[43,17,15,4],
[43,17,15,5],
[43,17,15,6],
[43,17,15,8],
[43,17,15,10],
[43,17,15,11],
[43,17,15,12],
[43,17,15,13],
[43,17,15,14],
[43,17,16,0],
[43,17,16,1],
[43,17,16,2],
[43,17,16,3],
[43,17,16,4],
[43,17,16,5],
[43,17,16,6],
[43,17,16,8],
[43,17,16,9],
[43,17,16,10],
[43,17,16,11],
[43,17,16,12],
[43,17,16,13],
[43,17,16,14],
[43,17,16,15],
[43,18,3,0],
[43,18,3,1],
[43,18,3,2],
[43,18,4,0],
[43,18,4,1],
[43,18,4,2],
[43,18,4,3],
[43,18,5,0],
[43,18,5,1],
[43,18,5,2],
[43,18,5,4],
[43,18,6,0],
[43,18,6,1],
[43,18,6,2],
[43,18,6,4],
[43,18,8,0],
[43,18,8,1],
[43,18,8,2],
[43,18,8,4],
[43,18,10,0],
[43,18,10,1],
[43,18,10,2],
[43,18,10,3],
[43,18,10,4],
[43,18,10,5],
[43,18,10,6],
[43,18,10,8],
[43,18,11,0],
[43,18,11,1],
[43,18,11,2],
[43,18,11,3],
[43,18,11,4],
[43,18,11,5],
[43,18,11,6],
[43,18,11,8],
[43,18,11,10],
[43,18,12,0],
[43,18,12,1],
[43,18,12,2],
[43,18,12,3],
[43,18,12,4],
[43,18,12,5],
[43,18,12,6],
[43,18,12,8],
[43,18,12,10],
[43,18,13,0],
[43,18,13,1],
[43,18,13,2],
[43,18,13,3],
[43,18,13,4],
[43,18,13,5],
[43,18,13,6],
[43,18,13,8],
[43,18,13,10],
[43,18,13,11],
[43,18,13,12],
[43,18,14,0],
[43,18,14,1],
[43,18,14,2],
[43,18,14,3],
[43,18,14,4],
[43,18,14,5],
[43,18,14,6],
[43,18,14,8],
[43,18,14,10],
[43,18,14,11],
[43,18,14,12],
[43,18,16,0],
[43,18,16,1],
[43,18,16,2],
[43,18,16,3],
[43,18,16,4],
[43,18,16,5],
[43,18,16,6],
[43,18,16,8],
[43,18,16,10],
[43,18,16,11],
[43,18,16,12],
[43,18,16,13],
[43,18,16,14],
[43,18,17,0],
[43,18,17,1],
[43,18,17,2],
[43,18,17,3],
[43,18,17,4],
[43,18,17,5],
[43,18,17,6],
[43,18,17,8],
[43,18,17,10],
[43,18,17,11],
[43,18,17,12],
[43,18,17,13],
[43,18,17,14],
[43,18,17,16],
[43,19,3,0],
[43,19,3,1],
[43,19,3,2],
[43,19,4,0],
[43,19,4,1],
[43,19,4,2],
[43,19,4,3],
[43,19,5,0],
[43,19,5,1],
[43,19,5,2],
[43,19,5,4],
[43,19,6,0],
[43,19,6,1],
[43,19,6,2],
[43,19,6,4],
[43,19,8,0],
[43,19,8,1],
[43,19,8,2],
[43,19,8,4],
[43,19,9,0],
[43,19,9,1],
[43,19,9,2],
[43,19,9,3],
[43,19,9,4],
[43,19,9,5],
[43,19,9,6],
[43,19,9,8],
[43,19,10,0],
[43,19,10,1],
[43,19,10,2],
[43,19,10,3],
[43,19,10,4],
[43,19,10,5],
[43,19,10,6],
[43,19,10,8],
[43,19,10,9],
[43,19,11,0],
[43,19,11,1],
[43,19,11,2],
[43,19,11,3],
[43,19,11,4],
[43,19,11,5],
[43,19,11,6],
[43,19,11,8],
[43,19,11,9],
[43,19,11,10],
[43,19,12,0],
[43,19,12,1],
[43,19,12,2],
[43,19,12,3],
[43,19,12,4],
[43,19,12,5],
[43,19,12,6],
[43,19,12,8],
[43,19,12,9],
[43,19,12,10],
[43,19,15,0],
[43,19,15,1],
[43,19,15,2],
[43,19,15,3],
[43,19,15,4],
[43,19,15,5],
[43,19,15,6],
[43,19,15,8],
[43,19,15,10],
[43,19,15,11],
[43,19,15,12],
[43,19,16,0],
[43,19,16,1],
[43,19,16,2],
[43,19,16,3],
[43,19,16,4],
[43,19,16,5],
[43,19,16,6],
[43,19,16,8],
[43,19,16,9],
[43,19,16,10],
[43,19,16,11],
[43,19,16,12],
[43,19,16,15],
[43,19,17,0],
[43,19,17,1],
[43,19,17,2],
[43,19,17,3],
[43,19,17,4],
[43,19,17,5],
[43,19,17,6],
[43,19,17,8],
[43,19,17,9],
[43,19,17,10],
[43,19,17,11],
[43,19,17,12],
[43,19,17,15],
[43,19,17,16],
[43,19,18,0],
[43,19,18,1],
[43,19,18,2],
[43,19,18,3],
[43,19,18,4],
[43,19,18,5],
[43,19,18,6],
[43,19,18,8],
[43,19,18,10],
[43,19,18,11],
[43,19,18,12],
[43,19,18,16],
[43,19,18,17],
[43,20,3,0],
[43,20,3,1],
[43,20,3,2],
[43,20,4,0],
[43,20,4,1],
[43,20,4,2],
[43,20,4,3],
[43,20,5,0],
[43,20,5,1],
[43,20,5,2],
[43,20,5,4],
[43,20,6,0],
[43,20,6,1],
[43,20,6,2],
[43,20,6,4],
[43,20,8,0],
[43,20,8,1],
[43,20,8,2],
[43,20,8,4],
[43,20,9,0],
[43,20,9,1],
[43,20,9,2],
[43,20,9,3],
[43,20,9,4],
[43,20,9,5],
[43,20,9,6],
[43,20,9,8],
[43,20,11,0],
[43,20,11,1],
[43,20,11,2],
[43,20,11,3],
[43,20,11,4],
[43,20,11,5],
[43,20,11,6],
[43,20,11,8],
[43,20,11,9],
[43,20,12,0],
[43,20,12,1],
[43,20,12,2],
[43,20,12,3],
[43,20,12,4],
[43,20,12,5],
[43,20,12,6],
[43,20,12,8],
[43,20,12,9],
[43,20,13,0],
[43,20,13,1],
[43,20,13,2],
[43,20,13,3],
[43,20,13,4],
[43,20,13,5],
[43,20,13,6],
[43,20,13,8],
[43,20,13,9],
[43,20,13,11],
[43,20,13,12],
[43,20,14,0],
[43,20,14,1],
[43,20,14,2],
[43,20,14,3],
[43,20,14,4],
[43,20,14,5],
[43,20,14,6],
[43,20,14,8],
[43,20,14,9],
[43,20,14,11],
[43,20,14,12],
[43,20,15,0],
[43,20,15,1],
[43,20,15,2],
[43,20,15,3],
[43,20,15,4],
[43,20,15,5],
[43,20,15,6],
[43,20,15,8],
[43,20,15,11],
[43,20,15,12],
[43,20,15,13],
[43,20,15,14],
[43,20,16,0],
[43,20,16,1],
[43,20,16,2],
[43,20,16,3],
[43,20,16,4],
[43,20,16,5],
[43,20,16,6],
[43,20,16,8],
[43,20,16,9],
[43,20,16,11],
[43,20,16,12],
[43,20,16,13],
[43,20,16,14],
[43,20,16,15],
[43,20,17,0],
[43,20,17,1],
[43,20,17,2],
[43,20,17,3],
[43,20,17,4],
[43,20,17,5],
[43,20,17,6],
[43,20,17,8],
[43,20,17,9],
[43,20,17,11],
[43,20,17,12],
[43,20,17,13],
[43,20,17,14],
[43,20,17,15],
[43,20,17,16],
[43,20,18,0],
[43,20,18,1],
[43,20,18,2],
[43,20,18,3],
[43,20,18,4],
[43,20,18,5],
[43,20,18,6],
[43,20,18,8],
[43,20,18,11],
[43,20,18,12],
[43,20,18,13],
[43,20,18,14],
[43,20,18,16],
[43,20,18,17],
[43,20,19,0],
[43,20,19,1],
[43,20,19,2],
[43,20,19,3],
[43,20,19,4],
[43,20,19,5],
[43,20,19,6],
[43,20,19,8],
[43,20,19,9],
[43,20,19,11],
[43,20,19,12],
[43,20,19,15],
[43,20,19,16],
[43,20,19,17],
[43,20,19,18],
[43,21,4,0],
[43,21,4,1],
[43,21,4,2],
[43,21,9,0],
[43,21,9,1],
[43,21,9,2],
[43,21,9,4],
[43,21,10,0],
[43,21,10,1],
[43,21,10,2],
[43,21,10,4],
[43,21,10,9],
[43,21,11,0],
[43,21,11,1],
[43,21,11,2],
[43,21,11,4],
[43,21,11,9],
[43,21,11,10],
[43,21,12,0],
[43,21,12,1],
[43,21,12,2],
[43,21,12,4],
[43,21,12,9],
[43,21,12,10],
[43,21,13,0],
[43,21,13,1],
[43,21,13,2],
[43,21,13,4],
[43,21,13,9],
[43,21,13,10],
[43,21,13,11],
[43,21,13,12],
[43,21,14,0],
[43,21,14,1],
[43,21,14,2],
[43,21,14,4],
[43,21,14,9],
[43,21,14,10],
[43,21,14,11],
[43,21,14,12],
[43,21,15,0],
[43,21,15,1],
[43,21,15,2],
[43,21,15,4],
[43,21,15,10],
[43,21,15,11],
[43,21,15,12],
[43,21,15,13],
[43,21,15,14],
[43,21,16,0],
[43,21,16,1],
[43,21,16,2],
[43,21,16,4],
[43,21,16,9],
[43,21,16,10],
[43,21,16,11],
[43,21,16,12],
[43,21,16,13],
[43,21,16,14],
[43,21,16,15],
[43,21,17,0],
[43,21,17,1],
[43,21,17,2],
[43,21,17,4],
[43,21,17,9],
[43,21,17,10],
[43,21,17,11],
[43,21,17,12],
[43,21,17,13],
[43,21,17,14],
[43,21,17,15],
[43,21,17,16],
[43,21,18,0],
[43,21,18,1],
[43,21,18,2],
[43,21,18,4],
[43,21,18,10],
[43,21,18,11],
[43,21,18,12],
[43,21,18,13],
[43,21,18,14],
[43,21,18,16],
[43,21,18,17],
[43,21,19,0],
[43,21,19,1],
[43,21,19,2],
[43,21,19,4],
[43,21,19,9],
[43,21,19,10],
[43,21,19,11],
[43,21,19,12],
[43,21,19,15],
[43,21,19,16],
[43,21,19,17],
[43,21,19,18],
[43,21,20,0],
[43,21,20,1],
[43,21,20,2],
[43,21,20,4],
[43,21,20,9],
[43,21,20,11],
[43,21,20,12],
[43,21,20,13],
[43,21,20,14],
[43,21,20,15],
[43,21,20,16],
[43,21,20,17],
[43,21,20,18],
[43,21,20,19],
[43,22,3,0],
[43,22,3,1],
[43,22,3,2],
[43,22,4,0],
[43,22,4,1],
[43,22,4,2],
[43,22,4,3],
[43,22,5,0],
[43,22,5,1],
[43,22,5,2],
[43,22,5,4],
[43,22,6,0],
[43,22,6,1],
[43,22,6,2],
[43,22,6,4],
[43,22,8,0],
[43,22,8,1],
[43,22,8,2],
[43,22,8,4],
[43,22,9,0],
[43,22,9,1],
[43,22,9,2],
[43,22,9,3],
[43,22,9,4],
[43,22,9,5],
[43,22,9,6],
[43,22,9,8],
[43,22,11,0],
[43,22,11,1],
[43,22,11,2],
[43,22,11,3],
[43,22,11,4],
[43,22,11,5],
[43,22,11,6],
[43,22,11,8],
[43,22,11,9],
[43,22,12,0],
[43,22,12,1],
[43,22,12,2],
[43,22,12,3],
[43,22,12,4],
[43,22,12,5],
[43,22,12,6],
[43,22,12,8],
[43,22,12,9],
[43,22,13,0],
[43,22,13,1],
[43,22,13,2],
[43,22,13,3],
[43,22,13,4],
[43,22,13,5],
[43,22,13,6],
[43,22,13,8],
[43,22,13,9],
[43,22,13,11],
[43,22,13,12],
[43,22,14,0],
[43,22,14,1],
[43,22,14,2],
[43,22,14,3],
[43,22,14,4],
[43,22,14,5],
[43,22,14,6],
[43,22,14,8],
[43,22,14,9],
[43,22,14,11],
[43,22,14,12],
[43,22,15,0],
[43,22,15,1],
[43,22,15,2],
[43,22,15,3],
[43,22,15,4],
[43,22,15,5],
[43,22,15,6],
[43,22,15,8],
[43,22,15,11],
[43,22,15,12],
[43,22,15,13],
[43,22,15,14],
[43,22,16,0],
[43,22,16,1],
[43,22,16,2],
[43,22,16,3],
[43,22,16,4],
[43,22,16,5],
[43,22,16,6],
[43,22,16,8],
[43,22,16,9],
[43,22,16,11],
[43,22,16,12],
[43,22,16,13],
[43,22,16,14],
[43,22,16,15],
[43,22,17,0],
[43,22,17,1],
[43,22,17,2],
[43,22,17,3],
[43,22,17,4],
[43,22,17,5],
[43,22,17,6],
[43,22,17,8],
[43,22,17,9],
[43,22,17,11],
[43,22,17,12],
[43,22,17,13],
[43,22,17,14],
[43,22,17,15],
[43,22,17,16],
[43,22,18,0],
[43,22,18,1],
[43,22,18,2],
[43,22,18,3],
[43,22,18,4],
[43,22,18,5],
[43,22,18,6],
[43,22,18,8],
[43,22,18,11],
[43,22,18,12],
[43,22,18,13],
[43,22,18,14],
[43,22,18,16],
[43,22,18,17],
[43,22,19,0],
[43,22,19,1],
[43,22,19,2],
[43,22,19,3],
[43,22,19,4],
[43,22,19,5],
[43,22,19,6],
[43,22,19,8],
[43,22,19,9],
[43,22,19,11],
[43,22,19,12],
[43,22,19,15],
[43,22,19,16],
[43,22,19,17],
[43,22,19,18],
[43,22,21,0],
[43,22,21,1],
[43,22,21,2],
[43,22,21,4],
[43,22,21,9],
[43,22,21,11],
[43,22,21,12],
[43,22,21,13],
[43,22,21,14],
[43,22,21,15],
[43,22,21,16],
[43,22,21,17],
[43,22,21,18],
[43,22,21,19],
[43,23,3,0],
[43,23,3,1],
[43,23,3,2],
[43,23,4,0],
[43,23,4,1],
[43,23,4,2],
[43,23,4,3],
[43,23,5,0],
[43,23,5,1],
[43,23,5,2],
[43,23,5,4],
[43,23,6,0],
[43,23,6,1],
[43,23,6,2],
[43,23,6,4],
[43,23,8,0],
[43,23,8,1],
[43,23,8,2],
[43,23,8,4],
[43,23,9,0],
[43,23,9,1],
[43,23,9,2],
[43,23,9,3],
[43,23,9,4],
[43,23,9,5],
[43,23,9,6],
[43,23,9,8],
[43,23,10,0],
[43,23,10,1],
[43,23,10,2],
[43,23,10,3],
[43,23,10,4],
[43,23,10,5],
[43,23,10,6],
[43,23,10,8],
[43,23,10,9],
[43,23,11,0],
[43,23,11,1],
[43,23,11,2],
[43,23,11,3],
[43,23,11,4],
[43,23,11,5],
[43,23,11,6],
[43,23,11,8],
[43,23,11,9],
[43,23,11,10],
[43,23,12,0],
[43,23,12,1],
[43,23,12,2],
[43,23,12,3],
[43,23,12,4],
[43,23,12,5],
[43,23,12,6],
[43,23,12,8],
[43,23,12,9],
[43,23,12,10],
[43,23,13,0],
[43,23,13,1],
[43,23,13,2],
[43,23,13,3],
[43,23,13,4],
[43,23,13,5],
[43,23,13,6],
[43,23,13,8],
[43,23,13,9],
[43,23,13,10],
[43,23,13,11],
[43,23,13,12],
[43,23,14,0],
[43,23,14,1],
[43,23,14,2],
[43,23,14,3],
[43,23,14,4],
[43,23,14,5],
[43,23,14,6],
[43,23,14,8],
[43,23,14,9],
[43,23,14,10],
[43,23,14,11],
[43,23,14,12],
[43,23,15,0],
[43,23,15,1],
[43,23,15,2],
[43,23,15,3],
[43,23,15,4],
[43,23,15,5],
[43,23,15,6],
[43,23,15,8],
[43,23,15,10],
[43,23,15,11],
[43,23,15,12],
[43,23,15,13],
[43,23,15,14],
[43,23,16,0],
[43,23,16,1],
[43,23,16,2],
[43,23,16,3],
[43,23,16,4],
[43,23,16,5],
[43,23,16,6],
[43,23,16,8],
[43,23,16,9],
[43,23,16,10],
[43,23,16,11],
[43,23,16,12],
[43,23,16,13],
[43,23,16,14],
[43,23,16,15],
[43,23,17,0],
[43,23,17,1],
[43,23,17,2],
[43,23,17,3],
[43,23,17,4],
[43,23,17,5],
[43,23,17,6],
[43,23,17,8],
[43,23,17,9],
[43,23,17,10],
[43,23,17,11],
[43,23,17,12],
[43,23,17,13],
[43,23,17,14],
[43,23,17,15],
[43,23,17,16],
[43,23,18,0],
[43,23,18,1],
[43,23,18,2],
[43,23,18,3],
[43,23,18,4],
[43,23,18,5],
[43,23,18,6],
[43,23,18,8],
[43,23,18,10],
[43,23,18,11],
[43,23,18,12],
[43,23,18,13],
[43,23,18,14],
[43,23,18,16],
[43,23,18,17],
[43,23,19,0],
[43,23,19,1],
[43,23,19,2],
[43,23,19,3],
[43,23,19,4],
[43,23,19,5],
[43,23,19,6],
[43,23,19,8],
[43,23,19,9],
[43,23,19,10],
[43,23,19,11],
[43,23,19,12],
[43,23,19,15],
[43,23,19,16],
[43,23,19,17],
[43,23,19,18],
[43,23,20,0],
[43,23,20,1],
[43,23,20,2],
[43,23,20,3],
[43,23,20,4],
[43,23,20,5],
[43,23,20,6],
[43,23,20,8],
[43,23,20,9],
[43,23,20,11],
[43,23,20,12],
[43,23,20,13],
[43,23,20,14],
[43,23,20,15],
[43,23,20,16],
[43,23,20,17],
[43,23,20,18],
[43,23,20,19],
[43,23,21,0],
[43,23,21,1],
[43,23,21,2],
[43,23,21,4],
[43,23,21,9],
[43,23,21,10],
[43,23,21,11],
[43,23,21,12],
[43,23,21,13],
[43,23,21,14],
[43,23,21,15],
[43,23,21,16],
[43,23,21,17],
[43,23,21,18],
[43,23,21,19],
[43,23,21,20],
[43,23,22,0],
[43,23,22,1],
[43,23,22,2],
[43,23,22,3],
[43,23,22,4],
[43,23,22,5],
[43,23,22,6],
[43,23,22,8],
[43,23,22,9],
[43,23,22,11],
[43,23,22,12],
[43,23,22,13],
[43,23,22,14],
[43,23,22,15],
[43,23,22,16],
[43,23,22,17],
[43,23,22,18],
[43,23,22,19],
[43,23,22,21],
[43,24,3,0],
[43,24,3,1],
[43,24,3,2],
[43,24,4,0],
[43,24,4,1],
[43,24,4,2],
[43,24,4,3],
[43,24,5,0],
[43,24,5,1],
[43,24,5,2],
[43,24,5,4],
[43,24,6,0],
[43,24,6,1],
[43,24,6,2],
[43,24,6,4],
[43,24,8,0],
[43,24,8,1],
[43,24,8,2],
[43,24,8,4],
[43,24,9,0],
[43,24,9,1],
[43,24,9,2],
[43,24,9,3],
[43,24,9,4],
[43,24,9,5],
[43,24,9,6],
[43,24,9,8],
[43,24,10,0],
[43,24,10,1],
[43,24,10,2],
[43,24,10,3],
[43,24,10,4],
[43,24,10,5],
[43,24,10,6],
[43,24,10,8],
[43,24,10,9],
[43,24,11,0],
[43,24,11,1],
[43,24,11,2],
[43,24,11,3],
[43,24,11,4],
[43,24,11,5],
[43,24,11,6],
[43,24,11,8],
[43,24,11,9],
[43,24,11,10],
[43,24,12,0],
[43,24,12,1],
[43,24,12,2],
[43,24,12,3],
[43,24,12,4],
[43,24,12,5],
[43,24,12,6],
[43,24,12,8],
[43,24,12,9],
[43,24,12,10],
[43,24,13,0],
[43,24,13,1],
[43,24,13,2],
[43,24,13,3],
[43,24,13,4],
[43,24,13,5],
[43,24,13,6],
[43,24,13,8],
[43,24,13,9],
[43,24,13,10],
[43,24,13,11],
[43,24,13,12],
[43,24,14,0],
[43,24,14,1],
[43,24,14,2],
[43,24,14,3],
[43,24,14,4],
[43,24,14,5],
[43,24,14,6],
[43,24,14,8],
[43,24,14,9],
[43,24,14,10],
[43,24,14,11],
[43,24,14,12],
[43,24,15,0],
[43,24,15,1],
[43,24,15,2],
[43,24,15,3],
[43,24,15,4],
[43,24,15,5],
[43,24,15,6],
[43,24,15,8],
[43,24,15,10],
[43,24,15,11],
[43,24,15,12],
[43,24,15,13],
[43,24,15,14],
[43,24,17,0],
[43,24,17,1],
[43,24,17,2],
[43,24,17,3],
[43,24,17,4],
[43,24,17,5],
[43,24,17,6],
[43,24,17,8],
[43,24,17,9],
[43,24,17,10],
[43,24,17,11],
[43,24,17,12],
[43,24,17,13],
[43,24,17,14],
[43,24,17,15],
[43,24,18,0],
[43,24,18,1],
[43,24,18,2],
[43,24,18,3],
[43,24,18,4],
[43,24,18,5],
[43,24,18,6],
[43,24,18,8],
[43,24,18,10],
[43,24,18,11],
[43,24,18,12],
[43,24,18,13],
[43,24,18,14],
[43,24,18,17],
[43,24,19,0],
[43,24,19,1],
[43,24,19,2],
[43,24,19,3],
[43,24,19,4],
[43,24,19,5],
[43,24,19,6],
[43,24,19,8],
[43,24,19,9],
[43,24,19,10],
[43,24,19,11],
[43,24,19,12],
[43,24,19,15],
[43,24,19,17],
[43,24,19,18],
[43,24,20,0],
[43,24,20,1],
[43,24,20,2],
[43,24,20,3],
[43,24,20,4],
[43,24,20,5],
[43,24,20,6],
[43,24,20,8],
[43,24,20,9],
[43,24,20,11],
[43,24,20,12],
[43,24,20,13],
[43,24,20,14],
[43,24,20,15],
[43,24,20,17],
[43,24,20,18],
[43,24,20,19],
[43,24,21,0],
[43,24,21,1],
[43,24,21,2],
[43,24,21,4],
[43,24,21,9],
[43,24,21,10],
[43,24,21,11],
[43,24,21,12],
[43,24,21,13],
[43,24,21,14],
[43,24,21,15],
[43,24,21,17],
[43,24,21,18],
[43,24,21,19],
[43,24,21,20],
[43,24,22,0],
[43,24,22,1],
[43,24,22,2],
[43,24,22,3],
[43,24,22,4],
[43,24,22,5],
[43,24,22,6],
[43,24,22,8],
[43,24,22,9],
[43,24,22,11],
[43,24,22,12],
[43,24,22,13],
[43,24,22,14],
[43,24,22,15],
[43,24,22,17],
[43,24,22,18],
[43,24,22,19],
[43,24,22,21],
[43,24,23,0],
[43,24,23,1],
[43,24,23,2],
[43,24,23,3],
[43,24,23,4],
[43,24,23,5],
[43,24,23,6],
[43,24,23,8],
[43,24,23,9],
[43,24,23,10],
[43,24,23,11],
[43,24,23,12],
[43,24,23,13],
[43,24,23,14],
[43,24,23,15],
[43,24,23,17],
[43,24,23,18],
[43,24,23,19],
[43,24,23,20],
[43,24,23,21],
[43,24,23,22],
[43,26,3,0],
[43,26,3,1],
[43,26,3,2],
[43,26,4,0],
[43,26,4,1],
[43,26,4,2],
[43,26,4,3],
[43,26,5,0],
[43,26,5,1],
[43,26,5,2],
[43,26,5,4],
[43,26,6,0],
[43,26,6,1],
[43,26,6,2],
[43,26,6,4],
[43,26,8,0],
[43,26,8,1],
[43,26,8,2],
[43,26,8,4],
[43,26,10,0],
[43,26,10,1],
[43,26,10,2],
[43,26,10,3],
[43,26,10,4],
[43,26,10,5],
[43,26,10,6],
[43,26,10,8],
[43,26,11,0],
[43,26,11,1],
[43,26,11,2],
[43,26,11,3],
[43,26,11,4],
[43,26,11,5],
[43,26,11,6],
[43,26,11,8],
[43,26,11,10],
[43,26,12,0],
[43,26,12,1],
[43,26,12,2],
[43,26,12,3],
[43,26,12,4],
[43,26,12,5],
[43,26,12,6],
[43,26,12,8],
[43,26,12,10],
[43,26,13,0],
[43,26,13,1],
[43,26,13,2],
[43,26,13,3],
[43,26,13,4],
[43,26,13,5],
[43,26,13,6],
[43,26,13,8],
[43,26,13,10],
[43,26,13,11],
[43,26,13,12],
[43,26,14,0],
[43,26,14,1],
[43,26,14,2],
[43,26,14,3],
[43,26,14,4],
[43,26,14,5],
[43,26,14,6],
[43,26,14,8],
[43,26,14,10],
[43,26,14,11],
[43,26,14,12],
[43,26,16,0],
[43,26,16,1],
[43,26,16,2],
[43,26,16,3],
[43,26,16,4],
[43,26,16,5],
[43,26,16,6],
[43,26,16,8],
[43,26,16,10],
[43,26,16,11],
[43,26,16,12],
[43,26,16,13],
[43,26,16,14],
[43,26,17,0],
[43,26,17,1],
[43,26,17,2],
[43,26,17,3],
[43,26,17,4],
[43,26,17,5],
[43,26,17,6],
[43,26,17,8],
[43,26,17,10],
[43,26,17,11],
[43,26,17,12],
[43,26,17,13],
[43,26,17,14],
[43,26,17,16],
[43,26,19,0],
[43,26,19,1],
[43,26,19,2],
[43,26,19,3],
[43,26,19,4],
[43,26,19,5],
[43,26,19,6],
[43,26,19,8],
[43,26,19,10],
[43,26,19,11],
[43,26,19,12],
[43,26,19,16],
[43,26,19,17],
[43,26,20,0],
[43,26,20,1],
[43,26,20,2],
[43,26,20,3],
[43,26,20,4],
[43,26,20,5],
[43,26,20,6],
[43,26,20,8],
[43,26,20,11],
[43,26,20,12],
[43,26,20,13],
[43,26,20,14],
[43,26,20,16],
[43,26,20,17],
[43,26,20,19],
[43,26,21,0],
[43,26,21,1],
[43,26,21,2],
[43,26,21,4],
[43,26,21,10],
[43,26,21,11],
[43,26,21,12],
[43,26,21,13],
[43,26,21,14],
[43,26,21,16],
[43,26,21,17],
[43,26,21,19],
[43,26,21,20],
[43,26,22,0],
[43,26,22,1],
[43,26,22,2],
[43,26,22,3],
[43,26,22,4],
[43,26,22,5],
[43,26,22,6],
[43,26,22,8],
[43,26,22,11],
[43,26,22,12],
[43,26,22,13],
[43,26,22,14],
[43,26,22,16],
[43,26,22,17],
[43,26,22,19],
[43,26,22,21],
[43,26,23,0],
[43,26,23,1],
[43,26,23,2],
[43,26,23,3],
[43,26,23,4],
[43,26,23,5],
[43,26,23,6],
[43,26,23,8],
[43,26,23,10],
[43,26,23,11],
[43,26,23,12],
[43,26,23,13],
[43,26,23,14],
[43,26,23,16],
[43,26,23,17],
[43,26,23,19],
[43,26,23,20],
[43,26,23,21],
[43,26,23,22],
[43,26,24,0],
[43,26,24,1],
[43,26,24,2],
[43,26,24,3],
[43,26,24,4],
[43,26,24,5],
[43,26,24,6],
[43,26,24,8],
[43,26,24,10],
[43,26,24,11],
[43,26,24,12],
[43,26,24,13],
[43,26,24,14],
[43,26,24,17],
[43,26,24,19],
[43,26,24,20],
[43,26,24,21],
[43,26,24,22],
[43,26,24,23],
[43,27,3,0],
[43,27,3,1],
[43,27,3,2],
[43,27,4,0],
[43,27,4,1],
[43,27,4,2],
[43,27,4,3],
[43,27,5,0],
[43,27,5,1],
[43,27,5,2],
[43,27,5,4],
[43,27,6,0],
[43,27,6,1],
[43,27,6,2],
[43,27,6,4],
[43,27,8,0],
[43,27,8,1],
[43,27,8,2],
[43,27,8,4],
[43,27,9,0],
[43,27,9,1],
[43,27,9,2],
[43,27,9,3],
[43,27,9,4],
[43,27,9,5],
[43,27,9,6],
[43,27,9,8],
[43,27,11,0],
[43,27,11,1],
[43,27,11,2],
[43,27,11,3],
[43,27,11,4],
[43,27,11,5],
[43,27,11,6],
[43,27,11,8],
[43,27,11,9],
[43,27,12,0],
[43,27,12,1],
[43,27,12,2],
[43,27,12,3],
[43,27,12,4],
[43,27,12,5],
[43,27,12,6],
[43,27,12,8],
[43,27,12,9],
[43,27,13,0],
[43,27,13,1],
[43,27,13,2],
[43,27,13,3],
[43,27,13,4],
[43,27,13,5],
[43,27,13,6],
[43,27,13,8],
[43,27,13,9],
[43,27,13,11],
[43,27,13,12],
[43,27,14,0],
[43,27,14,1],
[43,27,14,2],
[43,27,14,3],
[43,27,14,4],
[43,27,14,5],
[43,27,14,6],
[43,27,14,8],
[43,27,14,9],
[43,27,14,11],
[43,27,14,12],
[43,27,15,0],
[43,27,15,1],
[43,27,15,2],
[43,27,15,3],
[43,27,15,4],
[43,27,15,5],
[43,27,15,6],
[43,27,15,8],
[43,27,15,11],
[43,27,15,12],
[43,27,15,13],
[43,27,15,14],
[43,27,16,0],
[43,27,16,1],
[43,27,16,2],
[43,27,16,3],
[43,27,16,4],
[43,27,16,5],
[43,27,16,6],
[43,27,16,8],
[43,27,16,9],
[43,27,16,11],
[43,27,16,12],
[43,27,16,13],
[43,27,16,14],
[43,27,16,15],
[43,27,17,0],
[43,27,17,1],
[43,27,17,2],
[43,27,17,3],
[43,27,17,4],
[43,27,17,5],
[43,27,17,6],
[43,27,17,8],
[43,27,17,9],
[43,27,17,11],
[43,27,17,12],
[43,27,17,13],
[43,27,17,14],
[43,27,17,15],
[43,27,17,16],
[43,27,18,0],
[43,27,18,1],
[43,27,18,2],
[43,27,18,3],
[43,27,18,4],
[43,27,18,5],
[43,27,18,6],
[43,27,18,8],
[43,27,18,11],
[43,27,18,12],
[43,27,18,13],
[43,27,18,14],
[43,27,18,16],
[43,27,18,17],
[43,27,19,0],
[43,27,19,1],
[43,27,19,2],
[43,27,19,3],
[43,27,19,4],
[43,27,19,5],
[43,27,19,6],
[43,27,19,8],
[43,27,19,9],
[43,27,19,11],
[43,27,19,12],
[43,27,19,15],
[43,27,19,16],
[43,27,19,17],
[43,27,19,18],
[43,27,21,0],
[43,27,21,1],
[43,27,21,2],
[43,27,21,4],
[43,27,21,9],
[43,27,21,11],
[43,27,21,12],
[43,27,21,13],
[43,27,21,14],
[43,27,21,15],
[43,27,21,16],
[43,27,21,17],
[43,27,21,18],
[43,27,21,19],
[43,27,23,0],
[43,27,23,1],
[43,27,23,2],
[43,27,23,3],
[43,27,23,4],
[43,27,23,5],
[43,27,23,6],
[43,27,23,8],
[43,27,23,9],
[43,27,23,11],
[43,27,23,12],
[43,27,23,13],
[43,27,23,14],
[43,27,23,15],
[43,27,23,16],
[43,27,23,17],
[43,27,23,18],
[43,27,23,19],
[43,27,23,21],
[43,27,24,0],
[43,27,24,1],
[43,27,24,2],
[43,27,24,3],
[43,27,24,4],
[43,27,24,5],
[43,27,24,6],
[43,27,24,8],
[43,27,24,9],
[43,27,24,11],
[43,27,24,12],
[43,27,24,13],
[43,27,24,14],
[43,27,24,15],
[43,27,24,17],
[43,27,24,18],
[43,27,24,19],
[43,27,24,21],
[43,27,24,23],
[43,27,26,0],
[43,27,26,1],
[43,27,26,2],
[43,27,26,3],
[43,27,26,4],
[43,27,26,5],
[43,27,26,6],
[43,27,26,8],
[43,27,26,11],
[43,27,26,12],
[43,27,26,13],
[43,27,26,14],
[43,27,26,16],
[43,27,26,17],
[43,27,26,19],
[43,27,26,21],
[43,27,26,23],
[43,27,26,24],
[43,29,3,0],
[43,29,3,1],
[43,29,3,2],
[43,29,4,0],
[43,29,4,1],
[43,29,4,2],
[43,29,4,3],
[43,29,5,0],
[43,29,5,1],
[43,29,5,2],
[43,29,5,4],
[43,29,6,0],
[43,29,6,1],
[43,29,6,2],
[43,29,6,4],
[43,29,8,0],
[43,29,8,1],
[43,29,8,2],
[43,29,8,4],
[43,29,9,0],
[43,29,9,1],
[43,29,9,2],
[43,29,9,3],
[43,29,9,4],
[43,29,9,5],
[43,29,9,6],
[43,29,9,8],
[43,29,10,0],
[43,29,10,1],
[43,29,10,2],
[43,29,10,3],
[43,29,10,4],
[43,29,10,5],
[43,29,10,6],
[43,29,10,8],
[43,29,10,9],
[43,29,13,0],
[43,29,13,1],
[43,29,13,2],
[43,29,13,3],
[43,29,13,4],
[43,29,13,5],
[43,29,13,6],
[43,29,13,8],
[43,29,13,9],
[43,29,13,10],
[43,29,14,0],
[43,29,14,1],
[43,29,14,2],
[43,29,14,3],
[43,29,14,4],
[43,29,14,5],
[43,29,14,6],
[43,29,14,8],
[43,29,14,9],
[43,29,14,10],
[43,29,15,0],
[43,29,15,1],
[43,29,15,2],
[43,29,15,3],
[43,29,15,4],
[43,29,15,5],
[43,29,15,6],
[43,29,15,8],
[43,29,15,10],
[43,29,15,13],
[43,29,15,14],
[43,29,16,0],
[43,29,16,1],
[43,29,16,2],
[43,29,16,3],
[43,29,16,4],
[43,29,16,5],
[43,29,16,6],
[43,29,16,8],
[43,29,16,9],
[43,29,16,10],
[43,29,16,13],
[43,29,16,14],
[43,29,16,15],
[43,29,17,0],
[43,29,17,1],
[43,29,17,2],
[43,29,17,3],
[43,29,17,4],
[43,29,17,5],
[43,29,17,6],
[43,29,17,8],
[43,29,17,9],
[43,29,17,10],
[43,29,17,13],
[43,29,17,14],
[43,29,17,15],
[43,29,17,16],
[43,29,18,0],
[43,29,18,1],
[43,29,18,2],
[43,29,18,3],
[43,29,18,4],
[43,29,18,5],
[43,29,18,6],
[43,29,18,8],
[43,29,18,10],
[43,29,18,13],
[43,29,18,14],
[43,29,18,16],
[43,29,18,17],
[43,29,19,0],
[43,29,19,1],
[43,29,19,2],
[43,29,19,3],
[43,29,19,4],
[43,29,19,5],
[43,29,19,6],
[43,29,19,8],
[43,29,19,9],
[43,29,19,10],
[43,29,19,15],
[43,29,19,16],
[43,29,19,17],
[43,29,19,18],
[43,29,20,0],
[43,29,20,1],
[43,29,20,2],
[43,29,20,3],
[43,29,20,4],
[43,29,20,5],
[43,29,20,6],
[43,29,20,8],
[43,29,20,9],
[43,29,20,13],
[43,29,20,14],
[43,29,20,15],
[43,29,20,16],
[43,29,20,17],
[43,29,20,18],
[43,29,20,19],
[43,29,21,0],
[43,29,21,1],
[43,29,21,2],
[43,29,21,4],
[43,29,21,9],
[43,29,21,10],
[43,29,21,13],
[43,29,21,14],
[43,29,21,15],
[43,29,21,16],
[43,29,21,17],
[43,29,21,18],
[43,29,21,19],
[43,29,21,20],
[43,29,22,0],
[43,29,22,1],
[43,29,22,2],
[43,29,22,3],
[43,29,22,4],
[43,29,22,5],
[43,29,22,6],
[43,29,22,8],
[43,29,22,9],
[43,29,22,13],
[43,29,22,14],
[43,29,22,15],
[43,29,22,16],
[43,29,22,17],
[43,29,22,18],
[43,29,22,19],
[43,29,22,21],
[43,29,23,0],
[43,29,23,1],
[43,29,23,2],
[43,29,23,3],
[43,29,23,4],
[43,29,23,5],
[43,29,23,6],
[43,29,23,8],
[43,29,23,9],
[43,29,23,10],
[43,29,23,13],
[43,29,23,14],
[43,29,23,15],
[43,29,23,16],
[43,29,23,17],
[43,29,23,18],
[43,29,23,19],
[43,29,23,20],
[43,29,23,21],
[43,29,23,22],
[43,29,24,0],
[43,29,24,1],
[43,29,24,2],
[43,29,24,3],
[43,29,24,4],
[43,29,24,5],
[43,29,24,6],
[43,29,24,8],
[43,29,24,9],
[43,29,24,10],
[43,29,24,13],
[43,29,24,14],
[43,29,24,15],
[43,29,24,17],
[43,29,24,18],
[43,29,24,19],
[43,29,24,20],
[43,29,24,21],
[43,29,24,22],
[43,29,24,23],
[43,29,26,0],
[43,29,26,1],
[43,29,26,2],
[43,29,26,3],
[43,29,26,4],
[43,29,26,5],
[43,29,26,6],
[43,29,26,8],
[43,29,26,10],
[43,29,26,13],
[43,29,26,14],
[43,29,26,16],
[43,29,26,17],
[43,29,26,19],
[43,29,26,20],
[43,29,26,21],
[43,29,26,22],
[43,29,26,23],
[43,29,26,24],
[43,29,27,0],
[43,29,27,1],
[43,29,27,2],
[43,29,27,3],
[43,29,27,4],
[43,29,27,5],
[43,29,27,6],
[43,29,27,8],
[43,29,27,9],
[43,29,27,13],
[43,29,27,14],
[43,29,27,15],
[43,29,27,16],
[43,29,27,17],
[43,29,27,18],
[43,29,27,19],
[43,29,27,21],
[43,29,27,23],
[43,29,27,24],
[43,29,27,26],
[43,30,3,0],
[43,30,3,1],
[43,30,3,2],
[43,30,4,0],
[43,30,4,1],
[43,30,4,2],
[43,30,4,3],
[43,30,5,0],
[43,30,5,1],
[43,30,5,2],
[43,30,5,4],
[43,30,6,0],
[43,30,6,1],
[43,30,6,2],
[43,30,6,4],
[43,30,8,0],
[43,30,8,1],
[43,30,8,2],
[43,30,8,4],
[43,30,9,0],
[43,30,9,1],
[43,30,9,2],
[43,30,9,3],
[43,30,9,4],
[43,30,9,5],
[43,30,9,6],
[43,30,9,8],
[43,30,10,0],
[43,30,10,1],
[43,30,10,2],
[43,30,10,3],
[43,30,10,4],
[43,30,10,5],
[43,30,10,6],
[43,30,10,8],
[43,30,10,9],
[43,30,11,0],
[43,30,11,1],
[43,30,11,2],
[43,30,11,3],
[43,30,11,4],
[43,30,11,5],
[43,30,11,6],
[43,30,11,8],
[43,30,11,9],
[43,30,11,10],
[43,30,12,0],
[43,30,12,1],
[43,30,12,2],
[43,30,12,3],
[43,30,12,4],
[43,30,12,5],
[43,30,12,6],
[43,30,12,8],
[43,30,12,9],
[43,30,12,10],
[43,30,13,0],
[43,30,13,1],
[43,30,13,2],
[43,30,13,3],
[43,30,13,4],
[43,30,13,5],
[43,30,13,6],
[43,30,13,8],
[43,30,13,9],
[43,30,13,10],
[43,30,13,11],
[43,30,13,12],
[43,30,14,0],
[43,30,14,1],
[43,30,14,2],
[43,30,14,3],
[43,30,14,4],
[43,30,14,5],
[43,30,14,6],
[43,30,14,8],
[43,30,14,9],
[43,30,14,10],
[43,30,14,11],
[43,30,14,12],
[43,30,15,0],
[43,30,15,1],
[43,30,15,2],
[43,30,15,3],
[43,30,15,4],
[43,30,15,5],
[43,30,15,6],
[43,30,15,8],
[43,30,15,10],
[43,30,15,11],
[43,30,15,12],
[43,30,15,13],
[43,30,15,14],
[43,30,16,0],
[43,30,16,1],
[43,30,16,2],
[43,30,16,3],
[43,30,16,4],
[43,30,16,5],
[43,30,16,6],
[43,30,16,8],
[43,30,16,9],
[43,30,16,10],
[43,30,16,11],
[43,30,16,12],
[43,30,16,13],
[43,30,16,14],
[43,30,16,15],
[43,30,17,0],
[43,30,17,1],
[43,30,17,2],
[43,30,17,3],
[43,30,17,4],
[43,30,17,5],
[43,30,17,6],
[43,30,17,8],
[43,30,17,9],
[43,30,17,10],
[43,30,17,11],
[43,30,17,12],
[43,30,17,13],
[43,30,17,14],
[43,30,17,15],
[43,30,17,16],
[43,30,18,0],
[43,30,18,1],
[43,30,18,2],
[43,30,18,3],
[43,30,18,4],
[43,30,18,5],
[43,30,18,6],
[43,30,18,8],
[43,30,18,10],
[43,30,18,11],
[43,30,18,12],
[43,30,18,13],
[43,30,18,14],
[43,30,18,16],
[43,30,18,17],
[43,30,19,0],
[43,30,19,1],
[43,30,19,2],
[43,30,19,3],
[43,30,19,4],
[43,30,19,5],
[43,30,19,6],
[43,30,19,8],
[43,30,19,9],
[43,30,19,10],
[43,30,19,11],
[43,30,19,12],
[43,30,19,15],
[43,30,19,16],
[43,30,19,17],
[43,30,19,18],
[43,30,20,0],
[43,30,20,1],
[43,30,20,2],
[43,30,20,3],
[43,30,20,4],
[43,30,20,5],
[43,30,20,6],
[43,30,20,8],
[43,30,20,9],
[43,30,20,11],
[43,30,20,12],
[43,30,20,13],
[43,30,20,14],
[43,30,20,15],
[43,30,20,16],
[43,30,20,17],
[43,30,20,18],
[43,30,20,19],
[43,30,21,0],
[43,30,21,1],
[43,30,21,2],
[43,30,21,4],
[43,30,21,9],
[43,30,21,10],
[43,30,21,11],
[43,30,21,12],
[43,30,21,13],
[43,30,21,14],
[43,30,21,15],
[43,30,21,16],
[43,30,21,17],
[43,30,21,18],
[43,30,21,19],
[43,30,21,20],
[43,30,22,0],
[43,30,22,1],
[43,30,22,2],
[43,30,22,3],
[43,30,22,4],
[43,30,22,5],
[43,30,22,6],
[43,30,22,8],
[43,30,22,9],
[43,30,22,11],
[43,30,22,12],
[43,30,22,13],
[43,30,22,14],
[43,30,22,15],
[43,30,22,16],
[43,30,22,17],
[43,30,22,18],
[43,30,22,19],
[43,30,22,21],
[43,30,24,0],
[43,30,24,1],
[43,30,24,2],
[43,30,24,3],
[43,30,24,4],
[43,30,24,5],
[43,30,24,6],
[43,30,24,8],
[43,30,24,9],
[43,30,24,10],
[43,30,24,11],
[43,30,24,12],
[43,30,24,13],
[43,30,24,14],
[43,30,24,15],
[43,30,24,17],
[43,30,24,18],
[43,30,24,19],
[43,30,24,20],
[43,30,24,21],
[43,30,24,22],
[43,30,26,0],
[43,30,26,1],
[43,30,26,2],
[43,30,26,3],
[43,30,26,4],
[43,30,26,5],
[43,30,26,6],
[43,30,26,8],
[43,30,26,10],
[43,30,26,11],
[43,30,26,12],
[43,30,26,13],
[43,30,26,14],
[43,30,26,16],
[43,30,26,17],
[43,30,26,19],
[43,30,26,20],
[43,30,26,21],
[43,30,26,22],
[43,30,26,24],
[43,30,27,0],
[43,30,27,1],
[43,30,27,2],
[43,30,27,3],
[43,30,27,4],
[43,30,27,5],
[43,30,27,6],
[43,30,27,8],
[43,30,27,9],
[43,30,27,11],
[43,30,27,12],
[43,30,27,13],
[43,30,27,14],
[43,30,27,15],
[43,30,27,16],
[43,30,27,17],
[43,30,27,18],
[43,30,27,19],
[43,30,27,21],
[43,30,27,24],
[43,30,27,26],
[43,30,29,0],
[43,30,29,1],
[43,30,29,2],
[43,30,29,3],
[43,30,29,4],
[43,30,29,5],
[43,30,29,6],
[43,30,29,8],
[43,30,29,9],
[43,30,29,10],
[43,30,29,13],
[43,30,29,14],
[43,30,29,15],
[43,30,29,16],
[43,30,29,17],
[43,30,29,18],
[43,30,29,19],
[43,30,29,20],
[43,30,29,21],
[43,30,29,22],
[43,30,29,24],
[43,30,29,27],
[43,31,3,0],
[43,31,3,1],
[43,31,3,2],
[43,31,4,0],
[43,31,4,1],
[43,31,4,2],
[43,31,4,3],
[43,31,5,0],
[43,31,5,1],
[43,31,5,2],
[43,31,5,4],
[43,31,6,0],
[43,31,6,1],
[43,31,6,2],
[43,31,6,4],
[43,31,8,0],
[43,31,8,1],
[43,31,8,2],
[43,31,8,4],
[43,31,9,0],
[43,31,9,1],
[43,31,9,2],
[43,31,9,3],
[43,31,9,4],
[43,31,9,5],
[43,31,9,6],
[43,31,9,8],
[43,31,10,0],
[43,31,10,1],
[43,31,10,2],
[43,31,10,3],
[43,31,10,4],
[43,31,10,5],
[43,31,10,6],
[43,31,10,8],
[43,31,10,9],
[43,31,11,0],
[43,31,11,1],
[43,31,11,2],
[43,31,11,3],
[43,31,11,4],
[43,31,11,5],
[43,31,11,6],
[43,31,11,8],
[43,31,11,9],
[43,31,11,10],
[43,31,12,0],
[43,31,12,1],
[43,31,12,2],
[43,31,12,3],
[43,31,12,4],
[43,31,12,5],
[43,31,12,6],
[43,31,12,8],
[43,31,12,9],
[43,31,12,10],
[43,31,13,0],
[43,31,13,1],
[43,31,13,2],
[43,31,13,3],
[43,31,13,4],
[43,31,13,5],
[43,31,13,6],
[43,31,13,8],
[43,31,13,9],
[43,31,13,10],
[43,31,13,11],
[43,31,13,12],
[43,31,14,0],
[43,31,14,1],
[43,31,14,2],
[43,31,14,3],
[43,31,14,4],
[43,31,14,5],
[43,31,14,6],
[43,31,14,8],
[43,31,14,9],
[43,31,14,10],
[43,31,14,11],
[43,31,14,12],
[43,31,15,0],
[43,31,15,1],
[43,31,15,2],
[43,31,15,3],
[43,31,15,4],
[43,31,15,5],
[43,31,15,6],
[43,31,15,8],
[43,31,15,10],
[43,31,15,11],
[43,31,15,12],
[43,31,15,13],
[43,31,15,14],
[43,31,16,0],
[43,31,16,1],
[43,31,16,2],
[43,31,16,3],
[43,31,16,4],
[43,31,16,5],
[43,31,16,6],
[43,31,16,8],
[43,31,16,9],
[43,31,16,10],
[43,31,16,11],
[43,31,16,12],
[43,31,16,13],
[43,31,16,14],
[43,31,16,15],
[43,31,17,0],
[43,31,17,1],
[43,31,17,2],
[43,31,17,3],
[43,31,17,4],
[43,31,17,5],
[43,31,17,6],
[43,31,17,8],
[43,31,17,9],
[43,31,17,10],
[43,31,17,11],
[43,31,17,12],
[43,31,17,13],
[43,31,17,14],
[43,31,17,15],
[43,31,17,16],
[43,31,18,0],
[43,31,18,1],
[43,31,18,2],
[43,31,18,3],
[43,31,18,4],
[43,31,18,5],
[43,31,18,6],
[43,31,18,8],
[43,31,18,10],
[43,31,18,11],
[43,31,18,12],
[43,31,18,13],
[43,31,18,14],
[43,31,18,16],
[43,31,18,17],
[43,31,19,0],
[43,31,19,1],
[43,31,19,2],
[43,31,19,3],
[43,31,19,4],
[43,31,19,5],
[43,31,19,6],
[43,31,19,8],
[43,31,19,9],
[43,31,19,10],
[43,31,19,11],
[43,31,19,12],
[43,31,19,15],
[43,31,19,16],
[43,31,19,17],
[43,31,19,18],
[43,31,20,0],
[43,31,20,1],
[43,31,20,2],
[43,31,20,3],
[43,31,20,4],
[43,31,20,5],
[43,31,20,6],
[43,31,20,8],
[43,31,20,9],
[43,31,20,11],
[43,31,20,12],
[43,31,20,13],
[43,31,20,14],
[43,31,20,15],
[43,31,20,16],
[43,31,20,17],
[43,31,20,18],
[43,31,20,19],
[43,31,21,0],
[43,31,21,1],
[43,31,21,2],
[43,31,21,4],
[43,31,21,9],
[43,31,21,10],
[43,31,21,11],
[43,31,21,12],
[43,31,21,13],
[43,31,21,14],
[43,31,21,15],
[43,31,21,16],
[43,31,21,17],
[43,31,21,18],
[43,31,21,19],
[43,31,21,20],
[43,31,22,0],
[43,31,22,1],
[43,31,22,2],
[43,31,22,3],
[43,31,22,4],
[43,31,22,5],
[43,31,22,6],
[43,31,22,8],
[43,31,22,9],
[43,31,22,11],
[43,31,22,12],
[43,31,22,13],
[43,31,22,14],
[43,31,22,15],
[43,31,22,16],
[43,31,22,17],
[43,31,22,18],
[43,31,22,19],
[43,31,22,21],
[43,31,24,0],
[43,31,24,1],
[43,31,24,2],
[43,31,24,3],
[43,31,24,4],
[43,31,24,5],
[43,31,24,6],
[43,31,24,8],
[43,31,24,9],
[43,31,24,10],
[43,31,24,11],
[43,31,24,12],
[43,31,24,13],
[43,31,24,14],
[43,31,24,15],
[43,31,24,17],
[43,31,24,18],
[43,31,24,19],
[43,31,24,20],
[43,31,24,21],
[43,31,24,22],
[43,31,26,0],
[43,31,26,1],
[43,31,26,2],
[43,31,26,3],
[43,31,26,4],
[43,31,26,5],
[43,31,26,6],
[43,31,26,8],
[43,31,26,10],
[43,31,26,11],
[43,31,26,12],
[43,31,26,13],
[43,31,26,14],
[43,31,26,16],
[43,31,26,17],
[43,31,26,19],
[43,31,26,20],
[43,31,26,21],
[43,31,26,22],
[43,31,26,24],
[43,31,27,0],
[43,31,27,1],
[43,31,27,2],
[43,31,27,3],
[43,31,27,4],
[43,31,27,5],
[43,31,27,6],
[43,31,27,8],
[43,31,27,9],
[43,31,27,11],
[43,31,27,12],
[43,31,27,13],
[43,31,27,14],
[43,31,27,15],
[43,31,27,16],
[43,31,27,17],
[43,31,27,18],
[43,31,27,19],
[43,31,27,21],
[43,31,27,24],
[43,31,27,26],
[43,31,29,0],
[43,31,29,1],
[43,31,29,2],
[43,31,29,3],
[43,31,29,4],
[43,31,29,5],
[43,31,29,6],
[43,31,29,8],
[43,31,29,9],
[43,31,29,10],
[43,31,29,13],
[43,31,29,14],
[43,31,29,15],
[43,31,29,16],
[43,31,29,17],
[43,31,29,18],
[43,31,29,19],
[43,31,29,20],
[43,31,29,21],
[43,31,29,22],
[43,31,29,24],
[43,31,29,26],
[43,31,29,27],
[43,32,3,0],
[43,32,3,1],
[43,32,3,2],
[43,32,4,0],
[43,32,4,1],
[43,32,4,2],
[43,32,4,3],
[43,32,5,0],
[43,32,5,1],
[43,32,5,2],
[43,32,5,4],
[43,32,6,0],
[43,32,6,1],
[43,32,6,2],
[43,32,6,4],
[43,32,8,0],
[43,32,8,1],
[43,32,8,2],
[43,32,8,4],
[43,32,9,0],
[43,32,9,1],
[43,32,9,2],
[43,32,9,3],
[43,32,9,4],
[43,32,9,5],
[43,32,9,6],
[43,32,9,8],
[43,32,10,0],
[43,32,10,1],
[43,32,10,2],
[43,32,10,3],
[43,32,10,4],
[43,32,10,5],
[43,32,10,6],
[43,32,10,8],
[43,32,10,9],
[43,32,13,0],
[43,32,13,1],
[43,32,13,2],
[43,32,13,3],
[43,32,13,4],
[43,32,13,5],
[43,32,13,6],
[43,32,13,8],
[43,32,13,9],
[43,32,13,10],
[43,32,14,0],
[43,32,14,1],
[43,32,14,2],
[43,32,14,3],
[43,32,14,4],
[43,32,14,5],
[43,32,14,6],
[43,32,14,8],
[43,32,14,9],
[43,32,14,10],
[43,32,15,0],
[43,32,15,1],
[43,32,15,2],
[43,32,15,3],
[43,32,15,4],
[43,32,15,5],
[43,32,15,6],
[43,32,15,8],
[43,32,15,10],
[43,32,15,13],
[43,32,15,14],
[43,32,16,0],
[43,32,16,1],
[43,32,16,2],
[43,32,16,3],
[43,32,16,4],
[43,32,16,5],
[43,32,16,6],
[43,32,16,8],
[43,32,16,9],
[43,32,16,10],
[43,32,16,13],
[43,32,16,14],
[43,32,16,15],
[43,32,17,0],
[43,32,17,1],
[43,32,17,2],
[43,32,17,3],
[43,32,17,4],
[43,32,17,5],
[43,32,17,6],
[43,32,17,8],
[43,32,17,9],
[43,32,17,10],
[43,32,17,13],
[43,32,17,14],
[43,32,17,15],
[43,32,17,16],
[43,32,18,0],
[43,32,18,1],
[43,32,18,2],
[43,32,18,3],
[43,32,18,4],
[43,32,18,5],
[43,32,18,6],
[43,32,18,8],
[43,32,18,10],
[43,32,18,13],
[43,32,18,14],
[43,32,18,16],
[43,32,18,17],
[43,32,19,0],
[43,32,19,1],
[43,32,19,2],
[43,32,19,3],
[43,32,19,4],
[43,32,19,5],
[43,32,19,6],
[43,32,19,8],
[43,32,19,9],
[43,32,19,10],
[43,32,19,15],
[43,32,19,16],
[43,32,19,17],
[43,32,19,18],
[43,32,20,0],
[43,32,20,1],
[43,32,20,2],
[43,32,20,3],
[43,32,20,4],
[43,32,20,5],
[43,32,20,6],
[43,32,20,8],
[43,32,20,9],
[43,32,20,13],
[43,32,20,14],
[43,32,20,15],
[43,32,20,16],
[43,32,20,17],
[43,32,20,18],
[43,32,20,19],
[43,32,21,0],
[43,32,21,1],
[43,32,21,2],
[43,32,21,4],
[43,32,21,9],
[43,32,21,10],
[43,32,21,13],
[43,32,21,14],
[43,32,21,15],
[43,32,21,16],
[43,32,21,17],
[43,32,21,18],
[43,32,21,19],
[43,32,21,20],
[43,32,22,0],
[43,32,22,1],
[43,32,22,2],
[43,32,22,3],
[43,32,22,4],
[43,32,22,5],
[43,32,22,6],
[43,32,22,8],
[43,32,22,9],
[43,32,22,13],
[43,32,22,14],
[43,32,22,15],
[43,32,22,16],
[43,32,22,17],
[43,32,22,18],
[43,32,22,19],
[43,32,22,21],
[43,32,23,0],
[43,32,23,1],
[43,32,23,2],
[43,32,23,3],
[43,32,23,4],
[43,32,23,5],
[43,32,23,6],
[43,32,23,8],
[43,32,23,9],
[43,32,23,10],
[43,32,23,13],
[43,32,23,14],
[43,32,23,15],
[43,32,23,16],
[43,32,23,17],
[43,32,23,18],
[43,32,23,19],
[43,32,23,20],
[43,32,23,21],
[43,32,23,22],
[43,32,24,0],
[43,32,24,1],
[43,32,24,2],
[43,32,24,3],
[43,32,24,4],
[43,32,24,5],
[43,32,24,6],
[43,32,24,8],
[43,32,24,9],
[43,32,24,10],
[43,32,24,13],
[43,32,24,14],
[43,32,24,15],
[43,32,24,17],
[43,32,24,18],
[43,32,24,19],
[43,32,24,20],
[43,32,24,21],
[43,32,24,22],
[43,32,24,23],
[43,32,26,0],
[43,32,26,1],
[43,32,26,2],
[43,32,26,3],
[43,32,26,4],
[43,32,26,5],
[43,32,26,6],
[43,32,26,8],
[43,32,26,10],
[43,32,26,13],
[43,32,26,14],
[43,32,26,16],
[43,32,26,17],
[43,32,26,19],
[43,32,26,20],
[43,32,26,21],
[43,32,26,22],
[43,32,26,23],
[43,32,26,24],
[43,32,27,0],
[43,32,27,1],
[43,32,27,2],
[43,32,27,3],
[43,32,27,4],
[43,32,27,5],
[43,32,27,6],
[43,32,27,8],
[43,32,27,9],
[43,32,27,13],
[43,32,27,14],
[43,32,27,15],
[43,32,27,16],
[43,32,27,17],
[43,32,27,18],
[43,32,27,19],
[43,32,27,21],
[43,32,27,23],
[43,32,27,24],
[43,32,30,0],
[43,32,30,1],
[43,32,30,2],
[43,32,30,3],
[43,32,30,4],
[43,32,30,5],
[43,32,30,6],
[43,32,30,8],
[43,32,30,9],
[43,32,30,10],
[43,32,30,13],
[43,32,30,14],
[43,32,30,15],
[43,32,30,16],
[43,32,30,17],
[43,32,30,18],
[43,32,30,19],
[43,32,30,20],
[43,32,30,21],
[43,32,30,22],
[43,32,30,26],
[43,32,30,27],
[43,32,31,0],
[43,32,31,1],
[43,32,31,2],
[43,32,31,3],
[43,32,31,4],
[43,32,31,5],
[43,32,31,6],
[43,32,31,8],
[43,32,31,9],
[43,32,31,10],
[43,32,31,13],
[43,32,31,14],
[43,32,31,15],
[43,32,31,16],
[43,32,31,17],
[43,32,31,18],
[43,32,31,19],
[43,32,31,20],
[43,32,31,21],
[43,32,31,24],
[43,32,31,26],
[43,32,31,27],
[43,33,3,0],
[43,33,3,1],
[43,33,3,2],
[43,33,4,0],
[43,33,4,1],
[43,33,4,2],
[43,33,4,3],
[43,33,5,0],
[43,33,5,1],
[43,33,5,2],
[43,33,5,4],
[43,33,6,0],
[43,33,6,1],
[43,33,6,2],
[43,33,6,4],
[43,33,8,0],
[43,33,8,1],
[43,33,8,2],
[43,33,8,4],
[43,33,9,0],
[43,33,9,1],
[43,33,9,2],
[43,33,9,3],
[43,33,9,4],
[43,33,9,5],
[43,33,9,6],
[43,33,9,8],
[43,33,10,0],
[43,33,10,1],
[43,33,10,2],
[43,33,10,3],
[43,33,10,4],
[43,33,10,5],
[43,33,10,6],
[43,33,10,8],
[43,33,10,9],
[43,33,13,0],
[43,33,13,1],
[43,33,13,2],
[43,33,13,3],
[43,33,13,4],
[43,33,13,5],
[43,33,13,6],
[43,33,13,8],
[43,33,13,9],
[43,33,13,10],
[43,33,14,0],
[43,33,14,1],
[43,33,14,2],
[43,33,14,3],
[43,33,14,4],
[43,33,14,5],
[43,33,14,6],
[43,33,14,8],
[43,33,14,9],
[43,33,14,10],
[43,33,15,0],
[43,33,15,1],
[43,33,15,2],
[43,33,15,3],
[43,33,15,4],
[43,33,15,5],
[43,33,15,6],
[43,33,15,8],
[43,33,15,10],
[43,33,15,13],
[43,33,15,14],
[43,33,16,0],
[43,33,16,1],
[43,33,16,2],
[43,33,16,3],
[43,33,16,4],
[43,33,16,5],
[43,33,16,6],
[43,33,16,8],
[43,33,16,9],
[43,33,16,10],
[43,33,16,13],
[43,33,16,14],
[43,33,16,15],
[43,33,17,0],
[43,33,17,1],
[43,33,17,2],
[43,33,17,3],
[43,33,17,4],
[43,33,17,5],
[43,33,17,6],
[43,33,17,8],
[43,33,17,9],
[43,33,17,10],
[43,33,17,13],
[43,33,17,14],
[43,33,17,15],
[43,33,17,16],
[43,33,18,0],
[43,33,18,1],
[43,33,18,2],
[43,33,18,3],
[43,33,18,4],
[43,33,18,5],
[43,33,18,6],
[43,33,18,8],
[43,33,18,10],
[43,33,18,13],
[43,33,18,14],
[43,33,18,16],
[43,33,18,17],
[43,33,19,0],
[43,33,19,1],
[43,33,19,2],
[43,33,19,3],
[43,33,19,4],
[43,33,19,5],
[43,33,19,6],
[43,33,19,8],
[43,33,19,9],
[43,33,19,10],
[43,33,19,15],
[43,33,19,16],
[43,33,19,17],
[43,33,19,18],
[43,33,20,0],
[43,33,20,1],
[43,33,20,2],
[43,33,20,3],
[43,33,20,4],
[43,33,20,5],
[43,33,20,6],
[43,33,20,8],
[43,33,20,9],
[43,33,20,13],
[43,33,20,14],
[43,33,20,15],
[43,33,20,16],
[43,33,20,17],
[43,33,20,18],
[43,33,20,19],
[43,33,21,0],
[43,33,21,1],
[43,33,21,2],
[43,33,21,4],
[43,33,21,9],
[43,33,21,10],
[43,33,21,13],
[43,33,21,14],
[43,33,21,15],
[43,33,21,16],
[43,33,21,17],
[43,33,21,18],
[43,33,21,19],
[43,33,21,20],
[43,33,22,0],
[43,33,22,1],
[43,33,22,2],
[43,33,22,3],
[43,33,22,4],
[43,33,22,5],
[43,33,22,6],
[43,33,22,8],
[43,33,22,9],
[43,33,22,13],
[43,33,22,14],
[43,33,22,15],
[43,33,22,16],
[43,33,22,17],
[43,33,22,18],
[43,33,22,19],
[43,33,22,21],
[43,33,23,0],
[43,33,23,1],
[43,33,23,2],
[43,33,23,3],
[43,33,23,4],
[43,33,23,5],
[43,33,23,6],
[43,33,23,8],
[43,33,23,9],
[43,33,23,10],
[43,33,23,13],
[43,33,23,14],
[43,33,23,15],
[43,33,23,16],
[43,33,23,17],
[43,33,23,18],
[43,33,23,19],
[43,33,23,20],
[43,33,23,21],
[43,33,23,22],
[43,33,24,0],
[43,33,24,1],
[43,33,24,2],
[43,33,24,3],
[43,33,24,4],
[43,33,24,5],
[43,33,24,6],
[43,33,24,8],
[43,33,24,9],
[43,33,24,10],
[43,33,24,13],
[43,33,24,14],
[43,33,24,15],
[43,33,24,17],
[43,33,24,18],
[43,33,24,19],
[43,33,24,20],
[43,33,24,21],
[43,33,24,22],
[43,33,24,23],
[43,33,26,0],
[43,33,26,1],
[43,33,26,2],
[43,33,26,3],
[43,33,26,4],
[43,33,26,5],
[43,33,26,6],
[43,33,26,8],
[43,33,26,10],
[43,33,26,13],
[43,33,26,14],
[43,33,26,16],
[43,33,26,17],
[43,33,26,19],
[43,33,26,20],
[43,33,26,21],
[43,33,26,22],
[43,33,26,23],
[43,33,26,24],
[43,33,27,0],
[43,33,27,1],
[43,33,27,2],
[43,33,27,3],
[43,33,27,4],
[43,33,27,5],
[43,33,27,6],
[43,33,27,8],
[43,33,27,9],
[43,33,27,13],
[43,33,27,14],
[43,33,27,15],
[43,33,27,16],
[43,33,27,17],
[43,33,27,18],
[43,33,27,19],
[43,33,27,21],
[43,33,27,23],
[43,33,27,24],
[43,33,27,26],
[43,33,30,0],
[43,33,30,1],
[43,33,30,2],
[43,33,30,3],
[43,33,30,4],
[43,33,30,5],
[43,33,30,6],
[43,33,30,8],
[43,33,30,9],
[43,33,30,10],
[43,33,30,13],
[43,33,30,14],
[43,33,30,15],
[43,33,30,16],
[43,33,30,17],
[43,33,30,18],
[43,33,30,19],
[43,33,30,20],
[43,33,30,21],
[43,33,30,24],
[43,33,30,26],
[43,33,30,27],
[43,33,31,0],
[43,33,31,1],
[43,33,31,2],
[43,33,31,3],
[43,33,31,4],
[43,33,31,5],
[43,33,31,6],
[43,33,31,8],
[43,33,31,9],
[43,33,31,10],
[43,33,31,13],
[43,33,31,14],
[43,33,31,15],
[43,33,31,16],
[43,33,31,17],
[43,33,31,18],
[43,33,31,19],
[43,33,31,20],
[43,33,31,22],
[43,33,31,24],
[43,33,31,26],
[43,33,31,27],
[43,35,3,0],
[43,35,3,1],
[43,35,3,2],
[43,35,4,0],
[43,35,4,1],
[43,35,4,2],
[43,35,4,3],
[43,35,5,0],
[43,35,5,1],
[43,35,5,2],
[43,35,5,4],
[43,35,6,0],
[43,35,6,1],
[43,35,6,2],
[43,35,6,4],
[43,35,8,0],
[43,35,8,1],
[43,35,8,2],
[43,35,8,4],
[43,35,10,0],
[43,35,10,1],
[43,35,10,2],
[43,35,10,3],
[43,35,10,4],
[43,35,10,5],
[43,35,10,6],
[43,35,10,8],
[43,35,11,0],
[43,35,11,1],
[43,35,11,2],
[43,35,11,3],
[43,35,11,4],
[43,35,11,5],
[43,35,11,6],
[43,35,11,8],
[43,35,11,10],
[43,35,12,0],
[43,35,12,1],
[43,35,12,2],
[43,35,12,3],
[43,35,12,4],
[43,35,12,5],
[43,35,12,6],
[43,35,12,8],
[43,35,12,10],
[43,35,13,0],
[43,35,13,1],
[43,35,13,2],
[43,35,13,3],
[43,35,13,4],
[43,35,13,5],
[43,35,13,6],
[43,35,13,8],
[43,35,13,10],
[43,35,13,11],
[43,35,13,12],
[43,35,14,0],
[43,35,14,1],
[43,35,14,2],
[43,35,14,3],
[43,35,14,4],
[43,35,14,5],
[43,35,14,6],
[43,35,14,8],
[43,35,14,10],
[43,35,14,11],
[43,35,14,12],
[43,35,16,0],
[43,35,16,1],
[43,35,16,2],
[43,35,16,3],
[43,35,16,4],
[43,35,16,5],
[43,35,16,6],
[43,35,16,8],
[43,35,16,10],
[43,35,16,11],
[43,35,16,12],
[43,35,16,13],
[43,35,16,14],
[43,35,17,0],
[43,35,17,1],
[43,35,17,2],
[43,35,17,3],
[43,35,17,4],
[43,35,17,5],
[43,35,17,6],
[43,35,17,8],
[43,35,17,10],
[43,35,17,11],
[43,35,17,12],
[43,35,17,13],
[43,35,17,14],
[43,35,17,16],
[43,35,19,0],
[43,35,19,1],
[43,35,19,2],
[43,35,19,3],
[43,35,19,4],
[43,35,19,5],
[43,35,19,6],
[43,35,19,8],
[43,35,19,10],
[43,35,19,11],
[43,35,19,12],
[43,35,19,16],
[43,35,19,17],
[43,35,20,0],
[43,35,20,1],
[43,35,20,2],
[43,35,20,3],
[43,35,20,4],
[43,35,20,5],
[43,35,20,6],
[43,35,20,8],
[43,35,20,11],
[43,35,20,12],
[43,35,20,13],
[43,35,20,14],
[43,35,20,16],
[43,35,20,17],
[43,35,20,19],
[43,35,21,0],
[43,35,21,1],
[43,35,21,2],
[43,35,21,4],
[43,35,21,10],
[43,35,21,11],
[43,35,21,12],
[43,35,21,13],
[43,35,21,14],
[43,35,21,16],
[43,35,21,17],
[43,35,21,19],
[43,35,21,20],
[43,35,22,0],
[43,35,22,1],
[43,35,22,2],
[43,35,22,3],
[43,35,22,4],
[43,35,22,5],
[43,35,22,6],
[43,35,22,8],
[43,35,22,11],
[43,35,22,12],
[43,35,22,13],
[43,35,22,14],
[43,35,22,16],
[43,35,22,17],
[43,35,22,19],
[43,35,22,21],
[43,35,23,0],
[43,35,23,1],
[43,35,23,2],
[43,35,23,3],
[43,35,23,4],
[43,35,23,5],
[43,35,23,6],
[43,35,23,8],
[43,35,23,10],
[43,35,23,11],
[43,35,23,12],
[43,35,23,13],
[43,35,23,14],
[43,35,23,16],
[43,35,23,17],
[43,35,23,19],
[43,35,23,20],
[43,35,23,21],
[43,35,23,22],
[43,35,24,0],
[43,35,24,1],
[43,35,24,2],
[43,35,24,3],
[43,35,24,4],
[43,35,24,5],
[43,35,24,6],
[43,35,24,8],
[43,35,24,10],
[43,35,24,11],
[43,35,24,12],
[43,35,24,13],
[43,35,24,14],
[43,35,24,17],
[43,35,24,19],
[43,35,24,20],
[43,35,24,21],
[43,35,24,22],
[43,35,24,23],
[43,35,27,0],
[43,35,27,1],
[43,35,27,2],
[43,35,27,3],
[43,35,27,4],
[43,35,27,5],
[43,35,27,6],
[43,35,27,8],
[43,35,27,11],
[43,35,27,12],
[43,35,27,13],
[43,35,27,14],
[43,35,27,16],
[43,35,27,17],
[43,35,27,19],
[43,35,27,21],
[43,35,29,0],
[43,35,29,1],
[43,35,29,2],
[43,35,29,3],
[43,35,29,4],
[43,35,29,5],
[43,35,29,6],
[43,35,29,8],
[43,35,29,10],
[43,35,29,13],
[43,35,29,14],
[43,35,29,16],
[43,35,29,17],
[43,35,29,19],
[43,35,29,20],
[43,35,29,22],
[43,35,29,23],
[43,35,29,24],
[43,35,29,27],
[43,35,30,0],
[43,35,30,1],
[43,35,30,2],
[43,35,30,3],
[43,35,30,4],
[43,35,30,5],
[43,35,30,6],
[43,35,30,8],
[43,35,30,10],
[43,35,30,11],
[43,35,30,12],
[43,35,30,13],
[43,35,30,14],
[43,35,30,16],
[43,35,30,17],
[43,35,30,19],
[43,35,30,21],
[43,35,30,22],
[43,35,30,24],
[43,35,30,27],
[43,35,30,29],
[43,35,31,0],
[43,35,31,1],
[43,35,31,2],
[43,35,31,3],
[43,35,31,4],
[43,35,31,5],
[43,35,31,6],
[43,35,31,8],
[43,35,31,10],
[43,35,31,11],
[43,35,31,12],
[43,35,31,13],
[43,35,31,14],
[43,35,31,16],
[43,35,31,17],
[43,35,31,20],
[43,35,31,21],
[43,35,31,22],
[43,35,31,24],
[43,35,31,27],
[43,35,31,29],
[43,35,32,0],
[43,35,32,1],
[43,35,32,2],
[43,35,32,3],
[43,35,32,4],
[43,35,32,5],
[43,35,32,6],
[43,35,32,8],
[43,35,32,10],
[43,35,32,13],
[43,35,32,14],
[43,35,32,16],
[43,35,32,17],
[43,35,32,19],
[43,35,32,20],
[43,35,32,21],
[43,35,32,22],
[43,35,32,23],
[43,35,32,24],
[43,35,32,27],
[43,35,32,30],
[43,35,33,0],
[43,35,33,1],
[43,35,33,2],
[43,35,33,3],
[43,35,33,4],
[43,35,33,5],
[43,35,33,6],
[43,35,33,8],
[43,35,33,10],
[43,35,33,13],
[43,35,33,14],
[43,35,33,19],
[43,35,33,20],
[43,35,33,21],
[43,35,33,22],
[43,35,33,23],
[43,35,33,24],
[43,35,33,27],
[43,36,3,0],
[43,36,3,1],
[43,36,3,2],
[43,36,4,0],
[43,36,4,1],
[43,36,4,2],
[43,36,4,3],
[43,36,5,0],
[43,36,5,1],
[43,36,5,2],
[43,36,5,4],
[43,36,6,0],
[43,36,6,1],
[43,36,6,2],
[43,36,6,4],
[43,36,8,0],
[43,36,8,1],
[43,36,8,2],
[43,36,8,4],
[43,36,9,0],
[43,36,9,1],
[43,36,9,2],
[43,36,9,3],
[43,36,9,4],
[43,36,9,5],
[43,36,9,6],
[43,36,9,8],
[43,36,10,0],
[43,36,10,1],
[43,36,10,2],
[43,36,10,3],
[43,36,10,4],
[43,36,10,5],
[43,36,10,6],
[43,36,10,8],
[43,36,10,9],
[43,36,11,0],
[43,36,11,1],
[43,36,11,2],
[43,36,11,3],
[43,36,11,4],
[43,36,11,5],
[43,36,11,6],
[43,36,11,8],
[43,36,11,9],
[43,36,11,10],
[43,36,12,0],
[43,36,12,1],
[43,36,12,2],
[43,36,12,3],
[43,36,12,4],
[43,36,12,5],
[43,36,12,6],
[43,36,12,8],
[43,36,12,9],
[43,36,12,10],
[43,36,15,0],
[43,36,15,1],
[43,36,15,2],
[43,36,15,3],
[43,36,15,4],
[43,36,15,5],
[43,36,15,6],
[43,36,15,8],
[43,36,15,10],
[43,36,15,11],
[43,36,15,12],
[43,36,16,0],
[43,36,16,1],
[43,36,16,2],
[43,36,16,3],
[43,36,16,4],
[43,36,16,5],
[43,36,16,6],
[43,36,16,8],
[43,36,16,9],
[43,36,16,10],
[43,36,16,11],
[43,36,16,12],
[43,36,16,15],
[43,36,17,0],
[43,36,17,1],
[43,36,17,2],
[43,36,17,3],
[43,36,17,4],
[43,36,17,5],
[43,36,17,6],
[43,36,17,8],
[43,36,17,9],
[43,36,17,10],
[43,36,17,11],
[43,36,17,12],
[43,36,17,15],
[43,36,17,16],
[43,36,18,0],
[43,36,18,1],
[43,36,18,2],
[43,36,18,3],
[43,36,18,4],
[43,36,18,5],
[43,36,18,6],
[43,36,18,8],
[43,36,18,10],
[43,36,18,11],
[43,36,18,12],
[43,36,18,16],
[43,36,18,17],
[43,36,20,0],
[43,36,20,1],
[43,36,20,2],
[43,36,20,3],
[43,36,20,4],
[43,36,20,5],
[43,36,20,6],
[43,36,20,8],
[43,36,20,9],
[43,36,20,11],
[43,36,20,12],
[43,36,20,15],
[43,36,20,16],
[43,36,20,17],
[43,36,20,18],
[43,36,21,0],
[43,36,21,1],
[43,36,21,2],
[43,36,21,4],
[43,36,21,9],
[43,36,21,10],
[43,36,21,11],
[43,36,21,12],
[43,36,21,15],
[43,36,21,16],
[43,36,21,17],
[43,36,21,18],
[43,36,21,20],
[43,36,22,0],
[43,36,22,1],
[43,36,22,2],
[43,36,22,3],
[43,36,22,4],
[43,36,22,5],
[43,36,22,6],
[43,36,22,8],
[43,36,22,9],
[43,36,22,11],
[43,36,22,12],
[43,36,22,15],
[43,36,22,16],
[43,36,22,17],
[43,36,22,18],
[43,36,22,21],
[43,36,23,0],
[43,36,23,1],
[43,36,23,2],
[43,36,23,3],
[43,36,23,4],
[43,36,23,5],
[43,36,23,6],
[43,36,23,8],
[43,36,23,9],
[43,36,23,10],
[43,36,23,11],
[43,36,23,12],
[43,36,23,15],
[43,36,23,16],
[43,36,23,17],
[43,36,23,18],
[43,36,23,20],
[43,36,23,21],
[43,36,23,22],
[43,36,24,0],
[43,36,24,1],
[43,36,24,2],
[43,36,24,3],
[43,36,24,4],
[43,36,24,5],
[43,36,24,6],
[43,36,24,8],
[43,36,24,9],
[43,36,24,10],
[43,36,24,11],
[43,36,24,12],
[43,36,24,15],
[43,36,24,17],
[43,36,24,18],
[43,36,24,20],
[43,36,24,21],
[43,36,24,22],
[43,36,24,23],
[43,36,26,0],
[43,36,26,1],
[43,36,26,2],
[43,36,26,3],
[43,36,26,4],
[43,36,26,5],
[43,36,26,6],
[43,36,26,8],
[43,36,26,10],
[43,36,26,11],
[43,36,26,12],
[43,36,26,16],
[43,36,26,17],
[43,36,26,20],
[43,36,26,21],
[43,36,26,22],
[43,36,27,0],
[43,36,27,1],
[43,36,27,2],
[43,36,27,3],
[43,36,27,4],
[43,36,27,5],
[43,36,27,6],
[43,36,27,8],
[43,36,27,9],
[43,36,27,11],
[43,36,27,12],
[43,36,27,15],
[43,36,27,16],
[43,36,27,17],
[43,36,27,18],
[43,36,27,21],
[43,36,27,23],
[43,36,27,24],
[43,36,27,26],
[43,36,29,0],
[43,36,29,1],
[43,36,29,2],
[43,36,29,3],
[43,36,29,4],
[43,36,29,5],
[43,36,29,6],
[43,36,29,8],
[43,36,29,9],
[43,36,29,10],
[43,36,29,15],
[43,36,29,16],
[43,36,29,17],
[43,36,29,18],
[43,36,29,21],
[43,36,29,22],
[43,36,29,23],
[43,36,29,24],
[43,36,29,26],
[43,36,29,27],
[43,36,30,0],
[43,36,30,1],
[43,36,30,2],
[43,36,30,3],
[43,36,30,4],
[43,36,30,5],
[43,36,30,6],
[43,36,30,8],
[43,36,30,9],
[43,36,30,10],
[43,36,30,11],
[43,36,30,12],
[43,36,30,15],
[43,36,30,16],
[43,36,30,17],
[43,36,30,18],
[43,36,30,20],
[43,36,30,21],
[43,36,30,22],
[43,36,30,24],
[43,36,30,26],
[43,36,30,27],
[43,36,30,29],
[43,36,31,0],
[43,36,31,1],
[43,36,31,2],
[43,36,31,3],
[43,36,31,4],
[43,36,31,5],
[43,36,31,6],
[43,36,31,8],
[43,36,31,9],
[43,36,31,10],
[43,36,31,11],
[43,36,31,12],
[43,36,31,15],
[43,36,31,16],
[43,36,31,17],
[43,36,31,20],
[43,36,31,21],
[43,36,31,22],
[43,36,31,24],
[43,36,31,26],
[43,36,31,27],
[43,36,31,29],
[43,36,32,0],
[43,36,32,1],
[43,36,32,2],
[43,36,32,3],
[43,36,32,4],
[43,36,32,5],
[43,36,32,6],
[43,36,32,8],
[43,36,32,9],
[43,36,32,10],
[43,36,32,15],
[43,36,32,18],
[43,36,32,20],
[43,36,32,21],
[43,36,32,22],
[43,36,32,23],
[43,36,32,24],
[43,36,32,26],
[43,36,32,27],
[43,36,33,0],
[43,36,33,1],
[43,36,33,2],
[43,36,33,3],
[43,36,33,4],
[43,36,33,5],
[43,36,33,6],
[43,36,33,8],
[43,36,33,9],
[43,36,33,10],
[43,36,33,16],
[43,36,33,17],
[43,36,33,18],
[43,36,33,20],
[43,36,33,21],
[43,36,33,22],
[43,36,33,23],
[43,36,33,24],
[43,36,33,26],
[43,36,33,27],
[43,36,35,0],
[43,36,35,1],
[43,36,35,2],
[43,36,35,3],
[43,36,35,4],
[43,36,35,5],
[43,36,35,6],
[43,36,35,8],
[43,36,35,10],
[43,36,35,11],
[43,36,35,16],
[43,36,35,17],
[43,36,35,20],
[43,36,35,21],
[43,36,35,22],
[43,36,35,23],
[43,36,35,24],
[43,37,3,0],
[43,37,3,1],
[43,37,3,2],
[43,37,5,0],
[43,37,5,1],
[43,37,5,2],
[43,37,6,0],
[43,37,6,1],
[43,37,6,2],
[43,37,8,0],
[43,37,8,1],
[43,37,8,2],
[43,37,9,0],
[43,37,9,1],
[43,37,9,2],
[43,37,9,3],
[43,37,9,5],
[43,37,9,6],
[43,37,9,8],
[43,37,10,0],
[43,37,10,1],
[43,37,10,2],
[43,37,10,3],
[43,37,10,5],
[43,37,10,6],
[43,37,10,8],
[43,37,10,9],
[43,37,11,0],
[43,37,11,1],
[43,37,11,2],
[43,37,11,3],
[43,37,11,5],
[43,37,11,6],
[43,37,11,8],
[43,37,11,9],
[43,37,11,10],
[43,37,12,0],
[43,37,12,1],
[43,37,12,2],
[43,37,12,3],
[43,37,12,5],
[43,37,12,6],
[43,37,12,8],
[43,37,12,9],
[43,37,12,10],
[43,37,13,0],
[43,37,13,1],
[43,37,13,2],
[43,37,13,3],
[43,37,13,5],
[43,37,13,6],
[43,37,13,8],
[43,37,13,9],
[43,37,13,10],
[43,37,13,11],
[43,37,13,12],
[43,37,14,0],
[43,37,14,1],
[43,37,14,2],
[43,37,14,3],
[43,37,14,5],
[43,37,14,6],
[43,37,14,8],
[43,37,14,9],
[43,37,14,10],
[43,37,14,11],
[43,37,14,12],
[43,37,15,0],
[43,37,15,1],
[43,37,15,2],
[43,37,15,3],
[43,37,15,5],
[43,37,15,6],
[43,37,15,8],
[43,37,15,10],
[43,37,15,11],
[43,37,15,12],
[43,37,15,13],
[43,37,15,14],
[43,37,16,0],
[43,37,16,1],
[43,37,16,2],
[43,37,16,3],
[43,37,16,5],
[43,37,16,6],
[43,37,16,8],
[43,37,16,9],
[43,37,16,10],
[43,37,16,11],
[43,37,16,12],
[43,37,16,13],
[43,37,16,14],
[43,37,16,15],
[43,37,17,0],
[43,37,17,1],
[43,37,17,2],
[43,37,17,3],
[43,37,17,5],
[43,37,17,6],
[43,37,17,8],
[43,37,17,9],
[43,37,17,10],
[43,37,17,11],
[43,37,17,12],
[43,37,17,13],
[43,37,17,14],
[43,37,17,15],
[43,37,17,16],
[43,37,18,0],
[43,37,18,1],
[43,37,18,2],
[43,37,18,3],
[43,37,18,5],
[43,37,18,6],
[43,37,18,8],
[43,37,18,10],
[43,37,18,11],
[43,37,18,12],
[43,37,18,13],
[43,37,18,14],
[43,37,18,16],
[43,37,18,17],
[43,37,19,0],
[43,37,19,1],
[43,37,19,2],
[43,37,19,3],
[43,37,19,5],
[43,37,19,6],
[43,37,19,8],
[43,37,19,9],
[43,37,19,10],
[43,37,19,11],
[43,37,19,12],
[43,37,19,15],
[43,37,19,16],
[43,37,19,17],
[43,37,19,18],
[43,37,20,0],
[43,37,20,1],
[43,37,20,2],
[43,37,20,3],
[43,37,20,5],
[43,37,20,6],
[43,37,20,8],
[43,37,20,9],
[43,37,20,11],
[43,37,20,12],
[43,37,20,13],
[43,37,20,14],
[43,37,20,15],
[43,37,20,16],
[43,37,20,17],
[43,37,20,18],
[43,37,20,19],
[43,37,21,0],
[43,37,21,1],
[43,37,21,2],
[43,37,21,9],
[43,37,21,10],
[43,37,21,11],
[43,37,21,12],
[43,37,21,13],
[43,37,21,14],
[43,37,21,15],
[43,37,21,16],
[43,37,21,17],
[43,37,21,18],
[43,37,21,19],
[43,37,21,20],
[43,37,22,0],
[43,37,22,1],
[43,37,22,2],
[43,37,22,3],
[43,37,22,5],
[43,37,22,6],
[43,37,22,8],
[43,37,22,9],
[43,37,22,11],
[43,37,22,12],
[43,37,22,13],
[43,37,22,14],
[43,37,22,15],
[43,37,22,16],
[43,37,22,17],
[43,37,22,18],
[43,37,22,19],
[43,37,22,21],
[43,37,23,0],
[43,37,23,1],
[43,37,23,2],
[43,37,23,3],
[43,37,23,5],
[43,37,23,6],
[43,37,23,8],
[43,37,23,9],
[43,37,23,10],
[43,37,23,11],
[43,37,23,12],
[43,37,23,13],
[43,37,23,14],
[43,37,23,15],
[43,37,23,16],
[43,37,23,17],
[43,37,23,18],
[43,37,23,19],
[43,37,23,20],
[43,37,23,21],
[43,37,23,22],
[43,37,24,0],
[43,37,24,1],
[43,37,24,2],
[43,37,24,3],
[43,37,24,5],
[43,37,24,6],
[43,37,24,8],
[43,37,24,9],
[43,37,24,10],
[43,37,24,11],
[43,37,24,12],
[43,37,24,13],
[43,37,24,14],
[43,37,24,15],
[43,37,24,17],
[43,37,24,18],
[43,37,24,19],
[43,37,24,20],
[43,37,24,21],
[43,37,24,22],
[43,37,24,23],
[43,37,26,0],
[43,37,26,1],
[43,37,26,2],
[43,37,26,3],
[43,37,26,5],
[43,37,26,6],
[43,37,26,8],
[43,37,26,10],
[43,37,26,11],
[43,37,26,12],
[43,37,26,13],
[43,37,26,14],
[43,37,26,16],
[43,37,26,17],
[43,37,26,19],
[43,37,26,20],
[43,37,26,21],
[43,37,26,23],
[43,37,26,24],
[43,37,27,0],
[43,37,27,1],
[43,37,27,2],
[43,37,27,3],
[43,37,27,5],
[43,37,27,6],
[43,37,27,8],
[43,37,27,9],
[43,37,27,11],
[43,37,27,12],
[43,37,27,13],
[43,37,27,14],
[43,37,27,15],
[43,37,27,16],
[43,37,27,17],
[43,37,27,18],
[43,37,27,19],
[43,37,27,23],
[43,37,27,24],
[43,37,27,26],
[43,37,29,0],
[43,37,29,1],
[43,37,29,2],
[43,37,29,3],
[43,37,29,5],
[43,37,29,6],
[43,37,29,8],
[43,37,29,9],
[43,37,29,10],
[43,37,29,13],
[43,37,29,14],
[43,37,29,15],
[43,37,29,16],
[43,37,29,17],
[43,37,29,18],
[43,37,29,20],
[43,37,29,21],
[43,37,29,22],
[43,37,29,23],
[43,37,29,24],
[43,37,29,26],
[43,37,29,27],
[43,37,30,0],
[43,37,30,1],
[43,37,30,2],
[43,37,30,3],
[43,37,30,5],
[43,37,30,6],
[43,37,30,8],
[43,37,30,9],
[43,37,30,10],
[43,37,30,11],
[43,37,30,12],
[43,37,30,13],
[43,37,30,14],
[43,37,30,15],
[43,37,30,16],
[43,37,30,17],
[43,37,30,19],
[43,37,30,20],
[43,37,30,21],
[43,37,30,22],
[43,37,30,24],
[43,37,30,26],
[43,37,30,27],
[43,37,30,29],
[43,37,31,0],
[43,37,31,1],
[43,37,31,2],
[43,37,31,3],
[43,37,31,5],
[43,37,31,6],
[43,37,31,8],
[43,37,31,9],
[43,37,31,10],
[43,37,31,11],
[43,37,31,12],
[43,37,31,13],
[43,37,31,14],
[43,37,31,15],
[43,37,31,18],
[43,37,31,19],
[43,37,31,20],
[43,37,31,21],
[43,37,31,22],
[43,37,31,24],
[43,37,31,26],
[43,37,31,27],
[43,37,31,29],
[43,37,32,0],
[43,37,32,1],
[43,37,32,2],
[43,37,32,3],
[43,37,32,5],
[43,37,32,6],
[43,37,32,8],
[43,37,32,9],
[43,37,32,10],
[43,37,32,13],
[43,37,32,14],
[43,37,32,16],
[43,37,32,17],
[43,37,32,18],
[43,37,32,19],
[43,37,32,20],
[43,37,32,21],
[43,37,32,22],
[43,37,32,23],
[43,37,32,24],
[43,37,32,26],
[43,37,32,27],
[43,37,33,0],
[43,37,33,1],
[43,37,33,2],
[43,37,33,3],
[43,37,33,5],
[43,37,33,6],
[43,37,33,8],
[43,37,33,9],
[43,37,33,10],
[43,37,33,13],
[43,37,33,15],
[43,37,33,16],
[43,37,33,17],
[43,37,33,18],
[43,37,33,19],
[43,37,33,20],
[43,37,33,21],
[43,37,33,22],
[43,37,33,23],
[43,37,33,24],
[43,37,33,26],
[43,37,33,27],
[43,37,35,0],
[43,37,35,1],
[43,37,35,2],
[43,37,35,3],
[43,37,35,5],
[43,37,35,6],
[43,37,35,8],
[43,37,35,12],
[43,37,35,13],
[43,37,35,14],
[43,37,35,16],
[43,37,35,17],
[43,37,35,19],
[43,37,35,20],
[43,37,35,21],
[43,37,35,22],
[43,37,35,23],
[43,37,35,24],
[43,37,36,0],
[43,37,36,1],
[43,37,36,2],
[43,37,36,3],
[43,37,36,5],
[43,37,36,6],
[43,37,36,10],
[43,37,36,11],
[43,37,36,12],
[43,37,36,15],
[43,37,36,16],
[43,37,36,17],
[43,37,36,18],
[43,37,36,20],
[43,37,36,21],
[43,37,36,22],
[43,37,36,23],
[43,37,36,24],
[43,39,4,0],
[43,39,4,1],
[43,39,4,2],
[43,39,9,0],
[43,39,9,1],
[43,39,9,2],
[43,39,9,4],
[43,39,10,0],
[43,39,10,1],
[43,39,10,2],
[43,39,10,4],
[43,39,10,9],
[43,39,11,0],
[43,39,11,1],
[43,39,11,2],
[43,39,11,4],
[43,39,11,9],
[43,39,11,10],
[43,39,12,0],
[43,39,12,1],
[43,39,12,2],
[43,39,12,4],
[43,39,12,9],
[43,39,12,10],
[43,39,13,0],
[43,39,13,1],
[43,39,13,2],
[43,39,13,4],
[43,39,13,9],
[43,39,13,10],
[43,39,13,11],
[43,39,13,12],
[43,39,14,0],
[43,39,14,1],
[43,39,14,2],
[43,39,14,4],
[43,39,14,9],
[43,39,14,10],
[43,39,14,11],
[43,39,14,12],
[43,39,15,0],
[43,39,15,1],
[43,39,15,2],
[43,39,15,4],
[43,39,15,10],
[43,39,15,11],
[43,39,15,12],
[43,39,15,13],
[43,39,15,14],
[43,39,16,0],
[43,39,16,1],
[43,39,16,2],
[43,39,16,4],
[43,39,16,9],
[43,39,16,10],
[43,39,16,11],
[43,39,16,12],
[43,39,16,13],
[43,39,16,14],
[43,39,16,15],
[43,39,17,0],
[43,39,17,1],
[43,39,17,2],
[43,39,17,4],
[43,39,17,9],
[43,39,17,10],
[43,39,17,11],
[43,39,17,12],
[43,39,17,13],
[43,39,17,14],
[43,39,17,15],
[43,39,17,16],
[43,39,18,0],
[43,39,18,1],
[43,39,18,2],
[43,39,18,4],
[43,39,18,10],
[43,39,18,11],
[43,39,18,12],
[43,39,18,13],
[43,39,18,14],
[43,39,18,16],
[43,39,18,17],
[43,39,19,0],
[43,39,19,1],
[43,39,19,2],
[43,39,19,4],
[43,39,19,9],
[43,39,19,10],
[43,39,19,11],
[43,39,19,12],
[43,39,19,15],
[43,39,19,16],
[43,39,19,17],
[43,39,19,18],
[43,39,20,0],
[43,39,20,1],
[43,39,20,2],
[43,39,20,4],
[43,39,20,9],
[43,39,20,11],
[43,39,20,12],
[43,39,20,13],
[43,39,20,14],
[43,39,20,15],
[43,39,20,16],
[43,39,20,17],
[43,39,20,18],
[43,39,20,19],
[43,39,22,0],
[43,39,22,1],
[43,39,22,2],
[43,39,22,4],
[43,39,22,9],
[43,39,22,11],
[43,39,22,12],
[43,39,22,13],
[43,39,22,14],
[43,39,22,15],
[43,39,22,16],
[43,39,22,17],
[43,39,22,18],
[43,39,22,19],
[43,39,23,0],
[43,39,23,1],
[43,39,23,2],
[43,39,23,4],
[43,39,23,9],
[43,39,23,10],
[43,39,23,11],
[43,39,23,12],
[43,39,23,13],
[43,39,23,14],
[43,39,23,15],
[43,39,23,16],
[43,39,23,17],
[43,39,23,18],
[43,39,23,19],
[43,39,23,20],
[43,39,23,22],
[43,39,24,0],
[43,39,24,1],
[43,39,24,2],
[43,39,24,4],
[43,39,24,9],
[43,39,24,10],
[43,39,24,11],
[43,39,24,12],
[43,39,24,13],
[43,39,24,14],
[43,39,24,15],
[43,39,24,17],
[43,39,24,18],
[43,39,24,19],
[43,39,24,20],
[43,39,24,22],
[43,39,26,0],
[43,39,26,1],
[43,39,26,2],
[43,39,26,4],
[43,39,26,10],
[43,39,26,11],
[43,39,26,12],
[43,39,26,13],
[43,39,26,14],
[43,39,26,16],
[43,39,26,17],
[43,39,26,19],
[43,39,26,22],
[43,39,26,23],
[43,39,26,24],
[43,39,27,0],
[43,39,27,1],
[43,39,27,2],
[43,39,27,4],
[43,39,27,9],
[43,39,27,11],
[43,39,27,12],
[43,39,27,13],
[43,39,27,14],
[43,39,27,15],
[43,39,27,16],
[43,39,27,17],
[43,39,27,18],
[43,39,27,23],
[43,39,27,24],
[43,39,27,26],
[43,39,29,0],
[43,39,29,1],
[43,39,29,2],
[43,39,29,4],
[43,39,29,9],
[43,39,29,10],
[43,39,29,13],
[43,39,29,14],
[43,39,29,15],
[43,39,29,18],
[43,39,29,19],
[43,39,29,20],
[43,39,29,22],
[43,39,29,23],
[43,39,29,24],
[43,39,29,26],
[43,39,29,27],
[43,39,30,0],
[43,39,30,1],
[43,39,30,2],
[43,39,30,4],
[43,39,30,9],
[43,39,30,10],
[43,39,30,11],
[43,39,30,12],
[43,39,30,13],
[43,39,30,14],
[43,39,30,16],
[43,39,30,17],
[43,39,30,18],
[43,39,30,19],
[43,39,30,20],
[43,39,30,22],
[43,39,30,24],
[43,39,30,26],
[43,39,30,27],
[43,39,31,0],
[43,39,31,1],
[43,39,31,2],
[43,39,31,4],
[43,39,31,9],
[43,39,31,10],
[43,39,31,11],
[43,39,31,12],
[43,39,31,13],
[43,39,31,15],
[43,39,31,16],
[43,39,31,17],
[43,39,31,18],
[43,39,31,19],
[43,39,31,20],
[43,39,31,22],
[43,39,31,24],
[43,39,31,26],
[43,39,31,27],
[43,39,32,0],
[43,39,32,1],
[43,39,32,2],
[43,39,32,4],
[43,39,32,9],
[43,39,32,10],
[43,39,32,14],
[43,39,32,15],
[43,39,32,16],
[43,39,32,17],
[43,39,32,18],
[43,39,32,19],
[43,39,32,20],
[43,39,32,22],
[43,39,32,23],
[43,39,32,24],
[43,39,32,26],
[43,39,33,0],
[43,39,33,1],
[43,39,33,2],
[43,39,33,4],
[43,39,33,9],
[43,39,33,13],
[43,39,33,14],
[43,39,33,15],
[43,39,33,16],
[43,39,33,17],
[43,39,33,18],
[43,39,33,19],
[43,39,33,20],
[43,39,33,22],
[43,39,33,23],
[43,39,33,24],
[43,39,35,0],
[43,39,35,1],
[43,39,35,2],
[43,39,35,4],
[43,39,35,10],
[43,39,35,11],
[43,39,35,12],
[43,39,35,13],
[43,39,35,14],
[43,39,35,16],
[43,39,35,17],
[43,39,35,19],
[43,39,35,20],
[43,39,35,22],
[43,39,35,23],
[43,39,35,24],
[43,39,36,0],
[43,39,36,1],
[43,39,36,2],
[43,39,36,4],
[43,39,36,9],
[43,39,36,10],
[43,39,36,11],
[43,39,36,12],
[43,39,36,15],
[43,39,36,16],
[43,39,36,17],
[43,39,36,18],
[43,39,36,20],
[43,39,36,22],
[43,39,37,0],
[43,39,37,1],
[43,39,37,2],
[43,39,37,9],
[43,39,37,10],
[43,39,37,11],
[43,39,37,12],
[43,39,37,13],
[43,39,37,14],
[43,39,37,15],
[43,39,37,16],
[43,39,37,17],
[43,39,37,18],
[43,39,37,19],
[43,39,37,20],
[43,40,3,0],
[43,40,3,1],
[43,40,3,2],
[43,40,4,0],
[43,40,4,1],
[43,40,4,2],
[43,40,4,3],
[43,40,5,0],
[43,40,5,1],
[43,40,5,2],
[43,40,5,4],
[43,40,6,0],
[43,40,6,1],
[43,40,6,2],
[43,40,6,4],
[43,40,8,0],
[43,40,8,1],
[43,40,8,2],
[43,40,8,4],
[43,40,10,0],
[43,40,10,1],
[43,40,10,2],
[43,40,10,3],
[43,40,10,4],
[43,40,10,5],
[43,40,10,6],
[43,40,10,8],
[43,40,11,0],
[43,40,11,1],
[43,40,11,2],
[43,40,11,3],
[43,40,11,4],
[43,40,11,5],
[43,40,11,6],
[43,40,11,8],
[43,40,11,10],
[43,40,12,0],
[43,40,12,1],
[43,40,12,2],
[43,40,12,3],
[43,40,12,4],
[43,40,12,5],
[43,40,12,6],
[43,40,12,8],
[43,40,12,10],
[43,40,13,0],
[43,40,13,1],
[43,40,13,2],
[43,40,13,3],
[43,40,13,4],
[43,40,13,5],
[43,40,13,6],
[43,40,13,8],
[43,40,13,10],
[43,40,13,11],
[43,40,13,12],
[43,40,14,0],
[43,40,14,1],
[43,40,14,2],
[43,40,14,3],
[43,40,14,4],
[43,40,14,5],
[43,40,14,6],
[43,40,14,8],
[43,40,14,10],
[43,40,14,11],
[43,40,14,12],
[43,40,16,0],
[43,40,16,1],
[43,40,16,2],
[43,40,16,3],
[43,40,16,4],
[43,40,16,5],
[43,40,16,6],
[43,40,16,8],
[43,40,16,10],
[43,40,16,11],
[43,40,16,12],
[43,40,16,13],
[43,40,16,14],
[43,40,17,0],
[43,40,17,1],
[43,40,17,2],
[43,40,17,3],
[43,40,17,4],
[43,40,17,5],
[43,40,17,6],
[43,40,17,8],
[43,40,17,10],
[43,40,17,11],
[43,40,17,12],
[43,40,17,13],
[43,40,17,14],
[43,40,17,16],
[43,40,19,0],
[43,40,19,1],
[43,40,19,2],
[43,40,19,3],
[43,40,19,4],
[43,40,19,5],
[43,40,19,6],
[43,40,19,8],
[43,40,19,10],
[43,40,19,11],
[43,40,19,12],
[43,40,19,16],
[43,40,19,17],
[43,40,20,0],
[43,40,20,1],
[43,40,20,2],
[43,40,20,3],
[43,40,20,4],
[43,40,20,5],
[43,40,20,6],
[43,40,20,8],
[43,40,20,11],
[43,40,20,12],
[43,40,20,13],
[43,40,20,14],
[43,40,20,16],
[43,40,20,17],
[43,40,20,19],
[43,40,21,0],
[43,40,21,1],
[43,40,21,2],
[43,40,21,4],
[43,40,21,10],
[43,40,21,11],
[43,40,21,12],
[43,40,21,13],
[43,40,21,14],
[43,40,21,16],
[43,40,21,17],
[43,40,21,19],
[43,40,21,20],
[43,40,22,0],
[43,40,22,1],
[43,40,22,2],
[43,40,22,3],
[43,40,22,4],
[43,40,22,5],
[43,40,22,6],
[43,40,22,8],
[43,40,22,11],
[43,40,22,12],
[43,40,22,13],
[43,40,22,14],
[43,40,22,16],
[43,40,22,17],
[43,40,22,19],
[43,40,22,21],
[43,40,23,0],
[43,40,23,1],
[43,40,23,2],
[43,40,23,3],
[43,40,23,4],
[43,40,23,5],
[43,40,23,6],
[43,40,23,8],
[43,40,23,10],
[43,40,23,11],
[43,40,23,12],
[43,40,23,13],
[43,40,23,14],
[43,40,23,16],
[43,40,23,17],
[43,40,23,19],
[43,40,23,20],
[43,40,23,21],
[43,40,24,0],
[43,40,24,1],
[43,40,24,2],
[43,40,24,3],
[43,40,24,4],
[43,40,24,5],
[43,40,24,6],
[43,40,24,8],
[43,40,24,10],
[43,40,24,11],
[43,40,24,12],
[43,40,24,13],
[43,40,24,14],
[43,40,24,17],
[43,40,24,19],
[43,40,24,20],
[43,40,24,21],
[43,40,24,23],
[43,40,27,0],
[43,40,27,1],
[43,40,27,2],
[43,40,27,3],
[43,40,27,4],
[43,40,27,5],
[43,40,27,6],
[43,40,27,8],
[43,40,27,11],
[43,40,27,12],
[43,40,27,13],
[43,40,27,14],
[43,40,27,16],
[43,40,27,17],
[43,40,27,19],
[43,40,27,21],
[43,40,27,23],
[43,40,27,24],
[43,40,29,0],
[43,40,29,1],
[43,40,29,2],
[43,40,29,3],
[43,40,29,4],
[43,40,29,5],
[43,40,29,6],
[43,40,29,8],
[43,40,29,10],
[43,40,29,13],
[43,40,29,14],
[43,40,29,16],
[43,40,29,17],
[43,40,29,19],
[43,40,29,20],
[43,40,29,21],
[43,40,29,22],
[43,40,29,23],
[43,40,29,24],
[43,40,29,27],
[43,40,30,0],
[43,40,30,1],
[43,40,30,2],
[43,40,30,3],
[43,40,30,4],
[43,40,30,5],
[43,40,30,6],
[43,40,30,8],
[43,40,30,10],
[43,40,30,11],
[43,40,30,12],
[43,40,30,13],
[43,40,30,16],
[43,40,30,17],
[43,40,30,19],
[43,40,30,20],
[43,40,30,21],
[43,40,30,22],
[43,40,30,24],
[43,40,30,27],
[43,40,31,0],
[43,40,31,1],
[43,40,31,2],
[43,40,31,3],
[43,40,31,4],
[43,40,31,5],
[43,40,31,6],
[43,40,31,8],
[43,40,31,10],
[43,40,31,11],
[43,40,31,14],
[43,40,31,16],
[43,40,31,17],
[43,40,31,19],
[43,40,31,20],
[43,40,31,21],
[43,40,31,22],
[43,40,31,24],
[43,40,32,0],
[43,40,32,1],
[43,40,32,2],
[43,40,32,3],
[43,40,32,4],
[43,40,32,5],
[43,40,32,6],
[43,40,32,8],
[43,40,32,13],
[43,40,32,14],
[43,40,32,16],
[43,40,32,17],
[43,40,32,19],
[43,40,32,20],
[43,40,32,21],
[43,40,32,22],
[43,40,32,23],
[43,40,32,24],
[43,40,33,0],
[43,40,33,1],
[43,40,33,2],
[43,40,33,3],
[43,40,33,4],
[43,40,33,5],
[43,40,33,6],
[43,40,33,10],
[43,40,33,13],
[43,40,33,14],
[43,40,33,16],
[43,40,33,17],
[43,40,33,19],
[43,40,33,20],
[43,40,33,21],
[43,40,33,22],
[43,40,33,23],
[43,40,33,24],
[43,40,36,0],
[43,40,36,1],
[43,40,36,2],
[43,40,36,5],
[43,40,36,6],
[43,40,36,8],
[43,40,36,10],
[43,40,36,11],
[43,40,36,12],
[43,40,36,16],
[43,40,36,17],
[43,40,36,20],
[43,40,36,21],
[43,40,37,3],
[43,40,37,5],
[43,40,37,6],
[43,40,37,8],
[43,40,37,10],
[43,40,37,11],
[43,40,37,12],
[43,40,37,13],
[43,40,37,14],
[43,40,37,16],
[43,40,37,17],
[43,40,37,19],
[43,40,37,20],
[43,40,39,0],
[43,40,39,1],
[43,40,39,2],
[43,40,39,4],
[43,40,39,10],
[43,40,39,11],
[43,40,39,12],
[43,40,39,13],
[43,40,39,14],
[43,40,39,16],
[43,40,39,17],
[43,41,3,0],
[43,41,3,1],
[43,41,3,2],
[43,41,4,0],
[43,41,4,1],
[43,41,4,2],
[43,41,4,3],
[43,41,5,0],
[43,41,5,1],
[43,41,5,2],
[43,41,5,4],
[43,41,6,0],
[43,41,6,1],
[43,41,6,2],
[43,41,6,4],
[43,41,8,0],
[43,41,8,1],
[43,41,8,2],
[43,41,8,4],
[43,41,10,0],
[43,41,10,1],
[43,41,10,2],
[43,41,10,3],
[43,41,10,4],
[43,41,10,5],
[43,41,10,6],
[43,41,10,8],
[43,41,11,0],
[43,41,11,1],
[43,41,11,2],
[43,41,11,3],
[43,41,11,4],
[43,41,11,5],
[43,41,11,6],
[43,41,11,8],
[43,41,11,10],
[43,41,12,0],
[43,41,12,1],
[43,41,12,2],
[43,41,12,3],
[43,41,12,4],
[43,41,12,5],
[43,41,12,6],
[43,41,12,8],
[43,41,12,10],
[43,41,13,0],
[43,41,13,1],
[43,41,13,2],
[43,41,13,3],
[43,41,13,4],
[43,41,13,5],
[43,41,13,6],
[43,41,13,8],
[43,41,13,10],
[43,41,13,11],
[43,41,13,12],
[43,41,14,0],
[43,41,14,1],
[43,41,14,2],
[43,41,14,3],
[43,41,14,4],
[43,41,14,5],
[43,41,14,6],
[43,41,14,8],
[43,41,14,10],
[43,41,14,11],
[43,41,14,12],
[43,41,16,0],
[43,41,16,1],
[43,41,16,2],
[43,41,16,3],
[43,41,16,4],
[43,41,16,5],
[43,41,16,6],
[43,41,16,8],
[43,41,16,10],
[43,41,16,11],
[43,41,16,12],
[43,41,16,13],
[43,41,16,14],
[43,41,17,0],
[43,41,17,1],
[43,41,17,2],
[43,41,17,3],
[43,41,17,4],
[43,41,17,5],
[43,41,17,6],
[43,41,17,8],
[43,41,17,10],
[43,41,17,11],
[43,41,17,12],
[43,41,17,13],
[43,41,17,14],
[43,41,17,16],
[43,41,19,0],
[43,41,19,1],
[43,41,19,2],
[43,41,19,3],
[43,41,19,4],
[43,41,19,5],
[43,41,19,6],
[43,41,19,8],
[43,41,19,10],
[43,41,19,11],
[43,41,19,12],
[43,41,19,16],
[43,41,19,17],
[43,41,20,0],
[43,41,20,1],
[43,41,20,2],
[43,41,20,3],
[43,41,20,4],
[43,41,20,5],
[43,41,20,6],
[43,41,20,8],
[43,41,20,11],
[43,41,20,12],
[43,41,20,13],
[43,41,20,14],
[43,41,20,16],
[43,41,20,17],
[43,41,20,19],
[43,41,21,0],
[43,41,21,1],
[43,41,21,2],
[43,41,21,4],
[43,41,21,10],
[43,41,21,11],
[43,41,21,12],
[43,41,21,13],
[43,41,21,14],
[43,41,21,16],
[43,41,21,17],
[43,41,21,19],
[43,41,21,20],
[43,41,22,0],
[43,41,22,1],
[43,41,22,2],
[43,41,22,3],
[43,41,22,4],
[43,41,22,5],
[43,41,22,6],
[43,41,22,8],
[43,41,22,11],
[43,41,22,12],
[43,41,22,13],
[43,41,22,14],
[43,41,22,16],
[43,41,22,17],
[43,41,22,19],
[43,41,22,21],
[43,41,23,0],
[43,41,23,1],
[43,41,23,2],
[43,41,23,3],
[43,41,23,4],
[43,41,23,5],
[43,41,23,6],
[43,41,23,8],
[43,41,23,10],
[43,41,23,11],
[43,41,23,12],
[43,41,23,13],
[43,41,23,14],
[43,41,23,16],
[43,41,23,17],
[43,41,23,19],
[43,41,23,20],
[43,41,23,21],
[43,41,24,0],
[43,41,24,1],
[43,41,24,2],
[43,41,24,3],
[43,41,24,4],
[43,41,24,5],
[43,41,24,6],
[43,41,24,8],
[43,41,24,10],
[43,41,24,11],
[43,41,24,12],
[43,41,24,13],
[43,41,24,14],
[43,41,24,17],
[43,41,24,19],
[43,41,24,20],
[43,41,24,21],
[43,41,24,23],
[43,41,27,0],
[43,41,27,1],
[43,41,27,2],
[43,41,27,3],
[43,41,27,4],
[43,41,27,5],
[43,41,27,6],
[43,41,27,8],
[43,41,27,11],
[43,41,27,12],
[43,41,27,13],
[43,41,27,14],
[43,41,27,16],
[43,41,27,17],
[43,41,27,19],
[43,41,27,21],
[43,41,27,23],
[43,41,27,24],
[43,41,29,0],
[43,41,29,1],
[43,41,29,2],
[43,41,29,3],
[43,41,29,4],
[43,41,29,5],
[43,41,29,6],
[43,41,29,8],
[43,41,29,10],
[43,41,29,13],
[43,41,29,14],
[43,41,29,16],
[43,41,29,17],
[43,41,29,19],
[43,41,29,20],
[43,41,29,21],
[43,41,29,22],
[43,41,29,23],
[43,41,29,24],
[43,41,29,27],
[43,41,30,0],
[43,41,30,1],
[43,41,30,2],
[43,41,30,3],
[43,41,30,4],
[43,41,30,5],
[43,41,30,6],
[43,41,30,8],
[43,41,30,10],
[43,41,30,11],
[43,41,30,12],
[43,41,30,13],
[43,41,30,16],
[43,41,30,17],
[43,41,30,19],
[43,41,30,20],
[43,41,30,21],
[43,41,30,22],
[43,41,30,24],
[43,41,30,27],
[43,41,31,0],
[43,41,31,1],
[43,41,31,2],
[43,41,31,3],
[43,41,31,4],
[43,41,31,5],
[43,41,31,6],
[43,41,31,8],
[43,41,31,10],
[43,41,31,11],
[43,41,31,14],
[43,41,31,16],
[43,41,31,17],
[43,41,31,19],
[43,41,31,20],
[43,41,31,21],
[43,41,31,22],
[43,41,31,24],
[43,41,32,0],
[43,41,32,1],
[43,41,32,2],
[43,41,32,3],
[43,41,32,4],
[43,41,32,5],
[43,41,32,6],
[43,41,32,8],
[43,41,32,13],
[43,41,32,14],
[43,41,32,16],
[43,41,32,17],
[43,41,32,19],
[43,41,32,20],
[43,41,32,21],
[43,41,32,22],
[43,41,32,23],
[43,41,32,24],
[43,41,33,0],
[43,41,33,1],
[43,41,33,2],
[43,41,33,3],
[43,41,33,4],
[43,41,33,5],
[43,41,33,6],
[43,41,33,10],
[43,41,33,13],
[43,41,33,14],
[43,41,33,16],
[43,41,33,17],
[43,41,33,19],
[43,41,33,20],
[43,41,33,21],
[43,41,33,22],
[43,41,33,23],
[43,41,33,24],
[43,41,36,0],
[43,41,36,1],
[43,41,36,2],
[43,41,36,5],
[43,41,36,6],
[43,41,36,8],
[43,41,36,10],
[43,41,36,11],
[43,41,36,12],
[43,41,36,16],
[43,41,36,17],
[43,41,36,20],
[43,41,36,21],
[43,41,37,3],
[43,41,37,5],
[43,41,37,6],
[43,41,37,8],
[43,41,37,10],
[43,41,37,11],
[43,41,37,12],
[43,41,37,13],
[43,41,37,14],
[43,41,37,16],
[43,41,37,17],
[43,41,37,19],
[43,41,37,20],
[43,41,39,0],
[43,41,39,1],
[43,41,39,2],
[43,41,39,4],
[43,41,39,10],
[43,41,39,11],
[43,41,39,12],
[43,41,39,13],
[43,41,39,14],
[43,41,39,16],
[43,41,39,17],
[43,42,3,0],
[43,42,3,1],
[43,42,3,2],
[43,42,4,0],
[43,42,4,1],
[43,42,4,2],
[43,42,4,3],
[43,42,5,0],
[43,42,5,1],
[43,42,5,2],
[43,42,5,4],
[43,42,6,0],
[43,42,6,1],
[43,42,6,2],
[43,42,6,4],
[43,42,8,0],
[43,42,8,1],
[43,42,8,2],
[43,42,8,4],
[43,42,9,0],
[43,42,9,1],
[43,42,9,2],
[43,42,9,3],
[43,42,9,4],
[43,42,9,5],
[43,42,9,6],
[43,42,9,8],
[43,42,11,0],
[43,42,11,1],
[43,42,11,2],
[43,42,11,3],
[43,42,11,4],
[43,42,11,5],
[43,42,11,6],
[43,42,11,8],
[43,42,11,9],
[43,42,12,0],
[43,42,12,1],
[43,42,12,2],
[43,42,12,3],
[43,42,12,4],
[43,42,12,5],
[43,42,12,6],
[43,42,12,8],
[43,42,12,9],
[43,42,13,0],
[43,42,13,1],
[43,42,13,2],
[43,42,13,3],
[43,42,13,4],
[43,42,13,5],
[43,42,13,6],
[43,42,13,8],
[43,42,13,9],
[43,42,13,11],
[43,42,13,12],
[43,42,14,0],
[43,42,14,1],
[43,42,14,2],
[43,42,14,3],
[43,42,14,4],
[43,42,14,5],
[43,42,14,6],
[43,42,14,8],
[43,42,14,9],
[43,42,14,11],
[43,42,14,12],
[43,42,15,0],
[43,42,15,1],
[43,42,15,2],
[43,42,15,3],
[43,42,15,4],
[43,42,15,5],
[43,42,15,6],
[43,42,15,8],
[43,42,15,11],
[43,42,15,12],
[43,42,15,13],
[43,42,15,14],
[43,42,16,0],
[43,42,16,1],
[43,42,16,2],
[43,42,16,3],
[43,42,16,4],
[43,42,16,5],
[43,42,16,6],
[43,42,16,8],
[43,42,16,9],
[43,42,16,11],
[43,42,16,12],
[43,42,16,13],
[43,42,16,14],
[43,42,16,15],
[43,42,17,0],
[43,42,17,1],
[43,42,17,2],
[43,42,17,3],
[43,42,17,4],
[43,42,17,5],
[43,42,17,6],
[43,42,17,8],
[43,42,17,9],
[43,42,17,11],
[43,42,17,12],
[43,42,17,13],
[43,42,17,14],
[43,42,17,15],
[43,42,17,16],
[43,42,18,0],
[43,42,18,1],
[43,42,18,2],
[43,42,18,3],
[43,42,18,4],
[43,42,18,5],
[43,42,18,6],
[43,42,18,8],
[43,42,18,11],
[43,42,18,12],
[43,42,18,13],
[43,42,18,14],
[43,42,18,16],
[43,42,18,17],
[43,42,19,0],
[43,42,19,1],
[43,42,19,2],
[43,42,19,3],
[43,42,19,4],
[43,42,19,5],
[43,42,19,6],
[43,42,19,8],
[43,42,19,9],
[43,42,19,11],
[43,42,19,12],
[43,42,19,15],
[43,42,19,16],
[43,42,19,17],
[43,42,19,18],
[43,42,21,0],
[43,42,21,1],
[43,42,21,2],
[43,42,21,4],
[43,42,21,9],
[43,42,21,11],
[43,42,21,12],
[43,42,21,13],
[43,42,21,14],
[43,42,21,15],
[43,42,21,16],
[43,42,21,17],
[43,42,21,18],
[43,42,21,19],
[43,42,23,0],
[43,42,23,1],
[43,42,23,2],
[43,42,23,3],
[43,42,23,4],
[43,42,23,5],
[43,42,23,6],
[43,42,23,8],
[43,42,23,9],
[43,42,23,11],
[43,42,23,12],
[43,42,23,13],
[43,42,23,14],
[43,42,23,15],
[43,42,23,16],
[43,42,23,17],
[43,42,23,18],
[43,42,23,19],
[43,42,24,0],
[43,42,24,1],
[43,42,24,2],
[43,42,24,3],
[43,42,24,4],
[43,42,24,5],
[43,42,24,6],
[43,42,24,8],
[43,42,24,9],
[43,42,24,11],
[43,42,24,12],
[43,42,24,13],
[43,42,24,14],
[43,42,24,15],
[43,42,24,17],
[43,42,24,18],
[43,42,24,19],
[43,42,24,23],
[43,42,26,0],
[43,42,26,1],
[43,42,26,2],
[43,42,26,3],
[43,42,26,4],
[43,42,26,5],
[43,42,26,6],
[43,42,26,8],
[43,42,26,11],
[43,42,26,12],
[43,42,26,13],
[43,42,26,14],
[43,42,26,16],
[43,42,26,17],
[43,42,26,19],
[43,42,26,21],
[43,42,26,23],
[43,42,26,24],
[43,42,29,0],
[43,42,29,1],
[43,42,29,2],
[43,42,29,3],
[43,42,29,4],
[43,42,29,5],
[43,42,29,6],
[43,42,29,8],
[43,42,29,9],
[43,42,29,13],
[43,42,29,15],
[43,42,29,16],
[43,42,29,17],
[43,42,29,18],
[43,42,29,19],
[43,42,29,21],
[43,42,29,23],
[43,42,29,24],
[43,42,29,26],
[43,42,30,0],
[43,42,30,1],
[43,42,30,2],
[43,42,30,3],
[43,42,30,4],
[43,42,30,5],
[43,42,30,6],
[43,42,30,8],
[43,42,30,9],
[43,42,30,11],
[43,42,30,14],
[43,42,30,15],
[43,42,30,16],
[43,42,30,17],
[43,42,30,18],
[43,42,30,19],
[43,42,30,21],
[43,42,30,24],
[43,42,30,26],
[43,42,31,0],
[43,42,31,1],
[43,42,31,2],
[43,42,31,3],
[43,42,31,4],
[43,42,31,5],
[43,42,31,6],
[43,42,31,8],
[43,42,31,9],
[43,42,31,12],
[43,42,31,13],
[43,42,31,14],
[43,42,31,15],
[43,42,31,16],
[43,42,31,17],
[43,42,31,18],
[43,42,31,19],
[43,42,31,21],
[43,42,31,24],
[43,42,32,0],
[43,42,32,1],
[43,42,32,2],
[43,42,32,3],
[43,42,32,4],
[43,42,32,5],
[43,42,32,6],
[43,42,32,13],
[43,42,32,14],
[43,42,32,15],
[43,42,32,16],
[43,42,32,17],
[43,42,32,18],
[43,42,32,19],
[43,42,32,21],
[43,42,32,23],
[43,42,32,24],
[43,42,33,0],
[43,42,33,1],
[43,42,33,2],
[43,42,33,3],
[43,42,33,4],
[43,42,33,5],
[43,42,33,8],
[43,42,33,9],
[43,42,33,13],
[43,42,33,14],
[43,42,33,15],
[43,42,33,16],
[43,42,33,17],
[43,42,33,18],
[43,42,33,19],
[43,42,33,21],
[43,42,33,23],
[43,42,33,24],
[43,42,35,0],
[43,42,35,1],
[43,42,35,2],
[43,42,35,5],
[43,42,35,6],
[43,42,35,8],
[43,42,35,11],
[43,42,35,12],
[43,42,35,13],
[43,42,35,14],
[43,42,35,16],
[43,42,35,17],
[43,42,35,19],
[43,42,35,21],
[43,42,36,3],
[43,42,36,4],
[43,42,36,5],
[43,42,36,6],
[43,42,36,8],
[43,42,36,9],
[43,42,36,11],
[43,42,36,12],
[43,42,36,15],
[43,42,36,16],
[43,42,36,17],
[43,42,36,18],
[43,42,37,0],
[43,42,37,1],
[43,42,37,2],
[43,42,37,3],
[43,42,37,5],
[43,42,37,6],
[43,42,37,8],
[43,42,37,9],
[43,42,37,11],
[43,42,37,12],
[43,42,37,13],
[43,42,37,14],
[43,42,37,15],
[43,42,37,16],
[43,42,37,17],
[43,42,37,18],
[43,42,37,19],
[43,42,39,0],
[43,42,39,1],
[43,42,39,2],
[43,42,39,4],
[43,42,39,9],
[43,42,39,11],
[43,42,39,12],
[43,42,39,13],
[43,42,39,14],
[43,42,39,15],
[43,42,39,16],
[43,42,39,17],
[43,42,40,0],
[43,42,40,1],
[43,42,40,2],
[43,42,40,3],
[43,42,40,4],
[43,42,40,5],
[43,42,40,6],
[43,42,40,8],
[43,42,40,11],
[43,42,40,12],
[43,42,40,13],
[43,42,40,14],
[43,42,41,0],
[43,42,41,1],
[43,42,41,2],
[43,42,41,3],
[43,42,41,4],
[43,42,41,5],
[43,42,41,6],
[43,42,41,8],
[43,42,41,11],
[43,42,41,12],
[43,42,41,13],
[43,42,41,14],
[44,4,3,0],
[44,4,3,1],
[44,4,3,2],
[44,5,4,0],
[44,5,4,1],
[44,5,4,2],
[44,6,4,0],
[44,6,4,1],
[44,6,4,2],
[44,7,3,0],
[44,7,3,1],
[44,7,3,2],
[44,7,4,0],
[44,7,4,1],
[44,7,4,2],
[44,7,4,3],
[44,7,5,0],
[44,7,5,1],
[44,7,5,2],
[44,7,5,4],
[44,7,6,0],
[44,7,6,1],
[44,7,6,2],
[44,7,6,4],
[44,8,4,0],
[44,8,4,1],
[44,8,4,2],
[44,8,7,0],
[44,8,7,1],
[44,8,7,2],
[44,8,7,4],
[44,9,3,0],
[44,9,3,1],
[44,9,3,2],
[44,9,4,0],
[44,9,4,1],
[44,9,4,2],
[44,9,4,3],
[44,9,5,0],
[44,9,5,1],
[44,9,5,2],
[44,9,5,4],
[44,9,6,0],
[44,9,6,1],
[44,9,6,2],
[44,9,6,4],
[44,9,7,0],
[44,9,7,1],
[44,9,7,2],
[44,9,7,3],
[44,9,7,4],
[44,9,7,5],
[44,9,7,6],
[44,9,8,0],
[44,9,8,1],
[44,9,8,2],
[44,9,8,4],
[44,9,8,7],
[44,10,3,0],
[44,10,3,1],
[44,10,3,2],
[44,10,4,0],
[44,10,4,1],
[44,10,4,2],
[44,10,4,3],
[44,10,5,0],
[44,10,5,1],
[44,10,5,2],
[44,10,5,4],
[44,10,6,0],
[44,10,6,1],
[44,10,6,2],
[44,10,6,4],
[44,10,7,0],
[44,10,7,1],
[44,10,7,2],
[44,10,7,3],
[44,10,7,4],
[44,10,7,5],
[44,10,7,6],
[44,10,8,0],
[44,10,8,1],
[44,10,8,2],
[44,10,8,4],
[44,10,8,7],
[44,10,9,0],
[44,10,9,1],
[44,10,9,2],
[44,10,9,3],
[44,10,9,4],
[44,10,9,5],
[44,10,9,6],
[44,10,9,7],
[44,10,9,8],
[44,11,3,0],
[44,11,3,1],
[44,11,3,2],
[44,11,4,0],
[44,11,4,1],
[44,11,4,2],
[44,11,4,3],
[44,11,5,0],
[44,11,5,1],
[44,11,5,2],
[44,11,5,4],
[44,11,6,0],
[44,11,6,1],
[44,11,6,2],
[44,11,6,4],
[44,11,7,0],
[44,11,7,1],
[44,11,7,2],
[44,11,7,3],
[44,11,7,4],
[44,11,7,5],
[44,11,7,6],
[44,11,8,0],
[44,11,8,1],
[44,11,8,2],
[44,11,8,4],
[44,11,8,7],
[44,11,9,0],
[44,11,9,1],
[44,11,9,2],
[44,11,9,3],
[44,11,9,4],
[44,11,9,5],
[44,11,9,6],
[44,11,9,7],
[44,11,9,8],
[44,11,10,0],
[44,11,10,1],
[44,11,10,2],
[44,11,10,3],
[44,11,10,4],
[44,11,10,5],
[44,11,10,6],
[44,11,10,7],
[44,11,10,8],
[44,11,10,9],
[44,12,3,0],
[44,12,3,1],
[44,12,3,2],
[44,12,4,0],
[44,12,4,1],
[44,12,4,2],
[44,12,4,3],
[44,12,5,0],
[44,12,5,1],
[44,12,5,2],
[44,12,5,4],
[44,12,6,0],
[44,12,6,1],
[44,12,6,2],
[44,12,6,4],
[44,12,7,0],
[44,12,7,1],
[44,12,7,2],
[44,12,7,3],
[44,12,7,4],
[44,12,7,5],
[44,12,7,6],
[44,12,8,0],
[44,12,8,1],
[44,12,8,2],
[44,12,8,4],
[44,12,8,7],
[44,12,9,0],
[44,12,9,1],
[44,12,9,2],
[44,12,9,3],
[44,12,9,4],
[44,12,9,5],
[44,12,9,6],
[44,12,9,7],
[44,12,9,8],
[44,12,10,0],
[44,12,10,1],
[44,12,10,2],
[44,12,10,3],
[44,12,10,4],
[44,12,10,5],
[44,12,10,6],
[44,12,10,7],
[44,12,10,8],
[44,12,10,9],
[44,13,3,0],
[44,13,3,1],
[44,13,3,2],
[44,13,4,0],
[44,13,4,1],
[44,13,4,2],
[44,13,4,3],
[44,13,5,0],
[44,13,5,1],
[44,13,5,2],
[44,13,5,4],
[44,13,6,0],
[44,13,6,1],
[44,13,6,2],
[44,13,6,4],
[44,13,7,0],
[44,13,7,1],
[44,13,7,2],
[44,13,7,3],
[44,13,7,4],
[44,13,7,5],
[44,13,7,6],
[44,13,8,0],
[44,13,8,1],
[44,13,8,2],
[44,13,8,4],
[44,13,8,7],
[44,13,9,0],
[44,13,9,1],
[44,13,9,2],
[44,13,9,3],
[44,13,9,4],
[44,13,9,5],
[44,13,9,6],
[44,13,9,7],
[44,13,9,8],
[44,13,10,0],
[44,13,10,1],
[44,13,10,2],
[44,13,10,3],
[44,13,10,4],
[44,13,10,5],
[44,13,10,6],
[44,13,10,7],
[44,13,10,8],
[44,13,10,9],
[44,13,11,0],
[44,13,11,1],
[44,13,11,2],
[44,13,11,3],
[44,13,11,4],
[44,13,11,5],
[44,13,11,6],
[44,13,11,7],
[44,13,11,8],
[44,13,11,9],
[44,13,11,10],
[44,13,12,0],
[44,13,12,1],
[44,13,12,2],
[44,13,12,3],
[44,13,12,4],
[44,13,12,5],
[44,13,12,6],
[44,13,12,7],
[44,13,12,8],
[44,13,12,9],
[44,13,12,10],
[44,14,3,0],
[44,14,3,1],
[44,14,3,2],
[44,14,4,0],
[44,14,4,1],
[44,14,4,2],
[44,14,4,3],
[44,14,5,0],
[44,14,5,1],
[44,14,5,2],
[44,14,5,4],
[44,14,6,0],
[44,14,6,1],
[44,14,6,2],
[44,14,6,4],
[44,14,7,0],
[44,14,7,1],
[44,14,7,2],
[44,14,7,3],
[44,14,7,4],
[44,14,7,5],
[44,14,7,6],
[44,14,8,0],
[44,14,8,1],
[44,14,8,2],
[44,14,8,4],
[44,14,8,7],
[44,14,9,0],
[44,14,9,1],
[44,14,9,2],
[44,14,9,3],
[44,14,9,4],
[44,14,9,5],
[44,14,9,6],
[44,14,9,7],
[44,14,9,8],
[44,14,10,0],
[44,14,10,1],
[44,14,10,2],
[44,14,10,3],
[44,14,10,4],
[44,14,10,5],
[44,14,10,6],
[44,14,10,7],
[44,14,10,8],
[44,14,10,9],
[44,14,11,0],
[44,14,11,1],
[44,14,11,2],
[44,14,11,3],
[44,14,11,4],
[44,14,11,5],
[44,14,11,6],
[44,14,11,7],
[44,14,11,8],
[44,14,11,9],
[44,14,11,10],
[44,14,12,0],
[44,14,12,1],
[44,14,12,2],
[44,14,12,3],
[44,14,12,4],
[44,14,12,5],
[44,14,12,6],
[44,14,12,7],
[44,14,12,8],
[44,14,12,9],
[44,14,12,10],
[44,15,3,0],
[44,15,3,1],
[44,15,3,2],
[44,15,4,0],
[44,15,4,1],
[44,15,4,2],
[44,15,4,3],
[44,15,5,0],
[44,15,5,1],
[44,15,5,2],
[44,15,5,4],
[44,15,6,0],
[44,15,6,1],
[44,15,6,2],
[44,15,6,4],
[44,15,7,0],
[44,15,7,1],
[44,15,7,2],
[44,15,7,3],
[44,15,7,4],
[44,15,7,5],
[44,15,7,6],
[44,15,8,0],
[44,15,8,1],
[44,15,8,2],
[44,15,8,4],
[44,15,8,7],
[44,15,10,0],
[44,15,10,1],
[44,15,10,2],
[44,15,10,3],
[44,15,10,4],
[44,15,10,5],
[44,15,10,6],
[44,15,10,7],
[44,15,10,8],
[44,15,11,0],
[44,15,11,1],
[44,15,11,2],
[44,15,11,3],
[44,15,11,4],
[44,15,11,5],
[44,15,11,6],
[44,15,11,7],
[44,15,11,8],
[44,15,11,10],
[44,15,12,0],
[44,15,12,1],
[44,15,12,2],
[44,15,12,3],
[44,15,12,4],
[44,15,12,5],
[44,15,12,6],
[44,15,12,7],
[44,15,12,8],
[44,15,12,10],
[44,15,13,0],
[44,15,13,1],
[44,15,13,2],
[44,15,13,3],
[44,15,13,4],
[44,15,13,5],
[44,15,13,6],
[44,15,13,7],
[44,15,13,8],
[44,15,13,10],
[44,15,13,11],
[44,15,13,12],
[44,15,14,0],
[44,15,14,1],
[44,15,14,2],
[44,15,14,3],
[44,15,14,4],
[44,15,14,5],
[44,15,14,6],
[44,15,14,7],
[44,15,14,8],
[44,15,14,10],
[44,15,14,11],
[44,15,14,12],
[44,16,3,0],
[44,16,3,1],
[44,16,3,2],
[44,16,4,0],
[44,16,4,1],
[44,16,4,2],
[44,16,4,3],
[44,16,5,0],
[44,16,5,1],
[44,16,5,2],
[44,16,5,4],
[44,16,6,0],
[44,16,6,1],
[44,16,6,2],
[44,16,6,4],
[44,16,7,0],
[44,16,7,1],
[44,16,7,2],
[44,16,7,3],
[44,16,7,4],
[44,16,7,5],
[44,16,7,6],
[44,16,8,0],
[44,16,8,1],
[44,16,8,2],
[44,16,8,4],
[44,16,8,7],
[44,16,9,0],
[44,16,9,1],
[44,16,9,2],
[44,16,9,3],
[44,16,9,4],
[44,16,9,5],
[44,16,9,6],
[44,16,9,7],
[44,16,9,8],
[44,16,10,0],
[44,16,10,1],
[44,16,10,2],
[44,16,10,3],
[44,16,10,4],
[44,16,10,5],
[44,16,10,6],
[44,16,10,7],
[44,16,10,8],
[44,16,10,9],
[44,16,11,0],
[44,16,11,1],
[44,16,11,2],
[44,16,11,3],
[44,16,11,4],
[44,16,11,5],
[44,16,11,6],
[44,16,11,7],
[44,16,11,8],
[44,16,11,9],
[44,16,11,10],
[44,16,12,0],
[44,16,12,1],
[44,16,12,2],
[44,16,12,3],
[44,16,12,4],
[44,16,12,5],
[44,16,12,6],
[44,16,12,7],
[44,16,12,8],
[44,16,12,9],
[44,16,12,10],
[44,16,13,0],
[44,16,13,1],
[44,16,13,2],
[44,16,13,3],
[44,16,13,4],
[44,16,13,5],
[44,16,13,6],
[44,16,13,7],
[44,16,13,8],
[44,16,13,9],
[44,16,13,10],
[44,16,13,11],
[44,16,13,12],
[44,16,14,0],
[44,16,14,1],
[44,16,14,2],
[44,16,14,3],
[44,16,14,4],
[44,16,14,5],
[44,16,14,6],
[44,16,14,7],
[44,16,14,8],
[44,16,14,9],
[44,16,14,10],
[44,16,14,11],
[44,16,14,12],
[44,16,15,0],
[44,16,15,1],
[44,16,15,2],
[44,16,15,3],
[44,16,15,4],
[44,16,15,5],
[44,16,15,6],
[44,16,15,7],
[44,16,15,8],
[44,16,15,10],
[44,16,15,11],
[44,16,15,12],
[44,16,15,13],
[44,16,15,14],
[44,18,3,0],
[44,18,3,1],
[44,18,3,2],
[44,18,4,0],
[44,18,4,1],
[44,18,4,2],
[44,18,4,3],
[44,18,5,0],
[44,18,5,1],
[44,18,5,2],
[44,18,5,4],
[44,18,6,0],
[44,18,6,1],
[44,18,6,2],
[44,18,6,4],
[44,18,7,0],
[44,18,7,1],
[44,18,7,2],
[44,18,7,3],
[44,18,7,4],
[44,18,7,5],
[44,18,7,6],
[44,18,8,0],
[44,18,8,1],
[44,18,8,2],
[44,18,8,4],
[44,18,8,7],
[44,18,10,0],
[44,18,10,1],
[44,18,10,2],
[44,18,10,3],
[44,18,10,4],
[44,18,10,5],
[44,18,10,6],
[44,18,10,7],
[44,18,10,8],
[44,18,11,0],
[44,18,11,1],
[44,18,11,2],
[44,18,11,3],
[44,18,11,4],
[44,18,11,5],
[44,18,11,6],
[44,18,11,7],
[44,18,11,8],
[44,18,11,10],
[44,18,12,0],
[44,18,12,1],
[44,18,12,2],
[44,18,12,3],
[44,18,12,4],
[44,18,12,5],
[44,18,12,6],
[44,18,12,7],
[44,18,12,8],
[44,18,12,10],
[44,18,13,0],
[44,18,13,1],
[44,18,13,2],
[44,18,13,3],
[44,18,13,4],
[44,18,13,5],
[44,18,13,6],
[44,18,13,7],
[44,18,13,8],
[44,18,13,10],
[44,18,13,11],
[44,18,13,12],
[44,18,14,0],
[44,18,14,1],
[44,18,14,2],
[44,18,14,3],
[44,18,14,4],
[44,18,14,5],
[44,18,14,6],
[44,18,14,7],
[44,18,14,8],
[44,18,14,10],
[44,18,14,11],
[44,18,14,12],
[44,18,16,0],
[44,18,16,1],
[44,18,16,2],
[44,18,16,3],
[44,18,16,4],
[44,18,16,5],
[44,18,16,6],
[44,18,16,7],
[44,18,16,8],
[44,18,16,10],
[44,18,16,11],
[44,18,16,12],
[44,18,16,13],
[44,18,16,14],
[44,19,3,0],
[44,19,3,1],
[44,19,3,2],
[44,19,4,0],
[44,19,4,1],
[44,19,4,2],
[44,19,4,3],
[44,19,5,0],
[44,19,5,1],
[44,19,5,2],
[44,19,5,4],
[44,19,6,0],
[44,19,6,1],
[44,19,6,2],
[44,19,6,4],
[44,19,7,0],
[44,19,7,1],
[44,19,7,2],
[44,19,7,3],
[44,19,7,4],
[44,19,7,5],
[44,19,7,6],
[44,19,8,0],
[44,19,8,1],
[44,19,8,2],
[44,19,8,4],
[44,19,8,7],
[44,19,9,0],
[44,19,9,1],
[44,19,9,2],
[44,19,9,3],
[44,19,9,4],
[44,19,9,5],
[44,19,9,6],
[44,19,9,7],
[44,19,9,8],
[44,19,10,0],
[44,19,10,1],
[44,19,10,2],
[44,19,10,3],
[44,19,10,4],
[44,19,10,5],
[44,19,10,6],
[44,19,10,7],
[44,19,10,8],
[44,19,10,9],
[44,19,11,0],
[44,19,11,1],
[44,19,11,2],
[44,19,11,3],
[44,19,11,4],
[44,19,11,5],
[44,19,11,6],
[44,19,11,7],
[44,19,11,8],
[44,19,11,9],
[44,19,11,10],
[44,19,12,0],
[44,19,12,1],
[44,19,12,2],
[44,19,12,3],
[44,19,12,4],
[44,19,12,5],
[44,19,12,6],
[44,19,12,7],
[44,19,12,8],
[44,19,12,9],
[44,19,12,10],
[44,19,15,0],
[44,19,15,1],
[44,19,15,2],
[44,19,15,3],
[44,19,15,4],
[44,19,15,5],
[44,19,15,6],
[44,19,15,7],
[44,19,15,8],
[44,19,15,10],
[44,19,15,11],
[44,19,15,12],
[44,19,16,0],
[44,19,16,1],
[44,19,16,2],
[44,19,16,3],
[44,19,16,4],
[44,19,16,5],
[44,19,16,6],
[44,19,16,7],
[44,19,16,8],
[44,19,16,9],
[44,19,16,10],
[44,19,16,11],
[44,19,16,12],
[44,19,16,15],
[44,19,18,0],
[44,19,18,1],
[44,19,18,2],
[44,19,18,3],
[44,19,18,4],
[44,19,18,5],
[44,19,18,6],
[44,19,18,7],
[44,19,18,8],
[44,19,18,10],
[44,19,18,11],
[44,19,18,12],
[44,19,18,16],
[44,20,3,0],
[44,20,3,1],
[44,20,3,2],
[44,20,4,0],
[44,20,4,1],
[44,20,4,2],
[44,20,4,3],
[44,20,5,0],
[44,20,5,1],
[44,20,5,2],
[44,20,5,4],
[44,20,6,0],
[44,20,6,1],
[44,20,6,2],
[44,20,6,4],
[44,20,7,0],
[44,20,7,1],
[44,20,7,2],
[44,20,7,3],
[44,20,7,4],
[44,20,7,5],
[44,20,7,6],
[44,20,8,0],
[44,20,8,1],
[44,20,8,2],
[44,20,8,4],
[44,20,8,7],
[44,20,9,0],
[44,20,9,1],
[44,20,9,2],
[44,20,9,3],
[44,20,9,4],
[44,20,9,5],
[44,20,9,6],
[44,20,9,7],
[44,20,9,8],
[44,20,11,0],
[44,20,11,1],
[44,20,11,2],
[44,20,11,3],
[44,20,11,4],
[44,20,11,5],
[44,20,11,6],
[44,20,11,7],
[44,20,11,8],
[44,20,11,9],
[44,20,12,0],
[44,20,12,1],
[44,20,12,2],
[44,20,12,3],
[44,20,12,4],
[44,20,12,5],
[44,20,12,6],
[44,20,12,7],
[44,20,12,8],
[44,20,12,9],
[44,20,13,0],
[44,20,13,1],
[44,20,13,2],
[44,20,13,3],
[44,20,13,4],
[44,20,13,5],
[44,20,13,6],
[44,20,13,7],
[44,20,13,8],
[44,20,13,9],
[44,20,13,11],
[44,20,13,12],
[44,20,14,0],
[44,20,14,1],
[44,20,14,2],
[44,20,14,3],
[44,20,14,4],
[44,20,14,5],
[44,20,14,6],
[44,20,14,7],
[44,20,14,8],
[44,20,14,9],
[44,20,14,11],
[44,20,14,12],
[44,20,15,0],
[44,20,15,1],
[44,20,15,2],
[44,20,15,3],
[44,20,15,4],
[44,20,15,5],
[44,20,15,6],
[44,20,15,7],
[44,20,15,8],
[44,20,15,11],
[44,20,15,12],
[44,20,15,13],
[44,20,15,14],
[44,20,16,0],
[44,20,16,1],
[44,20,16,2],
[44,20,16,3],
[44,20,16,4],
[44,20,16,5],
[44,20,16,6],
[44,20,16,7],
[44,20,16,8],
[44,20,16,9],
[44,20,16,11],
[44,20,16,12],
[44,20,16,13],
[44,20,16,14],
[44,20,16,15],
[44,20,18,0],
[44,20,18,1],
[44,20,18,2],
[44,20,18,3],
[44,20,18,4],
[44,20,18,5],
[44,20,18,6],
[44,20,18,7],
[44,20,18,8],
[44,20,18,11],
[44,20,18,12],
[44,20,18,13],
[44,20,18,14],
[44,20,18,16],
[44,20,19,0],
[44,20,19,1],
[44,20,19,2],
[44,20,19,3],
[44,20,19,4],
[44,20,19,5],
[44,20,19,6],
[44,20,19,7],
[44,20,19,8],
[44,20,19,9],
[44,20,19,11],
[44,20,19,12],
[44,20,19,15],
[44,20,19,16],
[44,20,19,18],
[44,21,4,0],
[44,21,4,1],
[44,21,4,2],
[44,21,7,0],
[44,21,7,1],
[44,21,7,2],
[44,21,7,4],
[44,21,9,0],
[44,21,9,1],
[44,21,9,2],
[44,21,9,4],
[44,21,9,7],
[44,21,10,0],
[44,21,10,1],
[44,21,10,2],
[44,21,10,4],
[44,21,10,7],
[44,21,10,9],
[44,21,11,0],
[44,21,11,1],
[44,21,11,2],
[44,21,11,4],
[44,21,11,7],
[44,21,11,9],
[44,21,11,10],
[44,21,12,0],
[44,21,12,1],
[44,21,12,2],
[44,21,12,4],
[44,21,12,7],
[44,21,12,9],
[44,21,12,10],
[44,21,13,0],
[44,21,13,1],
[44,21,13,2],
[44,21,13,4],
[44,21,13,7],
[44,21,13,9],
[44,21,13,10],
[44,21,13,11],
[44,21,13,12],
[44,21,14,0],
[44,21,14,1],
[44,21,14,2],
[44,21,14,4],
[44,21,14,7],
[44,21,14,9],
[44,21,14,10],
[44,21,14,11],
[44,21,14,12],
[44,21,15,0],
[44,21,15,1],
[44,21,15,2],
[44,21,15,4],
[44,21,15,7],
[44,21,15,10],
[44,21,15,11],
[44,21,15,12],
[44,21,15,13],
[44,21,15,14],
[44,21,16,0],
[44,21,16,1],
[44,21,16,2],
[44,21,16,4],
[44,21,16,7],
[44,21,16,9],
[44,21,16,10],
[44,21,16,11],
[44,21,16,12],
[44,21,16,13],
[44,21,16,14],
[44,21,16,15],
[44,21,18,0],
[44,21,18,1],
[44,21,18,2],
[44,21,18,4],
[44,21,18,7],
[44,21,18,10],
[44,21,18,11],
[44,21,18,12],
[44,21,18,13],
[44,21,18,14],
[44,21,18,16],
[44,21,19,0],
[44,21,19,1],
[44,21,19,2],
[44,21,19,4],
[44,21,19,7],
[44,21,19,9],
[44,21,19,10],
[44,21,19,11],
[44,21,19,12],
[44,21,19,15],
[44,21,19,16],
[44,21,19,18],
[44,21,20,0],
[44,21,20,1],
[44,21,20,2],
[44,21,20,4],
[44,21,20,7],
[44,21,20,9],
[44,21,20,11],
[44,21,20,12],
[44,21,20,13],
[44,21,20,14],
[44,21,20,15],
[44,21,20,16],
[44,21,20,18],
[44,21,20,19],
[44,22,3,0],
[44,22,3,1],
[44,22,3,2],
[44,22,4,0],
[44,22,4,1],
[44,22,4,2],
[44,22,4,3],
[44,22,5,0],
[44,22,5,1],
[44,22,5,2],
[44,22,5,4],
[44,22,6,0],
[44,22,6,1],
[44,22,6,2],
[44,22,6,4],
[44,22,7,0],
[44,22,7,1],
[44,22,7,2],
[44,22,7,3],
[44,22,7,4],
[44,22,7,5],
[44,22,7,6],
[44,22,8,0],
[44,22,8,1],
[44,22,8,2],
[44,22,8,4],
[44,22,8,7],
[44,22,9,0],
[44,22,9,1],
[44,22,9,2],
[44,22,9,3],
[44,22,9,4],
[44,22,9,5],
[44,22,9,6],
[44,22,9,7],
[44,22,9,8],
[44,22,11,0],
[44,22,11,1],
[44,22,11,2],
[44,22,11,3],
[44,22,11,4],
[44,22,11,5],
[44,22,11,6],
[44,22,11,7],
[44,22,11,8],
[44,22,11,9],
[44,22,12,0],
[44,22,12,1],
[44,22,12,2],
[44,22,12,3],
[44,22,12,4],
[44,22,12,5],
[44,22,12,6],
[44,22,12,7],
[44,22,12,8],
[44,22,12,9],
[44,22,13,0],
[44,22,13,1],
[44,22,13,2],
[44,22,13,3],
[44,22,13,4],
[44,22,13,5],
[44,22,13,6],
[44,22,13,7],
[44,22,13,8],
[44,22,13,9],
[44,22,13,11],
[44,22,13,12],
[44,22,14,0],
[44,22,14,1],
[44,22,14,2],
[44,22,14,3],
[44,22,14,4],
[44,22,14,5],
[44,22,14,6],
[44,22,14,7],
[44,22,14,8],
[44,22,14,9],
[44,22,14,11],
[44,22,14,12],
[44,22,15,0],
[44,22,15,1],
[44,22,15,2],
[44,22,15,3],
[44,22,15,4],
[44,22,15,5],
[44,22,15,6],
[44,22,15,7],
[44,22,15,8],
[44,22,15,11],
[44,22,15,12],
[44,22,15,13],
[44,22,15,14],
[44,22,16,0],
[44,22,16,1],
[44,22,16,2],
[44,22,16,3],
[44,22,16,4],
[44,22,16,5],
[44,22,16,6],
[44,22,16,7],
[44,22,16,8],
[44,22,16,9],
[44,22,16,11],
[44,22,16,12],
[44,22,16,13],
[44,22,16,14],
[44,22,16,15],
[44,22,18,0],
[44,22,18,1],
[44,22,18,2],
[44,22,18,3],
[44,22,18,4],
[44,22,18,5],
[44,22,18,6],
[44,22,18,7],
[44,22,18,8],
[44,22,18,11],
[44,22,18,12],
[44,22,18,13],
[44,22,18,14],
[44,22,18,16],
[44,22,19,0],
[44,22,19,1],
[44,22,19,2],
[44,22,19,3],
[44,22,19,4],
[44,22,19,5],
[44,22,19,6],
[44,22,19,7],
[44,22,19,8],
[44,22,19,9],
[44,22,19,11],
[44,22,19,12],
[44,22,19,15],
[44,22,19,16],
[44,22,19,18],
[44,22,21,0],
[44,22,21,1],
[44,22,21,2],
[44,22,21,4],
[44,22,21,7],
[44,22,21,9],
[44,22,21,11],
[44,22,21,12],
[44,22,21,13],
[44,22,21,14],
[44,22,21,15],
[44,22,21,16],
[44,22,21,18],
[44,22,21,19],
[44,23,3,0],
[44,23,3,1],
[44,23,3,2],
[44,23,4,0],
[44,23,4,1],
[44,23,4,2],
[44,23,4,3],
[44,23,5,0],
[44,23,5,1],
[44,23,5,2],
[44,23,5,4],
[44,23,6,0],
[44,23,6,1],
[44,23,6,2],
[44,23,6,4],
[44,23,7,0],
[44,23,7,1],
[44,23,7,2],
[44,23,7,3],
[44,23,7,4],
[44,23,7,5],
[44,23,7,6],
[44,23,8,0],
[44,23,8,1],
[44,23,8,2],
[44,23,8,4],
[44,23,8,7],
[44,23,9,0],
[44,23,9,1],
[44,23,9,2],
[44,23,9,3],
[44,23,9,4],
[44,23,9,5],
[44,23,9,6],
[44,23,9,7],
[44,23,9,8],
[44,23,10,0],
[44,23,10,1],
[44,23,10,2],
[44,23,10,3],
[44,23,10,4],
[44,23,10,5],
[44,23,10,6],
[44,23,10,7],
[44,23,10,8],
[44,23,10,9],
[44,23,11,0],
[44,23,11,1],
[44,23,11,2],
[44,23,11,3],
[44,23,11,4],
[44,23,11,5],
[44,23,11,6],
[44,23,11,7],
[44,23,11,8],
[44,23,11,9],
[44,23,11,10],
[44,23,12,0],
[44,23,12,1],
[44,23,12,2],
[44,23,12,3],
[44,23,12,4],
[44,23,12,5],
[44,23,12,6],
[44,23,12,7],
[44,23,12,8],
[44,23,12,9],
[44,23,12,10],
[44,23,13,0],
[44,23,13,1],
[44,23,13,2],
[44,23,13,3],
[44,23,13,4],
[44,23,13,5],
[44,23,13,6],
[44,23,13,7],
[44,23,13,8],
[44,23,13,9],
[44,23,13,10],
[44,23,13,11],
[44,23,13,12],
[44,23,14,0],
[44,23,14,1],
[44,23,14,2],
[44,23,14,3],
[44,23,14,4],
[44,23,14,5],
[44,23,14,6],
[44,23,14,7],
[44,23,14,8],
[44,23,14,9],
[44,23,14,10],
[44,23,14,11],
[44,23,14,12],
[44,23,15,0],
[44,23,15,1],
[44,23,15,2],
[44,23,15,3],
[44,23,15,4],
[44,23,15,5],
[44,23,15,6],
[44,23,15,7],
[44,23,15,8],
[44,23,15,10],
[44,23,15,11],
[44,23,15,12],
[44,23,15,13],
[44,23,15,14],
[44,23,16,0],
[44,23,16,1],
[44,23,16,2],
[44,23,16,3],
[44,23,16,4],
[44,23,16,5],
[44,23,16,6],
[44,23,16,7],
[44,23,16,8],
[44,23,16,9],
[44,23,16,10],
[44,23,16,11],
[44,23,16,12],
[44,23,16,13],
[44,23,16,14],
[44,23,16,15],
[44,23,18,0],
[44,23,18,1],
[44,23,18,2],
[44,23,18,3],
[44,23,18,4],
[44,23,18,5],
[44,23,18,6],
[44,23,18,7],
[44,23,18,8],
[44,23,18,10],
[44,23,18,11],
[44,23,18,12],
[44,23,18,13],
[44,23,18,14],
[44,23,18,16],
[44,23,19,0],
[44,23,19,1],
[44,23,19,2],
[44,23,19,3],
[44,23,19,4],
[44,23,19,5],
[44,23,19,6],
[44,23,19,7],
[44,23,19,8],
[44,23,19,9],
[44,23,19,10],
[44,23,19,11],
[44,23,19,12],
[44,23,19,15],
[44,23,19,16],
[44,23,19,18],
[44,23,20,0],
[44,23,20,1],
[44,23,20,2],
[44,23,20,3],
[44,23,20,4],
[44,23,20,5],
[44,23,20,6],
[44,23,20,7],
[44,23,20,8],
[44,23,20,9],
[44,23,20,11],
[44,23,20,12],
[44,23,20,13],
[44,23,20,14],
[44,23,20,15],
[44,23,20,16],
[44,23,20,18],
[44,23,20,19],
[44,23,21,0],
[44,23,21,1],
[44,23,21,2],
[44,23,21,4],
[44,23,21,7],
[44,23,21,9],
[44,23,21,10],
[44,23,21,11],
[44,23,21,12],
[44,23,21,13],
[44,23,21,14],
[44,23,21,15],
[44,23,21,16],
[44,23,21,18],
[44,23,21,19],
[44,23,21,20],
[44,23,22,0],
[44,23,22,1],
[44,23,22,2],
[44,23,22,3],
[44,23,22,4],
[44,23,22,5],
[44,23,22,6],
[44,23,22,7],
[44,23,22,8],
[44,23,22,9],
[44,23,22,11],
[44,23,22,12],
[44,23,22,13],
[44,23,22,14],
[44,23,22,15],
[44,23,22,16],
[44,23,22,18],
[44,23,22,19],
[44,23,22,21],
[44,24,3,0],
[44,24,3,1],
[44,24,3,2],
[44,24,4,0],
[44,24,4,1],
[44,24,4,2],
[44,24,4,3],
[44,24,5,0],
[44,24,5,1],
[44,24,5,2],
[44,24,5,4],
[44,24,6,0],
[44,24,6,1],
[44,24,6,2],
[44,24,6,4],
[44,24,7,0],
[44,24,7,1],
[44,24,7,2],
[44,24,7,3],
[44,24,7,4],
[44,24,7,5],
[44,24,7,6],
[44,24,8,0],
[44,24,8,1],
[44,24,8,2],
[44,24,8,4],
[44,24,8,7],
[44,24,9,0],
[44,24,9,1],
[44,24,9,2],
[44,24,9,3],
[44,24,9,4],
[44,24,9,5],
[44,24,9,6],
[44,24,9,7],
[44,24,9,8],
[44,24,10,0],
[44,24,10,1],
[44,24,10,2],
[44,24,10,3],
[44,24,10,4],
[44,24,10,5],
[44,24,10,6],
[44,24,10,7],
[44,24,10,8],
[44,24,10,9],
[44,24,11,0],
[44,24,11,1],
[44,24,11,2],
[44,24,11,3],
[44,24,11,4],
[44,24,11,5],
[44,24,11,6],
[44,24,11,7],
[44,24,11,8],
[44,24,11,9],
[44,24,11,10],
[44,24,12,0],
[44,24,12,1],
[44,24,12,2],
[44,24,12,3],
[44,24,12,4],
[44,24,12,5],
[44,24,12,6],
[44,24,12,7],
[44,24,12,8],
[44,24,12,9],
[44,24,12,10],
[44,24,13,0],
[44,24,13,1],
[44,24,13,2],
[44,24,13,3],
[44,24,13,4],
[44,24,13,5],
[44,24,13,6],
[44,24,13,7],
[44,24,13,8],
[44,24,13,9],
[44,24,13,10],
[44,24,13,11],
[44,24,13,12],
[44,24,14,0],
[44,24,14,1],
[44,24,14,2],
[44,24,14,3],
[44,24,14,4],
[44,24,14,5],
[44,24,14,6],
[44,24,14,7],
[44,24,14,8],
[44,24,14,9],
[44,24,14,10],
[44,24,14,11],
[44,24,14,12],
[44,24,15,0],
[44,24,15,1],
[44,24,15,2],
[44,24,15,3],
[44,24,15,4],
[44,24,15,5],
[44,24,15,6],
[44,24,15,7],
[44,24,15,8],
[44,24,15,10],
[44,24,15,11],
[44,24,15,12],
[44,24,15,13],
[44,24,15,14],
[44,24,18,0],
[44,24,18,1],
[44,24,18,2],
[44,24,18,3],
[44,24,18,4],
[44,24,18,5],
[44,24,18,6],
[44,24,18,7],
[44,24,18,8],
[44,24,18,10],
[44,24,18,11],
[44,24,18,12],
[44,24,18,13],
[44,24,18,14],
[44,24,19,0],
[44,24,19,1],
[44,24,19,2],
[44,24,19,3],
[44,24,19,4],
[44,24,19,5],
[44,24,19,6],
[44,24,19,7],
[44,24,19,8],
[44,24,19,9],
[44,24,19,10],
[44,24,19,11],
[44,24,19,12],
[44,24,19,15],
[44,24,19,18],
[44,24,20,0],
[44,24,20,1],
[44,24,20,2],
[44,24,20,3],
[44,24,20,4],
[44,24,20,5],
[44,24,20,6],
[44,24,20,7],
[44,24,20,8],
[44,24,20,9],
[44,24,20,11],
[44,24,20,12],
[44,24,20,13],
[44,24,20,14],
[44,24,20,15],
[44,24,20,18],
[44,24,20,19],
[44,24,21,0],
[44,24,21,1],
[44,24,21,2],
[44,24,21,4],
[44,24,21,7],
[44,24,21,9],
[44,24,21,10],
[44,24,21,11],
[44,24,21,12],
[44,24,21,13],
[44,24,21,14],
[44,24,21,15],
[44,24,21,18],
[44,24,21,19],
[44,24,21,20],
[44,24,22,0],
[44,24,22,1],
[44,24,22,2],
[44,24,22,3],
[44,24,22,4],
[44,24,22,5],
[44,24,22,6],
[44,24,22,7],
[44,24,22,8],
[44,24,22,9],
[44,24,22,11],
[44,24,22,12],
[44,24,22,13],
[44,24,22,14],
[44,24,22,15],
[44,24,22,18],
[44,24,22,19],
[44,24,22,21],
[44,24,23,0],
[44,24,23,1],
[44,24,23,2],
[44,24,23,3],
[44,24,23,4],
[44,24,23,5],
[44,24,23,6],
[44,24,23,7],
[44,24,23,8],
[44,24,23,9],
[44,24,23,10],
[44,24,23,11],
[44,24,23,12],
[44,24,23,13],
[44,24,23,14],
[44,24,23,15],
[44,24,23,18],
[44,24,23,19],
[44,24,23,20],
[44,24,23,21],
[44,24,23,22],
[44,25,3,0],
[44,25,3,1],
[44,25,3,2],
[44,25,4,0],
[44,25,4,1],
[44,25,4,2],
[44,25,4,3],
[44,25,5,0],
[44,25,5,1],
[44,25,5,2],
[44,25,5,4],
[44,25,6,0],
[44,25,6,1],
[44,25,6,2],
[44,25,6,4],
[44,25,8,0],
[44,25,8,1],
[44,25,8,2],
[44,25,8,4],
[44,25,9,0],
[44,25,9,1],
[44,25,9,2],
[44,25,9,3],
[44,25,9,4],
[44,25,9,5],
[44,25,9,6],
[44,25,9,8],
[44,25,10,0],
[44,25,10,1],
[44,25,10,2],
[44,25,10,3],
[44,25,10,4],
[44,25,10,5],
[44,25,10,6],
[44,25,10,8],
[44,25,10,9],
[44,25,11,0],
[44,25,11,1],
[44,25,11,2],
[44,25,11,3],
[44,25,11,4],
[44,25,11,5],
[44,25,11,6],
[44,25,11,8],
[44,25,11,9],
[44,25,11,10],
[44,25,12,0],
[44,25,12,1],
[44,25,12,2],
[44,25,12,3],
[44,25,12,4],
[44,25,12,5],
[44,25,12,6],
[44,25,12,8],
[44,25,12,9],
[44,25,12,10],
[44,25,13,0],
[44,25,13,1],
[44,25,13,2],
[44,25,13,3],
[44,25,13,4],
[44,25,13,5],
[44,25,13,6],
[44,25,13,8],
[44,25,13,9],
[44,25,13,10],
[44,25,13,11],
[44,25,13,12],
[44,25,14,0],
[44,25,14,1],
[44,25,14,2],
[44,25,14,3],
[44,25,14,4],
[44,25,14,5],
[44,25,14,6],
[44,25,14,8],
[44,25,14,9],
[44,25,14,10],
[44,25,14,11],
[44,25,14,12],
[44,25,15,0],
[44,25,15,1],
[44,25,15,2],
[44,25,15,3],
[44,25,15,4],
[44,25,15,5],
[44,25,15,6],
[44,25,15,8],
[44,25,15,10],
[44,25,15,11],
[44,25,15,12],
[44,25,15,13],
[44,25,15,14],
[44,25,16,0],
[44,25,16,1],
[44,25,16,2],
[44,25,16,3],
[44,25,16,4],
[44,25,16,5],
[44,25,16,6],
[44,25,16,8],
[44,25,16,9],
[44,25,16,10],
[44,25,16,11],
[44,25,16,12],
[44,25,16,13],
[44,25,16,14],
[44,25,16,15],
[44,25,18,0],
[44,25,18,1],
[44,25,18,2],
[44,25,18,3],
[44,25,18,4],
[44,25,18,5],
[44,25,18,6],
[44,25,18,8],
[44,25,18,10],
[44,25,18,11],
[44,25,18,12],
[44,25,18,13],
[44,25,18,14],
[44,25,18,16],
[44,25,19,0],
[44,25,19,1],
[44,25,19,2],
[44,25,19,3],
[44,25,19,4],
[44,25,19,5],
[44,25,19,6],
[44,25,19,8],
[44,25,19,9],
[44,25,19,10],
[44,25,19,11],
[44,25,19,12],
[44,25,19,15],
[44,25,19,16],
[44,25,19,18],
[44,25,20,0],
[44,25,20,1],
[44,25,20,2],
[44,25,20,3],
[44,25,20,4],
[44,25,20,5],
[44,25,20,6],
[44,25,20,8],
[44,25,20,9],
[44,25,20,11],
[44,25,20,12],
[44,25,20,13],
[44,25,20,14],
[44,25,20,15],
[44,25,20,16],
[44,25,20,18],
[44,25,20,19],
[44,25,21,0],
[44,25,21,1],
[44,25,21,2],
[44,25,21,4],
[44,25,21,9],
[44,25,21,10],
[44,25,21,11],
[44,25,21,12],
[44,25,21,13],
[44,25,21,14],
[44,25,21,15],
[44,25,21,16],
[44,25,21,18],
[44,25,21,19],
[44,25,21,20],
[44,25,22,0],
[44,25,22,1],
[44,25,22,2],
[44,25,22,3],
[44,25,22,4],
[44,25,22,5],
[44,25,22,6],
[44,25,22,8],
[44,25,22,9],
[44,25,22,11],
[44,25,22,12],
[44,25,22,13],
[44,25,22,14],
[44,25,22,15],
[44,25,22,16],
[44,25,22,18],
[44,25,22,19],
[44,25,22,21],
[44,25,23,0],
[44,25,23,1],
[44,25,23,2],
[44,25,23,3],
[44,25,23,4],
[44,25,23,5],
[44,25,23,6],
[44,25,23,8],
[44,25,23,9],
[44,25,23,10],
[44,25,23,11],
[44,25,23,12],
[44,25,23,13],
[44,25,23,14],
[44,25,23,15],
[44,25,23,16],
[44,25,23,18],
[44,25,23,19],
[44,25,23,20],
[44,25,23,21],
[44,25,23,22],
[44,25,24,0],
[44,25,24,1],
[44,25,24,2],
[44,25,24,3],
[44,25,24,4],
[44,25,24,5],
[44,25,24,6],
[44,25,24,8],
[44,25,24,9],
[44,25,24,10],
[44,25,24,11],
[44,25,24,12],
[44,25,24,13],
[44,25,24,14],
[44,25,24,15],
[44,25,24,18],
[44,25,24,19],
[44,25,24,20],
[44,25,24,21],
[44,25,24,22],
[44,25,24,23],
[44,26,3,0],
[44,26,3,1],
[44,26,3,2],
[44,26,4,0],
[44,26,4,1],
[44,26,4,2],
[44,26,4,3],
[44,26,5,0],
[44,26,5,1],
[44,26,5,2],
[44,26,5,4],
[44,26,6,0],
[44,26,6,1],
[44,26,6,2],
[44,26,6,4],
[44,26,7,0],
[44,26,7,1],
[44,26,7,2],
[44,26,7,3],
[44,26,7,4],
[44,26,7,5],
[44,26,7,6],
[44,26,8,0],
[44,26,8,1],
[44,26,8,2],
[44,26,8,4],
[44,26,8,7],
[44,26,10,0],
[44,26,10,1],
[44,26,10,2],
[44,26,10,3],
[44,26,10,4],
[44,26,10,5],
[44,26,10,6],
[44,26,10,7],
[44,26,10,8],
[44,26,11,0],
[44,26,11,1],
[44,26,11,2],
[44,26,11,3],
[44,26,11,4],
[44,26,11,5],
[44,26,11,6],
[44,26,11,7],
[44,26,11,8],
[44,26,11,10],
[44,26,12,0],
[44,26,12,1],
[44,26,12,2],
[44,26,12,3],
[44,26,12,4],
[44,26,12,5],
[44,26,12,6],
[44,26,12,7],
[44,26,12,8],
[44,26,12,10],
[44,26,13,0],
[44,26,13,1],
[44,26,13,2],
[44,26,13,3],
[44,26,13,4],
[44,26,13,5],
[44,26,13,6],
[44,26,13,7],
[44,26,13,8],
[44,26,13,10],
[44,26,13,11],
[44,26,13,12],
[44,26,14,0],
[44,26,14,1],
[44,26,14,2],
[44,26,14,3],
[44,26,14,4],
[44,26,14,5],
[44,26,14,6],
[44,26,14,7],
[44,26,14,8],
[44,26,14,10],
[44,26,14,11],
[44,26,14,12],
[44,26,16,0],
[44,26,16,1],
[44,26,16,2],
[44,26,16,3],
[44,26,16,4],
[44,26,16,5],
[44,26,16,6],
[44,26,16,7],
[44,26,16,8],
[44,26,16,10],
[44,26,16,11],
[44,26,16,12],
[44,26,16,13],
[44,26,16,14],
[44,26,19,0],
[44,26,19,1],
[44,26,19,2],
[44,26,19,3],
[44,26,19,4],
[44,26,19,5],
[44,26,19,6],
[44,26,19,7],
[44,26,19,8],
[44,26,19,10],
[44,26,19,11],
[44,26,19,12],
[44,26,19,16],
[44,26,20,0],
[44,26,20,1],
[44,26,20,2],
[44,26,20,3],
[44,26,20,4],
[44,26,20,5],
[44,26,20,6],
[44,26,20,7],
[44,26,20,8],
[44,26,20,11],
[44,26,20,12],
[44,26,20,13],
[44,26,20,14],
[44,26,20,16],
[44,26,20,19],
[44,26,21,0],
[44,26,21,1],
[44,26,21,2],
[44,26,21,4],
[44,26,21,7],
[44,26,21,10],
[44,26,21,11],
[44,26,21,12],
[44,26,21,13],
[44,26,21,14],
[44,26,21,16],
[44,26,21,19],
[44,26,21,20],
[44,26,22,0],
[44,26,22,1],
[44,26,22,2],
[44,26,22,3],
[44,26,22,4],
[44,26,22,5],
[44,26,22,6],
[44,26,22,7],
[44,26,22,8],
[44,26,22,11],
[44,26,22,12],
[44,26,22,13],
[44,26,22,14],
[44,26,22,16],
[44,26,22,19],
[44,26,22,21],
[44,26,23,0],
[44,26,23,1],
[44,26,23,2],
[44,26,23,3],
[44,26,23,4],
[44,26,23,5],
[44,26,23,6],
[44,26,23,7],
[44,26,23,8],
[44,26,23,10],
[44,26,23,11],
[44,26,23,12],
[44,26,23,13],
[44,26,23,14],
[44,26,23,16],
[44,26,23,19],
[44,26,23,20],
[44,26,23,21],
[44,26,23,22],
[44,26,24,0],
[44,26,24,1],
[44,26,24,2],
[44,26,24,3],
[44,26,24,4],
[44,26,24,5],
[44,26,24,6],
[44,26,24,7],
[44,26,24,8],
[44,26,24,10],
[44,26,24,11],
[44,26,24,12],
[44,26,24,13],
[44,26,24,14],
[44,26,24,19],
[44,26,24,20],
[44,26,24,21],
[44,26,24,22],
[44,26,24,23],
[44,26,25,0],
[44,26,25,1],
[44,26,25,2],
[44,26,25,3],
[44,26,25,4],
[44,26,25,5],
[44,26,25,6],
[44,26,25,8],
[44,26,25,10],
[44,26,25,11],
[44,26,25,12],
[44,26,25,13],
[44,26,25,14],
[44,26,25,16],
[44,26,25,19],
[44,26,25,20],
[44,26,25,21],
[44,26,25,22],
[44,26,25,23],
[44,26,25,24],
[44,27,3,0],
[44,27,3,1],
[44,27,3,2],
[44,27,4,0],
[44,27,4,1],
[44,27,4,2],
[44,27,4,3],
[44,27,5,0],
[44,27,5,1],
[44,27,5,2],
[44,27,5,4],
[44,27,6,0],
[44,27,6,1],
[44,27,6,2],
[44,27,6,4],
[44,27,7,0],
[44,27,7,1],
[44,27,7,2],
[44,27,7,3],
[44,27,7,4],
[44,27,7,5],
[44,27,7,6],
[44,27,8,0],
[44,27,8,1],
[44,27,8,2],
[44,27,8,4],
[44,27,8,7],
[44,27,9,0],
[44,27,9,1],
[44,27,9,2],
[44,27,9,3],
[44,27,9,4],
[44,27,9,5],
[44,27,9,6],
[44,27,9,7],
[44,27,9,8],
[44,27,11,0],
[44,27,11,1],
[44,27,11,2],
[44,27,11,3],
[44,27,11,4],
[44,27,11,5],
[44,27,11,6],
[44,27,11,7],
[44,27,11,8],
[44,27,11,9],
[44,27,12,0],
[44,27,12,1],
[44,27,12,2],
[44,27,12,3],
[44,27,12,4],
[44,27,12,5],
[44,27,12,6],
[44,27,12,7],
[44,27,12,8],
[44,27,12,9],
[44,27,13,0],
[44,27,13,1],
[44,27,13,2],
[44,27,13,3],
[44,27,13,4],
[44,27,13,5],
[44,27,13,6],
[44,27,13,7],
[44,27,13,8],
[44,27,13,9],
[44,27,13,11],
[44,27,13,12],
[44,27,14,0],
[44,27,14,1],
[44,27,14,2],
[44,27,14,3],
[44,27,14,4],
[44,27,14,5],
[44,27,14,6],
[44,27,14,7],
[44,27,14,8],
[44,27,14,9],
[44,27,14,11],
[44,27,14,12],
[44,27,15,0],
[44,27,15,1],
[44,27,15,2],
[44,27,15,3],
[44,27,15,4],
[44,27,15,5],
[44,27,15,6],
[44,27,15,7],
[44,27,15,8],
[44,27,15,11],
[44,27,15,12],
[44,27,15,13],
[44,27,15,14],
[44,27,16,0],
[44,27,16,1],
[44,27,16,2],
[44,27,16,3],
[44,27,16,4],
[44,27,16,5],
[44,27,16,6],
[44,27,16,7],
[44,27,16,8],
[44,27,16,9],
[44,27,16,11],
[44,27,16,12],
[44,27,16,13],
[44,27,16,14],
[44,27,16,15],
[44,27,18,0],
[44,27,18,1],
[44,27,18,2],
[44,27,18,3],
[44,27,18,4],
[44,27,18,5],
[44,27,18,6],
[44,27,18,7],
[44,27,18,8],
[44,27,18,11],
[44,27,18,12],
[44,27,18,13],
[44,27,18,14],
[44,27,18,16],
[44,27,19,0],
[44,27,19,1],
[44,27,19,2],
[44,27,19,3],
[44,27,19,4],
[44,27,19,5],
[44,27,19,6],
[44,27,19,7],
[44,27,19,8],
[44,27,19,9],
[44,27,19,11],
[44,27,19,12],
[44,27,19,15],
[44,27,19,16],
[44,27,19,18],
[44,27,21,0],
[44,27,21,1],
[44,27,21,2],
[44,27,21,4],
[44,27,21,7],
[44,27,21,9],
[44,27,21,11],
[44,27,21,12],
[44,27,21,13],
[44,27,21,14],
[44,27,21,15],
[44,27,21,16],
[44,27,21,18],
[44,27,21,19],
[44,27,23,0],
[44,27,23,1],
[44,27,23,2],
[44,27,23,3],
[44,27,23,4],
[44,27,23,5],
[44,27,23,6],
[44,27,23,7],
[44,27,23,8],
[44,27,23,9],
[44,27,23,11],
[44,27,23,12],
[44,27,23,13],
[44,27,23,14],
[44,27,23,15],
[44,27,23,16],
[44,27,23,18],
[44,27,23,19],
[44,27,23,21],
[44,27,24,0],
[44,27,24,1],
[44,27,24,2],
[44,27,24,3],
[44,27,24,4],
[44,27,24,5],
[44,27,24,6],
[44,27,24,7],
[44,27,24,8],
[44,27,24,9],
[44,27,24,11],
[44,27,24,12],
[44,27,24,13],
[44,27,24,14],
[44,27,24,15],
[44,27,24,18],
[44,27,24,19],
[44,27,24,21],
[44,27,24,23],
[44,27,25,0],
[44,27,25,1],
[44,27,25,2],
[44,27,25,3],
[44,27,25,4],
[44,27,25,5],
[44,27,25,6],
[44,27,25,8],
[44,27,25,9],
[44,27,25,11],
[44,27,25,12],
[44,27,25,13],
[44,27,25,14],
[44,27,25,15],
[44,27,25,16],
[44,27,25,18],
[44,27,25,19],
[44,27,25,21],
[44,27,25,23],
[44,27,25,24],
[44,27,26,0],
[44,27,26,1],
[44,27,26,2],
[44,27,26,3],
[44,27,26,4],
[44,27,26,5],
[44,27,26,6],
[44,27,26,7],
[44,27,26,8],
[44,27,26,11],
[44,27,26,12],
[44,27,26,13],
[44,27,26,14],
[44,27,26,16],
[44,27,26,19],
[44,27,26,21],
[44,27,26,23],
[44,27,26,24],
[44,27,26,25],
[44,28,3,0],
[44,28,3,1],
[44,28,3,2],
[44,28,4,0],
[44,28,4,1],
[44,28,4,2],
[44,28,4,3],
[44,28,5,0],
[44,28,5,1],
[44,28,5,2],
[44,28,5,4],
[44,28,6,0],
[44,28,6,1],
[44,28,6,2],
[44,28,6,4],
[44,28,8,0],
[44,28,8,1],
[44,28,8,2],
[44,28,8,4],
[44,28,9,0],
[44,28,9,1],
[44,28,9,2],
[44,28,9,3],
[44,28,9,4],
[44,28,9,5],
[44,28,9,6],
[44,28,9,8],
[44,28,10,0],
[44,28,10,1],
[44,28,10,2],
[44,28,10,3],
[44,28,10,4],
[44,28,10,5],
[44,28,10,6],
[44,28,10,8],
[44,28,10,9],
[44,28,11,0],
[44,28,11,1],
[44,28,11,2],
[44,28,11,3],
[44,28,11,4],
[44,28,11,5],
[44,28,11,6],
[44,28,11,8],
[44,28,11,9],
[44,28,11,10],
[44,28,12,0],
[44,28,12,1],
[44,28,12,2],
[44,28,12,3],
[44,28,12,4],
[44,28,12,5],
[44,28,12,6],
[44,28,12,8],
[44,28,12,9],
[44,28,12,10],
[44,28,13,0],
[44,28,13,1],
[44,28,13,2],
[44,28,13,3],
[44,28,13,4],
[44,28,13,5],
[44,28,13,6],
[44,28,13,8],
[44,28,13,9],
[44,28,13,10],
[44,28,13,11],
[44,28,13,12],
[44,28,14,0],
[44,28,14,1],
[44,28,14,2],
[44,28,14,3],
[44,28,14,4],
[44,28,14,5],
[44,28,14,6],
[44,28,14,8],
[44,28,14,9],
[44,28,14,10],
[44,28,14,11],
[44,28,14,12],
[44,28,15,0],
[44,28,15,1],
[44,28,15,2],
[44,28,15,3],
[44,28,15,4],
[44,28,15,5],
[44,28,15,6],
[44,28,15,8],
[44,28,15,10],
[44,28,15,11],
[44,28,15,12],
[44,28,15,13],
[44,28,15,14],
[44,28,16,0],
[44,28,16,1],
[44,28,16,2],
[44,28,16,3],
[44,28,16,4],
[44,28,16,5],
[44,28,16,6],
[44,28,16,8],
[44,28,16,9],
[44,28,16,10],
[44,28,16,11],
[44,28,16,12],
[44,28,16,13],
[44,28,16,14],
[44,28,16,15],
[44,28,18,0],
[44,28,18,1],
[44,28,18,2],
[44,28,18,3],
[44,28,18,4],
[44,28,18,5],
[44,28,18,6],
[44,28,18,8],
[44,28,18,10],
[44,28,18,11],
[44,28,18,12],
[44,28,18,13],
[44,28,18,14],
[44,28,18,16],
[44,28,19,0],
[44,28,19,1],
[44,28,19,2],
[44,28,19,3],
[44,28,19,4],
[44,28,19,5],
[44,28,19,6],
[44,28,19,8],
[44,28,19,9],
[44,28,19,10],
[44,28,19,11],
[44,28,19,12],
[44,28,19,15],
[44,28,19,16],
[44,28,19,18],
[44,28,20,0],
[44,28,20,1],
[44,28,20,2],
[44,28,20,3],
[44,28,20,4],
[44,28,20,5],
[44,28,20,6],
[44,28,20,8],
[44,28,20,9],
[44,28,20,11],
[44,28,20,12],
[44,28,20,13],
[44,28,20,14],
[44,28,20,15],
[44,28,20,16],
[44,28,20,18],
[44,28,20,19],
[44,28,21,0],
[44,28,21,1],
[44,28,21,2],
[44,28,21,4],
[44,28,21,9],
[44,28,21,10],
[44,28,21,11],
[44,28,21,12],
[44,28,21,13],
[44,28,21,14],
[44,28,21,15],
[44,28,21,16],
[44,28,21,18],
[44,28,21,19],
[44,28,21,20],
[44,28,22,0],
[44,28,22,1],
[44,28,22,2],
[44,28,22,3],
[44,28,22,4],
[44,28,22,5],
[44,28,22,6],
[44,28,22,8],
[44,28,22,9],
[44,28,22,11],
[44,28,22,12],
[44,28,22,13],
[44,28,22,14],
[44,28,22,15],
[44,28,22,16],
[44,28,22,18],
[44,28,22,19],
[44,28,22,21],
[44,28,23,0],
[44,28,23,1],
[44,28,23,2],
[44,28,23,3],
[44,28,23,4],
[44,28,23,5],
[44,28,23,6],
[44,28,23,8],
[44,28,23,9],
[44,28,23,10],
[44,28,23,11],
[44,28,23,12],
[44,28,23,13],
[44,28,23,14],
[44,28,23,15],
[44,28,23,16],
[44,28,23,18],
[44,28,23,19],
[44,28,23,20],
[44,28,23,21],
[44,28,23,22],
[44,28,24,0],
[44,28,24,1],
[44,28,24,2],
[44,28,24,3],
[44,28,24,4],
[44,28,24,5],
[44,28,24,6],
[44,28,24,8],
[44,28,24,9],
[44,28,24,10],
[44,28,24,11],
[44,28,24,12],
[44,28,24,13],
[44,28,24,14],
[44,28,24,15],
[44,28,24,18],
[44,28,24,19],
[44,28,24,20],
[44,28,24,21],
[44,28,24,22],
[44,28,24,23],
[44,28,26,0],
[44,28,26,1],
[44,28,26,2],
[44,28,26,3],
[44,28,26,4],
[44,28,26,5],
[44,28,26,6],
[44,28,26,8],
[44,28,26,10],
[44,28,26,11],
[44,28,26,12],
[44,28,26,13],
[44,28,26,14],
[44,28,26,16],
[44,28,26,19],
[44,28,26,20],
[44,28,26,21],
[44,28,26,22],
[44,28,26,23],
[44,28,26,24],
[44,28,27,0],
[44,28,27,1],
[44,28,27,2],
[44,28,27,3],
[44,28,27,4],
[44,28,27,5],
[44,28,27,6],
[44,28,27,8],
[44,28,27,9],
[44,28,27,11],
[44,28,27,12],
[44,28,27,13],
[44,28,27,14],
[44,28,27,15],
[44,28,27,16],
[44,28,27,18],
[44,28,27,19],
[44,28,27,21],
[44,28,27,23],
[44,28,27,24],
[44,28,27,26],
[44,29,3,0],
[44,29,3,1],
[44,29,3,2],
[44,29,4,0],
[44,29,4,1],
[44,29,4,2],
[44,29,4,3],
[44,29,5,0],
[44,29,5,1],
[44,29,5,2],
[44,29,5,4],
[44,29,6,0],
[44,29,6,1],
[44,29,6,2],
[44,29,6,4],
[44,29,7,0],
[44,29,7,1],
[44,29,7,2],
[44,29,7,3],
[44,29,7,4],
[44,29,7,5],
[44,29,7,6],
[44,29,8,0],
[44,29,8,1],
[44,29,8,2],
[44,29,8,4],
[44,29,8,7],
[44,29,9,0],
[44,29,9,1],
[44,29,9,2],
[44,29,9,3],
[44,29,9,4],
[44,29,9,5],
[44,29,9,6],
[44,29,9,7],
[44,29,9,8],
[44,29,10,0],
[44,29,10,1],
[44,29,10,2],
[44,29,10,3],
[44,29,10,4],
[44,29,10,5],
[44,29,10,6],
[44,29,10,7],
[44,29,10,8],
[44,29,10,9],
[44,29,13,0],
[44,29,13,1],
[44,29,13,2],
[44,29,13,3],
[44,29,13,4],
[44,29,13,5],
[44,29,13,6],
[44,29,13,7],
[44,29,13,8],
[44,29,13,9],
[44,29,13,10],
[44,29,14,0],
[44,29,14,1],
[44,29,14,2],
[44,29,14,3],
[44,29,14,4],
[44,29,14,5],
[44,29,14,6],
[44,29,14,7],
[44,29,14,8],
[44,29,14,9],
[44,29,14,10],
[44,29,15,0],
[44,29,15,1],
[44,29,15,2],
[44,29,15,3],
[44,29,15,4],
[44,29,15,5],
[44,29,15,6],
[44,29,15,7],
[44,29,15,8],
[44,29,15,10],
[44,29,15,13],
[44,29,15,14],
[44,29,16,0],
[44,29,16,1],
[44,29,16,2],
[44,29,16,3],
[44,29,16,4],
[44,29,16,5],
[44,29,16,6],
[44,29,16,7],
[44,29,16,8],
[44,29,16,9],
[44,29,16,10],
[44,29,16,13],
[44,29,16,14],
[44,29,16,15],
[44,29,18,0],
[44,29,18,1],
[44,29,18,2],
[44,29,18,3],
[44,29,18,4],
[44,29,18,5],
[44,29,18,6],
[44,29,18,7],
[44,29,18,8],
[44,29,18,10],
[44,29,18,13],
[44,29,18,14],
[44,29,18,16],
[44,29,19,0],
[44,29,19,1],
[44,29,19,2],
[44,29,19,3],
[44,29,19,4],
[44,29,19,5],
[44,29,19,6],
[44,29,19,7],
[44,29,19,8],
[44,29,19,9],
[44,29,19,10],
[44,29,19,15],
[44,29,19,16],
[44,29,19,18],
[44,29,20,0],
[44,29,20,1],
[44,29,20,2],
[44,29,20,3],
[44,29,20,4],
[44,29,20,5],
[44,29,20,6],
[44,29,20,7],
[44,29,20,8],
[44,29,20,9],
[44,29,20,13],
[44,29,20,14],
[44,29,20,15],
[44,29,20,16],
[44,29,20,18],
[44,29,20,19],
[44,29,21,0],
[44,29,21,1],
[44,29,21,2],
[44,29,21,4],
[44,29,21,7],
[44,29,21,9],
[44,29,21,10],
[44,29,21,13],
[44,29,21,14],
[44,29,21,15],
[44,29,21,16],
[44,29,21,18],
[44,29,21,19],
[44,29,21,20],
[44,29,22,0],
[44,29,22,1],
[44,29,22,2],
[44,29,22,3],
[44,29,22,4],
[44,29,22,5],
[44,29,22,6],
[44,29,22,7],
[44,29,22,8],
[44,29,22,9],
[44,29,22,13],
[44,29,22,14],
[44,29,22,15],
[44,29,22,16],
[44,29,22,18],
[44,29,22,19],
[44,29,22,21],
[44,29,23,0],
[44,29,23,1],
[44,29,23,2],
[44,29,23,3],
[44,29,23,4],
[44,29,23,5],
[44,29,23,6],
[44,29,23,7],
[44,29,23,8],
[44,29,23,9],
[44,29,23,10],
[44,29,23,13],
[44,29,23,14],
[44,29,23,15],
[44,29,23,16],
[44,29,23,18],
[44,29,23,19],
[44,29,23,20],
[44,29,23,21],
[44,29,23,22],
[44,29,24,0],
[44,29,24,1],
[44,29,24,2],
[44,29,24,3],
[44,29,24,4],
[44,29,24,5],
[44,29,24,6],
[44,29,24,7],
[44,29,24,8],
[44,29,24,9],
[44,29,24,10],
[44,29,24,13],
[44,29,24,14],
[44,29,24,15],
[44,29,24,18],
[44,29,24,19],
[44,29,24,20],
[44,29,24,21],
[44,29,24,22],
[44,29,24,23],
[44,29,25,0],
[44,29,25,1],
[44,29,25,2],
[44,29,25,3],
[44,29,25,4],
[44,29,25,5],
[44,29,25,6],
[44,29,25,8],
[44,29,25,9],
[44,29,25,10],
[44,29,25,13],
[44,29,25,14],
[44,29,25,15],
[44,29,25,16],
[44,29,25,18],
[44,29,25,19],
[44,29,25,20],
[44,29,25,21],
[44,29,25,22],
[44,29,25,23],
[44,29,25,24],
[44,29,26,0],
[44,29,26,1],
[44,29,26,2],
[44,29,26,3],
[44,29,26,4],
[44,29,26,5],
[44,29,26,6],
[44,29,26,7],
[44,29,26,8],
[44,29,26,10],
[44,29,26,13],
[44,29,26,14],
[44,29,26,16],
[44,29,26,19],
[44,29,26,20],
[44,29,26,21],
[44,29,26,22],
[44,29,26,23],
[44,29,26,24],
[44,29,26,25],
[44,29,27,0],
[44,29,27,1],
[44,29,27,2],
[44,29,27,3],
[44,29,27,4],
[44,29,27,5],
[44,29,27,6],
[44,29,27,7],
[44,29,27,8],
[44,29,27,9],
[44,29,27,13],
[44,29,27,14],
[44,29,27,15],
[44,29,27,16],
[44,29,27,18],
[44,29,27,19],
[44,29,27,21],
[44,29,27,23],
[44,29,27,24],
[44,29,27,25],
[44,29,27,26],
[44,29,28,0],
[44,29,28,1],
[44,29,28,2],
[44,29,28,3],
[44,29,28,4],
[44,29,28,5],
[44,29,28,6],
[44,29,28,8],
[44,29,28,9],
[44,29,28,10],
[44,29,28,13],
[44,29,28,14],
[44,29,28,15],
[44,29,28,16],
[44,29,28,18],
[44,29,28,19],
[44,29,28,20],
[44,29,28,21],
[44,29,28,22],
[44,29,28,23],
[44,29,28,24],
[44,29,28,26],
[44,30,3,0],
[44,30,3,1],
[44,30,3,2],
[44,30,4,0],
[44,30,4,1],
[44,30,4,2],
[44,30,4,3],
[44,30,5,0],
[44,30,5,1],
[44,30,5,2],
[44,30,5,4],
[44,30,6,0],
[44,30,6,1],
[44,30,6,2],
[44,30,6,4],
[44,30,7,0],
[44,30,7,1],
[44,30,7,2],
[44,30,7,3],
[44,30,7,4],
[44,30,7,5],
[44,30,7,6],
[44,30,8,0],
[44,30,8,1],
[44,30,8,2],
[44,30,8,4],
[44,30,8,7],
[44,30,9,0],
[44,30,9,1],
[44,30,9,2],
[44,30,9,3],
[44,30,9,4],
[44,30,9,5],
[44,30,9,6],
[44,30,9,7],
[44,30,9,8],
[44,30,10,0],
[44,30,10,1],
[44,30,10,2],
[44,30,10,3],
[44,30,10,4],
[44,30,10,5],
[44,30,10,6],
[44,30,10,7],
[44,30,10,8],
[44,30,10,9],
[44,30,11,0],
[44,30,11,1],
[44,30,11,2],
[44,30,11,3],
[44,30,11,4],
[44,30,11,5],
[44,30,11,6],
[44,30,11,7],
[44,30,11,8],
[44,30,11,9],
[44,30,11,10],
[44,30,12,0],
[44,30,12,1],
[44,30,12,2],
[44,30,12,3],
[44,30,12,4],
[44,30,12,5],
[44,30,12,6],
[44,30,12,7],
[44,30,12,8],
[44,30,12,9],
[44,30,12,10],
[44,30,13,0],
[44,30,13,1],
[44,30,13,2],
[44,30,13,3],
[44,30,13,4],
[44,30,13,5],
[44,30,13,6],
[44,30,13,7],
[44,30,13,8],
[44,30,13,9],
[44,30,13,10],
[44,30,13,11],
[44,30,13,12],
[44,30,14,0],
[44,30,14,1],
[44,30,14,2],
[44,30,14,3],
[44,30,14,4],
[44,30,14,5],
[44,30,14,6],
[44,30,14,7],
[44,30,14,8],
[44,30,14,9],
[44,30,14,10],
[44,30,14,11],
[44,30,14,12],
[44,30,15,0],
[44,30,15,1],
[44,30,15,2],
[44,30,15,3],
[44,30,15,4],
[44,30,15,5],
[44,30,15,6],
[44,30,15,7],
[44,30,15,8],
[44,30,15,10],
[44,30,15,11],
[44,30,15,12],
[44,30,15,13],
[44,30,15,14],
[44,30,16,0],
[44,30,16,1],
[44,30,16,2],
[44,30,16,3],
[44,30,16,4],
[44,30,16,5],
[44,30,16,6],
[44,30,16,7],
[44,30,16,8],
[44,30,16,9],
[44,30,16,10],
[44,30,16,11],
[44,30,16,12],
[44,30,16,13],
[44,30,16,14],
[44,30,16,15],
[44,30,18,0],
[44,30,18,1],
[44,30,18,2],
[44,30,18,3],
[44,30,18,4],
[44,30,18,5],
[44,30,18,6],
[44,30,18,7],
[44,30,18,8],
[44,30,18,10],
[44,30,18,11],
[44,30,18,12],
[44,30,18,13],
[44,30,18,14],
[44,30,18,16],
[44,30,19,0],
[44,30,19,1],
[44,30,19,2],
[44,30,19,3],
[44,30,19,4],
[44,30,19,5],
[44,30,19,6],
[44,30,19,7],
[44,30,19,8],
[44,30,19,9],
[44,30,19,10],
[44,30,19,11],
[44,30,19,12],
[44,30,19,15],
[44,30,19,16],
[44,30,19,18],
[44,30,20,0],
[44,30,20,1],
[44,30,20,2],
[44,30,20,3],
[44,30,20,4],
[44,30,20,5],
[44,30,20,6],
[44,30,20,7],
[44,30,20,8],
[44,30,20,9],
[44,30,20,11],
[44,30,20,12],
[44,30,20,13],
[44,30,20,14],
[44,30,20,15],
[44,30,20,16],
[44,30,20,18],
[44,30,20,19],
[44,30,21,0],
[44,30,21,1],
[44,30,21,2],
[44,30,21,4],
[44,30,21,7],
[44,30,21,9],
[44,30,21,10],
[44,30,21,11],
[44,30,21,12],
[44,30,21,13],
[44,30,21,14],
[44,30,21,15],
[44,30,21,16],
[44,30,21,18],
[44,30,21,19],
[44,30,21,20],
[44,30,22,0],
[44,30,22,1],
[44,30,22,2],
[44,30,22,3],
[44,30,22,4],
[44,30,22,5],
[44,30,22,6],
[44,30,22,7],
[44,30,22,8],
[44,30,22,9],
[44,30,22,11],
[44,30,22,12],
[44,30,22,13],
[44,30,22,14],
[44,30,22,15],
[44,30,22,16],
[44,30,22,18],
[44,30,22,19],
[44,30,22,21],
[44,30,24,0],
[44,30,24,1],
[44,30,24,2],
[44,30,24,3],
[44,30,24,4],
[44,30,24,5],
[44,30,24,6],
[44,30,24,7],
[44,30,24,8],
[44,30,24,9],
[44,30,24,10],
[44,30,24,11],
[44,30,24,12],
[44,30,24,13],
[44,30,24,14],
[44,30,24,15],
[44,30,24,18],
[44,30,24,19],
[44,30,24,20],
[44,30,24,21],
[44,30,24,22],
[44,30,25,0],
[44,30,25,1],
[44,30,25,2],
[44,30,25,3],
[44,30,25,4],
[44,30,25,5],
[44,30,25,6],
[44,30,25,8],
[44,30,25,9],
[44,30,25,10],
[44,30,25,11],
[44,30,25,12],
[44,30,25,13],
[44,30,25,14],
[44,30,25,15],
[44,30,25,16],
[44,30,25,18],
[44,30,25,19],
[44,30,25,20],
[44,30,25,21],
[44,30,25,22],
[44,30,25,24],
[44,30,26,0],
[44,30,26,1],
[44,30,26,2],
[44,30,26,3],
[44,30,26,4],
[44,30,26,5],
[44,30,26,6],
[44,30,26,7],
[44,30,26,8],
[44,30,26,10],
[44,30,26,11],
[44,30,26,12],
[44,30,26,13],
[44,30,26,14],
[44,30,26,16],
[44,30,26,19],
[44,30,26,20],
[44,30,26,21],
[44,30,26,22],
[44,30,26,24],
[44,30,26,25],
[44,30,27,0],
[44,30,27,1],
[44,30,27,2],
[44,30,27,3],
[44,30,27,4],
[44,30,27,5],
[44,30,27,6],
[44,30,27,7],
[44,30,27,8],
[44,30,27,9],
[44,30,27,11],
[44,30,27,12],
[44,30,27,13],
[44,30,27,14],
[44,30,27,15],
[44,30,27,16],
[44,30,27,18],
[44,30,27,19],
[44,30,27,21],
[44,30,27,24],
[44,30,27,25],
[44,30,27,26],
[44,30,28,0],
[44,30,28,1],
[44,30,28,2],
[44,30,28,3],
[44,30,28,4],
[44,30,28,5],
[44,30,28,6],
[44,30,28,8],
[44,30,28,9],
[44,30,28,10],
[44,30,28,11],
[44,30,28,12],
[44,30,28,13],
[44,30,28,14],
[44,30,28,15],
[44,30,28,16],
[44,30,28,18],
[44,30,28,19],
[44,30,28,20],
[44,30,28,21],
[44,30,28,22],
[44,30,28,24],
[44,30,28,27],
[44,30,29,0],
[44,30,29,1],
[44,30,29,2],
[44,30,29,3],
[44,30,29,4],
[44,30,29,5],
[44,30,29,6],
[44,30,29,7],
[44,30,29,8],
[44,30,29,9],
[44,30,29,10],
[44,30,29,13],
[44,30,29,14],
[44,30,29,15],
[44,30,29,16],
[44,30,29,18],
[44,30,29,19],
[44,30,29,20],
[44,30,29,21],
[44,30,29,22],
[44,30,29,24],
[44,30,29,26],
[44,30,29,27],
[44,30,29,28],
[44,31,3,0],
[44,31,3,1],
[44,31,3,2],
[44,31,4,0],
[44,31,4,1],
[44,31,4,2],
[44,31,4,3],
[44,31,5,0],
[44,31,5,1],
[44,31,5,2],
[44,31,5,4],
[44,31,6,0],
[44,31,6,1],
[44,31,6,2],
[44,31,6,4],
[44,31,7,0],
[44,31,7,1],
[44,31,7,2],
[44,31,7,3],
[44,31,7,4],
[44,31,7,5],
[44,31,7,6],
[44,31,8,0],
[44,31,8,1],
[44,31,8,2],
[44,31,8,4],
[44,31,8,7],
[44,31,9,0],
[44,31,9,1],
[44,31,9,2],
[44,31,9,3],
[44,31,9,4],
[44,31,9,5],
[44,31,9,6],
[44,31,9,7],
[44,31,9,8],
[44,31,10,0],
[44,31,10,1],
[44,31,10,2],
[44,31,10,3],
[44,31,10,4],
[44,31,10,5],
[44,31,10,6],
[44,31,10,7],
[44,31,10,8],
[44,31,10,9],
[44,31,11,0],
[44,31,11,1],
[44,31,11,2],
[44,31,11,3],
[44,31,11,4],
[44,31,11,5],
[44,31,11,6],
[44,31,11,7],
[44,31,11,8],
[44,31,11,9],
[44,31,11,10],
[44,31,12,0],
[44,31,12,1],
[44,31,12,2],
[44,31,12,3],
[44,31,12,4],
[44,31,12,5],
[44,31,12,6],
[44,31,12,7],
[44,31,12,8],
[44,31,12,9],
[44,31,12,10],
[44,31,13,0],
[44,31,13,1],
[44,31,13,2],
[44,31,13,3],
[44,31,13,4],
[44,31,13,5],
[44,31,13,6],
[44,31,13,7],
[44,31,13,8],
[44,31,13,9],
[44,31,13,10],
[44,31,13,11],
[44,31,13,12],
[44,31,14,0],
[44,31,14,1],
[44,31,14,2],
[44,31,14,3],
[44,31,14,4],
[44,31,14,5],
[44,31,14,6],
[44,31,14,7],
[44,31,14,8],
[44,31,14,9],
[44,31,14,10],
[44,31,14,11],
[44,31,14,12],
[44,31,15,0],
[44,31,15,1],
[44,31,15,2],
[44,31,15,3],
[44,31,15,4],
[44,31,15,5],
[44,31,15,6],
[44,31,15,7],
[44,31,15,8],
[44,31,15,10],
[44,31,15,11],
[44,31,15,12],
[44,31,15,13],
[44,31,15,14],
[44,31,16,0],
[44,31,16,1],
[44,31,16,2],
[44,31,16,3],
[44,31,16,4],
[44,31,16,5],
[44,31,16,6],
[44,31,16,7],
[44,31,16,8],
[44,31,16,9],
[44,31,16,10],
[44,31,16,11],
[44,31,16,12],
[44,31,16,13],
[44,31,16,14],
[44,31,16,15],
[44,31,18,0],
[44,31,18,1],
[44,31,18,2],
[44,31,18,3],
[44,31,18,4],
[44,31,18,5],
[44,31,18,6],
[44,31,18,7],
[44,31,18,8],
[44,31,18,10],
[44,31,18,11],
[44,31,18,12],
[44,31,18,13],
[44,31,18,14],
[44,31,18,16],
[44,31,19,0],
[44,31,19,1],
[44,31,19,2],
[44,31,19,3],
[44,31,19,4],
[44,31,19,5],
[44,31,19,6],
[44,31,19,7],
[44,31,19,8],
[44,31,19,9],
[44,31,19,10],
[44,31,19,11],
[44,31,19,12],
[44,31,19,15],
[44,31,19,16],
[44,31,19,18],
[44,31,20,0],
[44,31,20,1],
[44,31,20,2],
[44,31,20,3],
[44,31,20,4],
[44,31,20,5],
[44,31,20,6],
[44,31,20,7],
[44,31,20,8],
[44,31,20,9],
[44,31,20,11],
[44,31,20,12],
[44,31,20,13],
[44,31,20,14],
[44,31,20,15],
[44,31,20,16],
[44,31,20,18],
[44,31,20,19],
[44,31,21,0],
[44,31,21,1],
[44,31,21,2],
[44,31,21,4],
[44,31,21,7],
[44,31,21,9],
[44,31,21,10],
[44,31,21,11],
[44,31,21,12],
[44,31,21,13],
[44,31,21,14],
[44,31,21,15],
[44,31,21,16],
[44,31,21,18],
[44,31,21,19],
[44,31,21,20],
[44,31,22,0],
[44,31,22,1],
[44,31,22,2],
[44,31,22,3],
[44,31,22,4],
[44,31,22,5],
[44,31,22,6],
[44,31,22,7],
[44,31,22,8],
[44,31,22,9],
[44,31,22,11],
[44,31,22,12],
[44,31,22,13],
[44,31,22,14],
[44,31,22,15],
[44,31,22,16],
[44,31,22,18],
[44,31,22,19],
[44,31,22,21],
[44,31,24,0],
[44,31,24,1],
[44,31,24,2],
[44,31,24,3],
[44,31,24,4],
[44,31,24,5],
[44,31,24,6],
[44,31,24,7],
[44,31,24,8],
[44,31,24,9],
[44,31,24,10],
[44,31,24,11],
[44,31,24,12],
[44,31,24,13],
[44,31,24,14],
[44,31,24,15],
[44,31,24,18],
[44,31,24,19],
[44,31,24,20],
[44,31,24,21],
[44,31,24,22],
[44,31,25,0],
[44,31,25,1],
[44,31,25,2],
[44,31,25,3],
[44,31,25,4],
[44,31,25,5],
[44,31,25,6],
[44,31,25,8],
[44,31,25,9],
[44,31,25,10],
[44,31,25,11],
[44,31,25,12],
[44,31,25,13],
[44,31,25,14],
[44,31,25,15],
[44,31,25,16],
[44,31,25,18],
[44,31,25,19],
[44,31,25,20],
[44,31,25,21],
[44,31,25,22],
[44,31,25,24],
[44,31,26,0],
[44,31,26,1],
[44,31,26,2],
[44,31,26,3],
[44,31,26,4],
[44,31,26,5],
[44,31,26,6],
[44,31,26,7],
[44,31,26,8],
[44,31,26,10],
[44,31,26,11],
[44,31,26,12],
[44,31,26,13],
[44,31,26,14],
[44,31,26,16],
[44,31,26,19],
[44,31,26,20],
[44,31,26,21],
[44,31,26,22],
[44,31,26,24],
[44,31,26,25],
[44,31,27,0],
[44,31,27,1],
[44,31,27,2],
[44,31,27,3],
[44,31,27,4],
[44,31,27,5],
[44,31,27,6],
[44,31,27,7],
[44,31,27,8],
[44,31,27,9],
[44,31,27,11],
[44,31,27,12],
[44,31,27,13],
[44,31,27,14],
[44,31,27,15],
[44,31,27,16],
[44,31,27,18],
[44,31,27,19],
[44,31,27,21],
[44,31,27,24],
[44,31,27,25],
[44,31,28,0],
[44,31,28,1],
[44,31,28,2],
[44,31,28,3],
[44,31,28,4],
[44,31,28,5],
[44,31,28,6],
[44,31,28,8],
[44,31,28,9],
[44,31,28,10],
[44,31,28,11],
[44,31,28,12],
[44,31,28,13],
[44,31,28,14],
[44,31,28,15],
[44,31,28,16],
[44,31,28,18],
[44,31,28,19],
[44,31,28,20],
[44,31,28,21],
[44,31,28,22],
[44,31,28,24],
[44,31,28,26],
[44,31,28,27],
[44,31,29,0],
[44,31,29,1],
[44,31,29,2],
[44,31,29,3],
[44,31,29,4],
[44,31,29,5],
[44,31,29,6],
[44,31,29,7],
[44,31,29,8],
[44,31,29,9],
[44,31,29,10],
[44,31,29,13],
[44,31,29,14],
[44,31,29,15],
[44,31,29,16],
[44,31,29,18],
[44,31,29,19],
[44,31,29,20],
[44,31,29,21],
[44,31,29,22],
[44,31,29,24],
[44,31,29,25],
[44,31,29,26],
[44,31,29,27],
[44,31,29,28],
[44,32,3,0],
[44,32,3,1],
[44,32,3,2],
[44,32,4,0],
[44,32,4,1],
[44,32,4,2],
[44,32,4,3],
[44,32,5,0],
[44,32,5,1],
[44,32,5,2],
[44,32,5,4],
[44,32,6,0],
[44,32,6,1],
[44,32,6,2],
[44,32,6,4],
[44,32,7,0],
[44,32,7,1],
[44,32,7,2],
[44,32,7,3],
[44,32,7,4],
[44,32,7,5],
[44,32,7,6],
[44,32,8,0],
[44,32,8,1],
[44,32,8,2],
[44,32,8,4],
[44,32,8,7],
[44,32,9,0],
[44,32,9,1],
[44,32,9,2],
[44,32,9,3],
[44,32,9,4],
[44,32,9,5],
[44,32,9,6],
[44,32,9,7],
[44,32,9,8],
[44,32,10,0],
[44,32,10,1],
[44,32,10,2],
[44,32,10,3],
[44,32,10,4],
[44,32,10,5],
[44,32,10,6],
[44,32,10,7],
[44,32,10,8],
[44,32,10,9],
[44,32,13,0],
[44,32,13,1],
[44,32,13,2],
[44,32,13,3],
[44,32,13,4],
[44,32,13,5],
[44,32,13,6],
[44,32,13,7],
[44,32,13,8],
[44,32,13,9],
[44,32,13,10],
[44,32,14,0],
[44,32,14,1],
[44,32,14,2],
[44,32,14,3],
[44,32,14,4],
[44,32,14,5],
[44,32,14,6],
[44,32,14,7],
[44,32,14,8],
[44,32,14,9],
[44,32,14,10],
[44,32,15,0],
[44,32,15,1],
[44,32,15,2],
[44,32,15,3],
[44,32,15,4],
[44,32,15,5],
[44,32,15,6],
[44,32,15,7],
[44,32,15,8],
[44,32,15,10],
[44,32,15,13],
[44,32,15,14],
[44,32,16,0],
[44,32,16,1],
[44,32,16,2],
[44,32,16,3],
[44,32,16,4],
[44,32,16,5],
[44,32,16,6],
[44,32,16,7],
[44,32,16,8],
[44,32,16,9],
[44,32,16,10],
[44,32,16,13],
[44,32,16,14],
[44,32,16,15],
[44,32,18,0],
[44,32,18,1],
[44,32,18,2],
[44,32,18,3],
[44,32,18,4],
[44,32,18,5],
[44,32,18,6],
[44,32,18,7],
[44,32,18,8],
[44,32,18,10],
[44,32,18,13],
[44,32,18,14],
[44,32,18,16],
[44,32,19,0],
[44,32,19,1],
[44,32,19,2],
[44,32,19,3],
[44,32,19,4],
[44,32,19,5],
[44,32,19,6],
[44,32,19,7],
[44,32,19,8],
[44,32,19,9],
[44,32,19,10],
[44,32,19,15],
[44,32,19,16],
[44,32,19,18],
[44,32,20,0],
[44,32,20,1],
[44,32,20,2],
[44,32,20,3],
[44,32,20,4],
[44,32,20,5],
[44,32,20,6],
[44,32,20,7],
[44,32,20,8],
[44,32,20,9],
[44,32,20,13],
[44,32,20,14],
[44,32,20,15],
[44,32,20,16],
[44,32,20,18],
[44,32,20,19],
[44,32,21,0],
[44,32,21,1],
[44,32,21,2],
[44,32,21,4],
[44,32,21,7],
[44,32,21,9],
[44,32,21,10],
[44,32,21,13],
[44,32,21,14],
[44,32,21,15],
[44,32,21,16],
[44,32,21,18],
[44,32,21,19],
[44,32,21,20],
[44,32,22,0],
[44,32,22,1],
[44,32,22,2],
[44,32,22,3],
[44,32,22,4],
[44,32,22,5],
[44,32,22,6],
[44,32,22,7],
[44,32,22,8],
[44,32,22,9],
[44,32,22,13],
[44,32,22,14],
[44,32,22,15],
[44,32,22,16],
[44,32,22,18],
[44,32,22,19],
[44,32,22,21],
[44,32,23,0],
[44,32,23,1],
[44,32,23,2],
[44,32,23,3],
[44,32,23,4],
[44,32,23,5],
[44,32,23,6],
[44,32,23,7],
[44,32,23,8],
[44,32,23,9],
[44,32,23,10],
[44,32,23,13],
[44,32,23,14],
[44,32,23,15],
[44,32,23,16],
[44,32,23,18],
[44,32,23,19],
[44,32,23,20],
[44,32,23,21],
[44,32,23,22],
[44,32,24,0],
[44,32,24,1],
[44,32,24,2],
[44,32,24,3],
[44,32,24,4],
[44,32,24,5],
[44,32,24,6],
[44,32,24,7],
[44,32,24,8],
[44,32,24,9],
[44,32,24,10],
[44,32,24,13],
[44,32,24,14],
[44,32,24,15],
[44,32,24,18],
[44,32,24,19],
[44,32,24,20],
[44,32,24,21],
[44,32,24,22],
[44,32,24,23],
[44,32,25,0],
[44,32,25,1],
[44,32,25,2],
[44,32,25,3],
[44,32,25,4],
[44,32,25,5],
[44,32,25,6],
[44,32,25,8],
[44,32,25,9],
[44,32,25,10],
[44,32,25,13],
[44,32,25,14],
[44,32,25,15],
[44,32,25,16],
[44,32,25,18],
[44,32,25,19],
[44,32,25,20],
[44,32,25,21],
[44,32,25,22],
[44,32,25,23],
[44,32,25,24],
[44,32,26,0],
[44,32,26,1],
[44,32,26,2],
[44,32,26,3],
[44,32,26,4],
[44,32,26,5],
[44,32,26,6],
[44,32,26,7],
[44,32,26,8],
[44,32,26,10],
[44,32,26,13],
[44,32,26,14],
[44,32,26,16],
[44,32,26,19],
[44,32,26,20],
[44,32,26,21],
[44,32,26,22],
[44,32,26,23],
[44,32,26,24],
[44,32,26,25],
[44,32,27,0],
[44,32,27,1],
[44,32,27,2],
[44,32,27,3],
[44,32,27,4],
[44,32,27,5],
[44,32,27,6],
[44,32,27,7],
[44,32,27,8],
[44,32,27,9],
[44,32,27,13],
[44,32,27,14],
[44,32,27,15],
[44,32,27,16],
[44,32,27,18],
[44,32,27,19],
[44,32,27,21],
[44,32,27,23],
[44,32,27,24],
[44,32,27,26],
[44,32,28,0],
[44,32,28,1],
[44,32,28,2],
[44,32,28,3],
[44,32,28,4],
[44,32,28,5],
[44,32,28,6],
[44,32,28,8],
[44,32,28,9],
[44,32,28,10],
[44,32,28,13],
[44,32,28,14],
[44,32,28,15],
[44,32,28,16],
[44,32,28,18],
[44,32,28,19],
[44,32,28,20],
[44,32,28,21],
[44,32,28,22],
[44,32,28,23],
[44,32,28,24],
[44,32,28,26],
[44,32,28,27],
[44,32,30,0],
[44,32,30,1],
[44,32,30,2],
[44,32,30,3],
[44,32,30,4],
[44,32,30,5],
[44,32,30,6],
[44,32,30,7],
[44,32,30,8],
[44,32,30,9],
[44,32,30,10],
[44,32,30,13],
[44,32,30,14],
[44,32,30,15],
[44,32,30,16],
[44,32,30,18],
[44,32,30,19],
[44,32,30,20],
[44,32,30,21],
[44,32,30,24],
[44,32,30,25],
[44,32,30,26],
[44,32,30,27],
[44,32,30,28],
[44,32,31,0],
[44,32,31,1],
[44,32,31,2],
[44,32,31,3],
[44,32,31,4],
[44,32,31,5],
[44,32,31,6],
[44,32,31,7],
[44,32,31,8],
[44,32,31,9],
[44,32,31,10],
[44,32,31,13],
[44,32,31,14],
[44,32,31,15],
[44,32,31,16],
[44,32,31,18],
[44,32,31,19],
[44,32,31,20],
[44,32,31,22],
[44,32,31,24],
[44,32,31,25],
[44,32,31,26],
[44,32,31,27],
[44,32,31,28],
[44,33,3,0],
[44,33,3,1],
[44,33,3,2],
[44,33,4,0],
[44,33,4,1],
[44,33,4,2],
[44,33,4,3],
[44,33,5,0],
[44,33,5,1],
[44,33,5,2],
[44,33,5,4],
[44,33,6,0],
[44,33,6,1],
[44,33,6,2],
[44,33,6,4],
[44,33,7,0],
[44,33,7,1],
[44,33,7,2],
[44,33,7,3],
[44,33,7,4],
[44,33,7,5],
[44,33,7,6],
[44,33,8,0],
[44,33,8,1],
[44,33,8,2],
[44,33,8,4],
[44,33,8,7],
[44,33,9,0],
[44,33,9,1],
[44,33,9,2],
[44,33,9,3],
[44,33,9,4],
[44,33,9,5],
[44,33,9,6],
[44,33,9,7],
[44,33,9,8],
[44,33,10,0],
[44,33,10,1],
[44,33,10,2],
[44,33,10,3],
[44,33,10,4],
[44,33,10,5],
[44,33,10,6],
[44,33,10,7],
[44,33,10,8],
[44,33,10,9],
[44,33,13,0],
[44,33,13,1],
[44,33,13,2],
[44,33,13,3],
[44,33,13,4],
[44,33,13,5],
[44,33,13,6],
[44,33,13,7],
[44,33,13,8],
[44,33,13,9],
[44,33,13,10],
[44,33,14,0],
[44,33,14,1],
[44,33,14,2],
[44,33,14,3],
[44,33,14,4],
[44,33,14,5],
[44,33,14,6],
[44,33,14,7],
[44,33,14,8],
[44,33,14,9],
[44,33,14,10],
[44,33,15,0],
[44,33,15,1],
[44,33,15,2],
[44,33,15,3],
[44,33,15,4],
[44,33,15,5],
[44,33,15,6],
[44,33,15,7],
[44,33,15,8],
[44,33,15,10],
[44,33,15,13],
[44,33,15,14],
[44,33,16,0],
[44,33,16,1],
[44,33,16,2],
[44,33,16,3],
[44,33,16,4],
[44,33,16,5],
[44,33,16,6],
[44,33,16,7],
[44,33,16,8],
[44,33,16,9],
[44,33,16,10],
[44,33,16,13],
[44,33,16,14],
[44,33,16,15],
[44,33,18,0],
[44,33,18,1],
[44,33,18,2],
[44,33,18,3],
[44,33,18,4],
[44,33,18,5],
[44,33,18,6],
[44,33,18,7],
[44,33,18,8],
[44,33,18,10],
[44,33,18,13],
[44,33,18,14],
[44,33,18,16],
[44,33,19,0],
[44,33,19,1],
[44,33,19,2],
[44,33,19,3],
[44,33,19,4],
[44,33,19,5],
[44,33,19,6],
[44,33,19,7],
[44,33,19,8],
[44,33,19,9],
[44,33,19,10],
[44,33,19,15],
[44,33,19,16],
[44,33,19,18],
[44,33,20,0],
[44,33,20,1],
[44,33,20,2],
[44,33,20,3],
[44,33,20,4],
[44,33,20,5],
[44,33,20,6],
[44,33,20,7],
[44,33,20,8],
[44,33,20,9],
[44,33,20,13],
[44,33,20,14],
[44,33,20,15],
[44,33,20,16],
[44,33,20,18],
[44,33,20,19],
[44,33,21,0],
[44,33,21,1],
[44,33,21,2],
[44,33,21,4],
[44,33,21,7],
[44,33,21,9],
[44,33,21,10],
[44,33,21,13],
[44,33,21,14],
[44,33,21,15],
[44,33,21,16],
[44,33,21,18],
[44,33,21,19],
[44,33,21,20],
[44,33,22,0],
[44,33,22,1],
[44,33,22,2],
[44,33,22,3],
[44,33,22,4],
[44,33,22,5],
[44,33,22,6],
[44,33,22,7],
[44,33,22,8],
[44,33,22,9],
[44,33,22,13],
[44,33,22,14],
[44,33,22,15],
[44,33,22,16],
[44,33,22,18],
[44,33,22,19],
[44,33,22,21],
[44,33,23,0],
[44,33,23,1],
[44,33,23,2],
[44,33,23,3],
[44,33,23,4],
[44,33,23,5],
[44,33,23,6],
[44,33,23,7],
[44,33,23,8],
[44,33,23,9],
[44,33,23,10],
[44,33,23,13],
[44,33,23,14],
[44,33,23,15],
[44,33,23,16],
[44,33,23,18],
[44,33,23,19],
[44,33,23,20],
[44,33,23,21],
[44,33,23,22],
[44,33,24,0],
[44,33,24,1],
[44,33,24,2],
[44,33,24,3],
[44,33,24,4],
[44,33,24,5],
[44,33,24,6],
[44,33,24,7],
[44,33,24,8],
[44,33,24,9],
[44,33,24,10],
[44,33,24,13],
[44,33,24,14],
[44,33,24,15],
[44,33,24,18],
[44,33,24,19],
[44,33,24,20],
[44,33,24,21],
[44,33,24,22],
[44,33,24,23],
[44,33,25,0],
[44,33,25,1],
[44,33,25,2],
[44,33,25,3],
[44,33,25,4],
[44,33,25,5],
[44,33,25,6],
[44,33,25,8],
[44,33,25,9],
[44,33,25,10],
[44,33,25,13],
[44,33,25,14],
[44,33,25,15],
[44,33,25,16],
[44,33,25,18],
[44,33,25,19],
[44,33,25,20],
[44,33,25,21],
[44,33,25,22],
[44,33,25,23],
[44,33,25,24],
[44,33,26,0],
[44,33,26,1],
[44,33,26,2],
[44,33,26,3],
[44,33,26,4],
[44,33,26,5],
[44,33,26,6],
[44,33,26,7],
[44,33,26,8],
[44,33,26,10],
[44,33,26,13],
[44,33,26,14],
[44,33,26,16],
[44,33,26,19],
[44,33,26,20],
[44,33,26,21],
[44,33,26,22],
[44,33,26,23],
[44,33,26,24],
[44,33,27,0],
[44,33,27,1],
[44,33,27,2],
[44,33,27,3],
[44,33,27,4],
[44,33,27,5],
[44,33,27,6],
[44,33,27,7],
[44,33,27,8],
[44,33,27,9],
[44,33,27,13],
[44,33,27,14],
[44,33,27,15],
[44,33,27,16],
[44,33,27,18],
[44,33,27,19],
[44,33,27,21],
[44,33,27,23],
[44,33,27,24],
[44,33,27,25],
[44,33,27,26],
[44,33,28,0],
[44,33,28,1],
[44,33,28,2],
[44,33,28,3],
[44,33,28,4],
[44,33,28,5],
[44,33,28,6],
[44,33,28,8],
[44,33,28,9],
[44,33,28,10],
[44,33,28,13],
[44,33,28,14],
[44,33,28,15],
[44,33,28,16],
[44,33,28,18],
[44,33,28,19],
[44,33,28,20],
[44,33,28,21],
[44,33,28,22],
[44,33,28,26],
[44,33,28,27],
[44,33,30,0],
[44,33,30,1],
[44,33,30,2],
[44,33,30,3],
[44,33,30,4],
[44,33,30,5],
[44,33,30,6],
[44,33,30,7],
[44,33,30,8],
[44,33,30,9],
[44,33,30,10],
[44,33,30,13],
[44,33,30,14],
[44,33,30,15],
[44,33,30,16],
[44,33,30,18],
[44,33,30,19],
[44,33,30,20],
[44,33,30,22],
[44,33,30,24],
[44,33,30,25],
[44,33,30,26],
[44,33,30,27],
[44,33,30,28],
[44,33,31,0],
[44,33,31,1],
[44,33,31,2],
[44,33,31,3],
[44,33,31,4],
[44,33,31,5],
[44,33,31,6],
[44,33,31,7],
[44,33,31,8],
[44,33,31,9],
[44,33,31,10],
[44,33,31,13],
[44,33,31,14],
[44,33,31,15],
[44,33,31,16],
[44,33,31,18],
[44,33,31,19],
[44,33,31,21],
[44,33,31,22],
[44,33,31,24],
[44,33,31,25],
[44,33,31,26],
[44,33,31,27],
[44,33,31,28],
[44,34,3,0],
[44,34,3,1],
[44,34,3,2],
[44,34,4,0],
[44,34,4,1],
[44,34,4,2],
[44,34,4,3],
[44,34,5,0],
[44,34,5,1],
[44,34,5,2],
[44,34,5,4],
[44,34,6,0],
[44,34,6,1],
[44,34,6,2],
[44,34,6,4],
[44,34,8,0],
[44,34,8,1],
[44,34,8,2],
[44,34,8,4],
[44,34,9,0],
[44,34,9,1],
[44,34,9,2],
[44,34,9,3],
[44,34,9,4],
[44,34,9,5],
[44,34,9,6],
[44,34,9,8],
[44,34,10,0],
[44,34,10,1],
[44,34,10,2],
[44,34,10,3],
[44,34,10,4],
[44,34,10,5],
[44,34,10,6],
[44,34,10,8],
[44,34,10,9],
[44,34,11,0],
[44,34,11,1],
[44,34,11,2],
[44,34,11,3],
[44,34,11,4],
[44,34,11,5],
[44,34,11,6],
[44,34,11,8],
[44,34,11,9],
[44,34,11,10],
[44,34,12,0],
[44,34,12,1],
[44,34,12,2],
[44,34,12,3],
[44,34,12,4],
[44,34,12,5],
[44,34,12,6],
[44,34,12,8],
[44,34,12,9],
[44,34,12,10],
[44,34,13,0],
[44,34,13,1],
[44,34,13,2],
[44,34,13,3],
[44,34,13,4],
[44,34,13,5],
[44,34,13,6],
[44,34,13,8],
[44,34,13,9],
[44,34,13,10],
[44,34,13,11],
[44,34,13,12],
[44,34,14,0],
[44,34,14,1],
[44,34,14,2],
[44,34,14,3],
[44,34,14,4],
[44,34,14,5],
[44,34,14,6],
[44,34,14,8],
[44,34,14,9],
[44,34,14,10],
[44,34,14,11],
[44,34,14,12],
[44,34,15,0],
[44,34,15,1],
[44,34,15,2],
[44,34,15,3],
[44,34,15,4],
[44,34,15,5],
[44,34,15,6],
[44,34,15,8],
[44,34,15,10],
[44,34,15,11],
[44,34,15,12],
[44,34,15,13],
[44,34,15,14],
[44,34,16,0],
[44,34,16,1],
[44,34,16,2],
[44,34,16,3],
[44,34,16,4],
[44,34,16,5],
[44,34,16,6],
[44,34,16,8],
[44,34,16,9],
[44,34,16,10],
[44,34,16,11],
[44,34,16,12],
[44,34,16,13],
[44,34,16,14],
[44,34,16,15],
[44,34,18,0],
[44,34,18,1],
[44,34,18,2],
[44,34,18,3],
[44,34,18,4],
[44,34,18,5],
[44,34,18,6],
[44,34,18,8],
[44,34,18,10],
[44,34,18,11],
[44,34,18,12],
[44,34,18,13],
[44,34,18,14],
[44,34,18,16],
[44,34,19,0],
[44,34,19,1],
[44,34,19,2],
[44,34,19,3],
[44,34,19,4],
[44,34,19,5],
[44,34,19,6],
[44,34,19,8],
[44,34,19,9],
[44,34,19,10],
[44,34,19,11],
[44,34,19,12],
[44,34,19,15],
[44,34,19,16],
[44,34,19,18],
[44,34,20,0],
[44,34,20,1],
[44,34,20,2],
[44,34,20,3],
[44,34,20,4],
[44,34,20,5],
[44,34,20,6],
[44,34,20,8],
[44,34,20,9],
[44,34,20,11],
[44,34,20,12],
[44,34,20,13],
[44,34,20,14],
[44,34,20,15],
[44,34,20,16],
[44,34,20,18],
[44,34,20,19],
[44,34,21,0],
[44,34,21,1],
[44,34,21,2],
[44,34,21,4],
[44,34,21,9],
[44,34,21,10],
[44,34,21,11],
[44,34,21,12],
[44,34,21,13],
[44,34,21,14],
[44,34,21,15],
[44,34,21,16],
[44,34,21,18],
[44,34,21,19],
[44,34,21,20],
[44,34,22,0],
[44,34,22,1],
[44,34,22,2],
[44,34,22,3],
[44,34,22,4],
[44,34,22,5],
[44,34,22,6],
[44,34,22,8],
[44,34,22,9],
[44,34,22,11],
[44,34,22,12],
[44,34,22,13],
[44,34,22,14],
[44,34,22,15],
[44,34,22,16],
[44,34,22,18],
[44,34,22,19],
[44,34,22,21],
[44,34,23,0],
[44,34,23,1],
[44,34,23,2],
[44,34,23,3],
[44,34,23,4],
[44,34,23,5],
[44,34,23,6],
[44,34,23,8],
[44,34,23,9],
[44,34,23,10],
[44,34,23,11],
[44,34,23,12],
[44,34,23,13],
[44,34,23,14],
[44,34,23,15],
[44,34,23,16],
[44,34,23,18],
[44,34,23,19],
[44,34,23,20],
[44,34,23,21],
[44,34,23,22],
[44,34,24,0],
[44,34,24,1],
[44,34,24,2],
[44,34,24,3],
[44,34,24,4],
[44,34,24,5],
[44,34,24,6],
[44,34,24,8],
[44,34,24,9],
[44,34,24,10],
[44,34,24,11],
[44,34,24,12],
[44,34,24,13],
[44,34,24,14],
[44,34,24,15],
[44,34,24,18],
[44,34,24,19],
[44,34,24,20],
[44,34,24,21],
[44,34,24,22],
[44,34,24,23],
[44,34,26,0],
[44,34,26,1],
[44,34,26,2],
[44,34,26,3],
[44,34,26,4],
[44,34,26,5],
[44,34,26,6],
[44,34,26,8],
[44,34,26,10],
[44,34,26,11],
[44,34,26,12],
[44,34,26,13],
[44,34,26,14],
[44,34,26,16],
[44,34,26,19],
[44,34,26,20],
[44,34,26,21],
[44,34,26,22],
[44,34,26,23],
[44,34,26,24],
[44,34,27,0],
[44,34,27,1],
[44,34,27,2],
[44,34,27,3],
[44,34,27,4],
[44,34,27,5],
[44,34,27,6],
[44,34,27,8],
[44,34,27,9],
[44,34,27,11],
[44,34,27,12],
[44,34,27,13],
[44,34,27,14],
[44,34,27,15],
[44,34,27,16],
[44,34,27,18],
[44,34,27,19],
[44,34,27,21],
[44,34,27,26],
[44,34,29,0],
[44,34,29,1],
[44,34,29,2],
[44,34,29,3],
[44,34,29,4],
[44,34,29,5],
[44,34,29,6],
[44,34,29,8],
[44,34,29,9],
[44,34,29,10],
[44,34,29,13],
[44,34,29,14],
[44,34,29,15],
[44,34,29,16],
[44,34,29,18],
[44,34,29,19],
[44,34,29,20],
[44,34,29,22],
[44,34,29,23],
[44,34,29,24],
[44,34,29,26],
[44,34,29,27],
[44,34,30,0],
[44,34,30,1],
[44,34,30,2],
[44,34,30,3],
[44,34,30,4],
[44,34,30,5],
[44,34,30,6],
[44,34,30,8],
[44,34,30,9],
[44,34,30,10],
[44,34,30,11],
[44,34,30,12],
[44,34,30,13],
[44,34,30,14],
[44,34,30,15],
[44,34,30,16],
[44,34,30,18],
[44,34,30,19],
[44,34,30,21],
[44,34,30,22],
[44,34,30,24],
[44,34,30,26],
[44,34,30,27],
[44,34,30,29],
[44,34,31,0],
[44,34,31,1],
[44,34,31,2],
[44,34,31,3],
[44,34,31,4],
[44,34,31,5],
[44,34,31,6],
[44,34,31,8],
[44,34,31,9],
[44,34,31,10],
[44,34,31,11],
[44,34,31,12],
[44,34,31,13],
[44,34,31,14],
[44,34,31,15],
[44,34,31,16],
[44,34,31,18],
[44,34,31,20],
[44,34,31,21],
[44,34,31,22],
[44,34,31,24],
[44,34,31,26],
[44,34,31,27],
[44,34,31,29],
[44,34,32,0],
[44,34,32,1],
[44,34,32,2],
[44,34,32,3],
[44,34,32,4],
[44,34,32,5],
[44,34,32,6],
[44,34,32,8],
[44,34,32,9],
[44,34,32,10],
[44,34,32,13],
[44,34,32,14],
[44,34,32,15],
[44,34,32,16],
[44,34,32,19],
[44,34,32,20],
[44,34,32,21],
[44,34,32,22],
[44,34,32,23],
[44,34,32,24],
[44,34,32,26],
[44,34,32,27],
[44,34,32,30],
[44,34,33,0],
[44,34,33,1],
[44,34,33,2],
[44,34,33,3],
[44,34,33,4],
[44,34,33,5],
[44,34,33,6],
[44,34,33,8],
[44,34,33,9],
[44,34,33,10],
[44,34,33,13],
[44,34,33,14],
[44,34,33,15],
[44,34,33,18],
[44,34,33,19],
[44,34,33,20],
[44,34,33,21],
[44,34,33,22],
[44,34,33,23],
[44,34,33,24],
[44,34,33,26],
[44,34,33,27],
[44,35,3,0],
[44,35,3,1],
[44,35,3,2],
[44,35,4,0],
[44,35,4,1],
[44,35,4,2],
[44,35,4,3],
[44,35,5,0],
[44,35,5,1],
[44,35,5,2],
[44,35,5,4],
[44,35,6,0],
[44,35,6,1],
[44,35,6,2],
[44,35,6,4],
[44,35,7,0],
[44,35,7,1],
[44,35,7,2],
[44,35,7,3],
[44,35,7,4],
[44,35,7,5],
[44,35,7,6],
[44,35,8,0],
[44,35,8,1],
[44,35,8,2],
[44,35,8,4],
[44,35,8,7],
[44,35,10,0],
[44,35,10,1],
[44,35,10,2],
[44,35,10,3],
[44,35,10,4],
[44,35,10,5],
[44,35,10,6],
[44,35,10,7],
[44,35,10,8],
[44,35,11,0],
[44,35,11,1],
[44,35,11,2],
[44,35,11,3],
[44,35,11,4],
[44,35,11,5],
[44,35,11,6],
[44,35,11,7],
[44,35,11,8],
[44,35,11,10],
[44,35,12,0],
[44,35,12,1],
[44,35,12,2],
[44,35,12,3],
[44,35,12,4],
[44,35,12,5],
[44,35,12,6],
[44,35,12,7],
[44,35,12,8],
[44,35,12,10],
[44,35,13,0],
[44,35,13,1],
[44,35,13,2],
[44,35,13,3],
[44,35,13,4],
[44,35,13,5],
[44,35,13,6],
[44,35,13,7],
[44,35,13,8],
[44,35,13,10],
[44,35,13,11],
[44,35,13,12],
[44,35,14,0],
[44,35,14,1],
[44,35,14,2],
[44,35,14,3],
[44,35,14,4],
[44,35,14,5],
[44,35,14,6],
[44,35,14,7],
[44,35,14,8],
[44,35,14,10],
[44,35,14,11],
[44,35,14,12],
[44,35,16,0],
[44,35,16,1],
[44,35,16,2],
[44,35,16,3],
[44,35,16,4],
[44,35,16,5],
[44,35,16,6],
[44,35,16,7],
[44,35,16,8],
[44,35,16,10],
[44,35,16,11],
[44,35,16,12],
[44,35,16,13],
[44,35,16,14],
[44,35,19,0],
[44,35,19,1],
[44,35,19,2],
[44,35,19,3],
[44,35,19,4],
[44,35,19,5],
[44,35,19,6],
[44,35,19,7],
[44,35,19,8],
[44,35,19,10],
[44,35,19,11],
[44,35,19,12],
[44,35,19,16],
[44,35,20,0],
[44,35,20,1],
[44,35,20,2],
[44,35,20,3],
[44,35,20,4],
[44,35,20,5],
[44,35,20,6],
[44,35,20,7],
[44,35,20,8],
[44,35,20,11],
[44,35,20,12],
[44,35,20,13],
[44,35,20,14],
[44,35,20,16],
[44,35,20,19],
[44,35,21,0],
[44,35,21,1],
[44,35,21,2],
[44,35,21,4],
[44,35,21,7],
[44,35,21,10],
[44,35,21,11],
[44,35,21,12],
[44,35,21,13],
[44,35,21,14],
[44,35,21,16],
[44,35,21,19],
[44,35,21,20],
[44,35,22,0],
[44,35,22,1],
[44,35,22,2],
[44,35,22,3],
[44,35,22,4],
[44,35,22,5],
[44,35,22,6],
[44,35,22,7],
[44,35,22,8],
[44,35,22,11],
[44,35,22,12],
[44,35,22,13],
[44,35,22,14],
[44,35,22,16],
[44,35,22,19],
[44,35,22,21],
[44,35,23,0],
[44,35,23,1],
[44,35,23,2],
[44,35,23,3],
[44,35,23,4],
[44,35,23,5],
[44,35,23,6],
[44,35,23,7],
[44,35,23,8],
[44,35,23,10],
[44,35,23,11],
[44,35,23,12],
[44,35,23,13],
[44,35,23,14],
[44,35,23,16],
[44,35,23,19],
[44,35,23,20],
[44,35,23,21],
[44,35,23,22],
[44,35,24,0],
[44,35,24,1],
[44,35,24,2],
[44,35,24,3],
[44,35,24,4],
[44,35,24,5],
[44,35,24,6],
[44,35,24,7],
[44,35,24,8],
[44,35,24,10],
[44,35,24,11],
[44,35,24,12],
[44,35,24,13],
[44,35,24,14],
[44,35,24,19],
[44,35,24,20],
[44,35,24,21],
[44,35,24,22],
[44,35,24,23],
[44,35,25,0],
[44,35,25,1],
[44,35,25,2],
[44,35,25,3],
[44,35,25,4],
[44,35,25,5],
[44,35,25,6],
[44,35,25,8],
[44,35,25,10],
[44,35,25,11],
[44,35,25,12],
[44,35,25,13],
[44,35,25,14],
[44,35,25,16],
[44,35,25,19],
[44,35,25,20],
[44,35,25,21],
[44,35,25,22],
[44,35,25,23],
[44,35,25,24],
[44,35,27,0],
[44,35,27,1],
[44,35,27,2],
[44,35,27,3],
[44,35,27,4],
[44,35,27,5],
[44,35,27,6],
[44,35,27,7],
[44,35,27,8],
[44,35,27,11],
[44,35,27,12],
[44,35,27,13],
[44,35,27,14],
[44,35,27,16],
[44,35,27,19],
[44,35,27,21],
[44,35,27,23],
[44,35,27,24],
[44,35,27,25],
[44,35,28,0],
[44,35,28,1],
[44,35,28,2],
[44,35,28,3],
[44,35,28,4],
[44,35,28,5],
[44,35,28,6],
[44,35,28,8],
[44,35,28,10],
[44,35,28,11],
[44,35,28,12],
[44,35,28,13],
[44,35,28,14],
[44,35,28,16],
[44,35,28,19],
[44,35,28,20],
[44,35,28,22],
[44,35,28,23],
[44,35,28,24],
[44,35,28,27],
[44,35,29,0],
[44,35,29,1],
[44,35,29,2],
[44,35,29,3],
[44,35,29,4],
[44,35,29,5],
[44,35,29,6],
[44,35,29,7],
[44,35,29,8],
[44,35,29,10],
[44,35,29,13],
[44,35,29,14],
[44,35,29,16],
[44,35,29,19],
[44,35,29,21],
[44,35,29,22],
[44,35,29,23],
[44,35,29,24],
[44,35,29,25],
[44,35,29,27],
[44,35,29,28],
[44,35,30,0],
[44,35,30,1],
[44,35,30,2],
[44,35,30,3],
[44,35,30,4],
[44,35,30,5],
[44,35,30,6],
[44,35,30,7],
[44,35,30,8],
[44,35,30,10],
[44,35,30,11],
[44,35,30,12],
[44,35,30,13],
[44,35,30,14],
[44,35,30,16],
[44,35,30,20],
[44,35,30,21],
[44,35,30,22],
[44,35,30,24],
[44,35,30,25],
[44,35,30,27],
[44,35,30,28],
[44,35,30,29],
[44,35,31,0],
[44,35,31,1],
[44,35,31,2],
[44,35,31,3],
[44,35,31,4],
[44,35,31,5],
[44,35,31,6],
[44,35,31,7],
[44,35,31,8],
[44,35,31,10],
[44,35,31,11],
[44,35,31,12],
[44,35,31,13],
[44,35,31,14],
[44,35,31,16],
[44,35,31,19],
[44,35,31,20],
[44,35,31,21],
[44,35,31,22],
[44,35,31,24],
[44,35,31,25],
[44,35,31,27],
[44,35,31,28],
[44,35,31,29],
[44,35,32,0],
[44,35,32,1],
[44,35,32,2],
[44,35,32,3],
[44,35,32,4],
[44,35,32,5],
[44,35,32,6],
[44,35,32,7],
[44,35,32,8],
[44,35,32,10],
[44,35,32,13],
[44,35,32,14],
[44,35,32,19],
[44,35,32,20],
[44,35,32,21],
[44,35,32,22],
[44,35,32,23],
[44,35,32,24],
[44,35,32,25],
[44,35,32,27],
[44,35,32,28],
[44,35,33,0],
[44,35,33,1],
[44,35,33,2],
[44,35,33,3],
[44,35,33,4],
[44,35,33,5],
[44,35,33,6],
[44,35,33,7],
[44,35,33,8],
[44,35,33,10],
[44,35,33,13],
[44,35,33,14],
[44,35,33,16],
[44,35,33,19],
[44,35,33,20],
[44,35,33,21],
[44,35,33,22],
[44,35,33,23],
[44,35,33,24],
[44,35,33,25],
[44,35,33,27],
[44,35,33,28],
[44,35,34,0],
[44,35,34,1],
[44,35,34,2],
[44,35,34,3],
[44,35,34,4],
[44,35,34,5],
[44,35,34,6],
[44,35,34,8],
[44,35,34,10],
[44,35,34,11],
[44,35,34,12],
[44,35,34,13],
[44,35,34,16],
[44,35,34,19],
[44,35,34,20],
[44,35,34,21],
[44,35,34,22],
[44,35,34,23],
[44,35,34,24],
[44,35,34,27],
[44,36,3,0],
[44,36,3,1],
[44,36,3,2],
[44,36,4,0],
[44,36,4,1],
[44,36,4,2],
[44,36,4,3],
[44,36,5,0],
[44,36,5,1],
[44,36,5,2],
[44,36,5,4],
[44,36,6,0],
[44,36,6,1],
[44,36,6,2],
[44,36,6,4],
[44,36,7,0],
[44,36,7,1],
[44,36,7,2],
[44,36,7,3],
[44,36,7,4],
[44,36,7,5],
[44,36,7,6],
[44,36,8,0],
[44,36,8,1],
[44,36,8,2],
[44,36,8,4],
[44,36,8,7],
[44,36,9,0],
[44,36,9,1],
[44,36,9,2],
[44,36,9,3],
[44,36,9,4],
[44,36,9,5],
[44,36,9,6],
[44,36,9,7],
[44,36,9,8],
[44,36,10,0],
[44,36,10,1],
[44,36,10,2],
[44,36,10,3],
[44,36,10,4],
[44,36,10,5],
[44,36,10,6],
[44,36,10,7],
[44,36,10,8],
[44,36,10,9],
[44,36,11,0],
[44,36,11,1],
[44,36,11,2],
[44,36,11,3],
[44,36,11,4],
[44,36,11,5],
[44,36,11,6],
[44,36,11,7],
[44,36,11,8],
[44,36,11,9],
[44,36,11,10],
[44,36,12,0],
[44,36,12,1],
[44,36,12,2],
[44,36,12,3],
[44,36,12,4],
[44,36,12,5],
[44,36,12,6],
[44,36,12,7],
[44,36,12,8],
[44,36,12,9],
[44,36,12,10],
[44,36,15,0],
[44,36,15,1],
[44,36,15,2],
[44,36,15,3],
[44,36,15,4],
[44,36,15,5],
[44,36,15,6],
[44,36,15,7],
[44,36,15,8],
[44,36,15,10],
[44,36,15,11],
[44,36,15,12],
[44,36,16,0],
[44,36,16,1],
[44,36,16,2],
[44,36,16,3],
[44,36,16,4],
[44,36,16,5],
[44,36,16,6],
[44,36,16,7],
[44,36,16,8],
[44,36,16,9],
[44,36,16,10],
[44,36,16,11],
[44,36,16,12],
[44,36,16,15],
[44,36,18,0],
[44,36,18,1],
[44,36,18,2],
[44,36,18,3],
[44,36,18,4],
[44,36,18,5],
[44,36,18,6],
[44,36,18,7],
[44,36,18,8],
[44,36,18,10],
[44,36,18,11],
[44,36,18,12],
[44,36,18,16],
[44,36,20,0],
[44,36,20,1],
[44,36,20,2],
[44,36,20,3],
[44,36,20,4],
[44,36,20,5],
[44,36,20,6],
[44,36,20,7],
[44,36,20,8],
[44,36,20,9],
[44,36,20,11],
[44,36,20,12],
[44,36,20,15],
[44,36,20,16],
[44,36,20,18],
[44,36,21,0],
[44,36,21,1],
[44,36,21,2],
[44,36,21,4],
[44,36,21,7],
[44,36,21,9],
[44,36,21,10],
[44,36,21,11],
[44,36,21,12],
[44,36,21,15],
[44,36,21,16],
[44,36,21,18],
[44,36,21,20],
[44,36,22,0],
[44,36,22,1],
[44,36,22,2],
[44,36,22,3],
[44,36,22,4],
[44,36,22,5],
[44,36,22,6],
[44,36,22,7],
[44,36,22,8],
[44,36,22,9],
[44,36,22,11],
[44,36,22,12],
[44,36,22,15],
[44,36,22,16],
[44,36,22,18],
[44,36,22,21],
[44,36,23,0],
[44,36,23,1],
[44,36,23,2],
[44,36,23,3],
[44,36,23,4],
[44,36,23,5],
[44,36,23,6],
[44,36,23,7],
[44,36,23,8],
[44,36,23,9],
[44,36,23,10],
[44,36,23,11],
[44,36,23,12],
[44,36,23,15],
[44,36,23,16],
[44,36,23,18],
[44,36,23,20],
[44,36,23,21],
[44,36,23,22],
[44,36,24,0],
[44,36,24,1],
[44,36,24,2],
[44,36,24,3],
[44,36,24,4],
[44,36,24,5],
[44,36,24,6],
[44,36,24,7],
[44,36,24,8],
[44,36,24,9],
[44,36,24,10],
[44,36,24,11],
[44,36,24,12],
[44,36,24,15],
[44,36,24,18],
[44,36,24,20],
[44,36,24,21],
[44,36,24,22],
[44,36,24,23],
[44,36,25,0],
[44,36,25,1],
[44,36,25,2],
[44,36,25,3],
[44,36,25,4],
[44,36,25,5],
[44,36,25,6],
[44,36,25,8],
[44,36,25,9],
[44,36,25,10],
[44,36,25,11],
[44,36,25,12],
[44,36,25,15],
[44,36,25,16],
[44,36,25,18],
[44,36,25,20],
[44,36,25,21],
[44,36,25,22],
[44,36,26,0],
[44,36,26,1],
[44,36,26,2],
[44,36,26,3],
[44,36,26,4],
[44,36,26,5],
[44,36,26,6],
[44,36,26,7],
[44,36,26,8],
[44,36,26,10],
[44,36,26,11],
[44,36,26,12],
[44,36,26,16],
[44,36,26,20],
[44,36,26,21],
[44,36,26,23],
[44,36,26,24],
[44,36,26,25],
[44,36,27,0],
[44,36,27,1],
[44,36,27,2],
[44,36,27,3],
[44,36,27,4],
[44,36,27,5],
[44,36,27,6],
[44,36,27,7],
[44,36,27,8],
[44,36,27,9],
[44,36,27,11],
[44,36,27,12],
[44,36,27,15],
[44,36,27,16],
[44,36,27,18],
[44,36,27,23],
[44,36,27,24],
[44,36,27,25],
[44,36,27,26],
[44,36,28,0],
[44,36,28,1],
[44,36,28,2],
[44,36,28,3],
[44,36,28,4],
[44,36,28,5],
[44,36,28,6],
[44,36,28,8],
[44,36,28,9],
[44,36,28,10],
[44,36,28,11],
[44,36,28,12],
[44,36,28,15],
[44,36,28,16],
[44,36,28,18],
[44,36,28,21],
[44,36,28,22],
[44,36,28,23],
[44,36,28,24],
[44,36,28,26],
[44,36,28,27],
[44,36,29,0],
[44,36,29,1],
[44,36,29,2],
[44,36,29,3],
[44,36,29,4],
[44,36,29,5],
[44,36,29,6],
[44,36,29,7],
[44,36,29,8],
[44,36,29,9],
[44,36,29,10],
[44,36,29,15],
[44,36,29,16],
[44,36,29,18],
[44,36,29,20],
[44,36,29,21],
[44,36,29,22],
[44,36,29,23],
[44,36,29,24],
[44,36,29,25],
[44,36,29,26],
[44,36,29,27],
[44,36,29,28],
[44,36,30,0],
[44,36,30,1],
[44,36,30,2],
[44,36,30,3],
[44,36,30,4],
[44,36,30,5],
[44,36,30,6],
[44,36,30,7],
[44,36,30,8],
[44,36,30,9],
[44,36,30,10],
[44,36,30,11],
[44,36,30,12],
[44,36,30,15],
[44,36,30,16],
[44,36,30,20],
[44,36,30,21],
[44,36,30,22],
[44,36,30,24],
[44,36,30,25],
[44,36,30,26],
[44,36,30,27],
[44,36,30,28],
[44,36,30,29],
[44,36,31,0],
[44,36,31,1],
[44,36,31,2],
[44,36,31,3],
[44,36,31,4],
[44,36,31,5],
[44,36,31,6],
[44,36,31,7],
[44,36,31,8],
[44,36,31,9],
[44,36,31,10],
[44,36,31,11],
[44,36,31,12],
[44,36,31,15],
[44,36,31,18],
[44,36,31,20],
[44,36,31,21],
[44,36,31,22],
[44,36,31,24],
[44,36,31,25],
[44,36,31,26],
[44,36,31,27],
[44,36,31,28],
[44,36,31,29],
[44,36,32,0],
[44,36,32,1],
[44,36,32,2],
[44,36,32,3],
[44,36,32,4],
[44,36,32,5],
[44,36,32,6],
[44,36,32,7],
[44,36,32,8],
[44,36,32,9],
[44,36,32,10],
[44,36,32,16],
[44,36,32,18],
[44,36,32,20],
[44,36,32,21],
[44,36,32,22],
[44,36,32,23],
[44,36,32,24],
[44,36,32,25],
[44,36,32,26],
[44,36,32,27],
[44,36,32,28],
[44,36,33,0],
[44,36,33,1],
[44,36,33,2],
[44,36,33,3],
[44,36,33,4],
[44,36,33,5],
[44,36,33,6],
[44,36,33,7],
[44,36,33,8],
[44,36,33,9],
[44,36,33,10],
[44,36,33,15],
[44,36,33,16],
[44,36,33,18],
[44,36,33,20],
[44,36,33,21],
[44,36,33,22],
[44,36,33,23],
[44,36,33,24],
[44,36,33,25],
[44,36,33,26],
[44,36,33,27],
[44,36,34,0],
[44,36,34,1],
[44,36,34,2],
[44,36,34,3],
[44,36,34,4],
[44,36,34,5],
[44,36,34,6],
[44,36,34,8],
[44,36,34,9],
[44,36,34,10],
[44,36,34,11],
[44,36,34,15],
[44,36,34,16],
[44,36,34,18],
[44,36,34,20],
[44,36,34,21],
[44,36,34,22],
[44,36,34,23],
[44,36,34,24],
[44,36,34,26],
[44,36,35,0],
[44,36,35,1],
[44,36,35,2],
[44,36,35,3],
[44,36,35,4],
[44,36,35,5],
[44,36,35,6],
[44,36,35,7],
[44,36,35,8],
[44,36,35,12],
[44,36,35,16],
[44,36,35,20],
[44,36,35,21],
[44,36,35,22],
[44,36,35,23],
[44,36,35,24],
[44,36,35,25],
[44,37,3,0],
[44,37,3,1],
[44,37,3,2],
[44,37,5,0],
[44,37,5,1],
[44,37,5,2],
[44,37,6,0],
[44,37,6,1],
[44,37,6,2],
[44,37,7,0],
[44,37,7,1],
[44,37,7,2],
[44,37,7,3],
[44,37,7,5],
[44,37,7,6],
[44,37,8,0],
[44,37,8,1],
[44,37,8,2],
[44,37,8,7],
[44,37,9,0],
[44,37,9,1],
[44,37,9,2],
[44,37,9,3],
[44,37,9,5],
[44,37,9,6],
[44,37,9,7],
[44,37,9,8],
[44,37,10,0],
[44,37,10,1],
[44,37,10,2],
[44,37,10,3],
[44,37,10,5],
[44,37,10,6],
[44,37,10,7],
[44,37,10,8],
[44,37,10,9],
[44,37,11,0],
[44,37,11,1],
[44,37,11,2],
[44,37,11,3],
[44,37,11,5],
[44,37,11,6],
[44,37,11,7],
[44,37,11,8],
[44,37,11,9],
[44,37,11,10],
[44,37,12,0],
[44,37,12,1],
[44,37,12,2],
[44,37,12,3],
[44,37,12,5],
[44,37,12,6],
[44,37,12,7],
[44,37,12,8],
[44,37,12,9],
[44,37,12,10],
[44,37,13,0],
[44,37,13,1],
[44,37,13,2],
[44,37,13,3],
[44,37,13,5],
[44,37,13,6],
[44,37,13,7],
[44,37,13,8],
[44,37,13,9],
[44,37,13,10],
[44,37,13,11],
[44,37,13,12],
[44,37,14,0],
[44,37,14,1],
[44,37,14,2],
[44,37,14,3],
[44,37,14,5],
[44,37,14,6],
[44,37,14,7],
[44,37,14,8],
[44,37,14,9],
[44,37,14,10],
[44,37,14,11],
[44,37,14,12],
[44,37,15,0],
[44,37,15,1],
[44,37,15,2],
[44,37,15,3],
[44,37,15,5],
[44,37,15,6],
[44,37,15,7],
[44,37,15,8],
[44,37,15,10],
[44,37,15,11],
[44,37,15,12],
[44,37,15,13],
[44,37,15,14],
[44,37,16,0],
[44,37,16,1],
[44,37,16,2],
[44,37,16,3],
[44,37,16,5],
[44,37,16,6],
[44,37,16,7],
[44,37,16,8],
[44,37,16,9],
[44,37,16,10],
[44,37,16,11],
[44,37,16,12],
[44,37,16,13],
[44,37,16,14],
[44,37,16,15],
[44,37,18,0],
[44,37,18,1],
[44,37,18,2],
[44,37,18,3],
[44,37,18,5],
[44,37,18,6],
[44,37,18,7],
[44,37,18,8],
[44,37,18,10],
[44,37,18,11],
[44,37,18,12],
[44,37,18,13],
[44,37,18,14],
[44,37,18,16],
[44,37,19,0],
[44,37,19,1],
[44,37,19,2],
[44,37,19,3],
[44,37,19,5],
[44,37,19,6],
[44,37,19,7],
[44,37,19,8],
[44,37,19,9],
[44,37,19,10],
[44,37,19,11],
[44,37,19,12],
[44,37,19,15],
[44,37,19,16],
[44,37,19,18],
[44,37,20,0],
[44,37,20,1],
[44,37,20,2],
[44,37,20,3],
[44,37,20,5],
[44,37,20,6],
[44,37,20,7],
[44,37,20,8],
[44,37,20,9],
[44,37,20,11],
[44,37,20,12],
[44,37,20,13],
[44,37,20,14],
[44,37,20,15],
[44,37,20,16],
[44,37,20,18],
[44,37,20,19],
[44,37,21,0],
[44,37,21,1],
[44,37,21,2],
[44,37,21,7],
[44,37,21,9],
[44,37,21,10],
[44,37,21,11],
[44,37,21,12],
[44,37,21,13],
[44,37,21,14],
[44,37,21,15],
[44,37,21,16],
[44,37,21,18],
[44,37,21,19],
[44,37,21,20],
[44,37,22,0],
[44,37,22,1],
[44,37,22,2],
[44,37,22,3],
[44,37,22,5],
[44,37,22,6],
[44,37,22,7],
[44,37,22,8],
[44,37,22,9],
[44,37,22,11],
[44,37,22,12],
[44,37,22,13],
[44,37,22,14],
[44,37,22,15],
[44,37,22,16],
[44,37,22,18],
[44,37,22,19],
[44,37,22,21],
[44,37,23,0],
[44,37,23,1],
[44,37,23,2],
[44,37,23,3],
[44,37,23,5],
[44,37,23,6],
[44,37,23,7],
[44,37,23,8],
[44,37,23,9],
[44,37,23,10],
[44,37,23,11],
[44,37,23,12],
[44,37,23,13],
[44,37,23,14],
[44,37,23,15],
[44,37,23,16],
[44,37,23,18],
[44,37,23,19],
[44,37,23,20],
[44,37,23,21],
[44,37,23,22],
[44,37,24,0],
[44,37,24,1],
[44,37,24,2],
[44,37,24,3],
[44,37,24,5],
[44,37,24,6],
[44,37,24,7],
[44,37,24,8],
[44,37,24,9],
[44,37,24,10],
[44,37,24,11],
[44,37,24,12],
[44,37,24,13],
[44,37,24,14],
[44,37,24,15],
[44,37,24,18],
[44,37,24,19],
[44,37,24,20],
[44,37,24,21],
[44,37,24,22],
[44,37,24,23],
[44,37,25,0],
[44,37,25,1],
[44,37,25,2],
[44,37,25,3],
[44,37,25,5],
[44,37,25,6],
[44,37,25,8],
[44,37,25,9],
[44,37,25,10],
[44,37,25,11],
[44,37,25,12],
[44,37,25,13],
[44,37,25,14],
[44,37,25,15],
[44,37,25,16],
[44,37,25,18],
[44,37,25,19],
[44,37,25,20],
[44,37,25,21],
[44,37,25,23],
[44,37,25,24],
[44,37,26,0],
[44,37,26,1],
[44,37,26,2],
[44,37,26,3],
[44,37,26,5],
[44,37,26,6],
[44,37,26,7],
[44,37,26,8],
[44,37,26,10],
[44,37,26,11],
[44,37,26,12],
[44,37,26,13],
[44,37,26,14],
[44,37,26,16],
[44,37,26,19],
[44,37,26,20],
[44,37,26,22],
[44,37,26,23],
[44,37,26,24],
[44,37,26,25],
[44,37,27,0],
[44,37,27,1],
[44,37,27,2],
[44,37,27,3],
[44,37,27,5],
[44,37,27,6],
[44,37,27,7],
[44,37,27,8],
[44,37,27,9],
[44,37,27,11],
[44,37,27,12],
[44,37,27,13],
[44,37,27,14],
[44,37,27,15],
[44,37,27,16],
[44,37,27,18],
[44,37,27,19],
[44,37,27,21],
[44,37,27,23],
[44,37,27,24],
[44,37,27,25],
[44,37,27,26],
[44,37,28,0],
[44,37,28,1],
[44,37,28,2],
[44,37,28,3],
[44,37,28,5],
[44,37,28,6],
[44,37,28,8],
[44,37,28,9],
[44,37,28,10],
[44,37,28,11],
[44,37,28,12],
[44,37,28,13],
[44,37,28,14],
[44,37,28,15],
[44,37,28,16],
[44,37,28,18],
[44,37,28,20],
[44,37,28,21],
[44,37,28,22],
[44,37,28,23],
[44,37,28,24],
[44,37,28,26],
[44,37,28,27],
[44,37,29,0],
[44,37,29,1],
[44,37,29,2],
[44,37,29,3],
[44,37,29,5],
[44,37,29,6],
[44,37,29,7],
[44,37,29,8],
[44,37,29,9],
[44,37,29,10],
[44,37,29,13],
[44,37,29,14],
[44,37,29,15],
[44,37,29,16],
[44,37,29,19],
[44,37,29,20],
[44,37,29,21],
[44,37,29,22],
[44,37,29,23],
[44,37,29,24],
[44,37,29,25],
[44,37,29,26],
[44,37,29,27],
[44,37,29,28],
[44,37,30,0],
[44,37,30,1],
[44,37,30,2],
[44,37,30,3],
[44,37,30,5],
[44,37,30,6],
[44,37,30,7],
[44,37,30,8],
[44,37,30,9],
[44,37,30,10],
[44,37,30,11],
[44,37,30,12],
[44,37,30,13],
[44,37,30,14],
[44,37,30,15],
[44,37,30,18],
[44,37,30,19],
[44,37,30,20],
[44,37,30,21],
[44,37,30,22],
[44,37,30,24],
[44,37,30,25],
[44,37,30,26],
[44,37,30,27],
[44,37,30,28],
[44,37,30,29],
[44,37,31,0],
[44,37,31,1],
[44,37,31,2],
[44,37,31,3],
[44,37,31,5],
[44,37,31,6],
[44,37,31,7],
[44,37,31,8],
[44,37,31,9],
[44,37,31,10],
[44,37,31,11],
[44,37,31,12],
[44,37,31,13],
[44,37,31,14],
[44,37,31,16],
[44,37,31,18],
[44,37,31,19],
[44,37,31,20],
[44,37,31,21],
[44,37,31,22],
[44,37,31,24],
[44,37,31,25],
[44,37,31,26],
[44,37,31,27],
[44,37,31,28],
[44,37,32,0],
[44,37,32,1],
[44,37,32,2],
[44,37,32,3],
[44,37,32,5],
[44,37,32,6],
[44,37,32,7],
[44,37,32,8],
[44,37,32,9],
[44,37,32,10],
[44,37,32,13],
[44,37,32,15],
[44,37,32,16],
[44,37,32,18],
[44,37,32,19],
[44,37,32,20],
[44,37,32,21],
[44,37,32,22],
[44,37,32,23],
[44,37,32,24],
[44,37,32,25],
[44,37,32,26],
[44,37,32,27],
[44,37,33,0],
[44,37,33,1],
[44,37,33,2],
[44,37,33,3],
[44,37,33,5],
[44,37,33,6],
[44,37,33,7],
[44,37,33,8],
[44,37,33,9],
[44,37,33,10],
[44,37,33,14],
[44,37,33,15],
[44,37,33,16],
[44,37,33,18],
[44,37,33,19],
[44,37,33,20],
[44,37,33,21],
[44,37,33,22],
[44,37,33,23],
[44,37,33,24],
[44,37,33,25],
[44,37,33,26],
[44,37,34,0],
[44,37,34,1],
[44,37,34,2],
[44,37,34,3],
[44,37,34,5],
[44,37,34,6],
[44,37,34,8],
[44,37,34,9],
[44,37,34,12],
[44,37,34,13],
[44,37,34,14],
[44,37,34,15],
[44,37,34,16],
[44,37,34,18],
[44,37,34,19],
[44,37,34,20],
[44,37,34,21],
[44,37,34,22],
[44,37,34,23],
[44,37,34,24],
[44,37,35,0],
[44,37,35,1],
[44,37,35,2],
[44,37,35,3],
[44,37,35,5],
[44,37,35,6],
[44,37,35,7],
[44,37,35,10],
[44,37,35,11],
[44,37,35,12],
[44,37,35,13],
[44,37,35,14],
[44,37,35,16],
[44,37,35,19],
[44,37,35,20],
[44,37,35,21],
[44,37,35,22],
[44,37,35,23],
[44,37,35,24],
[44,37,36,0],
[44,37,36,1],
[44,37,36,2],
[44,37,36,3],
[44,37,36,5],
[44,37,36,8],
[44,37,36,9],
[44,37,36,10],
[44,37,36,11],
[44,37,36,12],
[44,37,36,15],
[44,37,36,16],
[44,37,36,18],
[44,37,36,20],
[44,37,36,21],
[44,37,36,22],
[44,37,36,23],
[44,37,36,24],
[44,38,3,0],
[44,38,3,1],
[44,38,3,2],
[44,38,4,0],
[44,38,4,1],
[44,38,4,2],
[44,38,4,3],
[44,38,5,0],
[44,38,5,1],
[44,38,5,2],
[44,38,5,4],
[44,38,6,0],
[44,38,6,1],
[44,38,6,2],
[44,38,6,4],
[44,38,8,0],
[44,38,8,1],
[44,38,8,2],
[44,38,8,4],
[44,38,9,0],
[44,38,9,1],
[44,38,9,2],
[44,38,9,3],
[44,38,9,4],
[44,38,9,5],
[44,38,9,6],
[44,38,9,8],
[44,38,10,0],
[44,38,10,1],
[44,38,10,2],
[44,38,10,3],
[44,38,10,4],
[44,38,10,5],
[44,38,10,6],
[44,38,10,8],
[44,38,10,9],
[44,38,11,0],
[44,38,11,1],
[44,38,11,2],
[44,38,11,3],
[44,38,11,4],
[44,38,11,5],
[44,38,11,6],
[44,38,11,8],
[44,38,11,9],
[44,38,11,10],
[44,38,12,0],
[44,38,12,1],
[44,38,12,2],
[44,38,12,3],
[44,38,12,4],
[44,38,12,5],
[44,38,12,6],
[44,38,12,8],
[44,38,12,9],
[44,38,12,10],
[44,38,13,0],
[44,38,13,1],
[44,38,13,2],
[44,38,13,3],
[44,38,13,4],
[44,38,13,5],
[44,38,13,6],
[44,38,13,8],
[44,38,13,9],
[44,38,13,10],
[44,38,13,11],
[44,38,13,12],
[44,38,14,0],
[44,38,14,1],
[44,38,14,2],
[44,38,14,3],
[44,38,14,4],
[44,38,14,5],
[44,38,14,6],
[44,38,14,8],
[44,38,14,9],
[44,38,14,10],
[44,38,14,11],
[44,38,14,12],
[44,38,15,0],
[44,38,15,1],
[44,38,15,2],
[44,38,15,3],
[44,38,15,4],
[44,38,15,5],
[44,38,15,6],
[44,38,15,8],
[44,38,15,10],
[44,38,15,11],
[44,38,15,12],
[44,38,15,13],
[44,38,15,14],
[44,38,16,0],
[44,38,16,1],
[44,38,16,2],
[44,38,16,3],
[44,38,16,4],
[44,38,16,5],
[44,38,16,6],
[44,38,16,8],
[44,38,16,9],
[44,38,16,10],
[44,38,16,11],
[44,38,16,12],
[44,38,16,13],
[44,38,16,14],
[44,38,16,15],
[44,38,18,0],
[44,38,18,1],
[44,38,18,2],
[44,38,18,3],
[44,38,18,4],
[44,38,18,5],
[44,38,18,6],
[44,38,18,8],
[44,38,18,10],
[44,38,18,11],
[44,38,18,12],
[44,38,18,13],
[44,38,18,14],
[44,38,18,16],
[44,38,19,0],
[44,38,19,1],
[44,38,19,2],
[44,38,19,3],
[44,38,19,4],
[44,38,19,5],
[44,38,19,6],
[44,38,19,8],
[44,38,19,9],
[44,38,19,10],
[44,38,19,11],
[44,38,19,12],
[44,38,19,15],
[44,38,19,16],
[44,38,19,18],
[44,38,20,0],
[44,38,20,1],
[44,38,20,2],
[44,38,20,3],
[44,38,20,4],
[44,38,20,5],
[44,38,20,6],
[44,38,20,8],
[44,38,20,9],
[44,38,20,11],
[44,38,20,12],
[44,38,20,13],
[44,38,20,14],
[44,38,20,15],
[44,38,20,16],
[44,38,20,18],
[44,38,20,19],
[44,38,21,0],
[44,38,21,1],
[44,38,21,2],
[44,38,21,4],
[44,38,21,9],
[44,38,21,10],
[44,38,21,11],
[44,38,21,12],
[44,38,21,13],
[44,38,21,14],
[44,38,21,15],
[44,38,21,16],
[44,38,21,18],
[44,38,21,19],
[44,38,21,20],
[44,38,22,0],
[44,38,22,1],
[44,38,22,2],
[44,38,22,3],
[44,38,22,4],
[44,38,22,5],
[44,38,22,6],
[44,38,22,8],
[44,38,22,9],
[44,38,22,11],
[44,38,22,12],
[44,38,22,13],
[44,38,22,14],
[44,38,22,15],
[44,38,22,16],
[44,38,22,18],
[44,38,22,19],
[44,38,22,21],
[44,38,23,0],
[44,38,23,1],
[44,38,23,2],
[44,38,23,3],
[44,38,23,4],
[44,38,23,5],
[44,38,23,6],
[44,38,23,8],
[44,38,23,9],
[44,38,23,10],
[44,38,23,11],
[44,38,23,12],
[44,38,23,13],
[44,38,23,14],
[44,38,23,15],
[44,38,23,16],
[44,38,23,18],
[44,38,23,19],
[44,38,23,20],
[44,38,23,21],
[44,38,23,22],
[44,38,24,0],
[44,38,24,1],
[44,38,24,2],
[44,38,24,3],
[44,38,24,4],
[44,38,24,5],
[44,38,24,6],
[44,38,24,8],
[44,38,24,9],
[44,38,24,10],
[44,38,24,11],
[44,38,24,12],
[44,38,24,13],
[44,38,24,14],
[44,38,24,15],
[44,38,24,18],
[44,38,24,19],
[44,38,24,20],
[44,38,24,21],
[44,38,24,22],
[44,38,26,0],
[44,38,26,1],
[44,38,26,2],
[44,38,26,3],
[44,38,26,4],
[44,38,26,5],
[44,38,26,6],
[44,38,26,8],
[44,38,26,10],
[44,38,26,11],
[44,38,26,12],
[44,38,26,13],
[44,38,26,14],
[44,38,26,16],
[44,38,26,19],
[44,38,26,21],
[44,38,26,22],
[44,38,26,23],
[44,38,26,24],
[44,38,27,0],
[44,38,27,1],
[44,38,27,2],
[44,38,27,3],
[44,38,27,4],
[44,38,27,5],
[44,38,27,6],
[44,38,27,8],
[44,38,27,9],
[44,38,27,11],
[44,38,27,12],
[44,38,27,13],
[44,38,27,14],
[44,38,27,15],
[44,38,27,16],
[44,38,27,18],
[44,38,27,21],
[44,38,27,23],
[44,38,27,24],
[44,38,27,26],
[44,38,29,0],
[44,38,29,1],
[44,38,29,2],
[44,38,29,3],
[44,38,29,4],
[44,38,29,5],
[44,38,29,6],
[44,38,29,8],
[44,38,29,9],
[44,38,29,10],
[44,38,29,13],
[44,38,29,14],
[44,38,29,15],
[44,38,29,18],
[44,38,29,19],
[44,38,29,20],
[44,38,29,21],
[44,38,29,22],
[44,38,29,23],
[44,38,29,24],
[44,38,29,26],
[44,38,29,27],
[44,38,30,0],
[44,38,30,1],
[44,38,30,2],
[44,38,30,3],
[44,38,30,4],
[44,38,30,5],
[44,38,30,6],
[44,38,30,8],
[44,38,30,9],
[44,38,30,10],
[44,38,30,11],
[44,38,30,12],
[44,38,30,13],
[44,38,30,14],
[44,38,30,16],
[44,38,30,18],
[44,38,30,19],
[44,38,30,20],
[44,38,30,21],
[44,38,30,22],
[44,38,30,24],
[44,38,30,26],
[44,38,30,27],
[44,38,31,0],
[44,38,31,1],
[44,38,31,2],
[44,38,31,3],
[44,38,31,4],
[44,38,31,5],
[44,38,31,6],
[44,38,31,8],
[44,38,31,9],
[44,38,31,10],
[44,38,31,11],
[44,38,31,12],
[44,38,31,13],
[44,38,31,15],
[44,38,31,16],
[44,38,31,18],
[44,38,31,19],
[44,38,31,20],
[44,38,31,21],
[44,38,31,22],
[44,38,31,24],
[44,38,31,26],
[44,38,31,27],
[44,38,32,0],
[44,38,32,1],
[44,38,32,2],
[44,38,32,3],
[44,38,32,4],
[44,38,32,5],
[44,38,32,6],
[44,38,32,8],
[44,38,32,9],
[44,38,32,10],
[44,38,32,14],
[44,38,32,15],
[44,38,32,16],
[44,38,32,18],
[44,38,32,19],
[44,38,32,20],
[44,38,32,21],
[44,38,32,22],
[44,38,32,23],
[44,38,32,24],
[44,38,32,26],
[44,38,33,0],
[44,38,33,1],
[44,38,33,2],
[44,38,33,3],
[44,38,33,4],
[44,38,33,5],
[44,38,33,6],
[44,38,33,8],
[44,38,33,9],
[44,38,33,13],
[44,38,33,14],
[44,38,33,15],
[44,38,33,16],
[44,38,33,18],
[44,38,33,19],
[44,38,33,20],
[44,38,33,21],
[44,38,33,22],
[44,38,33,23],
[44,38,33,24],
[44,38,35,0],
[44,38,35,1],
[44,38,35,2],
[44,38,35,3],
[44,38,35,4],
[44,38,35,5],
[44,38,35,8],
[44,38,35,10],
[44,38,35,11],
[44,38,35,12],
[44,38,35,13],
[44,38,35,14],
[44,38,35,16],
[44,38,35,19],
[44,38,35,20],
[44,38,35,21],
[44,38,35,22],
[44,38,35,23],
[44,38,35,24],
[44,38,36,0],
[44,38,36,1],
[44,38,36,2],
[44,38,36,3],
[44,38,36,4],
[44,38,36,6],
[44,38,36,8],
[44,38,36,9],
[44,38,36,10],
[44,38,36,11],
[44,38,36,12],
[44,38,36,15],
[44,38,36,16],
[44,38,36,18],
[44,38,36,20],
[44,38,36,21],
[44,38,36,22],
[44,38,37,0],
[44,38,37,1],
[44,38,37,2],
[44,38,37,5],
[44,38,37,6],
[44,38,37,8],
[44,38,37,9],
[44,38,37,10],
[44,38,37,11],
[44,38,37,12],
[44,38,37,13],
[44,38,37,14],
[44,38,37,15],
[44,38,37,16],
[44,38,37,18],
[44,38,37,19],
[44,38,37,20],
[44,38,37,21],
[44,39,4,0],
[44,39,4,1],
[44,39,4,2],
[44,39,7,0],
[44,39,7,1],
[44,39,7,2],
[44,39,7,4],
[44,39,9,0],
[44,39,9,1],
[44,39,9,2],
[44,39,9,4],
[44,39,9,7],
[44,39,10,0],
[44,39,10,1],
[44,39,10,2],
[44,39,10,4],
[44,39,10,7],
[44,39,10,9],
[44,39,11,0],
[44,39,11,1],
[44,39,11,2],
[44,39,11,4],
[44,39,11,7],
[44,39,11,9],
[44,39,11,10],
[44,39,12,0],
[44,39,12,1],
[44,39,12,2],
[44,39,12,4],
[44,39,12,7],
[44,39,12,9],
[44,39,12,10],
[44,39,13,0],
[44,39,13,1],
[44,39,13,2],
[44,39,13,4],
[44,39,13,7],
[44,39,13,9],
[44,39,13,10],
[44,39,13,11],
[44,39,13,12],
[44,39,14,0],
[44,39,14,1],
[44,39,14,2],
[44,39,14,4],
[44,39,14,7],
[44,39,14,9],
[44,39,14,10],
[44,39,14,11],
[44,39,14,12],
[44,39,15,0],
[44,39,15,1],
[44,39,15,2],
[44,39,15,4],
[44,39,15,7],
[44,39,15,10],
[44,39,15,11],
[44,39,15,12],
[44,39,15,13],
[44,39,15,14],
[44,39,16,0],
[44,39,16,1],
[44,39,16,2],
[44,39,16,4],
[44,39,16,7],
[44,39,16,9],
[44,39,16,10],
[44,39,16,11],
[44,39,16,12],
[44,39,16,13],
[44,39,16,14],
[44,39,16,15],
[44,39,18,0],
[44,39,18,1],
[44,39,18,2],
[44,39,18,4],
[44,39,18,7],
[44,39,18,10],
[44,39,18,11],
[44,39,18,12],
[44,39,18,13],
[44,39,18,14],
[44,39,18,16],
[44,39,19,0],
[44,39,19,1],
[44,39,19,2],
[44,39,19,4],
[44,39,19,7],
[44,39,19,9],
[44,39,19,10],
[44,39,19,11],
[44,39,19,12],
[44,39,19,15],
[44,39,19,16],
[44,39,19,18],
[44,39,20,0],
[44,39,20,1],
[44,39,20,2],
[44,39,20,4],
[44,39,20,7],
[44,39,20,9],
[44,39,20,11],
[44,39,20,12],
[44,39,20,13],
[44,39,20,14],
[44,39,20,15],
[44,39,20,16],
[44,39,20,18],
[44,39,20,19],
[44,39,22,0],
[44,39,22,1],
[44,39,22,2],
[44,39,22,4],
[44,39,22,7],
[44,39,22,9],
[44,39,22,11],
[44,39,22,12],
[44,39,22,13],
[44,39,22,14],
[44,39,22,15],
[44,39,22,16],
[44,39,22,18],
[44,39,22,19],
[44,39,23,0],
[44,39,23,1],
[44,39,23,2],
[44,39,23,4],
[44,39,23,7],
[44,39,23,9],
[44,39,23,10],
[44,39,23,11],
[44,39,23,12],
[44,39,23,13],
[44,39,23,14],
[44,39,23,15],
[44,39,23,16],
[44,39,23,18],
[44,39,23,19],
[44,39,23,20],
[44,39,24,0],
[44,39,24,1],
[44,39,24,2],
[44,39,24,4],
[44,39,24,7],
[44,39,24,9],
[44,39,24,10],
[44,39,24,11],
[44,39,24,12],
[44,39,24,13],
[44,39,24,14],
[44,39,24,15],
[44,39,24,18],
[44,39,24,19],
[44,39,24,20],
[44,39,24,23],
[44,39,25,0],
[44,39,25,1],
[44,39,25,2],
[44,39,25,4],
[44,39,25,9],
[44,39,25,10],
[44,39,25,11],
[44,39,25,12],
[44,39,25,13],
[44,39,25,14],
[44,39,25,15],
[44,39,25,16],
[44,39,25,18],
[44,39,25,19],
[44,39,25,22],
[44,39,25,23],
[44,39,25,24],
[44,39,26,0],
[44,39,26,1],
[44,39,26,2],
[44,39,26,4],
[44,39,26,7],
[44,39,26,10],
[44,39,26,11],
[44,39,26,12],
[44,39,26,13],
[44,39,26,14],
[44,39,26,16],
[44,39,26,20],
[44,39,26,22],
[44,39,26,23],
[44,39,26,24],
[44,39,26,25],
[44,39,27,0],
[44,39,27,1],
[44,39,27,2],
[44,39,27,4],
[44,39,27,7],
[44,39,27,9],
[44,39,27,11],
[44,39,27,12],
[44,39,27,13],
[44,39,27,14],
[44,39,27,15],
[44,39,27,16],
[44,39,27,19],
[44,39,27,23],
[44,39,27,24],
[44,39,27,25],
[44,39,27,26],
[44,39,28,0],
[44,39,28,1],
[44,39,28,2],
[44,39,28,4],
[44,39,28,9],
[44,39,28,10],
[44,39,28,11],
[44,39,28,12],
[44,39,28,13],
[44,39,28,14],
[44,39,28,15],
[44,39,28,18],
[44,39,28,19],
[44,39,28,20],
[44,39,28,22],
[44,39,28,23],
[44,39,28,24],
[44,39,28,26],
[44,39,28,27],
[44,39,29,0],
[44,39,29,1],
[44,39,29,2],
[44,39,29,4],
[44,39,29,7],
[44,39,29,9],
[44,39,29,10],
[44,39,29,13],
[44,39,29,14],
[44,39,29,16],
[44,39,29,18],
[44,39,29,19],
[44,39,29,20],
[44,39,29,22],
[44,39,29,23],
[44,39,29,24],
[44,39,29,25],
[44,39,29,26],
[44,39,29,27],
[44,39,29,28],
[44,39,30,0],
[44,39,30,1],
[44,39,30,2],
[44,39,30,4],
[44,39,30,7],
[44,39,30,9],
[44,39,30,10],
[44,39,30,11],
[44,39,30,12],
[44,39,30,13],
[44,39,30,15],
[44,39,30,16],
[44,39,30,18],
[44,39,30,19],
[44,39,30,20],
[44,39,30,22],
[44,39,30,24],
[44,39,30,25],
[44,39,30,26],
[44,39,30,27],
[44,39,31,0],
[44,39,31,1],
[44,39,31,2],
[44,39,31,4],
[44,39,31,7],
[44,39,31,9],
[44,39,31,10],
[44,39,31,11],
[44,39,31,14],
[44,39,31,15],
[44,39,31,16],
[44,39,31,18],
[44,39,31,19],
[44,39,31,20],
[44,39,31,22],
[44,39,31,24],
[44,39,31,25],
[44,39,31,26],
[44,39,32,0],
[44,39,32,1],
[44,39,32,2],
[44,39,32,4],
[44,39,32,7],
[44,39,32,9],
[44,39,32,13],
[44,39,32,14],
[44,39,32,15],
[44,39,32,16],
[44,39,32,18],
[44,39,32,19],
[44,39,32,20],
[44,39,32,22],
[44,39,32,23],
[44,39,32,24],
[44,39,32,25],
[44,39,33,0],
[44,39,33,1],
[44,39,33,2],
[44,39,33,4],
[44,39,33,7],
[44,39,33,10],
[44,39,33,13],
[44,39,33,14],
[44,39,33,15],
[44,39,33,16],
[44,39,33,18],
[44,39,33,19],
[44,39,33,20],
[44,39,33,22],
[44,39,33,23],
[44,39,33,24],
[44,39,34,0],
[44,39,34,1],
[44,39,34,2],
[44,39,34,4],
[44,39,34,9],
[44,39,34,10],
[44,39,34,11],
[44,39,34,12],
[44,39,34,13],
[44,39,34,14],
[44,39,34,15],
[44,39,34,16],
[44,39,34,18],
[44,39,34,19],
[44,39,34,20],
[44,39,34,22],
[44,39,34,23],
[44,39,34,24],
[44,39,35,0],
[44,39,35,1],
[44,39,35,2],
[44,39,35,4],
[44,39,35,7],
[44,39,35,10],
[44,39,35,11],
[44,39,35,12],
[44,39,35,13],
[44,39,35,14],
[44,39,35,16],
[44,39,35,19],
[44,39,35,20],
[44,39,35,22],
[44,39,36,0],
[44,39,36,1],
[44,39,36,2],
[44,39,36,7],
[44,39,36,9],
[44,39,36,10],
[44,39,36,11],
[44,39,36,12],
[44,39,36,15],
[44,39,36,16],
[44,39,36,18],
[44,39,36,20],
[44,39,37,7],
[44,39,37,9],
[44,39,37,10],
[44,39,37,11],
[44,39,37,12],
[44,39,37,13],
[44,39,37,14],
[44,39,37,15],
[44,39,37,16],
[44,39,37,18],
[44,39,37,19],
[44,39,37,20],
[44,39,38,0],
[44,39,38,1],
[44,39,38,2],
[44,39,38,4],
[44,39,38,9],
[44,39,38,10],
[44,39,38,11],
[44,39,38,12],
[44,39,38,13],
[44,39,38,14],
[44,39,38,15],
[44,39,38,16],
[44,39,38,18],
[44,39,38,19],
[44,40,3,0],
[44,40,3,1],
[44,40,3,2],
[44,40,4,0],
[44,40,4,1],
[44,40,4,2],
[44,40,4,3],
[44,40,5,0],
[44,40,5,1],
[44,40,5,2],
[44,40,5,4],
[44,40,6,0],
[44,40,6,1],
[44,40,6,2],
[44,40,6,4],
[44,40,7,0],
[44,40,7,1],
[44,40,7,2],
[44,40,7,3],
[44,40,7,4],
[44,40,7,5],
[44,40,7,6],
[44,40,8,0],
[44,40,8,1],
[44,40,8,2],
[44,40,8,4],
[44,40,8,7],
[44,40,10,0],
[44,40,10,1],
[44,40,10,2],
[44,40,10,3],
[44,40,10,4],
[44,40,10,5],
[44,40,10,6],
[44,40,10,7],
[44,40,10,8],
[44,40,11,0],
[44,40,11,1],
[44,40,11,2],
[44,40,11,3],
[44,40,11,4],
[44,40,11,5],
[44,40,11,6],
[44,40,11,7],
[44,40,11,8],
[44,40,11,10],
[44,40,12,0],
[44,40,12,1],
[44,40,12,2],
[44,40,12,3],
[44,40,12,4],
[44,40,12,5],
[44,40,12,6],
[44,40,12,7],
[44,40,12,8],
[44,40,12,10],
[44,40,13,0],
[44,40,13,1],
[44,40,13,2],
[44,40,13,3],
[44,40,13,4],
[44,40,13,5],
[44,40,13,6],
[44,40,13,7],
[44,40,13,8],
[44,40,13,10],
[44,40,13,11],
[44,40,13,12],
[44,40,14,0],
[44,40,14,1],
[44,40,14,2],
[44,40,14,3],
[44,40,14,4],
[44,40,14,5],
[44,40,14,6],
[44,40,14,7],
[44,40,14,8],
[44,40,14,10],
[44,40,14,11],
[44,40,14,12],
[44,40,16,0],
[44,40,16,1],
[44,40,16,2],
[44,40,16,3],
[44,40,16,4],
[44,40,16,5],
[44,40,16,6],
[44,40,16,7],
[44,40,16,8],
[44,40,16,10],
[44,40,16,11],
[44,40,16,12],
[44,40,16,13],
[44,40,16,14],
[44,40,19,0],
[44,40,19,1],
[44,40,19,2],
[44,40,19,3],
[44,40,19,4],
[44,40,19,5],
[44,40,19,6],
[44,40,19,7],
[44,40,19,8],
[44,40,19,10],
[44,40,19,11],
[44,40,19,12],
[44,40,19,16],
[44,40,20,0],
[44,40,20,1],
[44,40,20,2],
[44,40,20,3],
[44,40,20,4],
[44,40,20,5],
[44,40,20,6],
[44,40,20,7],
[44,40,20,8],
[44,40,20,11],
[44,40,20,12],
[44,40,20,13],
[44,40,20,14],
[44,40,20,16],
[44,40,20,19],
[44,40,21,0],
[44,40,21,1],
[44,40,21,2],
[44,40,21,4],
[44,40,21,7],
[44,40,21,10],
[44,40,21,11],
[44,40,21,12],
[44,40,21,13],
[44,40,21,14],
[44,40,21,16],
[44,40,21,19],
[44,40,21,20],
[44,40,22,0],
[44,40,22,1],
[44,40,22,2],
[44,40,22,3],
[44,40,22,4],
[44,40,22,5],
[44,40,22,6],
[44,40,22,7],
[44,40,22,8],
[44,40,22,11],
[44,40,22,12],
[44,40,22,13],
[44,40,22,14],
[44,40,22,16],
[44,40,22,19],
[44,40,22,21],
[44,40,23,0],
[44,40,23,1],
[44,40,23,2],
[44,40,23,3],
[44,40,23,4],
[44,40,23,5],
[44,40,23,6],
[44,40,23,7],
[44,40,23,8],
[44,40,23,10],
[44,40,23,11],
[44,40,23,12],
[44,40,23,13],
[44,40,23,14],
[44,40,23,16],
[44,40,23,19],
[44,40,23,20],
[44,40,23,22],
[44,40,24,0],
[44,40,24,1],
[44,40,24,2],
[44,40,24,3],
[44,40,24,4],
[44,40,24,5],
[44,40,24,6],
[44,40,24,7],
[44,40,24,8],
[44,40,24,10],
[44,40,24,11],
[44,40,24,12],
[44,40,24,13],
[44,40,24,14],
[44,40,24,19],
[44,40,24,20],
[44,40,24,22],
[44,40,24,23],
[44,40,25,0],
[44,40,25,1],
[44,40,25,2],
[44,40,25,3],
[44,40,25,4],
[44,40,25,5],
[44,40,25,6],
[44,40,25,8],
[44,40,25,10],
[44,40,25,11],
[44,40,25,12],
[44,40,25,13],
[44,40,25,14],
[44,40,25,16],
[44,40,25,20],
[44,40,25,21],
[44,40,25,22],
[44,40,25,23],
[44,40,25,24],
[44,40,27,0],
[44,40,27,1],
[44,40,27,2],
[44,40,27,3],
[44,40,27,4],
[44,40,27,5],
[44,40,27,6],
[44,40,27,7],
[44,40,27,8],
[44,40,27,11],
[44,40,27,12],
[44,40,27,13],
[44,40,27,14],
[44,40,27,19],
[44,40,27,21],
[44,40,27,23],
[44,40,27,24],
[44,40,27,25],
[44,40,28,0],
[44,40,28,1],
[44,40,28,2],
[44,40,28,3],
[44,40,28,4],
[44,40,28,5],
[44,40,28,6],
[44,40,28,8],
[44,40,28,10],
[44,40,28,11],
[44,40,28,12],
[44,40,28,13],
[44,40,28,14],
[44,40,28,16],
[44,40,28,19],
[44,40,28,20],
[44,40,28,21],
[44,40,28,22],
[44,40,28,23],
[44,40,28,24],
[44,40,28,27],
[44,40,29,0],
[44,40,29,1],
[44,40,29,2],
[44,40,29,3],
[44,40,29,4],
[44,40,29,5],
[44,40,29,6],
[44,40,29,7],
[44,40,29,8],
[44,40,29,10],
[44,40,29,13],
[44,40,29,16],
[44,40,29,19],
[44,40,29,20],
[44,40,29,21],
[44,40,29,22],
[44,40,29,23],
[44,40,29,24],
[44,40,29,25],
[44,40,29,27],
[44,40,30,0],
[44,40,30,1],
[44,40,30,2],
[44,40,30,3],
[44,40,30,4],
[44,40,30,5],
[44,40,30,6],
[44,40,30,7],
[44,40,30,8],
[44,40,30,10],
[44,40,30,11],
[44,40,30,14],
[44,40,30,16],
[44,40,30,19],
[44,40,30,20],
[44,40,30,21],
[44,40,30,22],
[44,40,30,24],
[44,40,30,25],
[44,40,31,0],
[44,40,31,1],
[44,40,31,2],
[44,40,31,3],
[44,40,31,4],
[44,40,31,5],
[44,40,31,6],
[44,40,31,7],
[44,40,31,8],
[44,40,31,12],
[44,40,31,13],
[44,40,31,14],
[44,40,31,16],
[44,40,31,19],
[44,40,31,20],
[44,40,31,21],
[44,40,31,22],
[44,40,31,24],
[44,40,31,25],
[44,40,32,0],
[44,40,32,1],
[44,40,32,2],
[44,40,32,3],
[44,40,32,4],
[44,40,32,5],
[44,40,32,6],
[44,40,32,7],
[44,40,32,10],
[44,40,32,13],
[44,40,32,14],
[44,40,32,16],
[44,40,32,19],
[44,40,32,20],
[44,40,32,21],
[44,40,32,22],
[44,40,32,23],
[44,40,32,24],
[44,40,33,0],
[44,40,33,1],
[44,40,33,2],
[44,40,33,3],
[44,40,33,4],
[44,40,33,5],
[44,40,33,8],
[44,40,33,10],
[44,40,33,13],
[44,40,33,14],
[44,40,33,16],
[44,40,33,19],
[44,40,33,20],
[44,40,33,21],
[44,40,33,22],
[44,40,33,23],
[44,40,33,24],
[44,40,34,0],
[44,40,34,1],
[44,40,34,2],
[44,40,34,3],
[44,40,34,4],
[44,40,34,6],
[44,40,34,8],
[44,40,34,10],
[44,40,34,11],
[44,40,34,12],
[44,40,34,13],
[44,40,34,14],
[44,40,34,16],
[44,40,34,19],
[44,40,34,20],
[44,40,34,21],
[44,40,34,22],
[44,40,36,3],
[44,40,36,4],
[44,40,36,5],
[44,40,36,6],
[44,40,36,7],
[44,40,36,8],
[44,40,36,10],
[44,40,36,11],
[44,40,36,12],
[44,40,36,16],
[44,40,36,20],
[44,40,37,0],
[44,40,37,1],
[44,40,37,2],
[44,40,37,3],
[44,40,37,5],
[44,40,37,6],
[44,40,37,7],
[44,40,37,8],
[44,40,37,10],
[44,40,37,11],
[44,40,37,12],
[44,40,37,13],
[44,40,37,14],
[44,40,37,16],
[44,40,37,19],
[44,40,38,0],
[44,40,38,1],
[44,40,38,2],
[44,40,38,3],
[44,40,38,4],
[44,40,38,5],
[44,40,38,6],
[44,40,38,8],
[44,40,38,10],
[44,40,38,11],
[44,40,38,12],
[44,40,38,13],
[44,40,38,14],
[44,40,38,16],
[44,40,39,0],
[44,40,39,1],
[44,40,39,2],
[44,40,39,4],
[44,40,39,7],
[44,40,39,10],
[44,40,39,11],
[44,40,39,12],
[44,40,39,13],
[44,40,39,14],
[44,40,39,16],
[44,41,3,0],
[44,41,3,1],
[44,41,3,2],
[44,41,4,0],
[44,41,4,1],
[44,41,4,2],
[44,41,4,3],
[44,41,5,0],
[44,41,5,1],
[44,41,5,2],
[44,41,5,4],
[44,41,6,0],
[44,41,6,1],
[44,41,6,2],
[44,41,6,4],
[44,41,7,0],
[44,41,7,1],
[44,41,7,2],
[44,41,7,3],
[44,41,7,4],
[44,41,7,5],
[44,41,7,6],
[44,41,8,0],
[44,41,8,1],
[44,41,8,2],
[44,41,8,4],
[44,41,8,7],
[44,41,10,0],
[44,41,10,1],
[44,41,10,2],
[44,41,10,3],
[44,41,10,4],
[44,41,10,5],
[44,41,10,6],
[44,41,10,7],
[44,41,10,8],
[44,41,11,0],
[44,41,11,1],
[44,41,11,2],
[44,41,11,3],
[44,41,11,4],
[44,41,11,5],
[44,41,11,6],
[44,41,11,7],
[44,41,11,8],
[44,41,11,10],
[44,41,12,0],
[44,41,12,1],
[44,41,12,2],
[44,41,12,3],
[44,41,12,4],
[44,41,12,5],
[44,41,12,6],
[44,41,12,7],
[44,41,12,8],
[44,41,12,10],
[44,41,13,0],
[44,41,13,1],
[44,41,13,2],
[44,41,13,3],
[44,41,13,4],
[44,41,13,5],
[44,41,13,6],
[44,41,13,7],
[44,41,13,8],
[44,41,13,10],
[44,41,13,11],
[44,41,13,12],
[44,41,14,0],
[44,41,14,1],
[44,41,14,2],
[44,41,14,3],
[44,41,14,4],
[44,41,14,5],
[44,41,14,6],
[44,41,14,7],
[44,41,14,8],
[44,41,14,10],
[44,41,14,11],
[44,41,14,12],
[44,41,16,0],
[44,41,16,1],
[44,41,16,2],
[44,41,16,3],
[44,41,16,4],
[44,41,16,5],
[44,41,16,6],
[44,41,16,7],
[44,41,16,8],
[44,41,16,10],
[44,41,16,11],
[44,41,16,12],
[44,41,16,13],
[44,41,16,14],
[44,41,19,0],
[44,41,19,1],
[44,41,19,2],
[44,41,19,3],
[44,41,19,4],
[44,41,19,5],
[44,41,19,6],
[44,41,19,7],
[44,41,19,8],
[44,41,19,10],
[44,41,19,11],
[44,41,19,12],
[44,41,19,16],
[44,41,20,0],
[44,41,20,1],
[44,41,20,2],
[44,41,20,3],
[44,41,20,4],
[44,41,20,5],
[44,41,20,6],
[44,41,20,7],
[44,41,20,8],
[44,41,20,11],
[44,41,20,12],
[44,41,20,13],
[44,41,20,14],
[44,41,20,16],
[44,41,20,19],
[44,41,21,0],
[44,41,21,1],
[44,41,21,2],
[44,41,21,4],
[44,41,21,7],
[44,41,21,10],
[44,41,21,11],
[44,41,21,12],
[44,41,21,13],
[44,41,21,14],
[44,41,21,16],
[44,41,21,19],
[44,41,21,20],
[44,41,22,0],
[44,41,22,1],
[44,41,22,2],
[44,41,22,3],
[44,41,22,4],
[44,41,22,5],
[44,41,22,6],
[44,41,22,7],
[44,41,22,8],
[44,41,22,11],
[44,41,22,12],
[44,41,22,13],
[44,41,22,14],
[44,41,22,16],
[44,41,22,19],
[44,41,22,21],
[44,41,23,0],
[44,41,23,1],
[44,41,23,2],
[44,41,23,3],
[44,41,23,4],
[44,41,23,5],
[44,41,23,6],
[44,41,23,7],
[44,41,23,8],
[44,41,23,10],
[44,41,23,11],
[44,41,23,12],
[44,41,23,13],
[44,41,23,14],
[44,41,23,16],
[44,41,23,19],
[44,41,23,20],
[44,41,23,22],
[44,41,24,0],
[44,41,24,1],
[44,41,24,2],
[44,41,24,3],
[44,41,24,4],
[44,41,24,5],
[44,41,24,6],
[44,41,24,7],
[44,41,24,8],
[44,41,24,10],
[44,41,24,11],
[44,41,24,12],
[44,41,24,13],
[44,41,24,14],
[44,41,24,19],
[44,41,24,20],
[44,41,24,22],
[44,41,24,23],
[44,41,25,0],
[44,41,25,1],
[44,41,25,2],
[44,41,25,3],
[44,41,25,4],
[44,41,25,5],
[44,41,25,6],
[44,41,25,8],
[44,41,25,10],
[44,41,25,11],
[44,41,25,12],
[44,41,25,13],
[44,41,25,14],
[44,41,25,16],
[44,41,25,20],
[44,41,25,21],
[44,41,25,22],
[44,41,25,23],
[44,41,25,24],
[44,41,27,0],
[44,41,27,1],
[44,41,27,2],
[44,41,27,3],
[44,41,27,4],
[44,41,27,5],
[44,41,27,6],
[44,41,27,7],
[44,41,27,8],
[44,41,27,11],
[44,41,27,12],
[44,41,27,13],
[44,41,27,14],
[44,41,27,19],
[44,41,27,21],
[44,41,27,23],
[44,41,27,24],
[44,41,27,25],
[44,41,28,0],
[44,41,28,1],
[44,41,28,2],
[44,41,28,3],
[44,41,28,4],
[44,41,28,5],
[44,41,28,6],
[44,41,28,8],
[44,41,28,10],
[44,41,28,11],
[44,41,28,12],
[44,41,28,13],
[44,41,28,14],
[44,41,28,16],
[44,41,28,19],
[44,41,28,20],
[44,41,28,21],
[44,41,28,22],
[44,41,28,23],
[44,41,28,24],
[44,41,28,27],
[44,41,29,0],
[44,41,29,1],
[44,41,29,2],
[44,41,29,3],
[44,41,29,4],
[44,41,29,5],
[44,41,29,6],
[44,41,29,7],
[44,41,29,8],
[44,41,29,10],
[44,41,29,13],
[44,41,29,16],
[44,41,29,19],
[44,41,29,20],
[44,41,29,21],
[44,41,29,22],
[44,41,29,23],
[44,41,29,24],
[44,41,29,25],
[44,41,29,27],
[44,41,30,0],
[44,41,30,1],
[44,41,30,2],
[44,41,30,3],
[44,41,30,4],
[44,41,30,5],
[44,41,30,6],
[44,41,30,7],
[44,41,30,8],
[44,41,30,10],
[44,41,30,11],
[44,41,30,14],
[44,41,30,16],
[44,41,30,19],
[44,41,30,20],
[44,41,30,21],
[44,41,30,22],
[44,41,30,24],
[44,41,30,25],
[44,41,31,0],
[44,41,31,1],
[44,41,31,2],
[44,41,31,3],
[44,41,31,4],
[44,41,31,5],
[44,41,31,6],
[44,41,31,7],
[44,41,31,8],
[44,41,31,12],
[44,41,31,13],
[44,41,31,14],
[44,41,31,16],
[44,41,31,19],
[44,41,31,20],
[44,41,31,21],
[44,41,31,22],
[44,41,31,24],
[44,41,31,25],
[44,41,32,0],
[44,41,32,1],
[44,41,32,2],
[44,41,32,3],
[44,41,32,4],
[44,41,32,5],
[44,41,32,6],
[44,41,32,7],
[44,41,32,10],
[44,41,32,13],
[44,41,32,14],
[44,41,32,16],
[44,41,32,19],
[44,41,32,20],
[44,41,32,21],
[44,41,32,22],
[44,41,32,23],
[44,41,32,24],
[44,41,33,0],
[44,41,33,1],
[44,41,33,2],
[44,41,33,3],
[44,41,33,4],
[44,41,33,5],
[44,41,33,8],
[44,41,33,10],
[44,41,33,13],
[44,41,33,14],
[44,41,33,16],
[44,41,33,19],
[44,41,33,20],
[44,41,33,21],
[44,41,33,22],
[44,41,33,23],
[44,41,33,24],
[44,41,34,0],
[44,41,34,1],
[44,41,34,2],
[44,41,34,3],
[44,41,34,4],
[44,41,34,6],
[44,41,34,8],
[44,41,34,10],
[44,41,34,11],
[44,41,34,12],
[44,41,34,13],
[44,41,34,14],
[44,41,34,16],
[44,41,34,19],
[44,41,34,20],
[44,41,34,21],
[44,41,34,22],
[44,41,36,3],
[44,41,36,4],
[44,41,36,5],
[44,41,36,6],
[44,41,36,7],
[44,41,36,8],
[44,41,36,10],
[44,41,36,11],
[44,41,36,12],
[44,41,36,16],
[44,41,36,20],
[44,41,37,0],
[44,41,37,1],
[44,41,37,2],
[44,41,37,3],
[44,41,37,5],
[44,41,37,6],
[44,41,37,7],
[44,41,37,8],
[44,41,37,10],
[44,41,37,11],
[44,41,37,12],
[44,41,37,13],
[44,41,37,14],
[44,41,37,16],
[44,41,37,19],
[44,41,38,0],
[44,41,38,1],
[44,41,38,2],
[44,41,38,3],
[44,41,38,4],
[44,41,38,5],
[44,41,38,6],
[44,41,38,8],
[44,41,38,10],
[44,41,38,11],
[44,41,38,12],
[44,41,38,13],
[44,41,38,14],
[44,41,38,16],
[44,41,39,0],
[44,41,39,1],
[44,41,39,2],
[44,41,39,4],
[44,41,39,7],
[44,41,39,10],
[44,41,39,11],
[44,41,39,12],
[44,41,39,13],
[44,41,39,14],
[44,41,39,16],
[44,42,3,0],
[44,42,3,1],
[44,42,3,2],
[44,42,4,0],
[44,42,4,1],
[44,42,4,2],
[44,42,4,3],
[44,42,5,0],
[44,42,5,1],
[44,42,5,2],
[44,42,5,4],
[44,42,6,0],
[44,42,6,1],
[44,42,6,2],
[44,42,6,4],
[44,42,7,0],
[44,42,7,1],
[44,42,7,2],
[44,42,7,3],
[44,42,7,4],
[44,42,7,5],
[44,42,7,6],
[44,42,8,0],
[44,42,8,1],
[44,42,8,2],
[44,42,8,4],
[44,42,8,7],
[44,42,9,0],
[44,42,9,1],
[44,42,9,2],
[44,42,9,3],
[44,42,9,4],
[44,42,9,5],
[44,42,9,6],
[44,42,9,7],
[44,42,9,8],
[44,42,11,0],
[44,42,11,1],
[44,42,11,2],
[44,42,11,3],
[44,42,11,4],
[44,42,11,5],
[44,42,11,6],
[44,42,11,7],
[44,42,11,8],
[44,42,11,9],
[44,42,12,0],
[44,42,12,1],
[44,42,12,2],
[44,42,12,3],
[44,42,12,4],
[44,42,12,5],
[44,42,12,6],
[44,42,12,7],
[44,42,12,8],
[44,42,12,9],
[44,42,13,0],
[44,42,13,1],
[44,42,13,2],
[44,42,13,3],
[44,42,13,4],
[44,42,13,5],
[44,42,13,6],
[44,42,13,7],
[44,42,13,8],
[44,42,13,9],
[44,42,13,11],
[44,42,13,12],
[44,42,14,0],
[44,42,14,1],
[44,42,14,2],
[44,42,14,3],
[44,42,14,4],
[44,42,14,5],
[44,42,14,6],
[44,42,14,7],
[44,42,14,8],
[44,42,14,9],
[44,42,14,11],
[44,42,14,12],
[44,42,15,0],
[44,42,15,1],
[44,42,15,2],
[44,42,15,3],
[44,42,15,4],
[44,42,15,5],
[44,42,15,6],
[44,42,15,7],
[44,42,15,8],
[44,42,15,11],
[44,42,15,12],
[44,42,15,13],
[44,42,15,14],
[44,42,16,0],
[44,42,16,1],
[44,42,16,2],
[44,42,16,3],
[44,42,16,4],
[44,42,16,5],
[44,42,16,6],
[44,42,16,7],
[44,42,16,8],
[44,42,16,9],
[44,42,16,11],
[44,42,16,12],
[44,42,16,13],
[44,42,16,14],
[44,42,16,15],
[44,42,18,0],
[44,42,18,1],
[44,42,18,2],
[44,42,18,3],
[44,42,18,4],
[44,42,18,5],
[44,42,18,6],
[44,42,18,7],
[44,42,18,8],
[44,42,18,11],
[44,42,18,12],
[44,42,18,13],
[44,42,18,14],
[44,42,18,16],
[44,42,19,0],
[44,42,19,1],
[44,42,19,2],
[44,42,19,3],
[44,42,19,4],
[44,42,19,5],
[44,42,19,6],
[44,42,19,7],
[44,42,19,8],
[44,42,19,9],
[44,42,19,11],
[44,42,19,12],
[44,42,19,15],
[44,42,19,16],
[44,42,19,18],
[44,42,21,0],
[44,42,21,1],
[44,42,21,2],
[44,42,21,4],
[44,42,21,7],
[44,42,21,9],
[44,42,21,11],
[44,42,21,12],
[44,42,21,13],
[44,42,21,14],
[44,42,21,15],
[44,42,21,16],
[44,42,21,18],
[44,42,21,19],
[44,42,23,0],
[44,42,23,1],
[44,42,23,2],
[44,42,23,3],
[44,42,23,4],
[44,42,23,5],
[44,42,23,6],
[44,42,23,7],
[44,42,23,8],
[44,42,23,9],
[44,42,23,11],
[44,42,23,12],
[44,42,23,13],
[44,42,23,14],
[44,42,23,15],
[44,42,23,16],
[44,42,23,18],
[44,42,23,19],
[44,42,23,21],
[44,42,24,0],
[44,42,24,1],
[44,42,24,2],
[44,42,24,3],
[44,42,24,4],
[44,42,24,5],
[44,42,24,6],
[44,42,24,7],
[44,42,24,8],
[44,42,24,9],
[44,42,24,11],
[44,42,24,12],
[44,42,24,13],
[44,42,24,14],
[44,42,24,15],
[44,42,24,18],
[44,42,24,19],
[44,42,24,21],
[44,42,24,23],
[44,42,25,0],
[44,42,25,1],
[44,42,25,2],
[44,42,25,3],
[44,42,25,4],
[44,42,25,5],
[44,42,25,6],
[44,42,25,8],
[44,42,25,9],
[44,42,25,11],
[44,42,25,12],
[44,42,25,13],
[44,42,25,14],
[44,42,25,15],
[44,42,25,16],
[44,42,25,19],
[44,42,25,21],
[44,42,25,23],
[44,42,25,24],
[44,42,26,0],
[44,42,26,1],
[44,42,26,2],
[44,42,26,3],
[44,42,26,4],
[44,42,26,5],
[44,42,26,6],
[44,42,26,7],
[44,42,26,8],
[44,42,26,11],
[44,42,26,12],
[44,42,26,13],
[44,42,26,14],
[44,42,26,19],
[44,42,26,21],
[44,42,26,23],
[44,42,26,24],
[44,42,26,25],
[44,42,28,0],
[44,42,28,1],
[44,42,28,2],
[44,42,28,3],
[44,42,28,4],
[44,42,28,5],
[44,42,28,6],
[44,42,28,8],
[44,42,28,9],
[44,42,28,11],
[44,42,28,12],
[44,42,28,13],
[44,42,28,15],
[44,42,28,16],
[44,42,28,18],
[44,42,28,19],
[44,42,28,21],
[44,42,28,23],
[44,42,28,24],
[44,42,28,26],
[44,42,29,0],
[44,42,29,1],
[44,42,29,2],
[44,42,29,3],
[44,42,29,4],
[44,42,29,5],
[44,42,29,6],
[44,42,29,7],
[44,42,29,8],
[44,42,29,9],
[44,42,29,14],
[44,42,29,15],
[44,42,29,16],
[44,42,29,18],
[44,42,29,19],
[44,42,29,21],
[44,42,29,23],
[44,42,29,24],
[44,42,29,25],
[44,42,29,26],
[44,42,30,0],
[44,42,30,1],
[44,42,30,2],
[44,42,30,3],
[44,42,30,4],
[44,42,30,5],
[44,42,30,6],
[44,42,30,7],
[44,42,30,8],
[44,42,30,9],
[44,42,30,12],
[44,42,30,13],
[44,42,30,14],
[44,42,30,15],
[44,42,30,16],
[44,42,30,18],
[44,42,30,19],
[44,42,30,21],
[44,42,30,24],
[44,42,30,25],
[44,42,31,0],
[44,42,31,1],
[44,42,31,2],
[44,42,31,3],
[44,42,31,4],
[44,42,31,5],
[44,42,31,6],
[44,42,31,7],
[44,42,31,11],
[44,42,31,12],
[44,42,31,13],
[44,42,31,14],
[44,42,31,15],
[44,42,31,16],
[44,42,31,18],
[44,42,31,19],
[44,42,31,21],
[44,42,31,24],
[44,42,32,0],
[44,42,32,1],
[44,42,32,2],
[44,42,32,3],
[44,42,32,4],
[44,42,32,5],
[44,42,32,8],
[44,42,32,9],
[44,42,32,13],
[44,42,32,14],
[44,42,32,15],
[44,42,32,16],
[44,42,32,18],
[44,42,32,19],
[44,42,32,21],
[44,42,32,23],
[44,42,32,24],
[44,42,33,0],
[44,42,33,1],
[44,42,33,2],
[44,42,33,3],
[44,42,33,4],
[44,42,33,6],
[44,42,33,7],
[44,42,33,8],
[44,42,33,9],
[44,42,33,13],
[44,42,33,14],
[44,42,33,15],
[44,42,33,16],
[44,42,33,18],
[44,42,33,19],
[44,42,33,21],
[44,42,34,0],
[44,42,34,1],
[44,42,34,2],
[44,42,34,5],
[44,42,34,6],
[44,42,34,8],
[44,42,34,9],
[44,42,34,11],
[44,42,34,12],
[44,42,34,13],
[44,42,34,14],
[44,42,34,15],
[44,42,34,16],
[44,42,34,18],
[44,42,34,19],
[44,42,34,21],
[44,42,35,3],
[44,42,35,4],
[44,42,35,5],
[44,42,35,6],
[44,42,35,7],
[44,42,35,8],
[44,42,35,11],
[44,42,35,12],
[44,42,35,13],
[44,42,35,14],
[44,42,35,16],
[44,42,35,19],
[44,42,36,0],
[44,42,36,1],
[44,42,36,2],
[44,42,36,3],
[44,42,36,4],
[44,42,36,5],
[44,42,36,6],
[44,42,36,7],
[44,42,36,8],
[44,42,36,9],
[44,42,36,11],
[44,42,36,12],
[44,42,36,15],
[44,42,36,16],
[44,42,36,18],
[44,42,37,0],
[44,42,37,1],
[44,42,37,2],
[44,42,37,3],
[44,42,37,5],
[44,42,37,6],
[44,42,37,7],
[44,42,37,8],
[44,42,37,9],
[44,42,37,11],
[44,42,37,12],
[44,42,37,13],
[44,42,37,14],
[44,42,37,15],
[44,42,37,16],
[44,42,37,18],
[44,42,38,0],
[44,42,38,1],
[44,42,38,2],
[44,42,38,3],
[44,42,38,4],
[44,42,38,5],
[44,42,38,6],
[44,42,38,8],
[44,42,38,9],
[44,42,38,11],
[44,42,38,12],
[44,42,38,13],
[44,42,38,14],
[44,42,38,15],
[44,42,38,16],
[44,42,39,0],
[44,42,39,1],
[44,42,39,2],
[44,42,39,4],
[44,42,39,7],
[44,42,39,9],
[44,42,39,11],
[44,42,39,12],
[44,42,39,13],
[44,42,39,14],
[44,42,39,15],
[44,42,40,0],
[44,42,40,1],
[44,42,40,2],
[44,42,40,3],
[44,42,40,4],
[44,42,40,5],
[44,42,40,6],
[44,42,40,7],
[44,42,40,8],
[44,42,40,11],
[44,42,40,12],
[44,42,40,13],
[44,42,40,14],
[44,42,41,0],
[44,42,41,1],
[44,42,41,2],
[44,42,41,3],
[44,42,41,4],
[44,42,41,5],
[44,42,41,6],
[44,42,41,7],
[44,42,41,8],
[44,42,41,11],
[44,42,41,12],
[44,42,41,13],
[44,42,41,14],
[44,43,3,0],
[44,43,3,1],
[44,43,3,2],
[44,43,4,0],
[44,43,4,1],
[44,43,4,2],
[44,43,4,3],
[44,43,5,0],
[44,43,5,1],
[44,43,5,2],
[44,43,5,4],
[44,43,6,0],
[44,43,6,1],
[44,43,6,2],
[44,43,6,4],
[44,43,8,0],
[44,43,8,1],
[44,43,8,2],
[44,43,8,4],
[44,43,9,0],
[44,43,9,1],
[44,43,9,2],
[44,43,9,3],
[44,43,9,4],
[44,43,9,5],
[44,43,9,6],
[44,43,9,8],
[44,43,10,0],
[44,43,10,1],
[44,43,10,2],
[44,43,10,3],
[44,43,10,4],
[44,43,10,5],
[44,43,10,6],
[44,43,10,8],
[44,43,10,9],
[44,43,11,0],
[44,43,11,1],
[44,43,11,2],
[44,43,11,3],
[44,43,11,4],
[44,43,11,5],
[44,43,11,6],
[44,43,11,8],
[44,43,11,9],
[44,43,11,10],
[44,43,12,0],
[44,43,12,1],
[44,43,12,2],
[44,43,12,3],
[44,43,12,4],
[44,43,12,5],
[44,43,12,6],
[44,43,12,8],
[44,43,12,9],
[44,43,12,10],
[44,43,13,0],
[44,43,13,1],
[44,43,13,2],
[44,43,13,3],
[44,43,13,4],
[44,43,13,5],
[44,43,13,6],
[44,43,13,8],
[44,43,13,9],
[44,43,13,10],
[44,43,13,11],
[44,43,13,12],
[44,43,14,0],
[44,43,14,1],
[44,43,14,2],
[44,43,14,3],
[44,43,14,4],
[44,43,14,5],
[44,43,14,6],
[44,43,14,8],
[44,43,14,9],
[44,43,14,10],
[44,43,14,11],
[44,43,14,12],
[44,43,15,0],
[44,43,15,1],
[44,43,15,2],
[44,43,15,3],
[44,43,15,4],
[44,43,15,5],
[44,43,15,6],
[44,43,15,8],
[44,43,15,10],
[44,43,15,11],
[44,43,15,12],
[44,43,15,13],
[44,43,15,14],
[44,43,16,0],
[44,43,16,1],
[44,43,16,2],
[44,43,16,3],
[44,43,16,4],
[44,43,16,5],
[44,43,16,6],
[44,43,16,8],
[44,43,16,9],
[44,43,16,10],
[44,43,16,11],
[44,43,16,12],
[44,43,16,13],
[44,43,16,14],
[44,43,16,15],
[44,43,18,0],
[44,43,18,1],
[44,43,18,2],
[44,43,18,3],
[44,43,18,4],
[44,43,18,5],
[44,43,18,6],
[44,43,18,8],
[44,43,18,10],
[44,43,18,11],
[44,43,18,12],
[44,43,18,13],
[44,43,18,14],
[44,43,18,16],
[44,43,19,0],
[44,43,19,1],
[44,43,19,2],
[44,43,19,3],
[44,43,19,4],
[44,43,19,5],
[44,43,19,6],
[44,43,19,8],
[44,43,19,9],
[44,43,19,10],
[44,43,19,11],
[44,43,19,12],
[44,43,19,15],
[44,43,19,16],
[44,43,19,18],
[44,43,20,0],
[44,43,20,1],
[44,43,20,2],
[44,43,20,3],
[44,43,20,4],
[44,43,20,5],
[44,43,20,6],
[44,43,20,8],
[44,43,20,9],
[44,43,20,11],
[44,43,20,12],
[44,43,20,13],
[44,43,20,14],
[44,43,20,15],
[44,43,20,16],
[44,43,20,18],
[44,43,20,19],
[44,43,21,0],
[44,43,21,1],
[44,43,21,2],
[44,43,21,4],
[44,43,21,9],
[44,43,21,10],
[44,43,21,11],
[44,43,21,12],
[44,43,21,13],
[44,43,21,14],
[44,43,21,15],
[44,43,21,16],
[44,43,21,18],
[44,43,21,19],
[44,43,21,20],
[44,43,22,0],
[44,43,22,1],
[44,43,22,2],
[44,43,22,3],
[44,43,22,4],
[44,43,22,5],
[44,43,22,6],
[44,43,22,8],
[44,43,22,9],
[44,43,22,11],
[44,43,22,12],
[44,43,22,13],
[44,43,22,14],
[44,43,22,15],
[44,43,22,16],
[44,43,22,18],
[44,43,22,19],
[44,43,22,21],
[44,43,23,0],
[44,43,23,1],
[44,43,23,2],
[44,43,23,3],
[44,43,23,4],
[44,43,23,5],
[44,43,23,6],
[44,43,23,8],
[44,43,23,9],
[44,43,23,10],
[44,43,23,11],
[44,43,23,12],
[44,43,23,13],
[44,43,23,14],
[44,43,23,15],
[44,43,23,16],
[44,43,23,18],
[44,43,23,20],
[44,43,23,21],
[44,43,23,22],
[44,43,24,0],
[44,43,24,1],
[44,43,24,2],
[44,43,24,3],
[44,43,24,4],
[44,43,24,5],
[44,43,24,6],
[44,43,24,8],
[44,43,24,9],
[44,43,24,10],
[44,43,24,11],
[44,43,24,12],
[44,43,24,13],
[44,43,24,14],
[44,43,24,15],
[44,43,24,18],
[44,43,24,20],
[44,43,24,21],
[44,43,24,22],
[44,43,24,23],
[44,43,26,0],
[44,43,26,1],
[44,43,26,2],
[44,43,26,3],
[44,43,26,4],
[44,43,26,5],
[44,43,26,6],
[44,43,26,8],
[44,43,26,10],
[44,43,26,11],
[44,43,26,12],
[44,43,26,13],
[44,43,26,14],
[44,43,26,16],
[44,43,26,19],
[44,43,26,20],
[44,43,26,21],
[44,43,26,22],
[44,43,26,23],
[44,43,26,24],
[44,43,27,0],
[44,43,27,1],
[44,43,27,2],
[44,43,27,3],
[44,43,27,4],
[44,43,27,5],
[44,43,27,6],
[44,43,27,8],
[44,43,27,9],
[44,43,27,11],
[44,43,27,12],
[44,43,27,13],
[44,43,27,15],
[44,43,27,16],
[44,43,27,18],
[44,43,27,19],
[44,43,27,21],
[44,43,27,23],
[44,43,27,24],
[44,43,27,26],
[44,43,29,0],
[44,43,29,1],
[44,43,29,2],
[44,43,29,3],
[44,43,29,4],
[44,43,29,5],
[44,43,29,6],
[44,43,29,8],
[44,43,29,9],
[44,43,29,13],
[44,43,29,14],
[44,43,29,15],
[44,43,29,16],
[44,43,29,18],
[44,43,29,19],
[44,43,29,20],
[44,43,29,21],
[44,43,29,22],
[44,43,29,23],
[44,43,29,24],
[44,43,30,0],
[44,43,30,1],
[44,43,30,2],
[44,43,30,3],
[44,43,30,4],
[44,43,30,5],
[44,43,30,6],
[44,43,30,10],
[44,43,30,11],
[44,43,30,12],
[44,43,30,13],
[44,43,30,14],
[44,43,30,15],
[44,43,30,16],
[44,43,30,18],
[44,43,30,19],
[44,43,30,20],
[44,43,30,21],
[44,43,30,22],
[44,43,30,24],
[44,43,31,0],
[44,43,31,1],
[44,43,31,2],
[44,43,31,3],
[44,43,31,4],
[44,43,31,5],
[44,43,31,8],
[44,43,31,9],
[44,43,31,10],
[44,43,31,11],
[44,43,31,12],
[44,43,31,13],
[44,43,31,14],
[44,43,31,15],
[44,43,31,16],
[44,43,31,18],
[44,43,31,19],
[44,43,31,20],
[44,43,31,21],
[44,43,31,22],
[44,43,31,24],
[44,43,32,0],
[44,43,32,1],
[44,43,32,2],
[44,43,32,3],
[44,43,32,4],
[44,43,32,6],
[44,43,32,8],
[44,43,32,9],
[44,43,32,10],
[44,43,32,13],
[44,43,32,14],
[44,43,32,15],
[44,43,32,16],
[44,43,32,18],
[44,43,32,19],
[44,43,32,20],
[44,43,32,21],
[44,43,32,22],
[44,43,33,0],
[44,43,33,1],
[44,43,33,2],
[44,43,33,5],
[44,43,33,6],
[44,43,33,8],
[44,43,33,9],
[44,43,33,10],
[44,43,33,13],
[44,43,33,14],
[44,43,33,15],
[44,43,33,16],
[44,43,33,18],
[44,43,33,19],
[44,43,33,20],
[44,43,33,21],
[44,43,35,0],
[44,43,35,1],
[44,43,35,2],
[44,43,35,3],
[44,43,35,4],
[44,43,35,5],
[44,43,35,6],
[44,43,35,8],
[44,43,35,10],
[44,43,35,11],
[44,43,35,12],
[44,43,35,13],
[44,43,35,14],
[44,43,35,16],
[44,43,35,19],
[44,43,36,0],
[44,43,36,1],
[44,43,36,2],
[44,43,36,3],
[44,43,36,4],
[44,43,36,5],
[44,43,36,6],
[44,43,36,8],
[44,43,36,9],
[44,43,36,10],
[44,43,36,11],
[44,43,36,12],
[44,43,36,15],
[44,43,36,16],
[44,43,36,18],
[44,43,37,0],
[44,43,37,1],
[44,43,37,2],
[44,43,37,3],
[44,43,37,5],
[44,43,37,6],
[44,43,37,8],
[44,43,37,9],
[44,43,37,10],
[44,43,37,11],
[44,43,37,12],
[44,43,37,13],
[44,43,37,14],
[44,43,37,15],
[44,43,37,16],
[44,43,39,0],
[44,43,39,1],
[44,43,39,2],
[44,43,39,4],
[44,43,39,9],
[44,43,39,10],
[44,43,39,11],
[44,43,39,12],
[44,43,39,13],
[44,43,39,14],
[44,43,40,0],
[44,43,40,1],
[44,43,40,2],
[44,43,40,3],
[44,43,40,4],
[44,43,40,5],
[44,43,40,6],
[44,43,40,8],
[44,43,40,10],
[44,43,40,11],
[44,43,40,12],
[44,43,40,13],
[44,43,41,0],
[44,43,41,1],
[44,43,41,2],
[44,43,41,3],
[44,43,41,4],
[44,43,41,5],
[44,43,41,6],
[44,43,41,8],
[44,43,41,10],
[44,43,41,11],
[44,43,41,12],
[44,43,41,13],
[44,43,42,0],
[44,43,42,1],
[44,43,42,2],
[44,43,42,3],
[44,43,42,4],
[44,43,42,5],
[44,43,42,6],
[44,43,42,8],
[44,43,42,9],
[44,43,42,11],
[45,4,3,0],
[45,4,3,1],
[45,4,3,2],
[45,5,4,0],
[45,5,4,1],
[45,5,4,2],
[45,6,4,0],
[45,6,4,1],
[45,6,4,2],
[45,7,3,0],
[45,7,3,1],
[45,7,3,2],
[45,7,4,0],
[45,7,4,1],
[45,7,4,2],
[45,7,4,3],
[45,7,5,0],
[45,7,5,1],
[45,7,5,2],
[45,7,5,4],
[45,7,6,0],
[45,7,6,1],
[45,7,6,2],
[45,7,6,4],
[45,8,4,0],
[45,8,4,1],
[45,8,4,2],
[45,8,7,0],
[45,8,7,1],
[45,8,7,2],
[45,8,7,4],
[45,10,3,0],
[45,10,3,1],
[45,10,3,2],
[45,10,4,0],
[45,10,4,1],
[45,10,4,2],
[45,10,4,3],
[45,10,5,0],
[45,10,5,1],
[45,10,5,2],
[45,10,5,4],
[45,10,6,0],
[45,10,6,1],
[45,10,6,2],
[45,10,6,4],
[45,10,7,0],
[45,10,7,1],
[45,10,7,2],
[45,10,7,3],
[45,10,7,4],
[45,10,7,5],
[45,10,7,6],
[45,10,8,0],
[45,10,8,1],
[45,10,8,2],
[45,10,8,4],
[45,10,8,7],
[45,11,3,0],
[45,11,3,1],
[45,11,3,2],
[45,11,4,0],
[45,11,4,1],
[45,11,4,2],
[45,11,4,3],
[45,11,5,0],
[45,11,5,1],
[45,11,5,2],
[45,11,5,4],
[45,11,6,0],
[45,11,6,1],
[45,11,6,2],
[45,11,6,4],
[45,11,7,0],
[45,11,7,1],
[45,11,7,2],
[45,11,7,3],
[45,11,7,4],
[45,11,7,5],
[45,11,7,6],
[45,11,8,0],
[45,11,8,1],
[45,11,8,2],
[45,11,8,4],
[45,11,8,7],
[45,11,10,0],
[45,11,10,1],
[45,11,10,2],
[45,11,10,3],
[45,11,10,4],
[45,11,10,5],
[45,11,10,6],
[45,11,10,7],
[45,11,10,8],
[45,12,3,0],
[45,12,3,1],
[45,12,3,2],
[45,12,4,0],
[45,12,4,1],
[45,12,4,2],
[45,12,4,3],
[45,12,5,0],
[45,12,5,1],
[45,12,5,2],
[45,12,5,4],
[45,12,6,0],
[45,12,6,1],
[45,12,6,2],
[45,12,6,4],
[45,12,7,0],
[45,12,7,1],
[45,12,7,2],
[45,12,7,3],
[45,12,7,4],
[45,12,7,5],
[45,12,7,6],
[45,12,8,0],
[45,12,8,1],
[45,12,8,2],
[45,12,8,4],
[45,12,8,7],
[45,12,10,0],
[45,12,10,1],
[45,12,10,2],
[45,12,10,3],
[45,12,10,4],
[45,12,10,5],
[45,12,10,6],
[45,12,10,7],
[45,12,10,8],
[45,13,3,0],
[45,13,3,1],
[45,13,3,2],
[45,13,4,0],
[45,13,4,1],
[45,13,4,2],
[45,13,4,3],
[45,13,5,0],
[45,13,5,1],
[45,13,5,2],
[45,13,5,4],
[45,13,6,0],
[45,13,6,1],
[45,13,6,2],
[45,13,6,4],
[45,13,7,0],
[45,13,7,1],
[45,13,7,2],
[45,13,7,3],
[45,13,7,4],
[45,13,7,5],
[45,13,7,6],
[45,13,8,0],
[45,13,8,1],
[45,13,8,2],
[45,13,8,4],
[45,13,8,7],
[45,13,10,0],
[45,13,10,1],
[45,13,10,2],
[45,13,10,3],
[45,13,10,4],
[45,13,10,5],
[45,13,10,6],
[45,13,10,7],
[45,13,10,8],
[45,13,11,0],
[45,13,11,1],
[45,13,11,2],
[45,13,11,3],
[45,13,11,4],
[45,13,11,5],
[45,13,11,6],
[45,13,11,7],
[45,13,11,8],
[45,13,11,10],
[45,13,12,0],
[45,13,12,1],
[45,13,12,2],
[45,13,12,3],
[45,13,12,4],
[45,13,12,5],
[45,13,12,6],
[45,13,12,7],
[45,13,12,8],
[45,13,12,10],
[45,14,3,0],
[45,14,3,1],
[45,14,3,2],
[45,14,4,0],
[45,14,4,1],
[45,14,4,2],
[45,14,4,3],
[45,14,5,0],
[45,14,5,1],
[45,14,5,2],
[45,14,5,4],
[45,14,6,0],
[45,14,6,1],
[45,14,6,2],
[45,14,6,4],
[45,14,7,0],
[45,14,7,1],
[45,14,7,2],
[45,14,7,3],
[45,14,7,4],
[45,14,7,5],
[45,14,7,6],
[45,14,8,0],
[45,14,8,1],
[45,14,8,2],
[45,14,8,4],
[45,14,8,7],
[45,14,10,0],
[45,14,10,1],
[45,14,10,2],
[45,14,10,3],
[45,14,10,4],
[45,14,10,5],
[45,14,10,6],
[45,14,10,7],
[45,14,10,8],
[45,14,11,0],
[45,14,11,1],
[45,14,11,2],
[45,14,11,3],
[45,14,11,4],
[45,14,11,5],
[45,14,11,6],
[45,14,11,7],
[45,14,11,8],
[45,14,11,10],
[45,14,12,0],
[45,14,12,1],
[45,14,12,2],
[45,14,12,3],
[45,14,12,4],
[45,14,12,5],
[45,14,12,6],
[45,14,12,7],
[45,14,12,8],
[45,14,12,10],
[45,16,3,0],
[45,16,3,1],
[45,16,3,2],
[45,16,4,0],
[45,16,4,1],
[45,16,4,2],
[45,16,4,3],
[45,16,5,0],
[45,16,5,1],
[45,16,5,2],
[45,16,5,4],
[45,16,6,0],
[45,16,6,1],
[45,16,6,2],
[45,16,6,4],
[45,16,7,0],
[45,16,7,1],
[45,16,7,2],
[45,16,7,3],
[45,16,7,4],
[45,16,7,5],
[45,16,7,6],
[45,16,8,0],
[45,16,8,1],
[45,16,8,2],
[45,16,8,4],
[45,16,8,7],
[45,16,10,0],
[45,16,10,1],
[45,16,10,2],
[45,16,10,3],
[45,16,10,4],
[45,16,10,5],
[45,16,10,6],
[45,16,10,7],
[45,16,10,8],
[45,16,11,0],
[45,16,11,1],
[45,16,11,2],
[45,16,11,3],
[45,16,11,4],
[45,16,11,5],
[45,16,11,6],
[45,16,11,7],
[45,16,11,8],
[45,16,11,10],
[45,16,12,0],
[45,16,12,1],
[45,16,12,2],
[45,16,12,3],
[45,16,12,4],
[45,16,12,5],
[45,16,12,6],
[45,16,12,7],
[45,16,12,8],
[45,16,12,10],
[45,16,13,0],
[45,16,13,1],
[45,16,13,2],
[45,16,13,3],
[45,16,13,4],
[45,16,13,5],
[45,16,13,6],
[45,16,13,7],
[45,16,13,8],
[45,16,13,10],
[45,16,13,11],
[45,16,13,12],
[45,16,14,0],
[45,16,14,1],
[45,16,14,2],
[45,16,14,3],
[45,16,14,4],
[45,16,14,5],
[45,16,14,6],
[45,16,14,7],
[45,16,14,8],
[45,16,14,10],
[45,16,14,11],
[45,16,14,12],
[45,17,3,0],
[45,17,3,1],
[45,17,3,2],
[45,17,4,0],
[45,17,4,1],
[45,17,4,2],
[45,17,4,3],
[45,17,5,0],
[45,17,5,1],
[45,17,5,2],
[45,17,5,4],
[45,17,6,0],
[45,17,6,1],
[45,17,6,2],
[45,17,6,4],
[45,17,7,0],
[45,17,7,1],
[45,17,7,2],
[45,17,7,3],
[45,17,7,4],
[45,17,7,5],
[45,17,7,6],
[45,17,8,0],
[45,17,8,1],
[45,17,8,2],
[45,17,8,4],
[45,17,8,7],
[45,17,10,0],
[45,17,10,1],
[45,17,10,2],
[45,17,10,3],
[45,17,10,4],
[45,17,10,5],
[45,17,10,6],
[45,17,10,7],
[45,17,10,8],
[45,17,11,0],
[45,17,11,1],
[45,17,11,2],
[45,17,11,3],
[45,17,11,4],
[45,17,11,5],
[45,17,11,6],
[45,17,11,7],
[45,17,11,8],
[45,17,11,10],
[45,17,12,0],
[45,17,12,1],
[45,17,12,2],
[45,17,12,3],
[45,17,12,4],
[45,17,12,5],
[45,17,12,6],
[45,17,12,7],
[45,17,12,8],
[45,17,12,10],
[45,17,13,0],
[45,17,13,1],
[45,17,13,2],
[45,17,13,3],
[45,17,13,4],
[45,17,13,5],
[45,17,13,6],
[45,17,13,7],
[45,17,13,8],
[45,17,13,10],
[45,17,13,11],
[45,17,13,12],
[45,17,14,0],
[45,17,14,1],
[45,17,14,2],
[45,17,14,3],
[45,17,14,4],
[45,17,14,5],
[45,17,14,6],
[45,17,14,7],
[45,17,14,8],
[45,17,14,10],
[45,17,14,11],
[45,17,14,12],
[45,17,16,0],
[45,17,16,1],
[45,17,16,2],
[45,17,16,3],
[45,17,16,4],
[45,17,16,5],
[45,17,16,6],
[45,17,16,7],
[45,17,16,8],
[45,17,16,10],
[45,17,16,11],
[45,17,16,12],
[45,17,16,13],
[45,17,16,14],
[45,19,3,0],
[45,19,3,1],
[45,19,3,2],
[45,19,4,0],
[45,19,4,1],
[45,19,4,2],
[45,19,4,3],
[45,19,5,0],
[45,19,5,1],
[45,19,5,2],
[45,19,5,4],
[45,19,6,0],
[45,19,6,1],
[45,19,6,2],
[45,19,6,4],
[45,19,7,0],
[45,19,7,1],
[45,19,7,2],
[45,19,7,3],
[45,19,7,4],
[45,19,7,5],
[45,19,7,6],
[45,19,8,0],
[45,19,8,1],
[45,19,8,2],
[45,19,8,4],
[45,19,8,7],
[45,19,10,0],
[45,19,10,1],
[45,19,10,2],
[45,19,10,3],
[45,19,10,4],
[45,19,10,5],
[45,19,10,6],
[45,19,10,7],
[45,19,10,8],
[45,19,11,0],
[45,19,11,1],
[45,19,11,2],
[45,19,11,3],
[45,19,11,4],
[45,19,11,5],
[45,19,11,6],
[45,19,11,7],
[45,19,11,8],
[45,19,11,10],
[45,19,12,0],
[45,19,12,1],
[45,19,12,2],
[45,19,12,3],
[45,19,12,4],
[45,19,12,5],
[45,19,12,6],
[45,19,12,7],
[45,19,12,8],
[45,19,12,10],
[45,19,16,0],
[45,19,16,1],
[45,19,16,2],
[45,19,16,3],
[45,19,16,4],
[45,19,16,5],
[45,19,16,6],
[45,19,16,7],
[45,19,16,8],
[45,19,16,10],
[45,19,16,11],
[45,19,16,12],
[45,19,17,0],
[45,19,17,1],
[45,19,17,2],
[45,19,17,3],
[45,19,17,4],
[45,19,17,5],
[45,19,17,6],
[45,19,17,7],
[45,19,17,8],
[45,19,17,10],
[45,19,17,11],
[45,19,17,12],
[45,19,17,16],
[45,20,3,0],
[45,20,3,1],
[45,20,3,2],
[45,20,4,0],
[45,20,4,1],
[45,20,4,2],
[45,20,4,3],
[45,20,5,0],
[45,20,5,1],
[45,20,5,2],
[45,20,5,4],
[45,20,6,0],
[45,20,6,1],
[45,20,6,2],
[45,20,6,4],
[45,20,7,0],
[45,20,7,1],
[45,20,7,2],
[45,20,7,3],
[45,20,7,4],
[45,20,7,5],
[45,20,7,6],
[45,20,8,0],
[45,20,8,1],
[45,20,8,2],
[45,20,8,4],
[45,20,8,7],
[45,20,11,0],
[45,20,11,1],
[45,20,11,2],
[45,20,11,3],
[45,20,11,4],
[45,20,11,5],
[45,20,11,6],
[45,20,11,7],
[45,20,11,8],
[45,20,12,0],
[45,20,12,1],
[45,20,12,2],
[45,20,12,3],
[45,20,12,4],
[45,20,12,5],
[45,20,12,6],
[45,20,12,7],
[45,20,12,8],
[45,20,13,0],
[45,20,13,1],
[45,20,13,2],
[45,20,13,3],
[45,20,13,4],
[45,20,13,5],
[45,20,13,6],
[45,20,13,7],
[45,20,13,8],
[45,20,13,11],
[45,20,13,12],
[45,20,14,0],
[45,20,14,1],
[45,20,14,2],
[45,20,14,3],
[45,20,14,4],
[45,20,14,5],
[45,20,14,6],
[45,20,14,7],
[45,20,14,8],
[45,20,14,11],
[45,20,14,12],
[45,20,16,0],
[45,20,16,1],
[45,20,16,2],
[45,20,16,3],
[45,20,16,4],
[45,20,16,5],
[45,20,16,6],
[45,20,16,7],
[45,20,16,8],
[45,20,16,11],
[45,20,16,12],
[45,20,16,13],
[45,20,16,14],
[45,20,17,0],
[45,20,17,1],
[45,20,17,2],
[45,20,17,3],
[45,20,17,4],
[45,20,17,5],
[45,20,17,6],
[45,20,17,7],
[45,20,17,8],
[45,20,17,11],
[45,20,17,12],
[45,20,17,13],
[45,20,17,14],
[45,20,17,16],
[45,20,19,0],
[45,20,19,1],
[45,20,19,2],
[45,20,19,3],
[45,20,19,4],
[45,20,19,5],
[45,20,19,6],
[45,20,19,7],
[45,20,19,8],
[45,20,19,11],
[45,20,19,12],
[45,20,19,16],
[45,20,19,17],
[45,21,4,0],
[45,21,4,1],
[45,21,4,2],
[45,21,7,0],
[45,21,7,1],
[45,21,7,2],
[45,21,7,4],
[45,21,10,0],
[45,21,10,1],
[45,21,10,2],
[45,21,10,4],
[45,21,10,7],
[45,21,11,0],
[45,21,11,1],
[45,21,11,2],
[45,21,11,4],
[45,21,11,7],
[45,21,11,10],
[45,21,12,0],
[45,21,12,1],
[45,21,12,2],
[45,21,12,4],
[45,21,12,7],
[45,21,12,10],
[45,21,13,0],
[45,21,13,1],
[45,21,13,2],
[45,21,13,4],
[45,21,13,7],
[45,21,13,10],
[45,21,13,11],
[45,21,13,12],
[45,21,14,0],
[45,21,14,1],
[45,21,14,2],
[45,21,14,4],
[45,21,14,7],
[45,21,14,10],
[45,21,14,11],
[45,21,14,12],
[45,21,16,0],
[45,21,16,1],
[45,21,16,2],
[45,21,16,4],
[45,21,16,7],
[45,21,16,10],
[45,21,16,11],
[45,21,16,12],
[45,21,16,13],
[45,21,16,14],
[45,21,17,0],
[45,21,17,1],
[45,21,17,2],
[45,21,17,4],
[45,21,17,7],
[45,21,17,10],
[45,21,17,11],
[45,21,17,12],
[45,21,17,13],
[45,21,17,14],
[45,21,17,16],
[45,21,19,0],
[45,21,19,1],
[45,21,19,2],
[45,21,19,4],
[45,21,19,7],
[45,21,19,10],
[45,21,19,11],
[45,21,19,12],
[45,21,19,16],
[45,21,19,17],
[45,21,20,0],
[45,21,20,1],
[45,21,20,2],
[45,21,20,4],
[45,21,20,7],
[45,21,20,11],
[45,21,20,12],
[45,21,20,13],
[45,21,20,14],
[45,21,20,16],
[45,21,20,17],
[45,21,20,19],
[45,22,3,0],
[45,22,3,1],
[45,22,3,2],
[45,22,4,0],
[45,22,4,1],
[45,22,4,2],
[45,22,4,3],
[45,22,5,0],
[45,22,5,1],
[45,22,5,2],
[45,22,5,4],
[45,22,6,0],
[45,22,6,1],
[45,22,6,2],
[45,22,6,4],
[45,22,7,0],
[45,22,7,1],
[45,22,7,2],
[45,22,7,3],
[45,22,7,4],
[45,22,7,5],
[45,22,7,6],
[45,22,8,0],
[45,22,8,1],
[45,22,8,2],
[45,22,8,4],
[45,22,8,7],
[45,22,11,0],
[45,22,11,1],
[45,22,11,2],
[45,22,11,3],
[45,22,11,4],
[45,22,11,5],
[45,22,11,6],
[45,22,11,7],
[45,22,11,8],
[45,22,12,0],
[45,22,12,1],
[45,22,12,2],
[45,22,12,3],
[45,22,12,4],
[45,22,12,5],
[45,22,12,6],
[45,22,12,7],
[45,22,12,8],
[45,22,13,0],
[45,22,13,1],
[45,22,13,2],
[45,22,13,3],
[45,22,13,4],
[45,22,13,5],
[45,22,13,6],
[45,22,13,7],
[45,22,13,8],
[45,22,13,11],
[45,22,13,12],
[45,22,14,0],
[45,22,14,1],
[45,22,14,2],
[45,22,14,3],
[45,22,14,4],
[45,22,14,5],
[45,22,14,6],
[45,22,14,7],
[45,22,14,8],
[45,22,14,11],
[45,22,14,12],
[45,22,16,0],
[45,22,16,1],
[45,22,16,2],
[45,22,16,3],
[45,22,16,4],
[45,22,16,5],
[45,22,16,6],
[45,22,16,7],
[45,22,16,8],
[45,22,16,11],
[45,22,16,12],
[45,22,16,13],
[45,22,16,14],
[45,22,17,0],
[45,22,17,1],
[45,22,17,2],
[45,22,17,3],
[45,22,17,4],
[45,22,17,5],
[45,22,17,6],
[45,22,17,7],
[45,22,17,8],
[45,22,17,11],
[45,22,17,12],
[45,22,17,13],
[45,22,17,14],
[45,22,17,16],
[45,22,19,0],
[45,22,19,1],
[45,22,19,2],
[45,22,19,3],
[45,22,19,4],
[45,22,19,5],
[45,22,19,6],
[45,22,19,7],
[45,22,19,8],
[45,22,19,11],
[45,22,19,12],
[45,22,19,16],
[45,22,19,17],
[45,22,21,0],
[45,22,21,1],
[45,22,21,2],
[45,22,21,4],
[45,22,21,7],
[45,22,21,11],
[45,22,21,12],
[45,22,21,13],
[45,22,21,14],
[45,22,21,16],
[45,22,21,17],
[45,22,21,19],
[45,23,3,0],
[45,23,3,1],
[45,23,3,2],
[45,23,4,0],
[45,23,4,1],
[45,23,4,2],
[45,23,4,3],
[45,23,5,0],
[45,23,5,1],
[45,23,5,2],
[45,23,5,4],
[45,23,6,0],
[45,23,6,1],
[45,23,6,2],
[45,23,6,4],
[45,23,7,0],
[45,23,7,1],
[45,23,7,2],
[45,23,7,3],
[45,23,7,4],
[45,23,7,5],
[45,23,7,6],
[45,23,8,0],
[45,23,8,1],
[45,23,8,2],
[45,23,8,4],
[45,23,8,7],
[45,23,10,0],
[45,23,10,1],
[45,23,10,2],
[45,23,10,3],
[45,23,10,4],
[45,23,10,5],
[45,23,10,6],
[45,23,10,7],
[45,23,10,8],
[45,23,11,0],
[45,23,11,1],
[45,23,11,2],
[45,23,11,3],
[45,23,11,4],
[45,23,11,5],
[45,23,11,6],
[45,23,11,7],
[45,23,11,8],
[45,23,11,10],
[45,23,12,0],
[45,23,12,1],
[45,23,12,2],
[45,23,12,3],
[45,23,12,4],
[45,23,12,5],
[45,23,12,6],
[45,23,12,7],
[45,23,12,8],
[45,23,12,10],
[45,23,13,0],
[45,23,13,1],
[45,23,13,2],
[45,23,13,3],
[45,23,13,4],
[45,23,13,5],
[45,23,13,6],
[45,23,13,7],
[45,23,13,8],
[45,23,13,10],
[45,23,13,11],
[45,23,13,12],
[45,23,14,0],
[45,23,14,1],
[45,23,14,2],
[45,23,14,3],
[45,23,14,4],
[45,23,14,5],
[45,23,14,6],
[45,23,14,7],
[45,23,14,8],
[45,23,14,10],
[45,23,14,11],
[45,23,14,12],
[45,23,16,0],
[45,23,16,1],
[45,23,16,2],
[45,23,16,3],
[45,23,16,4],
[45,23,16,5],
[45,23,16,6],
[45,23,16,7],
[45,23,16,8],
[45,23,16,10],
[45,23,16,11],
[45,23,16,12],
[45,23,16,13],
[45,23,16,14],
[45,23,17,0],
[45,23,17,1],
[45,23,17,2],
[45,23,17,3],
[45,23,17,4],
[45,23,17,5],
[45,23,17,6],
[45,23,17,7],
[45,23,17,8],
[45,23,17,10],
[45,23,17,11],
[45,23,17,12],
[45,23,17,13],
[45,23,17,14],
[45,23,17,16],
[45,23,19,0],
[45,23,19,1],
[45,23,19,2],
[45,23,19,3],
[45,23,19,4],
[45,23,19,5],
[45,23,19,6],
[45,23,19,7],
[45,23,19,8],
[45,23,19,10],
[45,23,19,11],
[45,23,19,12],
[45,23,19,16],
[45,23,19,17],
[45,23,20,0],
[45,23,20,1],
[45,23,20,2],
[45,23,20,3],
[45,23,20,4],
[45,23,20,5],
[45,23,20,6],
[45,23,20,7],
[45,23,20,8],
[45,23,20,11],
[45,23,20,12],
[45,23,20,13],
[45,23,20,14],
[45,23,20,16],
[45,23,20,17],
[45,23,20,19],
[45,23,21,0],
[45,23,21,1],
[45,23,21,2],
[45,23,21,4],
[45,23,21,7],
[45,23,21,10],
[45,23,21,11],
[45,23,21,12],
[45,23,21,13],
[45,23,21,14],
[45,23,21,16],
[45,23,21,17],
[45,23,21,19],
[45,23,21,20],
[45,23,22,0],
[45,23,22,1],
[45,23,22,2],
[45,23,22,3],
[45,23,22,4],
[45,23,22,5],
[45,23,22,6],
[45,23,22,7],
[45,23,22,8],
[45,23,22,11],
[45,23,22,12],
[45,23,22,13],
[45,23,22,14],
[45,23,22,16],
[45,23,22,17],
[45,23,22,19],
[45,23,22,21],
[45,24,3,0],
[45,24,3,1],
[45,24,3,2],
[45,24,4,0],
[45,24,4,1],
[45,24,4,2],
[45,24,4,3],
[45,24,5,0],
[45,24,5,1],
[45,24,5,2],
[45,24,5,4],
[45,24,6,0],
[45,24,6,1],
[45,24,6,2],
[45,24,6,4],
[45,24,7,0],
[45,24,7,1],
[45,24,7,2],
[45,24,7,3],
[45,24,7,4],
[45,24,7,5],
[45,24,7,6],
[45,24,8,0],
[45,24,8,1],
[45,24,8,2],
[45,24,8,4],
[45,24,8,7],
[45,24,10,0],
[45,24,10,1],
[45,24,10,2],
[45,24,10,3],
[45,24,10,4],
[45,24,10,5],
[45,24,10,6],
[45,24,10,7],
[45,24,10,8],
[45,24,11,0],
[45,24,11,1],
[45,24,11,2],
[45,24,11,3],
[45,24,11,4],
[45,24,11,5],
[45,24,11,6],
[45,24,11,7],
[45,24,11,8],
[45,24,11,10],
[45,24,12,0],
[45,24,12,1],
[45,24,12,2],
[45,24,12,3],
[45,24,12,4],
[45,24,12,5],
[45,24,12,6],
[45,24,12,7],
[45,24,12,8],
[45,24,12,10],
[45,24,13,0],
[45,24,13,1],
[45,24,13,2],
[45,24,13,3],
[45,24,13,4],
[45,24,13,5],
[45,24,13,6],
[45,24,13,7],
[45,24,13,8],
[45,24,13,10],
[45,24,13,11],
[45,24,13,12],
[45,24,14,0],
[45,24,14,1],
[45,24,14,2],
[45,24,14,3],
[45,24,14,4],
[45,24,14,5],
[45,24,14,6],
[45,24,14,7],
[45,24,14,8],
[45,24,14,10],
[45,24,14,11],
[45,24,14,12],
[45,24,17,0],
[45,24,17,1],
[45,24,17,2],
[45,24,17,3],
[45,24,17,4],
[45,24,17,5],
[45,24,17,6],
[45,24,17,7],
[45,24,17,8],
[45,24,17,10],
[45,24,17,11],
[45,24,17,12],
[45,24,17,13],
[45,24,17,14],
[45,24,19,0],
[45,24,19,1],
[45,24,19,2],
[45,24,19,3],
[45,24,19,4],
[45,24,19,5],
[45,24,19,6],
[45,24,19,7],
[45,24,19,8],
[45,24,19,10],
[45,24,19,11],
[45,24,19,12],
[45,24,19,17],
[45,24,20,0],
[45,24,20,1],
[45,24,20,2],
[45,24,20,3],
[45,24,20,4],
[45,24,20,5],
[45,24,20,6],
[45,24,20,7],
[45,24,20,8],
[45,24,20,11],
[45,24,20,12],
[45,24,20,13],
[45,24,20,14],
[45,24,20,17],
[45,24,20,19],
[45,24,21,0],
[45,24,21,1],
[45,24,21,2],
[45,24,21,4],
[45,24,21,7],
[45,24,21,10],
[45,24,21,11],
[45,24,21,12],
[45,24,21,13],
[45,24,21,14],
[45,24,21,17],
[45,24,21,19],
[45,24,21,20],
[45,24,22,0],
[45,24,22,1],
[45,24,22,2],
[45,24,22,3],
[45,24,22,4],
[45,24,22,5],
[45,24,22,6],
[45,24,22,7],
[45,24,22,8],
[45,24,22,11],
[45,24,22,12],
[45,24,22,13],
[45,24,22,14],
[45,24,22,17],
[45,24,22,19],
[45,24,22,21],
[45,24,23,0],
[45,24,23,1],
[45,24,23,2],
[45,24,23,3],
[45,24,23,4],
[45,24,23,5],
[45,24,23,6],
[45,24,23,7],
[45,24,23,8],
[45,24,23,10],
[45,24,23,11],
[45,24,23,12],
[45,24,23,13],
[45,24,23,14],
[45,24,23,17],
[45,24,23,19],
[45,24,23,20],
[45,24,23,21],
[45,24,23,22],
[45,25,3,0],
[45,25,3,1],
[45,25,3,2],
[45,25,4,0],
[45,25,4,1],
[45,25,4,2],
[45,25,4,3],
[45,25,5,0],
[45,25,5,1],
[45,25,5,2],
[45,25,5,4],
[45,25,6,0],
[45,25,6,1],
[45,25,6,2],
[45,25,6,4],
[45,25,8,0],
[45,25,8,1],
[45,25,8,2],
[45,25,8,4],
[45,25,10,0],
[45,25,10,1],
[45,25,10,2],
[45,25,10,3],
[45,25,10,4],
[45,25,10,5],
[45,25,10,6],
[45,25,10,8],
[45,25,11,0],
[45,25,11,1],
[45,25,11,2],
[45,25,11,3],
[45,25,11,4],
[45,25,11,5],
[45,25,11,6],
[45,25,11,8],
[45,25,11,10],
[45,25,12,0],
[45,25,12,1],
[45,25,12,2],
[45,25,12,3],
[45,25,12,4],
[45,25,12,5],
[45,25,12,6],
[45,25,12,8],
[45,25,12,10],
[45,25,13,0],
[45,25,13,1],
[45,25,13,2],
[45,25,13,3],
[45,25,13,4],
[45,25,13,5],
[45,25,13,6],
[45,25,13,8],
[45,25,13,10],
[45,25,13,11],
[45,25,13,12],
[45,25,14,0],
[45,25,14,1],
[45,25,14,2],
[45,25,14,3],
[45,25,14,4],
[45,25,14,5],
[45,25,14,6],
[45,25,14,8],
[45,25,14,10],
[45,25,14,11],
[45,25,14,12],
[45,25,16,0],
[45,25,16,1],
[45,25,16,2],
[45,25,16,3],
[45,25,16,4],
[45,25,16,5],
[45,25,16,6],
[45,25,16,8],
[45,25,16,10],
[45,25,16,11],
[45,25,16,12],
[45,25,16,13],
[45,25,16,14],
[45,25,17,0],
[45,25,17,1],
[45,25,17,2],
[45,25,17,3],
[45,25,17,4],
[45,25,17,5],
[45,25,17,6],
[45,25,17,8],
[45,25,17,10],
[45,25,17,11],
[45,25,17,12],
[45,25,17,13],
[45,25,17,14],
[45,25,17,16],
[45,25,19,0],
[45,25,19,1],
[45,25,19,2],
[45,25,19,3],
[45,25,19,4],
[45,25,19,5],
[45,25,19,6],
[45,25,19,8],
[45,25,19,10],
[45,25,19,11],
[45,25,19,12],
[45,25,19,16],
[45,25,19,17],
[45,25,20,0],
[45,25,20,1],
[45,25,20,2],
[45,25,20,3],
[45,25,20,4],
[45,25,20,5],
[45,25,20,6],
[45,25,20,8],
[45,25,20,11],
[45,25,20,12],
[45,25,20,13],
[45,25,20,14],
[45,25,20,16],
[45,25,20,17],
[45,25,20,19],
[45,25,21,0],
[45,25,21,1],
[45,25,21,2],
[45,25,21,4],
[45,25,21,10],
[45,25,21,11],
[45,25,21,12],
[45,25,21,13],
[45,25,21,14],
[45,25,21,16],
[45,25,21,17],
[45,25,21,19],
[45,25,21,20],
[45,25,22,0],
[45,25,22,1],
[45,25,22,2],
[45,25,22,3],
[45,25,22,4],
[45,25,22,5],
[45,25,22,6],
[45,25,22,8],
[45,25,22,11],
[45,25,22,12],
[45,25,22,13],
[45,25,22,14],
[45,25,22,16],
[45,25,22,17],
[45,25,22,19],
[45,25,22,21],
[45,25,23,0],
[45,25,23,1],
[45,25,23,2],
[45,25,23,3],
[45,25,23,4],
[45,25,23,5],
[45,25,23,6],
[45,25,23,8],
[45,25,23,10],
[45,25,23,11],
[45,25,23,12],
[45,25,23,13],
[45,25,23,14],
[45,25,23,16],
[45,25,23,17],
[45,25,23,19],
[45,25,23,20],
[45,25,23,21],
[45,25,23,22],
[45,25,24,0],
[45,25,24,1],
[45,25,24,2],
[45,25,24,3],
[45,25,24,4],
[45,25,24,5],
[45,25,24,6],
[45,25,24,8],
[45,25,24,10],
[45,25,24,11],
[45,25,24,12],
[45,25,24,13],
[45,25,24,14],
[45,25,24,17],
[45,25,24,19],
[45,25,24,20],
[45,25,24,21],
[45,25,24,22],
[45,25,24,23],
[45,27,3,0],
[45,27,3,1],
[45,27,3,2],
[45,27,4,0],
[45,27,4,1],
[45,27,4,2],
[45,27,4,3],
[45,27,5,0],
[45,27,5,1],
[45,27,5,2],
[45,27,5,4],
[45,27,6,0],
[45,27,6,1],
[45,27,6,2],
[45,27,6,4],
[45,27,7,0],
[45,27,7,1],
[45,27,7,2],
[45,27,7,3],
[45,27,7,4],
[45,27,7,5],
[45,27,7,6],
[45,27,8,0],
[45,27,8,1],
[45,27,8,2],
[45,27,8,4],
[45,27,8,7],
[45,27,11,0],
[45,27,11,1],
[45,27,11,2],
[45,27,11,3],
[45,27,11,4],
[45,27,11,5],
[45,27,11,6],
[45,27,11,7],
[45,27,11,8],
[45,27,12,0],
[45,27,12,1],
[45,27,12,2],
[45,27,12,3],
[45,27,12,4],
[45,27,12,5],
[45,27,12,6],
[45,27,12,7],
[45,27,12,8],
[45,27,13,0],
[45,27,13,1],
[45,27,13,2],
[45,27,13,3],
[45,27,13,4],
[45,27,13,5],
[45,27,13,6],
[45,27,13,7],
[45,27,13,8],
[45,27,13,11],
[45,27,13,12],
[45,27,14,0],
[45,27,14,1],
[45,27,14,2],
[45,27,14,3],
[45,27,14,4],
[45,27,14,5],
[45,27,14,6],
[45,27,14,7],
[45,27,14,8],
[45,27,14,11],
[45,27,14,12],
[45,27,16,0],
[45,27,16,1],
[45,27,16,2],
[45,27,16,3],
[45,27,16,4],
[45,27,16,5],
[45,27,16,6],
[45,27,16,7],
[45,27,16,8],
[45,27,16,11],
[45,27,16,12],
[45,27,16,13],
[45,27,16,14],
[45,27,17,0],
[45,27,17,1],
[45,27,17,2],
[45,27,17,3],
[45,27,17,4],
[45,27,17,5],
[45,27,17,6],
[45,27,17,7],
[45,27,17,8],
[45,27,17,11],
[45,27,17,12],
[45,27,17,13],
[45,27,17,14],
[45,27,17,16],
[45,27,19,0],
[45,27,19,1],
[45,27,19,2],
[45,27,19,3],
[45,27,19,4],
[45,27,19,5],
[45,27,19,6],
[45,27,19,7],
[45,27,19,8],
[45,27,19,11],
[45,27,19,12],
[45,27,19,16],
[45,27,19,17],
[45,27,21,0],
[45,27,21,1],
[45,27,21,2],
[45,27,21,4],
[45,27,21,7],
[45,27,21,11],
[45,27,21,12],
[45,27,21,13],
[45,27,21,14],
[45,27,21,16],
[45,27,21,17],
[45,27,21,19],
[45,27,23,0],
[45,27,23,1],
[45,27,23,2],
[45,27,23,3],
[45,27,23,4],
[45,27,23,5],
[45,27,23,6],
[45,27,23,7],
[45,27,23,8],
[45,27,23,11],
[45,27,23,12],
[45,27,23,13],
[45,27,23,14],
[45,27,23,16],
[45,27,23,17],
[45,27,23,19],
[45,27,23,21],
[45,27,24,0],
[45,27,24,1],
[45,27,24,2],
[45,27,24,3],
[45,27,24,4],
[45,27,24,5],
[45,27,24,6],
[45,27,24,7],
[45,27,24,8],
[45,27,24,11],
[45,27,24,12],
[45,27,24,13],
[45,27,24,14],
[45,27,24,17],
[45,27,24,19],
[45,27,24,21],
[45,27,24,23],
[45,27,25,0],
[45,27,25,1],
[45,27,25,2],
[45,27,25,3],
[45,27,25,4],
[45,27,25,5],
[45,27,25,6],
[45,27,25,8],
[45,27,25,11],
[45,27,25,12],
[45,27,25,13],
[45,27,25,14],
[45,27,25,16],
[45,27,25,17],
[45,27,25,19],
[45,27,25,21],
[45,27,25,23],
[45,27,25,24],
[45,28,3,0],
[45,28,3,1],
[45,28,3,2],
[45,28,4,0],
[45,28,4,1],
[45,28,4,2],
[45,28,4,3],
[45,28,5,0],
[45,28,5,1],
[45,28,5,2],
[45,28,5,4],
[45,28,6,0],
[45,28,6,1],
[45,28,6,2],
[45,28,6,4],
[45,28,8,0],
[45,28,8,1],
[45,28,8,2],
[45,28,8,4],
[45,28,10,0],
[45,28,10,1],
[45,28,10,2],
[45,28,10,3],
[45,28,10,4],
[45,28,10,5],
[45,28,10,6],
[45,28,10,8],
[45,28,11,0],
[45,28,11,1],
[45,28,11,2],
[45,28,11,3],
[45,28,11,4],
[45,28,11,5],
[45,28,11,6],
[45,28,11,8],
[45,28,11,10],
[45,28,12,0],
[45,28,12,1],
[45,28,12,2],
[45,28,12,3],
[45,28,12,4],
[45,28,12,5],
[45,28,12,6],
[45,28,12,8],
[45,28,12,10],
[45,28,13,0],
[45,28,13,1],
[45,28,13,2],
[45,28,13,3],
[45,28,13,4],
[45,28,13,5],
[45,28,13,6],
[45,28,13,8],
[45,28,13,10],
[45,28,13,11],
[45,28,13,12],
[45,28,14,0],
[45,28,14,1],
[45,28,14,2],
[45,28,14,3],
[45,28,14,4],
[45,28,14,5],
[45,28,14,6],
[45,28,14,8],
[45,28,14,10],
[45,28,14,11],
[45,28,14,12],
[45,28,16,0],
[45,28,16,1],
[45,28,16,2],
[45,28,16,3],
[45,28,16,4],
[45,28,16,5],
[45,28,16,6],
[45,28,16,8],
[45,28,16,10],
[45,28,16,11],
[45,28,16,12],
[45,28,16,13],
[45,28,16,14],
[45,28,17,0],
[45,28,17,1],
[45,28,17,2],
[45,28,17,3],
[45,28,17,4],
[45,28,17,5],
[45,28,17,6],
[45,28,17,8],
[45,28,17,10],
[45,28,17,11],
[45,28,17,12],
[45,28,17,13],
[45,28,17,14],
[45,28,17,16],
[45,28,19,0],
[45,28,19,1],
[45,28,19,2],
[45,28,19,3],
[45,28,19,4],
[45,28,19,5],
[45,28,19,6],
[45,28,19,8],
[45,28,19,10],
[45,28,19,11],
[45,28,19,12],
[45,28,19,16],
[45,28,19,17],
[45,28,20,0],
[45,28,20,1],
[45,28,20,2],
[45,28,20,3],
[45,28,20,4],
[45,28,20,5],
[45,28,20,6],
[45,28,20,8],
[45,28,20,11],
[45,28,20,12],
[45,28,20,13],
[45,28,20,14],
[45,28,20,16],
[45,28,20,17],
[45,28,20,19],
[45,28,21,0],
[45,28,21,1],
[45,28,21,2],
[45,28,21,4],
[45,28,21,10],
[45,28,21,11],
[45,28,21,12],
[45,28,21,13],
[45,28,21,14],
[45,28,21,16],
[45,28,21,17],
[45,28,21,19],
[45,28,21,20],
[45,28,22,0],
[45,28,22,1],
[45,28,22,2],
[45,28,22,3],
[45,28,22,4],
[45,28,22,5],
[45,28,22,6],
[45,28,22,8],
[45,28,22,11],
[45,28,22,12],
[45,28,22,13],
[45,28,22,14],
[45,28,22,16],
[45,28,22,17],
[45,28,22,19],
[45,28,22,21],
[45,28,23,0],
[45,28,23,1],
[45,28,23,2],
[45,28,23,3],
[45,28,23,4],
[45,28,23,5],
[45,28,23,6],
[45,28,23,8],
[45,28,23,10],
[45,28,23,11],
[45,28,23,12],
[45,28,23,13],
[45,28,23,14],
[45,28,23,16],
[45,28,23,17],
[45,28,23,19],
[45,28,23,20],
[45,28,23,21],
[45,28,23,22],
[45,28,24,0],
[45,28,24,1],
[45,28,24,2],
[45,28,24,3],
[45,28,24,4],
[45,28,24,5],
[45,28,24,6],
[45,28,24,8],
[45,28,24,10],
[45,28,24,11],
[45,28,24,12],
[45,28,24,13],
[45,28,24,14],
[45,28,24,17],
[45,28,24,19],
[45,28,24,20],
[45,28,24,21],
[45,28,24,22],
[45,28,24,23],
[45,28,27,0],
[45,28,27,1],
[45,28,27,2],
[45,28,27,3],
[45,28,27,4],
[45,28,27,5],
[45,28,27,6],
[45,28,27,8],
[45,28,27,11],
[45,28,27,12],
[45,28,27,13],
[45,28,27,14],
[45,28,27,16],
[45,28,27,17],
[45,28,27,19],
[45,28,27,21],
[45,28,27,23],
[45,28,27,24],
[45,29,3,0],
[45,29,3,1],
[45,29,3,2],
[45,29,4,0],
[45,29,4,1],
[45,29,4,2],
[45,29,4,3],
[45,29,5,0],
[45,29,5,1],
[45,29,5,2],
[45,29,5,4],
[45,29,6,0],
[45,29,6,1],
[45,29,6,2],
[45,29,6,4],
[45,29,7,0],
[45,29,7,1],
[45,29,7,2],
[45,29,7,3],
[45,29,7,4],
[45,29,7,5],
[45,29,7,6],
[45,29,8,0],
[45,29,8,1],
[45,29,8,2],
[45,29,8,4],
[45,29,8,7],
[45,29,10,0],
[45,29,10,1],
[45,29,10,2],
[45,29,10,3],
[45,29,10,4],
[45,29,10,5],
[45,29,10,6],
[45,29,10,7],
[45,29,10,8],
[45,29,13,0],
[45,29,13,1],
[45,29,13,2],
[45,29,13,3],
[45,29,13,4],
[45,29,13,5],
[45,29,13,6],
[45,29,13,7],
[45,29,13,8],
[45,29,13,10],
[45,29,14,0],
[45,29,14,1],
[45,29,14,2],
[45,29,14,3],
[45,29,14,4],
[45,29,14,5],
[45,29,14,6],
[45,29,14,7],
[45,29,14,8],
[45,29,14,10],
[45,29,16,0],
[45,29,16,1],
[45,29,16,2],
[45,29,16,3],
[45,29,16,4],
[45,29,16,5],
[45,29,16,6],
[45,29,16,7],
[45,29,16,8],
[45,29,16,10],
[45,29,16,13],
[45,29,16,14],
[45,29,17,0],
[45,29,17,1],
[45,29,17,2],
[45,29,17,3],
[45,29,17,4],
[45,29,17,5],
[45,29,17,6],
[45,29,17,7],
[45,29,17,8],
[45,29,17,10],
[45,29,17,13],
[45,29,17,14],
[45,29,17,16],
[45,29,19,0],
[45,29,19,1],
[45,29,19,2],
[45,29,19,3],
[45,29,19,4],
[45,29,19,5],
[45,29,19,6],
[45,29,19,7],
[45,29,19,8],
[45,29,19,10],
[45,29,19,16],
[45,29,19,17],
[45,29,20,0],
[45,29,20,1],
[45,29,20,2],
[45,29,20,3],
[45,29,20,4],
[45,29,20,5],
[45,29,20,6],
[45,29,20,7],
[45,29,20,8],
[45,29,20,13],
[45,29,20,14],
[45,29,20,16],
[45,29,20,17],
[45,29,20,19],
[45,29,21,0],
[45,29,21,1],
[45,29,21,2],
[45,29,21,4],
[45,29,21,7],
[45,29,21,10],
[45,29,21,13],
[45,29,21,14],
[45,29,21,16],
[45,29,21,17],
[45,29,21,19],
[45,29,21,20],
[45,29,22,0],
[45,29,22,1],
[45,29,22,2],
[45,29,22,3],
[45,29,22,4],
[45,29,22,5],
[45,29,22,6],
[45,29,22,7],
[45,29,22,8],
[45,29,22,13],
[45,29,22,14],
[45,29,22,16],
[45,29,22,17],
[45,29,22,19],
[45,29,22,21],
[45,29,23,0],
[45,29,23,1],
[45,29,23,2],
[45,29,23,3],
[45,29,23,4],
[45,29,23,5],
[45,29,23,6],
[45,29,23,7],
[45,29,23,8],
[45,29,23,10],
[45,29,23,13],
[45,29,23,14],
[45,29,23,16],
[45,29,23,17],
[45,29,23,19],
[45,29,23,20],
[45,29,23,21],
[45,29,23,22],
[45,29,24,0],
[45,29,24,1],
[45,29,24,2],
[45,29,24,3],
[45,29,24,4],
[45,29,24,5],
[45,29,24,6],
[45,29,24,7],
[45,29,24,8],
[45,29,24,10],
[45,29,24,13],
[45,29,24,14],
[45,29,24,17],
[45,29,24,19],
[45,29,24,20],
[45,29,24,21],
[45,29,24,22],
[45,29,24,23],
[45,29,25,0],
[45,29,25,1],
[45,29,25,2],
[45,29,25,3],
[45,29,25,4],
[45,29,25,5],
[45,29,25,6],
[45,29,25,8],
[45,29,25,10],
[45,29,25,13],
[45,29,25,14],
[45,29,25,16],
[45,29,25,17],
[45,29,25,19],
[45,29,25,20],
[45,29,25,21],
[45,29,25,22],
[45,29,25,23],
[45,29,25,24],
[45,29,27,0],
[45,29,27,1],
[45,29,27,2],
[45,29,27,3],
[45,29,27,4],
[45,29,27,5],
[45,29,27,6],
[45,29,27,7],
[45,29,27,8],
[45,29,27,13],
[45,29,27,14],
[45,29,27,16],
[45,29,27,17],
[45,29,27,19],
[45,29,27,21],
[45,29,27,23],
[45,29,27,24],
[45,29,27,25],
[45,29,28,0],
[45,29,28,1],
[45,29,28,2],
[45,29,28,3],
[45,29,28,4],
[45,29,28,5],
[45,29,28,6],
[45,29,28,8],
[45,29,28,10],
[45,29,28,13],
[45,29,28,14],
[45,29,28,16],
[45,29,28,17],
[45,29,28,19],
[45,29,28,20],
[45,29,28,21],
[45,29,28,22],
[45,29,28,23],
[45,29,28,24],
[45,29,28,27],
[45,30,3,0],
[45,30,3,1],
[45,30,3,2],
[45,30,4,0],
[45,30,4,1],
[45,30,4,2],
[45,30,4,3],
[45,30,5,0],
[45,30,5,1],
[45,30,5,2],
[45,30,5,4],
[45,30,6,0],
[45,30,6,1],
[45,30,6,2],
[45,30,6,4],
[45,30,7,0],
[45,30,7,1],
[45,30,7,2],
[45,30,7,3],
[45,30,7,4],
[45,30,7,5],
[45,30,7,6],
[45,30,8,0],
[45,30,8,1],
[45,30,8,2],
[45,30,8,4],
[45,30,8,7],
[45,30,10,0],
[45,30,10,1],
[45,30,10,2],
[45,30,10,3],
[45,30,10,4],
[45,30,10,5],
[45,30,10,6],
[45,30,10,7],
[45,30,10,8],
[45,30,11,0],
[45,30,11,1],
[45,30,11,2],
[45,30,11,3],
[45,30,11,4],
[45,30,11,5],
[45,30,11,6],
[45,30,11,7],
[45,30,11,8],
[45,30,11,10],
[45,30,12,0],
[45,30,12,1],
[45,30,12,2],
[45,30,12,3],
[45,30,12,4],
[45,30,12,5],
[45,30,12,6],
[45,30,12,7],
[45,30,12,8],
[45,30,12,10],
[45,30,13,0],
[45,30,13,1],
[45,30,13,2],
[45,30,13,3],
[45,30,13,4],
[45,30,13,5],
[45,30,13,6],
[45,30,13,7],
[45,30,13,8],
[45,30,13,10],
[45,30,13,11],
[45,30,13,12],
[45,30,14,0],
[45,30,14,1],
[45,30,14,2],
[45,30,14,3],
[45,30,14,4],
[45,30,14,5],
[45,30,14,6],
[45,30,14,7],
[45,30,14,8],
[45,30,14,10],
[45,30,14,11],
[45,30,14,12],
[45,30,16,0],
[45,30,16,1],
[45,30,16,2],
[45,30,16,3],
[45,30,16,4],
[45,30,16,5],
[45,30,16,6],
[45,30,16,7],
[45,30,16,8],
[45,30,16,10],
[45,30,16,11],
[45,30,16,12],
[45,30,16,13],
[45,30,16,14],
[45,30,17,0],
[45,30,17,1],
[45,30,17,2],
[45,30,17,3],
[45,30,17,4],
[45,30,17,5],
[45,30,17,6],
[45,30,17,7],
[45,30,17,8],
[45,30,17,10],
[45,30,17,11],
[45,30,17,12],
[45,30,17,13],
[45,30,17,14],
[45,30,17,16],
[45,30,19,0],
[45,30,19,1],
[45,30,19,2],
[45,30,19,3],
[45,30,19,4],
[45,30,19,5],
[45,30,19,6],
[45,30,19,7],
[45,30,19,8],
[45,30,19,10],
[45,30,19,11],
[45,30,19,12],
[45,30,19,16],
[45,30,19,17],
[45,30,20,0],
[45,30,20,1],
[45,30,20,2],
[45,30,20,3],
[45,30,20,4],
[45,30,20,5],
[45,30,20,6],
[45,30,20,7],
[45,30,20,8],
[45,30,20,11],
[45,30,20,12],
[45,30,20,13],
[45,30,20,14],
[45,30,20,16],
[45,30,20,17],
[45,30,20,19],
[45,30,21,0],
[45,30,21,1],
[45,30,21,2],
[45,30,21,4],
[45,30,21,7],
[45,30,21,10],
[45,30,21,11],
[45,30,21,12],
[45,30,21,13],
[45,30,21,14],
[45,30,21,16],
[45,30,21,17],
[45,30,21,19],
[45,30,21,20],
[45,30,22,0],
[45,30,22,1],
[45,30,22,2],
[45,30,22,3],
[45,30,22,4],
[45,30,22,5],
[45,30,22,6],
[45,30,22,7],
[45,30,22,8],
[45,30,22,11],
[45,30,22,12],
[45,30,22,13],
[45,30,22,14],
[45,30,22,16],
[45,30,22,17],
[45,30,22,19],
[45,30,22,21],
[45,30,24,0],
[45,30,24,1],
[45,30,24,2],
[45,30,24,3],
[45,30,24,4],
[45,30,24,5],
[45,30,24,6],
[45,30,24,7],
[45,30,24,8],
[45,30,24,10],
[45,30,24,11],
[45,30,24,12],
[45,30,24,13],
[45,30,24,14],
[45,30,24,17],
[45,30,24,19],
[45,30,24,20],
[45,30,24,21],
[45,30,24,22],
[45,30,25,0],
[45,30,25,1],
[45,30,25,2],
[45,30,25,3],
[45,30,25,4],
[45,30,25,5],
[45,30,25,6],
[45,30,25,8],
[45,30,25,10],
[45,30,25,11],
[45,30,25,12],
[45,30,25,13],
[45,30,25,14],
[45,30,25,16],
[45,30,25,17],
[45,30,25,19],
[45,30,25,20],
[45,30,25,21],
[45,30,25,22],
[45,30,25,24],
[45,30,27,0],
[45,30,27,1],
[45,30,27,2],
[45,30,27,3],
[45,30,27,4],
[45,30,27,5],
[45,30,27,6],
[45,30,27,7],
[45,30,27,8],
[45,30,27,11],
[45,30,27,12],
[45,30,27,13],
[45,30,27,14],
[45,30,27,16],
[45,30,27,17],
[45,30,27,19],
[45,30,27,21],
[45,30,27,24],
[45,30,27,25],
[45,30,28,0],
[45,30,28,1],
[45,30,28,2],
[45,30,28,3],
[45,30,28,4],
[45,30,28,5],
[45,30,28,6],
[45,30,28,8],
[45,30,28,10],
[45,30,28,11],
[45,30,28,12],
[45,30,28,13],
[45,30,28,14],
[45,30,28,16],
[45,30,28,17],
[45,30,28,19],
[45,30,28,20],
[45,30,28,21],
[45,30,28,22],
[45,30,28,24],
[45,30,28,27],
[45,30,29,0],
[45,30,29,1],
[45,30,29,2],
[45,30,29,3],
[45,30,29,4],
[45,30,29,5],
[45,30,29,6],
[45,30,29,7],
[45,30,29,8],
[45,30,29,10],
[45,30,29,13],
[45,30,29,14],
[45,30,29,16],
[45,30,29,17],
[45,30,29,19],
[45,30,29,20],
[45,30,29,21],
[45,30,29,22],
[45,30,29,24],
[45,30,29,25],
[45,30,29,27],
[45,30,29,28],
[45,31,3,0],
[45,31,3,1],
[45,31,3,2],
[45,31,4,0],
[45,31,4,1],
[45,31,4,2],
[45,31,4,3],
[45,31,5,0],
[45,31,5,1],
[45,31,5,2],
[45,31,5,4],
[45,31,6,0],
[45,31,6,1],
[45,31,6,2],
[45,31,6,4],
[45,31,7,0],
[45,31,7,1],
[45,31,7,2],
[45,31,7,3],
[45,31,7,4],
[45,31,7,5],
[45,31,7,6],
[45,31,8,0],
[45,31,8,1],
[45,31,8,2],
[45,31,8,4],
[45,31,8,7],
[45,31,10,0],
[45,31,10,1],
[45,31,10,2],
[45,31,10,3],
[45,31,10,4],
[45,31,10,5],
[45,31,10,6],
[45,31,10,7],
[45,31,10,8],
[45,31,11,0],
[45,31,11,1],
[45,31,11,2],
[45,31,11,3],
[45,31,11,4],
[45,31,11,5],
[45,31,11,6],
[45,31,11,7],
[45,31,11,8],
[45,31,11,10],
[45,31,12,0],
[45,31,12,1],
[45,31,12,2],
[45,31,12,3],
[45,31,12,4],
[45,31,12,5],
[45,31,12,6],
[45,31,12,7],
[45,31,12,8],
[45,31,12,10],
[45,31,13,0],
[45,31,13,1],
[45,31,13,2],
[45,31,13,3],
[45,31,13,4],
[45,31,13,5],
[45,31,13,6],
[45,31,13,7],
[45,31,13,8],
[45,31,13,10],
[45,31,13,11],
[45,31,13,12],
[45,31,14,0],
[45,31,14,1],
[45,31,14,2],
[45,31,14,3],
[45,31,14,4],
[45,31,14,5],
[45,31,14,6],
[45,31,14,7],
[45,31,14,8],
[45,31,14,10],
[45,31,14,11],
[45,31,14,12],
[45,31,16,0],
[45,31,16,1],
[45,31,16,2],
[45,31,16,3],
[45,31,16,4],
[45,31,16,5],
[45,31,16,6],
[45,31,16,7],
[45,31,16,8],
[45,31,16,10],
[45,31,16,11],
[45,31,16,12],
[45,31,16,13],
[45,31,16,14],
[45,31,17,0],
[45,31,17,1],
[45,31,17,2],
[45,31,17,3],
[45,31,17,4],
[45,31,17,5],
[45,31,17,6],
[45,31,17,7],
[45,31,17,8],
[45,31,17,10],
[45,31,17,11],
[45,31,17,12],
[45,31,17,13],
[45,31,17,14],
[45,31,17,16],
[45,31,19,0],
[45,31,19,1],
[45,31,19,2],
[45,31,19,3],
[45,31,19,4],
[45,31,19,5],
[45,31,19,6],
[45,31,19,7],
[45,31,19,8],
[45,31,19,10],
[45,31,19,11],
[45,31,19,12],
[45,31,19,16],
[45,31,19,17],
[45,31,20,0],
[45,31,20,1],
[45,31,20,2],
[45,31,20,3],
[45,31,20,4],
[45,31,20,5],
[45,31,20,6],
[45,31,20,7],
[45,31,20,8],
[45,31,20,11],
[45,31,20,12],
[45,31,20,13],
[45,31,20,14],
[45,31,20,16],
[45,31,20,17],
[45,31,20,19],
[45,31,21,0],
[45,31,21,1],
[45,31,21,2],
[45,31,21,4],
[45,31,21,7],
[45,31,21,10],
[45,31,21,11],
[45,31,21,12],
[45,31,21,13],
[45,31,21,14],
[45,31,21,16],
[45,31,21,17],
[45,31,21,19],
[45,31,21,20],
[45,31,22,0],
[45,31,22,1],
[45,31,22,2],
[45,31,22,3],
[45,31,22,4],
[45,31,22,5],
[45,31,22,6],
[45,31,22,7],
[45,31,22,8],
[45,31,22,11],
[45,31,22,12],
[45,31,22,13],
[45,31,22,14],
[45,31,22,16],
[45,31,22,17],
[45,31,22,19],
[45,31,22,21],
[45,31,24,0],
[45,31,24,1],
[45,31,24,2],
[45,31,24,3],
[45,31,24,4],
[45,31,24,5],
[45,31,24,6],
[45,31,24,7],
[45,31,24,8],
[45,31,24,10],
[45,31,24,11],
[45,31,24,12],
[45,31,24,13],
[45,31,24,14],
[45,31,24,17],
[45,31,24,19],
[45,31,24,20],
[45,31,24,21],
[45,31,24,22],
[45,31,25,0],
[45,31,25,1],
[45,31,25,2],
[45,31,25,3],
[45,31,25,4],
[45,31,25,5],
[45,31,25,6],
[45,31,25,8],
[45,31,25,10],
[45,31,25,11],
[45,31,25,12],
[45,31,25,13],
[45,31,25,14],
[45,31,25,16],
[45,31,25,17],
[45,31,25,19],
[45,31,25,20],
[45,31,25,21],
[45,31,25,22],
[45,31,25,24],
[45,31,27,0],
[45,31,27,1],
[45,31,27,2],
[45,31,27,3],
[45,31,27,4],
[45,31,27,5],
[45,31,27,6],
[45,31,27,7],
[45,31,27,8],
[45,31,27,11],
[45,31,27,12],
[45,31,27,13],
[45,31,27,14],
[45,31,27,16],
[45,31,27,17],
[45,31,27,19],
[45,31,27,21],
[45,31,27,24],
[45,31,28,0],
[45,31,28,1],
[45,31,28,2],
[45,31,28,3],
[45,31,28,4],
[45,31,28,5],
[45,31,28,6],
[45,31,28,8],
[45,31,28,10],
[45,31,28,11],
[45,31,28,12],
[45,31,28,13],
[45,31,28,14],
[45,31,28,16],
[45,31,28,17],
[45,31,28,19],
[45,31,28,20],
[45,31,28,21],
[45,31,28,22],
[45,31,28,24],
[45,31,28,27],
[45,31,29,0],
[45,31,29,1],
[45,31,29,2],
[45,31,29,3],
[45,31,29,4],
[45,31,29,5],
[45,31,29,6],
[45,31,29,7],
[45,31,29,8],
[45,31,29,10],
[45,31,29,13],
[45,31,29,14],
[45,31,29,16],
[45,31,29,17],
[45,31,29,19],
[45,31,29,20],
[45,31,29,21],
[45,31,29,22],
[45,31,29,25],
[45,31,29,27],
[45,31,29,28],
[45,32,3,0],
[45,32,3,1],
[45,32,3,2],
[45,32,4,0],
[45,32,4,1],
[45,32,4,2],
[45,32,4,3],
[45,32,5,0],
[45,32,5,1],
[45,32,5,2],
[45,32,5,4],
[45,32,6,0],
[45,32,6,1],
[45,32,6,2],
[45,32,6,4],
[45,32,7,0],
[45,32,7,1],
[45,32,7,2],
[45,32,7,3],
[45,32,7,4],
[45,32,7,5],
[45,32,7,6],
[45,32,8,0],
[45,32,8,1],
[45,32,8,2],
[45,32,8,4],
[45,32,8,7],
[45,32,10,0],
[45,32,10,1],
[45,32,10,2],
[45,32,10,3],
[45,32,10,4],
[45,32,10,5],
[45,32,10,6],
[45,32,10,7],
[45,32,10,8],
[45,32,13,0],
[45,32,13,1],
[45,32,13,2],
[45,32,13,3],
[45,32,13,4],
[45,32,13,5],
[45,32,13,6],
[45,32,13,7],
[45,32,13,8],
[45,32,13,10],
[45,32,14,0],
[45,32,14,1],
[45,32,14,2],
[45,32,14,3],
[45,32,14,4],
[45,32,14,5],
[45,32,14,6],
[45,32,14,7],
[45,32,14,8],
[45,32,14,10],
[45,32,16,0],
[45,32,16,1],
[45,32,16,2],
[45,32,16,3],
[45,32,16,4],
[45,32,16,5],
[45,32,16,6],
[45,32,16,7],
[45,32,16,8],
[45,32,16,10],
[45,32,16,13],
[45,32,16,14],
[45,32,17,0],
[45,32,17,1],
[45,32,17,2],
[45,32,17,3],
[45,32,17,4],
[45,32,17,5],
[45,32,17,6],
[45,32,17,7],
[45,32,17,8],
[45,32,17,10],
[45,32,17,13],
[45,32,17,14],
[45,32,17,16],
[45,32,19,0],
[45,32,19,1],
[45,32,19,2],
[45,32,19,3],
[45,32,19,4],
[45,32,19,5],
[45,32,19,6],
[45,32,19,7],
[45,32,19,8],
[45,32,19,10],
[45,32,19,16],
[45,32,19,17],
[45,32,20,0],
[45,32,20,1],
[45,32,20,2],
[45,32,20,3],
[45,32,20,4],
[45,32,20,5],
[45,32,20,6],
[45,32,20,7],
[45,32,20,8],
[45,32,20,13],
[45,32,20,14],
[45,32,20,16],
[45,32,20,17],
[45,32,20,19],
[45,32,21,0],
[45,32,21,1],
[45,32,21,2],
[45,32,21,4],
[45,32,21,7],
[45,32,21,10],
[45,32,21,13],
[45,32,21,14],
[45,32,21,16],
[45,32,21,17],
[45,32,21,19],
[45,32,21,20],
[45,32,22,0],
[45,32,22,1],
[45,32,22,2],
[45,32,22,3],
[45,32,22,4],
[45,32,22,5],
[45,32,22,6],
[45,32,22,7],
[45,32,22,8],
[45,32,22,13],
[45,32,22,14],
[45,32,22,16],
[45,32,22,17],
[45,32,22,19],
[45,32,22,21],
[45,32,23,0],
[45,32,23,1],
[45,32,23,2],
[45,32,23,3],
[45,32,23,4],
[45,32,23,5],
[45,32,23,6],
[45,32,23,7],
[45,32,23,8],
[45,32,23,10],
[45,32,23,13],
[45,32,23,14],
[45,32,23,16],
[45,32,23,17],
[45,32,23,19],
[45,32,23,20],
[45,32,23,21],
[45,32,23,22],
[45,32,24,0],
[45,32,24,1],
[45,32,24,2],
[45,32,24,3],
[45,32,24,4],
[45,32,24,5],
[45,32,24,6],
[45,32,24,7],
[45,32,24,8],
[45,32,24,10],
[45,32,24,13],
[45,32,24,14],
[45,32,24,17],
[45,32,24,19],
[45,32,24,20],
[45,32,24,21],
[45,32,24,22],
[45,32,24,23],
[45,32,25,0],
[45,32,25,1],
[45,32,25,2],
[45,32,25,3],
[45,32,25,4],
[45,32,25,5],
[45,32,25,6],
[45,32,25,8],
[45,32,25,10],
[45,32,25,13],
[45,32,25,14],
[45,32,25,16],
[45,32,25,17],
[45,32,25,19],
[45,32,25,20],
[45,32,25,21],
[45,32,25,22],
[45,32,25,23],
[45,32,25,24],
[45,32,27,0],
[45,32,27,1],
[45,32,27,2],
[45,32,27,3],
[45,32,27,4],
[45,32,27,5],
[45,32,27,6],
[45,32,27,7],
[45,32,27,8],
[45,32,27,13],
[45,32,27,14],
[45,32,27,16],
[45,32,27,17],
[45,32,27,19],
[45,32,27,21],
[45,32,27,23],
[45,32,27,24],
[45,32,27,25],
[45,32,28,0],
[45,32,28,1],
[45,32,28,2],
[45,32,28,3],
[45,32,28,4],
[45,32,28,5],
[45,32,28,6],
[45,32,28,8],
[45,32,28,10],
[45,32,28,13],
[45,32,28,14],
[45,32,28,16],
[45,32,28,17],
[45,32,28,19],
[45,32,28,20],
[45,32,28,21],
[45,32,28,22],
[45,32,28,27],
[45,32,30,0],
[45,32,30,1],
[45,32,30,2],
[45,32,30,3],
[45,32,30,4],
[45,32,30,5],
[45,32,30,6],
[45,32,30,7],
[45,32,30,8],
[45,32,30,10],
[45,32,30,13],
[45,32,30,14],
[45,32,30,16],
[45,32,30,17],
[45,32,30,19],
[45,32,30,20],
[45,32,30,22],
[45,32,30,24],
[45,32,30,25],
[45,32,30,27],
[45,32,30,28],
[45,32,31,0],
[45,32,31,1],
[45,32,31,2],
[45,32,31,3],
[45,32,31,4],
[45,32,31,5],
[45,32,31,6],
[45,32,31,7],
[45,32,31,8],
[45,32,31,10],
[45,32,31,13],
[45,32,31,14],
[45,32,31,16],
[45,32,31,17],
[45,32,31,19],
[45,32,31,21],
[45,32,31,22],
[45,32,31,24],
[45,32,31,25],
[45,32,31,27],
[45,32,31,28],
[45,33,3,0],
[45,33,3,1],
[45,33,3,2],
[45,33,4,0],
[45,33,4,1],
[45,33,4,2],
[45,33,4,3],
[45,33,5,0],
[45,33,5,1],
[45,33,5,2],
[45,33,5,4],
[45,33,6,0],
[45,33,6,1],
[45,33,6,2],
[45,33,6,4],
[45,33,7,0],
[45,33,7,1],
[45,33,7,2],
[45,33,7,3],
[45,33,7,4],
[45,33,7,5],
[45,33,7,6],
[45,33,8,0],
[45,33,8,1],
[45,33,8,2],
[45,33,8,4],
[45,33,8,7],
[45,33,10,0],
[45,33,10,1],
[45,33,10,2],
[45,33,10,3],
[45,33,10,4],
[45,33,10,5],
[45,33,10,6],
[45,33,10,7],
[45,33,10,8],
[45,33,13,0],
[45,33,13,1],
[45,33,13,2],
[45,33,13,3],
[45,33,13,4],
[45,33,13,5],
[45,33,13,6],
[45,33,13,7],
[45,33,13,8],
[45,33,13,10],
[45,33,14,0],
[45,33,14,1],
[45,33,14,2],
[45,33,14,3],
[45,33,14,4],
[45,33,14,5],
[45,33,14,6],
[45,33,14,7],
[45,33,14,8],
[45,33,14,10],
[45,33,16,0],
[45,33,16,1],
[45,33,16,2],
[45,33,16,3],
[45,33,16,4],
[45,33,16,5],
[45,33,16,6],
[45,33,16,7],
[45,33,16,8],
[45,33,16,10],
[45,33,16,13],
[45,33,16,14],
[45,33,17,0],
[45,33,17,1],
[45,33,17,2],
[45,33,17,3],
[45,33,17,4],
[45,33,17,5],
[45,33,17,6],
[45,33,17,7],
[45,33,17,8],
[45,33,17,10],
[45,33,17,13],
[45,33,17,14],
[45,33,17,16],
[45,33,19,0],
[45,33,19,1],
[45,33,19,2],
[45,33,19,3],
[45,33,19,4],
[45,33,19,5],
[45,33,19,6],
[45,33,19,7],
[45,33,19,8],
[45,33,19,10],
[45,33,19,16],
[45,33,19,17],
[45,33,20,0],
[45,33,20,1],
[45,33,20,2],
[45,33,20,3],
[45,33,20,4],
[45,33,20,5],
[45,33,20,6],
[45,33,20,7],
[45,33,20,8],
[45,33,20,13],
[45,33,20,14],
[45,33,20,16],
[45,33,20,17],
[45,33,20,19],
[45,33,21,0],
[45,33,21,1],
[45,33,21,2],
[45,33,21,4],
[45,33,21,7],
[45,33,21,10],
[45,33,21,13],
[45,33,21,14],
[45,33,21,16],
[45,33,21,17],
[45,33,21,19],
[45,33,21,20],
[45,33,22,0],
[45,33,22,1],
[45,33,22,2],
[45,33,22,3],
[45,33,22,4],
[45,33,22,5],
[45,33,22,6],
[45,33,22,7],
[45,33,22,8],
[45,33,22,13],
[45,33,22,14],
[45,33,22,16],
[45,33,22,17],
[45,33,22,19],
[45,33,22,21],
[45,33,23,0],
[45,33,23,1],
[45,33,23,2],
[45,33,23,3],
[45,33,23,4],
[45,33,23,5],
[45,33,23,6],
[45,33,23,7],
[45,33,23,8],
[45,33,23,10],
[45,33,23,13],
[45,33,23,14],
[45,33,23,16],
[45,33,23,17],
[45,33,23,19],
[45,33,23,20],
[45,33,23,21],
[45,33,23,22],
[45,33,24,0],
[45,33,24,1],
[45,33,24,2],
[45,33,24,3],
[45,33,24,4],
[45,33,24,5],
[45,33,24,6],
[45,33,24,7],
[45,33,24,8],
[45,33,24,10],
[45,33,24,13],
[45,33,24,14],
[45,33,24,17],
[45,33,24,19],
[45,33,24,20],
[45,33,24,21],
[45,33,24,22],
[45,33,24,23],
[45,33,25,0],
[45,33,25,1],
[45,33,25,2],
[45,33,25,3],
[45,33,25,4],
[45,33,25,5],
[45,33,25,6],
[45,33,25,8],
[45,33,25,10],
[45,33,25,13],
[45,33,25,14],
[45,33,25,16],
[45,33,25,17],
[45,33,25,19],
[45,33,25,20],
[45,33,25,21],
[45,33,25,22],
[45,33,25,23],
[45,33,25,24],
[45,33,27,0],
[45,33,27,1],
[45,33,27,2],
[45,33,27,3],
[45,33,27,4],
[45,33,27,5],
[45,33,27,6],
[45,33,27,7],
[45,33,27,8],
[45,33,27,13],
[45,33,27,14],
[45,33,27,16],
[45,33,27,17],
[45,33,27,19],
[45,33,27,21],
[45,33,27,25],
[45,33,28,0],
[45,33,28,1],
[45,33,28,2],
[45,33,28,3],
[45,33,28,4],
[45,33,28,5],
[45,33,28,6],
[45,33,28,8],
[45,33,28,10],
[45,33,28,13],
[45,33,28,14],
[45,33,28,16],
[45,33,28,17],
[45,33,28,19],
[45,33,28,20],
[45,33,28,21],
[45,33,28,23],
[45,33,28,24],
[45,33,28,27],
[45,33,30,0],
[45,33,30,1],
[45,33,30,2],
[45,33,30,3],
[45,33,30,4],
[45,33,30,5],
[45,33,30,6],
[45,33,30,7],
[45,33,30,8],
[45,33,30,10],
[45,33,30,13],
[45,33,30,14],
[45,33,30,16],
[45,33,30,17],
[45,33,30,19],
[45,33,30,21],
[45,33,30,22],
[45,33,30,24],
[45,33,30,25],
[45,33,30,27],
[45,33,30,28],
[45,33,31,0],
[45,33,31,1],
[45,33,31,2],
[45,33,31,3],
[45,33,31,4],
[45,33,31,5],
[45,33,31,6],
[45,33,31,7],
[45,33,31,8],
[45,33,31,10],
[45,33,31,13],
[45,33,31,14],
[45,33,31,16],
[45,33,31,17],
[45,33,31,20],
[45,33,31,21],
[45,33,31,22],
[45,33,31,24],
[45,33,31,25],
[45,33,31,27],
[45,33,31,28],
[45,34,3,0],
[45,34,3,1],
[45,34,3,2],
[45,34,4,0],
[45,34,4,1],
[45,34,4,2],
[45,34,4,3],
[45,34,5,0],
[45,34,5,1],
[45,34,5,2],
[45,34,5,4],
[45,34,6,0],
[45,34,6,1],
[45,34,6,2],
[45,34,6,4],
[45,34,8,0],
[45,34,8,1],
[45,34,8,2],
[45,34,8,4],
[45,34,10,0],
[45,34,10,1],
[45,34,10,2],
[45,34,10,3],
[45,34,10,4],
[45,34,10,5],
[45,34,10,6],
[45,34,10,8],
[45,34,11,0],
[45,34,11,1],
[45,34,11,2],
[45,34,11,3],
[45,34,11,4],
[45,34,11,5],
[45,34,11,6],
[45,34,11,8],
[45,34,11,10],
[45,34,12,0],
[45,34,12,1],
[45,34,12,2],
[45,34,12,3],
[45,34,12,4],
[45,34,12,5],
[45,34,12,6],
[45,34,12,8],
[45,34,12,10],
[45,34,13,0],
[45,34,13,1],
[45,34,13,2],
[45,34,13,3],
[45,34,13,4],
[45,34,13,5],
[45,34,13,6],
[45,34,13,8],
[45,34,13,10],
[45,34,13,11],
[45,34,13,12],
[45,34,14,0],
[45,34,14,1],
[45,34,14,2],
[45,34,14,3],
[45,34,14,4],
[45,34,14,5],
[45,34,14,6],
[45,34,14,8],
[45,34,14,10],
[45,34,14,11],
[45,34,14,12],
[45,34,16,0],
[45,34,16,1],
[45,34,16,2],
[45,34,16,3],
[45,34,16,4],
[45,34,16,5],
[45,34,16,6],
[45,34,16,8],
[45,34,16,10],
[45,34,16,11],
[45,34,16,12],
[45,34,16,13],
[45,34,16,14],
[45,34,17,0],
[45,34,17,1],
[45,34,17,2],
[45,34,17,3],
[45,34,17,4],
[45,34,17,5],
[45,34,17,6],
[45,34,17,8],
[45,34,17,10],
[45,34,17,11],
[45,34,17,12],
[45,34,17,13],
[45,34,17,14],
[45,34,17,16],
[45,34,19,0],
[45,34,19,1],
[45,34,19,2],
[45,34,19,3],
[45,34,19,4],
[45,34,19,5],
[45,34,19,6],
[45,34,19,8],
[45,34,19,10],
[45,34,19,11],
[45,34,19,12],
[45,34,19,16],
[45,34,19,17],
[45,34,20,0],
[45,34,20,1],
[45,34,20,2],
[45,34,20,3],
[45,34,20,4],
[45,34,20,5],
[45,34,20,6],
[45,34,20,8],
[45,34,20,11],
[45,34,20,12],
[45,34,20,13],
[45,34,20,14],
[45,34,20,16],
[45,34,20,17],
[45,34,20,19],
[45,34,21,0],
[45,34,21,1],
[45,34,21,2],
[45,34,21,4],
[45,34,21,10],
[45,34,21,11],
[45,34,21,12],
[45,34,21,13],
[45,34,21,14],
[45,34,21,16],
[45,34,21,17],
[45,34,21,19],
[45,34,21,20],
[45,34,22,0],
[45,34,22,1],
[45,34,22,2],
[45,34,22,3],
[45,34,22,4],
[45,34,22,5],
[45,34,22,6],
[45,34,22,8],
[45,34,22,11],
[45,34,22,12],
[45,34,22,13],
[45,34,22,14],
[45,34,22,16],
[45,34,22,17],
[45,34,22,19],
[45,34,22,21],
[45,34,23,0],
[45,34,23,1],
[45,34,23,2],
[45,34,23,3],
[45,34,23,4],
[45,34,23,5],
[45,34,23,6],
[45,34,23,8],
[45,34,23,10],
[45,34,23,11],
[45,34,23,12],
[45,34,23,13],
[45,34,23,14],
[45,34,23,16],
[45,34,23,17],
[45,34,23,19],
[45,34,23,20],
[45,34,23,21],
[45,34,23,22],
[45,34,24,0],
[45,34,24,1],
[45,34,24,2],
[45,34,24,3],
[45,34,24,4],
[45,34,24,5],
[45,34,24,6],
[45,34,24,8],
[45,34,24,10],
[45,34,24,11],
[45,34,24,12],
[45,34,24,13],
[45,34,24,14],
[45,34,24,17],
[45,34,24,19],
[45,34,24,20],
[45,34,24,21],
[45,34,24,22],
[45,34,24,23],
[45,34,27,0],
[45,34,27,1],
[45,34,27,2],
[45,34,27,3],
[45,34,27,4],
[45,34,27,5],
[45,34,27,6],
[45,34,27,8],
[45,34,27,11],
[45,34,27,12],
[45,34,27,13],
[45,34,27,14],
[45,34,27,16],
[45,34,27,17],
[45,34,27,19],
[45,34,27,21],
[45,34,27,23],
[45,34,27,24],
[45,34,29,0],
[45,34,29,1],
[45,34,29,2],
[45,34,29,3],
[45,34,29,4],
[45,34,29,5],
[45,34,29,6],
[45,34,29,8],
[45,34,29,10],
[45,34,29,13],
[45,34,29,14],
[45,34,29,16],
[45,34,29,17],
[45,34,29,19],
[45,34,29,21],
[45,34,29,22],
[45,34,29,23],
[45,34,29,24],
[45,34,29,27],
[45,34,30,0],
[45,34,30,1],
[45,34,30,2],
[45,34,30,3],
[45,34,30,4],
[45,34,30,5],
[45,34,30,6],
[45,34,30,8],
[45,34,30,10],
[45,34,30,11],
[45,34,30,12],
[45,34,30,13],
[45,34,30,14],
[45,34,30,16],
[45,34,30,17],
[45,34,30,20],
[45,34,30,21],
[45,34,30,22],
[45,34,30,24],
[45,34,30,27],
[45,34,30,29],
[45,34,31,0],
[45,34,31,1],
[45,34,31,2],
[45,34,31,3],
[45,34,31,4],
[45,34,31,5],
[45,34,31,6],
[45,34,31,8],
[45,34,31,10],
[45,34,31,11],
[45,34,31,12],
[45,34,31,13],
[45,34,31,14],
[45,34,31,16],
[45,34,31,17],
[45,34,31,19],
[45,34,31,20],
[45,34,31,21],
[45,34,31,22],
[45,34,31,24],
[45,34,31,27],
[45,34,31,29],
[45,34,32,0],
[45,34,32,1],
[45,34,32,2],
[45,34,32,3],
[45,34,32,4],
[45,34,32,5],
[45,34,32,6],
[45,34,32,8],
[45,34,32,10],
[45,34,32,13],
[45,34,32,14],
[45,34,32,19],
[45,34,32,20],
[45,34,32,21],
[45,34,32,22],
[45,34,32,23],
[45,34,32,24],
[45,34,32,27],
[45,34,33,0],
[45,34,33,1],
[45,34,33,2],
[45,34,33,3],
[45,34,33,4],
[45,34,33,5],
[45,34,33,6],
[45,34,33,8],
[45,34,33,10],
[45,34,33,13],
[45,34,33,14],
[45,34,33,16],
[45,34,33,17],
[45,34,33,19],
[45,34,33,20],
[45,34,33,21],
[45,34,33,22],
[45,34,33,23],
[45,34,33,24],
[45,34,33,27],
[45,36,3,0],
[45,36,3,1],
[45,36,3,2],
[45,36,4,0],
[45,36,4,1],
[45,36,4,2],
[45,36,4,3],
[45,36,5,0],
[45,36,5,1],
[45,36,5,2],
[45,36,5,4],
[45,36,6,0],
[45,36,6,1],
[45,36,6,2],
[45,36,6,4],
[45,36,7,0],
[45,36,7,1],
[45,36,7,2],
[45,36,7,3],
[45,36,7,4],
[45,36,7,5],
[45,36,7,6],
[45,36,8,0],
[45,36,8,1],
[45,36,8,2],
[45,36,8,4],
[45,36,8,7],
[45,36,10,0],
[45,36,10,1],
[45,36,10,2],
[45,36,10,3],
[45,36,10,4],
[45,36,10,5],
[45,36,10,6],
[45,36,10,7],
[45,36,10,8],
[45,36,11,0],
[45,36,11,1],
[45,36,11,2],
[45,36,11,3],
[45,36,11,4],
[45,36,11,5],
[45,36,11,6],
[45,36,11,7],
[45,36,11,8],
[45,36,11,10],
[45,36,12,0],
[45,36,12,1],
[45,36,12,2],
[45,36,12,3],
[45,36,12,4],
[45,36,12,5],
[45,36,12,6],
[45,36,12,7],
[45,36,12,8],
[45,36,12,10],
[45,36,16,0],
[45,36,16,1],
[45,36,16,2],
[45,36,16,3],
[45,36,16,4],
[45,36,16,5],
[45,36,16,6],
[45,36,16,7],
[45,36,16,8],
[45,36,16,10],
[45,36,16,11],
[45,36,16,12],
[45,36,17,0],
[45,36,17,1],
[45,36,17,2],
[45,36,17,3],
[45,36,17,4],
[45,36,17,5],
[45,36,17,6],
[45,36,17,7],
[45,36,17,8],
[45,36,17,10],
[45,36,17,11],
[45,36,17,12],
[45,36,17,16],
[45,36,20,0],
[45,36,20,1],
[45,36,20,2],
[45,36,20,3],
[45,36,20,4],
[45,36,20,5],
[45,36,20,6],
[45,36,20,7],
[45,36,20,8],
[45,36,20,11],
[45,36,20,12],
[45,36,20,16],
[45,36,20,17],
[45,36,21,0],
[45,36,21,1],
[45,36,21,2],
[45,36,21,4],
[45,36,21,7],
[45,36,21,10],
[45,36,21,11],
[45,36,21,12],
[45,36,21,16],
[45,36,21,17],
[45,36,21,20],
[45,36,22,0],
[45,36,22,1],
[45,36,22,2],
[45,36,22,3],
[45,36,22,4],
[45,36,22,5],
[45,36,22,6],
[45,36,22,7],
[45,36,22,8],
[45,36,22,11],
[45,36,22,12],
[45,36,22,16],
[45,36,22,17],
[45,36,22,21],
[45,36,23,0],
[45,36,23,1],
[45,36,23,2],
[45,36,23,3],
[45,36,23,4],
[45,36,23,5],
[45,36,23,6],
[45,36,23,7],
[45,36,23,8],
[45,36,23,10],
[45,36,23,11],
[45,36,23,12],
[45,36,23,16],
[45,36,23,17],
[45,36,23,20],
[45,36,23,21],
[45,36,23,22],
[45,36,24,0],
[45,36,24,1],
[45,36,24,2],
[45,36,24,3],
[45,36,24,4],
[45,36,24,5],
[45,36,24,6],
[45,36,24,7],
[45,36,24,8],
[45,36,24,10],
[45,36,24,11],
[45,36,24,12],
[45,36,24,17],
[45,36,24,20],
[45,36,24,21],
[45,36,24,22],
[45,36,24,23],
[45,36,25,0],
[45,36,25,1],
[45,36,25,2],
[45,36,25,3],
[45,36,25,4],
[45,36,25,5],
[45,36,25,6],
[45,36,25,8],
[45,36,25,10],
[45,36,25,11],
[45,36,25,12],
[45,36,25,16],
[45,36,25,17],
[45,36,25,20],
[45,36,25,21],
[45,36,25,23],
[45,36,25,24],
[45,36,27,0],
[45,36,27,1],
[45,36,27,2],
[45,36,27,3],
[45,36,27,4],
[45,36,27,5],
[45,36,27,6],
[45,36,27,7],
[45,36,27,8],
[45,36,27,11],
[45,36,27,12],
[45,36,27,16],
[45,36,27,17],
[45,36,27,21],
[45,36,27,23],
[45,36,27,24],
[45,36,27,25],
[45,36,28,0],
[45,36,28,1],
[45,36,28,2],
[45,36,28,3],
[45,36,28,4],
[45,36,28,5],
[45,36,28,6],
[45,36,28,8],
[45,36,28,10],
[45,36,28,11],
[45,36,28,12],
[45,36,28,16],
[45,36,28,17],
[45,36,28,20],
[45,36,28,21],
[45,36,28,22],
[45,36,28,23],
[45,36,28,24],
[45,36,28,27],
[45,36,29,0],
[45,36,29,1],
[45,36,29,2],
[45,36,29,3],
[45,36,29,4],
[45,36,29,5],
[45,36,29,6],
[45,36,29,7],
[45,36,29,8],
[45,36,29,10],
[45,36,29,16],
[45,36,29,17],
[45,36,29,20],
[45,36,29,21],
[45,36,29,22],
[45,36,29,23],
[45,36,29,24],
[45,36,29,25],
[45,36,29,27],
[45,36,29,28],
[45,36,30,0],
[45,36,30,1],
[45,36,30,2],
[45,36,30,3],
[45,36,30,4],
[45,36,30,5],
[45,36,30,6],
[45,36,30,7],
[45,36,30,8],
[45,36,30,10],
[45,36,30,11],
[45,36,30,12],
[45,36,30,20],
[45,36,30,21],
[45,36,30,22],
[45,36,30,24],
[45,36,30,25],
[45,36,30,27],
[45,36,30,28],
[45,36,30,29],
[45,36,31,0],
[45,36,31,1],
[45,36,31,2],
[45,36,31,3],
[45,36,31,4],
[45,36,31,5],
[45,36,31,6],
[45,36,31,7],
[45,36,31,8],
[45,36,31,10],
[45,36,31,11],
[45,36,31,12],
[45,36,31,16],
[45,36,31,17],
[45,36,31,20],
[45,36,31,21],
[45,36,31,22],
[45,36,31,24],
[45,36,31,25],
[45,36,31,27],
[45,36,31,28],
[45,36,32,0],
[45,36,32,1],
[45,36,32,2],
[45,36,32,3],
[45,36,32,4],
[45,36,32,5],
[45,36,32,6],
[45,36,32,7],
[45,36,32,8],
[45,36,32,10],
[45,36,32,16],
[45,36,32,17],
[45,36,32,20],
[45,36,32,21],
[45,36,32,22],
[45,36,32,23],
[45,36,32,24],
[45,36,32,25],
[45,36,32,27],
[45,36,33,0],
[45,36,33,1],
[45,36,33,2],
[45,36,33,3],
[45,36,33,4],
[45,36,33,5],
[45,36,33,6],
[45,36,33,7],
[45,36,33,8],
[45,36,33,10],
[45,36,33,16],
[45,36,33,17],
[45,36,33,20],
[45,36,33,21],
[45,36,33,22],
[45,36,33,23],
[45,36,33,24],
[45,36,33,25],
[45,36,34,0],
[45,36,34,1],
[45,36,34,2],
[45,36,34,3],
[45,36,34,4],
[45,36,34,5],
[45,36,34,6],
[45,36,34,8],
[45,36,34,12],
[45,36,34,16],
[45,36,34,17],
[45,36,34,20],
[45,36,34,21],
[45,36,34,22],
[45,36,34,23],
[45,36,34,24],
[45,37,3,0],
[45,37,3,1],
[45,37,3,2],
[45,37,5,0],
[45,37,5,1],
[45,37,5,2],
[45,37,6,0],
[45,37,6,1],
[45,37,6,2],
[45,37,7,0],
[45,37,7,1],
[45,37,7,2],
[45,37,7,3],
[45,37,7,5],
[45,37,7,6],
[45,37,8,0],
[45,37,8,1],
[45,37,8,2],
[45,37,8,7],
[45,37,10,0],
[45,37,10,1],
[45,37,10,2],
[45,37,10,3],
[45,37,10,5],
[45,37,10,6],
[45,37,10,7],
[45,37,10,8],
[45,37,11,0],
[45,37,11,1],
[45,37,11,2],
[45,37,11,3],
[45,37,11,5],
[45,37,11,6],
[45,37,11,7],
[45,37,11,8],
[45,37,11,10],
[45,37,12,0],
[45,37,12,1],
[45,37,12,2],
[45,37,12,3],
[45,37,12,5],
[45,37,12,6],
[45,37,12,7],
[45,37,12,8],
[45,37,12,10],
[45,37,13,0],
[45,37,13,1],
[45,37,13,2],
[45,37,13,3],
[45,37,13,5],
[45,37,13,6],
[45,37,13,7],
[45,37,13,8],
[45,37,13,10],
[45,37,13,11],
[45,37,13,12],
[45,37,14,0],
[45,37,14,1],
[45,37,14,2],
[45,37,14,3],
[45,37,14,5],
[45,37,14,6],
[45,37,14,7],
[45,37,14,8],
[45,37,14,10],
[45,37,14,11],
[45,37,14,12],
[45,37,16,0],
[45,37,16,1],
[45,37,16,2],
[45,37,16,3],
[45,37,16,5],
[45,37,16,6],
[45,37,16,7],
[45,37,16,8],
[45,37,16,10],
[45,37,16,11],
[45,37,16,12],
[45,37,16,13],
[45,37,16,14],
[45,37,17,0],
[45,37,17,1],
[45,37,17,2],
[45,37,17,3],
[45,37,17,5],
[45,37,17,6],
[45,37,17,7],
[45,37,17,8],
[45,37,17,10],
[45,37,17,11],
[45,37,17,12],
[45,37,17,13],
[45,37,17,14],
[45,37,17,16],
[45,37,19,0],
[45,37,19,1],
[45,37,19,2],
[45,37,19,3],
[45,37,19,5],
[45,37,19,6],
[45,37,19,7],
[45,37,19,8],
[45,37,19,10],
[45,37,19,11],
[45,37,19,12],
[45,37,19,16],
[45,37,19,17],
[45,37,20,0],
[45,37,20,1],
[45,37,20,2],
[45,37,20,3],
[45,37,20,5],
[45,37,20,6],
[45,37,20,7],
[45,37,20,8],
[45,37,20,11],
[45,37,20,12],
[45,37,20,13],
[45,37,20,14],
[45,37,20,16],
[45,37,20,17],
[45,37,20,19],
[45,37,21,0],
[45,37,21,1],
[45,37,21,2],
[45,37,21,7],
[45,37,21,10],
[45,37,21,11],
[45,37,21,12],
[45,37,21,13],
[45,37,21,14],
[45,37,21,16],
[45,37,21,17],
[45,37,21,19],
[45,37,21,20],
[45,37,22,0],
[45,37,22,1],
[45,37,22,2],
[45,37,22,3],
[45,37,22,5],
[45,37,22,6],
[45,37,22,7],
[45,37,22,8],
[45,37,22,11],
[45,37,22,12],
[45,37,22,13],
[45,37,22,14],
[45,37,22,16],
[45,37,22,17],
[45,37,22,19],
[45,37,22,21],
[45,37,23,0],
[45,37,23,1],
[45,37,23,2],
[45,37,23,3],
[45,37,23,5],
[45,37,23,6],
[45,37,23,7],
[45,37,23,8],
[45,37,23,10],
[45,37,23,11],
[45,37,23,12],
[45,37,23,13],
[45,37,23,14],
[45,37,23,16],
[45,37,23,17],
[45,37,23,19],
[45,37,23,20],
[45,37,23,21],
[45,37,23,22],
[45,37,24,0],
[45,37,24,1],
[45,37,24,2],
[45,37,24,3],
[45,37,24,5],
[45,37,24,6],
[45,37,24,7],
[45,37,24,8],
[45,37,24,10],
[45,37,24,11],
[45,37,24,12],
[45,37,24,13],
[45,37,24,14],
[45,37,24,17],
[45,37,24,19],
[45,37,24,20],
[45,37,24,21],
[45,37,24,22],
[45,37,25,0],
[45,37,25,1],
[45,37,25,2],
[45,37,25,3],
[45,37,25,5],
[45,37,25,6],
[45,37,25,8],
[45,37,25,10],
[45,37,25,11],
[45,37,25,12],
[45,37,25,13],
[45,37,25,14],
[45,37,25,16],
[45,37,25,17],
[45,37,25,19],
[45,37,25,20],
[45,37,25,22],
[45,37,25,23],
[45,37,25,24],
[45,37,27,0],
[45,37,27,1],
[45,37,27,2],
[45,37,27,3],
[45,37,27,5],
[45,37,27,6],
[45,37,27,7],
[45,37,27,8],
[45,37,27,11],
[45,37,27,12],
[45,37,27,13],
[45,37,27,14],
[45,37,27,16],
[45,37,27,17],
[45,37,27,21],
[45,37,27,23],
[45,37,27,24],
[45,37,27,25],
[45,37,28,0],
[45,37,28,1],
[45,37,28,2],
[45,37,28,3],
[45,37,28,5],
[45,37,28,6],
[45,37,28,8],
[45,37,28,10],
[45,37,28,11],
[45,37,28,12],
[45,37,28,13],
[45,37,28,14],
[45,37,28,16],
[45,37,28,17],
[45,37,28,19],
[45,37,28,20],
[45,37,28,21],
[45,37,28,22],
[45,37,28,23],
[45,37,28,24],
[45,37,28,27],
[45,37,29,0],
[45,37,29,1],
[45,37,29,2],
[45,37,29,3],
[45,37,29,5],
[45,37,29,6],
[45,37,29,7],
[45,37,29,8],
[45,37,29,10],
[45,37,29,13],
[45,37,29,14],
[45,37,29,19],
[45,37,29,20],
[45,37,29,21],
[45,37,29,22],
[45,37,29,23],
[45,37,29,24],
[45,37,29,25],
[45,37,29,27],
[45,37,29,28],
[45,37,30,0],
[45,37,30,1],
[45,37,30,2],
[45,37,30,3],
[45,37,30,5],
[45,37,30,6],
[45,37,30,7],
[45,37,30,8],
[45,37,30,10],
[45,37,30,11],
[45,37,30,12],
[45,37,30,13],
[45,37,30,14],
[45,37,30,16],
[45,37,30,17],
[45,37,30,19],
[45,37,30,20],
[45,37,30,21],
[45,37,30,22],
[45,37,30,24],
[45,37,30,25],
[45,37,30,27],
[45,37,30,28],
[45,37,31,0],
[45,37,31,1],
[45,37,31,2],
[45,37,31,3],
[45,37,31,5],
[45,37,31,6],
[45,37,31,7],
[45,37,31,8],
[45,37,31,10],
[45,37,31,11],
[45,37,31,12],
[45,37,31,13],
[45,37,31,16],
[45,37,31,17],
[45,37,31,19],
[45,37,31,20],
[45,37,31,21],
[45,37,31,22],
[45,37,31,24],
[45,37,31,25],
[45,37,31,27],
[45,37,32,0],
[45,37,32,1],
[45,37,32,2],
[45,37,32,3],
[45,37,32,5],
[45,37,32,6],
[45,37,32,7],
[45,37,32,8],
[45,37,32,10],
[45,37,32,14],
[45,37,32,16],
[45,37,32,17],
[45,37,32,19],
[45,37,32,20],
[45,37,32,21],
[45,37,32,22],
[45,37,32,23],
[45,37,32,24],
[45,37,32,25],
[45,37,33,0],
[45,37,33,1],
[45,37,33,2],
[45,37,33,3],
[45,37,33,5],
[45,37,33,6],
[45,37,33,7],
[45,37,33,8],
[45,37,33,13],
[45,37,33,14],
[45,37,33,16],
[45,37,33,17],
[45,37,33,19],
[45,37,33,20],
[45,37,33,21],
[45,37,33,22],
[45,37,33,23],
[45,37,33,24],
[45,37,33,25],
[45,37,34,0],
[45,37,34,1],
[45,37,34,2],
[45,37,34,3],
[45,37,34,5],
[45,37,34,6],
[45,37,34,10],
[45,37,34,11],
[45,37,34,12],
[45,37,34,13],
[45,37,34,14],
[45,37,34,16],
[45,37,34,17],
[45,37,34,19],
[45,37,34,20],
[45,37,34,21],
[45,37,34,22],
[45,37,34,23],
[45,37,34,24],
[45,37,36,0],
[45,37,36,1],
[45,37,36,2],
[45,37,36,3],
[45,37,36,6],
[45,37,36,7],
[45,37,36,8],
[45,37,36,10],
[45,37,36,11],
[45,37,36,12],
[45,37,36,16],
[45,37,36,17],
[45,37,36,20],
[45,37,36,21],
[45,37,36,22],
[45,38,3,0],
[45,38,3,1],
[45,38,3,2],
[45,38,4,0],
[45,38,4,1],
[45,38,4,2],
[45,38,4,3],
[45,38,5,0],
[45,38,5,1],
[45,38,5,2],
[45,38,5,4],
[45,38,6,0],
[45,38,6,1],
[45,38,6,2],
[45,38,6,4],
[45,38,8,0],
[45,38,8,1],
[45,38,8,2],
[45,38,8,4],
[45,38,10,0],
[45,38,10,1],
[45,38,10,2],
[45,38,10,3],
[45,38,10,4],
[45,38,10,5],
[45,38,10,6],
[45,38,10,8],
[45,38,11,0],
[45,38,11,1],
[45,38,11,2],
[45,38,11,3],
[45,38,11,4],
[45,38,11,5],
[45,38,11,6],
[45,38,11,8],
[45,38,11,10],
[45,38,12,0],
[45,38,12,1],
[45,38,12,2],
[45,38,12,3],
[45,38,12,4],
[45,38,12,5],
[45,38,12,6],
[45,38,12,8],
[45,38,12,10],
[45,38,13,0],
[45,38,13,1],
[45,38,13,2],
[45,38,13,3],
[45,38,13,4],
[45,38,13,5],
[45,38,13,6],
[45,38,13,8],
[45,38,13,10],
[45,38,13,11],
[45,38,13,12],
[45,38,14,0],
[45,38,14,1],
[45,38,14,2],
[45,38,14,3],
[45,38,14,4],
[45,38,14,5],
[45,38,14,6],
[45,38,14,8],
[45,38,14,10],
[45,38,14,11],
[45,38,14,12],
[45,38,16,0],
[45,38,16,1],
[45,38,16,2],
[45,38,16,3],
[45,38,16,4],
[45,38,16,5],
[45,38,16,6],
[45,38,16,8],
[45,38,16,10],
[45,38,16,11],
[45,38,16,12],
[45,38,16,13],
[45,38,16,14],
[45,38,17,0],
[45,38,17,1],
[45,38,17,2],
[45,38,17,3],
[45,38,17,4],
[45,38,17,5],
[45,38,17,6],
[45,38,17,8],
[45,38,17,10],
[45,38,17,11],
[45,38,17,12],
[45,38,17,13],
[45,38,17,14],
[45,38,17,16],
[45,38,19,0],
[45,38,19,1],
[45,38,19,2],
[45,38,19,3],
[45,38,19,4],
[45,38,19,5],
[45,38,19,6],
[45,38,19,8],
[45,38,19,10],
[45,38,19,11],
[45,38,19,12],
[45,38,19,16],
[45,38,19,17],
[45,38,20,0],
[45,38,20,1],
[45,38,20,2],
[45,38,20,3],
[45,38,20,4],
[45,38,20,5],
[45,38,20,6],
[45,38,20,8],
[45,38,20,11],
[45,38,20,12],
[45,38,20,13],
[45,38,20,14],
[45,38,20,16],
[45,38,20,17],
[45,38,20,19],
[45,38,21,0],
[45,38,21,1],
[45,38,21,2],
[45,38,21,4],
[45,38,21,10],
[45,38,21,11],
[45,38,21,12],
[45,38,21,13],
[45,38,21,14],
[45,38,21,16],
[45,38,21,17],
[45,38,21,19],
[45,38,21,20],
[45,38,22,0],
[45,38,22,1],
[45,38,22,2],
[45,38,22,3],
[45,38,22,4],
[45,38,22,5],
[45,38,22,6],
[45,38,22,8],
[45,38,22,11],
[45,38,22,12],
[45,38,22,13],
[45,38,22,14],
[45,38,22,16],
[45,38,22,17],
[45,38,22,19],
[45,38,22,21],
[45,38,23,0],
[45,38,23,1],
[45,38,23,2],
[45,38,23,3],
[45,38,23,4],
[45,38,23,5],
[45,38,23,6],
[45,38,23,8],
[45,38,23,10],
[45,38,23,11],
[45,38,23,12],
[45,38,23,13],
[45,38,23,14],
[45,38,23,16],
[45,38,23,17],
[45,38,23,19],
[45,38,23,20],
[45,38,23,21],
[45,38,24,0],
[45,38,24,1],
[45,38,24,2],
[45,38,24,3],
[45,38,24,4],
[45,38,24,5],
[45,38,24,6],
[45,38,24,8],
[45,38,24,10],
[45,38,24,11],
[45,38,24,12],
[45,38,24,13],
[45,38,24,14],
[45,38,24,17],
[45,38,24,19],
[45,38,24,20],
[45,38,24,21],
[45,38,24,23],
[45,38,27,0],
[45,38,27,1],
[45,38,27,2],
[45,38,27,3],
[45,38,27,4],
[45,38,27,5],
[45,38,27,6],
[45,38,27,8],
[45,38,27,11],
[45,38,27,12],
[45,38,27,13],
[45,38,27,14],
[45,38,27,16],
[45,38,27,17],
[45,38,27,19],
[45,38,27,21],
[45,38,27,23],
[45,38,27,24],
[45,38,29,0],
[45,38,29,1],
[45,38,29,2],
[45,38,29,3],
[45,38,29,4],
[45,38,29,5],
[45,38,29,6],
[45,38,29,8],
[45,38,29,10],
[45,38,29,13],
[45,38,29,14],
[45,38,29,16],
[45,38,29,17],
[45,38,29,19],
[45,38,29,20],
[45,38,29,21],
[45,38,29,22],
[45,38,29,23],
[45,38,29,24],
[45,38,29,27],
[45,38,30,0],
[45,38,30,1],
[45,38,30,2],
[45,38,30,3],
[45,38,30,4],
[45,38,30,5],
[45,38,30,6],
[45,38,30,8],
[45,38,30,10],
[45,38,30,11],
[45,38,30,12],
[45,38,30,13],
[45,38,30,16],
[45,38,30,17],
[45,38,30,19],
[45,38,30,20],
[45,38,30,21],
[45,38,30,22],
[45,38,30,24],
[45,38,30,27],
[45,38,31,0],
[45,38,31,1],
[45,38,31,2],
[45,38,31,3],
[45,38,31,4],
[45,38,31,5],
[45,38,31,6],
[45,38,31,8],
[45,38,31,10],
[45,38,31,11],
[45,38,31,14],
[45,38,31,16],
[45,38,31,17],
[45,38,31,19],
[45,38,31,20],
[45,38,31,21],
[45,38,31,22],
[45,38,31,24],
[45,38,32,0],
[45,38,32,1],
[45,38,32,2],
[45,38,32,3],
[45,38,32,4],
[45,38,32,5],
[45,38,32,6],
[45,38,32,8],
[45,38,32,13],
[45,38,32,14],
[45,38,32,16],
[45,38,32,17],
[45,38,32,19],
[45,38,32,20],
[45,38,32,21],
[45,38,32,22],
[45,38,32,23],
[45,38,32,24],
[45,38,33,0],
[45,38,33,1],
[45,38,33,2],
[45,38,33,3],
[45,38,33,4],
[45,38,33,5],
[45,38,33,6],
[45,38,33,10],
[45,38,33,13],
[45,38,33,14],
[45,38,33,16],
[45,38,33,17],
[45,38,33,19],
[45,38,33,20],
[45,38,33,21],
[45,38,33,22],
[45,38,33,23],
[45,38,33,24],
[45,38,36,0],
[45,38,36,1],
[45,38,36,2],
[45,38,36,5],
[45,38,36,6],
[45,38,36,8],
[45,38,36,10],
[45,38,36,11],
[45,38,36,12],
[45,38,36,16],
[45,38,36,17],
[45,38,36,20],
[45,38,36,21],
[45,38,37,3],
[45,38,37,5],
[45,38,37,6],
[45,38,37,8],
[45,38,37,10],
[45,38,37,11],
[45,38,37,12],
[45,38,37,13],
[45,38,37,14],
[45,38,37,16],
[45,38,37,17],
[45,38,37,19],
[45,38,37,20],
[45,39,4,0],
[45,39,4,1],
[45,39,4,2],
[45,39,7,0],
[45,39,7,1],
[45,39,7,2],
[45,39,7,4],
[45,39,10,0],
[45,39,10,1],
[45,39,10,2],
[45,39,10,4],
[45,39,10,7],
[45,39,11,0],
[45,39,11,1],
[45,39,11,2],
[45,39,11,4],
[45,39,11,7],
[45,39,11,10],
[45,39,12,0],
[45,39,12,1],
[45,39,12,2],
[45,39,12,4],
[45,39,12,7],
[45,39,12,10],
[45,39,13,0],
[45,39,13,1],
[45,39,13,2],
[45,39,13,4],
[45,39,13,7],
[45,39,13,10],
[45,39,13,11],
[45,39,13,12],
[45,39,14,0],
[45,39,14,1],
[45,39,14,2],
[45,39,14,4],
[45,39,14,7],
[45,39,14,10],
[45,39,14,11],
[45,39,14,12],
[45,39,16,0],
[45,39,16,1],
[45,39,16,2],
[45,39,16,4],
[45,39,16,7],
[45,39,16,10],
[45,39,16,11],
[45,39,16,12],
[45,39,16,13],
[45,39,16,14],
[45,39,17,0],
[45,39,17,1],
[45,39,17,2],
[45,39,17,4],
[45,39,17,7],
[45,39,17,10],
[45,39,17,11],
[45,39,17,12],
[45,39,17,13],
[45,39,17,14],
[45,39,17,16],
[45,39,19,0],
[45,39,19,1],
[45,39,19,2],
[45,39,19,4],
[45,39,19,7],
[45,39,19,10],
[45,39,19,11],
[45,39,19,12],
[45,39,19,16],
[45,39,19,17],
[45,39,20,0],
[45,39,20,1],
[45,39,20,2],
[45,39,20,4],
[45,39,20,7],
[45,39,20,11],
[45,39,20,12],
[45,39,20,13],
[45,39,20,14],
[45,39,20,16],
[45,39,20,17],
[45,39,20,19],
[45,39,22,0],
[45,39,22,1],
[45,39,22,2],
[45,39,22,4],
[45,39,22,7],
[45,39,22,11],
[45,39,22,12],
[45,39,22,13],
[45,39,22,14],
[45,39,22,16],
[45,39,22,17],
[45,39,22,19],
[45,39,23,0],
[45,39,23,1],
[45,39,23,2],
[45,39,23,4],
[45,39,23,7],
[45,39,23,10],
[45,39,23,11],
[45,39,23,12],
[45,39,23,13],
[45,39,23,14],
[45,39,23,16],
[45,39,23,17],
[45,39,23,19],
[45,39,23,20],
[45,39,23,22],
[45,39,24,0],
[45,39,24,1],
[45,39,24,2],
[45,39,24,4],
[45,39,24,7],
[45,39,24,10],
[45,39,24,11],
[45,39,24,12],
[45,39,24,13],
[45,39,24,14],
[45,39,24,17],
[45,39,24,19],
[45,39,24,20],
[45,39,24,22],
[45,39,24,23],
[45,39,25,0],
[45,39,25,1],
[45,39,25,2],
[45,39,25,4],
[45,39,25,10],
[45,39,25,11],
[45,39,25,12],
[45,39,25,13],
[45,39,25,14],
[45,39,25,16],
[45,39,25,17],
[45,39,25,20],
[45,39,25,22],
[45,39,25,23],
[45,39,25,24],
[45,39,27,0],
[45,39,27,1],
[45,39,27,2],
[45,39,27,4],
[45,39,27,7],
[45,39,27,11],
[45,39,27,12],
[45,39,27,13],
[45,39,27,14],
[45,39,27,19],
[45,39,27,23],
[45,39,27,24],
[45,39,27,25],
[45,39,28,0],
[45,39,28,1],
[45,39,28,2],
[45,39,28,4],
[45,39,28,10],
[45,39,28,11],
[45,39,28,12],
[45,39,28,13],
[45,39,28,14],
[45,39,28,16],
[45,39,28,17],
[45,39,28,19],
[45,39,28,20],
[45,39,28,22],
[45,39,28,23],
[45,39,28,24],
[45,39,28,27],
[45,39,29,0],
[45,39,29,1],
[45,39,29,2],
[45,39,29,4],
[45,39,29,7],
[45,39,29,10],
[45,39,29,13],
[45,39,29,16],
[45,39,29,17],
[45,39,29,19],
[45,39,29,20],
[45,39,29,22],
[45,39,29,23],
[45,39,29,24],
[45,39,29,25],
[45,39,29,27],
[45,39,30,0],
[45,39,30,1],
[45,39,30,2],
[45,39,30,4],
[45,39,30,7],
[45,39,30,10],
[45,39,30,11],
[45,39,30,14],
[45,39,30,16],
[45,39,30,17],
[45,39,30,19],
[45,39,30,20],
[45,39,30,22],
[45,39,30,24],
[45,39,30,25],
[45,39,31,0],
[45,39,31,1],
[45,39,31,2],
[45,39,31,4],
[45,39,31,7],
[45,39,31,12],
[45,39,31,13],
[45,39,31,14],
[45,39,31,16],
[45,39,31,17],
[45,39,31,19],
[45,39,31,20],
[45,39,31,22],
[45,39,31,24],
[45,39,31,25],
[45,39,32,0],
[45,39,32,1],
[45,39,32,2],
[45,39,32,4],
[45,39,32,7],
[45,39,32,10],
[45,39,32,13],
[45,39,32,14],
[45,39,32,16],
[45,39,32,17],
[45,39,32,19],
[45,39,32,20],
[45,39,32,22],
[45,39,32,23],
[45,39,32,24],
[45,39,33,0],
[45,39,33,1],
[45,39,33,2],
[45,39,33,4],
[45,39,33,10],
[45,39,33,13],
[45,39,33,14],
[45,39,33,16],
[45,39,33,17],
[45,39,33,19],
[45,39,33,20],
[45,39,33,22],
[45,39,33,23],
[45,39,33,24],
[45,39,34,0],
[45,39,34,1],
[45,39,34,2],
[45,39,34,4],
[45,39,34,10],
[45,39,34,11],
[45,39,34,12],
[45,39,34,13],
[45,39,34,14],
[45,39,34,16],
[45,39,34,17],
[45,39,34,19],
[45,39,34,20],
[45,39,34,22],
[45,39,36,4],
[45,39,36,7],
[45,39,36,10],
[45,39,36,11],
[45,39,36,12],
[45,39,36,16],
[45,39,36,17],
[45,39,36,20],
[45,39,37,0],
[45,39,37,1],
[45,39,37,2],
[45,39,37,7],
[45,39,37,10],
[45,39,37,11],
[45,39,37,12],
[45,39,37,13],
[45,39,37,14],
[45,39,37,16],
[45,39,37,17],
[45,39,37,19],
[45,39,38,0],
[45,39,38,1],
[45,39,38,2],
[45,39,38,4],
[45,39,38,10],
[45,39,38,11],
[45,39,38,12],
[45,39,38,13],
[45,39,38,14],
[45,39,38,16],
[45,39,38,17],
[45,42,3,0],
[45,42,3,1],
[45,42,3,2],
[45,42,4,0],
[45,42,4,1],
[45,42,4,2],
[45,42,4,3],
[45,42,5,0],
[45,42,5,1],
[45,42,5,2],
[45,42,5,4],
[45,42,6,0],
[45,42,6,1],
[45,42,6,2],
[45,42,6,4],
[45,42,7,0],
[45,42,7,1],
[45,42,7,2],
[45,42,7,3],
[45,42,7,4],
[45,42,7,5],
[45,42,7,6],
[45,42,8,0],
[45,42,8,1],
[45,42,8,2],
[45,42,8,4],
[45,42,8,7],
[45,42,11,0],
[45,42,11,1],
[45,42,11,2],
[45,42,11,3],
[45,42,11,4],
[45,42,11,5],
[45,42,11,6],
[45,42,11,7],
[45,42,11,8],
[45,42,12,0],
[45,42,12,1],
[45,42,12,2],
[45,42,12,3],
[45,42,12,4],
[45,42,12,5],
[45,42,12,6],
[45,42,12,7],
[45,42,12,8],
[45,42,13,0],
[45,42,13,1],
[45,42,13,2],
[45,42,13,3],
[45,42,13,4],
[45,42,13,5],
[45,42,13,6],
[45,42,13,7],
[45,42,13,8],
[45,42,13,11],
[45,42,13,12],
[45,42,14,0],
[45,42,14,1],
[45,42,14,2],
[45,42,14,3],
[45,42,14,4],
[45,42,14,5],
[45,42,14,6],
[45,42,14,7],
[45,42,14,8],
[45,42,14,11],
[45,42,14,12],
[45,42,16,0],
[45,42,16,1],
[45,42,16,2],
[45,42,16,3],
[45,42,16,4],
[45,42,16,5],
[45,42,16,6],
[45,42,16,7],
[45,42,16,8],
[45,42,16,11],
[45,42,16,12],
[45,42,16,13],
[45,42,16,14],
[45,42,17,0],
[45,42,17,1],
[45,42,17,2],
[45,42,17,3],
[45,42,17,4],
[45,42,17,5],
[45,42,17,6],
[45,42,17,7],
[45,42,17,8],
[45,42,17,11],
[45,42,17,12],
[45,42,17,13],
[45,42,17,14],
[45,42,17,16],
[45,42,19,0],
[45,42,19,1],
[45,42,19,2],
[45,42,19,3],
[45,42,19,4],
[45,42,19,5],
[45,42,19,6],
[45,42,19,7],
[45,42,19,8],
[45,42,19,11],
[45,42,19,12],
[45,42,19,16],
[45,42,19,17],
[45,42,21,0],
[45,42,21,1],
[45,42,21,2],
[45,42,21,4],
[45,42,21,7],
[45,42,21,11],
[45,42,21,12],
[45,42,21,13],
[45,42,21,14],
[45,42,21,16],
[45,42,21,17],
[45,42,21,19],
[45,42,23,0],
[45,42,23,1],
[45,42,23,2],
[45,42,23,3],
[45,42,23,4],
[45,42,23,5],
[45,42,23,6],
[45,42,23,7],
[45,42,23,8],
[45,42,23,11],
[45,42,23,12],
[45,42,23,13],
[45,42,23,14],
[45,42,23,16],
[45,42,23,17],
[45,42,23,21],
[45,42,24,0],
[45,42,24,1],
[45,42,24,2],
[45,42,24,3],
[45,42,24,4],
[45,42,24,5],
[45,42,24,6],
[45,42,24,7],
[45,42,24,8],
[45,42,24,11],
[45,42,24,12],
[45,42,24,13],
[45,42,24,14],
[45,42,24,17],
[45,42,24,21],
[45,42,24,23],
[45,42,25,0],
[45,42,25,1],
[45,42,25,2],
[45,42,25,3],
[45,42,25,4],
[45,42,25,5],
[45,42,25,6],
[45,42,25,8],
[45,42,25,11],
[45,42,25,12],
[45,42,25,13],
[45,42,25,14],
[45,42,25,19],
[45,42,25,21],
[45,42,25,23],
[45,42,25,24],
[45,42,28,0],
[45,42,28,1],
[45,42,28,2],
[45,42,28,3],
[45,42,28,4],
[45,42,28,5],
[45,42,28,6],
[45,42,28,8],
[45,42,28,11],
[45,42,28,14],
[45,42,28,16],
[45,42,28,17],
[45,42,28,19],
[45,42,28,21],
[45,42,28,23],
[45,42,28,24],
[45,42,29,0],
[45,42,29,1],
[45,42,29,2],
[45,42,29,3],
[45,42,29,4],
[45,42,29,5],
[45,42,29,6],
[45,42,29,7],
[45,42,29,8],
[45,42,29,13],
[45,42,29,14],
[45,42,29,16],
[45,42,29,17],
[45,42,29,19],
[45,42,29,21],
[45,42,29,23],
[45,42,29,24],
[45,42,29,25],
[45,42,30,0],
[45,42,30,1],
[45,42,30,2],
[45,42,30,3],
[45,42,30,4],
[45,42,30,5],
[45,42,30,6],
[45,42,30,7],
[45,42,30,11],
[45,42,30,12],
[45,42,30,13],
[45,42,30,14],
[45,42,30,16],
[45,42,30,17],
[45,42,30,19],
[45,42,30,21],
[45,42,30,24],
[45,42,31,0],
[45,42,31,1],
[45,42,31,2],
[45,42,31,3],
[45,42,31,4],
[45,42,31,5],
[45,42,31,8],
[45,42,31,11],
[45,42,31,12],
[45,42,31,13],
[45,42,31,14],
[45,42,31,16],
[45,42,31,17],
[45,42,31,19],
[45,42,31,21],
[45,42,31,24],
[45,42,32,0],
[45,42,32,1],
[45,42,32,2],
[45,42,32,3],
[45,42,32,4],
[45,42,32,6],
[45,42,32,7],
[45,42,32,8],
[45,42,32,13],
[45,42,32,14],
[45,42,32,16],
[45,42,32,17],
[45,42,32,19],
[45,42,32,21],
[45,42,33,0],
[45,42,33,1],
[45,42,33,2],
[45,42,33,5],
[45,42,33,6],
[45,42,33,7],
[45,42,33,8],
[45,42,33,13],
[45,42,33,14],
[45,42,33,16],
[45,42,33,17],
[45,42,33,19],
[45,42,33,21],
[45,42,34,3],
[45,42,34,4],
[45,42,34,5],
[45,42,34,6],
[45,42,34,8],
[45,42,34,11],
[45,42,34,12],
[45,42,34,13],
[45,42,34,14],
[45,42,34,16],
[45,42,34,17],
[45,42,34,19],
[45,42,36,0],
[45,42,36,1],
[45,42,36,2],
[45,42,36,3],
[45,42,36,4],
[45,42,36,5],
[45,42,36,6],
[45,42,36,7],
[45,42,36,8],
[45,42,36,11],
[45,42,36,12],
[45,42,36,16],
[45,42,36,17],
[45,42,37,0],
[45,42,37,1],
[45,42,37,2],
[45,42,37,3],
[45,42,37,5],
[45,42,37,6],
[45,42,37,7],
[45,42,37,8],
[45,42,37,11],
[45,42,37,12],
[45,42,37,13],
[45,42,37,14],
[45,42,37,16],
[45,42,37,17],
[45,42,38,0],
[45,42,38,1],
[45,42,38,2],
[45,42,38,3],
[45,42,38,4],
[45,42,38,5],
[45,42,38,6],
[45,42,38,8],
[45,42,38,11],
[45,42,38,12],
[45,42,38,13],
[45,42,38,14],
[45,42,39,0],
[45,42,39,1],
[45,42,39,2],
[45,42,39,4],
[45,42,39,7],
[45,42,39,11],
[45,42,39,12],
[45,42,39,13],
[45,42,39,14],
[45,43,3,0],
[45,43,3,1],
[45,43,3,2],
[45,43,4,0],
[45,43,4,1],
[45,43,4,2],
[45,43,4,3],
[45,43,5,0],
[45,43,5,1],
[45,43,5,2],
[45,43,5,4],
[45,43,6,0],
[45,43,6,1],
[45,43,6,2],
[45,43,6,4],
[45,43,8,0],
[45,43,8,1],
[45,43,8,2],
[45,43,8,4],
[45,43,10,0],
[45,43,10,1],
[45,43,10,2],
[45,43,10,3],
[45,43,10,4],
[45,43,10,5],
[45,43,10,6],
[45,43,10,8],
[45,43,11,0],
[45,43,11,1],
[45,43,11,2],
[45,43,11,3],
[45,43,11,4],
[45,43,11,5],
[45,43,11,6],
[45,43,11,8],
[45,43,11,10],
[45,43,12,0],
[45,43,12,1],
[45,43,12,2],
[45,43,12,3],
[45,43,12,4],
[45,43,12,5],
[45,43,12,6],
[45,43,12,8],
[45,43,12,10],
[45,43,13,0],
[45,43,13,1],
[45,43,13,2],
[45,43,13,3],
[45,43,13,4],
[45,43,13,5],
[45,43,13,6],
[45,43,13,8],
[45,43,13,10],
[45,43,13,11],
[45,43,13,12],
[45,43,14,0],
[45,43,14,1],
[45,43,14,2],
[45,43,14,3],
[45,43,14,4],
[45,43,14,5],
[45,43,14,6],
[45,43,14,8],
[45,43,14,10],
[45,43,14,11],
[45,43,14,12],
[45,43,16,0],
[45,43,16,1],
[45,43,16,2],
[45,43,16,3],
[45,43,16,4],
[45,43,16,5],
[45,43,16,6],
[45,43,16,8],
[45,43,16,10],
[45,43,16,11],
[45,43,16,12],
[45,43,16,13],
[45,43,16,14],
[45,43,17,0],
[45,43,17,1],
[45,43,17,2],
[45,43,17,3],
[45,43,17,4],
[45,43,17,5],
[45,43,17,6],
[45,43,17,8],
[45,43,17,10],
[45,43,17,11],
[45,43,17,12],
[45,43,17,13],
[45,43,17,14],
[45,43,17,16],
[45,43,19,0],
[45,43,19,1],
[45,43,19,2],
[45,43,19,3],
[45,43,19,4],
[45,43,19,5],
[45,43,19,6],
[45,43,19,8],
[45,43,19,10],
[45,43,19,11],
[45,43,19,12],
[45,43,19,16],
[45,43,19,17],
[45,43,20,0],
[45,43,20,1],
[45,43,20,2],
[45,43,20,3],
[45,43,20,4],
[45,43,20,5],
[45,43,20,6],
[45,43,20,8],
[45,43,20,11],
[45,43,20,12],
[45,43,20,13],
[45,43,20,14],
[45,43,20,16],
[45,43,20,17],
[45,43,20,19],
[45,43,21,0],
[45,43,21,1],
[45,43,21,2],
[45,43,21,4],
[45,43,21,10],
[45,43,21,11],
[45,43,21,12],
[45,43,21,13],
[45,43,21,14],
[45,43,21,16],
[45,43,21,17],
[45,43,21,19],
[45,43,22,0],
[45,43,22,1],
[45,43,22,2],
[45,43,22,3],
[45,43,22,4],
[45,43,22,5],
[45,43,22,6],
[45,43,22,8],
[45,43,22,11],
[45,43,22,12],
[45,43,22,13],
[45,43,22,14],
[45,43,22,16],
[45,43,22,17],
[45,43,22,21],
[45,43,23,0],
[45,43,23,1],
[45,43,23,2],
[45,43,23,3],
[45,43,23,4],
[45,43,23,5],
[45,43,23,6],
[45,43,23,8],
[45,43,23,10],
[45,43,23,11],
[45,43,23,12],
[45,43,23,13],
[45,43,23,14],
[45,43,23,16],
[45,43,23,17],
[45,43,23,19],
[45,43,23,20],
[45,43,23,21],
[45,43,23,22],
[45,43,24,0],
[45,43,24,1],
[45,43,24,2],
[45,43,24,3],
[45,43,24,4],
[45,43,24,5],
[45,43,24,6],
[45,43,24,8],
[45,43,24,10],
[45,43,24,11],
[45,43,24,12],
[45,43,24,13],
[45,43,24,14],
[45,43,24,17],
[45,43,24,19],
[45,43,24,20],
[45,43,24,21],
[45,43,24,22],
[45,43,24,23],
[45,43,27,0],
[45,43,27,1],
[45,43,27,2],
[45,43,27,3],
[45,43,27,4],
[45,43,27,5],
[45,43,27,6],
[45,43,27,8],
[45,43,27,11],
[45,43,27,14],
[45,43,27,16],
[45,43,27,17],
[45,43,27,19],
[45,43,27,21],
[45,43,27,23],
[45,43,27,24],
[45,43,29,0],
[45,43,29,1],
[45,43,29,2],
[45,43,29,3],
[45,43,29,4],
[45,43,29,5],
[45,43,29,6],
[45,43,29,10],
[45,43,29,13],
[45,43,29,14],
[45,43,29,16],
[45,43,29,17],
[45,43,29,19],
[45,43,29,20],
[45,43,29,21],
[45,43,29,22],
[45,43,29,23],
[45,43,29,24],
[45,43,30,0],
[45,43,30,1],
[45,43,30,2],
[45,43,30,3],
[45,43,30,4],
[45,43,30,5],
[45,43,30,8],
[45,43,30,10],
[45,43,30,11],
[45,43,30,12],
[45,43,30,13],
[45,43,30,14],
[45,43,30,16],
[45,43,30,17],
[45,43,30,19],
[45,43,30,20],
[45,43,30,21],
[45,43,30,22],
[45,43,30,24],
[45,43,31,0],
[45,43,31,1],
[45,43,31,2],
[45,43,31,3],
[45,43,31,4],
[45,43,31,6],
[45,43,31,8],
[45,43,31,10],
[45,43,31,11],
[45,43,31,12],
[45,43,31,13],
[45,43,31,14],
[45,43,31,16],
[45,43,31,17],
[45,43,31,19],
[45,43,31,20],
[45,43,31,21],
[45,43,31,22],
[45,43,32,0],
[45,43,32,1],
[45,43,32,2],
[45,43,32,5],
[45,43,32,6],
[45,43,32,8],
[45,43,32,10],
[45,43,32,13],
[45,43,32,14],
[45,43,32,16],
[45,43,32,17],
[45,43,32,19],
[45,43,32,20],
[45,43,32,21],
[45,43,33,3],
[45,43,33,4],
[45,43,33,5],
[45,43,33,6],
[45,43,33,8],
[45,43,33,10],
[45,43,33,13],
[45,43,33,14],
[45,43,33,16],
[45,43,33,17],
[45,43,33,19],
[45,43,33,20],
[45,43,36,0],
[45,43,36,1],
[45,43,36,2],
[45,43,36,3],
[45,43,36,4],
[45,43,36,5],
[45,43,36,6],
[45,43,36,8],
[45,43,36,10],
[45,43,36,11],
[45,43,36,12],
[45,43,36,16],
[45,43,36,17],
[45,43,37,0],
[45,43,37,1],
[45,43,37,2],
[45,43,37,3],
[45,43,37,5],
[45,43,37,6],
[45,43,37,8],
[45,43,37,10],
[45,43,37,11],
[45,43,37,12],
[45,43,37,13],
[45,43,37,14],
[45,43,39,0],
[45,43,39,1],
[45,43,39,2],
[45,43,39,4],
[45,43,39,10],
[45,43,39,11],
[45,43,39,12],
[45,43,39,13],
[45,43,42,0],
[45,43,42,1],
[45,43,42,2],
[45,43,42,3],
[45,43,42,4],
[45,43,42,5],
[45,43,42,6],
[45,43,42,8],
[45,44,3,0],
[45,44,3,1],
[45,44,3,2],
[45,44,4,0],
[45,44,4,1],
[45,44,4,2],
[45,44,4,3],
[45,44,5,0],
[45,44,5,1],
[45,44,5,2],
[45,44,5,4],
[45,44,6,0],
[45,44,6,1],
[45,44,6,2],
[45,44,6,4],
[45,44,7,0],
[45,44,7,1],
[45,44,7,2],
[45,44,7,3],
[45,44,7,4],
[45,44,7,5],
[45,44,7,6],
[45,44,8,0],
[45,44,8,1],
[45,44,8,2],
[45,44,8,4],
[45,44,8,7],
[45,44,10,0],
[45,44,10,1],
[45,44,10,2],
[45,44,10,3],
[45,44,10,4],
[45,44,10,5],
[45,44,10,6],
[45,44,10,7],
[45,44,10,8],
[45,44,11,0],
[45,44,11,1],
[45,44,11,2],
[45,44,11,3],
[45,44,11,4],
[45,44,11,5],
[45,44,11,6],
[45,44,11,7],
[45,44,11,8],
[45,44,11,10],
[45,44,12,0],
[45,44,12,1],
[45,44,12,2],
[45,44,12,3],
[45,44,12,4],
[45,44,12,5],
[45,44,12,6],
[45,44,12,7],
[45,44,12,8],
[45,44,12,10],
[45,44,13,0],
[45,44,13,1],
[45,44,13,2],
[45,44,13,3],
[45,44,13,4],
[45,44,13,5],
[45,44,13,6],
[45,44,13,7],
[45,44,13,8],
[45,44,13,10],
[45,44,13,11],
[45,44,13,12],
[45,44,14,0],
[45,44,14,1],
[45,44,14,2],
[45,44,14,3],
[45,44,14,4],
[45,44,14,5],
[45,44,14,6],
[45,44,14,7],
[45,44,14,8],
[45,44,14,10],
[45,44,14,11],
[45,44,14,12],
[45,44,16,0],
[45,44,16,1],
[45,44,16,2],
[45,44,16,3],
[45,44,16,4],
[45,44,16,5],
[45,44,16,6],
[45,44,16,7],
[45,44,16,8],
[45,44,16,10],
[45,44,16,11],
[45,44,16,12],
[45,44,16,13],
[45,44,16,14],
[45,44,19,0],
[45,44,19,1],
[45,44,19,2],
[45,44,19,3],
[45,44,19,4],
[45,44,19,5],
[45,44,19,6],
[45,44,19,7],
[45,44,19,8],
[45,44,19,10],
[45,44,19,11],
[45,44,19,12],
[45,44,19,16],
[45,44,20,0],
[45,44,20,1],
[45,44,20,2],
[45,44,20,3],
[45,44,20,4],
[45,44,20,5],
[45,44,20,6],
[45,44,20,7],
[45,44,20,8],
[45,44,20,11],
[45,44,20,12],
[45,44,20,13],
[45,44,20,14],
[45,44,20,16],
[45,44,20,19],
[45,44,21,0],
[45,44,21,1],
[45,44,21,2],
[45,44,21,4],
[45,44,21,7],
[45,44,21,10],
[45,44,21,11],
[45,44,21,12],
[45,44,21,13],
[45,44,21,14],
[45,44,21,16],
[45,44,21,20],
[45,44,22,0],
[45,44,22,1],
[45,44,22,2],
[45,44,22,3],
[45,44,22,4],
[45,44,22,5],
[45,44,22,6],
[45,44,22,7],
[45,44,22,8],
[45,44,22,11],
[45,44,22,12],
[45,44,22,13],
[45,44,22,14],
[45,44,22,16],
[45,44,22,19],
[45,44,22,21],
[45,44,23,0],
[45,44,23,1],
[45,44,23,2],
[45,44,23,3],
[45,44,23,4],
[45,44,23,5],
[45,44,23,6],
[45,44,23,7],
[45,44,23,8],
[45,44,23,10],
[45,44,23,11],
[45,44,23,12],
[45,44,23,13],
[45,44,23,14],
[45,44,23,19],
[45,44,23,20],
[45,44,23,21],
[45,44,23,22],
[45,44,24,0],
[45,44,24,1],
[45,44,24,2],
[45,44,24,3],
[45,44,24,4],
[45,44,24,5],
[45,44,24,6],
[45,44,24,7],
[45,44,24,8],
[45,44,24,10],
[45,44,24,11],
[45,44,24,12],
[45,44,24,13],
[45,44,24,14],
[45,44,24,19],
[45,44,24,20],
[45,44,24,21],
[45,44,24,22],
[45,44,24,23],
[45,44,25,0],
[45,44,25,1],
[45,44,25,2],
[45,44,25,3],
[45,44,25,4],
[45,44,25,5],
[45,44,25,6],
[45,44,25,8],
[45,44,25,10],
[45,44,25,11],
[45,44,25,12],
[45,44,25,13],
[45,44,25,16],
[45,44,25,19],
[45,44,25,20],
[45,44,25,21],
[45,44,25,22],
[45,44,25,23],
[45,44,25,24],
[45,44,27,0],
[45,44,27,1],
[45,44,27,2],
[45,44,27,3],
[45,44,27,4],
[45,44,27,5],
[45,44,27,6],
[45,44,27,7],
[45,44,27,8],
[45,44,27,12],
[45,44,27,13],
[45,44,27,14],
[45,44,27,16],
[45,44,27,19],
[45,44,27,21],
[45,44,27,23],
[45,44,27,24],
[45,44,27,25],
[45,44,28,0],
[45,44,28,1],
[45,44,28,2],
[45,44,28,3],
[45,44,28,4],
[45,44,28,5],
[45,44,28,6],
[45,44,28,10],
[45,44,28,11],
[45,44,28,12],
[45,44,28,13],
[45,44,28,14],
[45,44,28,16],
[45,44,28,19],
[45,44,28,20],
[45,44,28,21],
[45,44,28,22],
[45,44,28,23],
[45,44,28,24],
[45,44,29,0],
[45,44,29,1],
[45,44,29,2],
[45,44,29,3],
[45,44,29,4],
[45,44,29,5],
[45,44,29,8],
[45,44,29,10],
[45,44,29,13],
[45,44,29,14],
[45,44,29,16],
[45,44,29,19],
[45,44,29,20],
[45,44,29,21],
[45,44,29,22],
[45,44,29,23],
[45,44,29,24],
[45,44,30,0],
[45,44,30,1],
[45,44,30,2],
[45,44,30,3],
[45,44,30,4],
[45,44,30,6],
[45,44,30,7],
[45,44,30,8],
[45,44,30,10],
[45,44,30,11],
[45,44,30,12],
[45,44,30,13],
[45,44,30,14],
[45,44,30,16],
[45,44,30,19],
[45,44,30,20],
[45,44,30,21],
[45,44,30,22],
[45,44,31,0],
[45,44,31,1],
[45,44,31,2],
[45,44,31,5],
[45,44,31,6],
[45,44,31,7],
[45,44,31,8],
[45,44,31,10],
[45,44,31,11],
[45,44,31,12],
[45,44,31,13],
[45,44,31,14],
[45,44,31,16],
[45,44,31,19],
[45,44,31,20],
[45,44,31,21],
[45,44,32,3],
[45,44,32,4],
[45,44,32,5],
[45,44,32,6],
[45,44,32,7],
[45,44,32,8],
[45,44,32,10],
[45,44,32,13],
[45,44,32,14],
[45,44,32,16],
[45,44,32,19],
[45,44,32,20],
[45,44,33,0],
[45,44,33,1],
[45,44,33,2],
[45,44,33,3],
[45,44,33,4],
[45,44,33,5],
[45,44,33,6],
[45,44,33,7],
[45,44,33,8],
[45,44,33,10],
[45,44,33,13],
[45,44,33,14],
[45,44,33,16],
[45,44,33,19],
[45,44,34,0],
[45,44,34,1],
[45,44,34,2],
[45,44,34,3],
[45,44,34,4],
[45,44,34,5],
[45,44,34,6],
[45,44,34,8],
[45,44,34,10],
[45,44,34,11],
[45,44,34,12],
[45,44,34,13],
[45,44,34,14],
[45,44,34,16],
[45,44,36,0],
[45,44,36,1],
[45,44,36,2],
[45,44,36,3],
[45,44,36,4],
[45,44,36,5],
[45,44,36,6],
[45,44,36,7],
[45,44,36,8],
[45,44,36,10],
[45,44,36,11],
[45,44,36,12],
[45,44,37,0],
[45,44,37,1],
[45,44,37,2],
[45,44,37,3],
[45,44,37,5],
[45,44,37,6],
[45,44,37,7],
[45,44,37,8],
[45,44,37,10],
[45,44,37,11],
[45,44,37,12],
[45,44,37,13],
[45,44,37,14],
[45,44,38,0],
[45,44,38,1],
[45,44,38,2],
[45,44,38,3],
[45,44,38,4],
[45,44,38,5],
[45,44,38,6],
[45,44,38,8],
[45,44,38,10],
[45,44,38,11],
[45,44,38,12],
[45,44,38,13],
[45,44,39,0],
[45,44,39,1],
[45,44,39,2],
[45,44,39,4],
[45,44,39,7],
[45,44,39,10],
[45,44,39,11],
[45,44,42,0],
[45,44,42,1],
[45,44,42,2],
[45,44,42,3],
[45,44,42,4],
[45,44,42,5],
[45,44,42,6],
[45,44,42,7],
[45,44,43,0],
[45,44,43,1],
[45,44,43,2],
[45,44,43,3],
[45,44,43,4],
[45,44,43,5],
[46,4,3,0],
[46,4,3,1],
[46,4,3,2],
[46,5,4,0],
[46,5,4,1],
[46,5,4,2],
[46,6,4,0],
[46,6,4,1],
[46,6,4,2],
[46,7,3,0],
[46,7,3,1],
[46,7,3,2],
[46,7,4,0],
[46,7,4,1],
[46,7,4,2],
[46,7,4,3],
[46,7,5,0],
[46,7,5,1],
[46,7,5,2],
[46,7,5,4],
[46,7,6,0],
[46,7,6,1],
[46,7,6,2],
[46,7,6,4],
[46,8,4,0],
[46,8,4,1],
[46,8,4,2],
[46,8,7,0],
[46,8,7,1],
[46,8,7,2],
[46,8,7,4],
[46,10,3,0],
[46,10,3,1],
[46,10,3,2],
[46,10,4,0],
[46,10,4,1],
[46,10,4,2],
[46,10,4,3],
[46,10,5,0],
[46,10,5,1],
[46,10,5,2],
[46,10,5,4],
[46,10,6,0],
[46,10,6,1],
[46,10,6,2],
[46,10,6,4],
[46,10,7,0],
[46,10,7,1],
[46,10,7,2],
[46,10,7,3],
[46,10,7,4],
[46,10,7,5],
[46,10,7,6],
[46,10,8,0],
[46,10,8,1],
[46,10,8,2],
[46,10,8,4],
[46,10,8,7],
[46,11,3,0],
[46,11,3,1],
[46,11,3,2],
[46,11,4,0],
[46,11,4,1],
[46,11,4,2],
[46,11,4,3],
[46,11,5,0],
[46,11,5,1],
[46,11,5,2],
[46,11,5,4],
[46,11,6,0],
[46,11,6,1],
[46,11,6,2],
[46,11,6,4],
[46,11,7,0],
[46,11,7,1],
[46,11,7,2],
[46,11,7,3],
[46,11,7,4],
[46,11,7,5],
[46,11,7,6],
[46,11,8,0],
[46,11,8,1],
[46,11,8,2],
[46,11,8,4],
[46,11,8,7],
[46,11,10,0],
[46,11,10,1],
[46,11,10,2],
[46,11,10,3],
[46,11,10,4],
[46,11,10,5],
[46,11,10,6],
[46,11,10,7],
[46,11,10,8],
[46,12,3,0],
[46,12,3,1],
[46,12,3,2],
[46,12,4,0],
[46,12,4,1],
[46,12,4,2],
[46,12,4,3],
[46,12,5,0],
[46,12,5,1],
[46,12,5,2],
[46,12,5,4],
[46,12,6,0],
[46,12,6,1],
[46,12,6,2],
[46,12,6,4],
[46,12,7,0],
[46,12,7,1],
[46,12,7,2],
[46,12,7,3],
[46,12,7,4],
[46,12,7,5],
[46,12,7,6],
[46,12,8,0],
[46,12,8,1],
[46,12,8,2],
[46,12,8,4],
[46,12,8,7],
[46,12,10,0],
[46,12,10,1],
[46,12,10,2],
[46,12,10,3],
[46,12,10,4],
[46,12,10,5],
[46,12,10,6],
[46,12,10,7],
[46,12,10,8],
[46,13,3,0],
[46,13,3,1],
[46,13,3,2],
[46,13,4,0],
[46,13,4,1],
[46,13,4,2],
[46,13,4,3],
[46,13,5,0],
[46,13,5,1],
[46,13,5,2],
[46,13,5,4],
[46,13,6,0],
[46,13,6,1],
[46,13,6,2],
[46,13,6,4],
[46,13,7,0],
[46,13,7,1],
[46,13,7,2],
[46,13,7,3],
[46,13,7,4],
[46,13,7,5],
[46,13,7,6],
[46,13,8,0],
[46,13,8,1],
[46,13,8,2],
[46,13,8,4],
[46,13,8,7],
[46,13,10,0],
[46,13,10,1],
[46,13,10,2],
[46,13,10,3],
[46,13,10,4],
[46,13,10,5],
[46,13,10,6],
[46,13,10,7],
[46,13,10,8],
[46,13,11,0],
[46,13,11,1],
[46,13,11,2],
[46,13,11,3],
[46,13,11,4],
[46,13,11,5],
[46,13,11,6],
[46,13,11,7],
[46,13,11,8],
[46,13,11,10],
[46,13,12,0],
[46,13,12,1],
[46,13,12,2],
[46,13,12,3],
[46,13,12,4],
[46,13,12,5],
[46,13,12,6],
[46,13,12,7],
[46,13,12,8],
[46,13,12,10],
[46,14,3,0],
[46,14,3,1],
[46,14,3,2],
[46,14,4,0],
[46,14,4,1],
[46,14,4,2],
[46,14,4,3],
[46,14,5,0],
[46,14,5,1],
[46,14,5,2],
[46,14,5,4],
[46,14,6,0],
[46,14,6,1],
[46,14,6,2],
[46,14,6,4],
[46,14,7,0],
[46,14,7,1],
[46,14,7,2],
[46,14,7,3],
[46,14,7,4],
[46,14,7,5],
[46,14,7,6],
[46,14,8,0],
[46,14,8,1],
[46,14,8,2],
[46,14,8,4],
[46,14,8,7],
[46,14,10,0],
[46,14,10,1],
[46,14,10,2],
[46,14,10,3],
[46,14,10,4],
[46,14,10,5],
[46,14,10,6],
[46,14,10,7],
[46,14,10,8],
[46,14,11,0],
[46,14,11,1],
[46,14,11,2],
[46,14,11,3],
[46,14,11,4],
[46,14,11,5],
[46,14,11,6],
[46,14,11,7],
[46,14,11,8],
[46,14,11,10],
[46,14,12,0],
[46,14,12,1],
[46,14,12,2],
[46,14,12,3],
[46,14,12,4],
[46,14,12,5],
[46,14,12,6],
[46,14,12,7],
[46,14,12,8],
[46,14,12,10],
[46,16,3,0],
[46,16,3,1],
[46,16,3,2],
[46,16,4,0],
[46,16,4,1],
[46,16,4,2],
[46,16,4,3],
[46,16,5,0],
[46,16,5,1],
[46,16,5,2],
[46,16,5,4],
[46,16,6,0],
[46,16,6,1],
[46,16,6,2],
[46,16,6,4],
[46,16,7,0],
[46,16,7,1],
[46,16,7,2],
[46,16,7,3],
[46,16,7,4],
[46,16,7,5],
[46,16,7,6],
[46,16,8,0],
[46,16,8,1],
[46,16,8,2],
[46,16,8,4],
[46,16,8,7],
[46,16,10,0],
[46,16,10,1],
[46,16,10,2],
[46,16,10,3],
[46,16,10,4],
[46,16,10,5],
[46,16,10,6],
[46,16,10,7],
[46,16,10,8],
[46,16,11,0],
[46,16,11,1],
[46,16,11,2],
[46,16,11,3],
[46,16,11,4],
[46,16,11,5],
[46,16,11,6],
[46,16,11,7],
[46,16,11,8],
[46,16,11,10],
[46,16,12,0],
[46,16,12,1],
[46,16,12,2],
[46,16,12,3],
[46,16,12,4],
[46,16,12,5],
[46,16,12,6],
[46,16,12,7],
[46,16,12,8],
[46,16,12,10],
[46,16,13,0],
[46,16,13,1],
[46,16,13,2],
[46,16,13,3],
[46,16,13,4],
[46,16,13,5],
[46,16,13,6],
[46,16,13,7],
[46,16,13,8],
[46,16,13,10],
[46,16,13,11],
[46,16,13,12],
[46,16,14,0],
[46,16,14,1],
[46,16,14,2],
[46,16,14,3],
[46,16,14,4],
[46,16,14,5],
[46,16,14,6],
[46,16,14,7],
[46,16,14,8],
[46,16,14,10],
[46,16,14,11],
[46,16,14,12],
[46,17,3,0],
[46,17,3,1],
[46,17,3,2],
[46,17,4,0],
[46,17,4,1],
[46,17,4,2],
[46,17,4,3],
[46,17,5,0],
[46,17,5,1],
[46,17,5,2],
[46,17,5,4],
[46,17,6,0],
[46,17,6,1],
[46,17,6,2],
[46,17,6,4],
[46,17,7,0],
[46,17,7,1],
[46,17,7,2],
[46,17,7,3],
[46,17,7,4],
[46,17,7,5],
[46,17,7,6],
[46,17,8,0],
[46,17,8,1],
[46,17,8,2],
[46,17,8,4],
[46,17,8,7],
[46,17,10,0],
[46,17,10,1],
[46,17,10,2],
[46,17,10,3],
[46,17,10,4],
[46,17,10,5],
[46,17,10,6],
[46,17,10,7],
[46,17,10,8],
[46,17,11,0],
[46,17,11,1],
[46,17,11,2],
[46,17,11,3],
[46,17,11,4],
[46,17,11,5],
[46,17,11,6],
[46,17,11,7],
[46,17,11,8],
[46,17,11,10],
[46,17,12,0],
[46,17,12,1],
[46,17,12,2],
[46,17,12,3],
[46,17,12,4],
[46,17,12,5],
[46,17,12,6],
[46,17,12,7],
[46,17,12,8],
[46,17,12,10],
[46,17,13,0],
[46,17,13,1],
[46,17,13,2],
[46,17,13,3],
[46,17,13,4],
[46,17,13,5],
[46,17,13,6],
[46,17,13,7],
[46,17,13,8],
[46,17,13,10],
[46,17,13,11],
[46,17,13,12],
[46,17,14,0],
[46,17,14,1],
[46,17,14,2],
[46,17,14,3],
[46,17,14,4],
[46,17,14,5],
[46,17,14,6],
[46,17,14,7],
[46,17,14,8],
[46,17,14,10],
[46,17,14,11],
[46,17,14,12],
[46,17,16,0],
[46,17,16,1],
[46,17,16,2],
[46,17,16,3],
[46,17,16,4],
[46,17,16,5],
[46,17,16,6],
[46,17,16,7],
[46,17,16,8],
[46,17,16,10],
[46,17,16,11],
[46,17,16,12],
[46,17,16,13],
[46,17,16,14],
[46,19,3,0],
[46,19,3,1],
[46,19,3,2],
[46,19,4,0],
[46,19,4,1],
[46,19,4,2],
[46,19,4,3],
[46,19,5,0],
[46,19,5,1],
[46,19,5,2],
[46,19,5,4],
[46,19,6,0],
[46,19,6,1],
[46,19,6,2],
[46,19,6,4],
[46,19,7,0],
[46,19,7,1],
[46,19,7,2],
[46,19,7,3],
[46,19,7,4],
[46,19,7,5],
[46,19,7,6],
[46,19,8,0],
[46,19,8,1],
[46,19,8,2],
[46,19,8,4],
[46,19,8,7],
[46,19,10,0],
[46,19,10,1],
[46,19,10,2],
[46,19,10,3],
[46,19,10,4],
[46,19,10,5],
[46,19,10,6],
[46,19,10,7],
[46,19,10,8],
[46,19,11,0],
[46,19,11,1],
[46,19,11,2],
[46,19,11,3],
[46,19,11,4],
[46,19,11,5],
[46,19,11,6],
[46,19,11,7],
[46,19,11,8],
[46,19,11,10],
[46,19,12,0],
[46,19,12,1],
[46,19,12,2],
[46,19,12,3],
[46,19,12,4],
[46,19,12,5],
[46,19,12,6],
[46,19,12,7],
[46,19,12,8],
[46,19,12,10],
[46,19,16,0],
[46,19,16,1],
[46,19,16,2],
[46,19,16,3],
[46,19,16,4],
[46,19,16,5],
[46,19,16,6],
[46,19,16,7],
[46,19,16,8],
[46,19,16,10],
[46,19,16,11],
[46,19,16,12],
[46,19,17,0],
[46,19,17,1],
[46,19,17,2],
[46,19,17,3],
[46,19,17,4],
[46,19,17,5],
[46,19,17,6],
[46,19,17,7],
[46,19,17,8],
[46,19,17,10],
[46,19,17,11],
[46,19,17,12],
[46,19,17,16],
[46,20,3,0],
[46,20,3,1],
[46,20,3,2],
[46,20,4,0],
[46,20,4,1],
[46,20,4,2],
[46,20,4,3],
[46,20,5,0],
[46,20,5,1],
[46,20,5,2],
[46,20,5,4],
[46,20,6,0],
[46,20,6,1],
[46,20,6,2],
[46,20,6,4],
[46,20,7,0],
[46,20,7,1],
[46,20,7,2],
[46,20,7,3],
[46,20,7,4],
[46,20,7,5],
[46,20,7,6],
[46,20,8,0],
[46,20,8,1],
[46,20,8,2],
[46,20,8,4],
[46,20,8,7],
[46,20,11,0],
[46,20,11,1],
[46,20,11,2],
[46,20,11,3],
[46,20,11,4],
[46,20,11,5],
[46,20,11,6],
[46,20,11,7],
[46,20,11,8],
[46,20,12,0],
[46,20,12,1],
[46,20,12,2],
[46,20,12,3],
[46,20,12,4],
[46,20,12,5],
[46,20,12,6],
[46,20,12,7],
[46,20,12,8],
[46,20,13,0],
[46,20,13,1],
[46,20,13,2],
[46,20,13,3],
[46,20,13,4],
[46,20,13,5],
[46,20,13,6],
[46,20,13,7],
[46,20,13,8],
[46,20,13,11],
[46,20,13,12],
[46,20,14,0],
[46,20,14,1],
[46,20,14,2],
[46,20,14,3],
[46,20,14,4],
[46,20,14,5],
[46,20,14,6],
[46,20,14,7],
[46,20,14,8],
[46,20,14,11],
[46,20,14,12],
[46,20,16,0],
[46,20,16,1],
[46,20,16,2],
[46,20,16,3],
[46,20,16,4],
[46,20,16,5],
[46,20,16,6],
[46,20,16,7],
[46,20,16,8],
[46,20,16,11],
[46,20,16,12],
[46,20,16,13],
[46,20,16,14],
[46,20,17,0],
[46,20,17,1],
[46,20,17,2],
[46,20,17,3],
[46,20,17,4],
[46,20,17,5],
[46,20,17,6],
[46,20,17,7],
[46,20,17,8],
[46,20,17,11],
[46,20,17,12],
[46,20,17,13],
[46,20,17,14],
[46,20,17,16],
[46,20,19,0],
[46,20,19,1],
[46,20,19,2],
[46,20,19,3],
[46,20,19,4],
[46,20,19,5],
[46,20,19,6],
[46,20,19,7],
[46,20,19,8],
[46,20,19,11],
[46,20,19,12],
[46,20,19,16],
[46,20,19,17],
[46,21,4,0],
[46,21,4,1],
[46,21,4,2],
[46,21,7,0],
[46,21,7,1],
[46,21,7,2],
[46,21,7,4],
[46,21,10,0],
[46,21,10,1],
[46,21,10,2],
[46,21,10,4],
[46,21,10,7],
[46,21,11,0],
[46,21,11,1],
[46,21,11,2],
[46,21,11,4],
[46,21,11,7],
[46,21,11,10],
[46,21,12,0],
[46,21,12,1],
[46,21,12,2],
[46,21,12,4],
[46,21,12,7],
[46,21,12,10],
[46,21,13,0],
[46,21,13,1],
[46,21,13,2],
[46,21,13,4],
[46,21,13,7],
[46,21,13,10],
[46,21,13,11],
[46,21,13,12],
[46,21,14,0],
[46,21,14,1],
[46,21,14,2],
[46,21,14,4],
[46,21,14,7],
[46,21,14,10],
[46,21,14,11],
[46,21,14,12],
[46,21,16,0],
[46,21,16,1],
[46,21,16,2],
[46,21,16,4],
[46,21,16,7],
[46,21,16,10],
[46,21,16,11],
[46,21,16,12],
[46,21,16,13],
[46,21,16,14],
[46,21,17,0],
[46,21,17,1],
[46,21,17,2],
[46,21,17,4],
[46,21,17,7],
[46,21,17,10],
[46,21,17,11],
[46,21,17,12],
[46,21,17,13],
[46,21,17,14],
[46,21,17,16],
[46,21,19,0],
[46,21,19,1],
[46,21,19,2],
[46,21,19,4],
[46,21,19,7],
[46,21,19,10],
[46,21,19,11],
[46,21,19,12],
[46,21,19,16],
[46,21,19,17],
[46,21,20,0],
[46,21,20,1],
[46,21,20,2],
[46,21,20,4],
[46,21,20,7],
[46,21,20,11],
[46,21,20,12],
[46,21,20,13],
[46,21,20,14],
[46,21,20,16],
[46,21,20,17],
[46,21,20,19],
[46,22,3,0],
[46,22,3,1],
[46,22,3,2],
[46,22,4,0],
[46,22,4,1],
[46,22,4,2],
[46,22,4,3],
[46,22,5,0],
[46,22,5,1],
[46,22,5,2],
[46,22,5,4],
[46,22,6,0],
[46,22,6,1],
[46,22,6,2],
[46,22,6,4],
[46,22,7,0],
[46,22,7,1],
[46,22,7,2],
[46,22,7,3],
[46,22,7,4],
[46,22,7,5],
[46,22,7,6],
[46,22,8,0],
[46,22,8,1],
[46,22,8,2],
[46,22,8,4],
[46,22,8,7],
[46,22,11,0],
[46,22,11,1],
[46,22,11,2],
[46,22,11,3],
[46,22,11,4],
[46,22,11,5],
[46,22,11,6],
[46,22,11,7],
[46,22,11,8],
[46,22,12,0],
[46,22,12,1],
[46,22,12,2],
[46,22,12,3],
[46,22,12,4],
[46,22,12,5],
[46,22,12,6],
[46,22,12,7],
[46,22,12,8],
[46,22,13,0],
[46,22,13,1],
[46,22,13,2],
[46,22,13,3],
[46,22,13,4],
[46,22,13,5],
[46,22,13,6],
[46,22,13,7],
[46,22,13,8],
[46,22,13,11],
[46,22,13,12],
[46,22,14,0],
[46,22,14,1],
[46,22,14,2],
[46,22,14,3],
[46,22,14,4],
[46,22,14,5],
[46,22,14,6],
[46,22,14,7],
[46,22,14,8],
[46,22,14,11],
[46,22,14,12],
[46,22,16,0],
[46,22,16,1],
[46,22,16,2],
[46,22,16,3],
[46,22,16,4],
[46,22,16,5],
[46,22,16,6],
[46,22,16,7],
[46,22,16,8],
[46,22,16,11],
[46,22,16,12],
[46,22,16,13],
[46,22,16,14],
[46,22,17,0],
[46,22,17,1],
[46,22,17,2],
[46,22,17,3],
[46,22,17,4],
[46,22,17,5],
[46,22,17,6],
[46,22,17,7],
[46,22,17,8],
[46,22,17,11],
[46,22,17,12],
[46,22,17,13],
[46,22,17,14],
[46,22,17,16],
[46,22,19,0],
[46,22,19,1],
[46,22,19,2],
[46,22,19,3],
[46,22,19,4],
[46,22,19,5],
[46,22,19,6],
[46,22,19,7],
[46,22,19,8],
[46,22,19,11],
[46,22,19,12],
[46,22,19,16],
[46,22,19,17],
[46,22,21,0],
[46,22,21,1],
[46,22,21,2],
[46,22,21,4],
[46,22,21,7],
[46,22,21,11],
[46,22,21,12],
[46,22,21,13],
[46,22,21,14],
[46,22,21,16],
[46,22,21,17],
[46,22,21,19],
[46,23,3,0],
[46,23,3,1],
[46,23,3,2],
[46,23,4,0],
[46,23,4,1],
[46,23,4,2],
[46,23,4,3],
[46,23,5,0],
[46,23,5,1],
[46,23,5,2],
[46,23,5,4],
[46,23,6,0],
[46,23,6,1],
[46,23,6,2],
[46,23,6,4],
[46,23,7,0],
[46,23,7,1],
[46,23,7,2],
[46,23,7,3],
[46,23,7,4],
[46,23,7,5],
[46,23,7,6],
[46,23,8,0],
[46,23,8,1],
[46,23,8,2],
[46,23,8,4],
[46,23,8,7],
[46,23,10,0],
[46,23,10,1],
[46,23,10,2],
[46,23,10,3],
[46,23,10,4],
[46,23,10,5],
[46,23,10,6],
[46,23,10,7],
[46,23,10,8],
[46,23,11,0],
[46,23,11,1],
[46,23,11,2],
[46,23,11,3],
[46,23,11,4],
[46,23,11,5],
[46,23,11,6],
[46,23,11,7],
[46,23,11,8],
[46,23,11,10],
[46,23,12,0],
[46,23,12,1],
[46,23,12,2],
[46,23,12,3],
[46,23,12,4],
[46,23,12,5],
[46,23,12,6],
[46,23,12,7],
[46,23,12,8],
[46,23,12,10],
[46,23,13,0],
[46,23,13,1],
[46,23,13,2],
[46,23,13,3],
[46,23,13,4],
[46,23,13,5],
[46,23,13,6],
[46,23,13,7],
[46,23,13,8],
[46,23,13,10],
[46,23,13,11],
[46,23,13,12],
[46,23,14,0],
[46,23,14,1],
[46,23,14,2],
[46,23,14,3],
[46,23,14,4],
[46,23,14,5],
[46,23,14,6],
[46,23,14,7],
[46,23,14,8],
[46,23,14,10],
[46,23,14,11],
[46,23,14,12],
[46,23,16,0],
[46,23,16,1],
[46,23,16,2],
[46,23,16,3],
[46,23,16,4],
[46,23,16,5],
[46,23,16,6],
[46,23,16,7],
[46,23,16,8],
[46,23,16,10],
[46,23,16,11],
[46,23,16,12],
[46,23,16,13],
[46,23,16,14],
[46,23,17,0],
[46,23,17,1],
[46,23,17,2],
[46,23,17,3],
[46,23,17,4],
[46,23,17,5],
[46,23,17,6],
[46,23,17,7],
[46,23,17,8],
[46,23,17,10],
[46,23,17,11],
[46,23,17,12],
[46,23,17,13],
[46,23,17,14],
[46,23,17,16],
[46,23,19,0],
[46,23,19,1],
[46,23,19,2],
[46,23,19,3],
[46,23,19,4],
[46,23,19,5],
[46,23,19,6],
[46,23,19,7],
[46,23,19,8],
[46,23,19,10],
[46,23,19,11],
[46,23,19,12],
[46,23,19,16],
[46,23,19,17],
[46,23,20,0],
[46,23,20,1],
[46,23,20,2],
[46,23,20,3],
[46,23,20,4],
[46,23,20,5],
[46,23,20,6],
[46,23,20,7],
[46,23,20,8],
[46,23,20,11],
[46,23,20,12],
[46,23,20,13],
[46,23,20,14],
[46,23,20,16],
[46,23,20,17],
[46,23,20,19],
[46,23,21,0],
[46,23,21,1],
[46,23,21,2],
[46,23,21,4],
[46,23,21,7],
[46,23,21,10],
[46,23,21,11],
[46,23,21,12],
[46,23,21,13],
[46,23,21,14],
[46,23,21,16],
[46,23,21,17],
[46,23,21,19],
[46,23,21,20],
[46,23,22,0],
[46,23,22,1],
[46,23,22,2],
[46,23,22,3],
[46,23,22,4],
[46,23,22,5],
[46,23,22,6],
[46,23,22,7],
[46,23,22,8],
[46,23,22,11],
[46,23,22,12],
[46,23,22,13],
[46,23,22,14],
[46,23,22,16],
[46,23,22,17],
[46,23,22,19],
[46,23,22,21],
[46,24,3,0],
[46,24,3,1],
[46,24,3,2],
[46,24,4,0],
[46,24,4,1],
[46,24,4,2],
[46,24,4,3],
[46,24,5,0],
[46,24,5,1],
[46,24,5,2],
[46,24,5,4],
[46,24,6,0],
[46,24,6,1],
[46,24,6,2],
[46,24,6,4],
[46,24,7,0],
[46,24,7,1],
[46,24,7,2],
[46,24,7,3],
[46,24,7,4],
[46,24,7,5],
[46,24,7,6],
[46,24,8,0],
[46,24,8,1],
[46,24,8,2],
[46,24,8,4],
[46,24,8,7],
[46,24,10,0],
[46,24,10,1],
[46,24,10,2],
[46,24,10,3],
[46,24,10,4],
[46,24,10,5],
[46,24,10,6],
[46,24,10,7],
[46,24,10,8],
[46,24,11,0],
[46,24,11,1],
[46,24,11,2],
[46,24,11,3],
[46,24,11,4],
[46,24,11,5],
[46,24,11,6],
[46,24,11,7],
[46,24,11,8],
[46,24,11,10],
[46,24,12,0],
[46,24,12,1],
[46,24,12,2],
[46,24,12,3],
[46,24,12,4],
[46,24,12,5],
[46,24,12,6],
[46,24,12,7],
[46,24,12,8],
[46,24,12,10],
[46,24,13,0],
[46,24,13,1],
[46,24,13,2],
[46,24,13,3],
[46,24,13,4],
[46,24,13,5],
[46,24,13,6],
[46,24,13,7],
[46,24,13,8],
[46,24,13,10],
[46,24,13,11],
[46,24,13,12],
[46,24,14,0],
[46,24,14,1],
[46,24,14,2],
[46,24,14,3],
[46,24,14,4],
[46,24,14,5],
[46,24,14,6],
[46,24,14,7],
[46,24,14,8],
[46,24,14,10],
[46,24,14,11],
[46,24,14,12],
[46,24,17,0],
[46,24,17,1],
[46,24,17,2],
[46,24,17,3],
[46,24,17,4],
[46,24,17,5],
[46,24,17,6],
[46,24,17,7],
[46,24,17,8],
[46,24,17,10],
[46,24,17,11],
[46,24,17,12],
[46,24,17,13],
[46,24,17,14],
[46,24,19,0],
[46,24,19,1],
[46,24,19,2],
[46,24,19,3],
[46,24,19,4],
[46,24,19,5],
[46,24,19,6],
[46,24,19,7],
[46,24,19,8],
[46,24,19,10],
[46,24,19,11],
[46,24,19,12],
[46,24,19,17],
[46,24,20,0],
[46,24,20,1],
[46,24,20,2],
[46,24,20,3],
[46,24,20,4],
[46,24,20,5],
[46,24,20,6],
[46,24,20,7],
[46,24,20,8],
[46,24,20,11],
[46,24,20,12],
[46,24,20,13],
[46,24,20,14],
[46,24,20,17],
[46,24,20,19],
[46,24,21,0],
[46,24,21,1],
[46,24,21,2],
[46,24,21,4],
[46,24,21,7],
[46,24,21,10],
[46,24,21,11],
[46,24,21,12],
[46,24,21,13],
[46,24,21,14],
[46,24,21,17],
[46,24,21,19],
[46,24,21,20],
[46,24,22,0],
[46,24,22,1],
[46,24,22,2],
[46,24,22,3],
[46,24,22,4],
[46,24,22,5],
[46,24,22,6],
[46,24,22,7],
[46,24,22,8],
[46,24,22,11],
[46,24,22,12],
[46,24,22,13],
[46,24,22,14],
[46,24,22,17],
[46,24,22,19],
[46,24,22,21],
[46,24,23,0],
[46,24,23,1],
[46,24,23,2],
[46,24,23,3],
[46,24,23,4],
[46,24,23,5],
[46,24,23,6],
[46,24,23,7],
[46,24,23,8],
[46,24,23,10],
[46,24,23,11],
[46,24,23,12],
[46,24,23,13],
[46,24,23,14],
[46,24,23,17],
[46,24,23,19],
[46,24,23,20],
[46,24,23,21],
[46,24,23,22],
[46,25,3,0],
[46,25,3,1],
[46,25,3,2],
[46,25,4,0],
[46,25,4,1],
[46,25,4,2],
[46,25,4,3],
[46,25,5,0],
[46,25,5,1],
[46,25,5,2],
[46,25,5,4],
[46,25,6,0],
[46,25,6,1],
[46,25,6,2],
[46,25,6,4],
[46,25,8,0],
[46,25,8,1],
[46,25,8,2],
[46,25,8,4],
[46,25,10,0],
[46,25,10,1],
[46,25,10,2],
[46,25,10,3],
[46,25,10,4],
[46,25,10,5],
[46,25,10,6],
[46,25,10,8],
[46,25,11,0],
[46,25,11,1],
[46,25,11,2],
[46,25,11,3],
[46,25,11,4],
[46,25,11,5],
[46,25,11,6],
[46,25,11,8],
[46,25,11,10],
[46,25,12,0],
[46,25,12,1],
[46,25,12,2],
[46,25,12,3],
[46,25,12,4],
[46,25,12,5],
[46,25,12,6],
[46,25,12,8],
[46,25,12,10],
[46,25,13,0],
[46,25,13,1],
[46,25,13,2],
[46,25,13,3],
[46,25,13,4],
[46,25,13,5],
[46,25,13,6],
[46,25,13,8],
[46,25,13,10],
[46,25,13,11],
[46,25,13,12],
[46,25,14,0],
[46,25,14,1],
[46,25,14,2],
[46,25,14,3],
[46,25,14,4],
[46,25,14,5],
[46,25,14,6],
[46,25,14,8],
[46,25,14,10],
[46,25,14,11],
[46,25,14,12],
[46,25,16,0],
[46,25,16,1],
[46,25,16,2],
[46,25,16,3],
[46,25,16,4],
[46,25,16,5],
[46,25,16,6],
[46,25,16,8],
[46,25,16,10],
[46,25,16,11],
[46,25,16,12],
[46,25,16,13],
[46,25,16,14],
[46,25,17,0],
[46,25,17,1],
[46,25,17,2],
[46,25,17,3],
[46,25,17,4],
[46,25,17,5],
[46,25,17,6],
[46,25,17,8],
[46,25,17,10],
[46,25,17,11],
[46,25,17,12],
[46,25,17,13],
[46,25,17,14],
[46,25,17,16],
[46,25,19,0],
[46,25,19,1],
[46,25,19,2],
[46,25,19,3],
[46,25,19,4],
[46,25,19,5],
[46,25,19,6],
[46,25,19,8],
[46,25,19,10],
[46,25,19,11],
[46,25,19,12],
[46,25,19,16],
[46,25,19,17],
[46,25,20,0],
[46,25,20,1],
[46,25,20,2],
[46,25,20,3],
[46,25,20,4],
[46,25,20,5],
[46,25,20,6],
[46,25,20,8],
[46,25,20,11],
[46,25,20,12],
[46,25,20,13],
[46,25,20,14],
[46,25,20,16],
[46,25,20,17],
[46,25,20,19],
[46,25,21,0],
[46,25,21,1],
[46,25,21,2],
[46,25,21,4],
[46,25,21,10],
[46,25,21,11],
[46,25,21,12],
[46,25,21,13],
[46,25,21,14],
[46,25,21,16],
[46,25,21,17],
[46,25,21,19],
[46,25,21,20],
[46,25,22,0],
[46,25,22,1],
[46,25,22,2],
[46,25,22,3],
[46,25,22,4],
[46,25,22,5],
[46,25,22,6],
[46,25,22,8],
[46,25,22,11],
[46,25,22,12],
[46,25,22,13],
[46,25,22,14],
[46,25,22,16],
[46,25,22,17],
[46,25,22,19],
[46,25,22,21],
[46,25,23,0],
[46,25,23,1],
[46,25,23,2],
[46,25,23,3],
[46,25,23,4],
[46,25,23,5],
[46,25,23,6],
[46,25,23,8],
[46,25,23,10],
[46,25,23,11],
[46,25,23,12],
[46,25,23,13],
[46,25,23,14],
[46,25,23,16],
[46,25,23,17],
[46,25,23,19],
[46,25,23,20],
[46,25,23,21],
[46,25,23,22],
[46,25,24,0],
[46,25,24,1],
[46,25,24,2],
[46,25,24,3],
[46,25,24,4],
[46,25,24,5],
[46,25,24,6],
[46,25,24,8],
[46,25,24,10],
[46,25,24,11],
[46,25,24,12],
[46,25,24,13],
[46,25,24,14],
[46,25,24,17],
[46,25,24,19],
[46,25,24,20],
[46,25,24,21],
[46,25,24,22],
[46,25,24,23],
[46,27,3,0],
[46,27,3,1],
[46,27,3,2],
[46,27,4,0],
[46,27,4,1],
[46,27,4,2],
[46,27,4,3],
[46,27,5,0],
[46,27,5,1],
[46,27,5,2],
[46,27,5,4],
[46,27,6,0],
[46,27,6,1],
[46,27,6,2],
[46,27,6,4],
[46,27,7,0],
[46,27,7,1],
[46,27,7,2],
[46,27,7,3],
[46,27,7,4],
[46,27,7,5],
[46,27,7,6],
[46,27,8,0],
[46,27,8,1],
[46,27,8,2],
[46,27,8,4],
[46,27,8,7],
[46,27,11,0],
[46,27,11,1],
[46,27,11,2],
[46,27,11,3],
[46,27,11,4],
[46,27,11,5],
[46,27,11,6],
[46,27,11,7],
[46,27,11,8],
[46,27,12,0],
[46,27,12,1],
[46,27,12,2],
[46,27,12,3],
[46,27,12,4],
[46,27,12,5],
[46,27,12,6],
[46,27,12,7],
[46,27,12,8],
[46,27,13,0],
[46,27,13,1],
[46,27,13,2],
[46,27,13,3],
[46,27,13,4],
[46,27,13,5],
[46,27,13,6],
[46,27,13,7],
[46,27,13,8],
[46,27,13,11],
[46,27,13,12],
[46,27,14,0],
[46,27,14,1],
[46,27,14,2],
[46,27,14,3],
[46,27,14,4],
[46,27,14,5],
[46,27,14,6],
[46,27,14,7],
[46,27,14,8],
[46,27,14,11],
[46,27,14,12],
[46,27,16,0],
[46,27,16,1],
[46,27,16,2],
[46,27,16,3],
[46,27,16,4],
[46,27,16,5],
[46,27,16,6],
[46,27,16,7],
[46,27,16,8],
[46,27,16,11],
[46,27,16,12],
[46,27,16,13],
[46,27,16,14],
[46,27,17,0],
[46,27,17,1],
[46,27,17,2],
[46,27,17,3],
[46,27,17,4],
[46,27,17,5],
[46,27,17,6],
[46,27,17,7],
[46,27,17,8],
[46,27,17,11],
[46,27,17,12],
[46,27,17,13],
[46,27,17,14],
[46,27,17,16],
[46,27,19,0],
[46,27,19,1],
[46,27,19,2],
[46,27,19,3],
[46,27,19,4],
[46,27,19,5],
[46,27,19,6],
[46,27,19,7],
[46,27,19,8],
[46,27,19,11],
[46,27,19,12],
[46,27,19,16],
[46,27,19,17],
[46,27,21,0],
[46,27,21,1],
[46,27,21,2],
[46,27,21,4],
[46,27,21,7],
[46,27,21,11],
[46,27,21,12],
[46,27,21,13],
[46,27,21,14],
[46,27,21,16],
[46,27,21,17],
[46,27,21,19],
[46,27,23,0],
[46,27,23,1],
[46,27,23,2],
[46,27,23,3],
[46,27,23,4],
[46,27,23,5],
[46,27,23,6],
[46,27,23,7],
[46,27,23,8],
[46,27,23,11],
[46,27,23,12],
[46,27,23,13],
[46,27,23,14],
[46,27,23,16],
[46,27,23,17],
[46,27,23,19],
[46,27,23,21],
[46,27,24,0],
[46,27,24,1],
[46,27,24,2],
[46,27,24,3],
[46,27,24,4],
[46,27,24,5],
[46,27,24,6],
[46,27,24,7],
[46,27,24,8],
[46,27,24,11],
[46,27,24,12],
[46,27,24,13],
[46,27,24,14],
[46,27,24,17],
[46,27,24,19],
[46,27,24,21],
[46,27,24,23],
[46,27,25,0],
[46,27,25,1],
[46,27,25,2],
[46,27,25,3],
[46,27,25,4],
[46,27,25,5],
[46,27,25,6],
[46,27,25,8],
[46,27,25,11],
[46,27,25,12],
[46,27,25,13],
[46,27,25,14],
[46,27,25,16],
[46,27,25,17],
[46,27,25,19],
[46,27,25,21],
[46,27,25,23],
[46,27,25,24],
[46,28,3,0],
[46,28,3,1],
[46,28,3,2],
[46,28,4,0],
[46,28,4,1],
[46,28,4,2],
[46,28,4,3],
[46,28,5,0],
[46,28,5,1],
[46,28,5,2],
[46,28,5,4],
[46,28,6,0],
[46,28,6,1],
[46,28,6,2],
[46,28,6,4],
[46,28,8,0],
[46,28,8,1],
[46,28,8,2],
[46,28,8,4],
[46,28,10,0],
[46,28,10,1],
[46,28,10,2],
[46,28,10,3],
[46,28,10,4],
[46,28,10,5],
[46,28,10,6],
[46,28,10,8],
[46,28,11,0],
[46,28,11,1],
[46,28,11,2],
[46,28,11,3],
[46,28,11,4],
[46,28,11,5],
[46,28,11,6],
[46,28,11,8],
[46,28,11,10],
[46,28,12,0],
[46,28,12,1],
[46,28,12,2],
[46,28,12,3],
[46,28,12,4],
[46,28,12,5],
[46,28,12,6],
[46,28,12,8],
[46,28,12,10],
[46,28,13,0],
[46,28,13,1],
[46,28,13,2],
[46,28,13,3],
[46,28,13,4],
[46,28,13,5],
[46,28,13,6],
[46,28,13,8],
[46,28,13,10],
[46,28,13,11],
[46,28,13,12],
[46,28,14,0],
[46,28,14,1],
[46,28,14,2],
[46,28,14,3],
[46,28,14,4],
[46,28,14,5],
[46,28,14,6],
[46,28,14,8],
[46,28,14,10],
[46,28,14,11],
[46,28,14,12],
[46,28,16,0],
[46,28,16,1],
[46,28,16,2],
[46,28,16,3],
[46,28,16,4],
[46,28,16,5],
[46,28,16,6],
[46,28,16,8],
[46,28,16,10],
[46,28,16,11],
[46,28,16,12],
[46,28,16,13],
[46,28,16,14],
[46,28,17,0],
[46,28,17,1],
[46,28,17,2],
[46,28,17,3],
[46,28,17,4],
[46,28,17,5],
[46,28,17,6],
[46,28,17,8],
[46,28,17,10],
[46,28,17,11],
[46,28,17,12],
[46,28,17,13],
[46,28,17,14],
[46,28,17,16],
[46,28,19,0],
[46,28,19,1],
[46,28,19,2],
[46,28,19,3],
[46,28,19,4],
[46,28,19,5],
[46,28,19,6],
[46,28,19,8],
[46,28,19,10],
[46,28,19,11],
[46,28,19,12],
[46,28,19,16],
[46,28,19,17],
[46,28,20,0],
[46,28,20,1],
[46,28,20,2],
[46,28,20,3],
[46,28,20,4],
[46,28,20,5],
[46,28,20,6],
[46,28,20,8],
[46,28,20,11],
[46,28,20,12],
[46,28,20,13],
[46,28,20,14],
[46,28,20,16],
[46,28,20,17],
[46,28,20,19],
[46,28,21,0],
[46,28,21,1],
[46,28,21,2],
[46,28,21,4],
[46,28,21,10],
[46,28,21,11],
[46,28,21,12],
[46,28,21,13],
[46,28,21,14],
[46,28,21,16],
[46,28,21,17],
[46,28,21,19],
[46,28,21,20],
[46,28,22,0],
[46,28,22,1],
[46,28,22,2],
[46,28,22,3],
[46,28,22,4],
[46,28,22,5],
[46,28,22,6],
[46,28,22,8],
[46,28,22,11],
[46,28,22,12],
[46,28,22,13],
[46,28,22,14],
[46,28,22,16],
[46,28,22,17],
[46,28,22,19],
[46,28,22,21],
[46,28,23,0],
[46,28,23,1],
[46,28,23,2],
[46,28,23,3],
[46,28,23,4],
[46,28,23,5],
[46,28,23,6],
[46,28,23,8],
[46,28,23,10],
[46,28,23,11],
[46,28,23,12],
[46,28,23,13],
[46,28,23,14],
[46,28,23,16],
[46,28,23,17],
[46,28,23,19],
[46,28,23,20],
[46,28,23,21],
[46,28,23,22],
[46,28,24,0],
[46,28,24,1],
[46,28,24,2],
[46,28,24,3],
[46,28,24,4],
[46,28,24,5],
[46,28,24,6],
[46,28,24,8],
[46,28,24,10],
[46,28,24,11],
[46,28,24,12],
[46,28,24,13],
[46,28,24,14],
[46,28,24,17],
[46,28,24,19],
[46,28,24,20],
[46,28,24,21],
[46,28,24,22],
[46,28,24,23],
[46,28,27,0],
[46,28,27,1],
[46,28,27,2],
[46,28,27,3],
[46,28,27,4],
[46,28,27,5],
[46,28,27,6],
[46,28,27,8],
[46,28,27,11],
[46,28,27,12],
[46,28,27,13],
[46,28,27,14],
[46,28,27,16],
[46,28,27,17],
[46,28,27,19],
[46,28,27,21],
[46,28,27,23],
[46,28,27,24],
[46,29,3,0],
[46,29,3,1],
[46,29,3,2],
[46,29,4,0],
[46,29,4,1],
[46,29,4,2],
[46,29,4,3],
[46,29,5,0],
[46,29,5,1],
[46,29,5,2],
[46,29,5,4],
[46,29,6,0],
[46,29,6,1],
[46,29,6,2],
[46,29,6,4],
[46,29,7,0],
[46,29,7,1],
[46,29,7,2],
[46,29,7,3],
[46,29,7,4],
[46,29,7,5],
[46,29,7,6],
[46,29,8,0],
[46,29,8,1],
[46,29,8,2],
[46,29,8,4],
[46,29,8,7],
[46,29,10,0],
[46,29,10,1],
[46,29,10,2],
[46,29,10,3],
[46,29,10,4],
[46,29,10,5],
[46,29,10,6],
[46,29,10,7],
[46,29,10,8],
[46,29,13,0],
[46,29,13,1],
[46,29,13,2],
[46,29,13,3],
[46,29,13,4],
[46,29,13,5],
[46,29,13,6],
[46,29,13,7],
[46,29,13,8],
[46,29,13,10],
[46,29,14,0],
[46,29,14,1],
[46,29,14,2],
[46,29,14,3],
[46,29,14,4],
[46,29,14,5],
[46,29,14,6],
[46,29,14,7],
[46,29,14,8],
[46,29,14,10],
[46,29,16,0],
[46,29,16,1],
[46,29,16,2],
[46,29,16,3],
[46,29,16,4],
[46,29,16,5],
[46,29,16,6],
[46,29,16,7],
[46,29,16,8],
[46,29,16,10],
[46,29,16,13],
[46,29,16,14],
[46,29,17,0],
[46,29,17,1],
[46,29,17,2],
[46,29,17,3],
[46,29,17,4],
[46,29,17,5],
[46,29,17,6],
[46,29,17,7],
[46,29,17,8],
[46,29,17,10],
[46,29,17,13],
[46,29,17,14],
[46,29,17,16],
[46,29,19,0],
[46,29,19,1],
[46,29,19,2],
[46,29,19,3],
[46,29,19,4],
[46,29,19,5],
[46,29,19,6],
[46,29,19,7],
[46,29,19,8],
[46,29,19,10],
[46,29,19,16],
[46,29,19,17],
[46,29,20,0],
[46,29,20,1],
[46,29,20,2],
[46,29,20,3],
[46,29,20,4],
[46,29,20,5],
[46,29,20,6],
[46,29,20,7],
[46,29,20,8],
[46,29,20,13],
[46,29,20,14],
[46,29,20,16],
[46,29,20,17],
[46,29,20,19],
[46,29,21,0],
[46,29,21,1],
[46,29,21,2],
[46,29,21,4],
[46,29,21,7],
[46,29,21,10],
[46,29,21,13],
[46,29,21,14],
[46,29,21,16],
[46,29,21,17],
[46,29,21,19],
[46,29,21,20],
[46,29,22,0],
[46,29,22,1],
[46,29,22,2],
[46,29,22,3],
[46,29,22,4],
[46,29,22,5],
[46,29,22,6],
[46,29,22,7],
[46,29,22,8],
[46,29,22,13],
[46,29,22,14],
[46,29,22,16],
[46,29,22,17],
[46,29,22,19],
[46,29,22,21],
[46,29,23,0],
[46,29,23,1],
[46,29,23,2],
[46,29,23,3],
[46,29,23,4],
[46,29,23,5],
[46,29,23,6],
[46,29,23,7],
[46,29,23,8],
[46,29,23,10],
[46,29,23,13],
[46,29,23,14],
[46,29,23,16],
[46,29,23,17],
[46,29,23,19],
[46,29,23,20],
[46,29,23,21],
[46,29,23,22],
[46,29,24,0],
[46,29,24,1],
[46,29,24,2],
[46,29,24,3],
[46,29,24,4],
[46,29,24,5],
[46,29,24,6],
[46,29,24,7],
[46,29,24,8],
[46,29,24,10],
[46,29,24,13],
[46,29,24,14],
[46,29,24,17],
[46,29,24,19],
[46,29,24,20],
[46,29,24,21],
[46,29,24,22],
[46,29,24,23],
[46,29,25,0],
[46,29,25,1],
[46,29,25,2],
[46,29,25,3],
[46,29,25,4],
[46,29,25,5],
[46,29,25,6],
[46,29,25,8],
[46,29,25,10],
[46,29,25,13],
[46,29,25,14],
[46,29,25,16],
[46,29,25,17],
[46,29,25,19],
[46,29,25,20],
[46,29,25,21],
[46,29,25,22],
[46,29,25,23],
[46,29,25,24],
[46,29,27,0],
[46,29,27,1],
[46,29,27,2],
[46,29,27,3],
[46,29,27,4],
[46,29,27,5],
[46,29,27,6],
[46,29,27,7],
[46,29,27,8],
[46,29,27,13],
[46,29,27,14],
[46,29,27,16],
[46,29,27,17],
[46,29,27,19],
[46,29,27,21],
[46,29,27,23],
[46,29,27,24],
[46,29,27,25],
[46,29,28,0],
[46,29,28,1],
[46,29,28,2],
[46,29,28,3],
[46,29,28,4],
[46,29,28,5],
[46,29,28,6],
[46,29,28,8],
[46,29,28,10],
[46,29,28,13],
[46,29,28,14],
[46,29,28,16],
[46,29,28,17],
[46,29,28,19],
[46,29,28,20],
[46,29,28,21],
[46,29,28,22],
[46,29,28,23],
[46,29,28,24],
[46,29,28,27],
[46,30,3,0],
[46,30,3,1],
[46,30,3,2],
[46,30,4,0],
[46,30,4,1],
[46,30,4,2],
[46,30,4,3],
[46,30,5,0],
[46,30,5,1],
[46,30,5,2],
[46,30,5,4],
[46,30,6,0],
[46,30,6,1],
[46,30,6,2],
[46,30,6,4],
[46,30,7,0],
[46,30,7,1],
[46,30,7,2],
[46,30,7,3],
[46,30,7,4],
[46,30,7,5],
[46,30,7,6],
[46,30,8,0],
[46,30,8,1],
[46,30,8,2],
[46,30,8,4],
[46,30,8,7],
[46,30,10,0],
[46,30,10,1],
[46,30,10,2],
[46,30,10,3],
[46,30,10,4],
[46,30,10,5],
[46,30,10,6],
[46,30,10,7],
[46,30,10,8],
[46,30,11,0],
[46,30,11,1],
[46,30,11,2],
[46,30,11,3],
[46,30,11,4],
[46,30,11,5],
[46,30,11,6],
[46,30,11,7],
[46,30,11,8],
[46,30,11,10],
[46,30,12,0],
[46,30,12,1],
[46,30,12,2],
[46,30,12,3],
[46,30,12,4],
[46,30,12,5],
[46,30,12,6],
[46,30,12,7],
[46,30,12,8],
[46,30,12,10],
[46,30,13,0],
[46,30,13,1],
[46,30,13,2],
[46,30,13,3],
[46,30,13,4],
[46,30,13,5],
[46,30,13,6],
[46,30,13,7],
[46,30,13,8],
[46,30,13,10],
[46,30,13,11],
[46,30,13,12],
[46,30,14,0],
[46,30,14,1],
[46,30,14,2],
[46,30,14,3],
[46,30,14,4],
[46,30,14,5],
[46,30,14,6],
[46,30,14,7],
[46,30,14,8],
[46,30,14,10],
[46,30,14,11],
[46,30,14,12],
[46,30,16,0],
[46,30,16,1],
[46,30,16,2],
[46,30,16,3],
[46,30,16,4],
[46,30,16,5],
[46,30,16,6],
[46,30,16,7],
[46,30,16,8],
[46,30,16,10],
[46,30,16,11],
[46,30,16,12],
[46,30,16,13],
[46,30,16,14],
[46,30,17,0],
[46,30,17,1],
[46,30,17,2],
[46,30,17,3],
[46,30,17,4],
[46,30,17,5],
[46,30,17,6],
[46,30,17,7],
[46,30,17,8],
[46,30,17,10],
[46,30,17,11],
[46,30,17,12],
[46,30,17,13],
[46,30,17,14],
[46,30,17,16],
[46,30,19,0],
[46,30,19,1],
[46,30,19,2],
[46,30,19,3],
[46,30,19,4],
[46,30,19,5],
[46,30,19,6],
[46,30,19,7],
[46,30,19,8],
[46,30,19,10],
[46,30,19,11],
[46,30,19,12],
[46,30,19,16],
[46,30,19,17],
[46,30,20,0],
[46,30,20,1],
[46,30,20,2],
[46,30,20,3],
[46,30,20,4],
[46,30,20,5],
[46,30,20,6],
[46,30,20,7],
[46,30,20,8],
[46,30,20,11],
[46,30,20,12],
[46,30,20,13],
[46,30,20,14],
[46,30,20,16],
[46,30,20,17],
[46,30,20,19],
[46,30,21,0],
[46,30,21,1],
[46,30,21,2],
[46,30,21,4],
[46,30,21,7],
[46,30,21,10],
[46,30,21,11],
[46,30,21,12],
[46,30,21,13],
[46,30,21,14],
[46,30,21,16],
[46,30,21,17],
[46,30,21,19],
[46,30,21,20],
[46,30,22,0],
[46,30,22,1],
[46,30,22,2],
[46,30,22,3],
[46,30,22,4],
[46,30,22,5],
[46,30,22,6],
[46,30,22,7],
[46,30,22,8],
[46,30,22,11],
[46,30,22,12],
[46,30,22,13],
[46,30,22,14],
[46,30,22,16],
[46,30,22,17],
[46,30,22,19],
[46,30,22,21],
[46,30,24,0],
[46,30,24,1],
[46,30,24,2],
[46,30,24,3],
[46,30,24,4],
[46,30,24,5],
[46,30,24,6],
[46,30,24,7],
[46,30,24,8],
[46,30,24,10],
[46,30,24,11],
[46,30,24,12],
[46,30,24,13],
[46,30,24,14],
[46,30,24,17],
[46,30,24,19],
[46,30,24,20],
[46,30,24,21],
[46,30,24,22],
[46,30,25,0],
[46,30,25,1],
[46,30,25,2],
[46,30,25,3],
[46,30,25,4],
[46,30,25,5],
[46,30,25,6],
[46,30,25,8],
[46,30,25,10],
[46,30,25,11],
[46,30,25,12],
[46,30,25,13],
[46,30,25,14],
[46,30,25,16],
[46,30,25,17],
[46,30,25,19],
[46,30,25,20],
[46,30,25,21],
[46,30,25,22],
[46,30,25,24],
[46,30,27,0],
[46,30,27,1],
[46,30,27,2],
[46,30,27,3],
[46,30,27,4],
[46,30,27,5],
[46,30,27,6],
[46,30,27,7],
[46,30,27,8],
[46,30,27,11],
[46,30,27,12],
[46,30,27,13],
[46,30,27,14],
[46,30,27,16],
[46,30,27,17],
[46,30,27,19],
[46,30,27,21],
[46,30,27,24],
[46,30,28,0],
[46,30,28,1],
[46,30,28,2],
[46,30,28,3],
[46,30,28,4],
[46,30,28,5],
[46,30,28,6],
[46,30,28,8],
[46,30,28,10],
[46,30,28,11],
[46,30,28,12],
[46,30,28,13],
[46,30,28,14],
[46,30,28,16],
[46,30,28,17],
[46,30,28,19],
[46,30,28,20],
[46,30,28,21],
[46,30,28,22],
[46,30,28,24],
[46,30,28,27],
[46,30,29,0],
[46,30,29,1],
[46,30,29,2],
[46,30,29,3],
[46,30,29,4],
[46,30,29,5],
[46,30,29,6],
[46,30,29,7],
[46,30,29,8],
[46,30,29,10],
[46,30,29,13],
[46,30,29,14],
[46,30,29,16],
[46,30,29,17],
[46,30,29,19],
[46,30,29,20],
[46,30,29,21],
[46,30,29,22],
[46,30,29,25],
[46,30,29,27],
[46,30,29,28],
[46,31,3,0],
[46,31,3,1],
[46,31,3,2],
[46,31,4,0],
[46,31,4,1],
[46,31,4,2],
[46,31,4,3],
[46,31,5,0],
[46,31,5,1],
[46,31,5,2],
[46,31,5,4],
[46,31,6,0],
[46,31,6,1],
[46,31,6,2],
[46,31,6,4],
[46,31,7,0],
[46,31,7,1],
[46,31,7,2],
[46,31,7,3],
[46,31,7,4],
[46,31,7,5],
[46,31,7,6],
[46,31,8,0],
[46,31,8,1],
[46,31,8,2],
[46,31,8,4],
[46,31,8,7],
[46,31,10,0],
[46,31,10,1],
[46,31,10,2],
[46,31,10,3],
[46,31,10,4],
[46,31,10,5],
[46,31,10,6],
[46,31,10,7],
[46,31,10,8],
[46,31,11,0],
[46,31,11,1],
[46,31,11,2],
[46,31,11,3],
[46,31,11,4],
[46,31,11,5],
[46,31,11,6],
[46,31,11,7],
[46,31,11,8],
[46,31,11,10],
[46,31,12,0],
[46,31,12,1],
[46,31,12,2],
[46,31,12,3],
[46,31,12,4],
[46,31,12,5],
[46,31,12,6],
[46,31,12,7],
[46,31,12,8],
[46,31,12,10],
[46,31,13,0],
[46,31,13,1],
[46,31,13,2],
[46,31,13,3],
[46,31,13,4],
[46,31,13,5],
[46,31,13,6],
[46,31,13,7],
[46,31,13,8],
[46,31,13,10],
[46,31,13,11],
[46,31,13,12],
[46,31,14,0],
[46,31,14,1],
[46,31,14,2],
[46,31,14,3],
[46,31,14,4],
[46,31,14,5],
[46,31,14,6],
[46,31,14,7],
[46,31,14,8],
[46,31,14,10],
[46,31,14,11],
[46,31,14,12],
[46,31,16,0],
[46,31,16,1],
[46,31,16,2],
[46,31,16,3],
[46,31,16,4],
[46,31,16,5],
[46,31,16,6],
[46,31,16,7],
[46,31,16,8],
[46,31,16,10],
[46,31,16,11],
[46,31,16,12],
[46,31,16,13],
[46,31,16,14],
[46,31,17,0],
[46,31,17,1],
[46,31,17,2],
[46,31,17,3],
[46,31,17,4],
[46,31,17,5],
[46,31,17,6],
[46,31,17,7],
[46,31,17,8],
[46,31,17,10],
[46,31,17,11],
[46,31,17,12],
[46,31,17,13],
[46,31,17,14],
[46,31,17,16],
[46,31,19,0],
[46,31,19,1],
[46,31,19,2],
[46,31,19,3],
[46,31,19,4],
[46,31,19,5],
[46,31,19,6],
[46,31,19,7],
[46,31,19,8],
[46,31,19,10],
[46,31,19,11],
[46,31,19,12],
[46,31,19,16],
[46,31,19,17],
[46,31,20,0],
[46,31,20,1],
[46,31,20,2],
[46,31,20,3],
[46,31,20,4],
[46,31,20,5],
[46,31,20,6],
[46,31,20,7],
[46,31,20,8],
[46,31,20,11],
[46,31,20,12],
[46,31,20,13],
[46,31,20,14],
[46,31,20,16],
[46,31,20,17],
[46,31,20,19],
[46,31,21,0],
[46,31,21,1],
[46,31,21,2],
[46,31,21,4],
[46,31,21,7],
[46,31,21,10],
[46,31,21,11],
[46,31,21,12],
[46,31,21,13],
[46,31,21,14],
[46,31,21,16],
[46,31,21,17],
[46,31,21,19],
[46,31,21,20],
[46,31,22,0],
[46,31,22,1],
[46,31,22,2],
[46,31,22,3],
[46,31,22,4],
[46,31,22,5],
[46,31,22,6],
[46,31,22,7],
[46,31,22,8],
[46,31,22,11],
[46,31,22,12],
[46,31,22,13],
[46,31,22,14],
[46,31,22,16],
[46,31,22,17],
[46,31,22,19],
[46,31,22,21],
[46,31,24,0],
[46,31,24,1],
[46,31,24,2],
[46,31,24,3],
[46,31,24,4],
[46,31,24,5],
[46,31,24,6],
[46,31,24,7],
[46,31,24,8],
[46,31,24,10],
[46,31,24,11],
[46,31,24,12],
[46,31,24,13],
[46,31,24,14],
[46,31,24,17],
[46,31,24,19],
[46,31,24,20],
[46,31,24,21],
[46,31,24,22],
[46,31,25,0],
[46,31,25,1],
[46,31,25,2],
[46,31,25,3],
[46,31,25,4],
[46,31,25,5],
[46,31,25,6],
[46,31,25,8],
[46,31,25,10],
[46,31,25,11],
[46,31,25,12],
[46,31,25,13],
[46,31,25,14],
[46,31,25,16],
[46,31,25,17],
[46,31,25,19],
[46,31,25,20],
[46,31,25,21],
[46,31,25,22],
[46,31,25,24],
[46,31,27,0],
[46,31,27,1],
[46,31,27,2],
[46,31,27,3],
[46,31,27,4],
[46,31,27,5],
[46,31,27,6],
[46,31,27,7],
[46,31,27,8],
[46,31,27,11],
[46,31,27,12],
[46,31,27,13],
[46,31,27,14],
[46,31,27,16],
[46,31,27,17],
[46,31,27,19],
[46,31,27,21],
[46,31,27,24],
[46,31,27,25],
[46,31,28,0],
[46,31,28,1],
[46,31,28,2],
[46,31,28,3],
[46,31,28,4],
[46,31,28,5],
[46,31,28,6],
[46,31,28,8],
[46,31,28,10],
[46,31,28,11],
[46,31,28,12],
[46,31,28,13],
[46,31,28,14],
[46,31,28,16],
[46,31,28,17],
[46,31,28,19],
[46,31,28,20],
[46,31,28,21],
[46,31,28,22],
[46,31,28,27],
[46,31,29,0],
[46,31,29,1],
[46,31,29,2],
[46,31,29,3],
[46,31,29,4],
[46,31,29,5],
[46,31,29,6],
[46,31,29,7],
[46,31,29,8],
[46,31,29,10],
[46,31,29,13],
[46,31,29,14],
[46,31,29,16],
[46,31,29,17],
[46,31,29,19],
[46,31,29,20],
[46,31,29,21],
[46,31,29,24],
[46,31,29,25],
[46,31,29,27],
[46,31,29,28],
[46,32,3,0],
[46,32,3,1],
[46,32,3,2],
[46,32,4,0],
[46,32,4,1],
[46,32,4,2],
[46,32,4,3],
[46,32,5,0],
[46,32,5,1],
[46,32,5,2],
[46,32,5,4],
[46,32,6,0],
[46,32,6,1],
[46,32,6,2],
[46,32,6,4],
[46,32,7,0],
[46,32,7,1],
[46,32,7,2],
[46,32,7,3],
[46,32,7,4],
[46,32,7,5],
[46,32,7,6],
[46,32,8,0],
[46,32,8,1],
[46,32,8,2],
[46,32,8,4],
[46,32,8,7],
[46,32,10,0],
[46,32,10,1],
[46,32,10,2],
[46,32,10,3],
[46,32,10,4],
[46,32,10,5],
[46,32,10,6],
[46,32,10,7],
[46,32,10,8],
[46,32,13,0],
[46,32,13,1],
[46,32,13,2],
[46,32,13,3],
[46,32,13,4],
[46,32,13,5],
[46,32,13,6],
[46,32,13,7],
[46,32,13,8],
[46,32,13,10],
[46,32,14,0],
[46,32,14,1],
[46,32,14,2],
[46,32,14,3],
[46,32,14,4],
[46,32,14,5],
[46,32,14,6],
[46,32,14,7],
[46,32,14,8],
[46,32,14,10],
[46,32,16,0],
[46,32,16,1],
[46,32,16,2],
[46,32,16,3],
[46,32,16,4],
[46,32,16,5],
[46,32,16,6],
[46,32,16,7],
[46,32,16,8],
[46,32,16,10],
[46,32,16,13],
[46,32,16,14],
[46,32,17,0],
[46,32,17,1],
[46,32,17,2],
[46,32,17,3],
[46,32,17,4],
[46,32,17,5],
[46,32,17,6],
[46,32,17,7],
[46,32,17,8],
[46,32,17,10],
[46,32,17,13],
[46,32,17,14],
[46,32,17,16],
[46,32,19,0],
[46,32,19,1],
[46,32,19,2],
[46,32,19,3],
[46,32,19,4],
[46,32,19,5],
[46,32,19,6],
[46,32,19,7],
[46,32,19,8],
[46,32,19,10],
[46,32,19,16],
[46,32,19,17],
[46,32,20,0],
[46,32,20,1],
[46,32,20,2],
[46,32,20,3],
[46,32,20,4],
[46,32,20,5],
[46,32,20,6],
[46,32,20,7],
[46,32,20,8],
[46,32,20,13],
[46,32,20,14],
[46,32,20,16],
[46,32,20,17],
[46,32,20,19],
[46,32,21,0],
[46,32,21,1],
[46,32,21,2],
[46,32,21,4],
[46,32,21,7],
[46,32,21,10],
[46,32,21,13],
[46,32,21,14],
[46,32,21,16],
[46,32,21,17],
[46,32,21,19],
[46,32,21,20],
[46,32,22,0],
[46,32,22,1],
[46,32,22,2],
[46,32,22,3],
[46,32,22,4],
[46,32,22,5],
[46,32,22,6],
[46,32,22,7],
[46,32,22,8],
[46,32,22,13],
[46,32,22,14],
[46,32,22,16],
[46,32,22,17],
[46,32,22,19],
[46,32,22,21],
[46,32,23,0],
[46,32,23,1],
[46,32,23,2],
[46,32,23,3],
[46,32,23,4],
[46,32,23,5],
[46,32,23,6],
[46,32,23,7],
[46,32,23,8],
[46,32,23,10],
[46,32,23,13],
[46,32,23,14],
[46,32,23,16],
[46,32,23,17],
[46,32,23,19],
[46,32,23,20],
[46,32,23,21],
[46,32,23,22],
[46,32,24,0],
[46,32,24,1],
[46,32,24,2],
[46,32,24,3],
[46,32,24,4],
[46,32,24,5],
[46,32,24,6],
[46,32,24,7],
[46,32,24,8],
[46,32,24,10],
[46,32,24,13],
[46,32,24,14],
[46,32,24,17],
[46,32,24,19],
[46,32,24,20],
[46,32,24,21],
[46,32,24,22],
[46,32,24,23],
[46,32,25,0],
[46,32,25,1],
[46,32,25,2],
[46,32,25,3],
[46,32,25,4],
[46,32,25,5],
[46,32,25,6],
[46,32,25,8],
[46,32,25,10],
[46,32,25,13],
[46,32,25,14],
[46,32,25,16],
[46,32,25,17],
[46,32,25,19],
[46,32,25,20],
[46,32,25,21],
[46,32,25,22],
[46,32,25,23],
[46,32,25,24],
[46,32,27,0],
[46,32,27,1],
[46,32,27,2],
[46,32,27,3],
[46,32,27,4],
[46,32,27,5],
[46,32,27,6],
[46,32,27,7],
[46,32,27,8],
[46,32,27,13],
[46,32,27,14],
[46,32,27,16],
[46,32,27,17],
[46,32,27,19],
[46,32,27,21],
[46,32,27,25],
[46,32,28,0],
[46,32,28,1],
[46,32,28,2],
[46,32,28,3],
[46,32,28,4],
[46,32,28,5],
[46,32,28,6],
[46,32,28,8],
[46,32,28,10],
[46,32,28,13],
[46,32,28,14],
[46,32,28,16],
[46,32,28,17],
[46,32,28,19],
[46,32,28,20],
[46,32,28,21],
[46,32,28,23],
[46,32,28,24],
[46,32,28,27],
[46,32,30,0],
[46,32,30,1],
[46,32,30,2],
[46,32,30,3],
[46,32,30,4],
[46,32,30,5],
[46,32,30,6],
[46,32,30,7],
[46,32,30,8],
[46,32,30,10],
[46,32,30,13],
[46,32,30,14],
[46,32,30,16],
[46,32,30,17],
[46,32,30,19],
[46,32,30,21],
[46,32,30,22],
[46,32,30,24],
[46,32,30,25],
[46,32,30,27],
[46,32,30,28],
[46,32,31,0],
[46,32,31,1],
[46,32,31,2],
[46,32,31,3],
[46,32,31,4],
[46,32,31,5],
[46,32,31,6],
[46,32,31,7],
[46,32,31,8],
[46,32,31,10],
[46,32,31,13],
[46,32,31,14],
[46,32,31,16],
[46,32,31,17],
[46,32,31,20],
[46,32,31,21],
[46,32,31,22],
[46,32,31,24],
[46,32,31,25],
[46,32,31,27],
[46,32,31,28],
[46,33,3,0],
[46,33,3,1],
[46,33,3,2],
[46,33,4,0],
[46,33,4,1],
[46,33,4,2],
[46,33,4,3],
[46,33,5,0],
[46,33,5,1],
[46,33,5,2],
[46,33,5,4],
[46,33,6,0],
[46,33,6,1],
[46,33,6,2],
[46,33,6,4],
[46,33,7,0],
[46,33,7,1],
[46,33,7,2],
[46,33,7,3],
[46,33,7,4],
[46,33,7,5],
[46,33,7,6],
[46,33,8,0],
[46,33,8,1],
[46,33,8,2],
[46,33,8,4],
[46,33,8,7],
[46,33,10,0],
[46,33,10,1],
[46,33,10,2],
[46,33,10,3],
[46,33,10,4],
[46,33,10,5],
[46,33,10,6],
[46,33,10,7],
[46,33,10,8],
[46,33,13,0],
[46,33,13,1],
[46,33,13,2],
[46,33,13,3],
[46,33,13,4],
[46,33,13,5],
[46,33,13,6],
[46,33,13,7],
[46,33,13,8],
[46,33,13,10],
[46,33,14,0],
[46,33,14,1],
[46,33,14,2],
[46,33,14,3],
[46,33,14,4],
[46,33,14,5],
[46,33,14,6],
[46,33,14,7],
[46,33,14,8],
[46,33,14,10],
[46,33,16,0],
[46,33,16,1],
[46,33,16,2],
[46,33,16,3],
[46,33,16,4],
[46,33,16,5],
[46,33,16,6],
[46,33,16,7],
[46,33,16,8],
[46,33,16,10],
[46,33,16,13],
[46,33,16,14],
[46,33,17,0],
[46,33,17,1],
[46,33,17,2],
[46,33,17,3],
[46,33,17,4],
[46,33,17,5],
[46,33,17,6],
[46,33,17,7],
[46,33,17,8],
[46,33,17,10],
[46,33,17,13],
[46,33,17,14],
[46,33,17,16],
[46,33,19,0],
[46,33,19,1],
[46,33,19,2],
[46,33,19,3],
[46,33,19,4],
[46,33,19,5],
[46,33,19,6],
[46,33,19,7],
[46,33,19,8],
[46,33,19,10],
[46,33,19,16],
[46,33,19,17],
[46,33,20,0],
[46,33,20,1],
[46,33,20,2],
[46,33,20,3],
[46,33,20,4],
[46,33,20,5],
[46,33,20,6],
[46,33,20,7],
[46,33,20,8],
[46,33,20,13],
[46,33,20,14],
[46,33,20,16],
[46,33,20,17],
[46,33,20,19],
[46,33,21,0],
[46,33,21,1],
[46,33,21,2],
[46,33,21,4],
[46,33,21,7],
[46,33,21,10],
[46,33,21,13],
[46,33,21,14],
[46,33,21,16],
[46,33,21,17],
[46,33,21,19],
[46,33,21,20],
[46,33,22,0],
[46,33,22,1],
[46,33,22,2],
[46,33,22,3],
[46,33,22,4],
[46,33,22,5],
[46,33,22,6],
[46,33,22,7],
[46,33,22,8],
[46,33,22,13],
[46,33,22,14],
[46,33,22,16],
[46,33,22,17],
[46,33,22,19],
[46,33,22,21],
[46,33,23,0],
[46,33,23,1],
[46,33,23,2],
[46,33,23,3],
[46,33,23,4],
[46,33,23,5],
[46,33,23,6],
[46,33,23,7],
[46,33,23,8],
[46,33,23,10],
[46,33,23,13],
[46,33,23,14],
[46,33,23,16],
[46,33,23,17],
[46,33,23,19],
[46,33,23,20],
[46,33,23,21],
[46,33,23,22],
[46,33,24,0],
[46,33,24,1],
[46,33,24,2],
[46,33,24,3],
[46,33,24,4],
[46,33,24,5],
[46,33,24,6],
[46,33,24,7],
[46,33,24,8],
[46,33,24,10],
[46,33,24,13],
[46,33,24,14],
[46,33,24,17],
[46,33,24,19],
[46,33,24,20],
[46,33,24,21],
[46,33,24,22],
[46,33,24,23],
[46,33,25,0],
[46,33,25,1],
[46,33,25,2],
[46,33,25,3],
[46,33,25,4],
[46,33,25,5],
[46,33,25,6],
[46,33,25,8],
[46,33,25,10],
[46,33,25,13],
[46,33,25,14],
[46,33,25,16],
[46,33,25,17],
[46,33,25,19],
[46,33,25,20],
[46,33,25,21],
[46,33,25,22],
[46,33,25,23],
[46,33,25,24],
[46,33,27,0],
[46,33,27,1],
[46,33,27,2],
[46,33,27,3],
[46,33,27,4],
[46,33,27,5],
[46,33,27,6],
[46,33,27,7],
[46,33,27,8],
[46,33,27,13],
[46,33,27,14],
[46,33,27,16],
[46,33,27,17],
[46,33,27,19],
[46,33,27,21],
[46,33,27,23],
[46,33,27,24],
[46,33,27,25],
[46,33,28,0],
[46,33,28,1],
[46,33,28,2],
[46,33,28,3],
[46,33,28,4],
[46,33,28,5],
[46,33,28,6],
[46,33,28,8],
[46,33,28,10],
[46,33,28,13],
[46,33,28,14],
[46,33,28,16],
[46,33,28,17],
[46,33,28,19],
[46,33,28,20],
[46,33,28,22],
[46,33,28,23],
[46,33,28,24],
[46,33,28,27],
[46,33,30,0],
[46,33,30,1],
[46,33,30,2],
[46,33,30,3],
[46,33,30,4],
[46,33,30,5],
[46,33,30,6],
[46,33,30,7],
[46,33,30,8],
[46,33,30,10],
[46,33,30,13],
[46,33,30,14],
[46,33,30,16],
[46,33,30,17],
[46,33,30,20],
[46,33,30,21],
[46,33,30,22],
[46,33,30,24],
[46,33,30,25],
[46,33,30,27],
[46,33,30,28],
[46,33,31,0],
[46,33,31,1],
[46,33,31,2],
[46,33,31,3],
[46,33,31,4],
[46,33,31,5],
[46,33,31,6],
[46,33,31,7],
[46,33,31,8],
[46,33,31,10],
[46,33,31,13],
[46,33,31,14],
[46,33,31,16],
[46,33,31,17],
[46,33,31,19],
[46,33,31,20],
[46,33,31,21],
[46,33,31,22],
[46,33,31,24],
[46,33,31,25],
[46,33,31,27],
[46,33,31,28],
[46,34,3,0],
[46,34,3,1],
[46,34,3,2],
[46,34,4,0],
[46,34,4,1],
[46,34,4,2],
[46,34,4,3],
[46,34,5,0],
[46,34,5,1],
[46,34,5,2],
[46,34,5,4],
[46,34,6,0],
[46,34,6,1],
[46,34,6,2],
[46,34,6,4],
[46,34,8,0],
[46,34,8,1],
[46,34,8,2],
[46,34,8,4],
[46,34,10,0],
[46,34,10,1],
[46,34,10,2],
[46,34,10,3],
[46,34,10,4],
[46,34,10,5],
[46,34,10,6],
[46,34,10,8],
[46,34,11,0],
[46,34,11,1],
[46,34,11,2],
[46,34,11,3],
[46,34,11,4],
[46,34,11,5],
[46,34,11,6],
[46,34,11,8],
[46,34,11,10],
[46,34,12,0],
[46,34,12,1],
[46,34,12,2],
[46,34,12,3],
[46,34,12,4],
[46,34,12,5],
[46,34,12,6],
[46,34,12,8],
[46,34,12,10],
[46,34,13,0],
[46,34,13,1],
[46,34,13,2],
[46,34,13,3],
[46,34,13,4],
[46,34,13,5],
[46,34,13,6],
[46,34,13,8],
[46,34,13,10],
[46,34,13,11],
[46,34,13,12],
[46,34,14,0],
[46,34,14,1],
[46,34,14,2],
[46,34,14,3],
[46,34,14,4],
[46,34,14,5],
[46,34,14,6],
[46,34,14,8],
[46,34,14,10],
[46,34,14,11],
[46,34,14,12],
[46,34,16,0],
[46,34,16,1],
[46,34,16,2],
[46,34,16,3],
[46,34,16,4],
[46,34,16,5],
[46,34,16,6],
[46,34,16,8],
[46,34,16,10],
[46,34,16,11],
[46,34,16,12],
[46,34,16,13],
[46,34,16,14],
[46,34,17,0],
[46,34,17,1],
[46,34,17,2],
[46,34,17,3],
[46,34,17,4],
[46,34,17,5],
[46,34,17,6],
[46,34,17,8],
[46,34,17,10],
[46,34,17,11],
[46,34,17,12],
[46,34,17,13],
[46,34,17,14],
[46,34,17,16],
[46,34,19,0],
[46,34,19,1],
[46,34,19,2],
[46,34,19,3],
[46,34,19,4],
[46,34,19,5],
[46,34,19,6],
[46,34,19,8],
[46,34,19,10],
[46,34,19,11],
[46,34,19,12],
[46,34,19,16],
[46,34,19,17],
[46,34,20,0],
[46,34,20,1],
[46,34,20,2],
[46,34,20,3],
[46,34,20,4],
[46,34,20,5],
[46,34,20,6],
[46,34,20,8],
[46,34,20,11],
[46,34,20,12],
[46,34,20,13],
[46,34,20,14],
[46,34,20,16],
[46,34,20,17],
[46,34,20,19],
[46,34,21,0],
[46,34,21,1],
[46,34,21,2],
[46,34,21,4],
[46,34,21,10],
[46,34,21,11],
[46,34,21,12],
[46,34,21,13],
[46,34,21,14],
[46,34,21,16],
[46,34,21,17],
[46,34,21,19],
[46,34,21,20],
[46,34,22,0],
[46,34,22,1],
[46,34,22,2],
[46,34,22,3],
[46,34,22,4],
[46,34,22,5],
[46,34,22,6],
[46,34,22,8],
[46,34,22,11],
[46,34,22,12],
[46,34,22,13],
[46,34,22,14],
[46,34,22,16],
[46,34,22,17],
[46,34,22,19],
[46,34,22,21],
[46,34,23,0],
[46,34,23,1],
[46,34,23,2],
[46,34,23,3],
[46,34,23,4],
[46,34,23,5],
[46,34,23,6],
[46,34,23,8],
[46,34,23,10],
[46,34,23,11],
[46,34,23,12],
[46,34,23,13],
[46,34,23,14],
[46,34,23,16],
[46,34,23,17],
[46,34,23,19],
[46,34,23,20],
[46,34,23,21],
[46,34,23,22],
[46,34,24,0],
[46,34,24,1],
[46,34,24,2],
[46,34,24,3],
[46,34,24,4],
[46,34,24,5],
[46,34,24,6],
[46,34,24,8],
[46,34,24,10],
[46,34,24,11],
[46,34,24,12],
[46,34,24,13],
[46,34,24,14],
[46,34,24,17],
[46,34,24,19],
[46,34,24,20],
[46,34,24,21],
[46,34,24,22],
[46,34,24,23],
[46,34,27,0],
[46,34,27,1],
[46,34,27,2],
[46,34,27,3],
[46,34,27,4],
[46,34,27,5],
[46,34,27,6],
[46,34,27,8],
[46,34,27,11],
[46,34,27,12],
[46,34,27,13],
[46,34,27,14],
[46,34,27,16],
[46,34,27,17],
[46,34,27,19],
[46,34,27,23],
[46,34,27,24],
[46,34,29,0],
[46,34,29,1],
[46,34,29,2],
[46,34,29,3],
[46,34,29,4],
[46,34,29,5],
[46,34,29,6],
[46,34,29,8],
[46,34,29,10],
[46,34,29,13],
[46,34,29,14],
[46,34,29,16],
[46,34,29,17],
[46,34,29,20],
[46,34,29,21],
[46,34,29,22],
[46,34,29,23],
[46,34,29,24],
[46,34,29,27],
[46,34,30,0],
[46,34,30,1],
[46,34,30,2],
[46,34,30,3],
[46,34,30,4],
[46,34,30,5],
[46,34,30,6],
[46,34,30,8],
[46,34,30,10],
[46,34,30,11],
[46,34,30,12],
[46,34,30,13],
[46,34,30,14],
[46,34,30,16],
[46,34,30,17],
[46,34,30,19],
[46,34,30,20],
[46,34,30,21],
[46,34,30,22],
[46,34,30,24],
[46,34,30,27],
[46,34,30,29],
[46,34,31,0],
[46,34,31,1],
[46,34,31,2],
[46,34,31,3],
[46,34,31,4],
[46,34,31,5],
[46,34,31,6],
[46,34,31,8],
[46,34,31,10],
[46,34,31,11],
[46,34,31,12],
[46,34,31,13],
[46,34,31,14],
[46,34,31,19],
[46,34,31,20],
[46,34,31,21],
[46,34,31,22],
[46,34,31,24],
[46,34,31,27],
[46,34,31,29],
[46,34,32,0],
[46,34,32,1],
[46,34,32,2],
[46,34,32,3],
[46,34,32,4],
[46,34,32,5],
[46,34,32,6],
[46,34,32,8],
[46,34,32,10],
[46,34,32,13],
[46,34,32,14],
[46,34,32,16],
[46,34,32,17],
[46,34,32,19],
[46,34,32,20],
[46,34,32,21],
[46,34,32,22],
[46,34,32,23],
[46,34,32,24],
[46,34,32,27],
[46,34,33,0],
[46,34,33,1],
[46,34,33,2],
[46,34,33,3],
[46,34,33,4],
[46,34,33,5],
[46,34,33,6],
[46,34,33,8],
[46,34,33,10],
[46,34,33,13],
[46,34,33,16],
[46,34,33,17],
[46,34,33,19],
[46,34,33,20],
[46,34,33,21],
[46,34,33,22],
[46,34,33,23],
[46,34,33,24],
[46,34,33,27],
[46,36,3,0],
[46,36,3,1],
[46,36,3,2],
[46,36,4,0],
[46,36,4,1],
[46,36,4,2],
[46,36,4,3],
[46,36,5,0],
[46,36,5,1],
[46,36,5,2],
[46,36,5,4],
[46,36,6,0],
[46,36,6,1],
[46,36,6,2],
[46,36,6,4],
[46,36,7,0],
[46,36,7,1],
[46,36,7,2],
[46,36,7,3],
[46,36,7,4],
[46,36,7,5],
[46,36,7,6],
[46,36,8,0],
[46,36,8,1],
[46,36,8,2],
[46,36,8,4],
[46,36,8,7],
[46,36,10,0],
[46,36,10,1],
[46,36,10,2],
[46,36,10,3],
[46,36,10,4],
[46,36,10,5],
[46,36,10,6],
[46,36,10,7],
[46,36,10,8],
[46,36,11,0],
[46,36,11,1],
[46,36,11,2],
[46,36,11,3],
[46,36,11,4],
[46,36,11,5],
[46,36,11,6],
[46,36,11,7],
[46,36,11,8],
[46,36,11,10],
[46,36,12,0],
[46,36,12,1],
[46,36,12,2],
[46,36,12,3],
[46,36,12,4],
[46,36,12,5],
[46,36,12,6],
[46,36,12,7],
[46,36,12,8],
[46,36,12,10],
[46,36,16,0],
[46,36,16,1],
[46,36,16,2],
[46,36,16,3],
[46,36,16,4],
[46,36,16,5],
[46,36,16,6],
[46,36,16,7],
[46,36,16,8],
[46,36,16,10],
[46,36,16,11],
[46,36,16,12],
[46,36,17,0],
[46,36,17,1],
[46,36,17,2],
[46,36,17,3],
[46,36,17,4],
[46,36,17,5],
[46,36,17,6],
[46,36,17,7],
[46,36,17,8],
[46,36,17,10],
[46,36,17,11],
[46,36,17,12],
[46,36,17,16],
[46,36,20,0],
[46,36,20,1],
[46,36,20,2],
[46,36,20,3],
[46,36,20,4],
[46,36,20,5],
[46,36,20,6],
[46,36,20,7],
[46,36,20,8],
[46,36,20,11],
[46,36,20,12],
[46,36,20,16],
[46,36,20,17],
[46,36,21,0],
[46,36,21,1],
[46,36,21,2],
[46,36,21,4],
[46,36,21,7],
[46,36,21,10],
[46,36,21,11],
[46,36,21,12],
[46,36,21,16],
[46,36,21,17],
[46,36,21,20],
[46,36,22,0],
[46,36,22,1],
[46,36,22,2],
[46,36,22,3],
[46,36,22,4],
[46,36,22,5],
[46,36,22,6],
[46,36,22,7],
[46,36,22,8],
[46,36,22,11],
[46,36,22,12],
[46,36,22,16],
[46,36,22,17],
[46,36,22,21],
[46,36,23,0],
[46,36,23,1],
[46,36,23,2],
[46,36,23,3],
[46,36,23,4],
[46,36,23,5],
[46,36,23,6],
[46,36,23,7],
[46,36,23,8],
[46,36,23,10],
[46,36,23,11],
[46,36,23,12],
[46,36,23,16],
[46,36,23,17],
[46,36,23,20],
[46,36,23,21],
[46,36,23,22],
[46,36,24,0],
[46,36,24,1],
[46,36,24,2],
[46,36,24,3],
[46,36,24,4],
[46,36,24,5],
[46,36,24,6],
[46,36,24,7],
[46,36,24,8],
[46,36,24,10],
[46,36,24,11],
[46,36,24,12],
[46,36,24,17],
[46,36,24,20],
[46,36,24,21],
[46,36,24,22],
[46,36,25,0],
[46,36,25,1],
[46,36,25,2],
[46,36,25,3],
[46,36,25,4],
[46,36,25,5],
[46,36,25,6],
[46,36,25,8],
[46,36,25,10],
[46,36,25,11],
[46,36,25,12],
[46,36,25,16],
[46,36,25,17],
[46,36,25,20],
[46,36,25,22],
[46,36,25,23],
[46,36,25,24],
[46,36,27,0],
[46,36,27,1],
[46,36,27,2],
[46,36,27,3],
[46,36,27,4],
[46,36,27,5],
[46,36,27,6],
[46,36,27,7],
[46,36,27,8],
[46,36,27,11],
[46,36,27,12],
[46,36,27,16],
[46,36,27,17],
[46,36,27,21],
[46,36,27,23],
[46,36,27,24],
[46,36,27,25],
[46,36,28,0],
[46,36,28,1],
[46,36,28,2],
[46,36,28,3],
[46,36,28,4],
[46,36,28,5],
[46,36,28,6],
[46,36,28,8],
[46,36,28,10],
[46,36,28,11],
[46,36,28,12],
[46,36,28,16],
[46,36,28,17],
[46,36,28,20],
[46,36,28,21],
[46,36,28,22],
[46,36,28,23],
[46,36,28,24],
[46,36,28,27],
[46,36,29,0],
[46,36,29,1],
[46,36,29,2],
[46,36,29,3],
[46,36,29,4],
[46,36,29,5],
[46,36,29,6],
[46,36,29,7],
[46,36,29,8],
[46,36,29,10],
[46,36,29,20],
[46,36,29,21],
[46,36,29,22],
[46,36,29,23],
[46,36,29,24],
[46,36,29,25],
[46,36,29,27],
[46,36,29,28],
[46,36,30,0],
[46,36,30,1],
[46,36,30,2],
[46,36,30,3],
[46,36,30,4],
[46,36,30,5],
[46,36,30,6],
[46,36,30,7],
[46,36,30,8],
[46,36,30,10],
[46,36,30,11],
[46,36,30,12],
[46,36,30,16],
[46,36,30,17],
[46,36,30,20],
[46,36,30,21],
[46,36,30,22],
[46,36,30,24],
[46,36,30,25],
[46,36,30,27],
[46,36,30,28],
[46,36,31,0],
[46,36,31,1],
[46,36,31,2],
[46,36,31,3],
[46,36,31,4],
[46,36,31,5],
[46,36,31,6],
[46,36,31,7],
[46,36,31,8],
[46,36,31,10],
[46,36,31,11],
[46,36,31,12],
[46,36,31,16],
[46,36,31,17],
[46,36,31,20],
[46,36,31,21],
[46,36,31,22],
[46,36,31,24],
[46,36,31,25],
[46,36,31,27],
[46,36,32,0],
[46,36,32,1],
[46,36,32,2],
[46,36,32,3],
[46,36,32,4],
[46,36,32,5],
[46,36,32,6],
[46,36,32,7],
[46,36,32,8],
[46,36,32,10],
[46,36,32,16],
[46,36,32,17],
[46,36,32,20],
[46,36,32,21],
[46,36,32,22],
[46,36,32,23],
[46,36,32,24],
[46,36,32,25],
[46,36,33,0],
[46,36,33,1],
[46,36,33,2],
[46,36,33,3],
[46,36,33,4],
[46,36,33,5],
[46,36,33,6],
[46,36,33,7],
[46,36,33,8],
[46,36,33,16],
[46,36,33,17],
[46,36,33,20],
[46,36,33,21],
[46,36,33,22],
[46,36,33,23],
[46,36,33,24],
[46,36,33,25],
[46,36,34,0],
[46,36,34,1],
[46,36,34,2],
[46,36,34,3],
[46,36,34,4],
[46,36,34,5],
[46,36,34,6],
[46,36,34,10],
[46,36,34,11],
[46,36,34,12],
[46,36,34,16],
[46,36,34,17],
[46,36,34,20],
[46,36,34,21],
[46,36,34,22],
[46,36,34,23],
[46,36,34,24],
[46,37,3,0],
[46,37,3,1],
[46,37,3,2],
[46,37,5,0],
[46,37,5,1],
[46,37,5,2],
[46,37,6,0],
[46,37,6,1],
[46,37,6,2],
[46,37,7,0],
[46,37,7,1],
[46,37,7,2],
[46,37,7,3],
[46,37,7,5],
[46,37,7,6],
[46,37,8,0],
[46,37,8,1],
[46,37,8,2],
[46,37,8,7],
[46,37,10,0],
[46,37,10,1],
[46,37,10,2],
[46,37,10,3],
[46,37,10,5],
[46,37,10,6],
[46,37,10,7],
[46,37,10,8],
[46,37,11,0],
[46,37,11,1],
[46,37,11,2],
[46,37,11,3],
[46,37,11,5],
[46,37,11,6],
[46,37,11,7],
[46,37,11,8],
[46,37,11,10],
[46,37,12,0],
[46,37,12,1],
[46,37,12,2],
[46,37,12,3],
[46,37,12,5],
[46,37,12,6],
[46,37,12,7],
[46,37,12,8],
[46,37,12,10],
[46,37,13,0],
[46,37,13,1],
[46,37,13,2],
[46,37,13,3],
[46,37,13,5],
[46,37,13,6],
[46,37,13,7],
[46,37,13,8],
[46,37,13,10],
[46,37,13,11],
[46,37,13,12],
[46,37,14,0],
[46,37,14,1],
[46,37,14,2],
[46,37,14,3],
[46,37,14,5],
[46,37,14,6],
[46,37,14,7],
[46,37,14,8],
[46,37,14,10],
[46,37,14,11],
[46,37,14,12],
[46,37,16,0],
[46,37,16,1],
[46,37,16,2],
[46,37,16,3],
[46,37,16,5],
[46,37,16,6],
[46,37,16,7],
[46,37,16,8],
[46,37,16,10],
[46,37,16,11],
[46,37,16,12],
[46,37,16,13],
[46,37,16,14],
[46,37,17,0],
[46,37,17,1],
[46,37,17,2],
[46,37,17,3],
[46,37,17,5],
[46,37,17,6],
[46,37,17,7],
[46,37,17,8],
[46,37,17,10],
[46,37,17,11],
[46,37,17,12],
[46,37,17,13],
[46,37,17,14],
[46,37,17,16],
[46,37,19,0],
[46,37,19,1],
[46,37,19,2],
[46,37,19,3],
[46,37,19,5],
[46,37,19,6],
[46,37,19,7],
[46,37,19,8],
[46,37,19,10],
[46,37,19,11],
[46,37,19,12],
[46,37,19,16],
[46,37,19,17],
[46,37,20,0],
[46,37,20,1],
[46,37,20,2],
[46,37,20,3],
[46,37,20,5],
[46,37,20,6],
[46,37,20,7],
[46,37,20,8],
[46,37,20,11],
[46,37,20,12],
[46,37,20,13],
[46,37,20,14],
[46,37,20,16],
[46,37,20,17],
[46,37,20,19],
[46,37,21,0],
[46,37,21,1],
[46,37,21,2],
[46,37,21,7],
[46,37,21,10],
[46,37,21,11],
[46,37,21,12],
[46,37,21,13],
[46,37,21,14],
[46,37,21,16],
[46,37,21,17],
[46,37,21,19],
[46,37,21,20],
[46,37,22,0],
[46,37,22,1],
[46,37,22,2],
[46,37,22,3],
[46,37,22,5],
[46,37,22,6],
[46,37,22,7],
[46,37,22,8],
[46,37,22,11],
[46,37,22,12],
[46,37,22,13],
[46,37,22,14],
[46,37,22,16],
[46,37,22,17],
[46,37,22,19],
[46,37,22,21],
[46,37,23,0],
[46,37,23,1],
[46,37,23,2],
[46,37,23,3],
[46,37,23,5],
[46,37,23,6],
[46,37,23,7],
[46,37,23,8],
[46,37,23,10],
[46,37,23,11],
[46,37,23,12],
[46,37,23,13],
[46,37,23,14],
[46,37,23,16],
[46,37,23,17],
[46,37,23,19],
[46,37,23,20],
[46,37,23,21],
[46,37,24,0],
[46,37,24,1],
[46,37,24,2],
[46,37,24,3],
[46,37,24,5],
[46,37,24,6],
[46,37,24,7],
[46,37,24,8],
[46,37,24,10],
[46,37,24,11],
[46,37,24,12],
[46,37,24,13],
[46,37,24,14],
[46,37,24,17],
[46,37,24,19],
[46,37,24,20],
[46,37,24,21],
[46,37,24,23],
[46,37,25,0],
[46,37,25,1],
[46,37,25,2],
[46,37,25,3],
[46,37,25,5],
[46,37,25,6],
[46,37,25,8],
[46,37,25,10],
[46,37,25,11],
[46,37,25,12],
[46,37,25,13],
[46,37,25,14],
[46,37,25,16],
[46,37,25,17],
[46,37,25,19],
[46,37,25,21],
[46,37,25,22],
[46,37,25,23],
[46,37,25,24],
[46,37,27,0],
[46,37,27,1],
[46,37,27,2],
[46,37,27,3],
[46,37,27,5],
[46,37,27,6],
[46,37,27,7],
[46,37,27,8],
[46,37,27,11],
[46,37,27,12],
[46,37,27,13],
[46,37,27,14],
[46,37,27,16],
[46,37,27,17],
[46,37,27,19],
[46,37,27,21],
[46,37,27,23],
[46,37,27,24],
[46,37,27,25],
[46,37,28,0],
[46,37,28,1],
[46,37,28,2],
[46,37,28,3],
[46,37,28,5],
[46,37,28,6],
[46,37,28,8],
[46,37,28,10],
[46,37,28,11],
[46,37,28,12],
[46,37,28,13],
[46,37,28,14],
[46,37,28,19],
[46,37,28,20],
[46,37,28,21],
[46,37,28,22],
[46,37,28,23],
[46,37,28,24],
[46,37,28,27],
[46,37,29,0],
[46,37,29,1],
[46,37,29,2],
[46,37,29,3],
[46,37,29,5],
[46,37,29,6],
[46,37,29,7],
[46,37,29,8],
[46,37,29,10],
[46,37,29,13],
[46,37,29,14],
[46,37,29,16],
[46,37,29,17],
[46,37,29,19],
[46,37,29,20],
[46,37,29,21],
[46,37,29,22],
[46,37,29,23],
[46,37,29,24],
[46,37,29,25],
[46,37,29,27],
[46,37,29,28],
[46,37,30,0],
[46,37,30,1],
[46,37,30,2],
[46,37,30,3],
[46,37,30,5],
[46,37,30,6],
[46,37,30,7],
[46,37,30,8],
[46,37,30,10],
[46,37,30,11],
[46,37,30,12],
[46,37,30,13],
[46,37,30,16],
[46,37,30,17],
[46,37,30,19],
[46,37,30,20],
[46,37,30,21],
[46,37,30,22],
[46,37,30,24],
[46,37,30,25],
[46,37,30,27],
[46,37,31,0],
[46,37,31,1],
[46,37,31,2],
[46,37,31,3],
[46,37,31,5],
[46,37,31,6],
[46,37,31,7],
[46,37,31,8],
[46,37,31,10],
[46,37,31,11],
[46,37,31,14],
[46,37,31,16],
[46,37,31,17],
[46,37,31,19],
[46,37,31,20],
[46,37,31,21],
[46,37,31,22],
[46,37,31,24],
[46,37,31,25],
[46,37,32,0],
[46,37,32,1],
[46,37,32,2],
[46,37,32,3],
[46,37,32,5],
[46,37,32,6],
[46,37,32,7],
[46,37,32,8],
[46,37,32,13],
[46,37,32,14],
[46,37,32,16],
[46,37,32,17],
[46,37,32,19],
[46,37,32,20],
[46,37,32,21],
[46,37,32,22],
[46,37,32,23],
[46,37,32,24],
[46,37,32,25],
[46,37,33,0],
[46,37,33,1],
[46,37,33,2],
[46,37,33,3],
[46,37,33,5],
[46,37,33,6],
[46,37,33,7],
[46,37,33,10],
[46,37,33,13],
[46,37,33,14],
[46,37,33,16],
[46,37,33,17],
[46,37,33,19],
[46,37,33,20],
[46,37,33,21],
[46,37,33,22],
[46,37,33,23],
[46,37,33,24],
[46,37,34,0],
[46,37,34,1],
[46,37,34,2],
[46,37,34,3],
[46,37,34,5],
[46,37,34,8],
[46,37,34,10],
[46,37,34,11],
[46,37,34,12],
[46,37,34,13],
[46,37,34,14],
[46,37,34,16],
[46,37,34,17],
[46,37,34,19],
[46,37,34,20],
[46,37,34,21],
[46,37,34,22],
[46,37,34,23],
[46,37,34,24],
[46,37,36,0],
[46,37,36,1],
[46,37,36,2],
[46,37,36,5],
[46,37,36,6],
[46,37,36,7],
[46,37,36,8],
[46,37,36,10],
[46,37,36,11],
[46,37,36,12],
[46,37,36,16],
[46,37,36,17],
[46,37,36,20],
[46,37,36,21],
[46,38,3,0],
[46,38,3,1],
[46,38,3,2],
[46,38,4,0],
[46,38,4,1],
[46,38,4,2],
[46,38,4,3],
[46,38,5,0],
[46,38,5,1],
[46,38,5,2],
[46,38,5,4],
[46,38,6,0],
[46,38,6,1],
[46,38,6,2],
[46,38,6,4],
[46,38,8,0],
[46,38,8,1],
[46,38,8,2],
[46,38,8,4],
[46,38,10,0],
[46,38,10,1],
[46,38,10,2],
[46,38,10,3],
[46,38,10,4],
[46,38,10,5],
[46,38,10,6],
[46,38,10,8],
[46,38,11,0],
[46,38,11,1],
[46,38,11,2],
[46,38,11,3],
[46,38,11,4],
[46,38,11,5],
[46,38,11,6],
[46,38,11,8],
[46,38,11,10],
[46,38,12,0],
[46,38,12,1],
[46,38,12,2],
[46,38,12,3],
[46,38,12,4],
[46,38,12,5],
[46,38,12,6],
[46,38,12,8],
[46,38,12,10],
[46,38,13,0],
[46,38,13,1],
[46,38,13,2],
[46,38,13,3],
[46,38,13,4],
[46,38,13,5],
[46,38,13,6],
[46,38,13,8],
[46,38,13,10],
[46,38,13,11],
[46,38,13,12],
[46,38,14,0],
[46,38,14,1],
[46,38,14,2],
[46,38,14,3],
[46,38,14,4],
[46,38,14,5],
[46,38,14,6],
[46,38,14,8],
[46,38,14,10],
[46,38,14,11],
[46,38,14,12],
[46,38,16,0],
[46,38,16,1],
[46,38,16,2],
[46,38,16,3],
[46,38,16,4],
[46,38,16,5],
[46,38,16,6],
[46,38,16,8],
[46,38,16,10],
[46,38,16,11],
[46,38,16,12],
[46,38,16,13],
[46,38,16,14],
[46,38,17,0],
[46,38,17,1],
[46,38,17,2],
[46,38,17,3],
[46,38,17,4],
[46,38,17,5],
[46,38,17,6],
[46,38,17,8],
[46,38,17,10],
[46,38,17,11],
[46,38,17,12],
[46,38,17,13],
[46,38,17,14],
[46,38,17,16],
[46,38,19,0],
[46,38,19,1],
[46,38,19,2],
[46,38,19,3],
[46,38,19,4],
[46,38,19,5],
[46,38,19,6],
[46,38,19,8],
[46,38,19,10],
[46,38,19,11],
[46,38,19,12],
[46,38,19,16],
[46,38,19,17],
[46,38,20,0],
[46,38,20,1],
[46,38,20,2],
[46,38,20,3],
[46,38,20,4],
[46,38,20,5],
[46,38,20,6],
[46,38,20,8],
[46,38,20,11],
[46,38,20,12],
[46,38,20,13],
[46,38,20,14],
[46,38,20,16],
[46,38,20,17],
[46,38,20,19],
[46,38,21,0],
[46,38,21,1],
[46,38,21,2],
[46,38,21,4],
[46,38,21,10],
[46,38,21,11],
[46,38,21,12],
[46,38,21,13],
[46,38,21,14],
[46,38,21,16],
[46,38,21,17],
[46,38,21,19],
[46,38,21,20],
[46,38,22,0],
[46,38,22,1],
[46,38,22,2],
[46,38,22,3],
[46,38,22,4],
[46,38,22,5],
[46,38,22,6],
[46,38,22,8],
[46,38,22,11],
[46,38,22,12],
[46,38,22,13],
[46,38,22,14],
[46,38,22,16],
[46,38,22,17],
[46,38,22,19],
[46,38,22,21],
[46,38,23,0],
[46,38,23,1],
[46,38,23,2],
[46,38,23,3],
[46,38,23,4],
[46,38,23,5],
[46,38,23,6],
[46,38,23,8],
[46,38,23,10],
[46,38,23,11],
[46,38,23,12],
[46,38,23,13],
[46,38,23,14],
[46,38,23,16],
[46,38,23,17],
[46,38,23,19],
[46,38,23,20],
[46,38,23,22],
[46,38,24,0],
[46,38,24,1],
[46,38,24,2],
[46,38,24,3],
[46,38,24,4],
[46,38,24,5],
[46,38,24,6],
[46,38,24,8],
[46,38,24,10],
[46,38,24,11],
[46,38,24,12],
[46,38,24,13],
[46,38,24,14],
[46,38,24,17],
[46,38,24,19],
[46,38,24,20],
[46,38,24,22],
[46,38,24,23],
[46,38,27,0],
[46,38,27,1],
[46,38,27,2],
[46,38,27,3],
[46,38,27,4],
[46,38,27,5],
[46,38,27,6],
[46,38,27,8],
[46,38,27,11],
[46,38,27,12],
[46,38,27,13],
[46,38,27,14],
[46,38,27,19],
[46,38,27,21],
[46,38,27,23],
[46,38,27,24],
[46,38,29,0],
[46,38,29,1],
[46,38,29,2],
[46,38,29,3],
[46,38,29,4],
[46,38,29,5],
[46,38,29,6],
[46,38,29,8],
[46,38,29,10],
[46,38,29,13],
[46,38,29,16],
[46,38,29,17],
[46,38,29,19],
[46,38,29,20],
[46,38,29,21],
[46,38,29,22],
[46,38,29,23],
[46,38,29,24],
[46,38,29,27],
[46,38,30,0],
[46,38,30,1],
[46,38,30,2],
[46,38,30,3],
[46,38,30,4],
[46,38,30,5],
[46,38,30,6],
[46,38,30,8],
[46,38,30,10],
[46,38,30,11],
[46,38,30,14],
[46,38,30,16],
[46,38,30,17],
[46,38,30,19],
[46,38,30,20],
[46,38,30,21],
[46,38,30,22],
[46,38,30,24],
[46,38,31,0],
[46,38,31,1],
[46,38,31,2],
[46,38,31,3],
[46,38,31,4],
[46,38,31,5],
[46,38,31,6],
[46,38,31,8],
[46,38,31,12],
[46,38,31,13],
[46,38,31,14],
[46,38,31,16],
[46,38,31,17],
[46,38,31,19],
[46,38,31,20],
[46,38,31,21],
[46,38,31,22],
[46,38,31,24],
[46,38,32,0],
[46,38,32,1],
[46,38,32,2],
[46,38,32,3],
[46,38,32,4],
[46,38,32,5],
[46,38,32,6],
[46,38,32,10],
[46,38,32,13],
[46,38,32,14],
[46,38,32,16],
[46,38,32,17],
[46,38,32,19],
[46,38,32,20],
[46,38,32,21],
[46,38,32,22],
[46,38,32,23],
[46,38,32,24],
[46,38,33,0],
[46,38,33,1],
[46,38,33,2],
[46,38,33,3],
[46,38,33,4],
[46,38,33,5],
[46,38,33,8],
[46,38,33,10],
[46,38,33,13],
[46,38,33,14],
[46,38,33,16],
[46,38,33,17],
[46,38,33,19],
[46,38,33,20],
[46,38,33,21],
[46,38,33,22],
[46,38,33,23],
[46,38,33,24],
[46,38,36,3],
[46,38,36,4],
[46,38,36,5],
[46,38,36,6],
[46,38,36,8],
[46,38,36,10],
[46,38,36,11],
[46,38,36,12],
[46,38,36,16],
[46,38,36,17],
[46,38,36,20],
[46,38,37,0],
[46,38,37,1],
[46,38,37,2],
[46,38,37,3],
[46,38,37,5],
[46,38,37,6],
[46,38,37,8],
[46,38,37,10],
[46,38,37,11],
[46,38,37,12],
[46,38,37,13],
[46,38,37,14],
[46,38,37,16],
[46,38,37,17],
[46,38,37,19],
[46,39,4,0],
[46,39,4,1],
[46,39,4,2],
[46,39,7,0],
[46,39,7,1],
[46,39,7,2],
[46,39,7,4],
[46,39,10,0],
[46,39,10,1],
[46,39,10,2],
[46,39,10,4],
[46,39,10,7],
[46,39,11,0],
[46,39,11,1],
[46,39,11,2],
[46,39,11,4],
[46,39,11,7],
[46,39,11,10],
[46,39,12,0],
[46,39,12,1],
[46,39,12,2],
[46,39,12,4],
[46,39,12,7],
[46,39,12,10],
[46,39,13,0],
[46,39,13,1],
[46,39,13,2],
[46,39,13,4],
[46,39,13,7],
[46,39,13,10],
[46,39,13,11],
[46,39,13,12],
[46,39,14,0],
[46,39,14,1],
[46,39,14,2],
[46,39,14,4],
[46,39,14,7],
[46,39,14,10],
[46,39,14,11],
[46,39,14,12],
[46,39,16,0],
[46,39,16,1],
[46,39,16,2],
[46,39,16,4],
[46,39,16,7],
[46,39,16,10],
[46,39,16,11],
[46,39,16,12],
[46,39,16,13],
[46,39,16,14],
[46,39,17,0],
[46,39,17,1],
[46,39,17,2],
[46,39,17,4],
[46,39,17,7],
[46,39,17,10],
[46,39,17,11],
[46,39,17,12],
[46,39,17,13],
[46,39,17,14],
[46,39,17,16],
[46,39,19,0],
[46,39,19,1],
[46,39,19,2],
[46,39,19,4],
[46,39,19,7],
[46,39,19,10],
[46,39,19,11],
[46,39,19,12],
[46,39,19,16],
[46,39,19,17],
[46,39,20,0],
[46,39,20,1],
[46,39,20,2],
[46,39,20,4],
[46,39,20,7],
[46,39,20,11],
[46,39,20,12],
[46,39,20,13],
[46,39,20,14],
[46,39,20,16],
[46,39,20,17],
[46,39,20,19],
[46,39,22,0],
[46,39,22,1],
[46,39,22,2],
[46,39,22,4],
[46,39,22,7],
[46,39,22,11],
[46,39,22,12],
[46,39,22,13],
[46,39,22,14],
[46,39,22,16],
[46,39,22,17],
[46,39,22,19],
[46,39,23,0],
[46,39,23,1],
[46,39,23,2],
[46,39,23,4],
[46,39,23,7],
[46,39,23,10],
[46,39,23,11],
[46,39,23,12],
[46,39,23,13],
[46,39,23,14],
[46,39,23,16],
[46,39,23,17],
[46,39,23,19],
[46,39,23,22],
[46,39,24,0],
[46,39,24,1],
[46,39,24,2],
[46,39,24,4],
[46,39,24,7],
[46,39,24,10],
[46,39,24,11],
[46,39,24,12],
[46,39,24,13],
[46,39,24,14],
[46,39,24,17],
[46,39,24,19],
[46,39,24,22],
[46,39,24,23],
[46,39,25,0],
[46,39,25,1],
[46,39,25,2],
[46,39,25,4],
[46,39,25,10],
[46,39,25,11],
[46,39,25,12],
[46,39,25,13],
[46,39,25,14],
[46,39,25,16],
[46,39,25,17],
[46,39,25,19],
[46,39,25,20],
[46,39,25,22],
[46,39,25,23],
[46,39,25,24],
[46,39,27,0],
[46,39,27,1],
[46,39,27,2],
[46,39,27,4],
[46,39,27,7],
[46,39,27,11],
[46,39,27,12],
[46,39,27,13],
[46,39,27,14],
[46,39,27,16],
[46,39,27,17],
[46,39,27,19],
[46,39,27,23],
[46,39,27,24],
[46,39,27,25],
[46,39,28,0],
[46,39,28,1],
[46,39,28,2],
[46,39,28,4],
[46,39,28,10],
[46,39,28,11],
[46,39,28,12],
[46,39,28,13],
[46,39,28,16],
[46,39,28,17],
[46,39,28,19],
[46,39,28,20],
[46,39,28,22],
[46,39,28,23],
[46,39,28,24],
[46,39,28,27],
[46,39,29,0],
[46,39,29,1],
[46,39,29,2],
[46,39,29,4],
[46,39,29,7],
[46,39,29,10],
[46,39,29,14],
[46,39,29,16],
[46,39,29,17],
[46,39,29,19],
[46,39,29,20],
[46,39,29,22],
[46,39,29,23],
[46,39,29,24],
[46,39,29,25],
[46,39,30,0],
[46,39,30,1],
[46,39,30,2],
[46,39,30,4],
[46,39,30,7],
[46,39,30,12],
[46,39,30,13],
[46,39,30,14],
[46,39,30,16],
[46,39,30,17],
[46,39,30,19],
[46,39,30,20],
[46,39,30,22],
[46,39,30,24],
[46,39,30,25],
[46,39,31,0],
[46,39,31,1],
[46,39,31,2],
[46,39,31,4],
[46,39,31,7],
[46,39,31,10],
[46,39,31,11],
[46,39,31,12],
[46,39,31,13],
[46,39,31,14],
[46,39,31,16],
[46,39,31,17],
[46,39,31,19],
[46,39,31,20],
[46,39,31,22],
[46,39,31,24],
[46,39,32,0],
[46,39,32,1],
[46,39,32,2],
[46,39,32,4],
[46,39,32,10],
[46,39,32,13],
[46,39,32,14],
[46,39,32,16],
[46,39,32,17],
[46,39,32,19],
[46,39,32,20],
[46,39,32,22],
[46,39,32,23],
[46,39,32,24],
[46,39,33,0],
[46,39,33,1],
[46,39,33,2],
[46,39,33,4],
[46,39,33,7],
[46,39,33,10],
[46,39,33,13],
[46,39,33,14],
[46,39,33,16],
[46,39,33,17],
[46,39,33,19],
[46,39,33,20],
[46,39,33,22],
[46,39,34,0],
[46,39,34,1],
[46,39,34,2],
[46,39,34,10],
[46,39,34,11],
[46,39,34,12],
[46,39,34,13],
[46,39,34,14],
[46,39,34,16],
[46,39,34,17],
[46,39,34,19],
[46,39,34,20],
[46,39,36,0],
[46,39,36,1],
[46,39,36,2],
[46,39,36,4],
[46,39,36,7],
[46,39,36,10],
[46,39,36,11],
[46,39,36,12],
[46,39,36,16],
[46,39,36,17],
[46,39,37,0],
[46,39,37,1],
[46,39,37,2],
[46,39,37,7],
[46,39,37,10],
[46,39,37,11],
[46,39,37,12],
[46,39,37,13],
[46,39,37,14],
[46,39,37,16],
[46,39,37,17],
[46,39,38,0],
[46,39,38,1],
[46,39,38,2],
[46,39,38,4],
[46,39,38,10],
[46,39,38,11],
[46,39,38,12],
[46,39,38,13],
[46,39,38,14],
[46,39,38,16],
[46,39,38,17],
[46,42,3,0],
[46,42,3,1],
[46,42,3,2],
[46,42,4,0],
[46,42,4,1],
[46,42,4,2],
[46,42,4,3],
[46,42,5,0],
[46,42,5,1],
[46,42,5,2],
[46,42,5,4],
[46,42,6,0],
[46,42,6,1],
[46,42,6,2],
[46,42,6,4],
[46,42,7,0],
[46,42,7,1],
[46,42,7,2],
[46,42,7,3],
[46,42,7,4],
[46,42,7,5],
[46,42,7,6],
[46,42,8,0],
[46,42,8,1],
[46,42,8,2],
[46,42,8,4],
[46,42,8,7],
[46,42,11,0],
[46,42,11,1],
[46,42,11,2],
[46,42,11,3],
[46,42,11,4],
[46,42,11,5],
[46,42,11,6],
[46,42,11,7],
[46,42,11,8],
[46,42,12,0],
[46,42,12,1],
[46,42,12,2],
[46,42,12,3],
[46,42,12,4],
[46,42,12,5],
[46,42,12,6],
[46,42,12,7],
[46,42,12,8],
[46,42,13,0],
[46,42,13,1],
[46,42,13,2],
[46,42,13,3],
[46,42,13,4],
[46,42,13,5],
[46,42,13,6],
[46,42,13,7],
[46,42,13,8],
[46,42,13,11],
[46,42,13,12],
[46,42,14,0],
[46,42,14,1],
[46,42,14,2],
[46,42,14,3],
[46,42,14,4],
[46,42,14,5],
[46,42,14,6],
[46,42,14,7],
[46,42,14,8],
[46,42,14,11],
[46,42,14,12],
[46,42,16,0],
[46,42,16,1],
[46,42,16,2],
[46,42,16,3],
[46,42,16,4],
[46,42,16,5],
[46,42,16,6],
[46,42,16,7],
[46,42,16,8],
[46,42,16,11],
[46,42,16,12],
[46,42,16,13],
[46,42,16,14],
[46,42,17,0],
[46,42,17,1],
[46,42,17,2],
[46,42,17,3],
[46,42,17,4],
[46,42,17,5],
[46,42,17,6],
[46,42,17,7],
[46,42,17,8],
[46,42,17,11],
[46,42,17,12],
[46,42,17,13],
[46,42,17,14],
[46,42,17,16],
[46,42,19,0],
[46,42,19,1],
[46,42,19,2],
[46,42,19,3],
[46,42,19,4],
[46,42,19,5],
[46,42,19,6],
[46,42,19,7],
[46,42,19,8],
[46,42,19,11],
[46,42,19,12],
[46,42,19,16],
[46,42,19,17],
[46,42,21,0],
[46,42,21,1],
[46,42,21,2],
[46,42,21,4],
[46,42,21,7],
[46,42,21,11],
[46,42,21,12],
[46,42,21,13],
[46,42,21,14],
[46,42,21,16],
[46,42,21,17],
[46,42,21,19],
[46,42,23,0],
[46,42,23,1],
[46,42,23,2],
[46,42,23,3],
[46,42,23,4],
[46,42,23,5],
[46,42,23,6],
[46,42,23,7],
[46,42,23,8],
[46,42,23,11],
[46,42,23,12],
[46,42,23,13],
[46,42,23,14],
[46,42,23,16],
[46,42,23,17],
[46,42,23,19],
[46,42,23,21],
[46,42,24,0],
[46,42,24,1],
[46,42,24,2],
[46,42,24,3],
[46,42,24,4],
[46,42,24,5],
[46,42,24,6],
[46,42,24,7],
[46,42,24,8],
[46,42,24,11],
[46,42,24,12],
[46,42,24,13],
[46,42,24,14],
[46,42,24,17],
[46,42,24,19],
[46,42,24,21],
[46,42,24,23],
[46,42,25,0],
[46,42,25,1],
[46,42,25,2],
[46,42,25,3],
[46,42,25,4],
[46,42,25,5],
[46,42,25,6],
[46,42,25,8],
[46,42,25,11],
[46,42,25,12],
[46,42,25,13],
[46,42,25,14],
[46,42,25,16],
[46,42,25,17],
[46,42,25,19],
[46,42,25,21],
[46,42,25,23],
[46,42,25,24],
[46,42,28,0],
[46,42,28,1],
[46,42,28,2],
[46,42,28,3],
[46,42,28,4],
[46,42,28,5],
[46,42,28,6],
[46,42,28,8],
[46,42,28,12],
[46,42,28,13],
[46,42,28,14],
[46,42,28,16],
[46,42,28,17],
[46,42,28,19],
[46,42,28,21],
[46,42,28,23],
[46,42,28,24],
[46,42,29,0],
[46,42,29,1],
[46,42,29,2],
[46,42,29,3],
[46,42,29,4],
[46,42,29,5],
[46,42,29,6],
[46,42,29,7],
[46,42,29,13],
[46,42,29,14],
[46,42,29,16],
[46,42,29,17],
[46,42,29,19],
[46,42,29,21],
[46,42,29,23],
[46,42,29,24],
[46,42,30,0],
[46,42,30,1],
[46,42,30,2],
[46,42,30,3],
[46,42,30,4],
[46,42,30,5],
[46,42,30,8],
[46,42,30,11],
[46,42,30,12],
[46,42,30,13],
[46,42,30,14],
[46,42,30,16],
[46,42,30,17],
[46,42,30,19],
[46,42,30,21],
[46,42,30,24],
[46,42,31,0],
[46,42,31,1],
[46,42,31,2],
[46,42,31,3],
[46,42,31,4],
[46,42,31,6],
[46,42,31,7],
[46,42,31,8],
[46,42,31,11],
[46,42,31,12],
[46,42,31,13],
[46,42,31,14],
[46,42,31,16],
[46,42,31,17],
[46,42,31,19],
[46,42,31,21],
[46,42,32,0],
[46,42,32,1],
[46,42,32,2],
[46,42,32,5],
[46,42,32,6],
[46,42,32,7],
[46,42,32,8],
[46,42,32,13],
[46,42,32,14],
[46,42,32,16],
[46,42,32,17],
[46,42,32,19],
[46,42,32,21],
[46,42,33,3],
[46,42,33,4],
[46,42,33,5],
[46,42,33,6],
[46,42,33,7],
[46,42,33,8],
[46,42,33,13],
[46,42,33,14],
[46,42,33,16],
[46,42,33,17],
[46,42,33,19],
[46,42,34,0],
[46,42,34,1],
[46,42,34,2],
[46,42,34,3],
[46,42,34,4],
[46,42,34,5],
[46,42,34,6],
[46,42,34,8],
[46,42,34,11],
[46,42,34,12],
[46,42,34,13],
[46,42,34,14],
[46,42,34,16],
[46,42,34,17],
[46,42,34,19],
[46,42,36,0],
[46,42,36,1],
[46,42,36,2],
[46,42,36,3],
[46,42,36,4],
[46,42,36,5],
[46,42,36,6],
[46,42,36,7],
[46,42,36,8],
[46,42,36,11],
[46,42,36,12],
[46,42,36,16],
[46,42,36,17],
[46,42,37,0],
[46,42,37,1],
[46,42,37,2],
[46,42,37,3],
[46,42,37,5],
[46,42,37,6],
[46,42,37,7],
[46,42,37,8],
[46,42,37,11],
[46,42,37,12],
[46,42,37,13],
[46,42,37,14],
[46,42,38,0],
[46,42,38,1],
[46,42,38,2],
[46,42,38,3],
[46,42,38,4],
[46,42,38,5],
[46,42,38,6],
[46,42,38,8],
[46,42,38,11],
[46,42,38,12],
[46,42,38,13],
[46,42,38,14],
[46,42,39,0],
[46,42,39,1],
[46,42,39,2],
[46,42,39,4],
[46,42,39,7],
[46,42,39,11],
[46,42,39,12],
[46,42,39,13],
[46,43,3,0],
[46,43,3,1],
[46,43,3,2],
[46,43,4,0],
[46,43,4,1],
[46,43,4,2],
[46,43,4,3],
[46,43,5,0],
[46,43,5,1],
[46,43,5,2],
[46,43,5,4],
[46,43,6,0],
[46,43,6,1],
[46,43,6,2],
[46,43,6,4],
[46,43,8,0],
[46,43,8,1],
[46,43,8,2],
[46,43,8,4],
[46,43,10,0],
[46,43,10,1],
[46,43,10,2],
[46,43,10,3],
[46,43,10,4],
[46,43,10,5],
[46,43,10,6],
[46,43,10,8],
[46,43,11,0],
[46,43,11,1],
[46,43,11,2],
[46,43,11,3],
[46,43,11,4],
[46,43,11,5],
[46,43,11,6],
[46,43,11,8],
[46,43,11,10],
[46,43,12,0],
[46,43,12,1],
[46,43,12,2],
[46,43,12,3],
[46,43,12,4],
[46,43,12,5],
[46,43,12,6],
[46,43,12,8],
[46,43,12,10],
[46,43,13,0],
[46,43,13,1],
[46,43,13,2],
[46,43,13,3],
[46,43,13,4],
[46,43,13,5],
[46,43,13,6],
[46,43,13,8],
[46,43,13,10],
[46,43,13,11],
[46,43,13,12],
[46,43,14,0],
[46,43,14,1],
[46,43,14,2],
[46,43,14,3],
[46,43,14,4],
[46,43,14,5],
[46,43,14,6],
[46,43,14,8],
[46,43,14,10],
[46,43,14,11],
[46,43,14,12],
[46,43,16,0],
[46,43,16,1],
[46,43,16,2],
[46,43,16,3],
[46,43,16,4],
[46,43,16,5],
[46,43,16,6],
[46,43,16,8],
[46,43,16,10],
[46,43,16,11],
[46,43,16,12],
[46,43,16,13],
[46,43,16,14],
[46,43,17,0],
[46,43,17,1],
[46,43,17,2],
[46,43,17,3],
[46,43,17,4],
[46,43,17,5],
[46,43,17,6],
[46,43,17,8],
[46,43,17,10],
[46,43,17,11],
[46,43,17,12],
[46,43,17,13],
[46,43,17,14],
[46,43,17,16],
[46,43,19,0],
[46,43,19,1],
[46,43,19,2],
[46,43,19,3],
[46,43,19,4],
[46,43,19,5],
[46,43,19,6],
[46,43,19,8],
[46,43,19,10],
[46,43,19,11],
[46,43,19,12],
[46,43,19,16],
[46,43,19,17],
[46,43,20,0],
[46,43,20,1],
[46,43,20,2],
[46,43,20,3],
[46,43,20,4],
[46,43,20,5],
[46,43,20,6],
[46,43,20,8],
[46,43,20,11],
[46,43,20,12],
[46,43,20,13],
[46,43,20,14],
[46,43,20,16],
[46,43,20,17],
[46,43,20,19],
[46,43,21,0],
[46,43,21,1],
[46,43,21,2],
[46,43,21,4],
[46,43,21,10],
[46,43,21,11],
[46,43,21,12],
[46,43,21,13],
[46,43,21,14],
[46,43,21,16],
[46,43,21,17],
[46,43,21,20],
[46,43,22,0],
[46,43,22,1],
[46,43,22,2],
[46,43,22,3],
[46,43,22,4],
[46,43,22,5],
[46,43,22,6],
[46,43,22,8],
[46,43,22,11],
[46,43,22,12],
[46,43,22,13],
[46,43,22,14],
[46,43,22,16],
[46,43,22,17],
[46,43,22,19],
[46,43,22,21],
[46,43,23,0],
[46,43,23,1],
[46,43,23,2],
[46,43,23,3],
[46,43,23,4],
[46,43,23,5],
[46,43,23,6],
[46,43,23,8],
[46,43,23,10],
[46,43,23,11],
[46,43,23,12],
[46,43,23,13],
[46,43,23,14],
[46,43,23,19],
[46,43,23,20],
[46,43,23,21],
[46,43,23,22],
[46,43,24,0],
[46,43,24,1],
[46,43,24,2],
[46,43,24,3],
[46,43,24,4],
[46,43,24,5],
[46,43,24,6],
[46,43,24,8],
[46,43,24,10],
[46,43,24,11],
[46,43,24,12],
[46,43,24,13],
[46,43,24,14],
[46,43,24,19],
[46,43,24,20],
[46,43,24,21],
[46,43,24,22],
[46,43,24,23],
[46,43,27,0],
[46,43,27,1],
[46,43,27,2],
[46,43,27,3],
[46,43,27,4],
[46,43,27,5],
[46,43,27,6],
[46,43,27,8],
[46,43,27,12],
[46,43,27,13],
[46,43,27,14],
[46,43,27,16],
[46,43,27,17],
[46,43,27,19],
[46,43,27,21],
[46,43,27,23],
[46,43,27,24],
[46,43,29,0],
[46,43,29,1],
[46,43,29,2],
[46,43,29,3],
[46,43,29,4],
[46,43,29,5],
[46,43,29,8],
[46,43,29,10],
[46,43,29,13],
[46,43,29,14],
[46,43,29,16],
[46,43,29,17],
[46,43,29,19],
[46,43,29,20],
[46,43,29,21],
[46,43,29,22],
[46,43,29,23],
[46,43,29,24],
[46,43,30,0],
[46,43,30,1],
[46,43,30,2],
[46,43,30,3],
[46,43,30,4],
[46,43,30,6],
[46,43,30,8],
[46,43,30,10],
[46,43,30,11],
[46,43,30,12],
[46,43,30,13],
[46,43,30,14],
[46,43,30,16],
[46,43,30,17],
[46,43,30,19],
[46,43,30,20],
[46,43,30,21],
[46,43,30,22],
[46,43,31,0],
[46,43,31,1],
[46,43,31,2],
[46,43,31,5],
[46,43,31,6],
[46,43,31,8],
[46,43,31,10],
[46,43,31,11],
[46,43,31,12],
[46,43,31,13],
[46,43,31,14],
[46,43,31,16],
[46,43,31,17],
[46,43,31,19],
[46,43,31,20],
[46,43,31,21],
[46,43,32,3],
[46,43,32,4],
[46,43,32,5],
[46,43,32,6],
[46,43,32,8],
[46,43,32,10],
[46,43,32,13],
[46,43,32,14],
[46,43,32,16],
[46,43,32,17],
[46,43,32,19],
[46,43,32,20],
[46,43,33,0],
[46,43,33,1],
[46,43,33,2],
[46,43,33,3],
[46,43,33,4],
[46,43,33,5],
[46,43,33,6],
[46,43,33,8],
[46,43,33,10],
[46,43,33,13],
[46,43,33,14],
[46,43,33,16],
[46,43,33,17],
[46,43,33,19],
[46,43,36,0],
[46,43,36,1],
[46,43,36,2],
[46,43,36,3],
[46,43,36,4],
[46,43,36,5],
[46,43,36,6],
[46,43,36,8],
[46,43,36,10],
[46,43,36,11],
[46,43,36,12],
[46,43,37,0],
[46,43,37,1],
[46,43,37,2],
[46,43,37,3],
[46,43,37,5],
[46,43,37,6],
[46,43,37,8],
[46,43,37,10],
[46,43,37,11],
[46,43,37,12],
[46,43,37,13],
[46,43,37,14],
[46,43,39,0],
[46,43,39,1],
[46,43,39,2],
[46,43,39,4],
[46,43,39,10],
[46,43,39,11],
[46,43,42,0],
[46,43,42,1],
[46,43,42,2],
[46,43,42,3],
[46,43,42,4],
[46,43,42,5],
[46,43,42,6],
[46,44,3,0],
[46,44,3,1],
[46,44,3,2],
[46,44,4,0],
[46,44,4,1],
[46,44,4,2],
[46,44,4,3],
[46,44,5,0],
[46,44,5,1],
[46,44,5,2],
[46,44,5,4],
[46,44,6,0],
[46,44,6,1],
[46,44,6,2],
[46,44,6,4],
[46,44,7,0],
[46,44,7,1],
[46,44,7,2],
[46,44,7,3],
[46,44,7,4],
[46,44,7,5],
[46,44,7,6],
[46,44,8,0],
[46,44,8,1],
[46,44,8,2],
[46,44,8,4],
[46,44,8,7],
[46,44,10,0],
[46,44,10,1],
[46,44,10,2],
[46,44,10,3],
[46,44,10,4],
[46,44,10,5],
[46,44,10,6],
[46,44,10,7],
[46,44,10,8],
[46,44,11,0],
[46,44,11,1],
[46,44,11,2],
[46,44,11,3],
[46,44,11,4],
[46,44,11,5],
[46,44,11,6],
[46,44,11,7],
[46,44,11,8],
[46,44,11,10],
[46,44,12,0],
[46,44,12,1],
[46,44,12,2],
[46,44,12,3],
[46,44,12,4],
[46,44,12,5],
[46,44,12,6],
[46,44,12,7],
[46,44,12,8],
[46,44,12,10],
[46,44,13,0],
[46,44,13,1],
[46,44,13,2],
[46,44,13,3],
[46,44,13,4],
[46,44,13,5],
[46,44,13,6],
[46,44,13,7],
[46,44,13,8],
[46,44,13,10],
[46,44,13,11],
[46,44,13,12],
[46,44,14,0],
[46,44,14,1],
[46,44,14,2],
[46,44,14,3],
[46,44,14,4],
[46,44,14,5],
[46,44,14,6],
[46,44,14,7],
[46,44,14,8],
[46,44,14,10],
[46,44,14,11],
[46,44,14,12],
[46,44,16,0],
[46,44,16,1],
[46,44,16,2],
[46,44,16,3],
[46,44,16,4],
[46,44,16,5],
[46,44,16,6],
[46,44,16,7],
[46,44,16,8],
[46,44,16,10],
[46,44,16,11],
[46,44,16,12],
[46,44,16,13],
[46,44,16,14],
[46,44,19,0],
[46,44,19,1],
[46,44,19,2],
[46,44,19,3],
[46,44,19,4],
[46,44,19,5],
[46,44,19,6],
[46,44,19,7],
[46,44,19,8],
[46,44,19,10],
[46,44,19,11],
[46,44,19,12],
[46,44,19,16],
[46,44,20,0],
[46,44,20,1],
[46,44,20,2],
[46,44,20,3],
[46,44,20,4],
[46,44,20,5],
[46,44,20,6],
[46,44,20,7],
[46,44,20,8],
[46,44,20,11],
[46,44,20,12],
[46,44,20,13],
[46,44,20,14],
[46,44,20,16],
[46,44,21,0],
[46,44,21,1],
[46,44,21,2],
[46,44,21,4],
[46,44,21,7],
[46,44,21,10],
[46,44,21,11],
[46,44,21,12],
[46,44,21,13],
[46,44,21,14],
[46,44,21,16],
[46,44,21,19],
[46,44,21,20],
[46,44,22,0],
[46,44,22,1],
[46,44,22,2],
[46,44,22,3],
[46,44,22,4],
[46,44,22,5],
[46,44,22,6],
[46,44,22,7],
[46,44,22,8],
[46,44,22,11],
[46,44,22,12],
[46,44,22,13],
[46,44,22,14],
[46,44,22,19],
[46,44,22,21],
[46,44,23,0],
[46,44,23,1],
[46,44,23,2],
[46,44,23,3],
[46,44,23,4],
[46,44,23,5],
[46,44,23,6],
[46,44,23,7],
[46,44,23,8],
[46,44,23,10],
[46,44,23,11],
[46,44,23,12],
[46,44,23,13],
[46,44,23,14],
[46,44,23,16],
[46,44,23,19],
[46,44,23,20],
[46,44,23,21],
[46,44,23,22],
[46,44,24,0],
[46,44,24,1],
[46,44,24,2],
[46,44,24,3],
[46,44,24,4],
[46,44,24,5],
[46,44,24,6],
[46,44,24,7],
[46,44,24,8],
[46,44,24,10],
[46,44,24,11],
[46,44,24,12],
[46,44,24,13],
[46,44,24,14],
[46,44,24,19],
[46,44,24,20],
[46,44,24,21],
[46,44,24,22],
[46,44,24,23],
[46,44,25,0],
[46,44,25,1],
[46,44,25,2],
[46,44,25,3],
[46,44,25,4],
[46,44,25,5],
[46,44,25,6],
[46,44,25,8],
[46,44,25,10],
[46,44,25,11],
[46,44,25,14],
[46,44,25,16],
[46,44,25,19],
[46,44,25,20],
[46,44,25,21],
[46,44,25,22],
[46,44,25,23],
[46,44,25,24],
[46,44,27,0],
[46,44,27,1],
[46,44,27,2],
[46,44,27,3],
[46,44,27,4],
[46,44,27,5],
[46,44,27,6],
[46,44,27,7],
[46,44,27,11],
[46,44,27,12],
[46,44,27,13],
[46,44,27,14],
[46,44,27,16],
[46,44,27,19],
[46,44,27,21],
[46,44,27,23],
[46,44,27,24],
[46,44,28,0],
[46,44,28,1],
[46,44,28,2],
[46,44,28,3],
[46,44,28,4],
[46,44,28,5],
[46,44,28,8],
[46,44,28,10],
[46,44,28,11],
[46,44,28,12],
[46,44,28,13],
[46,44,28,14],
[46,44,28,16],
[46,44,28,19],
[46,44,28,20],
[46,44,28,21],
[46,44,28,22],
[46,44,28,23],
[46,44,28,24],
[46,44,29,0],
[46,44,29,1],
[46,44,29,2],
[46,44,29,3],
[46,44,29,4],
[46,44,29,6],
[46,44,29,7],
[46,44,29,8],
[46,44,29,10],
[46,44,29,13],
[46,44,29,14],
[46,44,29,16],
[46,44,29,19],
[46,44,29,20],
[46,44,29,21],
[46,44,29,22],
[46,44,30,0],
[46,44,30,1],
[46,44,30,2],
[46,44,30,5],
[46,44,30,6],
[46,44,30,7],
[46,44,30,8],
[46,44,30,10],
[46,44,30,11],
[46,44,30,12],
[46,44,30,13],
[46,44,30,14],
[46,44,30,16],
[46,44,30,19],
[46,44,30,20],
[46,44,30,21],
[46,44,31,3],
[46,44,31,4],
[46,44,31,5],
[46,44,31,6],
[46,44,31,7],
[46,44,31,8],
[46,44,31,10],
[46,44,31,11],
[46,44,31,12],
[46,44,31,13],
[46,44,31,14],
[46,44,31,16],
[46,44,31,19],
[46,44,31,20],
[46,44,32,0],
[46,44,32,1],
[46,44,32,2],
[46,44,32,3],
[46,44,32,4],
[46,44,32,5],
[46,44,32,6],
[46,44,32,7],
[46,44,32,8],
[46,44,32,10],
[46,44,32,13],
[46,44,32,14],
[46,44,32,16],
[46,44,32,19],
[46,44,33,0],
[46,44,33,1],
[46,44,33,2],
[46,44,33,3],
[46,44,33,4],
[46,44,33,5],
[46,44,33,6],
[46,44,33,7],
[46,44,33,8],
[46,44,33,10],
[46,44,33,13],
[46,44,33,14],
[46,44,33,16],
[46,44,34,0],
[46,44,34,1],
[46,44,34,2],
[46,44,34,3],
[46,44,34,4],
[46,44,34,5],
[46,44,34,6],
[46,44,34,8],
[46,44,34,10],
[46,44,34,11],
[46,44,34,12],
[46,44,34,13],
[46,44,34,14],
[46,44,34,16],
[46,44,36,0],
[46,44,36,1],
[46,44,36,2],
[46,44,36,3],
[46,44,36,4],
[46,44,36,5],
[46,44,36,6],
[46,44,36,7],
[46,44,36,8],
[46,44,36,10],
[46,44,36,11],
[46,44,36,12],
[46,44,37,0],
[46,44,37,1],
[46,44,37,2],
[46,44,37,3],
[46,44,37,5],
[46,44,37,6],
[46,44,37,7],
[46,44,37,8],
[46,44,37,10],
[46,44,37,11],
[46,44,37,12],
[46,44,37,13],
[46,44,38,0],
[46,44,38,1],
[46,44,38,2],
[46,44,38,3],
[46,44,38,4],
[46,44,38,5],
[46,44,38,6],
[46,44,38,8],
[46,44,38,10],
[46,44,38,11],
[46,44,39,0],
[46,44,39,1],
[46,44,39,2],
[46,44,39,4],
[46,44,39,7],
[46,44,42,0],
[46,44,42,1],
[46,44,42,2],
[46,44,42,3],
[46,44,42,4],
[46,44,42,5],
[46,44,43,0],
[46,44,43,1],
[46,44,43,2],
[46,44,43,3],
[46,44,43,4],
[47,4,3,0],
[47,4,3,1],
[47,4,3,2],
[47,5,4,0],
[47,5,4,1],
[47,5,4,2],
[47,6,4,0],
[47,6,4,1],
[47,6,4,2],
[47,7,3,0],
[47,7,3,1],
[47,7,3,2],
[47,7,4,0],
[47,7,4,1],
[47,7,4,2],
[47,7,4,3],
[47,7,5,0],
[47,7,5,1],
[47,7,5,2],
[47,7,5,4],
[47,7,6,0],
[47,7,6,1],
[47,7,6,2],
[47,7,6,4],
[47,8,4,0],
[47,8,4,1],
[47,8,4,2],
[47,8,7,0],
[47,8,7,1],
[47,8,7,2],
[47,8,7,4],
[47,9,3,0],
[47,9,3,1],
[47,9,3,2],
[47,9,4,0],
[47,9,4,1],
[47,9,4,2],
[47,9,4,3],
[47,9,5,0],
[47,9,5,1],
[47,9,5,2],
[47,9,5,4],
[47,9,6,0],
[47,9,6,1],
[47,9,6,2],
[47,9,6,4],
[47,9,7,0],
[47,9,7,1],
[47,9,7,2],
[47,9,7,3],
[47,9,7,4],
[47,9,7,5],
[47,9,7,6],
[47,9,8,0],
[47,9,8,1],
[47,9,8,2],
[47,9,8,4],
[47,9,8,7],
[47,10,3,0],
[47,10,3,1],
[47,10,3,2],
[47,10,4,0],
[47,10,4,1],
[47,10,4,2],
[47,10,4,3],
[47,10,5,0],
[47,10,5,1],
[47,10,5,2],
[47,10,5,4],
[47,10,6,0],
[47,10,6,1],
[47,10,6,2],
[47,10,6,4],
[47,10,7,0],
[47,10,7,1],
[47,10,7,2],
[47,10,7,3],
[47,10,7,4],
[47,10,7,5],
[47,10,7,6],
[47,10,8,0],
[47,10,8,1],
[47,10,8,2],
[47,10,8,4],
[47,10,8,7],
[47,10,9,0],
[47,10,9,1],
[47,10,9,2],
[47,10,9,3],
[47,10,9,4],
[47,10,9,5],
[47,10,9,6],
[47,10,9,7],
[47,10,9,8],
[47,11,3,0],
[47,11,3,1],
[47,11,3,2],
[47,11,4,0],
[47,11,4,1],
[47,11,4,2],
[47,11,4,3],
[47,11,5,0],
[47,11,5,1],
[47,11,5,2],
[47,11,5,4],
[47,11,6,0],
[47,11,6,1],
[47,11,6,2],
[47,11,6,4],
[47,11,7,0],
[47,11,7,1],
[47,11,7,2],
[47,11,7,3],
[47,11,7,4],
[47,11,7,5],
[47,11,7,6],
[47,11,8,0],
[47,11,8,1],
[47,11,8,2],
[47,11,8,4],
[47,11,8,7],
[47,11,9,0],
[47,11,9,1],
[47,11,9,2],
[47,11,9,3],
[47,11,9,4],
[47,11,9,5],
[47,11,9,6],
[47,11,9,7],
[47,11,9,8],
[47,11,10,0],
[47,11,10,1],
[47,11,10,2],
[47,11,10,3],
[47,11,10,4],
[47,11,10,5],
[47,11,10,6],
[47,11,10,7],
[47,11,10,8],
[47,11,10,9],
[47,12,3,0],
[47,12,3,1],
[47,12,3,2],
[47,12,4,0],
[47,12,4,1],
[47,12,4,2],
[47,12,4,3],
[47,12,5,0],
[47,12,5,1],
[47,12,5,2],
[47,12,5,4],
[47,12,6,0],
[47,12,6,1],
[47,12,6,2],
[47,12,6,4],
[47,12,7,0],
[47,12,7,1],
[47,12,7,2],
[47,12,7,3],
[47,12,7,4],
[47,12,7,5],
[47,12,7,6],
[47,12,8,0],
[47,12,8,1],
[47,12,8,2],
[47,12,8,4],
[47,12,8,7],
[47,12,9,0],
[47,12,9,1],
[47,12,9,2],
[47,12,9,3],
[47,12,9,4],
[47,12,9,5],
[47,12,9,6],
[47,12,9,7],
[47,12,9,8],
[47,12,10,0],
[47,12,10,1],
[47,12,10,2],
[47,12,10,3],
[47,12,10,4],
[47,12,10,5],
[47,12,10,6],
[47,12,10,7],
[47,12,10,8],
[47,12,10,9],
[47,13,3,0],
[47,13,3,1],
[47,13,3,2],
[47,13,4,0],
[47,13,4,1],
[47,13,4,2],
[47,13,4,3],
[47,13,5,0],
[47,13,5,1],
[47,13,5,2],
[47,13,5,4],
[47,13,6,0],
[47,13,6,1],
[47,13,6,2],
[47,13,6,4],
[47,13,7,0],
[47,13,7,1],
[47,13,7,2],
[47,13,7,3],
[47,13,7,4],
[47,13,7,5],
[47,13,7,6],
[47,13,8,0],
[47,13,8,1],
[47,13,8,2],
[47,13,8,4],
[47,13,8,7],
[47,13,9,0],
[47,13,9,1],
[47,13,9,2],
[47,13,9,3],
[47,13,9,4],
[47,13,9,5],
[47,13,9,6],
[47,13,9,7],
[47,13,9,8],
[47,13,10,0],
[47,13,10,1],
[47,13,10,2],
[47,13,10,3],
[47,13,10,4],
[47,13,10,5],
[47,13,10,6],
[47,13,10,7],
[47,13,10,8],
[47,13,10,9],
[47,13,11,0],
[47,13,11,1],
[47,13,11,2],
[47,13,11,3],
[47,13,11,4],
[47,13,11,5],
[47,13,11,6],
[47,13,11,7],
[47,13,11,8],
[47,13,11,9],
[47,13,11,10],
[47,13,12,0],
[47,13,12,1],
[47,13,12,2],
[47,13,12,3],
[47,13,12,4],
[47,13,12,5],
[47,13,12,6],
[47,13,12,7],
[47,13,12,8],
[47,13,12,9],
[47,13,12,10],
[47,14,3,0],
[47,14,3,1],
[47,14,3,2],
[47,14,4,0],
[47,14,4,1],
[47,14,4,2],
[47,14,4,3],
[47,14,5,0],
[47,14,5,1],
[47,14,5,2],
[47,14,5,4],
[47,14,6,0],
[47,14,6,1],
[47,14,6,2],
[47,14,6,4],
[47,14,7,0],
[47,14,7,1],
[47,14,7,2],
[47,14,7,3],
[47,14,7,4],
[47,14,7,5],
[47,14,7,6],
[47,14,8,0],
[47,14,8,1],
[47,14,8,2],
[47,14,8,4],
[47,14,8,7],
[47,14,9,0],
[47,14,9,1],
[47,14,9,2],
[47,14,9,3],
[47,14,9,4],
[47,14,9,5],
[47,14,9,6],
[47,14,9,7],
[47,14,9,8],
[47,14,10,0],
[47,14,10,1],
[47,14,10,2],
[47,14,10,3],
[47,14,10,4],
[47,14,10,5],
[47,14,10,6],
[47,14,10,7],
[47,14,10,8],
[47,14,10,9],
[47,14,11,0],
[47,14,11,1],
[47,14,11,2],
[47,14,11,3],
[47,14,11,4],
[47,14,11,5],
[47,14,11,6],
[47,14,11,7],
[47,14,11,8],
[47,14,11,9],
[47,14,11,10],
[47,14,12,0],
[47,14,12,1],
[47,14,12,2],
[47,14,12,3],
[47,14,12,4],
[47,14,12,5],
[47,14,12,6],
[47,14,12,7],
[47,14,12,8],
[47,14,12,9],
[47,14,12,10],
[47,15,3,0],
[47,15,3,1],
[47,15,3,2],
[47,15,4,0],
[47,15,4,1],
[47,15,4,2],
[47,15,4,3],
[47,15,5,0],
[47,15,5,1],
[47,15,5,2],
[47,15,5,4],
[47,15,6,0],
[47,15,6,1],
[47,15,6,2],
[47,15,6,4],
[47,15,7,0],
[47,15,7,1],
[47,15,7,2],
[47,15,7,3],
[47,15,7,4],
[47,15,7,5],
[47,15,7,6],
[47,15,8,0],
[47,15,8,1],
[47,15,8,2],
[47,15,8,4],
[47,15,8,7],
[47,15,10,0],
[47,15,10,1],
[47,15,10,2],
[47,15,10,3],
[47,15,10,4],
[47,15,10,5],
[47,15,10,6],
[47,15,10,7],
[47,15,10,8],
[47,15,11,0],
[47,15,11,1],
[47,15,11,2],
[47,15,11,3],
[47,15,11,4],
[47,15,11,5],
[47,15,11,6],
[47,15,11,7],
[47,15,11,8],
[47,15,11,10],
[47,15,12,0],
[47,15,12,1],
[47,15,12,2],
[47,15,12,3],
[47,15,12,4],
[47,15,12,5],
[47,15,12,6],
[47,15,12,7],
[47,15,12,8],
[47,15,12,10],
[47,15,13,0],
[47,15,13,1],
[47,15,13,2],
[47,15,13,3],
[47,15,13,4],
[47,15,13,5],
[47,15,13,6],
[47,15,13,7],
[47,15,13,8],
[47,15,13,10],
[47,15,13,11],
[47,15,13,12],
[47,15,14,0],
[47,15,14,1],
[47,15,14,2],
[47,15,14,3],
[47,15,14,4],
[47,15,14,5],
[47,15,14,6],
[47,15,14,7],
[47,15,14,8],
[47,15,14,10],
[47,15,14,11],
[47,15,14,12],
[47,16,3,0],
[47,16,3,1],
[47,16,3,2],
[47,16,4,0],
[47,16,4,1],
[47,16,4,2],
[47,16,4,3],
[47,16,5,0],
[47,16,5,1],
[47,16,5,2],
[47,16,5,4],
[47,16,6,0],
[47,16,6,1],
[47,16,6,2],
[47,16,6,4],
[47,16,7,0],
[47,16,7,1],
[47,16,7,2],
[47,16,7,3],
[47,16,7,4],
[47,16,7,5],
[47,16,7,6],
[47,16,8,0],
[47,16,8,1],
[47,16,8,2],
[47,16,8,4],
[47,16,8,7],
[47,16,9,0],
[47,16,9,1],
[47,16,9,2],
[47,16,9,3],
[47,16,9,4],
[47,16,9,5],
[47,16,9,6],
[47,16,9,7],
[47,16,9,8],
[47,16,10,0],
[47,16,10,1],
[47,16,10,2],
[47,16,10,3],
[47,16,10,4],
[47,16,10,5],
[47,16,10,6],
[47,16,10,7],
[47,16,10,8],
[47,16,10,9],
[47,16,11,0],
[47,16,11,1],
[47,16,11,2],
[47,16,11,3],
[47,16,11,4],
[47,16,11,5],
[47,16,11,6],
[47,16,11,7],
[47,16,11,8],
[47,16,11,9],
[47,16,11,10],
[47,16,12,0],
[47,16,12,1],
[47,16,12,2],
[47,16,12,3],
[47,16,12,4],
[47,16,12,5],
[47,16,12,6],
[47,16,12,7],
[47,16,12,8],
[47,16,12,9],
[47,16,12,10],
[47,16,13,0],
[47,16,13,1],
[47,16,13,2],
[47,16,13,3],
[47,16,13,4],
[47,16,13,5],
[47,16,13,6],
[47,16,13,7],
[47,16,13,8],
[47,16,13,9],
[47,16,13,10],
[47,16,13,11],
[47,16,13,12],
[47,16,14,0],
[47,16,14,1],
[47,16,14,2],
[47,16,14,3],
[47,16,14,4],
[47,16,14,5],
[47,16,14,6],
[47,16,14,7],
[47,16,14,8],
[47,16,14,9],
[47,16,14,10],
[47,16,14,11],
[47,16,14,12],
[47,16,15,0],
[47,16,15,1],
[47,16,15,2],
[47,16,15,3],
[47,16,15,4],
[47,16,15,5],
[47,16,15,6],
[47,16,15,7],
[47,16,15,8],
[47,16,15,10],
[47,16,15,11],
[47,16,15,12],
[47,16,15,13],
[47,16,15,14],
[47,17,3,0],
[47,17,3,1],
[47,17,3,2],
[47,17,4,0],
[47,17,4,1],
[47,17,4,2],
[47,17,4,3],
[47,17,5,0],
[47,17,5,1],
[47,17,5,2],
[47,17,5,4],
[47,17,6,0],
[47,17,6,1],
[47,17,6,2],
[47,17,6,4],
[47,17,7,0],
[47,17,7,1],
[47,17,7,2],
[47,17,7,3],
[47,17,7,4],
[47,17,7,5],
[47,17,7,6],
[47,17,8,0],
[47,17,8,1],
[47,17,8,2],
[47,17,8,4],
[47,17,8,7],
[47,17,9,0],
[47,17,9,1],
[47,17,9,2],
[47,17,9,3],
[47,17,9,4],
[47,17,9,5],
[47,17,9,6],
[47,17,9,7],
[47,17,9,8],
[47,17,10,0],
[47,17,10,1],
[47,17,10,2],
[47,17,10,3],
[47,17,10,4],
[47,17,10,5],
[47,17,10,6],
[47,17,10,7],
[47,17,10,8],
[47,17,10,9],
[47,17,11,0],
[47,17,11,1],
[47,17,11,2],
[47,17,11,3],
[47,17,11,4],
[47,17,11,5],
[47,17,11,6],
[47,17,11,7],
[47,17,11,8],
[47,17,11,9],
[47,17,11,10],
[47,17,12,0],
[47,17,12,1],
[47,17,12,2],
[47,17,12,3],
[47,17,12,4],
[47,17,12,5],
[47,17,12,6],
[47,17,12,7],
[47,17,12,8],
[47,17,12,9],
[47,17,12,10],
[47,17,13,0],
[47,17,13,1],
[47,17,13,2],
[47,17,13,3],
[47,17,13,4],
[47,17,13,5],
[47,17,13,6],
[47,17,13,7],
[47,17,13,8],
[47,17,13,9],
[47,17,13,10],
[47,17,13,11],
[47,17,13,12],
[47,17,14,0],
[47,17,14,1],
[47,17,14,2],
[47,17,14,3],
[47,17,14,4],
[47,17,14,5],
[47,17,14,6],
[47,17,14,7],
[47,17,14,8],
[47,17,14,9],
[47,17,14,10],
[47,17,14,11],
[47,17,14,12],
[47,17,15,0],
[47,17,15,1],
[47,17,15,2],
[47,17,15,3],
[47,17,15,4],
[47,17,15,5],
[47,17,15,6],
[47,17,15,7],
[47,17,15,8],
[47,17,15,10],
[47,17,15,11],
[47,17,15,12],
[47,17,15,13],
[47,17,15,14],
[47,17,16,0],
[47,17,16,1],
[47,17,16,2],
[47,17,16,3],
[47,17,16,4],
[47,17,16,5],
[47,17,16,6],
[47,17,16,7],
[47,17,16,8],
[47,17,16,9],
[47,17,16,10],
[47,17,16,11],
[47,17,16,12],
[47,17,16,13],
[47,17,16,14],
[47,17,16,15],
[47,18,3,0],
[47,18,3,1],
[47,18,3,2],
[47,18,4,0],
[47,18,4,1],
[47,18,4,2],
[47,18,4,3],
[47,18,5,0],
[47,18,5,1],
[47,18,5,2],
[47,18,5,4],
[47,18,6,0],
[47,18,6,1],
[47,18,6,2],
[47,18,6,4],
[47,18,7,0],
[47,18,7,1],
[47,18,7,2],
[47,18,7,3],
[47,18,7,4],
[47,18,7,5],
[47,18,7,6],
[47,18,8,0],
[47,18,8,1],
[47,18,8,2],
[47,18,8,4],
[47,18,8,7],
[47,18,10,0],
[47,18,10,1],
[47,18,10,2],
[47,18,10,3],
[47,18,10,4],
[47,18,10,5],
[47,18,10,6],
[47,18,10,7],
[47,18,10,8],
[47,18,11,0],
[47,18,11,1],
[47,18,11,2],
[47,18,11,3],
[47,18,11,4],
[47,18,11,5],
[47,18,11,6],
[47,18,11,7],
[47,18,11,8],
[47,18,11,10],
[47,18,12,0],
[47,18,12,1],
[47,18,12,2],
[47,18,12,3],
[47,18,12,4],
[47,18,12,5],
[47,18,12,6],
[47,18,12,7],
[47,18,12,8],
[47,18,12,10],
[47,18,13,0],
[47,18,13,1],
[47,18,13,2],
[47,18,13,3],
[47,18,13,4],
[47,18,13,5],
[47,18,13,6],
[47,18,13,7],
[47,18,13,8],
[47,18,13,10],
[47,18,13,11],
[47,18,13,12],
[47,18,14,0],
[47,18,14,1],
[47,18,14,2],
[47,18,14,3],
[47,18,14,4],
[47,18,14,5],
[47,18,14,6],
[47,18,14,7],
[47,18,14,8],
[47,18,14,10],
[47,18,14,11],
[47,18,14,12],
[47,18,16,0],
[47,18,16,1],
[47,18,16,2],
[47,18,16,3],
[47,18,16,4],
[47,18,16,5],
[47,18,16,6],
[47,18,16,7],
[47,18,16,8],
[47,18,16,10],
[47,18,16,11],
[47,18,16,12],
[47,18,16,13],
[47,18,16,14],
[47,18,17,0],
[47,18,17,1],
[47,18,17,2],
[47,18,17,3],
[47,18,17,4],
[47,18,17,5],
[47,18,17,6],
[47,18,17,7],
[47,18,17,8],
[47,18,17,10],
[47,18,17,11],
[47,18,17,12],
[47,18,17,13],
[47,18,17,14],
[47,18,17,16],
[47,19,3,0],
[47,19,3,1],
[47,19,3,2],
[47,19,4,0],
[47,19,4,1],
[47,19,4,2],
[47,19,4,3],
[47,19,5,0],
[47,19,5,1],
[47,19,5,2],
[47,19,5,4],
[47,19,6,0],
[47,19,6,1],
[47,19,6,2],
[47,19,6,4],
[47,19,7,0],
[47,19,7,1],
[47,19,7,2],
[47,19,7,3],
[47,19,7,4],
[47,19,7,5],
[47,19,7,6],
[47,19,8,0],
[47,19,8,1],
[47,19,8,2],
[47,19,8,4],
[47,19,8,7],
[47,19,9,0],
[47,19,9,1],
[47,19,9,2],
[47,19,9,3],
[47,19,9,4],
[47,19,9,5],
[47,19,9,6],
[47,19,9,7],
[47,19,9,8],
[47,19,10,0],
[47,19,10,1],
[47,19,10,2],
[47,19,10,3],
[47,19,10,4],
[47,19,10,5],
[47,19,10,6],
[47,19,10,7],
[47,19,10,8],
[47,19,10,9],
[47,19,11,0],
[47,19,11,1],
[47,19,11,2],
[47,19,11,3],
[47,19,11,4],
[47,19,11,5],
[47,19,11,6],
[47,19,11,7],
[47,19,11,8],
[47,19,11,9],
[47,19,11,10],
[47,19,12,0],
[47,19,12,1],
[47,19,12,2],
[47,19,12,3],
[47,19,12,4],
[47,19,12,5],
[47,19,12,6],
[47,19,12,7],
[47,19,12,8],
[47,19,12,9],
[47,19,12,10],
[47,19,15,0],
[47,19,15,1],
[47,19,15,2],
[47,19,15,3],
[47,19,15,4],
[47,19,15,5],
[47,19,15,6],
[47,19,15,7],
[47,19,15,8],
[47,19,15,10],
[47,19,15,11],
[47,19,15,12],
[47,19,16,0],
[47,19,16,1],
[47,19,16,2],
[47,19,16,3],
[47,19,16,4],
[47,19,16,5],
[47,19,16,6],
[47,19,16,7],
[47,19,16,8],
[47,19,16,9],
[47,19,16,10],
[47,19,16,11],
[47,19,16,12],
[47,19,16,15],
[47,19,17,0],
[47,19,17,1],
[47,19,17,2],
[47,19,17,3],
[47,19,17,4],
[47,19,17,5],
[47,19,17,6],
[47,19,17,7],
[47,19,17,8],
[47,19,17,9],
[47,19,17,10],
[47,19,17,11],
[47,19,17,12],
[47,19,17,15],
[47,19,17,16],
[47,19,18,0],
[47,19,18,1],
[47,19,18,2],
[47,19,18,3],
[47,19,18,4],
[47,19,18,5],
[47,19,18,6],
[47,19,18,7],
[47,19,18,8],
[47,19,18,10],
[47,19,18,11],
[47,19,18,12],
[47,19,18,16],
[47,19,18,17],
[47,20,3,0],
[47,20,3,1],
[47,20,3,2],
[47,20,4,0],
[47,20,4,1],
[47,20,4,2],
[47,20,4,3],
[47,20,5,0],
[47,20,5,1],
[47,20,5,2],
[47,20,5,4],
[47,20,6,0],
[47,20,6,1],
[47,20,6,2],
[47,20,6,4],
[47,20,7,0],
[47,20,7,1],
[47,20,7,2],
[47,20,7,3],
[47,20,7,4],
[47,20,7,5],
[47,20,7,6],
[47,20,8,0],
[47,20,8,1],
[47,20,8,2],
[47,20,8,4],
[47,20,8,7],
[47,20,9,0],
[47,20,9,1],
[47,20,9,2],
[47,20,9,3],
[47,20,9,4],
[47,20,9,5],
[47,20,9,6],
[47,20,9,7],
[47,20,9,8],
[47,20,11,0],
[47,20,11,1],
[47,20,11,2],
[47,20,11,3],
[47,20,11,4],
[47,20,11,5],
[47,20,11,6],
[47,20,11,7],
[47,20,11,8],
[47,20,11,9],
[47,20,12,0],
[47,20,12,1],
[47,20,12,2],
[47,20,12,3],
[47,20,12,4],
[47,20,12,5],
[47,20,12,6],
[47,20,12,7],
[47,20,12,8],
[47,20,12,9],
[47,20,13,0],
[47,20,13,1],
[47,20,13,2],
[47,20,13,3],
[47,20,13,4],
[47,20,13,5],
[47,20,13,6],
[47,20,13,7],
[47,20,13,8],
[47,20,13,9],
[47,20,13,11],
[47,20,13,12],
[47,20,14,0],
[47,20,14,1],
[47,20,14,2],
[47,20,14,3],
[47,20,14,4],
[47,20,14,5],
[47,20,14,6],
[47,20,14,7],
[47,20,14,8],
[47,20,14,9],
[47,20,14,11],
[47,20,14,12],
[47,20,15,0],
[47,20,15,1],
[47,20,15,2],
[47,20,15,3],
[47,20,15,4],
[47,20,15,5],
[47,20,15,6],
[47,20,15,7],
[47,20,15,8],
[47,20,15,11],
[47,20,15,12],
[47,20,15,13],
[47,20,15,14],
[47,20,16,0],
[47,20,16,1],
[47,20,16,2],
[47,20,16,3],
[47,20,16,4],
[47,20,16,5],
[47,20,16,6],
[47,20,16,7],
[47,20,16,8],
[47,20,16,9],
[47,20,16,11],
[47,20,16,12],
[47,20,16,13],
[47,20,16,14],
[47,20,16,15],
[47,20,17,0],
[47,20,17,1],
[47,20,17,2],
[47,20,17,3],
[47,20,17,4],
[47,20,17,5],
[47,20,17,6],
[47,20,17,7],
[47,20,17,8],
[47,20,17,9],
[47,20,17,11],
[47,20,17,12],
[47,20,17,13],
[47,20,17,14],
[47,20,17,15],
[47,20,17,16],
[47,20,18,0],
[47,20,18,1],
[47,20,18,2],
[47,20,18,3],
[47,20,18,4],
[47,20,18,5],
[47,20,18,6],
[47,20,18,7],
[47,20,18,8],
[47,20,18,11],
[47,20,18,12],
[47,20,18,13],
[47,20,18,14],
[47,20,18,16],
[47,20,18,17],
[47,20,19,0],
[47,20,19,1],
[47,20,19,2],
[47,20,19,3],
[47,20,19,4],
[47,20,19,5],
[47,20,19,6],
[47,20,19,7],
[47,20,19,8],
[47,20,19,9],
[47,20,19,11],
[47,20,19,12],
[47,20,19,15],
[47,20,19,16],
[47,20,19,17],
[47,20,19,18],
[47,21,4,0],
[47,21,4,1],
[47,21,4,2],
[47,21,7,0],
[47,21,7,1],
[47,21,7,2],
[47,21,7,4],
[47,21,9,0],
[47,21,9,1],
[47,21,9,2],
[47,21,9,4],
[47,21,9,7],
[47,21,10,0],
[47,21,10,1],
[47,21,10,2],
[47,21,10,4],
[47,21,10,7],
[47,21,10,9],
[47,21,11,0],
[47,21,11,1],
[47,21,11,2],
[47,21,11,4],
[47,21,11,7],
[47,21,11,9],
[47,21,11,10],
[47,21,12,0],
[47,21,12,1],
[47,21,12,2],
[47,21,12,4],
[47,21,12,7],
[47,21,12,9],
[47,21,12,10],
[47,21,13,0],
[47,21,13,1],
[47,21,13,2],
[47,21,13,4],
[47,21,13,7],
[47,21,13,9],
[47,21,13,10],
[47,21,13,11],
[47,21,13,12],
[47,21,14,0],
[47,21,14,1],
[47,21,14,2],
[47,21,14,4],
[47,21,14,7],
[47,21,14,9],
[47,21,14,10],
[47,21,14,11],
[47,21,14,12],
[47,21,15,0],
[47,21,15,1],
[47,21,15,2],
[47,21,15,4],
[47,21,15,7],
[47,21,15,10],
[47,21,15,11],
[47,21,15,12],
[47,21,15,13],
[47,21,15,14],
[47,21,16,0],
[47,21,16,1],
[47,21,16,2],
[47,21,16,4],
[47,21,16,7],
[47,21,16,9],
[47,21,16,10],
[47,21,16,11],
[47,21,16,12],
[47,21,16,13],
[47,21,16,14],
[47,21,16,15],
[47,21,17,0],
[47,21,17,1],
[47,21,17,2],
[47,21,17,4],
[47,21,17,7],
[47,21,17,9],
[47,21,17,10],
[47,21,17,11],
[47,21,17,12],
[47,21,17,13],
[47,21,17,14],
[47,21,17,15],
[47,21,17,16],
[47,21,18,0],
[47,21,18,1],
[47,21,18,2],
[47,21,18,4],
[47,21,18,7],
[47,21,18,10],
[47,21,18,11],
[47,21,18,12],
[47,21,18,13],
[47,21,18,14],
[47,21,18,16],
[47,21,18,17],
[47,21,19,0],
[47,21,19,1],
[47,21,19,2],
[47,21,19,4],
[47,21,19,7],
[47,21,19,9],
[47,21,19,10],
[47,21,19,11],
[47,21,19,12],
[47,21,19,15],
[47,21,19,16],
[47,21,19,17],
[47,21,19,18],
[47,21,20,0],
[47,21,20,1],
[47,21,20,2],
[47,21,20,4],
[47,21,20,7],
[47,21,20,9],
[47,21,20,11],
[47,21,20,12],
[47,21,20,13],
[47,21,20,14],
[47,21,20,15],
[47,21,20,16],
[47,21,20,17],
[47,21,20,18],
[47,21,20,19],
[47,22,3,0],
[47,22,3,1],
[47,22,3,2],
[47,22,4,0],
[47,22,4,1],
[47,22,4,2],
[47,22,4,3],
[47,22,5,0],
[47,22,5,1],
[47,22,5,2],
[47,22,5,4],
[47,22,6,0],
[47,22,6,1],
[47,22,6,2],
[47,22,6,4],
[47,22,7,0],
[47,22,7,1],
[47,22,7,2],
[47,22,7,3],
[47,22,7,4],
[47,22,7,5],
[47,22,7,6],
[47,22,8,0],
[47,22,8,1],
[47,22,8,2],
[47,22,8,4],
[47,22,8,7],
[47,22,9,0],
[47,22,9,1],
[47,22,9,2],
[47,22,9,3],
[47,22,9,4],
[47,22,9,5],
[47,22,9,6],
[47,22,9,7],
[47,22,9,8],
[47,22,11,0],
[47,22,11,1],
[47,22,11,2],
[47,22,11,3],
[47,22,11,4],
[47,22,11,5],
[47,22,11,6],
[47,22,11,7],
[47,22,11,8],
[47,22,11,9],
[47,22,12,0],
[47,22,12,1],
[47,22,12,2],
[47,22,12,3],
[47,22,12,4],
[47,22,12,5],
[47,22,12,6],
[47,22,12,7],
[47,22,12,8],
[47,22,12,9],
[47,22,13,0],
[47,22,13,1],
[47,22,13,2],
[47,22,13,3],
[47,22,13,4],
[47,22,13,5],
[47,22,13,6],
[47,22,13,7],
[47,22,13,8],
[47,22,13,9],
[47,22,13,11],
[47,22,13,12],
[47,22,14,0],
[47,22,14,1],
[47,22,14,2],
[47,22,14,3],
[47,22,14,4],
[47,22,14,5],
[47,22,14,6],
[47,22,14,7],
[47,22,14,8],
[47,22,14,9],
[47,22,14,11],
[47,22,14,12],
[47,22,15,0],
[47,22,15,1],
[47,22,15,2],
[47,22,15,3],
[47,22,15,4],
[47,22,15,5],
[47,22,15,6],
[47,22,15,7],
[47,22,15,8],
[47,22,15,11],
[47,22,15,12],
[47,22,15,13],
[47,22,15,14],
[47,22,16,0],
[47,22,16,1],
[47,22,16,2],
[47,22,16,3],
[47,22,16,4],
[47,22,16,5],
[47,22,16,6],
[47,22,16,7],
[47,22,16,8],
[47,22,16,9],
[47,22,16,11],
[47,22,16,12],
[47,22,16,13],
[47,22,16,14],
[47,22,16,15],
[47,22,17,0],
[47,22,17,1],
[47,22,17,2],
[47,22,17,3],
[47,22,17,4],
[47,22,17,5],
[47,22,17,6],
[47,22,17,7],
[47,22,17,8],
[47,22,17,9],
[47,22,17,11],
[47,22,17,12],
[47,22,17,13],
[47,22,17,14],
[47,22,17,15],
[47,22,17,16],
[47,22,18,0],
[47,22,18,1],
[47,22,18,2],
[47,22,18,3],
[47,22,18,4],
[47,22,18,5],
[47,22,18,6],
[47,22,18,7],
[47,22,18,8],
[47,22,18,11],
[47,22,18,12],
[47,22,18,13],
[47,22,18,14],
[47,22,18,16],
[47,22,18,17],
[47,22,19,0],
[47,22,19,1],
[47,22,19,2],
[47,22,19,3],
[47,22,19,4],
[47,22,19,5],
[47,22,19,6],
[47,22,19,7],
[47,22,19,8],
[47,22,19,9],
[47,22,19,11],
[47,22,19,12],
[47,22,19,15],
[47,22,19,16],
[47,22,19,17],
[47,22,19,18],
[47,22,21,0],
[47,22,21,1],
[47,22,21,2],
[47,22,21,4],
[47,22,21,7],
[47,22,21,9],
[47,22,21,11],
[47,22,21,12],
[47,22,21,13],
[47,22,21,14],
[47,22,21,15],
[47,22,21,16],
[47,22,21,17],
[47,22,21,18],
[47,22,21,19],
[47,24,3,0],
[47,24,3,1],
[47,24,3,2],
[47,24,4,0],
[47,24,4,1],
[47,24,4,2],
[47,24,4,3],
[47,24,5,0],
[47,24,5,1],
[47,24,5,2],
[47,24,5,4],
[47,24,6,0],
[47,24,6,1],
[47,24,6,2],
[47,24,6,4],
[47,24,7,0],
[47,24,7,1],
[47,24,7,2],
[47,24,7,3],
[47,24,7,4],
[47,24,7,5],
[47,24,7,6],
[47,24,8,0],
[47,24,8,1],
[47,24,8,2],
[47,24,8,4],
[47,24,8,7],
[47,24,9,0],
[47,24,9,1],
[47,24,9,2],
[47,24,9,3],
[47,24,9,4],
[47,24,9,5],
[47,24,9,6],
[47,24,9,7],
[47,24,9,8],
[47,24,10,0],
[47,24,10,1],
[47,24,10,2],
[47,24,10,3],
[47,24,10,4],
[47,24,10,5],
[47,24,10,6],
[47,24,10,7],
[47,24,10,8],
[47,24,10,9],
[47,24,11,0],
[47,24,11,1],
[47,24,11,2],
[47,24,11,3],
[47,24,11,4],
[47,24,11,5],
[47,24,11,6],
[47,24,11,7],
[47,24,11,8],
[47,24,11,9],
[47,24,11,10],
[47,24,12,0],
[47,24,12,1],
[47,24,12,2],
[47,24,12,3],
[47,24,12,4],
[47,24,12,5],
[47,24,12,6],
[47,24,12,7],
[47,24,12,8],
[47,24,12,9],
[47,24,12,10],
[47,24,13,0],
[47,24,13,1],
[47,24,13,2],
[47,24,13,3],
[47,24,13,4],
[47,24,13,5],
[47,24,13,6],
[47,24,13,7],
[47,24,13,8],
[47,24,13,9],
[47,24,13,10],
[47,24,13,11],
[47,24,13,12],
[47,24,14,0],
[47,24,14,1],
[47,24,14,2],
[47,24,14,3],
[47,24,14,4],
[47,24,14,5],
[47,24,14,6],
[47,24,14,7],
[47,24,14,8],
[47,24,14,9],
[47,24,14,10],
[47,24,14,11],
[47,24,14,12],
[47,24,15,0],
[47,24,15,1],
[47,24,15,2],
[47,24,15,3],
[47,24,15,4],
[47,24,15,5],
[47,24,15,6],
[47,24,15,7],
[47,24,15,8],
[47,24,15,10],
[47,24,15,11],
[47,24,15,12],
[47,24,15,13],
[47,24,15,14],
[47,24,17,0],
[47,24,17,1],
[47,24,17,2],
[47,24,17,3],
[47,24,17,4],
[47,24,17,5],
[47,24,17,6],
[47,24,17,7],
[47,24,17,8],
[47,24,17,9],
[47,24,17,10],
[47,24,17,11],
[47,24,17,12],
[47,24,17,13],
[47,24,17,14],
[47,24,17,15],
[47,24,18,0],
[47,24,18,1],
[47,24,18,2],
[47,24,18,3],
[47,24,18,4],
[47,24,18,5],
[47,24,18,6],
[47,24,18,7],
[47,24,18,8],
[47,24,18,10],
[47,24,18,11],
[47,24,18,12],
[47,24,18,13],
[47,24,18,14],
[47,24,18,17],
[47,24,19,0],
[47,24,19,1],
[47,24,19,2],
[47,24,19,3],
[47,24,19,4],
[47,24,19,5],
[47,24,19,6],
[47,24,19,7],
[47,24,19,8],
[47,24,19,9],
[47,24,19,10],
[47,24,19,11],
[47,24,19,12],
[47,24,19,15],
[47,24,19,17],
[47,24,19,18],
[47,24,20,0],
[47,24,20,1],
[47,24,20,2],
[47,24,20,3],
[47,24,20,4],
[47,24,20,5],
[47,24,20,6],
[47,24,20,7],
[47,24,20,8],
[47,24,20,9],
[47,24,20,11],
[47,24,20,12],
[47,24,20,13],
[47,24,20,14],
[47,24,20,15],
[47,24,20,17],
[47,24,20,18],
[47,24,20,19],
[47,24,21,0],
[47,24,21,1],
[47,24,21,2],
[47,24,21,4],
[47,24,21,7],
[47,24,21,9],
[47,24,21,10],
[47,24,21,11],
[47,24,21,12],
[47,24,21,13],
[47,24,21,14],
[47,24,21,15],
[47,24,21,17],
[47,24,21,18],
[47,24,21,19],
[47,24,21,20],
[47,24,22,0],
[47,24,22,1],
[47,24,22,2],
[47,24,22,3],
[47,24,22,4],
[47,24,22,5],
[47,24,22,6],
[47,24,22,7],
[47,24,22,8],
[47,24,22,9],
[47,24,22,11],
[47,24,22,12],
[47,24,22,13],
[47,24,22,14],
[47,24,22,15],
[47,24,22,17],
[47,24,22,18],
[47,24,22,19],
[47,24,22,21],
[47,25,3,0],
[47,25,3,1],
[47,25,3,2],
[47,25,4,0],
[47,25,4,1],
[47,25,4,2],
[47,25,4,3],
[47,25,5,0],
[47,25,5,1],
[47,25,5,2],
[47,25,5,4],
[47,25,6,0],
[47,25,6,1],
[47,25,6,2],
[47,25,6,4],
[47,25,8,0],
[47,25,8,1],
[47,25,8,2],
[47,25,8,4],
[47,25,9,0],
[47,25,9,1],
[47,25,9,2],
[47,25,9,3],
[47,25,9,4],
[47,25,9,5],
[47,25,9,6],
[47,25,9,8],
[47,25,10,0],
[47,25,10,1],
[47,25,10,2],
[47,25,10,3],
[47,25,10,4],
[47,25,10,5],
[47,25,10,6],
[47,25,10,8],
[47,25,10,9],
[47,25,11,0],
[47,25,11,1],
[47,25,11,2],
[47,25,11,3],
[47,25,11,4],
[47,25,11,5],
[47,25,11,6],
[47,25,11,8],
[47,25,11,9],
[47,25,11,10],
[47,25,12,0],
[47,25,12,1],
[47,25,12,2],
[47,25,12,3],
[47,25,12,4],
[47,25,12,5],
[47,25,12,6],
[47,25,12,8],
[47,25,12,9],
[47,25,12,10],
[47,25,13,0],
[47,25,13,1],
[47,25,13,2],
[47,25,13,3],
[47,25,13,4],
[47,25,13,5],
[47,25,13,6],
[47,25,13,8],
[47,25,13,9],
[47,25,13,10],
[47,25,13,11],
[47,25,13,12],
[47,25,14,0],
[47,25,14,1],
[47,25,14,2],
[47,25,14,3],
[47,25,14,4],
[47,25,14,5],
[47,25,14,6],
[47,25,14,8],
[47,25,14,9],
[47,25,14,10],
[47,25,14,11],
[47,25,14,12],
[47,25,15,0],
[47,25,15,1],
[47,25,15,2],
[47,25,15,3],
[47,25,15,4],
[47,25,15,5],
[47,25,15,6],
[47,25,15,8],
[47,25,15,10],
[47,25,15,11],
[47,25,15,12],
[47,25,15,13],
[47,25,15,14],
[47,25,16,0],
[47,25,16,1],
[47,25,16,2],
[47,25,16,3],
[47,25,16,4],
[47,25,16,5],
[47,25,16,6],
[47,25,16,8],
[47,25,16,9],
[47,25,16,10],
[47,25,16,11],
[47,25,16,12],
[47,25,16,13],
[47,25,16,14],
[47,25,16,15],
[47,25,17,0],
[47,25,17,1],
[47,25,17,2],
[47,25,17,3],
[47,25,17,4],
[47,25,17,5],
[47,25,17,6],
[47,25,17,8],
[47,25,17,9],
[47,25,17,10],
[47,25,17,11],
[47,25,17,12],
[47,25,17,13],
[47,25,17,14],
[47,25,17,15],
[47,25,17,16],
[47,25,18,0],
[47,25,18,1],
[47,25,18,2],
[47,25,18,3],
[47,25,18,4],
[47,25,18,5],
[47,25,18,6],
[47,25,18,8],
[47,25,18,10],
[47,25,18,11],
[47,25,18,12],
[47,25,18,13],
[47,25,18,14],
[47,25,18,16],
[47,25,18,17],
[47,25,19,0],
[47,25,19,1],
[47,25,19,2],
[47,25,19,3],
[47,25,19,4],
[47,25,19,5],
[47,25,19,6],
[47,25,19,8],
[47,25,19,9],
[47,25,19,10],
[47,25,19,11],
[47,25,19,12],
[47,25,19,15],
[47,25,19,16],
[47,25,19,17],
[47,25,19,18],
[47,25,20,0],
[47,25,20,1],
[47,25,20,2],
[47,25,20,3],
[47,25,20,4],
[47,25,20,5],
[47,25,20,6],
[47,25,20,8],
[47,25,20,9],
[47,25,20,11],
[47,25,20,12],
[47,25,20,13],
[47,25,20,14],
[47,25,20,15],
[47,25,20,16],
[47,25,20,17],
[47,25,20,18],
[47,25,20,19],
[47,25,21,0],
[47,25,21,1],
[47,25,21,2],
[47,25,21,4],
[47,25,21,9],
[47,25,21,10],
[47,25,21,11],
[47,25,21,12],
[47,25,21,13],
[47,25,21,14],
[47,25,21,15],
[47,25,21,16],
[47,25,21,17],
[47,25,21,18],
[47,25,21,19],
[47,25,21,20],
[47,25,22,0],
[47,25,22,1],
[47,25,22,2],
[47,25,22,3],
[47,25,22,4],
[47,25,22,5],
[47,25,22,6],
[47,25,22,8],
[47,25,22,9],
[47,25,22,11],
[47,25,22,12],
[47,25,22,13],
[47,25,22,14],
[47,25,22,15],
[47,25,22,16],
[47,25,22,17],
[47,25,22,18],
[47,25,22,19],
[47,25,22,21],
[47,25,24,0],
[47,25,24,1],
[47,25,24,2],
[47,25,24,3],
[47,25,24,4],
[47,25,24,5],
[47,25,24,6],
[47,25,24,8],
[47,25,24,9],
[47,25,24,10],
[47,25,24,11],
[47,25,24,12],
[47,25,24,13],
[47,25,24,14],
[47,25,24,15],
[47,25,24,17],
[47,25,24,18],
[47,25,24,19],
[47,25,24,20],
[47,25,24,21],
[47,25,24,22],
[47,26,3,0],
[47,26,3,1],
[47,26,3,2],
[47,26,4,0],
[47,26,4,1],
[47,26,4,2],
[47,26,4,3],
[47,26,5,0],
[47,26,5,1],
[47,26,5,2],
[47,26,5,4],
[47,26,6,0],
[47,26,6,1],
[47,26,6,2],
[47,26,6,4],
[47,26,7,0],
[47,26,7,1],
[47,26,7,2],
[47,26,7,3],
[47,26,7,4],
[47,26,7,5],
[47,26,7,6],
[47,26,8,0],
[47,26,8,1],
[47,26,8,2],
[47,26,8,4],
[47,26,8,7],
[47,26,10,0],
[47,26,10,1],
[47,26,10,2],
[47,26,10,3],
[47,26,10,4],
[47,26,10,5],
[47,26,10,6],
[47,26,10,7],
[47,26,10,8],
[47,26,11,0],
[47,26,11,1],
[47,26,11,2],
[47,26,11,3],
[47,26,11,4],
[47,26,11,5],
[47,26,11,6],
[47,26,11,7],
[47,26,11,8],
[47,26,11,10],
[47,26,12,0],
[47,26,12,1],
[47,26,12,2],
[47,26,12,3],
[47,26,12,4],
[47,26,12,5],
[47,26,12,6],
[47,26,12,7],
[47,26,12,8],
[47,26,12,10],
[47,26,13,0],
[47,26,13,1],
[47,26,13,2],
[47,26,13,3],
[47,26,13,4],
[47,26,13,5],
[47,26,13,6],
[47,26,13,7],
[47,26,13,8],
[47,26,13,10],
[47,26,13,11],
[47,26,13,12],
[47,26,14,0],
[47,26,14,1],
[47,26,14,2],
[47,26,14,3],
[47,26,14,4],
[47,26,14,5],
[47,26,14,6],
[47,26,14,7],
[47,26,14,8],
[47,26,14,10],
[47,26,14,11],
[47,26,14,12],
[47,26,16,0],
[47,26,16,1],
[47,26,16,2],
[47,26,16,3],
[47,26,16,4],
[47,26,16,5],
[47,26,16,6],
[47,26,16,7],
[47,26,16,8],
[47,26,16,10],
[47,26,16,11],
[47,26,16,12],
[47,26,16,13],
[47,26,16,14],
[47,26,17,0],
[47,26,17,1],
[47,26,17,2],
[47,26,17,3],
[47,26,17,4],
[47,26,17,5],
[47,26,17,6],
[47,26,17,7],
[47,26,17,8],
[47,26,17,10],
[47,26,17,11],
[47,26,17,12],
[47,26,17,13],
[47,26,17,14],
[47,26,17,16],
[47,26,19,0],
[47,26,19,1],
[47,26,19,2],
[47,26,19,3],
[47,26,19,4],
[47,26,19,5],
[47,26,19,6],
[47,26,19,7],
[47,26,19,8],
[47,26,19,10],
[47,26,19,11],
[47,26,19,12],
[47,26,19,16],
[47,26,19,17],
[47,26,20,0],
[47,26,20,1],
[47,26,20,2],
[47,26,20,3],
[47,26,20,4],
[47,26,20,5],
[47,26,20,6],
[47,26,20,7],
[47,26,20,8],
[47,26,20,11],
[47,26,20,12],
[47,26,20,13],
[47,26,20,14],
[47,26,20,16],
[47,26,20,17],
[47,26,20,19],
[47,26,21,0],
[47,26,21,1],
[47,26,21,2],
[47,26,21,4],
[47,26,21,7],
[47,26,21,10],
[47,26,21,11],
[47,26,21,12],
[47,26,21,13],
[47,26,21,14],
[47,26,21,16],
[47,26,21,17],
[47,26,21,19],
[47,26,21,20],
[47,26,22,0],
[47,26,22,1],
[47,26,22,2],
[47,26,22,3],
[47,26,22,4],
[47,26,22,5],
[47,26,22,6],
[47,26,22,7],
[47,26,22,8],
[47,26,22,11],
[47,26,22,12],
[47,26,22,13],
[47,26,22,14],
[47,26,22,16],
[47,26,22,17],
[47,26,22,19],
[47,26,22,21],
[47,26,24,0],
[47,26,24,1],
[47,26,24,2],
[47,26,24,3],
[47,26,24,4],
[47,26,24,5],
[47,26,24,6],
[47,26,24,7],
[47,26,24,8],
[47,26,24,10],
[47,26,24,11],
[47,26,24,12],
[47,26,24,13],
[47,26,24,14],
[47,26,24,17],
[47,26,24,19],
[47,26,24,20],
[47,26,24,21],
[47,26,24,22],
[47,26,25,0],
[47,26,25,1],
[47,26,25,2],
[47,26,25,3],
[47,26,25,4],
[47,26,25,5],
[47,26,25,6],
[47,26,25,8],
[47,26,25,10],
[47,26,25,11],
[47,26,25,12],
[47,26,25,13],
[47,26,25,14],
[47,26,25,16],
[47,26,25,17],
[47,26,25,19],
[47,26,25,20],
[47,26,25,21],
[47,26,25,22],
[47,26,25,24],
[47,27,3,0],
[47,27,3,1],
[47,27,3,2],
[47,27,4,0],
[47,27,4,1],
[47,27,4,2],
[47,27,4,3],
[47,27,5,0],
[47,27,5,1],
[47,27,5,2],
[47,27,5,4],
[47,27,6,0],
[47,27,6,1],
[47,27,6,2],
[47,27,6,4],
[47,27,7,0],
[47,27,7,1],
[47,27,7,2],
[47,27,7,3],
[47,27,7,4],
[47,27,7,5],
[47,27,7,6],
[47,27,8,0],
[47,27,8,1],
[47,27,8,2],
[47,27,8,4],
[47,27,8,7],
[47,27,9,0],
[47,27,9,1],
[47,27,9,2],
[47,27,9,3],
[47,27,9,4],
[47,27,9,5],
[47,27,9,6],
[47,27,9,7],
[47,27,9,8],
[47,27,11,0],
[47,27,11,1],
[47,27,11,2],
[47,27,11,3],
[47,27,11,4],
[47,27,11,5],
[47,27,11,6],
[47,27,11,7],
[47,27,11,8],
[47,27,11,9],
[47,27,12,0],
[47,27,12,1],
[47,27,12,2],
[47,27,12,3],
[47,27,12,4],
[47,27,12,5],
[47,27,12,6],
[47,27,12,7],
[47,27,12,8],
[47,27,12,9],
[47,27,13,0],
[47,27,13,1],
[47,27,13,2],
[47,27,13,3],
[47,27,13,4],
[47,27,13,5],
[47,27,13,6],
[47,27,13,7],
[47,27,13,8],
[47,27,13,9],
[47,27,13,11],
[47,27,13,12],
[47,27,14,0],
[47,27,14,1],
[47,27,14,2],
[47,27,14,3],
[47,27,14,4],
[47,27,14,5],
[47,27,14,6],
[47,27,14,7],
[47,27,14,8],
[47,27,14,9],
[47,27,14,11],
[47,27,14,12],
[47,27,15,0],
[47,27,15,1],
[47,27,15,2],
[47,27,15,3],
[47,27,15,4],
[47,27,15,5],
[47,27,15,6],
[47,27,15,7],
[47,27,15,8],
[47,27,15,11],
[47,27,15,12],
[47,27,15,13],
[47,27,15,14],
[47,27,16,0],
[47,27,16,1],
[47,27,16,2],
[47,27,16,3],
[47,27,16,4],
[47,27,16,5],
[47,27,16,6],
[47,27,16,7],
[47,27,16,8],
[47,27,16,9],
[47,27,16,11],
[47,27,16,12],
[47,27,16,13],
[47,27,16,14],
[47,27,16,15],
[47,27,17,0],
[47,27,17,1],
[47,27,17,2],
[47,27,17,3],
[47,27,17,4],
[47,27,17,5],
[47,27,17,6],
[47,27,17,7],
[47,27,17,8],
[47,27,17,9],
[47,27,17,11],
[47,27,17,12],
[47,27,17,13],
[47,27,17,14],
[47,27,17,15],
[47,27,17,16],
[47,27,18,0],
[47,27,18,1],
[47,27,18,2],
[47,27,18,3],
[47,27,18,4],
[47,27,18,5],
[47,27,18,6],
[47,27,18,7],
[47,27,18,8],
[47,27,18,11],
[47,27,18,12],
[47,27,18,13],
[47,27,18,14],
[47,27,18,16],
[47,27,18,17],
[47,27,19,0],
[47,27,19,1],
[47,27,19,2],
[47,27,19,3],
[47,27,19,4],
[47,27,19,5],
[47,27,19,6],
[47,27,19,7],
[47,27,19,8],
[47,27,19,9],
[47,27,19,11],
[47,27,19,12],
[47,27,19,15],
[47,27,19,16],
[47,27,19,17],
[47,27,19,18],
[47,27,21,0],
[47,27,21,1],
[47,27,21,2],
[47,27,21,4],
[47,27,21,7],
[47,27,21,9],
[47,27,21,11],
[47,27,21,12],
[47,27,21,13],
[47,27,21,14],
[47,27,21,15],
[47,27,21,16],
[47,27,21,17],
[47,27,21,18],
[47,27,21,19],
[47,27,24,0],
[47,27,24,1],
[47,27,24,2],
[47,27,24,3],
[47,27,24,4],
[47,27,24,5],
[47,27,24,6],
[47,27,24,7],
[47,27,24,8],
[47,27,24,9],
[47,27,24,11],
[47,27,24,12],
[47,27,24,13],
[47,27,24,14],
[47,27,24,15],
[47,27,24,17],
[47,27,24,18],
[47,27,24,19],
[47,27,24,21],
[47,27,25,0],
[47,27,25,1],
[47,27,25,2],
[47,27,25,3],
[47,27,25,4],
[47,27,25,5],
[47,27,25,6],
[47,27,25,8],
[47,27,25,9],
[47,27,25,11],
[47,27,25,12],
[47,27,25,13],
[47,27,25,14],
[47,27,25,15],
[47,27,25,16],
[47,27,25,17],
[47,27,25,18],
[47,27,25,19],
[47,27,25,21],
[47,27,25,24],
[47,27,26,0],
[47,27,26,1],
[47,27,26,2],
[47,27,26,3],
[47,27,26,4],
[47,27,26,5],
[47,27,26,6],
[47,27,26,7],
[47,27,26,8],
[47,27,26,11],
[47,27,26,12],
[47,27,26,13],
[47,27,26,14],
[47,27,26,16],
[47,27,26,17],
[47,27,26,19],
[47,27,26,21],
[47,27,26,24],
[47,27,26,25],
[47,28,3,0],
[47,28,3,1],
[47,28,3,2],
[47,28,4,0],
[47,28,4,1],
[47,28,4,2],
[47,28,4,3],
[47,28,5,0],
[47,28,5,1],
[47,28,5,2],
[47,28,5,4],
[47,28,6,0],
[47,28,6,1],
[47,28,6,2],
[47,28,6,4],
[47,28,8,0],
[47,28,8,1],
[47,28,8,2],
[47,28,8,4],
[47,28,9,0],
[47,28,9,1],
[47,28,9,2],
[47,28,9,3],
[47,28,9,4],
[47,28,9,5],
[47,28,9,6],
[47,28,9,8],
[47,28,10,0],
[47,28,10,1],
[47,28,10,2],
[47,28,10,3],
[47,28,10,4],
[47,28,10,5],
[47,28,10,6],
[47,28,10,8],
[47,28,10,9],
[47,28,11,0],
[47,28,11,1],
[47,28,11,2],
[47,28,11,3],
[47,28,11,4],
[47,28,11,5],
[47,28,11,6],
[47,28,11,8],
[47,28,11,9],
[47,28,11,10],
[47,28,12,0],
[47,28,12,1],
[47,28,12,2],
[47,28,12,3],
[47,28,12,4],
[47,28,12,5],
[47,28,12,6],
[47,28,12,8],
[47,28,12,9],
[47,28,12,10],
[47,28,13,0],
[47,28,13,1],
[47,28,13,2],
[47,28,13,3],
[47,28,13,4],
[47,28,13,5],
[47,28,13,6],
[47,28,13,8],
[47,28,13,9],
[47,28,13,10],
[47,28,13,11],
[47,28,13,12],
[47,28,14,0],
[47,28,14,1],
[47,28,14,2],
[47,28,14,3],
[47,28,14,4],
[47,28,14,5],
[47,28,14,6],
[47,28,14,8],
[47,28,14,9],
[47,28,14,10],
[47,28,14,11],
[47,28,14,12],
[47,28,15,0],
[47,28,15,1],
[47,28,15,2],
[47,28,15,3],
[47,28,15,4],
[47,28,15,5],
[47,28,15,6],
[47,28,15,8],
[47,28,15,10],
[47,28,15,11],
[47,28,15,12],
[47,28,15,13],
[47,28,15,14],
[47,28,16,0],
[47,28,16,1],
[47,28,16,2],
[47,28,16,3],
[47,28,16,4],
[47,28,16,5],
[47,28,16,6],
[47,28,16,8],
[47,28,16,9],
[47,28,16,10],
[47,28,16,11],
[47,28,16,12],
[47,28,16,13],
[47,28,16,14],
[47,28,16,15],
[47,28,17,0],
[47,28,17,1],
[47,28,17,2],
[47,28,17,3],
[47,28,17,4],
[47,28,17,5],
[47,28,17,6],
[47,28,17,8],
[47,28,17,9],
[47,28,17,10],
[47,28,17,11],
[47,28,17,12],
[47,28,17,13],
[47,28,17,14],
[47,28,17,15],
[47,28,17,16],
[47,28,18,0],
[47,28,18,1],
[47,28,18,2],
[47,28,18,3],
[47,28,18,4],
[47,28,18,5],
[47,28,18,6],
[47,28,18,8],
[47,28,18,10],
[47,28,18,11],
[47,28,18,12],
[47,28,18,13],
[47,28,18,14],
[47,28,18,16],
[47,28,18,17],
[47,28,19,0],
[47,28,19,1],
[47,28,19,2],
[47,28,19,3],
[47,28,19,4],
[47,28,19,5],
[47,28,19,6],
[47,28,19,8],
[47,28,19,9],
[47,28,19,10],
[47,28,19,11],
[47,28,19,12],
[47,28,19,15],
[47,28,19,16],
[47,28,19,17],
[47,28,19,18],
[47,28,20,0],
[47,28,20,1],
[47,28,20,2],
[47,28,20,3],
[47,28,20,4],
[47,28,20,5],
[47,28,20,6],
[47,28,20,8],
[47,28,20,9],
[47,28,20,11],
[47,28,20,12],
[47,28,20,13],
[47,28,20,14],
[47,28,20,15],
[47,28,20,16],
[47,28,20,17],
[47,28,20,18],
[47,28,20,19],
[47,28,21,0],
[47,28,21,1],
[47,28,21,2],
[47,28,21,4],
[47,28,21,9],
[47,28,21,10],
[47,28,21,11],
[47,28,21,12],
[47,28,21,13],
[47,28,21,14],
[47,28,21,15],
[47,28,21,16],
[47,28,21,17],
[47,28,21,18],
[47,28,21,19],
[47,28,21,20],
[47,28,22,0],
[47,28,22,1],
[47,28,22,2],
[47,28,22,3],
[47,28,22,4],
[47,28,22,5],
[47,28,22,6],
[47,28,22,8],
[47,28,22,9],
[47,28,22,11],
[47,28,22,12],
[47,28,22,13],
[47,28,22,14],
[47,28,22,15],
[47,28,22,16],
[47,28,22,17],
[47,28,22,18],
[47,28,22,19],
[47,28,22,21],
[47,28,24,0],
[47,28,24,1],
[47,28,24,2],
[47,28,24,3],
[47,28,24,4],
[47,28,24,5],
[47,28,24,6],
[47,28,24,8],
[47,28,24,9],
[47,28,24,10],
[47,28,24,11],
[47,28,24,12],
[47,28,24,13],
[47,28,24,14],
[47,28,24,15],
[47,28,24,17],
[47,28,24,18],
[47,28,24,19],
[47,28,24,20],
[47,28,24,21],
[47,28,24,22],
[47,28,26,0],
[47,28,26,1],
[47,28,26,2],
[47,28,26,3],
[47,28,26,4],
[47,28,26,5],
[47,28,26,6],
[47,28,26,8],
[47,28,26,10],
[47,28,26,11],
[47,28,26,12],
[47,28,26,13],
[47,28,26,14],
[47,28,26,16],
[47,28,26,17],
[47,28,26,19],
[47,28,26,20],
[47,28,26,21],
[47,28,26,22],
[47,28,26,24],
[47,28,27,0],
[47,28,27,1],
[47,28,27,2],
[47,28,27,3],
[47,28,27,4],
[47,28,27,5],
[47,28,27,6],
[47,28,27,8],
[47,28,27,9],
[47,28,27,11],
[47,28,27,12],
[47,28,27,13],
[47,28,27,14],
[47,28,27,15],
[47,28,27,16],
[47,28,27,17],
[47,28,27,18],
[47,28,27,19],
[47,28,27,21],
[47,28,27,24],
[47,29,3,0],
[47,29,3,1],
[47,29,3,2],
[47,29,4,0],
[47,29,4,1],
[47,29,4,2],
[47,29,4,3],
[47,29,5,0],
[47,29,5,1],
[47,29,5,2],
[47,29,5,4],
[47,29,6,0],
[47,29,6,1],
[47,29,6,2],
[47,29,6,4],
[47,29,7,0],
[47,29,7,1],
[47,29,7,2],
[47,29,7,3],
[47,29,7,4],
[47,29,7,5],
[47,29,7,6],
[47,29,8,0],
[47,29,8,1],
[47,29,8,2],
[47,29,8,4],
[47,29,8,7],
[47,29,9,0],
[47,29,9,1],
[47,29,9,2],
[47,29,9,3],
[47,29,9,4],
[47,29,9,5],
[47,29,9,6],
[47,29,9,7],
[47,29,9,8],
[47,29,10,0],
[47,29,10,1],
[47,29,10,2],
[47,29,10,3],
[47,29,10,4],
[47,29,10,5],
[47,29,10,6],
[47,29,10,7],
[47,29,10,8],
[47,29,10,9],
[47,29,13,0],
[47,29,13,1],
[47,29,13,2],
[47,29,13,3],
[47,29,13,4],
[47,29,13,5],
[47,29,13,6],
[47,29,13,7],
[47,29,13,8],
[47,29,13,9],
[47,29,13,10],
[47,29,14,0],
[47,29,14,1],
[47,29,14,2],
[47,29,14,3],
[47,29,14,4],
[47,29,14,5],
[47,29,14,6],
[47,29,14,7],
[47,29,14,8],
[47,29,14,9],
[47,29,14,10],
[47,29,15,0],
[47,29,15,1],
[47,29,15,2],
[47,29,15,3],
[47,29,15,4],
[47,29,15,5],
[47,29,15,6],
[47,29,15,7],
[47,29,15,8],
[47,29,15,10],
[47,29,15,13],
[47,29,15,14],
[47,29,16,0],
[47,29,16,1],
[47,29,16,2],
[47,29,16,3],
[47,29,16,4],
[47,29,16,5],
[47,29,16,6],
[47,29,16,7],
[47,29,16,8],
[47,29,16,9],
[47,29,16,10],
[47,29,16,13],
[47,29,16,14],
[47,29,16,15],
[47,29,17,0],
[47,29,17,1],
[47,29,17,2],
[47,29,17,3],
[47,29,17,4],
[47,29,17,5],
[47,29,17,6],
[47,29,17,7],
[47,29,17,8],
[47,29,17,9],
[47,29,17,10],
[47,29,17,13],
[47,29,17,14],
[47,29,17,15],
[47,29,17,16],
[47,29,18,0],
[47,29,18,1],
[47,29,18,2],
[47,29,18,3],
[47,29,18,4],
[47,29,18,5],
[47,29,18,6],
[47,29,18,7],
[47,29,18,8],
[47,29,18,10],
[47,29,18,13],
[47,29,18,14],
[47,29,18,16],
[47,29,18,17],
[47,29,19,0],
[47,29,19,1],
[47,29,19,2],
[47,29,19,3],
[47,29,19,4],
[47,29,19,5],
[47,29,19,6],
[47,29,19,7],
[47,29,19,8],
[47,29,19,9],
[47,29,19,10],
[47,29,19,15],
[47,29,19,16],
[47,29,19,17],
[47,29,19,18],
[47,29,20,0],
[47,29,20,1],
[47,29,20,2],
[47,29,20,3],
[47,29,20,4],
[47,29,20,5],
[47,29,20,6],
[47,29,20,7],
[47,29,20,8],
[47,29,20,9],
[47,29,20,13],
[47,29,20,14],
[47,29,20,15],
[47,29,20,16],
[47,29,20,17],
[47,29,20,18],
[47,29,20,19],
[47,29,21,0],
[47,29,21,1],
[47,29,21,2],
[47,29,21,4],
[47,29,21,7],
[47,29,21,9],
[47,29,21,10],
[47,29,21,13],
[47,29,21,14],
[47,29,21,15],
[47,29,21,16],
[47,29,21,17],
[47,29,21,18],
[47,29,21,19],
[47,29,21,20],
[47,29,22,0],
[47,29,22,1],
[47,29,22,2],
[47,29,22,3],
[47,29,22,4],
[47,29,22,5],
[47,29,22,6],
[47,29,22,7],
[47,29,22,8],
[47,29,22,9],
[47,29,22,13],
[47,29,22,14],
[47,29,22,15],
[47,29,22,16],
[47,29,22,17],
[47,29,22,18],
[47,29,22,19],
[47,29,22,21],
[47,29,24,0],
[47,29,24,1],
[47,29,24,2],
[47,29,24,3],
[47,29,24,4],
[47,29,24,5],
[47,29,24,6],
[47,29,24,7],
[47,29,24,8],
[47,29,24,9],
[47,29,24,10],
[47,29,24,13],
[47,29,24,14],
[47,29,24,15],
[47,29,24,17],
[47,29,24,18],
[47,29,24,19],
[47,29,24,20],
[47,29,24,21],
[47,29,24,22],
[47,29,25,0],
[47,29,25,1],
[47,29,25,2],
[47,29,25,3],
[47,29,25,4],
[47,29,25,5],
[47,29,25,6],
[47,29,25,8],
[47,29,25,9],
[47,29,25,10],
[47,29,25,13],
[47,29,25,14],
[47,29,25,15],
[47,29,25,16],
[47,29,25,17],
[47,29,25,18],
[47,29,25,19],
[47,29,25,20],
[47,29,25,21],
[47,29,25,22],
[47,29,25,24],
[47,29,26,0],
[47,29,26,1],
[47,29,26,2],
[47,29,26,3],
[47,29,26,4],
[47,29,26,5],
[47,29,26,6],
[47,29,26,7],
[47,29,26,8],
[47,29,26,10],
[47,29,26,13],
[47,29,26,14],
[47,29,26,16],
[47,29,26,17],
[47,29,26,19],
[47,29,26,20],
[47,29,26,21],
[47,29,26,22],
[47,29,26,24],
[47,29,26,25],
[47,29,27,0],
[47,29,27,1],
[47,29,27,2],
[47,29,27,3],
[47,29,27,4],
[47,29,27,5],
[47,29,27,6],
[47,29,27,7],
[47,29,27,8],
[47,29,27,9],
[47,29,27,13],
[47,29,27,14],
[47,29,27,15],
[47,29,27,16],
[47,29,27,17],
[47,29,27,18],
[47,29,27,19],
[47,29,27,21],
[47,29,27,24],
[47,29,27,26],
[47,29,28,0],
[47,29,28,1],
[47,29,28,2],
[47,29,28,3],
[47,29,28,4],
[47,29,28,5],
[47,29,28,6],
[47,29,28,8],
[47,29,28,9],
[47,29,28,10],
[47,29,28,13],
[47,29,28,14],
[47,29,28,15],
[47,29,28,16],
[47,29,28,17],
[47,29,28,18],
[47,29,28,19],
[47,29,28,20],
[47,29,28,21],
[47,29,28,22],
[47,29,28,24],
[47,29,28,26],
[47,29,28,27],
[47,32,3,0],
[47,32,3,1],
[47,32,3,2],
[47,32,4,0],
[47,32,4,1],
[47,32,4,2],
[47,32,4,3],
[47,32,5,0],
[47,32,5,1],
[47,32,5,2],
[47,32,5,4],
[47,32,6,0],
[47,32,6,1],
[47,32,6,2],
[47,32,6,4],
[47,32,7,0],
[47,32,7,1],
[47,32,7,2],
[47,32,7,3],
[47,32,7,4],
[47,32,7,5],
[47,32,7,6],
[47,32,8,0],
[47,32,8,1],
[47,32,8,2],
[47,32,8,4],
[47,32,8,7],
[47,32,9,0],
[47,32,9,1],
[47,32,9,2],
[47,32,9,3],
[47,32,9,4],
[47,32,9,5],
[47,32,9,6],
[47,32,9,7],
[47,32,9,8],
[47,32,10,0],
[47,32,10,1],
[47,32,10,2],
[47,32,10,3],
[47,32,10,4],
[47,32,10,5],
[47,32,10,6],
[47,32,10,7],
[47,32,10,8],
[47,32,10,9],
[47,32,13,0],
[47,32,13,1],
[47,32,13,2],
[47,32,13,3],
[47,32,13,4],
[47,32,13,5],
[47,32,13,6],
[47,32,13,7],
[47,32,13,8],
[47,32,13,9],
[47,32,13,10],
[47,32,14,0],
[47,32,14,1],
[47,32,14,2],
[47,32,14,3],
[47,32,14,4],
[47,32,14,5],
[47,32,14,6],
[47,32,14,7],
[47,32,14,8],
[47,32,14,9],
[47,32,14,10],
[47,32,15,0],
[47,32,15,1],
[47,32,15,2],
[47,32,15,3],
[47,32,15,4],
[47,32,15,5],
[47,32,15,6],
[47,32,15,7],
[47,32,15,8],
[47,32,15,10],
[47,32,15,13],
[47,32,15,14],
[47,32,16,0],
[47,32,16,1],
[47,32,16,2],
[47,32,16,3],
[47,32,16,4],
[47,32,16,5],
[47,32,16,6],
[47,32,16,7],
[47,32,16,8],
[47,32,16,9],
[47,32,16,10],
[47,32,16,13],
[47,32,16,14],
[47,32,16,15],
[47,32,17,0],
[47,32,17,1],
[47,32,17,2],
[47,32,17,3],
[47,32,17,4],
[47,32,17,5],
[47,32,17,6],
[47,32,17,7],
[47,32,17,8],
[47,32,17,9],
[47,32,17,10],
[47,32,17,13],
[47,32,17,14],
[47,32,17,15],
[47,32,17,16],
[47,32,18,0],
[47,32,18,1],
[47,32,18,2],
[47,32,18,3],
[47,32,18,4],
[47,32,18,5],
[47,32,18,6],
[47,32,18,7],
[47,32,18,8],
[47,32,18,10],
[47,32,18,13],
[47,32,18,14],
[47,32,18,16],
[47,32,18,17],
[47,32,19,0],
[47,32,19,1],
[47,32,19,2],
[47,32,19,3],
[47,32,19,4],
[47,32,19,5],
[47,32,19,6],
[47,32,19,7],
[47,32,19,8],
[47,32,19,9],
[47,32,19,10],
[47,32,19,15],
[47,32,19,16],
[47,32,19,17],
[47,32,19,18],
[47,32,20,0],
[47,32,20,1],
[47,32,20,2],
[47,32,20,3],
[47,32,20,4],
[47,32,20,5],
[47,32,20,6],
[47,32,20,7],
[47,32,20,8],
[47,32,20,9],
[47,32,20,13],
[47,32,20,14],
[47,32,20,15],
[47,32,20,16],
[47,32,20,17],
[47,32,20,18],
[47,32,20,19],
[47,32,21,0],
[47,32,21,1],
[47,32,21,2],
[47,32,21,4],
[47,32,21,7],
[47,32,21,9],
[47,32,21,10],
[47,32,21,13],
[47,32,21,14],
[47,32,21,15],
[47,32,21,16],
[47,32,21,17],
[47,32,21,18],
[47,32,21,19],
[47,32,21,20],
[47,32,22,0],
[47,32,22,1],
[47,32,22,2],
[47,32,22,3],
[47,32,22,4],
[47,32,22,5],
[47,32,22,6],
[47,32,22,7],
[47,32,22,8],
[47,32,22,9],
[47,32,22,13],
[47,32,22,14],
[47,32,22,15],
[47,32,22,16],
[47,32,22,17],
[47,32,22,18],
[47,32,22,19],
[47,32,22,21],
[47,32,24,0],
[47,32,24,1],
[47,32,24,2],
[47,32,24,3],
[47,32,24,4],
[47,32,24,5],
[47,32,24,6],
[47,32,24,7],
[47,32,24,8],
[47,32,24,9],
[47,32,24,10],
[47,32,24,13],
[47,32,24,14],
[47,32,24,15],
[47,32,24,17],
[47,32,24,18],
[47,32,24,19],
[47,32,24,20],
[47,32,24,21],
[47,32,24,22],
[47,32,25,0],
[47,32,25,1],
[47,32,25,2],
[47,32,25,3],
[47,32,25,4],
[47,32,25,5],
[47,32,25,6],
[47,32,25,8],
[47,32,25,9],
[47,32,25,10],
[47,32,25,13],
[47,32,25,14],
[47,32,25,15],
[47,32,25,16],
[47,32,25,17],
[47,32,25,18],
[47,32,25,19],
[47,32,25,20],
[47,32,25,21],
[47,32,25,22],
[47,32,25,24],
[47,32,26,0],
[47,32,26,1],
[47,32,26,2],
[47,32,26,3],
[47,32,26,4],
[47,32,26,5],
[47,32,26,6],
[47,32,26,7],
[47,32,26,8],
[47,32,26,10],
[47,32,26,13],
[47,32,26,14],
[47,32,26,16],
[47,32,26,17],
[47,32,26,19],
[47,32,26,20],
[47,32,26,21],
[47,32,26,22],
[47,32,26,25],
[47,32,27,0],
[47,32,27,1],
[47,32,27,2],
[47,32,27,3],
[47,32,27,4],
[47,32,27,5],
[47,32,27,6],
[47,32,27,7],
[47,32,27,8],
[47,32,27,9],
[47,32,27,13],
[47,32,27,14],
[47,32,27,15],
[47,32,27,16],
[47,32,27,17],
[47,32,27,18],
[47,32,27,19],
[47,32,27,21],
[47,32,27,24],
[47,32,27,25],
[47,32,27,26],
[47,32,28,0],
[47,32,28,1],
[47,32,28,2],
[47,32,28,3],
[47,32,28,4],
[47,32,28,5],
[47,32,28,6],
[47,32,28,8],
[47,32,28,9],
[47,32,28,10],
[47,32,28,13],
[47,32,28,14],
[47,32,28,15],
[47,32,28,16],
[47,32,28,17],
[47,32,28,18],
[47,32,28,19],
[47,32,28,20],
[47,32,28,22],
[47,32,28,24],
[47,32,28,26],
[47,32,28,27],
[47,33,3,0],
[47,33,3,1],
[47,33,3,2],
[47,33,4,0],
[47,33,4,1],
[47,33,4,2],
[47,33,4,3],
[47,33,5,0],
[47,33,5,1],
[47,33,5,2],
[47,33,5,4],
[47,33,6,0],
[47,33,6,1],
[47,33,6,2],
[47,33,6,4],
[47,33,7,0],
[47,33,7,1],
[47,33,7,2],
[47,33,7,3],
[47,33,7,4],
[47,33,7,5],
[47,33,7,6],
[47,33,8,0],
[47,33,8,1],
[47,33,8,2],
[47,33,8,4],
[47,33,8,7],
[47,33,9,0],
[47,33,9,1],
[47,33,9,2],
[47,33,9,3],
[47,33,9,4],
[47,33,9,5],
[47,33,9,6],
[47,33,9,7],
[47,33,9,8],
[47,33,10,0],
[47,33,10,1],
[47,33,10,2],
[47,33,10,3],
[47,33,10,4],
[47,33,10,5],
[47,33,10,6],
[47,33,10,7],
[47,33,10,8],
[47,33,10,9],
[47,33,13,0],
[47,33,13,1],
[47,33,13,2],
[47,33,13,3],
[47,33,13,4],
[47,33,13,5],
[47,33,13,6],
[47,33,13,7],
[47,33,13,8],
[47,33,13,9],
[47,33,13,10],
[47,33,14,0],
[47,33,14,1],
[47,33,14,2],
[47,33,14,3],
[47,33,14,4],
[47,33,14,5],
[47,33,14,6],
[47,33,14,7],
[47,33,14,8],
[47,33,14,9],
[47,33,14,10],
[47,33,15,0],
[47,33,15,1],
[47,33,15,2],
[47,33,15,3],
[47,33,15,4],
[47,33,15,5],
[47,33,15,6],
[47,33,15,7],
[47,33,15,8],
[47,33,15,10],
[47,33,15,13],
[47,33,15,14],
[47,33,16,0],
[47,33,16,1],
[47,33,16,2],
[47,33,16,3],
[47,33,16,4],
[47,33,16,5],
[47,33,16,6],
[47,33,16,7],
[47,33,16,8],
[47,33,16,9],
[47,33,16,10],
[47,33,16,13],
[47,33,16,14],
[47,33,16,15],
[47,33,17,0],
[47,33,17,1],
[47,33,17,2],
[47,33,17,3],
[47,33,17,4],
[47,33,17,5],
[47,33,17,6],
[47,33,17,7],
[47,33,17,8],
[47,33,17,9],
[47,33,17,10],
[47,33,17,13],
[47,33,17,14],
[47,33,17,15],
[47,33,17,16],
[47,33,18,0],
[47,33,18,1],
[47,33,18,2],
[47,33,18,3],
[47,33,18,4],
[47,33,18,5],
[47,33,18,6],
[47,33,18,7],
[47,33,18,8],
[47,33,18,10],
[47,33,18,13],
[47,33,18,14],
[47,33,18,16],
[47,33,18,17],
[47,33,19,0],
[47,33,19,1],
[47,33,19,2],
[47,33,19,3],
[47,33,19,4],
[47,33,19,5],
[47,33,19,6],
[47,33,19,7],
[47,33,19,8],
[47,33,19,9],
[47,33,19,10],
[47,33,19,15],
[47,33,19,16],
[47,33,19,17],
[47,33,19,18],
[47,33,20,0],
[47,33,20,1],
[47,33,20,2],
[47,33,20,3],
[47,33,20,4],
[47,33,20,5],
[47,33,20,6],
[47,33,20,7],
[47,33,20,8],
[47,33,20,9],
[47,33,20,13],
[47,33,20,14],
[47,33,20,15],
[47,33,20,16],
[47,33,20,17],
[47,33,20,18],
[47,33,20,19],
[47,33,21,0],
[47,33,21,1],
[47,33,21,2],
[47,33,21,4],
[47,33,21,7],
[47,33,21,9],
[47,33,21,10],
[47,33,21,13],
[47,33,21,14],
[47,33,21,15],
[47,33,21,16],
[47,33,21,17],
[47,33,21,18],
[47,33,21,19],
[47,33,21,20],
[47,33,22,0],
[47,33,22,1],
[47,33,22,2],
[47,33,22,3],
[47,33,22,4],
[47,33,22,5],
[47,33,22,6],
[47,33,22,7],
[47,33,22,8],
[47,33,22,9],
[47,33,22,13],
[47,33,22,14],
[47,33,22,15],
[47,33,22,16],
[47,33,22,17],
[47,33,22,18],
[47,33,22,19],
[47,33,22,21],
[47,33,24,0],
[47,33,24,1],
[47,33,24,2],
[47,33,24,3],
[47,33,24,4],
[47,33,24,5],
[47,33,24,6],
[47,33,24,7],
[47,33,24,8],
[47,33,24,9],
[47,33,24,10],
[47,33,24,13],
[47,33,24,14],
[47,33,24,15],
[47,33,24,17],
[47,33,24,18],
[47,33,24,19],
[47,33,24,20],
[47,33,24,21],
[47,33,24,22],
[47,33,25,0],
[47,33,25,1],
[47,33,25,2],
[47,33,25,3],
[47,33,25,4],
[47,33,25,5],
[47,33,25,6],
[47,33,25,8],
[47,33,25,9],
[47,33,25,10],
[47,33,25,13],
[47,33,25,14],
[47,33,25,15],
[47,33,25,16],
[47,33,25,17],
[47,33,25,18],
[47,33,25,19],
[47,33,25,20],
[47,33,25,21],
[47,33,25,22],
[47,33,26,0],
[47,33,26,1],
[47,33,26,2],
[47,33,26,3],
[47,33,26,4],
[47,33,26,5],
[47,33,26,6],
[47,33,26,7],
[47,33,26,8],
[47,33,26,10],
[47,33,26,13],
[47,33,26,14],
[47,33,26,16],
[47,33,26,17],
[47,33,26,19],
[47,33,26,20],
[47,33,26,21],
[47,33,26,24],
[47,33,26,25],
[47,33,27,0],
[47,33,27,1],
[47,33,27,2],
[47,33,27,3],
[47,33,27,4],
[47,33,27,5],
[47,33,27,6],
[47,33,27,7],
[47,33,27,8],
[47,33,27,9],
[47,33,27,13],
[47,33,27,14],
[47,33,27,15],
[47,33,27,16],
[47,33,27,17],
[47,33,27,18],
[47,33,27,19],
[47,33,27,24],
[47,33,27,25],
[47,33,27,26],
[47,33,28,0],
[47,33,28,1],
[47,33,28,2],
[47,33,28,3],
[47,33,28,4],
[47,33,28,5],
[47,33,28,6],
[47,33,28,8],
[47,33,28,9],
[47,33,28,10],
[47,33,28,13],
[47,33,28,14],
[47,33,28,15],
[47,33,28,16],
[47,33,28,17],
[47,33,28,18],
[47,33,28,19],
[47,33,28,21],
[47,33,28,22],
[47,33,28,24],
[47,33,28,26],
[47,33,28,27],
[47,34,3,0],
[47,34,3,1],
[47,34,3,2],
[47,34,4,0],
[47,34,4,1],
[47,34,4,2],
[47,34,4,3],
[47,34,5,0],
[47,34,5,1],
[47,34,5,2],
[47,34,5,4],
[47,34,6,0],
[47,34,6,1],
[47,34,6,2],
[47,34,6,4],
[47,34,8,0],
[47,34,8,1],
[47,34,8,2],
[47,34,8,4],
[47,34,9,0],
[47,34,9,1],
[47,34,9,2],
[47,34,9,3],
[47,34,9,4],
[47,34,9,5],
[47,34,9,6],
[47,34,9,8],
[47,34,10,0],
[47,34,10,1],
[47,34,10,2],
[47,34,10,3],
[47,34,10,4],
[47,34,10,5],
[47,34,10,6],
[47,34,10,8],
[47,34,10,9],
[47,34,11,0],
[47,34,11,1],
[47,34,11,2],
[47,34,11,3],
[47,34,11,4],
[47,34,11,5],
[47,34,11,6],
[47,34,11,8],
[47,34,11,9],
[47,34,11,10],
[47,34,12,0],
[47,34,12,1],
[47,34,12,2],
[47,34,12,3],
[47,34,12,4],
[47,34,12,5],
[47,34,12,6],
[47,34,12,8],
[47,34,12,9],
[47,34,12,10],
[47,34,13,0],
[47,34,13,1],
[47,34,13,2],
[47,34,13,3],
[47,34,13,4],
[47,34,13,5],
[47,34,13,6],
[47,34,13,8],
[47,34,13,9],
[47,34,13,10],
[47,34,13,11],
[47,34,13,12],
[47,34,14,0],
[47,34,14,1],
[47,34,14,2],
[47,34,14,3],
[47,34,14,4],
[47,34,14,5],
[47,34,14,6],
[47,34,14,8],
[47,34,14,9],
[47,34,14,10],
[47,34,14,11],
[47,34,14,12],
[47,34,15,0],
[47,34,15,1],
[47,34,15,2],
[47,34,15,3],
[47,34,15,4],
[47,34,15,5],
[47,34,15,6],
[47,34,15,8],
[47,34,15,10],
[47,34,15,11],
[47,34,15,12],
[47,34,15,13],
[47,34,15,14],
[47,34,16,0],
[47,34,16,1],
[47,34,16,2],
[47,34,16,3],
[47,34,16,4],
[47,34,16,5],
[47,34,16,6],
[47,34,16,8],
[47,34,16,9],
[47,34,16,10],
[47,34,16,11],
[47,34,16,12],
[47,34,16,13],
[47,34,16,14],
[47,34,16,15],
[47,34,17,0],
[47,34,17,1],
[47,34,17,2],
[47,34,17,3],
[47,34,17,4],
[47,34,17,5],
[47,34,17,6],
[47,34,17,8],
[47,34,17,9],
[47,34,17,10],
[47,34,17,11],
[47,34,17,12],
[47,34,17,13],
[47,34,17,14],
[47,34,17,15],
[47,34,17,16],
[47,34,18,0],
[47,34,18,1],
[47,34,18,2],
[47,34,18,3],
[47,34,18,4],
[47,34,18,5],
[47,34,18,6],
[47,34,18,8],
[47,34,18,10],
[47,34,18,11],
[47,34,18,12],
[47,34,18,13],
[47,34,18,14],
[47,34,18,16],
[47,34,18,17],
[47,34,19,0],
[47,34,19,1],
[47,34,19,2],
[47,34,19,3],
[47,34,19,4],
[47,34,19,5],
[47,34,19,6],
[47,34,19,8],
[47,34,19,9],
[47,34,19,10],
[47,34,19,11],
[47,34,19,12],
[47,34,19,15],
[47,34,19,16],
[47,34,19,17],
[47,34,19,18],
[47,34,20,0],
[47,34,20,1],
[47,34,20,2],
[47,34,20,3],
[47,34,20,4],
[47,34,20,5],
[47,34,20,6],
[47,34,20,8],
[47,34,20,9],
[47,34,20,11],
[47,34,20,12],
[47,34,20,13],
[47,34,20,14],
[47,34,20,15],
[47,34,20,16],
[47,34,20,17],
[47,34,20,18],
[47,34,20,19],
[47,34,21,0],
[47,34,21,1],
[47,34,21,2],
[47,34,21,4],
[47,34,21,9],
[47,34,21,10],
[47,34,21,11],
[47,34,21,12],
[47,34,21,13],
[47,34,21,14],
[47,34,21,15],
[47,34,21,16],
[47,34,21,17],
[47,34,21,18],
[47,34,21,19],
[47,34,21,20],
[47,34,22,0],
[47,34,22,1],
[47,34,22,2],
[47,34,22,3],
[47,34,22,4],
[47,34,22,5],
[47,34,22,6],
[47,34,22,8],
[47,34,22,9],
[47,34,22,11],
[47,34,22,12],
[47,34,22,13],
[47,34,22,14],
[47,34,22,15],
[47,34,22,16],
[47,34,22,17],
[47,34,22,18],
[47,34,22,19],
[47,34,22,21],
[47,34,24,0],
[47,34,24,1],
[47,34,24,2],
[47,34,24,3],
[47,34,24,4],
[47,34,24,5],
[47,34,24,6],
[47,34,24,8],
[47,34,24,9],
[47,34,24,10],
[47,34,24,11],
[47,34,24,12],
[47,34,24,13],
[47,34,24,14],
[47,34,24,15],
[47,34,24,17],
[47,34,24,18],
[47,34,24,19],
[47,34,24,20],
[47,34,24,21],
[47,34,24,22],
[47,34,26,0],
[47,34,26,1],
[47,34,26,2],
[47,34,26,3],
[47,34,26,4],
[47,34,26,5],
[47,34,26,6],
[47,34,26,8],
[47,34,26,10],
[47,34,26,11],
[47,34,26,12],
[47,34,26,13],
[47,34,26,14],
[47,34,26,16],
[47,34,26,17],
[47,34,26,19],
[47,34,26,20],
[47,34,26,22],
[47,34,26,24],
[47,34,27,0],
[47,34,27,1],
[47,34,27,2],
[47,34,27,3],
[47,34,27,4],
[47,34,27,5],
[47,34,27,6],
[47,34,27,8],
[47,34,27,9],
[47,34,27,11],
[47,34,27,12],
[47,34,27,13],
[47,34,27,14],
[47,34,27,15],
[47,34,27,16],
[47,34,27,17],
[47,34,27,18],
[47,34,27,19],
[47,34,27,21],
[47,34,27,24],
[47,34,27,26],
[47,34,29,0],
[47,34,29,1],
[47,34,29,2],
[47,34,29,3],
[47,34,29,4],
[47,34,29,5],
[47,34,29,6],
[47,34,29,8],
[47,34,29,9],
[47,34,29,10],
[47,34,29,13],
[47,34,29,14],
[47,34,29,15],
[47,34,29,16],
[47,34,29,17],
[47,34,29,19],
[47,34,29,20],
[47,34,29,21],
[47,34,29,22],
[47,34,29,24],
[47,34,29,26],
[47,34,29,27],
[47,34,32,0],
[47,34,32,1],
[47,34,32,2],
[47,34,32,3],
[47,34,32,4],
[47,34,32,5],
[47,34,32,6],
[47,34,32,8],
[47,34,32,9],
[47,34,32,10],
[47,34,32,13],
[47,34,32,15],
[47,34,32,16],
[47,34,32,17],
[47,34,32,18],
[47,34,32,19],
[47,34,32,20],
[47,34,32,21],
[47,34,32,22],
[47,34,32,24],
[47,34,32,26],
[47,34,32,27],
[47,34,33,0],
[47,34,33,1],
[47,34,33,2],
[47,34,33,3],
[47,34,33,4],
[47,34,33,5],
[47,34,33,6],
[47,34,33,8],
[47,34,33,9],
[47,34,33,10],
[47,34,33,14],
[47,34,33,15],
[47,34,33,16],
[47,34,33,17],
[47,34,33,18],
[47,34,33,19],
[47,34,33,20],
[47,34,33,21],
[47,34,33,22],
[47,34,33,24],
[47,34,33,26],
[47,35,3,0],
[47,35,3,1],
[47,35,3,2],
[47,35,4,0],
[47,35,4,1],
[47,35,4,2],
[47,35,4,3],
[47,35,5,0],
[47,35,5,1],
[47,35,5,2],
[47,35,5,4],
[47,35,6,0],
[47,35,6,1],
[47,35,6,2],
[47,35,6,4],
[47,35,7,0],
[47,35,7,1],
[47,35,7,2],
[47,35,7,3],
[47,35,7,4],
[47,35,7,5],
[47,35,7,6],
[47,35,8,0],
[47,35,8,1],
[47,35,8,2],
[47,35,8,4],
[47,35,8,7],
[47,35,10,0],
[47,35,10,1],
[47,35,10,2],
[47,35,10,3],
[47,35,10,4],
[47,35,10,5],
[47,35,10,6],
[47,35,10,7],
[47,35,10,8],
[47,35,11,0],
[47,35,11,1],
[47,35,11,2],
[47,35,11,3],
[47,35,11,4],
[47,35,11,5],
[47,35,11,6],
[47,35,11,7],
[47,35,11,8],
[47,35,11,10],
[47,35,12,0],
[47,35,12,1],
[47,35,12,2],
[47,35,12,3],
[47,35,12,4],
[47,35,12,5],
[47,35,12,6],
[47,35,12,7],
[47,35,12,8],
[47,35,12,10],
[47,35,13,0],
[47,35,13,1],
[47,35,13,2],
[47,35,13,3],
[47,35,13,4],
[47,35,13,5],
[47,35,13,6],
[47,35,13,7],
[47,35,13,8],
[47,35,13,10],
[47,35,13,11],
[47,35,13,12],
[47,35,14,0],
[47,35,14,1],
[47,35,14,2],
[47,35,14,3],
[47,35,14,4],
[47,35,14,5],
[47,35,14,6],
[47,35,14,7],
[47,35,14,8],
[47,35,14,10],
[47,35,14,11],
[47,35,14,12],
[47,35,16,0],
[47,35,16,1],
[47,35,16,2],
[47,35,16,3],
[47,35,16,4],
[47,35,16,5],
[47,35,16,6],
[47,35,16,7],
[47,35,16,8],
[47,35,16,10],
[47,35,16,11],
[47,35,16,12],
[47,35,16,13],
[47,35,16,14],
[47,35,17,0],
[47,35,17,1],
[47,35,17,2],
[47,35,17,3],
[47,35,17,4],
[47,35,17,5],
[47,35,17,6],
[47,35,17,7],
[47,35,17,8],
[47,35,17,10],
[47,35,17,11],
[47,35,17,12],
[47,35,17,13],
[47,35,17,14],
[47,35,17,16],
[47,35,19,0],
[47,35,19,1],
[47,35,19,2],
[47,35,19,3],
[47,35,19,4],
[47,35,19,5],
[47,35,19,6],
[47,35,19,7],
[47,35,19,8],
[47,35,19,10],
[47,35,19,11],
[47,35,19,12],
[47,35,19,16],
[47,35,19,17],
[47,35,20,0],
[47,35,20,1],
[47,35,20,2],
[47,35,20,3],
[47,35,20,4],
[47,35,20,5],
[47,35,20,6],
[47,35,20,7],
[47,35,20,8],
[47,35,20,11],
[47,35,20,12],
[47,35,20,13],
[47,35,20,14],
[47,35,20,16],
[47,35,20,17],
[47,35,20,19],
[47,35,21,0],
[47,35,21,1],
[47,35,21,2],
[47,35,21,4],
[47,35,21,7],
[47,35,21,10],
[47,35,21,11],
[47,35,21,12],
[47,35,21,13],
[47,35,21,14],
[47,35,21,16],
[47,35,21,17],
[47,35,21,19],
[47,35,21,20],
[47,35,22,0],
[47,35,22,1],
[47,35,22,2],
[47,35,22,3],
[47,35,22,4],
[47,35,22,5],
[47,35,22,6],
[47,35,22,7],
[47,35,22,8],
[47,35,22,11],
[47,35,22,12],
[47,35,22,13],
[47,35,22,14],
[47,35,22,16],
[47,35,22,17],
[47,35,22,19],
[47,35,22,21],
[47,35,24,0],
[47,35,24,1],
[47,35,24,2],
[47,35,24,3],
[47,35,24,4],
[47,35,24,5],
[47,35,24,6],
[47,35,24,7],
[47,35,24,8],
[47,35,24,10],
[47,35,24,11],
[47,35,24,12],
[47,35,24,13],
[47,35,24,14],
[47,35,24,17],
[47,35,24,19],
[47,35,24,20],
[47,35,24,21],
[47,35,24,22],
[47,35,25,0],
[47,35,25,1],
[47,35,25,2],
[47,35,25,3],
[47,35,25,4],
[47,35,25,5],
[47,35,25,6],
[47,35,25,8],
[47,35,25,10],
[47,35,25,11],
[47,35,25,12],
[47,35,25,13],
[47,35,25,14],
[47,35,25,16],
[47,35,25,17],
[47,35,25,19],
[47,35,25,20],
[47,35,25,22],
[47,35,25,24],
[47,35,27,0],
[47,35,27,1],
[47,35,27,2],
[47,35,27,3],
[47,35,27,4],
[47,35,27,5],
[47,35,27,6],
[47,35,27,7],
[47,35,27,8],
[47,35,27,11],
[47,35,27,12],
[47,35,27,13],
[47,35,27,14],
[47,35,27,16],
[47,35,27,17],
[47,35,27,21],
[47,35,27,24],
[47,35,27,25],
[47,35,28,0],
[47,35,28,1],
[47,35,28,2],
[47,35,28,3],
[47,35,28,4],
[47,35,28,5],
[47,35,28,6],
[47,35,28,8],
[47,35,28,10],
[47,35,28,11],
[47,35,28,12],
[47,35,28,13],
[47,35,28,14],
[47,35,28,16],
[47,35,28,17],
[47,35,28,19],
[47,35,28,20],
[47,35,28,21],
[47,35,28,22],
[47,35,28,24],
[47,35,28,27],
[47,35,29,0],
[47,35,29,1],
[47,35,29,2],
[47,35,29,3],
[47,35,29,4],
[47,35,29,5],
[47,35,29,6],
[47,35,29,7],
[47,35,29,8],
[47,35,29,10],
[47,35,29,13],
[47,35,29,14],
[47,35,29,19],
[47,35,29,20],
[47,35,29,21],
[47,35,29,22],
[47,35,29,24],
[47,35,29,25],
[47,35,29,27],
[47,35,29,28],
[47,35,32,0],
[47,35,32,1],
[47,35,32,2],
[47,35,32,3],
[47,35,32,4],
[47,35,32,5],
[47,35,32,6],
[47,35,32,7],
[47,35,32,8],
[47,35,32,10],
[47,35,32,14],
[47,35,32,16],
[47,35,32,17],
[47,35,32,19],
[47,35,32,20],
[47,35,32,21],
[47,35,32,22],
[47,35,32,24],
[47,35,32,25],
[47,35,33,0],
[47,35,33,1],
[47,35,33,2],
[47,35,33,3],
[47,35,33,4],
[47,35,33,5],
[47,35,33,6],
[47,35,33,7],
[47,35,33,8],
[47,35,33,13],
[47,35,33,14],
[47,35,33,16],
[47,35,33,17],
[47,35,33,19],
[47,35,33,20],
[47,35,33,21],
[47,35,33,22],
[47,35,33,24],
[47,35,33,25],
[47,35,34,0],
[47,35,34,1],
[47,35,34,2],
[47,35,34,3],
[47,35,34,4],
[47,35,34,5],
[47,35,34,6],
[47,35,34,10],
[47,35,34,11],
[47,35,34,12],
[47,35,34,13],
[47,35,34,14],
[47,35,34,16],
[47,35,34,17],
[47,35,34,19],
[47,35,34,20],
[47,35,34,21],
[47,35,34,22],
[47,35,34,24],
[47,36,3,0],
[47,36,3,1],
[47,36,3,2],
[47,36,4,0],
[47,36,4,1],
[47,36,4,2],
[47,36,4,3],
[47,36,5,0],
[47,36,5,1],
[47,36,5,2],
[47,36,5,4],
[47,36,6,0],
[47,36,6,1],
[47,36,6,2],
[47,36,6,4],
[47,36,7,0],
[47,36,7,1],
[47,36,7,2],
[47,36,7,3],
[47,36,7,4],
[47,36,7,5],
[47,36,7,6],
[47,36,8,0],
[47,36,8,1],
[47,36,8,2],
[47,36,8,4],
[47,36,8,7],
[47,36,9,0],
[47,36,9,1],
[47,36,9,2],
[47,36,9,3],
[47,36,9,4],
[47,36,9,5],
[47,36,9,6],
[47,36,9,7],
[47,36,9,8],
[47,36,10,0],
[47,36,10,1],
[47,36,10,2],
[47,36,10,3],
[47,36,10,4],
[47,36,10,5],
[47,36,10,6],
[47,36,10,7],
[47,36,10,8],
[47,36,10,9],
[47,36,11,0],
[47,36,11,1],
[47,36,11,2],
[47,36,11,3],
[47,36,11,4],
[47,36,11,5],
[47,36,11,6],
[47,36,11,7],
[47,36,11,8],
[47,36,11,9],
[47,36,11,10],
[47,36,12,0],
[47,36,12,1],
[47,36,12,2],
[47,36,12,3],
[47,36,12,4],
[47,36,12,5],
[47,36,12,6],
[47,36,12,7],
[47,36,12,8],
[47,36,12,9],
[47,36,12,10],
[47,36,15,0],
[47,36,15,1],
[47,36,15,2],
[47,36,15,3],
[47,36,15,4],
[47,36,15,5],
[47,36,15,6],
[47,36,15,7],
[47,36,15,8],
[47,36,15,10],
[47,36,15,11],
[47,36,15,12],
[47,36,16,0],
[47,36,16,1],
[47,36,16,2],
[47,36,16,3],
[47,36,16,4],
[47,36,16,5],
[47,36,16,6],
[47,36,16,7],
[47,36,16,8],
[47,36,16,9],
[47,36,16,10],
[47,36,16,11],
[47,36,16,12],
[47,36,16,15],
[47,36,17,0],
[47,36,17,1],
[47,36,17,2],
[47,36,17,3],
[47,36,17,4],
[47,36,17,5],
[47,36,17,6],
[47,36,17,7],
[47,36,17,8],
[47,36,17,9],
[47,36,17,10],
[47,36,17,11],
[47,36,17,12],
[47,36,17,15],
[47,36,17,16],
[47,36,18,0],
[47,36,18,1],
[47,36,18,2],
[47,36,18,3],
[47,36,18,4],
[47,36,18,5],
[47,36,18,6],
[47,36,18,7],
[47,36,18,8],
[47,36,18,10],
[47,36,18,11],
[47,36,18,12],
[47,36,18,16],
[47,36,18,17],
[47,36,20,0],
[47,36,20,1],
[47,36,20,2],
[47,36,20,3],
[47,36,20,4],
[47,36,20,5],
[47,36,20,6],
[47,36,20,7],
[47,36,20,8],
[47,36,20,9],
[47,36,20,11],
[47,36,20,12],
[47,36,20,15],
[47,36,20,16],
[47,36,20,17],
[47,36,20,18],
[47,36,21,0],
[47,36,21,1],
[47,36,21,2],
[47,36,21,4],
[47,36,21,7],
[47,36,21,9],
[47,36,21,10],
[47,36,21,11],
[47,36,21,12],
[47,36,21,15],
[47,36,21,16],
[47,36,21,17],
[47,36,21,18],
[47,36,21,20],
[47,36,22,0],
[47,36,22,1],
[47,36,22,2],
[47,36,22,3],
[47,36,22,4],
[47,36,22,5],
[47,36,22,6],
[47,36,22,7],
[47,36,22,8],
[47,36,22,9],
[47,36,22,11],
[47,36,22,12],
[47,36,22,15],
[47,36,22,16],
[47,36,22,17],
[47,36,22,18],
[47,36,22,21],
[47,36,24,0],
[47,36,24,1],
[47,36,24,2],
[47,36,24,3],
[47,36,24,4],
[47,36,24,5],
[47,36,24,6],
[47,36,24,7],
[47,36,24,8],
[47,36,24,9],
[47,36,24,10],
[47,36,24,11],
[47,36,24,12],
[47,36,24,15],
[47,36,24,17],
[47,36,24,18],
[47,36,24,20],
[47,36,24,21],
[47,36,25,0],
[47,36,25,1],
[47,36,25,2],
[47,36,25,3],
[47,36,25,4],
[47,36,25,5],
[47,36,25,6],
[47,36,25,8],
[47,36,25,9],
[47,36,25,10],
[47,36,25,11],
[47,36,25,12],
[47,36,25,15],
[47,36,25,16],
[47,36,25,17],
[47,36,25,18],
[47,36,25,21],
[47,36,25,22],
[47,36,25,24],
[47,36,26,0],
[47,36,26,1],
[47,36,26,2],
[47,36,26,3],
[47,36,26,4],
[47,36,26,5],
[47,36,26,6],
[47,36,26,7],
[47,36,26,8],
[47,36,26,10],
[47,36,26,11],
[47,36,26,12],
[47,36,26,16],
[47,36,26,17],
[47,36,26,20],
[47,36,26,21],
[47,36,26,22],
[47,36,26,24],
[47,36,26,25],
[47,36,27,0],
[47,36,27,1],
[47,36,27,2],
[47,36,27,3],
[47,36,27,4],
[47,36,27,5],
[47,36,27,6],
[47,36,27,7],
[47,36,27,8],
[47,36,27,9],
[47,36,27,11],
[47,36,27,12],
[47,36,27,15],
[47,36,27,16],
[47,36,27,17],
[47,36,27,21],
[47,36,27,24],
[47,36,27,25],
[47,36,27,26],
[47,36,28,0],
[47,36,28,1],
[47,36,28,2],
[47,36,28,3],
[47,36,28,4],
[47,36,28,5],
[47,36,28,6],
[47,36,28,8],
[47,36,28,9],
[47,36,28,10],
[47,36,28,11],
[47,36,28,12],
[47,36,28,15],
[47,36,28,18],
[47,36,28,20],
[47,36,28,21],
[47,36,28,22],
[47,36,28,24],
[47,36,28,26],
[47,36,28,27],
[47,36,29,0],
[47,36,29,1],
[47,36,29,2],
[47,36,29,3],
[47,36,29,4],
[47,36,29,5],
[47,36,29,6],
[47,36,29,7],
[47,36,29,8],
[47,36,29,9],
[47,36,29,10],
[47,36,29,16],
[47,36,29,17],
[47,36,29,18],
[47,36,29,20],
[47,36,29,21],
[47,36,29,22],
[47,36,29,24],
[47,36,29,25],
[47,36,29,26],
[47,36,29,27],
[47,36,29,28],
[47,36,32,0],
[47,36,32,1],
[47,36,32,2],
[47,36,32,3],
[47,36,32,4],
[47,36,32,5],
[47,36,32,6],
[47,36,32,7],
[47,36,32,8],
[47,36,32,9],
[47,36,32,15],
[47,36,32,16],
[47,36,32,17],
[47,36,32,18],
[47,36,32,20],
[47,36,32,21],
[47,36,32,22],
[47,36,32,24],
[47,36,32,25],
[47,36,33,0],
[47,36,33,1],
[47,36,33,2],
[47,36,33,3],
[47,36,33,4],
[47,36,33,5],
[47,36,33,6],
[47,36,33,7],
[47,36,33,10],
[47,36,33,15],
[47,36,33,16],
[47,36,33,17],
[47,36,33,18],
[47,36,33,20],
[47,36,33,21],
[47,36,33,22],
[47,36,33,24],
[47,36,34,0],
[47,36,34,1],
[47,36,34,2],
[47,36,34,3],
[47,36,34,4],
[47,36,34,5],
[47,36,34,8],
[47,36,34,9],
[47,36,34,10],
[47,36,34,11],
[47,36,34,12],
[47,36,34,15],
[47,36,34,16],
[47,36,34,17],
[47,36,34,18],
[47,36,34,20],
[47,36,34,21],
[47,36,34,22],
[47,36,34,24],
[47,36,35,0],
[47,36,35,1],
[47,36,35,2],
[47,36,35,3],
[47,36,35,4],
[47,36,35,6],
[47,36,35,7],
[47,36,35,8],
[47,36,35,10],
[47,36,35,11],
[47,36,35,12],
[47,36,35,16],
[47,36,35,17],
[47,36,35,20],
[47,36,35,21],
[47,36,35,22],
[47,37,3,0],
[47,37,3,1],
[47,37,3,2],
[47,37,5,0],
[47,37,5,1],
[47,37,5,2],
[47,37,6,0],
[47,37,6,1],
[47,37,6,2],
[47,37,7,0],
[47,37,7,1],
[47,37,7,2],
[47,37,7,3],
[47,37,7,5],
[47,37,7,6],
[47,37,8,0],
[47,37,8,1],
[47,37,8,2],
[47,37,8,7],
[47,37,9,0],
[47,37,9,1],
[47,37,9,2],
[47,37,9,3],
[47,37,9,5],
[47,37,9,6],
[47,37,9,7],
[47,37,9,8],
[47,37,10,0],
[47,37,10,1],
[47,37,10,2],
[47,37,10,3],
[47,37,10,5],
[47,37,10,6],
[47,37,10,7],
[47,37,10,8],
[47,37,10,9],
[47,37,11,0],
[47,37,11,1],
[47,37,11,2],
[47,37,11,3],
[47,37,11,5],
[47,37,11,6],
[47,37,11,7],
[47,37,11,8],
[47,37,11,9],
[47,37,11,10],
[47,37,12,0],
[47,37,12,1],
[47,37,12,2],
[47,37,12,3],
[47,37,12,5],
[47,37,12,6],
[47,37,12,7],
[47,37,12,8],
[47,37,12,9],
[47,37,12,10],
[47,37,13,0],
[47,37,13,1],
[47,37,13,2],
[47,37,13,3],
[47,37,13,5],
[47,37,13,6],
[47,37,13,7],
[47,37,13,8],
[47,37,13,9],
[47,37,13,10],
[47,37,13,11],
[47,37,13,12],
[47,37,14,0],
[47,37,14,1],
[47,37,14,2],
[47,37,14,3],
[47,37,14,5],
[47,37,14,6],
[47,37,14,7],
[47,37,14,8],
[47,37,14,9],
[47,37,14,10],
[47,37,14,11],
[47,37,14,12],
[47,37,15,0],
[47,37,15,1],
[47,37,15,2],
[47,37,15,3],
[47,37,15,5],
[47,37,15,6],
[47,37,15,7],
[47,37,15,8],
[47,37,15,10],
[47,37,15,11],
[47,37,15,12],
[47,37,15,13],
[47,37,15,14],
[47,37,16,0],
[47,37,16,1],
[47,37,16,2],
[47,37,16,3],
[47,37,16,5],
[47,37,16,6],
[47,37,16,7],
[47,37,16,8],
[47,37,16,9],
[47,37,16,10],
[47,37,16,11],
[47,37,16,12],
[47,37,16,13],
[47,37,16,14],
[47,37,16,15],
[47,37,17,0],
[47,37,17,1],
[47,37,17,2],
[47,37,17,3],
[47,37,17,5],
[47,37,17,6],
[47,37,17,7],
[47,37,17,8],
[47,37,17,9],
[47,37,17,10],
[47,37,17,11],
[47,37,17,12],
[47,37,17,13],
[47,37,17,14],
[47,37,17,15],
[47,37,17,16],
[47,37,18,0],
[47,37,18,1],
[47,37,18,2],
[47,37,18,3],
[47,37,18,5],
[47,37,18,6],
[47,37,18,7],
[47,37,18,8],
[47,37,18,10],
[47,37,18,11],
[47,37,18,12],
[47,37,18,13],
[47,37,18,14],
[47,37,18,16],
[47,37,18,17],
[47,37,19,0],
[47,37,19,1],
[47,37,19,2],
[47,37,19,3],
[47,37,19,5],
[47,37,19,6],
[47,37,19,7],
[47,37,19,8],
[47,37,19,9],
[47,37,19,10],
[47,37,19,11],
[47,37,19,12],
[47,37,19,15],
[47,37,19,16],
[47,37,19,17],
[47,37,19,18],
[47,37,20,0],
[47,37,20,1],
[47,37,20,2],
[47,37,20,3],
[47,37,20,5],
[47,37,20,6],
[47,37,20,7],
[47,37,20,8],
[47,37,20,9],
[47,37,20,11],
[47,37,20,12],
[47,37,20,13],
[47,37,20,14],
[47,37,20,15],
[47,37,20,16],
[47,37,20,17],
[47,37,20,18],
[47,37,20,19],
[47,37,21,0],
[47,37,21,1],
[47,37,21,2],
[47,37,21,7],
[47,37,21,9],
[47,37,21,10],
[47,37,21,11],
[47,37,21,12],
[47,37,21,13],
[47,37,21,14],
[47,37,21,15],
[47,37,21,16],
[47,37,21,17],
[47,37,21,18],
[47,37,21,19],
[47,37,21,20],
[47,37,22,0],
[47,37,22,1],
[47,37,22,2],
[47,37,22,3],
[47,37,22,5],
[47,37,22,6],
[47,37,22,7],
[47,37,22,8],
[47,37,22,9],
[47,37,22,11],
[47,37,22,12],
[47,37,22,13],
[47,37,22,14],
[47,37,22,15],
[47,37,22,16],
[47,37,22,17],
[47,37,22,18],
[47,37,22,19],
[47,37,22,21],
[47,37,24,0],
[47,37,24,1],
[47,37,24,2],
[47,37,24,3],
[47,37,24,5],
[47,37,24,6],
[47,37,24,7],
[47,37,24,8],
[47,37,24,9],
[47,37,24,10],
[47,37,24,11],
[47,37,24,12],
[47,37,24,13],
[47,37,24,14],
[47,37,24,15],
[47,37,24,17],
[47,37,24,18],
[47,37,24,19],
[47,37,24,20],
[47,37,24,22],
[47,37,25,0],
[47,37,25,1],
[47,37,25,2],
[47,37,25,3],
[47,37,25,5],
[47,37,25,6],
[47,37,25,8],
[47,37,25,9],
[47,37,25,10],
[47,37,25,11],
[47,37,25,12],
[47,37,25,13],
[47,37,25,14],
[47,37,25,15],
[47,37,25,16],
[47,37,25,17],
[47,37,25,18],
[47,37,25,20],
[47,37,25,21],
[47,37,25,22],
[47,37,25,24],
[47,37,26,0],
[47,37,26,1],
[47,37,26,2],
[47,37,26,3],
[47,37,26,5],
[47,37,26,6],
[47,37,26,7],
[47,37,26,8],
[47,37,26,10],
[47,37,26,11],
[47,37,26,12],
[47,37,26,13],
[47,37,26,14],
[47,37,26,16],
[47,37,26,17],
[47,37,26,19],
[47,37,26,20],
[47,37,26,21],
[47,37,26,22],
[47,37,26,24],
[47,37,26,25],
[47,37,27,0],
[47,37,27,1],
[47,37,27,2],
[47,37,27,3],
[47,37,27,5],
[47,37,27,6],
[47,37,27,7],
[47,37,27,8],
[47,37,27,9],
[47,37,27,11],
[47,37,27,12],
[47,37,27,13],
[47,37,27,14],
[47,37,27,15],
[47,37,27,18],
[47,37,27,19],
[47,37,27,21],
[47,37,27,24],
[47,37,27,25],
[47,37,27,26],
[47,37,28,0],
[47,37,28,1],
[47,37,28,2],
[47,37,28,3],
[47,37,28,5],
[47,37,28,6],
[47,37,28,8],
[47,37,28,9],
[47,37,28,10],
[47,37,28,11],
[47,37,28,12],
[47,37,28,13],
[47,37,28,14],
[47,37,28,16],
[47,37,28,17],
[47,37,28,18],
[47,37,28,19],
[47,37,28,20],
[47,37,28,21],
[47,37,28,22],
[47,37,28,24],
[47,37,28,26],
[47,37,28,27],
[47,37,29,0],
[47,37,29,1],
[47,37,29,2],
[47,37,29,3],
[47,37,29,5],
[47,37,29,6],
[47,37,29,7],
[47,37,29,8],
[47,37,29,9],
[47,37,29,10],
[47,37,29,13],
[47,37,29,15],
[47,37,29,16],
[47,37,29,17],
[47,37,29,18],
[47,37,29,19],
[47,37,29,20],
[47,37,29,21],
[47,37,29,22],
[47,37,29,24],
[47,37,29,25],
[47,37,29,26],
[47,37,29,27],
[47,37,32,0],
[47,37,32,1],
[47,37,32,2],
[47,37,32,3],
[47,37,32,5],
[47,37,32,6],
[47,37,32,7],
[47,37,32,10],
[47,37,32,13],
[47,37,32,14],
[47,37,32,15],
[47,37,32,16],
[47,37,32,17],
[47,37,32,18],
[47,37,32,19],
[47,37,32,20],
[47,37,32,21],
[47,37,32,22],
[47,37,32,24],
[47,37,33,0],
[47,37,33,1],
[47,37,33,2],
[47,37,33,3],
[47,37,33,5],
[47,37,33,8],
[47,37,33,9],
[47,37,33,10],
[47,37,33,13],
[47,37,33,14],
[47,37,33,15],
[47,37,33,16],
[47,37,33,17],
[47,37,33,18],
[47,37,33,19],
[47,37,33,20],
[47,37,33,21],
[47,37,33,22],
[47,37,33,24],
[47,37,34,0],
[47,37,34,1],
[47,37,34,2],
[47,37,34,3],
[47,37,34,6],
[47,37,34,8],
[47,37,34,9],
[47,37,34,10],
[47,37,34,11],
[47,37,34,12],
[47,37,34,13],
[47,37,34,14],
[47,37,34,15],
[47,37,34,16],
[47,37,34,17],
[47,37,34,18],
[47,37,34,19],
[47,37,34,20],
[47,37,34,21],
[47,37,34,22],
[47,37,35,0],
[47,37,35,1],
[47,37,35,2],
[47,37,35,5],
[47,37,35,6],
[47,37,35,7],
[47,37,35,8],
[47,37,35,10],
[47,37,35,11],
[47,37,35,12],
[47,37,35,13],
[47,37,35,14],
[47,37,35,16],
[47,37,35,17],
[47,37,35,19],
[47,37,35,20],
[47,37,35,21],
[47,37,36,3],
[47,37,36,5],
[47,37,36,6],
[47,37,36,7],
[47,37,36,8],
[47,37,36,9],
[47,37,36,10],
[47,37,36,11],
[47,37,36,12],
[47,37,36,15],
[47,37,36,16],
[47,37,36,17],
[47,37,36,18],
[47,37,36,20],
[47,38,3,0],
[47,38,3,1],
[47,38,3,2],
[47,38,4,0],
[47,38,4,1],
[47,38,4,2],
[47,38,4,3],
[47,38,5,0],
[47,38,5,1],
[47,38,5,2],
[47,38,5,4],
[47,38,6,0],
[47,38,6,1],
[47,38,6,2],
[47,38,6,4],
[47,38,8,0],
[47,38,8,1],
[47,38,8,2],
[47,38,8,4],
[47,38,9,0],
[47,38,9,1],
[47,38,9,2],
[47,38,9,3],
[47,38,9,4],
[47,38,9,5],
[47,38,9,6],
[47,38,9,8],
[47,38,10,0],
[47,38,10,1],
[47,38,10,2],
[47,38,10,3],
[47,38,10,4],
[47,38,10,5],
[47,38,10,6],
[47,38,10,8],
[47,38,10,9],
[47,38,11,0],
[47,38,11,1],
[47,38,11,2],
[47,38,11,3],
[47,38,11,4],
[47,38,11,5],
[47,38,11,6],
[47,38,11,8],
[47,38,11,9],
[47,38,11,10],
[47,38,12,0],
[47,38,12,1],
[47,38,12,2],
[47,38,12,3],
[47,38,12,4],
[47,38,12,5],
[47,38,12,6],
[47,38,12,8],
[47,38,12,9],
[47,38,12,10],
[47,38,13,0],
[47,38,13,1],
[47,38,13,2],
[47,38,13,3],
[47,38,13,4],
[47,38,13,5],
[47,38,13,6],
[47,38,13,8],
[47,38,13,9],
[47,38,13,10],
[47,38,13,11],
[47,38,13,12],
[47,38,14,0],
[47,38,14,1],
[47,38,14,2],
[47,38,14,3],
[47,38,14,4],
[47,38,14,5],
[47,38,14,6],
[47,38,14,8],
[47,38,14,9],
[47,38,14,10],
[47,38,14,11],
[47,38,14,12],
[47,38,15,0],
[47,38,15,1],
[47,38,15,2],
[47,38,15,3],
[47,38,15,4],
[47,38,15,5],
[47,38,15,6],
[47,38,15,8],
[47,38,15,10],
[47,38,15,11],
[47,38,15,12],
[47,38,15,13],
[47,38,15,14],
[47,38,16,0],
[47,38,16,1],
[47,38,16,2],
[47,38,16,3],
[47,38,16,4],
[47,38,16,5],
[47,38,16,6],
[47,38,16,8],
[47,38,16,9],
[47,38,16,10],
[47,38,16,11],
[47,38,16,12],
[47,38,16,13],
[47,38,16,14],
[47,38,16,15],
[47,38,17,0],
[47,38,17,1],
[47,38,17,2],
[47,38,17,3],
[47,38,17,4],
[47,38,17,5],
[47,38,17,6],
[47,38,17,8],
[47,38,17,9],
[47,38,17,10],
[47,38,17,11],
[47,38,17,12],
[47,38,17,13],
[47,38,17,14],
[47,38,17,15],
[47,38,17,16],
[47,38,18,0],
[47,38,18,1],
[47,38,18,2],
[47,38,18,3],
[47,38,18,4],
[47,38,18,5],
[47,38,18,6],
[47,38,18,8],
[47,38,18,10],
[47,38,18,11],
[47,38,18,12],
[47,38,18,13],
[47,38,18,14],
[47,38,18,16],
[47,38,18,17],
[47,38,19,0],
[47,38,19,1],
[47,38,19,2],
[47,38,19,3],
[47,38,19,4],
[47,38,19,5],
[47,38,19,6],
[47,38,19,8],
[47,38,19,9],
[47,38,19,10],
[47,38,19,11],
[47,38,19,12],
[47,38,19,15],
[47,38,19,16],
[47,38,19,17],
[47,38,19,18],
[47,38,20,0],
[47,38,20,1],
[47,38,20,2],
[47,38,20,3],
[47,38,20,4],
[47,38,20,5],
[47,38,20,6],
[47,38,20,8],
[47,38,20,9],
[47,38,20,11],
[47,38,20,12],
[47,38,20,13],
[47,38,20,14],
[47,38,20,15],
[47,38,20,16],
[47,38,20,17],
[47,38,20,18],
[47,38,20,19],
[47,38,21,0],
[47,38,21,1],
[47,38,21,2],
[47,38,21,4],
[47,38,21,9],
[47,38,21,10],
[47,38,21,11],
[47,38,21,12],
[47,38,21,13],
[47,38,21,14],
[47,38,21,15],
[47,38,21,16],
[47,38,21,17],
[47,38,21,18],
[47,38,21,19],
[47,38,21,20],
[47,38,22,0],
[47,38,22,1],
[47,38,22,2],
[47,38,22,3],
[47,38,22,4],
[47,38,22,5],
[47,38,22,6],
[47,38,22,8],
[47,38,22,9],
[47,38,22,11],
[47,38,22,12],
[47,38,22,13],
[47,38,22,14],
[47,38,22,15],
[47,38,22,16],
[47,38,22,17],
[47,38,22,18],
[47,38,22,19],
[47,38,24,0],
[47,38,24,1],
[47,38,24,2],
[47,38,24,3],
[47,38,24,4],
[47,38,24,5],
[47,38,24,6],
[47,38,24,8],
[47,38,24,9],
[47,38,24,10],
[47,38,24,11],
[47,38,24,12],
[47,38,24,13],
[47,38,24,14],
[47,38,24,15],
[47,38,24,17],
[47,38,24,18],
[47,38,24,19],
[47,38,24,21],
[47,38,24,22],
[47,38,26,0],
[47,38,26,1],
[47,38,26,2],
[47,38,26,3],
[47,38,26,4],
[47,38,26,5],
[47,38,26,6],
[47,38,26,8],
[47,38,26,10],
[47,38,26,11],
[47,38,26,12],
[47,38,26,13],
[47,38,26,14],
[47,38,26,19],
[47,38,26,20],
[47,38,26,21],
[47,38,26,22],
[47,38,26,24],
[47,38,27,0],
[47,38,27,1],
[47,38,27,2],
[47,38,27,3],
[47,38,27,4],
[47,38,27,5],
[47,38,27,6],
[47,38,27,8],
[47,38,27,9],
[47,38,27,11],
[47,38,27,12],
[47,38,27,13],
[47,38,27,14],
[47,38,27,16],
[47,38,27,17],
[47,38,27,18],
[47,38,27,19],
[47,38,27,21],
[47,38,27,24],
[47,38,27,26],
[47,38,29,0],
[47,38,29,1],
[47,38,29,2],
[47,38,29,3],
[47,38,29,4],
[47,38,29,5],
[47,38,29,6],
[47,38,29,8],
[47,38,29,9],
[47,38,29,10],
[47,38,29,14],
[47,38,29,15],
[47,38,29,16],
[47,38,29,17],
[47,38,29,18],
[47,38,29,19],
[47,38,29,20],
[47,38,29,21],
[47,38,29,22],
[47,38,29,24],
[47,38,29,26],
[47,38,32,0],
[47,38,32,1],
[47,38,32,2],
[47,38,32,3],
[47,38,32,4],
[47,38,32,5],
[47,38,32,8],
[47,38,32,9],
[47,38,32,10],
[47,38,32,13],
[47,38,32,14],
[47,38,32,15],
[47,38,32,16],
[47,38,32,17],
[47,38,32,18],
[47,38,32,19],
[47,38,32,20],
[47,38,32,21],
[47,38,32,22],
[47,38,32,24],
[47,38,33,0],
[47,38,33,1],
[47,38,33,2],
[47,38,33,3],
[47,38,33,4],
[47,38,33,6],
[47,38,33,8],
[47,38,33,9],
[47,38,33,10],
[47,38,33,13],
[47,38,33,14],
[47,38,33,15],
[47,38,33,16],
[47,38,33,17],
[47,38,33,18],
[47,38,33,19],
[47,38,33,20],
[47,38,33,21],
[47,38,33,22],
[47,38,35,3],
[47,38,35,4],
[47,38,35,5],
[47,38,35,6],
[47,38,35,8],
[47,38,35,10],
[47,38,35,11],
[47,38,35,12],
[47,38,35,13],
[47,38,35,14],
[47,38,35,16],
[47,38,35,17],
[47,38,35,19],
[47,38,35,20],
[47,38,36,0],
[47,38,36,1],
[47,38,36,2],
[47,38,36,3],
[47,38,36,4],
[47,38,36,5],
[47,38,36,6],
[47,38,36,8],
[47,38,36,9],
[47,38,36,10],
[47,38,36,11],
[47,38,36,12],
[47,38,36,15],
[47,38,36,16],
[47,38,36,17],
[47,38,36,18],
[47,38,37,0],
[47,38,37,1],
[47,38,37,2],
[47,38,37,3],
[47,38,37,5],
[47,38,37,6],
[47,38,37,8],
[47,38,37,9],
[47,38,37,10],
[47,38,37,11],
[47,38,37,12],
[47,38,37,13],
[47,38,37,14],
[47,38,37,15],
[47,38,37,16],
[47,38,37,17],
[47,38,37,18],
[47,39,4,0],
[47,39,4,1],
[47,39,4,2],
[47,39,7,0],
[47,39,7,1],
[47,39,7,2],
[47,39,7,4],
[47,39,9,0],
[47,39,9,1],
[47,39,9,2],
[47,39,9,4],
[47,39,9,7],
[47,39,10,0],
[47,39,10,1],
[47,39,10,2],
[47,39,10,4],
[47,39,10,7],
[47,39,10,9],
[47,39,11,0],
[47,39,11,1],
[47,39,11,2],
[47,39,11,4],
[47,39,11,7],
[47,39,11,9],
[47,39,11,10],
[47,39,12,0],
[47,39,12,1],
[47,39,12,2],
[47,39,12,4],
[47,39,12,7],
[47,39,12,9],
[47,39,12,10],
[47,39,13,0],
[47,39,13,1],
[47,39,13,2],
[47,39,13,4],
[47,39,13,7],
[47,39,13,9],
[47,39,13,10],
[47,39,13,11],
[47,39,13,12],
[47,39,14,0],
[47,39,14,1],
[47,39,14,2],
[47,39,14,4],
[47,39,14,7],
[47,39,14,9],
[47,39,14,10],
[47,39,14,11],
[47,39,14,12],
[47,39,15,0],
[47,39,15,1],
[47,39,15,2],
[47,39,15,4],
[47,39,15,7],
[47,39,15,10],
[47,39,15,11],
[47,39,15,12],
[47,39,15,13],
[47,39,15,14],
[47,39,16,0],
[47,39,16,1],
[47,39,16,2],
[47,39,16,4],
[47,39,16,7],
[47,39,16,9],
[47,39,16,10],
[47,39,16,11],
[47,39,16,12],
[47,39,16,13],
[47,39,16,14],
[47,39,16,15],
[47,39,17,0],
[47,39,17,1],
[47,39,17,2],
[47,39,17,4],
[47,39,17,7],
[47,39,17,9],
[47,39,17,10],
[47,39,17,11],
[47,39,17,12],
[47,39,17,13],
[47,39,17,14],
[47,39,17,15],
[47,39,17,16],
[47,39,18,0],
[47,39,18,1],
[47,39,18,2],
[47,39,18,4],
[47,39,18,7],
[47,39,18,10],
[47,39,18,11],
[47,39,18,12],
[47,39,18,13],
[47,39,18,14],
[47,39,18,16],
[47,39,18,17],
[47,39,19,0],
[47,39,19,1],
[47,39,19,2],
[47,39,19,4],
[47,39,19,7],
[47,39,19,9],
[47,39,19,10],
[47,39,19,11],
[47,39,19,12],
[47,39,19,15],
[47,39,19,16],
[47,39,19,17],
[47,39,19,18],
[47,39,20,0],
[47,39,20,1],
[47,39,20,2],
[47,39,20,4],
[47,39,20,7],
[47,39,20,9],
[47,39,20,11],
[47,39,20,12],
[47,39,20,13],
[47,39,20,14],
[47,39,20,15],
[47,39,20,16],
[47,39,20,17],
[47,39,20,18],
[47,39,20,19],
[47,39,22,0],
[47,39,22,1],
[47,39,22,2],
[47,39,22,4],
[47,39,22,7],
[47,39,22,9],
[47,39,22,11],
[47,39,22,12],
[47,39,22,13],
[47,39,22,14],
[47,39,22,15],
[47,39,22,16],
[47,39,22,17],
[47,39,22,18],
[47,39,22,19],
[47,39,24,0],
[47,39,24,1],
[47,39,24,2],
[47,39,24,4],
[47,39,24,7],
[47,39,24,9],
[47,39,24,10],
[47,39,24,11],
[47,39,24,12],
[47,39,24,13],
[47,39,24,14],
[47,39,24,15],
[47,39,24,17],
[47,39,24,18],
[47,39,24,20],
[47,39,24,22],
[47,39,25,0],
[47,39,25,1],
[47,39,25,2],
[47,39,25,4],
[47,39,25,9],
[47,39,25,10],
[47,39,25,11],
[47,39,25,12],
[47,39,25,13],
[47,39,25,14],
[47,39,25,15],
[47,39,25,18],
[47,39,25,19],
[47,39,25,20],
[47,39,25,22],
[47,39,25,24],
[47,39,26,0],
[47,39,26,1],
[47,39,26,2],
[47,39,26,4],
[47,39,26,7],
[47,39,26,10],
[47,39,26,11],
[47,39,26,12],
[47,39,26,13],
[47,39,26,14],
[47,39,26,16],
[47,39,26,17],
[47,39,26,19],
[47,39,26,20],
[47,39,26,22],
[47,39,26,24],
[47,39,26,25],
[47,39,27,0],
[47,39,27,1],
[47,39,27,2],
[47,39,27,4],
[47,39,27,7],
[47,39,27,9],
[47,39,27,11],
[47,39,27,12],
[47,39,27,13],
[47,39,27,15],
[47,39,27,16],
[47,39,27,17],
[47,39,27,18],
[47,39,27,19],
[47,39,27,24],
[47,39,27,25],
[47,39,27,26],
[47,39,28,0],
[47,39,28,1],
[47,39,28,2],
[47,39,28,4],
[47,39,28,9],
[47,39,28,10],
[47,39,28,11],
[47,39,28,14],
[47,39,28,15],
[47,39,28,16],
[47,39,28,17],
[47,39,28,18],
[47,39,28,19],
[47,39,28,20],
[47,39,28,22],
[47,39,28,24],
[47,39,28,26],
[47,39,29,0],
[47,39,29,1],
[47,39,29,2],
[47,39,29,4],
[47,39,29,7],
[47,39,29,9],
[47,39,29,13],
[47,39,29,14],
[47,39,29,15],
[47,39,29,16],
[47,39,29,17],
[47,39,29,18],
[47,39,29,19],
[47,39,29,20],
[47,39,29,22],
[47,39,29,24],
[47,39,29,25],
[47,39,32,0],
[47,39,32,1],
[47,39,32,2],
[47,39,32,4],
[47,39,32,7],
[47,39,32,9],
[47,39,32,10],
[47,39,32,13],
[47,39,32,14],
[47,39,32,15],
[47,39,32,16],
[47,39,32,17],
[47,39,32,18],
[47,39,32,19],
[47,39,32,20],
[47,39,32,22],
[47,39,33,0],
[47,39,33,1],
[47,39,33,2],
[47,39,33,7],
[47,39,33,9],
[47,39,33,10],
[47,39,33,13],
[47,39,33,14],
[47,39,33,15],
[47,39,33,16],
[47,39,33,17],
[47,39,33,18],
[47,39,33,19],
[47,39,33,20],
[47,39,34,4],
[47,39,34,9],
[47,39,34,10],
[47,39,34,11],
[47,39,34,12],
[47,39,34,13],
[47,39,34,14],
[47,39,34,15],
[47,39,34,16],
[47,39,34,17],
[47,39,34,18],
[47,39,34,19],
[47,39,34,20],
[47,39,35,0],
[47,39,35,1],
[47,39,35,2],
[47,39,35,4],
[47,39,35,7],
[47,39,35,10],
[47,39,35,11],
[47,39,35,12],
[47,39,35,13],
[47,39,35,14],
[47,39,35,16],
[47,39,35,17],
[47,39,35,19],
[47,39,36,0],
[47,39,36,1],
[47,39,36,2],
[47,39,36,4],
[47,39,36,7],
[47,39,36,9],
[47,39,36,10],
[47,39,36,11],
[47,39,36,12],
[47,39,36,15],
[47,39,36,16],
[47,39,36,17],
[47,39,36,18],
[47,39,37,0],
[47,39,37,1],
[47,39,37,2],
[47,39,37,7],
[47,39,37,9],
[47,39,37,10],
[47,39,37,11],
[47,39,37,12],
[47,39,37,13],
[47,39,37,14],
[47,39,37,15],
[47,39,37,16],
[47,39,37,17],
[47,39,38,0],
[47,39,38,1],
[47,39,38,2],
[47,39,38,4],
[47,39,38,9],
[47,39,38,10],
[47,39,38,11],
[47,39,38,12],
[47,39,38,13],
[47,39,38,14],
[47,39,38,15],
[47,40,3,0],
[47,40,3,1],
[47,40,3,2],
[47,40,4,0],
[47,40,4,1],
[47,40,4,2],
[47,40,4,3],
[47,40,5,0],
[47,40,5,1],
[47,40,5,2],
[47,40,5,4],
[47,40,6,0],
[47,40,6,1],
[47,40,6,2],
[47,40,6,4],
[47,40,7,0],
[47,40,7,1],
[47,40,7,2],
[47,40,7,3],
[47,40,7,4],
[47,40,7,5],
[47,40,7,6],
[47,40,8,0],
[47,40,8,1],
[47,40,8,2],
[47,40,8,4],
[47,40,8,7],
[47,40,10,0],
[47,40,10,1],
[47,40,10,2],
[47,40,10,3],
[47,40,10,4],
[47,40,10,5],
[47,40,10,6],
[47,40,10,7],
[47,40,10,8],
[47,40,11,0],
[47,40,11,1],
[47,40,11,2],
[47,40,11,3],
[47,40,11,4],
[47,40,11,5],
[47,40,11,6],
[47,40,11,7],
[47,40,11,8],
[47,40,11,10],
[47,40,12,0],
[47,40,12,1],
[47,40,12,2],
[47,40,12,3],
[47,40,12,4],
[47,40,12,5],
[47,40,12,6],
[47,40,12,7],
[47,40,12,8],
[47,40,12,10],
[47,40,13,0],
[47,40,13,1],
[47,40,13,2],
[47,40,13,3],
[47,40,13,4],
[47,40,13,5],
[47,40,13,6],
[47,40,13,7],
[47,40,13,8],
[47,40,13,10],
[47,40,13,11],
[47,40,13,12],
[47,40,14,0],
[47,40,14,1],
[47,40,14,2],
[47,40,14,3],
[47,40,14,4],
[47,40,14,5],
[47,40,14,6],
[47,40,14,7],
[47,40,14,8],
[47,40,14,10],
[47,40,14,11],
[47,40,14,12],
[47,40,16,0],
[47,40,16,1],
[47,40,16,2],
[47,40,16,3],
[47,40,16,4],
[47,40,16,5],
[47,40,16,6],
[47,40,16,7],
[47,40,16,8],
[47,40,16,10],
[47,40,16,11],
[47,40,16,12],
[47,40,16,13],
[47,40,16,14],
[47,40,17,0],
[47,40,17,1],
[47,40,17,2],
[47,40,17,3],
[47,40,17,4],
[47,40,17,5],
[47,40,17,6],
[47,40,17,7],
[47,40,17,8],
[47,40,17,10],
[47,40,17,11],
[47,40,17,12],
[47,40,17,13],
[47,40,17,14],
[47,40,17,16],
[47,40,19,0],
[47,40,19,1],
[47,40,19,2],
[47,40,19,3],
[47,40,19,4],
[47,40,19,5],
[47,40,19,6],
[47,40,19,7],
[47,40,19,8],
[47,40,19,10],
[47,40,19,11],
[47,40,19,12],
[47,40,19,16],
[47,40,19,17],
[47,40,20,0],
[47,40,20,1],
[47,40,20,2],
[47,40,20,3],
[47,40,20,4],
[47,40,20,5],
[47,40,20,6],
[47,40,20,7],
[47,40,20,8],
[47,40,20,11],
[47,40,20,12],
[47,40,20,13],
[47,40,20,14],
[47,40,20,16],
[47,40,20,17],
[47,40,20,19],
[47,40,21,0],
[47,40,21,1],
[47,40,21,2],
[47,40,21,4],
[47,40,21,7],
[47,40,21,10],
[47,40,21,11],
[47,40,21,12],
[47,40,21,13],
[47,40,21,14],
[47,40,21,16],
[47,40,21,17],
[47,40,21,19],
[47,40,22,0],
[47,40,22,1],
[47,40,22,2],
[47,40,22,3],
[47,40,22,4],
[47,40,22,5],
[47,40,22,6],
[47,40,22,7],
[47,40,22,8],
[47,40,22,11],
[47,40,22,12],
[47,40,22,13],
[47,40,22,14],
[47,40,22,16],
[47,40,22,17],
[47,40,22,21],
[47,40,24,0],
[47,40,24,1],
[47,40,24,2],
[47,40,24,3],
[47,40,24,4],
[47,40,24,5],
[47,40,24,6],
[47,40,24,7],
[47,40,24,8],
[47,40,24,10],
[47,40,24,11],
[47,40,24,12],
[47,40,24,13],
[47,40,24,14],
[47,40,24,17],
[47,40,24,19],
[47,40,24,20],
[47,40,24,21],
[47,40,24,22],
[47,40,25,0],
[47,40,25,1],
[47,40,25,2],
[47,40,25,3],
[47,40,25,4],
[47,40,25,5],
[47,40,25,6],
[47,40,25,8],
[47,40,25,10],
[47,40,25,11],
[47,40,25,12],
[47,40,25,13],
[47,40,25,14],
[47,40,25,16],
[47,40,25,17],
[47,40,25,19],
[47,40,25,20],
[47,40,25,21],
[47,40,25,22],
[47,40,25,24],
[47,40,27,0],
[47,40,27,1],
[47,40,27,2],
[47,40,27,3],
[47,40,27,4],
[47,40,27,5],
[47,40,27,6],
[47,40,27,7],
[47,40,27,8],
[47,40,27,11],
[47,40,27,14],
[47,40,27,16],
[47,40,27,17],
[47,40,27,19],
[47,40,27,21],
[47,40,27,24],
[47,40,27,25],
[47,40,28,0],
[47,40,28,1],
[47,40,28,2],
[47,40,28,3],
[47,40,28,4],
[47,40,28,5],
[47,40,28,6],
[47,40,28,8],
[47,40,28,12],
[47,40,28,13],
[47,40,28,14],
[47,40,28,16],
[47,40,28,17],
[47,40,28,19],
[47,40,28,20],
[47,40,28,21],
[47,40,28,22],
[47,40,28,24],
[47,40,29,0],
[47,40,29,1],
[47,40,29,2],
[47,40,29,3],
[47,40,29,4],
[47,40,29,5],
[47,40,29,6],
[47,40,29,7],
[47,40,29,10],
[47,40,29,13],
[47,40,29,14],
[47,40,29,16],
[47,40,29,17],
[47,40,29,19],
[47,40,29,20],
[47,40,29,21],
[47,40,29,22],
[47,40,29,24],
[47,40,32,0],
[47,40,32,1],
[47,40,32,2],
[47,40,32,5],
[47,40,32,6],
[47,40,32,7],
[47,40,32,8],
[47,40,32,10],
[47,40,32,13],
[47,40,32,14],
[47,40,32,16],
[47,40,32,17],
[47,40,32,19],
[47,40,32,20],
[47,40,32,21],
[47,40,33,3],
[47,40,33,4],
[47,40,33,5],
[47,40,33,6],
[47,40,33,7],
[47,40,33,8],
[47,40,33,10],
[47,40,33,13],
[47,40,33,14],
[47,40,33,16],
[47,40,33,17],
[47,40,33,19],
[47,40,33,20],
[47,40,34,0],
[47,40,34,1],
[47,40,34,2],
[47,40,34,3],
[47,40,34,4],
[47,40,34,5],
[47,40,34,6],
[47,40,34,8],
[47,40,34,10],
[47,40,34,11],
[47,40,34,12],
[47,40,34,13],
[47,40,34,14],
[47,40,34,16],
[47,40,34,17],
[47,40,34,19],
[47,40,36,0],
[47,40,36,1],
[47,40,36,2],
[47,40,36,3],
[47,40,36,4],
[47,40,36,5],
[47,40,36,6],
[47,40,36,7],
[47,40,36,8],
[47,40,36,10],
[47,40,36,11],
[47,40,36,12],
[47,40,36,16],
[47,40,36,17],
[47,40,37,0],
[47,40,37,1],
[47,40,37,2],
[47,40,37,3],
[47,40,37,5],
[47,40,37,6],
[47,40,37,7],
[47,40,37,8],
[47,40,37,10],
[47,40,37,11],
[47,40,37,12],
[47,40,37,13],
[47,40,37,14],
[47,40,38,0],
[47,40,38,1],
[47,40,38,2],
[47,40,38,3],
[47,40,38,4],
[47,40,38,5],
[47,40,38,6],
[47,40,38,8],
[47,40,38,10],
[47,40,38,11],
[47,40,38,12],
[47,40,38,13],
[47,40,38,14],
[47,40,39,0],
[47,40,39,1],
[47,40,39,2],
[47,40,39,4],
[47,40,39,7],
[47,40,39,10],
[47,40,39,11],
[47,40,39,12],
[47,40,39,13],
[47,41,3,0],
[47,41,3,1],
[47,41,3,2],
[47,41,4,0],
[47,41,4,1],
[47,41,4,2],
[47,41,4,3],
[47,41,5,0],
[47,41,5,1],
[47,41,5,2],
[47,41,5,4],
[47,41,6,0],
[47,41,6,1],
[47,41,6,2],
[47,41,6,4],
[47,41,7,0],
[47,41,7,1],
[47,41,7,2],
[47,41,7,3],
[47,41,7,4],
[47,41,7,5],
[47,41,7,6],
[47,41,8,0],
[47,41,8,1],
[47,41,8,2],
[47,41,8,4],
[47,41,8,7],
[47,41,10,0],
[47,41,10,1],
[47,41,10,2],
[47,41,10,3],
[47,41,10,4],
[47,41,10,5],
[47,41,10,6],
[47,41,10,7],
[47,41,10,8],
[47,41,11,0],
[47,41,11,1],
[47,41,11,2],
[47,41,11,3],
[47,41,11,4],
[47,41,11,5],
[47,41,11,6],
[47,41,11,7],
[47,41,11,8],
[47,41,11,10],
[47,41,12,0],
[47,41,12,1],
[47,41,12,2],
[47,41,12,3],
[47,41,12,4],
[47,41,12,5],
[47,41,12,6],
[47,41,12,7],
[47,41,12,8],
[47,41,12,10],
[47,41,13,0],
[47,41,13,1],
[47,41,13,2],
[47,41,13,3],
[47,41,13,4],
[47,41,13,5],
[47,41,13,6],
[47,41,13,7],
[47,41,13,8],
[47,41,13,10],
[47,41,13,11],
[47,41,13,12],
[47,41,14,0],
[47,41,14,1],
[47,41,14,2],
[47,41,14,3],
[47,41,14,4],
[47,41,14,5],
[47,41,14,6],
[47,41,14,7],
[47,41,14,8],
[47,41,14,10],
[47,41,14,11],
[47,41,14,12],
[47,41,16,0],
[47,41,16,1],
[47,41,16,2],
[47,41,16,3],
[47,41,16,4],
[47,41,16,5],
[47,41,16,6],
[47,41,16,7],
[47,41,16,8],
[47,41,16,10],
[47,41,16,11],
[47,41,16,12],
[47,41,16,13],
[47,41,16,14],
[47,41,17,0],
[47,41,17,1],
[47,41,17,2],
[47,41,17,3],
[47,41,17,4],
[47,41,17,5],
[47,41,17,6],
[47,41,17,7],
[47,41,17,8],
[47,41,17,10],
[47,41,17,11],
[47,41,17,12],
[47,41,17,13],
[47,41,17,14],
[47,41,17,16],
[47,41,19,0],
[47,41,19,1],
[47,41,19,2],
[47,41,19,3],
[47,41,19,4],
[47,41,19,5],
[47,41,19,6],
[47,41,19,7],
[47,41,19,8],
[47,41,19,10],
[47,41,19,11],
[47,41,19,12],
[47,41,19,16],
[47,41,19,17],
[47,41,20,0],
[47,41,20,1],
[47,41,20,2],
[47,41,20,3],
[47,41,20,4],
[47,41,20,5],
[47,41,20,6],
[47,41,20,7],
[47,41,20,8],
[47,41,20,11],
[47,41,20,12],
[47,41,20,13],
[47,41,20,14],
[47,41,20,16],
[47,41,20,17],
[47,41,20,19],
[47,41,21,0],
[47,41,21,1],
[47,41,21,2],
[47,41,21,4],
[47,41,21,7],
[47,41,21,10],
[47,41,21,11],
[47,41,21,12],
[47,41,21,13],
[47,41,21,14],
[47,41,21,16],
[47,41,21,17],
[47,41,21,19],
[47,41,22,0],
[47,41,22,1],
[47,41,22,2],
[47,41,22,3],
[47,41,22,4],
[47,41,22,5],
[47,41,22,6],
[47,41,22,7],
[47,41,22,8],
[47,41,22,11],
[47,41,22,12],
[47,41,22,13],
[47,41,22,14],
[47,41,22,16],
[47,41,22,17],
[47,41,22,21],
[47,41,24,0],
[47,41,24,1],
[47,41,24,2],
[47,41,24,3],
[47,41,24,4],
[47,41,24,5],
[47,41,24,6],
[47,41,24,7],
[47,41,24,8],
[47,41,24,10],
[47,41,24,11],
[47,41,24,12],
[47,41,24,13],
[47,41,24,14],
[47,41,24,17],
[47,41,24,19],
[47,41,24,20],
[47,41,24,21],
[47,41,24,22],
[47,41,25,0],
[47,41,25,1],
[47,41,25,2],
[47,41,25,3],
[47,41,25,4],
[47,41,25,5],
[47,41,25,6],
[47,41,25,8],
[47,41,25,10],
[47,41,25,11],
[47,41,25,12],
[47,41,25,13],
[47,41,25,14],
[47,41,25,16],
[47,41,25,17],
[47,41,25,19],
[47,41,25,20],
[47,41,25,21],
[47,41,25,22],
[47,41,25,24],
[47,41,27,0],
[47,41,27,1],
[47,41,27,2],
[47,41,27,3],
[47,41,27,4],
[47,41,27,5],
[47,41,27,6],
[47,41,27,7],
[47,41,27,8],
[47,41,27,11],
[47,41,27,14],
[47,41,27,16],
[47,41,27,17],
[47,41,27,19],
[47,41,27,21],
[47,41,27,24],
[47,41,27,25],
[47,41,28,0],
[47,41,28,1],
[47,41,28,2],
[47,41,28,3],
[47,41,28,4],
[47,41,28,5],
[47,41,28,6],
[47,41,28,8],
[47,41,28,12],
[47,41,28,13],
[47,41,28,14],
[47,41,28,16],
[47,41,28,17],
[47,41,28,19],
[47,41,28,20],
[47,41,28,21],
[47,41,28,22],
[47,41,28,24],
[47,41,29,0],
[47,41,29,1],
[47,41,29,2],
[47,41,29,3],
[47,41,29,4],
[47,41,29,5],
[47,41,29,6],
[47,41,29,7],
[47,41,29,10],
[47,41,29,13],
[47,41,29,14],
[47,41,29,16],
[47,41,29,17],
[47,41,29,19],
[47,41,29,20],
[47,41,29,21],
[47,41,29,22],
[47,41,29,24],
[47,41,32,0],
[47,41,32,1],
[47,41,32,2],
[47,41,32,5],
[47,41,32,6],
[47,41,32,7],
[47,41,32,8],
[47,41,32,10],
[47,41,32,13],
[47,41,32,14],
[47,41,32,16],
[47,41,32,17],
[47,41,32,19],
[47,41,32,20],
[47,41,32,21],
[47,41,33,3],
[47,41,33,4],
[47,41,33,5],
[47,41,33,6],
[47,41,33,7],
[47,41,33,8],
[47,41,33,10],
[47,41,33,13],
[47,41,33,14],
[47,41,33,16],
[47,41,33,17],
[47,41,33,19],
[47,41,33,20],
[47,41,34,0],
[47,41,34,1],
[47,41,34,2],
[47,41,34,3],
[47,41,34,4],
[47,41,34,5],
[47,41,34,6],
[47,41,34,8],
[47,41,34,10],
[47,41,34,11],
[47,41,34,12],
[47,41,34,13],
[47,41,34,14],
[47,41,34,16],
[47,41,34,17],
[47,41,34,19],
[47,41,36,0],
[47,41,36,1],
[47,41,36,2],
[47,41,36,3],
[47,41,36,4],
[47,41,36,5],
[47,41,36,6],
[47,41,36,7],
[47,41,36,8],
[47,41,36,10],
[47,41,36,11],
[47,41,36,12],
[47,41,36,16],
[47,41,36,17],
[47,41,37,0],
[47,41,37,1],
[47,41,37,2],
[47,41,37,3],
[47,41,37,5],
[47,41,37,6],
[47,41,37,7],
[47,41,37,8],
[47,41,37,10],
[47,41,37,11],
[47,41,37,12],
[47,41,37,13],
[47,41,37,14],
[47,41,38,0],
[47,41,38,1],
[47,41,38,2],
[47,41,38,3],
[47,41,38,4],
[47,41,38,5],
[47,41,38,6],
[47,41,38,8],
[47,41,38,10],
[47,41,38,11],
[47,41,38,12],
[47,41,38,13],
[47,41,38,14],
[47,41,39,0],
[47,41,39,1],
[47,41,39,2],
[47,41,39,4],
[47,41,39,7],
[47,41,39,10],
[47,41,39,11],
[47,41,39,12],
[47,41,39,13],
[47,42,3,0],
[47,42,3,1],
[47,42,3,2],
[47,42,4,0],
[47,42,4,1],
[47,42,4,2],
[47,42,4,3],
[47,42,5,0],
[47,42,5,1],
[47,42,5,2],
[47,42,5,4],
[47,42,6,0],
[47,42,6,1],
[47,42,6,2],
[47,42,6,4],
[47,42,7,0],
[47,42,7,1],
[47,42,7,2],
[47,42,7,3],
[47,42,7,4],
[47,42,7,5],
[47,42,7,6],
[47,42,8,0],
[47,42,8,1],
[47,42,8,2],
[47,42,8,4],
[47,42,8,7],
[47,42,9,0],
[47,42,9,1],
[47,42,9,2],
[47,42,9,3],
[47,42,9,4],
[47,42,9,5],
[47,42,9,6],
[47,42,9,7],
[47,42,9,8],
[47,42,11,0],
[47,42,11,1],
[47,42,11,2],
[47,42,11,3],
[47,42,11,4],
[47,42,11,5],
[47,42,11,6],
[47,42,11,7],
[47,42,11,8],
[47,42,11,9],
[47,42,12,0],
[47,42,12,1],
[47,42,12,2],
[47,42,12,3],
[47,42,12,4],
[47,42,12,5],
[47,42,12,6],
[47,42,12,7],
[47,42,12,8],
[47,42,12,9],
[47,42,13,0],
[47,42,13,1],
[47,42,13,2],
[47,42,13,3],
[47,42,13,4],
[47,42,13,5],
[47,42,13,6],
[47,42,13,7],
[47,42,13,8],
[47,42,13,9],
[47,42,13,11],
[47,42,13,12],
[47,42,14,0],
[47,42,14,1],
[47,42,14,2],
[47,42,14,3],
[47,42,14,4],
[47,42,14,5],
[47,42,14,6],
[47,42,14,7],
[47,42,14,8],
[47,42,14,9],
[47,42,14,11],
[47,42,14,12],
[47,42,15,0],
[47,42,15,1],
[47,42,15,2],
[47,42,15,3],
[47,42,15,4],
[47,42,15,5],
[47,42,15,6],
[47,42,15,7],
[47,42,15,8],
[47,42,15,11],
[47,42,15,12],
[47,42,15,13],
[47,42,15,14],
[47,42,16,0],
[47,42,16,1],
[47,42,16,2],
[47,42,16,3],
[47,42,16,4],
[47,42,16,5],
[47,42,16,6],
[47,42,16,7],
[47,42,16,8],
[47,42,16,9],
[47,42,16,11],
[47,42,16,12],
[47,42,16,13],
[47,42,16,14],
[47,42,16,15],
[47,42,17,0],
[47,42,17,1],
[47,42,17,2],
[47,42,17,3],
[47,42,17,4],
[47,42,17,5],
[47,42,17,6],
[47,42,17,7],
[47,42,17,8],
[47,42,17,9],
[47,42,17,11],
[47,42,17,12],
[47,42,17,13],
[47,42,17,14],
[47,42,17,15],
[47,42,17,16],
[47,42,18,0],
[47,42,18,1],
[47,42,18,2],
[47,42,18,3],
[47,42,18,4],
[47,42,18,5],
[47,42,18,6],
[47,42,18,7],
[47,42,18,8],
[47,42,18,11],
[47,42,18,12],
[47,42,18,13],
[47,42,18,14],
[47,42,18,16],
[47,42,18,17],
[47,42,19,0],
[47,42,19,1],
[47,42,19,2],
[47,42,19,3],
[47,42,19,4],
[47,42,19,5],
[47,42,19,6],
[47,42,19,7],
[47,42,19,8],
[47,42,19,9],
[47,42,19,11],
[47,42,19,12],
[47,42,19,15],
[47,42,19,16],
[47,42,19,17],
[47,42,19,18],
[47,42,21,0],
[47,42,21,1],
[47,42,21,2],
[47,42,21,4],
[47,42,21,7],
[47,42,21,9],
[47,42,21,11],
[47,42,21,12],
[47,42,21,13],
[47,42,21,14],
[47,42,21,15],
[47,42,21,16],
[47,42,21,17],
[47,42,21,18],
[47,42,24,0],
[47,42,24,1],
[47,42,24,2],
[47,42,24,3],
[47,42,24,4],
[47,42,24,5],
[47,42,24,6],
[47,42,24,7],
[47,42,24,8],
[47,42,24,9],
[47,42,24,11],
[47,42,24,12],
[47,42,24,13],
[47,42,24,14],
[47,42,24,15],
[47,42,24,18],
[47,42,24,19],
[47,42,24,21],
[47,42,25,0],
[47,42,25,1],
[47,42,25,2],
[47,42,25,3],
[47,42,25,4],
[47,42,25,5],
[47,42,25,6],
[47,42,25,8],
[47,42,25,9],
[47,42,25,11],
[47,42,25,12],
[47,42,25,13],
[47,42,25,15],
[47,42,25,16],
[47,42,25,17],
[47,42,25,18],
[47,42,25,19],
[47,42,25,21],
[47,42,25,24],
[47,42,26,0],
[47,42,26,1],
[47,42,26,2],
[47,42,26,3],
[47,42,26,4],
[47,42,26,5],
[47,42,26,6],
[47,42,26,7],
[47,42,26,8],
[47,42,26,11],
[47,42,26,14],
[47,42,26,16],
[47,42,26,17],
[47,42,26,19],
[47,42,26,21],
[47,42,26,24],
[47,42,26,25],
[47,42,28,0],
[47,42,28,1],
[47,42,28,2],
[47,42,28,3],
[47,42,28,4],
[47,42,28,5],
[47,42,28,6],
[47,42,28,11],
[47,42,28,12],
[47,42,28,13],
[47,42,28,14],
[47,42,28,15],
[47,42,28,16],
[47,42,28,17],
[47,42,28,18],
[47,42,28,19],
[47,42,28,21],
[47,42,28,24],
[47,42,29,0],
[47,42,29,1],
[47,42,29,2],
[47,42,29,3],
[47,42,29,4],
[47,42,29,5],
[47,42,29,8],
[47,42,29,9],
[47,42,29,13],
[47,42,29,14],
[47,42,29,15],
[47,42,29,16],
[47,42,29,17],
[47,42,29,18],
[47,42,29,19],
[47,42,29,21],
[47,42,29,24],
[47,42,32,3],
[47,42,32,4],
[47,42,32,5],
[47,42,32,6],
[47,42,32,7],
[47,42,32,8],
[47,42,32,9],
[47,42,32,13],
[47,42,32,14],
[47,42,32,15],
[47,42,32,16],
[47,42,32,17],
[47,42,32,18],
[47,42,32,19],
[47,42,33,0],
[47,42,33,1],
[47,42,33,2],
[47,42,33,3],
[47,42,33,4],
[47,42,33,5],
[47,42,33,6],
[47,42,33,7],
[47,42,33,8],
[47,42,33,9],
[47,42,33,13],
[47,42,33,14],
[47,42,33,15],
[47,42,33,16],
[47,42,33,17],
[47,42,33,18],
[47,42,33,19],
[47,42,34,0],
[47,42,34,1],
[47,42,34,2],
[47,42,34,3],
[47,42,34,4],
[47,42,34,5],
[47,42,34,6],
[47,42,34,8],
[47,42,34,9],
[47,42,34,11],
[47,42,34,12],
[47,42,34,13],
[47,42,34,14],
[47,42,34,15],
[47,42,34,16],
[47,42,34,17],
[47,42,34,18],
[47,42,35,0],
[47,42,35,1],
[47,42,35,2],
[47,42,35,3],
[47,42,35,4],
[47,42,35,5],
[47,42,35,6],
[47,42,35,7],
[47,42,35,8],
[47,42,35,11],
[47,42,35,12],
[47,42,35,13],
[47,42,35,14],
[47,42,35,16],
[47,42,35,17],
[47,42,36,0],
[47,42,36,1],
[47,42,36,2],
[47,42,36,3],
[47,42,36,4],
[47,42,36,5],
[47,42,36,6],
[47,42,36,7],
[47,42,36,8],
[47,42,36,9],
[47,42,36,11],
[47,42,36,12],
[47,42,36,15],
[47,42,37,0],
[47,42,37,1],
[47,42,37,2],
[47,42,37,3],
[47,42,37,5],
[47,42,37,6],
[47,42,37,7],
[47,42,37,8],
[47,42,37,9],
[47,42,37,11],
[47,42,37,12],
[47,42,37,13],
[47,42,37,14],
[47,42,38,0],
[47,42,38,1],
[47,42,38,2],
[47,42,38,3],
[47,42,38,4],
[47,42,38,5],
[47,42,38,6],
[47,42,38,8],
[47,42,38,9],
[47,42,38,11],
[47,42,38,12],
[47,42,38,13],
[47,42,39,0],
[47,42,39,1],
[47,42,39,2],
[47,42,39,4],
[47,42,39,7],
[47,42,39,9],
[47,42,39,11],
[47,42,40,0],
[47,42,40,1],
[47,42,40,2],
[47,42,40,3],
[47,42,40,4],
[47,42,40,5],
[47,42,40,6],
[47,42,40,7],
[47,42,40,8],
[47,42,41,0],
[47,42,41,1],
[47,42,41,2],
[47,42,41,3],
[47,42,41,4],
[47,42,41,5],
[47,42,41,6],
[47,42,41,7],
[47,42,41,8],
[47,43,3,0],
[47,43,3,1],
[47,43,3,2],
[47,43,4,0],
[47,43,4,1],
[47,43,4,2],
[47,43,4,3],
[47,43,5,0],
[47,43,5,1],
[47,43,5,2],
[47,43,5,4],
[47,43,6,0],
[47,43,6,1],
[47,43,6,2],
[47,43,6,4],
[47,43,8,0],
[47,43,8,1],
[47,43,8,2],
[47,43,8,4],
[47,43,9,0],
[47,43,9,1],
[47,43,9,2],
[47,43,9,3],
[47,43,9,4],
[47,43,9,5],
[47,43,9,6],
[47,43,9,8],
[47,43,10,0],
[47,43,10,1],
[47,43,10,2],
[47,43,10,3],
[47,43,10,4],
[47,43,10,5],
[47,43,10,6],
[47,43,10,8],
[47,43,10,9],
[47,43,11,0],
[47,43,11,1],
[47,43,11,2],
[47,43,11,3],
[47,43,11,4],
[47,43,11,5],
[47,43,11,6],
[47,43,11,8],
[47,43,11,9],
[47,43,11,10],
[47,43,12,0],
[47,43,12,1],
[47,43,12,2],
[47,43,12,3],
[47,43,12,4],
[47,43,12,5],
[47,43,12,6],
[47,43,12,8],
[47,43,12,9],
[47,43,12,10],
[47,43,13,0],
[47,43,13,1],
[47,43,13,2],
[47,43,13,3],
[47,43,13,4],
[47,43,13,5],
[47,43,13,6],
[47,43,13,8],
[47,43,13,9],
[47,43,13,10],
[47,43,13,11],
[47,43,13,12],
[47,43,14,0],
[47,43,14,1],
[47,43,14,2],
[47,43,14,3],
[47,43,14,4],
[47,43,14,5],
[47,43,14,6],
[47,43,14,8],
[47,43,14,9],
[47,43,14,10],
[47,43,14,11],
[47,43,14,12],
[47,43,15,0],
[47,43,15,1],
[47,43,15,2],
[47,43,15,3],
[47,43,15,4],
[47,43,15,5],
[47,43,15,6],
[47,43,15,8],
[47,43,15,10],
[47,43,15,11],
[47,43,15,12],
[47,43,15,13],
[47,43,15,14],
[47,43,16,0],
[47,43,16,1],
[47,43,16,2],
[47,43,16,3],
[47,43,16,4],
[47,43,16,5],
[47,43,16,6],
[47,43,16,8],
[47,43,16,9],
[47,43,16,10],
[47,43,16,11],
[47,43,16,12],
[47,43,16,13],
[47,43,16,14],
[47,43,16,15],
[47,43,17,0],
[47,43,17,1],
[47,43,17,2],
[47,43,17,3],
[47,43,17,4],
[47,43,17,5],
[47,43,17,6],
[47,43,17,8],
[47,43,17,9],
[47,43,17,10],
[47,43,17,11],
[47,43,17,12],
[47,43,17,13],
[47,43,17,14],
[47,43,17,15],
[47,43,17,16],
[47,43,18,0],
[47,43,18,1],
[47,43,18,2],
[47,43,18,3],
[47,43,18,4],
[47,43,18,5],
[47,43,18,6],
[47,43,18,8],
[47,43,18,10],
[47,43,18,11],
[47,43,18,12],
[47,43,18,13],
[47,43,18,14],
[47,43,18,16],
[47,43,18,17],
[47,43,19,0],
[47,43,19,1],
[47,43,19,2],
[47,43,19,3],
[47,43,19,4],
[47,43,19,5],
[47,43,19,6],
[47,43,19,8],
[47,43,19,9],
[47,43,19,10],
[47,43,19,11],
[47,43,19,12],
[47,43,19,15],
[47,43,19,16],
[47,43,19,17],
[47,43,19,18],
[47,43,20,0],
[47,43,20,1],
[47,43,20,2],
[47,43,20,3],
[47,43,20,4],
[47,43,20,5],
[47,43,20,6],
[47,43,20,8],
[47,43,20,9],
[47,43,20,11],
[47,43,20,12],
[47,43,20,13],
[47,43,20,14],
[47,43,20,15],
[47,43,20,16],
[47,43,20,17],
[47,43,20,18],
[47,43,21,0],
[47,43,21,1],
[47,43,21,2],
[47,43,21,4],
[47,43,21,9],
[47,43,21,10],
[47,43,21,11],
[47,43,21,12],
[47,43,21,13],
[47,43,21,14],
[47,43,21,15],
[47,43,21,16],
[47,43,21,17],
[47,43,21,19],
[47,43,21,20],
[47,43,22,0],
[47,43,22,1],
[47,43,22,2],
[47,43,22,3],
[47,43,22,4],
[47,43,22,5],
[47,43,22,6],
[47,43,22,8],
[47,43,22,9],
[47,43,22,11],
[47,43,22,12],
[47,43,22,13],
[47,43,22,14],
[47,43,22,15],
[47,43,22,18],
[47,43,22,19],
[47,43,22,21],
[47,43,24,0],
[47,43,24,1],
[47,43,24,2],
[47,43,24,3],
[47,43,24,4],
[47,43,24,5],
[47,43,24,6],
[47,43,24,8],
[47,43,24,9],
[47,43,24,10],
[47,43,24,11],
[47,43,24,12],
[47,43,24,13],
[47,43,24,14],
[47,43,24,17],
[47,43,24,18],
[47,43,24,19],
[47,43,24,20],
[47,43,24,21],
[47,43,24,22],
[47,43,26,0],
[47,43,26,1],
[47,43,26,2],
[47,43,26,3],
[47,43,26,4],
[47,43,26,5],
[47,43,26,6],
[47,43,26,8],
[47,43,26,12],
[47,43,26,13],
[47,43,26,14],
[47,43,26,16],
[47,43,26,17],
[47,43,26,19],
[47,43,26,20],
[47,43,26,21],
[47,43,26,22],
[47,43,26,24],
[47,43,27,0],
[47,43,27,1],
[47,43,27,2],
[47,43,27,3],
[47,43,27,4],
[47,43,27,5],
[47,43,27,6],
[47,43,27,11],
[47,43,27,12],
[47,43,27,13],
[47,43,27,14],
[47,43,27,15],
[47,43,27,16],
[47,43,27,17],
[47,43,27,18],
[47,43,27,19],
[47,43,27,21],
[47,43,27,24],
[47,43,29,0],
[47,43,29,1],
[47,43,29,2],
[47,43,29,3],
[47,43,29,4],
[47,43,29,6],
[47,43,29,8],
[47,43,29,9],
[47,43,29,10],
[47,43,29,13],
[47,43,29,14],
[47,43,29,15],
[47,43,29,16],
[47,43,29,17],
[47,43,29,18],
[47,43,29,19],
[47,43,29,20],
[47,43,29,21],
[47,43,29,22],
[47,43,32,0],
[47,43,32,1],
[47,43,32,2],
[47,43,32,3],
[47,43,32,4],
[47,43,32,5],
[47,43,32,6],
[47,43,32,8],
[47,43,32,9],
[47,43,32,10],
[47,43,32,13],
[47,43,32,14],
[47,43,32,15],
[47,43,32,16],
[47,43,32,17],
[47,43,32,18],
[47,43,32,19],
[47,43,33,0],
[47,43,33,1],
[47,43,33,2],
[47,43,33,3],
[47,43,33,4],
[47,43,33,5],
[47,43,33,6],
[47,43,33,8],
[47,43,33,9],
[47,43,33,10],
[47,43,33,13],
[47,43,33,14],
[47,43,33,15],
[47,43,33,16],
[47,43,33,17],
[47,43,33,18],
[47,43,35,0],
[47,43,35,1],
[47,43,35,2],
[47,43,35,3],
[47,43,35,4],
[47,43,35,5],
[47,43,35,6],
[47,43,35,8],
[47,43,35,10],
[47,43,35,11],
[47,43,35,12],
[47,43,35,13],
[47,43,35,14],
[47,43,36,0],
[47,43,36,1],
[47,43,36,2],
[47,43,36,3],
[47,43,36,4],
[47,43,36,5],
[47,43,36,6],
[47,43,36,8],
[47,43,36,9],
[47,43,36,10],
[47,43,36,11],
[47,43,36,12],
[47,43,37,0],
[47,43,37,1],
[47,43,37,2],
[47,43,37,3],
[47,43,37,5],
[47,43,37,6],
[47,43,37,8],
[47,43,37,9],
[47,43,37,10],
[47,43,37,11],
[47,43,37,12],
[47,43,37,13],
[47,43,39,0],
[47,43,39,1],
[47,43,39,2],
[47,43,39,4],
[47,43,39,9],
[47,43,40,0],
[47,43,40,1],
[47,43,40,2],
[47,43,40,3],
[47,43,40,4],
[47,43,40,5],
[47,43,40,6],
[47,43,41,0],
[47,43,41,1],
[47,43,41,2],
[47,43,41,3],
[47,43,41,4],
[47,43,41,5],
[47,43,41,6],
[47,43,42,0],
[47,43,42,1],
[47,43,42,2],
[47,43,42,3],
[47,43,42,4],
[47,43,42,5],
[47,44,3,0],
[47,44,3,1],
[47,44,3,2],
[47,44,4,0],
[47,44,4,1],
[47,44,4,2],
[47,44,4,3],
[47,44,5,0],
[47,44,5,1],
[47,44,5,2],
[47,44,5,4],
[47,44,6,0],
[47,44,6,1],
[47,44,6,2],
[47,44,6,4],
[47,44,7,0],
[47,44,7,1],
[47,44,7,2],
[47,44,7,3],
[47,44,7,4],
[47,44,7,5],
[47,44,7,6],
[47,44,8,0],
[47,44,8,1],
[47,44,8,2],
[47,44,8,4],
[47,44,8,7],
[47,44,9,0],
[47,44,9,1],
[47,44,9,2],
[47,44,9,3],
[47,44,9,4],
[47,44,9,5],
[47,44,9,6],
[47,44,9,7],
[47,44,9,8],
[47,44,10,0],
[47,44,10,1],
[47,44,10,2],
[47,44,10,3],
[47,44,10,4],
[47,44,10,5],
[47,44,10,6],
[47,44,10,7],
[47,44,10,8],
[47,44,10,9],
[47,44,11,0],
[47,44,11,1],
[47,44,11,2],
[47,44,11,3],
[47,44,11,4],
[47,44,11,5],
[47,44,11,6],
[47,44,11,7],
[47,44,11,8],
[47,44,11,9],
[47,44,11,10],
[47,44,12,0],
[47,44,12,1],
[47,44,12,2],
[47,44,12,3],
[47,44,12,4],
[47,44,12,5],
[47,44,12,6],
[47,44,12,7],
[47,44,12,8],
[47,44,12,9],
[47,44,12,10],
[47,44,13,0],
[47,44,13,1],
[47,44,13,2],
[47,44,13,3],
[47,44,13,4],
[47,44,13,5],
[47,44,13,6],
[47,44,13,7],
[47,44,13,8],
[47,44,13,9],
[47,44,13,10],
[47,44,13,11],
[47,44,13,12],
[47,44,14,0],
[47,44,14,1],
[47,44,14,2],
[47,44,14,3],
[47,44,14,4],
[47,44,14,5],
[47,44,14,6],
[47,44,14,7],
[47,44,14,8],
[47,44,14,9],
[47,44,14,10],
[47,44,14,11],
[47,44,14,12],
[47,44,15,0],
[47,44,15,1],
[47,44,15,2],
[47,44,15,3],
[47,44,15,4],
[47,44,15,5],
[47,44,15,6],
[47,44,15,7],
[47,44,15,8],
[47,44,15,10],
[47,44,15,11],
[47,44,15,12],
[47,44,15,13],
[47,44,15,14],
[47,44,16,0],
[47,44,16,1],
[47,44,16,2],
[47,44,16,3],
[47,44,16,4],
[47,44,16,5],
[47,44,16,6],
[47,44,16,7],
[47,44,16,8],
[47,44,16,9],
[47,44,16,10],
[47,44,16,11],
[47,44,16,12],
[47,44,16,13],
[47,44,16,14],
[47,44,16,15],
[47,44,18,0],
[47,44,18,1],
[47,44,18,2],
[47,44,18,3],
[47,44,18,4],
[47,44,18,5],
[47,44,18,6],
[47,44,18,7],
[47,44,18,8],
[47,44,18,10],
[47,44,18,11],
[47,44,18,12],
[47,44,18,13],
[47,44,18,14],
[47,44,18,16],
[47,44,19,0],
[47,44,19,1],
[47,44,19,2],
[47,44,19,3],
[47,44,19,4],
[47,44,19,5],
[47,44,19,6],
[47,44,19,7],
[47,44,19,8],
[47,44,19,9],
[47,44,19,10],
[47,44,19,11],
[47,44,19,12],
[47,44,19,15],
[47,44,19,16],
[47,44,19,18],
[47,44,20,0],
[47,44,20,1],
[47,44,20,2],
[47,44,20,3],
[47,44,20,4],
[47,44,20,5],
[47,44,20,6],
[47,44,20,7],
[47,44,20,8],
[47,44,20,9],
[47,44,20,11],
[47,44,20,12],
[47,44,20,13],
[47,44,20,14],
[47,44,20,15],
[47,44,20,16],
[47,44,20,19],
[47,44,21,0],
[47,44,21,1],
[47,44,21,2],
[47,44,21,4],
[47,44,21,7],
[47,44,21,9],
[47,44,21,10],
[47,44,21,11],
[47,44,21,12],
[47,44,21,13],
[47,44,21,14],
[47,44,21,15],
[47,44,21,18],
[47,44,21,19],
[47,44,21,20],
[47,44,22,0],
[47,44,22,1],
[47,44,22,2],
[47,44,22,3],
[47,44,22,4],
[47,44,22,5],
[47,44,22,6],
[47,44,22,7],
[47,44,22,8],
[47,44,22,9],
[47,44,22,11],
[47,44,22,12],
[47,44,22,13],
[47,44,22,14],
[47,44,22,16],
[47,44,22,18],
[47,44,22,19],
[47,44,22,21],
[47,44,24,0],
[47,44,24,1],
[47,44,24,2],
[47,44,24,3],
[47,44,24,4],
[47,44,24,5],
[47,44,24,6],
[47,44,24,7],
[47,44,24,8],
[47,44,24,9],
[47,44,24,10],
[47,44,24,11],
[47,44,24,12],
[47,44,24,13],
[47,44,24,15],
[47,44,24,18],
[47,44,24,19],
[47,44,24,20],
[47,44,24,21],
[47,44,24,22],
[47,44,25,0],
[47,44,25,1],
[47,44,25,2],
[47,44,25,3],
[47,44,25,4],
[47,44,25,5],
[47,44,25,6],
[47,44,25,8],
[47,44,25,9],
[47,44,25,12],
[47,44,25,13],
[47,44,25,14],
[47,44,25,15],
[47,44,25,16],
[47,44,25,18],
[47,44,25,19],
[47,44,25,20],
[47,44,25,21],
[47,44,25,22],
[47,44,25,24],
[47,44,26,0],
[47,44,26,1],
[47,44,26,2],
[47,44,26,3],
[47,44,26,4],
[47,44,26,5],
[47,44,26,6],
[47,44,26,7],
[47,44,26,10],
[47,44,26,11],
[47,44,26,12],
[47,44,26,13],
[47,44,26,14],
[47,44,26,16],
[47,44,26,19],
[47,44,26,20],
[47,44,26,21],
[47,44,26,22],
[47,44,26,24],
[47,44,27,0],
[47,44,27,1],
[47,44,27,2],
[47,44,27,3],
[47,44,27,4],
[47,44,27,5],
[47,44,27,8],
[47,44,27,9],
[47,44,27,11],
[47,44,27,12],
[47,44,27,13],
[47,44,27,14],
[47,44,27,15],
[47,44,27,16],
[47,44,27,18],
[47,44,27,19],
[47,44,27,21],
[47,44,27,24],
[47,44,28,0],
[47,44,28,1],
[47,44,28,2],
[47,44,28,3],
[47,44,28,4],
[47,44,28,6],
[47,44,28,8],
[47,44,28,9],
[47,44,28,10],
[47,44,28,11],
[47,44,28,12],
[47,44,28,13],
[47,44,28,14],
[47,44,28,15],
[47,44,28,16],
[47,44,28,18],
[47,44,28,19],
[47,44,28,20],
[47,44,28,21],
[47,44,28,22],
[47,44,29,0],
[47,44,29,1],
[47,44,29,2],
[47,44,29,5],
[47,44,29,6],
[47,44,29,7],
[47,44,29,8],
[47,44,29,9],
[47,44,29,10],
[47,44,29,13],
[47,44,29,14],
[47,44,29,15],
[47,44,29,16],
[47,44,29,18],
[47,44,29,19],
[47,44,29,20],
[47,44,29,21],
[47,44,32,0],
[47,44,32,1],
[47,44,32,2],
[47,44,32,3],
[47,44,32,4],
[47,44,32,5],
[47,44,32,6],
[47,44,32,7],
[47,44,32,8],
[47,44,32,9],
[47,44,32,10],
[47,44,32,13],
[47,44,32,14],
[47,44,32,15],
[47,44,32,16],
[47,44,32,18],
[47,44,33,0],
[47,44,33,1],
[47,44,33,2],
[47,44,33,3],
[47,44,33,4],
[47,44,33,5],
[47,44,33,6],
[47,44,33,7],
[47,44,33,8],
[47,44,33,9],
[47,44,33,10],
[47,44,33,13],
[47,44,33,14],
[47,44,33,15],
[47,44,33,16],
[47,44,34,0],
[47,44,34,1],
[47,44,34,2],
[47,44,34,3],
[47,44,34,4],
[47,44,34,5],
[47,44,34,6],
[47,44,34,8],
[47,44,34,9],
[47,44,34,10],
[47,44,34,11],
[47,44,34,12],
[47,44,34,13],
[47,44,34,14],
[47,44,34,15],
[47,44,35,0],
[47,44,35,1],
[47,44,35,2],
[47,44,35,3],
[47,44,35,4],
[47,44,35,5],
[47,44,35,6],
[47,44,35,7],
[47,44,35,8],
[47,44,35,10],
[47,44,35,11],
[47,44,35,12],
[47,44,35,13],
[47,44,35,14],
[47,44,36,0],
[47,44,36,1],
[47,44,36,2],
[47,44,36,3],
[47,44,36,4],
[47,44,36,5],
[47,44,36,6],
[47,44,36,7],
[47,44,36,8],
[47,44,36,9],
[47,44,36,10],
[47,44,36,11],
[47,44,36,12],
[47,44,37,0],
[47,44,37,1],
[47,44,37,2],
[47,44,37,3],
[47,44,37,5],
[47,44,37,6],
[47,44,37,7],
[47,44,37,8],
[47,44,37,9],
[47,44,37,10],
[47,44,37,11],
[47,44,38,0],
[47,44,38,1],
[47,44,38,2],
[47,44,38,3],
[47,44,38,4],
[47,44,38,5],
[47,44,38,6],
[47,44,38,8],
[47,44,38,9],
[47,44,39,0],
[47,44,39,1],
[47,44,39,2],
[47,44,39,4],
[47,44,39,7],
[47,44,40,0],
[47,44,40,1],
[47,44,40,2],
[47,44,40,3],
[47,44,40,4],
[47,44,40,5],
[47,44,41,0],
[47,44,41,1],
[47,44,41,2],
[47,44,41,3],
[47,44,41,4],
[47,44,41,5],
[47,44,42,0],
[47,44,42,1],
[47,44,42,2],
[47,44,42,3],
[47,44,42,4],
[47,44,43,0],
[47,44,43,1],
[47,44,43,2],
[47,45,3,0],
[47,45,3,1],
[47,45,3,2],
[47,45,4,0],
[47,45,4,1],
[47,45,4,2],
[47,45,4,3],
[47,45,5,0],
[47,45,5,1],
[47,45,5,2],
[47,45,5,4],
[47,45,6,0],
[47,45,6,1],
[47,45,6,2],
[47,45,6,4],
[47,45,7,0],
[47,45,7,1],
[47,45,7,2],
[47,45,7,3],
[47,45,7,4],
[47,45,7,5],
[47,45,7,6],
[47,45,8,0],
[47,45,8,1],
[47,45,8,2],
[47,45,8,4],
[47,45,8,7],
[47,45,10,0],
[47,45,10,1],
[47,45,10,2],
[47,45,10,3],
[47,45,10,4],
[47,45,10,5],
[47,45,10,6],
[47,45,10,7],
[47,45,10,8],
[47,45,11,0],
[47,45,11,1],
[47,45,11,2],
[47,45,11,3],
[47,45,11,4],
[47,45,11,5],
[47,45,11,6],
[47,45,11,7],
[47,45,11,8],
[47,45,11,10],
[47,45,12,0],
[47,45,12,1],
[47,45,12,2],
[47,45,12,3],
[47,45,12,4],
[47,45,12,5],
[47,45,12,6],
[47,45,12,7],
[47,45,12,8],
[47,45,12,10],
[47,45,13,0],
[47,45,13,1],
[47,45,13,2],
[47,45,13,3],
[47,45,13,4],
[47,45,13,5],
[47,45,13,6],
[47,45,13,7],
[47,45,13,8],
[47,45,13,10],
[47,45,13,11],
[47,45,13,12],
[47,45,14,0],
[47,45,14,1],
[47,45,14,2],
[47,45,14,3],
[47,45,14,4],
[47,45,14,5],
[47,45,14,6],
[47,45,14,7],
[47,45,14,8],
[47,45,14,10],
[47,45,14,11],
[47,45,14,12],
[47,45,16,0],
[47,45,16,1],
[47,45,16,2],
[47,45,16,3],
[47,45,16,4],
[47,45,16,5],
[47,45,16,6],
[47,45,16,7],
[47,45,16,8],
[47,45,16,10],
[47,45,16,11],
[47,45,16,12],
[47,45,16,13],
[47,45,16,14],
[47,45,17,0],
[47,45,17,1],
[47,45,17,2],
[47,45,17,3],
[47,45,17,4],
[47,45,17,5],
[47,45,17,6],
[47,45,17,7],
[47,45,17,8],
[47,45,17,10],
[47,45,17,11],
[47,45,17,12],
[47,45,17,13],
[47,45,17,14],
[47,45,17,16],
[47,45,19,0],
[47,45,19,1],
[47,45,19,2],
[47,45,19,3],
[47,45,19,4],
[47,45,19,5],
[47,45,19,6],
[47,45,19,7],
[47,45,19,8],
[47,45,19,10],
[47,45,19,11],
[47,45,19,12],
[47,45,19,16],
[47,45,19,17],
[47,45,20,0],
[47,45,20,1],
[47,45,20,2],
[47,45,20,3],
[47,45,20,4],
[47,45,20,5],
[47,45,20,6],
[47,45,20,7],
[47,45,20,8],
[47,45,20,11],
[47,45,20,12],
[47,45,20,13],
[47,45,20,14],
[47,45,20,19],
[47,45,21,0],
[47,45,21,1],
[47,45,21,2],
[47,45,21,4],
[47,45,21,7],
[47,45,21,10],
[47,45,21,11],
[47,45,21,12],
[47,45,21,13],
[47,45,21,14],
[47,45,21,16],
[47,45,21,17],
[47,45,21,19],
[47,45,21,20],
[47,45,22,0],
[47,45,22,1],
[47,45,22,2],
[47,45,22,3],
[47,45,22,4],
[47,45,22,5],
[47,45,22,6],
[47,45,22,7],
[47,45,22,8],
[47,45,22,11],
[47,45,22,12],
[47,45,22,13],
[47,45,22,16],
[47,45,22,17],
[47,45,22,19],
[47,45,22,21],
[47,45,24,0],
[47,45,24,1],
[47,45,24,2],
[47,45,24,3],
[47,45,24,4],
[47,45,24,5],
[47,45,24,6],
[47,45,24,7],
[47,45,24,8],
[47,45,24,10],
[47,45,24,11],
[47,45,24,14],
[47,45,24,17],
[47,45,24,19],
[47,45,24,20],
[47,45,24,21],
[47,45,24,22],
[47,45,25,0],
[47,45,25,1],
[47,45,25,2],
[47,45,25,3],
[47,45,25,4],
[47,45,25,5],
[47,45,25,6],
[47,45,25,10],
[47,45,25,11],
[47,45,25,12],
[47,45,25,13],
[47,45,25,14],
[47,45,25,16],
[47,45,25,17],
[47,45,25,19],
[47,45,25,20],
[47,45,25,21],
[47,45,25,22],
[47,45,25,24],
[47,45,27,0],
[47,45,27,1],
[47,45,27,2],
[47,45,27,3],
[47,45,27,4],
[47,45,27,6],
[47,45,27,7],
[47,45,27,8],
[47,45,27,11],
[47,45,27,12],
[47,45,27,13],
[47,45,27,14],
[47,45,27,16],
[47,45,27,17],
[47,45,27,19],
[47,45,27,21],
[47,45,28,0],
[47,45,28,1],
[47,45,28,2],
[47,45,28,5],
[47,45,28,6],
[47,45,28,8],
[47,45,28,10],
[47,45,28,11],
[47,45,28,12],
[47,45,28,13],
[47,45,28,14],
[47,45,28,16],
[47,45,28,17],
[47,45,28,19],
[47,45,28,20],
[47,45,28,21],
[47,45,29,3],
[47,45,29,4],
[47,45,29,5],
[47,45,29,6],
[47,45,29,7],
[47,45,29,8],
[47,45,29,10],
[47,45,29,13],
[47,45,29,14],
[47,45,29,16],
[47,45,29,17],
[47,45,29,19],
[47,45,29,20],
[47,45,32,0],
[47,45,32,1],
[47,45,32,2],
[47,45,32,3],
[47,45,32,4],
[47,45,32,5],
[47,45,32,6],
[47,45,32,7],
[47,45,32,8],
[47,45,32,10],
[47,45,32,13],
[47,45,32,14],
[47,45,32,16],
[47,45,32,17],
[47,45,33,0],
[47,45,33,1],
[47,45,33,2],
[47,45,33,3],
[47,45,33,4],
[47,45,33,5],
[47,45,33,6],
[47,45,33,7],
[47,45,33,8],
[47,45,33,10],
[47,45,33,13],
[47,45,33,14],
[47,45,34,0],
[47,45,34,1],
[47,45,34,2],
[47,45,34,3],
[47,45,34,4],
[47,45,34,5],
[47,45,34,6],
[47,45,34,8],
[47,45,34,10],
[47,45,34,11],
[47,45,34,12],
[47,45,34,13],
[47,45,34,14],
[47,45,36,0],
[47,45,36,1],
[47,45,36,2],
[47,45,36,3],
[47,45,36,4],
[47,45,36,5],
[47,45,36,6],
[47,45,36,7],
[47,45,36,8],
[47,45,36,10],
[47,45,36,11],
[47,45,37,0],
[47,45,37,1],
[47,45,37,2],
[47,45,37,3],
[47,45,37,5],
[47,45,37,6],
[47,45,37,7],
[47,45,37,8],
[47,45,38,0],
[47,45,38,1],
[47,45,38,2],
[47,45,38,3],
[47,45,38,4],
[47,45,38,5],
[47,45,38,6],
[47,45,39,0],
[47,45,39,1],
[47,45,39,2],
[47,45,39,4],
[47,45,42,0],
[47,45,42,1],
[47,45,42,2],
[47,46,3,0],
[47,46,3,1],
[47,46,3,2],
[47,46,4,0],
[47,46,4,1],
[47,46,4,2],
[47,46,4,3],
[47,46,5,0],
[47,46,5,1],
[47,46,5,2],
[47,46,5,4],
[47,46,6,0],
[47,46,6,1],
[47,46,6,2],
[47,46,6,4],
[47,46,7,0],
[47,46,7,1],
[47,46,7,2],
[47,46,7,3],
[47,46,7,4],
[47,46,7,5],
[47,46,7,6],
[47,46,8,0],
[47,46,8,1],
[47,46,8,2],
[47,46,8,4],
[47,46,8,7],
[47,46,10,0],
[47,46,10,1],
[47,46,10,2],
[47,46,10,3],
[47,46,10,4],
[47,46,10,5],
[47,46,10,6],
[47,46,10,7],
[47,46,10,8],
[47,46,11,0],
[47,46,11,1],
[47,46,11,2],
[47,46,11,3],
[47,46,11,4],
[47,46,11,5],
[47,46,11,6],
[47,46,11,7],
[47,46,11,8],
[47,46,11,10],
[47,46,12,0],
[47,46,12,1],
[47,46,12,2],
[47,46,12,3],
[47,46,12,4],
[47,46,12,5],
[47,46,12,6],
[47,46,12,7],
[47,46,12,8],
[47,46,12,10],
[47,46,13,0],
[47,46,13,1],
[47,46,13,2],
[47,46,13,3],
[47,46,13,4],
[47,46,13,5],
[47,46,13,6],
[47,46,13,7],
[47,46,13,8],
[47,46,13,10],
[47,46,13,11],
[47,46,13,12],
[47,46,14,0],
[47,46,14,1],
[47,46,14,2],
[47,46,14,3],
[47,46,14,4],
[47,46,14,5],
[47,46,14,6],
[47,46,14,7],
[47,46,14,8],
[47,46,14,10],
[47,46,14,11],
[47,46,14,12],
[47,46,16,0],
[47,46,16,1],
[47,46,16,2],
[47,46,16,3],
[47,46,16,4],
[47,46,16,5],
[47,46,16,6],
[47,46,16,7],
[47,46,16,8],
[47,46,16,10],
[47,46,16,11],
[47,46,16,12],
[47,46,16,13],
[47,46,16,14],
[47,46,17,0],
[47,46,17,1],
[47,46,17,2],
[47,46,17,3],
[47,46,17,4],
[47,46,17,5],
[47,46,17,6],
[47,46,17,7],
[47,46,17,8],
[47,46,17,10],
[47,46,17,11],
[47,46,17,12],
[47,46,17,13],
[47,46,17,14],
[47,46,17,16],
[47,46,19,0],
[47,46,19,1],
[47,46,19,2],
[47,46,19,3],
[47,46,19,4],
[47,46,19,5],
[47,46,19,6],
[47,46,19,7],
[47,46,19,8],
[47,46,19,10],
[47,46,19,11],
[47,46,19,12],
[47,46,20,0],
[47,46,20,1],
[47,46,20,2],
[47,46,20,3],
[47,46,20,4],
[47,46,20,5],
[47,46,20,6],
[47,46,20,7],
[47,46,20,8],
[47,46,20,11],
[47,46,20,12],
[47,46,20,13],
[47,46,20,14],
[47,46,20,16],
[47,46,20,17],
[47,46,20,19],
[47,46,21,0],
[47,46,21,1],
[47,46,21,2],
[47,46,21,4],
[47,46,21,7],
[47,46,21,10],
[47,46,21,11],
[47,46,21,12],
[47,46,21,13],
[47,46,21,16],
[47,46,21,17],
[47,46,21,19],
[47,46,21,20],
[47,46,22,0],
[47,46,22,1],
[47,46,22,2],
[47,46,22,3],
[47,46,22,4],
[47,46,22,5],
[47,46,22,6],
[47,46,22,7],
[47,46,22,8],
[47,46,22,11],
[47,46,22,14],
[47,46,22,16],
[47,46,22,17],
[47,46,22,19],
[47,46,22,21],
[47,46,24,0],
[47,46,24,1],
[47,46,24,2],
[47,46,24,3],
[47,46,24,4],
[47,46,24,5],
[47,46,24,6],
[47,46,24,7],
[47,46,24,8],
[47,46,24,12],
[47,46,24,13],
[47,46,24,14],
[47,46,24,17],
[47,46,24,19],
[47,46,24,20],
[47,46,24,21],
[47,46,24,22],
[47,46,25,0],
[47,46,25,1],
[47,46,25,2],
[47,46,25,3],
[47,46,25,4],
[47,46,25,5],
[47,46,25,8],
[47,46,25,10],
[47,46,25,11],
[47,46,25,12],
[47,46,25,13],
[47,46,25,14],
[47,46,25,16],
[47,46,25,17],
[47,46,25,19],
[47,46,25,20],
[47,46,25,21],
[47,46,25,22],
[47,46,25,24],
[47,46,27,0],
[47,46,27,1],
[47,46,27,2],
[47,46,27,5],
[47,46,27,6],
[47,46,27,7],
[47,46,27,8],
[47,46,27,11],
[47,46,27,12],
[47,46,27,13],
[47,46,27,14],
[47,46,27,16],
[47,46,27,17],
[47,46,27,19],
[47,46,27,21],
[47,46,28,3],
[47,46,28,4],
[47,46,28,5],
[47,46,28,6],
[47,46,28,8],
[47,46,28,10],
[47,46,28,11],
[47,46,28,12],
[47,46,28,13],
[47,46,28,14],
[47,46,28,16],
[47,46,28,17],
[47,46,28,19],
[47,46,28,20],
[47,46,29,0],
[47,46,29,1],
[47,46,29,2],
[47,46,29,3],
[47,46,29,4],
[47,46,29,5],
[47,46,29,6],
[47,46,29,7],
[47,46,29,8],
[47,46,29,10],
[47,46,29,13],
[47,46,29,14],
[47,46,29,16],
[47,46,29,17],
[47,46,29,19],
[47,46,32,0],
[47,46,32,1],
[47,46,32,2],
[47,46,32,3],
[47,46,32,4],
[47,46,32,5],
[47,46,32,6],
[47,46,32,7],
[47,46,32,8],
[47,46,32,10],
[47,46,32,13],
[47,46,32,14],
[47,46,33,0],
[47,46,33,1],
[47,46,33,2],
[47,46,33,3],
[47,46,33,4],
[47,46,33,5],
[47,46,33,6],
[47,46,33,7],
[47,46,33,8],
[47,46,33,10],
[47,46,33,13],
[47,46,33,14],
[47,46,34,0],
[47,46,34,1],
[47,46,34,2],
[47,46,34,3],
[47,46,34,4],
[47,46,34,5],
[47,46,34,6],
[47,46,34,8],
[47,46,34,10],
[47,46,34,11],
[47,46,34,12],
[47,46,34,13],
[47,46,36,0],
[47,46,36,1],
[47,46,36,2],
[47,46,36,3],
[47,46,36,4],
[47,46,36,5],
[47,46,36,6],
[47,46,36,7],
[47,46,36,8],
[47,46,37,0],
[47,46,37,1],
[47,46,37,2],
[47,46,37,3],
[47,46,37,5],
[47,46,37,6],
[47,46,37,7],
[47,46,38,0],
[47,46,38,1],
[47,46,38,2],
[47,46,38,3],
[47,46,38,4],
[47,46,38,5],
[47,46,39,0],
[47,46,39,1],
[47,46,39,2],
[47,46,39,4],
[48,4,3,0],
[48,4,3,1],
[48,4,3,2],
[48,5,4,0],
[48,5,4,1],
[48,5,4,2],
[48,6,4,0],
[48,6,4,1],
[48,6,4,2],
[48,7,3,0],
[48,7,3,1],
[48,7,3,2],
[48,7,4,0],
[48,7,4,1],
[48,7,4,2],
[48,7,4,3],
[48,7,5,0],
[48,7,5,1],
[48,7,5,2],
[48,7,5,4],
[48,7,6,0],
[48,7,6,1],
[48,7,6,2],
[48,7,6,4],
[48,8,4,0],
[48,8,4,1],
[48,8,4,2],
[48,8,7,0],
[48,8,7,1],
[48,8,7,2],
[48,8,7,4],
[48,9,3,0],
[48,9,3,1],
[48,9,3,2],
[48,9,4,0],
[48,9,4,1],
[48,9,4,2],
[48,9,4,3],
[48,9,5,0],
[48,9,5,1],
[48,9,5,2],
[48,9,5,4],
[48,9,6,0],
[48,9,6,1],
[48,9,6,2],
[48,9,6,4],
[48,9,7,0],
[48,9,7,1],
[48,9,7,2],
[48,9,7,3],
[48,9,7,4],
[48,9,7,5],
[48,9,7,6],
[48,9,8,0],
[48,9,8,1],
[48,9,8,2],
[48,9,8,4],
[48,9,8,7],
[48,10,3,0],
[48,10,3,1],
[48,10,3,2],
[48,10,4,0],
[48,10,4,1],
[48,10,4,2],
[48,10,4,3],
[48,10,5,0],
[48,10,5,1],
[48,10,5,2],
[48,10,5,4],
[48,10,6,0],
[48,10,6,1],
[48,10,6,2],
[48,10,6,4],
[48,10,7,0],
[48,10,7,1],
[48,10,7,2],
[48,10,7,3],
[48,10,7,4],
[48,10,7,5],
[48,10,7,6],
[48,10,8,0],
[48,10,8,1],
[48,10,8,2],
[48,10,8,4],
[48,10,8,7],
[48,10,9,0],
[48,10,9,1],
[48,10,9,2],
[48,10,9,3],
[48,10,9,4],
[48,10,9,5],
[48,10,9,6],
[48,10,9,7],
[48,10,9,8],
[48,13,3,0],
[48,13,3,1],
[48,13,3,2],
[48,13,4,0],
[48,13,4,1],
[48,13,4,2],
[48,13,4,3],
[48,13,5,0],
[48,13,5,1],
[48,13,5,2],
[48,13,5,4],
[48,13,6,0],
[48,13,6,1],
[48,13,6,2],
[48,13,6,4],
[48,13,7,0],
[48,13,7,1],
[48,13,7,2],
[48,13,7,3],
[48,13,7,4],
[48,13,7,5],
[48,13,7,6],
[48,13,8,0],
[48,13,8,1],
[48,13,8,2],
[48,13,8,4],
[48,13,8,7],
[48,13,9,0],
[48,13,9,1],
[48,13,9,2],
[48,13,9,3],
[48,13,9,4],
[48,13,9,5],
[48,13,9,6],
[48,13,9,7],
[48,13,9,8],
[48,13,10,0],
[48,13,10,1],
[48,13,10,2],
[48,13,10,3],
[48,13,10,4],
[48,13,10,5],
[48,13,10,6],
[48,13,10,7],
[48,13,10,8],
[48,13,10,9],
[48,14,3,0],
[48,14,3,1],
[48,14,3,2],
[48,14,4,0],
[48,14,4,1],
[48,14,4,2],
[48,14,4,3],
[48,14,5,0],
[48,14,5,1],
[48,14,5,2],
[48,14,5,4],
[48,14,6,0],
[48,14,6,1],
[48,14,6,2],
[48,14,6,4],
[48,14,7,0],
[48,14,7,1],
[48,14,7,2],
[48,14,7,3],
[48,14,7,4],
[48,14,7,5],
[48,14,7,6],
[48,14,8,0],
[48,14,8,1],
[48,14,8,2],
[48,14,8,4],
[48,14,8,7],
[48,14,9,0],
[48,14,9,1],
[48,14,9,2],
[48,14,9,3],
[48,14,9,4],
[48,14,9,5],
[48,14,9,6],
[48,14,9,7],
[48,14,9,8],
[48,14,10,0],
[48,14,10,1],
[48,14,10,2],
[48,14,10,3],
[48,14,10,4],
[48,14,10,5],
[48,14,10,6],
[48,14,10,7],
[48,14,10,8],
[48,14,10,9],
[48,15,3,0],
[48,15,3,1],
[48,15,3,2],
[48,15,4,0],
[48,15,4,1],
[48,15,4,2],
[48,15,4,3],
[48,15,5,0],
[48,15,5,1],
[48,15,5,2],
[48,15,5,4],
[48,15,6,0],
[48,15,6,1],
[48,15,6,2],
[48,15,6,4],
[48,15,7,0],
[48,15,7,1],
[48,15,7,2],
[48,15,7,3],
[48,15,7,4],
[48,15,7,5],
[48,15,7,6],
[48,15,8,0],
[48,15,8,1],
[48,15,8,2],
[48,15,8,4],
[48,15,8,7],
[48,15,10,0],
[48,15,10,1],
[48,15,10,2],
[48,15,10,3],
[48,15,10,4],
[48,15,10,5],
[48,15,10,6],
[48,15,10,7],
[48,15,10,8],
[48,15,13,0],
[48,15,13,1],
[48,15,13,2],
[48,15,13,3],
[48,15,13,4],
[48,15,13,5],
[48,15,13,6],
[48,15,13,7],
[48,15,13,8],
[48,15,13,10],
[48,15,14,0],
[48,15,14,1],
[48,15,14,2],
[48,15,14,3],
[48,15,14,4],
[48,15,14,5],
[48,15,14,6],
[48,15,14,7],
[48,15,14,8],
[48,15,14,10],
[48,16,3,0],
[48,16,3,1],
[48,16,3,2],
[48,16,4,0],
[48,16,4,1],
[48,16,4,2],
[48,16,4,3],
[48,16,5,0],
[48,16,5,1],
[48,16,5,2],
[48,16,5,4],
[48,16,6,0],
[48,16,6,1],
[48,16,6,2],
[48,16,6,4],
[48,16,7,0],
[48,16,7,1],
[48,16,7,2],
[48,16,7,3],
[48,16,7,4],
[48,16,7,5],
[48,16,7,6],
[48,16,8,0],
[48,16,8,1],
[48,16,8,2],
[48,16,8,4],
[48,16,8,7],
[48,16,9,0],
[48,16,9,1],
[48,16,9,2],
[48,16,9,3],
[48,16,9,4],
[48,16,9,5],
[48,16,9,6],
[48,16,9,7],
[48,16,9,8],
[48,16,10,0],
[48,16,10,1],
[48,16,10,2],
[48,16,10,3],
[48,16,10,4],
[48,16,10,5],
[48,16,10,6],
[48,16,10,7],
[48,16,10,8],
[48,16,10,9],
[48,16,13,0],
[48,16,13,1],
[48,16,13,2],
[48,16,13,3],
[48,16,13,4],
[48,16,13,5],
[48,16,13,6],
[48,16,13,7],
[48,16,13,8],
[48,16,13,9],
[48,16,13,10],
[48,16,14,0],
[48,16,14,1],
[48,16,14,2],
[48,16,14,3],
[48,16,14,4],
[48,16,14,5],
[48,16,14,6],
[48,16,14,7],
[48,16,14,8],
[48,16,14,9],
[48,16,14,10],
[48,16,15,0],
[48,16,15,1],
[48,16,15,2],
[48,16,15,3],
[48,16,15,4],
[48,16,15,5],
[48,16,15,6],
[48,16,15,7],
[48,16,15,8],
[48,16,15,10],
[48,16,15,13],
[48,16,15,14],
[48,17,3,0],
[48,17,3,1],
[48,17,3,2],
[48,17,4,0],
[48,17,4,1],
[48,17,4,2],
[48,17,4,3],
[48,17,5,0],
[48,17,5,1],
[48,17,5,2],
[48,17,5,4],
[48,17,6,0],
[48,17,6,1],
[48,17,6,2],
[48,17,6,4],
[48,17,7,0],
[48,17,7,1],
[48,17,7,2],
[48,17,7,3],
[48,17,7,4],
[48,17,7,5],
[48,17,7,6],
[48,17,8,0],
[48,17,8,1],
[48,17,8,2],
[48,17,8,4],
[48,17,8,7],
[48,17,9,0],
[48,17,9,1],
[48,17,9,2],
[48,17,9,3],
[48,17,9,4],
[48,17,9,5],
[48,17,9,6],
[48,17,9,7],
[48,17,9,8],
[48,17,10,0],
[48,17,10,1],
[48,17,10,2],
[48,17,10,3],
[48,17,10,4],
[48,17,10,5],
[48,17,10,6],
[48,17,10,7],
[48,17,10,8],
[48,17,10,9],
[48,17,13,0],
[48,17,13,1],
[48,17,13,2],
[48,17,13,3],
[48,17,13,4],
[48,17,13,5],
[48,17,13,6],
[48,17,13,7],
[48,17,13,8],
[48,17,13,9],
[48,17,13,10],
[48,17,14,0],
[48,17,14,1],
[48,17,14,2],
[48,17,14,3],
[48,17,14,4],
[48,17,14,5],
[48,17,14,6],
[48,17,14,7],
[48,17,14,8],
[48,17,14,9],
[48,17,14,10],
[48,17,15,0],
[48,17,15,1],
[48,17,15,2],
[48,17,15,3],
[48,17,15,4],
[48,17,15,5],
[48,17,15,6],
[48,17,15,7],
[48,17,15,8],
[48,17,15,10],
[48,17,15,13],
[48,17,15,14],
[48,17,16,0],
[48,17,16,1],
[48,17,16,2],
[48,17,16,3],
[48,17,16,4],
[48,17,16,5],
[48,17,16,6],
[48,17,16,7],
[48,17,16,8],
[48,17,16,9],
[48,17,16,10],
[48,17,16,13],
[48,17,16,14],
[48,17,16,15],
[48,18,3,0],
[48,18,3,1],
[48,18,3,2],
[48,18,4,0],
[48,18,4,1],
[48,18,4,2],
[48,18,4,3],
[48,18,5,0],
[48,18,5,1],
[48,18,5,2],
[48,18,5,4],
[48,18,6,0],
[48,18,6,1],
[48,18,6,2],
[48,18,6,4],
[48,18,7,0],
[48,18,7,1],
[48,18,7,2],
[48,18,7,3],
[48,18,7,4],
[48,18,7,5],
[48,18,7,6],
[48,18,8,0],
[48,18,8,1],
[48,18,8,2],
[48,18,8,4],
[48,18,8,7],
[48,18,10,0],
[48,18,10,1],
[48,18,10,2],
[48,18,10,3],
[48,18,10,4],
[48,18,10,5],
[48,18,10,6],
[48,18,10,7],
[48,18,10,8],
[48,18,13,0],
[48,18,13,1],
[48,18,13,2],
[48,18,13,3],
[48,18,13,4],
[48,18,13,5],
[48,18,13,6],
[48,18,13,7],
[48,18,13,8],
[48,18,13,10],
[48,18,14,0],
[48,18,14,1],
[48,18,14,2],
[48,18,14,3],
[48,18,14,4],
[48,18,14,5],
[48,18,14,6],
[48,18,14,7],
[48,18,14,8],
[48,18,14,10],
[48,18,16,0],
[48,18,16,1],
[48,18,16,2],
[48,18,16,3],
[48,18,16,4],
[48,18,16,5],
[48,18,16,6],
[48,18,16,7],
[48,18,16,8],
[48,18,16,10],
[48,18,16,13],
[48,18,16,14],
[48,18,17,0],
[48,18,17,1],
[48,18,17,2],
[48,18,17,3],
[48,18,17,4],
[48,18,17,5],
[48,18,17,6],
[48,18,17,7],
[48,18,17,8],
[48,18,17,10],
[48,18,17,13],
[48,18,17,14],
[48,18,17,16],
[48,19,3,0],
[48,19,3,1],
[48,19,3,2],
[48,19,4,0],
[48,19,4,1],
[48,19,4,2],
[48,19,4,3],
[48,19,5,0],
[48,19,5,1],
[48,19,5,2],
[48,19,5,4],
[48,19,6,0],
[48,19,6,1],
[48,19,6,2],
[48,19,6,4],
[48,19,7,0],
[48,19,7,1],
[48,19,7,2],
[48,19,7,3],
[48,19,7,4],
[48,19,7,5],
[48,19,7,6],
[48,19,8,0],
[48,19,8,1],
[48,19,8,2],
[48,19,8,4],
[48,19,8,7],
[48,19,9,0],
[48,19,9,1],
[48,19,9,2],
[48,19,9,3],
[48,19,9,4],
[48,19,9,5],
[48,19,9,6],
[48,19,9,7],
[48,19,9,8],
[48,19,10,0],
[48,19,10,1],
[48,19,10,2],
[48,19,10,3],
[48,19,10,4],
[48,19,10,5],
[48,19,10,6],
[48,19,10,7],
[48,19,10,8],
[48,19,10,9],
[48,19,15,0],
[48,19,15,1],
[48,19,15,2],
[48,19,15,3],
[48,19,15,4],
[48,19,15,5],
[48,19,15,6],
[48,19,15,7],
[48,19,15,8],
[48,19,15,10],
[48,19,16,0],
[48,19,16,1],
[48,19,16,2],
[48,19,16,3],
[48,19,16,4],
[48,19,16,5],
[48,19,16,6],
[48,19,16,7],
[48,19,16,8],
[48,19,16,9],
[48,19,16,10],
[48,19,16,15],
[48,19,17,0],
[48,19,17,1],
[48,19,17,2],
[48,19,17,3],
[48,19,17,4],
[48,19,17,5],
[48,19,17,6],
[48,19,17,7],
[48,19,17,8],
[48,19,17,9],
[48,19,17,10],
[48,19,17,15],
[48,19,17,16],
[48,19,18,0],
[48,19,18,1],
[48,19,18,2],
[48,19,18,3],
[48,19,18,4],
[48,19,18,5],
[48,19,18,6],
[48,19,18,7],
[48,19,18,8],
[48,19,18,10],
[48,19,18,16],
[48,19,18,17],
[48,20,3,0],
[48,20,3,1],
[48,20,3,2],
[48,20,4,0],
[48,20,4,1],
[48,20,4,2],
[48,20,4,3],
[48,20,5,0],
[48,20,5,1],
[48,20,5,2],
[48,20,5,4],
[48,20,6,0],
[48,20,6,1],
[48,20,6,2],
[48,20,6,4],
[48,20,7,0],
[48,20,7,1],
[48,20,7,2],
[48,20,7,3],
[48,20,7,4],
[48,20,7,5],
[48,20,7,6],
[48,20,8,0],
[48,20,8,1],
[48,20,8,2],
[48,20,8,4],
[48,20,8,7],
[48,20,9,0],
[48,20,9,1],
[48,20,9,2],
[48,20,9,3],
[48,20,9,4],
[48,20,9,5],
[48,20,9,6],
[48,20,9,7],
[48,20,9,8],
[48,20,13,0],
[48,20,13,1],
[48,20,13,2],
[48,20,13,3],
[48,20,13,4],
[48,20,13,5],
[48,20,13,6],
[48,20,13,7],
[48,20,13,8],
[48,20,13,9],
[48,20,14,0],
[48,20,14,1],
[48,20,14,2],
[48,20,14,3],
[48,20,14,4],
[48,20,14,5],
[48,20,14,6],
[48,20,14,7],
[48,20,14,8],
[48,20,14,9],
[48,20,15,0],
[48,20,15,1],
[48,20,15,2],
[48,20,15,3],
[48,20,15,4],
[48,20,15,5],
[48,20,15,6],
[48,20,15,7],
[48,20,15,8],
[48,20,15,13],
[48,20,15,14],
[48,20,16,0],
[48,20,16,1],
[48,20,16,2],
[48,20,16,3],
[48,20,16,4],
[48,20,16,5],
[48,20,16,6],
[48,20,16,7],
[48,20,16,8],
[48,20,16,9],
[48,20,16,13],
[48,20,16,14],
[48,20,16,15],
[48,20,17,0],
[48,20,17,1],
[48,20,17,2],
[48,20,17,3],
[48,20,17,4],
[48,20,17,5],
[48,20,17,6],
[48,20,17,7],
[48,20,17,8],
[48,20,17,9],
[48,20,17,13],
[48,20,17,14],
[48,20,17,15],
[48,20,17,16],
[48,20,18,0],
[48,20,18,1],
[48,20,18,2],
[48,20,18,3],
[48,20,18,4],
[48,20,18,5],
[48,20,18,6],
[48,20,18,7],
[48,20,18,8],
[48,20,18,13],
[48,20,18,14],
[48,20,18,16],
[48,20,18,17],
[48,20,19,0],
[48,20,19,1],
[48,20,19,2],
[48,20,19,3],
[48,20,19,4],
[48,20,19,5],
[48,20,19,6],
[48,20,19,7],
[48,20,19,8],
[48,20,19,9],
[48,20,19,15],
[48,20,19,16],
[48,20,19,17],
[48,20,19,18],
[48,21,4,0],
[48,21,4,1],
[48,21,4,2],
[48,21,7,0],
[48,21,7,1],
[48,21,7,2],
[48,21,7,4],
[48,21,9,0],
[48,21,9,1],
[48,21,9,2],
[48,21,9,4],
[48,21,9,7],
[48,21,10,0],
[48,21,10,1],
[48,21,10,2],
[48,21,10,4],
[48,21,10,7],
[48,21,10,9],
[48,21,13,0],
[48,21,13,1],
[48,21,13,2],
[48,21,13,4],
[48,21,13,7],
[48,21,13,9],
[48,21,13,10],
[48,21,14,0],
[48,21,14,1],
[48,21,14,2],
[48,21,14,4],
[48,21,14,7],
[48,21,14,9],
[48,21,14,10],
[48,21,15,0],
[48,21,15,1],
[48,21,15,2],
[48,21,15,4],
[48,21,15,7],
[48,21,15,10],
[48,21,15,13],
[48,21,15,14],
[48,21,16,0],
[48,21,16,1],
[48,21,16,2],
[48,21,16,4],
[48,21,16,7],
[48,21,16,9],
[48,21,16,10],
[48,21,16,13],
[48,21,16,14],
[48,21,16,15],
[48,21,17,0],
[48,21,17,1],
[48,21,17,2],
[48,21,17,4],
[48,21,17,7],
[48,21,17,9],
[48,21,17,10],
[48,21,17,13],
[48,21,17,14],
[48,21,17,15],
[48,21,17,16],
[48,21,18,0],
[48,21,18,1],
[48,21,18,2],
[48,21,18,4],
[48,21,18,7],
[48,21,18,10],
[48,21,18,13],
[48,21,18,14],
[48,21,18,16],
[48,21,18,17],
[48,21,19,0],
[48,21,19,1],
[48,21,19,2],
[48,21,19,4],
[48,21,19,7],
[48,21,19,9],
[48,21,19,10],
[48,21,19,15],
[48,21,19,16],
[48,21,19,17],
[48,21,19,18],
[48,21,20,0],
[48,21,20,1],
[48,21,20,2],
[48,21,20,4],
[48,21,20,7],
[48,21,20,9],
[48,21,20,13],
[48,21,20,14],
[48,21,20,15],
[48,21,20,16],
[48,21,20,17],
[48,21,20,18],
[48,21,20,19],
[48,22,3,0],
[48,22,3,1],
[48,22,3,2],
[48,22,4,0],
[48,22,4,1],
[48,22,4,2],
[48,22,4,3],
[48,22,5,0],
[48,22,5,1],
[48,22,5,2],
[48,22,5,4],
[48,22,6,0],
[48,22,6,1],
[48,22,6,2],
[48,22,6,4],
[48,22,7,0],
[48,22,7,1],
[48,22,7,2],
[48,22,7,3],
[48,22,7,4],
[48,22,7,5],
[48,22,7,6],
[48,22,8,0],
[48,22,8,1],
[48,22,8,2],
[48,22,8,4],
[48,22,8,7],
[48,22,9,0],
[48,22,9,1],
[48,22,9,2],
[48,22,9,3],
[48,22,9,4],
[48,22,9,5],
[48,22,9,6],
[48,22,9,7],
[48,22,9,8],
[48,22,13,0],
[48,22,13,1],
[48,22,13,2],
[48,22,13,3],
[48,22,13,4],
[48,22,13,5],
[48,22,13,6],
[48,22,13,7],
[48,22,13,8],
[48,22,13,9],
[48,22,14,0],
[48,22,14,1],
[48,22,14,2],
[48,22,14,3],
[48,22,14,4],
[48,22,14,5],
[48,22,14,6],
[48,22,14,7],
[48,22,14,8],
[48,22,14,9],
[48,22,15,0],
[48,22,15,1],
[48,22,15,2],
[48,22,15,3],
[48,22,15,4],
[48,22,15,5],
[48,22,15,6],
[48,22,15,7],
[48,22,15,8],
[48,22,15,13],
[48,22,15,14],
[48,22,16,0],
[48,22,16,1],
[48,22,16,2],
[48,22,16,3],
[48,22,16,4],
[48,22,16,5],
[48,22,16,6],
[48,22,16,7],
[48,22,16,8],
[48,22,16,9],
[48,22,16,13],
[48,22,16,14],
[48,22,16,15],
[48,22,17,0],
[48,22,17,1],
[48,22,17,2],
[48,22,17,3],
[48,22,17,4],
[48,22,17,5],
[48,22,17,6],
[48,22,17,7],
[48,22,17,8],
[48,22,17,9],
[48,22,17,13],
[48,22,17,14],
[48,22,17,15],
[48,22,17,16],
[48,22,18,0],
[48,22,18,1],
[48,22,18,2],
[48,22,18,3],
[48,22,18,4],
[48,22,18,5],
[48,22,18,6],
[48,22,18,7],
[48,22,18,8],
[48,22,18,13],
[48,22,18,14],
[48,22,18,16],
[48,22,18,17],
[48,22,19,0],
[48,22,19,1],
[48,22,19,2],
[48,22,19,3],
[48,22,19,4],
[48,22,19,5],
[48,22,19,6],
[48,22,19,7],
[48,22,19,8],
[48,22,19,9],
[48,22,19,15],
[48,22,19,16],
[48,22,19,17],
[48,22,19,18],
[48,22,21,0],
[48,22,21,1],
[48,22,21,2],
[48,22,21,4],
[48,22,21,7],
[48,22,21,9],
[48,22,21,13],
[48,22,21,14],
[48,22,21,15],
[48,22,21,16],
[48,22,21,17],
[48,22,21,18],
[48,22,21,19],
[48,23,3,0],
[48,23,3,1],
[48,23,3,2],
[48,23,4,0],
[48,23,4,1],
[48,23,4,2],
[48,23,4,3],
[48,23,5,0],
[48,23,5,1],
[48,23,5,2],
[48,23,5,4],
[48,23,6,0],
[48,23,6,1],
[48,23,6,2],
[48,23,6,4],
[48,23,7,0],
[48,23,7,1],
[48,23,7,2],
[48,23,7,3],
[48,23,7,4],
[48,23,7,5],
[48,23,7,6],
[48,23,8,0],
[48,23,8,1],
[48,23,8,2],
[48,23,8,4],
[48,23,8,7],
[48,23,9,0],
[48,23,9,1],
[48,23,9,2],
[48,23,9,3],
[48,23,9,4],
[48,23,9,5],
[48,23,9,6],
[48,23,9,7],
[48,23,9,8],
[48,23,10,0],
[48,23,10,1],
[48,23,10,2],
[48,23,10,3],
[48,23,10,4],
[48,23,10,5],
[48,23,10,6],
[48,23,10,7],
[48,23,10,8],
[48,23,10,9],
[48,23,13,0],
[48,23,13,1],
[48,23,13,2],
[48,23,13,3],
[48,23,13,4],
[48,23,13,5],
[48,23,13,6],
[48,23,13,7],
[48,23,13,8],
[48,23,13,9],
[48,23,13,10],
[48,23,14,0],
[48,23,14,1],
[48,23,14,2],
[48,23,14,3],
[48,23,14,4],
[48,23,14,5],
[48,23,14,6],
[48,23,14,7],
[48,23,14,8],
[48,23,14,9],
[48,23,14,10],
[48,23,15,0],
[48,23,15,1],
[48,23,15,2],
[48,23,15,3],
[48,23,15,4],
[48,23,15,5],
[48,23,15,6],
[48,23,15,7],
[48,23,15,8],
[48,23,15,10],
[48,23,15,13],
[48,23,15,14],
[48,23,16,0],
[48,23,16,1],
[48,23,16,2],
[48,23,16,3],
[48,23,16,4],
[48,23,16,5],
[48,23,16,6],
[48,23,16,7],
[48,23,16,8],
[48,23,16,9],
[48,23,16,10],
[48,23,16,13],
[48,23,16,14],
[48,23,16,15],
[48,23,17,0],
[48,23,17,1],
[48,23,17,2],
[48,23,17,3],
[48,23,17,4],
[48,23,17,5],
[48,23,17,6],
[48,23,17,7],
[48,23,17,8],
[48,23,17,9],
[48,23,17,10],
[48,23,17,13],
[48,23,17,14],
[48,23,17,15],
[48,23,17,16],
[48,23,18,0],
[48,23,18,1],
[48,23,18,2],
[48,23,18,3],
[48,23,18,4],
[48,23,18,5],
[48,23,18,6],
[48,23,18,7],
[48,23,18,8],
[48,23,18,10],
[48,23,18,13],
[48,23,18,14],
[48,23,18,16],
[48,23,18,17],
[48,23,19,0],
[48,23,19,1],
[48,23,19,2],
[48,23,19,3],
[48,23,19,4],
[48,23,19,5],
[48,23,19,6],
[48,23,19,7],
[48,23,19,8],
[48,23,19,9],
[48,23,19,10],
[48,23,19,15],
[48,23,19,16],
[48,23,19,17],
[48,23,19,18],
[48,23,20,0],
[48,23,20,1],
[48,23,20,2],
[48,23,20,3],
[48,23,20,4],
[48,23,20,5],
[48,23,20,6],
[48,23,20,7],
[48,23,20,8],
[48,23,20,9],
[48,23,20,13],
[48,23,20,14],
[48,23,20,15],
[48,23,20,16],
[48,23,20,17],
[48,23,20,18],
[48,23,20,19],
[48,23,21,0],
[48,23,21,1],
[48,23,21,2],
[48,23,21,4],
[48,23,21,7],
[48,23,21,9],
[48,23,21,10],
[48,23,21,13],
[48,23,21,14],
[48,23,21,15],
[48,23,21,16],
[48,23,21,17],
[48,23,21,18],
[48,23,21,19],
[48,23,21,20],
[48,23,22,0],
[48,23,22,1],
[48,23,22,2],
[48,23,22,3],
[48,23,22,4],
[48,23,22,5],
[48,23,22,6],
[48,23,22,7],
[48,23,22,8],
[48,23,22,9],
[48,23,22,13],
[48,23,22,14],
[48,23,22,15],
[48,23,22,16],
[48,23,22,17],
[48,23,22,18],
[48,23,22,19],
[48,23,22,21],
[48,24,3,0],
[48,24,3,1],
[48,24,3,2],
[48,24,4,0],
[48,24,4,1],
[48,24,4,2],
[48,24,4,3],
[48,24,5,0],
[48,24,5,1],
[48,24,5,2],
[48,24,5,4],
[48,24,6,0],
[48,24,6,1],
[48,24,6,2],
[48,24,6,4],
[48,24,7,0],
[48,24,7,1],
[48,24,7,2],
[48,24,7,3],
[48,24,7,4],
[48,24,7,5],
[48,24,7,6],
[48,24,8,0],
[48,24,8,1],
[48,24,8,2],
[48,24,8,4],
[48,24,8,7],
[48,24,9,0],
[48,24,9,1],
[48,24,9,2],
[48,24,9,3],
[48,24,9,4],
[48,24,9,5],
[48,24,9,6],
[48,24,9,7],
[48,24,9,8],
[48,24,10,0],
[48,24,10,1],
[48,24,10,2],
[48,24,10,3],
[48,24,10,4],
[48,24,10,5],
[48,24,10,6],
[48,24,10,7],
[48,24,10,8],
[48,24,10,9],
[48,24,13,0],
[48,24,13,1],
[48,24,13,2],
[48,24,13,3],
[48,24,13,4],
[48,24,13,5],
[48,24,13,6],
[48,24,13,7],
[48,24,13,8],
[48,24,13,9],
[48,24,13,10],
[48,24,14,0],
[48,24,14,1],
[48,24,14,2],
[48,24,14,3],
[48,24,14,4],
[48,24,14,5],
[48,24,14,6],
[48,24,14,7],
[48,24,14,8],
[48,24,14,9],
[48,24,14,10],
[48,24,15,0],
[48,24,15,1],
[48,24,15,2],
[48,24,15,3],
[48,24,15,4],
[48,24,15,5],
[48,24,15,6],
[48,24,15,7],
[48,24,15,8],
[48,24,15,10],
[48,24,15,13],
[48,24,15,14],
[48,24,17,0],
[48,24,17,1],
[48,24,17,2],
[48,24,17,3],
[48,24,17,4],
[48,24,17,5],
[48,24,17,6],
[48,24,17,7],
[48,24,17,8],
[48,24,17,9],
[48,24,17,10],
[48,24,17,13],
[48,24,17,14],
[48,24,17,15],
[48,24,18,0],
[48,24,18,1],
[48,24,18,2],
[48,24,18,3],
[48,24,18,4],
[48,24,18,5],
[48,24,18,6],
[48,24,18,7],
[48,24,18,8],
[48,24,18,10],
[48,24,18,13],
[48,24,18,14],
[48,24,18,17],
[48,24,19,0],
[48,24,19,1],
[48,24,19,2],
[48,24,19,3],
[48,24,19,4],
[48,24,19,5],
[48,24,19,6],
[48,24,19,7],
[48,24,19,8],
[48,24,19,9],
[48,24,19,10],
[48,24,19,15],
[48,24,19,17],
[48,24,19,18],
[48,24,20,0],
[48,24,20,1],
[48,24,20,2],
[48,24,20,3],
[48,24,20,4],
[48,24,20,5],
[48,24,20,6],
[48,24,20,7],
[48,24,20,8],
[48,24,20,9],
[48,24,20,13],
[48,24,20,14],
[48,24,20,15],
[48,24,20,17],
[48,24,20,18],
[48,24,20,19],
[48,24,21,0],
[48,24,21,1],
[48,24,21,2],
[48,24,21,4],
[48,24,21,7],
[48,24,21,9],
[48,24,21,10],
[48,24,21,13],
[48,24,21,14],
[48,24,21,15],
[48,24,21,17],
[48,24,21,18],
[48,24,21,19],
[48,24,21,20],
[48,24,22,0],
[48,24,22,1],
[48,24,22,2],
[48,24,22,3],
[48,24,22,4],
[48,24,22,5],
[48,24,22,6],
[48,24,22,7],
[48,24,22,8],
[48,24,22,9],
[48,24,22,13],
[48,24,22,14],
[48,24,22,15],
[48,24,22,17],
[48,24,22,18],
[48,24,22,19],
[48,24,22,21],
[48,24,23,0],
[48,24,23,1],
[48,24,23,2],
[48,24,23,3],
[48,24,23,4],
[48,24,23,5],
[48,24,23,6],
[48,24,23,7],
[48,24,23,8],
[48,24,23,9],
[48,24,23,10],
[48,24,23,13],
[48,24,23,14],
[48,24,23,15],
[48,24,23,17],
[48,24,23,18],
[48,24,23,19],
[48,24,23,20],
[48,24,23,21],
[48,24,23,22],
[48,25,3,0],
[48,25,3,1],
[48,25,3,2],
[48,25,4,0],
[48,25,4,1],
[48,25,4,2],
[48,25,4,3],
[48,25,5,0],
[48,25,5,1],
[48,25,5,2],
[48,25,5,4],
[48,25,6,0],
[48,25,6,1],
[48,25,6,2],
[48,25,6,4],
[48,25,8,0],
[48,25,8,1],
[48,25,8,2],
[48,25,8,4],
[48,25,9,0],
[48,25,9,1],
[48,25,9,2],
[48,25,9,3],
[48,25,9,4],
[48,25,9,5],
[48,25,9,6],
[48,25,9,8],
[48,25,10,0],
[48,25,10,1],
[48,25,10,2],
[48,25,10,3],
[48,25,10,4],
[48,25,10,5],
[48,25,10,6],
[48,25,10,8],
[48,25,10,9],
[48,25,13,0],
[48,25,13,1],
[48,25,13,2],
[48,25,13,3],
[48,25,13,4],
[48,25,13,5],
[48,25,13,6],
[48,25,13,8],
[48,25,13,9],
[48,25,13,10],
[48,25,14,0],
[48,25,14,1],
[48,25,14,2],
[48,25,14,3],
[48,25,14,4],
[48,25,14,5],
[48,25,14,6],
[48,25,14,8],
[48,25,14,9],
[48,25,14,10],
[48,25,15,0],
[48,25,15,1],
[48,25,15,2],
[48,25,15,3],
[48,25,15,4],
[48,25,15,5],
[48,25,15,6],
[48,25,15,8],
[48,25,15,10],
[48,25,15,13],
[48,25,15,14],
[48,25,16,0],
[48,25,16,1],
[48,25,16,2],
[48,25,16,3],
[48,25,16,4],
[48,25,16,5],
[48,25,16,6],
[48,25,16,8],
[48,25,16,9],
[48,25,16,10],
[48,25,16,13],
[48,25,16,14],
[48,25,16,15],
[48,25,17,0],
[48,25,17,1],
[48,25,17,2],
[48,25,17,3],
[48,25,17,4],
[48,25,17,5],
[48,25,17,6],
[48,25,17,8],
[48,25,17,9],
[48,25,17,10],
[48,25,17,13],
[48,25,17,14],
[48,25,17,15],
[48,25,17,16],
[48,25,18,0],
[48,25,18,1],
[48,25,18,2],
[48,25,18,3],
[48,25,18,4],
[48,25,18,5],
[48,25,18,6],
[48,25,18,8],
[48,25,18,10],
[48,25,18,13],
[48,25,18,14],
[48,25,18,16],
[48,25,18,17],
[48,25,19,0],
[48,25,19,1],
[48,25,19,2],
[48,25,19,3],
[48,25,19,4],
[48,25,19,5],
[48,25,19,6],
[48,25,19,8],
[48,25,19,9],
[48,25,19,10],
[48,25,19,15],
[48,25,19,16],
[48,25,19,17],
[48,25,19,18],
[48,25,20,0],
[48,25,20,1],
[48,25,20,2],
[48,25,20,3],
[48,25,20,4],
[48,25,20,5],
[48,25,20,6],
[48,25,20,8],
[48,25,20,9],
[48,25,20,13],
[48,25,20,14],
[48,25,20,15],
[48,25,20,16],
[48,25,20,17],
[48,25,20,18],
[48,25,20,19],
[48,25,21,0],
[48,25,21,1],
[48,25,21,2],
[48,25,21,4],
[48,25,21,9],
[48,25,21,10],
[48,25,21,13],
[48,25,21,14],
[48,25,21,15],
[48,25,21,16],
[48,25,21,17],
[48,25,21,18],
[48,25,21,19],
[48,25,21,20],
[48,25,22,0],
[48,25,22,1],
[48,25,22,2],
[48,25,22,3],
[48,25,22,4],
[48,25,22,5],
[48,25,22,6],
[48,25,22,8],
[48,25,22,9],
[48,25,22,13],
[48,25,22,14],
[48,25,22,15],
[48,25,22,16],
[48,25,22,17],
[48,25,22,18],
[48,25,22,19],
[48,25,22,21],
[48,25,23,0],
[48,25,23,1],
[48,25,23,2],
[48,25,23,3],
[48,25,23,4],
[48,25,23,5],
[48,25,23,6],
[48,25,23,8],
[48,25,23,9],
[48,25,23,10],
[48,25,23,13],
[48,25,23,14],
[48,25,23,15],
[48,25,23,16],
[48,25,23,17],
[48,25,23,18],
[48,25,23,19],
[48,25,23,20],
[48,25,23,21],
[48,25,23,22],
[48,25,24,0],
[48,25,24,1],
[48,25,24,2],
[48,25,24,3],
[48,25,24,4],
[48,25,24,5],
[48,25,24,6],
[48,25,24,8],
[48,25,24,9],
[48,25,24,10],
[48,25,24,13],
[48,25,24,14],
[48,25,24,15],
[48,25,24,17],
[48,25,24,18],
[48,25,24,19],
[48,25,24,20],
[48,25,24,21],
[48,25,24,22],
[48,25,24,23],
[48,26,3,0],
[48,26,3,1],
[48,26,3,2],
[48,26,4,0],
[48,26,4,1],
[48,26,4,2],
[48,26,4,3],
[48,26,5,0],
[48,26,5,1],
[48,26,5,2],
[48,26,5,4],
[48,26,6,0],
[48,26,6,1],
[48,26,6,2],
[48,26,6,4],
[48,26,7,0],
[48,26,7,1],
[48,26,7,2],
[48,26,7,3],
[48,26,7,4],
[48,26,7,5],
[48,26,7,6],
[48,26,8,0],
[48,26,8,1],
[48,26,8,2],
[48,26,8,4],
[48,26,8,7],
[48,26,10,0],
[48,26,10,1],
[48,26,10,2],
[48,26,10,3],
[48,26,10,4],
[48,26,10,5],
[48,26,10,6],
[48,26,10,7],
[48,26,10,8],
[48,26,13,0],
[48,26,13,1],
[48,26,13,2],
[48,26,13,3],
[48,26,13,4],
[48,26,13,5],
[48,26,13,6],
[48,26,13,7],
[48,26,13,8],
[48,26,13,10],
[48,26,14,0],
[48,26,14,1],
[48,26,14,2],
[48,26,14,3],
[48,26,14,4],
[48,26,14,5],
[48,26,14,6],
[48,26,14,7],
[48,26,14,8],
[48,26,14,10],
[48,26,16,0],
[48,26,16,1],
[48,26,16,2],
[48,26,16,3],
[48,26,16,4],
[48,26,16,5],
[48,26,16,6],
[48,26,16,7],
[48,26,16,8],
[48,26,16,10],
[48,26,16,13],
[48,26,16,14],
[48,26,17,0],
[48,26,17,1],
[48,26,17,2],
[48,26,17,3],
[48,26,17,4],
[48,26,17,5],
[48,26,17,6],
[48,26,17,7],
[48,26,17,8],
[48,26,17,10],
[48,26,17,13],
[48,26,17,14],
[48,26,17,16],
[48,26,19,0],
[48,26,19,1],
[48,26,19,2],
[48,26,19,3],
[48,26,19,4],
[48,26,19,5],
[48,26,19,6],
[48,26,19,7],
[48,26,19,8],
[48,26,19,10],
[48,26,19,16],
[48,26,19,17],
[48,26,20,0],
[48,26,20,1],
[48,26,20,2],
[48,26,20,3],
[48,26,20,4],
[48,26,20,5],
[48,26,20,6],
[48,26,20,7],
[48,26,20,8],
[48,26,20,13],
[48,26,20,14],
[48,26,20,16],
[48,26,20,17],
[48,26,20,19],
[48,26,21,0],
[48,26,21,1],
[48,26,21,2],
[48,26,21,4],
[48,26,21,7],
[48,26,21,10],
[48,26,21,13],
[48,26,21,14],
[48,26,21,16],
[48,26,21,17],
[48,26,21,19],
[48,26,21,20],
[48,26,22,0],
[48,26,22,1],
[48,26,22,2],
[48,26,22,3],
[48,26,22,4],
[48,26,22,5],
[48,26,22,6],
[48,26,22,7],
[48,26,22,8],
[48,26,22,13],
[48,26,22,14],
[48,26,22,16],
[48,26,22,17],
[48,26,22,19],
[48,26,22,21],
[48,26,23,0],
[48,26,23,1],
[48,26,23,2],
[48,26,23,3],
[48,26,23,4],
[48,26,23,5],
[48,26,23,6],
[48,26,23,7],
[48,26,23,8],
[48,26,23,10],
[48,26,23,13],
[48,26,23,14],
[48,26,23,16],
[48,26,23,17],
[48,26,23,19],
[48,26,23,20],
[48,26,23,21],
[48,26,23,22],
[48,26,24,0],
[48,26,24,1],
[48,26,24,2],
[48,26,24,3],
[48,26,24,4],
[48,26,24,5],
[48,26,24,6],
[48,26,24,7],
[48,26,24,8],
[48,26,24,10],
[48,26,24,13],
[48,26,24,14],
[48,26,24,17],
[48,26,24,19],
[48,26,24,20],
[48,26,24,21],
[48,26,24,22],
[48,26,24,23],
[48,26,25,0],
[48,26,25,1],
[48,26,25,2],
[48,26,25,3],
[48,26,25,4],
[48,26,25,5],
[48,26,25,6],
[48,26,25,8],
[48,26,25,10],
[48,26,25,13],
[48,26,25,14],
[48,26,25,16],
[48,26,25,17],
[48,26,25,19],
[48,26,25,20],
[48,26,25,21],
[48,26,25,22],
[48,26,25,23],
[48,26,25,24],
[48,27,3,0],
[48,27,3,1],
[48,27,3,2],
[48,27,4,0],
[48,27,4,1],
[48,27,4,2],
[48,27,4,3],
[48,27,5,0],
[48,27,5,1],
[48,27,5,2],
[48,27,5,4],
[48,27,6,0],
[48,27,6,1],
[48,27,6,2],
[48,27,6,4],
[48,27,7,0],
[48,27,7,1],
[48,27,7,2],
[48,27,7,3],
[48,27,7,4],
[48,27,7,5],
[48,27,7,6],
[48,27,8,0],
[48,27,8,1],
[48,27,8,2],
[48,27,8,4],
[48,27,8,7],
[48,27,9,0],
[48,27,9,1],
[48,27,9,2],
[48,27,9,3],
[48,27,9,4],
[48,27,9,5],
[48,27,9,6],
[48,27,9,7],
[48,27,9,8],
[48,27,13,0],
[48,27,13,1],
[48,27,13,2],
[48,27,13,3],
[48,27,13,4],
[48,27,13,5],
[48,27,13,6],
[48,27,13,7],
[48,27,13,8],
[48,27,13,9],
[48,27,14,0],
[48,27,14,1],
[48,27,14,2],
[48,27,14,3],
[48,27,14,4],
[48,27,14,5],
[48,27,14,6],
[48,27,14,7],
[48,27,14,8],
[48,27,14,9],
[48,27,15,0],
[48,27,15,1],
[48,27,15,2],
[48,27,15,3],
[48,27,15,4],
[48,27,15,5],
[48,27,15,6],
[48,27,15,7],
[48,27,15,8],
[48,27,15,13],
[48,27,15,14],
[48,27,16,0],
[48,27,16,1],
[48,27,16,2],
[48,27,16,3],
[48,27,16,4],
[48,27,16,5],
[48,27,16,6],
[48,27,16,7],
[48,27,16,8],
[48,27,16,9],
[48,27,16,13],
[48,27,16,14],
[48,27,16,15],
[48,27,17,0],
[48,27,17,1],
[48,27,17,2],
[48,27,17,3],
[48,27,17,4],
[48,27,17,5],
[48,27,17,6],
[48,27,17,7],
[48,27,17,8],
[48,27,17,9],
[48,27,17,13],
[48,27,17,14],
[48,27,17,15],
[48,27,17,16],
[48,27,18,0],
[48,27,18,1],
[48,27,18,2],
[48,27,18,3],
[48,27,18,4],
[48,27,18,5],
[48,27,18,6],
[48,27,18,7],
[48,27,18,8],
[48,27,18,13],
[48,27,18,14],
[48,27,18,16],
[48,27,18,17],
[48,27,19,0],
[48,27,19,1],
[48,27,19,2],
[48,27,19,3],
[48,27,19,4],
[48,27,19,5],
[48,27,19,6],
[48,27,19,7],
[48,27,19,8],
[48,27,19,9],
[48,27,19,15],
[48,27,19,16],
[48,27,19,17],
[48,27,19,18],
[48,27,21,0],
[48,27,21,1],
[48,27,21,2],
[48,27,21,4],
[48,27,21,7],
[48,27,21,9],
[48,27,21,13],
[48,27,21,14],
[48,27,21,15],
[48,27,21,16],
[48,27,21,17],
[48,27,21,18],
[48,27,21,19],
[48,27,23,0],
[48,27,23,1],
[48,27,23,2],
[48,27,23,3],
[48,27,23,4],
[48,27,23,5],
[48,27,23,6],
[48,27,23,7],
[48,27,23,8],
[48,27,23,9],
[48,27,23,13],
[48,27,23,14],
[48,27,23,15],
[48,27,23,16],
[48,27,23,17],
[48,27,23,18],
[48,27,23,19],
[48,27,23,21],
[48,27,24,0],
[48,27,24,1],
[48,27,24,2],
[48,27,24,3],
[48,27,24,4],
[48,27,24,5],
[48,27,24,6],
[48,27,24,7],
[48,27,24,8],
[48,27,24,9],
[48,27,24,13],
[48,27,24,14],
[48,27,24,15],
[48,27,24,17],
[48,27,24,18],
[48,27,24,19],
[48,27,24,21],
[48,27,24,23],
[48,27,25,0],
[48,27,25,1],
[48,27,25,2],
[48,27,25,3],
[48,27,25,4],
[48,27,25,5],
[48,27,25,6],
[48,27,25,8],
[48,27,25,9],
[48,27,25,13],
[48,27,25,14],
[48,27,25,15],
[48,27,25,16],
[48,27,25,17],
[48,27,25,18],
[48,27,25,19],
[48,27,25,21],
[48,27,25,23],
[48,27,25,24],
[48,27,26,0],
[48,27,26,1],
[48,27,26,2],
[48,27,26,3],
[48,27,26,4],
[48,27,26,5],
[48,27,26,6],
[48,27,26,7],
[48,27,26,8],
[48,27,26,13],
[48,27,26,14],
[48,27,26,16],
[48,27,26,17],
[48,27,26,19],
[48,27,26,21],
[48,27,26,23],
[48,27,26,24],
[48,27,26,25],
[48,28,3,0],
[48,28,3,1],
[48,28,3,2],
[48,28,4,0],
[48,28,4,1],
[48,28,4,2],
[48,28,4,3],
[48,28,5,0],
[48,28,5,1],
[48,28,5,2],
[48,28,5,4],
[48,28,6,0],
[48,28,6,1],
[48,28,6,2],
[48,28,6,4],
[48,28,8,0],
[48,28,8,1],
[48,28,8,2],
[48,28,8,4],
[48,28,9,0],
[48,28,9,1],
[48,28,9,2],
[48,28,9,3],
[48,28,9,4],
[48,28,9,5],
[48,28,9,6],
[48,28,9,8],
[48,28,10,0],
[48,28,10,1],
[48,28,10,2],
[48,28,10,3],
[48,28,10,4],
[48,28,10,5],
[48,28,10,6],
[48,28,10,8],
[48,28,10,9],
[48,28,13,0],
[48,28,13,1],
[48,28,13,2],
[48,28,13,3],
[48,28,13,4],
[48,28,13,5],
[48,28,13,6],
[48,28,13,8],
[48,28,13,9],
[48,28,13,10],
[48,28,14,0],
[48,28,14,1],
[48,28,14,2],
[48,28,14,3],
[48,28,14,4],
[48,28,14,5],
[48,28,14,6],
[48,28,14,8],
[48,28,14,9],
[48,28,14,10],
[48,28,15,0],
[48,28,15,1],
[48,28,15,2],
[48,28,15,3],
[48,28,15,4],
[48,28,15,5],
[48,28,15,6],
[48,28,15,8],
[48,28,15,10],
[48,28,15,13],
[48,28,15,14],
[48,28,16,0],
[48,28,16,1],
[48,28,16,2],
[48,28,16,3],
[48,28,16,4],
[48,28,16,5],
[48,28,16,6],
[48,28,16,8],
[48,28,16,9],
[48,28,16,10],
[48,28,16,13],
[48,28,16,14],
[48,28,16,15],
[48,28,17,0],
[48,28,17,1],
[48,28,17,2],
[48,28,17,3],
[48,28,17,4],
[48,28,17,5],
[48,28,17,6],
[48,28,17,8],
[48,28,17,9],
[48,28,17,10],
[48,28,17,13],
[48,28,17,14],
[48,28,17,15],
[48,28,17,16],
[48,28,18,0],
[48,28,18,1],
[48,28,18,2],
[48,28,18,3],
[48,28,18,4],
[48,28,18,5],
[48,28,18,6],
[48,28,18,8],
[48,28,18,10],
[48,28,18,13],
[48,28,18,14],
[48,28,18,16],
[48,28,18,17],
[48,28,19,0],
[48,28,19,1],
[48,28,19,2],
[48,28,19,3],
[48,28,19,4],
[48,28,19,5],
[48,28,19,6],
[48,28,19,8],
[48,28,19,9],
[48,28,19,10],
[48,28,19,15],
[48,28,19,16],
[48,28,19,17],
[48,28,19,18],
[48,28,20,0],
[48,28,20,1],
[48,28,20,2],
[48,28,20,3],
[48,28,20,4],
[48,28,20,5],
[48,28,20,6],
[48,28,20,8],
[48,28,20,9],
[48,28,20,13],
[48,28,20,14],
[48,28,20,15],
[48,28,20,16],
[48,28,20,17],
[48,28,20,18],
[48,28,20,19],
[48,28,21,0],
[48,28,21,1],
[48,28,21,2],
[48,28,21,4],
[48,28,21,9],
[48,28,21,10],
[48,28,21,13],
[48,28,21,14],
[48,28,21,15],
[48,28,21,16],
[48,28,21,17],
[48,28,21,18],
[48,28,21,19],
[48,28,21,20],
[48,28,22,0],
[48,28,22,1],
[48,28,22,2],
[48,28,22,3],
[48,28,22,4],
[48,28,22,5],
[48,28,22,6],
[48,28,22,8],
[48,28,22,9],
[48,28,22,13],
[48,28,22,14],
[48,28,22,15],
[48,28,22,16],
[48,28,22,17],
[48,28,22,18],
[48,28,22,19],
[48,28,22,21],
[48,28,23,0],
[48,28,23,1],
[48,28,23,2],
[48,28,23,3],
[48,28,23,4],
[48,28,23,5],
[48,28,23,6],
[48,28,23,8],
[48,28,23,9],
[48,28,23,10],
[48,28,23,13],
[48,28,23,14],
[48,28,23,15],
[48,28,23,16],
[48,28,23,17],
[48,28,23,18],
[48,28,23,19],
[48,28,23,20],
[48,28,23,21],
[48,28,23,22],
[48,28,24,0],
[48,28,24,1],
[48,28,24,2],
[48,28,24,3],
[48,28,24,4],
[48,28,24,5],
[48,28,24,6],
[48,28,24,8],
[48,28,24,9],
[48,28,24,10],
[48,28,24,13],
[48,28,24,14],
[48,28,24,15],
[48,28,24,17],
[48,28,24,18],
[48,28,24,19],
[48,28,24,20],
[48,28,24,21],
[48,28,24,22],
[48,28,24,23],
[48,28,26,0],
[48,28,26,1],
[48,28,26,2],
[48,28,26,3],
[48,28,26,4],
[48,28,26,5],
[48,28,26,6],
[48,28,26,8],
[48,28,26,10],
[48,28,26,13],
[48,28,26,14],
[48,28,26,16],
[48,28,26,17],
[48,28,26,19],
[48,28,26,20],
[48,28,26,21],
[48,28,26,22],
[48,28,26,23],
[48,28,26,24],
[48,28,27,0],
[48,28,27,1],
[48,28,27,2],
[48,28,27,3],
[48,28,27,4],
[48,28,27,5],
[48,28,27,6],
[48,28,27,8],
[48,28,27,9],
[48,28,27,13],
[48,28,27,14],
[48,28,27,15],
[48,28,27,16],
[48,28,27,17],
[48,28,27,18],
[48,28,27,19],
[48,28,27,21],
[48,28,27,23],
[48,28,27,24],
[48,28,27,26],
[48,30,3,0],
[48,30,3,1],
[48,30,3,2],
[48,30,4,0],
[48,30,4,1],
[48,30,4,2],
[48,30,4,3],
[48,30,5,0],
[48,30,5,1],
[48,30,5,2],
[48,30,5,4],
[48,30,6,0],
[48,30,6,1],
[48,30,6,2],
[48,30,6,4],
[48,30,7,0],
[48,30,7,1],
[48,30,7,2],
[48,30,7,3],
[48,30,7,4],
[48,30,7,5],
[48,30,7,6],
[48,30,8,0],
[48,30,8,1],
[48,30,8,2],
[48,30,8,4],
[48,30,8,7],
[48,30,9,0],
[48,30,9,1],
[48,30,9,2],
[48,30,9,3],
[48,30,9,4],
[48,30,9,5],
[48,30,9,6],
[48,30,9,7],
[48,30,9,8],
[48,30,10,0],
[48,30,10,1],
[48,30,10,2],
[48,30,10,3],
[48,30,10,4],
[48,30,10,5],
[48,30,10,6],
[48,30,10,7],
[48,30,10,8],
[48,30,10,9],
[48,30,13,0],
[48,30,13,1],
[48,30,13,2],
[48,30,13,3],
[48,30,13,4],
[48,30,13,5],
[48,30,13,6],
[48,30,13,7],
[48,30,13,8],
[48,30,13,9],
[48,30,13,10],
[48,30,14,0],
[48,30,14,1],
[48,30,14,2],
[48,30,14,3],
[48,30,14,4],
[48,30,14,5],
[48,30,14,6],
[48,30,14,7],
[48,30,14,8],
[48,30,14,9],
[48,30,14,10],
[48,30,15,0],
[48,30,15,1],
[48,30,15,2],
[48,30,15,3],
[48,30,15,4],
[48,30,15,5],
[48,30,15,6],
[48,30,15,7],
[48,30,15,8],
[48,30,15,10],
[48,30,15,13],
[48,30,15,14],
[48,30,16,0],
[48,30,16,1],
[48,30,16,2],
[48,30,16,3],
[48,30,16,4],
[48,30,16,5],
[48,30,16,6],
[48,30,16,7],
[48,30,16,8],
[48,30,16,9],
[48,30,16,10],
[48,30,16,13],
[48,30,16,14],
[48,30,16,15],
[48,30,17,0],
[48,30,17,1],
[48,30,17,2],
[48,30,17,3],
[48,30,17,4],
[48,30,17,5],
[48,30,17,6],
[48,30,17,7],
[48,30,17,8],
[48,30,17,9],
[48,30,17,10],
[48,30,17,13],
[48,30,17,14],
[48,30,17,15],
[48,30,17,16],
[48,30,18,0],
[48,30,18,1],
[48,30,18,2],
[48,30,18,3],
[48,30,18,4],
[48,30,18,5],
[48,30,18,6],
[48,30,18,7],
[48,30,18,8],
[48,30,18,10],
[48,30,18,13],
[48,30,18,14],
[48,30,18,16],
[48,30,18,17],
[48,30,19,0],
[48,30,19,1],
[48,30,19,2],
[48,30,19,3],
[48,30,19,4],
[48,30,19,5],
[48,30,19,6],
[48,30,19,7],
[48,30,19,8],
[48,30,19,9],
[48,30,19,10],
[48,30,19,15],
[48,30,19,16],
[48,30,19,17],
[48,30,19,18],
[48,30,20,0],
[48,30,20,1],
[48,30,20,2],
[48,30,20,3],
[48,30,20,4],
[48,30,20,5],
[48,30,20,6],
[48,30,20,7],
[48,30,20,8],
[48,30,20,9],
[48,30,20,13],
[48,30,20,14],
[48,30,20,15],
[48,30,20,16],
[48,30,20,17],
[48,30,20,18],
[48,30,20,19],
[48,30,21,0],
[48,30,21,1],
[48,30,21,2],
[48,30,21,4],
[48,30,21,7],
[48,30,21,9],
[48,30,21,10],
[48,30,21,13],
[48,30,21,14],
[48,30,21,15],
[48,30,21,16],
[48,30,21,17],
[48,30,21,18],
[48,30,21,19],
[48,30,21,20],
[48,30,22,0],
[48,30,22,1],
[48,30,22,2],
[48,30,22,3],
[48,30,22,4],
[48,30,22,5],
[48,30,22,6],
[48,30,22,7],
[48,30,22,8],
[48,30,22,9],
[48,30,22,13],
[48,30,22,14],
[48,30,22,15],
[48,30,22,16],
[48,30,22,17],
[48,30,22,18],
[48,30,22,19],
[48,30,22,21],
[48,30,24,0],
[48,30,24,1],
[48,30,24,2],
[48,30,24,3],
[48,30,24,4],
[48,30,24,5],
[48,30,24,6],
[48,30,24,7],
[48,30,24,8],
[48,30,24,9],
[48,30,24,10],
[48,30,24,13],
[48,30,24,14],
[48,30,24,15],
[48,30,24,17],
[48,30,24,18],
[48,30,24,19],
[48,30,24,20],
[48,30,24,21],
[48,30,24,22],
[48,30,25,0],
[48,30,25,1],
[48,30,25,2],
[48,30,25,3],
[48,30,25,4],
[48,30,25,5],
[48,30,25,6],
[48,30,25,8],
[48,30,25,9],
[48,30,25,10],
[48,30,25,13],
[48,30,25,14],
[48,30,25,15],
[48,30,25,16],
[48,30,25,17],
[48,30,25,18],
[48,30,25,19],
[48,30,25,20],
[48,30,25,21],
[48,30,25,22],
[48,30,25,24],
[48,30,26,0],
[48,30,26,1],
[48,30,26,2],
[48,30,26,3],
[48,30,26,4],
[48,30,26,5],
[48,30,26,6],
[48,30,26,7],
[48,30,26,8],
[48,30,26,10],
[48,30,26,13],
[48,30,26,14],
[48,30,26,16],
[48,30,26,17],
[48,30,26,19],
[48,30,26,20],
[48,30,26,21],
[48,30,26,22],
[48,30,26,24],
[48,30,26,25],
[48,30,27,0],
[48,30,27,1],
[48,30,27,2],
[48,30,27,3],
[48,30,27,4],
[48,30,27,5],
[48,30,27,6],
[48,30,27,7],
[48,30,27,8],
[48,30,27,9],
[48,30,27,13],
[48,30,27,14],
[48,30,27,15],
[48,30,27,16],
[48,30,27,17],
[48,30,27,18],
[48,30,27,19],
[48,30,27,21],
[48,30,27,25],
[48,30,27,26],
[48,30,28,0],
[48,30,28,1],
[48,30,28,2],
[48,30,28,3],
[48,30,28,4],
[48,30,28,5],
[48,30,28,6],
[48,30,28,8],
[48,30,28,9],
[48,30,28,10],
[48,30,28,13],
[48,30,28,14],
[48,30,28,15],
[48,30,28,16],
[48,30,28,17],
[48,30,28,18],
[48,30,28,19],
[48,30,28,20],
[48,30,28,21],
[48,30,28,24],
[48,30,28,26],
[48,30,28,27],
[48,31,3,0],
[48,31,3,1],
[48,31,3,2],
[48,31,4,0],
[48,31,4,1],
[48,31,4,2],
[48,31,4,3],
[48,31,5,0],
[48,31,5,1],
[48,31,5,2],
[48,31,5,4],
[48,31,6,0],
[48,31,6,1],
[48,31,6,2],
[48,31,6,4],
[48,31,7,0],
[48,31,7,1],
[48,31,7,2],
[48,31,7,3],
[48,31,7,4],
[48,31,7,5],
[48,31,7,6],
[48,31,8,0],
[48,31,8,1],
[48,31,8,2],
[48,31,8,4],
[48,31,8,7],
[48,31,9,0],
[48,31,9,1],
[48,31,9,2],
[48,31,9,3],
[48,31,9,4],
[48,31,9,5],
[48,31,9,6],
[48,31,9,7],
[48,31,9,8],
[48,31,10,0],
[48,31,10,1],
[48,31,10,2],
[48,31,10,3],
[48,31,10,4],
[48,31,10,5],
[48,31,10,6],
[48,31,10,7],
[48,31,10,8],
[48,31,10,9],
[48,31,13,0],
[48,31,13,1],
[48,31,13,2],
[48,31,13,3],
[48,31,13,4],
[48,31,13,5],
[48,31,13,6],
[48,31,13,7],
[48,31,13,8],
[48,31,13,9],
[48,31,13,10],
[48,31,14,0],
[48,31,14,1],
[48,31,14,2],
[48,31,14,3],
[48,31,14,4],
[48,31,14,5],
[48,31,14,6],
[48,31,14,7],
[48,31,14,8],
[48,31,14,9],
[48,31,14,10],
[48,31,15,0],
[48,31,15,1],
[48,31,15,2],
[48,31,15,3],
[48,31,15,4],
[48,31,15,5],
[48,31,15,6],
[48,31,15,7],
[48,31,15,8],
[48,31,15,10],
[48,31,15,13],
[48,31,15,14],
[48,31,16,0],
[48,31,16,1],
[48,31,16,2],
[48,31,16,3],
[48,31,16,4],
[48,31,16,5],
[48,31,16,6],
[48,31,16,7],
[48,31,16,8],
[48,31,16,9],
[48,31,16,10],
[48,31,16,13],
[48,31,16,14],
[48,31,16,15],
[48,31,17,0],
[48,31,17,1],
[48,31,17,2],
[48,31,17,3],
[48,31,17,4],
[48,31,17,5],
[48,31,17,6],
[48,31,17,7],
[48,31,17,8],
[48,31,17,9],
[48,31,17,10],
[48,31,17,13],
[48,31,17,14],
[48,31,17,15],
[48,31,17,16],
[48,31,18,0],
[48,31,18,1],
[48,31,18,2],
[48,31,18,3],
[48,31,18,4],
[48,31,18,5],
[48,31,18,6],
[48,31,18,7],
[48,31,18,8],
[48,31,18,10],
[48,31,18,13],
[48,31,18,14],
[48,31,18,16],
[48,31,18,17],
[48,31,19,0],
[48,31,19,1],
[48,31,19,2],
[48,31,19,3],
[48,31,19,4],
[48,31,19,5],
[48,31,19,6],
[48,31,19,7],
[48,31,19,8],
[48,31,19,9],
[48,31,19,10],
[48,31,19,15],
[48,31,19,16],
[48,31,19,17],
[48,31,19,18],
[48,31,20,0],
[48,31,20,1],
[48,31,20,2],
[48,31,20,3],
[48,31,20,4],
[48,31,20,5],
[48,31,20,6],
[48,31,20,7],
[48,31,20,8],
[48,31,20,9],
[48,31,20,13],
[48,31,20,14],
[48,31,20,15],
[48,31,20,16],
[48,31,20,17],
[48,31,20,18],
[48,31,20,19],
[48,31,21,0],
[48,31,21,1],
[48,31,21,2],
[48,31,21,4],
[48,31,21,7],
[48,31,21,9],
[48,31,21,10],
[48,31,21,13],
[48,31,21,14],
[48,31,21,15],
[48,31,21,16],
[48,31,21,17],
[48,31,21,18],
[48,31,21,19],
[48,31,21,20],
[48,31,22,0],
[48,31,22,1],
[48,31,22,2],
[48,31,22,3],
[48,31,22,4],
[48,31,22,5],
[48,31,22,6],
[48,31,22,7],
[48,31,22,8],
[48,31,22,9],
[48,31,22,13],
[48,31,22,14],
[48,31,22,15],
[48,31,22,16],
[48,31,22,17],
[48,31,22,18],
[48,31,22,19],
[48,31,22,21],
[48,31,24,0],
[48,31,24,1],
[48,31,24,2],
[48,31,24,3],
[48,31,24,4],
[48,31,24,5],
[48,31,24,6],
[48,31,24,7],
[48,31,24,8],
[48,31,24,9],
[48,31,24,10],
[48,31,24,13],
[48,31,24,14],
[48,31,24,15],
[48,31,24,17],
[48,31,24,18],
[48,31,24,19],
[48,31,24,20],
[48,31,24,21],
[48,31,24,22],
[48,31,25,0],
[48,31,25,1],
[48,31,25,2],
[48,31,25,3],
[48,31,25,4],
[48,31,25,5],
[48,31,25,6],
[48,31,25,8],
[48,31,25,9],
[48,31,25,10],
[48,31,25,13],
[48,31,25,14],
[48,31,25,15],
[48,31,25,16],
[48,31,25,17],
[48,31,25,18],
[48,31,25,19],
[48,31,25,20],
[48,31,25,21],
[48,31,25,22],
[48,31,25,24],
[48,31,26,0],
[48,31,26,1],
[48,31,26,2],
[48,31,26,3],
[48,31,26,4],
[48,31,26,5],
[48,31,26,6],
[48,31,26,7],
[48,31,26,8],
[48,31,26,10],
[48,31,26,13],
[48,31,26,14],
[48,31,26,16],
[48,31,26,17],
[48,31,26,19],
[48,31,26,20],
[48,31,26,21],
[48,31,26,22],
[48,31,26,25],
[48,31,27,0],
[48,31,27,1],
[48,31,27,2],
[48,31,27,3],
[48,31,27,4],
[48,31,27,5],
[48,31,27,6],
[48,31,27,7],
[48,31,27,8],
[48,31,27,9],
[48,31,27,13],
[48,31,27,14],
[48,31,27,15],
[48,31,27,16],
[48,31,27,17],
[48,31,27,18],
[48,31,27,19],
[48,31,27,21],
[48,31,27,24],
[48,31,27,25],
[48,31,27,26],
[48,31,28,0],
[48,31,28,1],
[48,31,28,2],
[48,31,28,3],
[48,31,28,4],
[48,31,28,5],
[48,31,28,6],
[48,31,28,8],
[48,31,28,9],
[48,31,28,10],
[48,31,28,13],
[48,31,28,14],
[48,31,28,15],
[48,31,28,16],
[48,31,28,17],
[48,31,28,18],
[48,31,28,19],
[48,31,28,20],
[48,31,28,22],
[48,31,28,24],
[48,31,28,26],
[48,31,28,27],
[48,34,3,0],
[48,34,3,1],
[48,34,3,2],
[48,34,4,0],
[48,34,4,1],
[48,34,4,2],
[48,34,4,3],
[48,34,5,0],
[48,34,5,1],
[48,34,5,2],
[48,34,5,4],
[48,34,6,0],
[48,34,6,1],
[48,34,6,2],
[48,34,6,4],
[48,34,8,0],
[48,34,8,1],
[48,34,8,2],
[48,34,8,4],
[48,34,9,0],
[48,34,9,1],
[48,34,9,2],
[48,34,9,3],
[48,34,9,4],
[48,34,9,5],
[48,34,9,6],
[48,34,9,8],
[48,34,10,0],
[48,34,10,1],
[48,34,10,2],
[48,34,10,3],
[48,34,10,4],
[48,34,10,5],
[48,34,10,6],
[48,34,10,8],
[48,34,10,9],
[48,34,13,0],
[48,34,13,1],
[48,34,13,2],
[48,34,13,3],
[48,34,13,4],
[48,34,13,5],
[48,34,13,6],
[48,34,13,8],
[48,34,13,9],
[48,34,13,10],
[48,34,14,0],
[48,34,14,1],
[48,34,14,2],
[48,34,14,3],
[48,34,14,4],
[48,34,14,5],
[48,34,14,6],
[48,34,14,8],
[48,34,14,9],
[48,34,14,10],
[48,34,15,0],
[48,34,15,1],
[48,34,15,2],
[48,34,15,3],
[48,34,15,4],
[48,34,15,5],
[48,34,15,6],
[48,34,15,8],
[48,34,15,10],
[48,34,15,13],
[48,34,15,14],
[48,34,16,0],
[48,34,16,1],
[48,34,16,2],
[48,34,16,3],
[48,34,16,4],
[48,34,16,5],
[48,34,16,6],
[48,34,16,8],
[48,34,16,9],
[48,34,16,10],
[48,34,16,13],
[48,34,16,14],
[48,34,16,15],
[48,34,17,0],
[48,34,17,1],
[48,34,17,2],
[48,34,17,3],
[48,34,17,4],
[48,34,17,5],
[48,34,17,6],
[48,34,17,8],
[48,34,17,9],
[48,34,17,10],
[48,34,17,13],
[48,34,17,14],
[48,34,17,15],
[48,34,17,16],
[48,34,18,0],
[48,34,18,1],
[48,34,18,2],
[48,34,18,3],
[48,34,18,4],
[48,34,18,5],
[48,34,18,6],
[48,34,18,8],
[48,34,18,10],
[48,34,18,13],
[48,34,18,14],
[48,34,18,16],
[48,34,18,17],
[48,34,19,0],
[48,34,19,1],
[48,34,19,2],
[48,34,19,3],
[48,34,19,4],
[48,34,19,5],
[48,34,19,6],
[48,34,19,8],
[48,34,19,9],
[48,34,19,10],
[48,34,19,15],
[48,34,19,16],
[48,34,19,17],
[48,34,19,18],
[48,34,20,0],
[48,34,20,1],
[48,34,20,2],
[48,34,20,3],
[48,34,20,4],
[48,34,20,5],
[48,34,20,6],
[48,34,20,8],
[48,34,20,9],
[48,34,20,13],
[48,34,20,14],
[48,34,20,15],
[48,34,20,16],
[48,34,20,17],
[48,34,20,18],
[48,34,20,19],
[48,34,21,0],
[48,34,21,1],
[48,34,21,2],
[48,34,21,4],
[48,34,21,9],
[48,34,21,10],
[48,34,21,13],
[48,34,21,14],
[48,34,21,15],
[48,34,21,16],
[48,34,21,17],
[48,34,21,18],
[48,34,21,19],
[48,34,21,20],
[48,34,22,0],
[48,34,22,1],
[48,34,22,2],
[48,34,22,3],
[48,34,22,4],
[48,34,22,5],
[48,34,22,6],
[48,34,22,8],
[48,34,22,9],
[48,34,22,13],
[48,34,22,14],
[48,34,22,15],
[48,34,22,16],
[48,34,22,17],
[48,34,22,18],
[48,34,22,19],
[48,34,22,21],
[48,34,23,0],
[48,34,23,1],
[48,34,23,2],
[48,34,23,3],
[48,34,23,4],
[48,34,23,5],
[48,34,23,6],
[48,34,23,8],
[48,34,23,9],
[48,34,23,10],
[48,34,23,13],
[48,34,23,14],
[48,34,23,15],
[48,34,23,16],
[48,34,23,17],
[48,34,23,18],
[48,34,23,19],
[48,34,23,20],
[48,34,23,21],
[48,34,23,22],
[48,34,24,0],
[48,34,24,1],
[48,34,24,2],
[48,34,24,3],
[48,34,24,4],
[48,34,24,5],
[48,34,24,6],
[48,34,24,8],
[48,34,24,9],
[48,34,24,10],
[48,34,24,13],
[48,34,24,14],
[48,34,24,15],
[48,34,24,17],
[48,34,24,18],
[48,34,24,19],
[48,34,24,20],
[48,34,24,21],
[48,34,24,22],
[48,34,26,0],
[48,34,26,1],
[48,34,26,2],
[48,34,26,3],
[48,34,26,4],
[48,34,26,5],
[48,34,26,6],
[48,34,26,8],
[48,34,26,10],
[48,34,26,13],
[48,34,26,14],
[48,34,26,16],
[48,34,26,17],
[48,34,26,19],
[48,34,26,21],
[48,34,26,22],
[48,34,26,23],
[48,34,26,24],
[48,34,27,0],
[48,34,27,1],
[48,34,27,2],
[48,34,27,3],
[48,34,27,4],
[48,34,27,5],
[48,34,27,6],
[48,34,27,8],
[48,34,27,9],
[48,34,27,13],
[48,34,27,14],
[48,34,27,15],
[48,34,27,16],
[48,34,27,17],
[48,34,27,18],
[48,34,27,21],
[48,34,27,23],
[48,34,27,24],
[48,34,27,26],
[48,34,30,0],
[48,34,30,1],
[48,34,30,2],
[48,34,30,3],
[48,34,30,4],
[48,34,30,5],
[48,34,30,6],
[48,34,30,8],
[48,34,30,9],
[48,34,30,10],
[48,34,30,13],
[48,34,30,14],
[48,34,30,16],
[48,34,30,17],
[48,34,30,18],
[48,34,30,19],
[48,34,30,20],
[48,34,30,21],
[48,34,30,22],
[48,34,30,24],
[48,34,30,26],
[48,34,30,27],
[48,34,31,0],
[48,34,31,1],
[48,34,31,2],
[48,34,31,3],
[48,34,31,4],
[48,34,31,5],
[48,34,31,6],
[48,34,31,8],
[48,34,31,9],
[48,34,31,10],
[48,34,31,13],
[48,34,31,15],
[48,34,31,16],
[48,34,31,17],
[48,34,31,18],
[48,34,31,19],
[48,34,31,20],
[48,34,31,21],
[48,34,31,22],
[48,34,31,24],
[48,34,31,26],
[48,34,31,27],
[48,35,3,0],
[48,35,3,1],
[48,35,3,2],
[48,35,4,0],
[48,35,4,1],
[48,35,4,2],
[48,35,4,3],
[48,35,5,0],
[48,35,5,1],
[48,35,5,2],
[48,35,5,4],
[48,35,6,0],
[48,35,6,1],
[48,35,6,2],
[48,35,6,4],
[48,35,7,0],
[48,35,7,1],
[48,35,7,2],
[48,35,7,3],
[48,35,7,4],
[48,35,7,5],
[48,35,7,6],
[48,35,8,0],
[48,35,8,1],
[48,35,8,2],
[48,35,8,4],
[48,35,8,7],
[48,35,10,0],
[48,35,10,1],
[48,35,10,2],
[48,35,10,3],
[48,35,10,4],
[48,35,10,5],
[48,35,10,6],
[48,35,10,7],
[48,35,10,8],
[48,35,13,0],
[48,35,13,1],
[48,35,13,2],
[48,35,13,3],
[48,35,13,4],
[48,35,13,5],
[48,35,13,6],
[48,35,13,7],
[48,35,13,8],
[48,35,13,10],
[48,35,14,0],
[48,35,14,1],
[48,35,14,2],
[48,35,14,3],
[48,35,14,4],
[48,35,14,5],
[48,35,14,6],
[48,35,14,7],
[48,35,14,8],
[48,35,14,10],
[48,35,16,0],
[48,35,16,1],
[48,35,16,2],
[48,35,16,3],
[48,35,16,4],
[48,35,16,5],
[48,35,16,6],
[48,35,16,7],
[48,35,16,8],
[48,35,16,10],
[48,35,16,13],
[48,35,16,14],
[48,35,17,0],
[48,35,17,1],
[48,35,17,2],
[48,35,17,3],
[48,35,17,4],
[48,35,17,5],
[48,35,17,6],
[48,35,17,7],
[48,35,17,8],
[48,35,17,10],
[48,35,17,13],
[48,35,17,14],
[48,35,17,16],
[48,35,19,0],
[48,35,19,1],
[48,35,19,2],
[48,35,19,3],
[48,35,19,4],
[48,35,19,5],
[48,35,19,6],
[48,35,19,7],
[48,35,19,8],
[48,35,19,10],
[48,35,19,16],
[48,35,19,17],
[48,35,20,0],
[48,35,20,1],
[48,35,20,2],
[48,35,20,3],
[48,35,20,4],
[48,35,20,5],
[48,35,20,6],
[48,35,20,7],
[48,35,20,8],
[48,35,20,13],
[48,35,20,14],
[48,35,20,16],
[48,35,20,17],
[48,35,20,19],
[48,35,21,0],
[48,35,21,1],
[48,35,21,2],
[48,35,21,4],
[48,35,21,7],
[48,35,21,10],
[48,35,21,13],
[48,35,21,14],
[48,35,21,16],
[48,35,21,17],
[48,35,21,19],
[48,35,21,20],
[48,35,22,0],
[48,35,22,1],
[48,35,22,2],
[48,35,22,3],
[48,35,22,4],
[48,35,22,5],
[48,35,22,6],
[48,35,22,7],
[48,35,22,8],
[48,35,22,13],
[48,35,22,14],
[48,35,22,16],
[48,35,22,17],
[48,35,22,19],
[48,35,22,21],
[48,35,23,0],
[48,35,23,1],
[48,35,23,2],
[48,35,23,3],
[48,35,23,4],
[48,35,23,5],
[48,35,23,6],
[48,35,23,7],
[48,35,23,8],
[48,35,23,10],
[48,35,23,13],
[48,35,23,14],
[48,35,23,16],
[48,35,23,17],
[48,35,23,19],
[48,35,23,20],
[48,35,23,21],
[48,35,24,0],
[48,35,24,1],
[48,35,24,2],
[48,35,24,3],
[48,35,24,4],
[48,35,24,5],
[48,35,24,6],
[48,35,24,7],
[48,35,24,8],
[48,35,24,10],
[48,35,24,13],
[48,35,24,14],
[48,35,24,17],
[48,35,24,19],
[48,35,24,20],
[48,35,24,21],
[48,35,24,23],
[48,35,25,0],
[48,35,25,1],
[48,35,25,2],
[48,35,25,3],
[48,35,25,4],
[48,35,25,5],
[48,35,25,6],
[48,35,25,8],
[48,35,25,10],
[48,35,25,13],
[48,35,25,14],
[48,35,25,16],
[48,35,25,17],
[48,35,25,19],
[48,35,25,21],
[48,35,25,22],
[48,35,25,23],
[48,35,25,24],
[48,35,27,0],
[48,35,27,1],
[48,35,27,2],
[48,35,27,3],
[48,35,27,4],
[48,35,27,5],
[48,35,27,6],
[48,35,27,7],
[48,35,27,8],
[48,35,27,13],
[48,35,27,14],
[48,35,27,16],
[48,35,27,17],
[48,35,27,19],
[48,35,27,21],
[48,35,27,23],
[48,35,27,24],
[48,35,27,25],
[48,35,28,0],
[48,35,28,1],
[48,35,28,2],
[48,35,28,3],
[48,35,28,4],
[48,35,28,5],
[48,35,28,6],
[48,35,28,8],
[48,35,28,10],
[48,35,28,13],
[48,35,28,14],
[48,35,28,19],
[48,35,28,20],
[48,35,28,21],
[48,35,28,22],
[48,35,28,23],
[48,35,28,24],
[48,35,28,27],
[48,35,30,0],
[48,35,30,1],
[48,35,30,2],
[48,35,30,3],
[48,35,30,4],
[48,35,30,5],
[48,35,30,6],
[48,35,30,7],
[48,35,30,8],
[48,35,30,10],
[48,35,30,13],
[48,35,30,16],
[48,35,30,17],
[48,35,30,19],
[48,35,30,20],
[48,35,30,21],
[48,35,30,22],
[48,35,30,24],
[48,35,30,25],
[48,35,30,27],
[48,35,31,0],
[48,35,31,1],
[48,35,31,2],
[48,35,31,3],
[48,35,31,4],
[48,35,31,5],
[48,35,31,6],
[48,35,31,7],
[48,35,31,8],
[48,35,31,10],
[48,35,31,14],
[48,35,31,16],
[48,35,31,17],
[48,35,31,19],
[48,35,31,20],
[48,35,31,21],
[48,35,31,22],
[48,35,31,24],
[48,35,31,25],
[48,35,34,0],
[48,35,34,1],
[48,35,34,2],
[48,35,34,3],
[48,35,34,4],
[48,35,34,5],
[48,35,34,8],
[48,35,34,10],
[48,35,34,13],
[48,35,34,14],
[48,35,34,16],
[48,35,34,17],
[48,35,34,19],
[48,35,34,20],
[48,35,34,21],
[48,35,34,22],
[48,35,34,23],
[48,35,34,24],
[48,36,3,0],
[48,36,3,1],
[48,36,3,2],
[48,36,4,0],
[48,36,4,1],
[48,36,4,2],
[48,36,4,3],
[48,36,5,0],
[48,36,5,1],
[48,36,5,2],
[48,36,5,4],
[48,36,6,0],
[48,36,6,1],
[48,36,6,2],
[48,36,6,4],
[48,36,7,0],
[48,36,7,1],
[48,36,7,2],
[48,36,7,3],
[48,36,7,4],
[48,36,7,5],
[48,36,7,6],
[48,36,8,0],
[48,36,8,1],
[48,36,8,2],
[48,36,8,4],
[48,36,8,7],
[48,36,9,0],
[48,36,9,1],
[48,36,9,2],
[48,36,9,3],
[48,36,9,4],
[48,36,9,5],
[48,36,9,6],
[48,36,9,7],
[48,36,9,8],
[48,36,10,0],
[48,36,10,1],
[48,36,10,2],
[48,36,10,3],
[48,36,10,4],
[48,36,10,5],
[48,36,10,6],
[48,36,10,7],
[48,36,10,8],
[48,36,10,9],
[48,36,15,0],
[48,36,15,1],
[48,36,15,2],
[48,36,15,3],
[48,36,15,4],
[48,36,15,5],
[48,36,15,6],
[48,36,15,7],
[48,36,15,8],
[48,36,15,10],
[48,36,16,0],
[48,36,16,1],
[48,36,16,2],
[48,36,16,3],
[48,36,16,4],
[48,36,16,5],
[48,36,16,6],
[48,36,16,7],
[48,36,16,8],
[48,36,16,9],
[48,36,16,10],
[48,36,16,15],
[48,36,17,0],
[48,36,17,1],
[48,36,17,2],
[48,36,17,3],
[48,36,17,4],
[48,36,17,5],
[48,36,17,6],
[48,36,17,7],
[48,36,17,8],
[48,36,17,9],
[48,36,17,10],
[48,36,17,15],
[48,36,17,16],
[48,36,18,0],
[48,36,18,1],
[48,36,18,2],
[48,36,18,3],
[48,36,18,4],
[48,36,18,5],
[48,36,18,6],
[48,36,18,7],
[48,36,18,8],
[48,36,18,10],
[48,36,18,16],
[48,36,18,17],
[48,36,20,0],
[48,36,20,1],
[48,36,20,2],
[48,36,20,3],
[48,36,20,4],
[48,36,20,5],
[48,36,20,6],
[48,36,20,7],
[48,36,20,8],
[48,36,20,9],
[48,36,20,15],
[48,36,20,16],
[48,36,20,17],
[48,36,20,18],
[48,36,21,0],
[48,36,21,1],
[48,36,21,2],
[48,36,21,4],
[48,36,21,7],
[48,36,21,9],
[48,36,21,10],
[48,36,21,15],
[48,36,21,16],
[48,36,21,17],
[48,36,21,18],
[48,36,21,20],
[48,36,22,0],
[48,36,22,1],
[48,36,22,2],
[48,36,22,3],
[48,36,22,4],
[48,36,22,5],
[48,36,22,6],
[48,36,22,7],
[48,36,22,8],
[48,36,22,9],
[48,36,22,15],
[48,36,22,16],
[48,36,22,17],
[48,36,22,18],
[48,36,22,21],
[48,36,23,0],
[48,36,23,1],
[48,36,23,2],
[48,36,23,3],
[48,36,23,4],
[48,36,23,5],
[48,36,23,6],
[48,36,23,7],
[48,36,23,8],
[48,36,23,9],
[48,36,23,10],
[48,36,23,15],
[48,36,23,16],
[48,36,23,17],
[48,36,23,18],
[48,36,23,20],
[48,36,23,22],
[48,36,24,0],
[48,36,24,1],
[48,36,24,2],
[48,36,24,3],
[48,36,24,4],
[48,36,24,5],
[48,36,24,6],
[48,36,24,7],
[48,36,24,8],
[48,36,24,9],
[48,36,24,10],
[48,36,24,15],
[48,36,24,17],
[48,36,24,18],
[48,36,24,20],
[48,36,24,22],
[48,36,24,23],
[48,36,25,0],
[48,36,25,1],
[48,36,25,2],
[48,36,25,3],
[48,36,25,4],
[48,36,25,5],
[48,36,25,6],
[48,36,25,8],
[48,36,25,9],
[48,36,25,10],
[48,36,25,15],
[48,36,25,16],
[48,36,25,17],
[48,36,25,18],
[48,36,25,20],
[48,36,25,21],
[48,36,25,22],
[48,36,25,23],
[48,36,25,24],
[48,36,26,0],
[48,36,26,1],
[48,36,26,2],
[48,36,26,3],
[48,36,26,4],
[48,36,26,5],
[48,36,26,6],
[48,36,26,7],
[48,36,26,8],
[48,36,26,10],
[48,36,26,16],
[48,36,26,17],
[48,36,26,20],
[48,36,26,21],
[48,36,26,22],
[48,36,26,23],
[48,36,26,24],
[48,36,26,25],
[48,36,27,0],
[48,36,27,1],
[48,36,27,2],
[48,36,27,3],
[48,36,27,4],
[48,36,27,5],
[48,36,27,6],
[48,36,27,7],
[48,36,27,8],
[48,36,27,9],
[48,36,27,15],
[48,36,27,18],
[48,36,27,21],
[48,36,27,23],
[48,36,27,24],
[48,36,27,25],
[48,36,27,26],
[48,36,28,0],
[48,36,28,1],
[48,36,28,2],
[48,36,28,3],
[48,36,28,4],
[48,36,28,5],
[48,36,28,6],
[48,36,28,8],
[48,36,28,9],
[48,36,28,10],
[48,36,28,16],
[48,36,28,17],
[48,36,28,18],
[48,36,28,20],
[48,36,28,21],
[48,36,28,22],
[48,36,28,23],
[48,36,28,24],
[48,36,28,26],
[48,36,28,27],
[48,36,30,0],
[48,36,30,1],
[48,36,30,2],
[48,36,30,3],
[48,36,30,4],
[48,36,30,5],
[48,36,30,6],
[48,36,30,7],
[48,36,30,8],
[48,36,30,9],
[48,36,30,10],
[48,36,30,15],
[48,36,30,16],
[48,36,30,17],
[48,36,30,18],
[48,36,30,20],
[48,36,30,21],
[48,36,30,22],
[48,36,30,24],
[48,36,30,25],
[48,36,30,26],
[48,36,31,0],
[48,36,31,1],
[48,36,31,2],
[48,36,31,3],
[48,36,31,4],
[48,36,31,5],
[48,36,31,6],
[48,36,31,7],
[48,36,31,8],
[48,36,31,9],
[48,36,31,15],
[48,36,31,16],
[48,36,31,17],
[48,36,31,18],
[48,36,31,20],
[48,36,31,21],
[48,36,31,22],
[48,36,31,24],
[48,36,31,25],
[48,36,34,0],
[48,36,34,1],
[48,36,34,2],
[48,36,34,3],
[48,36,34,4],
[48,36,34,6],
[48,36,34,8],
[48,36,34,9],
[48,36,34,10],
[48,36,34,15],
[48,36,34,16],
[48,36,34,17],
[48,36,34,18],
[48,36,34,20],
[48,36,34,21],
[48,36,34,22],
[48,36,35,0],
[48,36,35,1],
[48,36,35,2],
[48,36,35,5],
[48,36,35,6],
[48,36,35,7],
[48,36,35,8],
[48,36,35,10],
[48,36,35,16],
[48,36,35,17],
[48,36,35,20],
[48,36,35,21],
[48,37,3,0],
[48,37,3,1],
[48,37,3,2],
[48,37,5,0],
[48,37,5,1],
[48,37,5,2],
[48,37,6,0],
[48,37,6,1],
[48,37,6,2],
[48,37,7,0],
[48,37,7,1],
[48,37,7,2],
[48,37,7,3],
[48,37,7,5],
[48,37,7,6],
[48,37,8,0],
[48,37,8,1],
[48,37,8,2],
[48,37,8,7],
[48,37,9,0],
[48,37,9,1],
[48,37,9,2],
[48,37,9,3],
[48,37,9,5],
[48,37,9,6],
[48,37,9,7],
[48,37,9,8],
[48,37,10,0],
[48,37,10,1],
[48,37,10,2],
[48,37,10,3],
[48,37,10,5],
[48,37,10,6],
[48,37,10,7],
[48,37,10,8],
[48,37,10,9],
[48,37,13,0],
[48,37,13,1],
[48,37,13,2],
[48,37,13,3],
[48,37,13,5],
[48,37,13,6],
[48,37,13,7],
[48,37,13,8],
[48,37,13,9],
[48,37,13,10],
[48,37,14,0],
[48,37,14,1],
[48,37,14,2],
[48,37,14,3],
[48,37,14,5],
[48,37,14,6],
[48,37,14,7],
[48,37,14,8],
[48,37,14,9],
[48,37,14,10],
[48,37,15,0],
[48,37,15,1],
[48,37,15,2],
[48,37,15,3],
[48,37,15,5],
[48,37,15,6],
[48,37,15,7],
[48,37,15,8],
[48,37,15,10],
[48,37,15,13],
[48,37,15,14],
[48,37,16,0],
[48,37,16,1],
[48,37,16,2],
[48,37,16,3],
[48,37,16,5],
[48,37,16,6],
[48,37,16,7],
[48,37,16,8],
[48,37,16,9],
[48,37,16,10],
[48,37,16,13],
[48,37,16,14],
[48,37,16,15],
[48,37,17,0],
[48,37,17,1],
[48,37,17,2],
[48,37,17,3],
[48,37,17,5],
[48,37,17,6],
[48,37,17,7],
[48,37,17,8],
[48,37,17,9],
[48,37,17,10],
[48,37,17,13],
[48,37,17,14],
[48,37,17,15],
[48,37,17,16],
[48,37,18,0],
[48,37,18,1],
[48,37,18,2],
[48,37,18,3],
[48,37,18,5],
[48,37,18,6],
[48,37,18,7],
[48,37,18,8],
[48,37,18,10],
[48,37,18,13],
[48,37,18,14],
[48,37,18,16],
[48,37,18,17],
[48,37,19,0],
[48,37,19,1],
[48,37,19,2],
[48,37,19,3],
[48,37,19,5],
[48,37,19,6],
[48,37,19,7],
[48,37,19,8],
[48,37,19,9],
[48,37,19,10],
[48,37,19,15],
[48,37,19,16],
[48,37,19,17],
[48,37,19,18],
[48,37,20,0],
[48,37,20,1],
[48,37,20,2],
[48,37,20,3],
[48,37,20,5],
[48,37,20,6],
[48,37,20,7],
[48,37,20,8],
[48,37,20,9],
[48,37,20,13],
[48,37,20,14],
[48,37,20,15],
[48,37,20,16],
[48,37,20,17],
[48,37,20,18],
[48,37,20,19],
[48,37,21,0],
[48,37,21,1],
[48,37,21,2],
[48,37,21,7],
[48,37,21,9],
[48,37,21,10],
[48,37,21,13],
[48,37,21,14],
[48,37,21,15],
[48,37,21,16],
[48,37,21,17],
[48,37,21,18],
[48,37,21,19],
[48,37,21,20],
[48,37,22,0],
[48,37,22,1],
[48,37,22,2],
[48,37,22,3],
[48,37,22,5],
[48,37,22,6],
[48,37,22,7],
[48,37,22,8],
[48,37,22,9],
[48,37,22,13],
[48,37,22,14],
[48,37,22,15],
[48,37,22,16],
[48,37,22,17],
[48,37,22,18],
[48,37,22,19],
[48,37,23,0],
[48,37,23,1],
[48,37,23,2],
[48,37,23,3],
[48,37,23,5],
[48,37,23,6],
[48,37,23,7],
[48,37,23,8],
[48,37,23,9],
[48,37,23,10],
[48,37,23,13],
[48,37,23,14],
[48,37,23,15],
[48,37,23,16],
[48,37,23,17],
[48,37,23,18],
[48,37,23,19],
[48,37,23,21],
[48,37,23,22],
[48,37,24,0],
[48,37,24,1],
[48,37,24,2],
[48,37,24,3],
[48,37,24,5],
[48,37,24,6],
[48,37,24,7],
[48,37,24,8],
[48,37,24,9],
[48,37,24,10],
[48,37,24,13],
[48,37,24,14],
[48,37,24,15],
[48,37,24,17],
[48,37,24,18],
[48,37,24,19],
[48,37,24,21],
[48,37,24,22],
[48,37,24,23],
[48,37,25,0],
[48,37,25,1],
[48,37,25,2],
[48,37,25,3],
[48,37,25,5],
[48,37,25,6],
[48,37,25,8],
[48,37,25,9],
[48,37,25,10],
[48,37,25,13],
[48,37,25,14],
[48,37,25,15],
[48,37,25,16],
[48,37,25,17],
[48,37,25,19],
[48,37,25,20],
[48,37,25,21],
[48,37,25,22],
[48,37,25,23],
[48,37,25,24],
[48,37,26,0],
[48,37,26,1],
[48,37,26,2],
[48,37,26,3],
[48,37,26,5],
[48,37,26,6],
[48,37,26,7],
[48,37,26,8],
[48,37,26,10],
[48,37,26,13],
[48,37,26,14],
[48,37,26,19],
[48,37,26,20],
[48,37,26,21],
[48,37,26,22],
[48,37,26,23],
[48,37,26,24],
[48,37,26,25],
[48,37,27,0],
[48,37,27,1],
[48,37,27,2],
[48,37,27,3],
[48,37,27,5],
[48,37,27,6],
[48,37,27,7],
[48,37,27,8],
[48,37,27,9],
[48,37,27,13],
[48,37,27,14],
[48,37,27,16],
[48,37,27,17],
[48,37,27,18],
[48,37,27,19],
[48,37,27,21],
[48,37,27,23],
[48,37,27,24],
[48,37,27,25],
[48,37,27,26],
[48,37,28,0],
[48,37,28,1],
[48,37,28,2],
[48,37,28,3],
[48,37,28,5],
[48,37,28,6],
[48,37,28,8],
[48,37,28,9],
[48,37,28,10],
[48,37,28,13],
[48,37,28,15],
[48,37,28,16],
[48,37,28,17],
[48,37,28,18],
[48,37,28,19],
[48,37,28,20],
[48,37,28,21],
[48,37,28,22],
[48,37,28,23],
[48,37,28,24],
[48,37,28,26],
[48,37,28,27],
[48,37,30,0],
[48,37,30,1],
[48,37,30,2],
[48,37,30,3],
[48,37,30,5],
[48,37,30,6],
[48,37,30,7],
[48,37,30,8],
[48,37,30,9],
[48,37,30,13],
[48,37,30,14],
[48,37,30,15],
[48,37,30,16],
[48,37,30,17],
[48,37,30,18],
[48,37,30,19],
[48,37,30,20],
[48,37,30,21],
[48,37,30,22],
[48,37,30,24],
[48,37,30,25],
[48,37,31,0],
[48,37,31,1],
[48,37,31,2],
[48,37,31,3],
[48,37,31,5],
[48,37,31,6],
[48,37,31,7],
[48,37,31,10],
[48,37,31,13],
[48,37,31,14],
[48,37,31,15],
[48,37,31,16],
[48,37,31,17],
[48,37,31,18],
[48,37,31,19],
[48,37,31,20],
[48,37,31,21],
[48,37,31,22],
[48,37,31,24],
[48,37,34,0],
[48,37,34,1],
[48,37,34,2],
[48,37,34,5],
[48,37,34,6],
[48,37,34,8],
[48,37,34,9],
[48,37,34,10],
[48,37,34,13],
[48,37,34,14],
[48,37,34,15],
[48,37,34,16],
[48,37,34,17],
[48,37,34,18],
[48,37,34,19],
[48,37,34,20],
[48,37,34,21],
[48,37,35,3],
[48,37,35,5],
[48,37,35,6],
[48,37,35,7],
[48,37,35,8],
[48,37,35,10],
[48,37,35,13],
[48,37,35,14],
[48,37,35,16],
[48,37,35,17],
[48,37,35,19],
[48,37,35,20],
[48,37,36,0],
[48,37,36,1],
[48,37,36,2],
[48,37,36,3],
[48,37,36,5],
[48,37,36,6],
[48,37,36,7],
[48,37,36,8],
[48,37,36,9],
[48,37,36,10],
[48,37,36,15],
[48,37,36,16],
[48,37,36,17],
[48,37,36,18],
[48,38,3,0],
[48,38,3,1],
[48,38,3,2],
[48,38,4,0],
[48,38,4,1],
[48,38,4,2],
[48,38,4,3],
[48,38,5,0],
[48,38,5,1],
[48,38,5,2],
[48,38,5,4],
[48,38,6,0],
[48,38,6,1],
[48,38,6,2],
[48,38,6,4],
[48,38,8,0],
[48,38,8,1],
[48,38,8,2],
[48,38,8,4],
[48,38,9,0],
[48,38,9,1],
[48,38,9,2],
[48,38,9,3],
[48,38,9,4],
[48,38,9,5],
[48,38,9,6],
[48,38,9,8],
[48,38,10,0],
[48,38,10,1],
[48,38,10,2],
[48,38,10,3],
[48,38,10,4],
[48,38,10,5],
[48,38,10,6],
[48,38,10,8],
[48,38,10,9],
[48,38,13,0],
[48,38,13,1],
[48,38,13,2],
[48,38,13,3],
[48,38,13,4],
[48,38,13,5],
[48,38,13,6],
[48,38,13,8],
[48,38,13,9],
[48,38,13,10],
[48,38,14,0],
[48,38,14,1],
[48,38,14,2],
[48,38,14,3],
[48,38,14,4],
[48,38,14,5],
[48,38,14,6],
[48,38,14,8],
[48,38,14,9],
[48,38,14,10],
[48,38,15,0],
[48,38,15,1],
[48,38,15,2],
[48,38,15,3],
[48,38,15,4],
[48,38,15,5],
[48,38,15,6],
[48,38,15,8],
[48,38,15,10],
[48,38,15,13],
[48,38,15,14],
[48,38,16,0],
[48,38,16,1],
[48,38,16,2],
[48,38,16,3],
[48,38,16,4],
[48,38,16,5],
[48,38,16,6],
[48,38,16,8],
[48,38,16,9],
[48,38,16,10],
[48,38,16,13],
[48,38,16,14],
[48,38,16,15],
[48,38,17,0],
[48,38,17,1],
[48,38,17,2],
[48,38,17,3],
[48,38,17,4],
[48,38,17,5],
[48,38,17,6],
[48,38,17,8],
[48,38,17,9],
[48,38,17,10],
[48,38,17,13],
[48,38,17,14],
[48,38,17,15],
[48,38,17,16],
[48,38,18,0],
[48,38,18,1],
[48,38,18,2],
[48,38,18,3],
[48,38,18,4],
[48,38,18,5],
[48,38,18,6],
[48,38,18,8],
[48,38,18,10],
[48,38,18,13],
[48,38,18,14],
[48,38,18,16],
[48,38,18,17],
[48,38,19,0],
[48,38,19,1],
[48,38,19,2],
[48,38,19,3],
[48,38,19,4],
[48,38,19,5],
[48,38,19,6],
[48,38,19,8],
[48,38,19,9],
[48,38,19,10],
[48,38,19,15],
[48,38,19,16],
[48,38,19,17],
[48,38,19,18],
[48,38,20,0],
[48,38,20,1],
[48,38,20,2],
[48,38,20,3],
[48,38,20,4],
[48,38,20,5],
[48,38,20,6],
[48,38,20,8],
[48,38,20,9],
[48,38,20,13],
[48,38,20,14],
[48,38,20,15],
[48,38,20,16],
[48,38,20,17],
[48,38,20,18],
[48,38,20,19],
[48,38,21,0],
[48,38,21,1],
[48,38,21,2],
[48,38,21,4],
[48,38,21,9],
[48,38,21,10],
[48,38,21,13],
[48,38,21,14],
[48,38,21,15],
[48,38,21,16],
[48,38,21,17],
[48,38,21,18],
[48,38,21,19],
[48,38,21,20],
[48,38,22,0],
[48,38,22,1],
[48,38,22,2],
[48,38,22,3],
[48,38,22,4],
[48,38,22,5],
[48,38,22,6],
[48,38,22,8],
[48,38,22,9],
[48,38,22,13],
[48,38,22,14],
[48,38,22,15],
[48,38,22,16],
[48,38,22,17],
[48,38,22,18],
[48,38,22,19],
[48,38,22,21],
[48,38,23,0],
[48,38,23,1],
[48,38,23,2],
[48,38,23,3],
[48,38,23,4],
[48,38,23,5],
[48,38,23,6],
[48,38,23,8],
[48,38,23,9],
[48,38,23,10],
[48,38,23,13],
[48,38,23,14],
[48,38,23,15],
[48,38,23,16],
[48,38,23,17],
[48,38,23,18],
[48,38,23,20],
[48,38,23,21],
[48,38,23,22],
[48,38,24,0],
[48,38,24,1],
[48,38,24,2],
[48,38,24,3],
[48,38,24,4],
[48,38,24,5],
[48,38,24,6],
[48,38,24,8],
[48,38,24,9],
[48,38,24,10],
[48,38,24,13],
[48,38,24,14],
[48,38,24,15],
[48,38,24,17],
[48,38,24,18],
[48,38,24,20],
[48,38,24,21],
[48,38,24,22],
[48,38,24,23],
[48,38,26,0],
[48,38,26,1],
[48,38,26,2],
[48,38,26,3],
[48,38,26,4],
[48,38,26,5],
[48,38,26,6],
[48,38,26,8],
[48,38,26,10],
[48,38,26,13],
[48,38,26,14],
[48,38,26,16],
[48,38,26,17],
[48,38,26,19],
[48,38,26,20],
[48,38,26,21],
[48,38,26,22],
[48,38,26,23],
[48,38,26,24],
[48,38,27,0],
[48,38,27,1],
[48,38,27,2],
[48,38,27,3],
[48,38,27,4],
[48,38,27,5],
[48,38,27,6],
[48,38,27,8],
[48,38,27,9],
[48,38,27,13],
[48,38,27,15],
[48,38,27,16],
[48,38,27,17],
[48,38,27,18],
[48,38,27,19],
[48,38,27,21],
[48,38,27,23],
[48,38,27,24],
[48,38,27,26],
[48,38,30,0],
[48,38,30,1],
[48,38,30,2],
[48,38,30,3],
[48,38,30,4],
[48,38,30,5],
[48,38,30,6],
[48,38,30,10],
[48,38,30,13],
[48,38,30,14],
[48,38,30,15],
[48,38,30,16],
[48,38,30,17],
[48,38,30,18],
[48,38,30,19],
[48,38,30,20],
[48,38,30,21],
[48,38,30,22],
[48,38,30,24],
[48,38,31,0],
[48,38,31,1],
[48,38,31,2],
[48,38,31,3],
[48,38,31,4],
[48,38,31,5],
[48,38,31,8],
[48,38,31,9],
[48,38,31,10],
[48,38,31,13],
[48,38,31,14],
[48,38,31,15],
[48,38,31,16],
[48,38,31,17],
[48,38,31,18],
[48,38,31,19],
[48,38,31,20],
[48,38,31,21],
[48,38,31,22],
[48,38,31,24],
[48,38,35,0],
[48,38,35,1],
[48,38,35,2],
[48,38,35,3],
[48,38,35,4],
[48,38,35,5],
[48,38,35,6],
[48,38,35,8],
[48,38,35,10],
[48,38,35,13],
[48,38,35,14],
[48,38,35,16],
[48,38,35,17],
[48,38,35,19],
[48,38,36,0],
[48,38,36,1],
[48,38,36,2],
[48,38,36,3],
[48,38,36,4],
[48,38,36,5],
[48,38,36,6],
[48,38,36,8],
[48,38,36,9],
[48,38,36,10],
[48,38,36,15],
[48,38,36,16],
[48,38,36,17],
[48,38,36,18],
[48,38,37,0],
[48,38,37,1],
[48,38,37,2],
[48,38,37,3],
[48,38,37,5],
[48,38,37,6],
[48,38,37,8],
[48,38,37,9],
[48,38,37,10],
[48,38,37,13],
[48,38,37,14],
[48,38,37,15],
[48,38,37,16],
[48,38,37,17],
[48,39,4,0],
[48,39,4,1],
[48,39,4,2],
[48,39,7,0],
[48,39,7,1],
[48,39,7,2],
[48,39,7,4],
[48,39,9,0],
[48,39,9,1],
[48,39,9,2],
[48,39,9,4],
[48,39,9,7],
[48,39,10,0],
[48,39,10,1],
[48,39,10,2],
[48,39,10,4],
[48,39,10,7],
[48,39,10,9],
[48,39,13,0],
[48,39,13,1],
[48,39,13,2],
[48,39,13,4],
[48,39,13,7],
[48,39,13,9],
[48,39,13,10],
[48,39,14,0],
[48,39,14,1],
[48,39,14,2],
[48,39,14,4],
[48,39,14,7],
[48,39,14,9],
[48,39,14,10],
[48,39,15,0],
[48,39,15,1],
[48,39,15,2],
[48,39,15,4],
[48,39,15,7],
[48,39,15,10],
[48,39,15,13],
[48,39,15,14],
[48,39,16,0],
[48,39,16,1],
[48,39,16,2],
[48,39,16,4],
[48,39,16,7],
[48,39,16,9],
[48,39,16,10],
[48,39,16,13],
[48,39,16,14],
[48,39,16,15],
[48,39,17,0],
[48,39,17,1],
[48,39,17,2],
[48,39,17,4],
[48,39,17,7],
[48,39,17,9],
[48,39,17,10],
[48,39,17,13],
[48,39,17,14],
[48,39,17,15],
[48,39,17,16],
[48,39,18,0],
[48,39,18,1],
[48,39,18,2],
[48,39,18,4],
[48,39,18,7],
[48,39,18,10],
[48,39,18,13],
[48,39,18,14],
[48,39,18,16],
[48,39,18,17],
[48,39,19,0],
[48,39,19,1],
[48,39,19,2],
[48,39,19,4],
[48,39,19,7],
[48,39,19,9],
[48,39,19,10],
[48,39,19,15],
[48,39,19,16],
[48,39,19,17],
[48,39,19,18],
[48,39,20,0],
[48,39,20,1],
[48,39,20,2],
[48,39,20,4],
[48,39,20,7],
[48,39,20,9],
[48,39,20,13],
[48,39,20,14],
[48,39,20,15],
[48,39,20,16],
[48,39,20,17],
[48,39,20,18],
[48,39,20,19],
[48,39,22,0],
[48,39,22,1],
[48,39,22,2],
[48,39,22,4],
[48,39,22,7],
[48,39,22,9],
[48,39,22,13],
[48,39,22,14],
[48,39,22,15],
[48,39,22,16],
[48,39,22,17],
[48,39,22,18],
[48,39,23,0],
[48,39,23,1],
[48,39,23,2],
[48,39,23,4],
[48,39,23,7],
[48,39,23,9],
[48,39,23,10],
[48,39,23,13],
[48,39,23,14],
[48,39,23,15],
[48,39,23,16],
[48,39,23,17],
[48,39,23,19],
[48,39,23,20],
[48,39,23,22],
[48,39,24,0],
[48,39,24,1],
[48,39,24,2],
[48,39,24,4],
[48,39,24,7],
[48,39,24,9],
[48,39,24,10],
[48,39,24,13],
[48,39,24,14],
[48,39,24,15],
[48,39,24,17],
[48,39,24,19],
[48,39,24,20],
[48,39,24,22],
[48,39,24,23],
[48,39,25,0],
[48,39,25,1],
[48,39,25,2],
[48,39,25,4],
[48,39,25,9],
[48,39,25,10],
[48,39,25,13],
[48,39,25,14],
[48,39,25,16],
[48,39,25,17],
[48,39,25,18],
[48,39,25,19],
[48,39,25,20],
[48,39,25,22],
[48,39,25,23],
[48,39,25,24],
[48,39,26,0],
[48,39,26,1],
[48,39,26,2],
[48,39,26,4],
[48,39,26,7],
[48,39,26,10],
[48,39,26,13],
[48,39,26,16],
[48,39,26,17],
[48,39,26,19],
[48,39,26,20],
[48,39,26,22],
[48,39,26,23],
[48,39,26,24],
[48,39,26,25],
[48,39,27,0],
[48,39,27,1],
[48,39,27,2],
[48,39,27,4],
[48,39,27,7],
[48,39,27,9],
[48,39,27,14],
[48,39,27,15],
[48,39,27,16],
[48,39,27,17],
[48,39,27,18],
[48,39,27,19],
[48,39,27,23],
[48,39,27,24],
[48,39,27,25],
[48,39,27,26],
[48,39,28,0],
[48,39,28,1],
[48,39,28,2],
[48,39,28,4],
[48,39,28,9],
[48,39,28,13],
[48,39,28,14],
[48,39,28,15],
[48,39,28,16],
[48,39,28,17],
[48,39,28,18],
[48,39,28,19],
[48,39,28,20],
[48,39,28,22],
[48,39,28,23],
[48,39,28,24],
[48,39,30,0],
[48,39,30,1],
[48,39,30,2],
[48,39,30,4],
[48,39,30,9],
[48,39,30,10],
[48,39,30,13],
[48,39,30,14],
[48,39,30,15],
[48,39,30,16],
[48,39,30,17],
[48,39,30,18],
[48,39,30,19],
[48,39,30,20],
[48,39,30,22],
[48,39,30,24],
[48,39,31,0],
[48,39,31,1],
[48,39,31,2],
[48,39,31,4],
[48,39,31,7],
[48,39,31,9],
[48,39,31,10],
[48,39,31,13],
[48,39,31,14],
[48,39,31,15],
[48,39,31,16],
[48,39,31,17],
[48,39,31,18],
[48,39,31,19],
[48,39,31,20],
[48,39,31,22],
[48,39,34,0],
[48,39,34,1],
[48,39,34,2],
[48,39,34,4],
[48,39,34,9],
[48,39,34,10],
[48,39,34,13],
[48,39,34,14],
[48,39,34,15],
[48,39,34,16],
[48,39,34,17],
[48,39,34,18],
[48,39,34,19],
[48,39,35,0],
[48,39,35,1],
[48,39,35,2],
[48,39,35,4],
[48,39,35,7],
[48,39,35,10],
[48,39,35,13],
[48,39,35,14],
[48,39,35,16],
[48,39,35,17],
[48,39,36,0],
[48,39,36,1],
[48,39,36,2],
[48,39,36,4],
[48,39,36,7],
[48,39,36,9],
[48,39,36,10],
[48,39,36,15],
[48,39,36,16],
[48,39,36,17],
[48,39,37,0],
[48,39,37,1],
[48,39,37,2],
[48,39,37,7],
[48,39,37,9],
[48,39,37,10],
[48,39,37,13],
[48,39,37,14],
[48,39,37,15],
[48,39,38,0],
[48,39,38,1],
[48,39,38,2],
[48,39,38,4],
[48,39,38,9],
[48,39,38,10],
[48,39,38,13],
[48,39,38,14],
[48,40,3,0],
[48,40,3,1],
[48,40,3,2],
[48,40,4,0],
[48,40,4,1],
[48,40,4,2],
[48,40,4,3],
[48,40,5,0],
[48,40,5,1],
[48,40,5,2],
[48,40,5,4],
[48,40,6,0],
[48,40,6,1],
[48,40,6,2],
[48,40,6,4],
[48,40,7,0],
[48,40,7,1],
[48,40,7,2],
[48,40,7,3],
[48,40,7,4],
[48,40,7,5],
[48,40,7,6],
[48,40,8,0],
[48,40,8,1],
[48,40,8,2],
[48,40,8,4],
[48,40,8,7],
[48,40,10,0],
[48,40,10,1],
[48,40,10,2],
[48,40,10,3],
[48,40,10,4],
[48,40,10,5],
[48,40,10,6],
[48,40,10,7],
[48,40,10,8],
[48,40,13,0],
[48,40,13,1],
[48,40,13,2],
[48,40,13,3],
[48,40,13,4],
[48,40,13,5],
[48,40,13,6],
[48,40,13,7],
[48,40,13,8],
[48,40,13,10],
[48,40,14,0],
[48,40,14,1],
[48,40,14,2],
[48,40,14,3],
[48,40,14,4],
[48,40,14,5],
[48,40,14,6],
[48,40,14,7],
[48,40,14,8],
[48,40,14,10],
[48,40,16,0],
[48,40,16,1],
[48,40,16,2],
[48,40,16,3],
[48,40,16,4],
[48,40,16,5],
[48,40,16,6],
[48,40,16,7],
[48,40,16,8],
[48,40,16,10],
[48,40,16,13],
[48,40,16,14],
[48,40,17,0],
[48,40,17,1],
[48,40,17,2],
[48,40,17,3],
[48,40,17,4],
[48,40,17,5],
[48,40,17,6],
[48,40,17,7],
[48,40,17,8],
[48,40,17,10],
[48,40,17,13],
[48,40,17,14],
[48,40,17,16],
[48,40,19,0],
[48,40,19,1],
[48,40,19,2],
[48,40,19,3],
[48,40,19,4],
[48,40,19,5],
[48,40,19,6],
[48,40,19,7],
[48,40,19,8],
[48,40,19,10],
[48,40,19,16],
[48,40,19,17],
[48,40,20,0],
[48,40,20,1],
[48,40,20,2],
[48,40,20,3],
[48,40,20,4],
[48,40,20,5],
[48,40,20,6],
[48,40,20,7],
[48,40,20,8],
[48,40,20,13],
[48,40,20,14],
[48,40,20,16],
[48,40,20,17],
[48,40,20,19],
[48,40,21,0],
[48,40,21,1],
[48,40,21,2],
[48,40,21,4],
[48,40,21,7],
[48,40,21,10],
[48,40,21,13],
[48,40,21,14],
[48,40,21,16],
[48,40,21,17],
[48,40,21,20],
[48,40,22,0],
[48,40,22,1],
[48,40,22,2],
[48,40,22,3],
[48,40,22,4],
[48,40,22,5],
[48,40,22,6],
[48,40,22,7],
[48,40,22,8],
[48,40,22,13],
[48,40,22,14],
[48,40,22,16],
[48,40,22,17],
[48,40,22,19],
[48,40,22,21],
[48,40,23,0],
[48,40,23,1],
[48,40,23,2],
[48,40,23,3],
[48,40,23,4],
[48,40,23,5],
[48,40,23,6],
[48,40,23,7],
[48,40,23,8],
[48,40,23,10],
[48,40,23,13],
[48,40,23,14],
[48,40,23,19],
[48,40,23,20],
[48,40,23,21],
[48,40,23,22],
[48,40,24,0],
[48,40,24,1],
[48,40,24,2],
[48,40,24,3],
[48,40,24,4],
[48,40,24,5],
[48,40,24,6],
[48,40,24,7],
[48,40,24,8],
[48,40,24,10],
[48,40,24,13],
[48,40,24,14],
[48,40,24,19],
[48,40,24,20],
[48,40,24,21],
[48,40,24,22],
[48,40,24,23],
[48,40,25,0],
[48,40,25,1],
[48,40,25,2],
[48,40,25,3],
[48,40,25,4],
[48,40,25,5],
[48,40,25,6],
[48,40,25,8],
[48,40,25,10],
[48,40,25,13],
[48,40,25,16],
[48,40,25,17],
[48,40,25,19],
[48,40,25,20],
[48,40,25,21],
[48,40,25,22],
[48,40,25,23],
[48,40,25,24],
[48,40,27,0],
[48,40,27,1],
[48,40,27,2],
[48,40,27,3],
[48,40,27,4],
[48,40,27,5],
[48,40,27,6],
[48,40,27,7],
[48,40,27,8],
[48,40,27,13],
[48,40,27,14],
[48,40,27,16],
[48,40,27,17],
[48,40,27,19],
[48,40,27,21],
[48,40,27,23],
[48,40,27,24],
[48,40,27,25],
[48,40,28,0],
[48,40,28,1],
[48,40,28,2],
[48,40,28,3],
[48,40,28,4],
[48,40,28,5],
[48,40,28,6],
[48,40,28,10],
[48,40,28,13],
[48,40,28,14],
[48,40,28,16],
[48,40,28,17],
[48,40,28,19],
[48,40,28,20],
[48,40,28,21],
[48,40,28,22],
[48,40,28,23],
[48,40,28,24],
[48,40,30,0],
[48,40,30,1],
[48,40,30,2],
[48,40,30,3],
[48,40,30,4],
[48,40,30,6],
[48,40,30,7],
[48,40,30,8],
[48,40,30,10],
[48,40,30,13],
[48,40,30,14],
[48,40,30,16],
[48,40,30,17],
[48,40,30,19],
[48,40,30,20],
[48,40,30,21],
[48,40,30,22],
[48,40,31,0],
[48,40,31,1],
[48,40,31,2],
[48,40,31,5],
[48,40,31,6],
[48,40,31,7],
[48,40,31,8],
[48,40,31,10],
[48,40,31,13],
[48,40,31,14],
[48,40,31,16],
[48,40,31,17],
[48,40,31,19],
[48,40,31,20],
[48,40,31,21],
[48,40,34,0],
[48,40,34,1],
[48,40,34,2],
[48,40,34,3],
[48,40,34,4],
[48,40,34,5],
[48,40,34,6],
[48,40,34,8],
[48,40,34,10],
[48,40,34,13],
[48,40,34,14],
[48,40,34,16],
[48,40,34,17],
[48,40,36,0],
[48,40,36,1],
[48,40,36,2],
[48,40,36,3],
[48,40,36,4],
[48,40,36,5],
[48,40,36,6],
[48,40,36,7],
[48,40,36,8],
[48,40,36,10],
[48,40,37,0],
[48,40,37,1],
[48,40,37,2],
[48,40,37,3],
[48,40,37,5],
[48,40,37,6],
[48,40,37,7],
[48,40,37,8],
[48,40,37,10],
[48,40,37,13],
[48,40,37,14],
[48,40,38,0],
[48,40,38,1],
[48,40,38,2],
[48,40,38,3],
[48,40,38,4],
[48,40,38,5],
[48,40,38,6],
[48,40,38,8],
[48,40,38,10],
[48,40,38,13],
[48,40,39,0],
[48,40,39,1],
[48,40,39,2],
[48,40,39,4],
[48,40,39,7],
[48,40,39,10],
[48,41,3,0],
[48,41,3,1],
[48,41,3,2],
[48,41,4,0],
[48,41,4,1],
[48,41,4,2],
[48,41,4,3],
[48,41,5,0],
[48,41,5,1],
[48,41,5,2],
[48,41,5,4],
[48,41,6,0],
[48,41,6,1],
[48,41,6,2],
[48,41,6,4],
[48,41,7,0],
[48,41,7,1],
[48,41,7,2],
[48,41,7,3],
[48,41,7,4],
[48,41,7,5],
[48,41,7,6],
[48,41,8,0],
[48,41,8,1],
[48,41,8,2],
[48,41,8,4],
[48,41,8,7],
[48,41,10,0],
[48,41,10,1],
[48,41,10,2],
[48,41,10,3],
[48,41,10,4],
[48,41,10,5],
[48,41,10,6],
[48,41,10,7],
[48,41,10,8],
[48,41,13,0],
[48,41,13,1],
[48,41,13,2],
[48,41,13,3],
[48,41,13,4],
[48,41,13,5],
[48,41,13,6],
[48,41,13,7],
[48,41,13,8],
[48,41,13,10],
[48,41,14,0],
[48,41,14,1],
[48,41,14,2],
[48,41,14,3],
[48,41,14,4],
[48,41,14,5],
[48,41,14,6],
[48,41,14,7],
[48,41,14,8],
[48,41,14,10],
[48,41,16,0],
[48,41,16,1],
[48,41,16,2],
[48,41,16,3],
[48,41,16,4],
[48,41,16,5],
[48,41,16,6],
[48,41,16,7],
[48,41,16,8],
[48,41,16,10],
[48,41,16,13],
[48,41,16,14],
[48,41,17,0],
[48,41,17,1],
[48,41,17,2],
[48,41,17,3],
[48,41,17,4],
[48,41,17,5],
[48,41,17,6],
[48,41,17,7],
[48,41,17,8],
[48,41,17,10],
[48,41,17,13],
[48,41,17,14],
[48,41,17,16],
[48,41,19,0],
[48,41,19,1],
[48,41,19,2],
[48,41,19,3],
[48,41,19,4],
[48,41,19,5],
[48,41,19,6],
[48,41,19,7],
[48,41,19,8],
[48,41,19,10],
[48,41,19,16],
[48,41,19,17],
[48,41,20,0],
[48,41,20,1],
[48,41,20,2],
[48,41,20,3],
[48,41,20,4],
[48,41,20,5],
[48,41,20,6],
[48,41,20,7],
[48,41,20,8],
[48,41,20,13],
[48,41,20,14],
[48,41,20,16],
[48,41,20,17],
[48,41,20,19],
[48,41,21,0],
[48,41,21,1],
[48,41,21,2],
[48,41,21,4],
[48,41,21,7],
[48,41,21,10],
[48,41,21,13],
[48,41,21,14],
[48,41,21,16],
[48,41,21,17],
[48,41,21,20],
[48,41,22,0],
[48,41,22,1],
[48,41,22,2],
[48,41,22,3],
[48,41,22,4],
[48,41,22,5],
[48,41,22,6],
[48,41,22,7],
[48,41,22,8],
[48,41,22,13],
[48,41,22,14],
[48,41,22,16],
[48,41,22,17],
[48,41,22,19],
[48,41,22,21],
[48,41,23,0],
[48,41,23,1],
[48,41,23,2],
[48,41,23,3],
[48,41,23,4],
[48,41,23,5],
[48,41,23,6],
[48,41,23,7],
[48,41,23,8],
[48,41,23,10],
[48,41,23,13],
[48,41,23,14],
[48,41,23,19],
[48,41,23,20],
[48,41,23,21],
[48,41,23,22],
[48,41,24,0],
[48,41,24,1],
[48,41,24,2],
[48,41,24,3],
[48,41,24,4],
[48,41,24,5],
[48,41,24,6],
[48,41,24,7],
[48,41,24,8],
[48,41,24,10],
[48,41,24,13],
[48,41,24,14],
[48,41,24,19],
[48,41,24,20],
[48,41,24,21],
[48,41,24,22],
[48,41,24,23],
[48,41,25,0],
[48,41,25,1],
[48,41,25,2],
[48,41,25,3],
[48,41,25,4],
[48,41,25,5],
[48,41,25,6],
[48,41,25,8],
[48,41,25,10],
[48,41,25,13],
[48,41,25,16],
[48,41,25,17],
[48,41,25,19],
[48,41,25,20],
[48,41,25,21],
[48,41,25,22],
[48,41,25,23],
[48,41,25,24],
[48,41,27,0],
[48,41,27,1],
[48,41,27,2],
[48,41,27,3],
[48,41,27,4],
[48,41,27,5],
[48,41,27,6],
[48,41,27,7],
[48,41,27,8],
[48,41,27,13],
[48,41,27,14],
[48,41,27,16],
[48,41,27,17],
[48,41,27,19],
[48,41,27,21],
[48,41,27,23],
[48,41,27,24],
[48,41,27,25],
[48,41,28,0],
[48,41,28,1],
[48,41,28,2],
[48,41,28,3],
[48,41,28,4],
[48,41,28,5],
[48,41,28,6],
[48,41,28,10],
[48,41,28,13],
[48,41,28,14],
[48,41,28,16],
[48,41,28,17],
[48,41,28,19],
[48,41,28,20],
[48,41,28,21],
[48,41,28,22],
[48,41,28,23],
[48,41,28,24],
[48,41,30,0],
[48,41,30,1],
[48,41,30,2],
[48,41,30,3],
[48,41,30,4],
[48,41,30,6],
[48,41,30,7],
[48,41,30,8],
[48,41,30,10],
[48,41,30,13],
[48,41,30,14],
[48,41,30,16],
[48,41,30,17],
[48,41,30,19],
[48,41,30,20],
[48,41,30,21],
[48,41,30,22],
[48,41,31,0],
[48,41,31,1],
[48,41,31,2],
[48,41,31,5],
[48,41,31,6],
[48,41,31,7],
[48,41,31,8],
[48,41,31,10],
[48,41,31,13],
[48,41,31,14],
[48,41,31,16],
[48,41,31,17],
[48,41,31,19],
[48,41,31,20],
[48,41,31,21],
[48,41,34,0],
[48,41,34,1],
[48,41,34,2],
[48,41,34,3],
[48,41,34,4],
[48,41,34,5],
[48,41,34,6],
[48,41,34,8],
[48,41,34,10],
[48,41,34,13],
[48,41,34,14],
[48,41,34,16],
[48,41,34,17],
[48,41,36,0],
[48,41,36,1],
[48,41,36,2],
[48,41,36,3],
[48,41,36,4],
[48,41,36,5],
[48,41,36,6],
[48,41,36,7],
[48,41,36,8],
[48,41,36,10],
[48,41,37,0],
[48,41,37,1],
[48,41,37,2],
[48,41,37,3],
[48,41,37,5],
[48,41,37,6],
[48,41,37,7],
[48,41,37,8],
[48,41,37,10],
[48,41,37,13],
[48,41,37,14],
[48,41,38,0],
[48,41,38,1],
[48,41,38,2],
[48,41,38,3],
[48,41,38,4],
[48,41,38,5],
[48,41,38,6],
[48,41,38,8],
[48,41,38,10],
[48,41,38,13],
[48,41,39,0],
[48,41,39,1],
[48,41,39,2],
[48,41,39,4],
[48,41,39,7],
[48,41,39,10],
[48,42,3,0],
[48,42,3,1],
[48,42,3,2],
[48,42,4,0],
[48,42,4,1],
[48,42,4,2],
[48,42,4,3],
[48,42,5,0],
[48,42,5,1],
[48,42,5,2],
[48,42,5,4],
[48,42,6,0],
[48,42,6,1],
[48,42,6,2],
[48,42,6,4],
[48,42,7,0],
[48,42,7,1],
[48,42,7,2],
[48,42,7,3],
[48,42,7,4],
[48,42,7,5],
[48,42,7,6],
[48,42,8,0],
[48,42,8,1],
[48,42,8,2],
[48,42,8,4],
[48,42,8,7],
[48,42,9,0],
[48,42,9,1],
[48,42,9,2],
[48,42,9,3],
[48,42,9,4],
[48,42,9,5],
[48,42,9,6],
[48,42,9,7],
[48,42,9,8],
[48,42,13,0],
[48,42,13,1],
[48,42,13,2],
[48,42,13,3],
[48,42,13,4],
[48,42,13,5],
[48,42,13,6],
[48,42,13,7],
[48,42,13,8],
[48,42,13,9],
[48,42,14,0],
[48,42,14,1],
[48,42,14,2],
[48,42,14,3],
[48,42,14,4],
[48,42,14,5],
[48,42,14,6],
[48,42,14,7],
[48,42,14,8],
[48,42,14,9],
[48,42,15,0],
[48,42,15,1],
[48,42,15,2],
[48,42,15,3],
[48,42,15,4],
[48,42,15,5],
[48,42,15,6],
[48,42,15,7],
[48,42,15,8],
[48,42,15,13],
[48,42,15,14],
[48,42,16,0],
[48,42,16,1],
[48,42,16,2],
[48,42,16,3],
[48,42,16,4],
[48,42,16,5],
[48,42,16,6],
[48,42,16,7],
[48,42,16,8],
[48,42,16,9],
[48,42,16,13],
[48,42,16,14],
[48,42,16,15],
[48,42,17,0],
[48,42,17,1],
[48,42,17,2],
[48,42,17,3],
[48,42,17,4],
[48,42,17,5],
[48,42,17,6],
[48,42,17,7],
[48,42,17,8],
[48,42,17,9],
[48,42,17,13],
[48,42,17,14],
[48,42,17,15],
[48,42,17,16],
[48,42,18,0],
[48,42,18,1],
[48,42,18,2],
[48,42,18,3],
[48,42,18,4],
[48,42,18,5],
[48,42,18,6],
[48,42,18,7],
[48,42,18,8],
[48,42,18,13],
[48,42,18,14],
[48,42,18,16],
[48,42,18,17],
[48,42,19,0],
[48,42,19,1],
[48,42,19,2],
[48,42,19,3],
[48,42,19,4],
[48,42,19,5],
[48,42,19,6],
[48,42,19,7],
[48,42,19,8],
[48,42,19,9],
[48,42,19,15],
[48,42,19,16],
[48,42,19,17],
[48,42,19,18],
[48,42,21,0],
[48,42,21,1],
[48,42,21,2],
[48,42,21,4],
[48,42,21,7],
[48,42,21,9],
[48,42,21,13],
[48,42,21,14],
[48,42,21,15],
[48,42,21,16],
[48,42,21,17],
[48,42,21,19],
[48,42,23,0],
[48,42,23,1],
[48,42,23,2],
[48,42,23,3],
[48,42,23,4],
[48,42,23,5],
[48,42,23,6],
[48,42,23,7],
[48,42,23,8],
[48,42,23,9],
[48,42,23,13],
[48,42,23,14],
[48,42,23,16],
[48,42,23,17],
[48,42,23,18],
[48,42,23,19],
[48,42,23,21],
[48,42,24,0],
[48,42,24,1],
[48,42,24,2],
[48,42,24,3],
[48,42,24,4],
[48,42,24,5],
[48,42,24,6],
[48,42,24,7],
[48,42,24,8],
[48,42,24,9],
[48,42,24,13],
[48,42,24,14],
[48,42,24,17],
[48,42,24,18],
[48,42,24,19],
[48,42,24,21],
[48,42,24,23],
[48,42,25,0],
[48,42,25,1],
[48,42,25,2],
[48,42,25,3],
[48,42,25,4],
[48,42,25,5],
[48,42,25,6],
[48,42,25,8],
[48,42,25,9],
[48,42,25,14],
[48,42,25,15],
[48,42,25,16],
[48,42,25,17],
[48,42,25,18],
[48,42,25,19],
[48,42,25,21],
[48,42,25,23],
[48,42,25,24],
[48,42,26,0],
[48,42,26,1],
[48,42,26,2],
[48,42,26,3],
[48,42,26,4],
[48,42,26,5],
[48,42,26,6],
[48,42,26,7],
[48,42,26,8],
[48,42,26,13],
[48,42,26,14],
[48,42,26,16],
[48,42,26,17],
[48,42,26,19],
[48,42,26,21],
[48,42,26,23],
[48,42,26,24],
[48,42,26,25],
[48,42,28,0],
[48,42,28,1],
[48,42,28,2],
[48,42,28,3],
[48,42,28,4],
[48,42,28,5],
[48,42,28,8],
[48,42,28,9],
[48,42,28,13],
[48,42,28,14],
[48,42,28,15],
[48,42,28,16],
[48,42,28,17],
[48,42,28,18],
[48,42,28,19],
[48,42,28,21],
[48,42,28,23],
[48,42,28,24],
[48,42,30,0],
[48,42,30,1],
[48,42,30,2],
[48,42,30,5],
[48,42,30,6],
[48,42,30,7],
[48,42,30,8],
[48,42,30,9],
[48,42,30,13],
[48,42,30,14],
[48,42,30,15],
[48,42,30,16],
[48,42,30,17],
[48,42,30,18],
[48,42,30,19],
[48,42,30,21],
[48,42,31,3],
[48,42,31,4],
[48,42,31,5],
[48,42,31,6],
[48,42,31,7],
[48,42,31,8],
[48,42,31,9],
[48,42,31,13],
[48,42,31,14],
[48,42,31,15],
[48,42,31,16],
[48,42,31,17],
[48,42,31,18],
[48,42,31,19],
[48,42,34,0],
[48,42,34,1],
[48,42,34,2],
[48,42,34,3],
[48,42,34,4],
[48,42,34,5],
[48,42,34,6],
[48,42,34,8],
[48,42,34,9],
[48,42,34,13],
[48,42,34,14],
[48,42,34,15],
[48,42,34,16],
[48,42,34,17],
[48,42,35,0],
[48,42,35,1],
[48,42,35,2],
[48,42,35,3],
[48,42,35,4],
[48,42,35,5],
[48,42,35,6],
[48,42,35,7],
[48,42,35,8],
[48,42,35,13],
[48,42,35,14],
[48,42,36,0],
[48,42,36,1],
[48,42,36,2],
[48,42,36,3],
[48,42,36,4],
[48,42,36,5],
[48,42,36,6],
[48,42,36,7],
[48,42,36,8],
[48,42,36,9],
[48,42,37,0],
[48,42,37,1],
[48,42,37,2],
[48,42,37,3],
[48,42,37,5],
[48,42,37,6],
[48,42,37,7],
[48,42,37,8],
[48,42,37,9],
[48,42,37,13],
[48,42,38,0],
[48,42,38,1],
[48,42,38,2],
[48,42,38,3],
[48,42,38,4],
[48,42,38,5],
[48,42,38,6],
[48,42,38,8],
[48,42,38,9],
[48,42,39,0],
[48,42,39,1],
[48,42,39,2],
[48,42,39,4],
[48,42,39,7],
[48,42,39,9],
[48,42,40,0],
[48,42,40,1],
[48,42,40,2],
[48,42,40,3],
[48,42,40,4],
[48,42,40,5],
[48,42,40,6],
[48,42,40,7],
[48,42,41,0],
[48,42,41,1],
[48,42,41,2],
[48,42,41,3],
[48,42,41,4],
[48,42,41,5],
[48,42,41,6],
[48,42,41,7],
[48,43,3,0],
[48,43,3,1],
[48,43,3,2],
[48,43,4,0],
[48,43,4,1],
[48,43,4,2],
[48,43,4,3],
[48,43,5,0],
[48,43,5,1],
[48,43,5,2],
[48,43,5,4],
[48,43,6,0],
[48,43,6,1],
[48,43,6,2],
[48,43,6,4],
[48,43,8,0],
[48,43,8,1],
[48,43,8,2],
[48,43,8,4],
[48,43,9,0],
[48,43,9,1],
[48,43,9,2],
[48,43,9,3],
[48,43,9,4],
[48,43,9,5],
[48,43,9,6],
[48,43,9,8],
[48,43,10,0],
[48,43,10,1],
[48,43,10,2],
[48,43,10,3],
[48,43,10,4],
[48,43,10,5],
[48,43,10,6],
[48,43,10,8],
[48,43,10,9],
[48,43,13,0],
[48,43,13,1],
[48,43,13,2],
[48,43,13,3],
[48,43,13,4],
[48,43,13,5],
[48,43,13,6],
[48,43,13,8],
[48,43,13,9],
[48,43,13,10],
[48,43,14,0],
[48,43,14,1],
[48,43,14,2],
[48,43,14,3],
[48,43,14,4],
[48,43,14,5],
[48,43,14,6],
[48,43,14,8],
[48,43,14,9],
[48,43,14,10],
[48,43,15,0],
[48,43,15,1],
[48,43,15,2],
[48,43,15,3],
[48,43,15,4],
[48,43,15,5],
[48,43,15,6],
[48,43,15,8],
[48,43,15,10],
[48,43,15,13],
[48,43,15,14],
[48,43,16,0],
[48,43,16,1],
[48,43,16,2],
[48,43,16,3],
[48,43,16,4],
[48,43,16,5],
[48,43,16,6],
[48,43,16,8],
[48,43,16,9],
[48,43,16,10],
[48,43,16,13],
[48,43,16,14],
[48,43,16,15],
[48,43,17,0],
[48,43,17,1],
[48,43,17,2],
[48,43,17,3],
[48,43,17,4],
[48,43,17,5],
[48,43,17,6],
[48,43,17,8],
[48,43,17,9],
[48,43,17,10],
[48,43,17,13],
[48,43,17,14],
[48,43,17,15],
[48,43,17,16],
[48,43,18,0],
[48,43,18,1],
[48,43,18,2],
[48,43,18,3],
[48,43,18,4],
[48,43,18,5],
[48,43,18,6],
[48,43,18,8],
[48,43,18,10],
[48,43,18,13],
[48,43,18,14],
[48,43,18,16],
[48,43,18,17],
[48,43,19,0],
[48,43,19,1],
[48,43,19,2],
[48,43,19,3],
[48,43,19,4],
[48,43,19,5],
[48,43,19,6],
[48,43,19,8],
[48,43,19,9],
[48,43,19,10],
[48,43,19,15],
[48,43,19,16],
[48,43,19,17],
[48,43,19,18],
[48,43,20,0],
[48,43,20,1],
[48,43,20,2],
[48,43,20,3],
[48,43,20,4],
[48,43,20,5],
[48,43,20,6],
[48,43,20,8],
[48,43,20,9],
[48,43,20,13],
[48,43,20,14],
[48,43,20,15],
[48,43,20,16],
[48,43,20,17],
[48,43,20,19],
[48,43,21,0],
[48,43,21,1],
[48,43,21,2],
[48,43,21,4],
[48,43,21,9],
[48,43,21,10],
[48,43,21,13],
[48,43,21,14],
[48,43,21,15],
[48,43,21,18],
[48,43,21,19],
[48,43,21,20],
[48,43,22,0],
[48,43,22,1],
[48,43,22,2],
[48,43,22,3],
[48,43,22,4],
[48,43,22,5],
[48,43,22,6],
[48,43,22,8],
[48,43,22,9],
[48,43,22,13],
[48,43,22,14],
[48,43,22,16],
[48,43,22,17],
[48,43,22,18],
[48,43,22,19],
[48,43,22,21],
[48,43,23,0],
[48,43,23,1],
[48,43,23,2],
[48,43,23,3],
[48,43,23,4],
[48,43,23,5],
[48,43,23,6],
[48,43,23,8],
[48,43,23,9],
[48,43,23,10],
[48,43,23,13],
[48,43,23,15],
[48,43,23,16],
[48,43,23,17],
[48,43,23,18],
[48,43,23,19],
[48,43,23,20],
[48,43,23,21],
[48,43,23,22],
[48,43,24,0],
[48,43,24,1],
[48,43,24,2],
[48,43,24,3],
[48,43,24,4],
[48,43,24,5],
[48,43,24,6],
[48,43,24,8],
[48,43,24,9],
[48,43,24,10],
[48,43,24,13],
[48,43,24,15],
[48,43,24,17],
[48,43,24,18],
[48,43,24,19],
[48,43,24,20],
[48,43,24,21],
[48,43,24,22],
[48,43,24,23],
[48,43,26,0],
[48,43,26,1],
[48,43,26,2],
[48,43,26,3],
[48,43,26,4],
[48,43,26,5],
[48,43,26,6],
[48,43,26,10],
[48,43,26,13],
[48,43,26,14],
[48,43,26,16],
[48,43,26,17],
[48,43,26,19],
[48,43,26,20],
[48,43,26,21],
[48,43,26,22],
[48,43,26,23],
[48,43,26,24],
[48,43,27,0],
[48,43,27,1],
[48,43,27,2],
[48,43,27,3],
[48,43,27,4],
[48,43,27,5],
[48,43,27,8],
[48,43,27,9],
[48,43,27,13],
[48,43,27,14],
[48,43,27,15],
[48,43,27,16],
[48,43,27,17],
[48,43,27,18],
[48,43,27,19],
[48,43,27,21],
[48,43,27,23],
[48,43,27,24],
[48,43,30,3],
[48,43,30,4],
[48,43,30,5],
[48,43,30,6],
[48,43,30,8],
[48,43,30,9],
[48,43,30,10],
[48,43,30,13],
[48,43,30,14],
[48,43,30,15],
[48,43,30,16],
[48,43,30,17],
[48,43,30,18],
[48,43,30,19],
[48,43,30,20],
[48,43,31,0],
[48,43,31,1],
[48,43,31,2],
[48,43,31,3],
[48,43,31,4],
[48,43,31,5],
[48,43,31,6],
[48,43,31,8],
[48,43,31,9],
[48,43,31,10],
[48,43,31,13],
[48,43,31,14],
[48,43,31,15],
[48,43,31,16],
[48,43,31,17],
[48,43,31,18],
[48,43,31,19],
[48,43,35,0],
[48,43,35,1],
[48,43,35,2],
[48,43,35,3],
[48,43,35,4],
[48,43,35,5],
[48,43,35,6],
[48,43,35,8],
[48,43,35,10],
[48,43,35,13],
[48,43,35,14],
[48,43,36,0],
[48,43,36,1],
[48,43,36,2],
[48,43,36,3],
[48,43,36,4],
[48,43,36,5],
[48,43,36,6],
[48,43,36,8],
[48,43,36,9],
[48,43,36,10],
[48,43,37,0],
[48,43,37,1],
[48,43,37,2],
[48,43,37,3],
[48,43,37,5],
[48,43,37,6],
[48,43,37,8],
[48,43,37,9],
[48,43,37,10],
[48,43,39,0],
[48,43,39,1],
[48,43,39,2],
[48,43,39,4],
[48,43,40,0],
[48,43,40,1],
[48,43,40,2],
[48,43,40,3],
[48,43,40,4],
[48,43,40,5],
[48,43,41,0],
[48,43,41,1],
[48,43,41,2],
[48,43,41,3],
[48,43,41,4],
[48,43,41,5],
[48,43,42,0],
[48,43,42,1],
[48,43,42,2],
[48,43,42,3],
[48,43,42,4],
[48,44,3,0],
[48,44,3,1],
[48,44,3,2],
[48,44,4,0],
[48,44,4,1],
[48,44,4,2],
[48,44,4,3],
[48,44,5,0],
[48,44,5,1],
[48,44,5,2],
[48,44,5,4],
[48,44,6,0],
[48,44,6,1],
[48,44,6,2],
[48,44,6,4],
[48,44,7,0],
[48,44,7,1],
[48,44,7,2],
[48,44,7,3],
[48,44,7,4],
[48,44,7,5],
[48,44,7,6],
[48,44,8,0],
[48,44,8,1],
[48,44,8,2],
[48,44,8,4],
[48,44,8,7],
[48,44,9,0],
[48,44,9,1],
[48,44,9,2],
[48,44,9,3],
[48,44,9,4],
[48,44,9,5],
[48,44,9,6],
[48,44,9,7],
[48,44,9,8],
[48,44,10,0],
[48,44,10,1],
[48,44,10,2],
[48,44,10,3],
[48,44,10,4],
[48,44,10,5],
[48,44,10,6],
[48,44,10,7],
[48,44,10,8],
[48,44,10,9],
[48,44,13,0],
[48,44,13,1],
[48,44,13,2],
[48,44,13,3],
[48,44,13,4],
[48,44,13,5],
[48,44,13,6],
[48,44,13,7],
[48,44,13,8],
[48,44,13,9],
[48,44,13,10],
[48,44,14,0],
[48,44,14,1],
[48,44,14,2],
[48,44,14,3],
[48,44,14,4],
[48,44,14,5],
[48,44,14,6],
[48,44,14,7],
[48,44,14,8],
[48,44,14,9],
[48,44,14,10],
[48,44,15,0],
[48,44,15,1],
[48,44,15,2],
[48,44,15,3],
[48,44,15,4],
[48,44,15,5],
[48,44,15,6],
[48,44,15,7],
[48,44,15,8],
[48,44,15,10],
[48,44,15,13],
[48,44,15,14],
[48,44,16,0],
[48,44,16,1],
[48,44,16,2],
[48,44,16,3],
[48,44,16,4],
[48,44,16,5],
[48,44,16,6],
[48,44,16,7],
[48,44,16,8],
[48,44,16,9],
[48,44,16,10],
[48,44,16,13],
[48,44,16,14],
[48,44,16,15],
[48,44,18,0],
[48,44,18,1],
[48,44,18,2],
[48,44,18,3],
[48,44,18,4],
[48,44,18,5],
[48,44,18,6],
[48,44,18,7],
[48,44,18,8],
[48,44,18,10],
[48,44,18,13],
[48,44,18,14],
[48,44,18,16],
[48,44,19,0],
[48,44,19,1],
[48,44,19,2],
[48,44,19,3],
[48,44,19,4],
[48,44,19,5],
[48,44,19,6],
[48,44,19,7],
[48,44,19,8],
[48,44,19,9],
[48,44,19,10],
[48,44,19,15],
[48,44,19,16],
[48,44,20,0],
[48,44,20,1],
[48,44,20,2],
[48,44,20,3],
[48,44,20,4],
[48,44,20,5],
[48,44,20,6],
[48,44,20,7],
[48,44,20,8],
[48,44,20,9],
[48,44,20,13],
[48,44,20,14],
[48,44,20,15],
[48,44,20,18],
[48,44,20,19],
[48,44,21,0],
[48,44,21,1],
[48,44,21,2],
[48,44,21,4],
[48,44,21,7],
[48,44,21,9],
[48,44,21,10],
[48,44,21,13],
[48,44,21,14],
[48,44,21,16],
[48,44,21,18],
[48,44,21,19],
[48,44,21,20],
[48,44,22,0],
[48,44,22,1],
[48,44,22,2],
[48,44,22,3],
[48,44,22,4],
[48,44,22,5],
[48,44,22,6],
[48,44,22,7],
[48,44,22,8],
[48,44,22,9],
[48,44,22,13],
[48,44,22,15],
[48,44,22,16],
[48,44,22,18],
[48,44,22,19],
[48,44,22,21],
[48,44,23,0],
[48,44,23,1],
[48,44,23,2],
[48,44,23,3],
[48,44,23,4],
[48,44,23,5],
[48,44,23,6],
[48,44,23,7],
[48,44,23,8],
[48,44,23,9],
[48,44,23,10],
[48,44,23,14],
[48,44,23,15],
[48,44,23,16],
[48,44,23,18],
[48,44,23,19],
[48,44,23,20],
[48,44,23,21],
[48,44,23,22],
[48,44,24,0],
[48,44,24,1],
[48,44,24,2],
[48,44,24,3],
[48,44,24,4],
[48,44,24,5],
[48,44,24,6],
[48,44,24,7],
[48,44,24,8],
[48,44,24,9],
[48,44,24,10],
[48,44,24,14],
[48,44,24,15],
[48,44,24,18],
[48,44,24,19],
[48,44,24,20],
[48,44,24,21],
[48,44,24,22],
[48,44,24,23],
[48,44,25,0],
[48,44,25,1],
[48,44,25,2],
[48,44,25,3],
[48,44,25,4],
[48,44,25,5],
[48,44,25,6],
[48,44,25,10],
[48,44,25,13],
[48,44,25,14],
[48,44,25,15],
[48,44,25,16],
[48,44,25,18],
[48,44,25,19],
[48,44,25,20],
[48,44,25,21],
[48,44,25,22],
[48,44,25,23],
[48,44,25,24],
[48,44,26,0],
[48,44,26,1],
[48,44,26,2],
[48,44,26,3],
[48,44,26,4],
[48,44,26,5],
[48,44,26,8],
[48,44,26,10],
[48,44,26,13],
[48,44,26,14],
[48,44,26,16],
[48,44,26,19],
[48,44,26,20],
[48,44,26,21],
[48,44,26,22],
[48,44,26,23],
[48,44,26,24],
[48,44,27,0],
[48,44,27,1],
[48,44,27,2],
[48,44,27,3],
[48,44,27,4],
[48,44,27,6],
[48,44,27,7],
[48,44,27,8],
[48,44,27,9],
[48,44,27,13],
[48,44,27,14],
[48,44,27,15],
[48,44,27,16],
[48,44,27,18],
[48,44,27,19],
[48,44,27,21],
[48,44,28,0],
[48,44,28,1],
[48,44,28,2],
[48,44,28,5],
[48,44,28,6],
[48,44,28,8],
[48,44,28,9],
[48,44,28,10],
[48,44,28,13],
[48,44,28,14],
[48,44,28,15],
[48,44,28,16],
[48,44,28,18],
[48,44,28,19],
[48,44,28,20],
[48,44,28,21],
[48,44,30,0],
[48,44,30,1],
[48,44,30,2],
[48,44,30,3],
[48,44,30,4],
[48,44,30,5],
[48,44,30,6],
[48,44,30,7],
[48,44,30,8],
[48,44,30,9],
[48,44,30,10],
[48,44,30,13],
[48,44,30,14],
[48,44,30,15],
[48,44,30,16],
[48,44,30,18],
[48,44,30,19],
[48,44,31,0],
[48,44,31,1],
[48,44,31,2],
[48,44,31,3],
[48,44,31,4],
[48,44,31,5],
[48,44,31,6],
[48,44,31,7],
[48,44,31,8],
[48,44,31,9],
[48,44,31,10],
[48,44,31,13],
[48,44,31,14],
[48,44,31,15],
[48,44,31,16],
[48,44,31,18],
[48,44,34,0],
[48,44,34,1],
[48,44,34,2],
[48,44,34,3],
[48,44,34,4],
[48,44,34,5],
[48,44,34,6],
[48,44,34,8],
[48,44,34,9],
[48,44,34,10],
[48,44,34,13],
[48,44,34,14],
[48,44,35,0],
[48,44,35,1],
[48,44,35,2],
[48,44,35,3],
[48,44,35,4],
[48,44,35,5],
[48,44,35,6],
[48,44,35,7],
[48,44,35,8],
[48,44,35,10],
[48,44,35,13],
[48,44,36,0],
[48,44,36,1],
[48,44,36,2],
[48,44,36,3],
[48,44,36,4],
[48,44,36,5],
[48,44,36,6],
[48,44,36,7],
[48,44,36,8],
[48,44,36,9],
[48,44,36,10],
[48,44,37,0],
[48,44,37,1],
[48,44,37,2],
[48,44,37,3],
[48,44,37,5],
[48,44,37,6],
[48,44,37,7],
[48,44,37,8],
[48,44,37,9],
[48,44,38,0],
[48,44,38,1],
[48,44,38,2],
[48,44,38,3],
[48,44,38,4],
[48,44,38,5],
[48,44,38,6],
[48,44,39,0],
[48,44,39,1],
[48,44,39,2],
[48,44,39,4],
[48,44,40,0],
[48,44,40,1],
[48,44,40,2],
[48,44,40,3],
[48,44,40,4],
[48,44,41,0],
[48,44,41,1],
[48,44,41,2],
[48,44,41,3],
[48,44,41,4],
[48,44,42,0],
[48,44,42,1],
[48,44,42,2],
[48,45,3,0],
[48,45,3,1],
[48,45,3,2],
[48,45,4,0],
[48,45,4,1],
[48,45,4,2],
[48,45,4,3],
[48,45,5,0],
[48,45,5,1],
[48,45,5,2],
[48,45,5,4],
[48,45,6,0],
[48,45,6,1],
[48,45,6,2],
[48,45,6,4],
[48,45,7,0],
[48,45,7,1],
[48,45,7,2],
[48,45,7,3],
[48,45,7,4],
[48,45,7,5],
[48,45,7,6],
[48,45,8,0],
[48,45,8,1],
[48,45,8,2],
[48,45,8,4],
[48,45,8,7],
[48,45,10,0],
[48,45,10,1],
[48,45,10,2],
[48,45,10,3],
[48,45,10,4],
[48,45,10,5],
[48,45,10,6],
[48,45,10,7],
[48,45,10,8],
[48,45,13,0],
[48,45,13,1],
[48,45,13,2],
[48,45,13,3],
[48,45,13,4],
[48,45,13,5],
[48,45,13,6],
[48,45,13,7],
[48,45,13,8],
[48,45,13,10],
[48,45,14,0],
[48,45,14,1],
[48,45,14,2],
[48,45,14,3],
[48,45,14,4],
[48,45,14,5],
[48,45,14,6],
[48,45,14,7],
[48,45,14,8],
[48,45,14,10],
[48,45,16,0],
[48,45,16,1],
[48,45,16,2],
[48,45,16,3],
[48,45,16,4],
[48,45,16,5],
[48,45,16,6],
[48,45,16,7],
[48,45,16,8],
[48,45,16,10],
[48,45,16,13],
[48,45,16,14],
[48,45,17,0],
[48,45,17,1],
[48,45,17,2],
[48,45,17,3],
[48,45,17,4],
[48,45,17,5],
[48,45,17,6],
[48,45,17,7],
[48,45,17,8],
[48,45,17,10],
[48,45,17,13],
[48,45,17,14],
[48,45,17,16],
[48,45,19,0],
[48,45,19,1],
[48,45,19,2],
[48,45,19,3],
[48,45,19,4],
[48,45,19,5],
[48,45,19,6],
[48,45,19,7],
[48,45,19,8],
[48,45,19,10],
[48,45,20,0],
[48,45,20,1],
[48,45,20,2],
[48,45,20,3],
[48,45,20,4],
[48,45,20,5],
[48,45,20,6],
[48,45,20,7],
[48,45,20,8],
[48,45,20,13],
[48,45,20,14],
[48,45,20,16],
[48,45,20,17],
[48,45,20,19],
[48,45,21,0],
[48,45,21,1],
[48,45,21,2],
[48,45,21,4],
[48,45,21,7],
[48,45,21,10],
[48,45,21,13],
[48,45,21,16],
[48,45,21,17],
[48,45,21,19],
[48,45,21,20],
[48,45,22,0],
[48,45,22,1],
[48,45,22,2],
[48,45,22,3],
[48,45,22,4],
[48,45,22,5],
[48,45,22,6],
[48,45,22,7],
[48,45,22,8],
[48,45,22,14],
[48,45,22,16],
[48,45,22,17],
[48,45,22,19],
[48,45,22,21],
[48,45,23,0],
[48,45,23,1],
[48,45,23,2],
[48,45,23,3],
[48,45,23,4],
[48,45,23,5],
[48,45,23,6],
[48,45,23,7],
[48,45,23,8],
[48,45,23,13],
[48,45,23,14],
[48,45,23,16],
[48,45,23,17],
[48,45,23,19],
[48,45,23,20],
[48,45,23,21],
[48,45,23,22],
[48,45,24,0],
[48,45,24,1],
[48,45,24,2],
[48,45,24,3],
[48,45,24,4],
[48,45,24,5],
[48,45,24,6],
[48,45,24,7],
[48,45,24,8],
[48,45,24,13],
[48,45,24,14],
[48,45,24,17],
[48,45,24,19],
[48,45,24,20],
[48,45,24,21],
[48,45,24,22],
[48,45,24,23],
[48,45,25,0],
[48,45,25,1],
[48,45,25,2],
[48,45,25,3],
[48,45,25,4],
[48,45,25,5],
[48,45,25,8],
[48,45,25,10],
[48,45,25,13],
[48,45,25,14],
[48,45,25,16],
[48,45,25,17],
[48,45,25,19],
[48,45,25,20],
[48,45,25,21],
[48,45,25,22],
[48,45,25,23],
[48,45,25,24],
[48,45,27,0],
[48,45,27,1],
[48,45,27,2],
[48,45,27,5],
[48,45,27,6],
[48,45,27,7],
[48,45,27,8],
[48,45,27,13],
[48,45,27,14],
[48,45,27,16],
[48,45,27,17],
[48,45,27,19],
[48,45,27,21],
[48,45,28,3],
[48,45,28,4],
[48,45,28,5],
[48,45,28,6],
[48,45,28,8],
[48,45,28,10],
[48,45,28,13],
[48,45,28,14],
[48,45,28,16],
[48,45,28,17],
[48,45,28,19],
[48,45,28,20],
[48,45,30,0],
[48,45,30,1],
[48,45,30,2],
[48,45,30,3],
[48,45,30,4],
[48,45,30,5],
[48,45,30,6],
[48,45,30,7],
[48,45,30,8],
[48,45,30,10],
[48,45,30,13],
[48,45,30,14],
[48,45,30,16],
[48,45,30,17],
[48,45,31,0],
[48,45,31,1],
[48,45,31,2],
[48,45,31,3],
[48,45,31,4],
[48,45,31,5],
[48,45,31,6],
[48,45,31,7],
[48,45,31,8],
[48,45,31,10],
[48,45,31,13],
[48,45,31,14],
[48,45,31,16],
[48,45,31,17],
[48,45,34,0],
[48,45,34,1],
[48,45,34,2],
[48,45,34,3],
[48,45,34,4],
[48,45,34,5],
[48,45,34,6],
[48,45,34,8],
[48,45,34,10],
[48,45,34,13],
[48,45,36,0],
[48,45,36,1],
[48,45,36,2],
[48,45,36,3],
[48,45,36,4],
[48,45,36,5],
[48,45,36,6],
[48,45,36,7],
[48,45,36,8],
[48,45,37,0],
[48,45,37,1],
[48,45,37,2],
[48,45,37,3],
[48,45,37,5],
[48,45,37,6],
[48,45,37,7],
[48,45,38,0],
[48,45,38,1],
[48,45,38,2],
[48,45,38,3],
[48,45,38,4],
[48,45,38,5],
[48,45,39,0],
[48,45,39,1],
[48,45,39,2],
[48,45,39,4],
[48,46,3,0],
[48,46,3,1],
[48,46,3,2],
[48,46,4,0],
[48,46,4,1],
[48,46,4,2],
[48,46,4,3],
[48,46,5,0],
[48,46,5,1],
[48,46,5,2],
[48,46,5,4],
[48,46,6,0],
[48,46,6,1],
[48,46,6,2],
[48,46,6,4],
[48,46,7,0],
[48,46,7,1],
[48,46,7,2],
[48,46,7,3],
[48,46,7,4],
[48,46,7,5],
[48,46,7,6],
[48,46,8,0],
[48,46,8,1],
[48,46,8,2],
[48,46,8,4],
[48,46,8,7],
[48,46,10,0],
[48,46,10,1],
[48,46,10,2],
[48,46,10,3],
[48,46,10,4],
[48,46,10,5],
[48,46,10,6],
[48,46,10,7],
[48,46,10,8],
[48,46,13,0],
[48,46,13,1],
[48,46,13,2],
[48,46,13,3],
[48,46,13,4],
[48,46,13,5],
[48,46,13,6],
[48,46,13,7],
[48,46,13,8],
[48,46,13,10],
[48,46,14,0],
[48,46,14,1],
[48,46,14,2],
[48,46,14,3],
[48,46,14,4],
[48,46,14,5],
[48,46,14,6],
[48,46,14,7],
[48,46,14,8],
[48,46,14,10],
[48,46,16,0],
[48,46,16,1],
[48,46,16,2],
[48,46,16,3],
[48,46,16,4],
[48,46,16,5],
[48,46,16,6],
[48,46,16,7],
[48,46,16,8],
[48,46,16,10],
[48,46,16,13],
[48,46,16,14],
[48,46,17,0],
[48,46,17,1],
[48,46,17,2],
[48,46,17,3],
[48,46,17,4],
[48,46,17,5],
[48,46,17,6],
[48,46,17,7],
[48,46,17,8],
[48,46,17,10],
[48,46,17,13],
[48,46,17,14],
[48,46,17,16],
[48,46,19,0],
[48,46,19,1],
[48,46,19,2],
[48,46,19,3],
[48,46,19,4],
[48,46,19,5],
[48,46,19,6],
[48,46,19,7],
[48,46,19,8],
[48,46,19,10],
[48,46,19,16],
[48,46,19,17],
[48,46,20,0],
[48,46,20,1],
[48,46,20,2],
[48,46,20,3],
[48,46,20,4],
[48,46,20,5],
[48,46,20,6],
[48,46,20,7],
[48,46,20,8],
[48,46,20,13],
[48,46,20,16],
[48,46,20,17],
[48,46,20,19],
[48,46,21,0],
[48,46,21,1],
[48,46,21,2],
[48,46,21,4],
[48,46,21,7],
[48,46,21,10],
[48,46,21,14],
[48,46,21,16],
[48,46,21,17],
[48,46,21,19],
[48,46,21,20],
[48,46,22,0],
[48,46,22,1],
[48,46,22,2],
[48,46,22,3],
[48,46,22,4],
[48,46,22,5],
[48,46,22,6],
[48,46,22,7],
[48,46,22,8],
[48,46,22,13],
[48,46,22,14],
[48,46,22,16],
[48,46,22,17],
[48,46,22,19],
[48,46,22,21],
[48,46,23,0],
[48,46,23,1],
[48,46,23,2],
[48,46,23,3],
[48,46,23,4],
[48,46,23,5],
[48,46,23,6],
[48,46,23,7],
[48,46,23,10],
[48,46,23,13],
[48,46,23,14],
[48,46,23,16],
[48,46,23,17],
[48,46,23,19],
[48,46,23,20],
[48,46,23,21],
[48,46,23,22],
[48,46,24,0],
[48,46,24,1],
[48,46,24,2],
[48,46,24,3],
[48,46,24,4],
[48,46,24,5],
[48,46,24,6],
[48,46,24,7],
[48,46,24,10],
[48,46,24,13],
[48,46,24,14],
[48,46,24,17],
[48,46,24,19],
[48,46,24,20],
[48,46,24,21],
[48,46,24,22],
[48,46,24,23],
[48,46,25,0],
[48,46,25,1],
[48,46,25,2],
[48,46,25,3],
[48,46,25,4],
[48,46,25,6],
[48,46,25,8],
[48,46,25,10],
[48,46,25,13],
[48,46,25,14],
[48,46,25,16],
[48,46,25,17],
[48,46,25,19],
[48,46,25,20],
[48,46,25,21],
[48,46,25,22],
[48,46,27,3],
[48,46,27,4],
[48,46,27,5],
[48,46,27,6],
[48,46,27,7],
[48,46,27,8],
[48,46,27,13],
[48,46,27,14],
[48,46,27,16],
[48,46,27,17],
[48,46,27,19],
[48,46,28,0],
[48,46,28,1],
[48,46,28,2],
[48,46,28,3],
[48,46,28,4],
[48,46,28,5],
[48,46,28,6],
[48,46,28,8],
[48,46,28,10],
[48,46,28,13],
[48,46,28,14],
[48,46,28,16],
[48,46,28,17],
[48,46,28,19],
[48,46,30,0],
[48,46,30,1],
[48,46,30,2],
[48,46,30,3],
[48,46,30,4],
[48,46,30,5],
[48,46,30,6],
[48,46,30,7],
[48,46,30,8],
[48,46,30,10],
[48,46,30,13],
[48,46,30,14],
[48,46,30,16],
[48,46,30,17],
[48,46,31,0],
[48,46,31,1],
[48,46,31,2],
[48,46,31,3],
[48,46,31,4],
[48,46,31,5],
[48,46,31,6],
[48,46,31,7],
[48,46,31,8],
[48,46,31,10],
[48,46,31,13],
[48,46,31,14],
[48,46,34,0],
[48,46,34,1],
[48,46,34,2],
[48,46,34,3],
[48,46,34,4],
[48,46,34,5],
[48,46,34,6],
[48,46,34,8],
[48,46,34,10],
[48,46,36,0],
[48,46,36,1],
[48,46,36,2],
[48,46,36,3],
[48,46,36,4],
[48,46,36,5],
[48,46,36,6],
[48,46,36,7],
[48,46,37,0],
[48,46,37,1],
[48,46,37,2],
[48,46,37,3],
[48,46,37,5],
[48,46,38,0],
[48,46,38,1],
[48,46,38,2],
[48,46,38,3],
[48,46,38,4],
[48,46,39,0],
[48,46,39,1],
[48,46,39,2],
[48,47,3,0],
[48,47,3,1],
[48,47,3,2],
[48,47,4,0],
[48,47,4,1],
[48,47,4,2],
[48,47,4,3],
[48,47,5,0],
[48,47,5,1],
[48,47,5,2],
[48,47,5,4],
[48,47,6,0],
[48,47,6,1],
[48,47,6,2],
[48,47,6,4],
[48,47,7,0],
[48,47,7,1],
[48,47,7,2],
[48,47,7,3],
[48,47,7,4],
[48,47,7,5],
[48,47,7,6],
[48,47,8,0],
[48,47,8,1],
[48,47,8,2],
[48,47,8,4],
[48,47,8,7],
[48,47,9,0],
[48,47,9,1],
[48,47,9,2],
[48,47,9,3],
[48,47,9,4],
[48,47,9,5],
[48,47,9,6],
[48,47,9,7],
[48,47,9,8],
[48,47,10,0],
[48,47,10,1],
[48,47,10,2],
[48,47,10,3],
[48,47,10,4],
[48,47,10,5],
[48,47,10,6],
[48,47,10,7],
[48,47,10,8],
[48,47,10,9],
[48,47,13,0],
[48,47,13,1],
[48,47,13,2],
[48,47,13,3],
[48,47,13,4],
[48,47,13,5],
[48,47,13,6],
[48,47,13,7],
[48,47,13,8],
[48,47,13,9],
[48,47,13,10],
[48,47,14,0],
[48,47,14,1],
[48,47,14,2],
[48,47,14,3],
[48,47,14,4],
[48,47,14,5],
[48,47,14,6],
[48,47,14,7],
[48,47,14,8],
[48,47,14,9],
[48,47,14,10],
[48,47,15,0],
[48,47,15,1],
[48,47,15,2],
[48,47,15,3],
[48,47,15,4],
[48,47,15,5],
[48,47,15,6],
[48,47,15,7],
[48,47,15,8],
[48,47,15,10],
[48,47,15,13],
[48,47,15,14],
[48,47,16,0],
[48,47,16,1],
[48,47,16,2],
[48,47,16,3],
[48,47,16,4],
[48,47,16,5],
[48,47,16,6],
[48,47,16,7],
[48,47,16,8],
[48,47,16,9],
[48,47,16,10],
[48,47,16,13],
[48,47,16,14],
[48,47,16,15],
[48,47,17,0],
[48,47,17,1],
[48,47,17,2],
[48,47,17,3],
[48,47,17,4],
[48,47,17,5],
[48,47,17,6],
[48,47,17,7],
[48,47,17,8],
[48,47,17,9],
[48,47,17,10],
[48,47,17,13],
[48,47,17,14],
[48,47,17,15],
[48,47,18,0],
[48,47,18,1],
[48,47,18,2],
[48,47,18,3],
[48,47,18,4],
[48,47,18,5],
[48,47,18,6],
[48,47,18,7],
[48,47,18,8],
[48,47,18,10],
[48,47,18,13],
[48,47,18,14],
[48,47,18,16],
[48,47,18,17],
[48,47,19,0],
[48,47,19,1],
[48,47,19,2],
[48,47,19,3],
[48,47,19,4],
[48,47,19,5],
[48,47,19,6],
[48,47,19,7],
[48,47,19,8],
[48,47,19,9],
[48,47,19,10],
[48,47,19,15],
[48,47,19,16],
[48,47,19,17],
[48,47,19,18],
[48,47,20,0],
[48,47,20,1],
[48,47,20,2],
[48,47,20,3],
[48,47,20,4],
[48,47,20,5],
[48,47,20,6],
[48,47,20,7],
[48,47,20,8],
[48,47,20,9],
[48,47,20,14],
[48,47,20,15],
[48,47,20,16],
[48,47,20,17],
[48,47,20,18],
[48,47,20,19],
[48,47,21,0],
[48,47,21,1],
[48,47,21,2],
[48,47,21,4],
[48,47,21,7],
[48,47,21,9],
[48,47,21,13],
[48,47,21,14],
[48,47,21,15],
[48,47,21,16],
[48,47,21,17],
[48,47,21,18],
[48,47,21,19],
[48,47,21,20],
[48,47,22,0],
[48,47,22,1],
[48,47,22,2],
[48,47,22,3],
[48,47,22,4],
[48,47,22,5],
[48,47,22,6],
[48,47,22,7],
[48,47,22,13],
[48,47,22,14],
[48,47,22,15],
[48,47,22,16],
[48,47,22,17],
[48,47,22,18],
[48,47,22,19],
[48,47,22,21],
[48,47,24,0],
[48,47,24,1],
[48,47,24,2],
[48,47,24,3],
[48,47,24,4],
[48,47,24,5],
[48,47,24,8],
[48,47,24,9],
[48,47,24,10],
[48,47,24,13],
[48,47,24,14],
[48,47,24,15],
[48,47,24,17],
[48,47,24,18],
[48,47,24,19],
[48,47,24,20],
[48,47,24,21],
[48,47,24,22],
[48,47,25,0],
[48,47,25,1],
[48,47,25,2],
[48,47,25,5],
[48,47,25,6],
[48,47,25,8],
[48,47,25,9],
[48,47,25,10],
[48,47,25,13],
[48,47,25,14],
[48,47,25,15],
[48,47,25,16],
[48,47,25,17],
[48,47,25,18],
[48,47,25,19],
[48,47,25,20],
[48,47,25,21],
[48,47,26,3],
[48,47,26,4],
[48,47,26,5],
[48,47,26,6],
[48,47,26,7],
[48,47,26,8],
[48,47,26,10],
[48,47,26,13],
[48,47,26,14],
[48,47,26,16],
[48,47,26,17],
[48,47,26,19],
[48,47,26,20],
[48,47,27,0],
[48,47,27,1],
[48,47,27,2],
[48,47,27,3],
[48,47,27,4],
[48,47,27,5],
[48,47,27,6],
[48,47,27,7],
[48,47,27,8],
[48,47,27,9],
[48,47,27,13],
[48,47,27,14],
[48,47,27,15],
[48,47,27,16],
[48,47,27,17],
[48,47,27,18],
[48,47,27,19],
[48,47,28,0],
[48,47,28,1],
[48,47,28,2],
[48,47,28,3],
[48,47,28,4],
[48,47,28,5],
[48,47,28,6],
[48,47,28,8],
[48,47,28,9],
[48,47,28,10],
[48,47,28,13],
[48,47,28,14],
[48,47,28,15],
[48,47,28,16],
[48,47,28,17],
[48,47,28,18],
[48,47,34,0],
[48,47,34,1],
[48,47,34,2],
[48,47,34,3],
[48,47,34,4],
[48,47,34,5],
[48,47,34,6],
[48,47,34,8],
[48,47,34,9],
[48,47,35,0],
[48,47,35,1],
[48,47,35,2],
[48,47,35,3],
[48,47,35,4],
[48,47,35,5],
[48,47,35,6],
[48,47,35,7],
[48,47,36,0],
[48,47,36,1],
[48,47,36,2],
[48,47,36,3],
[48,47,36,4],
[48,47,36,5],
[48,47,37,0],
[48,47,37,1],
[48,47,37,2],
[48,47,37,3],
[48,47,38,0],
[48,47,38,1],
[48,47,38,2],
[49,4,3,0],
[49,4,3,1],
[49,4,3,2],
[49,5,4,0],
[49,5,4,1],
[49,5,4,2],
[49,6,4,0],
[49,6,4,1],
[49,6,4,2],
[49,7,3,0],
[49,7,3,1],
[49,7,3,2],
[49,7,4,0],
[49,7,4,1],
[49,7,4,2],
[49,7,4,3],
[49,7,5,0],
[49,7,5,1],
[49,7,5,2],
[49,7,5,4],
[49,7,6,0],
[49,7,6,1],
[49,7,6,2],
[49,7,6,4],
[49,8,4,0],
[49,8,4,1],
[49,8,4,2],
[49,8,7,0],
[49,8,7,1],
[49,8,7,2],
[49,8,7,4],
[49,10,3,0],
[49,10,3,1],
[49,10,3,2],
[49,10,4,0],
[49,10,4,1],
[49,10,4,2],
[49,10,4,3],
[49,10,5,0],
[49,10,5,1],
[49,10,5,2],
[49,10,5,4],
[49,10,6,0],
[49,10,6,1],
[49,10,6,2],
[49,10,6,4],
[49,10,7,0],
[49,10,7,1],
[49,10,7,2],
[49,10,7,3],
[49,10,7,4],
[49,10,7,5],
[49,10,7,6],
[49,10,8,0],
[49,10,8,1],
[49,10,8,2],
[49,10,8,4],
[49,10,8,7],
[49,11,3,0],
[49,11,3,1],
[49,11,3,2],
[49,11,4,0],
[49,11,4,1],
[49,11,4,2],
[49,11,4,3],
[49,11,5,0],
[49,11,5,1],
[49,11,5,2],
[49,11,5,4],
[49,11,6,0],
[49,11,6,1],
[49,11,6,2],
[49,11,6,4],
[49,11,7,0],
[49,11,7,1],
[49,11,7,2],
[49,11,7,3],
[49,11,7,4],
[49,11,7,5],
[49,11,7,6],
[49,11,8,0],
[49,11,8,1],
[49,11,8,2],
[49,11,8,4],
[49,11,8,7],
[49,11,10,0],
[49,11,10,1],
[49,11,10,2],
[49,11,10,3],
[49,11,10,4],
[49,11,10,5],
[49,11,10,6],
[49,11,10,7],
[49,11,10,8],
[49,12,3,0],
[49,12,3,1],
[49,12,3,2],
[49,12,4,0],
[49,12,4,1],
[49,12,4,2],
[49,12,4,3],
[49,12,5,0],
[49,12,5,1],
[49,12,5,2],
[49,12,5,4],
[49,12,6,0],
[49,12,6,1],
[49,12,6,2],
[49,12,6,4],
[49,12,7,0],
[49,12,7,1],
[49,12,7,2],
[49,12,7,3],
[49,12,7,4],
[49,12,7,5],
[49,12,7,6],
[49,12,8,0],
[49,12,8,1],
[49,12,8,2],
[49,12,8,4],
[49,12,8,7],
[49,12,10,0],
[49,12,10,1],
[49,12,10,2],
[49,12,10,3],
[49,12,10,4],
[49,12,10,5],
[49,12,10,6],
[49,12,10,7],
[49,12,10,8],
[49,13,3,0],
[49,13,3,1],
[49,13,3,2],
[49,13,4,0],
[49,13,4,1],
[49,13,4,2],
[49,13,4,3],
[49,13,5,0],
[49,13,5,1],
[49,13,5,2],
[49,13,5,4],
[49,13,6,0],
[49,13,6,1],
[49,13,6,2],
[49,13,6,4],
[49,13,7,0],
[49,13,7,1],
[49,13,7,2],
[49,13,7,3],
[49,13,7,4],
[49,13,7,5],
[49,13,7,6],
[49,13,8,0],
[49,13,8,1],
[49,13,8,2],
[49,13,8,4],
[49,13,8,7],
[49,13,10,0],
[49,13,10,1],
[49,13,10,2],
[49,13,10,3],
[49,13,10,4],
[49,13,10,5],
[49,13,10,6],
[49,13,10,7],
[49,13,10,8],
[49,13,11,0],
[49,13,11,1],
[49,13,11,2],
[49,13,11,3],
[49,13,11,4],
[49,13,11,5],
[49,13,11,6],
[49,13,11,7],
[49,13,11,8],
[49,13,11,10],
[49,13,12,0],
[49,13,12,1],
[49,13,12,2],
[49,13,12,3],
[49,13,12,4],
[49,13,12,5],
[49,13,12,6],
[49,13,12,7],
[49,13,12,8],
[49,13,12,10],
[49,14,3,0],
[49,14,3,1],
[49,14,3,2],
[49,14,4,0],
[49,14,4,1],
[49,14,4,2],
[49,14,4,3],
[49,14,5,0],
[49,14,5,1],
[49,14,5,2],
[49,14,5,4],
[49,14,6,0],
[49,14,6,1],
[49,14,6,2],
[49,14,6,4],
[49,14,7,0],
[49,14,7,1],
[49,14,7,2],
[49,14,7,3],
[49,14,7,4],
[49,14,7,5],
[49,14,7,6],
[49,14,8,0],
[49,14,8,1],
[49,14,8,2],
[49,14,8,4],
[49,14,8,7],
[49,14,10,0],
[49,14,10,1],
[49,14,10,2],
[49,14,10,3],
[49,14,10,4],
[49,14,10,5],
[49,14,10,6],
[49,14,10,7],
[49,14,10,8],
[49,14,11,0],
[49,14,11,1],
[49,14,11,2],
[49,14,11,3],
[49,14,11,4],
[49,14,11,5],
[49,14,11,6],
[49,14,11,7],
[49,14,11,8],
[49,14,11,10],
[49,14,12,0],
[49,14,12,1],
[49,14,12,2],
[49,14,12,3],
[49,14,12,4],
[49,14,12,5],
[49,14,12,6],
[49,14,12,7],
[49,14,12,8],
[49,14,12,10],
[49,16,3,0],
[49,16,3,1],
[49,16,3,2],
[49,16,4,0],
[49,16,4,1],
[49,16,4,2],
[49,16,4,3],
[49,16,5,0],
[49,16,5,1],
[49,16,5,2],
[49,16,5,4],
[49,16,6,0],
[49,16,6,1],
[49,16,6,2],
[49,16,6,4],
[49,16,7,0],
[49,16,7,1],
[49,16,7,2],
[49,16,7,3],
[49,16,7,4],
[49,16,7,5],
[49,16,7,6],
[49,16,8,0],
[49,16,8,1],
[49,16,8,2],
[49,16,8,4],
[49,16,8,7],
[49,16,10,0],
[49,16,10,1],
[49,16,10,2],
[49,16,10,3],
[49,16,10,4],
[49,16,10,5],
[49,16,10,6],
[49,16,10,7],
[49,16,10,8],
[49,16,11,0],
[49,16,11,1],
[49,16,11,2],
[49,16,11,3],
[49,16,11,4],
[49,16,11,5],
[49,16,11,6],
[49,16,11,7],
[49,16,11,8],
[49,16,11,10],
[49,16,12,0],
[49,16,12,1],
[49,16,12,2],
[49,16,12,3],
[49,16,12,4],
[49,16,12,5],
[49,16,12,6],
[49,16,12,7],
[49,16,12,8],
[49,16,12,10],
[49,16,13,0],
[49,16,13,1],
[49,16,13,2],
[49,16,13,3],
[49,16,13,4],
[49,16,13,5],
[49,16,13,6],
[49,16,13,7],
[49,16,13,8],
[49,16,13,10],
[49,16,13,11],
[49,16,13,12],
[49,16,14,0],
[49,16,14,1],
[49,16,14,2],
[49,16,14,3],
[49,16,14,4],
[49,16,14,5],
[49,16,14,6],
[49,16,14,7],
[49,16,14,8],
[49,16,14,10],
[49,16,14,11],
[49,16,14,12],
[49,17,3,0],
[49,17,3,1],
[49,17,3,2],
[49,17,4,0],
[49,17,4,1],
[49,17,4,2],
[49,17,4,3],
[49,17,5,0],
[49,17,5,1],
[49,17,5,2],
[49,17,5,4],
[49,17,6,0],
[49,17,6,1],
[49,17,6,2],
[49,17,6,4],
[49,17,7,0],
[49,17,7,1],
[49,17,7,2],
[49,17,7,3],
[49,17,7,4],
[49,17,7,5],
[49,17,7,6],
[49,17,8,0],
[49,17,8,1],
[49,17,8,2],
[49,17,8,4],
[49,17,8,7],
[49,17,10,0],
[49,17,10,1],
[49,17,10,2],
[49,17,10,3],
[49,17,10,4],
[49,17,10,5],
[49,17,10,6],
[49,17,10,7],
[49,17,10,8],
[49,17,11,0],
[49,17,11,1],
[49,17,11,2],
[49,17,11,3],
[49,17,11,4],
[49,17,11,5],
[49,17,11,6],
[49,17,11,7],
[49,17,11,8],
[49,17,11,10],
[49,17,12,0],
[49,17,12,1],
[49,17,12,2],
[49,17,12,3],
[49,17,12,4],
[49,17,12,5],
[49,17,12,6],
[49,17,12,7],
[49,17,12,8],
[49,17,12,10],
[49,17,13,0],
[49,17,13,1],
[49,17,13,2],
[49,17,13,3],
[49,17,13,4],
[49,17,13,5],
[49,17,13,6],
[49,17,13,7],
[49,17,13,8],
[49,17,13,10],
[49,17,13,11],
[49,17,13,12],
[49,17,14,0],
[49,17,14,1],
[49,17,14,2],
[49,17,14,3],
[49,17,14,4],
[49,17,14,5],
[49,17,14,6],
[49,17,14,7],
[49,17,14,8],
[49,17,14,10],
[49,17,14,11],
[49,17,14,12],
[49,17,16,0],
[49,17,16,1],
[49,17,16,2],
[49,17,16,3],
[49,17,16,4],
[49,17,16,5],
[49,17,16,6],
[49,17,16,7],
[49,17,16,8],
[49,17,16,10],
[49,17,16,11],
[49,17,16,12],
[49,17,16,13],
[49,17,16,14],
[49,19,3,0],
[49,19,3,1],
[49,19,3,2],
[49,19,4,0],
[49,19,4,1],
[49,19,4,2],
[49,19,4,3],
[49,19,5,0],
[49,19,5,1],
[49,19,5,2],
[49,19,5,4],
[49,19,6,0],
[49,19,6,1],
[49,19,6,2],
[49,19,6,4],
[49,19,7,0],
[49,19,7,1],
[49,19,7,2],
[49,19,7,3],
[49,19,7,4],
[49,19,7,5],
[49,19,7,6],
[49,19,8,0],
[49,19,8,1],
[49,19,8,2],
[49,19,8,4],
[49,19,8,7],
[49,19,10,0],
[49,19,10,1],
[49,19,10,2],
[49,19,10,3],
[49,19,10,4],
[49,19,10,5],
[49,19,10,6],
[49,19,10,7],
[49,19,10,8],
[49,19,11,0],
[49,19,11,1],
[49,19,11,2],
[49,19,11,3],
[49,19,11,4],
[49,19,11,5],
[49,19,11,6],
[49,19,11,7],
[49,19,11,8],
[49,19,11,10],
[49,19,12,0],
[49,19,12,1],
[49,19,12,2],
[49,19,12,3],
[49,19,12,4],
[49,19,12,5],
[49,19,12,6],
[49,19,12,7],
[49,19,12,8],
[49,19,12,10],
[49,19,16,0],
[49,19,16,1],
[49,19,16,2],
[49,19,16,3],
[49,19,16,4],
[49,19,16,5],
[49,19,16,6],
[49,19,16,7],
[49,19,16,8],
[49,19,16,10],
[49,19,16,11],
[49,19,16,12],
[49,19,17,0],
[49,19,17,1],
[49,19,17,2],
[49,19,17,3],
[49,19,17,4],
[49,19,17,5],
[49,19,17,6],
[49,19,17,7],
[49,19,17,8],
[49,19,17,10],
[49,19,17,11],
[49,19,17,12],
[49,19,17,16],
[49,20,3,0],
[49,20,3,1],
[49,20,3,2],
[49,20,4,0],
[49,20,4,1],
[49,20,4,2],
[49,20,4,3],
[49,20,5,0],
[49,20,5,1],
[49,20,5,2],
[49,20,5,4],
[49,20,6,0],
[49,20,6,1],
[49,20,6,2],
[49,20,6,4],
[49,20,7,0],
[49,20,7,1],
[49,20,7,2],
[49,20,7,3],
[49,20,7,4],
[49,20,7,5],
[49,20,7,6],
[49,20,8,0],
[49,20,8,1],
[49,20,8,2],
[49,20,8,4],
[49,20,8,7],
[49,20,11,0],
[49,20,11,1],
[49,20,11,2],
[49,20,11,3],
[49,20,11,4],
[49,20,11,5],
[49,20,11,6],
[49,20,11,7],
[49,20,11,8],
[49,20,12,0],
[49,20,12,1],
[49,20,12,2],
[49,20,12,3],
[49,20,12,4],
[49,20,12,5],
[49,20,12,6],
[49,20,12,7],
[49,20,12,8],
[49,20,13,0],
[49,20,13,1],
[49,20,13,2],
[49,20,13,3],
[49,20,13,4],
[49,20,13,5],
[49,20,13,6],
[49,20,13,7],
[49,20,13,8],
[49,20,13,11],
[49,20,13,12],
[49,20,14,0],
[49,20,14,1],
[49,20,14,2],
[49,20,14,3],
[49,20,14,4],
[49,20,14,5],
[49,20,14,6],
[49,20,14,7],
[49,20,14,8],
[49,20,14,11],
[49,20,14,12],
[49,20,16,0],
[49,20,16,1],
[49,20,16,2],
[49,20,16,3],
[49,20,16,4],
[49,20,16,5],
[49,20,16,6],
[49,20,16,7],
[49,20,16,8],
[49,20,16,11],
[49,20,16,12],
[49,20,16,13],
[49,20,16,14],
[49,20,17,0],
[49,20,17,1],
[49,20,17,2],
[49,20,17,3],
[49,20,17,4],
[49,20,17,5],
[49,20,17,6],
[49,20,17,7],
[49,20,17,8],
[49,20,17,11],
[49,20,17,12],
[49,20,17,13],
[49,20,17,14],
[49,20,17,16],
[49,20,19,0],
[49,20,19,1],
[49,20,19,2],
[49,20,19,3],
[49,20,19,4],
[49,20,19,5],
[49,20,19,6],
[49,20,19,7],
[49,20,19,8],
[49,20,19,11],
[49,20,19,12],
[49,20,19,16],
[49,20,19,17],
[49,21,4,0],
[49,21,4,1],
[49,21,4,2],
[49,21,7,0],
[49,21,7,1],
[49,21,7,2],
[49,21,7,4],
[49,21,10,0],
[49,21,10,1],
[49,21,10,2],
[49,21,10,4],
[49,21,10,7],
[49,21,11,0],
[49,21,11,1],
[49,21,11,2],
[49,21,11,4],
[49,21,11,7],
[49,21,11,10],
[49,21,12,0],
[49,21,12,1],
[49,21,12,2],
[49,21,12,4],
[49,21,12,7],
[49,21,12,10],
[49,21,13,0],
[49,21,13,1],
[49,21,13,2],
[49,21,13,4],
[49,21,13,7],
[49,21,13,10],
[49,21,13,11],
[49,21,13,12],
[49,21,14,0],
[49,21,14,1],
[49,21,14,2],
[49,21,14,4],
[49,21,14,7],
[49,21,14,10],
[49,21,14,11],
[49,21,14,12],
[49,21,16,0],
[49,21,16,1],
[49,21,16,2],
[49,21,16,4],
[49,21,16,7],
[49,21,16,10],
[49,21,16,11],
[49,21,16,12],
[49,21,16,13],
[49,21,16,14],
[49,21,17,0],
[49,21,17,1],
[49,21,17,2],
[49,21,17,4],
[49,21,17,7],
[49,21,17,10],
[49,21,17,11],
[49,21,17,12],
[49,21,17,13],
[49,21,17,14],
[49,21,17,16],
[49,21,19,0],
[49,21,19,1],
[49,21,19,2],
[49,21,19,4],
[49,21,19,7],
[49,21,19,10],
[49,21,19,11],
[49,21,19,12],
[49,21,19,16],
[49,21,19,17],
[49,21,20,0],
[49,21,20,1],
[49,21,20,2],
[49,21,20,4],
[49,21,20,7],
[49,21,20,11],
[49,21,20,12],
[49,21,20,13],
[49,21,20,14],
[49,21,20,16],
[49,21,20,17],
[49,21,20,19],
[49,22,3,0],
[49,22,3,1],
[49,22,3,2],
[49,22,4,0],
[49,22,4,1],
[49,22,4,2],
[49,22,4,3],
[49,22,5,0],
[49,22,5,1],
[49,22,5,2],
[49,22,5,4],
[49,22,6,0],
[49,22,6,1],
[49,22,6,2],
[49,22,6,4],
[49,22,7,0],
[49,22,7,1],
[49,22,7,2],
[49,22,7,3],
[49,22,7,4],
[49,22,7,5],
[49,22,7,6],
[49,22,8,0],
[49,22,8,1],
[49,22,8,2],
[49,22,8,4],
[49,22,8,7],
[49,22,11,0],
[49,22,11,1],
[49,22,11,2],
[49,22,11,3],
[49,22,11,4],
[49,22,11,5],
[49,22,11,6],
[49,22,11,7],
[49,22,11,8],
[49,22,12,0],
[49,22,12,1],
[49,22,12,2],
[49,22,12,3],
[49,22,12,4],
[49,22,12,5],
[49,22,12,6],
[49,22,12,7],
[49,22,12,8],
[49,22,13,0],
[49,22,13,1],
[49,22,13,2],
[49,22,13,3],
[49,22,13,4],
[49,22,13,5],
[49,22,13,6],
[49,22,13,7],
[49,22,13,8],
[49,22,13,11],
[49,22,13,12],
[49,22,14,0],
[49,22,14,1],
[49,22,14,2],
[49,22,14,3],
[49,22,14,4],
[49,22,14,5],
[49,22,14,6],
[49,22,14,7],
[49,22,14,8],
[49,22,14,11],
[49,22,14,12],
[49,22,16,0],
[49,22,16,1],
[49,22,16,2],
[49,22,16,3],
[49,22,16,4],
[49,22,16,5],
[49,22,16,6],
[49,22,16,7],
[49,22,16,8],
[49,22,16,11],
[49,22,16,12],
[49,22,16,13],
[49,22,16,14],
[49,22,17,0],
[49,22,17,1],
[49,22,17,2],
[49,22,17,3],
[49,22,17,4],
[49,22,17,5],
[49,22,17,6],
[49,22,17,7],
[49,22,17,8],
[49,22,17,11],
[49,22,17,12],
[49,22,17,13],
[49,22,17,14],
[49,22,17,16],
[49,22,19,0],
[49,22,19,1],
[49,22,19,2],
[49,22,19,3],
[49,22,19,4],
[49,22,19,5],
[49,22,19,6],
[49,22,19,7],
[49,22,19,8],
[49,22,19,11],
[49,22,19,12],
[49,22,19,16],
[49,22,19,17],
[49,22,21,0],
[49,22,21,1],
[49,22,21,2],
[49,22,21,4],
[49,22,21,7],
[49,22,21,11],
[49,22,21,12],
[49,22,21,13],
[49,22,21,14],
[49,22,21,16],
[49,22,21,17],
[49,22,21,19],
[49,23,3,0],
[49,23,3,1],
[49,23,3,2],
[49,23,4,0],
[49,23,4,1],
[49,23,4,2],
[49,23,4,3],
[49,23,5,0],
[49,23,5,1],
[49,23,5,2],
[49,23,5,4],
[49,23,6,0],
[49,23,6,1],
[49,23,6,2],
[49,23,6,4],
[49,23,7,0],
[49,23,7,1],
[49,23,7,2],
[49,23,7,3],
[49,23,7,4],
[49,23,7,5],
[49,23,7,6],
[49,23,8,0],
[49,23,8,1],
[49,23,8,2],
[49,23,8,4],
[49,23,8,7],
[49,23,10,0],
[49,23,10,1],
[49,23,10,2],
[49,23,10,3],
[49,23,10,4],
[49,23,10,5],
[49,23,10,6],
[49,23,10,7],
[49,23,10,8],
[49,23,11,0],
[49,23,11,1],
[49,23,11,2],
[49,23,11,3],
[49,23,11,4],
[49,23,11,5],
[49,23,11,6],
[49,23,11,7],
[49,23,11,8],
[49,23,11,10],
[49,23,12,0],
[49,23,12,1],
[49,23,12,2],
[49,23,12,3],
[49,23,12,4],
[49,23,12,5],
[49,23,12,6],
[49,23,12,7],
[49,23,12,8],
[49,23,12,10],
[49,23,13,0],
[49,23,13,1],
[49,23,13,2],
[49,23,13,3],
[49,23,13,4],
[49,23,13,5],
[49,23,13,6],
[49,23,13,7],
[49,23,13,8],
[49,23,13,10],
[49,23,13,11],
[49,23,13,12],
[49,23,14,0],
[49,23,14,1],
[49,23,14,2],
[49,23,14,3],
[49,23,14,4],
[49,23,14,5],
[49,23,14,6],
[49,23,14,7],
[49,23,14,8],
[49,23,14,10],
[49,23,14,11],
[49,23,14,12],
[49,23,16,0],
[49,23,16,1],
[49,23,16,2],
[49,23,16,3],
[49,23,16,4],
[49,23,16,5],
[49,23,16,6],
[49,23,16,7],
[49,23,16,8],
[49,23,16,10],
[49,23,16,11],
[49,23,16,12],
[49,23,16,13],
[49,23,16,14],
[49,23,17,0],
[49,23,17,1],
[49,23,17,2],
[49,23,17,3],
[49,23,17,4],
[49,23,17,5],
[49,23,17,6],
[49,23,17,7],
[49,23,17,8],
[49,23,17,10],
[49,23,17,11],
[49,23,17,12],
[49,23,17,13],
[49,23,17,14],
[49,23,17,16],
[49,23,19,0],
[49,23,19,1],
[49,23,19,2],
[49,23,19,3],
[49,23,19,4],
[49,23,19,5],
[49,23,19,6],
[49,23,19,7],
[49,23,19,8],
[49,23,19,10],
[49,23,19,11],
[49,23,19,12],
[49,23,19,16],
[49,23,19,17],
[49,23,20,0],
[49,23,20,1],
[49,23,20,2],
[49,23,20,3],
[49,23,20,4],
[49,23,20,5],
[49,23,20,6],
[49,23,20,7],
[49,23,20,8],
[49,23,20,11],
[49,23,20,12],
[49,23,20,13],
[49,23,20,14],
[49,23,20,16],
[49,23,20,17],
[49,23,20,19],
[49,23,21,0],
[49,23,21,1],
[49,23,21,2],
[49,23,21,4],
[49,23,21,7],
[49,23,21,10],
[49,23,21,11],
[49,23,21,12],
[49,23,21,13],
[49,23,21,14],
[49,23,21,16],
[49,23,21,17],
[49,23,21,19],
[49,23,21,20],
[49,23,22,0],
[49,23,22,1],
[49,23,22,2],
[49,23,22,3],
[49,23,22,4],
[49,23,22,5],
[49,23,22,6],
[49,23,22,7],
[49,23,22,8],
[49,23,22,11],
[49,23,22,12],
[49,23,22,13],
[49,23,22,14],
[49,23,22,16],
[49,23,22,17],
[49,23,22,19],
[49,23,22,21],
[49,24,3,0],
[49,24,3,1],
[49,24,3,2],
[49,24,4,0],
[49,24,4,1],
[49,24,4,2],
[49,24,4,3],
[49,24,5,0],
[49,24,5,1],
[49,24,5,2],
[49,24,5,4],
[49,24,6,0],
[49,24,6,1],
[49,24,6,2],
[49,24,6,4],
[49,24,7,0],
[49,24,7,1],
[49,24,7,2],
[49,24,7,3],
[49,24,7,4],
[49,24,7,5],
[49,24,7,6],
[49,24,8,0],
[49,24,8,1],
[49,24,8,2],
[49,24,8,4],
[49,24,8,7],
[49,24,10,0],
[49,24,10,1],
[49,24,10,2],
[49,24,10,3],
[49,24,10,4],
[49,24,10,5],
[49,24,10,6],
[49,24,10,7],
[49,24,10,8],
[49,24,11,0],
[49,24,11,1],
[49,24,11,2],
[49,24,11,3],
[49,24,11,4],
[49,24,11,5],
[49,24,11,6],
[49,24,11,7],
[49,24,11,8],
[49,24,11,10],
[49,24,12,0],
[49,24,12,1],
[49,24,12,2],
[49,24,12,3],
[49,24,12,4],
[49,24,12,5],
[49,24,12,6],
[49,24,12,7],
[49,24,12,8],
[49,24,12,10],
[49,24,13,0],
[49,24,13,1],
[49,24,13,2],
[49,24,13,3],
[49,24,13,4],
[49,24,13,5],
[49,24,13,6],
[49,24,13,7],
[49,24,13,8],
[49,24,13,10],
[49,24,13,11],
[49,24,13,12],
[49,24,14,0],
[49,24,14,1],
[49,24,14,2],
[49,24,14,3],
[49,24,14,4],
[49,24,14,5],
[49,24,14,6],
[49,24,14,7],
[49,24,14,8],
[49,24,14,10],
[49,24,14,11],
[49,24,14,12],
[49,24,17,0],
[49,24,17,1],
[49,24,17,2],
[49,24,17,3],
[49,24,17,4],
[49,24,17,5],
[49,24,17,6],
[49,24,17,7],
[49,24,17,8],
[49,24,17,10],
[49,24,17,11],
[49,24,17,12],
[49,24,17,13],
[49,24,17,14],
[49,24,19,0],
[49,24,19,1],
[49,24,19,2],
[49,24,19,3],
[49,24,19,4],
[49,24,19,5],
[49,24,19,6],
[49,24,19,7],
[49,24,19,8],
[49,24,19,10],
[49,24,19,11],
[49,24,19,12],
[49,24,19,17],
[49,24,20,0],
[49,24,20,1],
[49,24,20,2],
[49,24,20,3],
[49,24,20,4],
[49,24,20,5],
[49,24,20,6],
[49,24,20,7],
[49,24,20,8],
[49,24,20,11],
[49,24,20,12],
[49,24,20,13],
[49,24,20,14],
[49,24,20,17],
[49,24,20,19],
[49,24,21,0],
[49,24,21,1],
[49,24,21,2],
[49,24,21,4],
[49,24,21,7],
[49,24,21,10],
[49,24,21,11],
[49,24,21,12],
[49,24,21,13],
[49,24,21,14],
[49,24,21,17],
[49,24,21,19],
[49,24,21,20],
[49,24,22,0],
[49,24,22,1],
[49,24,22,2],
[49,24,22,3],
[49,24,22,4],
[49,24,22,5],
[49,24,22,6],
[49,24,22,7],
[49,24,22,8],
[49,24,22,11],
[49,24,22,12],
[49,24,22,13],
[49,24,22,14],
[49,24,22,17],
[49,24,22,19],
[49,24,22,21],
[49,24,23,0],
[49,24,23,1],
[49,24,23,2],
[49,24,23,3],
[49,24,23,4],
[49,24,23,5],
[49,24,23,6],
[49,24,23,7],
[49,24,23,8],
[49,24,23,10],
[49,24,23,11],
[49,24,23,12],
[49,24,23,13],
[49,24,23,14],
[49,24,23,17],
[49,24,23,19],
[49,24,23,20],
[49,24,23,21],
[49,24,23,22],
[49,25,3,0],
[49,25,3,1],
[49,25,3,2],
[49,25,4,0],
[49,25,4,1],
[49,25,4,2],
[49,25,4,3],
[49,25,5,0],
[49,25,5,1],
[49,25,5,2],
[49,25,5,4],
[49,25,6,0],
[49,25,6,1],
[49,25,6,2],
[49,25,6,4],
[49,25,8,0],
[49,25,8,1],
[49,25,8,2],
[49,25,8,4],
[49,25,10,0],
[49,25,10,1],
[49,25,10,2],
[49,25,10,3],
[49,25,10,4],
[49,25,10,5],
[49,25,10,6],
[49,25,10,8],
[49,25,11,0],
[49,25,11,1],
[49,25,11,2],
[49,25,11,3],
[49,25,11,4],
[49,25,11,5],
[49,25,11,6],
[49,25,11,8],
[49,25,11,10],
[49,25,12,0],
[49,25,12,1],
[49,25,12,2],
[49,25,12,3],
[49,25,12,4],
[49,25,12,5],
[49,25,12,6],
[49,25,12,8],
[49,25,12,10],
[49,25,13,0],
[49,25,13,1],
[49,25,13,2],
[49,25,13,3],
[49,25,13,4],
[49,25,13,5],
[49,25,13,6],
[49,25,13,8],
[49,25,13,10],
[49,25,13,11],
[49,25,13,12],
[49,25,14,0],
[49,25,14,1],
[49,25,14,2],
[49,25,14,3],
[49,25,14,4],
[49,25,14,5],
[49,25,14,6],
[49,25,14,8],
[49,25,14,10],
[49,25,14,11],
[49,25,14,12],
[49,25,16,0],
[49,25,16,1],
[49,25,16,2],
[49,25,16,3],
[49,25,16,4],
[49,25,16,5],
[49,25,16,6],
[49,25,16,8],
[49,25,16,10],
[49,25,16,11],
[49,25,16,12],
[49,25,16,13],
[49,25,16,14],
[49,25,17,0],
[49,25,17,1],
[49,25,17,2],
[49,25,17,3],
[49,25,17,4],
[49,25,17,5],
[49,25,17,6],
[49,25,17,8],
[49,25,17,10],
[49,25,17,11],
[49,25,17,12],
[49,25,17,13],
[49,25,17,14],
[49,25,17,16],
[49,25,19,0],
[49,25,19,1],
[49,25,19,2],
[49,25,19,3],
[49,25,19,4],
[49,25,19,5],
[49,25,19,6],
[49,25,19,8],
[49,25,19,10],
[49,25,19,11],
[49,25,19,12],
[49,25,19,16],
[49,25,19,17],
[49,25,20,0],
[49,25,20,1],
[49,25,20,2],
[49,25,20,3],
[49,25,20,4],
[49,25,20,5],
[49,25,20,6],
[49,25,20,8],
[49,25,20,11],
[49,25,20,12],
[49,25,20,13],
[49,25,20,14],
[49,25,20,16],
[49,25,20,17],
[49,25,20,19],
[49,25,21,0],
[49,25,21,1],
[49,25,21,2],
[49,25,21,4],
[49,25,21,10],
[49,25,21,11],
[49,25,21,12],
[49,25,21,13],
[49,25,21,14],
[49,25,21,16],
[49,25,21,17],
[49,25,21,19],
[49,25,21,20],
[49,25,22,0],
[49,25,22,1],
[49,25,22,2],
[49,25,22,3],
[49,25,22,4],
[49,25,22,5],
[49,25,22,6],
[49,25,22,8],
[49,25,22,11],
[49,25,22,12],
[49,25,22,13],
[49,25,22,14],
[49,25,22,16],
[49,25,22,17],
[49,25,22,19],
[49,25,22,21],
[49,25,23,0],
[49,25,23,1],
[49,25,23,2],
[49,25,23,3],
[49,25,23,4],
[49,25,23,5],
[49,25,23,6],
[49,25,23,8],
[49,25,23,10],
[49,25,23,11],
[49,25,23,12],
[49,25,23,13],
[49,25,23,14],
[49,25,23,16],
[49,25,23,17],
[49,25,23,19],
[49,25,23,20],
[49,25,23,21],
[49,25,23,22],
[49,25,24,0],
[49,25,24,1],
[49,25,24,2],
[49,25,24,3],
[49,25,24,4],
[49,25,24,5],
[49,25,24,6],
[49,25,24,8],
[49,25,24,10],
[49,25,24,11],
[49,25,24,12],
[49,25,24,13],
[49,25,24,14],
[49,25,24,17],
[49,25,24,19],
[49,25,24,20],
[49,25,24,21],
[49,25,24,22],
[49,25,24,23],
[49,27,3,0],
[49,27,3,1],
[49,27,3,2],
[49,27,4,0],
[49,27,4,1],
[49,27,4,2],
[49,27,4,3],
[49,27,5,0],
[49,27,5,1],
[49,27,5,2],
[49,27,5,4],
[49,27,6,0],
[49,27,6,1],
[49,27,6,2],
[49,27,6,4],
[49,27,7,0],
[49,27,7,1],
[49,27,7,2],
[49,27,7,3],
[49,27,7,4],
[49,27,7,5],
[49,27,7,6],
[49,27,8,0],
[49,27,8,1],
[49,27,8,2],
[49,27,8,4],
[49,27,8,7],
[49,27,11,0],
[49,27,11,1],
[49,27,11,2],
[49,27,11,3],
[49,27,11,4],
[49,27,11,5],
[49,27,11,6],
[49,27,11,7],
[49,27,11,8],
[49,27,12,0],
[49,27,12,1],
[49,27,12,2],
[49,27,12,3],
[49,27,12,4],
[49,27,12,5],
[49,27,12,6],
[49,27,12,7],
[49,27,12,8],
[49,27,13,0],
[49,27,13,1],
[49,27,13,2],
[49,27,13,3],
[49,27,13,4],
[49,27,13,5],
[49,27,13,6],
[49,27,13,7],
[49,27,13,8],
[49,27,13,11],
[49,27,13,12],
[49,27,14,0],
[49,27,14,1],
[49,27,14,2],
[49,27,14,3],
[49,27,14,4],
[49,27,14,5],
[49,27,14,6],
[49,27,14,7],
[49,27,14,8],
[49,27,14,11],
[49,27,14,12],
[49,27,16,0],
[49,27,16,1],
[49,27,16,2],
[49,27,16,3],
[49,27,16,4],
[49,27,16,5],
[49,27,16,6],
[49,27,16,7],
[49,27,16,8],
[49,27,16,11],
[49,27,16,12],
[49,27,16,13],
[49,27,16,14],
[49,27,17,0],
[49,27,17,1],
[49,27,17,2],
[49,27,17,3],
[49,27,17,4],
[49,27,17,5],
[49,27,17,6],
[49,27,17,7],
[49,27,17,8],
[49,27,17,11],
[49,27,17,12],
[49,27,17,13],
[49,27,17,14],
[49,27,17,16],
[49,27,19,0],
[49,27,19,1],
[49,27,19,2],
[49,27,19,3],
[49,27,19,4],
[49,27,19,5],
[49,27,19,6],
[49,27,19,7],
[49,27,19,8],
[49,27,19,11],
[49,27,19,12],
[49,27,19,16],
[49,27,19,17],
[49,27,21,0],
[49,27,21,1],
[49,27,21,2],
[49,27,21,4],
[49,27,21,7],
[49,27,21,11],
[49,27,21,12],
[49,27,21,13],
[49,27,21,14],
[49,27,21,16],
[49,27,21,17],
[49,27,21,19],
[49,27,23,0],
[49,27,23,1],
[49,27,23,2],
[49,27,23,3],
[49,27,23,4],
[49,27,23,5],
[49,27,23,6],
[49,27,23,7],
[49,27,23,8],
[49,27,23,11],
[49,27,23,12],
[49,27,23,13],
[49,27,23,14],
[49,27,23,16],
[49,27,23,17],
[49,27,23,19],
[49,27,23,21],
[49,27,24,0],
[49,27,24,1],
[49,27,24,2],
[49,27,24,3],
[49,27,24,4],
[49,27,24,5],
[49,27,24,6],
[49,27,24,7],
[49,27,24,8],
[49,27,24,11],
[49,27,24,12],
[49,27,24,13],
[49,27,24,14],
[49,27,24,17],
[49,27,24,19],
[49,27,24,21],
[49,27,24,23],
[49,27,25,0],
[49,27,25,1],
[49,27,25,2],
[49,27,25,3],
[49,27,25,4],
[49,27,25,5],
[49,27,25,6],
[49,27,25,8],
[49,27,25,11],
[49,27,25,12],
[49,27,25,13],
[49,27,25,14],
[49,27,25,16],
[49,27,25,17],
[49,27,25,19],
[49,27,25,21],
[49,27,25,23],
[49,27,25,24],
[49,28,3,0],
[49,28,3,1],
[49,28,3,2],
[49,28,4,0],
[49,28,4,1],
[49,28,4,2],
[49,28,4,3],
[49,28,5,0],
[49,28,5,1],
[49,28,5,2],
[49,28,5,4],
[49,28,6,0],
[49,28,6,1],
[49,28,6,2],
[49,28,6,4],
[49,28,8,0],
[49,28,8,1],
[49,28,8,2],
[49,28,8,4],
[49,28,10,0],
[49,28,10,1],
[49,28,10,2],
[49,28,10,3],
[49,28,10,4],
[49,28,10,5],
[49,28,10,6],
[49,28,10,8],
[49,28,11,0],
[49,28,11,1],
[49,28,11,2],
[49,28,11,3],
[49,28,11,4],
[49,28,11,5],
[49,28,11,6],
[49,28,11,8],
[49,28,11,10],
[49,28,12,0],
[49,28,12,1],
[49,28,12,2],
[49,28,12,3],
[49,28,12,4],
[49,28,12,5],
[49,28,12,6],
[49,28,12,8],
[49,28,12,10],
[49,28,13,0],
[49,28,13,1],
[49,28,13,2],
[49,28,13,3],
[49,28,13,4],
[49,28,13,5],
[49,28,13,6],
[49,28,13,8],
[49,28,13,10],
[49,28,13,11],
[49,28,13,12],
[49,28,14,0],
[49,28,14,1],
[49,28,14,2],
[49,28,14,3],
[49,28,14,4],
[49,28,14,5],
[49,28,14,6],
[49,28,14,8],
[49,28,14,10],
[49,28,14,11],
[49,28,14,12],
[49,28,16,0],
[49,28,16,1],
[49,28,16,2],
[49,28,16,3],
[49,28,16,4],
[49,28,16,5],
[49,28,16,6],
[49,28,16,8],
[49,28,16,10],
[49,28,16,11],
[49,28,16,12],
[49,28,16,13],
[49,28,16,14],
[49,28,17,0],
[49,28,17,1],
[49,28,17,2],
[49,28,17,3],
[49,28,17,4],
[49,28,17,5],
[49,28,17,6],
[49,28,17,8],
[49,28,17,10],
[49,28,17,11],
[49,28,17,12],
[49,28,17,13],
[49,28,17,14],
[49,28,17,16],
[49,28,19,0],
[49,28,19,1],
[49,28,19,2],
[49,28,19,3],
[49,28,19,4],
[49,28,19,5],
[49,28,19,6],
[49,28,19,8],
[49,28,19,10],
[49,28,19,11],
[49,28,19,12],
[49,28,19,16],
[49,28,19,17],
[49,28,20,0],
[49,28,20,1],
[49,28,20,2],
[49,28,20,3],
[49,28,20,4],
[49,28,20,5],
[49,28,20,6],
[49,28,20,8],
[49,28,20,11],
[49,28,20,12],
[49,28,20,13],
[49,28,20,14],
[49,28,20,16],
[49,28,20,17],
[49,28,20,19],
[49,28,21,0],
[49,28,21,1],
[49,28,21,2],
[49,28,21,4],
[49,28,21,10],
[49,28,21,11],
[49,28,21,12],
[49,28,21,13],
[49,28,21,14],
[49,28,21,16],
[49,28,21,17],
[49,28,21,19],
[49,28,21,20],
[49,28,22,0],
[49,28,22,1],
[49,28,22,2],
[49,28,22,3],
[49,28,22,4],
[49,28,22,5],
[49,28,22,6],
[49,28,22,8],
[49,28,22,11],
[49,28,22,12],
[49,28,22,13],
[49,28,22,14],
[49,28,22,16],
[49,28,22,17],
[49,28,22,19],
[49,28,22,21],
[49,28,23,0],
[49,28,23,1],
[49,28,23,2],
[49,28,23,3],
[49,28,23,4],
[49,28,23,5],
[49,28,23,6],
[49,28,23,8],
[49,28,23,10],
[49,28,23,11],
[49,28,23,12],
[49,28,23,13],
[49,28,23,14],
[49,28,23,16],
[49,28,23,17],
[49,28,23,19],
[49,28,23,20],
[49,28,23,21],
[49,28,23,22],
[49,28,24,0],
[49,28,24,1],
[49,28,24,2],
[49,28,24,3],
[49,28,24,4],
[49,28,24,5],
[49,28,24,6],
[49,28,24,8],
[49,28,24,10],
[49,28,24,11],
[49,28,24,12],
[49,28,24,13],
[49,28,24,14],
[49,28,24,17],
[49,28,24,19],
[49,28,24,20],
[49,28,24,21],
[49,28,24,22],
[49,28,24,23],
[49,28,27,0],
[49,28,27,1],
[49,28,27,2],
[49,28,27,3],
[49,28,27,4],
[49,28,27,5],
[49,28,27,6],
[49,28,27,8],
[49,28,27,11],
[49,28,27,12],
[49,28,27,13],
[49,28,27,14],
[49,28,27,16],
[49,28,27,17],
[49,28,27,19],
[49,28,27,21],
[49,28,27,23],
[49,28,27,24],
[49,29,3,0],
[49,29,3,1],
[49,29,3,2],
[49,29,4,0],
[49,29,4,1],
[49,29,4,2],
[49,29,4,3],
[49,29,5,0],
[49,29,5,1],
[49,29,5,2],
[49,29,5,4],
[49,29,6,0],
[49,29,6,1],
[49,29,6,2],
[49,29,6,4],
[49,29,7,0],
[49,29,7,1],
[49,29,7,2],
[49,29,7,3],
[49,29,7,4],
[49,29,7,5],
[49,29,7,6],
[49,29,8,0],
[49,29,8,1],
[49,29,8,2],
[49,29,8,4],
[49,29,8,7],
[49,29,10,0],
[49,29,10,1],
[49,29,10,2],
[49,29,10,3],
[49,29,10,4],
[49,29,10,5],
[49,29,10,6],
[49,29,10,7],
[49,29,10,8],
[49,29,13,0],
[49,29,13,1],
[49,29,13,2],
[49,29,13,3],
[49,29,13,4],
[49,29,13,5],
[49,29,13,6],
[49,29,13,7],
[49,29,13,8],
[49,29,13,10],
[49,29,14,0],
[49,29,14,1],
[49,29,14,2],
[49,29,14,3],
[49,29,14,4],
[49,29,14,5],
[49,29,14,6],
[49,29,14,7],
[49,29,14,8],
[49,29,14,10],
[49,29,16,0],
[49,29,16,1],
[49,29,16,2],
[49,29,16,3],
[49,29,16,4],
[49,29,16,5],
[49,29,16,6],
[49,29,16,7],
[49,29,16,8],
[49,29,16,10],
[49,29,16,13],
[49,29,16,14],
[49,29,17,0],
[49,29,17,1],
[49,29,17,2],
[49,29,17,3],
[49,29,17,4],
[49,29,17,5],
[49,29,17,6],
[49,29,17,7],
[49,29,17,8],
[49,29,17,10],
[49,29,17,13],
[49,29,17,14],
[49,29,17,16],
[49,29,19,0],
[49,29,19,1],
[49,29,19,2],
[49,29,19,3],
[49,29,19,4],
[49,29,19,5],
[49,29,19,6],
[49,29,19,7],
[49,29,19,8],
[49,29,19,10],
[49,29,19,16],
[49,29,19,17],
[49,29,20,0],
[49,29,20,1],
[49,29,20,2],
[49,29,20,3],
[49,29,20,4],
[49,29,20,5],
[49,29,20,6],
[49,29,20,7],
[49,29,20,8],
[49,29,20,13],
[49,29,20,14],
[49,29,20,16],
[49,29,20,17],
[49,29,20,19],
[49,29,21,0],
[49,29,21,1],
[49,29,21,2],
[49,29,21,4],
[49,29,21,7],
[49,29,21,10],
[49,29,21,13],
[49,29,21,14],
[49,29,21,16],
[49,29,21,17],
[49,29,21,19],
[49,29,21,20],
[49,29,22,0],
[49,29,22,1],
[49,29,22,2],
[49,29,22,3],
[49,29,22,4],
[49,29,22,5],
[49,29,22,6],
[49,29,22,7],
[49,29,22,8],
[49,29,22,13],
[49,29,22,14],
[49,29,22,16],
[49,29,22,17],
[49,29,22,19],
[49,29,22,21],
[49,29,23,0],
[49,29,23,1],
[49,29,23,2],
[49,29,23,3],
[49,29,23,4],
[49,29,23,5],
[49,29,23,6],
[49,29,23,7],
[49,29,23,8],
[49,29,23,10],
[49,29,23,13],
[49,29,23,14],
[49,29,23,16],
[49,29,23,17],
[49,29,23,19],
[49,29,23,20],
[49,29,23,21],
[49,29,23,22],
[49,29,24,0],
[49,29,24,1],
[49,29,24,2],
[49,29,24,3],
[49,29,24,4],
[49,29,24,5],
[49,29,24,6],
[49,29,24,7],
[49,29,24,8],
[49,29,24,10],
[49,29,24,13],
[49,29,24,14],
[49,29,24,17],
[49,29,24,19],
[49,29,24,20],
[49,29,24,21],
[49,29,24,22],
[49,29,24,23],
[49,29,25,0],
[49,29,25,1],
[49,29,25,2],
[49,29,25,3],
[49,29,25,4],
[49,29,25,5],
[49,29,25,6],
[49,29,25,8],
[49,29,25,10],
[49,29,25,13],
[49,29,25,14],
[49,29,25,16],
[49,29,25,17],
[49,29,25,19],
[49,29,25,20],
[49,29,25,21],
[49,29,25,22],
[49,29,25,23],
[49,29,25,24],
[49,29,27,0],
[49,29,27,1],
[49,29,27,2],
[49,29,27,3],
[49,29,27,4],
[49,29,27,5],
[49,29,27,6],
[49,29,27,7],
[49,29,27,8],
[49,29,27,13],
[49,29,27,14],
[49,29,27,16],
[49,29,27,17],
[49,29,27,19],
[49,29,27,21],
[49,29,27,23],
[49,29,27,24],
[49,29,27,25],
[49,29,28,0],
[49,29,28,1],
[49,29,28,2],
[49,29,28,3],
[49,29,28,4],
[49,29,28,5],
[49,29,28,6],
[49,29,28,8],
[49,29,28,10],
[49,29,28,13],
[49,29,28,14],
[49,29,28,16],
[49,29,28,17],
[49,29,28,19],
[49,29,28,20],
[49,29,28,21],
[49,29,28,22],
[49,29,28,27],
[49,30,3,0],
[49,30,3,1],
[49,30,3,2],
[49,30,4,0],
[49,30,4,1],
[49,30,4,2],
[49,30,4,3],
[49,30,5,0],
[49,30,5,1],
[49,30,5,2],
[49,30,5,4],
[49,30,6,0],
[49,30,6,1],
[49,30,6,2],
[49,30,6,4],
[49,30,7,0],
[49,30,7,1],
[49,30,7,2],
[49,30,7,3],
[49,30,7,4],
[49,30,7,5],
[49,30,7,6],
[49,30,8,0],
[49,30,8,1],
[49,30,8,2],
[49,30,8,4],
[49,30,8,7],
[49,30,10,0],
[49,30,10,1],
[49,30,10,2],
[49,30,10,3],
[49,30,10,4],
[49,30,10,5],
[49,30,10,6],
[49,30,10,7],
[49,30,10,8],
[49,30,11,0],
[49,30,11,1],
[49,30,11,2],
[49,30,11,3],
[49,30,11,4],
[49,30,11,5],
[49,30,11,6],
[49,30,11,7],
[49,30,11,8],
[49,30,11,10],
[49,30,12,0],
[49,30,12,1],
[49,30,12,2],
[49,30,12,3],
[49,30,12,4],
[49,30,12,5],
[49,30,12,6],
[49,30,12,7],
[49,30,12,8],
[49,30,12,10],
[49,30,13,0],
[49,30,13,1],
[49,30,13,2],
[49,30,13,3],
[49,30,13,4],
[49,30,13,5],
[49,30,13,6],
[49,30,13,7],
[49,30,13,8],
[49,30,13,10],
[49,30,13,11],
[49,30,13,12],
[49,30,14,0],
[49,30,14,1],
[49,30,14,2],
[49,30,14,3],
[49,30,14,4],
[49,30,14,5],
[49,30,14,6],
[49,30,14,7],
[49,30,14,8],
[49,30,14,10],
[49,30,14,11],
[49,30,14,12],
[49,30,16,0],
[49,30,16,1],
[49,30,16,2],
[49,30,16,3],
[49,30,16,4],
[49,30,16,5],
[49,30,16,6],
[49,30,16,7],
[49,30,16,8],
[49,30,16,10],
[49,30,16,11],
[49,30,16,12],
[49,30,16,13],
[49,30,16,14],
[49,30,17,0],
[49,30,17,1],
[49,30,17,2],
[49,30,17,3],
[49,30,17,4],
[49,30,17,5],
[49,30,17,6],
[49,30,17,7],
[49,30,17,8],
[49,30,17,10],
[49,30,17,11],
[49,30,17,12],
[49,30,17,13],
[49,30,17,14],
[49,30,17,16],
[49,30,19,0],
[49,30,19,1],
[49,30,19,2],
[49,30,19,3],
[49,30,19,4],
[49,30,19,5],
[49,30,19,6],
[49,30,19,7],
[49,30,19,8],
[49,30,19,10],
[49,30,19,11],
[49,30,19,12],
[49,30,19,16],
[49,30,19,17],
[49,30,20,0],
[49,30,20,1],
[49,30,20,2],
[49,30,20,3],
[49,30,20,4],
[49,30,20,5],
[49,30,20,6],
[49,30,20,7],
[49,30,20,8],
[49,30,20,11],
[49,30,20,12],
[49,30,20,13],
[49,30,20,14],
[49,30,20,16],
[49,30,20,17],
[49,30,20,19],
[49,30,21,0],
[49,30,21,1],
[49,30,21,2],
[49,30,21,4],
[49,30,21,7],
[49,30,21,10],
[49,30,21,11],
[49,30,21,12],
[49,30,21,13],
[49,30,21,14],
[49,30,21,16],
[49,30,21,17],
[49,30,21,19],
[49,30,21,20],
[49,30,22,0],
[49,30,22,1],
[49,30,22,2],
[49,30,22,3],
[49,30,22,4],
[49,30,22,5],
[49,30,22,6],
[49,30,22,7],
[49,30,22,8],
[49,30,22,11],
[49,30,22,12],
[49,30,22,13],
[49,30,22,14],
[49,30,22,16],
[49,30,22,17],
[49,30,22,19],
[49,30,22,21],
[49,30,24,0],
[49,30,24,1],
[49,30,24,2],
[49,30,24,3],
[49,30,24,4],
[49,30,24,5],
[49,30,24,6],
[49,30,24,7],
[49,30,24,8],
[49,30,24,10],
[49,30,24,11],
[49,30,24,12],
[49,30,24,13],
[49,30,24,14],
[49,30,24,17],
[49,30,24,19],
[49,30,24,20],
[49,30,24,21],
[49,30,24,22],
[49,30,25,0],
[49,30,25,1],
[49,30,25,2],
[49,30,25,3],
[49,30,25,4],
[49,30,25,5],
[49,30,25,6],
[49,30,25,8],
[49,30,25,10],
[49,30,25,11],
[49,30,25,12],
[49,30,25,13],
[49,30,25,14],
[49,30,25,16],
[49,30,25,17],
[49,30,25,19],
[49,30,25,20],
[49,30,25,21],
[49,30,25,22],
[49,30,25,24],
[49,30,27,0],
[49,30,27,1],
[49,30,27,2],
[49,30,27,3],
[49,30,27,4],
[49,30,27,5],
[49,30,27,6],
[49,30,27,7],
[49,30,27,8],
[49,30,27,11],
[49,30,27,12],
[49,30,27,13],
[49,30,27,14],
[49,30,27,16],
[49,30,27,17],
[49,30,27,19],
[49,30,27,21],
[49,30,27,25],
[49,30,28,0],
[49,30,28,1],
[49,30,28,2],
[49,30,28,3],
[49,30,28,4],
[49,30,28,5],
[49,30,28,6],
[49,30,28,8],
[49,30,28,10],
[49,30,28,11],
[49,30,28,12],
[49,30,28,13],
[49,30,28,14],
[49,30,28,16],
[49,30,28,17],
[49,30,28,19],
[49,30,28,20],
[49,30,28,21],
[49,30,28,24],
[49,30,28,27],
[49,30,29,0],
[49,30,29,1],
[49,30,29,2],
[49,30,29,3],
[49,30,29,4],
[49,30,29,5],
[49,30,29,6],
[49,30,29,7],
[49,30,29,8],
[49,30,29,10],
[49,30,29,13],
[49,30,29,14],
[49,30,29,16],
[49,30,29,17],
[49,30,29,19],
[49,30,29,20],
[49,30,29,22],
[49,30,29,24],
[49,30,29,25],
[49,30,29,27],
[49,30,29,28],
[49,31,3,0],
[49,31,3,1],
[49,31,3,2],
[49,31,4,0],
[49,31,4,1],
[49,31,4,2],
[49,31,4,3],
[49,31,5,0],
[49,31,5,1],
[49,31,5,2],
[49,31,5,4],
[49,31,6,0],
[49,31,6,1],
[49,31,6,2],
[49,31,6,4],
[49,31,7,0],
[49,31,7,1],
[49,31,7,2],
[49,31,7,3],
[49,31,7,4],
[49,31,7,5],
[49,31,7,6],
[49,31,8,0],
[49,31,8,1],
[49,31,8,2],
[49,31,8,4],
[49,31,8,7],
[49,31,10,0],
[49,31,10,1],
[49,31,10,2],
[49,31,10,3],
[49,31,10,4],
[49,31,10,5],
[49,31,10,6],
[49,31,10,7],
[49,31,10,8],
[49,31,11,0],
[49,31,11,1],
[49,31,11,2],
[49,31,11,3],
[49,31,11,4],
[49,31,11,5],
[49,31,11,6],
[49,31,11,7],
[49,31,11,8],
[49,31,11,10],
[49,31,12,0],
[49,31,12,1],
[49,31,12,2],
[49,31,12,3],
[49,31,12,4],
[49,31,12,5],
[49,31,12,6],
[49,31,12,7],
[49,31,12,8],
[49,31,12,10],
[49,31,13,0],
[49,31,13,1],
[49,31,13,2],
[49,31,13,3],
[49,31,13,4],
[49,31,13,5],
[49,31,13,6],
[49,31,13,7],
[49,31,13,8],
[49,31,13,10],
[49,31,13,11],
[49,31,13,12],
[49,31,14,0],
[49,31,14,1],
[49,31,14,2],
[49,31,14,3],
[49,31,14,4],
[49,31,14,5],
[49,31,14,6],
[49,31,14,7],
[49,31,14,8],
[49,31,14,10],
[49,31,14,11],
[49,31,14,12],
[49,31,16,0],
[49,31,16,1],
[49,31,16,2],
[49,31,16,3],
[49,31,16,4],
[49,31,16,5],
[49,31,16,6],
[49,31,16,7],
[49,31,16,8],
[49,31,16,10],
[49,31,16,11],
[49,31,16,12],
[49,31,16,13],
[49,31,16,14],
[49,31,17,0],
[49,31,17,1],
[49,31,17,2],
[49,31,17,3],
[49,31,17,4],
[49,31,17,5],
[49,31,17,6],
[49,31,17,7],
[49,31,17,8],
[49,31,17,10],
[49,31,17,11],
[49,31,17,12],
[49,31,17,13],
[49,31,17,14],
[49,31,17,16],
[49,31,19,0],
[49,31,19,1],
[49,31,19,2],
[49,31,19,3],
[49,31,19,4],
[49,31,19,5],
[49,31,19,6],
[49,31,19,7],
[49,31,19,8],
[49,31,19,10],
[49,31,19,11],
[49,31,19,12],
[49,31,19,16],
[49,31,19,17],
[49,31,20,0],
[49,31,20,1],
[49,31,20,2],
[49,31,20,3],
[49,31,20,4],
[49,31,20,5],
[49,31,20,6],
[49,31,20,7],
[49,31,20,8],
[49,31,20,11],
[49,31,20,12],
[49,31,20,13],
[49,31,20,14],
[49,31,20,16],
[49,31,20,17],
[49,31,20,19],
[49,31,21,0],
[49,31,21,1],
[49,31,21,2],
[49,31,21,4],
[49,31,21,7],
[49,31,21,10],
[49,31,21,11],
[49,31,21,12],
[49,31,21,13],
[49,31,21,14],
[49,31,21,16],
[49,31,21,17],
[49,31,21,19],
[49,31,21,20],
[49,31,22,0],
[49,31,22,1],
[49,31,22,2],
[49,31,22,3],
[49,31,22,4],
[49,31,22,5],
[49,31,22,6],
[49,31,22,7],
[49,31,22,8],
[49,31,22,11],
[49,31,22,12],
[49,31,22,13],
[49,31,22,14],
[49,31,22,16],
[49,31,22,17],
[49,31,22,19],
[49,31,22,21],
[49,31,24,0],
[49,31,24,1],
[49,31,24,2],
[49,31,24,3],
[49,31,24,4],
[49,31,24,5],
[49,31,24,6],
[49,31,24,7],
[49,31,24,8],
[49,31,24,10],
[49,31,24,11],
[49,31,24,12],
[49,31,24,13],
[49,31,24,14],
[49,31,24,17],
[49,31,24,19],
[49,31,24,20],
[49,31,24,21],
[49,31,24,22],
[49,31,25,0],
[49,31,25,1],
[49,31,25,2],
[49,31,25,3],
[49,31,25,4],
[49,31,25,5],
[49,31,25,6],
[49,31,25,8],
[49,31,25,10],
[49,31,25,11],
[49,31,25,12],
[49,31,25,13],
[49,31,25,14],
[49,31,25,16],
[49,31,25,17],
[49,31,25,19],
[49,31,25,20],
[49,31,25,21],
[49,31,25,22],
[49,31,25,24],
[49,31,27,0],
[49,31,27,1],
[49,31,27,2],
[49,31,27,3],
[49,31,27,4],
[49,31,27,5],
[49,31,27,6],
[49,31,27,7],
[49,31,27,8],
[49,31,27,11],
[49,31,27,12],
[49,31,27,13],
[49,31,27,14],
[49,31,27,16],
[49,31,27,17],
[49,31,27,19],
[49,31,27,21],
[49,31,27,24],
[49,31,27,25],
[49,31,28,0],
[49,31,28,1],
[49,31,28,2],
[49,31,28,3],
[49,31,28,4],
[49,31,28,5],
[49,31,28,6],
[49,31,28,8],
[49,31,28,10],
[49,31,28,11],
[49,31,28,12],
[49,31,28,13],
[49,31,28,14],
[49,31,28,16],
[49,31,28,17],
[49,31,28,19],
[49,31,28,20],
[49,31,28,22],
[49,31,28,24],
[49,31,28,27],
[49,31,29,0],
[49,31,29,1],
[49,31,29,2],
[49,31,29,3],
[49,31,29,4],
[49,31,29,5],
[49,31,29,6],
[49,31,29,7],
[49,31,29,8],
[49,31,29,10],
[49,31,29,13],
[49,31,29,14],
[49,31,29,16],
[49,31,29,17],
[49,31,29,19],
[49,31,29,21],
[49,31,29,22],
[49,31,29,24],
[49,31,29,25],
[49,31,29,27],
[49,31,29,28],
[49,32,3,0],
[49,32,3,1],
[49,32,3,2],
[49,32,4,0],
[49,32,4,1],
[49,32,4,2],
[49,32,4,3],
[49,32,5,0],
[49,32,5,1],
[49,32,5,2],
[49,32,5,4],
[49,32,6,0],
[49,32,6,1],
[49,32,6,2],
[49,32,6,4],
[49,32,7,0],
[49,32,7,1],
[49,32,7,2],
[49,32,7,3],
[49,32,7,4],
[49,32,7,5],
[49,32,7,6],
[49,32,8,0],
[49,32,8,1],
[49,32,8,2],
[49,32,8,4],
[49,32,8,7],
[49,32,10,0],
[49,32,10,1],
[49,32,10,2],
[49,32,10,3],
[49,32,10,4],
[49,32,10,5],
[49,32,10,6],
[49,32,10,7],
[49,32,10,8],
[49,32,13,0],
[49,32,13,1],
[49,32,13,2],
[49,32,13,3],
[49,32,13,4],
[49,32,13,5],
[49,32,13,6],
[49,32,13,7],
[49,32,13,8],
[49,32,13,10],
[49,32,14,0],
[49,32,14,1],
[49,32,14,2],
[49,32,14,3],
[49,32,14,4],
[49,32,14,5],
[49,32,14,6],
[49,32,14,7],
[49,32,14,8],
[49,32,14,10],
[49,32,16,0],
[49,32,16,1],
[49,32,16,2],
[49,32,16,3],
[49,32,16,4],
[49,32,16,5],
[49,32,16,6],
[49,32,16,7],
[49,32,16,8],
[49,32,16,10],
[49,32,16,13],
[49,32,16,14],
[49,32,17,0],
[49,32,17,1],
[49,32,17,2],
[49,32,17,3],
[49,32,17,4],
[49,32,17,5],
[49,32,17,6],
[49,32,17,7],
[49,32,17,8],
[49,32,17,10],
[49,32,17,13],
[49,32,17,14],
[49,32,17,16],
[49,32,19,0],
[49,32,19,1],
[49,32,19,2],
[49,32,19,3],
[49,32,19,4],
[49,32,19,5],
[49,32,19,6],
[49,32,19,7],
[49,32,19,8],
[49,32,19,10],
[49,32,19,16],
[49,32,19,17],
[49,32,20,0],
[49,32,20,1],
[49,32,20,2],
[49,32,20,3],
[49,32,20,4],
[49,32,20,5],
[49,32,20,6],
[49,32,20,7],
[49,32,20,8],
[49,32,20,13],
[49,32,20,14],
[49,32,20,16],
[49,32,20,17],
[49,32,20,19],
[49,32,21,0],
[49,32,21,1],
[49,32,21,2],
[49,32,21,4],
[49,32,21,7],
[49,32,21,10],
[49,32,21,13],
[49,32,21,14],
[49,32,21,16],
[49,32,21,17],
[49,32,21,19],
[49,32,21,20],
[49,32,22,0],
[49,32,22,1],
[49,32,22,2],
[49,32,22,3],
[49,32,22,4],
[49,32,22,5],
[49,32,22,6],
[49,32,22,7],
[49,32,22,8],
[49,32,22,13],
[49,32,22,14],
[49,32,22,16],
[49,32,22,17],
[49,32,22,19],
[49,32,22,21],
[49,32,23,0],
[49,32,23,1],
[49,32,23,2],
[49,32,23,3],
[49,32,23,4],
[49,32,23,5],
[49,32,23,6],
[49,32,23,7],
[49,32,23,8],
[49,32,23,10],
[49,32,23,13],
[49,32,23,14],
[49,32,23,16],
[49,32,23,17],
[49,32,23,19],
[49,32,23,20],
[49,32,23,21],
[49,32,23,22],
[49,32,24,0],
[49,32,24,1],
[49,32,24,2],
[49,32,24,3],
[49,32,24,4],
[49,32,24,5],
[49,32,24,6],
[49,32,24,7],
[49,32,24,8],
[49,32,24,10],
[49,32,24,13],
[49,32,24,14],
[49,32,24,17],
[49,32,24,19],
[49,32,24,20],
[49,32,24,21],
[49,32,24,22],
[49,32,24,23],
[49,32,25,0],
[49,32,25,1],
[49,32,25,2],
[49,32,25,3],
[49,32,25,4],
[49,32,25,5],
[49,32,25,6],
[49,32,25,8],
[49,32,25,10],
[49,32,25,13],
[49,32,25,14],
[49,32,25,16],
[49,32,25,17],
[49,32,25,19],
[49,32,25,20],
[49,32,25,21],
[49,32,25,22],
[49,32,27,0],
[49,32,27,1],
[49,32,27,2],
[49,32,27,3],
[49,32,27,4],
[49,32,27,5],
[49,32,27,6],
[49,32,27,7],
[49,32,27,8],
[49,32,27,13],
[49,32,27,14],
[49,32,27,16],
[49,32,27,17],
[49,32,27,19],
[49,32,27,23],
[49,32,27,24],
[49,32,27,25],
[49,32,28,0],
[49,32,28,1],
[49,32,28,2],
[49,32,28,3],
[49,32,28,4],
[49,32,28,5],
[49,32,28,6],
[49,32,28,8],
[49,32,28,10],
[49,32,28,13],
[49,32,28,14],
[49,32,28,16],
[49,32,28,17],
[49,32,28,19],
[49,32,28,21],
[49,32,28,22],
[49,32,28,23],
[49,32,28,24],
[49,32,28,27],
[49,32,30,0],
[49,32,30,1],
[49,32,30,2],
[49,32,30,3],
[49,32,30,4],
[49,32,30,5],
[49,32,30,6],
[49,32,30,7],
[49,32,30,8],
[49,32,30,10],
[49,32,30,13],
[49,32,30,14],
[49,32,30,16],
[49,32,30,17],
[49,32,30,19],
[49,32,30,20],
[49,32,30,21],
[49,32,30,22],
[49,32,30,24],
[49,32,30,25],
[49,32,30,27],
[49,32,30,28],
[49,32,31,0],
[49,32,31,1],
[49,32,31,2],
[49,32,31,3],
[49,32,31,4],
[49,32,31,5],
[49,32,31,6],
[49,32,31,7],
[49,32,31,8],
[49,32,31,10],
[49,32,31,13],
[49,32,31,14],
[49,32,31,19],
[49,32,31,20],
[49,32,31,21],
[49,32,31,22],
[49,32,31,24],
[49,32,31,25],
[49,32,31,27],
[49,32,31,28],
[49,33,3,0],
[49,33,3,1],
[49,33,3,2],
[49,33,4,0],
[49,33,4,1],
[49,33,4,2],
[49,33,4,3],
[49,33,5,0],
[49,33,5,1],
[49,33,5,2],
[49,33,5,4],
[49,33,6,0],
[49,33,6,1],
[49,33,6,2],
[49,33,6,4],
[49,33,7,0],
[49,33,7,1],
[49,33,7,2],
[49,33,7,3],
[49,33,7,4],
[49,33,7,5],
[49,33,7,6],
[49,33,8,0],
[49,33,8,1],
[49,33,8,2],
[49,33,8,4],
[49,33,8,7],
[49,33,10,0],
[49,33,10,1],
[49,33,10,2],
[49,33,10,3],
[49,33,10,4],
[49,33,10,5],
[49,33,10,6],
[49,33,10,7],
[49,33,10,8],
[49,33,13,0],
[49,33,13,1],
[49,33,13,2],
[49,33,13,3],
[49,33,13,4],
[49,33,13,5],
[49,33,13,6],
[49,33,13,7],
[49,33,13,8],
[49,33,13,10],
[49,33,14,0],
[49,33,14,1],
[49,33,14,2],
[49,33,14,3],
[49,33,14,4],
[49,33,14,5],
[49,33,14,6],
[49,33,14,7],
[49,33,14,8],
[49,33,14,10],
[49,33,16,0],
[49,33,16,1],
[49,33,16,2],
[49,33,16,3],
[49,33,16,4],
[49,33,16,5],
[49,33,16,6],
[49,33,16,7],
[49,33,16,8],
[49,33,16,10],
[49,33,16,13],
[49,33,16,14],
[49,33,17,0],
[49,33,17,1],
[49,33,17,2],
[49,33,17,3],
[49,33,17,4],
[49,33,17,5],
[49,33,17,6],
[49,33,17,7],
[49,33,17,8],
[49,33,17,10],
[49,33,17,13],
[49,33,17,14],
[49,33,17,16],
[49,33,19,0],
[49,33,19,1],
[49,33,19,2],
[49,33,19,3],
[49,33,19,4],
[49,33,19,5],
[49,33,19,6],
[49,33,19,7],
[49,33,19,8],
[49,33,19,10],
[49,33,19,16],
[49,33,19,17],
[49,33,20,0],
[49,33,20,1],
[49,33,20,2],
[49,33,20,3],
[49,33,20,4],
[49,33,20,5],
[49,33,20,6],
[49,33,20,7],
[49,33,20,8],
[49,33,20,13],
[49,33,20,14],
[49,33,20,16],
[49,33,20,17],
[49,33,20,19],
[49,33,21,0],
[49,33,21,1],
[49,33,21,2],
[49,33,21,4],
[49,33,21,7],
[49,33,21,10],
[49,33,21,13],
[49,33,21,14],
[49,33,21,16],
[49,33,21,17],
[49,33,21,19],
[49,33,21,20],
[49,33,22,0],
[49,33,22,1],
[49,33,22,2],
[49,33,22,3],
[49,33,22,4],
[49,33,22,5],
[49,33,22,6],
[49,33,22,7],
[49,33,22,8],
[49,33,22,13],
[49,33,22,14],
[49,33,22,16],
[49,33,22,17],
[49,33,22,19],
[49,33,22,21],
[49,33,23,0],
[49,33,23,1],
[49,33,23,2],
[49,33,23,3],
[49,33,23,4],
[49,33,23,5],
[49,33,23,6],
[49,33,23,7],
[49,33,23,8],
[49,33,23,10],
[49,33,23,13],
[49,33,23,14],
[49,33,23,16],
[49,33,23,17],
[49,33,23,19],
[49,33,23,20],
[49,33,23,21],
[49,33,23,22],
[49,33,24,0],
[49,33,24,1],
[49,33,24,2],
[49,33,24,3],
[49,33,24,4],
[49,33,24,5],
[49,33,24,6],
[49,33,24,7],
[49,33,24,8],
[49,33,24,10],
[49,33,24,13],
[49,33,24,14],
[49,33,24,17],
[49,33,24,19],
[49,33,24,20],
[49,33,24,21],
[49,33,24,22],
[49,33,24,23],
[49,33,25,0],
[49,33,25,1],
[49,33,25,2],
[49,33,25,3],
[49,33,25,4],
[49,33,25,5],
[49,33,25,6],
[49,33,25,8],
[49,33,25,10],
[49,33,25,13],
[49,33,25,14],
[49,33,25,16],
[49,33,25,17],
[49,33,25,19],
[49,33,25,20],
[49,33,25,21],
[49,33,25,23],
[49,33,25,24],
[49,33,27,0],
[49,33,27,1],
[49,33,27,2],
[49,33,27,3],
[49,33,27,4],
[49,33,27,5],
[49,33,27,6],
[49,33,27,7],
[49,33,27,8],
[49,33,27,13],
[49,33,27,14],
[49,33,27,16],
[49,33,27,17],
[49,33,27,19],
[49,33,27,21],
[49,33,27,23],
[49,33,27,24],
[49,33,27,25],
[49,33,28,0],
[49,33,28,1],
[49,33,28,2],
[49,33,28,3],
[49,33,28,4],
[49,33,28,5],
[49,33,28,6],
[49,33,28,8],
[49,33,28,10],
[49,33,28,13],
[49,33,28,14],
[49,33,28,16],
[49,33,28,17],
[49,33,28,20],
[49,33,28,21],
[49,33,28,22],
[49,33,28,23],
[49,33,28,24],
[49,33,28,27],
[49,33,30,0],
[49,33,30,1],
[49,33,30,2],
[49,33,30,3],
[49,33,30,4],
[49,33,30,5],
[49,33,30,6],
[49,33,30,7],
[49,33,30,8],
[49,33,30,10],
[49,33,30,13],
[49,33,30,14],
[49,33,30,19],
[49,33,30,20],
[49,33,30,21],
[49,33,30,22],
[49,33,30,24],
[49,33,30,25],
[49,33,30,27],
[49,33,30,28],
[49,33,31,0],
[49,33,31,1],
[49,33,31,2],
[49,33,31,3],
[49,33,31,4],
[49,33,31,5],
[49,33,31,6],
[49,33,31,7],
[49,33,31,8],
[49,33,31,10],
[49,33,31,13],
[49,33,31,14],
[49,33,31,16],
[49,33,31,17],
[49,33,31,19],
[49,33,31,20],
[49,33,31,21],
[49,33,31,22],
[49,33,31,24],
[49,33,31,25],
[49,33,31,27],
[49,33,31,28],
[49,34,3,0],
[49,34,3,1],
[49,34,3,2],
[49,34,4,0],
[49,34,4,1],
[49,34,4,2],
[49,34,4,3],
[49,34,5,0],
[49,34,5,1],
[49,34,5,2],
[49,34,5,4],
[49,34,6,0],
[49,34,6,1],
[49,34,6,2],
[49,34,6,4],
[49,34,8,0],
[49,34,8,1],
[49,34,8,2],
[49,34,8,4],
[49,34,10,0],
[49,34,10,1],
[49,34,10,2],
[49,34,10,3],
[49,34,10,4],
[49,34,10,5],
[49,34,10,6],
[49,34,10,8],
[49,34,11,0],
[49,34,11,1],
[49,34,11,2],
[49,34,11,3],
[49,34,11,4],
[49,34,11,5],
[49,34,11,6],
[49,34,11,8],
[49,34,11,10],
[49,34,12,0],
[49,34,12,1],
[49,34,12,2],
[49,34,12,3],
[49,34,12,4],
[49,34,12,5],
[49,34,12,6],
[49,34,12,8],
[49,34,12,10],
[49,34,13,0],
[49,34,13,1],
[49,34,13,2],
[49,34,13,3],
[49,34,13,4],
[49,34,13,5],
[49,34,13,6],
[49,34,13,8],
[49,34,13,10],
[49,34,13,11],
[49,34,13,12],
[49,34,14,0],
[49,34,14,1],
[49,34,14,2],
[49,34,14,3],
[49,34,14,4],
[49,34,14,5],
[49,34,14,6],
[49,34,14,8],
[49,34,14,10],
[49,34,14,11],
[49,34,14,12],
[49,34,16,0],
[49,34,16,1],
[49,34,16,2],
[49,34,16,3],
[49,34,16,4],
[49,34,16,5],
[49,34,16,6],
[49,34,16,8],
[49,34,16,10],
[49,34,16,11],
[49,34,16,12],
[49,34,16,13],
[49,34,16,14],
[49,34,17,0],
[49,34,17,1],
[49,34,17,2],
[49,34,17,3],
[49,34,17,4],
[49,34,17,5],
[49,34,17,6],
[49,34,17,8],
[49,34,17,10],
[49,34,17,11],
[49,34,17,12],
[49,34,17,13],
[49,34,17,14],
[49,34,17,16],
[49,34,19,0],
[49,34,19,1],
[49,34,19,2],
[49,34,19,3],
[49,34,19,4],
[49,34,19,5],
[49,34,19,6],
[49,34,19,8],
[49,34,19,10],
[49,34,19,11],
[49,34,19,12],
[49,34,19,16],
[49,34,19,17],
[49,34,20,0],
[49,34,20,1],
[49,34,20,2],
[49,34,20,3],
[49,34,20,4],
[49,34,20,5],
[49,34,20,6],
[49,34,20,8],
[49,34,20,11],
[49,34,20,12],
[49,34,20,13],
[49,34,20,14],
[49,34,20,16],
[49,34,20,17],
[49,34,20,19],
[49,34,21,0],
[49,34,21,1],
[49,34,21,2],
[49,34,21,4],
[49,34,21,10],
[49,34,21,11],
[49,34,21,12],
[49,34,21,13],
[49,34,21,14],
[49,34,21,16],
[49,34,21,17],
[49,34,21,19],
[49,34,21,20],
[49,34,22,0],
[49,34,22,1],
[49,34,22,2],
[49,34,22,3],
[49,34,22,4],
[49,34,22,5],
[49,34,22,6],
[49,34,22,8],
[49,34,22,11],
[49,34,22,12],
[49,34,22,13],
[49,34,22,14],
[49,34,22,16],
[49,34,22,17],
[49,34,22,19],
[49,34,22,21],
[49,34,23,0],
[49,34,23,1],
[49,34,23,2],
[49,34,23,3],
[49,34,23,4],
[49,34,23,5],
[49,34,23,6],
[49,34,23,8],
[49,34,23,10],
[49,34,23,11],
[49,34,23,12],
[49,34,23,13],
[49,34,23,14],
[49,34,23,16],
[49,34,23,17],
[49,34,23,19],
[49,34,23,20],
[49,34,23,21],
[49,34,23,22],
[49,34,24,0],
[49,34,24,1],
[49,34,24,2],
[49,34,24,3],
[49,34,24,4],
[49,34,24,5],
[49,34,24,6],
[49,34,24,8],
[49,34,24,10],
[49,34,24,11],
[49,34,24,12],
[49,34,24,13],
[49,34,24,14],
[49,34,24,17],
[49,34,24,19],
[49,34,24,20],
[49,34,24,21],
[49,34,24,22],
[49,34,27,0],
[49,34,27,1],
[49,34,27,2],
[49,34,27,3],
[49,34,27,4],
[49,34,27,5],
[49,34,27,6],
[49,34,27,8],
[49,34,27,11],
[49,34,27,12],
[49,34,27,13],
[49,34,27,14],
[49,34,27,16],
[49,34,27,17],
[49,34,27,21],
[49,34,27,23],
[49,34,27,24],
[49,34,29,0],
[49,34,29,1],
[49,34,29,2],
[49,34,29,3],
[49,34,29,4],
[49,34,29,5],
[49,34,29,6],
[49,34,29,8],
[49,34,29,10],
[49,34,29,13],
[49,34,29,14],
[49,34,29,19],
[49,34,29,20],
[49,34,29,21],
[49,34,29,22],
[49,34,29,23],
[49,34,29,24],
[49,34,29,27],
[49,34,30,0],
[49,34,30,1],
[49,34,30,2],
[49,34,30,3],
[49,34,30,4],
[49,34,30,5],
[49,34,30,6],
[49,34,30,8],
[49,34,30,10],
[49,34,30,11],
[49,34,30,12],
[49,34,30,13],
[49,34,30,14],
[49,34,30,16],
[49,34,30,17],
[49,34,30,19],
[49,34,30,20],
[49,34,30,21],
[49,34,30,22],
[49,34,30,24],
[49,34,30,27],
[49,34,31,0],
[49,34,31,1],
[49,34,31,2],
[49,34,31,3],
[49,34,31,4],
[49,34,31,5],
[49,34,31,6],
[49,34,31,8],
[49,34,31,10],
[49,34,31,11],
[49,34,31,12],
[49,34,31,13],
[49,34,31,16],
[49,34,31,17],
[49,34,31,19],
[49,34,31,20],
[49,34,31,21],
[49,34,31,22],
[49,34,31,24],
[49,34,31,27],
[49,34,32,0],
[49,34,32,1],
[49,34,32,2],
[49,34,32,3],
[49,34,32,4],
[49,34,32,5],
[49,34,32,6],
[49,34,32,8],
[49,34,32,10],
[49,34,32,14],
[49,34,32,16],
[49,34,32,17],
[49,34,32,19],
[49,34,32,20],
[49,34,32,21],
[49,34,32,22],
[49,34,32,23],
[49,34,32,24],
[49,34,33,0],
[49,34,33,1],
[49,34,33,2],
[49,34,33,3],
[49,34,33,4],
[49,34,33,5],
[49,34,33,6],
[49,34,33,8],
[49,34,33,13],
[49,34,33,14],
[49,34,33,16],
[49,34,33,17],
[49,34,33,19],
[49,34,33,20],
[49,34,33,21],
[49,34,33,22],
[49,34,33,23],
[49,34,33,24],
[49,36,3,0],
[49,36,3,1],
[49,36,3,2],
[49,36,4,0],
[49,36,4,1],
[49,36,4,2],
[49,36,4,3],
[49,36,5,0],
[49,36,5,1],
[49,36,5,2],
[49,36,5,4],
[49,36,6,0],
[49,36,6,1],
[49,36,6,2],
[49,36,6,4],
[49,36,7,0],
[49,36,7,1],
[49,36,7,2],
[49,36,7,3],
[49,36,7,4],
[49,36,7,5],
[49,36,7,6],
[49,36,8,0],
[49,36,8,1],
[49,36,8,2],
[49,36,8,4],
[49,36,8,7],
[49,36,10,0],
[49,36,10,1],
[49,36,10,2],
[49,36,10,3],
[49,36,10,4],
[49,36,10,5],
[49,36,10,6],
[49,36,10,7],
[49,36,10,8],
[49,36,11,0],
[49,36,11,1],
[49,36,11,2],
[49,36,11,3],
[49,36,11,4],
[49,36,11,5],
[49,36,11,6],
[49,36,11,7],
[49,36,11,8],
[49,36,11,10],
[49,36,12,0],
[49,36,12,1],
[49,36,12,2],
[49,36,12,3],
[49,36,12,4],
[49,36,12,5],
[49,36,12,6],
[49,36,12,7],
[49,36,12,8],
[49,36,12,10],
[49,36,16,0],
[49,36,16,1],
[49,36,16,2],
[49,36,16,3],
[49,36,16,4],
[49,36,16,5],
[49,36,16,6],
[49,36,16,7],
[49,36,16,8],
[49,36,16,10],
[49,36,16,11],
[49,36,16,12],
[49,36,17,0],
[49,36,17,1],
[49,36,17,2],
[49,36,17,3],
[49,36,17,4],
[49,36,17,5],
[49,36,17,6],
[49,36,17,7],
[49,36,17,8],
[49,36,17,10],
[49,36,17,11],
[49,36,17,12],
[49,36,17,16],
[49,36,20,0],
[49,36,20,1],
[49,36,20,2],
[49,36,20,3],
[49,36,20,4],
[49,36,20,5],
[49,36,20,6],
[49,36,20,7],
[49,36,20,8],
[49,36,20,11],
[49,36,20,12],
[49,36,20,16],
[49,36,20,17],
[49,36,21,0],
[49,36,21,1],
[49,36,21,2],
[49,36,21,4],
[49,36,21,7],
[49,36,21,10],
[49,36,21,11],
[49,36,21,12],
[49,36,21,16],
[49,36,21,17],
[49,36,21,20],
[49,36,22,0],
[49,36,22,1],
[49,36,22,2],
[49,36,22,3],
[49,36,22,4],
[49,36,22,5],
[49,36,22,6],
[49,36,22,7],
[49,36,22,8],
[49,36,22,11],
[49,36,22,12],
[49,36,22,16],
[49,36,22,17],
[49,36,22,21],
[49,36,23,0],
[49,36,23,1],
[49,36,23,2],
[49,36,23,3],
[49,36,23,4],
[49,36,23,5],
[49,36,23,6],
[49,36,23,7],
[49,36,23,8],
[49,36,23,10],
[49,36,23,11],
[49,36,23,12],
[49,36,23,16],
[49,36,23,17],
[49,36,23,20],
[49,36,23,22],
[49,36,24,0],
[49,36,24,1],
[49,36,24,2],
[49,36,24,3],
[49,36,24,4],
[49,36,24,5],
[49,36,24,6],
[49,36,24,7],
[49,36,24,8],
[49,36,24,10],
[49,36,24,11],
[49,36,24,12],
[49,36,24,17],
[49,36,24,20],
[49,36,24,22],
[49,36,24,23],
[49,36,25,0],
[49,36,25,1],
[49,36,25,2],
[49,36,25,3],
[49,36,25,4],
[49,36,25,5],
[49,36,25,6],
[49,36,25,8],
[49,36,25,10],
[49,36,25,11],
[49,36,25,12],
[49,36,25,16],
[49,36,25,17],
[49,36,25,20],
[49,36,25,21],
[49,36,25,22],
[49,36,25,23],
[49,36,25,24],
[49,36,27,0],
[49,36,27,1],
[49,36,27,2],
[49,36,27,3],
[49,36,27,4],
[49,36,27,5],
[49,36,27,6],
[49,36,27,7],
[49,36,27,8],
[49,36,27,11],
[49,36,27,12],
[49,36,27,21],
[49,36,27,23],
[49,36,27,24],
[49,36,27,25],
[49,36,28,0],
[49,36,28,1],
[49,36,28,2],
[49,36,28,3],
[49,36,28,4],
[49,36,28,5],
[49,36,28,6],
[49,36,28,8],
[49,36,28,10],
[49,36,28,11],
[49,36,28,12],
[49,36,28,16],
[49,36,28,17],
[49,36,28,20],
[49,36,28,21],
[49,36,28,22],
[49,36,28,23],
[49,36,28,24],
[49,36,28,27],
[49,36,29,0],
[49,36,29,1],
[49,36,29,2],
[49,36,29,3],
[49,36,29,4],
[49,36,29,5],
[49,36,29,6],
[49,36,29,7],
[49,36,29,8],
[49,36,29,10],
[49,36,29,16],
[49,36,29,17],
[49,36,29,20],
[49,36,29,21],
[49,36,29,22],
[49,36,29,23],
[49,36,29,24],
[49,36,29,25],
[49,36,29,27],
[49,36,30,0],
[49,36,30,1],
[49,36,30,2],
[49,36,30,3],
[49,36,30,4],
[49,36,30,5],
[49,36,30,6],
[49,36,30,7],
[49,36,30,8],
[49,36,30,10],
[49,36,30,11],
[49,36,30,16],
[49,36,30,17],
[49,36,30,20],
[49,36,30,21],
[49,36,30,22],
[49,36,30,24],
[49,36,30,25],
[49,36,31,0],
[49,36,31,1],
[49,36,31,2],
[49,36,31,3],
[49,36,31,4],
[49,36,31,5],
[49,36,31,6],
[49,36,31,7],
[49,36,31,8],
[49,36,31,12],
[49,36,31,16],
[49,36,31,17],
[49,36,31,20],
[49,36,31,21],
[49,36,31,22],
[49,36,31,24],
[49,36,31,25],
[49,36,32,0],
[49,36,32,1],
[49,36,32,2],
[49,36,32,3],
[49,36,32,4],
[49,36,32,5],
[49,36,32,6],
[49,36,32,7],
[49,36,32,10],
[49,36,32,16],
[49,36,32,17],
[49,36,32,20],
[49,36,32,21],
[49,36,32,22],
[49,36,32,23],
[49,36,32,24],
[49,36,33,0],
[49,36,33,1],
[49,36,33,2],
[49,36,33,3],
[49,36,33,4],
[49,36,33,5],
[49,36,33,8],
[49,36,33,10],
[49,36,33,16],
[49,36,33,17],
[49,36,33,20],
[49,36,33,21],
[49,36,33,22],
[49,36,33,23],
[49,36,33,24],
[49,36,34,0],
[49,36,34,1],
[49,36,34,2],
[49,36,34,3],
[49,36,34,4],
[49,36,34,6],
[49,36,34,8],
[49,36,34,10],
[49,36,34,11],
[49,36,34,12],
[49,36,34,16],
[49,36,34,17],
[49,36,34,20],
[49,36,34,21],
[49,36,34,22],
[49,37,3,0],
[49,37,3,1],
[49,37,3,2],
[49,37,5,0],
[49,37,5,1],
[49,37,5,2],
[49,37,6,0],
[49,37,6,1],
[49,37,6,2],
[49,37,7,0],
[49,37,7,1],
[49,37,7,2],
[49,37,7,3],
[49,37,7,5],
[49,37,7,6],
[49,37,8,0],
[49,37,8,1],
[49,37,8,2],
[49,37,8,7],
[49,37,10,0],
[49,37,10,1],
[49,37,10,2],
[49,37,10,3],
[49,37,10,5],
[49,37,10,6],
[49,37,10,7],
[49,37,10,8],
[49,37,11,0],
[49,37,11,1],
[49,37,11,2],
[49,37,11,3],
[49,37,11,5],
[49,37,11,6],
[49,37,11,7],
[49,37,11,8],
[49,37,11,10],
[49,37,12,0],
[49,37,12,1],
[49,37,12,2],
[49,37,12,3],
[49,37,12,5],
[49,37,12,6],
[49,37,12,7],
[49,37,12,8],
[49,37,12,10],
[49,37,13,0],
[49,37,13,1],
[49,37,13,2],
[49,37,13,3],
[49,37,13,5],
[49,37,13,6],
[49,37,13,7],
[49,37,13,8],
[49,37,13,10],
[49,37,13,11],
[49,37,13,12],
[49,37,14,0],
[49,37,14,1],
[49,37,14,2],
[49,37,14,3],
[49,37,14,5],
[49,37,14,6],
[49,37,14,7],
[49,37,14,8],
[49,37,14,10],
[49,37,14,11],
[49,37,14,12],
[49,37,16,0],
[49,37,16,1],
[49,37,16,2],
[49,37,16,3],
[49,37,16,5],
[49,37,16,6],
[49,37,16,7],
[49,37,16,8],
[49,37,16,10],
[49,37,16,11],
[49,37,16,12],
[49,37,16,13],
[49,37,16,14],
[49,37,17,0],
[49,37,17,1],
[49,37,17,2],
[49,37,17,3],
[49,37,17,5],
[49,37,17,6],
[49,37,17,7],
[49,37,17,8],
[49,37,17,10],
[49,37,17,11],
[49,37,17,12],
[49,37,17,13],
[49,37,17,14],
[49,37,17,16],
[49,37,19,0],
[49,37,19,1],
[49,37,19,2],
[49,37,19,3],
[49,37,19,5],
[49,37,19,6],
[49,37,19,7],
[49,37,19,8],
[49,37,19,10],
[49,37,19,11],
[49,37,19,12],
[49,37,19,16],
[49,37,19,17],
[49,37,20,0],
[49,37,20,1],
[49,37,20,2],
[49,37,20,3],
[49,37,20,5],
[49,37,20,6],
[49,37,20,7],
[49,37,20,8],
[49,37,20,11],
[49,37,20,12],
[49,37,20,13],
[49,37,20,14],
[49,37,20,16],
[49,37,20,17],
[49,37,20,19],
[49,37,21,0],
[49,37,21,1],
[49,37,21,2],
[49,37,21,7],
[49,37,21,10],
[49,37,21,11],
[49,37,21,12],
[49,37,21,13],
[49,37,21,14],
[49,37,21,16],
[49,37,21,17],
[49,37,21,19],
[49,37,21,20],
[49,37,22,0],
[49,37,22,1],
[49,37,22,2],
[49,37,22,3],
[49,37,22,5],
[49,37,22,6],
[49,37,22,7],
[49,37,22,8],
[49,37,22,11],
[49,37,22,12],
[49,37,22,13],
[49,37,22,14],
[49,37,22,16],
[49,37,22,17],
[49,37,22,19],
[49,37,23,0],
[49,37,23,1],
[49,37,23,2],
[49,37,23,3],
[49,37,23,5],
[49,37,23,6],
[49,37,23,7],
[49,37,23,8],
[49,37,23,10],
[49,37,23,11],
[49,37,23,12],
[49,37,23,13],
[49,37,23,14],
[49,37,23,16],
[49,37,23,17],
[49,37,23,19],
[49,37,23,21],
[49,37,23,22],
[49,37,24,0],
[49,37,24,1],
[49,37,24,2],
[49,37,24,3],
[49,37,24,5],
[49,37,24,6],
[49,37,24,7],
[49,37,24,8],
[49,37,24,10],
[49,37,24,11],
[49,37,24,12],
[49,37,24,13],
[49,37,24,14],
[49,37,24,17],
[49,37,24,19],
[49,37,24,21],
[49,37,24,22],
[49,37,24,23],
[49,37,25,0],
[49,37,25,1],
[49,37,25,2],
[49,37,25,3],
[49,37,25,5],
[49,37,25,6],
[49,37,25,8],
[49,37,25,10],
[49,37,25,11],
[49,37,25,12],
[49,37,25,13],
[49,37,25,14],
[49,37,25,16],
[49,37,25,17],
[49,37,25,19],
[49,37,25,20],
[49,37,25,21],
[49,37,25,22],
[49,37,25,23],
[49,37,25,24],
[49,37,27,0],
[49,37,27,1],
[49,37,27,2],
[49,37,27,3],
[49,37,27,5],
[49,37,27,6],
[49,37,27,7],
[49,37,27,8],
[49,37,27,11],
[49,37,27,12],
[49,37,27,13],
[49,37,27,14],
[49,37,27,16],
[49,37,27,17],
[49,37,27,19],
[49,37,27,21],
[49,37,27,23],
[49,37,27,24],
[49,37,27,25],
[49,37,28,0],
[49,37,28,1],
[49,37,28,2],
[49,37,28,3],
[49,37,28,5],
[49,37,28,6],
[49,37,28,8],
[49,37,28,10],
[49,37,28,11],
[49,37,28,12],
[49,37,28,13],
[49,37,28,16],
[49,37,28,17],
[49,37,28,19],
[49,37,28,20],
[49,37,28,21],
[49,37,28,22],
[49,37,28,23],
[49,37,28,24],
[49,37,28,27],
[49,37,29,0],
[49,37,29,1],
[49,37,29,2],
[49,37,29,3],
[49,37,29,5],
[49,37,29,6],
[49,37,29,7],
[49,37,29,8],
[49,37,29,10],
[49,37,29,14],
[49,37,29,16],
[49,37,29,17],
[49,37,29,19],
[49,37,29,20],
[49,37,29,21],
[49,37,29,22],
[49,37,29,23],
[49,37,29,24],
[49,37,29,25],
[49,37,30,0],
[49,37,30,1],
[49,37,30,2],
[49,37,30,3],
[49,37,30,5],
[49,37,30,6],
[49,37,30,7],
[49,37,30,8],
[49,37,30,12],
[49,37,30,13],
[49,37,30,14],
[49,37,30,16],
[49,37,30,17],
[49,37,30,19],
[49,37,30,20],
[49,37,30,21],
[49,37,30,22],
[49,37,30,24],
[49,37,30,25],
[49,37,31,0],
[49,37,31,1],
[49,37,31,2],
[49,37,31,3],
[49,37,31,5],
[49,37,31,6],
[49,37,31,7],
[49,37,31,10],
[49,37,31,11],
[49,37,31,12],
[49,37,31,13],
[49,37,31,14],
[49,37,31,16],
[49,37,31,17],
[49,37,31,19],
[49,37,31,20],
[49,37,31,21],
[49,37,31,22],
[49,37,31,24],
[49,37,32,0],
[49,37,32,1],
[49,37,32,2],
[49,37,32,3],
[49,37,32,5],
[49,37,32,8],
[49,37,32,10],
[49,37,32,13],
[49,37,32,14],
[49,37,32,16],
[49,37,32,17],
[49,37,32,19],
[49,37,32,20],
[49,37,32,21],
[49,37,32,22],
[49,37,32,23],
[49,37,32,24],
[49,37,33,0],
[49,37,33,1],
[49,37,33,2],
[49,37,33,3],
[49,37,33,6],
[49,37,33,7],
[49,37,33,8],
[49,37,33,10],
[49,37,33,13],
[49,37,33,14],
[49,37,33,16],
[49,37,33,17],
[49,37,33,19],
[49,37,33,20],
[49,37,33,21],
[49,37,33,22],
[49,37,34,0],
[49,37,34,1],
[49,37,34,2],
[49,37,34,5],
[49,37,34,6],
[49,37,34,8],
[49,37,34,10],
[49,37,34,11],
[49,37,34,12],
[49,37,34,13],
[49,37,34,14],
[49,37,34,16],
[49,37,34,17],
[49,37,34,19],
[49,37,34,20],
[49,37,34,21],
[49,37,36,0],
[49,37,36,1],
[49,37,36,2],
[49,37,36,3],
[49,37,36,5],
[49,37,36,6],
[49,37,36,7],
[49,37,36,8],
[49,37,36,10],
[49,37,36,11],
[49,37,36,12],
[49,37,36,16],
[49,37,36,17],
[49,38,3,0],
[49,38,3,1],
[49,38,3,2],
[49,38,4,0],
[49,38,4,1],
[49,38,4,2],
[49,38,4,3],
[49,38,5,0],
[49,38,5,1],
[49,38,5,2],
[49,38,5,4],
[49,38,6,0],
[49,38,6,1],
[49,38,6,2],
[49,38,6,4],
[49,38,8,0],
[49,38,8,1],
[49,38,8,2],
[49,38,8,4],
[49,38,10,0],
[49,38,10,1],
[49,38,10,2],
[49,38,10,3],
[49,38,10,4],
[49,38,10,5],
[49,38,10,6],
[49,38,10,8],
[49,38,11,0],
[49,38,11,1],
[49,38,11,2],
[49,38,11,3],
[49,38,11,4],
[49,38,11,5],
[49,38,11,6],
[49,38,11,8],
[49,38,11,10],
[49,38,12,0],
[49,38,12,1],
[49,38,12,2],
[49,38,12,3],
[49,38,12,4],
[49,38,12,5],
[49,38,12,6],
[49,38,12,8],
[49,38,12,10],
[49,38,13,0],
[49,38,13,1],
[49,38,13,2],
[49,38,13,3],
[49,38,13,4],
[49,38,13,5],
[49,38,13,6],
[49,38,13,8],
[49,38,13,10],
[49,38,13,11],
[49,38,13,12],
[49,38,14,0],
[49,38,14,1],
[49,38,14,2],
[49,38,14,3],
[49,38,14,4],
[49,38,14,5],
[49,38,14,6],
[49,38,14,8],
[49,38,14,10],
[49,38,14,11],
[49,38,14,12],
[49,38,16,0],
[49,38,16,1],
[49,38,16,2],
[49,38,16,3],
[49,38,16,4],
[49,38,16,5],
[49,38,16,6],
[49,38,16,8],
[49,38,16,10],
[49,38,16,11],
[49,38,16,12],
[49,38,16,13],
[49,38,16,14],
[49,38,17,0],
[49,38,17,1],
[49,38,17,2],
[49,38,17,3],
[49,38,17,4],
[49,38,17,5],
[49,38,17,6],
[49,38,17,8],
[49,38,17,10],
[49,38,17,11],
[49,38,17,12],
[49,38,17,13],
[49,38,17,14],
[49,38,17,16],
[49,38,19,0],
[49,38,19,1],
[49,38,19,2],
[49,38,19,3],
[49,38,19,4],
[49,38,19,5],
[49,38,19,6],
[49,38,19,8],
[49,38,19,10],
[49,38,19,11],
[49,38,19,12],
[49,38,19,16],
[49,38,19,17],
[49,38,20,0],
[49,38,20,1],
[49,38,20,2],
[49,38,20,3],
[49,38,20,4],
[49,38,20,5],
[49,38,20,6],
[49,38,20,8],
[49,38,20,11],
[49,38,20,12],
[49,38,20,13],
[49,38,20,14],
[49,38,20,16],
[49,38,20,17],
[49,38,20,19],
[49,38,21,0],
[49,38,21,1],
[49,38,21,2],
[49,38,21,4],
[49,38,21,10],
[49,38,21,11],
[49,38,21,12],
[49,38,21,13],
[49,38,21,14],
[49,38,21,16],
[49,38,21,17],
[49,38,21,19],
[49,38,21,20],
[49,38,22,0],
[49,38,22,1],
[49,38,22,2],
[49,38,22,3],
[49,38,22,4],
[49,38,22,5],
[49,38,22,6],
[49,38,22,8],
[49,38,22,11],
[49,38,22,12],
[49,38,22,13],
[49,38,22,14],
[49,38,22,16],
[49,38,22,17],
[49,38,22,19],
[49,38,22,21],
[49,38,23,0],
[49,38,23,1],
[49,38,23,2],
[49,38,23,3],
[49,38,23,4],
[49,38,23,5],
[49,38,23,6],
[49,38,23,8],
[49,38,23,10],
[49,38,23,11],
[49,38,23,12],
[49,38,23,13],
[49,38,23,14],
[49,38,23,16],
[49,38,23,17],
[49,38,23,20],
[49,38,23,21],
[49,38,23,22],
[49,38,24,0],
[49,38,24,1],
[49,38,24,2],
[49,38,24,3],
[49,38,24,4],
[49,38,24,5],
[49,38,24,6],
[49,38,24,8],
[49,38,24,10],
[49,38,24,11],
[49,38,24,12],
[49,38,24,13],
[49,38,24,14],
[49,38,24,17],
[49,38,24,20],
[49,38,24,21],
[49,38,24,22],
[49,38,24,23],
[49,38,27,0],
[49,38,27,1],
[49,38,27,2],
[49,38,27,3],
[49,38,27,4],
[49,38,27,5],
[49,38,27,6],
[49,38,27,8],
[49,38,27,11],
[49,38,27,12],
[49,38,27,13],
[49,38,27,16],
[49,38,27,17],
[49,38,27,19],
[49,38,27,21],
[49,38,27,23],
[49,38,27,24],
[49,38,29,0],
[49,38,29,1],
[49,38,29,2],
[49,38,29,3],
[49,38,29,4],
[49,38,29,5],
[49,38,29,6],
[49,38,29,8],
[49,38,29,13],
[49,38,29,14],
[49,38,29,16],
[49,38,29,17],
[49,38,29,19],
[49,38,29,20],
[49,38,29,21],
[49,38,29,22],
[49,38,29,23],
[49,38,29,24],
[49,38,30,0],
[49,38,30,1],
[49,38,30,2],
[49,38,30,3],
[49,38,30,4],
[49,38,30,5],
[49,38,30,6],
[49,38,30,10],
[49,38,30,11],
[49,38,30,12],
[49,38,30,13],
[49,38,30,14],
[49,38,30,16],
[49,38,30,17],
[49,38,30,19],
[49,38,30,20],
[49,38,30,21],
[49,38,30,22],
[49,38,30,24],
[49,38,31,0],
[49,38,31,1],
[49,38,31,2],
[49,38,31,3],
[49,38,31,4],
[49,38,31,5],
[49,38,31,8],
[49,38,31,10],
[49,38,31,11],
[49,38,31,12],
[49,38,31,13],
[49,38,31,14],
[49,38,31,16],
[49,38,31,17],
[49,38,31,19],
[49,38,31,20],
[49,38,31,21],
[49,38,31,22],
[49,38,31,24],
[49,38,32,0],
[49,38,32,1],
[49,38,32,2],
[49,38,32,3],
[49,38,32,4],
[49,38,32,6],
[49,38,32,8],
[49,38,32,10],
[49,38,32,13],
[49,38,32,14],
[49,38,32,16],
[49,38,32,17],
[49,38,32,19],
[49,38,32,20],
[49,38,32,21],
[49,38,32,22],
[49,38,33,0],
[49,38,33,1],
[49,38,33,2],
[49,38,33,5],
[49,38,33,6],
[49,38,33,8],
[49,38,33,10],
[49,38,33,13],
[49,38,33,14],
[49,38,33,16],
[49,38,33,17],
[49,38,33,19],
[49,38,33,20],
[49,38,33,21],
[49,38,36,0],
[49,38,36,1],
[49,38,36,2],
[49,38,36,3],
[49,38,36,4],
[49,38,36,5],
[49,38,36,6],
[49,38,36,8],
[49,38,36,10],
[49,38,36,11],
[49,38,36,12],
[49,38,36,16],
[49,38,36,17],
[49,38,37,0],
[49,38,37,1],
[49,38,37,2],
[49,38,37,3],
[49,38,37,5],
[49,38,37,6],
[49,38,37,8],
[49,38,37,10],
[49,38,37,11],
[49,38,37,12],
[49,38,37,13],
[49,38,37,14],
[49,38,37,16],
[49,38,37,17],
[49,39,4,0],
[49,39,4,1],
[49,39,4,2],
[49,39,7,0],
[49,39,7,1],
[49,39,7,2],
[49,39,7,4],
[49,39,10,0],
[49,39,10,1],
[49,39,10,2],
[49,39,10,4],
[49,39,10,7],
[49,39,11,0],
[49,39,11,1],
[49,39,11,2],
[49,39,11,4],
[49,39,11,7],
[49,39,11,10],
[49,39,12,0],
[49,39,12,1],
[49,39,12,2],
[49,39,12,4],
[49,39,12,7],
[49,39,12,10],
[49,39,13,0],
[49,39,13,1],
[49,39,13,2],
[49,39,13,4],
[49,39,13,7],
[49,39,13,10],
[49,39,13,11],
[49,39,13,12],
[49,39,14,0],
[49,39,14,1],
[49,39,14,2],
[49,39,14,4],
[49,39,14,7],
[49,39,14,10],
[49,39,14,11],
[49,39,14,12],
[49,39,16,0],
[49,39,16,1],
[49,39,16,2],
[49,39,16,4],
[49,39,16,7],
[49,39,16,10],
[49,39,16,11],
[49,39,16,12],
[49,39,16,13],
[49,39,16,14],
[49,39,17,0],
[49,39,17,1],
[49,39,17,2],
[49,39,17,4],
[49,39,17,7],
[49,39,17,10],
[49,39,17,11],
[49,39,17,12],
[49,39,17,13],
[49,39,17,14],
[49,39,17,16],
[49,39,19,0],
[49,39,19,1],
[49,39,19,2],
[49,39,19,4],
[49,39,19,7],
[49,39,19,10],
[49,39,19,11],
[49,39,19,12],
[49,39,19,16],
[49,39,19,17],
[49,39,20,0],
[49,39,20,1],
[49,39,20,2],
[49,39,20,4],
[49,39,20,7],
[49,39,20,11],
[49,39,20,12],
[49,39,20,13],
[49,39,20,14],
[49,39,20,16],
[49,39,20,17],
[49,39,20,19],
[49,39,22,0],
[49,39,22,1],
[49,39,22,2],
[49,39,22,4],
[49,39,22,7],
[49,39,22,11],
[49,39,22,12],
[49,39,22,13],
[49,39,22,14],
[49,39,22,16],
[49,39,22,17],
[49,39,23,0],
[49,39,23,1],
[49,39,23,2],
[49,39,23,4],
[49,39,23,7],
[49,39,23,10],
[49,39,23,11],
[49,39,23,12],
[49,39,23,13],
[49,39,23,14],
[49,39,23,16],
[49,39,23,17],
[49,39,23,19],
[49,39,23,20],
[49,39,23,22],
[49,39,24,0],
[49,39,24,1],
[49,39,24,2],
[49,39,24,4],
[49,39,24,7],
[49,39,24,10],
[49,39,24,11],
[49,39,24,12],
[49,39,24,13],
[49,39,24,14],
[49,39,24,17],
[49,39,24,19],
[49,39,24,20],
[49,39,24,22],
[49,39,24,23],
[49,39,25,0],
[49,39,25,1],
[49,39,25,2],
[49,39,25,4],
[49,39,25,10],
[49,39,25,11],
[49,39,25,12],
[49,39,25,13],
[49,39,25,14],
[49,39,25,16],
[49,39,25,17],
[49,39,25,19],
[49,39,25,20],
[49,39,25,22],
[49,39,25,23],
[49,39,25,24],
[49,39,27,0],
[49,39,27,1],
[49,39,27,2],
[49,39,27,4],
[49,39,27,7],
[49,39,27,11],
[49,39,27,14],
[49,39,27,16],
[49,39,27,17],
[49,39,27,19],
[49,39,27,23],
[49,39,27,24],
[49,39,27,25],
[49,39,28,0],
[49,39,28,1],
[49,39,28,2],
[49,39,28,4],
[49,39,28,12],
[49,39,28,13],
[49,39,28,14],
[49,39,28,16],
[49,39,28,17],
[49,39,28,19],
[49,39,28,20],
[49,39,28,22],
[49,39,28,23],
[49,39,28,24],
[49,39,29,0],
[49,39,29,1],
[49,39,29,2],
[49,39,29,4],
[49,39,29,7],
[49,39,29,10],
[49,39,29,13],
[49,39,29,14],
[49,39,29,16],
[49,39,29,17],
[49,39,29,19],
[49,39,29,20],
[49,39,29,22],
[49,39,29,23],
[49,39,29,24],
[49,39,30,0],
[49,39,30,1],
[49,39,30,2],
[49,39,30,4],
[49,39,30,10],
[49,39,30,11],
[49,39,30,12],
[49,39,30,13],
[49,39,30,14],
[49,39,30,16],
[49,39,30,17],
[49,39,30,19],
[49,39,30,20],
[49,39,30,22],
[49,39,30,24],
[49,39,31,0],
[49,39,31,1],
[49,39,31,2],
[49,39,31,4],
[49,39,31,7],
[49,39,31,10],
[49,39,31,11],
[49,39,31,12],
[49,39,31,13],
[49,39,31,14],
[49,39,31,16],
[49,39,31,17],
[49,39,31,19],
[49,39,31,20],
[49,39,31,22],
[49,39,32,0],
[49,39,32,1],
[49,39,32,2],
[49,39,32,7],
[49,39,32,10],
[49,39,32,13],
[49,39,32,14],
[49,39,32,16],
[49,39,32,17],
[49,39,32,19],
[49,39,32,20],
[49,39,33,4],
[49,39,33,7],
[49,39,33,10],
[49,39,33,13],
[49,39,33,14],
[49,39,33,16],
[49,39,33,17],
[49,39,33,19],
[49,39,33,20],
[49,39,34,0],
[49,39,34,1],
[49,39,34,2],
[49,39,34,4],
[49,39,34,10],
[49,39,34,11],
[49,39,34,12],
[49,39,34,13],
[49,39,34,14],
[49,39,34,16],
[49,39,34,17],
[49,39,34,19],
[49,39,36,0],
[49,39,36,1],
[49,39,36,2],
[49,39,36,4],
[49,39,36,7],
[49,39,36,10],
[49,39,36,11],
[49,39,36,12],
[49,39,36,16],
[49,39,36,17],
[49,39,37,0],
[49,39,37,1],
[49,39,37,2],
[49,39,37,7],
[49,39,37,10],
[49,39,37,11],
[49,39,37,12],
[49,39,37,13],
[49,39,37,14],
[49,39,38,0],
[49,39,38,1],
[49,39,38,2],
[49,39,38,4],
[49,39,38,10],
[49,39,38,11],
[49,39,38,12],
[49,39,38,13],
[49,39,38,14],
[49,42,3,0],
[49,42,3,1],
[49,42,3,2],
[49,42,4,0],
[49,42,4,1],
[49,42,4,2],
[49,42,4,3],
[49,42,5,0],
[49,42,5,1],
[49,42,5,2],
[49,42,5,4],
[49,42,6,0],
[49,42,6,1],
[49,42,6,2],
[49,42,6,4],
[49,42,7,0],
[49,42,7,1],
[49,42,7,2],
[49,42,7,3],
[49,42,7,4],
[49,42,7,5],
[49,42,7,6],
[49,42,8,0],
[49,42,8,1],
[49,42,8,2],
[49,42,8,4],
[49,42,8,7],
[49,42,11,0],
[49,42,11,1],
[49,42,11,2],
[49,42,11,3],
[49,42,11,4],
[49,42,11,5],
[49,42,11,6],
[49,42,11,7],
[49,42,11,8],
[49,42,12,0],
[49,42,12,1],
[49,42,12,2],
[49,42,12,3],
[49,42,12,4],
[49,42,12,5],
[49,42,12,6],
[49,42,12,7],
[49,42,12,8],
[49,42,13,0],
[49,42,13,1],
[49,42,13,2],
[49,42,13,3],
[49,42,13,4],
[49,42,13,5],
[49,42,13,6],
[49,42,13,7],
[49,42,13,8],
[49,42,13,11],
[49,42,13,12],
[49,42,14,0],
[49,42,14,1],
[49,42,14,2],
[49,42,14,3],
[49,42,14,4],
[49,42,14,5],
[49,42,14,6],
[49,42,14,7],
[49,42,14,8],
[49,42,14,11],
[49,42,14,12],
[49,42,16,0],
[49,42,16,1],
[49,42,16,2],
[49,42,16,3],
[49,42,16,4],
[49,42,16,5],
[49,42,16,6],
[49,42,16,7],
[49,42,16,8],
[49,42,16,11],
[49,42,16,12],
[49,42,16,13],
[49,42,16,14],
[49,42,17,0],
[49,42,17,1],
[49,42,17,2],
[49,42,17,3],
[49,42,17,4],
[49,42,17,5],
[49,42,17,6],
[49,42,17,7],
[49,42,17,8],
[49,42,17,11],
[49,42,17,12],
[49,42,17,13],
[49,42,17,14],
[49,42,17,16],
[49,42,19,0],
[49,42,19,1],
[49,42,19,2],
[49,42,19,3],
[49,42,19,4],
[49,42,19,5],
[49,42,19,6],
[49,42,19,7],
[49,42,19,8],
[49,42,19,11],
[49,42,19,12],
[49,42,19,16],
[49,42,19,17],
[49,42,21,0],
[49,42,21,1],
[49,42,21,2],
[49,42,21,4],
[49,42,21,7],
[49,42,21,11],
[49,42,21,12],
[49,42,21,13],
[49,42,21,14],
[49,42,21,16],
[49,42,21,17],
[49,42,21,19],
[49,42,23,0],
[49,42,23,1],
[49,42,23,2],
[49,42,23,3],
[49,42,23,4],
[49,42,23,5],
[49,42,23,6],
[49,42,23,7],
[49,42,23,8],
[49,42,23,11],
[49,42,23,12],
[49,42,23,13],
[49,42,23,14],
[49,42,23,16],
[49,42,23,17],
[49,42,23,19],
[49,42,23,21],
[49,42,24,0],
[49,42,24,1],
[49,42,24,2],
[49,42,24,3],
[49,42,24,4],
[49,42,24,5],
[49,42,24,6],
[49,42,24,7],
[49,42,24,8],
[49,42,24,11],
[49,42,24,12],
[49,42,24,13],
[49,42,24,14],
[49,42,24,17],
[49,42,24,19],
[49,42,24,21],
[49,42,24,23],
[49,42,25,0],
[49,42,25,1],
[49,42,25,2],
[49,42,25,3],
[49,42,25,4],
[49,42,25,5],
[49,42,25,6],
[49,42,25,8],
[49,42,25,11],
[49,42,25,14],
[49,42,25,16],
[49,42,25,17],
[49,42,25,19],
[49,42,25,21],
[49,42,25,23],
[49,42,25,24],
[49,42,28,0],
[49,42,28,1],
[49,42,28,2],
[49,42,28,3],
[49,42,28,4],
[49,42,28,5],
[49,42,28,8],
[49,42,28,11],
[49,42,28,12],
[49,42,28,13],
[49,42,28,14],
[49,42,28,16],
[49,42,28,17],
[49,42,28,19],
[49,42,28,21],
[49,42,28,23],
[49,42,28,24],
[49,42,29,0],
[49,42,29,1],
[49,42,29,2],
[49,42,29,3],
[49,42,29,4],
[49,42,29,6],
[49,42,29,7],
[49,42,29,8],
[49,42,29,13],
[49,42,29,14],
[49,42,29,16],
[49,42,29,17],
[49,42,29,19],
[49,42,29,21],
[49,42,30,0],
[49,42,30,1],
[49,42,30,2],
[49,42,30,5],
[49,42,30,6],
[49,42,30,7],
[49,42,30,8],
[49,42,30,11],
[49,42,30,12],
[49,42,30,13],
[49,42,30,14],
[49,42,30,16],
[49,42,30,17],
[49,42,30,19],
[49,42,30,21],
[49,42,31,3],
[49,42,31,4],
[49,42,31,5],
[49,42,31,6],
[49,42,31,7],
[49,42,31,8],
[49,42,31,11],
[49,42,31,12],
[49,42,31,13],
[49,42,31,14],
[49,42,31,16],
[49,42,31,17],
[49,42,31,19],
[49,42,32,0],
[49,42,32,1],
[49,42,32,2],
[49,42,32,3],
[49,42,32,4],
[49,42,32,5],
[49,42,32,6],
[49,42,32,7],
[49,42,32,8],
[49,42,32,13],
[49,42,32,14],
[49,42,32,16],
[49,42,32,17],
[49,42,32,19],
[49,42,33,0],
[49,42,33,1],
[49,42,33,2],
[49,42,33,3],
[49,42,33,4],
[49,42,33,5],
[49,42,33,6],
[49,42,33,7],
[49,42,33,8],
[49,42,33,13],
[49,42,33,14],
[49,42,33,16],
[49,42,33,17],
[49,42,34,0],
[49,42,34,1],
[49,42,34,2],
[49,42,34,3],
[49,42,34,4],
[49,42,34,5],
[49,42,34,6],
[49,42,34,8],
[49,42,34,11],
[49,42,34,12],
[49,42,34,13],
[49,42,34,14],
[49,42,34,16],
[49,42,34,17],
[49,42,36,0],
[49,42,36,1],
[49,42,36,2],
[49,42,36,3],
[49,42,36,4],
[49,42,36,5],
[49,42,36,6],
[49,42,36,7],
[49,42,36,8],
[49,42,36,11],
[49,42,36,12],
[49,42,37,0],
[49,42,37,1],
[49,42,37,2],
[49,42,37,3],
[49,42,37,5],
[49,42,37,6],
[49,42,37,7],
[49,42,37,8],
[49,42,37,11],
[49,42,37,12],
[49,42,37,13],
[49,42,38,0],
[49,42,38,1],
[49,42,38,2],
[49,42,38,3],
[49,42,38,4],
[49,42,38,5],
[49,42,38,6],
[49,42,38,8],
[49,42,38,11],
[49,42,39,0],
[49,42,39,1],
[49,42,39,2],
[49,42,39,4],
[49,42,39,7],
[49,43,3,0],
[49,43,3,1],
[49,43,3,2],
[49,43,4,0],
[49,43,4,1],
[49,43,4,2],
[49,43,4,3],
[49,43,5,0],
[49,43,5,1],
[49,43,5,2],
[49,43,5,4],
[49,43,6,0],
[49,43,6,1],
[49,43,6,2],
[49,43,6,4],
[49,43,8,0],
[49,43,8,1],
[49,43,8,2],
[49,43,8,4],
[49,43,10,0],
[49,43,10,1],
[49,43,10,2],
[49,43,10,3],
[49,43,10,4],
[49,43,10,5],
[49,43,10,6],
[49,43,10,8],
[49,43,11,0],
[49,43,11,1],
[49,43,11,2],
[49,43,11,3],
[49,43,11,4],
[49,43,11,5],
[49,43,11,6],
[49,43,11,8],
[49,43,11,10],
[49,43,12,0],
[49,43,12,1],
[49,43,12,2],
[49,43,12,3],
[49,43,12,4],
[49,43,12,5],
[49,43,12,6],
[49,43,12,8],
[49,43,12,10],
[49,43,13,0],
[49,43,13,1],
[49,43,13,2],
[49,43,13,3],
[49,43,13,4],
[49,43,13,5],
[49,43,13,6],
[49,43,13,8],
[49,43,13,10],
[49,43,13,11],
[49,43,13,12],
[49,43,14,0],
[49,43,14,1],
[49,43,14,2],
[49,43,14,3],
[49,43,14,4],
[49,43,14,5],
[49,43,14,6],
[49,43,14,8],
[49,43,14,10],
[49,43,14,11],
[49,43,14,12],
[49,43,16,0],
[49,43,16,1],
[49,43,16,2],
[49,43,16,3],
[49,43,16,4],
[49,43,16,5],
[49,43,16,6],
[49,43,16,8],
[49,43,16,10],
[49,43,16,11],
[49,43,16,12],
[49,43,16,13],
[49,43,16,14],
[49,43,17,0],
[49,43,17,1],
[49,43,17,2],
[49,43,17,3],
[49,43,17,4],
[49,43,17,5],
[49,43,17,6],
[49,43,17,8],
[49,43,17,10],
[49,43,17,11],
[49,43,17,12],
[49,43,17,13],
[49,43,17,14],
[49,43,17,16],
[49,43,19,0],
[49,43,19,1],
[49,43,19,2],
[49,43,19,3],
[49,43,19,4],
[49,43,19,5],
[49,43,19,6],
[49,43,19,8],
[49,43,19,10],
[49,43,19,11],
[49,43,19,12],
[49,43,19,16],
[49,43,19,17],
[49,43,20,0],
[49,43,20,1],
[49,43,20,2],
[49,43,20,3],
[49,43,20,4],
[49,43,20,5],
[49,43,20,6],
[49,43,20,8],
[49,43,20,11],
[49,43,20,12],
[49,43,20,13],
[49,43,20,14],
[49,43,20,16],
[49,43,20,17],
[49,43,20,19],
[49,43,21,0],
[49,43,21,1],
[49,43,21,2],
[49,43,21,4],
[49,43,21,10],
[49,43,21,11],
[49,43,21,12],
[49,43,21,13],
[49,43,21,14],
[49,43,21,19],
[49,43,21,20],
[49,43,22,0],
[49,43,22,1],
[49,43,22,2],
[49,43,22,3],
[49,43,22,4],
[49,43,22,5],
[49,43,22,6],
[49,43,22,8],
[49,43,22,11],
[49,43,22,12],
[49,43,22,13],
[49,43,22,14],
[49,43,22,16],
[49,43,22,17],
[49,43,22,19],
[49,43,22,21],
[49,43,23,0],
[49,43,23,1],
[49,43,23,2],
[49,43,23,3],
[49,43,23,4],
[49,43,23,5],
[49,43,23,6],
[49,43,23,8],
[49,43,23,10],
[49,43,23,11],
[49,43,23,12],
[49,43,23,13],
[49,43,23,16],
[49,43,23,17],
[49,43,23,19],
[49,43,23,20],
[49,43,23,21],
[49,43,23,22],
[49,43,24,0],
[49,43,24,1],
[49,43,24,2],
[49,43,24,3],
[49,43,24,4],
[49,43,24,5],
[49,43,24,6],
[49,43,24,8],
[49,43,24,10],
[49,43,24,11],
[49,43,24,12],
[49,43,24,13],
[49,43,24,17],
[49,43,24,19],
[49,43,24,20],
[49,43,24,21],
[49,43,24,22],
[49,43,24,23],
[49,43,27,0],
[49,43,27,1],
[49,43,27,2],
[49,43,27,3],
[49,43,27,4],
[49,43,27,5],
[49,43,27,8],
[49,43,27,11],
[49,43,27,12],
[49,43,27,13],
[49,43,27,14],
[49,43,27,16],
[49,43,27,17],
[49,43,27,19],
[49,43,27,21],
[49,43,27,23],
[49,43,27,24],
[49,43,29,0],
[49,43,29,1],
[49,43,29,2],
[49,43,29,5],
[49,43,29,6],
[49,43,29,8],
[49,43,29,10],
[49,43,29,13],
[49,43,29,14],
[49,43,29,16],
[49,43,29,17],
[49,43,29,19],
[49,43,29,20],
[49,43,29,21],
[49,43,30,3],
[49,43,30,4],
[49,43,30,5],
[49,43,30,6],
[49,43,30,8],
[49,43,30,10],
[49,43,30,11],
[49,43,30,12],
[49,43,30,13],
[49,43,30,14],
[49,43,30,16],
[49,43,30,17],
[49,43,30,19],
[49,43,30,20],
[49,43,31,0],
[49,43,31,1],
[49,43,31,2],
[49,43,31,3],
[49,43,31,4],
[49,43,31,5],
[49,43,31,6],
[49,43,31,8],
[49,43,31,10],
[49,43,31,11],
[49,43,31,12],
[49,43,31,13],
[49,43,31,14],
[49,43,31,16],
[49,43,31,17],
[49,43,31,19],
[49,43,32,0],
[49,43,32,1],
[49,43,32,2],
[49,43,32,3],
[49,43,32,4],
[49,43,32,5],
[49,43,32,6],
[49,43,32,8],
[49,43,32,10],
[49,43,32,13],
[49,43,32,14],
[49,43,32,16],
[49,43,32,17],
[49,43,33,0],
[49,43,33,1],
[49,43,33,2],
[49,43,33,3],
[49,43,33,4],
[49,43,33,5],
[49,43,33,6],
[49,43,33,8],
[49,43,33,10],
[49,43,33,13],
[49,43,33,14],
[49,43,33,16],
[49,43,33,17],
[49,43,36,0],
[49,43,36,1],
[49,43,36,2],
[49,43,36,3],
[49,43,36,4],
[49,43,36,5],
[49,43,36,6],
[49,43,36,8],
[49,43,36,10],
[49,43,36,11],
[49,43,36,12],
[49,43,37,0],
[49,43,37,1],
[49,43,37,2],
[49,43,37,3],
[49,43,37,5],
[49,43,37,6],
[49,43,37,8],
[49,43,37,10],
[49,43,37,11],
[49,43,39,0],
[49,43,39,1],
[49,43,39,2],
[49,43,39,4],
[49,43,42,0],
[49,43,42,1],
[49,43,42,2],
[49,43,42,3],
[49,43,42,4],
[49,44,3,0],
[49,44,3,1],
[49,44,3,2],
[49,44,4,0],
[49,44,4,1],
[49,44,4,2],
[49,44,4,3],
[49,44,5,0],
[49,44,5,1],
[49,44,5,2],
[49,44,5,4],
[49,44,6,0],
[49,44,6,1],
[49,44,6,2],
[49,44,6,4],
[49,44,7,0],
[49,44,7,1],
[49,44,7,2],
[49,44,7,3],
[49,44,7,4],
[49,44,7,5],
[49,44,7,6],
[49,44,8,0],
[49,44,8,1],
[49,44,8,2],
[49,44,8,4],
[49,44,8,7],
[49,44,10,0],
[49,44,10,1],
[49,44,10,2],
[49,44,10,3],
[49,44,10,4],
[49,44,10,5],
[49,44,10,6],
[49,44,10,7],
[49,44,10,8],
[49,44,11,0],
[49,44,11,1],
[49,44,11,2],
[49,44,11,3],
[49,44,11,4],
[49,44,11,5],
[49,44,11,6],
[49,44,11,7],
[49,44,11,8],
[49,44,11,10],
[49,44,12,0],
[49,44,12,1],
[49,44,12,2],
[49,44,12,3],
[49,44,12,4],
[49,44,12,5],
[49,44,12,6],
[49,44,12,7],
[49,44,12,8],
[49,44,12,10],
[49,44,13,0],
[49,44,13,1],
[49,44,13,2],
[49,44,13,3],
[49,44,13,4],
[49,44,13,5],
[49,44,13,6],
[49,44,13,7],
[49,44,13,8],
[49,44,13,10],
[49,44,13,11],
[49,44,13,12],
[49,44,14,0],
[49,44,14,1],
[49,44,14,2],
[49,44,14,3],
[49,44,14,4],
[49,44,14,5],
[49,44,14,6],
[49,44,14,7],
[49,44,14,8],
[49,44,14,10],
[49,44,14,11],
[49,44,14,12],
[49,44,16,0],
[49,44,16,1],
[49,44,16,2],
[49,44,16,3],
[49,44,16,4],
[49,44,16,5],
[49,44,16,6],
[49,44,16,7],
[49,44,16,8],
[49,44,16,10],
[49,44,16,11],
[49,44,16,12],
[49,44,16,13],
[49,44,16,14],
[49,44,19,0],
[49,44,19,1],
[49,44,19,2],
[49,44,19,3],
[49,44,19,4],
[49,44,19,5],
[49,44,19,6],
[49,44,19,7],
[49,44,19,8],
[49,44,19,10],
[49,44,19,11],
[49,44,19,12],
[49,44,19,16],
[49,44,20,0],
[49,44,20,1],
[49,44,20,2],
[49,44,20,3],
[49,44,20,4],
[49,44,20,5],
[49,44,20,6],
[49,44,20,7],
[49,44,20,8],
[49,44,20,11],
[49,44,20,12],
[49,44,20,13],
[49,44,20,14],
[49,44,20,19],
[49,44,21,0],
[49,44,21,1],
[49,44,21,2],
[49,44,21,4],
[49,44,21,7],
[49,44,21,10],
[49,44,21,11],
[49,44,21,12],
[49,44,21,13],
[49,44,21,14],
[49,44,21,16],
[49,44,21,19],
[49,44,21,20],
[49,44,22,0],
[49,44,22,1],
[49,44,22,2],
[49,44,22,3],
[49,44,22,4],
[49,44,22,5],
[49,44,22,6],
[49,44,22,7],
[49,44,22,8],
[49,44,22,11],
[49,44,22,12],
[49,44,22,13],
[49,44,22,16],
[49,44,22,19],
[49,44,22,21],
[49,44,23,0],
[49,44,23,1],
[49,44,23,2],
[49,44,23,3],
[49,44,23,4],
[49,44,23,5],
[49,44,23,6],
[49,44,23,7],
[49,44,23,8],
[49,44,23,10],
[49,44,23,11],
[49,44,23,14],
[49,44,23,16],
[49,44,23,19],
[49,44,23,20],
[49,44,23,21],
[49,44,23,22],
[49,44,24,0],
[49,44,24,1],
[49,44,24,2],
[49,44,24,3],
[49,44,24,4],
[49,44,24,5],
[49,44,24,6],
[49,44,24,7],
[49,44,24,8],
[49,44,24,10],
[49,44,24,11],
[49,44,24,14],
[49,44,24,19],
[49,44,24,20],
[49,44,24,21],
[49,44,24,22],
[49,44,24,23],
[49,44,25,0],
[49,44,25,1],
[49,44,25,2],
[49,44,25,3],
[49,44,25,4],
[49,44,25,5],
[49,44,25,6],
[49,44,25,10],
[49,44,25,11],
[49,44,25,12],
[49,44,25,13],
[49,44,25,14],
[49,44,25,16],
[49,44,25,19],
[49,44,25,20],
[49,44,25,21],
[49,44,25,22],
[49,44,25,23],
[49,44,25,24],
[49,44,27,0],
[49,44,27,1],
[49,44,27,2],
[49,44,27,3],
[49,44,27,4],
[49,44,27,6],
[49,44,27,7],
[49,44,27,8],
[49,44,27,11],
[49,44,27,12],
[49,44,27,13],
[49,44,27,14],
[49,44,27,16],
[49,44,27,19],
[49,44,27,21],
[49,44,28,0],
[49,44,28,1],
[49,44,28,2],
[49,44,28,5],
[49,44,28,6],
[49,44,28,8],
[49,44,28,10],
[49,44,28,11],
[49,44,28,12],
[49,44,28,13],
[49,44,28,14],
[49,44,28,16],
[49,44,28,19],
[49,44,28,20],
[49,44,28,21],
[49,44,29,3],
[49,44,29,4],
[49,44,29,5],
[49,44,29,6],
[49,44,29,7],
[49,44,29,8],
[49,44,29,10],
[49,44,29,13],
[49,44,29,14],
[49,44,29,16],
[49,44,29,19],
[49,44,29,20],
[49,44,30,0],
[49,44,30,1],
[49,44,30,2],
[49,44,30,3],
[49,44,30,4],
[49,44,30,5],
[49,44,30,6],
[49,44,30,7],
[49,44,30,8],
[49,44,30,10],
[49,44,30,11],
[49,44,30,12],
[49,44,30,13],
[49,44,30,14],
[49,44,30,16],
[49,44,30,19],
[49,44,31,0],
[49,44,31,1],
[49,44,31,2],
[49,44,31,3],
[49,44,31,4],
[49,44,31,5],
[49,44,31,6],
[49,44,31,7],
[49,44,31,8],
[49,44,31,10],
[49,44,31,11],
[49,44,31,12],
[49,44,31,13],
[49,44,31,14],
[49,44,31,16],
[49,44,32,0],
[49,44,32,1],
[49,44,32,2],
[49,44,32,3],
[49,44,32,4],
[49,44,32,5],
[49,44,32,6],
[49,44,32,7],
[49,44,32,8],
[49,44,32,10],
[49,44,32,13],
[49,44,32,14],
[49,44,32,16],
[49,44,33,0],
[49,44,33,1],
[49,44,33,2],
[49,44,33,3],
[49,44,33,4],
[49,44,33,5],
[49,44,33,6],
[49,44,33,7],
[49,44,33,8],
[49,44,33,10],
[49,44,33,13],
[49,44,33,14],
[49,44,34,0],
[49,44,34,1],
[49,44,34,2],
[49,44,34,3],
[49,44,34,4],
[49,44,34,5],
[49,44,34,6],
[49,44,34,8],
[49,44,34,10],
[49,44,34,11],
[49,44,34,12],
[49,44,34,13],
[49,44,34,14],
[49,44,36,0],
[49,44,36,1],
[49,44,36,2],
[49,44,36,3],
[49,44,36,4],
[49,44,36,5],
[49,44,36,6],
[49,44,36,7],
[49,44,36,8],
[49,44,36,10],
[49,44,36,11],
[49,44,37,0],
[49,44,37,1],
[49,44,37,2],
[49,44,37,3],
[49,44,37,5],
[49,44,37,6],
[49,44,37,7],
[49,44,37,8],
[49,44,38,0],
[49,44,38,1],
[49,44,38,2],
[49,44,38,3],
[49,44,38,4],
[49,44,38,5],
[49,44,38,6],
[49,44,39,0],
[49,44,39,1],
[49,44,39,2],
[49,44,39,4],
[49,44,42,0],
[49,44,42,1],
[49,44,42,2],
[49,47,3,0],
[49,47,3,1],
[49,47,3,2],
[49,47,4,0],
[49,47,4,1],
[49,47,4,2],
[49,47,4,3],
[49,47,5,0],
[49,47,5,1],
[49,47,5,2],
[49,47,5,4],
[49,47,6,0],
[49,47,6,1],
[49,47,6,2],
[49,47,6,4],
[49,47,7,0],
[49,47,7,1],
[49,47,7,2],
[49,47,7,3],
[49,47,7,4],
[49,47,7,5],
[49,47,7,6],
[49,47,8,0],
[49,47,8,1],
[49,47,8,2],
[49,47,8,4],
[49,47,8,7],
[49,47,10,0],
[49,47,10,1],
[49,47,10,2],
[49,47,10,3],
[49,47,10,4],
[49,47,10,5],
[49,47,10,6],
[49,47,10,7],
[49,47,10,8],
[49,47,11,0],
[49,47,11,1],
[49,47,11,2],
[49,47,11,3],
[49,47,11,4],
[49,47,11,5],
[49,47,11,6],
[49,47,11,7],
[49,47,11,8],
[49,47,11,10],
[49,47,12,0],
[49,47,12,1],
[49,47,12,2],
[49,47,12,3],
[49,47,12,4],
[49,47,12,5],
[49,47,12,6],
[49,47,12,7],
[49,47,12,8],
[49,47,12,10],
[49,47,13,0],
[49,47,13,1],
[49,47,13,2],
[49,47,13,3],
[49,47,13,4],
[49,47,13,5],
[49,47,13,6],
[49,47,13,7],
[49,47,13,8],
[49,47,13,10],
[49,47,13,11],
[49,47,13,12],
[49,47,14,0],
[49,47,14,1],
[49,47,14,2],
[49,47,14,3],
[49,47,14,4],
[49,47,14,5],
[49,47,14,6],
[49,47,14,7],
[49,47,14,8],
[49,47,14,10],
[49,47,14,11],
[49,47,14,12],
[49,47,16,0],
[49,47,16,1],
[49,47,16,2],
[49,47,16,3],
[49,47,16,4],
[49,47,16,5],
[49,47,16,6],
[49,47,16,7],
[49,47,16,8],
[49,47,16,10],
[49,47,16,11],
[49,47,16,12],
[49,47,16,13],
[49,47,16,14],
[49,47,17,0],
[49,47,17,1],
[49,47,17,2],
[49,47,17,3],
[49,47,17,4],
[49,47,17,5],
[49,47,17,6],
[49,47,17,7],
[49,47,17,8],
[49,47,17,10],
[49,47,17,11],
[49,47,17,12],
[49,47,17,13],
[49,47,17,14],
[49,47,19,0],
[49,47,19,1],
[49,47,19,2],
[49,47,19,3],
[49,47,19,4],
[49,47,19,5],
[49,47,19,6],
[49,47,19,7],
[49,47,19,8],
[49,47,19,10],
[49,47,19,11],
[49,47,19,12],
[49,47,19,16],
[49,47,19,17],
[49,47,20,0],
[49,47,20,1],
[49,47,20,2],
[49,47,20,3],
[49,47,20,4],
[49,47,20,5],
[49,47,20,6],
[49,47,20,7],
[49,47,20,8],
[49,47,20,11],
[49,47,20,14],
[49,47,20,16],
[49,47,20,17],
[49,47,20,19],
[49,47,21,0],
[49,47,21,1],
[49,47,21,2],
[49,47,21,4],
[49,47,21,7],
[49,47,21,12],
[49,47,21,13],
[49,47,21,14],
[49,47,21,16],
[49,47,21,17],
[49,47,21,19],
[49,47,21,20],
[49,47,22,0],
[49,47,22,1],
[49,47,22,2],
[49,47,22,3],
[49,47,22,4],
[49,47,22,5],
[49,47,22,6],
[49,47,22,7],
[49,47,22,11],
[49,47,22,12],
[49,47,22,13],
[49,47,22,14],
[49,47,22,16],
[49,47,22,17],
[49,47,22,19],
[49,47,22,21],
[49,47,24,0],
[49,47,24,1],
[49,47,24,2],
[49,47,24,3],
[49,47,24,4],
[49,47,24,5],
[49,47,24,8],
[49,47,24,10],
[49,47,24,11],
[49,47,24,12],
[49,47,24,13],
[49,47,24,14],
[49,47,24,17],
[49,47,24,19],
[49,47,24,20],
[49,47,24,21],
[49,47,24,22],
[49,47,25,0],
[49,47,25,1],
[49,47,25,2],
[49,47,25,5],
[49,47,25,6],
[49,47,25,8],
[49,47,25,10],
[49,47,25,11],
[49,47,25,12],
[49,47,25,13],
[49,47,25,14],
[49,47,25,16],
[49,47,25,17],
[49,47,25,19],
[49,47,25,20],
[49,47,25,21],
[49,47,27,0],
[49,47,27,1],
[49,47,27,2],
[49,47,27,3],
[49,47,27,4],
[49,47,27,5],
[49,47,27,6],
[49,47,27,7],
[49,47,27,8],
[49,47,27,11],
[49,47,27,12],
[49,47,27,13],
[49,47,27,14],
[49,47,27,16],
[49,47,27,17],
[49,47,27,19],
[49,47,28,0],
[49,47,28,1],
[49,47,28,2],
[49,47,28,3],
[49,47,28,4],
[49,47,28,5],
[49,47,28,6],
[49,47,28,8],
[49,47,28,10],
[49,47,28,11],
[49,47,28,12],
[49,47,28,13],
[49,47,28,14],
[49,47,28,16],
[49,47,28,17],
[49,47,29,0],
[49,47,29,1],
[49,47,29,2],
[49,47,29,3],
[49,47,29,4],
[49,47,29,5],
[49,47,29,6],
[49,47,29,7],
[49,47,29,8],
[49,47,29,10],
[49,47,29,13],
[49,47,29,14],
[49,47,29,16],
[49,47,29,17],
[49,47,32,0],
[49,47,32,1],
[49,47,32,2],
[49,47,32,3],
[49,47,32,4],
[49,47,32,5],
[49,47,32,6],
[49,47,32,7],
[49,47,32,8],
[49,47,32,10],
[49,47,32,13],
[49,47,33,0],
[49,47,33,1],
[49,47,33,2],
[49,47,33,3],
[49,47,33,4],
[49,47,33,5],
[49,47,33,6],
[49,47,33,7],
[49,47,33,8],
[49,47,33,10],
[49,47,34,0],
[49,47,34,1],
[49,47,34,2],
[49,47,34,3],
[49,47,34,4],
[49,47,34,5],
[49,47,34,6],
[49,47,34,8],
[49,47,36,0],
[49,47,36,1],
[49,47,36,2],
[49,47,36,3],
[49,47,36,4],
[49,47,36,5],
[49,47,37,0],
[49,47,37,1],
[49,47,37,2],
[49,47,37,3],
[49,47,38,0],
[49,47,38,1],
[49,47,38,2],
[49,48,3,0],
[49,48,3,1],
[49,48,3,2],
[49,48,4,0],
[49,48,4,1],
[49,48,4,2],
[49,48,4,3],
[49,48,5,0],
[49,48,5,1],
[49,48,5,2],
[49,48,5,4],
[49,48,6,0],
[49,48,6,1],
[49,48,6,2],
[49,48,6,4],
[49,48,7,0],
[49,48,7,1],
[49,48,7,2],
[49,48,7,3],
[49,48,7,4],
[49,48,7,5],
[49,48,7,6],
[49,48,8,0],
[49,48,8,1],
[49,48,8,2],
[49,48,8,4],
[49,48,8,7],
[49,48,10,0],
[49,48,10,1],
[49,48,10,2],
[49,48,10,3],
[49,48,10,4],
[49,48,10,5],
[49,48,10,6],
[49,48,10,7],
[49,48,10,8],
[49,48,13,0],
[49,48,13,1],
[49,48,13,2],
[49,48,13,3],
[49,48,13,4],
[49,48,13,5],
[49,48,13,6],
[49,48,13,7],
[49,48,13,8],
[49,48,13,10],
[49,48,14,0],
[49,48,14,1],
[49,48,14,2],
[49,48,14,3],
[49,48,14,4],
[49,48,14,5],
[49,48,14,6],
[49,48,14,7],
[49,48,14,8],
[49,48,14,10],
[49,48,16,0],
[49,48,16,1],
[49,48,16,2],
[49,48,16,3],
[49,48,16,4],
[49,48,16,5],
[49,48,16,6],
[49,48,16,7],
[49,48,16,8],
[49,48,16,10],
[49,48,16,13],
[49,48,16,14],
[49,48,17,0],
[49,48,17,1],
[49,48,17,2],
[49,48,17,3],
[49,48,17,4],
[49,48,17,5],
[49,48,17,6],
[49,48,17,7],
[49,48,17,8],
[49,48,17,10],
[49,48,17,13],
[49,48,17,14],
[49,48,17,16],
[49,48,19,0],
[49,48,19,1],
[49,48,19,2],
[49,48,19,3],
[49,48,19,4],
[49,48,19,5],
[49,48,19,6],
[49,48,19,7],
[49,48,19,8],
[49,48,19,10],
[49,48,19,16],
[49,48,19,17],
[49,48,20,0],
[49,48,20,1],
[49,48,20,2],
[49,48,20,3],
[49,48,20,4],
[49,48,20,5],
[49,48,20,6],
[49,48,20,7],
[49,48,20,8],
[49,48,20,13],
[49,48,20,14],
[49,48,20,16],
[49,48,20,17],
[49,48,20,19],
[49,48,21,0],
[49,48,21,1],
[49,48,21,2],
[49,48,21,4],
[49,48,21,7],
[49,48,21,10],
[49,48,21,13],
[49,48,21,14],
[49,48,21,16],
[49,48,21,17],
[49,48,21,19],
[49,48,21,20],
[49,48,22,0],
[49,48,22,1],
[49,48,22,2],
[49,48,22,3],
[49,48,22,4],
[49,48,22,5],
[49,48,22,8],
[49,48,22,13],
[49,48,22,14],
[49,48,22,16],
[49,48,22,17],
[49,48,22,19],
[49,48,22,21],
[49,48,23,0],
[49,48,23,1],
[49,48,23,2],
[49,48,23,3],
[49,48,23,4],
[49,48,23,6],
[49,48,23,7],
[49,48,23,8],
[49,48,23,10],
[49,48,23,13],
[49,48,23,14],
[49,48,23,16],
[49,48,23,17],
[49,48,23,19],
[49,48,23,20],
[49,48,23,21],
[49,48,23,22],
[49,48,24,0],
[49,48,24,1],
[49,48,24,2],
[49,48,24,3],
[49,48,24,4],
[49,48,24,6],
[49,48,24,7],
[49,48,24,8],
[49,48,24,10],
[49,48,24,13],
[49,48,24,14],
[49,48,24,17],
[49,48,24,19],
[49,48,24,20],
[49,48,24,21],
[49,48,24,22],
[49,48,25,3],
[49,48,25,4],
[49,48,25,5],
[49,48,25,6],
[49,48,25,8],
[49,48,25,10],
[49,48,25,13],
[49,48,25,14],
[49,48,25,16],
[49,48,25,17],
[49,48,25,19],
[49,48,25,20],
[49,48,27,0],
[49,48,27,1],
[49,48,27,2],
[49,48,27,3],
[49,48,27,4],
[49,48,27,5],
[49,48,27,6],
[49,48,27,7],
[49,48,27,8],
[49,48,27,13],
[49,48,27,14],
[49,48,27,16],
[49,48,27,17],
[49,48,28,0],
[49,48,28,1],
[49,48,28,2],
[49,48,28,3],
[49,48,28,4],
[49,48,28,5],
[49,48,28,6],
[49,48,28,8],
[49,48,28,10],
[49,48,28,13],
[49,48,28,14],
[49,48,28,16],
[49,48,28,17],
[49,48,30,0],
[49,48,30,1],
[49,48,30,2],
[49,48,30,3],
[49,48,30,4],
[49,48,30,5],
[49,48,30,6],
[49,48,30,7],
[49,48,30,8],
[49,48,30,10],
[49,48,30,13],
[49,48,30,14],
[49,48,31,0],
[49,48,31,1],
[49,48,31,2],
[49,48,31,3],
[49,48,31,4],
[49,48,31,5],
[49,48,31,6],
[49,48,31,7],
[49,48,31,8],
[49,48,31,10],
[49,48,31,13],
[49,48,34,0],
[49,48,34,1],
[49,48,34,2],
[49,48,34,3],
[49,48,34,4],
[49,48,34,5],
[49,48,34,6],
[49,48,36,0],
[49,48,36,1],
[49,48,36,2],
[49,48,36,3],
[49,48,36,4],
[49,48,37,0],
[49,48,37,1],
[49,48,37,2],
[50,4,3,0],
[50,4,3,1],
[50,4,3,2],
[50,5,4,0],
[50,5,4,1],
[50,5,4,2],
[50,6,4,0],
[50,6,4,1],
[50,6,4,2],
[50,7,3,0],
[50,7,3,1],
[50,7,3,2],
[50,7,4,0],
[50,7,4,1],
[50,7,4,2],
[50,7,4,3],
[50,7,5,0],
[50,7,5,1],
[50,7,5,2],
[50,7,5,4],
[50,7,6,0],
[50,7,6,1],
[50,7,6,2],
[50,7,6,4],
[50,8,4,0],
[50,8,4,1],
[50,8,4,2],
[50,8,7,0],
[50,8,7,1],
[50,8,7,2],
[50,8,7,4],
[50,10,3,0],
[50,10,3,1],
[50,10,3,2],
[50,10,4,0],
[50,10,4,1],
[50,10,4,2],
[50,10,4,3],
[50,10,5,0],
[50,10,5,1],
[50,10,5,2],
[50,10,5,4],
[50,10,6,0],
[50,10,6,1],
[50,10,6,2],
[50,10,6,4],
[50,10,7,0],
[50,10,7,1],
[50,10,7,2],
[50,10,7,3],
[50,10,7,4],
[50,10,7,5],
[50,10,7,6],
[50,10,8,0],
[50,10,8,1],
[50,10,8,2],
[50,10,8,4],
[50,10,8,7],
[50,11,3,0],
[50,11,3,1],
[50,11,3,2],
[50,11,4,0],
[50,11,4,1],
[50,11,4,2],
[50,11,4,3],
[50,11,5,0],
[50,11,5,1],
[50,11,5,2],
[50,11,5,4],
[50,11,6,0],
[50,11,6,1],
[50,11,6,2],
[50,11,6,4],
[50,11,7,0],
[50,11,7,1],
[50,11,7,2],
[50,11,7,3],
[50,11,7,4],
[50,11,7,5],
[50,11,7,6],
[50,11,8,0],
[50,11,8,1],
[50,11,8,2],
[50,11,8,4],
[50,11,8,7],
[50,11,10,0],
[50,11,10,1],
[50,11,10,2],
[50,11,10,3],
[50,11,10,4],
[50,11,10,5],
[50,11,10,6],
[50,11,10,7],
[50,11,10,8],
[50,12,3,0],
[50,12,3,1],
[50,12,3,2],
[50,12,4,0],
[50,12,4,1],
[50,12,4,2],
[50,12,4,3],
[50,12,5,0],
[50,12,5,1],
[50,12,5,2],
[50,12,5,4],
[50,12,6,0],
[50,12,6,1],
[50,12,6,2],
[50,12,6,4],
[50,12,7,0],
[50,12,7,1],
[50,12,7,2],
[50,12,7,3],
[50,12,7,4],
[50,12,7,5],
[50,12,7,6],
[50,12,8,0],
[50,12,8,1],
[50,12,8,2],
[50,12,8,4],
[50,12,8,7],
[50,12,10,0],
[50,12,10,1],
[50,12,10,2],
[50,12,10,3],
[50,12,10,4],
[50,12,10,5],
[50,12,10,6],
[50,12,10,7],
[50,12,10,8],
[50,13,3,0],
[50,13,3,1],
[50,13,3,2],
[50,13,4,0],
[50,13,4,1],
[50,13,4,2],
[50,13,4,3],
[50,13,5,0],
[50,13,5,1],
[50,13,5,2],
[50,13,5,4],
[50,13,6,0],
[50,13,6,1],
[50,13,6,2],
[50,13,6,4],
[50,13,7,0],
[50,13,7,1],
[50,13,7,2],
[50,13,7,3],
[50,13,7,4],
[50,13,7,5],
[50,13,7,6],
[50,13,8,0],
[50,13,8,1],
[50,13,8,2],
[50,13,8,4],
[50,13,8,7],
[50,13,10,0],
[50,13,10,1],
[50,13,10,2],
[50,13,10,3],
[50,13,10,4],
[50,13,10,5],
[50,13,10,6],
[50,13,10,7],
[50,13,10,8],
[50,13,11,0],
[50,13,11,1],
[50,13,11,2],
[50,13,11,3],
[50,13,11,4],
[50,13,11,5],
[50,13,11,6],
[50,13,11,7],
[50,13,11,8],
[50,13,11,10],
[50,13,12,0],
[50,13,12,1],
[50,13,12,2],
[50,13,12,3],
[50,13,12,4],
[50,13,12,5],
[50,13,12,6],
[50,13,12,7],
[50,13,12,8],
[50,13,12,10],
[50,14,3,0],
[50,14,3,1],
[50,14,3,2],
[50,14,4,0],
[50,14,4,1],
[50,14,4,2],
[50,14,4,3],
[50,14,5,0],
[50,14,5,1],
[50,14,5,2],
[50,14,5,4],
[50,14,6,0],
[50,14,6,1],
[50,14,6,2],
[50,14,6,4],
[50,14,7,0],
[50,14,7,1],
[50,14,7,2],
[50,14,7,3],
[50,14,7,4],
[50,14,7,5],
[50,14,7,6],
[50,14,8,0],
[50,14,8,1],
[50,14,8,2],
[50,14,8,4],
[50,14,8,7],
[50,14,10,0],
[50,14,10,1],
[50,14,10,2],
[50,14,10,3],
[50,14,10,4],
[50,14,10,5],
[50,14,10,6],
[50,14,10,7],
[50,14,10,8],
[50,14,11,0],
[50,14,11,1],
[50,14,11,2],
[50,14,11,3],
[50,14,11,4],
[50,14,11,5],
[50,14,11,6],
[50,14,11,7],
[50,14,11,8],
[50,14,11,10],
[50,14,12,0],
[50,14,12,1],
[50,14,12,2],
[50,14,12,3],
[50,14,12,4],
[50,14,12,5],
[50,14,12,6],
[50,14,12,7],
[50,14,12,8],
[50,14,12,10],
[50,16,3,0],
[50,16,3,1],
[50,16,3,2],
[50,16,4,0],
[50,16,4,1],
[50,16,4,2],
[50,16,4,3],
[50,16,5,0],
[50,16,5,1],
[50,16,5,2],
[50,16,5,4],
[50,16,6,0],
[50,16,6,1],
[50,16,6,2],
[50,16,6,4],
[50,16,7,0],
[50,16,7,1],
[50,16,7,2],
[50,16,7,3],
[50,16,7,4],
[50,16,7,5],
[50,16,7,6],
[50,16,8,0],
[50,16,8,1],
[50,16,8,2],
[50,16,8,4],
[50,16,8,7],
[50,16,10,0],
[50,16,10,1],
[50,16,10,2],
[50,16,10,3],
[50,16,10,4],
[50,16,10,5],
[50,16,10,6],
[50,16,10,7],
[50,16,10,8],
[50,16,11,0],
[50,16,11,1],
[50,16,11,2],
[50,16,11,3],
[50,16,11,4],
[50,16,11,5],
[50,16,11,6],
[50,16,11,7],
[50,16,11,8],
[50,16,11,10],
[50,16,12,0],
[50,16,12,1],
[50,16,12,2],
[50,16,12,3],
[50,16,12,4],
[50,16,12,5],
[50,16,12,6],
[50,16,12,7],
[50,16,12,8],
[50,16,12,10],
[50,16,13,0],
[50,16,13,1],
[50,16,13,2],
[50,16,13,3],
[50,16,13,4],
[50,16,13,5],
[50,16,13,6],
[50,16,13,7],
[50,16,13,8],
[50,16,13,10],
[50,16,13,11],
[50,16,13,12],
[50,16,14,0],
[50,16,14,1],
[50,16,14,2],
[50,16,14,3],
[50,16,14,4],
[50,16,14,5],
[50,16,14,6],
[50,16,14,7],
[50,16,14,8],
[50,16,14,10],
[50,16,14,11],
[50,16,14,12],
[50,17,3,0],
[50,17,3,1],
[50,17,3,2],
[50,17,4,0],
[50,17,4,1],
[50,17,4,2],
[50,17,4,3],
[50,17,5,0],
[50,17,5,1],
[50,17,5,2],
[50,17,5,4],
[50,17,6,0],
[50,17,6,1],
[50,17,6,2],
[50,17,6,4],
[50,17,7,0],
[50,17,7,1],
[50,17,7,2],
[50,17,7,3],
[50,17,7,4],
[50,17,7,5],
[50,17,7,6],
[50,17,8,0],
[50,17,8,1],
[50,17,8,2],
[50,17,8,4],
[50,17,8,7],
[50,17,10,0],
[50,17,10,1],
[50,17,10,2],
[50,17,10,3],
[50,17,10,4],
[50,17,10,5],
[50,17,10,6],
[50,17,10,7],
[50,17,10,8],
[50,17,11,0],
[50,17,11,1],
[50,17,11,2],
[50,17,11,3],
[50,17,11,4],
[50,17,11,5],
[50,17,11,6],
[50,17,11,7],
[50,17,11,8],
[50,17,11,10],
[50,17,12,0],
[50,17,12,1],
[50,17,12,2],
[50,17,12,3],
[50,17,12,4],
[50,17,12,5],
[50,17,12,6],
[50,17,12,7],
[50,17,12,8],
[50,17,12,10],
[50,17,13,0],
[50,17,13,1],
[50,17,13,2],
[50,17,13,3],
[50,17,13,4],
[50,17,13,5],
[50,17,13,6],
[50,17,13,7],
[50,17,13,8],
[50,17,13,10],
[50,17,13,11],
[50,17,13,12],
[50,17,14,0],
[50,17,14,1],
[50,17,14,2],
[50,17,14,3],
[50,17,14,4],
[50,17,14,5],
[50,17,14,6],
[50,17,14,7],
[50,17,14,8],
[50,17,14,10],
[50,17,14,11],
[50,17,14,12],
[50,17,16,0],
[50,17,16,1],
[50,17,16,2],
[50,17,16,3],
[50,17,16,4],
[50,17,16,5],
[50,17,16,6],
[50,17,16,7],
[50,17,16,8],
[50,17,16,10],
[50,17,16,11],
[50,17,16,12],
[50,17,16,13],
[50,17,16,14],
[50,19,3,0],
[50,19,3,1],
[50,19,3,2],
[50,19,4,0],
[50,19,4,1],
[50,19,4,2],
[50,19,4,3],
[50,19,5,0],
[50,19,5,1],
[50,19,5,2],
[50,19,5,4],
[50,19,6,0],
[50,19,6,1],
[50,19,6,2],
[50,19,6,4],
[50,19,7,0],
[50,19,7,1],
[50,19,7,2],
[50,19,7,3],
[50,19,7,4],
[50,19,7,5],
[50,19,7,6],
[50,19,8,0],
[50,19,8,1],
[50,19,8,2],
[50,19,8,4],
[50,19,8,7],
[50,19,10,0],
[50,19,10,1],
[50,19,10,2],
[50,19,10,3],
[50,19,10,4],
[50,19,10,5],
[50,19,10,6],
[50,19,10,7],
[50,19,10,8],
[50,19,11,0],
[50,19,11,1],
[50,19,11,2],
[50,19,11,3],
[50,19,11,4],
[50,19,11,5],
[50,19,11,6],
[50,19,11,7],
[50,19,11,8],
[50,19,11,10],
[50,19,12,0],
[50,19,12,1],
[50,19,12,2],
[50,19,12,3],
[50,19,12,4],
[50,19,12,5],
[50,19,12,6],
[50,19,12,7],
[50,19,12,8],
[50,19,12,10],
[50,19,16,0],
[50,19,16,1],
[50,19,16,2],
[50,19,16,3],
[50,19,16,4],
[50,19,16,5],
[50,19,16,6],
[50,19,16,7],
[50,19,16,8],
[50,19,16,10],
[50,19,16,11],
[50,19,16,12],
[50,19,17,0],
[50,19,17,1],
[50,19,17,2],
[50,19,17,3],
[50,19,17,4],
[50,19,17,5],
[50,19,17,6],
[50,19,17,7],
[50,19,17,8],
[50,19,17,10],
[50,19,17,11],
[50,19,17,12],
[50,19,17,16],
[50,20,3,0],
[50,20,3,1],
[50,20,3,2],
[50,20,4,0],
[50,20,4,1],
[50,20,4,2],
[50,20,4,3],
[50,20,5,0],
[50,20,5,1],
[50,20,5,2],
[50,20,5,4],
[50,20,6,0],
[50,20,6,1],
[50,20,6,2],
[50,20,6,4],
[50,20,7,0],
[50,20,7,1],
[50,20,7,2],
[50,20,7,3],
[50,20,7,4],
[50,20,7,5],
[50,20,7,6],
[50,20,8,0],
[50,20,8,1],
[50,20,8,2],
[50,20,8,4],
[50,20,8,7],
[50,20,11,0],
[50,20,11,1],
[50,20,11,2],
[50,20,11,3],
[50,20,11,4],
[50,20,11,5],
[50,20,11,6],
[50,20,11,7],
[50,20,11,8],
[50,20,12,0],
[50,20,12,1],
[50,20,12,2],
[50,20,12,3],
[50,20,12,4],
[50,20,12,5],
[50,20,12,6],
[50,20,12,7],
[50,20,12,8],
[50,20,13,0],
[50,20,13,1],
[50,20,13,2],
[50,20,13,3],
[50,20,13,4],
[50,20,13,5],
[50,20,13,6],
[50,20,13,7],
[50,20,13,8],
[50,20,13,11],
[50,20,13,12],
[50,20,14,0],
[50,20,14,1],
[50,20,14,2],
[50,20,14,3],
[50,20,14,4],
[50,20,14,5],
[50,20,14,6],
[50,20,14,7],
[50,20,14,8],
[50,20,14,11],
[50,20,14,12],
[50,20,16,0],
[50,20,16,1],
[50,20,16,2],
[50,20,16,3],
[50,20,16,4],
[50,20,16,5],
[50,20,16,6],
[50,20,16,7],
[50,20,16,8],
[50,20,16,11],
[50,20,16,12],
[50,20,16,13],
[50,20,16,14],
[50,20,17,0],
[50,20,17,1],
[50,20,17,2],
[50,20,17,3],
[50,20,17,4],
[50,20,17,5],
[50,20,17,6],
[50,20,17,7],
[50,20,17,8],
[50,20,17,11],
[50,20,17,12],
[50,20,17,13],
[50,20,17,14],
[50,20,17,16],
[50,20,19,0],
[50,20,19,1],
[50,20,19,2],
[50,20,19,3],
[50,20,19,4],
[50,20,19,5],
[50,20,19,6],
[50,20,19,7],
[50,20,19,8],
[50,20,19,11],
[50,20,19,12],
[50,20,19,16],
[50,20,19,17],
[50,21,4,0],
[50,21,4,1],
[50,21,4,2],
[50,21,7,0],
[50,21,7,1],
[50,21,7,2],
[50,21,7,4],
[50,21,10,0],
[50,21,10,1],
[50,21,10,2],
[50,21,10,4],
[50,21,10,7],
[50,21,11,0],
[50,21,11,1],
[50,21,11,2],
[50,21,11,4],
[50,21,11,7],
[50,21,11,10],
[50,21,12,0],
[50,21,12,1],
[50,21,12,2],
[50,21,12,4],
[50,21,12,7],
[50,21,12,10],
[50,21,13,0],
[50,21,13,1],
[50,21,13,2],
[50,21,13,4],
[50,21,13,7],
[50,21,13,10],
[50,21,13,11],
[50,21,13,12],
[50,21,14,0],
[50,21,14,1],
[50,21,14,2],
[50,21,14,4],
[50,21,14,7],
[50,21,14,10],
[50,21,14,11],
[50,21,14,12],
[50,21,16,0],
[50,21,16,1],
[50,21,16,2],
[50,21,16,4],
[50,21,16,7],
[50,21,16,10],
[50,21,16,11],
[50,21,16,12],
[50,21,16,13],
[50,21,16,14],
[50,21,17,0],
[50,21,17,1],
[50,21,17,2],
[50,21,17,4],
[50,21,17,7],
[50,21,17,10],
[50,21,17,11],
[50,21,17,12],
[50,21,17,13],
[50,21,17,14],
[50,21,17,16],
[50,21,19,0],
[50,21,19,1],
[50,21,19,2],
[50,21,19,4],
[50,21,19,7],
[50,21,19,10],
[50,21,19,11],
[50,21,19,12],
[50,21,19,16],
[50,21,19,17],
[50,21,20,0],
[50,21,20,1],
[50,21,20,2],
[50,21,20,4],
[50,21,20,7],
[50,21,20,11],
[50,21,20,12],
[50,21,20,13],
[50,21,20,14],
[50,21,20,16],
[50,21,20,17],
[50,21,20,19],
[50,22,3,0],
[50,22,3,1],
[50,22,3,2],
[50,22,4,0],
[50,22,4,1],
[50,22,4,2],
[50,22,4,3],
[50,22,5,0],
[50,22,5,1],
[50,22,5,2],
[50,22,5,4],
[50,22,6,0],
[50,22,6,1],
[50,22,6,2],
[50,22,6,4],
[50,22,7,0],
[50,22,7,1],
[50,22,7,2],
[50,22,7,3],
[50,22,7,4],
[50,22,7,5],
[50,22,7,6],
[50,22,8,0],
[50,22,8,1],
[50,22,8,2],
[50,22,8,4],
[50,22,8,7],
[50,22,11,0],
[50,22,11,1],
[50,22,11,2],
[50,22,11,3],
[50,22,11,4],
[50,22,11,5],
[50,22,11,6],
[50,22,11,7],
[50,22,11,8],
[50,22,12,0],
[50,22,12,1],
[50,22,12,2],
[50,22,12,3],
[50,22,12,4],
[50,22,12,5],
[50,22,12,6],
[50,22,12,7],
[50,22,12,8],
[50,22,13,0],
[50,22,13,1],
[50,22,13,2],
[50,22,13,3],
[50,22,13,4],
[50,22,13,5],
[50,22,13,6],
[50,22,13,7],
[50,22,13,8],
[50,22,13,11],
[50,22,13,12],
[50,22,14,0],
[50,22,14,1],
[50,22,14,2],
[50,22,14,3],
[50,22,14,4],
[50,22,14,5],
[50,22,14,6],
[50,22,14,7],
[50,22,14,8],
[50,22,14,11],
[50,22,14,12],
[50,22,16,0],
[50,22,16,1],
[50,22,16,2],
[50,22,16,3],
[50,22,16,4],
[50,22,16,5],
[50,22,16,6],
[50,22,16,7],
[50,22,16,8],
[50,22,16,11],
[50,22,16,12],
[50,22,16,13],
[50,22,16,14],
[50,22,17,0],
[50,22,17,1],
[50,22,17,2],
[50,22,17,3],
[50,22,17,4],
[50,22,17,5],
[50,22,17,6],
[50,22,17,7],
[50,22,17,8],
[50,22,17,11],
[50,22,17,12],
[50,22,17,13],
[50,22,17,14],
[50,22,17,16],
[50,22,19,0],
[50,22,19,1],
[50,22,19,2],
[50,22,19,3],
[50,22,19,4],
[50,22,19,5],
[50,22,19,6],
[50,22,19,7],
[50,22,19,8],
[50,22,19,11],
[50,22,19,12],
[50,22,19,16],
[50,22,19,17],
[50,22,21,0],
[50,22,21,1],
[50,22,21,2],
[50,22,21,4],
[50,22,21,7],
[50,22,21,11],
[50,22,21,12],
[50,22,21,13],
[50,22,21,14],
[50,22,21,16],
[50,22,21,17],
[50,22,21,19],
[50,23,3,0],
[50,23,3,1],
[50,23,3,2],
[50,23,4,0],
[50,23,4,1],
[50,23,4,2],
[50,23,4,3],
[50,23,5,0],
[50,23,5,1],
[50,23,5,2],
[50,23,5,4],
[50,23,6,0],
[50,23,6,1],
[50,23,6,2],
[50,23,6,4],
[50,23,7,0],
[50,23,7,1],
[50,23,7,2],
[50,23,7,3],
[50,23,7,4],
[50,23,7,5],
[50,23,7,6],
[50,23,8,0],
[50,23,8,1],
[50,23,8,2],
[50,23,8,4],
[50,23,8,7],
[50,23,10,0],
[50,23,10,1],
[50,23,10,2],
[50,23,10,3],
[50,23,10,4],
[50,23,10,5],
[50,23,10,6],
[50,23,10,7],
[50,23,10,8],
[50,23,11,0],
[50,23,11,1],
[50,23,11,2],
[50,23,11,3],
[50,23,11,4],
[50,23,11,5],
[50,23,11,6],
[50,23,11,7],
[50,23,11,8],
[50,23,11,10],
[50,23,12,0],
[50,23,12,1],
[50,23,12,2],
[50,23,12,3],
[50,23,12,4],
[50,23,12,5],
[50,23,12,6],
[50,23,12,7],
[50,23,12,8],
[50,23,12,10],
[50,23,13,0],
[50,23,13,1],
[50,23,13,2],
[50,23,13,3],
[50,23,13,4],
[50,23,13,5],
[50,23,13,6],
[50,23,13,7],
[50,23,13,8],
[50,23,13,10],
[50,23,13,11],
[50,23,13,12],
[50,23,14,0],
[50,23,14,1],
[50,23,14,2],
[50,23,14,3],
[50,23,14,4],
[50,23,14,5],
[50,23,14,6],
[50,23,14,7],
[50,23,14,8],
[50,23,14,10],
[50,23,14,11],
[50,23,14,12],
[50,23,16,0],
[50,23,16,1],
[50,23,16,2],
[50,23,16,3],
[50,23,16,4],
[50,23,16,5],
[50,23,16,6],
[50,23,16,7],
[50,23,16,8],
[50,23,16,10],
[50,23,16,11],
[50,23,16,12],
[50,23,16,13],
[50,23,16,14],
[50,23,17,0],
[50,23,17,1],
[50,23,17,2],
[50,23,17,3],
[50,23,17,4],
[50,23,17,5],
[50,23,17,6],
[50,23,17,7],
[50,23,17,8],
[50,23,17,10],
[50,23,17,11],
[50,23,17,12],
[50,23,17,13],
[50,23,17,14],
[50,23,17,16],
[50,23,19,0],
[50,23,19,1],
[50,23,19,2],
[50,23,19,3],
[50,23,19,4],
[50,23,19,5],
[50,23,19,6],
[50,23,19,7],
[50,23,19,8],
[50,23,19,10],
[50,23,19,11],
[50,23,19,12],
[50,23,19,16],
[50,23,19,17],
[50,23,20,0],
[50,23,20,1],
[50,23,20,2],
[50,23,20,3],
[50,23,20,4],
[50,23,20,5],
[50,23,20,6],
[50,23,20,7],
[50,23,20,8],
[50,23,20,11],
[50,23,20,12],
[50,23,20,13],
[50,23,20,14],
[50,23,20,16],
[50,23,20,17],
[50,23,20,19],
[50,23,21,0],
[50,23,21,1],
[50,23,21,2],
[50,23,21,4],
[50,23,21,7],
[50,23,21,10],
[50,23,21,11],
[50,23,21,12],
[50,23,21,13],
[50,23,21,14],
[50,23,21,16],
[50,23,21,17],
[50,23,21,19],
[50,23,21,20],
[50,23,22,0],
[50,23,22,1],
[50,23,22,2],
[50,23,22,3],
[50,23,22,4],
[50,23,22,5],
[50,23,22,6],
[50,23,22,7],
[50,23,22,8],
[50,23,22,11],
[50,23,22,12],
[50,23,22,13],
[50,23,22,14],
[50,23,22,16],
[50,23,22,17],
[50,23,22,19],
[50,23,22,21],
[50,24,3,0],
[50,24,3,1],
[50,24,3,2],
[50,24,4,0],
[50,24,4,1],
[50,24,4,2],
[50,24,4,3],
[50,24,5,0],
[50,24,5,1],
[50,24,5,2],
[50,24,5,4],
[50,24,6,0],
[50,24,6,1],
[50,24,6,2],
[50,24,6,4],
[50,24,7,0],
[50,24,7,1],
[50,24,7,2],
[50,24,7,3],
[50,24,7,4],
[50,24,7,5],
[50,24,7,6],
[50,24,8,0],
[50,24,8,1],
[50,24,8,2],
[50,24,8,4],
[50,24,8,7],
[50,24,10,0],
[50,24,10,1],
[50,24,10,2],
[50,24,10,3],
[50,24,10,4],
[50,24,10,5],
[50,24,10,6],
[50,24,10,7],
[50,24,10,8],
[50,24,11,0],
[50,24,11,1],
[50,24,11,2],
[50,24,11,3],
[50,24,11,4],
[50,24,11,5],
[50,24,11,6],
[50,24,11,7],
[50,24,11,8],
[50,24,11,10],
[50,24,12,0],
[50,24,12,1],
[50,24,12,2],
[50,24,12,3],
[50,24,12,4],
[50,24,12,5],
[50,24,12,6],
[50,24,12,7],
[50,24,12,8],
[50,24,12,10],
[50,24,13,0],
[50,24,13,1],
[50,24,13,2],
[50,24,13,3],
[50,24,13,4],
[50,24,13,5],
[50,24,13,6],
[50,24,13,7],
[50,24,13,8],
[50,24,13,10],
[50,24,13,11],
[50,24,13,12],
[50,24,14,0],
[50,24,14,1],
[50,24,14,2],
[50,24,14,3],
[50,24,14,4],
[50,24,14,5],
[50,24,14,6],
[50,24,14,7],
[50,24,14,8],
[50,24,14,10],
[50,24,14,11],
[50,24,14,12],
[50,24,17,0],
[50,24,17,1],
[50,24,17,2],
[50,24,17,3],
[50,24,17,4],
[50,24,17,5],
[50,24,17,6],
[50,24,17,7],
[50,24,17,8],
[50,24,17,10],
[50,24,17,11],
[50,24,17,12],
[50,24,17,13],
[50,24,17,14],
[50,24,19,0],
[50,24,19,1],
[50,24,19,2],
[50,24,19,3],
[50,24,19,4],
[50,24,19,5],
[50,24,19,6],
[50,24,19,7],
[50,24,19,8],
[50,24,19,10],
[50,24,19,11],
[50,24,19,12],
[50,24,19,17],
[50,24,20,0],
[50,24,20,1],
[50,24,20,2],
[50,24,20,3],
[50,24,20,4],
[50,24,20,5],
[50,24,20,6],
[50,24,20,7],
[50,24,20,8],
[50,24,20,11],
[50,24,20,12],
[50,24,20,13],
[50,24,20,14],
[50,24,20,17],
[50,24,20,19],
[50,24,21,0],
[50,24,21,1],
[50,24,21,2],
[50,24,21,4],
[50,24,21,7],
[50,24,21,10],
[50,24,21,11],
[50,24,21,12],
[50,24,21,13],
[50,24,21,14],
[50,24,21,17],
[50,24,21,19],
[50,24,21,20],
[50,24,22,0],
[50,24,22,1],
[50,24,22,2],
[50,24,22,3],
[50,24,22,4],
[50,24,22,5],
[50,24,22,6],
[50,24,22,7],
[50,24,22,8],
[50,24,22,11],
[50,24,22,12],
[50,24,22,13],
[50,24,22,14],
[50,24,22,17],
[50,24,22,19],
[50,24,22,21],
[50,24,23,0],
[50,24,23,1],
[50,24,23,2],
[50,24,23,3],
[50,24,23,4],
[50,24,23,5],
[50,24,23,6],
[50,24,23,7],
[50,24,23,8],
[50,24,23,10],
[50,24,23,11],
[50,24,23,12],
[50,24,23,13],
[50,24,23,14],
[50,24,23,17],
[50,24,23,19],
[50,24,23,20],
[50,24,23,21],
[50,24,23,22],
[50,25,3,0],
[50,25,3,1],
[50,25,3,2],
[50,25,4,0],
[50,25,4,1],
[50,25,4,2],
[50,25,4,3],
[50,25,5,0],
[50,25,5,1],
[50,25,5,2],
[50,25,5,4],
[50,25,6,0],
[50,25,6,1],
[50,25,6,2],
[50,25,6,4],
[50,25,8,0],
[50,25,8,1],
[50,25,8,2],
[50,25,8,4],
[50,25,10,0],
[50,25,10,1],
[50,25,10,2],
[50,25,10,3],
[50,25,10,4],
[50,25,10,5],
[50,25,10,6],
[50,25,10,8],
[50,25,11,0],
[50,25,11,1],
[50,25,11,2],
[50,25,11,3],
[50,25,11,4],
[50,25,11,5],
[50,25,11,6],
[50,25,11,8],
[50,25,11,10],
[50,25,12,0],
[50,25,12,1],
[50,25,12,2],
[50,25,12,3],
[50,25,12,4],
[50,25,12,5],
[50,25,12,6],
[50,25,12,8],
[50,25,12,10],
[50,25,13,0],
[50,25,13,1],
[50,25,13,2],
[50,25,13,3],
[50,25,13,4],
[50,25,13,5],
[50,25,13,6],
[50,25,13,8],
[50,25,13,10],
[50,25,13,11],
[50,25,13,12],
[50,25,14,0],
[50,25,14,1],
[50,25,14,2],
[50,25,14,3],
[50,25,14,4],
[50,25,14,5],
[50,25,14,6],
[50,25,14,8],
[50,25,14,10],
[50,25,14,11],
[50,25,14,12],
[50,25,16,0],
[50,25,16,1],
[50,25,16,2],
[50,25,16,3],
[50,25,16,4],
[50,25,16,5],
[50,25,16,6],
[50,25,16,8],
[50,25,16,10],
[50,25,16,11],
[50,25,16,12],
[50,25,16,13],
[50,25,16,14],
[50,25,17,0],
[50,25,17,1],
[50,25,17,2],
[50,25,17,3],
[50,25,17,4],
[50,25,17,5],
[50,25,17,6],
[50,25,17,8],
[50,25,17,10],
[50,25,17,11],
[50,25,17,12],
[50,25,17,13],
[50,25,17,14],
[50,25,17,16],
[50,25,19,0],
[50,25,19,1],
[50,25,19,2],
[50,25,19,3],
[50,25,19,4],
[50,25,19,5],
[50,25,19,6],
[50,25,19,8],
[50,25,19,10],
[50,25,19,11],
[50,25,19,12],
[50,25,19,16],
[50,25,19,17],
[50,25,20,0],
[50,25,20,1],
[50,25,20,2],
[50,25,20,3],
[50,25,20,4],
[50,25,20,5],
[50,25,20,6],
[50,25,20,8],
[50,25,20,11],
[50,25,20,12],
[50,25,20,13],
[50,25,20,14],
[50,25,20,16],
[50,25,20,17],
[50,25,20,19],
[50,25,21,0],
[50,25,21,1],
[50,25,21,2],
[50,25,21,4],
[50,25,21,10],
[50,25,21,11],
[50,25,21,12],
[50,25,21,13],
[50,25,21,14],
[50,25,21,16],
[50,25,21,17],
[50,25,21,19],
[50,25,21,20],
[50,25,22,0],
[50,25,22,1],
[50,25,22,2],
[50,25,22,3],
[50,25,22,4],
[50,25,22,5],
[50,25,22,6],
[50,25,22,8],
[50,25,22,11],
[50,25,22,12],
[50,25,22,13],
[50,25,22,14],
[50,25,22,16],
[50,25,22,17],
[50,25,22,19],
[50,25,22,21],
[50,25,23,0],
[50,25,23,1],
[50,25,23,2],
[50,25,23,3],
[50,25,23,4],
[50,25,23,5],
[50,25,23,6],
[50,25,23,8],
[50,25,23,10],
[50,25,23,11],
[50,25,23,12],
[50,25,23,13],
[50,25,23,14],
[50,25,23,16],
[50,25,23,17],
[50,25,23,19],
[50,25,23,20],
[50,25,23,21],
[50,25,23,22],
[50,25,24,0],
[50,25,24,1],
[50,25,24,2],
[50,25,24,3],
[50,25,24,4],
[50,25,24,5],
[50,25,24,6],
[50,25,24,8],
[50,25,24,10],
[50,25,24,11],
[50,25,24,12],
[50,25,24,13],
[50,25,24,14],
[50,25,24,17],
[50,25,24,19],
[50,25,24,20],
[50,25,24,21],
[50,25,24,22],
[50,25,24,23],
[50,27,3,0],
[50,27,3,1],
[50,27,3,2],
[50,27,4,0],
[50,27,4,1],
[50,27,4,2],
[50,27,4,3],
[50,27,5,0],
[50,27,5,1],
[50,27,5,2],
[50,27,5,4],
[50,27,6,0],
[50,27,6,1],
[50,27,6,2],
[50,27,6,4],
[50,27,7,0],
[50,27,7,1],
[50,27,7,2],
[50,27,7,3],
[50,27,7,4],
[50,27,7,5],
[50,27,7,6],
[50,27,8,0],
[50,27,8,1],
[50,27,8,2],
[50,27,8,4],
[50,27,8,7],
[50,27,11,0],
[50,27,11,1],
[50,27,11,2],
[50,27,11,3],
[50,27,11,4],
[50,27,11,5],
[50,27,11,6],
[50,27,11,7],
[50,27,11,8],
[50,27,12,0],
[50,27,12,1],
[50,27,12,2],
[50,27,12,3],
[50,27,12,4],
[50,27,12,5],
[50,27,12,6],
[50,27,12,7],
[50,27,12,8],
[50,27,13,0],
[50,27,13,1],
[50,27,13,2],
[50,27,13,3],
[50,27,13,4],
[50,27,13,5],
[50,27,13,6],
[50,27,13,7],
[50,27,13,8],
[50,27,13,11],
[50,27,13,12],
[50,27,14,0],
[50,27,14,1],
[50,27,14,2],
[50,27,14,3],
[50,27,14,4],
[50,27,14,5],
[50,27,14,6],
[50,27,14,7],
[50,27,14,8],
[50,27,14,11],
[50,27,14,12],
[50,27,16,0],
[50,27,16,1],
[50,27,16,2],
[50,27,16,3],
[50,27,16,4],
[50,27,16,5],
[50,27,16,6],
[50,27,16,7],
[50,27,16,8],
[50,27,16,11],
[50,27,16,12],
[50,27,16,13],
[50,27,16,14],
[50,27,17,0],
[50,27,17,1],
[50,27,17,2],
[50,27,17,3],
[50,27,17,4],
[50,27,17,5],
[50,27,17,6],
[50,27,17,7],
[50,27,17,8],
[50,27,17,11],
[50,27,17,12],
[50,27,17,13],
[50,27,17,14],
[50,27,17,16],
[50,27,19,0],
[50,27,19,1],
[50,27,19,2],
[50,27,19,3],
[50,27,19,4],
[50,27,19,5],
[50,27,19,6],
[50,27,19,7],
[50,27,19,8],
[50,27,19,11],
[50,27,19,12],
[50,27,19,16],
[50,27,19,17],
[50,27,21,0],
[50,27,21,1],
[50,27,21,2],
[50,27,21,4],
[50,27,21,7],
[50,27,21,11],
[50,27,21,12],
[50,27,21,13],
[50,27,21,14],
[50,27,21,16],
[50,27,21,17],
[50,27,21,19],
[50,27,23,0],
[50,27,23,1],
[50,27,23,2],
[50,27,23,3],
[50,27,23,4],
[50,27,23,5],
[50,27,23,6],
[50,27,23,7],
[50,27,23,8],
[50,27,23,11],
[50,27,23,12],
[50,27,23,13],
[50,27,23,14],
[50,27,23,16],
[50,27,23,17],
[50,27,23,19],
[50,27,23,21],
[50,27,24,0],
[50,27,24,1],
[50,27,24,2],
[50,27,24,3],
[50,27,24,4],
[50,27,24,5],
[50,27,24,6],
[50,27,24,7],
[50,27,24,8],
[50,27,24,11],
[50,27,24,12],
[50,27,24,13],
[50,27,24,14],
[50,27,24,17],
[50,27,24,19],
[50,27,24,21],
[50,27,24,23],
[50,27,25,0],
[50,27,25,1],
[50,27,25,2],
[50,27,25,3],
[50,27,25,4],
[50,27,25,5],
[50,27,25,6],
[50,27,25,8],
[50,27,25,11],
[50,27,25,12],
[50,27,25,13],
[50,27,25,14],
[50,27,25,16],
[50,27,25,17],
[50,27,25,19],
[50,27,25,21],
[50,27,25,23],
[50,27,25,24],
[50,28,3,0],
[50,28,3,1],
[50,28,3,2],
[50,28,4,0],
[50,28,4,1],
[50,28,4,2],
[50,28,4,3],
[50,28,5,0],
[50,28,5,1],
[50,28,5,2],
[50,28,5,4],
[50,28,6,0],
[50,28,6,1],
[50,28,6,2],
[50,28,6,4],
[50,28,8,0],
[50,28,8,1],
[50,28,8,2],
[50,28,8,4],
[50,28,10,0],
[50,28,10,1],
[50,28,10,2],
[50,28,10,3],
[50,28,10,4],
[50,28,10,5],
[50,28,10,6],
[50,28,10,8],
[50,28,11,0],
[50,28,11,1],
[50,28,11,2],
[50,28,11,3],
[50,28,11,4],
[50,28,11,5],
[50,28,11,6],
[50,28,11,8],
[50,28,11,10],
[50,28,12,0],
[50,28,12,1],
[50,28,12,2],
[50,28,12,3],
[50,28,12,4],
[50,28,12,5],
[50,28,12,6],
[50,28,12,8],
[50,28,12,10],
[50,28,13,0],
[50,28,13,1],
[50,28,13,2],
[50,28,13,3],
[50,28,13,4],
[50,28,13,5],
[50,28,13,6],
[50,28,13,8],
[50,28,13,10],
[50,28,13,11],
[50,28,13,12],
[50,28,14,0],
[50,28,14,1],
[50,28,14,2],
[50,28,14,3],
[50,28,14,4],
[50,28,14,5],
[50,28,14,6],
[50,28,14,8],
[50,28,14,10],
[50,28,14,11],
[50,28,14,12],
[50,28,16,0],
[50,28,16,1],
[50,28,16,2],
[50,28,16,3],
[50,28,16,4],
[50,28,16,5],
[50,28,16,6],
[50,28,16,8],
[50,28,16,10],
[50,28,16,11],
[50,28,16,12],
[50,28,16,13],
[50,28,16,14],
[50,28,17,0],
[50,28,17,1],
[50,28,17,2],
[50,28,17,3],
[50,28,17,4],
[50,28,17,5],
[50,28,17,6],
[50,28,17,8],
[50,28,17,10],
[50,28,17,11],
[50,28,17,12],
[50,28,17,13],
[50,28,17,14],
[50,28,17,16],
[50,28,19,0],
[50,28,19,1],
[50,28,19,2],
[50,28,19,3],
[50,28,19,4],
[50,28,19,5],
[50,28,19,6],
[50,28,19,8],
[50,28,19,10],
[50,28,19,11],
[50,28,19,12],
[50,28,19,16],
[50,28,19,17],
[50,28,20,0],
[50,28,20,1],
[50,28,20,2],
[50,28,20,3],
[50,28,20,4],
[50,28,20,5],
[50,28,20,6],
[50,28,20,8],
[50,28,20,11],
[50,28,20,12],
[50,28,20,13],
[50,28,20,14],
[50,28,20,16],
[50,28,20,17],
[50,28,20,19],
[50,28,21,0],
[50,28,21,1],
[50,28,21,2],
[50,28,21,4],
[50,28,21,10],
[50,28,21,11],
[50,28,21,12],
[50,28,21,13],
[50,28,21,14],
[50,28,21,16],
[50,28,21,17],
[50,28,21,19],
[50,28,21,20],
[50,28,22,0],
[50,28,22,1],
[50,28,22,2],
[50,28,22,3],
[50,28,22,4],
[50,28,22,5],
[50,28,22,6],
[50,28,22,8],
[50,28,22,11],
[50,28,22,12],
[50,28,22,13],
[50,28,22,14],
[50,28,22,16],
[50,28,22,17],
[50,28,22,19],
[50,28,22,21],
[50,28,23,0],
[50,28,23,1],
[50,28,23,2],
[50,28,23,3],
[50,28,23,4],
[50,28,23,5],
[50,28,23,6],
[50,28,23,8],
[50,28,23,10],
[50,28,23,11],
[50,28,23,12],
[50,28,23,13],
[50,28,23,14],
[50,28,23,16],
[50,28,23,17],
[50,28,23,19],
[50,28,23,20],
[50,28,23,21],
[50,28,23,22],
[50,28,24,0],
[50,28,24,1],
[50,28,24,2],
[50,28,24,3],
[50,28,24,4],
[50,28,24,5],
[50,28,24,6],
[50,28,24,8],
[50,28,24,10],
[50,28,24,11],
[50,28,24,12],
[50,28,24,13],
[50,28,24,14],
[50,28,24,17],
[50,28,24,19],
[50,28,24,20],
[50,28,24,21],
[50,28,24,22],
[50,28,24,23],
[50,28,27,0],
[50,28,27,1],
[50,28,27,2],
[50,28,27,3],
[50,28,27,4],
[50,28,27,5],
[50,28,27,6],
[50,28,27,8],
[50,28,27,11],
[50,28,27,12],
[50,28,27,13],
[50,28,27,14],
[50,28,27,16],
[50,28,27,17],
[50,28,27,19],
[50,28,27,21],
[50,28,27,23],
[50,28,27,24],
[50,29,3,0],
[50,29,3,1],
[50,29,3,2],
[50,29,4,0],
[50,29,4,1],
[50,29,4,2],
[50,29,4,3],
[50,29,5,0],
[50,29,5,1],
[50,29,5,2],
[50,29,5,4],
[50,29,6,0],
[50,29,6,1],
[50,29,6,2],
[50,29,6,4],
[50,29,7,0],
[50,29,7,1],
[50,29,7,2],
[50,29,7,3],
[50,29,7,4],
[50,29,7,5],
[50,29,7,6],
[50,29,8,0],
[50,29,8,1],
[50,29,8,2],
[50,29,8,4],
[50,29,8,7],
[50,29,10,0],
[50,29,10,1],
[50,29,10,2],
[50,29,10,3],
[50,29,10,4],
[50,29,10,5],
[50,29,10,6],
[50,29,10,7],
[50,29,10,8],
[50,29,13,0],
[50,29,13,1],
[50,29,13,2],
[50,29,13,3],
[50,29,13,4],
[50,29,13,5],
[50,29,13,6],
[50,29,13,7],
[50,29,13,8],
[50,29,13,10],
[50,29,14,0],
[50,29,14,1],
[50,29,14,2],
[50,29,14,3],
[50,29,14,4],
[50,29,14,5],
[50,29,14,6],
[50,29,14,7],
[50,29,14,8],
[50,29,14,10],
[50,29,16,0],
[50,29,16,1],
[50,29,16,2],
[50,29,16,3],
[50,29,16,4],
[50,29,16,5],
[50,29,16,6],
[50,29,16,7],
[50,29,16,8],
[50,29,16,10],
[50,29,16,13],
[50,29,16,14],
[50,29,17,0],
[50,29,17,1],
[50,29,17,2],
[50,29,17,3],
[50,29,17,4],
[50,29,17,5],
[50,29,17,6],
[50,29,17,7],
[50,29,17,8],
[50,29,17,10],
[50,29,17,13],
[50,29,17,14],
[50,29,17,16],
[50,29,19,0],
[50,29,19,1],
[50,29,19,2],
[50,29,19,3],
[50,29,19,4],
[50,29,19,5],
[50,29,19,6],
[50,29,19,7],
[50,29,19,8],
[50,29,19,10],
[50,29,19,16],
[50,29,19,17],
[50,29,20,0],
[50,29,20,1],
[50,29,20,2],
[50,29,20,3],
[50,29,20,4],
[50,29,20,5],
[50,29,20,6],
[50,29,20,7],
[50,29,20,8],
[50,29,20,13],
[50,29,20,14],
[50,29,20,16],
[50,29,20,17],
[50,29,20,19],
[50,29,21,0],
[50,29,21,1],
[50,29,21,2],
[50,29,21,4],
[50,29,21,7],
[50,29,21,10],
[50,29,21,13],
[50,29,21,14],
[50,29,21,16],
[50,29,21,17],
[50,29,21,19],
[50,29,21,20],
[50,29,22,0],
[50,29,22,1],
[50,29,22,2],
[50,29,22,3],
[50,29,22,4],
[50,29,22,5],
[50,29,22,6],
[50,29,22,7],
[50,29,22,8],
[50,29,22,13],
[50,29,22,14],
[50,29,22,16],
[50,29,22,17],
[50,29,22,19],
[50,29,22,21],
[50,29,23,0],
[50,29,23,1],
[50,29,23,2],
[50,29,23,3],
[50,29,23,4],
[50,29,23,5],
[50,29,23,6],
[50,29,23,7],
[50,29,23,8],
[50,29,23,10],
[50,29,23,13],
[50,29,23,14],
[50,29,23,16],
[50,29,23,17],
[50,29,23,19],
[50,29,23,20],
[50,29,23,21],
[50,29,23,22],
[50,29,24,0],
[50,29,24,1],
[50,29,24,2],
[50,29,24,3],
[50,29,24,4],
[50,29,24,5],
[50,29,24,6],
[50,29,24,7],
[50,29,24,8],
[50,29,24,10],
[50,29,24,13],
[50,29,24,14],
[50,29,24,17],
[50,29,24,19],
[50,29,24,20],
[50,29,24,21],
[50,29,24,22],
[50,29,24,23],
[50,29,25,0],
[50,29,25,1],
[50,29,25,2],
[50,29,25,3],
[50,29,25,4],
[50,29,25,5],
[50,29,25,6],
[50,29,25,8],
[50,29,25,10],
[50,29,25,13],
[50,29,25,14],
[50,29,25,16],
[50,29,25,17],
[50,29,25,19],
[50,29,25,20],
[50,29,25,21],
[50,29,25,22],
[50,29,25,23],
[50,29,25,24],
[50,29,27,0],
[50,29,27,1],
[50,29,27,2],
[50,29,27,3],
[50,29,27,4],
[50,29,27,5],
[50,29,27,6],
[50,29,27,7],
[50,29,27,8],
[50,29,27,13],
[50,29,27,14],
[50,29,27,16],
[50,29,27,17],
[50,29,27,19],
[50,29,27,21],
[50,29,27,23],
[50,29,27,24],
[50,29,27,25],
[50,29,28,0],
[50,29,28,1],
[50,29,28,2],
[50,29,28,3],
[50,29,28,4],
[50,29,28,5],
[50,29,28,6],
[50,29,28,8],
[50,29,28,10],
[50,29,28,13],
[50,29,28,14],
[50,29,28,16],
[50,29,28,17],
[50,29,28,19],
[50,29,28,20],
[50,29,28,21],
[50,29,28,22],
[50,29,28,27],
[50,30,3,0],
[50,30,3,1],
[50,30,3,2],
[50,30,4,0],
[50,30,4,1],
[50,30,4,2],
[50,30,4,3],
[50,30,5,0],
[50,30,5,1],
[50,30,5,2],
[50,30,5,4],
[50,30,6,0],
[50,30,6,1],
[50,30,6,2],
[50,30,6,4],
[50,30,7,0],
[50,30,7,1],
[50,30,7,2],
[50,30,7,3],
[50,30,7,4],
[50,30,7,5],
[50,30,7,6],
[50,30,8,0],
[50,30,8,1],
[50,30,8,2],
[50,30,8,4],
[50,30,8,7],
[50,30,10,0],
[50,30,10,1],
[50,30,10,2],
[50,30,10,3],
[50,30,10,4],
[50,30,10,5],
[50,30,10,6],
[50,30,10,7],
[50,30,10,8],
[50,30,11,0],
[50,30,11,1],
[50,30,11,2],
[50,30,11,3],
[50,30,11,4],
[50,30,11,5],
[50,30,11,6],
[50,30,11,7],
[50,30,11,8],
[50,30,11,10],
[50,30,12,0],
[50,30,12,1],
[50,30,12,2],
[50,30,12,3],
[50,30,12,4],
[50,30,12,5],
[50,30,12,6],
[50,30,12,7],
[50,30,12,8],
[50,30,12,10],
[50,30,13,0],
[50,30,13,1],
[50,30,13,2],
[50,30,13,3],
[50,30,13,4],
[50,30,13,5],
[50,30,13,6],
[50,30,13,7],
[50,30,13,8],
[50,30,13,10],
[50,30,13,11],
[50,30,13,12],
[50,30,14,0],
[50,30,14,1],
[50,30,14,2],
[50,30,14,3],
[50,30,14,4],
[50,30,14,5],
[50,30,14,6],
[50,30,14,7],
[50,30,14,8],
[50,30,14,10],
[50,30,14,11],
[50,30,14,12],
[50,30,16,0],
[50,30,16,1],
[50,30,16,2],
[50,30,16,3],
[50,30,16,4],
[50,30,16,5],
[50,30,16,6],
[50,30,16,7],
[50,30,16,8],
[50,30,16,10],
[50,30,16,11],
[50,30,16,12],
[50,30,16,13],
[50,30,16,14],
[50,30,17,0],
[50,30,17,1],
[50,30,17,2],
[50,30,17,3],
[50,30,17,4],
[50,30,17,5],
[50,30,17,6],
[50,30,17,7],
[50,30,17,8],
[50,30,17,10],
[50,30,17,11],
[50,30,17,12],
[50,30,17,13],
[50,30,17,14],
[50,30,17,16],
[50,30,19,0],
[50,30,19,1],
[50,30,19,2],
[50,30,19,3],
[50,30,19,4],
[50,30,19,5],
[50,30,19,6],
[50,30,19,7],
[50,30,19,8],
[50,30,19,10],
[50,30,19,11],
[50,30,19,12],
[50,30,19,16],
[50,30,19,17],
[50,30,20,0],
[50,30,20,1],
[50,30,20,2],
[50,30,20,3],
[50,30,20,4],
[50,30,20,5],
[50,30,20,6],
[50,30,20,7],
[50,30,20,8],
[50,30,20,11],
[50,30,20,12],
[50,30,20,13],
[50,30,20,14],
[50,30,20,16],
[50,30,20,17],
[50,30,20,19],
[50,30,21,0],
[50,30,21,1],
[50,30,21,2],
[50,30,21,4],
[50,30,21,7],
[50,30,21,10],
[50,30,21,11],
[50,30,21,12],
[50,30,21,13],
[50,30,21,14],
[50,30,21,16],
[50,30,21,17],
[50,30,21,19],
[50,30,21,20],
[50,30,22,0],
[50,30,22,1],
[50,30,22,2],
[50,30,22,3],
[50,30,22,4],
[50,30,22,5],
[50,30,22,6],
[50,30,22,7],
[50,30,22,8],
[50,30,22,11],
[50,30,22,12],
[50,30,22,13],
[50,30,22,14],
[50,30,22,16],
[50,30,22,17],
[50,30,22,19],
[50,30,22,21],
[50,30,24,0],
[50,30,24,1],
[50,30,24,2],
[50,30,24,3],
[50,30,24,4],
[50,30,24,5],
[50,30,24,6],
[50,30,24,7],
[50,30,24,8],
[50,30,24,10],
[50,30,24,11],
[50,30,24,12],
[50,30,24,13],
[50,30,24,14],
[50,30,24,17],
[50,30,24,19],
[50,30,24,20],
[50,30,24,21],
[50,30,24,22],
[50,30,25,0],
[50,30,25,1],
[50,30,25,2],
[50,30,25,3],
[50,30,25,4],
[50,30,25,5],
[50,30,25,6],
[50,30,25,8],
[50,30,25,10],
[50,30,25,11],
[50,30,25,12],
[50,30,25,13],
[50,30,25,14],
[50,30,25,16],
[50,30,25,17],
[50,30,25,19],
[50,30,25,20],
[50,30,25,21],
[50,30,25,22],
[50,30,25,24],
[50,30,27,0],
[50,30,27,1],
[50,30,27,2],
[50,30,27,3],
[50,30,27,4],
[50,30,27,5],
[50,30,27,6],
[50,30,27,7],
[50,30,27,8],
[50,30,27,11],
[50,30,27,12],
[50,30,27,13],
[50,30,27,14],
[50,30,27,16],
[50,30,27,17],
[50,30,27,19],
[50,30,27,21],
[50,30,27,25],
[50,30,28,0],
[50,30,28,1],
[50,30,28,2],
[50,30,28,3],
[50,30,28,4],
[50,30,28,5],
[50,30,28,6],
[50,30,28,8],
[50,30,28,10],
[50,30,28,11],
[50,30,28,12],
[50,30,28,13],
[50,30,28,14],
[50,30,28,16],
[50,30,28,17],
[50,30,28,19],
[50,30,28,20],
[50,30,28,21],
[50,30,28,24],
[50,30,28,27],
[50,30,29,0],
[50,30,29,1],
[50,30,29,2],
[50,30,29,3],
[50,30,29,4],
[50,30,29,5],
[50,30,29,6],
[50,30,29,7],
[50,30,29,8],
[50,30,29,10],
[50,30,29,13],
[50,30,29,14],
[50,30,29,16],
[50,30,29,17],
[50,30,29,19],
[50,30,29,20],
[50,30,29,22],
[50,30,29,24],
[50,30,29,25],
[50,30,29,27],
[50,30,29,28],
[50,31,3,0],
[50,31,3,1],
[50,31,3,2],
[50,31,4,0],
[50,31,4,1],
[50,31,4,2],
[50,31,4,3],
[50,31,5,0],
[50,31,5,1],
[50,31,5,2],
[50,31,5,4],
[50,31,6,0],
[50,31,6,1],
[50,31,6,2],
[50,31,6,4],
[50,31,7,0],
[50,31,7,1],
[50,31,7,2],
[50,31,7,3],
[50,31,7,4],
[50,31,7,5],
[50,31,7,6],
[50,31,8,0],
[50,31,8,1],
[50,31,8,2],
[50,31,8,4],
[50,31,8,7],
[50,31,10,0],
[50,31,10,1],
[50,31,10,2],
[50,31,10,3],
[50,31,10,4],
[50,31,10,5],
[50,31,10,6],
[50,31,10,7],
[50,31,10,8],
[50,31,11,0],
[50,31,11,1],
[50,31,11,2],
[50,31,11,3],
[50,31,11,4],
[50,31,11,5],
[50,31,11,6],
[50,31,11,7],
[50,31,11,8],
[50,31,11,10],
[50,31,12,0],
[50,31,12,1],
[50,31,12,2],
[50,31,12,3],
[50,31,12,4],
[50,31,12,5],
[50,31,12,6],
[50,31,12,7],
[50,31,12,8],
[50,31,12,10],
[50,31,13,0],
[50,31,13,1],
[50,31,13,2],
[50,31,13,3],
[50,31,13,4],
[50,31,13,5],
[50,31,13,6],
[50,31,13,7],
[50,31,13,8],
[50,31,13,10],
[50,31,13,11],
[50,31,13,12],
[50,31,14,0],
[50,31,14,1],
[50,31,14,2],
[50,31,14,3],
[50,31,14,4],
[50,31,14,5],
[50,31,14,6],
[50,31,14,7],
[50,31,14,8],
[50,31,14,10],
[50,31,14,11],
[50,31,14,12],
[50,31,16,0],
[50,31,16,1],
[50,31,16,2],
[50,31,16,3],
[50,31,16,4],
[50,31,16,5],
[50,31,16,6],
[50,31,16,7],
[50,31,16,8],
[50,31,16,10],
[50,31,16,11],
[50,31,16,12],
[50,31,16,13],
[50,31,16,14],
[50,31,17,0],
[50,31,17,1],
[50,31,17,2],
[50,31,17,3],
[50,31,17,4],
[50,31,17,5],
[50,31,17,6],
[50,31,17,7],
[50,31,17,8],
[50,31,17,10],
[50,31,17,11],
[50,31,17,12],
[50,31,17,13],
[50,31,17,14],
[50,31,17,16],
[50,31,19,0],
[50,31,19,1],
[50,31,19,2],
[50,31,19,3],
[50,31,19,4],
[50,31,19,5],
[50,31,19,6],
[50,31,19,7],
[50,31,19,8],
[50,31,19,10],
[50,31,19,11],
[50,31,19,12],
[50,31,19,16],
[50,31,19,17],
[50,31,20,0],
[50,31,20,1],
[50,31,20,2],
[50,31,20,3],
[50,31,20,4],
[50,31,20,5],
[50,31,20,6],
[50,31,20,7],
[50,31,20,8],
[50,31,20,11],
[50,31,20,12],
[50,31,20,13],
[50,31,20,14],
[50,31,20,16],
[50,31,20,17],
[50,31,20,19],
[50,31,21,0],
[50,31,21,1],
[50,31,21,2],
[50,31,21,4],
[50,31,21,7],
[50,31,21,10],
[50,31,21,11],
[50,31,21,12],
[50,31,21,13],
[50,31,21,14],
[50,31,21,16],
[50,31,21,17],
[50,31,21,19],
[50,31,21,20],
[50,31,22,0],
[50,31,22,1],
[50,31,22,2],
[50,31,22,3],
[50,31,22,4],
[50,31,22,5],
[50,31,22,6],
[50,31,22,7],
[50,31,22,8],
[50,31,22,11],
[50,31,22,12],
[50,31,22,13],
[50,31,22,14],
[50,31,22,16],
[50,31,22,17],
[50,31,22,19],
[50,31,22,21],
[50,31,24,0],
[50,31,24,1],
[50,31,24,2],
[50,31,24,3],
[50,31,24,4],
[50,31,24,5],
[50,31,24,6],
[50,31,24,7],
[50,31,24,8],
[50,31,24,10],
[50,31,24,11],
[50,31,24,12],
[50,31,24,13],
[50,31,24,14],
[50,31,24,17],
[50,31,24,19],
[50,31,24,20],
[50,31,24,21],
[50,31,24,22],
[50,31,25,0],
[50,31,25,1],
[50,31,25,2],
[50,31,25,3],
[50,31,25,4],
[50,31,25,5],
[50,31,25,6],
[50,31,25,8],
[50,31,25,10],
[50,31,25,11],
[50,31,25,12],
[50,31,25,13],
[50,31,25,14],
[50,31,25,16],
[50,31,25,17],
[50,31,25,19],
[50,31,25,20],
[50,31,25,21],
[50,31,25,22],
[50,31,25,24],
[50,31,27,0],
[50,31,27,1],
[50,31,27,2],
[50,31,27,3],
[50,31,27,4],
[50,31,27,5],
[50,31,27,6],
[50,31,27,7],
[50,31,27,8],
[50,31,27,11],
[50,31,27,12],
[50,31,27,13],
[50,31,27,14],
[50,31,27,16],
[50,31,27,17],
[50,31,27,19],
[50,31,27,21],
[50,31,27,24],
[50,31,27,25],
[50,31,28,0],
[50,31,28,1],
[50,31,28,2],
[50,31,28,3],
[50,31,28,4],
[50,31,28,5],
[50,31,28,6],
[50,31,28,8],
[50,31,28,10],
[50,31,28,11],
[50,31,28,12],
[50,31,28,13],
[50,31,28,14],
[50,31,28,16],
[50,31,28,17],
[50,31,28,19],
[50,31,28,20],
[50,31,28,22],
[50,31,28,24],
[50,31,28,27],
[50,31,29,0],
[50,31,29,1],
[50,31,29,2],
[50,31,29,3],
[50,31,29,4],
[50,31,29,5],
[50,31,29,6],
[50,31,29,7],
[50,31,29,8],
[50,31,29,10],
[50,31,29,13],
[50,31,29,14],
[50,31,29,16],
[50,31,29,17],
[50,31,29,19],
[50,31,29,21],
[50,31,29,22],
[50,31,29,24],
[50,31,29,25],
[50,31,29,27],
[50,31,29,28],
[50,32,3,0],
[50,32,3,1],
[50,32,3,2],
[50,32,4,0],
[50,32,4,1],
[50,32,4,2],
[50,32,4,3],
[50,32,5,0],
[50,32,5,1],
[50,32,5,2],
[50,32,5,4],
[50,32,6,0],
[50,32,6,1],
[50,32,6,2],
[50,32,6,4],
[50,32,7,0],
[50,32,7,1],
[50,32,7,2],
[50,32,7,3],
[50,32,7,4],
[50,32,7,5],
[50,32,7,6],
[50,32,8,0],
[50,32,8,1],
[50,32,8,2],
[50,32,8,4],
[50,32,8,7],
[50,32,10,0],
[50,32,10,1],
[50,32,10,2],
[50,32,10,3],
[50,32,10,4],
[50,32,10,5],
[50,32,10,6],
[50,32,10,7],
[50,32,10,8],
[50,32,13,0],
[50,32,13,1],
[50,32,13,2],
[50,32,13,3],
[50,32,13,4],
[50,32,13,5],
[50,32,13,6],
[50,32,13,7],
[50,32,13,8],
[50,32,13,10],
[50,32,14,0],
[50,32,14,1],
[50,32,14,2],
[50,32,14,3],
[50,32,14,4],
[50,32,14,5],
[50,32,14,6],
[50,32,14,7],
[50,32,14,8],
[50,32,14,10],
[50,32,16,0],
[50,32,16,1],
[50,32,16,2],
[50,32,16,3],
[50,32,16,4],
[50,32,16,5],
[50,32,16,6],
[50,32,16,7],
[50,32,16,8],
[50,32,16,10],
[50,32,16,13],
[50,32,16,14],
[50,32,17,0],
[50,32,17,1],
[50,32,17,2],
[50,32,17,3],
[50,32,17,4],
[50,32,17,5],
[50,32,17,6],
[50,32,17,7],
[50,32,17,8],
[50,32,17,10],
[50,32,17,13],
[50,32,17,14],
[50,32,17,16],
[50,32,19,0],
[50,32,19,1],
[50,32,19,2],
[50,32,19,3],
[50,32,19,4],
[50,32,19,5],
[50,32,19,6],
[50,32,19,7],
[50,32,19,8],
[50,32,19,10],
[50,32,19,16],
[50,32,19,17],
[50,32,20,0],
[50,32,20,1],
[50,32,20,2],
[50,32,20,3],
[50,32,20,4],
[50,32,20,5],
[50,32,20,6],
[50,32,20,7],
[50,32,20,8],
[50,32,20,13],
[50,32,20,14],
[50,32,20,16],
[50,32,20,17],
[50,32,20,19],
[50,32,21,0],
[50,32,21,1],
[50,32,21,2],
[50,32,21,4],
[50,32,21,7],
[50,32,21,10],
[50,32,21,13],
[50,32,21,14],
[50,32,21,16],
[50,32,21,17],
[50,32,21,19],
[50,32,21,20],
[50,32,22,0],
[50,32,22,1],
[50,32,22,2],
[50,32,22,3],
[50,32,22,4],
[50,32,22,5],
[50,32,22,6],
[50,32,22,7],
[50,32,22,8],
[50,32,22,13],
[50,32,22,14],
[50,32,22,16],
[50,32,22,17],
[50,32,22,19],
[50,32,22,21],
[50,32,23,0],
[50,32,23,1],
[50,32,23,2],
[50,32,23,3],
[50,32,23,4],
[50,32,23,5],
[50,32,23,6],
[50,32,23,7],
[50,32,23,8],
[50,32,23,10],
[50,32,23,13],
[50,32,23,14],
[50,32,23,16],
[50,32,23,17],
[50,32,23,19],
[50,32,23,20],
[50,32,23,21],
[50,32,23,22],
[50,32,24,0],
[50,32,24,1],
[50,32,24,2],
[50,32,24,3],
[50,32,24,4],
[50,32,24,5],
[50,32,24,6],
[50,32,24,7],
[50,32,24,8],
[50,32,24,10],
[50,32,24,13],
[50,32,24,14],
[50,32,24,17],
[50,32,24,19],
[50,32,24,20],
[50,32,24,21],
[50,32,24,22],
[50,32,24,23],
[50,32,25,0],
[50,32,25,1],
[50,32,25,2],
[50,32,25,3],
[50,32,25,4],
[50,32,25,5],
[50,32,25,6],
[50,32,25,8],
[50,32,25,10],
[50,32,25,13],
[50,32,25,14],
[50,32,25,16],
[50,32,25,17],
[50,32,25,19],
[50,32,25,20],
[50,32,25,21],
[50,32,25,22],
[50,32,27,0],
[50,32,27,1],
[50,32,27,2],
[50,32,27,3],
[50,32,27,4],
[50,32,27,5],
[50,32,27,6],
[50,32,27,7],
[50,32,27,8],
[50,32,27,13],
[50,32,27,14],
[50,32,27,16],
[50,32,27,17],
[50,32,27,19],
[50,32,27,23],
[50,32,27,24],
[50,32,27,25],
[50,32,28,0],
[50,32,28,1],
[50,32,28,2],
[50,32,28,3],
[50,32,28,4],
[50,32,28,5],
[50,32,28,6],
[50,32,28,8],
[50,32,28,10],
[50,32,28,13],
[50,32,28,14],
[50,32,28,16],
[50,32,28,17],
[50,32,28,19],
[50,32,28,21],
[50,32,28,22],
[50,32,28,23],
[50,32,28,24],
[50,32,28,27],
[50,32,30,0],
[50,32,30,1],
[50,32,30,2],
[50,32,30,3],
[50,32,30,4],
[50,32,30,5],
[50,32,30,6],
[50,32,30,7],
[50,32,30,8],
[50,32,30,10],
[50,32,30,13],
[50,32,30,14],
[50,32,30,16],
[50,32,30,17],
[50,32,30,19],
[50,32,30,20],
[50,32,30,21],
[50,32,30,22],
[50,32,30,24],
[50,32,30,25],
[50,32,30,27],
[50,32,30,28],
[50,32,31,0],
[50,32,31,1],
[50,32,31,2],
[50,32,31,3],
[50,32,31,4],
[50,32,31,5],
[50,32,31,6],
[50,32,31,7],
[50,32,31,8],
[50,32,31,10],
[50,32,31,13],
[50,32,31,14],
[50,32,31,19],
[50,32,31,20],
[50,32,31,21],
[50,32,31,22],
[50,32,31,24],
[50,32,31,25],
[50,32,31,27],
[50,32,31,28],
[50,33,3,0],
[50,33,3,1],
[50,33,3,2],
[50,33,4,0],
[50,33,4,1],
[50,33,4,2],
[50,33,4,3],
[50,33,5,0],
[50,33,5,1],
[50,33,5,2],
[50,33,5,4],
[50,33,6,0],
[50,33,6,1],
[50,33,6,2],
[50,33,6,4],
[50,33,7,0],
[50,33,7,1],
[50,33,7,2],
[50,33,7,3],
[50,33,7,4],
[50,33,7,5],
[50,33,7,6],
[50,33,8,0],
[50,33,8,1],
[50,33,8,2],
[50,33,8,4],
[50,33,8,7],
[50,33,10,0],
[50,33,10,1],
[50,33,10,2],
[50,33,10,3],
[50,33,10,4],
[50,33,10,5],
[50,33,10,6],
[50,33,10,7],
[50,33,10,8],
[50,33,13,0],
[50,33,13,1],
[50,33,13,2],
[50,33,13,3],
[50,33,13,4],
[50,33,13,5],
[50,33,13,6],
[50,33,13,7],
[50,33,13,8],
[50,33,13,10],
[50,33,14,0],
[50,33,14,1],
[50,33,14,2],
[50,33,14,3],
[50,33,14,4],
[50,33,14,5],
[50,33,14,6],
[50,33,14,7],
[50,33,14,8],
[50,33,14,10],
[50,33,16,0],
[50,33,16,1],
[50,33,16,2],
[50,33,16,3],
[50,33,16,4],
[50,33,16,5],
[50,33,16,6],
[50,33,16,7],
[50,33,16,8],
[50,33,16,10],
[50,33,16,13],
[50,33,16,14],
[50,33,17,0],
[50,33,17,1],
[50,33,17,2],
[50,33,17,3],
[50,33,17,4],
[50,33,17,5],
[50,33,17,6],
[50,33,17,7],
[50,33,17,8],
[50,33,17,10],
[50,33,17,13],
[50,33,17,14],
[50,33,17,16],
[50,33,19,0],
[50,33,19,1],
[50,33,19,2],
[50,33,19,3],
[50,33,19,4],
[50,33,19,5],
[50,33,19,6],
[50,33,19,7],
[50,33,19,8],
[50,33,19,10],
[50,33,19,16],
[50,33,19,17],
[50,33,20,0],
[50,33,20,1],
[50,33,20,2],
[50,33,20,3],
[50,33,20,4],
[50,33,20,5],
[50,33,20,6],
[50,33,20,7],
[50,33,20,8],
[50,33,20,13],
[50,33,20,14],
[50,33,20,16],
[50,33,20,17],
[50,33,20,19],
[50,33,21,0],
[50,33,21,1],
[50,33,21,2],
[50,33,21,4],
[50,33,21,7],
[50,33,21,10],
[50,33,21,13],
[50,33,21,14],
[50,33,21,16],
[50,33,21,17],
[50,33,21,19],
[50,33,21,20],
[50,33,22,0],
[50,33,22,1],
[50,33,22,2],
[50,33,22,3],
[50,33,22,4],
[50,33,22,5],
[50,33,22,6],
[50,33,22,7],
[50,33,22,8],
[50,33,22,13],
[50,33,22,14],
[50,33,22,16],
[50,33,22,17],
[50,33,22,19],
[50,33,22,21],
[50,33,23,0],
[50,33,23,1],
[50,33,23,2],
[50,33,23,3],
[50,33,23,4],
[50,33,23,5],
[50,33,23,6],
[50,33,23,7],
[50,33,23,8],
[50,33,23,10],
[50,33,23,13],
[50,33,23,14],
[50,33,23,16],
[50,33,23,17],
[50,33,23,19],
[50,33,23,20],
[50,33,23,21],
[50,33,23,22],
[50,33,24,0],
[50,33,24,1],
[50,33,24,2],
[50,33,24,3],
[50,33,24,4],
[50,33,24,5],
[50,33,24,6],
[50,33,24,7],
[50,33,24,8],
[50,33,24,10],
[50,33,24,13],
[50,33,24,14],
[50,33,24,17],
[50,33,24,19],
[50,33,24,20],
[50,33,24,21],
[50,33,24,22],
[50,33,24,23],
[50,33,25,0],
[50,33,25,1],
[50,33,25,2],
[50,33,25,3],
[50,33,25,4],
[50,33,25,5],
[50,33,25,6],
[50,33,25,8],
[50,33,25,10],
[50,33,25,13],
[50,33,25,14],
[50,33,25,16],
[50,33,25,17],
[50,33,25,19],
[50,33,25,20],
[50,33,25,21],
[50,33,25,23],
[50,33,25,24],
[50,33,27,0],
[50,33,27,1],
[50,33,27,2],
[50,33,27,3],
[50,33,27,4],
[50,33,27,5],
[50,33,27,6],
[50,33,27,7],
[50,33,27,8],
[50,33,27,13],
[50,33,27,14],
[50,33,27,16],
[50,33,27,17],
[50,33,27,19],
[50,33,27,21],
[50,33,27,23],
[50,33,27,24],
[50,33,27,25],
[50,33,28,0],
[50,33,28,1],
[50,33,28,2],
[50,33,28,3],
[50,33,28,4],
[50,33,28,5],
[50,33,28,6],
[50,33,28,8],
[50,33,28,10],
[50,33,28,13],
[50,33,28,14],
[50,33,28,16],
[50,33,28,17],
[50,33,28,20],
[50,33,28,21],
[50,33,28,22],
[50,33,28,23],
[50,33,28,24],
[50,33,28,27],
[50,33,30,0],
[50,33,30,1],
[50,33,30,2],
[50,33,30,3],
[50,33,30,4],
[50,33,30,5],
[50,33,30,6],
[50,33,30,7],
[50,33,30,8],
[50,33,30,10],
[50,33,30,13],
[50,33,30,14],
[50,33,30,19],
[50,33,30,20],
[50,33,30,21],
[50,33,30,22],
[50,33,30,24],
[50,33,30,25],
[50,33,30,27],
[50,33,30,28],
[50,33,31,0],
[50,33,31,1],
[50,33,31,2],
[50,33,31,3],
[50,33,31,4],
[50,33,31,5],
[50,33,31,6],
[50,33,31,7],
[50,33,31,8],
[50,33,31,10],
[50,33,31,13],
[50,33,31,14],
[50,33,31,16],
[50,33,31,17],
[50,33,31,19],
[50,33,31,20],
[50,33,31,21],
[50,33,31,22],
[50,33,31,24],
[50,33,31,25],
[50,33,31,27],
[50,33,31,28],
[50,34,3,0],
[50,34,3,1],
[50,34,3,2],
[50,34,4,0],
[50,34,4,1],
[50,34,4,2],
[50,34,4,3],
[50,34,5,0],
[50,34,5,1],
[50,34,5,2],
[50,34,5,4],
[50,34,6,0],
[50,34,6,1],
[50,34,6,2],
[50,34,6,4],
[50,34,8,0],
[50,34,8,1],
[50,34,8,2],
[50,34,8,4],
[50,34,10,0],
[50,34,10,1],
[50,34,10,2],
[50,34,10,3],
[50,34,10,4],
[50,34,10,5],
[50,34,10,6],
[50,34,10,8],
[50,34,11,0],
[50,34,11,1],
[50,34,11,2],
[50,34,11,3],
[50,34,11,4],
[50,34,11,5],
[50,34,11,6],
[50,34,11,8],
[50,34,11,10],
[50,34,12,0],
[50,34,12,1],
[50,34,12,2],
[50,34,12,3],
[50,34,12,4],
[50,34,12,5],
[50,34,12,6],
[50,34,12,8],
[50,34,12,10],
[50,34,13,0],
[50,34,13,1],
[50,34,13,2],
[50,34,13,3],
[50,34,13,4],
[50,34,13,5],
[50,34,13,6],
[50,34,13,8],
[50,34,13,10],
[50,34,13,11],
[50,34,13,12],
[50,34,14,0],
[50,34,14,1],
[50,34,14,2],
[50,34,14,3],
[50,34,14,4],
[50,34,14,5],
[50,34,14,6],
[50,34,14,8],
[50,34,14,10],
[50,34,14,11],
[50,34,14,12],
[50,34,16,0],
[50,34,16,1],
[50,34,16,2],
[50,34,16,3],
[50,34,16,4],
[50,34,16,5],
[50,34,16,6],
[50,34,16,8],
[50,34,16,10],
[50,34,16,11],
[50,34,16,12],
[50,34,16,13],
[50,34,16,14],
[50,34,17,0],
[50,34,17,1],
[50,34,17,2],
[50,34,17,3],
[50,34,17,4],
[50,34,17,5],
[50,34,17,6],
[50,34,17,8],
[50,34,17,10],
[50,34,17,11],
[50,34,17,12],
[50,34,17,13],
[50,34,17,14],
[50,34,17,16],
[50,34,19,0],
[50,34,19,1],
[50,34,19,2],
[50,34,19,3],
[50,34,19,4],
[50,34,19,5],
[50,34,19,6],
[50,34,19,8],
[50,34,19,10],
[50,34,19,11],
[50,34,19,12],
[50,34,19,16],
[50,34,19,17],
[50,34,20,0],
[50,34,20,1],
[50,34,20,2],
[50,34,20,3],
[50,34,20,4],
[50,34,20,5],
[50,34,20,6],
[50,34,20,8],
[50,34,20,11],
[50,34,20,12],
[50,34,20,13],
[50,34,20,14],
[50,34,20,16],
[50,34,20,17],
[50,34,20,19],
[50,34,21,0],
[50,34,21,1],
[50,34,21,2],
[50,34,21,4],
[50,34,21,10],
[50,34,21,11],
[50,34,21,12],
[50,34,21,13],
[50,34,21,14],
[50,34,21,16],
[50,34,21,17],
[50,34,21,19],
[50,34,21,20],
[50,34,22,0],
[50,34,22,1],
[50,34,22,2],
[50,34,22,3],
[50,34,22,4],
[50,34,22,5],
[50,34,22,6],
[50,34,22,8],
[50,34,22,11],
[50,34,22,12],
[50,34,22,13],
[50,34,22,14],
[50,34,22,16],
[50,34,22,17],
[50,34,22,19],
[50,34,22,21],
[50,34,23,0],
[50,34,23,1],
[50,34,23,2],
[50,34,23,3],
[50,34,23,4],
[50,34,23,5],
[50,34,23,6],
[50,34,23,8],
[50,34,23,10],
[50,34,23,11],
[50,34,23,12],
[50,34,23,13],
[50,34,23,14],
[50,34,23,16],
[50,34,23,17],
[50,34,23,19],
[50,34,23,20],
[50,34,23,21],
[50,34,23,22],
[50,34,24,0],
[50,34,24,1],
[50,34,24,2],
[50,34,24,3],
[50,34,24,4],
[50,34,24,5],
[50,34,24,6],
[50,34,24,8],
[50,34,24,10],
[50,34,24,11],
[50,34,24,12],
[50,34,24,13],
[50,34,24,14],
[50,34,24,17],
[50,34,24,19],
[50,34,24,20],
[50,34,24,21],
[50,34,24,22],
[50,34,27,0],
[50,34,27,1],
[50,34,27,2],
[50,34,27,3],
[50,34,27,4],
[50,34,27,5],
[50,34,27,6],
[50,34,27,8],
[50,34,27,11],
[50,34,27,12],
[50,34,27,13],
[50,34,27,14],
[50,34,27,16],
[50,34,27,17],
[50,34,27,21],
[50,34,27,23],
[50,34,27,24],
[50,34,29,0],
[50,34,29,1],
[50,34,29,2],
[50,34,29,3],
[50,34,29,4],
[50,34,29,5],
[50,34,29,6],
[50,34,29,8],
[50,34,29,10],
[50,34,29,13],
[50,34,29,14],
[50,34,29,19],
[50,34,29,20],
[50,34,29,21],
[50,34,29,22],
[50,34,29,23],
[50,34,29,24],
[50,34,29,27],
[50,34,30,0],
[50,34,30,1],
[50,34,30,2],
[50,34,30,3],
[50,34,30,4],
[50,34,30,5],
[50,34,30,6],
[50,34,30,8],
[50,34,30,10],
[50,34,30,11],
[50,34,30,12],
[50,34,30,13],
[50,34,30,14],
[50,34,30,16],
[50,34,30,17],
[50,34,30,19],
[50,34,30,20],
[50,34,30,21],
[50,34,30,22],
[50,34,30,24],
[50,34,30,27],
[50,34,31,0],
[50,34,31,1],
[50,34,31,2],
[50,34,31,3],
[50,34,31,4],
[50,34,31,5],
[50,34,31,6],
[50,34,31,8],
[50,34,31,10],
[50,34,31,11],
[50,34,31,12],
[50,34,31,13],
[50,34,31,16],
[50,34,31,17],
[50,34,31,19],
[50,34,31,20],
[50,34,31,21],
[50,34,31,22],
[50,34,31,24],
[50,34,31,27],
[50,34,32,0],
[50,34,32,1],
[50,34,32,2],
[50,34,32,3],
[50,34,32,4],
[50,34,32,5],
[50,34,32,6],
[50,34,32,8],
[50,34,32,10],
[50,34,32,14],
[50,34,32,16],
[50,34,32,17],
[50,34,32,19],
[50,34,32,20],
[50,34,32,21],
[50,34,32,22],
[50,34,32,23],
[50,34,32,24],
[50,34,33,0],
[50,34,33,1],
[50,34,33,2],
[50,34,33,3],
[50,34,33,4],
[50,34,33,5],
[50,34,33,6],
[50,34,33,8],
[50,34,33,13],
[50,34,33,14],
[50,34,33,16],
[50,34,33,17],
[50,34,33,19],
[50,34,33,20],
[50,34,33,21],
[50,34,33,22],
[50,34,33,23],
[50,34,33,24],
[50,36,3,0],
[50,36,3,1],
[50,36,3,2],
[50,36,4,0],
[50,36,4,1],
[50,36,4,2],
[50,36,4,3],
[50,36,5,0],
[50,36,5,1],
[50,36,5,2],
[50,36,5,4],
[50,36,6,0],
[50,36,6,1],
[50,36,6,2],
[50,36,6,4],
[50,36,7,0],
[50,36,7,1],
[50,36,7,2],
[50,36,7,3],
[50,36,7,4],
[50,36,7,5],
[50,36,7,6],
[50,36,8,0],
[50,36,8,1],
[50,36,8,2],
[50,36,8,4],
[50,36,8,7],
[50,36,10,0],
[50,36,10,1],
[50,36,10,2],
[50,36,10,3],
[50,36,10,4],
[50,36,10,5],
[50,36,10,6],
[50,36,10,7],
[50,36,10,8],
[50,36,11,0],
[50,36,11,1],
[50,36,11,2],
[50,36,11,3],
[50,36,11,4],
[50,36,11,5],
[50,36,11,6],
[50,36,11,7],
[50,36,11,8],
[50,36,11,10],
[50,36,12,0],
[50,36,12,1],
[50,36,12,2],
[50,36,12,3],
[50,36,12,4],
[50,36,12,5],
[50,36,12,6],
[50,36,12,7],
[50,36,12,8],
[50,36,12,10],
[50,36,16,0],
[50,36,16,1],
[50,36,16,2],
[50,36,16,3],
[50,36,16,4],
[50,36,16,5],
[50,36,16,6],
[50,36,16,7],
[50,36,16,8],
[50,36,16,10],
[50,36,16,11],
[50,36,16,12],
[50,36,17,0],
[50,36,17,1],
[50,36,17,2],
[50,36,17,3],
[50,36,17,4],
[50,36,17,5],
[50,36,17,6],
[50,36,17,7],
[50,36,17,8],
[50,36,17,10],
[50,36,17,11],
[50,36,17,12],
[50,36,17,16],
[50,36,20,0],
[50,36,20,1],
[50,36,20,2],
[50,36,20,3],
[50,36,20,4],
[50,36,20,5],
[50,36,20,6],
[50,36,20,7],
[50,36,20,8],
[50,36,20,11],
[50,36,20,12],
[50,36,20,16],
[50,36,20,17],
[50,36,21,0],
[50,36,21,1],
[50,36,21,2],
[50,36,21,4],
[50,36,21,7],
[50,36,21,10],
[50,36,21,11],
[50,36,21,12],
[50,36,21,16],
[50,36,21,17],
[50,36,21,20],
[50,36,22,0],
[50,36,22,1],
[50,36,22,2],
[50,36,22,3],
[50,36,22,4],
[50,36,22,5],
[50,36,22,6],
[50,36,22,7],
[50,36,22,8],
[50,36,22,11],
[50,36,22,12],
[50,36,22,16],
[50,36,22,17],
[50,36,22,21],
[50,36,23,0],
[50,36,23,1],
[50,36,23,2],
[50,36,23,3],
[50,36,23,4],
[50,36,23,5],
[50,36,23,6],
[50,36,23,7],
[50,36,23,8],
[50,36,23,10],
[50,36,23,11],
[50,36,23,12],
[50,36,23,16],
[50,36,23,17],
[50,36,23,20],
[50,36,23,22],
[50,36,24,0],
[50,36,24,1],
[50,36,24,2],
[50,36,24,3],
[50,36,24,4],
[50,36,24,5],
[50,36,24,6],
[50,36,24,7],
[50,36,24,8],
[50,36,24,10],
[50,36,24,11],
[50,36,24,12],
[50,36,24,17],
[50,36,24,20],
[50,36,24,22],
[50,36,24,23],
[50,36,25,0],
[50,36,25,1],
[50,36,25,2],
[50,36,25,3],
[50,36,25,4],
[50,36,25,5],
[50,36,25,6],
[50,36,25,8],
[50,36,25,10],
[50,36,25,11],
[50,36,25,12],
[50,36,25,16],
[50,36,25,17],
[50,36,25,20],
[50,36,25,21],
[50,36,25,22],
[50,36,25,23],
[50,36,25,24],
[50,36,27,0],
[50,36,27,1],
[50,36,27,2],
[50,36,27,3],
[50,36,27,4],
[50,36,27,5],
[50,36,27,6],
[50,36,27,7],
[50,36,27,8],
[50,36,27,11],
[50,36,27,12],
[50,36,27,21],
[50,36,27,23],
[50,36,27,24],
[50,36,27,25],
[50,36,28,0],
[50,36,28,1],
[50,36,28,2],
[50,36,28,3],
[50,36,28,4],
[50,36,28,5],
[50,36,28,6],
[50,36,28,8],
[50,36,28,10],
[50,36,28,11],
[50,36,28,12],
[50,36,28,16],
[50,36,28,17],
[50,36,28,20],
[50,36,28,21],
[50,36,28,22],
[50,36,28,23],
[50,36,28,24],
[50,36,28,27],
[50,36,29,0],
[50,36,29,1],
[50,36,29,2],
[50,36,29,3],
[50,36,29,4],
[50,36,29,5],
[50,36,29,6],
[50,36,29,7],
[50,36,29,8],
[50,36,29,10],
[50,36,29,16],
[50,36,29,17],
[50,36,29,20],
[50,36,29,21],
[50,36,29,22],
[50,36,29,23],
[50,36,29,24],
[50,36,29,25],
[50,36,29,27],
[50,36,30,0],
[50,36,30,1],
[50,36,30,2],
[50,36,30,3],
[50,36,30,4],
[50,36,30,5],
[50,36,30,6],
[50,36,30,7],
[50,36,30,8],
[50,36,30,10],
[50,36,30,11],
[50,36,30,16],
[50,36,30,17],
[50,36,30,20],
[50,36,30,21],
[50,36,30,22],
[50,36,30,24],
[50,36,30,25],
[50,36,31,0],
[50,36,31,1],
[50,36,31,2],
[50,36,31,3],
[50,36,31,4],
[50,36,31,5],
[50,36,31,6],
[50,36,31,7],
[50,36,31,8],
[50,36,31,12],
[50,36,31,16],
[50,36,31,17],
[50,36,31,20],
[50,36,31,21],
[50,36,31,22],
[50,36,31,24],
[50,36,31,25],
[50,36,32,0],
[50,36,32,1],
[50,36,32,2],
[50,36,32,3],
[50,36,32,4],
[50,36,32,5],
[50,36,32,6],
[50,36,32,7],
[50,36,32,10],
[50,36,32,16],
[50,36,32,17],
[50,36,32,20],
[50,36,32,21],
[50,36,32,22],
[50,36,32,23],
[50,36,32,24],
[50,36,33,0],
[50,36,33,1],
[50,36,33,2],
[50,36,33,3],
[50,36,33,4],
[50,36,33,5],
[50,36,33,8],
[50,36,33,10],
[50,36,33,16],
[50,36,33,17],
[50,36,33,20],
[50,36,33,21],
[50,36,33,22],
[50,36,33,23],
[50,36,33,24],
[50,36,34,0],
[50,36,34,1],
[50,36,34,2],
[50,36,34,3],
[50,36,34,4],
[50,36,34,6],
[50,36,34,8],
[50,36,34,10],
[50,36,34,11],
[50,36,34,12],
[50,36,34,16],
[50,36,34,17],
[50,36,34,20],
[50,36,34,21],
[50,36,34,22],
[50,37,3,0],
[50,37,3,1],
[50,37,3,2],
[50,37,5,0],
[50,37,5,1],
[50,37,5,2],
[50,37,6,0],
[50,37,6,1],
[50,37,6,2],
[50,37,7,0],
[50,37,7,1],
[50,37,7,2],
[50,37,7,3],
[50,37,7,5],
[50,37,7,6],
[50,37,8,0],
[50,37,8,1],
[50,37,8,2],
[50,37,8,7],
[50,37,10,0],
[50,37,10,1],
[50,37,10,2],
[50,37,10,3],
[50,37,10,5],
[50,37,10,6],
[50,37,10,7],
[50,37,10,8],
[50,37,11,0],
[50,37,11,1],
[50,37,11,2],
[50,37,11,3],
[50,37,11,5],
[50,37,11,6],
[50,37,11,7],
[50,37,11,8],
[50,37,11,10],
[50,37,12,0],
[50,37,12,1],
[50,37,12,2],
[50,37,12,3],
[50,37,12,5],
[50,37,12,6],
[50,37,12,7],
[50,37,12,8],
[50,37,12,10],
[50,37,13,0],
[50,37,13,1],
[50,37,13,2],
[50,37,13,3],
[50,37,13,5],
[50,37,13,6],
[50,37,13,7],
[50,37,13,8],
[50,37,13,10],
[50,37,13,11],
[50,37,13,12],
[50,37,14,0],
[50,37,14,1],
[50,37,14,2],
[50,37,14,3],
[50,37,14,5],
[50,37,14,6],
[50,37,14,7],
[50,37,14,8],
[50,37,14,10],
[50,37,14,11],
[50,37,14,12],
[50,37,16,0],
[50,37,16,1],
[50,37,16,2],
[50,37,16,3],
[50,37,16,5],
[50,37,16,6],
[50,37,16,7],
[50,37,16,8],
[50,37,16,10],
[50,37,16,11],
[50,37,16,12],
[50,37,16,13],
[50,37,16,14],
[50,37,17,0],
[50,37,17,1],
[50,37,17,2],
[50,37,17,3],
[50,37,17,5],
[50,37,17,6],
[50,37,17,7],
[50,37,17,8],
[50,37,17,10],
[50,37,17,11],
[50,37,17,12],
[50,37,17,13],
[50,37,17,14],
[50,37,17,16],
[50,37,19,0],
[50,37,19,1],
[50,37,19,2],
[50,37,19,3],
[50,37,19,5],
[50,37,19,6],
[50,37,19,7],
[50,37,19,8],
[50,37,19,10],
[50,37,19,11],
[50,37,19,12],
[50,37,19,16],
[50,37,19,17],
[50,37,20,0],
[50,37,20,1],
[50,37,20,2],
[50,37,20,3],
[50,37,20,5],
[50,37,20,6],
[50,37,20,7],
[50,37,20,8],
[50,37,20,11],
[50,37,20,12],
[50,37,20,13],
[50,37,20,14],
[50,37,20,16],
[50,37,20,17],
[50,37,20,19],
[50,37,21,0],
[50,37,21,1],
[50,37,21,2],
[50,37,21,7],
[50,37,21,10],
[50,37,21,11],
[50,37,21,12],
[50,37,21,13],
[50,37,21,14],
[50,37,21,16],
[50,37,21,17],
[50,37,21,19],
[50,37,21,20],
[50,37,22,0],
[50,37,22,1],
[50,37,22,2],
[50,37,22,3],
[50,37,22,5],
[50,37,22,6],
[50,37,22,7],
[50,37,22,8],
[50,37,22,11],
[50,37,22,12],
[50,37,22,13],
[50,37,22,14],
[50,37,22,16],
[50,37,22,17],
[50,37,22,19],
[50,37,23,0],
[50,37,23,1],
[50,37,23,2],
[50,37,23,3],
[50,37,23,5],
[50,37,23,6],
[50,37,23,7],
[50,37,23,8],
[50,37,23,10],
[50,37,23,11],
[50,37,23,12],
[50,37,23,13],
[50,37,23,14],
[50,37,23,16],
[50,37,23,17],
[50,37,23,19],
[50,37,23,21],
[50,37,23,22],
[50,37,24,0],
[50,37,24,1],
[50,37,24,2],
[50,37,24,3],
[50,37,24,5],
[50,37,24,6],
[50,37,24,7],
[50,37,24,8],
[50,37,24,10],
[50,37,24,11],
[50,37,24,12],
[50,37,24,13],
[50,37,24,14],
[50,37,24,17],
[50,37,24,19],
[50,37,24,21],
[50,37,24,22],
[50,37,24,23],
[50,37,25,0],
[50,37,25,1],
[50,37,25,2],
[50,37,25,3],
[50,37,25,5],
[50,37,25,6],
[50,37,25,8],
[50,37,25,10],
[50,37,25,11],
[50,37,25,12],
[50,37,25,13],
[50,37,25,14],
[50,37,25,16],
[50,37,25,17],
[50,37,25,19],
[50,37,25,20],
[50,37,25,21],
[50,37,25,22],
[50,37,25,23],
[50,37,25,24],
[50,37,27,0],
[50,37,27,1],
[50,37,27,2],
[50,37,27,3],
[50,37,27,5],
[50,37,27,6],
[50,37,27,7],
[50,37,27,8],
[50,37,27,11],
[50,37,27,12],
[50,37,27,13],
[50,37,27,14],
[50,37,27,16],
[50,37,27,17],
[50,37,27,19],
[50,37,27,21],
[50,37,27,23],
[50,37,27,24],
[50,37,27,25],
[50,37,28,0],
[50,37,28,1],
[50,37,28,2],
[50,37,28,3],
[50,37,28,5],
[50,37,28,6],
[50,37,28,8],
[50,37,28,10],
[50,37,28,11],
[50,37,28,12],
[50,37,28,13],
[50,37,28,16],
[50,37,28,17],
[50,37,28,19],
[50,37,28,20],
[50,37,28,21],
[50,37,28,22],
[50,37,28,23],
[50,37,28,24],
[50,37,28,27],
[50,37,29,0],
[50,37,29,1],
[50,37,29,2],
[50,37,29,3],
[50,37,29,5],
[50,37,29,6],
[50,37,29,7],
[50,37,29,8],
[50,37,29,10],
[50,37,29,14],
[50,37,29,16],
[50,37,29,17],
[50,37,29,19],
[50,37,29,20],
[50,37,29,21],
[50,37,29,22],
[50,37,29,23],
[50,37,29,24],
[50,37,29,25],
[50,37,30,0],
[50,37,30,1],
[50,37,30,2],
[50,37,30,3],
[50,37,30,5],
[50,37,30,6],
[50,37,30,7],
[50,37,30,8],
[50,37,30,12],
[50,37,30,13],
[50,37,30,14],
[50,37,30,16],
[50,37,30,17],
[50,37,30,19],
[50,37,30,20],
[50,37,30,21],
[50,37,30,22],
[50,37,30,24],
[50,37,30,25],
[50,37,31,0],
[50,37,31,1],
[50,37,31,2],
[50,37,31,3],
[50,37,31,5],
[50,37,31,6],
[50,37,31,7],
[50,37,31,10],
[50,37,31,11],
[50,37,31,12],
[50,37,31,13],
[50,37,31,14],
[50,37,31,16],
[50,37,31,17],
[50,37,31,19],
[50,37,31,20],
[50,37,31,21],
[50,37,31,22],
[50,37,31,24],
[50,37,32,0],
[50,37,32,1],
[50,37,32,2],
[50,37,32,3],
[50,37,32,5],
[50,37,32,8],
[50,37,32,10],
[50,37,32,13],
[50,37,32,14],
[50,37,32,16],
[50,37,32,17],
[50,37,32,19],
[50,37,32,20],
[50,37,32,21],
[50,37,32,22],
[50,37,32,23],
[50,37,32,24],
[50,37,33,0],
[50,37,33,1],
[50,37,33,2],
[50,37,33,3],
[50,37,33,6],
[50,37,33,7],
[50,37,33,8],
[50,37,33,10],
[50,37,33,13],
[50,37,33,14],
[50,37,33,16],
[50,37,33,17],
[50,37,33,19],
[50,37,33,20],
[50,37,33,21],
[50,37,33,22],
[50,37,34,0],
[50,37,34,1],
[50,37,34,2],
[50,37,34,5],
[50,37,34,6],
[50,37,34,8],
[50,37,34,10],
[50,37,34,11],
[50,37,34,12],
[50,37,34,13],
[50,37,34,14],
[50,37,34,16],
[50,37,34,17],
[50,37,34,19],
[50,37,34,20],
[50,37,34,21],
[50,37,36,0],
[50,37,36,1],
[50,37,36,2],
[50,37,36,3],
[50,37,36,5],
[50,37,36,6],
[50,37,36,7],
[50,37,36,8],
[50,37,36,10],
[50,37,36,11],
[50,37,36,12],
[50,37,36,16],
[50,37,36,17],
[50,38,3,0],
[50,38,3,1],
[50,38,3,2],
[50,38,4,0],
[50,38,4,1],
[50,38,4,2],
[50,38,4,3],
[50,38,5,0],
[50,38,5,1],
[50,38,5,2],
[50,38,5,4],
[50,38,6,0],
[50,38,6,1],
[50,38,6,2],
[50,38,6,4],
[50,38,8,0],
[50,38,8,1],
[50,38,8,2],
[50,38,8,4],
[50,38,10,0],
[50,38,10,1],
[50,38,10,2],
[50,38,10,3],
[50,38,10,4],
[50,38,10,5],
[50,38,10,6],
[50,38,10,8],
[50,38,11,0],
[50,38,11,1],
[50,38,11,2],
[50,38,11,3],
[50,38,11,4],
[50,38,11,5],
[50,38,11,6],
[50,38,11,8],
[50,38,11,10],
[50,38,12,0],
[50,38,12,1],
[50,38,12,2],
[50,38,12,3],
[50,38,12,4],
[50,38,12,5],
[50,38,12,6],
[50,38,12,8],
[50,38,12,10],
[50,38,13,0],
[50,38,13,1],
[50,38,13,2],
[50,38,13,3],
[50,38,13,4],
[50,38,13,5],
[50,38,13,6],
[50,38,13,8],
[50,38,13,10],
[50,38,13,11],
[50,38,13,12],
[50,38,14,0],
[50,38,14,1],
[50,38,14,2],
[50,38,14,3],
[50,38,14,4],
[50,38,14,5],
[50,38,14,6],
[50,38,14,8],
[50,38,14,10],
[50,38,14,11],
[50,38,14,12],
[50,38,16,0],
[50,38,16,1],
[50,38,16,2],
[50,38,16,3],
[50,38,16,4],
[50,38,16,5],
[50,38,16,6],
[50,38,16,8],
[50,38,16,10],
[50,38,16,11],
[50,38,16,12],
[50,38,16,13],
[50,38,16,14],
[50,38,17,0],
[50,38,17,1],
[50,38,17,2],
[50,38,17,3],
[50,38,17,4],
[50,38,17,5],
[50,38,17,6],
[50,38,17,8],
[50,38,17,10],
[50,38,17,11],
[50,38,17,12],
[50,38,17,13],
[50,38,17,14],
[50,38,17,16],
[50,38,19,0],
[50,38,19,1],
[50,38,19,2],
[50,38,19,3],
[50,38,19,4],
[50,38,19,5],
[50,38,19,6],
[50,38,19,8],
[50,38,19,10],
[50,38,19,11],
[50,38,19,12],
[50,38,19,16],
[50,38,19,17],
[50,38,20,0],
[50,38,20,1],
[50,38,20,2],
[50,38,20,3],
[50,38,20,4],
[50,38,20,5],
[50,38,20,6],
[50,38,20,8],
[50,38,20,11],
[50,38,20,12],
[50,38,20,13],
[50,38,20,14],
[50,38,20,16],
[50,38,20,17],
[50,38,20,19],
[50,38,21,0],
[50,38,21,1],
[50,38,21,2],
[50,38,21,4],
[50,38,21,10],
[50,38,21,11],
[50,38,21,12],
[50,38,21,13],
[50,38,21,14],
[50,38,21,16],
[50,38,21,17],
[50,38,21,19],
[50,38,21,20],
[50,38,22,0],
[50,38,22,1],
[50,38,22,2],
[50,38,22,3],
[50,38,22,4],
[50,38,22,5],
[50,38,22,6],
[50,38,22,8],
[50,38,22,11],
[50,38,22,12],
[50,38,22,13],
[50,38,22,14],
[50,38,22,16],
[50,38,22,17],
[50,38,22,19],
[50,38,22,21],
[50,38,23,0],
[50,38,23,1],
[50,38,23,2],
[50,38,23,3],
[50,38,23,4],
[50,38,23,5],
[50,38,23,6],
[50,38,23,8],
[50,38,23,10],
[50,38,23,11],
[50,38,23,12],
[50,38,23,13],
[50,38,23,14],
[50,38,23,16],
[50,38,23,17],
[50,38,23,20],
[50,38,23,21],
[50,38,23,22],
[50,38,24,0],
[50,38,24,1],
[50,38,24,2],
[50,38,24,3],
[50,38,24,4],
[50,38,24,5],
[50,38,24,6],
[50,38,24,8],
[50,38,24,10],
[50,38,24,11],
[50,38,24,12],
[50,38,24,13],
[50,38,24,14],
[50,38,24,17],
[50,38,24,20],
[50,38,24,21],
[50,38,24,22],
[50,38,24,23],
[50,38,27,0],
[50,38,27,1],
[50,38,27,2],
[50,38,27,3],
[50,38,27,4],
[50,38,27,5],
[50,38,27,6],
[50,38,27,8],
[50,38,27,11],
[50,38,27,12],
[50,38,27,13],
[50,38,27,16],
[50,38,27,17],
[50,38,27,19],
[50,38,27,21],
[50,38,27,23],
[50,38,27,24],
[50,38,29,0],
[50,38,29,1],
[50,38,29,2],
[50,38,29,3],
[50,38,29,4],
[50,38,29,5],
[50,38,29,6],
[50,38,29,8],
[50,38,29,13],
[50,38,29,14],
[50,38,29,16],
[50,38,29,17],
[50,38,29,19],
[50,38,29,20],
[50,38,29,21],
[50,38,29,22],
[50,38,29,23],
[50,38,29,24],
[50,38,30,0],
[50,38,30,1],
[50,38,30,2],
[50,38,30,3],
[50,38,30,4],
[50,38,30,5],
[50,38,30,6],
[50,38,30,10],
[50,38,30,11],
[50,38,30,12],
[50,38,30,13],
[50,38,30,14],
[50,38,30,16],
[50,38,30,17],
[50,38,30,19],
[50,38,30,20],
[50,38,30,21],
[50,38,30,22],
[50,38,30,24],
[50,38,31,0],
[50,38,31,1],
[50,38,31,2],
[50,38,31,3],
[50,38,31,4],
[50,38,31,5],
[50,38,31,8],
[50,38,31,10],
[50,38,31,11],
[50,38,31,12],
[50,38,31,13],
[50,38,31,14],
[50,38,31,16],
[50,38,31,17],
[50,38,31,19],
[50,38,31,20],
[50,38,31,21],
[50,38,31,22],
[50,38,31,24],
[50,38,32,0],
[50,38,32,1],
[50,38,32,2],
[50,38,32,3],
[50,38,32,4],
[50,38,32,6],
[50,38,32,8],
[50,38,32,10],
[50,38,32,13],
[50,38,32,14],
[50,38,32,16],
[50,38,32,17],
[50,38,32,19],
[50,38,32,20],
[50,38,32,21],
[50,38,32,22],
[50,38,33,0],
[50,38,33,1],
[50,38,33,2],
[50,38,33,5],
[50,38,33,6],
[50,38,33,8],
[50,38,33,10],
[50,38,33,13],
[50,38,33,14],
[50,38,33,16],
[50,38,33,17],
[50,38,33,19],
[50,38,33,20],
[50,38,33,21],
[50,38,36,0],
[50,38,36,1],
[50,38,36,2],
[50,38,36,3],
[50,38,36,4],
[50,38,36,5],
[50,38,36,6],
[50,38,36,8],
[50,38,36,10],
[50,38,36,11],
[50,38,36,12],
[50,38,36,16],
[50,38,36,17],
[50,38,37,0],
[50,38,37,1],
[50,38,37,2],
[50,38,37,3],
[50,38,37,5],
[50,38,37,6],
[50,38,37,8],
[50,38,37,10],
[50,38,37,11],
[50,38,37,12],
[50,38,37,13],
[50,38,37,14],
[50,38,37,16],
[50,38,37,17],
[50,39,4,0],
[50,39,4,1],
[50,39,4,2],
[50,39,7,0],
[50,39,7,1],
[50,39,7,2],
[50,39,7,4],
[50,39,10,0],
[50,39,10,1],
[50,39,10,2],
[50,39,10,4],
[50,39,10,7],
[50,39,11,0],
[50,39,11,1],
[50,39,11,2],
[50,39,11,4],
[50,39,11,7],
[50,39,11,10],
[50,39,12,0],
[50,39,12,1],
[50,39,12,2],
[50,39,12,4],
[50,39,12,7],
[50,39,12,10],
[50,39,13,0],
[50,39,13,1],
[50,39,13,2],
[50,39,13,4],
[50,39,13,7],
[50,39,13,10],
[50,39,13,11],
[50,39,13,12],
[50,39,14,0],
[50,39,14,1],
[50,39,14,2],
[50,39,14,4],
[50,39,14,7],
[50,39,14,10],
[50,39,14,11],
[50,39,14,12],
[50,39,16,0],
[50,39,16,1],
[50,39,16,2],
[50,39,16,4],
[50,39,16,7],
[50,39,16,10],
[50,39,16,11],
[50,39,16,12],
[50,39,16,13],
[50,39,16,14],
[50,39,17,0],
[50,39,17,1],
[50,39,17,2],
[50,39,17,4],
[50,39,17,7],
[50,39,17,10],
[50,39,17,11],
[50,39,17,12],
[50,39,17,13],
[50,39,17,14],
[50,39,17,16],
[50,39,19,0],
[50,39,19,1],
[50,39,19,2],
[50,39,19,4],
[50,39,19,7],
[50,39,19,10],
[50,39,19,11],
[50,39,19,12],
[50,39,19,16],
[50,39,19,17],
[50,39,20,0],
[50,39,20,1],
[50,39,20,2],
[50,39,20,4],
[50,39,20,7],
[50,39,20,11],
[50,39,20,12],
[50,39,20,13],
[50,39,20,14],
[50,39,20,16],
[50,39,20,17],
[50,39,20,19],
[50,39,22,0],
[50,39,22,1],
[50,39,22,2],
[50,39,22,4],
[50,39,22,7],
[50,39,22,11],
[50,39,22,12],
[50,39,22,13],
[50,39,22,14],
[50,39,22,16],
[50,39,22,17],
[50,39,23,0],
[50,39,23,1],
[50,39,23,2],
[50,39,23,4],
[50,39,23,7],
[50,39,23,10],
[50,39,23,11],
[50,39,23,12],
[50,39,23,13],
[50,39,23,14],
[50,39,23,16],
[50,39,23,17],
[50,39,23,19],
[50,39,23,20],
[50,39,23,22],
[50,39,24,0],
[50,39,24,1],
[50,39,24,2],
[50,39,24,4],
[50,39,24,7],
[50,39,24,10],
[50,39,24,11],
[50,39,24,12],
[50,39,24,13],
[50,39,24,14],
[50,39,24,17],
[50,39,24,19],
[50,39,24,20],
[50,39,24,22],
[50,39,24,23],
[50,39,25,0],
[50,39,25,1],
[50,39,25,2],
[50,39,25,4],
[50,39,25,10],
[50,39,25,11],
[50,39,25,12],
[50,39,25,13],
[50,39,25,14],
[50,39,25,16],
[50,39,25,17],
[50,39,25,19],
[50,39,25,20],
[50,39,25,22],
[50,39,25,23],
[50,39,25,24],
[50,39,27,0],
[50,39,27,1],
[50,39,27,2],
[50,39,27,4],
[50,39,27,7],
[50,39,27,11],
[50,39,27,14],
[50,39,27,16],
[50,39,27,17],
[50,39,27,19],
[50,39,27,23],
[50,39,27,24],
[50,39,27,25],
[50,39,28,0],
[50,39,28,1],
[50,39,28,2],
[50,39,28,4],
[50,39,28,12],
[50,39,28,13],
[50,39,28,14],
[50,39,28,16],
[50,39,28,17],
[50,39,28,19],
[50,39,28,20],
[50,39,28,22],
[50,39,28,23],
[50,39,28,24],
[50,39,29,0],
[50,39,29,1],
[50,39,29,2],
[50,39,29,4],
[50,39,29,7],
[50,39,29,10],
[50,39,29,13],
[50,39,29,14],
[50,39,29,16],
[50,39,29,17],
[50,39,29,19],
[50,39,29,20],
[50,39,29,22],
[50,39,29,23],
[50,39,29,24],
[50,39,30,0],
[50,39,30,1],
[50,39,30,2],
[50,39,30,4],
[50,39,30,10],
[50,39,30,11],
[50,39,30,12],
[50,39,30,13],
[50,39,30,14],
[50,39,30,16],
[50,39,30,17],
[50,39,30,19],
[50,39,30,20],
[50,39,30,22],
[50,39,30,24],
[50,39,31,0],
[50,39,31,1],
[50,39,31,2],
[50,39,31,4],
[50,39,31,7],
[50,39,31,10],
[50,39,31,11],
[50,39,31,12],
[50,39,31,13],
[50,39,31,14],
[50,39,31,16],
[50,39,31,17],
[50,39,31,19],
[50,39,31,20],
[50,39,31,22],
[50,39,32,0],
[50,39,32,1],
[50,39,32,2],
[50,39,32,7],
[50,39,32,10],
[50,39,32,13],
[50,39,32,14],
[50,39,32,16],
[50,39,32,17],
[50,39,32,19],
[50,39,32,20],
[50,39,33,4],
[50,39,33,7],
[50,39,33,10],
[50,39,33,13],
[50,39,33,14],
[50,39,33,16],
[50,39,33,17],
[50,39,33,19],
[50,39,33,20],
[50,39,34,0],
[50,39,34,1],
[50,39,34,2],
[50,39,34,4],
[50,39,34,10],
[50,39,34,11],
[50,39,34,12],
[50,39,34,13],
[50,39,34,14],
[50,39,34,16],
[50,39,34,17],
[50,39,34,19],
[50,39,36,0],
[50,39,36,1],
[50,39,36,2],
[50,39,36,4],
[50,39,36,7],
[50,39,36,10],
[50,39,36,11],
[50,39,36,12],
[50,39,36,16],
[50,39,36,17],
[50,39,37,0],
[50,39,37,1],
[50,39,37,2],
[50,39,37,7],
[50,39,37,10],
[50,39,37,11],
[50,39,37,12],
[50,39,37,13],
[50,39,37,14],
[50,39,38,0],
[50,39,38,1],
[50,39,38,2],
[50,39,38,4],
[50,39,38,10],
[50,39,38,11],
[50,39,38,12],
[50,39,38,13],
[50,39,38,14],
[50,42,3,0],
[50,42,3,1],
[50,42,3,2],
[50,42,4,0],
[50,42,4,1],
[50,42,4,2],
[50,42,4,3],
[50,42,5,0],
[50,42,5,1],
[50,42,5,2],
[50,42,5,4],
[50,42,6,0],
[50,42,6,1],
[50,42,6,2],
[50,42,6,4],
[50,42,7,0],
[50,42,7,1],
[50,42,7,2],
[50,42,7,3],
[50,42,7,4],
[50,42,7,5],
[50,42,7,6],
[50,42,8,0],
[50,42,8,1],
[50,42,8,2],
[50,42,8,4],
[50,42,8,7],
[50,42,11,0],
[50,42,11,1],
[50,42,11,2],
[50,42,11,3],
[50,42,11,4],
[50,42,11,5],
[50,42,11,6],
[50,42,11,7],
[50,42,11,8],
[50,42,12,0],
[50,42,12,1],
[50,42,12,2],
[50,42,12,3],
[50,42,12,4],
[50,42,12,5],
[50,42,12,6],
[50,42,12,7],
[50,42,12,8],
[50,42,13,0],
[50,42,13,1],
[50,42,13,2],
[50,42,13,3],
[50,42,13,4],
[50,42,13,5],
[50,42,13,6],
[50,42,13,7],
[50,42,13,8],
[50,42,13,11],
[50,42,13,12],
[50,42,14,0],
[50,42,14,1],
[50,42,14,2],
[50,42,14,3],
[50,42,14,4],
[50,42,14,5],
[50,42,14,6],
[50,42,14,7],
[50,42,14,8],
[50,42,14,11],
[50,42,14,12],
[50,42,16,0],
[50,42,16,1],
[50,42,16,2],
[50,42,16,3],
[50,42,16,4],
[50,42,16,5],
[50,42,16,6],
[50,42,16,7],
[50,42,16,8],
[50,42,16,11],
[50,42,16,12],
[50,42,16,13],
[50,42,16,14],
[50,42,17,0],
[50,42,17,1],
[50,42,17,2],
[50,42,17,3],
[50,42,17,4],
[50,42,17,5],
[50,42,17,6],
[50,42,17,7],
[50,42,17,8],
[50,42,17,11],
[50,42,17,12],
[50,42,17,13],
[50,42,17,14],
[50,42,17,16],
[50,42,19,0],
[50,42,19,1],
[50,42,19,2],
[50,42,19,3],
[50,42,19,4],
[50,42,19,5],
[50,42,19,6],
[50,42,19,7],
[50,42,19,8],
[50,42,19,11],
[50,42,19,12],
[50,42,19,16],
[50,42,19,17],
[50,42,21,0],
[50,42,21,1],
[50,42,21,2],
[50,42,21,4],
[50,42,21,7],
[50,42,21,11],
[50,42,21,12],
[50,42,21,13],
[50,42,21,14],
[50,42,21,16],
[50,42,21,17],
[50,42,21,19],
[50,42,23,0],
[50,42,23,1],
[50,42,23,2],
[50,42,23,3],
[50,42,23,4],
[50,42,23,5],
[50,42,23,6],
[50,42,23,7],
[50,42,23,8],
[50,42,23,11],
[50,42,23,12],
[50,42,23,13],
[50,42,23,14],
[50,42,23,16],
[50,42,23,17],
[50,42,23,19],
[50,42,23,21],
[50,42,24,0],
[50,42,24,1],
[50,42,24,2],
[50,42,24,3],
[50,42,24,4],
[50,42,24,5],
[50,42,24,6],
[50,42,24,7],
[50,42,24,8],
[50,42,24,11],
[50,42,24,12],
[50,42,24,13],
[50,42,24,14],
[50,42,24,17],
[50,42,24,19],
[50,42,24,21],
[50,42,24,23],
[50,42,25,0],
[50,42,25,1],
[50,42,25,2],
[50,42,25,3],
[50,42,25,4],
[50,42,25,5],
[50,42,25,6],
[50,42,25,8],
[50,42,25,11],
[50,42,25,14],
[50,42,25,16],
[50,42,25,17],
[50,42,25,19],
[50,42,25,21],
[50,42,25,23],
[50,42,25,24],
[50,42,28,0],
[50,42,28,1],
[50,42,28,2],
[50,42,28,3],
[50,42,28,4],
[50,42,28,5],
[50,42,28,8],
[50,42,28,11],
[50,42,28,12],
[50,42,28,13],
[50,42,28,14],
[50,42,28,16],
[50,42,28,17],
[50,42,28,19],
[50,42,28,21],
[50,42,28,23],
[50,42,28,24],
[50,42,29,0],
[50,42,29,1],
[50,42,29,2],
[50,42,29,3],
[50,42,29,4],
[50,42,29,6],
[50,42,29,7],
[50,42,29,8],
[50,42,29,13],
[50,42,29,14],
[50,42,29,16],
[50,42,29,17],
[50,42,29,19],
[50,42,29,21],
[50,42,30,0],
[50,42,30,1],
[50,42,30,2],
[50,42,30,5],
[50,42,30,6],
[50,42,30,7],
[50,42,30,8],
[50,42,30,11],
[50,42,30,12],
[50,42,30,13],
[50,42,30,14],
[50,42,30,16],
[50,42,30,17],
[50,42,30,19],
[50,42,30,21],
[50,42,31,3],
[50,42,31,4],
[50,42,31,5],
[50,42,31,6],
[50,42,31,7],
[50,42,31,8],
[50,42,31,11],
[50,42,31,12],
[50,42,31,13],
[50,42,31,14],
[50,42,31,16],
[50,42,31,17],
[50,42,31,19],
[50,42,32,0],
[50,42,32,1],
[50,42,32,2],
[50,42,32,3],
[50,42,32,4],
[50,42,32,5],
[50,42,32,6],
[50,42,32,7],
[50,42,32,8],
[50,42,32,13],
[50,42,32,14],
[50,42,32,16],
[50,42,32,17],
[50,42,32,19],
[50,42,33,0],
[50,42,33,1],
[50,42,33,2],
[50,42,33,3],
[50,42,33,4],
[50,42,33,5],
[50,42,33,6],
[50,42,33,7],
[50,42,33,8],
[50,42,33,13],
[50,42,33,14],
[50,42,33,16],
[50,42,33,17],
[50,42,34,0],
[50,42,34,1],
[50,42,34,2],
[50,42,34,3],
[50,42,34,4],
[50,42,34,5],
[50,42,34,6],
[50,42,34,8],
[50,42,34,11],
[50,42,34,12],
[50,42,34,13],
[50,42,34,14],
[50,42,34,16],
[50,42,34,17],
[50,42,36,0],
[50,42,36,1],
[50,42,36,2],
[50,42,36,3],
[50,42,36,4],
[50,42,36,5],
[50,42,36,6],
[50,42,36,7],
[50,42,36,8],
[50,42,36,11],
[50,42,36,12],
[50,42,37,0],
[50,42,37,1],
[50,42,37,2],
[50,42,37,3],
[50,42,37,5],
[50,42,37,6],
[50,42,37,7],
[50,42,37,8],
[50,42,37,11],
[50,42,37,12],
[50,42,37,13],
[50,42,38,0],
[50,42,38,1],
[50,42,38,2],
[50,42,38,3],
[50,42,38,4],
[50,42,38,5],
[50,42,38,6],
[50,42,38,8],
[50,42,38,11],
[50,42,39,0],
[50,42,39,1],
[50,42,39,2],
[50,42,39,4],
[50,42,39,7],
[50,43,3,0],
[50,43,3,1],
[50,43,3,2],
[50,43,4,0],
[50,43,4,1],
[50,43,4,2],
[50,43,4,3],
[50,43,5,0],
[50,43,5,1],
[50,43,5,2],
[50,43,5,4],
[50,43,6,0],
[50,43,6,1],
[50,43,6,2],
[50,43,6,4],
[50,43,8,0],
[50,43,8,1],
[50,43,8,2],
[50,43,8,4],
[50,43,10,0],
[50,43,10,1],
[50,43,10,2],
[50,43,10,3],
[50,43,10,4],
[50,43,10,5],
[50,43,10,6],
[50,43,10,8],
[50,43,11,0],
[50,43,11,1],
[50,43,11,2],
[50,43,11,3],
[50,43,11,4],
[50,43,11,5],
[50,43,11,6],
[50,43,11,8],
[50,43,11,10],
[50,43,12,0],
[50,43,12,1],
[50,43,12,2],
[50,43,12,3],
[50,43,12,4],
[50,43,12,5],
[50,43,12,6],
[50,43,12,8],
[50,43,12,10],
[50,43,13,0],
[50,43,13,1],
[50,43,13,2],
[50,43,13,3],
[50,43,13,4],
[50,43,13,5],
[50,43,13,6],
[50,43,13,8],
[50,43,13,10],
[50,43,13,11],
[50,43,13,12],
[50,43,14,0],
[50,43,14,1],
[50,43,14,2],
[50,43,14,3],
[50,43,14,4],
[50,43,14,5],
[50,43,14,6],
[50,43,14,8],
[50,43,14,10],
[50,43,14,11],
[50,43,14,12],
[50,43,16,0],
[50,43,16,1],
[50,43,16,2],
[50,43,16,3],
[50,43,16,4],
[50,43,16,5],
[50,43,16,6],
[50,43,16,8],
[50,43,16,10],
[50,43,16,11],
[50,43,16,12],
[50,43,16,13],
[50,43,16,14],
[50,43,17,0],
[50,43,17,1],
[50,43,17,2],
[50,43,17,3],
[50,43,17,4],
[50,43,17,5],
[50,43,17,6],
[50,43,17,8],
[50,43,17,10],
[50,43,17,11],
[50,43,17,12],
[50,43,17,13],
[50,43,17,14],
[50,43,17,16],
[50,43,19,0],
[50,43,19,1],
[50,43,19,2],
[50,43,19,3],
[50,43,19,4],
[50,43,19,5],
[50,43,19,6],
[50,43,19,8],
[50,43,19,10],
[50,43,19,11],
[50,43,19,12],
[50,43,19,16],
[50,43,19,17],
[50,43,20,0],
[50,43,20,1],
[50,43,20,2],
[50,43,20,3],
[50,43,20,4],
[50,43,20,5],
[50,43,20,6],
[50,43,20,8],
[50,43,20,11],
[50,43,20,12],
[50,43,20,13],
[50,43,20,14],
[50,43,20,16],
[50,43,20,17],
[50,43,20,19],
[50,43,21,0],
[50,43,21,1],
[50,43,21,2],
[50,43,21,4],
[50,43,21,10],
[50,43,21,11],
[50,43,21,12],
[50,43,21,13],
[50,43,21,14],
[50,43,21,19],
[50,43,21,20],
[50,43,22,0],
[50,43,22,1],
[50,43,22,2],
[50,43,22,3],
[50,43,22,4],
[50,43,22,5],
[50,43,22,6],
[50,43,22,8],
[50,43,22,11],
[50,43,22,12],
[50,43,22,13],
[50,43,22,14],
[50,43,22,16],
[50,43,22,17],
[50,43,22,19],
[50,43,22,21],
[50,43,23,0],
[50,43,23,1],
[50,43,23,2],
[50,43,23,3],
[50,43,23,4],
[50,43,23,5],
[50,43,23,6],
[50,43,23,8],
[50,43,23,10],
[50,43,23,11],
[50,43,23,12],
[50,43,23,13],
[50,43,23,16],
[50,43,23,17],
[50,43,23,19],
[50,43,23,20],
[50,43,23,21],
[50,43,23,22],
[50,43,24,0],
[50,43,24,1],
[50,43,24,2],
[50,43,24,3],
[50,43,24,4],
[50,43,24,5],
[50,43,24,6],
[50,43,24,8],
[50,43,24,10],
[50,43,24,11],
[50,43,24,12],
[50,43,24,13],
[50,43,24,17],
[50,43,24,19],
[50,43,24,20],
[50,43,24,21],
[50,43,24,22],
[50,43,24,23],
[50,43,27,0],
[50,43,27,1],
[50,43,27,2],
[50,43,27,3],
[50,43,27,4],
[50,43,27,5],
[50,43,27,8],
[50,43,27,11],
[50,43,27,12],
[50,43,27,13],
[50,43,27,14],
[50,43,27,16],
[50,43,27,17],
[50,43,27,19],
[50,43,27,21],
[50,43,27,23],
[50,43,27,24],
[50,43,29,0],
[50,43,29,1],
[50,43,29,2],
[50,43,29,5],
[50,43,29,6],
[50,43,29,8],
[50,43,29,10],
[50,43,29,13],
[50,43,29,14],
[50,43,29,16],
[50,43,29,17],
[50,43,29,19],
[50,43,29,20],
[50,43,29,21],
[50,43,30,3],
[50,43,30,4],
[50,43,30,5],
[50,43,30,6],
[50,43,30,8],
[50,43,30,10],
[50,43,30,11],
[50,43,30,12],
[50,43,30,13],
[50,43,30,14],
[50,43,30,16],
[50,43,30,17],
[50,43,30,19],
[50,43,30,20],
[50,43,31,0],
[50,43,31,1],
[50,43,31,2],
[50,43,31,3],
[50,43,31,4],
[50,43,31,5],
[50,43,31,6],
[50,43,31,8],
[50,43,31,10],
[50,43,31,11],
[50,43,31,12],
[50,43,31,13],
[50,43,31,14],
[50,43,31,16],
[50,43,31,17],
[50,43,31,19],
[50,43,32,0],
[50,43,32,1],
[50,43,32,2],
[50,43,32,3],
[50,43,32,4],
[50,43,32,5],
[50,43,32,6],
[50,43,32,8],
[50,43,32,10],
[50,43,32,13],
[50,43,32,14],
[50,43,32,16],
[50,43,32,17],
[50,43,33,0],
[50,43,33,1],
[50,43,33,2],
[50,43,33,3],
[50,43,33,4],
[50,43,33,5],
[50,43,33,6],
[50,43,33,8],
[50,43,33,10],
[50,43,33,13],
[50,43,33,14],
[50,43,33,16],
[50,43,33,17],
[50,43,36,0],
[50,43,36,1],
[50,43,36,2],
[50,43,36,3],
[50,43,36,4],
[50,43,36,5],
[50,43,36,6],
[50,43,36,8],
[50,43,36,10],
[50,43,36,11],
[50,43,36,12],
[50,43,37,0],
[50,43,37,1],
[50,43,37,2],
[50,43,37,3],
[50,43,37,5],
[50,43,37,6],
[50,43,37,8],
[50,43,37,10],
[50,43,37,11],
[50,43,39,0],
[50,43,39,1],
[50,43,39,2],
[50,43,39,4],
[50,43,42,0],
[50,43,42,1],
[50,43,42,2],
[50,43,42,3],
[50,43,42,4],
[50,44,3,0],
[50,44,3,1],
[50,44,3,2],
[50,44,4,0],
[50,44,4,1],
[50,44,4,2],
[50,44,4,3],
[50,44,5,0],
[50,44,5,1],
[50,44,5,2],
[50,44,5,4],
[50,44,6,0],
[50,44,6,1],
[50,44,6,2],
[50,44,6,4],
[50,44,7,0],
[50,44,7,1],
[50,44,7,2],
[50,44,7,3],
[50,44,7,4],
[50,44,7,5],
[50,44,7,6],
[50,44,8,0],
[50,44,8,1],
[50,44,8,2],
[50,44,8,4],
[50,44,8,7],
[50,44,10,0],
[50,44,10,1],
[50,44,10,2],
[50,44,10,3],
[50,44,10,4],
[50,44,10,5],
[50,44,10,6],
[50,44,10,7],
[50,44,10,8],
[50,44,11,0],
[50,44,11,1],
[50,44,11,2],
[50,44,11,3],
[50,44,11,4],
[50,44,11,5],
[50,44,11,6],
[50,44,11,7],
[50,44,11,8],
[50,44,11,10],
[50,44,12,0],
[50,44,12,1],
[50,44,12,2],
[50,44,12,3],
[50,44,12,4],
[50,44,12,5],
[50,44,12,6],
[50,44,12,7],
[50,44,12,8],
[50,44,12,10],
[50,44,13,0],
[50,44,13,1],
[50,44,13,2],
[50,44,13,3],
[50,44,13,4],
[50,44,13,5],
[50,44,13,6],
[50,44,13,7],
[50,44,13,8],
[50,44,13,10],
[50,44,13,11],
[50,44,13,12],
[50,44,14,0],
[50,44,14,1],
[50,44,14,2],
[50,44,14,3],
[50,44,14,4],
[50,44,14,5],
[50,44,14,6],
[50,44,14,7],
[50,44,14,8],
[50,44,14,10],
[50,44,14,11],
[50,44,14,12],
[50,44,16,0],
[50,44,16,1],
[50,44,16,2],
[50,44,16,3],
[50,44,16,4],
[50,44,16,5],
[50,44,16,6],
[50,44,16,7],
[50,44,16,8],
[50,44,16,10],
[50,44,16,11],
[50,44,16,12],
[50,44,16,13],
[50,44,16,14],
[50,44,19,0],
[50,44,19,1],
[50,44,19,2],
[50,44,19,3],
[50,44,19,4],
[50,44,19,5],
[50,44,19,6],
[50,44,19,7],
[50,44,19,8],
[50,44,19,10],
[50,44,19,11],
[50,44,19,12],
[50,44,19,16],
[50,44,20,0],
[50,44,20,1],
[50,44,20,2],
[50,44,20,3],
[50,44,20,4],
[50,44,20,5],
[50,44,20,6],
[50,44,20,7],
[50,44,20,8],
[50,44,20,11],
[50,44,20,12],
[50,44,20,13],
[50,44,20,14],
[50,44,20,19],
[50,44,21,0],
[50,44,21,1],
[50,44,21,2],
[50,44,21,4],
[50,44,21,7],
[50,44,21,10],
[50,44,21,11],
[50,44,21,12],
[50,44,21,13],
[50,44,21,14],
[50,44,21,16],
[50,44,21,19],
[50,44,21,20],
[50,44,22,0],
[50,44,22,1],
[50,44,22,2],
[50,44,22,3],
[50,44,22,4],
[50,44,22,5],
[50,44,22,6],
[50,44,22,7],
[50,44,22,8],
[50,44,22,11],
[50,44,22,12],
[50,44,22,13],
[50,44,22,16],
[50,44,22,19],
[50,44,22,21],
[50,44,23,0],
[50,44,23,1],
[50,44,23,2],
[50,44,23,3],
[50,44,23,4],
[50,44,23,5],
[50,44,23,6],
[50,44,23,7],
[50,44,23,8],
[50,44,23,10],
[50,44,23,11],
[50,44,23,14],
[50,44,23,16],
[50,44,23,19],
[50,44,23,20],
[50,44,23,21],
[50,44,23,22],
[50,44,24,0],
[50,44,24,1],
[50,44,24,2],
[50,44,24,3],
[50,44,24,4],
[50,44,24,5],
[50,44,24,6],
[50,44,24,7],
[50,44,24,8],
[50,44,24,10],
[50,44,24,11],
[50,44,24,14],
[50,44,24,19],
[50,44,24,20],
[50,44,24,21],
[50,44,24,22],
[50,44,24,23],
[50,44,25,0],
[50,44,25,1],
[50,44,25,2],
[50,44,25,3],
[50,44,25,4],
[50,44,25,5],
[50,44,25,6],
[50,44,25,10],
[50,44,25,11],
[50,44,25,12],
[50,44,25,13],
[50,44,25,14],
[50,44,25,16],
[50,44,25,19],
[50,44,25,20],
[50,44,25,21],
[50,44,25,22],
[50,44,25,23],
[50,44,25,24],
[50,44,27,0],
[50,44,27,1],
[50,44,27,2],
[50,44,27,3],
[50,44,27,4],
[50,44,27,6],
[50,44,27,7],
[50,44,27,8],
[50,44,27,11],
[50,44,27,12],
[50,44,27,13],
[50,44,27,14],
[50,44,27,16],
[50,44,27,19],
[50,44,27,21],
[50,44,28,0],
[50,44,28,1],
[50,44,28,2],
[50,44,28,5],
[50,44,28,6],
[50,44,28,8],
[50,44,28,10],
[50,44,28,11],
[50,44,28,12],
[50,44,28,13],
[50,44,28,14],
[50,44,28,16],
[50,44,28,19],
[50,44,28,20],
[50,44,28,21],
[50,44,29,3],
[50,44,29,4],
[50,44,29,5],
[50,44,29,6],
[50,44,29,7],
[50,44,29,8],
[50,44,29,10],
[50,44,29,13],
[50,44,29,14],
[50,44,29,16],
[50,44,29,19],
[50,44,29,20],
[50,44,30,0],
[50,44,30,1],
[50,44,30,2],
[50,44,30,3],
[50,44,30,4],
[50,44,30,5],
[50,44,30,6],
[50,44,30,7],
[50,44,30,8],
[50,44,30,10],
[50,44,30,11],
[50,44,30,12],
[50,44,30,13],
[50,44,30,14],
[50,44,30,16],
[50,44,30,19],
[50,44,31,0],
[50,44,31,1],
[50,44,31,2],
[50,44,31,3],
[50,44,31,4],
[50,44,31,5],
[50,44,31,6],
[50,44,31,7],
[50,44,31,8],
[50,44,31,10],
[50,44,31,11],
[50,44,31,12],
[50,44,31,13],
[50,44,31,14],
[50,44,31,16],
[50,44,32,0],
[50,44,32,1],
[50,44,32,2],
[50,44,32,3],
[50,44,32,4],
[50,44,32,5],
[50,44,32,6],
[50,44,32,7],
[50,44,32,8],
[50,44,32,10],
[50,44,32,13],
[50,44,32,14],
[50,44,32,16],
[50,44,33,0],
[50,44,33,1],
[50,44,33,2],
[50,44,33,3],
[50,44,33,4],
[50,44,33,5],
[50,44,33,6],
[50,44,33,7],
[50,44,33,8],
[50,44,33,10],
[50,44,33,13],
[50,44,33,14],
[50,44,34,0],
[50,44,34,1],
[50,44,34,2],
[50,44,34,3],
[50,44,34,4],
[50,44,34,5],
[50,44,34,6],
[50,44,34,8],
[50,44,34,10],
[50,44,34,11],
[50,44,34,12],
[50,44,34,13],
[50,44,34,14],
[50,44,36,0],
[50,44,36,1],
[50,44,36,2],
[50,44,36,3],
[50,44,36,4],
[50,44,36,5],
[50,44,36,6],
[50,44,36,7],
[50,44,36,8],
[50,44,36,10],
[50,44,36,11],
[50,44,37,0],
[50,44,37,1],
[50,44,37,2],
[50,44,37,3],
[50,44,37,5],
[50,44,37,6],
[50,44,37,7],
[50,44,37,8],
[50,44,38,0],
[50,44,38,1],
[50,44,38,2],
[50,44,38,3],
[50,44,38,4],
[50,44,38,5],
[50,44,38,6],
[50,44,39,0],
[50,44,39,1],
[50,44,39,2],
[50,44,39,4],
[50,44,42,0],
[50,44,42,1],
[50,44,42,2],
[50,47,3,0],
[50,47,3,1],
[50,47,3,2],
[50,47,4,0],
[50,47,4,1],
[50,47,4,2],
[50,47,4,3],
[50,47,5,0],
[50,47,5,1],
[50,47,5,2],
[50,47,5,4],
[50,47,6,0],
[50,47,6,1],
[50,47,6,2],
[50,47,6,4],
[50,47,7,0],
[50,47,7,1],
[50,47,7,2],
[50,47,7,3],
[50,47,7,4],
[50,47,7,5],
[50,47,7,6],
[50,47,8,0],
[50,47,8,1],
[50,47,8,2],
[50,47,8,4],
[50,47,8,7],
[50,47,10,0],
[50,47,10,1],
[50,47,10,2],
[50,47,10,3],
[50,47,10,4],
[50,47,10,5],
[50,47,10,6],
[50,47,10,7],
[50,47,10,8],
[50,47,11,0],
[50,47,11,1],
[50,47,11,2],
[50,47,11,3],
[50,47,11,4],
[50,47,11,5],
[50,47,11,6],
[50,47,11,7],
[50,47,11,8],
[50,47,11,10],
[50,47,12,0],
[50,47,12,1],
[50,47,12,2],
[50,47,12,3],
[50,47,12,4],
[50,47,12,5],
[50,47,12,6],
[50,47,12,7],
[50,47,12,8],
[50,47,12,10],
[50,47,13,0],
[50,47,13,1],
[50,47,13,2],
[50,47,13,3],
[50,47,13,4],
[50,47,13,5],
[50,47,13,6],
[50,47,13,7],
[50,47,13,8],
[50,47,13,10],
[50,47,13,11],
[50,47,13,12],
[50,47,14,0],
[50,47,14,1],
[50,47,14,2],
[50,47,14,3],
[50,47,14,4],
[50,47,14,5],
[50,47,14,6],
[50,47,14,7],
[50,47,14,8],
[50,47,14,10],
[50,47,14,11],
[50,47,14,12],
[50,47,16,0],
[50,47,16,1],
[50,47,16,2],
[50,47,16,3],
[50,47,16,4],
[50,47,16,5],
[50,47,16,6],
[50,47,16,7],
[50,47,16,8],
[50,47,16,10],
[50,47,16,11],
[50,47,16,12],
[50,47,16,13],
[50,47,16,14],
[50,47,17,0],
[50,47,17,1],
[50,47,17,2],
[50,47,17,3],
[50,47,17,4],
[50,47,17,5],
[50,47,17,6],
[50,47,17,7],
[50,47,17,8],
[50,47,17,10],
[50,47,17,11],
[50,47,17,12],
[50,47,17,13],
[50,47,17,14],
[50,47,19,0],
[50,47,19,1],
[50,47,19,2],
[50,47,19,3],
[50,47,19,4],
[50,47,19,5],
[50,47,19,6],
[50,47,19,7],
[50,47,19,8],
[50,47,19,10],
[50,47,19,11],
[50,47,19,12],
[50,47,19,16],
[50,47,19,17],
[50,47,20,0],
[50,47,20,1],
[50,47,20,2],
[50,47,20,3],
[50,47,20,4],
[50,47,20,5],
[50,47,20,6],
[50,47,20,7],
[50,47,20,8],
[50,47,20,11],
[50,47,20,14],
[50,47,20,16],
[50,47,20,17],
[50,47,20,19],
[50,47,21,0],
[50,47,21,1],
[50,47,21,2],
[50,47,21,4],
[50,47,21,7],
[50,47,21,12],
[50,47,21,13],
[50,47,21,14],
[50,47,21,16],
[50,47,21,17],
[50,47,21,19],
[50,47,21,20],
[50,47,22,0],
[50,47,22,1],
[50,47,22,2],
[50,47,22,3],
[50,47,22,4],
[50,47,22,5],
[50,47,22,6],
[50,47,22,7],
[50,47,22,11],
[50,47,22,12],
[50,47,22,13],
[50,47,22,14],
[50,47,22,16],
[50,47,22,17],
[50,47,22,19],
[50,47,22,21],
[50,47,24,0],
[50,47,24,1],
[50,47,24,2],
[50,47,24,3],
[50,47,24,4],
[50,47,24,5],
[50,47,24,8],
[50,47,24,10],
[50,47,24,11],
[50,47,24,12],
[50,47,24,13],
[50,47,24,14],
[50,47,24,17],
[50,47,24,19],
[50,47,24,20],
[50,47,24,21],
[50,47,24,22],
[50,47,25,0],
[50,47,25,1],
[50,47,25,2],
[50,47,25,5],
[50,47,25,6],
[50,47,25,8],
[50,47,25,10],
[50,47,25,11],
[50,47,25,12],
[50,47,25,13],
[50,47,25,14],
[50,47,25,16],
[50,47,25,17],
[50,47,25,19],
[50,47,25,20],
[50,47,25,21],
[50,47,27,0],
[50,47,27,1],
[50,47,27,2],
[50,47,27,3],
[50,47,27,4],
[50,47,27,5],
[50,47,27,6],
[50,47,27,7],
[50,47,27,8],
[50,47,27,11],
[50,47,27,12],
[50,47,27,13],
[50,47,27,14],
[50,47,27,16],
[50,47,27,17],
[50,47,27,19],
[50,47,28,0],
[50,47,28,1],
[50,47,28,2],
[50,47,28,3],
[50,47,28,4],
[50,47,28,5],
[50,47,28,6],
[50,47,28,8],
[50,47,28,10],
[50,47,28,11],
[50,47,28,12],
[50,47,28,13],
[50,47,28,14],
[50,47,28,16],
[50,47,28,17],
[50,47,29,0],
[50,47,29,1],
[50,47,29,2],
[50,47,29,3],
[50,47,29,4],
[50,47,29,5],
[50,47,29,6],
[50,47,29,7],
[50,47,29,8],
[50,47,29,10],
[50,47,29,13],
[50,47,29,14],
[50,47,29,16],
[50,47,29,17],
[50,47,32,0],
[50,47,32,1],
[50,47,32,2],
[50,47,32,3],
[50,47,32,4],
[50,47,32,5],
[50,47,32,6],
[50,47,32,7],
[50,47,32,8],
[50,47,32,10],
[50,47,32,13],
[50,47,33,0],
[50,47,33,1],
[50,47,33,2],
[50,47,33,3],
[50,47,33,4],
[50,47,33,5],
[50,47,33,6],
[50,47,33,7],
[50,47,33,8],
[50,47,33,10],
[50,47,34,0],
[50,47,34,1],
[50,47,34,2],
[50,47,34,3],
[50,47,34,4],
[50,47,34,5],
[50,47,34,6],
[50,47,34,8],
[50,47,36,0],
[50,47,36,1],
[50,47,36,2],
[50,47,36,3],
[50,47,36,4],
[50,47,36,5],
[50,47,37,0],
[50,47,37,1],
[50,47,37,2],
[50,47,37,3],
[50,47,38,0],
[50,47,38,1],
[50,47,38,2],
[50,48,3,0],
[50,48,3,1],
[50,48,3,2],
[50,48,4,0],
[50,48,4,1],
[50,48,4,2],
[50,48,4,3],
[50,48,5,0],
[50,48,5,1],
[50,48,5,2],
[50,48,5,4],
[50,48,6,0],
[50,48,6,1],
[50,48,6,2],
[50,48,6,4],
[50,48,7,0],
[50,48,7,1],
[50,48,7,2],
[50,48,7,3],
[50,48,7,4],
[50,48,7,5],
[50,48,7,6],
[50,48,8,0],
[50,48,8,1],
[50,48,8,2],
[50,48,8,4],
[50,48,8,7],
[50,48,10,0],
[50,48,10,1],
[50,48,10,2],
[50,48,10,3],
[50,48,10,4],
[50,48,10,5],
[50,48,10,6],
[50,48,10,7],
[50,48,10,8],
[50,48,13,0],
[50,48,13,1],
[50,48,13,2],
[50,48,13,3],
[50,48,13,4],
[50,48,13,5],
[50,48,13,6],
[50,48,13,7],
[50,48,13,8],
[50,48,13,10],
[50,48,14,0],
[50,48,14,1],
[50,48,14,2],
[50,48,14,3],
[50,48,14,4],
[50,48,14,5],
[50,48,14,6],
[50,48,14,7],
[50,48,14,8],
[50,48,14,10],
[50,48,16,0],
[50,48,16,1],
[50,48,16,2],
[50,48,16,3],
[50,48,16,4],
[50,48,16,5],
[50,48,16,6],
[50,48,16,7],
[50,48,16,8],
[50,48,16,10],
[50,48,16,13],
[50,48,16,14],
[50,48,17,0],
[50,48,17,1],
[50,48,17,2],
[50,48,17,3],
[50,48,17,4],
[50,48,17,5],
[50,48,17,6],
[50,48,17,7],
[50,48,17,8],
[50,48,17,10],
[50,48,17,13],
[50,48,17,14],
[50,48,17,16],
[50,48,19,0],
[50,48,19,1],
[50,48,19,2],
[50,48,19,3],
[50,48,19,4],
[50,48,19,5],
[50,48,19,6],
[50,48,19,7],
[50,48,19,8],
[50,48,19,10],
[50,48,19,16],
[50,48,19,17],
[50,48,20,0],
[50,48,20,1],
[50,48,20,2],
[50,48,20,3],
[50,48,20,4],
[50,48,20,5],
[50,48,20,6],
[50,48,20,7],
[50,48,20,8],
[50,48,20,13],
[50,48,20,14],
[50,48,20,16],
[50,48,20,17],
[50,48,20,19],
[50,48,21,0],
[50,48,21,1],
[50,48,21,2],
[50,48,21,4],
[50,48,21,7],
[50,48,21,10],
[50,48,21,13],
[50,48,21,14],
[50,48,21,16],
[50,48,21,17],
[50,48,21,19],
[50,48,21,20],
[50,48,22,0],
[50,48,22,1],
[50,48,22,2],
[50,48,22,3],
[50,48,22,4],
[50,48,22,5],
[50,48,22,8],
[50,48,22,13],
[50,48,22,14],
[50,48,22,16],
[50,48,22,17],
[50,48,22,19],
[50,48,22,21],
[50,48,23,0],
[50,48,23,1],
[50,48,23,2],
[50,48,23,3],
[50,48,23,4],
[50,48,23,6],
[50,48,23,7],
[50,48,23,8],
[50,48,23,10],
[50,48,23,13],
[50,48,23,14],
[50,48,23,16],
[50,48,23,17],
[50,48,23,19],
[50,48,23,20],
[50,48,23,21],
[50,48,23,22],
[50,48,24,0],
[50,48,24,1],
[50,48,24,2],
[50,48,24,3],
[50,48,24,4],
[50,48,24,6],
[50,48,24,7],
[50,48,24,8],
[50,48,24,10],
[50,48,24,13],
[50,48,24,14],
[50,48,24,17],
[50,48,24,19],
[50,48,24,20],
[50,48,24,21],
[50,48,24,22],
[50,48,25,3],
[50,48,25,4],
[50,48,25,5],
[50,48,25,6],
[50,48,25,8],
[50,48,25,10],
[50,48,25,13],
[50,48,25,14],
[50,48,25,16],
[50,48,25,17],
[50,48,25,19],
[50,48,25,20],
[50,48,27,0],
[50,48,27,1],
[50,48,27,2],
[50,48,27,3],
[50,48,27,4],
[50,48,27,5],
[50,48,27,6],
[50,48,27,7],
[50,48,27,8],
[50,48,27,13],
[50,48,27,14],
[50,48,27,16],
[50,48,27,17],
[50,48,28,0],
[50,48,28,1],
[50,48,28,2],
[50,48,28,3],
[50,48,28,4],
[50,48,28,5],
[50,48,28,6],
[50,48,28,8],
[50,48,28,10],
[50,48,28,13],
[50,48,28,14],
[50,48,28,16],
[50,48,28,17],
[50,48,30,0],
[50,48,30,1],
[50,48,30,2],
[50,48,30,3],
[50,48,30,4],
[50,48,30,5],
[50,48,30,6],
[50,48,30,7],
[50,48,30,8],
[50,48,30,10],
[50,48,30,13],
[50,48,30,14],
[50,48,31,0],
[50,48,31,1],
[50,48,31,2],
[50,48,31,3],
[50,48,31,4],
[50,48,31,5],
[50,48,31,6],
[50,48,31,7],
[50,48,31,8],
[50,48,31,10],
[50,48,31,13],
[50,48,34,0],
[50,48,34,1],
[50,48,34,2],
[50,48,34,3],
[50,48,34,4],
[50,48,34,5],
[50,48,34,6],
[50,48,36,0],
[50,48,36,1],
[50,48,36,2],
[50,48,36,3],
[50,48,36,4],
[50,48,37,0],
[50,48,37,1],
[50,48,37,2],
[51,7,4,0],
[51,7,4,1],
[51,7,4,2],
[51,9,4,0],
[51,9,4,1],
[51,9,4,2],
[51,9,7,0],
[51,9,7,1],
[51,9,7,2],
[51,9,7,4],
[51,10,4,0],
[51,10,4,1],
[51,10,4,2],
[51,10,7,0],
[51,10,7,1],
[51,10,7,2],
[51,10,7,4],
[51,10,9,0],
[51,10,9,1],
[51,10,9,2],
[51,10,9,4],
[51,10,9,7],
[51,11,4,0],
[51,11,4,1],
[51,11,4,2],
[51,11,7,0],
[51,11,7,1],
[51,11,7,2],
[51,11,7,4],
[51,11,9,0],
[51,11,9,1],
[51,11,9,2],
[51,11,9,4],
[51,11,9,7],
[51,11,10,0],
[51,11,10,1],
[51,11,10,2],
[51,11,10,4],
[51,11,10,7],
[51,11,10,9],
[51,12,4,0],
[51,12,4,1],
[51,12,4,2],
[51,12,7,0],
[51,12,7,1],
[51,12,7,2],
[51,12,7,4],
[51,12,9,0],
[51,12,9,1],
[51,12,9,2],
[51,12,9,4],
[51,12,9,7],
[51,12,10,0],
[51,12,10,1],
[51,12,10,2],
[51,12,10,4],
[51,12,10,7],
[51,12,10,9],
[51,13,4,0],
[51,13,4,1],
[51,13,4,2],
[51,13,7,0],
[51,13,7,1],
[51,13,7,2],
[51,13,7,4],
[51,13,9,0],
[51,13,9,1],
[51,13,9,2],
[51,13,9,4],
[51,13,9,7],
[51,13,10,0],
[51,13,10,1],
[51,13,10,2],
[51,13,10,4],
[51,13,10,7],
[51,13,10,9],
[51,13,11,0],
[51,13,11,1],
[51,13,11,2],
[51,13,11,4],
[51,13,11,7],
[51,13,11,9],
[51,13,11,10],
[51,13,12,0],
[51,13,12,1],
[51,13,12,2],
[51,13,12,4],
[51,13,12,7],
[51,13,12,9],
[51,13,12,10],
[51,14,4,0],
[51,14,4,1],
[51,14,4,2],
[51,14,7,0],
[51,14,7,1],
[51,14,7,2],
[51,14,7,4],
[51,14,9,0],
[51,14,9,1],
[51,14,9,2],
[51,14,9,4],
[51,14,9,7],
[51,14,10,0],
[51,14,10,1],
[51,14,10,2],
[51,14,10,4],
[51,14,10,7],
[51,14,10,9],
[51,14,11,0],
[51,14,11,1],
[51,14,11,2],
[51,14,11,4],
[51,14,11,7],
[51,14,11,9],
[51,14,11,10],
[51,14,12,0],
[51,14,12,1],
[51,14,12,2],
[51,14,12,4],
[51,14,12,7],
[51,14,12,9],
[51,14,12,10],
[51,15,4,0],
[51,15,4,1],
[51,15,4,2],
[51,15,7,0],
[51,15,7,1],
[51,15,7,2],
[51,15,7,4],
[51,15,10,0],
[51,15,10,1],
[51,15,10,2],
[51,15,10,4],
[51,15,10,7],
[51,15,11,0],
[51,15,11,1],
[51,15,11,2],
[51,15,11,4],
[51,15,11,7],
[51,15,11,10],
[51,15,12,0],
[51,15,12,1],
[51,15,12,2],
[51,15,12,4],
[51,15,12,7],
[51,15,12,10],
[51,15,13,0],
[51,15,13,1],
[51,15,13,2],
[51,15,13,4],
[51,15,13,7],
[51,15,13,10],
[51,15,13,11],
[51,15,13,12],
[51,15,14,0],
[51,15,14,1],
[51,15,14,2],
[51,15,14,4],
[51,15,14,7],
[51,15,14,10],
[51,15,14,11],
[51,15,14,12],
[51,16,4,0],
[51,16,4,1],
[51,16,4,2],
[51,16,7,0],
[51,16,7,1],
[51,16,7,2],
[51,16,7,4],
[51,16,9,0],
[51,16,9,1],
[51,16,9,2],
[51,16,9,4],
[51,16,9,7],
[51,16,10,0],
[51,16,10,1],
[51,16,10,2],
[51,16,10,4],
[51,16,10,7],
[51,16,10,9],
[51,16,11,0],
[51,16,11,1],
[51,16,11,2],
[51,16,11,4],
[51,16,11,7],
[51,16,11,9],
[51,16,11,10],
[51,16,12,0],
[51,16,12,1],
[51,16,12,2],
[51,16,12,4],
[51,16,12,7],
[51,16,12,9],
[51,16,12,10],
[51,16,13,0],
[51,16,13,1],
[51,16,13,2],
[51,16,13,4],
[51,16,13,7],
[51,16,13,9],
[51,16,13,10],
[51,16,13,11],
[51,16,13,12],
[51,16,14,0],
[51,16,14,1],
[51,16,14,2],
[51,16,14,4],
[51,16,14,7],
[51,16,14,9],
[51,16,14,10],
[51,16,14,11],
[51,16,14,12],
[51,16,15,0],
[51,16,15,1],
[51,16,15,2],
[51,16,15,4],
[51,16,15,7],
[51,16,15,10],
[51,16,15,11],
[51,16,15,12],
[51,16,15,13],
[51,16,15,14],
[51,17,4,0],
[51,17,4,1],
[51,17,4,2],
[51,17,7,0],
[51,17,7,1],
[51,17,7,2],
[51,17,7,4],
[51,17,9,0],
[51,17,9,1],
[51,17,9,2],
[51,17,9,4],
[51,17,9,7],
[51,17,10,0],
[51,17,10,1],
[51,17,10,2],
[51,17,10,4],
[51,17,10,7],
[51,17,10,9],
[51,17,11,0],
[51,17,11,1],
[51,17,11,2],
[51,17,11,4],
[51,17,11,7],
[51,17,11,9],
[51,17,11,10],
[51,17,12,0],
[51,17,12,1],
[51,17,12,2],
[51,17,12,4],
[51,17,12,7],
[51,17,12,9],
[51,17,12,10],
[51,17,13,0],
[51,17,13,1],
[51,17,13,2],
[51,17,13,4],
[51,17,13,7],
[51,17,13,9],
[51,17,13,10],
[51,17,13,11],
[51,17,13,12],
[51,17,14,0],
[51,17,14,1],
[51,17,14,2],
[51,17,14,4],
[51,17,14,7],
[51,17,14,9],
[51,17,14,10],
[51,17,14,11],
[51,17,14,12],
[51,17,15,0],
[51,17,15,1],
[51,17,15,2],
[51,17,15,4],
[51,17,15,7],
[51,17,15,10],
[51,17,15,11],
[51,17,15,12],
[51,17,15,13],
[51,17,15,14],
[51,17,16,0],
[51,17,16,1],
[51,17,16,2],
[51,17,16,4],
[51,17,16,7],
[51,17,16,9],
[51,17,16,10],
[51,17,16,11],
[51,17,16,12],
[51,17,16,13],
[51,17,16,14],
[51,17,16,15],
[51,18,4,0],
[51,18,4,1],
[51,18,4,2],
[51,18,7,0],
[51,18,7,1],
[51,18,7,2],
[51,18,7,4],
[51,18,10,0],
[51,18,10,1],
[51,18,10,2],
[51,18,10,4],
[51,18,10,7],
[51,18,11,0],
[51,18,11,1],
[51,18,11,2],
[51,18,11,4],
[51,18,11,7],
[51,18,11,10],
[51,18,12,0],
[51,18,12,1],
[51,18,12,2],
[51,18,12,4],
[51,18,12,7],
[51,18,12,10],
[51,18,13,0],
[51,18,13,1],
[51,18,13,2],
[51,18,13,4],
[51,18,13,7],
[51,18,13,10],
[51,18,13,11],
[51,18,13,12],
[51,18,14,0],
[51,18,14,1],
[51,18,14,2],
[51,18,14,4],
[51,18,14,7],
[51,18,14,10],
[51,18,14,11],
[51,18,14,12],
[51,18,16,0],
[51,18,16,1],
[51,18,16,2],
[51,18,16,4],
[51,18,16,7],
[51,18,16,10],
[51,18,16,11],
[51,18,16,12],
[51,18,16,13],
[51,18,16,14],
[51,18,17,0],
[51,18,17,1],
[51,18,17,2],
[51,18,17,4],
[51,18,17,7],
[51,18,17,10],
[51,18,17,11],
[51,18,17,12],
[51,18,17,13],
[51,18,17,14],
[51,18,17,16],
[51,19,4,0],
[51,19,4,1],
[51,19,4,2],
[51,19,7,0],
[51,19,7,1],
[51,19,7,2],
[51,19,7,4],
[51,19,9,0],
[51,19,9,1],
[51,19,9,2],
[51,19,9,4],
[51,19,9,7],
[51,19,10,0],
[51,19,10,1],
[51,19,10,2],
[51,19,10,4],
[51,19,10,7],
[51,19,10,9],
[51,19,11,0],
[51,19,11,1],
[51,19,11,2],
[51,19,11,4],
[51,19,11,7],
[51,19,11,9],
[51,19,11,10],
[51,19,12,0],
[51,19,12,1],
[51,19,12,2],
[51,19,12,4],
[51,19,12,7],
[51,19,12,9],
[51,19,12,10],
[51,19,15,0],
[51,19,15,1],
[51,19,15,2],
[51,19,15,4],
[51,19,15,7],
[51,19,15,10],
[51,19,15,11],
[51,19,15,12],
[51,19,16,0],
[51,19,16,1],
[51,19,16,2],
[51,19,16,4],
[51,19,16,7],
[51,19,16,9],
[51,19,16,10],
[51,19,16,11],
[51,19,16,12],
[51,19,16,15],
[51,19,17,0],
[51,19,17,1],
[51,19,17,2],
[51,19,17,4],
[51,19,17,7],
[51,19,17,9],
[51,19,17,10],
[51,19,17,11],
[51,19,17,12],
[51,19,17,15],
[51,19,17,16],
[51,19,18,0],
[51,19,18,1],
[51,19,18,2],
[51,19,18,4],
[51,19,18,7],
[51,19,18,10],
[51,19,18,11],
[51,19,18,12],
[51,19,18,16],
[51,19,18,17],
[51,20,4,0],
[51,20,4,1],
[51,20,4,2],
[51,20,7,0],
[51,20,7,1],
[51,20,7,2],
[51,20,7,4],
[51,20,9,0],
[51,20,9,1],
[51,20,9,2],
[51,20,9,4],
[51,20,9,7],
[51,20,11,0],
[51,20,11,1],
[51,20,11,2],
[51,20,11,4],
[51,20,11,7],
[51,20,11,9],
[51,20,12,0],
[51,20,12,1],
[51,20,12,2],
[51,20,12,4],
[51,20,12,7],
[51,20,12,9],
[51,20,13,0],
[51,20,13,1],
[51,20,13,2],
[51,20,13,4],
[51,20,13,7],
[51,20,13,9],
[51,20,13,11],
[51,20,13,12],
[51,20,14,0],
[51,20,14,1],
[51,20,14,2],
[51,20,14,4],
[51,20,14,7],
[51,20,14,9],
[51,20,14,11],
[51,20,14,12],
[51,20,15,0],
[51,20,15,1],
[51,20,15,2],
[51,20,15,4],
[51,20,15,7],
[51,20,15,11],
[51,20,15,12],
[51,20,15,13],
[51,20,15,14],
[51,20,16,0],
[51,20,16,1],
[51,20,16,2],
[51,20,16,4],
[51,20,16,7],
[51,20,16,9],
[51,20,16,11],
[51,20,16,12],
[51,20,16,13],
[51,20,16,14],
[51,20,16,15],
[51,20,17,0],
[51,20,17,1],
[51,20,17,2],
[51,20,17,4],
[51,20,17,7],
[51,20,17,9],
[51,20,17,11],
[51,20,17,12],
[51,20,17,13],
[51,20,17,14],
[51,20,17,15],
[51,20,17,16],
[51,20,18,0],
[51,20,18,1],
[51,20,18,2],
[51,20,18,4],
[51,20,18,7],
[51,20,18,11],
[51,20,18,12],
[51,20,18,13],
[51,20,18,14],
[51,20,18,16],
[51,20,18,17],
[51,20,19,0],
[51,20,19,1],
[51,20,19,2],
[51,20,19,4],
[51,20,19,7],
[51,20,19,9],
[51,20,19,11],
[51,20,19,12],
[51,20,19,15],
[51,20,19,16],
[51,20,19,17],
[51,20,19,18],
[51,22,4,0],
[51,22,4,1],
[51,22,4,2],
[51,22,7,0],
[51,22,7,1],
[51,22,7,2],
[51,22,7,4],
[51,22,9,0],
[51,22,9,1],
[51,22,9,2],
[51,22,9,4],
[51,22,9,7],
[51,22,11,0],
[51,22,11,1],
[51,22,11,2],
[51,22,11,4],
[51,22,11,7],
[51,22,11,9],
[51,22,12,0],
[51,22,12,1],
[51,22,12,2],
[51,22,12,4],
[51,22,12,7],
[51,22,12,9],
[51,22,13,0],
[51,22,13,1],
[51,22,13,2],
[51,22,13,4],
[51,22,13,7],
[51,22,13,9],
[51,22,13,11],
[51,22,13,12],
[51,22,14,0],
[51,22,14,1],
[51,22,14,2],
[51,22,14,4],
[51,22,14,7],
[51,22,14,9],
[51,22,14,11],
[51,22,14,12],
[51,22,15,0],
[51,22,15,1],
[51,22,15,2],
[51,22,15,4],
[51,22,15,7],
[51,22,15,11],
[51,22,15,12],
[51,22,15,13],
[51,22,15,14],
[51,22,16,0],
[51,22,16,1],
[51,22,16,2],
[51,22,16,4],
[51,22,16,7],
[51,22,16,9],
[51,22,16,11],
[51,22,16,12],
[51,22,16,13],
[51,22,16,14],
[51,22,16,15],
[51,22,17,0],
[51,22,17,1],
[51,22,17,2],
[51,22,17,4],
[51,22,17,7],
[51,22,17,9],
[51,22,17,11],
[51,22,17,12],
[51,22,17,13],
[51,22,17,14],
[51,22,17,15],
[51,22,17,16],
[51,22,18,0],
[51,22,18,1],
[51,22,18,2],
[51,22,18,4],
[51,22,18,7],
[51,22,18,11],
[51,22,18,12],
[51,22,18,13],
[51,22,18,14],
[51,22,18,16],
[51,22,18,17],
[51,22,19,0],
[51,22,19,1],
[51,22,19,2],
[51,22,19,4],
[51,22,19,7],
[51,22,19,9],
[51,22,19,11],
[51,22,19,12],
[51,22,19,15],
[51,22,19,16],
[51,22,19,17],
[51,22,19,18],
[51,23,4,0],
[51,23,4,1],
[51,23,4,2],
[51,23,7,0],
[51,23,7,1],
[51,23,7,2],
[51,23,7,4],
[51,23,9,0],
[51,23,9,1],
[51,23,9,2],
[51,23,9,4],
[51,23,9,7],
[51,23,10,0],
[51,23,10,1],
[51,23,10,2],
[51,23,10,4],
[51,23,10,7],
[51,23,10,9],
[51,23,11,0],
[51,23,11,1],
[51,23,11,2],
[51,23,11,4],
[51,23,11,7],
[51,23,11,9],
[51,23,11,10],
[51,23,12,0],
[51,23,12,1],
[51,23,12,2],
[51,23,12,4],
[51,23,12,7],
[51,23,12,9],
[51,23,12,10],
[51,23,13,0],
[51,23,13,1],
[51,23,13,2],
[51,23,13,4],
[51,23,13,7],
[51,23,13,9],
[51,23,13,10],
[51,23,13,11],
[51,23,13,12],
[51,23,14,0],
[51,23,14,1],
[51,23,14,2],
[51,23,14,4],
[51,23,14,7],
[51,23,14,9],
[51,23,14,10],
[51,23,14,11],
[51,23,14,12],
[51,23,15,0],
[51,23,15,1],
[51,23,15,2],
[51,23,15,4],
[51,23,15,7],
[51,23,15,10],
[51,23,15,11],
[51,23,15,12],
[51,23,15,13],
[51,23,15,14],
[51,23,16,0],
[51,23,16,1],
[51,23,16,2],
[51,23,16,4],
[51,23,16,7],
[51,23,16,9],
[51,23,16,10],
[51,23,16,11],
[51,23,16,12],
[51,23,16,13],
[51,23,16,14],
[51,23,16,15],
[51,23,17,0],
[51,23,17,1],
[51,23,17,2],
[51,23,17,4],
[51,23,17,7],
[51,23,17,9],
[51,23,17,10],
[51,23,17,11],
[51,23,17,12],
[51,23,17,13],
[51,23,17,14],
[51,23,17,15],
[51,23,17,16],
[51,23,18,0],
[51,23,18,1],
[51,23,18,2],
[51,23,18,4],
[51,23,18,7],
[51,23,18,10],
[51,23,18,11],
[51,23,18,12],
[51,23,18,13],
[51,23,18,14],
[51,23,18,16],
[51,23,18,17],
[51,23,19,0],
[51,23,19,1],
[51,23,19,2],
[51,23,19,4],
[51,23,19,7],
[51,23,19,9],
[51,23,19,10],
[51,23,19,11],
[51,23,19,12],
[51,23,19,15],
[51,23,19,16],
[51,23,19,17],
[51,23,19,18],
[51,23,20,0],
[51,23,20,1],
[51,23,20,2],
[51,23,20,4],
[51,23,20,7],
[51,23,20,9],
[51,23,20,11],
[51,23,20,12],
[51,23,20,13],
[51,23,20,14],
[51,23,20,15],
[51,23,20,16],
[51,23,20,17],
[51,23,20,18],
[51,23,20,19],
[51,23,22,0],
[51,23,22,1],
[51,23,22,2],
[51,23,22,4],
[51,23,22,7],
[51,23,22,9],
[51,23,22,11],
[51,23,22,12],
[51,23,22,13],
[51,23,22,14],
[51,23,22,15],
[51,23,22,16],
[51,23,22,17],
[51,23,22,18],
[51,23,22,19],
[51,24,4,0],
[51,24,4,1],
[51,24,4,2],
[51,24,7,0],
[51,24,7,1],
[51,24,7,2],
[51,24,7,4],
[51,24,9,0],
[51,24,9,1],
[51,24,9,2],
[51,24,9,4],
[51,24,9,7],
[51,24,10,0],
[51,24,10,1],
[51,24,10,2],
[51,24,10,4],
[51,24,10,7],
[51,24,10,9],
[51,24,11,0],
[51,24,11,1],
[51,24,11,2],
[51,24,11,4],
[51,24,11,7],
[51,24,11,9],
[51,24,11,10],
[51,24,12,0],
[51,24,12,1],
[51,24,12,2],
[51,24,12,4],
[51,24,12,7],
[51,24,12,9],
[51,24,12,10],
[51,24,13,0],
[51,24,13,1],
[51,24,13,2],
[51,24,13,4],
[51,24,13,7],
[51,24,13,9],
[51,24,13,10],
[51,24,13,11],
[51,24,13,12],
[51,24,14,0],
[51,24,14,1],
[51,24,14,2],
[51,24,14,4],
[51,24,14,7],
[51,24,14,9],
[51,24,14,10],
[51,24,14,11],
[51,24,14,12],
[51,24,15,0],
[51,24,15,1],
[51,24,15,2],
[51,24,15,4],
[51,24,15,7],
[51,24,15,10],
[51,24,15,11],
[51,24,15,12],
[51,24,15,13],
[51,24,15,14],
[51,24,17,0],
[51,24,17,1],
[51,24,17,2],
[51,24,17,4],
[51,24,17,7],
[51,24,17,9],
[51,24,17,10],
[51,24,17,11],
[51,24,17,12],
[51,24,17,13],
[51,24,17,14],
[51,24,17,15],
[51,24,18,0],
[51,24,18,1],
[51,24,18,2],
[51,24,18,4],
[51,24,18,7],
[51,24,18,10],
[51,24,18,11],
[51,24,18,12],
[51,24,18,13],
[51,24,18,14],
[51,24,18,17],
[51,24,19,0],
[51,24,19,1],
[51,24,19,2],
[51,24,19,4],
[51,24,19,7],
[51,24,19,9],
[51,24,19,10],
[51,24,19,11],
[51,24,19,12],
[51,24,19,15],
[51,24,19,17],
[51,24,19,18],
[51,24,20,0],
[51,24,20,1],
[51,24,20,2],
[51,24,20,4],
[51,24,20,7],
[51,24,20,9],
[51,24,20,11],
[51,24,20,12],
[51,24,20,13],
[51,24,20,14],
[51,24,20,15],
[51,24,20,17],
[51,24,20,18],
[51,24,20,19],
[51,24,22,0],
[51,24,22,1],
[51,24,22,2],
[51,24,22,4],
[51,24,22,7],
[51,24,22,9],
[51,24,22,11],
[51,24,22,12],
[51,24,22,13],
[51,24,22,14],
[51,24,22,15],
[51,24,22,17],
[51,24,22,18],
[51,24,22,19],
[51,24,23,0],
[51,24,23,1],
[51,24,23,2],
[51,24,23,4],
[51,24,23,7],
[51,24,23,9],
[51,24,23,10],
[51,24,23,11],
[51,24,23,12],
[51,24,23,13],
[51,24,23,14],
[51,24,23,15],
[51,24,23,17],
[51,24,23,18],
[51,24,23,19],
[51,24,23,20],
[51,24,23,22],
[51,25,4,0],
[51,25,4,1],
[51,25,4,2],
[51,25,9,0],
[51,25,9,1],
[51,25,9,2],
[51,25,9,4],
[51,25,10,0],
[51,25,10,1],
[51,25,10,2],
[51,25,10,4],
[51,25,10,9],
[51,25,11,0],
[51,25,11,1],
[51,25,11,2],
[51,25,11,4],
[51,25,11,9],
[51,25,11,10],
[51,25,12,0],
[51,25,12,1],
[51,25,12,2],
[51,25,12,4],
[51,25,12,9],
[51,25,12,10],
[51,25,13,0],
[51,25,13,1],
[51,25,13,2],
[51,25,13,4],
[51,25,13,9],
[51,25,13,10],
[51,25,13,11],
[51,25,13,12],
[51,25,14,0],
[51,25,14,1],
[51,25,14,2],
[51,25,14,4],
[51,25,14,9],
[51,25,14,10],
[51,25,14,11],
[51,25,14,12],
[51,25,15,0],
[51,25,15,1],
[51,25,15,2],
[51,25,15,4],
[51,25,15,10],
[51,25,15,11],
[51,25,15,12],
[51,25,15,13],
[51,25,15,14],
[51,25,16,0],
[51,25,16,1],
[51,25,16,2],
[51,25,16,4],
[51,25,16,9],
[51,25,16,10],
[51,25,16,11],
[51,25,16,12],
[51,25,16,13],
[51,25,16,14],
[51,25,16,15],
[51,25,17,0],
[51,25,17,1],
[51,25,17,2],
[51,25,17,4],
[51,25,17,9],
[51,25,17,10],
[51,25,17,11],
[51,25,17,12],
[51,25,17,13],
[51,25,17,14],
[51,25,17,15],
[51,25,17,16],
[51,25,18,0],
[51,25,18,1],
[51,25,18,2],
[51,25,18,4],
[51,25,18,10],
[51,25,18,11],
[51,25,18,12],
[51,25,18,13],
[51,25,18,14],
[51,25,18,16],
[51,25,18,17],
[51,25,19,0],
[51,25,19,1],
[51,25,19,2],
[51,25,19,4],
[51,25,19,9],
[51,25,19,10],
[51,25,19,11],
[51,25,19,12],
[51,25,19,15],
[51,25,19,16],
[51,25,19,17],
[51,25,19,18],
[51,25,20,0],
[51,25,20,1],
[51,25,20,2],
[51,25,20,4],
[51,25,20,9],
[51,25,20,11],
[51,25,20,12],
[51,25,20,13],
[51,25,20,14],
[51,25,20,15],
[51,25,20,16],
[51,25,20,17],
[51,25,20,18],
[51,25,20,19],
[51,25,22,0],
[51,25,22,1],
[51,25,22,2],
[51,25,22,4],
[51,25,22,9],
[51,25,22,11],
[51,25,22,12],
[51,25,22,13],
[51,25,22,14],
[51,25,22,15],
[51,25,22,16],
[51,25,22,17],
[51,25,22,18],
[51,25,22,19],
[51,25,23,0],
[51,25,23,1],
[51,25,23,2],
[51,25,23,4],
[51,25,23,9],
[51,25,23,10],
[51,25,23,11],
[51,25,23,12],
[51,25,23,13],
[51,25,23,14],
[51,25,23,15],
[51,25,23,16],
[51,25,23,17],
[51,25,23,18],
[51,25,23,19],
[51,25,23,20],
[51,25,23,22],
[51,25,24,0],
[51,25,24,1],
[51,25,24,2],
[51,25,24,4],
[51,25,24,9],
[51,25,24,10],
[51,25,24,11],
[51,25,24,12],
[51,25,24,13],
[51,25,24,14],
[51,25,24,15],
[51,25,24,17],
[51,25,24,18],
[51,25,24,19],
[51,25,24,20],
[51,25,24,22],
[51,25,24,23],
[51,26,4,0],
[51,26,4,1],
[51,26,4,2],
[51,26,7,0],
[51,26,7,1],
[51,26,7,2],
[51,26,7,4],
[51,26,10,0],
[51,26,10,1],
[51,26,10,2],
[51,26,10,4],
[51,26,10,7],
[51,26,11,0],
[51,26,11,1],
[51,26,11,2],
[51,26,11,4],
[51,26,11,7],
[51,26,11,10],
[51,26,12,0],
[51,26,12,1],
[51,26,12,2],
[51,26,12,4],
[51,26,12,7],
[51,26,12,10],
[51,26,13,0],
[51,26,13,1],
[51,26,13,2],
[51,26,13,4],
[51,26,13,7],
[51,26,13,10],
[51,26,13,11],
[51,26,13,12],
[51,26,14,0],
[51,26,14,1],
[51,26,14,2],
[51,26,14,4],
[51,26,14,7],
[51,26,14,10],
[51,26,14,11],
[51,26,14,12],
[51,26,16,0],
[51,26,16,1],
[51,26,16,2],
[51,26,16,4],
[51,26,16,7],
[51,26,16,10],
[51,26,16,11],
[51,26,16,12],
[51,26,16,13],
[51,26,16,14],
[51,26,17,0],
[51,26,17,1],
[51,26,17,2],
[51,26,17,4],
[51,26,17,7],
[51,26,17,10],
[51,26,17,11],
[51,26,17,12],
[51,26,17,13],
[51,26,17,14],
[51,26,17,16],
[51,26,19,0],
[51,26,19,1],
[51,26,19,2],
[51,26,19,4],
[51,26,19,7],
[51,26,19,10],
[51,26,19,11],
[51,26,19,12],
[51,26,19,16],
[51,26,19,17],
[51,26,20,0],
[51,26,20,1],
[51,26,20,2],
[51,26,20,4],
[51,26,20,7],
[51,26,20,11],
[51,26,20,12],
[51,26,20,13],
[51,26,20,14],
[51,26,20,16],
[51,26,20,17],
[51,26,20,19],
[51,26,22,0],
[51,26,22,1],
[51,26,22,2],
[51,26,22,4],
[51,26,22,7],
[51,26,22,11],
[51,26,22,12],
[51,26,22,13],
[51,26,22,14],
[51,26,22,16],
[51,26,22,17],
[51,26,22,19],
[51,26,23,0],
[51,26,23,1],
[51,26,23,2],
[51,26,23,4],
[51,26,23,7],
[51,26,23,10],
[51,26,23,11],
[51,26,23,12],
[51,26,23,13],
[51,26,23,14],
[51,26,23,16],
[51,26,23,17],
[51,26,23,19],
[51,26,23,20],
[51,26,23,22],
[51,26,24,0],
[51,26,24,1],
[51,26,24,2],
[51,26,24,4],
[51,26,24,7],
[51,26,24,10],
[51,26,24,11],
[51,26,24,12],
[51,26,24,13],
[51,26,24,14],
[51,26,24,17],
[51,26,24,19],
[51,26,24,20],
[51,26,24,22],
[51,26,24,23],
[51,26,25,0],
[51,26,25,1],
[51,26,25,2],
[51,26,25,4],
[51,26,25,10],
[51,26,25,11],
[51,26,25,12],
[51,26,25,13],
[51,26,25,14],
[51,26,25,16],
[51,26,25,17],
[51,26,25,19],
[51,26,25,20],
[51,26,25,22],
[51,26,25,23],
[51,26,25,24],
[51,27,4,0],
[51,27,4,1],
[51,27,4,2],
[51,27,7,0],
[51,27,7,1],
[51,27,7,2],
[51,27,7,4],
[51,27,9,0],
[51,27,9,1],
[51,27,9,2],
[51,27,9,4],
[51,27,9,7],
[51,27,11,0],
[51,27,11,1],
[51,27,11,2],
[51,27,11,4],
[51,27,11,7],
[51,27,11,9],
[51,27,12,0],
[51,27,12,1],
[51,27,12,2],
[51,27,12,4],
[51,27,12,7],
[51,27,12,9],
[51,27,13,0],
[51,27,13,1],
[51,27,13,2],
[51,27,13,4],
[51,27,13,7],
[51,27,13,9],
[51,27,13,11],
[51,27,13,12],
[51,27,14,0],
[51,27,14,1],
[51,27,14,2],
[51,27,14,4],
[51,27,14,7],
[51,27,14,9],
[51,27,14,11],
[51,27,14,12],
[51,27,15,0],
[51,27,15,1],
[51,27,15,2],
[51,27,15,4],
[51,27,15,7],
[51,27,15,11],
[51,27,15,12],
[51,27,15,13],
[51,27,15,14],
[51,27,16,0],
[51,27,16,1],
[51,27,16,2],
[51,27,16,4],
[51,27,16,7],
[51,27,16,9],
[51,27,16,11],
[51,27,16,12],
[51,27,16,13],
[51,27,16,14],
[51,27,16,15],
[51,27,17,0],
[51,27,17,1],
[51,27,17,2],
[51,27,17,4],
[51,27,17,7],
[51,27,17,9],
[51,27,17,11],
[51,27,17,12],
[51,27,17,13],
[51,27,17,14],
[51,27,17,15],
[51,27,17,16],
[51,27,18,0],
[51,27,18,1],
[51,27,18,2],
[51,27,18,4],
[51,27,18,7],
[51,27,18,11],
[51,27,18,12],
[51,27,18,13],
[51,27,18,14],
[51,27,18,16],
[51,27,18,17],
[51,27,19,0],
[51,27,19,1],
[51,27,19,2],
[51,27,19,4],
[51,27,19,7],
[51,27,19,9],
[51,27,19,11],
[51,27,19,12],
[51,27,19,15],
[51,27,19,16],
[51,27,19,17],
[51,27,19,18],
[51,27,23,0],
[51,27,23,1],
[51,27,23,2],
[51,27,23,4],
[51,27,23,7],
[51,27,23,9],
[51,27,23,11],
[51,27,23,12],
[51,27,23,13],
[51,27,23,14],
[51,27,23,15],
[51,27,23,16],
[51,27,23,17],
[51,27,23,18],
[51,27,23,19],
[51,27,24,0],
[51,27,24,1],
[51,27,24,2],
[51,27,24,4],
[51,27,24,7],
[51,27,24,9],
[51,27,24,11],
[51,27,24,12],
[51,27,24,13],
[51,27,24,14],
[51,27,24,15],
[51,27,24,17],
[51,27,24,18],
[51,27,24,19],
[51,27,24,23],
[51,27,25,0],
[51,27,25,1],
[51,27,25,2],
[51,27,25,4],
[51,27,25,9],
[51,27,25,11],
[51,27,25,12],
[51,27,25,13],
[51,27,25,14],
[51,27,25,15],
[51,27,25,16],
[51,27,25,17],
[51,27,25,18],
[51,27,25,19],
[51,27,25,23],
[51,27,25,24],
[51,27,26,0],
[51,27,26,1],
[51,27,26,2],
[51,27,26,4],
[51,27,26,7],
[51,27,26,11],
[51,27,26,12],
[51,27,26,13],
[51,27,26,14],
[51,27,26,16],
[51,27,26,17],
[51,27,26,19],
[51,27,26,23],
[51,27,26,24],
[51,27,26,25],
[51,28,4,0],
[51,28,4,1],
[51,28,4,2],
[51,28,9,0],
[51,28,9,1],
[51,28,9,2],
[51,28,9,4],
[51,28,10,0],
[51,28,10,1],
[51,28,10,2],
[51,28,10,4],
[51,28,10,9],
[51,28,11,0],
[51,28,11,1],
[51,28,11,2],
[51,28,11,4],
[51,28,11,9],
[51,28,11,10],
[51,28,12,0],
[51,28,12,1],
[51,28,12,2],
[51,28,12,4],
[51,28,12,9],
[51,28,12,10],
[51,28,13,0],
[51,28,13,1],
[51,28,13,2],
[51,28,13,4],
[51,28,13,9],
[51,28,13,10],
[51,28,13,11],
[51,28,13,12],
[51,28,14,0],
[51,28,14,1],
[51,28,14,2],
[51,28,14,4],
[51,28,14,9],
[51,28,14,10],
[51,28,14,11],
[51,28,14,12],
[51,28,15,0],
[51,28,15,1],
[51,28,15,2],
[51,28,15,4],
[51,28,15,10],
[51,28,15,11],
[51,28,15,12],
[51,28,15,13],
[51,28,15,14],
[51,28,16,0],
[51,28,16,1],
[51,28,16,2],
[51,28,16,4],
[51,28,16,9],
[51,28,16,10],
[51,28,16,11],
[51,28,16,12],
[51,28,16,13],
[51,28,16,14],
[51,28,16,15],
[51,28,17,0],
[51,28,17,1],
[51,28,17,2],
[51,28,17,4],
[51,28,17,9],
[51,28,17,10],
[51,28,17,11],
[51,28,17,12],
[51,28,17,13],
[51,28,17,14],
[51,28,17,15],
[51,28,17,16],
[51,28,18,0],
[51,28,18,1],
[51,28,18,2],
[51,28,18,4],
[51,28,18,10],
[51,28,18,11],
[51,28,18,12],
[51,28,18,13],
[51,28,18,14],
[51,28,18,16],
[51,28,18,17],
[51,28,19,0],
[51,28,19,1],
[51,28,19,2],
[51,28,19,4],
[51,28,19,9],
[51,28,19,10],
[51,28,19,11],
[51,28,19,12],
[51,28,19,15],
[51,28,19,16],
[51,28,19,17],
[51,28,19,18],
[51,28,20,0],
[51,28,20,1],
[51,28,20,2],
[51,28,20,4],
[51,28,20,9],
[51,28,20,11],
[51,28,20,12],
[51,28,20,13],
[51,28,20,14],
[51,28,20,15],
[51,28,20,16],
[51,28,20,17],
[51,28,20,18],
[51,28,20,19],
[51,28,22,0],
[51,28,22,1],
[51,28,22,2],
[51,28,22,4],
[51,28,22,9],
[51,28,22,11],
[51,28,22,12],
[51,28,22,13],
[51,28,22,14],
[51,28,22,15],
[51,28,22,16],
[51,28,22,17],
[51,28,22,18],
[51,28,22,19],
[51,28,23,0],
[51,28,23,1],
[51,28,23,2],
[51,28,23,4],
[51,28,23,9],
[51,28,23,10],
[51,28,23,11],
[51,28,23,12],
[51,28,23,13],
[51,28,23,14],
[51,28,23,15],
[51,28,23,16],
[51,28,23,17],
[51,28,23,18],
[51,28,23,19],
[51,28,23,20],
[51,28,23,22],
[51,28,24,0],
[51,28,24,1],
[51,28,24,2],
[51,28,24,4],
[51,28,24,9],
[51,28,24,10],
[51,28,24,11],
[51,28,24,12],
[51,28,24,13],
[51,28,24,14],
[51,28,24,15],
[51,28,24,17],
[51,28,24,18],
[51,28,24,19],
[51,28,24,20],
[51,28,24,22],
[51,28,24,23],
[51,28,26,0],
[51,28,26,1],
[51,28,26,2],
[51,28,26,4],
[51,28,26,10],
[51,28,26,11],
[51,28,26,12],
[51,28,26,13],
[51,28,26,14],
[51,28,26,16],
[51,28,26,17],
[51,28,26,19],
[51,28,26,20],
[51,28,26,22],
[51,28,26,23],
[51,28,26,24],
[51,28,27,0],
[51,28,27,1],
[51,28,27,2],
[51,28,27,4],
[51,28,27,9],
[51,28,27,11],
[51,28,27,12],
[51,28,27,13],
[51,28,27,14],
[51,28,27,15],
[51,28,27,16],
[51,28,27,17],
[51,28,27,18],
[51,28,27,19],
[51,28,27,23],
[51,28,27,24],
[51,28,27,26],
[51,29,4,0],
[51,29,4,1],
[51,29,4,2],
[51,29,7,0],
[51,29,7,1],
[51,29,7,2],
[51,29,7,4],
[51,29,9,0],
[51,29,9,1],
[51,29,9,2],
[51,29,9,4],
[51,29,9,7],
[51,29,10,0],
[51,29,10,1],
[51,29,10,2],
[51,29,10,4],
[51,29,10,7],
[51,29,10,9],
[51,29,13,0],
[51,29,13,1],
[51,29,13,2],
[51,29,13,4],
[51,29,13,7],
[51,29,13,9],
[51,29,13,10],
[51,29,14,0],
[51,29,14,1],
[51,29,14,2],
[51,29,14,4],
[51,29,14,7],
[51,29,14,9],
[51,29,14,10],
[51,29,15,0],
[51,29,15,1],
[51,29,15,2],
[51,29,15,4],
[51,29,15,7],
[51,29,15,10],
[51,29,15,13],
[51,29,15,14],
[51,29,16,0],
[51,29,16,1],
[51,29,16,2],
[51,29,16,4],
[51,29,16,7],
[51,29,16,9],
[51,29,16,10],
[51,29,16,13],
[51,29,16,14],
[51,29,16,15],
[51,29,17,0],
[51,29,17,1],
[51,29,17,2],
[51,29,17,4],
[51,29,17,7],
[51,29,17,9],
[51,29,17,10],
[51,29,17,13],
[51,29,17,14],
[51,29,17,15],
[51,29,17,16],
[51,29,18,0],
[51,29,18,1],
[51,29,18,2],
[51,29,18,4],
[51,29,18,7],
[51,29,18,10],
[51,29,18,13],
[51,29,18,14],
[51,29,18,16],
[51,29,18,17],
[51,29,19,0],
[51,29,19,1],
[51,29,19,2],
[51,29,19,4],
[51,29,19,7],
[51,29,19,9],
[51,29,19,10],
[51,29,19,15],
[51,29,19,16],
[51,29,19,17],
[51,29,19,18],
[51,29,20,0],
[51,29,20,1],
[51,29,20,2],
[51,29,20,4],
[51,29,20,7],
[51,29,20,9],
[51,29,20,13],
[51,29,20,14],
[51,29,20,15],
[51,29,20,16],
[51,29,20,17],
[51,29,20,18],
[51,29,20,19],
[51,29,22,0],
[51,29,22,1],
[51,29,22,2],
[51,29,22,4],
[51,29,22,7],
[51,29,22,9],
[51,29,22,13],
[51,29,22,14],
[51,29,22,15],
[51,29,22,16],
[51,29,22,17],
[51,29,22,18],
[51,29,22,19],
[51,29,23,0],
[51,29,23,1],
[51,29,23,2],
[51,29,23,4],
[51,29,23,7],
[51,29,23,9],
[51,29,23,10],
[51,29,23,13],
[51,29,23,14],
[51,29,23,15],
[51,29,23,16],
[51,29,23,17],
[51,29,23,18],
[51,29,23,19],
[51,29,23,20],
[51,29,23,22],
[51,29,24,0],
[51,29,24,1],
[51,29,24,2],
[51,29,24,4],
[51,29,24,7],
[51,29,24,9],
[51,29,24,10],
[51,29,24,13],
[51,29,24,14],
[51,29,24,15],
[51,29,24,17],
[51,29,24,18],
[51,29,24,19],
[51,29,24,20],
[51,29,24,22],
[51,29,24,23],
[51,29,25,0],
[51,29,25,1],
[51,29,25,2],
[51,29,25,4],
[51,29,25,9],
[51,29,25,10],
[51,29,25,13],
[51,29,25,14],
[51,29,25,15],
[51,29,25,16],
[51,29,25,17],
[51,29,25,18],
[51,29,25,19],
[51,29,25,20],
[51,29,25,22],
[51,29,25,23],
[51,29,25,24],
[51,29,26,0],
[51,29,26,1],
[51,29,26,2],
[51,29,26,4],
[51,29,26,7],
[51,29,26,10],
[51,29,26,13],
[51,29,26,14],
[51,29,26,16],
[51,29,26,17],
[51,29,26,19],
[51,29,26,20],
[51,29,26,22],
[51,29,26,23],
[51,29,26,24],
[51,29,27,0],
[51,29,27,1],
[51,29,27,2],
[51,29,27,4],
[51,29,27,7],
[51,29,27,9],
[51,29,27,13],
[51,29,27,14],
[51,29,27,15],
[51,29,27,16],
[51,29,27,17],
[51,29,27,18],
[51,29,27,19],
[51,29,27,23],
[51,29,27,24],
[51,29,27,25],
[51,29,27,26],
[51,29,28,0],
[51,29,28,1],
[51,29,28,2],
[51,29,28,4],
[51,29,28,9],
[51,29,28,10],
[51,29,28,13],
[51,29,28,14],
[51,29,28,15],
[51,29,28,16],
[51,29,28,17],
[51,29,28,18],
[51,29,28,19],
[51,29,28,20],
[51,29,28,22],
[51,29,28,26],
[51,29,28,27],
[51,30,4,0],
[51,30,4,1],
[51,30,4,2],
[51,30,7,0],
[51,30,7,1],
[51,30,7,2],
[51,30,7,4],
[51,30,9,0],
[51,30,9,1],
[51,30,9,2],
[51,30,9,4],
[51,30,9,7],
[51,30,10,0],
[51,30,10,1],
[51,30,10,2],
[51,30,10,4],
[51,30,10,7],
[51,30,10,9],
[51,30,11,0],
[51,30,11,1],
[51,30,11,2],
[51,30,11,4],
[51,30,11,7],
[51,30,11,9],
[51,30,11,10],
[51,30,12,0],
[51,30,12,1],
[51,30,12,2],
[51,30,12,4],
[51,30,12,7],
[51,30,12,9],
[51,30,12,10],
[51,30,13,0],
[51,30,13,1],
[51,30,13,2],
[51,30,13,4],
[51,30,13,7],
[51,30,13,9],
[51,30,13,10],
[51,30,13,11],
[51,30,13,12],
[51,30,14,0],
[51,30,14,1],
[51,30,14,2],
[51,30,14,4],
[51,30,14,7],
[51,30,14,9],
[51,30,14,10],
[51,30,14,11],
[51,30,14,12],
[51,30,15,0],
[51,30,15,1],
[51,30,15,2],
[51,30,15,4],
[51,30,15,7],
[51,30,15,10],
[51,30,15,11],
[51,30,15,12],
[51,30,15,13],
[51,30,15,14],
[51,30,16,0],
[51,30,16,1],
[51,30,16,2],
[51,30,16,4],
[51,30,16,7],
[51,30,16,9],
[51,30,16,10],
[51,30,16,11],
[51,30,16,12],
[51,30,16,13],
[51,30,16,14],
[51,30,16,15],
[51,30,17,0],
[51,30,17,1],
[51,30,17,2],
[51,30,17,4],
[51,30,17,7],
[51,30,17,9],
[51,30,17,10],
[51,30,17,11],
[51,30,17,12],
[51,30,17,13],
[51,30,17,14],
[51,30,17,15],
[51,30,17,16],
[51,30,18,0],
[51,30,18,1],
[51,30,18,2],
[51,30,18,4],
[51,30,18,7],
[51,30,18,10],
[51,30,18,11],
[51,30,18,12],
[51,30,18,13],
[51,30,18,14],
[51,30,18,16],
[51,30,18,17],
[51,30,19,0],
[51,30,19,1],
[51,30,19,2],
[51,30,19,4],
[51,30,19,7],
[51,30,19,9],
[51,30,19,10],
[51,30,19,11],
[51,30,19,12],
[51,30,19,15],
[51,30,19,16],
[51,30,19,17],
[51,30,19,18],
[51,30,20,0],
[51,30,20,1],
[51,30,20,2],
[51,30,20,4],
[51,30,20,7],
[51,30,20,9],
[51,30,20,11],
[51,30,20,12],
[51,30,20,13],
[51,30,20,14],
[51,30,20,15],
[51,30,20,16],
[51,30,20,17],
[51,30,20,18],
[51,30,20,19],
[51,30,22,0],
[51,30,22,1],
[51,30,22,2],
[51,30,22,4],
[51,30,22,7],
[51,30,22,9],
[51,30,22,11],
[51,30,22,12],
[51,30,22,13],
[51,30,22,14],
[51,30,22,15],
[51,30,22,16],
[51,30,22,17],
[51,30,22,18],
[51,30,22,19],
[51,30,24,0],
[51,30,24,1],
[51,30,24,2],
[51,30,24,4],
[51,30,24,7],
[51,30,24,9],
[51,30,24,10],
[51,30,24,11],
[51,30,24,12],
[51,30,24,13],
[51,30,24,14],
[51,30,24,15],
[51,30,24,17],
[51,30,24,18],
[51,30,24,19],
[51,30,24,20],
[51,30,24,22],
[51,30,25,0],
[51,30,25,1],
[51,30,25,2],
[51,30,25,4],
[51,30,25,9],
[51,30,25,10],
[51,30,25,11],
[51,30,25,12],
[51,30,25,13],
[51,30,25,14],
[51,30,25,15],
[51,30,25,16],
[51,30,25,17],
[51,30,25,18],
[51,30,25,19],
[51,30,25,20],
[51,30,25,22],
[51,30,25,24],
[51,30,26,0],
[51,30,26,1],
[51,30,26,2],
[51,30,26,4],
[51,30,26,7],
[51,30,26,10],
[51,30,26,11],
[51,30,26,12],
[51,30,26,13],
[51,30,26,14],
[51,30,26,16],
[51,30,26,17],
[51,30,26,19],
[51,30,26,20],
[51,30,26,22],
[51,30,26,24],
[51,30,26,25],
[51,30,27,0],
[51,30,27,1],
[51,30,27,2],
[51,30,27,4],
[51,30,27,7],
[51,30,27,9],
[51,30,27,11],
[51,30,27,12],
[51,30,27,13],
[51,30,27,14],
[51,30,27,15],
[51,30,27,16],
[51,30,27,17],
[51,30,27,18],
[51,30,27,19],
[51,30,27,25],
[51,30,27,26],
[51,30,28,0],
[51,30,28,1],
[51,30,28,2],
[51,30,28,4],
[51,30,28,9],
[51,30,28,10],
[51,30,28,11],
[51,30,28,12],
[51,30,28,13],
[51,30,28,14],
[51,30,28,15],
[51,30,28,16],
[51,30,28,17],
[51,30,28,18],
[51,30,28,19],
[51,30,28,20],
[51,30,28,24],
[51,30,28,26],
[51,30,28,27],
[51,30,29,0],
[51,30,29,1],
[51,30,29,2],
[51,30,29,4],
[51,30,29,7],
[51,30,29,9],
[51,30,29,10],
[51,30,29,13],
[51,30,29,14],
[51,30,29,15],
[51,30,29,16],
[51,30,29,17],
[51,30,29,18],
[51,30,29,19],
[51,30,29,20],
[51,30,29,22],
[51,30,29,24],
[51,30,29,25],
[51,30,29,26],
[51,30,29,27],
[51,30,29,28],
[51,31,4,0],
[51,31,4,1],
[51,31,4,2],
[51,31,7,0],
[51,31,7,1],
[51,31,7,2],
[51,31,7,4],
[51,31,9,0],
[51,31,9,1],
[51,31,9,2],
[51,31,9,4],
[51,31,9,7],
[51,31,10,0],
[51,31,10,1],
[51,31,10,2],
[51,31,10,4],
[51,31,10,7],
[51,31,10,9],
[51,31,11,0],
[51,31,11,1],
[51,31,11,2],
[51,31,11,4],
[51,31,11,7],
[51,31,11,9],
[51,31,11,10],
[51,31,12,0],
[51,31,12,1],
[51,31,12,2],
[51,31,12,4],
[51,31,12,7],
[51,31,12,9],
[51,31,12,10],
[51,31,13,0],
[51,31,13,1],
[51,31,13,2],
[51,31,13,4],
[51,31,13,7],
[51,31,13,9],
[51,31,13,10],
[51,31,13,11],
[51,31,13,12],
[51,31,14,0],
[51,31,14,1],
[51,31,14,2],
[51,31,14,4],
[51,31,14,7],
[51,31,14,9],
[51,31,14,10],
[51,31,14,11],
[51,31,14,12],
[51,31,15,0],
[51,31,15,1],
[51,31,15,2],
[51,31,15,4],
[51,31,15,7],
[51,31,15,10],
[51,31,15,11],
[51,31,15,12],
[51,31,15,13],
[51,31,15,14],
[51,31,16,0],
[51,31,16,1],
[51,31,16,2],
[51,31,16,4],
[51,31,16,7],
[51,31,16,9],
[51,31,16,10],
[51,31,16,11],
[51,31,16,12],
[51,31,16,13],
[51,31,16,14],
[51,31,16,15],
[51,31,17,0],
[51,31,17,1],
[51,31,17,2],
[51,31,17,4],
[51,31,17,7],
[51,31,17,9],
[51,31,17,10],
[51,31,17,11],
[51,31,17,12],
[51,31,17,13],
[51,31,17,14],
[51,31,17,15],
[51,31,17,16],
[51,31,18,0],
[51,31,18,1],
[51,31,18,2],
[51,31,18,4],
[51,31,18,7],
[51,31,18,10],
[51,31,18,11],
[51,31,18,12],
[51,31,18,13],
[51,31,18,14],
[51,31,18,16],
[51,31,18,17],
[51,31,19,0],
[51,31,19,1],
[51,31,19,2],
[51,31,19,4],
[51,31,19,7],
[51,31,19,9],
[51,31,19,10],
[51,31,19,11],
[51,31,19,12],
[51,31,19,15],
[51,31,19,16],
[51,31,19,17],
[51,31,19,18],
[51,31,20,0],
[51,31,20,1],
[51,31,20,2],
[51,31,20,4],
[51,31,20,7],
[51,31,20,9],
[51,31,20,11],
[51,31,20,12],
[51,31,20,13],
[51,31,20,14],
[51,31,20,15],
[51,31,20,16],
[51,31,20,17],
[51,31,20,18],
[51,31,20,19],
[51,31,22,0],
[51,31,22,1],
[51,31,22,2],
[51,31,22,4],
[51,31,22,7],
[51,31,22,9],
[51,31,22,11],
[51,31,22,12],
[51,31,22,13],
[51,31,22,14],
[51,31,22,15],
[51,31,22,16],
[51,31,22,17],
[51,31,22,18],
[51,31,22,19],
[51,31,24,0],
[51,31,24,1],
[51,31,24,2],
[51,31,24,4],
[51,31,24,7],
[51,31,24,9],
[51,31,24,10],
[51,31,24,11],
[51,31,24,12],
[51,31,24,13],
[51,31,24,14],
[51,31,24,15],
[51,31,24,17],
[51,31,24,18],
[51,31,24,19],
[51,31,24,20],
[51,31,24,22],
[51,31,25,0],
[51,31,25,1],
[51,31,25,2],
[51,31,25,4],
[51,31,25,9],
[51,31,25,10],
[51,31,25,11],
[51,31,25,12],
[51,31,25,13],
[51,31,25,14],
[51,31,25,15],
[51,31,25,16],
[51,31,25,17],
[51,31,25,18],
[51,31,25,19],
[51,31,25,20],
[51,31,25,22],
[51,31,25,24],
[51,31,26,0],
[51,31,26,1],
[51,31,26,2],
[51,31,26,4],
[51,31,26,7],
[51,31,26,10],
[51,31,26,11],
[51,31,26,12],
[51,31,26,13],
[51,31,26,14],
[51,31,26,16],
[51,31,26,17],
[51,31,26,19],
[51,31,26,20],
[51,31,26,22],
[51,31,26,25],
[51,31,27,0],
[51,31,27,1],
[51,31,27,2],
[51,31,27,4],
[51,31,27,7],
[51,31,27,9],
[51,31,27,11],
[51,31,27,12],
[51,31,27,13],
[51,31,27,14],
[51,31,27,15],
[51,31,27,16],
[51,31,27,17],
[51,31,27,18],
[51,31,27,19],
[51,31,27,24],
[51,31,27,25],
[51,31,27,26],
[51,31,28,0],
[51,31,28,1],
[51,31,28,2],
[51,31,28,4],
[51,31,28,9],
[51,31,28,10],
[51,31,28,11],
[51,31,28,12],
[51,31,28,13],
[51,31,28,14],
[51,31,28,15],
[51,31,28,16],
[51,31,28,17],
[51,31,28,18],
[51,31,28,19],
[51,31,28,20],
[51,31,28,22],
[51,31,28,24],
[51,31,28,26],
[51,31,28,27],
[51,31,29,0],
[51,31,29,1],
[51,31,29,2],
[51,31,29,4],
[51,31,29,7],
[51,31,29,9],
[51,31,29,10],
[51,31,29,13],
[51,31,29,14],
[51,31,29,15],
[51,31,29,16],
[51,31,29,17],
[51,31,29,18],
[51,31,29,19],
[51,31,29,22],
[51,31,29,24],
[51,31,29,25],
[51,31,29,26],
[51,31,29,27],
[51,31,29,28],
[51,32,4,0],
[51,32,4,1],
[51,32,4,2],
[51,32,7,0],
[51,32,7,1],
[51,32,7,2],
[51,32,7,4],
[51,32,9,0],
[51,32,9,1],
[51,32,9,2],
[51,32,9,4],
[51,32,9,7],
[51,32,10,0],
[51,32,10,1],
[51,32,10,2],
[51,32,10,4],
[51,32,10,7],
[51,32,10,9],
[51,32,13,0],
[51,32,13,1],
[51,32,13,2],
[51,32,13,4],
[51,32,13,7],
[51,32,13,9],
[51,32,13,10],
[51,32,14,0],
[51,32,14,1],
[51,32,14,2],
[51,32,14,4],
[51,32,14,7],
[51,32,14,9],
[51,32,14,10],
[51,32,15,0],
[51,32,15,1],
[51,32,15,2],
[51,32,15,4],
[51,32,15,7],
[51,32,15,10],
[51,32,15,13],
[51,32,15,14],
[51,32,16,0],
[51,32,16,1],
[51,32,16,2],
[51,32,16,4],
[51,32,16,7],
[51,32,16,9],
[51,32,16,10],
[51,32,16,13],
[51,32,16,14],
[51,32,16,15],
[51,32,17,0],
[51,32,17,1],
[51,32,17,2],
[51,32,17,4],
[51,32,17,7],
[51,32,17,9],
[51,32,17,10],
[51,32,17,13],
[51,32,17,14],
[51,32,17,15],
[51,32,17,16],
[51,32,18,0],
[51,32,18,1],
[51,32,18,2],
[51,32,18,4],
[51,32,18,7],
[51,32,18,10],
[51,32,18,13],
[51,32,18,14],
[51,32,18,16],
[51,32,18,17],
[51,32,19,0],
[51,32,19,1],
[51,32,19,2],
[51,32,19,4],
[51,32,19,7],
[51,32,19,9],
[51,32,19,10],
[51,32,19,15],
[51,32,19,16],
[51,32,19,17],
[51,32,19,18],
[51,32,20,0],
[51,32,20,1],
[51,32,20,2],
[51,32,20,4],
[51,32,20,7],
[51,32,20,9],
[51,32,20,13],
[51,32,20,14],
[51,32,20,15],
[51,32,20,16],
[51,32,20,17],
[51,32,20,18],
[51,32,20,19],
[51,32,22,0],
[51,32,22,1],
[51,32,22,2],
[51,32,22,4],
[51,32,22,7],
[51,32,22,9],
[51,32,22,13],
[51,32,22,14],
[51,32,22,15],
[51,32,22,16],
[51,32,22,17],
[51,32,22,18],
[51,32,22,19],
[51,32,23,0],
[51,32,23,1],
[51,32,23,2],
[51,32,23,4],
[51,32,23,7],
[51,32,23,9],
[51,32,23,10],
[51,32,23,13],
[51,32,23,14],
[51,32,23,15],
[51,32,23,16],
[51,32,23,17],
[51,32,23,18],
[51,32,23,19],
[51,32,23,20],
[51,32,23,22],
[51,32,24,0],
[51,32,24,1],
[51,32,24,2],
[51,32,24,4],
[51,32,24,7],
[51,32,24,9],
[51,32,24,10],
[51,32,24,13],
[51,32,24,14],
[51,32,24,15],
[51,32,24,17],
[51,32,24,18],
[51,32,24,19],
[51,32,24,20],
[51,32,24,22],
[51,32,24,23],
[51,32,25,0],
[51,32,25,1],
[51,32,25,2],
[51,32,25,4],
[51,32,25,9],
[51,32,25,10],
[51,32,25,13],
[51,32,25,14],
[51,32,25,15],
[51,32,25,16],
[51,32,25,17],
[51,32,25,18],
[51,32,25,19],
[51,32,25,20],
[51,32,25,22],
[51,32,26,0],
[51,32,26,1],
[51,32,26,2],
[51,32,26,4],
[51,32,26,7],
[51,32,26,10],
[51,32,26,13],
[51,32,26,14],
[51,32,26,16],
[51,32,26,17],
[51,32,26,19],
[51,32,26,20],
[51,32,26,23],
[51,32,26,24],
[51,32,26,25],
[51,32,27,0],
[51,32,27,1],
[51,32,27,2],
[51,32,27,4],
[51,32,27,7],
[51,32,27,9],
[51,32,27,13],
[51,32,27,14],
[51,32,27,15],
[51,32,27,16],
[51,32,27,17],
[51,32,27,18],
[51,32,27,19],
[51,32,27,23],
[51,32,27,24],
[51,32,27,25],
[51,32,27,26],
[51,32,28,0],
[51,32,28,1],
[51,32,28,2],
[51,32,28,4],
[51,32,28,9],
[51,32,28,10],
[51,32,28,13],
[51,32,28,14],
[51,32,28,15],
[51,32,28,16],
[51,32,28,17],
[51,32,28,18],
[51,32,28,19],
[51,32,28,22],
[51,32,28,23],
[51,32,28,24],
[51,32,28,26],
[51,32,28,27],
[51,32,30,0],
[51,32,30,1],
[51,32,30,2],
[51,32,30,4],
[51,32,30,7],
[51,32,30,9],
[51,32,30,10],
[51,32,30,13],
[51,32,30,14],
[51,32,30,15],
[51,32,30,16],
[51,32,30,17],
[51,32,30,19],
[51,32,30,20],
[51,32,30,22],
[51,32,30,24],
[51,32,30,25],
[51,32,30,26],
[51,32,30,27],
[51,32,30,28],
[51,32,31,0],
[51,32,31,1],
[51,32,31,2],
[51,32,31,4],
[51,32,31,7],
[51,32,31,9],
[51,32,31,10],
[51,32,31,13],
[51,32,31,14],
[51,32,31,15],
[51,32,31,18],
[51,32,31,19],
[51,32,31,20],
[51,32,31,22],
[51,32,31,24],
[51,32,31,25],
[51,32,31,26],
[51,32,31,27],
[51,32,31,28],
[51,33,4,0],
[51,33,4,1],
[51,33,4,2],
[51,33,7,0],
[51,33,7,1],
[51,33,7,2],
[51,33,7,4],
[51,33,9,0],
[51,33,9,1],
[51,33,9,2],
[51,33,9,4],
[51,33,9,7],
[51,33,10,0],
[51,33,10,1],
[51,33,10,2],
[51,33,10,4],
[51,33,10,7],
[51,33,10,9],
[51,33,13,0],
[51,33,13,1],
[51,33,13,2],
[51,33,13,4],
[51,33,13,7],
[51,33,13,9],
[51,33,13,10],
[51,33,14,0],
[51,33,14,1],
[51,33,14,2],
[51,33,14,4],
[51,33,14,7],
[51,33,14,9],
[51,33,14,10],
[51,33,15,0],
[51,33,15,1],
[51,33,15,2],
[51,33,15,4],
[51,33,15,7],
[51,33,15,10],
[51,33,15,13],
[51,33,15,14],
[51,33,16,0],
[51,33,16,1],
[51,33,16,2],
[51,33,16,4],
[51,33,16,7],
[51,33,16,9],
[51,33,16,10],
[51,33,16,13],
[51,33,16,14],
[51,33,16,15],
[51,33,17,0],
[51,33,17,1],
[51,33,17,2],
[51,33,17,4],
[51,33,17,7],
[51,33,17,9],
[51,33,17,10],
[51,33,17,13],
[51,33,17,14],
[51,33,17,15],
[51,33,17,16],
[51,33,18,0],
[51,33,18,1],
[51,33,18,2],
[51,33,18,4],
[51,33,18,7],
[51,33,18,10],
[51,33,18,13],
[51,33,18,14],
[51,33,18,16],
[51,33,18,17],
[51,33,19,0],
[51,33,19,1],
[51,33,19,2],
[51,33,19,4],
[51,33,19,7],
[51,33,19,9],
[51,33,19,10],
[51,33,19,15],
[51,33,19,16],
[51,33,19,17],
[51,33,19,18],
[51,33,20,0],
[51,33,20,1],
[51,33,20,2],
[51,33,20,4],
[51,33,20,7],
[51,33,20,9],
[51,33,20,13],
[51,33,20,14],
[51,33,20,15],
[51,33,20,16],
[51,33,20,17],
[51,33,20,18],
[51,33,20,19],
[51,33,22,0],
[51,33,22,1],
[51,33,22,2],
[51,33,22,4],
[51,33,22,7],
[51,33,22,9],
[51,33,22,13],
[51,33,22,14],
[51,33,22,15],
[51,33,22,16],
[51,33,22,17],
[51,33,22,18],
[51,33,22,19],
[51,33,23,0],
[51,33,23,1],
[51,33,23,2],
[51,33,23,4],
[51,33,23,7],
[51,33,23,9],
[51,33,23,10],
[51,33,23,13],
[51,33,23,14],
[51,33,23,15],
[51,33,23,16],
[51,33,23,17],
[51,33,23,18],
[51,33,23,19],
[51,33,23,20],
[51,33,23,22],
[51,33,24,0],
[51,33,24,1],
[51,33,24,2],
[51,33,24,4],
[51,33,24,7],
[51,33,24,9],
[51,33,24,10],
[51,33,24,13],
[51,33,24,14],
[51,33,24,15],
[51,33,24,17],
[51,33,24,18],
[51,33,24,19],
[51,33,24,20],
[51,33,24,22],
[51,33,24,23],
[51,33,25,0],
[51,33,25,1],
[51,33,25,2],
[51,33,25,4],
[51,33,25,9],
[51,33,25,10],
[51,33,25,13],
[51,33,25,14],
[51,33,25,15],
[51,33,25,16],
[51,33,25,17],
[51,33,25,18],
[51,33,25,19],
[51,33,25,20],
[51,33,25,23],
[51,33,25,24],
[51,33,26,0],
[51,33,26,1],
[51,33,26,2],
[51,33,26,4],
[51,33,26,7],
[51,33,26,10],
[51,33,26,13],
[51,33,26,14],
[51,33,26,16],
[51,33,26,17],
[51,33,26,19],
[51,33,26,20],
[51,33,26,22],
[51,33,26,23],
[51,33,26,24],
[51,33,26,25],
[51,33,27,0],
[51,33,27,1],
[51,33,27,2],
[51,33,27,4],
[51,33,27,7],
[51,33,27,9],
[51,33,27,13],
[51,33,27,14],
[51,33,27,15],
[51,33,27,16],
[51,33,27,17],
[51,33,27,18],
[51,33,27,19],
[51,33,27,23],
[51,33,27,24],
[51,33,27,25],
[51,33,27,26],
[51,33,28,0],
[51,33,28,1],
[51,33,28,2],
[51,33,28,4],
[51,33,28,9],
[51,33,28,10],
[51,33,28,13],
[51,33,28,14],
[51,33,28,15],
[51,33,28,16],
[51,33,28,17],
[51,33,28,18],
[51,33,28,20],
[51,33,28,22],
[51,33,28,23],
[51,33,28,24],
[51,33,28,26],
[51,33,28,27],
[51,33,30,0],
[51,33,30,1],
[51,33,30,2],
[51,33,30,4],
[51,33,30,7],
[51,33,30,9],
[51,33,30,10],
[51,33,30,13],
[51,33,30,14],
[51,33,30,15],
[51,33,30,18],
[51,33,30,19],
[51,33,30,20],
[51,33,30,22],
[51,33,30,24],
[51,33,30,25],
[51,33,30,26],
[51,33,30,27],
[51,33,30,28],
[51,33,31,0],
[51,33,31,1],
[51,33,31,2],
[51,33,31,4],
[51,33,31,7],
[51,33,31,9],
[51,33,31,10],
[51,33,31,13],
[51,33,31,14],
[51,33,31,16],
[51,33,31,17],
[51,33,31,18],
[51,33,31,19],
[51,33,31,20],
[51,33,31,22],
[51,33,31,24],
[51,33,31,25],
[51,33,31,26],
[51,33,31,27],
[51,33,31,28],
[51,34,4,0],
[51,34,4,1],
[51,34,4,2],
[51,34,9,0],
[51,34,9,1],
[51,34,9,2],
[51,34,9,4],
[51,34,10,0],
[51,34,10,1],
[51,34,10,2],
[51,34,10,4],
[51,34,10,9],
[51,34,11,0],
[51,34,11,1],
[51,34,11,2],
[51,34,11,4],
[51,34,11,9],
[51,34,11,10],
[51,34,12,0],
[51,34,12,1],
[51,34,12,2],
[51,34,12,4],
[51,34,12,9],
[51,34,12,10],
[51,34,13,0],
[51,34,13,1],
[51,34,13,2],
[51,34,13,4],
[51,34,13,9],
[51,34,13,10],
[51,34,13,11],
[51,34,13,12],
[51,34,14,0],
[51,34,14,1],
[51,34,14,2],
[51,34,14,4],
[51,34,14,9],
[51,34,14,10],
[51,34,14,11],
[51,34,14,12],
[51,34,15,0],
[51,34,15,1],
[51,34,15,2],
[51,34,15,4],
[51,34,15,10],
[51,34,15,11],
[51,34,15,12],
[51,34,15,13],
[51,34,15,14],
[51,34,16,0],
[51,34,16,1],
[51,34,16,2],
[51,34,16,4],
[51,34,16,9],
[51,34,16,10],
[51,34,16,11],
[51,34,16,12],
[51,34,16,13],
[51,34,16,14],
[51,34,16,15],
[51,34,17,0],
[51,34,17,1],
[51,34,17,2],
[51,34,17,4],
[51,34,17,9],
[51,34,17,10],
[51,34,17,11],
[51,34,17,12],
[51,34,17,13],
[51,34,17,14],
[51,34,17,15],
[51,34,17,16],
[51,34,18,0],
[51,34,18,1],
[51,34,18,2],
[51,34,18,4],
[51,34,18,10],
[51,34,18,11],
[51,34,18,12],
[51,34,18,13],
[51,34,18,14],
[51,34,18,16],
[51,34,18,17],
[51,34,19,0],
[51,34,19,1],
[51,34,19,2],
[51,34,19,4],
[51,34,19,9],
[51,34,19,10],
[51,34,19,11],
[51,34,19,12],
[51,34,19,15],
[51,34,19,16],
[51,34,19,17],
[51,34,19,18],
[51,34,20,0],
[51,34,20,1],
[51,34,20,2],
[51,34,20,4],
[51,34,20,9],
[51,34,20,11],
[51,34,20,12],
[51,34,20,13],
[51,34,20,14],
[51,34,20,15],
[51,34,20,16],
[51,34,20,17],
[51,34,20,18],
[51,34,20,19],
[51,34,22,0],
[51,34,22,1],
[51,34,22,2],
[51,34,22,4],
[51,34,22,9],
[51,34,22,11],
[51,34,22,12],
[51,34,22,13],
[51,34,22,14],
[51,34,22,15],
[51,34,22,16],
[51,34,22,17],
[51,34,22,18],
[51,34,22,19],
[51,34,23,0],
[51,34,23,1],
[51,34,23,2],
[51,34,23,4],
[51,34,23,9],
[51,34,23,10],
[51,34,23,11],
[51,34,23,12],
[51,34,23,13],
[51,34,23,14],
[51,34,23,15],
[51,34,23,16],
[51,34,23,17],
[51,34,23,18],
[51,34,23,19],
[51,34,23,20],
[51,34,23,22],
[51,34,24,0],
[51,34,24,1],
[51,34,24,2],
[51,34,24,4],
[51,34,24,9],
[51,34,24,10],
[51,34,24,11],
[51,34,24,12],
[51,34,24,13],
[51,34,24,14],
[51,34,24,15],
[51,34,24,17],
[51,34,24,18],
[51,34,24,19],
[51,34,24,20],
[51,34,24,22],
[51,34,26,0],
[51,34,26,1],
[51,34,26,2],
[51,34,26,4],
[51,34,26,10],
[51,34,26,11],
[51,34,26,12],
[51,34,26,13],
[51,34,26,14],
[51,34,26,16],
[51,34,26,17],
[51,34,26,19],
[51,34,26,22],
[51,34,26,23],
[51,34,26,24],
[51,34,27,0],
[51,34,27,1],
[51,34,27,2],
[51,34,27,4],
[51,34,27,9],
[51,34,27,11],
[51,34,27,12],
[51,34,27,13],
[51,34,27,14],
[51,34,27,15],
[51,34,27,16],
[51,34,27,17],
[51,34,27,18],
[51,34,27,23],
[51,34,27,24],
[51,34,27,26],
[51,34,29,0],
[51,34,29,1],
[51,34,29,2],
[51,34,29,4],
[51,34,29,9],
[51,34,29,10],
[51,34,29,13],
[51,34,29,14],
[51,34,29,15],
[51,34,29,18],
[51,34,29,19],
[51,34,29,20],
[51,34,29,22],
[51,34,29,23],
[51,34,29,24],
[51,34,29,26],
[51,34,29,27],
[51,34,30,0],
[51,34,30,1],
[51,34,30,2],
[51,34,30,4],
[51,34,30,9],
[51,34,30,10],
[51,34,30,11],
[51,34,30,12],
[51,34,30,13],
[51,34,30,14],
[51,34,30,16],
[51,34,30,17],
[51,34,30,18],
[51,34,30,19],
[51,34,30,20],
[51,34,30,22],
[51,34,30,24],
[51,34,30,26],
[51,34,30,27],
[51,34,31,0],
[51,34,31,1],
[51,34,31,2],
[51,34,31,4],
[51,34,31,9],
[51,34,31,10],
[51,34,31,11],
[51,34,31,12],
[51,34,31,13],
[51,34,31,15],
[51,34,31,16],
[51,34,31,17],
[51,34,31,18],
[51,34,31,19],
[51,34,31,20],
[51,34,31,22],
[51,34,31,24],
[51,34,31,26],
[51,34,31,27],
[51,34,32,0],
[51,34,32,1],
[51,34,32,2],
[51,34,32,4],
[51,34,32,9],
[51,34,32,10],
[51,34,32,14],
[51,34,32,15],
[51,34,32,16],
[51,34,32,17],
[51,34,32,18],
[51,34,32,19],
[51,34,32,20],
[51,34,32,22],
[51,34,32,23],
[51,34,32,24],
[51,34,32,26],
[51,34,33,0],
[51,34,33,1],
[51,34,33,2],
[51,34,33,4],
[51,34,33,9],
[51,34,33,13],
[51,34,33,14],
[51,34,33,15],
[51,34,33,16],
[51,34,33,17],
[51,34,33,18],
[51,34,33,19],
[51,34,33,20],
[51,34,33,22],
[51,34,33,23],
[51,34,33,24],
[51,35,4,0],
[51,35,4,1],
[51,35,4,2],
[51,35,7,0],
[51,35,7,1],
[51,35,7,2],
[51,35,7,4],
[51,35,10,0],
[51,35,10,1],
[51,35,10,2],
[51,35,10,4],
[51,35,10,7],
[51,35,11,0],
[51,35,11,1],
[51,35,11,2],
[51,35,11,4],
[51,35,11,7],
[51,35,11,10],
[51,35,12,0],
[51,35,12,1],
[51,35,12,2],
[51,35,12,4],
[51,35,12,7],
[51,35,12,10],
[51,35,13,0],
[51,35,13,1],
[51,35,13,2],
[51,35,13,4],
[51,35,13,7],
[51,35,13,10],
[51,35,13,11],
[51,35,13,12],
[51,35,14,0],
[51,35,14,1],
[51,35,14,2],
[51,35,14,4],
[51,35,14,7],
[51,35,14,10],
[51,35,14,11],
[51,35,14,12],
[51,35,16,0],
[51,35,16,1],
[51,35,16,2],
[51,35,16,4],
[51,35,16,7],
[51,35,16,10],
[51,35,16,11],
[51,35,16,12],
[51,35,16,13],
[51,35,16,14],
[51,35,17,0],
[51,35,17,1],
[51,35,17,2],
[51,35,17,4],
[51,35,17,7],
[51,35,17,10],
[51,35,17,11],
[51,35,17,12],
[51,35,17,13],
[51,35,17,14],
[51,35,17,16],
[51,35,19,0],
[51,35,19,1],
[51,35,19,2],
[51,35,19,4],
[51,35,19,7],
[51,35,19,10],
[51,35,19,11],
[51,35,19,12],
[51,35,19,16],
[51,35,19,17],
[51,35,20,0],
[51,35,20,1],
[51,35,20,2],
[51,35,20,4],
[51,35,20,7],
[51,35,20,11],
[51,35,20,12],
[51,35,20,13],
[51,35,20,14],
[51,35,20,16],
[51,35,20,17],
[51,35,20,19],
[51,35,22,0],
[51,35,22,1],
[51,35,22,2],
[51,35,22,4],
[51,35,22,7],
[51,35,22,11],
[51,35,22,12],
[51,35,22,13],
[51,35,22,14],
[51,35,22,16],
[51,35,22,17],
[51,35,22,19],
[51,35,23,0],
[51,35,23,1],
[51,35,23,2],
[51,35,23,4],
[51,35,23,7],
[51,35,23,10],
[51,35,23,11],
[51,35,23,12],
[51,35,23,13],
[51,35,23,14],
[51,35,23,16],
[51,35,23,17],
[51,35,23,19],
[51,35,23,20],
[51,35,24,0],
[51,35,24,1],
[51,35,24,2],
[51,35,24,4],
[51,35,24,7],
[51,35,24,10],
[51,35,24,11],
[51,35,24,12],
[51,35,24,13],
[51,35,24,14],
[51,35,24,17],
[51,35,24,19],
[51,35,24,20],
[51,35,24,23],
[51,35,25,0],
[51,35,25,1],
[51,35,25,2],
[51,35,25,4],
[51,35,25,10],
[51,35,25,11],
[51,35,25,12],
[51,35,25,13],
[51,35,25,14],
[51,35,25,16],
[51,35,25,17],
[51,35,25,19],
[51,35,25,22],
[51,35,25,23],
[51,35,25,24],
[51,35,27,0],
[51,35,27,1],
[51,35,27,2],
[51,35,27,4],
[51,35,27,7],
[51,35,27,11],
[51,35,27,12],
[51,35,27,13],
[51,35,27,14],
[51,35,27,16],
[51,35,27,17],
[51,35,27,19],
[51,35,27,23],
[51,35,27,24],
[51,35,27,25],
[51,35,28,0],
[51,35,28,1],
[51,35,28,2],
[51,35,28,4],
[51,35,28,10],
[51,35,28,11],
[51,35,28,12],
[51,35,28,13],
[51,35,28,14],
[51,35,28,19],
[51,35,28,20],
[51,35,28,22],
[51,35,28,23],
[51,35,28,24],
[51,35,28,27],
[51,35,29,0],
[51,35,29,1],
[51,35,29,2],
[51,35,29,4],
[51,35,29,7],
[51,35,29,10],
[51,35,29,13],
[51,35,29,14],
[51,35,29,16],
[51,35,29,17],
[51,35,29,19],
[51,35,29,20],
[51,35,29,22],
[51,35,29,23],
[51,35,29,24],
[51,35,29,25],
[51,35,29,27],
[51,35,29,28],
[51,35,30,0],
[51,35,30,1],
[51,35,30,2],
[51,35,30,4],
[51,35,30,7],
[51,35,30,10],
[51,35,30,11],
[51,35,30,12],
[51,35,30,13],
[51,35,30,16],
[51,35,30,17],
[51,35,30,19],
[51,35,30,20],
[51,35,30,22],
[51,35,30,24],
[51,35,30,25],
[51,35,30,27],
[51,35,31,0],
[51,35,31,1],
[51,35,31,2],
[51,35,31,4],
[51,35,31,7],
[51,35,31,10],
[51,35,31,11],
[51,35,31,14],
[51,35,31,16],
[51,35,31,17],
[51,35,31,19],
[51,35,31,20],
[51,35,31,22],
[51,35,31,24],
[51,35,31,25],
[51,35,32,0],
[51,35,32,1],
[51,35,32,2],
[51,35,32,4],
[51,35,32,7],
[51,35,32,13],
[51,35,32,14],
[51,35,32,16],
[51,35,32,17],
[51,35,32,19],
[51,35,32,20],
[51,35,32,22],
[51,35,32,23],
[51,35,32,24],
[51,35,32,25],
[51,35,33,0],
[51,35,33,1],
[51,35,33,2],
[51,35,33,4],
[51,35,33,7],
[51,35,33,10],
[51,35,33,13],
[51,35,33,14],
[51,35,33,16],
[51,35,33,17],
[51,35,33,19],
[51,35,33,20],
[51,35,33,22],
[51,35,33,23],
[51,35,33,24],
[51,35,34,0],
[51,35,34,1],
[51,35,34,2],
[51,35,34,4],
[51,35,34,10],
[51,35,34,11],
[51,35,34,12],
[51,35,34,13],
[51,35,34,14],
[51,35,34,16],
[51,35,34,17],
[51,35,34,19],
[51,35,34,20],
[51,35,34,22],
[51,35,34,23],
[51,35,34,24],
[51,36,4,0],
[51,36,4,1],
[51,36,4,2],
[51,36,7,0],
[51,36,7,1],
[51,36,7,2],
[51,36,7,4],
[51,36,9,0],
[51,36,9,1],
[51,36,9,2],
[51,36,9,4],
[51,36,9,7],
[51,36,10,0],
[51,36,10,1],
[51,36,10,2],
[51,36,10,4],
[51,36,10,7],
[51,36,10,9],
[51,36,11,0],
[51,36,11,1],
[51,36,11,2],
[51,36,11,4],
[51,36,11,7],
[51,36,11,9],
[51,36,11,10],
[51,36,12,0],
[51,36,12,1],
[51,36,12,2],
[51,36,12,4],
[51,36,12,7],
[51,36,12,9],
[51,36,12,10],
[51,36,15,0],
[51,36,15,1],
[51,36,15,2],
[51,36,15,4],
[51,36,15,7],
[51,36,15,10],
[51,36,15,11],
[51,36,15,12],
[51,36,16,0],
[51,36,16,1],
[51,36,16,2],
[51,36,16,4],
[51,36,16,7],
[51,36,16,9],
[51,36,16,10],
[51,36,16,11],
[51,36,16,12],
[51,36,16,15],
[51,36,17,0],
[51,36,17,1],
[51,36,17,2],
[51,36,17,4],
[51,36,17,7],
[51,36,17,9],
[51,36,17,10],
[51,36,17,11],
[51,36,17,12],
[51,36,17,15],
[51,36,17,16],
[51,36,18,0],
[51,36,18,1],
[51,36,18,2],
[51,36,18,4],
[51,36,18,7],
[51,36,18,10],
[51,36,18,11],
[51,36,18,12],
[51,36,18,16],
[51,36,18,17],
[51,36,20,0],
[51,36,20,1],
[51,36,20,2],
[51,36,20,4],
[51,36,20,7],
[51,36,20,9],
[51,36,20,11],
[51,36,20,12],
[51,36,20,15],
[51,36,20,16],
[51,36,20,17],
[51,36,20,18],
[51,36,22,0],
[51,36,22,1],
[51,36,22,2],
[51,36,22,4],
[51,36,22,7],
[51,36,22,9],
[51,36,22,11],
[51,36,22,12],
[51,36,22,15],
[51,36,22,16],
[51,36,22,17],
[51,36,22,18],
[51,36,23,0],
[51,36,23,1],
[51,36,23,2],
[51,36,23,4],
[51,36,23,7],
[51,36,23,9],
[51,36,23,10],
[51,36,23,11],
[51,36,23,12],
[51,36,23,15],
[51,36,23,16],
[51,36,23,17],
[51,36,23,18],
[51,36,23,20],
[51,36,23,22],
[51,36,24,0],
[51,36,24,1],
[51,36,24,2],
[51,36,24,4],
[51,36,24,7],
[51,36,24,9],
[51,36,24,10],
[51,36,24,11],
[51,36,24,12],
[51,36,24,15],
[51,36,24,17],
[51,36,24,18],
[51,36,24,20],
[51,36,24,22],
[51,36,24,23],
[51,36,25,0],
[51,36,25,1],
[51,36,25,2],
[51,36,25,4],
[51,36,25,9],
[51,36,25,10],
[51,36,25,11],
[51,36,25,12],
[51,36,25,15],
[51,36,25,16],
[51,36,25,17],
[51,36,25,18],
[51,36,25,20],
[51,36,25,22],
[51,36,25,23],
[51,36,25,24],
[51,36,26,0],
[51,36,26,1],
[51,36,26,2],
[51,36,26,4],
[51,36,26,7],
[51,36,26,10],
[51,36,26,11],
[51,36,26,12],
[51,36,26,16],
[51,36,26,17],
[51,36,26,20],
[51,36,26,22],
[51,36,26,23],
[51,36,26,24],
[51,36,26,25],
[51,36,27,0],
[51,36,27,1],
[51,36,27,2],
[51,36,27,4],
[51,36,27,7],
[51,36,27,9],
[51,36,27,11],
[51,36,27,12],
[51,36,27,15],
[51,36,27,18],
[51,36,27,23],
[51,36,27,24],
[51,36,27,25],
[51,36,27,26],
[51,36,28,0],
[51,36,28,1],
[51,36,28,2],
[51,36,28,4],
[51,36,28,9],
[51,36,28,10],
[51,36,28,11],
[51,36,28,12],
[51,36,28,16],
[51,36,28,17],
[51,36,28,18],
[51,36,28,20],
[51,36,28,22],
[51,36,28,23],
[51,36,28,24],
[51,36,28,26],
[51,36,28,27],
[51,36,29,0],
[51,36,29,1],
[51,36,29,2],
[51,36,29,4],
[51,36,29,7],
[51,36,29,9],
[51,36,29,10],
[51,36,29,15],
[51,36,29,16],
[51,36,29,17],
[51,36,29,18],
[51,36,29,20],
[51,36,29,22],
[51,36,29,23],
[51,36,29,24],
[51,36,29,25],
[51,36,29,26],
[51,36,29,27],
[51,36,30,0],
[51,36,30,1],
[51,36,30,2],
[51,36,30,4],
[51,36,30,7],
[51,36,30,9],
[51,36,30,10],
[51,36,30,11],
[51,36,30,15],
[51,36,30,16],
[51,36,30,17],
[51,36,30,18],
[51,36,30,20],
[51,36,30,22],
[51,36,30,24],
[51,36,30,25],
[51,36,30,26],
[51,36,31,0],
[51,36,31,1],
[51,36,31,2],
[51,36,31,4],
[51,36,31,7],
[51,36,31,9],
[51,36,31,12],
[51,36,31,15],
[51,36,31,16],
[51,36,31,17],
[51,36,31,18],
[51,36,31,20],
[51,36,31,22],
[51,36,31,24],
[51,36,31,25],
[51,36,32,0],
[51,36,32,1],
[51,36,32,2],
[51,36,32,4],
[51,36,32,7],
[51,36,32,10],
[51,36,32,15],
[51,36,32,16],
[51,36,32,17],
[51,36,32,18],
[51,36,32,20],
[51,36,32,22],
[51,36,32,23],
[51,36,32,24],
[51,36,33,0],
[51,36,33,1],
[51,36,33,2],
[51,36,33,4],
[51,36,33,9],
[51,36,33,10],
[51,36,33,15],
[51,36,33,16],
[51,36,33,17],
[51,36,33,18],
[51,36,33,20],
[51,36,33,22],
[51,36,33,23],
[51,36,33,24],
[51,36,34,0],
[51,36,34,1],
[51,36,34,2],
[51,36,34,4],
[51,36,34,9],
[51,36,34,10],
[51,36,34,11],
[51,36,34,12],
[51,36,34,15],
[51,36,34,16],
[51,36,34,17],
[51,36,34,18],
[51,36,34,20],
[51,36,34,22],
[51,36,35,0],
[51,36,35,1],
[51,36,35,2],
[51,36,35,7],
[51,36,35,10],
[51,36,35,11],
[51,36,35,12],
[51,36,35,16],
[51,36,35,17],
[51,36,35,20],
[51,37,7,0],
[51,37,7,1],
[51,37,7,2],
[51,37,9,0],
[51,37,9,1],
[51,37,9,2],
[51,37,9,7],
[51,37,10,0],
[51,37,10,1],
[51,37,10,2],
[51,37,10,7],
[51,37,10,9],
[51,37,11,0],
[51,37,11,1],
[51,37,11,2],
[51,37,11,7],
[51,37,11,9],
[51,37,11,10],
[51,37,12,0],
[51,37,12,1],
[51,37,12,2],
[51,37,12,7],
[51,37,12,9],
[51,37,12,10],
[51,37,13,0],
[51,37,13,1],
[51,37,13,2],
[51,37,13,7],
[51,37,13,9],
[51,37,13,10],
[51,37,13,11],
[51,37,13,12],
[51,37,14,0],
[51,37,14,1],
[51,37,14,2],
[51,37,14,7],
[51,37,14,9],
[51,37,14,10],
[51,37,14,11],
[51,37,14,12],
[51,37,15,0],
[51,37,15,1],
[51,37,15,2],
[51,37,15,7],
[51,37,15,10],
[51,37,15,11],
[51,37,15,12],
[51,37,15,13],
[51,37,15,14],
[51,37,16,0],
[51,37,16,1],
[51,37,16,2],
[51,37,16,7],
[51,37,16,9],
[51,37,16,10],
[51,37,16,11],
[51,37,16,12],
[51,37,16,13],
[51,37,16,14],
[51,37,16,15],
[51,37,17,0],
[51,37,17,1],
[51,37,17,2],
[51,37,17,7],
[51,37,17,9],
[51,37,17,10],
[51,37,17,11],
[51,37,17,12],
[51,37,17,13],
[51,37,17,14],
[51,37,17,15],
[51,37,17,16],
[51,37,18,0],
[51,37,18,1],
[51,37,18,2],
[51,37,18,7],
[51,37,18,10],
[51,37,18,11],
[51,37,18,12],
[51,37,18,13],
[51,37,18,14],
[51,37,18,16],
[51,37,18,17],
[51,37,19,0],
[51,37,19,1],
[51,37,19,2],
[51,37,19,7],
[51,37,19,9],
[51,37,19,10],
[51,37,19,11],
[51,37,19,12],
[51,37,19,15],
[51,37,19,16],
[51,37,19,17],
[51,37,19,18],
[51,37,20,0],
[51,37,20,1],
[51,37,20,2],
[51,37,20,7],
[51,37,20,9],
[51,37,20,11],
[51,37,20,12],
[51,37,20,13],
[51,37,20,14],
[51,37,20,15],
[51,37,20,16],
[51,37,20,17],
[51,37,20,18],
[51,37,20,19],
[51,37,22,0],
[51,37,22,1],
[51,37,22,2],
[51,37,22,7],
[51,37,22,9],
[51,37,22,11],
[51,37,22,12],
[51,37,22,13],
[51,37,22,14],
[51,37,22,15],
[51,37,22,16],
[51,37,22,17],
[51,37,22,18],
[51,37,22,19],
[51,37,23,0],
[51,37,23,1],
[51,37,23,2],
[51,37,23,7],
[51,37,23,9],
[51,37,23,10],
[51,37,23,11],
[51,37,23,12],
[51,37,23,13],
[51,37,23,14],
[51,37,23,15],
[51,37,23,16],
[51,37,23,17],
[51,37,23,18],
[51,37,23,19],
[51,37,23,22],
[51,37,24,0],
[51,37,24,1],
[51,37,24,2],
[51,37,24,7],
[51,37,24,9],
[51,37,24,10],
[51,37,24,11],
[51,37,24,12],
[51,37,24,13],
[51,37,24,14],
[51,37,24,15],
[51,37,24,17],
[51,37,24,18],
[51,37,24,19],
[51,37,24,22],
[51,37,24,23],
[51,37,25,0],
[51,37,25,1],
[51,37,25,2],
[51,37,25,9],
[51,37,25,10],
[51,37,25,11],
[51,37,25,12],
[51,37,25,13],
[51,37,25,14],
[51,37,25,15],
[51,37,25,16],
[51,37,25,17],
[51,37,25,19],
[51,37,25,20],
[51,37,25,22],
[51,37,25,23],
[51,37,25,24],
[51,37,26,0],
[51,37,26,1],
[51,37,26,2],
[51,37,26,7],
[51,37,26,10],
[51,37,26,11],
[51,37,26,12],
[51,37,26,13],
[51,37,26,14],
[51,37,26,19],
[51,37,26,20],
[51,37,26,22],
[51,37,26,23],
[51,37,26,24],
[51,37,26,25],
[51,37,27,0],
[51,37,27,1],
[51,37,27,2],
[51,37,27,7],
[51,37,27,9],
[51,37,27,11],
[51,37,27,12],
[51,37,27,13],
[51,37,27,14],
[51,37,27,16],
[51,37,27,17],
[51,37,27,18],
[51,37,27,19],
[51,37,27,23],
[51,37,27,24],
[51,37,27,25],
[51,37,27,26],
[51,37,28,0],
[51,37,28,1],
[51,37,28,2],
[51,37,28,9],
[51,37,28,10],
[51,37,28,11],
[51,37,28,12],
[51,37,28,13],
[51,37,28,15],
[51,37,28,16],
[51,37,28,17],
[51,37,28,18],
[51,37,28,19],
[51,37,28,20],
[51,37,28,22],
[51,37,28,23],
[51,37,28,24],
[51,37,28,26],
[51,37,28,27],
[51,37,29,0],
[51,37,29,1],
[51,37,29,2],
[51,37,29,7],
[51,37,29,9],
[51,37,29,10],
[51,37,29,14],
[51,37,29,15],
[51,37,29,16],
[51,37,29,17],
[51,37,29,18],
[51,37,29,19],
[51,37,29,20],
[51,37,29,22],
[51,37,29,23],
[51,37,29,24],
[51,37,29,25],
[51,37,29,26],
[51,37,30,0],
[51,37,30,1],
[51,37,30,2],
[51,37,30,7],
[51,37,30,9],
[51,37,30,12],
[51,37,30,13],
[51,37,30,14],
[51,37,30,15],
[51,37,30,16],
[51,37,30,17],
[51,37,30,18],
[51,37,30,19],
[51,37,30,20],
[51,37,30,22],
[51,37,30,24],
[51,37,30,25],
[51,37,31,0],
[51,37,31,1],
[51,37,31,2],
[51,37,31,7],
[51,37,31,10],
[51,37,31,11],
[51,37,31,12],
[51,37,31,13],
[51,37,31,14],
[51,37,31,15],
[51,37,31,16],
[51,37,31,17],
[51,37,31,18],
[51,37,31,19],
[51,37,31,20],
[51,37,31,22],
[51,37,31,24],
[51,37,32,0],
[51,37,32,1],
[51,37,32,2],
[51,37,32,9],
[51,37,32,10],
[51,37,32,13],
[51,37,32,14],
[51,37,32,15],
[51,37,32,16],
[51,37,32,17],
[51,37,32,18],
[51,37,32,19],
[51,37,32,20],
[51,37,32,22],
[51,37,32,23],
[51,37,32,24],
[51,37,33,0],
[51,37,33,1],
[51,37,33,2],
[51,37,33,7],
[51,37,33,9],
[51,37,33,10],
[51,37,33,13],
[51,37,33,14],
[51,37,33,15],
[51,37,33,16],
[51,37,33,17],
[51,37,33,18],
[51,37,33,19],
[51,37,33,20],
[51,37,33,22],
[51,37,34,0],
[51,37,34,1],
[51,37,34,2],
[51,37,34,9],
[51,37,34,10],
[51,37,34,11],
[51,37,34,12],
[51,37,34,13],
[51,37,34,14],
[51,37,34,15],
[51,37,34,16],
[51,37,34,17],
[51,37,34,18],
[51,37,34,19],
[51,37,34,20],
[51,37,35,7],
[51,37,35,10],
[51,37,35,11],
[51,37,35,12],
[51,37,35,13],
[51,37,35,14],
[51,37,35,16],
[51,37,35,17],
[51,37,35,19],
[51,37,35,20],
[51,37,36,0],
[51,37,36,1],
[51,37,36,2],
[51,37,36,7],
[51,37,36,9],
[51,37,36,10],
[51,37,36,11],
[51,37,36,12],
[51,37,36,15],
[51,37,36,16],
[51,37,36,17],
[51,37,36,18],
[51,38,4,0],
[51,38,4,1],
[51,38,4,2],
[51,38,9,0],
[51,38,9,1],
[51,38,9,2],
[51,38,9,4],
[51,38,10,0],
[51,38,10,1],
[51,38,10,2],
[51,38,10,4],
[51,38,10,9],
[51,38,11,0],
[51,38,11,1],
[51,38,11,2],
[51,38,11,4],
[51,38,11,9],
[51,38,11,10],
[51,38,12,0],
[51,38,12,1],
[51,38,12,2],
[51,38,12,4],
[51,38,12,9],
[51,38,12,10],
[51,38,13,0],
[51,38,13,1],
[51,38,13,2],
[51,38,13,4],
[51,38,13,9],
[51,38,13,10],
[51,38,13,11],
[51,38,13,12],
[51,38,14,0],
[51,38,14,1],
[51,38,14,2],
[51,38,14,4],
[51,38,14,9],
[51,38,14,10],
[51,38,14,11],
[51,38,14,12],
[51,38,15,0],
[51,38,15,1],
[51,38,15,2],
[51,38,15,4],
[51,38,15,10],
[51,38,15,11],
[51,38,15,12],
[51,38,15,13],
[51,38,15,14],
[51,38,16,0],
[51,38,16,1],
[51,38,16,2],
[51,38,16,4],
[51,38,16,9],
[51,38,16,10],
[51,38,16,11],
[51,38,16,12],
[51,38,16,13],
[51,38,16,14],
[51,38,16,15],
[51,38,17,0],
[51,38,17,1],
[51,38,17,2],
[51,38,17,4],
[51,38,17,9],
[51,38,17,10],
[51,38,17,11],
[51,38,17,12],
[51,38,17,13],
[51,38,17,14],
[51,38,17,15],
[51,38,17,16],
[51,38,18,0],
[51,38,18,1],
[51,38,18,2],
[51,38,18,4],
[51,38,18,10],
[51,38,18,11],
[51,38,18,12],
[51,38,18,13],
[51,38,18,14],
[51,38,18,16],
[51,38,18,17],
[51,38,19,0],
[51,38,19,1],
[51,38,19,2],
[51,38,19,4],
[51,38,19,9],
[51,38,19,10],
[51,38,19,11],
[51,38,19,12],
[51,38,19,15],
[51,38,19,16],
[51,38,19,17],
[51,38,19,18],
[51,38,20,0],
[51,38,20,1],
[51,38,20,2],
[51,38,20,4],
[51,38,20,9],
[51,38,20,11],
[51,38,20,12],
[51,38,20,13],
[51,38,20,14],
[51,38,20,15],
[51,38,20,16],
[51,38,20,17],
[51,38,20,18],
[51,38,20,19],
[51,38,22,0],
[51,38,22,1],
[51,38,22,2],
[51,38,22,4],
[51,38,22,9],
[51,38,22,11],
[51,38,22,12],
[51,38,22,13],
[51,38,22,14],
[51,38,22,15],
[51,38,22,16],
[51,38,22,17],
[51,38,22,18],
[51,38,22,19],
[51,38,23,0],
[51,38,23,1],
[51,38,23,2],
[51,38,23,4],
[51,38,23,9],
[51,38,23,10],
[51,38,23,11],
[51,38,23,12],
[51,38,23,13],
[51,38,23,14],
[51,38,23,15],
[51,38,23,16],
[51,38,23,17],
[51,38,23,18],
[51,38,23,20],
[51,38,23,22],
[51,38,24,0],
[51,38,24,1],
[51,38,24,2],
[51,38,24,4],
[51,38,24,9],
[51,38,24,10],
[51,38,24,11],
[51,38,24,12],
[51,38,24,13],
[51,38,24,14],
[51,38,24,15],
[51,38,24,17],
[51,38,24,18],
[51,38,24,20],
[51,38,24,22],
[51,38,24,23],
[51,38,26,0],
[51,38,26,1],
[51,38,26,2],
[51,38,26,4],
[51,38,26,10],
[51,38,26,11],
[51,38,26,12],
[51,38,26,13],
[51,38,26,14],
[51,38,26,16],
[51,38,26,17],
[51,38,26,19],
[51,38,26,20],
[51,38,26,22],
[51,38,26,23],
[51,38,26,24],
[51,38,27,0],
[51,38,27,1],
[51,38,27,2],
[51,38,27,4],
[51,38,27,9],
[51,38,27,11],
[51,38,27,12],
[51,38,27,13],
[51,38,27,15],
[51,38,27,16],
[51,38,27,17],
[51,38,27,18],
[51,38,27,19],
[51,38,27,23],
[51,38,27,24],
[51,38,27,26],
[51,38,29,0],
[51,38,29,1],
[51,38,29,2],
[51,38,29,4],
[51,38,29,9],
[51,38,29,13],
[51,38,29,14],
[51,38,29,15],
[51,38,29,16],
[51,38,29,17],
[51,38,29,18],
[51,38,29,19],
[51,38,29,20],
[51,38,29,22],
[51,38,29,23],
[51,38,29,24],
[51,38,30,0],
[51,38,30,1],
[51,38,30,2],
[51,38,30,4],
[51,38,30,10],
[51,38,30,11],
[51,38,30,12],
[51,38,30,13],
[51,38,30,14],
[51,38,30,15],
[51,38,30,16],
[51,38,30,17],
[51,38,30,18],
[51,38,30,19],
[51,38,30,20],
[51,38,30,22],
[51,38,30,24],
[51,38,31,0],
[51,38,31,1],
[51,38,31,2],
[51,38,31,4],
[51,38,31,9],
[51,38,31,10],
[51,38,31,11],
[51,38,31,12],
[51,38,31,13],
[51,38,31,14],
[51,38,31,15],
[51,38,31,16],
[51,38,31,17],
[51,38,31,18],
[51,38,31,19],
[51,38,31,20],
[51,38,31,22],
[51,38,31,24],
[51,38,32,0],
[51,38,32,1],
[51,38,32,2],
[51,38,32,4],
[51,38,32,9],
[51,38,32,10],
[51,38,32,13],
[51,38,32,14],
[51,38,32,15],
[51,38,32,16],
[51,38,32,17],
[51,38,32,18],
[51,38,32,19],
[51,38,32,20],
[51,38,32,22],
[51,38,33,0],
[51,38,33,1],
[51,38,33,2],
[51,38,33,9],
[51,38,33,10],
[51,38,33,13],
[51,38,33,14],
[51,38,33,15],
[51,38,33,16],
[51,38,33,17],
[51,38,33,18],
[51,38,33,19],
[51,38,33,20],
[51,38,35,0],
[51,38,35,1],
[51,38,35,2],
[51,38,35,4],
[51,38,35,10],
[51,38,35,11],
[51,38,35,12],
[51,38,35,13],
[51,38,35,14],
[51,38,35,16],
[51,38,35,17],
[51,38,35,19],
[51,38,36,0],
[51,38,36,1],
[51,38,36,2],
[51,38,36,4],
[51,38,36,9],
[51,38,36,10],
[51,38,36,11],
[51,38,36,12],
[51,38,36,15],
[51,38,36,16],
[51,38,36,17],
[51,38,36,18],
[51,38,37,0],
[51,38,37,1],
[51,38,37,2],
[51,38,37,9],
[51,38,37,10],
[51,38,37,11],
[51,38,37,12],
[51,38,37,13],
[51,38,37,14],
[51,38,37,15],
[51,38,37,16],
[51,38,37,17],
[51,40,4,0],
[51,40,4,1],
[51,40,4,2],
[51,40,7,0],
[51,40,7,1],
[51,40,7,2],
[51,40,7,4],
[51,40,10,0],
[51,40,10,1],
[51,40,10,2],
[51,40,10,4],
[51,40,10,7],
[51,40,11,0],
[51,40,11,1],
[51,40,11,2],
[51,40,11,4],
[51,40,11,7],
[51,40,11,10],
[51,40,12,0],
[51,40,12,1],
[51,40,12,2],
[51,40,12,4],
[51,40,12,7],
[51,40,12,10],
[51,40,13,0],
[51,40,13,1],
[51,40,13,2],
[51,40,13,4],
[51,40,13,7],
[51,40,13,10],
[51,40,13,11],
[51,40,13,12],
[51,40,14,0],
[51,40,14,1],
[51,40,14,2],
[51,40,14,4],
[51,40,14,7],
[51,40,14,10],
[51,40,14,11],
[51,40,14,12],
[51,40,16,0],
[51,40,16,1],
[51,40,16,2],
[51,40,16,4],
[51,40,16,7],
[51,40,16,10],
[51,40,16,11],
[51,40,16,12],
[51,40,16,13],
[51,40,16,14],
[51,40,17,0],
[51,40,17,1],
[51,40,17,2],
[51,40,17,4],
[51,40,17,7],
[51,40,17,10],
[51,40,17,11],
[51,40,17,12],
[51,40,17,13],
[51,40,17,14],
[51,40,17,16],
[51,40,19,0],
[51,40,19,1],
[51,40,19,2],
[51,40,19,4],
[51,40,19,7],
[51,40,19,10],
[51,40,19,11],
[51,40,19,12],
[51,40,19,16],
[51,40,19,17],
[51,40,20,0],
[51,40,20,1],
[51,40,20,2],
[51,40,20,4],
[51,40,20,7],
[51,40,20,11],
[51,40,20,12],
[51,40,20,13],
[51,40,20,14],
[51,40,20,16],
[51,40,20,17],
[51,40,20,19],
[51,40,22,0],
[51,40,22,1],
[51,40,22,2],
[51,40,22,4],
[51,40,22,7],
[51,40,22,11],
[51,40,22,12],
[51,40,22,13],
[51,40,22,14],
[51,40,22,16],
[51,40,22,17],
[51,40,22,19],
[51,40,23,0],
[51,40,23,1],
[51,40,23,2],
[51,40,23,4],
[51,40,23,7],
[51,40,23,10],
[51,40,23,11],
[51,40,23,12],
[51,40,23,13],
[51,40,23,14],
[51,40,23,19],
[51,40,23,20],
[51,40,23,22],
[51,40,24,0],
[51,40,24,1],
[51,40,24,2],
[51,40,24,4],
[51,40,24,7],
[51,40,24,10],
[51,40,24,11],
[51,40,24,12],
[51,40,24,13],
[51,40,24,14],
[51,40,24,19],
[51,40,24,20],
[51,40,24,22],
[51,40,24,23],
[51,40,25,0],
[51,40,25,1],
[51,40,25,2],
[51,40,25,4],
[51,40,25,10],
[51,40,25,11],
[51,40,25,12],
[51,40,25,13],
[51,40,25,16],
[51,40,25,17],
[51,40,25,19],
[51,40,25,20],
[51,40,25,22],
[51,40,25,23],
[51,40,25,24],
[51,40,27,0],
[51,40,27,1],
[51,40,27,2],
[51,40,27,4],
[51,40,27,7],
[51,40,27,12],
[51,40,27,13],
[51,40,27,14],
[51,40,27,16],
[51,40,27,17],
[51,40,27,19],
[51,40,27,23],
[51,40,27,24],
[51,40,27,25],
[51,40,28,0],
[51,40,28,1],
[51,40,28,2],
[51,40,28,4],
[51,40,28,10],
[51,40,28,11],
[51,40,28,12],
[51,40,28,13],
[51,40,28,14],
[51,40,28,16],
[51,40,28,17],
[51,40,28,19],
[51,40,28,20],
[51,40,28,22],
[51,40,28,23],
[51,40,28,24],
[51,40,29,0],
[51,40,29,1],
[51,40,29,2],
[51,40,29,4],
[51,40,29,10],
[51,40,29,13],
[51,40,29,14],
[51,40,29,16],
[51,40,29,17],
[51,40,29,19],
[51,40,29,20],
[51,40,29,22],
[51,40,29,23],
[51,40,29,24],
[51,40,30,0],
[51,40,30,1],
[51,40,30,2],
[51,40,30,4],
[51,40,30,7],
[51,40,30,10],
[51,40,30,11],
[51,40,30,12],
[51,40,30,13],
[51,40,30,14],
[51,40,30,16],
[51,40,30,17],
[51,40,30,19],
[51,40,30,20],
[51,40,30,22],
[51,40,31,0],
[51,40,31,1],
[51,40,31,2],
[51,40,31,7],
[51,40,31,10],
[51,40,31,11],
[51,40,31,12],
[51,40,31,13],
[51,40,31,14],
[51,40,31,16],
[51,40,31,17],
[51,40,31,19],
[51,40,31,20],
[51,40,32,4],
[51,40,32,7],
[51,40,32,10],
[51,40,32,13],
[51,40,32,14],
[51,40,32,16],
[51,40,32,17],
[51,40,32,19],
[51,40,32,20],
[51,40,33,0],
[51,40,33,1],
[51,40,33,2],
[51,40,33,4],
[51,40,33,7],
[51,40,33,10],
[51,40,33,13],
[51,40,33,14],
[51,40,33,16],
[51,40,33,17],
[51,40,33,19],
[51,40,34,0],
[51,40,34,1],
[51,40,34,2],
[51,40,34,4],
[51,40,34,10],
[51,40,34,11],
[51,40,34,12],
[51,40,34,13],
[51,40,34,14],
[51,40,34,16],
[51,40,34,17],
[51,40,36,0],
[51,40,36,1],
[51,40,36,2],
[51,40,36,4],
[51,40,36,7],
[51,40,36,10],
[51,40,36,11],
[51,40,36,12],
[51,40,37,0],
[51,40,37,1],
[51,40,37,2],
[51,40,37,7],
[51,40,37,10],
[51,40,37,11],
[51,40,37,12],
[51,40,37,13],
[51,40,37,14],
[51,40,38,0],
[51,40,38,1],
[51,40,38,2],
[51,40,38,4],
[51,40,38,10],
[51,40,38,11],
[51,40,38,12],
[51,40,38,13],
[51,41,4,0],
[51,41,4,1],
[51,41,4,2],
[51,41,7,0],
[51,41,7,1],
[51,41,7,2],
[51,41,7,4],
[51,41,10,0],
[51,41,10,1],
[51,41,10,2],
[51,41,10,4],
[51,41,10,7],
[51,41,11,0],
[51,41,11,1],
[51,41,11,2],
[51,41,11,4],
[51,41,11,7],
[51,41,11,10],
[51,41,12,0],
[51,41,12,1],
[51,41,12,2],
[51,41,12,4],
[51,41,12,7],
[51,41,12,10],
[51,41,13,0],
[51,41,13,1],
[51,41,13,2],
[51,41,13,4],
[51,41,13,7],
[51,41,13,10],
[51,41,13,11],
[51,41,13,12],
[51,41,14,0],
[51,41,14,1],
[51,41,14,2],
[51,41,14,4],
[51,41,14,7],
[51,41,14,10],
[51,41,14,11],
[51,41,14,12],
[51,41,16,0],
[51,41,16,1],
[51,41,16,2],
[51,41,16,4],
[51,41,16,7],
[51,41,16,10],
[51,41,16,11],
[51,41,16,12],
[51,41,16,13],
[51,41,16,14],
[51,41,17,0],
[51,41,17,1],
[51,41,17,2],
[51,41,17,4],
[51,41,17,7],
[51,41,17,10],
[51,41,17,11],
[51,41,17,12],
[51,41,17,13],
[51,41,17,14],
[51,41,17,16],
[51,41,19,0],
[51,41,19,1],
[51,41,19,2],
[51,41,19,4],
[51,41,19,7],
[51,41,19,10],
[51,41,19,11],
[51,41,19,12],
[51,41,19,16],
[51,41,19,17],
[51,41,20,0],
[51,41,20,1],
[51,41,20,2],
[51,41,20,4],
[51,41,20,7],
[51,41,20,11],
[51,41,20,12],
[51,41,20,13],
[51,41,20,14],
[51,41,20,16],
[51,41,20,17],
[51,41,20,19],
[51,41,22,0],
[51,41,22,1],
[51,41,22,2],
[51,41,22,4],
[51,41,22,7],
[51,41,22,11],
[51,41,22,12],
[51,41,22,13],
[51,41,22,14],
[51,41,22,16],
[51,41,22,17],
[51,41,22,19],
[51,41,23,0],
[51,41,23,1],
[51,41,23,2],
[51,41,23,4],
[51,41,23,7],
[51,41,23,10],
[51,41,23,11],
[51,41,23,12],
[51,41,23,13],
[51,41,23,14],
[51,41,23,19],
[51,41,23,20],
[51,41,23,22],
[51,41,24,0],
[51,41,24,1],
[51,41,24,2],
[51,41,24,4],
[51,41,24,7],
[51,41,24,10],
[51,41,24,11],
[51,41,24,12],
[51,41,24,13],
[51,41,24,14],
[51,41,24,19],
[51,41,24,20],
[51,41,24,22],
[51,41,24,23],
[51,41,25,0],
[51,41,25,1],
[51,41,25,2],
[51,41,25,4],
[51,41,25,10],
[51,41,25,11],
[51,41,25,12],
[51,41,25,13],
[51,41,25,16],
[51,41,25,17],
[51,41,25,19],
[51,41,25,20],
[51,41,25,22],
[51,41,25,23],
[51,41,25,24],
[51,41,27,0],
[51,41,27,1],
[51,41,27,2],
[51,41,27,4],
[51,41,27,7],
[51,41,27,12],
[51,41,27,13],
[51,41,27,14],
[51,41,27,16],
[51,41,27,17],
[51,41,27,19],
[51,41,27,23],
[51,41,27,24],
[51,41,27,25],
[51,41,28,0],
[51,41,28,1],
[51,41,28,2],
[51,41,28,4],
[51,41,28,10],
[51,41,28,11],
[51,41,28,12],
[51,41,28,13],
[51,41,28,14],
[51,41,28,16],
[51,41,28,17],
[51,41,28,19],
[51,41,28,20],
[51,41,28,22],
[51,41,28,23],
[51,41,28,24],
[51,41,29,0],
[51,41,29,1],
[51,41,29,2],
[51,41,29,4],
[51,41,29,10],
[51,41,29,13],
[51,41,29,14],
[51,41,29,16],
[51,41,29,17],
[51,41,29,19],
[51,41,29,20],
[51,41,29,22],
[51,41,29,23],
[51,41,29,24],
[51,41,30,0],
[51,41,30,1],
[51,41,30,2],
[51,41,30,4],
[51,41,30,7],
[51,41,30,10],
[51,41,30,11],
[51,41,30,12],
[51,41,30,13],
[51,41,30,14],
[51,41,30,16],
[51,41,30,17],
[51,41,30,19],
[51,41,30,20],
[51,41,30,22],
[51,41,31,0],
[51,41,31,1],
[51,41,31,2],
[51,41,31,7],
[51,41,31,10],
[51,41,31,11],
[51,41,31,12],
[51,41,31,13],
[51,41,31,14],
[51,41,31,16],
[51,41,31,17],
[51,41,31,19],
[51,41,31,20],
[51,41,32,4],
[51,41,32,7],
[51,41,32,10],
[51,41,32,13],
[51,41,32,14],
[51,41,32,16],
[51,41,32,17],
[51,41,32,19],
[51,41,32,20],
[51,41,33,0],
[51,41,33,1],
[51,41,33,2],
[51,41,33,4],
[51,41,33,7],
[51,41,33,10],
[51,41,33,13],
[51,41,33,14],
[51,41,33,16],
[51,41,33,17],
[51,41,33,19],
[51,41,34,0],
[51,41,34,1],
[51,41,34,2],
[51,41,34,4],
[51,41,34,10],
[51,41,34,11],
[51,41,34,12],
[51,41,34,13],
[51,41,34,14],
[51,41,34,16],
[51,41,34,17],
[51,41,36,0],
[51,41,36,1],
[51,41,36,2],
[51,41,36,4],
[51,41,36,7],
[51,41,36,10],
[51,41,36,11],
[51,41,36,12],
[51,41,37,0],
[51,41,37,1],
[51,41,37,2],
[51,41,37,7],
[51,41,37,10],
[51,41,37,11],
[51,41,37,12],
[51,41,37,13],
[51,41,37,14],
[51,41,38,0],
[51,41,38,1],
[51,41,38,2],
[51,41,38,4],
[51,41,38,10],
[51,41,38,11],
[51,41,38,12],
[51,41,38,13],
[51,42,4,0],
[51,42,4,1],
[51,42,4,2],
[51,42,7,0],
[51,42,7,1],
[51,42,7,2],
[51,42,7,4],
[51,42,9,0],
[51,42,9,1],
[51,42,9,2],
[51,42,9,4],
[51,42,9,7],
[51,42,11,0],
[51,42,11,1],
[51,42,11,2],
[51,42,11,4],
[51,42,11,7],
[51,42,11,9],
[51,42,12,0],
[51,42,12,1],
[51,42,12,2],
[51,42,12,4],
[51,42,12,7],
[51,42,12,9],
[51,42,13,0],
[51,42,13,1],
[51,42,13,2],
[51,42,13,4],
[51,42,13,7],
[51,42,13,9],
[51,42,13,11],
[51,42,13,12],
[51,42,14,0],
[51,42,14,1],
[51,42,14,2],
[51,42,14,4],
[51,42,14,7],
[51,42,14,9],
[51,42,14,11],
[51,42,14,12],
[51,42,15,0],
[51,42,15,1],
[51,42,15,2],
[51,42,15,4],
[51,42,15,7],
[51,42,15,11],
[51,42,15,12],
[51,42,15,13],
[51,42,15,14],
[51,42,16,0],
[51,42,16,1],
[51,42,16,2],
[51,42,16,4],
[51,42,16,7],
[51,42,16,9],
[51,42,16,11],
[51,42,16,12],
[51,42,16,13],
[51,42,16,14],
[51,42,16,15],
[51,42,17,0],
[51,42,17,1],
[51,42,17,2],
[51,42,17,4],
[51,42,17,7],
[51,42,17,9],
[51,42,17,11],
[51,42,17,12],
[51,42,17,13],
[51,42,17,14],
[51,42,17,15],
[51,42,17,16],
[51,42,18,0],
[51,42,18,1],
[51,42,18,2],
[51,42,18,4],
[51,42,18,7],
[51,42,18,11],
[51,42,18,12],
[51,42,18,13],
[51,42,18,14],
[51,42,18,16],
[51,42,18,17],
[51,42,19,0],
[51,42,19,1],
[51,42,19,2],
[51,42,19,4],
[51,42,19,7],
[51,42,19,9],
[51,42,19,11],
[51,42,19,12],
[51,42,19,15],
[51,42,19,16],
[51,42,19,17],
[51,42,19,18],
[51,42,23,0],
[51,42,23,1],
[51,42,23,2],
[51,42,23,4],
[51,42,23,7],
[51,42,23,9],
[51,42,23,11],
[51,42,23,12],
[51,42,23,13],
[51,42,23,14],
[51,42,23,16],
[51,42,23,17],
[51,42,23,18],
[51,42,23,19],
[51,42,24,0],
[51,42,24,1],
[51,42,24,2],
[51,42,24,4],
[51,42,24,7],
[51,42,24,9],
[51,42,24,11],
[51,42,24,12],
[51,42,24,13],
[51,42,24,14],
[51,42,24,17],
[51,42,24,18],
[51,42,24,19],
[51,42,24,23],
[51,42,25,0],
[51,42,25,1],
[51,42,25,2],
[51,42,25,4],
[51,42,25,9],
[51,42,25,11],
[51,42,25,14],
[51,42,25,15],
[51,42,25,16],
[51,42,25,17],
[51,42,25,18],
[51,42,25,19],
[51,42,25,23],
[51,42,25,24],
[51,42,26,0],
[51,42,26,1],
[51,42,26,2],
[51,42,26,4],
[51,42,26,7],
[51,42,26,12],
[51,42,26,13],
[51,42,26,14],
[51,42,26,16],
[51,42,26,17],
[51,42,26,19],
[51,42,26,23],
[51,42,26,24],
[51,42,26,25],
[51,42,28,0],
[51,42,28,1],
[51,42,28,2],
[51,42,28,4],
[51,42,28,9],
[51,42,28,11],
[51,42,28,12],
[51,42,28,13],
[51,42,28,14],
[51,42,28,15],
[51,42,28,16],
[51,42,28,17],
[51,42,28,18],
[51,42,28,19],
[51,42,28,23],
[51,42,28,24],
[51,42,29,0],
[51,42,29,1],
[51,42,29,2],
[51,42,29,4],
[51,42,29,7],
[51,42,29,9],
[51,42,29,13],
[51,42,29,14],
[51,42,29,15],
[51,42,29,16],
[51,42,29,17],
[51,42,29,18],
[51,42,29,19],
[51,42,30,0],
[51,42,30,1],
[51,42,30,2],
[51,42,30,7],
[51,42,30,9],
[51,42,30,11],
[51,42,30,12],
[51,42,30,13],
[51,42,30,14],
[51,42,30,15],
[51,42,30,16],
[51,42,30,17],
[51,42,30,18],
[51,42,30,19],
[51,42,31,4],
[51,42,31,7],
[51,42,31,9],
[51,42,31,11],
[51,42,31,12],
[51,42,31,13],
[51,42,31,14],
[51,42,31,15],
[51,42,31,16],
[51,42,31,17],
[51,42,31,18],
[51,42,31,19],
[51,42,32,0],
[51,42,32,1],
[51,42,32,2],
[51,42,32,4],
[51,42,32,7],
[51,42,32,9],
[51,42,32,13],
[51,42,32,14],
[51,42,32,15],
[51,42,32,16],
[51,42,32,17],
[51,42,32,18],
[51,42,32,19],
[51,42,33,0],
[51,42,33,1],
[51,42,33,2],
[51,42,33,4],
[51,42,33,7],
[51,42,33,9],
[51,42,33,13],
[51,42,33,14],
[51,42,33,15],
[51,42,33,16],
[51,42,33,17],
[51,42,33,18],
[51,42,34,0],
[51,42,34,1],
[51,42,34,2],
[51,42,34,4],
[51,42,34,9],
[51,42,34,11],
[51,42,34,12],
[51,42,34,13],
[51,42,34,14],
[51,42,34,15],
[51,42,34,16],
[51,42,34,17],
[51,42,35,0],
[51,42,35,1],
[51,42,35,2],
[51,42,35,4],
[51,42,35,7],
[51,42,35,11],
[51,42,35,12],
[51,42,35,13],
[51,42,35,14],
[51,42,36,0],
[51,42,36,1],
[51,42,36,2],
[51,42,36,4],
[51,42,36,7],
[51,42,36,9],
[51,42,36,11],
[51,42,36,12],
[51,42,37,0],
[51,42,37,1],
[51,42,37,2],
[51,42,37,7],
[51,42,37,9],
[51,42,37,11],
[51,42,37,12],
[51,42,37,13],
[51,42,38,0],
[51,42,38,1],
[51,42,38,2],
[51,42,38,4],
[51,42,38,9],
[51,42,38,11],
[51,42,40,0],
[51,42,40,1],
[51,42,40,2],
[51,42,40,4],
[51,42,40,7],
[51,42,41,0],
[51,42,41,1],
[51,42,41,2],
[51,42,41,4],
[51,42,41,7],
[51,43,4,0],
[51,43,4,1],
[51,43,4,2],
[51,43,9,0],
[51,43,9,1],
[51,43,9,2],
[51,43,9,4],
[51,43,10,0],
[51,43,10,1],
[51,43,10,2],
[51,43,10,4],
[51,43,10,9],
[51,43,11,0],
[51,43,11,1],
[51,43,11,2],
[51,43,11,4],
[51,43,11,9],
[51,43,11,10],
[51,43,12,0],
[51,43,12,1],
[51,43,12,2],
[51,43,12,4],
[51,43,12,9],
[51,43,12,10],
[51,43,13,0],
[51,43,13,1],
[51,43,13,2],
[51,43,13,4],
[51,43,13,9],
[51,43,13,10],
[51,43,13,11],
[51,43,13,12],
[51,43,14,0],
[51,43,14,1],
[51,43,14,2],
[51,43,14,4],
[51,43,14,9],
[51,43,14,10],
[51,43,14,11],
[51,43,14,12],
[51,43,15,0],
[51,43,15,1],
[51,43,15,2],
[51,43,15,4],
[51,43,15,10],
[51,43,15,11],
[51,43,15,12],
[51,43,15,13],
[51,43,15,14],
[51,43,16,0],
[51,43,16,1],
[51,43,16,2],
[51,43,16,4],
[51,43,16,9],
[51,43,16,10],
[51,43,16,11],
[51,43,16,12],
[51,43,16,13],
[51,43,16,14],
[51,43,16,15],
[51,43,17,0],
[51,43,17,1],
[51,43,17,2],
[51,43,17,4],
[51,43,17,9],
[51,43,17,10],
[51,43,17,11],
[51,43,17,12],
[51,43,17,13],
[51,43,17,14],
[51,43,17,15],
[51,43,17,16],
[51,43,18,0],
[51,43,18,1],
[51,43,18,2],
[51,43,18,4],
[51,43,18,10],
[51,43,18,11],
[51,43,18,12],
[51,43,18,13],
[51,43,18,14],
[51,43,18,16],
[51,43,18,17],
[51,43,19,0],
[51,43,19,1],
[51,43,19,2],
[51,43,19,4],
[51,43,19,9],
[51,43,19,10],
[51,43,19,11],
[51,43,19,12],
[51,43,19,15],
[51,43,19,16],
[51,43,19,17],
[51,43,19,18],
[51,43,20,0],
[51,43,20,1],
[51,43,20,2],
[51,43,20,4],
[51,43,20,9],
[51,43,20,11],
[51,43,20,12],
[51,43,20,13],
[51,43,20,14],
[51,43,20,15],
[51,43,20,16],
[51,43,20,17],
[51,43,20,19],
[51,43,22,0],
[51,43,22,1],
[51,43,22,2],
[51,43,22,4],
[51,43,22,9],
[51,43,22,11],
[51,43,22,12],
[51,43,22,13],
[51,43,22,14],
[51,43,22,16],
[51,43,22,17],
[51,43,22,18],
[51,43,22,19],
[51,43,23,0],
[51,43,23,1],
[51,43,23,2],
[51,43,23,4],
[51,43,23,9],
[51,43,23,10],
[51,43,23,11],
[51,43,23,12],
[51,43,23,13],
[51,43,23,15],
[51,43,23,16],
[51,43,23,17],
[51,43,23,18],
[51,43,23,19],
[51,43,23,20],
[51,43,23,22],
[51,43,24,0],
[51,43,24,1],
[51,43,24,2],
[51,43,24,4],
[51,43,24,9],
[51,43,24,10],
[51,43,24,11],
[51,43,24,12],
[51,43,24,13],
[51,43,24,15],
[51,43,24,17],
[51,43,24,18],
[51,43,24,19],
[51,43,24,20],
[51,43,24,22],
[51,43,24,23],
[51,43,26,0],
[51,43,26,1],
[51,43,26,2],
[51,43,26,4],
[51,43,26,10],
[51,43,26,11],
[51,43,26,12],
[51,43,26,13],
[51,43,26,14],
[51,43,26,16],
[51,43,26,17],
[51,43,26,19],
[51,43,26,20],
[51,43,26,22],
[51,43,26,23],
[51,43,26,24],
[51,43,27,0],
[51,43,27,1],
[51,43,27,2],
[51,43,27,4],
[51,43,27,9],
[51,43,27,11],
[51,43,27,12],
[51,43,27,13],
[51,43,27,14],
[51,43,27,15],
[51,43,27,16],
[51,43,27,17],
[51,43,27,18],
[51,43,27,19],
[51,43,27,23],
[51,43,27,24],
[51,43,29,0],
[51,43,29,1],
[51,43,29,2],
[51,43,29,9],
[51,43,29,10],
[51,43,29,13],
[51,43,29,14],
[51,43,29,15],
[51,43,29,16],
[51,43,29,17],
[51,43,29,18],
[51,43,29,19],
[51,43,29,20],
[51,43,30,4],
[51,43,30,9],
[51,43,30,10],
[51,43,30,11],
[51,43,30,12],
[51,43,30,13],
[51,43,30,14],
[51,43,30,15],
[51,43,30,16],
[51,43,30,17],
[51,43,30,18],
[51,43,30,19],
[51,43,30,20],
[51,43,31,0],
[51,43,31,1],
[51,43,31,2],
[51,43,31,4],
[51,43,31,9],
[51,43,31,10],
[51,43,31,11],
[51,43,31,12],
[51,43,31,13],
[51,43,31,14],
[51,43,31,15],
[51,43,31,16],
[51,43,31,17],
[51,43,31,18],
[51,43,31,19],
[51,43,32,0],
[51,43,32,1],
[51,43,32,2],
[51,43,32,4],
[51,43,32,9],
[51,43,32,10],
[51,43,32,13],
[51,43,32,14],
[51,43,32,15],
[51,43,32,16],
[51,43,32,17],
[51,43,32,18],
[51,43,33,0],
[51,43,33,1],
[51,43,33,2],
[51,43,33,4],
[51,43,33,9],
[51,43,33,10],
[51,43,33,13],
[51,43,33,14],
[51,43,33,15],
[51,43,33,16],
[51,43,33,17],
[51,43,35,0],
[51,43,35,1],
[51,43,35,2],
[51,43,35,4],
[51,43,35,10],
[51,43,35,11],
[51,43,35,12],
[51,43,35,13],
[51,43,35,14],
[51,43,36,0],
[51,43,36,1],
[51,43,36,2],
[51,43,36,4],
[51,43,36,9],
[51,43,36,10],
[51,43,36,11],
[51,43,36,12],
[51,43,37,0],
[51,43,37,1],
[51,43,37,2],
[51,43,37,9],
[51,43,37,10],
[51,43,37,11],
[51,43,40,0],
[51,43,40,1],
[51,43,40,2],
[51,43,40,4],
[51,43,41,0],
[51,43,41,1],
[51,43,41,2],
[51,43,41,4],
[51,43,42,0],
[51,43,42,1],
[51,43,42,2],
[51,43,42,4],
[51,44,4,0],
[51,44,4,1],
[51,44,4,2],
[51,44,7,0],
[51,44,7,1],
[51,44,7,2],
[51,44,7,4],
[51,44,9,0],
[51,44,9,1],
[51,44,9,2],
[51,44,9,4],
[51,44,9,7],
[51,44,10,0],
[51,44,10,1],
[51,44,10,2],
[51,44,10,4],
[51,44,10,7],
[51,44,10,9],
[51,44,11,0],
[51,44,11,1],
[51,44,11,2],
[51,44,11,4],
[51,44,11,7],
[51,44,11,9],
[51,44,11,10],
[51,44,12,0],
[51,44,12,1],
[51,44,12,2],
[51,44,12,4],
[51,44,12,7],
[51,44,12,9],
[51,44,12,10],
[51,44,13,0],
[51,44,13,1],
[51,44,13,2],
[51,44,13,4],
[51,44,13,7],
[51,44,13,9],
[51,44,13,10],
[51,44,13,11],
[51,44,13,12],
[51,44,14,0],
[51,44,14,1],
[51,44,14,2],
[51,44,14,4],
[51,44,14,7],
[51,44,14,9],
[51,44,14,10],
[51,44,14,11],
[51,44,14,12],
[51,44,15,0],
[51,44,15,1],
[51,44,15,2],
[51,44,15,4],
[51,44,15,7],
[51,44,15,10],
[51,44,15,11],
[51,44,15,12],
[51,44,15,13],
[51,44,15,14],
[51,44,16,0],
[51,44,16,1],
[51,44,16,2],
[51,44,16,4],
[51,44,16,7],
[51,44,16,9],
[51,44,16,10],
[51,44,16,11],
[51,44,16,12],
[51,44,16,13],
[51,44,16,14],
[51,44,16,15],
[51,44,18,0],
[51,44,18,1],
[51,44,18,2],
[51,44,18,4],
[51,44,18,7],
[51,44,18,10],
[51,44,18,11],
[51,44,18,12],
[51,44,18,13],
[51,44,18,14],
[51,44,18,16],
[51,44,19,0],
[51,44,19,1],
[51,44,19,2],
[51,44,19,4],
[51,44,19,7],
[51,44,19,9],
[51,44,19,10],
[51,44,19,11],
[51,44,19,12],
[51,44,19,15],
[51,44,19,16],
[51,44,20,0],
[51,44,20,1],
[51,44,20,2],
[51,44,20,4],
[51,44,20,7],
[51,44,20,9],
[51,44,20,11],
[51,44,20,12],
[51,44,20,13],
[51,44,20,14],
[51,44,20,15],
[51,44,20,18],
[51,44,20,19],
[51,44,22,0],
[51,44,22,1],
[51,44,22,2],
[51,44,22,4],
[51,44,22,7],
[51,44,22,9],
[51,44,22,11],
[51,44,22,12],
[51,44,22,13],
[51,44,22,15],
[51,44,22,16],
[51,44,22,18],
[51,44,22,19],
[51,44,23,0],
[51,44,23,1],
[51,44,23,2],
[51,44,23,4],
[51,44,23,7],
[51,44,23,9],
[51,44,23,10],
[51,44,23,11],
[51,44,23,14],
[51,44,23,15],
[51,44,23,16],
[51,44,23,18],
[51,44,23,19],
[51,44,23,20],
[51,44,23,22],
[51,44,24,0],
[51,44,24,1],
[51,44,24,2],
[51,44,24,4],
[51,44,24,7],
[51,44,24,9],
[51,44,24,10],
[51,44,24,11],
[51,44,24,14],
[51,44,24,15],
[51,44,24,18],
[51,44,24,19],
[51,44,24,20],
[51,44,24,22],
[51,44,24,23],
[51,44,25,0],
[51,44,25,1],
[51,44,25,2],
[51,44,25,4],
[51,44,25,10],
[51,44,25,11],
[51,44,25,12],
[51,44,25,13],
[51,44,25,14],
[51,44,25,15],
[51,44,25,16],
[51,44,25,18],
[51,44,25,19],
[51,44,25,20],
[51,44,25,22],
[51,44,25,23],
[51,44,25,24],
[51,44,26,0],
[51,44,26,1],
[51,44,26,2],
[51,44,26,4],
[51,44,26,10],
[51,44,26,11],
[51,44,26,12],
[51,44,26,13],
[51,44,26,14],
[51,44,26,16],
[51,44,26,19],
[51,44,26,20],
[51,44,26,22],
[51,44,26,23],
[51,44,26,24],
[51,44,27,0],
[51,44,27,1],
[51,44,27,2],
[51,44,27,4],
[51,44,27,7],
[51,44,27,9],
[51,44,27,11],
[51,44,27,12],
[51,44,27,13],
[51,44,27,14],
[51,44,27,15],
[51,44,27,16],
[51,44,27,18],
[51,44,27,19],
[51,44,28,0],
[51,44,28,1],
[51,44,28,2],
[51,44,28,9],
[51,44,28,10],
[51,44,28,11],
[51,44,28,12],
[51,44,28,13],
[51,44,28,14],
[51,44,28,15],
[51,44,28,16],
[51,44,28,18],
[51,44,28,19],
[51,44,28,20],
[51,44,29,4],
[51,44,29,7],
[51,44,29,9],
[51,44,29,10],
[51,44,29,13],
[51,44,29,14],
[51,44,29,15],
[51,44,29,16],
[51,44,29,18],
[51,44,29,19],
[51,44,29,20],
[51,44,30,0],
[51,44,30,1],
[51,44,30,2],
[51,44,30,4],
[51,44,30,7],
[51,44,30,9],
[51,44,30,10],
[51,44,30,11],
[51,44,30,12],
[51,44,30,13],
[51,44,30,14],
[51,44,30,15],
[51,44,30,16],
[51,44,30,18],
[51,44,30,19],
[51,44,31,0],
[51,44,31,1],
[51,44,31,2],
[51,44,31,4],
[51,44,31,7],
[51,44,31,9],
[51,44,31,10],
[51,44,31,11],
[51,44,31,12],
[51,44,31,13],
[51,44,31,14],
[51,44,31,15],
[51,44,31,16],
[51,44,31,18],
[51,44,32,0],
[51,44,32,1],
[51,44,32,2],
[51,44,32,4],
[51,44,32,7],
[51,44,32,9],
[51,44,32,10],
[51,44,32,13],
[51,44,32,14],
[51,44,32,15],
[51,44,32,16],
[51,44,33,0],
[51,44,33,1],
[51,44,33,2],
[51,44,33,4],
[51,44,33,7],
[51,44,33,9],
[51,44,33,10],
[51,44,33,13],
[51,44,33,14],
[51,44,33,15],
[51,44,34,0],
[51,44,34,1],
[51,44,34,2],
[51,44,34,4],
[51,44,34,9],
[51,44,34,10],
[51,44,34,11],
[51,44,34,12],
[51,44,34,13],
[51,44,34,14],
[51,44,35,0],
[51,44,35,1],
[51,44,35,2],
[51,44,35,4],
[51,44,35,7],
[51,44,35,10],
[51,44,35,11],
[51,44,35,12],
[51,44,35,13],
[51,44,36,0],
[51,44,36,1],
[51,44,36,2],
[51,44,36,4],
[51,44,36,7],
[51,44,36,9],
[51,44,36,10],
[51,44,36,11],
[51,44,37,0],
[51,44,37,1],
[51,44,37,2],
[51,44,37,7],
[51,44,37,9],
[51,44,38,0],
[51,44,38,1],
[51,44,38,2],
[51,44,38,4],
[51,44,40,0],
[51,44,40,1],
[51,44,40,2],
[51,44,40,4],
[51,44,41,0],
[51,44,41,1],
[51,44,41,2],
[51,44,41,4],
[51,44,42,0],
[51,44,42,1],
[51,44,42,2],
[51,45,4,0],
[51,45,4,1],
[51,45,4,2],
[51,45,7,0],
[51,45,7,1],
[51,45,7,2],
[51,45,7,4],
[51,45,10,0],
[51,45,10,1],
[51,45,10,2],
[51,45,10,4],
[51,45,10,7],
[51,45,11,0],
[51,45,11,1],
[51,45,11,2],
[51,45,11,4],
[51,45,11,7],
[51,45,11,10],
[51,45,12,0],
[51,45,12,1],
[51,45,12,2],
[51,45,12,4],
[51,45,12,7],
[51,45,12,10],
[51,45,13,0],
[51,45,13,1],
[51,45,13,2],
[51,45,13,4],
[51,45,13,7],
[51,45,13,10],
[51,45,13,11],
[51,45,13,12],
[51,45,14,0],
[51,45,14,1],
[51,45,14,2],
[51,45,14,4],
[51,45,14,7],
[51,45,14,10],
[51,45,14,11],
[51,45,14,12],
[51,45,16,0],
[51,45,16,1],
[51,45,16,2],
[51,45,16,4],
[51,45,16,7],
[51,45,16,10],
[51,45,16,11],
[51,45,16,12],
[51,45,16,13],
[51,45,16,14],
[51,45,17,0],
[51,45,17,1],
[51,45,17,2],
[51,45,17,4],
[51,45,17,7],
[51,45,17,10],
[51,45,17,11],
[51,45,17,12],
[51,45,17,13],
[51,45,17,14],
[51,45,17,16],
[51,45,19,0],
[51,45,19,1],
[51,45,19,2],
[51,45,19,4],
[51,45,19,7],
[51,45,19,10],
[51,45,19,11],
[51,45,19,12],
[51,45,20,0],
[51,45,20,1],
[51,45,20,2],
[51,45,20,4],
[51,45,20,7],
[51,45,20,11],
[51,45,20,12],
[51,45,20,13],
[51,45,20,14],
[51,45,20,16],
[51,45,20,17],
[51,45,20,19],
[51,45,22,0],
[51,45,22,1],
[51,45,22,2],
[51,45,22,4],
[51,45,22,7],
[51,45,22,11],
[51,45,22,14],
[51,45,22,16],
[51,45,22,17],
[51,45,22,19],
[51,45,23,0],
[51,45,23,1],
[51,45,23,2],
[51,45,23,4],
[51,45,23,7],
[51,45,23,12],
[51,45,23,13],
[51,45,23,14],
[51,45,23,16],
[51,45,23,17],
[51,45,23,19],
[51,45,23,20],
[51,45,23,22],
[51,45,24,0],
[51,45,24,1],
[51,45,24,2],
[51,45,24,4],
[51,45,24,7],
[51,45,24,12],
[51,45,24,13],
[51,45,24,14],
[51,45,24,17],
[51,45,24,19],
[51,45,24,20],
[51,45,24,22],
[51,45,24,23],
[51,45,25,0],
[51,45,25,1],
[51,45,25,2],
[51,45,25,4],
[51,45,25,10],
[51,45,25,11],
[51,45,25,12],
[51,45,25,13],
[51,45,25,14],
[51,45,25,16],
[51,45,25,17],
[51,45,25,19],
[51,45,25,20],
[51,45,25,22],
[51,45,25,23],
[51,45,25,24],
[51,45,27,0],
[51,45,27,1],
[51,45,27,2],
[51,45,27,7],
[51,45,27,11],
[51,45,27,12],
[51,45,27,13],
[51,45,27,14],
[51,45,27,16],
[51,45,27,17],
[51,45,27,19],
[51,45,28,4],
[51,45,28,10],
[51,45,28,11],
[51,45,28,12],
[51,45,28,13],
[51,45,28,14],
[51,45,28,16],
[51,45,28,17],
[51,45,28,19],
[51,45,28,20],
[51,45,29,0],
[51,45,29,1],
[51,45,29,2],
[51,45,29,4],
[51,45,29,7],
[51,45,29,10],
[51,45,29,13],
[51,45,29,14],
[51,45,29,16],
[51,45,29,17],
[51,45,29,19],
[51,45,30,0],
[51,45,30,1],
[51,45,30,2],
[51,45,30,4],
[51,45,30,7],
[51,45,30,10],
[51,45,30,11],
[51,45,30,12],
[51,45,30,13],
[51,45,30,14],
[51,45,30,16],
[51,45,30,17],
[51,45,31,0],
[51,45,31,1],
[51,45,31,2],
[51,45,31,4],
[51,45,31,7],
[51,45,31,10],
[51,45,31,11],
[51,45,31,12],
[51,45,31,13],
[51,45,31,14],
[51,45,31,16],
[51,45,31,17],
[51,45,32,0],
[51,45,32,1],
[51,45,32,2],
[51,45,32,4],
[51,45,32,7],
[51,45,32,10],
[51,45,32,13],
[51,45,32,14],
[51,45,33,0],
[51,45,33,1],
[51,45,33,2],
[51,45,33,4],
[51,45,33,7],
[51,45,33,10],
[51,45,33,13],
[51,45,33,14],
[51,45,34,0],
[51,45,34,1],
[51,45,34,2],
[51,45,34,4],
[51,45,34,10],
[51,45,34,11],
[51,45,34,12],
[51,45,34,13],
[51,45,36,0],
[51,45,36,1],
[51,45,36,2],
[51,45,36,4],
[51,45,36,7],
[51,45,37,0],
[51,45,37,1],
[51,45,37,2],
[51,45,37,7],
[51,45,38,0],
[51,45,38,1],
[51,45,38,2],
[51,45,38,4],
[51,46,4,0],
[51,46,4,1],
[51,46,4,2],
[51,46,7,0],
[51,46,7,1],
[51,46,7,2],
[51,46,7,4],
[51,46,10,0],
[51,46,10,1],
[51,46,10,2],
[51,46,10,4],
[51,46,10,7],
[51,46,11,0],
[51,46,11,1],
[51,46,11,2],
[51,46,11,4],
[51,46,11,7],
[51,46,11,10],
[51,46,12,0],
[51,46,12,1],
[51,46,12,2],
[51,46,12,4],
[51,46,12,7],
[51,46,12,10],
[51,46,13,0],
[51,46,13,1],
[51,46,13,2],
[51,46,13,4],
[51,46,13,7],
[51,46,13,10],
[51,46,13,11],
[51,46,13,12],
[51,46,14,0],
[51,46,14,1],
[51,46,14,2],
[51,46,14,4],
[51,46,14,7],
[51,46,14,10],
[51,46,14,11],
[51,46,14,12],
[51,46,16,0],
[51,46,16,1],
[51,46,16,2],
[51,46,16,4],
[51,46,16,7],
[51,46,16,10],
[51,46,16,11],
[51,46,16,12],
[51,46,16,13],
[51,46,16,14],
[51,46,17,0],
[51,46,17,1],
[51,46,17,2],
[51,46,17,4],
[51,46,17,7],
[51,46,17,10],
[51,46,17,11],
[51,46,17,12],
[51,46,17,13],
[51,46,17,14],
[51,46,17,16],
[51,46,19,0],
[51,46,19,1],
[51,46,19,2],
[51,46,19,4],
[51,46,19,7],
[51,46,19,10],
[51,46,19,11],
[51,46,19,12],
[51,46,19,16],
[51,46,19,17],
[51,46,20,0],
[51,46,20,1],
[51,46,20,2],
[51,46,20,4],
[51,46,20,7],
[51,46,20,11],
[51,46,20,12],
[51,46,20,13],
[51,46,20,16],
[51,46,20,17],
[51,46,20,19],
[51,46,22,0],
[51,46,22,1],
[51,46,22,2],
[51,46,22,4],
[51,46,22,7],
[51,46,22,12],
[51,46,22,13],
[51,46,22,14],
[51,46,22,16],
[51,46,22,17],
[51,46,22,19],
[51,46,23,0],
[51,46,23,1],
[51,46,23,2],
[51,46,23,4],
[51,46,23,7],
[51,46,23,10],
[51,46,23,11],
[51,46,23,12],
[51,46,23,13],
[51,46,23,14],
[51,46,23,16],
[51,46,23,17],
[51,46,23,19],
[51,46,23,20],
[51,46,23,22],
[51,46,24,0],
[51,46,24,1],
[51,46,24,2],
[51,46,24,4],
[51,46,24,7],
[51,46,24,10],
[51,46,24,11],
[51,46,24,12],
[51,46,24,13],
[51,46,24,14],
[51,46,24,17],
[51,46,24,19],
[51,46,24,20],
[51,46,24,22],
[51,46,24,23],
[51,46,25,0],
[51,46,25,1],
[51,46,25,2],
[51,46,25,4],
[51,46,25,10],
[51,46,25,11],
[51,46,25,12],
[51,46,25,13],
[51,46,25,14],
[51,46,25,16],
[51,46,25,17],
[51,46,25,19],
[51,46,25,20],
[51,46,25,22],
[51,46,27,4],
[51,46,27,7],
[51,46,27,11],
[51,46,27,12],
[51,46,27,13],
[51,46,27,14],
[51,46,27,16],
[51,46,27,17],
[51,46,27,19],
[51,46,28,0],
[51,46,28,1],
[51,46,28,2],
[51,46,28,4],
[51,46,28,10],
[51,46,28,11],
[51,46,28,12],
[51,46,28,13],
[51,46,28,14],
[51,46,28,16],
[51,46,28,17],
[51,46,28,19],
[51,46,29,0],
[51,46,29,1],
[51,46,29,2],
[51,46,29,4],
[51,46,29,7],
[51,46,29,10],
[51,46,29,13],
[51,46,29,14],
[51,46,29,16],
[51,46,29,17],
[51,46,30,0],
[51,46,30,1],
[51,46,30,2],
[51,46,30,4],
[51,46,30,7],
[51,46,30,10],
[51,46,30,11],
[51,46,30,12],
[51,46,30,13],
[51,46,30,14],
[51,46,30,16],
[51,46,30,17],
[51,46,31,0],
[51,46,31,1],
[51,46,31,2],
[51,46,31,4],
[51,46,31,7],
[51,46,31,10],
[51,46,31,11],
[51,46,31,12],
[51,46,31,13],
[51,46,31,14],
[51,46,32,0],
[51,46,32,1],
[51,46,32,2],
[51,46,32,4],
[51,46,32,7],
[51,46,32,10],
[51,46,32,13],
[51,46,32,14],
[51,46,33,0],
[51,46,33,1],
[51,46,33,2],
[51,46,33,4],
[51,46,33,7],
[51,46,33,10],
[51,46,33,13],
[51,46,34,0],
[51,46,34,1],
[51,46,34,2],
[51,46,34,4],
[51,46,34,10],
[51,46,34,11],
[51,46,36,0],
[51,46,36,1],
[51,46,36,2],
[51,46,36,4],
[51,46,36,7],
[51,46,37,0],
[51,46,37,1],
[51,46,37,2],
[51,46,38,0],
[51,46,38,1],
[51,46,38,2],
[51,46,38,4],
[51,47,4,0],
[51,47,4,1],
[51,47,4,2],
[51,47,7,0],
[51,47,7,1],
[51,47,7,2],
[51,47,7,4],
[51,47,9,0],
[51,47,9,1],
[51,47,9,2],
[51,47,9,4],
[51,47,9,7],
[51,47,10,0],
[51,47,10,1],
[51,47,10,2],
[51,47,10,4],
[51,47,10,7],
[51,47,10,9],
[51,47,11,0],
[51,47,11,1],
[51,47,11,2],
[51,47,11,4],
[51,47,11,7],
[51,47,11,9],
[51,47,11,10],
[51,47,12,0],
[51,47,12,1],
[51,47,12,2],
[51,47,12,4],
[51,47,12,7],
[51,47,12,9],
[51,47,12,10],
[51,47,13,0],
[51,47,13,1],
[51,47,13,2],
[51,47,13,4],
[51,47,13,7],
[51,47,13,9],
[51,47,13,10],
[51,47,13,11],
[51,47,13,12],
[51,47,14,0],
[51,47,14,1],
[51,47,14,2],
[51,47,14,4],
[51,47,14,7],
[51,47,14,9],
[51,47,14,10],
[51,47,14,11],
[51,47,14,12],
[51,47,15,0],
[51,47,15,1],
[51,47,15,2],
[51,47,15,4],
[51,47,15,7],
[51,47,15,10],
[51,47,15,11],
[51,47,15,12],
[51,47,15,13],
[51,47,15,14],
[51,47,16,0],
[51,47,16,1],
[51,47,16,2],
[51,47,16,4],
[51,47,16,7],
[51,47,16,9],
[51,47,16,10],
[51,47,16,11],
[51,47,16,12],
[51,47,16,13],
[51,47,16,14],
[51,47,16,15],
[51,47,17,0],
[51,47,17,1],
[51,47,17,2],
[51,47,17,4],
[51,47,17,7],
[51,47,17,9],
[51,47,17,10],
[51,47,17,11],
[51,47,17,12],
[51,47,17,13],
[51,47,17,14],
[51,47,17,15],
[51,47,18,0],
[51,47,18,1],
[51,47,18,2],
[51,47,18,4],
[51,47,18,7],
[51,47,18,10],
[51,47,18,11],
[51,47,18,12],
[51,47,18,13],
[51,47,18,14],
[51,47,18,16],
[51,47,18,17],
[51,47,19,0],
[51,47,19,1],
[51,47,19,2],
[51,47,19,4],
[51,47,19,7],
[51,47,19,9],
[51,47,19,10],
[51,47,19,11],
[51,47,19,12],
[51,47,19,15],
[51,47,19,16],
[51,47,19,17],
[51,47,19,18],
[51,47,20,0],
[51,47,20,1],
[51,47,20,2],
[51,47,20,4],
[51,47,20,7],
[51,47,20,9],
[51,47,20,11],
[51,47,20,14],
[51,47,20,15],
[51,47,20,16],
[51,47,20,17],
[51,47,20,18],
[51,47,20,19],
[51,47,22,0],
[51,47,22,1],
[51,47,22,2],
[51,47,22,4],
[51,47,22,7],
[51,47,22,11],
[51,47,22,12],
[51,47,22,13],
[51,47,22,14],
[51,47,22,15],
[51,47,22,16],
[51,47,22,17],
[51,47,22,18],
[51,47,22,19],
[51,47,24,0],
[51,47,24,1],
[51,47,24,2],
[51,47,24,4],
[51,47,24,9],
[51,47,24,10],
[51,47,24,11],
[51,47,24,12],
[51,47,24,13],
[51,47,24,14],
[51,47,24,15],
[51,47,24,17],
[51,47,24,18],
[51,47,24,19],
[51,47,24,20],
[51,47,24,22],
[51,47,25,0],
[51,47,25,1],
[51,47,25,2],
[51,47,25,9],
[51,47,25,10],
[51,47,25,11],
[51,47,25,12],
[51,47,25,13],
[51,47,25,14],
[51,47,25,15],
[51,47,25,16],
[51,47,25,17],
[51,47,25,18],
[51,47,25,19],
[51,47,25,20],
[51,47,26,4],
[51,47,26,7],
[51,47,26,10],
[51,47,26,11],
[51,47,26,12],
[51,47,26,13],
[51,47,26,14],
[51,47,26,16],
[51,47,26,17],
[51,47,26,19],
[51,47,26,20],
[51,47,27,0],
[51,47,27,1],
[51,47,27,2],
[51,47,27,4],
[51,47,27,7],
[51,47,27,9],
[51,47,27,11],
[51,47,27,12],
[51,47,27,13],
[51,47,27,14],
[51,47,27,15],
[51,47,27,16],
[51,47,27,17],
[51,47,27,18],
[51,47,27,19],
[51,47,28,0],
[51,47,28,1],
[51,47,28,2],
[51,47,28,4],
[51,47,28,9],
[51,47,28,10],
[51,47,28,11],
[51,47,28,12],
[51,47,28,13],
[51,47,28,14],
[51,47,28,15],
[51,47,28,16],
[51,47,28,17],
[51,47,28,18],
[51,47,29,0],
[51,47,29,1],
[51,47,29,2],
[51,47,29,4],
[51,47,29,7],
[51,47,29,9],
[51,47,29,10],
[51,47,29,13],
[51,47,29,14],
[51,47,29,15],
[51,47,29,16],
[51,47,29,17],
[51,47,32,0],
[51,47,32,1],
[51,47,32,2],
[51,47,32,4],
[51,47,32,7],
[51,47,32,9],
[51,47,32,10],
[51,47,32,13],
[51,47,33,0],
[51,47,33,1],
[51,47,33,2],
[51,47,33,4],
[51,47,33,7],
[51,47,33,9],
[51,47,33,10],
[51,47,34,0],
[51,47,34,1],
[51,47,34,2],
[51,47,34,4],
[51,47,34,9],
[51,47,35,0],
[51,47,35,1],
[51,47,35,2],
[51,47,35,4],
[51,47,35,7],
[51,47,36,0],
[51,47,36,1],
[51,47,36,2],
[51,47,36,4],
[51,47,37,0],
[51,47,37,1],
[51,47,37,2],
[51,47,38,0],
[51,47,38,1],
[51,47,38,2],
[51,48,4,0],
[51,48,4,1],
[51,48,4,2],
[51,48,7,0],
[51,48,7,1],
[51,48,7,2],
[51,48,7,4],
[51,48,9,0],
[51,48,9,1],
[51,48,9,2],
[51,48,9,4],
[51,48,9,7],
[51,48,10,0],
[51,48,10,1],
[51,48,10,2],
[51,48,10,4],
[51,48,10,7],
[51,48,10,9],
[51,48,13,0],
[51,48,13,1],
[51,48,13,2],
[51,48,13,4],
[51,48,13,7],
[51,48,13,9],
[51,48,13,10],
[51,48,14,0],
[51,48,14,1],
[51,48,14,2],
[51,48,14,4],
[51,48,14,7],
[51,48,14,9],
[51,48,14,10],
[51,48,15,0],
[51,48,15,1],
[51,48,15,2],
[51,48,15,4],
[51,48,15,7],
[51,48,15,10],
[51,48,15,13],
[51,48,15,14],
[51,48,16,0],
[51,48,16,1],
[51,48,16,2],
[51,48,16,4],
[51,48,16,7],
[51,48,16,9],
[51,48,16,10],
[51,48,16,13],
[51,48,16,14],
[51,48,17,0],
[51,48,17,1],
[51,48,17,2],
[51,48,17,4],
[51,48,17,7],
[51,48,17,9],
[51,48,17,10],
[51,48,17,13],
[51,48,17,14],
[51,48,17,16],
[51,48,18,0],
[51,48,18,1],
[51,48,18,2],
[51,48,18,4],
[51,48,18,7],
[51,48,18,10],
[51,48,18,13],
[51,48,18,16],
[51,48,18,17],
[51,48,19,0],
[51,48,19,1],
[51,48,19,2],
[51,48,19,4],
[51,48,19,7],
[51,48,19,9],
[51,48,19,10],
[51,48,19,15],
[51,48,19,16],
[51,48,19,17],
[51,48,19,18],
[51,48,20,0],
[51,48,20,1],
[51,48,20,2],
[51,48,20,4],
[51,48,20,7],
[51,48,20,9],
[51,48,20,13],
[51,48,20,14],
[51,48,20,15],
[51,48,20,16],
[51,48,20,17],
[51,48,20,18],
[51,48,20,19],
[51,48,22,0],
[51,48,22,1],
[51,48,22,2],
[51,48,22,4],
[51,48,22,9],
[51,48,22,13],
[51,48,22,14],
[51,48,22,15],
[51,48,22,16],
[51,48,22,17],
[51,48,22,18],
[51,48,22,19],
[51,48,23,0],
[51,48,23,1],
[51,48,23,2],
[51,48,23,4],
[51,48,23,7],
[51,48,23,9],
[51,48,23,10],
[51,48,23,13],
[51,48,23,14],
[51,48,23,15],
[51,48,23,16],
[51,48,23,17],
[51,48,23,18],
[51,48,23,19],
[51,48,23,20],
[51,48,23,22],
[51,48,24,0],
[51,48,24,1],
[51,48,24,2],
[51,48,24,4],
[51,48,24,7],
[51,48,24,9],
[51,48,24,10],
[51,48,24,13],
[51,48,24,14],
[51,48,24,15],
[51,48,24,17],
[51,48,24,18],
[51,48,24,19],
[51,48,24,20],
[51,48,24,22],
[51,48,25,4],
[51,48,25,9],
[51,48,25,10],
[51,48,25,13],
[51,48,25,14],
[51,48,25,15],
[51,48,25,16],
[51,48,25,17],
[51,48,25,18],
[51,48,25,19],
[51,48,25,20],
[51,48,26,0],
[51,48,26,1],
[51,48,26,2],
[51,48,26,4],
[51,48,26,7],
[51,48,26,10],
[51,48,26,13],
[51,48,26,14],
[51,48,26,16],
[51,48,26,17],
[51,48,26,19],
[51,48,27,0],
[51,48,27,1],
[51,48,27,2],
[51,48,27,4],
[51,48,27,7],
[51,48,27,9],
[51,48,27,13],
[51,48,27,14],
[51,48,27,15],
[51,48,27,16],
[51,48,27,17],
[51,48,27,18],
[51,48,28,0],
[51,48,28,1],
[51,48,28,2],
[51,48,28,4],
[51,48,28,9],
[51,48,28,10],
[51,48,28,13],
[51,48,28,14],
[51,48,28,15],
[51,48,28,16],
[51,48,28,17],
[51,48,30,0],
[51,48,30,1],
[51,48,30,2],
[51,48,30,4],
[51,48,30,7],
[51,48,30,9],
[51,48,30,10],
[51,48,30,13],
[51,48,30,14],
[51,48,31,0],
[51,48,31,1],
[51,48,31,2],
[51,48,31,4],
[51,48,31,7],
[51,48,31,9],
[51,48,31,10],
[51,48,31,13],
[51,48,34,0],
[51,48,34,1],
[51,48,34,2],
[51,48,34,4],
[51,48,35,0],
[51,48,35,1],
[51,48,35,2],
[51,48,35,4],
[51,48,36,0],
[51,48,36,1],
[51,48,36,2],
[51,48,36,4],
[51,48,37,0],
[51,48,37,1],
[51,48,37,2],
[51,49,4,0],
[51,49,4,1],
[51,49,4,2],
[51,49,7,0],
[51,49,7,1],
[51,49,7,2],
[51,49,7,4],
[51,49,10,0],
[51,49,10,1],
[51,49,10,2],
[51,49,10,4],
[51,49,10,7],
[51,49,11,0],
[51,49,11,1],
[51,49,11,2],
[51,49,11,4],
[51,49,11,7],
[51,49,11,10],
[51,49,12,0],
[51,49,12,1],
[51,49,12,2],
[51,49,12,4],
[51,49,12,7],
[51,49,12,10],
[51,49,13,0],
[51,49,13,1],
[51,49,13,2],
[51,49,13,4],
[51,49,13,7],
[51,49,13,10],
[51,49,13,11],
[51,49,13,12],
[51,49,14,0],
[51,49,14,1],
[51,49,14,2],
[51,49,14,4],
[51,49,14,7],
[51,49,14,10],
[51,49,14,11],
[51,49,14,12],
[51,49,16,0],
[51,49,16,1],
[51,49,16,2],
[51,49,16,4],
[51,49,16,7],
[51,49,16,10],
[51,49,16,11],
[51,49,16,12],
[51,49,16,13],
[51,49,16,14],
[51,49,17,0],
[51,49,17,1],
[51,49,17,2],
[51,49,17,4],
[51,49,17,7],
[51,49,17,10],
[51,49,17,11],
[51,49,17,12],
[51,49,17,13],
[51,49,17,14],
[51,49,17,16],
[51,49,19,0],
[51,49,19,1],
[51,49,19,2],
[51,49,19,4],
[51,49,19,7],
[51,49,19,10],
[51,49,19,11],
[51,49,19,16],
[51,49,19,17],
[51,49,20,0],
[51,49,20,1],
[51,49,20,2],
[51,49,20,4],
[51,49,20,7],
[51,49,20,12],
[51,49,20,13],
[51,49,20,14],
[51,49,20,16],
[51,49,20,17],
[51,49,20,19],
[51,49,22,0],
[51,49,22,1],
[51,49,22,2],
[51,49,22,4],
[51,49,22,11],
[51,49,22,12],
[51,49,22,13],
[51,49,22,14],
[51,49,22,16],
[51,49,22,17],
[51,49,22,19],
[51,49,23,0],
[51,49,23,1],
[51,49,23,2],
[51,49,23,4],
[51,49,23,7],
[51,49,23,10],
[51,49,23,11],
[51,49,23,12],
[51,49,23,13],
[51,49,23,14],
[51,49,23,16],
[51,49,23,17],
[51,49,23,19],
[51,49,23,20],
[51,49,23,22],
[51,49,24,0],
[51,49,24,1],
[51,49,24,2],
[51,49,24,4],
[51,49,24,7],
[51,49,24,10],
[51,49,24,11],
[51,49,24,12],
[51,49,24,13],
[51,49,24,14],
[51,49,24,17],
[51,49,24,19],
[51,49,24,20],
[51,49,24,22],
[51,49,25,4],
[51,49,25,10],
[51,49,25,11],
[51,49,25,12],
[51,49,25,13],
[51,49,25,14],
[51,49,25,16],
[51,49,25,17],
[51,49,25,19],
[51,49,25,20],
[51,49,27,0],
[51,49,27,1],
[51,49,27,2],
[51,49,27,4],
[51,49,27,7],
[51,49,27,11],
[51,49,27,12],
[51,49,27,13],
[51,49,27,14],
[51,49,27,16],
[51,49,27,17],
[51,49,28,0],
[51,49,28,1],
[51,49,28,2],
[51,49,28,4],
[51,49,28,10],
[51,49,28,11],
[51,49,28,12],
[51,49,28,13],
[51,49,28,14],
[51,49,28,16],
[51,49,28,17],
[51,49,29,0],
[51,49,29,1],
[51,49,29,2],
[51,49,29,4],
[51,49,29,7],
[51,49,29,10],
[51,49,29,13],
[51,49,29,14],
[51,49,30,0],
[51,49,30,1],
[51,49,30,2],
[51,49,30,4],
[51,49,30,7],
[51,49,30,10],
[51,49,30,11],
[51,49,30,12],
[51,49,30,13],
[51,49,30,14],
[51,49,31,0],
[51,49,31,1],
[51,49,31,2],
[51,49,31,4],
[51,49,31,7],
[51,49,31,10],
[51,49,31,11],
[51,49,31,12],
[51,49,31,13],
[51,49,32,0],
[51,49,32,1],
[51,49,32,2],
[51,49,32,4],
[51,49,32,7],
[51,49,32,10],
[51,49,33,0],
[51,49,33,1],
[51,49,33,2],
[51,49,33,4],
[51,49,33,7],
[51,49,34,0],
[51,49,34,1],
[51,49,34,2],
[51,49,34,4],
[51,49,36,0],
[51,49,36,1],
[51,49,36,2],
[51,49,36,4],
[51,49,37,0],
[51,49,37,1],
[51,49,37,2],
[51,50,4,0],
[51,50,4,1],
[51,50,4,2],
[51,50,7,0],
[51,50,7,1],
[51,50,7,2],
[51,50,7,4],
[51,50,10,0],
[51,50,10,1],
[51,50,10,2],
[51,50,10,4],
[51,50,10,7],
[51,50,11,0],
[51,50,11,1],
[51,50,11,2],
[51,50,11,4],
[51,50,11,7],
[51,50,11,10],
[51,50,12,0],
[51,50,12,1],
[51,50,12,2],
[51,50,12,4],
[51,50,12,7],
[51,50,12,10],
[51,50,13,0],
[51,50,13,1],
[51,50,13,2],
[51,50,13,4],
[51,50,13,7],
[51,50,13,10],
[51,50,13,11],
[51,50,13,12],
[51,50,14,0],
[51,50,14,1],
[51,50,14,2],
[51,50,14,4],
[51,50,14,7],
[51,50,14,10],
[51,50,14,11],
[51,50,14,12],
[51,50,16,0],
[51,50,16,1],
[51,50,16,2],
[51,50,16,4],
[51,50,16,7],
[51,50,16,10],
[51,50,16,11],
[51,50,16,12],
[51,50,16,13],
[51,50,16,14],
[51,50,17,0],
[51,50,17,1],
[51,50,17,2],
[51,50,17,4],
[51,50,17,7],
[51,50,17,10],
[51,50,17,11],
[51,50,17,12],
[51,50,17,13],
[51,50,17,14],
[51,50,17,16],
[51,50,19,0],
[51,50,19,1],
[51,50,19,2],
[51,50,19,4],
[51,50,19,7],
[51,50,19,10],
[51,50,19,11],
[51,50,19,16],
[51,50,19,17],
[51,50,20,0],
[51,50,20,1],
[51,50,20,2],
[51,50,20,4],
[51,50,20,7],
[51,50,20,12],
[51,50,20,13],
[51,50,20,14],
[51,50,20,16],
[51,50,20,17],
[51,50,20,19],
[51,50,22,0],
[51,50,22,1],
[51,50,22,2],
[51,50,22,4],
[51,50,22,11],
[51,50,22,12],
[51,50,22,13],
[51,50,22,14],
[51,50,22,16],
[51,50,22,17],
[51,50,22,19],
[51,50,23,0],
[51,50,23,1],
[51,50,23,2],
[51,50,23,4],
[51,50,23,7],
[51,50,23,10],
[51,50,23,11],
[51,50,23,12],
[51,50,23,13],
[51,50,23,14],
[51,50,23,16],
[51,50,23,17],
[51,50,23,19],
[51,50,23,20],
[51,50,23,22],
[51,50,24,0],
[51,50,24,1],
[51,50,24,2],
[51,50,24,4],
[51,50,24,7],
[51,50,24,10],
[51,50,24,11],
[51,50,24,12],
[51,50,24,13],
[51,50,24,14],
[51,50,24,17],
[51,50,24,19],
[51,50,24,20],
[51,50,24,22],
[51,50,25,4],
[51,50,25,10],
[51,50,25,11],
[51,50,25,12],
[51,50,25,13],
[51,50,25,14],
[51,50,25,16],
[51,50,25,17],
[51,50,25,19],
[51,50,25,20],
[51,50,27,0],
[51,50,27,1],
[51,50,27,2],
[51,50,27,4],
[51,50,27,7],
[51,50,27,11],
[51,50,27,12],
[51,50,27,13],
[51,50,27,14],
[51,50,27,16],
[51,50,27,17],
[51,50,28,0],
[51,50,28,1],
[51,50,28,2],
[51,50,28,4],
[51,50,28,10],
[51,50,28,11],
[51,50,28,12],
[51,50,28,13],
[51,50,28,14],
[51,50,28,16],
[51,50,28,17],
[51,50,29,0],
[51,50,29,1],
[51,50,29,2],
[51,50,29,4],
[51,50,29,7],
[51,50,29,10],
[51,50,29,13],
[51,50,29,14],
[51,50,30,0],
[51,50,30,1],
[51,50,30,2],
[51,50,30,4],
[51,50,30,7],
[51,50,30,10],
[51,50,30,11],
[51,50,30,12],
[51,50,30,13],
[51,50,30,14],
[51,50,31,0],
[51,50,31,1],
[51,50,31,2],
[51,50,31,4],
[51,50,31,7],
[51,50,31,10],
[51,50,31,11],
[51,50,31,12],
[51,50,31,13],
[51,50,32,0],
[51,50,32,1],
[51,50,32,2],
[51,50,32,4],
[51,50,32,7],
[51,50,32,10],
[51,50,33,0],
[51,50,33,1],
[51,50,33,2],
[51,50,33,4],
[51,50,33,7],
[51,50,34,0],
[51,50,34,1],
[51,50,34,2],
[51,50,34,4],
[51,50,36,0],
[51,50,36,1],
[51,50,36,2],
[51,50,36,4],
[51,50,37,0],
[51,50,37,1],
[51,50,37,2],
[52,4,3,0],
[52,4,3,1],
[52,4,3,2],
[52,5,4,0],
[52,5,4,1],
[52,5,4,2],
[52,6,4,0],
[52,6,4,1],
[52,6,4,2],
[52,7,3,0],
[52,7,3,1],
[52,7,3,2],
[52,7,4,0],
[52,7,4,1],
[52,7,4,2],
[52,7,4,3],
[52,7,5,0],
[52,7,5,1],
[52,7,5,2],
[52,7,5,4],
[52,7,6,0],
[52,7,6,1],
[52,7,6,2],
[52,7,6,4],
[52,8,4,0],
[52,8,4,1],
[52,8,4,2],
[52,8,7,0],
[52,8,7,1],
[52,8,7,2],
[52,8,7,4],
[52,10,3,0],
[52,10,3,1],
[52,10,3,2],
[52,10,4,0],
[52,10,4,1],
[52,10,4,2],
[52,10,4,3],
[52,10,5,0],
[52,10,5,1],
[52,10,5,2],
[52,10,5,4],
[52,10,6,0],
[52,10,6,1],
[52,10,6,2],
[52,10,6,4],
[52,10,7,0],
[52,10,7,1],
[52,10,7,2],
[52,10,7,3],
[52,10,7,4],
[52,10,7,5],
[52,10,7,6],
[52,10,8,0],
[52,10,8,1],
[52,10,8,2],
[52,10,8,4],
[52,10,8,7],
[52,11,3,0],
[52,11,3,1],
[52,11,3,2],
[52,11,4,0],
[52,11,4,1],
[52,11,4,2],
[52,11,4,3],
[52,11,5,0],
[52,11,5,1],
[52,11,5,2],
[52,11,5,4],
[52,11,6,0],
[52,11,6,1],
[52,11,6,2],
[52,11,6,4],
[52,11,7,0],
[52,11,7,1],
[52,11,7,2],
[52,11,7,3],
[52,11,7,4],
[52,11,7,5],
[52,11,7,6],
[52,11,8,0],
[52,11,8,1],
[52,11,8,2],
[52,11,8,4],
[52,11,8,7],
[52,11,10,0],
[52,11,10,1],
[52,11,10,2],
[52,11,10,3],
[52,11,10,4],
[52,11,10,5],
[52,11,10,6],
[52,11,10,7],
[52,11,10,8],
[52,12,3,0],
[52,12,3,1],
[52,12,3,2],
[52,12,4,0],
[52,12,4,1],
[52,12,4,2],
[52,12,4,3],
[52,12,5,0],
[52,12,5,1],
[52,12,5,2],
[52,12,5,4],
[52,12,6,0],
[52,12,6,1],
[52,12,6,2],
[52,12,6,4],
[52,12,7,0],
[52,12,7,1],
[52,12,7,2],
[52,12,7,3],
[52,12,7,4],
[52,12,7,5],
[52,12,7,6],
[52,12,8,0],
[52,12,8,1],
[52,12,8,2],
[52,12,8,4],
[52,12,8,7],
[52,12,10,0],
[52,12,10,1],
[52,12,10,2],
[52,12,10,3],
[52,12,10,4],
[52,12,10,5],
[52,12,10,6],
[52,12,10,7],
[52,12,10,8],
[52,13,3,0],
[52,13,3,1],
[52,13,3,2],
[52,13,4,0],
[52,13,4,1],
[52,13,4,2],
[52,13,4,3],
[52,13,5,0],
[52,13,5,1],
[52,13,5,2],
[52,13,5,4],
[52,13,6,0],
[52,13,6,1],
[52,13,6,2],
[52,13,6,4],
[52,13,7,0],
[52,13,7,1],
[52,13,7,2],
[52,13,7,3],
[52,13,7,4],
[52,13,7,5],
[52,13,7,6],
[52,13,8,0],
[52,13,8,1],
[52,13,8,2],
[52,13,8,4],
[52,13,8,7],
[52,13,10,0],
[52,13,10,1],
[52,13,10,2],
[52,13,10,3],
[52,13,10,4],
[52,13,10,5],
[52,13,10,6],
[52,13,10,7],
[52,13,10,8],
[52,13,11,0],
[52,13,11,1],
[52,13,11,2],
[52,13,11,3],
[52,13,11,4],
[52,13,11,5],
[52,13,11,6],
[52,13,11,7],
[52,13,11,8],
[52,13,11,10],
[52,13,12,0],
[52,13,12,1],
[52,13,12,2],
[52,13,12,3],
[52,13,12,4],
[52,13,12,5],
[52,13,12,6],
[52,13,12,7],
[52,13,12,8],
[52,13,12,10],
[52,14,3,0],
[52,14,3,1],
[52,14,3,2],
[52,14,4,0],
[52,14,4,1],
[52,14,4,2],
[52,14,4,3],
[52,14,5,0],
[52,14,5,1],
[52,14,5,2],
[52,14,5,4],
[52,14,6,0],
[52,14,6,1],
[52,14,6,2],
[52,14,6,4],
[52,14,7,0],
[52,14,7,1],
[52,14,7,2],
[52,14,7,3],
[52,14,7,4],
[52,14,7,5],
[52,14,7,6],
[52,14,8,0],
[52,14,8,1],
[52,14,8,2],
[52,14,8,4],
[52,14,8,7],
[52,14,10,0],
[52,14,10,1],
[52,14,10,2],
[52,14,10,3],
[52,14,10,4],
[52,14,10,5],
[52,14,10,6],
[52,14,10,7],
[52,14,10,8],
[52,14,11,0],
[52,14,11,1],
[52,14,11,2],
[52,14,11,3],
[52,14,11,4],
[52,14,11,5],
[52,14,11,6],
[52,14,11,7],
[52,14,11,8],
[52,14,11,10],
[52,14,12,0],
[52,14,12,1],
[52,14,12,2],
[52,14,12,3],
[52,14,12,4],
[52,14,12,5],
[52,14,12,6],
[52,14,12,7],
[52,14,12,8],
[52,14,12,10],
[52,16,3,0],
[52,16,3,1],
[52,16,3,2],
[52,16,4,0],
[52,16,4,1],
[52,16,4,2],
[52,16,4,3],
[52,16,5,0],
[52,16,5,1],
[52,16,5,2],
[52,16,5,4],
[52,16,6,0],
[52,16,6,1],
[52,16,6,2],
[52,16,6,4],
[52,16,7,0],
[52,16,7,1],
[52,16,7,2],
[52,16,7,3],
[52,16,7,4],
[52,16,7,5],
[52,16,7,6],
[52,16,8,0],
[52,16,8,1],
[52,16,8,2],
[52,16,8,4],
[52,16,8,7],
[52,16,10,0],
[52,16,10,1],
[52,16,10,2],
[52,16,10,3],
[52,16,10,4],
[52,16,10,5],
[52,16,10,6],
[52,16,10,7],
[52,16,10,8],
[52,16,11,0],
[52,16,11,1],
[52,16,11,2],
[52,16,11,3],
[52,16,11,4],
[52,16,11,5],
[52,16,11,6],
[52,16,11,7],
[52,16,11,8],
[52,16,11,10],
[52,16,12,0],
[52,16,12,1],
[52,16,12,2],
[52,16,12,3],
[52,16,12,4],
[52,16,12,5],
[52,16,12,6],
[52,16,12,7],
[52,16,12,8],
[52,16,12,10],
[52,16,13,0],
[52,16,13,1],
[52,16,13,2],
[52,16,13,3],
[52,16,13,4],
[52,16,13,5],
[52,16,13,6],
[52,16,13,7],
[52,16,13,8],
[52,16,13,10],
[52,16,13,11],
[52,16,13,12],
[52,16,14,0],
[52,16,14,1],
[52,16,14,2],
[52,16,14,3],
[52,16,14,4],
[52,16,14,5],
[52,16,14,6],
[52,16,14,7],
[52,16,14,8],
[52,16,14,10],
[52,16,14,11],
[52,16,14,12],
[52,17,3,0],
[52,17,3,1],
[52,17,3,2],
[52,17,4,0],
[52,17,4,1],
[52,17,4,2],
[52,17,4,3],
[52,17,5,0],
[52,17,5,1],
[52,17,5,2],
[52,17,5,4],
[52,17,6,0],
[52,17,6,1],
[52,17,6,2],
[52,17,6,4],
[52,17,7,0],
[52,17,7,1],
[52,17,7,2],
[52,17,7,3],
[52,17,7,4],
[52,17,7,5],
[52,17,7,6],
[52,17,8,0],
[52,17,8,1],
[52,17,8,2],
[52,17,8,4],
[52,17,8,7],
[52,17,10,0],
[52,17,10,1],
[52,17,10,2],
[52,17,10,3],
[52,17,10,4],
[52,17,10,5],
[52,17,10,6],
[52,17,10,7],
[52,17,10,8],
[52,17,11,0],
[52,17,11,1],
[52,17,11,2],
[52,17,11,3],
[52,17,11,4],
[52,17,11,5],
[52,17,11,6],
[52,17,11,7],
[52,17,11,8],
[52,17,11,10],
[52,17,12,0],
[52,17,12,1],
[52,17,12,2],
[52,17,12,3],
[52,17,12,4],
[52,17,12,5],
[52,17,12,6],
[52,17,12,7],
[52,17,12,8],
[52,17,12,10],
[52,17,13,0],
[52,17,13,1],
[52,17,13,2],
[52,17,13,3],
[52,17,13,4],
[52,17,13,5],
[52,17,13,6],
[52,17,13,7],
[52,17,13,8],
[52,17,13,10],
[52,17,13,11],
[52,17,13,12],
[52,17,14,0],
[52,17,14,1],
[52,17,14,2],
[52,17,14,3],
[52,17,14,4],
[52,17,14,5],
[52,17,14,6],
[52,17,14,7],
[52,17,14,8],
[52,17,14,10],
[52,17,14,11],
[52,17,14,12],
[52,17,16,0],
[52,17,16,1],
[52,17,16,2],
[52,17,16,3],
[52,17,16,4],
[52,17,16,5],
[52,17,16,6],
[52,17,16,7],
[52,17,16,8],
[52,17,16,10],
[52,17,16,11],
[52,17,16,12],
[52,17,16,13],
[52,17,16,14],
[52,19,3,0],
[52,19,3,1],
[52,19,3,2],
[52,19,4,0],
[52,19,4,1],
[52,19,4,2],
[52,19,4,3],
[52,19,5,0],
[52,19,5,1],
[52,19,5,2],
[52,19,5,4],
[52,19,6,0],
[52,19,6,1],
[52,19,6,2],
[52,19,6,4],
[52,19,7,0],
[52,19,7,1],
[52,19,7,2],
[52,19,7,3],
[52,19,7,4],
[52,19,7,5],
[52,19,7,6],
[52,19,8,0],
[52,19,8,1],
[52,19,8,2],
[52,19,8,4],
[52,19,8,7],
[52,19,10,0],
[52,19,10,1],
[52,19,10,2],
[52,19,10,3],
[52,19,10,4],
[52,19,10,5],
[52,19,10,6],
[52,19,10,7],
[52,19,10,8],
[52,19,11,0],
[52,19,11,1],
[52,19,11,2],
[52,19,11,3],
[52,19,11,4],
[52,19,11,5],
[52,19,11,6],
[52,19,11,7],
[52,19,11,8],
[52,19,11,10],
[52,19,12,0],
[52,19,12,1],
[52,19,12,2],
[52,19,12,3],
[52,19,12,4],
[52,19,12,5],
[52,19,12,6],
[52,19,12,7],
[52,19,12,8],
[52,19,12,10],
[52,19,16,0],
[52,19,16,1],
[52,19,16,2],
[52,19,16,3],
[52,19,16,4],
[52,19,16,5],
[52,19,16,6],
[52,19,16,7],
[52,19,16,8],
[52,19,16,10],
[52,19,16,11],
[52,19,16,12],
[52,19,17,0],
[52,19,17,1],
[52,19,17,2],
[52,19,17,3],
[52,19,17,4],
[52,19,17,5],
[52,19,17,6],
[52,19,17,7],
[52,19,17,8],
[52,19,17,10],
[52,19,17,11],
[52,19,17,12],
[52,19,17,16],
[52,20,3,0],
[52,20,3,1],
[52,20,3,2],
[52,20,4,0],
[52,20,4,1],
[52,20,4,2],
[52,20,4,3],
[52,20,5,0],
[52,20,5,1],
[52,20,5,2],
[52,20,5,4],
[52,20,6,0],
[52,20,6,1],
[52,20,6,2],
[52,20,6,4],
[52,20,7,0],
[52,20,7,1],
[52,20,7,2],
[52,20,7,3],
[52,20,7,4],
[52,20,7,5],
[52,20,7,6],
[52,20,8,0],
[52,20,8,1],
[52,20,8,2],
[52,20,8,4],
[52,20,8,7],
[52,20,11,0],
[52,20,11,1],
[52,20,11,2],
[52,20,11,3],
[52,20,11,4],
[52,20,11,5],
[52,20,11,6],
[52,20,11,7],
[52,20,11,8],
[52,20,12,0],
[52,20,12,1],
[52,20,12,2],
[52,20,12,3],
[52,20,12,4],
[52,20,12,5],
[52,20,12,6],
[52,20,12,7],
[52,20,12,8],
[52,20,13,0],
[52,20,13,1],
[52,20,13,2],
[52,20,13,3],
[52,20,13,4],
[52,20,13,5],
[52,20,13,6],
[52,20,13,7],
[52,20,13,8],
[52,20,13,11],
[52,20,13,12],
[52,20,14,0],
[52,20,14,1],
[52,20,14,2],
[52,20,14,3],
[52,20,14,4],
[52,20,14,5],
[52,20,14,6],
[52,20,14,7],
[52,20,14,8],
[52,20,14,11],
[52,20,14,12],
[52,20,16,0],
[52,20,16,1],
[52,20,16,2],
[52,20,16,3],
[52,20,16,4],
[52,20,16,5],
[52,20,16,6],
[52,20,16,7],
[52,20,16,8],
[52,20,16,11],
[52,20,16,12],
[52,20,16,13],
[52,20,16,14],
[52,20,17,0],
[52,20,17,1],
[52,20,17,2],
[52,20,17,3],
[52,20,17,4],
[52,20,17,5],
[52,20,17,6],
[52,20,17,7],
[52,20,17,8],
[52,20,17,11],
[52,20,17,12],
[52,20,17,13],
[52,20,17,14],
[52,20,17,16],
[52,20,19,0],
[52,20,19,1],
[52,20,19,2],
[52,20,19,3],
[52,20,19,4],
[52,20,19,5],
[52,20,19,6],
[52,20,19,7],
[52,20,19,8],
[52,20,19,11],
[52,20,19,12],
[52,20,19,16],
[52,20,19,17],
[52,21,4,0],
[52,21,4,1],
[52,21,4,2],
[52,21,7,0],
[52,21,7,1],
[52,21,7,2],
[52,21,7,4],
[52,21,10,0],
[52,21,10,1],
[52,21,10,2],
[52,21,10,4],
[52,21,10,7],
[52,21,11,0],
[52,21,11,1],
[52,21,11,2],
[52,21,11,4],
[52,21,11,7],
[52,21,11,10],
[52,21,12,0],
[52,21,12,1],
[52,21,12,2],
[52,21,12,4],
[52,21,12,7],
[52,21,12,10],
[52,21,13,0],
[52,21,13,1],
[52,21,13,2],
[52,21,13,4],
[52,21,13,7],
[52,21,13,10],
[52,21,13,11],
[52,21,13,12],
[52,21,14,0],
[52,21,14,1],
[52,21,14,2],
[52,21,14,4],
[52,21,14,7],
[52,21,14,10],
[52,21,14,11],
[52,21,14,12],
[52,21,16,0],
[52,21,16,1],
[52,21,16,2],
[52,21,16,4],
[52,21,16,7],
[52,21,16,10],
[52,21,16,11],
[52,21,16,12],
[52,21,16,13],
[52,21,16,14],
[52,21,17,0],
[52,21,17,1],
[52,21,17,2],
[52,21,17,4],
[52,21,17,7],
[52,21,17,10],
[52,21,17,11],
[52,21,17,12],
[52,21,17,13],
[52,21,17,14],
[52,21,17,16],
[52,21,19,0],
[52,21,19,1],
[52,21,19,2],
[52,21,19,4],
[52,21,19,7],
[52,21,19,10],
[52,21,19,11],
[52,21,19,12],
[52,21,19,16],
[52,21,19,17],
[52,21,20,0],
[52,21,20,1],
[52,21,20,2],
[52,21,20,4],
[52,21,20,7],
[52,21,20,11],
[52,21,20,12],
[52,21,20,13],
[52,21,20,14],
[52,21,20,16],
[52,21,20,17],
[52,21,20,19],
[52,22,3,0],
[52,22,3,1],
[52,22,3,2],
[52,22,4,0],
[52,22,4,1],
[52,22,4,2],
[52,22,4,3],
[52,22,5,0],
[52,22,5,1],
[52,22,5,2],
[52,22,5,4],
[52,22,6,0],
[52,22,6,1],
[52,22,6,2],
[52,22,6,4],
[52,22,7,0],
[52,22,7,1],
[52,22,7,2],
[52,22,7,3],
[52,22,7,4],
[52,22,7,5],
[52,22,7,6],
[52,22,8,0],
[52,22,8,1],
[52,22,8,2],
[52,22,8,4],
[52,22,8,7],
[52,22,11,0],
[52,22,11,1],
[52,22,11,2],
[52,22,11,3],
[52,22,11,4],
[52,22,11,5],
[52,22,11,6],
[52,22,11,7],
[52,22,11,8],
[52,22,12,0],
[52,22,12,1],
[52,22,12,2],
[52,22,12,3],
[52,22,12,4],
[52,22,12,5],
[52,22,12,6],
[52,22,12,7],
[52,22,12,8],
[52,22,13,0],
[52,22,13,1],
[52,22,13,2],
[52,22,13,3],
[52,22,13,4],
[52,22,13,5],
[52,22,13,6],
[52,22,13,7],
[52,22,13,8],
[52,22,13,11],
[52,22,13,12],
[52,22,14,0],
[52,22,14,1],
[52,22,14,2],
[52,22,14,3],
[52,22,14,4],
[52,22,14,5],
[52,22,14,6],
[52,22,14,7],
[52,22,14,8],
[52,22,14,11],
[52,22,14,12],
[52,22,16,0],
[52,22,16,1],
[52,22,16,2],
[52,22,16,3],
[52,22,16,4],
[52,22,16,5],
[52,22,16,6],
[52,22,16,7],
[52,22,16,8],
[52,22,16,11],
[52,22,16,12],
[52,22,16,13],
[52,22,16,14],
[52,22,17,0],
[52,22,17,1],
[52,22,17,2],
[52,22,17,3],
[52,22,17,4],
[52,22,17,5],
[52,22,17,6],
[52,22,17,7],
[52,22,17,8],
[52,22,17,11],
[52,22,17,12],
[52,22,17,13],
[52,22,17,14],
[52,22,17,16],
[52,22,19,0],
[52,22,19,1],
[52,22,19,2],
[52,22,19,3],
[52,22,19,4],
[52,22,19,5],
[52,22,19,6],
[52,22,19,7],
[52,22,19,8],
[52,22,19,11],
[52,22,19,12],
[52,22,19,16],
[52,22,19,17],
[52,22,21,0],
[52,22,21,1],
[52,22,21,2],
[52,22,21,4],
[52,22,21,7],
[52,22,21,11],
[52,22,21,12],
[52,22,21,13],
[52,22,21,14],
[52,22,21,16],
[52,22,21,17],
[52,22,21,19],
[52,23,3,0],
[52,23,3,1],
[52,23,3,2],
[52,23,4,0],
[52,23,4,1],
[52,23,4,2],
[52,23,4,3],
[52,23,5,0],
[52,23,5,1],
[52,23,5,2],
[52,23,5,4],
[52,23,6,0],
[52,23,6,1],
[52,23,6,2],
[52,23,6,4],
[52,23,7,0],
[52,23,7,1],
[52,23,7,2],
[52,23,7,3],
[52,23,7,4],
[52,23,7,5],
[52,23,7,6],
[52,23,8,0],
[52,23,8,1],
[52,23,8,2],
[52,23,8,4],
[52,23,8,7],
[52,23,10,0],
[52,23,10,1],
[52,23,10,2],
[52,23,10,3],
[52,23,10,4],
[52,23,10,5],
[52,23,10,6],
[52,23,10,7],
[52,23,10,8],
[52,23,11,0],
[52,23,11,1],
[52,23,11,2],
[52,23,11,3],
[52,23,11,4],
[52,23,11,5],
[52,23,11,6],
[52,23,11,7],
[52,23,11,8],
[52,23,11,10],
[52,23,12,0],
[52,23,12,1],
[52,23,12,2],
[52,23,12,3],
[52,23,12,4],
[52,23,12,5],
[52,23,12,6],
[52,23,12,7],
[52,23,12,8],
[52,23,12,10],
[52,23,13,0],
[52,23,13,1],
[52,23,13,2],
[52,23,13,3],
[52,23,13,4],
[52,23,13,5],
[52,23,13,6],
[52,23,13,7],
[52,23,13,8],
[52,23,13,10],
[52,23,13,11],
[52,23,13,12],
[52,23,14,0],
[52,23,14,1],
[52,23,14,2],
[52,23,14,3],
[52,23,14,4],
[52,23,14,5],
[52,23,14,6],
[52,23,14,7],
[52,23,14,8],
[52,23,14,10],
[52,23,14,11],
[52,23,14,12],
[52,23,16,0],
[52,23,16,1],
[52,23,16,2],
[52,23,16,3],
[52,23,16,4],
[52,23,16,5],
[52,23,16,6],
[52,23,16,7],
[52,23,16,8],
[52,23,16,10],
[52,23,16,11],
[52,23,16,12],
[52,23,16,13],
[52,23,16,14],
[52,23,17,0],
[52,23,17,1],
[52,23,17,2],
[52,23,17,3],
[52,23,17,4],
[52,23,17,5],
[52,23,17,6],
[52,23,17,7],
[52,23,17,8],
[52,23,17,10],
[52,23,17,11],
[52,23,17,12],
[52,23,17,13],
[52,23,17,14],
[52,23,17,16],
[52,23,19,0],
[52,23,19,1],
[52,23,19,2],
[52,23,19,3],
[52,23,19,4],
[52,23,19,5],
[52,23,19,6],
[52,23,19,7],
[52,23,19,8],
[52,23,19,10],
[52,23,19,11],
[52,23,19,12],
[52,23,19,16],
[52,23,19,17],
[52,23,20,0],
[52,23,20,1],
[52,23,20,2],
[52,23,20,3],
[52,23,20,4],
[52,23,20,5],
[52,23,20,6],
[52,23,20,7],
[52,23,20,8],
[52,23,20,11],
[52,23,20,12],
[52,23,20,13],
[52,23,20,14],
[52,23,20,16],
[52,23,20,17],
[52,23,20,19],
[52,23,21,0],
[52,23,21,1],
[52,23,21,2],
[52,23,21,4],
[52,23,21,7],
[52,23,21,10],
[52,23,21,11],
[52,23,21,12],
[52,23,21,13],
[52,23,21,14],
[52,23,21,16],
[52,23,21,17],
[52,23,21,19],
[52,23,21,20],
[52,23,22,0],
[52,23,22,1],
[52,23,22,2],
[52,23,22,3],
[52,23,22,4],
[52,23,22,5],
[52,23,22,6],
[52,23,22,7],
[52,23,22,8],
[52,23,22,11],
[52,23,22,12],
[52,23,22,13],
[52,23,22,14],
[52,23,22,16],
[52,23,22,17],
[52,23,22,19],
[52,23,22,21],
[52,24,3,0],
[52,24,3,1],
[52,24,3,2],
[52,24,4,0],
[52,24,4,1],
[52,24,4,2],
[52,24,4,3],
[52,24,5,0],
[52,24,5,1],
[52,24,5,2],
[52,24,5,4],
[52,24,6,0],
[52,24,6,1],
[52,24,6,2],
[52,24,6,4],
[52,24,7,0],
[52,24,7,1],
[52,24,7,2],
[52,24,7,3],
[52,24,7,4],
[52,24,7,5],
[52,24,7,6],
[52,24,8,0],
[52,24,8,1],
[52,24,8,2],
[52,24,8,4],
[52,24,8,7],
[52,24,10,0],
[52,24,10,1],
[52,24,10,2],
[52,24,10,3],
[52,24,10,4],
[52,24,10,5],
[52,24,10,6],
[52,24,10,7],
[52,24,10,8],
[52,24,11,0],
[52,24,11,1],
[52,24,11,2],
[52,24,11,3],
[52,24,11,4],
[52,24,11,5],
[52,24,11,6],
[52,24,11,7],
[52,24,11,8],
[52,24,11,10],
[52,24,12,0],
[52,24,12,1],
[52,24,12,2],
[52,24,12,3],
[52,24,12,4],
[52,24,12,5],
[52,24,12,6],
[52,24,12,7],
[52,24,12,8],
[52,24,12,10],
[52,24,13,0],
[52,24,13,1],
[52,24,13,2],
[52,24,13,3],
[52,24,13,4],
[52,24,13,5],
[52,24,13,6],
[52,24,13,7],
[52,24,13,8],
[52,24,13,10],
[52,24,13,11],
[52,24,13,12],
[52,24,14,0],
[52,24,14,1],
[52,24,14,2],
[52,24,14,3],
[52,24,14,4],
[52,24,14,5],
[52,24,14,6],
[52,24,14,7],
[52,24,14,8],
[52,24,14,10],
[52,24,14,11],
[52,24,14,12],
[52,24,17,0],
[52,24,17,1],
[52,24,17,2],
[52,24,17,3],
[52,24,17,4],
[52,24,17,5],
[52,24,17,6],
[52,24,17,7],
[52,24,17,8],
[52,24,17,10],
[52,24,17,11],
[52,24,17,12],
[52,24,17,13],
[52,24,17,14],
[52,24,19,0],
[52,24,19,1],
[52,24,19,2],
[52,24,19,3],
[52,24,19,4],
[52,24,19,5],
[52,24,19,6],
[52,24,19,7],
[52,24,19,8],
[52,24,19,10],
[52,24,19,11],
[52,24,19,12],
[52,24,19,17],
[52,24,20,0],
[52,24,20,1],
[52,24,20,2],
[52,24,20,3],
[52,24,20,4],
[52,24,20,5],
[52,24,20,6],
[52,24,20,7],
[52,24,20,8],
[52,24,20,11],
[52,24,20,12],
[52,24,20,13],
[52,24,20,14],
[52,24,20,17],
[52,24,20,19],
[52,24,21,0],
[52,24,21,1],
[52,24,21,2],
[52,24,21,4],
[52,24,21,7],
[52,24,21,10],
[52,24,21,11],
[52,24,21,12],
[52,24,21,13],
[52,24,21,14],
[52,24,21,17],
[52,24,21,19],
[52,24,21,20],
[52,24,22,0],
[52,24,22,1],
[52,24,22,2],
[52,24,22,3],
[52,24,22,4],
[52,24,22,5],
[52,24,22,6],
[52,24,22,7],
[52,24,22,8],
[52,24,22,11],
[52,24,22,12],
[52,24,22,13],
[52,24,22,14],
[52,24,22,17],
[52,24,22,19],
[52,24,22,21],
[52,24,23,0],
[52,24,23,1],
[52,24,23,2],
[52,24,23,3],
[52,24,23,4],
[52,24,23,5],
[52,24,23,6],
[52,24,23,7],
[52,24,23,8],
[52,24,23,10],
[52,24,23,11],
[52,24,23,12],
[52,24,23,13],
[52,24,23,14],
[52,24,23,17],
[52,24,23,19],
[52,24,23,20],
[52,24,23,21],
[52,24,23,22],
[52,25,3,0],
[52,25,3,1],
[52,25,3,2],
[52,25,4,0],
[52,25,4,1],
[52,25,4,2],
[52,25,4,3],
[52,25,5,0],
[52,25,5,1],
[52,25,5,2],
[52,25,5,4],
[52,25,6,0],
[52,25,6,1],
[52,25,6,2],
[52,25,6,4],
[52,25,8,0],
[52,25,8,1],
[52,25,8,2],
[52,25,8,4],
[52,25,10,0],
[52,25,10,1],
[52,25,10,2],
[52,25,10,3],
[52,25,10,4],
[52,25,10,5],
[52,25,10,6],
[52,25,10,8],
[52,25,11,0],
[52,25,11,1],
[52,25,11,2],
[52,25,11,3],
[52,25,11,4],
[52,25,11,5],
[52,25,11,6],
[52,25,11,8],
[52,25,11,10],
[52,25,12,0],
[52,25,12,1],
[52,25,12,2],
[52,25,12,3],
[52,25,12,4],
[52,25,12,5],
[52,25,12,6],
[52,25,12,8],
[52,25,12,10],
[52,25,13,0],
[52,25,13,1],
[52,25,13,2],
[52,25,13,3],
[52,25,13,4],
[52,25,13,5],
[52,25,13,6],
[52,25,13,8],
[52,25,13,10],
[52,25,13,11],
[52,25,13,12],
[52,25,14,0],
[52,25,14,1],
[52,25,14,2],
[52,25,14,3],
[52,25,14,4],
[52,25,14,5],
[52,25,14,6],
[52,25,14,8],
[52,25,14,10],
[52,25,14,11],
[52,25,14,12],
[52,25,16,0],
[52,25,16,1],
[52,25,16,2],
[52,25,16,3],
[52,25,16,4],
[52,25,16,5],
[52,25,16,6],
[52,25,16,8],
[52,25,16,10],
[52,25,16,11],
[52,25,16,12],
[52,25,16,13],
[52,25,16,14],
[52,25,17,0],
[52,25,17,1],
[52,25,17,2],
[52,25,17,3],
[52,25,17,4],
[52,25,17,5],
[52,25,17,6],
[52,25,17,8],
[52,25,17,10],
[52,25,17,11],
[52,25,17,12],
[52,25,17,13],
[52,25,17,14],
[52,25,17,16],
[52,25,19,0],
[52,25,19,1],
[52,25,19,2],
[52,25,19,3],
[52,25,19,4],
[52,25,19,5],
[52,25,19,6],
[52,25,19,8],
[52,25,19,10],
[52,25,19,11],
[52,25,19,12],
[52,25,19,16],
[52,25,19,17],
[52,25,20,0],
[52,25,20,1],
[52,25,20,2],
[52,25,20,3],
[52,25,20,4],
[52,25,20,5],
[52,25,20,6],
[52,25,20,8],
[52,25,20,11],
[52,25,20,12],
[52,25,20,13],
[52,25,20,14],
[52,25,20,16],
[52,25,20,17],
[52,25,20,19],
[52,25,21,0],
[52,25,21,1],
[52,25,21,2],
[52,25,21,4],
[52,25,21,10],
[52,25,21,11],
[52,25,21,12],
[52,25,21,13],
[52,25,21,14],
[52,25,21,16],
[52,25,21,17],
[52,25,21,19],
[52,25,21,20],
[52,25,22,0],
[52,25,22,1],
[52,25,22,2],
[52,25,22,3],
[52,25,22,4],
[52,25,22,5],
[52,25,22,6],
[52,25,22,8],
[52,25,22,11],
[52,25,22,12],
[52,25,22,13],
[52,25,22,14],
[52,25,22,16],
[52,25,22,17],
[52,25,22,19],
[52,25,22,21],
[52,25,23,0],
[52,25,23,1],
[52,25,23,2],
[52,25,23,3],
[52,25,23,4],
[52,25,23,5],
[52,25,23,6],
[52,25,23,8],
[52,25,23,10],
[52,25,23,11],
[52,25,23,12],
[52,25,23,13],
[52,25,23,14],
[52,25,23,16],
[52,25,23,17],
[52,25,23,19],
[52,25,23,20],
[52,25,23,21],
[52,25,23,22],
[52,25,24,0],
[52,25,24,1],
[52,25,24,2],
[52,25,24,3],
[52,25,24,4],
[52,25,24,5],
[52,25,24,6],
[52,25,24,8],
[52,25,24,10],
[52,25,24,11],
[52,25,24,12],
[52,25,24,13],
[52,25,24,14],
[52,25,24,17],
[52,25,24,19],
[52,25,24,20],
[52,25,24,21],
[52,25,24,22],
[52,25,24,23],
[52,27,3,0],
[52,27,3,1],
[52,27,3,2],
[52,27,4,0],
[52,27,4,1],
[52,27,4,2],
[52,27,4,3],
[52,27,5,0],
[52,27,5,1],
[52,27,5,2],
[52,27,5,4],
[52,27,6,0],
[52,27,6,1],
[52,27,6,2],
[52,27,6,4],
[52,27,7,0],
[52,27,7,1],
[52,27,7,2],
[52,27,7,3],
[52,27,7,4],
[52,27,7,5],
[52,27,7,6],
[52,27,8,0],
[52,27,8,1],
[52,27,8,2],
[52,27,8,4],
[52,27,8,7],
[52,27,11,0],
[52,27,11,1],
[52,27,11,2],
[52,27,11,3],
[52,27,11,4],
[52,27,11,5],
[52,27,11,6],
[52,27,11,7],
[52,27,11,8],
[52,27,12,0],
[52,27,12,1],
[52,27,12,2],
[52,27,12,3],
[52,27,12,4],
[52,27,12,5],
[52,27,12,6],
[52,27,12,7],
[52,27,12,8],
[52,27,13,0],
[52,27,13,1],
[52,27,13,2],
[52,27,13,3],
[52,27,13,4],
[52,27,13,5],
[52,27,13,6],
[52,27,13,7],
[52,27,13,8],
[52,27,13,11],
[52,27,13,12],
[52,27,14,0],
[52,27,14,1],
[52,27,14,2],
[52,27,14,3],
[52,27,14,4],
[52,27,14,5],
[52,27,14,6],
[52,27,14,7],
[52,27,14,8],
[52,27,14,11],
[52,27,14,12],
[52,27,16,0],
[52,27,16,1],
[52,27,16,2],
[52,27,16,3],
[52,27,16,4],
[52,27,16,5],
[52,27,16,6],
[52,27,16,7],
[52,27,16,8],
[52,27,16,11],
[52,27,16,12],
[52,27,16,13],
[52,27,16,14],
[52,27,17,0],
[52,27,17,1],
[52,27,17,2],
[52,27,17,3],
[52,27,17,4],
[52,27,17,5],
[52,27,17,6],
[52,27,17,7],
[52,27,17,8],
[52,27,17,11],
[52,27,17,12],
[52,27,17,13],
[52,27,17,14],
[52,27,17,16],
[52,27,19,0],
[52,27,19,1],
[52,27,19,2],
[52,27,19,3],
[52,27,19,4],
[52,27,19,5],
[52,27,19,6],
[52,27,19,7],
[52,27,19,8],
[52,27,19,11],
[52,27,19,12],
[52,27,19,16],
[52,27,19,17],
[52,27,21,0],
[52,27,21,1],
[52,27,21,2],
[52,27,21,4],
[52,27,21,7],
[52,27,21,11],
[52,27,21,12],
[52,27,21,13],
[52,27,21,14],
[52,27,21,16],
[52,27,21,17],
[52,27,21,19],
[52,27,23,0],
[52,27,23,1],
[52,27,23,2],
[52,27,23,3],
[52,27,23,4],
[52,27,23,5],
[52,27,23,6],
[52,27,23,7],
[52,27,23,8],
[52,27,23,11],
[52,27,23,12],
[52,27,23,13],
[52,27,23,14],
[52,27,23,16],
[52,27,23,17],
[52,27,23,19],
[52,27,23,21],
[52,27,24,0],
[52,27,24,1],
[52,27,24,2],
[52,27,24,3],
[52,27,24,4],
[52,27,24,5],
[52,27,24,6],
[52,27,24,7],
[52,27,24,8],
[52,27,24,11],
[52,27,24,12],
[52,27,24,13],
[52,27,24,14],
[52,27,24,17],
[52,27,24,19],
[52,27,24,21],
[52,27,24,23],
[52,27,25,0],
[52,27,25,1],
[52,27,25,2],
[52,27,25,3],
[52,27,25,4],
[52,27,25,5],
[52,27,25,6],
[52,27,25,8],
[52,27,25,11],
[52,27,25,12],
[52,27,25,13],
[52,27,25,14],
[52,27,25,16],
[52,27,25,17],
[52,27,25,19],
[52,27,25,21],
[52,27,25,23],
[52,27,25,24],
[52,28,3,0],
[52,28,3,1],
[52,28,3,2],
[52,28,4,0],
[52,28,4,1],
[52,28,4,2],
[52,28,4,3],
[52,28,5,0],
[52,28,5,1],
[52,28,5,2],
[52,28,5,4],
[52,28,6,0],
[52,28,6,1],
[52,28,6,2],
[52,28,6,4],
[52,28,8,0],
[52,28,8,1],
[52,28,8,2],
[52,28,8,4],
[52,28,10,0],
[52,28,10,1],
[52,28,10,2],
[52,28,10,3],
[52,28,10,4],
[52,28,10,5],
[52,28,10,6],
[52,28,10,8],
[52,28,11,0],
[52,28,11,1],
[52,28,11,2],
[52,28,11,3],
[52,28,11,4],
[52,28,11,5],
[52,28,11,6],
[52,28,11,8],
[52,28,11,10],
[52,28,12,0],
[52,28,12,1],
[52,28,12,2],
[52,28,12,3],
[52,28,12,4],
[52,28,12,5],
[52,28,12,6],
[52,28,12,8],
[52,28,12,10],
[52,28,13,0],
[52,28,13,1],
[52,28,13,2],
[52,28,13,3],
[52,28,13,4],
[52,28,13,5],
[52,28,13,6],
[52,28,13,8],
[52,28,13,10],
[52,28,13,11],
[52,28,13,12],
[52,28,14,0],
[52,28,14,1],
[52,28,14,2],
[52,28,14,3],
[52,28,14,4],
[52,28,14,5],
[52,28,14,6],
[52,28,14,8],
[52,28,14,10],
[52,28,14,11],
[52,28,14,12],
[52,28,16,0],
[52,28,16,1],
[52,28,16,2],
[52,28,16,3],
[52,28,16,4],
[52,28,16,5],
[52,28,16,6],
[52,28,16,8],
[52,28,16,10],
[52,28,16,11],
[52,28,16,12],
[52,28,16,13],
[52,28,16,14],
[52,28,17,0],
[52,28,17,1],
[52,28,17,2],
[52,28,17,3],
[52,28,17,4],
[52,28,17,5],
[52,28,17,6],
[52,28,17,8],
[52,28,17,10],
[52,28,17,11],
[52,28,17,12],
[52,28,17,13],
[52,28,17,14],
[52,28,17,16],
[52,28,19,0],
[52,28,19,1],
[52,28,19,2],
[52,28,19,3],
[52,28,19,4],
[52,28,19,5],
[52,28,19,6],
[52,28,19,8],
[52,28,19,10],
[52,28,19,11],
[52,28,19,12],
[52,28,19,16],
[52,28,19,17],
[52,28,20,0],
[52,28,20,1],
[52,28,20,2],
[52,28,20,3],
[52,28,20,4],
[52,28,20,5],
[52,28,20,6],
[52,28,20,8],
[52,28,20,11],
[52,28,20,12],
[52,28,20,13],
[52,28,20,14],
[52,28,20,16],
[52,28,20,17],
[52,28,20,19],
[52,28,21,0],
[52,28,21,1],
[52,28,21,2],
[52,28,21,4],
[52,28,21,10],
[52,28,21,11],
[52,28,21,12],
[52,28,21,13],
[52,28,21,14],
[52,28,21,16],
[52,28,21,17],
[52,28,21,19],
[52,28,21,20],
[52,28,22,0],
[52,28,22,1],
[52,28,22,2],
[52,28,22,3],
[52,28,22,4],
[52,28,22,5],
[52,28,22,6],
[52,28,22,8],
[52,28,22,11],
[52,28,22,12],
[52,28,22,13],
[52,28,22,14],
[52,28,22,16],
[52,28,22,17],
[52,28,22,19],
[52,28,22,21],
[52,28,23,0],
[52,28,23,1],
[52,28,23,2],
[52,28,23,3],
[52,28,23,4],
[52,28,23,5],
[52,28,23,6],
[52,28,23,8],
[52,28,23,10],
[52,28,23,11],
[52,28,23,12],
[52,28,23,13],
[52,28,23,14],
[52,28,23,16],
[52,28,23,17],
[52,28,23,19],
[52,28,23,20],
[52,28,23,21],
[52,28,23,22],
[52,28,24,0],
[52,28,24,1],
[52,28,24,2],
[52,28,24,3],
[52,28,24,4],
[52,28,24,5],
[52,28,24,6],
[52,28,24,8],
[52,28,24,10],
[52,28,24,11],
[52,28,24,12],
[52,28,24,13],
[52,28,24,14],
[52,28,24,17],
[52,28,24,19],
[52,28,24,20],
[52,28,24,21],
[52,28,24,22],
[52,28,24,23],
[52,28,27,0],
[52,28,27,1],
[52,28,27,2],
[52,28,27,3],
[52,28,27,4],
[52,28,27,5],
[52,28,27,6],
[52,28,27,8],
[52,28,27,11],
[52,28,27,12],
[52,28,27,13],
[52,28,27,14],
[52,28,27,16],
[52,28,27,17],
[52,28,27,19],
[52,28,27,21],
[52,28,27,23],
[52,28,27,24],
[52,29,3,0],
[52,29,3,1],
[52,29,3,2],
[52,29,4,0],
[52,29,4,1],
[52,29,4,2],
[52,29,4,3],
[52,29,5,0],
[52,29,5,1],
[52,29,5,2],
[52,29,5,4],
[52,29,6,0],
[52,29,6,1],
[52,29,6,2],
[52,29,6,4],
[52,29,7,0],
[52,29,7,1],
[52,29,7,2],
[52,29,7,3],
[52,29,7,4],
[52,29,7,5],
[52,29,7,6],
[52,29,8,0],
[52,29,8,1],
[52,29,8,2],
[52,29,8,4],
[52,29,8,7],
[52,29,10,0],
[52,29,10,1],
[52,29,10,2],
[52,29,10,3],
[52,29,10,4],
[52,29,10,5],
[52,29,10,6],
[52,29,10,7],
[52,29,10,8],
[52,29,13,0],
[52,29,13,1],
[52,29,13,2],
[52,29,13,3],
[52,29,13,4],
[52,29,13,5],
[52,29,13,6],
[52,29,13,7],
[52,29,13,8],
[52,29,13,10],
[52,29,14,0],
[52,29,14,1],
[52,29,14,2],
[52,29,14,3],
[52,29,14,4],
[52,29,14,5],
[52,29,14,6],
[52,29,14,7],
[52,29,14,8],
[52,29,14,10],
[52,29,16,0],
[52,29,16,1],
[52,29,16,2],
[52,29,16,3],
[52,29,16,4],
[52,29,16,5],
[52,29,16,6],
[52,29,16,7],
[52,29,16,8],
[52,29,16,10],
[52,29,16,13],
[52,29,16,14],
[52,29,17,0],
[52,29,17,1],
[52,29,17,2],
[52,29,17,3],
[52,29,17,4],
[52,29,17,5],
[52,29,17,6],
[52,29,17,7],
[52,29,17,8],
[52,29,17,10],
[52,29,17,13],
[52,29,17,14],
[52,29,17,16],
[52,29,19,0],
[52,29,19,1],
[52,29,19,2],
[52,29,19,3],
[52,29,19,4],
[52,29,19,5],
[52,29,19,6],
[52,29,19,7],
[52,29,19,8],
[52,29,19,10],
[52,29,19,16],
[52,29,19,17],
[52,29,20,0],
[52,29,20,1],
[52,29,20,2],
[52,29,20,3],
[52,29,20,4],
[52,29,20,5],
[52,29,20,6],
[52,29,20,7],
[52,29,20,8],
[52,29,20,13],
[52,29,20,14],
[52,29,20,16],
[52,29,20,17],
[52,29,20,19],
[52,29,21,0],
[52,29,21,1],
[52,29,21,2],
[52,29,21,4],
[52,29,21,7],
[52,29,21,10],
[52,29,21,13],
[52,29,21,14],
[52,29,21,16],
[52,29,21,17],
[52,29,21,19],
[52,29,21,20],
[52,29,22,0],
[52,29,22,1],
[52,29,22,2],
[52,29,22,3],
[52,29,22,4],
[52,29,22,5],
[52,29,22,6],
[52,29,22,7],
[52,29,22,8],
[52,29,22,13],
[52,29,22,14],
[52,29,22,16],
[52,29,22,17],
[52,29,22,19],
[52,29,22,21],
[52,29,23,0],
[52,29,23,1],
[52,29,23,2],
[52,29,23,3],
[52,29,23,4],
[52,29,23,5],
[52,29,23,6],
[52,29,23,7],
[52,29,23,8],
[52,29,23,10],
[52,29,23,13],
[52,29,23,14],
[52,29,23,16],
[52,29,23,17],
[52,29,23,19],
[52,29,23,20],
[52,29,23,21],
[52,29,23,22],
[52,29,24,0],
[52,29,24,1],
[52,29,24,2],
[52,29,24,3],
[52,29,24,4],
[52,29,24,5],
[52,29,24,6],
[52,29,24,7],
[52,29,24,8],
[52,29,24,10],
[52,29,24,13],
[52,29,24,14],
[52,29,24,17],
[52,29,24,19],
[52,29,24,20],
[52,29,24,21],
[52,29,24,22],
[52,29,24,23],
[52,29,25,0],
[52,29,25,1],
[52,29,25,2],
[52,29,25,3],
[52,29,25,4],
[52,29,25,5],
[52,29,25,6],
[52,29,25,8],
[52,29,25,10],
[52,29,25,13],
[52,29,25,14],
[52,29,25,16],
[52,29,25,17],
[52,29,25,19],
[52,29,25,20],
[52,29,25,21],
[52,29,25,22],
[52,29,25,23],
[52,29,25,24],
[52,29,27,0],
[52,29,27,1],
[52,29,27,2],
[52,29,27,3],
[52,29,27,4],
[52,29,27,5],
[52,29,27,6],
[52,29,27,7],
[52,29,27,8],
[52,29,27,13],
[52,29,27,14],
[52,29,27,16],
[52,29,27,17],
[52,29,27,19],
[52,29,27,21],
[52,29,27,23],
[52,29,27,24],
[52,29,27,25],
[52,29,28,0],
[52,29,28,1],
[52,29,28,2],
[52,29,28,3],
[52,29,28,4],
[52,29,28,5],
[52,29,28,6],
[52,29,28,8],
[52,29,28,10],
[52,29,28,13],
[52,29,28,14],
[52,29,28,16],
[52,29,28,17],
[52,29,28,19],
[52,29,28,20],
[52,29,28,21],
[52,29,28,22],
[52,29,28,27],
[52,30,3,0],
[52,30,3,1],
[52,30,3,2],
[52,30,4,0],
[52,30,4,1],
[52,30,4,2],
[52,30,4,3],
[52,30,5,0],
[52,30,5,1],
[52,30,5,2],
[52,30,5,4],
[52,30,6,0],
[52,30,6,1],
[52,30,6,2],
[52,30,6,4],
[52,30,7,0],
[52,30,7,1],
[52,30,7,2],
[52,30,7,3],
[52,30,7,4],
[52,30,7,5],
[52,30,7,6],
[52,30,8,0],
[52,30,8,1],
[52,30,8,2],
[52,30,8,4],
[52,30,8,7],
[52,30,10,0],
[52,30,10,1],
[52,30,10,2],
[52,30,10,3],
[52,30,10,4],
[52,30,10,5],
[52,30,10,6],
[52,30,10,7],
[52,30,10,8],
[52,30,11,0],
[52,30,11,1],
[52,30,11,2],
[52,30,11,3],
[52,30,11,4],
[52,30,11,5],
[52,30,11,6],
[52,30,11,7],
[52,30,11,8],
[52,30,11,10],
[52,30,12,0],
[52,30,12,1],
[52,30,12,2],
[52,30,12,3],
[52,30,12,4],
[52,30,12,5],
[52,30,12,6],
[52,30,12,7],
[52,30,12,8],
[52,30,12,10],
[52,30,13,0],
[52,30,13,1],
[52,30,13,2],
[52,30,13,3],
[52,30,13,4],
[52,30,13,5],
[52,30,13,6],
[52,30,13,7],
[52,30,13,8],
[52,30,13,10],
[52,30,13,11],
[52,30,13,12],
[52,30,14,0],
[52,30,14,1],
[52,30,14,2],
[52,30,14,3],
[52,30,14,4],
[52,30,14,5],
[52,30,14,6],
[52,30,14,7],
[52,30,14,8],
[52,30,14,10],
[52,30,14,11],
[52,30,14,12],
[52,30,16,0],
[52,30,16,1],
[52,30,16,2],
[52,30,16,3],
[52,30,16,4],
[52,30,16,5],
[52,30,16,6],
[52,30,16,7],
[52,30,16,8],
[52,30,16,10],
[52,30,16,11],
[52,30,16,12],
[52,30,16,13],
[52,30,16,14],
[52,30,17,0],
[52,30,17,1],
[52,30,17,2],
[52,30,17,3],
[52,30,17,4],
[52,30,17,5],
[52,30,17,6],
[52,30,17,7],
[52,30,17,8],
[52,30,17,10],
[52,30,17,11],
[52,30,17,12],
[52,30,17,13],
[52,30,17,14],
[52,30,17,16],
[52,30,19,0],
[52,30,19,1],
[52,30,19,2],
[52,30,19,3],
[52,30,19,4],
[52,30,19,5],
[52,30,19,6],
[52,30,19,7],
[52,30,19,8],
[52,30,19,10],
[52,30,19,11],
[52,30,19,12],
[52,30,19,16],
[52,30,19,17],
[52,30,20,0],
[52,30,20,1],
[52,30,20,2],
[52,30,20,3],
[52,30,20,4],
[52,30,20,5],
[52,30,20,6],
[52,30,20,7],
[52,30,20,8],
[52,30,20,11],
[52,30,20,12],
[52,30,20,13],
[52,30,20,14],
[52,30,20,16],
[52,30,20,17],
[52,30,20,19],
[52,30,21,0],
[52,30,21,1],
[52,30,21,2],
[52,30,21,4],
[52,30,21,7],
[52,30,21,10],
[52,30,21,11],
[52,30,21,12],
[52,30,21,13],
[52,30,21,14],
[52,30,21,16],
[52,30,21,17],
[52,30,21,19],
[52,30,21,20],
[52,30,22,0],
[52,30,22,1],
[52,30,22,2],
[52,30,22,3],
[52,30,22,4],
[52,30,22,5],
[52,30,22,6],
[52,30,22,7],
[52,30,22,8],
[52,30,22,11],
[52,30,22,12],
[52,30,22,13],
[52,30,22,14],
[52,30,22,16],
[52,30,22,17],
[52,30,22,19],
[52,30,22,21],
[52,30,24,0],
[52,30,24,1],
[52,30,24,2],
[52,30,24,3],
[52,30,24,4],
[52,30,24,5],
[52,30,24,6],
[52,30,24,7],
[52,30,24,8],
[52,30,24,10],
[52,30,24,11],
[52,30,24,12],
[52,30,24,13],
[52,30,24,14],
[52,30,24,17],
[52,30,24,19],
[52,30,24,20],
[52,30,24,21],
[52,30,24,22],
[52,30,25,0],
[52,30,25,1],
[52,30,25,2],
[52,30,25,3],
[52,30,25,4],
[52,30,25,5],
[52,30,25,6],
[52,30,25,8],
[52,30,25,10],
[52,30,25,11],
[52,30,25,12],
[52,30,25,13],
[52,30,25,14],
[52,30,25,16],
[52,30,25,17],
[52,30,25,19],
[52,30,25,20],
[52,30,25,21],
[52,30,25,22],
[52,30,25,24],
[52,30,27,0],
[52,30,27,1],
[52,30,27,2],
[52,30,27,3],
[52,30,27,4],
[52,30,27,5],
[52,30,27,6],
[52,30,27,7],
[52,30,27,8],
[52,30,27,11],
[52,30,27,12],
[52,30,27,13],
[52,30,27,14],
[52,30,27,16],
[52,30,27,17],
[52,30,27,19],
[52,30,27,21],
[52,30,27,25],
[52,30,28,0],
[52,30,28,1],
[52,30,28,2],
[52,30,28,3],
[52,30,28,4],
[52,30,28,5],
[52,30,28,6],
[52,30,28,8],
[52,30,28,10],
[52,30,28,11],
[52,30,28,12],
[52,30,28,13],
[52,30,28,14],
[52,30,28,16],
[52,30,28,17],
[52,30,28,19],
[52,30,28,20],
[52,30,28,21],
[52,30,28,24],
[52,30,28,27],
[52,30,29,0],
[52,30,29,1],
[52,30,29,2],
[52,30,29,3],
[52,30,29,4],
[52,30,29,5],
[52,30,29,6],
[52,30,29,7],
[52,30,29,8],
[52,30,29,10],
[52,30,29,13],
[52,30,29,14],
[52,30,29,16],
[52,30,29,17],
[52,30,29,19],
[52,30,29,20],
[52,30,29,22],
[52,30,29,24],
[52,30,29,25],
[52,30,29,27],
[52,30,29,28],
[52,31,3,0],
[52,31,3,1],
[52,31,3,2],
[52,31,4,0],
[52,31,4,1],
[52,31,4,2],
[52,31,4,3],
[52,31,5,0],
[52,31,5,1],
[52,31,5,2],
[52,31,5,4],
[52,31,6,0],
[52,31,6,1],
[52,31,6,2],
[52,31,6,4],
[52,31,7,0],
[52,31,7,1],
[52,31,7,2],
[52,31,7,3],
[52,31,7,4],
[52,31,7,5],
[52,31,7,6],
[52,31,8,0],
[52,31,8,1],
[52,31,8,2],
[52,31,8,4],
[52,31,8,7],
[52,31,10,0],
[52,31,10,1],
[52,31,10,2],
[52,31,10,3],
[52,31,10,4],
[52,31,10,5],
[52,31,10,6],
[52,31,10,7],
[52,31,10,8],
[52,31,11,0],
[52,31,11,1],
[52,31,11,2],
[52,31,11,3],
[52,31,11,4],
[52,31,11,5],
[52,31,11,6],
[52,31,11,7],
[52,31,11,8],
[52,31,11,10],
[52,31,12,0],
[52,31,12,1],
[52,31,12,2],
[52,31,12,3],
[52,31,12,4],
[52,31,12,5],
[52,31,12,6],
[52,31,12,7],
[52,31,12,8],
[52,31,12,10],
[52,31,13,0],
[52,31,13,1],
[52,31,13,2],
[52,31,13,3],
[52,31,13,4],
[52,31,13,5],
[52,31,13,6],
[52,31,13,7],
[52,31,13,8],
[52,31,13,10],
[52,31,13,11],
[52,31,13,12],
[52,31,14,0],
[52,31,14,1],
[52,31,14,2],
[52,31,14,3],
[52,31,14,4],
[52,31,14,5],
[52,31,14,6],
[52,31,14,7],
[52,31,14,8],
[52,31,14,10],
[52,31,14,11],
[52,31,14,12],
[52,31,16,0],
[52,31,16,1],
[52,31,16,2],
[52,31,16,3],
[52,31,16,4],
[52,31,16,5],
[52,31,16,6],
[52,31,16,7],
[52,31,16,8],
[52,31,16,10],
[52,31,16,11],
[52,31,16,12],
[52,31,16,13],
[52,31,16,14],
[52,31,17,0],
[52,31,17,1],
[52,31,17,2],
[52,31,17,3],
[52,31,17,4],
[52,31,17,5],
[52,31,17,6],
[52,31,17,7],
[52,31,17,8],
[52,31,17,10],
[52,31,17,11],
[52,31,17,12],
[52,31,17,13],
[52,31,17,14],
[52,31,17,16],
[52,31,19,0],
[52,31,19,1],
[52,31,19,2],
[52,31,19,3],
[52,31,19,4],
[52,31,19,5],
[52,31,19,6],
[52,31,19,7],
[52,31,19,8],
[52,31,19,10],
[52,31,19,11],
[52,31,19,12],
[52,31,19,16],
[52,31,19,17],
[52,31,20,0],
[52,31,20,1],
[52,31,20,2],
[52,31,20,3],
[52,31,20,4],
[52,31,20,5],
[52,31,20,6],
[52,31,20,7],
[52,31,20,8],
[52,31,20,11],
[52,31,20,12],
[52,31,20,13],
[52,31,20,14],
[52,31,20,16],
[52,31,20,17],
[52,31,20,19],
[52,31,21,0],
[52,31,21,1],
[52,31,21,2],
[52,31,21,4],
[52,31,21,7],
[52,31,21,10],
[52,31,21,11],
[52,31,21,12],
[52,31,21,13],
[52,31,21,14],
[52,31,21,16],
[52,31,21,17],
[52,31,21,19],
[52,31,21,20],
[52,31,22,0],
[52,31,22,1],
[52,31,22,2],
[52,31,22,3],
[52,31,22,4],
[52,31,22,5],
[52,31,22,6],
[52,31,22,7],
[52,31,22,8],
[52,31,22,11],
[52,31,22,12],
[52,31,22,13],
[52,31,22,14],
[52,31,22,16],
[52,31,22,17],
[52,31,22,19],
[52,31,22,21],
[52,31,24,0],
[52,31,24,1],
[52,31,24,2],
[52,31,24,3],
[52,31,24,4],
[52,31,24,5],
[52,31,24,6],
[52,31,24,7],
[52,31,24,8],
[52,31,24,10],
[52,31,24,11],
[52,31,24,12],
[52,31,24,13],
[52,31,24,14],
[52,31,24,17],
[52,31,24,19],
[52,31,24,20],
[52,31,24,21],
[52,31,24,22],
[52,31,25,0],
[52,31,25,1],
[52,31,25,2],
[52,31,25,3],
[52,31,25,4],
[52,31,25,5],
[52,31,25,6],
[52,31,25,8],
[52,31,25,10],
[52,31,25,11],
[52,31,25,12],
[52,31,25,13],
[52,31,25,14],
[52,31,25,16],
[52,31,25,17],
[52,31,25,19],
[52,31,25,20],
[52,31,25,21],
[52,31,25,22],
[52,31,25,24],
[52,31,27,0],
[52,31,27,1],
[52,31,27,2],
[52,31,27,3],
[52,31,27,4],
[52,31,27,5],
[52,31,27,6],
[52,31,27,7],
[52,31,27,8],
[52,31,27,11],
[52,31,27,12],
[52,31,27,13],
[52,31,27,14],
[52,31,27,16],
[52,31,27,17],
[52,31,27,19],
[52,31,27,21],
[52,31,27,24],
[52,31,27,25],
[52,31,28,0],
[52,31,28,1],
[52,31,28,2],
[52,31,28,3],
[52,31,28,4],
[52,31,28,5],
[52,31,28,6],
[52,31,28,8],
[52,31,28,10],
[52,31,28,11],
[52,31,28,12],
[52,31,28,13],
[52,31,28,14],
[52,31,28,16],
[52,31,28,17],
[52,31,28,19],
[52,31,28,20],
[52,31,28,22],
[52,31,28,24],
[52,31,28,27],
[52,31,29,0],
[52,31,29,1],
[52,31,29,2],
[52,31,29,3],
[52,31,29,4],
[52,31,29,5],
[52,31,29,6],
[52,31,29,7],
[52,31,29,8],
[52,31,29,10],
[52,31,29,13],
[52,31,29,14],
[52,31,29,16],
[52,31,29,17],
[52,31,29,19],
[52,31,29,21],
[52,31,29,22],
[52,31,29,24],
[52,31,29,25],
[52,31,29,27],
[52,31,29,28],
[52,32,3,0],
[52,32,3,1],
[52,32,3,2],
[52,32,4,0],
[52,32,4,1],
[52,32,4,2],
[52,32,4,3],
[52,32,5,0],
[52,32,5,1],
[52,32,5,2],
[52,32,5,4],
[52,32,6,0],
[52,32,6,1],
[52,32,6,2],
[52,32,6,4],
[52,32,7,0],
[52,32,7,1],
[52,32,7,2],
[52,32,7,3],
[52,32,7,4],
[52,32,7,5],
[52,32,7,6],
[52,32,8,0],
[52,32,8,1],
[52,32,8,2],
[52,32,8,4],
[52,32,8,7],
[52,32,10,0],
[52,32,10,1],
[52,32,10,2],
[52,32,10,3],
[52,32,10,4],
[52,32,10,5],
[52,32,10,6],
[52,32,10,7],
[52,32,10,8],
[52,32,13,0],
[52,32,13,1],
[52,32,13,2],
[52,32,13,3],
[52,32,13,4],
[52,32,13,5],
[52,32,13,6],
[52,32,13,7],
[52,32,13,8],
[52,32,13,10],
[52,32,14,0],
[52,32,14,1],
[52,32,14,2],
[52,32,14,3],
[52,32,14,4],
[52,32,14,5],
[52,32,14,6],
[52,32,14,7],
[52,32,14,8],
[52,32,14,10],
[52,32,16,0],
[52,32,16,1],
[52,32,16,2],
[52,32,16,3],
[52,32,16,4],
[52,32,16,5],
[52,32,16,6],
[52,32,16,7],
[52,32,16,8],
[52,32,16,10],
[52,32,16,13],
[52,32,16,14],
[52,32,17,0],
[52,32,17,1],
[52,32,17,2],
[52,32,17,3],
[52,32,17,4],
[52,32,17,5],
[52,32,17,6],
[52,32,17,7],
[52,32,17,8],
[52,32,17,10],
[52,32,17,13],
[52,32,17,14],
[52,32,17,16],
[52,32,19,0],
[52,32,19,1],
[52,32,19,2],
[52,32,19,3],
[52,32,19,4],
[52,32,19,5],
[52,32,19,6],
[52,32,19,7],
[52,32,19,8],
[52,32,19,10],
[52,32,19,16],
[52,32,19,17],
[52,32,20,0],
[52,32,20,1],
[52,32,20,2],
[52,32,20,3],
[52,32,20,4],
[52,32,20,5],
[52,32,20,6],
[52,32,20,7],
[52,32,20,8],
[52,32,20,13],
[52,32,20,14],
[52,32,20,16],
[52,32,20,17],
[52,32,20,19],
[52,32,21,0],
[52,32,21,1],
[52,32,21,2],
[52,32,21,4],
[52,32,21,7],
[52,32,21,10],
[52,32,21,13],
[52,32,21,14],
[52,32,21,16],
[52,32,21,17],
[52,32,21,19],
[52,32,21,20],
[52,32,22,0],
[52,32,22,1],
[52,32,22,2],
[52,32,22,3],
[52,32,22,4],
[52,32,22,5],
[52,32,22,6],
[52,32,22,7],
[52,32,22,8],
[52,32,22,13],
[52,32,22,14],
[52,32,22,16],
[52,32,22,17],
[52,32,22,19],
[52,32,22,21],
[52,32,23,0],
[52,32,23,1],
[52,32,23,2],
[52,32,23,3],
[52,32,23,4],
[52,32,23,5],
[52,32,23,6],
[52,32,23,7],
[52,32,23,8],
[52,32,23,10],
[52,32,23,13],
[52,32,23,14],
[52,32,23,16],
[52,32,23,17],
[52,32,23,19],
[52,32,23,20],
[52,32,23,21],
[52,32,23,22],
[52,32,24,0],
[52,32,24,1],
[52,32,24,2],
[52,32,24,3],
[52,32,24,4],
[52,32,24,5],
[52,32,24,6],
[52,32,24,7],
[52,32,24,8],
[52,32,24,10],
[52,32,24,13],
[52,32,24,14],
[52,32,24,17],
[52,32,24,19],
[52,32,24,20],
[52,32,24,21],
[52,32,24,22],
[52,32,24,23],
[52,32,25,0],
[52,32,25,1],
[52,32,25,2],
[52,32,25,3],
[52,32,25,4],
[52,32,25,5],
[52,32,25,6],
[52,32,25,8],
[52,32,25,10],
[52,32,25,13],
[52,32,25,14],
[52,32,25,16],
[52,32,25,17],
[52,32,25,19],
[52,32,25,20],
[52,32,25,21],
[52,32,25,22],
[52,32,27,0],
[52,32,27,1],
[52,32,27,2],
[52,32,27,3],
[52,32,27,4],
[52,32,27,5],
[52,32,27,6],
[52,32,27,7],
[52,32,27,8],
[52,32,27,13],
[52,32,27,14],
[52,32,27,16],
[52,32,27,17],
[52,32,27,19],
[52,32,27,23],
[52,32,27,24],
[52,32,27,25],
[52,32,28,0],
[52,32,28,1],
[52,32,28,2],
[52,32,28,3],
[52,32,28,4],
[52,32,28,5],
[52,32,28,6],
[52,32,28,8],
[52,32,28,10],
[52,32,28,13],
[52,32,28,14],
[52,32,28,16],
[52,32,28,17],
[52,32,28,19],
[52,32,28,21],
[52,32,28,22],
[52,32,28,23],
[52,32,28,24],
[52,32,28,27],
[52,32,30,0],
[52,32,30,1],
[52,32,30,2],
[52,32,30,3],
[52,32,30,4],
[52,32,30,5],
[52,32,30,6],
[52,32,30,7],
[52,32,30,8],
[52,32,30,10],
[52,32,30,13],
[52,32,30,14],
[52,32,30,16],
[52,32,30,17],
[52,32,30,19],
[52,32,30,20],
[52,32,30,21],
[52,32,30,22],
[52,32,30,24],
[52,32,30,25],
[52,32,30,27],
[52,32,30,28],
[52,32,31,0],
[52,32,31,1],
[52,32,31,2],
[52,32,31,3],
[52,32,31,4],
[52,32,31,5],
[52,32,31,6],
[52,32,31,7],
[52,32,31,8],
[52,32,31,10],
[52,32,31,13],
[52,32,31,14],
[52,32,31,19],
[52,32,31,20],
[52,32,31,21],
[52,32,31,22],
[52,32,31,24],
[52,32,31,25],
[52,32,31,27],
[52,32,31,28],
[52,33,3,0],
[52,33,3,1],
[52,33,3,2],
[52,33,4,0],
[52,33,4,1],
[52,33,4,2],
[52,33,4,3],
[52,33,5,0],
[52,33,5,1],
[52,33,5,2],
[52,33,5,4],
[52,33,6,0],
[52,33,6,1],
[52,33,6,2],
[52,33,6,4],
[52,33,7,0],
[52,33,7,1],
[52,33,7,2],
[52,33,7,3],
[52,33,7,4],
[52,33,7,5],
[52,33,7,6],
[52,33,8,0],
[52,33,8,1],
[52,33,8,2],
[52,33,8,4],
[52,33,8,7],
[52,33,10,0],
[52,33,10,1],
[52,33,10,2],
[52,33,10,3],
[52,33,10,4],
[52,33,10,5],
[52,33,10,6],
[52,33,10,7],
[52,33,10,8],
[52,33,13,0],
[52,33,13,1],
[52,33,13,2],
[52,33,13,3],
[52,33,13,4],
[52,33,13,5],
[52,33,13,6],
[52,33,13,7],
[52,33,13,8],
[52,33,13,10],
[52,33,14,0],
[52,33,14,1],
[52,33,14,2],
[52,33,14,3],
[52,33,14,4],
[52,33,14,5],
[52,33,14,6],
[52,33,14,7],
[52,33,14,8],
[52,33,14,10],
[52,33,16,0],
[52,33,16,1],
[52,33,16,2],
[52,33,16,3],
[52,33,16,4],
[52,33,16,5],
[52,33,16,6],
[52,33,16,7],
[52,33,16,8],
[52,33,16,10],
[52,33,16,13],
[52,33,16,14],
[52,33,17,0],
[52,33,17,1],
[52,33,17,2],
[52,33,17,3],
[52,33,17,4],
[52,33,17,5],
[52,33,17,6],
[52,33,17,7],
[52,33,17,8],
[52,33,17,10],
[52,33,17,13],
[52,33,17,14],
[52,33,17,16],
[52,33,19,0],
[52,33,19,1],
[52,33,19,2],
[52,33,19,3],
[52,33,19,4],
[52,33,19,5],
[52,33,19,6],
[52,33,19,7],
[52,33,19,8],
[52,33,19,10],
[52,33,19,16],
[52,33,19,17],
[52,33,20,0],
[52,33,20,1],
[52,33,20,2],
[52,33,20,3],
[52,33,20,4],
[52,33,20,5],
[52,33,20,6],
[52,33,20,7],
[52,33,20,8],
[52,33,20,13],
[52,33,20,14],
[52,33,20,16],
[52,33,20,17],
[52,33,20,19],
[52,33,21,0],
[52,33,21,1],
[52,33,21,2],
[52,33,21,4],
[52,33,21,7],
[52,33,21,10],
[52,33,21,13],
[52,33,21,14],
[52,33,21,16],
[52,33,21,17],
[52,33,21,19],
[52,33,21,20],
[52,33,22,0],
[52,33,22,1],
[52,33,22,2],
[52,33,22,3],
[52,33,22,4],
[52,33,22,5],
[52,33,22,6],
[52,33,22,7],
[52,33,22,8],
[52,33,22,13],
[52,33,22,14],
[52,33,22,16],
[52,33,22,17],
[52,33,22,19],
[52,33,22,21],
[52,33,23,0],
[52,33,23,1],
[52,33,23,2],
[52,33,23,3],
[52,33,23,4],
[52,33,23,5],
[52,33,23,6],
[52,33,23,7],
[52,33,23,8],
[52,33,23,10],
[52,33,23,13],
[52,33,23,14],
[52,33,23,16],
[52,33,23,17],
[52,33,23,19],
[52,33,23,20],
[52,33,23,21],
[52,33,23,22],
[52,33,24,0],
[52,33,24,1],
[52,33,24,2],
[52,33,24,3],
[52,33,24,4],
[52,33,24,5],
[52,33,24,6],
[52,33,24,7],
[52,33,24,8],
[52,33,24,10],
[52,33,24,13],
[52,33,24,14],
[52,33,24,17],
[52,33,24,19],
[52,33,24,20],
[52,33,24,21],
[52,33,24,22],
[52,33,24,23],
[52,33,25,0],
[52,33,25,1],
[52,33,25,2],
[52,33,25,3],
[52,33,25,4],
[52,33,25,5],
[52,33,25,6],
[52,33,25,8],
[52,33,25,10],
[52,33,25,13],
[52,33,25,14],
[52,33,25,16],
[52,33,25,17],
[52,33,25,19],
[52,33,25,20],
[52,33,25,21],
[52,33,25,23],
[52,33,25,24],
[52,33,27,0],
[52,33,27,1],
[52,33,27,2],
[52,33,27,3],
[52,33,27,4],
[52,33,27,5],
[52,33,27,6],
[52,33,27,7],
[52,33,27,8],
[52,33,27,13],
[52,33,27,14],
[52,33,27,16],
[52,33,27,17],
[52,33,27,19],
[52,33,27,21],
[52,33,27,23],
[52,33,27,24],
[52,33,27,25],
[52,33,28,0],
[52,33,28,1],
[52,33,28,2],
[52,33,28,3],
[52,33,28,4],
[52,33,28,5],
[52,33,28,6],
[52,33,28,8],
[52,33,28,10],
[52,33,28,13],
[52,33,28,14],
[52,33,28,16],
[52,33,28,17],
[52,33,28,20],
[52,33,28,21],
[52,33,28,22],
[52,33,28,23],
[52,33,28,24],
[52,33,28,27],
[52,33,30,0],
[52,33,30,1],
[52,33,30,2],
[52,33,30,3],
[52,33,30,4],
[52,33,30,5],
[52,33,30,6],
[52,33,30,7],
[52,33,30,8],
[52,33,30,10],
[52,33,30,13],
[52,33,30,14],
[52,33,30,19],
[52,33,30,20],
[52,33,30,21],
[52,33,30,22],
[52,33,30,24],
[52,33,30,25],
[52,33,30,27],
[52,33,30,28],
[52,33,31,0],
[52,33,31,1],
[52,33,31,2],
[52,33,31,3],
[52,33,31,4],
[52,33,31,5],
[52,33,31,6],
[52,33,31,7],
[52,33,31,8],
[52,33,31,10],
[52,33,31,13],
[52,33,31,14],
[52,33,31,16],
[52,33,31,17],
[52,33,31,19],
[52,33,31,20],
[52,33,31,21],
[52,33,31,22],
[52,33,31,24],
[52,33,31,25],
[52,33,31,27],
[52,33,31,28],
[52,34,3,0],
[52,34,3,1],
[52,34,3,2],
[52,34,4,0],
[52,34,4,1],
[52,34,4,2],
[52,34,4,3],
[52,34,5,0],
[52,34,5,1],
[52,34,5,2],
[52,34,5,4],
[52,34,6,0],
[52,34,6,1],
[52,34,6,2],
[52,34,6,4],
[52,34,8,0],
[52,34,8,1],
[52,34,8,2],
[52,34,8,4],
[52,34,10,0],
[52,34,10,1],
[52,34,10,2],
[52,34,10,3],
[52,34,10,4],
[52,34,10,5],
[52,34,10,6],
[52,34,10,8],
[52,34,11,0],
[52,34,11,1],
[52,34,11,2],
[52,34,11,3],
[52,34,11,4],
[52,34,11,5],
[52,34,11,6],
[52,34,11,8],
[52,34,11,10],
[52,34,12,0],
[52,34,12,1],
[52,34,12,2],
[52,34,12,3],
[52,34,12,4],
[52,34,12,5],
[52,34,12,6],
[52,34,12,8],
[52,34,12,10],
[52,34,13,0],
[52,34,13,1],
[52,34,13,2],
[52,34,13,3],
[52,34,13,4],
[52,34,13,5],
[52,34,13,6],
[52,34,13,8],
[52,34,13,10],
[52,34,13,11],
[52,34,13,12],
[52,34,14,0],
[52,34,14,1],
[52,34,14,2],
[52,34,14,3],
[52,34,14,4],
[52,34,14,5],
[52,34,14,6],
[52,34,14,8],
[52,34,14,10],
[52,34,14,11],
[52,34,14,12],
[52,34,16,0],
[52,34,16,1],
[52,34,16,2],
[52,34,16,3],
[52,34,16,4],
[52,34,16,5],
[52,34,16,6],
[52,34,16,8],
[52,34,16,10],
[52,34,16,11],
[52,34,16,12],
[52,34,16,13],
[52,34,16,14],
[52,34,17,0],
[52,34,17,1],
[52,34,17,2],
[52,34,17,3],
[52,34,17,4],
[52,34,17,5],
[52,34,17,6],
[52,34,17,8],
[52,34,17,10],
[52,34,17,11],
[52,34,17,12],
[52,34,17,13],
[52,34,17,14],
[52,34,17,16],
[52,34,19,0],
[52,34,19,1],
[52,34,19,2],
[52,34,19,3],
[52,34,19,4],
[52,34,19,5],
[52,34,19,6],
[52,34,19,8],
[52,34,19,10],
[52,34,19,11],
[52,34,19,12],
[52,34,19,16],
[52,34,19,17],
[52,34,20,0],
[52,34,20,1],
[52,34,20,2],
[52,34,20,3],
[52,34,20,4],
[52,34,20,5],
[52,34,20,6],
[52,34,20,8],
[52,34,20,11],
[52,34,20,12],
[52,34,20,13],
[52,34,20,14],
[52,34,20,16],
[52,34,20,17],
[52,34,20,19],
[52,34,21,0],
[52,34,21,1],
[52,34,21,2],
[52,34,21,4],
[52,34,21,10],
[52,34,21,11],
[52,34,21,12],
[52,34,21,13],
[52,34,21,14],
[52,34,21,16],
[52,34,21,17],
[52,34,21,19],
[52,34,21,20],
[52,34,22,0],
[52,34,22,1],
[52,34,22,2],
[52,34,22,3],
[52,34,22,4],
[52,34,22,5],
[52,34,22,6],
[52,34,22,8],
[52,34,22,11],
[52,34,22,12],
[52,34,22,13],
[52,34,22,14],
[52,34,22,16],
[52,34,22,17],
[52,34,22,19],
[52,34,22,21],
[52,34,23,0],
[52,34,23,1],
[52,34,23,2],
[52,34,23,3],
[52,34,23,4],
[52,34,23,5],
[52,34,23,6],
[52,34,23,8],
[52,34,23,10],
[52,34,23,11],
[52,34,23,12],
[52,34,23,13],
[52,34,23,14],
[52,34,23,16],
[52,34,23,17],
[52,34,23,19],
[52,34,23,20],
[52,34,23,21],
[52,34,23,22],
[52,34,24,0],
[52,34,24,1],
[52,34,24,2],
[52,34,24,3],
[52,34,24,4],
[52,34,24,5],
[52,34,24,6],
[52,34,24,8],
[52,34,24,10],
[52,34,24,11],
[52,34,24,12],
[52,34,24,13],
[52,34,24,14],
[52,34,24,17],
[52,34,24,19],
[52,34,24,20],
[52,34,24,21],
[52,34,24,22],
[52,34,27,0],
[52,34,27,1],
[52,34,27,2],
[52,34,27,3],
[52,34,27,4],
[52,34,27,5],
[52,34,27,6],
[52,34,27,8],
[52,34,27,11],
[52,34,27,12],
[52,34,27,13],
[52,34,27,14],
[52,34,27,16],
[52,34,27,17],
[52,34,27,21],
[52,34,27,23],
[52,34,27,24],
[52,34,29,0],
[52,34,29,1],
[52,34,29,2],
[52,34,29,3],
[52,34,29,4],
[52,34,29,5],
[52,34,29,6],
[52,34,29,8],
[52,34,29,10],
[52,34,29,13],
[52,34,29,14],
[52,34,29,19],
[52,34,29,20],
[52,34,29,21],
[52,34,29,22],
[52,34,29,23],
[52,34,29,24],
[52,34,29,27],
[52,34,30,0],
[52,34,30,1],
[52,34,30,2],
[52,34,30,3],
[52,34,30,4],
[52,34,30,5],
[52,34,30,6],
[52,34,30,8],
[52,34,30,10],
[52,34,30,11],
[52,34,30,12],
[52,34,30,13],
[52,34,30,14],
[52,34,30,16],
[52,34,30,17],
[52,34,30,19],
[52,34,30,20],
[52,34,30,21],
[52,34,30,22],
[52,34,30,24],
[52,34,30,27],
[52,34,31,0],
[52,34,31,1],
[52,34,31,2],
[52,34,31,3],
[52,34,31,4],
[52,34,31,5],
[52,34,31,6],
[52,34,31,8],
[52,34,31,10],
[52,34,31,11],
[52,34,31,12],
[52,34,31,13],
[52,34,31,16],
[52,34,31,17],
[52,34,31,19],
[52,34,31,20],
[52,34,31,21],
[52,34,31,22],
[52,34,31,24],
[52,34,31,27],
[52,34,32,0],
[52,34,32,1],
[52,34,32,2],
[52,34,32,3],
[52,34,32,4],
[52,34,32,5],
[52,34,32,6],
[52,34,32,8],
[52,34,32,10],
[52,34,32,14],
[52,34,32,16],
[52,34,32,17],
[52,34,32,19],
[52,34,32,20],
[52,34,32,21],
[52,34,32,22],
[52,34,32,23],
[52,34,32,24],
[52,34,33,0],
[52,34,33,1],
[52,34,33,2],
[52,34,33,3],
[52,34,33,4],
[52,34,33,5],
[52,34,33,6],
[52,34,33,8],
[52,34,33,13],
[52,34,33,14],
[52,34,33,16],
[52,34,33,17],
[52,34,33,19],
[52,34,33,20],
[52,34,33,21],
[52,34,33,22],
[52,34,33,23],
[52,34,33,24],
[52,36,3,0],
[52,36,3,1],
[52,36,3,2],
[52,36,4,0],
[52,36,4,1],
[52,36,4,2],
[52,36,4,3],
[52,36,5,0],
[52,36,5,1],
[52,36,5,2],
[52,36,5,4],
[52,36,6,0],
[52,36,6,1],
[52,36,6,2],
[52,36,6,4],
[52,36,7,0],
[52,36,7,1],
[52,36,7,2],
[52,36,7,3],
[52,36,7,4],
[52,36,7,5],
[52,36,7,6],
[52,36,8,0],
[52,36,8,1],
[52,36,8,2],
[52,36,8,4],
[52,36,8,7],
[52,36,10,0],
[52,36,10,1],
[52,36,10,2],
[52,36,10,3],
[52,36,10,4],
[52,36,10,5],
[52,36,10,6],
[52,36,10,7],
[52,36,10,8],
[52,36,11,0],
[52,36,11,1],
[52,36,11,2],
[52,36,11,3],
[52,36,11,4],
[52,36,11,5],
[52,36,11,6],
[52,36,11,7],
[52,36,11,8],
[52,36,11,10],
[52,36,12,0],
[52,36,12,1],
[52,36,12,2],
[52,36,12,3],
[52,36,12,4],
[52,36,12,5],
[52,36,12,6],
[52,36,12,7],
[52,36,12,8],
[52,36,12,10],
[52,36,16,0],
[52,36,16,1],
[52,36,16,2],
[52,36,16,3],
[52,36,16,4],
[52,36,16,5],
[52,36,16,6],
[52,36,16,7],
[52,36,16,8],
[52,36,16,10],
[52,36,16,11],
[52,36,16,12],
[52,36,17,0],
[52,36,17,1],
[52,36,17,2],
[52,36,17,3],
[52,36,17,4],
[52,36,17,5],
[52,36,17,6],
[52,36,17,7],
[52,36,17,8],
[52,36,17,10],
[52,36,17,11],
[52,36,17,12],
[52,36,17,16],
[52,36,20,0],
[52,36,20,1],
[52,36,20,2],
[52,36,20,3],
[52,36,20,4],
[52,36,20,5],
[52,36,20,6],
[52,36,20,7],
[52,36,20,8],
[52,36,20,11],
[52,36,20,12],
[52,36,20,16],
[52,36,20,17],
[52,36,21,0],
[52,36,21,1],
[52,36,21,2],
[52,36,21,4],
[52,36,21,7],
[52,36,21,10],
[52,36,21,11],
[52,36,21,12],
[52,36,21,16],
[52,36,21,17],
[52,36,21,20],
[52,36,22,0],
[52,36,22,1],
[52,36,22,2],
[52,36,22,3],
[52,36,22,4],
[52,36,22,5],
[52,36,22,6],
[52,36,22,7],
[52,36,22,8],
[52,36,22,11],
[52,36,22,12],
[52,36,22,16],
[52,36,22,17],
[52,36,22,21],
[52,36,23,0],
[52,36,23,1],
[52,36,23,2],
[52,36,23,3],
[52,36,23,4],
[52,36,23,5],
[52,36,23,6],
[52,36,23,7],
[52,36,23,8],
[52,36,23,10],
[52,36,23,11],
[52,36,23,12],
[52,36,23,16],
[52,36,23,17],
[52,36,23,20],
[52,36,23,22],
[52,36,24,0],
[52,36,24,1],
[52,36,24,2],
[52,36,24,3],
[52,36,24,4],
[52,36,24,5],
[52,36,24,6],
[52,36,24,7],
[52,36,24,8],
[52,36,24,10],
[52,36,24,11],
[52,36,24,12],
[52,36,24,17],
[52,36,24,20],
[52,36,24,22],
[52,36,24,23],
[52,36,25,0],
[52,36,25,1],
[52,36,25,2],
[52,36,25,3],
[52,36,25,4],
[52,36,25,5],
[52,36,25,6],
[52,36,25,8],
[52,36,25,10],
[52,36,25,11],
[52,36,25,12],
[52,36,25,16],
[52,36,25,17],
[52,36,25,20],
[52,36,25,21],
[52,36,25,22],
[52,36,25,23],
[52,36,25,24],
[52,36,27,0],
[52,36,27,1],
[52,36,27,2],
[52,36,27,3],
[52,36,27,4],
[52,36,27,5],
[52,36,27,6],
[52,36,27,7],
[52,36,27,8],
[52,36,27,11],
[52,36,27,12],
[52,36,27,21],
[52,36,27,23],
[52,36,27,24],
[52,36,27,25],
[52,36,28,0],
[52,36,28,1],
[52,36,28,2],
[52,36,28,3],
[52,36,28,4],
[52,36,28,5],
[52,36,28,6],
[52,36,28,8],
[52,36,28,10],
[52,36,28,11],
[52,36,28,12],
[52,36,28,16],
[52,36,28,17],
[52,36,28,20],
[52,36,28,21],
[52,36,28,22],
[52,36,28,23],
[52,36,28,24],
[52,36,28,27],
[52,36,29,0],
[52,36,29,1],
[52,36,29,2],
[52,36,29,3],
[52,36,29,4],
[52,36,29,5],
[52,36,29,6],
[52,36,29,7],
[52,36,29,8],
[52,36,29,10],
[52,36,29,16],
[52,36,29,17],
[52,36,29,20],
[52,36,29,21],
[52,36,29,22],
[52,36,29,23],
[52,36,29,24],
[52,36,29,25],
[52,36,29,27],
[52,36,30,0],
[52,36,30,1],
[52,36,30,2],
[52,36,30,3],
[52,36,30,4],
[52,36,30,5],
[52,36,30,6],
[52,36,30,7],
[52,36,30,8],
[52,36,30,10],
[52,36,30,11],
[52,36,30,16],
[52,36,30,17],
[52,36,30,20],
[52,36,30,21],
[52,36,30,22],
[52,36,30,24],
[52,36,30,25],
[52,36,31,0],
[52,36,31,1],
[52,36,31,2],
[52,36,31,3],
[52,36,31,4],
[52,36,31,5],
[52,36,31,6],
[52,36,31,7],
[52,36,31,8],
[52,36,31,12],
[52,36,31,16],
[52,36,31,17],
[52,36,31,20],
[52,36,31,21],
[52,36,31,22],
[52,36,31,24],
[52,36,31,25],
[52,36,32,0],
[52,36,32,1],
[52,36,32,2],
[52,36,32,3],
[52,36,32,4],
[52,36,32,5],
[52,36,32,6],
[52,36,32,7],
[52,36,32,10],
[52,36,32,16],
[52,36,32,17],
[52,36,32,20],
[52,36,32,21],
[52,36,32,22],
[52,36,32,23],
[52,36,32,24],
[52,36,33,0],
[52,36,33,1],
[52,36,33,2],
[52,36,33,3],
[52,36,33,4],
[52,36,33,5],
[52,36,33,8],
[52,36,33,10],
[52,36,33,16],
[52,36,33,17],
[52,36,33,20],
[52,36,33,21],
[52,36,33,22],
[52,36,33,23],
[52,36,33,24],
[52,36,34,0],
[52,36,34,1],
[52,36,34,2],
[52,36,34,3],
[52,36,34,4],
[52,36,34,6],
[52,36,34,8],
[52,36,34,10],
[52,36,34,11],
[52,36,34,12],
[52,36,34,16],
[52,36,34,17],
[52,36,34,20],
[52,36,34,21],
[52,36,34,22],
[52,37,3,0],
[52,37,3,1],
[52,37,3,2],
[52,37,5,0],
[52,37,5,1],
[52,37,5,2],
[52,37,6,0],
[52,37,6,1],
[52,37,6,2],
[52,37,7,0],
[52,37,7,1],
[52,37,7,2],
[52,37,7,3],
[52,37,7,5],
[52,37,7,6],
[52,37,8,0],
[52,37,8,1],
[52,37,8,2],
[52,37,8,7],
[52,37,10,0],
[52,37,10,1],
[52,37,10,2],
[52,37,10,3],
[52,37,10,5],
[52,37,10,6],
[52,37,10,7],
[52,37,10,8],
[52,37,11,0],
[52,37,11,1],
[52,37,11,2],
[52,37,11,3],
[52,37,11,5],
[52,37,11,6],
[52,37,11,7],
[52,37,11,8],
[52,37,11,10],
[52,37,12,0],
[52,37,12,1],
[52,37,12,2],
[52,37,12,3],
[52,37,12,5],
[52,37,12,6],
[52,37,12,7],
[52,37,12,8],
[52,37,12,10],
[52,37,13,0],
[52,37,13,1],
[52,37,13,2],
[52,37,13,3],
[52,37,13,5],
[52,37,13,6],
[52,37,13,7],
[52,37,13,8],
[52,37,13,10],
[52,37,13,11],
[52,37,13,12],
[52,37,14,0],
[52,37,14,1],
[52,37,14,2],
[52,37,14,3],
[52,37,14,5],
[52,37,14,6],
[52,37,14,7],
[52,37,14,8],
[52,37,14,10],
[52,37,14,11],
[52,37,14,12],
[52,37,16,0],
[52,37,16,1],
[52,37,16,2],
[52,37,16,3],
[52,37,16,5],
[52,37,16,6],
[52,37,16,7],
[52,37,16,8],
[52,37,16,10],
[52,37,16,11],
[52,37,16,12],
[52,37,16,13],
[52,37,16,14],
[52,37,17,0],
[52,37,17,1],
[52,37,17,2],
[52,37,17,3],
[52,37,17,5],
[52,37,17,6],
[52,37,17,7],
[52,37,17,8],
[52,37,17,10],
[52,37,17,11],
[52,37,17,12],
[52,37,17,13],
[52,37,17,14],
[52,37,17,16],
[52,37,19,0],
[52,37,19,1],
[52,37,19,2],
[52,37,19,3],
[52,37,19,5],
[52,37,19,6],
[52,37,19,7],
[52,37,19,8],
[52,37,19,10],
[52,37,19,11],
[52,37,19,12],
[52,37,19,16],
[52,37,19,17],
[52,37,20,0],
[52,37,20,1],
[52,37,20,2],
[52,37,20,3],
[52,37,20,5],
[52,37,20,6],
[52,37,20,7],
[52,37,20,8],
[52,37,20,11],
[52,37,20,12],
[52,37,20,13],
[52,37,20,14],
[52,37,20,16],
[52,37,20,17],
[52,37,20,19],
[52,37,21,0],
[52,37,21,1],
[52,37,21,2],
[52,37,21,7],
[52,37,21,10],
[52,37,21,11],
[52,37,21,12],
[52,37,21,13],
[52,37,21,14],
[52,37,21,16],
[52,37,21,17],
[52,37,21,19],
[52,37,21,20],
[52,37,22,0],
[52,37,22,1],
[52,37,22,2],
[52,37,22,3],
[52,37,22,5],
[52,37,22,6],
[52,37,22,7],
[52,37,22,8],
[52,37,22,11],
[52,37,22,12],
[52,37,22,13],
[52,37,22,14],
[52,37,22,16],
[52,37,22,17],
[52,37,22,19],
[52,37,23,0],
[52,37,23,1],
[52,37,23,2],
[52,37,23,3],
[52,37,23,5],
[52,37,23,6],
[52,37,23,7],
[52,37,23,8],
[52,37,23,10],
[52,37,23,11],
[52,37,23,12],
[52,37,23,13],
[52,37,23,14],
[52,37,23,16],
[52,37,23,17],
[52,37,23,19],
[52,37,23,21],
[52,37,23,22],
[52,37,24,0],
[52,37,24,1],
[52,37,24,2],
[52,37,24,3],
[52,37,24,5],
[52,37,24,6],
[52,37,24,7],
[52,37,24,8],
[52,37,24,10],
[52,37,24,11],
[52,37,24,12],
[52,37,24,13],
[52,37,24,14],
[52,37,24,17],
[52,37,24,19],
[52,37,24,21],
[52,37,24,22],
[52,37,24,23],
[52,37,25,0],
[52,37,25,1],
[52,37,25,2],
[52,37,25,3],
[52,37,25,5],
[52,37,25,6],
[52,37,25,8],
[52,37,25,10],
[52,37,25,11],
[52,37,25,12],
[52,37,25,13],
[52,37,25,14],
[52,37,25,16],
[52,37,25,17],
[52,37,25,19],
[52,37,25,20],
[52,37,25,21],
[52,37,25,22],
[52,37,25,23],
[52,37,25,24],
[52,37,27,0],
[52,37,27,1],
[52,37,27,2],
[52,37,27,3],
[52,37,27,5],
[52,37,27,6],
[52,37,27,7],
[52,37,27,8],
[52,37,27,11],
[52,37,27,12],
[52,37,27,13],
[52,37,27,14],
[52,37,27,16],
[52,37,27,17],
[52,37,27,19],
[52,37,27,21],
[52,37,27,23],
[52,37,27,24],
[52,37,27,25],
[52,37,28,0],
[52,37,28,1],
[52,37,28,2],
[52,37,28,3],
[52,37,28,5],
[52,37,28,6],
[52,37,28,8],
[52,37,28,10],
[52,37,28,11],
[52,37,28,12],
[52,37,28,13],
[52,37,28,16],
[52,37,28,17],
[52,37,28,19],
[52,37,28,20],
[52,37,28,21],
[52,37,28,22],
[52,37,28,23],
[52,37,28,24],
[52,37,28,27],
[52,37,29,0],
[52,37,29,1],
[52,37,29,2],
[52,37,29,3],
[52,37,29,5],
[52,37,29,6],
[52,37,29,7],
[52,37,29,8],
[52,37,29,10],
[52,37,29,14],
[52,37,29,16],
[52,37,29,17],
[52,37,29,19],
[52,37,29,20],
[52,37,29,21],
[52,37,29,22],
[52,37,29,23],
[52,37,29,24],
[52,37,29,25],
[52,37,30,0],
[52,37,30,1],
[52,37,30,2],
[52,37,30,3],
[52,37,30,5],
[52,37,30,6],
[52,37,30,7],
[52,37,30,8],
[52,37,30,12],
[52,37,30,13],
[52,37,30,14],
[52,37,30,16],
[52,37,30,17],
[52,37,30,19],
[52,37,30,20],
[52,37,30,21],
[52,37,30,22],
[52,37,30,24],
[52,37,30,25],
[52,37,31,0],
[52,37,31,1],
[52,37,31,2],
[52,37,31,3],
[52,37,31,5],
[52,37,31,6],
[52,37,31,7],
[52,37,31,10],
[52,37,31,11],
[52,37,31,12],
[52,37,31,13],
[52,37,31,14],
[52,37,31,16],
[52,37,31,17],
[52,37,31,19],
[52,37,31,20],
[52,37,31,21],
[52,37,31,22],
[52,37,31,24],
[52,37,32,0],
[52,37,32,1],
[52,37,32,2],
[52,37,32,3],
[52,37,32,5],
[52,37,32,8],
[52,37,32,10],
[52,37,32,13],
[52,37,32,14],
[52,37,32,16],
[52,37,32,17],
[52,37,32,19],
[52,37,32,20],
[52,37,32,21],
[52,37,32,22],
[52,37,32,23],
[52,37,32,24],
[52,37,33,0],
[52,37,33,1],
[52,37,33,2],
[52,37,33,3],
[52,37,33,6],
[52,37,33,7],
[52,37,33,8],
[52,37,33,10],
[52,37,33,13],
[52,37,33,14],
[52,37,33,16],
[52,37,33,17],
[52,37,33,19],
[52,37,33,20],
[52,37,33,21],
[52,37,33,22],
[52,37,34,0],
[52,37,34,1],
[52,37,34,2],
[52,37,34,5],
[52,37,34,6],
[52,37,34,8],
[52,37,34,10],
[52,37,34,11],
[52,37,34,12],
[52,37,34,13],
[52,37,34,14],
[52,37,34,16],
[52,37,34,17],
[52,37,34,19],
[52,37,34,20],
[52,37,34,21],
[52,37,36,0],
[52,37,36,1],
[52,37,36,2],
[52,37,36,3],
[52,37,36,5],
[52,37,36,6],
[52,37,36,7],
[52,37,36,8],
[52,37,36,10],
[52,37,36,11],
[52,37,36,12],
[52,37,36,16],
[52,37,36,17],
[52,38,3,0],
[52,38,3,1],
[52,38,3,2],
[52,38,4,0],
[52,38,4,1],
[52,38,4,2],
[52,38,4,3],
[52,38,5,0],
[52,38,5,1],
[52,38,5,2],
[52,38,5,4],
[52,38,6,0],
[52,38,6,1],
[52,38,6,2],
[52,38,6,4],
[52,38,8,0],
[52,38,8,1],
[52,38,8,2],
[52,38,8,4],
[52,38,10,0],
[52,38,10,1],
[52,38,10,2],
[52,38,10,3],
[52,38,10,4],
[52,38,10,5],
[52,38,10,6],
[52,38,10,8],
[52,38,11,0],
[52,38,11,1],
[52,38,11,2],
[52,38,11,3],
[52,38,11,4],
[52,38,11,5],
[52,38,11,6],
[52,38,11,8],
[52,38,11,10],
[52,38,12,0],
[52,38,12,1],
[52,38,12,2],
[52,38,12,3],
[52,38,12,4],
[52,38,12,5],
[52,38,12,6],
[52,38,12,8],
[52,38,12,10],
[52,38,13,0],
[52,38,13,1],
[52,38,13,2],
[52,38,13,3],
[52,38,13,4],
[52,38,13,5],
[52,38,13,6],
[52,38,13,8],
[52,38,13,10],
[52,38,13,11],
[52,38,13,12],
[52,38,14,0],
[52,38,14,1],
[52,38,14,2],
[52,38,14,3],
[52,38,14,4],
[52,38,14,5],
[52,38,14,6],
[52,38,14,8],
[52,38,14,10],
[52,38,14,11],
[52,38,14,12],
[52,38,16,0],
[52,38,16,1],
[52,38,16,2],
[52,38,16,3],
[52,38,16,4],
[52,38,16,5],
[52,38,16,6],
[52,38,16,8],
[52,38,16,10],
[52,38,16,11],
[52,38,16,12],
[52,38,16,13],
[52,38,16,14],
[52,38,17,0],
[52,38,17,1],
[52,38,17,2],
[52,38,17,3],
[52,38,17,4],
[52,38,17,5],
[52,38,17,6],
[52,38,17,8],
[52,38,17,10],
[52,38,17,11],
[52,38,17,12],
[52,38,17,13],
[52,38,17,14],
[52,38,17,16],
[52,38,19,0],
[52,38,19,1],
[52,38,19,2],
[52,38,19,3],
[52,38,19,4],
[52,38,19,5],
[52,38,19,6],
[52,38,19,8],
[52,38,19,10],
[52,38,19,11],
[52,38,19,12],
[52,38,19,16],
[52,38,19,17],
[52,38,20,0],
[52,38,20,1],
[52,38,20,2],
[52,38,20,3],
[52,38,20,4],
[52,38,20,5],
[52,38,20,6],
[52,38,20,8],
[52,38,20,11],
[52,38,20,12],
[52,38,20,13],
[52,38,20,14],
[52,38,20,16],
[52,38,20,17],
[52,38,20,19],
[52,38,21,0],
[52,38,21,1],
[52,38,21,2],
[52,38,21,4],
[52,38,21,10],
[52,38,21,11],
[52,38,21,12],
[52,38,21,13],
[52,38,21,14],
[52,38,21,16],
[52,38,21,17],
[52,38,21,19],
[52,38,21,20],
[52,38,22,0],
[52,38,22,1],
[52,38,22,2],
[52,38,22,3],
[52,38,22,4],
[52,38,22,5],
[52,38,22,6],
[52,38,22,8],
[52,38,22,11],
[52,38,22,12],
[52,38,22,13],
[52,38,22,14],
[52,38,22,16],
[52,38,22,17],
[52,38,22,19],
[52,38,22,21],
[52,38,23,0],
[52,38,23,1],
[52,38,23,2],
[52,38,23,3],
[52,38,23,4],
[52,38,23,5],
[52,38,23,6],
[52,38,23,8],
[52,38,23,10],
[52,38,23,11],
[52,38,23,12],
[52,38,23,13],
[52,38,23,14],
[52,38,23,16],
[52,38,23,17],
[52,38,23,20],
[52,38,23,21],
[52,38,23,22],
[52,38,24,0],
[52,38,24,1],
[52,38,24,2],
[52,38,24,3],
[52,38,24,4],
[52,38,24,5],
[52,38,24,6],
[52,38,24,8],
[52,38,24,10],
[52,38,24,11],
[52,38,24,12],
[52,38,24,13],
[52,38,24,14],
[52,38,24,17],
[52,38,24,20],
[52,38,24,21],
[52,38,24,22],
[52,38,24,23],
[52,38,27,0],
[52,38,27,1],
[52,38,27,2],
[52,38,27,3],
[52,38,27,4],
[52,38,27,5],
[52,38,27,6],
[52,38,27,8],
[52,38,27,11],
[52,38,27,12],
[52,38,27,13],
[52,38,27,16],
[52,38,27,17],
[52,38,27,19],
[52,38,27,21],
[52,38,27,23],
[52,38,27,24],
[52,38,29,0],
[52,38,29,1],
[52,38,29,2],
[52,38,29,3],
[52,38,29,4],
[52,38,29,5],
[52,38,29,6],
[52,38,29,8],
[52,38,29,13],
[52,38,29,14],
[52,38,29,16],
[52,38,29,17],
[52,38,29,19],
[52,38,29,20],
[52,38,29,21],
[52,38,29,22],
[52,38,29,23],
[52,38,29,24],
[52,38,30,0],
[52,38,30,1],
[52,38,30,2],
[52,38,30,3],
[52,38,30,4],
[52,38,30,5],
[52,38,30,6],
[52,38,30,10],
[52,38,30,11],
[52,38,30,12],
[52,38,30,13],
[52,38,30,14],
[52,38,30,16],
[52,38,30,17],
[52,38,30,19],
[52,38,30,20],
[52,38,30,21],
[52,38,30,22],
[52,38,30,24],
[52,38,31,0],
[52,38,31,1],
[52,38,31,2],
[52,38,31,3],
[52,38,31,4],
[52,38,31,5],
[52,38,31,8],
[52,38,31,10],
[52,38,31,11],
[52,38,31,12],
[52,38,31,13],
[52,38,31,14],
[52,38,31,16],
[52,38,31,17],
[52,38,31,19],
[52,38,31,20],
[52,38,31,21],
[52,38,31,22],
[52,38,31,24],
[52,38,32,0],
[52,38,32,1],
[52,38,32,2],
[52,38,32,3],
[52,38,32,4],
[52,38,32,6],
[52,38,32,8],
[52,38,32,10],
[52,38,32,13],
[52,38,32,14],
[52,38,32,16],
[52,38,32,17],
[52,38,32,19],
[52,38,32,20],
[52,38,32,21],
[52,38,32,22],
[52,38,33,0],
[52,38,33,1],
[52,38,33,2],
[52,38,33,5],
[52,38,33,6],
[52,38,33,8],
[52,38,33,10],
[52,38,33,13],
[52,38,33,14],
[52,38,33,16],
[52,38,33,17],
[52,38,33,19],
[52,38,33,20],
[52,38,33,21],
[52,38,36,0],
[52,38,36,1],
[52,38,36,2],
[52,38,36,3],
[52,38,36,4],
[52,38,36,5],
[52,38,36,6],
[52,38,36,8],
[52,38,36,10],
[52,38,36,11],
[52,38,36,12],
[52,38,36,16],
[52,38,36,17],
[52,38,37,0],
[52,38,37,1],
[52,38,37,2],
[52,38,37,3],
[52,38,37,5],
[52,38,37,6],
[52,38,37,8],
[52,38,37,10],
[52,38,37,11],
[52,38,37,12],
[52,38,37,13],
[52,38,37,14],
[52,38,37,16],
[52,38,37,17],
[52,39,4,0],
[52,39,4,1],
[52,39,4,2],
[52,39,7,0],
[52,39,7,1],
[52,39,7,2],
[52,39,7,4],
[52,39,10,0],
[52,39,10,1],
[52,39,10,2],
[52,39,10,4],
[52,39,10,7],
[52,39,11,0],
[52,39,11,1],
[52,39,11,2],
[52,39,11,4],
[52,39,11,7],
[52,39,11,10],
[52,39,12,0],
[52,39,12,1],
[52,39,12,2],
[52,39,12,4],
[52,39,12,7],
[52,39,12,10],
[52,39,13,0],
[52,39,13,1],
[52,39,13,2],
[52,39,13,4],
[52,39,13,7],
[52,39,13,10],
[52,39,13,11],
[52,39,13,12],
[52,39,14,0],
[52,39,14,1],
[52,39,14,2],
[52,39,14,4],
[52,39,14,7],
[52,39,14,10],
[52,39,14,11],
[52,39,14,12],
[52,39,16,0],
[52,39,16,1],
[52,39,16,2],
[52,39,16,4],
[52,39,16,7],
[52,39,16,10],
[52,39,16,11],
[52,39,16,12],
[52,39,16,13],
[52,39,16,14],
[52,39,17,0],
[52,39,17,1],
[52,39,17,2],
[52,39,17,4],
[52,39,17,7],
[52,39,17,10],
[52,39,17,11],
[52,39,17,12],
[52,39,17,13],
[52,39,17,14],
[52,39,17,16],
[52,39,19,0],
[52,39,19,1],
[52,39,19,2],
[52,39,19,4],
[52,39,19,7],
[52,39,19,10],
[52,39,19,11],
[52,39,19,12],
[52,39,19,16],
[52,39,19,17],
[52,39,20,0],
[52,39,20,1],
[52,39,20,2],
[52,39,20,4],
[52,39,20,7],
[52,39,20,11],
[52,39,20,12],
[52,39,20,13],
[52,39,20,14],
[52,39,20,16],
[52,39,20,17],
[52,39,20,19],
[52,39,22,0],
[52,39,22,1],
[52,39,22,2],
[52,39,22,4],
[52,39,22,7],
[52,39,22,11],
[52,39,22,12],
[52,39,22,13],
[52,39,22,14],
[52,39,22,16],
[52,39,22,17],
[52,39,23,0],
[52,39,23,1],
[52,39,23,2],
[52,39,23,4],
[52,39,23,7],
[52,39,23,10],
[52,39,23,11],
[52,39,23,12],
[52,39,23,13],
[52,39,23,14],
[52,39,23,16],
[52,39,23,17],
[52,39,23,19],
[52,39,23,20],
[52,39,23,22],
[52,39,24,0],
[52,39,24,1],
[52,39,24,2],
[52,39,24,4],
[52,39,24,7],
[52,39,24,10],
[52,39,24,11],
[52,39,24,12],
[52,39,24,13],
[52,39,24,14],
[52,39,24,17],
[52,39,24,19],
[52,39,24,20],
[52,39,24,22],
[52,39,24,23],
[52,39,25,0],
[52,39,25,1],
[52,39,25,2],
[52,39,25,4],
[52,39,25,10],
[52,39,25,11],
[52,39,25,12],
[52,39,25,13],
[52,39,25,14],
[52,39,25,16],
[52,39,25,17],
[52,39,25,19],
[52,39,25,20],
[52,39,25,22],
[52,39,25,23],
[52,39,25,24],
[52,39,27,0],
[52,39,27,1],
[52,39,27,2],
[52,39,27,4],
[52,39,27,7],
[52,39,27,11],
[52,39,27,14],
[52,39,27,16],
[52,39,27,17],
[52,39,27,19],
[52,39,27,23],
[52,39,27,24],
[52,39,27,25],
[52,39,28,0],
[52,39,28,1],
[52,39,28,2],
[52,39,28,4],
[52,39,28,12],
[52,39,28,13],
[52,39,28,14],
[52,39,28,16],
[52,39,28,17],
[52,39,28,19],
[52,39,28,20],
[52,39,28,22],
[52,39,28,23],
[52,39,28,24],
[52,39,29,0],
[52,39,29,1],
[52,39,29,2],
[52,39,29,4],
[52,39,29,7],
[52,39,29,10],
[52,39,29,13],
[52,39,29,14],
[52,39,29,16],
[52,39,29,17],
[52,39,29,19],
[52,39,29,20],
[52,39,29,22],
[52,39,29,23],
[52,39,29,24],
[52,39,30,0],
[52,39,30,1],
[52,39,30,2],
[52,39,30,4],
[52,39,30,10],
[52,39,30,11],
[52,39,30,12],
[52,39,30,13],
[52,39,30,14],
[52,39,30,16],
[52,39,30,17],
[52,39,30,19],
[52,39,30,20],
[52,39,30,22],
[52,39,30,24],
[52,39,31,0],
[52,39,31,1],
[52,39,31,2],
[52,39,31,4],
[52,39,31,7],
[52,39,31,10],
[52,39,31,11],
[52,39,31,12],
[52,39,31,13],
[52,39,31,14],
[52,39,31,16],
[52,39,31,17],
[52,39,31,19],
[52,39,31,20],
[52,39,31,22],
[52,39,32,0],
[52,39,32,1],
[52,39,32,2],
[52,39,32,7],
[52,39,32,10],
[52,39,32,13],
[52,39,32,14],
[52,39,32,16],
[52,39,32,17],
[52,39,32,19],
[52,39,32,20],
[52,39,33,4],
[52,39,33,7],
[52,39,33,10],
[52,39,33,13],
[52,39,33,14],
[52,39,33,16],
[52,39,33,17],
[52,39,33,19],
[52,39,33,20],
[52,39,34,0],
[52,39,34,1],
[52,39,34,2],
[52,39,34,4],
[52,39,34,10],
[52,39,34,11],
[52,39,34,12],
[52,39,34,13],
[52,39,34,14],
[52,39,34,16],
[52,39,34,17],
[52,39,34,19],
[52,39,36,0],
[52,39,36,1],
[52,39,36,2],
[52,39,36,4],
[52,39,36,7],
[52,39,36,10],
[52,39,36,11],
[52,39,36,12],
[52,39,36,16],
[52,39,36,17],
[52,39,37,0],
[52,39,37,1],
[52,39,37,2],
[52,39,37,7],
[52,39,37,10],
[52,39,37,11],
[52,39,37,12],
[52,39,37,13],
[52,39,37,14],
[52,39,38,0],
[52,39,38,1],
[52,39,38,2],
[52,39,38,4],
[52,39,38,10],
[52,39,38,11],
[52,39,38,12],
[52,39,38,13],
[52,39,38,14],
[52,42,3,0],
[52,42,3,1],
[52,42,3,2],
[52,42,4,0],
[52,42,4,1],
[52,42,4,2],
[52,42,4,3],
[52,42,5,0],
[52,42,5,1],
[52,42,5,2],
[52,42,5,4],
[52,42,6,0],
[52,42,6,1],
[52,42,6,2],
[52,42,6,4],
[52,42,7,0],
[52,42,7,1],
[52,42,7,2],
[52,42,7,3],
[52,42,7,4],
[52,42,7,5],
[52,42,7,6],
[52,42,8,0],
[52,42,8,1],
[52,42,8,2],
[52,42,8,4],
[52,42,8,7],
[52,42,11,0],
[52,42,11,1],
[52,42,11,2],
[52,42,11,3],
[52,42,11,4],
[52,42,11,5],
[52,42,11,6],
[52,42,11,7],
[52,42,11,8],
[52,42,12,0],
[52,42,12,1],
[52,42,12,2],
[52,42,12,3],
[52,42,12,4],
[52,42,12,5],
[52,42,12,6],
[52,42,12,7],
[52,42,12,8],
[52,42,13,0],
[52,42,13,1],
[52,42,13,2],
[52,42,13,3],
[52,42,13,4],
[52,42,13,5],
[52,42,13,6],
[52,42,13,7],
[52,42,13,8],
[52,42,13,11],
[52,42,13,12],
[52,42,14,0],
[52,42,14,1],
[52,42,14,2],
[52,42,14,3],
[52,42,14,4],
[52,42,14,5],
[52,42,14,6],
[52,42,14,7],
[52,42,14,8],
[52,42,14,11],
[52,42,14,12],
[52,42,16,0],
[52,42,16,1],
[52,42,16,2],
[52,42,16,3],
[52,42,16,4],
[52,42,16,5],
[52,42,16,6],
[52,42,16,7],
[52,42,16,8],
[52,42,16,11],
[52,42,16,12],
[52,42,16,13],
[52,42,16,14],
[52,42,17,0],
[52,42,17,1],
[52,42,17,2],
[52,42,17,3],
[52,42,17,4],
[52,42,17,5],
[52,42,17,6],
[52,42,17,7],
[52,42,17,8],
[52,42,17,11],
[52,42,17,12],
[52,42,17,13],
[52,42,17,14],
[52,42,17,16],
[52,42,19,0],
[52,42,19,1],
[52,42,19,2],
[52,42,19,3],
[52,42,19,4],
[52,42,19,5],
[52,42,19,6],
[52,42,19,7],
[52,42,19,8],
[52,42,19,11],
[52,42,19,12],
[52,42,19,16],
[52,42,19,17],
[52,42,21,0],
[52,42,21,1],
[52,42,21,2],
[52,42,21,4],
[52,42,21,7],
[52,42,21,11],
[52,42,21,12],
[52,42,21,13],
[52,42,21,14],
[52,42,21,16],
[52,42,21,17],
[52,42,21,19],
[52,42,23,0],
[52,42,23,1],
[52,42,23,2],
[52,42,23,3],
[52,42,23,4],
[52,42,23,5],
[52,42,23,6],
[52,42,23,7],
[52,42,23,8],
[52,42,23,11],
[52,42,23,12],
[52,42,23,13],
[52,42,23,14],
[52,42,23,16],
[52,42,23,17],
[52,42,23,19],
[52,42,23,21],
[52,42,24,0],
[52,42,24,1],
[52,42,24,2],
[52,42,24,3],
[52,42,24,4],
[52,42,24,5],
[52,42,24,6],
[52,42,24,7],
[52,42,24,8],
[52,42,24,11],
[52,42,24,12],
[52,42,24,13],
[52,42,24,14],
[52,42,24,17],
[52,42,24,19],
[52,42,24,21],
[52,42,24,23],
[52,42,25,0],
[52,42,25,1],
[52,42,25,2],
[52,42,25,3],
[52,42,25,4],
[52,42,25,5],
[52,42,25,6],
[52,42,25,8],
[52,42,25,11],
[52,42,25,14],
[52,42,25,16],
[52,42,25,17],
[52,42,25,19],
[52,42,25,21],
[52,42,25,23],
[52,42,25,24],
[52,42,28,0],
[52,42,28,1],
[52,42,28,2],
[52,42,28,3],
[52,42,28,4],
[52,42,28,5],
[52,42,28,8],
[52,42,28,11],
[52,42,28,12],
[52,42,28,13],
[52,42,28,14],
[52,42,28,16],
[52,42,28,17],
[52,42,28,19],
[52,42,28,21],
[52,42,28,23],
[52,42,28,24],
[52,42,29,0],
[52,42,29,1],
[52,42,29,2],
[52,42,29,3],
[52,42,29,4],
[52,42,29,6],
[52,42,29,7],
[52,42,29,8],
[52,42,29,13],
[52,42,29,14],
[52,42,29,16],
[52,42,29,17],
[52,42,29,19],
[52,42,29,21],
[52,42,30,0],
[52,42,30,1],
[52,42,30,2],
[52,42,30,5],
[52,42,30,6],
[52,42,30,7],
[52,42,30,8],
[52,42,30,11],
[52,42,30,12],
[52,42,30,13],
[52,42,30,14],
[52,42,30,16],
[52,42,30,17],
[52,42,30,19],
[52,42,30,21],
[52,42,31,3],
[52,42,31,4],
[52,42,31,5],
[52,42,31,6],
[52,42,31,7],
[52,42,31,8],
[52,42,31,11],
[52,42,31,12],
[52,42,31,13],
[52,42,31,14],
[52,42,31,16],
[52,42,31,17],
[52,42,31,19],
[52,42,32,0],
[52,42,32,1],
[52,42,32,2],
[52,42,32,3],
[52,42,32,4],
[52,42,32,5],
[52,42,32,6],
[52,42,32,7],
[52,42,32,8],
[52,42,32,13],
[52,42,32,14],
[52,42,32,16],
[52,42,32,17],
[52,42,32,19],
[52,42,33,0],
[52,42,33,1],
[52,42,33,2],
[52,42,33,3],
[52,42,33,4],
[52,42,33,5],
[52,42,33,6],
[52,42,33,7],
[52,42,33,8],
[52,42,33,13],
[52,42,33,14],
[52,42,33,16],
[52,42,33,17],
[52,42,34,0],
[52,42,34,1],
[52,42,34,2],
[52,42,34,3],
[52,42,34,4],
[52,42,34,5],
[52,42,34,6],
[52,42,34,8],
[52,42,34,11],
[52,42,34,12],
[52,42,34,13],
[52,42,34,14],
[52,42,34,16],
[52,42,34,17],
[52,42,36,0],
[52,42,36,1],
[52,42,36,2],
[52,42,36,3],
[52,42,36,4],
[52,42,36,5],
[52,42,36,6],
[52,42,36,7],
[52,42,36,8],
[52,42,36,11],
[52,42,36,12],
[52,42,37,0],
[52,42,37,1],
[52,42,37,2],
[52,42,37,3],
[52,42,37,5],
[52,42,37,6],
[52,42,37,7],
[52,42,37,8],
[52,42,37,11],
[52,42,37,12],
[52,42,37,13],
[52,42,38,0],
[52,42,38,1],
[52,42,38,2],
[52,42,38,3],
[52,42,38,4],
[52,42,38,5],
[52,42,38,6],
[52,42,38,8],
[52,42,38,11],
[52,42,39,0],
[52,42,39,1],
[52,42,39,2],
[52,42,39,4],
[52,42,39,7],
[52,43,3,0],
[52,43,3,1],
[52,43,3,2],
[52,43,4,0],
[52,43,4,1],
[52,43,4,2],
[52,43,4,3],
[52,43,5,0],
[52,43,5,1],
[52,43,5,2],
[52,43,5,4],
[52,43,6,0],
[52,43,6,1],
[52,43,6,2],
[52,43,6,4],
[52,43,8,0],
[52,43,8,1],
[52,43,8,2],
[52,43,8,4],
[52,43,10,0],
[52,43,10,1],
[52,43,10,2],
[52,43,10,3],
[52,43,10,4],
[52,43,10,5],
[52,43,10,6],
[52,43,10,8],
[52,43,11,0],
[52,43,11,1],
[52,43,11,2],
[52,43,11,3],
[52,43,11,4],
[52,43,11,5],
[52,43,11,6],
[52,43,11,8],
[52,43,11,10],
[52,43,12,0],
[52,43,12,1],
[52,43,12,2],
[52,43,12,3],
[52,43,12,4],
[52,43,12,5],
[52,43,12,6],
[52,43,12,8],
[52,43,12,10],
[52,43,13,0],
[52,43,13,1],
[52,43,13,2],
[52,43,13,3],
[52,43,13,4],
[52,43,13,5],
[52,43,13,6],
[52,43,13,8],
[52,43,13,10],
[52,43,13,11],
[52,43,13,12],
[52,43,14,0],
[52,43,14,1],
[52,43,14,2],
[52,43,14,3],
[52,43,14,4],
[52,43,14,5],
[52,43,14,6],
[52,43,14,8],
[52,43,14,10],
[52,43,14,11],
[52,43,14,12],
[52,43,16,0],
[52,43,16,1],
[52,43,16,2],
[52,43,16,3],
[52,43,16,4],
[52,43,16,5],
[52,43,16,6],
[52,43,16,8],
[52,43,16,10],
[52,43,16,11],
[52,43,16,12],
[52,43,16,13],
[52,43,16,14],
[52,43,17,0],
[52,43,17,1],
[52,43,17,2],
[52,43,17,3],
[52,43,17,4],
[52,43,17,5],
[52,43,17,6],
[52,43,17,8],
[52,43,17,10],
[52,43,17,11],
[52,43,17,12],
[52,43,17,13],
[52,43,17,14],
[52,43,17,16],
[52,43,19,0],
[52,43,19,1],
[52,43,19,2],
[52,43,19,3],
[52,43,19,4],
[52,43,19,5],
[52,43,19,6],
[52,43,19,8],
[52,43,19,10],
[52,43,19,11],
[52,43,19,12],
[52,43,19,16],
[52,43,19,17],
[52,43,20,0],
[52,43,20,1],
[52,43,20,2],
[52,43,20,3],
[52,43,20,4],
[52,43,20,5],
[52,43,20,6],
[52,43,20,8],
[52,43,20,11],
[52,43,20,12],
[52,43,20,13],
[52,43,20,14],
[52,43,20,16],
[52,43,20,17],
[52,43,20,19],
[52,43,21,0],
[52,43,21,1],
[52,43,21,2],
[52,43,21,4],
[52,43,21,10],
[52,43,21,11],
[52,43,21,12],
[52,43,21,13],
[52,43,21,14],
[52,43,21,19],
[52,43,21,20],
[52,43,22,0],
[52,43,22,1],
[52,43,22,2],
[52,43,22,3],
[52,43,22,4],
[52,43,22,5],
[52,43,22,6],
[52,43,22,8],
[52,43,22,11],
[52,43,22,12],
[52,43,22,13],
[52,43,22,14],
[52,43,22,16],
[52,43,22,17],
[52,43,22,19],
[52,43,22,21],
[52,43,23,0],
[52,43,23,1],
[52,43,23,2],
[52,43,23,3],
[52,43,23,4],
[52,43,23,5],
[52,43,23,6],
[52,43,23,8],
[52,43,23,10],
[52,43,23,11],
[52,43,23,12],
[52,43,23,13],
[52,43,23,16],
[52,43,23,17],
[52,43,23,19],
[52,43,23,20],
[52,43,23,21],
[52,43,23,22],
[52,43,24,0],
[52,43,24,1],
[52,43,24,2],
[52,43,24,3],
[52,43,24,4],
[52,43,24,5],
[52,43,24,6],
[52,43,24,8],
[52,43,24,10],
[52,43,24,11],
[52,43,24,12],
[52,43,24,13],
[52,43,24,17],
[52,43,24,19],
[52,43,24,20],
[52,43,24,21],
[52,43,24,22],
[52,43,24,23],
[52,43,27,0],
[52,43,27,1],
[52,43,27,2],
[52,43,27,3],
[52,43,27,4],
[52,43,27,5],
[52,43,27,8],
[52,43,27,11],
[52,43,27,12],
[52,43,27,13],
[52,43,27,14],
[52,43,27,16],
[52,43,27,17],
[52,43,27,19],
[52,43,27,21],
[52,43,27,23],
[52,43,27,24],
[52,43,29,0],
[52,43,29,1],
[52,43,29,2],
[52,43,29,5],
[52,43,29,6],
[52,43,29,8],
[52,43,29,10],
[52,43,29,13],
[52,43,29,14],
[52,43,29,16],
[52,43,29,17],
[52,43,29,19],
[52,43,29,20],
[52,43,29,21],
[52,43,30,3],
[52,43,30,4],
[52,43,30,5],
[52,43,30,6],
[52,43,30,8],
[52,43,30,10],
[52,43,30,11],
[52,43,30,12],
[52,43,30,13],
[52,43,30,14],
[52,43,30,16],
[52,43,30,17],
[52,43,30,19],
[52,43,30,20],
[52,43,31,0],
[52,43,31,1],
[52,43,31,2],
[52,43,31,3],
[52,43,31,4],
[52,43,31,5],
[52,43,31,6],
[52,43,31,8],
[52,43,31,10],
[52,43,31,11],
[52,43,31,12],
[52,43,31,13],
[52,43,31,14],
[52,43,31,16],
[52,43,31,17],
[52,43,31,19],
[52,43,32,0],
[52,43,32,1],
[52,43,32,2],
[52,43,32,3],
[52,43,32,4],
[52,43,32,5],
[52,43,32,6],
[52,43,32,8],
[52,43,32,10],
[52,43,32,13],
[52,43,32,14],
[52,43,32,16],
[52,43,32,17],
[52,43,33,0],
[52,43,33,1],
[52,43,33,2],
[52,43,33,3],
[52,43,33,4],
[52,43,33,5],
[52,43,33,6],
[52,43,33,8],
[52,43,33,10],
[52,43,33,13],
[52,43,33,14],
[52,43,33,16],
[52,43,33,17],
[52,43,36,0],
[52,43,36,1],
[52,43,36,2],
[52,43,36,3],
[52,43,36,4],
[52,43,36,5],
[52,43,36,6],
[52,43,36,8],
[52,43,36,10],
[52,43,36,11],
[52,43,36,12],
[52,43,37,0],
[52,43,37,1],
[52,43,37,2],
[52,43,37,3],
[52,43,37,5],
[52,43,37,6],
[52,43,37,8],
[52,43,37,10],
[52,43,37,11],
[52,43,39,0],
[52,43,39,1],
[52,43,39,2],
[52,43,39,4],
[52,43,42,0],
[52,43,42,1],
[52,43,42,2],
[52,43,42,3],
[52,43,42,4],
[52,44,3,0],
[52,44,3,1],
[52,44,3,2],
[52,44,4,0],
[52,44,4,1],
[52,44,4,2],
[52,44,4,3],
[52,44,5,0],
[52,44,5,1],
[52,44,5,2],
[52,44,5,4],
[52,44,6,0],
[52,44,6,1],
[52,44,6,2],
[52,44,6,4],
[52,44,7,0],
[52,44,7,1],
[52,44,7,2],
[52,44,7,3],
[52,44,7,4],
[52,44,7,5],
[52,44,7,6],
[52,44,8,0],
[52,44,8,1],
[52,44,8,2],
[52,44,8,4],
[52,44,8,7],
[52,44,10,0],
[52,44,10,1],
[52,44,10,2],
[52,44,10,3],
[52,44,10,4],
[52,44,10,5],
[52,44,10,6],
[52,44,10,7],
[52,44,10,8],
[52,44,11,0],
[52,44,11,1],
[52,44,11,2],
[52,44,11,3],
[52,44,11,4],
[52,44,11,5],
[52,44,11,6],
[52,44,11,7],
[52,44,11,8],
[52,44,11,10],
[52,44,12,0],
[52,44,12,1],
[52,44,12,2],
[52,44,12,3],
[52,44,12,4],
[52,44,12,5],
[52,44,12,6],
[52,44,12,7],
[52,44,12,8],
[52,44,12,10],
[52,44,13,0],
[52,44,13,1],
[52,44,13,2],
[52,44,13,3],
[52,44,13,4],
[52,44,13,5],
[52,44,13,6],
[52,44,13,7],
[52,44,13,8],
[52,44,13,10],
[52,44,13,11],
[52,44,13,12],
[52,44,14,0],
[52,44,14,1],
[52,44,14,2],
[52,44,14,3],
[52,44,14,4],
[52,44,14,5],
[52,44,14,6],
[52,44,14,7],
[52,44,14,8],
[52,44,14,10],
[52,44,14,11],
[52,44,14,12],
[52,44,16,0],
[52,44,16,1],
[52,44,16,2],
[52,44,16,3],
[52,44,16,4],
[52,44,16,5],
[52,44,16,6],
[52,44,16,7],
[52,44,16,8],
[52,44,16,10],
[52,44,16,11],
[52,44,16,12],
[52,44,16,13],
[52,44,16,14],
[52,44,19,0],
[52,44,19,1],
[52,44,19,2],
[52,44,19,3],
[52,44,19,4],
[52,44,19,5],
[52,44,19,6],
[52,44,19,7],
[52,44,19,8],
[52,44,19,10],
[52,44,19,11],
[52,44,19,12],
[52,44,19,16],
[52,44,20,0],
[52,44,20,1],
[52,44,20,2],
[52,44,20,3],
[52,44,20,4],
[52,44,20,5],
[52,44,20,6],
[52,44,20,7],
[52,44,20,8],
[52,44,20,11],
[52,44,20,12],
[52,44,20,13],
[52,44,20,14],
[52,44,20,19],
[52,44,21,0],
[52,44,21,1],
[52,44,21,2],
[52,44,21,4],
[52,44,21,7],
[52,44,21,10],
[52,44,21,11],
[52,44,21,12],
[52,44,21,13],
[52,44,21,14],
[52,44,21,16],
[52,44,21,19],
[52,44,21,20],
[52,44,22,0],
[52,44,22,1],
[52,44,22,2],
[52,44,22,3],
[52,44,22,4],
[52,44,22,5],
[52,44,22,6],
[52,44,22,7],
[52,44,22,8],
[52,44,22,11],
[52,44,22,12],
[52,44,22,13],
[52,44,22,16],
[52,44,22,19],
[52,44,22,21],
[52,44,23,0],
[52,44,23,1],
[52,44,23,2],
[52,44,23,3],
[52,44,23,4],
[52,44,23,5],
[52,44,23,6],
[52,44,23,7],
[52,44,23,8],
[52,44,23,10],
[52,44,23,11],
[52,44,23,14],
[52,44,23,16],
[52,44,23,19],
[52,44,23,20],
[52,44,23,21],
[52,44,23,22],
[52,44,24,0],
[52,44,24,1],
[52,44,24,2],
[52,44,24,3],
[52,44,24,4],
[52,44,24,5],
[52,44,24,6],
[52,44,24,7],
[52,44,24,8],
[52,44,24,10],
[52,44,24,11],
[52,44,24,14],
[52,44,24,19],
[52,44,24,20],
[52,44,24,21],
[52,44,24,22],
[52,44,24,23],
[52,44,25,0],
[52,44,25,1],
[52,44,25,2],
[52,44,25,3],
[52,44,25,4],
[52,44,25,5],
[52,44,25,6],
[52,44,25,10],
[52,44,25,11],
[52,44,25,12],
[52,44,25,13],
[52,44,25,14],
[52,44,25,16],
[52,44,25,19],
[52,44,25,20],
[52,44,25,21],
[52,44,25,22],
[52,44,25,23],
[52,44,25,24],
[52,44,27,0],
[52,44,27,1],
[52,44,27,2],
[52,44,27,3],
[52,44,27,4],
[52,44,27,6],
[52,44,27,7],
[52,44,27,8],
[52,44,27,11],
[52,44,27,12],
[52,44,27,13],
[52,44,27,14],
[52,44,27,16],
[52,44,27,19],
[52,44,27,21],
[52,44,28,0],
[52,44,28,1],
[52,44,28,2],
[52,44,28,5],
[52,44,28,6],
[52,44,28,8],
[52,44,28,10],
[52,44,28,11],
[52,44,28,12],
[52,44,28,13],
[52,44,28,14],
[52,44,28,16],
[52,44,28,19],
[52,44,28,20],
[52,44,28,21],
[52,44,29,3],
[52,44,29,4],
[52,44,29,5],
[52,44,29,6],
[52,44,29,7],
[52,44,29,8],
[52,44,29,10],
[52,44,29,13],
[52,44,29,14],
[52,44,29,16],
[52,44,29,19],
[52,44,29,20],
[52,44,30,0],
[52,44,30,1],
[52,44,30,2],
[52,44,30,3],
[52,44,30,4],
[52,44,30,5],
[52,44,30,6],
[52,44,30,7],
[52,44,30,8],
[52,44,30,10],
[52,44,30,11],
[52,44,30,12],
[52,44,30,13],
[52,44,30,14],
[52,44,30,16],
[52,44,30,19],
[52,44,31,0],
[52,44,31,1],
[52,44,31,2],
[52,44,31,3],
[52,44,31,4],
[52,44,31,5],
[52,44,31,6],
[52,44,31,7],
[52,44,31,8],
[52,44,31,10],
[52,44,31,11],
[52,44,31,12],
[52,44,31,13],
[52,44,31,14],
[52,44,31,16],
[52,44,32,0],
[52,44,32,1],
[52,44,32,2],
[52,44,32,3],
[52,44,32,4],
[52,44,32,5],
[52,44,32,6],
[52,44,32,7],
[52,44,32,8],
[52,44,32,10],
[52,44,32,13],
[52,44,32,14],
[52,44,32,16],
[52,44,33,0],
[52,44,33,1],
[52,44,33,2],
[52,44,33,3],
[52,44,33,4],
[52,44,33,5],
[52,44,33,6],
[52,44,33,7],
[52,44,33,8],
[52,44,33,10],
[52,44,33,13],
[52,44,33,14],
[52,44,34,0],
[52,44,34,1],
[52,44,34,2],
[52,44,34,3],
[52,44,34,4],
[52,44,34,5],
[52,44,34,6],
[52,44,34,8],
[52,44,34,10],
[52,44,34,11],
[52,44,34,12],
[52,44,34,13],
[52,44,34,14],
[52,44,36,0],
[52,44,36,1],
[52,44,36,2],
[52,44,36,3],
[52,44,36,4],
[52,44,36,5],
[52,44,36,6],
[52,44,36,7],
[52,44,36,8],
[52,44,36,10],
[52,44,36,11],
[52,44,37,0],
[52,44,37,1],
[52,44,37,2],
[52,44,37,3],
[52,44,37,5],
[52,44,37,6],
[52,44,37,7],
[52,44,37,8],
[52,44,38,0],
[52,44,38,1],
[52,44,38,2],
[52,44,38,3],
[52,44,38,4],
[52,44,38,5],
[52,44,38,6],
[52,44,39,0],
[52,44,39,1],
[52,44,39,2],
[52,44,39,4],
[52,44,42,0],
[52,44,42,1],
[52,44,42,2],
[52,47,3,0],
[52,47,3,1],
[52,47,3,2],
[52,47,4,0],
[52,47,4,1],
[52,47,4,2],
[52,47,4,3],
[52,47,5,0],
[52,47,5,1],
[52,47,5,2],
[52,47,5,4],
[52,47,6,0],
[52,47,6,1],
[52,47,6,2],
[52,47,6,4],
[52,47,7,0],
[52,47,7,1],
[52,47,7,2],
[52,47,7,3],
[52,47,7,4],
[52,47,7,5],
[52,47,7,6],
[52,47,8,0],
[52,47,8,1],
[52,47,8,2],
[52,47,8,4],
[52,47,8,7],
[52,47,10,0],
[52,47,10,1],
[52,47,10,2],
[52,47,10,3],
[52,47,10,4],
[52,47,10,5],
[52,47,10,6],
[52,47,10,7],
[52,47,10,8],
[52,47,11,0],
[52,47,11,1],
[52,47,11,2],
[52,47,11,3],
[52,47,11,4],
[52,47,11,5],
[52,47,11,6],
[52,47,11,7],
[52,47,11,8],
[52,47,11,10],
[52,47,12,0],
[52,47,12,1],
[52,47,12,2],
[52,47,12,3],
[52,47,12,4],
[52,47,12,5],
[52,47,12,6],
[52,47,12,7],
[52,47,12,8],
[52,47,12,10],
[52,47,13,0],
[52,47,13,1],
[52,47,13,2],
[52,47,13,3],
[52,47,13,4],
[52,47,13,5],
[52,47,13,6],
[52,47,13,7],
[52,47,13,8],
[52,47,13,10],
[52,47,13,11],
[52,47,13,12],
[52,47,14,0],
[52,47,14,1],
[52,47,14,2],
[52,47,14,3],
[52,47,14,4],
[52,47,14,5],
[52,47,14,6],
[52,47,14,7],
[52,47,14,8],
[52,47,14,10],
[52,47,14,11],
[52,47,14,12],
[52,47,16,0],
[52,47,16,1],
[52,47,16,2],
[52,47,16,3],
[52,47,16,4],
[52,47,16,5],
[52,47,16,6],
[52,47,16,7],
[52,47,16,8],
[52,47,16,10],
[52,47,16,11],
[52,47,16,12],
[52,47,16,13],
[52,47,16,14],
[52,47,17,0],
[52,47,17,1],
[52,47,17,2],
[52,47,17,3],
[52,47,17,4],
[52,47,17,5],
[52,47,17,6],
[52,47,17,7],
[52,47,17,8],
[52,47,17,10],
[52,47,17,11],
[52,47,17,12],
[52,47,17,13],
[52,47,17,14],
[52,47,19,0],
[52,47,19,1],
[52,47,19,2],
[52,47,19,3],
[52,47,19,4],
[52,47,19,5],
[52,47,19,6],
[52,47,19,7],
[52,47,19,8],
[52,47,19,10],
[52,47,19,11],
[52,47,19,12],
[52,47,19,16],
[52,47,19,17],
[52,47,20,0],
[52,47,20,1],
[52,47,20,2],
[52,47,20,3],
[52,47,20,4],
[52,47,20,5],
[52,47,20,6],
[52,47,20,7],
[52,47,20,8],
[52,47,20,11],
[52,47,20,14],
[52,47,20,16],
[52,47,20,17],
[52,47,20,19],
[52,47,21,0],
[52,47,21,1],
[52,47,21,2],
[52,47,21,4],
[52,47,21,7],
[52,47,21,12],
[52,47,21,13],
[52,47,21,14],
[52,47,21,16],
[52,47,21,17],
[52,47,21,19],
[52,47,21,20],
[52,47,22,0],
[52,47,22,1],
[52,47,22,2],
[52,47,22,3],
[52,47,22,4],
[52,47,22,5],
[52,47,22,6],
[52,47,22,7],
[52,47,22,11],
[52,47,22,12],
[52,47,22,13],
[52,47,22,14],
[52,47,22,16],
[52,47,22,17],
[52,47,22,19],
[52,47,22,21],
[52,47,24,0],
[52,47,24,1],
[52,47,24,2],
[52,47,24,3],
[52,47,24,4],
[52,47,24,5],
[52,47,24,8],
[52,47,24,10],
[52,47,24,11],
[52,47,24,12],
[52,47,24,13],
[52,47,24,14],
[52,47,24,17],
[52,47,24,19],
[52,47,24,20],
[52,47,24,21],
[52,47,24,22],
[52,47,25,0],
[52,47,25,1],
[52,47,25,2],
[52,47,25,5],
[52,47,25,6],
[52,47,25,8],
[52,47,25,10],
[52,47,25,11],
[52,47,25,12],
[52,47,25,13],
[52,47,25,14],
[52,47,25,16],
[52,47,25,17],
[52,47,25,19],
[52,47,25,20],
[52,47,25,21],
[52,47,27,0],
[52,47,27,1],
[52,47,27,2],
[52,47,27,3],
[52,47,27,4],
[52,47,27,5],
[52,47,27,6],
[52,47,27,7],
[52,47,27,8],
[52,47,27,11],
[52,47,27,12],
[52,47,27,13],
[52,47,27,14],
[52,47,27,16],
[52,47,27,17],
[52,47,27,19],
[52,47,28,0],
[52,47,28,1],
[52,47,28,2],
[52,47,28,3],
[52,47,28,4],
[52,47,28,5],
[52,47,28,6],
[52,47,28,8],
[52,47,28,10],
[52,47,28,11],
[52,47,28,12],
[52,47,28,13],
[52,47,28,14],
[52,47,28,16],
[52,47,28,17],
[52,47,29,0],
[52,47,29,1],
[52,47,29,2],
[52,47,29,3],
[52,47,29,4],
[52,47,29,5],
[52,47,29,6],
[52,47,29,7],
[52,47,29,8],
[52,47,29,10],
[52,47,29,13],
[52,47,29,14],
[52,47,29,16],
[52,47,29,17],
[52,47,32,0],
[52,47,32,1],
[52,47,32,2],
[52,47,32,3],
[52,47,32,4],
[52,47,32,5],
[52,47,32,6],
[52,47,32,7],
[52,47,32,8],
[52,47,32,10],
[52,47,32,13],
[52,47,33,0],
[52,47,33,1],
[52,47,33,2],
[52,47,33,3],
[52,47,33,4],
[52,47,33,5],
[52,47,33,6],
[52,47,33,7],
[52,47,33,8],
[52,47,33,10],
[52,47,34,0],
[52,47,34,1],
[52,47,34,2],
[52,47,34,3],
[52,47,34,4],
[52,47,34,5],
[52,47,34,6],
[52,47,34,8],
[52,47,36,0],
[52,47,36,1],
[52,47,36,2],
[52,47,36,3],
[52,47,36,4],
[52,47,36,5],
[52,47,37,0],
[52,47,37,1],
[52,47,37,2],
[52,47,37,3],
[52,47,38,0],
[52,47,38,1],
[52,47,38,2],
[52,48,3,0],
[52,48,3,1],
[52,48,3,2],
[52,48,4,0],
[52,48,4,1],
[52,48,4,2],
[52,48,4,3],
[52,48,5,0],
[52,48,5,1],
[52,48,5,2],
[52,48,5,4],
[52,48,6,0],
[52,48,6,1],
[52,48,6,2],
[52,48,6,4],
[52,48,7,0],
[52,48,7,1],
[52,48,7,2],
[52,48,7,3],
[52,48,7,4],
[52,48,7,5],
[52,48,7,6],
[52,48,8,0],
[52,48,8,1],
[52,48,8,2],
[52,48,8,4],
[52,48,8,7],
[52,48,10,0],
[52,48,10,1],
[52,48,10,2],
[52,48,10,3],
[52,48,10,4],
[52,48,10,5],
[52,48,10,6],
[52,48,10,7],
[52,48,10,8],
[52,48,13,0],
[52,48,13,1],
[52,48,13,2],
[52,48,13,3],
[52,48,13,4],
[52,48,13,5],
[52,48,13,6],
[52,48,13,7],
[52,48,13,8],
[52,48,13,10],
[52,48,14,0],
[52,48,14,1],
[52,48,14,2],
[52,48,14,3],
[52,48,14,4],
[52,48,14,5],
[52,48,14,6],
[52,48,14,7],
[52,48,14,8],
[52,48,14,10],
[52,48,16,0],
[52,48,16,1],
[52,48,16,2],
[52,48,16,3],
[52,48,16,4],
[52,48,16,5],
[52,48,16,6],
[52,48,16,7],
[52,48,16,8],
[52,48,16,10],
[52,48,16,13],
[52,48,16,14],
[52,48,17,0],
[52,48,17,1],
[52,48,17,2],
[52,48,17,3],
[52,48,17,4],
[52,48,17,5],
[52,48,17,6],
[52,48,17,7],
[52,48,17,8],
[52,48,17,10],
[52,48,17,13],
[52,48,17,14],
[52,48,17,16],
[52,48,19,0],
[52,48,19,1],
[52,48,19,2],
[52,48,19,3],
[52,48,19,4],
[52,48,19,5],
[52,48,19,6],
[52,48,19,7],
[52,48,19,8],
[52,48,19,10],
[52,48,19,16],
[52,48,19,17],
[52,48,20,0],
[52,48,20,1],
[52,48,20,2],
[52,48,20,3],
[52,48,20,4],
[52,48,20,5],
[52,48,20,6],
[52,48,20,7],
[52,48,20,8],
[52,48,20,13],
[52,48,20,14],
[52,48,20,16],
[52,48,20,17],
[52,48,20,19],
[52,48,21,0],
[52,48,21,1],
[52,48,21,2],
[52,48,21,4],
[52,48,21,7],
[52,48,21,10],
[52,48,21,13],
[52,48,21,14],
[52,48,21,16],
[52,48,21,17],
[52,48,21,19],
[52,48,21,20],
[52,48,22,0],
[52,48,22,1],
[52,48,22,2],
[52,48,22,3],
[52,48,22,4],
[52,48,22,5],
[52,48,22,8],
[52,48,22,13],
[52,48,22,14],
[52,48,22,16],
[52,48,22,17],
[52,48,22,19],
[52,48,22,21],
[52,48,23,0],
[52,48,23,1],
[52,48,23,2],
[52,48,23,3],
[52,48,23,4],
[52,48,23,6],
[52,48,23,7],
[52,48,23,8],
[52,48,23,10],
[52,48,23,13],
[52,48,23,14],
[52,48,23,16],
[52,48,23,17],
[52,48,23,19],
[52,48,23,20],
[52,48,23,21],
[52,48,23,22],
[52,48,24,0],
[52,48,24,1],
[52,48,24,2],
[52,48,24,3],
[52,48,24,4],
[52,48,24,6],
[52,48,24,7],
[52,48,24,8],
[52,48,24,10],
[52,48,24,13],
[52,48,24,14],
[52,48,24,17],
[52,48,24,19],
[52,48,24,20],
[52,48,24,21],
[52,48,24,22],
[52,48,25,3],
[52,48,25,4],
[52,48,25,5],
[52,48,25,6],
[52,48,25,8],
[52,48,25,10],
[52,48,25,13],
[52,48,25,14],
[52,48,25,16],
[52,48,25,17],
[52,48,25,19],
[52,48,25,20],
[52,48,27,0],
[52,48,27,1],
[52,48,27,2],
[52,48,27,3],
[52,48,27,4],
[52,48,27,5],
[52,48,27,6],
[52,48,27,7],
[52,48,27,8],
[52,48,27,13],
[52,48,27,14],
[52,48,27,16],
[52,48,27,17],
[52,48,28,0],
[52,48,28,1],
[52,48,28,2],
[52,48,28,3],
[52,48,28,4],
[52,48,28,5],
[52,48,28,6],
[52,48,28,8],
[52,48,28,10],
[52,48,28,13],
[52,48,28,14],
[52,48,28,16],
[52,48,28,17],
[52,48,30,0],
[52,48,30,1],
[52,48,30,2],
[52,48,30,3],
[52,48,30,4],
[52,48,30,5],
[52,48,30,6],
[52,48,30,7],
[52,48,30,8],
[52,48,30,10],
[52,48,30,13],
[52,48,30,14],
[52,48,31,0],
[52,48,31,1],
[52,48,31,2],
[52,48,31,3],
[52,48,31,4],
[52,48,31,5],
[52,48,31,6],
[52,48,31,7],
[52,48,31,8],
[52,48,31,10],
[52,48,31,13],
[52,48,34,0],
[52,48,34,1],
[52,48,34,2],
[52,48,34,3],
[52,48,34,4],
[52,48,34,5],
[52,48,34,6],
[52,48,36,0],
[52,48,36,1],
[52,48,36,2],
[52,48,36,3],
[52,48,36,4],
[52,48,37,0],
[52,48,37,1],
[52,48,37,2],
[52,51,4,0],
[52,51,4,1],
[52,51,4,2],
[52,51,7,0],
[52,51,7,1],
[52,51,7,2],
[52,51,7,4],
[52,51,10,0],
[52,51,10,1],
[52,51,10,2],
[52,51,10,4],
[52,51,10,7],
[52,51,11,0],
[52,51,11,1],
[52,51,11,2],
[52,51,11,4],
[52,51,11,7],
[52,51,11,10],
[52,51,12,0],
[52,51,12,1],
[52,51,12,2],
[52,51,12,4],
[52,51,12,7],
[52,51,12,10],
[52,51,13,0],
[52,51,13,1],
[52,51,13,2],
[52,51,13,4],
[52,51,13,7],
[52,51,13,10],
[52,51,13,11],
[52,51,13,12],
[52,51,14,0],
[52,51,14,1],
[52,51,14,2],
[52,51,14,4],
[52,51,14,7],
[52,51,14,10],
[52,51,14,11],
[52,51,14,12],
[52,51,16,0],
[52,51,16,1],
[52,51,16,2],
[52,51,16,4],
[52,51,16,7],
[52,51,16,10],
[52,51,16,11],
[52,51,16,12],
[52,51,16,13],
[52,51,16,14],
[52,51,17,0],
[52,51,17,1],
[52,51,17,2],
[52,51,17,4],
[52,51,17,7],
[52,51,17,10],
[52,51,17,11],
[52,51,17,12],
[52,51,17,13],
[52,51,17,14],
[52,51,17,16],
[52,51,19,0],
[52,51,19,1],
[52,51,19,2],
[52,51,19,4],
[52,51,19,7],
[52,51,19,10],
[52,51,19,11],
[52,51,19,16],
[52,51,19,17],
[52,51,20,0],
[52,51,20,1],
[52,51,20,2],
[52,51,20,4],
[52,51,20,7],
[52,51,20,12],
[52,51,20,13],
[52,51,20,14],
[52,51,20,16],
[52,51,20,17],
[52,51,20,19],
[52,51,22,0],
[52,51,22,1],
[52,51,22,2],
[52,51,22,4],
[52,51,22,11],
[52,51,22,12],
[52,51,22,13],
[52,51,22,14],
[52,51,22,16],
[52,51,22,17],
[52,51,22,19],
[52,51,23,0],
[52,51,23,1],
[52,51,23,2],
[52,51,23,4],
[52,51,23,7],
[52,51,23,10],
[52,51,23,11],
[52,51,23,12],
[52,51,23,13],
[52,51,23,14],
[52,51,23,16],
[52,51,23,17],
[52,51,23,19],
[52,51,23,20],
[52,51,23,22],
[52,51,24,0],
[52,51,24,1],
[52,51,24,2],
[52,51,24,4],
[52,51,24,7],
[52,51,24,10],
[52,51,24,11],
[52,51,24,12],
[52,51,24,13],
[52,51,24,14],
[52,51,24,17],
[52,51,24,19],
[52,51,24,20],
[52,51,24,22],
[52,51,25,4],
[52,51,25,10],
[52,51,25,11],
[52,51,25,12],
[52,51,25,13],
[52,51,25,14],
[52,51,25,16],
[52,51,25,17],
[52,51,25,19],
[52,51,25,20],
[52,51,27,0],
[52,51,27,1],
[52,51,27,2],
[52,51,27,4],
[52,51,27,7],
[52,51,27,11],
[52,51,27,12],
[52,51,27,13],
[52,51,27,14],
[52,51,27,16],
[52,51,27,17],
[52,51,28,0],
[52,51,28,1],
[52,51,28,2],
[52,51,28,4],
[52,51,28,10],
[52,51,28,11],
[52,51,28,12],
[52,51,28,13],
[52,51,28,14],
[52,51,28,16],
[52,51,28,17],
[52,51,29,0],
[52,51,29,1],
[52,51,29,2],
[52,51,29,4],
[52,51,29,7],
[52,51,29,10],
[52,51,29,13],
[52,51,29,14],
[52,51,30,0],
[52,51,30,1],
[52,51,30,2],
[52,51,30,4],
[52,51,30,7],
[52,51,30,10],
[52,51,30,11],
[52,51,30,12],
[52,51,30,13],
[52,51,30,14],
[52,51,31,0],
[52,51,31,1],
[52,51,31,2],
[52,51,31,4],
[52,51,31,7],
[52,51,31,10],
[52,51,31,11],
[52,51,31,12],
[52,51,31,13],
[52,51,32,0],
[52,51,32,1],
[52,51,32,2],
[52,51,32,4],
[52,51,32,7],
[52,51,32,10],
[52,51,33,0],
[52,51,33,1],
[52,51,33,2],
[52,51,33,4],
[52,51,33,7],
[52,51,34,0],
[52,51,34,1],
[52,51,34,2],
[52,51,34,4],
[52,51,36,0],
[52,51,36,1],
[52,51,36,2],
[52,51,36,4],
[52,51,37,0],
[52,51,37,1],
[52,51,37,2],
[53,4,3,0],
[53,4,3,1],
[53,4,3,2],
[53,5,4,0],
[53,5,4,1],
[53,5,4,2],
[53,6,4,0],
[53,6,4,1],
[53,6,4,2],
[53,7,3,0],
[53,7,3,1],
[53,7,3,2],
[53,7,4,0],
[53,7,4,1],
[53,7,4,2],
[53,7,4,3],
[53,7,5,0],
[53,7,5,1],
[53,7,5,2],
[53,7,5,4],
[53,7,6,0],
[53,7,6,1],
[53,7,6,2],
[53,7,6,4],
[53,8,4,0],
[53,8,4,1],
[53,8,4,2],
[53,8,7,0],
[53,8,7,1],
[53,8,7,2],
[53,8,7,4],
[53,9,3,0],
[53,9,3,1],
[53,9,3,2],
[53,9,4,0],
[53,9,4,1],
[53,9,4,2],
[53,9,4,3],
[53,9,5,0],
[53,9,5,1],
[53,9,5,2],
[53,9,5,4],
[53,9,6,0],
[53,9,6,1],
[53,9,6,2],
[53,9,6,4],
[53,9,7,0],
[53,9,7,1],
[53,9,7,2],
[53,9,7,3],
[53,9,7,4],
[53,9,7,5],
[53,9,7,6],
[53,9,8,0],
[53,9,8,1],
[53,9,8,2],
[53,9,8,4],
[53,9,8,7],
[53,11,3,0],
[53,11,3,1],
[53,11,3,2],
[53,11,4,0],
[53,11,4,1],
[53,11,4,2],
[53,11,4,3],
[53,11,5,0],
[53,11,5,1],
[53,11,5,2],
[53,11,5,4],
[53,11,6,0],
[53,11,6,1],
[53,11,6,2],
[53,11,6,4],
[53,11,7,0],
[53,11,7,1],
[53,11,7,2],
[53,11,7,3],
[53,11,7,4],
[53,11,7,5],
[53,11,7,6],
[53,11,8,0],
[53,11,8,1],
[53,11,8,2],
[53,11,8,4],
[53,11,8,7],
[53,11,9,0],
[53,11,9,1],
[53,11,9,2],
[53,11,9,3],
[53,11,9,4],
[53,11,9,5],
[53,11,9,6],
[53,11,9,7],
[53,11,9,8],
[53,12,3,0],
[53,12,3,1],
[53,12,3,2],
[53,12,4,0],
[53,12,4,1],
[53,12,4,2],
[53,12,4,3],
[53,12,5,0],
[53,12,5,1],
[53,12,5,2],
[53,12,5,4],
[53,12,6,0],
[53,12,6,1],
[53,12,6,2],
[53,12,6,4],
[53,12,7,0],
[53,12,7,1],
[53,12,7,2],
[53,12,7,3],
[53,12,7,4],
[53,12,7,5],
[53,12,7,6],
[53,12,8,0],
[53,12,8,1],
[53,12,8,2],
[53,12,8,4],
[53,12,8,7],
[53,12,9,0],
[53,12,9,1],
[53,12,9,2],
[53,12,9,3],
[53,12,9,4],
[53,12,9,5],
[53,12,9,6],
[53,12,9,7],
[53,12,9,8],
[53,13,3,0],
[53,13,3,1],
[53,13,3,2],
[53,13,4,0],
[53,13,4,1],
[53,13,4,2],
[53,13,4,3],
[53,13,5,0],
[53,13,5,1],
[53,13,5,2],
[53,13,5,4],
[53,13,6,0],
[53,13,6,1],
[53,13,6,2],
[53,13,6,4],
[53,13,7,0],
[53,13,7,1],
[53,13,7,2],
[53,13,7,3],
[53,13,7,4],
[53,13,7,5],
[53,13,7,6],
[53,13,8,0],
[53,13,8,1],
[53,13,8,2],
[53,13,8,4],
[53,13,8,7],
[53,13,9,0],
[53,13,9,1],
[53,13,9,2],
[53,13,9,3],
[53,13,9,4],
[53,13,9,5],
[53,13,9,6],
[53,13,9,7],
[53,13,9,8],
[53,13,11,0],
[53,13,11,1],
[53,13,11,2],
[53,13,11,3],
[53,13,11,4],
[53,13,11,5],
[53,13,11,6],
[53,13,11,7],
[53,13,11,8],
[53,13,11,9],
[53,13,12,0],
[53,13,12,1],
[53,13,12,2],
[53,13,12,3],
[53,13,12,4],
[53,13,12,5],
[53,13,12,6],
[53,13,12,7],
[53,13,12,8],
[53,13,12,9],
[53,14,3,0],
[53,14,3,1],
[53,14,3,2],
[53,14,4,0],
[53,14,4,1],
[53,14,4,2],
[53,14,4,3],
[53,14,5,0],
[53,14,5,1],
[53,14,5,2],
[53,14,5,4],
[53,14,6,0],
[53,14,6,1],
[53,14,6,2],
[53,14,6,4],
[53,14,7,0],
[53,14,7,1],
[53,14,7,2],
[53,14,7,3],
[53,14,7,4],
[53,14,7,5],
[53,14,7,6],
[53,14,8,0],
[53,14,8,1],
[53,14,8,2],
[53,14,8,4],
[53,14,8,7],
[53,14,9,0],
[53,14,9,1],
[53,14,9,2],
[53,14,9,3],
[53,14,9,4],
[53,14,9,5],
[53,14,9,6],
[53,14,9,7],
[53,14,9,8],
[53,14,11,0],
[53,14,11,1],
[53,14,11,2],
[53,14,11,3],
[53,14,11,4],
[53,14,11,5],
[53,14,11,6],
[53,14,11,7],
[53,14,11,8],
[53,14,11,9],
[53,14,12,0],
[53,14,12,1],
[53,14,12,2],
[53,14,12,3],
[53,14,12,4],
[53,14,12,5],
[53,14,12,6],
[53,14,12,7],
[53,14,12,8],
[53,14,12,9],
[53,15,3,0],
[53,15,3,1],
[53,15,3,2],
[53,15,4,0],
[53,15,4,1],
[53,15,4,2],
[53,15,4,3],
[53,15,5,0],
[53,15,5,1],
[53,15,5,2],
[53,15,5,4],
[53,15,6,0],
[53,15,6,1],
[53,15,6,2],
[53,15,6,4],
[53,15,7,0],
[53,15,7,1],
[53,15,7,2],
[53,15,7,3],
[53,15,7,4],
[53,15,7,5],
[53,15,7,6],
[53,15,8,0],
[53,15,8,1],
[53,15,8,2],
[53,15,8,4],
[53,15,8,7],
[53,15,11,0],
[53,15,11,1],
[53,15,11,2],
[53,15,11,3],
[53,15,11,4],
[53,15,11,5],
[53,15,11,6],
[53,15,11,7],
[53,15,11,8],
[53,15,12,0],
[53,15,12,1],
[53,15,12,2],
[53,15,12,3],
[53,15,12,4],
[53,15,12,5],
[53,15,12,6],
[53,15,12,7],
[53,15,12,8],
[53,15,13,0],
[53,15,13,1],
[53,15,13,2],
[53,15,13,3],
[53,15,13,4],
[53,15,13,5],
[53,15,13,6],
[53,15,13,7],
[53,15,13,8],
[53,15,13,11],
[53,15,13,12],
[53,15,14,0],
[53,15,14,1],
[53,15,14,2],
[53,15,14,3],
[53,15,14,4],
[53,15,14,5],
[53,15,14,6],
[53,15,14,7],
[53,15,14,8],
[53,15,14,11],
[53,15,14,12],
[53,16,3,0],
[53,16,3,1],
[53,16,3,2],
[53,16,4,0],
[53,16,4,1],
[53,16,4,2],
[53,16,4,3],
[53,16,5,0],
[53,16,5,1],
[53,16,5,2],
[53,16,5,4],
[53,16,6,0],
[53,16,6,1],
[53,16,6,2],
[53,16,6,4],
[53,16,7,0],
[53,16,7,1],
[53,16,7,2],
[53,16,7,3],
[53,16,7,4],
[53,16,7,5],
[53,16,7,6],
[53,16,8,0],
[53,16,8,1],
[53,16,8,2],
[53,16,8,4],
[53,16,8,7],
[53,16,9,0],
[53,16,9,1],
[53,16,9,2],
[53,16,9,3],
[53,16,9,4],
[53,16,9,5],
[53,16,9,6],
[53,16,9,7],
[53,16,9,8],
[53,16,11,0],
[53,16,11,1],
[53,16,11,2],
[53,16,11,3],
[53,16,11,4],
[53,16,11,5],
[53,16,11,6],
[53,16,11,7],
[53,16,11,8],
[53,16,11,9],
[53,16,12,0],
[53,16,12,1],
[53,16,12,2],
[53,16,12,3],
[53,16,12,4],
[53,16,12,5],
[53,16,12,6],
[53,16,12,7],
[53,16,12,8],
[53,16,12,9],
[53,16,13,0],
[53,16,13,1],
[53,16,13,2],
[53,16,13,3],
[53,16,13,4],
[53,16,13,5],
[53,16,13,6],
[53,16,13,7],
[53,16,13,8],
[53,16,13,9],
[53,16,13,11],
[53,16,13,12],
[53,16,14,0],
[53,16,14,1],
[53,16,14,2],
[53,16,14,3],
[53,16,14,4],
[53,16,14,5],
[53,16,14,6],
[53,16,14,7],
[53,16,14,8],
[53,16,14,9],
[53,16,14,11],
[53,16,14,12],
[53,16,15,0],
[53,16,15,1],
[53,16,15,2],
[53,16,15,3],
[53,16,15,4],
[53,16,15,5],
[53,16,15,6],
[53,16,15,7],
[53,16,15,8],
[53,16,15,11],
[53,16,15,12],
[53,16,15,13],
[53,16,15,14],
[53,17,3,0],
[53,17,3,1],
[53,17,3,2],
[53,17,4,0],
[53,17,4,1],
[53,17,4,2],
[53,17,4,3],
[53,17,5,0],
[53,17,5,1],
[53,17,5,2],
[53,17,5,4],
[53,17,6,0],
[53,17,6,1],
[53,17,6,2],
[53,17,6,4],
[53,17,7,0],
[53,17,7,1],
[53,17,7,2],
[53,17,7,3],
[53,17,7,4],
[53,17,7,5],
[53,17,7,6],
[53,17,8,0],
[53,17,8,1],
[53,17,8,2],
[53,17,8,4],
[53,17,8,7],
[53,17,9,0],
[53,17,9,1],
[53,17,9,2],
[53,17,9,3],
[53,17,9,4],
[53,17,9,5],
[53,17,9,6],
[53,17,9,7],
[53,17,9,8],
[53,17,11,0],
[53,17,11,1],
[53,17,11,2],
[53,17,11,3],
[53,17,11,4],
[53,17,11,5],
[53,17,11,6],
[53,17,11,7],
[53,17,11,8],
[53,17,11,9],
[53,17,12,0],
[53,17,12,1],
[53,17,12,2],
[53,17,12,3],
[53,17,12,4],
[53,17,12,5],
[53,17,12,6],
[53,17,12,7],
[53,17,12,8],
[53,17,12,9],
[53,17,13,0],
[53,17,13,1],
[53,17,13,2],
[53,17,13,3],
[53,17,13,4],
[53,17,13,5],
[53,17,13,6],
[53,17,13,7],
[53,17,13,8],
[53,17,13,9],
[53,17,13,11],
[53,17,13,12],
[53,17,14,0],
[53,17,14,1],
[53,17,14,2],
[53,17,14,3],
[53,17,14,4],
[53,17,14,5],
[53,17,14,6],
[53,17,14,7],
[53,17,14,8],
[53,17,14,9],
[53,17,14,11],
[53,17,14,12],
[53,17,15,0],
[53,17,15,1],
[53,17,15,2],
[53,17,15,3],
[53,17,15,4],
[53,17,15,5],
[53,17,15,6],
[53,17,15,7],
[53,17,15,8],
[53,17,15,11],
[53,17,15,12],
[53,17,15,13],
[53,17,15,14],
[53,17,16,0],
[53,17,16,1],
[53,17,16,2],
[53,17,16,3],
[53,17,16,4],
[53,17,16,5],
[53,17,16,6],
[53,17,16,7],
[53,17,16,8],
[53,17,16,9],
[53,17,16,11],
[53,17,16,12],
[53,17,16,13],
[53,17,16,14],
[53,17,16,15],
[53,18,3,0],
[53,18,3,1],
[53,18,3,2],
[53,18,4,0],
[53,18,4,1],
[53,18,4,2],
[53,18,4,3],
[53,18,5,0],
[53,18,5,1],
[53,18,5,2],
[53,18,5,4],
[53,18,6,0],
[53,18,6,1],
[53,18,6,2],
[53,18,6,4],
[53,18,7,0],
[53,18,7,1],
[53,18,7,2],
[53,18,7,3],
[53,18,7,4],
[53,18,7,5],
[53,18,7,6],
[53,18,8,0],
[53,18,8,1],
[53,18,8,2],
[53,18,8,4],
[53,18,8,7],
[53,18,11,0],
[53,18,11,1],
[53,18,11,2],
[53,18,11,3],
[53,18,11,4],
[53,18,11,5],
[53,18,11,6],
[53,18,11,7],
[53,18,11,8],
[53,18,12,0],
[53,18,12,1],
[53,18,12,2],
[53,18,12,3],
[53,18,12,4],
[53,18,12,5],
[53,18,12,6],
[53,18,12,7],
[53,18,12,8],
[53,18,13,0],
[53,18,13,1],
[53,18,13,2],
[53,18,13,3],
[53,18,13,4],
[53,18,13,5],
[53,18,13,6],
[53,18,13,7],
[53,18,13,8],
[53,18,13,11],
[53,18,13,12],
[53,18,14,0],
[53,18,14,1],
[53,18,14,2],
[53,18,14,3],
[53,18,14,4],
[53,18,14,5],
[53,18,14,6],
[53,18,14,7],
[53,18,14,8],
[53,18,14,11],
[53,18,14,12],
[53,18,16,0],
[53,18,16,1],
[53,18,16,2],
[53,18,16,3],
[53,18,16,4],
[53,18,16,5],
[53,18,16,6],
[53,18,16,7],
[53,18,16,8],
[53,18,16,11],
[53,18,16,12],
[53,18,16,13],
[53,18,16,14],
[53,18,17,0],
[53,18,17,1],
[53,18,17,2],
[53,18,17,3],
[53,18,17,4],
[53,18,17,5],
[53,18,17,6],
[53,18,17,7],
[53,18,17,8],
[53,18,17,11],
[53,18,17,12],
[53,18,17,13],
[53,18,17,14],
[53,18,17,16],
[53,19,3,0],
[53,19,3,1],
[53,19,3,2],
[53,19,4,0],
[53,19,4,1],
[53,19,4,2],
[53,19,4,3],
[53,19,5,0],
[53,19,5,1],
[53,19,5,2],
[53,19,5,4],
[53,19,6,0],
[53,19,6,1],
[53,19,6,2],
[53,19,6,4],
[53,19,7,0],
[53,19,7,1],
[53,19,7,2],
[53,19,7,3],
[53,19,7,4],
[53,19,7,5],
[53,19,7,6],
[53,19,8,0],
[53,19,8,1],
[53,19,8,2],
[53,19,8,4],
[53,19,8,7],
[53,19,9,0],
[53,19,9,1],
[53,19,9,2],
[53,19,9,3],
[53,19,9,4],
[53,19,9,5],
[53,19,9,6],
[53,19,9,7],
[53,19,9,8],
[53,19,11,0],
[53,19,11,1],
[53,19,11,2],
[53,19,11,3],
[53,19,11,4],
[53,19,11,5],
[53,19,11,6],
[53,19,11,7],
[53,19,11,8],
[53,19,11,9],
[53,19,12,0],
[53,19,12,1],
[53,19,12,2],
[53,19,12,3],
[53,19,12,4],
[53,19,12,5],
[53,19,12,6],
[53,19,12,7],
[53,19,12,8],
[53,19,12,9],
[53,19,15,0],
[53,19,15,1],
[53,19,15,2],
[53,19,15,3],
[53,19,15,4],
[53,19,15,5],
[53,19,15,6],
[53,19,15,7],
[53,19,15,8],
[53,19,15,11],
[53,19,15,12],
[53,19,16,0],
[53,19,16,1],
[53,19,16,2],
[53,19,16,3],
[53,19,16,4],
[53,19,16,5],
[53,19,16,6],
[53,19,16,7],
[53,19,16,8],
[53,19,16,9],
[53,19,16,11],
[53,19,16,12],
[53,19,16,15],
[53,19,17,0],
[53,19,17,1],
[53,19,17,2],
[53,19,17,3],
[53,19,17,4],
[53,19,17,5],
[53,19,17,6],
[53,19,17,7],
[53,19,17,8],
[53,19,17,9],
[53,19,17,11],
[53,19,17,12],
[53,19,17,15],
[53,19,17,16],
[53,19,18,0],
[53,19,18,1],
[53,19,18,2],
[53,19,18,3],
[53,19,18,4],
[53,19,18,5],
[53,19,18,6],
[53,19,18,7],
[53,19,18,8],
[53,19,18,11],
[53,19,18,12],
[53,19,18,16],
[53,19,18,17],
[53,21,4,0],
[53,21,4,1],
[53,21,4,2],
[53,21,7,0],
[53,21,7,1],
[53,21,7,2],
[53,21,7,4],
[53,21,9,0],
[53,21,9,1],
[53,21,9,2],
[53,21,9,4],
[53,21,9,7],
[53,21,11,0],
[53,21,11,1],
[53,21,11,2],
[53,21,11,4],
[53,21,11,7],
[53,21,11,9],
[53,21,12,0],
[53,21,12,1],
[53,21,12,2],
[53,21,12,4],
[53,21,12,7],
[53,21,12,9],
[53,21,13,0],
[53,21,13,1],
[53,21,13,2],
[53,21,13,4],
[53,21,13,7],
[53,21,13,9],
[53,21,13,11],
[53,21,13,12],
[53,21,14,0],
[53,21,14,1],
[53,21,14,2],
[53,21,14,4],
[53,21,14,7],
[53,21,14,9],
[53,21,14,11],
[53,21,14,12],
[53,21,15,0],
[53,21,15,1],
[53,21,15,2],
[53,21,15,4],
[53,21,15,7],
[53,21,15,11],
[53,21,15,12],
[53,21,15,13],
[53,21,15,14],
[53,21,16,0],
[53,21,16,1],
[53,21,16,2],
[53,21,16,4],
[53,21,16,7],
[53,21,16,9],
[53,21,16,11],
[53,21,16,12],
[53,21,16,13],
[53,21,16,14],
[53,21,16,15],
[53,21,17,0],
[53,21,17,1],
[53,21,17,2],
[53,21,17,4],
[53,21,17,7],
[53,21,17,9],
[53,21,17,11],
[53,21,17,12],
[53,21,17,13],
[53,21,17,14],
[53,21,17,15],
[53,21,17,16],
[53,21,18,0],
[53,21,18,1],
[53,21,18,2],
[53,21,18,4],
[53,21,18,7],
[53,21,18,11],
[53,21,18,12],
[53,21,18,13],
[53,21,18,14],
[53,21,18,16],
[53,21,18,17],
[53,21,19,0],
[53,21,19,1],
[53,21,19,2],
[53,21,19,4],
[53,21,19,7],
[53,21,19,9],
[53,21,19,11],
[53,21,19,12],
[53,21,19,15],
[53,21,19,16],
[53,21,19,17],
[53,21,19,18],
[53,23,3,0],
[53,23,3,1],
[53,23,3,2],
[53,23,4,0],
[53,23,4,1],
[53,23,4,2],
[53,23,4,3],
[53,23,5,0],
[53,23,5,1],
[53,23,5,2],
[53,23,5,4],
[53,23,6,0],
[53,23,6,1],
[53,23,6,2],
[53,23,6,4],
[53,23,7,0],
[53,23,7,1],
[53,23,7,2],
[53,23,7,3],
[53,23,7,4],
[53,23,7,5],
[53,23,7,6],
[53,23,8,0],
[53,23,8,1],
[53,23,8,2],
[53,23,8,4],
[53,23,8,7],
[53,23,9,0],
[53,23,9,1],
[53,23,9,2],
[53,23,9,3],
[53,23,9,4],
[53,23,9,5],
[53,23,9,6],
[53,23,9,7],
[53,23,9,8],
[53,23,11,0],
[53,23,11,1],
[53,23,11,2],
[53,23,11,3],
[53,23,11,4],
[53,23,11,5],
[53,23,11,6],
[53,23,11,7],
[53,23,11,8],
[53,23,11,9],
[53,23,12,0],
[53,23,12,1],
[53,23,12,2],
[53,23,12,3],
[53,23,12,4],
[53,23,12,5],
[53,23,12,6],
[53,23,12,7],
[53,23,12,8],
[53,23,12,9],
[53,23,13,0],
[53,23,13,1],
[53,23,13,2],
[53,23,13,3],
[53,23,13,4],
[53,23,13,5],
[53,23,13,6],
[53,23,13,7],
[53,23,13,8],
[53,23,13,9],
[53,23,13,11],
[53,23,13,12],
[53,23,14,0],
[53,23,14,1],
[53,23,14,2],
[53,23,14,3],
[53,23,14,4],
[53,23,14,5],
[53,23,14,6],
[53,23,14,7],
[53,23,14,8],
[53,23,14,9],
[53,23,14,11],
[53,23,14,12],
[53,23,15,0],
[53,23,15,1],
[53,23,15,2],
[53,23,15,3],
[53,23,15,4],
[53,23,15,5],
[53,23,15,6],
[53,23,15,7],
[53,23,15,8],
[53,23,15,11],
[53,23,15,12],
[53,23,15,13],
[53,23,15,14],
[53,23,16,0],
[53,23,16,1],
[53,23,16,2],
[53,23,16,3],
[53,23,16,4],
[53,23,16,5],
[53,23,16,6],
[53,23,16,7],
[53,23,16,8],
[53,23,16,9],
[53,23,16,11],
[53,23,16,12],
[53,23,16,13],
[53,23,16,14],
[53,23,16,15],
[53,23,17,0],
[53,23,17,1],
[53,23,17,2],
[53,23,17,3],
[53,23,17,4],
[53,23,17,5],
[53,23,17,6],
[53,23,17,7],
[53,23,17,8],
[53,23,17,9],
[53,23,17,11],
[53,23,17,12],
[53,23,17,13],
[53,23,17,14],
[53,23,17,15],
[53,23,17,16],
[53,23,18,0],
[53,23,18,1],
[53,23,18,2],
[53,23,18,3],
[53,23,18,4],
[53,23,18,5],
[53,23,18,6],
[53,23,18,7],
[53,23,18,8],
[53,23,18,11],
[53,23,18,12],
[53,23,18,13],
[53,23,18,14],
[53,23,18,16],
[53,23,18,17],
[53,23,19,0],
[53,23,19,1],
[53,23,19,2],
[53,23,19,3],
[53,23,19,4],
[53,23,19,5],
[53,23,19,6],
[53,23,19,7],
[53,23,19,8],
[53,23,19,9],
[53,23,19,11],
[53,23,19,12],
[53,23,19,15],
[53,23,19,16],
[53,23,19,17],
[53,23,19,18],
[53,23,21,0],
[53,23,21,1],
[53,23,21,2],
[53,23,21,4],
[53,23,21,7],
[53,23,21,9],
[53,23,21,11],
[53,23,21,12],
[53,23,21,13],
[53,23,21,14],
[53,23,21,15],
[53,23,21,16],
[53,23,21,17],
[53,23,21,18],
[53,23,21,19],
[53,24,3,0],
[53,24,3,1],
[53,24,3,2],
[53,24,4,0],
[53,24,4,1],
[53,24,4,2],
[53,24,4,3],
[53,24,5,0],
[53,24,5,1],
[53,24,5,2],
[53,24,5,4],
[53,24,6,0],
[53,24,6,1],
[53,24,6,2],
[53,24,6,4],
[53,24,7,0],
[53,24,7,1],
[53,24,7,2],
[53,24,7,3],
[53,24,7,4],
[53,24,7,5],
[53,24,7,6],
[53,24,8,0],
[53,24,8,1],
[53,24,8,2],
[53,24,8,4],
[53,24,8,7],
[53,24,9,0],
[53,24,9,1],
[53,24,9,2],
[53,24,9,3],
[53,24,9,4],
[53,24,9,5],
[53,24,9,6],
[53,24,9,7],
[53,24,9,8],
[53,24,11,0],
[53,24,11,1],
[53,24,11,2],
[53,24,11,3],
[53,24,11,4],
[53,24,11,5],
[53,24,11,6],
[53,24,11,7],
[53,24,11,8],
[53,24,11,9],
[53,24,12,0],
[53,24,12,1],
[53,24,12,2],
[53,24,12,3],
[53,24,12,4],
[53,24,12,5],
[53,24,12,6],
[53,24,12,7],
[53,24,12,8],
[53,24,12,9],
[53,24,13,0],
[53,24,13,1],
[53,24,13,2],
[53,24,13,3],
[53,24,13,4],
[53,24,13,5],
[53,24,13,6],
[53,24,13,7],
[53,24,13,8],
[53,24,13,9],
[53,24,13,11],
[53,24,13,12],
[53,24,14,0],
[53,24,14,1],
[53,24,14,2],
[53,24,14,3],
[53,24,14,4],
[53,24,14,5],
[53,24,14,6],
[53,24,14,7],
[53,24,14,8],
[53,24,14,9],
[53,24,14,11],
[53,24,14,12],
[53,24,15,0],
[53,24,15,1],
[53,24,15,2],
[53,24,15,3],
[53,24,15,4],
[53,24,15,5],
[53,24,15,6],
[53,24,15,7],
[53,24,15,8],
[53,24,15,11],
[53,24,15,12],
[53,24,15,13],
[53,24,15,14],
[53,24,17,0],
[53,24,17,1],
[53,24,17,2],
[53,24,17,3],
[53,24,17,4],
[53,24,17,5],
[53,24,17,6],
[53,24,17,7],
[53,24,17,8],
[53,24,17,9],
[53,24,17,11],
[53,24,17,12],
[53,24,17,13],
[53,24,17,14],
[53,24,17,15],
[53,24,18,0],
[53,24,18,1],
[53,24,18,2],
[53,24,18,3],
[53,24,18,4],
[53,24,18,5],
[53,24,18,6],
[53,24,18,7],
[53,24,18,8],
[53,24,18,11],
[53,24,18,12],
[53,24,18,13],
[53,24,18,14],
[53,24,18,17],
[53,24,19,0],
[53,24,19,1],
[53,24,19,2],
[53,24,19,3],
[53,24,19,4],
[53,24,19,5],
[53,24,19,6],
[53,24,19,7],
[53,24,19,8],
[53,24,19,9],
[53,24,19,11],
[53,24,19,12],
[53,24,19,15],
[53,24,19,17],
[53,24,19,18],
[53,24,21,0],
[53,24,21,1],
[53,24,21,2],
[53,24,21,4],
[53,24,21,7],
[53,24,21,9],
[53,24,21,11],
[53,24,21,12],
[53,24,21,13],
[53,24,21,14],
[53,24,21,15],
[53,24,21,17],
[53,24,21,18],
[53,24,21,19],
[53,24,23,0],
[53,24,23,1],
[53,24,23,2],
[53,24,23,3],
[53,24,23,4],
[53,24,23,5],
[53,24,23,6],
[53,24,23,7],
[53,24,23,8],
[53,24,23,9],
[53,24,23,11],
[53,24,23,12],
[53,24,23,13],
[53,24,23,14],
[53,24,23,15],
[53,24,23,17],
[53,24,23,18],
[53,24,23,19],
[53,24,23,21],
[53,25,3,0],
[53,25,3,1],
[53,25,3,2],
[53,25,4,0],
[53,25,4,1],
[53,25,4,2],
[53,25,4,3],
[53,25,5,0],
[53,25,5,1],
[53,25,5,2],
[53,25,5,4],
[53,25,6,0],
[53,25,6,1],
[53,25,6,2],
[53,25,6,4],
[53,25,8,0],
[53,25,8,1],
[53,25,8,2],
[53,25,8,4],
[53,25,9,0],
[53,25,9,1],
[53,25,9,2],
[53,25,9,3],
[53,25,9,4],
[53,25,9,5],
[53,25,9,6],
[53,25,9,8],
[53,25,11,0],
[53,25,11,1],
[53,25,11,2],
[53,25,11,3],
[53,25,11,4],
[53,25,11,5],
[53,25,11,6],
[53,25,11,8],
[53,25,11,9],
[53,25,12,0],
[53,25,12,1],
[53,25,12,2],
[53,25,12,3],
[53,25,12,4],
[53,25,12,5],
[53,25,12,6],
[53,25,12,8],
[53,25,12,9],
[53,25,13,0],
[53,25,13,1],
[53,25,13,2],
[53,25,13,3],
[53,25,13,4],
[53,25,13,5],
[53,25,13,6],
[53,25,13,8],
[53,25,13,9],
[53,25,13,11],
[53,25,13,12],
[53,25,14,0],
[53,25,14,1],
[53,25,14,2],
[53,25,14,3],
[53,25,14,4],
[53,25,14,5],
[53,25,14,6],
[53,25,14,8],
[53,25,14,9],
[53,25,14,11],
[53,25,14,12],
[53,25,15,0],
[53,25,15,1],
[53,25,15,2],
[53,25,15,3],
[53,25,15,4],
[53,25,15,5],
[53,25,15,6],
[53,25,15,8],
[53,25,15,11],
[53,25,15,12],
[53,25,15,13],
[53,25,15,14],
[53,25,16,0],
[53,25,16,1],
[53,25,16,2],
[53,25,16,3],
[53,25,16,4],
[53,25,16,5],
[53,25,16,6],
[53,25,16,8],
[53,25,16,9],
[53,25,16,11],
[53,25,16,12],
[53,25,16,13],
[53,25,16,14],
[53,25,16,15],
[53,25,17,0],
[53,25,17,1],
[53,25,17,2],
[53,25,17,3],
[53,25,17,4],
[53,25,17,5],
[53,25,17,6],
[53,25,17,8],
[53,25,17,9],
[53,25,17,11],
[53,25,17,12],
[53,25,17,13],
[53,25,17,14],
[53,25,17,15],
[53,25,17,16],
[53,25,18,0],
[53,25,18,1],
[53,25,18,2],
[53,25,18,3],
[53,25,18,4],
[53,25,18,5],
[53,25,18,6],
[53,25,18,8],
[53,25,18,11],
[53,25,18,12],
[53,25,18,13],
[53,25,18,14],
[53,25,18,16],
[53,25,18,17],
[53,25,19,0],
[53,25,19,1],
[53,25,19,2],
[53,25,19,3],
[53,25,19,4],
[53,25,19,5],
[53,25,19,6],
[53,25,19,8],
[53,25,19,9],
[53,25,19,11],
[53,25,19,12],
[53,25,19,15],
[53,25,19,16],
[53,25,19,17],
[53,25,19,18],
[53,25,21,0],
[53,25,21,1],
[53,25,21,2],
[53,25,21,4],
[53,25,21,9],
[53,25,21,11],
[53,25,21,12],
[53,25,21,13],
[53,25,21,14],
[53,25,21,15],
[53,25,21,16],
[53,25,21,17],
[53,25,21,18],
[53,25,21,19],
[53,25,23,0],
[53,25,23,1],
[53,25,23,2],
[53,25,23,3],
[53,25,23,4],
[53,25,23,5],
[53,25,23,6],
[53,25,23,8],
[53,25,23,9],
[53,25,23,11],
[53,25,23,12],
[53,25,23,13],
[53,25,23,14],
[53,25,23,15],
[53,25,23,16],
[53,25,23,17],
[53,25,23,18],
[53,25,23,19],
[53,25,23,21],
[53,25,24,0],
[53,25,24,1],
[53,25,24,2],
[53,25,24,3],
[53,25,24,4],
[53,25,24,5],
[53,25,24,6],
[53,25,24,8],
[53,25,24,9],
[53,25,24,11],
[53,25,24,12],
[53,25,24,13],
[53,25,24,14],
[53,25,24,15],
[53,25,24,17],
[53,25,24,18],
[53,25,24,19],
[53,25,24,21],
[53,25,24,23],
[53,26,3,0],
[53,26,3,1],
[53,26,3,2],
[53,26,4,0],
[53,26,4,1],
[53,26,4,2],
[53,26,4,3],
[53,26,5,0],
[53,26,5,1],
[53,26,5,2],
[53,26,5,4],
[53,26,6,0],
[53,26,6,1],
[53,26,6,2],
[53,26,6,4],
[53,26,7,0],
[53,26,7,1],
[53,26,7,2],
[53,26,7,3],
[53,26,7,4],
[53,26,7,5],
[53,26,7,6],
[53,26,8,0],
[53,26,8,1],
[53,26,8,2],
[53,26,8,4],
[53,26,8,7],
[53,26,11,0],
[53,26,11,1],
[53,26,11,2],
[53,26,11,3],
[53,26,11,4],
[53,26,11,5],
[53,26,11,6],
[53,26,11,7],
[53,26,11,8],
[53,26,12,0],
[53,26,12,1],
[53,26,12,2],
[53,26,12,3],
[53,26,12,4],
[53,26,12,5],
[53,26,12,6],
[53,26,12,7],
[53,26,12,8],
[53,26,13,0],
[53,26,13,1],
[53,26,13,2],
[53,26,13,3],
[53,26,13,4],
[53,26,13,5],
[53,26,13,6],
[53,26,13,7],
[53,26,13,8],
[53,26,13,11],
[53,26,13,12],
[53,26,14,0],
[53,26,14,1],
[53,26,14,2],
[53,26,14,3],
[53,26,14,4],
[53,26,14,5],
[53,26,14,6],
[53,26,14,7],
[53,26,14,8],
[53,26,14,11],
[53,26,14,12],
[53,26,16,0],
[53,26,16,1],
[53,26,16,2],
[53,26,16,3],
[53,26,16,4],
[53,26,16,5],
[53,26,16,6],
[53,26,16,7],
[53,26,16,8],
[53,26,16,11],
[53,26,16,12],
[53,26,16,13],
[53,26,16,14],
[53,26,17,0],
[53,26,17,1],
[53,26,17,2],
[53,26,17,3],
[53,26,17,4],
[53,26,17,5],
[53,26,17,6],
[53,26,17,7],
[53,26,17,8],
[53,26,17,11],
[53,26,17,12],
[53,26,17,13],
[53,26,17,14],
[53,26,17,16],
[53,26,19,0],
[53,26,19,1],
[53,26,19,2],
[53,26,19,3],
[53,26,19,4],
[53,26,19,5],
[53,26,19,6],
[53,26,19,7],
[53,26,19,8],
[53,26,19,11],
[53,26,19,12],
[53,26,19,16],
[53,26,19,17],
[53,26,21,0],
[53,26,21,1],
[53,26,21,2],
[53,26,21,4],
[53,26,21,7],
[53,26,21,11],
[53,26,21,12],
[53,26,21,13],
[53,26,21,14],
[53,26,21,16],
[53,26,21,17],
[53,26,21,19],
[53,26,23,0],
[53,26,23,1],
[53,26,23,2],
[53,26,23,3],
[53,26,23,4],
[53,26,23,5],
[53,26,23,6],
[53,26,23,7],
[53,26,23,8],
[53,26,23,11],
[53,26,23,12],
[53,26,23,13],
[53,26,23,14],
[53,26,23,16],
[53,26,23,17],
[53,26,23,19],
[53,26,23,21],
[53,26,24,0],
[53,26,24,1],
[53,26,24,2],
[53,26,24,3],
[53,26,24,4],
[53,26,24,5],
[53,26,24,6],
[53,26,24,7],
[53,26,24,8],
[53,26,24,11],
[53,26,24,12],
[53,26,24,13],
[53,26,24,14],
[53,26,24,17],
[53,26,24,19],
[53,26,24,21],
[53,26,24,23],
[53,26,25,0],
[53,26,25,1],
[53,26,25,2],
[53,26,25,3],
[53,26,25,4],
[53,26,25,5],
[53,26,25,6],
[53,26,25,8],
[53,26,25,11],
[53,26,25,12],
[53,26,25,13],
[53,26,25,14],
[53,26,25,16],
[53,26,25,17],
[53,26,25,19],
[53,26,25,21],
[53,26,25,23],
[53,26,25,24],
[53,28,3,0],
[53,28,3,1],
[53,28,3,2],
[53,28,4,0],
[53,28,4,1],
[53,28,4,2],
[53,28,4,3],
[53,28,5,0],
[53,28,5,1],
[53,28,5,2],
[53,28,5,4],
[53,28,6,0],
[53,28,6,1],
[53,28,6,2],
[53,28,6,4],
[53,28,8,0],
[53,28,8,1],
[53,28,8,2],
[53,28,8,4],
[53,28,9,0],
[53,28,9,1],
[53,28,9,2],
[53,28,9,3],
[53,28,9,4],
[53,28,9,5],
[53,28,9,6],
[53,28,9,8],
[53,28,11,0],
[53,28,11,1],
[53,28,11,2],
[53,28,11,3],
[53,28,11,4],
[53,28,11,5],
[53,28,11,6],
[53,28,11,8],
[53,28,11,9],
[53,28,12,0],
[53,28,12,1],
[53,28,12,2],
[53,28,12,3],
[53,28,12,4],
[53,28,12,5],
[53,28,12,6],
[53,28,12,8],
[53,28,12,9],
[53,28,13,0],
[53,28,13,1],
[53,28,13,2],
[53,28,13,3],
[53,28,13,4],
[53,28,13,5],
[53,28,13,6],
[53,28,13,8],
[53,28,13,9],
[53,28,13,11],
[53,28,13,12],
[53,28,14,0],
[53,28,14,1],
[53,28,14,2],
[53,28,14,3],
[53,28,14,4],
[53,28,14,5],
[53,28,14,6],
[53,28,14,8],
[53,28,14,9],
[53,28,14,11],
[53,28,14,12],
[53,28,15,0],
[53,28,15,1],
[53,28,15,2],
[53,28,15,3],
[53,28,15,4],
[53,28,15,5],
[53,28,15,6],
[53,28,15,8],
[53,28,15,11],
[53,28,15,12],
[53,28,15,13],
[53,28,15,14],
[53,28,16,0],
[53,28,16,1],
[53,28,16,2],
[53,28,16,3],
[53,28,16,4],
[53,28,16,5],
[53,28,16,6],
[53,28,16,8],
[53,28,16,9],
[53,28,16,11],
[53,28,16,12],
[53,28,16,13],
[53,28,16,14],
[53,28,16,15],
[53,28,17,0],
[53,28,17,1],
[53,28,17,2],
[53,28,17,3],
[53,28,17,4],
[53,28,17,5],
[53,28,17,6],
[53,28,17,8],
[53,28,17,9],
[53,28,17,11],
[53,28,17,12],
[53,28,17,13],
[53,28,17,14],
[53,28,17,15],
[53,28,17,16],
[53,28,18,0],
[53,28,18,1],
[53,28,18,2],
[53,28,18,3],
[53,28,18,4],
[53,28,18,5],
[53,28,18,6],
[53,28,18,8],
[53,28,18,11],
[53,28,18,12],
[53,28,18,13],
[53,28,18,14],
[53,28,18,16],
[53,28,18,17],
[53,28,19,0],
[53,28,19,1],
[53,28,19,2],
[53,28,19,3],
[53,28,19,4],
[53,28,19,5],
[53,28,19,6],
[53,28,19,8],
[53,28,19,9],
[53,28,19,11],
[53,28,19,12],
[53,28,19,15],
[53,28,19,16],
[53,28,19,17],
[53,28,19,18],
[53,28,21,0],
[53,28,21,1],
[53,28,21,2],
[53,28,21,4],
[53,28,21,9],
[53,28,21,11],
[53,28,21,12],
[53,28,21,13],
[53,28,21,14],
[53,28,21,15],
[53,28,21,16],
[53,28,21,17],
[53,28,21,18],
[53,28,21,19],
[53,28,23,0],
[53,28,23,1],
[53,28,23,2],
[53,28,23,3],
[53,28,23,4],
[53,28,23,5],
[53,28,23,6],
[53,28,23,8],
[53,28,23,9],
[53,28,23,11],
[53,28,23,12],
[53,28,23,13],
[53,28,23,14],
[53,28,23,15],
[53,28,23,16],
[53,28,23,17],
[53,28,23,18],
[53,28,23,19],
[53,28,23,21],
[53,28,24,0],
[53,28,24,1],
[53,28,24,2],
[53,28,24,3],
[53,28,24,4],
[53,28,24,5],
[53,28,24,6],
[53,28,24,8],
[53,28,24,9],
[53,28,24,11],
[53,28,24,12],
[53,28,24,13],
[53,28,24,14],
[53,28,24,15],
[53,28,24,17],
[53,28,24,18],
[53,28,24,19],
[53,28,24,21],
[53,28,24,23],
[53,28,26,0],
[53,28,26,1],
[53,28,26,2],
[53,28,26,3],
[53,28,26,4],
[53,28,26,5],
[53,28,26,6],
[53,28,26,8],
[53,28,26,11],
[53,28,26,12],
[53,28,26,13],
[53,28,26,14],
[53,28,26,16],
[53,28,26,17],
[53,28,26,19],
[53,28,26,21],
[53,28,26,23],
[53,28,26,24],
[53,29,3,0],
[53,29,3,1],
[53,29,3,2],
[53,29,4,0],
[53,29,4,1],
[53,29,4,2],
[53,29,4,3],
[53,29,5,0],
[53,29,5,1],
[53,29,5,2],
[53,29,5,4],
[53,29,6,0],
[53,29,6,1],
[53,29,6,2],
[53,29,6,4],
[53,29,7,0],
[53,29,7,1],
[53,29,7,2],
[53,29,7,3],
[53,29,7,4],
[53,29,7,5],
[53,29,7,6],
[53,29,8,0],
[53,29,8,1],
[53,29,8,2],
[53,29,8,4],
[53,29,8,7],
[53,29,9,0],
[53,29,9,1],
[53,29,9,2],
[53,29,9,3],
[53,29,9,4],
[53,29,9,5],
[53,29,9,6],
[53,29,9,7],
[53,29,9,8],
[53,29,13,0],
[53,29,13,1],
[53,29,13,2],
[53,29,13,3],
[53,29,13,4],
[53,29,13,5],
[53,29,13,6],
[53,29,13,7],
[53,29,13,8],
[53,29,13,9],
[53,29,14,0],
[53,29,14,1],
[53,29,14,2],
[53,29,14,3],
[53,29,14,4],
[53,29,14,5],
[53,29,14,6],
[53,29,14,7],
[53,29,14,8],
[53,29,14,9],
[53,29,15,0],
[53,29,15,1],
[53,29,15,2],
[53,29,15,3],
[53,29,15,4],
[53,29,15,5],
[53,29,15,6],
[53,29,15,7],
[53,29,15,8],
[53,29,15,13],
[53,29,15,14],
[53,29,16,0],
[53,29,16,1],
[53,29,16,2],
[53,29,16,3],
[53,29,16,4],
[53,29,16,5],
[53,29,16,6],
[53,29,16,7],
[53,29,16,8],
[53,29,16,9],
[53,29,16,13],
[53,29,16,14],
[53,29,16,15],
[53,29,17,0],
[53,29,17,1],
[53,29,17,2],
[53,29,17,3],
[53,29,17,4],
[53,29,17,5],
[53,29,17,6],
[53,29,17,7],
[53,29,17,8],
[53,29,17,9],
[53,29,17,13],
[53,29,17,14],
[53,29,17,15],
[53,29,17,16],
[53,29,18,0],
[53,29,18,1],
[53,29,18,2],
[53,29,18,3],
[53,29,18,4],
[53,29,18,5],
[53,29,18,6],
[53,29,18,7],
[53,29,18,8],
[53,29,18,13],
[53,29,18,14],
[53,29,18,16],
[53,29,18,17],
[53,29,19,0],
[53,29,19,1],
[53,29,19,2],
[53,29,19,3],
[53,29,19,4],
[53,29,19,5],
[53,29,19,6],
[53,29,19,7],
[53,29,19,8],
[53,29,19,9],
[53,29,19,15],
[53,29,19,16],
[53,29,19,17],
[53,29,19,18],
[53,29,21,0],
[53,29,21,1],
[53,29,21,2],
[53,29,21,4],
[53,29,21,7],
[53,29,21,9],
[53,29,21,13],
[53,29,21,14],
[53,29,21,15],
[53,29,21,16],
[53,29,21,17],
[53,29,21,18],
[53,29,21,19],
[53,29,23,0],
[53,29,23,1],
[53,29,23,2],
[53,29,23,3],
[53,29,23,4],
[53,29,23,5],
[53,29,23,6],
[53,29,23,7],
[53,29,23,8],
[53,29,23,9],
[53,29,23,13],
[53,29,23,14],
[53,29,23,15],
[53,29,23,16],
[53,29,23,17],
[53,29,23,18],
[53,29,23,19],
[53,29,23,21],
[53,29,24,0],
[53,29,24,1],
[53,29,24,2],
[53,29,24,3],
[53,29,24,4],
[53,29,24,5],
[53,29,24,6],
[53,29,24,7],
[53,29,24,8],
[53,29,24,9],
[53,29,24,13],
[53,29,24,14],
[53,29,24,15],
[53,29,24,17],
[53,29,24,18],
[53,29,24,19],
[53,29,24,21],
[53,29,24,23],
[53,29,25,0],
[53,29,25,1],
[53,29,25,2],
[53,29,25,3],
[53,29,25,4],
[53,29,25,5],
[53,29,25,6],
[53,29,25,8],
[53,29,25,9],
[53,29,25,13],
[53,29,25,14],
[53,29,25,15],
[53,29,25,16],
[53,29,25,17],
[53,29,25,18],
[53,29,25,19],
[53,29,25,21],
[53,29,25,23],
[53,29,25,24],
[53,29,26,0],
[53,29,26,1],
[53,29,26,2],
[53,29,26,3],
[53,29,26,4],
[53,29,26,5],
[53,29,26,6],
[53,29,26,7],
[53,29,26,8],
[53,29,26,13],
[53,29,26,14],
[53,29,26,16],
[53,29,26,17],
[53,29,26,19],
[53,29,26,21],
[53,29,26,23],
[53,29,26,24],
[53,29,28,0],
[53,29,28,1],
[53,29,28,2],
[53,29,28,3],
[53,29,28,4],
[53,29,28,5],
[53,29,28,6],
[53,29,28,8],
[53,29,28,9],
[53,29,28,13],
[53,29,28,14],
[53,29,28,15],
[53,29,28,16],
[53,29,28,17],
[53,29,28,18],
[53,29,28,19],
[53,29,28,21],
[53,29,28,26],
[53,30,3,0],
[53,30,3,1],
[53,30,3,2],
[53,30,4,0],
[53,30,4,1],
[53,30,4,2],
[53,30,4,3],
[53,30,5,0],
[53,30,5,1],
[53,30,5,2],
[53,30,5,4],
[53,30,6,0],
[53,30,6,1],
[53,30,6,2],
[53,30,6,4],
[53,30,7,0],
[53,30,7,1],
[53,30,7,2],
[53,30,7,3],
[53,30,7,4],
[53,30,7,5],
[53,30,7,6],
[53,30,8,0],
[53,30,8,1],
[53,30,8,2],
[53,30,8,4],
[53,30,8,7],
[53,30,9,0],
[53,30,9,1],
[53,30,9,2],
[53,30,9,3],
[53,30,9,4],
[53,30,9,5],
[53,30,9,6],
[53,30,9,7],
[53,30,9,8],
[53,30,11,0],
[53,30,11,1],
[53,30,11,2],
[53,30,11,3],
[53,30,11,4],
[53,30,11,5],
[53,30,11,6],
[53,30,11,7],
[53,30,11,8],
[53,30,11,9],
[53,30,12,0],
[53,30,12,1],
[53,30,12,2],
[53,30,12,3],
[53,30,12,4],
[53,30,12,5],
[53,30,12,6],
[53,30,12,7],
[53,30,12,8],
[53,30,12,9],
[53,30,13,0],
[53,30,13,1],
[53,30,13,2],
[53,30,13,3],
[53,30,13,4],
[53,30,13,5],
[53,30,13,6],
[53,30,13,7],
[53,30,13,8],
[53,30,13,9],
[53,30,13,11],
[53,30,13,12],
[53,30,14,0],
[53,30,14,1],
[53,30,14,2],
[53,30,14,3],
[53,30,14,4],
[53,30,14,5],
[53,30,14,6],
[53,30,14,7],
[53,30,14,8],
[53,30,14,9],
[53,30,14,11],
[53,30,14,12],
[53,30,15,0],
[53,30,15,1],
[53,30,15,2],
[53,30,15,3],
[53,30,15,4],
[53,30,15,5],
[53,30,15,6],
[53,30,15,7],
[53,30,15,8],
[53,30,15,11],
[53,30,15,12],
[53,30,15,13],
[53,30,15,14],
[53,30,16,0],
[53,30,16,1],
[53,30,16,2],
[53,30,16,3],
[53,30,16,4],
[53,30,16,5],
[53,30,16,6],
[53,30,16,7],
[53,30,16,8],
[53,30,16,9],
[53,30,16,11],
[53,30,16,12],
[53,30,16,13],
[53,30,16,14],
[53,30,16,15],
[53,30,17,0],
[53,30,17,1],
[53,30,17,2],
[53,30,17,3],
[53,30,17,4],
[53,30,17,5],
[53,30,17,6],
[53,30,17,7],
[53,30,17,8],
[53,30,17,9],
[53,30,17,11],
[53,30,17,12],
[53,30,17,13],
[53,30,17,14],
[53,30,17,15],
[53,30,17,16],
[53,30,18,0],
[53,30,18,1],
[53,30,18,2],
[53,30,18,3],
[53,30,18,4],
[53,30,18,5],
[53,30,18,6],
[53,30,18,7],
[53,30,18,8],
[53,30,18,11],
[53,30,18,12],
[53,30,18,13],
[53,30,18,14],
[53,30,18,16],
[53,30,18,17],
[53,30,19,0],
[53,30,19,1],
[53,30,19,2],
[53,30,19,3],
[53,30,19,4],
[53,30,19,5],
[53,30,19,6],
[53,30,19,7],
[53,30,19,8],
[53,30,19,9],
[53,30,19,11],
[53,30,19,12],
[53,30,19,15],
[53,30,19,16],
[53,30,19,17],
[53,30,19,18],
[53,30,21,0],
[53,30,21,1],
[53,30,21,2],
[53,30,21,4],
[53,30,21,7],
[53,30,21,9],
[53,30,21,11],
[53,30,21,12],
[53,30,21,13],
[53,30,21,14],
[53,30,21,15],
[53,30,21,16],
[53,30,21,17],
[53,30,21,18],
[53,30,21,19],
[53,30,24,0],
[53,30,24,1],
[53,30,24,2],
[53,30,24,3],
[53,30,24,4],
[53,30,24,5],
[53,30,24,6],
[53,30,24,7],
[53,30,24,8],
[53,30,24,9],
[53,30,24,11],
[53,30,24,12],
[53,30,24,13],
[53,30,24,14],
[53,30,24,15],
[53,30,24,17],
[53,30,24,18],
[53,30,24,19],
[53,30,24,21],
[53,30,25,0],
[53,30,25,1],
[53,30,25,2],
[53,30,25,3],
[53,30,25,4],
[53,30,25,5],
[53,30,25,6],
[53,30,25,8],
[53,30,25,9],
[53,30,25,11],
[53,30,25,12],
[53,30,25,13],
[53,30,25,14],
[53,30,25,15],
[53,30,25,16],
[53,30,25,17],
[53,30,25,18],
[53,30,25,19],
[53,30,25,21],
[53,30,25,24],
[53,30,26,0],
[53,30,26,1],
[53,30,26,2],
[53,30,26,3],
[53,30,26,4],
[53,30,26,5],
[53,30,26,6],
[53,30,26,7],
[53,30,26,8],
[53,30,26,11],
[53,30,26,12],
[53,30,26,13],
[53,30,26,14],
[53,30,26,16],
[53,30,26,17],
[53,30,26,19],
[53,30,26,21],
[53,30,26,24],
[53,30,26,25],
[53,30,28,0],
[53,30,28,1],
[53,30,28,2],
[53,30,28,3],
[53,30,28,4],
[53,30,28,5],
[53,30,28,6],
[53,30,28,8],
[53,30,28,9],
[53,30,28,11],
[53,30,28,12],
[53,30,28,13],
[53,30,28,14],
[53,30,28,15],
[53,30,28,16],
[53,30,28,17],
[53,30,28,18],
[53,30,28,19],
[53,30,28,21],
[53,30,28,24],
[53,30,28,26],
[53,30,29,0],
[53,30,29,1],
[53,30,29,2],
[53,30,29,3],
[53,30,29,4],
[53,30,29,5],
[53,30,29,6],
[53,30,29,7],
[53,30,29,8],
[53,30,29,9],
[53,30,29,13],
[53,30,29,14],
[53,30,29,15],
[53,30,29,16],
[53,30,29,17],
[53,30,29,18],
[53,30,29,19],
[53,30,29,24],
[53,30,29,25],
[53,30,29,26],
[53,30,29,28],
[53,31,3,0],
[53,31,3,1],
[53,31,3,2],
[53,31,4,0],
[53,31,4,1],
[53,31,4,2],
[53,31,4,3],
[53,31,5,0],
[53,31,5,1],
[53,31,5,2],
[53,31,5,4],
[53,31,6,0],
[53,31,6,1],
[53,31,6,2],
[53,31,6,4],
[53,31,7,0],
[53,31,7,1],
[53,31,7,2],
[53,31,7,3],
[53,31,7,4],
[53,31,7,5],
[53,31,7,6],
[53,31,8,0],
[53,31,8,1],
[53,31,8,2],
[53,31,8,4],
[53,31,8,7],
[53,31,9,0],
[53,31,9,1],
[53,31,9,2],
[53,31,9,3],
[53,31,9,4],
[53,31,9,5],
[53,31,9,6],
[53,31,9,7],
[53,31,9,8],
[53,31,11,0],
[53,31,11,1],
[53,31,11,2],
[53,31,11,3],
[53,31,11,4],
[53,31,11,5],
[53,31,11,6],
[53,31,11,7],
[53,31,11,8],
[53,31,11,9],
[53,31,12,0],
[53,31,12,1],
[53,31,12,2],
[53,31,12,3],
[53,31,12,4],
[53,31,12,5],
[53,31,12,6],
[53,31,12,7],
[53,31,12,8],
[53,31,12,9],
[53,31,13,0],
[53,31,13,1],
[53,31,13,2],
[53,31,13,3],
[53,31,13,4],
[53,31,13,5],
[53,31,13,6],
[53,31,13,7],
[53,31,13,8],
[53,31,13,9],
[53,31,13,11],
[53,31,13,12],
[53,31,14,0],
[53,31,14,1],
[53,31,14,2],
[53,31,14,3],
[53,31,14,4],
[53,31,14,5],
[53,31,14,6],
[53,31,14,7],
[53,31,14,8],
[53,31,14,9],
[53,31,14,11],
[53,31,14,12],
[53,31,15,0],
[53,31,15,1],
[53,31,15,2],
[53,31,15,3],
[53,31,15,4],
[53,31,15,5],
[53,31,15,6],
[53,31,15,7],
[53,31,15,8],
[53,31,15,11],
[53,31,15,12],
[53,31,15,13],
[53,31,15,14],
[53,31,16,0],
[53,31,16,1],
[53,31,16,2],
[53,31,16,3],
[53,31,16,4],
[53,31,16,5],
[53,31,16,6],
[53,31,16,7],
[53,31,16,8],
[53,31,16,9],
[53,31,16,11],
[53,31,16,12],
[53,31,16,13],
[53,31,16,14],
[53,31,16,15],
[53,31,17,0],
[53,31,17,1],
[53,31,17,2],
[53,31,17,3],
[53,31,17,4],
[53,31,17,5],
[53,31,17,6],
[53,31,17,7],
[53,31,17,8],
[53,31,17,9],
[53,31,17,11],
[53,31,17,12],
[53,31,17,13],
[53,31,17,14],
[53,31,17,15],
[53,31,17,16],
[53,31,18,0],
[53,31,18,1],
[53,31,18,2],
[53,31,18,3],
[53,31,18,4],
[53,31,18,5],
[53,31,18,6],
[53,31,18,7],
[53,31,18,8],
[53,31,18,11],
[53,31,18,12],
[53,31,18,13],
[53,31,18,14],
[53,31,18,16],
[53,31,18,17],
[53,31,19,0],
[53,31,19,1],
[53,31,19,2],
[53,31,19,3],
[53,31,19,4],
[53,31,19,5],
[53,31,19,6],
[53,31,19,7],
[53,31,19,8],
[53,31,19,9],
[53,31,19,11],
[53,31,19,12],
[53,31,19,15],
[53,31,19,16],
[53,31,19,17],
[53,31,19,18],
[53,31,21,0],
[53,31,21,1],
[53,31,21,2],
[53,31,21,4],
[53,31,21,7],
[53,31,21,9],
[53,31,21,11],
[53,31,21,12],
[53,31,21,13],
[53,31,21,14],
[53,31,21,15],
[53,31,21,16],
[53,31,21,17],
[53,31,21,18],
[53,31,21,19],
[53,31,24,0],
[53,31,24,1],
[53,31,24,2],
[53,31,24,3],
[53,31,24,4],
[53,31,24,5],
[53,31,24,6],
[53,31,24,7],
[53,31,24,8],
[53,31,24,9],
[53,31,24,11],
[53,31,24,12],
[53,31,24,13],
[53,31,24,14],
[53,31,24,15],
[53,31,24,17],
[53,31,24,18],
[53,31,24,19],
[53,31,24,21],
[53,31,25,0],
[53,31,25,1],
[53,31,25,2],
[53,31,25,3],
[53,31,25,4],
[53,31,25,5],
[53,31,25,6],
[53,31,25,8],
[53,31,25,9],
[53,31,25,11],
[53,31,25,12],
[53,31,25,13],
[53,31,25,14],
[53,31,25,15],
[53,31,25,16],
[53,31,25,17],
[53,31,25,18],
[53,31,25,19],
[53,31,25,21],
[53,31,25,24],
[53,31,26,0],
[53,31,26,1],
[53,31,26,2],
[53,31,26,3],
[53,31,26,4],
[53,31,26,5],
[53,31,26,6],
[53,31,26,7],
[53,31,26,8],
[53,31,26,11],
[53,31,26,12],
[53,31,26,13],
[53,31,26,14],
[53,31,26,16],
[53,31,26,17],
[53,31,26,19],
[53,31,26,21],
[53,31,26,25],
[53,31,28,0],
[53,31,28,1],
[53,31,28,2],
[53,31,28,3],
[53,31,28,4],
[53,31,28,5],
[53,31,28,6],
[53,31,28,8],
[53,31,28,9],
[53,31,28,11],
[53,31,28,12],
[53,31,28,13],
[53,31,28,14],
[53,31,28,15],
[53,31,28,16],
[53,31,28,17],
[53,31,28,18],
[53,31,28,19],
[53,31,28,24],
[53,31,28,26],
[53,31,29,0],
[53,31,29,1],
[53,31,29,2],
[53,31,29,3],
[53,31,29,4],
[53,31,29,5],
[53,31,29,6],
[53,31,29,7],
[53,31,29,8],
[53,31,29,9],
[53,31,29,13],
[53,31,29,14],
[53,31,29,15],
[53,31,29,16],
[53,31,29,17],
[53,31,29,18],
[53,31,29,19],
[53,31,29,21],
[53,31,29,24],
[53,31,29,25],
[53,31,29,26],
[53,31,29,28],
[53,32,3,0],
[53,32,3,1],
[53,32,3,2],
[53,32,4,0],
[53,32,4,1],
[53,32,4,2],
[53,32,4,3],
[53,32,5,0],
[53,32,5,1],
[53,32,5,2],
[53,32,5,4],
[53,32,6,0],
[53,32,6,1],
[53,32,6,2],
[53,32,6,4],
[53,32,7,0],
[53,32,7,1],
[53,32,7,2],
[53,32,7,3],
[53,32,7,4],
[53,32,7,5],
[53,32,7,6],
[53,32,8,0],
[53,32,8,1],
[53,32,8,2],
[53,32,8,4],
[53,32,8,7],
[53,32,9,0],
[53,32,9,1],
[53,32,9,2],
[53,32,9,3],
[53,32,9,4],
[53,32,9,5],
[53,32,9,6],
[53,32,9,7],
[53,32,9,8],
[53,32,13,0],
[53,32,13,1],
[53,32,13,2],
[53,32,13,3],
[53,32,13,4],
[53,32,13,5],
[53,32,13,6],
[53,32,13,7],
[53,32,13,8],
[53,32,13,9],
[53,32,14,0],
[53,32,14,1],
[53,32,14,2],
[53,32,14,3],
[53,32,14,4],
[53,32,14,5],
[53,32,14,6],
[53,32,14,7],
[53,32,14,8],
[53,32,14,9],
[53,32,15,0],
[53,32,15,1],
[53,32,15,2],
[53,32,15,3],
[53,32,15,4],
[53,32,15,5],
[53,32,15,6],
[53,32,15,7],
[53,32,15,8],
[53,32,15,13],
[53,32,15,14],
[53,32,16,0],
[53,32,16,1],
[53,32,16,2],
[53,32,16,3],
[53,32,16,4],
[53,32,16,5],
[53,32,16,6],
[53,32,16,7],
[53,32,16,8],
[53,32,16,9],
[53,32,16,13],
[53,32,16,14],
[53,32,16,15],
[53,32,17,0],
[53,32,17,1],
[53,32,17,2],
[53,32,17,3],
[53,32,17,4],
[53,32,17,5],
[53,32,17,6],
[53,32,17,7],
[53,32,17,8],
[53,32,17,9],
[53,32,17,13],
[53,32,17,14],
[53,32,17,15],
[53,32,17,16],
[53,32,18,0],
[53,32,18,1],
[53,32,18,2],
[53,32,18,3],
[53,32,18,4],
[53,32,18,5],
[53,32,18,6],
[53,32,18,7],
[53,32,18,8],
[53,32,18,13],
[53,32,18,14],
[53,32,18,16],
[53,32,18,17],
[53,32,19,0],
[53,32,19,1],
[53,32,19,2],
[53,32,19,3],
[53,32,19,4],
[53,32,19,5],
[53,32,19,6],
[53,32,19,7],
[53,32,19,8],
[53,32,19,9],
[53,32,19,15],
[53,32,19,16],
[53,32,19,17],
[53,32,19,18],
[53,32,21,0],
[53,32,21,1],
[53,32,21,2],
[53,32,21,4],
[53,32,21,7],
[53,32,21,9],
[53,32,21,13],
[53,32,21,14],
[53,32,21,15],
[53,32,21,16],
[53,32,21,17],
[53,32,21,18],
[53,32,21,19],
[53,32,23,0],
[53,32,23,1],
[53,32,23,2],
[53,32,23,3],
[53,32,23,4],
[53,32,23,5],
[53,32,23,6],
[53,32,23,7],
[53,32,23,8],
[53,32,23,9],
[53,32,23,13],
[53,32,23,14],
[53,32,23,15],
[53,32,23,16],
[53,32,23,17],
[53,32,23,18],
[53,32,23,19],
[53,32,23,21],
[53,32,24,0],
[53,32,24,1],
[53,32,24,2],
[53,32,24,3],
[53,32,24,4],
[53,32,24,5],
[53,32,24,6],
[53,32,24,7],
[53,32,24,8],
[53,32,24,9],
[53,32,24,13],
[53,32,24,14],
[53,32,24,15],
[53,32,24,17],
[53,32,24,18],
[53,32,24,19],
[53,32,24,21],
[53,32,24,23],
[53,32,25,0],
[53,32,25,1],
[53,32,25,2],
[53,32,25,3],
[53,32,25,4],
[53,32,25,5],
[53,32,25,6],
[53,32,25,8],
[53,32,25,9],
[53,32,25,13],
[53,32,25,14],
[53,32,25,15],
[53,32,25,16],
[53,32,25,17],
[53,32,25,18],
[53,32,25,19],
[53,32,25,21],
[53,32,26,0],
[53,32,26,1],
[53,32,26,2],
[53,32,26,3],
[53,32,26,4],
[53,32,26,5],
[53,32,26,6],
[53,32,26,7],
[53,32,26,8],
[53,32,26,13],
[53,32,26,14],
[53,32,26,16],
[53,32,26,17],
[53,32,26,19],
[53,32,26,21],
[53,32,26,23],
[53,32,26,24],
[53,32,26,25],
[53,32,28,0],
[53,32,28,1],
[53,32,28,2],
[53,32,28,3],
[53,32,28,4],
[53,32,28,5],
[53,32,28,6],
[53,32,28,8],
[53,32,28,9],
[53,32,28,13],
[53,32,28,14],
[53,32,28,15],
[53,32,28,16],
[53,32,28,17],
[53,32,28,18],
[53,32,28,19],
[53,32,28,21],
[53,32,28,23],
[53,32,28,24],
[53,32,28,26],
[53,32,30,0],
[53,32,30,1],
[53,32,30,2],
[53,32,30,3],
[53,32,30,4],
[53,32,30,5],
[53,32,30,6],
[53,32,30,7],
[53,32,30,8],
[53,32,30,9],
[53,32,30,13],
[53,32,30,14],
[53,32,30,15],
[53,32,30,16],
[53,32,30,17],
[53,32,30,19],
[53,32,30,21],
[53,32,30,24],
[53,32,30,25],
[53,32,30,26],
[53,32,30,28],
[53,32,31,0],
[53,32,31,1],
[53,32,31,2],
[53,32,31,3],
[53,32,31,4],
[53,32,31,5],
[53,32,31,6],
[53,32,31,7],
[53,32,31,8],
[53,32,31,9],
[53,32,31,13],
[53,32,31,14],
[53,32,31,15],
[53,32,31,18],
[53,32,31,19],
[53,32,31,21],
[53,32,31,24],
[53,32,31,25],
[53,32,31,26],
[53,32,31,28],
[53,33,3,0],
[53,33,3,1],
[53,33,3,2],
[53,33,4,0],
[53,33,4,1],
[53,33,4,2],
[53,33,4,3],
[53,33,5,0],
[53,33,5,1],
[53,33,5,2],
[53,33,5,4],
[53,33,6,0],
[53,33,6,1],
[53,33,6,2],
[53,33,6,4],
[53,33,7,0],
[53,33,7,1],
[53,33,7,2],
[53,33,7,3],
[53,33,7,4],
[53,33,7,5],
[53,33,7,6],
[53,33,8,0],
[53,33,8,1],
[53,33,8,2],
[53,33,8,4],
[53,33,8,7],
[53,33,9,0],
[53,33,9,1],
[53,33,9,2],
[53,33,9,3],
[53,33,9,4],
[53,33,9,5],
[53,33,9,6],
[53,33,9,7],
[53,33,9,8],
[53,33,13,0],
[53,33,13,1],
[53,33,13,2],
[53,33,13,3],
[53,33,13,4],
[53,33,13,5],
[53,33,13,6],
[53,33,13,7],
[53,33,13,8],
[53,33,13,9],
[53,33,14,0],
[53,33,14,1],
[53,33,14,2],
[53,33,14,3],
[53,33,14,4],
[53,33,14,5],
[53,33,14,6],
[53,33,14,7],
[53,33,14,8],
[53,33,14,9],
[53,33,15,0],
[53,33,15,1],
[53,33,15,2],
[53,33,15,3],
[53,33,15,4],
[53,33,15,5],
[53,33,15,6],
[53,33,15,7],
[53,33,15,8],
[53,33,15,13],
[53,33,15,14],
[53,33,16,0],
[53,33,16,1],
[53,33,16,2],
[53,33,16,3],
[53,33,16,4],
[53,33,16,5],
[53,33,16,6],
[53,33,16,7],
[53,33,16,8],
[53,33,16,9],
[53,33,16,13],
[53,33,16,14],
[53,33,16,15],
[53,33,17,0],
[53,33,17,1],
[53,33,17,2],
[53,33,17,3],
[53,33,17,4],
[53,33,17,5],
[53,33,17,6],
[53,33,17,7],
[53,33,17,8],
[53,33,17,9],
[53,33,17,13],
[53,33,17,14],
[53,33,17,15],
[53,33,17,16],
[53,33,18,0],
[53,33,18,1],
[53,33,18,2],
[53,33,18,3],
[53,33,18,4],
[53,33,18,5],
[53,33,18,6],
[53,33,18,7],
[53,33,18,8],
[53,33,18,13],
[53,33,18,14],
[53,33,18,16],
[53,33,18,17],
[53,33,19,0],
[53,33,19,1],
[53,33,19,2],
[53,33,19,3],
[53,33,19,4],
[53,33,19,5],
[53,33,19,6],
[53,33,19,7],
[53,33,19,8],
[53,33,19,9],
[53,33,19,15],
[53,33,19,16],
[53,33,19,17],
[53,33,19,18],
[53,33,21,0],
[53,33,21,1],
[53,33,21,2],
[53,33,21,4],
[53,33,21,7],
[53,33,21,9],
[53,33,21,13],
[53,33,21,14],
[53,33,21,15],
[53,33,21,16],
[53,33,21,17],
[53,33,21,18],
[53,33,21,19],
[53,33,23,0],
[53,33,23,1],
[53,33,23,2],
[53,33,23,3],
[53,33,23,4],
[53,33,23,5],
[53,33,23,6],
[53,33,23,7],
[53,33,23,8],
[53,33,23,9],
[53,33,23,13],
[53,33,23,14],
[53,33,23,15],
[53,33,23,16],
[53,33,23,17],
[53,33,23,18],
[53,33,23,19],
[53,33,23,21],
[53,33,24,0],
[53,33,24,1],
[53,33,24,2],
[53,33,24,3],
[53,33,24,4],
[53,33,24,5],
[53,33,24,6],
[53,33,24,7],
[53,33,24,8],
[53,33,24,9],
[53,33,24,13],
[53,33,24,14],
[53,33,24,15],
[53,33,24,17],
[53,33,24,18],
[53,33,24,19],
[53,33,24,21],
[53,33,24,23],
[53,33,25,0],
[53,33,25,1],
[53,33,25,2],
[53,33,25,3],
[53,33,25,4],
[53,33,25,5],
[53,33,25,6],
[53,33,25,8],
[53,33,25,9],
[53,33,25,13],
[53,33,25,14],
[53,33,25,15],
[53,33,25,16],
[53,33,25,17],
[53,33,25,18],
[53,33,25,19],
[53,33,25,21],
[53,33,25,23],
[53,33,25,24],
[53,33,26,0],
[53,33,26,1],
[53,33,26,2],
[53,33,26,3],
[53,33,26,4],
[53,33,26,5],
[53,33,26,6],
[53,33,26,7],
[53,33,26,8],
[53,33,26,13],
[53,33,26,14],
[53,33,26,16],
[53,33,26,17],
[53,33,26,19],
[53,33,26,23],
[53,33,26,24],
[53,33,26,25],
[53,33,28,0],
[53,33,28,1],
[53,33,28,2],
[53,33,28,3],
[53,33,28,4],
[53,33,28,5],
[53,33,28,6],
[53,33,28,8],
[53,33,28,9],
[53,33,28,13],
[53,33,28,14],
[53,33,28,15],
[53,33,28,16],
[53,33,28,17],
[53,33,28,18],
[53,33,28,21],
[53,33,28,23],
[53,33,28,24],
[53,33,28,26],
[53,33,30,0],
[53,33,30,1],
[53,33,30,2],
[53,33,30,3],
[53,33,30,4],
[53,33,30,5],
[53,33,30,6],
[53,33,30,7],
[53,33,30,8],
[53,33,30,9],
[53,33,30,13],
[53,33,30,14],
[53,33,30,15],
[53,33,30,18],
[53,33,30,19],
[53,33,30,21],
[53,33,30,24],
[53,33,30,25],
[53,33,30,26],
[53,33,30,28],
[53,33,31,0],
[53,33,31,1],
[53,33,31,2],
[53,33,31,3],
[53,33,31,4],
[53,33,31,5],
[53,33,31,6],
[53,33,31,7],
[53,33,31,8],
[53,33,31,9],
[53,33,31,13],
[53,33,31,14],
[53,33,31,16],
[53,33,31,17],
[53,33,31,18],
[53,33,31,19],
[53,33,31,21],
[53,33,31,24],
[53,33,31,25],
[53,33,31,26],
[53,33,31,28],
[53,34,3,0],
[53,34,3,1],
[53,34,3,2],
[53,34,4,0],
[53,34,4,1],
[53,34,4,2],
[53,34,4,3],
[53,34,5,0],
[53,34,5,1],
[53,34,5,2],
[53,34,5,4],
[53,34,6,0],
[53,34,6,1],
[53,34,6,2],
[53,34,6,4],
[53,34,8,0],
[53,34,8,1],
[53,34,8,2],
[53,34,8,4],
[53,34,9,0],
[53,34,9,1],
[53,34,9,2],
[53,34,9,3],
[53,34,9,4],
[53,34,9,5],
[53,34,9,6],
[53,34,9,8],
[53,34,11,0],
[53,34,11,1],
[53,34,11,2],
[53,34,11,3],
[53,34,11,4],
[53,34,11,5],
[53,34,11,6],
[53,34,11,8],
[53,34,11,9],
[53,34,12,0],
[53,34,12,1],
[53,34,12,2],
[53,34,12,3],
[53,34,12,4],
[53,34,12,5],
[53,34,12,6],
[53,34,12,8],
[53,34,12,9],
[53,34,13,0],
[53,34,13,1],
[53,34,13,2],
[53,34,13,3],
[53,34,13,4],
[53,34,13,5],
[53,34,13,6],
[53,34,13,8],
[53,34,13,9],
[53,34,13,11],
[53,34,13,12],
[53,34,14,0],
[53,34,14,1],
[53,34,14,2],
[53,34,14,3],
[53,34,14,4],
[53,34,14,5],
[53,34,14,6],
[53,34,14,8],
[53,34,14,9],
[53,34,14,11],
[53,34,14,12],
[53,34,15,0],
[53,34,15,1],
[53,34,15,2],
[53,34,15,3],
[53,34,15,4],
[53,34,15,5],
[53,34,15,6],
[53,34,15,8],
[53,34,15,11],
[53,34,15,12],
[53,34,15,13],
[53,34,15,14],
[53,34,16,0],
[53,34,16,1],
[53,34,16,2],
[53,34,16,3],
[53,34,16,4],
[53,34,16,5],
[53,34,16,6],
[53,34,16,8],
[53,34,16,9],
[53,34,16,11],
[53,34,16,12],
[53,34,16,13],
[53,34,16,14],
[53,34,16,15],
[53,34,17,0],
[53,34,17,1],
[53,34,17,2],
[53,34,17,3],
[53,34,17,4],
[53,34,17,5],
[53,34,17,6],
[53,34,17,8],
[53,34,17,9],
[53,34,17,11],
[53,34,17,12],
[53,34,17,13],
[53,34,17,14],
[53,34,17,15],
[53,34,17,16],
[53,34,18,0],
[53,34,18,1],
[53,34,18,2],
[53,34,18,3],
[53,34,18,4],
[53,34,18,5],
[53,34,18,6],
[53,34,18,8],
[53,34,18,11],
[53,34,18,12],
[53,34,18,13],
[53,34,18,14],
[53,34,18,16],
[53,34,18,17],
[53,34,19,0],
[53,34,19,1],
[53,34,19,2],
[53,34,19,3],
[53,34,19,4],
[53,34,19,5],
[53,34,19,6],
[53,34,19,8],
[53,34,19,9],
[53,34,19,11],
[53,34,19,12],
[53,34,19,15],
[53,34,19,16],
[53,34,19,17],
[53,34,19,18],
[53,34,21,0],
[53,34,21,1],
[53,34,21,2],
[53,34,21,4],
[53,34,21,9],
[53,34,21,11],
[53,34,21,12],
[53,34,21,13],
[53,34,21,14],
[53,34,21,15],
[53,34,21,16],
[53,34,21,17],
[53,34,21,18],
[53,34,21,19],
[53,34,23,0],
[53,34,23,1],
[53,34,23,2],
[53,34,23,3],
[53,34,23,4],
[53,34,23,5],
[53,34,23,6],
[53,34,23,8],
[53,34,23,9],
[53,34,23,11],
[53,34,23,12],
[53,34,23,13],
[53,34,23,14],
[53,34,23,15],
[53,34,23,16],
[53,34,23,17],
[53,34,23,18],
[53,34,23,19],
[53,34,23,21],
[53,34,24,0],
[53,34,24,1],
[53,34,24,2],
[53,34,24,3],
[53,34,24,4],
[53,34,24,5],
[53,34,24,6],
[53,34,24,8],
[53,34,24,9],
[53,34,24,11],
[53,34,24,12],
[53,34,24,13],
[53,34,24,14],
[53,34,24,15],
[53,34,24,17],
[53,34,24,18],
[53,34,24,19],
[53,34,24,21],
[53,34,26,0],
[53,34,26,1],
[53,34,26,2],
[53,34,26,3],
[53,34,26,4],
[53,34,26,5],
[53,34,26,6],
[53,34,26,8],
[53,34,26,11],
[53,34,26,12],
[53,34,26,13],
[53,34,26,14],
[53,34,26,16],
[53,34,26,17],
[53,34,26,19],
[53,34,26,21],
[53,34,26,23],
[53,34,26,24],
[53,34,29,0],
[53,34,29,1],
[53,34,29,2],
[53,34,29,3],
[53,34,29,4],
[53,34,29,5],
[53,34,29,6],
[53,34,29,8],
[53,34,29,9],
[53,34,29,13],
[53,34,29,14],
[53,34,29,15],
[53,34,29,18],
[53,34,29,19],
[53,34,29,21],
[53,34,29,23],
[53,34,29,24],
[53,34,29,26],
[53,34,30,0],
[53,34,30,1],
[53,34,30,2],
[53,34,30,3],
[53,34,30,4],
[53,34,30,5],
[53,34,30,6],
[53,34,30,8],
[53,34,30,9],
[53,34,30,11],
[53,34,30,12],
[53,34,30,13],
[53,34,30,14],
[53,34,30,16],
[53,34,30,17],
[53,34,30,18],
[53,34,30,19],
[53,34,30,21],
[53,34,30,24],
[53,34,30,26],
[53,34,31,0],
[53,34,31,1],
[53,34,31,2],
[53,34,31,3],
[53,34,31,4],
[53,34,31,5],
[53,34,31,6],
[53,34,31,8],
[53,34,31,9],
[53,34,31,11],
[53,34,31,12],
[53,34,31,13],
[53,34,31,15],
[53,34,31,16],
[53,34,31,17],
[53,34,31,18],
[53,34,31,19],
[53,34,31,21],
[53,34,31,24],
[53,34,31,26],
[53,34,32,0],
[53,34,32,1],
[53,34,32,2],
[53,34,32,3],
[53,34,32,4],
[53,34,32,5],
[53,34,32,6],
[53,34,32,8],
[53,34,32,9],
[53,34,32,14],
[53,34,32,15],
[53,34,32,16],
[53,34,32,17],
[53,34,32,18],
[53,34,32,19],
[53,34,32,21],
[53,34,32,23],
[53,34,32,24],
[53,34,32,26],
[53,34,33,0],
[53,34,33,1],
[53,34,33,2],
[53,34,33,3],
[53,34,33,4],
[53,34,33,5],
[53,34,33,6],
[53,34,33,8],
[53,34,33,9],
[53,34,33,13],
[53,34,33,14],
[53,34,33,15],
[53,34,33,16],
[53,34,33,17],
[53,34,33,18],
[53,34,33,19],
[53,34,33,21],
[53,34,33,23],
[53,34,33,24],
[53,35,3,0],
[53,35,3,1],
[53,35,3,2],
[53,35,4,0],
[53,35,4,1],
[53,35,4,2],
[53,35,4,3],
[53,35,5,0],
[53,35,5,1],
[53,35,5,2],
[53,35,5,4],
[53,35,6,0],
[53,35,6,1],
[53,35,6,2],
[53,35,6,4],
[53,35,7,0],
[53,35,7,1],
[53,35,7,2],
[53,35,7,3],
[53,35,7,4],
[53,35,7,5],
[53,35,7,6],
[53,35,8,0],
[53,35,8,1],
[53,35,8,2],
[53,35,8,4],
[53,35,8,7],
[53,35,11,0],
[53,35,11,1],
[53,35,11,2],
[53,35,11,3],
[53,35,11,4],
[53,35,11,5],
[53,35,11,6],
[53,35,11,7],
[53,35,11,8],
[53,35,12,0],
[53,35,12,1],
[53,35,12,2],
[53,35,12,3],
[53,35,12,4],
[53,35,12,5],
[53,35,12,6],
[53,35,12,7],
[53,35,12,8],
[53,35,13,0],
[53,35,13,1],
[53,35,13,2],
[53,35,13,3],
[53,35,13,4],
[53,35,13,5],
[53,35,13,6],
[53,35,13,7],
[53,35,13,8],
[53,35,13,11],
[53,35,13,12],
[53,35,14,0],
[53,35,14,1],
[53,35,14,2],
[53,35,14,3],
[53,35,14,4],
[53,35,14,5],
[53,35,14,6],
[53,35,14,7],
[53,35,14,8],
[53,35,14,11],
[53,35,14,12],
[53,35,16,0],
[53,35,16,1],
[53,35,16,2],
[53,35,16,3],
[53,35,16,4],
[53,35,16,5],
[53,35,16,6],
[53,35,16,7],
[53,35,16,8],
[53,35,16,11],
[53,35,16,12],
[53,35,16,13],
[53,35,16,14],
[53,35,17,0],
[53,35,17,1],
[53,35,17,2],
[53,35,17,3],
[53,35,17,4],
[53,35,17,5],
[53,35,17,6],
[53,35,17,7],
[53,35,17,8],
[53,35,17,11],
[53,35,17,12],
[53,35,17,13],
[53,35,17,14],
[53,35,17,16],
[53,35,19,0],
[53,35,19,1],
[53,35,19,2],
[53,35,19,3],
[53,35,19,4],
[53,35,19,5],
[53,35,19,6],
[53,35,19,7],
[53,35,19,8],
[53,35,19,11],
[53,35,19,12],
[53,35,19,16],
[53,35,19,17],
[53,35,21,0],
[53,35,21,1],
[53,35,21,2],
[53,35,21,4],
[53,35,21,7],
[53,35,21,11],
[53,35,21,12],
[53,35,21,13],
[53,35,21,14],
[53,35,21,16],
[53,35,21,17],
[53,35,21,19],
[53,35,23,0],
[53,35,23,1],
[53,35,23,2],
[53,35,23,3],
[53,35,23,4],
[53,35,23,5],
[53,35,23,6],
[53,35,23,7],
[53,35,23,8],
[53,35,23,11],
[53,35,23,12],
[53,35,23,13],
[53,35,23,14],
[53,35,23,16],
[53,35,23,17],
[53,35,23,19],
[53,35,23,21],
[53,35,24,0],
[53,35,24,1],
[53,35,24,2],
[53,35,24,3],
[53,35,24,4],
[53,35,24,5],
[53,35,24,6],
[53,35,24,7],
[53,35,24,8],
[53,35,24,11],
[53,35,24,12],
[53,35,24,13],
[53,35,24,14],
[53,35,24,17],
[53,35,24,19],
[53,35,24,21],
[53,35,24,23],
[53,35,25,0],
[53,35,25,1],
[53,35,25,2],
[53,35,25,3],
[53,35,25,4],
[53,35,25,5],
[53,35,25,6],
[53,35,25,8],
[53,35,25,11],
[53,35,25,12],
[53,35,25,13],
[53,35,25,14],
[53,35,25,16],
[53,35,25,17],
[53,35,25,19],
[53,35,25,21],
[53,35,25,23],
[53,35,25,24],
[53,35,28,0],
[53,35,28,1],
[53,35,28,2],
[53,35,28,3],
[53,35,28,4],
[53,35,28,5],
[53,35,28,6],
[53,35,28,8],
[53,35,28,11],
[53,35,28,12],
[53,35,28,13],
[53,35,28,14],
[53,35,28,19],
[53,35,28,21],
[53,35,28,23],
[53,35,28,24],
[53,35,29,0],
[53,35,29,1],
[53,35,29,2],
[53,35,29,3],
[53,35,29,4],
[53,35,29,5],
[53,35,29,6],
[53,35,29,7],
[53,35,29,8],
[53,35,29,13],
[53,35,29,14],
[53,35,29,16],
[53,35,29,17],
[53,35,29,19],
[53,35,29,21],
[53,35,29,23],
[53,35,29,24],
[53,35,29,25],
[53,35,29,28],
[53,35,30,0],
[53,35,30,1],
[53,35,30,2],
[53,35,30,3],
[53,35,30,4],
[53,35,30,5],
[53,35,30,6],
[53,35,30,7],
[53,35,30,8],
[53,35,30,11],
[53,35,30,12],
[53,35,30,13],
[53,35,30,16],
[53,35,30,17],
[53,35,30,19],
[53,35,30,21],
[53,35,30,24],
[53,35,30,25],
[53,35,31,0],
[53,35,31,1],
[53,35,31,2],
[53,35,31,3],
[53,35,31,4],
[53,35,31,5],
[53,35,31,6],
[53,35,31,7],
[53,35,31,8],
[53,35,31,11],
[53,35,31,14],
[53,35,31,16],
[53,35,31,17],
[53,35,31,19],
[53,35,31,21],
[53,35,31,24],
[53,35,31,25],
[53,35,32,0],
[53,35,32,1],
[53,35,32,2],
[53,35,32,3],
[53,35,32,4],
[53,35,32,5],
[53,35,32,6],
[53,35,32,7],
[53,35,32,8],
[53,35,32,13],
[53,35,32,14],
[53,35,32,16],
[53,35,32,17],
[53,35,32,19],
[53,35,32,21],
[53,35,32,23],
[53,35,32,24],
[53,35,32,25],
[53,35,33,0],
[53,35,33,1],
[53,35,33,2],
[53,35,33,3],
[53,35,33,4],
[53,35,33,5],
[53,35,33,6],
[53,35,33,7],
[53,35,33,13],
[53,35,33,14],
[53,35,33,16],
[53,35,33,17],
[53,35,33,19],
[53,35,33,21],
[53,35,33,23],
[53,35,33,24],
[53,35,34,0],
[53,35,34,1],
[53,35,34,2],
[53,35,34,3],
[53,35,34,4],
[53,35,34,5],
[53,35,34,8],
[53,35,34,11],
[53,35,34,12],
[53,35,34,13],
[53,35,34,14],
[53,35,34,16],
[53,35,34,17],
[53,35,34,19],
[53,35,34,21],
[53,35,34,23],
[53,35,34,24],
[53,36,3,0],
[53,36,3,1],
[53,36,3,2],
[53,36,4,0],
[53,36,4,1],
[53,36,4,2],
[53,36,4,3],
[53,36,5,0],
[53,36,5,1],
[53,36,5,2],
[53,36,5,4],
[53,36,6,0],
[53,36,6,1],
[53,36,6,2],
[53,36,6,4],
[53,36,7,0],
[53,36,7,1],
[53,36,7,2],
[53,36,7,3],
[53,36,7,4],
[53,36,7,5],
[53,36,7,6],
[53,36,8,0],
[53,36,8,1],
[53,36,8,2],
[53,36,8,4],
[53,36,8,7],
[53,36,9,0],
[53,36,9,1],
[53,36,9,2],
[53,36,9,3],
[53,36,9,4],
[53,36,9,5],
[53,36,9,6],
[53,36,9,7],
[53,36,9,8],
[53,36,11,0],
[53,36,11,1],
[53,36,11,2],
[53,36,11,3],
[53,36,11,4],
[53,36,11,5],
[53,36,11,6],
[53,36,11,7],
[53,36,11,8],
[53,36,11,9],
[53,36,12,0],
[53,36,12,1],
[53,36,12,2],
[53,36,12,3],
[53,36,12,4],
[53,36,12,5],
[53,36,12,6],
[53,36,12,7],
[53,36,12,8],
[53,36,12,9],
[53,36,15,0],
[53,36,15,1],
[53,36,15,2],
[53,36,15,3],
[53,36,15,4],
[53,36,15,5],
[53,36,15,6],
[53,36,15,7],
[53,36,15,8],
[53,36,15,11],
[53,36,15,12],
[53,36,16,0],
[53,36,16,1],
[53,36,16,2],
[53,36,16,3],
[53,36,16,4],
[53,36,16,5],
[53,36,16,6],
[53,36,16,7],
[53,36,16,8],
[53,36,16,9],
[53,36,16,11],
[53,36,16,12],
[53,36,16,15],
[53,36,17,0],
[53,36,17,1],
[53,36,17,2],
[53,36,17,3],
[53,36,17,4],
[53,36,17,5],
[53,36,17,6],
[53,36,17,7],
[53,36,17,8],
[53,36,17,9],
[53,36,17,11],
[53,36,17,12],
[53,36,17,15],
[53,36,17,16],
[53,36,18,0],
[53,36,18,1],
[53,36,18,2],
[53,36,18,3],
[53,36,18,4],
[53,36,18,5],
[53,36,18,6],
[53,36,18,7],
[53,36,18,8],
[53,36,18,11],
[53,36,18,12],
[53,36,18,16],
[53,36,18,17],
[53,36,21,0],
[53,36,21,1],
[53,36,21,2],
[53,36,21,4],
[53,36,21,7],
[53,36,21,9],
[53,36,21,11],
[53,36,21,12],
[53,36,21,15],
[53,36,21,16],
[53,36,21,17],
[53,36,21,18],
[53,36,23,0],
[53,36,23,1],
[53,36,23,2],
[53,36,23,3],
[53,36,23,4],
[53,36,23,5],
[53,36,23,6],
[53,36,23,7],
[53,36,23,8],
[53,36,23,9],
[53,36,23,11],
[53,36,23,12],
[53,36,23,15],
[53,36,23,16],
[53,36,23,17],
[53,36,23,18],
[53,36,24,0],
[53,36,24,1],
[53,36,24,2],
[53,36,24,3],
[53,36,24,4],
[53,36,24,5],
[53,36,24,6],
[53,36,24,7],
[53,36,24,8],
[53,36,24,9],
[53,36,24,11],
[53,36,24,12],
[53,36,24,15],
[53,36,24,17],
[53,36,24,18],
[53,36,24,23],
[53,36,25,0],
[53,36,25,1],
[53,36,25,2],
[53,36,25,3],
[53,36,25,4],
[53,36,25,5],
[53,36,25,6],
[53,36,25,8],
[53,36,25,9],
[53,36,25,11],
[53,36,25,12],
[53,36,25,15],
[53,36,25,16],
[53,36,25,17],
[53,36,25,18],
[53,36,25,21],
[53,36,25,23],
[53,36,25,24],
[53,36,26,0],
[53,36,26,1],
[53,36,26,2],
[53,36,26,3],
[53,36,26,4],
[53,36,26,5],
[53,36,26,6],
[53,36,26,7],
[53,36,26,8],
[53,36,26,11],
[53,36,26,12],
[53,36,26,16],
[53,36,26,17],
[53,36,26,21],
[53,36,26,23],
[53,36,26,24],
[53,36,26,25],
[53,36,28,0],
[53,36,28,1],
[53,36,28,2],
[53,36,28,3],
[53,36,28,4],
[53,36,28,5],
[53,36,28,6],
[53,36,28,8],
[53,36,28,9],
[53,36,28,11],
[53,36,28,12],
[53,36,28,16],
[53,36,28,17],
[53,36,28,18],
[53,36,28,21],
[53,36,28,23],
[53,36,28,24],
[53,36,28,26],
[53,36,29,0],
[53,36,29,1],
[53,36,29,2],
[53,36,29,3],
[53,36,29,4],
[53,36,29,5],
[53,36,29,6],
[53,36,29,7],
[53,36,29,8],
[53,36,29,9],
[53,36,29,15],
[53,36,29,16],
[53,36,29,17],
[53,36,29,18],
[53,36,29,21],
[53,36,29,23],
[53,36,29,24],
[53,36,29,25],
[53,36,29,26],
[53,36,30,0],
[53,36,30,1],
[53,36,30,2],
[53,36,30,3],
[53,36,30,4],
[53,36,30,5],
[53,36,30,6],
[53,36,30,7],
[53,36,30,8],
[53,36,30,9],
[53,36,30,11],
[53,36,30,15],
[53,36,30,16],
[53,36,30,17],
[53,36,30,18],
[53,36,30,21],
[53,36,30,24],
[53,36,30,25],
[53,36,30,26],
[53,36,31,0],
[53,36,31,1],
[53,36,31,2],
[53,36,31,3],
[53,36,31,4],
[53,36,31,5],
[53,36,31,6],
[53,36,31,7],
[53,36,31,8],
[53,36,31,9],
[53,36,31,12],
[53,36,31,15],
[53,36,31,16],
[53,36,31,17],
[53,36,31,18],
[53,36,31,21],
[53,36,31,24],
[53,36,31,25],
[53,36,32,0],
[53,36,32,1],
[53,36,32,2],
[53,36,32,3],
[53,36,32,4],
[53,36,32,5],
[53,36,32,6],
[53,36,32,7],
[53,36,32,15],
[53,36,32,16],
[53,36,32,17],
[53,36,32,18],
[53,36,32,21],
[53,36,32,23],
[53,36,32,24],
[53,36,33,0],
[53,36,33,1],
[53,36,33,2],
[53,36,33,3],
[53,36,33,4],
[53,36,33,5],
[53,36,33,8],
[53,36,33,9],
[53,36,33,15],
[53,36,33,16],
[53,36,33,17],
[53,36,33,18],
[53,36,33,21],
[53,36,33,23],
[53,36,33,24],
[53,36,34,0],
[53,36,34,1],
[53,36,34,2],
[53,36,34,3],
[53,36,34,4],
[53,36,34,6],
[53,36,34,8],
[53,36,34,9],
[53,36,34,11],
[53,36,34,12],
[53,36,34,15],
[53,36,34,16],
[53,36,34,17],
[53,36,34,18],
[53,36,34,21],
[53,36,35,0],
[53,36,35,1],
[53,36,35,2],
[53,36,35,5],
[53,36,35,6],
[53,36,35,7],
[53,36,35,8],
[53,36,35,11],
[53,36,35,12],
[53,36,35,16],
[53,36,35,17],
[53,36,35,21],
[53,37,3,0],
[53,37,3,1],
[53,37,3,2],
[53,37,5,0],
[53,37,5,1],
[53,37,5,2],
[53,37,6,0],
[53,37,6,1],
[53,37,6,2],
[53,37,7,0],
[53,37,7,1],
[53,37,7,2],
[53,37,7,3],
[53,37,7,5],
[53,37,7,6],
[53,37,8,0],
[53,37,8,1],
[53,37,8,2],
[53,37,8,7],
[53,37,9,0],
[53,37,9,1],
[53,37,9,2],
[53,37,9,3],
[53,37,9,5],
[53,37,9,6],
[53,37,9,7],
[53,37,9,8],
[53,37,11,0],
[53,37,11,1],
[53,37,11,2],
[53,37,11,3],
[53,37,11,5],
[53,37,11,6],
[53,37,11,7],
[53,37,11,8],
[53,37,11,9],
[53,37,12,0],
[53,37,12,1],
[53,37,12,2],
[53,37,12,3],
[53,37,12,5],
[53,37,12,6],
[53,37,12,7],
[53,37,12,8],
[53,37,12,9],
[53,37,13,0],
[53,37,13,1],
[53,37,13,2],
[53,37,13,3],
[53,37,13,5],
[53,37,13,6],
[53,37,13,7],
[53,37,13,8],
[53,37,13,9],
[53,37,13,11],
[53,37,13,12],
[53,37,14,0],
[53,37,14,1],
[53,37,14,2],
[53,37,14,3],
[53,37,14,5],
[53,37,14,6],
[53,37,14,7],
[53,37,14,8],
[53,37,14,9],
[53,37,14,11],
[53,37,14,12],
[53,37,15,0],
[53,37,15,1],
[53,37,15,2],
[53,37,15,3],
[53,37,15,5],
[53,37,15,6],
[53,37,15,7],
[53,37,15,8],
[53,37,15,11],
[53,37,15,12],
[53,37,15,13],
[53,37,15,14],
[53,37,16,0],
[53,37,16,1],
[53,37,16,2],
[53,37,16,3],
[53,37,16,5],
[53,37,16,6],
[53,37,16,7],
[53,37,16,8],
[53,37,16,9],
[53,37,16,11],
[53,37,16,12],
[53,37,16,13],
[53,37,16,14],
[53,37,16,15],
[53,37,17,0],
[53,37,17,1],
[53,37,17,2],
[53,37,17,3],
[53,37,17,5],
[53,37,17,6],
[53,37,17,7],
[53,37,17,8],
[53,37,17,9],
[53,37,17,11],
[53,37,17,12],
[53,37,17,13],
[53,37,17,14],
[53,37,17,15],
[53,37,17,16],
[53,37,18,0],
[53,37,18,1],
[53,37,18,2],
[53,37,18,3],
[53,37,18,5],
[53,37,18,6],
[53,37,18,7],
[53,37,18,8],
[53,37,18,11],
[53,37,18,12],
[53,37,18,13],
[53,37,18,14],
[53,37,18,16],
[53,37,18,17],
[53,37,19,0],
[53,37,19,1],
[53,37,19,2],
[53,37,19,3],
[53,37,19,5],
[53,37,19,6],
[53,37,19,7],
[53,37,19,8],
[53,37,19,9],
[53,37,19,11],
[53,37,19,12],
[53,37,19,15],
[53,37,19,16],
[53,37,19,17],
[53,37,19,18],
[53,37,21,0],
[53,37,21,1],
[53,37,21,2],
[53,37,21,7],
[53,37,21,9],
[53,37,21,11],
[53,37,21,12],
[53,37,21,13],
[53,37,21,14],
[53,37,21,15],
[53,37,21,16],
[53,37,21,17],
[53,37,21,18],
[53,37,21,19],
[53,37,23,0],
[53,37,23,1],
[53,37,23,2],
[53,37,23,3],
[53,37,23,5],
[53,37,23,6],
[53,37,23,7],
[53,37,23,8],
[53,37,23,9],
[53,37,23,11],
[53,37,23,12],
[53,37,23,13],
[53,37,23,14],
[53,37,23,15],
[53,37,23,16],
[53,37,23,17],
[53,37,23,18],
[53,37,23,19],
[53,37,23,21],
[53,37,24,0],
[53,37,24,1],
[53,37,24,2],
[53,37,24,3],
[53,37,24,5],
[53,37,24,6],
[53,37,24,7],
[53,37,24,8],
[53,37,24,9],
[53,37,24,11],
[53,37,24,12],
[53,37,24,13],
[53,37,24,14],
[53,37,24,15],
[53,37,24,17],
[53,37,24,18],
[53,37,24,19],
[53,37,24,21],
[53,37,24,23],
[53,37,25,0],
[53,37,25,1],
[53,37,25,2],
[53,37,25,3],
[53,37,25,5],
[53,37,25,6],
[53,37,25,8],
[53,37,25,9],
[53,37,25,11],
[53,37,25,12],
[53,37,25,13],
[53,37,25,14],
[53,37,25,15],
[53,37,25,16],
[53,37,25,17],
[53,37,25,19],
[53,37,25,21],
[53,37,25,23],
[53,37,25,24],
[53,37,26,0],
[53,37,26,1],
[53,37,26,2],
[53,37,26,3],
[53,37,26,5],
[53,37,26,6],
[53,37,26,7],
[53,37,26,8],
[53,37,26,11],
[53,37,26,12],
[53,37,26,13],
[53,37,26,14],
[53,37,26,19],
[53,37,26,21],
[53,37,26,23],
[53,37,26,24],
[53,37,26,25],
[53,37,28,0],
[53,37,28,1],
[53,37,28,2],
[53,37,28,3],
[53,37,28,5],
[53,37,28,6],
[53,37,28,8],
[53,37,28,9],
[53,37,28,11],
[53,37,28,12],
[53,37,28,13],
[53,37,28,15],
[53,37,28,16],
[53,37,28,17],
[53,37,28,18],
[53,37,28,19],
[53,37,28,21],
[53,37,28,23],
[53,37,28,24],
[53,37,28,26],
[53,37,29,0],
[53,37,29,1],
[53,37,29,2],
[53,37,29,3],
[53,37,29,5],
[53,37,29,6],
[53,37,29,7],
[53,37,29,8],
[53,37,29,9],
[53,37,29,14],
[53,37,29,15],
[53,37,29,16],
[53,37,29,17],
[53,37,29,18],
[53,37,29,19],
[53,37,29,21],
[53,37,29,23],
[53,37,29,24],
[53,37,29,25],
[53,37,29,26],
[53,37,30,0],
[53,37,30,1],
[53,37,30,2],
[53,37,30,3],
[53,37,30,5],
[53,37,30,6],
[53,37,30,7],
[53,37,30,8],
[53,37,30,9],
[53,37,30,12],
[53,37,30,13],
[53,37,30,14],
[53,37,30,15],
[53,37,30,16],
[53,37,30,17],
[53,37,30,18],
[53,37,30,19],
[53,37,30,21],
[53,37,30,24],
[53,37,30,25],
[53,37,31,0],
[53,37,31,1],
[53,37,31,2],
[53,37,31,3],
[53,37,31,5],
[53,37,31,6],
[53,37,31,7],
[53,37,31,11],
[53,37,31,12],
[53,37,31,13],
[53,37,31,14],
[53,37,31,15],
[53,37,31,16],
[53,37,31,17],
[53,37,31,18],
[53,37,31,19],
[53,37,31,21],
[53,37,31,24],
[53,37,32,0],
[53,37,32,1],
[53,37,32,2],
[53,37,32,3],
[53,37,32,5],
[53,37,32,8],
[53,37,32,9],
[53,37,32,13],
[53,37,32,14],
[53,37,32,15],
[53,37,32,16],
[53,37,32,17],
[53,37,32,18],
[53,37,32,19],
[53,37,32,21],
[53,37,32,23],
[53,37,32,24],
[53,37,33,0],
[53,37,33,1],
[53,37,33,2],
[53,37,33,3],
[53,37,33,6],
[53,37,33,7],
[53,37,33,8],
[53,37,33,9],
[53,37,33,13],
[53,37,33,14],
[53,37,33,15],
[53,37,33,16],
[53,37,33,17],
[53,37,33,18],
[53,37,33,19],
[53,37,33,21],
[53,37,34,0],
[53,37,34,1],
[53,37,34,2],
[53,37,34,5],
[53,37,34,6],
[53,37,34,8],
[53,37,34,9],
[53,37,34,11],
[53,37,34,12],
[53,37,34,13],
[53,37,34,14],
[53,37,34,15],
[53,37,34,16],
[53,37,34,17],
[53,37,34,18],
[53,37,34,19],
[53,37,34,21],
[53,37,35,3],
[53,37,35,5],
[53,37,35,6],
[53,37,35,7],
[53,37,35,8],
[53,37,35,11],
[53,37,35,12],
[53,37,35,13],
[53,37,35,14],
[53,37,35,16],
[53,37,35,17],
[53,37,35,19],
[53,37,36,0],
[53,37,36,1],
[53,37,36,2],
[53,37,36,3],
[53,37,36,5],
[53,37,36,6],
[53,37,36,7],
[53,37,36,8],
[53,37,36,9],
[53,37,36,11],
[53,37,36,12],
[53,37,36,15],
[53,37,36,16],
[53,37,36,17],
[53,37,36,18],
[53,38,3,0],
[53,38,3,1],
[53,38,3,2],
[53,38,4,0],
[53,38,4,1],
[53,38,4,2],
[53,38,4,3],
[53,38,5,0],
[53,38,5,1],
[53,38,5,2],
[53,38,5,4],
[53,38,6,0],
[53,38,6,1],
[53,38,6,2],
[53,38,6,4],
[53,38,8,0],
[53,38,8,1],
[53,38,8,2],
[53,38,8,4],
[53,38,9,0],
[53,38,9,1],
[53,38,9,2],
[53,38,9,3],
[53,38,9,4],
[53,38,9,5],
[53,38,9,6],
[53,38,9,8],
[53,38,11,0],
[53,38,11,1],
[53,38,11,2],
[53,38,11,3],
[53,38,11,4],
[53,38,11,5],
[53,38,11,6],
[53,38,11,8],
[53,38,11,9],
[53,38,12,0],
[53,38,12,1],
[53,38,12,2],
[53,38,12,3],
[53,38,12,4],
[53,38,12,5],
[53,38,12,6],
[53,38,12,8],
[53,38,12,9],
[53,38,13,0],
[53,38,13,1],
[53,38,13,2],
[53,38,13,3],
[53,38,13,4],
[53,38,13,5],
[53,38,13,6],
[53,38,13,8],
[53,38,13,9],
[53,38,13,11],
[53,38,13,12],
[53,38,14,0],
[53,38,14,1],
[53,38,14,2],
[53,38,14,3],
[53,38,14,4],
[53,38,14,5],
[53,38,14,6],
[53,38,14,8],
[53,38,14,9],
[53,38,14,11],
[53,38,14,12],
[53,38,15,0],
[53,38,15,1],
[53,38,15,2],
[53,38,15,3],
[53,38,15,4],
[53,38,15,5],
[53,38,15,6],
[53,38,15,8],
[53,38,15,11],
[53,38,15,12],
[53,38,15,13],
[53,38,15,14],
[53,38,16,0],
[53,38,16,1],
[53,38,16,2],
[53,38,16,3],
[53,38,16,4],
[53,38,16,5],
[53,38,16,6],
[53,38,16,8],
[53,38,16,9],
[53,38,16,11],
[53,38,16,12],
[53,38,16,13],
[53,38,16,14],
[53,38,16,15],
[53,38,17,0],
[53,38,17,1],
[53,38,17,2],
[53,38,17,3],
[53,38,17,4],
[53,38,17,5],
[53,38,17,6],
[53,38,17,8],
[53,38,17,9],
[53,38,17,11],
[53,38,17,12],
[53,38,17,13],
[53,38,17,14],
[53,38,17,15],
[53,38,17,16],
[53,38,18,0],
[53,38,18,1],
[53,38,18,2],
[53,38,18,3],
[53,38,18,4],
[53,38,18,5],
[53,38,18,6],
[53,38,18,8],
[53,38,18,11],
[53,38,18,12],
[53,38,18,13],
[53,38,18,14],
[53,38,18,16],
[53,38,18,17],
[53,38,19,0],
[53,38,19,1],
[53,38,19,2],
[53,38,19,3],
[53,38,19,4],
[53,38,19,5],
[53,38,19,6],
[53,38,19,8],
[53,38,19,9],
[53,38,19,11],
[53,38,19,12],
[53,38,19,15],
[53,38,19,16],
[53,38,19,17],
[53,38,19,18],
[53,38,21,0],
[53,38,21,1],
[53,38,21,2],
[53,38,21,4],
[53,38,21,9],
[53,38,21,11],
[53,38,21,12],
[53,38,21,13],
[53,38,21,14],
[53,38,21,15],
[53,38,21,16],
[53,38,21,17],
[53,38,21,18],
[53,38,21,19],
[53,38,23,0],
[53,38,23,1],
[53,38,23,2],
[53,38,23,3],
[53,38,23,4],
[53,38,23,5],
[53,38,23,6],
[53,38,23,8],
[53,38,23,9],
[53,38,23,11],
[53,38,23,12],
[53,38,23,13],
[53,38,23,14],
[53,38,23,15],
[53,38,23,16],
[53,38,23,17],
[53,38,23,18],
[53,38,23,21],
[53,38,24,0],
[53,38,24,1],
[53,38,24,2],
[53,38,24,3],
[53,38,24,4],
[53,38,24,5],
[53,38,24,6],
[53,38,24,8],
[53,38,24,9],
[53,38,24,11],
[53,38,24,12],
[53,38,24,13],
[53,38,24,14],
[53,38,24,15],
[53,38,24,17],
[53,38,24,18],
[53,38,24,21],
[53,38,24,23],
[53,38,26,0],
[53,38,26,1],
[53,38,26,2],
[53,38,26,3],
[53,38,26,4],
[53,38,26,5],
[53,38,26,6],
[53,38,26,8],
[53,38,26,11],
[53,38,26,12],
[53,38,26,13],
[53,38,26,14],
[53,38,26,16],
[53,38,26,17],
[53,38,26,19],
[53,38,26,21],
[53,38,26,23],
[53,38,26,24],
[53,38,29,0],
[53,38,29,1],
[53,38,29,2],
[53,38,29,3],
[53,38,29,4],
[53,38,29,5],
[53,38,29,6],
[53,38,29,8],
[53,38,29,9],
[53,38,29,13],
[53,38,29,14],
[53,38,29,15],
[53,38,29,16],
[53,38,29,17],
[53,38,29,18],
[53,38,29,19],
[53,38,29,21],
[53,38,29,23],
[53,38,29,24],
[53,38,30,0],
[53,38,30,1],
[53,38,30,2],
[53,38,30,3],
[53,38,30,4],
[53,38,30,5],
[53,38,30,6],
[53,38,30,11],
[53,38,30,12],
[53,38,30,13],
[53,38,30,14],
[53,38,30,15],
[53,38,30,16],
[53,38,30,17],
[53,38,30,18],
[53,38,30,19],
[53,38,30,21],
[53,38,30,24],
[53,38,31,0],
[53,38,31,1],
[53,38,31,2],
[53,38,31,3],
[53,38,31,4],
[53,38,31,5],
[53,38,31,8],
[53,38,31,9],
[53,38,31,11],
[53,38,31,12],
[53,38,31,13],
[53,38,31,14],
[53,38,31,15],
[53,38,31,16],
[53,38,31,17],
[53,38,31,18],
[53,38,31,19],
[53,38,31,21],
[53,38,31,24],
[53,38,32,0],
[53,38,32,1],
[53,38,32,2],
[53,38,32,3],
[53,38,32,4],
[53,38,32,6],
[53,38,32,8],
[53,38,32,9],
[53,38,32,13],
[53,38,32,14],
[53,38,32,15],
[53,38,32,16],
[53,38,32,17],
[53,38,32,18],
[53,38,32,19],
[53,38,32,21],
[53,38,33,0],
[53,38,33,1],
[53,38,33,2],
[53,38,33,5],
[53,38,33,6],
[53,38,33,8],
[53,38,33,9],
[53,38,33,13],
[53,38,33,14],
[53,38,33,15],
[53,38,33,16],
[53,38,33,17],
[53,38,33,18],
[53,38,33,19],
[53,38,33,21],
[53,38,35,0],
[53,38,35,1],
[53,38,35,2],
[53,38,35,3],
[53,38,35,4],
[53,38,35,5],
[53,38,35,6],
[53,38,35,8],
[53,38,35,11],
[53,38,35,12],
[53,38,35,13],
[53,38,35,14],
[53,38,35,16],
[53,38,35,17],
[53,38,35,19],
[53,38,36,0],
[53,38,36,1],
[53,38,36,2],
[53,38,36,3],
[53,38,36,4],
[53,38,36,5],
[53,38,36,6],
[53,38,36,8],
[53,38,36,9],
[53,38,36,11],
[53,38,36,12],
[53,38,36,15],
[53,38,36,16],
[53,38,36,17],
[53,38,36,18],
[53,38,37,0],
[53,38,37,1],
[53,38,37,2],
[53,38,37,3],
[53,38,37,5],
[53,38,37,6],
[53,38,37,8],
[53,38,37,9],
[53,38,37,11],
[53,38,37,12],
[53,38,37,13],
[53,38,37,14],
[53,38,37,15],
[53,38,37,16],
[53,38,37,17],
[53,39,4,0],
[53,39,4,1],
[53,39,4,2],
[53,39,7,0],
[53,39,7,1],
[53,39,7,2],
[53,39,7,4],
[53,39,9,0],
[53,39,9,1],
[53,39,9,2],
[53,39,9,4],
[53,39,9,7],
[53,39,11,0],
[53,39,11,1],
[53,39,11,2],
[53,39,11,4],
[53,39,11,7],
[53,39,11,9],
[53,39,12,0],
[53,39,12,1],
[53,39,12,2],
[53,39,12,4],
[53,39,12,7],
[53,39,12,9],
[53,39,13,0],
[53,39,13,1],
[53,39,13,2],
[53,39,13,4],
[53,39,13,7],
[53,39,13,9],
[53,39,13,11],
[53,39,13,12],
[53,39,14,0],
[53,39,14,1],
[53,39,14,2],
[53,39,14,4],
[53,39,14,7],
[53,39,14,9],
[53,39,14,11],
[53,39,14,12],
[53,39,15,0],
[53,39,15,1],
[53,39,15,2],
[53,39,15,4],
[53,39,15,7],
[53,39,15,11],
[53,39,15,12],
[53,39,15,13],
[53,39,15,14],
[53,39,16,0],
[53,39,16,1],
[53,39,16,2],
[53,39,16,4],
[53,39,16,7],
[53,39,16,9],
[53,39,16,11],
[53,39,16,12],
[53,39,16,13],
[53,39,16,14],
[53,39,16,15],
[53,39,17,0],
[53,39,17,1],
[53,39,17,2],
[53,39,17,4],
[53,39,17,7],
[53,39,17,9],
[53,39,17,11],
[53,39,17,12],
[53,39,17,13],
[53,39,17,14],
[53,39,17,15],
[53,39,17,16],
[53,39,18,0],
[53,39,18,1],
[53,39,18,2],
[53,39,18,4],
[53,39,18,7],
[53,39,18,11],
[53,39,18,12],
[53,39,18,13],
[53,39,18,14],
[53,39,18,16],
[53,39,18,17],
[53,39,19,0],
[53,39,19,1],
[53,39,19,2],
[53,39,19,4],
[53,39,19,7],
[53,39,19,9],
[53,39,19,11],
[53,39,19,12],
[53,39,19,15],
[53,39,19,16],
[53,39,19,17],
[53,39,19,18],
[53,39,23,0],
[53,39,23,1],
[53,39,23,2],
[53,39,23,4],
[53,39,23,7],
[53,39,23,9],
[53,39,23,11],
[53,39,23,12],
[53,39,23,13],
[53,39,23,14],
[53,39,23,15],
[53,39,23,16],
[53,39,23,17],
[53,39,23,19],
[53,39,24,0],
[53,39,24,1],
[53,39,24,2],
[53,39,24,4],
[53,39,24,7],
[53,39,24,9],
[53,39,24,11],
[53,39,24,12],
[53,39,24,13],
[53,39,24,14],
[53,39,24,15],
[53,39,24,17],
[53,39,24,19],
[53,39,24,23],
[53,39,25,0],
[53,39,25,1],
[53,39,25,2],
[53,39,25,4],
[53,39,25,9],
[53,39,25,11],
[53,39,25,12],
[53,39,25,13],
[53,39,25,14],
[53,39,25,16],
[53,39,25,17],
[53,39,25,18],
[53,39,25,19],
[53,39,25,23],
[53,39,25,24],
[53,39,26,0],
[53,39,26,1],
[53,39,26,2],
[53,39,26,4],
[53,39,26,7],
[53,39,26,11],
[53,39,26,12],
[53,39,26,13],
[53,39,26,16],
[53,39,26,17],
[53,39,26,19],
[53,39,26,23],
[53,39,26,24],
[53,39,26,25],
[53,39,28,0],
[53,39,28,1],
[53,39,28,2],
[53,39,28,4],
[53,39,28,9],
[53,39,28,12],
[53,39,28,13],
[53,39,28,14],
[53,39,28,15],
[53,39,28,16],
[53,39,28,17],
[53,39,28,18],
[53,39,28,19],
[53,39,28,23],
[53,39,28,24],
[53,39,29,0],
[53,39,29,1],
[53,39,29,2],
[53,39,29,4],
[53,39,29,7],
[53,39,29,13],
[53,39,29,14],
[53,39,29,15],
[53,39,29,16],
[53,39,29,17],
[53,39,29,18],
[53,39,29,19],
[53,39,29,23],
[53,39,29,24],
[53,39,30,0],
[53,39,30,1],
[53,39,30,2],
[53,39,30,4],
[53,39,30,9],
[53,39,30,11],
[53,39,30,12],
[53,39,30,13],
[53,39,30,14],
[53,39,30,15],
[53,39,30,16],
[53,39,30,17],
[53,39,30,18],
[53,39,30,19],
[53,39,30,24],
[53,39,31,0],
[53,39,31,1],
[53,39,31,2],
[53,39,31,4],
[53,39,31,7],
[53,39,31,9],
[53,39,31,11],
[53,39,31,12],
[53,39,31,13],
[53,39,31,14],
[53,39,31,15],
[53,39,31,16],
[53,39,31,17],
[53,39,31,18],
[53,39,31,19],
[53,39,32,0],
[53,39,32,1],
[53,39,32,2],
[53,39,32,7],
[53,39,32,9],
[53,39,32,13],
[53,39,32,14],
[53,39,32,15],
[53,39,32,16],
[53,39,32,17],
[53,39,32,18],
[53,39,32,19],
[53,39,33,4],
[53,39,33,7],
[53,39,33,9],
[53,39,33,13],
[53,39,33,14],
[53,39,33,15],
[53,39,33,16],
[53,39,33,17],
[53,39,33,18],
[53,39,33,19],
[53,39,34,0],
[53,39,34,1],
[53,39,34,2],
[53,39,34,4],
[53,39,34,9],
[53,39,34,11],
[53,39,34,12],
[53,39,34,13],
[53,39,34,14],
[53,39,34,15],
[53,39,34,16],
[53,39,34,17],
[53,39,34,18],
[53,39,34,19],
[53,39,35,0],
[53,39,35,1],
[53,39,35,2],
[53,39,35,4],
[53,39,35,7],
[53,39,35,11],
[53,39,35,12],
[53,39,35,13],
[53,39,35,14],
[53,39,35,16],
[53,39,35,17],
[53,39,36,0],
[53,39,36,1],
[53,39,36,2],
[53,39,36,4],
[53,39,36,7],
[53,39,36,9],
[53,39,36,11],
[53,39,36,12],
[53,39,36,15],
[53,39,36,16],
[53,39,36,17],
[53,39,37,0],
[53,39,37,1],
[53,39,37,2],
[53,39,37,7],
[53,39,37,9],
[53,39,37,11],
[53,39,37,12],
[53,39,37,13],
[53,39,37,14],
[53,39,37,15],
[53,39,38,0],
[53,39,38,1],
[53,39,38,2],
[53,39,38,4],
[53,39,38,9],
[53,39,38,11],
[53,39,38,12],
[53,39,38,13],
[53,39,38,14],
[53,40,3,0],
[53,40,3,1],
[53,40,3,2],
[53,40,4,0],
[53,40,4,1],
[53,40,4,2],
[53,40,4,3],
[53,40,5,0],
[53,40,5,1],
[53,40,5,2],
[53,40,5,4],
[53,40,6,0],
[53,40,6,1],
[53,40,6,2],
[53,40,6,4],
[53,40,7,0],
[53,40,7,1],
[53,40,7,2],
[53,40,7,3],
[53,40,7,4],
[53,40,7,5],
[53,40,7,6],
[53,40,8,0],
[53,40,8,1],
[53,40,8,2],
[53,40,8,4],
[53,40,8,7],
[53,40,11,0],
[53,40,11,1],
[53,40,11,2],
[53,40,11,3],
[53,40,11,4],
[53,40,11,5],
[53,40,11,6],
[53,40,11,7],
[53,40,11,8],
[53,40,12,0],
[53,40,12,1],
[53,40,12,2],
[53,40,12,3],
[53,40,12,4],
[53,40,12,5],
[53,40,12,6],
[53,40,12,7],
[53,40,12,8],
[53,40,13,0],
[53,40,13,1],
[53,40,13,2],
[53,40,13,3],
[53,40,13,4],
[53,40,13,5],
[53,40,13,6],
[53,40,13,7],
[53,40,13,8],
[53,40,13,11],
[53,40,13,12],
[53,40,14,0],
[53,40,14,1],
[53,40,14,2],
[53,40,14,3],
[53,40,14,4],
[53,40,14,5],
[53,40,14,6],
[53,40,14,7],
[53,40,14,8],
[53,40,14,11],
[53,40,14,12],
[53,40,16,0],
[53,40,16,1],
[53,40,16,2],
[53,40,16,3],
[53,40,16,4],
[53,40,16,5],
[53,40,16,6],
[53,40,16,7],
[53,40,16,8],
[53,40,16,11],
[53,40,16,12],
[53,40,16,13],
[53,40,16,14],
[53,40,17,0],
[53,40,17,1],
[53,40,17,2],
[53,40,17,3],
[53,40,17,4],
[53,40,17,5],
[53,40,17,6],
[53,40,17,7],
[53,40,17,8],
[53,40,17,11],
[53,40,17,12],
[53,40,17,13],
[53,40,17,14],
[53,40,17,16],
[53,40,19,0],
[53,40,19,1],
[53,40,19,2],
[53,40,19,3],
[53,40,19,4],
[53,40,19,5],
[53,40,19,6],
[53,40,19,7],
[53,40,19,8],
[53,40,19,11],
[53,40,19,12],
[53,40,19,16],
[53,40,19,17],
[53,40,21,0],
[53,40,21,1],
[53,40,21,2],
[53,40,21,4],
[53,40,21,7],
[53,40,21,11],
[53,40,21,12],
[53,40,21,13],
[53,40,21,14],
[53,40,21,16],
[53,40,21,17],
[53,40,23,0],
[53,40,23,1],
[53,40,23,2],
[53,40,23,3],
[53,40,23,4],
[53,40,23,5],
[53,40,23,6],
[53,40,23,7],
[53,40,23,8],
[53,40,23,11],
[53,40,23,12],
[53,40,23,13],
[53,40,23,14],
[53,40,23,19],
[53,40,23,21],
[53,40,24,0],
[53,40,24,1],
[53,40,24,2],
[53,40,24,3],
[53,40,24,4],
[53,40,24,5],
[53,40,24,6],
[53,40,24,7],
[53,40,24,8],
[53,40,24,11],
[53,40,24,12],
[53,40,24,13],
[53,40,24,14],
[53,40,24,19],
[53,40,24,21],
[53,40,24,23],
[53,40,25,0],
[53,40,25,1],
[53,40,25,2],
[53,40,25,3],
[53,40,25,4],
[53,40,25,5],
[53,40,25,6],
[53,40,25,8],
[53,40,25,11],
[53,40,25,12],
[53,40,25,13],
[53,40,25,16],
[53,40,25,17],
[53,40,25,19],
[53,40,25,21],
[53,40,25,23],
[53,40,25,24],
[53,40,28,0],
[53,40,28,1],
[53,40,28,2],
[53,40,28,3],
[53,40,28,4],
[53,40,28,5],
[53,40,28,6],
[53,40,28,11],
[53,40,28,12],
[53,40,28,13],
[53,40,28,14],
[53,40,28,16],
[53,40,28,17],
[53,40,28,19],
[53,40,28,21],
[53,40,28,23],
[53,40,28,24],
[53,40,29,0],
[53,40,29,1],
[53,40,29,2],
[53,40,29,3],
[53,40,29,4],
[53,40,29,5],
[53,40,29,8],
[53,40,29,13],
[53,40,29,14],
[53,40,29,16],
[53,40,29,17],
[53,40,29,19],
[53,40,29,21],
[53,40,29,23],
[53,40,29,24],
[53,40,30,0],
[53,40,30,1],
[53,40,30,2],
[53,40,30,3],
[53,40,30,4],
[53,40,30,6],
[53,40,30,7],
[53,40,30,8],
[53,40,30,11],
[53,40,30,12],
[53,40,30,13],
[53,40,30,14],
[53,40,30,16],
[53,40,30,17],
[53,40,30,19],
[53,40,30,21],
[53,40,31,0],
[53,40,31,1],
[53,40,31,2],
[53,40,31,5],
[53,40,31,6],
[53,40,31,7],
[53,40,31,8],
[53,40,31,11],
[53,40,31,12],
[53,40,31,13],
[53,40,31,14],
[53,40,31,16],
[53,40,31,17],
[53,40,31,19],
[53,40,31,21],
[53,40,32,3],
[53,40,32,4],
[53,40,32,5],
[53,40,32,6],
[53,40,32,7],
[53,40,32,8],
[53,40,32,13],
[53,40,32,14],
[53,40,32,16],
[53,40,32,17],
[53,40,32,19],
[53,40,33,0],
[53,40,33,1],
[53,40,33,2],
[53,40,33,3],
[53,40,33,4],
[53,40,33,5],
[53,40,33,6],
[53,40,33,7],
[53,40,33,8],
[53,40,33,13],
[53,40,33,14],
[53,40,33,16],
[53,40,33,17],
[53,40,33,19],
[53,40,34,0],
[53,40,34,1],
[53,40,34,2],
[53,40,34,3],
[53,40,34,4],
[53,40,34,5],
[53,40,34,6],
[53,40,34,8],
[53,40,34,11],
[53,40,34,12],
[53,40,34,13],
[53,40,34,14],
[53,40,34,16],
[53,40,34,17],
[53,40,36,0],
[53,40,36,1],
[53,40,36,2],
[53,40,36,3],
[53,40,36,4],
[53,40,36,5],
[53,40,36,6],
[53,40,36,7],
[53,40,36,8],
[53,40,36,11],
[53,40,36,12],
[53,40,37,0],
[53,40,37,1],
[53,40,37,2],
[53,40,37,3],
[53,40,37,5],
[53,40,37,6],
[53,40,37,7],
[53,40,37,8],
[53,40,37,11],
[53,40,37,12],
[53,40,37,13],
[53,40,37,14],
[53,40,38,0],
[53,40,38,1],
[53,40,38,2],
[53,40,38,3],
[53,40,38,4],
[53,40,38,5],
[53,40,38,6],
[53,40,38,8],
[53,40,38,11],
[53,40,38,12],
[53,40,38,13],
[53,40,39,0],
[53,40,39,1],
[53,40,39,2],
[53,40,39,4],
[53,40,39,7],
[53,40,39,11],
[53,41,3,0],
[53,41,3,1],
[53,41,3,2],
[53,41,4,0],
[53,41,4,1],
[53,41,4,2],
[53,41,4,3],
[53,41,5,0],
[53,41,5,1],
[53,41,5,2],
[53,41,5,4],
[53,41,6,0],
[53,41,6,1],
[53,41,6,2],
[53,41,6,4],
[53,41,7,0],
[53,41,7,1],
[53,41,7,2],
[53,41,7,3],
[53,41,7,4],
[53,41,7,5],
[53,41,7,6],
[53,41,8,0],
[53,41,8,1],
[53,41,8,2],
[53,41,8,4],
[53,41,8,7],
[53,41,11,0],
[53,41,11,1],
[53,41,11,2],
[53,41,11,3],
[53,41,11,4],
[53,41,11,5],
[53,41,11,6],
[53,41,11,7],
[53,41,11,8],
[53,41,12,0],
[53,41,12,1],
[53,41,12,2],
[53,41,12,3],
[53,41,12,4],
[53,41,12,5],
[53,41,12,6],
[53,41,12,7],
[53,41,12,8],
[53,41,13,0],
[53,41,13,1],
[53,41,13,2],
[53,41,13,3],
[53,41,13,4],
[53,41,13,5],
[53,41,13,6],
[53,41,13,7],
[53,41,13,8],
[53,41,13,11],
[53,41,13,12],
[53,41,14,0],
[53,41,14,1],
[53,41,14,2],
[53,41,14,3],
[53,41,14,4],
[53,41,14,5],
[53,41,14,6],
[53,41,14,7],
[53,41,14,8],
[53,41,14,11],
[53,41,14,12],
[53,41,16,0],
[53,41,16,1],
[53,41,16,2],
[53,41,16,3],
[53,41,16,4],
[53,41,16,5],
[53,41,16,6],
[53,41,16,7],
[53,41,16,8],
[53,41,16,11],
[53,41,16,12],
[53,41,16,13],
[53,41,16,14],
[53,41,17,0],
[53,41,17,1],
[53,41,17,2],
[53,41,17,3],
[53,41,17,4],
[53,41,17,5],
[53,41,17,6],
[53,41,17,7],
[53,41,17,8],
[53,41,17,11],
[53,41,17,12],
[53,41,17,13],
[53,41,17,14],
[53,41,17,16],
[53,41,19,0],
[53,41,19,1],
[53,41,19,2],
[53,41,19,3],
[53,41,19,4],
[53,41,19,5],
[53,41,19,6],
[53,41,19,7],
[53,41,19,8],
[53,41,19,11],
[53,41,19,12],
[53,41,19,16],
[53,41,19,17],
[53,41,21,0],
[53,41,21,1],
[53,41,21,2],
[53,41,21,4],
[53,41,21,7],
[53,41,21,11],
[53,41,21,12],
[53,41,21,13],
[53,41,21,14],
[53,41,21,16],
[53,41,21,17],
[53,41,23,0],
[53,41,23,1],
[53,41,23,2],
[53,41,23,3],
[53,41,23,4],
[53,41,23,5],
[53,41,23,6],
[53,41,23,7],
[53,41,23,8],
[53,41,23,11],
[53,41,23,12],
[53,41,23,13],
[53,41,23,14],
[53,41,23,19],
[53,41,23,21],
[53,41,24,0],
[53,41,24,1],
[53,41,24,2],
[53,41,24,3],
[53,41,24,4],
[53,41,24,5],
[53,41,24,6],
[53,41,24,7],
[53,41,24,8],
[53,41,24,11],
[53,41,24,12],
[53,41,24,13],
[53,41,24,14],
[53,41,24,19],
[53,41,24,21],
[53,41,24,23],
[53,41,25,0],
[53,41,25,1],
[53,41,25,2],
[53,41,25,3],
[53,41,25,4],
[53,41,25,5],
[53,41,25,6],
[53,41,25,8],
[53,41,25,11],
[53,41,25,12],
[53,41,25,13],
[53,41,25,16],
[53,41,25,17],
[53,41,25,19],
[53,41,25,21],
[53,41,25,23],
[53,41,25,24],
[53,41,28,0],
[53,41,28,1],
[53,41,28,2],
[53,41,28,3],
[53,41,28,4],
[53,41,28,5],
[53,41,28,6],
[53,41,28,11],
[53,41,28,12],
[53,41,28,13],
[53,41,28,14],
[53,41,28,16],
[53,41,28,17],
[53,41,28,19],
[53,41,28,21],
[53,41,28,23],
[53,41,28,24],
[53,41,29,0],
[53,41,29,1],
[53,41,29,2],
[53,41,29,3],
[53,41,29,4],
[53,41,29,5],
[53,41,29,8],
[53,41,29,13],
[53,41,29,14],
[53,41,29,16],
[53,41,29,17],
[53,41,29,19],
[53,41,29,21],
[53,41,29,23],
[53,41,29,24],
[53,41,30,0],
[53,41,30,1],
[53,41,30,2],
[53,41,30,3],
[53,41,30,4],
[53,41,30,6],
[53,41,30,7],
[53,41,30,8],
[53,41,30,11],
[53,41,30,12],
[53,41,30,13],
[53,41,30,14],
[53,41,30,16],
[53,41,30,17],
[53,41,30,19],
[53,41,30,21],
[53,41,31,0],
[53,41,31,1],
[53,41,31,2],
[53,41,31,5],
[53,41,31,6],
[53,41,31,7],
[53,41,31,8],
[53,41,31,11],
[53,41,31,12],
[53,41,31,13],
[53,41,31,14],
[53,41,31,16],
[53,41,31,17],
[53,41,31,19],
[53,41,31,21],
[53,41,32,3],
[53,41,32,4],
[53,41,32,5],
[53,41,32,6],
[53,41,32,7],
[53,41,32,8],
[53,41,32,13],
[53,41,32,14],
[53,41,32,16],
[53,41,32,17],
[53,41,32,19],
[53,41,33,0],
[53,41,33,1],
[53,41,33,2],
[53,41,33,3],
[53,41,33,4],
[53,41,33,5],
[53,41,33,6],
[53,41,33,7],
[53,41,33,8],
[53,41,33,13],
[53,41,33,14],
[53,41,33,16],
[53,41,33,17],
[53,41,33,19],
[53,41,34,0],
[53,41,34,1],
[53,41,34,2],
[53,41,34,3],
[53,41,34,4],
[53,41,34,5],
[53,41,34,6],
[53,41,34,8],
[53,41,34,11],
[53,41,34,12],
[53,41,34,13],
[53,41,34,14],
[53,41,34,16],
[53,41,34,17],
[53,41,36,0],
[53,41,36,1],
[53,41,36,2],
[53,41,36,3],
[53,41,36,4],
[53,41,36,5],
[53,41,36,6],
[53,41,36,7],
[53,41,36,8],
[53,41,36,11],
[53,41,36,12],
[53,41,37,0],
[53,41,37,1],
[53,41,37,2],
[53,41,37,3],
[53,41,37,5],
[53,41,37,6],
[53,41,37,7],
[53,41,37,8],
[53,41,37,11],
[53,41,37,12],
[53,41,37,13],
[53,41,37,14],
[53,41,38,0],
[53,41,38,1],
[53,41,38,2],
[53,41,38,3],
[53,41,38,4],
[53,41,38,5],
[53,41,38,6],
[53,41,38,8],
[53,41,38,11],
[53,41,38,12],
[53,41,38,13],
[53,41,39,0],
[53,41,39,1],
[53,41,39,2],
[53,41,39,4],
[53,41,39,7],
[53,41,39,11],
[53,43,3,0],
[53,43,3,1],
[53,43,3,2],
[53,43,4,0],
[53,43,4,1],
[53,43,4,2],
[53,43,4,3],
[53,43,5,0],
[53,43,5,1],
[53,43,5,2],
[53,43,5,4],
[53,43,6,0],
[53,43,6,1],
[53,43,6,2],
[53,43,6,4],
[53,43,8,0],
[53,43,8,1],
[53,43,8,2],
[53,43,8,4],
[53,43,9,0],
[53,43,9,1],
[53,43,9,2],
[53,43,9,3],
[53,43,9,4],
[53,43,9,5],
[53,43,9,6],
[53,43,9,8],
[53,43,11,0],
[53,43,11,1],
[53,43,11,2],
[53,43,11,3],
[53,43,11,4],
[53,43,11,5],
[53,43,11,6],
[53,43,11,8],
[53,43,11,9],
[53,43,12,0],
[53,43,12,1],
[53,43,12,2],
[53,43,12,3],
[53,43,12,4],
[53,43,12,5],
[53,43,12,6],
[53,43,12,8],
[53,43,12,9],
[53,43,13,0],
[53,43,13,1],
[53,43,13,2],
[53,43,13,3],
[53,43,13,4],
[53,43,13,5],
[53,43,13,6],
[53,43,13,8],
[53,43,13,9],
[53,43,13,11],
[53,43,13,12],
[53,43,14,0],
[53,43,14,1],
[53,43,14,2],
[53,43,14,3],
[53,43,14,4],
[53,43,14,5],
[53,43,14,6],
[53,43,14,8],
[53,43,14,9],
[53,43,14,11],
[53,43,14,12],
[53,43,15,0],
[53,43,15,1],
[53,43,15,2],
[53,43,15,3],
[53,43,15,4],
[53,43,15,5],
[53,43,15,6],
[53,43,15,8],
[53,43,15,11],
[53,43,15,12],
[53,43,15,13],
[53,43,15,14],
[53,43,16,0],
[53,43,16,1],
[53,43,16,2],
[53,43,16,3],
[53,43,16,4],
[53,43,16,5],
[53,43,16,6],
[53,43,16,8],
[53,43,16,9],
[53,43,16,11],
[53,43,16,12],
[53,43,16,13],
[53,43,16,14],
[53,43,16,15],
[53,43,17,0],
[53,43,17,1],
[53,43,17,2],
[53,43,17,3],
[53,43,17,4],
[53,43,17,5],
[53,43,17,6],
[53,43,17,8],
[53,43,17,9],
[53,43,17,11],
[53,43,17,12],
[53,43,17,13],
[53,43,17,14],
[53,43,17,15],
[53,43,17,16],
[53,43,18,0],
[53,43,18,1],
[53,43,18,2],
[53,43,18,3],
[53,43,18,4],
[53,43,18,5],
[53,43,18,6],
[53,43,18,8],
[53,43,18,11],
[53,43,18,12],
[53,43,18,13],
[53,43,18,14],
[53,43,18,16],
[53,43,18,17],
[53,43,19,0],
[53,43,19,1],
[53,43,19,2],
[53,43,19,3],
[53,43,19,4],
[53,43,19,5],
[53,43,19,6],
[53,43,19,8],
[53,43,19,9],
[53,43,19,11],
[53,43,19,12],
[53,43,19,15],
[53,43,19,16],
[53,43,19,17],
[53,43,19,18],
[53,43,21,0],
[53,43,21,1],
[53,43,21,2],
[53,43,21,4],
[53,43,21,9],
[53,43,21,11],
[53,43,21,12],
[53,43,21,13],
[53,43,21,14],
[53,43,21,15],
[53,43,21,18],
[53,43,21,19],
[53,43,23,0],
[53,43,23,1],
[53,43,23,2],
[53,43,23,3],
[53,43,23,4],
[53,43,23,5],
[53,43,23,6],
[53,43,23,8],
[53,43,23,9],
[53,43,23,11],
[53,43,23,12],
[53,43,23,13],
[53,43,23,15],
[53,43,23,16],
[53,43,23,17],
[53,43,23,18],
[53,43,23,19],
[53,43,23,21],
[53,43,24,0],
[53,43,24,1],
[53,43,24,2],
[53,43,24,3],
[53,43,24,4],
[53,43,24,5],
[53,43,24,6],
[53,43,24,8],
[53,43,24,9],
[53,43,24,11],
[53,43,24,12],
[53,43,24,13],
[53,43,24,15],
[53,43,24,17],
[53,43,24,18],
[53,43,24,19],
[53,43,24,21],
[53,43,24,23],
[53,43,26,0],
[53,43,26,1],
[53,43,26,2],
[53,43,26,3],
[53,43,26,4],
[53,43,26,5],
[53,43,26,6],
[53,43,26,11],
[53,43,26,12],
[53,43,26,13],
[53,43,26,14],
[53,43,26,16],
[53,43,26,17],
[53,43,26,19],
[53,43,26,21],
[53,43,26,23],
[53,43,26,24],
[53,43,29,0],
[53,43,29,1],
[53,43,29,2],
[53,43,29,5],
[53,43,29,6],
[53,43,29,8],
[53,43,29,9],
[53,43,29,13],
[53,43,29,14],
[53,43,29,15],
[53,43,29,16],
[53,43,29,17],
[53,43,29,18],
[53,43,29,19],
[53,43,29,21],
[53,43,30,3],
[53,43,30,4],
[53,43,30,5],
[53,43,30,6],
[53,43,30,8],
[53,43,30,9],
[53,43,30,11],
[53,43,30,12],
[53,43,30,13],
[53,43,30,14],
[53,43,30,15],
[53,43,30,16],
[53,43,30,17],
[53,43,30,18],
[53,43,30,19],
[53,43,31,0],
[53,43,31,1],
[53,43,31,2],
[53,43,31,3],
[53,43,31,4],
[53,43,31,5],
[53,43,31,6],
[53,43,31,8],
[53,43,31,9],
[53,43,31,11],
[53,43,31,12],
[53,43,31,13],
[53,43,31,14],
[53,43,31,15],
[53,43,31,16],
[53,43,31,17],
[53,43,31,18],
[53,43,31,19],
[53,43,32,0],
[53,43,32,1],
[53,43,32,2],
[53,43,32,3],
[53,43,32,4],
[53,43,32,5],
[53,43,32,6],
[53,43,32,8],
[53,43,32,9],
[53,43,32,13],
[53,43,32,14],
[53,43,32,15],
[53,43,32,16],
[53,43,32,17],
[53,43,32,18],
[53,43,33,0],
[53,43,33,1],
[53,43,33,2],
[53,43,33,3],
[53,43,33,4],
[53,43,33,5],
[53,43,33,6],
[53,43,33,8],
[53,43,33,9],
[53,43,33,13],
[53,43,33,14],
[53,43,33,15],
[53,43,33,16],
[53,43,33,17],
[53,43,35,0],
[53,43,35,1],
[53,43,35,2],
[53,43,35,3],
[53,43,35,4],
[53,43,35,5],
[53,43,35,6],
[53,43,35,8],
[53,43,35,11],
[53,43,35,12],
[53,43,35,13],
[53,43,35,14],
[53,43,36,0],
[53,43,36,1],
[53,43,36,2],
[53,43,36,3],
[53,43,36,4],
[53,43,36,5],
[53,43,36,6],
[53,43,36,8],
[53,43,36,9],
[53,43,36,11],
[53,43,36,12],
[53,43,37,0],
[53,43,37,1],
[53,43,37,2],
[53,43,37,3],
[53,43,37,5],
[53,43,37,6],
[53,43,37,8],
[53,43,37,9],
[53,43,37,11],
[53,43,39,0],
[53,43,39,1],
[53,43,39,2],
[53,43,39,4],
[53,43,40,0],
[53,43,40,1],
[53,43,40,2],
[53,43,40,3],
[53,43,40,4],
[53,43,40,5],
[53,43,41,0],
[53,43,41,1],
[53,43,41,2],
[53,43,41,3],
[53,43,41,4],
[53,43,41,5],
[53,44,3,0],
[53,44,3,1],
[53,44,3,2],
[53,44,4,0],
[53,44,4,1],
[53,44,4,2],
[53,44,4,3],
[53,44,5,0],
[53,44,5,1],
[53,44,5,2],
[53,44,5,4],
[53,44,6,0],
[53,44,6,1],
[53,44,6,2],
[53,44,6,4],
[53,44,7,0],
[53,44,7,1],
[53,44,7,2],
[53,44,7,3],
[53,44,7,4],
[53,44,7,5],
[53,44,7,6],
[53,44,8,0],
[53,44,8,1],
[53,44,8,2],
[53,44,8,4],
[53,44,8,7],
[53,44,9,0],
[53,44,9,1],
[53,44,9,2],
[53,44,9,3],
[53,44,9,4],
[53,44,9,5],
[53,44,9,6],
[53,44,9,7],
[53,44,9,8],
[53,44,11,0],
[53,44,11,1],
[53,44,11,2],
[53,44,11,3],
[53,44,11,4],
[53,44,11,5],
[53,44,11,6],
[53,44,11,7],
[53,44,11,8],
[53,44,11,9],
[53,44,12,0],
[53,44,12,1],
[53,44,12,2],
[53,44,12,3],
[53,44,12,4],
[53,44,12,5],
[53,44,12,6],
[53,44,12,7],
[53,44,12,8],
[53,44,12,9],
[53,44,13,0],
[53,44,13,1],
[53,44,13,2],
[53,44,13,3],
[53,44,13,4],
[53,44,13,5],
[53,44,13,6],
[53,44,13,7],
[53,44,13,8],
[53,44,13,9],
[53,44,13,11],
[53,44,13,12],
[53,44,14,0],
[53,44,14,1],
[53,44,14,2],
[53,44,14,3],
[53,44,14,4],
[53,44,14,5],
[53,44,14,6],
[53,44,14,7],
[53,44,14,8],
[53,44,14,9],
[53,44,14,11],
[53,44,14,12],
[53,44,15,0],
[53,44,15,1],
[53,44,15,2],
[53,44,15,3],
[53,44,15,4],
[53,44,15,5],
[53,44,15,6],
[53,44,15,7],
[53,44,15,8],
[53,44,15,11],
[53,44,15,12],
[53,44,15,13],
[53,44,15,14],
[53,44,16,0],
[53,44,16,1],
[53,44,16,2],
[53,44,16,3],
[53,44,16,4],
[53,44,16,5],
[53,44,16,6],
[53,44,16,7],
[53,44,16,8],
[53,44,16,9],
[53,44,16,11],
[53,44,16,12],
[53,44,16,13],
[53,44,16,14],
[53,44,16,15],
[53,44,18,0],
[53,44,18,1],
[53,44,18,2],
[53,44,18,3],
[53,44,18,4],
[53,44,18,5],
[53,44,18,6],
[53,44,18,7],
[53,44,18,8],
[53,44,18,11],
[53,44,18,12],
[53,44,18,13],
[53,44,18,14],
[53,44,18,16],
[53,44,19,0],
[53,44,19,1],
[53,44,19,2],
[53,44,19,3],
[53,44,19,4],
[53,44,19,5],
[53,44,19,6],
[53,44,19,7],
[53,44,19,8],
[53,44,19,9],
[53,44,19,11],
[53,44,19,12],
[53,44,19,15],
[53,44,19,16],
[53,44,21,0],
[53,44,21,1],
[53,44,21,2],
[53,44,21,4],
[53,44,21,7],
[53,44,21,9],
[53,44,21,11],
[53,44,21,12],
[53,44,21,13],
[53,44,21,14],
[53,44,21,16],
[53,44,21,18],
[53,44,21,19],
[53,44,23,0],
[53,44,23,1],
[53,44,23,2],
[53,44,23,3],
[53,44,23,4],
[53,44,23,5],
[53,44,23,6],
[53,44,23,7],
[53,44,23,8],
[53,44,23,9],
[53,44,23,11],
[53,44,23,14],
[53,44,23,15],
[53,44,23,16],
[53,44,23,18],
[53,44,23,19],
[53,44,23,21],
[53,44,24,0],
[53,44,24,1],
[53,44,24,2],
[53,44,24,3],
[53,44,24,4],
[53,44,24,5],
[53,44,24,6],
[53,44,24,7],
[53,44,24,8],
[53,44,24,9],
[53,44,24,11],
[53,44,24,14],
[53,44,24,15],
[53,44,24,18],
[53,44,24,19],
[53,44,24,21],
[53,44,24,23],
[53,44,25,0],
[53,44,25,1],
[53,44,25,2],
[53,44,25,3],
[53,44,25,4],
[53,44,25,5],
[53,44,25,6],
[53,44,25,11],
[53,44,25,12],
[53,44,25,13],
[53,44,25,14],
[53,44,25,15],
[53,44,25,16],
[53,44,25,18],
[53,44,25,19],
[53,44,25,21],
[53,44,25,23],
[53,44,25,24],
[53,44,26,0],
[53,44,26,1],
[53,44,26,2],
[53,44,26,3],
[53,44,26,4],
[53,44,26,5],
[53,44,26,8],
[53,44,26,11],
[53,44,26,12],
[53,44,26,13],
[53,44,26,14],
[53,44,26,16],
[53,44,26,19],
[53,44,26,21],
[53,44,26,23],
[53,44,26,24],
[53,44,28,0],
[53,44,28,1],
[53,44,28,2],
[53,44,28,5],
[53,44,28,6],
[53,44,28,8],
[53,44,28,9],
[53,44,28,11],
[53,44,28,12],
[53,44,28,13],
[53,44,28,14],
[53,44,28,15],
[53,44,28,16],
[53,44,28,18],
[53,44,28,19],
[53,44,28,21],
[53,44,29,3],
[53,44,29,4],
[53,44,29,5],
[53,44,29,6],
[53,44,29,7],
[53,44,29,8],
[53,44,29,9],
[53,44,29,13],
[53,44,29,14],
[53,44,29,15],
[53,44,29,16],
[53,44,29,18],
[53,44,29,19],
[53,44,30,0],
[53,44,30,1],
[53,44,30,2],
[53,44,30,3],
[53,44,30,4],
[53,44,30,5],
[53,44,30,6],
[53,44,30,7],
[53,44,30,8],
[53,44,30,9],
[53,44,30,11],
[53,44,30,12],
[53,44,30,13],
[53,44,30,14],
[53,44,30,15],
[53,44,30,16],
[53,44,30,18],
[53,44,30,19],
[53,44,31,0],
[53,44,31,1],
[53,44,31,2],
[53,44,31,3],
[53,44,31,4],
[53,44,31,5],
[53,44,31,6],
[53,44,31,7],
[53,44,31,8],
[53,44,31,9],
[53,44,31,11],
[53,44,31,12],
[53,44,31,13],
[53,44,31,14],
[53,44,31,15],
[53,44,31,16],
[53,44,31,18],
[53,44,32,0],
[53,44,32,1],
[53,44,32,2],
[53,44,32,3],
[53,44,32,4],
[53,44,32,5],
[53,44,32,6],
[53,44,32,7],
[53,44,32,8],
[53,44,32,9],
[53,44,32,13],
[53,44,32,14],
[53,44,32,15],
[53,44,32,16],
[53,44,33,0],
[53,44,33,1],
[53,44,33,2],
[53,44,33,3],
[53,44,33,4],
[53,44,33,5],
[53,44,33,6],
[53,44,33,7],
[53,44,33,8],
[53,44,33,9],
[53,44,33,13],
[53,44,33,14],
[53,44,33,15],
[53,44,34,0],
[53,44,34,1],
[53,44,34,2],
[53,44,34,3],
[53,44,34,4],
[53,44,34,5],
[53,44,34,6],
[53,44,34,8],
[53,44,34,9],
[53,44,34,11],
[53,44,34,12],
[53,44,34,13],
[53,44,34,14],
[53,44,35,0],
[53,44,35,1],
[53,44,35,2],
[53,44,35,3],
[53,44,35,4],
[53,44,35,5],
[53,44,35,6],
[53,44,35,7],
[53,44,35,8],
[53,44,35,11],
[53,44,35,12],
[53,44,35,13],
[53,44,36,0],
[53,44,36,1],
[53,44,36,2],
[53,44,36,3],
[53,44,36,4],
[53,44,36,5],
[53,44,36,6],
[53,44,36,7],
[53,44,36,8],
[53,44,36,9],
[53,44,36,11],
[53,44,37,0],
[53,44,37,1],
[53,44,37,2],
[53,44,37,3],
[53,44,37,5],
[53,44,37,6],
[53,44,37,7],
[53,44,37,8],
[53,44,37,9],
[53,44,38,0],
[53,44,38,1],
[53,44,38,2],
[53,44,38,3],
[53,44,38,4],
[53,44,38,5],
[53,44,38,6],
[53,44,39,0],
[53,44,39,1],
[53,44,39,2],
[53,44,39,4],
[53,44,40,0],
[53,44,40,1],
[53,44,40,2],
[53,44,40,3],
[53,44,40,4],
[53,44,41,0],
[53,44,41,1],
[53,44,41,2],
[53,44,41,3],
[53,44,41,4],
[53,45,3,0],
[53,45,3,1],
[53,45,3,2],
[53,45,4,0],
[53,45,4,1],
[53,45,4,2],
[53,45,4,3],
[53,45,5,0],
[53,45,5,1],
[53,45,5,2],
[53,45,5,4],
[53,45,6,0],
[53,45,6,1],
[53,45,6,2],
[53,45,6,4],
[53,45,7,0],
[53,45,7,1],
[53,45,7,2],
[53,45,7,3],
[53,45,7,4],
[53,45,7,5],
[53,45,7,6],
[53,45,8,0],
[53,45,8,1],
[53,45,8,2],
[53,45,8,4],
[53,45,8,7],
[53,45,11,0],
[53,45,11,1],
[53,45,11,2],
[53,45,11,3],
[53,45,11,4],
[53,45,11,5],
[53,45,11,6],
[53,45,11,7],
[53,45,11,8],
[53,45,12,0],
[53,45,12,1],
[53,45,12,2],
[53,45,12,3],
[53,45,12,4],
[53,45,12,5],
[53,45,12,6],
[53,45,12,7],
[53,45,12,8],
[53,45,13,0],
[53,45,13,1],
[53,45,13,2],
[53,45,13,3],
[53,45,13,4],
[53,45,13,5],
[53,45,13,6],
[53,45,13,7],
[53,45,13,8],
[53,45,13,11],
[53,45,13,12],
[53,45,14,0],
[53,45,14,1],
[53,45,14,2],
[53,45,14,3],
[53,45,14,4],
[53,45,14,5],
[53,45,14,6],
[53,45,14,7],
[53,45,14,8],
[53,45,14,11],
[53,45,14,12],
[53,45,16,0],
[53,45,16,1],
[53,45,16,2],
[53,45,16,3],
[53,45,16,4],
[53,45,16,5],
[53,45,16,6],
[53,45,16,7],
[53,45,16,8],
[53,45,16,11],
[53,45,16,12],
[53,45,16,13],
[53,45,16,14],
[53,45,17,0],
[53,45,17,1],
[53,45,17,2],
[53,45,17,3],
[53,45,17,4],
[53,45,17,5],
[53,45,17,6],
[53,45,17,7],
[53,45,17,8],
[53,45,17,11],
[53,45,17,12],
[53,45,17,13],
[53,45,17,14],
[53,45,17,16],
[53,45,19,0],
[53,45,19,1],
[53,45,19,2],
[53,45,19,3],
[53,45,19,4],
[53,45,19,5],
[53,45,19,6],
[53,45,19,7],
[53,45,19,8],
[53,45,19,11],
[53,45,19,12],
[53,45,21,0],
[53,45,21,1],
[53,45,21,2],
[53,45,21,4],
[53,45,21,7],
[53,45,21,11],
[53,45,21,12],
[53,45,21,13],
[53,45,21,16],
[53,45,21,17],
[53,45,21,19],
[53,45,23,0],
[53,45,23,1],
[53,45,23,2],
[53,45,23,3],
[53,45,23,4],
[53,45,23,5],
[53,45,23,6],
[53,45,23,7],
[53,45,23,8],
[53,45,23,12],
[53,45,23,13],
[53,45,23,14],
[53,45,23,16],
[53,45,23,17],
[53,45,23,19],
[53,45,23,21],
[53,45,24,0],
[53,45,24,1],
[53,45,24,2],
[53,45,24,3],
[53,45,24,4],
[53,45,24,5],
[53,45,24,6],
[53,45,24,7],
[53,45,24,8],
[53,45,24,12],
[53,45,24,13],
[53,45,24,14],
[53,45,24,17],
[53,45,24,19],
[53,45,24,21],
[53,45,24,23],
[53,45,25,0],
[53,45,25,1],
[53,45,25,2],
[53,45,25,3],
[53,45,25,4],
[53,45,25,5],
[53,45,25,8],
[53,45,25,11],
[53,45,25,12],
[53,45,25,13],
[53,45,25,14],
[53,45,25,16],
[53,45,25,17],
[53,45,25,19],
[53,45,25,21],
[53,45,25,23],
[53,45,25,24],
[53,45,28,3],
[53,45,28,4],
[53,45,28,5],
[53,45,28,6],
[53,45,28,8],
[53,45,28,11],
[53,45,28,12],
[53,45,28,13],
[53,45,28,14],
[53,45,28,16],
[53,45,28,17],
[53,45,28,19],
[53,45,29,0],
[53,45,29,1],
[53,45,29,2],
[53,45,29,3],
[53,45,29,4],
[53,45,29,5],
[53,45,29,6],
[53,45,29,7],
[53,45,29,8],
[53,45,29,13],
[53,45,29,14],
[53,45,29,16],
[53,45,29,17],
[53,45,29,19],
[53,45,30,0],
[53,45,30,1],
[53,45,30,2],
[53,45,30,3],
[53,45,30,4],
[53,45,30,5],
[53,45,30,6],
[53,45,30,7],
[53,45,30,8],
[53,45,30,11],
[53,45,30,12],
[53,45,30,13],
[53,45,30,14],
[53,45,30,16],
[53,45,30,17],
[53,45,31,0],
[53,45,31,1],
[53,45,31,2],
[53,45,31,3],
[53,45,31,4],
[53,45,31,5],
[53,45,31,6],
[53,45,31,7],
[53,45,31,8],
[53,45,31,11],
[53,45,31,12],
[53,45,31,13],
[53,45,31,14],
[53,45,31,16],
[53,45,31,17],
[53,45,32,0],
[53,45,32,1],
[53,45,32,2],
[53,45,32,3],
[53,45,32,4],
[53,45,32,5],
[53,45,32,6],
[53,45,32,7],
[53,45,32,8],
[53,45,32,13],
[53,45,32,14],
[53,45,33,0],
[53,45,33,1],
[53,45,33,2],
[53,45,33,3],
[53,45,33,4],
[53,45,33,5],
[53,45,33,6],
[53,45,33,7],
[53,45,33,8],
[53,45,33,13],
[53,45,33,14],
[53,45,34,0],
[53,45,34,1],
[53,45,34,2],
[53,45,34,3],
[53,45,34,4],
[53,45,34,5],
[53,45,34,6],
[53,45,34,8],
[53,45,34,11],
[53,45,34,12],
[53,45,34,13],
[53,45,36,0],
[53,45,36,1],
[53,45,36,2],
[53,45,36,3],
[53,45,36,4],
[53,45,36,5],
[53,45,36,6],
[53,45,36,7],
[53,45,36,8],
[53,45,37,0],
[53,45,37,1],
[53,45,37,2],
[53,45,37,3],
[53,45,37,5],
[53,45,37,6],
[53,45,37,7],
[53,45,38,0],
[53,45,38,1],
[53,45,38,2],
[53,45,38,3],
[53,45,38,4],
[53,45,38,5],
[53,45,39,0],
[53,45,39,1],
[53,45,39,2],
[53,45,39,4],
[53,46,3,0],
[53,46,3,1],
[53,46,3,2],
[53,46,4,0],
[53,46,4,1],
[53,46,4,2],
[53,46,4,3],
[53,46,5,0],
[53,46,5,1],
[53,46,5,2],
[53,46,5,4],
[53,46,6,0],
[53,46,6,1],
[53,46,6,2],
[53,46,6,4],
[53,46,7,0],
[53,46,7,1],
[53,46,7,2],
[53,46,7,3],
[53,46,7,4],
[53,46,7,5],
[53,46,7,6],
[53,46,8,0],
[53,46,8,1],
[53,46,8,2],
[53,46,8,4],
[53,46,8,7],
[53,46,11,0],
[53,46,11,1],
[53,46,11,2],
[53,46,11,3],
[53,46,11,4],
[53,46,11,5],
[53,46,11,6],
[53,46,11,7],
[53,46,11,8],
[53,46,12,0],
[53,46,12,1],
[53,46,12,2],
[53,46,12,3],
[53,46,12,4],
[53,46,12,5],
[53,46,12,6],
[53,46,12,7],
[53,46,12,8],
[53,46,13,0],
[53,46,13,1],
[53,46,13,2],
[53,46,13,3],
[53,46,13,4],
[53,46,13,5],
[53,46,13,6],
[53,46,13,7],
[53,46,13,8],
[53,46,13,11],
[53,46,13,12],
[53,46,14,0],
[53,46,14,1],
[53,46,14,2],
[53,46,14,3],
[53,46,14,4],
[53,46,14,5],
[53,46,14,6],
[53,46,14,7],
[53,46,14,8],
[53,46,14,11],
[53,46,14,12],
[53,46,16,0],
[53,46,16,1],
[53,46,16,2],
[53,46,16,3],
[53,46,16,4],
[53,46,16,5],
[53,46,16,6],
[53,46,16,7],
[53,46,16,8],
[53,46,16,11],
[53,46,16,12],
[53,46,16,13],
[53,46,16,14],
[53,46,17,0],
[53,46,17,1],
[53,46,17,2],
[53,46,17,3],
[53,46,17,4],
[53,46,17,5],
[53,46,17,6],
[53,46,17,7],
[53,46,17,8],
[53,46,17,11],
[53,46,17,12],
[53,46,17,13],
[53,46,17,14],
[53,46,17,16],
[53,46,19,0],
[53,46,19,1],
[53,46,19,2],
[53,46,19,3],
[53,46,19,4],
[53,46,19,5],
[53,46,19,6],
[53,46,19,7],
[53,46,19,8],
[53,46,19,11],
[53,46,19,12],
[53,46,19,16],
[53,46,19,17],
[53,46,21,0],
[53,46,21,1],
[53,46,21,2],
[53,46,21,4],
[53,46,21,7],
[53,46,21,11],
[53,46,21,14],
[53,46,21,16],
[53,46,21,17],
[53,46,21,19],
[53,46,23,0],
[53,46,23,1],
[53,46,23,2],
[53,46,23,3],
[53,46,23,4],
[53,46,23,5],
[53,46,23,6],
[53,46,23,7],
[53,46,23,11],
[53,46,23,12],
[53,46,23,13],
[53,46,23,14],
[53,46,23,16],
[53,46,23,17],
[53,46,23,19],
[53,46,23,21],
[53,46,24,0],
[53,46,24,1],
[53,46,24,2],
[53,46,24,3],
[53,46,24,4],
[53,46,24,5],
[53,46,24,6],
[53,46,24,7],
[53,46,24,11],
[53,46,24,12],
[53,46,24,13],
[53,46,24,14],
[53,46,24,17],
[53,46,24,19],
[53,46,24,21],
[53,46,24,23],
[53,46,25,0],
[53,46,25,1],
[53,46,25,2],
[53,46,25,3],
[53,46,25,4],
[53,46,25,6],
[53,46,25,8],
[53,46,25,11],
[53,46,25,12],
[53,46,25,13],
[53,46,25,14],
[53,46,25,16],
[53,46,25,17],
[53,46,25,19],
[53,46,25,21],
[53,46,28,0],
[53,46,28,1],
[53,46,28,2],
[53,46,28,3],
[53,46,28,4],
[53,46,28,5],
[53,46,28,6],
[53,46,28,8],
[53,46,28,11],
[53,46,28,12],
[53,46,28,13],
[53,46,28,14],
[53,46,28,16],
[53,46,28,17],
[53,46,28,19],
[53,46,29,0],
[53,46,29,1],
[53,46,29,2],
[53,46,29,3],
[53,46,29,4],
[53,46,29,5],
[53,46,29,6],
[53,46,29,7],
[53,46,29,8],
[53,46,29,13],
[53,46,29,14],
[53,46,29,16],
[53,46,29,17],
[53,46,30,0],
[53,46,30,1],
[53,46,30,2],
[53,46,30,3],
[53,46,30,4],
[53,46,30,5],
[53,46,30,6],
[53,46,30,7],
[53,46,30,8],
[53,46,30,11],
[53,46,30,12],
[53,46,30,13],
[53,46,30,14],
[53,46,30,16],
[53,46,30,17],
[53,46,31,0],
[53,46,31,1],
[53,46,31,2],
[53,46,31,3],
[53,46,31,4],
[53,46,31,5],
[53,46,31,6],
[53,46,31,7],
[53,46,31,8],
[53,46,31,11],
[53,46,31,12],
[53,46,31,13],
[53,46,31,14],
[53,46,32,0],
[53,46,32,1],
[53,46,32,2],
[53,46,32,3],
[53,46,32,4],
[53,46,32,5],
[53,46,32,6],
[53,46,32,7],
[53,46,32,8],
[53,46,32,13],
[53,46,32,14],
[53,46,33,0],
[53,46,33,1],
[53,46,33,2],
[53,46,33,3],
[53,46,33,4],
[53,46,33,5],
[53,46,33,6],
[53,46,33,7],
[53,46,33,8],
[53,46,33,13],
[53,46,34,0],
[53,46,34,1],
[53,46,34,2],
[53,46,34,3],
[53,46,34,4],
[53,46,34,5],
[53,46,34,6],
[53,46,34,8],
[53,46,34,11],
[53,46,36,0],
[53,46,36,1],
[53,46,36,2],
[53,46,36,3],
[53,46,36,4],
[53,46,36,5],
[53,46,36,6],
[53,46,36,7],
[53,46,37,0],
[53,46,37,1],
[53,46,37,2],
[53,46,37,3],
[53,46,37,5],
[53,46,38,0],
[53,46,38,1],
[53,46,38,2],
[53,46,38,3],
[53,46,38,4],
[53,46,39,0],
[53,46,39,1],
[53,46,39,2],
[53,47,3,0],
[53,47,3,1],
[53,47,3,2],
[53,47,4,0],
[53,47,4,1],
[53,47,4,2],
[53,47,4,3],
[53,47,5,0],
[53,47,5,1],
[53,47,5,2],
[53,47,5,4],
[53,47,6,0],
[53,47,6,1],
[53,47,6,2],
[53,47,6,4],
[53,47,7,0],
[53,47,7,1],
[53,47,7,2],
[53,47,7,3],
[53,47,7,4],
[53,47,7,5],
[53,47,7,6],
[53,47,8,0],
[53,47,8,1],
[53,47,8,2],
[53,47,8,4],
[53,47,8,7],
[53,47,9,0],
[53,47,9,1],
[53,47,9,2],
[53,47,9,3],
[53,47,9,4],
[53,47,9,5],
[53,47,9,6],
[53,47,9,7],
[53,47,9,8],
[53,47,11,0],
[53,47,11,1],
[53,47,11,2],
[53,47,11,3],
[53,47,11,4],
[53,47,11,5],
[53,47,11,6],
[53,47,11,7],
[53,47,11,8],
[53,47,11,9],
[53,47,12,0],
[53,47,12,1],
[53,47,12,2],
[53,47,12,3],
[53,47,12,4],
[53,47,12,5],
[53,47,12,6],
[53,47,12,7],
[53,47,12,8],
[53,47,12,9],
[53,47,13,0],
[53,47,13,1],
[53,47,13,2],
[53,47,13,3],
[53,47,13,4],
[53,47,13,5],
[53,47,13,6],
[53,47,13,7],
[53,47,13,8],
[53,47,13,9],
[53,47,13,11],
[53,47,13,12],
[53,47,14,0],
[53,47,14,1],
[53,47,14,2],
[53,47,14,3],
[53,47,14,4],
[53,47,14,5],
[53,47,14,6],
[53,47,14,7],
[53,47,14,8],
[53,47,14,9],
[53,47,14,11],
[53,47,14,12],
[53,47,15,0],
[53,47,15,1],
[53,47,15,2],
[53,47,15,3],
[53,47,15,4],
[53,47,15,5],
[53,47,15,6],
[53,47,15,7],
[53,47,15,8],
[53,47,15,11],
[53,47,15,12],
[53,47,15,13],
[53,47,15,14],
[53,47,16,0],
[53,47,16,1],
[53,47,16,2],
[53,47,16,3],
[53,47,16,4],
[53,47,16,5],
[53,47,16,6],
[53,47,16,7],
[53,47,16,8],
[53,47,16,9],
[53,47,16,11],
[53,47,16,12],
[53,47,16,13],
[53,47,16,14],
[53,47,16,15],
[53,47,17,0],
[53,47,17,1],
[53,47,17,2],
[53,47,17,3],
[53,47,17,4],
[53,47,17,5],
[53,47,17,6],
[53,47,17,7],
[53,47,17,8],
[53,47,17,9],
[53,47,17,11],
[53,47,17,12],
[53,47,17,13],
[53,47,17,14],
[53,47,17,15],
[53,47,18,0],
[53,47,18,1],
[53,47,18,2],
[53,47,18,3],
[53,47,18,4],
[53,47,18,5],
[53,47,18,6],
[53,47,18,7],
[53,47,18,8],
[53,47,18,11],
[53,47,18,12],
[53,47,18,13],
[53,47,18,14],
[53,47,18,16],
[53,47,18,17],
[53,47,19,0],
[53,47,19,1],
[53,47,19,2],
[53,47,19,3],
[53,47,19,4],
[53,47,19,5],
[53,47,19,6],
[53,47,19,7],
[53,47,19,8],
[53,47,19,9],
[53,47,19,11],
[53,47,19,12],
[53,47,19,15],
[53,47,19,16],
[53,47,19,17],
[53,47,19,18],
[53,47,21,0],
[53,47,21,1],
[53,47,21,2],
[53,47,21,4],
[53,47,21,7],
[53,47,21,9],
[53,47,21,12],
[53,47,21,13],
[53,47,21,14],
[53,47,21,15],
[53,47,21,16],
[53,47,21,17],
[53,47,21,18],
[53,47,21,19],
[53,47,24,0],
[53,47,24,1],
[53,47,24,2],
[53,47,24,3],
[53,47,24,4],
[53,47,24,5],
[53,47,24,8],
[53,47,24,9],
[53,47,24,11],
[53,47,24,12],
[53,47,24,13],
[53,47,24,14],
[53,47,24,15],
[53,47,24,17],
[53,47,24,18],
[53,47,24,19],
[53,47,24,21],
[53,47,25,0],
[53,47,25,1],
[53,47,25,2],
[53,47,25,5],
[53,47,25,6],
[53,47,25,8],
[53,47,25,9],
[53,47,25,11],
[53,47,25,12],
[53,47,25,13],
[53,47,25,14],
[53,47,25,15],
[53,47,25,16],
[53,47,25,17],
[53,47,25,18],
[53,47,25,19],
[53,47,25,21],
[53,47,26,3],
[53,47,26,4],
[53,47,26,5],
[53,47,26,6],
[53,47,26,7],
[53,47,26,8],
[53,47,26,11],
[53,47,26,12],
[53,47,26,13],
[53,47,26,14],
[53,47,26,16],
[53,47,26,17],
[53,47,26,19],
[53,47,28,0],
[53,47,28,1],
[53,47,28,2],
[53,47,28,3],
[53,47,28,4],
[53,47,28,5],
[53,47,28,6],
[53,47,28,8],
[53,47,28,9],
[53,47,28,11],
[53,47,28,12],
[53,47,28,13],
[53,47,28,14],
[53,47,28,15],
[53,47,28,16],
[53,47,28,17],
[53,47,28,18],
[53,47,29,0],
[53,47,29,1],
[53,47,29,2],
[53,47,29,3],
[53,47,29,4],
[53,47,29,5],
[53,47,29,6],
[53,47,29,7],
[53,47,29,8],
[53,47,29,9],
[53,47,29,13],
[53,47,29,14],
[53,47,29,15],
[53,47,29,16],
[53,47,29,17],
[53,47,32,0],
[53,47,32,1],
[53,47,32,2],
[53,47,32,3],
[53,47,32,4],
[53,47,32,5],
[53,47,32,6],
[53,47,32,7],
[53,47,32,8],
[53,47,32,9],
[53,47,32,13],
[53,47,33,0],
[53,47,33,1],
[53,47,33,2],
[53,47,33,3],
[53,47,33,4],
[53,47,33,5],
[53,47,33,6],
[53,47,33,7],
[53,47,33,8],
[53,47,33,9],
[53,47,34,0],
[53,47,34,1],
[53,47,34,2],
[53,47,34,3],
[53,47,34,4],
[53,47,34,5],
[53,47,34,6],
[53,47,34,8],
[53,47,34,9],
[53,47,35,0],
[53,47,35,1],
[53,47,35,2],
[53,47,35,3],
[53,47,35,4],
[53,47,35,5],
[53,47,35,6],
[53,47,35,7],
[53,47,36,0],
[53,47,36,1],
[53,47,36,2],
[53,47,36,3],
[53,47,36,4],
[53,47,36,5],
[53,47,37,0],
[53,47,37,1],
[53,47,37,2],
[53,47,37,3],
[53,47,38,0],
[53,47,38,1],
[53,47,38,2],
[53,48,3,0],
[53,48,3,1],
[53,48,3,2],
[53,48,4,0],
[53,48,4,1],
[53,48,4,2],
[53,48,4,3],
[53,48,5,0],
[53,48,5,1],
[53,48,5,2],
[53,48,5,4],
[53,48,6,0],
[53,48,6,1],
[53,48,6,2],
[53,48,6,4],
[53,48,7,0],
[53,48,7,1],
[53,48,7,2],
[53,48,7,3],
[53,48,7,4],
[53,48,7,5],
[53,48,7,6],
[53,48,8,0],
[53,48,8,1],
[53,48,8,2],
[53,48,8,4],
[53,48,8,7],
[53,48,9,0],
[53,48,9,1],
[53,48,9,2],
[53,48,9,3],
[53,48,9,4],
[53,48,9,5],
[53,48,9,6],
[53,48,9,7],
[53,48,9,8],
[53,48,13,0],
[53,48,13,1],
[53,48,13,2],
[53,48,13,3],
[53,48,13,4],
[53,48,13,5],
[53,48,13,6],
[53,48,13,7],
[53,48,13,8],
[53,48,13,9],
[53,48,14,0],
[53,48,14,1],
[53,48,14,2],
[53,48,14,3],
[53,48,14,4],
[53,48,14,5],
[53,48,14,6],
[53,48,14,7],
[53,48,14,8],
[53,48,14,9],
[53,48,15,0],
[53,48,15,1],
[53,48,15,2],
[53,48,15,3],
[53,48,15,4],
[53,48,15,5],
[53,48,15,6],
[53,48,15,7],
[53,48,15,8],
[53,48,15,13],
[53,48,15,14],
[53,48,16,0],
[53,48,16,1],
[53,48,16,2],
[53,48,16,3],
[53,48,16,4],
[53,48,16,5],
[53,48,16,6],
[53,48,16,7],
[53,48,16,8],
[53,48,16,9],
[53,48,16,13],
[53,48,16,14],
[53,48,17,0],
[53,48,17,1],
[53,48,17,2],
[53,48,17,3],
[53,48,17,4],
[53,48,17,5],
[53,48,17,6],
[53,48,17,7],
[53,48,17,8],
[53,48,17,9],
[53,48,17,13],
[53,48,17,14],
[53,48,17,16],
[53,48,18,0],
[53,48,18,1],
[53,48,18,2],
[53,48,18,3],
[53,48,18,4],
[53,48,18,5],
[53,48,18,6],
[53,48,18,7],
[53,48,18,8],
[53,48,18,13],
[53,48,18,16],
[53,48,18,17],
[53,48,19,0],
[53,48,19,1],
[53,48,19,2],
[53,48,19,3],
[53,48,19,4],
[53,48,19,5],
[53,48,19,6],
[53,48,19,7],
[53,48,19,8],
[53,48,19,9],
[53,48,19,15],
[53,48,19,16],
[53,48,19,17],
[53,48,19,18],
[53,48,21,0],
[53,48,21,1],
[53,48,21,2],
[53,48,21,4],
[53,48,21,7],
[53,48,21,13],
[53,48,21,14],
[53,48,21,15],
[53,48,21,16],
[53,48,21,17],
[53,48,21,18],
[53,48,21,19],
[53,48,23,0],
[53,48,23,1],
[53,48,23,2],
[53,48,23,3],
[53,48,23,4],
[53,48,23,6],
[53,48,23,7],
[53,48,23,8],
[53,48,23,9],
[53,48,23,13],
[53,48,23,14],
[53,48,23,15],
[53,48,23,16],
[53,48,23,17],
[53,48,23,18],
[53,48,23,19],
[53,48,23,21],
[53,48,24,0],
[53,48,24,1],
[53,48,24,2],
[53,48,24,3],
[53,48,24,4],
[53,48,24,6],
[53,48,24,7],
[53,48,24,8],
[53,48,24,9],
[53,48,24,13],
[53,48,24,14],
[53,48,24,15],
[53,48,24,17],
[53,48,24,18],
[53,48,24,19],
[53,48,24,21],
[53,48,25,3],
[53,48,25,4],
[53,48,25,5],
[53,48,25,6],
[53,48,25,8],
[53,48,25,9],
[53,48,25,13],
[53,48,25,14],
[53,48,25,15],
[53,48,25,16],
[53,48,25,17],
[53,48,25,18],
[53,48,25,19],
[53,48,26,0],
[53,48,26,1],
[53,48,26,2],
[53,48,26,3],
[53,48,26,4],
[53,48,26,5],
[53,48,26,6],
[53,48,26,7],
[53,48,26,8],
[53,48,26,13],
[53,48,26,14],
[53,48,26,16],
[53,48,26,17],
[53,48,26,19],
[53,48,28,0],
[53,48,28,1],
[53,48,28,2],
[53,48,28,3],
[53,48,28,4],
[53,48,28,5],
[53,48,28,6],
[53,48,28,8],
[53,48,28,9],
[53,48,28,13],
[53,48,28,14],
[53,48,28,15],
[53,48,28,16],
[53,48,28,17],
[53,48,30,0],
[53,48,30,1],
[53,48,30,2],
[53,48,30,3],
[53,48,30,4],
[53,48,30,5],
[53,48,30,6],
[53,48,30,7],
[53,48,30,8],
[53,48,30,9],
[53,48,30,13],
[53,48,30,14],
[53,48,31,0],
[53,48,31,1],
[53,48,31,2],
[53,48,31,3],
[53,48,31,4],
[53,48,31,5],
[53,48,31,6],
[53,48,31,7],
[53,48,31,8],
[53,48,31,9],
[53,48,31,13],
[53,48,34,0],
[53,48,34,1],
[53,48,34,2],
[53,48,34,3],
[53,48,34,4],
[53,48,34,5],
[53,48,34,6],
[53,48,35,0],
[53,48,35,1],
[53,48,35,2],
[53,48,35,3],
[53,48,35,4],
[53,48,35,5],
[53,48,36,0],
[53,48,36,1],
[53,48,36,2],
[53,48,36,3],
[53,48,36,4],
[53,48,37,0],
[53,48,37,1],
[53,48,37,2],
[53,49,3,0],
[53,49,3,1],
[53,49,3,2],
[53,49,4,0],
[53,49,4,1],
[53,49,4,2],
[53,49,4,3],
[53,49,5,0],
[53,49,5,1],
[53,49,5,2],
[53,49,5,4],
[53,49,6,0],
[53,49,6,1],
[53,49,6,2],
[53,49,6,4],
[53,49,7,0],
[53,49,7,1],
[53,49,7,2],
[53,49,7,3],
[53,49,7,4],
[53,49,7,5],
[53,49,7,6],
[53,49,8,0],
[53,49,8,1],
[53,49,8,2],
[53,49,8,4],
[53,49,8,7],
[53,49,11,0],
[53,49,11,1],
[53,49,11,2],
[53,49,11,3],
[53,49,11,4],
[53,49,11,5],
[53,49,11,6],
[53,49,11,7],
[53,49,11,8],
[53,49,12,0],
[53,49,12,1],
[53,49,12,2],
[53,49,12,3],
[53,49,12,4],
[53,49,12,5],
[53,49,12,6],
[53,49,12,7],
[53,49,12,8],
[53,49,13,0],
[53,49,13,1],
[53,49,13,2],
[53,49,13,3],
[53,49,13,4],
[53,49,13,5],
[53,49,13,6],
[53,49,13,7],
[53,49,13,8],
[53,49,13,11],
[53,49,13,12],
[53,49,14,0],
[53,49,14,1],
[53,49,14,2],
[53,49,14,3],
[53,49,14,4],
[53,49,14,5],
[53,49,14,6],
[53,49,14,7],
[53,49,14,8],
[53,49,14,11],
[53,49,14,12],
[53,49,16,0],
[53,49,16,1],
[53,49,16,2],
[53,49,16,3],
[53,49,16,4],
[53,49,16,5],
[53,49,16,6],
[53,49,16,7],
[53,49,16,8],
[53,49,16,11],
[53,49,16,12],
[53,49,16,13],
[53,49,16,14],
[53,49,17,0],
[53,49,17,1],
[53,49,17,2],
[53,49,17,3],
[53,49,17,4],
[53,49,17,5],
[53,49,17,6],
[53,49,17,7],
[53,49,17,8],
[53,49,17,11],
[53,49,17,12],
[53,49,17,13],
[53,49,17,14],
[53,49,17,16],
[53,49,19,0],
[53,49,19,1],
[53,49,19,2],
[53,49,19,3],
[53,49,19,4],
[53,49,19,5],
[53,49,19,6],
[53,49,19,7],
[53,49,19,8],
[53,49,19,11],
[53,49,19,16],
[53,49,19,17],
[53,49,21,0],
[53,49,21,1],
[53,49,21,2],
[53,49,21,4],
[53,49,21,7],
[53,49,21,11],
[53,49,21,12],
[53,49,21,13],
[53,49,21,14],
[53,49,21,16],
[53,49,21,17],
[53,49,21,19],
[53,49,23,0],
[53,49,23,1],
[53,49,23,2],
[53,49,23,3],
[53,49,23,4],
[53,49,23,6],
[53,49,23,7],
[53,49,23,8],
[53,49,23,11],
[53,49,23,12],
[53,49,23,13],
[53,49,23,14],
[53,49,23,16],
[53,49,23,17],
[53,49,23,19],
[53,49,23,21],
[53,49,24,0],
[53,49,24,1],
[53,49,24,2],
[53,49,24,3],
[53,49,24,4],
[53,49,24,6],
[53,49,24,7],
[53,49,24,8],
[53,49,24,11],
[53,49,24,12],
[53,49,24,13],
[53,49,24,14],
[53,49,24,17],
[53,49,24,19],
[53,49,24,21],
[53,49,25,3],
[53,49,25,4],
[53,49,25,5],
[53,49,25,6],
[53,49,25,8],
[53,49,25,11],
[53,49,25,12],
[53,49,25,13],
[53,49,25,14],
[53,49,25,16],
[53,49,25,17],
[53,49,25,19],
[53,49,28,0],
[53,49,28,1],
[53,49,28,2],
[53,49,28,3],
[53,49,28,4],
[53,49,28,5],
[53,49,28,6],
[53,49,28,8],
[53,49,28,11],
[53,49,28,12],
[53,49,28,13],
[53,49,28,14],
[53,49,28,16],
[53,49,28,17],
[53,49,29,0],
[53,49,29,1],
[53,49,29,2],
[53,49,29,3],
[53,49,29,4],
[53,49,29,5],
[53,49,29,6],
[53,49,29,7],
[53,49,29,8],
[53,49,29,13],
[53,49,29,14],
[53,49,30,0],
[53,49,30,1],
[53,49,30,2],
[53,49,30,3],
[53,49,30,4],
[53,49,30,5],
[53,49,30,6],
[53,49,30,7],
[53,49,30,8],
[53,49,30,11],
[53,49,30,12],
[53,49,30,13],
[53,49,30,14],
[53,49,31,0],
[53,49,31,1],
[53,49,31,2],
[53,49,31,3],
[53,49,31,4],
[53,49,31,5],
[53,49,31,6],
[53,49,31,7],
[53,49,31,8],
[53,49,31,11],
[53,49,31,12],
[53,49,31,13],
[53,49,32,0],
[53,49,32,1],
[53,49,32,2],
[53,49,32,3],
[53,49,32,4],
[53,49,32,5],
[53,49,32,6],
[53,49,32,7],
[53,49,32,8],
[53,49,33,0],
[53,49,33,1],
[53,49,33,2],
[53,49,33,3],
[53,49,33,4],
[53,49,33,5],
[53,49,33,6],
[53,49,33,7],
[53,49,33,8],
[53,49,34,0],
[53,49,34,1],
[53,49,34,2],
[53,49,34,3],
[53,49,34,4],
[53,49,34,5],
[53,49,34,6],
[53,49,36,0],
[53,49,36,1],
[53,49,36,2],
[53,49,36,3],
[53,49,36,4],
[53,49,37,0],
[53,49,37,1],
[53,49,37,2],
[53,50,3,0],
[53,50,3,1],
[53,50,3,2],
[53,50,4,0],
[53,50,4,1],
[53,50,4,2],
[53,50,4,3],
[53,50,5,0],
[53,50,5,1],
[53,50,5,2],
[53,50,5,4],
[53,50,6,0],
[53,50,6,1],
[53,50,6,2],
[53,50,6,4],
[53,50,7,0],
[53,50,7,1],
[53,50,7,2],
[53,50,7,3],
[53,50,7,4],
[53,50,7,5],
[53,50,7,6],
[53,50,8,0],
[53,50,8,1],
[53,50,8,2],
[53,50,8,4],
[53,50,8,7],
[53,50,11,0],
[53,50,11,1],
[53,50,11,2],
[53,50,11,3],
[53,50,11,4],
[53,50,11,5],
[53,50,11,6],
[53,50,11,7],
[53,50,11,8],
[53,50,12,0],
[53,50,12,1],
[53,50,12,2],
[53,50,12,3],
[53,50,12,4],
[53,50,12,5],
[53,50,12,6],
[53,50,12,7],
[53,50,12,8],
[53,50,13,0],
[53,50,13,1],
[53,50,13,2],
[53,50,13,3],
[53,50,13,4],
[53,50,13,5],
[53,50,13,6],
[53,50,13,7],
[53,50,13,8],
[53,50,13,11],
[53,50,13,12],
[53,50,14,0],
[53,50,14,1],
[53,50,14,2],
[53,50,14,3],
[53,50,14,4],
[53,50,14,5],
[53,50,14,6],
[53,50,14,7],
[53,50,14,8],
[53,50,14,11],
[53,50,14,12],
[53,50,16,0],
[53,50,16,1],
[53,50,16,2],
[53,50,16,3],
[53,50,16,4],
[53,50,16,5],
[53,50,16,6],
[53,50,16,7],
[53,50,16,8],
[53,50,16,11],
[53,50,16,12],
[53,50,16,13],
[53,50,16,14],
[53,50,17,0],
[53,50,17,1],
[53,50,17,2],
[53,50,17,3],
[53,50,17,4],
[53,50,17,5],
[53,50,17,6],
[53,50,17,7],
[53,50,17,8],
[53,50,17,11],
[53,50,17,12],
[53,50,17,13],
[53,50,17,14],
[53,50,17,16],
[53,50,19,0],
[53,50,19,1],
[53,50,19,2],
[53,50,19,3],
[53,50,19,4],
[53,50,19,5],
[53,50,19,6],
[53,50,19,7],
[53,50,19,8],
[53,50,19,11],
[53,50,19,16],
[53,50,19,17],
[53,50,21,0],
[53,50,21,1],
[53,50,21,2],
[53,50,21,4],
[53,50,21,7],
[53,50,21,11],
[53,50,21,12],
[53,50,21,13],
[53,50,21,14],
[53,50,21,16],
[53,50,21,17],
[53,50,21,19],
[53,50,23,0],
[53,50,23,1],
[53,50,23,2],
[53,50,23,3],
[53,50,23,4],
[53,50,23,6],
[53,50,23,7],
[53,50,23,8],
[53,50,23,11],
[53,50,23,12],
[53,50,23,13],
[53,50,23,14],
[53,50,23,16],
[53,50,23,17],
[53,50,23,19],
[53,50,23,21],
[53,50,24,0],
[53,50,24,1],
[53,50,24,2],
[53,50,24,3],
[53,50,24,4],
[53,50,24,6],
[53,50,24,7],
[53,50,24,8],
[53,50,24,11],
[53,50,24,12],
[53,50,24,13],
[53,50,24,14],
[53,50,24,17],
[53,50,24,19],
[53,50,24,21],
[53,50,25,3],
[53,50,25,4],
[53,50,25,5],
[53,50,25,6],
[53,50,25,8],
[53,50,25,11],
[53,50,25,12],
[53,50,25,13],
[53,50,25,14],
[53,50,25,16],
[53,50,25,17],
[53,50,25,19],
[53,50,28,0],
[53,50,28,1],
[53,50,28,2],
[53,50,28,3],
[53,50,28,4],
[53,50,28,5],
[53,50,28,6],
[53,50,28,8],
[53,50,28,11],
[53,50,28,12],
[53,50,28,13],
[53,50,28,14],
[53,50,28,16],
[53,50,28,17],
[53,50,29,0],
[53,50,29,1],
[53,50,29,2],
[53,50,29,3],
[53,50,29,4],
[53,50,29,5],
[53,50,29,6],
[53,50,29,7],
[53,50,29,8],
[53,50,29,13],
[53,50,29,14],
[53,50,30,0],
[53,50,30,1],
[53,50,30,2],
[53,50,30,3],
[53,50,30,4],
[53,50,30,5],
[53,50,30,6],
[53,50,30,7],
[53,50,30,8],
[53,50,30,11],
[53,50,30,12],
[53,50,30,13],
[53,50,30,14],
[53,50,31,0],
[53,50,31,1],
[53,50,31,2],
[53,50,31,3],
[53,50,31,4],
[53,50,31,5],
[53,50,31,6],
[53,50,31,7],
[53,50,31,8],
[53,50,31,11],
[53,50,31,12],
[53,50,31,13],
[53,50,32,0],
[53,50,32,1],
[53,50,32,2],
[53,50,32,3],
[53,50,32,4],
[53,50,32,5],
[53,50,32,6],
[53,50,32,7],
[53,50,32,8],
[53,50,33,0],
[53,50,33,1],
[53,50,33,2],
[53,50,33,3],
[53,50,33,4],
[53,50,33,5],
[53,50,33,6],
[53,50,33,7],
[53,50,33,8],
[53,50,34,0],
[53,50,34,1],
[53,50,34,2],
[53,50,34,3],
[53,50,34,4],
[53,50,34,5],
[53,50,34,6],
[53,50,36,0],
[53,50,36,1],
[53,50,36,2],
[53,50,36,3],
[53,50,36,4],
[53,50,37,0],
[53,50,37,1],
[53,50,37,2],
[53,51,4,0],
[53,51,4,1],
[53,51,4,2],
[53,51,7,0],
[53,51,7,1],
[53,51,7,2],
[53,51,7,4],
[53,51,9,0],
[53,51,9,1],
[53,51,9,2],
[53,51,9,4],
[53,51,9,7],
[53,51,11,0],
[53,51,11,1],
[53,51,11,2],
[53,51,11,4],
[53,51,11,7],
[53,51,11,9],
[53,51,12,0],
[53,51,12,1],
[53,51,12,2],
[53,51,12,4],
[53,51,12,7],
[53,51,12,9],
[53,51,13,0],
[53,51,13,1],
[53,51,13,2],
[53,51,13,4],
[53,51,13,7],
[53,51,13,9],
[53,51,13,11],
[53,51,13,12],
[53,51,14,0],
[53,51,14,1],
[53,51,14,2],
[53,51,14,4],
[53,51,14,7],
[53,51,14,9],
[53,51,14,11],
[53,51,14,12],
[53,51,15,0],
[53,51,15,1],
[53,51,15,2],
[53,51,15,4],
[53,51,15,7],
[53,51,15,11],
[53,51,15,12],
[53,51,15,13],
[53,51,15,14],
[53,51,16,0],
[53,51,16,1],
[53,51,16,2],
[53,51,16,4],
[53,51,16,7],
[53,51,16,9],
[53,51,16,11],
[53,51,16,12],
[53,51,16,13],
[53,51,16,14],
[53,51,17,0],
[53,51,17,1],
[53,51,17,2],
[53,51,17,4],
[53,51,17,7],
[53,51,17,9],
[53,51,17,11],
[53,51,17,12],
[53,51,17,13],
[53,51,17,14],
[53,51,17,16],
[53,51,18,0],
[53,51,18,1],
[53,51,18,2],
[53,51,18,4],
[53,51,18,7],
[53,51,18,11],
[53,51,18,12],
[53,51,18,13],
[53,51,18,16],
[53,51,18,17],
[53,51,19,0],
[53,51,19,1],
[53,51,19,2],
[53,51,19,4],
[53,51,19,7],
[53,51,19,9],
[53,51,19,11],
[53,51,19,15],
[53,51,19,16],
[53,51,19,17],
[53,51,19,18],
[53,51,23,0],
[53,51,23,1],
[53,51,23,2],
[53,51,23,4],
[53,51,23,7],
[53,51,23,9],
[53,51,23,11],
[53,51,23,12],
[53,51,23,13],
[53,51,23,14],
[53,51,23,15],
[53,51,23,16],
[53,51,23,17],
[53,51,23,18],
[53,51,23,19],
[53,51,24,0],
[53,51,24,1],
[53,51,24,2],
[53,51,24,4],
[53,51,24,7],
[53,51,24,9],
[53,51,24,11],
[53,51,24,12],
[53,51,24,13],
[53,51,24,14],
[53,51,24,15],
[53,51,24,17],
[53,51,24,18],
[53,51,24,19],
[53,51,25,4],
[53,51,25,9],
[53,51,25,11],
[53,51,25,12],
[53,51,25,13],
[53,51,25,14],
[53,51,25,15],
[53,51,25,16],
[53,51,25,17],
[53,51,25,18],
[53,51,25,19],
[53,51,26,0],
[53,51,26,1],
[53,51,26,2],
[53,51,26,4],
[53,51,26,7],
[53,51,26,11],
[53,51,26,12],
[53,51,26,13],
[53,51,26,14],
[53,51,26,16],
[53,51,26,17],
[53,51,26,19],
[53,51,28,0],
[53,51,28,1],
[53,51,28,2],
[53,51,28,4],
[53,51,28,9],
[53,51,28,11],
[53,51,28,12],
[53,51,28,13],
[53,51,28,14],
[53,51,28,15],
[53,51,28,16],
[53,51,28,17],
[53,51,29,0],
[53,51,29,1],
[53,51,29,2],
[53,51,29,4],
[53,51,29,7],
[53,51,29,9],
[53,51,29,13],
[53,51,29,14],
[53,51,29,15],
[53,51,30,0],
[53,51,30,1],
[53,51,30,2],
[53,51,30,4],
[53,51,30,7],
[53,51,30,9],
[53,51,30,11],
[53,51,30,12],
[53,51,30,13],
[53,51,30,14],
[53,51,31,0],
[53,51,31,1],
[53,51,31,2],
[53,51,31,4],
[53,51,31,7],
[53,51,31,9],
[53,51,31,11],
[53,51,31,12],
[53,51,31,13],
[53,51,32,0],
[53,51,32,1],
[53,51,32,2],
[53,51,32,4],
[53,51,32,7],
[53,51,32,9],
[53,51,33,0],
[53,51,33,1],
[53,51,33,2],
[53,51,33,4],
[53,51,33,7],
[53,51,33,9],
[53,51,34,0],
[53,51,34,1],
[53,51,34,2],
[53,51,34,4],
[53,51,35,0],
[53,51,35,1],
[53,51,35,2],
[53,51,35,4],
[53,51,36,0],
[53,51,36,1],
[53,51,36,2],
[53,51,36,4],
[53,51,37,0],
[53,51,37,1],
[53,51,37,2],
[53,52,3,0],
[53,52,3,1],
[53,52,3,2],
[53,52,4,0],
[53,52,4,1],
[53,52,4,2],
[53,52,4,3],
[53,52,5,0],
[53,52,5,1],
[53,52,5,2],
[53,52,5,4],
[53,52,6,0],
[53,52,6,1],
[53,52,6,2],
[53,52,6,4],
[53,52,7,0],
[53,52,7,1],
[53,52,7,2],
[53,52,7,3],
[53,52,7,4],
[53,52,7,5],
[53,52,7,6],
[53,52,8,0],
[53,52,8,1],
[53,52,8,2],
[53,52,8,4],
[53,52,8,7],
[53,52,11,0],
[53,52,11,1],
[53,52,11,2],
[53,52,11,3],
[53,52,11,4],
[53,52,11,5],
[53,52,11,6],
[53,52,11,7],
[53,52,11,8],
[53,52,12,0],
[53,52,12,1],
[53,52,12,2],
[53,52,12,3],
[53,52,12,4],
[53,52,12,5],
[53,52,12,6],
[53,52,12,7],
[53,52,12,8],
[53,52,13,0],
[53,52,13,1],
[53,52,13,2],
[53,52,13,3],
[53,52,13,4],
[53,52,13,5],
[53,52,13,6],
[53,52,13,7],
[53,52,13,8],
[53,52,13,11],
[53,52,13,12],
[53,52,14,0],
[53,52,14,1],
[53,52,14,2],
[53,52,14,3],
[53,52,14,4],
[53,52,14,5],
[53,52,14,6],
[53,52,14,7],
[53,52,14,8],
[53,52,14,11],
[53,52,14,12],
[53,52,16,0],
[53,52,16,1],
[53,52,16,2],
[53,52,16,3],
[53,52,16,4],
[53,52,16,5],
[53,52,16,6],
[53,52,16,7],
[53,52,16,8],
[53,52,16,11],
[53,52,16,12],
[53,52,16,13],
[53,52,16,14],
[53,52,17,0],
[53,52,17,1],
[53,52,17,2],
[53,52,17,3],
[53,52,17,4],
[53,52,17,5],
[53,52,17,6],
[53,52,17,7],
[53,52,17,8],
[53,52,17,11],
[53,52,17,12],
[53,52,17,13],
[53,52,17,14],
[53,52,17,16],
[53,52,19,0],
[53,52,19,1],
[53,52,19,2],
[53,52,19,3],
[53,52,19,4],
[53,52,19,5],
[53,52,19,6],
[53,52,19,7],
[53,52,19,8],
[53,52,19,11],
[53,52,19,16],
[53,52,19,17],
[53,52,21,0],
[53,52,21,1],
[53,52,21,2],
[53,52,21,4],
[53,52,21,7],
[53,52,21,11],
[53,52,21,12],
[53,52,21,13],
[53,52,21,14],
[53,52,21,16],
[53,52,21,17],
[53,52,21,19],
[53,52,23,0],
[53,52,23,1],
[53,52,23,2],
[53,52,23,3],
[53,52,23,4],
[53,52,23,6],
[53,52,23,7],
[53,52,23,8],
[53,52,23,11],
[53,52,23,12],
[53,52,23,13],
[53,52,23,14],
[53,52,23,16],
[53,52,23,17],
[53,52,23,19],
[53,52,23,21],
[53,52,24,0],
[53,52,24,1],
[53,52,24,2],
[53,52,24,3],
[53,52,24,4],
[53,52,24,6],
[53,52,24,7],
[53,52,24,8],
[53,52,24,11],
[53,52,24,12],
[53,52,24,13],
[53,52,24,14],
[53,52,24,17],
[53,52,24,19],
[53,52,24,21],
[53,52,25,3],
[53,52,25,4],
[53,52,25,5],
[53,52,25,6],
[53,52,25,8],
[53,52,25,11],
[53,52,25,12],
[53,52,25,13],
[53,52,25,14],
[53,52,25,16],
[53,52,25,17],
[53,52,25,19],
[53,52,28,0],
[53,52,28,1],
[53,52,28,2],
[53,52,28,3],
[53,52,28,4],
[53,52,28,5],
[53,52,28,6],
[53,52,28,8],
[53,52,28,11],
[53,52,28,12],
[53,52,28,13],
[53,52,28,14],
[53,52,28,16],
[53,52,28,17],
[53,52,29,0],
[53,52,29,1],
[53,52,29,2],
[53,52,29,3],
[53,52,29,4],
[53,52,29,5],
[53,52,29,6],
[53,52,29,7],
[53,52,29,8],
[53,52,29,13],
[53,52,29,14],
[53,52,30,0],
[53,52,30,1],
[53,52,30,2],
[53,52,30,3],
[53,52,30,4],
[53,52,30,5],
[53,52,30,6],
[53,52,30,7],
[53,52,30,8],
[53,52,30,11],
[53,52,30,12],
[53,52,30,13],
[53,52,30,14],
[53,52,31,0],
[53,52,31,1],
[53,52,31,2],
[53,52,31,3],
[53,52,31,4],
[53,52,31,5],
[53,52,31,6],
[53,52,31,7],
[53,52,31,8],
[53,52,31,11],
[53,52,31,12],
[53,52,31,13],
[53,52,32,0],
[53,52,32,1],
[53,52,32,2],
[53,52,32,3],
[53,52,32,4],
[53,52,32,5],
[53,52,32,6],
[53,52,32,7],
[53,52,32,8],
[53,52,33,0],
[53,52,33,1],
[53,52,33,2],
[53,52,33,3],
[53,52,33,4],
[53,52,33,5],
[53,52,33,6],
[53,52,33,7],
[53,52,33,8],
[53,52,34,0],
[53,52,34,1],
[53,52,34,2],
[53,52,34,3],
[53,52,34,4],
[53,52,34,5],
[53,52,34,6],
[53,52,36,0],
[53,52,36,1],
[53,52,36,2],
[53,52,36,3],
[53,52,36,4],
[53,52,37,0],
[53,52,37,1],
[53,52,37,2],
[54,4,3,0],
[54,4,3,1],
[54,4,3,2],
[54,5,4,0],
[54,5,4,1],
[54,5,4,2],
[54,6,4,0],
[54,6,4,1],
[54,6,4,2],
[54,7,3,0],
[54,7,3,1],
[54,7,3,2],
[54,7,4,0],
[54,7,4,1],
[54,7,4,2],
[54,7,4,3],
[54,7,5,0],
[54,7,5,1],
[54,7,5,2],
[54,7,5,4],
[54,7,6,0],
[54,7,6,1],
[54,7,6,2],
[54,7,6,4],
[54,8,4,0],
[54,8,4,1],
[54,8,4,2],
[54,8,7,0],
[54,8,7,1],
[54,8,7,2],
[54,8,7,4],
[54,9,3,0],
[54,9,3,1],
[54,9,3,2],
[54,9,4,0],
[54,9,4,1],
[54,9,4,2],
[54,9,4,3],
[54,9,5,0],
[54,9,5,1],
[54,9,5,2],
[54,9,5,4],
[54,9,6,0],
[54,9,6,1],
[54,9,6,2],
[54,9,6,4],
[54,9,7,0],
[54,9,7,1],
[54,9,7,2],
[54,9,7,3],
[54,9,7,4],
[54,9,7,5],
[54,9,7,6],
[54,9,8,0],
[54,9,8,1],
[54,9,8,2],
[54,9,8,4],
[54,9,8,7],
[54,11,3,0],
[54,11,3,1],
[54,11,3,2],
[54,11,4,0],
[54,11,4,1],
[54,11,4,2],
[54,11,4,3],
[54,11,5,0],
[54,11,5,1],
[54,11,5,2],
[54,11,5,4],
[54,11,6,0],
[54,11,6,1],
[54,11,6,2],
[54,11,6,4],
[54,11,7,0],
[54,11,7,1],
[54,11,7,2],
[54,11,7,3],
[54,11,7,4],
[54,11,7,5],
[54,11,7,6],
[54,11,8,0],
[54,11,8,1],
[54,11,8,2],
[54,11,8,4],
[54,11,8,7],
[54,11,9,0],
[54,11,9,1],
[54,11,9,2],
[54,11,9,3],
[54,11,9,4],
[54,11,9,5],
[54,11,9,6],
[54,11,9,7],
[54,11,9,8],
[54,12,3,0],
[54,12,3,1],
[54,12,3,2],
[54,12,4,0],
[54,12,4,1],
[54,12,4,2],
[54,12,4,3],
[54,12,5,0],
[54,12,5,1],
[54,12,5,2],
[54,12,5,4],
[54,12,6,0],
[54,12,6,1],
[54,12,6,2],
[54,12,6,4],
[54,12,7,0],
[54,12,7,1],
[54,12,7,2],
[54,12,7,3],
[54,12,7,4],
[54,12,7,5],
[54,12,7,6],
[54,12,8,0],
[54,12,8,1],
[54,12,8,2],
[54,12,8,4],
[54,12,8,7],
[54,12,9,0],
[54,12,9,1],
[54,12,9,2],
[54,12,9,3],
[54,12,9,4],
[54,12,9,5],
[54,12,9,6],
[54,12,9,7],
[54,12,9,8],
[54,13,3,0],
[54,13,3,1],
[54,13,3,2],
[54,13,4,0],
[54,13,4,1],
[54,13,4,2],
[54,13,4,3],
[54,13,5,0],
[54,13,5,1],
[54,13,5,2],
[54,13,5,4],
[54,13,6,0],
[54,13,6,1],
[54,13,6,2],
[54,13,6,4],
[54,13,7,0],
[54,13,7,1],
[54,13,7,2],
[54,13,7,3],
[54,13,7,4],
[54,13,7,5],
[54,13,7,6],
[54,13,8,0],
[54,13,8,1],
[54,13,8,2],
[54,13,8,4],
[54,13,8,7],
[54,13,9,0],
[54,13,9,1],
[54,13,9,2],
[54,13,9,3],
[54,13,9,4],
[54,13,9,5],
[54,13,9,6],
[54,13,9,7],
[54,13,9,8],
[54,13,11,0],
[54,13,11,1],
[54,13,11,2],
[54,13,11,3],
[54,13,11,4],
[54,13,11,5],
[54,13,11,6],
[54,13,11,7],
[54,13,11,8],
[54,13,11,9],
[54,13,12,0],
[54,13,12,1],
[54,13,12,2],
[54,13,12,3],
[54,13,12,4],
[54,13,12,5],
[54,13,12,6],
[54,13,12,7],
[54,13,12,8],
[54,13,12,9],
[54,14,3,0],
[54,14,3,1],
[54,14,3,2],
[54,14,4,0],
[54,14,4,1],
[54,14,4,2],
[54,14,4,3],
[54,14,5,0],
[54,14,5,1],
[54,14,5,2],
[54,14,5,4],
[54,14,6,0],
[54,14,6,1],
[54,14,6,2],
[54,14,6,4],
[54,14,7,0],
[54,14,7,1],
[54,14,7,2],
[54,14,7,3],
[54,14,7,4],
[54,14,7,5],
[54,14,7,6],
[54,14,8,0],
[54,14,8,1],
[54,14,8,2],
[54,14,8,4],
[54,14,8,7],
[54,14,9,0],
[54,14,9,1],
[54,14,9,2],
[54,14,9,3],
[54,14,9,4],
[54,14,9,5],
[54,14,9,6],
[54,14,9,7],
[54,14,9,8],
[54,14,11,0],
[54,14,11,1],
[54,14,11,2],
[54,14,11,3],
[54,14,11,4],
[54,14,11,5],
[54,14,11,6],
[54,14,11,7],
[54,14,11,8],
[54,14,11,9],
[54,14,12,0],
[54,14,12,1],
[54,14,12,2],
[54,14,12,3],
[54,14,12,4],
[54,14,12,5],
[54,14,12,6],
[54,14,12,7],
[54,14,12,8],
[54,14,12,9],
[54,15,3,0],
[54,15,3,1],
[54,15,3,2],
[54,15,4,0],
[54,15,4,1],
[54,15,4,2],
[54,15,4,3],
[54,15,5,0],
[54,15,5,1],
[54,15,5,2],
[54,15,5,4],
[54,15,6,0],
[54,15,6,1],
[54,15,6,2],
[54,15,6,4],
[54,15,7,0],
[54,15,7,1],
[54,15,7,2],
[54,15,7,3],
[54,15,7,4],
[54,15,7,5],
[54,15,7,6],
[54,15,8,0],
[54,15,8,1],
[54,15,8,2],
[54,15,8,4],
[54,15,8,7],
[54,15,11,0],
[54,15,11,1],
[54,15,11,2],
[54,15,11,3],
[54,15,11,4],
[54,15,11,5],
[54,15,11,6],
[54,15,11,7],
[54,15,11,8],
[54,15,12,0],
[54,15,12,1],
[54,15,12,2],
[54,15,12,3],
[54,15,12,4],
[54,15,12,5],
[54,15,12,6],
[54,15,12,7],
[54,15,12,8],
[54,15,13,0],
[54,15,13,1],
[54,15,13,2],
[54,15,13,3],
[54,15,13,4],
[54,15,13,5],
[54,15,13,6],
[54,15,13,7],
[54,15,13,8],
[54,15,13,11],
[54,15,13,12],
[54,15,14,0],
[54,15,14,1],
[54,15,14,2],
[54,15,14,3],
[54,15,14,4],
[54,15,14,5],
[54,15,14,6],
[54,15,14,7],
[54,15,14,8],
[54,15,14,11],
[54,15,14,12],
[54,16,3,0],
[54,16,3,1],
[54,16,3,2],
[54,16,4,0],
[54,16,4,1],
[54,16,4,2],
[54,16,4,3],
[54,16,5,0],
[54,16,5,1],
[54,16,5,2],
[54,16,5,4],
[54,16,6,0],
[54,16,6,1],
[54,16,6,2],
[54,16,6,4],
[54,16,7,0],
[54,16,7,1],
[54,16,7,2],
[54,16,7,3],
[54,16,7,4],
[54,16,7,5],
[54,16,7,6],
[54,16,8,0],
[54,16,8,1],
[54,16,8,2],
[54,16,8,4],
[54,16,8,7],
[54,16,9,0],
[54,16,9,1],
[54,16,9,2],
[54,16,9,3],
[54,16,9,4],
[54,16,9,5],
[54,16,9,6],
[54,16,9,7],
[54,16,9,8],
[54,16,11,0],
[54,16,11,1],
[54,16,11,2],
[54,16,11,3],
[54,16,11,4],
[54,16,11,5],
[54,16,11,6],
[54,16,11,7],
[54,16,11,8],
[54,16,11,9],
[54,16,12,0],
[54,16,12,1],
[54,16,12,2],
[54,16,12,3],
[54,16,12,4],
[54,16,12,5],
[54,16,12,6],
[54,16,12,7],
[54,16,12,8],
[54,16,12,9],
[54,16,13,0],
[54,16,13,1],
[54,16,13,2],
[54,16,13,3],
[54,16,13,4],
[54,16,13,5],
[54,16,13,6],
[54,16,13,7],
[54,16,13,8],
[54,16,13,9],
[54,16,13,11],
[54,16,13,12],
[54,16,14,0],
[54,16,14,1],
[54,16,14,2],
[54,16,14,3],
[54,16,14,4],
[54,16,14,5],
[54,16,14,6],
[54,16,14,7],
[54,16,14,8],
[54,16,14,9],
[54,16,14,11],
[54,16,14,12],
[54,16,15,0],
[54,16,15,1],
[54,16,15,2],
[54,16,15,3],
[54,16,15,4],
[54,16,15,5],
[54,16,15,6],
[54,16,15,7],
[54,16,15,8],
[54,16,15,11],
[54,16,15,12],
[54,16,15,13],
[54,16,15,14],
[54,17,3,0],
[54,17,3,1],
[54,17,3,2],
[54,17,4,0],
[54,17,4,1],
[54,17,4,2],
[54,17,4,3],
[54,17,5,0],
[54,17,5,1],
[54,17,5,2],
[54,17,5,4],
[54,17,6,0],
[54,17,6,1],
[54,17,6,2],
[54,17,6,4],
[54,17,7,0],
[54,17,7,1],
[54,17,7,2],
[54,17,7,3],
[54,17,7,4],
[54,17,7,5],
[54,17,7,6],
[54,17,8,0],
[54,17,8,1],
[54,17,8,2],
[54,17,8,4],
[54,17,8,7],
[54,17,9,0],
[54,17,9,1],
[54,17,9,2],
[54,17,9,3],
[54,17,9,4],
[54,17,9,5],
[54,17,9,6],
[54,17,9,7],
[54,17,9,8],
[54,17,11,0],
[54,17,11,1],
[54,17,11,2],
[54,17,11,3],
[54,17,11,4],
[54,17,11,5],
[54,17,11,6],
[54,17,11,7],
[54,17,11,8],
[54,17,11,9],
[54,17,12,0],
[54,17,12,1],
[54,17,12,2],
[54,17,12,3],
[54,17,12,4],
[54,17,12,5],
[54,17,12,6],
[54,17,12,7],
[54,17,12,8],
[54,17,12,9],
[54,17,13,0],
[54,17,13,1],
[54,17,13,2],
[54,17,13,3],
[54,17,13,4],
[54,17,13,5],
[54,17,13,6],
[54,17,13,7],
[54,17,13,8],
[54,17,13,9],
[54,17,13,11],
[54,17,13,12],
[54,17,14,0],
[54,17,14,1],
[54,17,14,2],
[54,17,14,3],
[54,17,14,4],
[54,17,14,5],
[54,17,14,6],
[54,17,14,7],
[54,17,14,8],
[54,17,14,9],
[54,17,14,11],
[54,17,14,12],
[54,17,15,0],
[54,17,15,1],
[54,17,15,2],
[54,17,15,3],
[54,17,15,4],
[54,17,15,5],
[54,17,15,6],
[54,17,15,7],
[54,17,15,8],
[54,17,15,11],
[54,17,15,12],
[54,17,15,13],
[54,17,15,14],
[54,17,16,0],
[54,17,16,1],
[54,17,16,2],
[54,17,16,3],
[54,17,16,4],
[54,17,16,5],
[54,17,16,6],
[54,17,16,7],
[54,17,16,8],
[54,17,16,9],
[54,17,16,11],
[54,17,16,12],
[54,17,16,13],
[54,17,16,14],
[54,17,16,15],
[54,18,3,0],
[54,18,3,1],
[54,18,3,2],
[54,18,4,0],
[54,18,4,1],
[54,18,4,2],
[54,18,4,3],
[54,18,5,0],
[54,18,5,1],
[54,18,5,2],
[54,18,5,4],
[54,18,6,0],
[54,18,6,1],
[54,18,6,2],
[54,18,6,4],
[54,18,7,0],
[54,18,7,1],
[54,18,7,2],
[54,18,7,3],
[54,18,7,4],
[54,18,7,5],
[54,18,7,6],
[54,18,8,0],
[54,18,8,1],
[54,18,8,2],
[54,18,8,4],
[54,18,8,7],
[54,18,11,0],
[54,18,11,1],
[54,18,11,2],
[54,18,11,3],
[54,18,11,4],
[54,18,11,5],
[54,18,11,6],
[54,18,11,7],
[54,18,11,8],
[54,18,12,0],
[54,18,12,1],
[54,18,12,2],
[54,18,12,3],
[54,18,12,4],
[54,18,12,5],
[54,18,12,6],
[54,18,12,7],
[54,18,12,8],
[54,18,13,0],
[54,18,13,1],
[54,18,13,2],
[54,18,13,3],
[54,18,13,4],
[54,18,13,5],
[54,18,13,6],
[54,18,13,7],
[54,18,13,8],
[54,18,13,11],
[54,18,13,12],
[54,18,14,0],
[54,18,14,1],
[54,18,14,2],
[54,18,14,3],
[54,18,14,4],
[54,18,14,5],
[54,18,14,6],
[54,18,14,7],
[54,18,14,8],
[54,18,14,11],
[54,18,14,12],
[54,18,16,0],
[54,18,16,1],
[54,18,16,2],
[54,18,16,3],
[54,18,16,4],
[54,18,16,5],
[54,18,16,6],
[54,18,16,7],
[54,18,16,8],
[54,18,16,11],
[54,18,16,12],
[54,18,16,13],
[54,18,16,14],
[54,18,17,0],
[54,18,17,1],
[54,18,17,2],
[54,18,17,3],
[54,18,17,4],
[54,18,17,5],
[54,18,17,6],
[54,18,17,7],
[54,18,17,8],
[54,18,17,11],
[54,18,17,12],
[54,18,17,13],
[54,18,17,14],
[54,18,17,16],
[54,19,3,0],
[54,19,3,1],
[54,19,3,2],
[54,19,4,0],
[54,19,4,1],
[54,19,4,2],
[54,19,4,3],
[54,19,5,0],
[54,19,5,1],
[54,19,5,2],
[54,19,5,4],
[54,19,6,0],
[54,19,6,1],
[54,19,6,2],
[54,19,6,4],
[54,19,7,0],
[54,19,7,1],
[54,19,7,2],
[54,19,7,3],
[54,19,7,4],
[54,19,7,5],
[54,19,7,6],
[54,19,8,0],
[54,19,8,1],
[54,19,8,2],
[54,19,8,4],
[54,19,8,7],
[54,19,9,0],
[54,19,9,1],
[54,19,9,2],
[54,19,9,3],
[54,19,9,4],
[54,19,9,5],
[54,19,9,6],
[54,19,9,7],
[54,19,9,8],
[54,19,11,0],
[54,19,11,1],
[54,19,11,2],
[54,19,11,3],
[54,19,11,4],
[54,19,11,5],
[54,19,11,6],
[54,19,11,7],
[54,19,11,8],
[54,19,11,9],
[54,19,12,0],
[54,19,12,1],
[54,19,12,2],
[54,19,12,3],
[54,19,12,4],
[54,19,12,5],
[54,19,12,6],
[54,19,12,7],
[54,19,12,8],
[54,19,12,9],
[54,19,15,0],
[54,19,15,1],
[54,19,15,2],
[54,19,15,3],
[54,19,15,4],
[54,19,15,5],
[54,19,15,6],
[54,19,15,7],
[54,19,15,8],
[54,19,15,11],
[54,19,15,12],
[54,19,16,0],
[54,19,16,1],
[54,19,16,2],
[54,19,16,3],
[54,19,16,4],
[54,19,16,5],
[54,19,16,6],
[54,19,16,7],
[54,19,16,8],
[54,19,16,9],
[54,19,16,11],
[54,19,16,12],
[54,19,16,15],
[54,19,17,0],
[54,19,17,1],
[54,19,17,2],
[54,19,17,3],
[54,19,17,4],
[54,19,17,5],
[54,19,17,6],
[54,19,17,7],
[54,19,17,8],
[54,19,17,9],
[54,19,17,11],
[54,19,17,12],
[54,19,17,15],
[54,19,17,16],
[54,19,18,0],
[54,19,18,1],
[54,19,18,2],
[54,19,18,3],
[54,19,18,4],
[54,19,18,5],
[54,19,18,6],
[54,19,18,7],
[54,19,18,8],
[54,19,18,11],
[54,19,18,12],
[54,19,18,16],
[54,19,18,17],
[54,21,4,0],
[54,21,4,1],
[54,21,4,2],
[54,21,7,0],
[54,21,7,1],
[54,21,7,2],
[54,21,7,4],
[54,21,9,0],
[54,21,9,1],
[54,21,9,2],
[54,21,9,4],
[54,21,9,7],
[54,21,11,0],
[54,21,11,1],
[54,21,11,2],
[54,21,11,4],
[54,21,11,7],
[54,21,11,9],
[54,21,12,0],
[54,21,12,1],
[54,21,12,2],
[54,21,12,4],
[54,21,12,7],
[54,21,12,9],
[54,21,13,0],
[54,21,13,1],
[54,21,13,2],
[54,21,13,4],
[54,21,13,7],
[54,21,13,9],
[54,21,13,11],
[54,21,13,12],
[54,21,14,0],
[54,21,14,1],
[54,21,14,2],
[54,21,14,4],
[54,21,14,7],
[54,21,14,9],
[54,21,14,11],
[54,21,14,12],
[54,21,15,0],
[54,21,15,1],
[54,21,15,2],
[54,21,15,4],
[54,21,15,7],
[54,21,15,11],
[54,21,15,12],
[54,21,15,13],
[54,21,15,14],
[54,21,16,0],
[54,21,16,1],
[54,21,16,2],
[54,21,16,4],
[54,21,16,7],
[54,21,16,9],
[54,21,16,11],
[54,21,16,12],
[54,21,16,13],
[54,21,16,14],
[54,21,16,15],
[54,21,17,0],
[54,21,17,1],
[54,21,17,2],
[54,21,17,4],
[54,21,17,7],
[54,21,17,9],
[54,21,17,11],
[54,21,17,12],
[54,21,17,13],
[54,21,17,14],
[54,21,17,15],
[54,21,17,16],
[54,21,18,0],
[54,21,18,1],
[54,21,18,2],
[54,21,18,4],
[54,21,18,7],
[54,21,18,11],
[54,21,18,12],
[54,21,18,13],
[54,21,18,14],
[54,21,18,16],
[54,21,18,17],
[54,21,19,0],
[54,21,19,1],
[54,21,19,2],
[54,21,19,4],
[54,21,19,7],
[54,21,19,9],
[54,21,19,11],
[54,21,19,12],
[54,21,19,15],
[54,21,19,16],
[54,21,19,17],
[54,21,19,18],
[54,23,3,0],
[54,23,3,1],
[54,23,3,2],
[54,23,4,0],
[54,23,4,1],
[54,23,4,2],
[54,23,4,3],
[54,23,5,0],
[54,23,5,1],
[54,23,5,2],
[54,23,5,4],
[54,23,6,0],
[54,23,6,1],
[54,23,6,2],
[54,23,6,4],
[54,23,7,0],
[54,23,7,1],
[54,23,7,2],
[54,23,7,3],
[54,23,7,4],
[54,23,7,5],
[54,23,7,6],
[54,23,8,0],
[54,23,8,1],
[54,23,8,2],
[54,23,8,4],
[54,23,8,7],
[54,23,9,0],
[54,23,9,1],
[54,23,9,2],
[54,23,9,3],
[54,23,9,4],
[54,23,9,5],
[54,23,9,6],
[54,23,9,7],
[54,23,9,8],
[54,23,11,0],
[54,23,11,1],
[54,23,11,2],
[54,23,11,3],
[54,23,11,4],
[54,23,11,5],
[54,23,11,6],
[54,23,11,7],
[54,23,11,8],
[54,23,11,9],
[54,23,12,0],
[54,23,12,1],
[54,23,12,2],
[54,23,12,3],
[54,23,12,4],
[54,23,12,5],
[54,23,12,6],
[54,23,12,7],
[54,23,12,8],
[54,23,12,9],
[54,23,13,0],
[54,23,13,1],
[54,23,13,2],
[54,23,13,3],
[54,23,13,4],
[54,23,13,5],
[54,23,13,6],
[54,23,13,7],
[54,23,13,8],
[54,23,13,9],
[54,23,13,11],
[54,23,13,12],
[54,23,14,0],
[54,23,14,1],
[54,23,14,2],
[54,23,14,3],
[54,23,14,4],
[54,23,14,5],
[54,23,14,6],
[54,23,14,7],
[54,23,14,8],
[54,23,14,9],
[54,23,14,11],
[54,23,14,12],
[54,23,15,0],
[54,23,15,1],
[54,23,15,2],
[54,23,15,3],
[54,23,15,4],
[54,23,15,5],
[54,23,15,6],
[54,23,15,7],
[54,23,15,8],
[54,23,15,11],
[54,23,15,12],
[54,23,15,13],
[54,23,15,14],
[54,23,16,0],
[54,23,16,1],
[54,23,16,2],
[54,23,16,3],
[54,23,16,4],
[54,23,16,5],
[54,23,16,6],
[54,23,16,7],
[54,23,16,8],
[54,23,16,9],
[54,23,16,11],
[54,23,16,12],
[54,23,16,13],
[54,23,16,14],
[54,23,16,15],
[54,23,17,0],
[54,23,17,1],
[54,23,17,2],
[54,23,17,3],
[54,23,17,4],
[54,23,17,5],
[54,23,17,6],
[54,23,17,7],
[54,23,17,8],
[54,23,17,9],
[54,23,17,11],
[54,23,17,12],
[54,23,17,13],
[54,23,17,14],
[54,23,17,15],
[54,23,17,16],
[54,23,18,0],
[54,23,18,1],
[54,23,18,2],
[54,23,18,3],
[54,23,18,4],
[54,23,18,5],
[54,23,18,6],
[54,23,18,7],
[54,23,18,8],
[54,23,18,11],
[54,23,18,12],
[54,23,18,13],
[54,23,18,14],
[54,23,18,16],
[54,23,18,17],
[54,23,19,0],
[54,23,19,1],
[54,23,19,2],
[54,23,19,3],
[54,23,19,4],
[54,23,19,5],
[54,23,19,6],
[54,23,19,7],
[54,23,19,8],
[54,23,19,9],
[54,23,19,11],
[54,23,19,12],
[54,23,19,15],
[54,23,19,16],
[54,23,19,17],
[54,23,19,18],
[54,23,21,0],
[54,23,21,1],
[54,23,21,2],
[54,23,21,4],
[54,23,21,7],
[54,23,21,9],
[54,23,21,11],
[54,23,21,12],
[54,23,21,13],
[54,23,21,14],
[54,23,21,15],
[54,23,21,16],
[54,23,21,17],
[54,23,21,18],
[54,23,21,19],
[54,24,3,0],
[54,24,3,1],
[54,24,3,2],
[54,24,4,0],
[54,24,4,1],
[54,24,4,2],
[54,24,4,3],
[54,24,5,0],
[54,24,5,1],
[54,24,5,2],
[54,24,5,4],
[54,24,6,0],
[54,24,6,1],
[54,24,6,2],
[54,24,6,4],
[54,24,7,0],
[54,24,7,1],
[54,24,7,2],
[54,24,7,3],
[54,24,7,4],
[54,24,7,5],
[54,24,7,6],
[54,24,8,0],
[54,24,8,1],
[54,24,8,2],
[54,24,8,4],
[54,24,8,7],
[54,24,9,0],
[54,24,9,1],
[54,24,9,2],
[54,24,9,3],
[54,24,9,4],
[54,24,9,5],
[54,24,9,6],
[54,24,9,7],
[54,24,9,8],
[54,24,11,0],
[54,24,11,1],
[54,24,11,2],
[54,24,11,3],
[54,24,11,4],
[54,24,11,5],
[54,24,11,6],
[54,24,11,7],
[54,24,11,8],
[54,24,11,9],
[54,24,12,0],
[54,24,12,1],
[54,24,12,2],
[54,24,12,3],
[54,24,12,4],
[54,24,12,5],
[54,24,12,6],
[54,24,12,7],
[54,24,12,8],
[54,24,12,9],
[54,24,13,0],
[54,24,13,1],
[54,24,13,2],
[54,24,13,3],
[54,24,13,4],
[54,24,13,5],
[54,24,13,6],
[54,24,13,7],
[54,24,13,8],
[54,24,13,9],
[54,24,13,11],
[54,24,13,12],
[54,24,14,0],
[54,24,14,1],
[54,24,14,2],
[54,24,14,3],
[54,24,14,4],
[54,24,14,5],
[54,24,14,6],
[54,24,14,7],
[54,24,14,8],
[54,24,14,9],
[54,24,14,11],
[54,24,14,12],
[54,24,15,0],
[54,24,15,1],
[54,24,15,2],
[54,24,15,3],
[54,24,15,4],
[54,24,15,5],
[54,24,15,6],
[54,24,15,7],
[54,24,15,8],
[54,24,15,11],
[54,24,15,12],
[54,24,15,13],
[54,24,15,14],
[54,24,17,0],
[54,24,17,1],
[54,24,17,2],
[54,24,17,3],
[54,24,17,4],
[54,24,17,5],
[54,24,17,6],
[54,24,17,7],
[54,24,17,8],
[54,24,17,9],
[54,24,17,11],
[54,24,17,12],
[54,24,17,13],
[54,24,17,14],
[54,24,17,15],
[54,24,18,0],
[54,24,18,1],
[54,24,18,2],
[54,24,18,3],
[54,24,18,4],
[54,24,18,5],
[54,24,18,6],
[54,24,18,7],
[54,24,18,8],
[54,24,18,11],
[54,24,18,12],
[54,24,18,13],
[54,24,18,14],
[54,24,18,17],
[54,24,19,0],
[54,24,19,1],
[54,24,19,2],
[54,24,19,3],
[54,24,19,4],
[54,24,19,5],
[54,24,19,6],
[54,24,19,7],
[54,24,19,8],
[54,24,19,9],
[54,24,19,11],
[54,24,19,12],
[54,24,19,15],
[54,24,19,17],
[54,24,19,18],
[54,24,21,0],
[54,24,21,1],
[54,24,21,2],
[54,24,21,4],
[54,24,21,7],
[54,24,21,9],
[54,24,21,11],
[54,24,21,12],
[54,24,21,13],
[54,24,21,14],
[54,24,21,15],
[54,24,21,17],
[54,24,21,18],
[54,24,21,19],
[54,24,23,0],
[54,24,23,1],
[54,24,23,2],
[54,24,23,3],
[54,24,23,4],
[54,24,23,5],
[54,24,23,6],
[54,24,23,7],
[54,24,23,8],
[54,24,23,9],
[54,24,23,11],
[54,24,23,12],
[54,24,23,13],
[54,24,23,14],
[54,24,23,15],
[54,24,23,17],
[54,24,23,18],
[54,24,23,19],
[54,24,23,21],
[54,25,3,0],
[54,25,3,1],
[54,25,3,2],
[54,25,4,0],
[54,25,4,1],
[54,25,4,2],
[54,25,4,3],
[54,25,5,0],
[54,25,5,1],
[54,25,5,2],
[54,25,5,4],
[54,25,6,0],
[54,25,6,1],
[54,25,6,2],
[54,25,6,4],
[54,25,8,0],
[54,25,8,1],
[54,25,8,2],
[54,25,8,4],
[54,25,9,0],
[54,25,9,1],
[54,25,9,2],
[54,25,9,3],
[54,25,9,4],
[54,25,9,5],
[54,25,9,6],
[54,25,9,8],
[54,25,11,0],
[54,25,11,1],
[54,25,11,2],
[54,25,11,3],
[54,25,11,4],
[54,25,11,5],
[54,25,11,6],
[54,25,11,8],
[54,25,11,9],
[54,25,12,0],
[54,25,12,1],
[54,25,12,2],
[54,25,12,3],
[54,25,12,4],
[54,25,12,5],
[54,25,12,6],
[54,25,12,8],
[54,25,12,9],
[54,25,13,0],
[54,25,13,1],
[54,25,13,2],
[54,25,13,3],
[54,25,13,4],
[54,25,13,5],
[54,25,13,6],
[54,25,13,8],
[54,25,13,9],
[54,25,13,11],
[54,25,13,12],
[54,25,14,0],
[54,25,14,1],
[54,25,14,2],
[54,25,14,3],
[54,25,14,4],
[54,25,14,5],
[54,25,14,6],
[54,25,14,8],
[54,25,14,9],
[54,25,14,11],
[54,25,14,12],
[54,25,15,0],
[54,25,15,1],
[54,25,15,2],
[54,25,15,3],
[54,25,15,4],
[54,25,15,5],
[54,25,15,6],
[54,25,15,8],
[54,25,15,11],
[54,25,15,12],
[54,25,15,13],
[54,25,15,14],
[54,25,16,0],
[54,25,16,1],
[54,25,16,2],
[54,25,16,3],
[54,25,16,4],
[54,25,16,5],
[54,25,16,6],
[54,25,16,8],
[54,25,16,9],
[54,25,16,11],
[54,25,16,12],
[54,25,16,13],
[54,25,16,14],
[54,25,16,15],
[54,25,17,0],
[54,25,17,1],
[54,25,17,2],
[54,25,17,3],
[54,25,17,4],
[54,25,17,5],
[54,25,17,6],
[54,25,17,8],
[54,25,17,9],
[54,25,17,11],
[54,25,17,12],
[54,25,17,13],
[54,25,17,14],
[54,25,17,15],
[54,25,17,16],
[54,25,18,0],
[54,25,18,1],
[54,25,18,2],
[54,25,18,3],
[54,25,18,4],
[54,25,18,5],
[54,25,18,6],
[54,25,18,8],
[54,25,18,11],
[54,25,18,12],
[54,25,18,13],
[54,25,18,14],
[54,25,18,16],
[54,25,18,17],
[54,25,19,0],
[54,25,19,1],
[54,25,19,2],
[54,25,19,3],
[54,25,19,4],
[54,25,19,5],
[54,25,19,6],
[54,25,19,8],
[54,25,19,9],
[54,25,19,11],
[54,25,19,12],
[54,25,19,15],
[54,25,19,16],
[54,25,19,17],
[54,25,19,18],
[54,25,21,0],
[54,25,21,1],
[54,25,21,2],
[54,25,21,4],
[54,25,21,9],
[54,25,21,11],
[54,25,21,12],
[54,25,21,13],
[54,25,21,14],
[54,25,21,15],
[54,25,21,16],
[54,25,21,17],
[54,25,21,18],
[54,25,21,19],
[54,25,23,0],
[54,25,23,1],
[54,25,23,2],
[54,25,23,3],
[54,25,23,4],
[54,25,23,5],
[54,25,23,6],
[54,25,23,8],
[54,25,23,9],
[54,25,23,11],
[54,25,23,12],
[54,25,23,13],
[54,25,23,14],
[54,25,23,15],
[54,25,23,16],
[54,25,23,17],
[54,25,23,18],
[54,25,23,19],
[54,25,23,21],
[54,25,24,0],
[54,25,24,1],
[54,25,24,2],
[54,25,24,3],
[54,25,24,4],
[54,25,24,5],
[54,25,24,6],
[54,25,24,8],
[54,25,24,9],
[54,25,24,11],
[54,25,24,12],
[54,25,24,13],
[54,25,24,14],
[54,25,24,15],
[54,25,24,17],
[54,25,24,18],
[54,25,24,19],
[54,25,24,21],
[54,25,24,23],
[54,26,3,0],
[54,26,3,1],
[54,26,3,2],
[54,26,4,0],
[54,26,4,1],
[54,26,4,2],
[54,26,4,3],
[54,26,5,0],
[54,26,5,1],
[54,26,5,2],
[54,26,5,4],
[54,26,6,0],
[54,26,6,1],
[54,26,6,2],
[54,26,6,4],
[54,26,7,0],
[54,26,7,1],
[54,26,7,2],
[54,26,7,3],
[54,26,7,4],
[54,26,7,5],
[54,26,7,6],
[54,26,8,0],
[54,26,8,1],
[54,26,8,2],
[54,26,8,4],
[54,26,8,7],
[54,26,11,0],
[54,26,11,1],
[54,26,11,2],
[54,26,11,3],
[54,26,11,4],
[54,26,11,5],
[54,26,11,6],
[54,26,11,7],
[54,26,11,8],
[54,26,12,0],
[54,26,12,1],
[54,26,12,2],
[54,26,12,3],
[54,26,12,4],
[54,26,12,5],
[54,26,12,6],
[54,26,12,7],
[54,26,12,8],
[54,26,13,0],
[54,26,13,1],
[54,26,13,2],
[54,26,13,3],
[54,26,13,4],
[54,26,13,5],
[54,26,13,6],
[54,26,13,7],
[54,26,13,8],
[54,26,13,11],
[54,26,13,12],
[54,26,14,0],
[54,26,14,1],
[54,26,14,2],
[54,26,14,3],
[54,26,14,4],
[54,26,14,5],
[54,26,14,6],
[54,26,14,7],
[54,26,14,8],
[54,26,14,11],
[54,26,14,12],
[54,26,16,0],
[54,26,16,1],
[54,26,16,2],
[54,26,16,3],
[54,26,16,4],
[54,26,16,5],
[54,26,16,6],
[54,26,16,7],
[54,26,16,8],
[54,26,16,11],
[54,26,16,12],
[54,26,16,13],
[54,26,16,14],
[54,26,17,0],
[54,26,17,1],
[54,26,17,2],
[54,26,17,3],
[54,26,17,4],
[54,26,17,5],
[54,26,17,6],
[54,26,17,7],
[54,26,17,8],
[54,26,17,11],
[54,26,17,12],
[54,26,17,13],
[54,26,17,14],
[54,26,17,16],
[54,26,19,0],
[54,26,19,1],
[54,26,19,2],
[54,26,19,3],
[54,26,19,4],
[54,26,19,5],
[54,26,19,6],
[54,26,19,7],
[54,26,19,8],
[54,26,19,11],
[54,26,19,12],
[54,26,19,16],
[54,26,19,17],
[54,26,21,0],
[54,26,21,1],
[54,26,21,2],
[54,26,21,4],
[54,26,21,7],
[54,26,21,11],
[54,26,21,12],
[54,26,21,13],
[54,26,21,14],
[54,26,21,16],
[54,26,21,17],
[54,26,21,19],
[54,26,23,0],
[54,26,23,1],
[54,26,23,2],
[54,26,23,3],
[54,26,23,4],
[54,26,23,5],
[54,26,23,6],
[54,26,23,7],
[54,26,23,8],
[54,26,23,11],
[54,26,23,12],
[54,26,23,13],
[54,26,23,14],
[54,26,23,16],
[54,26,23,17],
[54,26,23,19],
[54,26,23,21],
[54,26,24,0],
[54,26,24,1],
[54,26,24,2],
[54,26,24,3],
[54,26,24,4],
[54,26,24,5],
[54,26,24,6],
[54,26,24,7],
[54,26,24,8],
[54,26,24,11],
[54,26,24,12],
[54,26,24,13],
[54,26,24,14],
[54,26,24,17],
[54,26,24,19],
[54,26,24,21],
[54,26,24,23],
[54,26,25,0],
[54,26,25,1],
[54,26,25,2],
[54,26,25,3],
[54,26,25,4],
[54,26,25,5],
[54,26,25,6],
[54,26,25,8],
[54,26,25,11],
[54,26,25,12],
[54,26,25,13],
[54,26,25,14],
[54,26,25,16],
[54,26,25,17],
[54,26,25,19],
[54,26,25,21],
[54,26,25,23],
[54,26,25,24],
[54,28,3,0],
[54,28,3,1],
[54,28,3,2],
[54,28,4,0],
[54,28,4,1],
[54,28,4,2],
[54,28,4,3],
[54,28,5,0],
[54,28,5,1],
[54,28,5,2],
[54,28,5,4],
[54,28,6,0],
[54,28,6,1],
[54,28,6,2],
[54,28,6,4],
[54,28,8,0],
[54,28,8,1],
[54,28,8,2],
[54,28,8,4],
[54,28,9,0],
[54,28,9,1],
[54,28,9,2],
[54,28,9,3],
[54,28,9,4],
[54,28,9,5],
[54,28,9,6],
[54,28,9,8],
[54,28,11,0],
[54,28,11,1],
[54,28,11,2],
[54,28,11,3],
[54,28,11,4],
[54,28,11,5],
[54,28,11,6],
[54,28,11,8],
[54,28,11,9],
[54,28,12,0],
[54,28,12,1],
[54,28,12,2],
[54,28,12,3],
[54,28,12,4],
[54,28,12,5],
[54,28,12,6],
[54,28,12,8],
[54,28,12,9],
[54,28,13,0],
[54,28,13,1],
[54,28,13,2],
[54,28,13,3],
[54,28,13,4],
[54,28,13,5],
[54,28,13,6],
[54,28,13,8],
[54,28,13,9],
[54,28,13,11],
[54,28,13,12],
[54,28,14,0],
[54,28,14,1],
[54,28,14,2],
[54,28,14,3],
[54,28,14,4],
[54,28,14,5],
[54,28,14,6],
[54,28,14,8],
[54,28,14,9],
[54,28,14,11],
[54,28,14,12],
[54,28,15,0],
[54,28,15,1],
[54,28,15,2],
[54,28,15,3],
[54,28,15,4],
[54,28,15,5],
[54,28,15,6],
[54,28,15,8],
[54,28,15,11],
[54,28,15,12],
[54,28,15,13],
[54,28,15,14],
[54,28,16,0],
[54,28,16,1],
[54,28,16,2],
[54,28,16,3],
[54,28,16,4],
[54,28,16,5],
[54,28,16,6],
[54,28,16,8],
[54,28,16,9],
[54,28,16,11],
[54,28,16,12],
[54,28,16,13],
[54,28,16,14],
[54,28,16,15],
[54,28,17,0],
[54,28,17,1],
[54,28,17,2],
[54,28,17,3],
[54,28,17,4],
[54,28,17,5],
[54,28,17,6],
[54,28,17,8],
[54,28,17,9],
[54,28,17,11],
[54,28,17,12],
[54,28,17,13],
[54,28,17,14],
[54,28,17,15],
[54,28,17,16],
[54,28,18,0],
[54,28,18,1],
[54,28,18,2],
[54,28,18,3],
[54,28,18,4],
[54,28,18,5],
[54,28,18,6],
[54,28,18,8],
[54,28,18,11],
[54,28,18,12],
[54,28,18,13],
[54,28,18,14],
[54,28,18,16],
[54,28,18,17],
[54,28,19,0],
[54,28,19,1],
[54,28,19,2],
[54,28,19,3],
[54,28,19,4],
[54,28,19,5],
[54,28,19,6],
[54,28,19,8],
[54,28,19,9],
[54,28,19,11],
[54,28,19,12],
[54,28,19,15],
[54,28,19,16],
[54,28,19,17],
[54,28,19,18],
[54,28,21,0],
[54,28,21,1],
[54,28,21,2],
[54,28,21,4],
[54,28,21,9],
[54,28,21,11],
[54,28,21,12],
[54,28,21,13],
[54,28,21,14],
[54,28,21,15],
[54,28,21,16],
[54,28,21,17],
[54,28,21,18],
[54,28,21,19],
[54,28,23,0],
[54,28,23,1],
[54,28,23,2],
[54,28,23,3],
[54,28,23,4],
[54,28,23,5],
[54,28,23,6],
[54,28,23,8],
[54,28,23,9],
[54,28,23,11],
[54,28,23,12],
[54,28,23,13],
[54,28,23,14],
[54,28,23,15],
[54,28,23,16],
[54,28,23,17],
[54,28,23,18],
[54,28,23,19],
[54,28,23,21],
[54,28,24,0],
[54,28,24,1],
[54,28,24,2],
[54,28,24,3],
[54,28,24,4],
[54,28,24,5],
[54,28,24,6],
[54,28,24,8],
[54,28,24,9],
[54,28,24,11],
[54,28,24,12],
[54,28,24,13],
[54,28,24,14],
[54,28,24,15],
[54,28,24,17],
[54,28,24,18],
[54,28,24,19],
[54,28,24,21],
[54,28,24,23],
[54,28,26,0],
[54,28,26,1],
[54,28,26,2],
[54,28,26,3],
[54,28,26,4],
[54,28,26,5],
[54,28,26,6],
[54,28,26,8],
[54,28,26,11],
[54,28,26,12],
[54,28,26,13],
[54,28,26,14],
[54,28,26,16],
[54,28,26,17],
[54,28,26,19],
[54,28,26,21],
[54,28,26,23],
[54,28,26,24],
[54,29,3,0],
[54,29,3,1],
[54,29,3,2],
[54,29,4,0],
[54,29,4,1],
[54,29,4,2],
[54,29,4,3],
[54,29,5,0],
[54,29,5,1],
[54,29,5,2],
[54,29,5,4],
[54,29,6,0],
[54,29,6,1],
[54,29,6,2],
[54,29,6,4],
[54,29,7,0],
[54,29,7,1],
[54,29,7,2],
[54,29,7,3],
[54,29,7,4],
[54,29,7,5],
[54,29,7,6],
[54,29,8,0],
[54,29,8,1],
[54,29,8,2],
[54,29,8,4],
[54,29,8,7],
[54,29,9,0],
[54,29,9,1],
[54,29,9,2],
[54,29,9,3],
[54,29,9,4],
[54,29,9,5],
[54,29,9,6],
[54,29,9,7],
[54,29,9,8],
[54,29,13,0],
[54,29,13,1],
[54,29,13,2],
[54,29,13,3],
[54,29,13,4],
[54,29,13,5],
[54,29,13,6],
[54,29,13,7],
[54,29,13,8],
[54,29,13,9],
[54,29,14,0],
[54,29,14,1],
[54,29,14,2],
[54,29,14,3],
[54,29,14,4],
[54,29,14,5],
[54,29,14,6],
[54,29,14,7],
[54,29,14,8],
[54,29,14,9],
[54,29,15,0],
[54,29,15,1],
[54,29,15,2],
[54,29,15,3],
[54,29,15,4],
[54,29,15,5],
[54,29,15,6],
[54,29,15,7],
[54,29,15,8],
[54,29,15,13],
[54,29,15,14],
[54,29,16,0],
[54,29,16,1],
[54,29,16,2],
[54,29,16,3],
[54,29,16,4],
[54,29,16,5],
[54,29,16,6],
[54,29,16,7],
[54,29,16,8],
[54,29,16,9],
[54,29,16,13],
[54,29,16,14],
[54,29,16,15],
[54,29,17,0],
[54,29,17,1],
[54,29,17,2],
[54,29,17,3],
[54,29,17,4],
[54,29,17,5],
[54,29,17,6],
[54,29,17,7],
[54,29,17,8],
[54,29,17,9],
[54,29,17,13],
[54,29,17,14],
[54,29,17,15],
[54,29,17,16],
[54,29,18,0],
[54,29,18,1],
[54,29,18,2],
[54,29,18,3],
[54,29,18,4],
[54,29,18,5],
[54,29,18,6],
[54,29,18,7],
[54,29,18,8],
[54,29,18,13],
[54,29,18,14],
[54,29,18,16],
[54,29,18,17],
[54,29,19,0],
[54,29,19,1],
[54,29,19,2],
[54,29,19,3],
[54,29,19,4],
[54,29,19,5],
[54,29,19,6],
[54,29,19,7],
[54,29,19,8],
[54,29,19,9],
[54,29,19,15],
[54,29,19,16],
[54,29,19,17],
[54,29,19,18],
[54,29,21,0],
[54,29,21,1],
[54,29,21,2],
[54,29,21,4],
[54,29,21,7],
[54,29,21,9],
[54,29,21,13],
[54,29,21,14],
[54,29,21,15],
[54,29,21,16],
[54,29,21,17],
[54,29,21,18],
[54,29,21,19],
[54,29,23,0],
[54,29,23,1],
[54,29,23,2],
[54,29,23,3],
[54,29,23,4],
[54,29,23,5],
[54,29,23,6],
[54,29,23,7],
[54,29,23,8],
[54,29,23,9],
[54,29,23,13],
[54,29,23,14],
[54,29,23,15],
[54,29,23,16],
[54,29,23,17],
[54,29,23,18],
[54,29,23,19],
[54,29,23,21],
[54,29,24,0],
[54,29,24,1],
[54,29,24,2],
[54,29,24,3],
[54,29,24,4],
[54,29,24,5],
[54,29,24,6],
[54,29,24,7],
[54,29,24,8],
[54,29,24,9],
[54,29,24,13],
[54,29,24,14],
[54,29,24,15],
[54,29,24,17],
[54,29,24,18],
[54,29,24,19],
[54,29,24,21],
[54,29,24,23],
[54,29,25,0],
[54,29,25,1],
[54,29,25,2],
[54,29,25,3],
[54,29,25,4],
[54,29,25,5],
[54,29,25,6],
[54,29,25,8],
[54,29,25,9],
[54,29,25,13],
[54,29,25,14],
[54,29,25,15],
[54,29,25,16],
[54,29,25,17],
[54,29,25,18],
[54,29,25,19],
[54,29,25,21],
[54,29,25,23],
[54,29,25,24],
[54,29,26,0],
[54,29,26,1],
[54,29,26,2],
[54,29,26,3],
[54,29,26,4],
[54,29,26,5],
[54,29,26,6],
[54,29,26,7],
[54,29,26,8],
[54,29,26,13],
[54,29,26,14],
[54,29,26,16],
[54,29,26,17],
[54,29,26,19],
[54,29,26,21],
[54,29,26,23],
[54,29,26,24],
[54,29,28,0],
[54,29,28,1],
[54,29,28,2],
[54,29,28,3],
[54,29,28,4],
[54,29,28,5],
[54,29,28,6],
[54,29,28,8],
[54,29,28,9],
[54,29,28,13],
[54,29,28,14],
[54,29,28,15],
[54,29,28,16],
[54,29,28,17],
[54,29,28,18],
[54,29,28,19],
[54,29,28,21],
[54,29,28,26],
[54,30,3,0],
[54,30,3,1],
[54,30,3,2],
[54,30,4,0],
[54,30,4,1],
[54,30,4,2],
[54,30,4,3],
[54,30,5,0],
[54,30,5,1],
[54,30,5,2],
[54,30,5,4],
[54,30,6,0],
[54,30,6,1],
[54,30,6,2],
[54,30,6,4],
[54,30,7,0],
[54,30,7,1],
[54,30,7,2],
[54,30,7,3],
[54,30,7,4],
[54,30,7,5],
[54,30,7,6],
[54,30,8,0],
[54,30,8,1],
[54,30,8,2],
[54,30,8,4],
[54,30,8,7],
[54,30,9,0],
[54,30,9,1],
[54,30,9,2],
[54,30,9,3],
[54,30,9,4],
[54,30,9,5],
[54,30,9,6],
[54,30,9,7],
[54,30,9,8],
[54,30,11,0],
[54,30,11,1],
[54,30,11,2],
[54,30,11,3],
[54,30,11,4],
[54,30,11,5],
[54,30,11,6],
[54,30,11,7],
[54,30,11,8],
[54,30,11,9],
[54,30,12,0],
[54,30,12,1],
[54,30,12,2],
[54,30,12,3],
[54,30,12,4],
[54,30,12,5],
[54,30,12,6],
[54,30,12,7],
[54,30,12,8],
[54,30,12,9],
[54,30,13,0],
[54,30,13,1],
[54,30,13,2],
[54,30,13,3],
[54,30,13,4],
[54,30,13,5],
[54,30,13,6],
[54,30,13,7],
[54,30,13,8],
[54,30,13,9],
[54,30,13,11],
[54,30,13,12],
[54,30,14,0],
[54,30,14,1],
[54,30,14,2],
[54,30,14,3],
[54,30,14,4],
[54,30,14,5],
[54,30,14,6],
[54,30,14,7],
[54,30,14,8],
[54,30,14,9],
[54,30,14,11],
[54,30,14,12],
[54,30,15,0],
[54,30,15,1],
[54,30,15,2],
[54,30,15,3],
[54,30,15,4],
[54,30,15,5],
[54,30,15,6],
[54,30,15,7],
[54,30,15,8],
[54,30,15,11],
[54,30,15,12],
[54,30,15,13],
[54,30,15,14],
[54,30,16,0],
[54,30,16,1],
[54,30,16,2],
[54,30,16,3],
[54,30,16,4],
[54,30,16,5],
[54,30,16,6],
[54,30,16,7],
[54,30,16,8],
[54,30,16,9],
[54,30,16,11],
[54,30,16,12],
[54,30,16,13],
[54,30,16,14],
[54,30,16,15],
[54,30,17,0],
[54,30,17,1],
[54,30,17,2],
[54,30,17,3],
[54,30,17,4],
[54,30,17,5],
[54,30,17,6],
[54,30,17,7],
[54,30,17,8],
[54,30,17,9],
[54,30,17,11],
[54,30,17,12],
[54,30,17,13],
[54,30,17,14],
[54,30,17,15],
[54,30,17,16],
[54,30,18,0],
[54,30,18,1],
[54,30,18,2],
[54,30,18,3],
[54,30,18,4],
[54,30,18,5],
[54,30,18,6],
[54,30,18,7],
[54,30,18,8],
[54,30,18,11],
[54,30,18,12],
[54,30,18,13],
[54,30,18,14],
[54,30,18,16],
[54,30,18,17],
[54,30,19,0],
[54,30,19,1],
[54,30,19,2],
[54,30,19,3],
[54,30,19,4],
[54,30,19,5],
[54,30,19,6],
[54,30,19,7],
[54,30,19,8],
[54,30,19,9],
[54,30,19,11],
[54,30,19,12],
[54,30,19,15],
[54,30,19,16],
[54,30,19,17],
[54,30,19,18],
[54,30,21,0],
[54,30,21,1],
[54,30,21,2],
[54,30,21,4],
[54,30,21,7],
[54,30,21,9],
[54,30,21,11],
[54,30,21,12],
[54,30,21,13],
[54,30,21,14],
[54,30,21,15],
[54,30,21,16],
[54,30,21,17],
[54,30,21,18],
[54,30,21,19],
[54,30,24,0],
[54,30,24,1],
[54,30,24,2],
[54,30,24,3],
[54,30,24,4],
[54,30,24,5],
[54,30,24,6],
[54,30,24,7],
[54,30,24,8],
[54,30,24,9],
[54,30,24,11],
[54,30,24,12],
[54,30,24,13],
[54,30,24,14],
[54,30,24,15],
[54,30,24,17],
[54,30,24,18],
[54,30,24,19],
[54,30,24,21],
[54,30,25,0],
[54,30,25,1],
[54,30,25,2],
[54,30,25,3],
[54,30,25,4],
[54,30,25,5],
[54,30,25,6],
[54,30,25,8],
[54,30,25,9],
[54,30,25,11],
[54,30,25,12],
[54,30,25,13],
[54,30,25,14],
[54,30,25,15],
[54,30,25,16],
[54,30,25,17],
[54,30,25,18],
[54,30,25,19],
[54,30,25,21],
[54,30,25,24],
[54,30,26,0],
[54,30,26,1],
[54,30,26,2],
[54,30,26,3],
[54,30,26,4],
[54,30,26,5],
[54,30,26,6],
[54,30,26,7],
[54,30,26,8],
[54,30,26,11],
[54,30,26,12],
[54,30,26,13],
[54,30,26,14],
[54,30,26,16],
[54,30,26,17],
[54,30,26,19],
[54,30,26,21],
[54,30,26,24],
[54,30,26,25],
[54,30,28,0],
[54,30,28,1],
[54,30,28,2],
[54,30,28,3],
[54,30,28,4],
[54,30,28,5],
[54,30,28,6],
[54,30,28,8],
[54,30,28,9],
[54,30,28,11],
[54,30,28,12],
[54,30,28,13],
[54,30,28,14],
[54,30,28,15],
[54,30,28,16],
[54,30,28,17],
[54,30,28,18],
[54,30,28,19],
[54,30,28,21],
[54,30,28,24],
[54,30,28,26],
[54,30,29,0],
[54,30,29,1],
[54,30,29,2],
[54,30,29,3],
[54,30,29,4],
[54,30,29,5],
[54,30,29,6],
[54,30,29,7],
[54,30,29,8],
[54,30,29,9],
[54,30,29,13],
[54,30,29,14],
[54,30,29,15],
[54,30,29,16],
[54,30,29,17],
[54,30,29,18],
[54,30,29,19],
[54,30,29,24],
[54,30,29,25],
[54,30,29,26],
[54,30,29,28],
[54,31,3,0],
[54,31,3,1],
[54,31,3,2],
[54,31,4,0],
[54,31,4,1],
[54,31,4,2],
[54,31,4,3],
[54,31,5,0],
[54,31,5,1],
[54,31,5,2],
[54,31,5,4],
[54,31,6,0],
[54,31,6,1],
[54,31,6,2],
[54,31,6,4],
[54,31,7,0],
[54,31,7,1],
[54,31,7,2],
[54,31,7,3],
[54,31,7,4],
[54,31,7,5],
[54,31,7,6],
[54,31,8,0],
[54,31,8,1],
[54,31,8,2],
[54,31,8,4],
[54,31,8,7],
[54,31,9,0],
[54,31,9,1],
[54,31,9,2],
[54,31,9,3],
[54,31,9,4],
[54,31,9,5],
[54,31,9,6],
[54,31,9,7],
[54,31,9,8],
[54,31,11,0],
[54,31,11,1],
[54,31,11,2],
[54,31,11,3],
[54,31,11,4],
[54,31,11,5],
[54,31,11,6],
[54,31,11,7],
[54,31,11,8],
[54,31,11,9],
[54,31,12,0],
[54,31,12,1],
[54,31,12,2],
[54,31,12,3],
[54,31,12,4],
[54,31,12,5],
[54,31,12,6],
[54,31,12,7],
[54,31,12,8],
[54,31,12,9],
[54,31,13,0],
[54,31,13,1],
[54,31,13,2],
[54,31,13,3],
[54,31,13,4],
[54,31,13,5],
[54,31,13,6],
[54,31,13,7],
[54,31,13,8],
[54,31,13,9],
[54,31,13,11],
[54,31,13,12],
[54,31,14,0],
[54,31,14,1],
[54,31,14,2],
[54,31,14,3],
[54,31,14,4],
[54,31,14,5],
[54,31,14,6],
[54,31,14,7],
[54,31,14,8],
[54,31,14,9],
[54,31,14,11],
[54,31,14,12],
[54,31,15,0],
[54,31,15,1],
[54,31,15,2],
[54,31,15,3],
[54,31,15,4],
[54,31,15,5],
[54,31,15,6],
[54,31,15,7],
[54,31,15,8],
[54,31,15,11],
[54,31,15,12],
[54,31,15,13],
[54,31,15,14],
[54,31,16,0],
[54,31,16,1],
[54,31,16,2],
[54,31,16,3],
[54,31,16,4],
[54,31,16,5],
[54,31,16,6],
[54,31,16,7],
[54,31,16,8],
[54,31,16,9],
[54,31,16,11],
[54,31,16,12],
[54,31,16,13],
[54,31,16,14],
[54,31,16,15],
[54,31,17,0],
[54,31,17,1],
[54,31,17,2],
[54,31,17,3],
[54,31,17,4],
[54,31,17,5],
[54,31,17,6],
[54,31,17,7],
[54,31,17,8],
[54,31,17,9],
[54,31,17,11],
[54,31,17,12],
[54,31,17,13],
[54,31,17,14],
[54,31,17,15],
[54,31,17,16],
[54,31,18,0],
[54,31,18,1],
[54,31,18,2],
[54,31,18,3],
[54,31,18,4],
[54,31,18,5],
[54,31,18,6],
[54,31,18,7],
[54,31,18,8],
[54,31,18,11],
[54,31,18,12],
[54,31,18,13],
[54,31,18,14],
[54,31,18,16],
[54,31,18,17],
[54,31,19,0],
[54,31,19,1],
[54,31,19,2],
[54,31,19,3],
[54,31,19,4],
[54,31,19,5],
[54,31,19,6],
[54,31,19,7],
[54,31,19,8],
[54,31,19,9],
[54,31,19,11],
[54,31,19,12],
[54,31,19,15],
[54,31,19,16],
[54,31,19,17],
[54,31,19,18],
[54,31,21,0],
[54,31,21,1],
[54,31,21,2],
[54,31,21,4],
[54,31,21,7],
[54,31,21,9],
[54,31,21,11],
[54,31,21,12],
[54,31,21,13],
[54,31,21,14],
[54,31,21,15],
[54,31,21,16],
[54,31,21,17],
[54,31,21,18],
[54,31,21,19],
[54,31,24,0],
[54,31,24,1],
[54,31,24,2],
[54,31,24,3],
[54,31,24,4],
[54,31,24,5],
[54,31,24,6],
[54,31,24,7],
[54,31,24,8],
[54,31,24,9],
[54,31,24,11],
[54,31,24,12],
[54,31,24,13],
[54,31,24,14],
[54,31,24,15],
[54,31,24,17],
[54,31,24,18],
[54,31,24,19],
[54,31,24,21],
[54,31,25,0],
[54,31,25,1],
[54,31,25,2],
[54,31,25,3],
[54,31,25,4],
[54,31,25,5],
[54,31,25,6],
[54,31,25,8],
[54,31,25,9],
[54,31,25,11],
[54,31,25,12],
[54,31,25,13],
[54,31,25,14],
[54,31,25,15],
[54,31,25,16],
[54,31,25,17],
[54,31,25,18],
[54,31,25,19],
[54,31,25,21],
[54,31,25,24],
[54,31,26,0],
[54,31,26,1],
[54,31,26,2],
[54,31,26,3],
[54,31,26,4],
[54,31,26,5],
[54,31,26,6],
[54,31,26,7],
[54,31,26,8],
[54,31,26,11],
[54,31,26,12],
[54,31,26,13],
[54,31,26,14],
[54,31,26,16],
[54,31,26,17],
[54,31,26,19],
[54,31,26,21],
[54,31,26,25],
[54,31,28,0],
[54,31,28,1],
[54,31,28,2],
[54,31,28,3],
[54,31,28,4],
[54,31,28,5],
[54,31,28,6],
[54,31,28,8],
[54,31,28,9],
[54,31,28,11],
[54,31,28,12],
[54,31,28,13],
[54,31,28,14],
[54,31,28,15],
[54,31,28,16],
[54,31,28,17],
[54,31,28,18],
[54,31,28,19],
[54,31,28,24],
[54,31,28,26],
[54,31,29,0],
[54,31,29,1],
[54,31,29,2],
[54,31,29,3],
[54,31,29,4],
[54,31,29,5],
[54,31,29,6],
[54,31,29,7],
[54,31,29,8],
[54,31,29,9],
[54,31,29,13],
[54,31,29,14],
[54,31,29,15],
[54,31,29,16],
[54,31,29,17],
[54,31,29,18],
[54,31,29,19],
[54,31,29,21],
[54,31,29,24],
[54,31,29,25],
[54,31,29,26],
[54,31,29,28],
[54,32,3,0],
[54,32,3,1],
[54,32,3,2],
[54,32,4,0],
[54,32,4,1],
[54,32,4,2],
[54,32,4,3],
[54,32,5,0],
[54,32,5,1],
[54,32,5,2],
[54,32,5,4],
[54,32,6,0],
[54,32,6,1],
[54,32,6,2],
[54,32,6,4],
[54,32,7,0],
[54,32,7,1],
[54,32,7,2],
[54,32,7,3],
[54,32,7,4],
[54,32,7,5],
[54,32,7,6],
[54,32,8,0],
[54,32,8,1],
[54,32,8,2],
[54,32,8,4],
[54,32,8,7],
[54,32,9,0],
[54,32,9,1],
[54,32,9,2],
[54,32,9,3],
[54,32,9,4],
[54,32,9,5],
[54,32,9,6],
[54,32,9,7],
[54,32,9,8],
[54,32,13,0],
[54,32,13,1],
[54,32,13,2],
[54,32,13,3],
[54,32,13,4],
[54,32,13,5],
[54,32,13,6],
[54,32,13,7],
[54,32,13,8],
[54,32,13,9],
[54,32,14,0],
[54,32,14,1],
[54,32,14,2],
[54,32,14,3],
[54,32,14,4],
[54,32,14,5],
[54,32,14,6],
[54,32,14,7],
[54,32,14,8],
[54,32,14,9],
[54,32,15,0],
[54,32,15,1],
[54,32,15,2],
[54,32,15,3],
[54,32,15,4],
[54,32,15,5],
[54,32,15,6],
[54,32,15,7],
[54,32,15,8],
[54,32,15,13],
[54,32,15,14],
[54,32,16,0],
[54,32,16,1],
[54,32,16,2],
[54,32,16,3],
[54,32,16,4],
[54,32,16,5],
[54,32,16,6],
[54,32,16,7],
[54,32,16,8],
[54,32,16,9],
[54,32,16,13],
[54,32,16,14],
[54,32,16,15],
[54,32,17,0],
[54,32,17,1],
[54,32,17,2],
[54,32,17,3],
[54,32,17,4],
[54,32,17,5],
[54,32,17,6],
[54,32,17,7],
[54,32,17,8],
[54,32,17,9],
[54,32,17,13],
[54,32,17,14],
[54,32,17,15],
[54,32,17,16],
[54,32,18,0],
[54,32,18,1],
[54,32,18,2],
[54,32,18,3],
[54,32,18,4],
[54,32,18,5],
[54,32,18,6],
[54,32,18,7],
[54,32,18,8],
[54,32,18,13],
[54,32,18,14],
[54,32,18,16],
[54,32,18,17],
[54,32,19,0],
[54,32,19,1],
[54,32,19,2],
[54,32,19,3],
[54,32,19,4],
[54,32,19,5],
[54,32,19,6],
[54,32,19,7],
[54,32,19,8],
[54,32,19,9],
[54,32,19,15],
[54,32,19,16],
[54,32,19,17],
[54,32,19,18],
[54,32,21,0],
[54,32,21,1],
[54,32,21,2],
[54,32,21,4],
[54,32,21,7],
[54,32,21,9],
[54,32,21,13],
[54,32,21,14],
[54,32,21,15],
[54,32,21,16],
[54,32,21,17],
[54,32,21,18],
[54,32,21,19],
[54,32,23,0],
[54,32,23,1],
[54,32,23,2],
[54,32,23,3],
[54,32,23,4],
[54,32,23,5],
[54,32,23,6],
[54,32,23,7],
[54,32,23,8],
[54,32,23,9],
[54,32,23,13],
[54,32,23,14],
[54,32,23,15],
[54,32,23,16],
[54,32,23,17],
[54,32,23,18],
[54,32,23,19],
[54,32,23,21],
[54,32,24,0],
[54,32,24,1],
[54,32,24,2],
[54,32,24,3],
[54,32,24,4],
[54,32,24,5],
[54,32,24,6],
[54,32,24,7],
[54,32,24,8],
[54,32,24,9],
[54,32,24,13],
[54,32,24,14],
[54,32,24,15],
[54,32,24,17],
[54,32,24,18],
[54,32,24,19],
[54,32,24,21],
[54,32,24,23],
[54,32,25,0],
[54,32,25,1],
[54,32,25,2],
[54,32,25,3],
[54,32,25,4],
[54,32,25,5],
[54,32,25,6],
[54,32,25,8],
[54,32,25,9],
[54,32,25,13],
[54,32,25,14],
[54,32,25,15],
[54,32,25,16],
[54,32,25,17],
[54,32,25,18],
[54,32,25,19],
[54,32,25,21],
[54,32,26,0],
[54,32,26,1],
[54,32,26,2],
[54,32,26,3],
[54,32,26,4],
[54,32,26,5],
[54,32,26,6],
[54,32,26,7],
[54,32,26,8],
[54,32,26,13],
[54,32,26,14],
[54,32,26,16],
[54,32,26,17],
[54,32,26,19],
[54,32,26,21],
[54,32,26,23],
[54,32,26,24],
[54,32,26,25],
[54,32,28,0],
[54,32,28,1],
[54,32,28,2],
[54,32,28,3],
[54,32,28,4],
[54,32,28,5],
[54,32,28,6],
[54,32,28,8],
[54,32,28,9],
[54,32,28,13],
[54,32,28,14],
[54,32,28,15],
[54,32,28,16],
[54,32,28,17],
[54,32,28,18],
[54,32,28,19],
[54,32,28,21],
[54,32,28,23],
[54,32,28,24],
[54,32,28,26],
[54,32,30,0],
[54,32,30,1],
[54,32,30,2],
[54,32,30,3],
[54,32,30,4],
[54,32,30,5],
[54,32,30,6],
[54,32,30,7],
[54,32,30,8],
[54,32,30,9],
[54,32,30,13],
[54,32,30,14],
[54,32,30,15],
[54,32,30,16],
[54,32,30,17],
[54,32,30,19],
[54,32,30,21],
[54,32,30,24],
[54,32,30,25],
[54,32,30,26],
[54,32,30,28],
[54,32,31,0],
[54,32,31,1],
[54,32,31,2],
[54,32,31,3],
[54,32,31,4],
[54,32,31,5],
[54,32,31,6],
[54,32,31,7],
[54,32,31,8],
[54,32,31,9],
[54,32,31,13],
[54,32,31,14],
[54,32,31,15],
[54,32,31,18],
[54,32,31,19],
[54,32,31,21],
[54,32,31,24],
[54,32,31,25],
[54,32,31,26],
[54,32,31,28],
[54,33,3,0],
[54,33,3,1],
[54,33,3,2],
[54,33,4,0],
[54,33,4,1],
[54,33,4,2],
[54,33,4,3],
[54,33,5,0],
[54,33,5,1],
[54,33,5,2],
[54,33,5,4],
[54,33,6,0],
[54,33,6,1],
[54,33,6,2],
[54,33,6,4],
[54,33,7,0],
[54,33,7,1],
[54,33,7,2],
[54,33,7,3],
[54,33,7,4],
[54,33,7,5],
[54,33,7,6],
[54,33,8,0],
[54,33,8,1],
[54,33,8,2],
[54,33,8,4],
[54,33,8,7],
[54,33,9,0],
[54,33,9,1],
[54,33,9,2],
[54,33,9,3],
[54,33,9,4],
[54,33,9,5],
[54,33,9,6],
[54,33,9,7],
[54,33,9,8],
[54,33,13,0],
[54,33,13,1],
[54,33,13,2],
[54,33,13,3],
[54,33,13,4],
[54,33,13,5],
[54,33,13,6],
[54,33,13,7],
[54,33,13,8],
[54,33,13,9],
[54,33,14,0],
[54,33,14,1],
[54,33,14,2],
[54,33,14,3],
[54,33,14,4],
[54,33,14,5],
[54,33,14,6],
[54,33,14,7],
[54,33,14,8],
[54,33,14,9],
[54,33,15,0],
[54,33,15,1],
[54,33,15,2],
[54,33,15,3],
[54,33,15,4],
[54,33,15,5],
[54,33,15,6],
[54,33,15,7],
[54,33,15,8],
[54,33,15,13],
[54,33,15,14],
[54,33,16,0],
[54,33,16,1],
[54,33,16,2],
[54,33,16,3],
[54,33,16,4],
[54,33,16,5],
[54,33,16,6],
[54,33,16,7],
[54,33,16,8],
[54,33,16,9],
[54,33,16,13],
[54,33,16,14],
[54,33,16,15],
[54,33,17,0],
[54,33,17,1],
[54,33,17,2],
[54,33,17,3],
[54,33,17,4],
[54,33,17,5],
[54,33,17,6],
[54,33,17,7],
[54,33,17,8],
[54,33,17,9],
[54,33,17,13],
[54,33,17,14],
[54,33,17,15],
[54,33,17,16],
[54,33,18,0],
[54,33,18,1],
[54,33,18,2],
[54,33,18,3],
[54,33,18,4],
[54,33,18,5],
[54,33,18,6],
[54,33,18,7],
[54,33,18,8],
[54,33,18,13],
[54,33,18,14],
[54,33,18,16],
[54,33,18,17],
[54,33,19,0],
[54,33,19,1],
[54,33,19,2],
[54,33,19,3],
[54,33,19,4],
[54,33,19,5],
[54,33,19,6],
[54,33,19,7],
[54,33,19,8],
[54,33,19,9],
[54,33,19,15],
[54,33,19,16],
[54,33,19,17],
[54,33,19,18],
[54,33,21,0],
[54,33,21,1],
[54,33,21,2],
[54,33,21,4],
[54,33,21,7],
[54,33,21,9],
[54,33,21,13],
[54,33,21,14],
[54,33,21,15],
[54,33,21,16],
[54,33,21,17],
[54,33,21,18],
[54,33,21,19],
[54,33,23,0],
[54,33,23,1],
[54,33,23,2],
[54,33,23,3],
[54,33,23,4],
[54,33,23,5],
[54,33,23,6],
[54,33,23,7],
[54,33,23,8],
[54,33,23,9],
[54,33,23,13],
[54,33,23,14],
[54,33,23,15],
[54,33,23,16],
[54,33,23,17],
[54,33,23,18],
[54,33,23,19],
[54,33,23,21],
[54,33,24,0],
[54,33,24,1],
[54,33,24,2],
[54,33,24,3],
[54,33,24,4],
[54,33,24,5],
[54,33,24,6],
[54,33,24,7],
[54,33,24,8],
[54,33,24,9],
[54,33,24,13],
[54,33,24,14],
[54,33,24,15],
[54,33,24,17],
[54,33,24,18],
[54,33,24,19],
[54,33,24,21],
[54,33,24,23],
[54,33,25,0],
[54,33,25,1],
[54,33,25,2],
[54,33,25,3],
[54,33,25,4],
[54,33,25,5],
[54,33,25,6],
[54,33,25,8],
[54,33,25,9],
[54,33,25,13],
[54,33,25,14],
[54,33,25,15],
[54,33,25,16],
[54,33,25,17],
[54,33,25,18],
[54,33,25,19],
[54,33,25,21],
[54,33,25,23],
[54,33,25,24],
[54,33,26,0],
[54,33,26,1],
[54,33,26,2],
[54,33,26,3],
[54,33,26,4],
[54,33,26,5],
[54,33,26,6],
[54,33,26,7],
[54,33,26,8],
[54,33,26,13],
[54,33,26,14],
[54,33,26,16],
[54,33,26,17],
[54,33,26,19],
[54,33,26,23],
[54,33,26,24],
[54,33,26,25],
[54,33,28,0],
[54,33,28,1],
[54,33,28,2],
[54,33,28,3],
[54,33,28,4],
[54,33,28,5],
[54,33,28,6],
[54,33,28,8],
[54,33,28,9],
[54,33,28,13],
[54,33,28,14],
[54,33,28,15],
[54,33,28,16],
[54,33,28,17],
[54,33,28,18],
[54,33,28,21],
[54,33,28,23],
[54,33,28,24],
[54,33,28,26],
[54,33,30,0],
[54,33,30,1],
[54,33,30,2],
[54,33,30,3],
[54,33,30,4],
[54,33,30,5],
[54,33,30,6],
[54,33,30,7],
[54,33,30,8],
[54,33,30,9],
[54,33,30,13],
[54,33,30,14],
[54,33,30,15],
[54,33,30,18],
[54,33,30,19],
[54,33,30,21],
[54,33,30,24],
[54,33,30,25],
[54,33,30,26],
[54,33,30,28],
[54,33,31,0],
[54,33,31,1],
[54,33,31,2],
[54,33,31,3],
[54,33,31,4],
[54,33,31,5],
[54,33,31,6],
[54,33,31,7],
[54,33,31,8],
[54,33,31,9],
[54,33,31,13],
[54,33,31,14],
[54,33,31,16],
[54,33,31,17],
[54,33,31,18],
[54,33,31,19],
[54,33,31,21],
[54,33,31,24],
[54,33,31,25],
[54,33,31,26],
[54,33,31,28],
[54,34,3,0],
[54,34,3,1],
[54,34,3,2],
[54,34,4,0],
[54,34,4,1],
[54,34,4,2],
[54,34,4,3],
[54,34,5,0],
[54,34,5,1],
[54,34,5,2],
[54,34,5,4],
[54,34,6,0],
[54,34,6,1],
[54,34,6,2],
[54,34,6,4],
[54,34,8,0],
[54,34,8,1],
[54,34,8,2],
[54,34,8,4],
[54,34,9,0],
[54,34,9,1],
[54,34,9,2],
[54,34,9,3],
[54,34,9,4],
[54,34,9,5],
[54,34,9,6],
[54,34,9,8],
[54,34,11,0],
[54,34,11,1],
[54,34,11,2],
[54,34,11,3],
[54,34,11,4],
[54,34,11,5],
[54,34,11,6],
[54,34,11,8],
[54,34,11,9],
[54,34,12,0],
[54,34,12,1],
[54,34,12,2],
[54,34,12,3],
[54,34,12,4],
[54,34,12,5],
[54,34,12,6],
[54,34,12,8],
[54,34,12,9],
[54,34,13,0],
[54,34,13,1],
[54,34,13,2],
[54,34,13,3],
[54,34,13,4],
[54,34,13,5],
[54,34,13,6],
[54,34,13,8],
[54,34,13,9],
[54,34,13,11],
[54,34,13,12],
[54,34,14,0],
[54,34,14,1],
[54,34,14,2],
[54,34,14,3],
[54,34,14,4],
[54,34,14,5],
[54,34,14,6],
[54,34,14,8],
[54,34,14,9],
[54,34,14,11],
[54,34,14,12],
[54,34,15,0],
[54,34,15,1],
[54,34,15,2],
[54,34,15,3],
[54,34,15,4],
[54,34,15,5],
[54,34,15,6],
[54,34,15,8],
[54,34,15,11],
[54,34,15,12],
[54,34,15,13],
[54,34,15,14],
[54,34,16,0],
[54,34,16,1],
[54,34,16,2],
[54,34,16,3],
[54,34,16,4],
[54,34,16,5],
[54,34,16,6],
[54,34,16,8],
[54,34,16,9],
[54,34,16,11],
[54,34,16,12],
[54,34,16,13],
[54,34,16,14],
[54,34,16,15],
[54,34,17,0],
[54,34,17,1],
[54,34,17,2],
[54,34,17,3],
[54,34,17,4],
[54,34,17,5],
[54,34,17,6],
[54,34,17,8],
[54,34,17,9],
[54,34,17,11],
[54,34,17,12],
[54,34,17,13],
[54,34,17,14],
[54,34,17,15],
[54,34,17,16],
[54,34,18,0],
[54,34,18,1],
[54,34,18,2],
[54,34,18,3],
[54,34,18,4],
[54,34,18,5],
[54,34,18,6],
[54,34,18,8],
[54,34,18,11],
[54,34,18,12],
[54,34,18,13],
[54,34,18,14],
[54,34,18,16],
[54,34,18,17],
[54,34,19,0],
[54,34,19,1],
[54,34,19,2],
[54,34,19,3],
[54,34,19,4],
[54,34,19,5],
[54,34,19,6],
[54,34,19,8],
[54,34,19,9],
[54,34,19,11],
[54,34,19,12],
[54,34,19,15],
[54,34,19,16],
[54,34,19,17],
[54,34,19,18],
[54,34,21,0],
[54,34,21,1],
[54,34,21,2],
[54,34,21,4],
[54,34,21,9],
[54,34,21,11],
[54,34,21,12],
[54,34,21,13],
[54,34,21,14],
[54,34,21,15],
[54,34,21,16],
[54,34,21,17],
[54,34,21,18],
[54,34,21,19],
[54,34,23,0],
[54,34,23,1],
[54,34,23,2],
[54,34,23,3],
[54,34,23,4],
[54,34,23,5],
[54,34,23,6],
[54,34,23,8],
[54,34,23,9],
[54,34,23,11],
[54,34,23,12],
[54,34,23,13],
[54,34,23,14],
[54,34,23,15],
[54,34,23,16],
[54,34,23,17],
[54,34,23,18],
[54,34,23,19],
[54,34,23,21],
[54,34,24,0],
[54,34,24,1],
[54,34,24,2],
[54,34,24,3],
[54,34,24,4],
[54,34,24,5],
[54,34,24,6],
[54,34,24,8],
[54,34,24,9],
[54,34,24,11],
[54,34,24,12],
[54,34,24,13],
[54,34,24,14],
[54,34,24,15],
[54,34,24,17],
[54,34,24,18],
[54,34,24,19],
[54,34,24,21],
[54,34,26,0],
[54,34,26,1],
[54,34,26,2],
[54,34,26,3],
[54,34,26,4],
[54,34,26,5],
[54,34,26,6],
[54,34,26,8],
[54,34,26,11],
[54,34,26,12],
[54,34,26,13],
[54,34,26,14],
[54,34,26,16],
[54,34,26,17],
[54,34,26,19],
[54,34,26,21],
[54,34,26,23],
[54,34,26,24],
[54,34,29,0],
[54,34,29,1],
[54,34,29,2],
[54,34,29,3],
[54,34,29,4],
[54,34,29,5],
[54,34,29,6],
[54,34,29,8],
[54,34,29,9],
[54,34,29,13],
[54,34,29,14],
[54,34,29,15],
[54,34,29,18],
[54,34,29,19],
[54,34,29,21],
[54,34,29,23],
[54,34,29,24],
[54,34,29,26],
[54,34,30,0],
[54,34,30,1],
[54,34,30,2],
[54,34,30,3],
[54,34,30,4],
[54,34,30,5],
[54,34,30,6],
[54,34,30,8],
[54,34,30,9],
[54,34,30,11],
[54,34,30,12],
[54,34,30,13],
[54,34,30,14],
[54,34,30,16],
[54,34,30,17],
[54,34,30,18],
[54,34,30,19],
[54,34,30,21],
[54,34,30,24],
[54,34,30,26],
[54,34,31,0],
[54,34,31,1],
[54,34,31,2],
[54,34,31,3],
[54,34,31,4],
[54,34,31,5],
[54,34,31,6],
[54,34,31,8],
[54,34,31,9],
[54,34,31,11],
[54,34,31,12],
[54,34,31,13],
[54,34,31,15],
[54,34,31,16],
[54,34,31,17],
[54,34,31,18],
[54,34,31,19],
[54,34,31,21],
[54,34,31,24],
[54,34,31,26],
[54,34,32,0],
[54,34,32,1],
[54,34,32,2],
[54,34,32,3],
[54,34,32,4],
[54,34,32,5],
[54,34,32,6],
[54,34,32,8],
[54,34,32,9],
[54,34,32,14],
[54,34,32,15],
[54,34,32,16],
[54,34,32,17],
[54,34,32,18],
[54,34,32,19],
[54,34,32,21],
[54,34,32,23],
[54,34,32,24],
[54,34,32,26],
[54,34,33,0],
[54,34,33,1],
[54,34,33,2],
[54,34,33,3],
[54,34,33,4],
[54,34,33,5],
[54,34,33,6],
[54,34,33,8],
[54,34,33,9],
[54,34,33,13],
[54,34,33,14],
[54,34,33,15],
[54,34,33,16],
[54,34,33,17],
[54,34,33,18],
[54,34,33,19],
[54,34,33,21],
[54,34,33,23],
[54,34,33,24],
[54,35,3,0],
[54,35,3,1],
[54,35,3,2],
[54,35,4,0],
[54,35,4,1],
[54,35,4,2],
[54,35,4,3],
[54,35,5,0],
[54,35,5,1],
[54,35,5,2],
[54,35,5,4],
[54,35,6,0],
[54,35,6,1],
[54,35,6,2],
[54,35,6,4],
[54,35,7,0],
[54,35,7,1],
[54,35,7,2],
[54,35,7,3],
[54,35,7,4],
[54,35,7,5],
[54,35,7,6],
[54,35,8,0],
[54,35,8,1],
[54,35,8,2],
[54,35,8,4],
[54,35,8,7],
[54,35,11,0],
[54,35,11,1],
[54,35,11,2],
[54,35,11,3],
[54,35,11,4],
[54,35,11,5],
[54,35,11,6],
[54,35,11,7],
[54,35,11,8],
[54,35,12,0],
[54,35,12,1],
[54,35,12,2],
[54,35,12,3],
[54,35,12,4],
[54,35,12,5],
[54,35,12,6],
[54,35,12,7],
[54,35,12,8],
[54,35,13,0],
[54,35,13,1],
[54,35,13,2],
[54,35,13,3],
[54,35,13,4],
[54,35,13,5],
[54,35,13,6],
[54,35,13,7],
[54,35,13,8],
[54,35,13,11],
[54,35,13,12],
[54,35,14,0],
[54,35,14,1],
[54,35,14,2],
[54,35,14,3],
[54,35,14,4],
[54,35,14,5],
[54,35,14,6],
[54,35,14,7],
[54,35,14,8],
[54,35,14,11],
[54,35,14,12],
[54,35,16,0],
[54,35,16,1],
[54,35,16,2],
[54,35,16,3],
[54,35,16,4],
[54,35,16,5],
[54,35,16,6],
[54,35,16,7],
[54,35,16,8],
[54,35,16,11],
[54,35,16,12],
[54,35,16,13],
[54,35,16,14],
[54,35,17,0],
[54,35,17,1],
[54,35,17,2],
[54,35,17,3],
[54,35,17,4],
[54,35,17,5],
[54,35,17,6],
[54,35,17,7],
[54,35,17,8],
[54,35,17,11],
[54,35,17,12],
[54,35,17,13],
[54,35,17,14],
[54,35,17,16],
[54,35,19,0],
[54,35,19,1],
[54,35,19,2],
[54,35,19,3],
[54,35,19,4],
[54,35,19,5],
[54,35,19,6],
[54,35,19,7],
[54,35,19,8],
[54,35,19,11],
[54,35,19,12],
[54,35,19,16],
[54,35,19,17],
[54,35,21,0],
[54,35,21,1],
[54,35,21,2],
[54,35,21,4],
[54,35,21,7],
[54,35,21,11],
[54,35,21,12],
[54,35,21,13],
[54,35,21,14],
[54,35,21,16],
[54,35,21,17],
[54,35,21,19],
[54,35,23,0],
[54,35,23,1],
[54,35,23,2],
[54,35,23,3],
[54,35,23,4],
[54,35,23,5],
[54,35,23,6],
[54,35,23,7],
[54,35,23,8],
[54,35,23,11],
[54,35,23,12],
[54,35,23,13],
[54,35,23,14],
[54,35,23,16],
[54,35,23,17],
[54,35,23,19],
[54,35,23,21],
[54,35,24,0],
[54,35,24,1],
[54,35,24,2],
[54,35,24,3],
[54,35,24,4],
[54,35,24,5],
[54,35,24,6],
[54,35,24,7],
[54,35,24,8],
[54,35,24,11],
[54,35,24,12],
[54,35,24,13],
[54,35,24,14],
[54,35,24,17],
[54,35,24,19],
[54,35,24,21],
[54,35,24,23],
[54,35,25,0],
[54,35,25,1],
[54,35,25,2],
[54,35,25,3],
[54,35,25,4],
[54,35,25,5],
[54,35,25,6],
[54,35,25,8],
[54,35,25,11],
[54,35,25,12],
[54,35,25,13],
[54,35,25,14],
[54,35,25,16],
[54,35,25,17],
[54,35,25,19],
[54,35,25,21],
[54,35,25,23],
[54,35,25,24],
[54,35,28,0],
[54,35,28,1],
[54,35,28,2],
[54,35,28,3],
[54,35,28,4],
[54,35,28,5],
[54,35,28,6],
[54,35,28,8],
[54,35,28,11],
[54,35,28,12],
[54,35,28,13],
[54,35,28,14],
[54,35,28,19],
[54,35,28,21],
[54,35,28,23],
[54,35,28,24],
[54,35,29,0],
[54,35,29,1],
[54,35,29,2],
[54,35,29,3],
[54,35,29,4],
[54,35,29,5],
[54,35,29,6],
[54,35,29,7],
[54,35,29,8],
[54,35,29,13],
[54,35,29,14],
[54,35,29,16],
[54,35,29,17],
[54,35,29,19],
[54,35,29,21],
[54,35,29,23],
[54,35,29,24],
[54,35,29,25],
[54,35,29,28],
[54,35,30,0],
[54,35,30,1],
[54,35,30,2],
[54,35,30,3],
[54,35,30,4],
[54,35,30,5],
[54,35,30,6],
[54,35,30,7],
[54,35,30,8],
[54,35,30,11],
[54,35,30,12],
[54,35,30,13],
[54,35,30,16],
[54,35,30,17],
[54,35,30,19],
[54,35,30,21],
[54,35,30,24],
[54,35,30,25],
[54,35,31,0],
[54,35,31,1],
[54,35,31,2],
[54,35,31,3],
[54,35,31,4],
[54,35,31,5],
[54,35,31,6],
[54,35,31,7],
[54,35,31,8],
[54,35,31,11],
[54,35,31,14],
[54,35,31,16],
[54,35,31,17],
[54,35,31,19],
[54,35,31,21],
[54,35,31,24],
[54,35,31,25],
[54,35,32,0],
[54,35,32,1],
[54,35,32,2],
[54,35,32,3],
[54,35,32,4],
[54,35,32,5],
[54,35,32,6],
[54,35,32,7],
[54,35,32,8],
[54,35,32,13],
[54,35,32,14],
[54,35,32,16],
[54,35,32,17],
[54,35,32,19],
[54,35,32,21],
[54,35,32,23],
[54,35,32,24],
[54,35,32,25],
[54,35,33,0],
[54,35,33,1],
[54,35,33,2],
[54,35,33,3],
[54,35,33,4],
[54,35,33,5],
[54,35,33,6],
[54,35,33,7],
[54,35,33,13],
[54,35,33,14],
[54,35,33,16],
[54,35,33,17],
[54,35,33,19],
[54,35,33,21],
[54,35,33,23],
[54,35,33,24],
[54,35,34,0],
[54,35,34,1],
[54,35,34,2],
[54,35,34,3],
[54,35,34,4],
[54,35,34,5],
[54,35,34,8],
[54,35,34,11],
[54,35,34,12],
[54,35,34,13],
[54,35,34,14],
[54,35,34,16],
[54,35,34,17],
[54,35,34,19],
[54,35,34,21],
[54,35,34,23],
[54,35,34,24],
[54,36,3,0],
[54,36,3,1],
[54,36,3,2],
[54,36,4,0],
[54,36,4,1],
[54,36,4,2],
[54,36,4,3],
[54,36,5,0],
[54,36,5,1],
[54,36,5,2],
[54,36,5,4],
[54,36,6,0],
[54,36,6,1],
[54,36,6,2],
[54,36,6,4],
[54,36,7,0],
[54,36,7,1],
[54,36,7,2],
[54,36,7,3],
[54,36,7,4],
[54,36,7,5],
[54,36,7,6],
[54,36,8,0],
[54,36,8,1],
[54,36,8,2],
[54,36,8,4],
[54,36,8,7],
[54,36,9,0],
[54,36,9,1],
[54,36,9,2],
[54,36,9,3],
[54,36,9,4],
[54,36,9,5],
[54,36,9,6],
[54,36,9,7],
[54,36,9,8],
[54,36,11,0],
[54,36,11,1],
[54,36,11,2],
[54,36,11,3],
[54,36,11,4],
[54,36,11,5],
[54,36,11,6],
[54,36,11,7],
[54,36,11,8],
[54,36,11,9],
[54,36,12,0],
[54,36,12,1],
[54,36,12,2],
[54,36,12,3],
[54,36,12,4],
[54,36,12,5],
[54,36,12,6],
[54,36,12,7],
[54,36,12,8],
[54,36,12,9],
[54,36,15,0],
[54,36,15,1],
[54,36,15,2],
[54,36,15,3],
[54,36,15,4],
[54,36,15,5],
[54,36,15,6],
[54,36,15,7],
[54,36,15,8],
[54,36,15,11],
[54,36,15,12],
[54,36,16,0],
[54,36,16,1],
[54,36,16,2],
[54,36,16,3],
[54,36,16,4],
[54,36,16,5],
[54,36,16,6],
[54,36,16,7],
[54,36,16,8],
[54,36,16,9],
[54,36,16,11],
[54,36,16,12],
[54,36,16,15],
[54,36,17,0],
[54,36,17,1],
[54,36,17,2],
[54,36,17,3],
[54,36,17,4],
[54,36,17,5],
[54,36,17,6],
[54,36,17,7],
[54,36,17,8],
[54,36,17,9],
[54,36,17,11],
[54,36,17,12],
[54,36,17,15],
[54,36,17,16],
[54,36,18,0],
[54,36,18,1],
[54,36,18,2],
[54,36,18,3],
[54,36,18,4],
[54,36,18,5],
[54,36,18,6],
[54,36,18,7],
[54,36,18,8],
[54,36,18,11],
[54,36,18,12],
[54,36,18,16],
[54,36,18,17],
[54,36,21,0],
[54,36,21,1],
[54,36,21,2],
[54,36,21,4],
[54,36,21,7],
[54,36,21,9],
[54,36,21,11],
[54,36,21,12],
[54,36,21,15],
[54,36,21,16],
[54,36,21,17],
[54,36,21,18],
[54,36,23,0],
[54,36,23,1],
[54,36,23,2],
[54,36,23,3],
[54,36,23,4],
[54,36,23,5],
[54,36,23,6],
[54,36,23,7],
[54,36,23,8],
[54,36,23,9],
[54,36,23,11],
[54,36,23,12],
[54,36,23,15],
[54,36,23,16],
[54,36,23,17],
[54,36,23,18],
[54,36,24,0],
[54,36,24,1],
[54,36,24,2],
[54,36,24,3],
[54,36,24,4],
[54,36,24,5],
[54,36,24,6],
[54,36,24,7],
[54,36,24,8],
[54,36,24,9],
[54,36,24,11],
[54,36,24,12],
[54,36,24,15],
[54,36,24,17],
[54,36,24,18],
[54,36,24,23],
[54,36,25,0],
[54,36,25,1],
[54,36,25,2],
[54,36,25,3],
[54,36,25,4],
[54,36,25,5],
[54,36,25,6],
[54,36,25,8],
[54,36,25,9],
[54,36,25,11],
[54,36,25,12],
[54,36,25,15],
[54,36,25,16],
[54,36,25,17],
[54,36,25,18],
[54,36,25,21],
[54,36,25,23],
[54,36,25,24],
[54,36,26,0],
[54,36,26,1],
[54,36,26,2],
[54,36,26,3],
[54,36,26,4],
[54,36,26,5],
[54,36,26,6],
[54,36,26,7],
[54,36,26,8],
[54,36,26,11],
[54,36,26,12],
[54,36,26,16],
[54,36,26,17],
[54,36,26,21],
[54,36,26,23],
[54,36,26,24],
[54,36,26,25],
[54,36,28,0],
[54,36,28,1],
[54,36,28,2],
[54,36,28,3],
[54,36,28,4],
[54,36,28,5],
[54,36,28,6],
[54,36,28,8],
[54,36,28,9],
[54,36,28,11],
[54,36,28,12],
[54,36,28,16],
[54,36,28,17],
[54,36,28,18],
[54,36,28,21],
[54,36,28,23],
[54,36,28,24],
[54,36,28,26],
[54,36,29,0],
[54,36,29,1],
[54,36,29,2],
[54,36,29,3],
[54,36,29,4],
[54,36,29,5],
[54,36,29,6],
[54,36,29,7],
[54,36,29,8],
[54,36,29,9],
[54,36,29,15],
[54,36,29,16],
[54,36,29,17],
[54,36,29,18],
[54,36,29,21],
[54,36,29,23],
[54,36,29,24],
[54,36,29,25],
[54,36,29,26],
[54,36,30,0],
[54,36,30,1],
[54,36,30,2],
[54,36,30,3],
[54,36,30,4],
[54,36,30,5],
[54,36,30,6],
[54,36,30,7],
[54,36,30,8],
[54,36,30,9],
[54,36,30,11],
[54,36,30,15],
[54,36,30,16],
[54,36,30,17],
[54,36,30,18],
[54,36,30,21],
[54,36,30,24],
[54,36,30,25],
[54,36,30,26],
[54,36,31,0],
[54,36,31,1],
[54,36,31,2],
[54,36,31,3],
[54,36,31,4],
[54,36,31,5],
[54,36,31,6],
[54,36,31,7],
[54,36,31,8],
[54,36,31,9],
[54,36,31,12],
[54,36,31,15],
[54,36,31,16],
[54,36,31,17],
[54,36,31,18],
[54,36,31,21],
[54,36,31,24],
[54,36,31,25],
[54,36,32,0],
[54,36,32,1],
[54,36,32,2],
[54,36,32,3],
[54,36,32,4],
[54,36,32,5],
[54,36,32,6],
[54,36,32,7],
[54,36,32,15],
[54,36,32,16],
[54,36,32,17],
[54,36,32,18],
[54,36,32,21],
[54,36,32,23],
[54,36,32,24],
[54,36,33,0],
[54,36,33,1],
[54,36,33,2],
[54,36,33,3],
[54,36,33,4],
[54,36,33,5],
[54,36,33,8],
[54,36,33,9],
[54,36,33,15],
[54,36,33,16],
[54,36,33,17],
[54,36,33,18],
[54,36,33,21],
[54,36,33,23],
[54,36,33,24],
[54,36,34,0],
[54,36,34,1],
[54,36,34,2],
[54,36,34,3],
[54,36,34,4],
[54,36,34,6],
[54,36,34,8],
[54,36,34,9],
[54,36,34,11],
[54,36,34,12],
[54,36,34,15],
[54,36,34,16],
[54,36,34,17],
[54,36,34,18],
[54,36,34,21],
[54,36,35,0],
[54,36,35,1],
[54,36,35,2],
[54,36,35,5],
[54,36,35,6],
[54,36,35,7],
[54,36,35,8],
[54,36,35,11],
[54,36,35,12],
[54,36,35,16],
[54,36,35,17],
[54,36,35,21],
[54,37,3,0],
[54,37,3,1],
[54,37,3,2],
[54,37,5,0],
[54,37,5,1],
[54,37,5,2],
[54,37,6,0],
[54,37,6,1],
[54,37,6,2],
[54,37,7,0],
[54,37,7,1],
[54,37,7,2],
[54,37,7,3],
[54,37,7,5],
[54,37,7,6],
[54,37,8,0],
[54,37,8,1],
[54,37,8,2],
[54,37,8,7],
[54,37,9,0],
[54,37,9,1],
[54,37,9,2],
[54,37,9,3],
[54,37,9,5],
[54,37,9,6],
[54,37,9,7],
[54,37,9,8],
[54,37,11,0],
[54,37,11,1],
[54,37,11,2],
[54,37,11,3],
[54,37,11,5],
[54,37,11,6],
[54,37,11,7],
[54,37,11,8],
[54,37,11,9],
[54,37,12,0],
[54,37,12,1],
[54,37,12,2],
[54,37,12,3],
[54,37,12,5],
[54,37,12,6],
[54,37,12,7],
[54,37,12,8],
[54,37,12,9],
[54,37,13,0],
[54,37,13,1],
[54,37,13,2],
[54,37,13,3],
[54,37,13,5],
[54,37,13,6],
[54,37,13,7],
[54,37,13,8],
[54,37,13,9],
[54,37,13,11],
[54,37,13,12],
[54,37,14,0],
[54,37,14,1],
[54,37,14,2],
[54,37,14,3],
[54,37,14,5],
[54,37,14,6],
[54,37,14,7],
[54,37,14,8],
[54,37,14,9],
[54,37,14,11],
[54,37,14,12],
[54,37,15,0],
[54,37,15,1],
[54,37,15,2],
[54,37,15,3],
[54,37,15,5],
[54,37,15,6],
[54,37,15,7],
[54,37,15,8],
[54,37,15,11],
[54,37,15,12],
[54,37,15,13],
[54,37,15,14],
[54,37,16,0],
[54,37,16,1],
[54,37,16,2],
[54,37,16,3],
[54,37,16,5],
[54,37,16,6],
[54,37,16,7],
[54,37,16,8],
[54,37,16,9],
[54,37,16,11],
[54,37,16,12],
[54,37,16,13],
[54,37,16,14],
[54,37,16,15],
[54,37,17,0],
[54,37,17,1],
[54,37,17,2],
[54,37,17,3],
[54,37,17,5],
[54,37,17,6],
[54,37,17,7],
[54,37,17,8],
[54,37,17,9],
[54,37,17,11],
[54,37,17,12],
[54,37,17,13],
[54,37,17,14],
[54,37,17,15],
[54,37,17,16],
[54,37,18,0],
[54,37,18,1],
[54,37,18,2],
[54,37,18,3],
[54,37,18,5],
[54,37,18,6],
[54,37,18,7],
[54,37,18,8],
[54,37,18,11],
[54,37,18,12],
[54,37,18,13],
[54,37,18,14],
[54,37,18,16],
[54,37,18,17],
[54,37,19,0],
[54,37,19,1],
[54,37,19,2],
[54,37,19,3],
[54,37,19,5],
[54,37,19,6],
[54,37,19,7],
[54,37,19,8],
[54,37,19,9],
[54,37,19,11],
[54,37,19,12],
[54,37,19,15],
[54,37,19,16],
[54,37,19,17],
[54,37,19,18],
[54,37,21,0],
[54,37,21,1],
[54,37,21,2],
[54,37,21,7],
[54,37,21,9],
[54,37,21,11],
[54,37,21,12],
[54,37,21,13],
[54,37,21,14],
[54,37,21,15],
[54,37,21,16],
[54,37,21,17],
[54,37,21,18],
[54,37,21,19],
[54,37,23,0],
[54,37,23,1],
[54,37,23,2],
[54,37,23,3],
[54,37,23,5],
[54,37,23,6],
[54,37,23,7],
[54,37,23,8],
[54,37,23,9],
[54,37,23,11],
[54,37,23,12],
[54,37,23,13],
[54,37,23,14],
[54,37,23,15],
[54,37,23,16],
[54,37,23,17],
[54,37,23,18],
[54,37,23,19],
[54,37,23,21],
[54,37,24,0],
[54,37,24,1],
[54,37,24,2],
[54,37,24,3],
[54,37,24,5],
[54,37,24,6],
[54,37,24,7],
[54,37,24,8],
[54,37,24,9],
[54,37,24,11],
[54,37,24,12],
[54,37,24,13],
[54,37,24,14],
[54,37,24,15],
[54,37,24,17],
[54,37,24,18],
[54,37,24,19],
[54,37,24,21],
[54,37,24,23],
[54,37,25,0],
[54,37,25,1],
[54,37,25,2],
[54,37,25,3],
[54,37,25,5],
[54,37,25,6],
[54,37,25,8],
[54,37,25,9],
[54,37,25,11],
[54,37,25,12],
[54,37,25,13],
[54,37,25,14],
[54,37,25,15],
[54,37,25,16],
[54,37,25,17],
[54,37,25,19],
[54,37,25,21],
[54,37,25,23],
[54,37,25,24],
[54,37,26,0],
[54,37,26,1],
[54,37,26,2],
[54,37,26,3],
[54,37,26,5],
[54,37,26,6],
[54,37,26,7],
[54,37,26,8],
[54,37,26,11],
[54,37,26,12],
[54,37,26,13],
[54,37,26,14],
[54,37,26,19],
[54,37,26,21],
[54,37,26,23],
[54,37,26,24],
[54,37,26,25],
[54,37,28,0],
[54,37,28,1],
[54,37,28,2],
[54,37,28,3],
[54,37,28,5],
[54,37,28,6],
[54,37,28,8],
[54,37,28,9],
[54,37,28,11],
[54,37,28,12],
[54,37,28,13],
[54,37,28,15],
[54,37,28,16],
[54,37,28,17],
[54,37,28,18],
[54,37,28,19],
[54,37,28,21],
[54,37,28,23],
[54,37,28,24],
[54,37,28,26],
[54,37,29,0],
[54,37,29,1],
[54,37,29,2],
[54,37,29,3],
[54,37,29,5],
[54,37,29,6],
[54,37,29,7],
[54,37,29,8],
[54,37,29,9],
[54,37,29,14],
[54,37,29,15],
[54,37,29,16],
[54,37,29,17],
[54,37,29,18],
[54,37,29,19],
[54,37,29,21],
[54,37,29,23],
[54,37,29,24],
[54,37,29,25],
[54,37,29,26],
[54,37,30,0],
[54,37,30,1],
[54,37,30,2],
[54,37,30,3],
[54,37,30,5],
[54,37,30,6],
[54,37,30,7],
[54,37,30,8],
[54,37,30,9],
[54,37,30,12],
[54,37,30,13],
[54,37,30,14],
[54,37,30,15],
[54,37,30,16],
[54,37,30,17],
[54,37,30,18],
[54,37,30,19],
[54,37,30,21],
[54,37,30,24],
[54,37,30,25],
[54,37,31,0],
[54,37,31,1],
[54,37,31,2],
[54,37,31,3],
[54,37,31,5],
[54,37,31,6],
[54,37,31,7],
[54,37,31,11],
[54,37,31,12],
[54,37,31,13],
[54,37,31,14],
[54,37,31,15],
[54,37,31,16],
[54,37,31,17],
[54,37,31,18],
[54,37,31,19],
[54,37,31,21],
[54,37,31,24],
[54,37,32,0],
[54,37,32,1],
[54,37,32,2],
[54,37,32,3],
[54,37,32,5],
[54,37,32,8],
[54,37,32,9],
[54,37,32,13],
[54,37,32,14],
[54,37,32,15],
[54,37,32,16],
[54,37,32,17],
[54,37,32,18],
[54,37,32,19],
[54,37,32,21],
[54,37,32,23],
[54,37,32,24],
[54,37,33,0],
[54,37,33,1],
[54,37,33,2],
[54,37,33,3],
[54,37,33,6],
[54,37,33,7],
[54,37,33,8],
[54,37,33,9],
[54,37,33,13],
[54,37,33,14],
[54,37,33,15],
[54,37,33,16],
[54,37,33,17],
[54,37,33,18],
[54,37,33,19],
[54,37,33,21],
[54,37,34,0],
[54,37,34,1],
[54,37,34,2],
[54,37,34,5],
[54,37,34,6],
[54,37,34,8],
[54,37,34,9],
[54,37,34,11],
[54,37,34,12],
[54,37,34,13],
[54,37,34,14],
[54,37,34,15],
[54,37,34,16],
[54,37,34,17],
[54,37,34,18],
[54,37,34,19],
[54,37,34,21],
[54,37,35,3],
[54,37,35,5],
[54,37,35,6],
[54,37,35,7],
[54,37,35,8],
[54,37,35,11],
[54,37,35,12],
[54,37,35,13],
[54,37,35,14],
[54,37,35,16],
[54,37,35,17],
[54,37,35,19],
[54,37,36,0],
[54,37,36,1],
[54,37,36,2],
[54,37,36,3],
[54,37,36,5],
[54,37,36,6],
[54,37,36,7],
[54,37,36,8],
[54,37,36,9],
[54,37,36,11],
[54,37,36,12],
[54,37,36,15],
[54,37,36,16],
[54,37,36,17],
[54,37,36,18],
[54,38,3,0],
[54,38,3,1],
[54,38,3,2],
[54,38,4,0],
[54,38,4,1],
[54,38,4,2],
[54,38,4,3],
[54,38,5,0],
[54,38,5,1],
[54,38,5,2],
[54,38,5,4],
[54,38,6,0],
[54,38,6,1],
[54,38,6,2],
[54,38,6,4],
[54,38,8,0],
[54,38,8,1],
[54,38,8,2],
[54,38,8,4],
[54,38,9,0],
[54,38,9,1],
[54,38,9,2],
[54,38,9,3],
[54,38,9,4],
[54,38,9,5],
[54,38,9,6],
[54,38,9,8],
[54,38,11,0],
[54,38,11,1],
[54,38,11,2],
[54,38,11,3],
[54,38,11,4],
[54,38,11,5],
[54,38,11,6],
[54,38,11,8],
[54,38,11,9],
[54,38,12,0],
[54,38,12,1],
[54,38,12,2],
[54,38,12,3],
[54,38,12,4],
[54,38,12,5],
[54,38,12,6],
[54,38,12,8],
[54,38,12,9],
[54,38,13,0],
[54,38,13,1],
[54,38,13,2],
[54,38,13,3],
[54,38,13,4],
[54,38,13,5],
[54,38,13,6],
[54,38,13,8],
[54,38,13,9],
[54,38,13,11],
[54,38,13,12],
[54,38,14,0],
[54,38,14,1],
[54,38,14,2],
[54,38,14,3],
[54,38,14,4],
[54,38,14,5],
[54,38,14,6],
[54,38,14,8],
[54,38,14,9],
[54,38,14,11],
[54,38,14,12],
[54,38,15,0],
[54,38,15,1],
[54,38,15,2],
[54,38,15,3],
[54,38,15,4],
[54,38,15,5],
[54,38,15,6],
[54,38,15,8],
[54,38,15,11],
[54,38,15,12],
[54,38,15,13],
[54,38,15,14],
[54,38,16,0],
[54,38,16,1],
[54,38,16,2],
[54,38,16,3],
[54,38,16,4],
[54,38,16,5],
[54,38,16,6],
[54,38,16,8],
[54,38,16,9],
[54,38,16,11],
[54,38,16,12],
[54,38,16,13],
[54,38,16,14],
[54,38,16,15],
[54,38,17,0],
[54,38,17,1],
[54,38,17,2],
[54,38,17,3],
[54,38,17,4],
[54,38,17,5],
[54,38,17,6],
[54,38,17,8],
[54,38,17,9],
[54,38,17,11],
[54,38,17,12],
[54,38,17,13],
[54,38,17,14],
[54,38,17,15],
[54,38,17,16],
[54,38,18,0],
[54,38,18,1],
[54,38,18,2],
[54,38,18,3],
[54,38,18,4],
[54,38,18,5],
[54,38,18,6],
[54,38,18,8],
[54,38,18,11],
[54,38,18,12],
[54,38,18,13],
[54,38,18,14],
[54,38,18,16],
[54,38,18,17],
[54,38,19,0],
[54,38,19,1],
[54,38,19,2],
[54,38,19,3],
[54,38,19,4],
[54,38,19,5],
[54,38,19,6],
[54,38,19,8],
[54,38,19,9],
[54,38,19,11],
[54,38,19,12],
[54,38,19,15],
[54,38,19,16],
[54,38,19,17],
[54,38,19,18],
[54,38,21,0],
[54,38,21,1],
[54,38,21,2],
[54,38,21,4],
[54,38,21,9],
[54,38,21,11],
[54,38,21,12],
[54,38,21,13],
[54,38,21,14],
[54,38,21,15],
[54,38,21,16],
[54,38,21,17],
[54,38,21,18],
[54,38,21,19],
[54,38,23,0],
[54,38,23,1],
[54,38,23,2],
[54,38,23,3],
[54,38,23,4],
[54,38,23,5],
[54,38,23,6],
[54,38,23,8],
[54,38,23,9],
[54,38,23,11],
[54,38,23,12],
[54,38,23,13],
[54,38,23,14],
[54,38,23,15],
[54,38,23,16],
[54,38,23,17],
[54,38,23,18],
[54,38,23,21],
[54,38,24,0],
[54,38,24,1],
[54,38,24,2],
[54,38,24,3],
[54,38,24,4],
[54,38,24,5],
[54,38,24,6],
[54,38,24,8],
[54,38,24,9],
[54,38,24,11],
[54,38,24,12],
[54,38,24,13],
[54,38,24,14],
[54,38,24,15],
[54,38,24,17],
[54,38,24,18],
[54,38,24,21],
[54,38,24,23],
[54,38,26,0],
[54,38,26,1],
[54,38,26,2],
[54,38,26,3],
[54,38,26,4],
[54,38,26,5],
[54,38,26,6],
[54,38,26,8],
[54,38,26,11],
[54,38,26,12],
[54,38,26,13],
[54,38,26,14],
[54,38,26,16],
[54,38,26,17],
[54,38,26,19],
[54,38,26,21],
[54,38,26,23],
[54,38,26,24],
[54,38,29,0],
[54,38,29,1],
[54,38,29,2],
[54,38,29,3],
[54,38,29,4],
[54,38,29,5],
[54,38,29,6],
[54,38,29,8],
[54,38,29,9],
[54,38,29,13],
[54,38,29,14],
[54,38,29,15],
[54,38,29,16],
[54,38,29,17],
[54,38,29,18],
[54,38,29,19],
[54,38,29,21],
[54,38,29,23],
[54,38,29,24],
[54,38,30,0],
[54,38,30,1],
[54,38,30,2],
[54,38,30,3],
[54,38,30,4],
[54,38,30,5],
[54,38,30,6],
[54,38,30,11],
[54,38,30,12],
[54,38,30,13],
[54,38,30,14],
[54,38,30,15],
[54,38,30,16],
[54,38,30,17],
[54,38,30,18],
[54,38,30,19],
[54,38,30,21],
[54,38,30,24],
[54,38,31,0],
[54,38,31,1],
[54,38,31,2],
[54,38,31,3],
[54,38,31,4],
[54,38,31,5],
[54,38,31,8],
[54,38,31,9],
[54,38,31,11],
[54,38,31,12],
[54,38,31,13],
[54,38,31,14],
[54,38,31,15],
[54,38,31,16],
[54,38,31,17],
[54,38,31,18],
[54,38,31,19],
[54,38,31,21],
[54,38,31,24],
[54,38,32,0],
[54,38,32,1],
[54,38,32,2],
[54,38,32,3],
[54,38,32,4],
[54,38,32,6],
[54,38,32,8],
[54,38,32,9],
[54,38,32,13],
[54,38,32,14],
[54,38,32,15],
[54,38,32,16],
[54,38,32,17],
[54,38,32,18],
[54,38,32,19],
[54,38,32,21],
[54,38,33,0],
[54,38,33,1],
[54,38,33,2],
[54,38,33,5],
[54,38,33,6],
[54,38,33,8],
[54,38,33,9],
[54,38,33,13],
[54,38,33,14],
[54,38,33,15],
[54,38,33,16],
[54,38,33,17],
[54,38,33,18],
[54,38,33,19],
[54,38,33,21],
[54,38,35,0],
[54,38,35,1],
[54,38,35,2],
[54,38,35,3],
[54,38,35,4],
[54,38,35,5],
[54,38,35,6],
[54,38,35,8],
[54,38,35,11],
[54,38,35,12],
[54,38,35,13],
[54,38,35,14],
[54,38,35,16],
[54,38,35,17],
[54,38,35,19],
[54,38,36,0],
[54,38,36,1],
[54,38,36,2],
[54,38,36,3],
[54,38,36,4],
[54,38,36,5],
[54,38,36,6],
[54,38,36,8],
[54,38,36,9],
[54,38,36,11],
[54,38,36,12],
[54,38,36,15],
[54,38,36,16],
[54,38,36,17],
[54,38,36,18],
[54,38,37,0],
[54,38,37,1],
[54,38,37,2],
[54,38,37,3],
[54,38,37,5],
[54,38,37,6],
[54,38,37,8],
[54,38,37,9],
[54,38,37,11],
[54,38,37,12],
[54,38,37,13],
[54,38,37,14],
[54,38,37,15],
[54,38,37,16],
[54,38,37,17],
[54,39,4,0],
[54,39,4,1],
[54,39,4,2],
[54,39,7,0],
[54,39,7,1],
[54,39,7,2],
[54,39,7,4],
[54,39,9,0],
[54,39,9,1],
[54,39,9,2],
[54,39,9,4],
[54,39,9,7],
[54,39,11,0],
[54,39,11,1],
[54,39,11,2],
[54,39,11,4],
[54,39,11,7],
[54,39,11,9],
[54,39,12,0],
[54,39,12,1],
[54,39,12,2],
[54,39,12,4],
[54,39,12,7],
[54,39,12,9],
[54,39,13,0],
[54,39,13,1],
[54,39,13,2],
[54,39,13,4],
[54,39,13,7],
[54,39,13,9],
[54,39,13,11],
[54,39,13,12],
[54,39,14,0],
[54,39,14,1],
[54,39,14,2],
[54,39,14,4],
[54,39,14,7],
[54,39,14,9],
[54,39,14,11],
[54,39,14,12],
[54,39,15,0],
[54,39,15,1],
[54,39,15,2],
[54,39,15,4],
[54,39,15,7],
[54,39,15,11],
[54,39,15,12],
[54,39,15,13],
[54,39,15,14],
[54,39,16,0],
[54,39,16,1],
[54,39,16,2],
[54,39,16,4],
[54,39,16,7],
[54,39,16,9],
[54,39,16,11],
[54,39,16,12],
[54,39,16,13],
[54,39,16,14],
[54,39,16,15],
[54,39,17,0],
[54,39,17,1],
[54,39,17,2],
[54,39,17,4],
[54,39,17,7],
[54,39,17,9],
[54,39,17,11],
[54,39,17,12],
[54,39,17,13],
[54,39,17,14],
[54,39,17,15],
[54,39,17,16],
[54,39,18,0],
[54,39,18,1],
[54,39,18,2],
[54,39,18,4],
[54,39,18,7],
[54,39,18,11],
[54,39,18,12],
[54,39,18,13],
[54,39,18,14],
[54,39,18,16],
[54,39,18,17],
[54,39,19,0],
[54,39,19,1],
[54,39,19,2],
[54,39,19,4],
[54,39,19,7],
[54,39,19,9],
[54,39,19,11],
[54,39,19,12],
[54,39,19,15],
[54,39,19,16],
[54,39,19,17],
[54,39,19,18],
[54,39,23,0],
[54,39,23,1],
[54,39,23,2],
[54,39,23,4],
[54,39,23,7],
[54,39,23,9],
[54,39,23,11],
[54,39,23,12],
[54,39,23,13],
[54,39,23,14],
[54,39,23,15],
[54,39,23,16],
[54,39,23,17],
[54,39,23,19],
[54,39,24,0],
[54,39,24,1],
[54,39,24,2],
[54,39,24,4],
[54,39,24,7],
[54,39,24,9],
[54,39,24,11],
[54,39,24,12],
[54,39,24,13],
[54,39,24,14],
[54,39,24,15],
[54,39,24,17],
[54,39,24,19],
[54,39,24,23],
[54,39,25,0],
[54,39,25,1],
[54,39,25,2],
[54,39,25,4],
[54,39,25,9],
[54,39,25,11],
[54,39,25,12],
[54,39,25,13],
[54,39,25,14],
[54,39,25,16],
[54,39,25,17],
[54,39,25,18],
[54,39,25,19],
[54,39,25,23],
[54,39,25,24],
[54,39,26,0],
[54,39,26,1],
[54,39,26,2],
[54,39,26,4],
[54,39,26,7],
[54,39,26,11],
[54,39,26,12],
[54,39,26,13],
[54,39,26,16],
[54,39,26,17],
[54,39,26,19],
[54,39,26,23],
[54,39,26,24],
[54,39,26,25],
[54,39,28,0],
[54,39,28,1],
[54,39,28,2],
[54,39,28,4],
[54,39,28,9],
[54,39,28,12],
[54,39,28,13],
[54,39,28,14],
[54,39,28,15],
[54,39,28,16],
[54,39,28,17],
[54,39,28,18],
[54,39,28,19],
[54,39,28,23],
[54,39,28,24],
[54,39,29,0],
[54,39,29,1],
[54,39,29,2],
[54,39,29,4],
[54,39,29,7],
[54,39,29,13],
[54,39,29,14],
[54,39,29,15],
[54,39,29,16],
[54,39,29,17],
[54,39,29,18],
[54,39,29,19],
[54,39,29,23],
[54,39,29,24],
[54,39,30,0],
[54,39,30,1],
[54,39,30,2],
[54,39,30,4],
[54,39,30,9],
[54,39,30,11],
[54,39,30,12],
[54,39,30,13],
[54,39,30,14],
[54,39,30,15],
[54,39,30,16],
[54,39,30,17],
[54,39,30,18],
[54,39,30,19],
[54,39,30,24],
[54,39,31,0],
[54,39,31,1],
[54,39,31,2],
[54,39,31,4],
[54,39,31,7],
[54,39,31,9],
[54,39,31,11],
[54,39,31,12],
[54,39,31,13],
[54,39,31,14],
[54,39,31,15],
[54,39,31,16],
[54,39,31,17],
[54,39,31,18],
[54,39,31,19],
[54,39,32,0],
[54,39,32,1],
[54,39,32,2],
[54,39,32,7],
[54,39,32,9],
[54,39,32,13],
[54,39,32,14],
[54,39,32,15],
[54,39,32,16],
[54,39,32,17],
[54,39,32,18],
[54,39,32,19],
[54,39,33,4],
[54,39,33,7],
[54,39,33,9],
[54,39,33,13],
[54,39,33,14],
[54,39,33,15],
[54,39,33,16],
[54,39,33,17],
[54,39,33,18],
[54,39,33,19],
[54,39,34,0],
[54,39,34,1],
[54,39,34,2],
[54,39,34,4],
[54,39,34,9],
[54,39,34,11],
[54,39,34,12],
[54,39,34,13],
[54,39,34,14],
[54,39,34,15],
[54,39,34,16],
[54,39,34,17],
[54,39,34,18],
[54,39,34,19],
[54,39,35,0],
[54,39,35,1],
[54,39,35,2],
[54,39,35,4],
[54,39,35,7],
[54,39,35,11],
[54,39,35,12],
[54,39,35,13],
[54,39,35,14],
[54,39,35,16],
[54,39,35,17],
[54,39,36,0],
[54,39,36,1],
[54,39,36,2],
[54,39,36,4],
[54,39,36,7],
[54,39,36,9],
[54,39,36,11],
[54,39,36,12],
[54,39,36,15],
[54,39,36,16],
[54,39,36,17],
[54,39,37,0],
[54,39,37,1],
[54,39,37,2],
[54,39,37,7],
[54,39,37,9],
[54,39,37,11],
[54,39,37,12],
[54,39,37,13],
[54,39,37,14],
[54,39,37,15],
[54,39,38,0],
[54,39,38,1],
[54,39,38,2],
[54,39,38,4],
[54,39,38,9],
[54,39,38,11],
[54,39,38,12],
[54,39,38,13],
[54,39,38,14],
[54,40,3,0],
[54,40,3,1],
[54,40,3,2],
[54,40,4,0],
[54,40,4,1],
[54,40,4,2],
[54,40,4,3],
[54,40,5,0],
[54,40,5,1],
[54,40,5,2],
[54,40,5,4],
[54,40,6,0],
[54,40,6,1],
[54,40,6,2],
[54,40,6,4],
[54,40,7,0],
[54,40,7,1],
[54,40,7,2],
[54,40,7,3],
[54,40,7,4],
[54,40,7,5],
[54,40,7,6],
[54,40,8,0],
[54,40,8,1],
[54,40,8,2],
[54,40,8,4],
[54,40,8,7],
[54,40,11,0],
[54,40,11,1],
[54,40,11,2],
[54,40,11,3],
[54,40,11,4],
[54,40,11,5],
[54,40,11,6],
[54,40,11,7],
[54,40,11,8],
[54,40,12,0],
[54,40,12,1],
[54,40,12,2],
[54,40,12,3],
[54,40,12,4],
[54,40,12,5],
[54,40,12,6],
[54,40,12,7],
[54,40,12,8],
[54,40,13,0],
[54,40,13,1],
[54,40,13,2],
[54,40,13,3],
[54,40,13,4],
[54,40,13,5],
[54,40,13,6],
[54,40,13,7],
[54,40,13,8],
[54,40,13,11],
[54,40,13,12],
[54,40,14,0],
[54,40,14,1],
[54,40,14,2],
[54,40,14,3],
[54,40,14,4],
[54,40,14,5],
[54,40,14,6],
[54,40,14,7],
[54,40,14,8],
[54,40,14,11],
[54,40,14,12],
[54,40,16,0],
[54,40,16,1],
[54,40,16,2],
[54,40,16,3],
[54,40,16,4],
[54,40,16,5],
[54,40,16,6],
[54,40,16,7],
[54,40,16,8],
[54,40,16,11],
[54,40,16,12],
[54,40,16,13],
[54,40,16,14],
[54,40,17,0],
[54,40,17,1],
[54,40,17,2],
[54,40,17,3],
[54,40,17,4],
[54,40,17,5],
[54,40,17,6],
[54,40,17,7],
[54,40,17,8],
[54,40,17,11],
[54,40,17,12],
[54,40,17,13],
[54,40,17,14],
[54,40,17,16],
[54,40,19,0],
[54,40,19,1],
[54,40,19,2],
[54,40,19,3],
[54,40,19,4],
[54,40,19,5],
[54,40,19,6],
[54,40,19,7],
[54,40,19,8],
[54,40,19,11],
[54,40,19,12],
[54,40,19,16],
[54,40,19,17],
[54,40,21,0],
[54,40,21,1],
[54,40,21,2],
[54,40,21,4],
[54,40,21,7],
[54,40,21,11],
[54,40,21,12],
[54,40,21,13],
[54,40,21,14],
[54,40,21,16],
[54,40,21,17],
[54,40,23,0],
[54,40,23,1],
[54,40,23,2],
[54,40,23,3],
[54,40,23,4],
[54,40,23,5],
[54,40,23,6],
[54,40,23,7],
[54,40,23,8],
[54,40,23,11],
[54,40,23,12],
[54,40,23,13],
[54,40,23,14],
[54,40,23,19],
[54,40,23,21],
[54,40,24,0],
[54,40,24,1],
[54,40,24,2],
[54,40,24,3],
[54,40,24,4],
[54,40,24,5],
[54,40,24,6],
[54,40,24,7],
[54,40,24,8],
[54,40,24,11],
[54,40,24,12],
[54,40,24,13],
[54,40,24,14],
[54,40,24,19],
[54,40,24,21],
[54,40,24,23],
[54,40,25,0],
[54,40,25,1],
[54,40,25,2],
[54,40,25,3],
[54,40,25,4],
[54,40,25,5],
[54,40,25,6],
[54,40,25,8],
[54,40,25,11],
[54,40,25,12],
[54,40,25,13],
[54,40,25,16],
[54,40,25,17],
[54,40,25,19],
[54,40,25,21],
[54,40,25,23],
[54,40,25,24],
[54,40,28,0],
[54,40,28,1],
[54,40,28,2],
[54,40,28,3],
[54,40,28,4],
[54,40,28,5],
[54,40,28,6],
[54,40,28,11],
[54,40,28,12],
[54,40,28,13],
[54,40,28,14],
[54,40,28,16],
[54,40,28,17],
[54,40,28,19],
[54,40,28,21],
[54,40,28,23],
[54,40,28,24],
[54,40,29,0],
[54,40,29,1],
[54,40,29,2],
[54,40,29,3],
[54,40,29,4],
[54,40,29,5],
[54,40,29,8],
[54,40,29,13],
[54,40,29,14],
[54,40,29,16],
[54,40,29,17],
[54,40,29,19],
[54,40,29,21],
[54,40,29,23],
[54,40,29,24],
[54,40,30,0],
[54,40,30,1],
[54,40,30,2],
[54,40,30,3],
[54,40,30,4],
[54,40,30,6],
[54,40,30,7],
[54,40,30,8],
[54,40,30,11],
[54,40,30,12],
[54,40,30,13],
[54,40,30,14],
[54,40,30,16],
[54,40,30,17],
[54,40,30,19],
[54,40,30,21],
[54,40,31,0],
[54,40,31,1],
[54,40,31,2],
[54,40,31,5],
[54,40,31,6],
[54,40,31,7],
[54,40,31,8],
[54,40,31,11],
[54,40,31,12],
[54,40,31,13],
[54,40,31,14],
[54,40,31,16],
[54,40,31,17],
[54,40,31,19],
[54,40,31,21],
[54,40,32,3],
[54,40,32,4],
[54,40,32,5],
[54,40,32,6],
[54,40,32,7],
[54,40,32,8],
[54,40,32,13],
[54,40,32,14],
[54,40,32,16],
[54,40,32,17],
[54,40,32,19],
[54,40,33,0],
[54,40,33,1],
[54,40,33,2],
[54,40,33,3],
[54,40,33,4],
[54,40,33,5],
[54,40,33,6],
[54,40,33,7],
[54,40,33,8],
[54,40,33,13],
[54,40,33,14],
[54,40,33,16],
[54,40,33,17],
[54,40,33,19],
[54,40,34,0],
[54,40,34,1],
[54,40,34,2],
[54,40,34,3],
[54,40,34,4],
[54,40,34,5],
[54,40,34,6],
[54,40,34,8],
[54,40,34,11],
[54,40,34,12],
[54,40,34,13],
[54,40,34,14],
[54,40,34,16],
[54,40,34,17],
[54,40,36,0],
[54,40,36,1],
[54,40,36,2],
[54,40,36,3],
[54,40,36,4],
[54,40,36,5],
[54,40,36,6],
[54,40,36,7],
[54,40,36,8],
[54,40,36,11],
[54,40,36,12],
[54,40,37,0],
[54,40,37,1],
[54,40,37,2],
[54,40,37,3],
[54,40,37,5],
[54,40,37,6],
[54,40,37,7],
[54,40,37,8],
[54,40,37,11],
[54,40,37,12],
[54,40,37,13],
[54,40,37,14],
[54,40,38,0],
[54,40,38,1],
[54,40,38,2],
[54,40,38,3],
[54,40,38,4],
[54,40,38,5],
[54,40,38,6],
[54,40,38,8],
[54,40,38,11],
[54,40,38,12],
[54,40,38,13],
[54,40,39,0],
[54,40,39,1],
[54,40,39,2],
[54,40,39,4],
[54,40,39,7],
[54,40,39,11],
[54,41,3,0],
[54,41,3,1],
[54,41,3,2],
[54,41,4,0],
[54,41,4,1],
[54,41,4,2],
[54,41,4,3],
[54,41,5,0],
[54,41,5,1],
[54,41,5,2],
[54,41,5,4],
[54,41,6,0],
[54,41,6,1],
[54,41,6,2],
[54,41,6,4],
[54,41,7,0],
[54,41,7,1],
[54,41,7,2],
[54,41,7,3],
[54,41,7,4],
[54,41,7,5],
[54,41,7,6],
[54,41,8,0],
[54,41,8,1],
[54,41,8,2],
[54,41,8,4],
[54,41,8,7],
[54,41,11,0],
[54,41,11,1],
[54,41,11,2],
[54,41,11,3],
[54,41,11,4],
[54,41,11,5],
[54,41,11,6],
[54,41,11,7],
[54,41,11,8],
[54,41,12,0],
[54,41,12,1],
[54,41,12,2],
[54,41,12,3],
[54,41,12,4],
[54,41,12,5],
[54,41,12,6],
[54,41,12,7],
[54,41,12,8],
[54,41,13,0],
[54,41,13,1],
[54,41,13,2],
[54,41,13,3],
[54,41,13,4],
[54,41,13,5],
[54,41,13,6],
[54,41,13,7],
[54,41,13,8],
[54,41,13,11],
[54,41,13,12],
[54,41,14,0],
[54,41,14,1],
[54,41,14,2],
[54,41,14,3],
[54,41,14,4],
[54,41,14,5],
[54,41,14,6],
[54,41,14,7],
[54,41,14,8],
[54,41,14,11],
[54,41,14,12],
[54,41,16,0],
[54,41,16,1],
[54,41,16,2],
[54,41,16,3],
[54,41,16,4],
[54,41,16,5],
[54,41,16,6],
[54,41,16,7],
[54,41,16,8],
[54,41,16,11],
[54,41,16,12],
[54,41,16,13],
[54,41,16,14],
[54,41,17,0],
[54,41,17,1],
[54,41,17,2],
[54,41,17,3],
[54,41,17,4],
[54,41,17,5],
[54,41,17,6],
[54,41,17,7],
[54,41,17,8],
[54,41,17,11],
[54,41,17,12],
[54,41,17,13],
[54,41,17,14],
[54,41,17,16],
[54,41,19,0],
[54,41,19,1],
[54,41,19,2],
[54,41,19,3],
[54,41,19,4],
[54,41,19,5],
[54,41,19,6],
[54,41,19,7],
[54,41,19,8],
[54,41,19,11],
[54,41,19,12],
[54,41,19,16],
[54,41,19,17],
[54,41,21,0],
[54,41,21,1],
[54,41,21,2],
[54,41,21,4],
[54,41,21,7],
[54,41,21,11],
[54,41,21,12],
[54,41,21,13],
[54,41,21,14],
[54,41,21,16],
[54,41,21,17],
[54,41,23,0],
[54,41,23,1],
[54,41,23,2],
[54,41,23,3],
[54,41,23,4],
[54,41,23,5],
[54,41,23,6],
[54,41,23,7],
[54,41,23,8],
[54,41,23,11],
[54,41,23,12],
[54,41,23,13],
[54,41,23,14],
[54,41,23,19],
[54,41,23,21],
[54,41,24,0],
[54,41,24,1],
[54,41,24,2],
[54,41,24,3],
[54,41,24,4],
[54,41,24,5],
[54,41,24,6],
[54,41,24,7],
[54,41,24,8],
[54,41,24,11],
[54,41,24,12],
[54,41,24,13],
[54,41,24,14],
[54,41,24,19],
[54,41,24,21],
[54,41,24,23],
[54,41,25,0],
[54,41,25,1],
[54,41,25,2],
[54,41,25,3],
[54,41,25,4],
[54,41,25,5],
[54,41,25,6],
[54,41,25,8],
[54,41,25,11],
[54,41,25,12],
[54,41,25,13],
[54,41,25,16],
[54,41,25,17],
[54,41,25,19],
[54,41,25,21],
[54,41,25,23],
[54,41,25,24],
[54,41,28,0],
[54,41,28,1],
[54,41,28,2],
[54,41,28,3],
[54,41,28,4],
[54,41,28,5],
[54,41,28,6],
[54,41,28,11],
[54,41,28,12],
[54,41,28,13],
[54,41,28,14],
[54,41,28,16],
[54,41,28,17],
[54,41,28,19],
[54,41,28,21],
[54,41,28,23],
[54,41,28,24],
[54,41,29,0],
[54,41,29,1],
[54,41,29,2],
[54,41,29,3],
[54,41,29,4],
[54,41,29,5],
[54,41,29,8],
[54,41,29,13],
[54,41,29,14],
[54,41,29,16],
[54,41,29,17],
[54,41,29,19],
[54,41,29,21],
[54,41,29,23],
[54,41,29,24],
[54,41,30,0],
[54,41,30,1],
[54,41,30,2],
[54,41,30,3],
[54,41,30,4],
[54,41,30,6],
[54,41,30,7],
[54,41,30,8],
[54,41,30,11],
[54,41,30,12],
[54,41,30,13],
[54,41,30,14],
[54,41,30,16],
[54,41,30,17],
[54,41,30,19],
[54,41,30,21],
[54,41,31,0],
[54,41,31,1],
[54,41,31,2],
[54,41,31,5],
[54,41,31,6],
[54,41,31,7],
[54,41,31,8],
[54,41,31,11],
[54,41,31,12],
[54,41,31,13],
[54,41,31,14],
[54,41,31,16],
[54,41,31,17],
[54,41,31,19],
[54,41,31,21],
[54,41,32,3],
[54,41,32,4],
[54,41,32,5],
[54,41,32,6],
[54,41,32,7],
[54,41,32,8],
[54,41,32,13],
[54,41,32,14],
[54,41,32,16],
[54,41,32,17],
[54,41,32,19],
[54,41,33,0],
[54,41,33,1],
[54,41,33,2],
[54,41,33,3],
[54,41,33,4],
[54,41,33,5],
[54,41,33,6],
[54,41,33,7],
[54,41,33,8],
[54,41,33,13],
[54,41,33,14],
[54,41,33,16],
[54,41,33,17],
[54,41,33,19],
[54,41,34,0],
[54,41,34,1],
[54,41,34,2],
[54,41,34,3],
[54,41,34,4],
[54,41,34,5],
[54,41,34,6],
[54,41,34,8],
[54,41,34,11],
[54,41,34,12],
[54,41,34,13],
[54,41,34,14],
[54,41,34,16],
[54,41,34,17],
[54,41,36,0],
[54,41,36,1],
[54,41,36,2],
[54,41,36,3],
[54,41,36,4],
[54,41,36,5],
[54,41,36,6],
[54,41,36,7],
[54,41,36,8],
[54,41,36,11],
[54,41,36,12],
[54,41,37,0],
[54,41,37,1],
[54,41,37,2],
[54,41,37,3],
[54,41,37,5],
[54,41,37,6],
[54,41,37,7],
[54,41,37,8],
[54,41,37,11],
[54,41,37,12],
[54,41,37,13],
[54,41,37,14],
[54,41,38,0],
[54,41,38,1],
[54,41,38,2],
[54,41,38,3],
[54,41,38,4],
[54,41,38,5],
[54,41,38,6],
[54,41,38,8],
[54,41,38,11],
[54,41,38,12],
[54,41,38,13],
[54,41,39,0],
[54,41,39,1],
[54,41,39,2],
[54,41,39,4],
[54,41,39,7],
[54,41,39,11],
[54,43,3,0],
[54,43,3,1],
[54,43,3,2],
[54,43,4,0],
[54,43,4,1],
[54,43,4,2],
[54,43,4,3],
[54,43,5,0],
[54,43,5,1],
[54,43,5,2],
[54,43,5,4],
[54,43,6,0],
[54,43,6,1],
[54,43,6,2],
[54,43,6,4],
[54,43,8,0],
[54,43,8,1],
[54,43,8,2],
[54,43,8,4],
[54,43,9,0],
[54,43,9,1],
[54,43,9,2],
[54,43,9,3],
[54,43,9,4],
[54,43,9,5],
[54,43,9,6],
[54,43,9,8],
[54,43,11,0],
[54,43,11,1],
[54,43,11,2],
[54,43,11,3],
[54,43,11,4],
[54,43,11,5],
[54,43,11,6],
[54,43,11,8],
[54,43,11,9],
[54,43,12,0],
[54,43,12,1],
[54,43,12,2],
[54,43,12,3],
[54,43,12,4],
[54,43,12,5],
[54,43,12,6],
[54,43,12,8],
[54,43,12,9],
[54,43,13,0],
[54,43,13,1],
[54,43,13,2],
[54,43,13,3],
[54,43,13,4],
[54,43,13,5],
[54,43,13,6],
[54,43,13,8],
[54,43,13,9],
[54,43,13,11],
[54,43,13,12],
[54,43,14,0],
[54,43,14,1],
[54,43,14,2],
[54,43,14,3],
[54,43,14,4],
[54,43,14,5],
[54,43,14,6],
[54,43,14,8],
[54,43,14,9],
[54,43,14,11],
[54,43,14,12],
[54,43,15,0],
[54,43,15,1],
[54,43,15,2],
[54,43,15,3],
[54,43,15,4],
[54,43,15,5],
[54,43,15,6],
[54,43,15,8],
[54,43,15,11],
[54,43,15,12],
[54,43,15,13],
[54,43,15,14],
[54,43,16,0],
[54,43,16,1],
[54,43,16,2],
[54,43,16,3],
[54,43,16,4],
[54,43,16,5],
[54,43,16,6],
[54,43,16,8],
[54,43,16,9],
[54,43,16,11],
[54,43,16,12],
[54,43,16,13],
[54,43,16,14],
[54,43,16,15],
[54,43,17,0],
[54,43,17,1],
[54,43,17,2],
[54,43,17,3],
[54,43,17,4],
[54,43,17,5],
[54,43,17,6],
[54,43,17,8],
[54,43,17,9],
[54,43,17,11],
[54,43,17,12],
[54,43,17,13],
[54,43,17,14],
[54,43,17,15],
[54,43,17,16],
[54,43,18,0],
[54,43,18,1],
[54,43,18,2],
[54,43,18,3],
[54,43,18,4],
[54,43,18,5],
[54,43,18,6],
[54,43,18,8],
[54,43,18,11],
[54,43,18,12],
[54,43,18,13],
[54,43,18,14],
[54,43,18,16],
[54,43,18,17],
[54,43,19,0],
[54,43,19,1],
[54,43,19,2],
[54,43,19,3],
[54,43,19,4],
[54,43,19,5],
[54,43,19,6],
[54,43,19,8],
[54,43,19,9],
[54,43,19,11],
[54,43,19,12],
[54,43,19,15],
[54,43,19,16],
[54,43,19,17],
[54,43,19,18],
[54,43,21,0],
[54,43,21,1],
[54,43,21,2],
[54,43,21,4],
[54,43,21,9],
[54,43,21,11],
[54,43,21,12],
[54,43,21,13],
[54,43,21,14],
[54,43,21,15],
[54,43,21,18],
[54,43,21,19],
[54,43,23,0],
[54,43,23,1],
[54,43,23,2],
[54,43,23,3],
[54,43,23,4],
[54,43,23,5],
[54,43,23,6],
[54,43,23,8],
[54,43,23,9],
[54,43,23,11],
[54,43,23,12],
[54,43,23,13],
[54,43,23,15],
[54,43,23,16],
[54,43,23,17],
[54,43,23,18],
[54,43,23,19],
[54,43,23,21],
[54,43,24,0],
[54,43,24,1],
[54,43,24,2],
[54,43,24,3],
[54,43,24,4],
[54,43,24,5],
[54,43,24,6],
[54,43,24,8],
[54,43,24,9],
[54,43,24,11],
[54,43,24,12],
[54,43,24,13],
[54,43,24,15],
[54,43,24,17],
[54,43,24,18],
[54,43,24,19],
[54,43,24,21],
[54,43,24,23],
[54,43,26,0],
[54,43,26,1],
[54,43,26,2],
[54,43,26,3],
[54,43,26,4],
[54,43,26,5],
[54,43,26,6],
[54,43,26,11],
[54,43,26,12],
[54,43,26,13],
[54,43,26,14],
[54,43,26,16],
[54,43,26,17],
[54,43,26,19],
[54,43,26,21],
[54,43,26,23],
[54,43,26,24],
[54,43,29,0],
[54,43,29,1],
[54,43,29,2],
[54,43,29,5],
[54,43,29,6],
[54,43,29,8],
[54,43,29,9],
[54,43,29,13],
[54,43,29,14],
[54,43,29,15],
[54,43,29,16],
[54,43,29,17],
[54,43,29,18],
[54,43,29,19],
[54,43,29,21],
[54,43,30,3],
[54,43,30,4],
[54,43,30,5],
[54,43,30,6],
[54,43,30,8],
[54,43,30,9],
[54,43,30,11],
[54,43,30,12],
[54,43,30,13],
[54,43,30,14],
[54,43,30,15],
[54,43,30,16],
[54,43,30,17],
[54,43,30,18],
[54,43,30,19],
[54,43,31,0],
[54,43,31,1],
[54,43,31,2],
[54,43,31,3],
[54,43,31,4],
[54,43,31,5],
[54,43,31,6],
[54,43,31,8],
[54,43,31,9],
[54,43,31,11],
[54,43,31,12],
[54,43,31,13],
[54,43,31,14],
[54,43,31,15],
[54,43,31,16],
[54,43,31,17],
[54,43,31,18],
[54,43,31,19],
[54,43,32,0],
[54,43,32,1],
[54,43,32,2],
[54,43,32,3],
[54,43,32,4],
[54,43,32,5],
[54,43,32,6],
[54,43,32,8],
[54,43,32,9],
[54,43,32,13],
[54,43,32,14],
[54,43,32,15],
[54,43,32,16],
[54,43,32,17],
[54,43,32,18],
[54,43,33,0],
[54,43,33,1],
[54,43,33,2],
[54,43,33,3],
[54,43,33,4],
[54,43,33,5],
[54,43,33,6],
[54,43,33,8],
[54,43,33,9],
[54,43,33,13],
[54,43,33,14],
[54,43,33,15],
[54,43,33,16],
[54,43,33,17],
[54,43,35,0],
[54,43,35,1],
[54,43,35,2],
[54,43,35,3],
[54,43,35,4],
[54,43,35,5],
[54,43,35,6],
[54,43,35,8],
[54,43,35,11],
[54,43,35,12],
[54,43,35,13],
[54,43,35,14],
[54,43,36,0],
[54,43,36,1],
[54,43,36,2],
[54,43,36,3],
[54,43,36,4],
[54,43,36,5],
[54,43,36,6],
[54,43,36,8],
[54,43,36,9],
[54,43,36,11],
[54,43,36,12],
[54,43,37,0],
[54,43,37,1],
[54,43,37,2],
[54,43,37,3],
[54,43,37,5],
[54,43,37,6],
[54,43,37,8],
[54,43,37,9],
[54,43,37,11],
[54,43,39,0],
[54,43,39,1],
[54,43,39,2],
[54,43,39,4],
[54,43,40,0],
[54,43,40,1],
[54,43,40,2],
[54,43,40,3],
[54,43,40,4],
[54,43,40,5],
[54,43,41,0],
[54,43,41,1],
[54,43,41,2],
[54,43,41,3],
[54,43,41,4],
[54,43,41,5],
[54,44,3,0],
[54,44,3,1],
[54,44,3,2],
[54,44,4,0],
[54,44,4,1],
[54,44,4,2],
[54,44,4,3],
[54,44,5,0],
[54,44,5,1],
[54,44,5,2],
[54,44,5,4],
[54,44,6,0],
[54,44,6,1],
[54,44,6,2],
[54,44,6,4],
[54,44,7,0],
[54,44,7,1],
[54,44,7,2],
[54,44,7,3],
[54,44,7,4],
[54,44,7,5],
[54,44,7,6],
[54,44,8,0],
[54,44,8,1],
[54,44,8,2],
[54,44,8,4],
[54,44,8,7],
[54,44,9,0],
[54,44,9,1],
[54,44,9,2],
[54,44,9,3],
[54,44,9,4],
[54,44,9,5],
[54,44,9,6],
[54,44,9,7],
[54,44,9,8],
[54,44,11,0],
[54,44,11,1],
[54,44,11,2],
[54,44,11,3],
[54,44,11,4],
[54,44,11,5],
[54,44,11,6],
[54,44,11,7],
[54,44,11,8],
[54,44,11,9],
[54,44,12,0],
[54,44,12,1],
[54,44,12,2],
[54,44,12,3],
[54,44,12,4],
[54,44,12,5],
[54,44,12,6],
[54,44,12,7],
[54,44,12,8],
[54,44,12,9],
[54,44,13,0],
[54,44,13,1],
[54,44,13,2],
[54,44,13,3],
[54,44,13,4],
[54,44,13,5],
[54,44,13,6],
[54,44,13,7],
[54,44,13,8],
[54,44,13,9],
[54,44,13,11],
[54,44,13,12],
[54,44,14,0],
[54,44,14,1],
[54,44,14,2],
[54,44,14,3],
[54,44,14,4],
[54,44,14,5],
[54,44,14,6],
[54,44,14,7],
[54,44,14,8],
[54,44,14,9],
[54,44,14,11],
[54,44,14,12],
[54,44,15,0],
[54,44,15,1],
[54,44,15,2],
[54,44,15,3],
[54,44,15,4],
[54,44,15,5],
[54,44,15,6],
[54,44,15,7],
[54,44,15,8],
[54,44,15,11],
[54,44,15,12],
[54,44,15,13],
[54,44,15,14],
[54,44,16,0],
[54,44,16,1],
[54,44,16,2],
[54,44,16,3],
[54,44,16,4],
[54,44,16,5],
[54,44,16,6],
[54,44,16,7],
[54,44,16,8],
[54,44,16,9],
[54,44,16,11],
[54,44,16,12],
[54,44,16,13],
[54,44,16,14],
[54,44,16,15],
[54,44,18,0],
[54,44,18,1],
[54,44,18,2],
[54,44,18,3],
[54,44,18,4],
[54,44,18,5],
[54,44,18,6],
[54,44,18,7],
[54,44,18,8],
[54,44,18,11],
[54,44,18,12],
[54,44,18,13],
[54,44,18,14],
[54,44,18,16],
[54,44,19,0],
[54,44,19,1],
[54,44,19,2],
[54,44,19,3],
[54,44,19,4],
[54,44,19,5],
[54,44,19,6],
[54,44,19,7],
[54,44,19,8],
[54,44,19,9],
[54,44,19,11],
[54,44,19,12],
[54,44,19,15],
[54,44,19,16],
[54,44,21,0],
[54,44,21,1],
[54,44,21,2],
[54,44,21,4],
[54,44,21,7],
[54,44,21,9],
[54,44,21,11],
[54,44,21,12],
[54,44,21,13],
[54,44,21,14],
[54,44,21,16],
[54,44,21,18],
[54,44,21,19],
[54,44,23,0],
[54,44,23,1],
[54,44,23,2],
[54,44,23,3],
[54,44,23,4],
[54,44,23,5],
[54,44,23,6],
[54,44,23,7],
[54,44,23,8],
[54,44,23,9],
[54,44,23,11],
[54,44,23,14],
[54,44,23,15],
[54,44,23,16],
[54,44,23,18],
[54,44,23,19],
[54,44,23,21],
[54,44,24,0],
[54,44,24,1],
[54,44,24,2],
[54,44,24,3],
[54,44,24,4],
[54,44,24,5],
[54,44,24,6],
[54,44,24,7],
[54,44,24,8],
[54,44,24,9],
[54,44,24,11],
[54,44,24,14],
[54,44,24,15],
[54,44,24,18],
[54,44,24,19],
[54,44,24,21],
[54,44,24,23],
[54,44,25,0],
[54,44,25,1],
[54,44,25,2],
[54,44,25,3],
[54,44,25,4],
[54,44,25,5],
[54,44,25,6],
[54,44,25,11],
[54,44,25,12],
[54,44,25,13],
[54,44,25,14],
[54,44,25,15],
[54,44,25,16],
[54,44,25,18],
[54,44,25,19],
[54,44,25,21],
[54,44,25,23],
[54,44,25,24],
[54,44,26,0],
[54,44,26,1],
[54,44,26,2],
[54,44,26,3],
[54,44,26,4],
[54,44,26,5],
[54,44,26,8],
[54,44,26,11],
[54,44,26,12],
[54,44,26,13],
[54,44,26,14],
[54,44,26,16],
[54,44,26,19],
[54,44,26,21],
[54,44,26,23],
[54,44,26,24],
[54,44,28,0],
[54,44,28,1],
[54,44,28,2],
[54,44,28,5],
[54,44,28,6],
[54,44,28,8],
[54,44,28,9],
[54,44,28,11],
[54,44,28,12],
[54,44,28,13],
[54,44,28,14],
[54,44,28,15],
[54,44,28,16],
[54,44,28,18],
[54,44,28,19],
[54,44,28,21],
[54,44,29,3],
[54,44,29,4],
[54,44,29,5],
[54,44,29,6],
[54,44,29,7],
[54,44,29,8],
[54,44,29,9],
[54,44,29,13],
[54,44,29,14],
[54,44,29,15],
[54,44,29,16],
[54,44,29,18],
[54,44,29,19],
[54,44,30,0],
[54,44,30,1],
[54,44,30,2],
[54,44,30,3],
[54,44,30,4],
[54,44,30,5],
[54,44,30,6],
[54,44,30,7],
[54,44,30,8],
[54,44,30,9],
[54,44,30,11],
[54,44,30,12],
[54,44,30,13],
[54,44,30,14],
[54,44,30,15],
[54,44,30,16],
[54,44,30,18],
[54,44,30,19],
[54,44,31,0],
[54,44,31,1],
[54,44,31,2],
[54,44,31,3],
[54,44,31,4],
[54,44,31,5],
[54,44,31,6],
[54,44,31,7],
[54,44,31,8],
[54,44,31,9],
[54,44,31,11],
[54,44,31,12],
[54,44,31,13],
[54,44,31,14],
[54,44,31,15],
[54,44,31,16],
[54,44,31,18],
[54,44,32,0],
[54,44,32,1],
[54,44,32,2],
[54,44,32,3],
[54,44,32,4],
[54,44,32,5],
[54,44,32,6],
[54,44,32,7],
[54,44,32,8],
[54,44,32,9],
[54,44,32,13],
[54,44,32,14],
[54,44,32,15],
[54,44,32,16],
[54,44,33,0],
[54,44,33,1],
[54,44,33,2],
[54,44,33,3],
[54,44,33,4],
[54,44,33,5],
[54,44,33,6],
[54,44,33,7],
[54,44,33,8],
[54,44,33,9],
[54,44,33,13],
[54,44,33,14],
[54,44,33,15],
[54,44,34,0],
[54,44,34,1],
[54,44,34,2],
[54,44,34,3],
[54,44,34,4],
[54,44,34,5],
[54,44,34,6],
[54,44,34,8],
[54,44,34,9],
[54,44,34,11],
[54,44,34,12],
[54,44,34,13],
[54,44,34,14],
[54,44,35,0],
[54,44,35,1],
[54,44,35,2],
[54,44,35,3],
[54,44,35,4],
[54,44,35,5],
[54,44,35,6],
[54,44,35,7],
[54,44,35,8],
[54,44,35,11],
[54,44,35,12],
[54,44,35,13],
[54,44,36,0],
[54,44,36,1],
[54,44,36,2],
[54,44,36,3],
[54,44,36,4],
[54,44,36,5],
[54,44,36,6],
[54,44,36,7],
[54,44,36,8],
[54,44,36,9],
[54,44,36,11],
[54,44,37,0],
[54,44,37,1],
[54,44,37,2],
[54,44,37,3],
[54,44,37,5],
[54,44,37,6],
[54,44,37,7],
[54,44,37,8],
[54,44,37,9],
[54,44,38,0],
[54,44,38,1],
[54,44,38,2],
[54,44,38,3],
[54,44,38,4],
[54,44,38,5],
[54,44,38,6],
[54,44,39,0],
[54,44,39,1],
[54,44,39,2],
[54,44,39,4],
[54,44,40,0],
[54,44,40,1],
[54,44,40,2],
[54,44,40,3],
[54,44,40,4],
[54,44,41,0],
[54,44,41,1],
[54,44,41,2],
[54,44,41,3],
[54,44,41,4],
[54,45,3,0],
[54,45,3,1],
[54,45,3,2],
[54,45,4,0],
[54,45,4,1],
[54,45,4,2],
[54,45,4,3],
[54,45,5,0],
[54,45,5,1],
[54,45,5,2],
[54,45,5,4],
[54,45,6,0],
[54,45,6,1],
[54,45,6,2],
[54,45,6,4],
[54,45,7,0],
[54,45,7,1],
[54,45,7,2],
[54,45,7,3],
[54,45,7,4],
[54,45,7,5],
[54,45,7,6],
[54,45,8,0],
[54,45,8,1],
[54,45,8,2],
[54,45,8,4],
[54,45,8,7],
[54,45,11,0],
[54,45,11,1],
[54,45,11,2],
[54,45,11,3],
[54,45,11,4],
[54,45,11,5],
[54,45,11,6],
[54,45,11,7],
[54,45,11,8],
[54,45,12,0],
[54,45,12,1],
[54,45,12,2],
[54,45,12,3],
[54,45,12,4],
[54,45,12,5],
[54,45,12,6],
[54,45,12,7],
[54,45,12,8],
[54,45,13,0],
[54,45,13,1],
[54,45,13,2],
[54,45,13,3],
[54,45,13,4],
[54,45,13,5],
[54,45,13,6],
[54,45,13,7],
[54,45,13,8],
[54,45,13,11],
[54,45,13,12],
[54,45,14,0],
[54,45,14,1],
[54,45,14,2],
[54,45,14,3],
[54,45,14,4],
[54,45,14,5],
[54,45,14,6],
[54,45,14,7],
[54,45,14,8],
[54,45,14,11],
[54,45,14,12],
[54,45,16,0],
[54,45,16,1],
[54,45,16,2],
[54,45,16,3],
[54,45,16,4],
[54,45,16,5],
[54,45,16,6],
[54,45,16,7],
[54,45,16,8],
[54,45,16,11],
[54,45,16,12],
[54,45,16,13],
[54,45,16,14],
[54,45,17,0],
[54,45,17,1],
[54,45,17,2],
[54,45,17,3],
[54,45,17,4],
[54,45,17,5],
[54,45,17,6],
[54,45,17,7],
[54,45,17,8],
[54,45,17,11],
[54,45,17,12],
[54,45,17,13],
[54,45,17,14],
[54,45,17,16],
[54,45,19,0],
[54,45,19,1],
[54,45,19,2],
[54,45,19,3],
[54,45,19,4],
[54,45,19,5],
[54,45,19,6],
[54,45,19,7],
[54,45,19,8],
[54,45,19,11],
[54,45,19,12],
[54,45,21,0],
[54,45,21,1],
[54,45,21,2],
[54,45,21,4],
[54,45,21,7],
[54,45,21,11],
[54,45,21,12],
[54,45,21,13],
[54,45,21,16],
[54,45,21,17],
[54,45,21,19],
[54,45,23,0],
[54,45,23,1],
[54,45,23,2],
[54,45,23,3],
[54,45,23,4],
[54,45,23,5],
[54,45,23,6],
[54,45,23,7],
[54,45,23,8],
[54,45,23,12],
[54,45,23,13],
[54,45,23,14],
[54,45,23,16],
[54,45,23,17],
[54,45,23,19],
[54,45,23,21],
[54,45,24,0],
[54,45,24,1],
[54,45,24,2],
[54,45,24,3],
[54,45,24,4],
[54,45,24,5],
[54,45,24,6],
[54,45,24,7],
[54,45,24,8],
[54,45,24,12],
[54,45,24,13],
[54,45,24,14],
[54,45,24,17],
[54,45,24,19],
[54,45,24,21],
[54,45,24,23],
[54,45,25,0],
[54,45,25,1],
[54,45,25,2],
[54,45,25,3],
[54,45,25,4],
[54,45,25,5],
[54,45,25,8],
[54,45,25,11],
[54,45,25,12],
[54,45,25,13],
[54,45,25,14],
[54,45,25,16],
[54,45,25,17],
[54,45,25,19],
[54,45,25,21],
[54,45,25,23],
[54,45,25,24],
[54,45,28,3],
[54,45,28,4],
[54,45,28,5],
[54,45,28,6],
[54,45,28,8],
[54,45,28,11],
[54,45,28,12],
[54,45,28,13],
[54,45,28,14],
[54,45,28,16],
[54,45,28,17],
[54,45,28,19],
[54,45,29,0],
[54,45,29,1],
[54,45,29,2],
[54,45,29,3],
[54,45,29,4],
[54,45,29,5],
[54,45,29,6],
[54,45,29,7],
[54,45,29,8],
[54,45,29,13],
[54,45,29,14],
[54,45,29,16],
[54,45,29,17],
[54,45,29,19],
[54,45,30,0],
[54,45,30,1],
[54,45,30,2],
[54,45,30,3],
[54,45,30,4],
[54,45,30,5],
[54,45,30,6],
[54,45,30,7],
[54,45,30,8],
[54,45,30,11],
[54,45,30,12],
[54,45,30,13],
[54,45,30,14],
[54,45,30,16],
[54,45,30,17],
[54,45,31,0],
[54,45,31,1],
[54,45,31,2],
[54,45,31,3],
[54,45,31,4],
[54,45,31,5],
[54,45,31,6],
[54,45,31,7],
[54,45,31,8],
[54,45,31,11],
[54,45,31,12],
[54,45,31,13],
[54,45,31,14],
[54,45,31,16],
[54,45,31,17],
[54,45,32,0],
[54,45,32,1],
[54,45,32,2],
[54,45,32,3],
[54,45,32,4],
[54,45,32,5],
[54,45,32,6],
[54,45,32,7],
[54,45,32,8],
[54,45,32,13],
[54,45,32,14],
[54,45,33,0],
[54,45,33,1],
[54,45,33,2],
[54,45,33,3],
[54,45,33,4],
[54,45,33,5],
[54,45,33,6],
[54,45,33,7],
[54,45,33,8],
[54,45,33,13],
[54,45,33,14],
[54,45,34,0],
[54,45,34,1],
[54,45,34,2],
[54,45,34,3],
[54,45,34,4],
[54,45,34,5],
[54,45,34,6],
[54,45,34,8],
[54,45,34,11],
[54,45,34,12],
[54,45,34,13],
[54,45,36,0],
[54,45,36,1],
[54,45,36,2],
[54,45,36,3],
[54,45,36,4],
[54,45,36,5],
[54,45,36,6],
[54,45,36,7],
[54,45,36,8],
[54,45,37,0],
[54,45,37,1],
[54,45,37,2],
[54,45,37,3],
[54,45,37,5],
[54,45,37,6],
[54,45,37,7],
[54,45,38,0],
[54,45,38,1],
[54,45,38,2],
[54,45,38,3],
[54,45,38,4],
[54,45,38,5],
[54,45,39,0],
[54,45,39,1],
[54,45,39,2],
[54,45,39,4],
[54,46,3,0],
[54,46,3,1],
[54,46,3,2],
[54,46,4,0],
[54,46,4,1],
[54,46,4,2],
[54,46,4,3],
[54,46,5,0],
[54,46,5,1],
[54,46,5,2],
[54,46,5,4],
[54,46,6,0],
[54,46,6,1],
[54,46,6,2],
[54,46,6,4],
[54,46,7,0],
[54,46,7,1],
[54,46,7,2],
[54,46,7,3],
[54,46,7,4],
[54,46,7,5],
[54,46,7,6],
[54,46,8,0],
[54,46,8,1],
[54,46,8,2],
[54,46,8,4],
[54,46,8,7],
[54,46,11,0],
[54,46,11,1],
[54,46,11,2],
[54,46,11,3],
[54,46,11,4],
[54,46,11,5],
[54,46,11,6],
[54,46,11,7],
[54,46,11,8],
[54,46,12,0],
[54,46,12,1],
[54,46,12,2],
[54,46,12,3],
[54,46,12,4],
[54,46,12,5],
[54,46,12,6],
[54,46,12,7],
[54,46,12,8],
[54,46,13,0],
[54,46,13,1],
[54,46,13,2],
[54,46,13,3],
[54,46,13,4],
[54,46,13,5],
[54,46,13,6],
[54,46,13,7],
[54,46,13,8],
[54,46,13,11],
[54,46,13,12],
[54,46,14,0],
[54,46,14,1],
[54,46,14,2],
[54,46,14,3],
[54,46,14,4],
[54,46,14,5],
[54,46,14,6],
[54,46,14,7],
[54,46,14,8],
[54,46,14,11],
[54,46,14,12],
[54,46,16,0],
[54,46,16,1],
[54,46,16,2],
[54,46,16,3],
[54,46,16,4],
[54,46,16,5],
[54,46,16,6],
[54,46,16,7],
[54,46,16,8],
[54,46,16,11],
[54,46,16,12],
[54,46,16,13],
[54,46,16,14],
[54,46,17,0],
[54,46,17,1],
[54,46,17,2],
[54,46,17,3],
[54,46,17,4],
[54,46,17,5],
[54,46,17,6],
[54,46,17,7],
[54,46,17,8],
[54,46,17,11],
[54,46,17,12],
[54,46,17,13],
[54,46,17,14],
[54,46,17,16],
[54,46,19,0],
[54,46,19,1],
[54,46,19,2],
[54,46,19,3],
[54,46,19,4],
[54,46,19,5],
[54,46,19,6],
[54,46,19,7],
[54,46,19,8],
[54,46,19,11],
[54,46,19,12],
[54,46,19,16],
[54,46,19,17],
[54,46,21,0],
[54,46,21,1],
[54,46,21,2],
[54,46,21,4],
[54,46,21,7],
[54,46,21,11],
[54,46,21,14],
[54,46,21,16],
[54,46,21,17],
[54,46,21,19],
[54,46,23,0],
[54,46,23,1],
[54,46,23,2],
[54,46,23,3],
[54,46,23,4],
[54,46,23,5],
[54,46,23,6],
[54,46,23,7],
[54,46,23,11],
[54,46,23,12],
[54,46,23,13],
[54,46,23,14],
[54,46,23,16],
[54,46,23,17],
[54,46,23,19],
[54,46,23,21],
[54,46,24,0],
[54,46,24,1],
[54,46,24,2],
[54,46,24,3],
[54,46,24,4],
[54,46,24,5],
[54,46,24,6],
[54,46,24,7],
[54,46,24,11],
[54,46,24,12],
[54,46,24,13],
[54,46,24,14],
[54,46,24,17],
[54,46,24,19],
[54,46,24,21],
[54,46,24,23],
[54,46,25,0],
[54,46,25,1],
[54,46,25,2],
[54,46,25,3],
[54,46,25,4],
[54,46,25,6],
[54,46,25,8],
[54,46,25,11],
[54,46,25,12],
[54,46,25,13],
[54,46,25,14],
[54,46,25,16],
[54,46,25,17],
[54,46,25,19],
[54,46,25,21],
[54,46,28,0],
[54,46,28,1],
[54,46,28,2],
[54,46,28,3],
[54,46,28,4],
[54,46,28,5],
[54,46,28,6],
[54,46,28,8],
[54,46,28,11],
[54,46,28,12],
[54,46,28,13],
[54,46,28,14],
[54,46,28,16],
[54,46,28,17],
[54,46,28,19],
[54,46,29,0],
[54,46,29,1],
[54,46,29,2],
[54,46,29,3],
[54,46,29,4],
[54,46,29,5],
[54,46,29,6],
[54,46,29,7],
[54,46,29,8],
[54,46,29,13],
[54,46,29,14],
[54,46,29,16],
[54,46,29,17],
[54,46,30,0],
[54,46,30,1],
[54,46,30,2],
[54,46,30,3],
[54,46,30,4],
[54,46,30,5],
[54,46,30,6],
[54,46,30,7],
[54,46,30,8],
[54,46,30,11],
[54,46,30,12],
[54,46,30,13],
[54,46,30,14],
[54,46,30,16],
[54,46,30,17],
[54,46,31,0],
[54,46,31,1],
[54,46,31,2],
[54,46,31,3],
[54,46,31,4],
[54,46,31,5],
[54,46,31,6],
[54,46,31,7],
[54,46,31,8],
[54,46,31,11],
[54,46,31,12],
[54,46,31,13],
[54,46,31,14],
[54,46,32,0],
[54,46,32,1],
[54,46,32,2],
[54,46,32,3],
[54,46,32,4],
[54,46,32,5],
[54,46,32,6],
[54,46,32,7],
[54,46,32,8],
[54,46,32,13],
[54,46,32,14],
[54,46,33,0],
[54,46,33,1],
[54,46,33,2],
[54,46,33,3],
[54,46,33,4],
[54,46,33,5],
[54,46,33,6],
[54,46,33,7],
[54,46,33,8],
[54,46,33,13],
[54,46,34,0],
[54,46,34,1],
[54,46,34,2],
[54,46,34,3],
[54,46,34,4],
[54,46,34,5],
[54,46,34,6],
[54,46,34,8],
[54,46,34,11],
[54,46,36,0],
[54,46,36,1],
[54,46,36,2],
[54,46,36,3],
[54,46,36,4],
[54,46,36,5],
[54,46,36,6],
[54,46,36,7],
[54,46,37,0],
[54,46,37,1],
[54,46,37,2],
[54,46,37,3],
[54,46,37,5],
[54,46,38,0],
[54,46,38,1],
[54,46,38,2],
[54,46,38,3],
[54,46,38,4],
[54,46,39,0],
[54,46,39,1],
[54,46,39,2],
[54,47,3,0],
[54,47,3,1],
[54,47,3,2],
[54,47,4,0],
[54,47,4,1],
[54,47,4,2],
[54,47,4,3],
[54,47,5,0],
[54,47,5,1],
[54,47,5,2],
[54,47,5,4],
[54,47,6,0],
[54,47,6,1],
[54,47,6,2],
[54,47,6,4],
[54,47,7,0],
[54,47,7,1],
[54,47,7,2],
[54,47,7,3],
[54,47,7,4],
[54,47,7,5],
[54,47,7,6],
[54,47,8,0],
[54,47,8,1],
[54,47,8,2],
[54,47,8,4],
[54,47,8,7],
[54,47,9,0],
[54,47,9,1],
[54,47,9,2],
[54,47,9,3],
[54,47,9,4],
[54,47,9,5],
[54,47,9,6],
[54,47,9,7],
[54,47,9,8],
[54,47,11,0],
[54,47,11,1],
[54,47,11,2],
[54,47,11,3],
[54,47,11,4],
[54,47,11,5],
[54,47,11,6],
[54,47,11,7],
[54,47,11,8],
[54,47,11,9],
[54,47,12,0],
[54,47,12,1],
[54,47,12,2],
[54,47,12,3],
[54,47,12,4],
[54,47,12,5],
[54,47,12,6],
[54,47,12,7],
[54,47,12,8],
[54,47,12,9],
[54,47,13,0],
[54,47,13,1],
[54,47,13,2],
[54,47,13,3],
[54,47,13,4],
[54,47,13,5],
[54,47,13,6],
[54,47,13,7],
[54,47,13,8],
[54,47,13,9],
[54,47,13,11],
[54,47,13,12],
[54,47,14,0],
[54,47,14,1],
[54,47,14,2],
[54,47,14,3],
[54,47,14,4],
[54,47,14,5],
[54,47,14,6],
[54,47,14,7],
[54,47,14,8],
[54,47,14,9],
[54,47,14,11],
[54,47,14,12],
[54,47,15,0],
[54,47,15,1],
[54,47,15,2],
[54,47,15,3],
[54,47,15,4],
[54,47,15,5],
[54,47,15,6],
[54,47,15,7],
[54,47,15,8],
[54,47,15,11],
[54,47,15,12],
[54,47,15,13],
[54,47,15,14],
[54,47,16,0],
[54,47,16,1],
[54,47,16,2],
[54,47,16,3],
[54,47,16,4],
[54,47,16,5],
[54,47,16,6],
[54,47,16,7],
[54,47,16,8],
[54,47,16,9],
[54,47,16,11],
[54,47,16,12],
[54,47,16,13],
[54,47,16,14],
[54,47,16,15],
[54,47,17,0],
[54,47,17,1],
[54,47,17,2],
[54,47,17,3],
[54,47,17,4],
[54,47,17,5],
[54,47,17,6],
[54,47,17,7],
[54,47,17,8],
[54,47,17,9],
[54,47,17,11],
[54,47,17,12],
[54,47,17,13],
[54,47,17,14],
[54,47,17,15],
[54,47,18,0],
[54,47,18,1],
[54,47,18,2],
[54,47,18,3],
[54,47,18,4],
[54,47,18,5],
[54,47,18,6],
[54,47,18,7],
[54,47,18,8],
[54,47,18,11],
[54,47,18,12],
[54,47,18,13],
[54,47,18,14],
[54,47,18,16],
[54,47,18,17],
[54,47,19,0],
[54,47,19,1],
[54,47,19,2],
[54,47,19,3],
[54,47,19,4],
[54,47,19,5],
[54,47,19,6],
[54,47,19,7],
[54,47,19,8],
[54,47,19,9],
[54,47,19,11],
[54,47,19,12],
[54,47,19,15],
[54,47,19,16],
[54,47,19,17],
[54,47,19,18],
[54,47,21,0],
[54,47,21,1],
[54,47,21,2],
[54,47,21,4],
[54,47,21,7],
[54,47,21,9],
[54,47,21,12],
[54,47,21,13],
[54,47,21,14],
[54,47,21,15],
[54,47,21,16],
[54,47,21,17],
[54,47,21,18],
[54,47,21,19],
[54,47,24,0],
[54,47,24,1],
[54,47,24,2],
[54,47,24,3],
[54,47,24,4],
[54,47,24,5],
[54,47,24,8],
[54,47,24,9],
[54,47,24,11],
[54,47,24,12],
[54,47,24,13],
[54,47,24,14],
[54,47,24,15],
[54,47,24,17],
[54,47,24,18],
[54,47,24,19],
[54,47,24,21],
[54,47,25,0],
[54,47,25,1],
[54,47,25,2],
[54,47,25,5],
[54,47,25,6],
[54,47,25,8],
[54,47,25,9],
[54,47,25,11],
[54,47,25,12],
[54,47,25,13],
[54,47,25,14],
[54,47,25,15],
[54,47,25,16],
[54,47,25,17],
[54,47,25,18],
[54,47,25,19],
[54,47,25,21],
[54,47,26,3],
[54,47,26,4],
[54,47,26,5],
[54,47,26,6],
[54,47,26,7],
[54,47,26,8],
[54,47,26,11],
[54,47,26,12],
[54,47,26,13],
[54,47,26,14],
[54,47,26,16],
[54,47,26,17],
[54,47,26,19],
[54,47,28,0],
[54,47,28,1],
[54,47,28,2],
[54,47,28,3],
[54,47,28,4],
[54,47,28,5],
[54,47,28,6],
[54,47,28,8],
[54,47,28,9],
[54,47,28,11],
[54,47,28,12],
[54,47,28,13],
[54,47,28,14],
[54,47,28,15],
[54,47,28,16],
[54,47,28,17],
[54,47,28,18],
[54,47,29,0],
[54,47,29,1],
[54,47,29,2],
[54,47,29,3],
[54,47,29,4],
[54,47,29,5],
[54,47,29,6],
[54,47,29,7],
[54,47,29,8],
[54,47,29,9],
[54,47,29,13],
[54,47,29,14],
[54,47,29,15],
[54,47,29,16],
[54,47,29,17],
[54,47,32,0],
[54,47,32,1],
[54,47,32,2],
[54,47,32,3],
[54,47,32,4],
[54,47,32,5],
[54,47,32,6],
[54,47,32,7],
[54,47,32,8],
[54,47,32,9],
[54,47,32,13],
[54,47,33,0],
[54,47,33,1],
[54,47,33,2],
[54,47,33,3],
[54,47,33,4],
[54,47,33,5],
[54,47,33,6],
[54,47,33,7],
[54,47,33,8],
[54,47,33,9],
[54,47,34,0],
[54,47,34,1],
[54,47,34,2],
[54,47,34,3],
[54,47,34,4],
[54,47,34,5],
[54,47,34,6],
[54,47,34,8],
[54,47,34,9],
[54,47,35,0],
[54,47,35,1],
[54,47,35,2],
[54,47,35,3],
[54,47,35,4],
[54,47,35,5],
[54,47,35,6],
[54,47,35,7],
[54,47,36,0],
[54,47,36,1],
[54,47,36,2],
[54,47,36,3],
[54,47,36,4],
[54,47,36,5],
[54,47,37,0],
[54,47,37,1],
[54,47,37,2],
[54,47,37,3],
[54,47,38,0],
[54,47,38,1],
[54,47,38,2],
[54,48,3,0],
[54,48,3,1],
[54,48,3,2],
[54,48,4,0],
[54,48,4,1],
[54,48,4,2],
[54,48,4,3],
[54,48,5,0],
[54,48,5,1],
[54,48,5,2],
[54,48,5,4],
[54,48,6,0],
[54,48,6,1],
[54,48,6,2],
[54,48,6,4],
[54,48,7,0],
[54,48,7,1],
[54,48,7,2],
[54,48,7,3],
[54,48,7,4],
[54,48,7,5],
[54,48,7,6],
[54,48,8,0],
[54,48,8,1],
[54,48,8,2],
[54,48,8,4],
[54,48,8,7],
[54,48,9,0],
[54,48,9,1],
[54,48,9,2],
[54,48,9,3],
[54,48,9,4],
[54,48,9,5],
[54,48,9,6],
[54,48,9,7],
[54,48,9,8],
[54,48,13,0],
[54,48,13,1],
[54,48,13,2],
[54,48,13,3],
[54,48,13,4],
[54,48,13,5],
[54,48,13,6],
[54,48,13,7],
[54,48,13,8],
[54,48,13,9],
[54,48,14,0],
[54,48,14,1],
[54,48,14,2],
[54,48,14,3],
[54,48,14,4],
[54,48,14,5],
[54,48,14,6],
[54,48,14,7],
[54,48,14,8],
[54,48,14,9],
[54,48,15,0],
[54,48,15,1],
[54,48,15,2],
[54,48,15,3],
[54,48,15,4],
[54,48,15,5],
[54,48,15,6],
[54,48,15,7],
[54,48,15,8],
[54,48,15,13],
[54,48,15,14],
[54,48,16,0],
[54,48,16,1],
[54,48,16,2],
[54,48,16,3],
[54,48,16,4],
[54,48,16,5],
[54,48,16,6],
[54,48,16,7],
[54,48,16,8],
[54,48,16,9],
[54,48,16,13],
[54,48,16,14],
[54,48,17,0],
[54,48,17,1],
[54,48,17,2],
[54,48,17,3],
[54,48,17,4],
[54,48,17,5],
[54,48,17,6],
[54,48,17,7],
[54,48,17,8],
[54,48,17,9],
[54,48,17,13],
[54,48,17,14],
[54,48,17,16],
[54,48,18,0],
[54,48,18,1],
[54,48,18,2],
[54,48,18,3],
[54,48,18,4],
[54,48,18,5],
[54,48,18,6],
[54,48,18,7],
[54,48,18,8],
[54,48,18,13],
[54,48,18,16],
[54,48,18,17],
[54,48,19,0],
[54,48,19,1],
[54,48,19,2],
[54,48,19,3],
[54,48,19,4],
[54,48,19,5],
[54,48,19,6],
[54,48,19,7],
[54,48,19,8],
[54,48,19,9],
[54,48,19,15],
[54,48,19,16],
[54,48,19,17],
[54,48,19,18],
[54,48,21,0],
[54,48,21,1],
[54,48,21,2],
[54,48,21,4],
[54,48,21,7],
[54,48,21,13],
[54,48,21,14],
[54,48,21,15],
[54,48,21,16],
[54,48,21,17],
[54,48,21,18],
[54,48,21,19],
[54,48,23,0],
[54,48,23,1],
[54,48,23,2],
[54,48,23,3],
[54,48,23,4],
[54,48,23,6],
[54,48,23,7],
[54,48,23,8],
[54,48,23,9],
[54,48,23,13],
[54,48,23,14],
[54,48,23,15],
[54,48,23,16],
[54,48,23,17],
[54,48,23,18],
[54,48,23,19],
[54,48,23,21],
[54,48,24,0],
[54,48,24,1],
[54,48,24,2],
[54,48,24,3],
[54,48,24,4],
[54,48,24,6],
[54,48,24,7],
[54,48,24,8],
[54,48,24,9],
[54,48,24,13],
[54,48,24,14],
[54,48,24,15],
[54,48,24,17],
[54,48,24,18],
[54,48,24,19],
[54,48,24,21],
[54,48,25,3],
[54,48,25,4],
[54,48,25,5],
[54,48,25,6],
[54,48,25,8],
[54,48,25,9],
[54,48,25,13],
[54,48,25,14],
[54,48,25,15],
[54,48,25,16],
[54,48,25,17],
[54,48,25,18],
[54,48,25,19],
[54,48,26,0],
[54,48,26,1],
[54,48,26,2],
[54,48,26,3],
[54,48,26,4],
[54,48,26,5],
[54,48,26,6],
[54,48,26,7],
[54,48,26,8],
[54,48,26,13],
[54,48,26,14],
[54,48,26,16],
[54,48,26,17],
[54,48,26,19],
[54,48,28,0],
[54,48,28,1],
[54,48,28,2],
[54,48,28,3],
[54,48,28,4],
[54,48,28,5],
[54,48,28,6],
[54,48,28,8],
[54,48,28,9],
[54,48,28,13],
[54,48,28,14],
[54,48,28,15],
[54,48,28,16],
[54,48,28,17],
[54,48,30,0],
[54,48,30,1],
[54,48,30,2],
[54,48,30,3],
[54,48,30,4],
[54,48,30,5],
[54,48,30,6],
[54,48,30,7],
[54,48,30,8],
[54,48,30,9],
[54,48,30,13],
[54,48,30,14],
[54,48,31,0],
[54,48,31,1],
[54,48,31,2],
[54,48,31,3],
[54,48,31,4],
[54,48,31,5],
[54,48,31,6],
[54,48,31,7],
[54,48,31,8],
[54,48,31,9],
[54,48,31,13],
[54,48,34,0],
[54,48,34,1],
[54,48,34,2],
[54,48,34,3],
[54,48,34,4],
[54,48,34,5],
[54,48,34,6],
[54,48,35,0],
[54,48,35,1],
[54,48,35,2],
[54,48,35,3],
[54,48,35,4],
[54,48,35,5],
[54,48,36,0],
[54,48,36,1],
[54,48,36,2],
[54,48,36,3],
[54,48,36,4],
[54,48,37,0],
[54,48,37,1],
[54,48,37,2],
[54,49,3,0],
[54,49,3,1],
[54,49,3,2],
[54,49,4,0],
[54,49,4,1],
[54,49,4,2],
[54,49,4,3],
[54,49,5,0],
[54,49,5,1],
[54,49,5,2],
[54,49,5,4],
[54,49,6,0],
[54,49,6,1],
[54,49,6,2],
[54,49,6,4],
[54,49,7,0],
[54,49,7,1],
[54,49,7,2],
[54,49,7,3],
[54,49,7,4],
[54,49,7,5],
[54,49,7,6],
[54,49,8,0],
[54,49,8,1],
[54,49,8,2],
[54,49,8,4],
[54,49,8,7],
[54,49,11,0],
[54,49,11,1],
[54,49,11,2],
[54,49,11,3],
[54,49,11,4],
[54,49,11,5],
[54,49,11,6],
[54,49,11,7],
[54,49,11,8],
[54,49,12,0],
[54,49,12,1],
[54,49,12,2],
[54,49,12,3],
[54,49,12,4],
[54,49,12,5],
[54,49,12,6],
[54,49,12,7],
[54,49,12,8],
[54,49,13,0],
[54,49,13,1],
[54,49,13,2],
[54,49,13,3],
[54,49,13,4],
[54,49,13,5],
[54,49,13,6],
[54,49,13,7],
[54,49,13,8],
[54,49,13,11],
[54,49,13,12],
[54,49,14,0],
[54,49,14,1],
[54,49,14,2],
[54,49,14,3],
[54,49,14,4],
[54,49,14,5],
[54,49,14,6],
[54,49,14,7],
[54,49,14,8],
[54,49,14,11],
[54,49,14,12],
[54,49,16,0],
[54,49,16,1],
[54,49,16,2],
[54,49,16,3],
[54,49,16,4],
[54,49,16,5],
[54,49,16,6],
[54,49,16,7],
[54,49,16,8],
[54,49,16,11],
[54,49,16,12],
[54,49,16,13],
[54,49,16,14],
[54,49,17,0],
[54,49,17,1],
[54,49,17,2],
[54,49,17,3],
[54,49,17,4],
[54,49,17,5],
[54,49,17,6],
[54,49,17,7],
[54,49,17,8],
[54,49,17,11],
[54,49,17,12],
[54,49,17,13],
[54,49,17,14],
[54,49,17,16],
[54,49,19,0],
[54,49,19,1],
[54,49,19,2],
[54,49,19,3],
[54,49,19,4],
[54,49,19,5],
[54,49,19,6],
[54,49,19,7],
[54,49,19,8],
[54,49,19,11],
[54,49,19,16],
[54,49,19,17],
[54,49,21,0],
[54,49,21,1],
[54,49,21,2],
[54,49,21,4],
[54,49,21,7],
[54,49,21,11],
[54,49,21,12],
[54,49,21,13],
[54,49,21,14],
[54,49,21,16],
[54,49,21,17],
[54,49,21,19],
[54,49,23,0],
[54,49,23,1],
[54,49,23,2],
[54,49,23,3],
[54,49,23,4],
[54,49,23,6],
[54,49,23,7],
[54,49,23,8],
[54,49,23,11],
[54,49,23,12],
[54,49,23,13],
[54,49,23,14],
[54,49,23,16],
[54,49,23,17],
[54,49,23,19],
[54,49,23,21],
[54,49,24,0],
[54,49,24,1],
[54,49,24,2],
[54,49,24,3],
[54,49,24,4],
[54,49,24,6],
[54,49,24,7],
[54,49,24,8],
[54,49,24,11],
[54,49,24,12],
[54,49,24,13],
[54,49,24,14],
[54,49,24,17],
[54,49,24,19],
[54,49,24,21],
[54,49,25,3],
[54,49,25,4],
[54,49,25,5],
[54,49,25,6],
[54,49,25,8],
[54,49,25,11],
[54,49,25,12],
[54,49,25,13],
[54,49,25,14],
[54,49,25,16],
[54,49,25,17],
[54,49,25,19],
[54,49,28,0],
[54,49,28,1],
[54,49,28,2],
[54,49,28,3],
[54,49,28,4],
[54,49,28,5],
[54,49,28,6],
[54,49,28,8],
[54,49,28,11],
[54,49,28,12],
[54,49,28,13],
[54,49,28,14],
[54,49,28,16],
[54,49,28,17],
[54,49,29,0],
[54,49,29,1],
[54,49,29,2],
[54,49,29,3],
[54,49,29,4],
[54,49,29,5],
[54,49,29,6],
[54,49,29,7],
[54,49,29,8],
[54,49,29,13],
[54,49,29,14],
[54,49,30,0],
[54,49,30,1],
[54,49,30,2],
[54,49,30,3],
[54,49,30,4],
[54,49,30,5],
[54,49,30,6],
[54,49,30,7],
[54,49,30,8],
[54,49,30,11],
[54,49,30,12],
[54,49,30,13],
[54,49,30,14],
[54,49,31,0],
[54,49,31,1],
[54,49,31,2],
[54,49,31,3],
[54,49,31,4],
[54,49,31,5],
[54,49,31,6],
[54,49,31,7],
[54,49,31,8],
[54,49,31,11],
[54,49,31,12],
[54,49,31,13],
[54,49,32,0],
[54,49,32,1],
[54,49,32,2],
[54,49,32,3],
[54,49,32,4],
[54,49,32,5],
[54,49,32,6],
[54,49,32,7],
[54,49,32,8],
[54,49,33,0],
[54,49,33,1],
[54,49,33,2],
[54,49,33,3],
[54,49,33,4],
[54,49,33,5],
[54,49,33,6],
[54,49,33,7],
[54,49,33,8],
[54,49,34,0],
[54,49,34,1],
[54,49,34,2],
[54,49,34,3],
[54,49,34,4],
[54,49,34,5],
[54,49,34,6],
[54,49,36,0],
[54,49,36,1],
[54,49,36,2],
[54,49,36,3],
[54,49,36,4],
[54,49,37,0],
[54,49,37,1],
[54,49,37,2],
[54,50,3,0],
[54,50,3,1],
[54,50,3,2],
[54,50,4,0],
[54,50,4,1],
[54,50,4,2],
[54,50,4,3],
[54,50,5,0],
[54,50,5,1],
[54,50,5,2],
[54,50,5,4],
[54,50,6,0],
[54,50,6,1],
[54,50,6,2],
[54,50,6,4],
[54,50,7,0],
[54,50,7,1],
[54,50,7,2],
[54,50,7,3],
[54,50,7,4],
[54,50,7,5],
[54,50,7,6],
[54,50,8,0],
[54,50,8,1],
[54,50,8,2],
[54,50,8,4],
[54,50,8,7],
[54,50,11,0],
[54,50,11,1],
[54,50,11,2],
[54,50,11,3],
[54,50,11,4],
[54,50,11,5],
[54,50,11,6],
[54,50,11,7],
[54,50,11,8],
[54,50,12,0],
[54,50,12,1],
[54,50,12,2],
[54,50,12,3],
[54,50,12,4],
[54,50,12,5],
[54,50,12,6],
[54,50,12,7],
[54,50,12,8],
[54,50,13,0],
[54,50,13,1],
[54,50,13,2],
[54,50,13,3],
[54,50,13,4],
[54,50,13,5],
[54,50,13,6],
[54,50,13,7],
[54,50,13,8],
[54,50,13,11],
[54,50,13,12],
[54,50,14,0],
[54,50,14,1],
[54,50,14,2],
[54,50,14,3],
[54,50,14,4],
[54,50,14,5],
[54,50,14,6],
[54,50,14,7],
[54,50,14,8],
[54,50,14,11],
[54,50,14,12],
[54,50,16,0],
[54,50,16,1],
[54,50,16,2],
[54,50,16,3],
[54,50,16,4],
[54,50,16,5],
[54,50,16,6],
[54,50,16,7],
[54,50,16,8],
[54,50,16,11],
[54,50,16,12],
[54,50,16,13],
[54,50,16,14],
[54,50,17,0],
[54,50,17,1],
[54,50,17,2],
[54,50,17,3],
[54,50,17,4],
[54,50,17,5],
[54,50,17,6],
[54,50,17,7],
[54,50,17,8],
[54,50,17,11],
[54,50,17,12],
[54,50,17,13],
[54,50,17,14],
[54,50,17,16],
[54,50,19,0],
[54,50,19,1],
[54,50,19,2],
[54,50,19,3],
[54,50,19,4],
[54,50,19,5],
[54,50,19,6],
[54,50,19,7],
[54,50,19,8],
[54,50,19,11],
[54,50,19,16],
[54,50,19,17],
[54,50,21,0],
[54,50,21,1],
[54,50,21,2],
[54,50,21,4],
[54,50,21,7],
[54,50,21,11],
[54,50,21,12],
[54,50,21,13],
[54,50,21,14],
[54,50,21,16],
[54,50,21,17],
[54,50,21,19],
[54,50,23,0],
[54,50,23,1],
[54,50,23,2],
[54,50,23,3],
[54,50,23,4],
[54,50,23,6],
[54,50,23,7],
[54,50,23,8],
[54,50,23,11],
[54,50,23,12],
[54,50,23,13],
[54,50,23,14],
[54,50,23,16],
[54,50,23,17],
[54,50,23,19],
[54,50,23,21],
[54,50,24,0],
[54,50,24,1],
[54,50,24,2],
[54,50,24,3],
[54,50,24,4],
[54,50,24,6],
[54,50,24,7],
[54,50,24,8],
[54,50,24,11],
[54,50,24,12],
[54,50,24,13],
[54,50,24,14],
[54,50,24,17],
[54,50,24,19],
[54,50,24,21],
[54,50,25,3],
[54,50,25,4],
[54,50,25,5],
[54,50,25,6],
[54,50,25,8],
[54,50,25,11],
[54,50,25,12],
[54,50,25,13],
[54,50,25,14],
[54,50,25,16],
[54,50,25,17],
[54,50,25,19],
[54,50,28,0],
[54,50,28,1],
[54,50,28,2],
[54,50,28,3],
[54,50,28,4],
[54,50,28,5],
[54,50,28,6],
[54,50,28,8],
[54,50,28,11],
[54,50,28,12],
[54,50,28,13],
[54,50,28,14],
[54,50,28,16],
[54,50,28,17],
[54,50,29,0],
[54,50,29,1],
[54,50,29,2],
[54,50,29,3],
[54,50,29,4],
[54,50,29,5],
[54,50,29,6],
[54,50,29,7],
[54,50,29,8],
[54,50,29,13],
[54,50,29,14],
[54,50,30,0],
[54,50,30,1],
[54,50,30,2],
[54,50,30,3],
[54,50,30,4],
[54,50,30,5],
[54,50,30,6],
[54,50,30,7],
[54,50,30,8],
[54,50,30,11],
[54,50,30,12],
[54,50,30,13],
[54,50,30,14],
[54,50,31,0],
[54,50,31,1],
[54,50,31,2],
[54,50,31,3],
[54,50,31,4],
[54,50,31,5],
[54,50,31,6],
[54,50,31,7],
[54,50,31,8],
[54,50,31,11],
[54,50,31,12],
[54,50,31,13],
[54,50,32,0],
[54,50,32,1],
[54,50,32,2],
[54,50,32,3],
[54,50,32,4],
[54,50,32,5],
[54,50,32,6],
[54,50,32,7],
[54,50,32,8],
[54,50,33,0],
[54,50,33,1],
[54,50,33,2],
[54,50,33,3],
[54,50,33,4],
[54,50,33,5],
[54,50,33,6],
[54,50,33,7],
[54,50,33,8],
[54,50,34,0],
[54,50,34,1],
[54,50,34,2],
[54,50,34,3],
[54,50,34,4],
[54,50,34,5],
[54,50,34,6],
[54,50,36,0],
[54,50,36,1],
[54,50,36,2],
[54,50,36,3],
[54,50,36,4],
[54,50,37,0],
[54,50,37,1],
[54,50,37,2],
[54,51,4,0],
[54,51,4,1],
[54,51,4,2],
[54,51,7,0],
[54,51,7,1],
[54,51,7,2],
[54,51,7,4],
[54,51,9,0],
[54,51,9,1],
[54,51,9,2],
[54,51,9,4],
[54,51,9,7],
[54,51,11,0],
[54,51,11,1],
[54,51,11,2],
[54,51,11,4],
[54,51,11,7],
[54,51,11,9],
[54,51,12,0],
[54,51,12,1],
[54,51,12,2],
[54,51,12,4],
[54,51,12,7],
[54,51,12,9],
[54,51,13,0],
[54,51,13,1],
[54,51,13,2],
[54,51,13,4],
[54,51,13,7],
[54,51,13,9],
[54,51,13,11],
[54,51,13,12],
[54,51,14,0],
[54,51,14,1],
[54,51,14,2],
[54,51,14,4],
[54,51,14,7],
[54,51,14,9],
[54,51,14,11],
[54,51,14,12],
[54,51,15,0],
[54,51,15,1],
[54,51,15,2],
[54,51,15,4],
[54,51,15,7],
[54,51,15,11],
[54,51,15,12],
[54,51,15,13],
[54,51,15,14],
[54,51,16,0],
[54,51,16,1],
[54,51,16,2],
[54,51,16,4],
[54,51,16,7],
[54,51,16,9],
[54,51,16,11],
[54,51,16,12],
[54,51,16,13],
[54,51,16,14],
[54,51,17,0],
[54,51,17,1],
[54,51,17,2],
[54,51,17,4],
[54,51,17,7],
[54,51,17,9],
[54,51,17,11],
[54,51,17,12],
[54,51,17,13],
[54,51,17,14],
[54,51,17,16],
[54,51,18,0],
[54,51,18,1],
[54,51,18,2],
[54,51,18,4],
[54,51,18,7],
[54,51,18,11],
[54,51,18,12],
[54,51,18,13],
[54,51,18,16],
[54,51,18,17],
[54,51,19,0],
[54,51,19,1],
[54,51,19,2],
[54,51,19,4],
[54,51,19,7],
[54,51,19,9],
[54,51,19,11],
[54,51,19,15],
[54,51,19,16],
[54,51,19,17],
[54,51,19,18],
[54,51,23,0],
[54,51,23,1],
[54,51,23,2],
[54,51,23,4],
[54,51,23,7],
[54,51,23,9],
[54,51,23,11],
[54,51,23,12],
[54,51,23,13],
[54,51,23,14],
[54,51,23,15],
[54,51,23,16],
[54,51,23,17],
[54,51,23,18],
[54,51,23,19],
[54,51,24,0],
[54,51,24,1],
[54,51,24,2],
[54,51,24,4],
[54,51,24,7],
[54,51,24,9],
[54,51,24,11],
[54,51,24,12],
[54,51,24,13],
[54,51,24,14],
[54,51,24,15],
[54,51,24,17],
[54,51,24,18],
[54,51,24,19],
[54,51,25,4],
[54,51,25,9],
[54,51,25,11],
[54,51,25,12],
[54,51,25,13],
[54,51,25,14],
[54,51,25,15],
[54,51,25,16],
[54,51,25,17],
[54,51,25,18],
[54,51,25,19],
[54,51,26,0],
[54,51,26,1],
[54,51,26,2],
[54,51,26,4],
[54,51,26,7],
[54,51,26,11],
[54,51,26,12],
[54,51,26,13],
[54,51,26,14],
[54,51,26,16],
[54,51,26,17],
[54,51,26,19],
[54,51,28,0],
[54,51,28,1],
[54,51,28,2],
[54,51,28,4],
[54,51,28,9],
[54,51,28,11],
[54,51,28,12],
[54,51,28,13],
[54,51,28,14],
[54,51,28,15],
[54,51,28,16],
[54,51,28,17],
[54,51,29,0],
[54,51,29,1],
[54,51,29,2],
[54,51,29,4],
[54,51,29,7],
[54,51,29,9],
[54,51,29,13],
[54,51,29,14],
[54,51,29,15],
[54,51,30,0],
[54,51,30,1],
[54,51,30,2],
[54,51,30,4],
[54,51,30,7],
[54,51,30,9],
[54,51,30,11],
[54,51,30,12],
[54,51,30,13],
[54,51,30,14],
[54,51,31,0],
[54,51,31,1],
[54,51,31,2],
[54,51,31,4],
[54,51,31,7],
[54,51,31,9],
[54,51,31,11],
[54,51,31,12],
[54,51,31,13],
[54,51,32,0],
[54,51,32,1],
[54,51,32,2],
[54,51,32,4],
[54,51,32,7],
[54,51,32,9],
[54,51,33,0],
[54,51,33,1],
[54,51,33,2],
[54,51,33,4],
[54,51,33,7],
[54,51,33,9],
[54,51,34,0],
[54,51,34,1],
[54,51,34,2],
[54,51,34,4],
[54,51,35,0],
[54,51,35,1],
[54,51,35,2],
[54,51,35,4],
[54,51,36,0],
[54,51,36,1],
[54,51,36,2],
[54,51,36,4],
[54,51,37,0],
[54,51,37,1],
[54,51,37,2],
[54,52,3,0],
[54,52,3,1],
[54,52,3,2],
[54,52,4,0],
[54,52,4,1],
[54,52,4,2],
[54,52,4,3],
[54,52,5,0],
[54,52,5,1],
[54,52,5,2],
[54,52,5,4],
[54,52,6,0],
[54,52,6,1],
[54,52,6,2],
[54,52,6,4],
[54,52,7,0],
[54,52,7,1],
[54,52,7,2],
[54,52,7,3],
[54,52,7,4],
[54,52,7,5],
[54,52,7,6],
[54,52,8,0],
[54,52,8,1],
[54,52,8,2],
[54,52,8,4],
[54,52,8,7],
[54,52,11,0],
[54,52,11,1],
[54,52,11,2],
[54,52,11,3],
[54,52,11,4],
[54,52,11,5],
[54,52,11,6],
[54,52,11,7],
[54,52,11,8],
[54,52,12,0],
[54,52,12,1],
[54,52,12,2],
[54,52,12,3],
[54,52,12,4],
[54,52,12,5],
[54,52,12,6],
[54,52,12,7],
[54,52,12,8],
[54,52,13,0],
[54,52,13,1],
[54,52,13,2],
[54,52,13,3],
[54,52,13,4],
[54,52,13,5],
[54,52,13,6],
[54,52,13,7],
[54,52,13,8],
[54,52,13,11],
[54,52,13,12],
[54,52,14,0],
[54,52,14,1],
[54,52,14,2],
[54,52,14,3],
[54,52,14,4],
[54,52,14,5],
[54,52,14,6],
[54,52,14,7],
[54,52,14,8],
[54,52,14,11],
[54,52,14,12],
[54,52,16,0],
[54,52,16,1],
[54,52,16,2],
[54,52,16,3],
[54,52,16,4],
[54,52,16,5],
[54,52,16,6],
[54,52,16,7],
[54,52,16,8],
[54,52,16,11],
[54,52,16,12],
[54,52,16,13],
[54,52,16,14],
[54,52,17,0],
[54,52,17,1],
[54,52,17,2],
[54,52,17,3],
[54,52,17,4],
[54,52,17,5],
[54,52,17,6],
[54,52,17,7],
[54,52,17,8],
[54,52,17,11],
[54,52,17,12],
[54,52,17,13],
[54,52,17,14],
[54,52,17,16],
[54,52,19,0],
[54,52,19,1],
[54,52,19,2],
[54,52,19,3],
[54,52,19,4],
[54,52,19,5],
[54,52,19,6],
[54,52,19,7],
[54,52,19,8],
[54,52,19,11],
[54,52,19,16],
[54,52,19,17],
[54,52,21,0],
[54,52,21,1],
[54,52,21,2],
[54,52,21,4],
[54,52,21,7],
[54,52,21,11],
[54,52,21,12],
[54,52,21,13],
[54,52,21,14],
[54,52,21,16],
[54,52,21,17],
[54,52,21,19],
[54,52,23,0],
[54,52,23,1],
[54,52,23,2],
[54,52,23,3],
[54,52,23,4],
[54,52,23,6],
[54,52,23,7],
[54,52,23,8],
[54,52,23,11],
[54,52,23,12],
[54,52,23,13],
[54,52,23,14],
[54,52,23,16],
[54,52,23,17],
[54,52,23,19],
[54,52,23,21],
[54,52,24,0],
[54,52,24,1],
[54,52,24,2],
[54,52,24,3],
[54,52,24,4],
[54,52,24,6],
[54,52,24,7],
[54,52,24,8],
[54,52,24,11],
[54,52,24,12],
[54,52,24,13],
[54,52,24,14],
[54,52,24,17],
[54,52,24,19],
[54,52,24,21],
[54,52,25,3],
[54,52,25,4],
[54,52,25,5],
[54,52,25,6],
[54,52,25,8],
[54,52,25,11],
[54,52,25,12],
[54,52,25,13],
[54,52,25,14],
[54,52,25,16],
[54,52,25,17],
[54,52,25,19],
[54,52,28,0],
[54,52,28,1],
[54,52,28,2],
[54,52,28,3],
[54,52,28,4],
[54,52,28,5],
[54,52,28,6],
[54,52,28,8],
[54,52,28,11],
[54,52,28,12],
[54,52,28,13],
[54,52,28,14],
[54,52,28,16],
[54,52,28,17],
[54,52,29,0],
[54,52,29,1],
[54,52,29,2],
[54,52,29,3],
[54,52,29,4],
[54,52,29,5],
[54,52,29,6],
[54,52,29,7],
[54,52,29,8],
[54,52,29,13],
[54,52,29,14],
[54,52,30,0],
[54,52,30,1],
[54,52,30,2],
[54,52,30,3],
[54,52,30,4],
[54,52,30,5],
[54,52,30,6],
[54,52,30,7],
[54,52,30,8],
[54,52,30,11],
[54,52,30,12],
[54,52,30,13],
[54,52,30,14],
[54,52,31,0],
[54,52,31,1],
[54,52,31,2],
[54,52,31,3],
[54,52,31,4],
[54,52,31,5],
[54,52,31,6],
[54,52,31,7],
[54,52,31,8],
[54,52,31,11],
[54,52,31,12],
[54,52,31,13],
[54,52,32,0],
[54,52,32,1],
[54,52,32,2],
[54,52,32,3],
[54,52,32,4],
[54,52,32,5],
[54,52,32,6],
[54,52,32,7],
[54,52,32,8],
[54,52,33,0],
[54,52,33,1],
[54,52,33,2],
[54,52,33,3],
[54,52,33,4],
[54,52,33,5],
[54,52,33,6],
[54,52,33,7],
[54,52,33,8],
[54,52,34,0],
[54,52,34,1],
[54,52,34,2],
[54,52,34,3],
[54,52,34,4],
[54,52,34,5],
[54,52,34,6],
[54,52,36,0],
[54,52,36,1],
[54,52,36,2],
[54,52,36,3],
[54,52,36,4],
[54,52,37,0],
[54,52,37,1],
[54,52,37,2],
[55,4,3,0],
[55,4,3,1],
[55,4,3,2],
[55,5,4,0],
[55,5,4,1],
[55,5,4,2],
[55,6,4,0],
[55,6,4,1],
[55,6,4,2],
[55,7,3,0],
[55,7,3,1],
[55,7,3,2],
[55,7,4,0],
[55,7,4,1],
[55,7,4,2],
[55,7,4,3],
[55,7,5,0],
[55,7,5,1],
[55,7,5,2],
[55,7,5,4],
[55,7,6,0],
[55,7,6,1],
[55,7,6,2],
[55,7,6,4],
[55,8,4,0],
[55,8,4,1],
[55,8,4,2],
[55,8,7,0],
[55,8,7,1],
[55,8,7,2],
[55,8,7,4],
[55,9,3,0],
[55,9,3,1],
[55,9,3,2],
[55,9,4,0],
[55,9,4,1],
[55,9,4,2],
[55,9,4,3],
[55,9,5,0],
[55,9,5,1],
[55,9,5,2],
[55,9,5,4],
[55,9,6,0],
[55,9,6,1],
[55,9,6,2],
[55,9,6,4],
[55,9,7,0],
[55,9,7,1],
[55,9,7,2],
[55,9,7,3],
[55,9,7,4],
[55,9,7,5],
[55,9,7,6],
[55,9,8,0],
[55,9,8,1],
[55,9,8,2],
[55,9,8,4],
[55,9,8,7],
[55,11,3,0],
[55,11,3,1],
[55,11,3,2],
[55,11,4,0],
[55,11,4,1],
[55,11,4,2],
[55,11,4,3],
[55,11,5,0],
[55,11,5,1],
[55,11,5,2],
[55,11,5,4],
[55,11,6,0],
[55,11,6,1],
[55,11,6,2],
[55,11,6,4],
[55,11,7,0],
[55,11,7,1],
[55,11,7,2],
[55,11,7,3],
[55,11,7,4],
[55,11,7,5],
[55,11,7,6],
[55,11,8,0],
[55,11,8,1],
[55,11,8,2],
[55,11,8,4],
[55,11,8,7],
[55,11,9,0],
[55,11,9,1],
[55,11,9,2],
[55,11,9,3],
[55,11,9,4],
[55,11,9,5],
[55,11,9,6],
[55,11,9,7],
[55,11,9,8],
[55,12,3,0],
[55,12,3,1],
[55,12,3,2],
[55,12,4,0],
[55,12,4,1],
[55,12,4,2],
[55,12,4,3],
[55,12,5,0],
[55,12,5,1],
[55,12,5,2],
[55,12,5,4],
[55,12,6,0],
[55,12,6,1],
[55,12,6,2],
[55,12,6,4],
[55,12,7,0],
[55,12,7,1],
[55,12,7,2],
[55,12,7,3],
[55,12,7,4],
[55,12,7,5],
[55,12,7,6],
[55,12,8,0],
[55,12,8,1],
[55,12,8,2],
[55,12,8,4],
[55,12,8,7],
[55,12,9,0],
[55,12,9,1],
[55,12,9,2],
[55,12,9,3],
[55,12,9,4],
[55,12,9,5],
[55,12,9,6],
[55,12,9,7],
[55,12,9,8],
[55,13,3,0],
[55,13,3,1],
[55,13,3,2],
[55,13,4,0],
[55,13,4,1],
[55,13,4,2],
[55,13,4,3],
[55,13,5,0],
[55,13,5,1],
[55,13,5,2],
[55,13,5,4],
[55,13,6,0],
[55,13,6,1],
[55,13,6,2],
[55,13,6,4],
[55,13,7,0],
[55,13,7,1],
[55,13,7,2],
[55,13,7,3],
[55,13,7,4],
[55,13,7,5],
[55,13,7,6],
[55,13,8,0],
[55,13,8,1],
[55,13,8,2],
[55,13,8,4],
[55,13,8,7],
[55,13,9,0],
[55,13,9,1],
[55,13,9,2],
[55,13,9,3],
[55,13,9,4],
[55,13,9,5],
[55,13,9,6],
[55,13,9,7],
[55,13,9,8],
[55,13,11,0],
[55,13,11,1],
[55,13,11,2],
[55,13,11,3],
[55,13,11,4],
[55,13,11,5],
[55,13,11,6],
[55,13,11,7],
[55,13,11,8],
[55,13,11,9],
[55,13,12,0],
[55,13,12,1],
[55,13,12,2],
[55,13,12,3],
[55,13,12,4],
[55,13,12,5],
[55,13,12,6],
[55,13,12,7],
[55,13,12,8],
[55,13,12,9],
[55,14,3,0],
[55,14,3,1],
[55,14,3,2],
[55,14,4,0],
[55,14,4,1],
[55,14,4,2],
[55,14,4,3],
[55,14,5,0],
[55,14,5,1],
[55,14,5,2],
[55,14,5,4],
[55,14,6,0],
[55,14,6,1],
[55,14,6,2],
[55,14,6,4],
[55,14,7,0],
[55,14,7,1],
[55,14,7,2],
[55,14,7,3],
[55,14,7,4],
[55,14,7,5],
[55,14,7,6],
[55,14,8,0],
[55,14,8,1],
[55,14,8,2],
[55,14,8,4],
[55,14,8,7],
[55,14,9,0],
[55,14,9,1],
[55,14,9,2],
[55,14,9,3],
[55,14,9,4],
[55,14,9,5],
[55,14,9,6],
[55,14,9,7],
[55,14,9,8],
[55,14,11,0],
[55,14,11,1],
[55,14,11,2],
[55,14,11,3],
[55,14,11,4],
[55,14,11,5],
[55,14,11,6],
[55,14,11,7],
[55,14,11,8],
[55,14,11,9],
[55,14,12,0],
[55,14,12,1],
[55,14,12,2],
[55,14,12,3],
[55,14,12,4],
[55,14,12,5],
[55,14,12,6],
[55,14,12,7],
[55,14,12,8],
[55,14,12,9],
[55,15,3,0],
[55,15,3,1],
[55,15,3,2],
[55,15,4,0],
[55,15,4,1],
[55,15,4,2],
[55,15,4,3],
[55,15,5,0],
[55,15,5,1],
[55,15,5,2],
[55,15,5,4],
[55,15,6,0],
[55,15,6,1],
[55,15,6,2],
[55,15,6,4],
[55,15,7,0],
[55,15,7,1],
[55,15,7,2],
[55,15,7,3],
[55,15,7,4],
[55,15,7,5],
[55,15,7,6],
[55,15,8,0],
[55,15,8,1],
[55,15,8,2],
[55,15,8,4],
[55,15,8,7],
[55,15,11,0],
[55,15,11,1],
[55,15,11,2],
[55,15,11,3],
[55,15,11,4],
[55,15,11,5],
[55,15,11,6],
[55,15,11,7],
[55,15,11,8],
[55,15,12,0],
[55,15,12,1],
[55,15,12,2],
[55,15,12,3],
[55,15,12,4],
[55,15,12,5],
[55,15,12,6],
[55,15,12,7],
[55,15,12,8],
[55,15,13,0],
[55,15,13,1],
[55,15,13,2],
[55,15,13,3],
[55,15,13,4],
[55,15,13,5],
[55,15,13,6],
[55,15,13,7],
[55,15,13,8],
[55,15,13,11],
[55,15,13,12],
[55,15,14,0],
[55,15,14,1],
[55,15,14,2],
[55,15,14,3],
[55,15,14,4],
[55,15,14,5],
[55,15,14,6],
[55,15,14,7],
[55,15,14,8],
[55,15,14,11],
[55,15,14,12],
[55,16,3,0],
[55,16,3,1],
[55,16,3,2],
[55,16,4,0],
[55,16,4,1],
[55,16,4,2],
[55,16,4,3],
[55,16,5,0],
[55,16,5,1],
[55,16,5,2],
[55,16,5,4],
[55,16,6,0],
[55,16,6,1],
[55,16,6,2],
[55,16,6,4],
[55,16,7,0],
[55,16,7,1],
[55,16,7,2],
[55,16,7,3],
[55,16,7,4],
[55,16,7,5],
[55,16,7,6],
[55,16,8,0],
[55,16,8,1],
[55,16,8,2],
[55,16,8,4],
[55,16,8,7],
[55,16,9,0],
[55,16,9,1],
[55,16,9,2],
[55,16,9,3],
[55,16,9,4],
[55,16,9,5],
[55,16,9,6],
[55,16,9,7],
[55,16,9,8],
[55,16,11,0],
[55,16,11,1],
[55,16,11,2],
[55,16,11,3],
[55,16,11,4],
[55,16,11,5],
[55,16,11,6],
[55,16,11,7],
[55,16,11,8],
[55,16,11,9],
[55,16,12,0],
[55,16,12,1],
[55,16,12,2],
[55,16,12,3],
[55,16,12,4],
[55,16,12,5],
[55,16,12,6],
[55,16,12,7],
[55,16,12,8],
[55,16,12,9],
[55,16,13,0],
[55,16,13,1],
[55,16,13,2],
[55,16,13,3],
[55,16,13,4],
[55,16,13,5],
[55,16,13,6],
[55,16,13,7],
[55,16,13,8],
[55,16,13,9],
[55,16,13,11],
[55,16,13,12],
[55,16,14,0],
[55,16,14,1],
[55,16,14,2],
[55,16,14,3],
[55,16,14,4],
[55,16,14,5],
[55,16,14,6],
[55,16,14,7],
[55,16,14,8],
[55,16,14,9],
[55,16,14,11],
[55,16,14,12],
[55,16,15,0],
[55,16,15,1],
[55,16,15,2],
[55,16,15,3],
[55,16,15,4],
[55,16,15,5],
[55,16,15,6],
[55,16,15,7],
[55,16,15,8],
[55,16,15,11],
[55,16,15,12],
[55,16,15,13],
[55,16,15,14],
[55,17,3,0],
[55,17,3,1],
[55,17,3,2],
[55,17,4,0],
[55,17,4,1],
[55,17,4,2],
[55,17,4,3],
[55,17,5,0],
[55,17,5,1],
[55,17,5,2],
[55,17,5,4],
[55,17,6,0],
[55,17,6,1],
[55,17,6,2],
[55,17,6,4],
[55,17,7,0],
[55,17,7,1],
[55,17,7,2],
[55,17,7,3],
[55,17,7,4],
[55,17,7,5],
[55,17,7,6],
[55,17,8,0],
[55,17,8,1],
[55,17,8,2],
[55,17,8,4],
[55,17,8,7],
[55,17,9,0],
[55,17,9,1],
[55,17,9,2],
[55,17,9,3],
[55,17,9,4],
[55,17,9,5],
[55,17,9,6],
[55,17,9,7],
[55,17,9,8],
[55,17,11,0],
[55,17,11,1],
[55,17,11,2],
[55,17,11,3],
[55,17,11,4],
[55,17,11,5],
[55,17,11,6],
[55,17,11,7],
[55,17,11,8],
[55,17,11,9],
[55,17,12,0],
[55,17,12,1],
[55,17,12,2],
[55,17,12,3],
[55,17,12,4],
[55,17,12,5],
[55,17,12,6],
[55,17,12,7],
[55,17,12,8],
[55,17,12,9],
[55,17,13,0],
[55,17,13,1],
[55,17,13,2],
[55,17,13,3],
[55,17,13,4],
[55,17,13,5],
[55,17,13,6],
[55,17,13,7],
[55,17,13,8],
[55,17,13,9],
[55,17,13,11],
[55,17,13,12],
[55,17,14,0],
[55,17,14,1],
[55,17,14,2],
[55,17,14,3],
[55,17,14,4],
[55,17,14,5],
[55,17,14,6],
[55,17,14,7],
[55,17,14,8],
[55,17,14,9],
[55,17,14,11],
[55,17,14,12],
[55,17,15,0],
[55,17,15,1],
[55,17,15,2],
[55,17,15,3],
[55,17,15,4],
[55,17,15,5],
[55,17,15,6],
[55,17,15,7],
[55,17,15,8],
[55,17,15,11],
[55,17,15,12],
[55,17,15,13],
[55,17,15,14],
[55,17,16,0],
[55,17,16,1],
[55,17,16,2],
[55,17,16,3],
[55,17,16,4],
[55,17,16,5],
[55,17,16,6],
[55,17,16,7],
[55,17,16,8],
[55,17,16,9],
[55,17,16,11],
[55,17,16,12],
[55,17,16,13],
[55,17,16,14],
[55,17,16,15],
[55,18,3,0],
[55,18,3,1],
[55,18,3,2],
[55,18,4,0],
[55,18,4,1],
[55,18,4,2],
[55,18,4,3],
[55,18,5,0],
[55,18,5,1],
[55,18,5,2],
[55,18,5,4],
[55,18,6,0],
[55,18,6,1],
[55,18,6,2],
[55,18,6,4],
[55,18,7,0],
[55,18,7,1],
[55,18,7,2],
[55,18,7,3],
[55,18,7,4],
[55,18,7,5],
[55,18,7,6],
[55,18,8,0],
[55,18,8,1],
[55,18,8,2],
[55,18,8,4],
[55,18,8,7],
[55,18,11,0],
[55,18,11,1],
[55,18,11,2],
[55,18,11,3],
[55,18,11,4],
[55,18,11,5],
[55,18,11,6],
[55,18,11,7],
[55,18,11,8],
[55,18,12,0],
[55,18,12,1],
[55,18,12,2],
[55,18,12,3],
[55,18,12,4],
[55,18,12,5],
[55,18,12,6],
[55,18,12,7],
[55,18,12,8],
[55,18,13,0],
[55,18,13,1],
[55,18,13,2],
[55,18,13,3],
[55,18,13,4],
[55,18,13,5],
[55,18,13,6],
[55,18,13,7],
[55,18,13,8],
[55,18,13,11],
[55,18,13,12],
[55,18,14,0],
[55,18,14,1],
[55,18,14,2],
[55,18,14,3],
[55,18,14,4],
[55,18,14,5],
[55,18,14,6],
[55,18,14,7],
[55,18,14,8],
[55,18,14,11],
[55,18,14,12],
[55,18,16,0],
[55,18,16,1],
[55,18,16,2],
[55,18,16,3],
[55,18,16,4],
[55,18,16,5],
[55,18,16,6],
[55,18,16,7],
[55,18,16,8],
[55,18,16,11],
[55,18,16,12],
[55,18,16,13],
[55,18,16,14],
[55,18,17,0],
[55,18,17,1],
[55,18,17,2],
[55,18,17,3],
[55,18,17,4],
[55,18,17,5],
[55,18,17,6],
[55,18,17,7],
[55,18,17,8],
[55,18,17,11],
[55,18,17,12],
[55,18,17,13],
[55,18,17,14],
[55,18,17,16],
[55,19,3,0],
[55,19,3,1],
[55,19,3,2],
[55,19,4,0],
[55,19,4,1],
[55,19,4,2],
[55,19,4,3],
[55,19,5,0],
[55,19,5,1],
[55,19,5,2],
[55,19,5,4],
[55,19,6,0],
[55,19,6,1],
[55,19,6,2],
[55,19,6,4],
[55,19,7,0],
[55,19,7,1],
[55,19,7,2],
[55,19,7,3],
[55,19,7,4],
[55,19,7,5],
[55,19,7,6],
[55,19,8,0],
[55,19,8,1],
[55,19,8,2],
[55,19,8,4],
[55,19,8,7],
[55,19,9,0],
[55,19,9,1],
[55,19,9,2],
[55,19,9,3],
[55,19,9,4],
[55,19,9,5],
[55,19,9,6],
[55,19,9,7],
[55,19,9,8],
[55,19,11,0],
[55,19,11,1],
[55,19,11,2],
[55,19,11,3],
[55,19,11,4],
[55,19,11,5],
[55,19,11,6],
[55,19,11,7],
[55,19,11,8],
[55,19,11,9],
[55,19,12,0],
[55,19,12,1],
[55,19,12,2],
[55,19,12,3],
[55,19,12,4],
[55,19,12,5],
[55,19,12,6],
[55,19,12,7],
[55,19,12,8],
[55,19,12,9],
[55,19,15,0],
[55,19,15,1],
[55,19,15,2],
[55,19,15,3],
[55,19,15,4],
[55,19,15,5],
[55,19,15,6],
[55,19,15,7],
[55,19,15,8],
[55,19,15,11],
[55,19,15,12],
[55,19,16,0],
[55,19,16,1],
[55,19,16,2],
[55,19,16,3],
[55,19,16,4],
[55,19,16,5],
[55,19,16,6],
[55,19,16,7],
[55,19,16,8],
[55,19,16,9],
[55,19,16,11],
[55,19,16,12],
[55,19,16,15],
[55,19,17,0],
[55,19,17,1],
[55,19,17,2],
[55,19,17,3],
[55,19,17,4],
[55,19,17,5],
[55,19,17,6],
[55,19,17,7],
[55,19,17,8],
[55,19,17,9],
[55,19,17,11],
[55,19,17,12],
[55,19,17,15],
[55,19,17,16],
[55,19,18,0],
[55,19,18,1],
[55,19,18,2],
[55,19,18,3],
[55,19,18,4],
[55,19,18,5],
[55,19,18,6],
[55,19,18,7],
[55,19,18,8],
[55,19,18,11],
[55,19,18,12],
[55,19,18,16],
[55,19,18,17],
[55,21,4,0],
[55,21,4,1],
[55,21,4,2],
[55,21,7,0],
[55,21,7,1],
[55,21,7,2],
[55,21,7,4],
[55,21,9,0],
[55,21,9,1],
[55,21,9,2],
[55,21,9,4],
[55,21,9,7],
[55,21,11,0],
[55,21,11,1],
[55,21,11,2],
[55,21,11,4],
[55,21,11,7],
[55,21,11,9],
[55,21,12,0],
[55,21,12,1],
[55,21,12,2],
[55,21,12,4],
[55,21,12,7],
[55,21,12,9],
[55,21,13,0],
[55,21,13,1],
[55,21,13,2],
[55,21,13,4],
[55,21,13,7],
[55,21,13,9],
[55,21,13,11],
[55,21,13,12],
[55,21,14,0],
[55,21,14,1],
[55,21,14,2],
[55,21,14,4],
[55,21,14,7],
[55,21,14,9],
[55,21,14,11],
[55,21,14,12],
[55,21,15,0],
[55,21,15,1],
[55,21,15,2],
[55,21,15,4],
[55,21,15,7],
[55,21,15,11],
[55,21,15,12],
[55,21,15,13],
[55,21,15,14],
[55,21,16,0],
[55,21,16,1],
[55,21,16,2],
[55,21,16,4],
[55,21,16,7],
[55,21,16,9],
[55,21,16,11],
[55,21,16,12],
[55,21,16,13],
[55,21,16,14],
[55,21,16,15],
[55,21,17,0],
[55,21,17,1],
[55,21,17,2],
[55,21,17,4],
[55,21,17,7],
[55,21,17,9],
[55,21,17,11],
[55,21,17,12],
[55,21,17,13],
[55,21,17,14],
[55,21,17,15],
[55,21,17,16],
[55,21,18,0],
[55,21,18,1],
[55,21,18,2],
[55,21,18,4],
[55,21,18,7],
[55,21,18,11],
[55,21,18,12],
[55,21,18,13],
[55,21,18,14],
[55,21,18,16],
[55,21,18,17],
[55,21,19,0],
[55,21,19,1],
[55,21,19,2],
[55,21,19,4],
[55,21,19,7],
[55,21,19,9],
[55,21,19,11],
[55,21,19,12],
[55,21,19,15],
[55,21,19,16],
[55,21,19,17],
[55,21,19,18],
[55,23,3,0],
[55,23,3,1],
[55,23,3,2],
[55,23,4,0],
[55,23,4,1],
[55,23,4,2],
[55,23,4,3],
[55,23,5,0],
[55,23,5,1],
[55,23,5,2],
[55,23,5,4],
[55,23,6,0],
[55,23,6,1],
[55,23,6,2],
[55,23,6,4],
[55,23,7,0],
[55,23,7,1],
[55,23,7,2],
[55,23,7,3],
[55,23,7,4],
[55,23,7,5],
[55,23,7,6],
[55,23,8,0],
[55,23,8,1],
[55,23,8,2],
[55,23,8,4],
[55,23,8,7],
[55,23,9,0],
[55,23,9,1],
[55,23,9,2],
[55,23,9,3],
[55,23,9,4],
[55,23,9,5],
[55,23,9,6],
[55,23,9,7],
[55,23,9,8],
[55,23,11,0],
[55,23,11,1],
[55,23,11,2],
[55,23,11,3],
[55,23,11,4],
[55,23,11,5],
[55,23,11,6],
[55,23,11,7],
[55,23,11,8],
[55,23,11,9],
[55,23,12,0],
[55,23,12,1],
[55,23,12,2],
[55,23,12,3],
[55,23,12,4],
[55,23,12,5],
[55,23,12,6],
[55,23,12,7],
[55,23,12,8],
[55,23,12,9],
[55,23,13,0],
[55,23,13,1],
[55,23,13,2],
[55,23,13,3],
[55,23,13,4],
[55,23,13,5],
[55,23,13,6],
[55,23,13,7],
[55,23,13,8],
[55,23,13,9],
[55,23,13,11],
[55,23,13,12],
[55,23,14,0],
[55,23,14,1],
[55,23,14,2],
[55,23,14,3],
[55,23,14,4],
[55,23,14,5],
[55,23,14,6],
[55,23,14,7],
[55,23,14,8],
[55,23,14,9],
[55,23,14,11],
[55,23,14,12],
[55,23,15,0],
[55,23,15,1],
[55,23,15,2],
[55,23,15,3],
[55,23,15,4],
[55,23,15,5],
[55,23,15,6],
[55,23,15,7],
[55,23,15,8],
[55,23,15,11],
[55,23,15,12],
[55,23,15,13],
[55,23,15,14],
[55,23,16,0],
[55,23,16,1],
[55,23,16,2],
[55,23,16,3],
[55,23,16,4],
[55,23,16,5],
[55,23,16,6],
[55,23,16,7],
[55,23,16,8],
[55,23,16,9],
[55,23,16,11],
[55,23,16,12],
[55,23,16,13],
[55,23,16,14],
[55,23,16,15],
[55,23,17,0],
[55,23,17,1],
[55,23,17,2],
[55,23,17,3],
[55,23,17,4],
[55,23,17,5],
[55,23,17,6],
[55,23,17,7],
[55,23,17,8],
[55,23,17,9],
[55,23,17,11],
[55,23,17,12],
[55,23,17,13],
[55,23,17,14],
[55,23,17,15],
[55,23,17,16],
[55,23,18,0],
[55,23,18,1],
[55,23,18,2],
[55,23,18,3],
[55,23,18,4],
[55,23,18,5],
[55,23,18,6],
[55,23,18,7],
[55,23,18,8],
[55,23,18,11],
[55,23,18,12],
[55,23,18,13],
[55,23,18,14],
[55,23,18,16],
[55,23,18,17],
[55,23,19,0],
[55,23,19,1],
[55,23,19,2],
[55,23,19,3],
[55,23,19,4],
[55,23,19,5],
[55,23,19,6],
[55,23,19,7],
[55,23,19,8],
[55,23,19,9],
[55,23,19,11],
[55,23,19,12],
[55,23,19,15],
[55,23,19,16],
[55,23,19,17],
[55,23,19,18],
[55,23,21,0],
[55,23,21,1],
[55,23,21,2],
[55,23,21,4],
[55,23,21,7],
[55,23,21,9],
[55,23,21,11],
[55,23,21,12],
[55,23,21,13],
[55,23,21,14],
[55,23,21,15],
[55,23,21,16],
[55,23,21,17],
[55,23,21,18],
[55,23,21,19],
[55,24,3,0],
[55,24,3,1],
[55,24,3,2],
[55,24,4,0],
[55,24,4,1],
[55,24,4,2],
[55,24,4,3],
[55,24,5,0],
[55,24,5,1],
[55,24,5,2],
[55,24,5,4],
[55,24,6,0],
[55,24,6,1],
[55,24,6,2],
[55,24,6,4],
[55,24,7,0],
[55,24,7,1],
[55,24,7,2],
[55,24,7,3],
[55,24,7,4],
[55,24,7,5],
[55,24,7,6],
[55,24,8,0],
[55,24,8,1],
[55,24,8,2],
[55,24,8,4],
[55,24,8,7],
[55,24,9,0],
[55,24,9,1],
[55,24,9,2],
[55,24,9,3],
[55,24,9,4],
[55,24,9,5],
[55,24,9,6],
[55,24,9,7],
[55,24,9,8],
[55,24,11,0],
[55,24,11,1],
[55,24,11,2],
[55,24,11,3],
[55,24,11,4],
[55,24,11,5],
[55,24,11,6],
[55,24,11,7],
[55,24,11,8],
[55,24,11,9],
[55,24,12,0],
[55,24,12,1],
[55,24,12,2],
[55,24,12,3],
[55,24,12,4],
[55,24,12,5],
[55,24,12,6],
[55,24,12,7],
[55,24,12,8],
[55,24,12,9],
[55,24,13,0],
[55,24,13,1],
[55,24,13,2],
[55,24,13,3],
[55,24,13,4],
[55,24,13,5],
[55,24,13,6],
[55,24,13,7],
[55,24,13,8],
[55,24,13,9],
[55,24,13,11],
[55,24,13,12],
[55,24,14,0],
[55,24,14,1],
[55,24,14,2],
[55,24,14,3],
[55,24,14,4],
[55,24,14,5],
[55,24,14,6],
[55,24,14,7],
[55,24,14,8],
[55,24,14,9],
[55,24,14,11],
[55,24,14,12],
[55,24,15,0],
[55,24,15,1],
[55,24,15,2],
[55,24,15,3],
[55,24,15,4],
[55,24,15,5],
[55,24,15,6],
[55,24,15,7],
[55,24,15,8],
[55,24,15,11],
[55,24,15,12],
[55,24,15,13],
[55,24,15,14],
[55,24,17,0],
[55,24,17,1],
[55,24,17,2],
[55,24,17,3],
[55,24,17,4],
[55,24,17,5],
[55,24,17,6],
[55,24,17,7],
[55,24,17,8],
[55,24,17,9],
[55,24,17,11],
[55,24,17,12],
[55,24,17,13],
[55,24,17,14],
[55,24,17,15],
[55,24,18,0],
[55,24,18,1],
[55,24,18,2],
[55,24,18,3],
[55,24,18,4],
[55,24,18,5],
[55,24,18,6],
[55,24,18,7],
[55,24,18,8],
[55,24,18,11],
[55,24,18,12],
[55,24,18,13],
[55,24,18,14],
[55,24,18,17],
[55,24,19,0],
[55,24,19,1],
[55,24,19,2],
[55,24,19,3],
[55,24,19,4],
[55,24,19,5],
[55,24,19,6],
[55,24,19,7],
[55,24,19,8],
[55,24,19,9],
[55,24,19,11],
[55,24,19,12],
[55,24,19,15],
[55,24,19,17],
[55,24,19,18],
[55,24,21,0],
[55,24,21,1],
[55,24,21,2],
[55,24,21,4],
[55,24,21,7],
[55,24,21,9],
[55,24,21,11],
[55,24,21,12],
[55,24,21,13],
[55,24,21,14],
[55,24,21,15],
[55,24,21,17],
[55,24,21,18],
[55,24,21,19],
[55,24,23,0],
[55,24,23,1],
[55,24,23,2],
[55,24,23,3],
[55,24,23,4],
[55,24,23,5],
[55,24,23,6],
[55,24,23,7],
[55,24,23,8],
[55,24,23,9],
[55,24,23,11],
[55,24,23,12],
[55,24,23,13],
[55,24,23,14],
[55,24,23,15],
[55,24,23,17],
[55,24,23,18],
[55,24,23,19],
[55,24,23,21],
[55,25,3,0],
[55,25,3,1],
[55,25,3,2],
[55,25,4,0],
[55,25,4,1],
[55,25,4,2],
[55,25,4,3],
[55,25,5,0],
[55,25,5,1],
[55,25,5,2],
[55,25,5,4],
[55,25,6,0],
[55,25,6,1],
[55,25,6,2],
[55,25,6,4],
[55,25,8,0],
[55,25,8,1],
[55,25,8,2],
[55,25,8,4],
[55,25,9,0],
[55,25,9,1],
[55,25,9,2],
[55,25,9,3],
[55,25,9,4],
[55,25,9,5],
[55,25,9,6],
[55,25,9,8],
[55,25,11,0],
[55,25,11,1],
[55,25,11,2],
[55,25,11,3],
[55,25,11,4],
[55,25,11,5],
[55,25,11,6],
[55,25,11,8],
[55,25,11,9],
[55,25,12,0],
[55,25,12,1],
[55,25,12,2],
[55,25,12,3],
[55,25,12,4],
[55,25,12,5],
[55,25,12,6],
[55,25,12,8],
[55,25,12,9],
[55,25,13,0],
[55,25,13,1],
[55,25,13,2],
[55,25,13,3],
[55,25,13,4],
[55,25,13,5],
[55,25,13,6],
[55,25,13,8],
[55,25,13,9],
[55,25,13,11],
[55,25,13,12],
[55,25,14,0],
[55,25,14,1],
[55,25,14,2],
[55,25,14,3],
[55,25,14,4],
[55,25,14,5],
[55,25,14,6],
[55,25,14,8],
[55,25,14,9],
[55,25,14,11],
[55,25,14,12],
[55,25,15,0],
[55,25,15,1],
[55,25,15,2],
[55,25,15,3],
[55,25,15,4],
[55,25,15,5],
[55,25,15,6],
[55,25,15,8],
[55,25,15,11],
[55,25,15,12],
[55,25,15,13],
[55,25,15,14],
[55,25,16,0],
[55,25,16,1],
[55,25,16,2],
[55,25,16,3],
[55,25,16,4],
[55,25,16,5],
[55,25,16,6],
[55,25,16,8],
[55,25,16,9],
[55,25,16,11],
[55,25,16,12],
[55,25,16,13],
[55,25,16,14],
[55,25,16,15],
[55,25,17,0],
[55,25,17,1],
[55,25,17,2],
[55,25,17,3],
[55,25,17,4],
[55,25,17,5],
[55,25,17,6],
[55,25,17,8],
[55,25,17,9],
[55,25,17,11],
[55,25,17,12],
[55,25,17,13],
[55,25,17,14],
[55,25,17,15],
[55,25,17,16],
[55,25,18,0],
[55,25,18,1],
[55,25,18,2],
[55,25,18,3],
[55,25,18,4],
[55,25,18,5],
[55,25,18,6],
[55,25,18,8],
[55,25,18,11],
[55,25,18,12],
[55,25,18,13],
[55,25,18,14],
[55,25,18,16],
[55,25,18,17],
[55,25,19,0],
[55,25,19,1],
[55,25,19,2],
[55,25,19,3],
[55,25,19,4],
[55,25,19,5],
[55,25,19,6],
[55,25,19,8],
[55,25,19,9],
[55,25,19,11],
[55,25,19,12],
[55,25,19,15],
[55,25,19,16],
[55,25,19,17],
[55,25,19,18],
[55,25,21,0],
[55,25,21,1],
[55,25,21,2],
[55,25,21,4],
[55,25,21,9],
[55,25,21,11],
[55,25,21,12],
[55,25,21,13],
[55,25,21,14],
[55,25,21,15],
[55,25,21,16],
[55,25,21,17],
[55,25,21,18],
[55,25,21,19],
[55,25,23,0],
[55,25,23,1],
[55,25,23,2],
[55,25,23,3],
[55,25,23,4],
[55,25,23,5],
[55,25,23,6],
[55,25,23,8],
[55,25,23,9],
[55,25,23,11],
[55,25,23,12],
[55,25,23,13],
[55,25,23,14],
[55,25,23,15],
[55,25,23,16],
[55,25,23,17],
[55,25,23,18],
[55,25,23,19],
[55,25,23,21],
[55,25,24,0],
[55,25,24,1],
[55,25,24,2],
[55,25,24,3],
[55,25,24,4],
[55,25,24,5],
[55,25,24,6],
[55,25,24,8],
[55,25,24,9],
[55,25,24,11],
[55,25,24,12],
[55,25,24,13],
[55,25,24,14],
[55,25,24,15],
[55,25,24,17],
[55,25,24,18],
[55,25,24,19],
[55,25,24,21],
[55,25,24,23],
[55,26,3,0],
[55,26,3,1],
[55,26,3,2],
[55,26,4,0],
[55,26,4,1],
[55,26,4,2],
[55,26,4,3],
[55,26,5,0],
[55,26,5,1],
[55,26,5,2],
[55,26,5,4],
[55,26,6,0],
[55,26,6,1],
[55,26,6,2],
[55,26,6,4],
[55,26,7,0],
[55,26,7,1],
[55,26,7,2],
[55,26,7,3],
[55,26,7,4],
[55,26,7,5],
[55,26,7,6],
[55,26,8,0],
[55,26,8,1],
[55,26,8,2],
[55,26,8,4],
[55,26,8,7],
[55,26,11,0],
[55,26,11,1],
[55,26,11,2],
[55,26,11,3],
[55,26,11,4],
[55,26,11,5],
[55,26,11,6],
[55,26,11,7],
[55,26,11,8],
[55,26,12,0],
[55,26,12,1],
[55,26,12,2],
[55,26,12,3],
[55,26,12,4],
[55,26,12,5],
[55,26,12,6],
[55,26,12,7],
[55,26,12,8],
[55,26,13,0],
[55,26,13,1],
[55,26,13,2],
[55,26,13,3],
[55,26,13,4],
[55,26,13,5],
[55,26,13,6],
[55,26,13,7],
[55,26,13,8],
[55,26,13,11],
[55,26,13,12],
[55,26,14,0],
[55,26,14,1],
[55,26,14,2],
[55,26,14,3],
[55,26,14,4],
[55,26,14,5],
[55,26,14,6],
[55,26,14,7],
[55,26,14,8],
[55,26,14,11],
[55,26,14,12],
[55,26,16,0],
[55,26,16,1],
[55,26,16,2],
[55,26,16,3],
[55,26,16,4],
[55,26,16,5],
[55,26,16,6],
[55,26,16,7],
[55,26,16,8],
[55,26,16,11],
[55,26,16,12],
[55,26,16,13],
[55,26,16,14],
[55,26,17,0],
[55,26,17,1],
[55,26,17,2],
[55,26,17,3],
[55,26,17,4],
[55,26,17,5],
[55,26,17,6],
[55,26,17,7],
[55,26,17,8],
[55,26,17,11],
[55,26,17,12],
[55,26,17,13],
[55,26,17,14],
[55,26,17,16],
[55,26,19,0],
[55,26,19,1],
[55,26,19,2],
[55,26,19,3],
[55,26,19,4],
[55,26,19,5],
[55,26,19,6],
[55,26,19,7],
[55,26,19,8],
[55,26,19,11],
[55,26,19,12],
[55,26,19,16],
[55,26,19,17],
[55,26,21,0],
[55,26,21,1],
[55,26,21,2],
[55,26,21,4],
[55,26,21,7],
[55,26,21,11],
[55,26,21,12],
[55,26,21,13],
[55,26,21,14],
[55,26,21,16],
[55,26,21,17],
[55,26,21,19],
[55,26,23,0],
[55,26,23,1],
[55,26,23,2],
[55,26,23,3],
[55,26,23,4],
[55,26,23,5],
[55,26,23,6],
[55,26,23,7],
[55,26,23,8],
[55,26,23,11],
[55,26,23,12],
[55,26,23,13],
[55,26,23,14],
[55,26,23,16],
[55,26,23,17],
[55,26,23,19],
[55,26,23,21],
[55,26,24,0],
[55,26,24,1],
[55,26,24,2],
[55,26,24,3],
[55,26,24,4],
[55,26,24,5],
[55,26,24,6],
[55,26,24,7],
[55,26,24,8],
[55,26,24,11],
[55,26,24,12],
[55,26,24,13],
[55,26,24,14],
[55,26,24,17],
[55,26,24,19],
[55,26,24,21],
[55,26,24,23],
[55,26,25,0],
[55,26,25,1],
[55,26,25,2],
[55,26,25,3],
[55,26,25,4],
[55,26,25,5],
[55,26,25,6],
[55,26,25,8],
[55,26,25,11],
[55,26,25,12],
[55,26,25,13],
[55,26,25,14],
[55,26,25,16],
[55,26,25,17],
[55,26,25,19],
[55,26,25,21],
[55,26,25,23],
[55,26,25,24],
[55,28,3,0],
[55,28,3,1],
[55,28,3,2],
[55,28,4,0],
[55,28,4,1],
[55,28,4,2],
[55,28,4,3],
[55,28,5,0],
[55,28,5,1],
[55,28,5,2],
[55,28,5,4],
[55,28,6,0],
[55,28,6,1],
[55,28,6,2],
[55,28,6,4],
[55,28,8,0],
[55,28,8,1],
[55,28,8,2],
[55,28,8,4],
[55,28,9,0],
[55,28,9,1],
[55,28,9,2],
[55,28,9,3],
[55,28,9,4],
[55,28,9,5],
[55,28,9,6],
[55,28,9,8],
[55,28,11,0],
[55,28,11,1],
[55,28,11,2],
[55,28,11,3],
[55,28,11,4],
[55,28,11,5],
[55,28,11,6],
[55,28,11,8],
[55,28,11,9],
[55,28,12,0],
[55,28,12,1],
[55,28,12,2],
[55,28,12,3],
[55,28,12,4],
[55,28,12,5],
[55,28,12,6],
[55,28,12,8],
[55,28,12,9],
[55,28,13,0],
[55,28,13,1],
[55,28,13,2],
[55,28,13,3],
[55,28,13,4],
[55,28,13,5],
[55,28,13,6],
[55,28,13,8],
[55,28,13,9],
[55,28,13,11],
[55,28,13,12],
[55,28,14,0],
[55,28,14,1],
[55,28,14,2],
[55,28,14,3],
[55,28,14,4],
[55,28,14,5],
[55,28,14,6],
[55,28,14,8],
[55,28,14,9],
[55,28,14,11],
[55,28,14,12],
[55,28,15,0],
[55,28,15,1],
[55,28,15,2],
[55,28,15,3],
[55,28,15,4],
[55,28,15,5],
[55,28,15,6],
[55,28,15,8],
[55,28,15,11],
[55,28,15,12],
[55,28,15,13],
[55,28,15,14],
[55,28,16,0],
[55,28,16,1],
[55,28,16,2],
[55,28,16,3],
[55,28,16,4],
[55,28,16,5],
[55,28,16,6],
[55,28,16,8],
[55,28,16,9],
[55,28,16,11],
[55,28,16,12],
[55,28,16,13],
[55,28,16,14],
[55,28,16,15],
[55,28,17,0],
[55,28,17,1],
[55,28,17,2],
[55,28,17,3],
[55,28,17,4],
[55,28,17,5],
[55,28,17,6],
[55,28,17,8],
[55,28,17,9],
[55,28,17,11],
[55,28,17,12],
[55,28,17,13],
[55,28,17,14],
[55,28,17,15],
[55,28,17,16],
[55,28,18,0],
[55,28,18,1],
[55,28,18,2],
[55,28,18,3],
[55,28,18,4],
[55,28,18,5],
[55,28,18,6],
[55,28,18,8],
[55,28,18,11],
[55,28,18,12],
[55,28,18,13],
[55,28,18,14],
[55,28,18,16],
[55,28,18,17],
[55,28,19,0],
[55,28,19,1],
[55,28,19,2],
[55,28,19,3],
[55,28,19,4],
[55,28,19,5],
[55,28,19,6],
[55,28,19,8],
[55,28,19,9],
[55,28,19,11],
[55,28,19,12],
[55,28,19,15],
[55,28,19,16],
[55,28,19,17],
[55,28,19,18],
[55,28,21,0],
[55,28,21,1],
[55,28,21,2],
[55,28,21,4],
[55,28,21,9],
[55,28,21,11],
[55,28,21,12],
[55,28,21,13],
[55,28,21,14],
[55,28,21,15],
[55,28,21,16],
[55,28,21,17],
[55,28,21,18],
[55,28,21,19],
[55,28,23,0],
[55,28,23,1],
[55,28,23,2],
[55,28,23,3],
[55,28,23,4],
[55,28,23,5],
[55,28,23,6],
[55,28,23,8],
[55,28,23,9],
[55,28,23,11],
[55,28,23,12],
[55,28,23,13],
[55,28,23,14],
[55,28,23,15],
[55,28,23,16],
[55,28,23,17],
[55,28,23,18],
[55,28,23,19],
[55,28,23,21],
[55,28,24,0],
[55,28,24,1],
[55,28,24,2],
[55,28,24,3],
[55,28,24,4],
[55,28,24,5],
[55,28,24,6],
[55,28,24,8],
[55,28,24,9],
[55,28,24,11],
[55,28,24,12],
[55,28,24,13],
[55,28,24,14],
[55,28,24,15],
[55,28,24,17],
[55,28,24,18],
[55,28,24,19],
[55,28,24,21],
[55,28,24,23],
[55,28,26,0],
[55,28,26,1],
[55,28,26,2],
[55,28,26,3],
[55,28,26,4],
[55,28,26,5],
[55,28,26,6],
[55,28,26,8],
[55,28,26,11],
[55,28,26,12],
[55,28,26,13],
[55,28,26,14],
[55,28,26,16],
[55,28,26,17],
[55,28,26,19],
[55,28,26,21],
[55,28,26,23],
[55,28,26,24],
[55,29,3,0],
[55,29,3,1],
[55,29,3,2],
[55,29,4,0],
[55,29,4,1],
[55,29,4,2],
[55,29,4,3],
[55,29,5,0],
[55,29,5,1],
[55,29,5,2],
[55,29,5,4],
[55,29,6,0],
[55,29,6,1],
[55,29,6,2],
[55,29,6,4],
[55,29,7,0],
[55,29,7,1],
[55,29,7,2],
[55,29,7,3],
[55,29,7,4],
[55,29,7,5],
[55,29,7,6],
[55,29,8,0],
[55,29,8,1],
[55,29,8,2],
[55,29,8,4],
[55,29,8,7],
[55,29,9,0],
[55,29,9,1],
[55,29,9,2],
[55,29,9,3],
[55,29,9,4],
[55,29,9,5],
[55,29,9,6],
[55,29,9,7],
[55,29,9,8],
[55,29,13,0],
[55,29,13,1],
[55,29,13,2],
[55,29,13,3],
[55,29,13,4],
[55,29,13,5],
[55,29,13,6],
[55,29,13,7],
[55,29,13,8],
[55,29,13,9],
[55,29,14,0],
[55,29,14,1],
[55,29,14,2],
[55,29,14,3],
[55,29,14,4],
[55,29,14,5],
[55,29,14,6],
[55,29,14,7],
[55,29,14,8],
[55,29,14,9],
[55,29,15,0],
[55,29,15,1],
[55,29,15,2],
[55,29,15,3],
[55,29,15,4],
[55,29,15,5],
[55,29,15,6],
[55,29,15,7],
[55,29,15,8],
[55,29,15,13],
[55,29,15,14],
[55,29,16,0],
[55,29,16,1],
[55,29,16,2],
[55,29,16,3],
[55,29,16,4],
[55,29,16,5],
[55,29,16,6],
[55,29,16,7],
[55,29,16,8],
[55,29,16,9],
[55,29,16,13],
[55,29,16,14],
[55,29,16,15],
[55,29,17,0],
[55,29,17,1],
[55,29,17,2],
[55,29,17,3],
[55,29,17,4],
[55,29,17,5],
[55,29,17,6],
[55,29,17,7],
[55,29,17,8],
[55,29,17,9],
[55,29,17,13],
[55,29,17,14],
[55,29,17,15],
[55,29,17,16],
[55,29,18,0],
[55,29,18,1],
[55,29,18,2],
[55,29,18,3],
[55,29,18,4],
[55,29,18,5],
[55,29,18,6],
[55,29,18,7],
[55,29,18,8],
[55,29,18,13],
[55,29,18,14],
[55,29,18,16],
[55,29,18,17],
[55,29,19,0],
[55,29,19,1],
[55,29,19,2],
[55,29,19,3],
[55,29,19,4],
[55,29,19,5],
[55,29,19,6],
[55,29,19,7],
[55,29,19,8],
[55,29,19,9],
[55,29,19,15],
[55,29,19,16],
[55,29,19,17],
[55,29,19,18],
[55,29,21,0],
[55,29,21,1],
[55,29,21,2],
[55,29,21,4],
[55,29,21,7],
[55,29,21,9],
[55,29,21,13],
[55,29,21,14],
[55,29,21,15],
[55,29,21,16],
[55,29,21,17],
[55,29,21,18],
[55,29,21,19],
[55,29,23,0],
[55,29,23,1],
[55,29,23,2],
[55,29,23,3],
[55,29,23,4],
[55,29,23,5],
[55,29,23,6],
[55,29,23,7],
[55,29,23,8],
[55,29,23,9],
[55,29,23,13],
[55,29,23,14],
[55,29,23,15],
[55,29,23,16],
[55,29,23,17],
[55,29,23,18],
[55,29,23,19],
[55,29,23,21],
[55,29,24,0],
[55,29,24,1],
[55,29,24,2],
[55,29,24,3],
[55,29,24,4],
[55,29,24,5],
[55,29,24,6],
[55,29,24,7],
[55,29,24,8],
[55,29,24,9],
[55,29,24,13],
[55,29,24,14],
[55,29,24,15],
[55,29,24,17],
[55,29,24,18],
[55,29,24,19],
[55,29,24,21],
[55,29,24,23],
[55,29,25,0],
[55,29,25,1],
[55,29,25,2],
[55,29,25,3],
[55,29,25,4],
[55,29,25,5],
[55,29,25,6],
[55,29,25,8],
[55,29,25,9],
[55,29,25,13],
[55,29,25,14],
[55,29,25,15],
[55,29,25,16],
[55,29,25,17],
[55,29,25,18],
[55,29,25,19],
[55,29,25,21],
[55,29,25,23],
[55,29,25,24],
[55,29,26,0],
[55,29,26,1],
[55,29,26,2],
[55,29,26,3],
[55,29,26,4],
[55,29,26,5],
[55,29,26,6],
[55,29,26,7],
[55,29,26,8],
[55,29,26,13],
[55,29,26,14],
[55,29,26,16],
[55,29,26,17],
[55,29,26,19],
[55,29,26,21],
[55,29,26,23],
[55,29,26,24],
[55,29,28,0],
[55,29,28,1],
[55,29,28,2],
[55,29,28,3],
[55,29,28,4],
[55,29,28,5],
[55,29,28,6],
[55,29,28,8],
[55,29,28,9],
[55,29,28,13],
[55,29,28,14],
[55,29,28,15],
[55,29,28,16],
[55,29,28,17],
[55,29,28,18],
[55,29,28,19],
[55,29,28,21],
[55,29,28,26],
[55,30,3,0],
[55,30,3,1],
[55,30,3,2],
[55,30,4,0],
[55,30,4,1],
[55,30,4,2],
[55,30,4,3],
[55,30,5,0],
[55,30,5,1],
[55,30,5,2],
[55,30,5,4],
[55,30,6,0],
[55,30,6,1],
[55,30,6,2],
[55,30,6,4],
[55,30,7,0],
[55,30,7,1],
[55,30,7,2],
[55,30,7,3],
[55,30,7,4],
[55,30,7,5],
[55,30,7,6],
[55,30,8,0],
[55,30,8,1],
[55,30,8,2],
[55,30,8,4],
[55,30,8,7],
[55,30,9,0],
[55,30,9,1],
[55,30,9,2],
[55,30,9,3],
[55,30,9,4],
[55,30,9,5],
[55,30,9,6],
[55,30,9,7],
[55,30,9,8],
[55,30,11,0],
[55,30,11,1],
[55,30,11,2],
[55,30,11,3],
[55,30,11,4],
[55,30,11,5],
[55,30,11,6],
[55,30,11,7],
[55,30,11,8],
[55,30,11,9],
[55,30,12,0],
[55,30,12,1],
[55,30,12,2],
[55,30,12,3],
[55,30,12,4],
[55,30,12,5],
[55,30,12,6],
[55,30,12,7],
[55,30,12,8],
[55,30,12,9],
[55,30,13,0],
[55,30,13,1],
[55,30,13,2],
[55,30,13,3],
[55,30,13,4],
[55,30,13,5],
[55,30,13,6],
[55,30,13,7],
[55,30,13,8],
[55,30,13,9],
[55,30,13,11],
[55,30,13,12],
[55,30,14,0],
[55,30,14,1],
[55,30,14,2],
[55,30,14,3],
[55,30,14,4],
[55,30,14,5],
[55,30,14,6],
[55,30,14,7],
[55,30,14,8],
[55,30,14,9],
[55,30,14,11],
[55,30,14,12],
[55,30,15,0],
[55,30,15,1],
[55,30,15,2],
[55,30,15,3],
[55,30,15,4],
[55,30,15,5],
[55,30,15,6],
[55,30,15,7],
[55,30,15,8],
[55,30,15,11],
[55,30,15,12],
[55,30,15,13],
[55,30,15,14],
[55,30,16,0],
[55,30,16,1],
[55,30,16,2],
[55,30,16,3],
[55,30,16,4],
[55,30,16,5],
[55,30,16,6],
[55,30,16,7],
[55,30,16,8],
[55,30,16,9],
[55,30,16,11],
[55,30,16,12],
[55,30,16,13],
[55,30,16,14],
[55,30,16,15],
[55,30,17,0],
[55,30,17,1],
[55,30,17,2],
[55,30,17,3],
[55,30,17,4],
[55,30,17,5],
[55,30,17,6],
[55,30,17,7],
[55,30,17,8],
[55,30,17,9],
[55,30,17,11],
[55,30,17,12],
[55,30,17,13],
[55,30,17,14],
[55,30,17,15],
[55,30,17,16],
[55,30,18,0],
[55,30,18,1],
[55,30,18,2],
[55,30,18,3],
[55,30,18,4],
[55,30,18,5],
[55,30,18,6],
[55,30,18,7],
[55,30,18,8],
[55,30,18,11],
[55,30,18,12],
[55,30,18,13],
[55,30,18,14],
[55,30,18,16],
[55,30,18,17],
[55,30,19,0],
[55,30,19,1],
[55,30,19,2],
[55,30,19,3],
[55,30,19,4],
[55,30,19,5],
[55,30,19,6],
[55,30,19,7],
[55,30,19,8],
[55,30,19,9],
[55,30,19,11],
[55,30,19,12],
[55,30,19,15],
[55,30,19,16],
[55,30,19,17],
[55,30,19,18],
[55,30,21,0],
[55,30,21,1],
[55,30,21,2],
[55,30,21,4],
[55,30,21,7],
[55,30,21,9],
[55,30,21,11],
[55,30,21,12],
[55,30,21,13],
[55,30,21,14],
[55,30,21,15],
[55,30,21,16],
[55,30,21,17],
[55,30,21,18],
[55,30,21,19],
[55,30,24,0],
[55,30,24,1],
[55,30,24,2],
[55,30,24,3],
[55,30,24,4],
[55,30,24,5],
[55,30,24,6],
[55,30,24,7],
[55,30,24,8],
[55,30,24,9],
[55,30,24,11],
[55,30,24,12],
[55,30,24,13],
[55,30,24,14],
[55,30,24,15],
[55,30,24,17],
[55,30,24,18],
[55,30,24,19],
[55,30,24,21],
[55,30,25,0],
[55,30,25,1],
[55,30,25,2],
[55,30,25,3],
[55,30,25,4],
[55,30,25,5],
[55,30,25,6],
[55,30,25,8],
[55,30,25,9],
[55,30,25,11],
[55,30,25,12],
[55,30,25,13],
[55,30,25,14],
[55,30,25,15],
[55,30,25,16],
[55,30,25,17],
[55,30,25,18],
[55,30,25,19],
[55,30,25,21],
[55,30,25,24],
[55,30,26,0],
[55,30,26,1],
[55,30,26,2],
[55,30,26,3],
[55,30,26,4],
[55,30,26,5],
[55,30,26,6],
[55,30,26,7],
[55,30,26,8],
[55,30,26,11],
[55,30,26,12],
[55,30,26,13],
[55,30,26,14],
[55,30,26,16],
[55,30,26,17],
[55,30,26,19],
[55,30,26,21],
[55,30,26,24],
[55,30,26,25],
[55,30,28,0],
[55,30,28,1],
[55,30,28,2],
[55,30,28,3],
[55,30,28,4],
[55,30,28,5],
[55,30,28,6],
[55,30,28,8],
[55,30,28,9],
[55,30,28,11],
[55,30,28,12],
[55,30,28,13],
[55,30,28,14],
[55,30,28,15],
[55,30,28,16],
[55,30,28,17],
[55,30,28,18],
[55,30,28,19],
[55,30,28,21],
[55,30,28,24],
[55,30,28,26],
[55,30,29,0],
[55,30,29,1],
[55,30,29,2],
[55,30,29,3],
[55,30,29,4],
[55,30,29,5],
[55,30,29,6],
[55,30,29,7],
[55,30,29,8],
[55,30,29,9],
[55,30,29,13],
[55,30,29,14],
[55,30,29,15],
[55,30,29,16],
[55,30,29,17],
[55,30,29,18],
[55,30,29,19],
[55,30,29,24],
[55,30,29,25],
[55,30,29,26],
[55,30,29,28],
[55,31,3,0],
[55,31,3,1],
[55,31,3,2],
[55,31,4,0],
[55,31,4,1],
[55,31,4,2],
[55,31,4,3],
[55,31,5,0],
[55,31,5,1],
[55,31,5,2],
[55,31,5,4],
[55,31,6,0],
[55,31,6,1],
[55,31,6,2],
[55,31,6,4],
[55,31,7,0],
[55,31,7,1],
[55,31,7,2],
[55,31,7,3],
[55,31,7,4],
[55,31,7,5],
[55,31,7,6],
[55,31,8,0],
[55,31,8,1],
[55,31,8,2],
[55,31,8,4],
[55,31,8,7],
[55,31,9,0],
[55,31,9,1],
[55,31,9,2],
[55,31,9,3],
[55,31,9,4],
[55,31,9,5],
[55,31,9,6],
[55,31,9,7],
[55,31,9,8],
[55,31,11,0],
[55,31,11,1],
[55,31,11,2],
[55,31,11,3],
[55,31,11,4],
[55,31,11,5],
[55,31,11,6],
[55,31,11,7],
[55,31,11,8],
[55,31,11,9],
[55,31,12,0],
[55,31,12,1],
[55,31,12,2],
[55,31,12,3],
[55,31,12,4],
[55,31,12,5],
[55,31,12,6],
[55,31,12,7],
[55,31,12,8],
[55,31,12,9],
[55,31,13,0],
[55,31,13,1],
[55,31,13,2],
[55,31,13,3],
[55,31,13,4],
[55,31,13,5],
[55,31,13,6],
[55,31,13,7],
[55,31,13,8],
[55,31,13,9],
[55,31,13,11],
[55,31,13,12],
[55,31,14,0],
[55,31,14,1],
[55,31,14,2],
[55,31,14,3],
[55,31,14,4],
[55,31,14,5],
[55,31,14,6],
[55,31,14,7],
[55,31,14,8],
[55,31,14,9],
[55,31,14,11],
[55,31,14,12],
[55,31,15,0],
[55,31,15,1],
[55,31,15,2],
[55,31,15,3],
[55,31,15,4],
[55,31,15,5],
[55,31,15,6],
[55,31,15,7],
[55,31,15,8],
[55,31,15,11],
[55,31,15,12],
[55,31,15,13],
[55,31,15,14],
[55,31,16,0],
[55,31,16,1],
[55,31,16,2],
[55,31,16,3],
[55,31,16,4],
[55,31,16,5],
[55,31,16,6],
[55,31,16,7],
[55,31,16,8],
[55,31,16,9],
[55,31,16,11],
[55,31,16,12],
[55,31,16,13],
[55,31,16,14],
[55,31,16,15],
[55,31,17,0],
[55,31,17,1],
[55,31,17,2],
[55,31,17,3],
[55,31,17,4],
[55,31,17,5],
[55,31,17,6],
[55,31,17,7],
[55,31,17,8],
[55,31,17,9],
[55,31,17,11],
[55,31,17,12],
[55,31,17,13],
[55,31,17,14],
[55,31,17,15],
[55,31,17,16],
[55,31,18,0],
[55,31,18,1],
[55,31,18,2],
[55,31,18,3],
[55,31,18,4],
[55,31,18,5],
[55,31,18,6],
[55,31,18,7],
[55,31,18,8],
[55,31,18,11],
[55,31,18,12],
[55,31,18,13],
[55,31,18,14],
[55,31,18,16],
[55,31,18,17],
[55,31,19,0],
[55,31,19,1],
[55,31,19,2],
[55,31,19,3],
[55,31,19,4],
[55,31,19,5],
[55,31,19,6],
[55,31,19,7],
[55,31,19,8],
[55,31,19,9],
[55,31,19,11],
[55,31,19,12],
[55,31,19,15],
[55,31,19,16],
[55,31,19,17],
[55,31,19,18],
[55,31,21,0],
[55,31,21,1],
[55,31,21,2],
[55,31,21,4],
[55,31,21,7],
[55,31,21,9],
[55,31,21,11],
[55,31,21,12],
[55,31,21,13],
[55,31,21,14],
[55,31,21,15],
[55,31,21,16],
[55,31,21,17],
[55,31,21,18],
[55,31,21,19],
[55,31,24,0],
[55,31,24,1],
[55,31,24,2],
[55,31,24,3],
[55,31,24,4],
[55,31,24,5],
[55,31,24,6],
[55,31,24,7],
[55,31,24,8],
[55,31,24,9],
[55,31,24,11],
[55,31,24,12],
[55,31,24,13],
[55,31,24,14],
[55,31,24,15],
[55,31,24,17],
[55,31,24,18],
[55,31,24,19],
[55,31,24,21],
[55,31,25,0],
[55,31,25,1],
[55,31,25,2],
[55,31,25,3],
[55,31,25,4],
[55,31,25,5],
[55,31,25,6],
[55,31,25,8],
[55,31,25,9],
[55,31,25,11],
[55,31,25,12],
[55,31,25,13],
[55,31,25,14],
[55,31,25,15],
[55,31,25,16],
[55,31,25,17],
[55,31,25,18],
[55,31,25,19],
[55,31,25,21],
[55,31,25,24],
[55,31,26,0],
[55,31,26,1],
[55,31,26,2],
[55,31,26,3],
[55,31,26,4],
[55,31,26,5],
[55,31,26,6],
[55,31,26,7],
[55,31,26,8],
[55,31,26,11],
[55,31,26,12],
[55,31,26,13],
[55,31,26,14],
[55,31,26,16],
[55,31,26,17],
[55,31,26,19],
[55,31,26,21],
[55,31,26,25],
[55,31,28,0],
[55,31,28,1],
[55,31,28,2],
[55,31,28,3],
[55,31,28,4],
[55,31,28,5],
[55,31,28,6],
[55,31,28,8],
[55,31,28,9],
[55,31,28,11],
[55,31,28,12],
[55,31,28,13],
[55,31,28,14],
[55,31,28,15],
[55,31,28,16],
[55,31,28,17],
[55,31,28,18],
[55,31,28,19],
[55,31,28,24],
[55,31,28,26],
[55,31,29,0],
[55,31,29,1],
[55,31,29,2],
[55,31,29,3],
[55,31,29,4],
[55,31,29,5],
[55,31,29,6],
[55,31,29,7],
[55,31,29,8],
[55,31,29,9],
[55,31,29,13],
[55,31,29,14],
[55,31,29,15],
[55,31,29,16],
[55,31,29,17],
[55,31,29,18],
[55,31,29,19],
[55,31,29,21],
[55,31,29,24],
[55,31,29,25],
[55,31,29,26],
[55,31,29,28],
[55,32,3,0],
[55,32,3,1],
[55,32,3,2],
[55,32,4,0],
[55,32,4,1],
[55,32,4,2],
[55,32,4,3],
[55,32,5,0],
[55,32,5,1],
[55,32,5,2],
[55,32,5,4],
[55,32,6,0],
[55,32,6,1],
[55,32,6,2],
[55,32,6,4],
[55,32,7,0],
[55,32,7,1],
[55,32,7,2],
[55,32,7,3],
[55,32,7,4],
[55,32,7,5],
[55,32,7,6],
[55,32,8,0],
[55,32,8,1],
[55,32,8,2],
[55,32,8,4],
[55,32,8,7],
[55,32,9,0],
[55,32,9,1],
[55,32,9,2],
[55,32,9,3],
[55,32,9,4],
[55,32,9,5],
[55,32,9,6],
[55,32,9,7],
[55,32,9,8],
[55,32,13,0],
[55,32,13,1],
[55,32,13,2],
[55,32,13,3],
[55,32,13,4],
[55,32,13,5],
[55,32,13,6],
[55,32,13,7],
[55,32,13,8],
[55,32,13,9],
[55,32,14,0],
[55,32,14,1],
[55,32,14,2],
[55,32,14,3],
[55,32,14,4],
[55,32,14,5],
[55,32,14,6],
[55,32,14,7],
[55,32,14,8],
[55,32,14,9],
[55,32,15,0],
[55,32,15,1],
[55,32,15,2],
[55,32,15,3],
[55,32,15,4],
[55,32,15,5],
[55,32,15,6],
[55,32,15,7],
[55,32,15,8],
[55,32,15,13],
[55,32,15,14],
[55,32,16,0],
[55,32,16,1],
[55,32,16,2],
[55,32,16,3],
[55,32,16,4],
[55,32,16,5],
[55,32,16,6],
[55,32,16,7],
[55,32,16,8],
[55,32,16,9],
[55,32,16,13],
[55,32,16,14],
[55,32,16,15],
[55,32,17,0],
[55,32,17,1],
[55,32,17,2],
[55,32,17,3],
[55,32,17,4],
[55,32,17,5],
[55,32,17,6],
[55,32,17,7],
[55,32,17,8],
[55,32,17,9],
[55,32,17,13],
[55,32,17,14],
[55,32,17,15],
[55,32,17,16],
[55,32,18,0],
[55,32,18,1],
[55,32,18,2],
[55,32,18,3],
[55,32,18,4],
[55,32,18,5],
[55,32,18,6],
[55,32,18,7],
[55,32,18,8],
[55,32,18,13],
[55,32,18,14],
[55,32,18,16],
[55,32,18,17],
[55,32,19,0],
[55,32,19,1],
[55,32,19,2],
[55,32,19,3],
[55,32,19,4],
[55,32,19,5],
[55,32,19,6],
[55,32,19,7],
[55,32,19,8],
[55,32,19,9],
[55,32,19,15],
[55,32,19,16],
[55,32,19,17],
[55,32,19,18],
[55,32,21,0],
[55,32,21,1],
[55,32,21,2],
[55,32,21,4],
[55,32,21,7],
[55,32,21,9],
[55,32,21,13],
[55,32,21,14],
[55,32,21,15],
[55,32,21,16],
[55,32,21,17],
[55,32,21,18],
[55,32,21,19],
[55,32,23,0],
[55,32,23,1],
[55,32,23,2],
[55,32,23,3],
[55,32,23,4],
[55,32,23,5],
[55,32,23,6],
[55,32,23,7],
[55,32,23,8],
[55,32,23,9],
[55,32,23,13],
[55,32,23,14],
[55,32,23,15],
[55,32,23,16],
[55,32,23,17],
[55,32,23,18],
[55,32,23,19],
[55,32,23,21],
[55,32,24,0],
[55,32,24,1],
[55,32,24,2],
[55,32,24,3],
[55,32,24,4],
[55,32,24,5],
[55,32,24,6],
[55,32,24,7],
[55,32,24,8],
[55,32,24,9],
[55,32,24,13],
[55,32,24,14],
[55,32,24,15],
[55,32,24,17],
[55,32,24,18],
[55,32,24,19],
[55,32,24,21],
[55,32,24,23],
[55,32,25,0],
[55,32,25,1],
[55,32,25,2],
[55,32,25,3],
[55,32,25,4],
[55,32,25,5],
[55,32,25,6],
[55,32,25,8],
[55,32,25,9],
[55,32,25,13],
[55,32,25,14],
[55,32,25,15],
[55,32,25,16],
[55,32,25,17],
[55,32,25,18],
[55,32,25,19],
[55,32,25,21],
[55,32,26,0],
[55,32,26,1],
[55,32,26,2],
[55,32,26,3],
[55,32,26,4],
[55,32,26,5],
[55,32,26,6],
[55,32,26,7],
[55,32,26,8],
[55,32,26,13],
[55,32,26,14],
[55,32,26,16],
[55,32,26,17],
[55,32,26,19],
[55,32,26,21],
[55,32,26,23],
[55,32,26,24],
[55,32,26,25],
[55,32,28,0],
[55,32,28,1],
[55,32,28,2],
[55,32,28,3],
[55,32,28,4],
[55,32,28,5],
[55,32,28,6],
[55,32,28,8],
[55,32,28,9],
[55,32,28,13],
[55,32,28,14],
[55,32,28,15],
[55,32,28,16],
[55,32,28,17],
[55,32,28,18],
[55,32,28,19],
[55,32,28,21],
[55,32,28,23],
[55,32,28,24],
[55,32,28,26],
[55,32,30,0],
[55,32,30,1],
[55,32,30,2],
[55,32,30,3],
[55,32,30,4],
[55,32,30,5],
[55,32,30,6],
[55,32,30,7],
[55,32,30,8],
[55,32,30,9],
[55,32,30,13],
[55,32,30,14],
[55,32,30,15],
[55,32,30,16],
[55,32,30,17],
[55,32,30,19],
[55,32,30,21],
[55,32,30,24],
[55,32,30,25],
[55,32,30,26],
[55,32,30,28],
[55,32,31,0],
[55,32,31,1],
[55,32,31,2],
[55,32,31,3],
[55,32,31,4],
[55,32,31,5],
[55,32,31,6],
[55,32,31,7],
[55,32,31,8],
[55,32,31,9],
[55,32,31,13],
[55,32,31,14],
[55,32,31,15],
[55,32,31,18],
[55,32,31,19],
[55,32,31,21],
[55,32,31,24],
[55,32,31,25],
[55,32,31,26],
[55,32,31,28],
[55,33,3,0],
[55,33,3,1],
[55,33,3,2],
[55,33,4,0],
[55,33,4,1],
[55,33,4,2],
[55,33,4,3],
[55,33,5,0],
[55,33,5,1],
[55,33,5,2],
[55,33,5,4],
[55,33,6,0],
[55,33,6,1],
[55,33,6,2],
[55,33,6,4],
[55,33,7,0],
[55,33,7,1],
[55,33,7,2],
[55,33,7,3],
[55,33,7,4],
[55,33,7,5],
[55,33,7,6],
[55,33,8,0],
[55,33,8,1],
[55,33,8,2],
[55,33,8,4],
[55,33,8,7],
[55,33,9,0],
[55,33,9,1],
[55,33,9,2],
[55,33,9,3],
[55,33,9,4],
[55,33,9,5],
[55,33,9,6],
[55,33,9,7],
[55,33,9,8],
[55,33,13,0],
[55,33,13,1],
[55,33,13,2],
[55,33,13,3],
[55,33,13,4],
[55,33,13,5],
[55,33,13,6],
[55,33,13,7],
[55,33,13,8],
[55,33,13,9],
[55,33,14,0],
[55,33,14,1],
[55,33,14,2],
[55,33,14,3],
[55,33,14,4],
[55,33,14,5],
[55,33,14,6],
[55,33,14,7],
[55,33,14,8],
[55,33,14,9],
[55,33,15,0],
[55,33,15,1],
[55,33,15,2],
[55,33,15,3],
[55,33,15,4],
[55,33,15,5],
[55,33,15,6],
[55,33,15,7],
[55,33,15,8],
[55,33,15,13],
[55,33,15,14],
[55,33,16,0],
[55,33,16,1],
[55,33,16,2],
[55,33,16,3],
[55,33,16,4],
[55,33,16,5],
[55,33,16,6],
[55,33,16,7],
[55,33,16,8],
[55,33,16,9],
[55,33,16,13],
[55,33,16,14],
[55,33,16,15],
[55,33,17,0],
[55,33,17,1],
[55,33,17,2],
[55,33,17,3],
[55,33,17,4],
[55,33,17,5],
[55,33,17,6],
[55,33,17,7],
[55,33,17,8],
[55,33,17,9],
[55,33,17,13],
[55,33,17,14],
[55,33,17,15],
[55,33,17,16],
[55,33,18,0],
[55,33,18,1],
[55,33,18,2],
[55,33,18,3],
[55,33,18,4],
[55,33,18,5],
[55,33,18,6],
[55,33,18,7],
[55,33,18,8],
[55,33,18,13],
[55,33,18,14],
[55,33,18,16],
[55,33,18,17],
[55,33,19,0],
[55,33,19,1],
[55,33,19,2],
[55,33,19,3],
[55,33,19,4],
[55,33,19,5],
[55,33,19,6],
[55,33,19,7],
[55,33,19,8],
[55,33,19,9],
[55,33,19,15],
[55,33,19,16],
[55,33,19,17],
[55,33,19,18],
[55,33,21,0],
[55,33,21,1],
[55,33,21,2],
[55,33,21,4],
[55,33,21,7],
[55,33,21,9],
[55,33,21,13],
[55,33,21,14],
[55,33,21,15],
[55,33,21,16],
[55,33,21,17],
[55,33,21,18],
[55,33,21,19],
[55,33,23,0],
[55,33,23,1],
[55,33,23,2],
[55,33,23,3],
[55,33,23,4],
[55,33,23,5],
[55,33,23,6],
[55,33,23,7],
[55,33,23,8],
[55,33,23,9],
[55,33,23,13],
[55,33,23,14],
[55,33,23,15],
[55,33,23,16],
[55,33,23,17],
[55,33,23,18],
[55,33,23,19],
[55,33,23,21],
[55,33,24,0],
[55,33,24,1],
[55,33,24,2],
[55,33,24,3],
[55,33,24,4],
[55,33,24,5],
[55,33,24,6],
[55,33,24,7],
[55,33,24,8],
[55,33,24,9],
[55,33,24,13],
[55,33,24,14],
[55,33,24,15],
[55,33,24,17],
[55,33,24,18],
[55,33,24,19],
[55,33,24,21],
[55,33,24,23],
[55,33,25,0],
[55,33,25,1],
[55,33,25,2],
[55,33,25,3],
[55,33,25,4],
[55,33,25,5],
[55,33,25,6],
[55,33,25,8],
[55,33,25,9],
[55,33,25,13],
[55,33,25,14],
[55,33,25,15],
[55,33,25,16],
[55,33,25,17],
[55,33,25,18],
[55,33,25,19],
[55,33,25,21],
[55,33,25,23],
[55,33,25,24],
[55,33,26,0],
[55,33,26,1],
[55,33,26,2],
[55,33,26,3],
[55,33,26,4],
[55,33,26,5],
[55,33,26,6],
[55,33,26,7],
[55,33,26,8],
[55,33,26,13],
[55,33,26,14],
[55,33,26,16],
[55,33,26,17],
[55,33,26,19],
[55,33,26,23],
[55,33,26,24],
[55,33,26,25],
[55,33,28,0],
[55,33,28,1],
[55,33,28,2],
[55,33,28,3],
[55,33,28,4],
[55,33,28,5],
[55,33,28,6],
[55,33,28,8],
[55,33,28,9],
[55,33,28,13],
[55,33,28,14],
[55,33,28,15],
[55,33,28,16],
[55,33,28,17],
[55,33,28,18],
[55,33,28,21],
[55,33,28,23],
[55,33,28,24],
[55,33,28,26],
[55,33,30,0],
[55,33,30,1],
[55,33,30,2],
[55,33,30,3],
[55,33,30,4],
[55,33,30,5],
[55,33,30,6],
[55,33,30,7],
[55,33,30,8],
[55,33,30,9],
[55,33,30,13],
[55,33,30,14],
[55,33,30,15],
[55,33,30,18],
[55,33,30,19],
[55,33,30,21],
[55,33,30,24],
[55,33,30,25],
[55,33,30,26],
[55,33,30,28],
[55,33,31,0],
[55,33,31,1],
[55,33,31,2],
[55,33,31,3],
[55,33,31,4],
[55,33,31,5],
[55,33,31,6],
[55,33,31,7],
[55,33,31,8],
[55,33,31,9],
[55,33,31,13],
[55,33,31,14],
[55,33,31,16],
[55,33,31,17],
[55,33,31,18],
[55,33,31,19],
[55,33,31,21],
[55,33,31,24],
[55,33,31,25],
[55,33,31,26],
[55,33,31,28],
[55,34,3,0],
[55,34,3,1],
[55,34,3,2],
[55,34,4,0],
[55,34,4,1],
[55,34,4,2],
[55,34,4,3],
[55,34,5,0],
[55,34,5,1],
[55,34,5,2],
[55,34,5,4],
[55,34,6,0],
[55,34,6,1],
[55,34,6,2],
[55,34,6,4],
[55,34,8,0],
[55,34,8,1],
[55,34,8,2],
[55,34,8,4],
[55,34,9,0],
[55,34,9,1],
[55,34,9,2],
[55,34,9,3],
[55,34,9,4],
[55,34,9,5],
[55,34,9,6],
[55,34,9,8],
[55,34,11,0],
[55,34,11,1],
[55,34,11,2],
[55,34,11,3],
[55,34,11,4],
[55,34,11,5],
[55,34,11,6],
[55,34,11,8],
[55,34,11,9],
[55,34,12,0],
[55,34,12,1],
[55,34,12,2],
[55,34,12,3],
[55,34,12,4],
[55,34,12,5],
[55,34,12,6],
[55,34,12,8],
[55,34,12,9],
[55,34,13,0],
[55,34,13,1],
[55,34,13,2],
[55,34,13,3],
[55,34,13,4],
[55,34,13,5],
[55,34,13,6],
[55,34,13,8],
[55,34,13,9],
[55,34,13,11],
[55,34,13,12],
[55,34,14,0],
[55,34,14,1],
[55,34,14,2],
[55,34,14,3],
[55,34,14,4],
[55,34,14,5],
[55,34,14,6],
[55,34,14,8],
[55,34,14,9],
[55,34,14,11],
[55,34,14,12],
[55,34,15,0],
[55,34,15,1],
[55,34,15,2],
[55,34,15,3],
[55,34,15,4],
[55,34,15,5],
[55,34,15,6],
[55,34,15,8],
[55,34,15,11],
[55,34,15,12],
[55,34,15,13],
[55,34,15,14],
[55,34,16,0],
[55,34,16,1],
[55,34,16,2],
[55,34,16,3],
[55,34,16,4],
[55,34,16,5],
[55,34,16,6],
[55,34,16,8],
[55,34,16,9],
[55,34,16,11],
[55,34,16,12],
[55,34,16,13],
[55,34,16,14],
[55,34,16,15],
[55,34,17,0],
[55,34,17,1],
[55,34,17,2],
[55,34,17,3],
[55,34,17,4],
[55,34,17,5],
[55,34,17,6],
[55,34,17,8],
[55,34,17,9],
[55,34,17,11],
[55,34,17,12],
[55,34,17,13],
[55,34,17,14],
[55,34,17,15],
[55,34,17,16],
[55,34,18,0],
[55,34,18,1],
[55,34,18,2],
[55,34,18,3],
[55,34,18,4],
[55,34,18,5],
[55,34,18,6],
[55,34,18,8],
[55,34,18,11],
[55,34,18,12],
[55,34,18,13],
[55,34,18,14],
[55,34,18,16],
[55,34,18,17],
[55,34,19,0],
[55,34,19,1],
[55,34,19,2],
[55,34,19,3],
[55,34,19,4],
[55,34,19,5],
[55,34,19,6],
[55,34,19,8],
[55,34,19,9],
[55,34,19,11],
[55,34,19,12],
[55,34,19,15],
[55,34,19,16],
[55,34,19,17],
[55,34,19,18],
[55,34,21,0],
[55,34,21,1],
[55,34,21,2],
[55,34,21,4],
[55,34,21,9],
[55,34,21,11],
[55,34,21,12],
[55,34,21,13],
[55,34,21,14],
[55,34,21,15],
[55,34,21,16],
[55,34,21,17],
[55,34,21,18],
[55,34,21,19],
[55,34,23,0],
[55,34,23,1],
[55,34,23,2],
[55,34,23,3],
[55,34,23,4],
[55,34,23,5],
[55,34,23,6],
[55,34,23,8],
[55,34,23,9],
[55,34,23,11],
[55,34,23,12],
[55,34,23,13],
[55,34,23,14],
[55,34,23,15],
[55,34,23,16],
[55,34,23,17],
[55,34,23,18],
[55,34,23,19],
[55,34,23,21],
[55,34,24,0],
[55,34,24,1],
[55,34,24,2],
[55,34,24,3],
[55,34,24,4],
[55,34,24,5],
[55,34,24,6],
[55,34,24,8],
[55,34,24,9],
[55,34,24,11],
[55,34,24,12],
[55,34,24,13],
[55,34,24,14],
[55,34,24,15],
[55,34,24,17],
[55,34,24,18],
[55,34,24,19],
[55,34,24,21],
[55,34,26,0],
[55,34,26,1],
[55,34,26,2],
[55,34,26,3],
[55,34,26,4],
[55,34,26,5],
[55,34,26,6],
[55,34,26,8],
[55,34,26,11],
[55,34,26,12],
[55,34,26,13],
[55,34,26,14],
[55,34,26,16],
[55,34,26,17],
[55,34,26,19],
[55,34,26,21],
[55,34,26,23],
[55,34,26,24],
[55,34,29,0],
[55,34,29,1],
[55,34,29,2],
[55,34,29,3],
[55,34,29,4],
[55,34,29,5],
[55,34,29,6],
[55,34,29,8],
[55,34,29,9],
[55,34,29,13],
[55,34,29,14],
[55,34,29,15],
[55,34,29,18],
[55,34,29,19],
[55,34,29,21],
[55,34,29,23],
[55,34,29,24],
[55,34,29,26],
[55,34,30,0],
[55,34,30,1],
[55,34,30,2],
[55,34,30,3],
[55,34,30,4],
[55,34,30,5],
[55,34,30,6],
[55,34,30,8],
[55,34,30,9],
[55,34,30,11],
[55,34,30,12],
[55,34,30,13],
[55,34,30,14],
[55,34,30,16],
[55,34,30,17],
[55,34,30,18],
[55,34,30,19],
[55,34,30,21],
[55,34,30,24],
[55,34,30,26],
[55,34,31,0],
[55,34,31,1],
[55,34,31,2],
[55,34,31,3],
[55,34,31,4],
[55,34,31,5],
[55,34,31,6],
[55,34,31,8],
[55,34,31,9],
[55,34,31,11],
[55,34,31,12],
[55,34,31,13],
[55,34,31,15],
[55,34,31,16],
[55,34,31,17],
[55,34,31,18],
[55,34,31,19],
[55,34,31,21],
[55,34,31,24],
[55,34,31,26],
[55,34,32,0],
[55,34,32,1],
[55,34,32,2],
[55,34,32,3],
[55,34,32,4],
[55,34,32,5],
[55,34,32,6],
[55,34,32,8],
[55,34,32,9],
[55,34,32,14],
[55,34,32,15],
[55,34,32,16],
[55,34,32,17],
[55,34,32,18],
[55,34,32,19],
[55,34,32,21],
[55,34,32,23],
[55,34,32,24],
[55,34,32,26],
[55,34,33,0],
[55,34,33,1],
[55,34,33,2],
[55,34,33,3],
[55,34,33,4],
[55,34,33,5],
[55,34,33,6],
[55,34,33,8],
[55,34,33,9],
[55,34,33,13],
[55,34,33,14],
[55,34,33,15],
[55,34,33,16],
[55,34,33,17],
[55,34,33,18],
[55,34,33,19],
[55,34,33,21],
[55,34,33,23],
[55,34,33,24],
[55,35,3,0],
[55,35,3,1],
[55,35,3,2],
[55,35,4,0],
[55,35,4,1],
[55,35,4,2],
[55,35,4,3],
[55,35,5,0],
[55,35,5,1],
[55,35,5,2],
[55,35,5,4],
[55,35,6,0],
[55,35,6,1],
[55,35,6,2],
[55,35,6,4],
[55,35,7,0],
[55,35,7,1],
[55,35,7,2],
[55,35,7,3],
[55,35,7,4],
[55,35,7,5],
[55,35,7,6],
[55,35,8,0],
[55,35,8,1],
[55,35,8,2],
[55,35,8,4],
[55,35,8,7],
[55,35,11,0],
[55,35,11,1],
[55,35,11,2],
[55,35,11,3],
[55,35,11,4],
[55,35,11,5],
[55,35,11,6],
[55,35,11,7],
[55,35,11,8],
[55,35,12,0],
[55,35,12,1],
[55,35,12,2],
[55,35,12,3],
[55,35,12,4],
[55,35,12,5],
[55,35,12,6],
[55,35,12,7],
[55,35,12,8],
[55,35,13,0],
[55,35,13,1],
[55,35,13,2],
[55,35,13,3],
[55,35,13,4],
[55,35,13,5],
[55,35,13,6],
[55,35,13,7],
[55,35,13,8],
[55,35,13,11],
[55,35,13,12],
[55,35,14,0],
[55,35,14,1],
[55,35,14,2],
[55,35,14,3],
[55,35,14,4],
[55,35,14,5],
[55,35,14,6],
[55,35,14,7],
[55,35,14,8],
[55,35,14,11],
[55,35,14,12],
[55,35,16,0],
[55,35,16,1],
[55,35,16,2],
[55,35,16,3],
[55,35,16,4],
[55,35,16,5],
[55,35,16,6],
[55,35,16,7],
[55,35,16,8],
[55,35,16,11],
[55,35,16,12],
[55,35,16,13],
[55,35,16,14],
[55,35,17,0],
[55,35,17,1],
[55,35,17,2],
[55,35,17,3],
[55,35,17,4],
[55,35,17,5],
[55,35,17,6],
[55,35,17,7],
[55,35,17,8],
[55,35,17,11],
[55,35,17,12],
[55,35,17,13],
[55,35,17,14],
[55,35,17,16],
[55,35,19,0],
[55,35,19,1],
[55,35,19,2],
[55,35,19,3],
[55,35,19,4],
[55,35,19,5],
[55,35,19,6],
[55,35,19,7],
[55,35,19,8],
[55,35,19,11],
[55,35,19,12],
[55,35,19,16],
[55,35,19,17],
[55,35,21,0],
[55,35,21,1],
[55,35,21,2],
[55,35,21,4],
[55,35,21,7],
[55,35,21,11],
[55,35,21,12],
[55,35,21,13],
[55,35,21,14],
[55,35,21,16],
[55,35,21,17],
[55,35,21,19],
[55,35,23,0],
[55,35,23,1],
[55,35,23,2],
[55,35,23,3],
[55,35,23,4],
[55,35,23,5],
[55,35,23,6],
[55,35,23,7],
[55,35,23,8],
[55,35,23,11],
[55,35,23,12],
[55,35,23,13],
[55,35,23,14],
[55,35,23,16],
[55,35,23,17],
[55,35,23,19],
[55,35,23,21],
[55,35,24,0],
[55,35,24,1],
[55,35,24,2],
[55,35,24,3],
[55,35,24,4],
[55,35,24,5],
[55,35,24,6],
[55,35,24,7],
[55,35,24,8],
[55,35,24,11],
[55,35,24,12],
[55,35,24,13],
[55,35,24,14],
[55,35,24,17],
[55,35,24,19],
[55,35,24,21],
[55,35,24,23],
[55,35,25,0],
[55,35,25,1],
[55,35,25,2],
[55,35,25,3],
[55,35,25,4],
[55,35,25,5],
[55,35,25,6],
[55,35,25,8],
[55,35,25,11],
[55,35,25,12],
[55,35,25,13],
[55,35,25,14],
[55,35,25,16],
[55,35,25,17],
[55,35,25,19],
[55,35,25,21],
[55,35,25,23],
[55,35,25,24],
[55,35,28,0],
[55,35,28,1],
[55,35,28,2],
[55,35,28,3],
[55,35,28,4],
[55,35,28,5],
[55,35,28,6],
[55,35,28,8],
[55,35,28,11],
[55,35,28,12],
[55,35,28,13],
[55,35,28,14],
[55,35,28,19],
[55,35,28,21],
[55,35,28,23],
[55,35,28,24],
[55,35,29,0],
[55,35,29,1],
[55,35,29,2],
[55,35,29,3],
[55,35,29,4],
[55,35,29,5],
[55,35,29,6],
[55,35,29,7],
[55,35,29,8],
[55,35,29,13],
[55,35,29,14],
[55,35,29,16],
[55,35,29,17],
[55,35,29,19],
[55,35,29,21],
[55,35,29,23],
[55,35,29,24],
[55,35,29,25],
[55,35,29,28],
[55,35,30,0],
[55,35,30,1],
[55,35,30,2],
[55,35,30,3],
[55,35,30,4],
[55,35,30,5],
[55,35,30,6],
[55,35,30,7],
[55,35,30,8],
[55,35,30,11],
[55,35,30,12],
[55,35,30,13],
[55,35,30,16],
[55,35,30,17],
[55,35,30,19],
[55,35,30,21],
[55,35,30,24],
[55,35,30,25],
[55,35,31,0],
[55,35,31,1],
[55,35,31,2],
[55,35,31,3],
[55,35,31,4],
[55,35,31,5],
[55,35,31,6],
[55,35,31,7],
[55,35,31,8],
[55,35,31,11],
[55,35,31,14],
[55,35,31,16],
[55,35,31,17],
[55,35,31,19],
[55,35,31,21],
[55,35,31,24],
[55,35,31,25],
[55,35,32,0],
[55,35,32,1],
[55,35,32,2],
[55,35,32,3],
[55,35,32,4],
[55,35,32,5],
[55,35,32,6],
[55,35,32,7],
[55,35,32,8],
[55,35,32,13],
[55,35,32,14],
[55,35,32,16],
[55,35,32,17],
[55,35,32,19],
[55,35,32,21],
[55,35,32,23],
[55,35,32,24],
[55,35,32,25],
[55,35,33,0],
[55,35,33,1],
[55,35,33,2],
[55,35,33,3],
[55,35,33,4],
[55,35,33,5],
[55,35,33,6],
[55,35,33,7],
[55,35,33,13],
[55,35,33,14],
[55,35,33,16],
[55,35,33,17],
[55,35,33,19],
[55,35,33,21],
[55,35,33,23],
[55,35,33,24],
[55,35,34,0],
[55,35,34,1],
[55,35,34,2],
[55,35,34,3],
[55,35,34,4],
[55,35,34,5],
[55,35,34,8],
[55,35,34,11],
[55,35,34,12],
[55,35,34,13],
[55,35,34,14],
[55,35,34,16],
[55,35,34,17],
[55,35,34,19],
[55,35,34,21],
[55,35,34,23],
[55,35,34,24],
[55,36,3,0],
[55,36,3,1],
[55,36,3,2],
[55,36,4,0],
[55,36,4,1],
[55,36,4,2],
[55,36,4,3],
[55,36,5,0],
[55,36,5,1],
[55,36,5,2],
[55,36,5,4],
[55,36,6,0],
[55,36,6,1],
[55,36,6,2],
[55,36,6,4],
[55,36,7,0],
[55,36,7,1],
[55,36,7,2],
[55,36,7,3],
[55,36,7,4],
[55,36,7,5],
[55,36,7,6],
[55,36,8,0],
[55,36,8,1],
[55,36,8,2],
[55,36,8,4],
[55,36,8,7],
[55,36,9,0],
[55,36,9,1],
[55,36,9,2],
[55,36,9,3],
[55,36,9,4],
[55,36,9,5],
[55,36,9,6],
[55,36,9,7],
[55,36,9,8],
[55,36,11,0],
[55,36,11,1],
[55,36,11,2],
[55,36,11,3],
[55,36,11,4],
[55,36,11,5],
[55,36,11,6],
[55,36,11,7],
[55,36,11,8],
[55,36,11,9],
[55,36,12,0],
[55,36,12,1],
[55,36,12,2],
[55,36,12,3],
[55,36,12,4],
[55,36,12,5],
[55,36,12,6],
[55,36,12,7],
[55,36,12,8],
[55,36,12,9],
[55,36,15,0],
[55,36,15,1],
[55,36,15,2],
[55,36,15,3],
[55,36,15,4],
[55,36,15,5],
[55,36,15,6],
[55,36,15,7],
[55,36,15,8],
[55,36,15,11],
[55,36,15,12],
[55,36,16,0],
[55,36,16,1],
[55,36,16,2],
[55,36,16,3],
[55,36,16,4],
[55,36,16,5],
[55,36,16,6],
[55,36,16,7],
[55,36,16,8],
[55,36,16,9],
[55,36,16,11],
[55,36,16,12],
[55,36,16,15],
[55,36,17,0],
[55,36,17,1],
[55,36,17,2],
[55,36,17,3],
[55,36,17,4],
[55,36,17,5],
[55,36,17,6],
[55,36,17,7],
[55,36,17,8],
[55,36,17,9],
[55,36,17,11],
[55,36,17,12],
[55,36,17,15],
[55,36,17,16],
[55,36,18,0],
[55,36,18,1],
[55,36,18,2],
[55,36,18,3],
[55,36,18,4],
[55,36,18,5],
[55,36,18,6],
[55,36,18,7],
[55,36,18,8],
[55,36,18,11],
[55,36,18,12],
[55,36,18,16],
[55,36,18,17],
[55,36,21,0],
[55,36,21,1],
[55,36,21,2],
[55,36,21,4],
[55,36,21,7],
[55,36,21,9],
[55,36,21,11],
[55,36,21,12],
[55,36,21,15],
[55,36,21,16],
[55,36,21,17],
[55,36,21,18],
[55,36,23,0],
[55,36,23,1],
[55,36,23,2],
[55,36,23,3],
[55,36,23,4],
[55,36,23,5],
[55,36,23,6],
[55,36,23,7],
[55,36,23,8],
[55,36,23,9],
[55,36,23,11],
[55,36,23,12],
[55,36,23,15],
[55,36,23,16],
[55,36,23,17],
[55,36,23,18],
[55,36,24,0],
[55,36,24,1],
[55,36,24,2],
[55,36,24,3],
[55,36,24,4],
[55,36,24,5],
[55,36,24,6],
[55,36,24,7],
[55,36,24,8],
[55,36,24,9],
[55,36,24,11],
[55,36,24,12],
[55,36,24,15],
[55,36,24,17],
[55,36,24,18],
[55,36,24,23],
[55,36,25,0],
[55,36,25,1],
[55,36,25,2],
[55,36,25,3],
[55,36,25,4],
[55,36,25,5],
[55,36,25,6],
[55,36,25,8],
[55,36,25,9],
[55,36,25,11],
[55,36,25,12],
[55,36,25,15],
[55,36,25,16],
[55,36,25,17],
[55,36,25,18],
[55,36,25,21],
[55,36,25,23],
[55,36,25,24],
[55,36,26,0],
[55,36,26,1],
[55,36,26,2],
[55,36,26,3],
[55,36,26,4],
[55,36,26,5],
[55,36,26,6],
[55,36,26,7],
[55,36,26,8],
[55,36,26,11],
[55,36,26,12],
[55,36,26,16],
[55,36,26,17],
[55,36,26,21],
[55,36,26,23],
[55,36,26,24],
[55,36,26,25],
[55,36,28,0],
[55,36,28,1],
[55,36,28,2],
[55,36,28,3],
[55,36,28,4],
[55,36,28,5],
[55,36,28,6],
[55,36,28,8],
[55,36,28,9],
[55,36,28,11],
[55,36,28,12],
[55,36,28,16],
[55,36,28,17],
[55,36,28,18],
[55,36,28,21],
[55,36,28,23],
[55,36,28,24],
[55,36,28,26],
[55,36,29,0],
[55,36,29,1],
[55,36,29,2],
[55,36,29,3],
[55,36,29,4],
[55,36,29,5],
[55,36,29,6],
[55,36,29,7],
[55,36,29,8],
[55,36,29,9],
[55,36,29,15],
[55,36,29,16],
[55,36,29,17],
[55,36,29,18],
[55,36,29,21],
[55,36,29,23],
[55,36,29,24],
[55,36,29,25],
[55,36,29,26],
[55,36,30,0],
[55,36,30,1],
[55,36,30,2],
[55,36,30,3],
[55,36,30,4],
[55,36,30,5],
[55,36,30,6],
[55,36,30,7],
[55,36,30,8],
[55,36,30,9],
[55,36,30,11],
[55,36,30,15],
[55,36,30,16],
[55,36,30,17],
[55,36,30,18],
[55,36,30,21],
[55,36,30,24],
[55,36,30,25],
[55,36,30,26],
[55,36,31,0],
[55,36,31,1],
[55,36,31,2],
[55,36,31,3],
[55,36,31,4],
[55,36,31,5],
[55,36,31,6],
[55,36,31,7],
[55,36,31,8],
[55,36,31,9],
[55,36,31,12],
[55,36,31,15],
[55,36,31,16],
[55,36,31,17],
[55,36,31,18],
[55,36,31,21],
[55,36,31,24],
[55,36,31,25],
[55,36,32,0],
[55,36,32,1],
[55,36,32,2],
[55,36,32,3],
[55,36,32,4],
[55,36,32,5],
[55,36,32,6],
[55,36,32,7],
[55,36,32,15],
[55,36,32,16],
[55,36,32,17],
[55,36,32,18],
[55,36,32,21],
[55,36,32,23],
[55,36,32,24],
[55,36,33,0],
[55,36,33,1],
[55,36,33,2],
[55,36,33,3],
[55,36,33,4],
[55,36,33,5],
[55,36,33,8],
[55,36,33,9],
[55,36,33,15],
[55,36,33,16],
[55,36,33,17],
[55,36,33,18],
[55,36,33,21],
[55,36,33,23],
[55,36,33,24],
[55,36,34,0],
[55,36,34,1],
[55,36,34,2],
[55,36,34,3],
[55,36,34,4],
[55,36,34,6],
[55,36,34,8],
[55,36,34,9],
[55,36,34,11],
[55,36,34,12],
[55,36,34,15],
[55,36,34,16],
[55,36,34,17],
[55,36,34,18],
[55,36,34,21],
[55,36,35,0],
[55,36,35,1],
[55,36,35,2],
[55,36,35,5],
[55,36,35,6],
[55,36,35,7],
[55,36,35,8],
[55,36,35,11],
[55,36,35,12],
[55,36,35,16],
[55,36,35,17],
[55,36,35,21],
[55,37,3,0],
[55,37,3,1],
[55,37,3,2],
[55,37,5,0],
[55,37,5,1],
[55,37,5,2],
[55,37,6,0],
[55,37,6,1],
[55,37,6,2],
[55,37,7,0],
[55,37,7,1],
[55,37,7,2],
[55,37,7,3],
[55,37,7,5],
[55,37,7,6],
[55,37,8,0],
[55,37,8,1],
[55,37,8,2],
[55,37,8,7],
[55,37,9,0],
[55,37,9,1],
[55,37,9,2],
[55,37,9,3],
[55,37,9,5],
[55,37,9,6],
[55,37,9,7],
[55,37,9,8],
[55,37,11,0],
[55,37,11,1],
[55,37,11,2],
[55,37,11,3],
[55,37,11,5],
[55,37,11,6],
[55,37,11,7],
[55,37,11,8],
[55,37,11,9],
[55,37,12,0],
[55,37,12,1],
[55,37,12,2],
[55,37,12,3],
[55,37,12,5],
[55,37,12,6],
[55,37,12,7],
[55,37,12,8],
[55,37,12,9],
[55,37,13,0],
[55,37,13,1],
[55,37,13,2],
[55,37,13,3],
[55,37,13,5],
[55,37,13,6],
[55,37,13,7],
[55,37,13,8],
[55,37,13,9],
[55,37,13,11],
[55,37,13,12],
[55,37,14,0],
[55,37,14,1],
[55,37,14,2],
[55,37,14,3],
[55,37,14,5],
[55,37,14,6],
[55,37,14,7],
[55,37,14,8],
[55,37,14,9],
[55,37,14,11],
[55,37,14,12],
[55,37,15,0],
[55,37,15,1],
[55,37,15,2],
[55,37,15,3],
[55,37,15,5],
[55,37,15,6],
[55,37,15,7],
[55,37,15,8],
[55,37,15,11],
[55,37,15,12],
[55,37,15,13],
[55,37,15,14],
[55,37,16,0],
[55,37,16,1],
[55,37,16,2],
[55,37,16,3],
[55,37,16,5],
[55,37,16,6],
[55,37,16,7],
[55,37,16,8],
[55,37,16,9],
[55,37,16,11],
[55,37,16,12],
[55,37,16,13],
[55,37,16,14],
[55,37,16,15],
[55,37,17,0],
[55,37,17,1],
[55,37,17,2],
[55,37,17,3],
[55,37,17,5],
[55,37,17,6],
[55,37,17,7],
[55,37,17,8],
[55,37,17,9],
[55,37,17,11],
[55,37,17,12],
[55,37,17,13],
[55,37,17,14],
[55,37,17,15],
[55,37,17,16],
[55,37,18,0],
[55,37,18,1],
[55,37,18,2],
[55,37,18,3],
[55,37,18,5],
[55,37,18,6],
[55,37,18,7],
[55,37,18,8],
[55,37,18,11],
[55,37,18,12],
[55,37,18,13],
[55,37,18,14],
[55,37,18,16],
[55,37,18,17],
[55,37,19,0],
[55,37,19,1],
[55,37,19,2],
[55,37,19,3],
[55,37,19,5],
[55,37,19,6],
[55,37,19,7],
[55,37,19,8],
[55,37,19,9],
[55,37,19,11],
[55,37,19,12],
[55,37,19,15],
[55,37,19,16],
[55,37,19,17],
[55,37,19,18],
[55,37,21,0],
[55,37,21,1],
[55,37,21,2],
[55,37,21,7],
[55,37,21,9],
[55,37,21,11],
[55,37,21,12],
[55,37,21,13],
[55,37,21,14],
[55,37,21,15],
[55,37,21,16],
[55,37,21,17],
[55,37,21,18],
[55,37,21,19],
[55,37,23,0],
[55,37,23,1],
[55,37,23,2],
[55,37,23,3],
[55,37,23,5],
[55,37,23,6],
[55,37,23,7],
[55,37,23,8],
[55,37,23,9],
[55,37,23,11],
[55,37,23,12],
[55,37,23,13],
[55,37,23,14],
[55,37,23,15],
[55,37,23,16],
[55,37,23,17],
[55,37,23,18],
[55,37,23,19],
[55,37,23,21],
[55,37,24,0],
[55,37,24,1],
[55,37,24,2],
[55,37,24,3],
[55,37,24,5],
[55,37,24,6],
[55,37,24,7],
[55,37,24,8],
[55,37,24,9],
[55,37,24,11],
[55,37,24,12],
[55,37,24,13],
[55,37,24,14],
[55,37,24,15],
[55,37,24,17],
[55,37,24,18],
[55,37,24,19],
[55,37,24,21],
[55,37,24,23],
[55,37,25,0],
[55,37,25,1],
[55,37,25,2],
[55,37,25,3],
[55,37,25,5],
[55,37,25,6],
[55,37,25,8],
[55,37,25,9],
[55,37,25,11],
[55,37,25,12],
[55,37,25,13],
[55,37,25,14],
[55,37,25,15],
[55,37,25,16],
[55,37,25,17],
[55,37,25,19],
[55,37,25,21],
[55,37,25,23],
[55,37,25,24],
[55,37,26,0],
[55,37,26,1],
[55,37,26,2],
[55,37,26,3],
[55,37,26,5],
[55,37,26,6],
[55,37,26,7],
[55,37,26,8],
[55,37,26,11],
[55,37,26,12],
[55,37,26,13],
[55,37,26,14],
[55,37,26,19],
[55,37,26,21],
[55,37,26,23],
[55,37,26,24],
[55,37,26,25],
[55,37,28,0],
[55,37,28,1],
[55,37,28,2],
[55,37,28,3],
[55,37,28,5],
[55,37,28,6],
[55,37,28,8],
[55,37,28,9],
[55,37,28,11],
[55,37,28,12],
[55,37,28,13],
[55,37,28,15],
[55,37,28,16],
[55,37,28,17],
[55,37,28,18],
[55,37,28,19],
[55,37,28,21],
[55,37,28,23],
[55,37,28,24],
[55,37,28,26],
[55,37,29,0],
[55,37,29,1],
[55,37,29,2],
[55,37,29,3],
[55,37,29,5],
[55,37,29,6],
[55,37,29,7],
[55,37,29,8],
[55,37,29,9],
[55,37,29,14],
[55,37,29,15],
[55,37,29,16],
[55,37,29,17],
[55,37,29,18],
[55,37,29,19],
[55,37,29,21],
[55,37,29,23],
[55,37,29,24],
[55,37,29,25],
[55,37,29,26],
[55,37,30,0],
[55,37,30,1],
[55,37,30,2],
[55,37,30,3],
[55,37,30,5],
[55,37,30,6],
[55,37,30,7],
[55,37,30,8],
[55,37,30,9],
[55,37,30,12],
[55,37,30,13],
[55,37,30,14],
[55,37,30,15],
[55,37,30,16],
[55,37,30,17],
[55,37,30,18],
[55,37,30,19],
[55,37,30,21],
[55,37,30,24],
[55,37,30,25],
[55,37,31,0],
[55,37,31,1],
[55,37,31,2],
[55,37,31,3],
[55,37,31,5],
[55,37,31,6],
[55,37,31,7],
[55,37,31,11],
[55,37,31,12],
[55,37,31,13],
[55,37,31,14],
[55,37,31,15],
[55,37,31,16],
[55,37,31,17],
[55,37,31,18],
[55,37,31,19],
[55,37,31,21],
[55,37,31,24],
[55,37,32,0],
[55,37,32,1],
[55,37,32,2],
[55,37,32,3],
[55,37,32,5],
[55,37,32,8],
[55,37,32,9],
[55,37,32,13],
[55,37,32,14],
[55,37,32,15],
[55,37,32,16],
[55,37,32,17],
[55,37,32,18],
[55,37,32,19],
[55,37,32,21],
[55,37,32,23],
[55,37,32,24],
[55,37,33,0],
[55,37,33,1],
[55,37,33,2],
[55,37,33,3],
[55,37,33,6],
[55,37,33,7],
[55,37,33,8],
[55,37,33,9],
[55,37,33,13],
[55,37,33,14],
[55,37,33,15],
[55,37,33,16],
[55,37,33,17],
[55,37,33,18],
[55,37,33,19],
[55,37,33,21],
[55,37,34,0],
[55,37,34,1],
[55,37,34,2],
[55,37,34,5],
[55,37,34,6],
[55,37,34,8],
[55,37,34,9],
[55,37,34,11],
[55,37,34,12],
[55,37,34,13],
[55,37,34,14],
[55,37,34,15],
[55,37,34,16],
[55,37,34,17],
[55,37,34,18],
[55,37,34,19],
[55,37,34,21],
[55,37,35,3],
[55,37,35,5],
[55,37,35,6],
[55,37,35,7],
[55,37,35,8],
[55,37,35,11],
[55,37,35,12],
[55,37,35,13],
[55,37,35,14],
[55,37,35,16],
[55,37,35,17],
[55,37,35,19],
[55,37,36,0],
[55,37,36,1],
[55,37,36,2],
[55,37,36,3],
[55,37,36,5],
[55,37,36,6],
[55,37,36,7],
[55,37,36,8],
[55,37,36,9],
[55,37,36,11],
[55,37,36,12],
[55,37,36,15],
[55,37,36,16],
[55,37,36,17],
[55,37,36,18],
[55,38,3,0],
[55,38,3,1],
[55,38,3,2],
[55,38,4,0],
[55,38,4,1],
[55,38,4,2],
[55,38,4,3],
[55,38,5,0],
[55,38,5,1],
[55,38,5,2],
[55,38,5,4],
[55,38,6,0],
[55,38,6,1],
[55,38,6,2],
[55,38,6,4],
[55,38,8,0],
[55,38,8,1],
[55,38,8,2],
[55,38,8,4],
[55,38,9,0],
[55,38,9,1],
[55,38,9,2],
[55,38,9,3],
[55,38,9,4],
[55,38,9,5],
[55,38,9,6],
[55,38,9,8],
[55,38,11,0],
[55,38,11,1],
[55,38,11,2],
[55,38,11,3],
[55,38,11,4],
[55,38,11,5],
[55,38,11,6],
[55,38,11,8],
[55,38,11,9],
[55,38,12,0],
[55,38,12,1],
[55,38,12,2],
[55,38,12,3],
[55,38,12,4],
[55,38,12,5],
[55,38,12,6],
[55,38,12,8],
[55,38,12,9],
[55,38,13,0],
[55,38,13,1],
[55,38,13,2],
[55,38,13,3],
[55,38,13,4],
[55,38,13,5],
[55,38,13,6],
[55,38,13,8],
[55,38,13,9],
[55,38,13,11],
[55,38,13,12],
[55,38,14,0],
[55,38,14,1],
[55,38,14,2],
[55,38,14,3],
[55,38,14,4],
[55,38,14,5],
[55,38,14,6],
[55,38,14,8],
[55,38,14,9],
[55,38,14,11],
[55,38,14,12],
[55,38,15,0],
[55,38,15,1],
[55,38,15,2],
[55,38,15,3],
[55,38,15,4],
[55,38,15,5],
[55,38,15,6],
[55,38,15,8],
[55,38,15,11],
[55,38,15,12],
[55,38,15,13],
[55,38,15,14],
[55,38,16,0],
[55,38,16,1],
[55,38,16,2],
[55,38,16,3],
[55,38,16,4],
[55,38,16,5],
[55,38,16,6],
[55,38,16,8],
[55,38,16,9],
[55,38,16,11],
[55,38,16,12],
[55,38,16,13],
[55,38,16,14],
[55,38,16,15],
[55,38,17,0],
[55,38,17,1],
[55,38,17,2],
[55,38,17,3],
[55,38,17,4],
[55,38,17,5],
[55,38,17,6],
[55,38,17,8],
[55,38,17,9],
[55,38,17,11],
[55,38,17,12],
[55,38,17,13],
[55,38,17,14],
[55,38,17,15],
[55,38,17,16],
[55,38,18,0],
[55,38,18,1],
[55,38,18,2],
[55,38,18,3],
[55,38,18,4],
[55,38,18,5],
[55,38,18,6],
[55,38,18,8],
[55,38,18,11],
[55,38,18,12],
[55,38,18,13],
[55,38,18,14],
[55,38,18,16],
[55,38,18,17],
[55,38,19,0],
[55,38,19,1],
[55,38,19,2],
[55,38,19,3],
[55,38,19,4],
[55,38,19,5],
[55,38,19,6],
[55,38,19,8],
[55,38,19,9],
[55,38,19,11],
[55,38,19,12],
[55,38,19,15],
[55,38,19,16],
[55,38,19,17],
[55,38,19,18],
[55,38,21,0],
[55,38,21,1],
[55,38,21,2],
[55,38,21,4],
[55,38,21,9],
[55,38,21,11],
[55,38,21,12],
[55,38,21,13],
[55,38,21,14],
[55,38,21,15],
[55,38,21,16],
[55,38,21,17],
[55,38,21,18],
[55,38,21,19],
[55,38,23,0],
[55,38,23,1],
[55,38,23,2],
[55,38,23,3],
[55,38,23,4],
[55,38,23,5],
[55,38,23,6],
[55,38,23,8],
[55,38,23,9],
[55,38,23,11],
[55,38,23,12],
[55,38,23,13],
[55,38,23,14],
[55,38,23,15],
[55,38,23,16],
[55,38,23,17],
[55,38,23,18],
[55,38,23,21],
[55,38,24,0],
[55,38,24,1],
[55,38,24,2],
[55,38,24,3],
[55,38,24,4],
[55,38,24,5],
[55,38,24,6],
[55,38,24,8],
[55,38,24,9],
[55,38,24,11],
[55,38,24,12],
[55,38,24,13],
[55,38,24,14],
[55,38,24,15],
[55,38,24,17],
[55,38,24,18],
[55,38,24,21],
[55,38,24,23],
[55,38,26,0],
[55,38,26,1],
[55,38,26,2],
[55,38,26,3],
[55,38,26,4],
[55,38,26,5],
[55,38,26,6],
[55,38,26,8],
[55,38,26,11],
[55,38,26,12],
[55,38,26,13],
[55,38,26,14],
[55,38,26,16],
[55,38,26,17],
[55,38,26,19],
[55,38,26,21],
[55,38,26,23],
[55,38,26,24],
[55,38,29,0],
[55,38,29,1],
[55,38,29,2],
[55,38,29,3],
[55,38,29,4],
[55,38,29,5],
[55,38,29,6],
[55,38,29,8],
[55,38,29,9],
[55,38,29,13],
[55,38,29,14],
[55,38,29,15],
[55,38,29,16],
[55,38,29,17],
[55,38,29,18],
[55,38,29,19],
[55,38,29,21],
[55,38,29,23],
[55,38,29,24],
[55,38,30,0],
[55,38,30,1],
[55,38,30,2],
[55,38,30,3],
[55,38,30,4],
[55,38,30,5],
[55,38,30,6],
[55,38,30,11],
[55,38,30,12],
[55,38,30,13],
[55,38,30,14],
[55,38,30,15],
[55,38,30,16],
[55,38,30,17],
[55,38,30,18],
[55,38,30,19],
[55,38,30,21],
[55,38,30,24],
[55,38,31,0],
[55,38,31,1],
[55,38,31,2],
[55,38,31,3],
[55,38,31,4],
[55,38,31,5],
[55,38,31,8],
[55,38,31,9],
[55,38,31,11],
[55,38,31,12],
[55,38,31,13],
[55,38,31,14],
[55,38,31,15],
[55,38,31,16],
[55,38,31,17],
[55,38,31,18],
[55,38,31,19],
[55,38,31,21],
[55,38,31,24],
[55,38,32,0],
[55,38,32,1],
[55,38,32,2],
[55,38,32,3],
[55,38,32,4],
[55,38,32,6],
[55,38,32,8],
[55,38,32,9],
[55,38,32,13],
[55,38,32,14],
[55,38,32,15],
[55,38,32,16],
[55,38,32,17],
[55,38,32,18],
[55,38,32,19],
[55,38,32,21],
[55,38,33,0],
[55,38,33,1],
[55,38,33,2],
[55,38,33,5],
[55,38,33,6],
[55,38,33,8],
[55,38,33,9],
[55,38,33,13],
[55,38,33,14],
[55,38,33,15],
[55,38,33,16],
[55,38,33,17],
[55,38,33,18],
[55,38,33,19],
[55,38,33,21],
[55,38,35,0],
[55,38,35,1],
[55,38,35,2],
[55,38,35,3],
[55,38,35,4],
[55,38,35,5],
[55,38,35,6],
[55,38,35,8],
[55,38,35,11],
[55,38,35,12],
[55,38,35,13],
[55,38,35,14],
[55,38,35,16],
[55,38,35,17],
[55,38,35,19],
[55,38,36,0],
[55,38,36,1],
[55,38,36,2],
[55,38,36,3],
[55,38,36,4],
[55,38,36,5],
[55,38,36,6],
[55,38,36,8],
[55,38,36,9],
[55,38,36,11],
[55,38,36,12],
[55,38,36,15],
[55,38,36,16],
[55,38,36,17],
[55,38,36,18],
[55,38,37,0],
[55,38,37,1],
[55,38,37,2],
[55,38,37,3],
[55,38,37,5],
[55,38,37,6],
[55,38,37,8],
[55,38,37,9],
[55,38,37,11],
[55,38,37,12],
[55,38,37,13],
[55,38,37,14],
[55,38,37,15],
[55,38,37,16],
[55,38,37,17],
[55,39,4,0],
[55,39,4,1],
[55,39,4,2],
[55,39,7,0],
[55,39,7,1],
[55,39,7,2],
[55,39,7,4],
[55,39,9,0],
[55,39,9,1],
[55,39,9,2],
[55,39,9,4],
[55,39,9,7],
[55,39,11,0],
[55,39,11,1],
[55,39,11,2],
[55,39,11,4],
[55,39,11,7],
[55,39,11,9],
[55,39,12,0],
[55,39,12,1],
[55,39,12,2],
[55,39,12,4],
[55,39,12,7],
[55,39,12,9],
[55,39,13,0],
[55,39,13,1],
[55,39,13,2],
[55,39,13,4],
[55,39,13,7],
[55,39,13,9],
[55,39,13,11],
[55,39,13,12],
[55,39,14,0],
[55,39,14,1],
[55,39,14,2],
[55,39,14,4],
[55,39,14,7],
[55,39,14,9],
[55,39,14,11],
[55,39,14,12],
[55,39,15,0],
[55,39,15,1],
[55,39,15,2],
[55,39,15,4],
[55,39,15,7],
[55,39,15,11],
[55,39,15,12],
[55,39,15,13],
[55,39,15,14],
[55,39,16,0],
[55,39,16,1],
[55,39,16,2],
[55,39,16,4],
[55,39,16,7],
[55,39,16,9],
[55,39,16,11],
[55,39,16,12],
[55,39,16,13],
[55,39,16,14],
[55,39,16,15],
[55,39,17,0],
[55,39,17,1],
[55,39,17,2],
[55,39,17,4],
[55,39,17,7],
[55,39,17,9],
[55,39,17,11],
[55,39,17,12],
[55,39,17,13],
[55,39,17,14],
[55,39,17,15],
[55,39,17,16],
[55,39,18,0],
[55,39,18,1],
[55,39,18,2],
[55,39,18,4],
[55,39,18,7],
[55,39,18,11],
[55,39,18,12],
[55,39,18,13],
[55,39,18,14],
[55,39,18,16],
[55,39,18,17],
[55,39,19,0],
[55,39,19,1],
[55,39,19,2],
[55,39,19,4],
[55,39,19,7],
[55,39,19,9],
[55,39,19,11],
[55,39,19,12],
[55,39,19,15],
[55,39,19,16],
[55,39,19,17],
[55,39,19,18],
[55,39,23,0],
[55,39,23,1],
[55,39,23,2],
[55,39,23,4],
[55,39,23,7],
[55,39,23,9],
[55,39,23,11],
[55,39,23,12],
[55,39,23,13],
[55,39,23,14],
[55,39,23,15],
[55,39,23,16],
[55,39,23,17],
[55,39,23,19],
[55,39,24,0],
[55,39,24,1],
[55,39,24,2],
[55,39,24,4],
[55,39,24,7],
[55,39,24,9],
[55,39,24,11],
[55,39,24,12],
[55,39,24,13],
[55,39,24,14],
[55,39,24,15],
[55,39,24,17],
[55,39,24,19],
[55,39,24,23],
[55,39,25,0],
[55,39,25,1],
[55,39,25,2],
[55,39,25,4],
[55,39,25,9],
[55,39,25,11],
[55,39,25,12],
[55,39,25,13],
[55,39,25,14],
[55,39,25,16],
[55,39,25,17],
[55,39,25,18],
[55,39,25,19],
[55,39,25,23],
[55,39,25,24],
[55,39,26,0],
[55,39,26,1],
[55,39,26,2],
[55,39,26,4],
[55,39,26,7],
[55,39,26,11],
[55,39,26,12],
[55,39,26,13],
[55,39,26,16],
[55,39,26,17],
[55,39,26,19],
[55,39,26,23],
[55,39,26,24],
[55,39,26,25],
[55,39,28,0],
[55,39,28,1],
[55,39,28,2],
[55,39,28,4],
[55,39,28,9],
[55,39,28,12],
[55,39,28,13],
[55,39,28,14],
[55,39,28,15],
[55,39,28,16],
[55,39,28,17],
[55,39,28,18],
[55,39,28,19],
[55,39,28,23],
[55,39,28,24],
[55,39,29,0],
[55,39,29,1],
[55,39,29,2],
[55,39,29,4],
[55,39,29,7],
[55,39,29,13],
[55,39,29,14],
[55,39,29,15],
[55,39,29,16],
[55,39,29,17],
[55,39,29,18],
[55,39,29,19],
[55,39,29,23],
[55,39,29,24],
[55,39,30,0],
[55,39,30,1],
[55,39,30,2],
[55,39,30,4],
[55,39,30,9],
[55,39,30,11],
[55,39,30,12],
[55,39,30,13],
[55,39,30,14],
[55,39,30,15],
[55,39,30,16],
[55,39,30,17],
[55,39,30,18],
[55,39,30,19],
[55,39,30,24],
[55,39,31,0],
[55,39,31,1],
[55,39,31,2],
[55,39,31,4],
[55,39,31,7],
[55,39,31,9],
[55,39,31,11],
[55,39,31,12],
[55,39,31,13],
[55,39,31,14],
[55,39,31,15],
[55,39,31,16],
[55,39,31,17],
[55,39,31,18],
[55,39,31,19],
[55,39,32,0],
[55,39,32,1],
[55,39,32,2],
[55,39,32,7],
[55,39,32,9],
[55,39,32,13],
[55,39,32,14],
[55,39,32,15],
[55,39,32,16],
[55,39,32,17],
[55,39,32,18],
[55,39,32,19],
[55,39,33,4],
[55,39,33,7],
[55,39,33,9],
[55,39,33,13],
[55,39,33,14],
[55,39,33,15],
[55,39,33,16],
[55,39,33,17],
[55,39,33,18],
[55,39,33,19],
[55,39,34,0],
[55,39,34,1],
[55,39,34,2],
[55,39,34,4],
[55,39,34,9],
[55,39,34,11],
[55,39,34,12],
[55,39,34,13],
[55,39,34,14],
[55,39,34,15],
[55,39,34,16],
[55,39,34,17],
[55,39,34,18],
[55,39,34,19],
[55,39,35,0],
[55,39,35,1],
[55,39,35,2],
[55,39,35,4],
[55,39,35,7],
[55,39,35,11],
[55,39,35,12],
[55,39,35,13],
[55,39,35,14],
[55,39,35,16],
[55,39,35,17],
[55,39,36,0],
[55,39,36,1],
[55,39,36,2],
[55,39,36,4],
[55,39,36,7],
[55,39,36,9],
[55,39,36,11],
[55,39,36,12],
[55,39,36,15],
[55,39,36,16],
[55,39,36,17],
[55,39,37,0],
[55,39,37,1],
[55,39,37,2],
[55,39,37,7],
[55,39,37,9],
[55,39,37,11],
[55,39,37,12],
[55,39,37,13],
[55,39,37,14],
[55,39,37,15],
[55,39,38,0],
[55,39,38,1],
[55,39,38,2],
[55,39,38,4],
[55,39,38,9],
[55,39,38,11],
[55,39,38,12],
[55,39,38,13],
[55,39,38,14],
[55,40,3,0],
[55,40,3,1],
[55,40,3,2],
[55,40,4,0],
[55,40,4,1],
[55,40,4,2],
[55,40,4,3],
[55,40,5,0],
[55,40,5,1],
[55,40,5,2],
[55,40,5,4],
[55,40,6,0],
[55,40,6,1],
[55,40,6,2],
[55,40,6,4],
[55,40,7,0],
[55,40,7,1],
[55,40,7,2],
[55,40,7,3],
[55,40,7,4],
[55,40,7,5],
[55,40,7,6],
[55,40,8,0],
[55,40,8,1],
[55,40,8,2],
[55,40,8,4],
[55,40,8,7],
[55,40,11,0],
[55,40,11,1],
[55,40,11,2],
[55,40,11,3],
[55,40,11,4],
[55,40,11,5],
[55,40,11,6],
[55,40,11,7],
[55,40,11,8],
[55,40,12,0],
[55,40,12,1],
[55,40,12,2],
[55,40,12,3],
[55,40,12,4],
[55,40,12,5],
[55,40,12,6],
[55,40,12,7],
[55,40,12,8],
[55,40,13,0],
[55,40,13,1],
[55,40,13,2],
[55,40,13,3],
[55,40,13,4],
[55,40,13,5],
[55,40,13,6],
[55,40,13,7],
[55,40,13,8],
[55,40,13,11],
[55,40,13,12],
[55,40,14,0],
[55,40,14,1],
[55,40,14,2],
[55,40,14,3],
[55,40,14,4],
[55,40,14,5],
[55,40,14,6],
[55,40,14,7],
[55,40,14,8],
[55,40,14,11],
[55,40,14,12],
[55,40,16,0],
[55,40,16,1],
[55,40,16,2],
[55,40,16,3],
[55,40,16,4],
[55,40,16,5],
[55,40,16,6],
[55,40,16,7],
[55,40,16,8],
[55,40,16,11],
[55,40,16,12],
[55,40,16,13],
[55,40,16,14],
[55,40,17,0],
[55,40,17,1],
[55,40,17,2],
[55,40,17,3],
[55,40,17,4],
[55,40,17,5],
[55,40,17,6],
[55,40,17,7],
[55,40,17,8],
[55,40,17,11],
[55,40,17,12],
[55,40,17,13],
[55,40,17,14],
[55,40,17,16],
[55,40,19,0],
[55,40,19,1],
[55,40,19,2],
[55,40,19,3],
[55,40,19,4],
[55,40,19,5],
[55,40,19,6],
[55,40,19,7],
[55,40,19,8],
[55,40,19,11],
[55,40,19,12],
[55,40,19,16],
[55,40,19,17],
[55,40,21,0],
[55,40,21,1],
[55,40,21,2],
[55,40,21,4],
[55,40,21,7],
[55,40,21,11],
[55,40,21,12],
[55,40,21,13],
[55,40,21,14],
[55,40,21,16],
[55,40,21,17],
[55,40,23,0],
[55,40,23,1],
[55,40,23,2],
[55,40,23,3],
[55,40,23,4],
[55,40,23,5],
[55,40,23,6],
[55,40,23,7],
[55,40,23,8],
[55,40,23,11],
[55,40,23,12],
[55,40,23,13],
[55,40,23,14],
[55,40,23,19],
[55,40,23,21],
[55,40,24,0],
[55,40,24,1],
[55,40,24,2],
[55,40,24,3],
[55,40,24,4],
[55,40,24,5],
[55,40,24,6],
[55,40,24,7],
[55,40,24,8],
[55,40,24,11],
[55,40,24,12],
[55,40,24,13],
[55,40,24,14],
[55,40,24,19],
[55,40,24,21],
[55,40,24,23],
[55,40,25,0],
[55,40,25,1],
[55,40,25,2],
[55,40,25,3],
[55,40,25,4],
[55,40,25,5],
[55,40,25,6],
[55,40,25,8],
[55,40,25,11],
[55,40,25,12],
[55,40,25,13],
[55,40,25,16],
[55,40,25,17],
[55,40,25,19],
[55,40,25,21],
[55,40,25,23],
[55,40,25,24],
[55,40,28,0],
[55,40,28,1],
[55,40,28,2],
[55,40,28,3],
[55,40,28,4],
[55,40,28,5],
[55,40,28,6],
[55,40,28,11],
[55,40,28,12],
[55,40,28,13],
[55,40,28,14],
[55,40,28,16],
[55,40,28,17],
[55,40,28,19],
[55,40,28,21],
[55,40,28,23],
[55,40,28,24],
[55,40,29,0],
[55,40,29,1],
[55,40,29,2],
[55,40,29,3],
[55,40,29,4],
[55,40,29,5],
[55,40,29,8],
[55,40,29,13],
[55,40,29,14],
[55,40,29,16],
[55,40,29,17],
[55,40,29,19],
[55,40,29,21],
[55,40,29,23],
[55,40,29,24],
[55,40,30,0],
[55,40,30,1],
[55,40,30,2],
[55,40,30,3],
[55,40,30,4],
[55,40,30,6],
[55,40,30,7],
[55,40,30,8],
[55,40,30,11],
[55,40,30,12],
[55,40,30,13],
[55,40,30,14],
[55,40,30,16],
[55,40,30,17],
[55,40,30,19],
[55,40,30,21],
[55,40,31,0],
[55,40,31,1],
[55,40,31,2],
[55,40,31,5],
[55,40,31,6],
[55,40,31,7],
[55,40,31,8],
[55,40,31,11],
[55,40,31,12],
[55,40,31,13],
[55,40,31,14],
[55,40,31,16],
[55,40,31,17],
[55,40,31,19],
[55,40,31,21],
[55,40,32,3],
[55,40,32,4],
[55,40,32,5],
[55,40,32,6],
[55,40,32,7],
[55,40,32,8],
[55,40,32,13],
[55,40,32,14],
[55,40,32,16],
[55,40,32,17],
[55,40,32,19],
[55,40,33,0],
[55,40,33,1],
[55,40,33,2],
[55,40,33,3],
[55,40,33,4],
[55,40,33,5],
[55,40,33,6],
[55,40,33,7],
[55,40,33,8],
[55,40,33,13],
[55,40,33,14],
[55,40,33,16],
[55,40,33,17],
[55,40,33,19],
[55,40,34,0],
[55,40,34,1],
[55,40,34,2],
[55,40,34,3],
[55,40,34,4],
[55,40,34,5],
[55,40,34,6],
[55,40,34,8],
[55,40,34,11],
[55,40,34,12],
[55,40,34,13],
[55,40,34,14],
[55,40,34,16],
[55,40,34,17],
[55,40,36,0],
[55,40,36,1],
[55,40,36,2],
[55,40,36,3],
[55,40,36,4],
[55,40,36,5],
[55,40,36,6],
[55,40,36,7],
[55,40,36,8],
[55,40,36,11],
[55,40,36,12],
[55,40,37,0],
[55,40,37,1],
[55,40,37,2],
[55,40,37,3],
[55,40,37,5],
[55,40,37,6],
[55,40,37,7],
[55,40,37,8],
[55,40,37,11],
[55,40,37,12],
[55,40,37,13],
[55,40,37,14],
[55,40,38,0],
[55,40,38,1],
[55,40,38,2],
[55,40,38,3],
[55,40,38,4],
[55,40,38,5],
[55,40,38,6],
[55,40,38,8],
[55,40,38,11],
[55,40,38,12],
[55,40,38,13],
[55,40,39,0],
[55,40,39,1],
[55,40,39,2],
[55,40,39,4],
[55,40,39,7],
[55,40,39,11],
[55,41,3,0],
[55,41,3,1],
[55,41,3,2],
[55,41,4,0],
[55,41,4,1],
[55,41,4,2],
[55,41,4,3],
[55,41,5,0],
[55,41,5,1],
[55,41,5,2],
[55,41,5,4],
[55,41,6,0],
[55,41,6,1],
[55,41,6,2],
[55,41,6,4],
[55,41,7,0],
[55,41,7,1],
[55,41,7,2],
[55,41,7,3],
[55,41,7,4],
[55,41,7,5],
[55,41,7,6],
[55,41,8,0],
[55,41,8,1],
[55,41,8,2],
[55,41,8,4],
[55,41,8,7],
[55,41,11,0],
[55,41,11,1],
[55,41,11,2],
[55,41,11,3],
[55,41,11,4],
[55,41,11,5],
[55,41,11,6],
[55,41,11,7],
[55,41,11,8],
[55,41,12,0],
[55,41,12,1],
[55,41,12,2],
[55,41,12,3],
[55,41,12,4],
[55,41,12,5],
[55,41,12,6],
[55,41,12,7],
[55,41,12,8],
[55,41,13,0],
[55,41,13,1],
[55,41,13,2],
[55,41,13,3],
[55,41,13,4],
[55,41,13,5],
[55,41,13,6],
[55,41,13,7],
[55,41,13,8],
[55,41,13,11],
[55,41,13,12],
[55,41,14,0],
[55,41,14,1],
[55,41,14,2],
[55,41,14,3],
[55,41,14,4],
[55,41,14,5],
[55,41,14,6],
[55,41,14,7],
[55,41,14,8],
[55,41,14,11],
[55,41,14,12],
[55,41,16,0],
[55,41,16,1],
[55,41,16,2],
[55,41,16,3],
[55,41,16,4],
[55,41,16,5],
[55,41,16,6],
[55,41,16,7],
[55,41,16,8],
[55,41,16,11],
[55,41,16,12],
[55,41,16,13],
[55,41,16,14],
[55,41,17,0],
[55,41,17,1],
[55,41,17,2],
[55,41,17,3],
[55,41,17,4],
[55,41,17,5],
[55,41,17,6],
[55,41,17,7],
[55,41,17,8],
[55,41,17,11],
[55,41,17,12],
[55,41,17,13],
[55,41,17,14],
[55,41,17,16],
[55,41,19,0],
[55,41,19,1],
[55,41,19,2],
[55,41,19,3],
[55,41,19,4],
[55,41,19,5],
[55,41,19,6],
[55,41,19,7],
[55,41,19,8],
[55,41,19,11],
[55,41,19,12],
[55,41,19,16],
[55,41,19,17],
[55,41,21,0],
[55,41,21,1],
[55,41,21,2],
[55,41,21,4],
[55,41,21,7],
[55,41,21,11],
[55,41,21,12],
[55,41,21,13],
[55,41,21,14],
[55,41,21,16],
[55,41,21,17],
[55,41,23,0],
[55,41,23,1],
[55,41,23,2],
[55,41,23,3],
[55,41,23,4],
[55,41,23,5],
[55,41,23,6],
[55,41,23,7],
[55,41,23,8],
[55,41,23,11],
[55,41,23,12],
[55,41,23,13],
[55,41,23,14],
[55,41,23,19],
[55,41,23,21],
[55,41,24,0],
[55,41,24,1],
[55,41,24,2],
[55,41,24,3],
[55,41,24,4],
[55,41,24,5],
[55,41,24,6],
[55,41,24,7],
[55,41,24,8],
[55,41,24,11],
[55,41,24,12],
[55,41,24,13],
[55,41,24,14],
[55,41,24,19],
[55,41,24,21],
[55,41,24,23],
[55,41,25,0],
[55,41,25,1],
[55,41,25,2],
[55,41,25,3],
[55,41,25,4],
[55,41,25,5],
[55,41,25,6],
[55,41,25,8],
[55,41,25,11],
[55,41,25,12],
[55,41,25,13],
[55,41,25,16],
[55,41,25,17],
[55,41,25,19],
[55,41,25,21],
[55,41,25,23],
[55,41,25,24],
[55,41,28,0],
[55,41,28,1],
[55,41,28,2],
[55,41,28,3],
[55,41,28,4],
[55,41,28,5],
[55,41,28,6],
[55,41,28,11],
[55,41,28,12],
[55,41,28,13],
[55,41,28,14],
[55,41,28,16],
[55,41,28,17],
[55,41,28,19],
[55,41,28,21],
[55,41,28,23],
[55,41,28,24],
[55,41,29,0],
[55,41,29,1],
[55,41,29,2],
[55,41,29,3],
[55,41,29,4],
[55,41,29,5],
[55,41,29,8],
[55,41,29,13],
[55,41,29,14],
[55,41,29,16],
[55,41,29,17],
[55,41,29,19],
[55,41,29,21],
[55,41,29,23],
[55,41,29,24],
[55,41,30,0],
[55,41,30,1],
[55,41,30,2],
[55,41,30,3],
[55,41,30,4],
[55,41,30,6],
[55,41,30,7],
[55,41,30,8],
[55,41,30,11],
[55,41,30,12],
[55,41,30,13],
[55,41,30,14],
[55,41,30,16],
[55,41,30,17],
[55,41,30,19],
[55,41,30,21],
[55,41,31,0],
[55,41,31,1],
[55,41,31,2],
[55,41,31,5],
[55,41,31,6],
[55,41,31,7],
[55,41,31,8],
[55,41,31,11],
[55,41,31,12],
[55,41,31,13],
[55,41,31,14],
[55,41,31,16],
[55,41,31,17],
[55,41,31,19],
[55,41,31,21],
[55,41,32,3],
[55,41,32,4],
[55,41,32,5],
[55,41,32,6],
[55,41,32,7],
[55,41,32,8],
[55,41,32,13],
[55,41,32,14],
[55,41,32,16],
[55,41,32,17],
[55,41,32,19],
[55,41,33,0],
[55,41,33,1],
[55,41,33,2],
[55,41,33,3],
[55,41,33,4],
[55,41,33,5],
[55,41,33,6],
[55,41,33,7],
[55,41,33,8],
[55,41,33,13],
[55,41,33,14],
[55,41,33,16],
[55,41,33,17],
[55,41,33,19],
[55,41,34,0],
[55,41,34,1],
[55,41,34,2],
[55,41,34,3],
[55,41,34,4],
[55,41,34,5],
[55,41,34,6],
[55,41,34,8],
[55,41,34,11],
[55,41,34,12],
[55,41,34,13],
[55,41,34,14],
[55,41,34,16],
[55,41,34,17],
[55,41,36,0],
[55,41,36,1],
[55,41,36,2],
[55,41,36,3],
[55,41,36,4],
[55,41,36,5],
[55,41,36,6],
[55,41,36,7],
[55,41,36,8],
[55,41,36,11],
[55,41,36,12],
[55,41,37,0],
[55,41,37,1],
[55,41,37,2],
[55,41,37,3],
[55,41,37,5],
[55,41,37,6],
[55,41,37,7],
[55,41,37,8],
[55,41,37,11],
[55,41,37,12],
[55,41,37,13],
[55,41,37,14],
[55,41,38,0],
[55,41,38,1],
[55,41,38,2],
[55,41,38,3],
[55,41,38,4],
[55,41,38,5],
[55,41,38,6],
[55,41,38,8],
[55,41,38,11],
[55,41,38,12],
[55,41,38,13],
[55,41,39,0],
[55,41,39,1],
[55,41,39,2],
[55,41,39,4],
[55,41,39,7],
[55,41,39,11],
[55,43,3,0],
[55,43,3,1],
[55,43,3,2],
[55,43,4,0],
[55,43,4,1],
[55,43,4,2],
[55,43,4,3],
[55,43,5,0],
[55,43,5,1],
[55,43,5,2],
[55,43,5,4],
[55,43,6,0],
[55,43,6,1],
[55,43,6,2],
[55,43,6,4],
[55,43,8,0],
[55,43,8,1],
[55,43,8,2],
[55,43,8,4],
[55,43,9,0],
[55,43,9,1],
[55,43,9,2],
[55,43,9,3],
[55,43,9,4],
[55,43,9,5],
[55,43,9,6],
[55,43,9,8],
[55,43,11,0],
[55,43,11,1],
[55,43,11,2],
[55,43,11,3],
[55,43,11,4],
[55,43,11,5],
[55,43,11,6],
[55,43,11,8],
[55,43,11,9],
[55,43,12,0],
[55,43,12,1],
[55,43,12,2],
[55,43,12,3],
[55,43,12,4],
[55,43,12,5],
[55,43,12,6],
[55,43,12,8],
[55,43,12,9],
[55,43,13,0],
[55,43,13,1],
[55,43,13,2],
[55,43,13,3],
[55,43,13,4],
[55,43,13,5],
[55,43,13,6],
[55,43,13,8],
[55,43,13,9],
[55,43,13,11],
[55,43,13,12],
[55,43,14,0],
[55,43,14,1],
[55,43,14,2],
[55,43,14,3],
[55,43,14,4],
[55,43,14,5],
[55,43,14,6],
[55,43,14,8],
[55,43,14,9],
[55,43,14,11],
[55,43,14,12],
[55,43,15,0],
[55,43,15,1],
[55,43,15,2],
[55,43,15,3],
[55,43,15,4],
[55,43,15,5],
[55,43,15,6],
[55,43,15,8],
[55,43,15,11],
[55,43,15,12],
[55,43,15,13],
[55,43,15,14],
[55,43,16,0],
[55,43,16,1],
[55,43,16,2],
[55,43,16,3],
[55,43,16,4],
[55,43,16,5],
[55,43,16,6],
[55,43,16,8],
[55,43,16,9],
[55,43,16,11],
[55,43,16,12],
[55,43,16,13],
[55,43,16,14],
[55,43,16,15],
[55,43,17,0],
[55,43,17,1],
[55,43,17,2],
[55,43,17,3],
[55,43,17,4],
[55,43,17,5],
[55,43,17,6],
[55,43,17,8],
[55,43,17,9],
[55,43,17,11],
[55,43,17,12],
[55,43,17,13],
[55,43,17,14],
[55,43,17,15],
[55,43,17,16],
[55,43,18,0],
[55,43,18,1],
[55,43,18,2],
[55,43,18,3],
[55,43,18,4],
[55,43,18,5],
[55,43,18,6],
[55,43,18,8],
[55,43,18,11],
[55,43,18,12],
[55,43,18,13],
[55,43,18,14],
[55,43,18,16],
[55,43,18,17],
[55,43,19,0],
[55,43,19,1],
[55,43,19,2],
[55,43,19,3],
[55,43,19,4],
[55,43,19,5],
[55,43,19,6],
[55,43,19,8],
[55,43,19,9],
[55,43,19,11],
[55,43,19,12],
[55,43,19,15],
[55,43,19,16],
[55,43,19,17],
[55,43,19,18],
[55,43,21,0],
[55,43,21,1],
[55,43,21,2],
[55,43,21,4],
[55,43,21,9],
[55,43,21,11],
[55,43,21,12],
[55,43,21,13],
[55,43,21,14],
[55,43,21,15],
[55,43,21,18],
[55,43,21,19],
[55,43,23,0],
[55,43,23,1],
[55,43,23,2],
[55,43,23,3],
[55,43,23,4],
[55,43,23,5],
[55,43,23,6],
[55,43,23,8],
[55,43,23,9],
[55,43,23,11],
[55,43,23,12],
[55,43,23,13],
[55,43,23,15],
[55,43,23,16],
[55,43,23,17],
[55,43,23,18],
[55,43,23,19],
[55,43,23,21],
[55,43,24,0],
[55,43,24,1],
[55,43,24,2],
[55,43,24,3],
[55,43,24,4],
[55,43,24,5],
[55,43,24,6],
[55,43,24,8],
[55,43,24,9],
[55,43,24,11],
[55,43,24,12],
[55,43,24,13],
[55,43,24,15],
[55,43,24,17],
[55,43,24,18],
[55,43,24,19],
[55,43,24,21],
[55,43,24,23],
[55,43,26,0],
[55,43,26,1],
[55,43,26,2],
[55,43,26,3],
[55,43,26,4],
[55,43,26,5],
[55,43,26,6],
[55,43,26,11],
[55,43,26,12],
[55,43,26,13],
[55,43,26,14],
[55,43,26,16],
[55,43,26,17],
[55,43,26,19],
[55,43,26,21],
[55,43,26,23],
[55,43,26,24],
[55,43,29,0],
[55,43,29,1],
[55,43,29,2],
[55,43,29,5],
[55,43,29,6],
[55,43,29,8],
[55,43,29,9],
[55,43,29,13],
[55,43,29,14],
[55,43,29,15],
[55,43,29,16],
[55,43,29,17],
[55,43,29,18],
[55,43,29,19],
[55,43,29,21],
[55,43,30,3],
[55,43,30,4],
[55,43,30,5],
[55,43,30,6],
[55,43,30,8],
[55,43,30,9],
[55,43,30,11],
[55,43,30,12],
[55,43,30,13],
[55,43,30,14],
[55,43,30,15],
[55,43,30,16],
[55,43,30,17],
[55,43,30,18],
[55,43,30,19],
[55,43,31,0],
[55,43,31,1],
[55,43,31,2],
[55,43,31,3],
[55,43,31,4],
[55,43,31,5],
[55,43,31,6],
[55,43,31,8],
[55,43,31,9],
[55,43,31,11],
[55,43,31,12],
[55,43,31,13],
[55,43,31,14],
[55,43,31,15],
[55,43,31,16],
[55,43,31,17],
[55,43,31,18],
[55,43,31,19],
[55,43,32,0],
[55,43,32,1],
[55,43,32,2],
[55,43,32,3],
[55,43,32,4],
[55,43,32,5],
[55,43,32,6],
[55,43,32,8],
[55,43,32,9],
[55,43,32,13],
[55,43,32,14],
[55,43,32,15],
[55,43,32,16],
[55,43,32,17],
[55,43,32,18],
[55,43,33,0],
[55,43,33,1],
[55,43,33,2],
[55,43,33,3],
[55,43,33,4],
[55,43,33,5],
[55,43,33,6],
[55,43,33,8],
[55,43,33,9],
[55,43,33,13],
[55,43,33,14],
[55,43,33,15],
[55,43,33,16],
[55,43,33,17],
[55,43,35,0],
[55,43,35,1],
[55,43,35,2],
[55,43,35,3],
[55,43,35,4],
[55,43,35,5],
[55,43,35,6],
[55,43,35,8],
[55,43,35,11],
[55,43,35,12],
[55,43,35,13],
[55,43,35,14],
[55,43,36,0],
[55,43,36,1],
[55,43,36,2],
[55,43,36,3],
[55,43,36,4],
[55,43,36,5],
[55,43,36,6],
[55,43,36,8],
[55,43,36,9],
[55,43,36,11],
[55,43,36,12],
[55,43,37,0],
[55,43,37,1],
[55,43,37,2],
[55,43,37,3],
[55,43,37,5],
[55,43,37,6],
[55,43,37,8],
[55,43,37,9],
[55,43,37,11],
[55,43,39,0],
[55,43,39,1],
[55,43,39,2],
[55,43,39,4],
[55,43,40,0],
[55,43,40,1],
[55,43,40,2],
[55,43,40,3],
[55,43,40,4],
[55,43,40,5],
[55,43,41,0],
[55,43,41,1],
[55,43,41,2],
[55,43,41,3],
[55,43,41,4],
[55,43,41,5],
[55,44,3,0],
[55,44,3,1],
[55,44,3,2],
[55,44,4,0],
[55,44,4,1],
[55,44,4,2],
[55,44,4,3],
[55,44,5,0],
[55,44,5,1],
[55,44,5,2],
[55,44,5,4],
[55,44,6,0],
[55,44,6,1],
[55,44,6,2],
[55,44,6,4],
[55,44,7,0],
[55,44,7,1],
[55,44,7,2],
[55,44,7,3],
[55,44,7,4],
[55,44,7,5],
[55,44,7,6],
[55,44,8,0],
[55,44,8,1],
[55,44,8,2],
[55,44,8,4],
[55,44,8,7],
[55,44,9,0],
[55,44,9,1],
[55,44,9,2],
[55,44,9,3],
[55,44,9,4],
[55,44,9,5],
[55,44,9,6],
[55,44,9,7],
[55,44,9,8],
[55,44,11,0],
[55,44,11,1],
[55,44,11,2],
[55,44,11,3],
[55,44,11,4],
[55,44,11,5],
[55,44,11,6],
[55,44,11,7],
[55,44,11,8],
[55,44,11,9],
[55,44,12,0],
[55,44,12,1],
[55,44,12,2],
[55,44,12,3],
[55,44,12,4],
[55,44,12,5],
[55,44,12,6],
[55,44,12,7],
[55,44,12,8],
[55,44,12,9],
[55,44,13,0],
[55,44,13,1],
[55,44,13,2],
[55,44,13,3],
[55,44,13,4],
[55,44,13,5],
[55,44,13,6],
[55,44,13,7],
[55,44,13,8],
[55,44,13,9],
[55,44,13,11],
[55,44,13,12],
[55,44,14,0],
[55,44,14,1],
[55,44,14,2],
[55,44,14,3],
[55,44,14,4],
[55,44,14,5],
[55,44,14,6],
[55,44,14,7],
[55,44,14,8],
[55,44,14,9],
[55,44,14,11],
[55,44,14,12],
[55,44,15,0],
[55,44,15,1],
[55,44,15,2],
[55,44,15,3],
[55,44,15,4],
[55,44,15,5],
[55,44,15,6],
[55,44,15,7],
[55,44,15,8],
[55,44,15,11],
[55,44,15,12],
[55,44,15,13],
[55,44,15,14],
[55,44,16,0],
[55,44,16,1],
[55,44,16,2],
[55,44,16,3],
[55,44,16,4],
[55,44,16,5],
[55,44,16,6],
[55,44,16,7],
[55,44,16,8],
[55,44,16,9],
[55,44,16,11],
[55,44,16,12],
[55,44,16,13],
[55,44,16,14],
[55,44,16,15],
[55,44,18,0],
[55,44,18,1],
[55,44,18,2],
[55,44,18,3],
[55,44,18,4],
[55,44,18,5],
[55,44,18,6],
[55,44,18,7],
[55,44,18,8],
[55,44,18,11],
[55,44,18,12],
[55,44,18,13],
[55,44,18,14],
[55,44,18,16],
[55,44,19,0],
[55,44,19,1],
[55,44,19,2],
[55,44,19,3],
[55,44,19,4],
[55,44,19,5],
[55,44,19,6],
[55,44,19,7],
[55,44,19,8],
[55,44,19,9],
[55,44,19,11],
[55,44,19,12],
[55,44,19,15],
[55,44,19,16],
[55,44,21,0],
[55,44,21,1],
[55,44,21,2],
[55,44,21,4],
[55,44,21,7],
[55,44,21,9],
[55,44,21,11],
[55,44,21,12],
[55,44,21,13],
[55,44,21,14],
[55,44,21,16],
[55,44,21,18],
[55,44,21,19],
[55,44,23,0],
[55,44,23,1],
[55,44,23,2],
[55,44,23,3],
[55,44,23,4],
[55,44,23,5],
[55,44,23,6],
[55,44,23,7],
[55,44,23,8],
[55,44,23,9],
[55,44,23,11],
[55,44,23,14],
[55,44,23,15],
[55,44,23,16],
[55,44,23,18],
[55,44,23,19],
[55,44,23,21],
[55,44,24,0],
[55,44,24,1],
[55,44,24,2],
[55,44,24,3],
[55,44,24,4],
[55,44,24,5],
[55,44,24,6],
[55,44,24,7],
[55,44,24,8],
[55,44,24,9],
[55,44,24,11],
[55,44,24,14],
[55,44,24,15],
[55,44,24,18],
[55,44,24,19],
[55,44,24,21],
[55,44,24,23],
[55,44,25,0],
[55,44,25,1],
[55,44,25,2],
[55,44,25,3],
[55,44,25,4],
[55,44,25,5],
[55,44,25,6],
[55,44,25,11],
[55,44,25,12],
[55,44,25,13],
[55,44,25,14],
[55,44,25,15],
[55,44,25,16],
[55,44,25,18],
[55,44,25,19],
[55,44,25,21],
[55,44,25,23],
[55,44,25,24],
[55,44,26,0],
[55,44,26,1],
[55,44,26,2],
[55,44,26,3],
[55,44,26,4],
[55,44,26,5],
[55,44,26,8],
[55,44,26,11],
[55,44,26,12],
[55,44,26,13],
[55,44,26,14],
[55,44,26,16],
[55,44,26,19],
[55,44,26,21],
[55,44,26,23],
[55,44,26,24],
[55,44,28,0],
[55,44,28,1],
[55,44,28,2],
[55,44,28,5],
[55,44,28,6],
[55,44,28,8],
[55,44,28,9],
[55,44,28,11],
[55,44,28,12],
[55,44,28,13],
[55,44,28,14],
[55,44,28,15],
[55,44,28,16],
[55,44,28,18],
[55,44,28,19],
[55,44,28,21],
[55,44,29,3],
[55,44,29,4],
[55,44,29,5],
[55,44,29,6],
[55,44,29,7],
[55,44,29,8],
[55,44,29,9],
[55,44,29,13],
[55,44,29,14],
[55,44,29,15],
[55,44,29,16],
[55,44,29,18],
[55,44,29,19],
[55,44,30,0],
[55,44,30,1],
[55,44,30,2],
[55,44,30,3],
[55,44,30,4],
[55,44,30,5],
[55,44,30,6],
[55,44,30,7],
[55,44,30,8],
[55,44,30,9],
[55,44,30,11],
[55,44,30,12],
[55,44,30,13],
[55,44,30,14],
[55,44,30,15],
[55,44,30,16],
[55,44,30,18],
[55,44,30,19],
[55,44,31,0],
[55,44,31,1],
[55,44,31,2],
[55,44,31,3],
[55,44,31,4],
[55,44,31,5],
[55,44,31,6],
[55,44,31,7],
[55,44,31,8],
[55,44,31,9],
[55,44,31,11],
[55,44,31,12],
[55,44,31,13],
[55,44,31,14],
[55,44,31,15],
[55,44,31,16],
[55,44,31,18],
[55,44,32,0],
[55,44,32,1],
[55,44,32,2],
[55,44,32,3],
[55,44,32,4],
[55,44,32,5],
[55,44,32,6],
[55,44,32,7],
[55,44,32,8],
[55,44,32,9],
[55,44,32,13],
[55,44,32,14],
[55,44,32,15],
[55,44,32,16],
[55,44,33,0],
[55,44,33,1],
[55,44,33,2],
[55,44,33,3],
[55,44,33,4],
[55,44,33,5],
[55,44,33,6],
[55,44,33,7],
[55,44,33,8],
[55,44,33,9],
[55,44,33,13],
[55,44,33,14],
[55,44,33,15],
[55,44,34,0],
[55,44,34,1],
[55,44,34,2],
[55,44,34,3],
[55,44,34,4],
[55,44,34,5],
[55,44,34,6],
[55,44,34,8],
[55,44,34,9],
[55,44,34,11],
[55,44,34,12],
[55,44,34,13],
[55,44,34,14],
[55,44,35,0],
[55,44,35,1],
[55,44,35,2],
[55,44,35,3],
[55,44,35,4],
[55,44,35,5],
[55,44,35,6],
[55,44,35,7],
[55,44,35,8],
[55,44,35,11],
[55,44,35,12],
[55,44,35,13],
[55,44,36,0],
[55,44,36,1],
[55,44,36,2],
[55,44,36,3],
[55,44,36,4],
[55,44,36,5],
[55,44,36,6],
[55,44,36,7],
[55,44,36,8],
[55,44,36,9],
[55,44,36,11],
[55,44,37,0],
[55,44,37,1],
[55,44,37,2],
[55,44,37,3],
[55,44,37,5],
[55,44,37,6],
[55,44,37,7],
[55,44,37,8],
[55,44,37,9],
[55,44,38,0],
[55,44,38,1],
[55,44,38,2],
[55,44,38,3],
[55,44,38,4],
[55,44,38,5],
[55,44,38,6],
[55,44,39,0],
[55,44,39,1],
[55,44,39,2],
[55,44,39,4],
[55,44,40,0],
[55,44,40,1],
[55,44,40,2],
[55,44,40,3],
[55,44,40,4],
[55,44,41,0],
[55,44,41,1],
[55,44,41,2],
[55,44,41,3],
[55,44,41,4],
[55,45,3,0],
[55,45,3,1],
[55,45,3,2],
[55,45,4,0],
[55,45,4,1],
[55,45,4,2],
[55,45,4,3],
[55,45,5,0],
[55,45,5,1],
[55,45,5,2],
[55,45,5,4],
[55,45,6,0],
[55,45,6,1],
[55,45,6,2],
[55,45,6,4],
[55,45,7,0],
[55,45,7,1],
[55,45,7,2],
[55,45,7,3],
[55,45,7,4],
[55,45,7,5],
[55,45,7,6],
[55,45,8,0],
[55,45,8,1],
[55,45,8,2],
[55,45,8,4],
[55,45,8,7],
[55,45,11,0],
[55,45,11,1],
[55,45,11,2],
[55,45,11,3],
[55,45,11,4],
[55,45,11,5],
[55,45,11,6],
[55,45,11,7],
[55,45,11,8],
[55,45,12,0],
[55,45,12,1],
[55,45,12,2],
[55,45,12,3],
[55,45,12,4],
[55,45,12,5],
[55,45,12,6],
[55,45,12,7],
[55,45,12,8],
[55,45,13,0],
[55,45,13,1],
[55,45,13,2],
[55,45,13,3],
[55,45,13,4],
[55,45,13,5],
[55,45,13,6],
[55,45,13,7],
[55,45,13,8],
[55,45,13,11],
[55,45,13,12],
[55,45,14,0],
[55,45,14,1],
[55,45,14,2],
[55,45,14,3],
[55,45,14,4],
[55,45,14,5],
[55,45,14,6],
[55,45,14,7],
[55,45,14,8],
[55,45,14,11],
[55,45,14,12],
[55,45,16,0],
[55,45,16,1],
[55,45,16,2],
[55,45,16,3],
[55,45,16,4],
[55,45,16,5],
[55,45,16,6],
[55,45,16,7],
[55,45,16,8],
[55,45,16,11],
[55,45,16,12],
[55,45,16,13],
[55,45,16,14],
[55,45,17,0],
[55,45,17,1],
[55,45,17,2],
[55,45,17,3],
[55,45,17,4],
[55,45,17,5],
[55,45,17,6],
[55,45,17,7],
[55,45,17,8],
[55,45,17,11],
[55,45,17,12],
[55,45,17,13],
[55,45,17,14],
[55,45,17,16],
[55,45,19,0],
[55,45,19,1],
[55,45,19,2],
[55,45,19,3],
[55,45,19,4],
[55,45,19,5],
[55,45,19,6],
[55,45,19,7],
[55,45,19,8],
[55,45,19,11],
[55,45,19,12],
[55,45,21,0],
[55,45,21,1],
[55,45,21,2],
[55,45,21,4],
[55,45,21,7],
[55,45,21,11],
[55,45,21,12],
[55,45,21,13],
[55,45,21,16],
[55,45,21,17],
[55,45,21,19],
[55,45,23,0],
[55,45,23,1],
[55,45,23,2],
[55,45,23,3],
[55,45,23,4],
[55,45,23,5],
[55,45,23,6],
[55,45,23,7],
[55,45,23,8],
[55,45,23,12],
[55,45,23,13],
[55,45,23,14],
[55,45,23,16],
[55,45,23,17],
[55,45,23,19],
[55,45,23,21],
[55,45,24,0],
[55,45,24,1],
[55,45,24,2],
[55,45,24,3],
[55,45,24,4],
[55,45,24,5],
[55,45,24,6],
[55,45,24,7],
[55,45,24,8],
[55,45,24,12],
[55,45,24,13],
[55,45,24,14],
[55,45,24,17],
[55,45,24,19],
[55,45,24,21],
[55,45,24,23],
[55,45,25,0],
[55,45,25,1],
[55,45,25,2],
[55,45,25,3],
[55,45,25,4],
[55,45,25,5],
[55,45,25,8],
[55,45,25,11],
[55,45,25,12],
[55,45,25,13],
[55,45,25,14],
[55,45,25,16],
[55,45,25,17],
[55,45,25,19],
[55,45,25,21],
[55,45,25,23],
[55,45,25,24],
[55,45,28,3],
[55,45,28,4],
[55,45,28,5],
[55,45,28,6],
[55,45,28,8],
[55,45,28,11],
[55,45,28,12],
[55,45,28,13],
[55,45,28,14],
[55,45,28,16],
[55,45,28,17],
[55,45,28,19],
[55,45,29,0],
[55,45,29,1],
[55,45,29,2],
[55,45,29,3],
[55,45,29,4],
[55,45,29,5],
[55,45,29,6],
[55,45,29,7],
[55,45,29,8],
[55,45,29,13],
[55,45,29,14],
[55,45,29,16],
[55,45,29,17],
[55,45,29,19],
[55,45,30,0],
[55,45,30,1],
[55,45,30,2],
[55,45,30,3],
[55,45,30,4],
[55,45,30,5],
[55,45,30,6],
[55,45,30,7],
[55,45,30,8],
[55,45,30,11],
[55,45,30,12],
[55,45,30,13],
[55,45,30,14],
[55,45,30,16],
[55,45,30,17],
[55,45,31,0],
[55,45,31,1],
[55,45,31,2],
[55,45,31,3],
[55,45,31,4],
[55,45,31,5],
[55,45,31,6],
[55,45,31,7],
[55,45,31,8],
[55,45,31,11],
[55,45,31,12],
[55,45,31,13],
[55,45,31,14],
[55,45,31,16],
[55,45,31,17],
[55,45,32,0],
[55,45,32,1],
[55,45,32,2],
[55,45,32,3],
[55,45,32,4],
[55,45,32,5],
[55,45,32,6],
[55,45,32,7],
[55,45,32,8],
[55,45,32,13],
[55,45,32,14],
[55,45,33,0],
[55,45,33,1],
[55,45,33,2],
[55,45,33,3],
[55,45,33,4],
[55,45,33,5],
[55,45,33,6],
[55,45,33,7],
[55,45,33,8],
[55,45,33,13],
[55,45,33,14],
[55,45,34,0],
[55,45,34,1],
[55,45,34,2],
[55,45,34,3],
[55,45,34,4],
[55,45,34,5],
[55,45,34,6],
[55,45,34,8],
[55,45,34,11],
[55,45,34,12],
[55,45,34,13],
[55,45,36,0],
[55,45,36,1],
[55,45,36,2],
[55,45,36,3],
[55,45,36,4],
[55,45,36,5],
[55,45,36,6],
[55,45,36,7],
[55,45,36,8],
[55,45,37,0],
[55,45,37,1],
[55,45,37,2],
[55,45,37,3],
[55,45,37,5],
[55,45,37,6],
[55,45,37,7],
[55,45,38,0],
[55,45,38,1],
[55,45,38,2],
[55,45,38,3],
[55,45,38,4],
[55,45,38,5],
[55,45,39,0],
[55,45,39,1],
[55,45,39,2],
[55,45,39,4],
[55,46,3,0],
[55,46,3,1],
[55,46,3,2],
[55,46,4,0],
[55,46,4,1],
[55,46,4,2],
[55,46,4,3],
[55,46,5,0],
[55,46,5,1],
[55,46,5,2],
[55,46,5,4],
[55,46,6,0],
[55,46,6,1],
[55,46,6,2],
[55,46,6,4],
[55,46,7,0],
[55,46,7,1],
[55,46,7,2],
[55,46,7,3],
[55,46,7,4],
[55,46,7,5],
[55,46,7,6],
[55,46,8,0],
[55,46,8,1],
[55,46,8,2],
[55,46,8,4],
[55,46,8,7],
[55,46,11,0],
[55,46,11,1],
[55,46,11,2],
[55,46,11,3],
[55,46,11,4],
[55,46,11,5],
[55,46,11,6],
[55,46,11,7],
[55,46,11,8],
[55,46,12,0],
[55,46,12,1],
[55,46,12,2],
[55,46,12,3],
[55,46,12,4],
[55,46,12,5],
[55,46,12,6],
[55,46,12,7],
[55,46,12,8],
[55,46,13,0],
[55,46,13,1],
[55,46,13,2],
[55,46,13,3],
[55,46,13,4],
[55,46,13,5],
[55,46,13,6],
[55,46,13,7],
[55,46,13,8],
[55,46,13,11],
[55,46,13,12],
[55,46,14,0],
[55,46,14,1],
[55,46,14,2],
[55,46,14,3],
[55,46,14,4],
[55,46,14,5],
[55,46,14,6],
[55,46,14,7],
[55,46,14,8],
[55,46,14,11],
[55,46,14,12],
[55,46,16,0],
[55,46,16,1],
[55,46,16,2],
[55,46,16,3],
[55,46,16,4],
[55,46,16,5],
[55,46,16,6],
[55,46,16,7],
[55,46,16,8],
[55,46,16,11],
[55,46,16,12],
[55,46,16,13],
[55,46,16,14],
[55,46,17,0],
[55,46,17,1],
[55,46,17,2],
[55,46,17,3],
[55,46,17,4],
[55,46,17,5],
[55,46,17,6],
[55,46,17,7],
[55,46,17,8],
[55,46,17,11],
[55,46,17,12],
[55,46,17,13],
[55,46,17,14],
[55,46,17,16],
[55,46,19,0],
[55,46,19,1],
[55,46,19,2],
[55,46,19,3],
[55,46,19,4],
[55,46,19,5],
[55,46,19,6],
[55,46,19,7],
[55,46,19,8],
[55,46,19,11],
[55,46,19,12],
[55,46,19,16],
[55,46,19,17],
[55,46,21,0],
[55,46,21,1],
[55,46,21,2],
[55,46,21,4],
[55,46,21,7],
[55,46,21,11],
[55,46,21,14],
[55,46,21,16],
[55,46,21,17],
[55,46,21,19],
[55,46,23,0],
[55,46,23,1],
[55,46,23,2],
[55,46,23,3],
[55,46,23,4],
[55,46,23,5],
[55,46,23,6],
[55,46,23,7],
[55,46,23,11],
[55,46,23,12],
[55,46,23,13],
[55,46,23,14],
[55,46,23,16],
[55,46,23,17],
[55,46,23,19],
[55,46,23,21],
[55,46,24,0],
[55,46,24,1],
[55,46,24,2],
[55,46,24,3],
[55,46,24,4],
[55,46,24,5],
[55,46,24,6],
[55,46,24,7],
[55,46,24,11],
[55,46,24,12],
[55,46,24,13],
[55,46,24,14],
[55,46,24,17],
[55,46,24,19],
[55,46,24,21],
[55,46,24,23],
[55,46,25,0],
[55,46,25,1],
[55,46,25,2],
[55,46,25,3],
[55,46,25,4],
[55,46,25,6],
[55,46,25,8],
[55,46,25,11],
[55,46,25,12],
[55,46,25,13],
[55,46,25,14],
[55,46,25,16],
[55,46,25,17],
[55,46,25,19],
[55,46,25,21],
[55,46,28,0],
[55,46,28,1],
[55,46,28,2],
[55,46,28,3],
[55,46,28,4],
[55,46,28,5],
[55,46,28,6],
[55,46,28,8],
[55,46,28,11],
[55,46,28,12],
[55,46,28,13],
[55,46,28,14],
[55,46,28,16],
[55,46,28,17],
[55,46,28,19],
[55,46,29,0],
[55,46,29,1],
[55,46,29,2],
[55,46,29,3],
[55,46,29,4],
[55,46,29,5],
[55,46,29,6],
[55,46,29,7],
[55,46,29,8],
[55,46,29,13],
[55,46,29,14],
[55,46,29,16],
[55,46,29,17],
[55,46,30,0],
[55,46,30,1],
[55,46,30,2],
[55,46,30,3],
[55,46,30,4],
[55,46,30,5],
[55,46,30,6],
[55,46,30,7],
[55,46,30,8],
[55,46,30,11],
[55,46,30,12],
[55,46,30,13],
[55,46,30,14],
[55,46,30,16],
[55,46,30,17],
[55,46,31,0],
[55,46,31,1],
[55,46,31,2],
[55,46,31,3],
[55,46,31,4],
[55,46,31,5],
[55,46,31,6],
[55,46,31,7],
[55,46,31,8],
[55,46,31,11],
[55,46,31,12],
[55,46,31,13],
[55,46,31,14],
[55,46,32,0],
[55,46,32,1],
[55,46,32,2],
[55,46,32,3],
[55,46,32,4],
[55,46,32,5],
[55,46,32,6],
[55,46,32,7],
[55,46,32,8],
[55,46,32,13],
[55,46,32,14],
[55,46,33,0],
[55,46,33,1],
[55,46,33,2],
[55,46,33,3],
[55,46,33,4],
[55,46,33,5],
[55,46,33,6],
[55,46,33,7],
[55,46,33,8],
[55,46,33,13],
[55,46,34,0],
[55,46,34,1],
[55,46,34,2],
[55,46,34,3],
[55,46,34,4],
[55,46,34,5],
[55,46,34,6],
[55,46,34,8],
[55,46,34,11],
[55,46,36,0],
[55,46,36,1],
[55,46,36,2],
[55,46,36,3],
[55,46,36,4],
[55,46,36,5],
[55,46,36,6],
[55,46,36,7],
[55,46,37,0],
[55,46,37,1],
[55,46,37,2],
[55,46,37,3],
[55,46,37,5],
[55,46,38,0],
[55,46,38,1],
[55,46,38,2],
[55,46,38,3],
[55,46,38,4],
[55,46,39,0],
[55,46,39,1],
[55,46,39,2],
[55,47,3,0],
[55,47,3,1],
[55,47,3,2],
[55,47,4,0],
[55,47,4,1],
[55,47,4,2],
[55,47,4,3],
[55,47,5,0],
[55,47,5,1],
[55,47,5,2],
[55,47,5,4],
[55,47,6,0],
[55,47,6,1],
[55,47,6,2],
[55,47,6,4],
[55,47,7,0],
[55,47,7,1],
[55,47,7,2],
[55,47,7,3],
[55,47,7,4],
[55,47,7,5],
[55,47,7,6],
[55,47,8,0],
[55,47,8,1],
[55,47,8,2],
[55,47,8,4],
[55,47,8,7],
[55,47,9,0],
[55,47,9,1],
[55,47,9,2],
[55,47,9,3],
[55,47,9,4],
[55,47,9,5],
[55,47,9,6],
[55,47,9,7],
[55,47,9,8],
[55,47,11,0],
[55,47,11,1],
[55,47,11,2],
[55,47,11,3],
[55,47,11,4],
[55,47,11,5],
[55,47,11,6],
[55,47,11,7],
[55,47,11,8],
[55,47,11,9],
[55,47,12,0],
[55,47,12,1],
[55,47,12,2],
[55,47,12,3],
[55,47,12,4],
[55,47,12,5],
[55,47,12,6],
[55,47,12,7],
[55,47,12,8],
[55,47,12,9],
[55,47,13,0],
[55,47,13,1],
[55,47,13,2],
[55,47,13,3],
[55,47,13,4],
[55,47,13,5],
[55,47,13,6],
[55,47,13,7],
[55,47,13,8],
[55,47,13,9],
[55,47,13,11],
[55,47,13,12],
[55,47,14,0],
[55,47,14,1],
[55,47,14,2],
[55,47,14,3],
[55,47,14,4],
[55,47,14,5],
[55,47,14,6],
[55,47,14,7],
[55,47,14,8],
[55,47,14,9],
[55,47,14,11],
[55,47,14,12],
[55,47,15,0],
[55,47,15,1],
[55,47,15,2],
[55,47,15,3],
[55,47,15,4],
[55,47,15,5],
[55,47,15,6],
[55,47,15,7],
[55,47,15,8],
[55,47,15,11],
[55,47,15,12],
[55,47,15,13],
[55,47,15,14],
[55,47,16,0],
[55,47,16,1],
[55,47,16,2],
[55,47,16,3],
[55,47,16,4],
[55,47,16,5],
[55,47,16,6],
[55,47,16,7],
[55,47,16,8],
[55,47,16,9],
[55,47,16,11],
[55,47,16,12],
[55,47,16,13],
[55,47,16,14],
[55,47,16,15],
[55,47,17,0],
[55,47,17,1],
[55,47,17,2],
[55,47,17,3],
[55,47,17,4],
[55,47,17,5],
[55,47,17,6],
[55,47,17,7],
[55,47,17,8],
[55,47,17,9],
[55,47,17,11],
[55,47,17,12],
[55,47,17,13],
[55,47,17,14],
[55,47,17,15],
[55,47,18,0],
[55,47,18,1],
[55,47,18,2],
[55,47,18,3],
[55,47,18,4],
[55,47,18,5],
[55,47,18,6],
[55,47,18,7],
[55,47,18,8],
[55,47,18,11],
[55,47,18,12],
[55,47,18,13],
[55,47,18,14],
[55,47,18,16],
[55,47,18,17],
[55,47,19,0],
[55,47,19,1],
[55,47,19,2],
[55,47,19,3],
[55,47,19,4],
[55,47,19,5],
[55,47,19,6],
[55,47,19,7],
[55,47,19,8],
[55,47,19,9],
[55,47,19,11],
[55,47,19,12],
[55,47,19,15],
[55,47,19,16],
[55,47,19,17],
[55,47,19,18],
[55,47,21,0],
[55,47,21,1],
[55,47,21,2],
[55,47,21,4],
[55,47,21,7],
[55,47,21,9],
[55,47,21,12],
[55,47,21,13],
[55,47,21,14],
[55,47,21,15],
[55,47,21,16],
[55,47,21,17],
[55,47,21,18],
[55,47,21,19],
[55,47,24,0],
[55,47,24,1],
[55,47,24,2],
[55,47,24,3],
[55,47,24,4],
[55,47,24,5],
[55,47,24,8],
[55,47,24,9],
[55,47,24,11],
[55,47,24,12],
[55,47,24,13],
[55,47,24,14],
[55,47,24,15],
[55,47,24,17],
[55,47,24,18],
[55,47,24,19],
[55,47,24,21],
[55,47,25,0],
[55,47,25,1],
[55,47,25,2],
[55,47,25,5],
[55,47,25,6],
[55,47,25,8],
[55,47,25,9],
[55,47,25,11],
[55,47,25,12],
[55,47,25,13],
[55,47,25,14],
[55,47,25,15],
[55,47,25,16],
[55,47,25,17],
[55,47,25,18],
[55,47,25,19],
[55,47,25,21],
[55,47,26,3],
[55,47,26,4],
[55,47,26,5],
[55,47,26,6],
[55,47,26,7],
[55,47,26,8],
[55,47,26,11],
[55,47,26,12],
[55,47,26,13],
[55,47,26,14],
[55,47,26,16],
[55,47,26,17],
[55,47,26,19],
[55,47,28,0],
[55,47,28,1],
[55,47,28,2],
[55,47,28,3],
[55,47,28,4],
[55,47,28,5],
[55,47,28,6],
[55,47,28,8],
[55,47,28,9],
[55,47,28,11],
[55,47,28,12],
[55,47,28,13],
[55,47,28,14],
[55,47,28,15],
[55,47,28,16],
[55,47,28,17],
[55,47,28,18],
[55,47,29,0],
[55,47,29,1],
[55,47,29,2],
[55,47,29,3],
[55,47,29,4],
[55,47,29,5],
[55,47,29,6],
[55,47,29,7],
[55,47,29,8],
[55,47,29,9],
[55,47,29,13],
[55,47,29,14],
[55,47,29,15],
[55,47,29,16],
[55,47,29,17],
[55,47,32,0],
[55,47,32,1],
[55,47,32,2],
[55,47,32,3],
[55,47,32,4],
[55,47,32,5],
[55,47,32,6],
[55,47,32,7],
[55,47,32,8],
[55,47,32,9],
[55,47,32,13],
[55,47,33,0],
[55,47,33,1],
[55,47,33,2],
[55,47,33,3],
[55,47,33,4],
[55,47,33,5],
[55,47,33,6],
[55,47,33,7],
[55,47,33,8],
[55,47,33,9],
[55,47,34,0],
[55,47,34,1],
[55,47,34,2],
[55,47,34,3],
[55,47,34,4],
[55,47,34,5],
[55,47,34,6],
[55,47,34,8],
[55,47,34,9],
[55,47,35,0],
[55,47,35,1],
[55,47,35,2],
[55,47,35,3],
[55,47,35,4],
[55,47,35,5],
[55,47,35,6],
[55,47,35,7],
[55,47,36,0],
[55,47,36,1],
[55,47,36,2],
[55,47,36,3],
[55,47,36,4],
[55,47,36,5],
[55,47,37,0],
[55,47,37,1],
[55,47,37,2],
[55,47,37,3],
[55,47,38,0],
[55,47,38,1],
[55,47,38,2],
[55,48,3,0],
[55,48,3,1],
[55,48,3,2],
[55,48,4,0],
[55,48,4,1],
[55,48,4,2],
[55,48,4,3],
[55,48,5,0],
[55,48,5,1],
[55,48,5,2],
[55,48,5,4],
[55,48,6,0],
[55,48,6,1],
[55,48,6,2],
[55,48,6,4],
[55,48,7,0],
[55,48,7,1],
[55,48,7,2],
[55,48,7,3],
[55,48,7,4],
[55,48,7,5],
[55,48,7,6],
[55,48,8,0],
[55,48,8,1],
[55,48,8,2],
[55,48,8,4],
[55,48,8,7],
[55,48,9,0],
[55,48,9,1],
[55,48,9,2],
[55,48,9,3],
[55,48,9,4],
[55,48,9,5],
[55,48,9,6],
[55,48,9,7],
[55,48,9,8],
[55,48,13,0],
[55,48,13,1],
[55,48,13,2],
[55,48,13,3],
[55,48,13,4],
[55,48,13,5],
[55,48,13,6],
[55,48,13,7],
[55,48,13,8],
[55,48,13,9],
[55,48,14,0],
[55,48,14,1],
[55,48,14,2],
[55,48,14,3],
[55,48,14,4],
[55,48,14,5],
[55,48,14,6],
[55,48,14,7],
[55,48,14,8],
[55,48,14,9],
[55,48,15,0],
[55,48,15,1],
[55,48,15,2],
[55,48,15,3],
[55,48,15,4],
[55,48,15,5],
[55,48,15,6],
[55,48,15,7],
[55,48,15,8],
[55,48,15,13],
[55,48,15,14],
[55,48,16,0],
[55,48,16,1],
[55,48,16,2],
[55,48,16,3],
[55,48,16,4],
[55,48,16,5],
[55,48,16,6],
[55,48,16,7],
[55,48,16,8],
[55,48,16,9],
[55,48,16,13],
[55,48,16,14],
[55,48,17,0],
[55,48,17,1],
[55,48,17,2],
[55,48,17,3],
[55,48,17,4],
[55,48,17,5],
[55,48,17,6],
[55,48,17,7],
[55,48,17,8],
[55,48,17,9],
[55,48,17,13],
[55,48,17,14],
[55,48,17,16],
[55,48,18,0],
[55,48,18,1],
[55,48,18,2],
[55,48,18,3],
[55,48,18,4],
[55,48,18,5],
[55,48,18,6],
[55,48,18,7],
[55,48,18,8],
[55,48,18,13],
[55,48,18,16],
[55,48,18,17],
[55,48,19,0],
[55,48,19,1],
[55,48,19,2],
[55,48,19,3],
[55,48,19,4],
[55,48,19,5],
[55,48,19,6],
[55,48,19,7],
[55,48,19,8],
[55,48,19,9],
[55,48,19,15],
[55,48,19,16],
[55,48,19,17],
[55,48,19,18],
[55,48,21,0],
[55,48,21,1],
[55,48,21,2],
[55,48,21,4],
[55,48,21,7],
[55,48,21,13],
[55,48,21,14],
[55,48,21,15],
[55,48,21,16],
[55,48,21,17],
[55,48,21,18],
[55,48,21,19],
[55,48,23,0],
[55,48,23,1],
[55,48,23,2],
[55,48,23,3],
[55,48,23,4],
[55,48,23,6],
[55,48,23,7],
[55,48,23,8],
[55,48,23,9],
[55,48,23,13],
[55,48,23,14],
[55,48,23,15],
[55,48,23,16],
[55,48,23,17],
[55,48,23,18],
[55,48,23,19],
[55,48,23,21],
[55,48,24,0],
[55,48,24,1],
[55,48,24,2],
[55,48,24,3],
[55,48,24,4],
[55,48,24,6],
[55,48,24,7],
[55,48,24,8],
[55,48,24,9],
[55,48,24,13],
[55,48,24,14],
[55,48,24,15],
[55,48,24,17],
[55,48,24,18],
[55,48,24,19],
[55,48,24,21],
[55,48,25,3],
[55,48,25,4],
[55,48,25,5],
[55,48,25,6],
[55,48,25,8],
[55,48,25,9],
[55,48,25,13],
[55,48,25,14],
[55,48,25,15],
[55,48,25,16],
[55,48,25,17],
[55,48,25,18],
[55,48,25,19],
[55,48,26,0],
[55,48,26,1],
[55,48,26,2],
[55,48,26,3],
[55,48,26,4],
[55,48,26,5],
[55,48,26,6],
[55,48,26,7],
[55,48,26,8],
[55,48,26,13],
[55,48,26,14],
[55,48,26,16],
[55,48,26,17],
[55,48,26,19],
[55,48,28,0],
[55,48,28,1],
[55,48,28,2],
[55,48,28,3],
[55,48,28,4],
[55,48,28,5],
[55,48,28,6],
[55,48,28,8],
[55,48,28,9],
[55,48,28,13],
[55,48,28,14],
[55,48,28,15],
[55,48,28,16],
[55,48,28,17],
[55,48,30,0],
[55,48,30,1],
[55,48,30,2],
[55,48,30,3],
[55,48,30,4],
[55,48,30,5],
[55,48,30,6],
[55,48,30,7],
[55,48,30,8],
[55,48,30,9],
[55,48,30,13],
[55,48,30,14],
[55,48,31,0],
[55,48,31,1],
[55,48,31,2],
[55,48,31,3],
[55,48,31,4],
[55,48,31,5],
[55,48,31,6],
[55,48,31,7],
[55,48,31,8],
[55,48,31,9],
[55,48,31,13],
[55,48,34,0],
[55,48,34,1],
[55,48,34,2],
[55,48,34,3],
[55,48,34,4],
[55,48,34,5],
[55,48,34,6],
[55,48,35,0],
[55,48,35,1],
[55,48,35,2],
[55,48,35,3],
[55,48,35,4],
[55,48,35,5],
[55,48,36,0],
[55,48,36,1],
[55,48,36,2],
[55,48,36,3],
[55,48,36,4],
[55,48,37,0],
[55,48,37,1],
[55,48,37,2],
[55,49,3,0],
[55,49,3,1],
[55,49,3,2],
[55,49,4,0],
[55,49,4,1],
[55,49,4,2],
[55,49,4,3],
[55,49,5,0],
[55,49,5,1],
[55,49,5,2],
[55,49,5,4],
[55,49,6,0],
[55,49,6,1],
[55,49,6,2],
[55,49,6,4],
[55,49,7,0],
[55,49,7,1],
[55,49,7,2],
[55,49,7,3],
[55,49,7,4],
[55,49,7,5],
[55,49,7,6],
[55,49,8,0],
[55,49,8,1],
[55,49,8,2],
[55,49,8,4],
[55,49,8,7],
[55,49,11,0],
[55,49,11,1],
[55,49,11,2],
[55,49,11,3],
[55,49,11,4],
[55,49,11,5],
[55,49,11,6],
[55,49,11,7],
[55,49,11,8],
[55,49,12,0],
[55,49,12,1],
[55,49,12,2],
[55,49,12,3],
[55,49,12,4],
[55,49,12,5],
[55,49,12,6],
[55,49,12,7],
[55,49,12,8],
[55,49,13,0],
[55,49,13,1],
[55,49,13,2],
[55,49,13,3],
[55,49,13,4],
[55,49,13,5],
[55,49,13,6],
[55,49,13,7],
[55,49,13,8],
[55,49,13,11],
[55,49,13,12],
[55,49,14,0],
[55,49,14,1],
[55,49,14,2],
[55,49,14,3],
[55,49,14,4],
[55,49,14,5],
[55,49,14,6],
[55,49,14,7],
[55,49,14,8],
[55,49,14,11],
[55,49,14,12],
[55,49,16,0],
[55,49,16,1],
[55,49,16,2],
[55,49,16,3],
[55,49,16,4],
[55,49,16,5],
[55,49,16,6],
[55,49,16,7],
[55,49,16,8],
[55,49,16,11],
[55,49,16,12],
[55,49,16,13],
[55,49,16,14],
[55,49,17,0],
[55,49,17,1],
[55,49,17,2],
[55,49,17,3],
[55,49,17,4],
[55,49,17,5],
[55,49,17,6],
[55,49,17,7],
[55,49,17,8],
[55,49,17,11],
[55,49,17,12],
[55,49,17,13],
[55,49,17,14],
[55,49,17,16],
[55,49,19,0],
[55,49,19,1],
[55,49,19,2],
[55,49,19,3],
[55,49,19,4],
[55,49,19,5],
[55,49,19,6],
[55,49,19,7],
[55,49,19,8],
[55,49,19,11],
[55,49,19,16],
[55,49,19,17],
[55,49,21,0],
[55,49,21,1],
[55,49,21,2],
[55,49,21,4],
[55,49,21,7],
[55,49,21,11],
[55,49,21,12],
[55,49,21,13],
[55,49,21,14],
[55,49,21,16],
[55,49,21,17],
[55,49,21,19],
[55,49,23,0],
[55,49,23,1],
[55,49,23,2],
[55,49,23,3],
[55,49,23,4],
[55,49,23,6],
[55,49,23,7],
[55,49,23,8],
[55,49,23,11],
[55,49,23,12],
[55,49,23,13],
[55,49,23,14],
[55,49,23,16],
[55,49,23,17],
[55,49,23,19],
[55,49,23,21],
[55,49,24,0],
[55,49,24,1],
[55,49,24,2],
[55,49,24,3],
[55,49,24,4],
[55,49,24,6],
[55,49,24,7],
[55,49,24,8],
[55,49,24,11],
[55,49,24,12],
[55,49,24,13],
[55,49,24,14],
[55,49,24,17],
[55,49,24,19],
[55,49,24,21],
[55,49,25,3],
[55,49,25,4],
[55,49,25,5],
[55,49,25,6],
[55,49,25,8],
[55,49,25,11],
[55,49,25,12],
[55,49,25,13],
[55,49,25,14],
[55,49,25,16],
[55,49,25,17],
[55,49,25,19],
[55,49,28,0],
[55,49,28,1],
[55,49,28,2],
[55,49,28,3],
[55,49,28,4],
[55,49,28,5],
[55,49,28,6],
[55,49,28,8],
[55,49,28,11],
[55,49,28,12],
[55,49,28,13],
[55,49,28,14],
[55,49,28,16],
[55,49,28,17],
[55,49,29,0],
[55,49,29,1],
[55,49,29,2],
[55,49,29,3],
[55,49,29,4],
[55,49,29,5],
[55,49,29,6],
[55,49,29,7],
[55,49,29,8],
[55,49,29,13],
[55,49,29,14],
[55,49,30,0],
[55,49,30,1],
[55,49,30,2],
[55,49,30,3],
[55,49,30,4],
[55,49,30,5],
[55,49,30,6],
[55,49,30,7],
[55,49,30,8],
[55,49,30,11],
[55,49,30,12],
[55,49,30,13],
[55,49,30,14],
[55,49,31,0],
[55,49,31,1],
[55,49,31,2],
[55,49,31,3],
[55,49,31,4],
[55,49,31,5],
[55,49,31,6],
[55,49,31,7],
[55,49,31,8],
[55,49,31,11],
[55,49,31,12],
[55,49,31,13],
[55,49,32,0],
[55,49,32,1],
[55,49,32,2],
[55,49,32,3],
[55,49,32,4],
[55,49,32,5],
[55,49,32,6],
[55,49,32,7],
[55,49,32,8],
[55,49,33,0],
[55,49,33,1],
[55,49,33,2],
[55,49,33,3],
[55,49,33,4],
[55,49,33,5],
[55,49,33,6],
[55,49,33,7],
[55,49,33,8],
[55,49,34,0],
[55,49,34,1],
[55,49,34,2],
[55,49,34,3],
[55,49,34,4],
[55,49,34,5],
[55,49,34,6],
[55,49,36,0],
[55,49,36,1],
[55,49,36,2],
[55,49,36,3],
[55,49,36,4],
[55,49,37,0],
[55,49,37,1],
[55,49,37,2],
[55,50,3,0],
[55,50,3,1],
[55,50,3,2],
[55,50,4,0],
[55,50,4,1],
[55,50,4,2],
[55,50,4,3],
[55,50,5,0],
[55,50,5,1],
[55,50,5,2],
[55,50,5,4],
[55,50,6,0],
[55,50,6,1],
[55,50,6,2],
[55,50,6,4],
[55,50,7,0],
[55,50,7,1],
[55,50,7,2],
[55,50,7,3],
[55,50,7,4],
[55,50,7,5],
[55,50,7,6],
[55,50,8,0],
[55,50,8,1],
[55,50,8,2],
[55,50,8,4],
[55,50,8,7],
[55,50,11,0],
[55,50,11,1],
[55,50,11,2],
[55,50,11,3],
[55,50,11,4],
[55,50,11,5],
[55,50,11,6],
[55,50,11,7],
[55,50,11,8],
[55,50,12,0],
[55,50,12,1],
[55,50,12,2],
[55,50,12,3],
[55,50,12,4],
[55,50,12,5],
[55,50,12,6],
[55,50,12,7],
[55,50,12,8],
[55,50,13,0],
[55,50,13,1],
[55,50,13,2],
[55,50,13,3],
[55,50,13,4],
[55,50,13,5],
[55,50,13,6],
[55,50,13,7],
[55,50,13,8],
[55,50,13,11],
[55,50,13,12],
[55,50,14,0],
[55,50,14,1],
[55,50,14,2],
[55,50,14,3],
[55,50,14,4],
[55,50,14,5],
[55,50,14,6],
[55,50,14,7],
[55,50,14,8],
[55,50,14,11],
[55,50,14,12],
[55,50,16,0],
[55,50,16,1],
[55,50,16,2],
[55,50,16,3],
[55,50,16,4],
[55,50,16,5],
[55,50,16,6],
[55,50,16,7],
[55,50,16,8],
[55,50,16,11],
[55,50,16,12],
[55,50,16,13],
[55,50,16,14],
[55,50,17,0],
[55,50,17,1],
[55,50,17,2],
[55,50,17,3],
[55,50,17,4],
[55,50,17,5],
[55,50,17,6],
[55,50,17,7],
[55,50,17,8],
[55,50,17,11],
[55,50,17,12],
[55,50,17,13],
[55,50,17,14],
[55,50,17,16],
[55,50,19,0],
[55,50,19,1],
[55,50,19,2],
[55,50,19,3],
[55,50,19,4],
[55,50,19,5],
[55,50,19,6],
[55,50,19,7],
[55,50,19,8],
[55,50,19,11],
[55,50,19,16],
[55,50,19,17],
[55,50,21,0],
[55,50,21,1],
[55,50,21,2],
[55,50,21,4],
[55,50,21,7],
[55,50,21,11],
[55,50,21,12],
[55,50,21,13],
[55,50,21,14],
[55,50,21,16],
[55,50,21,17],
[55,50,21,19],
[55,50,23,0],
[55,50,23,1],
[55,50,23,2],
[55,50,23,3],
[55,50,23,4],
[55,50,23,6],
[55,50,23,7],
[55,50,23,8],
[55,50,23,11],
[55,50,23,12],
[55,50,23,13],
[55,50,23,14],
[55,50,23,16],
[55,50,23,17],
[55,50,23,19],
[55,50,23,21],
[55,50,24,0],
[55,50,24,1],
[55,50,24,2],
[55,50,24,3],
[55,50,24,4],
[55,50,24,6],
[55,50,24,7],
[55,50,24,8],
[55,50,24,11],
[55,50,24,12],
[55,50,24,13],
[55,50,24,14],
[55,50,24,17],
[55,50,24,19],
[55,50,24,21],
[55,50,25,3],
[55,50,25,4],
[55,50,25,5],
[55,50,25,6],
[55,50,25,8],
[55,50,25,11],
[55,50,25,12],
[55,50,25,13],
[55,50,25,14],
[55,50,25,16],
[55,50,25,17],
[55,50,25,19],
[55,50,28,0],
[55,50,28,1],
[55,50,28,2],
[55,50,28,3],
[55,50,28,4],
[55,50,28,5],
[55,50,28,6],
[55,50,28,8],
[55,50,28,11],
[55,50,28,12],
[55,50,28,13],
[55,50,28,14],
[55,50,28,16],
[55,50,28,17],
[55,50,29,0],
[55,50,29,1],
[55,50,29,2],
[55,50,29,3],
[55,50,29,4],
[55,50,29,5],
[55,50,29,6],
[55,50,29,7],
[55,50,29,8],
[55,50,29,13],
[55,50,29,14],
[55,50,30,0],
[55,50,30,1],
[55,50,30,2],
[55,50,30,3],
[55,50,30,4],
[55,50,30,5],
[55,50,30,6],
[55,50,30,7],
[55,50,30,8],
[55,50,30,11],
[55,50,30,12],
[55,50,30,13],
[55,50,30,14],
[55,50,31,0],
[55,50,31,1],
[55,50,31,2],
[55,50,31,3],
[55,50,31,4],
[55,50,31,5],
[55,50,31,6],
[55,50,31,7],
[55,50,31,8],
[55,50,31,11],
[55,50,31,12],
[55,50,31,13],
[55,50,32,0],
[55,50,32,1],
[55,50,32,2],
[55,50,32,3],
[55,50,32,4],
[55,50,32,5],
[55,50,32,6],
[55,50,32,7],
[55,50,32,8],
[55,50,33,0],
[55,50,33,1],
[55,50,33,2],
[55,50,33,3],
[55,50,33,4],
[55,50,33,5],
[55,50,33,6],
[55,50,33,7],
[55,50,33,8],
[55,50,34,0],
[55,50,34,1],
[55,50,34,2],
[55,50,34,3],
[55,50,34,4],
[55,50,34,5],
[55,50,34,6],
[55,50,36,0],
[55,50,36,1],
[55,50,36,2],
[55,50,36,3],
[55,50,36,4],
[55,50,37,0],
[55,50,37,1],
[55,50,37,2],
[55,51,4,0],
[55,51,4,1],
[55,51,4,2],
[55,51,7,0],
[55,51,7,1],
[55,51,7,2],
[55,51,7,4],
[55,51,9,0],
[55,51,9,1],
[55,51,9,2],
[55,51,9,4],
[55,51,9,7],
[55,51,11,0],
[55,51,11,1],
[55,51,11,2],
[55,51,11,4],
[55,51,11,7],
[55,51,11,9],
[55,51,12,0],
[55,51,12,1],
[55,51,12,2],
[55,51,12,4],
[55,51,12,7],
[55,51,12,9],
[55,51,13,0],
[55,51,13,1],
[55,51,13,2],
[55,51,13,4],
[55,51,13,7],
[55,51,13,9],
[55,51,13,11],
[55,51,13,12],
[55,51,14,0],
[55,51,14,1],
[55,51,14,2],
[55,51,14,4],
[55,51,14,7],
[55,51,14,9],
[55,51,14,11],
[55,51,14,12],
[55,51,15,0],
[55,51,15,1],
[55,51,15,2],
[55,51,15,4],
[55,51,15,7],
[55,51,15,11],
[55,51,15,12],
[55,51,15,13],
[55,51,15,14],
[55,51,16,0],
[55,51,16,1],
[55,51,16,2],
[55,51,16,4],
[55,51,16,7],
[55,51,16,9],
[55,51,16,11],
[55,51,16,12],
[55,51,16,13],
[55,51,16,14],
[55,51,17,0],
[55,51,17,1],
[55,51,17,2],
[55,51,17,4],
[55,51,17,7],
[55,51,17,9],
[55,51,17,11],
[55,51,17,12],
[55,51,17,13],
[55,51,17,14],
[55,51,17,16],
[55,51,18,0],
[55,51,18,1],
[55,51,18,2],
[55,51,18,4],
[55,51,18,7],
[55,51,18,11],
[55,51,18,12],
[55,51,18,13],
[55,51,18,16],
[55,51,18,17],
[55,51,19,0],
[55,51,19,1],
[55,51,19,2],
[55,51,19,4],
[55,51,19,7],
[55,51,19,9],
[55,51,19,11],
[55,51,19,15],
[55,51,19,16],
[55,51,19,17],
[55,51,19,18],
[55,51,23,0],
[55,51,23,1],
[55,51,23,2],
[55,51,23,4],
[55,51,23,7],
[55,51,23,9],
[55,51,23,11],
[55,51,23,12],
[55,51,23,13],
[55,51,23,14],
[55,51,23,15],
[55,51,23,16],
[55,51,23,17],
[55,51,23,18],
[55,51,23,19],
[55,51,24,0],
[55,51,24,1],
[55,51,24,2],
[55,51,24,4],
[55,51,24,7],
[55,51,24,9],
[55,51,24,11],
[55,51,24,12],
[55,51,24,13],
[55,51,24,14],
[55,51,24,15],
[55,51,24,17],
[55,51,24,18],
[55,51,24,19],
[55,51,25,4],
[55,51,25,9],
[55,51,25,11],
[55,51,25,12],
[55,51,25,13],
[55,51,25,14],
[55,51,25,15],
[55,51,25,16],
[55,51,25,17],
[55,51,25,18],
[55,51,25,19],
[55,51,26,0],
[55,51,26,1],
[55,51,26,2],
[55,51,26,4],
[55,51,26,7],
[55,51,26,11],
[55,51,26,12],
[55,51,26,13],
[55,51,26,14],
[55,51,26,16],
[55,51,26,17],
[55,51,26,19],
[55,51,28,0],
[55,51,28,1],
[55,51,28,2],
[55,51,28,4],
[55,51,28,9],
[55,51,28,11],
[55,51,28,12],
[55,51,28,13],
[55,51,28,14],
[55,51,28,15],
[55,51,28,16],
[55,51,28,17],
[55,51,29,0],
[55,51,29,1],
[55,51,29,2],
[55,51,29,4],
[55,51,29,7],
[55,51,29,9],
[55,51,29,13],
[55,51,29,14],
[55,51,29,15],
[55,51,30,0],
[55,51,30,1],
[55,51,30,2],
[55,51,30,4],
[55,51,30,7],
[55,51,30,9],
[55,51,30,11],
[55,51,30,12],
[55,51,30,13],
[55,51,30,14],
[55,51,31,0],
[55,51,31,1],
[55,51,31,2],
[55,51,31,4],
[55,51,31,7],
[55,51,31,9],
[55,51,31,11],
[55,51,31,12],
[55,51,31,13],
[55,51,32,0],
[55,51,32,1],
[55,51,32,2],
[55,51,32,4],
[55,51,32,7],
[55,51,32,9],
[55,51,33,0],
[55,51,33,1],
[55,51,33,2],
[55,51,33,4],
[55,51,33,7],
[55,51,33,9],
[55,51,34,0],
[55,51,34,1],
[55,51,34,2],
[55,51,34,4],
[55,51,35,0],
[55,51,35,1],
[55,51,35,2],
[55,51,35,4],
[55,51,36,0],
[55,51,36,1],
[55,51,36,2],
[55,51,36,4],
[55,51,37,0],
[55,51,37,1],
[55,51,37,2],
[55,52,3,0],
[55,52,3,1],
[55,52,3,2],
[55,52,4,0],
[55,52,4,1],
[55,52,4,2],
[55,52,4,3],
[55,52,5,0],
[55,52,5,1],
[55,52,5,2],
[55,52,5,4],
[55,52,6,0],
[55,52,6,1],
[55,52,6,2],
[55,52,6,4],
[55,52,7,0],
[55,52,7,1],
[55,52,7,2],
[55,52,7,3],
[55,52,7,4],
[55,52,7,5],
[55,52,7,6],
[55,52,8,0],
[55,52,8,1],
[55,52,8,2],
[55,52,8,4],
[55,52,8,7],
[55,52,11,0],
[55,52,11,1],
[55,52,11,2],
[55,52,11,3],
[55,52,11,4],
[55,52,11,5],
[55,52,11,6],
[55,52,11,7],
[55,52,11,8],
[55,52,12,0],
[55,52,12,1],
[55,52,12,2],
[55,52,12,3],
[55,52,12,4],
[55,52,12,5],
[55,52,12,6],
[55,52,12,7],
[55,52,12,8],
[55,52,13,0],
[55,52,13,1],
[55,52,13,2],
[55,52,13,3],
[55,52,13,4],
[55,52,13,5],
[55,52,13,6],
[55,52,13,7],
[55,52,13,8],
[55,52,13,11],
[55,52,13,12],
[55,52,14,0],
[55,52,14,1],
[55,52,14,2],
[55,52,14,3],
[55,52,14,4],
[55,52,14,5],
[55,52,14,6],
[55,52,14,7],
[55,52,14,8],
[55,52,14,11],
[55,52,14,12],
[55,52,16,0],
[55,52,16,1],
[55,52,16,2],
[55,52,16,3],
[55,52,16,4],
[55,52,16,5],
[55,52,16,6],
[55,52,16,7],
[55,52,16,8],
[55,52,16,11],
[55,52,16,12],
[55,52,16,13],
[55,52,16,14],
[55,52,17,0],
[55,52,17,1],
[55,52,17,2],
[55,52,17,3],
[55,52,17,4],
[55,52,17,5],
[55,52,17,6],
[55,52,17,7],
[55,52,17,8],
[55,52,17,11],
[55,52,17,12],
[55,52,17,13],
[55,52,17,14],
[55,52,17,16],
[55,52,19,0],
[55,52,19,1],
[55,52,19,2],
[55,52,19,3],
[55,52,19,4],
[55,52,19,5],
[55,52,19,6],
[55,52,19,7],
[55,52,19,8],
[55,52,19,11],
[55,52,19,16],
[55,52,19,17],
[55,52,21,0],
[55,52,21,1],
[55,52,21,2],
[55,52,21,4],
[55,52,21,7],
[55,52,21,11],
[55,52,21,12],
[55,52,21,13],
[55,52,21,14],
[55,52,21,16],
[55,52,21,17],
[55,52,21,19],
[55,52,23,0],
[55,52,23,1],
[55,52,23,2],
[55,52,23,3],
[55,52,23,4],
[55,52,23,6],
[55,52,23,7],
[55,52,23,8],
[55,52,23,11],
[55,52,23,12],
[55,52,23,13],
[55,52,23,14],
[55,52,23,16],
[55,52,23,17],
[55,52,23,19],
[55,52,23,21],
[55,52,24,0],
[55,52,24,1],
[55,52,24,2],
[55,52,24,3],
[55,52,24,4],
[55,52,24,6],
[55,52,24,7],
[55,52,24,8],
[55,52,24,11],
[55,52,24,12],
[55,52,24,13],
[55,52,24,14],
[55,52,24,17],
[55,52,24,19],
[55,52,24,21],
[55,52,25,3],
[55,52,25,4],
[55,52,25,5],
[55,52,25,6],
[55,52,25,8],
[55,52,25,11],
[55,52,25,12],
[55,52,25,13],
[55,52,25,14],
[55,52,25,16],
[55,52,25,17],
[55,52,25,19],
[55,52,28,0],
[55,52,28,1],
[55,52,28,2],
[55,52,28,3],
[55,52,28,4],
[55,52,28,5],
[55,52,28,6],
[55,52,28,8],
[55,52,28,11],
[55,52,28,12],
[55,52,28,13],
[55,52,28,14],
[55,52,28,16],
[55,52,28,17],
[55,52,29,0],
[55,52,29,1],
[55,52,29,2],
[55,52,29,3],
[55,52,29,4],
[55,52,29,5],
[55,52,29,6],
[55,52,29,7],
[55,52,29,8],
[55,52,29,13],
[55,52,29,14],
[55,52,30,0],
[55,52,30,1],
[55,52,30,2],
[55,52,30,3],
[55,52,30,4],
[55,52,30,5],
[55,52,30,6],
[55,52,30,7],
[55,52,30,8],
[55,52,30,11],
[55,52,30,12],
[55,52,30,13],
[55,52,30,14],
[55,52,31,0],
[55,52,31,1],
[55,52,31,2],
[55,52,31,3],
[55,52,31,4],
[55,52,31,5],
[55,52,31,6],
[55,52,31,7],
[55,52,31,8],
[55,52,31,11],
[55,52,31,12],
[55,52,31,13],
[55,52,32,0],
[55,52,32,1],
[55,52,32,2],
[55,52,32,3],
[55,52,32,4],
[55,52,32,5],
[55,52,32,6],
[55,52,32,7],
[55,52,32,8],
[55,52,33,0],
[55,52,33,1],
[55,52,33,2],
[55,52,33,3],
[55,52,33,4],
[55,52,33,5],
[55,52,33,6],
[55,52,33,7],
[55,52,33,8],
[55,52,34,0],
[55,52,34,1],
[55,52,34,2],
[55,52,34,3],
[55,52,34,4],
[55,52,34,5],
[55,52,34,6],
[55,52,36,0],
[55,52,36,1],
[55,52,36,2],
[55,52,36,3],
[55,52,36,4],
[55,52,37,0],
[55,52,37,1],
[55,52,37,2],
[56,4,3,0],
[56,4,3,1],
[56,4,3,2],
[56,5,4,0],
[56,5,4,1],
[56,5,4,2],
[56,6,4,0],
[56,6,4,1],
[56,6,4,2],
[56,7,3,0],
[56,7,3,1],
[56,7,3,2],
[56,7,4,0],
[56,7,4,1],
[56,7,4,2],
[56,7,4,3],
[56,7,5,0],
[56,7,5,1],
[56,7,5,2],
[56,7,5,4],
[56,7,6,0],
[56,7,6,1],
[56,7,6,2],
[56,7,6,4],
[56,8,4,0],
[56,8,4,1],
[56,8,4,2],
[56,8,7,0],
[56,8,7,1],
[56,8,7,2],
[56,8,7,4],
[56,10,3,0],
[56,10,3,1],
[56,10,3,2],
[56,10,4,0],
[56,10,4,1],
[56,10,4,2],
[56,10,4,3],
[56,10,5,0],
[56,10,5,1],
[56,10,5,2],
[56,10,5,4],
[56,10,6,0],
[56,10,6,1],
[56,10,6,2],
[56,10,6,4],
[56,10,7,0],
[56,10,7,1],
[56,10,7,2],
[56,10,7,3],
[56,10,7,4],
[56,10,7,5],
[56,10,7,6],
[56,10,8,0],
[56,10,8,1],
[56,10,8,2],
[56,10,8,4],
[56,10,8,7],
[56,11,3,0],
[56,11,3,1],
[56,11,3,2],
[56,11,4,0],
[56,11,4,1],
[56,11,4,2],
[56,11,4,3],
[56,11,5,0],
[56,11,5,1],
[56,11,5,2],
[56,11,5,4],
[56,11,6,0],
[56,11,6,1],
[56,11,6,2],
[56,11,6,4],
[56,11,7,0],
[56,11,7,1],
[56,11,7,2],
[56,11,7,3],
[56,11,7,4],
[56,11,7,5],
[56,11,7,6],
[56,11,8,0],
[56,11,8,1],
[56,11,8,2],
[56,11,8,4],
[56,11,8,7],
[56,11,10,0],
[56,11,10,1],
[56,11,10,2],
[56,11,10,3],
[56,11,10,4],
[56,11,10,5],
[56,11,10,6],
[56,11,10,7],
[56,11,10,8],
[56,12,3,0],
[56,12,3,1],
[56,12,3,2],
[56,12,4,0],
[56,12,4,1],
[56,12,4,2],
[56,12,4,3],
[56,12,5,0],
[56,12,5,1],
[56,12,5,2],
[56,12,5,4],
[56,12,6,0],
[56,12,6,1],
[56,12,6,2],
[56,12,6,4],
[56,12,7,0],
[56,12,7,1],
[56,12,7,2],
[56,12,7,3],
[56,12,7,4],
[56,12,7,5],
[56,12,7,6],
[56,12,8,0],
[56,12,8,1],
[56,12,8,2],
[56,12,8,4],
[56,12,8,7],
[56,12,10,0],
[56,12,10,1],
[56,12,10,2],
[56,12,10,3],
[56,12,10,4],
[56,12,10,5],
[56,12,10,6],
[56,12,10,7],
[56,12,10,8],
[56,13,3,0],
[56,13,3,1],
[56,13,3,2],
[56,13,4,0],
[56,13,4,1],
[56,13,4,2],
[56,13,4,3],
[56,13,5,0],
[56,13,5,1],
[56,13,5,2],
[56,13,5,4],
[56,13,6,0],
[56,13,6,1],
[56,13,6,2],
[56,13,6,4],
[56,13,7,0],
[56,13,7,1],
[56,13,7,2],
[56,13,7,3],
[56,13,7,4],
[56,13,7,5],
[56,13,7,6],
[56,13,8,0],
[56,13,8,1],
[56,13,8,2],
[56,13,8,4],
[56,13,8,7],
[56,13,10,0],
[56,13,10,1],
[56,13,10,2],
[56,13,10,3],
[56,13,10,4],
[56,13,10,5],
[56,13,10,6],
[56,13,10,7],
[56,13,10,8],
[56,13,11,0],
[56,13,11,1],
[56,13,11,2],
[56,13,11,3],
[56,13,11,4],
[56,13,11,5],
[56,13,11,6],
[56,13,11,7],
[56,13,11,8],
[56,13,11,10],
[56,13,12,0],
[56,13,12,1],
[56,13,12,2],
[56,13,12,3],
[56,13,12,4],
[56,13,12,5],
[56,13,12,6],
[56,13,12,7],
[56,13,12,8],
[56,13,12,10],
[56,14,3,0],
[56,14,3,1],
[56,14,3,2],
[56,14,4,0],
[56,14,4,1],
[56,14,4,2],
[56,14,4,3],
[56,14,5,0],
[56,14,5,1],
[56,14,5,2],
[56,14,5,4],
[56,14,6,0],
[56,14,6,1],
[56,14,6,2],
[56,14,6,4],
[56,14,7,0],
[56,14,7,1],
[56,14,7,2],
[56,14,7,3],
[56,14,7,4],
[56,14,7,5],
[56,14,7,6],
[56,14,8,0],
[56,14,8,1],
[56,14,8,2],
[56,14,8,4],
[56,14,8,7],
[56,14,10,0],
[56,14,10,1],
[56,14,10,2],
[56,14,10,3],
[56,14,10,4],
[56,14,10,5],
[56,14,10,6],
[56,14,10,7],
[56,14,10,8],
[56,14,11,0],
[56,14,11,1],
[56,14,11,2],
[56,14,11,3],
[56,14,11,4],
[56,14,11,5],
[56,14,11,6],
[56,14,11,7],
[56,14,11,8],
[56,14,11,10],
[56,14,12,0],
[56,14,12,1],
[56,14,12,2],
[56,14,12,3],
[56,14,12,4],
[56,14,12,5],
[56,14,12,6],
[56,14,12,7],
[56,14,12,8],
[56,14,12,10],
[56,16,3,0],
[56,16,3,1],
[56,16,3,2],
[56,16,4,0],
[56,16,4,1],
[56,16,4,2],
[56,16,4,3],
[56,16,5,0],
[56,16,5,1],
[56,16,5,2],
[56,16,5,4],
[56,16,6,0],
[56,16,6,1],
[56,16,6,2],
[56,16,6,4],
[56,16,7,0],
[56,16,7,1],
[56,16,7,2],
[56,16,7,3],
[56,16,7,4],
[56,16,7,5],
[56,16,7,6],
[56,16,8,0],
[56,16,8,1],
[56,16,8,2],
[56,16,8,4],
[56,16,8,7],
[56,16,10,0],
[56,16,10,1],
[56,16,10,2],
[56,16,10,3],
[56,16,10,4],
[56,16,10,5],
[56,16,10,6],
[56,16,10,7],
[56,16,10,8],
[56,16,11,0],
[56,16,11,1],
[56,16,11,2],
[56,16,11,3],
[56,16,11,4],
[56,16,11,5],
[56,16,11,6],
[56,16,11,7],
[56,16,11,8],
[56,16,11,10],
[56,16,12,0],
[56,16,12,1],
[56,16,12,2],
[56,16,12,3],
[56,16,12,4],
[56,16,12,5],
[56,16,12,6],
[56,16,12,7],
[56,16,12,8],
[56,16,12,10],
[56,16,13,0],
[56,16,13,1],
[56,16,13,2],
[56,16,13,3],
[56,16,13,4],
[56,16,13,5],
[56,16,13,6],
[56,16,13,7],
[56,16,13,8],
[56,16,13,10],
[56,16,13,11],
[56,16,13,12],
[56,16,14,0],
[56,16,14,1],
[56,16,14,2],
[56,16,14,3],
[56,16,14,4],
[56,16,14,5],
[56,16,14,6],
[56,16,14,7],
[56,16,14,8],
[56,16,14,10],
[56,16,14,11],
[56,16,14,12],
[56,17,3,0],
[56,17,3,1],
[56,17,3,2],
[56,17,4,0],
[56,17,4,1],
[56,17,4,2],
[56,17,4,3],
[56,17,5,0],
[56,17,5,1],
[56,17,5,2],
[56,17,5,4],
[56,17,6,0],
[56,17,6,1],
[56,17,6,2],
[56,17,6,4],
[56,17,7,0],
[56,17,7,1],
[56,17,7,2],
[56,17,7,3],
[56,17,7,4],
[56,17,7,5],
[56,17,7,6],
[56,17,8,0],
[56,17,8,1],
[56,17,8,2],
[56,17,8,4],
[56,17,8,7],
[56,17,10,0],
[56,17,10,1],
[56,17,10,2],
[56,17,10,3],
[56,17,10,4],
[56,17,10,5],
[56,17,10,6],
[56,17,10,7],
[56,17,10,8],
[56,17,11,0],
[56,17,11,1],
[56,17,11,2],
[56,17,11,3],
[56,17,11,4],
[56,17,11,5],
[56,17,11,6],
[56,17,11,7],
[56,17,11,8],
[56,17,11,10],
[56,17,12,0],
[56,17,12,1],
[56,17,12,2],
[56,17,12,3],
[56,17,12,4],
[56,17,12,5],
[56,17,12,6],
[56,17,12,7],
[56,17,12,8],
[56,17,12,10],
[56,17,13,0],
[56,17,13,1],
[56,17,13,2],
[56,17,13,3],
[56,17,13,4],
[56,17,13,5],
[56,17,13,6],
[56,17,13,7],
[56,17,13,8],
[56,17,13,10],
[56,17,13,11],
[56,17,13,12],
[56,17,14,0],
[56,17,14,1],
[56,17,14,2],
[56,17,14,3],
[56,17,14,4],
[56,17,14,5],
[56,17,14,6],
[56,17,14,7],
[56,17,14,8],
[56,17,14,10],
[56,17,14,11],
[56,17,14,12],
[56,17,16,0],
[56,17,16,1],
[56,17,16,2],
[56,17,16,3],
[56,17,16,4],
[56,17,16,5],
[56,17,16,6],
[56,17,16,7],
[56,17,16,8],
[56,17,16,10],
[56,17,16,11],
[56,17,16,12],
[56,17,16,13],
[56,17,16,14],
[56,19,3,0],
[56,19,3,1],
[56,19,3,2],
[56,19,4,0],
[56,19,4,1],
[56,19,4,2],
[56,19,4,3],
[56,19,5,0],
[56,19,5,1],
[56,19,5,2],
[56,19,5,4],
[56,19,6,0],
[56,19,6,1],
[56,19,6,2],
[56,19,6,4],
[56,19,7,0],
[56,19,7,1],
[56,19,7,2],
[56,19,7,3],
[56,19,7,4],
[56,19,7,5],
[56,19,7,6],
[56,19,8,0],
[56,19,8,1],
[56,19,8,2],
[56,19,8,4],
[56,19,8,7],
[56,19,10,0],
[56,19,10,1],
[56,19,10,2],
[56,19,10,3],
[56,19,10,4],
[56,19,10,5],
[56,19,10,6],
[56,19,10,7],
[56,19,10,8],
[56,19,11,0],
[56,19,11,1],
[56,19,11,2],
[56,19,11,3],
[56,19,11,4],
[56,19,11,5],
[56,19,11,6],
[56,19,11,7],
[56,19,11,8],
[56,19,11,10],
[56,19,12,0],
[56,19,12,1],
[56,19,12,2],
[56,19,12,3],
[56,19,12,4],
[56,19,12,5],
[56,19,12,6],
[56,19,12,7],
[56,19,12,8],
[56,19,12,10],
[56,19,16,0],
[56,19,16,1],
[56,19,16,2],
[56,19,16,3],
[56,19,16,4],
[56,19,16,5],
[56,19,16,6],
[56,19,16,7],
[56,19,16,8],
[56,19,16,10],
[56,19,16,11],
[56,19,16,12],
[56,19,17,0],
[56,19,17,1],
[56,19,17,2],
[56,19,17,3],
[56,19,17,4],
[56,19,17,5],
[56,19,17,6],
[56,19,17,7],
[56,19,17,8],
[56,19,17,10],
[56,19,17,11],
[56,19,17,12],
[56,19,17,16],
[56,20,3,0],
[56,20,3,1],
[56,20,3,2],
[56,20,4,0],
[56,20,4,1],
[56,20,4,2],
[56,20,4,3],
[56,20,5,0],
[56,20,5,1],
[56,20,5,2],
[56,20,5,4],
[56,20,6,0],
[56,20,6,1],
[56,20,6,2],
[56,20,6,4],
[56,20,7,0],
[56,20,7,1],
[56,20,7,2],
[56,20,7,3],
[56,20,7,4],
[56,20,7,5],
[56,20,7,6],
[56,20,8,0],
[56,20,8,1],
[56,20,8,2],
[56,20,8,4],
[56,20,8,7],
[56,20,11,0],
[56,20,11,1],
[56,20,11,2],
[56,20,11,3],
[56,20,11,4],
[56,20,11,5],
[56,20,11,6],
[56,20,11,7],
[56,20,11,8],
[56,20,12,0],
[56,20,12,1],
[56,20,12,2],
[56,20,12,3],
[56,20,12,4],
[56,20,12,5],
[56,20,12,6],
[56,20,12,7],
[56,20,12,8],
[56,20,13,0],
[56,20,13,1],
[56,20,13,2],
[56,20,13,3],
[56,20,13,4],
[56,20,13,5],
[56,20,13,6],
[56,20,13,7],
[56,20,13,8],
[56,20,13,11],
[56,20,13,12],
[56,20,14,0],
[56,20,14,1],
[56,20,14,2],
[56,20,14,3],
[56,20,14,4],
[56,20,14,5],
[56,20,14,6],
[56,20,14,7],
[56,20,14,8],
[56,20,14,11],
[56,20,14,12],
[56,20,16,0],
[56,20,16,1],
[56,20,16,2],
[56,20,16,3],
[56,20,16,4],
[56,20,16,5],
[56,20,16,6],
[56,20,16,7],
[56,20,16,8],
[56,20,16,11],
[56,20,16,12],
[56,20,16,13],
[56,20,16,14],
[56,20,17,0],
[56,20,17,1],
[56,20,17,2],
[56,20,17,3],
[56,20,17,4],
[56,20,17,5],
[56,20,17,6],
[56,20,17,7],
[56,20,17,8],
[56,20,17,11],
[56,20,17,12],
[56,20,17,13],
[56,20,17,14],
[56,20,17,16],
[56,20,19,0],
[56,20,19,1],
[56,20,19,2],
[56,20,19,3],
[56,20,19,4],
[56,20,19,5],
[56,20,19,6],
[56,20,19,7],
[56,20,19,8],
[56,20,19,11],
[56,20,19,12],
[56,20,19,16],
[56,20,19,17],
[56,21,4,0],
[56,21,4,1],
[56,21,4,2],
[56,21,7,0],
[56,21,7,1],
[56,21,7,2],
[56,21,7,4],
[56,21,10,0],
[56,21,10,1],
[56,21,10,2],
[56,21,10,4],
[56,21,10,7],
[56,21,11,0],
[56,21,11,1],
[56,21,11,2],
[56,21,11,4],
[56,21,11,7],
[56,21,11,10],
[56,21,12,0],
[56,21,12,1],
[56,21,12,2],
[56,21,12,4],
[56,21,12,7],
[56,21,12,10],
[56,21,13,0],
[56,21,13,1],
[56,21,13,2],
[56,21,13,4],
[56,21,13,7],
[56,21,13,10],
[56,21,13,11],
[56,21,13,12],
[56,21,14,0],
[56,21,14,1],
[56,21,14,2],
[56,21,14,4],
[56,21,14,7],
[56,21,14,10],
[56,21,14,11],
[56,21,14,12],
[56,21,16,0],
[56,21,16,1],
[56,21,16,2],
[56,21,16,4],
[56,21,16,7],
[56,21,16,10],
[56,21,16,11],
[56,21,16,12],
[56,21,16,13],
[56,21,16,14],
[56,21,17,0],
[56,21,17,1],
[56,21,17,2],
[56,21,17,4],
[56,21,17,7],
[56,21,17,10],
[56,21,17,11],
[56,21,17,12],
[56,21,17,13],
[56,21,17,14],
[56,21,17,16],
[56,21,19,0],
[56,21,19,1],
[56,21,19,2],
[56,21,19,4],
[56,21,19,7],
[56,21,19,10],
[56,21,19,11],
[56,21,19,12],
[56,21,19,16],
[56,21,19,17],
[56,21,20,0],
[56,21,20,1],
[56,21,20,2],
[56,21,20,4],
[56,21,20,7],
[56,21,20,11],
[56,21,20,12],
[56,21,20,13],
[56,21,20,14],
[56,21,20,16],
[56,21,20,17],
[56,21,20,19],
[56,22,3,0],
[56,22,3,1],
[56,22,3,2],
[56,22,4,0],
[56,22,4,1],
[56,22,4,2],
[56,22,4,3],
[56,22,5,0],
[56,22,5,1],
[56,22,5,2],
[56,22,5,4],
[56,22,6,0],
[56,22,6,1],
[56,22,6,2],
[56,22,6,4],
[56,22,7,0],
[56,22,7,1],
[56,22,7,2],
[56,22,7,3],
[56,22,7,4],
[56,22,7,5],
[56,22,7,6],
[56,22,8,0],
[56,22,8,1],
[56,22,8,2],
[56,22,8,4],
[56,22,8,7],
[56,22,11,0],
[56,22,11,1],
[56,22,11,2],
[56,22,11,3],
[56,22,11,4],
[56,22,11,5],
[56,22,11,6],
[56,22,11,7],
[56,22,11,8],
[56,22,12,0],
[56,22,12,1],
[56,22,12,2],
[56,22,12,3],
[56,22,12,4],
[56,22,12,5],
[56,22,12,6],
[56,22,12,7],
[56,22,12,8],
[56,22,13,0],
[56,22,13,1],
[56,22,13,2],
[56,22,13,3],
[56,22,13,4],
[56,22,13,5],
[56,22,13,6],
[56,22,13,7],
[56,22,13,8],
[56,22,13,11],
[56,22,13,12],
[56,22,14,0],
[56,22,14,1],
[56,22,14,2],
[56,22,14,3],
[56,22,14,4],
[56,22,14,5],
[56,22,14,6],
[56,22,14,7],
[56,22,14,8],
[56,22,14,11],
[56,22,14,12],
[56,22,16,0],
[56,22,16,1],
[56,22,16,2],
[56,22,16,3],
[56,22,16,4],
[56,22,16,5],
[56,22,16,6],
[56,22,16,7],
[56,22,16,8],
[56,22,16,11],
[56,22,16,12],
[56,22,16,13],
[56,22,16,14],
[56,22,17,0],
[56,22,17,1],
[56,22,17,2],
[56,22,17,3],
[56,22,17,4],
[56,22,17,5],
[56,22,17,6],
[56,22,17,7],
[56,22,17,8],
[56,22,17,11],
[56,22,17,12],
[56,22,17,13],
[56,22,17,14],
[56,22,17,16],
[56,22,19,0],
[56,22,19,1],
[56,22,19,2],
[56,22,19,3],
[56,22,19,4],
[56,22,19,5],
[56,22,19,6],
[56,22,19,7],
[56,22,19,8],
[56,22,19,11],
[56,22,19,12],
[56,22,19,16],
[56,22,19,17],
[56,22,21,0],
[56,22,21,1],
[56,22,21,2],
[56,22,21,4],
[56,22,21,7],
[56,22,21,11],
[56,22,21,12],
[56,22,21,13],
[56,22,21,14],
[56,22,21,16],
[56,22,21,17],
[56,22,21,19],
[56,23,3,0],
[56,23,3,1],
[56,23,3,2],
[56,23,4,0],
[56,23,4,1],
[56,23,4,2],
[56,23,4,3],
[56,23,5,0],
[56,23,5,1],
[56,23,5,2],
[56,23,5,4],
[56,23,6,0],
[56,23,6,1],
[56,23,6,2],
[56,23,6,4],
[56,23,7,0],
[56,23,7,1],
[56,23,7,2],
[56,23,7,3],
[56,23,7,4],
[56,23,7,5],
[56,23,7,6],
[56,23,8,0],
[56,23,8,1],
[56,23,8,2],
[56,23,8,4],
[56,23,8,7],
[56,23,10,0],
[56,23,10,1],
[56,23,10,2],
[56,23,10,3],
[56,23,10,4],
[56,23,10,5],
[56,23,10,6],
[56,23,10,7],
[56,23,10,8],
[56,23,11,0],
[56,23,11,1],
[56,23,11,2],
[56,23,11,3],
[56,23,11,4],
[56,23,11,5],
[56,23,11,6],
[56,23,11,7],
[56,23,11,8],
[56,23,11,10],
[56,23,12,0],
[56,23,12,1],
[56,23,12,2],
[56,23,12,3],
[56,23,12,4],
[56,23,12,5],
[56,23,12,6],
[56,23,12,7],
[56,23,12,8],
[56,23,12,10],
[56,23,13,0],
[56,23,13,1],
[56,23,13,2],
[56,23,13,3],
[56,23,13,4],
[56,23,13,5],
[56,23,13,6],
[56,23,13,7],
[56,23,13,8],
[56,23,13,10],
[56,23,13,11],
[56,23,13,12],
[56,23,14,0],
[56,23,14,1],
[56,23,14,2],
[56,23,14,3],
[56,23,14,4],
[56,23,14,5],
[56,23,14,6],
[56,23,14,7],
[56,23,14,8],
[56,23,14,10],
[56,23,14,11],
[56,23,14,12],
[56,23,16,0],
[56,23,16,1],
[56,23,16,2],
[56,23,16,3],
[56,23,16,4],
[56,23,16,5],
[56,23,16,6],
[56,23,16,7],
[56,23,16,8],
[56,23,16,10],
[56,23,16,11],
[56,23,16,12],
[56,23,16,13],
[56,23,16,14],
[56,23,17,0],
[56,23,17,1],
[56,23,17,2],
[56,23,17,3],
[56,23,17,4],
[56,23,17,5],
[56,23,17,6],
[56,23,17,7],
[56,23,17,8],
[56,23,17,10],
[56,23,17,11],
[56,23,17,12],
[56,23,17,13],
[56,23,17,14],
[56,23,17,16],
[56,23,19,0],
[56,23,19,1],
[56,23,19,2],
[56,23,19,3],
[56,23,19,4],
[56,23,19,5],
[56,23,19,6],
[56,23,19,7],
[56,23,19,8],
[56,23,19,10],
[56,23,19,11],
[56,23,19,12],
[56,23,19,16],
[56,23,19,17],
[56,23,20,0],
[56,23,20,1],
[56,23,20,2],
[56,23,20,3],
[56,23,20,4],
[56,23,20,5],
[56,23,20,6],
[56,23,20,7],
[56,23,20,8],
[56,23,20,11],
[56,23,20,12],
[56,23,20,13],
[56,23,20,14],
[56,23,20,16],
[56,23,20,17],
[56,23,20,19],
[56,23,21,0],
[56,23,21,1],
[56,23,21,2],
[56,23,21,4],
[56,23,21,7],
[56,23,21,10],
[56,23,21,11],
[56,23,21,12],
[56,23,21,13],
[56,23,21,14],
[56,23,21,16],
[56,23,21,17],
[56,23,21,19],
[56,23,21,20],
[56,23,22,0],
[56,23,22,1],
[56,23,22,2],
[56,23,22,3],
[56,23,22,4],
[56,23,22,5],
[56,23,22,6],
[56,23,22,7],
[56,23,22,8],
[56,23,22,11],
[56,23,22,12],
[56,23,22,13],
[56,23,22,14],
[56,23,22,16],
[56,23,22,17],
[56,23,22,19],
[56,23,22,21],
[56,24,3,0],
[56,24,3,1],
[56,24,3,2],
[56,24,4,0],
[56,24,4,1],
[56,24,4,2],
[56,24,4,3],
[56,24,5,0],
[56,24,5,1],
[56,24,5,2],
[56,24,5,4],
[56,24,6,0],
[56,24,6,1],
[56,24,6,2],
[56,24,6,4],
[56,24,7,0],
[56,24,7,1],
[56,24,7,2],
[56,24,7,3],
[56,24,7,4],
[56,24,7,5],
[56,24,7,6],
[56,24,8,0],
[56,24,8,1],
[56,24,8,2],
[56,24,8,4],
[56,24,8,7],
[56,24,10,0],
[56,24,10,1],
[56,24,10,2],
[56,24,10,3],
[56,24,10,4],
[56,24,10,5],
[56,24,10,6],
[56,24,10,7],
[56,24,10,8],
[56,24,11,0],
[56,24,11,1],
[56,24,11,2],
[56,24,11,3],
[56,24,11,4],
[56,24,11,5],
[56,24,11,6],
[56,24,11,7],
[56,24,11,8],
[56,24,11,10],
[56,24,12,0],
[56,24,12,1],
[56,24,12,2],
[56,24,12,3],
[56,24,12,4],
[56,24,12,5],
[56,24,12,6],
[56,24,12,7],
[56,24,12,8],
[56,24,12,10],
[56,24,13,0],
[56,24,13,1],
[56,24,13,2],
[56,24,13,3],
[56,24,13,4],
[56,24,13,5],
[56,24,13,6],
[56,24,13,7],
[56,24,13,8],
[56,24,13,10],
[56,24,13,11],
[56,24,13,12],
[56,24,14,0],
[56,24,14,1],
[56,24,14,2],
[56,24,14,3],
[56,24,14,4],
[56,24,14,5],
[56,24,14,6],
[56,24,14,7],
[56,24,14,8],
[56,24,14,10],
[56,24,14,11],
[56,24,14,12],
[56,24,17,0],
[56,24,17,1],
[56,24,17,2],
[56,24,17,3],
[56,24,17,4],
[56,24,17,5],
[56,24,17,6],
[56,24,17,7],
[56,24,17,8],
[56,24,17,10],
[56,24,17,11],
[56,24,17,12],
[56,24,17,13],
[56,24,17,14],
[56,24,19,0],
[56,24,19,1],
[56,24,19,2],
[56,24,19,3],
[56,24,19,4],
[56,24,19,5],
[56,24,19,6],
[56,24,19,7],
[56,24,19,8],
[56,24,19,10],
[56,24,19,11],
[56,24,19,12],
[56,24,19,17],
[56,24,20,0],
[56,24,20,1],
[56,24,20,2],
[56,24,20,3],
[56,24,20,4],
[56,24,20,5],
[56,24,20,6],
[56,24,20,7],
[56,24,20,8],
[56,24,20,11],
[56,24,20,12],
[56,24,20,13],
[56,24,20,14],
[56,24,20,17],
[56,24,20,19],
[56,24,21,0],
[56,24,21,1],
[56,24,21,2],
[56,24,21,4],
[56,24,21,7],
[56,24,21,10],
[56,24,21,11],
[56,24,21,12],
[56,24,21,13],
[56,24,21,14],
[56,24,21,17],
[56,24,21,19],
[56,24,21,20],
[56,24,22,0],
[56,24,22,1],
[56,24,22,2],
[56,24,22,3],
[56,24,22,4],
[56,24,22,5],
[56,24,22,6],
[56,24,22,7],
[56,24,22,8],
[56,24,22,11],
[56,24,22,12],
[56,24,22,13],
[56,24,22,14],
[56,24,22,17],
[56,24,22,19],
[56,24,22,21],
[56,24,23,0],
[56,24,23,1],
[56,24,23,2],
[56,24,23,3],
[56,24,23,4],
[56,24,23,5],
[56,24,23,6],
[56,24,23,7],
[56,24,23,8],
[56,24,23,10],
[56,24,23,11],
[56,24,23,12],
[56,24,23,13],
[56,24,23,14],
[56,24,23,17],
[56,24,23,19],
[56,24,23,20],
[56,24,23,21],
[56,24,23,22],
[56,25,3,0],
[56,25,3,1],
[56,25,3,2],
[56,25,4,0],
[56,25,4,1],
[56,25,4,2],
[56,25,4,3],
[56,25,5,0],
[56,25,5,1],
[56,25,5,2],
[56,25,5,4],
[56,25,6,0],
[56,25,6,1],
[56,25,6,2],
[56,25,6,4],
[56,25,8,0],
[56,25,8,1],
[56,25,8,2],
[56,25,8,4],
[56,25,10,0],
[56,25,10,1],
[56,25,10,2],
[56,25,10,3],
[56,25,10,4],
[56,25,10,5],
[56,25,10,6],
[56,25,10,8],
[56,25,11,0],
[56,25,11,1],
[56,25,11,2],
[56,25,11,3],
[56,25,11,4],
[56,25,11,5],
[56,25,11,6],
[56,25,11,8],
[56,25,11,10],
[56,25,12,0],
[56,25,12,1],
[56,25,12,2],
[56,25,12,3],
[56,25,12,4],
[56,25,12,5],
[56,25,12,6],
[56,25,12,8],
[56,25,12,10],
[56,25,13,0],
[56,25,13,1],
[56,25,13,2],
[56,25,13,3],
[56,25,13,4],
[56,25,13,5],
[56,25,13,6],
[56,25,13,8],
[56,25,13,10],
[56,25,13,11],
[56,25,13,12],
[56,25,14,0],
[56,25,14,1],
[56,25,14,2],
[56,25,14,3],
[56,25,14,4],
[56,25,14,5],
[56,25,14,6],
[56,25,14,8],
[56,25,14,10],
[56,25,14,11],
[56,25,14,12],
[56,25,16,0],
[56,25,16,1],
[56,25,16,2],
[56,25,16,3],
[56,25,16,4],
[56,25,16,5],
[56,25,16,6],
[56,25,16,8],
[56,25,16,10],
[56,25,16,11],
[56,25,16,12],
[56,25,16,13],
[56,25,16,14],
[56,25,17,0],
[56,25,17,1],
[56,25,17,2],
[56,25,17,3],
[56,25,17,4],
[56,25,17,5],
[56,25,17,6],
[56,25,17,8],
[56,25,17,10],
[56,25,17,11],
[56,25,17,12],
[56,25,17,13],
[56,25,17,14],
[56,25,17,16],
[56,25,19,0],
[56,25,19,1],
[56,25,19,2],
[56,25,19,3],
[56,25,19,4],
[56,25,19,5],
[56,25,19,6],
[56,25,19,8],
[56,25,19,10],
[56,25,19,11],
[56,25,19,12],
[56,25,19,16],
[56,25,19,17],
[56,25,20,0],
[56,25,20,1],
[56,25,20,2],
[56,25,20,3],
[56,25,20,4],
[56,25,20,5],
[56,25,20,6],
[56,25,20,8],
[56,25,20,11],
[56,25,20,12],
[56,25,20,13],
[56,25,20,14],
[56,25,20,16],
[56,25,20,17],
[56,25,20,19],
[56,25,21,0],
[56,25,21,1],
[56,25,21,2],
[56,25,21,4],
[56,25,21,10],
[56,25,21,11],
[56,25,21,12],
[56,25,21,13],
[56,25,21,14],
[56,25,21,16],
[56,25,21,17],
[56,25,21,19],
[56,25,21,20],
[56,25,22,0],
[56,25,22,1],
[56,25,22,2],
[56,25,22,3],
[56,25,22,4],
[56,25,22,5],
[56,25,22,6],
[56,25,22,8],
[56,25,22,11],
[56,25,22,12],
[56,25,22,13],
[56,25,22,14],
[56,25,22,16],
[56,25,22,17],
[56,25,22,19],
[56,25,22,21],
[56,25,23,0],
[56,25,23,1],
[56,25,23,2],
[56,25,23,3],
[56,25,23,4],
[56,25,23,5],
[56,25,23,6],
[56,25,23,8],
[56,25,23,10],
[56,25,23,11],
[56,25,23,12],
[56,25,23,13],
[56,25,23,14],
[56,25,23,16],
[56,25,23,17],
[56,25,23,19],
[56,25,23,20],
[56,25,23,21],
[56,25,23,22],
[56,25,24,0],
[56,25,24,1],
[56,25,24,2],
[56,25,24,3],
[56,25,24,4],
[56,25,24,5],
[56,25,24,6],
[56,25,24,8],
[56,25,24,10],
[56,25,24,11],
[56,25,24,12],
[56,25,24,13],
[56,25,24,14],
[56,25,24,17],
[56,25,24,19],
[56,25,24,20],
[56,25,24,21],
[56,25,24,22],
[56,25,24,23],
[56,27,3,0],
[56,27,3,1],
[56,27,3,2],
[56,27,4,0],
[56,27,4,1],
[56,27,4,2],
[56,27,4,3],
[56,27,5,0],
[56,27,5,1],
[56,27,5,2],
[56,27,5,4],
[56,27,6,0],
[56,27,6,1],
[56,27,6,2],
[56,27,6,4],
[56,27,7,0],
[56,27,7,1],
[56,27,7,2],
[56,27,7,3],
[56,27,7,4],
[56,27,7,5],
[56,27,7,6],
[56,27,8,0],
[56,27,8,1],
[56,27,8,2],
[56,27,8,4],
[56,27,8,7],
[56,27,11,0],
[56,27,11,1],
[56,27,11,2],
[56,27,11,3],
[56,27,11,4],
[56,27,11,5],
[56,27,11,6],
[56,27,11,7],
[56,27,11,8],
[56,27,12,0],
[56,27,12,1],
[56,27,12,2],
[56,27,12,3],
[56,27,12,4],
[56,27,12,5],
[56,27,12,6],
[56,27,12,7],
[56,27,12,8],
[56,27,13,0],
[56,27,13,1],
[56,27,13,2],
[56,27,13,3],
[56,27,13,4],
[56,27,13,5],
[56,27,13,6],
[56,27,13,7],
[56,27,13,8],
[56,27,13,11],
[56,27,13,12],
[56,27,14,0],
[56,27,14,1],
[56,27,14,2],
[56,27,14,3],
[56,27,14,4],
[56,27,14,5],
[56,27,14,6],
[56,27,14,7],
[56,27,14,8],
[56,27,14,11],
[56,27,14,12],
[56,27,16,0],
[56,27,16,1],
[56,27,16,2],
[56,27,16,3],
[56,27,16,4],
[56,27,16,5],
[56,27,16,6],
[56,27,16,7],
[56,27,16,8],
[56,27,16,11],
[56,27,16,12],
[56,27,16,13],
[56,27,16,14],
[56,27,17,0],
[56,27,17,1],
[56,27,17,2],
[56,27,17,3],
[56,27,17,4],
[56,27,17,5],
[56,27,17,6],
[56,27,17,7],
[56,27,17,8],
[56,27,17,11],
[56,27,17,12],
[56,27,17,13],
[56,27,17,14],
[56,27,17,16],
[56,27,19,0],
[56,27,19,1],
[56,27,19,2],
[56,27,19,3],
[56,27,19,4],
[56,27,19,5],
[56,27,19,6],
[56,27,19,7],
[56,27,19,8],
[56,27,19,11],
[56,27,19,12],
[56,27,19,16],
[56,27,19,17],
[56,27,21,0],
[56,27,21,1],
[56,27,21,2],
[56,27,21,4],
[56,27,21,7],
[56,27,21,11],
[56,27,21,12],
[56,27,21,13],
[56,27,21,14],
[56,27,21,16],
[56,27,21,17],
[56,27,21,19],
[56,27,23,0],
[56,27,23,1],
[56,27,23,2],
[56,27,23,3],
[56,27,23,4],
[56,27,23,5],
[56,27,23,6],
[56,27,23,7],
[56,27,23,8],
[56,27,23,11],
[56,27,23,12],
[56,27,23,13],
[56,27,23,14],
[56,27,23,16],
[56,27,23,17],
[56,27,23,19],
[56,27,23,21],
[56,27,24,0],
[56,27,24,1],
[56,27,24,2],
[56,27,24,3],
[56,27,24,4],
[56,27,24,5],
[56,27,24,6],
[56,27,24,7],
[56,27,24,8],
[56,27,24,11],
[56,27,24,12],
[56,27,24,13],
[56,27,24,14],
[56,27,24,17],
[56,27,24,19],
[56,27,24,21],
[56,27,24,23],
[56,27,25,0],
[56,27,25,1],
[56,27,25,2],
[56,27,25,3],
[56,27,25,4],
[56,27,25,5],
[56,27,25,6],
[56,27,25,8],
[56,27,25,11],
[56,27,25,12],
[56,27,25,13],
[56,27,25,14],
[56,27,25,16],
[56,27,25,17],
[56,27,25,19],
[56,27,25,21],
[56,27,25,23],
[56,27,25,24],
[56,28,3,0],
[56,28,3,1],
[56,28,3,2],
[56,28,4,0],
[56,28,4,1],
[56,28,4,2],
[56,28,4,3],
[56,28,5,0],
[56,28,5,1],
[56,28,5,2],
[56,28,5,4],
[56,28,6,0],
[56,28,6,1],
[56,28,6,2],
[56,28,6,4],
[56,28,8,0],
[56,28,8,1],
[56,28,8,2],
[56,28,8,4],
[56,28,10,0],
[56,28,10,1],
[56,28,10,2],
[56,28,10,3],
[56,28,10,4],
[56,28,10,5],
[56,28,10,6],
[56,28,10,8],
[56,28,11,0],
[56,28,11,1],
[56,28,11,2],
[56,28,11,3],
[56,28,11,4],
[56,28,11,5],
[56,28,11,6],
[56,28,11,8],
[56,28,11,10],
[56,28,12,0],
[56,28,12,1],
[56,28,12,2],
[56,28,12,3],
[56,28,12,4],
[56,28,12,5],
[56,28,12,6],
[56,28,12,8],
[56,28,12,10],
[56,28,13,0],
[56,28,13,1],
[56,28,13,2],
[56,28,13,3],
[56,28,13,4],
[56,28,13,5],
[56,28,13,6],
[56,28,13,8],
[56,28,13,10],
[56,28,13,11],
[56,28,13,12],
[56,28,14,0],
[56,28,14,1],
[56,28,14,2],
[56,28,14,3],
[56,28,14,4],
[56,28,14,5],
[56,28,14,6],
[56,28,14,8],
[56,28,14,10],
[56,28,14,11],
[56,28,14,12],
[56,28,16,0],
[56,28,16,1],
[56,28,16,2],
[56,28,16,3],
[56,28,16,4],
[56,28,16,5],
[56,28,16,6],
[56,28,16,8],
[56,28,16,10],
[56,28,16,11],
[56,28,16,12],
[56,28,16,13],
[56,28,16,14],
[56,28,17,0],
[56,28,17,1],
[56,28,17,2],
[56,28,17,3],
[56,28,17,4],
[56,28,17,5],
[56,28,17,6],
[56,28,17,8],
[56,28,17,10],
[56,28,17,11],
[56,28,17,12],
[56,28,17,13],
[56,28,17,14],
[56,28,17,16],
[56,28,19,0],
[56,28,19,1],
[56,28,19,2],
[56,28,19,3],
[56,28,19,4],
[56,28,19,5],
[56,28,19,6],
[56,28,19,8],
[56,28,19,10],
[56,28,19,11],
[56,28,19,12],
[56,28,19,16],
[56,28,19,17],
[56,28,20,0],
[56,28,20,1],
[56,28,20,2],
[56,28,20,3],
[56,28,20,4],
[56,28,20,5],
[56,28,20,6],
[56,28,20,8],
[56,28,20,11],
[56,28,20,12],
[56,28,20,13],
[56,28,20,14],
[56,28,20,16],
[56,28,20,17],
[56,28,20,19],
[56,28,21,0],
[56,28,21,1],
[56,28,21,2],
[56,28,21,4],
[56,28,21,10],
[56,28,21,11],
[56,28,21,12],
[56,28,21,13],
[56,28,21,14],
[56,28,21,16],
[56,28,21,17],
[56,28,21,19],
[56,28,21,20],
[56,28,22,0],
[56,28,22,1],
[56,28,22,2],
[56,28,22,3],
[56,28,22,4],
[56,28,22,5],
[56,28,22,6],
[56,28,22,8],
[56,28,22,11],
[56,28,22,12],
[56,28,22,13],
[56,28,22,14],
[56,28,22,16],
[56,28,22,17],
[56,28,22,19],
[56,28,22,21],
[56,28,23,0],
[56,28,23,1],
[56,28,23,2],
[56,28,23,3],
[56,28,23,4],
[56,28,23,5],
[56,28,23,6],
[56,28,23,8],
[56,28,23,10],
[56,28,23,11],
[56,28,23,12],
[56,28,23,13],
[56,28,23,14],
[56,28,23,16],
[56,28,23,17],
[56,28,23,19],
[56,28,23,20],
[56,28,23,21],
[56,28,23,22],
[56,28,24,0],
[56,28,24,1],
[56,28,24,2],
[56,28,24,3],
[56,28,24,4],
[56,28,24,5],
[56,28,24,6],
[56,28,24,8],
[56,28,24,10],
[56,28,24,11],
[56,28,24,12],
[56,28,24,13],
[56,28,24,14],
[56,28,24,17],
[56,28,24,19],
[56,28,24,20],
[56,28,24,21],
[56,28,24,22],
[56,28,24,23],
[56,28,27,0],
[56,28,27,1],
[56,28,27,2],
[56,28,27,3],
[56,28,27,4],
[56,28,27,5],
[56,28,27,6],
[56,28,27,8],
[56,28,27,11],
[56,28,27,12],
[56,28,27,13],
[56,28,27,14],
[56,28,27,16],
[56,28,27,17],
[56,28,27,19],
[56,28,27,21],
[56,28,27,23],
[56,28,27,24],
[56,29,3,0],
[56,29,3,1],
[56,29,3,2],
[56,29,4,0],
[56,29,4,1],
[56,29,4,2],
[56,29,4,3],
[56,29,5,0],
[56,29,5,1],
[56,29,5,2],
[56,29,5,4],
[56,29,6,0],
[56,29,6,1],
[56,29,6,2],
[56,29,6,4],
[56,29,7,0],
[56,29,7,1],
[56,29,7,2],
[56,29,7,3],
[56,29,7,4],
[56,29,7,5],
[56,29,7,6],
[56,29,8,0],
[56,29,8,1],
[56,29,8,2],
[56,29,8,4],
[56,29,8,7],
[56,29,10,0],
[56,29,10,1],
[56,29,10,2],
[56,29,10,3],
[56,29,10,4],
[56,29,10,5],
[56,29,10,6],
[56,29,10,7],
[56,29,10,8],
[56,29,13,0],
[56,29,13,1],
[56,29,13,2],
[56,29,13,3],
[56,29,13,4],
[56,29,13,5],
[56,29,13,6],
[56,29,13,7],
[56,29,13,8],
[56,29,13,10],
[56,29,14,0],
[56,29,14,1],
[56,29,14,2],
[56,29,14,3],
[56,29,14,4],
[56,29,14,5],
[56,29,14,6],
[56,29,14,7],
[56,29,14,8],
[56,29,14,10],
[56,29,16,0],
[56,29,16,1],
[56,29,16,2],
[56,29,16,3],
[56,29,16,4],
[56,29,16,5],
[56,29,16,6],
[56,29,16,7],
[56,29,16,8],
[56,29,16,10],
[56,29,16,13],
[56,29,16,14],
[56,29,17,0],
[56,29,17,1],
[56,29,17,2],
[56,29,17,3],
[56,29,17,4],
[56,29,17,5],
[56,29,17,6],
[56,29,17,7],
[56,29,17,8],
[56,29,17,10],
[56,29,17,13],
[56,29,17,14],
[56,29,17,16],
[56,29,19,0],
[56,29,19,1],
[56,29,19,2],
[56,29,19,3],
[56,29,19,4],
[56,29,19,5],
[56,29,19,6],
[56,29,19,7],
[56,29,19,8],
[56,29,19,10],
[56,29,19,16],
[56,29,19,17],
[56,29,20,0],
[56,29,20,1],
[56,29,20,2],
[56,29,20,3],
[56,29,20,4],
[56,29,20,5],
[56,29,20,6],
[56,29,20,7],
[56,29,20,8],
[56,29,20,13],
[56,29,20,14],
[56,29,20,16],
[56,29,20,17],
[56,29,20,19],
[56,29,21,0],
[56,29,21,1],
[56,29,21,2],
[56,29,21,4],
[56,29,21,7],
[56,29,21,10],
[56,29,21,13],
[56,29,21,14],
[56,29,21,16],
[56,29,21,17],
[56,29,21,19],
[56,29,21,20],
[56,29,22,0],
[56,29,22,1],
[56,29,22,2],
[56,29,22,3],
[56,29,22,4],
[56,29,22,5],
[56,29,22,6],
[56,29,22,7],
[56,29,22,8],
[56,29,22,13],
[56,29,22,14],
[56,29,22,16],
[56,29,22,17],
[56,29,22,19],
[56,29,22,21],
[56,29,23,0],
[56,29,23,1],
[56,29,23,2],
[56,29,23,3],
[56,29,23,4],
[56,29,23,5],
[56,29,23,6],
[56,29,23,7],
[56,29,23,8],
[56,29,23,10],
[56,29,23,13],
[56,29,23,14],
[56,29,23,16],
[56,29,23,17],
[56,29,23,19],
[56,29,23,20],
[56,29,23,21],
[56,29,23,22],
[56,29,24,0],
[56,29,24,1],
[56,29,24,2],
[56,29,24,3],
[56,29,24,4],
[56,29,24,5],
[56,29,24,6],
[56,29,24,7],
[56,29,24,8],
[56,29,24,10],
[56,29,24,13],
[56,29,24,14],
[56,29,24,17],
[56,29,24,19],
[56,29,24,20],
[56,29,24,21],
[56,29,24,22],
[56,29,24,23],
[56,29,25,0],
[56,29,25,1],
[56,29,25,2],
[56,29,25,3],
[56,29,25,4],
[56,29,25,5],
[56,29,25,6],
[56,29,25,8],
[56,29,25,10],
[56,29,25,13],
[56,29,25,14],
[56,29,25,16],
[56,29,25,17],
[56,29,25,19],
[56,29,25,20],
[56,29,25,21],
[56,29,25,22],
[56,29,25,23],
[56,29,25,24],
[56,29,27,0],
[56,29,27,1],
[56,29,27,2],
[56,29,27,3],
[56,29,27,4],
[56,29,27,5],
[56,29,27,6],
[56,29,27,7],
[56,29,27,8],
[56,29,27,13],
[56,29,27,14],
[56,29,27,16],
[56,29,27,17],
[56,29,27,19],
[56,29,27,21],
[56,29,27,23],
[56,29,27,24],
[56,29,27,25],
[56,29,28,0],
[56,29,28,1],
[56,29,28,2],
[56,29,28,3],
[56,29,28,4],
[56,29,28,5],
[56,29,28,6],
[56,29,28,8],
[56,29,28,10],
[56,29,28,13],
[56,29,28,14],
[56,29,28,16],
[56,29,28,17],
[56,29,28,19],
[56,29,28,20],
[56,29,28,21],
[56,29,28,22],
[56,29,28,27],
[56,30,3,0],
[56,30,3,1],
[56,30,3,2],
[56,30,4,0],
[56,30,4,1],
[56,30,4,2],
[56,30,4,3],
[56,30,5,0],
[56,30,5,1],
[56,30,5,2],
[56,30,5,4],
[56,30,6,0],
[56,30,6,1],
[56,30,6,2],
[56,30,6,4],
[56,30,7,0],
[56,30,7,1],
[56,30,7,2],
[56,30,7,3],
[56,30,7,4],
[56,30,7,5],
[56,30,7,6],
[56,30,8,0],
[56,30,8,1],
[56,30,8,2],
[56,30,8,4],
[56,30,8,7],
[56,30,10,0],
[56,30,10,1],
[56,30,10,2],
[56,30,10,3],
[56,30,10,4],
[56,30,10,5],
[56,30,10,6],
[56,30,10,7],
[56,30,10,8],
[56,30,11,0],
[56,30,11,1],
[56,30,11,2],
[56,30,11,3],
[56,30,11,4],
[56,30,11,5],
[56,30,11,6],
[56,30,11,7],
[56,30,11,8],
[56,30,11,10],
[56,30,12,0],
[56,30,12,1],
[56,30,12,2],
[56,30,12,3],
[56,30,12,4],
[56,30,12,5],
[56,30,12,6],
[56,30,12,7],
[56,30,12,8],
[56,30,12,10],
[56,30,13,0],
[56,30,13,1],
[56,30,13,2],
[56,30,13,3],
[56,30,13,4],
[56,30,13,5],
[56,30,13,6],
[56,30,13,7],
[56,30,13,8],
[56,30,13,10],
[56,30,13,11],
[56,30,13,12],
[56,30,14,0],
[56,30,14,1],
[56,30,14,2],
[56,30,14,3],
[56,30,14,4],
[56,30,14,5],
[56,30,14,6],
[56,30,14,7],
[56,30,14,8],
[56,30,14,10],
[56,30,14,11],
[56,30,14,12],
[56,30,16,0],
[56,30,16,1],
[56,30,16,2],
[56,30,16,3],
[56,30,16,4],
[56,30,16,5],
[56,30,16,6],
[56,30,16,7],
[56,30,16,8],
[56,30,16,10],
[56,30,16,11],
[56,30,16,12],
[56,30,16,13],
[56,30,16,14],
[56,30,17,0],
[56,30,17,1],
[56,30,17,2],
[56,30,17,3],
[56,30,17,4],
[56,30,17,5],
[56,30,17,6],
[56,30,17,7],
[56,30,17,8],
[56,30,17,10],
[56,30,17,11],
[56,30,17,12],
[56,30,17,13],
[56,30,17,14],
[56,30,17,16],
[56,30,19,0],
[56,30,19,1],
[56,30,19,2],
[56,30,19,3],
[56,30,19,4],
[56,30,19,5],
[56,30,19,6],
[56,30,19,7],
[56,30,19,8],
[56,30,19,10],
[56,30,19,11],
[56,30,19,12],
[56,30,19,16],
[56,30,19,17],
[56,30,20,0],
[56,30,20,1],
[56,30,20,2],
[56,30,20,3],
[56,30,20,4],
[56,30,20,5],
[56,30,20,6],
[56,30,20,7],
[56,30,20,8],
[56,30,20,11],
[56,30,20,12],
[56,30,20,13],
[56,30,20,14],
[56,30,20,16],
[56,30,20,17],
[56,30,20,19],
[56,30,21,0],
[56,30,21,1],
[56,30,21,2],
[56,30,21,4],
[56,30,21,7],
[56,30,21,10],
[56,30,21,11],
[56,30,21,12],
[56,30,21,13],
[56,30,21,14],
[56,30,21,16],
[56,30,21,17],
[56,30,21,19],
[56,30,21,20],
[56,30,22,0],
[56,30,22,1],
[56,30,22,2],
[56,30,22,3],
[56,30,22,4],
[56,30,22,5],
[56,30,22,6],
[56,30,22,7],
[56,30,22,8],
[56,30,22,11],
[56,30,22,12],
[56,30,22,13],
[56,30,22,14],
[56,30,22,16],
[56,30,22,17],
[56,30,22,19],
[56,30,22,21],
[56,30,24,0],
[56,30,24,1],
[56,30,24,2],
[56,30,24,3],
[56,30,24,4],
[56,30,24,5],
[56,30,24,6],
[56,30,24,7],
[56,30,24,8],
[56,30,24,10],
[56,30,24,11],
[56,30,24,12],
[56,30,24,13],
[56,30,24,14],
[56,30,24,17],
[56,30,24,19],
[56,30,24,20],
[56,30,24,21],
[56,30,24,22],
[56,30,25,0],
[56,30,25,1],
[56,30,25,2],
[56,30,25,3],
[56,30,25,4],
[56,30,25,5],
[56,30,25,6],
[56,30,25,8],
[56,30,25,10],
[56,30,25,11],
[56,30,25,12],
[56,30,25,13],
[56,30,25,14],
[56,30,25,16],
[56,30,25,17],
[56,30,25,19],
[56,30,25,20],
[56,30,25,21],
[56,30,25,22],
[56,30,25,24],
[56,30,27,0],
[56,30,27,1],
[56,30,27,2],
[56,30,27,3],
[56,30,27,4],
[56,30,27,5],
[56,30,27,6],
[56,30,27,7],
[56,30,27,8],
[56,30,27,11],
[56,30,27,12],
[56,30,27,13],
[56,30,27,14],
[56,30,27,16],
[56,30,27,17],
[56,30,27,19],
[56,30,27,21],
[56,30,27,25],
[56,30,28,0],
[56,30,28,1],
[56,30,28,2],
[56,30,28,3],
[56,30,28,4],
[56,30,28,5],
[56,30,28,6],
[56,30,28,8],
[56,30,28,10],
[56,30,28,11],
[56,30,28,12],
[56,30,28,13],
[56,30,28,14],
[56,30,28,16],
[56,30,28,17],
[56,30,28,19],
[56,30,28,20],
[56,30,28,21],
[56,30,28,24],
[56,30,28,27],
[56,30,29,0],
[56,30,29,1],
[56,30,29,2],
[56,30,29,3],
[56,30,29,4],
[56,30,29,5],
[56,30,29,6],
[56,30,29,7],
[56,30,29,8],
[56,30,29,10],
[56,30,29,13],
[56,30,29,14],
[56,30,29,16],
[56,30,29,17],
[56,30,29,19],
[56,30,29,20],
[56,30,29,22],
[56,30,29,24],
[56,30,29,25],
[56,30,29,27],
[56,30,29,28],
[56,31,3,0],
[56,31,3,1],
[56,31,3,2],
[56,31,4,0],
[56,31,4,1],
[56,31,4,2],
[56,31,4,3],
[56,31,5,0],
[56,31,5,1],
[56,31,5,2],
[56,31,5,4],
[56,31,6,0],
[56,31,6,1],
[56,31,6,2],
[56,31,6,4],
[56,31,7,0],
[56,31,7,1],
[56,31,7,2],
[56,31,7,3],
[56,31,7,4],
[56,31,7,5],
[56,31,7,6],
[56,31,8,0],
[56,31,8,1],
[56,31,8,2],
[56,31,8,4],
[56,31,8,7],
[56,31,10,0],
[56,31,10,1],
[56,31,10,2],
[56,31,10,3],
[56,31,10,4],
[56,31,10,5],
[56,31,10,6],
[56,31,10,7],
[56,31,10,8],
[56,31,11,0],
[56,31,11,1],
[56,31,11,2],
[56,31,11,3],
[56,31,11,4],
[56,31,11,5],
[56,31,11,6],
[56,31,11,7],
[56,31,11,8],
[56,31,11,10],
[56,31,12,0],
[56,31,12,1],
[56,31,12,2],
[56,31,12,3],
[56,31,12,4],
[56,31,12,5],
[56,31,12,6],
[56,31,12,7],
[56,31,12,8],
[56,31,12,10],
[56,31,13,0],
[56,31,13,1],
[56,31,13,2],
[56,31,13,3],
[56,31,13,4],
[56,31,13,5],
[56,31,13,6],
[56,31,13,7],
[56,31,13,8],
[56,31,13,10],
[56,31,13,11],
[56,31,13,12],
[56,31,14,0],
[56,31,14,1],
[56,31,14,2],
[56,31,14,3],
[56,31,14,4],
[56,31,14,5],
[56,31,14,6],
[56,31,14,7],
[56,31,14,8],
[56,31,14,10],
[56,31,14,11],
[56,31,14,12],
[56,31,16,0],
[56,31,16,1],
[56,31,16,2],
[56,31,16,3],
[56,31,16,4],
[56,31,16,5],
[56,31,16,6],
[56,31,16,7],
[56,31,16,8],
[56,31,16,10],
[56,31,16,11],
[56,31,16,12],
[56,31,16,13],
[56,31,16,14],
[56,31,17,0],
[56,31,17,1],
[56,31,17,2],
[56,31,17,3],
[56,31,17,4],
[56,31,17,5],
[56,31,17,6],
[56,31,17,7],
[56,31,17,8],
[56,31,17,10],
[56,31,17,11],
[56,31,17,12],
[56,31,17,13],
[56,31,17,14],
[56,31,17,16],
[56,31,19,0],
[56,31,19,1],
[56,31,19,2],
[56,31,19,3],
[56,31,19,4],
[56,31,19,5],
[56,31,19,6],
[56,31,19,7],
[56,31,19,8],
[56,31,19,10],
[56,31,19,11],
[56,31,19,12],
[56,31,19,16],
[56,31,19,17],
[56,31,20,0],
[56,31,20,1],
[56,31,20,2],
[56,31,20,3],
[56,31,20,4],
[56,31,20,5],
[56,31,20,6],
[56,31,20,7],
[56,31,20,8],
[56,31,20,11],
[56,31,20,12],
[56,31,20,13],
[56,31,20,14],
[56,31,20,16],
[56,31,20,17],
[56,31,20,19],
[56,31,21,0],
[56,31,21,1],
[56,31,21,2],
[56,31,21,4],
[56,31,21,7],
[56,31,21,10],
[56,31,21,11],
[56,31,21,12],
[56,31,21,13],
[56,31,21,14],
[56,31,21,16],
[56,31,21,17],
[56,31,21,19],
[56,31,21,20],
[56,31,22,0],
[56,31,22,1],
[56,31,22,2],
[56,31,22,3],
[56,31,22,4],
[56,31,22,5],
[56,31,22,6],
[56,31,22,7],
[56,31,22,8],
[56,31,22,11],
[56,31,22,12],
[56,31,22,13],
[56,31,22,14],
[56,31,22,16],
[56,31,22,17],
[56,31,22,19],
[56,31,22,21],
[56,31,24,0],
[56,31,24,1],
[56,31,24,2],
[56,31,24,3],
[56,31,24,4],
[56,31,24,5],
[56,31,24,6],
[56,31,24,7],
[56,31,24,8],
[56,31,24,10],
[56,31,24,11],
[56,31,24,12],
[56,31,24,13],
[56,31,24,14],
[56,31,24,17],
[56,31,24,19],
[56,31,24,20],
[56,31,24,21],
[56,31,24,22],
[56,31,25,0],
[56,31,25,1],
[56,31,25,2],
[56,31,25,3],
[56,31,25,4],
[56,31,25,5],
[56,31,25,6],
[56,31,25,8],
[56,31,25,10],
[56,31,25,11],
[56,31,25,12],
[56,31,25,13],
[56,31,25,14],
[56,31,25,16],
[56,31,25,17],
[56,31,25,19],
[56,31,25,20],
[56,31,25,21],
[56,31,25,22],
[56,31,25,24],
[56,31,27,0],
[56,31,27,1],
[56,31,27,2],
[56,31,27,3],
[56,31,27,4],
[56,31,27,5],
[56,31,27,6],
[56,31,27,7],
[56,31,27,8],
[56,31,27,11],
[56,31,27,12],
[56,31,27,13],
[56,31,27,14],
[56,31,27,16],
[56,31,27,17],
[56,31,27,19],
[56,31,27,21],
[56,31,27,24],
[56,31,27,25],
[56,31,28,0],
[56,31,28,1],
[56,31,28,2],
[56,31,28,3],
[56,31,28,4],
[56,31,28,5],
[56,31,28,6],
[56,31,28,8],
[56,31,28,10],
[56,31,28,11],
[56,31,28,12],
[56,31,28,13],
[56,31,28,14],
[56,31,28,16],
[56,31,28,17],
[56,31,28,19],
[56,31,28,20],
[56,31,28,22],
[56,31,28,24],
[56,31,28,27],
[56,31,29,0],
[56,31,29,1],
[56,31,29,2],
[56,31,29,3],
[56,31,29,4],
[56,31,29,5],
[56,31,29,6],
[56,31,29,7],
[56,31,29,8],
[56,31,29,10],
[56,31,29,13],
[56,31,29,14],
[56,31,29,16],
[56,31,29,17],
[56,31,29,19],
[56,31,29,21],
[56,31,29,22],
[56,31,29,24],
[56,31,29,25],
[56,31,29,27],
[56,31,29,28],
[56,32,3,0],
[56,32,3,1],
[56,32,3,2],
[56,32,4,0],
[56,32,4,1],
[56,32,4,2],
[56,32,4,3],
[56,32,5,0],
[56,32,5,1],
[56,32,5,2],
[56,32,5,4],
[56,32,6,0],
[56,32,6,1],
[56,32,6,2],
[56,32,6,4],
[56,32,7,0],
[56,32,7,1],
[56,32,7,2],
[56,32,7,3],
[56,32,7,4],
[56,32,7,5],
[56,32,7,6],
[56,32,8,0],
[56,32,8,1],
[56,32,8,2],
[56,32,8,4],
[56,32,8,7],
[56,32,10,0],
[56,32,10,1],
[56,32,10,2],
[56,32,10,3],
[56,32,10,4],
[56,32,10,5],
[56,32,10,6],
[56,32,10,7],
[56,32,10,8],
[56,32,13,0],
[56,32,13,1],
[56,32,13,2],
[56,32,13,3],
[56,32,13,4],
[56,32,13,5],
[56,32,13,6],
[56,32,13,7],
[56,32,13,8],
[56,32,13,10],
[56,32,14,0],
[56,32,14,1],
[56,32,14,2],
[56,32,14,3],
[56,32,14,4],
[56,32,14,5],
[56,32,14,6],
[56,32,14,7],
[56,32,14,8],
[56,32,14,10],
[56,32,16,0],
[56,32,16,1],
[56,32,16,2],
[56,32,16,3],
[56,32,16,4],
[56,32,16,5],
[56,32,16,6],
[56,32,16,7],
[56,32,16,8],
[56,32,16,10],
[56,32,16,13],
[56,32,16,14],
[56,32,17,0],
[56,32,17,1],
[56,32,17,2],
[56,32,17,3],
[56,32,17,4],
[56,32,17,5],
[56,32,17,6],
[56,32,17,7],
[56,32,17,8],
[56,32,17,10],
[56,32,17,13],
[56,32,17,14],
[56,32,17,16],
[56,32,19,0],
[56,32,19,1],
[56,32,19,2],
[56,32,19,3],
[56,32,19,4],
[56,32,19,5],
[56,32,19,6],
[56,32,19,7],
[56,32,19,8],
[56,32,19,10],
[56,32,19,16],
[56,32,19,17],
[56,32,20,0],
[56,32,20,1],
[56,32,20,2],
[56,32,20,3],
[56,32,20,4],
[56,32,20,5],
[56,32,20,6],
[56,32,20,7],
[56,32,20,8],
[56,32,20,13],
[56,32,20,14],
[56,32,20,16],
[56,32,20,17],
[56,32,20,19],
[56,32,21,0],
[56,32,21,1],
[56,32,21,2],
[56,32,21,4],
[56,32,21,7],
[56,32,21,10],
[56,32,21,13],
[56,32,21,14],
[56,32,21,16],
[56,32,21,17],
[56,32,21,19],
[56,32,21,20],
[56,32,22,0],
[56,32,22,1],
[56,32,22,2],
[56,32,22,3],
[56,32,22,4],
[56,32,22,5],
[56,32,22,6],
[56,32,22,7],
[56,32,22,8],
[56,32,22,13],
[56,32,22,14],
[56,32,22,16],
[56,32,22,17],
[56,32,22,19],
[56,32,22,21],
[56,32,23,0],
[56,32,23,1],
[56,32,23,2],
[56,32,23,3],
[56,32,23,4],
[56,32,23,5],
[56,32,23,6],
[56,32,23,7],
[56,32,23,8],
[56,32,23,10],
[56,32,23,13],
[56,32,23,14],
[56,32,23,16],
[56,32,23,17],
[56,32,23,19],
[56,32,23,20],
[56,32,23,21],
[56,32,23,22],
[56,32,24,0],
[56,32,24,1],
[56,32,24,2],
[56,32,24,3],
[56,32,24,4],
[56,32,24,5],
[56,32,24,6],
[56,32,24,7],
[56,32,24,8],
[56,32,24,10],
[56,32,24,13],
[56,32,24,14],
[56,32,24,17],
[56,32,24,19],
[56,32,24,20],
[56,32,24,21],
[56,32,24,22],
[56,32,24,23],
[56,32,25,0],
[56,32,25,1],
[56,32,25,2],
[56,32,25,3],
[56,32,25,4],
[56,32,25,5],
[56,32,25,6],
[56,32,25,8],
[56,32,25,10],
[56,32,25,13],
[56,32,25,14],
[56,32,25,16],
[56,32,25,17],
[56,32,25,19],
[56,32,25,20],
[56,32,25,21],
[56,32,25,22],
[56,32,27,0],
[56,32,27,1],
[56,32,27,2],
[56,32,27,3],
[56,32,27,4],
[56,32,27,5],
[56,32,27,6],
[56,32,27,7],
[56,32,27,8],
[56,32,27,13],
[56,32,27,14],
[56,32,27,16],
[56,32,27,17],
[56,32,27,19],
[56,32,27,23],
[56,32,27,24],
[56,32,27,25],
[56,32,28,0],
[56,32,28,1],
[56,32,28,2],
[56,32,28,3],
[56,32,28,4],
[56,32,28,5],
[56,32,28,6],
[56,32,28,8],
[56,32,28,10],
[56,32,28,13],
[56,32,28,14],
[56,32,28,16],
[56,32,28,17],
[56,32,28,19],
[56,32,28,21],
[56,32,28,22],
[56,32,28,23],
[56,32,28,24],
[56,32,28,27],
[56,32,30,0],
[56,32,30,1],
[56,32,30,2],
[56,32,30,3],
[56,32,30,4],
[56,32,30,5],
[56,32,30,6],
[56,32,30,7],
[56,32,30,8],
[56,32,30,10],
[56,32,30,13],
[56,32,30,14],
[56,32,30,16],
[56,32,30,17],
[56,32,30,19],
[56,32,30,20],
[56,32,30,21],
[56,32,30,22],
[56,32,30,24],
[56,32,30,25],
[56,32,30,27],
[56,32,30,28],
[56,32,31,0],
[56,32,31,1],
[56,32,31,2],
[56,32,31,3],
[56,32,31,4],
[56,32,31,5],
[56,32,31,6],
[56,32,31,7],
[56,32,31,8],
[56,32,31,10],
[56,32,31,13],
[56,32,31,14],
[56,32,31,19],
[56,32,31,20],
[56,32,31,21],
[56,32,31,22],
[56,32,31,24],
[56,32,31,25],
[56,32,31,27],
[56,32,31,28],
[56,33,3,0],
[56,33,3,1],
[56,33,3,2],
[56,33,4,0],
[56,33,4,1],
[56,33,4,2],
[56,33,4,3],
[56,33,5,0],
[56,33,5,1],
[56,33,5,2],
[56,33,5,4],
[56,33,6,0],
[56,33,6,1],
[56,33,6,2],
[56,33,6,4],
[56,33,7,0],
[56,33,7,1],
[56,33,7,2],
[56,33,7,3],
[56,33,7,4],
[56,33,7,5],
[56,33,7,6],
[56,33,8,0],
[56,33,8,1],
[56,33,8,2],
[56,33,8,4],
[56,33,8,7],
[56,33,10,0],
[56,33,10,1],
[56,33,10,2],
[56,33,10,3],
[56,33,10,4],
[56,33,10,5],
[56,33,10,6],
[56,33,10,7],
[56,33,10,8],
[56,33,13,0],
[56,33,13,1],
[56,33,13,2],
[56,33,13,3],
[56,33,13,4],
[56,33,13,5],
[56,33,13,6],
[56,33,13,7],
[56,33,13,8],
[56,33,13,10],
[56,33,14,0],
[56,33,14,1],
[56,33,14,2],
[56,33,14,3],
[56,33,14,4],
[56,33,14,5],
[56,33,14,6],
[56,33,14,7],
[56,33,14,8],
[56,33,14,10],
[56,33,16,0],
[56,33,16,1],
[56,33,16,2],
[56,33,16,3],
[56,33,16,4],
[56,33,16,5],
[56,33,16,6],
[56,33,16,7],
[56,33,16,8],
[56,33,16,10],
[56,33,16,13],
[56,33,16,14],
[56,33,17,0],
[56,33,17,1],
[56,33,17,2],
[56,33,17,3],
[56,33,17,4],
[56,33,17,5],
[56,33,17,6],
[56,33,17,7],
[56,33,17,8],
[56,33,17,10],
[56,33,17,13],
[56,33,17,14],
[56,33,17,16],
[56,33,19,0],
[56,33,19,1],
[56,33,19,2],
[56,33,19,3],
[56,33,19,4],
[56,33,19,5],
[56,33,19,6],
[56,33,19,7],
[56,33,19,8],
[56,33,19,10],
[56,33,19,16],
[56,33,19,17],
[56,33,20,0],
[56,33,20,1],
[56,33,20,2],
[56,33,20,3],
[56,33,20,4],
[56,33,20,5],
[56,33,20,6],
[56,33,20,7],
[56,33,20,8],
[56,33,20,13],
[56,33,20,14],
[56,33,20,16],
[56,33,20,17],
[56,33,20,19],
[56,33,21,0],
[56,33,21,1],
[56,33,21,2],
[56,33,21,4],
[56,33,21,7],
[56,33,21,10],
[56,33,21,13],
[56,33,21,14],
[56,33,21,16],
[56,33,21,17],
[56,33,21,19],
[56,33,21,20],
[56,33,22,0],
[56,33,22,1],
[56,33,22,2],
[56,33,22,3],
[56,33,22,4],
[56,33,22,5],
[56,33,22,6],
[56,33,22,7],
[56,33,22,8],
[56,33,22,13],
[56,33,22,14],
[56,33,22,16],
[56,33,22,17],
[56,33,22,19],
[56,33,22,21],
[56,33,23,0],
[56,33,23,1],
[56,33,23,2],
[56,33,23,3],
[56,33,23,4],
[56,33,23,5],
[56,33,23,6],
[56,33,23,7],
[56,33,23,8],
[56,33,23,10],
[56,33,23,13],
[56,33,23,14],
[56,33,23,16],
[56,33,23,17],
[56,33,23,19],
[56,33,23,20],
[56,33,23,21],
[56,33,23,22],
[56,33,24,0],
[56,33,24,1],
[56,33,24,2],
[56,33,24,3],
[56,33,24,4],
[56,33,24,5],
[56,33,24,6],
[56,33,24,7],
[56,33,24,8],
[56,33,24,10],
[56,33,24,13],
[56,33,24,14],
[56,33,24,17],
[56,33,24,19],
[56,33,24,20],
[56,33,24,21],
[56,33,24,22],
[56,33,24,23],
[56,33,25,0],
[56,33,25,1],
[56,33,25,2],
[56,33,25,3],
[56,33,25,4],
[56,33,25,5],
[56,33,25,6],
[56,33,25,8],
[56,33,25,10],
[56,33,25,13],
[56,33,25,14],
[56,33,25,16],
[56,33,25,17],
[56,33,25,19],
[56,33,25,20],
[56,33,25,21],
[56,33,25,23],
[56,33,25,24],
[56,33,27,0],
[56,33,27,1],
[56,33,27,2],
[56,33,27,3],
[56,33,27,4],
[56,33,27,5],
[56,33,27,6],
[56,33,27,7],
[56,33,27,8],
[56,33,27,13],
[56,33,27,14],
[56,33,27,16],
[56,33,27,17],
[56,33,27,19],
[56,33,27,21],
[56,33,27,23],
[56,33,27,24],
[56,33,27,25],
[56,33,28,0],
[56,33,28,1],
[56,33,28,2],
[56,33,28,3],
[56,33,28,4],
[56,33,28,5],
[56,33,28,6],
[56,33,28,8],
[56,33,28,10],
[56,33,28,13],
[56,33,28,14],
[56,33,28,16],
[56,33,28,17],
[56,33,28,20],
[56,33,28,21],
[56,33,28,22],
[56,33,28,23],
[56,33,28,24],
[56,33,28,27],
[56,33,30,0],
[56,33,30,1],
[56,33,30,2],
[56,33,30,3],
[56,33,30,4],
[56,33,30,5],
[56,33,30,6],
[56,33,30,7],
[56,33,30,8],
[56,33,30,10],
[56,33,30,13],
[56,33,30,14],
[56,33,30,19],
[56,33,30,20],
[56,33,30,21],
[56,33,30,22],
[56,33,30,24],
[56,33,30,25],
[56,33,30,27],
[56,33,30,28],
[56,33,31,0],
[56,33,31,1],
[56,33,31,2],
[56,33,31,3],
[56,33,31,4],
[56,33,31,5],
[56,33,31,6],
[56,33,31,7],
[56,33,31,8],
[56,33,31,10],
[56,33,31,13],
[56,33,31,14],
[56,33,31,16],
[56,33,31,17],
[56,33,31,19],
[56,33,31,20],
[56,33,31,21],
[56,33,31,22],
[56,33,31,24],
[56,33,31,25],
[56,33,31,27],
[56,33,31,28],
[56,34,3,0],
[56,34,3,1],
[56,34,3,2],
[56,34,4,0],
[56,34,4,1],
[56,34,4,2],
[56,34,4,3],
[56,34,5,0],
[56,34,5,1],
[56,34,5,2],
[56,34,5,4],
[56,34,6,0],
[56,34,6,1],
[56,34,6,2],
[56,34,6,4],
[56,34,8,0],
[56,34,8,1],
[56,34,8,2],
[56,34,8,4],
[56,34,10,0],
[56,34,10,1],
[56,34,10,2],
[56,34,10,3],
[56,34,10,4],
[56,34,10,5],
[56,34,10,6],
[56,34,10,8],
[56,34,11,0],
[56,34,11,1],
[56,34,11,2],
[56,34,11,3],
[56,34,11,4],
[56,34,11,5],
[56,34,11,6],
[56,34,11,8],
[56,34,11,10],
[56,34,12,0],
[56,34,12,1],
[56,34,12,2],
[56,34,12,3],
[56,34,12,4],
[56,34,12,5],
[56,34,12,6],
[56,34,12,8],
[56,34,12,10],
[56,34,13,0],
[56,34,13,1],
[56,34,13,2],
[56,34,13,3],
[56,34,13,4],
[56,34,13,5],
[56,34,13,6],
[56,34,13,8],
[56,34,13,10],
[56,34,13,11],
[56,34,13,12],
[56,34,14,0],
[56,34,14,1],
[56,34,14,2],
[56,34,14,3],
[56,34,14,4],
[56,34,14,5],
[56,34,14,6],
[56,34,14,8],
[56,34,14,10],
[56,34,14,11],
[56,34,14,12],
[56,34,16,0],
[56,34,16,1],
[56,34,16,2],
[56,34,16,3],
[56,34,16,4],
[56,34,16,5],
[56,34,16,6],
[56,34,16,8],
[56,34,16,10],
[56,34,16,11],
[56,34,16,12],
[56,34,16,13],
[56,34,16,14],
[56,34,17,0],
[56,34,17,1],
[56,34,17,2],
[56,34,17,3],
[56,34,17,4],
[56,34,17,5],
[56,34,17,6],
[56,34,17,8],
[56,34,17,10],
[56,34,17,11],
[56,34,17,12],
[56,34,17,13],
[56,34,17,14],
[56,34,17,16],
[56,34,19,0],
[56,34,19,1],
[56,34,19,2],
[56,34,19,3],
[56,34,19,4],
[56,34,19,5],
[56,34,19,6],
[56,34,19,8],
[56,34,19,10],
[56,34,19,11],
[56,34,19,12],
[56,34,19,16],
[56,34,19,17],
[56,34,20,0],
[56,34,20,1],
[56,34,20,2],
[56,34,20,3],
[56,34,20,4],
[56,34,20,5],
[56,34,20,6],
[56,34,20,8],
[56,34,20,11],
[56,34,20,12],
[56,34,20,13],
[56,34,20,14],
[56,34,20,16],
[56,34,20,17],
[56,34,20,19],
[56,34,21,0],
[56,34,21,1],
[56,34,21,2],
[56,34,21,4],
[56,34,21,10],
[56,34,21,11],
[56,34,21,12],
[56,34,21,13],
[56,34,21,14],
[56,34,21,16],
[56,34,21,17],
[56,34,21,19],
[56,34,21,20],
[56,34,22,0],
[56,34,22,1],
[56,34,22,2],
[56,34,22,3],
[56,34,22,4],
[56,34,22,5],
[56,34,22,6],
[56,34,22,8],
[56,34,22,11],
[56,34,22,12],
[56,34,22,13],
[56,34,22,14],
[56,34,22,16],
[56,34,22,17],
[56,34,22,19],
[56,34,22,21],
[56,34,23,0],
[56,34,23,1],
[56,34,23,2],
[56,34,23,3],
[56,34,23,4],
[56,34,23,5],
[56,34,23,6],
[56,34,23,8],
[56,34,23,10],
[56,34,23,11],
[56,34,23,12],
[56,34,23,13],
[56,34,23,14],
[56,34,23,16],
[56,34,23,17],
[56,34,23,19],
[56,34,23,20],
[56,34,23,21],
[56,34,23,22],
[56,34,24,0],
[56,34,24,1],
[56,34,24,2],
[56,34,24,3],
[56,34,24,4],
[56,34,24,5],
[56,34,24,6],
[56,34,24,8],
[56,34,24,10],
[56,34,24,11],
[56,34,24,12],
[56,34,24,13],
[56,34,24,14],
[56,34,24,17],
[56,34,24,19],
[56,34,24,20],
[56,34,24,21],
[56,34,24,22],
[56,34,27,0],
[56,34,27,1],
[56,34,27,2],
[56,34,27,3],
[56,34,27,4],
[56,34,27,5],
[56,34,27,6],
[56,34,27,8],
[56,34,27,11],
[56,34,27,12],
[56,34,27,13],
[56,34,27,14],
[56,34,27,16],
[56,34,27,17],
[56,34,27,21],
[56,34,27,23],
[56,34,27,24],
[56,34,29,0],
[56,34,29,1],
[56,34,29,2],
[56,34,29,3],
[56,34,29,4],
[56,34,29,5],
[56,34,29,6],
[56,34,29,8],
[56,34,29,10],
[56,34,29,13],
[56,34,29,14],
[56,34,29,19],
[56,34,29,20],
[56,34,29,21],
[56,34,29,22],
[56,34,29,23],
[56,34,29,24],
[56,34,29,27],
[56,34,30,0],
[56,34,30,1],
[56,34,30,2],
[56,34,30,3],
[56,34,30,4],
[56,34,30,5],
[56,34,30,6],
[56,34,30,8],
[56,34,30,10],
[56,34,30,11],
[56,34,30,12],
[56,34,30,13],
[56,34,30,14],
[56,34,30,16],
[56,34,30,17],
[56,34,30,19],
[56,34,30,20],
[56,34,30,21],
[56,34,30,22],
[56,34,30,24],
[56,34,30,27],
[56,34,31,0],
[56,34,31,1],
[56,34,31,2],
[56,34,31,3],
[56,34,31,4],
[56,34,31,5],
[56,34,31,6],
[56,34,31,8],
[56,34,31,10],
[56,34,31,11],
[56,34,31,12],
[56,34,31,13],
[56,34,31,16],
[56,34,31,17],
[56,34,31,19],
[56,34,31,20],
[56,34,31,21],
[56,34,31,22],
[56,34,31,24],
[56,34,31,27],
[56,34,32,0],
[56,34,32,1],
[56,34,32,2],
[56,34,32,3],
[56,34,32,4],
[56,34,32,5],
[56,34,32,6],
[56,34,32,8],
[56,34,32,10],
[56,34,32,14],
[56,34,32,16],
[56,34,32,17],
[56,34,32,19],
[56,34,32,20],
[56,34,32,21],
[56,34,32,22],
[56,34,32,23],
[56,34,32,24],
[56,34,33,0],
[56,34,33,1],
[56,34,33,2],
[56,34,33,3],
[56,34,33,4],
[56,34,33,5],
[56,34,33,6],
[56,34,33,8],
[56,34,33,13],
[56,34,33,14],
[56,34,33,16],
[56,34,33,17],
[56,34,33,19],
[56,34,33,20],
[56,34,33,21],
[56,34,33,22],
[56,34,33,23],
[56,34,33,24],
[56,36,3,0],
[56,36,3,1],
[56,36,3,2],
[56,36,4,0],
[56,36,4,1],
[56,36,4,2],
[56,36,4,3],
[56,36,5,0],
[56,36,5,1],
[56,36,5,2],
[56,36,5,4],
[56,36,6,0],
[56,36,6,1],
[56,36,6,2],
[56,36,6,4],
[56,36,7,0],
[56,36,7,1],
[56,36,7,2],
[56,36,7,3],
[56,36,7,4],
[56,36,7,5],
[56,36,7,6],
[56,36,8,0],
[56,36,8,1],
[56,36,8,2],
[56,36,8,4],
[56,36,8,7],
[56,36,10,0],
[56,36,10,1],
[56,36,10,2],
[56,36,10,3],
[56,36,10,4],
[56,36,10,5],
[56,36,10,6],
[56,36,10,7],
[56,36,10,8],
[56,36,11,0],
[56,36,11,1],
[56,36,11,2],
[56,36,11,3],
[56,36,11,4],
[56,36,11,5],
[56,36,11,6],
[56,36,11,7],
[56,36,11,8],
[56,36,11,10],
[56,36,12,0],
[56,36,12,1],
[56,36,12,2],
[56,36,12,3],
[56,36,12,4],
[56,36,12,5],
[56,36,12,6],
[56,36,12,7],
[56,36,12,8],
[56,36,12,10],
[56,36,16,0],
[56,36,16,1],
[56,36,16,2],
[56,36,16,3],
[56,36,16,4],
[56,36,16,5],
[56,36,16,6],
[56,36,16,7],
[56,36,16,8],
[56,36,16,10],
[56,36,16,11],
[56,36,16,12],
[56,36,17,0],
[56,36,17,1],
[56,36,17,2],
[56,36,17,3],
[56,36,17,4],
[56,36,17,5],
[56,36,17,6],
[56,36,17,7],
[56,36,17,8],
[56,36,17,10],
[56,36,17,11],
[56,36,17,12],
[56,36,17,16],
[56,36,20,0],
[56,36,20,1],
[56,36,20,2],
[56,36,20,3],
[56,36,20,4],
[56,36,20,5],
[56,36,20,6],
[56,36,20,7],
[56,36,20,8],
[56,36,20,11],
[56,36,20,12],
[56,36,20,16],
[56,36,20,17],
[56,36,21,0],
[56,36,21,1],
[56,36,21,2],
[56,36,21,4],
[56,36,21,7],
[56,36,21,10],
[56,36,21,11],
[56,36,21,12],
[56,36,21,16],
[56,36,21,17],
[56,36,21,20],
[56,36,22,0],
[56,36,22,1],
[56,36,22,2],
[56,36,22,3],
[56,36,22,4],
[56,36,22,5],
[56,36,22,6],
[56,36,22,7],
[56,36,22,8],
[56,36,22,11],
[56,36,22,12],
[56,36,22,16],
[56,36,22,17],
[56,36,22,21],
[56,36,23,0],
[56,36,23,1],
[56,36,23,2],
[56,36,23,3],
[56,36,23,4],
[56,36,23,5],
[56,36,23,6],
[56,36,23,7],
[56,36,23,8],
[56,36,23,10],
[56,36,23,11],
[56,36,23,12],
[56,36,23,16],
[56,36,23,17],
[56,36,23,20],
[56,36,23,22],
[56,36,24,0],
[56,36,24,1],
[56,36,24,2],
[56,36,24,3],
[56,36,24,4],
[56,36,24,5],
[56,36,24,6],
[56,36,24,7],
[56,36,24,8],
[56,36,24,10],
[56,36,24,11],
[56,36,24,12],
[56,36,24,17],
[56,36,24,20],
[56,36,24,22],
[56,36,24,23],
[56,36,25,0],
[56,36,25,1],
[56,36,25,2],
[56,36,25,3],
[56,36,25,4],
[56,36,25,5],
[56,36,25,6],
[56,36,25,8],
[56,36,25,10],
[56,36,25,11],
[56,36,25,12],
[56,36,25,16],
[56,36,25,17],
[56,36,25,20],
[56,36,25,21],
[56,36,25,22],
[56,36,25,23],
[56,36,25,24],
[56,36,27,0],
[56,36,27,1],
[56,36,27,2],
[56,36,27,3],
[56,36,27,4],
[56,36,27,5],
[56,36,27,6],
[56,36,27,7],
[56,36,27,8],
[56,36,27,11],
[56,36,27,12],
[56,36,27,21],
[56,36,27,23],
[56,36,27,24],
[56,36,27,25],
[56,36,28,0],
[56,36,28,1],
[56,36,28,2],
[56,36,28,3],
[56,36,28,4],
[56,36,28,5],
[56,36,28,6],
[56,36,28,8],
[56,36,28,10],
[56,36,28,11],
[56,36,28,12],
[56,36,28,16],
[56,36,28,17],
[56,36,28,20],
[56,36,28,21],
[56,36,28,22],
[56,36,28,23],
[56,36,28,24],
[56,36,28,27],
[56,36,29,0],
[56,36,29,1],
[56,36,29,2],
[56,36,29,3],
[56,36,29,4],
[56,36,29,5],
[56,36,29,6],
[56,36,29,7],
[56,36,29,8],
[56,36,29,10],
[56,36,29,16],
[56,36,29,17],
[56,36,29,20],
[56,36,29,21],
[56,36,29,22],
[56,36,29,23],
[56,36,29,24],
[56,36,29,25],
[56,36,29,27],
[56,36,30,0],
[56,36,30,1],
[56,36,30,2],
[56,36,30,3],
[56,36,30,4],
[56,36,30,5],
[56,36,30,6],
[56,36,30,7],
[56,36,30,8],
[56,36,30,10],
[56,36,30,11],
[56,36,30,16],
[56,36,30,17],
[56,36,30,20],
[56,36,30,21],
[56,36,30,22],
[56,36,30,24],
[56,36,30,25],
[56,36,31,0],
[56,36,31,1],
[56,36,31,2],
[56,36,31,3],
[56,36,31,4],
[56,36,31,5],
[56,36,31,6],
[56,36,31,7],
[56,36,31,8],
[56,36,31,12],
[56,36,31,16],
[56,36,31,17],
[56,36,31,20],
[56,36,31,21],
[56,36,31,22],
[56,36,31,24],
[56,36,31,25],
[56,36,32,0],
[56,36,32,1],
[56,36,32,2],
[56,36,32,3],
[56,36,32,4],
[56,36,32,5],
[56,36,32,6],
[56,36,32,7],
[56,36,32,10],
[56,36,32,16],
[56,36,32,17],
[56,36,32,20],
[56,36,32,21],
[56,36,32,22],
[56,36,32,23],
[56,36,32,24],
[56,36,33,0],
[56,36,33,1],
[56,36,33,2],
[56,36,33,3],
[56,36,33,4],
[56,36,33,5],
[56,36,33,8],
[56,36,33,10],
[56,36,33,16],
[56,36,33,17],
[56,36,33,20],
[56,36,33,21],
[56,36,33,22],
[56,36,33,23],
[56,36,33,24],
[56,36,34,0],
[56,36,34,1],
[56,36,34,2],
[56,36,34,3],
[56,36,34,4],
[56,36,34,6],
[56,36,34,8],
[56,36,34,10],
[56,36,34,11],
[56,36,34,12],
[56,36,34,16],
[56,36,34,17],
[56,36,34,20],
[56,36,34,21],
[56,36,34,22],
[56,37,3,0],
[56,37,3,1],
[56,37,3,2],
[56,37,5,0],
[56,37,5,1],
[56,37,5,2],
[56,37,6,0],
[56,37,6,1],
[56,37,6,2],
[56,37,7,0],
[56,37,7,1],
[56,37,7,2],
[56,37,7,3],
[56,37,7,5],
[56,37,7,6],
[56,37,8,0],
[56,37,8,1],
[56,37,8,2],
[56,37,8,7],
[56,37,10,0],
[56,37,10,1],
[56,37,10,2],
[56,37,10,3],
[56,37,10,5],
[56,37,10,6],
[56,37,10,7],
[56,37,10,8],
[56,37,11,0],
[56,37,11,1],
[56,37,11,2],
[56,37,11,3],
[56,37,11,5],
[56,37,11,6],
[56,37,11,7],
[56,37,11,8],
[56,37,11,10],
[56,37,12,0],
[56,37,12,1],
[56,37,12,2],
[56,37,12,3],
[56,37,12,5],
[56,37,12,6],
[56,37,12,7],
[56,37,12,8],
[56,37,12,10],
[56,37,13,0],
[56,37,13,1],
[56,37,13,2],
[56,37,13,3],
[56,37,13,5],
[56,37,13,6],
[56,37,13,7],
[56,37,13,8],
[56,37,13,10],
[56,37,13,11],
[56,37,13,12],
[56,37,14,0],
[56,37,14,1],
[56,37,14,2],
[56,37,14,3],
[56,37,14,5],
[56,37,14,6],
[56,37,14,7],
[56,37,14,8],
[56,37,14,10],
[56,37,14,11],
[56,37,14,12],
[56,37,16,0],
[56,37,16,1],
[56,37,16,2],
[56,37,16,3],
[56,37,16,5],
[56,37,16,6],
[56,37,16,7],
[56,37,16,8],
[56,37,16,10],
[56,37,16,11],
[56,37,16,12],
[56,37,16,13],
[56,37,16,14],
[56,37,17,0],
[56,37,17,1],
[56,37,17,2],
[56,37,17,3],
[56,37,17,5],
[56,37,17,6],
[56,37,17,7],
[56,37,17,8],
[56,37,17,10],
[56,37,17,11],
[56,37,17,12],
[56,37,17,13],
[56,37,17,14],
[56,37,17,16],
[56,37,19,0],
[56,37,19,1],
[56,37,19,2],
[56,37,19,3],
[56,37,19,5],
[56,37,19,6],
[56,37,19,7],
[56,37,19,8],
[56,37,19,10],
[56,37,19,11],
[56,37,19,12],
[56,37,19,16],
[56,37,19,17],
[56,37,20,0],
[56,37,20,1],
[56,37,20,2],
[56,37,20,3],
[56,37,20,5],
[56,37,20,6],
[56,37,20,7],
[56,37,20,8],
[56,37,20,11],
[56,37,20,12],
[56,37,20,13],
[56,37,20,14],
[56,37,20,16],
[56,37,20,17],
[56,37,20,19],
[56,37,21,0],
[56,37,21,1],
[56,37,21,2],
[56,37,21,7],
[56,37,21,10],
[56,37,21,11],
[56,37,21,12],
[56,37,21,13],
[56,37,21,14],
[56,37,21,16],
[56,37,21,17],
[56,37,21,19],
[56,37,21,20],
[56,37,22,0],
[56,37,22,1],
[56,37,22,2],
[56,37,22,3],
[56,37,22,5],
[56,37,22,6],
[56,37,22,7],
[56,37,22,8],
[56,37,22,11],
[56,37,22,12],
[56,37,22,13],
[56,37,22,14],
[56,37,22,16],
[56,37,22,17],
[56,37,22,19],
[56,37,23,0],
[56,37,23,1],
[56,37,23,2],
[56,37,23,3],
[56,37,23,5],
[56,37,23,6],
[56,37,23,7],
[56,37,23,8],
[56,37,23,10],
[56,37,23,11],
[56,37,23,12],
[56,37,23,13],
[56,37,23,14],
[56,37,23,16],
[56,37,23,17],
[56,37,23,19],
[56,37,23,21],
[56,37,23,22],
[56,37,24,0],
[56,37,24,1],
[56,37,24,2],
[56,37,24,3],
[56,37,24,5],
[56,37,24,6],
[56,37,24,7],
[56,37,24,8],
[56,37,24,10],
[56,37,24,11],
[56,37,24,12],
[56,37,24,13],
[56,37,24,14],
[56,37,24,17],
[56,37,24,19],
[56,37,24,21],
[56,37,24,22],
[56,37,24,23],
[56,37,25,0],
[56,37,25,1],
[56,37,25,2],
[56,37,25,3],
[56,37,25,5],
[56,37,25,6],
[56,37,25,8],
[56,37,25,10],
[56,37,25,11],
[56,37,25,12],
[56,37,25,13],
[56,37,25,14],
[56,37,25,16],
[56,37,25,17],
[56,37,25,19],
[56,37,25,20],
[56,37,25,21],
[56,37,25,22],
[56,37,25,23],
[56,37,25,24],
[56,37,27,0],
[56,37,27,1],
[56,37,27,2],
[56,37,27,3],
[56,37,27,5],
[56,37,27,6],
[56,37,27,7],
[56,37,27,8],
[56,37,27,11],
[56,37,27,12],
[56,37,27,13],
[56,37,27,14],
[56,37,27,16],
[56,37,27,17],
[56,37,27,19],
[56,37,27,21],
[56,37,27,23],
[56,37,27,24],
[56,37,27,25],
[56,37,28,0],
[56,37,28,1],
[56,37,28,2],
[56,37,28,3],
[56,37,28,5],
[56,37,28,6],
[56,37,28,8],
[56,37,28,10],
[56,37,28,11],
[56,37,28,12],
[56,37,28,13],
[56,37,28,16],
[56,37,28,17],
[56,37,28,19],
[56,37,28,20],
[56,37,28,21],
[56,37,28,22],
[56,37,28,23],
[56,37,28,24],
[56,37,28,27],
[56,37,29,0],
[56,37,29,1],
[56,37,29,2],
[56,37,29,3],
[56,37,29,5],
[56,37,29,6],
[56,37,29,7],
[56,37,29,8],
[56,37,29,10],
[56,37,29,14],
[56,37,29,16],
[56,37,29,17],
[56,37,29,19],
[56,37,29,20],
[56,37,29,21],
[56,37,29,22],
[56,37,29,23],
[56,37,29,24],
[56,37,29,25],
[56,37,30,0],
[56,37,30,1],
[56,37,30,2],
[56,37,30,3],
[56,37,30,5],
[56,37,30,6],
[56,37,30,7],
[56,37,30,8],
[56,37,30,12],
[56,37,30,13],
[56,37,30,14],
[56,37,30,16],
[56,37,30,17],
[56,37,30,19],
[56,37,30,20],
[56,37,30,21],
[56,37,30,22],
[56,37,30,24],
[56,37,30,25],
[56,37,31,0],
[56,37,31,1],
[56,37,31,2],
[56,37,31,3],
[56,37,31,5],
[56,37,31,6],
[56,37,31,7],
[56,37,31,10],
[56,37,31,11],
[56,37,31,12],
[56,37,31,13],
[56,37,31,14],
[56,37,31,16],
[56,37,31,17],
[56,37,31,19],
[56,37,31,20],
[56,37,31,21],
[56,37,31,22],
[56,37,31,24],
[56,37,32,0],
[56,37,32,1],
[56,37,32,2],
[56,37,32,3],
[56,37,32,5],
[56,37,32,8],
[56,37,32,10],
[56,37,32,13],
[56,37,32,14],
[56,37,32,16],
[56,37,32,17],
[56,37,32,19],
[56,37,32,20],
[56,37,32,21],
[56,37,32,22],
[56,37,32,23],
[56,37,32,24],
[56,37,33,0],
[56,37,33,1],
[56,37,33,2],
[56,37,33,3],
[56,37,33,6],
[56,37,33,7],
[56,37,33,8],
[56,37,33,10],
[56,37,33,13],
[56,37,33,14],
[56,37,33,16],
[56,37,33,17],
[56,37,33,19],
[56,37,33,20],
[56,37,33,21],
[56,37,33,22],
[56,37,34,0],
[56,37,34,1],
[56,37,34,2],
[56,37,34,5],
[56,37,34,6],
[56,37,34,8],
[56,37,34,10],
[56,37,34,11],
[56,37,34,12],
[56,37,34,13],
[56,37,34,14],
[56,37,34,16],
[56,37,34,17],
[56,37,34,19],
[56,37,34,20],
[56,37,34,21],
[56,37,36,0],
[56,37,36,1],
[56,37,36,2],
[56,37,36,3],
[56,37,36,5],
[56,37,36,6],
[56,37,36,7],
[56,37,36,8],
[56,37,36,10],
[56,37,36,11],
[56,37,36,12],
[56,37,36,16],
[56,37,36,17],
[56,38,3,0],
[56,38,3,1],
[56,38,3,2],
[56,38,4,0],
[56,38,4,1],
[56,38,4,2],
[56,38,4,3],
[56,38,5,0],
[56,38,5,1],
[56,38,5,2],
[56,38,5,4],
[56,38,6,0],
[56,38,6,1],
[56,38,6,2],
[56,38,6,4],
[56,38,8,0],
[56,38,8,1],
[56,38,8,2],
[56,38,8,4],
[56,38,10,0],
[56,38,10,1],
[56,38,10,2],
[56,38,10,3],
[56,38,10,4],
[56,38,10,5],
[56,38,10,6],
[56,38,10,8],
[56,38,11,0],
[56,38,11,1],
[56,38,11,2],
[56,38,11,3],
[56,38,11,4],
[56,38,11,5],
[56,38,11,6],
[56,38,11,8],
[56,38,11,10],
[56,38,12,0],
[56,38,12,1],
[56,38,12,2],
[56,38,12,3],
[56,38,12,4],
[56,38,12,5],
[56,38,12,6],
[56,38,12,8],
[56,38,12,10],
[56,38,13,0],
[56,38,13,1],
[56,38,13,2],
[56,38,13,3],
[56,38,13,4],
[56,38,13,5],
[56,38,13,6],
[56,38,13,8],
[56,38,13,10],
[56,38,13,11],
[56,38,13,12],
[56,38,14,0],
[56,38,14,1],
[56,38,14,2],
[56,38,14,3],
[56,38,14,4],
[56,38,14,5],
[56,38,14,6],
[56,38,14,8],
[56,38,14,10],
[56,38,14,11],
[56,38,14,12],
[56,38,16,0],
[56,38,16,1],
[56,38,16,2],
[56,38,16,3],
[56,38,16,4],
[56,38,16,5],
[56,38,16,6],
[56,38,16,8],
[56,38,16,10],
[56,38,16,11],
[56,38,16,12],
[56,38,16,13],
[56,38,16,14],
[56,38,17,0],
[56,38,17,1],
[56,38,17,2],
[56,38,17,3],
[56,38,17,4],
[56,38,17,5],
[56,38,17,6],
[56,38,17,8],
[56,38,17,10],
[56,38,17,11],
[56,38,17,12],
[56,38,17,13],
[56,38,17,14],
[56,38,17,16],
[56,38,19,0],
[56,38,19,1],
[56,38,19,2],
[56,38,19,3],
[56,38,19,4],
[56,38,19,5],
[56,38,19,6],
[56,38,19,8],
[56,38,19,10],
[56,38,19,11],
[56,38,19,12],
[56,38,19,16],
[56,38,19,17],
[56,38,20,0],
[56,38,20,1],
[56,38,20,2],
[56,38,20,3],
[56,38,20,4],
[56,38,20,5],
[56,38,20,6],
[56,38,20,8],
[56,38,20,11],
[56,38,20,12],
[56,38,20,13],
[56,38,20,14],
[56,38,20,16],
[56,38,20,17],
[56,38,20,19],
[56,38,21,0],
[56,38,21,1],
[56,38,21,2],
[56,38,21,4],
[56,38,21,10],
[56,38,21,11],
[56,38,21,12],
[56,38,21,13],
[56,38,21,14],
[56,38,21,16],
[56,38,21,17],
[56,38,21,19],
[56,38,21,20],
[56,38,22,0],
[56,38,22,1],
[56,38,22,2],
[56,38,22,3],
[56,38,22,4],
[56,38,22,5],
[56,38,22,6],
[56,38,22,8],
[56,38,22,11],
[56,38,22,12],
[56,38,22,13],
[56,38,22,14],
[56,38,22,16],
[56,38,22,17],
[56,38,22,19],
[56,38,22,21],
[56,38,23,0],
[56,38,23,1],
[56,38,23,2],
[56,38,23,3],
[56,38,23,4],
[56,38,23,5],
[56,38,23,6],
[56,38,23,8],
[56,38,23,10],
[56,38,23,11],
[56,38,23,12],
[56,38,23,13],
[56,38,23,14],
[56,38,23,16],
[56,38,23,17],
[56,38,23,20],
[56,38,23,21],
[56,38,23,22],
[56,38,24,0],
[56,38,24,1],
[56,38,24,2],
[56,38,24,3],
[56,38,24,4],
[56,38,24,5],
[56,38,24,6],
[56,38,24,8],
[56,38,24,10],
[56,38,24,11],
[56,38,24,12],
[56,38,24,13],
[56,38,24,14],
[56,38,24,17],
[56,38,24,20],
[56,38,24,21],
[56,38,24,22],
[56,38,24,23],
[56,38,27,0],
[56,38,27,1],
[56,38,27,2],
[56,38,27,3],
[56,38,27,4],
[56,38,27,5],
[56,38,27,6],
[56,38,27,8],
[56,38,27,11],
[56,38,27,12],
[56,38,27,13],
[56,38,27,16],
[56,38,27,17],
[56,38,27,19],
[56,38,27,21],
[56,38,27,23],
[56,38,27,24],
[56,38,29,0],
[56,38,29,1],
[56,38,29,2],
[56,38,29,3],
[56,38,29,4],
[56,38,29,5],
[56,38,29,6],
[56,38,29,8],
[56,38,29,13],
[56,38,29,14],
[56,38,29,16],
[56,38,29,17],
[56,38,29,19],
[56,38,29,20],
[56,38,29,21],
[56,38,29,22],
[56,38,29,23],
[56,38,29,24],
[56,38,30,0],
[56,38,30,1],
[56,38,30,2],
[56,38,30,3],
[56,38,30,4],
[56,38,30,5],
[56,38,30,6],
[56,38,30,10],
[56,38,30,11],
[56,38,30,12],
[56,38,30,13],
[56,38,30,14],
[56,38,30,16],
[56,38,30,17],
[56,38,30,19],
[56,38,30,20],
[56,38,30,21],
[56,38,30,22],
[56,38,30,24],
[56,38,31,0],
[56,38,31,1],
[56,38,31,2],
[56,38,31,3],
[56,38,31,4],
[56,38,31,5],
[56,38,31,8],
[56,38,31,10],
[56,38,31,11],
[56,38,31,12],
[56,38,31,13],
[56,38,31,14],
[56,38,31,16],
[56,38,31,17],
[56,38,31,19],
[56,38,31,20],
[56,38,31,21],
[56,38,31,22],
[56,38,31,24],
[56,38,32,0],
[56,38,32,1],
[56,38,32,2],
[56,38,32,3],
[56,38,32,4],
[56,38,32,6],
[56,38,32,8],
[56,38,32,10],
[56,38,32,13],
[56,38,32,14],
[56,38,32,16],
[56,38,32,17],
[56,38,32,19],
[56,38,32,20],
[56,38,32,21],
[56,38,32,22],
[56,38,33,0],
[56,38,33,1],
[56,38,33,2],
[56,38,33,5],
[56,38,33,6],
[56,38,33,8],
[56,38,33,10],
[56,38,33,13],
[56,38,33,14],
[56,38,33,16],
[56,38,33,17],
[56,38,33,19],
[56,38,33,20],
[56,38,33,21],
[56,38,36,0],
[56,38,36,1],
[56,38,36,2],
[56,38,36,3],
[56,38,36,4],
[56,38,36,5],
[56,38,36,6],
[56,38,36,8],
[56,38,36,10],
[56,38,36,11],
[56,38,36,12],
[56,38,36,16],
[56,38,36,17],
[56,38,37,0],
[56,38,37,1],
[56,38,37,2],
[56,38,37,3],
[56,38,37,5],
[56,38,37,6],
[56,38,37,8],
[56,38,37,10],
[56,38,37,11],
[56,38,37,12],
[56,38,37,13],
[56,38,37,14],
[56,38,37,16],
[56,38,37,17],
[56,39,4,0],
[56,39,4,1],
[56,39,4,2],
[56,39,7,0],
[56,39,7,1],
[56,39,7,2],
[56,39,7,4],
[56,39,10,0],
[56,39,10,1],
[56,39,10,2],
[56,39,10,4],
[56,39,10,7],
[56,39,11,0],
[56,39,11,1],
[56,39,11,2],
[56,39,11,4],
[56,39,11,7],
[56,39,11,10],
[56,39,12,0],
[56,39,12,1],
[56,39,12,2],
[56,39,12,4],
[56,39,12,7],
[56,39,12,10],
[56,39,13,0],
[56,39,13,1],
[56,39,13,2],
[56,39,13,4],
[56,39,13,7],
[56,39,13,10],
[56,39,13,11],
[56,39,13,12],
[56,39,14,0],
[56,39,14,1],
[56,39,14,2],
[56,39,14,4],
[56,39,14,7],
[56,39,14,10],
[56,39,14,11],
[56,39,14,12],
[56,39,16,0],
[56,39,16,1],
[56,39,16,2],
[56,39,16,4],
[56,39,16,7],
[56,39,16,10],
[56,39,16,11],
[56,39,16,12],
[56,39,16,13],
[56,39,16,14],
[56,39,17,0],
[56,39,17,1],
[56,39,17,2],
[56,39,17,4],
[56,39,17,7],
[56,39,17,10],
[56,39,17,11],
[56,39,17,12],
[56,39,17,13],
[56,39,17,14],
[56,39,17,16],
[56,39,19,0],
[56,39,19,1],
[56,39,19,2],
[56,39,19,4],
[56,39,19,7],
[56,39,19,10],
[56,39,19,11],
[56,39,19,12],
[56,39,19,16],
[56,39,19,17],
[56,39,20,0],
[56,39,20,1],
[56,39,20,2],
[56,39,20,4],
[56,39,20,7],
[56,39,20,11],
[56,39,20,12],
[56,39,20,13],
[56,39,20,14],
[56,39,20,16],
[56,39,20,17],
[56,39,20,19],
[56,39,22,0],
[56,39,22,1],
[56,39,22,2],
[56,39,22,4],
[56,39,22,7],
[56,39,22,11],
[56,39,22,12],
[56,39,22,13],
[56,39,22,14],
[56,39,22,16],
[56,39,22,17],
[56,39,23,0],
[56,39,23,1],
[56,39,23,2],
[56,39,23,4],
[56,39,23,7],
[56,39,23,10],
[56,39,23,11],
[56,39,23,12],
[56,39,23,13],
[56,39,23,14],
[56,39,23,16],
[56,39,23,17],
[56,39,23,19],
[56,39,23,20],
[56,39,23,22],
[56,39,24,0],
[56,39,24,1],
[56,39,24,2],
[56,39,24,4],
[56,39,24,7],
[56,39,24,10],
[56,39,24,11],
[56,39,24,12],
[56,39,24,13],
[56,39,24,14],
[56,39,24,17],
[56,39,24,19],
[56,39,24,20],
[56,39,24,22],
[56,39,24,23],
[56,39,25,0],
[56,39,25,1],
[56,39,25,2],
[56,39,25,4],
[56,39,25,10],
[56,39,25,11],
[56,39,25,12],
[56,39,25,13],
[56,39,25,14],
[56,39,25,16],
[56,39,25,17],
[56,39,25,19],
[56,39,25,20],
[56,39,25,22],
[56,39,25,23],
[56,39,25,24],
[56,39,27,0],
[56,39,27,1],
[56,39,27,2],
[56,39,27,4],
[56,39,27,7],
[56,39,27,11],
[56,39,27,14],
[56,39,27,16],
[56,39,27,17],
[56,39,27,19],
[56,39,27,23],
[56,39,27,24],
[56,39,27,25],
[56,39,28,0],
[56,39,28,1],
[56,39,28,2],
[56,39,28,4],
[56,39,28,12],
[56,39,28,13],
[56,39,28,14],
[56,39,28,16],
[56,39,28,17],
[56,39,28,19],
[56,39,28,20],
[56,39,28,22],
[56,39,28,23],
[56,39,28,24],
[56,39,29,0],
[56,39,29,1],
[56,39,29,2],
[56,39,29,4],
[56,39,29,7],
[56,39,29,10],
[56,39,29,13],
[56,39,29,14],
[56,39,29,16],
[56,39,29,17],
[56,39,29,19],
[56,39,29,20],
[56,39,29,22],
[56,39,29,23],
[56,39,29,24],
[56,39,30,0],
[56,39,30,1],
[56,39,30,2],
[56,39,30,4],
[56,39,30,10],
[56,39,30,11],
[56,39,30,12],
[56,39,30,13],
[56,39,30,14],
[56,39,30,16],
[56,39,30,17],
[56,39,30,19],
[56,39,30,20],
[56,39,30,22],
[56,39,30,24],
[56,39,31,0],
[56,39,31,1],
[56,39,31,2],
[56,39,31,4],
[56,39,31,7],
[56,39,31,10],
[56,39,31,11],
[56,39,31,12],
[56,39,31,13],
[56,39,31,14],
[56,39,31,16],
[56,39,31,17],
[56,39,31,19],
[56,39,31,20],
[56,39,31,22],
[56,39,32,0],
[56,39,32,1],
[56,39,32,2],
[56,39,32,7],
[56,39,32,10],
[56,39,32,13],
[56,39,32,14],
[56,39,32,16],
[56,39,32,17],
[56,39,32,19],
[56,39,32,20],
[56,39,33,4],
[56,39,33,7],
[56,39,33,10],
[56,39,33,13],
[56,39,33,14],
[56,39,33,16],
[56,39,33,17],
[56,39,33,19],
[56,39,33,20],
[56,39,34,0],
[56,39,34,1],
[56,39,34,2],
[56,39,34,4],
[56,39,34,10],
[56,39,34,11],
[56,39,34,12],
[56,39,34,13],
[56,39,34,14],
[56,39,34,16],
[56,39,34,17],
[56,39,34,19],
[56,39,36,0],
[56,39,36,1],
[56,39,36,2],
[56,39,36,4],
[56,39,36,7],
[56,39,36,10],
[56,39,36,11],
[56,39,36,12],
[56,39,36,16],
[56,39,36,17],
[56,39,37,0],
[56,39,37,1],
[56,39,37,2],
[56,39,37,7],
[56,39,37,10],
[56,39,37,11],
[56,39,37,12],
[56,39,37,13],
[56,39,37,14],
[56,39,38,0],
[56,39,38,1],
[56,39,38,2],
[56,39,38,4],
[56,39,38,10],
[56,39,38,11],
[56,39,38,12],
[56,39,38,13],
[56,39,38,14],
[56,42,3,0],
[56,42,3,1],
[56,42,3,2],
[56,42,4,0],
[56,42,4,1],
[56,42,4,2],
[56,42,4,3],
[56,42,5,0],
[56,42,5,1],
[56,42,5,2],
[56,42,5,4],
[56,42,6,0],
[56,42,6,1],
[56,42,6,2],
[56,42,6,4],
[56,42,7,0],
[56,42,7,1],
[56,42,7,2],
[56,42,7,3],
[56,42,7,4],
[56,42,7,5],
[56,42,7,6],
[56,42,8,0],
[56,42,8,1],
[56,42,8,2],
[56,42,8,4],
[56,42,8,7],
[56,42,11,0],
[56,42,11,1],
[56,42,11,2],
[56,42,11,3],
[56,42,11,4],
[56,42,11,5],
[56,42,11,6],
[56,42,11,7],
[56,42,11,8],
[56,42,12,0],
[56,42,12,1],
[56,42,12,2],
[56,42,12,3],
[56,42,12,4],
[56,42,12,5],
[56,42,12,6],
[56,42,12,7],
[56,42,12,8],
[56,42,13,0],
[56,42,13,1],
[56,42,13,2],
[56,42,13,3],
[56,42,13,4],
[56,42,13,5],
[56,42,13,6],
[56,42,13,7],
[56,42,13,8],
[56,42,13,11],
[56,42,13,12],
[56,42,14,0],
[56,42,14,1],
[56,42,14,2],
[56,42,14,3],
[56,42,14,4],
[56,42,14,5],
[56,42,14,6],
[56,42,14,7],
[56,42,14,8],
[56,42,14,11],
[56,42,14,12],
[56,42,16,0],
[56,42,16,1],
[56,42,16,2],
[56,42,16,3],
[56,42,16,4],
[56,42,16,5],
[56,42,16,6],
[56,42,16,7],
[56,42,16,8],
[56,42,16,11],
[56,42,16,12],
[56,42,16,13],
[56,42,16,14],
[56,42,17,0],
[56,42,17,1],
[56,42,17,2],
[56,42,17,3],
[56,42,17,4],
[56,42,17,5],
[56,42,17,6],
[56,42,17,7],
[56,42,17,8],
[56,42,17,11],
[56,42,17,12],
[56,42,17,13],
[56,42,17,14],
[56,42,17,16],
[56,42,19,0],
[56,42,19,1],
[56,42,19,2],
[56,42,19,3],
[56,42,19,4],
[56,42,19,5],
[56,42,19,6],
[56,42,19,7],
[56,42,19,8],
[56,42,19,11],
[56,42,19,12],
[56,42,19,16],
[56,42,19,17],
[56,42,21,0],
[56,42,21,1],
[56,42,21,2],
[56,42,21,4],
[56,42,21,7],
[56,42,21,11],
[56,42,21,12],
[56,42,21,13],
[56,42,21,14],
[56,42,21,16],
[56,42,21,17],
[56,42,21,19],
[56,42,23,0],
[56,42,23,1],
[56,42,23,2],
[56,42,23,3],
[56,42,23,4],
[56,42,23,5],
[56,42,23,6],
[56,42,23,7],
[56,42,23,8],
[56,42,23,11],
[56,42,23,12],
[56,42,23,13],
[56,42,23,14],
[56,42,23,16],
[56,42,23,17],
[56,42,23,19],
[56,42,23,21],
[56,42,24,0],
[56,42,24,1],
[56,42,24,2],
[56,42,24,3],
[56,42,24,4],
[56,42,24,5],
[56,42,24,6],
[56,42,24,7],
[56,42,24,8],
[56,42,24,11],
[56,42,24,12],
[56,42,24,13],
[56,42,24,14],
[56,42,24,17],
[56,42,24,19],
[56,42,24,21],
[56,42,24,23],
[56,42,25,0],
[56,42,25,1],
[56,42,25,2],
[56,42,25,3],
[56,42,25,4],
[56,42,25,5],
[56,42,25,6],
[56,42,25,8],
[56,42,25,11],
[56,42,25,14],
[56,42,25,16],
[56,42,25,17],
[56,42,25,19],
[56,42,25,21],
[56,42,25,23],
[56,42,25,24],
[56,42,28,0],
[56,42,28,1],
[56,42,28,2],
[56,42,28,3],
[56,42,28,4],
[56,42,28,5],
[56,42,28,8],
[56,42,28,11],
[56,42,28,12],
[56,42,28,13],
[56,42,28,14],
[56,42,28,16],
[56,42,28,17],
[56,42,28,19],
[56,42,28,21],
[56,42,28,23],
[56,42,28,24],
[56,42,29,0],
[56,42,29,1],
[56,42,29,2],
[56,42,29,3],
[56,42,29,4],
[56,42,29,6],
[56,42,29,7],
[56,42,29,8],
[56,42,29,13],
[56,42,29,14],
[56,42,29,16],
[56,42,29,17],
[56,42,29,19],
[56,42,29,21],
[56,42,30,0],
[56,42,30,1],
[56,42,30,2],
[56,42,30,5],
[56,42,30,6],
[56,42,30,7],
[56,42,30,8],
[56,42,30,11],
[56,42,30,12],
[56,42,30,13],
[56,42,30,14],
[56,42,30,16],
[56,42,30,17],
[56,42,30,19],
[56,42,30,21],
[56,42,31,3],
[56,42,31,4],
[56,42,31,5],
[56,42,31,6],
[56,42,31,7],
[56,42,31,8],
[56,42,31,11],
[56,42,31,12],
[56,42,31,13],
[56,42,31,14],
[56,42,31,16],
[56,42,31,17],
[56,42,31,19],
[56,42,32,0],
[56,42,32,1],
[56,42,32,2],
[56,42,32,3],
[56,42,32,4],
[56,42,32,5],
[56,42,32,6],
[56,42,32,7],
[56,42,32,8],
[56,42,32,13],
[56,42,32,14],
[56,42,32,16],
[56,42,32,17],
[56,42,32,19],
[56,42,33,0],
[56,42,33,1],
[56,42,33,2],
[56,42,33,3],
[56,42,33,4],
[56,42,33,5],
[56,42,33,6],
[56,42,33,7],
[56,42,33,8],
[56,42,33,13],
[56,42,33,14],
[56,42,33,16],
[56,42,33,17],
[56,42,34,0],
[56,42,34,1],
[56,42,34,2],
[56,42,34,3],
[56,42,34,4],
[56,42,34,5],
[56,42,34,6],
[56,42,34,8],
[56,42,34,11],
[56,42,34,12],
[56,42,34,13],
[56,42,34,14],
[56,42,34,16],
[56,42,34,17],
[56,42,36,0],
[56,42,36,1],
[56,42,36,2],
[56,42,36,3],
[56,42,36,4],
[56,42,36,5],
[56,42,36,6],
[56,42,36,7],
[56,42,36,8],
[56,42,36,11],
[56,42,36,12],
[56,42,37,0],
[56,42,37,1],
[56,42,37,2],
[56,42,37,3],
[56,42,37,5],
[56,42,37,6],
[56,42,37,7],
[56,42,37,8],
[56,42,37,11],
[56,42,37,12],
[56,42,37,13],
[56,42,38,0],
[56,42,38,1],
[56,42,38,2],
[56,42,38,3],
[56,42,38,4],
[56,42,38,5],
[56,42,38,6],
[56,42,38,8],
[56,42,38,11],
[56,42,39,0],
[56,42,39,1],
[56,42,39,2],
[56,42,39,4],
[56,42,39,7],
[56,43,3,0],
[56,43,3,1],
[56,43,3,2],
[56,43,4,0],
[56,43,4,1],
[56,43,4,2],
[56,43,4,3],
[56,43,5,0],
[56,43,5,1],
[56,43,5,2],
[56,43,5,4],
[56,43,6,0],
[56,43,6,1],
[56,43,6,2],
[56,43,6,4],
[56,43,8,0],
[56,43,8,1],
[56,43,8,2],
[56,43,8,4],
[56,43,10,0],
[56,43,10,1],
[56,43,10,2],
[56,43,10,3],
[56,43,10,4],
[56,43,10,5],
[56,43,10,6],
[56,43,10,8],
[56,43,11,0],
[56,43,11,1],
[56,43,11,2],
[56,43,11,3],
[56,43,11,4],
[56,43,11,5],
[56,43,11,6],
[56,43,11,8],
[56,43,11,10],
[56,43,12,0],
[56,43,12,1],
[56,43,12,2],
[56,43,12,3],
[56,43,12,4],
[56,43,12,5],
[56,43,12,6],
[56,43,12,8],
[56,43,12,10],
[56,43,13,0],
[56,43,13,1],
[56,43,13,2],
[56,43,13,3],
[56,43,13,4],
[56,43,13,5],
[56,43,13,6],
[56,43,13,8],
[56,43,13,10],
[56,43,13,11],
[56,43,13,12],
[56,43,14,0],
[56,43,14,1],
[56,43,14,2],
[56,43,14,3],
[56,43,14,4],
[56,43,14,5],
[56,43,14,6],
[56,43,14,8],
[56,43,14,10],
[56,43,14,11],
[56,43,14,12],
[56,43,16,0],
[56,43,16,1],
[56,43,16,2],
[56,43,16,3],
[56,43,16,4],
[56,43,16,5],
[56,43,16,6],
[56,43,16,8],
[56,43,16,10],
[56,43,16,11],
[56,43,16,12],
[56,43,16,13],
[56,43,16,14],
[56,43,17,0],
[56,43,17,1],
[56,43,17,2],
[56,43,17,3],
[56,43,17,4],
[56,43,17,5],
[56,43,17,6],
[56,43,17,8],
[56,43,17,10],
[56,43,17,11],
[56,43,17,12],
[56,43,17,13],
[56,43,17,14],
[56,43,17,16],
[56,43,19,0],
[56,43,19,1],
[56,43,19,2],
[56,43,19,3],
[56,43,19,4],
[56,43,19,5],
[56,43,19,6],
[56,43,19,8],
[56,43,19,10],
[56,43,19,11],
[56,43,19,12],
[56,43,19,16],
[56,43,19,17],
[56,43,20,0],
[56,43,20,1],
[56,43,20,2],
[56,43,20,3],
[56,43,20,4],
[56,43,20,5],
[56,43,20,6],
[56,43,20,8],
[56,43,20,11],
[56,43,20,12],
[56,43,20,13],
[56,43,20,14],
[56,43,20,16],
[56,43,20,17],
[56,43,20,19],
[56,43,21,0],
[56,43,21,1],
[56,43,21,2],
[56,43,21,4],
[56,43,21,10],
[56,43,21,11],
[56,43,21,12],
[56,43,21,13],
[56,43,21,14],
[56,43,21,19],
[56,43,21,20],
[56,43,22,0],
[56,43,22,1],
[56,43,22,2],
[56,43,22,3],
[56,43,22,4],
[56,43,22,5],
[56,43,22,6],
[56,43,22,8],
[56,43,22,11],
[56,43,22,12],
[56,43,22,13],
[56,43,22,14],
[56,43,22,16],
[56,43,22,17],
[56,43,22,19],
[56,43,22,21],
[56,43,23,0],
[56,43,23,1],
[56,43,23,2],
[56,43,23,3],
[56,43,23,4],
[56,43,23,5],
[56,43,23,6],
[56,43,23,8],
[56,43,23,10],
[56,43,23,11],
[56,43,23,12],
[56,43,23,13],
[56,43,23,16],
[56,43,23,17],
[56,43,23,19],
[56,43,23,20],
[56,43,23,21],
[56,43,23,22],
[56,43,24,0],
[56,43,24,1],
[56,43,24,2],
[56,43,24,3],
[56,43,24,4],
[56,43,24,5],
[56,43,24,6],
[56,43,24,8],
[56,43,24,10],
[56,43,24,11],
[56,43,24,12],
[56,43,24,13],
[56,43,24,17],
[56,43,24,19],
[56,43,24,20],
[56,43,24,21],
[56,43,24,22],
[56,43,24,23],
[56,43,27,0],
[56,43,27,1],
[56,43,27,2],
[56,43,27,3],
[56,43,27,4],
[56,43,27,5],
[56,43,27,8],
[56,43,27,11],
[56,43,27,12],
[56,43,27,13],
[56,43,27,14],
[56,43,27,16],
[56,43,27,17],
[56,43,27,19],
[56,43,27,21],
[56,43,27,23],
[56,43,27,24],
[56,43,29,0],
[56,43,29,1],
[56,43,29,2],
[56,43,29,5],
[56,43,29,6],
[56,43,29,8],
[56,43,29,10],
[56,43,29,13],
[56,43,29,14],
[56,43,29,16],
[56,43,29,17],
[56,43,29,19],
[56,43,29,20],
[56,43,29,21],
[56,43,30,3],
[56,43,30,4],
[56,43,30,5],
[56,43,30,6],
[56,43,30,8],
[56,43,30,10],
[56,43,30,11],
[56,43,30,12],
[56,43,30,13],
[56,43,30,14],
[56,43,30,16],
[56,43,30,17],
[56,43,30,19],
[56,43,30,20],
[56,43,31,0],
[56,43,31,1],
[56,43,31,2],
[56,43,31,3],
[56,43,31,4],
[56,43,31,5],
[56,43,31,6],
[56,43,31,8],
[56,43,31,10],
[56,43,31,11],
[56,43,31,12],
[56,43,31,13],
[56,43,31,14],
[56,43,31,16],
[56,43,31,17],
[56,43,31,19],
[56,43,32,0],
[56,43,32,1],
[56,43,32,2],
[56,43,32,3],
[56,43,32,4],
[56,43,32,5],
[56,43,32,6],
[56,43,32,8],
[56,43,32,10],
[56,43,32,13],
[56,43,32,14],
[56,43,32,16],
[56,43,32,17],
[56,43,33,0],
[56,43,33,1],
[56,43,33,2],
[56,43,33,3],
[56,43,33,4],
[56,43,33,5],
[56,43,33,6],
[56,43,33,8],
[56,43,33,10],
[56,43,33,13],
[56,43,33,14],
[56,43,33,16],
[56,43,33,17],
[56,43,36,0],
[56,43,36,1],
[56,43,36,2],
[56,43,36,3],
[56,43,36,4],
[56,43,36,5],
[56,43,36,6],
[56,43,36,8],
[56,43,36,10],
[56,43,36,11],
[56,43,36,12],
[56,43,37,0],
[56,43,37,1],
[56,43,37,2],
[56,43,37,3],
[56,43,37,5],
[56,43,37,6],
[56,43,37,8],
[56,43,37,10],
[56,43,37,11],
[56,43,39,0],
[56,43,39,1],
[56,43,39,2],
[56,43,39,4],
[56,43,42,0],
[56,43,42,1],
[56,43,42,2],
[56,43,42,3],
[56,43,42,4],
[56,44,3,0],
[56,44,3,1],
[56,44,3,2],
[56,44,4,0],
[56,44,4,1],
[56,44,4,2],
[56,44,4,3],
[56,44,5,0],
[56,44,5,1],
[56,44,5,2],
[56,44,5,4],
[56,44,6,0],
[56,44,6,1],
[56,44,6,2],
[56,44,6,4],
[56,44,7,0],
[56,44,7,1],
[56,44,7,2],
[56,44,7,3],
[56,44,7,4],
[56,44,7,5],
[56,44,7,6],
[56,44,8,0],
[56,44,8,1],
[56,44,8,2],
[56,44,8,4],
[56,44,8,7],
[56,44,10,0],
[56,44,10,1],
[56,44,10,2],
[56,44,10,3],
[56,44,10,4],
[56,44,10,5],
[56,44,10,6],
[56,44,10,7],
[56,44,10,8],
[56,44,11,0],
[56,44,11,1],
[56,44,11,2],
[56,44,11,3],
[56,44,11,4],
[56,44,11,5],
[56,44,11,6],
[56,44,11,7],
[56,44,11,8],
[56,44,11,10],
[56,44,12,0],
[56,44,12,1],
[56,44,12,2],
[56,44,12,3],
[56,44,12,4],
[56,44,12,5],
[56,44,12,6],
[56,44,12,7],
[56,44,12,8],
[56,44,12,10],
[56,44,13,0],
[56,44,13,1],
[56,44,13,2],
[56,44,13,3],
[56,44,13,4],
[56,44,13,5],
[56,44,13,6],
[56,44,13,7],
[56,44,13,8],
[56,44,13,10],
[56,44,13,11],
[56,44,13,12],
[56,44,14,0],
[56,44,14,1],
[56,44,14,2],
[56,44,14,3],
[56,44,14,4],
[56,44,14,5],
[56,44,14,6],
[56,44,14,7],
[56,44,14,8],
[56,44,14,10],
[56,44,14,11],
[56,44,14,12],
[56,44,16,0],
[56,44,16,1],
[56,44,16,2],
[56,44,16,3],
[56,44,16,4],
[56,44,16,5],
[56,44,16,6],
[56,44,16,7],
[56,44,16,8],
[56,44,16,10],
[56,44,16,11],
[56,44,16,12],
[56,44,16,13],
[56,44,16,14],
[56,44,19,0],
[56,44,19,1],
[56,44,19,2],
[56,44,19,3],
[56,44,19,4],
[56,44,19,5],
[56,44,19,6],
[56,44,19,7],
[56,44,19,8],
[56,44,19,10],
[56,44,19,11],
[56,44,19,12],
[56,44,19,16],
[56,44,20,0],
[56,44,20,1],
[56,44,20,2],
[56,44,20,3],
[56,44,20,4],
[56,44,20,5],
[56,44,20,6],
[56,44,20,7],
[56,44,20,8],
[56,44,20,11],
[56,44,20,12],
[56,44,20,13],
[56,44,20,14],
[56,44,20,19],
[56,44,21,0],
[56,44,21,1],
[56,44,21,2],
[56,44,21,4],
[56,44,21,7],
[56,44,21,10],
[56,44,21,11],
[56,44,21,12],
[56,44,21,13],
[56,44,21,14],
[56,44,21,16],
[56,44,21,19],
[56,44,21,20],
[56,44,22,0],
[56,44,22,1],
[56,44,22,2],
[56,44,22,3],
[56,44,22,4],
[56,44,22,5],
[56,44,22,6],
[56,44,22,7],
[56,44,22,8],
[56,44,22,11],
[56,44,22,12],
[56,44,22,13],
[56,44,22,16],
[56,44,22,19],
[56,44,22,21],
[56,44,23,0],
[56,44,23,1],
[56,44,23,2],
[56,44,23,3],
[56,44,23,4],
[56,44,23,5],
[56,44,23,6],
[56,44,23,7],
[56,44,23,8],
[56,44,23,10],
[56,44,23,11],
[56,44,23,14],
[56,44,23,16],
[56,44,23,19],
[56,44,23,20],
[56,44,23,21],
[56,44,23,22],
[56,44,24,0],
[56,44,24,1],
[56,44,24,2],
[56,44,24,3],
[56,44,24,4],
[56,44,24,5],
[56,44,24,6],
[56,44,24,7],
[56,44,24,8],
[56,44,24,10],
[56,44,24,11],
[56,44,24,14],
[56,44,24,19],
[56,44,24,20],
[56,44,24,21],
[56,44,24,22],
[56,44,24,23],
[56,44,25,0],
[56,44,25,1],
[56,44,25,2],
[56,44,25,3],
[56,44,25,4],
[56,44,25,5],
[56,44,25,6],
[56,44,25,10],
[56,44,25,11],
[56,44,25,12],
[56,44,25,13],
[56,44,25,14],
[56,44,25,16],
[56,44,25,19],
[56,44,25,20],
[56,44,25,21],
[56,44,25,22],
[56,44,25,23],
[56,44,25,24],
[56,44,27,0],
[56,44,27,1],
[56,44,27,2],
[56,44,27,3],
[56,44,27,4],
[56,44,27,6],
[56,44,27,7],
[56,44,27,8],
[56,44,27,11],
[56,44,27,12],
[56,44,27,13],
[56,44,27,14],
[56,44,27,16],
[56,44,27,19],
[56,44,27,21],
[56,44,28,0],
[56,44,28,1],
[56,44,28,2],
[56,44,28,5],
[56,44,28,6],
[56,44,28,8],
[56,44,28,10],
[56,44,28,11],
[56,44,28,12],
[56,44,28,13],
[56,44,28,14],
[56,44,28,16],
[56,44,28,19],
[56,44,28,20],
[56,44,28,21],
[56,44,29,3],
[56,44,29,4],
[56,44,29,5],
[56,44,29,6],
[56,44,29,7],
[56,44,29,8],
[56,44,29,10],
[56,44,29,13],
[56,44,29,14],
[56,44,29,16],
[56,44,29,19],
[56,44,29,20],
[56,44,30,0],
[56,44,30,1],
[56,44,30,2],
[56,44,30,3],
[56,44,30,4],
[56,44,30,5],
[56,44,30,6],
[56,44,30,7],
[56,44,30,8],
[56,44,30,10],
[56,44,30,11],
[56,44,30,12],
[56,44,30,13],
[56,44,30,14],
[56,44,30,16],
[56,44,30,19],
[56,44,31,0],
[56,44,31,1],
[56,44,31,2],
[56,44,31,3],
[56,44,31,4],
[56,44,31,5],
[56,44,31,6],
[56,44,31,7],
[56,44,31,8],
[56,44,31,10],
[56,44,31,11],
[56,44,31,12],
[56,44,31,13],
[56,44,31,14],
[56,44,31,16],
[56,44,32,0],
[56,44,32,1],
[56,44,32,2],
[56,44,32,3],
[56,44,32,4],
[56,44,32,5],
[56,44,32,6],
[56,44,32,7],
[56,44,32,8],
[56,44,32,10],
[56,44,32,13],
[56,44,32,14],
[56,44,32,16],
[56,44,33,0],
[56,44,33,1],
[56,44,33,2],
[56,44,33,3],
[56,44,33,4],
[56,44,33,5],
[56,44,33,6],
[56,44,33,7],
[56,44,33,8],
[56,44,33,10],
[56,44,33,13],
[56,44,33,14],
[56,44,34,0],
[56,44,34,1],
[56,44,34,2],
[56,44,34,3],
[56,44,34,4],
[56,44,34,5],
[56,44,34,6],
[56,44,34,8],
[56,44,34,10],
[56,44,34,11],
[56,44,34,12],
[56,44,34,13],
[56,44,34,14],
[56,44,36,0],
[56,44,36,1],
[56,44,36,2],
[56,44,36,3],
[56,44,36,4],
[56,44,36,5],
[56,44,36,6],
[56,44,36,7],
[56,44,36,8],
[56,44,36,10],
[56,44,36,11],
[56,44,37,0],
[56,44,37,1],
[56,44,37,2],
[56,44,37,3],
[56,44,37,5],
[56,44,37,6],
[56,44,37,7],
[56,44,37,8],
[56,44,38,0],
[56,44,38,1],
[56,44,38,2],
[56,44,38,3],
[56,44,38,4],
[56,44,38,5],
[56,44,38,6],
[56,44,39,0],
[56,44,39,1],
[56,44,39,2],
[56,44,39,4],
[56,44,42,0],
[56,44,42,1],
[56,44,42,2],
[56,47,3,0],
[56,47,3,1],
[56,47,3,2],
[56,47,4,0],
[56,47,4,1],
[56,47,4,2],
[56,47,4,3],
[56,47,5,0],
[56,47,5,1],
[56,47,5,2],
[56,47,5,4],
[56,47,6,0],
[56,47,6,1],
[56,47,6,2],
[56,47,6,4],
[56,47,7,0],
[56,47,7,1],
[56,47,7,2],
[56,47,7,3],
[56,47,7,4],
[56,47,7,5],
[56,47,7,6],
[56,47,8,0],
[56,47,8,1],
[56,47,8,2],
[56,47,8,4],
[56,47,8,7],
[56,47,10,0],
[56,47,10,1],
[56,47,10,2],
[56,47,10,3],
[56,47,10,4],
[56,47,10,5],
[56,47,10,6],
[56,47,10,7],
[56,47,10,8],
[56,47,11,0],
[56,47,11,1],
[56,47,11,2],
[56,47,11,3],
[56,47,11,4],
[56,47,11,5],
[56,47,11,6],
[56,47,11,7],
[56,47,11,8],
[56,47,11,10],
[56,47,12,0],
[56,47,12,1],
[56,47,12,2],
[56,47,12,3],
[56,47,12,4],
[56,47,12,5],
[56,47,12,6],
[56,47,12,7],
[56,47,12,8],
[56,47,12,10],
[56,47,13,0],
[56,47,13,1],
[56,47,13,2],
[56,47,13,3],
[56,47,13,4],
[56,47,13,5],
[56,47,13,6],
[56,47,13,7],
[56,47,13,8],
[56,47,13,10],
[56,47,13,11],
[56,47,13,12],
[56,47,14,0],
[56,47,14,1],
[56,47,14,2],
[56,47,14,3],
[56,47,14,4],
[56,47,14,5],
[56,47,14,6],
[56,47,14,7],
[56,47,14,8],
[56,47,14,10],
[56,47,14,11],
[56,47,14,12],
[56,47,16,0],
[56,47,16,1],
[56,47,16,2],
[56,47,16,3],
[56,47,16,4],
[56,47,16,5],
[56,47,16,6],
[56,47,16,7],
[56,47,16,8],
[56,47,16,10],
[56,47,16,11],
[56,47,16,12],
[56,47,16,13],
[56,47,16,14],
[56,47,17,0],
[56,47,17,1],
[56,47,17,2],
[56,47,17,3],
[56,47,17,4],
[56,47,17,5],
[56,47,17,6],
[56,47,17,7],
[56,47,17,8],
[56,47,17,10],
[56,47,17,11],
[56,47,17,12],
[56,47,17,13],
[56,47,17,14],
[56,47,19,0],
[56,47,19,1],
[56,47,19,2],
[56,47,19,3],
[56,47,19,4],
[56,47,19,5],
[56,47,19,6],
[56,47,19,7],
[56,47,19,8],
[56,47,19,10],
[56,47,19,11],
[56,47,19,12],
[56,47,19,16],
[56,47,19,17],
[56,47,20,0],
[56,47,20,1],
[56,47,20,2],
[56,47,20,3],
[56,47,20,4],
[56,47,20,5],
[56,47,20,6],
[56,47,20,7],
[56,47,20,8],
[56,47,20,11],
[56,47,20,14],
[56,47,20,16],
[56,47,20,17],
[56,47,20,19],
[56,47,21,0],
[56,47,21,1],
[56,47,21,2],
[56,47,21,4],
[56,47,21,7],
[56,47,21,12],
[56,47,21,13],
[56,47,21,14],
[56,47,21,16],
[56,47,21,17],
[56,47,21,19],
[56,47,21,20],
[56,47,22,0],
[56,47,22,1],
[56,47,22,2],
[56,47,22,3],
[56,47,22,4],
[56,47,22,5],
[56,47,22,6],
[56,47,22,7],
[56,47,22,11],
[56,47,22,12],
[56,47,22,13],
[56,47,22,14],
[56,47,22,16],
[56,47,22,17],
[56,47,22,19],
[56,47,22,21],
[56,47,24,0],
[56,47,24,1],
[56,47,24,2],
[56,47,24,3],
[56,47,24,4],
[56,47,24,5],
[56,47,24,8],
[56,47,24,10],
[56,47,24,11],
[56,47,24,12],
[56,47,24,13],
[56,47,24,14],
[56,47,24,17],
[56,47,24,19],
[56,47,24,20],
[56,47,24,21],
[56,47,24,22],
[56,47,25,0],
[56,47,25,1],
[56,47,25,2],
[56,47,25,5],
[56,47,25,6],
[56,47,25,8],
[56,47,25,10],
[56,47,25,11],
[56,47,25,12],
[56,47,25,13],
[56,47,25,14],
[56,47,25,16],
[56,47,25,17],
[56,47,25,19],
[56,47,25,20],
[56,47,25,21],
[56,47,27,0],
[56,47,27,1],
[56,47,27,2],
[56,47,27,3],
[56,47,27,4],
[56,47,27,5],
[56,47,27,6],
[56,47,27,7],
[56,47,27,8],
[56,47,27,11],
[56,47,27,12],
[56,47,27,13],
[56,47,27,14],
[56,47,27,16],
[56,47,27,17],
[56,47,27,19],
[56,47,28,0],
[56,47,28,1],
[56,47,28,2],
[56,47,28,3],
[56,47,28,4],
[56,47,28,5],
[56,47,28,6],
[56,47,28,8],
[56,47,28,10],
[56,47,28,11],
[56,47,28,12],
[56,47,28,13],
[56,47,28,14],
[56,47,28,16],
[56,47,28,17],
[56,47,29,0],
[56,47,29,1],
[56,47,29,2],
[56,47,29,3],
[56,47,29,4],
[56,47,29,5],
[56,47,29,6],
[56,47,29,7],
[56,47,29,8],
[56,47,29,10],
[56,47,29,13],
[56,47,29,14],
[56,47,29,16],
[56,47,29,17],
[56,47,32,0],
[56,47,32,1],
[56,47,32,2],
[56,47,32,3],
[56,47,32,4],
[56,47,32,5],
[56,47,32,6],
[56,47,32,7],
[56,47,32,8],
[56,47,32,10],
[56,47,32,13],
[56,47,33,0],
[56,47,33,1],
[56,47,33,2],
[56,47,33,3],
[56,47,33,4],
[56,47,33,5],
[56,47,33,6],
[56,47,33,7],
[56,47,33,8],
[56,47,33,10],
[56,47,34,0],
[56,47,34,1],
[56,47,34,2],
[56,47,34,3],
[56,47,34,4],
[56,47,34,5],
[56,47,34,6],
[56,47,34,8],
[56,47,36,0],
[56,47,36,1],
[56,47,36,2],
[56,47,36,3],
[56,47,36,4],
[56,47,36,5],
[56,47,37,0],
[56,47,37,1],
[56,47,37,2],
[56,47,37,3],
[56,47,38,0],
[56,47,38,1],
[56,47,38,2],
[56,48,3,0],
[56,48,3,1],
[56,48,3,2],
[56,48,4,0],
[56,48,4,1],
[56,48,4,2],
[56,48,4,3],
[56,48,5,0],
[56,48,5,1],
[56,48,5,2],
[56,48,5,4],
[56,48,6,0],
[56,48,6,1],
[56,48,6,2],
[56,48,6,4],
[56,48,7,0],
[56,48,7,1],
[56,48,7,2],
[56,48,7,3],
[56,48,7,4],
[56,48,7,5],
[56,48,7,6],
[56,48,8,0],
[56,48,8,1],
[56,48,8,2],
[56,48,8,4],
[56,48,8,7],
[56,48,10,0],
[56,48,10,1],
[56,48,10,2],
[56,48,10,3],
[56,48,10,4],
[56,48,10,5],
[56,48,10,6],
[56,48,10,7],
[56,48,10,8],
[56,48,13,0],
[56,48,13,1],
[56,48,13,2],
[56,48,13,3],
[56,48,13,4],
[56,48,13,5],
[56,48,13,6],
[56,48,13,7],
[56,48,13,8],
[56,48,13,10],
[56,48,14,0],
[56,48,14,1],
[56,48,14,2],
[56,48,14,3],
[56,48,14,4],
[56,48,14,5],
[56,48,14,6],
[56,48,14,7],
[56,48,14,8],
[56,48,14,10],
[56,48,16,0],
[56,48,16,1],
[56,48,16,2],
[56,48,16,3],
[56,48,16,4],
[56,48,16,5],
[56,48,16,6],
[56,48,16,7],
[56,48,16,8],
[56,48,16,10],
[56,48,16,13],
[56,48,16,14],
[56,48,17,0],
[56,48,17,1],
[56,48,17,2],
[56,48,17,3],
[56,48,17,4],
[56,48,17,5],
[56,48,17,6],
[56,48,17,7],
[56,48,17,8],
[56,48,17,10],
[56,48,17,13],
[56,48,17,14],
[56,48,17,16],
[56,48,19,0],
[56,48,19,1],
[56,48,19,2],
[56,48,19,3],
[56,48,19,4],
[56,48,19,5],
[56,48,19,6],
[56,48,19,7],
[56,48,19,8],
[56,48,19,10],
[56,48,19,16],
[56,48,19,17],
[56,48,20,0],
[56,48,20,1],
[56,48,20,2],
[56,48,20,3],
[56,48,20,4],
[56,48,20,5],
[56,48,20,6],
[56,48,20,7],
[56,48,20,8],
[56,48,20,13],
[56,48,20,14],
[56,48,20,16],
[56,48,20,17],
[56,48,20,19],
[56,48,21,0],
[56,48,21,1],
[56,48,21,2],
[56,48,21,4],
[56,48,21,7],
[56,48,21,10],
[56,48,21,13],
[56,48,21,14],
[56,48,21,16],
[56,48,21,17],
[56,48,21,19],
[56,48,21,20],
[56,48,22,0],
[56,48,22,1],
[56,48,22,2],
[56,48,22,3],
[56,48,22,4],
[56,48,22,5],
[56,48,22,8],
[56,48,22,13],
[56,48,22,14],
[56,48,22,16],
[56,48,22,17],
[56,48,22,19],
[56,48,22,21],
[56,48,23,0],
[56,48,23,1],
[56,48,23,2],
[56,48,23,3],
[56,48,23,4],
[56,48,23,6],
[56,48,23,7],
[56,48,23,8],
[56,48,23,10],
[56,48,23,13],
[56,48,23,14],
[56,48,23,16],
[56,48,23,17],
[56,48,23,19],
[56,48,23,20],
[56,48,23,21],
[56,48,23,22],
[56,48,24,0],
[56,48,24,1],
[56,48,24,2],
[56,48,24,3],
[56,48,24,4],
[56,48,24,6],
[56,48,24,7],
[56,48,24,8],
[56,48,24,10],
[56,48,24,13],
[56,48,24,14],
[56,48,24,17],
[56,48,24,19],
[56,48,24,20],
[56,48,24,21],
[56,48,24,22],
[56,48,25,3],
[56,48,25,4],
[56,48,25,5],
[56,48,25,6],
[56,48,25,8],
[56,48,25,10],
[56,48,25,13],
[56,48,25,14],
[56,48,25,16],
[56,48,25,17],
[56,48,25,19],
[56,48,25,20],
[56,48,27,0],
[56,48,27,1],
[56,48,27,2],
[56,48,27,3],
[56,48,27,4],
[56,48,27,5],
[56,48,27,6],
[56,48,27,7],
[56,48,27,8],
[56,48,27,13],
[56,48,27,14],
[56,48,27,16],
[56,48,27,17],
[56,48,28,0],
[56,48,28,1],
[56,48,28,2],
[56,48,28,3],
[56,48,28,4],
[56,48,28,5],
[56,48,28,6],
[56,48,28,8],
[56,48,28,10],
[56,48,28,13],
[56,48,28,14],
[56,48,28,16],
[56,48,28,17],
[56,48,30,0],
[56,48,30,1],
[56,48,30,2],
[56,48,30,3],
[56,48,30,4],
[56,48,30,5],
[56,48,30,6],
[56,48,30,7],
[56,48,30,8],
[56,48,30,10],
[56,48,30,13],
[56,48,30,14],
[56,48,31,0],
[56,48,31,1],
[56,48,31,2],
[56,48,31,3],
[56,48,31,4],
[56,48,31,5],
[56,48,31,6],
[56,48,31,7],
[56,48,31,8],
[56,48,31,10],
[56,48,31,13],
[56,48,34,0],
[56,48,34,1],
[56,48,34,2],
[56,48,34,3],
[56,48,34,4],
[56,48,34,5],
[56,48,34,6],
[56,48,36,0],
[56,48,36,1],
[56,48,36,2],
[56,48,36,3],
[56,48,36,4],
[56,48,37,0],
[56,48,37,1],
[56,48,37,2],
[56,51,4,0],
[56,51,4,1],
[56,51,4,2],
[56,51,7,0],
[56,51,7,1],
[56,51,7,2],
[56,51,7,4],
[56,51,10,0],
[56,51,10,1],
[56,51,10,2],
[56,51,10,4],
[56,51,10,7],
[56,51,11,0],
[56,51,11,1],
[56,51,11,2],
[56,51,11,4],
[56,51,11,7],
[56,51,11,10],
[56,51,12,0],
[56,51,12,1],
[56,51,12,2],
[56,51,12,4],
[56,51,12,7],
[56,51,12,10],
[56,51,13,0],
[56,51,13,1],
[56,51,13,2],
[56,51,13,4],
[56,51,13,7],
[56,51,13,10],
[56,51,13,11],
[56,51,13,12],
[56,51,14,0],
[56,51,14,1],
[56,51,14,2],
[56,51,14,4],
[56,51,14,7],
[56,51,14,10],
[56,51,14,11],
[56,51,14,12],
[56,51,16,0],
[56,51,16,1],
[56,51,16,2],
[56,51,16,4],
[56,51,16,7],
[56,51,16,10],
[56,51,16,11],
[56,51,16,12],
[56,51,16,13],
[56,51,16,14],
[56,51,17,0],
[56,51,17,1],
[56,51,17,2],
[56,51,17,4],
[56,51,17,7],
[56,51,17,10],
[56,51,17,11],
[56,51,17,12],
[56,51,17,13],
[56,51,17,14],
[56,51,17,16],
[56,51,19,0],
[56,51,19,1],
[56,51,19,2],
[56,51,19,4],
[56,51,19,7],
[56,51,19,10],
[56,51,19,11],
[56,51,19,16],
[56,51,19,17],
[56,51,20,0],
[56,51,20,1],
[56,51,20,2],
[56,51,20,4],
[56,51,20,7],
[56,51,20,12],
[56,51,20,13],
[56,51,20,14],
[56,51,20,16],
[56,51,20,17],
[56,51,20,19],
[56,51,22,0],
[56,51,22,1],
[56,51,22,2],
[56,51,22,4],
[56,51,22,11],
[56,51,22,12],
[56,51,22,13],
[56,51,22,14],
[56,51,22,16],
[56,51,22,17],
[56,51,22,19],
[56,51,23,0],
[56,51,23,1],
[56,51,23,2],
[56,51,23,4],
[56,51,23,7],
[56,51,23,10],
[56,51,23,11],
[56,51,23,12],
[56,51,23,13],
[56,51,23,14],
[56,51,23,16],
[56,51,23,17],
[56,51,23,19],
[56,51,23,20],
[56,51,23,22],
[56,51,24,0],
[56,51,24,1],
[56,51,24,2],
[56,51,24,4],
[56,51,24,7],
[56,51,24,10],
[56,51,24,11],
[56,51,24,12],
[56,51,24,13],
[56,51,24,14],
[56,51,24,17],
[56,51,24,19],
[56,51,24,20],
[56,51,24,22],
[56,51,25,4],
[56,51,25,10],
[56,51,25,11],
[56,51,25,12],
[56,51,25,13],
[56,51,25,14],
[56,51,25,16],
[56,51,25,17],
[56,51,25,19],
[56,51,25,20],
[56,51,27,0],
[56,51,27,1],
[56,51,27,2],
[56,51,27,4],
[56,51,27,7],
[56,51,27,11],
[56,51,27,12],
[56,51,27,13],
[56,51,27,14],
[56,51,27,16],
[56,51,27,17],
[56,51,28,0],
[56,51,28,1],
[56,51,28,2],
[56,51,28,4],
[56,51,28,10],
[56,51,28,11],
[56,51,28,12],
[56,51,28,13],
[56,51,28,14],
[56,51,28,16],
[56,51,28,17],
[56,51,29,0],
[56,51,29,1],
[56,51,29,2],
[56,51,29,4],
[56,51,29,7],
[56,51,29,10],
[56,51,29,13],
[56,51,29,14],
[56,51,30,0],
[56,51,30,1],
[56,51,30,2],
[56,51,30,4],
[56,51,30,7],
[56,51,30,10],
[56,51,30,11],
[56,51,30,12],
[56,51,30,13],
[56,51,30,14],
[56,51,31,0],
[56,51,31,1],
[56,51,31,2],
[56,51,31,4],
[56,51,31,7],
[56,51,31,10],
[56,51,31,11],
[56,51,31,12],
[56,51,31,13],
[56,51,32,0],
[56,51,32,1],
[56,51,32,2],
[56,51,32,4],
[56,51,32,7],
[56,51,32,10],
[56,51,33,0],
[56,51,33,1],
[56,51,33,2],
[56,51,33,4],
[56,51,33,7],
[56,51,34,0],
[56,51,34,1],
[56,51,34,2],
[56,51,34,4],
[56,51,36,0],
[56,51,36,1],
[56,51,36,2],
[56,51,36,4],
[56,51,37,0],
[56,51,37,1],
[56,51,37,2],
[56,53,3,0],
[56,53,3,1],
[56,53,3,2],
[56,53,4,0],
[56,53,4,1],
[56,53,4,2],
[56,53,4,3],
[56,53,5,0],
[56,53,5,1],
[56,53,5,2],
[56,53,5,4],
[56,53,6,0],
[56,53,6,1],
[56,53,6,2],
[56,53,6,4],
[56,53,7,0],
[56,53,7,1],
[56,53,7,2],
[56,53,7,3],
[56,53,7,4],
[56,53,7,5],
[56,53,7,6],
[56,53,8,0],
[56,53,8,1],
[56,53,8,2],
[56,53,8,4],
[56,53,8,7],
[56,53,11,0],
[56,53,11,1],
[56,53,11,2],
[56,53,11,3],
[56,53,11,4],
[56,53,11,5],
[56,53,11,6],
[56,53,11,7],
[56,53,11,8],
[56,53,12,0],
[56,53,12,1],
[56,53,12,2],
[56,53,12,3],
[56,53,12,4],
[56,53,12,5],
[56,53,12,6],
[56,53,12,7],
[56,53,12,8],
[56,53,13,0],
[56,53,13,1],
[56,53,13,2],
[56,53,13,3],
[56,53,13,4],
[56,53,13,5],
[56,53,13,6],
[56,53,13,7],
[56,53,13,8],
[56,53,13,11],
[56,53,13,12],
[56,53,14,0],
[56,53,14,1],
[56,53,14,2],
[56,53,14,3],
[56,53,14,4],
[56,53,14,5],
[56,53,14,6],
[56,53,14,7],
[56,53,14,8],
[56,53,14,11],
[56,53,14,12],
[56,53,16,0],
[56,53,16,1],
[56,53,16,2],
[56,53,16,3],
[56,53,16,4],
[56,53,16,5],
[56,53,16,6],
[56,53,16,7],
[56,53,16,8],
[56,53,16,11],
[56,53,16,12],
[56,53,16,13],
[56,53,16,14],
[56,53,17,0],
[56,53,17,1],
[56,53,17,2],
[56,53,17,3],
[56,53,17,4],
[56,53,17,5],
[56,53,17,6],
[56,53,17,7],
[56,53,17,8],
[56,53,17,11],
[56,53,17,12],
[56,53,17,13],
[56,53,17,14],
[56,53,17,16],
[56,53,19,0],
[56,53,19,1],
[56,53,19,2],
[56,53,19,3],
[56,53,19,4],
[56,53,19,5],
[56,53,19,6],
[56,53,19,7],
[56,53,19,8],
[56,53,19,11],
[56,53,19,16],
[56,53,19,17],
[56,53,21,0],
[56,53,21,1],
[56,53,21,2],
[56,53,21,4],
[56,53,21,7],
[56,53,21,11],
[56,53,21,12],
[56,53,21,13],
[56,53,21,14],
[56,53,21,16],
[56,53,21,17],
[56,53,21,19],
[56,53,23,0],
[56,53,23,1],
[56,53,23,2],
[56,53,23,3],
[56,53,23,4],
[56,53,23,6],
[56,53,23,7],
[56,53,23,8],
[56,53,23,11],
[56,53,23,12],
[56,53,23,13],
[56,53,23,14],
[56,53,23,16],
[56,53,23,17],
[56,53,23,19],
[56,53,23,21],
[56,53,24,0],
[56,53,24,1],
[56,53,24,2],
[56,53,24,3],
[56,53,24,4],
[56,53,24,6],
[56,53,24,7],
[56,53,24,8],
[56,53,24,11],
[56,53,24,12],
[56,53,24,13],
[56,53,24,14],
[56,53,24,17],
[56,53,24,19],
[56,53,24,21],
[56,53,25,3],
[56,53,25,4],
[56,53,25,5],
[56,53,25,6],
[56,53,25,8],
[56,53,25,11],
[56,53,25,12],
[56,53,25,13],
[56,53,25,14],
[56,53,25,16],
[56,53,25,17],
[56,53,25,19],
[56,53,28,0],
[56,53,28,1],
[56,53,28,2],
[56,53,28,3],
[56,53,28,4],
[56,53,28,5],
[56,53,28,6],
[56,53,28,8],
[56,53,28,11],
[56,53,28,12],
[56,53,28,13],
[56,53,28,14],
[56,53,28,16],
[56,53,28,17],
[56,53,29,0],
[56,53,29,1],
[56,53,29,2],
[56,53,29,3],
[56,53,29,4],
[56,53,29,5],
[56,53,29,6],
[56,53,29,7],
[56,53,29,8],
[56,53,29,13],
[56,53,29,14],
[56,53,30,0],
[56,53,30,1],
[56,53,30,2],
[56,53,30,3],
[56,53,30,4],
[56,53,30,5],
[56,53,30,6],
[56,53,30,7],
[56,53,30,8],
[56,53,30,11],
[56,53,30,12],
[56,53,30,13],
[56,53,30,14],
[56,53,31,0],
[56,53,31,1],
[56,53,31,2],
[56,53,31,3],
[56,53,31,4],
[56,53,31,5],
[56,53,31,6],
[56,53,31,7],
[56,53,31,8],
[56,53,31,11],
[56,53,31,12],
[56,53,31,13],
[56,53,32,0],
[56,53,32,1],
[56,53,32,2],
[56,53,32,3],
[56,53,32,4],
[56,53,32,5],
[56,53,32,6],
[56,53,32,7],
[56,53,32,8],
[56,53,33,0],
[56,53,33,1],
[56,53,33,2],
[56,53,33,3],
[56,53,33,4],
[56,53,33,5],
[56,53,33,6],
[56,53,33,7],
[56,53,33,8],
[56,53,34,0],
[56,53,34,1],
[56,53,34,2],
[56,53,34,3],
[56,53,34,4],
[56,53,34,5],
[56,53,34,6],
[56,53,36,0],
[56,53,36,1],
[56,53,36,2],
[56,53,36,3],
[56,53,36,4],
[56,53,37,0],
[56,53,37,1],
[56,53,37,2],
[56,54,3,0],
[56,54,3,1],
[56,54,3,2],
[56,54,4,0],
[56,54,4,1],
[56,54,4,2],
[56,54,4,3],
[56,54,5,0],
[56,54,5,1],
[56,54,5,2],
[56,54,5,4],
[56,54,6,0],
[56,54,6,1],
[56,54,6,2],
[56,54,6,4],
[56,54,7,0],
[56,54,7,1],
[56,54,7,2],
[56,54,7,3],
[56,54,7,4],
[56,54,7,5],
[56,54,7,6],
[56,54,8,0],
[56,54,8,1],
[56,54,8,2],
[56,54,8,4],
[56,54,8,7],
[56,54,11,0],
[56,54,11,1],
[56,54,11,2],
[56,54,11,3],
[56,54,11,4],
[56,54,11,5],
[56,54,11,6],
[56,54,11,7],
[56,54,11,8],
[56,54,12,0],
[56,54,12,1],
[56,54,12,2],
[56,54,12,3],
[56,54,12,4],
[56,54,12,5],
[56,54,12,6],
[56,54,12,7],
[56,54,12,8],
[56,54,13,0],
[56,54,13,1],
[56,54,13,2],
[56,54,13,3],
[56,54,13,4],
[56,54,13,5],
[56,54,13,6],
[56,54,13,7],
[56,54,13,8],
[56,54,13,11],
[56,54,13,12],
[56,54,14,0],
[56,54,14,1],
[56,54,14,2],
[56,54,14,3],
[56,54,14,4],
[56,54,14,5],
[56,54,14,6],
[56,54,14,7],
[56,54,14,8],
[56,54,14,11],
[56,54,14,12],
[56,54,16,0],
[56,54,16,1],
[56,54,16,2],
[56,54,16,3],
[56,54,16,4],
[56,54,16,5],
[56,54,16,6],
[56,54,16,7],
[56,54,16,8],
[56,54,16,11],
[56,54,16,12],
[56,54,16,13],
[56,54,16,14],
[56,54,17,0],
[56,54,17,1],
[56,54,17,2],
[56,54,17,3],
[56,54,17,4],
[56,54,17,5],
[56,54,17,6],
[56,54,17,7],
[56,54,17,8],
[56,54,17,11],
[56,54,17,12],
[56,54,17,13],
[56,54,17,14],
[56,54,17,16],
[56,54,19,0],
[56,54,19,1],
[56,54,19,2],
[56,54,19,3],
[56,54,19,4],
[56,54,19,5],
[56,54,19,6],
[56,54,19,7],
[56,54,19,8],
[56,54,19,11],
[56,54,19,16],
[56,54,19,17],
[56,54,21,0],
[56,54,21,1],
[56,54,21,2],
[56,54,21,4],
[56,54,21,7],
[56,54,21,11],
[56,54,21,12],
[56,54,21,13],
[56,54,21,14],
[56,54,21,16],
[56,54,21,17],
[56,54,21,19],
[56,54,23,0],
[56,54,23,1],
[56,54,23,2],
[56,54,23,3],
[56,54,23,4],
[56,54,23,6],
[56,54,23,7],
[56,54,23,8],
[56,54,23,11],
[56,54,23,12],
[56,54,23,13],
[56,54,23,14],
[56,54,23,16],
[56,54,23,17],
[56,54,23,19],
[56,54,23,21],
[56,54,24,0],
[56,54,24,1],
[56,54,24,2],
[56,54,24,3],
[56,54,24,4],
[56,54,24,6],
[56,54,24,7],
[56,54,24,8],
[56,54,24,11],
[56,54,24,12],
[56,54,24,13],
[56,54,24,14],
[56,54,24,17],
[56,54,24,19],
[56,54,24,21],
[56,54,25,3],
[56,54,25,4],
[56,54,25,5],
[56,54,25,6],
[56,54,25,8],
[56,54,25,11],
[56,54,25,12],
[56,54,25,13],
[56,54,25,14],
[56,54,25,16],
[56,54,25,17],
[56,54,25,19],
[56,54,28,0],
[56,54,28,1],
[56,54,28,2],
[56,54,28,3],
[56,54,28,4],
[56,54,28,5],
[56,54,28,6],
[56,54,28,8],
[56,54,28,11],
[56,54,28,12],
[56,54,28,13],
[56,54,28,14],
[56,54,28,16],
[56,54,28,17],
[56,54,29,0],
[56,54,29,1],
[56,54,29,2],
[56,54,29,3],
[56,54,29,4],
[56,54,29,5],
[56,54,29,6],
[56,54,29,7],
[56,54,29,8],
[56,54,29,13],
[56,54,29,14],
[56,54,30,0],
[56,54,30,1],
[56,54,30,2],
[56,54,30,3],
[56,54,30,4],
[56,54,30,5],
[56,54,30,6],
[56,54,30,7],
[56,54,30,8],
[56,54,30,11],
[56,54,30,12],
[56,54,30,13],
[56,54,30,14],
[56,54,31,0],
[56,54,31,1],
[56,54,31,2],
[56,54,31,3],
[56,54,31,4],
[56,54,31,5],
[56,54,31,6],
[56,54,31,7],
[56,54,31,8],
[56,54,31,11],
[56,54,31,12],
[56,54,31,13],
[56,54,32,0],
[56,54,32,1],
[56,54,32,2],
[56,54,32,3],
[56,54,32,4],
[56,54,32,5],
[56,54,32,6],
[56,54,32,7],
[56,54,32,8],
[56,54,33,0],
[56,54,33,1],
[56,54,33,2],
[56,54,33,3],
[56,54,33,4],
[56,54,33,5],
[56,54,33,6],
[56,54,33,7],
[56,54,33,8],
[56,54,34,0],
[56,54,34,1],
[56,54,34,2],
[56,54,34,3],
[56,54,34,4],
[56,54,34,5],
[56,54,34,6],
[56,54,36,0],
[56,54,36,1],
[56,54,36,2],
[56,54,36,3],
[56,54,36,4],
[56,54,37,0],
[56,54,37,1],
[56,54,37,2],
[56,55,3,0],
[56,55,3,1],
[56,55,3,2],
[56,55,4,0],
[56,55,4,1],
[56,55,4,2],
[56,55,4,3],
[56,55,5,0],
[56,55,5,1],
[56,55,5,2],
[56,55,5,4],
[56,55,6,0],
[56,55,6,1],
[56,55,6,2],
[56,55,6,4],
[56,55,7,0],
[56,55,7,1],
[56,55,7,2],
[56,55,7,3],
[56,55,7,4],
[56,55,7,5],
[56,55,7,6],
[56,55,8,0],
[56,55,8,1],
[56,55,8,2],
[56,55,8,4],
[56,55,8,7],
[56,55,11,0],
[56,55,11,1],
[56,55,11,2],
[56,55,11,3],
[56,55,11,4],
[56,55,11,5],
[56,55,11,6],
[56,55,11,7],
[56,55,11,8],
[56,55,12,0],
[56,55,12,1],
[56,55,12,2],
[56,55,12,3],
[56,55,12,4],
[56,55,12,5],
[56,55,12,6],
[56,55,12,7],
[56,55,12,8],
[56,55,13,0],
[56,55,13,1],
[56,55,13,2],
[56,55,13,3],
[56,55,13,4],
[56,55,13,5],
[56,55,13,6],
[56,55,13,7],
[56,55,13,8],
[56,55,13,11],
[56,55,13,12],
[56,55,14,0],
[56,55,14,1],
[56,55,14,2],
[56,55,14,3],
[56,55,14,4],
[56,55,14,5],
[56,55,14,6],
[56,55,14,7],
[56,55,14,8],
[56,55,14,11],
[56,55,14,12],
[56,55,16,0],
[56,55,16,1],
[56,55,16,2],
[56,55,16,3],
[56,55,16,4],
[56,55,16,5],
[56,55,16,6],
[56,55,16,7],
[56,55,16,8],
[56,55,16,11],
[56,55,16,12],
[56,55,16,13],
[56,55,16,14],
[56,55,17,0],
[56,55,17,1],
[56,55,17,2],
[56,55,17,3],
[56,55,17,4],
[56,55,17,5],
[56,55,17,6],
[56,55,17,7],
[56,55,17,8],
[56,55,17,11],
[56,55,17,12],
[56,55,17,13],
[56,55,17,14],
[56,55,17,16],
[56,55,19,0],
[56,55,19,1],
[56,55,19,2],
[56,55,19,3],
[56,55,19,4],
[56,55,19,5],
[56,55,19,6],
[56,55,19,7],
[56,55,19,8],
[56,55,19,11],
[56,55,19,16],
[56,55,19,17],
[56,55,21,0],
[56,55,21,1],
[56,55,21,2],
[56,55,21,4],
[56,55,21,7],
[56,55,21,11],
[56,55,21,12],
[56,55,21,13],
[56,55,21,14],
[56,55,21,16],
[56,55,21,17],
[56,55,21,19],
[56,55,23,0],
[56,55,23,1],
[56,55,23,2],
[56,55,23,3],
[56,55,23,4],
[56,55,23,6],
[56,55,23,7],
[56,55,23,8],
[56,55,23,11],
[56,55,23,12],
[56,55,23,13],
[56,55,23,14],
[56,55,23,16],
[56,55,23,17],
[56,55,23,19],
[56,55,23,21],
[56,55,24,0],
[56,55,24,1],
[56,55,24,2],
[56,55,24,3],
[56,55,24,4],
[56,55,24,6],
[56,55,24,7],
[56,55,24,8],
[56,55,24,11],
[56,55,24,12],
[56,55,24,13],
[56,55,24,14],
[56,55,24,17],
[56,55,24,19],
[56,55,24,21],
[56,55,25,3],
[56,55,25,4],
[56,55,25,5],
[56,55,25,6],
[56,55,25,8],
[56,55,25,11],
[56,55,25,12],
[56,55,25,13],
[56,55,25,14],
[56,55,25,16],
[56,55,25,17],
[56,55,25,19],
[56,55,28,0],
[56,55,28,1],
[56,55,28,2],
[56,55,28,3],
[56,55,28,4],
[56,55,28,5],
[56,55,28,6],
[56,55,28,8],
[56,55,28,11],
[56,55,28,12],
[56,55,28,13],
[56,55,28,14],
[56,55,28,16],
[56,55,28,17],
[56,55,29,0],
[56,55,29,1],
[56,55,29,2],
[56,55,29,3],
[56,55,29,4],
[56,55,29,5],
[56,55,29,6],
[56,55,29,7],
[56,55,29,8],
[56,55,29,13],
[56,55,29,14],
[56,55,30,0],
[56,55,30,1],
[56,55,30,2],
[56,55,30,3],
[56,55,30,4],
[56,55,30,5],
[56,55,30,6],
[56,55,30,7],
[56,55,30,8],
[56,55,30,11],
[56,55,30,12],
[56,55,30,13],
[56,55,30,14],
[56,55,31,0],
[56,55,31,1],
[56,55,31,2],
[56,55,31,3],
[56,55,31,4],
[56,55,31,5],
[56,55,31,6],
[56,55,31,7],
[56,55,31,8],
[56,55,31,11],
[56,55,31,12],
[56,55,31,13],
[56,55,32,0],
[56,55,32,1],
[56,55,32,2],
[56,55,32,3],
[56,55,32,4],
[56,55,32,5],
[56,55,32,6],
[56,55,32,7],
[56,55,32,8],
[56,55,33,0],
[56,55,33,1],
[56,55,33,2],
[56,55,33,3],
[56,55,33,4],
[56,55,33,5],
[56,55,33,6],
[56,55,33,7],
[56,55,33,8],
[56,55,34,0],
[56,55,34,1],
[56,55,34,2],
[56,55,34,3],
[56,55,34,4],
[56,55,34,5],
[56,55,34,6],
[56,55,36,0],
[56,55,36,1],
[56,55,36,2],
[56,55,36,3],
[56,55,36,4],
[56,55,37,0],
[56,55,37,1],
[56,55,37,2],
[57,4,3,0],
[57,4,3,1],
[57,4,3,2],
[57,5,4,0],
[57,5,4,1],
[57,5,4,2],
[57,6,4,0],
[57,6,4,1],
[57,6,4,2],
[57,7,3,0],
[57,7,3,1],
[57,7,3,2],
[57,7,4,0],
[57,7,4,1],
[57,7,4,2],
[57,7,4,3],
[57,7,5,0],
[57,7,5,1],
[57,7,5,2],
[57,7,5,4],
[57,7,6,0],
[57,7,6,1],
[57,7,6,2],
[57,7,6,4],
[57,8,4,0],
[57,8,4,1],
[57,8,4,2],
[57,8,7,0],
[57,8,7,1],
[57,8,7,2],
[57,8,7,4],
[57,9,3,0],
[57,9,3,1],
[57,9,3,2],
[57,9,4,0],
[57,9,4,1],
[57,9,4,2],
[57,9,4,3],
[57,9,5,0],
[57,9,5,1],
[57,9,5,2],
[57,9,5,4],
[57,9,6,0],
[57,9,6,1],
[57,9,6,2],
[57,9,6,4],
[57,9,7,0],
[57,9,7,1],
[57,9,7,2],
[57,9,7,3],
[57,9,7,4],
[57,9,7,5],
[57,9,7,6],
[57,9,8,0],
[57,9,8,1],
[57,9,8,2],
[57,9,8,4],
[57,9,8,7],
[57,10,3,0],
[57,10,3,1],
[57,10,3,2],
[57,10,4,0],
[57,10,4,1],
[57,10,4,2],
[57,10,4,3],
[57,10,5,0],
[57,10,5,1],
[57,10,5,2],
[57,10,5,4],
[57,10,6,0],
[57,10,6,1],
[57,10,6,2],
[57,10,6,4],
[57,10,7,0],
[57,10,7,1],
[57,10,7,2],
[57,10,7,3],
[57,10,7,4],
[57,10,7,5],
[57,10,7,6],
[57,10,8,0],
[57,10,8,1],
[57,10,8,2],
[57,10,8,4],
[57,10,8,7],
[57,10,9,0],
[57,10,9,1],
[57,10,9,2],
[57,10,9,3],
[57,10,9,4],
[57,10,9,5],
[57,10,9,6],
[57,10,9,7],
[57,10,9,8],
[57,11,3,0],
[57,11,3,1],
[57,11,3,2],
[57,11,4,0],
[57,11,4,1],
[57,11,4,2],
[57,11,4,3],
[57,11,5,0],
[57,11,5,1],
[57,11,5,2],
[57,11,5,4],
[57,11,6,0],
[57,11,6,1],
[57,11,6,2],
[57,11,6,4],
[57,11,7,0],
[57,11,7,1],
[57,11,7,2],
[57,11,7,3],
[57,11,7,4],
[57,11,7,5],
[57,11,7,6],
[57,11,8,0],
[57,11,8,1],
[57,11,8,2],
[57,11,8,4],
[57,11,8,7],
[57,11,9,0],
[57,11,9,1],
[57,11,9,2],
[57,11,9,3],
[57,11,9,4],
[57,11,9,5],
[57,11,9,6],
[57,11,9,7],
[57,11,9,8],
[57,11,10,0],
[57,11,10,1],
[57,11,10,2],
[57,11,10,3],
[57,11,10,4],
[57,11,10,5],
[57,11,10,6],
[57,11,10,7],
[57,11,10,8],
[57,11,10,9],
[57,12,3,0],
[57,12,3,1],
[57,12,3,2],
[57,12,4,0],
[57,12,4,1],
[57,12,4,2],
[57,12,4,3],
[57,12,5,0],
[57,12,5,1],
[57,12,5,2],
[57,12,5,4],
[57,12,6,0],
[57,12,6,1],
[57,12,6,2],
[57,12,6,4],
[57,12,7,0],
[57,12,7,1],
[57,12,7,2],
[57,12,7,3],
[57,12,7,4],
[57,12,7,5],
[57,12,7,6],
[57,12,8,0],
[57,12,8,1],
[57,12,8,2],
[57,12,8,4],
[57,12,8,7],
[57,12,9,0],
[57,12,9,1],
[57,12,9,2],
[57,12,9,3],
[57,12,9,4],
[57,12,9,5],
[57,12,9,6],
[57,12,9,7],
[57,12,9,8],
[57,12,10,0],
[57,12,10,1],
[57,12,10,2],
[57,12,10,3],
[57,12,10,4],
[57,12,10,5],
[57,12,10,6],
[57,12,10,7],
[57,12,10,8],
[57,12,10,9],
[57,13,3,0],
[57,13,3,1],
[57,13,3,2],
[57,13,4,0],
[57,13,4,1],
[57,13,4,2],
[57,13,4,3],
[57,13,5,0],
[57,13,5,1],
[57,13,5,2],
[57,13,5,4],
[57,13,6,0],
[57,13,6,1],
[57,13,6,2],
[57,13,6,4],
[57,13,7,0],
[57,13,7,1],
[57,13,7,2],
[57,13,7,3],
[57,13,7,4],
[57,13,7,5],
[57,13,7,6],
[57,13,8,0],
[57,13,8,1],
[57,13,8,2],
[57,13,8,4],
[57,13,8,7],
[57,13,9,0],
[57,13,9,1],
[57,13,9,2],
[57,13,9,3],
[57,13,9,4],
[57,13,9,5],
[57,13,9,6],
[57,13,9,7],
[57,13,9,8],
[57,13,10,0],
[57,13,10,1],
[57,13,10,2],
[57,13,10,3],
[57,13,10,4],
[57,13,10,5],
[57,13,10,6],
[57,13,10,7],
[57,13,10,8],
[57,13,10,9],
[57,13,11,0],
[57,13,11,1],
[57,13,11,2],
[57,13,11,3],
[57,13,11,4],
[57,13,11,5],
[57,13,11,6],
[57,13,11,7],
[57,13,11,8],
[57,13,11,9],
[57,13,11,10],
[57,13,12,0],
[57,13,12,1],
[57,13,12,2],
[57,13,12,3],
[57,13,12,4],
[57,13,12,5],
[57,13,12,6],
[57,13,12,7],
[57,13,12,8],
[57,13,12,9],
[57,13,12,10],
[57,14,3,0],
[57,14,3,1],
[57,14,3,2],
[57,14,4,0],
[57,14,4,1],
[57,14,4,2],
[57,14,4,3],
[57,14,5,0],
[57,14,5,1],
[57,14,5,2],
[57,14,5,4],
[57,14,6,0],
[57,14,6,1],
[57,14,6,2],
[57,14,6,4],
[57,14,7,0],
[57,14,7,1],
[57,14,7,2],
[57,14,7,3],
[57,14,7,4],
[57,14,7,5],
[57,14,7,6],
[57,14,8,0],
[57,14,8,1],
[57,14,8,2],
[57,14,8,4],
[57,14,8,7],
[57,14,9,0],
[57,14,9,1],
[57,14,9,2],
[57,14,9,3],
[57,14,9,4],
[57,14,9,5],
[57,14,9,6],
[57,14,9,7],
[57,14,9,8],
[57,14,10,0],
[57,14,10,1],
[57,14,10,2],
[57,14,10,3],
[57,14,10,4],
[57,14,10,5],
[57,14,10,6],
[57,14,10,7],
[57,14,10,8],
[57,14,10,9],
[57,14,11,0],
[57,14,11,1],
[57,14,11,2],
[57,14,11,3],
[57,14,11,4],
[57,14,11,5],
[57,14,11,6],
[57,14,11,7],
[57,14,11,8],
[57,14,11,9],
[57,14,11,10],
[57,14,12,0],
[57,14,12,1],
[57,14,12,2],
[57,14,12,3],
[57,14,12,4],
[57,14,12,5],
[57,14,12,6],
[57,14,12,7],
[57,14,12,8],
[57,14,12,9],
[57,14,12,10],
[57,15,3,0],
[57,15,3,1],
[57,15,3,2],
[57,15,4,0],
[57,15,4,1],
[57,15,4,2],
[57,15,4,3],
[57,15,5,0],
[57,15,5,1],
[57,15,5,2],
[57,15,5,4],
[57,15,6,0],
[57,15,6,1],
[57,15,6,2],
[57,15,6,4],
[57,15,7,0],
[57,15,7,1],
[57,15,7,2],
[57,15,7,3],
[57,15,7,4],
[57,15,7,5],
[57,15,7,6],
[57,15,8,0],
[57,15,8,1],
[57,15,8,2],
[57,15,8,4],
[57,15,8,7],
[57,15,10,0],
[57,15,10,1],
[57,15,10,2],
[57,15,10,3],
[57,15,10,4],
[57,15,10,5],
[57,15,10,6],
[57,15,10,7],
[57,15,10,8],
[57,15,11,0],
[57,15,11,1],
[57,15,11,2],
[57,15,11,3],
[57,15,11,4],
[57,15,11,5],
[57,15,11,6],
[57,15,11,7],
[57,15,11,8],
[57,15,11,10],
[57,15,12,0],
[57,15,12,1],
[57,15,12,2],
[57,15,12,3],
[57,15,12,4],
[57,15,12,5],
[57,15,12,6],
[57,15,12,7],
[57,15,12,8],
[57,15,12,10],
[57,15,13,0],
[57,15,13,1],
[57,15,13,2],
[57,15,13,3],
[57,15,13,4],
[57,15,13,5],
[57,15,13,6],
[57,15,13,7],
[57,15,13,8],
[57,15,13,10],
[57,15,13,11],
[57,15,13,12],
[57,15,14,0],
[57,15,14,1],
[57,15,14,2],
[57,15,14,3],
[57,15,14,4],
[57,15,14,5],
[57,15,14,6],
[57,15,14,7],
[57,15,14,8],
[57,15,14,10],
[57,15,14,11],
[57,15,14,12],
[57,16,3,0],
[57,16,3,1],
[57,16,3,2],
[57,16,4,0],
[57,16,4,1],
[57,16,4,2],
[57,16,4,3],
[57,16,5,0],
[57,16,5,1],
[57,16,5,2],
[57,16,5,4],
[57,16,6,0],
[57,16,6,1],
[57,16,6,2],
[57,16,6,4],
[57,16,7,0],
[57,16,7,1],
[57,16,7,2],
[57,16,7,3],
[57,16,7,4],
[57,16,7,5],
[57,16,7,6],
[57,16,8,0],
[57,16,8,1],
[57,16,8,2],
[57,16,8,4],
[57,16,8,7],
[57,16,9,0],
[57,16,9,1],
[57,16,9,2],
[57,16,9,3],
[57,16,9,4],
[57,16,9,5],
[57,16,9,6],
[57,16,9,7],
[57,16,9,8],
[57,16,10,0],
[57,16,10,1],
[57,16,10,2],
[57,16,10,3],
[57,16,10,4],
[57,16,10,5],
[57,16,10,6],
[57,16,10,7],
[57,16,10,8],
[57,16,10,9],
[57,16,11,0],
[57,16,11,1],
[57,16,11,2],
[57,16,11,3],
[57,16,11,4],
[57,16,11,5],
[57,16,11,6],
[57,16,11,7],
[57,16,11,8],
[57,16,11,9],
[57,16,11,10],
[57,16,12,0],
[57,16,12,1],
[57,16,12,2],
[57,16,12,3],
[57,16,12,4],
[57,16,12,5],
[57,16,12,6],
[57,16,12,7],
[57,16,12,8],
[57,16,12,9],
[57,16,12,10],
[57,16,13,0],
[57,16,13,1],
[57,16,13,2],
[57,16,13,3],
[57,16,13,4],
[57,16,13,5],
[57,16,13,6],
[57,16,13,7],
[57,16,13,8],
[57,16,13,9],
[57,16,13,10],
[57,16,13,11],
[57,16,13,12],
[57,16,14,0],
[57,16,14,1],
[57,16,14,2],
[57,16,14,3],
[57,16,14,4],
[57,16,14,5],
[57,16,14,6],
[57,16,14,7],
[57,16,14,8],
[57,16,14,9],
[57,16,14,10],
[57,16,14,11],
[57,16,14,12],
[57,16,15,0],
[57,16,15,1],
[57,16,15,2],
[57,16,15,3],
[57,16,15,4],
[57,16,15,5],
[57,16,15,6],
[57,16,15,7],
[57,16,15,8],
[57,16,15,10],
[57,16,15,11],
[57,16,15,12],
[57,16,15,13],
[57,16,15,14],
[57,17,3,0],
[57,17,3,1],
[57,17,3,2],
[57,17,4,0],
[57,17,4,1],
[57,17,4,2],
[57,17,4,3],
[57,17,5,0],
[57,17,5,1],
[57,17,5,2],
[57,17,5,4],
[57,17,6,0],
[57,17,6,1],
[57,17,6,2],
[57,17,6,4],
[57,17,7,0],
[57,17,7,1],
[57,17,7,2],
[57,17,7,3],
[57,17,7,4],
[57,17,7,5],
[57,17,7,6],
[57,17,8,0],
[57,17,8,1],
[57,17,8,2],
[57,17,8,4],
[57,17,8,7],
[57,17,9,0],
[57,17,9,1],
[57,17,9,2],
[57,17,9,3],
[57,17,9,4],
[57,17,9,5],
[57,17,9,6],
[57,17,9,7],
[57,17,9,8],
[57,17,10,0],
[57,17,10,1],
[57,17,10,2],
[57,17,10,3],
[57,17,10,4],
[57,17,10,5],
[57,17,10,6],
[57,17,10,7],
[57,17,10,8],
[57,17,10,9],
[57,17,11,0],
[57,17,11,1],
[57,17,11,2],
[57,17,11,3],
[57,17,11,4],
[57,17,11,5],
[57,17,11,6],
[57,17,11,7],
[57,17,11,8],
[57,17,11,9],
[57,17,11,10],
[57,17,12,0],
[57,17,12,1],
[57,17,12,2],
[57,17,12,3],
[57,17,12,4],
[57,17,12,5],
[57,17,12,6],
[57,17,12,7],
[57,17,12,8],
[57,17,12,9],
[57,17,12,10],
[57,17,13,0],
[57,17,13,1],
[57,17,13,2],
[57,17,13,3],
[57,17,13,4],
[57,17,13,5],
[57,17,13,6],
[57,17,13,7],
[57,17,13,8],
[57,17,13,9],
[57,17,13,10],
[57,17,13,11],
[57,17,13,12],
[57,17,14,0],
[57,17,14,1],
[57,17,14,2],
[57,17,14,3],
[57,17,14,4],
[57,17,14,5],
[57,17,14,6],
[57,17,14,7],
[57,17,14,8],
[57,17,14,9],
[57,17,14,10],
[57,17,14,11],
[57,17,14,12],
[57,17,15,0],
[57,17,15,1],
[57,17,15,2],
[57,17,15,3],
[57,17,15,4],
[57,17,15,5],
[57,17,15,6],
[57,17,15,7],
[57,17,15,8],
[57,17,15,10],
[57,17,15,11],
[57,17,15,12],
[57,17,15,13],
[57,17,15,14],
[57,17,16,0],
[57,17,16,1],
[57,17,16,2],
[57,17,16,3],
[57,17,16,4],
[57,17,16,5],
[57,17,16,6],
[57,17,16,7],
[57,17,16,8],
[57,17,16,9],
[57,17,16,10],
[57,17,16,11],
[57,17,16,12],
[57,17,16,13],
[57,17,16,14],
[57,17,16,15],
[57,18,3,0],
[57,18,3,1],
[57,18,3,2],
[57,18,4,0],
[57,18,4,1],
[57,18,4,2],
[57,18,4,3],
[57,18,5,0],
[57,18,5,1],
[57,18,5,2],
[57,18,5,4],
[57,18,6,0],
[57,18,6,1],
[57,18,6,2],
[57,18,6,4],
[57,18,7,0],
[57,18,7,1],
[57,18,7,2],
[57,18,7,3],
[57,18,7,4],
[57,18,7,5],
[57,18,7,6],
[57,18,8,0],
[57,18,8,1],
[57,18,8,2],
[57,18,8,4],
[57,18,8,7],
[57,18,10,0],
[57,18,10,1],
[57,18,10,2],
[57,18,10,3],
[57,18,10,4],
[57,18,10,5],
[57,18,10,6],
[57,18,10,7],
[57,18,10,8],
[57,18,11,0],
[57,18,11,1],
[57,18,11,2],
[57,18,11,3],
[57,18,11,4],
[57,18,11,5],
[57,18,11,6],
[57,18,11,7],
[57,18,11,8],
[57,18,11,10],
[57,18,12,0],
[57,18,12,1],
[57,18,12,2],
[57,18,12,3],
[57,18,12,4],
[57,18,12,5],
[57,18,12,6],
[57,18,12,7],
[57,18,12,8],
[57,18,12,10],
[57,18,13,0],
[57,18,13,1],
[57,18,13,2],
[57,18,13,3],
[57,18,13,4],
[57,18,13,5],
[57,18,13,6],
[57,18,13,7],
[57,18,13,8],
[57,18,13,10],
[57,18,13,11],
[57,18,13,12],
[57,18,14,0],
[57,18,14,1],
[57,18,14,2],
[57,18,14,3],
[57,18,14,4],
[57,18,14,5],
[57,18,14,6],
[57,18,14,7],
[57,18,14,8],
[57,18,14,10],
[57,18,14,11],
[57,18,14,12],
[57,18,16,0],
[57,18,16,1],
[57,18,16,2],
[57,18,16,3],
[57,18,16,4],
[57,18,16,5],
[57,18,16,6],
[57,18,16,7],
[57,18,16,8],
[57,18,16,10],
[57,18,16,11],
[57,18,16,12],
[57,18,16,13],
[57,18,16,14],
[57,18,17,0],
[57,18,17,1],
[57,18,17,2],
[57,18,17,3],
[57,18,17,4],
[57,18,17,5],
[57,18,17,6],
[57,18,17,7],
[57,18,17,8],
[57,18,17,10],
[57,18,17,11],
[57,18,17,12],
[57,18,17,13],
[57,18,17,14],
[57,18,17,16],
[57,19,3,0],
[57,19,3,1],
[57,19,3,2],
[57,19,4,0],
[57,19,4,1],
[57,19,4,2],
[57,19,4,3],
[57,19,5,0],
[57,19,5,1],
[57,19,5,2],
[57,19,5,4],
[57,19,6,0],
[57,19,6,1],
[57,19,6,2],
[57,19,6,4],
[57,19,7,0],
[57,19,7,1],
[57,19,7,2],
[57,19,7,3],
[57,19,7,4],
[57,19,7,5],
[57,19,7,6],
[57,19,8,0],
[57,19,8,1],
[57,19,8,2],
[57,19,8,4],
[57,19,8,7],
[57,19,9,0],
[57,19,9,1],
[57,19,9,2],
[57,19,9,3],
[57,19,9,4],
[57,19,9,5],
[57,19,9,6],
[57,19,9,7],
[57,19,9,8],
[57,19,10,0],
[57,19,10,1],
[57,19,10,2],
[57,19,10,3],
[57,19,10,4],
[57,19,10,5],
[57,19,10,6],
[57,19,10,7],
[57,19,10,8],
[57,19,10,9],
[57,19,11,0],
[57,19,11,1],
[57,19,11,2],
[57,19,11,3],
[57,19,11,4],
[57,19,11,5],
[57,19,11,6],
[57,19,11,7],
[57,19,11,8],
[57,19,11,9],
[57,19,11,10],
[57,19,12,0],
[57,19,12,1],
[57,19,12,2],
[57,19,12,3],
[57,19,12,4],
[57,19,12,5],
[57,19,12,6],
[57,19,12,7],
[57,19,12,8],
[57,19,12,9],
[57,19,12,10],
[57,19,15,0],
[57,19,15,1],
[57,19,15,2],
[57,19,15,3],
[57,19,15,4],
[57,19,15,5],
[57,19,15,6],
[57,19,15,7],
[57,19,15,8],
[57,19,15,10],
[57,19,15,11],
[57,19,15,12],
[57,19,16,0],
[57,19,16,1],
[57,19,16,2],
[57,19,16,3],
[57,19,16,4],
[57,19,16,5],
[57,19,16,6],
[57,19,16,7],
[57,19,16,8],
[57,19,16,9],
[57,19,16,10],
[57,19,16,11],
[57,19,16,12],
[57,19,16,15],
[57,19,17,0],
[57,19,17,1],
[57,19,17,2],
[57,19,17,3],
[57,19,17,4],
[57,19,17,5],
[57,19,17,6],
[57,19,17,7],
[57,19,17,8],
[57,19,17,9],
[57,19,17,10],
[57,19,17,11],
[57,19,17,12],
[57,19,17,15],
[57,19,17,16],
[57,19,18,0],
[57,19,18,1],
[57,19,18,2],
[57,19,18,3],
[57,19,18,4],
[57,19,18,5],
[57,19,18,6],
[57,19,18,7],
[57,19,18,8],
[57,19,18,10],
[57,19,18,11],
[57,19,18,12],
[57,19,18,16],
[57,19,18,17],
[57,20,3,0],
[57,20,3,1],
[57,20,3,2],
[57,20,4,0],
[57,20,4,1],
[57,20,4,2],
[57,20,4,3],
[57,20,5,0],
[57,20,5,1],
[57,20,5,2],
[57,20,5,4],
[57,20,6,0],
[57,20,6,1],
[57,20,6,2],
[57,20,6,4],
[57,20,7,0],
[57,20,7,1],
[57,20,7,2],
[57,20,7,3],
[57,20,7,4],
[57,20,7,5],
[57,20,7,6],
[57,20,8,0],
[57,20,8,1],
[57,20,8,2],
[57,20,8,4],
[57,20,8,7],
[57,20,9,0],
[57,20,9,1],
[57,20,9,2],
[57,20,9,3],
[57,20,9,4],
[57,20,9,5],
[57,20,9,6],
[57,20,9,7],
[57,20,9,8],
[57,20,11,0],
[57,20,11,1],
[57,20,11,2],
[57,20,11,3],
[57,20,11,4],
[57,20,11,5],
[57,20,11,6],
[57,20,11,7],
[57,20,11,8],
[57,20,11,9],
[57,20,12,0],
[57,20,12,1],
[57,20,12,2],
[57,20,12,3],
[57,20,12,4],
[57,20,12,5],
[57,20,12,6],
[57,20,12,7],
[57,20,12,8],
[57,20,12,9],
[57,20,13,0],
[57,20,13,1],
[57,20,13,2],
[57,20,13,3],
[57,20,13,4],
[57,20,13,5],
[57,20,13,6],
[57,20,13,7],
[57,20,13,8],
[57,20,13,9],
[57,20,13,11],
[57,20,13,12],
[57,20,14,0],
[57,20,14,1],
[57,20,14,2],
[57,20,14,3],
[57,20,14,4],
[57,20,14,5],
[57,20,14,6],
[57,20,14,7],
[57,20,14,8],
[57,20,14,9],
[57,20,14,11],
[57,20,14,12],
[57,20,15,0],
[57,20,15,1],
[57,20,15,2],
[57,20,15,3],
[57,20,15,4],
[57,20,15,5],
[57,20,15,6],
[57,20,15,7],
[57,20,15,8],
[57,20,15,11],
[57,20,15,12],
[57,20,15,13],
[57,20,15,14],
[57,20,16,0],
[57,20,16,1],
[57,20,16,2],
[57,20,16,3],
[57,20,16,4],
[57,20,16,5],
[57,20,16,6],
[57,20,16,7],
[57,20,16,8],
[57,20,16,9],
[57,20,16,11],
[57,20,16,12],
[57,20,16,13],
[57,20,16,14],
[57,20,16,15],
[57,20,17,0],
[57,20,17,1],
[57,20,17,2],
[57,20,17,3],
[57,20,17,4],
[57,20,17,5],
[57,20,17,6],
[57,20,17,7],
[57,20,17,8],
[57,20,17,9],
[57,20,17,11],
[57,20,17,12],
[57,20,17,13],
[57,20,17,14],
[57,20,17,15],
[57,20,17,16],
[57,20,18,0],
[57,20,18,1],
[57,20,18,2],
[57,20,18,3],
[57,20,18,4],
[57,20,18,5],
[57,20,18,6],
[57,20,18,7],
[57,20,18,8],
[57,20,18,11],
[57,20,18,12],
[57,20,18,13],
[57,20,18,14],
[57,20,18,16],
[57,20,18,17],
[57,20,19,0],
[57,20,19,1],
[57,20,19,2],
[57,20,19,3],
[57,20,19,4],
[57,20,19,5],
[57,20,19,6],
[57,20,19,7],
[57,20,19,8],
[57,20,19,9],
[57,20,19,11],
[57,20,19,12],
[57,20,19,15],
[57,20,19,16],
[57,20,19,17],
[57,20,19,18],
[57,21,4,0],
[57,21,4,1],
[57,21,4,2],
[57,21,7,0],
[57,21,7,1],
[57,21,7,2],
[57,21,7,4],
[57,21,9,0],
[57,21,9,1],
[57,21,9,2],
[57,21,9,4],
[57,21,9,7],
[57,21,10,0],
[57,21,10,1],
[57,21,10,2],
[57,21,10,4],
[57,21,10,7],
[57,21,10,9],
[57,21,11,0],
[57,21,11,1],
[57,21,11,2],
[57,21,11,4],
[57,21,11,7],
[57,21,11,9],
[57,21,11,10],
[57,21,12,0],
[57,21,12,1],
[57,21,12,2],
[57,21,12,4],
[57,21,12,7],
[57,21,12,9],
[57,21,12,10],
[57,21,13,0],
[57,21,13,1],
[57,21,13,2],
[57,21,13,4],
[57,21,13,7],
[57,21,13,9],
[57,21,13,10],
[57,21,13,11],
[57,21,13,12],
[57,21,14,0],
[57,21,14,1],
[57,21,14,2],
[57,21,14,4],
[57,21,14,7],
[57,21,14,9],
[57,21,14,10],
[57,21,14,11],
[57,21,14,12],
[57,21,15,0],
[57,21,15,1],
[57,21,15,2],
[57,21,15,4],
[57,21,15,7],
[57,21,15,10],
[57,21,15,11],
[57,21,15,12],
[57,21,15,13],
[57,21,15,14],
[57,21,16,0],
[57,21,16,1],
[57,21,16,2],
[57,21,16,4],
[57,21,16,7],
[57,21,16,9],
[57,21,16,10],
[57,21,16,11],
[57,21,16,12],
[57,21,16,13],
[57,21,16,14],
[57,21,16,15],
[57,21,17,0],
[57,21,17,1],
[57,21,17,2],
[57,21,17,4],
[57,21,17,7],
[57,21,17,9],
[57,21,17,10],
[57,21,17,11],
[57,21,17,12],
[57,21,17,13],
[57,21,17,14],
[57,21,17,15],
[57,21,17,16],
[57,21,18,0],
[57,21,18,1],
[57,21,18,2],
[57,21,18,4],
[57,21,18,7],
[57,21,18,10],
[57,21,18,11],
[57,21,18,12],
[57,21,18,13],
[57,21,18,14],
[57,21,18,16],
[57,21,18,17],
[57,21,19,0],
[57,21,19,1],
[57,21,19,2],
[57,21,19,4],
[57,21,19,7],
[57,21,19,9],
[57,21,19,10],
[57,21,19,11],
[57,21,19,12],
[57,21,19,15],
[57,21,19,16],
[57,21,19,17],
[57,21,19,18],
[57,21,20,0],
[57,21,20,1],
[57,21,20,2],
[57,21,20,4],
[57,21,20,7],
[57,21,20,9],
[57,21,20,11],
[57,21,20,12],
[57,21,20,13],
[57,21,20,14],
[57,21,20,15],
[57,21,20,16],
[57,21,20,17],
[57,21,20,18],
[57,21,20,19],
[57,22,3,0],
[57,22,3,1],
[57,22,3,2],
[57,22,4,0],
[57,22,4,1],
[57,22,4,2],
[57,22,4,3],
[57,22,5,0],
[57,22,5,1],
[57,22,5,2],
[57,22,5,4],
[57,22,6,0],
[57,22,6,1],
[57,22,6,2],
[57,22,6,4],
[57,22,7,0],
[57,22,7,1],
[57,22,7,2],
[57,22,7,3],
[57,22,7,4],
[57,22,7,5],
[57,22,7,6],
[57,22,8,0],
[57,22,8,1],
[57,22,8,2],
[57,22,8,4],
[57,22,8,7],
[57,22,9,0],
[57,22,9,1],
[57,22,9,2],
[57,22,9,3],
[57,22,9,4],
[57,22,9,5],
[57,22,9,6],
[57,22,9,7],
[57,22,9,8],
[57,22,11,0],
[57,22,11,1],
[57,22,11,2],
[57,22,11,3],
[57,22,11,4],
[57,22,11,5],
[57,22,11,6],
[57,22,11,7],
[57,22,11,8],
[57,22,11,9],
[57,22,12,0],
[57,22,12,1],
[57,22,12,2],
[57,22,12,3],
[57,22,12,4],
[57,22,12,5],
[57,22,12,6],
[57,22,12,7],
[57,22,12,8],
[57,22,12,9],
[57,22,13,0],
[57,22,13,1],
[57,22,13,2],
[57,22,13,3],
[57,22,13,4],
[57,22,13,5],
[57,22,13,6],
[57,22,13,7],
[57,22,13,8],
[57,22,13,9],
[57,22,13,11],
[57,22,13,12],
[57,22,14,0],
[57,22,14,1],
[57,22,14,2],
[57,22,14,3],
[57,22,14,4],
[57,22,14,5],
[57,22,14,6],
[57,22,14,7],
[57,22,14,8],
[57,22,14,9],
[57,22,14,11],
[57,22,14,12],
[57,22,15,0],
[57,22,15,1],
[57,22,15,2],
[57,22,15,3],
[57,22,15,4],
[57,22,15,5],
[57,22,15,6],
[57,22,15,7],
[57,22,15,8],
[57,22,15,11],
[57,22,15,12],
[57,22,15,13],
[57,22,15,14],
[57,22,16,0],
[57,22,16,1],
[57,22,16,2],
[57,22,16,3],
[57,22,16,4],
[57,22,16,5],
[57,22,16,6],
[57,22,16,7],
[57,22,16,8],
[57,22,16,9],
[57,22,16,11],
[57,22,16,12],
[57,22,16,13],
[57,22,16,14],
[57,22,16,15],
[57,22,17,0],
[57,22,17,1],
[57,22,17,2],
[57,22,17,3],
[57,22,17,4],
[57,22,17,5],
[57,22,17,6],
[57,22,17,7],
[57,22,17,8],
[57,22,17,9],
[57,22,17,11],
[57,22,17,12],
[57,22,17,13],
[57,22,17,14],
[57,22,17,15],
[57,22,17,16],
[57,22,18,0],
[57,22,18,1],
[57,22,18,2],
[57,22,18,3],
[57,22,18,4],
[57,22,18,5],
[57,22,18,6],
[57,22,18,7],
[57,22,18,8],
[57,22,18,11],
[57,22,18,12],
[57,22,18,13],
[57,22,18,14],
[57,22,18,16],
[57,22,18,17],
[57,22,19,0],
[57,22,19,1],
[57,22,19,2],
[57,22,19,3],
[57,22,19,4],
[57,22,19,5],
[57,22,19,6],
[57,22,19,7],
[57,22,19,8],
[57,22,19,9],
[57,22,19,11],
[57,22,19,12],
[57,22,19,15],
[57,22,19,16],
[57,22,19,17],
[57,22,19,18],
[57,22,21,0],
[57,22,21,1],
[57,22,21,2],
[57,22,21,4],
[57,22,21,7],
[57,22,21,9],
[57,22,21,11],
[57,22,21,12],
[57,22,21,13],
[57,22,21,14],
[57,22,21,15],
[57,22,21,16],
[57,22,21,17],
[57,22,21,18],
[57,22,21,19],
[57,24,3,0],
[57,24,3,1],
[57,24,3,2],
[57,24,4,0],
[57,24,4,1],
[57,24,4,2],
[57,24,4,3],
[57,24,5,0],
[57,24,5,1],
[57,24,5,2],
[57,24,5,4],
[57,24,6,0],
[57,24,6,1],
[57,24,6,2],
[57,24,6,4],
[57,24,7,0],
[57,24,7,1],
[57,24,7,2],
[57,24,7,3],
[57,24,7,4],
[57,24,7,5],
[57,24,7,6],
[57,24,8,0],
[57,24,8,1],
[57,24,8,2],
[57,24,8,4],
[57,24,8,7],
[57,24,9,0],
[57,24,9,1],
[57,24,9,2],
[57,24,9,3],
[57,24,9,4],
[57,24,9,5],
[57,24,9,6],
[57,24,9,7],
[57,24,9,8],
[57,24,10,0],
[57,24,10,1],
[57,24,10,2],
[57,24,10,3],
[57,24,10,4],
[57,24,10,5],
[57,24,10,6],
[57,24,10,7],
[57,24,10,8],
[57,24,10,9],
[57,24,11,0],
[57,24,11,1],
[57,24,11,2],
[57,24,11,3],
[57,24,11,4],
[57,24,11,5],
[57,24,11,6],
[57,24,11,7],
[57,24,11,8],
[57,24,11,9],
[57,24,11,10],
[57,24,12,0],
[57,24,12,1],
[57,24,12,2],
[57,24,12,3],
[57,24,12,4],
[57,24,12,5],
[57,24,12,6],
[57,24,12,7],
[57,24,12,8],
[57,24,12,9],
[57,24,12,10],
[57,24,13,0],
[57,24,13,1],
[57,24,13,2],
[57,24,13,3],
[57,24,13,4],
[57,24,13,5],
[57,24,13,6],
[57,24,13,7],
[57,24,13,8],
[57,24,13,9],
[57,24,13,10],
[57,24,13,11],
[57,24,13,12],
[57,24,14,0],
[57,24,14,1],
[57,24,14,2],
[57,24,14,3],
[57,24,14,4],
[57,24,14,5],
[57,24,14,6],
[57,24,14,7],
[57,24,14,8],
[57,24,14,9],
[57,24,14,10],
[57,24,14,11],
[57,24,14,12],
[57,24,15,0],
[57,24,15,1],
[57,24,15,2],
[57,24,15,3],
[57,24,15,4],
[57,24,15,5],
[57,24,15,6],
[57,24,15,7],
[57,24,15,8],
[57,24,15,10],
[57,24,15,11],
[57,24,15,12],
[57,24,15,13],
[57,24,15,14],
[57,24,17,0],
[57,24,17,1],
[57,24,17,2],
[57,24,17,3],
[57,24,17,4],
[57,24,17,5],
[57,24,17,6],
[57,24,17,7],
[57,24,17,8],
[57,24,17,9],
[57,24,17,10],
[57,24,17,11],
[57,24,17,12],
[57,24,17,13],
[57,24,17,14],
[57,24,17,15],
[57,24,18,0],
[57,24,18,1],
[57,24,18,2],
[57,24,18,3],
[57,24,18,4],
[57,24,18,5],
[57,24,18,6],
[57,24,18,7],
[57,24,18,8],
[57,24,18,10],
[57,24,18,11],
[57,24,18,12],
[57,24,18,13],
[57,24,18,14],
[57,24,18,17],
[57,24,19,0],
[57,24,19,1],
[57,24,19,2],
[57,24,19,3],
[57,24,19,4],
[57,24,19,5],
[57,24,19,6],
[57,24,19,7],
[57,24,19,8],
[57,24,19,9],
[57,24,19,10],
[57,24,19,11],
[57,24,19,12],
[57,24,19,15],
[57,24,19,17],
[57,24,19,18],
[57,24,20,0],
[57,24,20,1],
[57,24,20,2],
[57,24,20,3],
[57,24,20,4],
[57,24,20,5],
[57,24,20,6],
[57,24,20,7],
[57,24,20,8],
[57,24,20,9],
[57,24,20,11],
[57,24,20,12],
[57,24,20,13],
[57,24,20,14],
[57,24,20,15],
[57,24,20,17],
[57,24,20,18],
[57,24,20,19],
[57,24,21,0],
[57,24,21,1],
[57,24,21,2],
[57,24,21,4],
[57,24,21,7],
[57,24,21,9],
[57,24,21,10],
[57,24,21,11],
[57,24,21,12],
[57,24,21,13],
[57,24,21,14],
[57,24,21,15],
[57,24,21,17],
[57,24,21,18],
[57,24,21,19],
[57,24,21,20],
[57,24,22,0],
[57,24,22,1],
[57,24,22,2],
[57,24,22,3],
[57,24,22,4],
[57,24,22,5],
[57,24,22,6],
[57,24,22,7],
[57,24,22,8],
[57,24,22,9],
[57,24,22,11],
[57,24,22,12],
[57,24,22,13],
[57,24,22,14],
[57,24,22,15],
[57,24,22,17],
[57,24,22,18],
[57,24,22,19],
[57,24,22,21],
[57,25,3,0],
[57,25,3,1],
[57,25,3,2],
[57,25,4,0],
[57,25,4,1],
[57,25,4,2],
[57,25,4,3],
[57,25,5,0],
[57,25,5,1],
[57,25,5,2],
[57,25,5,4],
[57,25,6,0],
[57,25,6,1],
[57,25,6,2],
[57,25,6,4],
[57,25,8,0],
[57,25,8,1],
[57,25,8,2],
[57,25,8,4],
[57,25,9,0],
[57,25,9,1],
[57,25,9,2],
[57,25,9,3],
[57,25,9,4],
[57,25,9,5],
[57,25,9,6],
[57,25,9,8],
[57,25,10,0],
[57,25,10,1],
[57,25,10,2],
[57,25,10,3],
[57,25,10,4],
[57,25,10,5],
[57,25,10,6],
[57,25,10,8],
[57,25,10,9],
[57,25,11,0],
[57,25,11,1],
[57,25,11,2],
[57,25,11,3],
[57,25,11,4],
[57,25,11,5],
[57,25,11,6],
[57,25,11,8],
[57,25,11,9],
[57,25,11,10],
[57,25,12,0],
[57,25,12,1],
[57,25,12,2],
[57,25,12,3],
[57,25,12,4],
[57,25,12,5],
[57,25,12,6],
[57,25,12,8],
[57,25,12,9],
[57,25,12,10],
[57,25,13,0],
[57,25,13,1],
[57,25,13,2],
[57,25,13,3],
[57,25,13,4],
[57,25,13,5],
[57,25,13,6],
[57,25,13,8],
[57,25,13,9],
[57,25,13,10],
[57,25,13,11],
[57,25,13,12],
[57,25,14,0],
[57,25,14,1],
[57,25,14,2],
[57,25,14,3],
[57,25,14,4],
[57,25,14,5],
[57,25,14,6],
[57,25,14,8],
[57,25,14,9],
[57,25,14,10],
[57,25,14,11],
[57,25,14,12],
[57,25,15,0],
[57,25,15,1],
[57,25,15,2],
[57,25,15,3],
[57,25,15,4],
[57,25,15,5],
[57,25,15,6],
[57,25,15,8],
[57,25,15,10],
[57,25,15,11],
[57,25,15,12],
[57,25,15,13],
[57,25,15,14],
[57,25,16,0],
[57,25,16,1],
[57,25,16,2],
[57,25,16,3],
[57,25,16,4],
[57,25,16,5],
[57,25,16,6],
[57,25,16,8],
[57,25,16,9],
[57,25,16,10],
[57,25,16,11],
[57,25,16,12],
[57,25,16,13],
[57,25,16,14],
[57,25,16,15],
[57,25,17,0],
[57,25,17,1],
[57,25,17,2],
[57,25,17,3],
[57,25,17,4],
[57,25,17,5],
[57,25,17,6],
[57,25,17,8],
[57,25,17,9],
[57,25,17,10],
[57,25,17,11],
[57,25,17,12],
[57,25,17,13],
[57,25,17,14],
[57,25,17,15],
[57,25,17,16],
[57,25,18,0],
[57,25,18,1],
[57,25,18,2],
[57,25,18,3],
[57,25,18,4],
[57,25,18,5],
[57,25,18,6],
[57,25,18,8],
[57,25,18,10],
[57,25,18,11],
[57,25,18,12],
[57,25,18,13],
[57,25,18,14],
[57,25,18,16],
[57,25,18,17],
[57,25,19,0],
[57,25,19,1],
[57,25,19,2],
[57,25,19,3],
[57,25,19,4],
[57,25,19,5],
[57,25,19,6],
[57,25,19,8],
[57,25,19,9],
[57,25,19,10],
[57,25,19,11],
[57,25,19,12],
[57,25,19,15],
[57,25,19,16],
[57,25,19,17],
[57,25,19,18],
[57,25,20,0],
[57,25,20,1],
[57,25,20,2],
[57,25,20,3],
[57,25,20,4],
[57,25,20,5],
[57,25,20,6],
[57,25,20,8],
[57,25,20,9],
[57,25,20,11],
[57,25,20,12],
[57,25,20,13],
[57,25,20,14],
[57,25,20,15],
[57,25,20,16],
[57,25,20,17],
[57,25,20,18],
[57,25,20,19],
[57,25,21,0],
[57,25,21,1],
[57,25,21,2],
[57,25,21,4],
[57,25,21,9],
[57,25,21,10],
[57,25,21,11],
[57,25,21,12],
[57,25,21,13],
[57,25,21,14],
[57,25,21,15],
[57,25,21,16],
[57,25,21,17],
[57,25,21,18],
[57,25,21,19],
[57,25,21,20],
[57,25,22,0],
[57,25,22,1],
[57,25,22,2],
[57,25,22,3],
[57,25,22,4],
[57,25,22,5],
[57,25,22,6],
[57,25,22,8],
[57,25,22,9],
[57,25,22,11],
[57,25,22,12],
[57,25,22,13],
[57,25,22,14],
[57,25,22,15],
[57,25,22,16],
[57,25,22,17],
[57,25,22,18],
[57,25,22,19],
[57,25,22,21],
[57,25,24,0],
[57,25,24,1],
[57,25,24,2],
[57,25,24,3],
[57,25,24,4],
[57,25,24,5],
[57,25,24,6],
[57,25,24,8],
[57,25,24,9],
[57,25,24,10],
[57,25,24,11],
[57,25,24,12],
[57,25,24,13],
[57,25,24,14],
[57,25,24,15],
[57,25,24,17],
[57,25,24,18],
[57,25,24,19],
[57,25,24,20],
[57,25,24,21],
[57,25,24,22],
[57,26,3,0],
[57,26,3,1],
[57,26,3,2],
[57,26,4,0],
[57,26,4,1],
[57,26,4,2],
[57,26,4,3],
[57,26,5,0],
[57,26,5,1],
[57,26,5,2],
[57,26,5,4],
[57,26,6,0],
[57,26,6,1],
[57,26,6,2],
[57,26,6,4],
[57,26,7,0],
[57,26,7,1],
[57,26,7,2],
[57,26,7,3],
[57,26,7,4],
[57,26,7,5],
[57,26,7,6],
[57,26,8,0],
[57,26,8,1],
[57,26,8,2],
[57,26,8,4],
[57,26,8,7],
[57,26,10,0],
[57,26,10,1],
[57,26,10,2],
[57,26,10,3],
[57,26,10,4],
[57,26,10,5],
[57,26,10,6],
[57,26,10,7],
[57,26,10,8],
[57,26,11,0],
[57,26,11,1],
[57,26,11,2],
[57,26,11,3],
[57,26,11,4],
[57,26,11,5],
[57,26,11,6],
[57,26,11,7],
[57,26,11,8],
[57,26,11,10],
[57,26,12,0],
[57,26,12,1],
[57,26,12,2],
[57,26,12,3],
[57,26,12,4],
[57,26,12,5],
[57,26,12,6],
[57,26,12,7],
[57,26,12,8],
[57,26,12,10],
[57,26,13,0],
[57,26,13,1],
[57,26,13,2],
[57,26,13,3],
[57,26,13,4],
[57,26,13,5],
[57,26,13,6],
[57,26,13,7],
[57,26,13,8],
[57,26,13,10],
[57,26,13,11],
[57,26,13,12],
[57,26,14,0],
[57,26,14,1],
[57,26,14,2],
[57,26,14,3],
[57,26,14,4],
[57,26,14,5],
[57,26,14,6],
[57,26,14,7],
[57,26,14,8],
[57,26,14,10],
[57,26,14,11],
[57,26,14,12],
[57,26,16,0],
[57,26,16,1],
[57,26,16,2],
[57,26,16,3],
[57,26,16,4],
[57,26,16,5],
[57,26,16,6],
[57,26,16,7],
[57,26,16,8],
[57,26,16,10],
[57,26,16,11],
[57,26,16,12],
[57,26,16,13],
[57,26,16,14],
[57,26,17,0],
[57,26,17,1],
[57,26,17,2],
[57,26,17,3],
[57,26,17,4],
[57,26,17,5],
[57,26,17,6],
[57,26,17,7],
[57,26,17,8],
[57,26,17,10],
[57,26,17,11],
[57,26,17,12],
[57,26,17,13],
[57,26,17,14],
[57,26,17,16],
[57,26,19,0],
[57,26,19,1],
[57,26,19,2],
[57,26,19,3],
[57,26,19,4],
[57,26,19,5],
[57,26,19,6],
[57,26,19,7],
[57,26,19,8],
[57,26,19,10],
[57,26,19,11],
[57,26,19,12],
[57,26,19,16],
[57,26,19,17],
[57,26,20,0],
[57,26,20,1],
[57,26,20,2],
[57,26,20,3],
[57,26,20,4],
[57,26,20,5],
[57,26,20,6],
[57,26,20,7],
[57,26,20,8],
[57,26,20,11],
[57,26,20,12],
[57,26,20,13],
[57,26,20,14],
[57,26,20,16],
[57,26,20,17],
[57,26,20,19],
[57,26,21,0],
[57,26,21,1],
[57,26,21,2],
[57,26,21,4],
[57,26,21,7],
[57,26,21,10],
[57,26,21,11],
[57,26,21,12],
[57,26,21,13],
[57,26,21,14],
[57,26,21,16],
[57,26,21,17],
[57,26,21,19],
[57,26,21,20],
[57,26,22,0],
[57,26,22,1],
[57,26,22,2],
[57,26,22,3],
[57,26,22,4],
[57,26,22,5],
[57,26,22,6],
[57,26,22,7],
[57,26,22,8],
[57,26,22,11],
[57,26,22,12],
[57,26,22,13],
[57,26,22,14],
[57,26,22,16],
[57,26,22,17],
[57,26,22,19],
[57,26,22,21],
[57,26,24,0],
[57,26,24,1],
[57,26,24,2],
[57,26,24,3],
[57,26,24,4],
[57,26,24,5],
[57,26,24,6],
[57,26,24,7],
[57,26,24,8],
[57,26,24,10],
[57,26,24,11],
[57,26,24,12],
[57,26,24,13],
[57,26,24,14],
[57,26,24,17],
[57,26,24,19],
[57,26,24,20],
[57,26,24,21],
[57,26,24,22],
[57,26,25,0],
[57,26,25,1],
[57,26,25,2],
[57,26,25,3],
[57,26,25,4],
[57,26,25,5],
[57,26,25,6],
[57,26,25,8],
[57,26,25,10],
[57,26,25,11],
[57,26,25,12],
[57,26,25,13],
[57,26,25,14],
[57,26,25,16],
[57,26,25,17],
[57,26,25,19],
[57,26,25,20],
[57,26,25,21],
[57,26,25,22],
[57,26,25,24],
[57,27,3,0],
[57,27,3,1],
[57,27,3,2],
[57,27,4,0],
[57,27,4,1],
[57,27,4,2],
[57,27,4,3],
[57,27,5,0],
[57,27,5,1],
[57,27,5,2],
[57,27,5,4],
[57,27,6,0],
[57,27,6,1],
[57,27,6,2],
[57,27,6,4],
[57,27,7,0],
[57,27,7,1],
[57,27,7,2],
[57,27,7,3],
[57,27,7,4],
[57,27,7,5],
[57,27,7,6],
[57,27,8,0],
[57,27,8,1],
[57,27,8,2],
[57,27,8,4],
[57,27,8,7],
[57,27,9,0],
[57,27,9,1],
[57,27,9,2],
[57,27,9,3],
[57,27,9,4],
[57,27,9,5],
[57,27,9,6],
[57,27,9,7],
[57,27,9,8],
[57,27,11,0],
[57,27,11,1],
[57,27,11,2],
[57,27,11,3],
[57,27,11,4],
[57,27,11,5],
[57,27,11,6],
[57,27,11,7],
[57,27,11,8],
[57,27,11,9],
[57,27,12,0],
[57,27,12,1],
[57,27,12,2],
[57,27,12,3],
[57,27,12,4],
[57,27,12,5],
[57,27,12,6],
[57,27,12,7],
[57,27,12,8],
[57,27,12,9],
[57,27,13,0],
[57,27,13,1],
[57,27,13,2],
[57,27,13,3],
[57,27,13,4],
[57,27,13,5],
[57,27,13,6],
[57,27,13,7],
[57,27,13,8],
[57,27,13,9],
[57,27,13,11],
[57,27,13,12],
[57,27,14,0],
[57,27,14,1],
[57,27,14,2],
[57,27,14,3],
[57,27,14,4],
[57,27,14,5],
[57,27,14,6],
[57,27,14,7],
[57,27,14,8],
[57,27,14,9],
[57,27,14,11],
[57,27,14,12],
[57,27,15,0],
[57,27,15,1],
[57,27,15,2],
[57,27,15,3],
[57,27,15,4],
[57,27,15,5],
[57,27,15,6],
[57,27,15,7],
[57,27,15,8],
[57,27,15,11],
[57,27,15,12],
[57,27,15,13],
[57,27,15,14],
[57,27,16,0],
[57,27,16,1],
[57,27,16,2],
[57,27,16,3],
[57,27,16,4],
[57,27,16,5],
[57,27,16,6],
[57,27,16,7],
[57,27,16,8],
[57,27,16,9],
[57,27,16,11],
[57,27,16,12],
[57,27,16,13],
[57,27,16,14],
[57,27,16,15],
[57,27,17,0],
[57,27,17,1],
[57,27,17,2],
[57,27,17,3],
[57,27,17,4],
[57,27,17,5],
[57,27,17,6],
[57,27,17,7],
[57,27,17,8],
[57,27,17,9],
[57,27,17,11],
[57,27,17,12],
[57,27,17,13],
[57,27,17,14],
[57,27,17,15],
[57,27,17,16],
[57,27,18,0],
[57,27,18,1],
[57,27,18,2],
[57,27,18,3],
[57,27,18,4],
[57,27,18,5],
[57,27,18,6],
[57,27,18,7],
[57,27,18,8],
[57,27,18,11],
[57,27,18,12],
[57,27,18,13],
[57,27,18,14],
[57,27,18,16],
[57,27,18,17],
[57,27,19,0],
[57,27,19,1],
[57,27,19,2],
[57,27,19,3],
[57,27,19,4],
[57,27,19,5],
[57,27,19,6],
[57,27,19,7],
[57,27,19,8],
[57,27,19,9],
[57,27,19,11],
[57,27,19,12],
[57,27,19,15],
[57,27,19,16],
[57,27,19,17],
[57,27,19,18],
[57,27,21,0],
[57,27,21,1],
[57,27,21,2],
[57,27,21,4],
[57,27,21,7],
[57,27,21,9],
[57,27,21,11],
[57,27,21,12],
[57,27,21,13],
[57,27,21,14],
[57,27,21,15],
[57,27,21,16],
[57,27,21,17],
[57,27,21,18],
[57,27,21,19],
[57,27,24,0],
[57,27,24,1],
[57,27,24,2],
[57,27,24,3],
[57,27,24,4],
[57,27,24,5],
[57,27,24,6],
[57,27,24,7],
[57,27,24,8],
[57,27,24,9],
[57,27,24,11],
[57,27,24,12],
[57,27,24,13],
[57,27,24,14],
[57,27,24,15],
[57,27,24,17],
[57,27,24,18],
[57,27,24,19],
[57,27,24,21],
[57,27,25,0],
[57,27,25,1],
[57,27,25,2],
[57,27,25,3],
[57,27,25,4],
[57,27,25,5],
[57,27,25,6],
[57,27,25,8],
[57,27,25,9],
[57,27,25,11],
[57,27,25,12],
[57,27,25,13],
[57,27,25,14],
[57,27,25,15],
[57,27,25,16],
[57,27,25,17],
[57,27,25,18],
[57,27,25,19],
[57,27,25,21],
[57,27,25,24],
[57,27,26,0],
[57,27,26,1],
[57,27,26,2],
[57,27,26,3],
[57,27,26,4],
[57,27,26,5],
[57,27,26,6],
[57,27,26,7],
[57,27,26,8],
[57,27,26,11],
[57,27,26,12],
[57,27,26,13],
[57,27,26,14],
[57,27,26,16],
[57,27,26,17],
[57,27,26,19],
[57,27,26,21],
[57,27,26,24],
[57,27,26,25],
[57,28,3,0],
[57,28,3,1],
[57,28,3,2],
[57,28,4,0],
[57,28,4,1],
[57,28,4,2],
[57,28,4,3],
[57,28,5,0],
[57,28,5,1],
[57,28,5,2],
[57,28,5,4],
[57,28,6,0],
[57,28,6,1],
[57,28,6,2],
[57,28,6,4],
[57,28,8,0],
[57,28,8,1],
[57,28,8,2],
[57,28,8,4],
[57,28,9,0],
[57,28,9,1],
[57,28,9,2],
[57,28,9,3],
[57,28,9,4],
[57,28,9,5],
[57,28,9,6],
[57,28,9,8],
[57,28,10,0],
[57,28,10,1],
[57,28,10,2],
[57,28,10,3],
[57,28,10,4],
[57,28,10,5],
[57,28,10,6],
[57,28,10,8],
[57,28,10,9],
[57,28,11,0],
[57,28,11,1],
[57,28,11,2],
[57,28,11,3],
[57,28,11,4],
[57,28,11,5],
[57,28,11,6],
[57,28,11,8],
[57,28,11,9],
[57,28,11,10],
[57,28,12,0],
[57,28,12,1],
[57,28,12,2],
[57,28,12,3],
[57,28,12,4],
[57,28,12,5],
[57,28,12,6],
[57,28,12,8],
[57,28,12,9],
[57,28,12,10],
[57,28,13,0],
[57,28,13,1],
[57,28,13,2],
[57,28,13,3],
[57,28,13,4],
[57,28,13,5],
[57,28,13,6],
[57,28,13,8],
[57,28,13,9],
[57,28,13,10],
[57,28,13,11],
[57,28,13,12],
[57,28,14,0],
[57,28,14,1],
[57,28,14,2],
[57,28,14,3],
[57,28,14,4],
[57,28,14,5],
[57,28,14,6],
[57,28,14,8],
[57,28,14,9],
[57,28,14,10],
[57,28,14,11],
[57,28,14,12],
[57,28,15,0],
[57,28,15,1],
[57,28,15,2],
[57,28,15,3],
[57,28,15,4],
[57,28,15,5],
[57,28,15,6],
[57,28,15,8],
[57,28,15,10],
[57,28,15,11],
[57,28,15,12],
[57,28,15,13],
[57,28,15,14],
[57,28,16,0],
[57,28,16,1],
[57,28,16,2],
[57,28,16,3],
[57,28,16,4],
[57,28,16,5],
[57,28,16,6],
[57,28,16,8],
[57,28,16,9],
[57,28,16,10],
[57,28,16,11],
[57,28,16,12],
[57,28,16,13],
[57,28,16,14],
[57,28,16,15],
[57,28,17,0],
[57,28,17,1],
[57,28,17,2],
[57,28,17,3],
[57,28,17,4],
[57,28,17,5],
[57,28,17,6],
[57,28,17,8],
[57,28,17,9],
[57,28,17,10],
[57,28,17,11],
[57,28,17,12],
[57,28,17,13],
[57,28,17,14],
[57,28,17,15],
[57,28,17,16],
[57,28,18,0],
[57,28,18,1],
[57,28,18,2],
[57,28,18,3],
[57,28,18,4],
[57,28,18,5],
[57,28,18,6],
[57,28,18,8],
[57,28,18,10],
[57,28,18,11],
[57,28,18,12],
[57,28,18,13],
[57,28,18,14],
[57,28,18,16],
[57,28,18,17],
[57,28,19,0],
[57,28,19,1],
[57,28,19,2],
[57,28,19,3],
[57,28,19,4],
[57,28,19,5],
[57,28,19,6],
[57,28,19,8],
[57,28,19,9],
[57,28,19,10],
[57,28,19,11],
[57,28,19,12],
[57,28,19,15],
[57,28,19,16],
[57,28,19,17],
[57,28,19,18],
[57,28,20,0],
[57,28,20,1],
[57,28,20,2],
[57,28,20,3],
[57,28,20,4],
[57,28,20,5],
[57,28,20,6],
[57,28,20,8],
[57,28,20,9],
[57,28,20,11],
[57,28,20,12],
[57,28,20,13],
[57,28,20,14],
[57,28,20,15],
[57,28,20,16],
[57,28,20,17],
[57,28,20,18],
[57,28,20,19],
[57,28,21,0],
[57,28,21,1],
[57,28,21,2],
[57,28,21,4],
[57,28,21,9],
[57,28,21,10],
[57,28,21,11],
[57,28,21,12],
[57,28,21,13],
[57,28,21,14],
[57,28,21,15],
[57,28,21,16],
[57,28,21,17],
[57,28,21,18],
[57,28,21,19],
[57,28,21,20],
[57,28,22,0],
[57,28,22,1],
[57,28,22,2],
[57,28,22,3],
[57,28,22,4],
[57,28,22,5],
[57,28,22,6],
[57,28,22,8],
[57,28,22,9],
[57,28,22,11],
[57,28,22,12],
[57,28,22,13],
[57,28,22,14],
[57,28,22,15],
[57,28,22,16],
[57,28,22,17],
[57,28,22,18],
[57,28,22,19],
[57,28,22,21],
[57,28,24,0],
[57,28,24,1],
[57,28,24,2],
[57,28,24,3],
[57,28,24,4],
[57,28,24,5],
[57,28,24,6],
[57,28,24,8],
[57,28,24,9],
[57,28,24,10],
[57,28,24,11],
[57,28,24,12],
[57,28,24,13],
[57,28,24,14],
[57,28,24,15],
[57,28,24,17],
[57,28,24,18],
[57,28,24,19],
[57,28,24,20],
[57,28,24,21],
[57,28,24,22],
[57,28,26,0],
[57,28,26,1],
[57,28,26,2],
[57,28,26,3],
[57,28,26,4],
[57,28,26,5],
[57,28,26,6],
[57,28,26,8],
[57,28,26,10],
[57,28,26,11],
[57,28,26,12],
[57,28,26,13],
[57,28,26,14],
[57,28,26,16],
[57,28,26,17],
[57,28,26,19],
[57,28,26,20],
[57,28,26,21],
[57,28,26,22],
[57,28,26,24],
[57,28,27,0],
[57,28,27,1],
[57,28,27,2],
[57,28,27,3],
[57,28,27,4],
[57,28,27,5],
[57,28,27,6],
[57,28,27,8],
[57,28,27,9],
[57,28,27,11],
[57,28,27,12],
[57,28,27,13],
[57,28,27,14],
[57,28,27,15],
[57,28,27,16],
[57,28,27,17],
[57,28,27,18],
[57,28,27,19],
[57,28,27,21],
[57,28,27,24],
[57,28,27,26],
[57,29,3,0],
[57,29,3,1],
[57,29,3,2],
[57,29,4,0],
[57,29,4,1],
[57,29,4,2],
[57,29,4,3],
[57,29,5,0],
[57,29,5,1],
[57,29,5,2],
[57,29,5,4],
[57,29,6,0],
[57,29,6,1],
[57,29,6,2],
[57,29,6,4],
[57,29,7,0],
[57,29,7,1],
[57,29,7,2],
[57,29,7,3],
[57,29,7,4],
[57,29,7,5],
[57,29,7,6],
[57,29,8,0],
[57,29,8,1],
[57,29,8,2],
[57,29,8,4],
[57,29,8,7],
[57,29,9,0],
[57,29,9,1],
[57,29,9,2],
[57,29,9,3],
[57,29,9,4],
[57,29,9,5],
[57,29,9,6],
[57,29,9,7],
[57,29,9,8],
[57,29,10,0],
[57,29,10,1],
[57,29,10,2],
[57,29,10,3],
[57,29,10,4],
[57,29,10,5],
[57,29,10,6],
[57,29,10,7],
[57,29,10,8],
[57,29,10,9],
[57,29,13,0],
[57,29,13,1],
[57,29,13,2],
[57,29,13,3],
[57,29,13,4],
[57,29,13,5],
[57,29,13,6],
[57,29,13,7],
[57,29,13,8],
[57,29,13,9],
[57,29,13,10],
[57,29,14,0],
[57,29,14,1],
[57,29,14,2],
[57,29,14,3],
[57,29,14,4],
[57,29,14,5],
[57,29,14,6],
[57,29,14,7],
[57,29,14,8],
[57,29,14,9],
[57,29,14,10],
[57,29,15,0],
[57,29,15,1],
[57,29,15,2],
[57,29,15,3],
[57,29,15,4],
[57,29,15,5],
[57,29,15,6],
[57,29,15,7],
[57,29,15,8],
[57,29,15,10],
[57,29,15,13],
[57,29,15,14],
[57,29,16,0],
[57,29,16,1],
[57,29,16,2],
[57,29,16,3],
[57,29,16,4],
[57,29,16,5],
[57,29,16,6],
[57,29,16,7],
[57,29,16,8],
[57,29,16,9],
[57,29,16,10],
[57,29,16,13],
[57,29,16,14],
[57,29,16,15],
[57,29,17,0],
[57,29,17,1],
[57,29,17,2],
[57,29,17,3],
[57,29,17,4],
[57,29,17,5],
[57,29,17,6],
[57,29,17,7],
[57,29,17,8],
[57,29,17,9],
[57,29,17,10],
[57,29,17,13],
[57,29,17,14],
[57,29,17,15],
[57,29,17,16],
[57,29,18,0],
[57,29,18,1],
[57,29,18,2],
[57,29,18,3],
[57,29,18,4],
[57,29,18,5],
[57,29,18,6],
[57,29,18,7],
[57,29,18,8],
[57,29,18,10],
[57,29,18,13],
[57,29,18,14],
[57,29,18,16],
[57,29,18,17],
[57,29,19,0],
[57,29,19,1],
[57,29,19,2],
[57,29,19,3],
[57,29,19,4],
[57,29,19,5],
[57,29,19,6],
[57,29,19,7],
[57,29,19,8],
[57,29,19,9],
[57,29,19,10],
[57,29,19,15],
[57,29,19,16],
[57,29,19,17],
[57,29,19,18],
[57,29,20,0],
[57,29,20,1],
[57,29,20,2],
[57,29,20,3],
[57,29,20,4],
[57,29,20,5],
[57,29,20,6],
[57,29,20,7],
[57,29,20,8],
[57,29,20,9],
[57,29,20,13],
[57,29,20,14],
[57,29,20,15],
[57,29,20,16],
[57,29,20,17],
[57,29,20,18],
[57,29,20,19],
[57,29,21,0],
[57,29,21,1],
[57,29,21,2],
[57,29,21,4],
[57,29,21,7],
[57,29,21,9],
[57,29,21,10],
[57,29,21,13],
[57,29,21,14],
[57,29,21,15],
[57,29,21,16],
[57,29,21,17],
[57,29,21,18],
[57,29,21,19],
[57,29,21,20],
[57,29,22,0],
[57,29,22,1],
[57,29,22,2],
[57,29,22,3],
[57,29,22,4],
[57,29,22,5],
[57,29,22,6],
[57,29,22,7],
[57,29,22,8],
[57,29,22,9],
[57,29,22,13],
[57,29,22,14],
[57,29,22,15],
[57,29,22,16],
[57,29,22,17],
[57,29,22,18],
[57,29,22,19],
[57,29,22,21],
[57,29,24,0],
[57,29,24,1],
[57,29,24,2],
[57,29,24,3],
[57,29,24,4],
[57,29,24,5],
[57,29,24,6],
[57,29,24,7],
[57,29,24,8],
[57,29,24,9],
[57,29,24,10],
[57,29,24,13],
[57,29,24,14],
[57,29,24,15],
[57,29,24,17],
[57,29,24,18],
[57,29,24,19],
[57,29,24,20],
[57,29,24,21],
[57,29,24,22],
[57,29,25,0],
[57,29,25,1],
[57,29,25,2],
[57,29,25,3],
[57,29,25,4],
[57,29,25,5],
[57,29,25,6],
[57,29,25,8],
[57,29,25,9],
[57,29,25,10],
[57,29,25,13],
[57,29,25,14],
[57,29,25,15],
[57,29,25,16],
[57,29,25,17],
[57,29,25,18],
[57,29,25,19],
[57,29,25,20],
[57,29,25,21],
[57,29,25,22],
[57,29,25,24],
[57,29,26,0],
[57,29,26,1],
[57,29,26,2],
[57,29,26,3],
[57,29,26,4],
[57,29,26,5],
[57,29,26,6],
[57,29,26,7],
[57,29,26,8],
[57,29,26,10],
[57,29,26,13],
[57,29,26,14],
[57,29,26,16],
[57,29,26,17],
[57,29,26,19],
[57,29,26,20],
[57,29,26,21],
[57,29,26,22],
[57,29,26,24],
[57,29,26,25],
[57,29,27,0],
[57,29,27,1],
[57,29,27,2],
[57,29,27,3],
[57,29,27,4],
[57,29,27,5],
[57,29,27,6],
[57,29,27,7],
[57,29,27,8],
[57,29,27,9],
[57,29,27,13],
[57,29,27,14],
[57,29,27,15],
[57,29,27,16],
[57,29,27,17],
[57,29,27,18],
[57,29,27,19],
[57,29,27,21],
[57,29,27,25],
[57,29,27,26],
[57,29,28,0],
[57,29,28,1],
[57,29,28,2],
[57,29,28,3],
[57,29,28,4],
[57,29,28,5],
[57,29,28,6],
[57,29,28,8],
[57,29,28,9],
[57,29,28,10],
[57,29,28,13],
[57,29,28,14],
[57,29,28,15],
[57,29,28,16],
[57,29,28,17],
[57,29,28,18],
[57,29,28,19],
[57,29,28,20],
[57,29,28,21],
[57,29,28,24],
[57,29,28,26],
[57,29,28,27],
[57,32,3,0],
[57,32,3,1],
[57,32,3,2],
[57,32,4,0],
[57,32,4,1],
[57,32,4,2],
[57,32,4,3],
[57,32,5,0],
[57,32,5,1],
[57,32,5,2],
[57,32,5,4],
[57,32,6,0],
[57,32,6,1],
[57,32,6,2],
[57,32,6,4],
[57,32,7,0],
[57,32,7,1],
[57,32,7,2],
[57,32,7,3],
[57,32,7,4],
[57,32,7,5],
[57,32,7,6],
[57,32,8,0],
[57,32,8,1],
[57,32,8,2],
[57,32,8,4],
[57,32,8,7],
[57,32,9,0],
[57,32,9,1],
[57,32,9,2],
[57,32,9,3],
[57,32,9,4],
[57,32,9,5],
[57,32,9,6],
[57,32,9,7],
[57,32,9,8],
[57,32,10,0],
[57,32,10,1],
[57,32,10,2],
[57,32,10,3],
[57,32,10,4],
[57,32,10,5],
[57,32,10,6],
[57,32,10,7],
[57,32,10,8],
[57,32,10,9],
[57,32,13,0],
[57,32,13,1],
[57,32,13,2],
[57,32,13,3],
[57,32,13,4],
[57,32,13,5],
[57,32,13,6],
[57,32,13,7],
[57,32,13,8],
[57,32,13,9],
[57,32,13,10],
[57,32,14,0],
[57,32,14,1],
[57,32,14,2],
[57,32,14,3],
[57,32,14,4],
[57,32,14,5],
[57,32,14,6],
[57,32,14,7],
[57,32,14,8],
[57,32,14,9],
[57,32,14,10],
[57,32,15,0],
[57,32,15,1],
[57,32,15,2],
[57,32,15,3],
[57,32,15,4],
[57,32,15,5],
[57,32,15,6],
[57,32,15,7],
[57,32,15,8],
[57,32,15,10],
[57,32,15,13],
[57,32,15,14],
[57,32,16,0],
[57,32,16,1],
[57,32,16,2],
[57,32,16,3],
[57,32,16,4],
[57,32,16,5],
[57,32,16,6],
[57,32,16,7],
[57,32,16,8],
[57,32,16,9],
[57,32,16,10],
[57,32,16,13],
[57,32,16,14],
[57,32,16,15],
[57,32,17,0],
[57,32,17,1],
[57,32,17,2],
[57,32,17,3],
[57,32,17,4],
[57,32,17,5],
[57,32,17,6],
[57,32,17,7],
[57,32,17,8],
[57,32,17,9],
[57,32,17,10],
[57,32,17,13],
[57,32,17,14],
[57,32,17,15],
[57,32,17,16],
[57,32,18,0],
[57,32,18,1],
[57,32,18,2],
[57,32,18,3],
[57,32,18,4],
[57,32,18,5],
[57,32,18,6],
[57,32,18,7],
[57,32,18,8],
[57,32,18,10],
[57,32,18,13],
[57,32,18,14],
[57,32,18,16],
[57,32,18,17],
[57,32,19,0],
[57,32,19,1],
[57,32,19,2],
[57,32,19,3],
[57,32,19,4],
[57,32,19,5],
[57,32,19,6],
[57,32,19,7],
[57,32,19,8],
[57,32,19,9],
[57,32,19,10],
[57,32,19,15],
[57,32,19,16],
[57,32,19,17],
[57,32,19,18],
[57,32,20,0],
[57,32,20,1],
[57,32,20,2],
[57,32,20,3],
[57,32,20,4],
[57,32,20,5],
[57,32,20,6],
[57,32,20,7],
[57,32,20,8],
[57,32,20,9],
[57,32,20,13],
[57,32,20,14],
[57,32,20,15],
[57,32,20,16],
[57,32,20,17],
[57,32,20,18],
[57,32,20,19],
[57,32,21,0],
[57,32,21,1],
[57,32,21,2],
[57,32,21,4],
[57,32,21,7],
[57,32,21,9],
[57,32,21,10],
[57,32,21,13],
[57,32,21,14],
[57,32,21,15],
[57,32,21,16],
[57,32,21,17],
[57,32,21,18],
[57,32,21,19],
[57,32,21,20],
[57,32,22,0],
[57,32,22,1],
[57,32,22,2],
[57,32,22,3],
[57,32,22,4],
[57,32,22,5],
[57,32,22,6],
[57,32,22,7],
[57,32,22,8],
[57,32,22,9],
[57,32,22,13],
[57,32,22,14],
[57,32,22,15],
[57,32,22,16],
[57,32,22,17],
[57,32,22,18],
[57,32,22,19],
[57,32,22,21],
[57,32,24,0],
[57,32,24,1],
[57,32,24,2],
[57,32,24,3],
[57,32,24,4],
[57,32,24,5],
[57,32,24,6],
[57,32,24,7],
[57,32,24,8],
[57,32,24,9],
[57,32,24,10],
[57,32,24,13],
[57,32,24,14],
[57,32,24,15],
[57,32,24,17],
[57,32,24,18],
[57,32,24,19],
[57,32,24,20],
[57,32,24,21],
[57,32,24,22],
[57,32,25,0],
[57,32,25,1],
[57,32,25,2],
[57,32,25,3],
[57,32,25,4],
[57,32,25,5],
[57,32,25,6],
[57,32,25,8],
[57,32,25,9],
[57,32,25,10],
[57,32,25,13],
[57,32,25,14],
[57,32,25,15],
[57,32,25,16],
[57,32,25,17],
[57,32,25,18],
[57,32,25,19],
[57,32,25,20],
[57,32,25,21],
[57,32,25,24],
[57,32,26,0],
[57,32,26,1],
[57,32,26,2],
[57,32,26,3],
[57,32,26,4],
[57,32,26,5],
[57,32,26,6],
[57,32,26,7],
[57,32,26,8],
[57,32,26,10],
[57,32,26,13],
[57,32,26,14],
[57,32,26,16],
[57,32,26,17],
[57,32,26,19],
[57,32,26,20],
[57,32,26,22],
[57,32,26,24],
[57,32,26,25],
[57,32,27,0],
[57,32,27,1],
[57,32,27,2],
[57,32,27,3],
[57,32,27,4],
[57,32,27,5],
[57,32,27,6],
[57,32,27,7],
[57,32,27,8],
[57,32,27,9],
[57,32,27,13],
[57,32,27,14],
[57,32,27,15],
[57,32,27,16],
[57,32,27,17],
[57,32,27,18],
[57,32,27,19],
[57,32,27,21],
[57,32,27,24],
[57,32,27,25],
[57,32,27,26],
[57,32,28,0],
[57,32,28,1],
[57,32,28,2],
[57,32,28,3],
[57,32,28,4],
[57,32,28,5],
[57,32,28,6],
[57,32,28,8],
[57,32,28,9],
[57,32,28,10],
[57,32,28,13],
[57,32,28,14],
[57,32,28,15],
[57,32,28,16],
[57,32,28,17],
[57,32,28,18],
[57,32,28,20],
[57,32,28,21],
[57,32,28,22],
[57,32,28,24],
[57,32,28,26],
[57,32,28,27],
[57,33,3,0],
[57,33,3,1],
[57,33,3,2],
[57,33,4,0],
[57,33,4,1],
[57,33,4,2],
[57,33,4,3],
[57,33,5,0],
[57,33,5,1],
[57,33,5,2],
[57,33,5,4],
[57,33,6,0],
[57,33,6,1],
[57,33,6,2],
[57,33,6,4],
[57,33,7,0],
[57,33,7,1],
[57,33,7,2],
[57,33,7,3],
[57,33,7,4],
[57,33,7,5],
[57,33,7,6],
[57,33,8,0],
[57,33,8,1],
[57,33,8,2],
[57,33,8,4],
[57,33,8,7],
[57,33,9,0],
[57,33,9,1],
[57,33,9,2],
[57,33,9,3],
[57,33,9,4],
[57,33,9,5],
[57,33,9,6],
[57,33,9,7],
[57,33,9,8],
[57,33,10,0],
[57,33,10,1],
[57,33,10,2],
[57,33,10,3],
[57,33,10,4],
[57,33,10,5],
[57,33,10,6],
[57,33,10,7],
[57,33,10,8],
[57,33,10,9],
[57,33,13,0],
[57,33,13,1],
[57,33,13,2],
[57,33,13,3],
[57,33,13,4],
[57,33,13,5],
[57,33,13,6],
[57,33,13,7],
[57,33,13,8],
[57,33,13,9],
[57,33,13,10],
[57,33,14,0],
[57,33,14,1],
[57,33,14,2],
[57,33,14,3],
[57,33,14,4],
[57,33,14,5],
[57,33,14,6],
[57,33,14,7],
[57,33,14,8],
[57,33,14,9],
[57,33,14,10],
[57,33,15,0],
[57,33,15,1],
[57,33,15,2],
[57,33,15,3],
[57,33,15,4],
[57,33,15,5],
[57,33,15,6],
[57,33,15,7],
[57,33,15,8],
[57,33,15,10],
[57,33,15,13],
[57,33,15,14],
[57,33,16,0],
[57,33,16,1],
[57,33,16,2],
[57,33,16,3],
[57,33,16,4],
[57,33,16,5],
[57,33,16,6],
[57,33,16,7],
[57,33,16,8],
[57,33,16,9],
[57,33,16,10],
[57,33,16,13],
[57,33,16,14],
[57,33,16,15],
[57,33,17,0],
[57,33,17,1],
[57,33,17,2],
[57,33,17,3],
[57,33,17,4],
[57,33,17,5],
[57,33,17,6],
[57,33,17,7],
[57,33,17,8],
[57,33,17,9],
[57,33,17,10],
[57,33,17,13],
[57,33,17,14],
[57,33,17,15],
[57,33,17,16],
[57,33,18,0],
[57,33,18,1],
[57,33,18,2],
[57,33,18,3],
[57,33,18,4],
[57,33,18,5],
[57,33,18,6],
[57,33,18,7],
[57,33,18,8],
[57,33,18,10],
[57,33,18,13],
[57,33,18,14],
[57,33,18,16],
[57,33,18,17],
[57,33,19,0],
[57,33,19,1],
[57,33,19,2],
[57,33,19,3],
[57,33,19,4],
[57,33,19,5],
[57,33,19,6],
[57,33,19,7],
[57,33,19,8],
[57,33,19,9],
[57,33,19,10],
[57,33,19,15],
[57,33,19,16],
[57,33,19,17],
[57,33,19,18],
[57,33,20,0],
[57,33,20,1],
[57,33,20,2],
[57,33,20,3],
[57,33,20,4],
[57,33,20,5],
[57,33,20,6],
[57,33,20,7],
[57,33,20,8],
[57,33,20,9],
[57,33,20,13],
[57,33,20,14],
[57,33,20,15],
[57,33,20,16],
[57,33,20,17],
[57,33,20,18],
[57,33,20,19],
[57,33,21,0],
[57,33,21,1],
[57,33,21,2],
[57,33,21,4],
[57,33,21,7],
[57,33,21,9],
[57,33,21,10],
[57,33,21,13],
[57,33,21,14],
[57,33,21,15],
[57,33,21,16],
[57,33,21,17],
[57,33,21,18],
[57,33,21,19],
[57,33,21,20],
[57,33,22,0],
[57,33,22,1],
[57,33,22,2],
[57,33,22,3],
[57,33,22,4],
[57,33,22,5],
[57,33,22,6],
[57,33,22,7],
[57,33,22,8],
[57,33,22,9],
[57,33,22,13],
[57,33,22,14],
[57,33,22,15],
[57,33,22,16],
[57,33,22,17],
[57,33,22,18],
[57,33,22,19],
[57,33,22,21],
[57,33,24,0],
[57,33,24,1],
[57,33,24,2],
[57,33,24,3],
[57,33,24,4],
[57,33,24,5],
[57,33,24,6],
[57,33,24,7],
[57,33,24,8],
[57,33,24,9],
[57,33,24,10],
[57,33,24,13],
[57,33,24,14],
[57,33,24,15],
[57,33,24,17],
[57,33,24,18],
[57,33,24,19],
[57,33,24,20],
[57,33,24,21],
[57,33,24,22],
[57,33,25,0],
[57,33,25,1],
[57,33,25,2],
[57,33,25,3],
[57,33,25,4],
[57,33,25,5],
[57,33,25,6],
[57,33,25,8],
[57,33,25,9],
[57,33,25,10],
[57,33,25,13],
[57,33,25,14],
[57,33,25,15],
[57,33,25,16],
[57,33,25,17],
[57,33,25,18],
[57,33,25,19],
[57,33,25,20],
[57,33,25,22],
[57,33,25,24],
[57,33,26,0],
[57,33,26,1],
[57,33,26,2],
[57,33,26,3],
[57,33,26,4],
[57,33,26,5],
[57,33,26,6],
[57,33,26,7],
[57,33,26,8],
[57,33,26,10],
[57,33,26,13],
[57,33,26,14],
[57,33,26,16],
[57,33,26,17],
[57,33,26,19],
[57,33,26,21],
[57,33,26,22],
[57,33,26,24],
[57,33,26,25],
[57,33,27,0],
[57,33,27,1],
[57,33,27,2],
[57,33,27,3],
[57,33,27,4],
[57,33,27,5],
[57,33,27,6],
[57,33,27,7],
[57,33,27,8],
[57,33,27,9],
[57,33,27,13],
[57,33,27,14],
[57,33,27,15],
[57,33,27,16],
[57,33,27,17],
[57,33,27,18],
[57,33,27,21],
[57,33,27,24],
[57,33,27,25],
[57,33,27,26],
[57,33,28,0],
[57,33,28,1],
[57,33,28,2],
[57,33,28,3],
[57,33,28,4],
[57,33,28,5],
[57,33,28,6],
[57,33,28,8],
[57,33,28,9],
[57,33,28,10],
[57,33,28,13],
[57,33,28,14],
[57,33,28,15],
[57,33,28,16],
[57,33,28,17],
[57,33,28,19],
[57,33,28,20],
[57,33,28,21],
[57,33,28,22],
[57,33,28,24],
[57,33,28,26],
[57,33,28,27],
[57,34,3,0],
[57,34,3,1],
[57,34,3,2],
[57,34,4,0],
[57,34,4,1],
[57,34,4,2],
[57,34,4,3],
[57,34,5,0],
[57,34,5,1],
[57,34,5,2],
[57,34,5,4],
[57,34,6,0],
[57,34,6,1],
[57,34,6,2],
[57,34,6,4],
[57,34,8,0],
[57,34,8,1],
[57,34,8,2],
[57,34,8,4],
[57,34,9,0],
[57,34,9,1],
[57,34,9,2],
[57,34,9,3],
[57,34,9,4],
[57,34,9,5],
[57,34,9,6],
[57,34,9,8],
[57,34,10,0],
[57,34,10,1],
[57,34,10,2],
[57,34,10,3],
[57,34,10,4],
[57,34,10,5],
[57,34,10,6],
[57,34,10,8],
[57,34,10,9],
[57,34,11,0],
[57,34,11,1],
[57,34,11,2],
[57,34,11,3],
[57,34,11,4],
[57,34,11,5],
[57,34,11,6],
[57,34,11,8],
[57,34,11,9],
[57,34,11,10],
[57,34,12,0],
[57,34,12,1],
[57,34,12,2],
[57,34,12,3],
[57,34,12,4],
[57,34,12,5],
[57,34,12,6],
[57,34,12,8],
[57,34,12,9],
[57,34,12,10],
[57,34,13,0],
[57,34,13,1],
[57,34,13,2],
[57,34,13,3],
[57,34,13,4],
[57,34,13,5],
[57,34,13,6],
[57,34,13,8],
[57,34,13,9],
[57,34,13,10],
[57,34,13,11],
[57,34,13,12],
[57,34,14,0],
[57,34,14,1],
[57,34,14,2],
[57,34,14,3],
[57,34,14,4],
[57,34,14,5],
[57,34,14,6],
[57,34,14,8],
[57,34,14,9],
[57,34,14,10],
[57,34,14,11],
[57,34,14,12],
[57,34,15,0],
[57,34,15,1],
[57,34,15,2],
[57,34,15,3],
[57,34,15,4],
[57,34,15,5],
[57,34,15,6],
[57,34,15,8],
[57,34,15,10],
[57,34,15,11],
[57,34,15,12],
[57,34,15,13],
[57,34,15,14],
[57,34,16,0],
[57,34,16,1],
[57,34,16,2],
[57,34,16,3],
[57,34,16,4],
[57,34,16,5],
[57,34,16,6],
[57,34,16,8],
[57,34,16,9],
[57,34,16,10],
[57,34,16,11],
[57,34,16,12],
[57,34,16,13],
[57,34,16,14],
[57,34,16,15],
[57,34,17,0],
[57,34,17,1],
[57,34,17,2],
[57,34,17,3],
[57,34,17,4],
[57,34,17,5],
[57,34,17,6],
[57,34,17,8],
[57,34,17,9],
[57,34,17,10],
[57,34,17,11],
[57,34,17,12],
[57,34,17,13],
[57,34,17,14],
[57,34,17,15],
[57,34,17,16],
[57,34,18,0],
[57,34,18,1],
[57,34,18,2],
[57,34,18,3],
[57,34,18,4],
[57,34,18,5],
[57,34,18,6],
[57,34,18,8],
[57,34,18,10],
[57,34,18,11],
[57,34,18,12],
[57,34,18,13],
[57,34,18,14],
[57,34,18,16],
[57,34,18,17],
[57,34,19,0],
[57,34,19,1],
[57,34,19,2],
[57,34,19,3],
[57,34,19,4],
[57,34,19,5],
[57,34,19,6],
[57,34,19,8],
[57,34,19,9],
[57,34,19,10],
[57,34,19,11],
[57,34,19,12],
[57,34,19,15],
[57,34,19,16],
[57,34,19,17],
[57,34,19,18],
[57,34,20,0],
[57,34,20,1],
[57,34,20,2],
[57,34,20,3],
[57,34,20,4],
[57,34,20,5],
[57,34,20,6],
[57,34,20,8],
[57,34,20,9],
[57,34,20,11],
[57,34,20,12],
[57,34,20,13],
[57,34,20,14],
[57,34,20,15],
[57,34,20,16],
[57,34,20,17],
[57,34,20,18],
[57,34,20,19],
[57,34,21,0],
[57,34,21,1],
[57,34,21,2],
[57,34,21,4],
[57,34,21,9],
[57,34,21,10],
[57,34,21,11],
[57,34,21,12],
[57,34,21,13],
[57,34,21,14],
[57,34,21,15],
[57,34,21,16],
[57,34,21,17],
[57,34,21,18],
[57,34,21,19],
[57,34,21,20],
[57,34,22,0],
[57,34,22,1],
[57,34,22,2],
[57,34,22,3],
[57,34,22,4],
[57,34,22,5],
[57,34,22,6],
[57,34,22,8],
[57,34,22,9],
[57,34,22,11],
[57,34,22,12],
[57,34,22,13],
[57,34,22,14],
[57,34,22,15],
[57,34,22,16],
[57,34,22,17],
[57,34,22,18],
[57,34,22,19],
[57,34,22,21],
[57,34,24,0],
[57,34,24,1],
[57,34,24,2],
[57,34,24,3],
[57,34,24,4],
[57,34,24,5],
[57,34,24,6],
[57,34,24,8],
[57,34,24,9],
[57,34,24,10],
[57,34,24,11],
[57,34,24,12],
[57,34,24,13],
[57,34,24,14],
[57,34,24,15],
[57,34,24,17],
[57,34,24,18],
[57,34,24,19],
[57,34,24,20],
[57,34,24,21],
[57,34,26,0],
[57,34,26,1],
[57,34,26,2],
[57,34,26,3],
[57,34,26,4],
[57,34,26,5],
[57,34,26,6],
[57,34,26,8],
[57,34,26,10],
[57,34,26,11],
[57,34,26,12],
[57,34,26,13],
[57,34,26,14],
[57,34,26,16],
[57,34,26,17],
[57,34,26,20],
[57,34,26,21],
[57,34,26,22],
[57,34,26,24],
[57,34,27,0],
[57,34,27,1],
[57,34,27,2],
[57,34,27,3],
[57,34,27,4],
[57,34,27,5],
[57,34,27,6],
[57,34,27,8],
[57,34,27,9],
[57,34,27,11],
[57,34,27,12],
[57,34,27,13],
[57,34,27,14],
[57,34,27,15],
[57,34,27,16],
[57,34,27,17],
[57,34,27,19],
[57,34,27,21],
[57,34,27,24],
[57,34,27,26],
[57,34,29,0],
[57,34,29,1],
[57,34,29,2],
[57,34,29,3],
[57,34,29,4],
[57,34,29,5],
[57,34,29,6],
[57,34,29,8],
[57,34,29,9],
[57,34,29,10],
[57,34,29,13],
[57,34,29,14],
[57,34,29,16],
[57,34,29,17],
[57,34,29,18],
[57,34,29,19],
[57,34,29,20],
[57,34,29,21],
[57,34,29,22],
[57,34,29,24],
[57,34,29,26],
[57,34,29,27],
[57,34,32,0],
[57,34,32,1],
[57,34,32,2],
[57,34,32,3],
[57,34,32,4],
[57,34,32,5],
[57,34,32,6],
[57,34,32,8],
[57,34,32,9],
[57,34,32,13],
[57,34,32,14],
[57,34,32,15],
[57,34,32,16],
[57,34,32,17],
[57,34,32,18],
[57,34,32,19],
[57,34,32,20],
[57,34,32,21],
[57,34,32,22],
[57,34,32,24],
[57,34,33,0],
[57,34,33,1],
[57,34,33,2],
[57,34,33,3],
[57,34,33,4],
[57,34,33,5],
[57,34,33,6],
[57,34,33,10],
[57,34,33,13],
[57,34,33,14],
[57,34,33,15],
[57,34,33,16],
[57,34,33,17],
[57,34,33,18],
[57,34,33,19],
[57,34,33,20],
[57,34,33,21],
[57,34,33,22],
[57,34,33,24],
[57,35,3,0],
[57,35,3,1],
[57,35,3,2],
[57,35,4,0],
[57,35,4,1],
[57,35,4,2],
[57,35,4,3],
[57,35,5,0],
[57,35,5,1],
[57,35,5,2],
[57,35,5,4],
[57,35,6,0],
[57,35,6,1],
[57,35,6,2],
[57,35,6,4],
[57,35,7,0],
[57,35,7,1],
[57,35,7,2],
[57,35,7,3],
[57,35,7,4],
[57,35,7,5],
[57,35,7,6],
[57,35,8,0],
[57,35,8,1],
[57,35,8,2],
[57,35,8,4],
[57,35,8,7],
[57,35,10,0],
[57,35,10,1],
[57,35,10,2],
[57,35,10,3],
[57,35,10,4],
[57,35,10,5],
[57,35,10,6],
[57,35,10,7],
[57,35,10,8],
[57,35,11,0],
[57,35,11,1],
[57,35,11,2],
[57,35,11,3],
[57,35,11,4],
[57,35,11,5],
[57,35,11,6],
[57,35,11,7],
[57,35,11,8],
[57,35,11,10],
[57,35,12,0],
[57,35,12,1],
[57,35,12,2],
[57,35,12,3],
[57,35,12,4],
[57,35,12,5],
[57,35,12,6],
[57,35,12,7],
[57,35,12,8],
[57,35,12,10],
[57,35,13,0],
[57,35,13,1],
[57,35,13,2],
[57,35,13,3],
[57,35,13,4],
[57,35,13,5],
[57,35,13,6],
[57,35,13,7],
[57,35,13,8],
[57,35,13,10],
[57,35,13,11],
[57,35,13,12],
[57,35,14,0],
[57,35,14,1],
[57,35,14,2],
[57,35,14,3],
[57,35,14,4],
[57,35,14,5],
[57,35,14,6],
[57,35,14,7],
[57,35,14,8],
[57,35,14,10],
[57,35,14,11],
[57,35,14,12],
[57,35,16,0],
[57,35,16,1],
[57,35,16,2],
[57,35,16,3],
[57,35,16,4],
[57,35,16,5],
[57,35,16,6],
[57,35,16,7],
[57,35,16,8],
[57,35,16,10],
[57,35,16,11],
[57,35,16,12],
[57,35,16,13],
[57,35,16,14],
[57,35,17,0],
[57,35,17,1],
[57,35,17,2],
[57,35,17,3],
[57,35,17,4],
[57,35,17,5],
[57,35,17,6],
[57,35,17,7],
[57,35,17,8],
[57,35,17,10],
[57,35,17,11],
[57,35,17,12],
[57,35,17,13],
[57,35,17,14],
[57,35,17,16],
[57,35,19,0],
[57,35,19,1],
[57,35,19,2],
[57,35,19,3],
[57,35,19,4],
[57,35,19,5],
[57,35,19,6],
[57,35,19,7],
[57,35,19,8],
[57,35,19,10],
[57,35,19,11],
[57,35,19,12],
[57,35,19,16],
[57,35,19,17],
[57,35,20,0],
[57,35,20,1],
[57,35,20,2],
[57,35,20,3],
[57,35,20,4],
[57,35,20,5],
[57,35,20,6],
[57,35,20,7],
[57,35,20,8],
[57,35,20,11],
[57,35,20,12],
[57,35,20,13],
[57,35,20,14],
[57,35,20,16],
[57,35,20,17],
[57,35,20,19],
[57,35,21,0],
[57,35,21,1],
[57,35,21,2],
[57,35,21,4],
[57,35,21,7],
[57,35,21,10],
[57,35,21,11],
[57,35,21,12],
[57,35,21,13],
[57,35,21,14],
[57,35,21,16],
[57,35,21,17],
[57,35,21,19],
[57,35,21,20],
[57,35,22,0],
[57,35,22,1],
[57,35,22,2],
[57,35,22,3],
[57,35,22,4],
[57,35,22,5],
[57,35,22,6],
[57,35,22,7],
[57,35,22,8],
[57,35,22,11],
[57,35,22,12],
[57,35,22,13],
[57,35,22,14],
[57,35,22,16],
[57,35,22,17],
[57,35,22,19],
[57,35,22,21],
[57,35,24,0],
[57,35,24,1],
[57,35,24,2],
[57,35,24,3],
[57,35,24,4],
[57,35,24,5],
[57,35,24,6],
[57,35,24,7],
[57,35,24,8],
[57,35,24,10],
[57,35,24,11],
[57,35,24,12],
[57,35,24,13],
[57,35,24,14],
[57,35,24,17],
[57,35,24,19],
[57,35,24,20],
[57,35,24,22],
[57,35,25,0],
[57,35,25,1],
[57,35,25,2],
[57,35,25,3],
[57,35,25,4],
[57,35,25,5],
[57,35,25,6],
[57,35,25,8],
[57,35,25,10],
[57,35,25,11],
[57,35,25,12],
[57,35,25,13],
[57,35,25,14],
[57,35,25,16],
[57,35,25,17],
[57,35,25,20],
[57,35,25,21],
[57,35,25,22],
[57,35,25,24],
[57,35,27,0],
[57,35,27,1],
[57,35,27,2],
[57,35,27,3],
[57,35,27,4],
[57,35,27,5],
[57,35,27,6],
[57,35,27,7],
[57,35,27,8],
[57,35,27,11],
[57,35,27,12],
[57,35,27,13],
[57,35,27,14],
[57,35,27,19],
[57,35,27,21],
[57,35,27,24],
[57,35,27,25],
[57,35,28,0],
[57,35,28,1],
[57,35,28,2],
[57,35,28,3],
[57,35,28,4],
[57,35,28,5],
[57,35,28,6],
[57,35,28,8],
[57,35,28,10],
[57,35,28,11],
[57,35,28,12],
[57,35,28,13],
[57,35,28,14],
[57,35,28,16],
[57,35,28,17],
[57,35,28,19],
[57,35,28,20],
[57,35,28,21],
[57,35,28,22],
[57,35,28,24],
[57,35,28,27],
[57,35,29,0],
[57,35,29,1],
[57,35,29,2],
[57,35,29,3],
[57,35,29,4],
[57,35,29,5],
[57,35,29,6],
[57,35,29,7],
[57,35,29,8],
[57,35,29,10],
[57,35,29,13],
[57,35,29,16],
[57,35,29,17],
[57,35,29,19],
[57,35,29,20],
[57,35,29,21],
[57,35,29,22],
[57,35,29,24],
[57,35,29,25],
[57,35,29,27],
[57,35,32,0],
[57,35,32,1],
[57,35,32,2],
[57,35,32,3],
[57,35,32,4],
[57,35,32,5],
[57,35,32,6],
[57,35,32,7],
[57,35,32,10],
[57,35,32,13],
[57,35,32,14],
[57,35,32,16],
[57,35,32,17],
[57,35,32,19],
[57,35,32,20],
[57,35,32,21],
[57,35,32,22],
[57,35,32,24],
[57,35,33,0],
[57,35,33,1],
[57,35,33,2],
[57,35,33,3],
[57,35,33,4],
[57,35,33,5],
[57,35,33,8],
[57,35,33,10],
[57,35,33,13],
[57,35,33,14],
[57,35,33,16],
[57,35,33,17],
[57,35,33,19],
[57,35,33,20],
[57,35,33,21],
[57,35,33,22],
[57,35,33,24],
[57,35,34,0],
[57,35,34,1],
[57,35,34,2],
[57,35,34,3],
[57,35,34,4],
[57,35,34,6],
[57,35,34,8],
[57,35,34,10],
[57,35,34,11],
[57,35,34,12],
[57,35,34,13],
[57,35,34,14],
[57,35,34,16],
[57,35,34,17],
[57,35,34,19],
[57,35,34,20],
[57,35,34,21],
[57,35,34,22],
[57,36,3,0],
[57,36,3,1],
[57,36,3,2],
[57,36,4,0],
[57,36,4,1],
[57,36,4,2],
[57,36,4,3],
[57,36,5,0],
[57,36,5,1],
[57,36,5,2],
[57,36,5,4],
[57,36,6,0],
[57,36,6,1],
[57,36,6,2],
[57,36,6,4],
[57,36,7,0],
[57,36,7,1],
[57,36,7,2],
[57,36,7,3],
[57,36,7,4],
[57,36,7,5],
[57,36,7,6],
[57,36,8,0],
[57,36,8,1],
[57,36,8,2],
[57,36,8,4],
[57,36,8,7],
[57,36,9,0],
[57,36,9,1],
[57,36,9,2],
[57,36,9,3],
[57,36,9,4],
[57,36,9,5],
[57,36,9,6],
[57,36,9,7],
[57,36,9,8],
[57,36,10,0],
[57,36,10,1],
[57,36,10,2],
[57,36,10,3],
[57,36,10,4],
[57,36,10,5],
[57,36,10,6],
[57,36,10,7],
[57,36,10,8],
[57,36,10,9],
[57,36,11,0],
[57,36,11,1],
[57,36,11,2],
[57,36,11,3],
[57,36,11,4],
[57,36,11,5],
[57,36,11,6],
[57,36,11,7],
[57,36,11,8],
[57,36,11,9],
[57,36,11,10],
[57,36,12,0],
[57,36,12,1],
[57,36,12,2],
[57,36,12,3],
[57,36,12,4],
[57,36,12,5],
[57,36,12,6],
[57,36,12,7],
[57,36,12,8],
[57,36,12,9],
[57,36,12,10],
[57,36,15,0],
[57,36,15,1],
[57,36,15,2],
[57,36,15,3],
[57,36,15,4],
[57,36,15,5],
[57,36,15,6],
[57,36,15,7],
[57,36,15,8],
[57,36,15,10],
[57,36,15,11],
[57,36,15,12],
[57,36,16,0],
[57,36,16,1],
[57,36,16,2],
[57,36,16,3],
[57,36,16,4],
[57,36,16,5],
[57,36,16,6],
[57,36,16,7],
[57,36,16,8],
[57,36,16,9],
[57,36,16,10],
[57,36,16,11],
[57,36,16,12],
[57,36,16,15],
[57,36,17,0],
[57,36,17,1],
[57,36,17,2],
[57,36,17,3],
[57,36,17,4],
[57,36,17,5],
[57,36,17,6],
[57,36,17,7],
[57,36,17,8],
[57,36,17,9],
[57,36,17,10],
[57,36,17,11],
[57,36,17,12],
[57,36,17,15],
[57,36,17,16],
[57,36,18,0],
[57,36,18,1],
[57,36,18,2],
[57,36,18,3],
[57,36,18,4],
[57,36,18,5],
[57,36,18,6],
[57,36,18,7],
[57,36,18,8],
[57,36,18,10],
[57,36,18,11],
[57,36,18,12],
[57,36,18,16],
[57,36,18,17],
[57,36,20,0],
[57,36,20,1],
[57,36,20,2],
[57,36,20,3],
[57,36,20,4],
[57,36,20,5],
[57,36,20,6],
[57,36,20,7],
[57,36,20,8],
[57,36,20,9],
[57,36,20,11],
[57,36,20,12],
[57,36,20,15],
[57,36,20,16],
[57,36,20,17],
[57,36,20,18],
[57,36,21,0],
[57,36,21,1],
[57,36,21,2],
[57,36,21,4],
[57,36,21,7],
[57,36,21,9],
[57,36,21,10],
[57,36,21,11],
[57,36,21,12],
[57,36,21,15],
[57,36,21,16],
[57,36,21,17],
[57,36,21,18],
[57,36,21,20],
[57,36,22,0],
[57,36,22,1],
[57,36,22,2],
[57,36,22,3],
[57,36,22,4],
[57,36,22,5],
[57,36,22,6],
[57,36,22,7],
[57,36,22,8],
[57,36,22,9],
[57,36,22,11],
[57,36,22,12],
[57,36,22,15],
[57,36,22,16],
[57,36,22,17],
[57,36,22,18],
[57,36,24,0],
[57,36,24,1],
[57,36,24,2],
[57,36,24,3],
[57,36,24,4],
[57,36,24,5],
[57,36,24,6],
[57,36,24,7],
[57,36,24,8],
[57,36,24,9],
[57,36,24,10],
[57,36,24,11],
[57,36,24,12],
[57,36,24,15],
[57,36,24,17],
[57,36,24,18],
[57,36,24,21],
[57,36,24,22],
[57,36,25,0],
[57,36,25,1],
[57,36,25,2],
[57,36,25,3],
[57,36,25,4],
[57,36,25,5],
[57,36,25,6],
[57,36,25,8],
[57,36,25,9],
[57,36,25,10],
[57,36,25,11],
[57,36,25,12],
[57,36,25,15],
[57,36,25,16],
[57,36,25,17],
[57,36,25,20],
[57,36,25,21],
[57,36,25,22],
[57,36,25,24],
[57,36,26,0],
[57,36,26,1],
[57,36,26,2],
[57,36,26,3],
[57,36,26,4],
[57,36,26,5],
[57,36,26,6],
[57,36,26,7],
[57,36,26,8],
[57,36,26,10],
[57,36,26,11],
[57,36,26,12],
[57,36,26,20],
[57,36,26,21],
[57,36,26,22],
[57,36,26,24],
[57,36,26,25],
[57,36,27,0],
[57,36,27,1],
[57,36,27,2],
[57,36,27,3],
[57,36,27,4],
[57,36,27,5],
[57,36,27,6],
[57,36,27,7],
[57,36,27,8],
[57,36,27,9],
[57,36,27,11],
[57,36,27,12],
[57,36,27,16],
[57,36,27,17],
[57,36,27,18],
[57,36,27,21],
[57,36,27,24],
[57,36,27,25],
[57,36,27,26],
[57,36,28,0],
[57,36,28,1],
[57,36,28,2],
[57,36,28,3],
[57,36,28,4],
[57,36,28,5],
[57,36,28,6],
[57,36,28,8],
[57,36,28,9],
[57,36,28,10],
[57,36,28,11],
[57,36,28,12],
[57,36,28,15],
[57,36,28,16],
[57,36,28,17],
[57,36,28,18],
[57,36,28,20],
[57,36,28,21],
[57,36,28,22],
[57,36,28,24],
[57,36,28,26],
[57,36,28,27],
[57,36,29,0],
[57,36,29,1],
[57,36,29,2],
[57,36,29,3],
[57,36,29,4],
[57,36,29,5],
[57,36,29,6],
[57,36,29,7],
[57,36,29,8],
[57,36,29,9],
[57,36,29,10],
[57,36,29,15],
[57,36,29,16],
[57,36,29,17],
[57,36,29,18],
[57,36,29,20],
[57,36,29,21],
[57,36,29,22],
[57,36,29,24],
[57,36,29,25],
[57,36,29,26],
[57,36,32,0],
[57,36,32,1],
[57,36,32,2],
[57,36,32,3],
[57,36,32,4],
[57,36,32,5],
[57,36,32,8],
[57,36,32,9],
[57,36,32,10],
[57,36,32,15],
[57,36,32,16],
[57,36,32,17],
[57,36,32,18],
[57,36,32,20],
[57,36,32,21],
[57,36,32,22],
[57,36,32,24],
[57,36,33,0],
[57,36,33,1],
[57,36,33,2],
[57,36,33,3],
[57,36,33,4],
[57,36,33,6],
[57,36,33,7],
[57,36,33,8],
[57,36,33,9],
[57,36,33,10],
[57,36,33,15],
[57,36,33,16],
[57,36,33,17],
[57,36,33,18],
[57,36,33,20],
[57,36,33,21],
[57,36,33,22],
[57,36,34,0],
[57,36,34,1],
[57,36,34,2],
[57,36,34,5],
[57,36,34,6],
[57,36,34,8],
[57,36,34,9],
[57,36,34,10],
[57,36,34,11],
[57,36,34,12],
[57,36,34,15],
[57,36,34,16],
[57,36,34,17],
[57,36,34,18],
[57,36,34,20],
[57,36,34,21],
[57,36,35,3],
[57,36,35,4],
[57,36,35,5],
[57,36,35,6],
[57,36,35,7],
[57,36,35,8],
[57,36,35,10],
[57,36,35,11],
[57,36,35,12],
[57,36,35,16],
[57,36,35,17],
[57,36,35,20],
[57,37,3,0],
[57,37,3,1],
[57,37,3,2],
[57,37,5,0],
[57,37,5,1],
[57,37,5,2],
[57,37,6,0],
[57,37,6,1],
[57,37,6,2],
[57,37,7,0],
[57,37,7,1],
[57,37,7,2],
[57,37,7,3],
[57,37,7,5],
[57,37,7,6],
[57,37,8,0],
[57,37,8,1],
[57,37,8,2],
[57,37,8,7],
[57,37,9,0],
[57,37,9,1],
[57,37,9,2],
[57,37,9,3],
[57,37,9,5],
[57,37,9,6],
[57,37,9,7],
[57,37,9,8],
[57,37,10,0],
[57,37,10,1],
[57,37,10,2],
[57,37,10,3],
[57,37,10,5],
[57,37,10,6],
[57,37,10,7],
[57,37,10,8],
[57,37,10,9],
[57,37,11,0],
[57,37,11,1],
[57,37,11,2],
[57,37,11,3],
[57,37,11,5],
[57,37,11,6],
[57,37,11,7],
[57,37,11,8],
[57,37,11,9],
[57,37,11,10],
[57,37,12,0],
[57,37,12,1],
[57,37,12,2],
[57,37,12,3],
[57,37,12,5],
[57,37,12,6],
[57,37,12,7],
[57,37,12,8],
[57,37,12,9],
[57,37,12,10],
[57,37,13,0],
[57,37,13,1],
[57,37,13,2],
[57,37,13,3],
[57,37,13,5],
[57,37,13,6],
[57,37,13,7],
[57,37,13,8],
[57,37,13,9],
[57,37,13,10],
[57,37,13,11],
[57,37,13,12],
[57,37,14,0],
[57,37,14,1],
[57,37,14,2],
[57,37,14,3],
[57,37,14,5],
[57,37,14,6],
[57,37,14,7],
[57,37,14,8],
[57,37,14,9],
[57,37,14,10],
[57,37,14,11],
[57,37,14,12],
[57,37,15,0],
[57,37,15,1],
[57,37,15,2],
[57,37,15,3],
[57,37,15,5],
[57,37,15,6],
[57,37,15,7],
[57,37,15,8],
[57,37,15,10],
[57,37,15,11],
[57,37,15,12],
[57,37,15,13],
[57,37,15,14],
[57,37,16,0],
[57,37,16,1],
[57,37,16,2],
[57,37,16,3],
[57,37,16,5],
[57,37,16,6],
[57,37,16,7],
[57,37,16,8],
[57,37,16,9],
[57,37,16,10],
[57,37,16,11],
[57,37,16,12],
[57,37,16,13],
[57,37,16,14],
[57,37,16,15],
[57,37,17,0],
[57,37,17,1],
[57,37,17,2],
[57,37,17,3],
[57,37,17,5],
[57,37,17,6],
[57,37,17,7],
[57,37,17,8],
[57,37,17,9],
[57,37,17,10],
[57,37,17,11],
[57,37,17,12],
[57,37,17,13],
[57,37,17,14],
[57,37,17,15],
[57,37,17,16],
[57,37,18,0],
[57,37,18,1],
[57,37,18,2],
[57,37,18,3],
[57,37,18,5],
[57,37,18,6],
[57,37,18,7],
[57,37,18,8],
[57,37,18,10],
[57,37,18,11],
[57,37,18,12],
[57,37,18,13],
[57,37,18,14],
[57,37,18,16],
[57,37,18,17],
[57,37,19,0],
[57,37,19,1],
[57,37,19,2],
[57,37,19,3],
[57,37,19,5],
[57,37,19,6],
[57,37,19,7],
[57,37,19,8],
[57,37,19,9],
[57,37,19,10],
[57,37,19,11],
[57,37,19,12],
[57,37,19,15],
[57,37,19,16],
[57,37,19,17],
[57,37,19,18],
[57,37,20,0],
[57,37,20,1],
[57,37,20,2],
[57,37,20,3],
[57,37,20,5],
[57,37,20,6],
[57,37,20,7],
[57,37,20,8],
[57,37,20,9],
[57,37,20,11],
[57,37,20,12],
[57,37,20,13],
[57,37,20,14],
[57,37,20,15],
[57,37,20,16],
[57,37,20,17],
[57,37,20,18],
[57,37,20,19],
[57,37,21,0],
[57,37,21,1],
[57,37,21,2],
[57,37,21,7],
[57,37,21,9],
[57,37,21,10],
[57,37,21,11],
[57,37,21,12],
[57,37,21,13],
[57,37,21,14],
[57,37,21,15],
[57,37,21,16],
[57,37,21,17],
[57,37,21,18],
[57,37,21,19],
[57,37,21,20],
[57,37,22,0],
[57,37,22,1],
[57,37,22,2],
[57,37,22,3],
[57,37,22,5],
[57,37,22,6],
[57,37,22,7],
[57,37,22,8],
[57,37,22,9],
[57,37,22,11],
[57,37,22,12],
[57,37,22,13],
[57,37,22,14],
[57,37,22,15],
[57,37,22,16],
[57,37,22,17],
[57,37,22,18],
[57,37,22,19],
[57,37,22,21],
[57,37,24,0],
[57,37,24,1],
[57,37,24,2],
[57,37,24,3],
[57,37,24,5],
[57,37,24,6],
[57,37,24,7],
[57,37,24,8],
[57,37,24,9],
[57,37,24,10],
[57,37,24,11],
[57,37,24,12],
[57,37,24,13],
[57,37,24,14],
[57,37,24,15],
[57,37,24,17],
[57,37,24,18],
[57,37,24,20],
[57,37,24,21],
[57,37,24,22],
[57,37,25,0],
[57,37,25,1],
[57,37,25,2],
[57,37,25,3],
[57,37,25,5],
[57,37,25,6],
[57,37,25,8],
[57,37,25,9],
[57,37,25,10],
[57,37,25,11],
[57,37,25,12],
[57,37,25,13],
[57,37,25,14],
[57,37,25,15],
[57,37,25,18],
[57,37,25,19],
[57,37,25,20],
[57,37,25,21],
[57,37,25,22],
[57,37,25,24],
[57,37,26,0],
[57,37,26,1],
[57,37,26,2],
[57,37,26,3],
[57,37,26,5],
[57,37,26,6],
[57,37,26,7],
[57,37,26,8],
[57,37,26,10],
[57,37,26,11],
[57,37,26,12],
[57,37,26,13],
[57,37,26,14],
[57,37,26,16],
[57,37,26,17],
[57,37,26,19],
[57,37,26,20],
[57,37,26,21],
[57,37,26,22],
[57,37,26,24],
[57,37,26,25],
[57,37,27,0],
[57,37,27,1],
[57,37,27,2],
[57,37,27,3],
[57,37,27,5],
[57,37,27,6],
[57,37,27,7],
[57,37,27,8],
[57,37,27,9],
[57,37,27,11],
[57,37,27,12],
[57,37,27,13],
[57,37,27,15],
[57,37,27,16],
[57,37,27,17],
[57,37,27,18],
[57,37,27,19],
[57,37,27,21],
[57,37,27,24],
[57,37,27,25],
[57,37,27,26],
[57,37,28,0],
[57,37,28,1],
[57,37,28,2],
[57,37,28,3],
[57,37,28,5],
[57,37,28,6],
[57,37,28,8],
[57,37,28,9],
[57,37,28,10],
[57,37,28,11],
[57,37,28,14],
[57,37,28,15],
[57,37,28,16],
[57,37,28,17],
[57,37,28,18],
[57,37,28,19],
[57,37,28,20],
[57,37,28,21],
[57,37,28,22],
[57,37,28,24],
[57,37,28,26],
[57,37,29,0],
[57,37,29,1],
[57,37,29,2],
[57,37,29,3],
[57,37,29,5],
[57,37,29,6],
[57,37,29,7],
[57,37,29,8],
[57,37,29,9],
[57,37,29,13],
[57,37,29,14],
[57,37,29,15],
[57,37,29,16],
[57,37,29,17],
[57,37,29,18],
[57,37,29,19],
[57,37,29,20],
[57,37,29,21],
[57,37,29,22],
[57,37,29,24],
[57,37,29,25],
[57,37,32,0],
[57,37,32,1],
[57,37,32,2],
[57,37,32,3],
[57,37,32,6],
[57,37,32,7],
[57,37,32,8],
[57,37,32,9],
[57,37,32,10],
[57,37,32,13],
[57,37,32,14],
[57,37,32,15],
[57,37,32,16],
[57,37,32,17],
[57,37,32,18],
[57,37,32,19],
[57,37,32,20],
[57,37,32,21],
[57,37,32,22],
[57,37,33,0],
[57,37,33,1],
[57,37,33,2],
[57,37,33,5],
[57,37,33,6],
[57,37,33,7],
[57,37,33,8],
[57,37,33,9],
[57,37,33,10],
[57,37,33,13],
[57,37,33,14],
[57,37,33,15],
[57,37,33,16],
[57,37,33,17],
[57,37,33,18],
[57,37,33,19],
[57,37,33,20],
[57,37,33,21],
[57,37,34,3],
[57,37,34,5],
[57,37,34,6],
[57,37,34,8],
[57,37,34,9],
[57,37,34,10],
[57,37,34,11],
[57,37,34,12],
[57,37,34,13],
[57,37,34,14],
[57,37,34,15],
[57,37,34,16],
[57,37,34,17],
[57,37,34,18],
[57,37,34,19],
[57,37,34,20],
[57,37,35,0],
[57,37,35,1],
[57,37,35,2],
[57,37,35,3],
[57,37,35,5],
[57,37,35,6],
[57,37,35,7],
[57,37,35,8],
[57,37,35,10],
[57,37,35,11],
[57,37,35,12],
[57,37,35,13],
[57,37,35,14],
[57,37,35,16],
[57,37,35,17],
[57,37,35,19],
[57,37,36,0],
[57,37,36,1],
[57,37,36,2],
[57,37,36,3],
[57,37,36,5],
[57,37,36,6],
[57,37,36,7],
[57,37,36,8],
[57,37,36,9],
[57,37,36,10],
[57,37,36,11],
[57,37,36,12],
[57,37,36,15],
[57,37,36,16],
[57,37,36,17],
[57,37,36,18],
[57,38,3,0],
[57,38,3,1],
[57,38,3,2],
[57,38,4,0],
[57,38,4,1],
[57,38,4,2],
[57,38,4,3],
[57,38,5,0],
[57,38,5,1],
[57,38,5,2],
[57,38,5,4],
[57,38,6,0],
[57,38,6,1],
[57,38,6,2],
[57,38,6,4],
[57,38,8,0],
[57,38,8,1],
[57,38,8,2],
[57,38,8,4],
[57,38,9,0],
[57,38,9,1],
[57,38,9,2],
[57,38,9,3],
[57,38,9,4],
[57,38,9,5],
[57,38,9,6],
[57,38,9,8],
[57,38,10,0],
[57,38,10,1],
[57,38,10,2],
[57,38,10,3],
[57,38,10,4],
[57,38,10,5],
[57,38,10,6],
[57,38,10,8],
[57,38,10,9],
[57,38,11,0],
[57,38,11,1],
[57,38,11,2],
[57,38,11,3],
[57,38,11,4],
[57,38,11,5],
[57,38,11,6],
[57,38,11,8],
[57,38,11,9],
[57,38,11,10],
[57,38,12,0],
[57,38,12,1],
[57,38,12,2],
[57,38,12,3],
[57,38,12,4],
[57,38,12,5],
[57,38,12,6],
[57,38,12,8],
[57,38,12,9],
[57,38,12,10],
[57,38,13,0],
[57,38,13,1],
[57,38,13,2],
[57,38,13,3],
[57,38,13,4],
[57,38,13,5],
[57,38,13,6],
[57,38,13,8],
[57,38,13,9],
[57,38,13,10],
[57,38,13,11],
[57,38,13,12],
[57,38,14,0],
[57,38,14,1],
[57,38,14,2],
[57,38,14,3],
[57,38,14,4],
[57,38,14,5],
[57,38,14,6],
[57,38,14,8],
[57,38,14,9],
[57,38,14,10],
[57,38,14,11],
[57,38,14,12],
[57,38,15,0],
[57,38,15,1],
[57,38,15,2],
[57,38,15,3],
[57,38,15,4],
[57,38,15,5],
[57,38,15,6],
[57,38,15,8],
[57,38,15,10],
[57,38,15,11],
[57,38,15,12],
[57,38,15,13],
[57,38,15,14],
[57,38,16,0],
[57,38,16,1],
[57,38,16,2],
[57,38,16,3],
[57,38,16,4],
[57,38,16,5],
[57,38,16,6],
[57,38,16,8],
[57,38,16,9],
[57,38,16,10],
[57,38,16,11],
[57,38,16,12],
[57,38,16,13],
[57,38,16,14],
[57,38,16,15],
[57,38,17,0],
[57,38,17,1],
[57,38,17,2],
[57,38,17,3],
[57,38,17,4],
[57,38,17,5],
[57,38,17,6],
[57,38,17,8],
[57,38,17,9],
[57,38,17,10],
[57,38,17,11],
[57,38,17,12],
[57,38,17,13],
[57,38,17,14],
[57,38,17,15],
[57,38,17,16],
[57,38,18,0],
[57,38,18,1],
[57,38,18,2],
[57,38,18,3],
[57,38,18,4],
[57,38,18,5],
[57,38,18,6],
[57,38,18,8],
[57,38,18,10],
[57,38,18,11],
[57,38,18,12],
[57,38,18,13],
[57,38,18,14],
[57,38,18,16],
[57,38,18,17],
[57,38,19,0],
[57,38,19,1],
[57,38,19,2],
[57,38,19,3],
[57,38,19,4],
[57,38,19,5],
[57,38,19,6],
[57,38,19,8],
[57,38,19,9],
[57,38,19,10],
[57,38,19,11],
[57,38,19,12],
[57,38,19,15],
[57,38,19,16],
[57,38,19,17],
[57,38,19,18],
[57,38,20,0],
[57,38,20,1],
[57,38,20,2],
[57,38,20,3],
[57,38,20,4],
[57,38,20,5],
[57,38,20,6],
[57,38,20,8],
[57,38,20,9],
[57,38,20,11],
[57,38,20,12],
[57,38,20,13],
[57,38,20,14],
[57,38,20,15],
[57,38,20,16],
[57,38,20,17],
[57,38,20,18],
[57,38,20,19],
[57,38,21,0],
[57,38,21,1],
[57,38,21,2],
[57,38,21,4],
[57,38,21,9],
[57,38,21,10],
[57,38,21,11],
[57,38,21,12],
[57,38,21,13],
[57,38,21,14],
[57,38,21,15],
[57,38,21,16],
[57,38,21,17],
[57,38,21,18],
[57,38,21,19],
[57,38,22,0],
[57,38,22,1],
[57,38,22,2],
[57,38,22,3],
[57,38,22,4],
[57,38,22,5],
[57,38,22,6],
[57,38,22,8],
[57,38,22,9],
[57,38,22,11],
[57,38,22,12],
[57,38,22,13],
[57,38,22,14],
[57,38,22,15],
[57,38,22,16],
[57,38,22,17],
[57,38,22,18],
[57,38,22,21],
[57,38,24,0],
[57,38,24,1],
[57,38,24,2],
[57,38,24,3],
[57,38,24,4],
[57,38,24,5],
[57,38,24,6],
[57,38,24,8],
[57,38,24,9],
[57,38,24,10],
[57,38,24,11],
[57,38,24,12],
[57,38,24,13],
[57,38,24,14],
[57,38,24,15],
[57,38,24,17],
[57,38,24,19],
[57,38,24,20],
[57,38,24,21],
[57,38,24,22],
[57,38,26,0],
[57,38,26,1],
[57,38,26,2],
[57,38,26,3],
[57,38,26,4],
[57,38,26,5],
[57,38,26,6],
[57,38,26,8],
[57,38,26,10],
[57,38,26,11],
[57,38,26,12],
[57,38,26,13],
[57,38,26,16],
[57,38,26,17],
[57,38,26,19],
[57,38,26,20],
[57,38,26,21],
[57,38,26,22],
[57,38,26,24],
[57,38,27,0],
[57,38,27,1],
[57,38,27,2],
[57,38,27,3],
[57,38,27,4],
[57,38,27,5],
[57,38,27,6],
[57,38,27,8],
[57,38,27,9],
[57,38,27,11],
[57,38,27,14],
[57,38,27,15],
[57,38,27,16],
[57,38,27,17],
[57,38,27,18],
[57,38,27,19],
[57,38,27,21],
[57,38,27,24],
[57,38,27,26],
[57,38,29,0],
[57,38,29,1],
[57,38,29,2],
[57,38,29,3],
[57,38,29,4],
[57,38,29,5],
[57,38,29,6],
[57,38,29,10],
[57,38,29,13],
[57,38,29,14],
[57,38,29,15],
[57,38,29,16],
[57,38,29,17],
[57,38,29,18],
[57,38,29,19],
[57,38,29,20],
[57,38,29,21],
[57,38,29,22],
[57,38,29,24],
[57,38,32,0],
[57,38,32,1],
[57,38,32,2],
[57,38,32,5],
[57,38,32,6],
[57,38,32,8],
[57,38,32,9],
[57,38,32,10],
[57,38,32,13],
[57,38,32,14],
[57,38,32,15],
[57,38,32,16],
[57,38,32,17],
[57,38,32,18],
[57,38,32,19],
[57,38,32,20],
[57,38,32,21],
[57,38,33,3],
[57,38,33,4],
[57,38,33,5],
[57,38,33,6],
[57,38,33,8],
[57,38,33,9],
[57,38,33,10],
[57,38,33,13],
[57,38,33,14],
[57,38,33,15],
[57,38,33,16],
[57,38,33,17],
[57,38,33,18],
[57,38,33,19],
[57,38,33,20],
[57,38,35,0],
[57,38,35,1],
[57,38,35,2],
[57,38,35,3],
[57,38,35,4],
[57,38,35,5],
[57,38,35,6],
[57,38,35,8],
[57,38,35,10],
[57,38,35,11],
[57,38,35,12],
[57,38,35,13],
[57,38,35,14],
[57,38,35,16],
[57,38,35,17],
[57,38,36,0],
[57,38,36,1],
[57,38,36,2],
[57,38,36,3],
[57,38,36,4],
[57,38,36,5],
[57,38,36,6],
[57,38,36,8],
[57,38,36,9],
[57,38,36,10],
[57,38,36,11],
[57,38,36,12],
[57,38,36,15],
[57,38,36,16],
[57,38,36,17],
[57,38,37,0],
[57,38,37,1],
[57,38,37,2],
[57,38,37,3],
[57,38,37,5],
[57,38,37,6],
[57,38,37,8],
[57,38,37,9],
[57,38,37,10],
[57,38,37,11],
[57,38,37,12],
[57,38,37,13],
[57,38,37,14],
[57,38,37,15],
[57,39,4,0],
[57,39,4,1],
[57,39,4,2],
[57,39,7,0],
[57,39,7,1],
[57,39,7,2],
[57,39,7,4],
[57,39,9,0],
[57,39,9,1],
[57,39,9,2],
[57,39,9,4],
[57,39,9,7],
[57,39,10,0],
[57,39,10,1],
[57,39,10,2],
[57,39,10,4],
[57,39,10,7],
[57,39,10,9],
[57,39,11,0],
[57,39,11,1],
[57,39,11,2],
[57,39,11,4],
[57,39,11,7],
[57,39,11,9],
[57,39,11,10],
[57,39,12,0],
[57,39,12,1],
[57,39,12,2],
[57,39,12,4],
[57,39,12,7],
[57,39,12,9],
[57,39,12,10],
[57,39,13,0],
[57,39,13,1],
[57,39,13,2],
[57,39,13,4],
[57,39,13,7],
[57,39,13,9],
[57,39,13,10],
[57,39,13,11],
[57,39,13,12],
[57,39,14,0],
[57,39,14,1],
[57,39,14,2],
[57,39,14,4],
[57,39,14,7],
[57,39,14,9],
[57,39,14,10],
[57,39,14,11],
[57,39,14,12],
[57,39,15,0],
[57,39,15,1],
[57,39,15,2],
[57,39,15,4],
[57,39,15,7],
[57,39,15,10],
[57,39,15,11],
[57,39,15,12],
[57,39,15,13],
[57,39,15,14],
[57,39,16,0],
[57,39,16,1],
[57,39,16,2],
[57,39,16,4],
[57,39,16,7],
[57,39,16,9],
[57,39,16,10],
[57,39,16,11],
[57,39,16,12],
[57,39,16,13],
[57,39,16,14],
[57,39,16,15],
[57,39,17,0],
[57,39,17,1],
[57,39,17,2],
[57,39,17,4],
[57,39,17,7],
[57,39,17,9],
[57,39,17,10],
[57,39,17,11],
[57,39,17,12],
[57,39,17,13],
[57,39,17,14],
[57,39,17,15],
[57,39,17,16],
[57,39,18,0],
[57,39,18,1],
[57,39,18,2],
[57,39,18,4],
[57,39,18,7],
[57,39,18,10],
[57,39,18,11],
[57,39,18,12],
[57,39,18,13],
[57,39,18,14],
[57,39,18,16],
[57,39,18,17],
[57,39,19,0],
[57,39,19,1],
[57,39,19,2],
[57,39,19,4],
[57,39,19,7],
[57,39,19,9],
[57,39,19,10],
[57,39,19,11],
[57,39,19,12],
[57,39,19,15],
[57,39,19,16],
[57,39,19,17],
[57,39,19,18],
[57,39,20,0],
[57,39,20,1],
[57,39,20,2],
[57,39,20,4],
[57,39,20,7],
[57,39,20,9],
[57,39,20,11],
[57,39,20,12],
[57,39,20,13],
[57,39,20,14],
[57,39,20,15],
[57,39,20,16],
[57,39,20,17],
[57,39,20,18],
[57,39,20,19],
[57,39,22,0],
[57,39,22,1],
[57,39,22,2],
[57,39,22,4],
[57,39,22,7],
[57,39,22,9],
[57,39,22,11],
[57,39,22,12],
[57,39,22,13],
[57,39,22,14],
[57,39,22,15],
[57,39,22,16],
[57,39,22,17],
[57,39,22,19],
[57,39,24,0],
[57,39,24,1],
[57,39,24,2],
[57,39,24,4],
[57,39,24,7],
[57,39,24,9],
[57,39,24,10],
[57,39,24,11],
[57,39,24,12],
[57,39,24,13],
[57,39,24,14],
[57,39,24,15],
[57,39,24,18],
[57,39,24,19],
[57,39,24,20],
[57,39,24,22],
[57,39,25,0],
[57,39,25,1],
[57,39,25,2],
[57,39,25,4],
[57,39,25,9],
[57,39,25,10],
[57,39,25,11],
[57,39,25,12],
[57,39,25,13],
[57,39,25,15],
[57,39,25,16],
[57,39,25,17],
[57,39,25,18],
[57,39,25,19],
[57,39,25,20],
[57,39,25,22],
[57,39,25,24],
[57,39,26,0],
[57,39,26,1],
[57,39,26,2],
[57,39,26,4],
[57,39,26,7],
[57,39,26,10],
[57,39,26,11],
[57,39,26,14],
[57,39,26,16],
[57,39,26,17],
[57,39,26,19],
[57,39,26,20],
[57,39,26,22],
[57,39,26,24],
[57,39,26,25],
[57,39,27,0],
[57,39,27,1],
[57,39,27,2],
[57,39,27,4],
[57,39,27,7],
[57,39,27,9],
[57,39,27,12],
[57,39,27,13],
[57,39,27,14],
[57,39,27,15],
[57,39,27,16],
[57,39,27,17],
[57,39,27,18],
[57,39,27,19],
[57,39,27,24],
[57,39,27,25],
[57,39,28,0],
[57,39,28,1],
[57,39,28,2],
[57,39,28,4],
[57,39,28,10],
[57,39,28,11],
[57,39,28,12],
[57,39,28,13],
[57,39,28,14],
[57,39,28,15],
[57,39,28,16],
[57,39,28,17],
[57,39,28,18],
[57,39,28,19],
[57,39,28,20],
[57,39,28,22],
[57,39,28,24],
[57,39,29,0],
[57,39,29,1],
[57,39,29,2],
[57,39,29,4],
[57,39,29,9],
[57,39,29,10],
[57,39,29,13],
[57,39,29,14],
[57,39,29,15],
[57,39,29,16],
[57,39,29,17],
[57,39,29,18],
[57,39,29,19],
[57,39,29,20],
[57,39,29,22],
[57,39,29,24],
[57,39,32,4],
[57,39,32,7],
[57,39,32,9],
[57,39,32,10],
[57,39,32,13],
[57,39,32,14],
[57,39,32,15],
[57,39,32,16],
[57,39,32,17],
[57,39,32,18],
[57,39,32,19],
[57,39,32,20],
[57,39,33,0],
[57,39,33,1],
[57,39,33,2],
[57,39,33,4],
[57,39,33,7],
[57,39,33,9],
[57,39,33,10],
[57,39,33,13],
[57,39,33,14],
[57,39,33,15],
[57,39,33,16],
[57,39,33,17],
[57,39,33,18],
[57,39,33,19],
[57,39,34,0],
[57,39,34,1],
[57,39,34,2],
[57,39,34,4],
[57,39,34,9],
[57,39,34,10],
[57,39,34,11],
[57,39,34,12],
[57,39,34,13],
[57,39,34,14],
[57,39,34,15],
[57,39,34,16],
[57,39,34,17],
[57,39,34,18],
[57,39,35,0],
[57,39,35,1],
[57,39,35,2],
[57,39,35,4],
[57,39,35,7],
[57,39,35,10],
[57,39,35,11],
[57,39,35,12],
[57,39,35,13],
[57,39,35,14],
[57,39,35,16],
[57,39,35,17],
[57,39,36,0],
[57,39,36,1],
[57,39,36,2],
[57,39,36,4],
[57,39,36,7],
[57,39,36,9],
[57,39,36,10],
[57,39,36,11],
[57,39,36,12],
[57,39,36,15],
[57,39,37,0],
[57,39,37,1],
[57,39,37,2],
[57,39,37,7],
[57,39,37,9],
[57,39,37,10],
[57,39,37,11],
[57,39,37,12],
[57,39,37,13],
[57,39,37,14],
[57,39,38,0],
[57,39,38,1],
[57,39,38,2],
[57,39,38,4],
[57,39,38,9],
[57,39,38,10],
[57,39,38,11],
[57,39,38,12],
[57,39,38,13],
[57,40,3,0],
[57,40,3,1],
[57,40,3,2],
[57,40,4,0],
[57,40,4,1],
[57,40,4,2],
[57,40,4,3],
[57,40,5,0],
[57,40,5,1],
[57,40,5,2],
[57,40,5,4],
[57,40,6,0],
[57,40,6,1],
[57,40,6,2],
[57,40,6,4],
[57,40,7,0],
[57,40,7,1],
[57,40,7,2],
[57,40,7,3],
[57,40,7,4],
[57,40,7,5],
[57,40,7,6],
[57,40,8,0],
[57,40,8,1],
[57,40,8,2],
[57,40,8,4],
[57,40,8,7],
[57,40,10,0],
[57,40,10,1],
[57,40,10,2],
[57,40,10,3],
[57,40,10,4],
[57,40,10,5],
[57,40,10,6],
[57,40,10,7],
[57,40,10,8],
[57,40,11,0],
[57,40,11,1],
[57,40,11,2],
[57,40,11,3],
[57,40,11,4],
[57,40,11,5],
[57,40,11,6],
[57,40,11,7],
[57,40,11,8],
[57,40,11,10],
[57,40,12,0],
[57,40,12,1],
[57,40,12,2],
[57,40,12,3],
[57,40,12,4],
[57,40,12,5],
[57,40,12,6],
[57,40,12,7],
[57,40,12,8],
[57,40,12,10],
[57,40,13,0],
[57,40,13,1],
[57,40,13,2],
[57,40,13,3],
[57,40,13,4],
[57,40,13,5],
[57,40,13,6],
[57,40,13,7],
[57,40,13,8],
[57,40,13,10],
[57,40,13,11],
[57,40,13,12],
[57,40,14,0],
[57,40,14,1],
[57,40,14,2],
[57,40,14,3],
[57,40,14,4],
[57,40,14,5],
[57,40,14,6],
[57,40,14,7],
[57,40,14,8],
[57,40,14,10],
[57,40,14,11],
[57,40,14,12],
[57,40,16,0],
[57,40,16,1],
[57,40,16,2],
[57,40,16,3],
[57,40,16,4],
[57,40,16,5],
[57,40,16,6],
[57,40,16,7],
[57,40,16,8],
[57,40,16,10],
[57,40,16,11],
[57,40,16,12],
[57,40,16,13],
[57,40,16,14],
[57,40,17,0],
[57,40,17,1],
[57,40,17,2],
[57,40,17,3],
[57,40,17,4],
[57,40,17,5],
[57,40,17,6],
[57,40,17,7],
[57,40,17,8],
[57,40,17,10],
[57,40,17,11],
[57,40,17,12],
[57,40,17,13],
[57,40,17,14],
[57,40,17,16],
[57,40,19,0],
[57,40,19,1],
[57,40,19,2],
[57,40,19,3],
[57,40,19,4],
[57,40,19,5],
[57,40,19,6],
[57,40,19,7],
[57,40,19,8],
[57,40,19,10],
[57,40,19,11],
[57,40,19,12],
[57,40,19,16],
[57,40,19,17],
[57,40,20,0],
[57,40,20,1],
[57,40,20,2],
[57,40,20,3],
[57,40,20,4],
[57,40,20,5],
[57,40,20,6],
[57,40,20,7],
[57,40,20,8],
[57,40,20,11],
[57,40,20,12],
[57,40,20,13],
[57,40,20,14],
[57,40,20,16],
[57,40,20,17],
[57,40,21,0],
[57,40,21,1],
[57,40,21,2],
[57,40,21,4],
[57,40,21,7],
[57,40,21,10],
[57,40,21,11],
[57,40,21,12],
[57,40,21,13],
[57,40,21,14],
[57,40,21,16],
[57,40,21,17],
[57,40,21,19],
[57,40,21,20],
[57,40,22,0],
[57,40,22,1],
[57,40,22,2],
[57,40,22,3],
[57,40,22,4],
[57,40,22,5],
[57,40,22,6],
[57,40,22,7],
[57,40,22,8],
[57,40,22,11],
[57,40,22,12],
[57,40,22,13],
[57,40,22,14],
[57,40,22,19],
[57,40,22,21],
[57,40,24,0],
[57,40,24,1],
[57,40,24,2],
[57,40,24,3],
[57,40,24,4],
[57,40,24,5],
[57,40,24,6],
[57,40,24,7],
[57,40,24,8],
[57,40,24,10],
[57,40,24,11],
[57,40,24,12],
[57,40,24,13],
[57,40,24,14],
[57,40,24,17],
[57,40,24,19],
[57,40,24,20],
[57,40,24,21],
[57,40,24,22],
[57,40,25,0],
[57,40,25,1],
[57,40,25,2],
[57,40,25,3],
[57,40,25,4],
[57,40,25,5],
[57,40,25,6],
[57,40,25,8],
[57,40,25,10],
[57,40,25,11],
[57,40,25,14],
[57,40,25,16],
[57,40,25,17],
[57,40,25,19],
[57,40,25,20],
[57,40,25,21],
[57,40,25,22],
[57,40,25,24],
[57,40,27,0],
[57,40,27,1],
[57,40,27,2],
[57,40,27,3],
[57,40,27,4],
[57,40,27,5],
[57,40,27,6],
[57,40,27,7],
[57,40,27,11],
[57,40,27,12],
[57,40,27,13],
[57,40,27,14],
[57,40,27,16],
[57,40,27,17],
[57,40,27,19],
[57,40,27,21],
[57,40,27,24],
[57,40,28,0],
[57,40,28,1],
[57,40,28,2],
[57,40,28,3],
[57,40,28,4],
[57,40,28,5],
[57,40,28,8],
[57,40,28,10],
[57,40,28,11],
[57,40,28,12],
[57,40,28,13],
[57,40,28,14],
[57,40,28,16],
[57,40,28,17],
[57,40,28,19],
[57,40,28,20],
[57,40,28,21],
[57,40,28,22],
[57,40,28,24],
[57,40,29,0],
[57,40,29,1],
[57,40,29,2],
[57,40,29,3],
[57,40,29,4],
[57,40,29,6],
[57,40,29,7],
[57,40,29,8],
[57,40,29,10],
[57,40,29,13],
[57,40,29,14],
[57,40,29,16],
[57,40,29,17],
[57,40,29,19],
[57,40,29,20],
[57,40,29,21],
[57,40,29,22],
[57,40,32,0],
[57,40,32,1],
[57,40,32,2],
[57,40,32,3],
[57,40,32,4],
[57,40,32,5],
[57,40,32,6],
[57,40,32,7],
[57,40,32,8],
[57,40,32,10],
[57,40,32,13],
[57,40,32,14],
[57,40,32,16],
[57,40,32,17],
[57,40,32,19],
[57,40,33,0],
[57,40,33,1],
[57,40,33,2],
[57,40,33,3],
[57,40,33,4],
[57,40,33,5],
[57,40,33,6],
[57,40,33,7],
[57,40,33,8],
[57,40,33,10],
[57,40,33,13],
[57,40,33,14],
[57,40,33,16],
[57,40,33,17],
[57,40,34,0],
[57,40,34,1],
[57,40,34,2],
[57,40,34,3],
[57,40,34,4],
[57,40,34,5],
[57,40,34,6],
[57,40,34,8],
[57,40,34,10],
[57,40,34,11],
[57,40,34,12],
[57,40,34,13],
[57,40,34,14],
[57,40,34,16],
[57,40,34,17],
[57,40,36,0],
[57,40,36,1],
[57,40,36,2],
[57,40,36,3],
[57,40,36,4],
[57,40,36,5],
[57,40,36,6],
[57,40,36,7],
[57,40,36,8],
[57,40,36,10],
[57,40,36,11],
[57,40,36,12],
[57,40,37,0],
[57,40,37,1],
[57,40,37,2],
[57,40,37,3],
[57,40,37,5],
[57,40,37,6],
[57,40,37,7],
[57,40,37,8],
[57,40,37,10],
[57,40,37,11],
[57,40,37,12],
[57,40,37,13],
[57,40,38,0],
[57,40,38,1],
[57,40,38,2],
[57,40,38,3],
[57,40,38,4],
[57,40,38,5],
[57,40,38,6],
[57,40,38,8],
[57,40,38,10],
[57,40,38,11],
[57,40,39,0],
[57,40,39,1],
[57,40,39,2],
[57,40,39,4],
[57,40,39,7],
[57,41,3,0],
[57,41,3,1],
[57,41,3,2],
[57,41,4,0],
[57,41,4,1],
[57,41,4,2],
[57,41,4,3],
[57,41,5,0],
[57,41,5,1],
[57,41,5,2],
[57,41,5,4],
[57,41,6,0],
[57,41,6,1],
[57,41,6,2],
[57,41,6,4],
[57,41,7,0],
[57,41,7,1],
[57,41,7,2],
[57,41,7,3],
[57,41,7,4],
[57,41,7,5],
[57,41,7,6],
[57,41,8,0],
[57,41,8,1],
[57,41,8,2],
[57,41,8,4],
[57,41,8,7],
[57,41,10,0],
[57,41,10,1],
[57,41,10,2],
[57,41,10,3],
[57,41,10,4],
[57,41,10,5],
[57,41,10,6],
[57,41,10,7],
[57,41,10,8],
[57,41,11,0],
[57,41,11,1],
[57,41,11,2],
[57,41,11,3],
[57,41,11,4],
[57,41,11,5],
[57,41,11,6],
[57,41,11,7],
[57,41,11,8],
[57,41,11,10],
[57,41,12,0],
[57,41,12,1],
[57,41,12,2],
[57,41,12,3],
[57,41,12,4],
[57,41,12,5],
[57,41,12,6],
[57,41,12,7],
[57,41,12,8],
[57,41,12,10],
[57,41,13,0],
[57,41,13,1],
[57,41,13,2],
[57,41,13,3],
[57,41,13,4],
[57,41,13,5],
[57,41,13,6],
[57,41,13,7],
[57,41,13,8],
[57,41,13,10],
[57,41,13,11],
[57,41,13,12],
[57,41,14,0],
[57,41,14,1],
[57,41,14,2],
[57,41,14,3],
[57,41,14,4],
[57,41,14,5],
[57,41,14,6],
[57,41,14,7],
[57,41,14,8],
[57,41,14,10],
[57,41,14,11],
[57,41,14,12],
[57,41,16,0],
[57,41,16,1],
[57,41,16,2],
[57,41,16,3],
[57,41,16,4],
[57,41,16,5],
[57,41,16,6],
[57,41,16,7],
[57,41,16,8],
[57,41,16,10],
[57,41,16,11],
[57,41,16,12],
[57,41,16,13],
[57,41,16,14],
[57,41,17,0],
[57,41,17,1],
[57,41,17,2],
[57,41,17,3],
[57,41,17,4],
[57,41,17,5],
[57,41,17,6],
[57,41,17,7],
[57,41,17,8],
[57,41,17,10],
[57,41,17,11],
[57,41,17,12],
[57,41,17,13],
[57,41,17,14],
[57,41,17,16],
[57,41,19,0],
[57,41,19,1],
[57,41,19,2],
[57,41,19,3],
[57,41,19,4],
[57,41,19,5],
[57,41,19,6],
[57,41,19,7],
[57,41,19,8],
[57,41,19,10],
[57,41,19,11],
[57,41,19,12],
[57,41,19,16],
[57,41,19,17],
[57,41,20,0],
[57,41,20,1],
[57,41,20,2],
[57,41,20,3],
[57,41,20,4],
[57,41,20,5],
[57,41,20,6],
[57,41,20,7],
[57,41,20,8],
[57,41,20,11],
[57,41,20,12],
[57,41,20,13],
[57,41,20,14],
[57,41,20,16],
[57,41,20,17],
[57,41,21,0],
[57,41,21,1],
[57,41,21,2],
[57,41,21,4],
[57,41,21,7],
[57,41,21,10],
[57,41,21,11],
[57,41,21,12],
[57,41,21,13],
[57,41,21,14],
[57,41,21,16],
[57,41,21,17],
[57,41,21,19],
[57,41,21,20],
[57,41,22,0],
[57,41,22,1],
[57,41,22,2],
[57,41,22,3],
[57,41,22,4],
[57,41,22,5],
[57,41,22,6],
[57,41,22,7],
[57,41,22,8],
[57,41,22,11],
[57,41,22,12],
[57,41,22,13],
[57,41,22,14],
[57,41,22,19],
[57,41,22,21],
[57,41,24,0],
[57,41,24,1],
[57,41,24,2],
[57,41,24,3],
[57,41,24,4],
[57,41,24,5],
[57,41,24,6],
[57,41,24,7],
[57,41,24,8],
[57,41,24,10],
[57,41,24,11],
[57,41,24,12],
[57,41,24,13],
[57,41,24,14],
[57,41,24,17],
[57,41,24,19],
[57,41,24,20],
[57,41,24,21],
[57,41,24,22],
[57,41,25,0],
[57,41,25,1],
[57,41,25,2],
[57,41,25,3],
[57,41,25,4],
[57,41,25,5],
[57,41,25,6],
[57,41,25,8],
[57,41,25,10],
[57,41,25,11],
[57,41,25,14],
[57,41,25,16],
[57,41,25,17],
[57,41,25,19],
[57,41,25,20],
[57,41,25,21],
[57,41,25,22],
[57,41,25,24],
[57,41,27,0],
[57,41,27,1],
[57,41,27,2],
[57,41,27,3],
[57,41,27,4],
[57,41,27,5],
[57,41,27,6],
[57,41,27,7],
[57,41,27,11],
[57,41,27,12],
[57,41,27,13],
[57,41,27,14],
[57,41,27,16],
[57,41,27,17],
[57,41,27,19],
[57,41,27,21],
[57,41,27,24],
[57,41,28,0],
[57,41,28,1],
[57,41,28,2],
[57,41,28,3],
[57,41,28,4],
[57,41,28,5],
[57,41,28,8],
[57,41,28,10],
[57,41,28,11],
[57,41,28,12],
[57,41,28,13],
[57,41,28,14],
[57,41,28,16],
[57,41,28,17],
[57,41,28,19],
[57,41,28,20],
[57,41,28,21],
[57,41,28,22],
[57,41,28,24],
[57,41,29,0],
[57,41,29,1],
[57,41,29,2],
[57,41,29,3],
[57,41,29,4],
[57,41,29,6],
[57,41,29,7],
[57,41,29,8],
[57,41,29,10],
[57,41,29,13],
[57,41,29,14],
[57,41,29,16],
[57,41,29,17],
[57,41,29,19],
[57,41,29,20],
[57,41,29,21],
[57,41,29,22],
[57,41,32,0],
[57,41,32,1],
[57,41,32,2],
[57,41,32,3],
[57,41,32,4],
[57,41,32,5],
[57,41,32,6],
[57,41,32,7],
[57,41,32,8],
[57,41,32,10],
[57,41,32,13],
[57,41,32,14],
[57,41,32,16],
[57,41,32,17],
[57,41,32,19],
[57,41,33,0],
[57,41,33,1],
[57,41,33,2],
[57,41,33,3],
[57,41,33,4],
[57,41,33,5],
[57,41,33,6],
[57,41,33,7],
[57,41,33,8],
[57,41,33,10],
[57,41,33,13],
[57,41,33,14],
[57,41,33,16],
[57,41,33,17],
[57,41,34,0],
[57,41,34,1],
[57,41,34,2],
[57,41,34,3],
[57,41,34,4],
[57,41,34,5],
[57,41,34,6],
[57,41,34,8],
[57,41,34,10],
[57,41,34,11],
[57,41,34,12],
[57,41,34,13],
[57,41,34,14],
[57,41,34,16],
[57,41,34,17],
[57,41,36,0],
[57,41,36,1],
[57,41,36,2],
[57,41,36,3],
[57,41,36,4],
[57,41,36,5],
[57,41,36,6],
[57,41,36,7],
[57,41,36,8],
[57,41,36,10],
[57,41,36,11],
[57,41,36,12],
[57,41,37,0],
[57,41,37,1],
[57,41,37,2],
[57,41,37,3],
[57,41,37,5],
[57,41,37,6],
[57,41,37,7],
[57,41,37,8],
[57,41,37,10],
[57,41,37,11],
[57,41,37,12],
[57,41,37,13],
[57,41,38,0],
[57,41,38,1],
[57,41,38,2],
[57,41,38,3],
[57,41,38,4],
[57,41,38,5],
[57,41,38,6],
[57,41,38,8],
[57,41,38,10],
[57,41,38,11],
[57,41,39,0],
[57,41,39,1],
[57,41,39,2],
[57,41,39,4],
[57,41,39,7],
[57,42,3,0],
[57,42,3,1],
[57,42,3,2],
[57,42,4,0],
[57,42,4,1],
[57,42,4,2],
[57,42,4,3],
[57,42,5,0],
[57,42,5,1],
[57,42,5,2],
[57,42,5,4],
[57,42,6,0],
[57,42,6,1],
[57,42,6,2],
[57,42,6,4],
[57,42,7,0],
[57,42,7,1],
[57,42,7,2],
[57,42,7,3],
[57,42,7,4],
[57,42,7,5],
[57,42,7,6],
[57,42,8,0],
[57,42,8,1],
[57,42,8,2],
[57,42,8,4],
[57,42,8,7],
[57,42,9,0],
[57,42,9,1],
[57,42,9,2],
[57,42,9,3],
[57,42,9,4],
[57,42,9,5],
[57,42,9,6],
[57,42,9,7],
[57,42,9,8],
[57,42,11,0],
[57,42,11,1],
[57,42,11,2],
[57,42,11,3],
[57,42,11,4],
[57,42,11,5],
[57,42,11,6],
[57,42,11,7],
[57,42,11,8],
[57,42,11,9],
[57,42,12,0],
[57,42,12,1],
[57,42,12,2],
[57,42,12,3],
[57,42,12,4],
[57,42,12,5],
[57,42,12,6],
[57,42,12,7],
[57,42,12,8],
[57,42,12,9],
[57,42,13,0],
[57,42,13,1],
[57,42,13,2],
[57,42,13,3],
[57,42,13,4],
[57,42,13,5],
[57,42,13,6],
[57,42,13,7],
[57,42,13,8],
[57,42,13,9],
[57,42,13,11],
[57,42,13,12],
[57,42,14,0],
[57,42,14,1],
[57,42,14,2],
[57,42,14,3],
[57,42,14,4],
[57,42,14,5],
[57,42,14,6],
[57,42,14,7],
[57,42,14,8],
[57,42,14,9],
[57,42,14,11],
[57,42,14,12],
[57,42,15,0],
[57,42,15,1],
[57,42,15,2],
[57,42,15,3],
[57,42,15,4],
[57,42,15,5],
[57,42,15,6],
[57,42,15,7],
[57,42,15,8],
[57,42,15,11],
[57,42,15,12],
[57,42,15,13],
[57,42,15,14],
[57,42,16,0],
[57,42,16,1],
[57,42,16,2],
[57,42,16,3],
[57,42,16,4],
[57,42,16,5],
[57,42,16,6],
[57,42,16,7],
[57,42,16,8],
[57,42,16,9],
[57,42,16,11],
[57,42,16,12],
[57,42,16,13],
[57,42,16,14],
[57,42,16,15],
[57,42,17,0],
[57,42,17,1],
[57,42,17,2],
[57,42,17,3],
[57,42,17,4],
[57,42,17,5],
[57,42,17,6],
[57,42,17,7],
[57,42,17,8],
[57,42,17,9],
[57,42,17,11],
[57,42,17,12],
[57,42,17,13],
[57,42,17,14],
[57,42,17,15],
[57,42,17,16],
[57,42,18,0],
[57,42,18,1],
[57,42,18,2],
[57,42,18,3],
[57,42,18,4],
[57,42,18,5],
[57,42,18,6],
[57,42,18,7],
[57,42,18,8],
[57,42,18,11],
[57,42,18,12],
[57,42,18,13],
[57,42,18,14],
[57,42,18,16],
[57,42,18,17],
[57,42,19,0],
[57,42,19,1],
[57,42,19,2],
[57,42,19,3],
[57,42,19,4],
[57,42,19,5],
[57,42,19,6],
[57,42,19,7],
[57,42,19,8],
[57,42,19,9],
[57,42,19,11],
[57,42,19,12],
[57,42,19,15],
[57,42,19,16],
[57,42,19,17],
[57,42,19,18],
[57,42,21,0],
[57,42,21,1],
[57,42,21,2],
[57,42,21,4],
[57,42,21,7],
[57,42,21,9],
[57,42,21,11],
[57,42,21,12],
[57,42,21,13],
[57,42,21,14],
[57,42,21,15],
[57,42,21,18],
[57,42,21,19],
[57,42,24,0],
[57,42,24,1],
[57,42,24,2],
[57,42,24,3],
[57,42,24,4],
[57,42,24,5],
[57,42,24,6],
[57,42,24,7],
[57,42,24,8],
[57,42,24,9],
[57,42,24,11],
[57,42,24,12],
[57,42,24,13],
[57,42,24,15],
[57,42,24,17],
[57,42,24,18],
[57,42,24,19],
[57,42,24,21],
[57,42,25,0],
[57,42,25,1],
[57,42,25,2],
[57,42,25,3],
[57,42,25,4],
[57,42,25,5],
[57,42,25,6],
[57,42,25,8],
[57,42,25,9],
[57,42,25,12],
[57,42,25,13],
[57,42,25,14],
[57,42,25,15],
[57,42,25,16],
[57,42,25,17],
[57,42,25,18],
[57,42,25,19],
[57,42,25,21],
[57,42,25,24],
[57,42,26,0],
[57,42,26,1],
[57,42,26,2],
[57,42,26,3],
[57,42,26,4],
[57,42,26,5],
[57,42,26,6],
[57,42,26,7],
[57,42,26,11],
[57,42,26,12],
[57,42,26,13],
[57,42,26,14],
[57,42,26,16],
[57,42,26,17],
[57,42,26,19],
[57,42,26,21],
[57,42,26,24],
[57,42,28,0],
[57,42,28,1],
[57,42,28,2],
[57,42,28,3],
[57,42,28,4],
[57,42,28,6],
[57,42,28,8],
[57,42,28,9],
[57,42,28,11],
[57,42,28,12],
[57,42,28,13],
[57,42,28,14],
[57,42,28,15],
[57,42,28,16],
[57,42,28,17],
[57,42,28,18],
[57,42,28,19],
[57,42,28,21],
[57,42,29,0],
[57,42,29,1],
[57,42,29,2],
[57,42,29,5],
[57,42,29,6],
[57,42,29,7],
[57,42,29,8],
[57,42,29,9],
[57,42,29,13],
[57,42,29,14],
[57,42,29,15],
[57,42,29,16],
[57,42,29,17],
[57,42,29,18],
[57,42,29,19],
[57,42,29,21],
[57,42,32,0],
[57,42,32,1],
[57,42,32,2],
[57,42,32,3],
[57,42,32,4],
[57,42,32,5],
[57,42,32,6],
[57,42,32,7],
[57,42,32,8],
[57,42,32,9],
[57,42,32,13],
[57,42,32,14],
[57,42,32,15],
[57,42,32,16],
[57,42,32,17],
[57,42,32,18],
[57,42,33,0],
[57,42,33,1],
[57,42,33,2],
[57,42,33,3],
[57,42,33,4],
[57,42,33,5],
[57,42,33,6],
[57,42,33,7],
[57,42,33,8],
[57,42,33,9],
[57,42,33,13],
[57,42,33,14],
[57,42,33,15],
[57,42,33,16],
[57,42,33,17],
[57,42,34,0],
[57,42,34,1],
[57,42,34,2],
[57,42,34,3],
[57,42,34,4],
[57,42,34,5],
[57,42,34,6],
[57,42,34,8],
[57,42,34,9],
[57,42,34,11],
[57,42,34,12],
[57,42,34,13],
[57,42,34,14],
[57,42,34,15],
[57,42,35,0],
[57,42,35,1],
[57,42,35,2],
[57,42,35,3],
[57,42,35,4],
[57,42,35,5],
[57,42,35,6],
[57,42,35,7],
[57,42,35,8],
[57,42,35,11],
[57,42,35,12],
[57,42,35,13],
[57,42,35,14],
[57,42,36,0],
[57,42,36,1],
[57,42,36,2],
[57,42,36,3],
[57,42,36,4],
[57,42,36,5],
[57,42,36,6],
[57,42,36,7],
[57,42,36,8],
[57,42,36,9],
[57,42,36,11],
[57,42,36,12],
[57,42,37,0],
[57,42,37,1],
[57,42,37,2],
[57,42,37,3],
[57,42,37,5],
[57,42,37,6],
[57,42,37,7],
[57,42,37,8],
[57,42,37,9],
[57,42,37,11],
[57,42,38,0],
[57,42,38,1],
[57,42,38,2],
[57,42,38,3],
[57,42,38,4],
[57,42,38,5],
[57,42,38,6],
[57,42,38,8],
[57,42,38,9],
[57,42,39,0],
[57,42,39,1],
[57,42,39,2],
[57,42,39,4],
[57,42,39,7],
[57,42,40,0],
[57,42,40,1],
[57,42,40,2],
[57,42,40,3],
[57,42,40,4],
[57,42,40,5],
[57,42,41,0],
[57,42,41,1],
[57,42,41,2],
[57,42,41,3],
[57,42,41,4],
[57,42,41,5],
[57,43,3,0],
[57,43,3,1],
[57,43,3,2],
[57,43,4,0],
[57,43,4,1],
[57,43,4,2],
[57,43,4,3],
[57,43,5,0],
[57,43,5,1],
[57,43,5,2],
[57,43,5,4],
[57,43,6,0],
[57,43,6,1],
[57,43,6,2],
[57,43,6,4],
[57,43,8,0],
[57,43,8,1],
[57,43,8,2],
[57,43,8,4],
[57,43,9,0],
[57,43,9,1],
[57,43,9,2],
[57,43,9,3],
[57,43,9,4],
[57,43,9,5],
[57,43,9,6],
[57,43,9,8],
[57,43,10,0],
[57,43,10,1],
[57,43,10,2],
[57,43,10,3],
[57,43,10,4],
[57,43,10,5],
[57,43,10,6],
[57,43,10,8],
[57,43,10,9],
[57,43,11,0],
[57,43,11,1],
[57,43,11,2],
[57,43,11,3],
[57,43,11,4],
[57,43,11,5],
[57,43,11,6],
[57,43,11,8],
[57,43,11,9],
[57,43,11,10],
[57,43,12,0],
[57,43,12,1],
[57,43,12,2],
[57,43,12,3],
[57,43,12,4],
[57,43,12,5],
[57,43,12,6],
[57,43,12,8],
[57,43,12,9],
[57,43,12,10],
[57,43,13,0],
[57,43,13,1],
[57,43,13,2],
[57,43,13,3],
[57,43,13,4],
[57,43,13,5],
[57,43,13,6],
[57,43,13,8],
[57,43,13,9],
[57,43,13,10],
[57,43,13,11],
[57,43,13,12],
[57,43,14,0],
[57,43,14,1],
[57,43,14,2],
[57,43,14,3],
[57,43,14,4],
[57,43,14,5],
[57,43,14,6],
[57,43,14,8],
[57,43,14,9],
[57,43,14,10],
[57,43,14,11],
[57,43,14,12],
[57,43,15,0],
[57,43,15,1],
[57,43,15,2],
[57,43,15,3],
[57,43,15,4],
[57,43,15,5],
[57,43,15,6],
[57,43,15,8],
[57,43,15,10],
[57,43,15,11],
[57,43,15,12],
[57,43,15,13],
[57,43,15,14],
[57,43,16,0],
[57,43,16,1],
[57,43,16,2],
[57,43,16,3],
[57,43,16,4],
[57,43,16,5],
[57,43,16,6],
[57,43,16,8],
[57,43,16,9],
[57,43,16,10],
[57,43,16,11],
[57,43,16,12],
[57,43,16,13],
[57,43,16,14],
[57,43,16,15],
[57,43,17,0],
[57,43,17,1],
[57,43,17,2],
[57,43,17,3],
[57,43,17,4],
[57,43,17,5],
[57,43,17,6],
[57,43,17,8],
[57,43,17,9],
[57,43,17,10],
[57,43,17,11],
[57,43,17,12],
[57,43,17,13],
[57,43,17,14],
[57,43,17,15],
[57,43,17,16],
[57,43,18,0],
[57,43,18,1],
[57,43,18,2],
[57,43,18,3],
[57,43,18,4],
[57,43,18,5],
[57,43,18,6],
[57,43,18,8],
[57,43,18,10],
[57,43,18,11],
[57,43,18,12],
[57,43,18,13],
[57,43,18,14],
[57,43,18,16],
[57,43,18,17],
[57,43,19,0],
[57,43,19,1],
[57,43,19,2],
[57,43,19,3],
[57,43,19,4],
[57,43,19,5],
[57,43,19,6],
[57,43,19,8],
[57,43,19,9],
[57,43,19,10],
[57,43,19,11],
[57,43,19,12],
[57,43,19,15],
[57,43,19,16],
[57,43,19,17],
[57,43,20,0],
[57,43,20,1],
[57,43,20,2],
[57,43,20,3],
[57,43,20,4],
[57,43,20,5],
[57,43,20,6],
[57,43,20,8],
[57,43,20,9],
[57,43,20,11],
[57,43,20,12],
[57,43,20,13],
[57,43,20,14],
[57,43,20,15],
[57,43,20,18],
[57,43,20,19],
[57,43,21,0],
[57,43,21,1],
[57,43,21,2],
[57,43,21,4],
[57,43,21,9],
[57,43,21,10],
[57,43,21,11],
[57,43,21,12],
[57,43,21,13],
[57,43,21,14],
[57,43,21,16],
[57,43,21,17],
[57,43,21,18],
[57,43,21,19],
[57,43,21,20],
[57,43,22,0],
[57,43,22,1],
[57,43,22,2],
[57,43,22,3],
[57,43,22,4],
[57,43,22,5],
[57,43,22,6],
[57,43,22,8],
[57,43,22,9],
[57,43,22,11],
[57,43,22,12],
[57,43,22,13],
[57,43,22,15],
[57,43,22,16],
[57,43,22,17],
[57,43,22,18],
[57,43,22,19],
[57,43,22,21],
[57,43,24,0],
[57,43,24,1],
[57,43,24,2],
[57,43,24,3],
[57,43,24,4],
[57,43,24,5],
[57,43,24,6],
[57,43,24,8],
[57,43,24,9],
[57,43,24,10],
[57,43,24,11],
[57,43,24,14],
[57,43,24,15],
[57,43,24,17],
[57,43,24,18],
[57,43,24,19],
[57,43,24,20],
[57,43,24,21],
[57,43,24,22],
[57,43,26,0],
[57,43,26,1],
[57,43,26,2],
[57,43,26,3],
[57,43,26,4],
[57,43,26,5],
[57,43,26,8],
[57,43,26,10],
[57,43,26,11],
[57,43,26,12],
[57,43,26,13],
[57,43,26,14],
[57,43,26,16],
[57,43,26,17],
[57,43,26,19],
[57,43,26,20],
[57,43,26,21],
[57,43,26,22],
[57,43,26,24],
[57,43,27,0],
[57,43,27,1],
[57,43,27,2],
[57,43,27,3],
[57,43,27,4],
[57,43,27,6],
[57,43,27,8],
[57,43,27,9],
[57,43,27,11],
[57,43,27,12],
[57,43,27,13],
[57,43,27,14],
[57,43,27,15],
[57,43,27,16],
[57,43,27,17],
[57,43,27,18],
[57,43,27,19],
[57,43,27,21],
[57,43,29,3],
[57,43,29,4],
[57,43,29,5],
[57,43,29,6],
[57,43,29,8],
[57,43,29,9],
[57,43,29,10],
[57,43,29,13],
[57,43,29,14],
[57,43,29,15],
[57,43,29,16],
[57,43,29,17],
[57,43,29,18],
[57,43,29,19],
[57,43,29,20],
[57,43,32,0],
[57,43,32,1],
[57,43,32,2],
[57,43,32,3],
[57,43,32,4],
[57,43,32,5],
[57,43,32,6],
[57,43,32,8],
[57,43,32,9],
[57,43,32,10],
[57,43,32,13],
[57,43,32,14],
[57,43,32,15],
[57,43,32,16],
[57,43,32,17],
[57,43,33,0],
[57,43,33,1],
[57,43,33,2],
[57,43,33,3],
[57,43,33,4],
[57,43,33,5],
[57,43,33,6],
[57,43,33,8],
[57,43,33,9],
[57,43,33,10],
[57,43,33,13],
[57,43,33,14],
[57,43,33,15],
[57,43,35,0],
[57,43,35,1],
[57,43,35,2],
[57,43,35,3],
[57,43,35,4],
[57,43,35,5],
[57,43,35,6],
[57,43,35,8],
[57,43,35,10],
[57,43,35,11],
[57,43,35,12],
[57,43,35,13],
[57,43,36,0],
[57,43,36,1],
[57,43,36,2],
[57,43,36,3],
[57,43,36,4],
[57,43,36,5],
[57,43,36,6],
[57,43,36,8],
[57,43,36,9],
[57,43,36,10],
[57,43,36,11],
[57,43,37,0],
[57,43,37,1],
[57,43,37,2],
[57,43,37,3],
[57,43,37,5],
[57,43,37,6],
[57,43,37,8],
[57,43,37,9],
[57,43,39,0],
[57,43,39,1],
[57,43,39,2],
[57,43,39,4],
[57,43,40,0],
[57,43,40,1],
[57,43,40,2],
[57,43,40,3],
[57,43,40,4],
[57,43,41,0],
[57,43,41,1],
[57,43,41,2],
[57,43,41,3],
[57,43,41,4],
[57,43,42,0],
[57,43,42,1],
[57,43,42,2],
[57,44,3,0],
[57,44,3,1],
[57,44,3,2],
[57,44,4,0],
[57,44,4,1],
[57,44,4,2],
[57,44,4,3],
[57,44,5,0],
[57,44,5,1],
[57,44,5,2],
[57,44,5,4],
[57,44,6,0],
[57,44,6,1],
[57,44,6,2],
[57,44,6,4],
[57,44,7,0],
[57,44,7,1],
[57,44,7,2],
[57,44,7,3],
[57,44,7,4],
[57,44,7,5],
[57,44,7,6],
[57,44,8,0],
[57,44,8,1],
[57,44,8,2],
[57,44,8,4],
[57,44,8,7],
[57,44,9,0],
[57,44,9,1],
[57,44,9,2],
[57,44,9,3],
[57,44,9,4],
[57,44,9,5],
[57,44,9,6],
[57,44,9,7],
[57,44,9,8],
[57,44,10,0],
[57,44,10,1],
[57,44,10,2],
[57,44,10,3],
[57,44,10,4],
[57,44,10,5],
[57,44,10,6],
[57,44,10,7],
[57,44,10,8],
[57,44,10,9],
[57,44,11,0],
[57,44,11,1],
[57,44,11,2],
[57,44,11,3],
[57,44,11,4],
[57,44,11,5],
[57,44,11,6],
[57,44,11,7],
[57,44,11,8],
[57,44,11,9],
[57,44,11,10],
[57,44,12,0],
[57,44,12,1],
[57,44,12,2],
[57,44,12,3],
[57,44,12,4],
[57,44,12,5],
[57,44,12,6],
[57,44,12,7],
[57,44,12,8],
[57,44,12,9],
[57,44,12,10],
[57,44,13,0],
[57,44,13,1],
[57,44,13,2],
[57,44,13,3],
[57,44,13,4],
[57,44,13,5],
[57,44,13,6],
[57,44,13,7],
[57,44,13,8],
[57,44,13,9],
[57,44,13,10],
[57,44,13,11],
[57,44,13,12],
[57,44,14,0],
[57,44,14,1],
[57,44,14,2],
[57,44,14,3],
[57,44,14,4],
[57,44,14,5],
[57,44,14,6],
[57,44,14,7],
[57,44,14,8],
[57,44,14,9],
[57,44,14,10],
[57,44,14,11],
[57,44,14,12],
[57,44,15,0],
[57,44,15,1],
[57,44,15,2],
[57,44,15,3],
[57,44,15,4],
[57,44,15,5],
[57,44,15,6],
[57,44,15,7],
[57,44,15,8],
[57,44,15,10],
[57,44,15,11],
[57,44,15,12],
[57,44,15,13],
[57,44,15,14],
[57,44,16,0],
[57,44,16,1],
[57,44,16,2],
[57,44,16,3],
[57,44,16,4],
[57,44,16,5],
[57,44,16,6],
[57,44,16,7],
[57,44,16,8],
[57,44,16,9],
[57,44,16,10],
[57,44,16,11],
[57,44,16,12],
[57,44,16,13],
[57,44,16,14],
[57,44,16,15],
[57,44,18,0],
[57,44,18,1],
[57,44,18,2],
[57,44,18,3],
[57,44,18,4],
[57,44,18,5],
[57,44,18,6],
[57,44,18,7],
[57,44,18,8],
[57,44,18,10],
[57,44,18,11],
[57,44,18,12],
[57,44,18,13],
[57,44,18,14],
[57,44,18,16],
[57,44,19,0],
[57,44,19,1],
[57,44,19,2],
[57,44,19,3],
[57,44,19,4],
[57,44,19,5],
[57,44,19,6],
[57,44,19,7],
[57,44,19,8],
[57,44,19,9],
[57,44,19,10],
[57,44,19,11],
[57,44,19,12],
[57,44,19,15],
[57,44,19,18],
[57,44,20,0],
[57,44,20,1],
[57,44,20,2],
[57,44,20,3],
[57,44,20,4],
[57,44,20,5],
[57,44,20,6],
[57,44,20,7],
[57,44,20,8],
[57,44,20,9],
[57,44,20,11],
[57,44,20,12],
[57,44,20,13],
[57,44,20,14],
[57,44,20,16],
[57,44,20,18],
[57,44,20,19],
[57,44,21,0],
[57,44,21,1],
[57,44,21,2],
[57,44,21,4],
[57,44,21,7],
[57,44,21,9],
[57,44,21,10],
[57,44,21,11],
[57,44,21,12],
[57,44,21,13],
[57,44,21,15],
[57,44,21,16],
[57,44,21,18],
[57,44,21,19],
[57,44,21,20],
[57,44,22,0],
[57,44,22,1],
[57,44,22,2],
[57,44,22,3],
[57,44,22,4],
[57,44,22,5],
[57,44,22,6],
[57,44,22,7],
[57,44,22,8],
[57,44,22,9],
[57,44,22,11],
[57,44,22,14],
[57,44,22,15],
[57,44,22,16],
[57,44,22,18],
[57,44,22,19],
[57,44,22,21],
[57,44,24,0],
[57,44,24,1],
[57,44,24,2],
[57,44,24,3],
[57,44,24,4],
[57,44,24,5],
[57,44,24,6],
[57,44,24,7],
[57,44,24,8],
[57,44,24,9],
[57,44,24,12],
[57,44,24,13],
[57,44,24,14],
[57,44,24,15],
[57,44,24,18],
[57,44,24,19],
[57,44,24,20],
[57,44,24,21],
[57,44,24,22],
[57,44,25,0],
[57,44,25,1],
[57,44,25,2],
[57,44,25,3],
[57,44,25,4],
[57,44,25,5],
[57,44,25,8],
[57,44,25,9],
[57,44,25,10],
[57,44,25,11],
[57,44,25,12],
[57,44,25,13],
[57,44,25,14],
[57,44,25,15],
[57,44,25,16],
[57,44,25,18],
[57,44,25,19],
[57,44,25,20],
[57,44,25,21],
[57,44,25,22],
[57,44,25,24],
[57,44,26,0],
[57,44,26,1],
[57,44,26,2],
[57,44,26,3],
[57,44,26,4],
[57,44,26,6],
[57,44,26,7],
[57,44,26,8],
[57,44,26,10],
[57,44,26,11],
[57,44,26,12],
[57,44,26,13],
[57,44,26,14],
[57,44,26,16],
[57,44,26,19],
[57,44,26,20],
[57,44,26,21],
[57,44,26,22],
[57,44,27,0],
[57,44,27,1],
[57,44,27,2],
[57,44,27,5],
[57,44,27,6],
[57,44,27,7],
[57,44,27,8],
[57,44,27,9],
[57,44,27,11],
[57,44,27,12],
[57,44,27,13],
[57,44,27,14],
[57,44,27,15],
[57,44,27,16],
[57,44,27,18],
[57,44,27,19],
[57,44,27,21],
[57,44,28,3],
[57,44,28,4],
[57,44,28,5],
[57,44,28,6],
[57,44,28,8],
[57,44,28,9],
[57,44,28,10],
[57,44,28,11],
[57,44,28,12],
[57,44,28,13],
[57,44,28,14],
[57,44,28,15],
[57,44,28,16],
[57,44,28,18],
[57,44,28,19],
[57,44,28,20],
[57,44,29,0],
[57,44,29,1],
[57,44,29,2],
[57,44,29,3],
[57,44,29,4],
[57,44,29,5],
[57,44,29,6],
[57,44,29,7],
[57,44,29,8],
[57,44,29,9],
[57,44,29,10],
[57,44,29,13],
[57,44,29,14],
[57,44,29,15],
[57,44,29,16],
[57,44,29,18],
[57,44,29,19],
[57,44,32,0],
[57,44,32,1],
[57,44,32,2],
[57,44,32,3],
[57,44,32,4],
[57,44,32,5],
[57,44,32,6],
[57,44,32,7],
[57,44,32,8],
[57,44,32,9],
[57,44,32,10],
[57,44,32,13],
[57,44,32,14],
[57,44,32,15],
[57,44,33,0],
[57,44,33,1],
[57,44,33,2],
[57,44,33,3],
[57,44,33,4],
[57,44,33,5],
[57,44,33,6],
[57,44,33,7],
[57,44,33,8],
[57,44,33,9],
[57,44,33,10],
[57,44,33,13],
[57,44,33,14],
[57,44,34,0],
[57,44,34,1],
[57,44,34,2],
[57,44,34,3],
[57,44,34,4],
[57,44,34,5],
[57,44,34,6],
[57,44,34,8],
[57,44,34,9],
[57,44,34,10],
[57,44,34,11],
[57,44,34,12],
[57,44,34,13],
[57,44,35,0],
[57,44,35,1],
[57,44,35,2],
[57,44,35,3],
[57,44,35,4],
[57,44,35,5],
[57,44,35,6],
[57,44,35,7],
[57,44,35,8],
[57,44,35,10],
[57,44,35,11],
[57,44,36,0],
[57,44,36,1],
[57,44,36,2],
[57,44,36,3],
[57,44,36,4],
[57,44,36,5],
[57,44,36,6],
[57,44,36,7],
[57,44,36,8],
[57,44,36,9],
[57,44,37,0],
[57,44,37,1],
[57,44,37,2],
[57,44,37,3],
[57,44,37,5],
[57,44,37,6],
[57,44,37,7],
[57,44,38,0],
[57,44,38,1],
[57,44,38,2],
[57,44,38,3],
[57,44,38,4],
[57,44,38,5],
[57,44,39,0],
[57,44,39,1],
[57,44,39,2],
[57,44,39,4],
[57,44,40,0],
[57,44,40,1],
[57,44,40,2],
[57,44,41,0],
[57,44,41,1],
[57,44,41,2],
[57,45,3,0],
[57,45,3,1],
[57,45,3,2],
[57,45,4,0],
[57,45,4,1],
[57,45,4,2],
[57,45,4,3],
[57,45,5,0],
[57,45,5,1],
[57,45,5,2],
[57,45,5,4],
[57,45,6,0],
[57,45,6,1],
[57,45,6,2],
[57,45,6,4],
[57,45,7,0],
[57,45,7,1],
[57,45,7,2],
[57,45,7,3],
[57,45,7,4],
[57,45,7,5],
[57,45,7,6],
[57,45,8,0],
[57,45,8,1],
[57,45,8,2],
[57,45,8,4],
[57,45,8,7],
[57,45,10,0],
[57,45,10,1],
[57,45,10,2],
[57,45,10,3],
[57,45,10,4],
[57,45,10,5],
[57,45,10,6],
[57,45,10,7],
[57,45,10,8],
[57,45,11,0],
[57,45,11,1],
[57,45,11,2],
[57,45,11,3],
[57,45,11,4],
[57,45,11,5],
[57,45,11,6],
[57,45,11,7],
[57,45,11,8],
[57,45,11,10],
[57,45,12,0],
[57,45,12,1],
[57,45,12,2],
[57,45,12,3],
[57,45,12,4],
[57,45,12,5],
[57,45,12,6],
[57,45,12,7],
[57,45,12,8],
[57,45,12,10],
[57,45,13,0],
[57,45,13,1],
[57,45,13,2],
[57,45,13,3],
[57,45,13,4],
[57,45,13,5],
[57,45,13,6],
[57,45,13,7],
[57,45,13,8],
[57,45,13,10],
[57,45,13,11],
[57,45,13,12],
[57,45,14,0],
[57,45,14,1],
[57,45,14,2],
[57,45,14,3],
[57,45,14,4],
[57,45,14,5],
[57,45,14,6],
[57,45,14,7],
[57,45,14,8],
[57,45,14,10],
[57,45,14,11],
[57,45,14,12],
[57,45,16,0],
[57,45,16,1],
[57,45,16,2],
[57,45,16,3],
[57,45,16,4],
[57,45,16,5],
[57,45,16,6],
[57,45,16,7],
[57,45,16,8],
[57,45,16,10],
[57,45,16,11],
[57,45,16,12],
[57,45,16,13],
[57,45,16,14],
[57,45,17,0],
[57,45,17,1],
[57,45,17,2],
[57,45,17,3],
[57,45,17,4],
[57,45,17,5],
[57,45,17,6],
[57,45,17,7],
[57,45,17,8],
[57,45,17,10],
[57,45,17,11],
[57,45,17,12],
[57,45,17,13],
[57,45,17,14],
[57,45,17,16],
[57,45,19,0],
[57,45,19,1],
[57,45,19,2],
[57,45,19,3],
[57,45,19,4],
[57,45,19,5],
[57,45,19,6],
[57,45,19,7],
[57,45,19,8],
[57,45,19,10],
[57,45,19,11],
[57,45,19,12],
[57,45,19,16],
[57,45,19,17],
[57,45,20,0],
[57,45,20,1],
[57,45,20,2],
[57,45,20,3],
[57,45,20,4],
[57,45,20,5],
[57,45,20,6],
[57,45,20,7],
[57,45,20,8],
[57,45,20,11],
[57,45,20,12],
[57,45,20,13],
[57,45,20,16],
[57,45,20,17],
[57,45,20,19],
[57,45,21,0],
[57,45,21,1],
[57,45,21,2],
[57,45,21,4],
[57,45,21,7],
[57,45,21,10],
[57,45,21,11],
[57,45,21,14],
[57,45,21,16],
[57,45,21,17],
[57,45,21,19],
[57,45,21,20],
[57,45,22,0],
[57,45,22,1],
[57,45,22,2],
[57,45,22,3],
[57,45,22,4],
[57,45,22,5],
[57,45,22,6],
[57,45,22,7],
[57,45,22,8],
[57,45,22,12],
[57,45,22,13],
[57,45,22,14],
[57,45,22,16],
[57,45,22,17],
[57,45,22,19],
[57,45,22,21],
[57,45,24,0],
[57,45,24,1],
[57,45,24,2],
[57,45,24,3],
[57,45,24,4],
[57,45,24,5],
[57,45,24,6],
[57,45,24,7],
[57,45,24,10],
[57,45,24,11],
[57,45,24,12],
[57,45,24,13],
[57,45,24,14],
[57,45,24,17],
[57,45,24,19],
[57,45,24,20],
[57,45,24,21],
[57,45,24,22],
[57,45,25,0],
[57,45,25,1],
[57,45,25,2],
[57,45,25,3],
[57,45,25,4],
[57,45,25,6],
[57,45,25,8],
[57,45,25,10],
[57,45,25,11],
[57,45,25,12],
[57,45,25,13],
[57,45,25,14],
[57,45,25,16],
[57,45,25,17],
[57,45,25,19],
[57,45,25,20],
[57,45,25,21],
[57,45,25,22],
[57,45,27,3],
[57,45,27,4],
[57,45,27,5],
[57,45,27,6],
[57,45,27,7],
[57,45,27,8],
[57,45,27,11],
[57,45,27,12],
[57,45,27,13],
[57,45,27,14],
[57,45,27,16],
[57,45,27,17],
[57,45,27,19],
[57,45,28,0],
[57,45,28,1],
[57,45,28,2],
[57,45,28,3],
[57,45,28,4],
[57,45,28,5],
[57,45,28,6],
[57,45,28,8],
[57,45,28,10],
[57,45,28,11],
[57,45,28,12],
[57,45,28,13],
[57,45,28,14],
[57,45,28,16],
[57,45,28,17],
[57,45,28,19],
[57,45,29,0],
[57,45,29,1],
[57,45,29,2],
[57,45,29,3],
[57,45,29,4],
[57,45,29,5],
[57,45,29,6],
[57,45,29,7],
[57,45,29,8],
[57,45,29,10],
[57,45,29,13],
[57,45,29,14],
[57,45,29,16],
[57,45,29,17],
[57,45,32,0],
[57,45,32,1],
[57,45,32,2],
[57,45,32,3],
[57,45,32,4],
[57,45,32,5],
[57,45,32,6],
[57,45,32,7],
[57,45,32,8],
[57,45,32,10],
[57,45,32,13],
[57,45,32,14],
[57,45,33,0],
[57,45,33,1],
[57,45,33,2],
[57,45,33,3],
[57,45,33,4],
[57,45,33,5],
[57,45,33,6],
[57,45,33,7],
[57,45,33,8],
[57,45,33,10],
[57,45,33,13],
[57,45,34,0],
[57,45,34,1],
[57,45,34,2],
[57,45,34,3],
[57,45,34,4],
[57,45,34,5],
[57,45,34,6],
[57,45,34,8],
[57,45,34,10],
[57,45,34,11],
[57,45,36,0],
[57,45,36,1],
[57,45,36,2],
[57,45,36,3],
[57,45,36,4],
[57,45,36,5],
[57,45,36,6],
[57,45,36,7],
[57,45,37,0],
[57,45,37,1],
[57,45,37,2],
[57,45,37,3],
[57,45,37,5],
[57,45,38,0],
[57,45,38,1],
[57,45,38,2],
[57,45,38,3],
[57,45,38,4],
[57,45,39,0],
[57,45,39,1],
[57,45,39,2],
[57,46,3,0],
[57,46,3,1],
[57,46,3,2],
[57,46,4,0],
[57,46,4,1],
[57,46,4,2],
[57,46,4,3],
[57,46,5,0],
[57,46,5,1],
[57,46,5,2],
[57,46,5,4],
[57,46,6,0],
[57,46,6,1],
[57,46,6,2],
[57,46,6,4],
[57,46,7,0],
[57,46,7,1],
[57,46,7,2],
[57,46,7,3],
[57,46,7,4],
[57,46,7,5],
[57,46,7,6],
[57,46,8,0],
[57,46,8,1],
[57,46,8,2],
[57,46,8,4],
[57,46,8,7],
[57,46,10,0],
[57,46,10,1],
[57,46,10,2],
[57,46,10,3],
[57,46,10,4],
[57,46,10,5],
[57,46,10,6],
[57,46,10,7],
[57,46,10,8],
[57,46,11,0],
[57,46,11,1],
[57,46,11,2],
[57,46,11,3],
[57,46,11,4],
[57,46,11,5],
[57,46,11,6],
[57,46,11,7],
[57,46,11,8],
[57,46,11,10],
[57,46,12,0],
[57,46,12,1],
[57,46,12,2],
[57,46,12,3],
[57,46,12,4],
[57,46,12,5],
[57,46,12,6],
[57,46,12,7],
[57,46,12,8],
[57,46,12,10],
[57,46,13,0],
[57,46,13,1],
[57,46,13,2],
[57,46,13,3],
[57,46,13,4],
[57,46,13,5],
[57,46,13,6],
[57,46,13,7],
[57,46,13,8],
[57,46,13,10],
[57,46,13,11],
[57,46,13,12],
[57,46,14,0],
[57,46,14,1],
[57,46,14,2],
[57,46,14,3],
[57,46,14,4],
[57,46,14,5],
[57,46,14,6],
[57,46,14,7],
[57,46,14,8],
[57,46,14,10],
[57,46,14,11],
[57,46,14,12],
[57,46,16,0],
[57,46,16,1],
[57,46,16,2],
[57,46,16,3],
[57,46,16,4],
[57,46,16,5],
[57,46,16,6],
[57,46,16,7],
[57,46,16,8],
[57,46,16,10],
[57,46,16,11],
[57,46,16,12],
[57,46,16,13],
[57,46,16,14],
[57,46,17,0],
[57,46,17,1],
[57,46,17,2],
[57,46,17,3],
[57,46,17,4],
[57,46,17,5],
[57,46,17,6],
[57,46,17,7],
[57,46,17,8],
[57,46,17,10],
[57,46,17,11],
[57,46,17,12],
[57,46,17,13],
[57,46,17,14],
[57,46,19,0],
[57,46,19,1],
[57,46,19,2],
[57,46,19,3],
[57,46,19,4],
[57,46,19,5],
[57,46,19,6],
[57,46,19,7],
[57,46,19,8],
[57,46,19,10],
[57,46,19,11],
[57,46,19,12],
[57,46,19,16],
[57,46,19,17],
[57,46,20,0],
[57,46,20,1],
[57,46,20,2],
[57,46,20,3],
[57,46,20,4],
[57,46,20,5],
[57,46,20,6],
[57,46,20,7],
[57,46,20,8],
[57,46,20,11],
[57,46,20,14],
[57,46,20,16],
[57,46,20,17],
[57,46,20,19],
[57,46,21,0],
[57,46,21,1],
[57,46,21,2],
[57,46,21,4],
[57,46,21,7],
[57,46,21,12],
[57,46,21,13],
[57,46,21,14],
[57,46,21,16],
[57,46,21,17],
[57,46,21,19],
[57,46,21,20],
[57,46,22,0],
[57,46,22,1],
[57,46,22,2],
[57,46,22,3],
[57,46,22,4],
[57,46,22,5],
[57,46,22,6],
[57,46,22,7],
[57,46,22,11],
[57,46,22,12],
[57,46,22,13],
[57,46,22,14],
[57,46,22,16],
[57,46,22,17],
[57,46,22,19],
[57,46,22,21],
[57,46,24,0],
[57,46,24,1],
[57,46,24,2],
[57,46,24,3],
[57,46,24,4],
[57,46,24,5],
[57,46,24,8],
[57,46,24,10],
[57,46,24,11],
[57,46,24,12],
[57,46,24,13],
[57,46,24,14],
[57,46,24,17],
[57,46,24,19],
[57,46,24,20],
[57,46,24,21],
[57,46,24,22],
[57,46,25,0],
[57,46,25,1],
[57,46,25,2],
[57,46,25,5],
[57,46,25,6],
[57,46,25,8],
[57,46,25,10],
[57,46,25,11],
[57,46,25,12],
[57,46,25,13],
[57,46,25,14],
[57,46,25,16],
[57,46,25,17],
[57,46,25,19],
[57,46,25,20],
[57,46,25,21],
[57,46,27,0],
[57,46,27,1],
[57,46,27,2],
[57,46,27,3],
[57,46,27,4],
[57,46,27,5],
[57,46,27,6],
[57,46,27,7],
[57,46,27,8],
[57,46,27,11],
[57,46,27,12],
[57,46,27,13],
[57,46,27,14],
[57,46,27,16],
[57,46,27,17],
[57,46,27,19],
[57,46,28,0],
[57,46,28,1],
[57,46,28,2],
[57,46,28,3],
[57,46,28,4],
[57,46,28,5],
[57,46,28,6],
[57,46,28,8],
[57,46,28,10],
[57,46,28,11],
[57,46,28,12],
[57,46,28,13],
[57,46,28,14],
[57,46,28,16],
[57,46,28,17],
[57,46,29,0],
[57,46,29,1],
[57,46,29,2],
[57,46,29,3],
[57,46,29,4],
[57,46,29,5],
[57,46,29,6],
[57,46,29,7],
[57,46,29,8],
[57,46,29,10],
[57,46,29,13],
[57,46,29,14],
[57,46,29,16],
[57,46,29,17],
[57,46,32,0],
[57,46,32,1],
[57,46,32,2],
[57,46,32,3],
[57,46,32,4],
[57,46,32,5],
[57,46,32,6],
[57,46,32,7],
[57,46,32,8],
[57,46,32,10],
[57,46,32,13],
[57,46,33,0],
[57,46,33,1],
[57,46,33,2],
[57,46,33,3],
[57,46,33,4],
[57,46,33,5],
[57,46,33,6],
[57,46,33,7],
[57,46,33,8],
[57,46,33,10],
[57,46,34,0],
[57,46,34,1],
[57,46,34,2],
[57,46,34,3],
[57,46,34,4],
[57,46,34,5],
[57,46,34,6],
[57,46,34,8],
[57,46,36,0],
[57,46,36,1],
[57,46,36,2],
[57,46,36,3],
[57,46,36,4],
[57,46,36,5],
[57,46,37,0],
[57,46,37,1],
[57,46,37,2],
[57,46,37,3],
[57,46,38,0],
[57,46,38,1],
[57,46,38,2],
[57,48,3,0],
[57,48,3,1],
[57,48,3,2],
[57,48,4,0],
[57,48,4,1],
[57,48,4,2],
[57,48,4,3],
[57,48,5,0],
[57,48,5,1],
[57,48,5,2],
[57,48,5,4],
[57,48,6,0],
[57,48,6,1],
[57,48,6,2],
[57,48,6,4],
[57,48,7,0],
[57,48,7,1],
[57,48,7,2],
[57,48,7,3],
[57,48,7,4],
[57,48,7,5],
[57,48,7,6],
[57,48,8,0],
[57,48,8,1],
[57,48,8,2],
[57,48,8,4],
[57,48,8,7],
[57,48,9,0],
[57,48,9,1],
[57,48,9,2],
[57,48,9,3],
[57,48,9,4],
[57,48,9,5],
[57,48,9,6],
[57,48,9,7],
[57,48,9,8],
[57,48,10,0],
[57,48,10,1],
[57,48,10,2],
[57,48,10,3],
[57,48,10,4],
[57,48,10,5],
[57,48,10,6],
[57,48,10,7],
[57,48,10,8],
[57,48,10,9],
[57,48,13,0],
[57,48,13,1],
[57,48,13,2],
[57,48,13,3],
[57,48,13,4],
[57,48,13,5],
[57,48,13,6],
[57,48,13,7],
[57,48,13,8],
[57,48,13,9],
[57,48,13,10],
[57,48,14,0],
[57,48,14,1],
[57,48,14,2],
[57,48,14,3],
[57,48,14,4],
[57,48,14,5],
[57,48,14,6],
[57,48,14,7],
[57,48,14,8],
[57,48,14,9],
[57,48,14,10],
[57,48,15,0],
[57,48,15,1],
[57,48,15,2],
[57,48,15,3],
[57,48,15,4],
[57,48,15,5],
[57,48,15,6],
[57,48,15,7],
[57,48,15,8],
[57,48,15,10],
[57,48,15,13],
[57,48,15,14],
[57,48,16,0],
[57,48,16,1],
[57,48,16,2],
[57,48,16,3],
[57,48,16,4],
[57,48,16,5],
[57,48,16,6],
[57,48,16,7],
[57,48,16,8],
[57,48,16,9],
[57,48,16,10],
[57,48,16,13],
[57,48,16,15],
[57,48,17,0],
[57,48,17,1],
[57,48,17,2],
[57,48,17,3],
[57,48,17,4],
[57,48,17,5],
[57,48,17,6],
[57,48,17,7],
[57,48,17,8],
[57,48,17,9],
[57,48,17,10],
[57,48,17,13],
[57,48,17,15],
[57,48,17,16],
[57,48,18,0],
[57,48,18,1],
[57,48,18,2],
[57,48,18,3],
[57,48,18,4],
[57,48,18,5],
[57,48,18,6],
[57,48,18,7],
[57,48,18,8],
[57,48,18,10],
[57,48,18,14],
[57,48,18,16],
[57,48,18,17],
[57,48,19,0],
[57,48,19,1],
[57,48,19,2],
[57,48,19,3],
[57,48,19,4],
[57,48,19,5],
[57,48,19,6],
[57,48,19,7],
[57,48,19,8],
[57,48,19,9],
[57,48,19,15],
[57,48,19,16],
[57,48,19,17],
[57,48,19,18],
[57,48,20,0],
[57,48,20,1],
[57,48,20,2],
[57,48,20,3],
[57,48,20,4],
[57,48,20,5],
[57,48,20,6],
[57,48,20,7],
[57,48,20,13],
[57,48,20,14],
[57,48,20,15],
[57,48,20,16],
[57,48,20,17],
[57,48,20,18],
[57,48,20,19],
[57,48,21,0],
[57,48,21,1],
[57,48,21,2],
[57,48,21,4],
[57,48,21,9],
[57,48,21,10],
[57,48,21,13],
[57,48,21,14],
[57,48,21,15],
[57,48,21,16],
[57,48,21,17],
[57,48,21,18],
[57,48,21,19],
[57,48,21,20],
[57,48,22,0],
[57,48,22,1],
[57,48,22,2],
[57,48,22,3],
[57,48,22,4],
[57,48,22,6],
[57,48,22,7],
[57,48,22,8],
[57,48,22,9],
[57,48,22,13],
[57,48,22,14],
[57,48,22,15],
[57,48,22,16],
[57,48,22,17],
[57,48,22,18],
[57,48,22,19],
[57,48,22,21],
[57,48,24,0],
[57,48,24,1],
[57,48,24,2],
[57,48,24,5],
[57,48,24,6],
[57,48,24,7],
[57,48,24,8],
[57,48,24,9],
[57,48,24,10],
[57,48,24,13],
[57,48,24,14],
[57,48,24,15],
[57,48,24,17],
[57,48,24,18],
[57,48,24,19],
[57,48,24,20],
[57,48,24,21],
[57,48,25,0],
[57,48,25,1],
[57,48,25,2],
[57,48,25,3],
[57,48,25,4],
[57,48,25,5],
[57,48,25,6],
[57,48,25,8],
[57,48,25,9],
[57,48,25,10],
[57,48,25,13],
[57,48,25,14],
[57,48,25,15],
[57,48,25,16],
[57,48,25,17],
[57,48,25,18],
[57,48,25,19],
[57,48,26,0],
[57,48,26,1],
[57,48,26,2],
[57,48,26,3],
[57,48,26,4],
[57,48,26,5],
[57,48,26,6],
[57,48,26,7],
[57,48,26,8],
[57,48,26,10],
[57,48,26,13],
[57,48,26,14],
[57,48,26,16],
[57,48,26,17],
[57,48,27,0],
[57,48,27,1],
[57,48,27,2],
[57,48,27,3],
[57,48,27,4],
[57,48,27,5],
[57,48,27,6],
[57,48,27,7],
[57,48,27,8],
[57,48,27,9],
[57,48,27,13],
[57,48,27,14],
[57,48,27,15],
[57,48,27,16],
[57,48,27,17],
[57,48,28,0],
[57,48,28,1],
[57,48,28,2],
[57,48,28,3],
[57,48,28,4],
[57,48,28,5],
[57,48,28,6],
[57,48,28,8],
[57,48,28,9],
[57,48,28,10],
[57,48,28,13],
[57,48,28,14],
[57,48,28,15],
[57,48,34,0],
[57,48,34,1],
[57,48,34,2],
[57,48,34,3],
[57,48,34,4],
[57,48,34,5],
[57,48,35,0],
[57,48,35,1],
[57,48,35,2],
[57,48,35,3],
[57,48,35,4],
[57,48,36,0],
[57,48,36,1],
[57,48,36,2],
[57,49,3,0],
[57,49,3,1],
[57,49,3,2],
[57,49,4,0],
[57,49,4,1],
[57,49,4,2],
[57,49,4,3],
[57,49,5,0],
[57,49,5,1],
[57,49,5,2],
[57,49,5,4],
[57,49,6,0],
[57,49,6,1],
[57,49,6,2],
[57,49,6,4],
[57,49,7,0],
[57,49,7,1],
[57,49,7,2],
[57,49,7,3],
[57,49,7,4],
[57,49,7,5],
[57,49,7,6],
[57,49,8,0],
[57,49,8,1],
[57,49,8,2],
[57,49,8,4],
[57,49,8,7],
[57,49,10,0],
[57,49,10,1],
[57,49,10,2],
[57,49,10,3],
[57,49,10,4],
[57,49,10,5],
[57,49,10,6],
[57,49,10,7],
[57,49,10,8],
[57,49,11,0],
[57,49,11,1],
[57,49,11,2],
[57,49,11,3],
[57,49,11,4],
[57,49,11,5],
[57,49,11,6],
[57,49,11,7],
[57,49,11,8],
[57,49,11,10],
[57,49,12,0],
[57,49,12,1],
[57,49,12,2],
[57,49,12,3],
[57,49,12,4],
[57,49,12,5],
[57,49,12,6],
[57,49,12,7],
[57,49,12,8],
[57,49,12,10],
[57,49,13,0],
[57,49,13,1],
[57,49,13,2],
[57,49,13,3],
[57,49,13,4],
[57,49,13,5],
[57,49,13,6],
[57,49,13,7],
[57,49,13,8],
[57,49,13,10],
[57,49,13,11],
[57,49,13,12],
[57,49,14,0],
[57,49,14,1],
[57,49,14,2],
[57,49,14,3],
[57,49,14,4],
[57,49,14,5],
[57,49,14,6],
[57,49,14,7],
[57,49,14,8],
[57,49,14,10],
[57,49,14,11],
[57,49,14,12],
[57,49,16,0],
[57,49,16,1],
[57,49,16,2],
[57,49,16,3],
[57,49,16,4],
[57,49,16,5],
[57,49,16,6],
[57,49,16,7],
[57,49,16,8],
[57,49,16,10],
[57,49,16,11],
[57,49,16,12],
[57,49,16,13],
[57,49,17,0],
[57,49,17,1],
[57,49,17,2],
[57,49,17,3],
[57,49,17,4],
[57,49,17,5],
[57,49,17,6],
[57,49,17,7],
[57,49,17,8],
[57,49,17,10],
[57,49,17,11],
[57,49,17,12],
[57,49,17,13],
[57,49,17,16],
[57,49,19,0],
[57,49,19,1],
[57,49,19,2],
[57,49,19,3],
[57,49,19,4],
[57,49,19,5],
[57,49,19,6],
[57,49,19,7],
[57,49,19,8],
[57,49,19,12],
[57,49,19,16],
[57,49,19,17],
[57,49,20,0],
[57,49,20,1],
[57,49,20,2],
[57,49,20,3],
[57,49,20,4],
[57,49,20,5],
[57,49,20,6],
[57,49,20,7],
[57,49,20,11],
[57,49,20,12],
[57,49,20,13],
[57,49,20,14],
[57,49,20,16],
[57,49,20,17],
[57,49,20,19],
[57,49,21,0],
[57,49,21,1],
[57,49,21,2],
[57,49,21,4],
[57,49,21,10],
[57,49,21,11],
[57,49,21,12],
[57,49,21,13],
[57,49,21,14],
[57,49,21,16],
[57,49,21,17],
[57,49,21,19],
[57,49,21,20],
[57,49,22,0],
[57,49,22,1],
[57,49,22,2],
[57,49,22,3],
[57,49,22,4],
[57,49,22,6],
[57,49,22,7],
[57,49,22,8],
[57,49,22,11],
[57,49,22,12],
[57,49,22,13],
[57,49,22,14],
[57,49,22,16],
[57,49,22,17],
[57,49,22,19],
[57,49,22,21],
[57,49,24,0],
[57,49,24,1],
[57,49,24,2],
[57,49,24,5],
[57,49,24,6],
[57,49,24,7],
[57,49,24,8],
[57,49,24,10],
[57,49,24,11],
[57,49,24,12],
[57,49,24,13],
[57,49,24,14],
[57,49,24,17],
[57,49,24,19],
[57,49,24,20],
[57,49,24,21],
[57,49,25,0],
[57,49,25,1],
[57,49,25,2],
[57,49,25,3],
[57,49,25,4],
[57,49,25,5],
[57,49,25,6],
[57,49,25,8],
[57,49,25,10],
[57,49,25,11],
[57,49,25,12],
[57,49,25,13],
[57,49,25,14],
[57,49,25,16],
[57,49,25,17],
[57,49,25,19],
[57,49,27,0],
[57,49,27,1],
[57,49,27,2],
[57,49,27,3],
[57,49,27,4],
[57,49,27,5],
[57,49,27,6],
[57,49,27,7],
[57,49,27,8],
[57,49,27,11],
[57,49,27,12],
[57,49,27,13],
[57,49,27,14],
[57,49,27,16],
[57,49,27,17],
[57,49,28,0],
[57,49,28,1],
[57,49,28,2],
[57,49,28,3],
[57,49,28,4],
[57,49,28,5],
[57,49,28,6],
[57,49,28,8],
[57,49,28,10],
[57,49,28,11],
[57,49,28,12],
[57,49,28,13],
[57,49,28,14],
[57,49,29,0],
[57,49,29,1],
[57,49,29,2],
[57,49,29,3],
[57,49,29,4],
[57,49,29,5],
[57,49,29,6],
[57,49,29,7],
[57,49,29,8],
[57,49,29,10],
[57,49,29,13],
[57,49,29,14],
[57,49,32,0],
[57,49,32,1],
[57,49,32,2],
[57,49,32,3],
[57,49,32,4],
[57,49,32,5],
[57,49,32,6],
[57,49,32,7],
[57,49,32,8],
[57,49,33,0],
[57,49,33,1],
[57,49,33,2],
[57,49,33,3],
[57,49,33,4],
[57,49,33,5],
[57,49,33,6],
[57,49,33,7],
[57,49,34,0],
[57,49,34,1],
[57,49,34,2],
[57,49,34,3],
[57,49,34,4],
[57,49,34,5],
[57,49,36,0],
[57,49,36,1],
[57,49,36,2],
[57,50,3,0],
[57,50,3,1],
[57,50,3,2],
[57,50,4,0],
[57,50,4,1],
[57,50,4,2],
[57,50,4,3],
[57,50,5,0],
[57,50,5,1],
[57,50,5,2],
[57,50,5,4],
[57,50,6,0],
[57,50,6,1],
[57,50,6,2],
[57,50,6,4],
[57,50,7,0],
[57,50,7,1],
[57,50,7,2],
[57,50,7,3],
[57,50,7,4],
[57,50,7,5],
[57,50,7,6],
[57,50,8,0],
[57,50,8,1],
[57,50,8,2],
[57,50,8,4],
[57,50,8,7],
[57,50,10,0],
[57,50,10,1],
[57,50,10,2],
[57,50,10,3],
[57,50,10,4],
[57,50,10,5],
[57,50,10,6],
[57,50,10,7],
[57,50,10,8],
[57,50,11,0],
[57,50,11,1],
[57,50,11,2],
[57,50,11,3],
[57,50,11,4],
[57,50,11,5],
[57,50,11,6],
[57,50,11,7],
[57,50,11,8],
[57,50,11,10],
[57,50,12,0],
[57,50,12,1],
[57,50,12,2],
[57,50,12,3],
[57,50,12,4],
[57,50,12,5],
[57,50,12,6],
[57,50,12,7],
[57,50,12,8],
[57,50,12,10],
[57,50,13,0],
[57,50,13,1],
[57,50,13,2],
[57,50,13,3],
[57,50,13,4],
[57,50,13,5],
[57,50,13,6],
[57,50,13,7],
[57,50,13,8],
[57,50,13,10],
[57,50,13,11],
[57,50,13,12],
[57,50,14,0],
[57,50,14,1],
[57,50,14,2],
[57,50,14,3],
[57,50,14,4],
[57,50,14,5],
[57,50,14,6],
[57,50,14,7],
[57,50,14,8],
[57,50,14,10],
[57,50,14,11],
[57,50,14,12],
[57,50,16,0],
[57,50,16,1],
[57,50,16,2],
[57,50,16,3],
[57,50,16,4],
[57,50,16,5],
[57,50,16,6],
[57,50,16,7],
[57,50,16,8],
[57,50,16,10],
[57,50,16,11],
[57,50,16,12],
[57,50,16,13],
[57,50,17,0],
[57,50,17,1],
[57,50,17,2],
[57,50,17,3],
[57,50,17,4],
[57,50,17,5],
[57,50,17,6],
[57,50,17,7],
[57,50,17,8],
[57,50,17,10],
[57,50,17,11],
[57,50,17,12],
[57,50,17,13],
[57,50,17,16],
[57,50,19,0],
[57,50,19,1],
[57,50,19,2],
[57,50,19,3],
[57,50,19,4],
[57,50,19,5],
[57,50,19,6],
[57,50,19,7],
[57,50,19,8],
[57,50,19,12],
[57,50,19,16],
[57,50,19,17],
[57,50,20,0],
[57,50,20,1],
[57,50,20,2],
[57,50,20,3],
[57,50,20,4],
[57,50,20,5],
[57,50,20,6],
[57,50,20,7],
[57,50,20,11],
[57,50,20,12],
[57,50,20,13],
[57,50,20,14],
[57,50,20,16],
[57,50,20,17],
[57,50,20,19],
[57,50,21,0],
[57,50,21,1],
[57,50,21,2],
[57,50,21,4],
[57,50,21,10],
[57,50,21,11],
[57,50,21,12],
[57,50,21,13],
[57,50,21,14],
[57,50,21,16],
[57,50,21,17],
[57,50,21,19],
[57,50,21,20],
[57,50,22,0],
[57,50,22,1],
[57,50,22,2],
[57,50,22,3],
[57,50,22,4],
[57,50,22,6],
[57,50,22,7],
[57,50,22,8],
[57,50,22,11],
[57,50,22,12],
[57,50,22,13],
[57,50,22,14],
[57,50,22,16],
[57,50,22,17],
[57,50,22,19],
[57,50,22,21],
[57,50,24,0],
[57,50,24,1],
[57,50,24,2],
[57,50,24,5],
[57,50,24,6],
[57,50,24,7],
[57,50,24,8],
[57,50,24,10],
[57,50,24,11],
[57,50,24,12],
[57,50,24,13],
[57,50,24,14],
[57,50,24,17],
[57,50,24,19],
[57,50,24,20],
[57,50,24,21],
[57,50,25,0],
[57,50,25,1],
[57,50,25,2],
[57,50,25,3],
[57,50,25,4],
[57,50,25,5],
[57,50,25,6],
[57,50,25,8],
[57,50,25,10],
[57,50,25,11],
[57,50,25,12],
[57,50,25,13],
[57,50,25,14],
[57,50,25,16],
[57,50,25,17],
[57,50,25,19],
[57,50,27,0],
[57,50,27,1],
[57,50,27,2],
[57,50,27,3],
[57,50,27,4],
[57,50,27,5],
[57,50,27,6],
[57,50,27,7],
[57,50,27,8],
[57,50,27,11],
[57,50,27,12],
[57,50,27,13],
[57,50,27,14],
[57,50,27,16],
[57,50,27,17],
[57,50,28,0],
[57,50,28,1],
[57,50,28,2],
[57,50,28,3],
[57,50,28,4],
[57,50,28,5],
[57,50,28,6],
[57,50,28,8],
[57,50,28,10],
[57,50,28,11],
[57,50,28,12],
[57,50,28,13],
[57,50,28,14],
[57,50,29,0],
[57,50,29,1],
[57,50,29,2],
[57,50,29,3],
[57,50,29,4],
[57,50,29,5],
[57,50,29,6],
[57,50,29,7],
[57,50,29,8],
[57,50,29,10],
[57,50,29,13],
[57,50,29,14],
[57,50,32,0],
[57,50,32,1],
[57,50,32,2],
[57,50,32,3],
[57,50,32,4],
[57,50,32,5],
[57,50,32,6],
[57,50,32,7],
[57,50,32,8],
[57,50,33,0],
[57,50,33,1],
[57,50,33,2],
[57,50,33,3],
[57,50,33,4],
[57,50,33,5],
[57,50,33,6],
[57,50,33,7],
[57,50,34,0],
[57,50,34,1],
[57,50,34,2],
[57,50,34,3],
[57,50,34,4],
[57,50,34,5],
[57,50,36,0],
[57,50,36,1],
[57,50,36,2],
[57,51,4,0],
[57,51,4,1],
[57,51,4,2],
[57,51,7,0],
[57,51,7,1],
[57,51,7,2],
[57,51,7,4],
[57,51,9,0],
[57,51,9,1],
[57,51,9,2],
[57,51,9,4],
[57,51,9,7],
[57,51,10,0],
[57,51,10,1],
[57,51,10,2],
[57,51,10,4],
[57,51,10,7],
[57,51,10,9],
[57,51,11,0],
[57,51,11,1],
[57,51,11,2],
[57,51,11,4],
[57,51,11,7],
[57,51,11,9],
[57,51,11,10],
[57,51,12,0],
[57,51,12,1],
[57,51,12,2],
[57,51,12,4],
[57,51,12,7],
[57,51,12,9],
[57,51,12,10],
[57,51,13,0],
[57,51,13,1],
[57,51,13,2],
[57,51,13,4],
[57,51,13,7],
[57,51,13,9],
[57,51,13,10],
[57,51,13,11],
[57,51,13,12],
[57,51,14,0],
[57,51,14,1],
[57,51,14,2],
[57,51,14,4],
[57,51,14,7],
[57,51,14,9],
[57,51,14,10],
[57,51,14,11],
[57,51,14,12],
[57,51,15,0],
[57,51,15,1],
[57,51,15,2],
[57,51,15,4],
[57,51,15,7],
[57,51,15,10],
[57,51,15,11],
[57,51,15,12],
[57,51,15,13],
[57,51,15,14],
[57,51,16,0],
[57,51,16,1],
[57,51,16,2],
[57,51,16,4],
[57,51,16,7],
[57,51,16,9],
[57,51,16,10],
[57,51,16,11],
[57,51,16,12],
[57,51,16,13],
[57,51,16,15],
[57,51,17,0],
[57,51,17,1],
[57,51,17,2],
[57,51,17,4],
[57,51,17,7],
[57,51,17,9],
[57,51,17,10],
[57,51,17,11],
[57,51,17,12],
[57,51,17,13],
[57,51,17,15],
[57,51,17,16],
[57,51,18,0],
[57,51,18,1],
[57,51,18,2],
[57,51,18,4],
[57,51,18,7],
[57,51,18,10],
[57,51,18,11],
[57,51,18,14],
[57,51,18,16],
[57,51,18,17],
[57,51,19,0],
[57,51,19,1],
[57,51,19,2],
[57,51,19,4],
[57,51,19,7],
[57,51,19,9],
[57,51,19,12],
[57,51,19,15],
[57,51,19,16],
[57,51,19,17],
[57,51,19,18],
[57,51,20,0],
[57,51,20,1],
[57,51,20,2],
[57,51,20,4],
[57,51,20,7],
[57,51,20,11],
[57,51,20,12],
[57,51,20,13],
[57,51,20,14],
[57,51,20,15],
[57,51,20,16],
[57,51,20,17],
[57,51,20,18],
[57,51,20,19],
[57,51,22,0],
[57,51,22,1],
[57,51,22,2],
[57,51,22,4],
[57,51,22,7],
[57,51,22,9],
[57,51,22,11],
[57,51,22,12],
[57,51,22,13],
[57,51,22,14],
[57,51,22,15],
[57,51,22,16],
[57,51,22,17],
[57,51,22,18],
[57,51,22,19],
[57,51,24,0],
[57,51,24,1],
[57,51,24,2],
[57,51,24,7],
[57,51,24,9],
[57,51,24,10],
[57,51,24,11],
[57,51,24,12],
[57,51,24,13],
[57,51,24,14],
[57,51,24,15],
[57,51,24,17],
[57,51,24,18],
[57,51,24,19],
[57,51,24,20],
[57,51,25,0],
[57,51,25,1],
[57,51,25,2],
[57,51,25,4],
[57,51,25,9],
[57,51,25,10],
[57,51,25,11],
[57,51,25,12],
[57,51,25,13],
[57,51,25,14],
[57,51,25,15],
[57,51,25,16],
[57,51,25,17],
[57,51,25,18],
[57,51,25,19],
[57,51,26,0],
[57,51,26,1],
[57,51,26,2],
[57,51,26,4],
[57,51,26,7],
[57,51,26,10],
[57,51,26,11],
[57,51,26,12],
[57,51,26,13],
[57,51,26,14],
[57,51,26,16],
[57,51,26,17],
[57,51,27,0],
[57,51,27,1],
[57,51,27,2],
[57,51,27,4],
[57,51,27,7],
[57,51,27,9],
[57,51,27,11],
[57,51,27,12],
[57,51,27,13],
[57,51,27,14],
[57,51,27,15],
[57,51,27,16],
[57,51,27,17],
[57,51,28,0],
[57,51,28,1],
[57,51,28,2],
[57,51,28,4],
[57,51,28,9],
[57,51,28,10],
[57,51,28,11],
[57,51,28,12],
[57,51,28,13],
[57,51,28,14],
[57,51,28,15],
[57,51,29,0],
[57,51,29,1],
[57,51,29,2],
[57,51,29,4],
[57,51,29,7],
[57,51,29,9],
[57,51,29,10],
[57,51,29,13],
[57,51,29,14],
[57,51,32,0],
[57,51,32,1],
[57,51,32,2],
[57,51,32,4],
[57,51,32,7],
[57,51,32,9],
[57,51,33,0],
[57,51,33,1],
[57,51,33,2],
[57,51,33,4],
[57,51,33,7],
[57,51,34,0],
[57,51,34,1],
[57,51,34,2],
[57,51,34,4],
[57,51,35,0],
[57,51,35,1],
[57,51,35,2],
[57,51,35,4],
[57,51,36,0],
[57,51,36,1],
[57,51,36,2],
[57,52,3,0],
[57,52,3,1],
[57,52,3,2],
[57,52,4,0],
[57,52,4,1],
[57,52,4,2],
[57,52,4,3],
[57,52,5,0],
[57,52,5,1],
[57,52,5,2],
[57,52,5,4],
[57,52,6,0],
[57,52,6,1],
[57,52,6,2],
[57,52,6,4],
[57,52,7,0],
[57,52,7,1],
[57,52,7,2],
[57,52,7,3],
[57,52,7,4],
[57,52,7,5],
[57,52,7,6],
[57,52,8,0],
[57,52,8,1],
[57,52,8,2],
[57,52,8,4],
[57,52,8,7],
[57,52,10,0],
[57,52,10,1],
[57,52,10,2],
[57,52,10,3],
[57,52,10,4],
[57,52,10,5],
[57,52,10,6],
[57,52,10,7],
[57,52,10,8],
[57,52,11,0],
[57,52,11,1],
[57,52,11,2],
[57,52,11,3],
[57,52,11,4],
[57,52,11,5],
[57,52,11,6],
[57,52,11,7],
[57,52,11,8],
[57,52,11,10],
[57,52,12,0],
[57,52,12,1],
[57,52,12,2],
[57,52,12,3],
[57,52,12,4],
[57,52,12,5],
[57,52,12,6],
[57,52,12,7],
[57,52,12,8],
[57,52,12,10],
[57,52,13,0],
[57,52,13,1],
[57,52,13,2],
[57,52,13,3],
[57,52,13,4],
[57,52,13,5],
[57,52,13,6],
[57,52,13,7],
[57,52,13,8],
[57,52,13,10],
[57,52,13,11],
[57,52,13,12],
[57,52,14,0],
[57,52,14,1],
[57,52,14,2],
[57,52,14,3],
[57,52,14,4],
[57,52,14,5],
[57,52,14,6],
[57,52,14,7],
[57,52,14,8],
[57,52,14,10],
[57,52,14,11],
[57,52,14,12],
[57,52,16,0],
[57,52,16,1],
[57,52,16,2],
[57,52,16,3],
[57,52,16,4],
[57,52,16,5],
[57,52,16,6],
[57,52,16,7],
[57,52,16,8],
[57,52,16,10],
[57,52,16,11],
[57,52,16,12],
[57,52,16,13],
[57,52,17,0],
[57,52,17,1],
[57,52,17,2],
[57,52,17,3],
[57,52,17,4],
[57,52,17,5],
[57,52,17,6],
[57,52,17,7],
[57,52,17,8],
[57,52,17,10],
[57,52,17,11],
[57,52,17,12],
[57,52,17,13],
[57,52,17,16],
[57,52,19,0],
[57,52,19,1],
[57,52,19,2],
[57,52,19,3],
[57,52,19,4],
[57,52,19,5],
[57,52,19,6],
[57,52,19,7],
[57,52,19,8],
[57,52,19,12],
[57,52,19,16],
[57,52,19,17],
[57,52,20,0],
[57,52,20,1],
[57,52,20,2],
[57,52,20,3],
[57,52,20,4],
[57,52,20,5],
[57,52,20,6],
[57,52,20,7],
[57,52,20,11],
[57,52,20,12],
[57,52,20,13],
[57,52,20,14],
[57,52,20,16],
[57,52,20,17],
[57,52,20,19],
[57,52,21,0],
[57,52,21,1],
[57,52,21,2],
[57,52,21,4],
[57,52,21,10],
[57,52,21,11],
[57,52,21,12],
[57,52,21,13],
[57,52,21,14],
[57,52,21,16],
[57,52,21,17],
[57,52,21,19],
[57,52,21,20],
[57,52,22,0],
[57,52,22,1],
[57,52,22,2],
[57,52,22,3],
[57,52,22,4],
[57,52,22,6],
[57,52,22,7],
[57,52,22,8],
[57,52,22,11],
[57,52,22,12],
[57,52,22,13],
[57,52,22,14],
[57,52,22,16],
[57,52,22,17],
[57,52,22,19],
[57,52,22,21],
[57,52,24,0],
[57,52,24,1],
[57,52,24,2],
[57,52,24,5],
[57,52,24,6],
[57,52,24,7],
[57,52,24,8],
[57,52,24,10],
[57,52,24,11],
[57,52,24,12],
[57,52,24,13],
[57,52,24,14],
[57,52,24,17],
[57,52,24,19],
[57,52,24,20],
[57,52,24,21],
[57,52,25,0],
[57,52,25,1],
[57,52,25,2],
[57,52,25,3],
[57,52,25,4],
[57,52,25,5],
[57,52,25,6],
[57,52,25,8],
[57,52,25,10],
[57,52,25,11],
[57,52,25,12],
[57,52,25,13],
[57,52,25,14],
[57,52,25,16],
[57,52,25,17],
[57,52,25,19],
[57,52,27,0],
[57,52,27,1],
[57,52,27,2],
[57,52,27,3],
[57,52,27,4],
[57,52,27,5],
[57,52,27,6],
[57,52,27,7],
[57,52,27,8],
[57,52,27,11],
[57,52,27,12],
[57,52,27,13],
[57,52,27,14],
[57,52,27,16],
[57,52,27,17],
[57,52,28,0],
[57,52,28,1],
[57,52,28,2],
[57,52,28,3],
[57,52,28,4],
[57,52,28,5],
[57,52,28,6],
[57,52,28,8],
[57,52,28,10],
[57,52,28,11],
[57,52,28,12],
[57,52,28,13],
[57,52,28,14],
[57,52,29,0],
[57,52,29,1],
[57,52,29,2],
[57,52,29,3],
[57,52,29,4],
[57,52,29,5],
[57,52,29,6],
[57,52,29,7],
[57,52,29,8],
[57,52,29,10],
[57,52,29,13],
[57,52,29,14],
[57,52,32,0],
[57,52,32,1],
[57,52,32,2],
[57,52,32,3],
[57,52,32,4],
[57,52,32,5],
[57,52,32,6],
[57,52,32,7],
[57,52,32,8],
[57,52,33,0],
[57,52,33,1],
[57,52,33,2],
[57,52,33,3],
[57,52,33,4],
[57,52,33,5],
[57,52,33,6],
[57,52,33,7],
[57,52,34,0],
[57,52,34,1],
[57,52,34,2],
[57,52,34,3],
[57,52,34,4],
[57,52,34,5],
[57,52,36,0],
[57,52,36,1],
[57,52,36,2],
[57,53,3,0],
[57,53,3,1],
[57,53,3,2],
[57,53,4,0],
[57,53,4,1],
[57,53,4,2],
[57,53,4,3],
[57,53,5,0],
[57,53,5,1],
[57,53,5,2],
[57,53,5,4],
[57,53,6,0],
[57,53,6,1],
[57,53,6,2],
[57,53,6,4],
[57,53,7,0],
[57,53,7,1],
[57,53,7,2],
[57,53,7,3],
[57,53,7,4],
[57,53,7,5],
[57,53,7,6],
[57,53,8,0],
[57,53,8,1],
[57,53,8,2],
[57,53,8,4],
[57,53,8,7],
[57,53,9,0],
[57,53,9,1],
[57,53,9,2],
[57,53,9,3],
[57,53,9,4],
[57,53,9,5],
[57,53,9,6],
[57,53,9,7],
[57,53,9,8],
[57,53,11,0],
[57,53,11,1],
[57,53,11,2],
[57,53,11,3],
[57,53,11,4],
[57,53,11,5],
[57,53,11,6],
[57,53,11,7],
[57,53,11,8],
[57,53,11,9],
[57,53,12,0],
[57,53,12,1],
[57,53,12,2],
[57,53,12,3],
[57,53,12,4],
[57,53,12,5],
[57,53,12,6],
[57,53,12,7],
[57,53,12,8],
[57,53,12,9],
[57,53,13,0],
[57,53,13,1],
[57,53,13,2],
[57,53,13,3],
[57,53,13,4],
[57,53,13,5],
[57,53,13,6],
[57,53,13,7],
[57,53,13,8],
[57,53,13,9],
[57,53,13,11],
[57,53,13,12],
[57,53,14,0],
[57,53,14,1],
[57,53,14,2],
[57,53,14,3],
[57,53,14,4],
[57,53,14,5],
[57,53,14,6],
[57,53,14,7],
[57,53,14,8],
[57,53,14,9],
[57,53,14,11],
[57,53,14,12],
[57,53,15,0],
[57,53,15,1],
[57,53,15,2],
[57,53,15,3],
[57,53,15,4],
[57,53,15,5],
[57,53,15,6],
[57,53,15,7],
[57,53,15,8],
[57,53,15,11],
[57,53,15,12],
[57,53,15,13],
[57,53,15,14],
[57,53,16,0],
[57,53,16,1],
[57,53,16,2],
[57,53,16,3],
[57,53,16,4],
[57,53,16,5],
[57,53,16,6],
[57,53,16,7],
[57,53,16,8],
[57,53,16,9],
[57,53,16,11],
[57,53,16,12],
[57,53,16,13],
[57,53,16,15],
[57,53,17,0],
[57,53,17,1],
[57,53,17,2],
[57,53,17,3],
[57,53,17,4],
[57,53,17,5],
[57,53,17,6],
[57,53,17,7],
[57,53,17,8],
[57,53,17,9],
[57,53,17,11],
[57,53,17,12],
[57,53,17,13],
[57,53,17,15],
[57,53,17,16],
[57,53,18,0],
[57,53,18,1],
[57,53,18,2],
[57,53,18,3],
[57,53,18,4],
[57,53,18,5],
[57,53,18,6],
[57,53,18,7],
[57,53,18,8],
[57,53,18,11],
[57,53,18,14],
[57,53,18,16],
[57,53,18,17],
[57,53,19,0],
[57,53,19,1],
[57,53,19,2],
[57,53,19,3],
[57,53,19,4],
[57,53,19,5],
[57,53,19,6],
[57,53,19,7],
[57,53,19,8],
[57,53,19,9],
[57,53,19,12],
[57,53,19,15],
[57,53,19,16],
[57,53,19,17],
[57,53,19,18],
[57,53,21,0],
[57,53,21,1],
[57,53,21,2],
[57,53,21,4],
[57,53,21,9],
[57,53,21,11],
[57,53,21,12],
[57,53,21,13],
[57,53,21,14],
[57,53,21,15],
[57,53,21,16],
[57,53,21,17],
[57,53,21,18],
[57,53,21,19],
[57,53,24,0],
[57,53,24,1],
[57,53,24,2],
[57,53,24,5],
[57,53,24,6],
[57,53,24,7],
[57,53,24,8],
[57,53,24,9],
[57,53,24,11],
[57,53,24,12],
[57,53,24,13],
[57,53,24,14],
[57,53,24,15],
[57,53,24,17],
[57,53,24,18],
[57,53,24,19],
[57,53,24,21],
[57,53,25,0],
[57,53,25,1],
[57,53,25,2],
[57,53,25,3],
[57,53,25,4],
[57,53,25,5],
[57,53,25,6],
[57,53,25,8],
[57,53,25,9],
[57,53,25,11],
[57,53,25,12],
[57,53,25,13],
[57,53,25,14],
[57,53,25,15],
[57,53,25,16],
[57,53,25,17],
[57,53,25,18],
[57,53,25,19],
[57,53,26,0],
[57,53,26,1],
[57,53,26,2],
[57,53,26,3],
[57,53,26,4],
[57,53,26,5],
[57,53,26,6],
[57,53,26,7],
[57,53,26,8],
[57,53,26,11],
[57,53,26,12],
[57,53,26,13],
[57,53,26,14],
[57,53,26,16],
[57,53,26,17],
[57,53,28,0],
[57,53,28,1],
[57,53,28,2],
[57,53,28,3],
[57,53,28,4],
[57,53,28,5],
[57,53,28,6],
[57,53,28,8],
[57,53,28,9],
[57,53,28,11],
[57,53,28,12],
[57,53,28,13],
[57,53,28,14],
[57,53,28,15],
[57,53,29,0],
[57,53,29,1],
[57,53,29,2],
[57,53,29,3],
[57,53,29,4],
[57,53,29,5],
[57,53,29,6],
[57,53,29,7],
[57,53,29,8],
[57,53,29,9],
[57,53,29,13],
[57,53,29,14],
[57,53,32,0],
[57,53,32,1],
[57,53,32,2],
[57,53,32,3],
[57,53,32,4],
[57,53,32,5],
[57,53,32,6],
[57,53,32,7],
[57,53,32,8],
[57,53,32,9],
[57,53,33,0],
[57,53,33,1],
[57,53,33,2],
[57,53,33,3],
[57,53,33,4],
[57,53,33,5],
[57,53,33,6],
[57,53,33,7],
[57,53,34,0],
[57,53,34,1],
[57,53,34,2],
[57,53,34,3],
[57,53,34,4],
[57,53,34,5],
[57,53,35,0],
[57,53,35,1],
[57,53,35,2],
[57,53,35,3],
[57,53,35,4],
[57,53,36,0],
[57,53,36,1],
[57,53,36,2],
[57,54,3,0],
[57,54,3,1],
[57,54,3,2],
[57,54,4,0],
[57,54,4,1],
[57,54,4,2],
[57,54,4,3],
[57,54,5,0],
[57,54,5,1],
[57,54,5,2],
[57,54,5,4],
[57,54,6,0],
[57,54,6,1],
[57,54,6,2],
[57,54,6,4],
[57,54,7,0],
[57,54,7,1],
[57,54,7,2],
[57,54,7,3],
[57,54,7,4],
[57,54,7,5],
[57,54,7,6],
[57,54,8,0],
[57,54,8,1],
[57,54,8,2],
[57,54,8,4],
[57,54,8,7],
[57,54,9,0],
[57,54,9,1],
[57,54,9,2],
[57,54,9,3],
[57,54,9,4],
[57,54,9,5],
[57,54,9,6],
[57,54,9,7],
[57,54,9,8],
[57,54,11,0],
[57,54,11,1],
[57,54,11,2],
[57,54,11,3],
[57,54,11,4],
[57,54,11,5],
[57,54,11,6],
[57,54,11,7],
[57,54,11,8],
[57,54,11,9],
[57,54,12,0],
[57,54,12,1],
[57,54,12,2],
[57,54,12,3],
[57,54,12,4],
[57,54,12,5],
[57,54,12,6],
[57,54,12,7],
[57,54,12,8],
[57,54,12,9],
[57,54,13,0],
[57,54,13,1],
[57,54,13,2],
[57,54,13,3],
[57,54,13,4],
[57,54,13,5],
[57,54,13,6],
[57,54,13,7],
[57,54,13,8],
[57,54,13,9],
[57,54,13,11],
[57,54,13,12],
[57,54,14,0],
[57,54,14,1],
[57,54,14,2],
[57,54,14,3],
[57,54,14,4],
[57,54,14,5],
[57,54,14,6],
[57,54,14,7],
[57,54,14,8],
[57,54,14,9],
[57,54,14,11],
[57,54,14,12],
[57,54,15,0],
[57,54,15,1],
[57,54,15,2],
[57,54,15,3],
[57,54,15,4],
[57,54,15,5],
[57,54,15,6],
[57,54,15,7],
[57,54,15,8],
[57,54,15,11],
[57,54,15,12],
[57,54,15,13],
[57,54,15,14],
[57,54,16,0],
[57,54,16,1],
[57,54,16,2],
[57,54,16,3],
[57,54,16,4],
[57,54,16,5],
[57,54,16,6],
[57,54,16,7],
[57,54,16,8],
[57,54,16,9],
[57,54,16,11],
[57,54,16,12],
[57,54,16,13],
[57,54,16,15],
[57,54,17,0],
[57,54,17,1],
[57,54,17,2],
[57,54,17,3],
[57,54,17,4],
[57,54,17,5],
[57,54,17,6],
[57,54,17,7],
[57,54,17,8],
[57,54,17,9],
[57,54,17,11],
[57,54,17,12],
[57,54,17,13],
[57,54,17,15],
[57,54,17,16],
[57,54,18,0],
[57,54,18,1],
[57,54,18,2],
[57,54,18,3],
[57,54,18,4],
[57,54,18,5],
[57,54,18,6],
[57,54,18,7],
[57,54,18,8],
[57,54,18,11],
[57,54,18,14],
[57,54,18,16],
[57,54,18,17],
[57,54,19,0],
[57,54,19,1],
[57,54,19,2],
[57,54,19,3],
[57,54,19,4],
[57,54,19,5],
[57,54,19,6],
[57,54,19,7],
[57,54,19,8],
[57,54,19,9],
[57,54,19,12],
[57,54,19,15],
[57,54,19,16],
[57,54,19,17],
[57,54,19,18],
[57,54,21,0],
[57,54,21,1],
[57,54,21,2],
[57,54,21,4],
[57,54,21,9],
[57,54,21,11],
[57,54,21,12],
[57,54,21,13],
[57,54,21,14],
[57,54,21,15],
[57,54,21,16],
[57,54,21,17],
[57,54,21,18],
[57,54,21,19],
[57,54,24,0],
[57,54,24,1],
[57,54,24,2],
[57,54,24,5],
[57,54,24,6],
[57,54,24,7],
[57,54,24,8],
[57,54,24,9],
[57,54,24,11],
[57,54,24,12],
[57,54,24,13],
[57,54,24,14],
[57,54,24,15],
[57,54,24,17],
[57,54,24,18],
[57,54,24,19],
[57,54,24,21],
[57,54,25,0],
[57,54,25,1],
[57,54,25,2],
[57,54,25,3],
[57,54,25,4],
[57,54,25,5],
[57,54,25,6],
[57,54,25,8],
[57,54,25,9],
[57,54,25,11],
[57,54,25,12],
[57,54,25,13],
[57,54,25,14],
[57,54,25,15],
[57,54,25,16],
[57,54,25,17],
[57,54,25,18],
[57,54,25,19],
[57,54,26,0],
[57,54,26,1],
[57,54,26,2],
[57,54,26,3],
[57,54,26,4],
[57,54,26,5],
[57,54,26,6],
[57,54,26,7],
[57,54,26,8],
[57,54,26,11],
[57,54,26,12],
[57,54,26,13],
[57,54,26,14],
[57,54,26,16],
[57,54,26,17],
[57,54,28,0],
[57,54,28,1],
[57,54,28,2],
[57,54,28,3],
[57,54,28,4],
[57,54,28,5],
[57,54,28,6],
[57,54,28,8],
[57,54,28,9],
[57,54,28,11],
[57,54,28,12],
[57,54,28,13],
[57,54,28,14],
[57,54,28,15],
[57,54,29,0],
[57,54,29,1],
[57,54,29,2],
[57,54,29,3],
[57,54,29,4],
[57,54,29,5],
[57,54,29,6],
[57,54,29,7],
[57,54,29,8],
[57,54,29,9],
[57,54,29,13],
[57,54,29,14],
[57,54,32,0],
[57,54,32,1],
[57,54,32,2],
[57,54,32,3],
[57,54,32,4],
[57,54,32,5],
[57,54,32,6],
[57,54,32,7],
[57,54,32,8],
[57,54,32,9],
[57,54,33,0],
[57,54,33,1],
[57,54,33,2],
[57,54,33,3],
[57,54,33,4],
[57,54,33,5],
[57,54,33,6],
[57,54,33,7],
[57,54,34,0],
[57,54,34,1],
[57,54,34,2],
[57,54,34,3],
[57,54,34,4],
[57,54,34,5],
[57,54,35,0],
[57,54,35,1],
[57,54,35,2],
[57,54,35,3],
[57,54,35,4],
[57,54,36,0],
[57,54,36,1],
[57,54,36,2],
[57,55,3,0],
[57,55,3,1],
[57,55,3,2],
[57,55,4,0],
[57,55,4,1],
[57,55,4,2],
[57,55,4,3],
[57,55,5,0],
[57,55,5,1],
[57,55,5,2],
[57,55,5,4],
[57,55,6,0],
[57,55,6,1],
[57,55,6,2],
[57,55,6,4],
[57,55,7,0],
[57,55,7,1],
[57,55,7,2],
[57,55,7,3],
[57,55,7,4],
[57,55,7,5],
[57,55,7,6],
[57,55,8,0],
[57,55,8,1],
[57,55,8,2],
[57,55,8,4],
[57,55,8,7],
[57,55,9,0],
[57,55,9,1],
[57,55,9,2],
[57,55,9,3],
[57,55,9,4],
[57,55,9,5],
[57,55,9,6],
[57,55,9,7],
[57,55,9,8],
[57,55,11,0],
[57,55,11,1],
[57,55,11,2],
[57,55,11,3],
[57,55,11,4],
[57,55,11,5],
[57,55,11,6],
[57,55,11,7],
[57,55,11,8],
[57,55,11,9],
[57,55,12,0],
[57,55,12,1],
[57,55,12,2],
[57,55,12,3],
[57,55,12,4],
[57,55,12,5],
[57,55,12,6],
[57,55,12,7],
[57,55,12,8],
[57,55,12,9],
[57,55,13,0],
[57,55,13,1],
[57,55,13,2],
[57,55,13,3],
[57,55,13,4],
[57,55,13,5],
[57,55,13,6],
[57,55,13,7],
[57,55,13,8],
[57,55,13,9],
[57,55,13,11],
[57,55,13,12],
[57,55,14,0],
[57,55,14,1],
[57,55,14,2],
[57,55,14,3],
[57,55,14,4],
[57,55,14,5],
[57,55,14,6],
[57,55,14,7],
[57,55,14,8],
[57,55,14,9],
[57,55,14,11],
[57,55,14,12],
[57,55,15,0],
[57,55,15,1],
[57,55,15,2],
[57,55,15,3],
[57,55,15,4],
[57,55,15,5],
[57,55,15,6],
[57,55,15,7],
[57,55,15,8],
[57,55,15,11],
[57,55,15,12],
[57,55,15,13],
[57,55,15,14],
[57,55,16,0],
[57,55,16,1],
[57,55,16,2],
[57,55,16,3],
[57,55,16,4],
[57,55,16,5],
[57,55,16,6],
[57,55,16,7],
[57,55,16,8],
[57,55,16,9],
[57,55,16,11],
[57,55,16,12],
[57,55,16,13],
[57,55,16,15],
[57,55,17,0],
[57,55,17,1],
[57,55,17,2],
[57,55,17,3],
[57,55,17,4],
[57,55,17,5],
[57,55,17,6],
[57,55,17,7],
[57,55,17,8],
[57,55,17,9],
[57,55,17,11],
[57,55,17,12],
[57,55,17,13],
[57,55,17,15],
[57,55,17,16],
[57,55,18,0],
[57,55,18,1],
[57,55,18,2],
[57,55,18,3],
[57,55,18,4],
[57,55,18,5],
[57,55,18,6],
[57,55,18,7],
[57,55,18,8],
[57,55,18,11],
[57,55,18,14],
[57,55,18,16],
[57,55,18,17],
[57,55,19,0],
[57,55,19,1],
[57,55,19,2],
[57,55,19,3],
[57,55,19,4],
[57,55,19,5],
[57,55,19,6],
[57,55,19,7],
[57,55,19,8],
[57,55,19,9],
[57,55,19,12],
[57,55,19,15],
[57,55,19,16],
[57,55,19,17],
[57,55,19,18],
[57,55,21,0],
[57,55,21,1],
[57,55,21,2],
[57,55,21,4],
[57,55,21,9],
[57,55,21,11],
[57,55,21,12],
[57,55,21,13],
[57,55,21,14],
[57,55,21,15],
[57,55,21,16],
[57,55,21,17],
[57,55,21,18],
[57,55,21,19],
[57,55,24,0],
[57,55,24,1],
[57,55,24,2],
[57,55,24,5],
[57,55,24,6],
[57,55,24,7],
[57,55,24,8],
[57,55,24,9],
[57,55,24,11],
[57,55,24,12],
[57,55,24,13],
[57,55,24,14],
[57,55,24,15],
[57,55,24,17],
[57,55,24,18],
[57,55,24,19],
[57,55,24,21],
[57,55,25,0],
[57,55,25,1],
[57,55,25,2],
[57,55,25,3],
[57,55,25,4],
[57,55,25,5],
[57,55,25,6],
[57,55,25,8],
[57,55,25,9],
[57,55,25,11],
[57,55,25,12],
[57,55,25,13],
[57,55,25,14],
[57,55,25,15],
[57,55,25,16],
[57,55,25,17],
[57,55,25,18],
[57,55,25,19],
[57,55,26,0],
[57,55,26,1],
[57,55,26,2],
[57,55,26,3],
[57,55,26,4],
[57,55,26,5],
[57,55,26,6],
[57,55,26,7],
[57,55,26,8],
[57,55,26,11],
[57,55,26,12],
[57,55,26,13],
[57,55,26,14],
[57,55,26,16],
[57,55,26,17],
[57,55,28,0],
[57,55,28,1],
[57,55,28,2],
[57,55,28,3],
[57,55,28,4],
[57,55,28,5],
[57,55,28,6],
[57,55,28,8],
[57,55,28,9],
[57,55,28,11],
[57,55,28,12],
[57,55,28,13],
[57,55,28,14],
[57,55,28,15],
[57,55,29,0],
[57,55,29,1],
[57,55,29,2],
[57,55,29,3],
[57,55,29,4],
[57,55,29,5],
[57,55,29,6],
[57,55,29,7],
[57,55,29,8],
[57,55,29,9],
[57,55,29,13],
[57,55,29,14],
[57,55,32,0],
[57,55,32,1],
[57,55,32,2],
[57,55,32,3],
[57,55,32,4],
[57,55,32,5],
[57,55,32,6],
[57,55,32,7],
[57,55,32,8],
[57,55,32,9],
[57,55,33,0],
[57,55,33,1],
[57,55,33,2],
[57,55,33,3],
[57,55,33,4],
[57,55,33,5],
[57,55,33,6],
[57,55,33,7],
[57,55,34,0],
[57,55,34,1],
[57,55,34,2],
[57,55,34,3],
[57,55,34,4],
[57,55,34,5],
[57,55,35,0],
[57,55,35,1],
[57,55,35,2],
[57,55,35,3],
[57,55,35,4],
[57,55,36,0],
[57,55,36,1],
[57,55,36,2],
[57,56,3,0],
[57,56,3,1],
[57,56,3,2],
[57,56,4,0],
[57,56,4,1],
[57,56,4,2],
[57,56,4,3],
[57,56,5,0],
[57,56,5,1],
[57,56,5,2],
[57,56,5,4],
[57,56,6,0],
[57,56,6,1],
[57,56,6,2],
[57,56,6,4],
[57,56,7,0],
[57,56,7,1],
[57,56,7,2],
[57,56,7,3],
[57,56,7,4],
[57,56,7,5],
[57,56,7,6],
[57,56,8,0],
[57,56,8,1],
[57,56,8,2],
[57,56,8,4],
[57,56,8,7],
[57,56,10,0],
[57,56,10,1],
[57,56,10,2],
[57,56,10,3],
[57,56,10,4],
[57,56,10,5],
[57,56,10,6],
[57,56,10,7],
[57,56,10,8],
[57,56,11,0],
[57,56,11,1],
[57,56,11,2],
[57,56,11,3],
[57,56,11,4],
[57,56,11,5],
[57,56,11,6],
[57,56,11,7],
[57,56,11,8],
[57,56,11,10],
[57,56,12,0],
[57,56,12,1],
[57,56,12,2],
[57,56,12,3],
[57,56,12,4],
[57,56,12,5],
[57,56,12,6],
[57,56,12,7],
[57,56,12,8],
[57,56,12,10],
[57,56,13,0],
[57,56,13,1],
[57,56,13,2],
[57,56,13,3],
[57,56,13,4],
[57,56,13,5],
[57,56,13,6],
[57,56,13,7],
[57,56,13,8],
[57,56,13,10],
[57,56,13,11],
[57,56,13,12],
[57,56,14,0],
[57,56,14,1],
[57,56,14,2],
[57,56,14,3],
[57,56,14,4],
[57,56,14,5],
[57,56,14,6],
[57,56,14,7],
[57,56,14,8],
[57,56,14,10],
[57,56,14,11],
[57,56,14,12],
[57,56,16,0],
[57,56,16,1],
[57,56,16,2],
[57,56,16,3],
[57,56,16,4],
[57,56,16,5],
[57,56,16,6],
[57,56,16,7],
[57,56,16,8],
[57,56,16,10],
[57,56,16,11],
[57,56,16,12],
[57,56,16,13],
[57,56,17,0],
[57,56,17,1],
[57,56,17,2],
[57,56,17,3],
[57,56,17,4],
[57,56,17,5],
[57,56,17,6],
[57,56,17,7],
[57,56,17,8],
[57,56,17,10],
[57,56,17,11],
[57,56,17,12],
[57,56,17,13],
[57,56,17,16],
[57,56,19,0],
[57,56,19,1],
[57,56,19,2],
[57,56,19,3],
[57,56,19,4],
[57,56,19,5],
[57,56,19,6],
[57,56,19,7],
[57,56,19,8],
[57,56,19,12],
[57,56,19,16],
[57,56,19,17],
[57,56,20,0],
[57,56,20,1],
[57,56,20,2],
[57,56,20,3],
[57,56,20,4],
[57,56,20,5],
[57,56,20,6],
[57,56,20,7],
[57,56,20,11],
[57,56,20,12],
[57,56,20,13],
[57,56,20,14],
[57,56,20,16],
[57,56,20,17],
[57,56,20,19],
[57,56,21,0],
[57,56,21,1],
[57,56,21,2],
[57,56,21,4],
[57,56,21,10],
[57,56,21,11],
[57,56,21,12],
[57,56,21,13],
[57,56,21,14],
[57,56,21,16],
[57,56,21,17],
[57,56,21,19],
[57,56,21,20],
[57,56,22,0],
[57,56,22,1],
[57,56,22,2],
[57,56,22,3],
[57,56,22,4],
[57,56,22,6],
[57,56,22,7],
[57,56,22,8],
[57,56,22,11],
[57,56,22,12],
[57,56,22,13],
[57,56,22,14],
[57,56,22,16],
[57,56,22,17],
[57,56,22,19],
[57,56,22,21],
[57,56,24,0],
[57,56,24,1],
[57,56,24,2],
[57,56,24,5],
[57,56,24,6],
[57,56,24,7],
[57,56,24,8],
[57,56,24,10],
[57,56,24,11],
[57,56,24,12],
[57,56,24,13],
[57,56,24,14],
[57,56,24,17],
[57,56,24,19],
[57,56,24,20],
[57,56,24,21],
[57,56,25,0],
[57,56,25,1],
[57,56,25,2],
[57,56,25,3],
[57,56,25,4],
[57,56,25,5],
[57,56,25,6],
[57,56,25,8],
[57,56,25,10],
[57,56,25,11],
[57,56,25,12],
[57,56,25,13],
[57,56,25,14],
[57,56,25,16],
[57,56,25,17],
[57,56,25,19],
[57,56,27,0],
[57,56,27,1],
[57,56,27,2],
[57,56,27,3],
[57,56,27,4],
[57,56,27,5],
[57,56,27,6],
[57,56,27,7],
[57,56,27,8],
[57,56,27,11],
[57,56,27,12],
[57,56,27,13],
[57,56,27,14],
[57,56,27,16],
[57,56,27,17],
[57,56,28,0],
[57,56,28,1],
[57,56,28,2],
[57,56,28,3],
[57,56,28,4],
[57,56,28,5],
[57,56,28,6],
[57,56,28,8],
[57,56,28,10],
[57,56,28,11],
[57,56,28,12],
[57,56,28,13],
[57,56,28,14],
[57,56,29,0],
[57,56,29,1],
[57,56,29,2],
[57,56,29,3],
[57,56,29,4],
[57,56,29,5],
[57,56,29,6],
[57,56,29,7],
[57,56,29,8],
[57,56,29,10],
[57,56,29,13],
[57,56,29,14],
[57,56,32,0],
[57,56,32,1],
[57,56,32,2],
[57,56,32,3],
[57,56,32,4],
[57,56,32,5],
[57,56,32,6],
[57,56,32,7],
[57,56,32,8],
[57,56,33,0],
[57,56,33,1],
[57,56,33,2],
[57,56,33,3],
[57,56,33,4],
[57,56,33,5],
[57,56,33,6],
[57,56,33,7],
[57,56,34,0],
[57,56,34,1],
[57,56,34,2],
[57,56,34,3],
[57,56,34,4],
[57,56,34,5],
[57,56,36,0],
[57,56,36,1],
[57,56,36,2],
[58,4,3,0],
[58,4,3,1],
[58,4,3,2],
[58,5,4,0],
[58,5,4,1],
[58,5,4,2],
[58,6,4,0],
[58,6,4,1],
[58,6,4,2],
[58,7,3,0],
[58,7,3,1],
[58,7,3,2],
[58,7,4,0],
[58,7,4,1],
[58,7,4,2],
[58,7,4,3],
[58,7,5,0],
[58,7,5,1],
[58,7,5,2],
[58,7,5,4],
[58,7,6,0],
[58,7,6,1],
[58,7,6,2],
[58,7,6,4],
[58,8,4,0],
[58,8,4,1],
[58,8,4,2],
[58,8,7,0],
[58,8,7,1],
[58,8,7,2],
[58,8,7,4],
[58,9,3,0],
[58,9,3,1],
[58,9,3,2],
[58,9,4,0],
[58,9,4,1],
[58,9,4,2],
[58,9,4,3],
[58,9,5,0],
[58,9,5,1],
[58,9,5,2],
[58,9,5,4],
[58,9,6,0],
[58,9,6,1],
[58,9,6,2],
[58,9,6,4],
[58,9,7,0],
[58,9,7,1],
[58,9,7,2],
[58,9,7,3],
[58,9,7,4],
[58,9,7,5],
[58,9,7,6],
[58,9,8,0],
[58,9,8,1],
[58,9,8,2],
[58,9,8,4],
[58,9,8,7],
[58,10,3,0],
[58,10,3,1],
[58,10,3,2],
[58,10,4,0],
[58,10,4,1],
[58,10,4,2],
[58,10,4,3],
[58,10,5,0],
[58,10,5,1],
[58,10,5,2],
[58,10,5,4],
[58,10,6,0],
[58,10,6,1],
[58,10,6,2],
[58,10,6,4],
[58,10,7,0],
[58,10,7,1],
[58,10,7,2],
[58,10,7,3],
[58,10,7,4],
[58,10,7,5],
[58,10,7,6],
[58,10,8,0],
[58,10,8,1],
[58,10,8,2],
[58,10,8,4],
[58,10,8,7],
[58,10,9,0],
[58,10,9,1],
[58,10,9,2],
[58,10,9,3],
[58,10,9,4],
[58,10,9,5],
[58,10,9,6],
[58,10,9,7],
[58,10,9,8],
[58,11,3,0],
[58,11,3,1],
[58,11,3,2],
[58,11,4,0],
[58,11,4,1],
[58,11,4,2],
[58,11,4,3],
[58,11,5,0],
[58,11,5,1],
[58,11,5,2],
[58,11,5,4],
[58,11,6,0],
[58,11,6,1],
[58,11,6,2],
[58,11,6,4],
[58,11,7,0],
[58,11,7,1],
[58,11,7,2],
[58,11,7,3],
[58,11,7,4],
[58,11,7,5],
[58,11,7,6],
[58,11,8,0],
[58,11,8,1],
[58,11,8,2],
[58,11,8,4],
[58,11,8,7],
[58,11,9,0],
[58,11,9,1],
[58,11,9,2],
[58,11,9,3],
[58,11,9,4],
[58,11,9,5],
[58,11,9,6],
[58,11,9,7],
[58,11,9,8],
[58,11,10,0],
[58,11,10,1],
[58,11,10,2],
[58,11,10,3],
[58,11,10,4],
[58,11,10,5],
[58,11,10,6],
[58,11,10,7],
[58,11,10,8],
[58,11,10,9],
[58,12,3,0],
[58,12,3,1],
[58,12,3,2],
[58,12,4,0],
[58,12,4,1],
[58,12,4,2],
[58,12,4,3],
[58,12,5,0],
[58,12,5,1],
[58,12,5,2],
[58,12,5,4],
[58,12,6,0],
[58,12,6,1],
[58,12,6,2],
[58,12,6,4],
[58,12,7,0],
[58,12,7,1],
[58,12,7,2],
[58,12,7,3],
[58,12,7,4],
[58,12,7,5],
[58,12,7,6],
[58,12,8,0],
[58,12,8,1],
[58,12,8,2],
[58,12,8,4],
[58,12,8,7],
[58,12,9,0],
[58,12,9,1],
[58,12,9,2],
[58,12,9,3],
[58,12,9,4],
[58,12,9,5],
[58,12,9,6],
[58,12,9,7],
[58,12,9,8],
[58,12,10,0],
[58,12,10,1],
[58,12,10,2],
[58,12,10,3],
[58,12,10,4],
[58,12,10,5],
[58,12,10,6],
[58,12,10,7],
[58,12,10,8],
[58,12,10,9],
[58,13,3,0],
[58,13,3,1],
[58,13,3,2],
[58,13,4,0],
[58,13,4,1],
[58,13,4,2],
[58,13,4,3],
[58,13,5,0],
[58,13,5,1],
[58,13,5,2],
[58,13,5,4],
[58,13,6,0],
[58,13,6,1],
[58,13,6,2],
[58,13,6,4],
[58,13,7,0],
[58,13,7,1],
[58,13,7,2],
[58,13,7,3],
[58,13,7,4],
[58,13,7,5],
[58,13,7,6],
[58,13,8,0],
[58,13,8,1],
[58,13,8,2],
[58,13,8,4],
[58,13,8,7],
[58,13,9,0],
[58,13,9,1],
[58,13,9,2],
[58,13,9,3],
[58,13,9,4],
[58,13,9,5],
[58,13,9,6],
[58,13,9,7],
[58,13,9,8],
[58,13,10,0],
[58,13,10,1],
[58,13,10,2],
[58,13,10,3],
[58,13,10,4],
[58,13,10,5],
[58,13,10,6],
[58,13,10,7],
[58,13,10,8],
[58,13,10,9],
[58,13,11,0],
[58,13,11,1],
[58,13,11,2],
[58,13,11,3],
[58,13,11,4],
[58,13,11,5],
[58,13,11,6],
[58,13,11,7],
[58,13,11,8],
[58,13,11,9],
[58,13,11,10],
[58,13,12,0],
[58,13,12,1],
[58,13,12,2],
[58,13,12,3],
[58,13,12,4],
[58,13,12,5],
[58,13,12,6],
[58,13,12,7],
[58,13,12,8],
[58,13,12,9],
[58,13,12,10],
[58,14,3,0],
[58,14,3,1],
[58,14,3,2],
[58,14,4,0],
[58,14,4,1],
[58,14,4,2],
[58,14,4,3],
[58,14,5,0],
[58,14,5,1],
[58,14,5,2],
[58,14,5,4],
[58,14,6,0],
[58,14,6,1],
[58,14,6,2],
[58,14,6,4],
[58,14,7,0],
[58,14,7,1],
[58,14,7,2],
[58,14,7,3],
[58,14,7,4],
[58,14,7,5],
[58,14,7,6],
[58,14,8,0],
[58,14,8,1],
[58,14,8,2],
[58,14,8,4],
[58,14,8,7],
[58,14,9,0],
[58,14,9,1],
[58,14,9,2],
[58,14,9,3],
[58,14,9,4],
[58,14,9,5],
[58,14,9,6],
[58,14,9,7],
[58,14,9,8],
[58,14,10,0],
[58,14,10,1],
[58,14,10,2],
[58,14,10,3],
[58,14,10,4],
[58,14,10,5],
[58,14,10,6],
[58,14,10,7],
[58,14,10,8],
[58,14,10,9],
[58,14,11,0],
[58,14,11,1],
[58,14,11,2],
[58,14,11,3],
[58,14,11,4],
[58,14,11,5],
[58,14,11,6],
[58,14,11,7],
[58,14,11,8],
[58,14,11,9],
[58,14,11,10],
[58,14,12,0],
[58,14,12,1],
[58,14,12,2],
[58,14,12,3],
[58,14,12,4],
[58,14,12,5],
[58,14,12,6],
[58,14,12,7],
[58,14,12,8],
[58,14,12,9],
[58,14,12,10],
[58,15,3,0],
[58,15,3,1],
[58,15,3,2],
[58,15,4,0],
[58,15,4,1],
[58,15,4,2],
[58,15,4,3],
[58,15,5,0],
[58,15,5,1],
[58,15,5,2],
[58,15,5,4],
[58,15,6,0],
[58,15,6,1],
[58,15,6,2],
[58,15,6,4],
[58,15,7,0],
[58,15,7,1],
[58,15,7,2],
[58,15,7,3],
[58,15,7,4],
[58,15,7,5],
[58,15,7,6],
[58,15,8,0],
[58,15,8,1],
[58,15,8,2],
[58,15,8,4],
[58,15,8,7],
[58,15,10,0],
[58,15,10,1],
[58,15,10,2],
[58,15,10,3],
[58,15,10,4],
[58,15,10,5],
[58,15,10,6],
[58,15,10,7],
[58,15,10,8],
[58,15,11,0],
[58,15,11,1],
[58,15,11,2],
[58,15,11,3],
[58,15,11,4],
[58,15,11,5],
[58,15,11,6],
[58,15,11,7],
[58,15,11,8],
[58,15,11,10],
[58,15,12,0],
[58,15,12,1],
[58,15,12,2],
[58,15,12,3],
[58,15,12,4],
[58,15,12,5],
[58,15,12,6],
[58,15,12,7],
[58,15,12,8],
[58,15,12,10],
[58,15,13,0],
[58,15,13,1],
[58,15,13,2],
[58,15,13,3],
[58,15,13,4],
[58,15,13,5],
[58,15,13,6],
[58,15,13,7],
[58,15,13,8],
[58,15,13,10],
[58,15,13,11],
[58,15,13,12],
[58,15,14,0],
[58,15,14,1],
[58,15,14,2],
[58,15,14,3],
[58,15,14,4],
[58,15,14,5],
[58,15,14,6],
[58,15,14,7],
[58,15,14,8],
[58,15,14,10],
[58,15,14,11],
[58,15,14,12],
[58,17,3,0],
[58,17,3,1],
[58,17,3,2],
[58,17,4,0],
[58,17,4,1],
[58,17,4,2],
[58,17,4,3],
[58,17,5,0],
[58,17,5,1],
[58,17,5,2],
[58,17,5,4],
[58,17,6,0],
[58,17,6,1],
[58,17,6,2],
[58,17,6,4],
[58,17,7,0],
[58,17,7,1],
[58,17,7,2],
[58,17,7,3],
[58,17,7,4],
[58,17,7,5],
[58,17,7,6],
[58,17,8,0],
[58,17,8,1],
[58,17,8,2],
[58,17,8,4],
[58,17,8,7],
[58,17,9,0],
[58,17,9,1],
[58,17,9,2],
[58,17,9,3],
[58,17,9,4],
[58,17,9,5],
[58,17,9,6],
[58,17,9,7],
[58,17,9,8],
[58,17,10,0],
[58,17,10,1],
[58,17,10,2],
[58,17,10,3],
[58,17,10,4],
[58,17,10,5],
[58,17,10,6],
[58,17,10,7],
[58,17,10,8],
[58,17,10,9],
[58,17,11,0],
[58,17,11,1],
[58,17,11,2],
[58,17,11,3],
[58,17,11,4],
[58,17,11,5],
[58,17,11,6],
[58,17,11,7],
[58,17,11,8],
[58,17,11,9],
[58,17,11,10],
[58,17,12,0],
[58,17,12,1],
[58,17,12,2],
[58,17,12,3],
[58,17,12,4],
[58,17,12,5],
[58,17,12,6],
[58,17,12,7],
[58,17,12,8],
[58,17,12,9],
[58,17,12,10],
[58,17,13,0],
[58,17,13,1],
[58,17,13,2],
[58,17,13,3],
[58,17,13,4],
[58,17,13,5],
[58,17,13,6],
[58,17,13,7],
[58,17,13,8],
[58,17,13,9],
[58,17,13,10],
[58,17,13,11],
[58,17,13,12],
[58,17,14,0],
[58,17,14,1],
[58,17,14,2],
[58,17,14,3],
[58,17,14,4],
[58,17,14,5],
[58,17,14,6],
[58,17,14,7],
[58,17,14,8],
[58,17,14,9],
[58,17,14,10],
[58,17,14,11],
[58,17,14,12],
[58,17,15,0],
[58,17,15,1],
[58,17,15,2],
[58,17,15,3],
[58,17,15,4],
[58,17,15,5],
[58,17,15,6],
[58,17,15,7],
[58,17,15,8],
[58,17,15,10],
[58,17,15,11],
[58,17,15,12],
[58,17,15,13],
[58,17,15,14],
[58,18,3,0],
[58,18,3,1],
[58,18,3,2],
[58,18,4,0],
[58,18,4,1],
[58,18,4,2],
[58,18,4,3],
[58,18,5,0],
[58,18,5,1],
[58,18,5,2],
[58,18,5,4],
[58,18,6,0],
[58,18,6,1],
[58,18,6,2],
[58,18,6,4],
[58,18,7,0],
[58,18,7,1],
[58,18,7,2],
[58,18,7,3],
[58,18,7,4],
[58,18,7,5],
[58,18,7,6],
[58,18,8,0],
[58,18,8,1],
[58,18,8,2],
[58,18,8,4],
[58,18,8,7],
[58,18,10,0],
[58,18,10,1],
[58,18,10,2],
[58,18,10,3],
[58,18,10,4],
[58,18,10,5],
[58,18,10,6],
[58,18,10,7],
[58,18,10,8],
[58,18,11,0],
[58,18,11,1],
[58,18,11,2],
[58,18,11,3],
[58,18,11,4],
[58,18,11,5],
[58,18,11,6],
[58,18,11,7],
[58,18,11,8],
[58,18,11,10],
[58,18,12,0],
[58,18,12,1],
[58,18,12,2],
[58,18,12,3],
[58,18,12,4],
[58,18,12,5],
[58,18,12,6],
[58,18,12,7],
[58,18,12,8],
[58,18,12,10],
[58,18,13,0],
[58,18,13,1],
[58,18,13,2],
[58,18,13,3],
[58,18,13,4],
[58,18,13,5],
[58,18,13,6],
[58,18,13,7],
[58,18,13,8],
[58,18,13,10],
[58,18,13,11],
[58,18,13,12],
[58,18,14,0],
[58,18,14,1],
[58,18,14,2],
[58,18,14,3],
[58,18,14,4],
[58,18,14,5],
[58,18,14,6],
[58,18,14,7],
[58,18,14,8],
[58,18,14,10],
[58,18,14,11],
[58,18,14,12],
[58,18,17,0],
[58,18,17,1],
[58,18,17,2],
[58,18,17,3],
[58,18,17,4],
[58,18,17,5],
[58,18,17,6],
[58,18,17,7],
[58,18,17,8],
[58,18,17,10],
[58,18,17,11],
[58,18,17,12],
[58,18,17,13],
[58,18,17,14],
[58,19,3,0],
[58,19,3,1],
[58,19,3,2],
[58,19,4,0],
[58,19,4,1],
[58,19,4,2],
[58,19,4,3],
[58,19,5,0],
[58,19,5,1],
[58,19,5,2],
[58,19,5,4],
[58,19,6,0],
[58,19,6,1],
[58,19,6,2],
[58,19,6,4],
[58,19,7,0],
[58,19,7,1],
[58,19,7,2],
[58,19,7,3],
[58,19,7,4],
[58,19,7,5],
[58,19,7,6],
[58,19,8,0],
[58,19,8,1],
[58,19,8,2],
[58,19,8,4],
[58,19,8,7],
[58,19,9,0],
[58,19,9,1],
[58,19,9,2],
[58,19,9,3],
[58,19,9,4],
[58,19,9,5],
[58,19,9,6],
[58,19,9,7],
[58,19,9,8],
[58,19,10,0],
[58,19,10,1],
[58,19,10,2],
[58,19,10,3],
[58,19,10,4],
[58,19,10,5],
[58,19,10,6],
[58,19,10,7],
[58,19,10,8],
[58,19,10,9],
[58,19,11,0],
[58,19,11,1],
[58,19,11,2],
[58,19,11,3],
[58,19,11,4],
[58,19,11,5],
[58,19,11,6],
[58,19,11,7],
[58,19,11,8],
[58,19,11,9],
[58,19,11,10],
[58,19,12,0],
[58,19,12,1],
[58,19,12,2],
[58,19,12,3],
[58,19,12,4],
[58,19,12,5],
[58,19,12,6],
[58,19,12,7],
[58,19,12,8],
[58,19,12,9],
[58,19,12,10],
[58,19,15,0],
[58,19,15,1],
[58,19,15,2],
[58,19,15,3],
[58,19,15,4],
[58,19,15,5],
[58,19,15,6],
[58,19,15,7],
[58,19,15,8],
[58,19,15,10],
[58,19,15,11],
[58,19,15,12],
[58,19,17,0],
[58,19,17,1],
[58,19,17,2],
[58,19,17,3],
[58,19,17,4],
[58,19,17,5],
[58,19,17,6],
[58,19,17,7],
[58,19,17,8],
[58,19,17,9],
[58,19,17,10],
[58,19,17,11],
[58,19,17,12],
[58,19,17,15],
[58,19,18,0],
[58,19,18,1],
[58,19,18,2],
[58,19,18,3],
[58,19,18,4],
[58,19,18,5],
[58,19,18,6],
[58,19,18,7],
[58,19,18,8],
[58,19,18,10],
[58,19,18,11],
[58,19,18,12],
[58,19,18,17],
[58,20,3,0],
[58,20,3,1],
[58,20,3,2],
[58,20,4,0],
[58,20,4,1],
[58,20,4,2],
[58,20,4,3],
[58,20,5,0],
[58,20,5,1],
[58,20,5,2],
[58,20,5,4],
[58,20,6,0],
[58,20,6,1],
[58,20,6,2],
[58,20,6,4],
[58,20,7,0],
[58,20,7,1],
[58,20,7,2],
[58,20,7,3],
[58,20,7,4],
[58,20,7,5],
[58,20,7,6],
[58,20,8,0],
[58,20,8,1],
[58,20,8,2],
[58,20,8,4],
[58,20,8,7],
[58,20,9,0],
[58,20,9,1],
[58,20,9,2],
[58,20,9,3],
[58,20,9,4],
[58,20,9,5],
[58,20,9,6],
[58,20,9,7],
[58,20,9,8],
[58,20,11,0],
[58,20,11,1],
[58,20,11,2],
[58,20,11,3],
[58,20,11,4],
[58,20,11,5],
[58,20,11,6],
[58,20,11,7],
[58,20,11,8],
[58,20,11,9],
[58,20,12,0],
[58,20,12,1],
[58,20,12,2],
[58,20,12,3],
[58,20,12,4],
[58,20,12,5],
[58,20,12,6],
[58,20,12,7],
[58,20,12,8],
[58,20,12,9],
[58,20,13,0],
[58,20,13,1],
[58,20,13,2],
[58,20,13,3],
[58,20,13,4],
[58,20,13,5],
[58,20,13,6],
[58,20,13,7],
[58,20,13,8],
[58,20,13,9],
[58,20,13,11],
[58,20,13,12],
[58,20,14,0],
[58,20,14,1],
[58,20,14,2],
[58,20,14,3],
[58,20,14,4],
[58,20,14,5],
[58,20,14,6],
[58,20,14,7],
[58,20,14,8],
[58,20,14,9],
[58,20,14,11],
[58,20,14,12],
[58,20,15,0],
[58,20,15,1],
[58,20,15,2],
[58,20,15,3],
[58,20,15,4],
[58,20,15,5],
[58,20,15,6],
[58,20,15,7],
[58,20,15,8],
[58,20,15,11],
[58,20,15,12],
[58,20,15,13],
[58,20,15,14],
[58,20,17,0],
[58,20,17,1],
[58,20,17,2],
[58,20,17,3],
[58,20,17,4],
[58,20,17,5],
[58,20,17,6],
[58,20,17,7],
[58,20,17,8],
[58,20,17,9],
[58,20,17,11],
[58,20,17,12],
[58,20,17,13],
[58,20,17,14],
[58,20,17,15],
[58,20,18,0],
[58,20,18,1],
[58,20,18,2],
[58,20,18,3],
[58,20,18,4],
[58,20,18,5],
[58,20,18,6],
[58,20,18,7],
[58,20,18,8],
[58,20,18,11],
[58,20,18,12],
[58,20,18,13],
[58,20,18,14],
[58,20,18,17],
[58,20,19,0],
[58,20,19,1],
[58,20,19,2],
[58,20,19,3],
[58,20,19,4],
[58,20,19,5],
[58,20,19,6],
[58,20,19,7],
[58,20,19,8],
[58,20,19,9],
[58,20,19,11],
[58,20,19,12],
[58,20,19,15],
[58,20,19,17],
[58,20,19,18],
[58,21,4,0],
[58,21,4,1],
[58,21,4,2],
[58,21,7,0],
[58,21,7,1],
[58,21,7,2],
[58,21,7,4],
[58,21,9,0],
[58,21,9,1],
[58,21,9,2],
[58,21,9,4],
[58,21,9,7],
[58,21,10,0],
[58,21,10,1],
[58,21,10,2],
[58,21,10,4],
[58,21,10,7],
[58,21,10,9],
[58,21,11,0],
[58,21,11,1],
[58,21,11,2],
[58,21,11,4],
[58,21,11,7],
[58,21,11,9],
[58,21,11,10],
[58,21,12,0],
[58,21,12,1],
[58,21,12,2],
[58,21,12,4],
[58,21,12,7],
[58,21,12,9],
[58,21,12,10],
[58,21,13,0],
[58,21,13,1],
[58,21,13,2],
[58,21,13,4],
[58,21,13,7],
[58,21,13,9],
[58,21,13,10],
[58,21,13,11],
[58,21,13,12],
[58,21,14,0],
[58,21,14,1],
[58,21,14,2],
[58,21,14,4],
[58,21,14,7],
[58,21,14,9],
[58,21,14,10],
[58,21,14,11],
[58,21,14,12],
[58,21,15,0],
[58,21,15,1],
[58,21,15,2],
[58,21,15,4],
[58,21,15,7],
[58,21,15,10],
[58,21,15,11],
[58,21,15,12],
[58,21,15,13],
[58,21,15,14],
[58,21,17,0],
[58,21,17,1],
[58,21,17,2],
[58,21,17,4],
[58,21,17,7],
[58,21,17,9],
[58,21,17,10],
[58,21,17,11],
[58,21,17,12],
[58,21,17,13],
[58,21,17,14],
[58,21,17,15],
[58,21,18,0],
[58,21,18,1],
[58,21,18,2],
[58,21,18,4],
[58,21,18,7],
[58,21,18,10],
[58,21,18,11],
[58,21,18,12],
[58,21,18,13],
[58,21,18,14],
[58,21,18,17],
[58,21,19,0],
[58,21,19,1],
[58,21,19,2],
[58,21,19,4],
[58,21,19,7],
[58,21,19,9],
[58,21,19,10],
[58,21,19,11],
[58,21,19,12],
[58,21,19,15],
[58,21,19,17],
[58,21,19,18],
[58,21,20,0],
[58,21,20,1],
[58,21,20,2],
[58,21,20,4],
[58,21,20,7],
[58,21,20,9],
[58,21,20,11],
[58,21,20,12],
[58,21,20,13],
[58,21,20,14],
[58,21,20,15],
[58,21,20,17],
[58,21,20,18],
[58,21,20,19],
[58,22,3,0],
[58,22,3,1],
[58,22,3,2],
[58,22,4,0],
[58,22,4,1],
[58,22,4,2],
[58,22,4,3],
[58,22,5,0],
[58,22,5,1],
[58,22,5,2],
[58,22,5,4],
[58,22,6,0],
[58,22,6,1],
[58,22,6,2],
[58,22,6,4],
[58,22,7,0],
[58,22,7,1],
[58,22,7,2],
[58,22,7,3],
[58,22,7,4],
[58,22,7,5],
[58,22,7,6],
[58,22,8,0],
[58,22,8,1],
[58,22,8,2],
[58,22,8,4],
[58,22,8,7],
[58,22,9,0],
[58,22,9,1],
[58,22,9,2],
[58,22,9,3],
[58,22,9,4],
[58,22,9,5],
[58,22,9,6],
[58,22,9,7],
[58,22,9,8],
[58,22,11,0],
[58,22,11,1],
[58,22,11,2],
[58,22,11,3],
[58,22,11,4],
[58,22,11,5],
[58,22,11,6],
[58,22,11,7],
[58,22,11,8],
[58,22,11,9],
[58,22,12,0],
[58,22,12,1],
[58,22,12,2],
[58,22,12,3],
[58,22,12,4],
[58,22,12,5],
[58,22,12,6],
[58,22,12,7],
[58,22,12,8],
[58,22,12,9],
[58,22,13,0],
[58,22,13,1],
[58,22,13,2],
[58,22,13,3],
[58,22,13,4],
[58,22,13,5],
[58,22,13,6],
[58,22,13,7],
[58,22,13,8],
[58,22,13,9],
[58,22,13,11],
[58,22,13,12],
[58,22,14,0],
[58,22,14,1],
[58,22,14,2],
[58,22,14,3],
[58,22,14,4],
[58,22,14,5],
[58,22,14,6],
[58,22,14,7],
[58,22,14,8],
[58,22,14,9],
[58,22,14,11],
[58,22,14,12],
[58,22,15,0],
[58,22,15,1],
[58,22,15,2],
[58,22,15,3],
[58,22,15,4],
[58,22,15,5],
[58,22,15,6],
[58,22,15,7],
[58,22,15,8],
[58,22,15,11],
[58,22,15,12],
[58,22,15,13],
[58,22,15,14],
[58,22,17,0],
[58,22,17,1],
[58,22,17,2],
[58,22,17,3],
[58,22,17,4],
[58,22,17,5],
[58,22,17,6],
[58,22,17,7],
[58,22,17,8],
[58,22,17,9],
[58,22,17,11],
[58,22,17,12],
[58,22,17,13],
[58,22,17,14],
[58,22,17,15],
[58,22,18,0],
[58,22,18,1],
[58,22,18,2],
[58,22,18,3],
[58,22,18,4],
[58,22,18,5],
[58,22,18,6],
[58,22,18,7],
[58,22,18,8],
[58,22,18,11],
[58,22,18,12],
[58,22,18,13],
[58,22,18,14],
[58,22,18,17],
[58,22,19,0],
[58,22,19,1],
[58,22,19,2],
[58,22,19,3],
[58,22,19,4],
[58,22,19,5],
[58,22,19,6],
[58,22,19,7],
[58,22,19,8],
[58,22,19,9],
[58,22,19,11],
[58,22,19,12],
[58,22,19,15],
[58,22,19,17],
[58,22,19,18],
[58,22,21,0],
[58,22,21,1],
[58,22,21,2],
[58,22,21,4],
[58,22,21,7],
[58,22,21,9],
[58,22,21,11],
[58,22,21,12],
[58,22,21,13],
[58,22,21,14],
[58,22,21,15],
[58,22,21,17],
[58,22,21,18],
[58,22,21,19],
[58,23,3,0],
[58,23,3,1],
[58,23,3,2],
[58,23,4,0],
[58,23,4,1],
[58,23,4,2],
[58,23,4,3],
[58,23,5,0],
[58,23,5,1],
[58,23,5,2],
[58,23,5,4],
[58,23,6,0],
[58,23,6,1],
[58,23,6,2],
[58,23,6,4],
[58,23,7,0],
[58,23,7,1],
[58,23,7,2],
[58,23,7,3],
[58,23,7,4],
[58,23,7,5],
[58,23,7,6],
[58,23,8,0],
[58,23,8,1],
[58,23,8,2],
[58,23,8,4],
[58,23,8,7],
[58,23,9,0],
[58,23,9,1],
[58,23,9,2],
[58,23,9,3],
[58,23,9,4],
[58,23,9,5],
[58,23,9,6],
[58,23,9,7],
[58,23,9,8],
[58,23,10,0],
[58,23,10,1],
[58,23,10,2],
[58,23,10,3],
[58,23,10,4],
[58,23,10,5],
[58,23,10,6],
[58,23,10,7],
[58,23,10,8],
[58,23,10,9],
[58,23,11,0],
[58,23,11,1],
[58,23,11,2],
[58,23,11,3],
[58,23,11,4],
[58,23,11,5],
[58,23,11,6],
[58,23,11,7],
[58,23,11,8],
[58,23,11,9],
[58,23,11,10],
[58,23,12,0],
[58,23,12,1],
[58,23,12,2],
[58,23,12,3],
[58,23,12,4],
[58,23,12,5],
[58,23,12,6],
[58,23,12,7],
[58,23,12,8],
[58,23,12,9],
[58,23,12,10],
[58,23,13,0],
[58,23,13,1],
[58,23,13,2],
[58,23,13,3],
[58,23,13,4],
[58,23,13,5],
[58,23,13,6],
[58,23,13,7],
[58,23,13,8],
[58,23,13,9],
[58,23,13,10],
[58,23,13,11],
[58,23,13,12],
[58,23,14,0],
[58,23,14,1],
[58,23,14,2],
[58,23,14,3],
[58,23,14,4],
[58,23,14,5],
[58,23,14,6],
[58,23,14,7],
[58,23,14,8],
[58,23,14,9],
[58,23,14,10],
[58,23,14,11],
[58,23,14,12],
[58,23,15,0],
[58,23,15,1],
[58,23,15,2],
[58,23,15,3],
[58,23,15,4],
[58,23,15,5],
[58,23,15,6],
[58,23,15,7],
[58,23,15,8],
[58,23,15,10],
[58,23,15,11],
[58,23,15,12],
[58,23,15,13],
[58,23,15,14],
[58,23,17,0],
[58,23,17,1],
[58,23,17,2],
[58,23,17,3],
[58,23,17,4],
[58,23,17,5],
[58,23,17,6],
[58,23,17,7],
[58,23,17,8],
[58,23,17,9],
[58,23,17,10],
[58,23,17,11],
[58,23,17,12],
[58,23,17,13],
[58,23,17,14],
[58,23,17,15],
[58,23,18,0],
[58,23,18,1],
[58,23,18,2],
[58,23,18,3],
[58,23,18,4],
[58,23,18,5],
[58,23,18,6],
[58,23,18,7],
[58,23,18,8],
[58,23,18,10],
[58,23,18,11],
[58,23,18,12],
[58,23,18,13],
[58,23,18,14],
[58,23,18,17],
[58,23,19,0],
[58,23,19,1],
[58,23,19,2],
[58,23,19,3],
[58,23,19,4],
[58,23,19,5],
[58,23,19,6],
[58,23,19,7],
[58,23,19,8],
[58,23,19,9],
[58,23,19,10],
[58,23,19,11],
[58,23,19,12],
[58,23,19,15],
[58,23,19,17],
[58,23,19,18],
[58,23,20,0],
[58,23,20,1],
[58,23,20,2],
[58,23,20,3],
[58,23,20,4],
[58,23,20,5],
[58,23,20,6],
[58,23,20,7],
[58,23,20,8],
[58,23,20,9],
[58,23,20,11],
[58,23,20,12],
[58,23,20,13],
[58,23,20,14],
[58,23,20,15],
[58,23,20,17],
[58,23,20,18],
[58,23,20,19],
[58,23,21,0],
[58,23,21,1],
[58,23,21,2],
[58,23,21,4],
[58,23,21,7],
[58,23,21,9],
[58,23,21,10],
[58,23,21,11],
[58,23,21,12],
[58,23,21,13],
[58,23,21,14],
[58,23,21,15],
[58,23,21,17],
[58,23,21,18],
[58,23,21,19],
[58,23,21,20],
[58,23,22,0],
[58,23,22,1],
[58,23,22,2],
[58,23,22,3],
[58,23,22,4],
[58,23,22,5],
[58,23,22,6],
[58,23,22,7],
[58,23,22,8],
[58,23,22,9],
[58,23,22,11],
[58,23,22,12],
[58,23,22,13],
[58,23,22,14],
[58,23,22,15],
[58,23,22,17],
[58,23,22,18],
[58,23,22,19],
[58,23,22,21],
[58,25,3,0],
[58,25,3,1],
[58,25,3,2],
[58,25,4,0],
[58,25,4,1],
[58,25,4,2],
[58,25,4,3],
[58,25,5,0],
[58,25,5,1],
[58,25,5,2],
[58,25,5,4],
[58,25,6,0],
[58,25,6,1],
[58,25,6,2],
[58,25,6,4],
[58,25,8,0],
[58,25,8,1],
[58,25,8,2],
[58,25,8,4],
[58,25,9,0],
[58,25,9,1],
[58,25,9,2],
[58,25,9,3],
[58,25,9,4],
[58,25,9,5],
[58,25,9,6],
[58,25,9,8],
[58,25,10,0],
[58,25,10,1],
[58,25,10,2],
[58,25,10,3],
[58,25,10,4],
[58,25,10,5],
[58,25,10,6],
[58,25,10,8],
[58,25,10,9],
[58,25,11,0],
[58,25,11,1],
[58,25,11,2],
[58,25,11,3],
[58,25,11,4],
[58,25,11,5],
[58,25,11,6],
[58,25,11,8],
[58,25,11,9],
[58,25,11,10],
[58,25,12,0],
[58,25,12,1],
[58,25,12,2],
[58,25,12,3],
[58,25,12,4],
[58,25,12,5],
[58,25,12,6],
[58,25,12,8],
[58,25,12,9],
[58,25,12,10],
[58,25,13,0],
[58,25,13,1],
[58,25,13,2],
[58,25,13,3],
[58,25,13,4],
[58,25,13,5],
[58,25,13,6],
[58,25,13,8],
[58,25,13,9],
[58,25,13,10],
[58,25,13,11],
[58,25,13,12],
[58,25,14,0],
[58,25,14,1],
[58,25,14,2],
[58,25,14,3],
[58,25,14,4],
[58,25,14,5],
[58,25,14,6],
[58,25,14,8],
[58,25,14,9],
[58,25,14,10],
[58,25,14,11],
[58,25,14,12],
[58,25,15,0],
[58,25,15,1],
[58,25,15,2],
[58,25,15,3],
[58,25,15,4],
[58,25,15,5],
[58,25,15,6],
[58,25,15,8],
[58,25,15,10],
[58,25,15,11],
[58,25,15,12],
[58,25,15,13],
[58,25,15,14],
[58,25,17,0],
[58,25,17,1],
[58,25,17,2],
[58,25,17,3],
[58,25,17,4],
[58,25,17,5],
[58,25,17,6],
[58,25,17,8],
[58,25,17,9],
[58,25,17,10],
[58,25,17,11],
[58,25,17,12],
[58,25,17,13],
[58,25,17,14],
[58,25,17,15],
[58,25,18,0],
[58,25,18,1],
[58,25,18,2],
[58,25,18,3],
[58,25,18,4],
[58,25,18,5],
[58,25,18,6],
[58,25,18,8],
[58,25,18,10],
[58,25,18,11],
[58,25,18,12],
[58,25,18,13],
[58,25,18,14],
[58,25,18,17],
[58,25,19,0],
[58,25,19,1],
[58,25,19,2],
[58,25,19,3],
[58,25,19,4],
[58,25,19,5],
[58,25,19,6],
[58,25,19,8],
[58,25,19,9],
[58,25,19,10],
[58,25,19,11],
[58,25,19,12],
[58,25,19,15],
[58,25,19,17],
[58,25,19,18],
[58,25,20,0],
[58,25,20,1],
[58,25,20,2],
[58,25,20,3],
[58,25,20,4],
[58,25,20,5],
[58,25,20,6],
[58,25,20,8],
[58,25,20,9],
[58,25,20,11],
[58,25,20,12],
[58,25,20,13],
[58,25,20,14],
[58,25,20,15],
[58,25,20,17],
[58,25,20,18],
[58,25,20,19],
[58,25,21,0],
[58,25,21,1],
[58,25,21,2],
[58,25,21,4],
[58,25,21,9],
[58,25,21,10],
[58,25,21,11],
[58,25,21,12],
[58,25,21,13],
[58,25,21,14],
[58,25,21,15],
[58,25,21,17],
[58,25,21,18],
[58,25,21,19],
[58,25,21,20],
[58,25,22,0],
[58,25,22,1],
[58,25,22,2],
[58,25,22,3],
[58,25,22,4],
[58,25,22,5],
[58,25,22,6],
[58,25,22,8],
[58,25,22,9],
[58,25,22,11],
[58,25,22,12],
[58,25,22,13],
[58,25,22,14],
[58,25,22,15],
[58,25,22,17],
[58,25,22,18],
[58,25,22,19],
[58,25,22,21],
[58,25,23,0],
[58,25,23,1],
[58,25,23,2],
[58,25,23,3],
[58,25,23,4],
[58,25,23,5],
[58,25,23,6],
[58,25,23,8],
[58,25,23,9],
[58,25,23,10],
[58,25,23,11],
[58,25,23,12],
[58,25,23,13],
[58,25,23,14],
[58,25,23,15],
[58,25,23,17],
[58,25,23,18],
[58,25,23,19],
[58,25,23,20],
[58,25,23,21],
[58,25,23,22],
[58,26,3,0],
[58,26,3,1],
[58,26,3,2],
[58,26,4,0],
[58,26,4,1],
[58,26,4,2],
[58,26,4,3],
[58,26,5,0],
[58,26,5,1],
[58,26,5,2],
[58,26,5,4],
[58,26,6,0],
[58,26,6,1],
[58,26,6,2],
[58,26,6,4],
[58,26,7,0],
[58,26,7,1],
[58,26,7,2],
[58,26,7,3],
[58,26,7,4],
[58,26,7,5],
[58,26,7,6],
[58,26,8,0],
[58,26,8,1],
[58,26,8,2],
[58,26,8,4],
[58,26,8,7],
[58,26,10,0],
[58,26,10,1],
[58,26,10,2],
[58,26,10,3],
[58,26,10,4],
[58,26,10,5],
[58,26,10,6],
[58,26,10,7],
[58,26,10,8],
[58,26,11,0],
[58,26,11,1],
[58,26,11,2],
[58,26,11,3],
[58,26,11,4],
[58,26,11,5],
[58,26,11,6],
[58,26,11,7],
[58,26,11,8],
[58,26,11,10],
[58,26,12,0],
[58,26,12,1],
[58,26,12,2],
[58,26,12,3],
[58,26,12,4],
[58,26,12,5],
[58,26,12,6],
[58,26,12,7],
[58,26,12,8],
[58,26,12,10],
[58,26,13,0],
[58,26,13,1],
[58,26,13,2],
[58,26,13,3],
[58,26,13,4],
[58,26,13,5],
[58,26,13,6],
[58,26,13,7],
[58,26,13,8],
[58,26,13,10],
[58,26,13,11],
[58,26,13,12],
[58,26,14,0],
[58,26,14,1],
[58,26,14,2],
[58,26,14,3],
[58,26,14,4],
[58,26,14,5],
[58,26,14,6],
[58,26,14,7],
[58,26,14,8],
[58,26,14,10],
[58,26,14,11],
[58,26,14,12],
[58,26,17,0],
[58,26,17,1],
[58,26,17,2],
[58,26,17,3],
[58,26,17,4],
[58,26,17,5],
[58,26,17,6],
[58,26,17,7],
[58,26,17,8],
[58,26,17,10],
[58,26,17,11],
[58,26,17,12],
[58,26,17,13],
[58,26,17,14],
[58,26,19,0],
[58,26,19,1],
[58,26,19,2],
[58,26,19,3],
[58,26,19,4],
[58,26,19,5],
[58,26,19,6],
[58,26,19,7],
[58,26,19,8],
[58,26,19,10],
[58,26,19,11],
[58,26,19,12],
[58,26,19,17],
[58,26,20,0],
[58,26,20,1],
[58,26,20,2],
[58,26,20,3],
[58,26,20,4],
[58,26,20,5],
[58,26,20,6],
[58,26,20,7],
[58,26,20,8],
[58,26,20,11],
[58,26,20,12],
[58,26,20,13],
[58,26,20,14],
[58,26,20,17],
[58,26,20,19],
[58,26,21,0],
[58,26,21,1],
[58,26,21,2],
[58,26,21,4],
[58,26,21,7],
[58,26,21,10],
[58,26,21,11],
[58,26,21,12],
[58,26,21,13],
[58,26,21,14],
[58,26,21,17],
[58,26,21,19],
[58,26,21,20],
[58,26,22,0],
[58,26,22,1],
[58,26,22,2],
[58,26,22,3],
[58,26,22,4],
[58,26,22,5],
[58,26,22,6],
[58,26,22,7],
[58,26,22,8],
[58,26,22,11],
[58,26,22,12],
[58,26,22,13],
[58,26,22,14],
[58,26,22,17],
[58,26,22,19],
[58,26,22,21],
[58,26,23,0],
[58,26,23,1],
[58,26,23,2],
[58,26,23,3],
[58,26,23,4],
[58,26,23,5],
[58,26,23,6],
[58,26,23,7],
[58,26,23,8],
[58,26,23,10],
[58,26,23,11],
[58,26,23,12],
[58,26,23,13],
[58,26,23,14],
[58,26,23,17],
[58,26,23,19],
[58,26,23,20],
[58,26,23,21],
[58,26,23,22],
[58,26,25,0],
[58,26,25,1],
[58,26,25,2],
[58,26,25,3],
[58,26,25,4],
[58,26,25,5],
[58,26,25,6],
[58,26,25,8],
[58,26,25,10],
[58,26,25,11],
[58,26,25,12],
[58,26,25,13],
[58,26,25,14],
[58,26,25,17],
[58,26,25,19],
[58,26,25,20],
[58,26,25,21],
[58,26,25,22],
[58,26,25,23],
[58,27,3,0],
[58,27,3,1],
[58,27,3,2],
[58,27,4,0],
[58,27,4,1],
[58,27,4,2],
[58,27,4,3],
[58,27,5,0],
[58,27,5,1],
[58,27,5,2],
[58,27,5,4],
[58,27,6,0],
[58,27,6,1],
[58,27,6,2],
[58,27,6,4],
[58,27,7,0],
[58,27,7,1],
[58,27,7,2],
[58,27,7,3],
[58,27,7,4],
[58,27,7,5],
[58,27,7,6],
[58,27,8,0],
[58,27,8,1],
[58,27,8,2],
[58,27,8,4],
[58,27,8,7],
[58,27,9,0],
[58,27,9,1],
[58,27,9,2],
[58,27,9,3],
[58,27,9,4],
[58,27,9,5],
[58,27,9,6],
[58,27,9,7],
[58,27,9,8],
[58,27,11,0],
[58,27,11,1],
[58,27,11,2],
[58,27,11,3],
[58,27,11,4],
[58,27,11,5],
[58,27,11,6],
[58,27,11,7],
[58,27,11,8],
[58,27,11,9],
[58,27,12,0],
[58,27,12,1],
[58,27,12,2],
[58,27,12,3],
[58,27,12,4],
[58,27,12,5],
[58,27,12,6],
[58,27,12,7],
[58,27,12,8],
[58,27,12,9],
[58,27,13,0],
[58,27,13,1],
[58,27,13,2],
[58,27,13,3],
[58,27,13,4],
[58,27,13,5],
[58,27,13,6],
[58,27,13,7],
[58,27,13,8],
[58,27,13,9],
[58,27,13,11],
[58,27,13,12],
[58,27,14,0],
[58,27,14,1],
[58,27,14,2],
[58,27,14,3],
[58,27,14,4],
[58,27,14,5],
[58,27,14,6],
[58,27,14,7],
[58,27,14,8],
[58,27,14,9],
[58,27,14,11],
[58,27,14,12],
[58,27,15,0],
[58,27,15,1],
[58,27,15,2],
[58,27,15,3],
[58,27,15,4],
[58,27,15,5],
[58,27,15,6],
[58,27,15,7],
[58,27,15,8],
[58,27,15,11],
[58,27,15,12],
[58,27,15,13],
[58,27,15,14],
[58,27,17,0],
[58,27,17,1],
[58,27,17,2],
[58,27,17,3],
[58,27,17,4],
[58,27,17,5],
[58,27,17,6],
[58,27,17,7],
[58,27,17,8],
[58,27,17,9],
[58,27,17,11],
[58,27,17,12],
[58,27,17,13],
[58,27,17,14],
[58,27,17,15],
[58,27,18,0],
[58,27,18,1],
[58,27,18,2],
[58,27,18,3],
[58,27,18,4],
[58,27,18,5],
[58,27,18,6],
[58,27,18,7],
[58,27,18,8],
[58,27,18,11],
[58,27,18,12],
[58,27,18,13],
[58,27,18,14],
[58,27,18,17],
[58,27,19,0],
[58,27,19,1],
[58,27,19,2],
[58,27,19,3],
[58,27,19,4],
[58,27,19,5],
[58,27,19,6],
[58,27,19,7],
[58,27,19,8],
[58,27,19,9],
[58,27,19,11],
[58,27,19,12],
[58,27,19,15],
[58,27,19,17],
[58,27,19,18],
[58,27,21,0],
[58,27,21,1],
[58,27,21,2],
[58,27,21,4],
[58,27,21,7],
[58,27,21,9],
[58,27,21,11],
[58,27,21,12],
[58,27,21,13],
[58,27,21,14],
[58,27,21,15],
[58,27,21,17],
[58,27,21,18],
[58,27,21,19],
[58,27,23,0],
[58,27,23,1],
[58,27,23,2],
[58,27,23,3],
[58,27,23,4],
[58,27,23,5],
[58,27,23,6],
[58,27,23,7],
[58,27,23,8],
[58,27,23,9],
[58,27,23,11],
[58,27,23,12],
[58,27,23,13],
[58,27,23,14],
[58,27,23,15],
[58,27,23,17],
[58,27,23,18],
[58,27,23,19],
[58,27,23,21],
[58,27,25,0],
[58,27,25,1],
[58,27,25,2],
[58,27,25,3],
[58,27,25,4],
[58,27,25,5],
[58,27,25,6],
[58,27,25,8],
[58,27,25,9],
[58,27,25,11],
[58,27,25,12],
[58,27,25,13],
[58,27,25,14],
[58,27,25,15],
[58,27,25,17],
[58,27,25,18],
[58,27,25,19],
[58,27,25,21],
[58,27,25,23],
[58,27,26,0],
[58,27,26,1],
[58,27,26,2],
[58,27,26,3],
[58,27,26,4],
[58,27,26,5],
[58,27,26,6],
[58,27,26,7],
[58,27,26,8],
[58,27,26,11],
[58,27,26,12],
[58,27,26,13],
[58,27,26,14],
[58,27,26,17],
[58,27,26,19],
[58,27,26,21],
[58,27,26,23],
[58,27,26,25],
[58,28,3,0],
[58,28,3,1],
[58,28,3,2],
[58,28,4,0],
[58,28,4,1],
[58,28,4,2],
[58,28,4,3],
[58,28,5,0],
[58,28,5,1],
[58,28,5,2],
[58,28,5,4],
[58,28,6,0],
[58,28,6,1],
[58,28,6,2],
[58,28,6,4],
[58,28,8,0],
[58,28,8,1],
[58,28,8,2],
[58,28,8,4],
[58,28,9,0],
[58,28,9,1],
[58,28,9,2],
[58,28,9,3],
[58,28,9,4],
[58,28,9,5],
[58,28,9,6],
[58,28,9,8],
[58,28,10,0],
[58,28,10,1],
[58,28,10,2],
[58,28,10,3],
[58,28,10,4],
[58,28,10,5],
[58,28,10,6],
[58,28,10,8],
[58,28,10,9],
[58,28,11,0],
[58,28,11,1],
[58,28,11,2],
[58,28,11,3],
[58,28,11,4],
[58,28,11,5],
[58,28,11,6],
[58,28,11,8],
[58,28,11,9],
[58,28,11,10],
[58,28,12,0],
[58,28,12,1],
[58,28,12,2],
[58,28,12,3],
[58,28,12,4],
[58,28,12,5],
[58,28,12,6],
[58,28,12,8],
[58,28,12,9],
[58,28,12,10],
[58,28,13,0],
[58,28,13,1],
[58,28,13,2],
[58,28,13,3],
[58,28,13,4],
[58,28,13,5],
[58,28,13,6],
[58,28,13,8],
[58,28,13,9],
[58,28,13,10],
[58,28,13,11],
[58,28,13,12],
[58,28,14,0],
[58,28,14,1],
[58,28,14,2],
[58,28,14,3],
[58,28,14,4],
[58,28,14,5],
[58,28,14,6],
[58,28,14,8],
[58,28,14,9],
[58,28,14,10],
[58,28,14,11],
[58,28,14,12],
[58,28,15,0],
[58,28,15,1],
[58,28,15,2],
[58,28,15,3],
[58,28,15,4],
[58,28,15,5],
[58,28,15,6],
[58,28,15,8],
[58,28,15,10],
[58,28,15,11],
[58,28,15,12],
[58,28,15,13],
[58,28,15,14],
[58,28,17,0],
[58,28,17,1],
[58,28,17,2],
[58,28,17,3],
[58,28,17,4],
[58,28,17,5],
[58,28,17,6],
[58,28,17,8],
[58,28,17,9],
[58,28,17,10],
[58,28,17,11],
[58,28,17,12],
[58,28,17,13],
[58,28,17,14],
[58,28,17,15],
[58,28,18,0],
[58,28,18,1],
[58,28,18,2],
[58,28,18,3],
[58,28,18,4],
[58,28,18,5],
[58,28,18,6],
[58,28,18,8],
[58,28,18,10],
[58,28,18,11],
[58,28,18,12],
[58,28,18,13],
[58,28,18,14],
[58,28,18,17],
[58,28,19,0],
[58,28,19,1],
[58,28,19,2],
[58,28,19,3],
[58,28,19,4],
[58,28,19,5],
[58,28,19,6],
[58,28,19,8],
[58,28,19,9],
[58,28,19,10],
[58,28,19,11],
[58,28,19,12],
[58,28,19,15],
[58,28,19,17],
[58,28,19,18],
[58,28,20,0],
[58,28,20,1],
[58,28,20,2],
[58,28,20,3],
[58,28,20,4],
[58,28,20,5],
[58,28,20,6],
[58,28,20,8],
[58,28,20,9],
[58,28,20,11],
[58,28,20,12],
[58,28,20,13],
[58,28,20,14],
[58,28,20,15],
[58,28,20,17],
[58,28,20,18],
[58,28,20,19],
[58,28,21,0],
[58,28,21,1],
[58,28,21,2],
[58,28,21,4],
[58,28,21,9],
[58,28,21,10],
[58,28,21,11],
[58,28,21,12],
[58,28,21,13],
[58,28,21,14],
[58,28,21,15],
[58,28,21,17],
[58,28,21,18],
[58,28,21,19],
[58,28,21,20],
[58,28,22,0],
[58,28,22,1],
[58,28,22,2],
[58,28,22,3],
[58,28,22,4],
[58,28,22,5],
[58,28,22,6],
[58,28,22,8],
[58,28,22,9],
[58,28,22,11],
[58,28,22,12],
[58,28,22,13],
[58,28,22,14],
[58,28,22,15],
[58,28,22,17],
[58,28,22,18],
[58,28,22,19],
[58,28,22,21],
[58,28,23,0],
[58,28,23,1],
[58,28,23,2],
[58,28,23,3],
[58,28,23,4],
[58,28,23,5],
[58,28,23,6],
[58,28,23,8],
[58,28,23,9],
[58,28,23,10],
[58,28,23,11],
[58,28,23,12],
[58,28,23,13],
[58,28,23,14],
[58,28,23,15],
[58,28,23,17],
[58,28,23,18],
[58,28,23,19],
[58,28,23,20],
[58,28,23,21],
[58,28,23,22],
[58,28,26,0],
[58,28,26,1],
[58,28,26,2],
[58,28,26,3],
[58,28,26,4],
[58,28,26,5],
[58,28,26,6],
[58,28,26,8],
[58,28,26,10],
[58,28,26,11],
[58,28,26,12],
[58,28,26,13],
[58,28,26,14],
[58,28,26,17],
[58,28,26,19],
[58,28,26,20],
[58,28,26,21],
[58,28,26,22],
[58,28,26,23],
[58,28,27,0],
[58,28,27,1],
[58,28,27,2],
[58,28,27,3],
[58,28,27,4],
[58,28,27,5],
[58,28,27,6],
[58,28,27,8],
[58,28,27,9],
[58,28,27,11],
[58,28,27,12],
[58,28,27,13],
[58,28,27,14],
[58,28,27,15],
[58,28,27,17],
[58,28,27,18],
[58,28,27,19],
[58,28,27,21],
[58,28,27,23],
[58,28,27,26],
[58,29,3,0],
[58,29,3,1],
[58,29,3,2],
[58,29,4,0],
[58,29,4,1],
[58,29,4,2],
[58,29,4,3],
[58,29,5,0],
[58,29,5,1],
[58,29,5,2],
[58,29,5,4],
[58,29,6,0],
[58,29,6,1],
[58,29,6,2],
[58,29,6,4],
[58,29,7,0],
[58,29,7,1],
[58,29,7,2],
[58,29,7,3],
[58,29,7,4],
[58,29,7,5],
[58,29,7,6],
[58,29,8,0],
[58,29,8,1],
[58,29,8,2],
[58,29,8,4],
[58,29,8,7],
[58,29,9,0],
[58,29,9,1],
[58,29,9,2],
[58,29,9,3],
[58,29,9,4],
[58,29,9,5],
[58,29,9,6],
[58,29,9,7],
[58,29,9,8],
[58,29,10,0],
[58,29,10,1],
[58,29,10,2],
[58,29,10,3],
[58,29,10,4],
[58,29,10,5],
[58,29,10,6],
[58,29,10,7],
[58,29,10,8],
[58,29,10,9],
[58,29,13,0],
[58,29,13,1],
[58,29,13,2],
[58,29,13,3],
[58,29,13,4],
[58,29,13,5],
[58,29,13,6],
[58,29,13,7],
[58,29,13,8],
[58,29,13,9],
[58,29,13,10],
[58,29,14,0],
[58,29,14,1],
[58,29,14,2],
[58,29,14,3],
[58,29,14,4],
[58,29,14,5],
[58,29,14,6],
[58,29,14,7],
[58,29,14,8],
[58,29,14,9],
[58,29,14,10],
[58,29,15,0],
[58,29,15,1],
[58,29,15,2],
[58,29,15,3],
[58,29,15,4],
[58,29,15,5],
[58,29,15,6],
[58,29,15,7],
[58,29,15,8],
[58,29,15,10],
[58,29,15,13],
[58,29,15,14],
[58,29,17,0],
[58,29,17,1],
[58,29,17,2],
[58,29,17,3],
[58,29,17,4],
[58,29,17,5],
[58,29,17,6],
[58,29,17,7],
[58,29,17,8],
[58,29,17,9],
[58,29,17,10],
[58,29,17,13],
[58,29,17,14],
[58,29,17,15],
[58,29,18,0],
[58,29,18,1],
[58,29,18,2],
[58,29,18,3],
[58,29,18,4],
[58,29,18,5],
[58,29,18,6],
[58,29,18,7],
[58,29,18,8],
[58,29,18,10],
[58,29,18,13],
[58,29,18,14],
[58,29,18,17],
[58,29,19,0],
[58,29,19,1],
[58,29,19,2],
[58,29,19,3],
[58,29,19,4],
[58,29,19,5],
[58,29,19,6],
[58,29,19,7],
[58,29,19,8],
[58,29,19,9],
[58,29,19,10],
[58,29,19,15],
[58,29,19,17],
[58,29,19,18],
[58,29,20,0],
[58,29,20,1],
[58,29,20,2],
[58,29,20,3],
[58,29,20,4],
[58,29,20,5],
[58,29,20,6],
[58,29,20,7],
[58,29,20,8],
[58,29,20,9],
[58,29,20,13],
[58,29,20,14],
[58,29,20,15],
[58,29,20,17],
[58,29,20,18],
[58,29,20,19],
[58,29,21,0],
[58,29,21,1],
[58,29,21,2],
[58,29,21,4],
[58,29,21,7],
[58,29,21,9],
[58,29,21,10],
[58,29,21,13],
[58,29,21,14],
[58,29,21,15],
[58,29,21,17],
[58,29,21,18],
[58,29,21,19],
[58,29,21,20],
[58,29,22,0],
[58,29,22,1],
[58,29,22,2],
[58,29,22,3],
[58,29,22,4],
[58,29,22,5],
[58,29,22,6],
[58,29,22,7],
[58,29,22,8],
[58,29,22,9],
[58,29,22,13],
[58,29,22,14],
[58,29,22,15],
[58,29,22,17],
[58,29,22,18],
[58,29,22,19],
[58,29,22,21],
[58,29,23,0],
[58,29,23,1],
[58,29,23,2],
[58,29,23,3],
[58,29,23,4],
[58,29,23,5],
[58,29,23,6],
[58,29,23,7],
[58,29,23,8],
[58,29,23,9],
[58,29,23,10],
[58,29,23,13],
[58,29,23,14],
[58,29,23,15],
[58,29,23,17],
[58,29,23,18],
[58,29,23,19],
[58,29,23,20],
[58,29,23,21],
[58,29,23,22],
[58,29,25,0],
[58,29,25,1],
[58,29,25,2],
[58,29,25,3],
[58,29,25,4],
[58,29,25,5],
[58,29,25,6],
[58,29,25,8],
[58,29,25,9],
[58,29,25,10],
[58,29,25,13],
[58,29,25,14],
[58,29,25,15],
[58,29,25,17],
[58,29,25,18],
[58,29,25,19],
[58,29,25,20],
[58,29,25,21],
[58,29,25,22],
[58,29,25,23],
[58,29,26,0],
[58,29,26,1],
[58,29,26,2],
[58,29,26,3],
[58,29,26,4],
[58,29,26,5],
[58,29,26,6],
[58,29,26,7],
[58,29,26,8],
[58,29,26,10],
[58,29,26,13],
[58,29,26,14],
[58,29,26,17],
[58,29,26,19],
[58,29,26,20],
[58,29,26,21],
[58,29,26,22],
[58,29,26,23],
[58,29,26,25],
[58,29,27,0],
[58,29,27,1],
[58,29,27,2],
[58,29,27,3],
[58,29,27,4],
[58,29,27,5],
[58,29,27,6],
[58,29,27,7],
[58,29,27,8],
[58,29,27,9],
[58,29,27,13],
[58,29,27,14],
[58,29,27,15],
[58,29,27,17],
[58,29,27,18],
[58,29,27,19],
[58,29,27,21],
[58,29,27,25],
[58,29,27,26],
[58,29,28,0],
[58,29,28,1],
[58,29,28,2],
[58,29,28,3],
[58,29,28,4],
[58,29,28,5],
[58,29,28,6],
[58,29,28,8],
[58,29,28,9],
[58,29,28,10],
[58,29,28,13],
[58,29,28,14],
[58,29,28,15],
[58,29,28,17],
[58,29,28,18],
[58,29,28,19],
[58,29,28,20],
[58,29,28,21],
[58,29,28,23],
[58,29,28,26],
[58,29,28,27],
[58,30,3,0],
[58,30,3,1],
[58,30,3,2],
[58,30,4,0],
[58,30,4,1],
[58,30,4,2],
[58,30,4,3],
[58,30,5,0],
[58,30,5,1],
[58,30,5,2],
[58,30,5,4],
[58,30,6,0],
[58,30,6,1],
[58,30,6,2],
[58,30,6,4],
[58,30,7,0],
[58,30,7,1],
[58,30,7,2],
[58,30,7,3],
[58,30,7,4],
[58,30,7,5],
[58,30,7,6],
[58,30,8,0],
[58,30,8,1],
[58,30,8,2],
[58,30,8,4],
[58,30,8,7],
[58,30,9,0],
[58,30,9,1],
[58,30,9,2],
[58,30,9,3],
[58,30,9,4],
[58,30,9,5],
[58,30,9,6],
[58,30,9,7],
[58,30,9,8],
[58,30,10,0],
[58,30,10,1],
[58,30,10,2],
[58,30,10,3],
[58,30,10,4],
[58,30,10,5],
[58,30,10,6],
[58,30,10,7],
[58,30,10,8],
[58,30,10,9],
[58,30,11,0],
[58,30,11,1],
[58,30,11,2],
[58,30,11,3],
[58,30,11,4],
[58,30,11,5],
[58,30,11,6],
[58,30,11,7],
[58,30,11,8],
[58,30,11,9],
[58,30,11,10],
[58,30,12,0],
[58,30,12,1],
[58,30,12,2],
[58,30,12,3],
[58,30,12,4],
[58,30,12,5],
[58,30,12,6],
[58,30,12,7],
[58,30,12,8],
[58,30,12,9],
[58,30,12,10],
[58,30,13,0],
[58,30,13,1],
[58,30,13,2],
[58,30,13,3],
[58,30,13,4],
[58,30,13,5],
[58,30,13,6],
[58,30,13,7],
[58,30,13,8],
[58,30,13,9],
[58,30,13,10],
[58,30,13,11],
[58,30,13,12],
[58,30,14,0],
[58,30,14,1],
[58,30,14,2],
[58,30,14,3],
[58,30,14,4],
[58,30,14,5],
[58,30,14,6],
[58,30,14,7],
[58,30,14,8],
[58,30,14,9],
[58,30,14,10],
[58,30,14,11],
[58,30,14,12],
[58,30,15,0],
[58,30,15,1],
[58,30,15,2],
[58,30,15,3],
[58,30,15,4],
[58,30,15,5],
[58,30,15,6],
[58,30,15,7],
[58,30,15,8],
[58,30,15,10],
[58,30,15,11],
[58,30,15,12],
[58,30,15,13],
[58,30,15,14],
[58,30,17,0],
[58,30,17,1],
[58,30,17,2],
[58,30,17,3],
[58,30,17,4],
[58,30,17,5],
[58,30,17,6],
[58,30,17,7],
[58,30,17,8],
[58,30,17,9],
[58,30,17,10],
[58,30,17,11],
[58,30,17,12],
[58,30,17,13],
[58,30,17,14],
[58,30,17,15],
[58,30,18,0],
[58,30,18,1],
[58,30,18,2],
[58,30,18,3],
[58,30,18,4],
[58,30,18,5],
[58,30,18,6],
[58,30,18,7],
[58,30,18,8],
[58,30,18,10],
[58,30,18,11],
[58,30,18,12],
[58,30,18,13],
[58,30,18,14],
[58,30,18,17],
[58,30,19,0],
[58,30,19,1],
[58,30,19,2],
[58,30,19,3],
[58,30,19,4],
[58,30,19,5],
[58,30,19,6],
[58,30,19,7],
[58,30,19,8],
[58,30,19,9],
[58,30,19,10],
[58,30,19,11],
[58,30,19,12],
[58,30,19,15],
[58,30,19,17],
[58,30,19,18],
[58,30,20,0],
[58,30,20,1],
[58,30,20,2],
[58,30,20,3],
[58,30,20,4],
[58,30,20,5],
[58,30,20,6],
[58,30,20,7],
[58,30,20,8],
[58,30,20,9],
[58,30,20,11],
[58,30,20,12],
[58,30,20,13],
[58,30,20,14],
[58,30,20,15],
[58,30,20,17],
[58,30,20,18],
[58,30,20,19],
[58,30,21,0],
[58,30,21,1],
[58,30,21,2],
[58,30,21,4],
[58,30,21,7],
[58,30,21,9],
[58,30,21,10],
[58,30,21,11],
[58,30,21,12],
[58,30,21,13],
[58,30,21,14],
[58,30,21,15],
[58,30,21,17],
[58,30,21,18],
[58,30,21,19],
[58,30,21,20],
[58,30,22,0],
[58,30,22,1],
[58,30,22,2],
[58,30,22,3],
[58,30,22,4],
[58,30,22,5],
[58,30,22,6],
[58,30,22,7],
[58,30,22,8],
[58,30,22,9],
[58,30,22,11],
[58,30,22,12],
[58,30,22,13],
[58,30,22,14],
[58,30,22,15],
[58,30,22,17],
[58,30,22,18],
[58,30,22,19],
[58,30,22,21],
[58,30,25,0],
[58,30,25,1],
[58,30,25,2],
[58,30,25,3],
[58,30,25,4],
[58,30,25,5],
[58,30,25,6],
[58,30,25,8],
[58,30,25,9],
[58,30,25,10],
[58,30,25,11],
[58,30,25,12],
[58,30,25,13],
[58,30,25,14],
[58,30,25,15],
[58,30,25,17],
[58,30,25,18],
[58,30,25,19],
[58,30,25,20],
[58,30,25,21],
[58,30,25,22],
[58,30,26,0],
[58,30,26,1],
[58,30,26,2],
[58,30,26,3],
[58,30,26,4],
[58,30,26,5],
[58,30,26,6],
[58,30,26,7],
[58,30,26,8],
[58,30,26,10],
[58,30,26,11],
[58,30,26,12],
[58,30,26,13],
[58,30,26,14],
[58,30,26,17],
[58,30,26,19],
[58,30,26,20],
[58,30,26,21],
[58,30,26,22],
[58,30,26,25],
[58,30,27,0],
[58,30,27,1],
[58,30,27,2],
[58,30,27,3],
[58,30,27,4],
[58,30,27,5],
[58,30,27,6],
[58,30,27,7],
[58,30,27,8],
[58,30,27,9],
[58,30,27,11],
[58,30,27,12],
[58,30,27,13],
[58,30,27,14],
[58,30,27,15],
[58,30,27,17],
[58,30,27,18],
[58,30,27,19],
[58,30,27,21],
[58,30,27,25],
[58,30,27,26],
[58,30,28,0],
[58,30,28,1],
[58,30,28,2],
[58,30,28,3],
[58,30,28,4],
[58,30,28,5],
[58,30,28,6],
[58,30,28,8],
[58,30,28,9],
[58,30,28,10],
[58,30,28,11],
[58,30,28,12],
[58,30,28,13],
[58,30,28,14],
[58,30,28,15],
[58,30,28,17],
[58,30,28,18],
[58,30,28,19],
[58,30,28,20],
[58,30,28,22],
[58,30,28,26],
[58,30,28,27],
[58,30,29,0],
[58,30,29,1],
[58,30,29,2],
[58,30,29,3],
[58,30,29,4],
[58,30,29,5],
[58,30,29,6],
[58,30,29,7],
[58,30,29,8],
[58,30,29,9],
[58,30,29,10],
[58,30,29,13],
[58,30,29,14],
[58,30,29,15],
[58,30,29,17],
[58,30,29,18],
[58,30,29,19],
[58,30,29,21],
[58,30,29,22],
[58,30,29,25],
[58,30,29,26],
[58,30,29,27],
[58,30,29,28],
[58,31,3,0],
[58,31,3,1],
[58,31,3,2],
[58,31,4,0],
[58,31,4,1],
[58,31,4,2],
[58,31,4,3],
[58,31,5,0],
[58,31,5,1],
[58,31,5,2],
[58,31,5,4],
[58,31,6,0],
[58,31,6,1],
[58,31,6,2],
[58,31,6,4],
[58,31,7,0],
[58,31,7,1],
[58,31,7,2],
[58,31,7,3],
[58,31,7,4],
[58,31,7,5],
[58,31,7,6],
[58,31,8,0],
[58,31,8,1],
[58,31,8,2],
[58,31,8,4],
[58,31,8,7],
[58,31,9,0],
[58,31,9,1],
[58,31,9,2],
[58,31,9,3],
[58,31,9,4],
[58,31,9,5],
[58,31,9,6],
[58,31,9,7],
[58,31,9,8],
[58,31,10,0],
[58,31,10,1],
[58,31,10,2],
[58,31,10,3],
[58,31,10,4],
[58,31,10,5],
[58,31,10,6],
[58,31,10,7],
[58,31,10,8],
[58,31,10,9],
[58,31,11,0],
[58,31,11,1],
[58,31,11,2],
[58,31,11,3],
[58,31,11,4],
[58,31,11,5],
[58,31,11,6],
[58,31,11,7],
[58,31,11,8],
[58,31,11,9],
[58,31,11,10],
[58,31,12,0],
[58,31,12,1],
[58,31,12,2],
[58,31,12,3],
[58,31,12,4],
[58,31,12,5],
[58,31,12,6],
[58,31,12,7],
[58,31,12,8],
[58,31,12,9],
[58,31,12,10],
[58,31,13,0],
[58,31,13,1],
[58,31,13,2],
[58,31,13,3],
[58,31,13,4],
[58,31,13,5],
[58,31,13,6],
[58,31,13,7],
[58,31,13,8],
[58,31,13,9],
[58,31,13,10],
[58,31,13,11],
[58,31,13,12],
[58,31,14,0],
[58,31,14,1],
[58,31,14,2],
[58,31,14,3],
[58,31,14,4],
[58,31,14,5],
[58,31,14,6],
[58,31,14,7],
[58,31,14,8],
[58,31,14,9],
[58,31,14,10],
[58,31,14,11],
[58,31,14,12],
[58,31,15,0],
[58,31,15,1],
[58,31,15,2],
[58,31,15,3],
[58,31,15,4],
[58,31,15,5],
[58,31,15,6],
[58,31,15,7],
[58,31,15,8],
[58,31,15,10],
[58,31,15,11],
[58,31,15,12],
[58,31,15,13],
[58,31,15,14],
[58,31,17,0],
[58,31,17,1],
[58,31,17,2],
[58,31,17,3],
[58,31,17,4],
[58,31,17,5],
[58,31,17,6],
[58,31,17,7],
[58,31,17,8],
[58,31,17,9],
[58,31,17,10],
[58,31,17,11],
[58,31,17,12],
[58,31,17,13],
[58,31,17,14],
[58,31,17,15],
[58,31,18,0],
[58,31,18,1],
[58,31,18,2],
[58,31,18,3],
[58,31,18,4],
[58,31,18,5],
[58,31,18,6],
[58,31,18,7],
[58,31,18,8],
[58,31,18,10],
[58,31,18,11],
[58,31,18,12],
[58,31,18,13],
[58,31,18,14],
[58,31,18,17],
[58,31,19,0],
[58,31,19,1],
[58,31,19,2],
[58,31,19,3],
[58,31,19,4],
[58,31,19,5],
[58,31,19,6],
[58,31,19,7],
[58,31,19,8],
[58,31,19,9],
[58,31,19,10],
[58,31,19,11],
[58,31,19,12],
[58,31,19,15],
[58,31,19,17],
[58,31,19,18],
[58,31,20,0],
[58,31,20,1],
[58,31,20,2],
[58,31,20,3],
[58,31,20,4],
[58,31,20,5],
[58,31,20,6],
[58,31,20,7],
[58,31,20,8],
[58,31,20,9],
[58,31,20,11],
[58,31,20,12],
[58,31,20,13],
[58,31,20,14],
[58,31,20,15],
[58,31,20,17],
[58,31,20,18],
[58,31,20,19],
[58,31,21,0],
[58,31,21,1],
[58,31,21,2],
[58,31,21,4],
[58,31,21,7],
[58,31,21,9],
[58,31,21,10],
[58,31,21,11],
[58,31,21,12],
[58,31,21,13],
[58,31,21,14],
[58,31,21,15],
[58,31,21,17],
[58,31,21,18],
[58,31,21,19],
[58,31,21,20],
[58,31,22,0],
[58,31,22,1],
[58,31,22,2],
[58,31,22,3],
[58,31,22,4],
[58,31,22,5],
[58,31,22,6],
[58,31,22,7],
[58,31,22,8],
[58,31,22,9],
[58,31,22,11],
[58,31,22,12],
[58,31,22,13],
[58,31,22,14],
[58,31,22,15],
[58,31,22,17],
[58,31,22,18],
[58,31,22,19],
[58,31,22,21],
[58,31,25,0],
[58,31,25,1],
[58,31,25,2],
[58,31,25,3],
[58,31,25,4],
[58,31,25,5],
[58,31,25,6],
[58,31,25,8],
[58,31,25,9],
[58,31,25,10],
[58,31,25,11],
[58,31,25,12],
[58,31,25,13],
[58,31,25,14],
[58,31,25,15],
[58,31,25,17],
[58,31,25,18],
[58,31,25,19],
[58,31,25,20],
[58,31,25,21],
[58,31,25,22],
[58,31,26,0],
[58,31,26,1],
[58,31,26,2],
[58,31,26,3],
[58,31,26,4],
[58,31,26,5],
[58,31,26,6],
[58,31,26,7],
[58,31,26,8],
[58,31,26,10],
[58,31,26,11],
[58,31,26,12],
[58,31,26,13],
[58,31,26,14],
[58,31,26,17],
[58,31,26,19],
[58,31,26,20],
[58,31,26,21],
[58,31,26,25],
[58,31,27,0],
[58,31,27,1],
[58,31,27,2],
[58,31,27,3],
[58,31,27,4],
[58,31,27,5],
[58,31,27,6],
[58,31,27,7],
[58,31,27,8],
[58,31,27,9],
[58,31,27,11],
[58,31,27,12],
[58,31,27,13],
[58,31,27,14],
[58,31,27,15],
[58,31,27,17],
[58,31,27,18],
[58,31,27,19],
[58,31,27,25],
[58,31,27,26],
[58,31,28,0],
[58,31,28,1],
[58,31,28,2],
[58,31,28,3],
[58,31,28,4],
[58,31,28,5],
[58,31,28,6],
[58,31,28,8],
[58,31,28,9],
[58,31,28,10],
[58,31,28,11],
[58,31,28,12],
[58,31,28,13],
[58,31,28,14],
[58,31,28,15],
[58,31,28,17],
[58,31,28,18],
[58,31,28,19],
[58,31,28,21],
[58,31,28,22],
[58,31,28,26],
[58,31,28,27],
[58,31,29,0],
[58,31,29,1],
[58,31,29,2],
[58,31,29,3],
[58,31,29,4],
[58,31,29,5],
[58,31,29,6],
[58,31,29,7],
[58,31,29,8],
[58,31,29,9],
[58,31,29,10],
[58,31,29,13],
[58,31,29,14],
[58,31,29,15],
[58,31,29,17],
[58,31,29,18],
[58,31,29,20],
[58,31,29,21],
[58,31,29,22],
[58,31,29,25],
[58,31,29,26],
[58,31,29,27],
[58,31,29,28],
[58,32,3,0],
[58,32,3,1],
[58,32,3,2],
[58,32,4,0],
[58,32,4,1],
[58,32,4,2],
[58,32,4,3],
[58,32,5,0],
[58,32,5,1],
[58,32,5,2],
[58,32,5,4],
[58,32,6,0],
[58,32,6,1],
[58,32,6,2],
[58,32,6,4],
[58,32,7,0],
[58,32,7,1],
[58,32,7,2],
[58,32,7,3],
[58,32,7,4],
[58,32,7,5],
[58,32,7,6],
[58,32,8,0],
[58,32,8,1],
[58,32,8,2],
[58,32,8,4],
[58,32,8,7],
[58,32,9,0],
[58,32,9,1],
[58,32,9,2],
[58,32,9,3],
[58,32,9,4],
[58,32,9,5],
[58,32,9,6],
[58,32,9,7],
[58,32,9,8],
[58,32,10,0],
[58,32,10,1],
[58,32,10,2],
[58,32,10,3],
[58,32,10,4],
[58,32,10,5],
[58,32,10,6],
[58,32,10,7],
[58,32,10,8],
[58,32,10,9],
[58,32,13,0],
[58,32,13,1],
[58,32,13,2],
[58,32,13,3],
[58,32,13,4],
[58,32,13,5],
[58,32,13,6],
[58,32,13,7],
[58,32,13,8],
[58,32,13,9],
[58,32,13,10],
[58,32,14,0],
[58,32,14,1],
[58,32,14,2],
[58,32,14,3],
[58,32,14,4],
[58,32,14,5],
[58,32,14,6],
[58,32,14,7],
[58,32,14,8],
[58,32,14,9],
[58,32,14,10],
[58,32,15,0],
[58,32,15,1],
[58,32,15,2],
[58,32,15,3],
[58,32,15,4],
[58,32,15,5],
[58,32,15,6],
[58,32,15,7],
[58,32,15,8],
[58,32,15,10],
[58,32,15,13],
[58,32,15,14],
[58,32,17,0],
[58,32,17,1],
[58,32,17,2],
[58,32,17,3],
[58,32,17,4],
[58,32,17,5],
[58,32,17,6],
[58,32,17,7],
[58,32,17,8],
[58,32,17,9],
[58,32,17,10],
[58,32,17,13],
[58,32,17,14],
[58,32,17,15],
[58,32,18,0],
[58,32,18,1],
[58,32,18,2],
[58,32,18,3],
[58,32,18,4],
[58,32,18,5],
[58,32,18,6],
[58,32,18,7],
[58,32,18,8],
[58,32,18,10],
[58,32,18,13],
[58,32,18,14],
[58,32,18,17],
[58,32,19,0],
[58,32,19,1],
[58,32,19,2],
[58,32,19,3],
[58,32,19,4],
[58,32,19,5],
[58,32,19,6],
[58,32,19,7],
[58,32,19,8],
[58,32,19,9],
[58,32,19,10],
[58,32,19,15],
[58,32,19,17],
[58,32,19,18],
[58,32,20,0],
[58,32,20,1],
[58,32,20,2],
[58,32,20,3],
[58,32,20,4],
[58,32,20,5],
[58,32,20,6],
[58,32,20,7],
[58,32,20,8],
[58,32,20,9],
[58,32,20,13],
[58,32,20,14],
[58,32,20,15],
[58,32,20,17],
[58,32,20,18],
[58,32,20,19],
[58,32,21,0],
[58,32,21,1],
[58,32,21,2],
[58,32,21,4],
[58,32,21,7],
[58,32,21,9],
[58,32,21,10],
[58,32,21,13],
[58,32,21,14],
[58,32,21,15],
[58,32,21,17],
[58,32,21,18],
[58,32,21,19],
[58,32,21,20],
[58,32,22,0],
[58,32,22,1],
[58,32,22,2],
[58,32,22,3],
[58,32,22,4],
[58,32,22,5],
[58,32,22,6],
[58,32,22,7],
[58,32,22,8],
[58,32,22,9],
[58,32,22,13],
[58,32,22,14],
[58,32,22,15],
[58,32,22,17],
[58,32,22,18],
[58,32,22,19],
[58,32,22,21],
[58,32,23,0],
[58,32,23,1],
[58,32,23,2],
[58,32,23,3],
[58,32,23,4],
[58,32,23,5],
[58,32,23,6],
[58,32,23,7],
[58,32,23,8],
[58,32,23,9],
[58,32,23,10],
[58,32,23,13],
[58,32,23,14],
[58,32,23,15],
[58,32,23,17],
[58,32,23,18],
[58,32,23,19],
[58,32,23,20],
[58,32,23,21],
[58,32,23,22],
[58,32,25,0],
[58,32,25,1],
[58,32,25,2],
[58,32,25,3],
[58,32,25,4],
[58,32,25,5],
[58,32,25,6],
[58,32,25,8],
[58,32,25,9],
[58,32,25,10],
[58,32,25,13],
[58,32,25,14],
[58,32,25,15],
[58,32,25,17],
[58,32,25,18],
[58,32,25,19],
[58,32,25,20],
[58,32,25,21],
[58,32,25,23],
[58,32,26,0],
[58,32,26,1],
[58,32,26,2],
[58,32,26,3],
[58,32,26,4],
[58,32,26,5],
[58,32,26,6],
[58,32,26,7],
[58,32,26,8],
[58,32,26,10],
[58,32,26,13],
[58,32,26,14],
[58,32,26,17],
[58,32,26,19],
[58,32,26,20],
[58,32,26,22],
[58,32,26,23],
[58,32,26,25],
[58,32,27,0],
[58,32,27,1],
[58,32,27,2],
[58,32,27,3],
[58,32,27,4],
[58,32,27,5],
[58,32,27,6],
[58,32,27,7],
[58,32,27,8],
[58,32,27,9],
[58,32,27,13],
[58,32,27,14],
[58,32,27,15],
[58,32,27,17],
[58,32,27,18],
[58,32,27,19],
[58,32,27,21],
[58,32,27,23],
[58,32,27,25],
[58,32,27,26],
[58,32,28,0],
[58,32,28,1],
[58,32,28,2],
[58,32,28,3],
[58,32,28,4],
[58,32,28,5],
[58,32,28,6],
[58,32,28,8],
[58,32,28,9],
[58,32,28,10],
[58,32,28,13],
[58,32,28,14],
[58,32,28,15],
[58,32,28,17],
[58,32,28,18],
[58,32,28,20],
[58,32,28,21],
[58,32,28,22],
[58,32,28,23],
[58,32,28,26],
[58,32,28,27],
[58,32,30,0],
[58,32,30,1],
[58,32,30,2],
[58,32,30,3],
[58,32,30,4],
[58,32,30,5],
[58,32,30,6],
[58,32,30,7],
[58,32,30,8],
[58,32,30,9],
[58,32,30,10],
[58,32,30,13],
[58,32,30,14],
[58,32,30,15],
[58,32,30,18],
[58,32,30,19],
[58,32,30,20],
[58,32,30,21],
[58,32,30,22],
[58,32,30,25],
[58,32,30,26],
[58,32,30,27],
[58,32,30,28],
[58,32,31,0],
[58,32,31,1],
[58,32,31,2],
[58,32,31,3],
[58,32,31,4],
[58,32,31,5],
[58,32,31,6],
[58,32,31,7],
[58,32,31,8],
[58,32,31,9],
[58,32,31,10],
[58,32,31,13],
[58,32,31,14],
[58,32,31,17],
[58,32,31,18],
[58,32,31,19],
[58,32,31,20],
[58,32,31,21],
[58,32,31,22],
[58,32,31,25],
[58,32,31,26],
[58,32,31,27],
[58,32,31,28],
[58,33,3,0],
[58,33,3,1],
[58,33,3,2],
[58,33,4,0],
[58,33,4,1],
[58,33,4,2],
[58,33,4,3],
[58,33,5,0],
[58,33,5,1],
[58,33,5,2],
[58,33,5,4],
[58,33,6,0],
[58,33,6,1],
[58,33,6,2],
[58,33,6,4],
[58,33,7,0],
[58,33,7,1],
[58,33,7,2],
[58,33,7,3],
[58,33,7,4],
[58,33,7,5],
[58,33,7,6],
[58,33,8,0],
[58,33,8,1],
[58,33,8,2],
[58,33,8,4],
[58,33,8,7],
[58,33,9,0],
[58,33,9,1],
[58,33,9,2],
[58,33,9,3],
[58,33,9,4],
[58,33,9,5],
[58,33,9,6],
[58,33,9,7],
[58,33,9,8],
[58,33,10,0],
[58,33,10,1],
[58,33,10,2],
[58,33,10,3],
[58,33,10,4],
[58,33,10,5],
[58,33,10,6],
[58,33,10,7],
[58,33,10,8],
[58,33,10,9],
[58,33,13,0],
[58,33,13,1],
[58,33,13,2],
[58,33,13,3],
[58,33,13,4],
[58,33,13,5],
[58,33,13,6],
[58,33,13,7],
[58,33,13,8],
[58,33,13,9],
[58,33,13,10],
[58,33,14,0],
[58,33,14,1],
[58,33,14,2],
[58,33,14,3],
[58,33,14,4],
[58,33,14,5],
[58,33,14,6],
[58,33,14,7],
[58,33,14,8],
[58,33,14,9],
[58,33,14,10],
[58,33,15,0],
[58,33,15,1],
[58,33,15,2],
[58,33,15,3],
[58,33,15,4],
[58,33,15,5],
[58,33,15,6],
[58,33,15,7],
[58,33,15,8],
[58,33,15,10],
[58,33,15,13],
[58,33,15,14],
[58,33,17,0],
[58,33,17,1],
[58,33,17,2],
[58,33,17,3],
[58,33,17,4],
[58,33,17,5],
[58,33,17,6],
[58,33,17,7],
[58,33,17,8],
[58,33,17,9],
[58,33,17,10],
[58,33,17,13],
[58,33,17,14],
[58,33,17,15],
[58,33,18,0],
[58,33,18,1],
[58,33,18,2],
[58,33,18,3],
[58,33,18,4],
[58,33,18,5],
[58,33,18,6],
[58,33,18,7],
[58,33,18,8],
[58,33,18,10],
[58,33,18,13],
[58,33,18,14],
[58,33,18,17],
[58,33,19,0],
[58,33,19,1],
[58,33,19,2],
[58,33,19,3],
[58,33,19,4],
[58,33,19,5],
[58,33,19,6],
[58,33,19,7],
[58,33,19,8],
[58,33,19,9],
[58,33,19,10],
[58,33,19,15],
[58,33,19,17],
[58,33,19,18],
[58,33,20,0],
[58,33,20,1],
[58,33,20,2],
[58,33,20,3],
[58,33,20,4],
[58,33,20,5],
[58,33,20,6],
[58,33,20,7],
[58,33,20,8],
[58,33,20,9],
[58,33,20,13],
[58,33,20,14],
[58,33,20,15],
[58,33,20,17],
[58,33,20,18],
[58,33,20,19],
[58,33,21,0],
[58,33,21,1],
[58,33,21,2],
[58,33,21,4],
[58,33,21,7],
[58,33,21,9],
[58,33,21,10],
[58,33,21,13],
[58,33,21,14],
[58,33,21,15],
[58,33,21,17],
[58,33,21,18],
[58,33,21,19],
[58,33,21,20],
[58,33,22,0],
[58,33,22,1],
[58,33,22,2],
[58,33,22,3],
[58,33,22,4],
[58,33,22,5],
[58,33,22,6],
[58,33,22,7],
[58,33,22,8],
[58,33,22,9],
[58,33,22,13],
[58,33,22,14],
[58,33,22,15],
[58,33,22,17],
[58,33,22,18],
[58,33,22,19],
[58,33,22,21],
[58,33,23,0],
[58,33,23,1],
[58,33,23,2],
[58,33,23,3],
[58,33,23,4],
[58,33,23,5],
[58,33,23,6],
[58,33,23,7],
[58,33,23,8],
[58,33,23,9],
[58,33,23,10],
[58,33,23,13],
[58,33,23,14],
[58,33,23,15],
[58,33,23,17],
[58,33,23,18],
[58,33,23,19],
[58,33,23,20],
[58,33,23,21],
[58,33,23,22],
[58,33,25,0],
[58,33,25,1],
[58,33,25,2],
[58,33,25,3],
[58,33,25,4],
[58,33,25,5],
[58,33,25,6],
[58,33,25,8],
[58,33,25,9],
[58,33,25,10],
[58,33,25,13],
[58,33,25,14],
[58,33,25,15],
[58,33,25,17],
[58,33,25,18],
[58,33,25,19],
[58,33,25,20],
[58,33,25,22],
[58,33,25,23],
[58,33,26,0],
[58,33,26,1],
[58,33,26,2],
[58,33,26,3],
[58,33,26,4],
[58,33,26,5],
[58,33,26,6],
[58,33,26,7],
[58,33,26,8],
[58,33,26,10],
[58,33,26,13],
[58,33,26,14],
[58,33,26,17],
[58,33,26,19],
[58,33,26,21],
[58,33,26,22],
[58,33,26,23],
[58,33,26,25],
[58,33,27,0],
[58,33,27,1],
[58,33,27,2],
[58,33,27,3],
[58,33,27,4],
[58,33,27,5],
[58,33,27,6],
[58,33,27,7],
[58,33,27,8],
[58,33,27,9],
[58,33,27,13],
[58,33,27,14],
[58,33,27,15],
[58,33,27,17],
[58,33,27,18],
[58,33,27,21],
[58,33,27,23],
[58,33,27,25],
[58,33,27,26],
[58,33,28,0],
[58,33,28,1],
[58,33,28,2],
[58,33,28,3],
[58,33,28,4],
[58,33,28,5],
[58,33,28,6],
[58,33,28,8],
[58,33,28,9],
[58,33,28,10],
[58,33,28,13],
[58,33,28,14],
[58,33,28,15],
[58,33,28,17],
[58,33,28,19],
[58,33,28,20],
[58,33,28,21],
[58,33,28,22],
[58,33,28,23],
[58,33,28,26],
[58,33,28,27],
[58,33,30,0],
[58,33,30,1],
[58,33,30,2],
[58,33,30,3],
[58,33,30,4],
[58,33,30,5],
[58,33,30,6],
[58,33,30,7],
[58,33,30,8],
[58,33,30,9],
[58,33,30,10],
[58,33,30,13],
[58,33,30,14],
[58,33,30,17],
[58,33,30,18],
[58,33,30,19],
[58,33,30,20],
[58,33,30,21],
[58,33,30,22],
[58,33,30,25],
[58,33,30,26],
[58,33,30,27],
[58,33,30,28],
[58,33,31,0],
[58,33,31,1],
[58,33,31,2],
[58,33,31,3],
[58,33,31,4],
[58,33,31,5],
[58,33,31,6],
[58,33,31,7],
[58,33,31,8],
[58,33,31,9],
[58,33,31,10],
[58,33,31,13],
[58,33,31,15],
[58,33,31,17],
[58,33,31,18],
[58,33,31,19],
[58,33,31,20],
[58,33,31,21],
[58,33,31,22],
[58,33,31,25],
[58,33,31,26],
[58,33,31,27],
[58,34,3,0],
[58,34,3,1],
[58,34,3,2],
[58,34,4,0],
[58,34,4,1],
[58,34,4,2],
[58,34,4,3],
[58,34,5,0],
[58,34,5,1],
[58,34,5,2],
[58,34,5,4],
[58,34,6,0],
[58,34,6,1],
[58,34,6,2],
[58,34,6,4],
[58,34,8,0],
[58,34,8,1],
[58,34,8,2],
[58,34,8,4],
[58,34,9,0],
[58,34,9,1],
[58,34,9,2],
[58,34,9,3],
[58,34,9,4],
[58,34,9,5],
[58,34,9,6],
[58,34,9,8],
[58,34,10,0],
[58,34,10,1],
[58,34,10,2],
[58,34,10,3],
[58,34,10,4],
[58,34,10,5],
[58,34,10,6],
[58,34,10,8],
[58,34,10,9],
[58,34,11,0],
[58,34,11,1],
[58,34,11,2],
[58,34,11,3],
[58,34,11,4],
[58,34,11,5],
[58,34,11,6],
[58,34,11,8],
[58,34,11,9],
[58,34,11,10],
[58,34,12,0],
[58,34,12,1],
[58,34,12,2],
[58,34,12,3],
[58,34,12,4],
[58,34,12,5],
[58,34,12,6],
[58,34,12,8],
[58,34,12,9],
[58,34,12,10],
[58,34,13,0],
[58,34,13,1],
[58,34,13,2],
[58,34,13,3],
[58,34,13,4],
[58,34,13,5],
[58,34,13,6],
[58,34,13,8],
[58,34,13,9],
[58,34,13,10],
[58,34,13,11],
[58,34,13,12],
[58,34,14,0],
[58,34,14,1],
[58,34,14,2],
[58,34,14,3],
[58,34,14,4],
[58,34,14,5],
[58,34,14,6],
[58,34,14,8],
[58,34,14,9],
[58,34,14,10],
[58,34,14,11],
[58,34,14,12],
[58,34,15,0],
[58,34,15,1],
[58,34,15,2],
[58,34,15,3],
[58,34,15,4],
[58,34,15,5],
[58,34,15,6],
[58,34,15,8],
[58,34,15,10],
[58,34,15,11],
[58,34,15,12],
[58,34,15,13],
[58,34,15,14],
[58,34,17,0],
[58,34,17,1],
[58,34,17,2],
[58,34,17,3],
[58,34,17,4],
[58,34,17,5],
[58,34,17,6],
[58,34,17,8],
[58,34,17,9],
[58,34,17,10],
[58,34,17,11],
[58,34,17,12],
[58,34,17,13],
[58,34,17,14],
[58,34,17,15],
[58,34,18,0],
[58,34,18,1],
[58,34,18,2],
[58,34,18,3],
[58,34,18,4],
[58,34,18,5],
[58,34,18,6],
[58,34,18,8],
[58,34,18,10],
[58,34,18,11],
[58,34,18,12],
[58,34,18,13],
[58,34,18,14],
[58,34,18,17],
[58,34,19,0],
[58,34,19,1],
[58,34,19,2],
[58,34,19,3],
[58,34,19,4],
[58,34,19,5],
[58,34,19,6],
[58,34,19,8],
[58,34,19,9],
[58,34,19,10],
[58,34,19,11],
[58,34,19,12],
[58,34,19,15],
[58,34,19,17],
[58,34,19,18],
[58,34,20,0],
[58,34,20,1],
[58,34,20,2],
[58,34,20,3],
[58,34,20,4],
[58,34,20,5],
[58,34,20,6],
[58,34,20,8],
[58,34,20,9],
[58,34,20,11],
[58,34,20,12],
[58,34,20,13],
[58,34,20,14],
[58,34,20,15],
[58,34,20,17],
[58,34,20,18],
[58,34,20,19],
[58,34,21,0],
[58,34,21,1],
[58,34,21,2],
[58,34,21,4],
[58,34,21,9],
[58,34,21,10],
[58,34,21,11],
[58,34,21,12],
[58,34,21,13],
[58,34,21,14],
[58,34,21,15],
[58,34,21,17],
[58,34,21,18],
[58,34,21,19],
[58,34,21,20],
[58,34,22,0],
[58,34,22,1],
[58,34,22,2],
[58,34,22,3],
[58,34,22,4],
[58,34,22,5],
[58,34,22,6],
[58,34,22,8],
[58,34,22,9],
[58,34,22,11],
[58,34,22,12],
[58,34,22,13],
[58,34,22,14],
[58,34,22,15],
[58,34,22,17],
[58,34,22,18],
[58,34,22,19],
[58,34,22,21],
[58,34,23,0],
[58,34,23,1],
[58,34,23,2],
[58,34,23,3],
[58,34,23,4],
[58,34,23,5],
[58,34,23,6],
[58,34,23,8],
[58,34,23,9],
[58,34,23,10],
[58,34,23,11],
[58,34,23,12],
[58,34,23,13],
[58,34,23,14],
[58,34,23,15],
[58,34,23,17],
[58,34,23,18],
[58,34,23,19],
[58,34,23,20],
[58,34,23,21],
[58,34,26,0],
[58,34,26,1],
[58,34,26,2],
[58,34,26,3],
[58,34,26,4],
[58,34,26,5],
[58,34,26,6],
[58,34,26,8],
[58,34,26,10],
[58,34,26,11],
[58,34,26,12],
[58,34,26,13],
[58,34,26,14],
[58,34,26,17],
[58,34,26,20],
[58,34,26,21],
[58,34,26,22],
[58,34,26,23],
[58,34,27,0],
[58,34,27,1],
[58,34,27,2],
[58,34,27,3],
[58,34,27,4],
[58,34,27,5],
[58,34,27,6],
[58,34,27,8],
[58,34,27,9],
[58,34,27,11],
[58,34,27,12],
[58,34,27,13],
[58,34,27,14],
[58,34,27,15],
[58,34,27,17],
[58,34,27,19],
[58,34,27,21],
[58,34,27,23],
[58,34,27,26],
[58,34,29,0],
[58,34,29,1],
[58,34,29,2],
[58,34,29,3],
[58,34,29,4],
[58,34,29,5],
[58,34,29,6],
[58,34,29,8],
[58,34,29,9],
[58,34,29,10],
[58,34,29,13],
[58,34,29,14],
[58,34,29,17],
[58,34,29,18],
[58,34,29,19],
[58,34,29,20],
[58,34,29,21],
[58,34,29,22],
[58,34,29,23],
[58,34,29,26],
[58,34,29,27],
[58,34,30,0],
[58,34,30,1],
[58,34,30,2],
[58,34,30,3],
[58,34,30,4],
[58,34,30,5],
[58,34,30,6],
[58,34,30,8],
[58,34,30,9],
[58,34,30,10],
[58,34,30,11],
[58,34,30,12],
[58,34,30,13],
[58,34,30,15],
[58,34,30,17],
[58,34,30,18],
[58,34,30,19],
[58,34,30,20],
[58,34,30,21],
[58,34,30,22],
[58,34,30,26],
[58,34,30,27],
[58,34,31,0],
[58,34,31,1],
[58,34,31,2],
[58,34,31,3],
[58,34,31,4],
[58,34,31,5],
[58,34,31,6],
[58,34,31,8],
[58,34,31,9],
[58,34,31,10],
[58,34,31,11],
[58,34,31,14],
[58,34,31,15],
[58,34,31,17],
[58,34,31,18],
[58,34,31,19],
[58,34,31,20],
[58,34,31,21],
[58,34,31,22],
[58,34,31,26],
[58,34,32,0],
[58,34,32,1],
[58,34,32,2],
[58,34,32,3],
[58,34,32,4],
[58,34,32,5],
[58,34,32,6],
[58,34,32,8],
[58,34,32,9],
[58,34,32,13],
[58,34,32,14],
[58,34,32,15],
[58,34,32,17],
[58,34,32,18],
[58,34,32,19],
[58,34,32,20],
[58,34,32,21],
[58,34,32,22],
[58,34,32,23],
[58,34,33,0],
[58,34,33,1],
[58,34,33,2],
[58,34,33,3],
[58,34,33,4],
[58,34,33,5],
[58,34,33,6],
[58,34,33,10],
[58,34,33,13],
[58,34,33,14],
[58,34,33,15],
[58,34,33,17],
[58,34,33,18],
[58,34,33,19],
[58,34,33,20],
[58,34,33,21],
[58,34,33,22],
[58,34,33,23],
[58,35,3,0],
[58,35,3,1],
[58,35,3,2],
[58,35,4,0],
[58,35,4,1],
[58,35,4,2],
[58,35,4,3],
[58,35,5,0],
[58,35,5,1],
[58,35,5,2],
[58,35,5,4],
[58,35,6,0],
[58,35,6,1],
[58,35,6,2],
[58,35,6,4],
[58,35,7,0],
[58,35,7,1],
[58,35,7,2],
[58,35,7,3],
[58,35,7,4],
[58,35,7,5],
[58,35,7,6],
[58,35,8,0],
[58,35,8,1],
[58,35,8,2],
[58,35,8,4],
[58,35,8,7],
[58,35,10,0],
[58,35,10,1],
[58,35,10,2],
[58,35,10,3],
[58,35,10,4],
[58,35,10,5],
[58,35,10,6],
[58,35,10,7],
[58,35,10,8],
[58,35,11,0],
[58,35,11,1],
[58,35,11,2],
[58,35,11,3],
[58,35,11,4],
[58,35,11,5],
[58,35,11,6],
[58,35,11,7],
[58,35,11,8],
[58,35,11,10],
[58,35,12,0],
[58,35,12,1],
[58,35,12,2],
[58,35,12,3],
[58,35,12,4],
[58,35,12,5],
[58,35,12,6],
[58,35,12,7],
[58,35,12,8],
[58,35,12,10],
[58,35,13,0],
[58,35,13,1],
[58,35,13,2],
[58,35,13,3],
[58,35,13,4],
[58,35,13,5],
[58,35,13,6],
[58,35,13,7],
[58,35,13,8],
[58,35,13,10],
[58,35,13,11],
[58,35,13,12],
[58,35,14,0],
[58,35,14,1],
[58,35,14,2],
[58,35,14,3],
[58,35,14,4],
[58,35,14,5],
[58,35,14,6],
[58,35,14,7],
[58,35,14,8],
[58,35,14,10],
[58,35,14,11],
[58,35,14,12],
[58,35,17,0],
[58,35,17,1],
[58,35,17,2],
[58,35,17,3],
[58,35,17,4],
[58,35,17,5],
[58,35,17,6],
[58,35,17,7],
[58,35,17,8],
[58,35,17,10],
[58,35,17,11],
[58,35,17,12],
[58,35,17,13],
[58,35,17,14],
[58,35,19,0],
[58,35,19,1],
[58,35,19,2],
[58,35,19,3],
[58,35,19,4],
[58,35,19,5],
[58,35,19,6],
[58,35,19,7],
[58,35,19,8],
[58,35,19,10],
[58,35,19,11],
[58,35,19,12],
[58,35,19,17],
[58,35,20,0],
[58,35,20,1],
[58,35,20,2],
[58,35,20,3],
[58,35,20,4],
[58,35,20,5],
[58,35,20,6],
[58,35,20,7],
[58,35,20,8],
[58,35,20,11],
[58,35,20,12],
[58,35,20,13],
[58,35,20,14],
[58,35,20,17],
[58,35,20,19],
[58,35,21,0],
[58,35,21,1],
[58,35,21,2],
[58,35,21,4],
[58,35,21,7],
[58,35,21,10],
[58,35,21,11],
[58,35,21,12],
[58,35,21,13],
[58,35,21,14],
[58,35,21,17],
[58,35,21,19],
[58,35,21,20],
[58,35,22,0],
[58,35,22,1],
[58,35,22,2],
[58,35,22,3],
[58,35,22,4],
[58,35,22,5],
[58,35,22,6],
[58,35,22,7],
[58,35,22,8],
[58,35,22,11],
[58,35,22,12],
[58,35,22,13],
[58,35,22,14],
[58,35,22,17],
[58,35,22,19],
[58,35,22,21],
[58,35,23,0],
[58,35,23,1],
[58,35,23,2],
[58,35,23,3],
[58,35,23,4],
[58,35,23,5],
[58,35,23,6],
[58,35,23,7],
[58,35,23,8],
[58,35,23,10],
[58,35,23,11],
[58,35,23,12],
[58,35,23,13],
[58,35,23,14],
[58,35,23,17],
[58,35,23,19],
[58,35,23,20],
[58,35,23,22],
[58,35,25,0],
[58,35,25,1],
[58,35,25,2],
[58,35,25,3],
[58,35,25,4],
[58,35,25,5],
[58,35,25,6],
[58,35,25,8],
[58,35,25,10],
[58,35,25,11],
[58,35,25,12],
[58,35,25,13],
[58,35,25,14],
[58,35,25,17],
[58,35,25,20],
[58,35,25,21],
[58,35,25,22],
[58,35,25,23],
[58,35,27,0],
[58,35,27,1],
[58,35,27,2],
[58,35,27,3],
[58,35,27,4],
[58,35,27,5],
[58,35,27,6],
[58,35,27,7],
[58,35,27,8],
[58,35,27,11],
[58,35,27,12],
[58,35,27,13],
[58,35,27,14],
[58,35,27,19],
[58,35,27,21],
[58,35,27,23],
[58,35,27,25],
[58,35,28,0],
[58,35,28,1],
[58,35,28,2],
[58,35,28,3],
[58,35,28,4],
[58,35,28,5],
[58,35,28,6],
[58,35,28,8],
[58,35,28,10],
[58,35,28,11],
[58,35,28,12],
[58,35,28,13],
[58,35,28,14],
[58,35,28,17],
[58,35,28,19],
[58,35,28,20],
[58,35,28,21],
[58,35,28,22],
[58,35,28,23],
[58,35,28,27],
[58,35,29,0],
[58,35,29,1],
[58,35,29,2],
[58,35,29,3],
[58,35,29,4],
[58,35,29,5],
[58,35,29,6],
[58,35,29,7],
[58,35,29,8],
[58,35,29,10],
[58,35,29,13],
[58,35,29,17],
[58,35,29,19],
[58,35,29,20],
[58,35,29,21],
[58,35,29,22],
[58,35,29,23],
[58,35,29,25],
[58,35,29,27],
[58,35,30,0],
[58,35,30,1],
[58,35,30,2],
[58,35,30,3],
[58,35,30,4],
[58,35,30,5],
[58,35,30,6],
[58,35,30,7],
[58,35,30,8],
[58,35,30,10],
[58,35,30,11],
[58,35,30,14],
[58,35,30,17],
[58,35,30,19],
[58,35,30,20],
[58,35,30,21],
[58,35,30,22],
[58,35,30,25],
[58,35,31,0],
[58,35,31,1],
[58,35,31,2],
[58,35,31,3],
[58,35,31,4],
[58,35,31,5],
[58,35,31,6],
[58,35,31,7],
[58,35,31,8],
[58,35,31,12],
[58,35,31,13],
[58,35,31,14],
[58,35,31,17],
[58,35,31,19],
[58,35,31,20],
[58,35,31,21],
[58,35,31,22],
[58,35,31,25],
[58,35,32,0],
[58,35,32,1],
[58,35,32,2],
[58,35,32,3],
[58,35,32,4],
[58,35,32,5],
[58,35,32,6],
[58,35,32,7],
[58,35,32,10],
[58,35,32,13],
[58,35,32,14],
[58,35,32,17],
[58,35,32,19],
[58,35,32,20],
[58,35,32,21],
[58,35,32,22],
[58,35,32,23],
[58,35,33,0],
[58,35,33,1],
[58,35,33,2],
[58,35,33,3],
[58,35,33,4],
[58,35,33,5],
[58,35,33,8],
[58,35,33,10],
[58,35,33,13],
[58,35,33,14],
[58,35,33,17],
[58,35,33,19],
[58,35,33,20],
[58,35,33,21],
[58,35,33,22],
[58,35,33,23],
[58,35,34,0],
[58,35,34,1],
[58,35,34,2],
[58,35,34,3],
[58,35,34,4],
[58,35,34,6],
[58,35,34,8],
[58,35,34,10],
[58,35,34,11],
[58,35,34,12],
[58,35,34,13],
[58,35,34,14],
[58,35,34,17],
[58,35,34,19],
[58,35,34,20],
[58,35,34,21],
[58,35,34,22],
[58,36,3,0],
[58,36,3,1],
[58,36,3,2],
[58,36,4,0],
[58,36,4,1],
[58,36,4,2],
[58,36,4,3],
[58,36,5,0],
[58,36,5,1],
[58,36,5,2],
[58,36,5,4],
[58,36,6,0],
[58,36,6,1],
[58,36,6,2],
[58,36,6,4],
[58,36,7,0],
[58,36,7,1],
[58,36,7,2],
[58,36,7,3],
[58,36,7,4],
[58,36,7,5],
[58,36,7,6],
[58,36,8,0],
[58,36,8,1],
[58,36,8,2],
[58,36,8,4],
[58,36,8,7],
[58,36,9,0],
[58,36,9,1],
[58,36,9,2],
[58,36,9,3],
[58,36,9,4],
[58,36,9,5],
[58,36,9,6],
[58,36,9,7],
[58,36,9,8],
[58,36,10,0],
[58,36,10,1],
[58,36,10,2],
[58,36,10,3],
[58,36,10,4],
[58,36,10,5],
[58,36,10,6],
[58,36,10,7],
[58,36,10,8],
[58,36,10,9],
[58,36,11,0],
[58,36,11,1],
[58,36,11,2],
[58,36,11,3],
[58,36,11,4],
[58,36,11,5],
[58,36,11,6],
[58,36,11,7],
[58,36,11,8],
[58,36,11,9],
[58,36,11,10],
[58,36,12,0],
[58,36,12,1],
[58,36,12,2],
[58,36,12,3],
[58,36,12,4],
[58,36,12,5],
[58,36,12,6],
[58,36,12,7],
[58,36,12,8],
[58,36,12,9],
[58,36,12,10],
[58,36,15,0],
[58,36,15,1],
[58,36,15,2],
[58,36,15,3],
[58,36,15,4],
[58,36,15,5],
[58,36,15,6],
[58,36,15,7],
[58,36,15,8],
[58,36,15,10],
[58,36,15,11],
[58,36,15,12],
[58,36,17,0],
[58,36,17,1],
[58,36,17,2],
[58,36,17,3],
[58,36,17,4],
[58,36,17,5],
[58,36,17,6],
[58,36,17,7],
[58,36,17,8],
[58,36,17,9],
[58,36,17,10],
[58,36,17,11],
[58,36,17,12],
[58,36,17,15],
[58,36,18,0],
[58,36,18,1],
[58,36,18,2],
[58,36,18,3],
[58,36,18,4],
[58,36,18,5],
[58,36,18,6],
[58,36,18,7],
[58,36,18,8],
[58,36,18,10],
[58,36,18,11],
[58,36,18,12],
[58,36,18,17],
[58,36,20,0],
[58,36,20,1],
[58,36,20,2],
[58,36,20,3],
[58,36,20,4],
[58,36,20,5],
[58,36,20,6],
[58,36,20,7],
[58,36,20,8],
[58,36,20,9],
[58,36,20,11],
[58,36,20,12],
[58,36,20,15],
[58,36,20,17],
[58,36,20,18],
[58,36,21,0],
[58,36,21,1],
[58,36,21,2],
[58,36,21,4],
[58,36,21,7],
[58,36,21,9],
[58,36,21,10],
[58,36,21,11],
[58,36,21,12],
[58,36,21,15],
[58,36,21,17],
[58,36,21,18],
[58,36,21,20],
[58,36,22,0],
[58,36,22,1],
[58,36,22,2],
[58,36,22,3],
[58,36,22,4],
[58,36,22,5],
[58,36,22,6],
[58,36,22,7],
[58,36,22,8],
[58,36,22,9],
[58,36,22,11],
[58,36,22,12],
[58,36,22,15],
[58,36,22,17],
[58,36,22,18],
[58,36,23,0],
[58,36,23,1],
[58,36,23,2],
[58,36,23,3],
[58,36,23,4],
[58,36,23,5],
[58,36,23,6],
[58,36,23,7],
[58,36,23,8],
[58,36,23,9],
[58,36,23,10],
[58,36,23,11],
[58,36,23,12],
[58,36,23,15],
[58,36,23,17],
[58,36,23,18],
[58,36,23,21],
[58,36,23,22],
[58,36,25,0],
[58,36,25,1],
[58,36,25,2],
[58,36,25,3],
[58,36,25,4],
[58,36,25,5],
[58,36,25,6],
[58,36,25,8],
[58,36,25,9],
[58,36,25,10],
[58,36,25,11],
[58,36,25,12],
[58,36,25,15],
[58,36,25,17],
[58,36,25,20],
[58,36,25,21],
[58,36,25,22],
[58,36,25,23],
[58,36,26,0],
[58,36,26,1],
[58,36,26,2],
[58,36,26,3],
[58,36,26,4],
[58,36,26,5],
[58,36,26,6],
[58,36,26,7],
[58,36,26,8],
[58,36,26,10],
[58,36,26,11],
[58,36,26,12],
[58,36,26,20],
[58,36,26,21],
[58,36,26,22],
[58,36,26,23],
[58,36,26,25],
[58,36,27,0],
[58,36,27,1],
[58,36,27,2],
[58,36,27,3],
[58,36,27,4],
[58,36,27,5],
[58,36,27,6],
[58,36,27,7],
[58,36,27,8],
[58,36,27,9],
[58,36,27,11],
[58,36,27,12],
[58,36,27,17],
[58,36,27,18],
[58,36,27,21],
[58,36,27,23],
[58,36,27,25],
[58,36,27,26],
[58,36,28,0],
[58,36,28,1],
[58,36,28,2],
[58,36,28,3],
[58,36,28,4],
[58,36,28,5],
[58,36,28,6],
[58,36,28,8],
[58,36,28,9],
[58,36,28,10],
[58,36,28,11],
[58,36,28,12],
[58,36,28,15],
[58,36,28,17],
[58,36,28,18],
[58,36,28,20],
[58,36,28,21],
[58,36,28,22],
[58,36,28,23],
[58,36,28,26],
[58,36,28,27],
[58,36,29,0],
[58,36,29,1],
[58,36,29,2],
[58,36,29,3],
[58,36,29,4],
[58,36,29,5],
[58,36,29,6],
[58,36,29,7],
[58,36,29,8],
[58,36,29,9],
[58,36,29,10],
[58,36,29,15],
[58,36,29,17],
[58,36,29,18],
[58,36,29,20],
[58,36,29,21],
[58,36,29,22],
[58,36,29,23],
[58,36,29,25],
[58,36,29,26],
[58,36,30,0],
[58,36,30,1],
[58,36,30,2],
[58,36,30,3],
[58,36,30,4],
[58,36,30,5],
[58,36,30,6],
[58,36,30,7],
[58,36,30,8],
[58,36,30,9],
[58,36,30,12],
[58,36,30,15],
[58,36,30,17],
[58,36,30,18],
[58,36,30,20],
[58,36,30,21],
[58,36,30,22],
[58,36,30,25],
[58,36,31,0],
[58,36,31,1],
[58,36,31,2],
[58,36,31,3],
[58,36,31,4],
[58,36,31,5],
[58,36,31,6],
[58,36,31,7],
[58,36,31,10],
[58,36,31,11],
[58,36,31,12],
[58,36,31,15],
[58,36,31,17],
[58,36,31,18],
[58,36,31,20],
[58,36,31,21],
[58,36,31,22],
[58,36,32,0],
[58,36,32,1],
[58,36,32,2],
[58,36,32,3],
[58,36,32,4],
[58,36,32,5],
[58,36,32,8],
[58,36,32,9],
[58,36,32,10],
[58,36,32,15],
[58,36,32,17],
[58,36,32,18],
[58,36,32,20],
[58,36,32,21],
[58,36,32,22],
[58,36,32,23],
[58,36,33,0],
[58,36,33,1],
[58,36,33,2],
[58,36,33,3],
[58,36,33,4],
[58,36,33,6],
[58,36,33,7],
[58,36,33,8],
[58,36,33,9],
[58,36,33,10],
[58,36,33,15],
[58,36,33,17],
[58,36,33,18],
[58,36,33,20],
[58,36,33,21],
[58,36,33,22],
[58,36,34,0],
[58,36,34,1],
[58,36,34,2],
[58,36,34,5],
[58,36,34,6],
[58,36,34,8],
[58,36,34,9],
[58,36,34,10],
[58,36,34,11],
[58,36,34,12],
[58,36,34,15],
[58,36,34,17],
[58,36,34,18],
[58,36,34,20],
[58,36,34,21],
[58,36,35,3],
[58,36,35,4],
[58,36,35,5],
[58,36,35,6],
[58,36,35,7],
[58,36,35,8],
[58,36,35,10],
[58,36,35,11],
[58,36,35,12],
[58,36,35,17],
[58,36,35,20],
[58,37,3,0],
[58,37,3,1],
[58,37,3,2],
[58,37,5,0],
[58,37,5,1],
[58,37,5,2],
[58,37,6,0],
[58,37,6,1],
[58,37,6,2],
[58,37,7,0],
[58,37,7,1],
[58,37,7,2],
[58,37,7,3],
[58,37,7,5],
[58,37,7,6],
[58,37,8,0],
[58,37,8,1],
[58,37,8,2],
[58,37,8,7],
[58,37,9,0],
[58,37,9,1],
[58,37,9,2],
[58,37,9,3],
[58,37,9,5],
[58,37,9,6],
[58,37,9,7],
[58,37,9,8],
[58,37,10,0],
[58,37,10,1],
[58,37,10,2],
[58,37,10,3],
[58,37,10,5],
[58,37,10,6],
[58,37,10,7],
[58,37,10,8],
[58,37,10,9],
[58,37,11,0],
[58,37,11,1],
[58,37,11,2],
[58,37,11,3],
[58,37,11,5],
[58,37,11,6],
[58,37,11,7],
[58,37,11,8],
[58,37,11,9],
[58,37,11,10],
[58,37,12,0],
[58,37,12,1],
[58,37,12,2],
[58,37,12,3],
[58,37,12,5],
[58,37,12,6],
[58,37,12,7],
[58,37,12,8],
[58,37,12,9],
[58,37,12,10],
[58,37,13,0],
[58,37,13,1],
[58,37,13,2],
[58,37,13,3],
[58,37,13,5],
[58,37,13,6],
[58,37,13,7],
[58,37,13,8],
[58,37,13,9],
[58,37,13,10],
[58,37,13,11],
[58,37,13,12],
[58,37,14,0],
[58,37,14,1],
[58,37,14,2],
[58,37,14,3],
[58,37,14,5],
[58,37,14,6],
[58,37,14,7],
[58,37,14,8],
[58,37,14,9],
[58,37,14,10],
[58,37,14,11],
[58,37,14,12],
[58,37,15,0],
[58,37,15,1],
[58,37,15,2],
[58,37,15,3],
[58,37,15,5],
[58,37,15,6],
[58,37,15,7],
[58,37,15,8],
[58,37,15,10],
[58,37,15,11],
[58,37,15,12],
[58,37,15,13],
[58,37,15,14],
[58,37,17,0],
[58,37,17,1],
[58,37,17,2],
[58,37,17,3],
[58,37,17,5],
[58,37,17,6],
[58,37,17,7],
[58,37,17,8],
[58,37,17,9],
[58,37,17,10],
[58,37,17,11],
[58,37,17,12],
[58,37,17,13],
[58,37,17,14],
[58,37,17,15],
[58,37,18,0],
[58,37,18,1],
[58,37,18,2],
[58,37,18,3],
[58,37,18,5],
[58,37,18,6],
[58,37,18,7],
[58,37,18,8],
[58,37,18,10],
[58,37,18,11],
[58,37,18,12],
[58,37,18,13],
[58,37,18,14],
[58,37,18,17],
[58,37,19,0],
[58,37,19,1],
[58,37,19,2],
[58,37,19,3],
[58,37,19,5],
[58,37,19,6],
[58,37,19,7],
[58,37,19,8],
[58,37,19,9],
[58,37,19,10],
[58,37,19,11],
[58,37,19,12],
[58,37,19,15],
[58,37,19,17],
[58,37,19,18],
[58,37,20,0],
[58,37,20,1],
[58,37,20,2],
[58,37,20,3],
[58,37,20,5],
[58,37,20,6],
[58,37,20,7],
[58,37,20,8],
[58,37,20,9],
[58,37,20,11],
[58,37,20,12],
[58,37,20,13],
[58,37,20,14],
[58,37,20,15],
[58,37,20,17],
[58,37,20,18],
[58,37,20,19],
[58,37,21,0],
[58,37,21,1],
[58,37,21,2],
[58,37,21,7],
[58,37,21,9],
[58,37,21,10],
[58,37,21,11],
[58,37,21,12],
[58,37,21,13],
[58,37,21,14],
[58,37,21,15],
[58,37,21,17],
[58,37,21,18],
[58,37,21,19],
[58,37,21,20],
[58,37,22,0],
[58,37,22,1],
[58,37,22,2],
[58,37,22,3],
[58,37,22,5],
[58,37,22,6],
[58,37,22,7],
[58,37,22,8],
[58,37,22,9],
[58,37,22,11],
[58,37,22,12],
[58,37,22,13],
[58,37,22,14],
[58,37,22,15],
[58,37,22,17],
[58,37,22,18],
[58,37,22,19],
[58,37,22,21],
[58,37,23,0],
[58,37,23,1],
[58,37,23,2],
[58,37,23,3],
[58,37,23,5],
[58,37,23,6],
[58,37,23,7],
[58,37,23,8],
[58,37,23,9],
[58,37,23,10],
[58,37,23,11],
[58,37,23,12],
[58,37,23,13],
[58,37,23,14],
[58,37,23,15],
[58,37,23,17],
[58,37,23,18],
[58,37,23,20],
[58,37,23,21],
[58,37,23,22],
[58,37,25,0],
[58,37,25,1],
[58,37,25,2],
[58,37,25,3],
[58,37,25,5],
[58,37,25,6],
[58,37,25,8],
[58,37,25,9],
[58,37,25,10],
[58,37,25,11],
[58,37,25,12],
[58,37,25,13],
[58,37,25,14],
[58,37,25,15],
[58,37,25,18],
[58,37,25,19],
[58,37,25,20],
[58,37,25,21],
[58,37,25,22],
[58,37,25,23],
[58,37,26,0],
[58,37,26,1],
[58,37,26,2],
[58,37,26,3],
[58,37,26,5],
[58,37,26,6],
[58,37,26,7],
[58,37,26,8],
[58,37,26,10],
[58,37,26,11],
[58,37,26,12],
[58,37,26,13],
[58,37,26,14],
[58,37,26,17],
[58,37,26,19],
[58,37,26,20],
[58,37,26,21],
[58,37,26,22],
[58,37,26,23],
[58,37,26,25],
[58,37,27,0],
[58,37,27,1],
[58,37,27,2],
[58,37,27,3],
[58,37,27,5],
[58,37,27,6],
[58,37,27,7],
[58,37,27,8],
[58,37,27,9],
[58,37,27,11],
[58,37,27,12],
[58,37,27,13],
[58,37,27,15],
[58,37,27,17],
[58,37,27,18],
[58,37,27,19],
[58,37,27,21],
[58,37,27,23],
[58,37,27,25],
[58,37,27,26],
[58,37,28,0],
[58,37,28,1],
[58,37,28,2],
[58,37,28,3],
[58,37,28,5],
[58,37,28,6],
[58,37,28,8],
[58,37,28,9],
[58,37,28,10],
[58,37,28,11],
[58,37,28,14],
[58,37,28,15],
[58,37,28,17],
[58,37,28,18],
[58,37,28,19],
[58,37,28,20],
[58,37,28,21],
[58,37,28,22],
[58,37,28,23],
[58,37,28,26],
[58,37,29,0],
[58,37,29,1],
[58,37,29,2],
[58,37,29,3],
[58,37,29,5],
[58,37,29,6],
[58,37,29,7],
[58,37,29,8],
[58,37,29,9],
[58,37,29,13],
[58,37,29,14],
[58,37,29,15],
[58,37,29,17],
[58,37,29,18],
[58,37,29,19],
[58,37,29,20],
[58,37,29,21],
[58,37,29,22],
[58,37,29,23],
[58,37,29,25],
[58,37,30,0],
[58,37,30,1],
[58,37,30,2],
[58,37,30,3],
[58,37,30,5],
[58,37,30,6],
[58,37,30,7],
[58,37,30,10],
[58,37,30,11],
[58,37,30,12],
[58,37,30,13],
[58,37,30,14],
[58,37,30,15],
[58,37,30,17],
[58,37,30,18],
[58,37,30,19],
[58,37,30,20],
[58,37,30,21],
[58,37,30,22],
[58,37,31,0],
[58,37,31,1],
[58,37,31,2],
[58,37,31,3],
[58,37,31,5],
[58,37,31,8],
[58,37,31,9],
[58,37,31,10],
[58,37,31,11],
[58,37,31,12],
[58,37,31,13],
[58,37,31,14],
[58,37,31,15],
[58,37,31,17],
[58,37,31,18],
[58,37,31,19],
[58,37,31,20],
[58,37,31,21],
[58,37,31,22],
[58,37,32,0],
[58,37,32,1],
[58,37,32,2],
[58,37,32,3],
[58,37,32,6],
[58,37,32,7],
[58,37,32,8],
[58,37,32,9],
[58,37,32,10],
[58,37,32,13],
[58,37,32,14],
[58,37,32,15],
[58,37,32,17],
[58,37,32,18],
[58,37,32,19],
[58,37,32,20],
[58,37,32,21],
[58,37,32,22],
[58,37,33,0],
[58,37,33,1],
[58,37,33,2],
[58,37,33,5],
[58,37,33,6],
[58,37,33,7],
[58,37,33,8],
[58,37,33,9],
[58,37,33,10],
[58,37,33,13],
[58,37,33,14],
[58,37,33,15],
[58,37,33,17],
[58,37,33,18],
[58,37,33,19],
[58,37,33,20],
[58,37,33,21],
[58,37,34,3],
[58,37,34,5],
[58,37,34,6],
[58,37,34,8],
[58,37,34,9],
[58,37,34,10],
[58,37,34,11],
[58,37,34,12],
[58,37,34,13],
[58,37,34,14],
[58,37,34,15],
[58,37,34,17],
[58,37,34,18],
[58,37,34,19],
[58,37,34,20],
[58,37,35,0],
[58,37,35,1],
[58,37,35,2],
[58,37,35,3],
[58,37,35,5],
[58,37,35,6],
[58,37,35,7],
[58,37,35,8],
[58,37,35,10],
[58,37,35,11],
[58,37,35,12],
[58,37,35,13],
[58,37,35,14],
[58,37,35,17],
[58,37,35,19],
[58,37,36,0],
[58,37,36,1],
[58,37,36,2],
[58,37,36,3],
[58,37,36,5],
[58,37,36,6],
[58,37,36,7],
[58,37,36,8],
[58,37,36,9],
[58,37,36,10],
[58,37,36,11],
[58,37,36,12],
[58,37,36,15],
[58,37,36,17],
[58,37,36,18],
[58,38,3,0],
[58,38,3,1],
[58,38,3,2],
[58,38,4,0],
[58,38,4,1],
[58,38,4,2],
[58,38,4,3],
[58,38,5,0],
[58,38,5,1],
[58,38,5,2],
[58,38,5,4],
[58,38,6,0],
[58,38,6,1],
[58,38,6,2],
[58,38,6,4],
[58,38,8,0],
[58,38,8,1],
[58,38,8,2],
[58,38,8,4],
[58,38,9,0],
[58,38,9,1],
[58,38,9,2],
[58,38,9,3],
[58,38,9,4],
[58,38,9,5],
[58,38,9,6],
[58,38,9,8],
[58,38,10,0],
[58,38,10,1],
[58,38,10,2],
[58,38,10,3],
[58,38,10,4],
[58,38,10,5],
[58,38,10,6],
[58,38,10,8],
[58,38,10,9],
[58,38,11,0],
[58,38,11,1],
[58,38,11,2],
[58,38,11,3],
[58,38,11,4],
[58,38,11,5],
[58,38,11,6],
[58,38,11,8],
[58,38,11,9],
[58,38,11,10],
[58,38,12,0],
[58,38,12,1],
[58,38,12,2],
[58,38,12,3],
[58,38,12,4],
[58,38,12,5],
[58,38,12,6],
[58,38,12,8],
[58,38,12,9],
[58,38,12,10],
[58,38,13,0],
[58,38,13,1],
[58,38,13,2],
[58,38,13,3],
[58,38,13,4],
[58,38,13,5],
[58,38,13,6],
[58,38,13,8],
[58,38,13,9],
[58,38,13,10],
[58,38,13,11],
[58,38,13,12],
[58,38,14,0],
[58,38,14,1],
[58,38,14,2],
[58,38,14,3],
[58,38,14,4],
[58,38,14,5],
[58,38,14,6],
[58,38,14,8],
[58,38,14,9],
[58,38,14,10],
[58,38,14,11],
[58,38,14,12],
[58,38,15,0],
[58,38,15,1],
[58,38,15,2],
[58,38,15,3],
[58,38,15,4],
[58,38,15,5],
[58,38,15,6],
[58,38,15,8],
[58,38,15,10],
[58,38,15,11],
[58,38,15,12],
[58,38,15,13],
[58,38,15,14],
[58,38,17,0],
[58,38,17,1],
[58,38,17,2],
[58,38,17,3],
[58,38,17,4],
[58,38,17,5],
[58,38,17,6],
[58,38,17,8],
[58,38,17,9],
[58,38,17,10],
[58,38,17,11],
[58,38,17,12],
[58,38,17,13],
[58,38,17,14],
[58,38,17,15],
[58,38,18,0],
[58,38,18,1],
[58,38,18,2],
[58,38,18,3],
[58,38,18,4],
[58,38,18,5],
[58,38,18,6],
[58,38,18,8],
[58,38,18,10],
[58,38,18,11],
[58,38,18,12],
[58,38,18,13],
[58,38,18,14],
[58,38,18,17],
[58,38,19,0],
[58,38,19,1],
[58,38,19,2],
[58,38,19,3],
[58,38,19,4],
[58,38,19,5],
[58,38,19,6],
[58,38,19,8],
[58,38,19,9],
[58,38,19,10],
[58,38,19,11],
[58,38,19,12],
[58,38,19,15],
[58,38,19,17],
[58,38,19,18],
[58,38,20,0],
[58,38,20,1],
[58,38,20,2],
[58,38,20,3],
[58,38,20,4],
[58,38,20,5],
[58,38,20,6],
[58,38,20,8],
[58,38,20,9],
[58,38,20,11],
[58,38,20,12],
[58,38,20,13],
[58,38,20,14],
[58,38,20,15],
[58,38,20,17],
[58,38,20,18],
[58,38,20,19],
[58,38,21,0],
[58,38,21,1],
[58,38,21,2],
[58,38,21,4],
[58,38,21,9],
[58,38,21,10],
[58,38,21,11],
[58,38,21,12],
[58,38,21,13],
[58,38,21,14],
[58,38,21,15],
[58,38,21,17],
[58,38,21,18],
[58,38,21,19],
[58,38,22,0],
[58,38,22,1],
[58,38,22,2],
[58,38,22,3],
[58,38,22,4],
[58,38,22,5],
[58,38,22,6],
[58,38,22,8],
[58,38,22,9],
[58,38,22,11],
[58,38,22,12],
[58,38,22,13],
[58,38,22,14],
[58,38,22,15],
[58,38,22,17],
[58,38,22,18],
[58,38,22,21],
[58,38,23,0],
[58,38,23,1],
[58,38,23,2],
[58,38,23,3],
[58,38,23,4],
[58,38,23,5],
[58,38,23,6],
[58,38,23,8],
[58,38,23,9],
[58,38,23,10],
[58,38,23,11],
[58,38,23,12],
[58,38,23,13],
[58,38,23,14],
[58,38,23,15],
[58,38,23,17],
[58,38,23,19],
[58,38,23,20],
[58,38,23,21],
[58,38,23,22],
[58,38,26,0],
[58,38,26,1],
[58,38,26,2],
[58,38,26,3],
[58,38,26,4],
[58,38,26,5],
[58,38,26,6],
[58,38,26,8],
[58,38,26,10],
[58,38,26,11],
[58,38,26,12],
[58,38,26,13],
[58,38,26,17],
[58,38,26,19],
[58,38,26,20],
[58,38,26,21],
[58,38,26,22],
[58,38,26,23],
[58,38,27,0],
[58,38,27,1],
[58,38,27,2],
[58,38,27,3],
[58,38,27,4],
[58,38,27,5],
[58,38,27,6],
[58,38,27,8],
[58,38,27,9],
[58,38,27,11],
[58,38,27,14],
[58,38,27,15],
[58,38,27,17],
[58,38,27,18],
[58,38,27,19],
[58,38,27,21],
[58,38,27,23],
[58,38,27,26],
[58,38,29,0],
[58,38,29,1],
[58,38,29,2],
[58,38,29,3],
[58,38,29,4],
[58,38,29,5],
[58,38,29,6],
[58,38,29,10],
[58,38,29,13],
[58,38,29,14],
[58,38,29,15],
[58,38,29,17],
[58,38,29,18],
[58,38,29,19],
[58,38,29,20],
[58,38,29,21],
[58,38,29,22],
[58,38,29,23],
[58,38,30,0],
[58,38,30,1],
[58,38,30,2],
[58,38,30,3],
[58,38,30,4],
[58,38,30,5],
[58,38,30,8],
[58,38,30,9],
[58,38,30,10],
[58,38,30,11],
[58,38,30,12],
[58,38,30,13],
[58,38,30,14],
[58,38,30,15],
[58,38,30,17],
[58,38,30,18],
[58,38,30,19],
[58,38,30,20],
[58,38,30,21],
[58,38,30,22],
[58,38,31,0],
[58,38,31,1],
[58,38,31,2],
[58,38,31,3],
[58,38,31,4],
[58,38,31,6],
[58,38,31,8],
[58,38,31,9],
[58,38,31,10],
[58,38,31,11],
[58,38,31,12],
[58,38,31,13],
[58,38,31,14],
[58,38,31,15],
[58,38,31,17],
[58,38,31,18],
[58,38,31,19],
[58,38,31,20],
[58,38,31,21],
[58,38,31,22],
[58,38,32,0],
[58,38,32,1],
[58,38,32,2],
[58,38,32,5],
[58,38,32,6],
[58,38,32,8],
[58,38,32,9],
[58,38,32,10],
[58,38,32,13],
[58,38,32,14],
[58,38,32,15],
[58,38,32,17],
[58,38,32,18],
[58,38,32,19],
[58,38,32,20],
[58,38,32,21],
[58,38,33,3],
[58,38,33,4],
[58,38,33,5],
[58,38,33,6],
[58,38,33,8],
[58,38,33,9],
[58,38,33,10],
[58,38,33,13],
[58,38,33,14],
[58,38,33,15],
[58,38,33,17],
[58,38,33,18],
[58,38,33,19],
[58,38,33,20],
[58,38,35,0],
[58,38,35,1],
[58,38,35,2],
[58,38,35,3],
[58,38,35,4],
[58,38,35,5],
[58,38,35,6],
[58,38,35,8],
[58,38,35,10],
[58,38,35,11],
[58,38,35,12],
[58,38,35,13],
[58,38,35,14],
[58,38,35,17],
[58,38,36,0],
[58,38,36,1],
[58,38,36,2],
[58,38,36,3],
[58,38,36,4],
[58,38,36,5],
[58,38,36,6],
[58,38,36,8],
[58,38,36,9],
[58,38,36,10],
[58,38,36,11],
[58,38,36,12],
[58,38,36,15],
[58,38,36,17],
[58,38,37,0],
[58,38,37,1],
[58,38,37,2],
[58,38,37,3],
[58,38,37,5],
[58,38,37,6],
[58,38,37,8],
[58,38,37,9],
[58,38,37,10],
[58,38,37,11],
[58,38,37,12],
[58,38,37,13],
[58,38,37,14],
[58,38,37,15],
[58,39,4,0],
[58,39,4,1],
[58,39,4,2],
[58,39,7,0],
[58,39,7,1],
[58,39,7,2],
[58,39,7,4],
[58,39,9,0],
[58,39,9,1],
[58,39,9,2],
[58,39,9,4],
[58,39,9,7],
[58,39,10,0],
[58,39,10,1],
[58,39,10,2],
[58,39,10,4],
[58,39,10,7],
[58,39,10,9],
[58,39,11,0],
[58,39,11,1],
[58,39,11,2],
[58,39,11,4],
[58,39,11,7],
[58,39,11,9],
[58,39,11,10],
[58,39,12,0],
[58,39,12,1],
[58,39,12,2],
[58,39,12,4],
[58,39,12,7],
[58,39,12,9],
[58,39,12,10],
[58,39,13,0],
[58,39,13,1],
[58,39,13,2],
[58,39,13,4],
[58,39,13,7],
[58,39,13,9],
[58,39,13,10],
[58,39,13,11],
[58,39,13,12],
[58,39,14,0],
[58,39,14,1],
[58,39,14,2],
[58,39,14,4],
[58,39,14,7],
[58,39,14,9],
[58,39,14,10],
[58,39,14,11],
[58,39,14,12],
[58,39,15,0],
[58,39,15,1],
[58,39,15,2],
[58,39,15,4],
[58,39,15,7],
[58,39,15,10],
[58,39,15,11],
[58,39,15,12],
[58,39,15,13],
[58,39,15,14],
[58,39,17,0],
[58,39,17,1],
[58,39,17,2],
[58,39,17,4],
[58,39,17,7],
[58,39,17,9],
[58,39,17,10],
[58,39,17,11],
[58,39,17,12],
[58,39,17,13],
[58,39,17,14],
[58,39,17,15],
[58,39,18,0],
[58,39,18,1],
[58,39,18,2],
[58,39,18,4],
[58,39,18,7],
[58,39,18,10],
[58,39,18,11],
[58,39,18,12],
[58,39,18,13],
[58,39,18,14],
[58,39,18,17],
[58,39,19,0],
[58,39,19,1],
[58,39,19,2],
[58,39,19,4],
[58,39,19,7],
[58,39,19,9],
[58,39,19,10],
[58,39,19,11],
[58,39,19,12],
[58,39,19,15],
[58,39,19,17],
[58,39,19,18],
[58,39,20,0],
[58,39,20,1],
[58,39,20,2],
[58,39,20,4],
[58,39,20,7],
[58,39,20,9],
[58,39,20,11],
[58,39,20,12],
[58,39,20,13],
[58,39,20,14],
[58,39,20,15],
[58,39,20,17],
[58,39,20,18],
[58,39,20,19],
[58,39,22,0],
[58,39,22,1],
[58,39,22,2],
[58,39,22,4],
[58,39,22,7],
[58,39,22,9],
[58,39,22,11],
[58,39,22,12],
[58,39,22,13],
[58,39,22,14],
[58,39,22,15],
[58,39,22,17],
[58,39,22,19],
[58,39,23,0],
[58,39,23,1],
[58,39,23,2],
[58,39,23,4],
[58,39,23,7],
[58,39,23,9],
[58,39,23,10],
[58,39,23,11],
[58,39,23,12],
[58,39,23,13],
[58,39,23,14],
[58,39,23,15],
[58,39,23,18],
[58,39,23,19],
[58,39,23,20],
[58,39,23,22],
[58,39,25,0],
[58,39,25,1],
[58,39,25,2],
[58,39,25,4],
[58,39,25,9],
[58,39,25,10],
[58,39,25,11],
[58,39,25,12],
[58,39,25,13],
[58,39,25,15],
[58,39,25,17],
[58,39,25,18],
[58,39,25,19],
[58,39,25,20],
[58,39,25,22],
[58,39,25,23],
[58,39,26,0],
[58,39,26,1],
[58,39,26,2],
[58,39,26,4],
[58,39,26,7],
[58,39,26,10],
[58,39,26,11],
[58,39,26,14],
[58,39,26,17],
[58,39,26,19],
[58,39,26,20],
[58,39,26,22],
[58,39,26,23],
[58,39,26,25],
[58,39,27,0],
[58,39,27,1],
[58,39,27,2],
[58,39,27,4],
[58,39,27,7],
[58,39,27,9],
[58,39,27,12],
[58,39,27,13],
[58,39,27,14],
[58,39,27,15],
[58,39,27,17],
[58,39,27,18],
[58,39,27,19],
[58,39,27,23],
[58,39,27,25],
[58,39,28,0],
[58,39,28,1],
[58,39,28,2],
[58,39,28,4],
[58,39,28,10],
[58,39,28,11],
[58,39,28,12],
[58,39,28,13],
[58,39,28,14],
[58,39,28,15],
[58,39,28,17],
[58,39,28,18],
[58,39,28,19],
[58,39,28,20],
[58,39,28,22],
[58,39,28,23],
[58,39,29,0],
[58,39,29,1],
[58,39,29,2],
[58,39,29,4],
[58,39,29,9],
[58,39,29,10],
[58,39,29,13],
[58,39,29,14],
[58,39,29,15],
[58,39,29,17],
[58,39,29,18],
[58,39,29,19],
[58,39,29,20],
[58,39,29,22],
[58,39,29,23],
[58,39,30,0],
[58,39,30,1],
[58,39,30,2],
[58,39,30,4],
[58,39,30,7],
[58,39,30,9],
[58,39,30,10],
[58,39,30,11],
[58,39,30,12],
[58,39,30,13],
[58,39,30,14],
[58,39,30,15],
[58,39,30,17],
[58,39,30,18],
[58,39,30,19],
[58,39,30,20],
[58,39,30,22],
[58,39,31,0],
[58,39,31,1],
[58,39,31,2],
[58,39,31,7],
[58,39,31,9],
[58,39,31,10],
[58,39,31,11],
[58,39,31,12],
[58,39,31,13],
[58,39,31,14],
[58,39,31,15],
[58,39,31,17],
[58,39,31,18],
[58,39,31,19],
[58,39,31,20],
[58,39,32,4],
[58,39,32,7],
[58,39,32,9],
[58,39,32,10],
[58,39,32,13],
[58,39,32,14],
[58,39,32,15],
[58,39,32,17],
[58,39,32,18],
[58,39,32,19],
[58,39,32,20],
[58,39,33,0],
[58,39,33,1],
[58,39,33,2],
[58,39,33,4],
[58,39,33,7],
[58,39,33,9],
[58,39,33,10],
[58,39,33,13],
[58,39,33,14],
[58,39,33,15],
[58,39,33,17],
[58,39,33,18],
[58,39,33,19],
[58,39,34,0],
[58,39,34,1],
[58,39,34,2],
[58,39,34,4],
[58,39,34,9],
[58,39,34,10],
[58,39,34,11],
[58,39,34,12],
[58,39,34,13],
[58,39,34,14],
[58,39,34,15],
[58,39,34,17],
[58,39,34,18],
[58,39,35,0],
[58,39,35,1],
[58,39,35,2],
[58,39,35,4],
[58,39,35,7],
[58,39,35,10],
[58,39,35,11],
[58,39,35,12],
[58,39,35,13],
[58,39,35,14],
[58,39,35,17],
[58,39,36,0],
[58,39,36,1],
[58,39,36,2],
[58,39,36,4],
[58,39,36,7],
[58,39,36,9],
[58,39,36,10],
[58,39,36,11],
[58,39,36,12],
[58,39,36,15],
[58,39,37,0],
[58,39,37,1],
[58,39,37,2],
[58,39,37,7],
[58,39,37,9],
[58,39,37,10],
[58,39,37,11],
[58,39,37,12],
[58,39,37,13],
[58,39,37,14],
[58,39,38,0],
[58,39,38,1],
[58,39,38,2],
[58,39,38,4],
[58,39,38,9],
[58,39,38,10],
[58,39,38,11],
[58,39,38,12],
[58,39,38,13],
[58,40,3,0],
[58,40,3,1],
[58,40,3,2],
[58,40,4,0],
[58,40,4,1],
[58,40,4,2],
[58,40,4,3],
[58,40,5,0],
[58,40,5,1],
[58,40,5,2],
[58,40,5,4],
[58,40,6,0],
[58,40,6,1],
[58,40,6,2],
[58,40,6,4],
[58,40,7,0],
[58,40,7,1],
[58,40,7,2],
[58,40,7,3],
[58,40,7,4],
[58,40,7,5],
[58,40,7,6],
[58,40,8,0],
[58,40,8,1],
[58,40,8,2],
[58,40,8,4],
[58,40,8,7],
[58,40,10,0],
[58,40,10,1],
[58,40,10,2],
[58,40,10,3],
[58,40,10,4],
[58,40,10,5],
[58,40,10,6],
[58,40,10,7],
[58,40,10,8],
[58,40,11,0],
[58,40,11,1],
[58,40,11,2],
[58,40,11,3],
[58,40,11,4],
[58,40,11,5],
[58,40,11,6],
[58,40,11,7],
[58,40,11,8],
[58,40,11,10],
[58,40,12,0],
[58,40,12,1],
[58,40,12,2],
[58,40,12,3],
[58,40,12,4],
[58,40,12,5],
[58,40,12,6],
[58,40,12,7],
[58,40,12,8],
[58,40,12,10],
[58,40,13,0],
[58,40,13,1],
[58,40,13,2],
[58,40,13,3],
[58,40,13,4],
[58,40,13,5],
[58,40,13,6],
[58,40,13,7],
[58,40,13,8],
[58,40,13,10],
[58,40,13,11],
[58,40,13,12],
[58,40,14,0],
[58,40,14,1],
[58,40,14,2],
[58,40,14,3],
[58,40,14,4],
[58,40,14,5],
[58,40,14,6],
[58,40,14,7],
[58,40,14,8],
[58,40,14,10],
[58,40,14,11],
[58,40,14,12],
[58,40,17,0],
[58,40,17,1],
[58,40,17,2],
[58,40,17,3],
[58,40,17,4],
[58,40,17,5],
[58,40,17,6],
[58,40,17,7],
[58,40,17,8],
[58,40,17,10],
[58,40,17,11],
[58,40,17,12],
[58,40,17,13],
[58,40,17,14],
[58,40,19,0],
[58,40,19,1],
[58,40,19,2],
[58,40,19,3],
[58,40,19,4],
[58,40,19,5],
[58,40,19,6],
[58,40,19,7],
[58,40,19,8],
[58,40,19,10],
[58,40,19,11],
[58,40,19,12],
[58,40,19,17],
[58,40,20,0],
[58,40,20,1],
[58,40,20,2],
[58,40,20,3],
[58,40,20,4],
[58,40,20,5],
[58,40,20,6],
[58,40,20,7],
[58,40,20,8],
[58,40,20,11],
[58,40,20,12],
[58,40,20,13],
[58,40,20,14],
[58,40,20,17],
[58,40,21,0],
[58,40,21,1],
[58,40,21,2],
[58,40,21,4],
[58,40,21,7],
[58,40,21,10],
[58,40,21,11],
[58,40,21,12],
[58,40,21,13],
[58,40,21,14],
[58,40,21,17],
[58,40,21,19],
[58,40,21,20],
[58,40,22,0],
[58,40,22,1],
[58,40,22,2],
[58,40,22,3],
[58,40,22,4],
[58,40,22,5],
[58,40,22,6],
[58,40,22,7],
[58,40,22,8],
[58,40,22,11],
[58,40,22,12],
[58,40,22,13],
[58,40,22,14],
[58,40,22,19],
[58,40,22,21],
[58,40,23,0],
[58,40,23,1],
[58,40,23,2],
[58,40,23,3],
[58,40,23,4],
[58,40,23,5],
[58,40,23,6],
[58,40,23,7],
[58,40,23,8],
[58,40,23,10],
[58,40,23,11],
[58,40,23,12],
[58,40,23,13],
[58,40,23,14],
[58,40,23,17],
[58,40,23,19],
[58,40,23,20],
[58,40,23,21],
[58,40,23,22],
[58,40,25,0],
[58,40,25,1],
[58,40,25,2],
[58,40,25,3],
[58,40,25,4],
[58,40,25,5],
[58,40,25,6],
[58,40,25,8],
[58,40,25,10],
[58,40,25,11],
[58,40,25,14],
[58,40,25,17],
[58,40,25,19],
[58,40,25,20],
[58,40,25,21],
[58,40,25,22],
[58,40,25,23],
[58,40,27,0],
[58,40,27,1],
[58,40,27,2],
[58,40,27,3],
[58,40,27,4],
[58,40,27,5],
[58,40,27,6],
[58,40,27,7],
[58,40,27,11],
[58,40,27,12],
[58,40,27,13],
[58,40,27,14],
[58,40,27,17],
[58,40,27,19],
[58,40,27,21],
[58,40,27,23],
[58,40,28,0],
[58,40,28,1],
[58,40,28,2],
[58,40,28,3],
[58,40,28,4],
[58,40,28,5],
[58,40,28,8],
[58,40,28,10],
[58,40,28,11],
[58,40,28,12],
[58,40,28,13],
[58,40,28,14],
[58,40,28,17],
[58,40,28,19],
[58,40,28,20],
[58,40,28,21],
[58,40,28,22],
[58,40,28,23],
[58,40,29,0],
[58,40,29,1],
[58,40,29,2],
[58,40,29,3],
[58,40,29,4],
[58,40,29,6],
[58,40,29,7],
[58,40,29,8],
[58,40,29,10],
[58,40,29,13],
[58,40,29,14],
[58,40,29,17],
[58,40,29,19],
[58,40,29,20],
[58,40,29,21],
[58,40,29,22],
[58,40,30,0],
[58,40,30,1],
[58,40,30,2],
[58,40,30,5],
[58,40,30,6],
[58,40,30,7],
[58,40,30,8],
[58,40,30,10],
[58,40,30,11],
[58,40,30,12],
[58,40,30,13],
[58,40,30,14],
[58,40,30,17],
[58,40,30,19],
[58,40,30,20],
[58,40,30,21],
[58,40,31,3],
[58,40,31,4],
[58,40,31,5],
[58,40,31,6],
[58,40,31,7],
[58,40,31,8],
[58,40,31,10],
[58,40,31,11],
[58,40,31,12],
[58,40,31,13],
[58,40,31,14],
[58,40,31,17],
[58,40,31,19],
[58,40,31,20],
[58,40,32,0],
[58,40,32,1],
[58,40,32,2],
[58,40,32,3],
[58,40,32,4],
[58,40,32,5],
[58,40,32,6],
[58,40,32,7],
[58,40,32,8],
[58,40,32,10],
[58,40,32,13],
[58,40,32,14],
[58,40,32,17],
[58,40,32,19],
[58,40,33,0],
[58,40,33,1],
[58,40,33,2],
[58,40,33,3],
[58,40,33,4],
[58,40,33,5],
[58,40,33,6],
[58,40,33,7],
[58,40,33,8],
[58,40,33,10],
[58,40,33,13],
[58,40,33,14],
[58,40,33,17],
[58,40,34,0],
[58,40,34,1],
[58,40,34,2],
[58,40,34,3],
[58,40,34,4],
[58,40,34,5],
[58,40,34,6],
[58,40,34,8],
[58,40,34,10],
[58,40,34,11],
[58,40,34,12],
[58,40,34,13],
[58,40,34,14],
[58,40,34,17],
[58,40,36,0],
[58,40,36,1],
[58,40,36,2],
[58,40,36,3],
[58,40,36,4],
[58,40,36,5],
[58,40,36,6],
[58,40,36,7],
[58,40,36,8],
[58,40,36,10],
[58,40,36,11],
[58,40,36,12],
[58,40,37,0],
[58,40,37,1],
[58,40,37,2],
[58,40,37,3],
[58,40,37,5],
[58,40,37,6],
[58,40,37,7],
[58,40,37,8],
[58,40,37,10],
[58,40,37,11],
[58,40,37,12],
[58,40,37,13],
[58,40,38,0],
[58,40,38,1],
[58,40,38,2],
[58,40,38,3],
[58,40,38,4],
[58,40,38,5],
[58,40,38,6],
[58,40,38,8],
[58,40,38,10],
[58,40,38,11],
[58,40,39,0],
[58,40,39,1],
[58,40,39,2],
[58,40,39,4],
[58,40,39,7],
[58,41,3,0],
[58,41,3,1],
[58,41,3,2],
[58,41,4,0],
[58,41,4,1],
[58,41,4,2],
[58,41,4,3],
[58,41,5,0],
[58,41,5,1],
[58,41,5,2],
[58,41,5,4],
[58,41,6,0],
[58,41,6,1],
[58,41,6,2],
[58,41,6,4],
[58,41,7,0],
[58,41,7,1],
[58,41,7,2],
[58,41,7,3],
[58,41,7,4],
[58,41,7,5],
[58,41,7,6],
[58,41,8,0],
[58,41,8,1],
[58,41,8,2],
[58,41,8,4],
[58,41,8,7],
[58,41,10,0],
[58,41,10,1],
[58,41,10,2],
[58,41,10,3],
[58,41,10,4],
[58,41,10,5],
[58,41,10,6],
[58,41,10,7],
[58,41,10,8],
[58,41,11,0],
[58,41,11,1],
[58,41,11,2],
[58,41,11,3],
[58,41,11,4],
[58,41,11,5],
[58,41,11,6],
[58,41,11,7],
[58,41,11,8],
[58,41,11,10],
[58,41,12,0],
[58,41,12,1],
[58,41,12,2],
[58,41,12,3],
[58,41,12,4],
[58,41,12,5],
[58,41,12,6],
[58,41,12,7],
[58,41,12,8],
[58,41,12,10],
[58,41,13,0],
[58,41,13,1],
[58,41,13,2],
[58,41,13,3],
[58,41,13,4],
[58,41,13,5],
[58,41,13,6],
[58,41,13,7],
[58,41,13,8],
[58,41,13,10],
[58,41,13,11],
[58,41,13,12],
[58,41,14,0],
[58,41,14,1],
[58,41,14,2],
[58,41,14,3],
[58,41,14,4],
[58,41,14,5],
[58,41,14,6],
[58,41,14,7],
[58,41,14,8],
[58,41,14,10],
[58,41,14,11],
[58,41,14,12],
[58,41,17,0],
[58,41,17,1],
[58,41,17,2],
[58,41,17,3],
[58,41,17,4],
[58,41,17,5],
[58,41,17,6],
[58,41,17,7],
[58,41,17,8],
[58,41,17,10],
[58,41,17,11],
[58,41,17,12],
[58,41,17,13],
[58,41,17,14],
[58,41,19,0],
[58,41,19,1],
[58,41,19,2],
[58,41,19,3],
[58,41,19,4],
[58,41,19,5],
[58,41,19,6],
[58,41,19,7],
[58,41,19,8],
[58,41,19,10],
[58,41,19,11],
[58,41,19,12],
[58,41,19,17],
[58,41,20,0],
[58,41,20,1],
[58,41,20,2],
[58,41,20,3],
[58,41,20,4],
[58,41,20,5],
[58,41,20,6],
[58,41,20,7],
[58,41,20,8],
[58,41,20,11],
[58,41,20,12],
[58,41,20,13],
[58,41,20,14],
[58,41,20,17],
[58,41,21,0],
[58,41,21,1],
[58,41,21,2],
[58,41,21,4],
[58,41,21,7],
[58,41,21,10],
[58,41,21,11],
[58,41,21,12],
[58,41,21,13],
[58,41,21,14],
[58,41,21,17],
[58,41,21,19],
[58,41,21,20],
[58,41,22,0],
[58,41,22,1],
[58,41,22,2],
[58,41,22,3],
[58,41,22,4],
[58,41,22,5],
[58,41,22,6],
[58,41,22,7],
[58,41,22,8],
[58,41,22,11],
[58,41,22,12],
[58,41,22,13],
[58,41,22,14],
[58,41,22,19],
[58,41,22,21],
[58,41,23,0],
[58,41,23,1],
[58,41,23,2],
[58,41,23,3],
[58,41,23,4],
[58,41,23,5],
[58,41,23,6],
[58,41,23,7],
[58,41,23,8],
[58,41,23,10],
[58,41,23,11],
[58,41,23,12],
[58,41,23,13],
[58,41,23,14],
[58,41,23,17],
[58,41,23,19],
[58,41,23,20],
[58,41,23,21],
[58,41,23,22],
[58,41,25,0],
[58,41,25,1],
[58,41,25,2],
[58,41,25,3],
[58,41,25,4],
[58,41,25,5],
[58,41,25,6],
[58,41,25,8],
[58,41,25,10],
[58,41,25,11],
[58,41,25,14],
[58,41,25,17],
[58,41,25,19],
[58,41,25,20],
[58,41,25,21],
[58,41,25,22],
[58,41,25,23],
[58,41,27,0],
[58,41,27,1],
[58,41,27,2],
[58,41,27,3],
[58,41,27,4],
[58,41,27,5],
[58,41,27,6],
[58,41,27,7],
[58,41,27,11],
[58,41,27,12],
[58,41,27,13],
[58,41,27,14],
[58,41,27,17],
[58,41,27,19],
[58,41,27,21],
[58,41,27,23],
[58,41,28,0],
[58,41,28,1],
[58,41,28,2],
[58,41,28,3],
[58,41,28,4],
[58,41,28,5],
[58,41,28,8],
[58,41,28,10],
[58,41,28,11],
[58,41,28,12],
[58,41,28,13],
[58,41,28,14],
[58,41,28,17],
[58,41,28,19],
[58,41,28,20],
[58,41,28,21],
[58,41,28,22],
[58,41,28,23],
[58,41,29,0],
[58,41,29,1],
[58,41,29,2],
[58,41,29,3],
[58,41,29,4],
[58,41,29,6],
[58,41,29,7],
[58,41,29,8],
[58,41,29,10],
[58,41,29,13],
[58,41,29,14],
[58,41,29,17],
[58,41,29,19],
[58,41,29,20],
[58,41,29,21],
[58,41,29,22],
[58,41,30,0],
[58,41,30,1],
[58,41,30,2],
[58,41,30,5],
[58,41,30,6],
[58,41,30,7],
[58,41,30,8],
[58,41,30,10],
[58,41,30,11],
[58,41,30,12],
[58,41,30,13],
[58,41,30,14],
[58,41,30,17],
[58,41,30,19],
[58,41,30,20],
[58,41,30,21],
[58,41,31,3],
[58,41,31,4],
[58,41,31,5],
[58,41,31,6],
[58,41,31,7],
[58,41,31,8],
[58,41,31,10],
[58,41,31,11],
[58,41,31,12],
[58,41,31,13],
[58,41,31,14],
[58,41,31,17],
[58,41,31,19],
[58,41,31,20],
[58,41,32,0],
[58,41,32,1],
[58,41,32,2],
[58,41,32,3],
[58,41,32,4],
[58,41,32,5],
[58,41,32,6],
[58,41,32,7],
[58,41,32,8],
[58,41,32,10],
[58,41,32,13],
[58,41,32,14],
[58,41,32,17],
[58,41,32,19],
[58,41,33,0],
[58,41,33,1],
[58,41,33,2],
[58,41,33,3],
[58,41,33,4],
[58,41,33,5],
[58,41,33,6],
[58,41,33,7],
[58,41,33,8],
[58,41,33,10],
[58,41,33,13],
[58,41,33,14],
[58,41,33,17],
[58,41,34,0],
[58,41,34,1],
[58,41,34,2],
[58,41,34,3],
[58,41,34,4],
[58,41,34,5],
[58,41,34,6],
[58,41,34,8],
[58,41,34,10],
[58,41,34,11],
[58,41,34,12],
[58,41,34,13],
[58,41,34,14],
[58,41,34,17],
[58,41,36,0],
[58,41,36,1],
[58,41,36,2],
[58,41,36,3],
[58,41,36,4],
[58,41,36,5],
[58,41,36,6],
[58,41,36,7],
[58,41,36,8],
[58,41,36,10],
[58,41,36,11],
[58,41,36,12],
[58,41,37,0],
[58,41,37,1],
[58,41,37,2],
[58,41,37,3],
[58,41,37,5],
[58,41,37,6],
[58,41,37,7],
[58,41,37,8],
[58,41,37,10],
[58,41,37,11],
[58,41,37,12],
[58,41,37,13],
[58,41,38,0],
[58,41,38,1],
[58,41,38,2],
[58,41,38,3],
[58,41,38,4],
[58,41,38,5],
[58,41,38,6],
[58,41,38,8],
[58,41,38,10],
[58,41,38,11],
[58,41,39,0],
[58,41,39,1],
[58,41,39,2],
[58,41,39,4],
[58,41,39,7],
[58,42,3,0],
[58,42,3,1],
[58,42,3,2],
[58,42,4,0],
[58,42,4,1],
[58,42,4,2],
[58,42,4,3],
[58,42,5,0],
[58,42,5,1],
[58,42,5,2],
[58,42,5,4],
[58,42,6,0],
[58,42,6,1],
[58,42,6,2],
[58,42,6,4],
[58,42,7,0],
[58,42,7,1],
[58,42,7,2],
[58,42,7,3],
[58,42,7,4],
[58,42,7,5],
[58,42,7,6],
[58,42,8,0],
[58,42,8,1],
[58,42,8,2],
[58,42,8,4],
[58,42,8,7],
[58,42,9,0],
[58,42,9,1],
[58,42,9,2],
[58,42,9,3],
[58,42,9,4],
[58,42,9,5],
[58,42,9,6],
[58,42,9,7],
[58,42,9,8],
[58,42,11,0],
[58,42,11,1],
[58,42,11,2],
[58,42,11,3],
[58,42,11,4],
[58,42,11,5],
[58,42,11,6],
[58,42,11,7],
[58,42,11,8],
[58,42,11,9],
[58,42,12,0],
[58,42,12,1],
[58,42,12,2],
[58,42,12,3],
[58,42,12,4],
[58,42,12,5],
[58,42,12,6],
[58,42,12,7],
[58,42,12,8],
[58,42,12,9],
[58,42,13,0],
[58,42,13,1],
[58,42,13,2],
[58,42,13,3],
[58,42,13,4],
[58,42,13,5],
[58,42,13,6],
[58,42,13,7],
[58,42,13,8],
[58,42,13,9],
[58,42,13,11],
[58,42,13,12],
[58,42,14,0],
[58,42,14,1],
[58,42,14,2],
[58,42,14,3],
[58,42,14,4],
[58,42,14,5],
[58,42,14,6],
[58,42,14,7],
[58,42,14,8],
[58,42,14,9],
[58,42,14,11],
[58,42,14,12],
[58,42,15,0],
[58,42,15,1],
[58,42,15,2],
[58,42,15,3],
[58,42,15,4],
[58,42,15,5],
[58,42,15,6],
[58,42,15,7],
[58,42,15,8],
[58,42,15,11],
[58,42,15,12],
[58,42,15,13],
[58,42,15,14],
[58,42,17,0],
[58,42,17,1],
[58,42,17,2],
[58,42,17,3],
[58,42,17,4],
[58,42,17,5],
[58,42,17,6],
[58,42,17,7],
[58,42,17,8],
[58,42,17,9],
[58,42,17,11],
[58,42,17,12],
[58,42,17,13],
[58,42,17,14],
[58,42,17,15],
[58,42,18,0],
[58,42,18,1],
[58,42,18,2],
[58,42,18,3],
[58,42,18,4],
[58,42,18,5],
[58,42,18,6],
[58,42,18,7],
[58,42,18,8],
[58,42,18,11],
[58,42,18,12],
[58,42,18,13],
[58,42,18,14],
[58,42,18,17],
[58,42,19,0],
[58,42,19,1],
[58,42,19,2],
[58,42,19,3],
[58,42,19,4],
[58,42,19,5],
[58,42,19,6],
[58,42,19,7],
[58,42,19,8],
[58,42,19,9],
[58,42,19,11],
[58,42,19,12],
[58,42,19,15],
[58,42,19,17],
[58,42,19,18],
[58,42,21,0],
[58,42,21,1],
[58,42,21,2],
[58,42,21,4],
[58,42,21,7],
[58,42,21,9],
[58,42,21,11],
[58,42,21,12],
[58,42,21,13],
[58,42,21,14],
[58,42,21,15],
[58,42,21,18],
[58,42,21,19],
[58,42,23,0],
[58,42,23,1],
[58,42,23,2],
[58,42,23,3],
[58,42,23,4],
[58,42,23,5],
[58,42,23,6],
[58,42,23,7],
[58,42,23,8],
[58,42,23,9],
[58,42,23,11],
[58,42,23,12],
[58,42,23,13],
[58,42,23,15],
[58,42,23,17],
[58,42,23,18],
[58,42,23,19],
[58,42,23,21],
[58,42,25,0],
[58,42,25,1],
[58,42,25,2],
[58,42,25,3],
[58,42,25,4],
[58,42,25,5],
[58,42,25,6],
[58,42,25,8],
[58,42,25,9],
[58,42,25,12],
[58,42,25,13],
[58,42,25,14],
[58,42,25,15],
[58,42,25,17],
[58,42,25,18],
[58,42,25,19],
[58,42,25,21],
[58,42,25,23],
[58,42,26,0],
[58,42,26,1],
[58,42,26,2],
[58,42,26,3],
[58,42,26,4],
[58,42,26,5],
[58,42,26,6],
[58,42,26,7],
[58,42,26,11],
[58,42,26,12],
[58,42,26,13],
[58,42,26,14],
[58,42,26,17],
[58,42,26,19],
[58,42,26,21],
[58,42,26,23],
[58,42,28,0],
[58,42,28,1],
[58,42,28,2],
[58,42,28,3],
[58,42,28,4],
[58,42,28,6],
[58,42,28,8],
[58,42,28,9],
[58,42,28,11],
[58,42,28,12],
[58,42,28,13],
[58,42,28,14],
[58,42,28,15],
[58,42,28,17],
[58,42,28,18],
[58,42,28,19],
[58,42,28,21],
[58,42,29,0],
[58,42,29,1],
[58,42,29,2],
[58,42,29,5],
[58,42,29,6],
[58,42,29,7],
[58,42,29,8],
[58,42,29,9],
[58,42,29,13],
[58,42,29,14],
[58,42,29,15],
[58,42,29,17],
[58,42,29,18],
[58,42,29,19],
[58,42,29,21],
[58,42,30,3],
[58,42,30,4],
[58,42,30,5],
[58,42,30,6],
[58,42,30,7],
[58,42,30,8],
[58,42,30,9],
[58,42,30,11],
[58,42,30,12],
[58,42,30,13],
[58,42,30,14],
[58,42,30,15],
[58,42,30,17],
[58,42,30,18],
[58,42,30,19],
[58,42,31,0],
[58,42,31,1],
[58,42,31,2],
[58,42,31,3],
[58,42,31,4],
[58,42,31,5],
[58,42,31,6],
[58,42,31,7],
[58,42,31,8],
[58,42,31,9],
[58,42,31,11],
[58,42,31,12],
[58,42,31,13],
[58,42,31,14],
[58,42,31,15],
[58,42,31,17],
[58,42,31,18],
[58,42,31,19],
[58,42,32,0],
[58,42,32,1],
[58,42,32,2],
[58,42,32,3],
[58,42,32,4],
[58,42,32,5],
[58,42,32,6],
[58,42,32,7],
[58,42,32,8],
[58,42,32,9],
[58,42,32,13],
[58,42,32,14],
[58,42,32,15],
[58,42,32,17],
[58,42,32,18],
[58,42,33,0],
[58,42,33,1],
[58,42,33,2],
[58,42,33,3],
[58,42,33,4],
[58,42,33,5],
[58,42,33,6],
[58,42,33,7],
[58,42,33,8],
[58,42,33,9],
[58,42,33,13],
[58,42,33,14],
[58,42,33,15],
[58,42,33,17],
[58,42,34,0],
[58,42,34,1],
[58,42,34,2],
[58,42,34,3],
[58,42,34,4],
[58,42,34,5],
[58,42,34,6],
[58,42,34,8],
[58,42,34,9],
[58,42,34,11],
[58,42,34,12],
[58,42,34,13],
[58,42,34,14],
[58,42,34,15],
[58,42,35,0],
[58,42,35,1],
[58,42,35,2],
[58,42,35,3],
[58,42,35,4],
[58,42,35,5],
[58,42,35,6],
[58,42,35,7],
[58,42,35,8],
[58,42,35,11],
[58,42,35,12],
[58,42,35,13],
[58,42,35,14],
[58,42,36,0],
[58,42,36,1],
[58,42,36,2],
[58,42,36,3],
[58,42,36,4],
[58,42,36,5],
[58,42,36,6],
[58,42,36,7],
[58,42,36,8],
[58,42,36,9],
[58,42,36,11],
[58,42,36,12],
[58,42,37,0],
[58,42,37,1],
[58,42,37,2],
[58,42,37,3],
[58,42,37,5],
[58,42,37,6],
[58,42,37,7],
[58,42,37,8],
[58,42,37,9],
[58,42,37,11],
[58,42,38,0],
[58,42,38,1],
[58,42,38,2],
[58,42,38,3],
[58,42,38,4],
[58,42,38,5],
[58,42,38,6],
[58,42,38,8],
[58,42,38,9],
[58,42,39,0],
[58,42,39,1],
[58,42,39,2],
[58,42,39,4],
[58,42,39,7],
[58,42,40,0],
[58,42,40,1],
[58,42,40,2],
[58,42,40,3],
[58,42,40,4],
[58,42,40,5],
[58,42,41,0],
[58,42,41,1],
[58,42,41,2],
[58,42,41,3],
[58,42,41,4],
[58,42,41,5],
[58,43,3,0],
[58,43,3,1],
[58,43,3,2],
[58,43,4,0],
[58,43,4,1],
[58,43,4,2],
[58,43,4,3],
[58,43,5,0],
[58,43,5,1],
[58,43,5,2],
[58,43,5,4],
[58,43,6,0],
[58,43,6,1],
[58,43,6,2],
[58,43,6,4],
[58,43,8,0],
[58,43,8,1],
[58,43,8,2],
[58,43,8,4],
[58,43,9,0],
[58,43,9,1],
[58,43,9,2],
[58,43,9,3],
[58,43,9,4],
[58,43,9,5],
[58,43,9,6],
[58,43,9,8],
[58,43,10,0],
[58,43,10,1],
[58,43,10,2],
[58,43,10,3],
[58,43,10,4],
[58,43,10,5],
[58,43,10,6],
[58,43,10,8],
[58,43,10,9],
[58,43,11,0],
[58,43,11,1],
[58,43,11,2],
[58,43,11,3],
[58,43,11,4],
[58,43,11,5],
[58,43,11,6],
[58,43,11,8],
[58,43,11,9],
[58,43,11,10],
[58,43,12,0],
[58,43,12,1],
[58,43,12,2],
[58,43,12,3],
[58,43,12,4],
[58,43,12,5],
[58,43,12,6],
[58,43,12,8],
[58,43,12,9],
[58,43,12,10],
[58,43,13,0],
[58,43,13,1],
[58,43,13,2],
[58,43,13,3],
[58,43,13,4],
[58,43,13,5],
[58,43,13,6],
[58,43,13,8],
[58,43,13,9],
[58,43,13,10],
[58,43,13,11],
[58,43,13,12],
[58,43,14,0],
[58,43,14,1],
[58,43,14,2],
[58,43,14,3],
[58,43,14,4],
[58,43,14,5],
[58,43,14,6],
[58,43,14,8],
[58,43,14,9],
[58,43,14,10],
[58,43,14,11],
[58,43,14,12],
[58,43,15,0],
[58,43,15,1],
[58,43,15,2],
[58,43,15,3],
[58,43,15,4],
[58,43,15,5],
[58,43,15,6],
[58,43,15,8],
[58,43,15,10],
[58,43,15,11],
[58,43,15,12],
[58,43,15,13],
[58,43,15,14],
[58,43,17,0],
[58,43,17,1],
[58,43,17,2],
[58,43,17,3],
[58,43,17,4],
[58,43,17,5],
[58,43,17,6],
[58,43,17,8],
[58,43,17,9],
[58,43,17,10],
[58,43,17,11],
[58,43,17,12],
[58,43,17,13],
[58,43,17,14],
[58,43,17,15],
[58,43,18,0],
[58,43,18,1],
[58,43,18,2],
[58,43,18,3],
[58,43,18,4],
[58,43,18,5],
[58,43,18,6],
[58,43,18,8],
[58,43,18,10],
[58,43,18,11],
[58,43,18,12],
[58,43,18,13],
[58,43,18,14],
[58,43,18,17],
[58,43,19,0],
[58,43,19,1],
[58,43,19,2],
[58,43,19,3],
[58,43,19,4],
[58,43,19,5],
[58,43,19,6],
[58,43,19,8],
[58,43,19,9],
[58,43,19,10],
[58,43,19,11],
[58,43,19,12],
[58,43,19,15],
[58,43,19,17],
[58,43,20,0],
[58,43,20,1],
[58,43,20,2],
[58,43,20,3],
[58,43,20,4],
[58,43,20,5],
[58,43,20,6],
[58,43,20,8],
[58,43,20,9],
[58,43,20,11],
[58,43,20,12],
[58,43,20,13],
[58,43,20,14],
[58,43,20,15],
[58,43,20,18],
[58,43,20,19],
[58,43,21,0],
[58,43,21,1],
[58,43,21,2],
[58,43,21,4],
[58,43,21,9],
[58,43,21,10],
[58,43,21,11],
[58,43,21,12],
[58,43,21,13],
[58,43,21,14],
[58,43,21,17],
[58,43,21,18],
[58,43,21,19],
[58,43,21,20],
[58,43,22,0],
[58,43,22,1],
[58,43,22,2],
[58,43,22,3],
[58,43,22,4],
[58,43,22,5],
[58,43,22,6],
[58,43,22,8],
[58,43,22,9],
[58,43,22,11],
[58,43,22,12],
[58,43,22,13],
[58,43,22,15],
[58,43,22,17],
[58,43,22,18],
[58,43,22,19],
[58,43,22,21],
[58,43,23,0],
[58,43,23,1],
[58,43,23,2],
[58,43,23,3],
[58,43,23,4],
[58,43,23,5],
[58,43,23,6],
[58,43,23,8],
[58,43,23,9],
[58,43,23,10],
[58,43,23,11],
[58,43,23,14],
[58,43,23,15],
[58,43,23,17],
[58,43,23,18],
[58,43,23,19],
[58,43,23,20],
[58,43,23,21],
[58,43,23,22],
[58,43,26,0],
[58,43,26,1],
[58,43,26,2],
[58,43,26,3],
[58,43,26,4],
[58,43,26,5],
[58,43,26,8],
[58,43,26,10],
[58,43,26,11],
[58,43,26,12],
[58,43,26,13],
[58,43,26,14],
[58,43,26,17],
[58,43,26,19],
[58,43,26,20],
[58,43,26,21],
[58,43,26,22],
[58,43,26,23],
[58,43,27,0],
[58,43,27,1],
[58,43,27,2],
[58,43,27,3],
[58,43,27,4],
[58,43,27,6],
[58,43,27,8],
[58,43,27,9],
[58,43,27,11],
[58,43,27,12],
[58,43,27,13],
[58,43,27,14],
[58,43,27,15],
[58,43,27,17],
[58,43,27,18],
[58,43,27,19],
[58,43,27,21],
[58,43,29,3],
[58,43,29,4],
[58,43,29,5],
[58,43,29,6],
[58,43,29,8],
[58,43,29,9],
[58,43,29,10],
[58,43,29,13],
[58,43,29,14],
[58,43,29,15],
[58,43,29,17],
[58,43,29,18],
[58,43,29,19],
[58,43,29,20],
[58,43,30,0],
[58,43,30,1],
[58,43,30,2],
[58,43,30,3],
[58,43,30,4],
[58,43,30,5],
[58,43,30,6],
[58,43,30,8],
[58,43,30,9],
[58,43,30,10],
[58,43,30,11],
[58,43,30,12],
[58,43,30,13],
[58,43,30,14],
[58,43,30,15],
[58,43,30,17],
[58,43,30,18],
[58,43,30,19],
[58,43,31,0],
[58,43,31,1],
[58,43,31,2],
[58,43,31,3],
[58,43,31,4],
[58,43,31,5],
[58,43,31,6],
[58,43,31,8],
[58,43,31,9],
[58,43,31,10],
[58,43,31,11],
[58,43,31,12],
[58,43,31,13],
[58,43,31,14],
[58,43,31,15],
[58,43,31,17],
[58,43,31,18],
[58,43,32,0],
[58,43,32,1],
[58,43,32,2],
[58,43,32,3],
[58,43,32,4],
[58,43,32,5],
[58,43,32,6],
[58,43,32,8],
[58,43,32,9],
[58,43,32,10],
[58,43,32,13],
[58,43,32,14],
[58,43,32,15],
[58,43,32,17],
[58,43,33,0],
[58,43,33,1],
[58,43,33,2],
[58,43,33,3],
[58,43,33,4],
[58,43,33,5],
[58,43,33,6],
[58,43,33,8],
[58,43,33,9],
[58,43,33,10],
[58,43,33,13],
[58,43,33,14],
[58,43,33,15],
[58,43,35,0],
[58,43,35,1],
[58,43,35,2],
[58,43,35,3],
[58,43,35,4],
[58,43,35,5],
[58,43,35,6],
[58,43,35,8],
[58,43,35,10],
[58,43,35,11],
[58,43,35,12],
[58,43,35,13],
[58,43,36,0],
[58,43,36,1],
[58,43,36,2],
[58,43,36,3],
[58,43,36,4],
[58,43,36,5],
[58,43,36,6],
[58,43,36,8],
[58,43,36,9],
[58,43,36,10],
[58,43,36,11],
[58,43,37,0],
[58,43,37,1],
[58,43,37,2],
[58,43,37,3],
[58,43,37,5],
[58,43,37,6],
[58,43,37,8],
[58,43,37,9],
[58,43,39,0],
[58,43,39,1],
[58,43,39,2],
[58,43,39,4],
[58,43,40,0],
[58,43,40,1],
[58,43,40,2],
[58,43,40,3],
[58,43,40,4],
[58,43,41,0],
[58,43,41,1],
[58,43,41,2],
[58,43,41,3],
[58,43,41,4],
[58,43,42,0],
[58,43,42,1],
[58,43,42,2],
[58,44,3,0],
[58,44,3,1],
[58,44,3,2],
[58,44,4,0],
[58,44,4,1],
[58,44,4,2],
[58,44,4,3],
[58,44,5,0],
[58,44,5,1],
[58,44,5,2],
[58,44,5,4],
[58,44,6,0],
[58,44,6,1],
[58,44,6,2],
[58,44,6,4],
[58,44,7,0],
[58,44,7,1],
[58,44,7,2],
[58,44,7,3],
[58,44,7,4],
[58,44,7,5],
[58,44,7,6],
[58,44,8,0],
[58,44,8,1],
[58,44,8,2],
[58,44,8,4],
[58,44,8,7],
[58,44,9,0],
[58,44,9,1],
[58,44,9,2],
[58,44,9,3],
[58,44,9,4],
[58,44,9,5],
[58,44,9,6],
[58,44,9,7],
[58,44,9,8],
[58,44,10,0],
[58,44,10,1],
[58,44,10,2],
[58,44,10,3],
[58,44,10,4],
[58,44,10,5],
[58,44,10,6],
[58,44,10,7],
[58,44,10,8],
[58,44,10,9],
[58,44,11,0],
[58,44,11,1],
[58,44,11,2],
[58,44,11,3],
[58,44,11,4],
[58,44,11,5],
[58,44,11,6],
[58,44,11,7],
[58,44,11,8],
[58,44,11,9],
[58,44,11,10],
[58,44,12,0],
[58,44,12,1],
[58,44,12,2],
[58,44,12,3],
[58,44,12,4],
[58,44,12,5],
[58,44,12,6],
[58,44,12,7],
[58,44,12,8],
[58,44,12,9],
[58,44,12,10],
[58,44,13,0],
[58,44,13,1],
[58,44,13,2],
[58,44,13,3],
[58,44,13,4],
[58,44,13,5],
[58,44,13,6],
[58,44,13,7],
[58,44,13,8],
[58,44,13,9],
[58,44,13,10],
[58,44,13,11],
[58,44,13,12],
[58,44,14,0],
[58,44,14,1],
[58,44,14,2],
[58,44,14,3],
[58,44,14,4],
[58,44,14,5],
[58,44,14,6],
[58,44,14,7],
[58,44,14,8],
[58,44,14,9],
[58,44,14,10],
[58,44,14,11],
[58,44,14,12],
[58,44,15,0],
[58,44,15,1],
[58,44,15,2],
[58,44,15,3],
[58,44,15,4],
[58,44,15,5],
[58,44,15,6],
[58,44,15,7],
[58,44,15,8],
[58,44,15,10],
[58,44,15,11],
[58,44,15,12],
[58,44,15,13],
[58,44,15,14],
[58,44,18,0],
[58,44,18,1],
[58,44,18,2],
[58,44,18,3],
[58,44,18,4],
[58,44,18,5],
[58,44,18,6],
[58,44,18,7],
[58,44,18,8],
[58,44,18,10],
[58,44,18,11],
[58,44,18,12],
[58,44,18,13],
[58,44,18,14],
[58,44,19,0],
[58,44,19,1],
[58,44,19,2],
[58,44,19,3],
[58,44,19,4],
[58,44,19,5],
[58,44,19,6],
[58,44,19,7],
[58,44,19,8],
[58,44,19,9],
[58,44,19,10],
[58,44,19,11],
[58,44,19,12],
[58,44,19,15],
[58,44,19,18],
[58,44,20,0],
[58,44,20,1],
[58,44,20,2],
[58,44,20,3],
[58,44,20,4],
[58,44,20,5],
[58,44,20,6],
[58,44,20,7],
[58,44,20,8],
[58,44,20,9],
[58,44,20,11],
[58,44,20,12],
[58,44,20,13],
[58,44,20,14],
[58,44,20,18],
[58,44,20,19],
[58,44,21,0],
[58,44,21,1],
[58,44,21,2],
[58,44,21,4],
[58,44,21,7],
[58,44,21,9],
[58,44,21,10],
[58,44,21,11],
[58,44,21,12],
[58,44,21,13],
[58,44,21,15],
[58,44,21,18],
[58,44,21,19],
[58,44,21,20],
[58,44,22,0],
[58,44,22,1],
[58,44,22,2],
[58,44,22,3],
[58,44,22,4],
[58,44,22,5],
[58,44,22,6],
[58,44,22,7],
[58,44,22,8],
[58,44,22,9],
[58,44,22,11],
[58,44,22,14],
[58,44,22,15],
[58,44,22,18],
[58,44,22,19],
[58,44,22,21],
[58,44,23,0],
[58,44,23,1],
[58,44,23,2],
[58,44,23,3],
[58,44,23,4],
[58,44,23,5],
[58,44,23,6],
[58,44,23,7],
[58,44,23,8],
[58,44,23,9],
[58,44,23,12],
[58,44,23,13],
[58,44,23,14],
[58,44,23,15],
[58,44,23,18],
[58,44,23,19],
[58,44,23,20],
[58,44,23,21],
[58,44,23,22],
[58,44,25,0],
[58,44,25,1],
[58,44,25,2],
[58,44,25,3],
[58,44,25,4],
[58,44,25,5],
[58,44,25,8],
[58,44,25,9],
[58,44,25,10],
[58,44,25,11],
[58,44,25,12],
[58,44,25,13],
[58,44,25,14],
[58,44,25,15],
[58,44,25,18],
[58,44,25,19],
[58,44,25,20],
[58,44,25,21],
[58,44,25,22],
[58,44,25,23],
[58,44,26,0],
[58,44,26,1],
[58,44,26,2],
[58,44,26,3],
[58,44,26,4],
[58,44,26,6],
[58,44,26,7],
[58,44,26,8],
[58,44,26,10],
[58,44,26,11],
[58,44,26,12],
[58,44,26,13],
[58,44,26,14],
[58,44,26,19],
[58,44,26,20],
[58,44,26,21],
[58,44,26,22],
[58,44,27,0],
[58,44,27,1],
[58,44,27,2],
[58,44,27,5],
[58,44,27,6],
[58,44,27,7],
[58,44,27,8],
[58,44,27,9],
[58,44,27,11],
[58,44,27,12],
[58,44,27,13],
[58,44,27,14],
[58,44,27,15],
[58,44,27,18],
[58,44,27,19],
[58,44,27,21],
[58,44,28,3],
[58,44,28,4],
[58,44,28,5],
[58,44,28,6],
[58,44,28,8],
[58,44,28,9],
[58,44,28,10],
[58,44,28,11],
[58,44,28,12],
[58,44,28,13],
[58,44,28,14],
[58,44,28,15],
[58,44,28,18],
[58,44,28,19],
[58,44,28,20],
[58,44,29,0],
[58,44,29,1],
[58,44,29,2],
[58,44,29,3],
[58,44,29,4],
[58,44,29,5],
[58,44,29,6],
[58,44,29,7],
[58,44,29,8],
[58,44,29,9],
[58,44,29,10],
[58,44,29,13],
[58,44,29,14],
[58,44,29,15],
[58,44,29,18],
[58,44,29,19],
[58,44,30,0],
[58,44,30,1],
[58,44,30,2],
[58,44,30,3],
[58,44,30,4],
[58,44,30,5],
[58,44,30,6],
[58,44,30,7],
[58,44,30,8],
[58,44,30,9],
[58,44,30,10],
[58,44,30,11],
[58,44,30,12],
[58,44,30,13],
[58,44,30,14],
[58,44,30,15],
[58,44,30,18],
[58,44,31,0],
[58,44,31,1],
[58,44,31,2],
[58,44,31,3],
[58,44,31,4],
[58,44,31,5],
[58,44,31,6],
[58,44,31,7],
[58,44,31,8],
[58,44,31,9],
[58,44,31,10],
[58,44,31,11],
[58,44,31,12],
[58,44,31,13],
[58,44,31,14],
[58,44,31,15],
[58,44,32,0],
[58,44,32,1],
[58,44,32,2],
[58,44,32,3],
[58,44,32,4],
[58,44,32,5],
[58,44,32,6],
[58,44,32,7],
[58,44,32,8],
[58,44,32,9],
[58,44,32,10],
[58,44,32,13],
[58,44,32,14],
[58,44,32,15],
[58,44,33,0],
[58,44,33,1],
[58,44,33,2],
[58,44,33,3],
[58,44,33,4],
[58,44,33,5],
[58,44,33,6],
[58,44,33,7],
[58,44,33,8],
[58,44,33,9],
[58,44,33,10],
[58,44,33,13],
[58,44,33,14],
[58,44,34,0],
[58,44,34,1],
[58,44,34,2],
[58,44,34,3],
[58,44,34,4],
[58,44,34,5],
[58,44,34,6],
[58,44,34,8],
[58,44,34,9],
[58,44,34,10],
[58,44,34,11],
[58,44,34,12],
[58,44,34,13],
[58,44,35,0],
[58,44,35,1],
[58,44,35,2],
[58,44,35,3],
[58,44,35,4],
[58,44,35,5],
[58,44,35,6],
[58,44,35,7],
[58,44,35,8],
[58,44,35,10],
[58,44,35,11],
[58,44,36,0],
[58,44,36,1],
[58,44,36,2],
[58,44,36,3],
[58,44,36,4],
[58,44,36,5],
[58,44,36,6],
[58,44,36,7],
[58,44,36,8],
[58,44,36,9],
[58,44,37,0],
[58,44,37,1],
[58,44,37,2],
[58,44,37,3],
[58,44,37,5],
[58,44,37,6],
[58,44,37,7],
[58,44,38,0],
[58,44,38,1],
[58,44,38,2],
[58,44,38,3],
[58,44,38,4],
[58,44,38,5],
[58,44,39,0],
[58,44,39,1],
[58,44,39,2],
[58,44,39,4],
[58,44,40,0],
[58,44,40,1],
[58,44,40,2],
[58,44,41,0],
[58,44,41,1],
[58,44,41,2],
[58,45,3,0],
[58,45,3,1],
[58,45,3,2],
[58,45,4,0],
[58,45,4,1],
[58,45,4,2],
[58,45,4,3],
[58,45,5,0],
[58,45,5,1],
[58,45,5,2],
[58,45,5,4],
[58,45,6,0],
[58,45,6,1],
[58,45,6,2],
[58,45,6,4],
[58,45,7,0],
[58,45,7,1],
[58,45,7,2],
[58,45,7,3],
[58,45,7,4],
[58,45,7,5],
[58,45,7,6],
[58,45,8,0],
[58,45,8,1],
[58,45,8,2],
[58,45,8,4],
[58,45,8,7],
[58,45,10,0],
[58,45,10,1],
[58,45,10,2],
[58,45,10,3],
[58,45,10,4],
[58,45,10,5],
[58,45,10,6],
[58,45,10,7],
[58,45,10,8],
[58,45,11,0],
[58,45,11,1],
[58,45,11,2],
[58,45,11,3],
[58,45,11,4],
[58,45,11,5],
[58,45,11,6],
[58,45,11,7],
[58,45,11,8],
[58,45,11,10],
[58,45,12,0],
[58,45,12,1],
[58,45,12,2],
[58,45,12,3],
[58,45,12,4],
[58,45,12,5],
[58,45,12,6],
[58,45,12,7],
[58,45,12,8],
[58,45,12,10],
[58,45,13,0],
[58,45,13,1],
[58,45,13,2],
[58,45,13,3],
[58,45,13,4],
[58,45,13,5],
[58,45,13,6],
[58,45,13,7],
[58,45,13,8],
[58,45,13,10],
[58,45,13,11],
[58,45,13,12],
[58,45,14,0],
[58,45,14,1],
[58,45,14,2],
[58,45,14,3],
[58,45,14,4],
[58,45,14,5],
[58,45,14,6],
[58,45,14,7],
[58,45,14,8],
[58,45,14,10],
[58,45,14,11],
[58,45,14,12],
[58,45,17,0],
[58,45,17,1],
[58,45,17,2],
[58,45,17,3],
[58,45,17,4],
[58,45,17,5],
[58,45,17,6],
[58,45,17,7],
[58,45,17,8],
[58,45,17,10],
[58,45,17,11],
[58,45,17,12],
[58,45,17,13],
[58,45,17,14],
[58,45,19,0],
[58,45,19,1],
[58,45,19,2],
[58,45,19,3],
[58,45,19,4],
[58,45,19,5],
[58,45,19,6],
[58,45,19,7],
[58,45,19,8],
[58,45,19,10],
[58,45,19,11],
[58,45,19,12],
[58,45,19,17],
[58,45,20,0],
[58,45,20,1],
[58,45,20,2],
[58,45,20,3],
[58,45,20,4],
[58,45,20,5],
[58,45,20,6],
[58,45,20,7],
[58,45,20,8],
[58,45,20,11],
[58,45,20,12],
[58,45,20,13],
[58,45,20,17],
[58,45,20,19],
[58,45,21,0],
[58,45,21,1],
[58,45,21,2],
[58,45,21,4],
[58,45,21,7],
[58,45,21,10],
[58,45,21,11],
[58,45,21,14],
[58,45,21,17],
[58,45,21,19],
[58,45,21,20],
[58,45,22,0],
[58,45,22,1],
[58,45,22,2],
[58,45,22,3],
[58,45,22,4],
[58,45,22,5],
[58,45,22,6],
[58,45,22,7],
[58,45,22,8],
[58,45,22,12],
[58,45,22,13],
[58,45,22,14],
[58,45,22,17],
[58,45,22,19],
[58,45,22,21],
[58,45,23,0],
[58,45,23,1],
[58,45,23,2],
[58,45,23,3],
[58,45,23,4],
[58,45,23,5],
[58,45,23,6],
[58,45,23,7],
[58,45,23,10],
[58,45,23,11],
[58,45,23,12],
[58,45,23,13],
[58,45,23,14],
[58,45,23,17],
[58,45,23,19],
[58,45,23,20],
[58,45,23,21],
[58,45,23,22],
[58,45,25,0],
[58,45,25,1],
[58,45,25,2],
[58,45,25,3],
[58,45,25,4],
[58,45,25,6],
[58,45,25,8],
[58,45,25,10],
[58,45,25,11],
[58,45,25,12],
[58,45,25,13],
[58,45,25,14],
[58,45,25,17],
[58,45,25,19],
[58,45,25,20],
[58,45,25,21],
[58,45,25,22],
[58,45,27,3],
[58,45,27,4],
[58,45,27,5],
[58,45,27,6],
[58,45,27,7],
[58,45,27,8],
[58,45,27,11],
[58,45,27,12],
[58,45,27,13],
[58,45,27,14],
[58,45,27,17],
[58,45,27,19],
[58,45,28,0],
[58,45,28,1],
[58,45,28,2],
[58,45,28,3],
[58,45,28,4],
[58,45,28,5],
[58,45,28,6],
[58,45,28,8],
[58,45,28,10],
[58,45,28,11],
[58,45,28,12],
[58,45,28,13],
[58,45,28,14],
[58,45,28,17],
[58,45,28,19],
[58,45,29,0],
[58,45,29,1],
[58,45,29,2],
[58,45,29,3],
[58,45,29,4],
[58,45,29,5],
[58,45,29,6],
[58,45,29,7],
[58,45,29,8],
[58,45,29,10],
[58,45,29,13],
[58,45,29,14],
[58,45,29,17],
[58,45,30,0],
[58,45,30,1],
[58,45,30,2],
[58,45,30,3],
[58,45,30,4],
[58,45,30,5],
[58,45,30,6],
[58,45,30,7],
[58,45,30,8],
[58,45,30,10],
[58,45,30,11],
[58,45,30,12],
[58,45,30,13],
[58,45,30,14],
[58,45,30,17],
[58,45,31,0],
[58,45,31,1],
[58,45,31,2],
[58,45,31,3],
[58,45,31,4],
[58,45,31,5],
[58,45,31,6],
[58,45,31,7],
[58,45,31,8],
[58,45,31,10],
[58,45,31,11],
[58,45,31,12],
[58,45,31,13],
[58,45,31,14],
[58,45,32,0],
[58,45,32,1],
[58,45,32,2],
[58,45,32,3],
[58,45,32,4],
[58,45,32,5],
[58,45,32,6],
[58,45,32,7],
[58,45,32,8],
[58,45,32,10],
[58,45,32,13],
[58,45,32,14],
[58,45,33,0],
[58,45,33,1],
[58,45,33,2],
[58,45,33,3],
[58,45,33,4],
[58,45,33,5],
[58,45,33,6],
[58,45,33,7],
[58,45,33,8],
[58,45,33,10],
[58,45,33,13],
[58,45,34,0],
[58,45,34,1],
[58,45,34,2],
[58,45,34,3],
[58,45,34,4],
[58,45,34,5],
[58,45,34,6],
[58,45,34,8],
[58,45,34,10],
[58,45,34,11],
[58,45,36,0],
[58,45,36,1],
[58,45,36,2],
[58,45,36,3],
[58,45,36,4],
[58,45,36,5],
[58,45,36,6],
[58,45,36,7],
[58,45,37,0],
[58,45,37,1],
[58,45,37,2],
[58,45,37,3],
[58,45,37,5],
[58,45,38,0],
[58,45,38,1],
[58,45,38,2],
[58,45,38,3],
[58,45,38,4],
[58,45,39,0],
[58,45,39,1],
[58,45,39,2],
[58,46,3,0],
[58,46,3,1],
[58,46,3,2],
[58,46,4,0],
[58,46,4,1],
[58,46,4,2],
[58,46,4,3],
[58,46,5,0],
[58,46,5,1],
[58,46,5,2],
[58,46,5,4],
[58,46,6,0],
[58,46,6,1],
[58,46,6,2],
[58,46,6,4],
[58,46,7,0],
[58,46,7,1],
[58,46,7,2],
[58,46,7,3],
[58,46,7,4],
[58,46,7,5],
[58,46,7,6],
[58,46,8,0],
[58,46,8,1],
[58,46,8,2],
[58,46,8,4],
[58,46,8,7],
[58,46,10,0],
[58,46,10,1],
[58,46,10,2],
[58,46,10,3],
[58,46,10,4],
[58,46,10,5],
[58,46,10,6],
[58,46,10,7],
[58,46,10,8],
[58,46,11,0],
[58,46,11,1],
[58,46,11,2],
[58,46,11,3],
[58,46,11,4],
[58,46,11,5],
[58,46,11,6],
[58,46,11,7],
[58,46,11,8],
[58,46,11,10],
[58,46,12,0],
[58,46,12,1],
[58,46,12,2],
[58,46,12,3],
[58,46,12,4],
[58,46,12,5],
[58,46,12,6],
[58,46,12,7],
[58,46,12,8],
[58,46,12,10],
[58,46,13,0],
[58,46,13,1],
[58,46,13,2],
[58,46,13,3],
[58,46,13,4],
[58,46,13,5],
[58,46,13,6],
[58,46,13,7],
[58,46,13,8],
[58,46,13,10],
[58,46,13,11],
[58,46,13,12],
[58,46,14,0],
[58,46,14,1],
[58,46,14,2],
[58,46,14,3],
[58,46,14,4],
[58,46,14,5],
[58,46,14,6],
[58,46,14,7],
[58,46,14,8],
[58,46,14,10],
[58,46,14,11],
[58,46,14,12],
[58,46,17,0],
[58,46,17,1],
[58,46,17,2],
[58,46,17,3],
[58,46,17,4],
[58,46,17,5],
[58,46,17,6],
[58,46,17,7],
[58,46,17,8],
[58,46,17,10],
[58,46,17,11],
[58,46,17,12],
[58,46,17,13],
[58,46,17,14],
[58,46,19,0],
[58,46,19,1],
[58,46,19,2],
[58,46,19,3],
[58,46,19,4],
[58,46,19,5],
[58,46,19,6],
[58,46,19,7],
[58,46,19,8],
[58,46,19,10],
[58,46,19,11],
[58,46,19,12],
[58,46,19,17],
[58,46,20,0],
[58,46,20,1],
[58,46,20,2],
[58,46,20,3],
[58,46,20,4],
[58,46,20,5],
[58,46,20,6],
[58,46,20,7],
[58,46,20,8],
[58,46,20,11],
[58,46,20,14],
[58,46,20,17],
[58,46,20,19],
[58,46,21,0],
[58,46,21,1],
[58,46,21,2],
[58,46,21,4],
[58,46,21,7],
[58,46,21,12],
[58,46,21,13],
[58,46,21,14],
[58,46,21,17],
[58,46,21,19],
[58,46,21,20],
[58,46,22,0],
[58,46,22,1],
[58,46,22,2],
[58,46,22,3],
[58,46,22,4],
[58,46,22,5],
[58,46,22,6],
[58,46,22,7],
[58,46,22,11],
[58,46,22,12],
[58,46,22,13],
[58,46,22,14],
[58,46,22,17],
[58,46,22,19],
[58,46,22,21],
[58,46,23,0],
[58,46,23,1],
[58,46,23,2],
[58,46,23,3],
[58,46,23,4],
[58,46,23,5],
[58,46,23,8],
[58,46,23,10],
[58,46,23,11],
[58,46,23,12],
[58,46,23,13],
[58,46,23,14],
[58,46,23,17],
[58,46,23,19],
[58,46,23,20],
[58,46,23,21],
[58,46,23,22],
[58,46,25,0],
[58,46,25,1],
[58,46,25,2],
[58,46,25,5],
[58,46,25,6],
[58,46,25,8],
[58,46,25,10],
[58,46,25,11],
[58,46,25,12],
[58,46,25,13],
[58,46,25,14],
[58,46,25,17],
[58,46,25,19],
[58,46,25,20],
[58,46,25,21],
[58,46,27,0],
[58,46,27,1],
[58,46,27,2],
[58,46,27,3],
[58,46,27,4],
[58,46,27,5],
[58,46,27,6],
[58,46,27,7],
[58,46,27,8],
[58,46,27,11],
[58,46,27,12],
[58,46,27,13],
[58,46,27,14],
[58,46,27,17],
[58,46,27,19],
[58,46,28,0],
[58,46,28,1],
[58,46,28,2],
[58,46,28,3],
[58,46,28,4],
[58,46,28,5],
[58,46,28,6],
[58,46,28,8],
[58,46,28,10],
[58,46,28,11],
[58,46,28,12],
[58,46,28,13],
[58,46,28,14],
[58,46,28,17],
[58,46,29,0],
[58,46,29,1],
[58,46,29,2],
[58,46,29,3],
[58,46,29,4],
[58,46,29,5],
[58,46,29,6],
[58,46,29,7],
[58,46,29,8],
[58,46,29,10],
[58,46,29,13],
[58,46,29,14],
[58,46,29,17],
[58,46,30,0],
[58,46,30,1],
[58,46,30,2],
[58,46,30,3],
[58,46,30,4],
[58,46,30,5],
[58,46,30,6],
[58,46,30,7],
[58,46,30,8],
[58,46,30,10],
[58,46,30,11],
[58,46,30,12],
[58,46,30,13],
[58,46,30,14],
[58,46,31,0],
[58,46,31,1],
[58,46,31,2],
[58,46,31,3],
[58,46,31,4],
[58,46,31,5],
[58,46,31,6],
[58,46,31,7],
[58,46,31,8],
[58,46,31,10],
[58,46,31,11],
[58,46,31,12],
[58,46,31,13],
[58,46,31,14],
[58,46,32,0],
[58,46,32,1],
[58,46,32,2],
[58,46,32,3],
[58,46,32,4],
[58,46,32,5],
[58,46,32,6],
[58,46,32,7],
[58,46,32,8],
[58,46,32,10],
[58,46,32,13],
[58,46,33,0],
[58,46,33,1],
[58,46,33,2],
[58,46,33,3],
[58,46,33,4],
[58,46,33,5],
[58,46,33,6],
[58,46,33,7],
[58,46,33,8],
[58,46,33,10],
[58,46,34,0],
[58,46,34,1],
[58,46,34,2],
[58,46,34,3],
[58,46,34,4],
[58,46,34,5],
[58,46,34,6],
[58,46,34,8],
[58,46,36,0],
[58,46,36,1],
[58,46,36,2],
[58,46,36,3],
[58,46,36,4],
[58,46,36,5],
[58,46,37,0],
[58,46,37,1],
[58,46,37,2],
[58,46,37,3],
[58,46,38,0],
[58,46,38,1],
[58,46,38,2],
[58,47,3,0],
[58,47,3,1],
[58,47,3,2],
[58,47,4,0],
[58,47,4,1],
[58,47,4,2],
[58,47,4,3],
[58,47,5,0],
[58,47,5,1],
[58,47,5,2],
[58,47,5,4],
[58,47,6,0],
[58,47,6,1],
[58,47,6,2],
[58,47,6,4],
[58,47,7,0],
[58,47,7,1],
[58,47,7,2],
[58,47,7,3],
[58,47,7,4],
[58,47,7,5],
[58,47,7,6],
[58,47,8,0],
[58,47,8,1],
[58,47,8,2],
[58,47,8,4],
[58,47,8,7],
[58,47,9,0],
[58,47,9,1],
[58,47,9,2],
[58,47,9,3],
[58,47,9,4],
[58,47,9,5],
[58,47,9,6],
[58,47,9,7],
[58,47,9,8],
[58,47,10,0],
[58,47,10,1],
[58,47,10,2],
[58,47,10,3],
[58,47,10,4],
[58,47,10,5],
[58,47,10,6],
[58,47,10,7],
[58,47,10,8],
[58,47,10,9],
[58,47,11,0],
[58,47,11,1],
[58,47,11,2],
[58,47,11,3],
[58,47,11,4],
[58,47,11,5],
[58,47,11,6],
[58,47,11,7],
[58,47,11,8],
[58,47,11,9],
[58,47,11,10],
[58,47,12,0],
[58,47,12,1],
[58,47,12,2],
[58,47,12,3],
[58,47,12,4],
[58,47,12,5],
[58,47,12,6],
[58,47,12,7],
[58,47,12,8],
[58,47,12,9],
[58,47,12,10],
[58,47,13,0],
[58,47,13,1],
[58,47,13,2],
[58,47,13,3],
[58,47,13,4],
[58,47,13,5],
[58,47,13,6],
[58,47,13,7],
[58,47,13,8],
[58,47,13,9],
[58,47,13,10],
[58,47,13,11],
[58,47,13,12],
[58,47,14,0],
[58,47,14,1],
[58,47,14,2],
[58,47,14,3],
[58,47,14,4],
[58,47,14,5],
[58,47,14,6],
[58,47,14,7],
[58,47,14,8],
[58,47,14,9],
[58,47,14,10],
[58,47,14,11],
[58,47,14,12],
[58,47,15,0],
[58,47,15,1],
[58,47,15,2],
[58,47,15,3],
[58,47,15,4],
[58,47,15,5],
[58,47,15,6],
[58,47,15,7],
[58,47,15,8],
[58,47,15,10],
[58,47,15,11],
[58,47,15,12],
[58,47,15,13],
[58,47,15,14],
[58,47,17,0],
[58,47,17,1],
[58,47,17,2],
[58,47,17,3],
[58,47,17,4],
[58,47,17,5],
[58,47,17,6],
[58,47,17,7],
[58,47,17,8],
[58,47,17,9],
[58,47,17,10],
[58,47,17,11],
[58,47,17,12],
[58,47,17,13],
[58,47,17,14],
[58,47,18,0],
[58,47,18,1],
[58,47,18,2],
[58,47,18,3],
[58,47,18,4],
[58,47,18,5],
[58,47,18,6],
[58,47,18,7],
[58,47,18,8],
[58,47,18,10],
[58,47,18,11],
[58,47,18,12],
[58,47,18,13],
[58,47,18,17],
[58,47,19,0],
[58,47,19,1],
[58,47,19,2],
[58,47,19,3],
[58,47,19,4],
[58,47,19,5],
[58,47,19,6],
[58,47,19,7],
[58,47,19,8],
[58,47,19,9],
[58,47,19,10],
[58,47,19,11],
[58,47,19,15],
[58,47,19,17],
[58,47,19,18],
[58,47,20,0],
[58,47,20,1],
[58,47,20,2],
[58,47,20,3],
[58,47,20,4],
[58,47,20,5],
[58,47,20,6],
[58,47,20,7],
[58,47,20,8],
[58,47,20,9],
[58,47,20,12],
[58,47,20,13],
[58,47,20,14],
[58,47,20,15],
[58,47,20,17],
[58,47,20,18],
[58,47,20,19],
[58,47,21,0],
[58,47,21,1],
[58,47,21,2],
[58,47,21,4],
[58,47,21,7],
[58,47,21,10],
[58,47,21,11],
[58,47,21,12],
[58,47,21,13],
[58,47,21,14],
[58,47,21,15],
[58,47,21,17],
[58,47,21,18],
[58,47,21,19],
[58,47,21,20],
[58,47,22,0],
[58,47,22,1],
[58,47,22,2],
[58,47,22,3],
[58,47,22,4],
[58,47,22,5],
[58,47,22,8],
[58,47,22,9],
[58,47,22,11],
[58,47,22,12],
[58,47,22,13],
[58,47,22,14],
[58,47,22,15],
[58,47,22,17],
[58,47,22,18],
[58,47,22,19],
[58,47,22,21],
[58,47,25,3],
[58,47,25,4],
[58,47,25,5],
[58,47,25,6],
[58,47,25,8],
[58,47,25,9],
[58,47,25,10],
[58,47,25,11],
[58,47,25,12],
[58,47,25,13],
[58,47,25,14],
[58,47,25,15],
[58,47,25,17],
[58,47,25,18],
[58,47,25,19],
[58,47,25,20],
[58,47,26,0],
[58,47,26,1],
[58,47,26,2],
[58,47,26,3],
[58,47,26,4],
[58,47,26,5],
[58,47,26,6],
[58,47,26,7],
[58,47,26,8],
[58,47,26,10],
[58,47,26,11],
[58,47,26,12],
[58,47,26,13],
[58,47,26,14],
[58,47,26,17],
[58,47,26,19],
[58,47,27,0],
[58,47,27,1],
[58,47,27,2],
[58,47,27,3],
[58,47,27,4],
[58,47,27,5],
[58,47,27,6],
[58,47,27,7],
[58,47,27,8],
[58,47,27,9],
[58,47,27,11],
[58,47,27,12],
[58,47,27,13],
[58,47,27,14],
[58,47,27,15],
[58,47,27,17],
[58,47,27,18],
[58,47,28,0],
[58,47,28,1],
[58,47,28,2],
[58,47,28,3],
[58,47,28,4],
[58,47,28,5],
[58,47,28,6],
[58,47,28,8],
[58,47,28,9],
[58,47,28,10],
[58,47,28,11],
[58,47,28,12],
[58,47,28,13],
[58,47,28,14],
[58,47,28,15],
[58,47,28,17],
[58,47,29,0],
[58,47,29,1],
[58,47,29,2],
[58,47,29,3],
[58,47,29,4],
[58,47,29,5],
[58,47,29,6],
[58,47,29,7],
[58,47,29,8],
[58,47,29,9],
[58,47,29,10],
[58,47,29,13],
[58,47,29,14],
[58,47,29,15],
[58,47,32,0],
[58,47,32,1],
[58,47,32,2],
[58,47,32,3],
[58,47,32,4],
[58,47,32,5],
[58,47,32,6],
[58,47,32,7],
[58,47,32,8],
[58,47,32,9],
[58,47,32,10],
[58,47,33,0],
[58,47,33,1],
[58,47,33,2],
[58,47,33,3],
[58,47,33,4],
[58,47,33,5],
[58,47,33,6],
[58,47,33,7],
[58,47,33,8],
[58,47,33,9],
[58,47,34,0],
[58,47,34,1],
[58,47,34,2],
[58,47,34,3],
[58,47,34,4],
[58,47,34,5],
[58,47,34,6],
[58,47,35,0],
[58,47,35,1],
[58,47,35,2],
[58,47,35,3],
[58,47,35,4],
[58,47,35,5],
[58,47,36,0],
[58,47,36,1],
[58,47,36,2],
[58,47,36,3],
[58,47,36,4],
[58,47,37,0],
[58,47,37,1],
[58,47,37,2],
[58,48,3,0],
[58,48,3,1],
[58,48,3,2],
[58,48,4,0],
[58,48,4,1],
[58,48,4,2],
[58,48,4,3],
[58,48,5,0],
[58,48,5,1],
[58,48,5,2],
[58,48,5,4],
[58,48,6,0],
[58,48,6,1],
[58,48,6,2],
[58,48,6,4],
[58,48,7,0],
[58,48,7,1],
[58,48,7,2],
[58,48,7,3],
[58,48,7,4],
[58,48,7,5],
[58,48,7,6],
[58,48,8,0],
[58,48,8,1],
[58,48,8,2],
[58,48,8,4],
[58,48,8,7],
[58,48,9,0],
[58,48,9,1],
[58,48,9,2],
[58,48,9,3],
[58,48,9,4],
[58,48,9,5],
[58,48,9,6],
[58,48,9,7],
[58,48,9,8],
[58,48,10,0],
[58,48,10,1],
[58,48,10,2],
[58,48,10,3],
[58,48,10,4],
[58,48,10,5],
[58,48,10,6],
[58,48,10,7],
[58,48,10,8],
[58,48,10,9],
[58,48,13,0],
[58,48,13,1],
[58,48,13,2],
[58,48,13,3],
[58,48,13,4],
[58,48,13,5],
[58,48,13,6],
[58,48,13,7],
[58,48,13,8],
[58,48,13,9],
[58,48,13,10],
[58,48,14,0],
[58,48,14,1],
[58,48,14,2],
[58,48,14,3],
[58,48,14,4],
[58,48,14,5],
[58,48,14,6],
[58,48,14,7],
[58,48,14,8],
[58,48,14,9],
[58,48,14,10],
[58,48,15,0],
[58,48,15,1],
[58,48,15,2],
[58,48,15,3],
[58,48,15,4],
[58,48,15,5],
[58,48,15,6],
[58,48,15,7],
[58,48,15,8],
[58,48,15,10],
[58,48,15,13],
[58,48,15,14],
[58,48,17,0],
[58,48,17,1],
[58,48,17,2],
[58,48,17,3],
[58,48,17,4],
[58,48,17,5],
[58,48,17,6],
[58,48,17,7],
[58,48,17,8],
[58,48,17,9],
[58,48,17,10],
[58,48,17,13],
[58,48,17,15],
[58,48,18,0],
[58,48,18,1],
[58,48,18,2],
[58,48,18,3],
[58,48,18,4],
[58,48,18,5],
[58,48,18,6],
[58,48,18,7],
[58,48,18,8],
[58,48,18,10],
[58,48,18,14],
[58,48,18,17],
[58,48,19,0],
[58,48,19,1],
[58,48,19,2],
[58,48,19,3],
[58,48,19,4],
[58,48,19,5],
[58,48,19,6],
[58,48,19,7],
[58,48,19,8],
[58,48,19,9],
[58,48,19,15],
[58,48,19,17],
[58,48,19,18],
[58,48,20,0],
[58,48,20,1],
[58,48,20,2],
[58,48,20,3],
[58,48,20,4],
[58,48,20,5],
[58,48,20,6],
[58,48,20,7],
[58,48,20,13],
[58,48,20,14],
[58,48,20,15],
[58,48,20,17],
[58,48,20,18],
[58,48,20,19],
[58,48,21,0],
[58,48,21,1],
[58,48,21,2],
[58,48,21,4],
[58,48,21,9],
[58,48,21,10],
[58,48,21,13],
[58,48,21,14],
[58,48,21,15],
[58,48,21,17],
[58,48,21,18],
[58,48,21,19],
[58,48,21,20],
[58,48,22,0],
[58,48,22,1],
[58,48,22,2],
[58,48,22,3],
[58,48,22,4],
[58,48,22,6],
[58,48,22,7],
[58,48,22,8],
[58,48,22,9],
[58,48,22,13],
[58,48,22,14],
[58,48,22,15],
[58,48,22,17],
[58,48,22,18],
[58,48,22,19],
[58,48,22,21],
[58,48,23,0],
[58,48,23,1],
[58,48,23,2],
[58,48,23,5],
[58,48,23,6],
[58,48,23,7],
[58,48,23,8],
[58,48,23,9],
[58,48,23,10],
[58,48,23,13],
[58,48,23,14],
[58,48,23,15],
[58,48,23,17],
[58,48,23,18],
[58,48,23,19],
[58,48,23,20],
[58,48,23,21],
[58,48,25,0],
[58,48,25,1],
[58,48,25,2],
[58,48,25,3],
[58,48,25,4],
[58,48,25,5],
[58,48,25,6],
[58,48,25,8],
[58,48,25,9],
[58,48,25,10],
[58,48,25,13],
[58,48,25,14],
[58,48,25,15],
[58,48,25,17],
[58,48,25,18],
[58,48,25,19],
[58,48,26,0],
[58,48,26,1],
[58,48,26,2],
[58,48,26,3],
[58,48,26,4],
[58,48,26,5],
[58,48,26,6],
[58,48,26,7],
[58,48,26,8],
[58,48,26,10],
[58,48,26,13],
[58,48,26,14],
[58,48,26,17],
[58,48,27,0],
[58,48,27,1],
[58,48,27,2],
[58,48,27,3],
[58,48,27,4],
[58,48,27,5],
[58,48,27,6],
[58,48,27,7],
[58,48,27,8],
[58,48,27,9],
[58,48,27,13],
[58,48,27,14],
[58,48,27,15],
[58,48,27,17],
[58,48,28,0],
[58,48,28,1],
[58,48,28,2],
[58,48,28,3],
[58,48,28,4],
[58,48,28,5],
[58,48,28,6],
[58,48,28,8],
[58,48,28,9],
[58,48,28,10],
[58,48,28,13],
[58,48,28,14],
[58,48,28,15],
[58,48,30,0],
[58,48,30,1],
[58,48,30,2],
[58,48,30,3],
[58,48,30,4],
[58,48,30,5],
[58,48,30,6],
[58,48,30,7],
[58,48,30,8],
[58,48,30,9],
[58,48,30,10],
[58,48,30,13],
[58,48,31,0],
[58,48,31,1],
[58,48,31,2],
[58,48,31,3],
[58,48,31,4],
[58,48,31,5],
[58,48,31,6],
[58,48,31,7],
[58,48,31,8],
[58,48,31,9],
[58,48,31,10],
[58,48,34,0],
[58,48,34,1],
[58,48,34,2],
[58,48,34,3],
[58,48,34,4],
[58,48,34,5],
[58,48,35,0],
[58,48,35,1],
[58,48,35,2],
[58,48,35,3],
[58,48,35,4],
[58,48,36,0],
[58,48,36,1],
[58,48,36,2],
[58,49,3,0],
[58,49,3,1],
[58,49,3,2],
[58,49,4,0],
[58,49,4,1],
[58,49,4,2],
[58,49,4,3],
[58,49,5,0],
[58,49,5,1],
[58,49,5,2],
[58,49,5,4],
[58,49,6,0],
[58,49,6,1],
[58,49,6,2],
[58,49,6,4],
[58,49,7,0],
[58,49,7,1],
[58,49,7,2],
[58,49,7,3],
[58,49,7,4],
[58,49,7,5],
[58,49,7,6],
[58,49,8,0],
[58,49,8,1],
[58,49,8,2],
[58,49,8,4],
[58,49,8,7],
[58,49,10,0],
[58,49,10,1],
[58,49,10,2],
[58,49,10,3],
[58,49,10,4],
[58,49,10,5],
[58,49,10,6],
[58,49,10,7],
[58,49,10,8],
[58,49,11,0],
[58,49,11,1],
[58,49,11,2],
[58,49,11,3],
[58,49,11,4],
[58,49,11,5],
[58,49,11,6],
[58,49,11,7],
[58,49,11,8],
[58,49,11,10],
[58,49,12,0],
[58,49,12,1],
[58,49,12,2],
[58,49,12,3],
[58,49,12,4],
[58,49,12,5],
[58,49,12,6],
[58,49,12,7],
[58,49,12,8],
[58,49,12,10],
[58,49,13,0],
[58,49,13,1],
[58,49,13,2],
[58,49,13,3],
[58,49,13,4],
[58,49,13,5],
[58,49,13,6],
[58,49,13,7],
[58,49,13,8],
[58,49,13,10],
[58,49,13,11],
[58,49,13,12],
[58,49,14,0],
[58,49,14,1],
[58,49,14,2],
[58,49,14,3],
[58,49,14,4],
[58,49,14,5],
[58,49,14,6],
[58,49,14,7],
[58,49,14,8],
[58,49,14,10],
[58,49,14,11],
[58,49,14,12],
[58,49,17,0],
[58,49,17,1],
[58,49,17,2],
[58,49,17,3],
[58,49,17,4],
[58,49,17,5],
[58,49,17,6],
[58,49,17,7],
[58,49,17,8],
[58,49,17,10],
[58,49,17,11],
[58,49,17,12],
[58,49,17,13],
[58,49,19,0],
[58,49,19,1],
[58,49,19,2],
[58,49,19,3],
[58,49,19,4],
[58,49,19,5],
[58,49,19,6],
[58,49,19,7],
[58,49,19,8],
[58,49,19,12],
[58,49,19,17],
[58,49,20,0],
[58,49,20,1],
[58,49,20,2],
[58,49,20,3],
[58,49,20,4],
[58,49,20,5],
[58,49,20,6],
[58,49,20,7],
[58,49,20,11],
[58,49,20,12],
[58,49,20,13],
[58,49,20,14],
[58,49,20,17],
[58,49,20,19],
[58,49,21,0],
[58,49,21,1],
[58,49,21,2],
[58,49,21,4],
[58,49,21,10],
[58,49,21,11],
[58,49,21,12],
[58,49,21,13],
[58,49,21,14],
[58,49,21,17],
[58,49,21,19],
[58,49,21,20],
[58,49,22,0],
[58,49,22,1],
[58,49,22,2],
[58,49,22,3],
[58,49,22,4],
[58,49,22,6],
[58,49,22,7],
[58,49,22,8],
[58,49,22,11],
[58,49,22,12],
[58,49,22,13],
[58,49,22,14],
[58,49,22,17],
[58,49,22,19],
[58,49,22,21],
[58,49,23,0],
[58,49,23,1],
[58,49,23,2],
[58,49,23,5],
[58,49,23,6],
[58,49,23,7],
[58,49,23,8],
[58,49,23,10],
[58,49,23,11],
[58,49,23,12],
[58,49,23,13],
[58,49,23,14],
[58,49,23,17],
[58,49,23,19],
[58,49,23,20],
[58,49,23,21],
[58,49,25,0],
[58,49,25,1],
[58,49,25,2],
[58,49,25,3],
[58,49,25,4],
[58,49,25,5],
[58,49,25,6],
[58,49,25,8],
[58,49,25,10],
[58,49,25,11],
[58,49,25,12],
[58,49,25,13],
[58,49,25,14],
[58,49,25,17],
[58,49,25,19],
[58,49,27,0],
[58,49,27,1],
[58,49,27,2],
[58,49,27,3],
[58,49,27,4],
[58,49,27,5],
[58,49,27,6],
[58,49,27,7],
[58,49,27,8],
[58,49,27,11],
[58,49,27,12],
[58,49,27,13],
[58,49,27,14],
[58,49,27,17],
[58,49,28,0],
[58,49,28,1],
[58,49,28,2],
[58,49,28,3],
[58,49,28,4],
[58,49,28,5],
[58,49,28,6],
[58,49,28,8],
[58,49,28,10],
[58,49,28,11],
[58,49,28,12],
[58,49,28,13],
[58,49,28,14],
[58,49,29,0],
[58,49,29,1],
[58,49,29,2],
[58,49,29,3],
[58,49,29,4],
[58,49,29,5],
[58,49,29,6],
[58,49,29,7],
[58,49,29,8],
[58,49,29,10],
[58,49,29,13],
[58,49,29,14],
[58,49,30,0],
[58,49,30,1],
[58,49,30,2],
[58,49,30,3],
[58,49,30,4],
[58,49,30,5],
[58,49,30,6],
[58,49,30,7],
[58,49,30,8],
[58,49,30,10],
[58,49,30,11],
[58,49,30,12],
[58,49,30,13],
[58,49,31,0],
[58,49,31,1],
[58,49,31,2],
[58,49,31,3],
[58,49,31,4],
[58,49,31,5],
[58,49,31,6],
[58,49,31,7],
[58,49,31,8],
[58,49,31,10],
[58,49,31,11],
[58,49,32,0],
[58,49,32,1],
[58,49,32,2],
[58,49,32,3],
[58,49,32,4],
[58,49,32,5],
[58,49,32,6],
[58,49,32,7],
[58,49,32,8],
[58,49,33,0],
[58,49,33,1],
[58,49,33,2],
[58,49,33,3],
[58,49,33,4],
[58,49,33,5],
[58,49,33,6],
[58,49,33,7],
[58,49,34,0],
[58,49,34,1],
[58,49,34,2],
[58,49,34,3],
[58,49,34,4],
[58,49,34,5],
[58,49,36,0],
[58,49,36,1],
[58,49,36,2],
[58,50,3,0],
[58,50,3,1],
[58,50,3,2],
[58,50,4,0],
[58,50,4,1],
[58,50,4,2],
[58,50,4,3],
[58,50,5,0],
[58,50,5,1],
[58,50,5,2],
[58,50,5,4],
[58,50,6,0],
[58,50,6,1],
[58,50,6,2],
[58,50,6,4],
[58,50,7,0],
[58,50,7,1],
[58,50,7,2],
[58,50,7,3],
[58,50,7,4],
[58,50,7,5],
[58,50,7,6],
[58,50,8,0],
[58,50,8,1],
[58,50,8,2],
[58,50,8,4],
[58,50,8,7],
[58,50,10,0],
[58,50,10,1],
[58,50,10,2],
[58,50,10,3],
[58,50,10,4],
[58,50,10,5],
[58,50,10,6],
[58,50,10,7],
[58,50,10,8],
[58,50,11,0],
[58,50,11,1],
[58,50,11,2],
[58,50,11,3],
[58,50,11,4],
[58,50,11,5],
[58,50,11,6],
[58,50,11,7],
[58,50,11,8],
[58,50,11,10],
[58,50,12,0],
[58,50,12,1],
[58,50,12,2],
[58,50,12,3],
[58,50,12,4],
[58,50,12,5],
[58,50,12,6],
[58,50,12,7],
[58,50,12,8],
[58,50,12,10],
[58,50,13,0],
[58,50,13,1],
[58,50,13,2],
[58,50,13,3],
[58,50,13,4],
[58,50,13,5],
[58,50,13,6],
[58,50,13,7],
[58,50,13,8],
[58,50,13,10],
[58,50,13,11],
[58,50,13,12],
[58,50,14,0],
[58,50,14,1],
[58,50,14,2],
[58,50,14,3],
[58,50,14,4],
[58,50,14,5],
[58,50,14,6],
[58,50,14,7],
[58,50,14,8],
[58,50,14,10],
[58,50,14,11],
[58,50,14,12],
[58,50,17,0],
[58,50,17,1],
[58,50,17,2],
[58,50,17,3],
[58,50,17,4],
[58,50,17,5],
[58,50,17,6],
[58,50,17,7],
[58,50,17,8],
[58,50,17,10],
[58,50,17,11],
[58,50,17,12],
[58,50,17,13],
[58,50,19,0],
[58,50,19,1],
[58,50,19,2],
[58,50,19,3],
[58,50,19,4],
[58,50,19,5],
[58,50,19,6],
[58,50,19,7],
[58,50,19,8],
[58,50,19,12],
[58,50,19,17],
[58,50,20,0],
[58,50,20,1],
[58,50,20,2],
[58,50,20,3],
[58,50,20,4],
[58,50,20,5],
[58,50,20,6],
[58,50,20,7],
[58,50,20,11],
[58,50,20,12],
[58,50,20,13],
[58,50,20,14],
[58,50,20,17],
[58,50,20,19],
[58,50,21,0],
[58,50,21,1],
[58,50,21,2],
[58,50,21,4],
[58,50,21,10],
[58,50,21,11],
[58,50,21,12],
[58,50,21,13],
[58,50,21,14],
[58,50,21,17],
[58,50,21,19],
[58,50,21,20],
[58,50,22,0],
[58,50,22,1],
[58,50,22,2],
[58,50,22,3],
[58,50,22,4],
[58,50,22,6],
[58,50,22,7],
[58,50,22,8],
[58,50,22,11],
[58,50,22,12],
[58,50,22,13],
[58,50,22,14],
[58,50,22,17],
[58,50,22,19],
[58,50,22,21],
[58,50,23,0],
[58,50,23,1],
[58,50,23,2],
[58,50,23,5],
[58,50,23,6],
[58,50,23,7],
[58,50,23,8],
[58,50,23,10],
[58,50,23,11],
[58,50,23,12],
[58,50,23,13],
[58,50,23,14],
[58,50,23,17],
[58,50,23,19],
[58,50,23,20],
[58,50,23,21],
[58,50,25,0],
[58,50,25,1],
[58,50,25,2],
[58,50,25,3],
[58,50,25,4],
[58,50,25,5],
[58,50,25,6],
[58,50,25,8],
[58,50,25,10],
[58,50,25,11],
[58,50,25,12],
[58,50,25,13],
[58,50,25,14],
[58,50,25,17],
[58,50,25,19],
[58,50,27,0],
[58,50,27,1],
[58,50,27,2],
[58,50,27,3],
[58,50,27,4],
[58,50,27,5],
[58,50,27,6],
[58,50,27,7],
[58,50,27,8],
[58,50,27,11],
[58,50,27,12],
[58,50,27,13],
[58,50,27,14],
[58,50,27,17],
[58,50,28,0],
[58,50,28,1],
[58,50,28,2],
[58,50,28,3],
[58,50,28,4],
[58,50,28,5],
[58,50,28,6],
[58,50,28,8],
[58,50,28,10],
[58,50,28,11],
[58,50,28,12],
[58,50,28,13],
[58,50,28,14],
[58,50,29,0],
[58,50,29,1],
[58,50,29,2],
[58,50,29,3],
[58,50,29,4],
[58,50,29,5],
[58,50,29,6],
[58,50,29,7],
[58,50,29,8],
[58,50,29,10],
[58,50,29,13],
[58,50,29,14],
[58,50,30,0],
[58,50,30,1],
[58,50,30,2],
[58,50,30,3],
[58,50,30,4],
[58,50,30,5],
[58,50,30,6],
[58,50,30,7],
[58,50,30,8],
[58,50,30,10],
[58,50,30,11],
[58,50,30,12],
[58,50,30,13],
[58,50,31,0],
[58,50,31,1],
[58,50,31,2],
[58,50,31,3],
[58,50,31,4],
[58,50,31,5],
[58,50,31,6],
[58,50,31,7],
[58,50,31,8],
[58,50,31,10],
[58,50,31,11],
[58,50,32,0],
[58,50,32,1],
[58,50,32,2],
[58,50,32,3],
[58,50,32,4],
[58,50,32,5],
[58,50,32,6],
[58,50,32,7],
[58,50,32,8],
[58,50,33,0],
[58,50,33,1],
[58,50,33,2],
[58,50,33,3],
[58,50,33,4],
[58,50,33,5],
[58,50,33,6],
[58,50,33,7],
[58,50,34,0],
[58,50,34,1],
[58,50,34,2],
[58,50,34,3],
[58,50,34,4],
[58,50,34,5],
[58,50,36,0],
[58,50,36,1],
[58,50,36,2],
[58,51,4,0],
[58,51,4,1],
[58,51,4,2],
[58,51,7,0],
[58,51,7,1],
[58,51,7,2],
[58,51,7,4],
[58,51,9,0],
[58,51,9,1],
[58,51,9,2],
[58,51,9,4],
[58,51,9,7],
[58,51,10,0],
[58,51,10,1],
[58,51,10,2],
[58,51,10,4],
[58,51,10,7],
[58,51,10,9],
[58,51,11,0],
[58,51,11,1],
[58,51,11,2],
[58,51,11,4],
[58,51,11,7],
[58,51,11,9],
[58,51,11,10],
[58,51,12,0],
[58,51,12,1],
[58,51,12,2],
[58,51,12,4],
[58,51,12,7],
[58,51,12,9],
[58,51,12,10],
[58,51,13,0],
[58,51,13,1],
[58,51,13,2],
[58,51,13,4],
[58,51,13,7],
[58,51,13,9],
[58,51,13,10],
[58,51,13,11],
[58,51,13,12],
[58,51,14,0],
[58,51,14,1],
[58,51,14,2],
[58,51,14,4],
[58,51,14,7],
[58,51,14,9],
[58,51,14,10],
[58,51,14,11],
[58,51,14,12],
[58,51,15,0],
[58,51,15,1],
[58,51,15,2],
[58,51,15,4],
[58,51,15,7],
[58,51,15,10],
[58,51,15,11],
[58,51,15,12],
[58,51,15,13],
[58,51,15,14],
[58,51,17,0],
[58,51,17,1],
[58,51,17,2],
[58,51,17,4],
[58,51,17,7],
[58,51,17,9],
[58,51,17,10],
[58,51,17,11],
[58,51,17,12],
[58,51,17,13],
[58,51,17,15],
[58,51,18,0],
[58,51,18,1],
[58,51,18,2],
[58,51,18,4],
[58,51,18,7],
[58,51,18,10],
[58,51,18,11],
[58,51,18,14],
[58,51,18,17],
[58,51,19,0],
[58,51,19,1],
[58,51,19,2],
[58,51,19,4],
[58,51,19,7],
[58,51,19,9],
[58,51,19,12],
[58,51,19,15],
[58,51,19,17],
[58,51,19,18],
[58,51,20,0],
[58,51,20,1],
[58,51,20,2],
[58,51,20,4],
[58,51,20,7],
[58,51,20,11],
[58,51,20,12],
[58,51,20,13],
[58,51,20,14],
[58,51,20,15],
[58,51,20,17],
[58,51,20,18],
[58,51,20,19],
[58,51,22,0],
[58,51,22,1],
[58,51,22,2],
[58,51,22,4],
[58,51,22,7],
[58,51,22,9],
[58,51,22,11],
[58,51,22,12],
[58,51,22,13],
[58,51,22,14],
[58,51,22,15],
[58,51,22,17],
[58,51,22,18],
[58,51,22,19],
[58,51,23,0],
[58,51,23,1],
[58,51,23,2],
[58,51,23,7],
[58,51,23,9],
[58,51,23,10],
[58,51,23,11],
[58,51,23,12],
[58,51,23,13],
[58,51,23,14],
[58,51,23,15],
[58,51,23,17],
[58,51,23,18],
[58,51,23,19],
[58,51,23,20],
[58,51,25,0],
[58,51,25,1],
[58,51,25,2],
[58,51,25,4],
[58,51,25,9],
[58,51,25,10],
[58,51,25,11],
[58,51,25,12],
[58,51,25,13],
[58,51,25,14],
[58,51,25,15],
[58,51,25,17],
[58,51,25,18],
[58,51,25,19],
[58,51,26,0],
[58,51,26,1],
[58,51,26,2],
[58,51,26,4],
[58,51,26,7],
[58,51,26,10],
[58,51,26,11],
[58,51,26,12],
[58,51,26,13],
[58,51,26,14],
[58,51,26,17],
[58,51,27,0],
[58,51,27,1],
[58,51,27,2],
[58,51,27,4],
[58,51,27,7],
[58,51,27,9],
[58,51,27,11],
[58,51,27,12],
[58,51,27,13],
[58,51,27,14],
[58,51,27,15],
[58,51,27,17],
[58,51,28,0],
[58,51,28,1],
[58,51,28,2],
[58,51,28,4],
[58,51,28,9],
[58,51,28,10],
[58,51,28,11],
[58,51,28,12],
[58,51,28,13],
[58,51,28,14],
[58,51,28,15],
[58,51,29,0],
[58,51,29,1],
[58,51,29,2],
[58,51,29,4],
[58,51,29,7],
[58,51,29,9],
[58,51,29,10],
[58,51,29,13],
[58,51,29,14],
[58,51,30,0],
[58,51,30,1],
[58,51,30,2],
[58,51,30,4],
[58,51,30,7],
[58,51,30,9],
[58,51,30,10],
[58,51,30,11],
[58,51,30,12],
[58,51,30,13],
[58,51,31,0],
[58,51,31,1],
[58,51,31,2],
[58,51,31,4],
[58,51,31,7],
[58,51,31,9],
[58,51,31,10],
[58,51,31,11],
[58,51,32,0],
[58,51,32,1],
[58,51,32,2],
[58,51,32,4],
[58,51,32,7],
[58,51,32,9],
[58,51,33,0],
[58,51,33,1],
[58,51,33,2],
[58,51,33,4],
[58,51,33,7],
[58,51,34,0],
[58,51,34,1],
[58,51,34,2],
[58,51,34,4],
[58,51,35,0],
[58,51,35,1],
[58,51,35,2],
[58,51,35,4],
[58,51,36,0],
[58,51,36,1],
[58,51,36,2],
[58,52,3,0],
[58,52,3,1],
[58,52,3,2],
[58,52,4,0],
[58,52,4,1],
[58,52,4,2],
[58,52,4,3],
[58,52,5,0],
[58,52,5,1],
[58,52,5,2],
[58,52,5,4],
[58,52,6,0],
[58,52,6,1],
[58,52,6,2],
[58,52,6,4],
[58,52,7,0],
[58,52,7,1],
[58,52,7,2],
[58,52,7,3],
[58,52,7,4],
[58,52,7,5],
[58,52,7,6],
[58,52,8,0],
[58,52,8,1],
[58,52,8,2],
[58,52,8,4],
[58,52,8,7],
[58,52,10,0],
[58,52,10,1],
[58,52,10,2],
[58,52,10,3],
[58,52,10,4],
[58,52,10,5],
[58,52,10,6],
[58,52,10,7],
[58,52,10,8],
[58,52,11,0],
[58,52,11,1],
[58,52,11,2],
[58,52,11,3],
[58,52,11,4],
[58,52,11,5],
[58,52,11,6],
[58,52,11,7],
[58,52,11,8],
[58,52,11,10],
[58,52,12,0],
[58,52,12,1],
[58,52,12,2],
[58,52,12,3],
[58,52,12,4],
[58,52,12,5],
[58,52,12,6],
[58,52,12,7],
[58,52,12,8],
[58,52,12,10],
[58,52,13,0],
[58,52,13,1],
[58,52,13,2],
[58,52,13,3],
[58,52,13,4],
[58,52,13,5],
[58,52,13,6],
[58,52,13,7],
[58,52,13,8],
[58,52,13,10],
[58,52,13,11],
[58,52,13,12],
[58,52,14,0],
[58,52,14,1],
[58,52,14,2],
[58,52,14,3],
[58,52,14,4],
[58,52,14,5],
[58,52,14,6],
[58,52,14,7],
[58,52,14,8],
[58,52,14,10],
[58,52,14,11],
[58,52,14,12],
[58,52,17,0],
[58,52,17,1],
[58,52,17,2],
[58,52,17,3],
[58,52,17,4],
[58,52,17,5],
[58,52,17,6],
[58,52,17,7],
[58,52,17,8],
[58,52,17,10],
[58,52,17,11],
[58,52,17,12],
[58,52,17,13],
[58,52,19,0],
[58,52,19,1],
[58,52,19,2],
[58,52,19,3],
[58,52,19,4],
[58,52,19,5],
[58,52,19,6],
[58,52,19,7],
[58,52,19,8],
[58,52,19,12],
[58,52,19,17],
[58,52,20,0],
[58,52,20,1],
[58,52,20,2],
[58,52,20,3],
[58,52,20,4],
[58,52,20,5],
[58,52,20,6],
[58,52,20,7],
[58,52,20,11],
[58,52,20,12],
[58,52,20,13],
[58,52,20,14],
[58,52,20,17],
[58,52,20,19],
[58,52,21,0],
[58,52,21,1],
[58,52,21,2],
[58,52,21,4],
[58,52,21,10],
[58,52,21,11],
[58,52,21,12],
[58,52,21,13],
[58,52,21,14],
[58,52,21,17],
[58,52,21,19],
[58,52,21,20],
[58,52,22,0],
[58,52,22,1],
[58,52,22,2],
[58,52,22,3],
[58,52,22,4],
[58,52,22,6],
[58,52,22,7],
[58,52,22,8],
[58,52,22,11],
[58,52,22,12],
[58,52,22,13],
[58,52,22,14],
[58,52,22,17],
[58,52,22,19],
[58,52,22,21],
[58,52,23,0],
[58,52,23,1],
[58,52,23,2],
[58,52,23,5],
[58,52,23,6],
[58,52,23,7],
[58,52,23,8],
[58,52,23,10],
[58,52,23,11],
[58,52,23,12],
[58,52,23,13],
[58,52,23,14],
[58,52,23,17],
[58,52,23,19],
[58,52,23,20],
[58,52,23,21],
[58,52,25,0],
[58,52,25,1],
[58,52,25,2],
[58,52,25,3],
[58,52,25,4],
[58,52,25,5],
[58,52,25,6],
[58,52,25,8],
[58,52,25,10],
[58,52,25,11],
[58,52,25,12],
[58,52,25,13],
[58,52,25,14],
[58,52,25,17],
[58,52,25,19],
[58,52,27,0],
[58,52,27,1],
[58,52,27,2],
[58,52,27,3],
[58,52,27,4],
[58,52,27,5],
[58,52,27,6],
[58,52,27,7],
[58,52,27,8],
[58,52,27,11],
[58,52,27,12],
[58,52,27,13],
[58,52,27,14],
[58,52,27,17],
[58,52,28,0],
[58,52,28,1],
[58,52,28,2],
[58,52,28,3],
[58,52,28,4],
[58,52,28,5],
[58,52,28,6],
[58,52,28,8],
[58,52,28,10],
[58,52,28,11],
[58,52,28,12],
[58,52,28,13],
[58,52,28,14],
[58,52,29,0],
[58,52,29,1],
[58,52,29,2],
[58,52,29,3],
[58,52,29,4],
[58,52,29,5],
[58,52,29,6],
[58,52,29,7],
[58,52,29,8],
[58,52,29,10],
[58,52,29,13],
[58,52,29,14],
[58,52,30,0],
[58,52,30,1],
[58,52,30,2],
[58,52,30,3],
[58,52,30,4],
[58,52,30,5],
[58,52,30,6],
[58,52,30,7],
[58,52,30,8],
[58,52,30,10],
[58,52,30,11],
[58,52,30,12],
[58,52,30,13],
[58,52,31,0],
[58,52,31,1],
[58,52,31,2],
[58,52,31,3],
[58,52,31,4],
[58,52,31,5],
[58,52,31,6],
[58,52,31,7],
[58,52,31,8],
[58,52,31,10],
[58,52,31,11],
[58,52,32,0],
[58,52,32,1],
[58,52,32,2],
[58,52,32,3],
[58,52,32,4],
[58,52,32,5],
[58,52,32,6],
[58,52,32,7],
[58,52,32,8],
[58,52,33,0],
[58,52,33,1],
[58,52,33,2],
[58,52,33,3],
[58,52,33,4],
[58,52,33,5],
[58,52,33,6],
[58,52,33,7],
[58,52,34,0],
[58,52,34,1],
[58,52,34,2],
[58,52,34,3],
[58,52,34,4],
[58,52,34,5],
[58,52,36,0],
[58,52,36,1],
[58,52,36,2],
[58,53,3,0],
[58,53,3,1],
[58,53,3,2],
[58,53,4,0],
[58,53,4,1],
[58,53,4,2],
[58,53,4,3],
[58,53,5,0],
[58,53,5,1],
[58,53,5,2],
[58,53,5,4],
[58,53,6,0],
[58,53,6,1],
[58,53,6,2],
[58,53,6,4],
[58,53,7,0],
[58,53,7,1],
[58,53,7,2],
[58,53,7,3],
[58,53,7,4],
[58,53,7,5],
[58,53,7,6],
[58,53,8,0],
[58,53,8,1],
[58,53,8,2],
[58,53,8,4],
[58,53,8,7],
[58,53,9,0],
[58,53,9,1],
[58,53,9,2],
[58,53,9,3],
[58,53,9,4],
[58,53,9,5],
[58,53,9,6],
[58,53,9,7],
[58,53,9,8],
[58,53,11,0],
[58,53,11,1],
[58,53,11,2],
[58,53,11,3],
[58,53,11,4],
[58,53,11,5],
[58,53,11,6],
[58,53,11,7],
[58,53,11,8],
[58,53,11,9],
[58,53,12,0],
[58,53,12,1],
[58,53,12,2],
[58,53,12,3],
[58,53,12,4],
[58,53,12,5],
[58,53,12,6],
[58,53,12,7],
[58,53,12,8],
[58,53,12,9],
[58,53,13,0],
[58,53,13,1],
[58,53,13,2],
[58,53,13,3],
[58,53,13,4],
[58,53,13,5],
[58,53,13,6],
[58,53,13,7],
[58,53,13,8],
[58,53,13,9],
[58,53,13,11],
[58,53,13,12],
[58,53,14,0],
[58,53,14,1],
[58,53,14,2],
[58,53,14,3],
[58,53,14,4],
[58,53,14,5],
[58,53,14,6],
[58,53,14,7],
[58,53,14,8],
[58,53,14,9],
[58,53,14,11],
[58,53,14,12],
[58,53,15,0],
[58,53,15,1],
[58,53,15,2],
[58,53,15,3],
[58,53,15,4],
[58,53,15,5],
[58,53,15,6],
[58,53,15,7],
[58,53,15,8],
[58,53,15,11],
[58,53,15,12],
[58,53,15,13],
[58,53,15,14],
[58,53,17,0],
[58,53,17,1],
[58,53,17,2],
[58,53,17,3],
[58,53,17,4],
[58,53,17,5],
[58,53,17,6],
[58,53,17,7],
[58,53,17,8],
[58,53,17,9],
[58,53,17,11],
[58,53,17,12],
[58,53,17,13],
[58,53,17,15],
[58,53,18,0],
[58,53,18,1],
[58,53,18,2],
[58,53,18,3],
[58,53,18,4],
[58,53,18,5],
[58,53,18,6],
[58,53,18,7],
[58,53,18,8],
[58,53,18,11],
[58,53,18,14],
[58,53,18,17],
[58,53,19,0],
[58,53,19,1],
[58,53,19,2],
[58,53,19,3],
[58,53,19,4],
[58,53,19,5],
[58,53,19,6],
[58,53,19,7],
[58,53,19,8],
[58,53,19,9],
[58,53,19,12],
[58,53,19,15],
[58,53,19,17],
[58,53,19,18],
[58,53,21,0],
[58,53,21,1],
[58,53,21,2],
[58,53,21,4],
[58,53,21,9],
[58,53,21,11],
[58,53,21,12],
[58,53,21,13],
[58,53,21,14],
[58,53,21,15],
[58,53,21,17],
[58,53,21,18],
[58,53,21,19],
[58,53,23,0],
[58,53,23,1],
[58,53,23,2],
[58,53,23,5],
[58,53,23,6],
[58,53,23,7],
[58,53,23,8],
[58,53,23,9],
[58,53,23,11],
[58,53,23,12],
[58,53,23,13],
[58,53,23,14],
[58,53,23,15],
[58,53,23,17],
[58,53,23,18],
[58,53,23,19],
[58,53,23,21],
[58,53,25,0],
[58,53,25,1],
[58,53,25,2],
[58,53,25,3],
[58,53,25,4],
[58,53,25,5],
[58,53,25,6],
[58,53,25,8],
[58,53,25,9],
[58,53,25,11],
[58,53,25,12],
[58,53,25,13],
[58,53,25,14],
[58,53,25,15],
[58,53,25,17],
[58,53,25,18],
[58,53,25,19],
[58,53,26,0],
[58,53,26,1],
[58,53,26,2],
[58,53,26,3],
[58,53,26,4],
[58,53,26,5],
[58,53,26,6],
[58,53,26,7],
[58,53,26,8],
[58,53,26,11],
[58,53,26,12],
[58,53,26,13],
[58,53,26,14],
[58,53,26,17],
[58,53,28,0],
[58,53,28,1],
[58,53,28,2],
[58,53,28,3],
[58,53,28,4],
[58,53,28,5],
[58,53,28,6],
[58,53,28,8],
[58,53,28,9],
[58,53,28,11],
[58,53,28,12],
[58,53,28,13],
[58,53,28,14],
[58,53,28,15],
[58,53,29,0],
[58,53,29,1],
[58,53,29,2],
[58,53,29,3],
[58,53,29,4],
[58,53,29,5],
[58,53,29,6],
[58,53,29,7],
[58,53,29,8],
[58,53,29,9],
[58,53,29,13],
[58,53,29,14],
[58,53,30,0],
[58,53,30,1],
[58,53,30,2],
[58,53,30,3],
[58,53,30,4],
[58,53,30,5],
[58,53,30,6],
[58,53,30,7],
[58,53,30,8],
[58,53,30,9],
[58,53,30,11],
[58,53,30,12],
[58,53,30,13],
[58,53,31,0],
[58,53,31,1],
[58,53,31,2],
[58,53,31,3],
[58,53,31,4],
[58,53,31,5],
[58,53,31,6],
[58,53,31,7],
[58,53,31,8],
[58,53,31,9],
[58,53,31,11],
[58,53,32,0],
[58,53,32,1],
[58,53,32,2],
[58,53,32,3],
[58,53,32,4],
[58,53,32,5],
[58,53,32,6],
[58,53,32,7],
[58,53,32,8],
[58,53,32,9],
[58,53,33,0],
[58,53,33,1],
[58,53,33,2],
[58,53,33,3],
[58,53,33,4],
[58,53,33,5],
[58,53,33,6],
[58,53,33,7],
[58,53,34,0],
[58,53,34,1],
[58,53,34,2],
[58,53,34,3],
[58,53,34,4],
[58,53,34,5],
[58,53,35,0],
[58,53,35,1],
[58,53,35,2],
[58,53,35,3],
[58,53,35,4],
[58,53,36,0],
[58,53,36,1],
[58,53,36,2],
[58,54,3,0],
[58,54,3,1],
[58,54,3,2],
[58,54,4,0],
[58,54,4,1],
[58,54,4,2],
[58,54,4,3],
[58,54,5,0],
[58,54,5,1],
[58,54,5,2],
[58,54,5,4],
[58,54,6,0],
[58,54,6,1],
[58,54,6,2],
[58,54,6,4],
[58,54,7,0],
[58,54,7,1],
[58,54,7,2],
[58,54,7,3],
[58,54,7,4],
[58,54,7,5],
[58,54,7,6],
[58,54,8,0],
[58,54,8,1],
[58,54,8,2],
[58,54,8,4],
[58,54,8,7],
[58,54,9,0],
[58,54,9,1],
[58,54,9,2],
[58,54,9,3],
[58,54,9,4],
[58,54,9,5],
[58,54,9,6],
[58,54,9,7],
[58,54,9,8],
[58,54,11,0],
[58,54,11,1],
[58,54,11,2],
[58,54,11,3],
[58,54,11,4],
[58,54,11,5],
[58,54,11,6],
[58,54,11,7],
[58,54,11,8],
[58,54,11,9],
[58,54,12,0],
[58,54,12,1],
[58,54,12,2],
[58,54,12,3],
[58,54,12,4],
[58,54,12,5],
[58,54,12,6],
[58,54,12,7],
[58,54,12,8],
[58,54,12,9],
[58,54,13,0],
[58,54,13,1],
[58,54,13,2],
[58,54,13,3],
[58,54,13,4],
[58,54,13,5],
[58,54,13,6],
[58,54,13,7],
[58,54,13,8],
[58,54,13,9],
[58,54,13,11],
[58,54,13,12],
[58,54,14,0],
[58,54,14,1],
[58,54,14,2],
[58,54,14,3],
[58,54,14,4],
[58,54,14,5],
[58,54,14,6],
[58,54,14,7],
[58,54,14,8],
[58,54,14,9],
[58,54,14,11],
[58,54,14,12],
[58,54,15,0],
[58,54,15,1],
[58,54,15,2],
[58,54,15,3],
[58,54,15,4],
[58,54,15,5],
[58,54,15,6],
[58,54,15,7],
[58,54,15,8],
[58,54,15,11],
[58,54,15,12],
[58,54,15,13],
[58,54,15,14],
[58,54,17,0],
[58,54,17,1],
[58,54,17,2],
[58,54,17,3],
[58,54,17,4],
[58,54,17,5],
[58,54,17,6],
[58,54,17,7],
[58,54,17,8],
[58,54,17,9],
[58,54,17,11],
[58,54,17,12],
[58,54,17,13],
[58,54,17,15],
[58,54,18,0],
[58,54,18,1],
[58,54,18,2],
[58,54,18,3],
[58,54,18,4],
[58,54,18,5],
[58,54,18,6],
[58,54,18,7],
[58,54,18,8],
[58,54,18,11],
[58,54,18,14],
[58,54,18,17],
[58,54,19,0],
[58,54,19,1],
[58,54,19,2],
[58,54,19,3],
[58,54,19,4],
[58,54,19,5],
[58,54,19,6],
[58,54,19,7],
[58,54,19,8],
[58,54,19,9],
[58,54,19,12],
[58,54,19,15],
[58,54,19,17],
[58,54,19,18],
[58,54,21,0],
[58,54,21,1],
[58,54,21,2],
[58,54,21,4],
[58,54,21,9],
[58,54,21,11],
[58,54,21,12],
[58,54,21,13],
[58,54,21,14],
[58,54,21,15],
[58,54,21,17],
[58,54,21,18],
[58,54,21,19],
[58,54,23,0],
[58,54,23,1],
[58,54,23,2],
[58,54,23,5],
[58,54,23,6],
[58,54,23,7],
[58,54,23,8],
[58,54,23,9],
[58,54,23,11],
[58,54,23,12],
[58,54,23,13],
[58,54,23,14],
[58,54,23,15],
[58,54,23,17],
[58,54,23,18],
[58,54,23,19],
[58,54,23,21],
[58,54,25,0],
[58,54,25,1],
[58,54,25,2],
[58,54,25,3],
[58,54,25,4],
[58,54,25,5],
[58,54,25,6],
[58,54,25,8],
[58,54,25,9],
[58,54,25,11],
[58,54,25,12],
[58,54,25,13],
[58,54,25,14],
[58,54,25,15],
[58,54,25,17],
[58,54,25,18],
[58,54,25,19],
[58,54,26,0],
[58,54,26,1],
[58,54,26,2],
[58,54,26,3],
[58,54,26,4],
[58,54,26,5],
[58,54,26,6],
[58,54,26,7],
[58,54,26,8],
[58,54,26,11],
[58,54,26,12],
[58,54,26,13],
[58,54,26,14],
[58,54,26,17],
[58,54,28,0],
[58,54,28,1],
[58,54,28,2],
[58,54,28,3],
[58,54,28,4],
[58,54,28,5],
[58,54,28,6],
[58,54,28,8],
[58,54,28,9],
[58,54,28,11],
[58,54,28,12],
[58,54,28,13],
[58,54,28,14],
[58,54,28,15],
[58,54,29,0],
[58,54,29,1],
[58,54,29,2],
[58,54,29,3],
[58,54,29,4],
[58,54,29,5],
[58,54,29,6],
[58,54,29,7],
[58,54,29,8],
[58,54,29,9],
[58,54,29,13],
[58,54,29,14],
[58,54,30,0],
[58,54,30,1],
[58,54,30,2],
[58,54,30,3],
[58,54,30,4],
[58,54,30,5],
[58,54,30,6],
[58,54,30,7],
[58,54,30,8],
[58,54,30,9],
[58,54,30,11],
[58,54,30,12],
[58,54,30,13],
[58,54,31,0],
[58,54,31,1],
[58,54,31,2],
[58,54,31,3],
[58,54,31,4],
[58,54,31,5],
[58,54,31,6],
[58,54,31,7],
[58,54,31,8],
[58,54,31,9],
[58,54,31,11],
[58,54,32,0],
[58,54,32,1],
[58,54,32,2],
[58,54,32,3],
[58,54,32,4],
[58,54,32,5],
[58,54,32,6],
[58,54,32,7],
[58,54,32,8],
[58,54,32,9],
[58,54,33,0],
[58,54,33,1],
[58,54,33,2],
[58,54,33,3],
[58,54,33,4],
[58,54,33,5],
[58,54,33,6],
[58,54,33,7],
[58,54,34,0],
[58,54,34,1],
[58,54,34,2],
[58,54,34,3],
[58,54,34,4],
[58,54,34,5],
[58,54,35,0],
[58,54,35,1],
[58,54,35,2],
[58,54,35,3],
[58,54,35,4],
[58,54,36,0],
[58,54,36,1],
[58,54,36,2],
[58,55,3,0],
[58,55,3,1],
[58,55,3,2],
[58,55,4,0],
[58,55,4,1],
[58,55,4,2],
[58,55,4,3],
[58,55,5,0],
[58,55,5,1],
[58,55,5,2],
[58,55,5,4],
[58,55,6,0],
[58,55,6,1],
[58,55,6,2],
[58,55,6,4],
[58,55,7,0],
[58,55,7,1],
[58,55,7,2],
[58,55,7,3],
[58,55,7,4],
[58,55,7,5],
[58,55,7,6],
[58,55,8,0],
[58,55,8,1],
[58,55,8,2],
[58,55,8,4],
[58,55,8,7],
[58,55,9,0],
[58,55,9,1],
[58,55,9,2],
[58,55,9,3],
[58,55,9,4],
[58,55,9,5],
[58,55,9,6],
[58,55,9,7],
[58,55,9,8],
[58,55,11,0],
[58,55,11,1],
[58,55,11,2],
[58,55,11,3],
[58,55,11,4],
[58,55,11,5],
[58,55,11,6],
[58,55,11,7],
[58,55,11,8],
[58,55,11,9],
[58,55,12,0],
[58,55,12,1],
[58,55,12,2],
[58,55,12,3],
[58,55,12,4],
[58,55,12,5],
[58,55,12,6],
[58,55,12,7],
[58,55,12,8],
[58,55,12,9],
[58,55,13,0],
[58,55,13,1],
[58,55,13,2],
[58,55,13,3],
[58,55,13,4],
[58,55,13,5],
[58,55,13,6],
[58,55,13,7],
[58,55,13,8],
[58,55,13,9],
[58,55,13,11],
[58,55,13,12],
[58,55,14,0],
[58,55,14,1],
[58,55,14,2],
[58,55,14,3],
[58,55,14,4],
[58,55,14,5],
[58,55,14,6],
[58,55,14,7],
[58,55,14,8],
[58,55,14,9],
[58,55,14,11],
[58,55,14,12],
[58,55,15,0],
[58,55,15,1],
[58,55,15,2],
[58,55,15,3],
[58,55,15,4],
[58,55,15,5],
[58,55,15,6],
[58,55,15,7],
[58,55,15,8],
[58,55,15,11],
[58,55,15,12],
[58,55,15,13],
[58,55,15,14],
[58,55,17,0],
[58,55,17,1],
[58,55,17,2],
[58,55,17,3],
[58,55,17,4],
[58,55,17,5],
[58,55,17,6],
[58,55,17,7],
[58,55,17,8],
[58,55,17,9],
[58,55,17,11],
[58,55,17,12],
[58,55,17,13],
[58,55,17,15],
[58,55,18,0],
[58,55,18,1],
[58,55,18,2],
[58,55,18,3],
[58,55,18,4],
[58,55,18,5],
[58,55,18,6],
[58,55,18,7],
[58,55,18,8],
[58,55,18,11],
[58,55,18,14],
[58,55,18,17],
[58,55,19,0],
[58,55,19,1],
[58,55,19,2],
[58,55,19,3],
[58,55,19,4],
[58,55,19,5],
[58,55,19,6],
[58,55,19,7],
[58,55,19,8],
[58,55,19,9],
[58,55,19,12],
[58,55,19,15],
[58,55,19,17],
[58,55,19,18],
[58,55,21,0],
[58,55,21,1],
[58,55,21,2],
[58,55,21,4],
[58,55,21,9],
[58,55,21,11],
[58,55,21,12],
[58,55,21,13],
[58,55,21,14],
[58,55,21,15],
[58,55,21,17],
[58,55,21,18],
[58,55,21,19],
[58,55,23,0],
[58,55,23,1],
[58,55,23,2],
[58,55,23,5],
[58,55,23,6],
[58,55,23,7],
[58,55,23,8],
[58,55,23,9],
[58,55,23,11],
[58,55,23,12],
[58,55,23,13],
[58,55,23,14],
[58,55,23,15],
[58,55,23,17],
[58,55,23,18],
[58,55,23,19],
[58,55,23,21],
[58,55,25,0],
[58,55,25,1],
[58,55,25,2],
[58,55,25,3],
[58,55,25,4],
[58,55,25,5],
[58,55,25,6],
[58,55,25,8],
[58,55,25,9],
[58,55,25,11],
[58,55,25,12],
[58,55,25,13],
[58,55,25,14],
[58,55,25,15],
[58,55,25,17],
[58,55,25,18],
[58,55,25,19],
[58,55,26,0],
[58,55,26,1],
[58,55,26,2],
[58,55,26,3],
[58,55,26,4],
[58,55,26,5],
[58,55,26,6],
[58,55,26,7],
[58,55,26,8],
[58,55,26,11],
[58,55,26,12],
[58,55,26,13],
[58,55,26,14],
[58,55,26,17],
[58,55,28,0],
[58,55,28,1],
[58,55,28,2],
[58,55,28,3],
[58,55,28,4],
[58,55,28,5],
[58,55,28,6],
[58,55,28,8],
[58,55,28,9],
[58,55,28,11],
[58,55,28,12],
[58,55,28,13],
[58,55,28,14],
[58,55,28,15],
[58,55,29,0],
[58,55,29,1],
[58,55,29,2],
[58,55,29,3],
[58,55,29,4],
[58,55,29,5],
[58,55,29,6],
[58,55,29,7],
[58,55,29,8],
[58,55,29,9],
[58,55,29,13],
[58,55,29,14],
[58,55,30,0],
[58,55,30,1],
[58,55,30,2],
[58,55,30,3],
[58,55,30,4],
[58,55,30,5],
[58,55,30,6],
[58,55,30,7],
[58,55,30,8],
[58,55,30,9],
[58,55,30,11],
[58,55,30,12],
[58,55,30,13],
[58,55,31,0],
[58,55,31,1],
[58,55,31,2],
[58,55,31,3],
[58,55,31,4],
[58,55,31,5],
[58,55,31,6],
[58,55,31,7],
[58,55,31,8],
[58,55,31,9],
[58,55,31,11],
[58,55,32,0],
[58,55,32,1],
[58,55,32,2],
[58,55,32,3],
[58,55,32,4],
[58,55,32,5],
[58,55,32,6],
[58,55,32,7],
[58,55,32,8],
[58,55,32,9],
[58,55,33,0],
[58,55,33,1],
[58,55,33,2],
[58,55,33,3],
[58,55,33,4],
[58,55,33,5],
[58,55,33,6],
[58,55,33,7],
[58,55,34,0],
[58,55,34,1],
[58,55,34,2],
[58,55,34,3],
[58,55,34,4],
[58,55,34,5],
[58,55,35,0],
[58,55,35,1],
[58,55,35,2],
[58,55,35,3],
[58,55,35,4],
[58,55,36,0],
[58,55,36,1],
[58,55,36,2],
[58,56,3,0],
[58,56,3,1],
[58,56,3,2],
[58,56,4,0],
[58,56,4,1],
[58,56,4,2],
[58,56,4,3],
[58,56,5,0],
[58,56,5,1],
[58,56,5,2],
[58,56,5,4],
[58,56,6,0],
[58,56,6,1],
[58,56,6,2],
[58,56,6,4],
[58,56,7,0],
[58,56,7,1],
[58,56,7,2],
[58,56,7,3],
[58,56,7,4],
[58,56,7,5],
[58,56,7,6],
[58,56,8,0],
[58,56,8,1],
[58,56,8,2],
[58,56,8,4],
[58,56,8,7],
[58,56,10,0],
[58,56,10,1],
[58,56,10,2],
[58,56,10,3],
[58,56,10,4],
[58,56,10,5],
[58,56,10,6],
[58,56,10,7],
[58,56,10,8],
[58,56,11,0],
[58,56,11,1],
[58,56,11,2],
[58,56,11,3],
[58,56,11,4],
[58,56,11,5],
[58,56,11,6],
[58,56,11,7],
[58,56,11,8],
[58,56,11,10],
[58,56,12,0],
[58,56,12,1],
[58,56,12,2],
[58,56,12,3],
[58,56,12,4],
[58,56,12,5],
[58,56,12,6],
[58,56,12,7],
[58,56,12,8],
[58,56,12,10],
[58,56,13,0],
[58,56,13,1],
[58,56,13,2],
[58,56,13,3],
[58,56,13,4],
[58,56,13,5],
[58,56,13,6],
[58,56,13,7],
[58,56,13,8],
[58,56,13,10],
[58,56,13,11],
[58,56,13,12],
[58,56,14,0],
[58,56,14,1],
[58,56,14,2],
[58,56,14,3],
[58,56,14,4],
[58,56,14,5],
[58,56,14,6],
[58,56,14,7],
[58,56,14,8],
[58,56,14,10],
[58,56,14,11],
[58,56,14,12],
[58,56,17,0],
[58,56,17,1],
[58,56,17,2],
[58,56,17,3],
[58,56,17,4],
[58,56,17,5],
[58,56,17,6],
[58,56,17,7],
[58,56,17,8],
[58,56,17,10],
[58,56,17,11],
[58,56,17,12],
[58,56,17,13],
[58,56,19,0],
[58,56,19,1],
[58,56,19,2],
[58,56,19,3],
[58,56,19,4],
[58,56,19,5],
[58,56,19,6],
[58,56,19,7],
[58,56,19,8],
[58,56,19,12],
[58,56,19,17],
[58,56,20,0],
[58,56,20,1],
[58,56,20,2],
[58,56,20,3],
[58,56,20,4],
[58,56,20,5],
[58,56,20,6],
[58,56,20,7],
[58,56,20,11],
[58,56,20,12],
[58,56,20,13],
[58,56,20,14],
[58,56,20,17],
[58,56,20,19],
[58,56,21,0],
[58,56,21,1],
[58,56,21,2],
[58,56,21,4],
[58,56,21,10],
[58,56,21,11],
[58,56,21,12],
[58,56,21,13],
[58,56,21,14],
[58,56,21,17],
[58,56,21,19],
[58,56,21,20],
[58,56,22,0],
[58,56,22,1],
[58,56,22,2],
[58,56,22,3],
[58,56,22,4],
[58,56,22,6],
[58,56,22,7],
[58,56,22,8],
[58,56,22,11],
[58,56,22,12],
[58,56,22,13],
[58,56,22,14],
[58,56,22,17],
[58,56,22,19],
[58,56,22,21],
[58,56,23,0],
[58,56,23,1],
[58,56,23,2],
[58,56,23,5],
[58,56,23,6],
[58,56,23,7],
[58,56,23,8],
[58,56,23,10],
[58,56,23,11],
[58,56,23,12],
[58,56,23,13],
[58,56,23,14],
[58,56,23,17],
[58,56,23,19],
[58,56,23,20],
[58,56,23,21],
[58,56,25,0],
[58,56,25,1],
[58,56,25,2],
[58,56,25,3],
[58,56,25,4],
[58,56,25,5],
[58,56,25,6],
[58,56,25,8],
[58,56,25,10],
[58,56,25,11],
[58,56,25,12],
[58,56,25,13],
[58,56,25,14],
[58,56,25,17],
[58,56,25,19],
[58,56,27,0],
[58,56,27,1],
[58,56,27,2],
[58,56,27,3],
[58,56,27,4],
[58,56,27,5],
[58,56,27,6],
[58,56,27,7],
[58,56,27,8],
[58,56,27,11],
[58,56,27,12],
[58,56,27,13],
[58,56,27,14],
[58,56,27,17],
[58,56,28,0],
[58,56,28,1],
[58,56,28,2],
[58,56,28,3],
[58,56,28,4],
[58,56,28,5],
[58,56,28,6],
[58,56,28,8],
[58,56,28,10],
[58,56,28,11],
[58,56,28,12],
[58,56,28,13],
[58,56,28,14],
[58,56,29,0],
[58,56,29,1],
[58,56,29,2],
[58,56,29,3],
[58,56,29,4],
[58,56,29,5],
[58,56,29,6],
[58,56,29,7],
[58,56,29,8],
[58,56,29,10],
[58,56,29,13],
[58,56,29,14],
[58,56,30,0],
[58,56,30,1],
[58,56,30,2],
[58,56,30,3],
[58,56,30,4],
[58,56,30,5],
[58,56,30,6],
[58,56,30,7],
[58,56,30,8],
[58,56,30,10],
[58,56,30,11],
[58,56,30,12],
[58,56,30,13],
[58,56,31,0],
[58,56,31,1],
[58,56,31,2],
[58,56,31,3],
[58,56,31,4],
[58,56,31,5],
[58,56,31,6],
[58,56,31,7],
[58,56,31,8],
[58,56,31,10],
[58,56,31,11],
[58,56,32,0],
[58,56,32,1],
[58,56,32,2],
[58,56,32,3],
[58,56,32,4],
[58,56,32,5],
[58,56,32,6],
[58,56,32,7],
[58,56,32,8],
[58,56,33,0],
[58,56,33,1],
[58,56,33,2],
[58,56,33,3],
[58,56,33,4],
[58,56,33,5],
[58,56,33,6],
[58,56,33,7],
[58,56,34,0],
[58,56,34,1],
[58,56,34,2],
[58,56,34,3],
[58,56,34,4],
[58,56,34,5],
[58,56,36,0],
[58,56,36,1],
[58,56,36,2],
[58,57,3,0],
[58,57,3,1],
[58,57,3,2],
[58,57,4,0],
[58,57,4,1],
[58,57,4,2],
[58,57,4,3],
[58,57,5,0],
[58,57,5,1],
[58,57,5,2],
[58,57,5,4],
[58,57,6,0],
[58,57,6,1],
[58,57,6,2],
[58,57,6,4],
[58,57,7,0],
[58,57,7,1],
[58,57,7,2],
[58,57,7,3],
[58,57,7,4],
[58,57,7,5],
[58,57,7,6],
[58,57,8,0],
[58,57,8,1],
[58,57,8,2],
[58,57,8,4],
[58,57,8,7],
[58,57,9,0],
[58,57,9,1],
[58,57,9,2],
[58,57,9,3],
[58,57,9,4],
[58,57,9,5],
[58,57,9,6],
[58,57,9,7],
[58,57,9,8],
[58,57,10,0],
[58,57,10,1],
[58,57,10,2],
[58,57,10,3],
[58,57,10,4],
[58,57,10,5],
[58,57,10,6],
[58,57,10,7],
[58,57,10,8],
[58,57,10,9],
[58,57,11,0],
[58,57,11,1],
[58,57,11,2],
[58,57,11,3],
[58,57,11,4],
[58,57,11,5],
[58,57,11,6],
[58,57,11,7],
[58,57,11,8],
[58,57,11,9],
[58,57,11,10],
[58,57,12,0],
[58,57,12,1],
[58,57,12,2],
[58,57,12,3],
[58,57,12,4],
[58,57,12,5],
[58,57,12,6],
[58,57,12,7],
[58,57,12,8],
[58,57,12,9],
[58,57,12,10],
[58,57,13,0],
[58,57,13,1],
[58,57,13,2],
[58,57,13,3],
[58,57,13,4],
[58,57,13,5],
[58,57,13,6],
[58,57,13,7],
[58,57,13,8],
[58,57,13,9],
[58,57,13,10],
[58,57,13,11],
[58,57,13,12],
[58,57,14,0],
[58,57,14,1],
[58,57,14,2],
[58,57,14,3],
[58,57,14,4],
[58,57,14,5],
[58,57,14,6],
[58,57,14,7],
[58,57,14,8],
[58,57,14,9],
[58,57,14,10],
[58,57,14,11],
[58,57,14,12],
[58,57,15,0],
[58,57,15,1],
[58,57,15,2],
[58,57,15,3],
[58,57,15,4],
[58,57,15,5],
[58,57,15,6],
[58,57,15,7],
[58,57,15,8],
[58,57,15,10],
[58,57,15,11],
[58,57,15,12],
[58,57,15,13],
[58,57,17,0],
[58,57,17,1],
[58,57,17,2],
[58,57,17,3],
[58,57,17,4],
[58,57,17,5],
[58,57,17,6],
[58,57,17,7],
[58,57,17,8],
[58,57,17,9],
[58,57,17,10],
[58,57,17,11],
[58,57,17,14],
[58,57,17,15],
[58,57,18,0],
[58,57,18,1],
[58,57,18,2],
[58,57,18,3],
[58,57,18,4],
[58,57,18,5],
[58,57,18,6],
[58,57,18,7],
[58,57,18,8],
[58,57,18,12],
[58,57,18,13],
[58,57,18,14],
[58,57,18,17],
[58,57,19,0],
[58,57,19,1],
[58,57,19,2],
[58,57,19,3],
[58,57,19,4],
[58,57,19,5],
[58,57,19,6],
[58,57,19,7],
[58,57,19,10],
[58,57,19,11],
[58,57,19,12],
[58,57,19,15],
[58,57,19,17],
[58,57,19,18],
[58,57,20,0],
[58,57,20,1],
[58,57,20,2],
[58,57,20,3],
[58,57,20,4],
[58,57,20,5],
[58,57,20,8],
[58,57,20,9],
[58,57,20,11],
[58,57,20,12],
[58,57,20,13],
[58,57,20,14],
[58,57,20,15],
[58,57,20,17],
[58,57,20,18],
[58,57,20,19],
[58,57,21,0],
[58,57,21,1],
[58,57,21,2],
[58,57,21,4],
[58,57,21,7],
[58,57,21,9],
[58,57,21,10],
[58,57,21,11],
[58,57,21,12],
[58,57,21,13],
[58,57,21,14],
[58,57,21,15],
[58,57,21,17],
[58,57,21,18],
[58,57,21,19],
[58,57,21,20],
[58,57,22,0],
[58,57,22,1],
[58,57,22,2],
[58,57,22,5],
[58,57,22,6],
[58,57,22,7],
[58,57,22,8],
[58,57,22,9],
[58,57,22,11],
[58,57,22,12],
[58,57,22,13],
[58,57,22,14],
[58,57,22,15],
[58,57,22,17],
[58,57,22,18],
[58,57,22,19],
[58,57,22,21],
[58,57,25,0],
[58,57,25,1],
[58,57,25,2],
[58,57,25,3],
[58,57,25,4],
[58,57,25,5],
[58,57,25,6],
[58,57,25,8],
[58,57,25,9],
[58,57,25,10],
[58,57,25,11],
[58,57,25,12],
[58,57,25,13],
[58,57,25,14],
[58,57,25,15],
[58,57,25,17],
[58,57,25,18],
[58,57,26,0],
[58,57,26,1],
[58,57,26,2],
[58,57,26,3],
[58,57,26,4],
[58,57,26,5],
[58,57,26,6],
[58,57,26,7],
[58,57,26,8],
[58,57,26,10],
[58,57,26,11],
[58,57,26,12],
[58,57,26,13],
[58,57,26,14],
[58,57,26,17],
[58,57,27,0],
[58,57,27,1],
[58,57,27,2],
[58,57,27,3],
[58,57,27,4],
[58,57,27,5],
[58,57,27,6],
[58,57,27,7],
[58,57,27,8],
[58,57,27,9],
[58,57,27,11],
[58,57,27,12],
[58,57,27,13],
[58,57,27,14],
[58,57,27,15],
[58,57,28,0],
[58,57,28,1],
[58,57,28,2],
[58,57,28,3],
[58,57,28,4],
[58,57,28,5],
[58,57,28,6],
[58,57,28,8],
[58,57,28,9],
[58,57,28,10],
[58,57,28,11],
[58,57,28,12],
[58,57,28,13],
[58,57,28,14],
[58,57,29,0],
[58,57,29,1],
[58,57,29,2],
[58,57,29,3],
[58,57,29,4],
[58,57,29,5],
[58,57,29,6],
[58,57,29,7],
[58,57,29,8],
[58,57,29,9],
[58,57,29,10],
[58,57,29,13],
[58,57,32,0],
[58,57,32,1],
[58,57,32,2],
[58,57,32,3],
[58,57,32,4],
[58,57,32,5],
[58,57,32,6],
[58,57,32,7],
[58,57,33,0],
[58,57,33,1],
[58,57,33,2],
[58,57,33,3],
[58,57,33,4],
[58,57,33,5],
[58,57,34,0],
[58,57,34,1],
[58,57,34,2],
[58,57,34,3],
[58,57,34,4],
[58,57,35,0],
[58,57,35,1],
[58,57,35,2],
[59,4,3,0],
[59,4,3,1],
[59,4,3,2],
[59,5,4,0],
[59,5,4,1],
[59,5,4,2],
[59,6,4,0],
[59,6,4,1],
[59,6,4,2],
[59,7,3,0],
[59,7,3,1],
[59,7,3,2],
[59,7,4,0],
[59,7,4,1],
[59,7,4,2],
[59,7,4,3],
[59,7,5,0],
[59,7,5,1],
[59,7,5,2],
[59,7,5,4],
[59,7,6,0],
[59,7,6,1],
[59,7,6,2],
[59,7,6,4],
[59,8,4,0],
[59,8,4,1],
[59,8,4,2],
[59,8,7,0],
[59,8,7,1],
[59,8,7,2],
[59,8,7,4],
[59,10,3,0],
[59,10,3,1],
[59,10,3,2],
[59,10,4,0],
[59,10,4,1],
[59,10,4,2],
[59,10,4,3],
[59,10,5,0],
[59,10,5,1],
[59,10,5,2],
[59,10,5,4],
[59,10,6,0],
[59,10,6,1],
[59,10,6,2],
[59,10,6,4],
[59,10,7,0],
[59,10,7,1],
[59,10,7,2],
[59,10,7,3],
[59,10,7,4],
[59,10,7,5],
[59,10,7,6],
[59,10,8,0],
[59,10,8,1],
[59,10,8,2],
[59,10,8,4],
[59,10,8,7],
[59,11,3,0],
[59,11,3,1],
[59,11,3,2],
[59,11,4,0],
[59,11,4,1],
[59,11,4,2],
[59,11,4,3],
[59,11,5,0],
[59,11,5,1],
[59,11,5,2],
[59,11,5,4],
[59,11,6,0],
[59,11,6,1],
[59,11,6,2],
[59,11,6,4],
[59,11,7,0],
[59,11,7,1],
[59,11,7,2],
[59,11,7,3],
[59,11,7,4],
[59,11,7,5],
[59,11,7,6],
[59,11,8,0],
[59,11,8,1],
[59,11,8,2],
[59,11,8,4],
[59,11,8,7],
[59,11,10,0],
[59,11,10,1],
[59,11,10,2],
[59,11,10,3],
[59,11,10,4],
[59,11,10,5],
[59,11,10,6],
[59,11,10,7],
[59,11,10,8],
[59,12,3,0],
[59,12,3,1],
[59,12,3,2],
[59,12,4,0],
[59,12,4,1],
[59,12,4,2],
[59,12,4,3],
[59,12,5,0],
[59,12,5,1],
[59,12,5,2],
[59,12,5,4],
[59,12,6,0],
[59,12,6,1],
[59,12,6,2],
[59,12,6,4],
[59,12,7,0],
[59,12,7,1],
[59,12,7,2],
[59,12,7,3],
[59,12,7,4],
[59,12,7,5],
[59,12,7,6],
[59,12,8,0],
[59,12,8,1],
[59,12,8,2],
[59,12,8,4],
[59,12,8,7],
[59,12,10,0],
[59,12,10,1],
[59,12,10,2],
[59,12,10,3],
[59,12,10,4],
[59,12,10,5],
[59,12,10,6],
[59,12,10,7],
[59,12,10,8],
[59,13,3,0],
[59,13,3,1],
[59,13,3,2],
[59,13,4,0],
[59,13,4,1],
[59,13,4,2],
[59,13,4,3],
[59,13,5,0],
[59,13,5,1],
[59,13,5,2],
[59,13,5,4],
[59,13,6,0],
[59,13,6,1],
[59,13,6,2],
[59,13,6,4],
[59,13,7,0],
[59,13,7,1],
[59,13,7,2],
[59,13,7,3],
[59,13,7,4],
[59,13,7,5],
[59,13,7,6],
[59,13,8,0],
[59,13,8,1],
[59,13,8,2],
[59,13,8,4],
[59,13,8,7],
[59,13,10,0],
[59,13,10,1],
[59,13,10,2],
[59,13,10,3],
[59,13,10,4],
[59,13,10,5],
[59,13,10,6],
[59,13,10,7],
[59,13,10,8],
[59,13,11,0],
[59,13,11,1],
[59,13,11,2],
[59,13,11,3],
[59,13,11,4],
[59,13,11,5],
[59,13,11,6],
[59,13,11,7],
[59,13,11,8],
[59,13,11,10],
[59,13,12,0],
[59,13,12,1],
[59,13,12,2],
[59,13,12,3],
[59,13,12,4],
[59,13,12,5],
[59,13,12,6],
[59,13,12,7],
[59,13,12,8],
[59,13,12,10],
[59,14,3,0],
[59,14,3,1],
[59,14,3,2],
[59,14,4,0],
[59,14,4,1],
[59,14,4,2],
[59,14,4,3],
[59,14,5,0],
[59,14,5,1],
[59,14,5,2],
[59,14,5,4],
[59,14,6,0],
[59,14,6,1],
[59,14,6,2],
[59,14,6,4],
[59,14,7,0],
[59,14,7,1],
[59,14,7,2],
[59,14,7,3],
[59,14,7,4],
[59,14,7,5],
[59,14,7,6],
[59,14,8,0],
[59,14,8,1],
[59,14,8,2],
[59,14,8,4],
[59,14,8,7],
[59,14,10,0],
[59,14,10,1],
[59,14,10,2],
[59,14,10,3],
[59,14,10,4],
[59,14,10,5],
[59,14,10,6],
[59,14,10,7],
[59,14,10,8],
[59,14,11,0],
[59,14,11,1],
[59,14,11,2],
[59,14,11,3],
[59,14,11,4],
[59,14,11,5],
[59,14,11,6],
[59,14,11,7],
[59,14,11,8],
[59,14,11,10],
[59,14,12,0],
[59,14,12,1],
[59,14,12,2],
[59,14,12,3],
[59,14,12,4],
[59,14,12,5],
[59,14,12,6],
[59,14,12,7],
[59,14,12,8],
[59,14,12,10],
[59,16,3,0],
[59,16,3,1],
[59,16,3,2],
[59,16,4,0],
[59,16,4,1],
[59,16,4,2],
[59,16,4,3],
[59,16,5,0],
[59,16,5,1],
[59,16,5,2],
[59,16,5,4],
[59,16,6,0],
[59,16,6,1],
[59,16,6,2],
[59,16,6,4],
[59,16,7,0],
[59,16,7,1],
[59,16,7,2],
[59,16,7,3],
[59,16,7,4],
[59,16,7,5],
[59,16,7,6],
[59,16,8,0],
[59,16,8,1],
[59,16,8,2],
[59,16,8,4],
[59,16,8,7],
[59,16,10,0],
[59,16,10,1],
[59,16,10,2],
[59,16,10,3],
[59,16,10,4],
[59,16,10,5],
[59,16,10,6],
[59,16,10,7],
[59,16,10,8],
[59,16,11,0],
[59,16,11,1],
[59,16,11,2],
[59,16,11,3],
[59,16,11,4],
[59,16,11,5],
[59,16,11,6],
[59,16,11,7],
[59,16,11,8],
[59,16,11,10],
[59,16,12,0],
[59,16,12,1],
[59,16,12,2],
[59,16,12,3],
[59,16,12,4],
[59,16,12,5],
[59,16,12,6],
[59,16,12,7],
[59,16,12,8],
[59,16,12,10],
[59,16,13,0],
[59,16,13,1],
[59,16,13,2],
[59,16,13,3],
[59,16,13,4],
[59,16,13,5],
[59,16,13,6],
[59,16,13,7],
[59,16,13,8],
[59,16,13,10],
[59,16,13,11],
[59,16,13,12],
[59,16,14,0],
[59,16,14,1],
[59,16,14,2],
[59,16,14,3],
[59,16,14,4],
[59,16,14,5],
[59,16,14,6],
[59,16,14,7],
[59,16,14,8],
[59,16,14,10],
[59,16,14,11],
[59,16,14,12],
[59,17,3,0],
[59,17,3,1],
[59,17,3,2],
[59,17,4,0],
[59,17,4,1],
[59,17,4,2],
[59,17,4,3],
[59,17,5,0],
[59,17,5,1],
[59,17,5,2],
[59,17,5,4],
[59,17,6,0],
[59,17,6,1],
[59,17,6,2],
[59,17,6,4],
[59,17,7,0],
[59,17,7,1],
[59,17,7,2],
[59,17,7,3],
[59,17,7,4],
[59,17,7,5],
[59,17,7,6],
[59,17,8,0],
[59,17,8,1],
[59,17,8,2],
[59,17,8,4],
[59,17,8,7],
[59,17,10,0],
[59,17,10,1],
[59,17,10,2],
[59,17,10,3],
[59,17,10,4],
[59,17,10,5],
[59,17,10,6],
[59,17,10,7],
[59,17,10,8],
[59,17,11,0],
[59,17,11,1],
[59,17,11,2],
[59,17,11,3],
[59,17,11,4],
[59,17,11,5],
[59,17,11,6],
[59,17,11,7],
[59,17,11,8],
[59,17,11,10],
[59,17,12,0],
[59,17,12,1],
[59,17,12,2],
[59,17,12,3],
[59,17,12,4],
[59,17,12,5],
[59,17,12,6],
[59,17,12,7],
[59,17,12,8],
[59,17,12,10],
[59,17,13,0],
[59,17,13,1],
[59,17,13,2],
[59,17,13,3],
[59,17,13,4],
[59,17,13,5],
[59,17,13,6],
[59,17,13,7],
[59,17,13,8],
[59,17,13,10],
[59,17,13,11],
[59,17,13,12],
[59,17,14,0],
[59,17,14,1],
[59,17,14,2],
[59,17,14,3],
[59,17,14,4],
[59,17,14,5],
[59,17,14,6],
[59,17,14,7],
[59,17,14,8],
[59,17,14,10],
[59,17,14,11],
[59,17,14,12],
[59,17,16,0],
[59,17,16,1],
[59,17,16,2],
[59,17,16,3],
[59,17,16,4],
[59,17,16,5],
[59,17,16,6],
[59,17,16,7],
[59,17,16,8],
[59,17,16,10],
[59,17,16,11],
[59,17,16,12],
[59,17,16,13],
[59,17,16,14],
[59,19,3,0],
[59,19,3,1],
[59,19,3,2],
[59,19,4,0],
[59,19,4,1],
[59,19,4,2],
[59,19,4,3],
[59,19,5,0],
[59,19,5,1],
[59,19,5,2],
[59,19,5,4],
[59,19,6,0],
[59,19,6,1],
[59,19,6,2],
[59,19,6,4],
[59,19,7,0],
[59,19,7,1],
[59,19,7,2],
[59,19,7,3],
[59,19,7,4],
[59,19,7,5],
[59,19,7,6],
[59,19,8,0],
[59,19,8,1],
[59,19,8,2],
[59,19,8,4],
[59,19,8,7],
[59,19,10,0],
[59,19,10,1],
[59,19,10,2],
[59,19,10,3],
[59,19,10,4],
[59,19,10,5],
[59,19,10,6],
[59,19,10,7],
[59,19,10,8],
[59,19,11,0],
[59,19,11,1],
[59,19,11,2],
[59,19,11,3],
[59,19,11,4],
[59,19,11,5],
[59,19,11,6],
[59,19,11,7],
[59,19,11,8],
[59,19,11,10],
[59,19,12,0],
[59,19,12,1],
[59,19,12,2],
[59,19,12,3],
[59,19,12,4],
[59,19,12,5],
[59,19,12,6],
[59,19,12,7],
[59,19,12,8],
[59,19,12,10],
[59,19,16,0],
[59,19,16,1],
[59,19,16,2],
[59,19,16,3],
[59,19,16,4],
[59,19,16,5],
[59,19,16,6],
[59,19,16,7],
[59,19,16,8],
[59,19,16,10],
[59,19,16,11],
[59,19,16,12],
[59,19,17,0],
[59,19,17,1],
[59,19,17,2],
[59,19,17,3],
[59,19,17,4],
[59,19,17,5],
[59,19,17,6],
[59,19,17,7],
[59,19,17,8],
[59,19,17,10],
[59,19,17,11],
[59,19,17,12],
[59,19,17,16],
[59,20,3,0],
[59,20,3,1],
[59,20,3,2],
[59,20,4,0],
[59,20,4,1],
[59,20,4,2],
[59,20,4,3],
[59,20,5,0],
[59,20,5,1],
[59,20,5,2],
[59,20,5,4],
[59,20,6,0],
[59,20,6,1],
[59,20,6,2],
[59,20,6,4],
[59,20,7,0],
[59,20,7,1],
[59,20,7,2],
[59,20,7,3],
[59,20,7,4],
[59,20,7,5],
[59,20,7,6],
[59,20,8,0],
[59,20,8,1],
[59,20,8,2],
[59,20,8,4],
[59,20,8,7],
[59,20,11,0],
[59,20,11,1],
[59,20,11,2],
[59,20,11,3],
[59,20,11,4],
[59,20,11,5],
[59,20,11,6],
[59,20,11,7],
[59,20,11,8],
[59,20,12,0],
[59,20,12,1],
[59,20,12,2],
[59,20,12,3],
[59,20,12,4],
[59,20,12,5],
[59,20,12,6],
[59,20,12,7],
[59,20,12,8],
[59,20,13,0],
[59,20,13,1],
[59,20,13,2],
[59,20,13,3],
[59,20,13,4],
[59,20,13,5],
[59,20,13,6],
[59,20,13,7],
[59,20,13,8],
[59,20,13,11],
[59,20,13,12],
[59,20,14,0],
[59,20,14,1],
[59,20,14,2],
[59,20,14,3],
[59,20,14,4],
[59,20,14,5],
[59,20,14,6],
[59,20,14,7],
[59,20,14,8],
[59,20,14,11],
[59,20,14,12],
[59,20,16,0],
[59,20,16,1],
[59,20,16,2],
[59,20,16,3],
[59,20,16,4],
[59,20,16,5],
[59,20,16,6],
[59,20,16,7],
[59,20,16,8],
[59,20,16,11],
[59,20,16,12],
[59,20,16,13],
[59,20,16,14],
[59,20,17,0],
[59,20,17,1],
[59,20,17,2],
[59,20,17,3],
[59,20,17,4],
[59,20,17,5],
[59,20,17,6],
[59,20,17,7],
[59,20,17,8],
[59,20,17,11],
[59,20,17,12],
[59,20,17,13],
[59,20,17,14],
[59,20,17,16],
[59,20,19,0],
[59,20,19,1],
[59,20,19,2],
[59,20,19,3],
[59,20,19,4],
[59,20,19,5],
[59,20,19,6],
[59,20,19,7],
[59,20,19,8],
[59,20,19,11],
[59,20,19,12],
[59,20,19,16],
[59,20,19,17],
[59,21,4,0],
[59,21,4,1],
[59,21,4,2],
[59,21,7,0],
[59,21,7,1],
[59,21,7,2],
[59,21,7,4],
[59,21,10,0],
[59,21,10,1],
[59,21,10,2],
[59,21,10,4],
[59,21,10,7],
[59,21,11,0],
[59,21,11,1],
[59,21,11,2],
[59,21,11,4],
[59,21,11,7],
[59,21,11,10],
[59,21,12,0],
[59,21,12,1],
[59,21,12,2],
[59,21,12,4],
[59,21,12,7],
[59,21,12,10],
[59,21,13,0],
[59,21,13,1],
[59,21,13,2],
[59,21,13,4],
[59,21,13,7],
[59,21,13,10],
[59,21,13,11],
[59,21,13,12],
[59,21,14,0],
[59,21,14,1],
[59,21,14,2],
[59,21,14,4],
[59,21,14,7],
[59,21,14,10],
[59,21,14,11],
[59,21,14,12],
[59,21,16,0],
[59,21,16,1],
[59,21,16,2],
[59,21,16,4],
[59,21,16,7],
[59,21,16,10],
[59,21,16,11],
[59,21,16,12],
[59,21,16,13],
[59,21,16,14],
[59,21,17,0],
[59,21,17,1],
[59,21,17,2],
[59,21,17,4],
[59,21,17,7],
[59,21,17,10],
[59,21,17,11],
[59,21,17,12],
[59,21,17,13],
[59,21,17,14],
[59,21,17,16],
[59,21,19,0],
[59,21,19,1],
[59,21,19,2],
[59,21,19,4],
[59,21,19,7],
[59,21,19,10],
[59,21,19,11],
[59,21,19,12],
[59,21,19,16],
[59,21,19,17],
[59,21,20,0],
[59,21,20,1],
[59,21,20,2],
[59,21,20,4],
[59,21,20,7],
[59,21,20,11],
[59,21,20,12],
[59,21,20,13],
[59,21,20,14],
[59,21,20,16],
[59,21,20,17],
[59,21,20,19],
[59,22,3,0],
[59,22,3,1],
[59,22,3,2],
[59,22,4,0],
[59,22,4,1],
[59,22,4,2],
[59,22,4,3],
[59,22,5,0],
[59,22,5,1],
[59,22,5,2],
[59,22,5,4],
[59,22,6,0],
[59,22,6,1],
[59,22,6,2],
[59,22,6,4],
[59,22,7,0],
[59,22,7,1],
[59,22,7,2],
[59,22,7,3],
[59,22,7,4],
[59,22,7,5],
[59,22,7,6],
[59,22,8,0],
[59,22,8,1],
[59,22,8,2],
[59,22,8,4],
[59,22,8,7],
[59,22,11,0],
[59,22,11,1],
[59,22,11,2],
[59,22,11,3],
[59,22,11,4],
[59,22,11,5],
[59,22,11,6],
[59,22,11,7],
[59,22,11,8],
[59,22,12,0],
[59,22,12,1],
[59,22,12,2],
[59,22,12,3],
[59,22,12,4],
[59,22,12,5],
[59,22,12,6],
[59,22,12,7],
[59,22,12,8],
[59,22,13,0],
[59,22,13,1],
[59,22,13,2],
[59,22,13,3],
[59,22,13,4],
[59,22,13,5],
[59,22,13,6],
[59,22,13,7],
[59,22,13,8],
[59,22,13,11],
[59,22,13,12],
[59,22,14,0],
[59,22,14,1],
[59,22,14,2],
[59,22,14,3],
[59,22,14,4],
[59,22,14,5],
[59,22,14,6],
[59,22,14,7],
[59,22,14,8],
[59,22,14,11],
[59,22,14,12],
[59,22,16,0],
[59,22,16,1],
[59,22,16,2],
[59,22,16,3],
[59,22,16,4],
[59,22,16,5],
[59,22,16,6],
[59,22,16,7],
[59,22,16,8],
[59,22,16,11],
[59,22,16,12],
[59,22,16,13],
[59,22,16,14],
[59,22,17,0],
[59,22,17,1],
[59,22,17,2],
[59,22,17,3],
[59,22,17,4],
[59,22,17,5],
[59,22,17,6],
[59,22,17,7],
[59,22,17,8],
[59,22,17,11],
[59,22,17,12],
[59,22,17,13],
[59,22,17,14],
[59,22,17,16],
[59,22,19,0],
[59,22,19,1],
[59,22,19,2],
[59,22,19,3],
[59,22,19,4],
[59,22,19,5],
[59,22,19,6],
[59,22,19,7],
[59,22,19,8],
[59,22,19,11],
[59,22,19,12],
[59,22,19,16],
[59,22,19,17],
[59,22,21,0],
[59,22,21,1],
[59,22,21,2],
[59,22,21,4],
[59,22,21,7],
[59,22,21,11],
[59,22,21,12],
[59,22,21,13],
[59,22,21,14],
[59,22,21,16],
[59,22,21,17],
[59,22,21,19],
[59,23,3,0],
[59,23,3,1],
[59,23,3,2],
[59,23,4,0],
[59,23,4,1],
[59,23,4,2],
[59,23,4,3],
[59,23,5,0],
[59,23,5,1],
[59,23,5,2],
[59,23,5,4],
[59,23,6,0],
[59,23,6,1],
[59,23,6,2],
[59,23,6,4],
[59,23,7,0],
[59,23,7,1],
[59,23,7,2],
[59,23,7,3],
[59,23,7,4],
[59,23,7,5],
[59,23,7,6],
[59,23,8,0],
[59,23,8,1],
[59,23,8,2],
[59,23,8,4],
[59,23,8,7],
[59,23,10,0],
[59,23,10,1],
[59,23,10,2],
[59,23,10,3],
[59,23,10,4],
[59,23,10,5],
[59,23,10,6],
[59,23,10,7],
[59,23,10,8],
[59,23,11,0],
[59,23,11,1],
[59,23,11,2],
[59,23,11,3],
[59,23,11,4],
[59,23,11,5],
[59,23,11,6],
[59,23,11,7],
[59,23,11,8],
[59,23,11,10],
[59,23,12,0],
[59,23,12,1],
[59,23,12,2],
[59,23,12,3],
[59,23,12,4],
[59,23,12,5],
[59,23,12,6],
[59,23,12,7],
[59,23,12,8],
[59,23,12,10],
[59,23,13,0],
[59,23,13,1],
[59,23,13,2],
[59,23,13,3],
[59,23,13,4],
[59,23,13,5],
[59,23,13,6],
[59,23,13,7],
[59,23,13,8],
[59,23,13,10],
[59,23,13,11],
[59,23,13,12],
[59,23,14,0],
[59,23,14,1],
[59,23,14,2],
[59,23,14,3],
[59,23,14,4],
[59,23,14,5],
[59,23,14,6],
[59,23,14,7],
[59,23,14,8],
[59,23,14,10],
[59,23,14,11],
[59,23,14,12],
[59,23,16,0],
[59,23,16,1],
[59,23,16,2],
[59,23,16,3],
[59,23,16,4],
[59,23,16,5],
[59,23,16,6],
[59,23,16,7],
[59,23,16,8],
[59,23,16,10],
[59,23,16,11],
[59,23,16,12],
[59,23,16,13],
[59,23,16,14],
[59,23,17,0],
[59,23,17,1],
[59,23,17,2],
[59,23,17,3],
[59,23,17,4],
[59,23,17,5],
[59,23,17,6],
[59,23,17,7],
[59,23,17,8],
[59,23,17,10],
[59,23,17,11],
[59,23,17,12],
[59,23,17,13],
[59,23,17,14],
[59,23,17,16],
[59,23,19,0],
[59,23,19,1],
[59,23,19,2],
[59,23,19,3],
[59,23,19,4],
[59,23,19,5],
[59,23,19,6],
[59,23,19,7],
[59,23,19,8],
[59,23,19,10],
[59,23,19,11],
[59,23,19,12],
[59,23,19,16],
[59,23,19,17],
[59,23,20,0],
[59,23,20,1],
[59,23,20,2],
[59,23,20,3],
[59,23,20,4],
[59,23,20,5],
[59,23,20,6],
[59,23,20,7],
[59,23,20,8],
[59,23,20,11],
[59,23,20,12],
[59,23,20,13],
[59,23,20,14],
[59,23,20,16],
[59,23,20,17],
[59,23,20,19],
[59,23,21,0],
[59,23,21,1],
[59,23,21,2],
[59,23,21,4],
[59,23,21,7],
[59,23,21,10],
[59,23,21,11],
[59,23,21,12],
[59,23,21,13],
[59,23,21,14],
[59,23,21,16],
[59,23,21,17],
[59,23,21,19],
[59,23,21,20],
[59,23,22,0],
[59,23,22,1],
[59,23,22,2],
[59,23,22,3],
[59,23,22,4],
[59,23,22,5],
[59,23,22,6],
[59,23,22,7],
[59,23,22,8],
[59,23,22,11],
[59,23,22,12],
[59,23,22,13],
[59,23,22,14],
[59,23,22,16],
[59,23,22,17],
[59,23,22,19],
[59,23,22,21],
[59,24,3,0],
[59,24,3,1],
[59,24,3,2],
[59,24,4,0],
[59,24,4,1],
[59,24,4,2],
[59,24,4,3],
[59,24,5,0],
[59,24,5,1],
[59,24,5,2],
[59,24,5,4],
[59,24,6,0],
[59,24,6,1],
[59,24,6,2],
[59,24,6,4],
[59,24,7,0],
[59,24,7,1],
[59,24,7,2],
[59,24,7,3],
[59,24,7,4],
[59,24,7,5],
[59,24,7,6],
[59,24,8,0],
[59,24,8,1],
[59,24,8,2],
[59,24,8,4],
[59,24,8,7],
[59,24,10,0],
[59,24,10,1],
[59,24,10,2],
[59,24,10,3],
[59,24,10,4],
[59,24,10,5],
[59,24,10,6],
[59,24,10,7],
[59,24,10,8],
[59,24,11,0],
[59,24,11,1],
[59,24,11,2],
[59,24,11,3],
[59,24,11,4],
[59,24,11,5],
[59,24,11,6],
[59,24,11,7],
[59,24,11,8],
[59,24,11,10],
[59,24,12,0],
[59,24,12,1],
[59,24,12,2],
[59,24,12,3],
[59,24,12,4],
[59,24,12,5],
[59,24,12,6],
[59,24,12,7],
[59,24,12,8],
[59,24,12,10],
[59,24,13,0],
[59,24,13,1],
[59,24,13,2],
[59,24,13,3],
[59,24,13,4],
[59,24,13,5],
[59,24,13,6],
[59,24,13,7],
[59,24,13,8],
[59,24,13,10],
[59,24,13,11],
[59,24,13,12],
[59,24,14,0],
[59,24,14,1],
[59,24,14,2],
[59,24,14,3],
[59,24,14,4],
[59,24,14,5],
[59,24,14,6],
[59,24,14,7],
[59,24,14,8],
[59,24,14,10],
[59,24,14,11],
[59,24,14,12],
[59,24,17,0],
[59,24,17,1],
[59,24,17,2],
[59,24,17,3],
[59,24,17,4],
[59,24,17,5],
[59,24,17,6],
[59,24,17,7],
[59,24,17,8],
[59,24,17,10],
[59,24,17,11],
[59,24,17,12],
[59,24,17,13],
[59,24,17,14],
[59,24,19,0],
[59,24,19,1],
[59,24,19,2],
[59,24,19,3],
[59,24,19,4],
[59,24,19,5],
[59,24,19,6],
[59,24,19,7],
[59,24,19,8],
[59,24,19,10],
[59,24,19,11],
[59,24,19,12],
[59,24,19,17],
[59,24,20,0],
[59,24,20,1],
[59,24,20,2],
[59,24,20,3],
[59,24,20,4],
[59,24,20,5],
[59,24,20,6],
[59,24,20,7],
[59,24,20,8],
[59,24,20,11],
[59,24,20,12],
[59,24,20,13],
[59,24,20,14],
[59,24,20,17],
[59,24,20,19],
[59,24,21,0],
[59,24,21,1],
[59,24,21,2],
[59,24,21,4],
[59,24,21,7],
[59,24,21,10],
[59,24,21,11],
[59,24,21,12],
[59,24,21,13],
[59,24,21,14],
[59,24,21,17],
[59,24,21,19],
[59,24,21,20],
[59,24,22,0],
[59,24,22,1],
[59,24,22,2],
[59,24,22,3],
[59,24,22,4],
[59,24,22,5],
[59,24,22,6],
[59,24,22,7],
[59,24,22,8],
[59,24,22,11],
[59,24,22,12],
[59,24,22,13],
[59,24,22,14],
[59,24,22,17],
[59,24,22,19],
[59,24,22,21],
[59,24,23,0],
[59,24,23,1],
[59,24,23,2],
[59,24,23,3],
[59,24,23,4],
[59,24,23,5],
[59,24,23,6],
[59,24,23,7],
[59,24,23,8],
[59,24,23,10],
[59,24,23,11],
[59,24,23,12],
[59,24,23,13],
[59,24,23,14],
[59,24,23,17],
[59,24,23,19],
[59,24,23,20],
[59,24,23,21],
[59,24,23,22],
[59,25,3,0],
[59,25,3,1],
[59,25,3,2],
[59,25,4,0],
[59,25,4,1],
[59,25,4,2],
[59,25,4,3],
[59,25,5,0],
[59,25,5,1],
[59,25,5,2],
[59,25,5,4],
[59,25,6,0],
[59,25,6,1],
[59,25,6,2],
[59,25,6,4],
[59,25,8,0],
[59,25,8,1],
[59,25,8,2],
[59,25,8,4],
[59,25,10,0],
[59,25,10,1],
[59,25,10,2],
[59,25,10,3],
[59,25,10,4],
[59,25,10,5],
[59,25,10,6],
[59,25,10,8],
[59,25,11,0],
[59,25,11,1],
[59,25,11,2],
[59,25,11,3],
[59,25,11,4],
[59,25,11,5],
[59,25,11,6],
[59,25,11,8],
[59,25,11,10],
[59,25,12,0],
[59,25,12,1],
[59,25,12,2],
[59,25,12,3],
[59,25,12,4],
[59,25,12,5],
[59,25,12,6],
[59,25,12,8],
[59,25,12,10],
[59,25,13,0],
[59,25,13,1],
[59,25,13,2],
[59,25,13,3],
[59,25,13,4],
[59,25,13,5],
[59,25,13,6],
[59,25,13,8],
[59,25,13,10],
[59,25,13,11],
[59,25,13,12],
[59,25,14,0],
[59,25,14,1],
[59,25,14,2],
[59,25,14,3],
[59,25,14,4],
[59,25,14,5],
[59,25,14,6],
[59,25,14,8],
[59,25,14,10],
[59,25,14,11],
[59,25,14,12],
[59,25,16,0],
[59,25,16,1],
[59,25,16,2],
[59,25,16,3],
[59,25,16,4],
[59,25,16,5],
[59,25,16,6],
[59,25,16,8],
[59,25,16,10],
[59,25,16,11],
[59,25,16,12],
[59,25,16,13],
[59,25,16,14],
[59,25,17,0],
[59,25,17,1],
[59,25,17,2],
[59,25,17,3],
[59,25,17,4],
[59,25,17,5],
[59,25,17,6],
[59,25,17,8],
[59,25,17,10],
[59,25,17,11],
[59,25,17,12],
[59,25,17,13],
[59,25,17,14],
[59,25,17,16],
[59,25,19,0],
[59,25,19,1],
[59,25,19,2],
[59,25,19,3],
[59,25,19,4],
[59,25,19,5],
[59,25,19,6],
[59,25,19,8],
[59,25,19,10],
[59,25,19,11],
[59,25,19,12],
[59,25,19,16],
[59,25,19,17],
[59,25,20,0],
[59,25,20,1],
[59,25,20,2],
[59,25,20,3],
[59,25,20,4],
[59,25,20,5],
[59,25,20,6],
[59,25,20,8],
[59,25,20,11],
[59,25,20,12],
[59,25,20,13],
[59,25,20,14],
[59,25,20,16],
[59,25,20,17],
[59,25,20,19],
[59,25,21,0],
[59,25,21,1],
[59,25,21,2],
[59,25,21,4],
[59,25,21,10],
[59,25,21,11],
[59,25,21,12],
[59,25,21,13],
[59,25,21,14],
[59,25,21,16],
[59,25,21,17],
[59,25,21,19],
[59,25,21,20],
[59,25,22,0],
[59,25,22,1],
[59,25,22,2],
[59,25,22,3],
[59,25,22,4],
[59,25,22,5],
[59,25,22,6],
[59,25,22,8],
[59,25,22,11],
[59,25,22,12],
[59,25,22,13],
[59,25,22,14],
[59,25,22,16],
[59,25,22,17],
[59,25,22,19],
[59,25,22,21],
[59,25,23,0],
[59,25,23,1],
[59,25,23,2],
[59,25,23,3],
[59,25,23,4],
[59,25,23,5],
[59,25,23,6],
[59,25,23,8],
[59,25,23,10],
[59,25,23,11],
[59,25,23,12],
[59,25,23,13],
[59,25,23,14],
[59,25,23,16],
[59,25,23,17],
[59,25,23,19],
[59,25,23,20],
[59,25,23,21],
[59,25,23,22],
[59,25,24,0],
[59,25,24,1],
[59,25,24,2],
[59,25,24,3],
[59,25,24,4],
[59,25,24,5],
[59,25,24,6],
[59,25,24,8],
[59,25,24,10],
[59,25,24,11],
[59,25,24,12],
[59,25,24,13],
[59,25,24,14],
[59,25,24,17],
[59,25,24,19],
[59,25,24,20],
[59,25,24,21],
[59,25,24,22],
[59,25,24,23],
[59,27,3,0],
[59,27,3,1],
[59,27,3,2],
[59,27,4,0],
[59,27,4,1],
[59,27,4,2],
[59,27,4,3],
[59,27,5,0],
[59,27,5,1],
[59,27,5,2],
[59,27,5,4],
[59,27,6,0],
[59,27,6,1],
[59,27,6,2],
[59,27,6,4],
[59,27,7,0],
[59,27,7,1],
[59,27,7,2],
[59,27,7,3],
[59,27,7,4],
[59,27,7,5],
[59,27,7,6],
[59,27,8,0],
[59,27,8,1],
[59,27,8,2],
[59,27,8,4],
[59,27,8,7],
[59,27,11,0],
[59,27,11,1],
[59,27,11,2],
[59,27,11,3],
[59,27,11,4],
[59,27,11,5],
[59,27,11,6],
[59,27,11,7],
[59,27,11,8],
[59,27,12,0],
[59,27,12,1],
[59,27,12,2],
[59,27,12,3],
[59,27,12,4],
[59,27,12,5],
[59,27,12,6],
[59,27,12,7],
[59,27,12,8],
[59,27,13,0],
[59,27,13,1],
[59,27,13,2],
[59,27,13,3],
[59,27,13,4],
[59,27,13,5],
[59,27,13,6],
[59,27,13,7],
[59,27,13,8],
[59,27,13,11],
[59,27,13,12],
[59,27,14,0],
[59,27,14,1],
[59,27,14,2],
[59,27,14,3],
[59,27,14,4],
[59,27,14,5],
[59,27,14,6],
[59,27,14,7],
[59,27,14,8],
[59,27,14,11],
[59,27,14,12],
[59,27,16,0],
[59,27,16,1],
[59,27,16,2],
[59,27,16,3],
[59,27,16,4],
[59,27,16,5],
[59,27,16,6],
[59,27,16,7],
[59,27,16,8],
[59,27,16,11],
[59,27,16,12],
[59,27,16,13],
[59,27,16,14],
[59,27,17,0],
[59,27,17,1],
[59,27,17,2],
[59,27,17,3],
[59,27,17,4],
[59,27,17,5],
[59,27,17,6],
[59,27,17,7],
[59,27,17,8],
[59,27,17,11],
[59,27,17,12],
[59,27,17,13],
[59,27,17,14],
[59,27,17,16],
[59,27,19,0],
[59,27,19,1],
[59,27,19,2],
[59,27,19,3],
[59,27,19,4],
[59,27,19,5],
[59,27,19,6],
[59,27,19,7],
[59,27,19,8],
[59,27,19,11],
[59,27,19,12],
[59,27,19,16],
[59,27,19,17],
[59,27,21,0],
[59,27,21,1],
[59,27,21,2],
[59,27,21,4],
[59,27,21,7],
[59,27,21,11],
[59,27,21,12],
[59,27,21,13],
[59,27,21,14],
[59,27,21,16],
[59,27,21,17],
[59,27,21,19],
[59,27,23,0],
[59,27,23,1],
[59,27,23,2],
[59,27,23,3],
[59,27,23,4],
[59,27,23,5],
[59,27,23,6],
[59,27,23,7],
[59,27,23,8],
[59,27,23,11],
[59,27,23,12],
[59,27,23,13],
[59,27,23,14],
[59,27,23,16],
[59,27,23,17],
[59,27,23,19],
[59,27,23,21],
[59,27,24,0],
[59,27,24,1],
[59,27,24,2],
[59,27,24,3],
[59,27,24,4],
[59,27,24,5],
[59,27,24,6],
[59,27,24,7],
[59,27,24,8],
[59,27,24,11],
[59,27,24,12],
[59,27,24,13],
[59,27,24,14],
[59,27,24,17],
[59,27,24,19],
[59,27,24,21],
[59,27,24,23],
[59,27,25,0],
[59,27,25,1],
[59,27,25,2],
[59,27,25,3],
[59,27,25,4],
[59,27,25,5],
[59,27,25,6],
[59,27,25,8],
[59,27,25,11],
[59,27,25,12],
[59,27,25,13],
[59,27,25,14],
[59,27,25,16],
[59,27,25,17],
[59,27,25,19],
[59,27,25,21],
[59,27,25,23],
[59,27,25,24],
[59,28,3,0],
[59,28,3,1],
[59,28,3,2],
[59,28,4,0],
[59,28,4,1],
[59,28,4,2],
[59,28,4,3],
[59,28,5,0],
[59,28,5,1],
[59,28,5,2],
[59,28,5,4],
[59,28,6,0],
[59,28,6,1],
[59,28,6,2],
[59,28,6,4],
[59,28,8,0],
[59,28,8,1],
[59,28,8,2],
[59,28,8,4],
[59,28,10,0],
[59,28,10,1],
[59,28,10,2],
[59,28,10,3],
[59,28,10,4],
[59,28,10,5],
[59,28,10,6],
[59,28,10,8],
[59,28,11,0],
[59,28,11,1],
[59,28,11,2],
[59,28,11,3],
[59,28,11,4],
[59,28,11,5],
[59,28,11,6],
[59,28,11,8],
[59,28,11,10],
[59,28,12,0],
[59,28,12,1],
[59,28,12,2],
[59,28,12,3],
[59,28,12,4],
[59,28,12,5],
[59,28,12,6],
[59,28,12,8],
[59,28,12,10],
[59,28,13,0],
[59,28,13,1],
[59,28,13,2],
[59,28,13,3],
[59,28,13,4],
[59,28,13,5],
[59,28,13,6],
[59,28,13,8],
[59,28,13,10],
[59,28,13,11],
[59,28,13,12],
[59,28,14,0],
[59,28,14,1],
[59,28,14,2],
[59,28,14,3],
[59,28,14,4],
[59,28,14,5],
[59,28,14,6],
[59,28,14,8],
[59,28,14,10],
[59,28,14,11],
[59,28,14,12],
[59,28,16,0],
[59,28,16,1],
[59,28,16,2],
[59,28,16,3],
[59,28,16,4],
[59,28,16,5],
[59,28,16,6],
[59,28,16,8],
[59,28,16,10],
[59,28,16,11],
[59,28,16,12],
[59,28,16,13],
[59,28,16,14],
[59,28,17,0],
[59,28,17,1],
[59,28,17,2],
[59,28,17,3],
[59,28,17,4],
[59,28,17,5],
[59,28,17,6],
[59,28,17,8],
[59,28,17,10],
[59,28,17,11],
[59,28,17,12],
[59,28,17,13],
[59,28,17,14],
[59,28,17,16],
[59,28,19,0],
[59,28,19,1],
[59,28,19,2],
[59,28,19,3],
[59,28,19,4],
[59,28,19,5],
[59,28,19,6],
[59,28,19,8],
[59,28,19,10],
[59,28,19,11],
[59,28,19,12],
[59,28,19,16],
[59,28,19,17],
[59,28,20,0],
[59,28,20,1],
[59,28,20,2],
[59,28,20,3],
[59,28,20,4],
[59,28,20,5],
[59,28,20,6],
[59,28,20,8],
[59,28,20,11],
[59,28,20,12],
[59,28,20,13],
[59,28,20,14],
[59,28,20,16],
[59,28,20,17],
[59,28,20,19],
[59,28,21,0],
[59,28,21,1],
[59,28,21,2],
[59,28,21,4],
[59,28,21,10],
[59,28,21,11],
[59,28,21,12],
[59,28,21,13],
[59,28,21,14],
[59,28,21,16],
[59,28,21,17],
[59,28,21,19],
[59,28,21,20],
[59,28,22,0],
[59,28,22,1],
[59,28,22,2],
[59,28,22,3],
[59,28,22,4],
[59,28,22,5],
[59,28,22,6],
[59,28,22,8],
[59,28,22,11],
[59,28,22,12],
[59,28,22,13],
[59,28,22,14],
[59,28,22,16],
[59,28,22,17],
[59,28,22,19],
[59,28,22,21],
[59,28,23,0],
[59,28,23,1],
[59,28,23,2],
[59,28,23,3],
[59,28,23,4],
[59,28,23,5],
[59,28,23,6],
[59,28,23,8],
[59,28,23,10],
[59,28,23,11],
[59,28,23,12],
[59,28,23,13],
[59,28,23,14],
[59,28,23,16],
[59,28,23,17],
[59,28,23,19],
[59,28,23,20],
[59,28,23,21],
[59,28,23,22],
[59,28,24,0],
[59,28,24,1],
[59,28,24,2],
[59,28,24,3],
[59,28,24,4],
[59,28,24,5],
[59,28,24,6],
[59,28,24,8],
[59,28,24,10],
[59,28,24,11],
[59,28,24,12],
[59,28,24,13],
[59,28,24,14],
[59,28,24,17],
[59,28,24,19],
[59,28,24,20],
[59,28,24,21],
[59,28,24,22],
[59,28,24,23],
[59,28,27,0],
[59,28,27,1],
[59,28,27,2],
[59,28,27,3],
[59,28,27,4],
[59,28,27,5],
[59,28,27,6],
[59,28,27,8],
[59,28,27,11],
[59,28,27,12],
[59,28,27,13],
[59,28,27,14],
[59,28,27,16],
[59,28,27,17],
[59,28,27,19],
[59,28,27,21],
[59,29,3,0],
[59,29,3,1],
[59,29,3,2],
[59,29,4,0],
[59,29,4,1],
[59,29,4,2],
[59,29,4,3],
[59,29,5,0],
[59,29,5,1],
[59,29,5,2],
[59,29,5,4],
[59,29,6,0],
[59,29,6,1],
[59,29,6,2],
[59,29,6,4],
[59,29,7,0],
[59,29,7,1],
[59,29,7,2],
[59,29,7,3],
[59,29,7,4],
[59,29,7,5],
[59,29,7,6],
[59,29,8,0],
[59,29,8,1],
[59,29,8,2],
[59,29,8,4],
[59,29,8,7],
[59,29,10,0],
[59,29,10,1],
[59,29,10,2],
[59,29,10,3],
[59,29,10,4],
[59,29,10,5],
[59,29,10,6],
[59,29,10,7],
[59,29,10,8],
[59,29,13,0],
[59,29,13,1],
[59,29,13,2],
[59,29,13,3],
[59,29,13,4],
[59,29,13,5],
[59,29,13,6],
[59,29,13,7],
[59,29,13,8],
[59,29,13,10],
[59,29,14,0],
[59,29,14,1],
[59,29,14,2],
[59,29,14,3],
[59,29,14,4],
[59,29,14,5],
[59,29,14,6],
[59,29,14,7],
[59,29,14,8],
[59,29,14,10],
[59,29,16,0],
[59,29,16,1],
[59,29,16,2],
[59,29,16,3],
[59,29,16,4],
[59,29,16,5],
[59,29,16,6],
[59,29,16,7],
[59,29,16,8],
[59,29,16,10],
[59,29,16,13],
[59,29,16,14],
[59,29,17,0],
[59,29,17,1],
[59,29,17,2],
[59,29,17,3],
[59,29,17,4],
[59,29,17,5],
[59,29,17,6],
[59,29,17,7],
[59,29,17,8],
[59,29,17,10],
[59,29,17,13],
[59,29,17,14],
[59,29,17,16],
[59,29,19,0],
[59,29,19,1],
[59,29,19,2],
[59,29,19,3],
[59,29,19,4],
[59,29,19,5],
[59,29,19,6],
[59,29,19,7],
[59,29,19,8],
[59,29,19,10],
[59,29,19,16],
[59,29,19,17],
[59,29,20,0],
[59,29,20,1],
[59,29,20,2],
[59,29,20,3],
[59,29,20,4],
[59,29,20,5],
[59,29,20,6],
[59,29,20,7],
[59,29,20,8],
[59,29,20,13],
[59,29,20,14],
[59,29,20,16],
[59,29,20,17],
[59,29,20,19],
[59,29,21,0],
[59,29,21,1],
[59,29,21,2],
[59,29,21,4],
[59,29,21,7],
[59,29,21,10],
[59,29,21,13],
[59,29,21,14],
[59,29,21,16],
[59,29,21,17],
[59,29,21,19],
[59,29,21,20],
[59,29,22,0],
[59,29,22,1],
[59,29,22,2],
[59,29,22,3],
[59,29,22,4],
[59,29,22,5],
[59,29,22,6],
[59,29,22,7],
[59,29,22,8],
[59,29,22,13],
[59,29,22,14],
[59,29,22,16],
[59,29,22,17],
[59,29,22,19],
[59,29,22,21],
[59,29,23,0],
[59,29,23,1],
[59,29,23,2],
[59,29,23,3],
[59,29,23,4],
[59,29,23,5],
[59,29,23,6],
[59,29,23,7],
[59,29,23,8],
[59,29,23,10],
[59,29,23,13],
[59,29,23,14],
[59,29,23,16],
[59,29,23,17],
[59,29,23,19],
[59,29,23,20],
[59,29,23,21],
[59,29,23,22],
[59,29,24,0],
[59,29,24,1],
[59,29,24,2],
[59,29,24,3],
[59,29,24,4],
[59,29,24,5],
[59,29,24,6],
[59,29,24,7],
[59,29,24,8],
[59,29,24,10],
[59,29,24,13],
[59,29,24,14],
[59,29,24,17],
[59,29,24,19],
[59,29,24,20],
[59,29,24,21],
[59,29,24,22],
[59,29,24,23],
[59,29,25,0],
[59,29,25,1],
[59,29,25,2],
[59,29,25,3],
[59,29,25,4],
[59,29,25,5],
[59,29,25,6],
[59,29,25,8],
[59,29,25,10],
[59,29,25,13],
[59,29,25,14],
[59,29,25,16],
[59,29,25,17],
[59,29,25,19],
[59,29,25,20],
[59,29,25,21],
[59,29,25,22],
[59,29,25,23],
[59,29,25,24],
[59,29,27,0],
[59,29,27,1],
[59,29,27,2],
[59,29,27,3],
[59,29,27,4],
[59,29,27,5],
[59,29,27,6],
[59,29,27,7],
[59,29,27,8],
[59,29,27,13],
[59,29,27,14],
[59,29,27,16],
[59,29,27,17],
[59,29,27,19],
[59,29,27,21],
[59,29,27,23],
[59,29,27,24],
[59,29,27,25],
[59,29,28,0],
[59,29,28,1],
[59,29,28,2],
[59,29,28,3],
[59,29,28,4],
[59,29,28,5],
[59,29,28,6],
[59,29,28,8],
[59,29,28,10],
[59,29,28,13],
[59,29,28,14],
[59,29,28,16],
[59,29,28,17],
[59,29,28,19],
[59,29,28,20],
[59,29,28,22],
[59,29,28,23],
[59,29,28,24],
[59,29,28,27],
[59,30,3,0],
[59,30,3,1],
[59,30,3,2],
[59,30,4,0],
[59,30,4,1],
[59,30,4,2],
[59,30,4,3],
[59,30,5,0],
[59,30,5,1],
[59,30,5,2],
[59,30,5,4],
[59,30,6,0],
[59,30,6,1],
[59,30,6,2],
[59,30,6,4],
[59,30,7,0],
[59,30,7,1],
[59,30,7,2],
[59,30,7,3],
[59,30,7,4],
[59,30,7,5],
[59,30,7,6],
[59,30,8,0],
[59,30,8,1],
[59,30,8,2],
[59,30,8,4],
[59,30,8,7],
[59,30,10,0],
[59,30,10,1],
[59,30,10,2],
[59,30,10,3],
[59,30,10,4],
[59,30,10,5],
[59,30,10,6],
[59,30,10,7],
[59,30,10,8],
[59,30,11,0],
[59,30,11,1],
[59,30,11,2],
[59,30,11,3],
[59,30,11,4],
[59,30,11,5],
[59,30,11,6],
[59,30,11,7],
[59,30,11,8],
[59,30,11,10],
[59,30,12,0],
[59,30,12,1],
[59,30,12,2],
[59,30,12,3],
[59,30,12,4],
[59,30,12,5],
[59,30,12,6],
[59,30,12,7],
[59,30,12,8],
[59,30,12,10],
[59,30,13,0],
[59,30,13,1],
[59,30,13,2],
[59,30,13,3],
[59,30,13,4],
[59,30,13,5],
[59,30,13,6],
[59,30,13,7],
[59,30,13,8],
[59,30,13,10],
[59,30,13,11],
[59,30,13,12],
[59,30,14,0],
[59,30,14,1],
[59,30,14,2],
[59,30,14,3],
[59,30,14,4],
[59,30,14,5],
[59,30,14,6],
[59,30,14,7],
[59,30,14,8],
[59,30,14,10],
[59,30,14,11],
[59,30,14,12],
[59,30,16,0],
[59,30,16,1],
[59,30,16,2],
[59,30,16,3],
[59,30,16,4],
[59,30,16,5],
[59,30,16,6],
[59,30,16,7],
[59,30,16,8],
[59,30,16,10],
[59,30,16,11],
[59,30,16,12],
[59,30,16,13],
[59,30,16,14],
[59,30,17,0],
[59,30,17,1],
[59,30,17,2],
[59,30,17,3],
[59,30,17,4],
[59,30,17,5],
[59,30,17,6],
[59,30,17,7],
[59,30,17,8],
[59,30,17,10],
[59,30,17,11],
[59,30,17,12],
[59,30,17,13],
[59,30,17,14],
[59,30,17,16],
[59,30,19,0],
[59,30,19,1],
[59,30,19,2],
[59,30,19,3],
[59,30,19,4],
[59,30,19,5],
[59,30,19,6],
[59,30,19,7],
[59,30,19,8],
[59,30,19,10],
[59,30,19,11],
[59,30,19,12],
[59,30,19,16],
[59,30,19,17],
[59,30,20,0],
[59,30,20,1],
[59,30,20,2],
[59,30,20,3],
[59,30,20,4],
[59,30,20,5],
[59,30,20,6],
[59,30,20,7],
[59,30,20,8],
[59,30,20,11],
[59,30,20,12],
[59,30,20,13],
[59,30,20,14],
[59,30,20,16],
[59,30,20,17],
[59,30,20,19],
[59,30,21,0],
[59,30,21,1],
[59,30,21,2],
[59,30,21,4],
[59,30,21,7],
[59,30,21,10],
[59,30,21,11],
[59,30,21,12],
[59,30,21,13],
[59,30,21,14],
[59,30,21,16],
[59,30,21,17],
[59,30,21,19],
[59,30,21,20],
[59,30,22,0],
[59,30,22,1],
[59,30,22,2],
[59,30,22,3],
[59,30,22,4],
[59,30,22,5],
[59,30,22,6],
[59,30,22,7],
[59,30,22,8],
[59,30,22,11],
[59,30,22,12],
[59,30,22,13],
[59,30,22,14],
[59,30,22,16],
[59,30,22,17],
[59,30,22,19],
[59,30,22,21],
[59,30,24,0],
[59,30,24,1],
[59,30,24,2],
[59,30,24,3],
[59,30,24,4],
[59,30,24,5],
[59,30,24,6],
[59,30,24,7],
[59,30,24,8],
[59,30,24,10],
[59,30,24,11],
[59,30,24,12],
[59,30,24,13],
[59,30,24,14],
[59,30,24,17],
[59,30,24,19],
[59,30,24,20],
[59,30,24,21],
[59,30,24,22],
[59,30,25,0],
[59,30,25,1],
[59,30,25,2],
[59,30,25,3],
[59,30,25,4],
[59,30,25,5],
[59,30,25,6],
[59,30,25,8],
[59,30,25,10],
[59,30,25,11],
[59,30,25,12],
[59,30,25,13],
[59,30,25,14],
[59,30,25,16],
[59,30,25,17],
[59,30,25,19],
[59,30,25,20],
[59,30,25,21],
[59,30,25,22],
[59,30,27,0],
[59,30,27,1],
[59,30,27,2],
[59,30,27,3],
[59,30,27,4],
[59,30,27,5],
[59,30,27,6],
[59,30,27,7],
[59,30,27,8],
[59,30,27,11],
[59,30,27,12],
[59,30,27,13],
[59,30,27,14],
[59,30,27,16],
[59,30,27,17],
[59,30,27,19],
[59,30,27,24],
[59,30,27,25],
[59,30,28,0],
[59,30,28,1],
[59,30,28,2],
[59,30,28,3],
[59,30,28,4],
[59,30,28,5],
[59,30,28,6],
[59,30,28,8],
[59,30,28,10],
[59,30,28,11],
[59,30,28,12],
[59,30,28,13],
[59,30,28,14],
[59,30,28,16],
[59,30,28,17],
[59,30,28,19],
[59,30,28,21],
[59,30,28,22],
[59,30,28,24],
[59,30,28,27],
[59,30,29,0],
[59,30,29,1],
[59,30,29,2],
[59,30,29,3],
[59,30,29,4],
[59,30,29,5],
[59,30,29,6],
[59,30,29,7],
[59,30,29,8],
[59,30,29,10],
[59,30,29,13],
[59,30,29,14],
[59,30,29,16],
[59,30,29,17],
[59,30,29,20],
[59,30,29,21],
[59,30,29,22],
[59,30,29,24],
[59,30,29,25],
[59,30,29,27],
[59,30,29,28],
[59,31,3,0],
[59,31,3,1],
[59,31,3,2],
[59,31,4,0],
[59,31,4,1],
[59,31,4,2],
[59,31,4,3],
[59,31,5,0],
[59,31,5,1],
[59,31,5,2],
[59,31,5,4],
[59,31,6,0],
[59,31,6,1],
[59,31,6,2],
[59,31,6,4],
[59,31,7,0],
[59,31,7,1],
[59,31,7,2],
[59,31,7,3],
[59,31,7,4],
[59,31,7,5],
[59,31,7,6],
[59,31,8,0],
[59,31,8,1],
[59,31,8,2],
[59,31,8,4],
[59,31,8,7],
[59,31,10,0],
[59,31,10,1],
[59,31,10,2],
[59,31,10,3],
[59,31,10,4],
[59,31,10,5],
[59,31,10,6],
[59,31,10,7],
[59,31,10,8],
[59,31,11,0],
[59,31,11,1],
[59,31,11,2],
[59,31,11,3],
[59,31,11,4],
[59,31,11,5],
[59,31,11,6],
[59,31,11,7],
[59,31,11,8],
[59,31,11,10],
[59,31,12,0],
[59,31,12,1],
[59,31,12,2],
[59,31,12,3],
[59,31,12,4],
[59,31,12,5],
[59,31,12,6],
[59,31,12,7],
[59,31,12,8],
[59,31,12,10],
[59,31,13,0],
[59,31,13,1],
[59,31,13,2],
[59,31,13,3],
[59,31,13,4],
[59,31,13,5],
[59,31,13,6],
[59,31,13,7],
[59,31,13,8],
[59,31,13,10],
[59,31,13,11],
[59,31,13,12],
[59,31,14,0],
[59,31,14,1],
[59,31,14,2],
[59,31,14,3],
[59,31,14,4],
[59,31,14,5],
[59,31,14,6],
[59,31,14,7],
[59,31,14,8],
[59,31,14,10],
[59,31,14,11],
[59,31,14,12],
[59,31,16,0],
[59,31,16,1],
[59,31,16,2],
[59,31,16,3],
[59,31,16,4],
[59,31,16,5],
[59,31,16,6],
[59,31,16,7],
[59,31,16,8],
[59,31,16,10],
[59,31,16,11],
[59,31,16,12],
[59,31,16,13],
[59,31,16,14],
[59,31,17,0],
[59,31,17,1],
[59,31,17,2],
[59,31,17,3],
[59,31,17,4],
[59,31,17,5],
[59,31,17,6],
[59,31,17,7],
[59,31,17,8],
[59,31,17,10],
[59,31,17,11],
[59,31,17,12],
[59,31,17,13],
[59,31,17,14],
[59,31,17,16],
[59,31,19,0],
[59,31,19,1],
[59,31,19,2],
[59,31,19,3],
[59,31,19,4],
[59,31,19,5],
[59,31,19,6],
[59,31,19,7],
[59,31,19,8],
[59,31,19,10],
[59,31,19,11],
[59,31,19,12],
[59,31,19,16],
[59,31,19,17],
[59,31,20,0],
[59,31,20,1],
[59,31,20,2],
[59,31,20,3],
[59,31,20,4],
[59,31,20,5],
[59,31,20,6],
[59,31,20,7],
[59,31,20,8],
[59,31,20,11],
[59,31,20,12],
[59,31,20,13],
[59,31,20,14],
[59,31,20,16],
[59,31,20,17],
[59,31,20,19],
[59,31,21,0],
[59,31,21,1],
[59,31,21,2],
[59,31,21,4],
[59,31,21,7],
[59,31,21,10],
[59,31,21,11],
[59,31,21,12],
[59,31,21,13],
[59,31,21,14],
[59,31,21,16],
[59,31,21,17],
[59,31,21,19],
[59,31,21,20],
[59,31,22,0],
[59,31,22,1],
[59,31,22,2],
[59,31,22,3],
[59,31,22,4],
[59,31,22,5],
[59,31,22,6],
[59,31,22,7],
[59,31,22,8],
[59,31,22,11],
[59,31,22,12],
[59,31,22,13],
[59,31,22,14],
[59,31,22,16],
[59,31,22,17],
[59,31,22,19],
[59,31,22,21],
[59,31,24,0],
[59,31,24,1],
[59,31,24,2],
[59,31,24,3],
[59,31,24,4],
[59,31,24,5],
[59,31,24,6],
[59,31,24,7],
[59,31,24,8],
[59,31,24,10],
[59,31,24,11],
[59,31,24,12],
[59,31,24,13],
[59,31,24,14],
[59,31,24,17],
[59,31,24,19],
[59,31,24,20],
[59,31,24,21],
[59,31,24,22],
[59,31,25,0],
[59,31,25,1],
[59,31,25,2],
[59,31,25,3],
[59,31,25,4],
[59,31,25,5],
[59,31,25,6],
[59,31,25,8],
[59,31,25,10],
[59,31,25,11],
[59,31,25,12],
[59,31,25,13],
[59,31,25,14],
[59,31,25,16],
[59,31,25,17],
[59,31,25,19],
[59,31,25,20],
[59,31,25,21],
[59,31,25,24],
[59,31,27,0],
[59,31,27,1],
[59,31,27,2],
[59,31,27,3],
[59,31,27,4],
[59,31,27,5],
[59,31,27,6],
[59,31,27,7],
[59,31,27,8],
[59,31,27,11],
[59,31,27,12],
[59,31,27,13],
[59,31,27,14],
[59,31,27,16],
[59,31,27,17],
[59,31,27,19],
[59,31,27,21],
[59,31,27,24],
[59,31,27,25],
[59,31,28,0],
[59,31,28,1],
[59,31,28,2],
[59,31,28,3],
[59,31,28,4],
[59,31,28,5],
[59,31,28,6],
[59,31,28,8],
[59,31,28,10],
[59,31,28,11],
[59,31,28,12],
[59,31,28,13],
[59,31,28,14],
[59,31,28,16],
[59,31,28,17],
[59,31,28,20],
[59,31,28,21],
[59,31,28,22],
[59,31,28,24],
[59,31,28,27],
[59,31,29,0],
[59,31,29,1],
[59,31,29,2],
[59,31,29,3],
[59,31,29,4],
[59,31,29,5],
[59,31,29,6],
[59,31,29,7],
[59,31,29,8],
[59,31,29,10],
[59,31,29,13],
[59,31,29,14],
[59,31,29,16],
[59,31,29,17],
[59,31,29,19],
[59,31,29,20],
[59,31,29,21],
[59,31,29,22],
[59,31,29,24],
[59,31,29,25],
[59,31,29,27],
[59,31,29,28],
[59,32,3,0],
[59,32,3,1],
[59,32,3,2],
[59,32,4,0],
[59,32,4,1],
[59,32,4,2],
[59,32,4,3],
[59,32,5,0],
[59,32,5,1],
[59,32,5,2],
[59,32,5,4],
[59,32,6,0],
[59,32,6,1],
[59,32,6,2],
[59,32,6,4],
[59,32,7,0],
[59,32,7,1],
[59,32,7,2],
[59,32,7,3],
[59,32,7,4],
[59,32,7,5],
[59,32,7,6],
[59,32,8,0],
[59,32,8,1],
[59,32,8,2],
[59,32,8,4],
[59,32,8,7],
[59,32,10,0],
[59,32,10,1],
[59,32,10,2],
[59,32,10,3],
[59,32,10,4],
[59,32,10,5],
[59,32,10,6],
[59,32,10,7],
[59,32,10,8],
[59,32,13,0],
[59,32,13,1],
[59,32,13,2],
[59,32,13,3],
[59,32,13,4],
[59,32,13,5],
[59,32,13,6],
[59,32,13,7],
[59,32,13,8],
[59,32,13,10],
[59,32,14,0],
[59,32,14,1],
[59,32,14,2],
[59,32,14,3],
[59,32,14,4],
[59,32,14,5],
[59,32,14,6],
[59,32,14,7],
[59,32,14,8],
[59,32,14,10],
[59,32,16,0],
[59,32,16,1],
[59,32,16,2],
[59,32,16,3],
[59,32,16,4],
[59,32,16,5],
[59,32,16,6],
[59,32,16,7],
[59,32,16,8],
[59,32,16,10],
[59,32,16,13],
[59,32,16,14],
[59,32,17,0],
[59,32,17,1],
[59,32,17,2],
[59,32,17,3],
[59,32,17,4],
[59,32,17,5],
[59,32,17,6],
[59,32,17,7],
[59,32,17,8],
[59,32,17,10],
[59,32,17,13],
[59,32,17,14],
[59,32,17,16],
[59,32,19,0],
[59,32,19,1],
[59,32,19,2],
[59,32,19,3],
[59,32,19,4],
[59,32,19,5],
[59,32,19,6],
[59,32,19,7],
[59,32,19,8],
[59,32,19,10],
[59,32,19,16],
[59,32,19,17],
[59,32,20,0],
[59,32,20,1],
[59,32,20,2],
[59,32,20,3],
[59,32,20,4],
[59,32,20,5],
[59,32,20,6],
[59,32,20,7],
[59,32,20,8],
[59,32,20,13],
[59,32,20,14],
[59,32,20,16],
[59,32,20,17],
[59,32,20,19],
[59,32,21,0],
[59,32,21,1],
[59,32,21,2],
[59,32,21,4],
[59,32,21,7],
[59,32,21,10],
[59,32,21,13],
[59,32,21,14],
[59,32,21,16],
[59,32,21,17],
[59,32,21,19],
[59,32,21,20],
[59,32,22,0],
[59,32,22,1],
[59,32,22,2],
[59,32,22,3],
[59,32,22,4],
[59,32,22,5],
[59,32,22,6],
[59,32,22,7],
[59,32,22,8],
[59,32,22,13],
[59,32,22,14],
[59,32,22,16],
[59,32,22,17],
[59,32,22,19],
[59,32,22,21],
[59,32,23,0],
[59,32,23,1],
[59,32,23,2],
[59,32,23,3],
[59,32,23,4],
[59,32,23,5],
[59,32,23,6],
[59,32,23,7],
[59,32,23,8],
[59,32,23,10],
[59,32,23,13],
[59,32,23,14],
[59,32,23,16],
[59,32,23,17],
[59,32,23,19],
[59,32,23,20],
[59,32,23,21],
[59,32,23,22],
[59,32,24,0],
[59,32,24,1],
[59,32,24,2],
[59,32,24,3],
[59,32,24,4],
[59,32,24,5],
[59,32,24,6],
[59,32,24,7],
[59,32,24,8],
[59,32,24,10],
[59,32,24,13],
[59,32,24,14],
[59,32,24,17],
[59,32,24,19],
[59,32,24,20],
[59,32,24,21],
[59,32,24,22],
[59,32,25,0],
[59,32,25,1],
[59,32,25,2],
[59,32,25,3],
[59,32,25,4],
[59,32,25,5],
[59,32,25,6],
[59,32,25,8],
[59,32,25,10],
[59,32,25,13],
[59,32,25,14],
[59,32,25,16],
[59,32,25,17],
[59,32,25,19],
[59,32,25,20],
[59,32,25,22],
[59,32,25,23],
[59,32,25,24],
[59,32,27,0],
[59,32,27,1],
[59,32,27,2],
[59,32,27,3],
[59,32,27,4],
[59,32,27,5],
[59,32,27,6],
[59,32,27,7],
[59,32,27,8],
[59,32,27,13],
[59,32,27,14],
[59,32,27,16],
[59,32,27,17],
[59,32,27,21],
[59,32,27,23],
[59,32,27,24],
[59,32,27,25],
[59,32,28,0],
[59,32,28,1],
[59,32,28,2],
[59,32,28,3],
[59,32,28,4],
[59,32,28,5],
[59,32,28,6],
[59,32,28,8],
[59,32,28,10],
[59,32,28,13],
[59,32,28,14],
[59,32,28,16],
[59,32,28,17],
[59,32,28,19],
[59,32,28,20],
[59,32,28,21],
[59,32,28,22],
[59,32,28,23],
[59,32,28,24],
[59,32,28,27],
[59,32,30,0],
[59,32,30,1],
[59,32,30,2],
[59,32,30,3],
[59,32,30,4],
[59,32,30,5],
[59,32,30,6],
[59,32,30,7],
[59,32,30,8],
[59,32,30,10],
[59,32,30,13],
[59,32,30,14],
[59,32,30,16],
[59,32,30,17],
[59,32,30,19],
[59,32,30,20],
[59,32,30,21],
[59,32,30,22],
[59,32,30,24],
[59,32,30,25],
[59,32,30,27],
[59,32,30,28],
[59,32,31,0],
[59,32,31,1],
[59,32,31,2],
[59,32,31,3],
[59,32,31,4],
[59,32,31,5],
[59,32,31,6],
[59,32,31,7],
[59,32,31,8],
[59,32,31,10],
[59,32,31,13],
[59,32,31,16],
[59,32,31,17],
[59,32,31,19],
[59,32,31,20],
[59,32,31,21],
[59,32,31,22],
[59,32,31,24],
[59,32,31,25],
[59,32,31,27],
[59,33,3,0],
[59,33,3,1],
[59,33,3,2],
[59,33,4,0],
[59,33,4,1],
[59,33,4,2],
[59,33,4,3],
[59,33,5,0],
[59,33,5,1],
[59,33,5,2],
[59,33,5,4],
[59,33,6,0],
[59,33,6,1],
[59,33,6,2],
[59,33,6,4],
[59,33,7,0],
[59,33,7,1],
[59,33,7,2],
[59,33,7,3],
[59,33,7,4],
[59,33,7,5],
[59,33,7,6],
[59,33,8,0],
[59,33,8,1],
[59,33,8,2],
[59,33,8,4],
[59,33,8,7],
[59,33,10,0],
[59,33,10,1],
[59,33,10,2],
[59,33,10,3],
[59,33,10,4],
[59,33,10,5],
[59,33,10,6],
[59,33,10,7],
[59,33,10,8],
[59,33,13,0],
[59,33,13,1],
[59,33,13,2],
[59,33,13,3],
[59,33,13,4],
[59,33,13,5],
[59,33,13,6],
[59,33,13,7],
[59,33,13,8],
[59,33,13,10],
[59,33,14,0],
[59,33,14,1],
[59,33,14,2],
[59,33,14,3],
[59,33,14,4],
[59,33,14,5],
[59,33,14,6],
[59,33,14,7],
[59,33,14,8],
[59,33,14,10],
[59,33,16,0],
[59,33,16,1],
[59,33,16,2],
[59,33,16,3],
[59,33,16,4],
[59,33,16,5],
[59,33,16,6],
[59,33,16,7],
[59,33,16,8],
[59,33,16,10],
[59,33,16,13],
[59,33,16,14],
[59,33,17,0],
[59,33,17,1],
[59,33,17,2],
[59,33,17,3],
[59,33,17,4],
[59,33,17,5],
[59,33,17,6],
[59,33,17,7],
[59,33,17,8],
[59,33,17,10],
[59,33,17,13],
[59,33,17,14],
[59,33,17,16],
[59,33,19,0],
[59,33,19,1],
[59,33,19,2],
[59,33,19,3],
[59,33,19,4],
[59,33,19,5],
[59,33,19,6],
[59,33,19,7],
[59,33,19,8],
[59,33,19,10],
[59,33,19,16],
[59,33,19,17],
[59,33,20,0],
[59,33,20,1],
[59,33,20,2],
[59,33,20,3],
[59,33,20,4],
[59,33,20,5],
[59,33,20,6],
[59,33,20,7],
[59,33,20,8],
[59,33,20,13],
[59,33,20,14],
[59,33,20,16],
[59,33,20,17],
[59,33,20,19],
[59,33,21,0],
[59,33,21,1],
[59,33,21,2],
[59,33,21,4],
[59,33,21,7],
[59,33,21,10],
[59,33,21,13],
[59,33,21,14],
[59,33,21,16],
[59,33,21,17],
[59,33,21,19],
[59,33,21,20],
[59,33,22,0],
[59,33,22,1],
[59,33,22,2],
[59,33,22,3],
[59,33,22,4],
[59,33,22,5],
[59,33,22,6],
[59,33,22,7],
[59,33,22,8],
[59,33,22,13],
[59,33,22,14],
[59,33,22,16],
[59,33,22,17],
[59,33,22,19],
[59,33,22,21],
[59,33,23,0],
[59,33,23,1],
[59,33,23,2],
[59,33,23,3],
[59,33,23,4],
[59,33,23,5],
[59,33,23,6],
[59,33,23,7],
[59,33,23,8],
[59,33,23,10],
[59,33,23,13],
[59,33,23,14],
[59,33,23,16],
[59,33,23,17],
[59,33,23,19],
[59,33,23,20],
[59,33,23,21],
[59,33,24,0],
[59,33,24,1],
[59,33,24,2],
[59,33,24,3],
[59,33,24,4],
[59,33,24,5],
[59,33,24,6],
[59,33,24,7],
[59,33,24,8],
[59,33,24,10],
[59,33,24,13],
[59,33,24,14],
[59,33,24,17],
[59,33,24,19],
[59,33,24,20],
[59,33,24,21],
[59,33,24,23],
[59,33,25,0],
[59,33,25,1],
[59,33,25,2],
[59,33,25,3],
[59,33,25,4],
[59,33,25,5],
[59,33,25,6],
[59,33,25,8],
[59,33,25,10],
[59,33,25,13],
[59,33,25,14],
[59,33,25,16],
[59,33,25,17],
[59,33,25,19],
[59,33,25,21],
[59,33,25,22],
[59,33,25,23],
[59,33,25,24],
[59,33,27,0],
[59,33,27,1],
[59,33,27,2],
[59,33,27,3],
[59,33,27,4],
[59,33,27,5],
[59,33,27,6],
[59,33,27,7],
[59,33,27,8],
[59,33,27,13],
[59,33,27,14],
[59,33,27,16],
[59,33,27,17],
[59,33,27,19],
[59,33,27,21],
[59,33,27,23],
[59,33,27,24],
[59,33,27,25],
[59,33,28,0],
[59,33,28,1],
[59,33,28,2],
[59,33,28,3],
[59,33,28,4],
[59,33,28,5],
[59,33,28,6],
[59,33,28,8],
[59,33,28,10],
[59,33,28,13],
[59,33,28,14],
[59,33,28,19],
[59,33,28,20],
[59,33,28,21],
[59,33,28,22],
[59,33,28,23],
[59,33,28,24],
[59,33,28,27],
[59,33,30,0],
[59,33,30,1],
[59,33,30,2],
[59,33,30,3],
[59,33,30,4],
[59,33,30,5],
[59,33,30,6],
[59,33,30,7],
[59,33,30,8],
[59,33,30,10],
[59,33,30,13],
[59,33,30,16],
[59,33,30,17],
[59,33,30,19],
[59,33,30,20],
[59,33,30,21],
[59,33,30,22],
[59,33,30,24],
[59,33,30,25],
[59,33,30,27],
[59,33,31,0],
[59,33,31,1],
[59,33,31,2],
[59,33,31,3],
[59,33,31,4],
[59,33,31,5],
[59,33,31,6],
[59,33,31,7],
[59,33,31,8],
[59,33,31,10],
[59,33,31,14],
[59,33,31,16],
[59,33,31,17],
[59,33,31,19],
[59,33,31,20],
[59,33,31,21],
[59,33,31,22],
[59,33,31,24],
[59,33,31,25],
[59,34,3,0],
[59,34,3,1],
[59,34,3,2],
[59,34,4,0],
[59,34,4,1],
[59,34,4,2],
[59,34,4,3],
[59,34,5,0],
[59,34,5,1],
[59,34,5,2],
[59,34,5,4],
[59,34,6,0],
[59,34,6,1],
[59,34,6,2],
[59,34,6,4],
[59,34,8,0],
[59,34,8,1],
[59,34,8,2],
[59,34,8,4],
[59,34,10,0],
[59,34,10,1],
[59,34,10,2],
[59,34,10,3],
[59,34,10,4],
[59,34,10,5],
[59,34,10,6],
[59,34,10,8],
[59,34,11,0],
[59,34,11,1],
[59,34,11,2],
[59,34,11,3],
[59,34,11,4],
[59,34,11,5],
[59,34,11,6],
[59,34,11,8],
[59,34,11,10],
[59,34,12,0],
[59,34,12,1],
[59,34,12,2],
[59,34,12,3],
[59,34,12,4],
[59,34,12,5],
[59,34,12,6],
[59,34,12,8],
[59,34,12,10],
[59,34,13,0],
[59,34,13,1],
[59,34,13,2],
[59,34,13,3],
[59,34,13,4],
[59,34,13,5],
[59,34,13,6],
[59,34,13,8],
[59,34,13,10],
[59,34,13,11],
[59,34,13,12],
[59,34,14,0],
[59,34,14,1],
[59,34,14,2],
[59,34,14,3],
[59,34,14,4],
[59,34,14,5],
[59,34,14,6],
[59,34,14,8],
[59,34,14,10],
[59,34,14,11],
[59,34,14,12],
[59,34,16,0],
[59,34,16,1],
[59,34,16,2],
[59,34,16,3],
[59,34,16,4],
[59,34,16,5],
[59,34,16,6],
[59,34,16,8],
[59,34,16,10],
[59,34,16,11],
[59,34,16,12],
[59,34,16,13],
[59,34,16,14],
[59,34,17,0],
[59,34,17,1],
[59,34,17,2],
[59,34,17,3],
[59,34,17,4],
[59,34,17,5],
[59,34,17,6],
[59,34,17,8],
[59,34,17,10],
[59,34,17,11],
[59,34,17,12],
[59,34,17,13],
[59,34,17,14],
[59,34,17,16],
[59,34,19,0],
[59,34,19,1],
[59,34,19,2],
[59,34,19,3],
[59,34,19,4],
[59,34,19,5],
[59,34,19,6],
[59,34,19,8],
[59,34,19,10],
[59,34,19,11],
[59,34,19,12],
[59,34,19,16],
[59,34,19,17],
[59,34,20,0],
[59,34,20,1],
[59,34,20,2],
[59,34,20,3],
[59,34,20,4],
[59,34,20,5],
[59,34,20,6],
[59,34,20,8],
[59,34,20,11],
[59,34,20,12],
[59,34,20,13],
[59,34,20,14],
[59,34,20,16],
[59,34,20,17],
[59,34,20,19],
[59,34,21,0],
[59,34,21,1],
[59,34,21,2],
[59,34,21,4],
[59,34,21,10],
[59,34,21,11],
[59,34,21,12],
[59,34,21,13],
[59,34,21,14],
[59,34,21,16],
[59,34,21,17],
[59,34,21,19],
[59,34,21,20],
[59,34,22,0],
[59,34,22,1],
[59,34,22,2],
[59,34,22,3],
[59,34,22,4],
[59,34,22,5],
[59,34,22,6],
[59,34,22,8],
[59,34,22,11],
[59,34,22,12],
[59,34,22,13],
[59,34,22,14],
[59,34,22,16],
[59,34,22,17],
[59,34,22,19],
[59,34,22,21],
[59,34,23,0],
[59,34,23,1],
[59,34,23,2],
[59,34,23,3],
[59,34,23,4],
[59,34,23,5],
[59,34,23,6],
[59,34,23,8],
[59,34,23,10],
[59,34,23,11],
[59,34,23,12],
[59,34,23,13],
[59,34,23,14],
[59,34,23,16],
[59,34,23,17],
[59,34,23,19],
[59,34,23,20],
[59,34,23,22],
[59,34,24,0],
[59,34,24,1],
[59,34,24,2],
[59,34,24,3],
[59,34,24,4],
[59,34,24,5],
[59,34,24,6],
[59,34,24,8],
[59,34,24,10],
[59,34,24,11],
[59,34,24,12],
[59,34,24,13],
[59,34,24,14],
[59,34,24,17],
[59,34,24,19],
[59,34,24,20],
[59,34,24,22],
[59,34,24,23],
[59,34,27,0],
[59,34,27,1],
[59,34,27,2],
[59,34,27,3],
[59,34,27,4],
[59,34,27,5],
[59,34,27,6],
[59,34,27,8],
[59,34,27,11],
[59,34,27,12],
[59,34,27,13],
[59,34,27,14],
[59,34,27,19],
[59,34,27,21],
[59,34,27,23],
[59,34,27,24],
[59,34,29,0],
[59,34,29,1],
[59,34,29,2],
[59,34,29,3],
[59,34,29,4],
[59,34,29,5],
[59,34,29,6],
[59,34,29,8],
[59,34,29,10],
[59,34,29,13],
[59,34,29,16],
[59,34,29,17],
[59,34,29,19],
[59,34,29,20],
[59,34,29,21],
[59,34,29,22],
[59,34,29,23],
[59,34,29,24],
[59,34,29,27],
[59,34,30,0],
[59,34,30,1],
[59,34,30,2],
[59,34,30,3],
[59,34,30,4],
[59,34,30,5],
[59,34,30,6],
[59,34,30,8],
[59,34,30,10],
[59,34,30,11],
[59,34,30,14],
[59,34,30,16],
[59,34,30,17],
[59,34,30,19],
[59,34,30,20],
[59,34,30,21],
[59,34,30,22],
[59,34,30,24],
[59,34,31,0],
[59,34,31,1],
[59,34,31,2],
[59,34,31,3],
[59,34,31,4],
[59,34,31,5],
[59,34,31,6],
[59,34,31,8],
[59,34,31,12],
[59,34,31,13],
[59,34,31,14],
[59,34,31,16],
[59,34,31,17],
[59,34,31,19],
[59,34,31,20],
[59,34,31,21],
[59,34,31,22],
[59,34,31,24],
[59,34,32,0],
[59,34,32,1],
[59,34,32,2],
[59,34,32,3],
[59,34,32,4],
[59,34,32,5],
[59,34,32,6],
[59,34,32,10],
[59,34,32,13],
[59,34,32,14],
[59,34,32,16],
[59,34,32,17],
[59,34,32,19],
[59,34,32,20],
[59,34,32,21],
[59,34,32,22],
[59,34,32,23],
[59,34,32,24],
[59,34,33,0],
[59,34,33,1],
[59,34,33,2],
[59,34,33,3],
[59,34,33,4],
[59,34,33,5],
[59,34,33,8],
[59,34,33,10],
[59,34,33,13],
[59,34,33,14],
[59,34,33,16],
[59,34,33,17],
[59,34,33,19],
[59,34,33,20],
[59,34,33,21],
[59,34,33,22],
[59,34,33,23],
[59,34,33,24],
[59,36,3,0],
[59,36,3,1],
[59,36,3,2],
[59,36,4,0],
[59,36,4,1],
[59,36,4,2],
[59,36,4,3],
[59,36,5,0],
[59,36,5,1],
[59,36,5,2],
[59,36,5,4],
[59,36,6,0],
[59,36,6,1],
[59,36,6,2],
[59,36,6,4],
[59,36,7,0],
[59,36,7,1],
[59,36,7,2],
[59,36,7,3],
[59,36,7,4],
[59,36,7,5],
[59,36,7,6],
[59,36,8,0],
[59,36,8,1],
[59,36,8,2],
[59,36,8,4],
[59,36,8,7],
[59,36,10,0],
[59,36,10,1],
[59,36,10,2],
[59,36,10,3],
[59,36,10,4],
[59,36,10,5],
[59,36,10,6],
[59,36,10,7],
[59,36,10,8],
[59,36,11,0],
[59,36,11,1],
[59,36,11,2],
[59,36,11,3],
[59,36,11,4],
[59,36,11,5],
[59,36,11,6],
[59,36,11,7],
[59,36,11,8],
[59,36,11,10],
[59,36,12,0],
[59,36,12,1],
[59,36,12,2],
[59,36,12,3],
[59,36,12,4],
[59,36,12,5],
[59,36,12,6],
[59,36,12,7],
[59,36,12,8],
[59,36,12,10],
[59,36,16,0],
[59,36,16,1],
[59,36,16,2],
[59,36,16,3],
[59,36,16,4],
[59,36,16,5],
[59,36,16,6],
[59,36,16,7],
[59,36,16,8],
[59,36,16,10],
[59,36,16,11],
[59,36,16,12],
[59,36,17,0],
[59,36,17,1],
[59,36,17,2],
[59,36,17,3],
[59,36,17,4],
[59,36,17,5],
[59,36,17,6],
[59,36,17,7],
[59,36,17,8],
[59,36,17,10],
[59,36,17,11],
[59,36,17,12],
[59,36,17,16],
[59,36,20,0],
[59,36,20,1],
[59,36,20,2],
[59,36,20,3],
[59,36,20,4],
[59,36,20,5],
[59,36,20,6],
[59,36,20,7],
[59,36,20,8],
[59,36,20,11],
[59,36,20,12],
[59,36,20,16],
[59,36,20,17],
[59,36,21,0],
[59,36,21,1],
[59,36,21,2],
[59,36,21,4],
[59,36,21,7],
[59,36,21,10],
[59,36,21,11],
[59,36,21,12],
[59,36,21,16],
[59,36,21,17],
[59,36,21,20],
[59,36,22,0],
[59,36,22,1],
[59,36,22,2],
[59,36,22,3],
[59,36,22,4],
[59,36,22,5],
[59,36,22,6],
[59,36,22,7],
[59,36,22,8],
[59,36,22,11],
[59,36,22,12],
[59,36,22,16],
[59,36,22,17],
[59,36,22,21],
[59,36,23,0],
[59,36,23,1],
[59,36,23,2],
[59,36,23,3],
[59,36,23,4],
[59,36,23,5],
[59,36,23,6],
[59,36,23,7],
[59,36,23,8],
[59,36,23,10],
[59,36,23,11],
[59,36,23,12],
[59,36,23,16],
[59,36,23,17],
[59,36,23,20],
[59,36,23,21],
[59,36,23,22],
[59,36,24,0],
[59,36,24,1],
[59,36,24,2],
[59,36,24,3],
[59,36,24,4],
[59,36,24,5],
[59,36,24,6],
[59,36,24,7],
[59,36,24,8],
[59,36,24,10],
[59,36,24,11],
[59,36,24,12],
[59,36,24,17],
[59,36,24,20],
[59,36,24,21],
[59,36,24,22],
[59,36,24,23],
[59,36,25,0],
[59,36,25,1],
[59,36,25,2],
[59,36,25,3],
[59,36,25,4],
[59,36,25,5],
[59,36,25,6],
[59,36,25,8],
[59,36,25,10],
[59,36,25,11],
[59,36,25,12],
[59,36,25,20],
[59,36,25,21],
[59,36,25,22],
[59,36,25,23],
[59,36,25,24],
[59,36,27,0],
[59,36,27,1],
[59,36,27,2],
[59,36,27,3],
[59,36,27,4],
[59,36,27,5],
[59,36,27,6],
[59,36,27,7],
[59,36,27,8],
[59,36,27,11],
[59,36,27,12],
[59,36,27,16],
[59,36,27,17],
[59,36,27,21],
[59,36,27,23],
[59,36,27,24],
[59,36,27,25],
[59,36,28,0],
[59,36,28,1],
[59,36,28,2],
[59,36,28,3],
[59,36,28,4],
[59,36,28,5],
[59,36,28,6],
[59,36,28,8],
[59,36,28,10],
[59,36,28,11],
[59,36,28,16],
[59,36,28,17],
[59,36,28,20],
[59,36,28,21],
[59,36,28,22],
[59,36,28,23],
[59,36,28,24],
[59,36,29,0],
[59,36,29,1],
[59,36,29,2],
[59,36,29,3],
[59,36,29,4],
[59,36,29,5],
[59,36,29,6],
[59,36,29,7],
[59,36,29,8],
[59,36,29,16],
[59,36,29,17],
[59,36,29,20],
[59,36,29,21],
[59,36,29,22],
[59,36,29,23],
[59,36,29,24],
[59,36,29,25],
[59,36,30,0],
[59,36,30,1],
[59,36,30,2],
[59,36,30,3],
[59,36,30,4],
[59,36,30,5],
[59,36,30,6],
[59,36,30,7],
[59,36,30,10],
[59,36,30,11],
[59,36,30,12],
[59,36,30,16],
[59,36,30,17],
[59,36,30,20],
[59,36,30,21],
[59,36,30,22],
[59,36,30,24],
[59,36,31,0],
[59,36,31,1],
[59,36,31,2],
[59,36,31,3],
[59,36,31,4],
[59,36,31,5],
[59,36,31,8],
[59,36,31,10],
[59,36,31,11],
[59,36,31,12],
[59,36,31,16],
[59,36,31,17],
[59,36,31,20],
[59,36,31,21],
[59,36,31,22],
[59,36,31,24],
[59,36,32,0],
[59,36,32,1],
[59,36,32,2],
[59,36,32,3],
[59,36,32,4],
[59,36,32,6],
[59,36,32,7],
[59,36,32,8],
[59,36,32,10],
[59,36,32,16],
[59,36,32,17],
[59,36,32,20],
[59,36,32,21],
[59,36,32,22],
[59,36,33,0],
[59,36,33,1],
[59,36,33,2],
[59,36,33,5],
[59,36,33,6],
[59,36,33,7],
[59,36,33,8],
[59,36,33,10],
[59,36,33,16],
[59,36,33,17],
[59,36,33,20],
[59,36,33,21],
[59,36,34,3],
[59,36,34,4],
[59,36,34,5],
[59,36,34,6],
[59,36,34,8],
[59,36,34,10],
[59,36,34,11],
[59,36,34,12],
[59,36,34,16],
[59,36,34,17],
[59,36,34,20],
[59,37,3,0],
[59,37,3,1],
[59,37,3,2],
[59,37,5,0],
[59,37,5,1],
[59,37,5,2],
[59,37,6,0],
[59,37,6,1],
[59,37,6,2],
[59,37,7,0],
[59,37,7,1],
[59,37,7,2],
[59,37,7,3],
[59,37,7,5],
[59,37,7,6],
[59,37,8,0],
[59,37,8,1],
[59,37,8,2],
[59,37,8,7],
[59,37,10,0],
[59,37,10,1],
[59,37,10,2],
[59,37,10,3],
[59,37,10,5],
[59,37,10,6],
[59,37,10,7],
[59,37,10,8],
[59,37,11,0],
[59,37,11,1],
[59,37,11,2],
[59,37,11,3],
[59,37,11,5],
[59,37,11,6],
[59,37,11,7],
[59,37,11,8],
[59,37,11,10],
[59,37,12,0],
[59,37,12,1],
[59,37,12,2],
[59,37,12,3],
[59,37,12,5],
[59,37,12,6],
[59,37,12,7],
[59,37,12,8],
[59,37,12,10],
[59,37,13,0],
[59,37,13,1],
[59,37,13,2],
[59,37,13,3],
[59,37,13,5],
[59,37,13,6],
[59,37,13,7],
[59,37,13,8],
[59,37,13,10],
[59,37,13,11],
[59,37,13,12],
[59,37,14,0],
[59,37,14,1],
[59,37,14,2],
[59,37,14,3],
[59,37,14,5],
[59,37,14,6],
[59,37,14,7],
[59,37,14,8],
[59,37,14,10],
[59,37,14,11],
[59,37,14,12],
[59,37,16,0],
[59,37,16,1],
[59,37,16,2],
[59,37,16,3],
[59,37,16,5],
[59,37,16,6],
[59,37,16,7],
[59,37,16,8],
[59,37,16,10],
[59,37,16,11],
[59,37,16,12],
[59,37,16,13],
[59,37,16,14],
[59,37,17,0],
[59,37,17,1],
[59,37,17,2],
[59,37,17,3],
[59,37,17,5],
[59,37,17,6],
[59,37,17,7],
[59,37,17,8],
[59,37,17,10],
[59,37,17,11],
[59,37,17,12],
[59,37,17,13],
[59,37,17,14],
[59,37,17,16],
[59,37,19,0],
[59,37,19,1],
[59,37,19,2],
[59,37,19,3],
[59,37,19,5],
[59,37,19,6],
[59,37,19,7],
[59,37,19,8],
[59,37,19,10],
[59,37,19,11],
[59,37,19,12],
[59,37,19,16],
[59,37,19,17],
[59,37,20,0],
[59,37,20,1],
[59,37,20,2],
[59,37,20,3],
[59,37,20,5],
[59,37,20,6],
[59,37,20,7],
[59,37,20,8],
[59,37,20,11],
[59,37,20,12],
[59,37,20,13],
[59,37,20,14],
[59,37,20,16],
[59,37,20,17],
[59,37,20,19],
[59,37,21,0],
[59,37,21,1],
[59,37,21,2],
[59,37,21,7],
[59,37,21,10],
[59,37,21,11],
[59,37,21,12],
[59,37,21,13],
[59,37,21,14],
[59,37,21,16],
[59,37,21,17],
[59,37,21,19],
[59,37,22,0],
[59,37,22,1],
[59,37,22,2],
[59,37,22,3],
[59,37,22,5],
[59,37,22,6],
[59,37,22,7],
[59,37,22,8],
[59,37,22,11],
[59,37,22,12],
[59,37,22,13],
[59,37,22,14],
[59,37,22,16],
[59,37,22,17],
[59,37,22,21],
[59,37,23,0],
[59,37,23,1],
[59,37,23,2],
[59,37,23,3],
[59,37,23,5],
[59,37,23,6],
[59,37,23,7],
[59,37,23,8],
[59,37,23,10],
[59,37,23,11],
[59,37,23,12],
[59,37,23,13],
[59,37,23,14],
[59,37,23,16],
[59,37,23,17],
[59,37,23,19],
[59,37,23,20],
[59,37,23,21],
[59,37,23,22],
[59,37,24,0],
[59,37,24,1],
[59,37,24,2],
[59,37,24,3],
[59,37,24,5],
[59,37,24,6],
[59,37,24,7],
[59,37,24,8],
[59,37,24,10],
[59,37,24,11],
[59,37,24,12],
[59,37,24,13],
[59,37,24,14],
[59,37,24,17],
[59,37,24,19],
[59,37,24,20],
[59,37,24,21],
[59,37,24,22],
[59,37,24,23],
[59,37,25,0],
[59,37,25,1],
[59,37,25,2],
[59,37,25,3],
[59,37,25,5],
[59,37,25,6],
[59,37,25,8],
[59,37,25,10],
[59,37,25,11],
[59,37,25,12],
[59,37,25,13],
[59,37,25,14],
[59,37,25,16],
[59,37,25,17],
[59,37,25,19],
[59,37,25,20],
[59,37,25,21],
[59,37,25,22],
[59,37,25,23],
[59,37,25,24],
[59,37,27,0],
[59,37,27,1],
[59,37,27,2],
[59,37,27,3],
[59,37,27,5],
[59,37,27,6],
[59,37,27,7],
[59,37,27,8],
[59,37,27,11],
[59,37,27,14],
[59,37,27,16],
[59,37,27,17],
[59,37,27,19],
[59,37,27,21],
[59,37,27,23],
[59,37,27,24],
[59,37,27,25],
[59,37,28,0],
[59,37,28,1],
[59,37,28,2],
[59,37,28,3],
[59,37,28,5],
[59,37,28,6],
[59,37,28,8],
[59,37,28,12],
[59,37,28,13],
[59,37,28,14],
[59,37,28,16],
[59,37,28,17],
[59,37,28,19],
[59,37,28,20],
[59,37,28,21],
[59,37,28,22],
[59,37,28,23],
[59,37,28,24],
[59,37,29,0],
[59,37,29,1],
[59,37,29,2],
[59,37,29,3],
[59,37,29,5],
[59,37,29,6],
[59,37,29,7],
[59,37,29,10],
[59,37,29,13],
[59,37,29,14],
[59,37,29,16],
[59,37,29,17],
[59,37,29,19],
[59,37,29,20],
[59,37,29,21],
[59,37,29,22],
[59,37,29,23],
[59,37,29,24],
[59,37,30,0],
[59,37,30,1],
[59,37,30,2],
[59,37,30,3],
[59,37,30,5],
[59,37,30,8],
[59,37,30,10],
[59,37,30,11],
[59,37,30,12],
[59,37,30,13],
[59,37,30,14],
[59,37,30,16],
[59,37,30,17],
[59,37,30,19],
[59,37,30,20],
[59,37,30,21],
[59,37,30,22],
[59,37,30,24],
[59,37,31,0],
[59,37,31,1],
[59,37,31,2],
[59,37,31,3],
[59,37,31,6],
[59,37,31,7],
[59,37,31,8],
[59,37,31,10],
[59,37,31,11],
[59,37,31,12],
[59,37,31,13],
[59,37,31,14],
[59,37,31,16],
[59,37,31,17],
[59,37,31,19],
[59,37,31,20],
[59,37,31,21],
[59,37,31,22],
[59,37,32,0],
[59,37,32,1],
[59,37,32,2],
[59,37,32,5],
[59,37,32,6],
[59,37,32,7],
[59,37,32,8],
[59,37,32,10],
[59,37,32,13],
[59,37,32,14],
[59,37,32,16],
[59,37,32,17],
[59,37,32,19],
[59,37,32,20],
[59,37,32,21],
[59,37,33,3],
[59,37,33,5],
[59,37,33,6],
[59,37,33,7],
[59,37,33,8],
[59,37,33,10],
[59,37,33,13],
[59,37,33,14],
[59,37,33,16],
[59,37,33,17],
[59,37,33,19],
[59,37,33,20],
[59,37,34,0],
[59,37,34,1],
[59,37,34,2],
[59,37,34,3],
[59,37,34,5],
[59,37,34,6],
[59,37,34,8],
[59,37,34,10],
[59,37,34,11],
[59,37,34,12],
[59,37,34,13],
[59,37,34,14],
[59,37,34,16],
[59,37,34,17],
[59,37,34,19],
[59,37,36,0],
[59,37,36,1],
[59,37,36,2],
[59,37,36,3],
[59,37,36,5],
[59,37,36,6],
[59,37,36,7],
[59,37,36,8],
[59,37,36,10],
[59,37,36,11],
[59,37,36,12],
[59,37,36,16],
[59,37,36,17],
[59,38,3,0],
[59,38,3,1],
[59,38,3,2],
[59,38,4,0],
[59,38,4,1],
[59,38,4,2],
[59,38,4,3],
[59,38,5,0],
[59,38,5,1],
[59,38,5,2],
[59,38,5,4],
[59,38,6,0],
[59,38,6,1],
[59,38,6,2],
[59,38,6,4],
[59,38,8,0],
[59,38,8,1],
[59,38,8,2],
[59,38,8,4],
[59,38,10,0],
[59,38,10,1],
[59,38,10,2],
[59,38,10,3],
[59,38,10,4],
[59,38,10,5],
[59,38,10,6],
[59,38,10,8],
[59,38,11,0],
[59,38,11,1],
[59,38,11,2],
[59,38,11,3],
[59,38,11,4],
[59,38,11,5],
[59,38,11,6],
[59,38,11,8],
[59,38,11,10],
[59,38,12,0],
[59,38,12,1],
[59,38,12,2],
[59,38,12,3],
[59,38,12,4],
[59,38,12,5],
[59,38,12,6],
[59,38,12,8],
[59,38,12,10],
[59,38,13,0],
[59,38,13,1],
[59,38,13,2],
[59,38,13,3],
[59,38,13,4],
[59,38,13,5],
[59,38,13,6],
[59,38,13,8],
[59,38,13,10],
[59,38,13,11],
[59,38,13,12],
[59,38,14,0],
[59,38,14,1],
[59,38,14,2],
[59,38,14,3],
[59,38,14,4],
[59,38,14,5],
[59,38,14,6],
[59,38,14,8],
[59,38,14,10],
[59,38,14,11],
[59,38,14,12],
[59,38,16,0],
[59,38,16,1],
[59,38,16,2],
[59,38,16,3],
[59,38,16,4],
[59,38,16,5],
[59,38,16,6],
[59,38,16,8],
[59,38,16,10],
[59,38,16,11],
[59,38,16,12],
[59,38,16,13],
[59,38,16,14],
[59,38,17,0],
[59,38,17,1],
[59,38,17,2],
[59,38,17,3],
[59,38,17,4],
[59,38,17,5],
[59,38,17,6],
[59,38,17,8],
[59,38,17,10],
[59,38,17,11],
[59,38,17,12],
[59,38,17,13],
[59,38,17,14],
[59,38,17,16],
[59,38,19,0],
[59,38,19,1],
[59,38,19,2],
[59,38,19,3],
[59,38,19,4],
[59,38,19,5],
[59,38,19,6],
[59,38,19,8],
[59,38,19,10],
[59,38,19,11],
[59,38,19,12],
[59,38,19,16],
[59,38,19,17],
[59,38,20,0],
[59,38,20,1],
[59,38,20,2],
[59,38,20,3],
[59,38,20,4],
[59,38,20,5],
[59,38,20,6],
[59,38,20,8],
[59,38,20,11],
[59,38,20,12],
[59,38,20,13],
[59,38,20,14],
[59,38,20,16],
[59,38,20,17],
[59,38,20,19],
[59,38,21,0],
[59,38,21,1],
[59,38,21,2],
[59,38,21,4],
[59,38,21,10],
[59,38,21,11],
[59,38,21,12],
[59,38,21,13],
[59,38,21,14],
[59,38,21,16],
[59,38,21,17],
[59,38,21,20],
[59,38,22,0],
[59,38,22,1],
[59,38,22,2],
[59,38,22,3],
[59,38,22,4],
[59,38,22,5],
[59,38,22,6],
[59,38,22,8],
[59,38,22,11],
[59,38,22,12],
[59,38,22,13],
[59,38,22,14],
[59,38,22,16],
[59,38,22,17],
[59,38,22,19],
[59,38,22,21],
[59,38,23,0],
[59,38,23,1],
[59,38,23,2],
[59,38,23,3],
[59,38,23,4],
[59,38,23,5],
[59,38,23,6],
[59,38,23,8],
[59,38,23,10],
[59,38,23,11],
[59,38,23,12],
[59,38,23,13],
[59,38,23,14],
[59,38,23,19],
[59,38,23,20],
[59,38,23,21],
[59,38,23,22],
[59,38,24,0],
[59,38,24,1],
[59,38,24,2],
[59,38,24,3],
[59,38,24,4],
[59,38,24,5],
[59,38,24,6],
[59,38,24,8],
[59,38,24,10],
[59,38,24,11],
[59,38,24,12],
[59,38,24,13],
[59,38,24,14],
[59,38,24,19],
[59,38,24,20],
[59,38,24,21],
[59,38,24,22],
[59,38,24,23],
[59,38,27,0],
[59,38,27,1],
[59,38,27,2],
[59,38,27,3],
[59,38,27,4],
[59,38,27,5],
[59,38,27,6],
[59,38,27,8],
[59,38,27,12],
[59,38,27,13],
[59,38,27,14],
[59,38,27,16],
[59,38,27,17],
[59,38,27,19],
[59,38,27,21],
[59,38,27,23],
[59,38,27,24],
[59,38,29,0],
[59,38,29,1],
[59,38,29,2],
[59,38,29,3],
[59,38,29,4],
[59,38,29,5],
[59,38,29,8],
[59,38,29,10],
[59,38,29,13],
[59,38,29,14],
[59,38,29,16],
[59,38,29,17],
[59,38,29,19],
[59,38,29,20],
[59,38,29,21],
[59,38,29,22],
[59,38,29,23],
[59,38,29,24],
[59,38,30,0],
[59,38,30,1],
[59,38,30,2],
[59,38,30,3],
[59,38,30,4],
[59,38,30,6],
[59,38,30,8],
[59,38,30,10],
[59,38,30,11],
[59,38,30,12],
[59,38,30,13],
[59,38,30,14],
[59,38,30,16],
[59,38,30,17],
[59,38,30,19],
[59,38,30,20],
[59,38,30,21],
[59,38,30,22],
[59,38,31,0],
[59,38,31,1],
[59,38,31,2],
[59,38,31,5],
[59,38,31,6],
[59,38,31,8],
[59,38,31,10],
[59,38,31,11],
[59,38,31,12],
[59,38,31,13],
[59,38,31,14],
[59,38,31,16],
[59,38,31,17],
[59,38,31,19],
[59,38,31,20],
[59,38,31,21],
[59,38,32,3],
[59,38,32,4],
[59,38,32,5],
[59,38,32,6],
[59,38,32,8],
[59,38,32,10],
[59,38,32,13],
[59,38,32,14],
[59,38,32,16],
[59,38,32,17],
[59,38,32,19],
[59,38,32,20],
[59,38,33,0],
[59,38,33,1],
[59,38,33,2],
[59,38,33,3],
[59,38,33,4],
[59,38,33,5],
[59,38,33,6],
[59,38,33,8],
[59,38,33,10],
[59,38,33,13],
[59,38,33,14],
[59,38,33,16],
[59,38,33,17],
[59,38,33,19],
[59,38,36,0],
[59,38,36,1],
[59,38,36,2],
[59,38,36,3],
[59,38,36,4],
[59,38,36,5],
[59,38,36,6],
[59,38,36,8],
[59,38,36,10],
[59,38,36,11],
[59,38,36,12],
[59,38,37,0],
[59,38,37,1],
[59,38,37,2],
[59,38,37,3],
[59,38,37,5],
[59,38,37,6],
[59,38,37,8],
[59,38,37,10],
[59,38,37,11],
[59,38,37,12],
[59,38,37,13],
[59,38,37,14],
[59,39,4,0],
[59,39,4,1],
[59,39,4,2],
[59,39,7,0],
[59,39,7,1],
[59,39,7,2],
[59,39,7,4],
[59,39,10,0],
[59,39,10,1],
[59,39,10,2],
[59,39,10,4],
[59,39,10,7],
[59,39,11,0],
[59,39,11,1],
[59,39,11,2],
[59,39,11,4],
[59,39,11,7],
[59,39,11,10],
[59,39,12,0],
[59,39,12,1],
[59,39,12,2],
[59,39,12,4],
[59,39,12,7],
[59,39,12,10],
[59,39,13,0],
[59,39,13,1],
[59,39,13,2],
[59,39,13,4],
[59,39,13,7],
[59,39,13,10],
[59,39,13,11],
[59,39,13,12],
[59,39,14,0],
[59,39,14,1],
[59,39,14,2],
[59,39,14,4],
[59,39,14,7],
[59,39,14,10],
[59,39,14,11],
[59,39,14,12],
[59,39,16,0],
[59,39,16,1],
[59,39,16,2],
[59,39,16,4],
[59,39,16,7],
[59,39,16,10],
[59,39,16,11],
[59,39,16,12],
[59,39,16,13],
[59,39,16,14],
[59,39,17,0],
[59,39,17,1],
[59,39,17,2],
[59,39,17,4],
[59,39,17,7],
[59,39,17,10],
[59,39,17,11],
[59,39,17,12],
[59,39,17,13],
[59,39,17,14],
[59,39,17,16],
[59,39,19,0],
[59,39,19,1],
[59,39,19,2],
[59,39,19,4],
[59,39,19,7],
[59,39,19,10],
[59,39,19,11],
[59,39,19,12],
[59,39,19,16],
[59,39,19,17],
[59,39,20,0],
[59,39,20,1],
[59,39,20,2],
[59,39,20,4],
[59,39,20,7],
[59,39,20,11],
[59,39,20,12],
[59,39,20,13],
[59,39,20,14],
[59,39,20,16],
[59,39,20,17],
[59,39,22,0],
[59,39,22,1],
[59,39,22,2],
[59,39,22,4],
[59,39,22,7],
[59,39,22,11],
[59,39,22,12],
[59,39,22,13],
[59,39,22,14],
[59,39,22,19],
[59,39,23,0],
[59,39,23,1],
[59,39,23,2],
[59,39,23,4],
[59,39,23,7],
[59,39,23,10],
[59,39,23,11],
[59,39,23,12],
[59,39,23,13],
[59,39,23,14],
[59,39,23,16],
[59,39,23,17],
[59,39,23,19],
[59,39,23,20],
[59,39,23,22],
[59,39,24,0],
[59,39,24,1],
[59,39,24,2],
[59,39,24,4],
[59,39,24,7],
[59,39,24,10],
[59,39,24,11],
[59,39,24,12],
[59,39,24,13],
[59,39,24,14],
[59,39,24,17],
[59,39,24,19],
[59,39,24,20],
[59,39,24,22],
[59,39,24,23],
[59,39,25,0],
[59,39,25,1],
[59,39,25,2],
[59,39,25,4],
[59,39,25,10],
[59,39,25,11],
[59,39,25,14],
[59,39,25,16],
[59,39,25,17],
[59,39,25,19],
[59,39,25,20],
[59,39,25,22],
[59,39,25,23],
[59,39,25,24],
[59,39,27,0],
[59,39,27,1],
[59,39,27,2],
[59,39,27,4],
[59,39,27,7],
[59,39,27,11],
[59,39,27,12],
[59,39,27,13],
[59,39,27,14],
[59,39,27,16],
[59,39,27,17],
[59,39,27,19],
[59,39,27,23],
[59,39,27,24],
[59,39,28,0],
[59,39,28,1],
[59,39,28,2],
[59,39,28,4],
[59,39,28,10],
[59,39,28,11],
[59,39,28,12],
[59,39,28,13],
[59,39,28,14],
[59,39,28,16],
[59,39,28,17],
[59,39,28,19],
[59,39,28,20],
[59,39,28,22],
[59,39,28,23],
[59,39,28,24],
[59,39,29,0],
[59,39,29,1],
[59,39,29,2],
[59,39,29,4],
[59,39,29,7],
[59,39,29,10],
[59,39,29,13],
[59,39,29,14],
[59,39,29,16],
[59,39,29,17],
[59,39,29,19],
[59,39,29,20],
[59,39,29,22],
[59,39,30,0],
[59,39,30,1],
[59,39,30,2],
[59,39,30,7],
[59,39,30,10],
[59,39,30,11],
[59,39,30,12],
[59,39,30,13],
[59,39,30,14],
[59,39,30,16],
[59,39,30,17],
[59,39,30,19],
[59,39,30,20],
[59,39,31,4],
[59,39,31,7],
[59,39,31,10],
[59,39,31,11],
[59,39,31,12],
[59,39,31,13],
[59,39,31,14],
[59,39,31,16],
[59,39,31,17],
[59,39,31,19],
[59,39,31,20],
[59,39,32,0],
[59,39,32,1],
[59,39,32,2],
[59,39,32,4],
[59,39,32,7],
[59,39,32,10],
[59,39,32,13],
[59,39,32,14],
[59,39,32,16],
[59,39,32,17],
[59,39,32,19],
[59,39,33,0],
[59,39,33,1],
[59,39,33,2],
[59,39,33,4],
[59,39,33,7],
[59,39,33,10],
[59,39,33,13],
[59,39,33,14],
[59,39,33,16],
[59,39,33,17],
[59,39,34,0],
[59,39,34,1],
[59,39,34,2],
[59,39,34,4],
[59,39,34,10],
[59,39,34,11],
[59,39,34,12],
[59,39,34,13],
[59,39,34,14],
[59,39,34,16],
[59,39,34,17],
[59,39,36,0],
[59,39,36,1],
[59,39,36,2],
[59,39,36,4],
[59,39,36,7],
[59,39,36,10],
[59,39,36,11],
[59,39,36,12],
[59,39,37,0],
[59,39,37,1],
[59,39,37,2],
[59,39,37,7],
[59,39,37,10],
[59,39,37,11],
[59,39,37,12],
[59,39,37,13],
[59,39,38,0],
[59,39,38,1],
[59,39,38,2],
[59,39,38,4],
[59,39,38,10],
[59,39,38,11],
[59,42,3,0],
[59,42,3,1],
[59,42,3,2],
[59,42,4,0],
[59,42,4,1],
[59,42,4,2],
[59,42,4,3],
[59,42,5,0],
[59,42,5,1],
[59,42,5,2],
[59,42,5,4],
[59,42,6,0],
[59,42,6,1],
[59,42,6,2],
[59,42,6,4],
[59,42,7,0],
[59,42,7,1],
[59,42,7,2],
[59,42,7,3],
[59,42,7,4],
[59,42,7,5],
[59,42,7,6],
[59,42,8,0],
[59,42,8,1],
[59,42,8,2],
[59,42,8,4],
[59,42,8,7],
[59,42,11,0],
[59,42,11,1],
[59,42,11,2],
[59,42,11,3],
[59,42,11,4],
[59,42,11,5],
[59,42,11,6],
[59,42,11,7],
[59,42,11,8],
[59,42,12,0],
[59,42,12,1],
[59,42,12,2],
[59,42,12,3],
[59,42,12,4],
[59,42,12,5],
[59,42,12,6],
[59,42,12,7],
[59,42,12,8],
[59,42,13,0],
[59,42,13,1],
[59,42,13,2],
[59,42,13,3],
[59,42,13,4],
[59,42,13,5],
[59,42,13,6],
[59,42,13,7],
[59,42,13,8],
[59,42,13,11],
[59,42,13,12],
[59,42,14,0],
[59,42,14,1],
[59,42,14,2],
[59,42,14,3],
[59,42,14,4],
[59,42,14,5],
[59,42,14,6],
[59,42,14,7],
[59,42,14,8],
[59,42,14,11],
[59,42,14,12],
[59,42,16,0],
[59,42,16,1],
[59,42,16,2],
[59,42,16,3],
[59,42,16,4],
[59,42,16,5],
[59,42,16,6],
[59,42,16,7],
[59,42,16,8],
[59,42,16,11],
[59,42,16,12],
[59,42,16,13],
[59,42,16,14],
[59,42,17,0],
[59,42,17,1],
[59,42,17,2],
[59,42,17,3],
[59,42,17,4],
[59,42,17,5],
[59,42,17,6],
[59,42,17,7],
[59,42,17,8],
[59,42,17,11],
[59,42,17,12],
[59,42,17,13],
[59,42,17,14],
[59,42,17,16],
[59,42,19,0],
[59,42,19,1],
[59,42,19,2],
[59,42,19,3],
[59,42,19,4],
[59,42,19,5],
[59,42,19,6],
[59,42,19,7],
[59,42,19,8],
[59,42,19,11],
[59,42,19,12],
[59,42,19,16],
[59,42,19,17],
[59,42,21,0],
[59,42,21,1],
[59,42,21,2],
[59,42,21,4],
[59,42,21,7],
[59,42,21,11],
[59,42,21,12],
[59,42,21,13],
[59,42,21,14],
[59,42,21,16],
[59,42,21,17],
[59,42,21,19],
[59,42,23,0],
[59,42,23,1],
[59,42,23,2],
[59,42,23,3],
[59,42,23,4],
[59,42,23,5],
[59,42,23,6],
[59,42,23,7],
[59,42,23,8],
[59,42,23,11],
[59,42,23,14],
[59,42,23,16],
[59,42,23,17],
[59,42,23,19],
[59,42,23,21],
[59,42,24,0],
[59,42,24,1],
[59,42,24,2],
[59,42,24,3],
[59,42,24,4],
[59,42,24,5],
[59,42,24,6],
[59,42,24,7],
[59,42,24,8],
[59,42,24,11],
[59,42,24,14],
[59,42,24,17],
[59,42,24,19],
[59,42,24,21],
[59,42,24,23],
[59,42,25,0],
[59,42,25,1],
[59,42,25,2],
[59,42,25,3],
[59,42,25,4],
[59,42,25,5],
[59,42,25,6],
[59,42,25,11],
[59,42,25,12],
[59,42,25,13],
[59,42,25,14],
[59,42,25,16],
[59,42,25,17],
[59,42,25,19],
[59,42,25,21],
[59,42,25,23],
[59,42,25,24],
[59,42,28,0],
[59,42,28,1],
[59,42,28,2],
[59,42,28,5],
[59,42,28,6],
[59,42,28,8],
[59,42,28,11],
[59,42,28,12],
[59,42,28,13],
[59,42,28,14],
[59,42,28,16],
[59,42,28,17],
[59,42,28,19],
[59,42,28,21],
[59,42,29,3],
[59,42,29,4],
[59,42,29,5],
[59,42,29,6],
[59,42,29,7],
[59,42,29,8],
[59,42,29,13],
[59,42,29,14],
[59,42,29,16],
[59,42,29,17],
[59,42,29,19],
[59,42,30,0],
[59,42,30,1],
[59,42,30,2],
[59,42,30,3],
[59,42,30,4],
[59,42,30,5],
[59,42,30,6],
[59,42,30,7],
[59,42,30,8],
[59,42,30,11],
[59,42,30,12],
[59,42,30,13],
[59,42,30,14],
[59,42,30,16],
[59,42,30,17],
[59,42,30,19],
[59,42,31,0],
[59,42,31,1],
[59,42,31,2],
[59,42,31,3],
[59,42,31,4],
[59,42,31,5],
[59,42,31,6],
[59,42,31,7],
[59,42,31,8],
[59,42,31,11],
[59,42,31,12],
[59,42,31,13],
[59,42,31,14],
[59,42,31,16],
[59,42,31,17],
[59,42,32,0],
[59,42,32,1],
[59,42,32,2],
[59,42,32,3],
[59,42,32,4],
[59,42,32,5],
[59,42,32,6],
[59,42,32,7],
[59,42,32,8],
[59,42,32,13],
[59,42,32,14],
[59,42,32,16],
[59,42,32,17],
[59,42,33,0],
[59,42,33,1],
[59,42,33,2],
[59,42,33,3],
[59,42,33,4],
[59,42,33,5],
[59,42,33,6],
[59,42,33,7],
[59,42,33,8],
[59,42,33,13],
[59,42,33,14],
[59,42,34,0],
[59,42,34,1],
[59,42,34,2],
[59,42,34,3],
[59,42,34,4],
[59,42,34,5],
[59,42,34,6],
[59,42,34,8],
[59,42,34,11],
[59,42,34,12],
[59,42,34,13],
[59,42,34,14],
[59,42,36,0],
[59,42,36,1],
[59,42,36,2],
[59,42,36,3],
[59,42,36,4],
[59,42,36,5],
[59,42,36,6],
[59,42,36,7],
[59,42,36,8],
[59,42,36,11],
[59,42,37,0],
[59,42,37,1],
[59,42,37,2],
[59,42,37,3],
[59,42,37,5],
[59,42,37,6],
[59,42,37,7],
[59,42,37,8],
[59,42,38,0],
[59,42,38,1],
[59,42,38,2],
[59,42,38,3],
[59,42,38,4],
[59,42,38,5],
[59,42,38,6],
[59,42,39,0],
[59,42,39,1],
[59,42,39,2],
[59,42,39,4],
[59,43,3,0],
[59,43,3,1],
[59,43,3,2],
[59,43,4,0],
[59,43,4,1],
[59,43,4,2],
[59,43,4,3],
[59,43,5,0],
[59,43,5,1],
[59,43,5,2],
[59,43,5,4],
[59,43,6,0],
[59,43,6,1],
[59,43,6,2],
[59,43,6,4],
[59,43,8,0],
[59,43,8,1],
[59,43,8,2],
[59,43,8,4],
[59,43,10,0],
[59,43,10,1],
[59,43,10,2],
[59,43,10,3],
[59,43,10,4],
[59,43,10,5],
[59,43,10,6],
[59,43,10,8],
[59,43,11,0],
[59,43,11,1],
[59,43,11,2],
[59,43,11,3],
[59,43,11,4],
[59,43,11,5],
[59,43,11,6],
[59,43,11,8],
[59,43,11,10],
[59,43,12,0],
[59,43,12,1],
[59,43,12,2],
[59,43,12,3],
[59,43,12,4],
[59,43,12,5],
[59,43,12,6],
[59,43,12,8],
[59,43,12,10],
[59,43,13,0],
[59,43,13,1],
[59,43,13,2],
[59,43,13,3],
[59,43,13,4],
[59,43,13,5],
[59,43,13,6],
[59,43,13,8],
[59,43,13,10],
[59,43,13,11],
[59,43,13,12],
[59,43,14,0],
[59,43,14,1],
[59,43,14,2],
[59,43,14,3],
[59,43,14,4],
[59,43,14,5],
[59,43,14,6],
[59,43,14,8],
[59,43,14,10],
[59,43,14,11],
[59,43,14,12],
[59,43,16,0],
[59,43,16,1],
[59,43,16,2],
[59,43,16,3],
[59,43,16,4],
[59,43,16,5],
[59,43,16,6],
[59,43,16,8],
[59,43,16,10],
[59,43,16,11],
[59,43,16,12],
[59,43,16,13],
[59,43,16,14],
[59,43,17,0],
[59,43,17,1],
[59,43,17,2],
[59,43,17,3],
[59,43,17,4],
[59,43,17,5],
[59,43,17,6],
[59,43,17,8],
[59,43,17,10],
[59,43,17,11],
[59,43,17,12],
[59,43,17,13],
[59,43,17,14],
[59,43,17,16],
[59,43,19,0],
[59,43,19,1],
[59,43,19,2],
[59,43,19,3],
[59,43,19,4],
[59,43,19,5],
[59,43,19,6],
[59,43,19,8],
[59,43,19,10],
[59,43,19,11],
[59,43,19,12],
[59,43,20,0],
[59,43,20,1],
[59,43,20,2],
[59,43,20,3],
[59,43,20,4],
[59,43,20,5],
[59,43,20,6],
[59,43,20,8],
[59,43,20,11],
[59,43,20,12],
[59,43,20,13],
[59,43,20,14],
[59,43,20,16],
[59,43,20,17],
[59,43,20,19],
[59,43,21,0],
[59,43,21,1],
[59,43,21,2],
[59,43,21,4],
[59,43,21,10],
[59,43,21,11],
[59,43,21,12],
[59,43,21,13],
[59,43,21,16],
[59,43,21,17],
[59,43,21,19],
[59,43,21,20],
[59,43,22,0],
[59,43,22,1],
[59,43,22,2],
[59,43,22,3],
[59,43,22,4],
[59,43,22,5],
[59,43,22,6],
[59,43,22,8],
[59,43,22,11],
[59,43,22,14],
[59,43,22,16],
[59,43,22,17],
[59,43,22,19],
[59,43,22,21],
[59,43,23,0],
[59,43,23,1],
[59,43,23,2],
[59,43,23,3],
[59,43,23,4],
[59,43,23,5],
[59,43,23,6],
[59,43,23,8],
[59,43,23,12],
[59,43,23,13],
[59,43,23,14],
[59,43,23,16],
[59,43,23,17],
[59,43,23,19],
[59,43,23,20],
[59,43,23,21],
[59,43,23,22],
[59,43,24,0],
[59,43,24,1],
[59,43,24,2],
[59,43,24,3],
[59,43,24,4],
[59,43,24,5],
[59,43,24,6],
[59,43,24,8],
[59,43,24,12],
[59,43,24,13],
[59,43,24,14],
[59,43,24,17],
[59,43,24,19],
[59,43,24,20],
[59,43,24,21],
[59,43,24,22],
[59,43,24,23],
[59,43,27,0],
[59,43,27,1],
[59,43,27,2],
[59,43,27,5],
[59,43,27,6],
[59,43,27,8],
[59,43,27,11],
[59,43,27,12],
[59,43,27,13],
[59,43,27,14],
[59,43,27,16],
[59,43,27,17],
[59,43,27,19],
[59,43,27,21],
[59,43,29,0],
[59,43,29,1],
[59,43,29,2],
[59,43,29,3],
[59,43,29,4],
[59,43,29,5],
[59,43,29,6],
[59,43,29,8],
[59,43,29,10],
[59,43,29,13],
[59,43,29,14],
[59,43,29,16],
[59,43,29,17],
[59,43,29,19],
[59,43,30,0],
[59,43,30,1],
[59,43,30,2],
[59,43,30,3],
[59,43,30,4],
[59,43,30,5],
[59,43,30,6],
[59,43,30,8],
[59,43,30,10],
[59,43,30,11],
[59,43,30,12],
[59,43,30,13],
[59,43,30,14],
[59,43,30,16],
[59,43,30,17],
[59,43,31,0],
[59,43,31,1],
[59,43,31,2],
[59,43,31,3],
[59,43,31,4],
[59,43,31,5],
[59,43,31,6],
[59,43,31,8],
[59,43,31,10],
[59,43,31,11],
[59,43,31,12],
[59,43,31,13],
[59,43,31,14],
[59,43,31,16],
[59,43,31,17],
[59,43,32,0],
[59,43,32,1],
[59,43,32,2],
[59,43,32,3],
[59,43,32,4],
[59,43,32,5],
[59,43,32,6],
[59,43,32,8],
[59,43,32,10],
[59,43,32,13],
[59,43,32,14],
[59,43,33,0],
[59,43,33,1],
[59,43,33,2],
[59,43,33,3],
[59,43,33,4],
[59,43,33,5],
[59,43,33,6],
[59,43,33,8],
[59,43,33,10],
[59,43,33,13],
[59,43,33,14],
[59,43,36,0],
[59,43,36,1],
[59,43,36,2],
[59,43,36,3],
[59,43,36,4],
[59,43,36,5],
[59,43,36,6],
[59,43,36,8],
[59,43,37,0],
[59,43,37,1],
[59,43,37,2],
[59,43,37,3],
[59,43,37,5],
[59,43,37,6],
[59,43,39,0],
[59,43,39,1],
[59,43,39,2],
[59,43,39,4],
[59,44,3,0],
[59,44,3,1],
[59,44,3,2],
[59,44,4,0],
[59,44,4,1],
[59,44,4,2],
[59,44,4,3],
[59,44,5,0],
[59,44,5,1],
[59,44,5,2],
[59,44,5,4],
[59,44,6,0],
[59,44,6,1],
[59,44,6,2],
[59,44,6,4],
[59,44,7,0],
[59,44,7,1],
[59,44,7,2],
[59,44,7,3],
[59,44,7,4],
[59,44,7,5],
[59,44,7,6],
[59,44,8,0],
[59,44,8,1],
[59,44,8,2],
[59,44,8,4],
[59,44,8,7],
[59,44,10,0],
[59,44,10,1],
[59,44,10,2],
[59,44,10,3],
[59,44,10,4],
[59,44,10,5],
[59,44,10,6],
[59,44,10,7],
[59,44,10,8],
[59,44,11,0],
[59,44,11,1],
[59,44,11,2],
[59,44,11,3],
[59,44,11,4],
[59,44,11,5],
[59,44,11,6],
[59,44,11,7],
[59,44,11,8],
[59,44,11,10],
[59,44,12,0],
[59,44,12,1],
[59,44,12,2],
[59,44,12,3],
[59,44,12,4],
[59,44,12,5],
[59,44,12,6],
[59,44,12,7],
[59,44,12,8],
[59,44,12,10],
[59,44,13,0],
[59,44,13,1],
[59,44,13,2],
[59,44,13,3],
[59,44,13,4],
[59,44,13,5],
[59,44,13,6],
[59,44,13,7],
[59,44,13,8],
[59,44,13,10],
[59,44,13,11],
[59,44,13,12],
[59,44,14,0],
[59,44,14,1],
[59,44,14,2],
[59,44,14,3],
[59,44,14,4],
[59,44,14,5],
[59,44,14,6],
[59,44,14,7],
[59,44,14,8],
[59,44,14,10],
[59,44,14,11],
[59,44,14,12],
[59,44,16,0],
[59,44,16,1],
[59,44,16,2],
[59,44,16,3],
[59,44,16,4],
[59,44,16,5],
[59,44,16,6],
[59,44,16,7],
[59,44,16,8],
[59,44,16,10],
[59,44,16,11],
[59,44,16,12],
[59,44,16,13],
[59,44,16,14],
[59,44,19,0],
[59,44,19,1],
[59,44,19,2],
[59,44,19,3],
[59,44,19,4],
[59,44,19,5],
[59,44,19,6],
[59,44,19,7],
[59,44,19,8],
[59,44,19,10],
[59,44,19,11],
[59,44,19,12],
[59,44,19,16],
[59,44,20,0],
[59,44,20,1],
[59,44,20,2],
[59,44,20,3],
[59,44,20,4],
[59,44,20,5],
[59,44,20,6],
[59,44,20,7],
[59,44,20,8],
[59,44,20,11],
[59,44,20,12],
[59,44,20,13],
[59,44,20,16],
[59,44,20,19],
[59,44,21,0],
[59,44,21,1],
[59,44,21,2],
[59,44,21,4],
[59,44,21,7],
[59,44,21,10],
[59,44,21,11],
[59,44,21,14],
[59,44,21,16],
[59,44,21,19],
[59,44,21,20],
[59,44,22,0],
[59,44,22,1],
[59,44,22,2],
[59,44,22,3],
[59,44,22,4],
[59,44,22,5],
[59,44,22,6],
[59,44,22,7],
[59,44,22,8],
[59,44,22,12],
[59,44,22,13],
[59,44,22,14],
[59,44,22,16],
[59,44,22,19],
[59,44,22,21],
[59,44,23,0],
[59,44,23,1],
[59,44,23,2],
[59,44,23,3],
[59,44,23,4],
[59,44,23,5],
[59,44,23,6],
[59,44,23,7],
[59,44,23,10],
[59,44,23,11],
[59,44,23,12],
[59,44,23,13],
[59,44,23,14],
[59,44,23,16],
[59,44,23,19],
[59,44,23,20],
[59,44,23,21],
[59,44,23,22],
[59,44,24,0],
[59,44,24,1],
[59,44,24,2],
[59,44,24,3],
[59,44,24,4],
[59,44,24,5],
[59,44,24,6],
[59,44,24,7],
[59,44,24,10],
[59,44,24,11],
[59,44,24,12],
[59,44,24,13],
[59,44,24,14],
[59,44,24,19],
[59,44,24,20],
[59,44,24,21],
[59,44,24,22],
[59,44,24,23],
[59,44,25,0],
[59,44,25,1],
[59,44,25,2],
[59,44,25,3],
[59,44,25,4],
[59,44,25,6],
[59,44,25,8],
[59,44,25,10],
[59,44,25,11],
[59,44,25,12],
[59,44,25,13],
[59,44,25,14],
[59,44,25,16],
[59,44,25,19],
[59,44,25,20],
[59,44,25,21],
[59,44,25,22],
[59,44,27,3],
[59,44,27,4],
[59,44,27,5],
[59,44,27,6],
[59,44,27,7],
[59,44,27,8],
[59,44,27,11],
[59,44,27,12],
[59,44,27,13],
[59,44,27,14],
[59,44,27,16],
[59,44,27,19],
[59,44,28,0],
[59,44,28,1],
[59,44,28,2],
[59,44,28,3],
[59,44,28,4],
[59,44,28,5],
[59,44,28,6],
[59,44,28,8],
[59,44,28,10],
[59,44,28,11],
[59,44,28,12],
[59,44,28,13],
[59,44,28,14],
[59,44,28,16],
[59,44,28,19],
[59,44,29,0],
[59,44,29,1],
[59,44,29,2],
[59,44,29,3],
[59,44,29,4],
[59,44,29,5],
[59,44,29,6],
[59,44,29,7],
[59,44,29,8],
[59,44,29,10],
[59,44,29,13],
[59,44,29,14],
[59,44,29,16],
[59,44,30,0],
[59,44,30,1],
[59,44,30,2],
[59,44,30,3],
[59,44,30,4],
[59,44,30,5],
[59,44,30,6],
[59,44,30,7],
[59,44,30,8],
[59,44,30,10],
[59,44,30,11],
[59,44,30,12],
[59,44,30,13],
[59,44,30,14],
[59,44,30,16],
[59,44,31,0],
[59,44,31,1],
[59,44,31,2],
[59,44,31,3],
[59,44,31,4],
[59,44,31,5],
[59,44,31,6],
[59,44,31,7],
[59,44,31,8],
[59,44,31,10],
[59,44,31,11],
[59,44,31,12],
[59,44,31,13],
[59,44,31,14],
[59,44,32,0],
[59,44,32,1],
[59,44,32,2],
[59,44,32,3],
[59,44,32,4],
[59,44,32,5],
[59,44,32,6],
[59,44,32,7],
[59,44,32,8],
[59,44,32,10],
[59,44,32,13],
[59,44,32,14],
[59,44,33,0],
[59,44,33,1],
[59,44,33,2],
[59,44,33,3],
[59,44,33,4],
[59,44,33,5],
[59,44,33,6],
[59,44,33,7],
[59,44,33,8],
[59,44,33,10],
[59,44,33,13],
[59,44,34,0],
[59,44,34,1],
[59,44,34,2],
[59,44,34,3],
[59,44,34,4],
[59,44,34,5],
[59,44,34,6],
[59,44,34,8],
[59,44,34,10],
[59,44,34,11],
[59,44,36,0],
[59,44,36,1],
[59,44,36,2],
[59,44,36,3],
[59,44,36,4],
[59,44,36,5],
[59,44,36,6],
[59,44,36,7],
[59,44,37,0],
[59,44,37,1],
[59,44,37,2],
[59,44,37,3],
[59,44,37,5],
[59,44,38,0],
[59,44,38,1],
[59,44,38,2],
[59,44,38,3],
[59,44,38,4],
[59,44,39,0],
[59,44,39,1],
[59,44,39,2],
[59,47,3,0],
[59,47,3,1],
[59,47,3,2],
[59,47,4,0],
[59,47,4,1],
[59,47,4,2],
[59,47,4,3],
[59,47,5,0],
[59,47,5,1],
[59,47,5,2],
[59,47,5,4],
[59,47,6,0],
[59,47,6,1],
[59,47,6,2],
[59,47,6,4],
[59,47,7,0],
[59,47,7,1],
[59,47,7,2],
[59,47,7,3],
[59,47,7,4],
[59,47,7,5],
[59,47,7,6],
[59,47,8,0],
[59,47,8,1],
[59,47,8,2],
[59,47,8,4],
[59,47,8,7],
[59,47,10,0],
[59,47,10,1],
[59,47,10,2],
[59,47,10,3],
[59,47,10,4],
[59,47,10,5],
[59,47,10,6],
[59,47,10,7],
[59,47,10,8],
[59,47,11,0],
[59,47,11,1],
[59,47,11,2],
[59,47,11,3],
[59,47,11,4],
[59,47,11,5],
[59,47,11,6],
[59,47,11,7],
[59,47,11,8],
[59,47,11,10],
[59,47,12,0],
[59,47,12,1],
[59,47,12,2],
[59,47,12,3],
[59,47,12,4],
[59,47,12,5],
[59,47,12,6],
[59,47,12,7],
[59,47,12,8],
[59,47,12,10],
[59,47,13,0],
[59,47,13,1],
[59,47,13,2],
[59,47,13,3],
[59,47,13,4],
[59,47,13,5],
[59,47,13,6],
[59,47,13,7],
[59,47,13,8],
[59,47,13,10],
[59,47,13,11],
[59,47,13,12],
[59,47,14,0],
[59,47,14,1],
[59,47,14,2],
[59,47,14,3],
[59,47,14,4],
[59,47,14,5],
[59,47,14,6],
[59,47,14,7],
[59,47,14,8],
[59,47,14,10],
[59,47,14,11],
[59,47,14,12],
[59,47,16,0],
[59,47,16,1],
[59,47,16,2],
[59,47,16,3],
[59,47,16,4],
[59,47,16,5],
[59,47,16,6],
[59,47,16,7],
[59,47,16,8],
[59,47,16,10],
[59,47,16,11],
[59,47,16,12],
[59,47,16,13],
[59,47,17,0],
[59,47,17,1],
[59,47,17,2],
[59,47,17,3],
[59,47,17,4],
[59,47,17,5],
[59,47,17,6],
[59,47,17,7],
[59,47,17,8],
[59,47,17,10],
[59,47,17,11],
[59,47,17,12],
[59,47,17,13],
[59,47,17,16],
[59,47,19,0],
[59,47,19,1],
[59,47,19,2],
[59,47,19,3],
[59,47,19,4],
[59,47,19,5],
[59,47,19,6],
[59,47,19,7],
[59,47,19,8],
[59,47,19,12],
[59,47,19,16],
[59,47,19,17],
[59,47,20,0],
[59,47,20,1],
[59,47,20,2],
[59,47,20,3],
[59,47,20,4],
[59,47,20,5],
[59,47,20,6],
[59,47,20,7],
[59,47,20,11],
[59,47,20,12],
[59,47,20,13],
[59,47,20,14],
[59,47,20,16],
[59,47,20,17],
[59,47,20,19],
[59,47,21,0],
[59,47,21,1],
[59,47,21,2],
[59,47,21,4],
[59,47,21,10],
[59,47,21,11],
[59,47,21,12],
[59,47,21,13],
[59,47,21,14],
[59,47,21,16],
[59,47,21,17],
[59,47,21,19],
[59,47,21,20],
[59,47,22,0],
[59,47,22,1],
[59,47,22,2],
[59,47,22,3],
[59,47,22,4],
[59,47,22,6],
[59,47,22,7],
[59,47,22,8],
[59,47,22,11],
[59,47,22,12],
[59,47,22,13],
[59,47,22,14],
[59,47,22,16],
[59,47,22,17],
[59,47,22,19],
[59,47,22,21],
[59,47,24,0],
[59,47,24,1],
[59,47,24,2],
[59,47,24,5],
[59,47,24,6],
[59,47,24,7],
[59,47,24,8],
[59,47,24,10],
[59,47,24,11],
[59,47,24,12],
[59,47,24,13],
[59,47,24,14],
[59,47,24,17],
[59,47,24,19],
[59,47,24,20],
[59,47,24,21],
[59,47,25,0],
[59,47,25,1],
[59,47,25,2],
[59,47,25,3],
[59,47,25,4],
[59,47,25,5],
[59,47,25,6],
[59,47,25,8],
[59,47,25,10],
[59,47,25,11],
[59,47,25,12],
[59,47,25,13],
[59,47,25,14],
[59,47,25,16],
[59,47,25,17],
[59,47,25,19],
[59,47,27,0],
[59,47,27,1],
[59,47,27,2],
[59,47,27,3],
[59,47,27,4],
[59,47,27,5],
[59,47,27,6],
[59,47,27,7],
[59,47,27,8],
[59,47,27,11],
[59,47,27,12],
[59,47,27,13],
[59,47,27,14],
[59,47,27,16],
[59,47,27,17],
[59,47,28,0],
[59,47,28,1],
[59,47,28,2],
[59,47,28,3],
[59,47,28,4],
[59,47,28,5],
[59,47,28,6],
[59,47,28,8],
[59,47,28,10],
[59,47,28,11],
[59,47,28,12],
[59,47,28,13],
[59,47,28,14],
[59,47,29,0],
[59,47,29,1],
[59,47,29,2],
[59,47,29,3],
[59,47,29,4],
[59,47,29,5],
[59,47,29,6],
[59,47,29,7],
[59,47,29,8],
[59,47,29,10],
[59,47,29,13],
[59,47,29,14],
[59,47,32,0],
[59,47,32,1],
[59,47,32,2],
[59,47,32,3],
[59,47,32,4],
[59,47,32,5],
[59,47,32,6],
[59,47,32,7],
[59,47,32,8],
[59,47,33,0],
[59,47,33,1],
[59,47,33,2],
[59,47,33,3],
[59,47,33,4],
[59,47,33,5],
[59,47,33,6],
[59,47,33,7],
[59,47,34,0],
[59,47,34,1],
[59,47,34,2],
[59,47,34,3],
[59,47,34,4],
[59,47,34,5],
[59,47,36,0],
[59,47,36,1],
[59,47,36,2],
[59,48,3,0],
[59,48,3,1],
[59,48,3,2],
[59,48,4,0],
[59,48,4,1],
[59,48,4,2],
[59,48,4,3],
[59,48,5,0],
[59,48,5,1],
[59,48,5,2],
[59,48,5,4],
[59,48,6,0],
[59,48,6,1],
[59,48,6,2],
[59,48,6,4],
[59,48,7,0],
[59,48,7,1],
[59,48,7,2],
[59,48,7,3],
[59,48,7,4],
[59,48,7,5],
[59,48,7,6],
[59,48,8,0],
[59,48,8,1],
[59,48,8,2],
[59,48,8,4],
[59,48,8,7],
[59,48,10,0],
[59,48,10,1],
[59,48,10,2],
[59,48,10,3],
[59,48,10,4],
[59,48,10,5],
[59,48,10,6],
[59,48,10,7],
[59,48,10,8],
[59,48,13,0],
[59,48,13,1],
[59,48,13,2],
[59,48,13,3],
[59,48,13,4],
[59,48,13,5],
[59,48,13,6],
[59,48,13,7],
[59,48,13,8],
[59,48,13,10],
[59,48,14,0],
[59,48,14,1],
[59,48,14,2],
[59,48,14,3],
[59,48,14,4],
[59,48,14,5],
[59,48,14,6],
[59,48,14,7],
[59,48,14,8],
[59,48,14,10],
[59,48,16,0],
[59,48,16,1],
[59,48,16,2],
[59,48,16,3],
[59,48,16,4],
[59,48,16,5],
[59,48,16,6],
[59,48,16,7],
[59,48,16,8],
[59,48,16,10],
[59,48,16,14],
[59,48,17,0],
[59,48,17,1],
[59,48,17,2],
[59,48,17,3],
[59,48,17,4],
[59,48,17,5],
[59,48,17,6],
[59,48,17,7],
[59,48,17,8],
[59,48,17,10],
[59,48,17,14],
[59,48,17,16],
[59,48,19,0],
[59,48,19,1],
[59,48,19,2],
[59,48,19,3],
[59,48,19,4],
[59,48,19,5],
[59,48,19,6],
[59,48,19,7],
[59,48,19,10],
[59,48,19,16],
[59,48,19,17],
[59,48,20,0],
[59,48,20,1],
[59,48,20,2],
[59,48,20,3],
[59,48,20,4],
[59,48,20,5],
[59,48,20,8],
[59,48,20,13],
[59,48,20,14],
[59,48,20,16],
[59,48,20,17],
[59,48,20,19],
[59,48,21,0],
[59,48,21,1],
[59,48,21,2],
[59,48,21,4],
[59,48,21,7],
[59,48,21,10],
[59,48,21,13],
[59,48,21,14],
[59,48,21,16],
[59,48,21,17],
[59,48,21,19],
[59,48,21,20],
[59,48,22,0],
[59,48,22,1],
[59,48,22,2],
[59,48,22,5],
[59,48,22,6],
[59,48,22,7],
[59,48,22,8],
[59,48,22,13],
[59,48,22,14],
[59,48,22,16],
[59,48,22,17],
[59,48,22,19],
[59,48,22,21],
[59,48,23,3],
[59,48,23,4],
[59,48,23,5],
[59,48,23,6],
[59,48,23,7],
[59,48,23,8],
[59,48,23,10],
[59,48,23,13],
[59,48,23,14],
[59,48,23,16],
[59,48,23,17],
[59,48,23,19],
[59,48,23,20],
[59,48,24,3],
[59,48,24,4],
[59,48,24,5],
[59,48,24,6],
[59,48,24,7],
[59,48,24,8],
[59,48,24,10],
[59,48,24,13],
[59,48,24,14],
[59,48,24,17],
[59,48,24,19],
[59,48,24,20],
[59,48,25,0],
[59,48,25,1],
[59,48,25,2],
[59,48,25,3],
[59,48,25,4],
[59,48,25,5],
[59,48,25,6],
[59,48,25,8],
[59,48,25,10],
[59,48,25,13],
[59,48,25,14],
[59,48,25,16],
[59,48,25,17],
[59,48,27,0],
[59,48,27,1],
[59,48,27,2],
[59,48,27,3],
[59,48,27,4],
[59,48,27,5],
[59,48,27,6],
[59,48,27,7],
[59,48,27,8],
[59,48,27,13],
[59,48,27,14],
[59,48,28,0],
[59,48,28,1],
[59,48,28,2],
[59,48,28,3],
[59,48,28,4],
[59,48,28,5],
[59,48,28,6],
[59,48,28,8],
[59,48,28,10],
[59,48,28,13],
[59,48,28,14],
[59,48,30,0],
[59,48,30,1],
[59,48,30,2],
[59,48,30,3],
[59,48,30,4],
[59,48,30,5],
[59,48,30,6],
[59,48,30,7],
[59,48,30,8],
[59,48,30,10],
[59,48,31,0],
[59,48,31,1],
[59,48,31,2],
[59,48,31,3],
[59,48,31,4],
[59,48,31,5],
[59,48,31,6],
[59,48,31,7],
[59,48,31,8],
[59,48,34,0],
[59,48,34,1],
[59,48,34,2],
[59,48,34,3],
[59,48,34,4],
[59,51,4,0],
[59,51,4,1],
[59,51,4,2],
[59,51,7,0],
[59,51,7,1],
[59,51,7,2],
[59,51,7,4],
[59,51,10,0],
[59,51,10,1],
[59,51,10,2],
[59,51,10,4],
[59,51,10,7],
[59,51,11,0],
[59,51,11,1],
[59,51,11,2],
[59,51,11,4],
[59,51,11,7],
[59,51,11,10],
[59,51,12,0],
[59,51,12,1],
[59,51,12,2],
[59,51,12,4],
[59,51,12,7],
[59,51,12,10],
[59,51,13,0],
[59,51,13,1],
[59,51,13,2],
[59,51,13,4],
[59,51,13,7],
[59,51,13,10],
[59,51,13,11],
[59,51,13,12],
[59,51,14,0],
[59,51,14,1],
[59,51,14,2],
[59,51,14,4],
[59,51,14,7],
[59,51,14,10],
[59,51,14,11],
[59,51,14,12],
[59,51,16,0],
[59,51,16,1],
[59,51,16,2],
[59,51,16,4],
[59,51,16,7],
[59,51,16,10],
[59,51,16,11],
[59,51,16,14],
[59,51,17,0],
[59,51,17,1],
[59,51,17,2],
[59,51,17,4],
[59,51,17,7],
[59,51,17,10],
[59,51,17,11],
[59,51,17,14],
[59,51,17,16],
[59,51,19,0],
[59,51,19,1],
[59,51,19,2],
[59,51,19,4],
[59,51,19,7],
[59,51,19,10],
[59,51,19,11],
[59,51,19,12],
[59,51,19,16],
[59,51,19,17],
[59,51,20,0],
[59,51,20,1],
[59,51,20,2],
[59,51,20,4],
[59,51,20,11],
[59,51,20,12],
[59,51,20,13],
[59,51,20,14],
[59,51,20,16],
[59,51,20,17],
[59,51,20,19],
[59,51,22,0],
[59,51,22,1],
[59,51,22,2],
[59,51,22,7],
[59,51,22,11],
[59,51,22,12],
[59,51,22,13],
[59,51,22,14],
[59,51,22,16],
[59,51,22,17],
[59,51,22,19],
[59,51,23,4],
[59,51,23,7],
[59,51,23,10],
[59,51,23,11],
[59,51,23,12],
[59,51,23,13],
[59,51,23,14],
[59,51,23,16],
[59,51,23,17],
[59,51,23,19],
[59,51,23,20],
[59,51,24,4],
[59,51,24,7],
[59,51,24,10],
[59,51,24,11],
[59,51,24,12],
[59,51,24,13],
[59,51,24,14],
[59,51,24,17],
[59,51,24,19],
[59,51,24,20],
[59,51,25,0],
[59,51,25,1],
[59,51,25,2],
[59,51,25,4],
[59,51,25,10],
[59,51,25,11],
[59,51,25,12],
[59,51,25,13],
[59,51,25,14],
[59,51,25,16],
[59,51,25,17],
[59,51,27,0],
[59,51,27,1],
[59,51,27,2],
[59,51,27,4],
[59,51,27,7],
[59,51,27,11],
[59,51,27,12],
[59,51,27,13],
[59,51,27,14],
[59,51,28,0],
[59,51,28,1],
[59,51,28,2],
[59,51,28,4],
[59,51,28,10],
[59,51,28,11],
[59,51,28,12],
[59,51,28,13],
[59,51,28,14],
[59,51,29,0],
[59,51,29,1],
[59,51,29,2],
[59,51,29,4],
[59,51,29,7],
[59,51,29,10],
[59,51,29,13],
[59,51,30,0],
[59,51,30,1],
[59,51,30,2],
[59,51,30,4],
[59,51,30,7],
[59,51,30,10],
[59,51,30,11],
[59,51,31,0],
[59,51,31,1],
[59,51,31,2],
[59,51,31,4],
[59,51,31,7],
[59,51,32,0],
[59,51,32,1],
[59,51,32,2],
[59,51,32,4],
[59,51,32,7],
[59,51,33,0],
[59,51,33,1],
[59,51,33,2],
[59,51,33,4],
[59,51,34,0],
[59,51,34,1],
[59,51,34,2],
[59,51,34,4],
[59,53,3,0],
[59,53,3,1],
[59,53,3,2],
[59,53,4,0],
[59,53,4,1],
[59,53,4,2],
[59,53,4,3],
[59,53,5,0],
[59,53,5,1],
[59,53,5,2],
[59,53,5,4],
[59,53,6,0],
[59,53,6,1],
[59,53,6,2],
[59,53,6,4],
[59,53,7,0],
[59,53,7,1],
[59,53,7,2],
[59,53,7,3],
[59,53,7,4],
[59,53,7,5],
[59,53,7,6],
[59,53,8,0],
[59,53,8,1],
[59,53,8,2],
[59,53,8,4],
[59,53,8,7],
[59,53,11,0],
[59,53,11,1],
[59,53,11,2],
[59,53,11,3],
[59,53,11,4],
[59,53,11,5],
[59,53,11,6],
[59,53,11,7],
[59,53,11,8],
[59,53,12,0],
[59,53,12,1],
[59,53,12,2],
[59,53,12,3],
[59,53,12,4],
[59,53,12,5],
[59,53,12,6],
[59,53,12,7],
[59,53,12,8],
[59,53,13,0],
[59,53,13,1],
[59,53,13,2],
[59,53,13,3],
[59,53,13,4],
[59,53,13,5],
[59,53,13,6],
[59,53,13,7],
[59,53,13,8],
[59,53,13,11],
[59,53,13,12],
[59,53,14,0],
[59,53,14,1],
[59,53,14,2],
[59,53,14,3],
[59,53,14,4],
[59,53,14,5],
[59,53,14,6],
[59,53,14,7],
[59,53,14,8],
[59,53,14,11],
[59,53,14,12],
[59,53,16,0],
[59,53,16,1],
[59,53,16,2],
[59,53,16,3],
[59,53,16,4],
[59,53,16,5],
[59,53,16,6],
[59,53,16,7],
[59,53,16,8],
[59,53,16,11],
[59,53,16,14],
[59,53,17,0],
[59,53,17,1],
[59,53,17,2],
[59,53,17,3],
[59,53,17,4],
[59,53,17,5],
[59,53,17,6],
[59,53,17,7],
[59,53,17,8],
[59,53,17,11],
[59,53,17,14],
[59,53,17,16],
[59,53,19,0],
[59,53,19,1],
[59,53,19,2],
[59,53,19,3],
[59,53,19,4],
[59,53,19,5],
[59,53,19,6],
[59,53,19,7],
[59,53,19,11],
[59,53,19,12],
[59,53,19,16],
[59,53,19,17],
[59,53,21,0],
[59,53,21,1],
[59,53,21,2],
[59,53,21,4],
[59,53,21,7],
[59,53,21,11],
[59,53,21,12],
[59,53,21,13],
[59,53,21,14],
[59,53,21,16],
[59,53,21,17],
[59,53,21,19],
[59,53,23,3],
[59,53,23,4],
[59,53,23,5],
[59,53,23,6],
[59,53,23,7],
[59,53,23,8],
[59,53,23,11],
[59,53,23,12],
[59,53,23,13],
[59,53,23,14],
[59,53,23,16],
[59,53,23,17],
[59,53,23,19],
[59,53,24,3],
[59,53,24,4],
[59,53,24,5],
[59,53,24,6],
[59,53,24,7],
[59,53,24,8],
[59,53,24,11],
[59,53,24,12],
[59,53,24,13],
[59,53,24,14],
[59,53,24,17],
[59,53,24,19],
[59,53,25,0],
[59,53,25,1],
[59,53,25,2],
[59,53,25,3],
[59,53,25,4],
[59,53,25,5],
[59,53,25,6],
[59,53,25,8],
[59,53,25,11],
[59,53,25,12],
[59,53,25,13],
[59,53,25,14],
[59,53,25,16],
[59,53,25,17],
[59,53,28,0],
[59,53,28,1],
[59,53,28,2],
[59,53,28,3],
[59,53,28,4],
[59,53,28,5],
[59,53,28,6],
[59,53,28,8],
[59,53,28,11],
[59,53,28,12],
[59,53,28,13],
[59,53,28,14],
[59,53,29,0],
[59,53,29,1],
[59,53,29,2],
[59,53,29,3],
[59,53,29,4],
[59,53,29,5],
[59,53,29,6],
[59,53,29,7],
[59,53,29,8],
[59,53,29,13],
[59,53,30,0],
[59,53,30,1],
[59,53,30,2],
[59,53,30,3],
[59,53,30,4],
[59,53,30,5],
[59,53,30,6],
[59,53,30,7],
[59,53,30,8],
[59,53,30,11],
[59,53,31,0],
[59,53,31,1],
[59,53,31,2],
[59,53,31,3],
[59,53,31,4],
[59,53,31,5],
[59,53,31,6],
[59,53,31,7],
[59,53,31,8],
[59,53,32,0],
[59,53,32,1],
[59,53,32,2],
[59,53,32,3],
[59,53,32,4],
[59,53,32,5],
[59,53,32,6],
[59,53,32,7],
[59,53,33,0],
[59,53,33,1],
[59,53,33,2],
[59,53,33,3],
[59,53,33,4],
[59,53,33,5],
[59,53,34,0],
[59,53,34,1],
[59,53,34,2],
[59,53,34,3],
[59,53,34,4],
[59,54,3,0],
[59,54,3,1],
[59,54,3,2],
[59,54,4,0],
[59,54,4,1],
[59,54,4,2],
[59,54,4,3],
[59,54,5,0],
[59,54,5,1],
[59,54,5,2],
[59,54,5,4],
[59,54,6,0],
[59,54,6,1],
[59,54,6,2],
[59,54,6,4],
[59,54,7,0],
[59,54,7,1],
[59,54,7,2],
[59,54,7,3],
[59,54,7,4],
[59,54,7,5],
[59,54,7,6],
[59,54,8,0],
[59,54,8,1],
[59,54,8,2],
[59,54,8,4],
[59,54,8,7],
[59,54,11,0],
[59,54,11,1],
[59,54,11,2],
[59,54,11,3],
[59,54,11,4],
[59,54,11,5],
[59,54,11,6],
[59,54,11,7],
[59,54,11,8],
[59,54,12,0],
[59,54,12,1],
[59,54,12,2],
[59,54,12,3],
[59,54,12,4],
[59,54,12,5],
[59,54,12,6],
[59,54,12,7],
[59,54,12,8],
[59,54,13,0],
[59,54,13,1],
[59,54,13,2],
[59,54,13,3],
[59,54,13,4],
[59,54,13,5],
[59,54,13,6],
[59,54,13,7],
[59,54,13,8],
[59,54,13,11],
[59,54,13,12],
[59,54,14,0],
[59,54,14,1],
[59,54,14,2],
[59,54,14,3],
[59,54,14,4],
[59,54,14,5],
[59,54,14,6],
[59,54,14,7],
[59,54,14,8],
[59,54,14,11],
[59,54,14,12],
[59,54,16,0],
[59,54,16,1],
[59,54,16,2],
[59,54,16,3],
[59,54,16,4],
[59,54,16,5],
[59,54,16,6],
[59,54,16,7],
[59,54,16,8],
[59,54,16,11],
[59,54,16,14],
[59,54,17,0],
[59,54,17,1],
[59,54,17,2],
[59,54,17,3],
[59,54,17,4],
[59,54,17,5],
[59,54,17,6],
[59,54,17,7],
[59,54,17,8],
[59,54,17,11],
[59,54,17,14],
[59,54,17,16],
[59,54,19,0],
[59,54,19,1],
[59,54,19,2],
[59,54,19,3],
[59,54,19,4],
[59,54,19,5],
[59,54,19,6],
[59,54,19,7],
[59,54,19,11],
[59,54,19,12],
[59,54,19,16],
[59,54,19,17],
[59,54,21,0],
[59,54,21,1],
[59,54,21,2],
[59,54,21,4],
[59,54,21,7],
[59,54,21,11],
[59,54,21,12],
[59,54,21,13],
[59,54,21,14],
[59,54,21,16],
[59,54,21,17],
[59,54,21,19],
[59,54,23,3],
[59,54,23,4],
[59,54,23,5],
[59,54,23,6],
[59,54,23,7],
[59,54,23,8],
[59,54,23,11],
[59,54,23,12],
[59,54,23,13],
[59,54,23,14],
[59,54,23,16],
[59,54,23,17],
[59,54,23,19],
[59,54,24,3],
[59,54,24,4],
[59,54,24,5],
[59,54,24,6],
[59,54,24,7],
[59,54,24,8],
[59,54,24,11],
[59,54,24,12],
[59,54,24,13],
[59,54,24,14],
[59,54,24,17],
[59,54,24,19],
[59,54,25,0],
[59,54,25,1],
[59,54,25,2],
[59,54,25,3],
[59,54,25,4],
[59,54,25,5],
[59,54,25,6],
[59,54,25,8],
[59,54,25,11],
[59,54,25,12],
[59,54,25,13],
[59,54,25,14],
[59,54,25,16],
[59,54,25,17],
[59,54,28,0],
[59,54,28,1],
[59,54,28,2],
[59,54,28,3],
[59,54,28,4],
[59,54,28,5],
[59,54,28,6],
[59,54,28,8],
[59,54,28,11],
[59,54,28,12],
[59,54,28,13],
[59,54,28,14],
[59,54,29,0],
[59,54,29,1],
[59,54,29,2],
[59,54,29,3],
[59,54,29,4],
[59,54,29,5],
[59,54,29,6],
[59,54,29,7],
[59,54,29,8],
[59,54,29,13],
[59,54,30,0],
[59,54,30,1],
[59,54,30,2],
[59,54,30,3],
[59,54,30,4],
[59,54,30,5],
[59,54,30,6],
[59,54,30,7],
[59,54,30,8],
[59,54,30,11],
[59,54,31,0],
[59,54,31,1],
[59,54,31,2],
[59,54,31,3],
[59,54,31,4],
[59,54,31,5],
[59,54,31,6],
[59,54,31,7],
[59,54,31,8],
[59,54,32,0],
[59,54,32,1],
[59,54,32,2],
[59,54,32,3],
[59,54,32,4],
[59,54,32,5],
[59,54,32,6],
[59,54,32,7],
[59,54,33,0],
[59,54,33,1],
[59,54,33,2],
[59,54,33,3],
[59,54,33,4],
[59,54,33,5],
[59,54,34,0],
[59,54,34,1],
[59,54,34,2],
[59,54,34,3],
[59,54,34,4],
[59,55,3,0],
[59,55,3,1],
[59,55,3,2],
[59,55,4,0],
[59,55,4,1],
[59,55,4,2],
[59,55,4,3],
[59,55,5,0],
[59,55,5,1],
[59,55,5,2],
[59,55,5,4],
[59,55,6,0],
[59,55,6,1],
[59,55,6,2],
[59,55,6,4],
[59,55,7,0],
[59,55,7,1],
[59,55,7,2],
[59,55,7,3],
[59,55,7,4],
[59,55,7,5],
[59,55,7,6],
[59,55,8,0],
[59,55,8,1],
[59,55,8,2],
[59,55,8,4],
[59,55,8,7],
[59,55,11,0],
[59,55,11,1],
[59,55,11,2],
[59,55,11,3],
[59,55,11,4],
[59,55,11,5],
[59,55,11,6],
[59,55,11,7],
[59,55,11,8],
[59,55,12,0],
[59,55,12,1],
[59,55,12,2],
[59,55,12,3],
[59,55,12,4],
[59,55,12,5],
[59,55,12,6],
[59,55,12,7],
[59,55,12,8],
[59,55,13,0],
[59,55,13,1],
[59,55,13,2],
[59,55,13,3],
[59,55,13,4],
[59,55,13,5],
[59,55,13,6],
[59,55,13,7],
[59,55,13,8],
[59,55,13,11],
[59,55,13,12],
[59,55,14,0],
[59,55,14,1],
[59,55,14,2],
[59,55,14,3],
[59,55,14,4],
[59,55,14,5],
[59,55,14,6],
[59,55,14,7],
[59,55,14,8],
[59,55,14,11],
[59,55,14,12],
[59,55,16,0],
[59,55,16,1],
[59,55,16,2],
[59,55,16,3],
[59,55,16,4],
[59,55,16,5],
[59,55,16,6],
[59,55,16,7],
[59,55,16,8],
[59,55,16,11],
[59,55,16,14],
[59,55,17,0],
[59,55,17,1],
[59,55,17,2],
[59,55,17,3],
[59,55,17,4],
[59,55,17,5],
[59,55,17,6],
[59,55,17,7],
[59,55,17,8],
[59,55,17,11],
[59,55,17,14],
[59,55,17,16],
[59,55,19,0],
[59,55,19,1],
[59,55,19,2],
[59,55,19,3],
[59,55,19,4],
[59,55,19,5],
[59,55,19,6],
[59,55,19,7],
[59,55,19,11],
[59,55,19,12],
[59,55,19,16],
[59,55,19,17],
[59,55,21,0],
[59,55,21,1],
[59,55,21,2],
[59,55,21,4],
[59,55,21,7],
[59,55,21,11],
[59,55,21,12],
[59,55,21,13],
[59,55,21,14],
[59,55,21,16],
[59,55,21,17],
[59,55,21,19],
[59,55,23,3],
[59,55,23,4],
[59,55,23,5],
[59,55,23,6],
[59,55,23,7],
[59,55,23,8],
[59,55,23,11],
[59,55,23,12],
[59,55,23,13],
[59,55,23,14],
[59,55,23,16],
[59,55,23,17],
[59,55,23,19],
[59,55,24,3],
[59,55,24,4],
[59,55,24,5],
[59,55,24,6],
[59,55,24,7],
[59,55,24,8],
[59,55,24,11],
[59,55,24,12],
[59,55,24,13],
[59,55,24,14],
[59,55,24,17],
[59,55,24,19],
[59,55,25,0],
[59,55,25,1],
[59,55,25,2],
[59,55,25,3],
[59,55,25,4],
[59,55,25,5],
[59,55,25,6],
[59,55,25,8],
[59,55,25,11],
[59,55,25,12],
[59,55,25,13],
[59,55,25,14],
[59,55,25,16],
[59,55,25,17],
[59,55,28,0],
[59,55,28,1],
[59,55,28,2],
[59,55,28,3],
[59,55,28,4],
[59,55,28,5],
[59,55,28,6],
[59,55,28,8],
[59,55,28,11],
[59,55,28,12],
[59,55,28,13],
[59,55,28,14],
[59,55,29,0],
[59,55,29,1],
[59,55,29,2],
[59,55,29,3],
[59,55,29,4],
[59,55,29,5],
[59,55,29,6],
[59,55,29,7],
[59,55,29,8],
[59,55,29,13],
[59,55,30,0],
[59,55,30,1],
[59,55,30,2],
[59,55,30,3],
[59,55,30,4],
[59,55,30,5],
[59,55,30,6],
[59,55,30,7],
[59,55,30,8],
[59,55,30,11],
[59,55,31,0],
[59,55,31,1],
[59,55,31,2],
[59,55,31,3],
[59,55,31,4],
[59,55,31,5],
[59,55,31,6],
[59,55,31,7],
[59,55,31,8],
[59,55,32,0],
[59,55,32,1],
[59,55,32,2],
[59,55,32,3],
[59,55,32,4],
[59,55,32,5],
[59,55,32,6],
[59,55,32,7],
[59,55,33,0],
[59,55,33,1],
[59,55,33,2],
[59,55,33,3],
[59,55,33,4],
[59,55,33,5],
[59,55,34,0],
[59,55,34,1],
[59,55,34,2],
[59,55,34,3],
[59,55,34,4],
[59,57,3,0],
[59,57,3,1],
[59,57,3,2],
[59,57,4,0],
[59,57,4,1],
[59,57,4,2],
[59,57,4,3],
[59,57,5,0],
[59,57,5,1],
[59,57,5,2],
[59,57,5,4],
[59,57,6,0],
[59,57,6,1],
[59,57,6,2],
[59,57,6,4],
[59,57,7,0],
[59,57,7,1],
[59,57,7,2],
[59,57,7,3],
[59,57,7,4],
[59,57,7,5],
[59,57,7,6],
[59,57,8,0],
[59,57,8,1],
[59,57,8,2],
[59,57,8,4],
[59,57,8,7],
[59,57,10,0],
[59,57,10,1],
[59,57,10,2],
[59,57,10,3],
[59,57,10,4],
[59,57,10,5],
[59,57,10,6],
[59,57,10,7],
[59,57,10,8],
[59,57,11,0],
[59,57,11,1],
[59,57,11,2],
[59,57,11,3],
[59,57,11,4],
[59,57,11,5],
[59,57,11,6],
[59,57,11,7],
[59,57,11,8],
[59,57,11,10],
[59,57,12,0],
[59,57,12,1],
[59,57,12,2],
[59,57,12,3],
[59,57,12,4],
[59,57,12,5],
[59,57,12,6],
[59,57,12,7],
[59,57,12,8],
[59,57,12,10],
[59,57,13,0],
[59,57,13,1],
[59,57,13,2],
[59,57,13,3],
[59,57,13,4],
[59,57,13,5],
[59,57,13,6],
[59,57,13,7],
[59,57,13,8],
[59,57,13,10],
[59,57,13,11],
[59,57,13,12],
[59,57,14,0],
[59,57,14,1],
[59,57,14,2],
[59,57,14,3],
[59,57,14,4],
[59,57,14,5],
[59,57,14,6],
[59,57,14,7],
[59,57,14,8],
[59,57,14,10],
[59,57,14,11],
[59,57,14,12],
[59,57,16,0],
[59,57,16,1],
[59,57,16,2],
[59,57,16,3],
[59,57,16,4],
[59,57,16,5],
[59,57,16,6],
[59,57,16,7],
[59,57,16,8],
[59,57,16,12],
[59,57,16,13],
[59,57,16,14],
[59,57,17,0],
[59,57,17,1],
[59,57,17,2],
[59,57,17,3],
[59,57,17,4],
[59,57,17,5],
[59,57,17,6],
[59,57,17,7],
[59,57,17,8],
[59,57,17,12],
[59,57,17,13],
[59,57,17,14],
[59,57,17,16],
[59,57,19,0],
[59,57,19,1],
[59,57,19,2],
[59,57,19,3],
[59,57,19,4],
[59,57,19,5],
[59,57,19,8],
[59,57,19,10],
[59,57,19,11],
[59,57,19,12],
[59,57,19,16],
[59,57,19,17],
[59,57,20,0],
[59,57,20,1],
[59,57,20,2],
[59,57,20,3],
[59,57,20,4],
[59,57,20,6],
[59,57,20,7],
[59,57,20,8],
[59,57,20,11],
[59,57,20,12],
[59,57,20,13],
[59,57,20,14],
[59,57,20,16],
[59,57,20,17],
[59,57,20,19],
[59,57,21,0],
[59,57,21,1],
[59,57,21,2],
[59,57,21,7],
[59,57,21,10],
[59,57,21,11],
[59,57,21,12],
[59,57,21,13],
[59,57,21,14],
[59,57,21,16],
[59,57,21,17],
[59,57,21,19],
[59,57,21,20],
[59,57,22,3],
[59,57,22,4],
[59,57,22,5],
[59,57,22,6],
[59,57,22,7],
[59,57,22,8],
[59,57,22,11],
[59,57,22,12],
[59,57,22,13],
[59,57,22,14],
[59,57,22,16],
[59,57,22,17],
[59,57,22,19],
[59,57,24,0],
[59,57,24,1],
[59,57,24,2],
[59,57,24,3],
[59,57,24,4],
[59,57,24,5],
[59,57,24,6],
[59,57,24,7],
[59,57,24,8],
[59,57,24,10],
[59,57,24,11],
[59,57,24,12],
[59,57,24,13],
[59,57,24,14],
[59,57,24,17],
[59,57,24,19],
[59,57,25,0],
[59,57,25,1],
[59,57,25,2],
[59,57,25,3],
[59,57,25,4],
[59,57,25,5],
[59,57,25,6],
[59,57,25,8],
[59,57,25,10],
[59,57,25,11],
[59,57,25,12],
[59,57,25,13],
[59,57,25,14],
[59,57,25,16],
[59,57,25,17],
[59,57,27,0],
[59,57,27,1],
[59,57,27,2],
[59,57,27,3],
[59,57,27,4],
[59,57,27,5],
[59,57,27,6],
[59,57,27,7],
[59,57,27,8],
[59,57,27,11],
[59,57,27,12],
[59,57,27,13],
[59,57,27,14],
[59,57,28,0],
[59,57,28,1],
[59,57,28,2],
[59,57,28,3],
[59,57,28,4],
[59,57,28,5],
[59,57,28,6],
[59,57,28,8],
[59,57,28,10],
[59,57,28,11],
[59,57,28,12],
[59,57,28,13],
[59,57,29,0],
[59,57,29,1],
[59,57,29,2],
[59,57,29,3],
[59,57,29,4],
[59,57,29,5],
[59,57,29,6],
[59,57,29,7],
[59,57,29,8],
[59,57,29,10],
[59,57,32,0],
[59,57,32,1],
[59,57,32,2],
[59,57,32,3],
[59,57,32,4],
[59,57,32,5],
[59,57,33,0],
[59,57,33,1],
[59,57,33,2],
[59,57,33,3],
[59,57,33,4],
[59,57,34,0],
[59,57,34,1],
[59,57,34,2],
[59,58,3,0],
[59,58,3,1],
[59,58,3,2],
[59,58,4,0],
[59,58,4,1],
[59,58,4,2],
[59,58,4,3],
[59,58,5,0],
[59,58,5,1],
[59,58,5,2],
[59,58,5,4],
[59,58,6,0],
[59,58,6,1],
[59,58,6,2],
[59,58,6,4],
[59,58,7,0],
[59,58,7,1],
[59,58,7,2],
[59,58,7,3],
[59,58,7,4],
[59,58,7,5],
[59,58,7,6],
[59,58,8,0],
[59,58,8,1],
[59,58,8,2],
[59,58,8,4],
[59,58,8,7],
[59,58,10,0],
[59,58,10,1],
[59,58,10,2],
[59,58,10,3],
[59,58,10,4],
[59,58,10,5],
[59,58,10,6],
[59,58,10,7],
[59,58,10,8],
[59,58,11,0],
[59,58,11,1],
[59,58,11,2],
[59,58,11,3],
[59,58,11,4],
[59,58,11,5],
[59,58,11,6],
[59,58,11,7],
[59,58,11,8],
[59,58,11,10],
[59,58,12,0],
[59,58,12,1],
[59,58,12,2],
[59,58,12,3],
[59,58,12,4],
[59,58,12,5],
[59,58,12,6],
[59,58,12,7],
[59,58,12,8],
[59,58,12,10],
[59,58,13,0],
[59,58,13,1],
[59,58,13,2],
[59,58,13,3],
[59,58,13,4],
[59,58,13,5],
[59,58,13,6],
[59,58,13,7],
[59,58,13,8],
[59,58,13,10],
[59,58,13,11],
[59,58,13,12],
[59,58,14,0],
[59,58,14,1],
[59,58,14,2],
[59,58,14,3],
[59,58,14,4],
[59,58,14,5],
[59,58,14,6],
[59,58,14,7],
[59,58,14,8],
[59,58,14,10],
[59,58,14,11],
[59,58,14,12],
[59,58,17,0],
[59,58,17,1],
[59,58,17,2],
[59,58,17,3],
[59,58,17,4],
[59,58,17,5],
[59,58,17,6],
[59,58,17,7],
[59,58,17,8],
[59,58,17,12],
[59,58,17,13],
[59,58,17,14],
[59,58,19,0],
[59,58,19,1],
[59,58,19,2],
[59,58,19,3],
[59,58,19,4],
[59,58,19,5],
[59,58,19,8],
[59,58,19,10],
[59,58,19,11],
[59,58,19,12],
[59,58,19,17],
[59,58,20,0],
[59,58,20,1],
[59,58,20,2],
[59,58,20,3],
[59,58,20,4],
[59,58,20,6],
[59,58,20,7],
[59,58,20,8],
[59,58,20,11],
[59,58,20,12],
[59,58,20,13],
[59,58,20,14],
[59,58,20,17],
[59,58,20,19],
[59,58,21,0],
[59,58,21,1],
[59,58,21,2],
[59,58,21,7],
[59,58,21,10],
[59,58,21,11],
[59,58,21,12],
[59,58,21,13],
[59,58,21,14],
[59,58,21,17],
[59,58,21,19],
[59,58,21,20],
[59,58,22,3],
[59,58,22,4],
[59,58,22,5],
[59,58,22,6],
[59,58,22,7],
[59,58,22,8],
[59,58,22,11],
[59,58,22,12],
[59,58,22,13],
[59,58,22,14],
[59,58,22,17],
[59,58,22,19],
[59,58,23,0],
[59,58,23,1],
[59,58,23,2],
[59,58,23,3],
[59,58,23,4],
[59,58,23,5],
[59,58,23,6],
[59,58,23,7],
[59,58,23,8],
[59,58,23,10],
[59,58,23,11],
[59,58,23,12],
[59,58,23,13],
[59,58,23,14],
[59,58,23,17],
[59,58,23,19],
[59,58,25,0],
[59,58,25,1],
[59,58,25,2],
[59,58,25,3],
[59,58,25,4],
[59,58,25,5],
[59,58,25,6],
[59,58,25,8],
[59,58,25,10],
[59,58,25,11],
[59,58,25,12],
[59,58,25,13],
[59,58,25,14],
[59,58,25,17],
[59,58,27,0],
[59,58,27,1],
[59,58,27,2],
[59,58,27,3],
[59,58,27,4],
[59,58,27,5],
[59,58,27,6],
[59,58,27,7],
[59,58,27,8],
[59,58,27,11],
[59,58,27,12],
[59,58,27,13],
[59,58,27,14],
[59,58,28,0],
[59,58,28,1],
[59,58,28,2],
[59,58,28,3],
[59,58,28,4],
[59,58,28,5],
[59,58,28,6],
[59,58,28,8],
[59,58,28,10],
[59,58,28,11],
[59,58,28,12],
[59,58,28,13],
[59,58,29,0],
[59,58,29,1],
[59,58,29,2],
[59,58,29,3],
[59,58,29,4],
[59,58,29,5],
[59,58,29,6],
[59,58,29,7],
[59,58,29,8],
[59,58,29,10],
[59,58,30,0],
[59,58,30,1],
[59,58,30,2],
[59,58,30,3],
[59,58,30,4],
[59,58,30,5],
[59,58,30,6],
[59,58,30,7],
[59,58,30,8],
[59,58,31,0],
[59,58,31,1],
[59,58,31,2],
[59,58,31,3],
[59,58,31,4],
[59,58,31,5],
[59,58,31,6],
[59,58,31,7],
[59,58,32,0],
[59,58,32,1],
[59,58,32,2],
[59,58,32,3],
[59,58,32,4],
[59,58,32,5],
[59,58,33,0],
[59,58,33,1],
[59,58,33,2],
[59,58,33,3],
[59,58,33,4],
[59,58,34,0],
[59,58,34,1],
[59,58,34,2],
[60,4,3,0],
[60,4,3,1],
[60,4,3,2],
[60,5,4,0],
[60,5,4,1],
[60,5,4,2],
[60,6,4,0],
[60,6,4,1],
[60,6,4,2],
[60,7,3,0],
[60,7,3,1],
[60,7,3,2],
[60,7,4,0],
[60,7,4,1],
[60,7,4,2],
[60,7,4,3],
[60,7,5,0],
[60,7,5,1],
[60,7,5,2],
[60,7,5,4],
[60,7,6,0],
[60,7,6,1],
[60,7,6,2],
[60,7,6,4],
[60,8,4,0],
[60,8,4,1],
[60,8,4,2],
[60,8,7,0],
[60,8,7,1],
[60,8,7,2],
[60,8,7,4],
[60,9,3,0],
[60,9,3,1],
[60,9,3,2],
[60,9,4,0],
[60,9,4,1],
[60,9,4,2],
[60,9,4,3],
[60,9,5,0],
[60,9,5,1],
[60,9,5,2],
[60,9,5,4],
[60,9,6,0],
[60,9,6,1],
[60,9,6,2],
[60,9,6,4],
[60,9,7,0],
[60,9,7,1],
[60,9,7,2],
[60,9,7,3],
[60,9,7,4],
[60,9,7,5],
[60,9,7,6],
[60,9,8,0],
[60,9,8,1],
[60,9,8,2],
[60,9,8,4],
[60,9,8,7],
[60,10,3,0],
[60,10,3,1],
[60,10,3,2],
[60,10,4,0],
[60,10,4,1],
[60,10,4,2],
[60,10,4,3],
[60,10,5,0],
[60,10,5,1],
[60,10,5,2],
[60,10,5,4],
[60,10,6,0],
[60,10,6,1],
[60,10,6,2],
[60,10,6,4],
[60,10,7,0],
[60,10,7,1],
[60,10,7,2],
[60,10,7,3],
[60,10,7,4],
[60,10,7,5],
[60,10,7,6],
[60,10,8,0],
[60,10,8,1],
[60,10,8,2],
[60,10,8,4],
[60,10,8,7],
[60,10,9,0],
[60,10,9,1],
[60,10,9,2],
[60,10,9,3],
[60,10,9,4],
[60,10,9,5],
[60,10,9,6],
[60,10,9,7],
[60,10,9,8],
[60,11,3,0],
[60,11,3,1],
[60,11,3,2],
[60,11,4,0],
[60,11,4,1],
[60,11,4,2],
[60,11,4,3],
[60,11,5,0],
[60,11,5,1],
[60,11,5,2],
[60,11,5,4],
[60,11,6,0],
[60,11,6,1],
[60,11,6,2],
[60,11,6,4],
[60,11,7,0],
[60,11,7,1],
[60,11,7,2],
[60,11,7,3],
[60,11,7,4],
[60,11,7,5],
[60,11,7,6],
[60,11,8,0],
[60,11,8,1],
[60,11,8,2],
[60,11,8,4],
[60,11,8,7],
[60,11,9,0],
[60,11,9,1],
[60,11,9,2],
[60,11,9,3],
[60,11,9,4],
[60,11,9,5],
[60,11,9,6],
[60,11,9,7],
[60,11,9,8],
[60,11,10,0],
[60,11,10,1],
[60,11,10,2],
[60,11,10,3],
[60,11,10,4],
[60,11,10,5],
[60,11,10,6],
[60,11,10,7],
[60,11,10,8],
[60,11,10,9],
[60,12,3,0],
[60,12,3,1],
[60,12,3,2],
[60,12,4,0],
[60,12,4,1],
[60,12,4,2],
[60,12,4,3],
[60,12,5,0],
[60,12,5,1],
[60,12,5,2],
[60,12,5,4],
[60,12,6,0],
[60,12,6,1],
[60,12,6,2],
[60,12,6,4],
[60,12,7,0],
[60,12,7,1],
[60,12,7,2],
[60,12,7,3],
[60,12,7,4],
[60,12,7,5],
[60,12,7,6],
[60,12,8,0],
[60,12,8,1],
[60,12,8,2],
[60,12,8,4],
[60,12,8,7],
[60,12,9,0],
[60,12,9,1],
[60,12,9,2],
[60,12,9,3],
[60,12,9,4],
[60,12,9,5],
[60,12,9,6],
[60,12,9,7],
[60,12,9,8],
[60,12,10,0],
[60,12,10,1],
[60,12,10,2],
[60,12,10,3],
[60,12,10,4],
[60,12,10,5],
[60,12,10,6],
[60,12,10,7],
[60,12,10,8],
[60,12,10,9],
[60,15,3,0],
[60,15,3,1],
[60,15,3,2],
[60,15,4,0],
[60,15,4,1],
[60,15,4,2],
[60,15,4,3],
[60,15,5,0],
[60,15,5,1],
[60,15,5,2],
[60,15,5,4],
[60,15,6,0],
[60,15,6,1],
[60,15,6,2],
[60,15,6,4],
[60,15,7,0],
[60,15,7,1],
[60,15,7,2],
[60,15,7,3],
[60,15,7,4],
[60,15,7,5],
[60,15,7,6],
[60,15,8,0],
[60,15,8,1],
[60,15,8,2],
[60,15,8,4],
[60,15,8,7],
[60,15,10,0],
[60,15,10,1],
[60,15,10,2],
[60,15,10,3],
[60,15,10,4],
[60,15,10,5],
[60,15,10,6],
[60,15,10,7],
[60,15,10,8],
[60,15,11,0],
[60,15,11,1],
[60,15,11,2],
[60,15,11,3],
[60,15,11,4],
[60,15,11,5],
[60,15,11,6],
[60,15,11,7],
[60,15,11,8],
[60,15,11,10],
[60,15,12,0],
[60,15,12,1],
[60,15,12,2],
[60,15,12,3],
[60,15,12,4],
[60,15,12,5],
[60,15,12,6],
[60,15,12,7],
[60,15,12,8],
[60,15,12,10],
[60,16,3,0],
[60,16,3,1],
[60,16,3,2],
[60,16,4,0],
[60,16,4,1],
[60,16,4,2],
[60,16,4,3],
[60,16,5,0],
[60,16,5,1],
[60,16,5,2],
[60,16,5,4],
[60,16,6,0],
[60,16,6,1],
[60,16,6,2],
[60,16,6,4],
[60,16,7,0],
[60,16,7,1],
[60,16,7,2],
[60,16,7,3],
[60,16,7,4],
[60,16,7,5],
[60,16,7,6],
[60,16,8,0],
[60,16,8,1],
[60,16,8,2],
[60,16,8,4],
[60,16,8,7],
[60,16,9,0],
[60,16,9,1],
[60,16,9,2],
[60,16,9,3],
[60,16,9,4],
[60,16,9,5],
[60,16,9,6],
[60,16,9,7],
[60,16,9,8],
[60,16,10,0],
[60,16,10,1],
[60,16,10,2],
[60,16,10,3],
[60,16,10,4],
[60,16,10,5],
[60,16,10,6],
[60,16,10,7],
[60,16,10,8],
[60,16,10,9],
[60,16,11,0],
[60,16,11,1],
[60,16,11,2],
[60,16,11,3],
[60,16,11,4],
[60,16,11,5],
[60,16,11,6],
[60,16,11,7],
[60,16,11,8],
[60,16,11,9],
[60,16,11,10],
[60,16,12,0],
[60,16,12,1],
[60,16,12,2],
[60,16,12,3],
[60,16,12,4],
[60,16,12,5],
[60,16,12,6],
[60,16,12,7],
[60,16,12,8],
[60,16,12,9],
[60,16,12,10],
[60,16,15,0],
[60,16,15,1],
[60,16,15,2],
[60,16,15,3],
[60,16,15,4],
[60,16,15,5],
[60,16,15,6],
[60,16,15,7],
[60,16,15,8],
[60,16,15,10],
[60,16,15,11],
[60,16,15,12],
[60,17,3,0],
[60,17,3,1],
[60,17,3,2],
[60,17,4,0],
[60,17,4,1],
[60,17,4,2],
[60,17,4,3],
[60,17,5,0],
[60,17,5,1],
[60,17,5,2],
[60,17,5,4],
[60,17,6,0],
[60,17,6,1],
[60,17,6,2],
[60,17,6,4],
[60,17,7,0],
[60,17,7,1],
[60,17,7,2],
[60,17,7,3],
[60,17,7,4],
[60,17,7,5],
[60,17,7,6],
[60,17,8,0],
[60,17,8,1],
[60,17,8,2],
[60,17,8,4],
[60,17,8,7],
[60,17,9,0],
[60,17,9,1],
[60,17,9,2],
[60,17,9,3],
[60,17,9,4],
[60,17,9,5],
[60,17,9,6],
[60,17,9,7],
[60,17,9,8],
[60,17,10,0],
[60,17,10,1],
[60,17,10,2],
[60,17,10,3],
[60,17,10,4],
[60,17,10,5],
[60,17,10,6],
[60,17,10,7],
[60,17,10,8],
[60,17,10,9],
[60,17,11,0],
[60,17,11,1],
[60,17,11,2],
[60,17,11,3],
[60,17,11,4],
[60,17,11,5],
[60,17,11,6],
[60,17,11,7],
[60,17,11,8],
[60,17,11,9],
[60,17,11,10],
[60,17,12,0],
[60,17,12,1],
[60,17,12,2],
[60,17,12,3],
[60,17,12,4],
[60,17,12,5],
[60,17,12,6],
[60,17,12,7],
[60,17,12,8],
[60,17,12,9],
[60,17,12,10],
[60,17,15,0],
[60,17,15,1],
[60,17,15,2],
[60,17,15,3],
[60,17,15,4],
[60,17,15,5],
[60,17,15,6],
[60,17,15,7],
[60,17,15,8],
[60,17,15,10],
[60,17,15,11],
[60,17,15,12],
[60,17,16,0],
[60,17,16,1],
[60,17,16,2],
[60,17,16,3],
[60,17,16,4],
[60,17,16,5],
[60,17,16,6],
[60,17,16,7],
[60,17,16,8],
[60,17,16,9],
[60,17,16,10],
[60,17,16,11],
[60,17,16,12],
[60,17,16,15],
[60,18,3,0],
[60,18,3,1],
[60,18,3,2],
[60,18,4,0],
[60,18,4,1],
[60,18,4,2],
[60,18,4,3],
[60,18,5,0],
[60,18,5,1],
[60,18,5,2],
[60,18,5,4],
[60,18,6,0],
[60,18,6,1],
[60,18,6,2],
[60,18,6,4],
[60,18,7,0],
[60,18,7,1],
[60,18,7,2],
[60,18,7,3],
[60,18,7,4],
[60,18,7,5],
[60,18,7,6],
[60,18,8,0],
[60,18,8,1],
[60,18,8,2],
[60,18,8,4],
[60,18,8,7],
[60,18,10,0],
[60,18,10,1],
[60,18,10,2],
[60,18,10,3],
[60,18,10,4],
[60,18,10,5],
[60,18,10,6],
[60,18,10,7],
[60,18,10,8],
[60,18,11,0],
[60,18,11,1],
[60,18,11,2],
[60,18,11,3],
[60,18,11,4],
[60,18,11,5],
[60,18,11,6],
[60,18,11,7],
[60,18,11,8],
[60,18,11,10],
[60,18,12,0],
[60,18,12,1],
[60,18,12,2],
[60,18,12,3],
[60,18,12,4],
[60,18,12,5],
[60,18,12,6],
[60,18,12,7],
[60,18,12,8],
[60,18,12,10],
[60,18,16,0],
[60,18,16,1],
[60,18,16,2],
[60,18,16,3],
[60,18,16,4],
[60,18,16,5],
[60,18,16,6],
[60,18,16,7],
[60,18,16,8],
[60,18,16,10],
[60,18,16,11],
[60,18,16,12],
[60,18,17,0],
[60,18,17,1],
[60,18,17,2],
[60,18,17,3],
[60,18,17,4],
[60,18,17,5],
[60,18,17,6],
[60,18,17,7],
[60,18,17,8],
[60,18,17,10],
[60,18,17,11],
[60,18,17,12],
[60,18,17,16],
[60,20,3,0],
[60,20,3,1],
[60,20,3,2],
[60,20,4,0],
[60,20,4,1],
[60,20,4,2],
[60,20,4,3],
[60,20,5,0],
[60,20,5,1],
[60,20,5,2],
[60,20,5,4],
[60,20,6,0],
[60,20,6,1],
[60,20,6,2],
[60,20,6,4],
[60,20,7,0],
[60,20,7,1],
[60,20,7,2],
[60,20,7,3],
[60,20,7,4],
[60,20,7,5],
[60,20,7,6],
[60,20,8,0],
[60,20,8,1],
[60,20,8,2],
[60,20,8,4],
[60,20,8,7],
[60,20,9,0],
[60,20,9,1],
[60,20,9,2],
[60,20,9,3],
[60,20,9,4],
[60,20,9,5],
[60,20,9,6],
[60,20,9,7],
[60,20,9,8],
[60,20,11,0],
[60,20,11,1],
[60,20,11,2],
[60,20,11,3],
[60,20,11,4],
[60,20,11,5],
[60,20,11,6],
[60,20,11,7],
[60,20,11,8],
[60,20,11,9],
[60,20,12,0],
[60,20,12,1],
[60,20,12,2],
[60,20,12,3],
[60,20,12,4],
[60,20,12,5],
[60,20,12,6],
[60,20,12,7],
[60,20,12,8],
[60,20,12,9],
[60,20,15,0],
[60,20,15,1],
[60,20,15,2],
[60,20,15,3],
[60,20,15,4],
[60,20,15,5],
[60,20,15,6],
[60,20,15,7],
[60,20,15,8],
[60,20,15,11],
[60,20,15,12],
[60,20,16,0],
[60,20,16,1],
[60,20,16,2],
[60,20,16,3],
[60,20,16,4],
[60,20,16,5],
[60,20,16,6],
[60,20,16,7],
[60,20,16,8],
[60,20,16,9],
[60,20,16,11],
[60,20,16,12],
[60,20,16,15],
[60,20,17,0],
[60,20,17,1],
[60,20,17,2],
[60,20,17,3],
[60,20,17,4],
[60,20,17,5],
[60,20,17,6],
[60,20,17,7],
[60,20,17,8],
[60,20,17,9],
[60,20,17,11],
[60,20,17,12],
[60,20,17,15],
[60,20,17,16],
[60,20,18,0],
[60,20,18,1],
[60,20,18,2],
[60,20,18,3],
[60,20,18,4],
[60,20,18,5],
[60,20,18,6],
[60,20,18,7],
[60,20,18,8],
[60,20,18,11],
[60,20,18,12],
[60,20,18,16],
[60,20,18,17],
[60,21,4,0],
[60,21,4,1],
[60,21,4,2],
[60,21,7,0],
[60,21,7,1],
[60,21,7,2],
[60,21,7,4],
[60,21,9,0],
[60,21,9,1],
[60,21,9,2],
[60,21,9,4],
[60,21,9,7],
[60,21,10,0],
[60,21,10,1],
[60,21,10,2],
[60,21,10,4],
[60,21,10,7],
[60,21,10,9],
[60,21,11,0],
[60,21,11,1],
[60,21,11,2],
[60,21,11,4],
[60,21,11,7],
[60,21,11,9],
[60,21,11,10],
[60,21,12,0],
[60,21,12,1],
[60,21,12,2],
[60,21,12,4],
[60,21,12,7],
[60,21,12,9],
[60,21,12,10],
[60,21,15,0],
[60,21,15,1],
[60,21,15,2],
[60,21,15,4],
[60,21,15,7],
[60,21,15,10],
[60,21,15,11],
[60,21,15,12],
[60,21,16,0],
[60,21,16,1],
[60,21,16,2],
[60,21,16,4],
[60,21,16,7],
[60,21,16,9],
[60,21,16,10],
[60,21,16,11],
[60,21,16,12],
[60,21,16,15],
[60,21,17,0],
[60,21,17,1],
[60,21,17,2],
[60,21,17,4],
[60,21,17,7],
[60,21,17,9],
[60,21,17,10],
[60,21,17,11],
[60,21,17,12],
[60,21,17,15],
[60,21,17,16],
[60,21,18,0],
[60,21,18,1],
[60,21,18,2],
[60,21,18,4],
[60,21,18,7],
[60,21,18,10],
[60,21,18,11],
[60,21,18,12],
[60,21,18,16],
[60,21,18,17],
[60,21,20,0],
[60,21,20,1],
[60,21,20,2],
[60,21,20,4],
[60,21,20,7],
[60,21,20,9],
[60,21,20,11],
[60,21,20,12],
[60,21,20,15],
[60,21,20,16],
[60,21,20,17],
[60,21,20,18],
[60,22,3,0],
[60,22,3,1],
[60,22,3,2],
[60,22,4,0],
[60,22,4,1],
[60,22,4,2],
[60,22,4,3],
[60,22,5,0],
[60,22,5,1],
[60,22,5,2],
[60,22,5,4],
[60,22,6,0],
[60,22,6,1],
[60,22,6,2],
[60,22,6,4],
[60,22,7,0],
[60,22,7,1],
[60,22,7,2],
[60,22,7,3],
[60,22,7,4],
[60,22,7,5],
[60,22,7,6],
[60,22,8,0],
[60,22,8,1],
[60,22,8,2],
[60,22,8,4],
[60,22,8,7],
[60,22,9,0],
[60,22,9,1],
[60,22,9,2],
[60,22,9,3],
[60,22,9,4],
[60,22,9,5],
[60,22,9,6],
[60,22,9,7],
[60,22,9,8],
[60,22,11,0],
[60,22,11,1],
[60,22,11,2],
[60,22,11,3],
[60,22,11,4],
[60,22,11,5],
[60,22,11,6],
[60,22,11,7],
[60,22,11,8],
[60,22,11,9],
[60,22,12,0],
[60,22,12,1],
[60,22,12,2],
[60,22,12,3],
[60,22,12,4],
[60,22,12,5],
[60,22,12,6],
[60,22,12,7],
[60,22,12,8],
[60,22,12,9],
[60,22,15,0],
[60,22,15,1],
[60,22,15,2],
[60,22,15,3],
[60,22,15,4],
[60,22,15,5],
[60,22,15,6],
[60,22,15,7],
[60,22,15,8],
[60,22,15,11],
[60,22,15,12],
[60,22,16,0],
[60,22,16,1],
[60,22,16,2],
[60,22,16,3],
[60,22,16,4],
[60,22,16,5],
[60,22,16,6],
[60,22,16,7],
[60,22,16,8],
[60,22,16,9],
[60,22,16,11],
[60,22,16,12],
[60,22,16,15],
[60,22,17,0],
[60,22,17,1],
[60,22,17,2],
[60,22,17,3],
[60,22,17,4],
[60,22,17,5],
[60,22,17,6],
[60,22,17,7],
[60,22,17,8],
[60,22,17,9],
[60,22,17,11],
[60,22,17,12],
[60,22,17,15],
[60,22,17,16],
[60,22,18,0],
[60,22,18,1],
[60,22,18,2],
[60,22,18,3],
[60,22,18,4],
[60,22,18,5],
[60,22,18,6],
[60,22,18,7],
[60,22,18,8],
[60,22,18,11],
[60,22,18,12],
[60,22,18,16],
[60,22,18,17],
[60,22,21,0],
[60,22,21,1],
[60,22,21,2],
[60,22,21,4],
[60,22,21,7],
[60,22,21,9],
[60,22,21,11],
[60,22,21,12],
[60,22,21,15],
[60,22,21,16],
[60,22,21,17],
[60,22,21,18],
[60,23,3,0],
[60,23,3,1],
[60,23,3,2],
[60,23,4,0],
[60,23,4,1],
[60,23,4,2],
[60,23,4,3],
[60,23,5,0],
[60,23,5,1],
[60,23,5,2],
[60,23,5,4],
[60,23,6,0],
[60,23,6,1],
[60,23,6,2],
[60,23,6,4],
[60,23,7,0],
[60,23,7,1],
[60,23,7,2],
[60,23,7,3],
[60,23,7,4],
[60,23,7,5],
[60,23,7,6],
[60,23,8,0],
[60,23,8,1],
[60,23,8,2],
[60,23,8,4],
[60,23,8,7],
[60,23,9,0],
[60,23,9,1],
[60,23,9,2],
[60,23,9,3],
[60,23,9,4],
[60,23,9,5],
[60,23,9,6],
[60,23,9,7],
[60,23,9,8],
[60,23,10,0],
[60,23,10,1],
[60,23,10,2],
[60,23,10,3],
[60,23,10,4],
[60,23,10,5],
[60,23,10,6],
[60,23,10,7],
[60,23,10,8],
[60,23,10,9],
[60,23,11,0],
[60,23,11,1],
[60,23,11,2],
[60,23,11,3],
[60,23,11,4],
[60,23,11,5],
[60,23,11,6],
[60,23,11,7],
[60,23,11,8],
[60,23,11,9],
[60,23,11,10],
[60,23,12,0],
[60,23,12,1],
[60,23,12,2],
[60,23,12,3],
[60,23,12,4],
[60,23,12,5],
[60,23,12,6],
[60,23,12,7],
[60,23,12,8],
[60,23,12,9],
[60,23,12,10],
[60,23,15,0],
[60,23,15,1],
[60,23,15,2],
[60,23,15,3],
[60,23,15,4],
[60,23,15,5],
[60,23,15,6],
[60,23,15,7],
[60,23,15,8],
[60,23,15,10],
[60,23,15,11],
[60,23,15,12],
[60,23,16,0],
[60,23,16,1],
[60,23,16,2],
[60,23,16,3],
[60,23,16,4],
[60,23,16,5],
[60,23,16,6],
[60,23,16,7],
[60,23,16,8],
[60,23,16,9],
[60,23,16,10],
[60,23,16,11],
[60,23,16,12],
[60,23,16,15],
[60,23,17,0],
[60,23,17,1],
[60,23,17,2],
[60,23,17,3],
[60,23,17,4],
[60,23,17,5],
[60,23,17,6],
[60,23,17,7],
[60,23,17,8],
[60,23,17,9],
[60,23,17,10],
[60,23,17,11],
[60,23,17,12],
[60,23,17,15],
[60,23,17,16],
[60,23,18,0],
[60,23,18,1],
[60,23,18,2],
[60,23,18,3],
[60,23,18,4],
[60,23,18,5],
[60,23,18,6],
[60,23,18,7],
[60,23,18,8],
[60,23,18,10],
[60,23,18,11],
[60,23,18,12],
[60,23,18,16],
[60,23,18,17],
[60,23,20,0],
[60,23,20,1],
[60,23,20,2],
[60,23,20,3],
[60,23,20,4],
[60,23,20,5],
[60,23,20,6],
[60,23,20,7],
[60,23,20,8],
[60,23,20,9],
[60,23,20,11],
[60,23,20,12],
[60,23,20,15],
[60,23,20,16],
[60,23,20,17],
[60,23,20,18],
[60,23,21,0],
[60,23,21,1],
[60,23,21,2],
[60,23,21,4],
[60,23,21,7],
[60,23,21,9],
[60,23,21,10],
[60,23,21,11],
[60,23,21,12],
[60,23,21,15],
[60,23,21,16],
[60,23,21,17],
[60,23,21,18],
[60,23,21,20],
[60,23,22,0],
[60,23,22,1],
[60,23,22,2],
[60,23,22,3],
[60,23,22,4],
[60,23,22,5],
[60,23,22,6],
[60,23,22,7],
[60,23,22,8],
[60,23,22,9],
[60,23,22,11],
[60,23,22,12],
[60,23,22,15],
[60,23,22,16],
[60,23,22,17],
[60,23,22,18],
[60,23,22,21],
[60,24,3,0],
[60,24,3,1],
[60,24,3,2],
[60,24,4,0],
[60,24,4,1],
[60,24,4,2],
[60,24,4,3],
[60,24,5,0],
[60,24,5,1],
[60,24,5,2],
[60,24,5,4],
[60,24,6,0],
[60,24,6,1],
[60,24,6,2],
[60,24,6,4],
[60,24,7,0],
[60,24,7,1],
[60,24,7,2],
[60,24,7,3],
[60,24,7,4],
[60,24,7,5],
[60,24,7,6],
[60,24,8,0],
[60,24,8,1],
[60,24,8,2],
[60,24,8,4],
[60,24,8,7],
[60,24,9,0],
[60,24,9,1],
[60,24,9,2],
[60,24,9,3],
[60,24,9,4],
[60,24,9,5],
[60,24,9,6],
[60,24,9,7],
[60,24,9,8],
[60,24,10,0],
[60,24,10,1],
[60,24,10,2],
[60,24,10,3],
[60,24,10,4],
[60,24,10,5],
[60,24,10,6],
[60,24,10,7],
[60,24,10,8],
[60,24,10,9],
[60,24,11,0],
[60,24,11,1],
[60,24,11,2],
[60,24,11,3],
[60,24,11,4],
[60,24,11,5],
[60,24,11,6],
[60,24,11,7],
[60,24,11,8],
[60,24,11,9],
[60,24,11,10],
[60,24,12,0],
[60,24,12,1],
[60,24,12,2],
[60,24,12,3],
[60,24,12,4],
[60,24,12,5],
[60,24,12,6],
[60,24,12,7],
[60,24,12,8],
[60,24,12,9],
[60,24,12,10],
[60,24,15,0],
[60,24,15,1],
[60,24,15,2],
[60,24,15,3],
[60,24,15,4],
[60,24,15,5],
[60,24,15,6],
[60,24,15,7],
[60,24,15,8],
[60,24,15,10],
[60,24,15,11],
[60,24,15,12],
[60,24,17,0],
[60,24,17,1],
[60,24,17,2],
[60,24,17,3],
[60,24,17,4],
[60,24,17,5],
[60,24,17,6],
[60,24,17,7],
[60,24,17,8],
[60,24,17,9],
[60,24,17,10],
[60,24,17,11],
[60,24,17,12],
[60,24,17,15],
[60,24,18,0],
[60,24,18,1],
[60,24,18,2],
[60,24,18,3],
[60,24,18,4],
[60,24,18,5],
[60,24,18,6],
[60,24,18,7],
[60,24,18,8],
[60,24,18,10],
[60,24,18,11],
[60,24,18,12],
[60,24,18,17],
[60,24,20,0],
[60,24,20,1],
[60,24,20,2],
[60,24,20,3],
[60,24,20,4],
[60,24,20,5],
[60,24,20,6],
[60,24,20,7],
[60,24,20,8],
[60,24,20,9],
[60,24,20,11],
[60,24,20,12],
[60,24,20,15],
[60,24,20,17],
[60,24,20,18],
[60,24,21,0],
[60,24,21,1],
[60,24,21,2],
[60,24,21,4],
[60,24,21,7],
[60,24,21,9],
[60,24,21,10],
[60,24,21,11],
[60,24,21,12],
[60,24,21,15],
[60,24,21,17],
[60,24,21,18],
[60,24,21,20],
[60,24,22,0],
[60,24,22,1],
[60,24,22,2],
[60,24,22,3],
[60,24,22,4],
[60,24,22,5],
[60,24,22,6],
[60,24,22,7],
[60,24,22,8],
[60,24,22,9],
[60,24,22,11],
[60,24,22,12],
[60,24,22,15],
[60,24,22,17],
[60,24,22,18],
[60,24,22,21],
[60,24,23,0],
[60,24,23,1],
[60,24,23,2],
[60,24,23,3],
[60,24,23,4],
[60,24,23,5],
[60,24,23,6],
[60,24,23,7],
[60,24,23,8],
[60,24,23,9],
[60,24,23,10],
[60,24,23,11],
[60,24,23,12],
[60,24,23,15],
[60,24,23,17],
[60,24,23,18],
[60,24,23,20],
[60,24,23,21],
[60,24,23,22],
[60,25,3,0],
[60,25,3,1],
[60,25,3,2],
[60,25,4,0],
[60,25,4,1],
[60,25,4,2],
[60,25,4,3],
[60,25,5,0],
[60,25,5,1],
[60,25,5,2],
[60,25,5,4],
[60,25,6,0],
[60,25,6,1],
[60,25,6,2],
[60,25,6,4],
[60,25,8,0],
[60,25,8,1],
[60,25,8,2],
[60,25,8,4],
[60,25,9,0],
[60,25,9,1],
[60,25,9,2],
[60,25,9,3],
[60,25,9,4],
[60,25,9,5],
[60,25,9,6],
[60,25,9,8],
[60,25,10,0],
[60,25,10,1],
[60,25,10,2],
[60,25,10,3],
[60,25,10,4],
[60,25,10,5],
[60,25,10,6],
[60,25,10,8],
[60,25,10,9],
[60,25,11,0],
[60,25,11,1],
[60,25,11,2],
[60,25,11,3],
[60,25,11,4],
[60,25,11,5],
[60,25,11,6],
[60,25,11,8],
[60,25,11,9],
[60,25,11,10],
[60,25,12,0],
[60,25,12,1],
[60,25,12,2],
[60,25,12,3],
[60,25,12,4],
[60,25,12,5],
[60,25,12,6],
[60,25,12,8],
[60,25,12,9],
[60,25,12,10],
[60,25,15,0],
[60,25,15,1],
[60,25,15,2],
[60,25,15,3],
[60,25,15,4],
[60,25,15,5],
[60,25,15,6],
[60,25,15,8],
[60,25,15,10],
[60,25,15,11],
[60,25,15,12],
[60,25,16,0],
[60,25,16,1],
[60,25,16,2],
[60,25,16,3],
[60,25,16,4],
[60,25,16,5],
[60,25,16,6],
[60,25,16,8],
[60,25,16,9],
[60,25,16,10],
[60,25,16,11],
[60,25,16,12],
[60,25,16,15],
[60,25,17,0],
[60,25,17,1],
[60,25,17,2],
[60,25,17,3],
[60,25,17,4],
[60,25,17,5],
[60,25,17,6],
[60,25,17,8],
[60,25,17,9],
[60,25,17,10],
[60,25,17,11],
[60,25,17,12],
[60,25,17,15],
[60,25,17,16],
[60,25,18,0],
[60,25,18,1],
[60,25,18,2],
[60,25,18,3],
[60,25,18,4],
[60,25,18,5],
[60,25,18,6],
[60,25,18,8],
[60,25,18,10],
[60,25,18,11],
[60,25,18,12],
[60,25,18,16],
[60,25,18,17],
[60,25,20,0],
[60,25,20,1],
[60,25,20,2],
[60,25,20,3],
[60,25,20,4],
[60,25,20,5],
[60,25,20,6],
[60,25,20,8],
[60,25,20,9],
[60,25,20,11],
[60,25,20,12],
[60,25,20,15],
[60,25,20,16],
[60,25,20,17],
[60,25,20,18],
[60,25,21,0],
[60,25,21,1],
[60,25,21,2],
[60,25,21,4],
[60,25,21,9],
[60,25,21,10],
[60,25,21,11],
[60,25,21,12],
[60,25,21,15],
[60,25,21,16],
[60,25,21,17],
[60,25,21,18],
[60,25,21,20],
[60,25,22,0],
[60,25,22,1],
[60,25,22,2],
[60,25,22,3],
[60,25,22,4],
[60,25,22,5],
[60,25,22,6],
[60,25,22,8],
[60,25,22,9],
[60,25,22,11],
[60,25,22,12],
[60,25,22,15],
[60,25,22,16],
[60,25,22,17],
[60,25,22,18],
[60,25,22,21],
[60,25,23,0],
[60,25,23,1],
[60,25,23,2],
[60,25,23,3],
[60,25,23,4],
[60,25,23,5],
[60,25,23,6],
[60,25,23,8],
[60,25,23,9],
[60,25,23,10],
[60,25,23,11],
[60,25,23,12],
[60,25,23,15],
[60,25,23,16],
[60,25,23,17],
[60,25,23,18],
[60,25,23,20],
[60,25,23,21],
[60,25,23,22],
[60,25,24,0],
[60,25,24,1],
[60,25,24,2],
[60,25,24,3],
[60,25,24,4],
[60,25,24,5],
[60,25,24,6],
[60,25,24,8],
[60,25,24,9],
[60,25,24,10],
[60,25,24,11],
[60,25,24,12],
[60,25,24,15],
[60,25,24,17],
[60,25,24,18],
[60,25,24,20],
[60,25,24,21],
[60,25,24,22],
[60,25,24,23],
[60,26,3,0],
[60,26,3,1],
[60,26,3,2],
[60,26,4,0],
[60,26,4,1],
[60,26,4,2],
[60,26,4,3],
[60,26,5,0],
[60,26,5,1],
[60,26,5,2],
[60,26,5,4],
[60,26,6,0],
[60,26,6,1],
[60,26,6,2],
[60,26,6,4],
[60,26,7,0],
[60,26,7,1],
[60,26,7,2],
[60,26,7,3],
[60,26,7,4],
[60,26,7,5],
[60,26,7,6],
[60,26,8,0],
[60,26,8,1],
[60,26,8,2],
[60,26,8,4],
[60,26,8,7],
[60,26,10,0],
[60,26,10,1],
[60,26,10,2],
[60,26,10,3],
[60,26,10,4],
[60,26,10,5],
[60,26,10,6],
[60,26,10,7],
[60,26,10,8],
[60,26,11,0],
[60,26,11,1],
[60,26,11,2],
[60,26,11,3],
[60,26,11,4],
[60,26,11,5],
[60,26,11,6],
[60,26,11,7],
[60,26,11,8],
[60,26,11,10],
[60,26,12,0],
[60,26,12,1],
[60,26,12,2],
[60,26,12,3],
[60,26,12,4],
[60,26,12,5],
[60,26,12,6],
[60,26,12,7],
[60,26,12,8],
[60,26,12,10],
[60,26,16,0],
[60,26,16,1],
[60,26,16,2],
[60,26,16,3],
[60,26,16,4],
[60,26,16,5],
[60,26,16,6],
[60,26,16,7],
[60,26,16,8],
[60,26,16,10],
[60,26,16,11],
[60,26,16,12],
[60,26,17,0],
[60,26,17,1],
[60,26,17,2],
[60,26,17,3],
[60,26,17,4],
[60,26,17,5],
[60,26,17,6],
[60,26,17,7],
[60,26,17,8],
[60,26,17,10],
[60,26,17,11],
[60,26,17,12],
[60,26,17,16],
[60,26,20,0],
[60,26,20,1],
[60,26,20,2],
[60,26,20,3],
[60,26,20,4],
[60,26,20,5],
[60,26,20,6],
[60,26,20,7],
[60,26,20,8],
[60,26,20,11],
[60,26,20,12],
[60,26,20,16],
[60,26,20,17],
[60,26,21,0],
[60,26,21,1],
[60,26,21,2],
[60,26,21,4],
[60,26,21,7],
[60,26,21,10],
[60,26,21,11],
[60,26,21,12],
[60,26,21,16],
[60,26,21,17],
[60,26,21,20],
[60,26,22,0],
[60,26,22,1],
[60,26,22,2],
[60,26,22,3],
[60,26,22,4],
[60,26,22,5],
[60,26,22,6],
[60,26,22,7],
[60,26,22,8],
[60,26,22,11],
[60,26,22,12],
[60,26,22,16],
[60,26,22,17],
[60,26,22,21],
[60,26,23,0],
[60,26,23,1],
[60,26,23,2],
[60,26,23,3],
[60,26,23,4],
[60,26,23,5],
[60,26,23,6],
[60,26,23,7],
[60,26,23,8],
[60,26,23,10],
[60,26,23,11],
[60,26,23,12],
[60,26,23,16],
[60,26,23,17],
[60,26,23,20],
[60,26,23,21],
[60,26,23,22],
[60,26,24,0],
[60,26,24,1],
[60,26,24,2],
[60,26,24,3],
[60,26,24,4],
[60,26,24,5],
[60,26,24,6],
[60,26,24,7],
[60,26,24,8],
[60,26,24,10],
[60,26,24,11],
[60,26,24,12],
[60,26,24,17],
[60,26,24,20],
[60,26,24,21],
[60,26,24,22],
[60,26,24,23],
[60,26,25,0],
[60,26,25,1],
[60,26,25,2],
[60,26,25,3],
[60,26,25,4],
[60,26,25,5],
[60,26,25,6],
[60,26,25,8],
[60,26,25,10],
[60,26,25,11],
[60,26,25,12],
[60,26,25,16],
[60,26,25,17],
[60,26,25,20],
[60,26,25,21],
[60,26,25,22],
[60,26,25,23],
[60,26,25,24],
[60,27,3,0],
[60,27,3,1],
[60,27,3,2],
[60,27,4,0],
[60,27,4,1],
[60,27,4,2],
[60,27,4,3],
[60,27,5,0],
[60,27,5,1],
[60,27,5,2],
[60,27,5,4],
[60,27,6,0],
[60,27,6,1],
[60,27,6,2],
[60,27,6,4],
[60,27,7,0],
[60,27,7,1],
[60,27,7,2],
[60,27,7,3],
[60,27,7,4],
[60,27,7,5],
[60,27,7,6],
[60,27,8,0],
[60,27,8,1],
[60,27,8,2],
[60,27,8,4],
[60,27,8,7],
[60,27,9,0],
[60,27,9,1],
[60,27,9,2],
[60,27,9,3],
[60,27,9,4],
[60,27,9,5],
[60,27,9,6],
[60,27,9,7],
[60,27,9,8],
[60,27,11,0],
[60,27,11,1],
[60,27,11,2],
[60,27,11,3],
[60,27,11,4],
[60,27,11,5],
[60,27,11,6],
[60,27,11,7],
[60,27,11,8],
[60,27,11,9],
[60,27,12,0],
[60,27,12,1],
[60,27,12,2],
[60,27,12,3],
[60,27,12,4],
[60,27,12,5],
[60,27,12,6],
[60,27,12,7],
[60,27,12,8],
[60,27,12,9],
[60,27,15,0],
[60,27,15,1],
[60,27,15,2],
[60,27,15,3],
[60,27,15,4],
[60,27,15,5],
[60,27,15,6],
[60,27,15,7],
[60,27,15,8],
[60,27,15,11],
[60,27,15,12],
[60,27,16,0],
[60,27,16,1],
[60,27,16,2],
[60,27,16,3],
[60,27,16,4],
[60,27,16,5],
[60,27,16,6],
[60,27,16,7],
[60,27,16,8],
[60,27,16,9],
[60,27,16,11],
[60,27,16,12],
[60,27,16,15],
[60,27,17,0],
[60,27,17,1],
[60,27,17,2],
[60,27,17,3],
[60,27,17,4],
[60,27,17,5],
[60,27,17,6],
[60,27,17,7],
[60,27,17,8],
[60,27,17,9],
[60,27,17,11],
[60,27,17,12],
[60,27,17,15],
[60,27,17,16],
[60,27,18,0],
[60,27,18,1],
[60,27,18,2],
[60,27,18,3],
[60,27,18,4],
[60,27,18,5],
[60,27,18,6],
[60,27,18,7],
[60,27,18,8],
[60,27,18,11],
[60,27,18,12],
[60,27,18,16],
[60,27,18,17],
[60,27,21,0],
[60,27,21,1],
[60,27,21,2],
[60,27,21,4],
[60,27,21,7],
[60,27,21,9],
[60,27,21,11],
[60,27,21,12],
[60,27,21,15],
[60,27,21,16],
[60,27,21,17],
[60,27,21,18],
[60,27,23,0],
[60,27,23,1],
[60,27,23,2],
[60,27,23,3],
[60,27,23,4],
[60,27,23,5],
[60,27,23,6],
[60,27,23,7],
[60,27,23,8],
[60,27,23,9],
[60,27,23,11],
[60,27,23,12],
[60,27,23,15],
[60,27,23,16],
[60,27,23,17],
[60,27,23,18],
[60,27,23,21],
[60,27,24,0],
[60,27,24,1],
[60,27,24,2],
[60,27,24,3],
[60,27,24,4],
[60,27,24,5],
[60,27,24,6],
[60,27,24,7],
[60,27,24,8],
[60,27,24,9],
[60,27,24,11],
[60,27,24,12],
[60,27,24,15],
[60,27,24,17],
[60,27,24,18],
[60,27,24,21],
[60,27,24,23],
[60,27,25,0],
[60,27,25,1],
[60,27,25,2],
[60,27,25,3],
[60,27,25,4],
[60,27,25,5],
[60,27,25,6],
[60,27,25,8],
[60,27,25,9],
[60,27,25,11],
[60,27,25,12],
[60,27,25,15],
[60,27,25,16],
[60,27,25,17],
[60,27,25,18],
[60,27,25,21],
[60,27,25,23],
[60,27,25,24],
[60,27,26,0],
[60,27,26,1],
[60,27,26,2],
[60,27,26,3],
[60,27,26,4],
[60,27,26,5],
[60,27,26,6],
[60,27,26,7],
[60,27,26,8],
[60,27,26,11],
[60,27,26,12],
[60,27,26,16],
[60,27,26,17],
[60,27,26,21],
[60,27,26,23],
[60,27,26,24],
[60,27,26,25],
[60,28,3,0],
[60,28,3,1],
[60,28,3,2],
[60,28,4,0],
[60,28,4,1],
[60,28,4,2],
[60,28,4,3],
[60,28,5,0],
[60,28,5,1],
[60,28,5,2],
[60,28,5,4],
[60,28,6,0],
[60,28,6,1],
[60,28,6,2],
[60,28,6,4],
[60,28,8,0],
[60,28,8,1],
[60,28,8,2],
[60,28,8,4],
[60,28,9,0],
[60,28,9,1],
[60,28,9,2],
[60,28,9,3],
[60,28,9,4],
[60,28,9,5],
[60,28,9,6],
[60,28,9,8],
[60,28,10,0],
[60,28,10,1],
[60,28,10,2],
[60,28,10,3],
[60,28,10,4],
[60,28,10,5],
[60,28,10,6],
[60,28,10,8],
[60,28,10,9],
[60,28,11,0],
[60,28,11,1],
[60,28,11,2],
[60,28,11,3],
[60,28,11,4],
[60,28,11,5],
[60,28,11,6],
[60,28,11,8],
[60,28,11,9],
[60,28,11,10],
[60,28,12,0],
[60,28,12,1],
[60,28,12,2],
[60,28,12,3],
[60,28,12,4],
[60,28,12,5],
[60,28,12,6],
[60,28,12,8],
[60,28,12,9],
[60,28,12,10],
[60,28,15,0],
[60,28,15,1],
[60,28,15,2],
[60,28,15,3],
[60,28,15,4],
[60,28,15,5],
[60,28,15,6],
[60,28,15,8],
[60,28,15,10],
[60,28,15,11],
[60,28,15,12],
[60,28,16,0],
[60,28,16,1],
[60,28,16,2],
[60,28,16,3],
[60,28,16,4],
[60,28,16,5],
[60,28,16,6],
[60,28,16,8],
[60,28,16,9],
[60,28,16,10],
[60,28,16,11],
[60,28,16,12],
[60,28,16,15],
[60,28,17,0],
[60,28,17,1],
[60,28,17,2],
[60,28,17,3],
[60,28,17,4],
[60,28,17,5],
[60,28,17,6],
[60,28,17,8],
[60,28,17,9],
[60,28,17,10],
[60,28,17,11],
[60,28,17,12],
[60,28,17,15],
[60,28,17,16],
[60,28,18,0],
[60,28,18,1],
[60,28,18,2],
[60,28,18,3],
[60,28,18,4],
[60,28,18,5],
[60,28,18,6],
[60,28,18,8],
[60,28,18,10],
[60,28,18,11],
[60,28,18,12],
[60,28,18,16],
[60,28,18,17],
[60,28,20,0],
[60,28,20,1],
[60,28,20,2],
[60,28,20,3],
[60,28,20,4],
[60,28,20,5],
[60,28,20,6],
[60,28,20,8],
[60,28,20,9],
[60,28,20,11],
[60,28,20,12],
[60,28,20,15],
[60,28,20,16],
[60,28,20,17],
[60,28,20,18],
[60,28,21,0],
[60,28,21,1],
[60,28,21,2],
[60,28,21,4],
[60,28,21,9],
[60,28,21,10],
[60,28,21,11],
[60,28,21,12],
[60,28,21,15],
[60,28,21,16],
[60,28,21,17],
[60,28,21,18],
[60,28,21,20],
[60,28,22,0],
[60,28,22,1],
[60,28,22,2],
[60,28,22,3],
[60,28,22,4],
[60,28,22,5],
[60,28,22,6],
[60,28,22,8],
[60,28,22,9],
[60,28,22,11],
[60,28,22,12],
[60,28,22,15],
[60,28,22,16],
[60,28,22,17],
[60,28,22,18],
[60,28,22,21],
[60,28,23,0],
[60,28,23,1],
[60,28,23,2],
[60,28,23,3],
[60,28,23,4],
[60,28,23,5],
[60,28,23,6],
[60,28,23,8],
[60,28,23,9],
[60,28,23,10],
[60,28,23,11],
[60,28,23,12],
[60,28,23,15],
[60,28,23,16],
[60,28,23,17],
[60,28,23,18],
[60,28,23,20],
[60,28,23,21],
[60,28,23,22],
[60,28,24,0],
[60,28,24,1],
[60,28,24,2],
[60,28,24,3],
[60,28,24,4],
[60,28,24,5],
[60,28,24,6],
[60,28,24,8],
[60,28,24,9],
[60,28,24,10],
[60,28,24,11],
[60,28,24,12],
[60,28,24,15],
[60,28,24,17],
[60,28,24,18],
[60,28,24,20],
[60,28,24,21],
[60,28,24,22],
[60,28,24,23],
[60,28,26,0],
[60,28,26,1],
[60,28,26,2],
[60,28,26,3],
[60,28,26,4],
[60,28,26,5],
[60,28,26,6],
[60,28,26,8],
[60,28,26,10],
[60,28,26,11],
[60,28,26,12],
[60,28,26,16],
[60,28,26,17],
[60,28,26,20],
[60,28,26,21],
[60,28,26,22],
[60,28,27,0],
[60,28,27,1],
[60,28,27,2],
[60,28,27,3],
[60,28,27,4],
[60,28,27,5],
[60,28,27,6],
[60,28,27,8],
[60,28,27,9],
[60,28,27,11],
[60,28,27,12],
[60,28,27,15],
[60,28,27,16],
[60,28,27,17],
[60,28,27,18],
[60,28,27,21],
[60,28,27,23],
[60,28,27,24],
[60,28,27,26],
[60,29,3,0],
[60,29,3,1],
[60,29,3,2],
[60,29,4,0],
[60,29,4,1],
[60,29,4,2],
[60,29,4,3],
[60,29,5,0],
[60,29,5,1],
[60,29,5,2],
[60,29,5,4],
[60,29,6,0],
[60,29,6,1],
[60,29,6,2],
[60,29,6,4],
[60,29,7,0],
[60,29,7,1],
[60,29,7,2],
[60,29,7,3],
[60,29,7,4],
[60,29,7,5],
[60,29,7,6],
[60,29,8,0],
[60,29,8,1],
[60,29,8,2],
[60,29,8,4],
[60,29,8,7],
[60,29,9,0],
[60,29,9,1],
[60,29,9,2],
[60,29,9,3],
[60,29,9,4],
[60,29,9,5],
[60,29,9,6],
[60,29,9,7],
[60,29,9,8],
[60,29,10,0],
[60,29,10,1],
[60,29,10,2],
[60,29,10,3],
[60,29,10,4],
[60,29,10,5],
[60,29,10,6],
[60,29,10,7],
[60,29,10,8],
[60,29,10,9],
[60,29,15,0],
[60,29,15,1],
[60,29,15,2],
[60,29,15,3],
[60,29,15,4],
[60,29,15,5],
[60,29,15,6],
[60,29,15,7],
[60,29,15,8],
[60,29,15,10],
[60,29,16,0],
[60,29,16,1],
[60,29,16,2],
[60,29,16,3],
[60,29,16,4],
[60,29,16,5],
[60,29,16,6],
[60,29,16,7],
[60,29,16,8],
[60,29,16,9],
[60,29,16,10],
[60,29,16,15],
[60,29,17,0],
[60,29,17,1],
[60,29,17,2],
[60,29,17,3],
[60,29,17,4],
[60,29,17,5],
[60,29,17,6],
[60,29,17,7],
[60,29,17,8],
[60,29,17,9],
[60,29,17,10],
[60,29,17,15],
[60,29,17,16],
[60,29,18,0],
[60,29,18,1],
[60,29,18,2],
[60,29,18,3],
[60,29,18,4],
[60,29,18,5],
[60,29,18,6],
[60,29,18,7],
[60,29,18,8],
[60,29,18,10],
[60,29,18,16],
[60,29,18,17],
[60,29,20,0],
[60,29,20,1],
[60,29,20,2],
[60,29,20,3],
[60,29,20,4],
[60,29,20,5],
[60,29,20,6],
[60,29,20,7],
[60,29,20,8],
[60,29,20,9],
[60,29,20,15],
[60,29,20,16],
[60,29,20,17],
[60,29,20,18],
[60,29,21,0],
[60,29,21,1],
[60,29,21,2],
[60,29,21,4],
[60,29,21,7],
[60,29,21,9],
[60,29,21,10],
[60,29,21,15],
[60,29,21,16],
[60,29,21,17],
[60,29,21,18],
[60,29,21,20],
[60,29,22,0],
[60,29,22,1],
[60,29,22,2],
[60,29,22,3],
[60,29,22,4],
[60,29,22,5],
[60,29,22,6],
[60,29,22,7],
[60,29,22,8],
[60,29,22,9],
[60,29,22,15],
[60,29,22,16],
[60,29,22,17],
[60,29,22,18],
[60,29,22,21],
[60,29,23,0],
[60,29,23,1],
[60,29,23,2],
[60,29,23,3],
[60,29,23,4],
[60,29,23,5],
[60,29,23,6],
[60,29,23,7],
[60,29,23,8],
[60,29,23,9],
[60,29,23,10],
[60,29,23,15],
[60,29,23,16],
[60,29,23,17],
[60,29,23,18],
[60,29,23,20],
[60,29,23,21],
[60,29,23,22],
[60,29,24,0],
[60,29,24,1],
[60,29,24,2],
[60,29,24,3],
[60,29,24,4],
[60,29,24,5],
[60,29,24,6],
[60,29,24,7],
[60,29,24,8],
[60,29,24,9],
[60,29,24,10],
[60,29,24,15],
[60,29,24,17],
[60,29,24,18],
[60,29,24,20],
[60,29,24,21],
[60,29,24,22],
[60,29,24,23],
[60,29,25,0],
[60,29,25,1],
[60,29,25,2],
[60,29,25,3],
[60,29,25,4],
[60,29,25,5],
[60,29,25,6],
[60,29,25,8],
[60,29,25,9],
[60,29,25,10],
[60,29,25,15],
[60,29,25,16],
[60,29,25,17],
[60,29,25,18],
[60,29,25,20],
[60,29,25,21],
[60,29,25,22],
[60,29,26,0],
[60,29,26,1],
[60,29,26,2],
[60,29,26,3],
[60,29,26,4],
[60,29,26,5],
[60,29,26,6],
[60,29,26,7],
[60,29,26,8],
[60,29,26,10],
[60,29,26,16],
[60,29,26,17],
[60,29,26,20],
[60,29,26,21],
[60,29,26,23],
[60,29,26,24],
[60,29,26,25],
[60,29,27,0],
[60,29,27,1],
[60,29,27,2],
[60,29,27,3],
[60,29,27,4],
[60,29,27,5],
[60,29,27,6],
[60,29,27,7],
[60,29,27,8],
[60,29,27,9],
[60,29,27,15],
[60,29,27,16],
[60,29,27,17],
[60,29,27,18],
[60,29,27,23],
[60,29,27,24],
[60,29,27,25],
[60,29,27,26],
[60,29,28,0],
[60,29,28,1],
[60,29,28,2],
[60,29,28,3],
[60,29,28,4],
[60,29,28,5],
[60,29,28,6],
[60,29,28,8],
[60,29,28,9],
[60,29,28,10],
[60,29,28,15],
[60,29,28,16],
[60,29,28,17],
[60,29,28,18],
[60,29,28,21],
[60,29,28,22],
[60,29,28,23],
[60,29,28,24],
[60,29,28,26],
[60,29,28,27],
[60,30,3,0],
[60,30,3,1],
[60,30,3,2],
[60,30,4,0],
[60,30,4,1],
[60,30,4,2],
[60,30,4,3],
[60,30,5,0],
[60,30,5,1],
[60,30,5,2],
[60,30,5,4],
[60,30,6,0],
[60,30,6,1],
[60,30,6,2],
[60,30,6,4],
[60,30,7,0],
[60,30,7,1],
[60,30,7,2],
[60,30,7,3],
[60,30,7,4],
[60,30,7,5],
[60,30,7,6],
[60,30,8,0],
[60,30,8,1],
[60,30,8,2],
[60,30,8,4],
[60,30,8,7],
[60,30,9,0],
[60,30,9,1],
[60,30,9,2],
[60,30,9,3],
[60,30,9,4],
[60,30,9,5],
[60,30,9,6],
[60,30,9,7],
[60,30,9,8],
[60,30,10,0],
[60,30,10,1],
[60,30,10,2],
[60,30,10,3],
[60,30,10,4],
[60,30,10,5],
[60,30,10,6],
[60,30,10,7],
[60,30,10,8],
[60,30,10,9],
[60,30,11,0],
[60,30,11,1],
[60,30,11,2],
[60,30,11,3],
[60,30,11,4],
[60,30,11,5],
[60,30,11,6],
[60,30,11,7],
[60,30,11,8],
[60,30,11,9],
[60,30,11,10],
[60,30,12,0],
[60,30,12,1],
[60,30,12,2],
[60,30,12,3],
[60,30,12,4],
[60,30,12,5],
[60,30,12,6],
[60,30,12,7],
[60,30,12,8],
[60,30,12,9],
[60,30,12,10],
[60,30,15,0],
[60,30,15,1],
[60,30,15,2],
[60,30,15,3],
[60,30,15,4],
[60,30,15,5],
[60,30,15,6],
[60,30,15,7],
[60,30,15,8],
[60,30,15,10],
[60,30,15,11],
[60,30,15,12],
[60,30,16,0],
[60,30,16,1],
[60,30,16,2],
[60,30,16,3],
[60,30,16,4],
[60,30,16,5],
[60,30,16,6],
[60,30,16,7],
[60,30,16,8],
[60,30,16,9],
[60,30,16,10],
[60,30,16,11],
[60,30,16,12],
[60,30,16,15],
[60,30,17,0],
[60,30,17,1],
[60,30,17,2],
[60,30,17,3],
[60,30,17,4],
[60,30,17,5],
[60,30,17,6],
[60,30,17,7],
[60,30,17,8],
[60,30,17,9],
[60,30,17,10],
[60,30,17,11],
[60,30,17,12],
[60,30,17,15],
[60,30,17,16],
[60,30,18,0],
[60,30,18,1],
[60,30,18,2],
[60,30,18,3],
[60,30,18,4],
[60,30,18,5],
[60,30,18,6],
[60,30,18,7],
[60,30,18,8],
[60,30,18,10],
[60,30,18,11],
[60,30,18,12],
[60,30,18,16],
[60,30,18,17],
[60,30,20,0],
[60,30,20,1],
[60,30,20,2],
[60,30,20,3],
[60,30,20,4],
[60,30,20,5],
[60,30,20,6],
[60,30,20,7],
[60,30,20,8],
[60,30,20,9],
[60,30,20,11],
[60,30,20,12],
[60,30,20,15],
[60,30,20,16],
[60,30,20,17],
[60,30,20,18],
[60,30,21,0],
[60,30,21,1],
[60,30,21,2],
[60,30,21,4],
[60,30,21,7],
[60,30,21,9],
[60,30,21,10],
[60,30,21,11],
[60,30,21,12],
[60,30,21,15],
[60,30,21,16],
[60,30,21,17],
[60,30,21,18],
[60,30,21,20],
[60,30,22,0],
[60,30,22,1],
[60,30,22,2],
[60,30,22,3],
[60,30,22,4],
[60,30,22,5],
[60,30,22,6],
[60,30,22,7],
[60,30,22,8],
[60,30,22,9],
[60,30,22,11],
[60,30,22,12],
[60,30,22,15],
[60,30,22,16],
[60,30,22,17],
[60,30,22,18],
[60,30,22,21],
[60,30,24,0],
[60,30,24,1],
[60,30,24,2],
[60,30,24,3],
[60,30,24,4],
[60,30,24,5],
[60,30,24,6],
[60,30,24,7],
[60,30,24,8],
[60,30,24,9],
[60,30,24,10],
[60,30,24,11],
[60,30,24,12],
[60,30,24,15],
[60,30,24,17],
[60,30,24,18],
[60,30,24,20],
[60,30,24,21],
[60,30,24,22],
[60,30,25,0],
[60,30,25,1],
[60,30,25,2],
[60,30,25,3],
[60,30,25,4],
[60,30,25,5],
[60,30,25,6],
[60,30,25,8],
[60,30,25,9],
[60,30,25,10],
[60,30,25,11],
[60,30,25,12],
[60,30,25,15],
[60,30,25,16],
[60,30,25,17],
[60,30,25,18],
[60,30,25,20],
[60,30,25,21],
[60,30,25,24],
[60,30,26,0],
[60,30,26,1],
[60,30,26,2],
[60,30,26,3],
[60,30,26,4],
[60,30,26,5],
[60,30,26,6],
[60,30,26,7],
[60,30,26,8],
[60,30,26,10],
[60,30,26,11],
[60,30,26,12],
[60,30,26,16],
[60,30,26,17],
[60,30,26,20],
[60,30,26,22],
[60,30,26,24],
[60,30,26,25],
[60,30,27,0],
[60,30,27,1],
[60,30,27,2],
[60,30,27,3],
[60,30,27,4],
[60,30,27,5],
[60,30,27,6],
[60,30,27,7],
[60,30,27,8],
[60,30,27,9],
[60,30,27,11],
[60,30,27,12],
[60,30,27,15],
[60,30,27,16],
[60,30,27,17],
[60,30,27,18],
[60,30,27,21],
[60,30,27,24],
[60,30,27,25],
[60,30,27,26],
[60,30,28,0],
[60,30,28,1],
[60,30,28,2],
[60,30,28,3],
[60,30,28,4],
[60,30,28,5],
[60,30,28,6],
[60,30,28,8],
[60,30,28,9],
[60,30,28,10],
[60,30,28,11],
[60,30,28,12],
[60,30,28,15],
[60,30,28,16],
[60,30,28,17],
[60,30,28,18],
[60,30,28,20],
[60,30,28,21],
[60,30,28,22],
[60,30,28,24],
[60,30,28,26],
[60,30,28,27],
[60,30,29,0],
[60,30,29,1],
[60,30,29,2],
[60,30,29,3],
[60,30,29,4],
[60,30,29,5],
[60,30,29,6],
[60,30,29,7],
[60,30,29,8],
[60,30,29,9],
[60,30,29,10],
[60,30,29,15],
[60,30,29,16],
[60,30,29,17],
[60,30,29,20],
[60,30,29,21],
[60,30,29,22],
[60,30,29,24],
[60,30,29,25],
[60,30,29,26],
[60,30,29,27],
[60,30,29,28],
[60,31,3,0],
[60,31,3,1],
[60,31,3,2],
[60,31,4,0],
[60,31,4,1],
[60,31,4,2],
[60,31,4,3],
[60,31,5,0],
[60,31,5,1],
[60,31,5,2],
[60,31,5,4],
[60,31,6,0],
[60,31,6,1],
[60,31,6,2],
[60,31,6,4],
[60,31,7,0],
[60,31,7,1],
[60,31,7,2],
[60,31,7,3],
[60,31,7,4],
[60,31,7,5],
[60,31,7,6],
[60,31,8,0],
[60,31,8,1],
[60,31,8,2],
[60,31,8,4],
[60,31,8,7],
[60,31,9,0],
[60,31,9,1],
[60,31,9,2],
[60,31,9,3],
[60,31,9,4],
[60,31,9,5],
[60,31,9,6],
[60,31,9,7],
[60,31,9,8],
[60,31,10,0],
[60,31,10,1],
[60,31,10,2],
[60,31,10,3],
[60,31,10,4],
[60,31,10,5],
[60,31,10,6],
[60,31,10,7],
[60,31,10,8],
[60,31,10,9],
[60,31,11,0],
[60,31,11,1],
[60,31,11,2],
[60,31,11,3],
[60,31,11,4],
[60,31,11,5],
[60,31,11,6],
[60,31,11,7],
[60,31,11,8],
[60,31,11,9],
[60,31,11,10],
[60,31,12,0],
[60,31,12,1],
[60,31,12,2],
[60,31,12,3],
[60,31,12,4],
[60,31,12,5],
[60,31,12,6],
[60,31,12,7],
[60,31,12,8],
[60,31,12,9],
[60,31,12,10],
[60,31,15,0],
[60,31,15,1],
[60,31,15,2],
[60,31,15,3],
[60,31,15,4],
[60,31,15,5],
[60,31,15,6],
[60,31,15,7],
[60,31,15,8],
[60,31,15,10],
[60,31,15,11],
[60,31,15,12],
[60,31,16,0],
[60,31,16,1],
[60,31,16,2],
[60,31,16,3],
[60,31,16,4],
[60,31,16,5],
[60,31,16,6],
[60,31,16,7],
[60,31,16,8],
[60,31,16,9],
[60,31,16,10],
[60,31,16,11],
[60,31,16,12],
[60,31,16,15],
[60,31,17,0],
[60,31,17,1],
[60,31,17,2],
[60,31,17,3],
[60,31,17,4],
[60,31,17,5],
[60,31,17,6],
[60,31,17,7],
[60,31,17,8],
[60,31,17,9],
[60,31,17,10],
[60,31,17,11],
[60,31,17,12],
[60,31,17,15],
[60,31,17,16],
[60,31,18,0],
[60,31,18,1],
[60,31,18,2],
[60,31,18,3],
[60,31,18,4],
[60,31,18,5],
[60,31,18,6],
[60,31,18,7],
[60,31,18,8],
[60,31,18,10],
[60,31,18,11],
[60,31,18,12],
[60,31,18,16],
[60,31,18,17],
[60,31,20,0],
[60,31,20,1],
[60,31,20,2],
[60,31,20,3],
[60,31,20,4],
[60,31,20,5],
[60,31,20,6],
[60,31,20,7],
[60,31,20,8],
[60,31,20,9],
[60,31,20,11],
[60,31,20,12],
[60,31,20,15],
[60,31,20,16],
[60,31,20,17],
[60,31,20,18],
[60,31,21,0],
[60,31,21,1],
[60,31,21,2],
[60,31,21,4],
[60,31,21,7],
[60,31,21,9],
[60,31,21,10],
[60,31,21,11],
[60,31,21,12],
[60,31,21,15],
[60,31,21,16],
[60,31,21,17],
[60,31,21,18],
[60,31,21,20],
[60,31,22,0],
[60,31,22,1],
[60,31,22,2],
[60,31,22,3],
[60,31,22,4],
[60,31,22,5],
[60,31,22,6],
[60,31,22,7],
[60,31,22,8],
[60,31,22,9],
[60,31,22,11],
[60,31,22,12],
[60,31,22,15],
[60,31,22,16],
[60,31,22,17],
[60,31,22,18],
[60,31,22,21],
[60,31,24,0],
[60,31,24,1],
[60,31,24,2],
[60,31,24,3],
[60,31,24,4],
[60,31,24,5],
[60,31,24,6],
[60,31,24,7],
[60,31,24,8],
[60,31,24,9],
[60,31,24,10],
[60,31,24,11],
[60,31,24,12],
[60,31,24,15],
[60,31,24,17],
[60,31,24,18],
[60,31,24,20],
[60,31,24,21],
[60,31,24,22],
[60,31,25,0],
[60,31,25,1],
[60,31,25,2],
[60,31,25,3],
[60,31,25,4],
[60,31,25,5],
[60,31,25,6],
[60,31,25,8],
[60,31,25,9],
[60,31,25,10],
[60,31,25,11],
[60,31,25,12],
[60,31,25,15],
[60,31,25,16],
[60,31,25,17],
[60,31,25,18],
[60,31,25,20],
[60,31,25,22],
[60,31,25,24],
[60,31,26,0],
[60,31,26,1],
[60,31,26,2],
[60,31,26,3],
[60,31,26,4],
[60,31,26,5],
[60,31,26,6],
[60,31,26,7],
[60,31,26,8],
[60,31,26,10],
[60,31,26,11],
[60,31,26,12],
[60,31,26,16],
[60,31,26,17],
[60,31,26,21],
[60,31,26,22],
[60,31,26,24],
[60,31,26,25],
[60,31,27,0],
[60,31,27,1],
[60,31,27,2],
[60,31,27,3],
[60,31,27,4],
[60,31,27,5],
[60,31,27,6],
[60,31,27,7],
[60,31,27,8],
[60,31,27,9],
[60,31,27,11],
[60,31,27,12],
[60,31,27,15],
[60,31,27,16],
[60,31,27,17],
[60,31,27,18],
[60,31,27,21],
[60,31,27,24],
[60,31,27,25],
[60,31,27,26],
[60,31,28,0],
[60,31,28,1],
[60,31,28,2],
[60,31,28,3],
[60,31,28,4],
[60,31,28,5],
[60,31,28,6],
[60,31,28,8],
[60,31,28,9],
[60,31,28,10],
[60,31,28,11],
[60,31,28,12],
[60,31,28,15],
[60,31,28,16],
[60,31,28,17],
[60,31,28,20],
[60,31,28,21],
[60,31,28,22],
[60,31,28,24],
[60,31,28,26],
[60,31,28,27],
[60,31,29,0],
[60,31,29,1],
[60,31,29,2],
[60,31,29,3],
[60,31,29,4],
[60,31,29,5],
[60,31,29,6],
[60,31,29,7],
[60,31,29,8],
[60,31,29,9],
[60,31,29,10],
[60,31,29,15],
[60,31,29,18],
[60,31,29,20],
[60,31,29,21],
[60,31,29,22],
[60,31,29,24],
[60,31,29,25],
[60,31,29,26],
[60,31,29,27],
[60,31,29,28],
[60,32,3,0],
[60,32,3,1],
[60,32,3,2],
[60,32,4,0],
[60,32,4,1],
[60,32,4,2],
[60,32,4,3],
[60,32,5,0],
[60,32,5,1],
[60,32,5,2],
[60,32,5,4],
[60,32,6,0],
[60,32,6,1],
[60,32,6,2],
[60,32,6,4],
[60,32,7,0],
[60,32,7,1],
[60,32,7,2],
[60,32,7,3],
[60,32,7,4],
[60,32,7,5],
[60,32,7,6],
[60,32,8,0],
[60,32,8,1],
[60,32,8,2],
[60,32,8,4],
[60,32,8,7],
[60,32,9,0],
[60,32,9,1],
[60,32,9,2],
[60,32,9,3],
[60,32,9,4],
[60,32,9,5],
[60,32,9,6],
[60,32,9,7],
[60,32,9,8],
[60,32,10,0],
[60,32,10,1],
[60,32,10,2],
[60,32,10,3],
[60,32,10,4],
[60,32,10,5],
[60,32,10,6],
[60,32,10,7],
[60,32,10,8],
[60,32,10,9],
[60,32,15,0],
[60,32,15,1],
[60,32,15,2],
[60,32,15,3],
[60,32,15,4],
[60,32,15,5],
[60,32,15,6],
[60,32,15,7],
[60,32,15,8],
[60,32,15,10],
[60,32,16,0],
[60,32,16,1],
[60,32,16,2],
[60,32,16,3],
[60,32,16,4],
[60,32,16,5],
[60,32,16,6],
[60,32,16,7],
[60,32,16,8],
[60,32,16,9],
[60,32,16,10],
[60,32,16,15],
[60,32,17,0],
[60,32,17,1],
[60,32,17,2],
[60,32,17,3],
[60,32,17,4],
[60,32,17,5],
[60,32,17,6],
[60,32,17,7],
[60,32,17,8],
[60,32,17,9],
[60,32,17,10],
[60,32,17,15],
[60,32,17,16],
[60,32,18,0],
[60,32,18,1],
[60,32,18,2],
[60,32,18,3],
[60,32,18,4],
[60,32,18,5],
[60,32,18,6],
[60,32,18,7],
[60,32,18,8],
[60,32,18,10],
[60,32,18,16],
[60,32,18,17],
[60,32,20,0],
[60,32,20,1],
[60,32,20,2],
[60,32,20,3],
[60,32,20,4],
[60,32,20,5],
[60,32,20,6],
[60,32,20,7],
[60,32,20,8],
[60,32,20,9],
[60,32,20,15],
[60,32,20,16],
[60,32,20,17],
[60,32,20,18],
[60,32,21,0],
[60,32,21,1],
[60,32,21,2],
[60,32,21,4],
[60,32,21,7],
[60,32,21,9],
[60,32,21,10],
[60,32,21,15],
[60,32,21,16],
[60,32,21,17],
[60,32,21,18],
[60,32,21,20],
[60,32,22,0],
[60,32,22,1],
[60,32,22,2],
[60,32,22,3],
[60,32,22,4],
[60,32,22,5],
[60,32,22,6],
[60,32,22,7],
[60,32,22,8],
[60,32,22,9],
[60,32,22,15],
[60,32,22,16],
[60,32,22,17],
[60,32,22,18],
[60,32,22,21],
[60,32,23,0],
[60,32,23,1],
[60,32,23,2],
[60,32,23,3],
[60,32,23,4],
[60,32,23,5],
[60,32,23,6],
[60,32,23,7],
[60,32,23,8],
[60,32,23,9],
[60,32,23,10],
[60,32,23,15],
[60,32,23,16],
[60,32,23,17],
[60,32,23,18],
[60,32,23,20],
[60,32,23,21],
[60,32,24,0],
[60,32,24,1],
[60,32,24,2],
[60,32,24,3],
[60,32,24,4],
[60,32,24,5],
[60,32,24,6],
[60,32,24,7],
[60,32,24,8],
[60,32,24,9],
[60,32,24,10],
[60,32,24,15],
[60,32,24,17],
[60,32,24,18],
[60,32,24,20],
[60,32,24,21],
[60,32,24,23],
[60,32,25,0],
[60,32,25,1],
[60,32,25,2],
[60,32,25,3],
[60,32,25,4],
[60,32,25,5],
[60,32,25,6],
[60,32,25,8],
[60,32,25,9],
[60,32,25,10],
[60,32,25,15],
[60,32,25,16],
[60,32,25,17],
[60,32,25,18],
[60,32,25,21],
[60,32,25,22],
[60,32,25,23],
[60,32,25,24],
[60,32,26,0],
[60,32,26,1],
[60,32,26,2],
[60,32,26,3],
[60,32,26,4],
[60,32,26,5],
[60,32,26,6],
[60,32,26,7],
[60,32,26,8],
[60,32,26,10],
[60,32,26,16],
[60,32,26,17],
[60,32,26,20],
[60,32,26,21],
[60,32,26,22],
[60,32,26,23],
[60,32,26,24],
[60,32,26,25],
[60,32,27,0],
[60,32,27,1],
[60,32,27,2],
[60,32,27,3],
[60,32,27,4],
[60,32,27,5],
[60,32,27,6],
[60,32,27,7],
[60,32,27,8],
[60,32,27,9],
[60,32,27,15],
[60,32,27,16],
[60,32,27,17],
[60,32,27,21],
[60,32,27,23],
[60,32,27,24],
[60,32,27,25],
[60,32,27,26],
[60,32,28,0],
[60,32,28,1],
[60,32,28,2],
[60,32,28,3],
[60,32,28,4],
[60,32,28,5],
[60,32,28,6],
[60,32,28,8],
[60,32,28,9],
[60,32,28,10],
[60,32,28,15],
[60,32,28,18],
[60,32,28,20],
[60,32,28,21],
[60,32,28,22],
[60,32,28,23],
[60,32,28,24],
[60,32,28,26],
[60,32,28,27],
[60,32,30,0],
[60,32,30,1],
[60,32,30,2],
[60,32,30,3],
[60,32,30,4],
[60,32,30,5],
[60,32,30,6],
[60,32,30,7],
[60,32,30,8],
[60,32,30,9],
[60,32,30,10],
[60,32,30,15],
[60,32,30,16],
[60,32,30,17],
[60,32,30,18],
[60,32,30,20],
[60,32,30,21],
[60,32,30,22],
[60,32,30,24],
[60,32,30,25],
[60,32,30,26],
[60,32,30,27],
[60,32,31,0],
[60,32,31,1],
[60,32,31,2],
[60,32,31,3],
[60,32,31,4],
[60,32,31,5],
[60,32,31,6],
[60,32,31,7],
[60,32,31,8],
[60,32,31,9],
[60,32,31,10],
[60,32,31,15],
[60,32,31,16],
[60,32,31,17],
[60,32,31,18],
[60,32,31,20],
[60,32,31,21],
[60,32,31,22],
[60,32,31,24],
[60,32,31,25],
[60,32,31,26],
[60,33,3,0],
[60,33,3,1],
[60,33,3,2],
[60,33,4,0],
[60,33,4,1],
[60,33,4,2],
[60,33,4,3],
[60,33,5,0],
[60,33,5,1],
[60,33,5,2],
[60,33,5,4],
[60,33,6,0],
[60,33,6,1],
[60,33,6,2],
[60,33,6,4],
[60,33,7,0],
[60,33,7,1],
[60,33,7,2],
[60,33,7,3],
[60,33,7,4],
[60,33,7,5],
[60,33,7,6],
[60,33,8,0],
[60,33,8,1],
[60,33,8,2],
[60,33,8,4],
[60,33,8,7],
[60,33,9,0],
[60,33,9,1],
[60,33,9,2],
[60,33,9,3],
[60,33,9,4],
[60,33,9,5],
[60,33,9,6],
[60,33,9,7],
[60,33,9,8],
[60,33,10,0],
[60,33,10,1],
[60,33,10,2],
[60,33,10,3],
[60,33,10,4],
[60,33,10,5],
[60,33,10,6],
[60,33,10,7],
[60,33,10,8],
[60,33,10,9],
[60,33,15,0],
[60,33,15,1],
[60,33,15,2],
[60,33,15,3],
[60,33,15,4],
[60,33,15,5],
[60,33,15,6],
[60,33,15,7],
[60,33,15,8],
[60,33,15,10],
[60,33,16,0],
[60,33,16,1],
[60,33,16,2],
[60,33,16,3],
[60,33,16,4],
[60,33,16,5],
[60,33,16,6],
[60,33,16,7],
[60,33,16,8],
[60,33,16,9],
[60,33,16,10],
[60,33,16,15],
[60,33,17,0],
[60,33,17,1],
[60,33,17,2],
[60,33,17,3],
[60,33,17,4],
[60,33,17,5],
[60,33,17,6],
[60,33,17,7],
[60,33,17,8],
[60,33,17,9],
[60,33,17,10],
[60,33,17,15],
[60,33,17,16],
[60,33,18,0],
[60,33,18,1],
[60,33,18,2],
[60,33,18,3],
[60,33,18,4],
[60,33,18,5],
[60,33,18,6],
[60,33,18,7],
[60,33,18,8],
[60,33,18,10],
[60,33,18,16],
[60,33,18,17],
[60,33,20,0],
[60,33,20,1],
[60,33,20,2],
[60,33,20,3],
[60,33,20,4],
[60,33,20,5],
[60,33,20,6],
[60,33,20,7],
[60,33,20,8],
[60,33,20,9],
[60,33,20,15],
[60,33,20,16],
[60,33,20,17],
[60,33,20,18],
[60,33,21,0],
[60,33,21,1],
[60,33,21,2],
[60,33,21,4],
[60,33,21,7],
[60,33,21,9],
[60,33,21,10],
[60,33,21,15],
[60,33,21,16],
[60,33,21,17],
[60,33,21,18],
[60,33,21,20],
[60,33,22,0],
[60,33,22,1],
[60,33,22,2],
[60,33,22,3],
[60,33,22,4],
[60,33,22,5],
[60,33,22,6],
[60,33,22,7],
[60,33,22,8],
[60,33,22,9],
[60,33,22,15],
[60,33,22,16],
[60,33,22,17],
[60,33,22,18],
[60,33,22,21],
[60,33,23,0],
[60,33,23,1],
[60,33,23,2],
[60,33,23,3],
[60,33,23,4],
[60,33,23,5],
[60,33,23,6],
[60,33,23,7],
[60,33,23,8],
[60,33,23,9],
[60,33,23,10],
[60,33,23,15],
[60,33,23,16],
[60,33,23,17],
[60,33,23,18],
[60,33,23,20],
[60,33,23,22],
[60,33,24,0],
[60,33,24,1],
[60,33,24,2],
[60,33,24,3],
[60,33,24,4],
[60,33,24,5],
[60,33,24,6],
[60,33,24,7],
[60,33,24,8],
[60,33,24,9],
[60,33,24,10],
[60,33,24,15],
[60,33,24,17],
[60,33,24,18],
[60,33,24,20],
[60,33,24,22],
[60,33,24,23],
[60,33,25,0],
[60,33,25,1],
[60,33,25,2],
[60,33,25,3],
[60,33,25,4],
[60,33,25,5],
[60,33,25,6],
[60,33,25,8],
[60,33,25,9],
[60,33,25,10],
[60,33,25,15],
[60,33,25,16],
[60,33,25,17],
[60,33,25,18],
[60,33,25,20],
[60,33,25,21],
[60,33,25,22],
[60,33,25,23],
[60,33,25,24],
[60,33,26,0],
[60,33,26,1],
[60,33,26,2],
[60,33,26,3],
[60,33,26,4],
[60,33,26,5],
[60,33,26,6],
[60,33,26,7],
[60,33,26,8],
[60,33,26,10],
[60,33,26,16],
[60,33,26,17],
[60,33,26,20],
[60,33,26,21],
[60,33,26,22],
[60,33,26,23],
[60,33,26,24],
[60,33,26,25],
[60,33,27,0],
[60,33,27,1],
[60,33,27,2],
[60,33,27,3],
[60,33,27,4],
[60,33,27,5],
[60,33,27,6],
[60,33,27,7],
[60,33,27,8],
[60,33,27,9],
[60,33,27,15],
[60,33,27,18],
[60,33,27,21],
[60,33,27,23],
[60,33,27,24],
[60,33,27,25],
[60,33,27,26],
[60,33,28,0],
[60,33,28,1],
[60,33,28,2],
[60,33,28,3],
[60,33,28,4],
[60,33,28,5],
[60,33,28,6],
[60,33,28,8],
[60,33,28,9],
[60,33,28,10],
[60,33,28,16],
[60,33,28,17],
[60,33,28,18],
[60,33,28,20],
[60,33,28,21],
[60,33,28,22],
[60,33,28,23],
[60,33,28,24],
[60,33,28,26],
[60,33,28,27],
[60,33,30,0],
[60,33,30,1],
[60,33,30,2],
[60,33,30,3],
[60,33,30,4],
[60,33,30,5],
[60,33,30,6],
[60,33,30,7],
[60,33,30,8],
[60,33,30,9],
[60,33,30,10],
[60,33,30,15],
[60,33,30,16],
[60,33,30,17],
[60,33,30,18],
[60,33,30,20],
[60,33,30,21],
[60,33,30,22],
[60,33,30,24],
[60,33,30,25],
[60,33,30,26],
[60,33,31,0],
[60,33,31,1],
[60,33,31,2],
[60,33,31,3],
[60,33,31,4],
[60,33,31,5],
[60,33,31,6],
[60,33,31,7],
[60,33,31,8],
[60,33,31,9],
[60,33,31,15],
[60,33,31,16],
[60,33,31,17],
[60,33,31,18],
[60,33,31,20],
[60,33,31,21],
[60,33,31,22],
[60,33,31,24],
[60,33,31,25],
[60,34,3,0],
[60,34,3,1],
[60,34,3,2],
[60,34,4,0],
[60,34,4,1],
[60,34,4,2],
[60,34,4,3],
[60,34,5,0],
[60,34,5,1],
[60,34,5,2],
[60,34,5,4],
[60,34,6,0],
[60,34,6,1],
[60,34,6,2],
[60,34,6,4],
[60,34,8,0],
[60,34,8,1],
[60,34,8,2],
[60,34,8,4],
[60,34,9,0],
[60,34,9,1],
[60,34,9,2],
[60,34,9,3],
[60,34,9,4],
[60,34,9,5],
[60,34,9,6],
[60,34,9,8],
[60,34,10,0],
[60,34,10,1],
[60,34,10,2],
[60,34,10,3],
[60,34,10,4],
[60,34,10,5],
[60,34,10,6],
[60,34,10,8],
[60,34,10,9],
[60,34,11,0],
[60,34,11,1],
[60,34,11,2],
[60,34,11,3],
[60,34,11,4],
[60,34,11,5],
[60,34,11,6],
[60,34,11,8],
[60,34,11,9],
[60,34,11,10],
[60,34,12,0],
[60,34,12,1],
[60,34,12,2],
[60,34,12,3],
[60,34,12,4],
[60,34,12,5],
[60,34,12,6],
[60,34,12,8],
[60,34,12,9],
[60,34,12,10],
[60,34,15,0],
[60,34,15,1],
[60,34,15,2],
[60,34,15,3],
[60,34,15,4],
[60,34,15,5],
[60,34,15,6],
[60,34,15,8],
[60,34,15,10],
[60,34,15,11],
[60,34,15,12],
[60,34,16,0],
[60,34,16,1],
[60,34,16,2],
[60,34,16,3],
[60,34,16,4],
[60,34,16,5],
[60,34,16,6],
[60,34,16,8],
[60,34,16,9],
[60,34,16,10],
[60,34,16,11],
[60,34,16,12],
[60,34,16,15],
[60,34,17,0],
[60,34,17,1],
[60,34,17,2],
[60,34,17,3],
[60,34,17,4],
[60,34,17,5],
[60,34,17,6],
[60,34,17,8],
[60,34,17,9],
[60,34,17,10],
[60,34,17,11],
[60,34,17,12],
[60,34,17,15],
[60,34,17,16],
[60,34,18,0],
[60,34,18,1],
[60,34,18,2],
[60,34,18,3],
[60,34,18,4],
[60,34,18,5],
[60,34,18,6],
[60,34,18,8],
[60,34,18,10],
[60,34,18,11],
[60,34,18,12],
[60,34,18,16],
[60,34,18,17],
[60,34,20,0],
[60,34,20,1],
[60,34,20,2],
[60,34,20,3],
[60,34,20,4],
[60,34,20,5],
[60,34,20,6],
[60,34,20,8],
[60,34,20,9],
[60,34,20,11],
[60,34,20,12],
[60,34,20,15],
[60,34,20,16],
[60,34,20,17],
[60,34,20,18],
[60,34,21,0],
[60,34,21,1],
[60,34,21,2],
[60,34,21,4],
[60,34,21,9],
[60,34,21,10],
[60,34,21,11],
[60,34,21,12],
[60,34,21,15],
[60,34,21,16],
[60,34,21,17],
[60,34,21,18],
[60,34,21,20],
[60,34,22,0],
[60,34,22,1],
[60,34,22,2],
[60,34,22,3],
[60,34,22,4],
[60,34,22,5],
[60,34,22,6],
[60,34,22,8],
[60,34,22,9],
[60,34,22,11],
[60,34,22,12],
[60,34,22,15],
[60,34,22,16],
[60,34,22,17],
[60,34,22,18],
[60,34,23,0],
[60,34,23,1],
[60,34,23,2],
[60,34,23,3],
[60,34,23,4],
[60,34,23,5],
[60,34,23,6],
[60,34,23,8],
[60,34,23,9],
[60,34,23,10],
[60,34,23,11],
[60,34,23,12],
[60,34,23,15],
[60,34,23,16],
[60,34,23,17],
[60,34,23,18],
[60,34,23,21],
[60,34,23,22],
[60,34,24,0],
[60,34,24,1],
[60,34,24,2],
[60,34,24,3],
[60,34,24,4],
[60,34,24,5],
[60,34,24,6],
[60,34,24,8],
[60,34,24,9],
[60,34,24,10],
[60,34,24,11],
[60,34,24,12],
[60,34,24,15],
[60,34,24,17],
[60,34,24,18],
[60,34,24,21],
[60,34,24,22],
[60,34,24,23],
[60,34,26,0],
[60,34,26,1],
[60,34,26,2],
[60,34,26,3],
[60,34,26,4],
[60,34,26,5],
[60,34,26,6],
[60,34,26,8],
[60,34,26,10],
[60,34,26,11],
[60,34,26,12],
[60,34,26,20],
[60,34,26,21],
[60,34,26,22],
[60,34,26,23],
[60,34,26,24],
[60,34,27,0],
[60,34,27,1],
[60,34,27,2],
[60,34,27,3],
[60,34,27,4],
[60,34,27,5],
[60,34,27,6],
[60,34,27,8],
[60,34,27,9],
[60,34,27,11],
[60,34,27,12],
[60,34,27,16],
[60,34,27,17],
[60,34,27,18],
[60,34,27,21],
[60,34,27,23],
[60,34,27,24],
[60,34,27,26],
[60,34,29,0],
[60,34,29,1],
[60,34,29,2],
[60,34,29,3],
[60,34,29,4],
[60,34,29,5],
[60,34,29,6],
[60,34,29,8],
[60,34,29,9],
[60,34,29,10],
[60,34,29,15],
[60,34,29,16],
[60,34,29,17],
[60,34,29,18],
[60,34,29,20],
[60,34,29,21],
[60,34,29,22],
[60,34,29,23],
[60,34,29,24],
[60,34,29,26],
[60,34,30,0],
[60,34,30,1],
[60,34,30,2],
[60,34,30,3],
[60,34,30,4],
[60,34,30,5],
[60,34,30,6],
[60,34,30,8],
[60,34,30,9],
[60,34,30,12],
[60,34,30,15],
[60,34,30,16],
[60,34,30,17],
[60,34,30,18],
[60,34,30,20],
[60,34,30,21],
[60,34,30,22],
[60,34,30,24],
[60,34,31,0],
[60,34,31,1],
[60,34,31,2],
[60,34,31,3],
[60,34,31,4],
[60,34,31,5],
[60,34,31,6],
[60,34,31,10],
[60,34,31,11],
[60,34,31,12],
[60,34,31,15],
[60,34,31,16],
[60,34,31,17],
[60,34,31,18],
[60,34,31,20],
[60,34,31,21],
[60,34,31,22],
[60,34,31,24],
[60,34,32,0],
[60,34,32,1],
[60,34,32,2],
[60,34,32,3],
[60,34,32,4],
[60,34,32,5],
[60,34,32,8],
[60,34,32,9],
[60,34,32,10],
[60,34,32,15],
[60,34,32,16],
[60,34,32,17],
[60,34,32,18],
[60,34,32,20],
[60,34,32,21],
[60,34,32,22],
[60,34,32,23],
[60,34,32,24],
[60,34,33,0],
[60,34,33,1],
[60,34,33,2],
[60,34,33,3],
[60,34,33,4],
[60,34,33,6],
[60,34,33,8],
[60,34,33,9],
[60,34,33,10],
[60,34,33,15],
[60,34,33,16],
[60,34,33,17],
[60,34,33,18],
[60,34,33,20],
[60,34,33,21],
[60,34,33,22],
[60,35,3,0],
[60,35,3,1],
[60,35,3,2],
[60,35,4,0],
[60,35,4,1],
[60,35,4,2],
[60,35,4,3],
[60,35,5,0],
[60,35,5,1],
[60,35,5,2],
[60,35,5,4],
[60,35,6,0],
[60,35,6,1],
[60,35,6,2],
[60,35,6,4],
[60,35,7,0],
[60,35,7,1],
[60,35,7,2],
[60,35,7,3],
[60,35,7,4],
[60,35,7,5],
[60,35,7,6],
[60,35,8,0],
[60,35,8,1],
[60,35,8,2],
[60,35,8,4],
[60,35,8,7],
[60,35,10,0],
[60,35,10,1],
[60,35,10,2],
[60,35,10,3],
[60,35,10,4],
[60,35,10,5],
[60,35,10,6],
[60,35,10,7],
[60,35,10,8],
[60,35,11,0],
[60,35,11,1],
[60,35,11,2],
[60,35,11,3],
[60,35,11,4],
[60,35,11,5],
[60,35,11,6],
[60,35,11,7],
[60,35,11,8],
[60,35,11,10],
[60,35,12,0],
[60,35,12,1],
[60,35,12,2],
[60,35,12,3],
[60,35,12,4],
[60,35,12,5],
[60,35,12,6],
[60,35,12,7],
[60,35,12,8],
[60,35,12,10],
[60,35,16,0],
[60,35,16,1],
[60,35,16,2],
[60,35,16,3],
[60,35,16,4],
[60,35,16,5],
[60,35,16,6],
[60,35,16,7],
[60,35,16,8],
[60,35,16,10],
[60,35,16,11],
[60,35,16,12],
[60,35,17,0],
[60,35,17,1],
[60,35,17,2],
[60,35,17,3],
[60,35,17,4],
[60,35,17,5],
[60,35,17,6],
[60,35,17,7],
[60,35,17,8],
[60,35,17,10],
[60,35,17,11],
[60,35,17,12],
[60,35,17,16],
[60,35,20,0],
[60,35,20,1],
[60,35,20,2],
[60,35,20,3],
[60,35,20,4],
[60,35,20,5],
[60,35,20,6],
[60,35,20,7],
[60,35,20,8],
[60,35,20,11],
[60,35,20,12],
[60,35,20,16],
[60,35,20,17],
[60,35,21,0],
[60,35,21,1],
[60,35,21,2],
[60,35,21,4],
[60,35,21,7],
[60,35,21,10],
[60,35,21,11],
[60,35,21,12],
[60,35,21,16],
[60,35,21,17],
[60,35,21,20],
[60,35,22,0],
[60,35,22,1],
[60,35,22,2],
[60,35,22,3],
[60,35,22,4],
[60,35,22,5],
[60,35,22,6],
[60,35,22,7],
[60,35,22,8],
[60,35,22,11],
[60,35,22,12],
[60,35,22,16],
[60,35,22,17],
[60,35,22,21],
[60,35,23,0],
[60,35,23,1],
[60,35,23,2],
[60,35,23,3],
[60,35,23,4],
[60,35,23,5],
[60,35,23,6],
[60,35,23,7],
[60,35,23,8],
[60,35,23,10],
[60,35,23,11],
[60,35,23,12],
[60,35,23,16],
[60,35,23,17],
[60,35,23,20],
[60,35,23,21],
[60,35,23,22],
[60,35,24,0],
[60,35,24,1],
[60,35,24,2],
[60,35,24,3],
[60,35,24,4],
[60,35,24,5],
[60,35,24,6],
[60,35,24,7],
[60,35,24,8],
[60,35,24,10],
[60,35,24,11],
[60,35,24,12],
[60,35,24,17],
[60,35,24,20],
[60,35,24,21],
[60,35,24,22],
[60,35,24,23],
[60,35,25,0],
[60,35,25,1],
[60,35,25,2],
[60,35,25,3],
[60,35,25,4],
[60,35,25,5],
[60,35,25,6],
[60,35,25,8],
[60,35,25,10],
[60,35,25,11],
[60,35,25,12],
[60,35,25,20],
[60,35,25,21],
[60,35,25,22],
[60,35,25,23],
[60,35,25,24],
[60,35,27,0],
[60,35,27,1],
[60,35,27,2],
[60,35,27,3],
[60,35,27,4],
[60,35,27,5],
[60,35,27,6],
[60,35,27,7],
[60,35,27,8],
[60,35,27,11],
[60,35,27,12],
[60,35,27,16],
[60,35,27,17],
[60,35,27,21],
[60,35,27,23],
[60,35,27,24],
[60,35,27,25],
[60,35,28,0],
[60,35,28,1],
[60,35,28,2],
[60,35,28,3],
[60,35,28,4],
[60,35,28,5],
[60,35,28,6],
[60,35,28,8],
[60,35,28,10],
[60,35,28,11],
[60,35,28,16],
[60,35,28,17],
[60,35,28,20],
[60,35,28,21],
[60,35,28,22],
[60,35,28,23],
[60,35,28,24],
[60,35,29,0],
[60,35,29,1],
[60,35,29,2],
[60,35,29,3],
[60,35,29,4],
[60,35,29,5],
[60,35,29,6],
[60,35,29,7],
[60,35,29,8],
[60,35,29,16],
[60,35,29,17],
[60,35,29,20],
[60,35,29,21],
[60,35,29,22],
[60,35,29,23],
[60,35,29,24],
[60,35,29,25],
[60,35,30,0],
[60,35,30,1],
[60,35,30,2],
[60,35,30,3],
[60,35,30,4],
[60,35,30,5],
[60,35,30,6],
[60,35,30,7],
[60,35,30,10],
[60,35,30,11],
[60,35,30,12],
[60,35,30,16],
[60,35,30,17],
[60,35,30,20],
[60,35,30,21],
[60,35,30,22],
[60,35,30,24],
[60,35,31,0],
[60,35,31,1],
[60,35,31,2],
[60,35,31,3],
[60,35,31,4],
[60,35,31,5],
[60,35,31,8],
[60,35,31,10],
[60,35,31,11],
[60,35,31,12],
[60,35,31,16],
[60,35,31,17],
[60,35,31,20],
[60,35,31,21],
[60,35,31,22],
[60,35,31,24],
[60,35,32,0],
[60,35,32,1],
[60,35,32,2],
[60,35,32,3],
[60,35,32,4],
[60,35,32,6],
[60,35,32,7],
[60,35,32,8],
[60,35,32,10],
[60,35,32,16],
[60,35,32,17],
[60,35,32,20],
[60,35,32,21],
[60,35,32,22],
[60,35,33,0],
[60,35,33,1],
[60,35,33,2],
[60,35,33,5],
[60,35,33,6],
[60,35,33,7],
[60,35,33,8],
[60,35,33,10],
[60,35,33,16],
[60,35,33,17],
[60,35,33,20],
[60,35,33,21],
[60,35,34,3],
[60,35,34,4],
[60,35,34,5],
[60,35,34,6],
[60,35,34,8],
[60,35,34,10],
[60,35,34,11],
[60,35,34,12],
[60,35,34,16],
[60,35,34,17],
[60,35,34,20],
[60,37,3,0],
[60,37,3,1],
[60,37,3,2],
[60,37,5,0],
[60,37,5,1],
[60,37,5,2],
[60,37,6,0],
[60,37,6,1],
[60,37,6,2],
[60,37,7,0],
[60,37,7,1],
[60,37,7,2],
[60,37,7,3],
[60,37,7,5],
[60,37,7,6],
[60,37,8,0],
[60,37,8,1],
[60,37,8,2],
[60,37,8,7],
[60,37,9,0],
[60,37,9,1],
[60,37,9,2],
[60,37,9,3],
[60,37,9,5],
[60,37,9,6],
[60,37,9,7],
[60,37,9,8],
[60,37,10,0],
[60,37,10,1],
[60,37,10,2],
[60,37,10,3],
[60,37,10,5],
[60,37,10,6],
[60,37,10,7],
[60,37,10,8],
[60,37,10,9],
[60,37,11,0],
[60,37,11,1],
[60,37,11,2],
[60,37,11,3],
[60,37,11,5],
[60,37,11,6],
[60,37,11,7],
[60,37,11,8],
[60,37,11,9],
[60,37,11,10],
[60,37,12,0],
[60,37,12,1],
[60,37,12,2],
[60,37,12,3],
[60,37,12,5],
[60,37,12,6],
[60,37,12,7],
[60,37,12,8],
[60,37,12,9],
[60,37,12,10],
[60,37,15,0],
[60,37,15,1],
[60,37,15,2],
[60,37,15,3],
[60,37,15,5],
[60,37,15,6],
[60,37,15,7],
[60,37,15,8],
[60,37,15,10],
[60,37,15,11],
[60,37,15,12],
[60,37,16,0],
[60,37,16,1],
[60,37,16,2],
[60,37,16,3],
[60,37,16,5],
[60,37,16,6],
[60,37,16,7],
[60,37,16,8],
[60,37,16,9],
[60,37,16,10],
[60,37,16,11],
[60,37,16,12],
[60,37,16,15],
[60,37,17,0],
[60,37,17,1],
[60,37,17,2],
[60,37,17,3],
[60,37,17,5],
[60,37,17,6],
[60,37,17,7],
[60,37,17,8],
[60,37,17,9],
[60,37,17,10],
[60,37,17,11],
[60,37,17,12],
[60,37,17,15],
[60,37,17,16],
[60,37,18,0],
[60,37,18,1],
[60,37,18,2],
[60,37,18,3],
[60,37,18,5],
[60,37,18,6],
[60,37,18,7],
[60,37,18,8],
[60,37,18,10],
[60,37,18,11],
[60,37,18,12],
[60,37,18,16],
[60,37,18,17],
[60,37,20,0],
[60,37,20,1],
[60,37,20,2],
[60,37,20,3],
[60,37,20,5],
[60,37,20,6],
[60,37,20,7],
[60,37,20,8],
[60,37,20,9],
[60,37,20,11],
[60,37,20,12],
[60,37,20,15],
[60,37,20,16],
[60,37,20,17],
[60,37,20,18],
[60,37,21,0],
[60,37,21,1],
[60,37,21,2],
[60,37,21,7],
[60,37,21,9],
[60,37,21,10],
[60,37,21,11],
[60,37,21,12],
[60,37,21,15],
[60,37,21,16],
[60,37,21,17],
[60,37,21,18],
[60,37,21,20],
[60,37,22,0],
[60,37,22,1],
[60,37,22,2],
[60,37,22,3],
[60,37,22,5],
[60,37,22,6],
[60,37,22,7],
[60,37,22,8],
[60,37,22,9],
[60,37,22,11],
[60,37,22,12],
[60,37,22,15],
[60,37,22,16],
[60,37,22,17],
[60,37,22,21],
[60,37,23,0],
[60,37,23,1],
[60,37,23,2],
[60,37,23,3],
[60,37,23,5],
[60,37,23,6],
[60,37,23,7],
[60,37,23,8],
[60,37,23,9],
[60,37,23,10],
[60,37,23,11],
[60,37,23,12],
[60,37,23,15],
[60,37,23,18],
[60,37,23,20],
[60,37,23,21],
[60,37,23,22],
[60,37,24,0],
[60,37,24,1],
[60,37,24,2],
[60,37,24,3],
[60,37,24,5],
[60,37,24,6],
[60,37,24,7],
[60,37,24,8],
[60,37,24,9],
[60,37,24,10],
[60,37,24,11],
[60,37,24,12],
[60,37,24,15],
[60,37,24,18],
[60,37,24,20],
[60,37,24,21],
[60,37,24,22],
[60,37,24,23],
[60,37,25,0],
[60,37,25,1],
[60,37,25,2],
[60,37,25,3],
[60,37,25,5],
[60,37,25,6],
[60,37,25,8],
[60,37,25,9],
[60,37,25,10],
[60,37,25,11],
[60,37,25,12],
[60,37,25,15],
[60,37,25,16],
[60,37,25,17],
[60,37,25,18],
[60,37,25,20],
[60,37,25,21],
[60,37,25,22],
[60,37,25,23],
[60,37,25,24],
[60,37,26,0],
[60,37,26,1],
[60,37,26,2],
[60,37,26,3],
[60,37,26,5],
[60,37,26,6],
[60,37,26,7],
[60,37,26,8],
[60,37,26,10],
[60,37,26,11],
[60,37,26,16],
[60,37,26,17],
[60,37,26,20],
[60,37,26,21],
[60,37,26,22],
[60,37,26,23],
[60,37,26,24],
[60,37,26,25],
[60,37,27,0],
[60,37,27,1],
[60,37,27,2],
[60,37,27,3],
[60,37,27,5],
[60,37,27,6],
[60,37,27,7],
[60,37,27,8],
[60,37,27,9],
[60,37,27,12],
[60,37,27,15],
[60,37,27,16],
[60,37,27,17],
[60,37,27,18],
[60,37,27,21],
[60,37,27,23],
[60,37,27,24],
[60,37,27,25],
[60,37,28,0],
[60,37,28,1],
[60,37,28,2],
[60,37,28,3],
[60,37,28,5],
[60,37,28,6],
[60,37,28,10],
[60,37,28,11],
[60,37,28,12],
[60,37,28,15],
[60,37,28,16],
[60,37,28,17],
[60,37,28,18],
[60,37,28,20],
[60,37,28,21],
[60,37,28,22],
[60,37,28,23],
[60,37,28,24],
[60,37,29,0],
[60,37,29,1],
[60,37,29,2],
[60,37,29,3],
[60,37,29,5],
[60,37,29,8],
[60,37,29,9],
[60,37,29,10],
[60,37,29,15],
[60,37,29,16],
[60,37,29,17],
[60,37,29,18],
[60,37,29,20],
[60,37,29,21],
[60,37,29,22],
[60,37,29,23],
[60,37,29,24],
[60,37,30,0],
[60,37,30,1],
[60,37,30,2],
[60,37,30,3],
[60,37,30,6],
[60,37,30,7],
[60,37,30,8],
[60,37,30,9],
[60,37,30,10],
[60,37,30,11],
[60,37,30,12],
[60,37,30,15],
[60,37,30,16],
[60,37,30,17],
[60,37,30,18],
[60,37,30,20],
[60,37,30,21],
[60,37,30,22],
[60,37,31,0],
[60,37,31,1],
[60,37,31,2],
[60,37,31,5],
[60,37,31,6],
[60,37,31,7],
[60,37,31,8],
[60,37,31,9],
[60,37,31,10],
[60,37,31,11],
[60,37,31,12],
[60,37,31,15],
[60,37,31,16],
[60,37,31,17],
[60,37,31,18],
[60,37,31,20],
[60,37,31,21],
[60,37,32,3],
[60,37,32,5],
[60,37,32,6],
[60,37,32,7],
[60,37,32,8],
[60,37,32,9],
[60,37,32,10],
[60,37,32,15],
[60,37,32,16],
[60,37,32,17],
[60,37,32,18],
[60,37,32,20],
[60,37,33,0],
[60,37,33,1],
[60,37,33,2],
[60,37,33,3],
[60,37,33,5],
[60,37,33,6],
[60,37,33,7],
[60,37,33,8],
[60,37,33,9],
[60,37,33,10],
[60,37,33,15],
[60,37,33,16],
[60,37,33,17],
[60,37,33,18],
[60,37,34,0],
[60,37,34,1],
[60,37,34,2],
[60,37,34,3],
[60,37,34,5],
[60,37,34,6],
[60,37,34,8],
[60,37,34,9],
[60,37,34,10],
[60,37,34,11],
[60,37,34,12],
[60,37,34,15],
[60,37,34,16],
[60,37,34,17],
[60,37,34,18],
[60,37,35,0],
[60,37,35,1],
[60,37,35,2],
[60,37,35,3],
[60,37,35,5],
[60,37,35,6],
[60,37,35,7],
[60,37,35,8],
[60,37,35,10],
[60,37,35,11],
[60,37,35,12],
[60,37,35,16],
[60,37,35,17],
[60,38,3,0],
[60,38,3,1],
[60,38,3,2],
[60,38,4,0],
[60,38,4,1],
[60,38,4,2],
[60,38,4,3],
[60,38,5,0],
[60,38,5,1],
[60,38,5,2],
[60,38,5,4],
[60,38,6,0],
[60,38,6,1],
[60,38,6,2],
[60,38,6,4],
[60,38,8,0],
[60,38,8,1],
[60,38,8,2],
[60,38,8,4],
[60,38,9,0],
[60,38,9,1],
[60,38,9,2],
[60,38,9,3],
[60,38,9,4],
[60,38,9,5],
[60,38,9,6],
[60,38,9,8],
[60,38,10,0],
[60,38,10,1],
[60,38,10,2],
[60,38,10,3],
[60,38,10,4],
[60,38,10,5],
[60,38,10,6],
[60,38,10,8],
[60,38,10,9],
[60,38,11,0],
[60,38,11,1],
[60,38,11,2],
[60,38,11,3],
[60,38,11,4],
[60,38,11,5],
[60,38,11,6],
[60,38,11,8],
[60,38,11,9],
[60,38,11,10],
[60,38,12,0],
[60,38,12,1],
[60,38,12,2],
[60,38,12,3],
[60,38,12,4],
[60,38,12,5],
[60,38,12,6],
[60,38,12,8],
[60,38,12,9],
[60,38,12,10],
[60,38,15,0],
[60,38,15,1],
[60,38,15,2],
[60,38,15,3],
[60,38,15,4],
[60,38,15,5],
[60,38,15,6],
[60,38,15,8],
[60,38,15,10],
[60,38,15,11],
[60,38,15,12],
[60,38,16,0],
[60,38,16,1],
[60,38,16,2],
[60,38,16,3],
[60,38,16,4],
[60,38,16,5],
[60,38,16,6],
[60,38,16,8],
[60,38,16,9],
[60,38,16,10],
[60,38,16,11],
[60,38,16,12],
[60,38,16,15],
[60,38,17,0],
[60,38,17,1],
[60,38,17,2],
[60,38,17,3],
[60,38,17,4],
[60,38,17,5],
[60,38,17,6],
[60,38,17,8],
[60,38,17,9],
[60,38,17,10],
[60,38,17,11],
[60,38,17,12],
[60,38,17,15],
[60,38,17,16],
[60,38,18,0],
[60,38,18,1],
[60,38,18,2],
[60,38,18,3],
[60,38,18,4],
[60,38,18,5],
[60,38,18,6],
[60,38,18,8],
[60,38,18,10],
[60,38,18,11],
[60,38,18,12],
[60,38,18,16],
[60,38,18,17],
[60,38,20,0],
[60,38,20,1],
[60,38,20,2],
[60,38,20,3],
[60,38,20,4],
[60,38,20,5],
[60,38,20,6],
[60,38,20,8],
[60,38,20,9],
[60,38,20,11],
[60,38,20,12],
[60,38,20,15],
[60,38,20,16],
[60,38,20,17],
[60,38,20,18],
[60,38,21,0],
[60,38,21,1],
[60,38,21,2],
[60,38,21,4],
[60,38,21,9],
[60,38,21,10],
[60,38,21,11],
[60,38,21,12],
[60,38,21,15],
[60,38,21,16],
[60,38,21,17],
[60,38,21,20],
[60,38,22,0],
[60,38,22,1],
[60,38,22,2],
[60,38,22,3],
[60,38,22,4],
[60,38,22,5],
[60,38,22,6],
[60,38,22,8],
[60,38,22,9],
[60,38,22,11],
[60,38,22,12],
[60,38,22,15],
[60,38,22,18],
[60,38,22,21],
[60,38,23,0],
[60,38,23,1],
[60,38,23,2],
[60,38,23,3],
[60,38,23,4],
[60,38,23,5],
[60,38,23,6],
[60,38,23,8],
[60,38,23,9],
[60,38,23,10],
[60,38,23,11],
[60,38,23,12],
[60,38,23,16],
[60,38,23,17],
[60,38,23,18],
[60,38,23,20],
[60,38,23,21],
[60,38,23,22],
[60,38,24,0],
[60,38,24,1],
[60,38,24,2],
[60,38,24,3],
[60,38,24,4],
[60,38,24,5],
[60,38,24,6],
[60,38,24,8],
[60,38,24,9],
[60,38,24,10],
[60,38,24,11],
[60,38,24,12],
[60,38,24,17],
[60,38,24,18],
[60,38,24,20],
[60,38,24,21],
[60,38,24,22],
[60,38,24,23],
[60,38,26,0],
[60,38,26,1],
[60,38,26,2],
[60,38,26,3],
[60,38,26,4],
[60,38,26,5],
[60,38,26,6],
[60,38,26,8],
[60,38,26,12],
[60,38,26,16],
[60,38,26,17],
[60,38,26,20],
[60,38,26,21],
[60,38,26,22],
[60,38,26,23],
[60,38,26,24],
[60,38,27,0],
[60,38,27,1],
[60,38,27,2],
[60,38,27,3],
[60,38,27,4],
[60,38,27,5],
[60,38,27,6],
[60,38,27,11],
[60,38,27,12],
[60,38,27,15],
[60,38,27,16],
[60,38,27,17],
[60,38,27,18],
[60,38,27,21],
[60,38,27,23],
[60,38,27,24],
[60,38,29,0],
[60,38,29,1],
[60,38,29,2],
[60,38,29,3],
[60,38,29,4],
[60,38,29,6],
[60,38,29,8],
[60,38,29,9],
[60,38,29,10],
[60,38,29,15],
[60,38,29,16],
[60,38,29,17],
[60,38,29,18],
[60,38,29,20],
[60,38,29,21],
[60,38,29,22],
[60,38,30,0],
[60,38,30,1],
[60,38,30,2],
[60,38,30,5],
[60,38,30,6],
[60,38,30,8],
[60,38,30,9],
[60,38,30,10],
[60,38,30,11],
[60,38,30,12],
[60,38,30,15],
[60,38,30,16],
[60,38,30,17],
[60,38,30,18],
[60,38,30,20],
[60,38,30,21],
[60,38,31,3],
[60,38,31,4],
[60,38,31,5],
[60,38,31,6],
[60,38,31,8],
[60,38,31,9],
[60,38,31,10],
[60,38,31,11],
[60,38,31,12],
[60,38,31,15],
[60,38,31,16],
[60,38,31,17],
[60,38,31,18],
[60,38,31,20],
[60,38,32,0],
[60,38,32,1],
[60,38,32,2],
[60,38,32,3],
[60,38,32,4],
[60,38,32,5],
[60,38,32,6],
[60,38,32,8],
[60,38,32,9],
[60,38,32,10],
[60,38,32,15],
[60,38,32,16],
[60,38,32,17],
[60,38,32,18],
[60,38,33,0],
[60,38,33,1],
[60,38,33,2],
[60,38,33,3],
[60,38,33,4],
[60,38,33,5],
[60,38,33,6],
[60,38,33,8],
[60,38,33,9],
[60,38,33,10],
[60,38,33,15],
[60,38,33,16],
[60,38,33,17],
[60,38,33,18],
[60,38,35,0],
[60,38,35,1],
[60,38,35,2],
[60,38,35,3],
[60,38,35,4],
[60,38,35,5],
[60,38,35,6],
[60,38,35,8],
[60,38,35,10],
[60,38,35,11],
[60,38,35,12],
[60,38,37,0],
[60,38,37,1],
[60,38,37,2],
[60,38,37,3],
[60,38,37,5],
[60,38,37,6],
[60,38,37,8],
[60,38,37,9],
[60,38,37,10],
[60,38,37,11],
[60,38,37,12],
[60,39,4,0],
[60,39,4,1],
[60,39,4,2],
[60,39,7,0],
[60,39,7,1],
[60,39,7,2],
[60,39,7,4],
[60,39,9,0],
[60,39,9,1],
[60,39,9,2],
[60,39,9,4],
[60,39,9,7],
[60,39,10,0],
[60,39,10,1],
[60,39,10,2],
[60,39,10,4],
[60,39,10,7],
[60,39,10,9],
[60,39,11,0],
[60,39,11,1],
[60,39,11,2],
[60,39,11,4],
[60,39,11,7],
[60,39,11,9],
[60,39,11,10],
[60,39,12,0],
[60,39,12,1],
[60,39,12,2],
[60,39,12,4],
[60,39,12,7],
[60,39,12,9],
[60,39,12,10],
[60,39,15,0],
[60,39,15,1],
[60,39,15,2],
[60,39,15,4],
[60,39,15,7],
[60,39,15,10],
[60,39,15,11],
[60,39,15,12],
[60,39,16,0],
[60,39,16,1],
[60,39,16,2],
[60,39,16,4],
[60,39,16,7],
[60,39,16,9],
[60,39,16,10],
[60,39,16,11],
[60,39,16,12],
[60,39,16,15],
[60,39,17,0],
[60,39,17,1],
[60,39,17,2],
[60,39,17,4],
[60,39,17,7],
[60,39,17,9],
[60,39,17,10],
[60,39,17,11],
[60,39,17,12],
[60,39,17,15],
[60,39,17,16],
[60,39,18,0],
[60,39,18,1],
[60,39,18,2],
[60,39,18,4],
[60,39,18,7],
[60,39,18,10],
[60,39,18,11],
[60,39,18,12],
[60,39,18,16],
[60,39,18,17],
[60,39,20,0],
[60,39,20,1],
[60,39,20,2],
[60,39,20,4],
[60,39,20,7],
[60,39,20,9],
[60,39,20,11],
[60,39,20,12],
[60,39,20,15],
[60,39,20,16],
[60,39,20,17],
[60,39,22,0],
[60,39,22,1],
[60,39,22,2],
[60,39,22,4],
[60,39,22,7],
[60,39,22,9],
[60,39,22,11],
[60,39,22,12],
[60,39,22,16],
[60,39,22,17],
[60,39,22,18],
[60,39,23,0],
[60,39,23,1],
[60,39,23,2],
[60,39,23,4],
[60,39,23,7],
[60,39,23,9],
[60,39,23,10],
[60,39,23,11],
[60,39,23,12],
[60,39,23,15],
[60,39,23,16],
[60,39,23,17],
[60,39,23,18],
[60,39,23,20],
[60,39,23,22],
[60,39,24,0],
[60,39,24,1],
[60,39,24,2],
[60,39,24,4],
[60,39,24,7],
[60,39,24,9],
[60,39,24,10],
[60,39,24,11],
[60,39,24,12],
[60,39,24,15],
[60,39,24,17],
[60,39,24,18],
[60,39,24,20],
[60,39,24,22],
[60,39,24,23],
[60,39,25,0],
[60,39,25,1],
[60,39,25,2],
[60,39,25,4],
[60,39,25,9],
[60,39,25,12],
[60,39,25,15],
[60,39,25,16],
[60,39,25,17],
[60,39,25,18],
[60,39,25,20],
[60,39,25,22],
[60,39,25,23],
[60,39,25,24],
[60,39,26,0],
[60,39,26,1],
[60,39,26,2],
[60,39,26,4],
[60,39,26,7],
[60,39,26,10],
[60,39,26,11],
[60,39,26,12],
[60,39,26,16],
[60,39,26,17],
[60,39,26,20],
[60,39,26,22],
[60,39,26,23],
[60,39,26,24],
[60,39,27,0],
[60,39,27,1],
[60,39,27,2],
[60,39,27,4],
[60,39,27,9],
[60,39,27,11],
[60,39,27,12],
[60,39,27,15],
[60,39,27,16],
[60,39,27,17],
[60,39,27,18],
[60,39,27,23],
[60,39,27,24],
[60,39,28,0],
[60,39,28,1],
[60,39,28,2],
[60,39,28,4],
[60,39,28,9],
[60,39,28,10],
[60,39,28,11],
[60,39,28,12],
[60,39,28,15],
[60,39,28,16],
[60,39,28,17],
[60,39,28,18],
[60,39,28,20],
[60,39,28,22],
[60,39,29,0],
[60,39,29,1],
[60,39,29,2],
[60,39,29,7],
[60,39,29,9],
[60,39,29,10],
[60,39,29,15],
[60,39,29,16],
[60,39,29,17],
[60,39,29,18],
[60,39,29,20],
[60,39,30,4],
[60,39,30,7],
[60,39,30,9],
[60,39,30,10],
[60,39,30,11],
[60,39,30,12],
[60,39,30,15],
[60,39,30,16],
[60,39,30,17],
[60,39,30,18],
[60,39,30,20],
[60,39,31,0],
[60,39,31,1],
[60,39,31,2],
[60,39,31,4],
[60,39,31,7],
[60,39,31,9],
[60,39,31,10],
[60,39,31,11],
[60,39,31,12],
[60,39,31,15],
[60,39,31,16],
[60,39,31,17],
[60,39,31,18],
[60,39,32,0],
[60,39,32,1],
[60,39,32,2],
[60,39,32,4],
[60,39,32,7],
[60,39,32,9],
[60,39,32,10],
[60,39,32,15],
[60,39,32,16],
[60,39,32,17],
[60,39,32,18],
[60,39,33,0],
[60,39,33,1],
[60,39,33,2],
[60,39,33,4],
[60,39,33,7],
[60,39,33,9],
[60,39,33,10],
[60,39,33,15],
[60,39,33,16],
[60,39,33,17],
[60,39,34,0],
[60,39,34,1],
[60,39,34,2],
[60,39,34,4],
[60,39,34,9],
[60,39,34,10],
[60,39,34,11],
[60,39,34,12],
[60,39,34,15],
[60,39,35,0],
[60,39,35,1],
[60,39,35,2],
[60,39,35,4],
[60,39,35,7],
[60,39,35,10],
[60,39,35,11],
[60,39,35,12],
[60,39,37,0],
[60,39,37,1],
[60,39,37,2],
[60,39,37,7],
[60,39,37,9],
[60,39,37,10],
[60,39,37,11],
[60,39,38,0],
[60,39,38,1],
[60,39,38,2],
[60,39,38,4],
[60,39,38,9],
[60,40,3,0],
[60,40,3,1],
[60,40,3,2],
[60,40,4,0],
[60,40,4,1],
[60,40,4,2],
[60,40,4,3],
[60,40,5,0],
[60,40,5,1],
[60,40,5,2],
[60,40,5,4],
[60,40,6,0],
[60,40,6,1],
[60,40,6,2],
[60,40,6,4],
[60,40,7,0],
[60,40,7,1],
[60,40,7,2],
[60,40,7,3],
[60,40,7,4],
[60,40,7,5],
[60,40,7,6],
[60,40,8,0],
[60,40,8,1],
[60,40,8,2],
[60,40,8,4],
[60,40,8,7],
[60,40,10,0],
[60,40,10,1],
[60,40,10,2],
[60,40,10,3],
[60,40,10,4],
[60,40,10,5],
[60,40,10,6],
[60,40,10,7],
[60,40,10,8],
[60,40,11,0],
[60,40,11,1],
[60,40,11,2],
[60,40,11,3],
[60,40,11,4],
[60,40,11,5],
[60,40,11,6],
[60,40,11,7],
[60,40,11,8],
[60,40,11,10],
[60,40,12,0],
[60,40,12,1],
[60,40,12,2],
[60,40,12,3],
[60,40,12,4],
[60,40,12,5],
[60,40,12,6],
[60,40,12,7],
[60,40,12,8],
[60,40,12,10],
[60,40,16,0],
[60,40,16,1],
[60,40,16,2],
[60,40,16,3],
[60,40,16,4],
[60,40,16,5],
[60,40,16,6],
[60,40,16,7],
[60,40,16,8],
[60,40,16,10],
[60,40,16,11],
[60,40,16,12],
[60,40,17,0],
[60,40,17,1],
[60,40,17,2],
[60,40,17,3],
[60,40,17,4],
[60,40,17,5],
[60,40,17,6],
[60,40,17,7],
[60,40,17,8],
[60,40,17,10],
[60,40,17,11],
[60,40,17,12],
[60,40,17,16],
[60,40,20,0],
[60,40,20,1],
[60,40,20,2],
[60,40,20,3],
[60,40,20,4],
[60,40,20,5],
[60,40,20,6],
[60,40,20,7],
[60,40,20,8],
[60,40,20,11],
[60,40,20,12],
[60,40,21,0],
[60,40,21,1],
[60,40,21,2],
[60,40,21,4],
[60,40,21,7],
[60,40,21,10],
[60,40,21,11],
[60,40,21,12],
[60,40,21,16],
[60,40,21,17],
[60,40,21,20],
[60,40,22,0],
[60,40,22,1],
[60,40,22,2],
[60,40,22,3],
[60,40,22,4],
[60,40,22,5],
[60,40,22,6],
[60,40,22,7],
[60,40,22,8],
[60,40,22,11],
[60,40,22,12],
[60,40,22,16],
[60,40,22,17],
[60,40,22,21],
[60,40,23,0],
[60,40,23,1],
[60,40,23,2],
[60,40,23,3],
[60,40,23,4],
[60,40,23,5],
[60,40,23,6],
[60,40,23,7],
[60,40,23,8],
[60,40,23,10],
[60,40,23,11],
[60,40,23,16],
[60,40,23,17],
[60,40,23,20],
[60,40,23,21],
[60,40,23,22],
[60,40,24,0],
[60,40,24,1],
[60,40,24,2],
[60,40,24,3],
[60,40,24,4],
[60,40,24,5],
[60,40,24,6],
[60,40,24,7],
[60,40,24,8],
[60,40,24,10],
[60,40,24,11],
[60,40,24,17],
[60,40,24,20],
[60,40,24,21],
[60,40,24,22],
[60,40,24,23],
[60,40,25,0],
[60,40,25,1],
[60,40,25,2],
[60,40,25,3],
[60,40,25,4],
[60,40,25,5],
[60,40,25,6],
[60,40,25,10],
[60,40,25,11],
[60,40,25,12],
[60,40,25,16],
[60,40,25,17],
[60,40,25,20],
[60,40,25,21],
[60,40,25,22],
[60,40,25,23],
[60,40,25,24],
[60,40,27,0],
[60,40,27,1],
[60,40,27,2],
[60,40,27,3],
[60,40,27,4],
[60,40,27,6],
[60,40,27,7],
[60,40,27,8],
[60,40,27,11],
[60,40,27,12],
[60,40,27,16],
[60,40,27,17],
[60,40,27,21],
[60,40,28,0],
[60,40,28,1],
[60,40,28,2],
[60,40,28,5],
[60,40,28,6],
[60,40,28,8],
[60,40,28,10],
[60,40,28,11],
[60,40,28,12],
[60,40,28,16],
[60,40,28,17],
[60,40,28,20],
[60,40,28,21],
[60,40,29,3],
[60,40,29,4],
[60,40,29,5],
[60,40,29,6],
[60,40,29,7],
[60,40,29,8],
[60,40,29,10],
[60,40,29,16],
[60,40,29,17],
[60,40,29,20],
[60,40,30,0],
[60,40,30,1],
[60,40,30,2],
[60,40,30,3],
[60,40,30,4],
[60,40,30,5],
[60,40,30,6],
[60,40,30,7],
[60,40,30,8],
[60,40,30,10],
[60,40,30,11],
[60,40,30,12],
[60,40,30,16],
[60,40,30,17],
[60,40,31,0],
[60,40,31,1],
[60,40,31,2],
[60,40,31,3],
[60,40,31,4],
[60,40,31,5],
[60,40,31,6],
[60,40,31,7],
[60,40,31,8],
[60,40,31,10],
[60,40,31,11],
[60,40,31,12],
[60,40,31,16],
[60,40,31,17],
[60,40,32,0],
[60,40,32,1],
[60,40,32,2],
[60,40,32,3],
[60,40,32,4],
[60,40,32,5],
[60,40,32,6],
[60,40,32,7],
[60,40,32,8],
[60,40,32,10],
[60,40,32,16],
[60,40,32,17],
[60,40,33,0],
[60,40,33,1],
[60,40,33,2],
[60,40,33,3],
[60,40,33,4],
[60,40,33,5],
[60,40,33,6],
[60,40,33,7],
[60,40,33,8],
[60,40,33,10],
[60,40,34,0],
[60,40,34,1],
[60,40,34,2],
[60,40,34,3],
[60,40,34,4],
[60,40,34,5],
[60,40,34,6],
[60,40,34,8],
[60,40,34,10],
[60,40,34,11],
[60,40,34,12],
[60,40,37,0],
[60,40,37,1],
[60,40,37,2],
[60,40,37,3],
[60,40,37,5],
[60,40,37,6],
[60,40,37,7],
[60,40,37,8],
[60,40,38,0],
[60,40,38,1],
[60,40,38,2],
[60,40,38,3],
[60,40,38,4],
[60,40,38,5],
[60,40,38,6],
[60,40,39,0],
[60,40,39,1],
[60,40,39,2],
[60,40,39,4],
[60,41,3,0],
[60,41,3,1],
[60,41,3,2],
[60,41,4,0],
[60,41,4,1],
[60,41,4,2],
[60,41,4,3],
[60,41,5,0],
[60,41,5,1],
[60,41,5,2],
[60,41,5,4],
[60,41,6,0],
[60,41,6,1],
[60,41,6,2],
[60,41,6,4],
[60,41,7,0],
[60,41,7,1],
[60,41,7,2],
[60,41,7,3],
[60,41,7,4],
[60,41,7,5],
[60,41,7,6],
[60,41,8,0],
[60,41,8,1],
[60,41,8,2],
[60,41,8,4],
[60,41,8,7],
[60,41,10,0],
[60,41,10,1],
[60,41,10,2],
[60,41,10,3],
[60,41,10,4],
[60,41,10,5],
[60,41,10,6],
[60,41,10,7],
[60,41,10,8],
[60,41,11,0],
[60,41,11,1],
[60,41,11,2],
[60,41,11,3],
[60,41,11,4],
[60,41,11,5],
[60,41,11,6],
[60,41,11,7],
[60,41,11,8],
[60,41,11,10],
[60,41,12,0],
[60,41,12,1],
[60,41,12,2],
[60,41,12,3],
[60,41,12,4],
[60,41,12,5],
[60,41,12,6],
[60,41,12,7],
[60,41,12,8],
[60,41,12,10],
[60,41,16,0],
[60,41,16,1],
[60,41,16,2],
[60,41,16,3],
[60,41,16,4],
[60,41,16,5],
[60,41,16,6],
[60,41,16,7],
[60,41,16,8],
[60,41,16,10],
[60,41,16,11],
[60,41,16,12],
[60,41,17,0],
[60,41,17,1],
[60,41,17,2],
[60,41,17,3],
[60,41,17,4],
[60,41,17,5],
[60,41,17,6],
[60,41,17,7],
[60,41,17,8],
[60,41,17,10],
[60,41,17,11],
[60,41,17,12],
[60,41,17,16],
[60,41,20,0],
[60,41,20,1],
[60,41,20,2],
[60,41,20,3],
[60,41,20,4],
[60,41,20,5],
[60,41,20,6],
[60,41,20,7],
[60,41,20,8],
[60,41,20,11],
[60,41,20,12],
[60,41,21,0],
[60,41,21,1],
[60,41,21,2],
[60,41,21,4],
[60,41,21,7],
[60,41,21,10],
[60,41,21,11],
[60,41,21,12],
[60,41,21,16],
[60,41,21,17],
[60,41,21,20],
[60,41,22,0],
[60,41,22,1],
[60,41,22,2],
[60,41,22,3],
[60,41,22,4],
[60,41,22,5],
[60,41,22,6],
[60,41,22,7],
[60,41,22,8],
[60,41,22,11],
[60,41,22,12],
[60,41,22,16],
[60,41,22,17],
[60,41,22,21],
[60,41,23,0],
[60,41,23,1],
[60,41,23,2],
[60,41,23,3],
[60,41,23,4],
[60,41,23,5],
[60,41,23,6],
[60,41,23,7],
[60,41,23,8],
[60,41,23,10],
[60,41,23,11],
[60,41,23,16],
[60,41,23,17],
[60,41,23,20],
[60,41,23,21],
[60,41,23,22],
[60,41,24,0],
[60,41,24,1],
[60,41,24,2],
[60,41,24,3],
[60,41,24,4],
[60,41,24,5],
[60,41,24,6],
[60,41,24,7],
[60,41,24,8],
[60,41,24,10],
[60,41,24,11],
[60,41,24,17],
[60,41,24,20],
[60,41,24,21],
[60,41,24,22],
[60,41,24,23],
[60,41,25,0],
[60,41,25,1],
[60,41,25,2],
[60,41,25,3],
[60,41,25,4],
[60,41,25,5],
[60,41,25,6],
[60,41,25,10],
[60,41,25,11],
[60,41,25,12],
[60,41,25,16],
[60,41,25,17],
[60,41,25,20],
[60,41,25,21],
[60,41,25,22],
[60,41,25,23],
[60,41,25,24],
[60,41,27,0],
[60,41,27,1],
[60,41,27,2],
[60,41,27,3],
[60,41,27,4],
[60,41,27,6],
[60,41,27,7],
[60,41,27,8],
[60,41,27,11],
[60,41,27,12],
[60,41,27,16],
[60,41,27,17],
[60,41,27,21],
[60,41,28,0],
[60,41,28,1],
[60,41,28,2],
[60,41,28,5],
[60,41,28,6],
[60,41,28,8],
[60,41,28,10],
[60,41,28,11],
[60,41,28,12],
[60,41,28,16],
[60,41,28,17],
[60,41,28,20],
[60,41,28,21],
[60,41,29,3],
[60,41,29,4],
[60,41,29,5],
[60,41,29,6],
[60,41,29,7],
[60,41,29,8],
[60,41,29,10],
[60,41,29,16],
[60,41,29,17],
[60,41,29,20],
[60,41,30,0],
[60,41,30,1],
[60,41,30,2],
[60,41,30,3],
[60,41,30,4],
[60,41,30,5],
[60,41,30,6],
[60,41,30,7],
[60,41,30,8],
[60,41,30,10],
[60,41,30,11],
[60,41,30,12],
[60,41,30,16],
[60,41,30,17],
[60,41,31,0],
[60,41,31,1],
[60,41,31,2],
[60,41,31,3],
[60,41,31,4],
[60,41,31,5],
[60,41,31,6],
[60,41,31,7],
[60,41,31,8],
[60,41,31,10],
[60,41,31,11],
[60,41,31,12],
[60,41,31,16],
[60,41,31,17],
[60,41,32,0],
[60,41,32,1],
[60,41,32,2],
[60,41,32,3],
[60,41,32,4],
[60,41,32,5],
[60,41,32,6],
[60,41,32,7],
[60,41,32,8],
[60,41,32,10],
[60,41,32,16],
[60,41,32,17],
[60,41,33,0],
[60,41,33,1],
[60,41,33,2],
[60,41,33,3],
[60,41,33,4],
[60,41,33,5],
[60,41,33,6],
[60,41,33,7],
[60,41,33,8],
[60,41,33,10],
[60,41,34,0],
[60,41,34,1],
[60,41,34,2],
[60,41,34,3],
[60,41,34,4],
[60,41,34,5],
[60,41,34,6],
[60,41,34,8],
[60,41,34,10],
[60,41,34,11],
[60,41,34,12],
[60,41,37,0],
[60,41,37,1],
[60,41,37,2],
[60,41,37,3],
[60,41,37,5],
[60,41,37,6],
[60,41,37,7],
[60,41,37,8],
[60,41,38,0],
[60,41,38,1],
[60,41,38,2],
[60,41,38,3],
[60,41,38,4],
[60,41,38,5],
[60,41,38,6],
[60,41,39,0],
[60,41,39,1],
[60,41,39,2],
[60,41,39,4],
[60,42,3,0],
[60,42,3,1],
[60,42,3,2],
[60,42,4,0],
[60,42,4,1],
[60,42,4,2],
[60,42,4,3],
[60,42,5,0],
[60,42,5,1],
[60,42,5,2],
[60,42,5,4],
[60,42,6,0],
[60,42,6,1],
[60,42,6,2],
[60,42,6,4],
[60,42,7,0],
[60,42,7,1],
[60,42,7,2],
[60,42,7,3],
[60,42,7,4],
[60,42,7,5],
[60,42,7,6],
[60,42,8,0],
[60,42,8,1],
[60,42,8,2],
[60,42,8,4],
[60,42,8,7],
[60,42,9,0],
[60,42,9,1],
[60,42,9,2],
[60,42,9,3],
[60,42,9,4],
[60,42,9,5],
[60,42,9,6],
[60,42,9,7],
[60,42,9,8],
[60,42,11,0],
[60,42,11,1],
[60,42,11,2],
[60,42,11,3],
[60,42,11,4],
[60,42,11,5],
[60,42,11,6],
[60,42,11,7],
[60,42,11,8],
[60,42,11,9],
[60,42,12,0],
[60,42,12,1],
[60,42,12,2],
[60,42,12,3],
[60,42,12,4],
[60,42,12,5],
[60,42,12,6],
[60,42,12,7],
[60,42,12,8],
[60,42,12,9],
[60,42,15,0],
[60,42,15,1],
[60,42,15,2],
[60,42,15,3],
[60,42,15,4],
[60,42,15,5],
[60,42,15,6],
[60,42,15,7],
[60,42,15,8],
[60,42,15,11],
[60,42,15,12],
[60,42,16,0],
[60,42,16,1],
[60,42,16,2],
[60,42,16,3],
[60,42,16,4],
[60,42,16,5],
[60,42,16,6],
[60,42,16,7],
[60,42,16,8],
[60,42,16,9],
[60,42,16,11],
[60,42,16,12],
[60,42,16,15],
[60,42,17,0],
[60,42,17,1],
[60,42,17,2],
[60,42,17,3],
[60,42,17,4],
[60,42,17,5],
[60,42,17,6],
[60,42,17,7],
[60,42,17,8],
[60,42,17,9],
[60,42,17,11],
[60,42,17,12],
[60,42,17,15],
[60,42,17,16],
[60,42,18,0],
[60,42,18,1],
[60,42,18,2],
[60,42,18,3],
[60,42,18,4],
[60,42,18,5],
[60,42,18,6],
[60,42,18,7],
[60,42,18,8],
[60,42,18,11],
[60,42,18,12],
[60,42,18,16],
[60,42,18,17],
[60,42,21,0],
[60,42,21,1],
[60,42,21,2],
[60,42,21,4],
[60,42,21,7],
[60,42,21,9],
[60,42,21,11],
[60,42,21,12],
[60,42,21,15],
[60,42,21,16],
[60,42,21,17],
[60,42,21,18],
[60,42,23,0],
[60,42,23,1],
[60,42,23,2],
[60,42,23,3],
[60,42,23,4],
[60,42,23,5],
[60,42,23,6],
[60,42,23,7],
[60,42,23,8],
[60,42,23,9],
[60,42,23,12],
[60,42,23,15],
[60,42,23,16],
[60,42,23,17],
[60,42,23,18],
[60,42,23,21],
[60,42,24,0],
[60,42,24,1],
[60,42,24,2],
[60,42,24,3],
[60,42,24,4],
[60,42,24,5],
[60,42,24,6],
[60,42,24,7],
[60,42,24,8],
[60,42,24,9],
[60,42,24,12],
[60,42,24,15],
[60,42,24,17],
[60,42,24,18],
[60,42,24,21],
[60,42,24,23],
[60,42,25,0],
[60,42,25,1],
[60,42,25,2],
[60,42,25,3],
[60,42,25,4],
[60,42,25,5],
[60,42,25,8],
[60,42,25,9],
[60,42,25,11],
[60,42,25,12],
[60,42,25,15],
[60,42,25,16],
[60,42,25,17],
[60,42,25,18],
[60,42,25,21],
[60,42,25,23],
[60,42,25,24],
[60,42,26,0],
[60,42,26,1],
[60,42,26,2],
[60,42,26,3],
[60,42,26,4],
[60,42,26,6],
[60,42,26,7],
[60,42,26,8],
[60,42,26,11],
[60,42,26,12],
[60,42,26,16],
[60,42,26,17],
[60,42,26,21],
[60,42,28,3],
[60,42,28,4],
[60,42,28,5],
[60,42,28,6],
[60,42,28,8],
[60,42,28,9],
[60,42,28,11],
[60,42,28,12],
[60,42,28,15],
[60,42,28,16],
[60,42,28,17],
[60,42,28,18],
[60,42,29,0],
[60,42,29,1],
[60,42,29,2],
[60,42,29,3],
[60,42,29,4],
[60,42,29,5],
[60,42,29,6],
[60,42,29,7],
[60,42,29,8],
[60,42,29,9],
[60,42,29,15],
[60,42,29,16],
[60,42,29,17],
[60,42,29,18],
[60,42,30,0],
[60,42,30,1],
[60,42,30,2],
[60,42,30,3],
[60,42,30,4],
[60,42,30,5],
[60,42,30,6],
[60,42,30,7],
[60,42,30,8],
[60,42,30,9],
[60,42,30,11],
[60,42,30,12],
[60,42,30,15],
[60,42,30,16],
[60,42,30,17],
[60,42,30,18],
[60,42,31,0],
[60,42,31,1],
[60,42,31,2],
[60,42,31,3],
[60,42,31,4],
[60,42,31,5],
[60,42,31,6],
[60,42,31,7],
[60,42,31,8],
[60,42,31,9],
[60,42,31,11],
[60,42,31,12],
[60,42,31,15],
[60,42,31,16],
[60,42,31,17],
[60,42,32,0],
[60,42,32,1],
[60,42,32,2],
[60,42,32,3],
[60,42,32,4],
[60,42,32,5],
[60,42,32,6],
[60,42,32,7],
[60,42,32,8],
[60,42,32,9],
[60,42,32,15],
[60,42,33,0],
[60,42,33,1],
[60,42,33,2],
[60,42,33,3],
[60,42,33,4],
[60,42,33,5],
[60,42,33,6],
[60,42,33,7],
[60,42,33,8],
[60,42,33,9],
[60,42,34,0],
[60,42,34,1],
[60,42,34,2],
[60,42,34,3],
[60,42,34,4],
[60,42,34,5],
[60,42,34,6],
[60,42,34,8],
[60,42,34,9],
[60,42,34,11],
[60,42,34,12],
[60,42,35,0],
[60,42,35,1],
[60,42,35,2],
[60,42,35,3],
[60,42,35,4],
[60,42,35,5],
[60,42,35,6],
[60,42,35,7],
[60,42,35,8],
[60,42,35,11],
[60,42,37,0],
[60,42,37,1],
[60,42,37,2],
[60,42,37,3],
[60,42,37,5],
[60,42,37,6],
[60,42,37,7],
[60,42,38,0],
[60,42,38,1],
[60,42,38,2],
[60,42,38,3],
[60,42,38,4],
[60,42,38,5],
[60,42,39,0],
[60,42,39,1],
[60,42,39,2],
[60,42,39,4],
[60,42,40,0],
[60,42,40,1],
[60,42,40,2],
[60,42,41,0],
[60,42,41,1],
[60,42,41,2],
[60,43,3,0],
[60,43,3,1],
[60,43,3,2],
[60,43,4,0],
[60,43,4,1],
[60,43,4,2],
[60,43,4,3],
[60,43,5,0],
[60,43,5,1],
[60,43,5,2],
[60,43,5,4],
[60,43,6,0],
[60,43,6,1],
[60,43,6,2],
[60,43,6,4],
[60,43,8,0],
[60,43,8,1],
[60,43,8,2],
[60,43,8,4],
[60,43,9,0],
[60,43,9,1],
[60,43,9,2],
[60,43,9,3],
[60,43,9,4],
[60,43,9,5],
[60,43,9,6],
[60,43,9,8],
[60,43,10,0],
[60,43,10,1],
[60,43,10,2],
[60,43,10,3],
[60,43,10,4],
[60,43,10,5],
[60,43,10,6],
[60,43,10,8],
[60,43,10,9],
[60,43,11,0],
[60,43,11,1],
[60,43,11,2],
[60,43,11,3],
[60,43,11,4],
[60,43,11,5],
[60,43,11,6],
[60,43,11,8],
[60,43,11,9],
[60,43,11,10],
[60,43,12,0],
[60,43,12,1],
[60,43,12,2],
[60,43,12,3],
[60,43,12,4],
[60,43,12,5],
[60,43,12,6],
[60,43,12,8],
[60,43,12,9],
[60,43,12,10],
[60,43,15,0],
[60,43,15,1],
[60,43,15,2],
[60,43,15,3],
[60,43,15,4],
[60,43,15,5],
[60,43,15,6],
[60,43,15,8],
[60,43,15,10],
[60,43,15,11],
[60,43,15,12],
[60,43,16,0],
[60,43,16,1],
[60,43,16,2],
[60,43,16,3],
[60,43,16,4],
[60,43,16,5],
[60,43,16,6],
[60,43,16,8],
[60,43,16,9],
[60,43,16,10],
[60,43,16,11],
[60,43,16,12],
[60,43,16,15],
[60,43,17,0],
[60,43,17,1],
[60,43,17,2],
[60,43,17,3],
[60,43,17,4],
[60,43,17,5],
[60,43,17,6],
[60,43,17,8],
[60,43,17,9],
[60,43,17,10],
[60,43,17,11],
[60,43,17,12],
[60,43,17,15],
[60,43,17,16],
[60,43,18,0],
[60,43,18,1],
[60,43,18,2],
[60,43,18,3],
[60,43,18,4],
[60,43,18,5],
[60,43,18,6],
[60,43,18,8],
[60,43,18,10],
[60,43,18,11],
[60,43,18,12],
[60,43,20,0],
[60,43,20,1],
[60,43,20,2],
[60,43,20,3],
[60,43,20,4],
[60,43,20,5],
[60,43,20,6],
[60,43,20,8],
[60,43,20,9],
[60,43,20,11],
[60,43,20,12],
[60,43,20,15],
[60,43,20,16],
[60,43,20,17],
[60,43,20,18],
[60,43,21,0],
[60,43,21,1],
[60,43,21,2],
[60,43,21,4],
[60,43,21,9],
[60,43,21,10],
[60,43,21,11],
[60,43,21,15],
[60,43,21,16],
[60,43,21,17],
[60,43,21,18],
[60,43,21,20],
[60,43,22,0],
[60,43,22,1],
[60,43,22,2],
[60,43,22,3],
[60,43,22,4],
[60,43,22,5],
[60,43,22,6],
[60,43,22,8],
[60,43,22,9],
[60,43,22,12],
[60,43,22,15],
[60,43,22,16],
[60,43,22,17],
[60,43,22,18],
[60,43,22,21],
[60,43,23,0],
[60,43,23,1],
[60,43,23,2],
[60,43,23,3],
[60,43,23,4],
[60,43,23,5],
[60,43,23,6],
[60,43,23,10],
[60,43,23,11],
[60,43,23,12],
[60,43,23,15],
[60,43,23,16],
[60,43,23,17],
[60,43,23,18],
[60,43,23,20],
[60,43,23,21],
[60,43,23,22],
[60,43,24,0],
[60,43,24,1],
[60,43,24,2],
[60,43,24,3],
[60,43,24,4],
[60,43,24,5],
[60,43,24,6],
[60,43,24,10],
[60,43,24,11],
[60,43,24,12],
[60,43,24,15],
[60,43,24,17],
[60,43,24,18],
[60,43,24,20],
[60,43,24,21],
[60,43,24,22],
[60,43,24,23],
[60,43,26,0],
[60,43,26,1],
[60,43,26,2],
[60,43,26,5],
[60,43,26,6],
[60,43,26,8],
[60,43,26,10],
[60,43,26,11],
[60,43,26,12],
[60,43,26,16],
[60,43,26,17],
[60,43,26,20],
[60,43,26,21],
[60,43,27,3],
[60,43,27,4],
[60,43,27,5],
[60,43,27,6],
[60,43,27,8],
[60,43,27,9],
[60,43,27,11],
[60,43,27,12],
[60,43,27,15],
[60,43,27,16],
[60,43,27,17],
[60,43,27,18],
[60,43,29,0],
[60,43,29,1],
[60,43,29,2],
[60,43,29,3],
[60,43,29,4],
[60,43,29,5],
[60,43,29,6],
[60,43,29,8],
[60,43,29,9],
[60,43,29,10],
[60,43,29,15],
[60,43,29,16],
[60,43,29,17],
[60,43,29,18],
[60,43,30,0],
[60,43,30,1],
[60,43,30,2],
[60,43,30,3],
[60,43,30,4],
[60,43,30,5],
[60,43,30,6],
[60,43,30,8],
[60,43,30,9],
[60,43,30,10],
[60,43,30,11],
[60,43,30,12],
[60,43,30,15],
[60,43,30,16],
[60,43,30,17],
[60,43,31,0],
[60,43,31,1],
[60,43,31,2],
[60,43,31,3],
[60,43,31,4],
[60,43,31,5],
[60,43,31,6],
[60,43,31,8],
[60,43,31,9],
[60,43,31,10],
[60,43,31,11],
[60,43,31,12],
[60,43,31,15],
[60,43,32,0],
[60,43,32,1],
[60,43,32,2],
[60,43,32,3],
[60,43,32,4],
[60,43,32,5],
[60,43,32,6],
[60,43,32,8],
[60,43,32,9],
[60,43,32,10],
[60,43,33,0],
[60,43,33,1],
[60,43,33,2],
[60,43,33,3],
[60,43,33,4],
[60,43,33,5],
[60,43,33,6],
[60,43,33,8],
[60,43,33,9],
[60,43,33,10],
[60,43,35,0],
[60,43,35,1],
[60,43,35,2],
[60,43,35,3],
[60,43,35,4],
[60,43,35,5],
[60,43,35,6],
[60,43,35,8],
[60,43,37,0],
[60,43,37,1],
[60,43,37,2],
[60,43,37,3],
[60,43,37,5],
[60,43,39,0],
[60,43,39,1],
[60,43,39,2],
[60,44,3,0],
[60,44,3,1],
[60,44,3,2],
[60,44,4,0],
[60,44,4,1],
[60,44,4,2],
[60,44,4,3],
[60,44,5,0],
[60,44,5,1],
[60,44,5,2],
[60,44,5,4],
[60,44,6,0],
[60,44,6,1],
[60,44,6,2],
[60,44,6,4],
[60,44,7,0],
[60,44,7,1],
[60,44,7,2],
[60,44,7,3],
[60,44,7,4],
[60,44,7,5],
[60,44,7,6],
[60,44,8,0],
[60,44,8,1],
[60,44,8,2],
[60,44,8,4],
[60,44,8,7],
[60,44,9,0],
[60,44,9,1],
[60,44,9,2],
[60,44,9,3],
[60,44,9,4],
[60,44,9,5],
[60,44,9,6],
[60,44,9,7],
[60,44,9,8],
[60,44,10,0],
[60,44,10,1],
[60,44,10,2],
[60,44,10,3],
[60,44,10,4],
[60,44,10,5],
[60,44,10,6],
[60,44,10,7],
[60,44,10,8],
[60,44,10,9],
[60,44,11,0],
[60,44,11,1],
[60,44,11,2],
[60,44,11,3],
[60,44,11,4],
[60,44,11,5],
[60,44,11,6],
[60,44,11,7],
[60,44,11,8],
[60,44,11,9],
[60,44,11,10],
[60,44,12,0],
[60,44,12,1],
[60,44,12,2],
[60,44,12,3],
[60,44,12,4],
[60,44,12,5],
[60,44,12,6],
[60,44,12,7],
[60,44,12,8],
[60,44,12,9],
[60,44,12,10],
[60,44,15,0],
[60,44,15,1],
[60,44,15,2],
[60,44,15,3],
[60,44,15,4],
[60,44,15,5],
[60,44,15,6],
[60,44,15,7],
[60,44,15,8],
[60,44,15,10],
[60,44,15,11],
[60,44,15,12],
[60,44,16,0],
[60,44,16,1],
[60,44,16,2],
[60,44,16,3],
[60,44,16,4],
[60,44,16,5],
[60,44,16,6],
[60,44,16,7],
[60,44,16,8],
[60,44,16,9],
[60,44,16,10],
[60,44,16,11],
[60,44,16,12],
[60,44,16,15],
[60,44,18,0],
[60,44,18,1],
[60,44,18,2],
[60,44,18,3],
[60,44,18,4],
[60,44,18,5],
[60,44,18,6],
[60,44,18,7],
[60,44,18,8],
[60,44,18,10],
[60,44,18,11],
[60,44,18,12],
[60,44,18,16],
[60,44,20,0],
[60,44,20,1],
[60,44,20,2],
[60,44,20,3],
[60,44,20,4],
[60,44,20,5],
[60,44,20,6],
[60,44,20,7],
[60,44,20,8],
[60,44,20,9],
[60,44,20,11],
[60,44,20,15],
[60,44,20,16],
[60,44,20,18],
[60,44,21,0],
[60,44,21,1],
[60,44,21,2],
[60,44,21,4],
[60,44,21,7],
[60,44,21,9],
[60,44,21,12],
[60,44,21,15],
[60,44,21,16],
[60,44,21,18],
[60,44,21,20],
[60,44,22,0],
[60,44,22,1],
[60,44,22,2],
[60,44,22,3],
[60,44,22,4],
[60,44,22,5],
[60,44,22,6],
[60,44,22,7],
[60,44,22,11],
[60,44,22,12],
[60,44,22,15],
[60,44,22,16],
[60,44,22,18],
[60,44,22,21],
[60,44,23,0],
[60,44,23,1],
[60,44,23,2],
[60,44,23,3],
[60,44,23,4],
[60,44,23,5],
[60,44,23,8],
[60,44,23,9],
[60,44,23,10],
[60,44,23,11],
[60,44,23,12],
[60,44,23,15],
[60,44,23,16],
[60,44,23,18],
[60,44,23,20],
[60,44,23,21],
[60,44,23,22],
[60,44,24,0],
[60,44,24,1],
[60,44,24,2],
[60,44,24,3],
[60,44,24,4],
[60,44,24,5],
[60,44,24,8],
[60,44,24,9],
[60,44,24,10],
[60,44,24,11],
[60,44,24,12],
[60,44,24,15],
[60,44,24,18],
[60,44,24,20],
[60,44,24,21],
[60,44,24,22],
[60,44,24,23],
[60,44,25,0],
[60,44,25,1],
[60,44,25,2],
[60,44,25,5],
[60,44,25,6],
[60,44,25,8],
[60,44,25,9],
[60,44,25,10],
[60,44,25,11],
[60,44,25,12],
[60,44,25,15],
[60,44,25,16],
[60,44,25,18],
[60,44,25,20],
[60,44,25,21],
[60,44,26,3],
[60,44,26,4],
[60,44,26,5],
[60,44,26,6],
[60,44,26,7],
[60,44,26,8],
[60,44,26,10],
[60,44,26,11],
[60,44,26,12],
[60,44,26,16],
[60,44,26,20],
[60,44,27,0],
[60,44,27,1],
[60,44,27,2],
[60,44,27,3],
[60,44,27,4],
[60,44,27,5],
[60,44,27,6],
[60,44,27,7],
[60,44,27,8],
[60,44,27,9],
[60,44,27,11],
[60,44,27,12],
[60,44,27,15],
[60,44,27,16],
[60,44,27,18],
[60,44,28,0],
[60,44,28,1],
[60,44,28,2],
[60,44,28,3],
[60,44,28,4],
[60,44,28,5],
[60,44,28,6],
[60,44,28,8],
[60,44,28,9],
[60,44,28,10],
[60,44,28,11],
[60,44,28,12],
[60,44,28,15],
[60,44,28,16],
[60,44,28,18],
[60,44,29,0],
[60,44,29,1],
[60,44,29,2],
[60,44,29,3],
[60,44,29,4],
[60,44,29,5],
[60,44,29,6],
[60,44,29,7],
[60,44,29,8],
[60,44,29,9],
[60,44,29,10],
[60,44,29,15],
[60,44,29,16],
[60,44,30,0],
[60,44,30,1],
[60,44,30,2],
[60,44,30,3],
[60,44,30,4],
[60,44,30,5],
[60,44,30,6],
[60,44,30,7],
[60,44,30,8],
[60,44,30,9],
[60,44,30,10],
[60,44,30,11],
[60,44,30,12],
[60,44,30,15],
[60,44,31,0],
[60,44,31,1],
[60,44,31,2],
[60,44,31,3],
[60,44,31,4],
[60,44,31,5],
[60,44,31,6],
[60,44,31,7],
[60,44,31,8],
[60,44,31,9],
[60,44,31,10],
[60,44,31,11],
[60,44,31,12],
[60,44,32,0],
[60,44,32,1],
[60,44,32,2],
[60,44,32,3],
[60,44,32,4],
[60,44,32,5],
[60,44,32,6],
[60,44,32,7],
[60,44,32,8],
[60,44,32,9],
[60,44,32,10],
[60,44,33,0],
[60,44,33,1],
[60,44,33,2],
[60,44,33,3],
[60,44,33,4],
[60,44,33,5],
[60,44,33,6],
[60,44,33,7],
[60,44,33,8],
[60,44,33,9],
[60,44,33,10],
[60,44,34,0],
[60,44,34,1],
[60,44,34,2],
[60,44,34,3],
[60,44,34,4],
[60,44,34,5],
[60,44,34,6],
[60,44,34,8],
[60,44,34,9],
[60,44,35,0],
[60,44,35,1],
[60,44,35,2],
[60,44,35,3],
[60,44,35,4],
[60,44,35,5],
[60,44,35,6],
[60,44,35,7],
[60,44,37,0],
[60,44,37,1],
[60,44,37,2],
[60,44,37,3],
[60,44,38,0],
[60,44,38,1],
[60,44,38,2],
[60,45,3,0],
[60,45,3,1],
[60,45,3,2],
[60,45,4,0],
[60,45,4,1],
[60,45,4,2],
[60,45,4,3],
[60,45,5,0],
[60,45,5,1],
[60,45,5,2],
[60,45,5,4],
[60,45,6,0],
[60,45,6,1],
[60,45,6,2],
[60,45,6,4],
[60,45,7,0],
[60,45,7,1],
[60,45,7,2],
[60,45,7,3],
[60,45,7,4],
[60,45,7,5],
[60,45,7,6],
[60,45,8,0],
[60,45,8,1],
[60,45,8,2],
[60,45,8,4],
[60,45,8,7],
[60,45,10,0],
[60,45,10,1],
[60,45,10,2],
[60,45,10,3],
[60,45,10,4],
[60,45,10,5],
[60,45,10,6],
[60,45,10,7],
[60,45,10,8],
[60,45,11,0],
[60,45,11,1],
[60,45,11,2],
[60,45,11,3],
[60,45,11,4],
[60,45,11,5],
[60,45,11,6],
[60,45,11,7],
[60,45,11,8],
[60,45,11,10],
[60,45,12,0],
[60,45,12,1],
[60,45,12,2],
[60,45,12,3],
[60,45,12,4],
[60,45,12,5],
[60,45,12,6],
[60,45,12,7],
[60,45,12,8],
[60,45,12,10],
[60,45,16,0],
[60,45,16,1],
[60,45,16,2],
[60,45,16,3],
[60,45,16,4],
[60,45,16,5],
[60,45,16,6],
[60,45,16,7],
[60,45,16,8],
[60,45,16,10],
[60,45,16,11],
[60,45,16,12],
[60,45,17,0],
[60,45,17,1],
[60,45,17,2],
[60,45,17,3],
[60,45,17,4],
[60,45,17,5],
[60,45,17,6],
[60,45,17,7],
[60,45,17,8],
[60,45,17,10],
[60,45,17,11],
[60,45,17,12],
[60,45,17,16],
[60,45,20,0],
[60,45,20,1],
[60,45,20,2],
[60,45,20,3],
[60,45,20,4],
[60,45,20,5],
[60,45,20,6],
[60,45,20,7],
[60,45,20,8],
[60,45,20,12],
[60,45,20,16],
[60,45,20,17],
[60,45,21,0],
[60,45,21,1],
[60,45,21,2],
[60,45,21,4],
[60,45,21,7],
[60,45,21,10],
[60,45,21,11],
[60,45,21,12],
[60,45,21,16],
[60,45,21,17],
[60,45,21,20],
[60,45,22,0],
[60,45,22,1],
[60,45,22,2],
[60,45,22,3],
[60,45,22,4],
[60,45,22,5],
[60,45,22,8],
[60,45,22,11],
[60,45,22,12],
[60,45,22,16],
[60,45,22,17],
[60,45,22,21],
[60,45,23,0],
[60,45,23,1],
[60,45,23,2],
[60,45,23,3],
[60,45,23,4],
[60,45,23,6],
[60,45,23,7],
[60,45,23,8],
[60,45,23,10],
[60,45,23,11],
[60,45,23,12],
[60,45,23,16],
[60,45,23,17],
[60,45,23,20],
[60,45,23,21],
[60,45,23,22],
[60,45,24,0],
[60,45,24,1],
[60,45,24,2],
[60,45,24,3],
[60,45,24,4],
[60,45,24,6],
[60,45,24,7],
[60,45,24,8],
[60,45,24,10],
[60,45,24,11],
[60,45,24,12],
[60,45,24,17],
[60,45,24,20],
[60,45,24,21],
[60,45,24,22],
[60,45,25,3],
[60,45,25,4],
[60,45,25,5],
[60,45,25,6],
[60,45,25,8],
[60,45,25,10],
[60,45,25,11],
[60,45,25,12],
[60,45,25,16],
[60,45,25,17],
[60,45,25,20],
[60,45,27,0],
[60,45,27,1],
[60,45,27,2],
[60,45,27,3],
[60,45,27,4],
[60,45,27,5],
[60,45,27,6],
[60,45,27,7],
[60,45,27,8],
[60,45,27,11],
[60,45,27,12],
[60,45,27,16],
[60,45,27,17],
[60,45,28,0],
[60,45,28,1],
[60,45,28,2],
[60,45,28,3],
[60,45,28,4],
[60,45,28,5],
[60,45,28,6],
[60,45,28,8],
[60,45,28,10],
[60,45,28,11],
[60,45,28,12],
[60,45,28,16],
[60,45,28,17],
[60,45,29,0],
[60,45,29,1],
[60,45,29,2],
[60,45,29,3],
[60,45,29,4],
[60,45,29,5],
[60,45,29,6],
[60,45,29,7],
[60,45,29,8],
[60,45,29,10],
[60,45,30,0],
[60,45,30,1],
[60,45,30,2],
[60,45,30,3],
[60,45,30,4],
[60,45,30,5],
[60,45,30,6],
[60,45,30,7],
[60,45,30,8],
[60,45,30,10],
[60,45,30,11],
[60,45,30,12],
[60,45,31,0],
[60,45,31,1],
[60,45,31,2],
[60,45,31,3],
[60,45,31,4],
[60,45,31,5],
[60,45,31,6],
[60,45,31,7],
[60,45,31,8],
[60,45,31,10],
[60,45,31,11],
[60,45,31,12],
[60,45,32,0],
[60,45,32,1],
[60,45,32,2],
[60,45,32,3],
[60,45,32,4],
[60,45,32,5],
[60,45,32,6],
[60,45,32,7],
[60,45,32,8],
[60,45,32,10],
[60,45,33,0],
[60,45,33,1],
[60,45,33,2],
[60,45,33,3],
[60,45,33,4],
[60,45,33,5],
[60,45,33,6],
[60,45,33,7],
[60,45,33,8],
[60,45,34,0],
[60,45,34,1],
[60,45,34,2],
[60,45,34,3],
[60,45,34,4],
[60,45,34,5],
[60,45,34,6],
[60,45,37,0],
[60,45,37,1],
[60,45,37,2],
[60,46,3,0],
[60,46,3,1],
[60,46,3,2],
[60,46,4,0],
[60,46,4,1],
[60,46,4,2],
[60,46,4,3],
[60,46,5,0],
[60,46,5,1],
[60,46,5,2],
[60,46,5,4],
[60,46,6,0],
[60,46,6,1],
[60,46,6,2],
[60,46,6,4],
[60,46,7,0],
[60,46,7,1],
[60,46,7,2],
[60,46,7,3],
[60,46,7,4],
[60,46,7,5],
[60,46,7,6],
[60,46,8,0],
[60,46,8,1],
[60,46,8,2],
[60,46,8,4],
[60,46,8,7],
[60,46,10,0],
[60,46,10,1],
[60,46,10,2],
[60,46,10,3],
[60,46,10,4],
[60,46,10,5],
[60,46,10,6],
[60,46,10,7],
[60,46,10,8],
[60,46,11,0],
[60,46,11,1],
[60,46,11,2],
[60,46,11,3],
[60,46,11,4],
[60,46,11,5],
[60,46,11,6],
[60,46,11,7],
[60,46,11,8],
[60,46,11,10],
[60,46,12,0],
[60,46,12,1],
[60,46,12,2],
[60,46,12,3],
[60,46,12,4],
[60,46,12,5],
[60,46,12,6],
[60,46,12,7],
[60,46,12,8],
[60,46,12,10],
[60,46,16,0],
[60,46,16,1],
[60,46,16,2],
[60,46,16,3],
[60,46,16,4],
[60,46,16,5],
[60,46,16,6],
[60,46,16,7],
[60,46,16,8],
[60,46,16,10],
[60,46,16,11],
[60,46,16,12],
[60,46,17,0],
[60,46,17,1],
[60,46,17,2],
[60,46,17,3],
[60,46,17,4],
[60,46,17,5],
[60,46,17,6],
[60,46,17,7],
[60,46,17,8],
[60,46,17,10],
[60,46,17,11],
[60,46,17,12],
[60,46,17,16],
[60,46,20,0],
[60,46,20,1],
[60,46,20,2],
[60,46,20,3],
[60,46,20,4],
[60,46,20,5],
[60,46,20,6],
[60,46,20,7],
[60,46,20,11],
[60,46,20,12],
[60,46,20,16],
[60,46,20,17],
[60,46,21,0],
[60,46,21,1],
[60,46,21,2],
[60,46,21,4],
[60,46,21,10],
[60,46,21,11],
[60,46,21,12],
[60,46,21,16],
[60,46,21,17],
[60,46,21,20],
[60,46,22,0],
[60,46,22,1],
[60,46,22,2],
[60,46,22,3],
[60,46,22,4],
[60,46,22,6],
[60,46,22,7],
[60,46,22,8],
[60,46,22,11],
[60,46,22,12],
[60,46,22,16],
[60,46,22,17],
[60,46,22,21],
[60,46,23,0],
[60,46,23,1],
[60,46,23,2],
[60,46,23,5],
[60,46,23,6],
[60,46,23,7],
[60,46,23,8],
[60,46,23,10],
[60,46,23,11],
[60,46,23,12],
[60,46,23,16],
[60,46,23,17],
[60,46,23,20],
[60,46,23,21],
[60,46,24,0],
[60,46,24,1],
[60,46,24,2],
[60,46,24,5],
[60,46,24,6],
[60,46,24,7],
[60,46,24,8],
[60,46,24,10],
[60,46,24,11],
[60,46,24,12],
[60,46,24,17],
[60,46,24,20],
[60,46,24,21],
[60,46,25,0],
[60,46,25,1],
[60,46,25,2],
[60,46,25,3],
[60,46,25,4],
[60,46,25,5],
[60,46,25,6],
[60,46,25,8],
[60,46,25,10],
[60,46,25,11],
[60,46,25,12],
[60,46,25,16],
[60,46,25,17],
[60,46,27,0],
[60,46,27,1],
[60,46,27,2],
[60,46,27,3],
[60,46,27,4],
[60,46,27,5],
[60,46,27,6],
[60,46,27,7],
[60,46,27,8],
[60,46,27,11],
[60,46,27,12],
[60,46,27,16],
[60,46,27,17],
[60,46,28,0],
[60,46,28,1],
[60,46,28,2],
[60,46,28,3],
[60,46,28,4],
[60,46,28,5],
[60,46,28,6],
[60,46,28,8],
[60,46,28,10],
[60,46,28,11],
[60,46,28,12],
[60,46,29,0],
[60,46,29,1],
[60,46,29,2],
[60,46,29,3],
[60,46,29,4],
[60,46,29,5],
[60,46,29,6],
[60,46,29,7],
[60,46,29,8],
[60,46,29,10],
[60,46,30,0],
[60,46,30,1],
[60,46,30,2],
[60,46,30,3],
[60,46,30,4],
[60,46,30,5],
[60,46,30,6],
[60,46,30,7],
[60,46,30,8],
[60,46,30,10],
[60,46,30,11],
[60,46,30,12],
[60,46,31,0],
[60,46,31,1],
[60,46,31,2],
[60,46,31,3],
[60,46,31,4],
[60,46,31,5],
[60,46,31,6],
[60,46,31,7],
[60,46,31,8],
[60,46,31,10],
[60,46,31,11],
[60,46,32,0],
[60,46,32,1],
[60,46,32,2],
[60,46,32,3],
[60,46,32,4],
[60,46,32,5],
[60,46,32,6],
[60,46,32,7],
[60,46,32,8],
[60,46,33,0],
[60,46,33,1],
[60,46,33,2],
[60,46,33,3],
[60,46,33,4],
[60,46,33,5],
[60,46,33,6],
[60,46,33,7],
[60,46,34,0],
[60,46,34,1],
[60,46,34,2],
[60,46,34,3],
[60,46,34,4],
[60,46,34,5],
[60,47,3,0],
[60,47,3,1],
[60,47,3,2],
[60,47,4,0],
[60,47,4,1],
[60,47,4,2],
[60,47,4,3],
[60,47,5,0],
[60,47,5,1],
[60,47,5,2],
[60,47,5,4],
[60,47,6,0],
[60,47,6,1],
[60,47,6,2],
[60,47,6,4],
[60,47,7,0],
[60,47,7,1],
[60,47,7,2],
[60,47,7,3],
[60,47,7,4],
[60,47,7,5],
[60,47,7,6],
[60,47,8,0],
[60,47,8,1],
[60,47,8,2],
[60,47,8,4],
[60,47,8,7],
[60,47,9,0],
[60,47,9,1],
[60,47,9,2],
[60,47,9,3],
[60,47,9,4],
[60,47,9,5],
[60,47,9,6],
[60,47,9,7],
[60,47,9,8],
[60,47,10,0],
[60,47,10,1],
[60,47,10,2],
[60,47,10,3],
[60,47,10,4],
[60,47,10,5],
[60,47,10,6],
[60,47,10,7],
[60,47,10,8],
[60,47,10,9],
[60,47,11,0],
[60,47,11,1],
[60,47,11,2],
[60,47,11,3],
[60,47,11,4],
[60,47,11,5],
[60,47,11,6],
[60,47,11,7],
[60,47,11,8],
[60,47,11,9],
[60,47,11,10],
[60,47,12,0],
[60,47,12,1],
[60,47,12,2],
[60,47,12,3],
[60,47,12,4],
[60,47,12,5],
[60,47,12,6],
[60,47,12,7],
[60,47,12,8],
[60,47,12,9],
[60,47,12,10],
[60,47,15,0],
[60,47,15,1],
[60,47,15,2],
[60,47,15,3],
[60,47,15,4],
[60,47,15,5],
[60,47,15,6],
[60,47,15,7],
[60,47,15,8],
[60,47,15,10],
[60,47,15,11],
[60,47,15,12],
[60,47,16,0],
[60,47,16,1],
[60,47,16,2],
[60,47,16,3],
[60,47,16,4],
[60,47,16,5],
[60,47,16,6],
[60,47,16,7],
[60,47,16,8],
[60,47,16,9],
[60,47,16,10],
[60,47,16,11],
[60,47,16,15],
[60,47,17,0],
[60,47,17,1],
[60,47,17,2],
[60,47,17,3],
[60,47,17,4],
[60,47,17,5],
[60,47,17,6],
[60,47,17,7],
[60,47,17,8],
[60,47,17,9],
[60,47,17,10],
[60,47,17,11],
[60,47,17,15],
[60,47,17,16],
[60,47,18,0],
[60,47,18,1],
[60,47,18,2],
[60,47,18,3],
[60,47,18,4],
[60,47,18,5],
[60,47,18,6],
[60,47,18,7],
[60,47,18,8],
[60,47,18,12],
[60,47,18,16],
[60,47,18,17],
[60,47,20,0],
[60,47,20,1],
[60,47,20,2],
[60,47,20,3],
[60,47,20,4],
[60,47,20,5],
[60,47,20,8],
[60,47,20,9],
[60,47,20,11],
[60,47,20,12],
[60,47,20,15],
[60,47,20,16],
[60,47,20,17],
[60,47,20,18],
[60,47,21,0],
[60,47,21,1],
[60,47,21,2],
[60,47,21,4],
[60,47,21,7],
[60,47,21,9],
[60,47,21,10],
[60,47,21,11],
[60,47,21,12],
[60,47,21,15],
[60,47,21,16],
[60,47,21,17],
[60,47,21,18],
[60,47,21,20],
[60,47,22,0],
[60,47,22,1],
[60,47,22,2],
[60,47,22,5],
[60,47,22,6],
[60,47,22,7],
[60,47,22,8],
[60,47,22,9],
[60,47,22,11],
[60,47,22,12],
[60,47,22,15],
[60,47,22,16],
[60,47,22,17],
[60,47,22,18],
[60,47,22,21],
[60,47,24,3],
[60,47,24,4],
[60,47,24,5],
[60,47,24,6],
[60,47,24,7],
[60,47,24,8],
[60,47,24,9],
[60,47,24,10],
[60,47,24,11],
[60,47,24,12],
[60,47,24,15],
[60,47,24,17],
[60,47,24,18],
[60,47,24,20],
[60,47,25,0],
[60,47,25,1],
[60,47,25,2],
[60,47,25,3],
[60,47,25,4],
[60,47,25,5],
[60,47,25,6],
[60,47,25,8],
[60,47,25,9],
[60,47,25,10],
[60,47,25,11],
[60,47,25,12],
[60,47,25,15],
[60,47,25,16],
[60,47,25,17],
[60,47,25,18],
[60,47,26,0],
[60,47,26,1],
[60,47,26,2],
[60,47,26,3],
[60,47,26,4],
[60,47,26,5],
[60,47,26,6],
[60,47,26,7],
[60,47,26,8],
[60,47,26,10],
[60,47,26,11],
[60,47,26,12],
[60,47,26,16],
[60,47,26,17],
[60,47,27,0],
[60,47,27,1],
[60,47,27,2],
[60,47,27,3],
[60,47,27,4],
[60,47,27,5],
[60,47,27,6],
[60,47,27,7],
[60,47,27,8],
[60,47,27,9],
[60,47,27,11],
[60,47,27,12],
[60,47,27,15],
[60,47,28,0],
[60,47,28,1],
[60,47,28,2],
[60,47,28,3],
[60,47,28,4],
[60,47,28,5],
[60,47,28,6],
[60,47,28,8],
[60,47,28,9],
[60,47,28,10],
[60,47,28,11],
[60,47,28,12],
[60,47,29,0],
[60,47,29,1],
[60,47,29,2],
[60,47,29,3],
[60,47,29,4],
[60,47,29,5],
[60,47,29,6],
[60,47,29,7],
[60,47,29,8],
[60,47,29,9],
[60,47,29,10],
[60,47,32,0],
[60,47,32,1],
[60,47,32,2],
[60,47,32,3],
[60,47,32,4],
[60,47,32,5],
[60,47,32,6],
[60,47,32,7],
[60,47,33,0],
[60,47,33,1],
[60,47,33,2],
[60,47,33,3],
[60,47,33,4],
[60,47,33,5],
[60,47,34,0],
[60,47,34,1],
[60,47,34,2],
[60,47,34,3],
[60,47,34,4],
[60,47,35,0],
[60,47,35,1],
[60,47,35,2],
[60,48,3,0],
[60,48,3,1],
[60,48,3,2],
[60,48,4,0],
[60,48,4,1],
[60,48,4,2],
[60,48,4,3],
[60,48,5,0],
[60,48,5,1],
[60,48,5,2],
[60,48,5,4],
[60,48,6,0],
[60,48,6,1],
[60,48,6,2],
[60,48,6,4],
[60,48,7,0],
[60,48,7,1],
[60,48,7,2],
[60,48,7,3],
[60,48,7,4],
[60,48,7,5],
[60,48,7,6],
[60,48,8,0],
[60,48,8,1],
[60,48,8,2],
[60,48,8,4],
[60,48,8,7],
[60,48,9,0],
[60,48,9,1],
[60,48,9,2],
[60,48,9,3],
[60,48,9,4],
[60,48,9,5],
[60,48,9,6],
[60,48,9,7],
[60,48,9,8],
[60,48,10,0],
[60,48,10,1],
[60,48,10,2],
[60,48,10,3],
[60,48,10,4],
[60,48,10,5],
[60,48,10,6],
[60,48,10,7],
[60,48,10,8],
[60,48,10,9],
[60,48,15,0],
[60,48,15,1],
[60,48,15,2],
[60,48,15,3],
[60,48,15,4],
[60,48,15,5],
[60,48,15,6],
[60,48,15,7],
[60,48,15,8],
[60,48,15,10],
[60,48,16,0],
[60,48,16,1],
[60,48,16,2],
[60,48,16,3],
[60,48,16,4],
[60,48,16,5],
[60,48,16,6],
[60,48,16,7],
[60,48,16,8],
[60,48,16,9],
[60,48,16,15],
[60,48,17,0],
[60,48,17,1],
[60,48,17,2],
[60,48,17,3],
[60,48,17,4],
[60,48,17,5],
[60,48,17,6],
[60,48,17,7],
[60,48,17,8],
[60,48,17,9],
[60,48,17,15],
[60,48,17,16],
[60,48,18,0],
[60,48,18,1],
[60,48,18,2],
[60,48,18,3],
[60,48,18,4],
[60,48,18,5],
[60,48,18,6],
[60,48,18,7],
[60,48,18,10],
[60,48,18,16],
[60,48,18,17],
[60,48,20,0],
[60,48,20,1],
[60,48,20,2],
[60,48,20,3],
[60,48,20,4],
[60,48,20,6],
[60,48,20,7],
[60,48,20,8],
[60,48,20,9],
[60,48,20,15],
[60,48,20,16],
[60,48,20,17],
[60,48,20,18],
[60,48,21,0],
[60,48,21,1],
[60,48,21,2],
[60,48,21,7],
[60,48,21,9],
[60,48,21,10],
[60,48,21,15],
[60,48,21,16],
[60,48,21,17],
[60,48,21,18],
[60,48,21,20],
[60,48,22,3],
[60,48,22,4],
[60,48,22,5],
[60,48,22,6],
[60,48,22,7],
[60,48,22,8],
[60,48,22,9],
[60,48,22,15],
[60,48,22,16],
[60,48,22,17],
[60,48,22,18],
[60,48,23,0],
[60,48,23,1],
[60,48,23,2],
[60,48,23,3],
[60,48,23,4],
[60,48,23,5],
[60,48,23,6],
[60,48,23,7],
[60,48,23,8],
[60,48,23,9],
[60,48,23,10],
[60,48,23,15],
[60,48,23,16],
[60,48,23,17],
[60,48,23,18],
[60,48,24,0],
[60,48,24,1],
[60,48,24,2],
[60,48,24,3],
[60,48,24,4],
[60,48,24,5],
[60,48,24,6],
[60,48,24,7],
[60,48,24,8],
[60,48,24,9],
[60,48,24,10],
[60,48,24,15],
[60,48,24,17],
[60,48,24,18],
[60,48,25,0],
[60,48,25,1],
[60,48,25,2],
[60,48,25,3],
[60,48,25,4],
[60,48,25,5],
[60,48,25,6],
[60,48,25,8],
[60,48,25,9],
[60,48,25,10],
[60,48,25,15],
[60,48,25,16],
[60,48,25,17],
[60,48,26,0],
[60,48,26,1],
[60,48,26,2],
[60,48,26,3],
[60,48,26,4],
[60,48,26,5],
[60,48,26,6],
[60,48,26,7],
[60,48,26,8],
[60,48,26,10],
[60,48,27,0],
[60,48,27,1],
[60,48,27,2],
[60,48,27,3],
[60,48,27,4],
[60,48,27,5],
[60,48,27,6],
[60,48,27,7],
[60,48,27,8],
[60,48,27,9],
[60,48,28,0],
[60,48,28,1],
[60,48,28,2],
[60,48,28,3],
[60,48,28,4],
[60,48,28,5],
[60,48,28,6],
[60,48,28,8],
[60,48,28,9],
[60,48,28,10],
[60,48,30,0],
[60,48,30,1],
[60,48,30,2],
[60,48,30,3],
[60,48,30,4],
[60,48,30,5],
[60,48,30,6],
[60,48,30,7],
[60,48,30,8],
[60,48,30,9],
[60,48,31,0],
[60,48,31,1],
[60,48,31,2],
[60,48,31,3],
[60,48,31,4],
[60,48,31,5],
[60,48,31,6],
[60,48,31,7],
[60,48,34,0],
[60,48,34,1],
[60,48,34,2],
[60,49,3,0],
[60,49,3,1],
[60,49,3,2],
[60,49,4,0],
[60,49,4,1],
[60,49,4,2],
[60,49,4,3],
[60,49,5,0],
[60,49,5,1],
[60,49,5,2],
[60,49,5,4],
[60,49,6,0],
[60,49,6,1],
[60,49,6,2],
[60,49,6,4],
[60,49,7,0],
[60,49,7,1],
[60,49,7,2],
[60,49,7,3],
[60,49,7,4],
[60,49,7,5],
[60,49,7,6],
[60,49,8,0],
[60,49,8,1],
[60,49,8,2],
[60,49,8,4],
[60,49,8,7],
[60,49,10,0],
[60,49,10,1],
[60,49,10,2],
[60,49,10,3],
[60,49,10,4],
[60,49,10,5],
[60,49,10,6],
[60,49,10,7],
[60,49,10,8],
[60,49,11,0],
[60,49,11,1],
[60,49,11,2],
[60,49,11,3],
[60,49,11,4],
[60,49,11,5],
[60,49,11,6],
[60,49,11,7],
[60,49,11,8],
[60,49,11,10],
[60,49,12,0],
[60,49,12,1],
[60,49,12,2],
[60,49,12,3],
[60,49,12,4],
[60,49,12,5],
[60,49,12,6],
[60,49,12,7],
[60,49,12,8],
[60,49,12,10],
[60,49,16,0],
[60,49,16,1],
[60,49,16,2],
[60,49,16,3],
[60,49,16,4],
[60,49,16,5],
[60,49,16,6],
[60,49,16,7],
[60,49,16,8],
[60,49,16,12],
[60,49,17,0],
[60,49,17,1],
[60,49,17,2],
[60,49,17,3],
[60,49,17,4],
[60,49,17,5],
[60,49,17,6],
[60,49,17,7],
[60,49,17,8],
[60,49,17,12],
[60,49,17,16],
[60,49,20,0],
[60,49,20,1],
[60,49,20,2],
[60,49,20,3],
[60,49,20,4],
[60,49,20,6],
[60,49,20,7],
[60,49,20,8],
[60,49,20,11],
[60,49,20,12],
[60,49,20,16],
[60,49,20,17],
[60,49,21,0],
[60,49,21,1],
[60,49,21,2],
[60,49,21,7],
[60,49,21,10],
[60,49,21,11],
[60,49,21,12],
[60,49,21,16],
[60,49,21,17],
[60,49,21,20],
[60,49,22,3],
[60,49,22,4],
[60,49,22,5],
[60,49,22,6],
[60,49,22,7],
[60,49,22,8],
[60,49,22,11],
[60,49,22,12],
[60,49,22,16],
[60,49,22,17],
[60,49,23,0],
[60,49,23,1],
[60,49,23,2],
[60,49,23,3],
[60,49,23,4],
[60,49,23,5],
[60,49,23,6],
[60,49,23,7],
[60,49,23,8],
[60,49,23,10],
[60,49,23,11],
[60,49,23,12],
[60,49,23,16],
[60,49,23,17],
[60,49,24,0],
[60,49,24,1],
[60,49,24,2],
[60,49,24,3],
[60,49,24,4],
[60,49,24,5],
[60,49,24,6],
[60,49,24,7],
[60,49,24,8],
[60,49,24,10],
[60,49,24,11],
[60,49,24,12],
[60,49,24,17],
[60,49,25,0],
[60,49,25,1],
[60,49,25,2],
[60,49,25,3],
[60,49,25,4],
[60,49,25,5],
[60,49,25,6],
[60,49,25,8],
[60,49,25,10],
[60,49,25,11],
[60,49,25,12],
[60,49,25,16],
[60,49,25,17],
[60,49,27,0],
[60,49,27,1],
[60,49,27,2],
[60,49,27,3],
[60,49,27,4],
[60,49,27,5],
[60,49,27,6],
[60,49,27,7],
[60,49,27,8],
[60,49,27,11],
[60,49,27,12],
[60,49,28,0],
[60,49,28,1],
[60,49,28,2],
[60,49,28,3],
[60,49,28,4],
[60,49,28,5],
[60,49,28,6],
[60,49,28,8],
[60,49,28,10],
[60,49,28,11],
[60,49,28,12],
[60,49,29,0],
[60,49,29,1],
[60,49,29,2],
[60,49,29,3],
[60,49,29,4],
[60,49,29,5],
[60,49,29,6],
[60,49,29,7],
[60,49,29,8],
[60,49,29,10],
[60,49,30,0],
[60,49,30,1],
[60,49,30,2],
[60,49,30,3],
[60,49,30,4],
[60,49,30,5],
[60,49,30,6],
[60,49,30,7],
[60,49,30,8],
[60,49,31,0],
[60,49,31,1],
[60,49,31,2],
[60,49,31,3],
[60,49,31,4],
[60,49,31,5],
[60,49,31,6],
[60,49,31,7],
[60,49,32,0],
[60,49,32,1],
[60,49,32,2],
[60,49,32,3],
[60,49,32,4],
[60,49,32,5],
[60,49,33,0],
[60,49,33,1],
[60,49,33,2],
[60,49,33,3],
[60,49,33,4],
[60,49,34,0],
[60,49,34,1],
[60,49,34,2],
[60,50,3,0],
[60,50,3,1],
[60,50,3,2],
[60,50,4,0],
[60,50,4,1],
[60,50,4,2],
[60,50,4,3],
[60,50,5,0],
[60,50,5,1],
[60,50,5,2],
[60,50,5,4],
[60,50,6,0],
[60,50,6,1],
[60,50,6,2],
[60,50,6,4],
[60,50,7,0],
[60,50,7,1],
[60,50,7,2],
[60,50,7,3],
[60,50,7,4],
[60,50,7,5],
[60,50,7,6],
[60,50,8,0],
[60,50,8,1],
[60,50,8,2],
[60,50,8,4],
[60,50,8,7],
[60,50,10,0],
[60,50,10,1],
[60,50,10,2],
[60,50,10,3],
[60,50,10,4],
[60,50,10,5],
[60,50,10,6],
[60,50,10,7],
[60,50,10,8],
[60,50,11,0],
[60,50,11,1],
[60,50,11,2],
[60,50,11,3],
[60,50,11,4],
[60,50,11,5],
[60,50,11,6],
[60,50,11,7],
[60,50,11,8],
[60,50,11,10],
[60,50,12,0],
[60,50,12,1],
[60,50,12,2],
[60,50,12,3],
[60,50,12,4],
[60,50,12,5],
[60,50,12,6],
[60,50,12,7],
[60,50,12,8],
[60,50,12,10],
[60,50,16,0],
[60,50,16,1],
[60,50,16,2],
[60,50,16,3],
[60,50,16,4],
[60,50,16,5],
[60,50,16,6],
[60,50,16,7],
[60,50,16,8],
[60,50,16,12],
[60,50,17,0],
[60,50,17,1],
[60,50,17,2],
[60,50,17,3],
[60,50,17,4],
[60,50,17,5],
[60,50,17,6],
[60,50,17,7],
[60,50,17,8],
[60,50,17,12],
[60,50,17,16],
[60,50,20,0],
[60,50,20,1],
[60,50,20,2],
[60,50,20,3],
[60,50,20,4],
[60,50,20,6],
[60,50,20,7],
[60,50,20,8],
[60,50,20,11],
[60,50,20,12],
[60,50,20,16],
[60,50,20,17],
[60,50,21,0],
[60,50,21,1],
[60,50,21,2],
[60,50,21,7],
[60,50,21,10],
[60,50,21,11],
[60,50,21,12],
[60,50,21,16],
[60,50,21,17],
[60,50,21,20],
[60,50,22,3],
[60,50,22,4],
[60,50,22,5],
[60,50,22,6],
[60,50,22,7],
[60,50,22,8],
[60,50,22,11],
[60,50,22,12],
[60,50,22,16],
[60,50,22,17],
[60,50,23,0],
[60,50,23,1],
[60,50,23,2],
[60,50,23,3],
[60,50,23,4],
[60,50,23,5],
[60,50,23,6],
[60,50,23,7],
[60,50,23,8],
[60,50,23,10],
[60,50,23,11],
[60,50,23,12],
[60,50,23,16],
[60,50,23,17],
[60,50,24,0],
[60,50,24,1],
[60,50,24,2],
[60,50,24,3],
[60,50,24,4],
[60,50,24,5],
[60,50,24,6],
[60,50,24,7],
[60,50,24,8],
[60,50,24,10],
[60,50,24,11],
[60,50,24,12],
[60,50,24,17],
[60,50,25,0],
[60,50,25,1],
[60,50,25,2],
[60,50,25,3],
[60,50,25,4],
[60,50,25,5],
[60,50,25,6],
[60,50,25,8],
[60,50,25,10],
[60,50,25,11],
[60,50,25,12],
[60,50,25,16],
[60,50,25,17],
[60,50,27,0],
[60,50,27,1],
[60,50,27,2],
[60,50,27,3],
[60,50,27,4],
[60,50,27,5],
[60,50,27,6],
[60,50,27,7],
[60,50,27,8],
[60,50,27,11],
[60,50,27,12],
[60,50,28,0],
[60,50,28,1],
[60,50,28,2],
[60,50,28,3],
[60,50,28,4],
[60,50,28,5],
[60,50,28,6],
[60,50,28,8],
[60,50,28,10],
[60,50,28,11],
[60,50,28,12],
[60,50,29,0],
[60,50,29,1],
[60,50,29,2],
[60,50,29,3],
[60,50,29,4],
[60,50,29,5],
[60,50,29,6],
[60,50,29,7],
[60,50,29,8],
[60,50,29,10],
[60,50,30,0],
[60,50,30,1],
[60,50,30,2],
[60,50,30,3],
[60,50,30,4],
[60,50,30,5],
[60,50,30,6],
[60,50,30,7],
[60,50,30,8],
[60,50,31,0],
[60,50,31,1],
[60,50,31,2],
[60,50,31,3],
[60,50,31,4],
[60,50,31,5],
[60,50,31,6],
[60,50,31,7],
[60,50,32,0],
[60,50,32,1],
[60,50,32,2],
[60,50,32,3],
[60,50,32,4],
[60,50,32,5],
[60,50,33,0],
[60,50,33,1],
[60,50,33,2],
[60,50,33,3],
[60,50,33,4],
[60,50,34,0],
[60,50,34,1],
[60,50,34,2],
[60,51,4,0],
[60,51,4,1],
[60,51,4,2],
[60,51,7,0],
[60,51,7,1],
[60,51,7,2],
[60,51,7,4],
[60,51,9,0],
[60,51,9,1],
[60,51,9,2],
[60,51,9,4],
[60,51,9,7],
[60,51,10,0],
[60,51,10,1],
[60,51,10,2],
[60,51,10,4],
[60,51,10,7],
[60,51,10,9],
[60,51,11,0],
[60,51,11,1],
[60,51,11,2],
[60,51,11,4],
[60,51,11,7],
[60,51,11,9],
[60,51,11,10],
[60,51,12,0],
[60,51,12,1],
[60,51,12,2],
[60,51,12,4],
[60,51,12,7],
[60,51,12,9],
[60,51,12,10],
[60,51,15,0],
[60,51,15,1],
[60,51,15,2],
[60,51,15,4],
[60,51,15,7],
[60,51,15,10],
[60,51,15,11],
[60,51,16,0],
[60,51,16,1],
[60,51,16,2],
[60,51,16,4],
[60,51,16,7],
[60,51,16,9],
[60,51,16,12],
[60,51,16,15],
[60,51,17,0],
[60,51,17,1],
[60,51,17,2],
[60,51,17,4],
[60,51,17,7],
[60,51,17,9],
[60,51,17,12],
[60,51,17,15],
[60,51,17,16],
[60,51,18,0],
[60,51,18,1],
[60,51,18,2],
[60,51,18,4],
[60,51,18,7],
[60,51,18,10],
[60,51,18,11],
[60,51,18,12],
[60,51,18,16],
[60,51,18,17],
[60,51,20,0],
[60,51,20,1],
[60,51,20,2],
[60,51,20,4],
[60,51,20,7],
[60,51,20,9],
[60,51,20,11],
[60,51,20,12],
[60,51,20,15],
[60,51,20,16],
[60,51,20,17],
[60,51,20,18],
[60,51,22,4],
[60,51,22,7],
[60,51,22,9],
[60,51,22,11],
[60,51,22,12],
[60,51,22,15],
[60,51,22,16],
[60,51,22,17],
[60,51,22,18],
[60,51,23,0],
[60,51,23,1],
[60,51,23,2],
[60,51,23,4],
[60,51,23,7],
[60,51,23,9],
[60,51,23,10],
[60,51,23,11],
[60,51,23,12],
[60,51,23,15],
[60,51,23,16],
[60,51,23,17],
[60,51,23,18],
[60,51,24,0],
[60,51,24,1],
[60,51,24,2],
[60,51,24,4],
[60,51,24,7],
[60,51,24,9],
[60,51,24,10],
[60,51,24,11],
[60,51,24,12],
[60,51,24,15],
[60,51,24,17],
[60,51,24,18],
[60,51,25,0],
[60,51,25,1],
[60,51,25,2],
[60,51,25,4],
[60,51,25,9],
[60,51,25,10],
[60,51,25,11],
[60,51,25,12],
[60,51,25,15],
[60,51,25,16],
[60,51,25,17],
[60,51,26,0],
[60,51,26,1],
[60,51,26,2],
[60,51,26,4],
[60,51,26,7],
[60,51,26,10],
[60,51,26,11],
[60,51,26,12],
[60,51,27,0],
[60,51,27,1],
[60,51,27,2],
[60,51,27,4],
[60,51,27,7],
[60,51,27,9],
[60,51,27,11],
[60,51,27,12],
[60,51,28,0],
[60,51,28,1],
[60,51,28,2],
[60,51,28,4],
[60,51,28,9],
[60,51,28,10],
[60,51,28,11],
[60,51,28,12],
[60,51,29,0],
[60,51,29,1],
[60,51,29,2],
[60,51,29,4],
[60,51,29,7],
[60,51,29,9],
[60,51,29,10],
[60,51,30,0],
[60,51,30,1],
[60,51,30,2],
[60,51,30,4],
[60,51,30,7],
[60,51,30,9],
[60,51,31,0],
[60,51,31,1],
[60,51,31,2],
[60,51,31,4],
[60,51,31,7],
[60,51,32,0],
[60,51,32,1],
[60,51,32,2],
[60,51,32,4],
[60,51,33,0],
[60,51,33,1],
[60,51,33,2],
[60,51,33,4],
[60,51,34,0],
[60,51,34,1],
[60,51,34,2],
[60,52,3,0],
[60,52,3,1],
[60,52,3,2],
[60,52,4,0],
[60,52,4,1],
[60,52,4,2],
[60,52,4,3],
[60,52,5,0],
[60,52,5,1],
[60,52,5,2],
[60,52,5,4],
[60,52,6,0],
[60,52,6,1],
[60,52,6,2],
[60,52,6,4],
[60,52,7,0],
[60,52,7,1],
[60,52,7,2],
[60,52,7,3],
[60,52,7,4],
[60,52,7,5],
[60,52,7,6],
[60,52,8,0],
[60,52,8,1],
[60,52,8,2],
[60,52,8,4],
[60,52,8,7],
[60,52,10,0],
[60,52,10,1],
[60,52,10,2],
[60,52,10,3],
[60,52,10,4],
[60,52,10,5],
[60,52,10,6],
[60,52,10,7],
[60,52,10,8],
[60,52,11,0],
[60,52,11,1],
[60,52,11,2],
[60,52,11,3],
[60,52,11,4],
[60,52,11,5],
[60,52,11,6],
[60,52,11,7],
[60,52,11,8],
[60,52,11,10],
[60,52,12,0],
[60,52,12,1],
[60,52,12,2],
[60,52,12,3],
[60,52,12,4],
[60,52,12,5],
[60,52,12,6],
[60,52,12,7],
[60,52,12,8],
[60,52,12,10],
[60,52,16,0],
[60,52,16,1],
[60,52,16,2],
[60,52,16,3],
[60,52,16,4],
[60,52,16,5],
[60,52,16,6],
[60,52,16,7],
[60,52,16,8],
[60,52,16,12],
[60,52,17,0],
[60,52,17,1],
[60,52,17,2],
[60,52,17,3],
[60,52,17,4],
[60,52,17,5],
[60,52,17,6],
[60,52,17,7],
[60,52,17,8],
[60,52,17,12],
[60,52,17,16],
[60,52,20,0],
[60,52,20,1],
[60,52,20,2],
[60,52,20,3],
[60,52,20,4],
[60,52,20,6],
[60,52,20,7],
[60,52,20,8],
[60,52,20,11],
[60,52,20,12],
[60,52,20,16],
[60,52,20,17],
[60,52,21,0],
[60,52,21,1],
[60,52,21,2],
[60,52,21,7],
[60,52,21,10],
[60,52,21,11],
[60,52,21,12],
[60,52,21,16],
[60,52,21,17],
[60,52,21,20],
[60,52,22,3],
[60,52,22,4],
[60,52,22,5],
[60,52,22,6],
[60,52,22,7],
[60,52,22,8],
[60,52,22,11],
[60,52,22,12],
[60,52,22,16],
[60,52,22,17],
[60,52,23,0],
[60,52,23,1],
[60,52,23,2],
[60,52,23,3],
[60,52,23,4],
[60,52,23,5],
[60,52,23,6],
[60,52,23,7],
[60,52,23,8],
[60,52,23,10],
[60,52,23,11],
[60,52,23,12],
[60,52,23,16],
[60,52,23,17],
[60,52,24,0],
[60,52,24,1],
[60,52,24,2],
[60,52,24,3],
[60,52,24,4],
[60,52,24,5],
[60,52,24,6],
[60,52,24,7],
[60,52,24,8],
[60,52,24,10],
[60,52,24,11],
[60,52,24,12],
[60,52,24,17],
[60,52,25,0],
[60,52,25,1],
[60,52,25,2],
[60,52,25,3],
[60,52,25,4],
[60,52,25,5],
[60,52,25,6],
[60,52,25,8],
[60,52,25,10],
[60,52,25,11],
[60,52,25,12],
[60,52,25,16],
[60,52,25,17],
[60,52,27,0],
[60,52,27,1],
[60,52,27,2],
[60,52,27,3],
[60,52,27,4],
[60,52,27,5],
[60,52,27,6],
[60,52,27,7],
[60,52,27,8],
[60,52,27,11],
[60,52,27,12],
[60,52,28,0],
[60,52,28,1],
[60,52,28,2],
[60,52,28,3],
[60,52,28,4],
[60,52,28,5],
[60,52,28,6],
[60,52,28,8],
[60,52,28,10],
[60,52,28,11],
[60,52,28,12],
[60,52,29,0],
[60,52,29,1],
[60,52,29,2],
[60,52,29,3],
[60,52,29,4],
[60,52,29,5],
[60,52,29,6],
[60,52,29,7],
[60,52,29,8],
[60,52,29,10],
[60,52,30,0],
[60,52,30,1],
[60,52,30,2],
[60,52,30,3],
[60,52,30,4],
[60,52,30,5],
[60,52,30,6],
[60,52,30,7],
[60,52,30,8],
[60,52,31,0],
[60,52,31,1],
[60,52,31,2],
[60,52,31,3],
[60,52,31,4],
[60,52,31,5],
[60,52,31,6],
[60,52,31,7],
[60,52,32,0],
[60,52,32,1],
[60,52,32,2],
[60,52,32,3],
[60,52,32,4],
[60,52,32,5],
[60,52,33,0],
[60,52,33,1],
[60,52,33,2],
[60,52,33,3],
[60,52,33,4],
[60,52,34,0],
[60,52,34,1],
[60,52,34,2],
[60,53,3,0],
[60,53,3,1],
[60,53,3,2],
[60,53,4,0],
[60,53,4,1],
[60,53,4,2],
[60,53,4,3],
[60,53,5,0],
[60,53,5,1],
[60,53,5,2],
[60,53,5,4],
[60,53,6,0],
[60,53,6,1],
[60,53,6,2],
[60,53,6,4],
[60,53,7,0],
[60,53,7,1],
[60,53,7,2],
[60,53,7,3],
[60,53,7,4],
[60,53,7,5],
[60,53,7,6],
[60,53,8,0],
[60,53,8,1],
[60,53,8,2],
[60,53,8,4],
[60,53,8,7],
[60,53,9,0],
[60,53,9,1],
[60,53,9,2],
[60,53,9,3],
[60,53,9,4],
[60,53,9,5],
[60,53,9,6],
[60,53,9,7],
[60,53,9,8],
[60,53,11,0],
[60,53,11,1],
[60,53,11,2],
[60,53,11,3],
[60,53,11,4],
[60,53,11,5],
[60,53,11,6],
[60,53,11,7],
[60,53,11,8],
[60,53,11,9],
[60,53,12,0],
[60,53,12,1],
[60,53,12,2],
[60,53,12,3],
[60,53,12,4],
[60,53,12,5],
[60,53,12,6],
[60,53,12,7],
[60,53,12,8],
[60,53,12,9],
[60,53,15,0],
[60,53,15,1],
[60,53,15,2],
[60,53,15,3],
[60,53,15,4],
[60,53,15,5],
[60,53,15,6],
[60,53,15,7],
[60,53,15,8],
[60,53,15,11],
[60,53,16,0],
[60,53,16,1],
[60,53,16,2],
[60,53,16,3],
[60,53,16,4],
[60,53,16,5],
[60,53,16,6],
[60,53,16,7],
[60,53,16,8],
[60,53,16,9],
[60,53,16,12],
[60,53,16,15],
[60,53,17,0],
[60,53,17,1],
[60,53,17,2],
[60,53,17,3],
[60,53,17,4],
[60,53,17,5],
[60,53,17,6],
[60,53,17,7],
[60,53,17,8],
[60,53,17,9],
[60,53,17,12],
[60,53,17,15],
[60,53,17,16],
[60,53,18,0],
[60,53,18,1],
[60,53,18,2],
[60,53,18,3],
[60,53,18,4],
[60,53,18,5],
[60,53,18,6],
[60,53,18,7],
[60,53,18,11],
[60,53,18,12],
[60,53,18,16],
[60,53,18,17],
[60,53,21,0],
[60,53,21,1],
[60,53,21,2],
[60,53,21,7],
[60,53,21,9],
[60,53,21,11],
[60,53,21,12],
[60,53,21,15],
[60,53,21,16],
[60,53,21,17],
[60,53,21,18],
[60,53,23,0],
[60,53,23,1],
[60,53,23,2],
[60,53,23,3],
[60,53,23,4],
[60,53,23,5],
[60,53,23,6],
[60,53,23,7],
[60,53,23,8],
[60,53,23,9],
[60,53,23,11],
[60,53,23,12],
[60,53,23,15],
[60,53,23,16],
[60,53,23,17],
[60,53,23,18],
[60,53,24,0],
[60,53,24,1],
[60,53,24,2],
[60,53,24,3],
[60,53,24,4],
[60,53,24,5],
[60,53,24,6],
[60,53,24,7],
[60,53,24,8],
[60,53,24,9],
[60,53,24,11],
[60,53,24,12],
[60,53,24,15],
[60,53,24,17],
[60,53,24,18],
[60,53,25,0],
[60,53,25,1],
[60,53,25,2],
[60,53,25,3],
[60,53,25,4],
[60,53,25,5],
[60,53,25,6],
[60,53,25,8],
[60,53,25,9],
[60,53,25,11],
[60,53,25,12],
[60,53,25,15],
[60,53,25,16],
[60,53,25,17],
[60,53,26,0],
[60,53,26,1],
[60,53,26,2],
[60,53,26,3],
[60,53,26,4],
[60,53,26,5],
[60,53,26,6],
[60,53,26,7],
[60,53,26,8],
[60,53,26,11],
[60,53,26,12],
[60,53,28,0],
[60,53,28,1],
[60,53,28,2],
[60,53,28,3],
[60,53,28,4],
[60,53,28,5],
[60,53,28,6],
[60,53,28,8],
[60,53,28,9],
[60,53,28,11],
[60,53,28,12],
[60,53,29,0],
[60,53,29,1],
[60,53,29,2],
[60,53,29,3],
[60,53,29,4],
[60,53,29,5],
[60,53,29,6],
[60,53,29,7],
[60,53,29,8],
[60,53,29,9],
[60,53,30,0],
[60,53,30,1],
[60,53,30,2],
[60,53,30,3],
[60,53,30,4],
[60,53,30,5],
[60,53,30,6],
[60,53,30,7],
[60,53,30,8],
[60,53,30,9],
[60,53,31,0],
[60,53,31,1],
[60,53,31,2],
[60,53,31,3],
[60,53,31,4],
[60,53,31,5],
[60,53,31,6],
[60,53,31,7],
[60,53,32,0],
[60,53,32,1],
[60,53,32,2],
[60,53,32,3],
[60,53,32,4],
[60,53,32,5],
[60,53,33,0],
[60,53,33,1],
[60,53,33,2],
[60,53,33,3],
[60,53,33,4],
[60,53,34,0],
[60,53,34,1],
[60,53,34,2],
[60,54,3,0],
[60,54,3,1],
[60,54,3,2],
[60,54,4,0],
[60,54,4,1],
[60,54,4,2],
[60,54,4,3],
[60,54,5,0],
[60,54,5,1],
[60,54,5,2],
[60,54,5,4],
[60,54,6,0],
[60,54,6,1],
[60,54,6,2],
[60,54,6,4],
[60,54,7,0],
[60,54,7,1],
[60,54,7,2],
[60,54,7,3],
[60,54,7,4],
[60,54,7,5],
[60,54,7,6],
[60,54,8,0],
[60,54,8,1],
[60,54,8,2],
[60,54,8,4],
[60,54,8,7],
[60,54,9,0],
[60,54,9,1],
[60,54,9,2],
[60,54,9,3],
[60,54,9,4],
[60,54,9,5],
[60,54,9,6],
[60,54,9,7],
[60,54,9,8],
[60,54,11,0],
[60,54,11,1],
[60,54,11,2],
[60,54,11,3],
[60,54,11,4],
[60,54,11,5],
[60,54,11,6],
[60,54,11,7],
[60,54,11,8],
[60,54,11,9],
[60,54,12,0],
[60,54,12,1],
[60,54,12,2],
[60,54,12,3],
[60,54,12,4],
[60,54,12,5],
[60,54,12,6],
[60,54,12,7],
[60,54,12,8],
[60,54,12,9],
[60,54,15,0],
[60,54,15,1],
[60,54,15,2],
[60,54,15,3],
[60,54,15,4],
[60,54,15,5],
[60,54,15,6],
[60,54,15,7],
[60,54,15,8],
[60,54,15,11],
[60,54,16,0],
[60,54,16,1],
[60,54,16,2],
[60,54,16,3],
[60,54,16,4],
[60,54,16,5],
[60,54,16,6],
[60,54,16,7],
[60,54,16,8],
[60,54,16,9],
[60,54,16,12],
[60,54,16,15],
[60,54,17,0],
[60,54,17,1],
[60,54,17,2],
[60,54,17,3],
[60,54,17,4],
[60,54,17,5],
[60,54,17,6],
[60,54,17,7],
[60,54,17,8],
[60,54,17,9],
[60,54,17,12],
[60,54,17,15],
[60,54,17,16],
[60,54,18,0],
[60,54,18,1],
[60,54,18,2],
[60,54,18,3],
[60,54,18,4],
[60,54,18,5],
[60,54,18,6],
[60,54,18,7],
[60,54,18,11],
[60,54,18,12],
[60,54,18,16],
[60,54,18,17],
[60,54,21,0],
[60,54,21,1],
[60,54,21,2],
[60,54,21,7],
[60,54,21,9],
[60,54,21,11],
[60,54,21,12],
[60,54,21,15],
[60,54,21,16],
[60,54,21,17],
[60,54,21,18],
[60,54,23,0],
[60,54,23,1],
[60,54,23,2],
[60,54,23,3],
[60,54,23,4],
[60,54,23,5],
[60,54,23,6],
[60,54,23,7],
[60,54,23,8],
[60,54,23,9],
[60,54,23,11],
[60,54,23,12],
[60,54,23,15],
[60,54,23,16],
[60,54,23,17],
[60,54,23,18],
[60,54,24,0],
[60,54,24,1],
[60,54,24,2],
[60,54,24,3],
[60,54,24,4],
[60,54,24,5],
[60,54,24,6],
[60,54,24,7],
[60,54,24,8],
[60,54,24,9],
[60,54,24,11],
[60,54,24,12],
[60,54,24,15],
[60,54,24,17],
[60,54,24,18],
[60,54,25,0],
[60,54,25,1],
[60,54,25,2],
[60,54,25,3],
[60,54,25,4],
[60,54,25,5],
[60,54,25,6],
[60,54,25,8],
[60,54,25,9],
[60,54,25,11],
[60,54,25,12],
[60,54,25,15],
[60,54,25,16],
[60,54,25,17],
[60,54,26,0],
[60,54,26,1],
[60,54,26,2],
[60,54,26,3],
[60,54,26,4],
[60,54,26,5],
[60,54,26,6],
[60,54,26,7],
[60,54,26,8],
[60,54,26,11],
[60,54,26,12],
[60,54,28,0],
[60,54,28,1],
[60,54,28,2],
[60,54,28,3],
[60,54,28,4],
[60,54,28,5],
[60,54,28,6],
[60,54,28,8],
[60,54,28,9],
[60,54,28,11],
[60,54,28,12],
[60,54,29,0],
[60,54,29,1],
[60,54,29,2],
[60,54,29,3],
[60,54,29,4],
[60,54,29,5],
[60,54,29,6],
[60,54,29,7],
[60,54,29,8],
[60,54,29,9],
[60,54,30,0],
[60,54,30,1],
[60,54,30,2],
[60,54,30,3],
[60,54,30,4],
[60,54,30,5],
[60,54,30,6],
[60,54,30,7],
[60,54,30,8],
[60,54,30,9],
[60,54,31,0],
[60,54,31,1],
[60,54,31,2],
[60,54,31,3],
[60,54,31,4],
[60,54,31,5],
[60,54,31,6],
[60,54,31,7],
[60,54,32,0],
[60,54,32,1],
[60,54,32,2],
[60,54,32,3],
[60,54,32,4],
[60,54,32,5],
[60,54,33,0],
[60,54,33,1],
[60,54,33,2],
[60,54,33,3],
[60,54,33,4],
[60,54,34,0],
[60,54,34,1],
[60,54,34,2],
[60,55,3,0],
[60,55,3,1],
[60,55,3,2],
[60,55,4,0],
[60,55,4,1],
[60,55,4,2],
[60,55,4,3],
[60,55,5,0],
[60,55,5,1],
[60,55,5,2],
[60,55,5,4],
[60,55,6,0],
[60,55,6,1],
[60,55,6,2],
[60,55,6,4],
[60,55,7,0],
[60,55,7,1],
[60,55,7,2],
[60,55,7,3],
[60,55,7,4],
[60,55,7,5],
[60,55,7,6],
[60,55,8,0],
[60,55,8,1],
[60,55,8,2],
[60,55,8,4],
[60,55,8,7],
[60,55,9,0],
[60,55,9,1],
[60,55,9,2],
[60,55,9,3],
[60,55,9,4],
[60,55,9,5],
[60,55,9,6],
[60,55,9,7],
[60,55,9,8],
[60,55,11,0],
[60,55,11,1],
[60,55,11,2],
[60,55,11,3],
[60,55,11,4],
[60,55,11,5],
[60,55,11,6],
[60,55,11,7],
[60,55,11,8],
[60,55,11,9],
[60,55,12,0],
[60,55,12,1],
[60,55,12,2],
[60,55,12,3],
[60,55,12,4],
[60,55,12,5],
[60,55,12,6],
[60,55,12,7],
[60,55,12,8],
[60,55,12,9],
[60,55,15,0],
[60,55,15,1],
[60,55,15,2],
[60,55,15,3],
[60,55,15,4],
[60,55,15,5],
[60,55,15,6],
[60,55,15,7],
[60,55,15,8],
[60,55,15,11],
[60,55,16,0],
[60,55,16,1],
[60,55,16,2],
[60,55,16,3],
[60,55,16,4],
[60,55,16,5],
[60,55,16,6],
[60,55,16,7],
[60,55,16,8],
[60,55,16,9],
[60,55,16,12],
[60,55,16,15],
[60,55,17,0],
[60,55,17,1],
[60,55,17,2],
[60,55,17,3],
[60,55,17,4],
[60,55,17,5],
[60,55,17,6],
[60,55,17,7],
[60,55,17,8],
[60,55,17,9],
[60,55,17,12],
[60,55,17,15],
[60,55,17,16],
[60,55,18,0],
[60,55,18,1],
[60,55,18,2],
[60,55,18,3],
[60,55,18,4],
[60,55,18,5],
[60,55,18,6],
[60,55,18,7],
[60,55,18,11],
[60,55,18,12],
[60,55,18,16],
[60,55,18,17],
[60,55,21,0],
[60,55,21,1],
[60,55,21,2],
[60,55,21,7],
[60,55,21,9],
[60,55,21,11],
[60,55,21,12],
[60,55,21,15],
[60,55,21,16],
[60,55,21,17],
[60,55,21,18],
[60,55,23,0],
[60,55,23,1],
[60,55,23,2],
[60,55,23,3],
[60,55,23,4],
[60,55,23,5],
[60,55,23,6],
[60,55,23,7],
[60,55,23,8],
[60,55,23,9],
[60,55,23,11],
[60,55,23,12],
[60,55,23,15],
[60,55,23,16],
[60,55,23,17],
[60,55,23,18],
[60,55,24,0],
[60,55,24,1],
[60,55,24,2],
[60,55,24,3],
[60,55,24,4],
[60,55,24,5],
[60,55,24,6],
[60,55,24,7],
[60,55,24,8],
[60,55,24,9],
[60,55,24,11],
[60,55,24,12],
[60,55,24,15],
[60,55,24,17],
[60,55,24,18],
[60,55,25,0],
[60,55,25,1],
[60,55,25,2],
[60,55,25,3],
[60,55,25,4],
[60,55,25,5],
[60,55,25,6],
[60,55,25,8],
[60,55,25,9],
[60,55,25,11],
[60,55,25,12],
[60,55,25,15],
[60,55,25,16],
[60,55,25,17],
[60,55,26,0],
[60,55,26,1],
[60,55,26,2],
[60,55,26,3],
[60,55,26,4],
[60,55,26,5],
[60,55,26,6],
[60,55,26,7],
[60,55,26,8],
[60,55,26,11],
[60,55,26,12],
[60,55,28,0],
[60,55,28,1],
[60,55,28,2],
[60,55,28,3],
[60,55,28,4],
[60,55,28,5],
[60,55,28,6],
[60,55,28,8],
[60,55,28,9],
[60,55,28,11],
[60,55,28,12],
[60,55,29,0],
[60,55,29,1],
[60,55,29,2],
[60,55,29,3],
[60,55,29,4],
[60,55,29,5],
[60,55,29,6],
[60,55,29,7],
[60,55,29,8],
[60,55,29,9],
[60,55,30,0],
[60,55,30,1],
[60,55,30,2],
[60,55,30,3],
[60,55,30,4],
[60,55,30,5],
[60,55,30,6],
[60,55,30,7],
[60,55,30,8],
[60,55,30,9],
[60,55,31,0],
[60,55,31,1],
[60,55,31,2],
[60,55,31,3],
[60,55,31,4],
[60,55,31,5],
[60,55,31,6],
[60,55,31,7],
[60,55,32,0],
[60,55,32,1],
[60,55,32,2],
[60,55,32,3],
[60,55,32,4],
[60,55,32,5],
[60,55,33,0],
[60,55,33,1],
[60,55,33,2],
[60,55,33,3],
[60,55,33,4],
[60,55,34,0],
[60,55,34,1],
[60,55,34,2],
[60,56,3,0],
[60,56,3,1],
[60,56,3,2],
[60,56,4,0],
[60,56,4,1],
[60,56,4,2],
[60,56,4,3],
[60,56,5,0],
[60,56,5,1],
[60,56,5,2],
[60,56,5,4],
[60,56,6,0],
[60,56,6,1],
[60,56,6,2],
[60,56,6,4],
[60,56,7,0],
[60,56,7,1],
[60,56,7,2],
[60,56,7,3],
[60,56,7,4],
[60,56,7,5],
[60,56,7,6],
[60,56,8,0],
[60,56,8,1],
[60,56,8,2],
[60,56,8,4],
[60,56,8,7],
[60,56,10,0],
[60,56,10,1],
[60,56,10,2],
[60,56,10,3],
[60,56,10,4],
[60,56,10,5],
[60,56,10,6],
[60,56,10,7],
[60,56,10,8],
[60,56,11,0],
[60,56,11,1],
[60,56,11,2],
[60,56,11,3],
[60,56,11,4],
[60,56,11,5],
[60,56,11,6],
[60,56,11,7],
[60,56,11,8],
[60,56,11,10],
[60,56,12,0],
[60,56,12,1],
[60,56,12,2],
[60,56,12,3],
[60,56,12,4],
[60,56,12,5],
[60,56,12,6],
[60,56,12,7],
[60,56,12,8],
[60,56,12,10],
[60,56,16,0],
[60,56,16,1],
[60,56,16,2],
[60,56,16,3],
[60,56,16,4],
[60,56,16,5],
[60,56,16,6],
[60,56,16,7],
[60,56,16,8],
[60,56,16,12],
[60,56,17,0],
[60,56,17,1],
[60,56,17,2],
[60,56,17,3],
[60,56,17,4],
[60,56,17,5],
[60,56,17,6],
[60,56,17,7],
[60,56,17,8],
[60,56,17,12],
[60,56,17,16],
[60,56,20,0],
[60,56,20,1],
[60,56,20,2],
[60,56,20,3],
[60,56,20,4],
[60,56,20,6],
[60,56,20,7],
[60,56,20,8],
[60,56,20,11],
[60,56,20,12],
[60,56,20,16],
[60,56,20,17],
[60,56,21,0],
[60,56,21,1],
[60,56,21,2],
[60,56,21,7],
[60,56,21,10],
[60,56,21,11],
[60,56,21,12],
[60,56,21,16],
[60,56,21,17],
[60,56,21,20],
[60,56,22,3],
[60,56,22,4],
[60,56,22,5],
[60,56,22,6],
[60,56,22,7],
[60,56,22,8],
[60,56,22,11],
[60,56,22,12],
[60,56,22,16],
[60,56,22,17],
[60,56,23,0],
[60,56,23,1],
[60,56,23,2],
[60,56,23,3],
[60,56,23,4],
[60,56,23,5],
[60,56,23,6],
[60,56,23,7],
[60,56,23,8],
[60,56,23,10],
[60,56,23,11],
[60,56,23,12],
[60,56,23,16],
[60,56,23,17],
[60,56,24,0],
[60,56,24,1],
[60,56,24,2],
[60,56,24,3],
[60,56,24,4],
[60,56,24,5],
[60,56,24,6],
[60,56,24,7],
[60,56,24,8],
[60,56,24,10],
[60,56,24,11],
[60,56,24,12],
[60,56,24,17],
[60,56,25,0],
[60,56,25,1],
[60,56,25,2],
[60,56,25,3],
[60,56,25,4],
[60,56,25,5],
[60,56,25,6],
[60,56,25,8],
[60,56,25,10],
[60,56,25,11],
[60,56,25,12],
[60,56,25,16],
[60,56,25,17],
[60,56,27,0],
[60,56,27,1],
[60,56,27,2],
[60,56,27,3],
[60,56,27,4],
[60,56,27,5],
[60,56,27,6],
[60,56,27,7],
[60,56,27,8],
[60,56,27,11],
[60,56,27,12],
[60,56,28,0],
[60,56,28,1],
[60,56,28,2],
[60,56,28,3],
[60,56,28,4],
[60,56,28,5],
[60,56,28,6],
[60,56,28,8],
[60,56,28,10],
[60,56,28,11],
[60,56,28,12],
[60,56,29,0],
[60,56,29,1],
[60,56,29,2],
[60,56,29,3],
[60,56,29,4],
[60,56,29,5],
[60,56,29,6],
[60,56,29,7],
[60,56,29,8],
[60,56,29,10],
[60,56,30,0],
[60,56,30,1],
[60,56,30,2],
[60,56,30,3],
[60,56,30,4],
[60,56,30,5],
[60,56,30,6],
[60,56,30,7],
[60,56,30,8],
[60,56,31,0],
[60,56,31,1],
[60,56,31,2],
[60,56,31,3],
[60,56,31,4],
[60,56,31,5],
[60,56,31,6],
[60,56,31,7],
[60,56,32,0],
[60,56,32,1],
[60,56,32,2],
[60,56,32,3],
[60,56,32,4],
[60,56,32,5],
[60,56,33,0],
[60,56,33,1],
[60,56,33,2],
[60,56,33,3],
[60,56,33,4],
[60,56,34,0],
[60,56,34,1],
[60,56,34,2],
[60,57,3,0],
[60,57,3,1],
[60,57,3,2],
[60,57,4,0],
[60,57,4,1],
[60,57,4,2],
[60,57,4,3],
[60,57,5,0],
[60,57,5,1],
[60,57,5,2],
[60,57,5,4],
[60,57,6,0],
[60,57,6,1],
[60,57,6,2],
[60,57,6,4],
[60,57,7,0],
[60,57,7,1],
[60,57,7,2],
[60,57,7,3],
[60,57,7,4],
[60,57,7,5],
[60,57,7,6],
[60,57,8,0],
[60,57,8,1],
[60,57,8,2],
[60,57,8,4],
[60,57,8,7],
[60,57,9,0],
[60,57,9,1],
[60,57,9,2],
[60,57,9,3],
[60,57,9,4],
[60,57,9,5],
[60,57,9,6],
[60,57,9,7],
[60,57,9,8],
[60,57,10,0],
[60,57,10,1],
[60,57,10,2],
[60,57,10,3],
[60,57,10,4],
[60,57,10,5],
[60,57,10,6],
[60,57,10,7],
[60,57,10,8],
[60,57,10,9],
[60,57,11,0],
[60,57,11,1],
[60,57,11,2],
[60,57,11,3],
[60,57,11,4],
[60,57,11,5],
[60,57,11,6],
[60,57,11,7],
[60,57,11,8],
[60,57,11,9],
[60,57,11,10],
[60,57,12,0],
[60,57,12,1],
[60,57,12,2],
[60,57,12,3],
[60,57,12,4],
[60,57,12,5],
[60,57,12,6],
[60,57,12,7],
[60,57,12,8],
[60,57,12,9],
[60,57,12,10],
[60,57,15,0],
[60,57,15,1],
[60,57,15,2],
[60,57,15,3],
[60,57,15,4],
[60,57,15,5],
[60,57,15,6],
[60,57,15,7],
[60,57,15,8],
[60,57,15,12],
[60,57,16,0],
[60,57,16,1],
[60,57,16,2],
[60,57,16,3],
[60,57,16,4],
[60,57,16,5],
[60,57,16,6],
[60,57,16,7],
[60,57,16,10],
[60,57,16,11],
[60,57,16,12],
[60,57,16,15],
[60,57,17,0],
[60,57,17,1],
[60,57,17,2],
[60,57,17,3],
[60,57,17,4],
[60,57,17,5],
[60,57,17,6],
[60,57,17,7],
[60,57,17,10],
[60,57,17,11],
[60,57,17,12],
[60,57,17,15],
[60,57,17,16],
[60,57,18,0],
[60,57,18,1],
[60,57,18,2],
[60,57,18,3],
[60,57,18,4],
[60,57,18,5],
[60,57,18,8],
[60,57,18,10],
[60,57,18,11],
[60,57,18,12],
[60,57,18,16],
[60,57,18,17],
[60,57,20,0],
[60,57,20,1],
[60,57,20,2],
[60,57,20,5],
[60,57,20,6],
[60,57,20,7],
[60,57,20,8],
[60,57,20,9],
[60,57,20,11],
[60,57,20,12],
[60,57,20,15],
[60,57,20,16],
[60,57,20,17],
[60,57,20,18],
[60,57,21,4],
[60,57,21,7],
[60,57,21,9],
[60,57,21,10],
[60,57,21,11],
[60,57,21,12],
[60,57,21,15],
[60,57,21,16],
[60,57,21,17],
[60,57,21,18],
[60,57,21,20],
[60,57,22,0],
[60,57,22,1],
[60,57,22,2],
[60,57,22,3],
[60,57,22,4],
[60,57,22,5],
[60,57,22,6],
[60,57,22,7],
[60,57,22,8],
[60,57,22,9],
[60,57,22,11],
[60,57,22,12],
[60,57,22,15],
[60,57,22,16],
[60,57,22,17],
[60,57,22,18],
[60,57,24,0],
[60,57,24,1],
[60,57,24,2],
[60,57,24,3],
[60,57,24,4],
[60,57,24,5],
[60,57,24,6],
[60,57,24,7],
[60,57,24,8],
[60,57,24,9],
[60,57,24,10],
[60,57,24,11],
[60,57,24,12],
[60,57,24,15],
[60,57,24,17],
[60,57,24,18],
[60,57,25,0],
[60,57,25,1],
[60,57,25,2],
[60,57,25,3],
[60,57,25,4],
[60,57,25,5],
[60,57,25,6],
[60,57,25,8],
[60,57,25,9],
[60,57,25,10],
[60,57,25,11],
[60,57,25,12],
[60,57,25,15],
[60,57,26,0],
[60,57,26,1],
[60,57,26,2],
[60,57,26,3],
[60,57,26,4],
[60,57,26,5],
[60,57,26,6],
[60,57,26,7],
[60,57,26,8],
[60,57,26,10],
[60,57,26,11],
[60,57,26,12],
[60,57,27,0],
[60,57,27,1],
[60,57,27,2],
[60,57,27,3],
[60,57,27,4],
[60,57,27,5],
[60,57,27,6],
[60,57,27,7],
[60,57,27,8],
[60,57,27,9],
[60,57,27,11],
[60,57,27,12],
[60,57,28,0],
[60,57,28,1],
[60,57,28,2],
[60,57,28,3],
[60,57,28,4],
[60,57,28,5],
[60,57,28,6],
[60,57,28,8],
[60,57,28,9],
[60,57,28,10],
[60,57,28,11],
[60,57,29,0],
[60,57,29,1],
[60,57,29,2],
[60,57,29,3],
[60,57,29,4],
[60,57,29,5],
[60,57,29,6],
[60,57,29,7],
[60,57,29,8],
[60,57,29,9],
[60,57,32,0],
[60,57,32,1],
[60,57,32,2],
[60,57,32,3],
[60,57,32,4],
[60,57,33,0],
[60,57,33,1],
[60,57,33,2],
[60,58,3,0],
[60,58,3,1],
[60,58,3,2],
[60,58,4,0],
[60,58,4,1],
[60,58,4,2],
[60,58,4,3],
[60,58,5,0],
[60,58,5,1],
[60,58,5,2],
[60,58,5,4],
[60,58,6,0],
[60,58,6,1],
[60,58,6,2],
[60,58,6,4],
[60,58,7,0],
[60,58,7,1],
[60,58,7,2],
[60,58,7,3],
[60,58,7,4],
[60,58,7,5],
[60,58,7,6],
[60,58,8,0],
[60,58,8,1],
[60,58,8,2],
[60,58,8,4],
[60,58,8,7],
[60,58,9,0],
[60,58,9,1],
[60,58,9,2],
[60,58,9,3],
[60,58,9,4],
[60,58,9,5],
[60,58,9,6],
[60,58,9,7],
[60,58,9,8],
[60,58,10,0],
[60,58,10,1],
[60,58,10,2],
[60,58,10,3],
[60,58,10,4],
[60,58,10,5],
[60,58,10,6],
[60,58,10,7],
[60,58,10,8],
[60,58,10,9],
[60,58,11,0],
[60,58,11,1],
[60,58,11,2],
[60,58,11,3],
[60,58,11,4],
[60,58,11,5],
[60,58,11,6],
[60,58,11,7],
[60,58,11,8],
[60,58,11,9],
[60,58,11,10],
[60,58,12,0],
[60,58,12,1],
[60,58,12,2],
[60,58,12,3],
[60,58,12,4],
[60,58,12,5],
[60,58,12,6],
[60,58,12,7],
[60,58,12,8],
[60,58,12,9],
[60,58,12,10],
[60,58,15,0],
[60,58,15,1],
[60,58,15,2],
[60,58,15,3],
[60,58,15,4],
[60,58,15,5],
[60,58,15,6],
[60,58,15,7],
[60,58,15,8],
[60,58,15,12],
[60,58,17,0],
[60,58,17,1],
[60,58,17,2],
[60,58,17,3],
[60,58,17,4],
[60,58,17,5],
[60,58,17,6],
[60,58,17,7],
[60,58,17,10],
[60,58,17,11],
[60,58,17,12],
[60,58,17,15],
[60,58,18,0],
[60,58,18,1],
[60,58,18,2],
[60,58,18,3],
[60,58,18,4],
[60,58,18,5],
[60,58,18,8],
[60,58,18,10],
[60,58,18,11],
[60,58,18,12],
[60,58,18,17],
[60,58,20,0],
[60,58,20,1],
[60,58,20,2],
[60,58,20,5],
[60,58,20,6],
[60,58,20,7],
[60,58,20,8],
[60,58,20,9],
[60,58,20,11],
[60,58,20,12],
[60,58,20,15],
[60,58,20,17],
[60,58,20,18],
[60,58,21,4],
[60,58,21,7],
[60,58,21,9],
[60,58,21,10],
[60,58,21,11],
[60,58,21,12],
[60,58,21,15],
[60,58,21,17],
[60,58,21,18],
[60,58,21,20],
[60,58,22,0],
[60,58,22,1],
[60,58,22,2],
[60,58,22,3],
[60,58,22,4],
[60,58,22,5],
[60,58,22,6],
[60,58,22,7],
[60,58,22,8],
[60,58,22,9],
[60,58,22,11],
[60,58,22,12],
[60,58,22,15],
[60,58,22,17],
[60,58,22,18],
[60,58,23,0],
[60,58,23,1],
[60,58,23,2],
[60,58,23,3],
[60,58,23,4],
[60,58,23,5],
[60,58,23,6],
[60,58,23,7],
[60,58,23,8],
[60,58,23,9],
[60,58,23,10],
[60,58,23,11],
[60,58,23,12],
[60,58,23,15],
[60,58,23,17],
[60,58,23,18],
[60,58,25,0],
[60,58,25,1],
[60,58,25,2],
[60,58,25,3],
[60,58,25,4],
[60,58,25,5],
[60,58,25,6],
[60,58,25,8],
[60,58,25,9],
[60,58,25,10],
[60,58,25,11],
[60,58,25,12],
[60,58,25,15],
[60,58,26,0],
[60,58,26,1],
[60,58,26,2],
[60,58,26,3],
[60,58,26,4],
[60,58,26,5],
[60,58,26,6],
[60,58,26,7],
[60,58,26,8],
[60,58,26,10],
[60,58,26,11],
[60,58,26,12],
[60,58,27,0],
[60,58,27,1],
[60,58,27,2],
[60,58,27,3],
[60,58,27,4],
[60,58,27,5],
[60,58,27,6],
[60,58,27,7],
[60,58,27,8],
[60,58,27,9],
[60,58,27,11],
[60,58,27,12],
[60,58,28,0],
[60,58,28,1],
[60,58,28,2],
[60,58,28,3],
[60,58,28,4],
[60,58,28,5],
[60,58,28,6],
[60,58,28,8],
[60,58,28,9],
[60,58,28,10],
[60,58,28,11],
[60,58,29,0],
[60,58,29,1],
[60,58,29,2],
[60,58,29,3],
[60,58,29,4],
[60,58,29,5],
[60,58,29,6],
[60,58,29,7],
[60,58,29,8],
[60,58,29,9],
[60,58,30,0],
[60,58,30,1],
[60,58,30,2],
[60,58,30,3],
[60,58,30,4],
[60,58,30,5],
[60,58,30,6],
[60,58,30,7],
[60,58,31,0],
[60,58,31,1],
[60,58,31,2],
[60,58,31,3],
[60,58,31,4],
[60,58,31,5],
[60,58,32,0],
[60,58,32,1],
[60,58,32,2],
[60,58,32,3],
[60,58,32,4],
[60,58,33,0],
[60,58,33,1],
[60,58,33,2],
[60,59,3,0],
[60,59,3,1],
[60,59,3,2],
[60,59,4,0],
[60,59,4,1],
[60,59,4,2],
[60,59,4,3],
[60,59,5,0],
[60,59,5,1],
[60,59,5,2],
[60,59,5,4],
[60,59,6,0],
[60,59,6,1],
[60,59,6,2],
[60,59,6,4],
[60,59,7,0],
[60,59,7,1],
[60,59,7,2],
[60,59,7,3],
[60,59,7,4],
[60,59,7,5],
[60,59,7,6],
[60,59,8,0],
[60,59,8,1],
[60,59,8,2],
[60,59,8,4],
[60,59,8,7],
[60,59,10,0],
[60,59,10,1],
[60,59,10,2],
[60,59,10,3],
[60,59,10,4],
[60,59,10,5],
[60,59,10,6],
[60,59,10,7],
[60,59,10,8],
[60,59,11,0],
[60,59,11,1],
[60,59,11,2],
[60,59,11,3],
[60,59,11,4],
[60,59,11,5],
[60,59,11,6],
[60,59,11,7],
[60,59,11,8],
[60,59,11,10],
[60,59,12,0],
[60,59,12,1],
[60,59,12,2],
[60,59,12,3],
[60,59,12,4],
[60,59,12,5],
[60,59,12,6],
[60,59,12,7],
[60,59,12,8],
[60,59,12,10],
[60,59,16,0],
[60,59,16,1],
[60,59,16,2],
[60,59,16,3],
[60,59,16,4],
[60,59,16,5],
[60,59,16,8],
[60,59,16,10],
[60,59,16,11],
[60,59,16,12],
[60,59,17,0],
[60,59,17,1],
[60,59,17,2],
[60,59,17,3],
[60,59,17,4],
[60,59,17,5],
[60,59,17,8],
[60,59,17,10],
[60,59,17,11],
[60,59,17,12],
[60,59,17,16],
[60,59,20,3],
[60,59,20,4],
[60,59,20,5],
[60,59,20,6],
[60,59,20,7],
[60,59,20,8],
[60,59,20,11],
[60,59,20,12],
[60,59,20,16],
[60,59,20,17],
[60,59,21,0],
[60,59,21,1],
[60,59,21,2],
[60,59,21,4],
[60,59,21,7],
[60,59,21,10],
[60,59,21,11],
[60,59,21,12],
[60,59,21,16],
[60,59,21,17],
[60,59,22,0],
[60,59,22,1],
[60,59,22,2],
[60,59,22,3],
[60,59,22,4],
[60,59,22,5],
[60,59,22,6],
[60,59,22,7],
[60,59,22,8],
[60,59,22,11],
[60,59,22,12],
[60,59,22,16],
[60,59,22,17],
[60,59,23,0],
[60,59,23,1],
[60,59,23,2],
[60,59,23,3],
[60,59,23,4],
[60,59,23,5],
[60,59,23,6],
[60,59,23,7],
[60,59,23,8],
[60,59,23,10],
[60,59,23,11],
[60,59,23,12],
[60,59,23,16],
[60,59,23,17],
[60,59,24,0],
[60,59,24,1],
[60,59,24,2],
[60,59,24,3],
[60,59,24,4],
[60,59,24,5],
[60,59,24,6],
[60,59,24,7],
[60,59,24,8],
[60,59,24,10],
[60,59,24,11],
[60,59,24,12],
[60,59,24,17],
[60,59,25,0],
[60,59,25,1],
[60,59,25,2],
[60,59,25,3],
[60,59,25,4],
[60,59,25,5],
[60,59,25,6],
[60,59,25,8],
[60,59,25,10],
[60,59,25,11],
[60,59,25,12],
[60,59,27,0],
[60,59,27,1],
[60,59,27,2],
[60,59,27,3],
[60,59,27,4],
[60,59,27,5],
[60,59,27,6],
[60,59,27,7],
[60,59,27,8],
[60,59,27,11],
[60,59,28,0],
[60,59,28,1],
[60,59,28,2],
[60,59,28,3],
[60,59,28,4],
[60,59,28,5],
[60,59,28,6],
[60,59,28,8],
[60,59,29,0],
[60,59,29,1],
[60,59,29,2],
[60,59,29,3],
[60,59,29,4],
[60,59,29,5],
[60,59,29,6],
[60,59,29,7],
[60,59,30,0],
[60,59,30,1],
[60,59,30,2],
[60,59,30,3],
[60,59,30,4],
[60,59,30,5],
[60,59,31,0],
[60,59,31,1],
[60,59,31,2],
[60,59,31,3],
[60,59,31,4],
[60,59,32,0],
[60,59,32,1],
[60,59,32,2],
[61,7,3,0],
[61,7,3,1],
[61,7,3,2],
[61,7,5,0],
[61,7,5,1],
[61,7,5,2],
[61,7,6,0],
[61,7,6,1],
[61,7,6,2],
[61,8,7,0],
[61,8,7,1],
[61,8,7,2],
[61,9,3,0],
[61,9,3,1],
[61,9,3,2],
[61,9,5,0],
[61,9,5,1],
[61,9,5,2],
[61,9,6,0],
[61,9,6,1],
[61,9,6,2],
[61,9,7,0],
[61,9,7,1],
[61,9,7,2],
[61,9,7,3],
[61,9,7,5],
[61,9,7,6],
[61,9,8,0],
[61,9,8,1],
[61,9,8,2],
[61,9,8,7],
[61,10,3,0],
[61,10,3,1],
[61,10,3,2],
[61,10,5,0],
[61,10,5,1],
[61,10,5,2],
[61,10,6,0],
[61,10,6,1],
[61,10,6,2],
[61,10,7,0],
[61,10,7,1],
[61,10,7,2],
[61,10,7,3],
[61,10,7,5],
[61,10,7,6],
[61,10,8,0],
[61,10,8,1],
[61,10,8,2],
[61,10,8,7],
[61,10,9,0],
[61,10,9,1],
[61,10,9,2],
[61,10,9,3],
[61,10,9,5],
[61,10,9,6],
[61,10,9,7],
[61,10,9,8],
[61,11,3,0],
[61,11,3,1],
[61,11,3,2],
[61,11,5,0],
[61,11,5,1],
[61,11,5,2],
[61,11,6,0],
[61,11,6,1],
[61,11,6,2],
[61,11,7,0],
[61,11,7,1],
[61,11,7,2],
[61,11,7,3],
[61,11,7,5],
[61,11,7,6],
[61,11,8,0],
[61,11,8,1],
[61,11,8,2],
[61,11,8,7],
[61,11,9,0],
[61,11,9,1],
[61,11,9,2],
[61,11,9,3],
[61,11,9,5],
[61,11,9,6],
[61,11,9,7],
[61,11,9,8],
[61,11,10,0],
[61,11,10,1],
[61,11,10,2],
[61,11,10,3],
[61,11,10,5],
[61,11,10,6],
[61,11,10,7],
[61,11,10,8],
[61,11,10,9],
[61,12,3,0],
[61,12,3,1],
[61,12,3,2],
[61,12,5,0],
[61,12,5,1],
[61,12,5,2],
[61,12,6,0],
[61,12,6,1],
[61,12,6,2],
[61,12,7,0],
[61,12,7,1],
[61,12,7,2],
[61,12,7,3],
[61,12,7,5],
[61,12,7,6],
[61,12,8,0],
[61,12,8,1],
[61,12,8,2],
[61,12,8,7],
[61,12,9,0],
[61,12,9,1],
[61,12,9,2],
[61,12,9,3],
[61,12,9,5],
[61,12,9,6],
[61,12,9,7],
[61,12,9,8],
[61,12,10,0],
[61,12,10,1],
[61,12,10,2],
[61,12,10,3],
[61,12,10,5],
[61,12,10,6],
[61,12,10,7],
[61,12,10,8],
[61,12,10,9],
[61,13,3,0],
[61,13,3,1],
[61,13,3,2],
[61,13,5,0],
[61,13,5,1],
[61,13,5,2],
[61,13,6,0],
[61,13,6,1],
[61,13,6,2],
[61,13,7,0],
[61,13,7,1],
[61,13,7,2],
[61,13,7,3],
[61,13,7,5],
[61,13,7,6],
[61,13,8,0],
[61,13,8,1],
[61,13,8,2],
[61,13,8,7],
[61,13,9,0],
[61,13,9,1],
[61,13,9,2],
[61,13,9,3],
[61,13,9,5],
[61,13,9,6],
[61,13,9,7],
[61,13,9,8],
[61,13,10,0],
[61,13,10,1],
[61,13,10,2],
[61,13,10,3],
[61,13,10,5],
[61,13,10,6],
[61,13,10,7],
[61,13,10,8],
[61,13,10,9],
[61,13,11,0],
[61,13,11,1],
[61,13,11,2],
[61,13,11,3],
[61,13,11,5],
[61,13,11,6],
[61,13,11,7],
[61,13,11,8],
[61,13,11,9],
[61,13,11,10],
[61,13,12,0],
[61,13,12,1],
[61,13,12,2],
[61,13,12,3],
[61,13,12,5],
[61,13,12,6],
[61,13,12,7],
[61,13,12,8],
[61,13,12,9],
[61,13,12,10],
[61,14,3,0],
[61,14,3,1],
[61,14,3,2],
[61,14,5,0],
[61,14,5,1],
[61,14,5,2],
[61,14,6,0],
[61,14,6,1],
[61,14,6,2],
[61,14,7,0],
[61,14,7,1],
[61,14,7,2],
[61,14,7,3],
[61,14,7,5],
[61,14,7,6],
[61,14,8,0],
[61,14,8,1],
[61,14,8,2],
[61,14,8,7],
[61,14,9,0],
[61,14,9,1],
[61,14,9,2],
[61,14,9,3],
[61,14,9,5],
[61,14,9,6],
[61,14,9,7],
[61,14,9,8],
[61,14,10,0],
[61,14,10,1],
[61,14,10,2],
[61,14,10,3],
[61,14,10,5],
[61,14,10,6],
[61,14,10,7],
[61,14,10,8],
[61,14,10,9],
[61,14,11,0],
[61,14,11,1],
[61,14,11,2],
[61,14,11,3],
[61,14,11,5],
[61,14,11,6],
[61,14,11,7],
[61,14,11,8],
[61,14,11,9],
[61,14,11,10],
[61,14,12,0],
[61,14,12,1],
[61,14,12,2],
[61,14,12,3],
[61,14,12,5],
[61,14,12,6],
[61,14,12,7],
[61,14,12,8],
[61,14,12,9],
[61,14,12,10],
[61,15,3,0],
[61,15,3,1],
[61,15,3,2],
[61,15,5,0],
[61,15,5,1],
[61,15,5,2],
[61,15,6,0],
[61,15,6,1],
[61,15,6,2],
[61,15,7,0],
[61,15,7,1],
[61,15,7,2],
[61,15,7,3],
[61,15,7,5],
[61,15,7,6],
[61,15,8,0],
[61,15,8,1],
[61,15,8,2],
[61,15,8,7],
[61,15,10,0],
[61,15,10,1],
[61,15,10,2],
[61,15,10,3],
[61,15,10,5],
[61,15,10,6],
[61,15,10,7],
[61,15,10,8],
[61,15,11,0],
[61,15,11,1],
[61,15,11,2],
[61,15,11,3],
[61,15,11,5],
[61,15,11,6],
[61,15,11,7],
[61,15,11,8],
[61,15,11,10],
[61,15,12,0],
[61,15,12,1],
[61,15,12,2],
[61,15,12,3],
[61,15,12,5],
[61,15,12,6],
[61,15,12,7],
[61,15,12,8],
[61,15,12,10],
[61,15,13,0],
[61,15,13,1],
[61,15,13,2],
[61,15,13,3],
[61,15,13,5],
[61,15,13,6],
[61,15,13,7],
[61,15,13,8],
[61,15,13,10],
[61,15,13,11],
[61,15,13,12],
[61,15,14,0],
[61,15,14,1],
[61,15,14,2],
[61,15,14,3],
[61,15,14,5],
[61,15,14,6],
[61,15,14,7],
[61,15,14,8],
[61,15,14,10],
[61,15,14,11],
[61,15,14,12],
[61,16,3,0],
[61,16,3,1],
[61,16,3,2],
[61,16,5,0],
[61,16,5,1],
[61,16,5,2],
[61,16,6,0],
[61,16,6,1],
[61,16,6,2],
[61,16,7,0],
[61,16,7,1],
[61,16,7,2],
[61,16,7,3],
[61,16,7,5],
[61,16,7,6],
[61,16,8,0],
[61,16,8,1],
[61,16,8,2],
[61,16,8,7],
[61,16,9,0],
[61,16,9,1],
[61,16,9,2],
[61,16,9,3],
[61,16,9,5],
[61,16,9,6],
[61,16,9,7],
[61,16,9,8],
[61,16,10,0],
[61,16,10,1],
[61,16,10,2],
[61,16,10,3],
[61,16,10,5],
[61,16,10,6],
[61,16,10,7],
[61,16,10,8],
[61,16,10,9],
[61,16,11,0],
[61,16,11,1],
[61,16,11,2],
[61,16,11,3],
[61,16,11,5],
[61,16,11,6],
[61,16,11,7],
[61,16,11,8],
[61,16,11,9],
[61,16,11,10],
[61,16,12,0],
[61,16,12,1],
[61,16,12,2],
[61,16,12,3],
[61,16,12,5],
[61,16,12,6],
[61,16,12,7],
[61,16,12,8],
[61,16,12,9],
[61,16,12,10],
[61,16,13,0],
[61,16,13,1],
[61,16,13,2],
[61,16,13,3],
[61,16,13,5],
[61,16,13,6],
[61,16,13,7],
[61,16,13,8],
[61,16,13,9],
[61,16,13,10],
[61,16,13,11],
[61,16,13,12],
[61,16,14,0],
[61,16,14,1],
[61,16,14,2],
[61,16,14,3],
[61,16,14,5],
[61,16,14,6],
[61,16,14,7],
[61,16,14,8],
[61,16,14,9],
[61,16,14,10],
[61,16,14,11],
[61,16,14,12],
[61,16,15,0],
[61,16,15,1],
[61,16,15,2],
[61,16,15,3],
[61,16,15,5],
[61,16,15,6],
[61,16,15,7],
[61,16,15,8],
[61,16,15,10],
[61,16,15,11],
[61,16,15,12],
[61,16,15,13],
[61,16,15,14],
[61,17,3,0],
[61,17,3,1],
[61,17,3,2],
[61,17,5,0],
[61,17,5,1],
[61,17,5,2],
[61,17,6,0],
[61,17,6,1],
[61,17,6,2],
[61,17,7,0],
[61,17,7,1],
[61,17,7,2],
[61,17,7,3],
[61,17,7,5],
[61,17,7,6],
[61,17,8,0],
[61,17,8,1],
[61,17,8,2],
[61,17,8,7],
[61,17,9,0],
[61,17,9,1],
[61,17,9,2],
[61,17,9,3],
[61,17,9,5],
[61,17,9,6],
[61,17,9,7],
[61,17,9,8],
[61,17,10,0],
[61,17,10,1],
[61,17,10,2],
[61,17,10,3],
[61,17,10,5],
[61,17,10,6],
[61,17,10,7],
[61,17,10,8],
[61,17,10,9],
[61,17,11,0],
[61,17,11,1],
[61,17,11,2],
[61,17,11,3],
[61,17,11,5],
[61,17,11,6],
[61,17,11,7],
[61,17,11,8],
[61,17,11,9],
[61,17,11,10],
[61,17,12,0],
[61,17,12,1],
[61,17,12,2],
[61,17,12,3],
[61,17,12,5],
[61,17,12,6],
[61,17,12,7],
[61,17,12,8],
[61,17,12,9],
[61,17,12,10],
[61,17,13,0],
[61,17,13,1],
[61,17,13,2],
[61,17,13,3],
[61,17,13,5],
[61,17,13,6],
[61,17,13,7],
[61,17,13,8],
[61,17,13,9],
[61,17,13,10],
[61,17,13,11],
[61,17,13,12],
[61,17,14,0],
[61,17,14,1],
[61,17,14,2],
[61,17,14,3],
[61,17,14,5],
[61,17,14,6],
[61,17,14,7],
[61,17,14,8],
[61,17,14,9],
[61,17,14,10],
[61,17,14,11],
[61,17,14,12],
[61,17,15,0],
[61,17,15,1],
[61,17,15,2],
[61,17,15,3],
[61,17,15,5],
[61,17,15,6],
[61,17,15,7],
[61,17,15,8],
[61,17,15,10],
[61,17,15,11],
[61,17,15,12],
[61,17,15,13],
[61,17,15,14],
[61,17,16,0],
[61,17,16,1],
[61,17,16,2],
[61,17,16,3],
[61,17,16,5],
[61,17,16,6],
[61,17,16,7],
[61,17,16,8],
[61,17,16,9],
[61,17,16,10],
[61,17,16,11],
[61,17,16,12],
[61,17,16,13],
[61,17,16,14],
[61,17,16,15],
[61,18,3,0],
[61,18,3,1],
[61,18,3,2],
[61,18,5,0],
[61,18,5,1],
[61,18,5,2],
[61,18,6,0],
[61,18,6,1],
[61,18,6,2],
[61,18,7,0],
[61,18,7,1],
[61,18,7,2],
[61,18,7,3],
[61,18,7,5],
[61,18,7,6],
[61,18,8,0],
[61,18,8,1],
[61,18,8,2],
[61,18,8,7],
[61,18,10,0],
[61,18,10,1],
[61,18,10,2],
[61,18,10,3],
[61,18,10,5],
[61,18,10,6],
[61,18,10,7],
[61,18,10,8],
[61,18,11,0],
[61,18,11,1],
[61,18,11,2],
[61,18,11,3],
[61,18,11,5],
[61,18,11,6],
[61,18,11,7],
[61,18,11,8],
[61,18,11,10],
[61,18,12,0],
[61,18,12,1],
[61,18,12,2],
[61,18,12,3],
[61,18,12,5],
[61,18,12,6],
[61,18,12,7],
[61,18,12,8],
[61,18,12,10],
[61,18,13,0],
[61,18,13,1],
[61,18,13,2],
[61,18,13,3],
[61,18,13,5],
[61,18,13,6],
[61,18,13,7],
[61,18,13,8],
[61,18,13,10],
[61,18,13,11],
[61,18,13,12],
[61,18,14,0],
[61,18,14,1],
[61,18,14,2],
[61,18,14,3],
[61,18,14,5],
[61,18,14,6],
[61,18,14,7],
[61,18,14,8],
[61,18,14,10],
[61,18,14,11],
[61,18,14,12],
[61,18,16,0],
[61,18,16,1],
[61,18,16,2],
[61,18,16,3],
[61,18,16,5],
[61,18,16,6],
[61,18,16,7],
[61,18,16,8],
[61,18,16,10],
[61,18,16,11],
[61,18,16,12],
[61,18,16,13],
[61,18,16,14],
[61,18,17,0],
[61,18,17,1],
[61,18,17,2],
[61,18,17,3],
[61,18,17,5],
[61,18,17,6],
[61,18,17,7],
[61,18,17,8],
[61,18,17,10],
[61,18,17,11],
[61,18,17,12],
[61,18,17,13],
[61,18,17,14],
[61,18,17,16],
[61,19,3,0],
[61,19,3,1],
[61,19,3,2],
[61,19,5,0],
[61,19,5,1],
[61,19,5,2],
[61,19,6,0],
[61,19,6,1],
[61,19,6,2],
[61,19,7,0],
[61,19,7,1],
[61,19,7,2],
[61,19,7,3],
[61,19,7,5],
[61,19,7,6],
[61,19,8,0],
[61,19,8,1],
[61,19,8,2],
[61,19,8,7],
[61,19,9,0],
[61,19,9,1],
[61,19,9,2],
[61,19,9,3],
[61,19,9,5],
[61,19,9,6],
[61,19,9,7],
[61,19,9,8],
[61,19,10,0],
[61,19,10,1],
[61,19,10,2],
[61,19,10,3],
[61,19,10,5],
[61,19,10,6],
[61,19,10,7],
[61,19,10,8],
[61,19,10,9],
[61,19,11,0],
[61,19,11,1],
[61,19,11,2],
[61,19,11,3],
[61,19,11,5],
[61,19,11,6],
[61,19,11,7],
[61,19,11,8],
[61,19,11,9],
[61,19,11,10],
[61,19,12,0],
[61,19,12,1],
[61,19,12,2],
[61,19,12,3],
[61,19,12,5],
[61,19,12,6],
[61,19,12,7],
[61,19,12,8],
[61,19,12,9],
[61,19,12,10],
[61,19,15,0],
[61,19,15,1],
[61,19,15,2],
[61,19,15,3],
[61,19,15,5],
[61,19,15,6],
[61,19,15,7],
[61,19,15,8],
[61,19,15,10],
[61,19,15,11],
[61,19,15,12],
[61,19,16,0],
[61,19,16,1],
[61,19,16,2],
[61,19,16,3],
[61,19,16,5],
[61,19,16,6],
[61,19,16,7],
[61,19,16,8],
[61,19,16,9],
[61,19,16,10],
[61,19,16,11],
[61,19,16,12],
[61,19,16,15],
[61,19,17,0],
[61,19,17,1],
[61,19,17,2],
[61,19,17,3],
[61,19,17,5],
[61,19,17,6],
[61,19,17,7],
[61,19,17,8],
[61,19,17,9],
[61,19,17,10],
[61,19,17,11],
[61,19,17,12],
[61,19,17,15],
[61,19,17,16],
[61,19,18,0],
[61,19,18,1],
[61,19,18,2],
[61,19,18,3],
[61,19,18,5],
[61,19,18,6],
[61,19,18,7],
[61,19,18,8],
[61,19,18,10],
[61,19,18,11],
[61,19,18,12],
[61,19,18,16],
[61,19,18,17],
[61,20,3,0],
[61,20,3,1],
[61,20,3,2],
[61,20,5,0],
[61,20,5,1],
[61,20,5,2],
[61,20,6,0],
[61,20,6,1],
[61,20,6,2],
[61,20,7,0],
[61,20,7,1],
[61,20,7,2],
[61,20,7,3],
[61,20,7,5],
[61,20,7,6],
[61,20,8,0],
[61,20,8,1],
[61,20,8,2],
[61,20,8,7],
[61,20,9,0],
[61,20,9,1],
[61,20,9,2],
[61,20,9,3],
[61,20,9,5],
[61,20,9,6],
[61,20,9,7],
[61,20,9,8],
[61,20,11,0],
[61,20,11,1],
[61,20,11,2],
[61,20,11,3],
[61,20,11,5],
[61,20,11,6],
[61,20,11,7],
[61,20,11,8],
[61,20,11,9],
[61,20,12,0],
[61,20,12,1],
[61,20,12,2],
[61,20,12,3],
[61,20,12,5],
[61,20,12,6],
[61,20,12,7],
[61,20,12,8],
[61,20,12,9],
[61,20,13,0],
[61,20,13,1],
[61,20,13,2],
[61,20,13,3],
[61,20,13,5],
[61,20,13,6],
[61,20,13,7],
[61,20,13,8],
[61,20,13,9],
[61,20,13,11],
[61,20,13,12],
[61,20,14,0],
[61,20,14,1],
[61,20,14,2],
[61,20,14,3],
[61,20,14,5],
[61,20,14,6],
[61,20,14,7],
[61,20,14,8],
[61,20,14,9],
[61,20,14,11],
[61,20,14,12],
[61,20,15,0],
[61,20,15,1],
[61,20,15,2],
[61,20,15,3],
[61,20,15,5],
[61,20,15,6],
[61,20,15,7],
[61,20,15,8],
[61,20,15,11],
[61,20,15,12],
[61,20,15,13],
[61,20,15,14],
[61,20,16,0],
[61,20,16,1],
[61,20,16,2],
[61,20,16,3],
[61,20,16,5],
[61,20,16,6],
[61,20,16,7],
[61,20,16,8],
[61,20,16,9],
[61,20,16,11],
[61,20,16,12],
[61,20,16,13],
[61,20,16,14],
[61,20,16,15],
[61,20,17,0],
[61,20,17,1],
[61,20,17,2],
[61,20,17,3],
[61,20,17,5],
[61,20,17,6],
[61,20,17,7],
[61,20,17,8],
[61,20,17,9],
[61,20,17,11],
[61,20,17,12],
[61,20,17,13],
[61,20,17,14],
[61,20,17,15],
[61,20,17,16],
[61,20,18,0],
[61,20,18,1],
[61,20,18,2],
[61,20,18,3],
[61,20,18,5],
[61,20,18,6],
[61,20,18,7],
[61,20,18,8],
[61,20,18,11],
[61,20,18,12],
[61,20,18,13],
[61,20,18,14],
[61,20,18,16],
[61,20,18,17],
[61,20,19,0],
[61,20,19,1],
[61,20,19,2],
[61,20,19,3],
[61,20,19,5],
[61,20,19,6],
[61,20,19,7],
[61,20,19,8],
[61,20,19,9],
[61,20,19,11],
[61,20,19,12],
[61,20,19,15],
[61,20,19,16],
[61,20,19,17],
[61,20,19,18],
[61,21,7,0],
[61,21,7,1],
[61,21,7,2],
[61,21,9,0],
[61,21,9,1],
[61,21,9,2],
[61,21,9,7],
[61,21,10,0],
[61,21,10,1],
[61,21,10,2],
[61,21,10,7],
[61,21,10,9],
[61,21,11,0],
[61,21,11,1],
[61,21,11,2],
[61,21,11,7],
[61,21,11,9],
[61,21,11,10],
[61,21,12,0],
[61,21,12,1],
[61,21,12,2],
[61,21,12,7],
[61,21,12,9],
[61,21,12,10],
[61,21,13,0],
[61,21,13,1],
[61,21,13,2],
[61,21,13,7],
[61,21,13,9],
[61,21,13,10],
[61,21,13,11],
[61,21,13,12],
[61,21,14,0],
[61,21,14,1],
[61,21,14,2],
[61,21,14,7],
[61,21,14,9],
[61,21,14,10],
[61,21,14,11],
[61,21,14,12],
[61,21,15,0],
[61,21,15,1],
[61,21,15,2],
[61,21,15,7],
[61,21,15,10],
[61,21,15,11],
[61,21,15,12],
[61,21,15,13],
[61,21,15,14],
[61,21,16,0],
[61,21,16,1],
[61,21,16,2],
[61,21,16,7],
[61,21,16,9],
[61,21,16,10],
[61,21,16,11],
[61,21,16,12],
[61,21,16,13],
[61,21,16,14],
[61,21,16,15],
[61,21,17,0],
[61,21,17,1],
[61,21,17,2],
[61,21,17,7],
[61,21,17,9],
[61,21,17,10],
[61,21,17,11],
[61,21,17,12],
[61,21,17,13],
[61,21,17,14],
[61,21,17,15],
[61,21,17,16],
[61,21,18,0],
[61,21,18,1],
[61,21,18,2],
[61,21,18,7],
[61,21,18,10],
[61,21,18,11],
[61,21,18,12],
[61,21,18,13],
[61,21,18,14],
[61,21,18,16],
[61,21,18,17],
[61,21,19,0],
[61,21,19,1],
[61,21,19,2],
[61,21,19,7],
[61,21,19,9],
[61,21,19,10],
[61,21,19,11],
[61,21,19,12],
[61,21,19,15],
[61,21,19,16],
[61,21,19,17],
[61,21,19,18],
[61,21,20,0],
[61,21,20,1],
[61,21,20,2],
[61,21,20,7],
[61,21,20,9],
[61,21,20,11],
[61,21,20,12],
[61,21,20,13],
[61,21,20,14],
[61,21,20,15],
[61,21,20,16],
[61,21,20,17],
[61,21,20,18],
[61,21,20,19],
[61,22,3,0],
[61,22,3,1],
[61,22,3,2],
[61,22,5,0],
[61,22,5,1],
[61,22,5,2],
[61,22,6,0],
[61,22,6,1],
[61,22,6,2],
[61,22,7,0],
[61,22,7,1],
[61,22,7,2],
[61,22,7,3],
[61,22,7,5],
[61,22,7,6],
[61,22,8,0],
[61,22,8,1],
[61,22,8,2],
[61,22,8,7],
[61,22,9,0],
[61,22,9,1],
[61,22,9,2],
[61,22,9,3],
[61,22,9,5],
[61,22,9,6],
[61,22,9,7],
[61,22,9,8],
[61,22,11,0],
[61,22,11,1],
[61,22,11,2],
[61,22,11,3],
[61,22,11,5],
[61,22,11,6],
[61,22,11,7],
[61,22,11,8],
[61,22,11,9],
[61,22,12,0],
[61,22,12,1],
[61,22,12,2],
[61,22,12,3],
[61,22,12,5],
[61,22,12,6],
[61,22,12,7],
[61,22,12,8],
[61,22,12,9],
[61,22,13,0],
[61,22,13,1],
[61,22,13,2],
[61,22,13,3],
[61,22,13,5],
[61,22,13,6],
[61,22,13,7],
[61,22,13,8],
[61,22,13,9],
[61,22,13,11],
[61,22,13,12],
[61,22,14,0],
[61,22,14,1],
[61,22,14,2],
[61,22,14,3],
[61,22,14,5],
[61,22,14,6],
[61,22,14,7],
[61,22,14,8],
[61,22,14,9],
[61,22,14,11],
[61,22,14,12],
[61,22,15,0],
[61,22,15,1],
[61,22,15,2],
[61,22,15,3],
[61,22,15,5],
[61,22,15,6],
[61,22,15,7],
[61,22,15,8],
[61,22,15,11],
[61,22,15,12],
[61,22,15,13],
[61,22,15,14],
[61,22,16,0],
[61,22,16,1],
[61,22,16,2],
[61,22,16,3],
[61,22,16,5],
[61,22,16,6],
[61,22,16,7],
[61,22,16,8],
[61,22,16,9],
[61,22,16,11],
[61,22,16,12],
[61,22,16,13],
[61,22,16,14],
[61,22,16,15],
[61,22,17,0],
[61,22,17,1],
[61,22,17,2],
[61,22,17,3],
[61,22,17,5],
[61,22,17,6],
[61,22,17,7],
[61,22,17,8],
[61,22,17,9],
[61,22,17,11],
[61,22,17,12],
[61,22,17,13],
[61,22,17,14],
[61,22,17,15],
[61,22,17,16],
[61,22,18,0],
[61,22,18,1],
[61,22,18,2],
[61,22,18,3],
[61,22,18,5],
[61,22,18,6],
[61,22,18,7],
[61,22,18,8],
[61,22,18,11],
[61,22,18,12],
[61,22,18,13],
[61,22,18,14],
[61,22,18,16],
[61,22,18,17],
[61,22,19,0],
[61,22,19,1],
[61,22,19,2],
[61,22,19,3],
[61,22,19,5],
[61,22,19,6],
[61,22,19,7],
[61,22,19,8],
[61,22,19,9],
[61,22,19,11],
[61,22,19,12],
[61,22,19,15],
[61,22,19,16],
[61,22,19,17],
[61,22,19,18],
[61,22,21,0],
[61,22,21,1],
[61,22,21,2],
[61,22,21,7],
[61,22,21,9],
[61,22,21,11],
[61,22,21,12],
[61,22,21,13],
[61,22,21,14],
[61,22,21,15],
[61,22,21,16],
[61,22,21,17],
[61,22,21,18],
[61,22,21,19],
[61,23,3,0],
[61,23,3,1],
[61,23,3,2],
[61,23,5,0],
[61,23,5,1],
[61,23,5,2],
[61,23,6,0],
[61,23,6,1],
[61,23,6,2],
[61,23,7,0],
[61,23,7,1],
[61,23,7,2],
[61,23,7,3],
[61,23,7,5],
[61,23,7,6],
[61,23,8,0],
[61,23,8,1],
[61,23,8,2],
[61,23,8,7],
[61,23,9,0],
[61,23,9,1],
[61,23,9,2],
[61,23,9,3],
[61,23,9,5],
[61,23,9,6],
[61,23,9,7],
[61,23,9,8],
[61,23,10,0],
[61,23,10,1],
[61,23,10,2],
[61,23,10,3],
[61,23,10,5],
[61,23,10,6],
[61,23,10,7],
[61,23,10,8],
[61,23,10,9],
[61,23,11,0],
[61,23,11,1],
[61,23,11,2],
[61,23,11,3],
[61,23,11,5],
[61,23,11,6],
[61,23,11,7],
[61,23,11,8],
[61,23,11,9],
[61,23,11,10],
[61,23,12,0],
[61,23,12,1],
[61,23,12,2],
[61,23,12,3],
[61,23,12,5],
[61,23,12,6],
[61,23,12,7],
[61,23,12,8],
[61,23,12,9],
[61,23,12,10],
[61,23,13,0],
[61,23,13,1],
[61,23,13,2],
[61,23,13,3],
[61,23,13,5],
[61,23,13,6],
[61,23,13,7],
[61,23,13,8],
[61,23,13,9],
[61,23,13,10],
[61,23,13,11],
[61,23,13,12],
[61,23,14,0],
[61,23,14,1],
[61,23,14,2],
[61,23,14,3],
[61,23,14,5],
[61,23,14,6],
[61,23,14,7],
[61,23,14,8],
[61,23,14,9],
[61,23,14,10],
[61,23,14,11],
[61,23,14,12],
[61,23,15,0],
[61,23,15,1],
[61,23,15,2],
[61,23,15,3],
[61,23,15,5],
[61,23,15,6],
[61,23,15,7],
[61,23,15,8],
[61,23,15,10],
[61,23,15,11],
[61,23,15,12],
[61,23,15,13],
[61,23,15,14],
[61,23,16,0],
[61,23,16,1],
[61,23,16,2],
[61,23,16,3],
[61,23,16,5],
[61,23,16,6],
[61,23,16,7],
[61,23,16,8],
[61,23,16,9],
[61,23,16,10],
[61,23,16,11],
[61,23,16,12],
[61,23,16,13],
[61,23,16,14],
[61,23,16,15],
[61,23,17,0],
[61,23,17,1],
[61,23,17,2],
[61,23,17,3],
[61,23,17,5],
[61,23,17,6],
[61,23,17,7],
[61,23,17,8],
[61,23,17,9],
[61,23,17,10],
[61,23,17,11],
[61,23,17,12],
[61,23,17,13],
[61,23,17,14],
[61,23,17,15],
[61,23,17,16],
[61,23,18,0],
[61,23,18,1],
[61,23,18,2],
[61,23,18,3],
[61,23,18,5],
[61,23,18,6],
[61,23,18,7],
[61,23,18,8],
[61,23,18,10],
[61,23,18,11],
[61,23,18,12],
[61,23,18,13],
[61,23,18,14],
[61,23,18,16],
[61,23,18,17],
[61,23,19,0],
[61,23,19,1],
[61,23,19,2],
[61,23,19,3],
[61,23,19,5],
[61,23,19,6],
[61,23,19,7],
[61,23,19,8],
[61,23,19,9],
[61,23,19,10],
[61,23,19,11],
[61,23,19,12],
[61,23,19,15],
[61,23,19,16],
[61,23,19,17],
[61,23,19,18],
[61,23,20,0],
[61,23,20,1],
[61,23,20,2],
[61,23,20,3],
[61,23,20,5],
[61,23,20,6],
[61,23,20,7],
[61,23,20,8],
[61,23,20,9],
[61,23,20,11],
[61,23,20,12],
[61,23,20,13],
[61,23,20,14],
[61,23,20,15],
[61,23,20,16],
[61,23,20,17],
[61,23,20,18],
[61,23,20,19],
[61,23,21,0],
[61,23,21,1],
[61,23,21,2],
[61,23,21,7],
[61,23,21,9],
[61,23,21,10],
[61,23,21,11],
[61,23,21,12],
[61,23,21,13],
[61,23,21,14],
[61,23,21,15],
[61,23,21,16],
[61,23,21,17],
[61,23,21,18],
[61,23,21,19],
[61,23,21,20],
[61,23,22,0],
[61,23,22,1],
[61,23,22,2],
[61,23,22,3],
[61,23,22,5],
[61,23,22,6],
[61,23,22,7],
[61,23,22,8],
[61,23,22,9],
[61,23,22,11],
[61,23,22,12],
[61,23,22,13],
[61,23,22,14],
[61,23,22,15],
[61,23,22,16],
[61,23,22,17],
[61,23,22,18],
[61,23,22,19],
[61,23,22,21],
[61,24,3,0],
[61,24,3,1],
[61,24,3,2],
[61,24,5,0],
[61,24,5,1],
[61,24,5,2],
[61,24,6,0],
[61,24,6,1],
[61,24,6,2],
[61,24,7,0],
[61,24,7,1],
[61,24,7,2],
[61,24,7,3],
[61,24,7,5],
[61,24,7,6],
[61,24,8,0],
[61,24,8,1],
[61,24,8,2],
[61,24,8,7],
[61,24,9,0],
[61,24,9,1],
[61,24,9,2],
[61,24,9,3],
[61,24,9,5],
[61,24,9,6],
[61,24,9,7],
[61,24,9,8],
[61,24,10,0],
[61,24,10,1],
[61,24,10,2],
[61,24,10,3],
[61,24,10,5],
[61,24,10,6],
[61,24,10,7],
[61,24,10,8],
[61,24,10,9],
[61,24,11,0],
[61,24,11,1],
[61,24,11,2],
[61,24,11,3],
[61,24,11,5],
[61,24,11,6],
[61,24,11,7],
[61,24,11,8],
[61,24,11,9],
[61,24,11,10],
[61,24,12,0],
[61,24,12,1],
[61,24,12,2],
[61,24,12,3],
[61,24,12,5],
[61,24,12,6],
[61,24,12,7],
[61,24,12,8],
[61,24,12,9],
[61,24,12,10],
[61,24,13,0],
[61,24,13,1],
[61,24,13,2],
[61,24,13,3],
[61,24,13,5],
[61,24,13,6],
[61,24,13,7],
[61,24,13,8],
[61,24,13,9],
[61,24,13,10],
[61,24,13,11],
[61,24,13,12],
[61,24,14,0],
[61,24,14,1],
[61,24,14,2],
[61,24,14,3],
[61,24,14,5],
[61,24,14,6],
[61,24,14,7],
[61,24,14,8],
[61,24,14,9],
[61,24,14,10],
[61,24,14,11],
[61,24,14,12],
[61,24,15,0],
[61,24,15,1],
[61,24,15,2],
[61,24,15,3],
[61,24,15,5],
[61,24,15,6],
[61,24,15,7],
[61,24,15,8],
[61,24,15,10],
[61,24,15,11],
[61,24,15,12],
[61,24,15,13],
[61,24,15,14],
[61,24,17,0],
[61,24,17,1],
[61,24,17,2],
[61,24,17,3],
[61,24,17,5],
[61,24,17,6],
[61,24,17,7],
[61,24,17,8],
[61,24,17,9],
[61,24,17,10],
[61,24,17,11],
[61,24,17,12],
[61,24,17,13],
[61,24,17,14],
[61,24,17,15],
[61,24,18,0],
[61,24,18,1],
[61,24,18,2],
[61,24,18,3],
[61,24,18,5],
[61,24,18,6],
[61,24,18,7],
[61,24,18,8],
[61,24,18,10],
[61,24,18,11],
[61,24,18,12],
[61,24,18,13],
[61,24,18,14],
[61,24,18,17],
[61,24,19,0],
[61,24,19,1],
[61,24,19,2],
[61,24,19,3],
[61,24,19,5],
[61,24,19,6],
[61,24,19,7],
[61,24,19,8],
[61,24,19,9],
[61,24,19,10],
[61,24,19,11],
[61,24,19,12],
[61,24,19,15],
[61,24,19,17],
[61,24,19,18],
[61,24,20,0],
[61,24,20,1],
[61,24,20,2],
[61,24,20,3],
[61,24,20,5],
[61,24,20,6],
[61,24,20,7],
[61,24,20,8],
[61,24,20,9],
[61,24,20,11],
[61,24,20,12],
[61,24,20,13],
[61,24,20,14],
[61,24,20,15],
[61,24,20,17],
[61,24,20,18],
[61,24,20,19],
[61,24,21,0],
[61,24,21,1],
[61,24,21,2],
[61,24,21,7],
[61,24,21,9],
[61,24,21,10],
[61,24,21,11],
[61,24,21,12],
[61,24,21,13],
[61,24,21,14],
[61,24,21,15],
[61,24,21,17],
[61,24,21,18],
[61,24,21,19],
[61,24,21,20],
[61,24,22,0],
[61,24,22,1],
[61,24,22,2],
[61,24,22,3],
[61,24,22,5],
[61,24,22,6],
[61,24,22,7],
[61,24,22,8],
[61,24,22,9],
[61,24,22,11],
[61,24,22,12],
[61,24,22,13],
[61,24,22,14],
[61,24,22,15],
[61,24,22,17],
[61,24,22,18],
[61,24,22,19],
[61,24,22,21],
[61,24,23,0],
[61,24,23,1],
[61,24,23,2],
[61,24,23,3],
[61,24,23,5],
[61,24,23,6],
[61,24,23,7],
[61,24,23,8],
[61,24,23,9],
[61,24,23,10],
[61,24,23,11],
[61,24,23,12],
[61,24,23,13],
[61,24,23,14],
[61,24,23,15],
[61,24,23,17],
[61,24,23,18],
[61,24,23,19],
[61,24,23,20],
[61,24,23,21],
[61,24,23,22],
[61,25,3,0],
[61,25,3,1],
[61,25,3,2],
[61,25,5,0],
[61,25,5,1],
[61,25,5,2],
[61,25,6,0],
[61,25,6,1],
[61,25,6,2],
[61,25,8,0],
[61,25,8,1],
[61,25,8,2],
[61,25,9,0],
[61,25,9,1],
[61,25,9,2],
[61,25,9,3],
[61,25,9,5],
[61,25,9,6],
[61,25,9,8],
[61,25,10,0],
[61,25,10,1],
[61,25,10,2],
[61,25,10,3],
[61,25,10,5],
[61,25,10,6],
[61,25,10,8],
[61,25,10,9],
[61,25,11,0],
[61,25,11,1],
[61,25,11,2],
[61,25,11,3],
[61,25,11,5],
[61,25,11,6],
[61,25,11,8],
[61,25,11,9],
[61,25,11,10],
[61,25,12,0],
[61,25,12,1],
[61,25,12,2],
[61,25,12,3],
[61,25,12,5],
[61,25,12,6],
[61,25,12,8],
[61,25,12,9],
[61,25,12,10],
[61,25,13,0],
[61,25,13,1],
[61,25,13,2],
[61,25,13,3],
[61,25,13,5],
[61,25,13,6],
[61,25,13,8],
[61,25,13,9],
[61,25,13,10],
[61,25,13,11],
[61,25,13,12],
[61,25,14,0],
[61,25,14,1],
[61,25,14,2],
[61,25,14,3],
[61,25,14,5],
[61,25,14,6],
[61,25,14,8],
[61,25,14,9],
[61,25,14,10],
[61,25,14,11],
[61,25,14,12],
[61,25,15,0],
[61,25,15,1],
[61,25,15,2],
[61,25,15,3],
[61,25,15,5],
[61,25,15,6],
[61,25,15,8],
[61,25,15,10],
[61,25,15,11],
[61,25,15,12],
[61,25,15,13],
[61,25,15,14],
[61,25,16,0],
[61,25,16,1],
[61,25,16,2],
[61,25,16,3],
[61,25,16,5],
[61,25,16,6],
[61,25,16,8],
[61,25,16,9],
[61,25,16,10],
[61,25,16,11],
[61,25,16,12],
[61,25,16,13],
[61,25,16,14],
[61,25,16,15],
[61,25,17,0],
[61,25,17,1],
[61,25,17,2],
[61,25,17,3],
[61,25,17,5],
[61,25,17,6],
[61,25,17,8],
[61,25,17,9],
[61,25,17,10],
[61,25,17,11],
[61,25,17,12],
[61,25,17,13],
[61,25,17,14],
[61,25,17,15],
[61,25,17,16],
[61,25,18,0],
[61,25,18,1],
[61,25,18,2],
[61,25,18,3],
[61,25,18,5],
[61,25,18,6],
[61,25,18,8],
[61,25,18,10],
[61,25,18,11],
[61,25,18,12],
[61,25,18,13],
[61,25,18,14],
[61,25,18,16],
[61,25,18,17],
[61,25,19,0],
[61,25,19,1],
[61,25,19,2],
[61,25,19,3],
[61,25,19,5],
[61,25,19,6],
[61,25,19,8],
[61,25,19,9],
[61,25,19,10],
[61,25,19,11],
[61,25,19,12],
[61,25,19,15],
[61,25,19,16],
[61,25,19,17],
[61,25,19,18],
[61,25,20,0],
[61,25,20,1],
[61,25,20,2],
[61,25,20,3],
[61,25,20,5],
[61,25,20,6],
[61,25,20,8],
[61,25,20,9],
[61,25,20,11],
[61,25,20,12],
[61,25,20,13],
[61,25,20,14],
[61,25,20,15],
[61,25,20,16],
[61,25,20,17],
[61,25,20,18],
[61,25,20,19],
[61,25,21,0],
[61,25,21,1],
[61,25,21,2],
[61,25,21,9],
[61,25,21,10],
[61,25,21,11],
[61,25,21,12],
[61,25,21,13],
[61,25,21,14],
[61,25,21,15],
[61,25,21,16],
[61,25,21,17],
[61,25,21,18],
[61,25,21,19],
[61,25,21,20],
[61,25,22,0],
[61,25,22,1],
[61,25,22,2],
[61,25,22,3],
[61,25,22,5],
[61,25,22,6],
[61,25,22,8],
[61,25,22,9],
[61,25,22,11],
[61,25,22,12],
[61,25,22,13],
[61,25,22,14],
[61,25,22,15],
[61,25,22,16],
[61,25,22,17],
[61,25,22,18],
[61,25,22,19],
[61,25,22,21],
[61,25,23,0],
[61,25,23,1],
[61,25,23,2],
[61,25,23,3],
[61,25,23,5],
[61,25,23,6],
[61,25,23,8],
[61,25,23,9],
[61,25,23,10],
[61,25,23,11],
[61,25,23,12],
[61,25,23,13],
[61,25,23,14],
[61,25,23,15],
[61,25,23,16],
[61,25,23,17],
[61,25,23,18],
[61,25,23,19],
[61,25,23,20],
[61,25,23,21],
[61,25,23,22],
[61,25,24,0],
[61,25,24,1],
[61,25,24,2],
[61,25,24,3],
[61,25,24,5],
[61,25,24,6],
[61,25,24,8],
[61,25,24,9],
[61,25,24,10],
[61,25,24,11],
[61,25,24,12],
[61,25,24,13],
[61,25,24,14],
[61,25,24,15],
[61,25,24,17],
[61,25,24,18],
[61,25,24,19],
[61,25,24,20],
[61,25,24,21],
[61,25,24,22],
[61,25,24,23],
[61,26,3,0],
[61,26,3,1],
[61,26,3,2],
[61,26,5,0],
[61,26,5,1],
[61,26,5,2],
[61,26,6,0],
[61,26,6,1],
[61,26,6,2],
[61,26,7,0],
[61,26,7,1],
[61,26,7,2],
[61,26,7,3],
[61,26,7,5],
[61,26,7,6],
[61,26,8,0],
[61,26,8,1],
[61,26,8,2],
[61,26,8,7],
[61,26,10,0],
[61,26,10,1],
[61,26,10,2],
[61,26,10,3],
[61,26,10,5],
[61,26,10,6],
[61,26,10,7],
[61,26,10,8],
[61,26,11,0],
[61,26,11,1],
[61,26,11,2],
[61,26,11,3],
[61,26,11,5],
[61,26,11,6],
[61,26,11,7],
[61,26,11,8],
[61,26,11,10],
[61,26,12,0],
[61,26,12,1],
[61,26,12,2],
[61,26,12,3],
[61,26,12,5],
[61,26,12,6],
[61,26,12,7],
[61,26,12,8],
[61,26,12,10],
[61,26,13,0],
[61,26,13,1],
[61,26,13,2],
[61,26,13,3],
[61,26,13,5],
[61,26,13,6],
[61,26,13,7],
[61,26,13,8],
[61,26,13,10],
[61,26,13,11],
[61,26,13,12],
[61,26,14,0],
[61,26,14,1],
[61,26,14,2],
[61,26,14,3],
[61,26,14,5],
[61,26,14,6],
[61,26,14,7],
[61,26,14,8],
[61,26,14,10],
[61,26,14,11],
[61,26,14,12],
[61,26,16,0],
[61,26,16,1],
[61,26,16,2],
[61,26,16,3],
[61,26,16,5],
[61,26,16,6],
[61,26,16,7],
[61,26,16,8],
[61,26,16,10],
[61,26,16,11],
[61,26,16,12],
[61,26,16,13],
[61,26,16,14],
[61,26,17,0],
[61,26,17,1],
[61,26,17,2],
[61,26,17,3],
[61,26,17,5],
[61,26,17,6],
[61,26,17,7],
[61,26,17,8],
[61,26,17,10],
[61,26,17,11],
[61,26,17,12],
[61,26,17,13],
[61,26,17,14],
[61,26,17,16],
[61,26,19,0],
[61,26,19,1],
[61,26,19,2],
[61,26,19,3],
[61,26,19,5],
[61,26,19,6],
[61,26,19,7],
[61,26,19,8],
[61,26,19,10],
[61,26,19,11],
[61,26,19,12],
[61,26,19,16],
[61,26,19,17],
[61,26,20,0],
[61,26,20,1],
[61,26,20,2],
[61,26,20,3],
[61,26,20,5],
[61,26,20,6],
[61,26,20,7],
[61,26,20,8],
[61,26,20,11],
[61,26,20,12],
[61,26,20,13],
[61,26,20,14],
[61,26,20,16],
[61,26,20,17],
[61,26,20,19],
[61,26,21,0],
[61,26,21,1],
[61,26,21,2],
[61,26,21,7],
[61,26,21,10],
[61,26,21,11],
[61,26,21,12],
[61,26,21,13],
[61,26,21,14],
[61,26,21,16],
[61,26,21,17],
[61,26,21,19],
[61,26,21,20],
[61,26,22,0],
[61,26,22,1],
[61,26,22,2],
[61,26,22,3],
[61,26,22,5],
[61,26,22,6],
[61,26,22,7],
[61,26,22,8],
[61,26,22,11],
[61,26,22,12],
[61,26,22,13],
[61,26,22,14],
[61,26,22,16],
[61,26,22,17],
[61,26,22,19],
[61,26,22,21],
[61,26,23,0],
[61,26,23,1],
[61,26,23,2],
[61,26,23,3],
[61,26,23,5],
[61,26,23,6],
[61,26,23,7],
[61,26,23,8],
[61,26,23,10],
[61,26,23,11],
[61,26,23,12],
[61,26,23,13],
[61,26,23,14],
[61,26,23,16],
[61,26,23,17],
[61,26,23,19],
[61,26,23,20],
[61,26,23,21],
[61,26,23,22],
[61,26,24,0],
[61,26,24,1],
[61,26,24,2],
[61,26,24,3],
[61,26,24,5],
[61,26,24,6],
[61,26,24,7],
[61,26,24,8],
[61,26,24,10],
[61,26,24,11],
[61,26,24,12],
[61,26,24,13],
[61,26,24,14],
[61,26,24,17],
[61,26,24,19],
[61,26,24,20],
[61,26,24,21],
[61,26,24,22],
[61,26,24,23],
[61,26,25,0],
[61,26,25,1],
[61,26,25,2],
[61,26,25,3],
[61,26,25,5],
[61,26,25,6],
[61,26,25,8],
[61,26,25,10],
[61,26,25,11],
[61,26,25,12],
[61,26,25,13],
[61,26,25,14],
[61,26,25,16],
[61,26,25,17],
[61,26,25,19],
[61,26,25,20],
[61,26,25,21],
[61,26,25,22],
[61,26,25,23],
[61,26,25,24],
[61,27,3,0],
[61,27,3,1],
[61,27,3,2],
[61,27,5,0],
[61,27,5,1],
[61,27,5,2],
[61,27,6,0],
[61,27,6,1],
[61,27,6,2],
[61,27,7,0],
[61,27,7,1],
[61,27,7,2],
[61,27,7,3],
[61,27,7,5],
[61,27,7,6],
[61,27,8,0],
[61,27,8,1],
[61,27,8,2],
[61,27,8,7],
[61,27,9,0],
[61,27,9,1],
[61,27,9,2],
[61,27,9,3],
[61,27,9,5],
[61,27,9,6],
[61,27,9,7],
[61,27,9,8],
[61,27,11,0],
[61,27,11,1],
[61,27,11,2],
[61,27,11,3],
[61,27,11,5],
[61,27,11,6],
[61,27,11,7],
[61,27,11,8],
[61,27,11,9],
[61,27,12,0],
[61,27,12,1],
[61,27,12,2],
[61,27,12,3],
[61,27,12,5],
[61,27,12,6],
[61,27,12,7],
[61,27,12,8],
[61,27,12,9],
[61,27,13,0],
[61,27,13,1],
[61,27,13,2],
[61,27,13,3],
[61,27,13,5],
[61,27,13,6],
[61,27,13,7],
[61,27,13,8],
[61,27,13,9],
[61,27,13,11],
[61,27,13,12],
[61,27,14,0],
[61,27,14,1],
[61,27,14,2],
[61,27,14,3],
[61,27,14,5],
[61,27,14,6],
[61,27,14,7],
[61,27,14,8],
[61,27,14,9],
[61,27,14,11],
[61,27,14,12],
[61,27,15,0],
[61,27,15,1],
[61,27,15,2],
[61,27,15,3],
[61,27,15,5],
[61,27,15,6],
[61,27,15,7],
[61,27,15,8],
[61,27,15,11],
[61,27,15,12],
[61,27,15,13],
[61,27,15,14],
[61,27,16,0],
[61,27,16,1],
[61,27,16,2],
[61,27,16,3],
[61,27,16,5],
[61,27,16,6],
[61,27,16,7],
[61,27,16,8],
[61,27,16,9],
[61,27,16,11],
[61,27,16,12],
[61,27,16,13],
[61,27,16,14],
[61,27,16,15],
[61,27,17,0],
[61,27,17,1],
[61,27,17,2],
[61,27,17,3],
[61,27,17,5],
[61,27,17,6],
[61,27,17,7],
[61,27,17,8],
[61,27,17,9],
[61,27,17,11],
[61,27,17,12],
[61,27,17,13],
[61,27,17,14],
[61,27,17,15],
[61,27,17,16],
[61,27,18,0],
[61,27,18,1],
[61,27,18,2],
[61,27,18,3],
[61,27,18,5],
[61,27,18,6],
[61,27,18,7],
[61,27,18,8],
[61,27,18,11],
[61,27,18,12],
[61,27,18,13],
[61,27,18,14],
[61,27,18,16],
[61,27,18,17],
[61,27,19,0],
[61,27,19,1],
[61,27,19,2],
[61,27,19,3],
[61,27,19,5],
[61,27,19,6],
[61,27,19,7],
[61,27,19,8],
[61,27,19,9],
[61,27,19,11],
[61,27,19,12],
[61,27,19,15],
[61,27,19,16],
[61,27,19,17],
[61,27,19,18],
[61,27,21,0],
[61,27,21,1],
[61,27,21,2],
[61,27,21,7],
[61,27,21,9],
[61,27,21,11],
[61,27,21,12],
[61,27,21,13],
[61,27,21,14],
[61,27,21,15],
[61,27,21,16],
[61,27,21,17],
[61,27,21,18],
[61,27,21,19],
[61,27,23,0],
[61,27,23,1],
[61,27,23,2],
[61,27,23,3],
[61,27,23,5],
[61,27,23,6],
[61,27,23,7],
[61,27,23,8],
[61,27,23,9],
[61,27,23,11],
[61,27,23,12],
[61,27,23,13],
[61,27,23,14],
[61,27,23,15],
[61,27,23,16],
[61,27,23,17],
[61,27,23,18],
[61,27,23,19],
[61,27,23,21],
[61,27,24,0],
[61,27,24,1],
[61,27,24,2],
[61,27,24,3],
[61,27,24,5],
[61,27,24,6],
[61,27,24,7],
[61,27,24,8],
[61,27,24,9],
[61,27,24,11],
[61,27,24,12],
[61,27,24,13],
[61,27,24,14],
[61,27,24,15],
[61,27,24,17],
[61,27,24,18],
[61,27,24,19],
[61,27,24,21],
[61,27,24,23],
[61,27,25,0],
[61,27,25,1],
[61,27,25,2],
[61,27,25,3],
[61,27,25,5],
[61,27,25,6],
[61,27,25,8],
[61,27,25,9],
[61,27,25,11],
[61,27,25,12],
[61,27,25,13],
[61,27,25,14],
[61,27,25,15],
[61,27,25,16],
[61,27,25,17],
[61,27,25,18],
[61,27,25,19],
[61,27,25,21],
[61,27,25,23],
[61,27,25,24],
[61,27,26,0],
[61,27,26,1],
[61,27,26,2],
[61,27,26,3],
[61,27,26,5],
[61,27,26,6],
[61,27,26,7],
[61,27,26,8],
[61,27,26,11],
[61,27,26,12],
[61,27,26,13],
[61,27,26,14],
[61,27,26,16],
[61,27,26,17],
[61,27,26,19],
[61,27,26,21],
[61,27,26,25],
[61,28,3,0],
[61,28,3,1],
[61,28,3,2],
[61,28,5,0],
[61,28,5,1],
[61,28,5,2],
[61,28,6,0],
[61,28,6,1],
[61,28,6,2],
[61,28,8,0],
[61,28,8,1],
[61,28,8,2],
[61,28,9,0],
[61,28,9,1],
[61,28,9,2],
[61,28,9,3],
[61,28,9,5],
[61,28,9,6],
[61,28,9,8],
[61,28,10,0],
[61,28,10,1],
[61,28,10,2],
[61,28,10,3],
[61,28,10,5],
[61,28,10,6],
[61,28,10,8],
[61,28,10,9],
[61,28,11,0],
[61,28,11,1],
[61,28,11,2],
[61,28,11,3],
[61,28,11,5],
[61,28,11,6],
[61,28,11,8],
[61,28,11,9],
[61,28,11,10],
[61,28,12,0],
[61,28,12,1],
[61,28,12,2],
[61,28,12,3],
[61,28,12,5],
[61,28,12,6],
[61,28,12,8],
[61,28,12,9],
[61,28,12,10],
[61,28,13,0],
[61,28,13,1],
[61,28,13,2],
[61,28,13,3],
[61,28,13,5],
[61,28,13,6],
[61,28,13,8],
[61,28,13,9],
[61,28,13,10],
[61,28,13,11],
[61,28,13,12],
[61,28,14,0],
[61,28,14,1],
[61,28,14,2],
[61,28,14,3],
[61,28,14,5],
[61,28,14,6],
[61,28,14,8],
[61,28,14,9],
[61,28,14,10],
[61,28,14,11],
[61,28,14,12],
[61,28,15,0],
[61,28,15,1],
[61,28,15,2],
[61,28,15,3],
[61,28,15,5],
[61,28,15,6],
[61,28,15,8],
[61,28,15,10],
[61,28,15,11],
[61,28,15,12],
[61,28,15,13],
[61,28,15,14],
[61,28,16,0],
[61,28,16,1],
[61,28,16,2],
[61,28,16,3],
[61,28,16,5],
[61,28,16,6],
[61,28,16,8],
[61,28,16,9],
[61,28,16,10],
[61,28,16,11],
[61,28,16,12],
[61,28,16,13],
[61,28,16,14],
[61,28,16,15],
[61,28,17,0],
[61,28,17,1],
[61,28,17,2],
[61,28,17,3],
[61,28,17,5],
[61,28,17,6],
[61,28,17,8],
[61,28,17,9],
[61,28,17,10],
[61,28,17,11],
[61,28,17,12],
[61,28,17,13],
[61,28,17,14],
[61,28,17,15],
[61,28,17,16],
[61,28,18,0],
[61,28,18,1],
[61,28,18,2],
[61,28,18,3],
[61,28,18,5],
[61,28,18,6],
[61,28,18,8],
[61,28,18,10],
[61,28,18,11],
[61,28,18,12],
[61,28,18,13],
[61,28,18,14],
[61,28,18,16],
[61,28,18,17],
[61,28,19,0],
[61,28,19,1],
[61,28,19,2],
[61,28,19,3],
[61,28,19,5],
[61,28,19,6],
[61,28,19,8],
[61,28,19,9],
[61,28,19,10],
[61,28,19,11],
[61,28,19,12],
[61,28,19,15],
[61,28,19,16],
[61,28,19,17],
[61,28,19,18],
[61,28,20,0],
[61,28,20,1],
[61,28,20,2],
[61,28,20,3],
[61,28,20,5],
[61,28,20,6],
[61,28,20,8],
[61,28,20,9],
[61,28,20,11],
[61,28,20,12],
[61,28,20,13],
[61,28,20,14],
[61,28,20,15],
[61,28,20,16],
[61,28,20,17],
[61,28,20,18],
[61,28,20,19],
[61,28,21,0],
[61,28,21,1],
[61,28,21,2],
[61,28,21,9],
[61,28,21,10],
[61,28,21,11],
[61,28,21,12],
[61,28,21,13],
[61,28,21,14],
[61,28,21,15],
[61,28,21,16],
[61,28,21,17],
[61,28,21,18],
[61,28,21,19],
[61,28,21,20],
[61,28,22,0],
[61,28,22,1],
[61,28,22,2],
[61,28,22,3],
[61,28,22,5],
[61,28,22,6],
[61,28,22,8],
[61,28,22,9],
[61,28,22,11],
[61,28,22,12],
[61,28,22,13],
[61,28,22,14],
[61,28,22,15],
[61,28,22,16],
[61,28,22,17],
[61,28,22,18],
[61,28,22,19],
[61,28,22,21],
[61,28,23,0],
[61,28,23,1],
[61,28,23,2],
[61,28,23,3],
[61,28,23,5],
[61,28,23,6],
[61,28,23,8],
[61,28,23,9],
[61,28,23,10],
[61,28,23,11],
[61,28,23,12],
[61,28,23,13],
[61,28,23,14],
[61,28,23,15],
[61,28,23,16],
[61,28,23,17],
[61,28,23,18],
[61,28,23,19],
[61,28,23,20],
[61,28,23,21],
[61,28,23,22],
[61,28,24,0],
[61,28,24,1],
[61,28,24,2],
[61,28,24,3],
[61,28,24,5],
[61,28,24,6],
[61,28,24,8],
[61,28,24,9],
[61,28,24,10],
[61,28,24,11],
[61,28,24,12],
[61,28,24,13],
[61,28,24,14],
[61,28,24,15],
[61,28,24,17],
[61,28,24,18],
[61,28,24,19],
[61,28,24,20],
[61,28,24,21],
[61,28,24,22],
[61,28,24,23],
[61,28,26,0],
[61,28,26,1],
[61,28,26,2],
[61,28,26,3],
[61,28,26,5],
[61,28,26,6],
[61,28,26,8],
[61,28,26,10],
[61,28,26,11],
[61,28,26,12],
[61,28,26,13],
[61,28,26,14],
[61,28,26,16],
[61,28,26,17],
[61,28,26,19],
[61,28,26,20],
[61,28,26,21],
[61,28,26,23],
[61,28,26,24],
[61,28,27,0],
[61,28,27,1],
[61,28,27,2],
[61,28,27,3],
[61,28,27,5],
[61,28,27,6],
[61,28,27,8],
[61,28,27,9],
[61,28,27,11],
[61,28,27,12],
[61,28,27,13],
[61,28,27,14],
[61,28,27,15],
[61,28,27,16],
[61,28,27,17],
[61,28,27,18],
[61,28,27,19],
[61,28,27,23],
[61,28,27,24],
[61,28,27,26],
[61,29,3,0],
[61,29,3,1],
[61,29,3,2],
[61,29,5,0],
[61,29,5,1],
[61,29,5,2],
[61,29,6,0],
[61,29,6,1],
[61,29,6,2],
[61,29,7,0],
[61,29,7,1],
[61,29,7,2],
[61,29,7,3],
[61,29,7,5],
[61,29,7,6],
[61,29,8,0],
[61,29,8,1],
[61,29,8,2],
[61,29,8,7],
[61,29,9,0],
[61,29,9,1],
[61,29,9,2],
[61,29,9,3],
[61,29,9,5],
[61,29,9,6],
[61,29,9,7],
[61,29,9,8],
[61,29,10,0],
[61,29,10,1],
[61,29,10,2],
[61,29,10,3],
[61,29,10,5],
[61,29,10,6],
[61,29,10,7],
[61,29,10,8],
[61,29,10,9],
[61,29,13,0],
[61,29,13,1],
[61,29,13,2],
[61,29,13,3],
[61,29,13,5],
[61,29,13,6],
[61,29,13,7],
[61,29,13,8],
[61,29,13,9],
[61,29,13,10],
[61,29,14,0],
[61,29,14,1],
[61,29,14,2],
[61,29,14,3],
[61,29,14,5],
[61,29,14,6],
[61,29,14,7],
[61,29,14,8],
[61,29,14,9],
[61,29,14,10],
[61,29,15,0],
[61,29,15,1],
[61,29,15,2],
[61,29,15,3],
[61,29,15,5],
[61,29,15,6],
[61,29,15,7],
[61,29,15,8],
[61,29,15,10],
[61,29,15,13],
[61,29,15,14],
[61,29,16,0],
[61,29,16,1],
[61,29,16,2],
[61,29,16,3],
[61,29,16,5],
[61,29,16,6],
[61,29,16,7],
[61,29,16,8],
[61,29,16,9],
[61,29,16,10],
[61,29,16,13],
[61,29,16,14],
[61,29,16,15],
[61,29,17,0],
[61,29,17,1],
[61,29,17,2],
[61,29,17,3],
[61,29,17,5],
[61,29,17,6],
[61,29,17,7],
[61,29,17,8],
[61,29,17,9],
[61,29,17,10],
[61,29,17,13],
[61,29,17,14],
[61,29,17,15],
[61,29,17,16],
[61,29,18,0],
[61,29,18,1],
[61,29,18,2],
[61,29,18,3],
[61,29,18,5],
[61,29,18,6],
[61,29,18,7],
[61,29,18,8],
[61,29,18,10],
[61,29,18,13],
[61,29,18,14],
[61,29,18,16],
[61,29,18,17],
[61,29,19,0],
[61,29,19,1],
[61,29,19,2],
[61,29,19,3],
[61,29,19,5],
[61,29,19,6],
[61,29,19,7],
[61,29,19,8],
[61,29,19,9],
[61,29,19,10],
[61,29,19,15],
[61,29,19,16],
[61,29,19,17],
[61,29,19,18],
[61,29,20,0],
[61,29,20,1],
[61,29,20,2],
[61,29,20,3],
[61,29,20,5],
[61,29,20,6],
[61,29,20,7],
[61,29,20,8],
[61,29,20,9],
[61,29,20,13],
[61,29,20,14],
[61,29,20,15],
[61,29,20,16],
[61,29,20,17],
[61,29,20,18],
[61,29,20,19],
[61,29,21,0],
[61,29,21,1],
[61,29,21,2],
[61,29,21,7],
[61,29,21,9],
[61,29,21,10],
[61,29,21,13],
[61,29,21,14],
[61,29,21,15],
[61,29,21,16],
[61,29,21,17],
[61,29,21,18],
[61,29,21,19],
[61,29,21,20],
[61,29,22,0],
[61,29,22,1],
[61,29,22,2],
[61,29,22,3],
[61,29,22,5],
[61,29,22,6],
[61,29,22,7],
[61,29,22,8],
[61,29,22,9],
[61,29,22,13],
[61,29,22,14],
[61,29,22,15],
[61,29,22,16],
[61,29,22,17],
[61,29,22,18],
[61,29,22,19],
[61,29,22,21],
[61,29,23,0],
[61,29,23,1],
[61,29,23,2],
[61,29,23,3],
[61,29,23,5],
[61,29,23,6],
[61,29,23,7],
[61,29,23,8],
[61,29,23,9],
[61,29,23,10],
[61,29,23,13],
[61,29,23,14],
[61,29,23,15],
[61,29,23,16],
[61,29,23,17],
[61,29,23,18],
[61,29,23,19],
[61,29,23,20],
[61,29,23,21],
[61,29,23,22],
[61,29,24,0],
[61,29,24,1],
[61,29,24,2],
[61,29,24,3],
[61,29,24,5],
[61,29,24,6],
[61,29,24,7],
[61,29,24,8],
[61,29,24,9],
[61,29,24,10],
[61,29,24,13],
[61,29,24,14],
[61,29,24,15],
[61,29,24,17],
[61,29,24,18],
[61,29,24,19],
[61,29,24,20],
[61,29,24,21],
[61,29,24,22],
[61,29,24,23],
[61,29,25,0],
[61,29,25,1],
[61,29,25,2],
[61,29,25,3],
[61,29,25,5],
[61,29,25,6],
[61,29,25,8],
[61,29,25,9],
[61,29,25,10],
[61,29,25,13],
[61,29,25,14],
[61,29,25,15],
[61,29,25,16],
[61,29,25,17],
[61,29,25,18],
[61,29,25,19],
[61,29,25,20],
[61,29,25,21],
[61,29,25,23],
[61,29,25,24],
[61,29,26,0],
[61,29,26,1],
[61,29,26,2],
[61,29,26,3],
[61,29,26,5],
[61,29,26,6],
[61,29,26,7],
[61,29,26,8],
[61,29,26,10],
[61,29,26,13],
[61,29,26,14],
[61,29,26,16],
[61,29,26,17],
[61,29,26,19],
[61,29,26,20],
[61,29,26,22],
[61,29,26,23],
[61,29,26,24],
[61,29,26,25],
[61,29,27,0],
[61,29,27,1],
[61,29,27,2],
[61,29,27,3],
[61,29,27,5],
[61,29,27,6],
[61,29,27,7],
[61,29,27,8],
[61,29,27,9],
[61,29,27,13],
[61,29,27,14],
[61,29,27,15],
[61,29,27,16],
[61,29,27,17],
[61,29,27,18],
[61,29,27,19],
[61,29,27,21],
[61,29,27,23],
[61,29,27,24],
[61,29,27,25],
[61,29,27,26],
[61,29,28,0],
[61,29,28,1],
[61,29,28,2],
[61,29,28,3],
[61,29,28,5],
[61,29,28,6],
[61,29,28,8],
[61,29,28,9],
[61,29,28,10],
[61,29,28,13],
[61,29,28,14],
[61,29,28,15],
[61,29,28,16],
[61,29,28,17],
[61,29,28,18],
[61,29,28,20],
[61,29,28,21],
[61,29,28,22],
[61,29,28,23],
[61,29,28,24],
[61,29,28,26],
[61,29,28,27],
[61,30,3,0],
[61,30,3,1],
[61,30,3,2],
[61,30,5,0],
[61,30,5,1],
[61,30,5,2],
[61,30,6,0],
[61,30,6,1],
[61,30,6,2],
[61,30,7,0],
[61,30,7,1],
[61,30,7,2],
[61,30,7,3],
[61,30,7,5],
[61,30,7,6],
[61,30,8,0],
[61,30,8,1],
[61,30,8,2],
[61,30,8,7],
[61,30,9,0],
[61,30,9,1],
[61,30,9,2],
[61,30,9,3],
[61,30,9,5],
[61,30,9,6],
[61,30,9,7],
[61,30,9,8],
[61,30,10,0],
[61,30,10,1],
[61,30,10,2],
[61,30,10,3],
[61,30,10,5],
[61,30,10,6],
[61,30,10,7],
[61,30,10,8],
[61,30,10,9],
[61,30,11,0],
[61,30,11,1],
[61,30,11,2],
[61,30,11,3],
[61,30,11,5],
[61,30,11,6],
[61,30,11,7],
[61,30,11,8],
[61,30,11,9],
[61,30,11,10],
[61,30,12,0],
[61,30,12,1],
[61,30,12,2],
[61,30,12,3],
[61,30,12,5],
[61,30,12,6],
[61,30,12,7],
[61,30,12,8],
[61,30,12,9],
[61,30,12,10],
[61,30,13,0],
[61,30,13,1],
[61,30,13,2],
[61,30,13,3],
[61,30,13,5],
[61,30,13,6],
[61,30,13,7],
[61,30,13,8],
[61,30,13,9],
[61,30,13,10],
[61,30,13,11],
[61,30,13,12],
[61,30,14,0],
[61,30,14,1],
[61,30,14,2],
[61,30,14,3],
[61,30,14,5],
[61,30,14,6],
[61,30,14,7],
[61,30,14,8],
[61,30,14,9],
[61,30,14,10],
[61,30,14,11],
[61,30,14,12],
[61,30,15,0],
[61,30,15,1],
[61,30,15,2],
[61,30,15,3],
[61,30,15,5],
[61,30,15,6],
[61,30,15,7],
[61,30,15,8],
[61,30,15,10],
[61,30,15,11],
[61,30,15,12],
[61,30,15,13],
[61,30,15,14],
[61,30,16,0],
[61,30,16,1],
[61,30,16,2],
[61,30,16,3],
[61,30,16,5],
[61,30,16,6],
[61,30,16,7],
[61,30,16,8],
[61,30,16,9],
[61,30,16,10],
[61,30,16,11],
[61,30,16,12],
[61,30,16,13],
[61,30,16,14],
[61,30,16,15],
[61,30,17,0],
[61,30,17,1],
[61,30,17,2],
[61,30,17,3],
[61,30,17,5],
[61,30,17,6],
[61,30,17,7],
[61,30,17,8],
[61,30,17,9],
[61,30,17,10],
[61,30,17,11],
[61,30,17,12],
[61,30,17,13],
[61,30,17,14],
[61,30,17,15],
[61,30,17,16],
[61,30,18,0],
[61,30,18,1],
[61,30,18,2],
[61,30,18,3],
[61,30,18,5],
[61,30,18,6],
[61,30,18,7],
[61,30,18,8],
[61,30,18,10],
[61,30,18,11],
[61,30,18,12],
[61,30,18,13],
[61,30,18,14],
[61,30,18,16],
[61,30,18,17],
[61,30,19,0],
[61,30,19,1],
[61,30,19,2],
[61,30,19,3],
[61,30,19,5],
[61,30,19,6],
[61,30,19,7],
[61,30,19,8],
[61,30,19,9],
[61,30,19,10],
[61,30,19,11],
[61,30,19,12],
[61,30,19,15],
[61,30,19,16],
[61,30,19,17],
[61,30,19,18],
[61,30,20,0],
[61,30,20,1],
[61,30,20,2],
[61,30,20,3],
[61,30,20,5],
[61,30,20,6],
[61,30,20,7],
[61,30,20,8],
[61,30,20,9],
[61,30,20,11],
[61,30,20,12],
[61,30,20,13],
[61,30,20,14],
[61,30,20,15],
[61,30,20,16],
[61,30,20,17],
[61,30,20,18],
[61,30,20,19],
[61,30,21,0],
[61,30,21,1],
[61,30,21,2],
[61,30,21,7],
[61,30,21,9],
[61,30,21,10],
[61,30,21,11],
[61,30,21,12],
[61,30,21,13],
[61,30,21,14],
[61,30,21,15],
[61,30,21,16],
[61,30,21,17],
[61,30,21,18],
[61,30,21,19],
[61,30,21,20],
[61,30,22,0],
[61,30,22,1],
[61,30,22,2],
[61,30,22,3],
[61,30,22,5],
[61,30,22,6],
[61,30,22,7],
[61,30,22,8],
[61,30,22,9],
[61,30,22,11],
[61,30,22,12],
[61,30,22,13],
[61,30,22,14],
[61,30,22,15],
[61,30,22,16],
[61,30,22,17],
[61,30,22,18],
[61,30,22,19],
[61,30,22,21],
[61,30,24,0],
[61,30,24,1],
[61,30,24,2],
[61,30,24,3],
[61,30,24,5],
[61,30,24,6],
[61,30,24,7],
[61,30,24,8],
[61,30,24,9],
[61,30,24,10],
[61,30,24,11],
[61,30,24,12],
[61,30,24,13],
[61,30,24,14],
[61,30,24,15],
[61,30,24,17],
[61,30,24,18],
[61,30,24,19],
[61,30,24,20],
[61,30,24,21],
[61,30,24,22],
[61,30,25,0],
[61,30,25,1],
[61,30,25,2],
[61,30,25,3],
[61,30,25,5],
[61,30,25,6],
[61,30,25,8],
[61,30,25,9],
[61,30,25,10],
[61,30,25,11],
[61,30,25,12],
[61,30,25,13],
[61,30,25,14],
[61,30,25,15],
[61,30,25,16],
[61,30,25,17],
[61,30,25,18],
[61,30,25,19],
[61,30,25,20],
[61,30,25,22],
[61,30,25,24],
[61,30,26,0],
[61,30,26,1],
[61,30,26,2],
[61,30,26,3],
[61,30,26,5],
[61,30,26,6],
[61,30,26,7],
[61,30,26,8],
[61,30,26,10],
[61,30,26,11],
[61,30,26,12],
[61,30,26,13],
[61,30,26,14],
[61,30,26,16],
[61,30,26,17],
[61,30,26,19],
[61,30,26,21],
[61,30,26,22],
[61,30,26,24],
[61,30,26,25],
[61,30,27,0],
[61,30,27,1],
[61,30,27,2],
[61,30,27,3],
[61,30,27,5],
[61,30,27,6],
[61,30,27,7],
[61,30,27,8],
[61,30,27,9],
[61,30,27,11],
[61,30,27,12],
[61,30,27,13],
[61,30,27,14],
[61,30,27,15],
[61,30,27,16],
[61,30,27,17],
[61,30,27,18],
[61,30,27,21],
[61,30,27,24],
[61,30,27,25],
[61,30,27,26],
[61,30,28,0],
[61,30,28,1],
[61,30,28,2],
[61,30,28,3],
[61,30,28,5],
[61,30,28,6],
[61,30,28,8],
[61,30,28,9],
[61,30,28,10],
[61,30,28,11],
[61,30,28,12],
[61,30,28,13],
[61,30,28,14],
[61,30,28,15],
[61,30,28,16],
[61,30,28,17],
[61,30,28,19],
[61,30,28,20],
[61,30,28,21],
[61,30,28,22],
[61,30,28,24],
[61,30,28,26],
[61,30,28,27],
[61,30,29,0],
[61,30,29,1],
[61,30,29,2],
[61,30,29,3],
[61,30,29,5],
[61,30,29,6],
[61,30,29,7],
[61,30,29,8],
[61,30,29,9],
[61,30,29,10],
[61,30,29,13],
[61,30,29,14],
[61,30,29,15],
[61,30,29,18],
[61,30,29,19],
[61,30,29,20],
[61,30,29,21],
[61,30,29,22],
[61,30,29,24],
[61,30,29,25],
[61,30,29,26],
[61,30,29,27],
[61,30,29,28],
[61,31,3,0],
[61,31,3,1],
[61,31,3,2],
[61,31,5,0],
[61,31,5,1],
[61,31,5,2],
[61,31,6,0],
[61,31,6,1],
[61,31,6,2],
[61,31,7,0],
[61,31,7,1],
[61,31,7,2],
[61,31,7,3],
[61,31,7,5],
[61,31,7,6],
[61,31,8,0],
[61,31,8,1],
[61,31,8,2],
[61,31,8,7],
[61,31,9,0],
[61,31,9,1],
[61,31,9,2],
[61,31,9,3],
[61,31,9,5],
[61,31,9,6],
[61,31,9,7],
[61,31,9,8],
[61,31,10,0],
[61,31,10,1],
[61,31,10,2],
[61,31,10,3],
[61,31,10,5],
[61,31,10,6],
[61,31,10,7],
[61,31,10,8],
[61,31,10,9],
[61,31,11,0],
[61,31,11,1],
[61,31,11,2],
[61,31,11,3],
[61,31,11,5],
[61,31,11,6],
[61,31,11,7],
[61,31,11,8],
[61,31,11,9],
[61,31,11,10],
[61,31,12,0],
[61,31,12,1],
[61,31,12,2],
[61,31,12,3],
[61,31,12,5],
[61,31,12,6],
[61,31,12,7],
[61,31,12,8],
[61,31,12,9],
[61,31,12,10],
[61,31,13,0],
[61,31,13,1],
[61,31,13,2],
[61,31,13,3],
[61,31,13,5],
[61,31,13,6],
[61,31,13,7],
[61,31,13,8],
[61,31,13,9],
[61,31,13,10],
[61,31,13,11],
[61,31,13,12],
[61,31,14,0],
[61,31,14,1],
[61,31,14,2],
[61,31,14,3],
[61,31,14,5],
[61,31,14,6],
[61,31,14,7],
[61,31,14,8],
[61,31,14,9],
[61,31,14,10],
[61,31,14,11],
[61,31,14,12],
[61,31,15,0],
[61,31,15,1],
[61,31,15,2],
[61,31,15,3],
[61,31,15,5],
[61,31,15,6],
[61,31,15,7],
[61,31,15,8],
[61,31,15,10],
[61,31,15,11],
[61,31,15,12],
[61,31,15,13],
[61,31,15,14],
[61,31,16,0],
[61,31,16,1],
[61,31,16,2],
[61,31,16,3],
[61,31,16,5],
[61,31,16,6],
[61,31,16,7],
[61,31,16,8],
[61,31,16,9],
[61,31,16,10],
[61,31,16,11],
[61,31,16,12],
[61,31,16,13],
[61,31,16,14],
[61,31,16,15],
[61,31,17,0],
[61,31,17,1],
[61,31,17,2],
[61,31,17,3],
[61,31,17,5],
[61,31,17,6],
[61,31,17,7],
[61,31,17,8],
[61,31,17,9],
[61,31,17,10],
[61,31,17,11],
[61,31,17,12],
[61,31,17,13],
[61,31,17,14],
[61,31,17,15],
[61,31,17,16],
[61,31,18,0],
[61,31,18,1],
[61,31,18,2],
[61,31,18,3],
[61,31,18,5],
[61,31,18,6],
[61,31,18,7],
[61,31,18,8],
[61,31,18,10],
[61,31,18,11],
[61,31,18,12],
[61,31,18,13],
[61,31,18,14],
[61,31,18,16],
[61,31,18,17],
[61,31,19,0],
[61,31,19,1],
[61,31,19,2],
[61,31,19,3],
[61,31,19,5],
[61,31,19,6],
[61,31,19,7],
[61,31,19,8],
[61,31,19,9],
[61,31,19,10],
[61,31,19,11],
[61,31,19,12],
[61,31,19,15],
[61,31,19,16],
[61,31,19,17],
[61,31,19,18],
[61,31,20,0],
[61,31,20,1],
[61,31,20,2],
[61,31,20,3],
[61,31,20,5],
[61,31,20,6],
[61,31,20,7],
[61,31,20,8],
[61,31,20,9],
[61,31,20,11],
[61,31,20,12],
[61,31,20,13],
[61,31,20,14],
[61,31,20,15],
[61,31,20,16],
[61,31,20,17],
[61,31,20,18],
[61,31,20,19],
[61,31,21,0],
[61,31,21,1],
[61,31,21,2],
[61,31,21,7],
[61,31,21,9],
[61,31,21,10],
[61,31,21,11],
[61,31,21,12],
[61,31,21,13],
[61,31,21,14],
[61,31,21,15],
[61,31,21,16],
[61,31,21,17],
[61,31,21,18],
[61,31,21,19],
[61,31,21,20],
[61,31,22,0],
[61,31,22,1],
[61,31,22,2],
[61,31,22,3],
[61,31,22,5],
[61,31,22,6],
[61,31,22,7],
[61,31,22,8],
[61,31,22,9],
[61,31,22,11],
[61,31,22,12],
[61,31,22,13],
[61,31,22,14],
[61,31,22,15],
[61,31,22,16],
[61,31,22,17],
[61,31,22,18],
[61,31,22,19],
[61,31,22,21],
[61,31,24,0],
[61,31,24,1],
[61,31,24,2],
[61,31,24,3],
[61,31,24,5],
[61,31,24,6],
[61,31,24,7],
[61,31,24,8],
[61,31,24,9],
[61,31,24,10],
[61,31,24,11],
[61,31,24,12],
[61,31,24,13],
[61,31,24,14],
[61,31,24,15],
[61,31,24,17],
[61,31,24,18],
[61,31,24,19],
[61,31,24,20],
[61,31,24,21],
[61,31,25,0],
[61,31,25,1],
[61,31,25,2],
[61,31,25,3],
[61,31,25,5],
[61,31,25,6],
[61,31,25,8],
[61,31,25,9],
[61,31,25,10],
[61,31,25,11],
[61,31,25,12],
[61,31,25,13],
[61,31,25,14],
[61,31,25,15],
[61,31,25,16],
[61,31,25,17],
[61,31,25,18],
[61,31,25,19],
[61,31,25,21],
[61,31,25,22],
[61,31,25,24],
[61,31,26,0],
[61,31,26,1],
[61,31,26,2],
[61,31,26,3],
[61,31,26,5],
[61,31,26,6],
[61,31,26,7],
[61,31,26,8],
[61,31,26,10],
[61,31,26,11],
[61,31,26,12],
[61,31,26,13],
[61,31,26,14],
[61,31,26,16],
[61,31,26,17],
[61,31,26,20],
[61,31,26,21],
[61,31,26,22],
[61,31,26,24],
[61,31,26,25],
[61,31,27,0],
[61,31,27,1],
[61,31,27,2],
[61,31,27,3],
[61,31,27,5],
[61,31,27,6],
[61,31,27,7],
[61,31,27,8],
[61,31,27,9],
[61,31,27,11],
[61,31,27,12],
[61,31,27,13],
[61,31,27,14],
[61,31,27,15],
[61,31,27,16],
[61,31,27,17],
[61,31,27,19],
[61,31,27,21],
[61,31,27,24],
[61,31,27,25],
[61,31,27,26],
[61,31,28,0],
[61,31,28,1],
[61,31,28,2],
[61,31,28,3],
[61,31,28,5],
[61,31,28,6],
[61,31,28,8],
[61,31,28,9],
[61,31,28,10],
[61,31,28,11],
[61,31,28,12],
[61,31,28,13],
[61,31,28,14],
[61,31,28,15],
[61,31,28,18],
[61,31,28,19],
[61,31,28,20],
[61,31,28,21],
[61,31,28,22],
[61,31,28,24],
[61,31,28,26],
[61,31,28,27],
[61,31,29,0],
[61,31,29,1],
[61,31,29,2],
[61,31,29,3],
[61,31,29,5],
[61,31,29,6],
[61,31,29,7],
[61,31,29,8],
[61,31,29,9],
[61,31,29,10],
[61,31,29,13],
[61,31,29,14],
[61,31,29,16],
[61,31,29,17],
[61,31,29,18],
[61,31,29,19],
[61,31,29,20],
[61,31,29,21],
[61,31,29,22],
[61,31,29,24],
[61,31,29,25],
[61,31,29,26],
[61,31,29,27],
[61,31,29,28],
[61,32,3,0],
[61,32,3,1],
[61,32,3,2],
[61,32,5,0],
[61,32,5,1],
[61,32,5,2],
[61,32,6,0],
[61,32,6,1],
[61,32,6,2],
[61,32,7,0],
[61,32,7,1],
[61,32,7,2],
[61,32,7,3],
[61,32,7,5],
[61,32,7,6],
[61,32,8,0],
[61,32,8,1],
[61,32,8,2],
[61,32,8,7],
[61,32,9,0],
[61,32,9,1],
[61,32,9,2],
[61,32,9,3],
[61,32,9,5],
[61,32,9,6],
[61,32,9,7],
[61,32,9,8],
[61,32,10,0],
[61,32,10,1],
[61,32,10,2],
[61,32,10,3],
[61,32,10,5],
[61,32,10,6],
[61,32,10,7],
[61,32,10,8],
[61,32,10,9],
[61,32,13,0],
[61,32,13,1],
[61,32,13,2],
[61,32,13,3],
[61,32,13,5],
[61,32,13,6],
[61,32,13,7],
[61,32,13,8],
[61,32,13,9],
[61,32,13,10],
[61,32,14,0],
[61,32,14,1],
[61,32,14,2],
[61,32,14,3],
[61,32,14,5],
[61,32,14,6],
[61,32,14,7],
[61,32,14,8],
[61,32,14,9],
[61,32,14,10],
[61,32,15,0],
[61,32,15,1],
[61,32,15,2],
[61,32,15,3],
[61,32,15,5],
[61,32,15,6],
[61,32,15,7],
[61,32,15,8],
[61,32,15,10],
[61,32,15,13],
[61,32,15,14],
[61,32,16,0],
[61,32,16,1],
[61,32,16,2],
[61,32,16,3],
[61,32,16,5],
[61,32,16,6],
[61,32,16,7],
[61,32,16,8],
[61,32,16,9],
[61,32,16,10],
[61,32,16,13],
[61,32,16,14],
[61,32,16,15],
[61,32,17,0],
[61,32,17,1],
[61,32,17,2],
[61,32,17,3],
[61,32,17,5],
[61,32,17,6],
[61,32,17,7],
[61,32,17,8],
[61,32,17,9],
[61,32,17,10],
[61,32,17,13],
[61,32,17,14],
[61,32,17,15],
[61,32,17,16],
[61,32,18,0],
[61,32,18,1],
[61,32,18,2],
[61,32,18,3],
[61,32,18,5],
[61,32,18,6],
[61,32,18,7],
[61,32,18,8],
[61,32,18,10],
[61,32,18,13],
[61,32,18,14],
[61,32,18,16],
[61,32,18,17],
[61,32,19,0],
[61,32,19,1],
[61,32,19,2],
[61,32,19,3],
[61,32,19,5],
[61,32,19,6],
[61,32,19,7],
[61,32,19,8],
[61,32,19,9],
[61,32,19,10],
[61,32,19,15],
[61,32,19,16],
[61,32,19,17],
[61,32,19,18],
[61,32,20,0],
[61,32,20,1],
[61,32,20,2],
[61,32,20,3],
[61,32,20,5],
[61,32,20,6],
[61,32,20,7],
[61,32,20,8],
[61,32,20,9],
[61,32,20,13],
[61,32,20,14],
[61,32,20,15],
[61,32,20,16],
[61,32,20,17],
[61,32,20,18],
[61,32,20,19],
[61,32,21,0],
[61,32,21,1],
[61,32,21,2],
[61,32,21,7],
[61,32,21,9],
[61,32,21,10],
[61,32,21,13],
[61,32,21,14],
[61,32,21,15],
[61,32,21,16],
[61,32,21,17],
[61,32,21,18],
[61,32,21,19],
[61,32,21,20],
[61,32,22,0],
[61,32,22,1],
[61,32,22,2],
[61,32,22,3],
[61,32,22,5],
[61,32,22,6],
[61,32,22,7],
[61,32,22,8],
[61,32,22,9],
[61,32,22,13],
[61,32,22,14],
[61,32,22,15],
[61,32,22,16],
[61,32,22,17],
[61,32,22,18],
[61,32,22,19],
[61,32,22,21],
[61,32,23,0],
[61,32,23,1],
[61,32,23,2],
[61,32,23,3],
[61,32,23,5],
[61,32,23,6],
[61,32,23,7],
[61,32,23,8],
[61,32,23,9],
[61,32,23,10],
[61,32,23,13],
[61,32,23,14],
[61,32,23,15],
[61,32,23,16],
[61,32,23,17],
[61,32,23,18],
[61,32,23,19],
[61,32,23,20],
[61,32,23,22],
[61,32,24,0],
[61,32,24,1],
[61,32,24,2],
[61,32,24,3],
[61,32,24,5],
[61,32,24,6],
[61,32,24,7],
[61,32,24,8],
[61,32,24,9],
[61,32,24,10],
[61,32,24,13],
[61,32,24,14],
[61,32,24,15],
[61,32,24,17],
[61,32,24,18],
[61,32,24,19],
[61,32,24,20],
[61,32,24,22],
[61,32,24,23],
[61,32,25,0],
[61,32,25,1],
[61,32,25,2],
[61,32,25,3],
[61,32,25,5],
[61,32,25,6],
[61,32,25,8],
[61,32,25,9],
[61,32,25,10],
[61,32,25,13],
[61,32,25,14],
[61,32,25,15],
[61,32,25,16],
[61,32,25,17],
[61,32,25,18],
[61,32,25,20],
[61,32,25,21],
[61,32,25,22],
[61,32,25,23],
[61,32,25,24],
[61,32,26,0],
[61,32,26,1],
[61,32,26,2],
[61,32,26,3],
[61,32,26,5],
[61,32,26,6],
[61,32,26,7],
[61,32,26,8],
[61,32,26,10],
[61,32,26,13],
[61,32,26,14],
[61,32,26,16],
[61,32,26,17],
[61,32,26,19],
[61,32,26,20],
[61,32,26,21],
[61,32,26,22],
[61,32,26,23],
[61,32,26,24],
[61,32,26,25],
[61,32,27,0],
[61,32,27,1],
[61,32,27,2],
[61,32,27,3],
[61,32,27,5],
[61,32,27,6],
[61,32,27,7],
[61,32,27,8],
[61,32,27,9],
[61,32,27,13],
[61,32,27,14],
[61,32,27,15],
[61,32,27,18],
[61,32,27,19],
[61,32,27,21],
[61,32,27,23],
[61,32,27,24],
[61,32,27,25],
[61,32,27,26],
[61,32,28,0],
[61,32,28,1],
[61,32,28,2],
[61,32,28,3],
[61,32,28,5],
[61,32,28,6],
[61,32,28,8],
[61,32,28,9],
[61,32,28,10],
[61,32,28,13],
[61,32,28,14],
[61,32,28,16],
[61,32,28,17],
[61,32,28,18],
[61,32,28,19],
[61,32,28,20],
[61,32,28,21],
[61,32,28,22],
[61,32,28,23],
[61,32,28,24],
[61,32,28,26],
[61,32,28,27],
[61,32,30,0],
[61,32,30,1],
[61,32,30,2],
[61,32,30,3],
[61,32,30,5],
[61,32,30,6],
[61,32,30,7],
[61,32,30,8],
[61,32,30,9],
[61,32,30,10],
[61,32,30,14],
[61,32,30,15],
[61,32,30,16],
[61,32,30,17],
[61,32,30,18],
[61,32,30,19],
[61,32,30,20],
[61,32,30,21],
[61,32,30,22],
[61,32,30,24],
[61,32,30,25],
[61,32,30,26],
[61,32,31,0],
[61,32,31,1],
[61,32,31,2],
[61,32,31,3],
[61,32,31,5],
[61,32,31,6],
[61,32,31,7],
[61,32,31,8],
[61,32,31,9],
[61,32,31,13],
[61,32,31,14],
[61,32,31,15],
[61,32,31,16],
[61,32,31,17],
[61,32,31,18],
[61,32,31,19],
[61,32,31,20],
[61,32,31,21],
[61,32,31,22],
[61,32,31,24],
[61,32,31,25],
[61,33,3,0],
[61,33,3,1],
[61,33,3,2],
[61,33,5,0],
[61,33,5,1],
[61,33,5,2],
[61,33,6,0],
[61,33,6,1],
[61,33,6,2],
[61,33,7,0],
[61,33,7,1],
[61,33,7,2],
[61,33,7,3],
[61,33,7,5],
[61,33,7,6],
[61,33,8,0],
[61,33,8,1],
[61,33,8,2],
[61,33,8,7],
[61,33,9,0],
[61,33,9,1],
[61,33,9,2],
[61,33,9,3],
[61,33,9,5],
[61,33,9,6],
[61,33,9,7],
[61,33,9,8],
[61,33,10,0],
[61,33,10,1],
[61,33,10,2],
[61,33,10,3],
[61,33,10,5],
[61,33,10,6],
[61,33,10,7],
[61,33,10,8],
[61,33,10,9],
[61,33,13,0],
[61,33,13,1],
[61,33,13,2],
[61,33,13,3],
[61,33,13,5],
[61,33,13,6],
[61,33,13,7],
[61,33,13,8],
[61,33,13,9],
[61,33,13,10],
[61,33,14,0],
[61,33,14,1],
[61,33,14,2],
[61,33,14,3],
[61,33,14,5],
[61,33,14,6],
[61,33,14,7],
[61,33,14,8],
[61,33,14,9],
[61,33,14,10],
[61,33,15,0],
[61,33,15,1],
[61,33,15,2],
[61,33,15,3],
[61,33,15,5],
[61,33,15,6],
[61,33,15,7],
[61,33,15,8],
[61,33,15,10],
[61,33,15,13],
[61,33,15,14],
[61,33,16,0],
[61,33,16,1],
[61,33,16,2],
[61,33,16,3],
[61,33,16,5],
[61,33,16,6],
[61,33,16,7],
[61,33,16,8],
[61,33,16,9],
[61,33,16,10],
[61,33,16,13],
[61,33,16,14],
[61,33,16,15],
[61,33,17,0],
[61,33,17,1],
[61,33,17,2],
[61,33,17,3],
[61,33,17,5],
[61,33,17,6],
[61,33,17,7],
[61,33,17,8],
[61,33,17,9],
[61,33,17,10],
[61,33,17,13],
[61,33,17,14],
[61,33,17,15],
[61,33,17,16],
[61,33,18,0],
[61,33,18,1],
[61,33,18,2],
[61,33,18,3],
[61,33,18,5],
[61,33,18,6],
[61,33,18,7],
[61,33,18,8],
[61,33,18,10],
[61,33,18,13],
[61,33,18,14],
[61,33,18,16],
[61,33,18,17],
[61,33,19,0],
[61,33,19,1],
[61,33,19,2],
[61,33,19,3],
[61,33,19,5],
[61,33,19,6],
[61,33,19,7],
[61,33,19,8],
[61,33,19,9],
[61,33,19,10],
[61,33,19,15],
[61,33,19,16],
[61,33,19,17],
[61,33,19,18],
[61,33,20,0],
[61,33,20,1],
[61,33,20,2],
[61,33,20,3],
[61,33,20,5],
[61,33,20,6],
[61,33,20,7],
[61,33,20,8],
[61,33,20,9],
[61,33,20,13],
[61,33,20,14],
[61,33,20,15],
[61,33,20,16],
[61,33,20,17],
[61,33,20,18],
[61,33,20,19],
[61,33,21,0],
[61,33,21,1],
[61,33,21,2],
[61,33,21,7],
[61,33,21,9],
[61,33,21,10],
[61,33,21,13],
[61,33,21,14],
[61,33,21,15],
[61,33,21,16],
[61,33,21,17],
[61,33,21,18],
[61,33,21,19],
[61,33,21,20],
[61,33,22,0],
[61,33,22,1],
[61,33,22,2],
[61,33,22,3],
[61,33,22,5],
[61,33,22,6],
[61,33,22,7],
[61,33,22,8],
[61,33,22,9],
[61,33,22,13],
[61,33,22,14],
[61,33,22,15],
[61,33,22,16],
[61,33,22,17],
[61,33,22,18],
[61,33,22,19],
[61,33,23,0],
[61,33,23,1],
[61,33,23,2],
[61,33,23,3],
[61,33,23,5],
[61,33,23,6],
[61,33,23,7],
[61,33,23,8],
[61,33,23,9],
[61,33,23,10],
[61,33,23,13],
[61,33,23,14],
[61,33,23,15],
[61,33,23,16],
[61,33,23,17],
[61,33,23,18],
[61,33,23,19],
[61,33,23,21],
[61,33,23,22],
[61,33,24,0],
[61,33,24,1],
[61,33,24,2],
[61,33,24,3],
[61,33,24,5],
[61,33,24,6],
[61,33,24,7],
[61,33,24,8],
[61,33,24,9],
[61,33,24,10],
[61,33,24,13],
[61,33,24,14],
[61,33,24,15],
[61,33,24,17],
[61,33,24,18],
[61,33,24,19],
[61,33,24,21],
[61,33,24,22],
[61,33,24,23],
[61,33,25,0],
[61,33,25,1],
[61,33,25,2],
[61,33,25,3],
[61,33,25,5],
[61,33,25,6],
[61,33,25,8],
[61,33,25,9],
[61,33,25,10],
[61,33,25,13],
[61,33,25,14],
[61,33,25,15],
[61,33,25,16],
[61,33,25,17],
[61,33,25,19],
[61,33,25,20],
[61,33,25,21],
[61,33,25,22],
[61,33,25,23],
[61,33,25,24],
[61,33,26,0],
[61,33,26,1],
[61,33,26,2],
[61,33,26,3],
[61,33,26,5],
[61,33,26,6],
[61,33,26,7],
[61,33,26,8],
[61,33,26,10],
[61,33,26,13],
[61,33,26,14],
[61,33,26,19],
[61,33,26,20],
[61,33,26,21],
[61,33,26,22],
[61,33,26,23],
[61,33,26,24],
[61,33,26,25],
[61,33,27,0],
[61,33,27,1],
[61,33,27,2],
[61,33,27,3],
[61,33,27,5],
[61,33,27,6],
[61,33,27,7],
[61,33,27,8],
[61,33,27,9],
[61,33,27,13],
[61,33,27,14],
[61,33,27,16],
[61,33,27,17],
[61,33,27,18],
[61,33,27,19],
[61,33,27,21],
[61,33,27,23],
[61,33,27,24],
[61,33,27,25],
[61,33,27,26],
[61,33,28,0],
[61,33,28,1],
[61,33,28,2],
[61,33,28,3],
[61,33,28,5],
[61,33,28,6],
[61,33,28,8],
[61,33,28,9],
[61,33,28,10],
[61,33,28,13],
[61,33,28,15],
[61,33,28,16],
[61,33,28,17],
[61,33,28,18],
[61,33,28,19],
[61,33,28,20],
[61,33,28,21],
[61,33,28,22],
[61,33,28,23],
[61,33,28,24],
[61,33,28,26],
[61,33,28,27],
[61,33,30,0],
[61,33,30,1],
[61,33,30,2],
[61,33,30,3],
[61,33,30,5],
[61,33,30,6],
[61,33,30,7],
[61,33,30,8],
[61,33,30,9],
[61,33,30,13],
[61,33,30,14],
[61,33,30,15],
[61,33,30,16],
[61,33,30,17],
[61,33,30,18],
[61,33,30,19],
[61,33,30,20],
[61,33,30,21],
[61,33,30,22],
[61,33,30,24],
[61,33,30,25],
[61,33,31,0],
[61,33,31,1],
[61,33,31,2],
[61,33,31,3],
[61,33,31,5],
[61,33,31,6],
[61,33,31,7],
[61,33,31,10],
[61,33,31,13],
[61,33,31,14],
[61,33,31,15],
[61,33,31,16],
[61,33,31,17],
[61,33,31,18],
[61,33,31,19],
[61,33,31,20],
[61,33,31,21],
[61,33,31,22],
[61,33,31,24],
[61,34,3,0],
[61,34,3,1],
[61,34,3,2],
[61,34,5,0],
[61,34,5,1],
[61,34,5,2],
[61,34,6,0],
[61,34,6,1],
[61,34,6,2],
[61,34,8,0],
[61,34,8,1],
[61,34,8,2],
[61,34,9,0],
[61,34,9,1],
[61,34,9,2],
[61,34,9,3],
[61,34,9,5],
[61,34,9,6],
[61,34,9,8],
[61,34,10,0],
[61,34,10,1],
[61,34,10,2],
[61,34,10,3],
[61,34,10,5],
[61,34,10,6],
[61,34,10,8],
[61,34,10,9],
[61,34,11,0],
[61,34,11,1],
[61,34,11,2],
[61,34,11,3],
[61,34,11,5],
[61,34,11,6],
[61,34,11,8],
[61,34,11,9],
[61,34,11,10],
[61,34,12,0],
[61,34,12,1],
[61,34,12,2],
[61,34,12,3],
[61,34,12,5],
[61,34,12,6],
[61,34,12,8],
[61,34,12,9],
[61,34,12,10],
[61,34,13,0],
[61,34,13,1],
[61,34,13,2],
[61,34,13,3],
[61,34,13,5],
[61,34,13,6],
[61,34,13,8],
[61,34,13,9],
[61,34,13,10],
[61,34,13,11],
[61,34,13,12],
[61,34,14,0],
[61,34,14,1],
[61,34,14,2],
[61,34,14,3],
[61,34,14,5],
[61,34,14,6],
[61,34,14,8],
[61,34,14,9],
[61,34,14,10],
[61,34,14,11],
[61,34,14,12],
[61,34,15,0],
[61,34,15,1],
[61,34,15,2],
[61,34,15,3],
[61,34,15,5],
[61,34,15,6],
[61,34,15,8],
[61,34,15,10],
[61,34,15,11],
[61,34,15,12],
[61,34,15,13],
[61,34,15,14],
[61,34,16,0],
[61,34,16,1],
[61,34,16,2],
[61,34,16,3],
[61,34,16,5],
[61,34,16,6],
[61,34,16,8],
[61,34,16,9],
[61,34,16,10],
[61,34,16,11],
[61,34,16,12],
[61,34,16,13],
[61,34,16,14],
[61,34,16,15],
[61,34,17,0],
[61,34,17,1],
[61,34,17,2],
[61,34,17,3],
[61,34,17,5],
[61,34,17,6],
[61,34,17,8],
[61,34,17,9],
[61,34,17,10],
[61,34,17,11],
[61,34,17,12],
[61,34,17,13],
[61,34,17,14],
[61,34,17,15],
[61,34,17,16],
[61,34,18,0],
[61,34,18,1],
[61,34,18,2],
[61,34,18,3],
[61,34,18,5],
[61,34,18,6],
[61,34,18,8],
[61,34,18,10],
[61,34,18,11],
[61,34,18,12],
[61,34,18,13],
[61,34,18,14],
[61,34,18,16],
[61,34,18,17],
[61,34,19,0],
[61,34,19,1],
[61,34,19,2],
[61,34,19,3],
[61,34,19,5],
[61,34,19,6],
[61,34,19,8],
[61,34,19,9],
[61,34,19,10],
[61,34,19,11],
[61,34,19,12],
[61,34,19,15],
[61,34,19,16],
[61,34,19,17],
[61,34,19,18],
[61,34,20,0],
[61,34,20,1],
[61,34,20,2],
[61,34,20,3],
[61,34,20,5],
[61,34,20,6],
[61,34,20,8],
[61,34,20,9],
[61,34,20,11],
[61,34,20,12],
[61,34,20,13],
[61,34,20,14],
[61,34,20,15],
[61,34,20,16],
[61,34,20,17],
[61,34,20,18],
[61,34,20,19],
[61,34,21,0],
[61,34,21,1],
[61,34,21,2],
[61,34,21,9],
[61,34,21,10],
[61,34,21,11],
[61,34,21,12],
[61,34,21,13],
[61,34,21,14],
[61,34,21,15],
[61,34,21,16],
[61,34,21,17],
[61,34,21,18],
[61,34,21,19],
[61,34,21,20],
[61,34,22,0],
[61,34,22,1],
[61,34,22,2],
[61,34,22,3],
[61,34,22,5],
[61,34,22,6],
[61,34,22,8],
[61,34,22,9],
[61,34,22,11],
[61,34,22,12],
[61,34,22,13],
[61,34,22,14],
[61,34,22,15],
[61,34,22,16],
[61,34,22,17],
[61,34,22,18],
[61,34,22,19],
[61,34,22,21],
[61,34,23,0],
[61,34,23,1],
[61,34,23,2],
[61,34,23,3],
[61,34,23,5],
[61,34,23,6],
[61,34,23,8],
[61,34,23,9],
[61,34,23,10],
[61,34,23,11],
[61,34,23,12],
[61,34,23,13],
[61,34,23,14],
[61,34,23,15],
[61,34,23,16],
[61,34,23,17],
[61,34,23,18],
[61,34,23,20],
[61,34,23,21],
[61,34,23,22],
[61,34,24,0],
[61,34,24,1],
[61,34,24,2],
[61,34,24,3],
[61,34,24,5],
[61,34,24,6],
[61,34,24,8],
[61,34,24,9],
[61,34,24,10],
[61,34,24,11],
[61,34,24,12],
[61,34,24,13],
[61,34,24,14],
[61,34,24,15],
[61,34,24,17],
[61,34,24,18],
[61,34,24,20],
[61,34,24,21],
[61,34,24,22],
[61,34,24,23],
[61,34,26,0],
[61,34,26,1],
[61,34,26,2],
[61,34,26,3],
[61,34,26,5],
[61,34,26,6],
[61,34,26,8],
[61,34,26,10],
[61,34,26,11],
[61,34,26,12],
[61,34,26,13],
[61,34,26,14],
[61,34,26,16],
[61,34,26,17],
[61,34,26,19],
[61,34,26,20],
[61,34,26,21],
[61,34,26,22],
[61,34,26,23],
[61,34,26,24],
[61,34,27,0],
[61,34,27,1],
[61,34,27,2],
[61,34,27,3],
[61,34,27,5],
[61,34,27,6],
[61,34,27,8],
[61,34,27,9],
[61,34,27,11],
[61,34,27,12],
[61,34,27,13],
[61,34,27,15],
[61,34,27,16],
[61,34,27,17],
[61,34,27,18],
[61,34,27,19],
[61,34,27,21],
[61,34,27,23],
[61,34,27,24],
[61,34,27,26],
[61,34,29,0],
[61,34,29,1],
[61,34,29,2],
[61,34,29,3],
[61,34,29,5],
[61,34,29,6],
[61,34,29,8],
[61,34,29,9],
[61,34,29,13],
[61,34,29,14],
[61,34,29,15],
[61,34,29,16],
[61,34,29,17],
[61,34,29,18],
[61,34,29,19],
[61,34,29,20],
[61,34,29,21],
[61,34,29,22],
[61,34,29,23],
[61,34,29,24],
[61,34,30,0],
[61,34,30,1],
[61,34,30,2],
[61,34,30,3],
[61,34,30,5],
[61,34,30,6],
[61,34,30,10],
[61,34,30,11],
[61,34,30,12],
[61,34,30,13],
[61,34,30,14],
[61,34,30,15],
[61,34,30,16],
[61,34,30,17],
[61,34,30,18],
[61,34,30,19],
[61,34,30,20],
[61,34,30,21],
[61,34,30,22],
[61,34,30,24],
[61,34,31,0],
[61,34,31,1],
[61,34,31,2],
[61,34,31,3],
[61,34,31,5],
[61,34,31,8],
[61,34,31,9],
[61,34,31,10],
[61,34,31,11],
[61,34,31,12],
[61,34,31,13],
[61,34,31,14],
[61,34,31,15],
[61,34,31,16],
[61,34,31,17],
[61,34,31,18],
[61,34,31,19],
[61,34,31,20],
[61,34,31,21],
[61,34,31,22],
[61,34,31,24],
[61,34,32,0],
[61,34,32,1],
[61,34,32,2],
[61,34,32,3],
[61,34,32,6],
[61,34,32,8],
[61,34,32,9],
[61,34,32,10],
[61,34,32,13],
[61,34,32,14],
[61,34,32,15],
[61,34,32,16],
[61,34,32,17],
[61,34,32,18],
[61,34,32,19],
[61,34,32,20],
[61,34,32,21],
[61,34,32,22],
[61,34,33,0],
[61,34,33,1],
[61,34,33,2],
[61,34,33,5],
[61,34,33,6],
[61,34,33,8],
[61,34,33,9],
[61,34,33,10],
[61,34,33,13],
[61,34,33,14],
[61,34,33,15],
[61,34,33,16],
[61,34,33,17],
[61,34,33,18],
[61,34,33,19],
[61,34,33,20],
[61,34,33,21],
[61,35,3,0],
[61,35,3,1],
[61,35,3,2],
[61,35,5,0],
[61,35,5,1],
[61,35,5,2],
[61,35,6,0],
[61,35,6,1],
[61,35,6,2],
[61,35,7,0],
[61,35,7,1],
[61,35,7,2],
[61,35,7,3],
[61,35,7,5],
[61,35,7,6],
[61,35,8,0],
[61,35,8,1],
[61,35,8,2],
[61,35,8,7],
[61,35,10,0],
[61,35,10,1],
[61,35,10,2],
[61,35,10,3],
[61,35,10,5],
[61,35,10,6],
[61,35,10,7],
[61,35,10,8],
[61,35,11,0],
[61,35,11,1],
[61,35,11,2],
[61,35,11,3],
[61,35,11,5],
[61,35,11,6],
[61,35,11,7],
[61,35,11,8],
[61,35,11,10],
[61,35,12,0],
[61,35,12,1],
[61,35,12,2],
[61,35,12,3],
[61,35,12,5],
[61,35,12,6],
[61,35,12,7],
[61,35,12,8],
[61,35,12,10],
[61,35,13,0],
[61,35,13,1],
[61,35,13,2],
[61,35,13,3],
[61,35,13,5],
[61,35,13,6],
[61,35,13,7],
[61,35,13,8],
[61,35,13,10],
[61,35,13,11],
[61,35,13,12],
[61,35,14,0],
[61,35,14,1],
[61,35,14,2],
[61,35,14,3],
[61,35,14,5],
[61,35,14,6],
[61,35,14,7],
[61,35,14,8],
[61,35,14,10],
[61,35,14,11],
[61,35,14,12],
[61,35,16,0],
[61,35,16,1],
[61,35,16,2],
[61,35,16,3],
[61,35,16,5],
[61,35,16,6],
[61,35,16,7],
[61,35,16,8],
[61,35,16,10],
[61,35,16,11],
[61,35,16,12],
[61,35,16,13],
[61,35,16,14],
[61,35,17,0],
[61,35,17,1],
[61,35,17,2],
[61,35,17,3],
[61,35,17,5],
[61,35,17,6],
[61,35,17,7],
[61,35,17,8],
[61,35,17,10],
[61,35,17,11],
[61,35,17,12],
[61,35,17,13],
[61,35,17,14],
[61,35,17,16],
[61,35,19,0],
[61,35,19,1],
[61,35,19,2],
[61,35,19,3],
[61,35,19,5],
[61,35,19,6],
[61,35,19,7],
[61,35,19,8],
[61,35,19,10],
[61,35,19,11],
[61,35,19,12],
[61,35,19,16],
[61,35,19,17],
[61,35,20,0],
[61,35,20,1],
[61,35,20,2],
[61,35,20,3],
[61,35,20,5],
[61,35,20,6],
[61,35,20,7],
[61,35,20,8],
[61,35,20,11],
[61,35,20,12],
[61,35,20,13],
[61,35,20,14],
[61,35,20,16],
[61,35,20,17],
[61,35,20,19],
[61,35,21,0],
[61,35,21,1],
[61,35,21,2],
[61,35,21,7],
[61,35,21,10],
[61,35,21,11],
[61,35,21,12],
[61,35,21,13],
[61,35,21,14],
[61,35,21,16],
[61,35,21,17],
[61,35,21,19],
[61,35,22,0],
[61,35,22,1],
[61,35,22,2],
[61,35,22,3],
[61,35,22,5],
[61,35,22,6],
[61,35,22,7],
[61,35,22,8],
[61,35,22,11],
[61,35,22,12],
[61,35,22,13],
[61,35,22,14],
[61,35,22,16],
[61,35,22,17],
[61,35,22,21],
[61,35,23,0],
[61,35,23,1],
[61,35,23,2],
[61,35,23,3],
[61,35,23,5],
[61,35,23,6],
[61,35,23,7],
[61,35,23,8],
[61,35,23,10],
[61,35,23,11],
[61,35,23,12],
[61,35,23,13],
[61,35,23,14],
[61,35,23,16],
[61,35,23,17],
[61,35,23,19],
[61,35,23,20],
[61,35,23,21],
[61,35,23,22],
[61,35,24,0],
[61,35,24,1],
[61,35,24,2],
[61,35,24,3],
[61,35,24,5],
[61,35,24,6],
[61,35,24,7],
[61,35,24,8],
[61,35,24,10],
[61,35,24,11],
[61,35,24,12],
[61,35,24,13],
[61,35,24,14],
[61,35,24,17],
[61,35,24,19],
[61,35,24,20],
[61,35,24,21],
[61,35,24,22],
[61,35,24,23],
[61,35,25,0],
[61,35,25,1],
[61,35,25,2],
[61,35,25,3],
[61,35,25,5],
[61,35,25,6],
[61,35,25,8],
[61,35,25,10],
[61,35,25,11],
[61,35,25,12],
[61,35,25,13],
[61,35,25,14],
[61,35,25,16],
[61,35,25,17],
[61,35,25,19],
[61,35,25,20],
[61,35,25,21],
[61,35,25,22],
[61,35,25,23],
[61,35,25,24],
[61,35,27,0],
[61,35,27,1],
[61,35,27,2],
[61,35,27,3],
[61,35,27,5],
[61,35,27,6],
[61,35,27,7],
[61,35,27,8],
[61,35,27,11],
[61,35,27,14],
[61,35,27,16],
[61,35,27,17],
[61,35,27,19],
[61,35,27,21],
[61,35,27,23],
[61,35,27,24],
[61,35,27,25],
[61,35,28,0],
[61,35,28,1],
[61,35,28,2],
[61,35,28,3],
[61,35,28,5],
[61,35,28,6],
[61,35,28,8],
[61,35,28,12],
[61,35,28,13],
[61,35,28,14],
[61,35,28,16],
[61,35,28,17],
[61,35,28,19],
[61,35,28,20],
[61,35,28,21],
[61,35,28,22],
[61,35,28,23],
[61,35,28,24],
[61,35,29,0],
[61,35,29,1],
[61,35,29,2],
[61,35,29,3],
[61,35,29,5],
[61,35,29,6],
[61,35,29,7],
[61,35,29,10],
[61,35,29,13],
[61,35,29,14],
[61,35,29,16],
[61,35,29,17],
[61,35,29,19],
[61,35,29,20],
[61,35,29,21],
[61,35,29,22],
[61,35,29,23],
[61,35,29,24],
[61,35,30,0],
[61,35,30,1],
[61,35,30,2],
[61,35,30,3],
[61,35,30,5],
[61,35,30,8],
[61,35,30,10],
[61,35,30,11],
[61,35,30,12],
[61,35,30,13],
[61,35,30,14],
[61,35,30,16],
[61,35,30,17],
[61,35,30,19],
[61,35,30,20],
[61,35,30,21],
[61,35,30,22],
[61,35,30,24],
[61,35,31,0],
[61,35,31,1],
[61,35,31,2],
[61,35,31,3],
[61,35,31,6],
[61,35,31,7],
[61,35,31,8],
[61,35,31,10],
[61,35,31,11],
[61,35,31,12],
[61,35,31,13],
[61,35,31,14],
[61,35,31,16],
[61,35,31,17],
[61,35,31,19],
[61,35,31,20],
[61,35,31,21],
[61,35,31,22],
[61,35,32,0],
[61,35,32,1],
[61,35,32,2],
[61,35,32,5],
[61,35,32,6],
[61,35,32,7],
[61,35,32,8],
[61,35,32,10],
[61,35,32,13],
[61,35,32,14],
[61,35,32,16],
[61,35,32,17],
[61,35,32,19],
[61,35,32,20],
[61,35,32,21],
[61,35,33,3],
[61,35,33,5],
[61,35,33,6],
[61,35,33,7],
[61,35,33,8],
[61,35,33,10],
[61,35,33,13],
[61,35,33,14],
[61,35,33,16],
[61,35,33,17],
[61,35,33,19],
[61,35,33,20],
[61,35,34,0],
[61,35,34,1],
[61,35,34,2],
[61,35,34,3],
[61,35,34,5],
[61,35,34,6],
[61,35,34,8],
[61,35,34,10],
[61,35,34,11],
[61,35,34,12],
[61,35,34,13],
[61,35,34,14],
[61,35,34,16],
[61,35,34,17],
[61,35,34,19],
[61,36,3,0],
[61,36,3,1],
[61,36,3,2],
[61,36,5,0],
[61,36,5,1],
[61,36,5,2],
[61,36,6,0],
[61,36,6,1],
[61,36,6,2],
[61,36,7,0],
[61,36,7,1],
[61,36,7,2],
[61,36,7,3],
[61,36,7,5],
[61,36,7,6],
[61,36,8,0],
[61,36,8,1],
[61,36,8,2],
[61,36,8,7],
[61,36,9,0],
[61,36,9,1],
[61,36,9,2],
[61,36,9,3],
[61,36,9,5],
[61,36,9,6],
[61,36,9,7],
[61,36,9,8],
[61,36,10,0],
[61,36,10,1],
[61,36,10,2],
[61,36,10,3],
[61,36,10,5],
[61,36,10,6],
[61,36,10,7],
[61,36,10,8],
[61,36,10,9],
[61,36,11,0],
[61,36,11,1],
[61,36,11,2],
[61,36,11,3],
[61,36,11,5],
[61,36,11,6],
[61,36,11,7],
[61,36,11,8],
[61,36,11,9],
[61,36,11,10],
[61,36,12,0],
[61,36,12,1],
[61,36,12,2],
[61,36,12,3],
[61,36,12,5],
[61,36,12,6],
[61,36,12,7],
[61,36,12,8],
[61,36,12,9],
[61,36,12,10],
[61,36,15,0],
[61,36,15,1],
[61,36,15,2],
[61,36,15,3],
[61,36,15,5],
[61,36,15,6],
[61,36,15,7],
[61,36,15,8],
[61,36,15,10],
[61,36,15,11],
[61,36,15,12],
[61,36,16,0],
[61,36,16,1],
[61,36,16,2],
[61,36,16,3],
[61,36,16,5],
[61,36,16,6],
[61,36,16,7],
[61,36,16,8],
[61,36,16,9],
[61,36,16,10],
[61,36,16,11],
[61,36,16,12],
[61,36,16,15],
[61,36,17,0],
[61,36,17,1],
[61,36,17,2],
[61,36,17,3],
[61,36,17,5],
[61,36,17,6],
[61,36,17,7],
[61,36,17,8],
[61,36,17,9],
[61,36,17,10],
[61,36,17,11],
[61,36,17,12],
[61,36,17,15],
[61,36,17,16],
[61,36,18,0],
[61,36,18,1],
[61,36,18,2],
[61,36,18,3],
[61,36,18,5],
[61,36,18,6],
[61,36,18,7],
[61,36,18,8],
[61,36,18,10],
[61,36,18,11],
[61,36,18,12],
[61,36,18,16],
[61,36,18,17],
[61,36,20,0],
[61,36,20,1],
[61,36,20,2],
[61,36,20,3],
[61,36,20,5],
[61,36,20,6],
[61,36,20,7],
[61,36,20,8],
[61,36,20,9],
[61,36,20,11],
[61,36,20,12],
[61,36,20,15],
[61,36,20,16],
[61,36,20,17],
[61,36,20,18],
[61,36,21,0],
[61,36,21,1],
[61,36,21,2],
[61,36,21,7],
[61,36,21,9],
[61,36,21,10],
[61,36,21,11],
[61,36,21,12],
[61,36,21,15],
[61,36,21,16],
[61,36,21,17],
[61,36,21,18],
[61,36,21,20],
[61,36,22,0],
[61,36,22,1],
[61,36,22,2],
[61,36,22,3],
[61,36,22,5],
[61,36,22,6],
[61,36,22,7],
[61,36,22,8],
[61,36,22,9],
[61,36,22,11],
[61,36,22,12],
[61,36,22,15],
[61,36,22,16],
[61,36,22,17],
[61,36,22,21],
[61,36,23,0],
[61,36,23,1],
[61,36,23,2],
[61,36,23,3],
[61,36,23,5],
[61,36,23,6],
[61,36,23,7],
[61,36,23,8],
[61,36,23,9],
[61,36,23,10],
[61,36,23,11],
[61,36,23,12],
[61,36,23,15],
[61,36,23,18],
[61,36,23,20],
[61,36,23,21],
[61,36,23,22],
[61,36,24,0],
[61,36,24,1],
[61,36,24,2],
[61,36,24,3],
[61,36,24,5],
[61,36,24,6],
[61,36,24,7],
[61,36,24,8],
[61,36,24,9],
[61,36,24,10],
[61,36,24,11],
[61,36,24,12],
[61,36,24,15],
[61,36,24,18],
[61,36,24,20],
[61,36,24,21],
[61,36,24,22],
[61,36,24,23],
[61,36,25,0],
[61,36,25,1],
[61,36,25,2],
[61,36,25,3],
[61,36,25,5],
[61,36,25,6],
[61,36,25,8],
[61,36,25,9],
[61,36,25,10],
[61,36,25,11],
[61,36,25,12],
[61,36,25,15],
[61,36,25,16],
[61,36,25,17],
[61,36,25,18],
[61,36,25,20],
[61,36,25,21],
[61,36,25,22],
[61,36,25,23],
[61,36,25,24],
[61,36,26,0],
[61,36,26,1],
[61,36,26,2],
[61,36,26,3],
[61,36,26,5],
[61,36,26,6],
[61,36,26,7],
[61,36,26,8],
[61,36,26,10],
[61,36,26,11],
[61,36,26,16],
[61,36,26,17],
[61,36,26,20],
[61,36,26,21],
[61,36,26,22],
[61,36,26,23],
[61,36,26,24],
[61,36,26,25],
[61,36,27,0],
[61,36,27,1],
[61,36,27,2],
[61,36,27,3],
[61,36,27,5],
[61,36,27,6],
[61,36,27,7],
[61,36,27,8],
[61,36,27,9],
[61,36,27,12],
[61,36,27,15],
[61,36,27,16],
[61,36,27,17],
[61,36,27,18],
[61,36,27,21],
[61,36,27,23],
[61,36,27,24],
[61,36,27,25],
[61,36,28,0],
[61,36,28,1],
[61,36,28,2],
[61,36,28,3],
[61,36,28,5],
[61,36,28,6],
[61,36,28,10],
[61,36,28,11],
[61,36,28,12],
[61,36,28,15],
[61,36,28,16],
[61,36,28,17],
[61,36,28,18],
[61,36,28,20],
[61,36,28,21],
[61,36,28,22],
[61,36,28,23],
[61,36,28,24],
[61,36,29,0],
[61,36,29,1],
[61,36,29,2],
[61,36,29,3],
[61,36,29,5],
[61,36,29,8],
[61,36,29,9],
[61,36,29,10],
[61,36,29,15],
[61,36,29,16],
[61,36,29,17],
[61,36,29,18],
[61,36,29,20],
[61,36,29,21],
[61,36,29,22],
[61,36,29,23],
[61,36,29,24],
[61,36,30,0],
[61,36,30,1],
[61,36,30,2],
[61,36,30,3],
[61,36,30,6],
[61,36,30,7],
[61,36,30,8],
[61,36,30,9],
[61,36,30,10],
[61,36,30,11],
[61,36,30,12],
[61,36,30,15],
[61,36,30,16],
[61,36,30,17],
[61,36,30,18],
[61,36,30,20],
[61,36,30,21],
[61,36,30,22],
[61,36,31,0],
[61,36,31,1],
[61,36,31,2],
[61,36,31,5],
[61,36,31,6],
[61,36,31,7],
[61,36,31,8],
[61,36,31,9],
[61,36,31,10],
[61,36,31,11],
[61,36,31,12],
[61,36,31,15],
[61,36,31,16],
[61,36,31,17],
[61,36,31,18],
[61,36,31,20],
[61,36,31,21],
[61,36,32,3],
[61,36,32,5],
[61,36,32,6],
[61,36,32,7],
[61,36,32,8],
[61,36,32,9],
[61,36,32,10],
[61,36,32,15],
[61,36,32,16],
[61,36,32,17],
[61,36,32,18],
[61,36,32,20],
[61,36,33,0],
[61,36,33,1],
[61,36,33,2],
[61,36,33,3],
[61,36,33,5],
[61,36,33,6],
[61,36,33,7],
[61,36,33,8],
[61,36,33,9],
[61,36,33,10],
[61,36,33,15],
[61,36,33,16],
[61,36,33,17],
[61,36,33,18],
[61,36,34,0],
[61,36,34,1],
[61,36,34,2],
[61,36,34,3],
[61,36,34,5],
[61,36,34,6],
[61,36,34,8],
[61,36,34,9],
[61,36,34,10],
[61,36,34,11],
[61,36,34,12],
[61,36,34,15],
[61,36,34,16],
[61,36,34,17],
[61,36,34,18],
[61,36,35,0],
[61,36,35,1],
[61,36,35,2],
[61,36,35,3],
[61,36,35,5],
[61,36,35,6],
[61,36,35,7],
[61,36,35,8],
[61,36,35,10],
[61,36,35,11],
[61,36,35,12],
[61,36,35,16],
[61,36,35,17],
[61,38,3,0],
[61,38,3,1],
[61,38,3,2],
[61,38,5,0],
[61,38,5,1],
[61,38,5,2],
[61,38,6,0],
[61,38,6,1],
[61,38,6,2],
[61,38,8,0],
[61,38,8,1],
[61,38,8,2],
[61,38,9,0],
[61,38,9,1],
[61,38,9,2],
[61,38,9,3],
[61,38,9,5],
[61,38,9,6],
[61,38,9,8],
[61,38,10,0],
[61,38,10,1],
[61,38,10,2],
[61,38,10,3],
[61,38,10,5],
[61,38,10,6],
[61,38,10,8],
[61,38,10,9],
[61,38,11,0],
[61,38,11,1],
[61,38,11,2],
[61,38,11,3],
[61,38,11,5],
[61,38,11,6],
[61,38,11,8],
[61,38,11,9],
[61,38,11,10],
[61,38,12,0],
[61,38,12,1],
[61,38,12,2],
[61,38,12,3],
[61,38,12,5],
[61,38,12,6],
[61,38,12,8],
[61,38,12,9],
[61,38,12,10],
[61,38,13,0],
[61,38,13,1],
[61,38,13,2],
[61,38,13,3],
[61,38,13,5],
[61,38,13,6],
[61,38,13,8],
[61,38,13,9],
[61,38,13,10],
[61,38,13,11],
[61,38,13,12],
[61,38,14,0],
[61,38,14,1],
[61,38,14,2],
[61,38,14,3],
[61,38,14,5],
[61,38,14,6],
[61,38,14,8],
[61,38,14,9],
[61,38,14,10],
[61,38,14,11],
[61,38,14,12],
[61,38,15,0],
[61,38,15,1],
[61,38,15,2],
[61,38,15,3],
[61,38,15,5],
[61,38,15,6],
[61,38,15,8],
[61,38,15,10],
[61,38,15,11],
[61,38,15,12],
[61,38,15,13],
[61,38,15,14],
[61,38,16,0],
[61,38,16,1],
[61,38,16,2],
[61,38,16,3],
[61,38,16,5],
[61,38,16,6],
[61,38,16,8],
[61,38,16,9],
[61,38,16,10],
[61,38,16,11],
[61,38,16,12],
[61,38,16,13],
[61,38,16,14],
[61,38,16,15],
[61,38,17,0],
[61,38,17,1],
[61,38,17,2],
[61,38,17,3],
[61,38,17,5],
[61,38,17,6],
[61,38,17,8],
[61,38,17,9],
[61,38,17,10],
[61,38,17,11],
[61,38,17,12],
[61,38,17,13],
[61,38,17,14],
[61,38,17,15],
[61,38,17,16],
[61,38,18,0],
[61,38,18,1],
[61,38,18,2],
[61,38,18,3],
[61,38,18,5],
[61,38,18,6],
[61,38,18,8],
[61,38,18,10],
[61,38,18,11],
[61,38,18,12],
[61,38,18,13],
[61,38,18,14],
[61,38,18,16],
[61,38,18,17],
[61,38,19,0],
[61,38,19,1],
[61,38,19,2],
[61,38,19,3],
[61,38,19,5],
[61,38,19,6],
[61,38,19,8],
[61,38,19,9],
[61,38,19,10],
[61,38,19,11],
[61,38,19,12],
[61,38,19,15],
[61,38,19,16],
[61,38,19,17],
[61,38,19,18],
[61,38,20,0],
[61,38,20,1],
[61,38,20,2],
[61,38,20,3],
[61,38,20,5],
[61,38,20,6],
[61,38,20,8],
[61,38,20,9],
[61,38,20,11],
[61,38,20,12],
[61,38,20,13],
[61,38,20,14],
[61,38,20,15],
[61,38,20,16],
[61,38,20,17],
[61,38,20,19],
[61,38,21,0],
[61,38,21,1],
[61,38,21,2],
[61,38,21,9],
[61,38,21,10],
[61,38,21,11],
[61,38,21,12],
[61,38,21,13],
[61,38,21,14],
[61,38,21,15],
[61,38,21,18],
[61,38,21,19],
[61,38,21,20],
[61,38,22,0],
[61,38,22,1],
[61,38,22,2],
[61,38,22,3],
[61,38,22,5],
[61,38,22,6],
[61,38,22,8],
[61,38,22,9],
[61,38,22,11],
[61,38,22,12],
[61,38,22,13],
[61,38,22,14],
[61,38,22,16],
[61,38,22,17],
[61,38,22,18],
[61,38,22,19],
[61,38,22,21],
[61,38,23,0],
[61,38,23,1],
[61,38,23,2],
[61,38,23,3],
[61,38,23,5],
[61,38,23,6],
[61,38,23,8],
[61,38,23,9],
[61,38,23,10],
[61,38,23,11],
[61,38,23,12],
[61,38,23,13],
[61,38,23,15],
[61,38,23,16],
[61,38,23,17],
[61,38,23,18],
[61,38,23,19],
[61,38,23,20],
[61,38,23,21],
[61,38,23,22],
[61,38,24,0],
[61,38,24,1],
[61,38,24,2],
[61,38,24,3],
[61,38,24,5],
[61,38,24,6],
[61,38,24,8],
[61,38,24,9],
[61,38,24,10],
[61,38,24,11],
[61,38,24,12],
[61,38,24,13],
[61,38,24,15],
[61,38,24,17],
[61,38,24,18],
[61,38,24,19],
[61,38,24,20],
[61,38,24,21],
[61,38,24,22],
[61,38,24,23],
[61,38,26,0],
[61,38,26,1],
[61,38,26,2],
[61,38,26,3],
[61,38,26,5],
[61,38,26,6],
[61,38,26,10],
[61,38,26,11],
[61,38,26,12],
[61,38,26,13],
[61,38,26,14],
[61,38,26,16],
[61,38,26,17],
[61,38,26,19],
[61,38,26,20],
[61,38,26,21],
[61,38,26,22],
[61,38,26,23],
[61,38,26,24],
[61,38,27,0],
[61,38,27,1],
[61,38,27,2],
[61,38,27,3],
[61,38,27,5],
[61,38,27,8],
[61,38,27,9],
[61,38,27,11],
[61,38,27,12],
[61,38,27,13],
[61,38,27,14],
[61,38,27,15],
[61,38,27,16],
[61,38,27,17],
[61,38,27,18],
[61,38,27,19],
[61,38,27,21],
[61,38,27,23],
[61,38,27,24],
[61,38,29,0],
[61,38,29,1],
[61,38,29,2],
[61,38,29,5],
[61,38,29,6],
[61,38,29,8],
[61,38,29,9],
[61,38,29,10],
[61,38,29,13],
[61,38,29,14],
[61,38,29,15],
[61,38,29,16],
[61,38,29,17],
[61,38,29,18],
[61,38,29,19],
[61,38,29,20],
[61,38,29,21],
[61,38,30,3],
[61,38,30,5],
[61,38,30,6],
[61,38,30,8],
[61,38,30,9],
[61,38,30,10],
[61,38,30,11],
[61,38,30,12],
[61,38,30,13],
[61,38,30,14],
[61,38,30,15],
[61,38,30,16],
[61,38,30,17],
[61,38,30,18],
[61,38,30,19],
[61,38,30,20],
[61,38,31,0],
[61,38,31,1],
[61,38,31,2],
[61,38,31,3],
[61,38,31,5],
[61,38,31,6],
[61,38,31,8],
[61,38,31,9],
[61,38,31,10],
[61,38,31,11],
[61,38,31,12],
[61,38,31,13],
[61,38,31,14],
[61,38,31,15],
[61,38,31,16],
[61,38,31,17],
[61,38,31,18],
[61,38,31,19],
[61,38,32,0],
[61,38,32,1],
[61,38,32,2],
[61,38,32,3],
[61,38,32,5],
[61,38,32,6],
[61,38,32,8],
[61,38,32,9],
[61,38,32,10],
[61,38,32,13],
[61,38,32,14],
[61,38,32,15],
[61,38,32,16],
[61,38,32,17],
[61,38,32,18],
[61,38,33,0],
[61,38,33,1],
[61,38,33,2],
[61,38,33,3],
[61,38,33,5],
[61,38,33,6],
[61,38,33,8],
[61,38,33,9],
[61,38,33,10],
[61,38,33,13],
[61,38,33,14],
[61,38,33,15],
[61,38,33,16],
[61,38,33,17],
[61,38,35,0],
[61,38,35,1],
[61,38,35,2],
[61,38,35,3],
[61,38,35,5],
[61,38,35,6],
[61,38,35,8],
[61,38,35,10],
[61,38,35,11],
[61,38,35,12],
[61,38,35,13],
[61,38,35,14],
[61,38,36,0],
[61,38,36,1],
[61,38,36,2],
[61,38,36,3],
[61,38,36,5],
[61,38,36,6],
[61,38,36,8],
[61,38,36,9],
[61,38,36,10],
[61,38,36,11],
[61,38,36,12],
[61,39,7,0],
[61,39,7,1],
[61,39,7,2],
[61,39,9,0],
[61,39,9,1],
[61,39,9,2],
[61,39,9,7],
[61,39,10,0],
[61,39,10,1],
[61,39,10,2],
[61,39,10,7],
[61,39,10,9],
[61,39,11,0],
[61,39,11,1],
[61,39,11,2],
[61,39,11,7],
[61,39,11,9],
[61,39,11,10],
[61,39,12,0],
[61,39,12,1],
[61,39,12,2],
[61,39,12,7],
[61,39,12,9],
[61,39,12,10],
[61,39,13,0],
[61,39,13,1],
[61,39,13,2],
[61,39,13,7],
[61,39,13,9],
[61,39,13,10],
[61,39,13,11],
[61,39,13,12],
[61,39,14,0],
[61,39,14,1],
[61,39,14,2],
[61,39,14,7],
[61,39,14,9],
[61,39,14,10],
[61,39,14,11],
[61,39,14,12],
[61,39,15,0],
[61,39,15,1],
[61,39,15,2],
[61,39,15,7],
[61,39,15,10],
[61,39,15,11],
[61,39,15,12],
[61,39,15,13],
[61,39,15,14],
[61,39,16,0],
[61,39,16,1],
[61,39,16,2],
[61,39,16,7],
[61,39,16,9],
[61,39,16,10],
[61,39,16,11],
[61,39,16,12],
[61,39,16,13],
[61,39,16,14],
[61,39,16,15],
[61,39,17,0],
[61,39,17,1],
[61,39,17,2],
[61,39,17,7],
[61,39,17,9],
[61,39,17,10],
[61,39,17,11],
[61,39,17,12],
[61,39,17,13],
[61,39,17,14],
[61,39,17,15],
[61,39,17,16],
[61,39,18,0],
[61,39,18,1],
[61,39,18,2],
[61,39,18,7],
[61,39,18,10],
[61,39,18,11],
[61,39,18,12],
[61,39,18,13],
[61,39,18,14],
[61,39,18,16],
[61,39,18,17],
[61,39,19,0],
[61,39,19,1],
[61,39,19,2],
[61,39,19,7],
[61,39,19,9],
[61,39,19,10],
[61,39,19,11],
[61,39,19,12],
[61,39,19,15],
[61,39,19,16],
[61,39,19,17],
[61,39,20,0],
[61,39,20,1],
[61,39,20,2],
[61,39,20,7],
[61,39,20,9],
[61,39,20,11],
[61,39,20,12],
[61,39,20,13],
[61,39,20,14],
[61,39,20,15],
[61,39,20,18],
[61,39,20,19],
[61,39,22,0],
[61,39,22,1],
[61,39,22,2],
[61,39,22,7],
[61,39,22,9],
[61,39,22,11],
[61,39,22,12],
[61,39,22,13],
[61,39,22,15],
[61,39,22,16],
[61,39,22,17],
[61,39,22,18],
[61,39,22,19],
[61,39,23,0],
[61,39,23,1],
[61,39,23,2],
[61,39,23,7],
[61,39,23,9],
[61,39,23,10],
[61,39,23,11],
[61,39,23,14],
[61,39,23,15],
[61,39,23,16],
[61,39,23,17],
[61,39,23,18],
[61,39,23,19],
[61,39,23,20],
[61,39,23,22],
[61,39,24,0],
[61,39,24,1],
[61,39,24,2],
[61,39,24,7],
[61,39,24,9],
[61,39,24,10],
[61,39,24,11],
[61,39,24,14],
[61,39,24,15],
[61,39,24,17],
[61,39,24,18],
[61,39,24,19],
[61,39,24,20],
[61,39,24,22],
[61,39,24,23],
[61,39,25,0],
[61,39,25,1],
[61,39,25,2],
[61,39,25,10],
[61,39,25,11],
[61,39,25,12],
[61,39,25,13],
[61,39,25,14],
[61,39,25,15],
[61,39,25,16],
[61,39,25,17],
[61,39,25,18],
[61,39,25,19],
[61,39,25,20],
[61,39,25,22],
[61,39,25,23],
[61,39,25,24],
[61,39,26,0],
[61,39,26,1],
[61,39,26,2],
[61,39,26,10],
[61,39,26,11],
[61,39,26,12],
[61,39,26,13],
[61,39,26,14],
[61,39,26,16],
[61,39,26,17],
[61,39,26,19],
[61,39,26,20],
[61,39,26,22],
[61,39,26,23],
[61,39,26,24],
[61,39,27,0],
[61,39,27,1],
[61,39,27,2],
[61,39,27,7],
[61,39,27,9],
[61,39,27,11],
[61,39,27,12],
[61,39,27,13],
[61,39,27,14],
[61,39,27,15],
[61,39,27,16],
[61,39,27,17],
[61,39,27,18],
[61,39,27,19],
[61,39,28,0],
[61,39,28,1],
[61,39,28,2],
[61,39,28,9],
[61,39,28,10],
[61,39,28,11],
[61,39,28,12],
[61,39,28,13],
[61,39,28,14],
[61,39,28,15],
[61,39,28,16],
[61,39,28,17],
[61,39,28,18],
[61,39,28,19],
[61,39,28,20],
[61,39,29,7],
[61,39,29,9],
[61,39,29,10],
[61,39,29,13],
[61,39,29,14],
[61,39,29,15],
[61,39,29,16],
[61,39,29,17],
[61,39,29,18],
[61,39,29,19],
[61,39,29,20],
[61,39,30,0],
[61,39,30,1],
[61,39,30,2],
[61,39,30,7],
[61,39,30,9],
[61,39,30,10],
[61,39,30,11],
[61,39,30,12],
[61,39,30,13],
[61,39,30,14],
[61,39,30,15],
[61,39,30,16],
[61,39,30,17],
[61,39,30,18],
[61,39,30,19],
[61,39,31,0],
[61,39,31,1],
[61,39,31,2],
[61,39,31,7],
[61,39,31,9],
[61,39,31,10],
[61,39,31,11],
[61,39,31,12],
[61,39,31,13],
[61,39,31,14],
[61,39,31,15],
[61,39,31,16],
[61,39,31,17],
[61,39,31,18],
[61,39,32,0],
[61,39,32,1],
[61,39,32,2],
[61,39,32,7],
[61,39,32,9],
[61,39,32,10],
[61,39,32,13],
[61,39,32,14],
[61,39,32,15],
[61,39,32,16],
[61,39,32,17],
[61,39,33,0],
[61,39,33,1],
[61,39,33,2],
[61,39,33,7],
[61,39,33,9],
[61,39,33,10],
[61,39,33,13],
[61,39,33,14],
[61,39,33,15],
[61,39,34,0],
[61,39,34,1],
[61,39,34,2],
[61,39,34,9],
[61,39,34,10],
[61,39,34,11],
[61,39,34,12],
[61,39,34,13],
[61,39,34,14],
[61,39,35,0],
[61,39,35,1],
[61,39,35,2],
[61,39,35,7],
[61,39,35,10],
[61,39,35,11],
[61,39,35,12],
[61,39,35,13],
[61,39,36,0],
[61,39,36,1],
[61,39,36,2],
[61,39,36,7],
[61,39,36,9],
[61,39,36,10],
[61,39,36,11],
[61,39,38,0],
[61,39,38,1],
[61,39,38,2],
[61,40,3,0],
[61,40,3,1],
[61,40,3,2],
[61,40,5,0],
[61,40,5,1],
[61,40,5,2],
[61,40,6,0],
[61,40,6,1],
[61,40,6,2],
[61,40,7,0],
[61,40,7,1],
[61,40,7,2],
[61,40,7,3],
[61,40,7,5],
[61,40,7,6],
[61,40,8,0],
[61,40,8,1],
[61,40,8,2],
[61,40,8,7],
[61,40,10,0],
[61,40,10,1],
[61,40,10,2],
[61,40,10,3],
[61,40,10,5],
[61,40,10,6],
[61,40,10,7],
[61,40,10,8],
[61,40,11,0],
[61,40,11,1],
[61,40,11,2],
[61,40,11,3],
[61,40,11,5],
[61,40,11,6],
[61,40,11,7],
[61,40,11,8],
[61,40,11,10],
[61,40,12,0],
[61,40,12,1],
[61,40,12,2],
[61,40,12,3],
[61,40,12,5],
[61,40,12,6],
[61,40,12,7],
[61,40,12,8],
[61,40,12,10],
[61,40,13,0],
[61,40,13,1],
[61,40,13,2],
[61,40,13,3],
[61,40,13,5],
[61,40,13,6],
[61,40,13,7],
[61,40,13,8],
[61,40,13,10],
[61,40,13,11],
[61,40,13,12],
[61,40,14,0],
[61,40,14,1],
[61,40,14,2],
[61,40,14,3],
[61,40,14,5],
[61,40,14,6],
[61,40,14,7],
[61,40,14,8],
[61,40,14,10],
[61,40,14,11],
[61,40,14,12],
[61,40,16,0],
[61,40,16,1],
[61,40,16,2],
[61,40,16,3],
[61,40,16,5],
[61,40,16,6],
[61,40,16,7],
[61,40,16,8],
[61,40,16,10],
[61,40,16,11],
[61,40,16,12],
[61,40,16,13],
[61,40,16,14],
[61,40,17,0],
[61,40,17,1],
[61,40,17,2],
[61,40,17,3],
[61,40,17,5],
[61,40,17,6],
[61,40,17,7],
[61,40,17,8],
[61,40,17,10],
[61,40,17,11],
[61,40,17,12],
[61,40,17,13],
[61,40,17,14],
[61,40,17,16],
[61,40,19,0],
[61,40,19,1],
[61,40,19,2],
[61,40,19,3],
[61,40,19,5],
[61,40,19,6],
[61,40,19,7],
[61,40,19,8],
[61,40,19,10],
[61,40,19,11],
[61,40,19,12],
[61,40,20,0],
[61,40,20,1],
[61,40,20,2],
[61,40,20,3],
[61,40,20,5],
[61,40,20,6],
[61,40,20,7],
[61,40,20,8],
[61,40,20,11],
[61,40,20,12],
[61,40,20,13],
[61,40,20,14],
[61,40,20,16],
[61,40,20,17],
[61,40,20,19],
[61,40,21,0],
[61,40,21,1],
[61,40,21,2],
[61,40,21,7],
[61,40,21,10],
[61,40,21,11],
[61,40,21,12],
[61,40,21,13],
[61,40,21,16],
[61,40,21,17],
[61,40,21,19],
[61,40,21,20],
[61,40,22,0],
[61,40,22,1],
[61,40,22,2],
[61,40,22,3],
[61,40,22,5],
[61,40,22,6],
[61,40,22,7],
[61,40,22,8],
[61,40,22,11],
[61,40,22,14],
[61,40,22,16],
[61,40,22,17],
[61,40,22,19],
[61,40,22,21],
[61,40,23,0],
[61,40,23,1],
[61,40,23,2],
[61,40,23,3],
[61,40,23,5],
[61,40,23,6],
[61,40,23,7],
[61,40,23,8],
[61,40,23,12],
[61,40,23,13],
[61,40,23,14],
[61,40,23,16],
[61,40,23,17],
[61,40,23,19],
[61,40,23,20],
[61,40,23,21],
[61,40,23,22],
[61,40,24,0],
[61,40,24,1],
[61,40,24,2],
[61,40,24,3],
[61,40,24,5],
[61,40,24,6],
[61,40,24,7],
[61,40,24,8],
[61,40,24,12],
[61,40,24,13],
[61,40,24,14],
[61,40,24,17],
[61,40,24,19],
[61,40,24,20],
[61,40,24,21],
[61,40,24,22],
[61,40,24,23],
[61,40,25,0],
[61,40,25,1],
[61,40,25,2],
[61,40,25,3],
[61,40,25,5],
[61,40,25,8],
[61,40,25,10],
[61,40,25,11],
[61,40,25,12],
[61,40,25,13],
[61,40,25,14],
[61,40,25,16],
[61,40,25,17],
[61,40,25,19],
[61,40,25,20],
[61,40,25,21],
[61,40,25,22],
[61,40,25,23],
[61,40,25,24],
[61,40,27,0],
[61,40,27,1],
[61,40,27,2],
[61,40,27,5],
[61,40,27,6],
[61,40,27,7],
[61,40,27,8],
[61,40,27,11],
[61,40,27,12],
[61,40,27,13],
[61,40,27,14],
[61,40,27,16],
[61,40,27,17],
[61,40,27,19],
[61,40,27,21],
[61,40,28,3],
[61,40,28,5],
[61,40,28,6],
[61,40,28,8],
[61,40,28,10],
[61,40,28,11],
[61,40,28,12],
[61,40,28,13],
[61,40,28,14],
[61,40,28,16],
[61,40,28,17],
[61,40,28,19],
[61,40,28,20],
[61,40,29,0],
[61,40,29,1],
[61,40,29,2],
[61,40,29,3],
[61,40,29,5],
[61,40,29,6],
[61,40,29,7],
[61,40,29,8],
[61,40,29,10],
[61,40,29,13],
[61,40,29,14],
[61,40,29,16],
[61,40,29,17],
[61,40,29,19],
[61,40,30,0],
[61,40,30,1],
[61,40,30,2],
[61,40,30,3],
[61,40,30,5],
[61,40,30,6],
[61,40,30,7],
[61,40,30,8],
[61,40,30,10],
[61,40,30,11],
[61,40,30,12],
[61,40,30,13],
[61,40,30,14],
[61,40,30,16],
[61,40,30,17],
[61,40,31,0],
[61,40,31,1],
[61,40,31,2],
[61,40,31,3],
[61,40,31,5],
[61,40,31,6],
[61,40,31,7],
[61,40,31,8],
[61,40,31,10],
[61,40,31,11],
[61,40,31,12],
[61,40,31,13],
[61,40,31,14],
[61,40,31,16],
[61,40,31,17],
[61,40,32,0],
[61,40,32,1],
[61,40,32,2],
[61,40,32,3],
[61,40,32,5],
[61,40,32,6],
[61,40,32,7],
[61,40,32,8],
[61,40,32,10],
[61,40,32,13],
[61,40,32,14],
[61,40,33,0],
[61,40,33,1],
[61,40,33,2],
[61,40,33,3],
[61,40,33,5],
[61,40,33,6],
[61,40,33,7],
[61,40,33,8],
[61,40,33,10],
[61,40,33,13],
[61,40,33,14],
[61,40,34,0],
[61,40,34,1],
[61,40,34,2],
[61,40,34,3],
[61,40,34,5],
[61,40,34,6],
[61,40,34,8],
[61,40,34,10],
[61,40,34,11],
[61,40,34,12],
[61,40,34,13],
[61,40,36,0],
[61,40,36,1],
[61,40,36,2],
[61,40,36,3],
[61,40,36,5],
[61,40,36,6],
[61,40,36,7],
[61,40,36,8],
[61,40,38,0],
[61,40,38,1],
[61,40,38,2],
[61,40,38,3],
[61,40,38,5],
[61,40,39,0],
[61,40,39,1],
[61,40,39,2],
[61,41,3,0],
[61,41,3,1],
[61,41,3,2],
[61,41,5,0],
[61,41,5,1],
[61,41,5,2],
[61,41,6,0],
[61,41,6,1],
[61,41,6,2],
[61,41,7,0],
[61,41,7,1],
[61,41,7,2],
[61,41,7,3],
[61,41,7,5],
[61,41,7,6],
[61,41,8,0],
[61,41,8,1],
[61,41,8,2],
[61,41,8,7],
[61,41,10,0],
[61,41,10,1],
[61,41,10,2],
[61,41,10,3],
[61,41,10,5],
[61,41,10,6],
[61,41,10,7],
[61,41,10,8],
[61,41,11,0],
[61,41,11,1],
[61,41,11,2],
[61,41,11,3],
[61,41,11,5],
[61,41,11,6],
[61,41,11,7],
[61,41,11,8],
[61,41,11,10],
[61,41,12,0],
[61,41,12,1],
[61,41,12,2],
[61,41,12,3],
[61,41,12,5],
[61,41,12,6],
[61,41,12,7],
[61,41,12,8],
[61,41,12,10],
[61,41,13,0],
[61,41,13,1],
[61,41,13,2],
[61,41,13,3],
[61,41,13,5],
[61,41,13,6],
[61,41,13,7],
[61,41,13,8],
[61,41,13,10],
[61,41,13,11],
[61,41,13,12],
[61,41,14,0],
[61,41,14,1],
[61,41,14,2],
[61,41,14,3],
[61,41,14,5],
[61,41,14,6],
[61,41,14,7],
[61,41,14,8],
[61,41,14,10],
[61,41,14,11],
[61,41,14,12],
[61,41,16,0],
[61,41,16,1],
[61,41,16,2],
[61,41,16,3],
[61,41,16,5],
[61,41,16,6],
[61,41,16,7],
[61,41,16,8],
[61,41,16,10],
[61,41,16,11],
[61,41,16,12],
[61,41,16,13],
[61,41,16,14],
[61,41,17,0],
[61,41,17,1],
[61,41,17,2],
[61,41,17,3],
[61,41,17,5],
[61,41,17,6],
[61,41,17,7],
[61,41,17,8],
[61,41,17,10],
[61,41,17,11],
[61,41,17,12],
[61,41,17,13],
[61,41,17,14],
[61,41,17,16],
[61,41,19,0],
[61,41,19,1],
[61,41,19,2],
[61,41,19,3],
[61,41,19,5],
[61,41,19,6],
[61,41,19,7],
[61,41,19,8],
[61,41,19,10],
[61,41,19,11],
[61,41,19,12],
[61,41,20,0],
[61,41,20,1],
[61,41,20,2],
[61,41,20,3],
[61,41,20,5],
[61,41,20,6],
[61,41,20,7],
[61,41,20,8],
[61,41,20,11],
[61,41,20,12],
[61,41,20,13],
[61,41,20,14],
[61,41,20,16],
[61,41,20,17],
[61,41,20,19],
[61,41,21,0],
[61,41,21,1],
[61,41,21,2],
[61,41,21,7],
[61,41,21,10],
[61,41,21,11],
[61,41,21,12],
[61,41,21,13],
[61,41,21,16],
[61,41,21,17],
[61,41,21,19],
[61,41,21,20],
[61,41,22,0],
[61,41,22,1],
[61,41,22,2],
[61,41,22,3],
[61,41,22,5],
[61,41,22,6],
[61,41,22,7],
[61,41,22,8],
[61,41,22,11],
[61,41,22,14],
[61,41,22,16],
[61,41,22,17],
[61,41,22,19],
[61,41,22,21],
[61,41,23,0],
[61,41,23,1],
[61,41,23,2],
[61,41,23,3],
[61,41,23,5],
[61,41,23,6],
[61,41,23,7],
[61,41,23,8],
[61,41,23,12],
[61,41,23,13],
[61,41,23,14],
[61,41,23,16],
[61,41,23,17],
[61,41,23,19],
[61,41,23,20],
[61,41,23,21],
[61,41,23,22],
[61,41,24,0],
[61,41,24,1],
[61,41,24,2],
[61,41,24,3],
[61,41,24,5],
[61,41,24,6],
[61,41,24,7],
[61,41,24,8],
[61,41,24,12],
[61,41,24,13],
[61,41,24,14],
[61,41,24,17],
[61,41,24,19],
[61,41,24,20],
[61,41,24,21],
[61,41,24,22],
[61,41,24,23],
[61,41,25,0],
[61,41,25,1],
[61,41,25,2],
[61,41,25,3],
[61,41,25,5],
[61,41,25,8],
[61,41,25,10],
[61,41,25,11],
[61,41,25,12],
[61,41,25,13],
[61,41,25,14],
[61,41,25,16],
[61,41,25,17],
[61,41,25,19],
[61,41,25,20],
[61,41,25,21],
[61,41,25,22],
[61,41,25,23],
[61,41,25,24],
[61,41,27,0],
[61,41,27,1],
[61,41,27,2],
[61,41,27,5],
[61,41,27,6],
[61,41,27,7],
[61,41,27,8],
[61,41,27,11],
[61,41,27,12],
[61,41,27,13],
[61,41,27,14],
[61,41,27,16],
[61,41,27,17],
[61,41,27,19],
[61,41,27,21],
[61,41,28,3],
[61,41,28,5],
[61,41,28,6],
[61,41,28,8],
[61,41,28,10],
[61,41,28,11],
[61,41,28,12],
[61,41,28,13],
[61,41,28,14],
[61,41,28,16],
[61,41,28,17],
[61,41,28,19],
[61,41,28,20],
[61,41,29,0],
[61,41,29,1],
[61,41,29,2],
[61,41,29,3],
[61,41,29,5],
[61,41,29,6],
[61,41,29,7],
[61,41,29,8],
[61,41,29,10],
[61,41,29,13],
[61,41,29,14],
[61,41,29,16],
[61,41,29,17],
[61,41,29,19],
[61,41,30,0],
[61,41,30,1],
[61,41,30,2],
[61,41,30,3],
[61,41,30,5],
[61,41,30,6],
[61,41,30,7],
[61,41,30,8],
[61,41,30,10],
[61,41,30,11],
[61,41,30,12],
[61,41,30,13],
[61,41,30,14],
[61,41,30,16],
[61,41,30,17],
[61,41,31,0],
[61,41,31,1],
[61,41,31,2],
[61,41,31,3],
[61,41,31,5],
[61,41,31,6],
[61,41,31,7],
[61,41,31,8],
[61,41,31,10],
[61,41,31,11],
[61,41,31,12],
[61,41,31,13],
[61,41,31,14],
[61,41,31,16],
[61,41,31,17],
[61,41,32,0],
[61,41,32,1],
[61,41,32,2],
[61,41,32,3],
[61,41,32,5],
[61,41,32,6],
[61,41,32,7],
[61,41,32,8],
[61,41,32,10],
[61,41,32,13],
[61,41,32,14],
[61,41,33,0],
[61,41,33,1],
[61,41,33,2],
[61,41,33,3],
[61,41,33,5],
[61,41,33,6],
[61,41,33,7],
[61,41,33,8],
[61,41,33,10],
[61,41,33,13],
[61,41,33,14],
[61,41,34,0],
[61,41,34,1],
[61,41,34,2],
[61,41,34,3],
[61,41,34,5],
[61,41,34,6],
[61,41,34,8],
[61,41,34,10],
[61,41,34,11],
[61,41,34,12],
[61,41,34,13],
[61,41,36,0],
[61,41,36,1],
[61,41,36,2],
[61,41,36,3],
[61,41,36,5],
[61,41,36,6],
[61,41,36,7],
[61,41,36,8],
[61,41,38,0],
[61,41,38,1],
[61,41,38,2],
[61,41,38,3],
[61,41,38,5],
[61,41,39,0],
[61,41,39,1],
[61,41,39,2],
[61,42,3,0],
[61,42,3,1],
[61,42,3,2],
[61,42,5,0],
[61,42,5,1],
[61,42,5,2],
[61,42,6,0],
[61,42,6,1],
[61,42,6,2],
[61,42,7,0],
[61,42,7,1],
[61,42,7,2],
[61,42,7,3],
[61,42,7,5],
[61,42,7,6],
[61,42,8,0],
[61,42,8,1],
[61,42,8,2],
[61,42,8,7],
[61,42,9,0],
[61,42,9,1],
[61,42,9,2],
[61,42,9,3],
[61,42,9,5],
[61,42,9,6],
[61,42,9,7],
[61,42,9,8],
[61,42,11,0],
[61,42,11,1],
[61,42,11,2],
[61,42,11,3],
[61,42,11,5],
[61,42,11,6],
[61,42,11,7],
[61,42,11,8],
[61,42,11,9],
[61,42,12,0],
[61,42,12,1],
[61,42,12,2],
[61,42,12,3],
[61,42,12,5],
[61,42,12,6],
[61,42,12,7],
[61,42,12,8],
[61,42,12,9],
[61,42,13,0],
[61,42,13,1],
[61,42,13,2],
[61,42,13,3],
[61,42,13,5],
[61,42,13,6],
[61,42,13,7],
[61,42,13,8],
[61,42,13,9],
[61,42,13,11],
[61,42,13,12],
[61,42,14,0],
[61,42,14,1],
[61,42,14,2],
[61,42,14,3],
[61,42,14,5],
[61,42,14,6],
[61,42,14,7],
[61,42,14,8],
[61,42,14,9],
[61,42,14,11],
[61,42,14,12],
[61,42,15,0],
[61,42,15,1],
[61,42,15,2],
[61,42,15,3],
[61,42,15,5],
[61,42,15,6],
[61,42,15,7],
[61,42,15,8],
[61,42,15,11],
[61,42,15,12],
[61,42,15,13],
[61,42,15,14],
[61,42,16,0],
[61,42,16,1],
[61,42,16,2],
[61,42,16,3],
[61,42,16,5],
[61,42,16,6],
[61,42,16,7],
[61,42,16,8],
[61,42,16,9],
[61,42,16,11],
[61,42,16,12],
[61,42,16,13],
[61,42,16,14],
[61,42,16,15],
[61,42,17,0],
[61,42,17,1],
[61,42,17,2],
[61,42,17,3],
[61,42,17,5],
[61,42,17,6],
[61,42,17,7],
[61,42,17,8],
[61,42,17,9],
[61,42,17,11],
[61,42,17,12],
[61,42,17,13],
[61,42,17,14],
[61,42,17,15],
[61,42,17,16],
[61,42,18,0],
[61,42,18,1],
[61,42,18,2],
[61,42,18,3],
[61,42,18,5],
[61,42,18,6],
[61,42,18,7],
[61,42,18,8],
[61,42,18,11],
[61,42,18,12],
[61,42,18,13],
[61,42,18,14],
[61,42,19,0],
[61,42,19,1],
[61,42,19,2],
[61,42,19,3],
[61,42,19,5],
[61,42,19,6],
[61,42,19,7],
[61,42,19,8],
[61,42,19,9],
[61,42,19,11],
[61,42,19,12],
[61,42,19,16],
[61,42,19,17],
[61,42,19,18],
[61,42,21,0],
[61,42,21,1],
[61,42,21,2],
[61,42,21,7],
[61,42,21,9],
[61,42,21,11],
[61,42,21,14],
[61,42,21,15],
[61,42,21,16],
[61,42,21,17],
[61,42,21,18],
[61,42,21,19],
[61,42,23,0],
[61,42,23,1],
[61,42,23,2],
[61,42,23,3],
[61,42,23,5],
[61,42,23,6],
[61,42,23,7],
[61,42,23,11],
[61,42,23,12],
[61,42,23,13],
[61,42,23,14],
[61,42,23,15],
[61,42,23,16],
[61,42,23,17],
[61,42,23,18],
[61,42,23,19],
[61,42,23,21],
[61,42,24,0],
[61,42,24,1],
[61,42,24,2],
[61,42,24,3],
[61,42,24,5],
[61,42,24,6],
[61,42,24,7],
[61,42,24,11],
[61,42,24,12],
[61,42,24,13],
[61,42,24,14],
[61,42,24,15],
[61,42,24,17],
[61,42,24,18],
[61,42,24,19],
[61,42,24,21],
[61,42,24,23],
[61,42,25,0],
[61,42,25,1],
[61,42,25,2],
[61,42,25,3],
[61,42,25,6],
[61,42,25,8],
[61,42,25,9],
[61,42,25,11],
[61,42,25,12],
[61,42,25,13],
[61,42,25,14],
[61,42,25,15],
[61,42,25,16],
[61,42,25,17],
[61,42,25,18],
[61,42,25,19],
[61,42,25,21],
[61,42,26,0],
[61,42,26,1],
[61,42,26,2],
[61,42,26,5],
[61,42,26,6],
[61,42,26,7],
[61,42,26,8],
[61,42,26,11],
[61,42,26,12],
[61,42,26,13],
[61,42,26,14],
[61,42,26,16],
[61,42,26,17],
[61,42,26,19],
[61,42,26,21],
[61,42,28,0],
[61,42,28,1],
[61,42,28,2],
[61,42,28,3],
[61,42,28,5],
[61,42,28,6],
[61,42,28,8],
[61,42,28,9],
[61,42,28,11],
[61,42,28,12],
[61,42,28,13],
[61,42,28,14],
[61,42,28,15],
[61,42,28,16],
[61,42,28,17],
[61,42,28,18],
[61,42,28,19],
[61,42,29,0],
[61,42,29,1],
[61,42,29,2],
[61,42,29,3],
[61,42,29,5],
[61,42,29,6],
[61,42,29,7],
[61,42,29,8],
[61,42,29,9],
[61,42,29,13],
[61,42,29,14],
[61,42,29,15],
[61,42,29,16],
[61,42,29,17],
[61,42,29,18],
[61,42,30,0],
[61,42,30,1],
[61,42,30,2],
[61,42,30,3],
[61,42,30,5],
[61,42,30,6],
[61,42,30,7],
[61,42,30,8],
[61,42,30,9],
[61,42,30,11],
[61,42,30,12],
[61,42,30,13],
[61,42,30,14],
[61,42,30,15],
[61,42,30,16],
[61,42,30,17],
[61,42,31,0],
[61,42,31,1],
[61,42,31,2],
[61,42,31,3],
[61,42,31,5],
[61,42,31,6],
[61,42,31,7],
[61,42,31,8],
[61,42,31,9],
[61,42,31,11],
[61,42,31,12],
[61,42,31,13],
[61,42,31,14],
[61,42,31,15],
[61,42,32,0],
[61,42,32,1],
[61,42,32,2],
[61,42,32,3],
[61,42,32,5],
[61,42,32,6],
[61,42,32,7],
[61,42,32,8],
[61,42,32,9],
[61,42,32,13],
[61,42,32,14],
[61,42,33,0],
[61,42,33,1],
[61,42,33,2],
[61,42,33,3],
[61,42,33,5],
[61,42,33,6],
[61,42,33,7],
[61,42,33,8],
[61,42,33,9],
[61,42,33,13],
[61,42,34,0],
[61,42,34,1],
[61,42,34,2],
[61,42,34,3],
[61,42,34,5],
[61,42,34,6],
[61,42,34,8],
[61,42,34,9],
[61,42,34,11],
[61,42,35,0],
[61,42,35,1],
[61,42,35,2],
[61,42,35,3],
[61,42,35,5],
[61,42,35,6],
[61,42,35,7],
[61,42,35,8],
[61,42,36,0],
[61,42,36,1],
[61,42,36,2],
[61,42,36,3],
[61,42,36,5],
[61,42,36,6],
[61,42,36,7],
[61,42,38,0],
[61,42,38,1],
[61,42,38,2],
[61,42,38,3],
[61,42,39,0],
[61,42,39,1],
[61,42,39,2],
[61,43,3,0],
[61,43,3,1],
[61,43,3,2],
[61,43,5,0],
[61,43,5,1],
[61,43,5,2],
[61,43,6,0],
[61,43,6,1],
[61,43,6,2],
[61,43,8,0],
[61,43,8,1],
[61,43,8,2],
[61,43,9,0],
[61,43,9,1],
[61,43,9,2],
[61,43,9,3],
[61,43,9,5],
[61,43,9,6],
[61,43,9,8],
[61,43,10,0],
[61,43,10,1],
[61,43,10,2],
[61,43,10,3],
[61,43,10,5],
[61,43,10,6],
[61,43,10,8],
[61,43,10,9],
[61,43,11,0],
[61,43,11,1],
[61,43,11,2],
[61,43,11,3],
[61,43,11,5],
[61,43,11,6],
[61,43,11,8],
[61,43,11,9],
[61,43,11,10],
[61,43,12,0],
[61,43,12,1],
[61,43,12,2],
[61,43,12,3],
[61,43,12,5],
[61,43,12,6],
[61,43,12,8],
[61,43,12,9],
[61,43,12,10],
[61,43,13,0],
[61,43,13,1],
[61,43,13,2],
[61,43,13,3],
[61,43,13,5],
[61,43,13,6],
[61,43,13,8],
[61,43,13,9],
[61,43,13,10],
[61,43,13,11],
[61,43,13,12],
[61,43,14,0],
[61,43,14,1],
[61,43,14,2],
[61,43,14,3],
[61,43,14,5],
[61,43,14,6],
[61,43,14,8],
[61,43,14,9],
[61,43,14,10],
[61,43,14,11],
[61,43,14,12],
[61,43,15,0],
[61,43,15,1],
[61,43,15,2],
[61,43,15,3],
[61,43,15,5],
[61,43,15,6],
[61,43,15,8],
[61,43,15,10],
[61,43,15,11],
[61,43,15,12],
[61,43,15,13],
[61,43,15,14],
[61,43,16,0],
[61,43,16,1],
[61,43,16,2],
[61,43,16,3],
[61,43,16,5],
[61,43,16,6],
[61,43,16,8],
[61,43,16,9],
[61,43,16,10],
[61,43,16,11],
[61,43,16,12],
[61,43,16,13],
[61,43,16,14],
[61,43,16,15],
[61,43,17,0],
[61,43,17,1],
[61,43,17,2],
[61,43,17,3],
[61,43,17,5],
[61,43,17,6],
[61,43,17,8],
[61,43,17,9],
[61,43,17,10],
[61,43,17,11],
[61,43,17,12],
[61,43,17,13],
[61,43,17,14],
[61,43,17,15],
[61,43,18,0],
[61,43,18,1],
[61,43,18,2],
[61,43,18,3],
[61,43,18,5],
[61,43,18,6],
[61,43,18,8],
[61,43,18,10],
[61,43,18,11],
[61,43,18,12],
[61,43,18,13],
[61,43,18,14],
[61,43,18,16],
[61,43,18,17],
[61,43,19,0],
[61,43,19,1],
[61,43,19,2],
[61,43,19,3],
[61,43,19,5],
[61,43,19,6],
[61,43,19,8],
[61,43,19,9],
[61,43,19,10],
[61,43,19,11],
[61,43,19,12],
[61,43,19,15],
[61,43,19,16],
[61,43,19,17],
[61,43,19,18],
[61,43,20,0],
[61,43,20,1],
[61,43,20,2],
[61,43,20,3],
[61,43,20,5],
[61,43,20,6],
[61,43,20,8],
[61,43,20,9],
[61,43,20,11],
[61,43,20,14],
[61,43,20,15],
[61,43,20,16],
[61,43,20,17],
[61,43,20,18],
[61,43,20,19],
[61,43,21,0],
[61,43,21,1],
[61,43,21,2],
[61,43,21,9],
[61,43,21,12],
[61,43,21,13],
[61,43,21,14],
[61,43,21,15],
[61,43,21,16],
[61,43,21,17],
[61,43,21,18],
[61,43,21,19],
[61,43,21,20],
[61,43,22,0],
[61,43,22,1],
[61,43,22,2],
[61,43,22,3],
[61,43,22,5],
[61,43,22,6],
[61,43,22,11],
[61,43,22,12],
[61,43,22,13],
[61,43,22,14],
[61,43,22,15],
[61,43,22,16],
[61,43,22,17],
[61,43,22,18],
[61,43,22,19],
[61,43,22,21],
[61,43,23,0],
[61,43,23,1],
[61,43,23,2],
[61,43,23,3],
[61,43,23,5],
[61,43,23,8],
[61,43,23,9],
[61,43,23,10],
[61,43,23,11],
[61,43,23,12],
[61,43,23,13],
[61,43,23,14],
[61,43,23,15],
[61,43,23,16],
[61,43,23,17],
[61,43,23,18],
[61,43,23,19],
[61,43,23,20],
[61,43,23,21],
[61,43,23,22],
[61,43,24,0],
[61,43,24,1],
[61,43,24,2],
[61,43,24,3],
[61,43,24,5],
[61,43,24,8],
[61,43,24,9],
[61,43,24,10],
[61,43,24,11],
[61,43,24,12],
[61,43,24,13],
[61,43,24,14],
[61,43,24,15],
[61,43,24,17],
[61,43,24,18],
[61,43,24,19],
[61,43,24,20],
[61,43,24,21],
[61,43,24,22],
[61,43,24,23],
[61,43,26,3],
[61,43,26,5],
[61,43,26,6],
[61,43,26,8],
[61,43,26,10],
[61,43,26,11],
[61,43,26,12],
[61,43,26,13],
[61,43,26,14],
[61,43,26,16],
[61,43,26,17],
[61,43,26,19],
[61,43,26,20],
[61,43,27,0],
[61,43,27,1],
[61,43,27,2],
[61,43,27,3],
[61,43,27,5],
[61,43,27,6],
[61,43,27,8],
[61,43,27,9],
[61,43,27,11],
[61,43,27,12],
[61,43,27,13],
[61,43,27,14],
[61,43,27,15],
[61,43,27,16],
[61,43,27,17],
[61,43,27,18],
[61,43,27,19],
[61,43,29,0],
[61,43,29,1],
[61,43,29,2],
[61,43,29,3],
[61,43,29,5],
[61,43,29,6],
[61,43,29,8],
[61,43,29,9],
[61,43,29,10],
[61,43,29,13],
[61,43,29,14],
[61,43,29,15],
[61,43,29,16],
[61,43,29,17],
[61,43,30,0],
[61,43,30,1],
[61,43,30,2],
[61,43,30,3],
[61,43,30,5],
[61,43,30,6],
[61,43,30,8],
[61,43,30,9],
[61,43,30,10],
[61,43,30,11],
[61,43,30,12],
[61,43,30,13],
[61,43,30,14],
[61,43,30,15],
[61,43,31,0],
[61,43,31,1],
[61,43,31,2],
[61,43,31,3],
[61,43,31,5],
[61,43,31,6],
[61,43,31,8],
[61,43,31,9],
[61,43,31,10],
[61,43,31,11],
[61,43,31,12],
[61,43,31,13],
[61,43,31,14],
[61,43,32,0],
[61,43,32,1],
[61,43,32,2],
[61,43,32,3],
[61,43,32,5],
[61,43,32,6],
[61,43,32,8],
[61,43,32,9],
[61,43,32,10],
[61,43,32,13],
[61,43,33,0],
[61,43,33,1],
[61,43,33,2],
[61,43,33,3],
[61,43,33,5],
[61,43,33,6],
[61,43,33,8],
[61,43,33,9],
[61,43,33,10],
[61,43,35,0],
[61,43,35,1],
[61,43,35,2],
[61,43,35,3],
[61,43,35,5],
[61,43,35,6],
[61,43,36,0],
[61,43,36,1],
[61,43,36,2],
[61,43,36,3],
[61,43,36,5],
[61,44,3,0],
[61,44,3,1],
[61,44,3,2],
[61,44,5,0],
[61,44,5,1],
[61,44,5,2],
[61,44,6,0],
[61,44,6,1],
[61,44,6,2],
[61,44,7,0],
[61,44,7,1],
[61,44,7,2],
[61,44,7,3],
[61,44,7,5],
[61,44,7,6],
[61,44,8,0],
[61,44,8,1],
[61,44,8,2],
[61,44,8,7],
[61,44,9,0],
[61,44,9,1],
[61,44,9,2],
[61,44,9,3],
[61,44,9,5],
[61,44,9,6],
[61,44,9,7],
[61,44,9,8],
[61,44,10,0],
[61,44,10,1],
[61,44,10,2],
[61,44,10,3],
[61,44,10,5],
[61,44,10,6],
[61,44,10,7],
[61,44,10,8],
[61,44,10,9],
[61,44,11,0],
[61,44,11,1],
[61,44,11,2],
[61,44,11,3],
[61,44,11,5],
[61,44,11,6],
[61,44,11,7],
[61,44,11,8],
[61,44,11,9],
[61,44,11,10],
[61,44,12,0],
[61,44,12,1],
[61,44,12,2],
[61,44,12,3],
[61,44,12,5],
[61,44,12,6],
[61,44,12,7],
[61,44,12,8],
[61,44,12,9],
[61,44,12,10],
[61,44,13,0],
[61,44,13,1],
[61,44,13,2],
[61,44,13,3],
[61,44,13,5],
[61,44,13,6],
[61,44,13,7],
[61,44,13,8],
[61,44,13,9],
[61,44,13,10],
[61,44,13,11],
[61,44,13,12],
[61,44,14,0],
[61,44,14,1],
[61,44,14,2],
[61,44,14,3],
[61,44,14,5],
[61,44,14,6],
[61,44,14,7],
[61,44,14,8],
[61,44,14,9],
[61,44,14,10],
[61,44,14,11],
[61,44,14,12],
[61,44,15,0],
[61,44,15,1],
[61,44,15,2],
[61,44,15,3],
[61,44,15,5],
[61,44,15,6],
[61,44,15,7],
[61,44,15,8],
[61,44,15,10],
[61,44,15,11],
[61,44,15,12],
[61,44,15,13],
[61,44,15,14],
[61,44,16,0],
[61,44,16,1],
[61,44,16,2],
[61,44,16,3],
[61,44,16,5],
[61,44,16,6],
[61,44,16,7],
[61,44,16,8],
[61,44,16,9],
[61,44,16,10],
[61,44,16,11],
[61,44,16,12],
[61,44,16,13],
[61,44,16,14],
[61,44,18,0],
[61,44,18,1],
[61,44,18,2],
[61,44,18,3],
[61,44,18,5],
[61,44,18,6],
[61,44,18,7],
[61,44,18,8],
[61,44,18,10],
[61,44,18,11],
[61,44,18,12],
[61,44,18,13],
[61,44,18,16],
[61,44,19,0],
[61,44,19,1],
[61,44,19,2],
[61,44,19,3],
[61,44,19,5],
[61,44,19,6],
[61,44,19,7],
[61,44,19,8],
[61,44,19,9],
[61,44,19,10],
[61,44,19,11],
[61,44,19,15],
[61,44,19,16],
[61,44,19,18],
[61,44,20,0],
[61,44,20,1],
[61,44,20,2],
[61,44,20,3],
[61,44,20,5],
[61,44,20,6],
[61,44,20,7],
[61,44,20,8],
[61,44,20,9],
[61,44,20,12],
[61,44,20,13],
[61,44,20,14],
[61,44,20,15],
[61,44,20,16],
[61,44,20,18],
[61,44,20,19],
[61,44,21,0],
[61,44,21,1],
[61,44,21,2],
[61,44,21,7],
[61,44,21,10],
[61,44,21,11],
[61,44,21,12],
[61,44,21,13],
[61,44,21,14],
[61,44,21,15],
[61,44,21,16],
[61,44,21,18],
[61,44,21,19],
[61,44,21,20],
[61,44,22,0],
[61,44,22,1],
[61,44,22,2],
[61,44,22,3],
[61,44,22,5],
[61,44,22,8],
[61,44,22,9],
[61,44,22,11],
[61,44,22,12],
[61,44,22,13],
[61,44,22,14],
[61,44,22,15],
[61,44,22,16],
[61,44,22,18],
[61,44,22,19],
[61,44,22,21],
[61,44,23,0],
[61,44,23,1],
[61,44,23,2],
[61,44,23,3],
[61,44,23,6],
[61,44,23,7],
[61,44,23,8],
[61,44,23,9],
[61,44,23,10],
[61,44,23,11],
[61,44,23,12],
[61,44,23,13],
[61,44,23,14],
[61,44,23,15],
[61,44,23,16],
[61,44,23,18],
[61,44,23,19],
[61,44,23,20],
[61,44,23,21],
[61,44,23,22],
[61,44,24,0],
[61,44,24,1],
[61,44,24,2],
[61,44,24,3],
[61,44,24,6],
[61,44,24,7],
[61,44,24,8],
[61,44,24,9],
[61,44,24,10],
[61,44,24,11],
[61,44,24,12],
[61,44,24,13],
[61,44,24,14],
[61,44,24,15],
[61,44,24,18],
[61,44,24,19],
[61,44,24,20],
[61,44,24,21],
[61,44,24,22],
[61,44,25,3],
[61,44,25,5],
[61,44,25,6],
[61,44,25,8],
[61,44,25,9],
[61,44,25,10],
[61,44,25,11],
[61,44,25,12],
[61,44,25,13],
[61,44,25,14],
[61,44,25,15],
[61,44,25,16],
[61,44,25,18],
[61,44,25,19],
[61,44,25,20],
[61,44,26,0],
[61,44,26,1],
[61,44,26,2],
[61,44,26,3],
[61,44,26,5],
[61,44,26,6],
[61,44,26,7],
[61,44,26,8],
[61,44,26,10],
[61,44,26,11],
[61,44,26,12],
[61,44,26,13],
[61,44,26,14],
[61,44,26,16],
[61,44,26,19],
[61,44,27,0],
[61,44,27,1],
[61,44,27,2],
[61,44,27,3],
[61,44,27,5],
[61,44,27,6],
[61,44,27,7],
[61,44,27,8],
[61,44,27,9],
[61,44,27,11],
[61,44,27,12],
[61,44,27,13],
[61,44,27,14],
[61,44,27,15],
[61,44,27,16],
[61,44,27,18],
[61,44,28,0],
[61,44,28,1],
[61,44,28,2],
[61,44,28,3],
[61,44,28,5],
[61,44,28,6],
[61,44,28,8],
[61,44,28,9],
[61,44,28,10],
[61,44,28,11],
[61,44,28,12],
[61,44,28,13],
[61,44,28,14],
[61,44,28,15],
[61,44,28,16],
[61,44,29,0],
[61,44,29,1],
[61,44,29,2],
[61,44,29,3],
[61,44,29,5],
[61,44,29,6],
[61,44,29,7],
[61,44,29,8],
[61,44,29,9],
[61,44,29,10],
[61,44,29,13],
[61,44,29,14],
[61,44,29,15],
[61,44,30,0],
[61,44,30,1],
[61,44,30,2],
[61,44,30,3],
[61,44,30,5],
[61,44,30,6],
[61,44,30,7],
[61,44,30,8],
[61,44,30,9],
[61,44,30,10],
[61,44,30,11],
[61,44,30,12],
[61,44,30,13],
[61,44,30,14],
[61,44,31,0],
[61,44,31,1],
[61,44,31,2],
[61,44,31,3],
[61,44,31,5],
[61,44,31,6],
[61,44,31,7],
[61,44,31,8],
[61,44,31,9],
[61,44,31,10],
[61,44,31,11],
[61,44,31,12],
[61,44,31,13],
[61,44,32,0],
[61,44,32,1],
[61,44,32,2],
[61,44,32,3],
[61,44,32,5],
[61,44,32,6],
[61,44,32,7],
[61,44,32,8],
[61,44,32,9],
[61,44,32,10],
[61,44,33,0],
[61,44,33,1],
[61,44,33,2],
[61,44,33,3],
[61,44,33,5],
[61,44,33,6],
[61,44,33,7],
[61,44,33,8],
[61,44,33,9],
[61,44,34,0],
[61,44,34,1],
[61,44,34,2],
[61,44,34,3],
[61,44,34,5],
[61,44,34,6],
[61,44,35,0],
[61,44,35,1],
[61,44,35,2],
[61,44,35,3],
[61,44,35,5],
[61,44,36,0],
[61,44,36,1],
[61,44,36,2],
[61,44,36,3],
[61,45,3,0],
[61,45,3,1],
[61,45,3,2],
[61,45,5,0],
[61,45,5,1],
[61,45,5,2],
[61,45,6,0],
[61,45,6,1],
[61,45,6,2],
[61,45,7,0],
[61,45,7,1],
[61,45,7,2],
[61,45,7,3],
[61,45,7,5],
[61,45,7,6],
[61,45,8,0],
[61,45,8,1],
[61,45,8,2],
[61,45,8,7],
[61,45,10,0],
[61,45,10,1],
[61,45,10,2],
[61,45,10,3],
[61,45,10,5],
[61,45,10,6],
[61,45,10,7],
[61,45,10,8],
[61,45,11,0],
[61,45,11,1],
[61,45,11,2],
[61,45,11,3],
[61,45,11,5],
[61,45,11,6],
[61,45,11,7],
[61,45,11,8],
[61,45,11,10],
[61,45,12,0],
[61,45,12,1],
[61,45,12,2],
[61,45,12,3],
[61,45,12,5],
[61,45,12,6],
[61,45,12,7],
[61,45,12,8],
[61,45,12,10],
[61,45,13,0],
[61,45,13,1],
[61,45,13,2],
[61,45,13,3],
[61,45,13,5],
[61,45,13,6],
[61,45,13,7],
[61,45,13,8],
[61,45,13,10],
[61,45,13,11],
[61,45,13,12],
[61,45,14,0],
[61,45,14,1],
[61,45,14,2],
[61,45,14,3],
[61,45,14,5],
[61,45,14,6],
[61,45,14,7],
[61,45,14,8],
[61,45,14,10],
[61,45,14,11],
[61,45,14,12],
[61,45,16,0],
[61,45,16,1],
[61,45,16,2],
[61,45,16,3],
[61,45,16,5],
[61,45,16,6],
[61,45,16,7],
[61,45,16,8],
[61,45,16,10],
[61,45,16,11],
[61,45,16,12],
[61,45,16,13],
[61,45,17,0],
[61,45,17,1],
[61,45,17,2],
[61,45,17,3],
[61,45,17,5],
[61,45,17,6],
[61,45,17,7],
[61,45,17,8],
[61,45,17,10],
[61,45,17,11],
[61,45,17,12],
[61,45,17,13],
[61,45,17,16],
[61,45,19,0],
[61,45,19,1],
[61,45,19,2],
[61,45,19,3],
[61,45,19,5],
[61,45,19,6],
[61,45,19,7],
[61,45,19,8],
[61,45,19,12],
[61,45,19,16],
[61,45,19,17],
[61,45,20,0],
[61,45,20,1],
[61,45,20,2],
[61,45,20,3],
[61,45,20,5],
[61,45,20,6],
[61,45,20,7],
[61,45,20,11],
[61,45,20,12],
[61,45,20,13],
[61,45,20,14],
[61,45,20,16],
[61,45,20,17],
[61,45,20,19],
[61,45,21,0],
[61,45,21,1],
[61,45,21,2],
[61,45,21,10],
[61,45,21,11],
[61,45,21,12],
[61,45,21,13],
[61,45,21,14],
[61,45,21,16],
[61,45,21,17],
[61,45,21,19],
[61,45,21,20],
[61,45,22,0],
[61,45,22,1],
[61,45,22,2],
[61,45,22,3],
[61,45,22,6],
[61,45,22,7],
[61,45,22,8],
[61,45,22,11],
[61,45,22,12],
[61,45,22,13],
[61,45,22,14],
[61,45,22,16],
[61,45,22,17],
[61,45,22,19],
[61,45,22,21],
[61,45,23,0],
[61,45,23,1],
[61,45,23,2],
[61,45,23,5],
[61,45,23,6],
[61,45,23,7],
[61,45,23,8],
[61,45,23,10],
[61,45,23,11],
[61,45,23,12],
[61,45,23,13],
[61,45,23,14],
[61,45,23,16],
[61,45,23,17],
[61,45,23,19],
[61,45,23,20],
[61,45,23,21],
[61,45,24,0],
[61,45,24,1],
[61,45,24,2],
[61,45,24,5],
[61,45,24,6],
[61,45,24,7],
[61,45,24,8],
[61,45,24,10],
[61,45,24,11],
[61,45,24,12],
[61,45,24,13],
[61,45,24,14],
[61,45,24,17],
[61,45,24,19],
[61,45,24,20],
[61,45,24,21],
[61,45,25,0],
[61,45,25,1],
[61,45,25,2],
[61,45,25,3],
[61,45,25,5],
[61,45,25,6],
[61,45,25,8],
[61,45,25,10],
[61,45,25,11],
[61,45,25,12],
[61,45,25,13],
[61,45,25,14],
[61,45,25,16],
[61,45,25,17],
[61,45,25,19],
[61,45,27,0],
[61,45,27,1],
[61,45,27,2],
[61,45,27,3],
[61,45,27,5],
[61,45,27,6],
[61,45,27,7],
[61,45,27,8],
[61,45,27,11],
[61,45,27,12],
[61,45,27,13],
[61,45,27,14],
[61,45,27,16],
[61,45,27,17],
[61,45,28,0],
[61,45,28,1],
[61,45,28,2],
[61,45,28,3],
[61,45,28,5],
[61,45,28,6],
[61,45,28,8],
[61,45,28,10],
[61,45,28,11],
[61,45,28,12],
[61,45,28,13],
[61,45,28,14],
[61,45,29,0],
[61,45,29,1],
[61,45,29,2],
[61,45,29,3],
[61,45,29,5],
[61,45,29,6],
[61,45,29,7],
[61,45,29,8],
[61,45,29,10],
[61,45,29,13],
[61,45,29,14],
[61,45,30,0],
[61,45,30,1],
[61,45,30,2],
[61,45,30,3],
[61,45,30,5],
[61,45,30,6],
[61,45,30,7],
[61,45,30,8],
[61,45,30,10],
[61,45,30,11],
[61,45,30,12],
[61,45,30,13],
[61,45,31,0],
[61,45,31,1],
[61,45,31,2],
[61,45,31,3],
[61,45,31,5],
[61,45,31,6],
[61,45,31,7],
[61,45,31,8],
[61,45,31,10],
[61,45,31,11],
[61,45,32,0],
[61,45,32,1],
[61,45,32,2],
[61,45,32,3],
[61,45,32,5],
[61,45,32,6],
[61,45,32,7],
[61,45,32,8],
[61,45,33,0],
[61,45,33,1],
[61,45,33,2],
[61,45,33,3],
[61,45,33,5],
[61,45,33,6],
[61,45,33,7],
[61,45,34,0],
[61,45,34,1],
[61,45,34,2],
[61,45,34,3],
[61,45,34,5],
[61,45,36,0],
[61,45,36,1],
[61,45,36,2],
[61,46,3,0],
[61,46,3,1],
[61,46,3,2],
[61,46,5,0],
[61,46,5,1],
[61,46,5,2],
[61,46,6,0],
[61,46,6,1],
[61,46,6,2],
[61,46,7,0],
[61,46,7,1],
[61,46,7,2],
[61,46,7,3],
[61,46,7,5],
[61,46,7,6],
[61,46,8,0],
[61,46,8,1],
[61,46,8,2],
[61,46,8,7],
[61,46,10,0],
[61,46,10,1],
[61,46,10,2],
[61,46,10,3],
[61,46,10,5],
[61,46,10,6],
[61,46,10,7],
[61,46,10,8],
[61,46,11,0],
[61,46,11,1],
[61,46,11,2],
[61,46,11,3],
[61,46,11,5],
[61,46,11,6],
[61,46,11,7],
[61,46,11,8],
[61,46,11,10],
[61,46,12,0],
[61,46,12,1],
[61,46,12,2],
[61,46,12,3],
[61,46,12,5],
[61,46,12,6],
[61,46,12,7],
[61,46,12,8],
[61,46,12,10],
[61,46,13,0],
[61,46,13,1],
[61,46,13,2],
[61,46,13,3],
[61,46,13,5],
[61,46,13,6],
[61,46,13,7],
[61,46,13,8],
[61,46,13,10],
[61,46,13,11],
[61,46,13,12],
[61,46,14,0],
[61,46,14,1],
[61,46,14,2],
[61,46,14,3],
[61,46,14,5],
[61,46,14,6],
[61,46,14,7],
[61,46,14,8],
[61,46,14,10],
[61,46,14,11],
[61,46,14,12],
[61,46,16,0],
[61,46,16,1],
[61,46,16,2],
[61,46,16,3],
[61,46,16,5],
[61,46,16,6],
[61,46,16,7],
[61,46,16,8],
[61,46,16,10],
[61,46,16,11],
[61,46,16,14],
[61,46,17,0],
[61,46,17,1],
[61,46,17,2],
[61,46,17,3],
[61,46,17,5],
[61,46,17,6],
[61,46,17,7],
[61,46,17,8],
[61,46,17,10],
[61,46,17,11],
[61,46,17,14],
[61,46,17,16],
[61,46,19,0],
[61,46,19,1],
[61,46,19,2],
[61,46,19,3],
[61,46,19,5],
[61,46,19,6],
[61,46,19,7],
[61,46,19,10],
[61,46,19,11],
[61,46,19,12],
[61,46,19,16],
[61,46,19,17],
[61,46,20,0],
[61,46,20,1],
[61,46,20,2],
[61,46,20,3],
[61,46,20,5],
[61,46,20,8],
[61,46,20,11],
[61,46,20,12],
[61,46,20,13],
[61,46,20,14],
[61,46,20,16],
[61,46,20,17],
[61,46,20,19],
[61,46,21,0],
[61,46,21,1],
[61,46,21,2],
[61,46,21,7],
[61,46,21,10],
[61,46,21,11],
[61,46,21,12],
[61,46,21,13],
[61,46,21,14],
[61,46,21,16],
[61,46,21,17],
[61,46,21,19],
[61,46,21,20],
[61,46,22,0],
[61,46,22,1],
[61,46,22,2],
[61,46,22,5],
[61,46,22,6],
[61,46,22,7],
[61,46,22,8],
[61,46,22,11],
[61,46,22,12],
[61,46,22,13],
[61,46,22,14],
[61,46,22,16],
[61,46,22,17],
[61,46,22,19],
[61,46,22,21],
[61,46,23,3],
[61,46,23,5],
[61,46,23,6],
[61,46,23,7],
[61,46,23,8],
[61,46,23,10],
[61,46,23,11],
[61,46,23,12],
[61,46,23,13],
[61,46,23,14],
[61,46,23,16],
[61,46,23,17],
[61,46,23,19],
[61,46,23,20],
[61,46,24,3],
[61,46,24,5],
[61,46,24,6],
[61,46,24,7],
[61,46,24,8],
[61,46,24,10],
[61,46,24,11],
[61,46,24,12],
[61,46,24,13],
[61,46,24,14],
[61,46,24,17],
[61,46,24,19],
[61,46,24,20],
[61,46,25,0],
[61,46,25,1],
[61,46,25,2],
[61,46,25,3],
[61,46,25,5],
[61,46,25,6],
[61,46,25,8],
[61,46,25,10],
[61,46,25,11],
[61,46,25,12],
[61,46,25,13],
[61,46,25,14],
[61,46,25,16],
[61,46,25,17],
[61,46,27,0],
[61,46,27,1],
[61,46,27,2],
[61,46,27,3],
[61,46,27,5],
[61,46,27,6],
[61,46,27,7],
[61,46,27,8],
[61,46,27,11],
[61,46,27,12],
[61,46,27,13],
[61,46,27,14],
[61,46,28,0],
[61,46,28,1],
[61,46,28,2],
[61,46,28,3],
[61,46,28,5],
[61,46,28,6],
[61,46,28,8],
[61,46,28,10],
[61,46,28,11],
[61,46,28,12],
[61,46,28,13],
[61,46,28,14],
[61,46,29,0],
[61,46,29,1],
[61,46,29,2],
[61,46,29,3],
[61,46,29,5],
[61,46,29,6],
[61,46,29,7],
[61,46,29,8],
[61,46,29,10],
[61,46,29,13],
[61,46,30,0],
[61,46,30,1],
[61,46,30,2],
[61,46,30,3],
[61,46,30,5],
[61,46,30,6],
[61,46,30,7],
[61,46,30,8],
[61,46,30,10],
[61,46,30,11],
[61,46,31,0],
[61,46,31,1],
[61,46,31,2],
[61,46,31,3],
[61,46,31,5],
[61,46,31,6],
[61,46,31,7],
[61,46,31,8],
[61,46,32,0],
[61,46,32,1],
[61,46,32,2],
[61,46,32,3],
[61,46,32,5],
[61,46,32,6],
[61,46,32,7],
[61,46,33,0],
[61,46,33,1],
[61,46,33,2],
[61,46,33,3],
[61,46,33,5],
[61,46,34,0],
[61,46,34,1],
[61,46,34,2],
[61,46,34,3],
[61,47,3,0],
[61,47,3,1],
[61,47,3,2],
[61,47,5,0],
[61,47,5,1],
[61,47,5,2],
[61,47,6,0],
[61,47,6,1],
[61,47,6,2],
[61,47,7,0],
[61,47,7,1],
[61,47,7,2],
[61,47,7,3],
[61,47,7,5],
[61,47,7,6],
[61,47,8,0],
[61,47,8,1],
[61,47,8,2],
[61,47,8,7],
[61,47,9,0],
[61,47,9,1],
[61,47,9,2],
[61,47,9,3],
[61,47,9,5],
[61,47,9,6],
[61,47,9,7],
[61,47,9,8],
[61,47,10,0],
[61,47,10,1],
[61,47,10,2],
[61,47,10,3],
[61,47,10,5],
[61,47,10,6],
[61,47,10,7],
[61,47,10,8],
[61,47,10,9],
[61,47,11,0],
[61,47,11,1],
[61,47,11,2],
[61,47,11,3],
[61,47,11,5],
[61,47,11,6],
[61,47,11,7],
[61,47,11,8],
[61,47,11,9],
[61,47,11,10],
[61,47,12,0],
[61,47,12,1],
[61,47,12,2],
[61,47,12,3],
[61,47,12,5],
[61,47,12,6],
[61,47,12,7],
[61,47,12,8],
[61,47,12,9],
[61,47,12,10],
[61,47,13,0],
[61,47,13,1],
[61,47,13,2],
[61,47,13,3],
[61,47,13,5],
[61,47,13,6],
[61,47,13,7],
[61,47,13,8],
[61,47,13,9],
[61,47,13,10],
[61,47,13,11],
[61,47,13,12],
[61,47,14,0],
[61,47,14,1],
[61,47,14,2],
[61,47,14,3],
[61,47,14,5],
[61,47,14,6],
[61,47,14,7],
[61,47,14,8],
[61,47,14,9],
[61,47,14,10],
[61,47,14,11],
[61,47,14,12],
[61,47,15,0],
[61,47,15,1],
[61,47,15,2],
[61,47,15,3],
[61,47,15,5],
[61,47,15,6],
[61,47,15,7],
[61,47,15,8],
[61,47,15,10],
[61,47,15,11],
[61,47,15,14],
[61,47,16,0],
[61,47,16,1],
[61,47,16,2],
[61,47,16,3],
[61,47,16,5],
[61,47,16,6],
[61,47,16,7],
[61,47,16,8],
[61,47,16,9],
[61,47,16,12],
[61,47,16,13],
[61,47,16,14],
[61,47,16,15],
[61,47,17,0],
[61,47,17,1],
[61,47,17,2],
[61,47,17,3],
[61,47,17,5],
[61,47,17,6],
[61,47,17,7],
[61,47,17,8],
[61,47,17,9],
[61,47,17,12],
[61,47,17,13],
[61,47,17,14],
[61,47,17,15],
[61,47,17,16],
[61,47,18,0],
[61,47,18,1],
[61,47,18,2],
[61,47,18,3],
[61,47,18,5],
[61,47,18,6],
[61,47,18,7],
[61,47,18,10],
[61,47,18,11],
[61,47,18,12],
[61,47,18,13],
[61,47,18,14],
[61,47,18,16],
[61,47,18,17],
[61,47,19,0],
[61,47,19,1],
[61,47,19,2],
[61,47,19,3],
[61,47,19,5],
[61,47,19,8],
[61,47,19,9],
[61,47,19,10],
[61,47,19,11],
[61,47,19,12],
[61,47,19,15],
[61,47,19,16],
[61,47,19,17],
[61,47,19,18],
[61,47,20,0],
[61,47,20,1],
[61,47,20,2],
[61,47,20,3],
[61,47,20,6],
[61,47,20,7],
[61,47,20,8],
[61,47,20,9],
[61,47,20,11],
[61,47,20,12],
[61,47,20,13],
[61,47,20,14],
[61,47,20,15],
[61,47,20,16],
[61,47,20,17],
[61,47,20,18],
[61,47,20,19],
[61,47,21,0],
[61,47,21,1],
[61,47,21,2],
[61,47,21,7],
[61,47,21,9],
[61,47,21,10],
[61,47,21,11],
[61,47,21,12],
[61,47,21,13],
[61,47,21,14],
[61,47,21,15],
[61,47,21,16],
[61,47,21,17],
[61,47,21,18],
[61,47,21,19],
[61,47,21,20],
[61,47,22,3],
[61,47,22,5],
[61,47,22,6],
[61,47,22,7],
[61,47,22,8],
[61,47,22,9],
[61,47,22,11],
[61,47,22,12],
[61,47,22,13],
[61,47,22,14],
[61,47,22,15],
[61,47,22,16],
[61,47,22,17],
[61,47,22,18],
[61,47,22,19],
[61,47,24,0],
[61,47,24,1],
[61,47,24,2],
[61,47,24,3],
[61,47,24,5],
[61,47,24,6],
[61,47,24,7],
[61,47,24,8],
[61,47,24,9],
[61,47,24,10],
[61,47,24,11],
[61,47,24,12],
[61,47,24,13],
[61,47,24,14],
[61,47,24,15],
[61,47,24,17],
[61,47,24,18],
[61,47,24,19],
[61,47,25,0],
[61,47,25,1],
[61,47,25,2],
[61,47,25,3],
[61,47,25,5],
[61,47,25,6],
[61,47,25,8],
[61,47,25,9],
[61,47,25,10],
[61,47,25,11],
[61,47,25,12],
[61,47,25,13],
[61,47,25,14],
[61,47,25,15],
[61,47,25,16],
[61,47,25,17],
[61,47,26,0],
[61,47,26,1],
[61,47,26,2],
[61,47,26,3],
[61,47,26,5],
[61,47,26,6],
[61,47,26,7],
[61,47,26,8],
[61,47,26,10],
[61,47,26,11],
[61,47,26,12],
[61,47,26,13],
[61,47,26,14],
[61,47,27,0],
[61,47,27,1],
[61,47,27,2],
[61,47,27,3],
[61,47,27,5],
[61,47,27,6],
[61,47,27,7],
[61,47,27,8],
[61,47,27,9],
[61,47,27,11],
[61,47,27,12],
[61,47,27,13],
[61,47,27,14],
[61,47,28,0],
[61,47,28,1],
[61,47,28,2],
[61,47,28,3],
[61,47,28,5],
[61,47,28,6],
[61,47,28,8],
[61,47,28,9],
[61,47,28,10],
[61,47,28,11],
[61,47,28,12],
[61,47,28,13],
[61,47,29,0],
[61,47,29,1],
[61,47,29,2],
[61,47,29,3],
[61,47,29,5],
[61,47,29,6],
[61,47,29,7],
[61,47,29,8],
[61,47,29,9],
[61,47,29,10],
[61,47,32,0],
[61,47,32,1],
[61,47,32,2],
[61,47,32,3],
[61,47,32,5],
[61,47,33,0],
[61,47,33,1],
[61,47,33,2],
[61,47,33,3],
[61,47,34,0],
[61,47,34,1],
[61,47,34,2],
[61,48,3,0],
[61,48,3,1],
[61,48,3,2],
[61,48,5,0],
[61,48,5,1],
[61,48,5,2],
[61,48,6,0],
[61,48,6,1],
[61,48,6,2],
[61,48,7,0],
[61,48,7,1],
[61,48,7,2],
[61,48,7,3],
[61,48,7,5],
[61,48,7,6],
[61,48,8,0],
[61,48,8,1],
[61,48,8,2],
[61,48,8,7],
[61,48,9,0],
[61,48,9,1],
[61,48,9,2],
[61,48,9,3],
[61,48,9,5],
[61,48,9,6],
[61,48,9,7],
[61,48,9,8],
[61,48,10,0],
[61,48,10,1],
[61,48,10,2],
[61,48,10,3],
[61,48,10,5],
[61,48,10,6],
[61,48,10,7],
[61,48,10,8],
[61,48,10,9],
[61,48,13,0],
[61,48,13,1],
[61,48,13,2],
[61,48,13,3],
[61,48,13,5],
[61,48,13,6],
[61,48,13,7],
[61,48,13,8],
[61,48,13,9],
[61,48,13,10],
[61,48,14,0],
[61,48,14,1],
[61,48,14,2],
[61,48,14,3],
[61,48,14,5],
[61,48,14,6],
[61,48,14,7],
[61,48,14,8],
[61,48,14,9],
[61,48,14,10],
[61,48,15,0],
[61,48,15,1],
[61,48,15,2],
[61,48,15,3],
[61,48,15,5],
[61,48,15,6],
[61,48,15,7],
[61,48,15,8],
[61,48,15,13],
[61,48,15,14],
[61,48,16,0],
[61,48,16,1],
[61,48,16,2],
[61,48,16,3],
[61,48,16,5],
[61,48,16,6],
[61,48,16,7],
[61,48,16,10],
[61,48,16,13],
[61,48,16,14],
[61,48,16,15],
[61,48,17,0],
[61,48,17,1],
[61,48,17,2],
[61,48,17,3],
[61,48,17,5],
[61,48,17,6],
[61,48,17,7],
[61,48,17,10],
[61,48,17,13],
[61,48,17,14],
[61,48,17,15],
[61,48,17,16],
[61,48,18,0],
[61,48,18,1],
[61,48,18,2],
[61,48,18,3],
[61,48,18,5],
[61,48,18,8],
[61,48,18,10],
[61,48,18,13],
[61,48,18,14],
[61,48,18,16],
[61,48,18,17],
[61,48,19,0],
[61,48,19,1],
[61,48,19,2],
[61,48,19,3],
[61,48,19,6],
[61,48,19,7],
[61,48,19,8],
[61,48,19,9],
[61,48,19,10],
[61,48,19,15],
[61,48,19,16],
[61,48,19,17],
[61,48,19,18],
[61,48,20,0],
[61,48,20,1],
[61,48,20,2],
[61,48,20,5],
[61,48,20,6],
[61,48,20,7],
[61,48,20,8],
[61,48,20,9],
[61,48,20,13],
[61,48,20,14],
[61,48,20,15],
[61,48,20,16],
[61,48,20,17],
[61,48,20,18],
[61,48,20,19],
[61,48,21,7],
[61,48,21,9],
[61,48,21,10],
[61,48,21,13],
[61,48,21,14],
[61,48,21,15],
[61,48,21,16],
[61,48,21,17],
[61,48,21,18],
[61,48,21,19],
[61,48,21,20],
[61,48,22,0],
[61,48,22,1],
[61,48,22,2],
[61,48,22,3],
[61,48,22,5],
[61,48,22,6],
[61,48,22,7],
[61,48,22,8],
[61,48,22,9],
[61,48,22,13],
[61,48,22,14],
[61,48,22,15],
[61,48,22,16],
[61,48,22,17],
[61,48,22,18],
[61,48,22,19],
[61,48,23,0],
[61,48,23,1],
[61,48,23,2],
[61,48,23,3],
[61,48,23,5],
[61,48,23,6],
[61,48,23,7],
[61,48,23,8],
[61,48,23,9],
[61,48,23,10],
[61,48,23,13],
[61,48,23,14],
[61,48,23,15],
[61,48,23,16],
[61,48,23,17],
[61,48,23,18],
[61,48,24,0],
[61,48,24,1],
[61,48,24,2],
[61,48,24,3],
[61,48,24,5],
[61,48,24,6],
[61,48,24,7],
[61,48,24,8],
[61,48,24,9],
[61,48,24,10],
[61,48,24,13],
[61,48,24,14],
[61,48,24,15],
[61,48,24,17],
[61,48,24,18],
[61,48,25,0],
[61,48,25,1],
[61,48,25,2],
[61,48,25,3],
[61,48,25,5],
[61,48,25,6],
[61,48,25,8],
[61,48,25,9],
[61,48,25,10],
[61,48,25,13],
[61,48,25,14],
[61,48,25,15],
[61,48,26,0],
[61,48,26,1],
[61,48,26,2],
[61,48,26,3],
[61,48,26,5],
[61,48,26,6],
[61,48,26,7],
[61,48,26,8],
[61,48,26,10],
[61,48,26,13],
[61,48,26,14],
[61,48,27,0],
[61,48,27,1],
[61,48,27,2],
[61,48,27,3],
[61,48,27,5],
[61,48,27,6],
[61,48,27,7],
[61,48,27,8],
[61,48,27,9],
[61,48,27,13],
[61,48,28,0],
[61,48,28,1],
[61,48,28,2],
[61,48,28,3],
[61,48,28,5],
[61,48,28,6],
[61,48,28,8],
[61,48,28,9],
[61,48,28,10],
[61,48,30,0],
[61,48,30,1],
[61,48,30,2],
[61,48,30,3],
[61,48,30,5],
[61,48,30,6],
[61,48,30,7],
[61,48,31,0],
[61,48,31,1],
[61,48,31,2],
[61,48,31,3],
[61,48,31,5],
[61,49,3,0],
[61,49,3,1],
[61,49,3,2],
[61,49,5,0],
[61,49,5,1],
[61,49,5,2],
[61,49,6,0],
[61,49,6,1],
[61,49,6,2],
[61,49,7,0],
[61,49,7,1],
[61,49,7,2],
[61,49,7,3],
[61,49,7,5],
[61,49,7,6],
[61,49,8,0],
[61,49,8,1],
[61,49,8,2],
[61,49,8,7],
[61,49,10,0],
[61,49,10,1],
[61,49,10,2],
[61,49,10,3],
[61,49,10,5],
[61,49,10,6],
[61,49,10,7],
[61,49,10,8],
[61,49,11,0],
[61,49,11,1],
[61,49,11,2],
[61,49,11,3],
[61,49,11,5],
[61,49,11,6],
[61,49,11,7],
[61,49,11,8],
[61,49,11,10],
[61,49,12,0],
[61,49,12,1],
[61,49,12,2],
[61,49,12,3],
[61,49,12,5],
[61,49,12,6],
[61,49,12,7],
[61,49,12,8],
[61,49,12,10],
[61,49,13,0],
[61,49,13,1],
[61,49,13,2],
[61,49,13,3],
[61,49,13,5],
[61,49,13,6],
[61,49,13,7],
[61,49,13,8],
[61,49,13,10],
[61,49,13,11],
[61,49,13,12],
[61,49,14,0],
[61,49,14,1],
[61,49,14,2],
[61,49,14,3],
[61,49,14,5],
[61,49,14,6],
[61,49,14,7],
[61,49,14,8],
[61,49,14,10],
[61,49,14,11],
[61,49,16,0],
[61,49,16,1],
[61,49,16,2],
[61,49,16,3],
[61,49,16,5],
[61,49,16,6],
[61,49,16,7],
[61,49,16,10],
[61,49,16,11],
[61,49,16,12],
[61,49,16,13],
[61,49,16,14],
[61,49,17,0],
[61,49,17,1],
[61,49,17,2],
[61,49,17,3],
[61,49,17,5],
[61,49,17,6],
[61,49,17,7],
[61,49,17,10],
[61,49,17,11],
[61,49,17,12],
[61,49,17,13],
[61,49,17,14],
[61,49,17,16],
[61,49,19,0],
[61,49,19,1],
[61,49,19,2],
[61,49,19,3],
[61,49,19,6],
[61,49,19,7],
[61,49,19,8],
[61,49,19,10],
[61,49,19,11],
[61,49,19,12],
[61,49,19,16],
[61,49,19,17],
[61,49,20,0],
[61,49,20,1],
[61,49,20,2],
[61,49,20,5],
[61,49,20,6],
[61,49,20,7],
[61,49,20,8],
[61,49,20,11],
[61,49,20,12],
[61,49,20,13],
[61,49,20,14],
[61,49,20,16],
[61,49,20,17],
[61,49,20,19],
[61,49,21,7],
[61,49,21,10],
[61,49,21,11],
[61,49,21,12],
[61,49,21,13],
[61,49,21,14],
[61,49,21,16],
[61,49,21,17],
[61,49,21,19],
[61,49,21,20],
[61,49,22,0],
[61,49,22,1],
[61,49,22,2],
[61,49,22,3],
[61,49,22,5],
[61,49,22,6],
[61,49,22,7],
[61,49,22,8],
[61,49,22,11],
[61,49,22,12],
[61,49,22,13],
[61,49,22,14],
[61,49,22,16],
[61,49,22,17],
[61,49,22,19],
[61,49,23,0],
[61,49,23,1],
[61,49,23,2],
[61,49,23,3],
[61,49,23,5],
[61,49,23,6],
[61,49,23,7],
[61,49,23,8],
[61,49,23,10],
[61,49,23,11],
[61,49,23,12],
[61,49,23,13],
[61,49,23,14],
[61,49,23,16],
[61,49,23,17],
[61,49,24,0],
[61,49,24,1],
[61,49,24,2],
[61,49,24,3],
[61,49,24,5],
[61,49,24,6],
[61,49,24,7],
[61,49,24,8],
[61,49,24,10],
[61,49,24,11],
[61,49,24,12],
[61,49,24,13],
[61,49,24,14],
[61,49,24,17],
[61,49,25,0],
[61,49,25,1],
[61,49,25,2],
[61,49,25,3],
[61,49,25,5],
[61,49,25,6],
[61,49,25,8],
[61,49,25,10],
[61,49,25,11],
[61,49,25,12],
[61,49,25,13],
[61,49,25,14],
[61,49,27,0],
[61,49,27,1],
[61,49,27,2],
[61,49,27,3],
[61,49,27,5],
[61,49,27,6],
[61,49,27,7],
[61,49,27,8],
[61,49,27,11],
[61,49,27,12],
[61,49,27,13],
[61,49,28,0],
[61,49,28,1],
[61,49,28,2],
[61,49,28,3],
[61,49,28,5],
[61,49,28,6],
[61,49,28,8],
[61,49,28,10],
[61,49,28,11],
[61,49,29,0],
[61,49,29,1],
[61,49,29,2],
[61,49,29,3],
[61,49,29,5],
[61,49,29,6],
[61,49,29,7],
[61,49,29,8],
[61,49,30,0],
[61,49,30,1],
[61,49,30,2],
[61,49,30,3],
[61,49,30,5],
[61,49,30,6],
[61,49,30,7],
[61,49,31,0],
[61,49,31,1],
[61,49,31,2],
[61,49,31,3],
[61,49,31,5],
[61,49,32,0],
[61,49,32,1],
[61,49,32,2],
[61,49,32,3],
[61,49,33,0],
[61,49,33,1],
[61,49,33,2],
[61,50,3,0],
[61,50,3,1],
[61,50,3,2],
[61,50,5,0],
[61,50,5,1],
[61,50,5,2],
[61,50,6,0],
[61,50,6,1],
[61,50,6,2],
[61,50,7,0],
[61,50,7,1],
[61,50,7,2],
[61,50,7,3],
[61,50,7,5],
[61,50,7,6],
[61,50,8,0],
[61,50,8,1],
[61,50,8,2],
[61,50,8,7],
[61,50,10,0],
[61,50,10,1],
[61,50,10,2],
[61,50,10,3],
[61,50,10,5],
[61,50,10,6],
[61,50,10,7],
[61,50,10,8],
[61,50,11,0],
[61,50,11,1],
[61,50,11,2],
[61,50,11,3],
[61,50,11,5],
[61,50,11,6],
[61,50,11,7],
[61,50,11,8],
[61,50,11,10],
[61,50,12,0],
[61,50,12,1],
[61,50,12,2],
[61,50,12,3],
[61,50,12,5],
[61,50,12,6],
[61,50,12,7],
[61,50,12,8],
[61,50,12,10],
[61,50,13,0],
[61,50,13,1],
[61,50,13,2],
[61,50,13,3],
[61,50,13,5],
[61,50,13,6],
[61,50,13,7],
[61,50,13,8],
[61,50,13,10],
[61,50,13,11],
[61,50,13,12],
[61,50,14,0],
[61,50,14,1],
[61,50,14,2],
[61,50,14,3],
[61,50,14,5],
[61,50,14,6],
[61,50,14,7],
[61,50,14,8],
[61,50,14,10],
[61,50,14,11],
[61,50,16,0],
[61,50,16,1],
[61,50,16,2],
[61,50,16,3],
[61,50,16,5],
[61,50,16,6],
[61,50,16,7],
[61,50,16,10],
[61,50,16,11],
[61,50,16,12],
[61,50,16,13],
[61,50,16,14],
[61,50,17,0],
[61,50,17,1],
[61,50,17,2],
[61,50,17,3],
[61,50,17,5],
[61,50,17,6],
[61,50,17,7],
[61,50,17,10],
[61,50,17,11],
[61,50,17,12],
[61,50,17,13],
[61,50,17,14],
[61,50,17,16],
[61,50,19,0],
[61,50,19,1],
[61,50,19,2],
[61,50,19,3],
[61,50,19,6],
[61,50,19,7],
[61,50,19,8],
[61,50,19,10],
[61,50,19,11],
[61,50,19,12],
[61,50,19,16],
[61,50,19,17],
[61,50,20,0],
[61,50,20,1],
[61,50,20,2],
[61,50,20,5],
[61,50,20,6],
[61,50,20,7],
[61,50,20,8],
[61,50,20,11],
[61,50,20,12],
[61,50,20,13],
[61,50,20,14],
[61,50,20,16],
[61,50,20,17],
[61,50,20,19],
[61,50,21,7],
[61,50,21,10],
[61,50,21,11],
[61,50,21,12],
[61,50,21,13],
[61,50,21,14],
[61,50,21,16],
[61,50,21,17],
[61,50,21,19],
[61,50,21,20],
[61,50,22,0],
[61,50,22,1],
[61,50,22,2],
[61,50,22,3],
[61,50,22,5],
[61,50,22,6],
[61,50,22,7],
[61,50,22,8],
[61,50,22,11],
[61,50,22,12],
[61,50,22,13],
[61,50,22,14],
[61,50,22,16],
[61,50,22,17],
[61,50,22,19],
[61,50,23,0],
[61,50,23,1],
[61,50,23,2],
[61,50,23,3],
[61,50,23,5],
[61,50,23,6],
[61,50,23,7],
[61,50,23,8],
[61,50,23,10],
[61,50,23,11],
[61,50,23,12],
[61,50,23,13],
[61,50,23,14],
[61,50,23,16],
[61,50,23,17],
[61,50,24,0],
[61,50,24,1],
[61,50,24,2],
[61,50,24,3],
[61,50,24,5],
[61,50,24,6],
[61,50,24,7],
[61,50,24,8],
[61,50,24,10],
[61,50,24,11],
[61,50,24,12],
[61,50,24,13],
[61,50,24,14],
[61,50,24,17],
[61,50,25,0],
[61,50,25,1],
[61,50,25,2],
[61,50,25,3],
[61,50,25,5],
[61,50,25,6],
[61,50,25,8],
[61,50,25,10],
[61,50,25,11],
[61,50,25,12],
[61,50,25,13],
[61,50,25,14],
[61,50,27,0],
[61,50,27,1],
[61,50,27,2],
[61,50,27,3],
[61,50,27,5],
[61,50,27,6],
[61,50,27,7],
[61,50,27,8],
[61,50,27,11],
[61,50,27,12],
[61,50,27,13],
[61,50,28,0],
[61,50,28,1],
[61,50,28,2],
[61,50,28,3],
[61,50,28,5],
[61,50,28,6],
[61,50,28,8],
[61,50,28,10],
[61,50,28,11],
[61,50,29,0],
[61,50,29,1],
[61,50,29,2],
[61,50,29,3],
[61,50,29,5],
[61,50,29,6],
[61,50,29,7],
[61,50,29,8],
[61,50,30,0],
[61,50,30,1],
[61,50,30,2],
[61,50,30,3],
[61,50,30,5],
[61,50,30,6],
[61,50,30,7],
[61,50,31,0],
[61,50,31,1],
[61,50,31,2],
[61,50,31,3],
[61,50,31,5],
[61,50,32,0],
[61,50,32,1],
[61,50,32,2],
[61,50,32,3],
[61,50,33,0],
[61,50,33,1],
[61,50,33,2],
[61,51,7,0],
[61,51,7,1],
[61,51,7,2],
[61,51,9,0],
[61,51,9,1],
[61,51,9,2],
[61,51,9,7],
[61,51,10,0],
[61,51,10,1],
[61,51,10,2],
[61,51,10,7],
[61,51,10,9],
[61,51,11,0],
[61,51,11,1],
[61,51,11,2],
[61,51,11,7],
[61,51,11,9],
[61,51,11,10],
[61,51,12,0],
[61,51,12,1],
[61,51,12,2],
[61,51,12,7],
[61,51,12,9],
[61,51,12,10],
[61,51,13,0],
[61,51,13,1],
[61,51,13,2],
[61,51,13,7],
[61,51,13,9],
[61,51,13,10],
[61,51,13,11],
[61,51,13,12],
[61,51,14,0],
[61,51,14,1],
[61,51,14,2],
[61,51,14,7],
[61,51,14,9],
[61,51,14,10],
[61,51,14,11],
[61,51,15,0],
[61,51,15,1],
[61,51,15,2],
[61,51,15,7],
[61,51,15,12],
[61,51,15,13],
[61,51,15,14],
[61,51,16,0],
[61,51,16,1],
[61,51,16,2],
[61,51,16,7],
[61,51,16,10],
[61,51,16,11],
[61,51,16,12],
[61,51,16,13],
[61,51,16,14],
[61,51,16,15],
[61,51,17,0],
[61,51,17,1],
[61,51,17,2],
[61,51,17,7],
[61,51,17,10],
[61,51,17,11],
[61,51,17,12],
[61,51,17,13],
[61,51,17,14],
[61,51,17,15],
[61,51,17,16],
[61,51,18,0],
[61,51,18,1],
[61,51,18,2],
[61,51,18,10],
[61,51,18,11],
[61,51,18,12],
[61,51,18,13],
[61,51,18,14],
[61,51,18,16],
[61,51,18,17],
[61,51,19,0],
[61,51,19,1],
[61,51,19,2],
[61,51,19,7],
[61,51,19,9],
[61,51,19,10],
[61,51,19,11],
[61,51,19,12],
[61,51,19,15],
[61,51,19,16],
[61,51,19,17],
[61,51,19,18],
[61,51,20,0],
[61,51,20,1],
[61,51,20,2],
[61,51,20,7],
[61,51,20,9],
[61,51,20,11],
[61,51,20,12],
[61,51,20,13],
[61,51,20,14],
[61,51,20,15],
[61,51,20,16],
[61,51,20,17],
[61,51,20,18],
[61,51,20,19],
[61,51,22,0],
[61,51,22,1],
[61,51,22,2],
[61,51,22,7],
[61,51,22,9],
[61,51,22,11],
[61,51,22,12],
[61,51,22,13],
[61,51,22,14],
[61,51,22,15],
[61,51,22,16],
[61,51,22,17],
[61,51,22,18],
[61,51,22,19],
[61,51,23,0],
[61,51,23,1],
[61,51,23,2],
[61,51,23,7],
[61,51,23,9],
[61,51,23,10],
[61,51,23,11],
[61,51,23,12],
[61,51,23,13],
[61,51,23,14],
[61,51,23,15],
[61,51,23,16],
[61,51,23,17],
[61,51,23,18],
[61,51,24,0],
[61,51,24,1],
[61,51,24,2],
[61,51,24,7],
[61,51,24,9],
[61,51,24,10],
[61,51,24,11],
[61,51,24,12],
[61,51,24,13],
[61,51,24,14],
[61,51,24,15],
[61,51,24,17],
[61,51,24,18],
[61,51,25,0],
[61,51,25,1],
[61,51,25,2],
[61,51,25,9],
[61,51,25,10],
[61,51,25,11],
[61,51,25,12],
[61,51,25,13],
[61,51,25,14],
[61,51,25,15],
[61,51,26,0],
[61,51,26,1],
[61,51,26,2],
[61,51,26,7],
[61,51,26,10],
[61,51,26,11],
[61,51,26,12],
[61,51,26,13],
[61,51,26,14],
[61,51,27,0],
[61,51,27,1],
[61,51,27,2],
[61,51,27,7],
[61,51,27,9],
[61,51,27,11],
[61,51,27,12],
[61,51,27,13],
[61,51,28,0],
[61,51,28,1],
[61,51,28,2],
[61,51,28,9],
[61,51,28,10],
[61,51,28,11],
[61,51,29,0],
[61,51,29,1],
[61,51,29,2],
[61,51,29,7],
[61,51,29,9],
[61,51,30,0],
[61,51,30,1],
[61,51,30,2],
[61,51,30,7],
[61,51,31,0],
[61,51,31,1],
[61,51,31,2],
[61,51,32,0],
[61,51,32,1],
[61,51,32,2],
[61,51,33,0],
[61,51,33,1],
[61,51,33,2],
[61,52,3,0],
[61,52,3,1],
[61,52,3,2],
[61,52,5,0],
[61,52,5,1],
[61,52,5,2],
[61,52,6,0],
[61,52,6,1],
[61,52,6,2],
[61,52,7,0],
[61,52,7,1],
[61,52,7,2],
[61,52,7,3],
[61,52,7,5],
[61,52,7,6],
[61,52,8,0],
[61,52,8,1],
[61,52,8,2],
[61,52,8,7],
[61,52,10,0],
[61,52,10,1],
[61,52,10,2],
[61,52,10,3],
[61,52,10,5],
[61,52,10,6],
[61,52,10,7],
[61,52,10,8],
[61,52,11,0],
[61,52,11,1],
[61,52,11,2],
[61,52,11,3],
[61,52,11,5],
[61,52,11,6],
[61,52,11,7],
[61,52,11,8],
[61,52,11,10],
[61,52,12,0],
[61,52,12,1],
[61,52,12,2],
[61,52,12,3],
[61,52,12,5],
[61,52,12,6],
[61,52,12,7],
[61,52,12,8],
[61,52,12,10],
[61,52,13,0],
[61,52,13,1],
[61,52,13,2],
[61,52,13,3],
[61,52,13,5],
[61,52,13,6],
[61,52,13,7],
[61,52,13,8],
[61,52,13,10],
[61,52,13,11],
[61,52,13,12],
[61,52,14,0],
[61,52,14,1],
[61,52,14,2],
[61,52,14,3],
[61,52,14,5],
[61,52,14,6],
[61,52,14,7],
[61,52,14,8],
[61,52,14,10],
[61,52,14,11],
[61,52,16,0],
[61,52,16,1],
[61,52,16,2],
[61,52,16,3],
[61,52,16,5],
[61,52,16,6],
[61,52,16,7],
[61,52,16,10],
[61,52,16,11],
[61,52,16,12],
[61,52,16,13],
[61,52,16,14],
[61,52,17,0],
[61,52,17,1],
[61,52,17,2],
[61,52,17,3],
[61,52,17,5],
[61,52,17,6],
[61,52,17,7],
[61,52,17,10],
[61,52,17,11],
[61,52,17,12],
[61,52,17,13],
[61,52,17,14],
[61,52,17,16],
[61,52,19,0],
[61,52,19,1],
[61,52,19,2],
[61,52,19,3],
[61,52,19,6],
[61,52,19,7],
[61,52,19,8],
[61,52,19,10],
[61,52,19,11],
[61,52,19,12],
[61,52,19,16],
[61,52,19,17],
[61,52,20,0],
[61,52,20,1],
[61,52,20,2],
[61,52,20,5],
[61,52,20,6],
[61,52,20,7],
[61,52,20,8],
[61,52,20,11],
[61,52,20,12],
[61,52,20,13],
[61,52,20,14],
[61,52,20,16],
[61,52,20,17],
[61,52,20,19],
[61,52,21,7],
[61,52,21,10],
[61,52,21,11],
[61,52,21,12],
[61,52,21,13],
[61,52,21,14],
[61,52,21,16],
[61,52,21,17],
[61,52,21,19],
[61,52,21,20],
[61,52,22,0],
[61,52,22,1],
[61,52,22,2],
[61,52,22,3],
[61,52,22,5],
[61,52,22,6],
[61,52,22,7],
[61,52,22,8],
[61,52,22,11],
[61,52,22,12],
[61,52,22,13],
[61,52,22,14],
[61,52,22,16],
[61,52,22,17],
[61,52,22,19],
[61,52,23,0],
[61,52,23,1],
[61,52,23,2],
[61,52,23,3],
[61,52,23,5],
[61,52,23,6],
[61,52,23,7],
[61,52,23,8],
[61,52,23,10],
[61,52,23,11],
[61,52,23,12],
[61,52,23,13],
[61,52,23,14],
[61,52,23,16],
[61,52,23,17],
[61,52,24,0],
[61,52,24,1],
[61,52,24,2],
[61,52,24,3],
[61,52,24,5],
[61,52,24,6],
[61,52,24,7],
[61,52,24,8],
[61,52,24,10],
[61,52,24,11],
[61,52,24,12],
[61,52,24,13],
[61,52,24,14],
[61,52,24,17],
[61,52,25,0],
[61,52,25,1],
[61,52,25,2],
[61,52,25,3],
[61,52,25,5],
[61,52,25,6],
[61,52,25,8],
[61,52,25,10],
[61,52,25,11],
[61,52,25,12],
[61,52,25,13],
[61,52,25,14],
[61,52,27,0],
[61,52,27,1],
[61,52,27,2],
[61,52,27,3],
[61,52,27,5],
[61,52,27,6],
[61,52,27,7],
[61,52,27,8],
[61,52,27,11],
[61,52,27,12],
[61,52,27,13],
[61,52,28,0],
[61,52,28,1],
[61,52,28,2],
[61,52,28,3],
[61,52,28,5],
[61,52,28,6],
[61,52,28,8],
[61,52,28,10],
[61,52,28,11],
[61,52,29,0],
[61,52,29,1],
[61,52,29,2],
[61,52,29,3],
[61,52,29,5],
[61,52,29,6],
[61,52,29,7],
[61,52,29,8],
[61,52,30,0],
[61,52,30,1],
[61,52,30,2],
[61,52,30,3],
[61,52,30,5],
[61,52,30,6],
[61,52,30,7],
[61,52,31,0],
[61,52,31,1],
[61,52,31,2],
[61,52,31,3],
[61,52,31,5],
[61,52,32,0],
[61,52,32,1],
[61,52,32,2],
[61,52,32,3],
[61,52,33,0],
[61,52,33,1],
[61,52,33,2],
[61,53,3,0],
[61,53,3,1],
[61,53,3,2],
[61,53,5,0],
[61,53,5,1],
[61,53,5,2],
[61,53,6,0],
[61,53,6,1],
[61,53,6,2],
[61,53,7,0],
[61,53,7,1],
[61,53,7,2],
[61,53,7,3],
[61,53,7,5],
[61,53,7,6],
[61,53,8,0],
[61,53,8,1],
[61,53,8,2],
[61,53,8,7],
[61,53,9,0],
[61,53,9,1],
[61,53,9,2],
[61,53,9,3],
[61,53,9,5],
[61,53,9,6],
[61,53,9,7],
[61,53,9,8],
[61,53,11,0],
[61,53,11,1],
[61,53,11,2],
[61,53,11,3],
[61,53,11,5],
[61,53,11,6],
[61,53,11,7],
[61,53,11,8],
[61,53,11,9],
[61,53,12,0],
[61,53,12,1],
[61,53,12,2],
[61,53,12,3],
[61,53,12,5],
[61,53,12,6],
[61,53,12,7],
[61,53,12,8],
[61,53,12,9],
[61,53,13,0],
[61,53,13,1],
[61,53,13,2],
[61,53,13,3],
[61,53,13,5],
[61,53,13,6],
[61,53,13,7],
[61,53,13,8],
[61,53,13,9],
[61,53,13,11],
[61,53,13,12],
[61,53,14,0],
[61,53,14,1],
[61,53,14,2],
[61,53,14,3],
[61,53,14,5],
[61,53,14,6],
[61,53,14,7],
[61,53,14,8],
[61,53,14,9],
[61,53,14,11],
[61,53,15,0],
[61,53,15,1],
[61,53,15,2],
[61,53,15,3],
[61,53,15,5],
[61,53,15,6],
[61,53,15,7],
[61,53,15,8],
[61,53,15,12],
[61,53,15,13],
[61,53,15,14],
[61,53,16,0],
[61,53,16,1],
[61,53,16,2],
[61,53,16,3],
[61,53,16,5],
[61,53,16,6],
[61,53,16,7],
[61,53,16,11],
[61,53,16,12],
[61,53,16,13],
[61,53,16,14],
[61,53,16,15],
[61,53,17,0],
[61,53,17,1],
[61,53,17,2],
[61,53,17,3],
[61,53,17,5],
[61,53,17,6],
[61,53,17,7],
[61,53,17,11],
[61,53,17,12],
[61,53,17,13],
[61,53,17,14],
[61,53,17,15],
[61,53,17,16],
[61,53,18,0],
[61,53,18,1],
[61,53,18,2],
[61,53,18,3],
[61,53,18,5],
[61,53,18,8],
[61,53,18,11],
[61,53,18,12],
[61,53,18,13],
[61,53,18,14],
[61,53,18,16],
[61,53,18,17],
[61,53,19,0],
[61,53,19,1],
[61,53,19,2],
[61,53,19,3],
[61,53,19,6],
[61,53,19,7],
[61,53,19,8],
[61,53,19,9],
[61,53,19,11],
[61,53,19,12],
[61,53,19,15],
[61,53,19,16],
[61,53,19,17],
[61,53,19,18],
[61,53,21,7],
[61,53,21,9],
[61,53,21,11],
[61,53,21,12],
[61,53,21,13],
[61,53,21,14],
[61,53,21,15],
[61,53,21,16],
[61,53,21,17],
[61,53,21,18],
[61,53,21,19],
[61,53,23,0],
[61,53,23,1],
[61,53,23,2],
[61,53,23,3],
[61,53,23,5],
[61,53,23,6],
[61,53,23,7],
[61,53,23,8],
[61,53,23,9],
[61,53,23,11],
[61,53,23,12],
[61,53,23,13],
[61,53,23,14],
[61,53,23,15],
[61,53,23,16],
[61,53,23,17],
[61,53,23,18],
[61,53,24,0],
[61,53,24,1],
[61,53,24,2],
[61,53,24,3],
[61,53,24,5],
[61,53,24,6],
[61,53,24,7],
[61,53,24,8],
[61,53,24,9],
[61,53,24,11],
[61,53,24,12],
[61,53,24,13],
[61,53,24,14],
[61,53,24,15],
[61,53,24,17],
[61,53,24,18],
[61,53,25,0],
[61,53,25,1],
[61,53,25,2],
[61,53,25,3],
[61,53,25,5],
[61,53,25,6],
[61,53,25,8],
[61,53,25,9],
[61,53,25,11],
[61,53,25,12],
[61,53,25,13],
[61,53,25,14],
[61,53,25,15],
[61,53,26,0],
[61,53,26,1],
[61,53,26,2],
[61,53,26,3],
[61,53,26,5],
[61,53,26,6],
[61,53,26,7],
[61,53,26,8],
[61,53,26,11],
[61,53,26,12],
[61,53,26,13],
[61,53,26,14],
[61,53,28,0],
[61,53,28,1],
[61,53,28,2],
[61,53,28,3],
[61,53,28,5],
[61,53,28,6],
[61,53,28,8],
[61,53,28,9],
[61,53,28,11],
[61,53,29,0],
[61,53,29,1],
[61,53,29,2],
[61,53,29,3],
[61,53,29,5],
[61,53,29,6],
[61,53,29,7],
[61,53,29,8],
[61,53,29,9],
[61,53,30,0],
[61,53,30,1],
[61,53,30,2],
[61,53,30,3],
[61,53,30,5],
[61,53,30,6],
[61,53,30,7],
[61,53,31,0],
[61,53,31,1],
[61,53,31,2],
[61,53,31,3],
[61,53,31,5],
[61,53,32,0],
[61,53,32,1],
[61,53,32,2],
[61,53,32,3],
[61,53,33,0],
[61,53,33,1],
[61,53,33,2],
[61,54,3,0],
[61,54,3,1],
[61,54,3,2],
[61,54,5,0],
[61,54,5,1],
[61,54,5,2],
[61,54,6,0],
[61,54,6,1],
[61,54,6,2],
[61,54,7,0],
[61,54,7,1],
[61,54,7,2],
[61,54,7,3],
[61,54,7,5],
[61,54,7,6],
[61,54,8,0],
[61,54,8,1],
[61,54,8,2],
[61,54,8,7],
[61,54,9,0],
[61,54,9,1],
[61,54,9,2],
[61,54,9,3],
[61,54,9,5],
[61,54,9,6],
[61,54,9,7],
[61,54,9,8],
[61,54,11,0],
[61,54,11,1],
[61,54,11,2],
[61,54,11,3],
[61,54,11,5],
[61,54,11,6],
[61,54,11,7],
[61,54,11,8],
[61,54,11,9],
[61,54,12,0],
[61,54,12,1],
[61,54,12,2],
[61,54,12,3],
[61,54,12,5],
[61,54,12,6],
[61,54,12,7],
[61,54,12,8],
[61,54,12,9],
[61,54,13,0],
[61,54,13,1],
[61,54,13,2],
[61,54,13,3],
[61,54,13,5],
[61,54,13,6],
[61,54,13,7],
[61,54,13,8],
[61,54,13,9],
[61,54,13,11],
[61,54,13,12],
[61,54,14,0],
[61,54,14,1],
[61,54,14,2],
[61,54,14,3],
[61,54,14,5],
[61,54,14,6],
[61,54,14,7],
[61,54,14,8],
[61,54,14,9],
[61,54,14,11],
[61,54,15,0],
[61,54,15,1],
[61,54,15,2],
[61,54,15,3],
[61,54,15,5],
[61,54,15,6],
[61,54,15,7],
[61,54,15,8],
[61,54,15,12],
[61,54,15,13],
[61,54,15,14],
[61,54,16,0],
[61,54,16,1],
[61,54,16,2],
[61,54,16,3],
[61,54,16,5],
[61,54,16,6],
[61,54,16,7],
[61,54,16,11],
[61,54,16,12],
[61,54,16,13],
[61,54,16,14],
[61,54,16,15],
[61,54,17,0],
[61,54,17,1],
[61,54,17,2],
[61,54,17,3],
[61,54,17,5],
[61,54,17,6],
[61,54,17,7],
[61,54,17,11],
[61,54,17,12],
[61,54,17,13],
[61,54,17,14],
[61,54,17,15],
[61,54,17,16],
[61,54,18,0],
[61,54,18,1],
[61,54,18,2],
[61,54,18,3],
[61,54,18,5],
[61,54,18,8],
[61,54,18,11],
[61,54,18,12],
[61,54,18,13],
[61,54,18,14],
[61,54,18,16],
[61,54,18,17],
[61,54,19,0],
[61,54,19,1],
[61,54,19,2],
[61,54,19,3],
[61,54,19,6],
[61,54,19,7],
[61,54,19,8],
[61,54,19,9],
[61,54,19,11],
[61,54,19,12],
[61,54,19,15],
[61,54,19,16],
[61,54,19,17],
[61,54,19,18],
[61,54,21,7],
[61,54,21,9],
[61,54,21,11],
[61,54,21,12],
[61,54,21,13],
[61,54,21,14],
[61,54,21,15],
[61,54,21,16],
[61,54,21,17],
[61,54,21,18],
[61,54,21,19],
[61,54,23,0],
[61,54,23,1],
[61,54,23,2],
[61,54,23,3],
[61,54,23,5],
[61,54,23,6],
[61,54,23,7],
[61,54,23,8],
[61,54,23,9],
[61,54,23,11],
[61,54,23,12],
[61,54,23,13],
[61,54,23,14],
[61,54,23,15],
[61,54,23,16],
[61,54,23,17],
[61,54,23,18],
[61,54,24,0],
[61,54,24,1],
[61,54,24,2],
[61,54,24,3],
[61,54,24,5],
[61,54,24,6],
[61,54,24,7],
[61,54,24,8],
[61,54,24,9],
[61,54,24,11],
[61,54,24,12],
[61,54,24,13],
[61,54,24,14],
[61,54,24,15],
[61,54,24,17],
[61,54,24,18],
[61,54,25,0],
[61,54,25,1],
[61,54,25,2],
[61,54,25,3],
[61,54,25,5],
[61,54,25,6],
[61,54,25,8],
[61,54,25,9],
[61,54,25,11],
[61,54,25,12],
[61,54,25,13],
[61,54,25,14],
[61,54,25,15],
[61,54,26,0],
[61,54,26,1],
[61,54,26,2],
[61,54,26,3],
[61,54,26,5],
[61,54,26,6],
[61,54,26,7],
[61,54,26,8],
[61,54,26,11],
[61,54,26,12],
[61,54,26,13],
[61,54,26,14],
[61,54,28,0],
[61,54,28,1],
[61,54,28,2],
[61,54,28,3],
[61,54,28,5],
[61,54,28,6],
[61,54,28,8],
[61,54,28,9],
[61,54,28,11],
[61,54,29,0],
[61,54,29,1],
[61,54,29,2],
[61,54,29,3],
[61,54,29,5],
[61,54,29,6],
[61,54,29,7],
[61,54,29,8],
[61,54,29,9],
[61,54,30,0],
[61,54,30,1],
[61,54,30,2],
[61,54,30,3],
[61,54,30,5],
[61,54,30,6],
[61,54,30,7],
[61,54,31,0],
[61,54,31,1],
[61,54,31,2],
[61,54,31,3],
[61,54,31,5],
[61,54,32,0],
[61,54,32,1],
[61,54,32,2],
[61,54,32,3],
[61,54,33,0],
[61,54,33,1],
[61,54,33,2],
[61,55,3,0],
[61,55,3,1],
[61,55,3,2],
[61,55,5,0],
[61,55,5,1],
[61,55,5,2],
[61,55,6,0],
[61,55,6,1],
[61,55,6,2],
[61,55,7,0],
[61,55,7,1],
[61,55,7,2],
[61,55,7,3],
[61,55,7,5],
[61,55,7,6],
[61,55,8,0],
[61,55,8,1],
[61,55,8,2],
[61,55,8,7],
[61,55,9,0],
[61,55,9,1],
[61,55,9,2],
[61,55,9,3],
[61,55,9,5],
[61,55,9,6],
[61,55,9,7],
[61,55,9,8],
[61,55,11,0],
[61,55,11,1],
[61,55,11,2],
[61,55,11,3],
[61,55,11,5],
[61,55,11,6],
[61,55,11,7],
[61,55,11,8],
[61,55,11,9],
[61,55,12,0],
[61,55,12,1],
[61,55,12,2],
[61,55,12,3],
[61,55,12,5],
[61,55,12,6],
[61,55,12,7],
[61,55,12,8],
[61,55,12,9],
[61,55,13,0],
[61,55,13,1],
[61,55,13,2],
[61,55,13,3],
[61,55,13,5],
[61,55,13,6],
[61,55,13,7],
[61,55,13,8],
[61,55,13,9],
[61,55,13,11],
[61,55,13,12],
[61,55,14,0],
[61,55,14,1],
[61,55,14,2],
[61,55,14,3],
[61,55,14,5],
[61,55,14,6],
[61,55,14,7],
[61,55,14,8],
[61,55,14,9],
[61,55,14,11],
[61,55,15,0],
[61,55,15,1],
[61,55,15,2],
[61,55,15,3],
[61,55,15,5],
[61,55,15,6],
[61,55,15,7],
[61,55,15,8],
[61,55,15,12],
[61,55,15,13],
[61,55,15,14],
[61,55,16,0],
[61,55,16,1],
[61,55,16,2],
[61,55,16,3],
[61,55,16,5],
[61,55,16,6],
[61,55,16,7],
[61,55,16,11],
[61,55,16,12],
[61,55,16,13],
[61,55,16,14],
[61,55,16,15],
[61,55,17,0],
[61,55,17,1],
[61,55,17,2],
[61,55,17,3],
[61,55,17,5],
[61,55,17,6],
[61,55,17,7],
[61,55,17,11],
[61,55,17,12],
[61,55,17,13],
[61,55,17,14],
[61,55,17,15],
[61,55,17,16],
[61,55,18,0],
[61,55,18,1],
[61,55,18,2],
[61,55,18,3],
[61,55,18,5],
[61,55,18,8],
[61,55,18,11],
[61,55,18,12],
[61,55,18,13],
[61,55,18,14],
[61,55,18,16],
[61,55,18,17],
[61,55,19,0],
[61,55,19,1],
[61,55,19,2],
[61,55,19,3],
[61,55,19,6],
[61,55,19,7],
[61,55,19,8],
[61,55,19,9],
[61,55,19,11],
[61,55,19,12],
[61,55,19,15],
[61,55,19,16],
[61,55,19,17],
[61,55,19,18],
[61,55,21,7],
[61,55,21,9],
[61,55,21,11],
[61,55,21,12],
[61,55,21,13],
[61,55,21,14],
[61,55,21,15],
[61,55,21,16],
[61,55,21,17],
[61,55,21,18],
[61,55,21,19],
[61,55,23,0],
[61,55,23,1],
[61,55,23,2],
[61,55,23,3],
[61,55,23,5],
[61,55,23,6],
[61,55,23,7],
[61,55,23,8],
[61,55,23,9],
[61,55,23,11],
[61,55,23,12],
[61,55,23,13],
[61,55,23,14],
[61,55,23,15],
[61,55,23,16],
[61,55,23,17],
[61,55,23,18],
[61,55,24,0],
[61,55,24,1],
[61,55,24,2],
[61,55,24,3],
[61,55,24,5],
[61,55,24,6],
[61,55,24,7],
[61,55,24,8],
[61,55,24,9],
[61,55,24,11],
[61,55,24,12],
[61,55,24,13],
[61,55,24,14],
[61,55,24,15],
[61,55,24,17],
[61,55,24,18],
[61,55,25,0],
[61,55,25,1],
[61,55,25,2],
[61,55,25,3],
[61,55,25,5],
[61,55,25,6],
[61,55,25,8],
[61,55,25,9],
[61,55,25,11],
[61,55,25,12],
[61,55,25,13],
[61,55,25,14],
[61,55,25,15],
[61,55,26,0],
[61,55,26,1],
[61,55,26,2],
[61,55,26,3],
[61,55,26,5],
[61,55,26,6],
[61,55,26,7],
[61,55,26,8],
[61,55,26,11],
[61,55,26,12],
[61,55,26,13],
[61,55,26,14],
[61,55,28,0],
[61,55,28,1],
[61,55,28,2],
[61,55,28,3],
[61,55,28,5],
[61,55,28,6],
[61,55,28,8],
[61,55,28,9],
[61,55,28,11],
[61,55,29,0],
[61,55,29,1],
[61,55,29,2],
[61,55,29,3],
[61,55,29,5],
[61,55,29,6],
[61,55,29,7],
[61,55,29,8],
[61,55,29,9],
[61,55,30,0],
[61,55,30,1],
[61,55,30,2],
[61,55,30,3],
[61,55,30,5],
[61,55,30,6],
[61,55,30,7],
[61,55,31,0],
[61,55,31,1],
[61,55,31,2],
[61,55,31,3],
[61,55,31,5],
[61,55,32,0],
[61,55,32,1],
[61,55,32,2],
[61,55,32,3],
[61,55,33,0],
[61,55,33,1],
[61,55,33,2],
[61,56,3,0],
[61,56,3,1],
[61,56,3,2],
[61,56,5,0],
[61,56,5,1],
[61,56,5,2],
[61,56,6,0],
[61,56,6,1],
[61,56,6,2],
[61,56,7,0],
[61,56,7,1],
[61,56,7,2],
[61,56,7,3],
[61,56,7,5],
[61,56,7,6],
[61,56,8,0],
[61,56,8,1],
[61,56,8,2],
[61,56,8,7],
[61,56,10,0],
[61,56,10,1],
[61,56,10,2],
[61,56,10,3],
[61,56,10,5],
[61,56,10,6],
[61,56,10,7],
[61,56,10,8],
[61,56,11,0],
[61,56,11,1],
[61,56,11,2],
[61,56,11,3],
[61,56,11,5],
[61,56,11,6],
[61,56,11,7],
[61,56,11,8],
[61,56,11,10],
[61,56,12,0],
[61,56,12,1],
[61,56,12,2],
[61,56,12,3],
[61,56,12,5],
[61,56,12,6],
[61,56,12,7],
[61,56,12,8],
[61,56,12,10],
[61,56,13,0],
[61,56,13,1],
[61,56,13,2],
[61,56,13,3],
[61,56,13,5],
[61,56,13,6],
[61,56,13,7],
[61,56,13,8],
[61,56,13,10],
[61,56,13,11],
[61,56,13,12],
[61,56,14,0],
[61,56,14,1],
[61,56,14,2],
[61,56,14,3],
[61,56,14,5],
[61,56,14,6],
[61,56,14,7],
[61,56,14,8],
[61,56,14,10],
[61,56,14,11],
[61,56,16,0],
[61,56,16,1],
[61,56,16,2],
[61,56,16,3],
[61,56,16,5],
[61,56,16,6],
[61,56,16,7],
[61,56,16,10],
[61,56,16,11],
[61,56,16,12],
[61,56,16,13],
[61,56,16,14],
[61,56,17,0],
[61,56,17,1],
[61,56,17,2],
[61,56,17,3],
[61,56,17,5],
[61,56,17,6],
[61,56,17,7],
[61,56,17,10],
[61,56,17,11],
[61,56,17,12],
[61,56,17,13],
[61,56,17,14],
[61,56,17,16],
[61,56,19,0],
[61,56,19,1],
[61,56,19,2],
[61,56,19,3],
[61,56,19,6],
[61,56,19,7],
[61,56,19,8],
[61,56,19,10],
[61,56,19,11],
[61,56,19,12],
[61,56,19,16],
[61,56,19,17],
[61,56,20,0],
[61,56,20,1],
[61,56,20,2],
[61,56,20,5],
[61,56,20,6],
[61,56,20,7],
[61,56,20,8],
[61,56,20,11],
[61,56,20,12],
[61,56,20,13],
[61,56,20,14],
[61,56,20,16],
[61,56,20,17],
[61,56,20,19],
[61,56,21,7],
[61,56,21,10],
[61,56,21,11],
[61,56,21,12],
[61,56,21,13],
[61,56,21,14],
[61,56,21,16],
[61,56,21,17],
[61,56,21,19],
[61,56,21,20],
[61,56,22,0],
[61,56,22,1],
[61,56,22,2],
[61,56,22,3],
[61,56,22,5],
[61,56,22,6],
[61,56,22,7],
[61,56,22,8],
[61,56,22,11],
[61,56,22,12],
[61,56,22,13],
[61,56,22,14],
[61,56,22,16],
[61,56,22,17],
[61,56,22,19],
[61,56,23,0],
[61,56,23,1],
[61,56,23,2],
[61,56,23,3],
[61,56,23,5],
[61,56,23,6],
[61,56,23,7],
[61,56,23,8],
[61,56,23,10],
[61,56,23,11],
[61,56,23,12],
[61,56,23,13],
[61,56,23,14],
[61,56,23,16],
[61,56,23,17],
[61,56,24,0],
[61,56,24,1],
[61,56,24,2],
[61,56,24,3],
[61,56,24,5],
[61,56,24,6],
[61,56,24,7],
[61,56,24,8],
[61,56,24,10],
[61,56,24,11],
[61,56,24,12],
[61,56,24,13],
[61,56,24,14],
[61,56,24,17],
[61,56,25,0],
[61,56,25,1],
[61,56,25,2],
[61,56,25,3],
[61,56,25,5],
[61,56,25,6],
[61,56,25,8],
[61,56,25,10],
[61,56,25,11],
[61,56,25,12],
[61,56,25,13],
[61,56,25,14],
[61,56,27,0],
[61,56,27,1],
[61,56,27,2],
[61,56,27,3],
[61,56,27,5],
[61,56,27,6],
[61,56,27,7],
[61,56,27,8],
[61,56,27,11],
[61,56,27,12],
[61,56,27,13],
[61,56,28,0],
[61,56,28,1],
[61,56,28,2],
[61,56,28,3],
[61,56,28,5],
[61,56,28,6],
[61,56,28,8],
[61,56,28,10],
[61,56,28,11],
[61,56,29,0],
[61,56,29,1],
[61,56,29,2],
[61,56,29,3],
[61,56,29,5],
[61,56,29,6],
[61,56,29,7],
[61,56,29,8],
[61,56,30,0],
[61,56,30,1],
[61,56,30,2],
[61,56,30,3],
[61,56,30,5],
[61,56,30,6],
[61,56,30,7],
[61,56,31,0],
[61,56,31,1],
[61,56,31,2],
[61,56,31,3],
[61,56,31,5],
[61,56,32,0],
[61,56,32,1],
[61,56,32,2],
[61,56,32,3],
[61,56,33,0],
[61,56,33,1],
[61,56,33,2],
[61,57,3,0],
[61,57,3,1],
[61,57,3,2],
[61,57,5,0],
[61,57,5,1],
[61,57,5,2],
[61,57,6,0],
[61,57,6,1],
[61,57,6,2],
[61,57,7,0],
[61,57,7,1],
[61,57,7,2],
[61,57,7,3],
[61,57,7,5],
[61,57,7,6],
[61,57,8,0],
[61,57,8,1],
[61,57,8,2],
[61,57,8,7],
[61,57,9,0],
[61,57,9,1],
[61,57,9,2],
[61,57,9,3],
[61,57,9,5],
[61,57,9,6],
[61,57,9,7],
[61,57,9,8],
[61,57,10,0],
[61,57,10,1],
[61,57,10,2],
[61,57,10,3],
[61,57,10,5],
[61,57,10,6],
[61,57,10,7],
[61,57,10,8],
[61,57,10,9],
[61,57,11,0],
[61,57,11,1],
[61,57,11,2],
[61,57,11,3],
[61,57,11,5],
[61,57,11,6],
[61,57,11,7],
[61,57,11,8],
[61,57,11,9],
[61,57,11,10],
[61,57,12,0],
[61,57,12,1],
[61,57,12,2],
[61,57,12,3],
[61,57,12,5],
[61,57,12,6],
[61,57,12,7],
[61,57,12,8],
[61,57,12,9],
[61,57,12,10],
[61,57,13,0],
[61,57,13,1],
[61,57,13,2],
[61,57,13,3],
[61,57,13,5],
[61,57,13,6],
[61,57,13,7],
[61,57,13,8],
[61,57,13,9],
[61,57,13,10],
[61,57,13,11],
[61,57,14,0],
[61,57,14,1],
[61,57,14,2],
[61,57,14,3],
[61,57,14,5],
[61,57,14,6],
[61,57,14,7],
[61,57,14,8],
[61,57,14,9],
[61,57,14,12],
[61,57,15,0],
[61,57,15,1],
[61,57,15,2],
[61,57,15,3],
[61,57,15,5],
[61,57,15,6],
[61,57,15,7],
[61,57,15,10],
[61,57,15,11],
[61,57,15,12],
[61,57,15,13],
[61,57,15,14],
[61,57,16,0],
[61,57,16,1],
[61,57,16,2],
[61,57,16,3],
[61,57,16,5],
[61,57,16,8],
[61,57,16,9],
[61,57,16,10],
[61,57,16,11],
[61,57,16,12],
[61,57,16,13],
[61,57,16,14],
[61,57,16,15],
[61,57,17,0],
[61,57,17,1],
[61,57,17,2],
[61,57,17,3],
[61,57,17,5],
[61,57,17,8],
[61,57,17,9],
[61,57,17,10],
[61,57,17,11],
[61,57,17,12],
[61,57,17,13],
[61,57,17,14],
[61,57,17,15],
[61,57,17,16],
[61,57,18,0],
[61,57,18,1],
[61,57,18,2],
[61,57,18,3],
[61,57,18,6],
[61,57,18,7],
[61,57,18,8],
[61,57,18,10],
[61,57,18,11],
[61,57,18,12],
[61,57,18,13],
[61,57,18,14],
[61,57,18,16],
[61,57,18,17],
[61,57,19,0],
[61,57,19,1],
[61,57,19,2],
[61,57,19,5],
[61,57,19,6],
[61,57,19,7],
[61,57,19,8],
[61,57,19,9],
[61,57,19,10],
[61,57,19,11],
[61,57,19,12],
[61,57,19,15],
[61,57,19,16],
[61,57,19,17],
[61,57,19,18],
[61,57,20,3],
[61,57,20,5],
[61,57,20,6],
[61,57,20,7],
[61,57,20,8],
[61,57,20,9],
[61,57,20,11],
[61,57,20,12],
[61,57,20,13],
[61,57,20,14],
[61,57,20,15],
[61,57,20,16],
[61,57,20,17],
[61,57,20,18],
[61,57,20,19],
[61,57,21,0],
[61,57,21,1],
[61,57,21,2],
[61,57,21,7],
[61,57,21,9],
[61,57,21,10],
[61,57,21,11],
[61,57,21,12],
[61,57,21,13],
[61,57,21,14],
[61,57,21,15],
[61,57,21,16],
[61,57,21,17],
[61,57,21,18],
[61,57,21,19],
[61,57,22,0],
[61,57,22,1],
[61,57,22,2],
[61,57,22,3],
[61,57,22,5],
[61,57,22,6],
[61,57,22,7],
[61,57,22,8],
[61,57,22,9],
[61,57,22,11],
[61,57,22,12],
[61,57,22,13],
[61,57,22,14],
[61,57,22,15],
[61,57,22,16],
[61,57,22,17],
[61,57,22,18],
[61,57,24,0],
[61,57,24,1],
[61,57,24,2],
[61,57,24,3],
[61,57,24,5],
[61,57,24,6],
[61,57,24,7],
[61,57,24,8],
[61,57,24,9],
[61,57,24,10],
[61,57,24,11],
[61,57,24,12],
[61,57,24,13],
[61,57,24,14],
[61,57,24,15],
[61,57,24,17],
[61,57,25,0],
[61,57,25,1],
[61,57,25,2],
[61,57,25,3],
[61,57,25,5],
[61,57,25,6],
[61,57,25,8],
[61,57,25,9],
[61,57,25,10],
[61,57,25,11],
[61,57,25,12],
[61,57,25,13],
[61,57,25,14],
[61,57,26,0],
[61,57,26,1],
[61,57,26,2],
[61,57,26,3],
[61,57,26,5],
[61,57,26,6],
[61,57,26,7],
[61,57,26,8],
[61,57,26,10],
[61,57,26,11],
[61,57,26,12],
[61,57,26,13],
[61,57,27,0],
[61,57,27,1],
[61,57,27,2],
[61,57,27,3],
[61,57,27,5],
[61,57,27,6],
[61,57,27,7],
[61,57,27,8],
[61,57,27,9],
[61,57,27,11],
[61,57,28,0],
[61,57,28,1],
[61,57,28,2],
[61,57,28,3],
[61,57,28,5],
[61,57,28,6],
[61,57,28,8],
[61,57,28,9],
[61,57,29,0],
[61,57,29,1],
[61,57,29,2],
[61,57,29,3],
[61,57,29,5],
[61,57,29,6],
[61,57,29,7],
[61,57,32,0],
[61,57,32,1],
[61,57,32,2],
[61,58,3,0],
[61,58,3,1],
[61,58,3,2],
[61,58,5,0],
[61,58,5,1],
[61,58,5,2],
[61,58,6,0],
[61,58,6,1],
[61,58,6,2],
[61,58,7,0],
[61,58,7,1],
[61,58,7,2],
[61,58,7,3],
[61,58,7,5],
[61,58,7,6],
[61,58,8,0],
[61,58,8,1],
[61,58,8,2],
[61,58,8,7],
[61,58,9,0],
[61,58,9,1],
[61,58,9,2],
[61,58,9,3],
[61,58,9,5],
[61,58,9,6],
[61,58,9,7],
[61,58,9,8],
[61,58,10,0],
[61,58,10,1],
[61,58,10,2],
[61,58,10,3],
[61,58,10,5],
[61,58,10,6],
[61,58,10,7],
[61,58,10,8],
[61,58,10,9],
[61,58,11,0],
[61,58,11,1],
[61,58,11,2],
[61,58,11,3],
[61,58,11,5],
[61,58,11,6],
[61,58,11,7],
[61,58,11,8],
[61,58,11,9],
[61,58,11,10],
[61,58,12,0],
[61,58,12,1],
[61,58,12,2],
[61,58,12,3],
[61,58,12,5],
[61,58,12,6],
[61,58,12,7],
[61,58,12,8],
[61,58,12,9],
[61,58,12,10],
[61,58,13,0],
[61,58,13,1],
[61,58,13,2],
[61,58,13,3],
[61,58,13,5],
[61,58,13,6],
[61,58,13,7],
[61,58,13,8],
[61,58,13,9],
[61,58,13,10],
[61,58,13,11],
[61,58,14,0],
[61,58,14,1],
[61,58,14,2],
[61,58,14,3],
[61,58,14,5],
[61,58,14,6],
[61,58,14,7],
[61,58,14,8],
[61,58,14,9],
[61,58,14,12],
[61,58,15,0],
[61,58,15,1],
[61,58,15,2],
[61,58,15,3],
[61,58,15,5],
[61,58,15,6],
[61,58,15,7],
[61,58,15,10],
[61,58,15,11],
[61,58,15,12],
[61,58,15,13],
[61,58,15,14],
[61,58,17,0],
[61,58,17,1],
[61,58,17,2],
[61,58,17,3],
[61,58,17,5],
[61,58,17,8],
[61,58,17,9],
[61,58,17,10],
[61,58,17,11],
[61,58,17,12],
[61,58,17,13],
[61,58,17,14],
[61,58,17,15],
[61,58,18,0],
[61,58,18,1],
[61,58,18,2],
[61,58,18,3],
[61,58,18,6],
[61,58,18,7],
[61,58,18,8],
[61,58,18,10],
[61,58,18,11],
[61,58,18,12],
[61,58,18,13],
[61,58,18,14],
[61,58,18,17],
[61,58,19,0],
[61,58,19,1],
[61,58,19,2],
[61,58,19,5],
[61,58,19,6],
[61,58,19,7],
[61,58,19,8],
[61,58,19,9],
[61,58,19,10],
[61,58,19,11],
[61,58,19,12],
[61,58,19,15],
[61,58,19,17],
[61,58,19,18],
[61,58,20,3],
[61,58,20,5],
[61,58,20,6],
[61,58,20,7],
[61,58,20,8],
[61,58,20,9],
[61,58,20,11],
[61,58,20,12],
[61,58,20,13],
[61,58,20,14],
[61,58,20,15],
[61,58,20,17],
[61,58,20,18],
[61,58,20,19],
[61,58,21,0],
[61,58,21,1],
[61,58,21,2],
[61,58,21,7],
[61,58,21,9],
[61,58,21,10],
[61,58,21,11],
[61,58,21,12],
[61,58,21,13],
[61,58,21,14],
[61,58,21,15],
[61,58,21,17],
[61,58,21,18],
[61,58,21,19],
[61,58,22,0],
[61,58,22,1],
[61,58,22,2],
[61,58,22,3],
[61,58,22,5],
[61,58,22,6],
[61,58,22,7],
[61,58,22,8],
[61,58,22,9],
[61,58,22,11],
[61,58,22,12],
[61,58,22,13],
[61,58,22,14],
[61,58,22,15],
[61,58,22,17],
[61,58,22,18],
[61,58,23,0],
[61,58,23,1],
[61,58,23,2],
[61,58,23,3],
[61,58,23,5],
[61,58,23,6],
[61,58,23,7],
[61,58,23,8],
[61,58,23,9],
[61,58,23,10],
[61,58,23,11],
[61,58,23,12],
[61,58,23,13],
[61,58,23,14],
[61,58,23,15],
[61,58,23,17],
[61,58,25,0],
[61,58,25,1],
[61,58,25,2],
[61,58,25,3],
[61,58,25,5],
[61,58,25,6],
[61,58,25,8],
[61,58,25,9],
[61,58,25,10],
[61,58,25,11],
[61,58,25,12],
[61,58,25,13],
[61,58,25,14],
[61,58,26,0],
[61,58,26,1],
[61,58,26,2],
[61,58,26,3],
[61,58,26,5],
[61,58,26,6],
[61,58,26,7],
[61,58,26,8],
[61,58,26,10],
[61,58,26,11],
[61,58,26,12],
[61,58,26,13],
[61,58,27,0],
[61,58,27,1],
[61,58,27,2],
[61,58,27,3],
[61,58,27,5],
[61,58,27,6],
[61,58,27,7],
[61,58,27,8],
[61,58,27,9],
[61,58,27,11],
[61,58,28,0],
[61,58,28,1],
[61,58,28,2],
[61,58,28,3],
[61,58,28,5],
[61,58,28,6],
[61,58,28,8],
[61,58,28,9],
[61,58,29,0],
[61,58,29,1],
[61,58,29,2],
[61,58,29,3],
[61,58,29,5],
[61,58,29,6],
[61,58,29,7],
[61,58,30,0],
[61,58,30,1],
[61,58,30,2],
[61,58,30,3],
[61,58,30,5],
[61,58,31,0],
[61,58,31,1],
[61,58,31,2],
[61,58,31,3],
[61,58,32,0],
[61,58,32,1],
[61,58,32,2],
[61,59,3,0],
[61,59,3,1],
[61,59,3,2],
[61,59,5,0],
[61,59,5,1],
[61,59,5,2],
[61,59,6,0],
[61,59,6,1],
[61,59,6,2],
[61,59,7,0],
[61,59,7,1],
[61,59,7,2],
[61,59,7,3],
[61,59,7,5],
[61,59,7,6],
[61,59,8,0],
[61,59,8,1],
[61,59,8,2],
[61,59,8,7],
[61,59,10,0],
[61,59,10,1],
[61,59,10,2],
[61,59,10,3],
[61,59,10,5],
[61,59,10,6],
[61,59,10,7],
[61,59,10,8],
[61,59,11,0],
[61,59,11,1],
[61,59,11,2],
[61,59,11,3],
[61,59,11,5],
[61,59,11,6],
[61,59,11,7],
[61,59,11,8],
[61,59,11,10],
[61,59,12,0],
[61,59,12,1],
[61,59,12,2],
[61,59,12,3],
[61,59,12,5],
[61,59,12,6],
[61,59,12,7],
[61,59,12,8],
[61,59,13,0],
[61,59,13,1],
[61,59,13,2],
[61,59,13,3],
[61,59,13,5],
[61,59,13,6],
[61,59,13,7],
[61,59,13,8],
[61,59,13,12],
[61,59,14,0],
[61,59,14,1],
[61,59,14,2],
[61,59,14,3],
[61,59,14,5],
[61,59,14,6],
[61,59,14,7],
[61,59,14,10],
[61,59,14,11],
[61,59,14,12],
[61,59,16,0],
[61,59,16,1],
[61,59,16,2],
[61,59,16,3],
[61,59,16,6],
[61,59,16,7],
[61,59,16,8],
[61,59,16,10],
[61,59,16,11],
[61,59,16,12],
[61,59,16,13],
[61,59,16,14],
[61,59,17,0],
[61,59,17,1],
[61,59,17,2],
[61,59,17,3],
[61,59,17,6],
[61,59,17,7],
[61,59,17,8],
[61,59,17,10],
[61,59,17,11],
[61,59,17,12],
[61,59,17,13],
[61,59,17,14],
[61,59,17,16],
[61,59,19,3],
[61,59,19,5],
[61,59,19,6],
[61,59,19,7],
[61,59,19,8],
[61,59,19,10],
[61,59,19,11],
[61,59,19,12],
[61,59,19,16],
[61,59,19,17],
[61,59,20,0],
[61,59,20,1],
[61,59,20,2],
[61,59,20,3],
[61,59,20,5],
[61,59,20,6],
[61,59,20,7],
[61,59,20,8],
[61,59,20,11],
[61,59,20,12],
[61,59,20,13],
[61,59,20,14],
[61,59,20,16],
[61,59,20,17],
[61,59,20,19],
[61,59,21,0],
[61,59,21,1],
[61,59,21,2],
[61,59,21,7],
[61,59,21,10],
[61,59,21,11],
[61,59,21,12],
[61,59,21,13],
[61,59,21,14],
[61,59,21,16],
[61,59,21,17],
[61,59,22,0],
[61,59,22,1],
[61,59,22,2],
[61,59,22,3],
[61,59,22,5],
[61,59,22,6],
[61,59,22,7],
[61,59,22,8],
[61,59,22,11],
[61,59,22,12],
[61,59,22,13],
[61,59,22,14],
[61,59,22,16],
[61,59,22,17],
[61,59,23,0],
[61,59,23,1],
[61,59,23,2],
[61,59,23,3],
[61,59,23,5],
[61,59,23,6],
[61,59,23,7],
[61,59,23,8],
[61,59,23,10],
[61,59,23,11],
[61,59,23,12],
[61,59,23,13],
[61,59,23,14],
[61,59,24,0],
[61,59,24,1],
[61,59,24,2],
[61,59,24,3],
[61,59,24,5],
[61,59,24,6],
[61,59,24,7],
[61,59,24,8],
[61,59,24,10],
[61,59,24,11],
[61,59,24,12],
[61,59,24,13],
[61,59,24,14],
[61,59,25,0],
[61,59,25,1],
[61,59,25,2],
[61,59,25,3],
[61,59,25,5],
[61,59,25,6],
[61,59,25,8],
[61,59,25,10],
[61,59,25,11],
[61,59,25,12],
[61,59,25,13],
[61,59,27,0],
[61,59,27,1],
[61,59,27,2],
[61,59,27,3],
[61,59,27,5],
[61,59,27,6],
[61,59,27,7],
[61,59,27,8],
[61,59,28,0],
[61,59,28,1],
[61,59,28,2],
[61,59,28,3],
[61,59,28,5],
[61,59,28,6],
[61,59,29,0],
[61,59,29,1],
[61,59,29,2],
[61,59,29,3],
[61,59,29,5],
[61,59,30,0],
[61,59,30,1],
[61,59,30,2],
[61,59,30,3],
[61,59,31,0],
[61,59,31,1],
[61,59,31,2],
[61,60,3,0],
[61,60,3,1],
[61,60,3,2],
[61,60,5,0],
[61,60,5,1],
[61,60,5,2],
[61,60,6,0],
[61,60,6,1],
[61,60,6,2],
[61,60,7,0],
[61,60,7,1],
[61,60,7,2],
[61,60,7,3],
[61,60,7,5],
[61,60,7,6],
[61,60,8,0],
[61,60,8,1],
[61,60,8,2],
[61,60,8,7],
[61,60,9,0],
[61,60,9,1],
[61,60,9,2],
[61,60,9,3],
[61,60,9,5],
[61,60,9,6],
[61,60,9,7],
[61,60,9,8],
[61,60,10,0],
[61,60,10,1],
[61,60,10,2],
[61,60,10,3],
[61,60,10,5],
[61,60,10,6],
[61,60,10,7],
[61,60,10,8],
[61,60,10,9],
[61,60,11,0],
[61,60,11,1],
[61,60,11,2],
[61,60,11,3],
[61,60,11,5],
[61,60,11,6],
[61,60,11,7],
[61,60,11,8],
[61,60,11,9],
[61,60,12,0],
[61,60,12,1],
[61,60,12,2],
[61,60,12,3],
[61,60,12,5],
[61,60,12,6],
[61,60,12,7],
[61,60,12,10],
[61,60,15,0],
[61,60,15,1],
[61,60,15,2],
[61,60,15,3],
[61,60,15,6],
[61,60,15,7],
[61,60,15,8],
[61,60,15,10],
[61,60,15,11],
[61,60,15,12],
[61,60,16,0],
[61,60,16,1],
[61,60,16,2],
[61,60,16,5],
[61,60,16,6],
[61,60,16,7],
[61,60,16,8],
[61,60,16,9],
[61,60,16,10],
[61,60,16,11],
[61,60,16,12],
[61,60,16,15],
[61,60,17,0],
[61,60,17,1],
[61,60,17,2],
[61,60,17,5],
[61,60,17,6],
[61,60,17,7],
[61,60,17,8],
[61,60,17,9],
[61,60,17,10],
[61,60,17,11],
[61,60,17,12],
[61,60,17,15],
[61,60,17,16],
[61,60,18,3],
[61,60,18,5],
[61,60,18,6],
[61,60,18,7],
[61,60,18,8],
[61,60,18,10],
[61,60,18,11],
[61,60,18,12],
[61,60,18,16],
[61,60,18,17],
[61,60,20,0],
[61,60,20,1],
[61,60,20,2],
[61,60,20,3],
[61,60,20,5],
[61,60,20,6],
[61,60,20,7],
[61,60,20,8],
[61,60,20,9],
[61,60,20,11],
[61,60,20,12],
[61,60,20,15],
[61,60,20,16],
[61,60,20,17],
[61,60,20,18],
[61,60,21,0],
[61,60,21,1],
[61,60,21,2],
[61,60,21,7],
[61,60,21,9],
[61,60,21,10],
[61,60,21,11],
[61,60,21,12],
[61,60,21,15],
[61,60,21,16],
[61,60,21,17],
[61,60,22,0],
[61,60,22,1],
[61,60,22,2],
[61,60,22,3],
[61,60,22,5],
[61,60,22,6],
[61,60,22,7],
[61,60,22,8],
[61,60,22,9],
[61,60,22,11],
[61,60,22,12],
[61,60,22,15],
[61,60,23,0],
[61,60,23,1],
[61,60,23,2],
[61,60,23,3],
[61,60,23,5],
[61,60,23,6],
[61,60,23,7],
[61,60,23,8],
[61,60,23,9],
[61,60,23,10],
[61,60,23,11],
[61,60,23,12],
[61,60,24,0],
[61,60,24,1],
[61,60,24,2],
[61,60,24,3],
[61,60,24,5],
[61,60,24,6],
[61,60,24,7],
[61,60,24,8],
[61,60,24,9],
[61,60,24,10],
[61,60,24,11],
[61,60,24,12],
[61,60,25,0],
[61,60,25,1],
[61,60,25,2],
[61,60,25,3],
[61,60,25,5],
[61,60,25,6],
[61,60,25,8],
[61,60,25,9],
[61,60,25,10],
[61,60,25,11],
[61,60,26,0],
[61,60,26,1],
[61,60,26,2],
[61,60,26,3],
[61,60,26,5],
[61,60,26,6],
[61,60,26,7],
[61,60,26,8],
[61,60,27,0],
[61,60,27,1],
[61,60,27,2],
[61,60,27,3],
[61,60,27,5],
[61,60,27,6],
[61,60,27,7],
[61,60,28,0],
[61,60,28,1],
[61,60,28,2],
[61,60,28,3],
[61,60,28,5],
[61,60,29,0],
[61,60,29,1],
[61,60,29,2],
[61,60,29,3],
[61,60,30,0],
[61,60,30,1],
[61,60,30,2],
[62,4,3,0],
[62,4,3,1],
[62,4,3,2],
[62,5,4,0],
[62,5,4,1],
[62,5,4,2],
[62,6,4,0],
[62,6,4,1],
[62,6,4,2],
[62,8,4,0],
[62,8,4,1],
[62,8,4,2],
[62,9,3,0],
[62,9,3,1],
[62,9,3,2],
[62,9,4,0],
[62,9,4,1],
[62,9,4,2],
[62,9,4,3],
[62,9,5,0],
[62,9,5,1],
[62,9,5,2],
[62,9,5,4],
[62,9,6,0],
[62,9,6,1],
[62,9,6,2],
[62,9,6,4],
[62,9,8,0],
[62,9,8,1],
[62,9,8,2],
[62,9,8,4],
[62,10,3,0],
[62,10,3,1],
[62,10,3,2],
[62,10,4,0],
[62,10,4,1],
[62,10,4,2],
[62,10,4,3],
[62,10,5,0],
[62,10,5,1],
[62,10,5,2],
[62,10,5,4],
[62,10,6,0],
[62,10,6,1],
[62,10,6,2],
[62,10,6,4],
[62,10,8,0],
[62,10,8,1],
[62,10,8,2],
[62,10,8,4],
[62,10,9,0],
[62,10,9,1],
[62,10,9,2],
[62,10,9,3],
[62,10,9,4],
[62,10,9,5],
[62,10,9,6],
[62,10,9,8],
[62,11,3,0],
[62,11,3,1],
[62,11,3,2],
[62,11,4,0],
[62,11,4,1],
[62,11,4,2],
[62,11,4,3],
[62,11,5,0],
[62,11,5,1],
[62,11,5,2],
[62,11,5,4],
[62,11,6,0],
[62,11,6,1],
[62,11,6,2],
[62,11,6,4],
[62,11,8,0],
[62,11,8,1],
[62,11,8,2],
[62,11,8,4],
[62,11,9,0],
[62,11,9,1],
[62,11,9,2],
[62,11,9,3],
[62,11,9,4],
[62,11,9,5],
[62,11,9,6],
[62,11,9,8],
[62,11,10,0],
[62,11,10,1],
[62,11,10,2],
[62,11,10,3],
[62,11,10,4],
[62,11,10,5],
[62,11,10,6],
[62,11,10,8],
[62,11,10,9],
[62,12,3,0],
[62,12,3,1],
[62,12,3,2],
[62,12,4,0],
[62,12,4,1],
[62,12,4,2],
[62,12,4,3],
[62,12,5,0],
[62,12,5,1],
[62,12,5,2],
[62,12,5,4],
[62,12,6,0],
[62,12,6,1],
[62,12,6,2],
[62,12,6,4],
[62,12,8,0],
[62,12,8,1],
[62,12,8,2],
[62,12,8,4],
[62,12,9,0],
[62,12,9,1],
[62,12,9,2],
[62,12,9,3],
[62,12,9,4],
[62,12,9,5],
[62,12,9,6],
[62,12,9,8],
[62,12,10,0],
[62,12,10,1],
[62,12,10,2],
[62,12,10,3],
[62,12,10,4],
[62,12,10,5],
[62,12,10,6],
[62,12,10,8],
[62,12,10,9],
[62,13,3,0],
[62,13,3,1],
[62,13,3,2],
[62,13,4,0],
[62,13,4,1],
[62,13,4,2],
[62,13,4,3],
[62,13,5,0],
[62,13,5,1],
[62,13,5,2],
[62,13,5,4],
[62,13,6,0],
[62,13,6,1],
[62,13,6,2],
[62,13,6,4],
[62,13,8,0],
[62,13,8,1],
[62,13,8,2],
[62,13,8,4],
[62,13,9,0],
[62,13,9,1],
[62,13,9,2],
[62,13,9,3],
[62,13,9,4],
[62,13,9,5],
[62,13,9,6],
[62,13,9,8],
[62,13,10,0],
[62,13,10,1],
[62,13,10,2],
[62,13,10,3],
[62,13,10,4],
[62,13,10,5],
[62,13,10,6],
[62,13,10,8],
[62,13,10,9],
[62,13,11,0],
[62,13,11,1],
[62,13,11,2],
[62,13,11,3],
[62,13,11,4],
[62,13,11,5],
[62,13,11,6],
[62,13,11,8],
[62,13,11,9],
[62,13,11,10],
[62,13,12,0],
[62,13,12,1],
[62,13,12,2],
[62,13,12,3],
[62,13,12,4],
[62,13,12,5],
[62,13,12,6],
[62,13,12,8],
[62,13,12,9],
[62,13,12,10],
[62,14,3,0],
[62,14,3,1],
[62,14,3,2],
[62,14,4,0],
[62,14,4,1],
[62,14,4,2],
[62,14,4,3],
[62,14,5,0],
[62,14,5,1],
[62,14,5,2],
[62,14,5,4],
[62,14,6,0],
[62,14,6,1],
[62,14,6,2],
[62,14,6,4],
[62,14,8,0],
[62,14,8,1],
[62,14,8,2],
[62,14,8,4],
[62,14,9,0],
[62,14,9,1],
[62,14,9,2],
[62,14,9,3],
[62,14,9,4],
[62,14,9,5],
[62,14,9,6],
[62,14,9,8],
[62,14,10,0],
[62,14,10,1],
[62,14,10,2],
[62,14,10,3],
[62,14,10,4],
[62,14,10,5],
[62,14,10,6],
[62,14,10,8],
[62,14,10,9],
[62,14,11,0],
[62,14,11,1],
[62,14,11,2],
[62,14,11,3],
[62,14,11,4],
[62,14,11,5],
[62,14,11,6],
[62,14,11,8],
[62,14,11,9],
[62,14,11,10],
[62,14,12,0],
[62,14,12,1],
[62,14,12,2],
[62,14,12,3],
[62,14,12,4],
[62,14,12,5],
[62,14,12,6],
[62,14,12,8],
[62,14,12,9],
[62,14,12,10],
[62,15,3,0],
[62,15,3,1],
[62,15,3,2],
[62,15,4,0],
[62,15,4,1],
[62,15,4,2],
[62,15,4,3],
[62,15,5,0],
[62,15,5,1],
[62,15,5,2],
[62,15,5,4],
[62,15,6,0],
[62,15,6,1],
[62,15,6,2],
[62,15,6,4],
[62,15,8,0],
[62,15,8,1],
[62,15,8,2],
[62,15,8,4],
[62,15,10,0],
[62,15,10,1],
[62,15,10,2],
[62,15,10,3],
[62,15,10,4],
[62,15,10,5],
[62,15,10,6],
[62,15,10,8],
[62,15,11,0],
[62,15,11,1],
[62,15,11,2],
[62,15,11,3],
[62,15,11,4],
[62,15,11,5],
[62,15,11,6],
[62,15,11,8],
[62,15,11,10],
[62,15,12,0],
[62,15,12,1],
[62,15,12,2],
[62,15,12,3],
[62,15,12,4],
[62,15,12,5],
[62,15,12,6],
[62,15,12,8],
[62,15,12,10],
[62,15,13,0],
[62,15,13,1],
[62,15,13,2],
[62,15,13,3],
[62,15,13,4],
[62,15,13,5],
[62,15,13,6],
[62,15,13,8],
[62,15,13,10],
[62,15,13,11],
[62,15,13,12],
[62,15,14,0],
[62,15,14,1],
[62,15,14,2],
[62,15,14,3],
[62,15,14,4],
[62,15,14,5],
[62,15,14,6],
[62,15,14,8],
[62,15,14,10],
[62,15,14,11],
[62,15,14,12],
[62,16,3,0],
[62,16,3,1],
[62,16,3,2],
[62,16,4,0],
[62,16,4,1],
[62,16,4,2],
[62,16,4,3],
[62,16,5,0],
[62,16,5,1],
[62,16,5,2],
[62,16,5,4],
[62,16,6,0],
[62,16,6,1],
[62,16,6,2],
[62,16,6,4],
[62,16,8,0],
[62,16,8,1],
[62,16,8,2],
[62,16,8,4],
[62,16,9,0],
[62,16,9,1],
[62,16,9,2],
[62,16,9,3],
[62,16,9,4],
[62,16,9,5],
[62,16,9,6],
[62,16,9,8],
[62,16,10,0],
[62,16,10,1],
[62,16,10,2],
[62,16,10,3],
[62,16,10,4],
[62,16,10,5],
[62,16,10,6],
[62,16,10,8],
[62,16,10,9],
[62,16,11,0],
[62,16,11,1],
[62,16,11,2],
[62,16,11,3],
[62,16,11,4],
[62,16,11,5],
[62,16,11,6],
[62,16,11,8],
[62,16,11,9],
[62,16,11,10],
[62,16,12,0],
[62,16,12,1],
[62,16,12,2],
[62,16,12,3],
[62,16,12,4],
[62,16,12,5],
[62,16,12,6],
[62,16,12,8],
[62,16,12,9],
[62,16,12,10],
[62,16,13,0],
[62,16,13,1],
[62,16,13,2],
[62,16,13,3],
[62,16,13,4],
[62,16,13,5],
[62,16,13,6],
[62,16,13,8],
[62,16,13,9],
[62,16,13,10],
[62,16,13,11],
[62,16,13,12],
[62,16,14,0],
[62,16,14,1],
[62,16,14,2],
[62,16,14,3],
[62,16,14,4],
[62,16,14,5],
[62,16,14,6],
[62,16,14,8],
[62,16,14,9],
[62,16,14,10],
[62,16,14,11],
[62,16,14,12],
[62,16,15,0],
[62,16,15,1],
[62,16,15,2],
[62,16,15,3],
[62,16,15,4],
[62,16,15,5],
[62,16,15,6],
[62,16,15,8],
[62,16,15,10],
[62,16,15,11],
[62,16,15,12],
[62,16,15,13],
[62,16,15,14],
[62,17,3,0],
[62,17,3,1],
[62,17,3,2],
[62,17,4,0],
[62,17,4,1],
[62,17,4,2],
[62,17,4,3],
[62,17,5,0],
[62,17,5,1],
[62,17,5,2],
[62,17,5,4],
[62,17,6,0],
[62,17,6,1],
[62,17,6,2],
[62,17,6,4],
[62,17,8,0],
[62,17,8,1],
[62,17,8,2],
[62,17,8,4],
[62,17,9,0],
[62,17,9,1],
[62,17,9,2],
[62,17,9,3],
[62,17,9,4],
[62,17,9,5],
[62,17,9,6],
[62,17,9,8],
[62,17,10,0],
[62,17,10,1],
[62,17,10,2],
[62,17,10,3],
[62,17,10,4],
[62,17,10,5],
[62,17,10,6],
[62,17,10,8],
[62,17,10,9],
[62,17,11,0],
[62,17,11,1],
[62,17,11,2],
[62,17,11,3],
[62,17,11,4],
[62,17,11,5],
[62,17,11,6],
[62,17,11,8],
[62,17,11,9],
[62,17,11,10],
[62,17,12,0],
[62,17,12,1],
[62,17,12,2],
[62,17,12,3],
[62,17,12,4],
[62,17,12,5],
[62,17,12,6],
[62,17,12,8],
[62,17,12,9],
[62,17,12,10],
[62,17,13,0],
[62,17,13,1],
[62,17,13,2],
[62,17,13,3],
[62,17,13,4],
[62,17,13,5],
[62,17,13,6],
[62,17,13,8],
[62,17,13,9],
[62,17,13,10],
[62,17,13,11],
[62,17,13,12],
[62,17,14,0],
[62,17,14,1],
[62,17,14,2],
[62,17,14,3],
[62,17,14,4],
[62,17,14,5],
[62,17,14,6],
[62,17,14,8],
[62,17,14,9],
[62,17,14,10],
[62,17,14,11],
[62,17,14,12],
[62,17,15,0],
[62,17,15,1],
[62,17,15,2],
[62,17,15,3],
[62,17,15,4],
[62,17,15,5],
[62,17,15,6],
[62,17,15,8],
[62,17,15,10],
[62,17,15,11],
[62,17,15,12],
[62,17,15,13],
[62,17,15,14],
[62,17,16,0],
[62,17,16,1],
[62,17,16,2],
[62,17,16,3],
[62,17,16,4],
[62,17,16,5],
[62,17,16,6],
[62,17,16,8],
[62,17,16,9],
[62,17,16,10],
[62,17,16,11],
[62,17,16,12],
[62,17,16,13],
[62,17,16,14],
[62,17,16,15],
[62,18,3,0],
[62,18,3,1],
[62,18,3,2],
[62,18,4,0],
[62,18,4,1],
[62,18,4,2],
[62,18,4,3],
[62,18,5,0],
[62,18,5,1],
[62,18,5,2],
[62,18,5,4],
[62,18,6,0],
[62,18,6,1],
[62,18,6,2],
[62,18,6,4],
[62,18,8,0],
[62,18,8,1],
[62,18,8,2],
[62,18,8,4],
[62,18,10,0],
[62,18,10,1],
[62,18,10,2],
[62,18,10,3],
[62,18,10,4],
[62,18,10,5],
[62,18,10,6],
[62,18,10,8],
[62,18,11,0],
[62,18,11,1],
[62,18,11,2],
[62,18,11,3],
[62,18,11,4],
[62,18,11,5],
[62,18,11,6],
[62,18,11,8],
[62,18,11,10],
[62,18,12,0],
[62,18,12,1],
[62,18,12,2],
[62,18,12,3],
[62,18,12,4],
[62,18,12,5],
[62,18,12,6],
[62,18,12,8],
[62,18,12,10],
[62,18,13,0],
[62,18,13,1],
[62,18,13,2],
[62,18,13,3],
[62,18,13,4],
[62,18,13,5],
[62,18,13,6],
[62,18,13,8],
[62,18,13,10],
[62,18,13,11],
[62,18,13,12],
[62,18,14,0],
[62,18,14,1],
[62,18,14,2],
[62,18,14,3],
[62,18,14,4],
[62,18,14,5],
[62,18,14,6],
[62,18,14,8],
[62,18,14,10],
[62,18,14,11],
[62,18,14,12],
[62,18,16,0],
[62,18,16,1],
[62,18,16,2],
[62,18,16,3],
[62,18,16,4],
[62,18,16,5],
[62,18,16,6],
[62,18,16,8],
[62,18,16,10],
[62,18,16,11],
[62,18,16,12],
[62,18,16,13],
[62,18,16,14],
[62,18,17,0],
[62,18,17,1],
[62,18,17,2],
[62,18,17,3],
[62,18,17,4],
[62,18,17,5],
[62,18,17,6],
[62,18,17,8],
[62,18,17,10],
[62,18,17,11],
[62,18,17,12],
[62,18,17,13],
[62,18,17,14],
[62,18,17,16],
[62,19,3,0],
[62,19,3,1],
[62,19,3,2],
[62,19,4,0],
[62,19,4,1],
[62,19,4,2],
[62,19,4,3],
[62,19,5,0],
[62,19,5,1],
[62,19,5,2],
[62,19,5,4],
[62,19,6,0],
[62,19,6,1],
[62,19,6,2],
[62,19,6,4],
[62,19,8,0],
[62,19,8,1],
[62,19,8,2],
[62,19,8,4],
[62,19,9,0],
[62,19,9,1],
[62,19,9,2],
[62,19,9,3],
[62,19,9,4],
[62,19,9,5],
[62,19,9,6],
[62,19,9,8],
[62,19,10,0],
[62,19,10,1],
[62,19,10,2],
[62,19,10,3],
[62,19,10,4],
[62,19,10,5],
[62,19,10,6],
[62,19,10,8],
[62,19,10,9],
[62,19,11,0],
[62,19,11,1],
[62,19,11,2],
[62,19,11,3],
[62,19,11,4],
[62,19,11,5],
[62,19,11,6],
[62,19,11,8],
[62,19,11,9],
[62,19,11,10],
[62,19,12,0],
[62,19,12,1],
[62,19,12,2],
[62,19,12,3],
[62,19,12,4],
[62,19,12,5],
[62,19,12,6],
[62,19,12,8],
[62,19,12,9],
[62,19,12,10],
[62,19,15,0],
[62,19,15,1],
[62,19,15,2],
[62,19,15,3],
[62,19,15,4],
[62,19,15,5],
[62,19,15,6],
[62,19,15,8],
[62,19,15,10],
[62,19,15,11],
[62,19,15,12],
[62,19,16,0],
[62,19,16,1],
[62,19,16,2],
[62,19,16,3],
[62,19,16,4],
[62,19,16,5],
[62,19,16,6],
[62,19,16,8],
[62,19,16,9],
[62,19,16,10],
[62,19,16,11],
[62,19,16,12],
[62,19,16,15],
[62,19,17,0],
[62,19,17,1],
[62,19,17,2],
[62,19,17,3],
[62,19,17,4],
[62,19,17,5],
[62,19,17,6],
[62,19,17,8],
[62,19,17,9],
[62,19,17,10],
[62,19,17,11],
[62,19,17,12],
[62,19,17,15],
[62,19,17,16],
[62,19,18,0],
[62,19,18,1],
[62,19,18,2],
[62,19,18,3],
[62,19,18,4],
[62,19,18,5],
[62,19,18,6],
[62,19,18,8],
[62,19,18,10],
[62,19,18,11],
[62,19,18,12],
[62,19,18,16],
[62,19,18,17],
[62,20,3,0],
[62,20,3,1],
[62,20,3,2],
[62,20,4,0],
[62,20,4,1],
[62,20,4,2],
[62,20,4,3],
[62,20,5,0],
[62,20,5,1],
[62,20,5,2],
[62,20,5,4],
[62,20,6,0],
[62,20,6,1],
[62,20,6,2],
[62,20,6,4],
[62,20,8,0],
[62,20,8,1],
[62,20,8,2],
[62,20,8,4],
[62,20,9,0],
[62,20,9,1],
[62,20,9,2],
[62,20,9,3],
[62,20,9,4],
[62,20,9,5],
[62,20,9,6],
[62,20,9,8],
[62,20,11,0],
[62,20,11,1],
[62,20,11,2],
[62,20,11,3],
[62,20,11,4],
[62,20,11,5],
[62,20,11,6],
[62,20,11,8],
[62,20,11,9],
[62,20,12,0],
[62,20,12,1],
[62,20,12,2],
[62,20,12,3],
[62,20,12,4],
[62,20,12,5],
[62,20,12,6],
[62,20,12,8],
[62,20,12,9],
[62,20,13,0],
[62,20,13,1],
[62,20,13,2],
[62,20,13,3],
[62,20,13,4],
[62,20,13,5],
[62,20,13,6],
[62,20,13,8],
[62,20,13,9],
[62,20,13,11],
[62,20,13,12],
[62,20,14,0],
[62,20,14,1],
[62,20,14,2],
[62,20,14,3],
[62,20,14,4],
[62,20,14,5],
[62,20,14,6],
[62,20,14,8],
[62,20,14,9],
[62,20,14,11],
[62,20,14,12],
[62,20,15,0],
[62,20,15,1],
[62,20,15,2],
[62,20,15,3],
[62,20,15,4],
[62,20,15,5],
[62,20,15,6],
[62,20,15,8],
[62,20,15,11],
[62,20,15,12],
[62,20,15,13],
[62,20,15,14],
[62,20,16,0],
[62,20,16,1],
[62,20,16,2],
[62,20,16,3],
[62,20,16,4],
[62,20,16,5],
[62,20,16,6],
[62,20,16,8],
[62,20,16,9],
[62,20,16,11],
[62,20,16,12],
[62,20,16,13],
[62,20,16,14],
[62,20,16,15],
[62,20,17,0],
[62,20,17,1],
[62,20,17,2],
[62,20,17,3],
[62,20,17,4],
[62,20,17,5],
[62,20,17,6],
[62,20,17,8],
[62,20,17,9],
[62,20,17,11],
[62,20,17,12],
[62,20,17,13],
[62,20,17,14],
[62,20,17,15],
[62,20,17,16],
[62,20,18,0],
[62,20,18,1],
[62,20,18,2],
[62,20,18,3],
[62,20,18,4],
[62,20,18,5],
[62,20,18,6],
[62,20,18,8],
[62,20,18,11],
[62,20,18,12],
[62,20,18,13],
[62,20,18,14],
[62,20,18,16],
[62,20,18,17],
[62,20,19,0],
[62,20,19,1],
[62,20,19,2],
[62,20,19,3],
[62,20,19,4],
[62,20,19,5],
[62,20,19,6],
[62,20,19,8],
[62,20,19,9],
[62,20,19,11],
[62,20,19,12],
[62,20,19,15],
[62,20,19,16],
[62,20,19,17],
[62,20,19,18],
[62,21,4,0],
[62,21,4,1],
[62,21,4,2],
[62,21,9,0],
[62,21,9,1],
[62,21,9,2],
[62,21,9,4],
[62,21,10,0],
[62,21,10,1],
[62,21,10,2],
[62,21,10,4],
[62,21,10,9],
[62,21,11,0],
[62,21,11,1],
[62,21,11,2],
[62,21,11,4],
[62,21,11,9],
[62,21,11,10],
[62,21,12,0],
[62,21,12,1],
[62,21,12,2],
[62,21,12,4],
[62,21,12,9],
[62,21,12,10],
[62,21,13,0],
[62,21,13,1],
[62,21,13,2],
[62,21,13,4],
[62,21,13,9],
[62,21,13,10],
[62,21,13,11],
[62,21,13,12],
[62,21,14,0],
[62,21,14,1],
[62,21,14,2],
[62,21,14,4],
[62,21,14,9],
[62,21,14,10],
[62,21,14,11],
[62,21,14,12],
[62,21,15,0],
[62,21,15,1],
[62,21,15,2],
[62,21,15,4],
[62,21,15,10],
[62,21,15,11],
[62,21,15,12],
[62,21,15,13],
[62,21,15,14],
[62,21,16,0],
[62,21,16,1],
[62,21,16,2],
[62,21,16,4],
[62,21,16,9],
[62,21,16,10],
[62,21,16,11],
[62,21,16,12],
[62,21,16,13],
[62,21,16,14],
[62,21,16,15],
[62,21,17,0],
[62,21,17,1],
[62,21,17,2],
[62,21,17,4],
[62,21,17,9],
[62,21,17,10],
[62,21,17,11],
[62,21,17,12],
[62,21,17,13],
[62,21,17,14],
[62,21,17,15],
[62,21,17,16],
[62,21,18,0],
[62,21,18,1],
[62,21,18,2],
[62,21,18,4],
[62,21,18,10],
[62,21,18,11],
[62,21,18,12],
[62,21,18,13],
[62,21,18,14],
[62,21,18,16],
[62,21,18,17],
[62,21,19,0],
[62,21,19,1],
[62,21,19,2],
[62,21,19,4],
[62,21,19,9],
[62,21,19,10],
[62,21,19,11],
[62,21,19,12],
[62,21,19,15],
[62,21,19,16],
[62,21,19,17],
[62,21,19,18],
[62,21,20,0],
[62,21,20,1],
[62,21,20,2],
[62,21,20,4],
[62,21,20,9],
[62,21,20,11],
[62,21,20,12],
[62,21,20,13],
[62,21,20,14],
[62,21,20,15],
[62,21,20,16],
[62,21,20,17],
[62,21,20,18],
[62,21,20,19],
[62,22,3,0],
[62,22,3,1],
[62,22,3,2],
[62,22,4,0],
[62,22,4,1],
[62,22,4,2],
[62,22,4,3],
[62,22,5,0],
[62,22,5,1],
[62,22,5,2],
[62,22,5,4],
[62,22,6,0],
[62,22,6,1],
[62,22,6,2],
[62,22,6,4],
[62,22,8,0],
[62,22,8,1],
[62,22,8,2],
[62,22,8,4],
[62,22,9,0],
[62,22,9,1],
[62,22,9,2],
[62,22,9,3],
[62,22,9,4],
[62,22,9,5],
[62,22,9,6],
[62,22,9,8],
[62,22,11,0],
[62,22,11,1],
[62,22,11,2],
[62,22,11,3],
[62,22,11,4],
[62,22,11,5],
[62,22,11,6],
[62,22,11,8],
[62,22,11,9],
[62,22,12,0],
[62,22,12,1],
[62,22,12,2],
[62,22,12,3],
[62,22,12,4],
[62,22,12,5],
[62,22,12,6],
[62,22,12,8],
[62,22,12,9],
[62,22,13,0],
[62,22,13,1],
[62,22,13,2],
[62,22,13,3],
[62,22,13,4],
[62,22,13,5],
[62,22,13,6],
[62,22,13,8],
[62,22,13,9],
[62,22,13,11],
[62,22,13,12],
[62,22,14,0],
[62,22,14,1],
[62,22,14,2],
[62,22,14,3],
[62,22,14,4],
[62,22,14,5],
[62,22,14,6],
[62,22,14,8],
[62,22,14,9],
[62,22,14,11],
[62,22,14,12],
[62,22,15,0],
[62,22,15,1],
[62,22,15,2],
[62,22,15,3],
[62,22,15,4],
[62,22,15,5],
[62,22,15,6],
[62,22,15,8],
[62,22,15,11],
[62,22,15,12],
[62,22,15,13],
[62,22,15,14],
[62,22,16,0],
[62,22,16,1],
[62,22,16,2],
[62,22,16,3],
[62,22,16,4],
[62,22,16,5],
[62,22,16,6],
[62,22,16,8],
[62,22,16,9],
[62,22,16,11],
[62,22,16,12],
[62,22,16,13],
[62,22,16,14],
[62,22,16,15],
[62,22,17,0],
[62,22,17,1],
[62,22,17,2],
[62,22,17,3],
[62,22,17,4],
[62,22,17,5],
[62,22,17,6],
[62,22,17,8],
[62,22,17,9],
[62,22,17,11],
[62,22,17,12],
[62,22,17,13],
[62,22,17,14],
[62,22,17,15],
[62,22,17,16],
[62,22,18,0],
[62,22,18,1],
[62,22,18,2],
[62,22,18,3],
[62,22,18,4],
[62,22,18,5],
[62,22,18,6],
[62,22,18,8],
[62,22,18,11],
[62,22,18,12],
[62,22,18,13],
[62,22,18,14],
[62,22,18,16],
[62,22,18,17],
[62,22,19,0],
[62,22,19,1],
[62,22,19,2],
[62,22,19,3],
[62,22,19,4],
[62,22,19,5],
[62,22,19,6],
[62,22,19,8],
[62,22,19,9],
[62,22,19,11],
[62,22,19,12],
[62,22,19,15],
[62,22,19,16],
[62,22,19,17],
[62,22,19,18],
[62,22,21,0],
[62,22,21,1],
[62,22,21,2],
[62,22,21,4],
[62,22,21,9],
[62,22,21,11],
[62,22,21,12],
[62,22,21,13],
[62,22,21,14],
[62,22,21,15],
[62,22,21,16],
[62,22,21,17],
[62,22,21,18],
[62,22,21,19],
[62,23,3,0],
[62,23,3,1],
[62,23,3,2],
[62,23,4,0],
[62,23,4,1],
[62,23,4,2],
[62,23,4,3],
[62,23,5,0],
[62,23,5,1],
[62,23,5,2],
[62,23,5,4],
[62,23,6,0],
[62,23,6,1],
[62,23,6,2],
[62,23,6,4],
[62,23,8,0],
[62,23,8,1],
[62,23,8,2],
[62,23,8,4],
[62,23,9,0],
[62,23,9,1],
[62,23,9,2],
[62,23,9,3],
[62,23,9,4],
[62,23,9,5],
[62,23,9,6],
[62,23,9,8],
[62,23,10,0],
[62,23,10,1],
[62,23,10,2],
[62,23,10,3],
[62,23,10,4],
[62,23,10,5],
[62,23,10,6],
[62,23,10,8],
[62,23,10,9],
[62,23,11,0],
[62,23,11,1],
[62,23,11,2],
[62,23,11,3],
[62,23,11,4],
[62,23,11,5],
[62,23,11,6],
[62,23,11,8],
[62,23,11,9],
[62,23,11,10],
[62,23,12,0],
[62,23,12,1],
[62,23,12,2],
[62,23,12,3],
[62,23,12,4],
[62,23,12,5],
[62,23,12,6],
[62,23,12,8],
[62,23,12,9],
[62,23,12,10],
[62,23,13,0],
[62,23,13,1],
[62,23,13,2],
[62,23,13,3],
[62,23,13,4],
[62,23,13,5],
[62,23,13,6],
[62,23,13,8],
[62,23,13,9],
[62,23,13,10],
[62,23,13,11],
[62,23,13,12],
[62,23,14,0],
[62,23,14,1],
[62,23,14,2],
[62,23,14,3],
[62,23,14,4],
[62,23,14,5],
[62,23,14,6],
[62,23,14,8],
[62,23,14,9],
[62,23,14,10],
[62,23,14,11],
[62,23,14,12],
[62,23,15,0],
[62,23,15,1],
[62,23,15,2],
[62,23,15,3],
[62,23,15,4],
[62,23,15,5],
[62,23,15,6],
[62,23,15,8],
[62,23,15,10],
[62,23,15,11],
[62,23,15,12],
[62,23,15,13],
[62,23,15,14],
[62,23,16,0],
[62,23,16,1],
[62,23,16,2],
[62,23,16,3],
[62,23,16,4],
[62,23,16,5],
[62,23,16,6],
[62,23,16,8],
[62,23,16,9],
[62,23,16,10],
[62,23,16,11],
[62,23,16,12],
[62,23,16,13],
[62,23,16,14],
[62,23,16,15],
[62,23,17,0],
[62,23,17,1],
[62,23,17,2],
[62,23,17,3],
[62,23,17,4],
[62,23,17,5],
[62,23,17,6],
[62,23,17,8],
[62,23,17,9],
[62,23,17,10],
[62,23,17,11],
[62,23,17,12],
[62,23,17,13],
[62,23,17,14],
[62,23,17,15],
[62,23,17,16],
[62,23,18,0],
[62,23,18,1],
[62,23,18,2],
[62,23,18,3],
[62,23,18,4],
[62,23,18,5],
[62,23,18,6],
[62,23,18,8],
[62,23,18,10],
[62,23,18,11],
[62,23,18,12],
[62,23,18,13],
[62,23,18,14],
[62,23,18,16],
[62,23,18,17],
[62,23,19,0],
[62,23,19,1],
[62,23,19,2],
[62,23,19,3],
[62,23,19,4],
[62,23,19,5],
[62,23,19,6],
[62,23,19,8],
[62,23,19,9],
[62,23,19,10],
[62,23,19,11],
[62,23,19,12],
[62,23,19,15],
[62,23,19,16],
[62,23,19,17],
[62,23,19,18],
[62,23,20,0],
[62,23,20,1],
[62,23,20,2],
[62,23,20,3],
[62,23,20,4],
[62,23,20,5],
[62,23,20,6],
[62,23,20,8],
[62,23,20,9],
[62,23,20,11],
[62,23,20,12],
[62,23,20,13],
[62,23,20,14],
[62,23,20,15],
[62,23,20,16],
[62,23,20,17],
[62,23,20,18],
[62,23,20,19],
[62,23,21,0],
[62,23,21,1],
[62,23,21,2],
[62,23,21,4],
[62,23,21,9],
[62,23,21,10],
[62,23,21,11],
[62,23,21,12],
[62,23,21,13],
[62,23,21,14],
[62,23,21,15],
[62,23,21,16],
[62,23,21,17],
[62,23,21,18],
[62,23,21,19],
[62,23,21,20],
[62,23,22,0],
[62,23,22,1],
[62,23,22,2],
[62,23,22,3],
[62,23,22,4],
[62,23,22,5],
[62,23,22,6],
[62,23,22,8],
[62,23,22,9],
[62,23,22,11],
[62,23,22,12],
[62,23,22,13],
[62,23,22,14],
[62,23,22,15],
[62,23,22,16],
[62,23,22,17],
[62,23,22,18],
[62,23,22,19],
[62,23,22,21],
[62,24,3,0],
[62,24,3,1],
[62,24,3,2],
[62,24,4,0],
[62,24,4,1],
[62,24,4,2],
[62,24,4,3],
[62,24,5,0],
[62,24,5,1],
[62,24,5,2],
[62,24,5,4],
[62,24,6,0],
[62,24,6,1],
[62,24,6,2],
[62,24,6,4],
[62,24,8,0],
[62,24,8,1],
[62,24,8,2],
[62,24,8,4],
[62,24,9,0],
[62,24,9,1],
[62,24,9,2],
[62,24,9,3],
[62,24,9,4],
[62,24,9,5],
[62,24,9,6],
[62,24,9,8],
[62,24,10,0],
[62,24,10,1],
[62,24,10,2],
[62,24,10,3],
[62,24,10,4],
[62,24,10,5],
[62,24,10,6],
[62,24,10,8],
[62,24,10,9],
[62,24,11,0],
[62,24,11,1],
[62,24,11,2],
[62,24,11,3],
[62,24,11,4],
[62,24,11,5],
[62,24,11,6],
[62,24,11,8],
[62,24,11,9],
[62,24,11,10],
[62,24,12,0],
[62,24,12,1],
[62,24,12,2],
[62,24,12,3],
[62,24,12,4],
[62,24,12,5],
[62,24,12,6],
[62,24,12,8],
[62,24,12,9],
[62,24,12,10],
[62,24,13,0],
[62,24,13,1],
[62,24,13,2],
[62,24,13,3],
[62,24,13,4],
[62,24,13,5],
[62,24,13,6],
[62,24,13,8],
[62,24,13,9],
[62,24,13,10],
[62,24,13,11],
[62,24,13,12],
[62,24,14,0],
[62,24,14,1],
[62,24,14,2],
[62,24,14,3],
[62,24,14,4],
[62,24,14,5],
[62,24,14,6],
[62,24,14,8],
[62,24,14,9],
[62,24,14,10],
[62,24,14,11],
[62,24,14,12],
[62,24,15,0],
[62,24,15,1],
[62,24,15,2],
[62,24,15,3],
[62,24,15,4],
[62,24,15,5],
[62,24,15,6],
[62,24,15,8],
[62,24,15,10],
[62,24,15,11],
[62,24,15,12],
[62,24,15,13],
[62,24,15,14],
[62,24,17,0],
[62,24,17,1],
[62,24,17,2],
[62,24,17,3],
[62,24,17,4],
[62,24,17,5],
[62,24,17,6],
[62,24,17,8],
[62,24,17,9],
[62,24,17,10],
[62,24,17,11],
[62,24,17,12],
[62,24,17,13],
[62,24,17,14],
[62,24,17,15],
[62,24,18,0],
[62,24,18,1],
[62,24,18,2],
[62,24,18,3],
[62,24,18,4],
[62,24,18,5],
[62,24,18,6],
[62,24,18,8],
[62,24,18,10],
[62,24,18,11],
[62,24,18,12],
[62,24,18,13],
[62,24,18,14],
[62,24,18,17],
[62,24,19,0],
[62,24,19,1],
[62,24,19,2],
[62,24,19,3],
[62,24,19,4],
[62,24,19,5],
[62,24,19,6],
[62,24,19,8],
[62,24,19,9],
[62,24,19,10],
[62,24,19,11],
[62,24,19,12],
[62,24,19,15],
[62,24,19,17],
[62,24,19,18],
[62,24,20,0],
[62,24,20,1],
[62,24,20,2],
[62,24,20,3],
[62,24,20,4],
[62,24,20,5],
[62,24,20,6],
[62,24,20,8],
[62,24,20,9],
[62,24,20,11],
[62,24,20,12],
[62,24,20,13],
[62,24,20,14],
[62,24,20,15],
[62,24,20,17],
[62,24,20,18],
[62,24,20,19],
[62,24,21,0],
[62,24,21,1],
[62,24,21,2],
[62,24,21,4],
[62,24,21,9],
[62,24,21,10],
[62,24,21,11],
[62,24,21,12],
[62,24,21,13],
[62,24,21,14],
[62,24,21,15],
[62,24,21,17],
[62,24,21,18],
[62,24,21,19],
[62,24,21,20],
[62,24,22,0],
[62,24,22,1],
[62,24,22,2],
[62,24,22,3],
[62,24,22,4],
[62,24,22,5],
[62,24,22,6],
[62,24,22,8],
[62,24,22,9],
[62,24,22,11],
[62,24,22,12],
[62,24,22,13],
[62,24,22,14],
[62,24,22,15],
[62,24,22,17],
[62,24,22,18],
[62,24,22,19],
[62,24,22,21],
[62,24,23,0],
[62,24,23,1],
[62,24,23,2],
[62,24,23,3],
[62,24,23,4],
[62,24,23,5],
[62,24,23,6],
[62,24,23,8],
[62,24,23,9],
[62,24,23,10],
[62,24,23,11],
[62,24,23,12],
[62,24,23,13],
[62,24,23,14],
[62,24,23,15],
[62,24,23,17],
[62,24,23,18],
[62,24,23,19],
[62,24,23,20],
[62,24,23,21],
[62,24,23,22],
[62,26,3,0],
[62,26,3,1],
[62,26,3,2],
[62,26,4,0],
[62,26,4,1],
[62,26,4,2],
[62,26,4,3],
[62,26,5,0],
[62,26,5,1],
[62,26,5,2],
[62,26,5,4],
[62,26,6,0],
[62,26,6,1],
[62,26,6,2],
[62,26,6,4],
[62,26,8,0],
[62,26,8,1],
[62,26,8,2],
[62,26,8,4],
[62,26,10,0],
[62,26,10,1],
[62,26,10,2],
[62,26,10,3],
[62,26,10,4],
[62,26,10,5],
[62,26,10,6],
[62,26,10,8],
[62,26,11,0],
[62,26,11,1],
[62,26,11,2],
[62,26,11,3],
[62,26,11,4],
[62,26,11,5],
[62,26,11,6],
[62,26,11,8],
[62,26,11,10],
[62,26,12,0],
[62,26,12,1],
[62,26,12,2],
[62,26,12,3],
[62,26,12,4],
[62,26,12,5],
[62,26,12,6],
[62,26,12,8],
[62,26,12,10],
[62,26,13,0],
[62,26,13,1],
[62,26,13,2],
[62,26,13,3],
[62,26,13,4],
[62,26,13,5],
[62,26,13,6],
[62,26,13,8],
[62,26,13,10],
[62,26,13,11],
[62,26,13,12],
[62,26,14,0],
[62,26,14,1],
[62,26,14,2],
[62,26,14,3],
[62,26,14,4],
[62,26,14,5],
[62,26,14,6],
[62,26,14,8],
[62,26,14,10],
[62,26,14,11],
[62,26,14,12],
[62,26,16,0],
[62,26,16,1],
[62,26,16,2],
[62,26,16,3],
[62,26,16,4],
[62,26,16,5],
[62,26,16,6],
[62,26,16,8],
[62,26,16,10],
[62,26,16,11],
[62,26,16,12],
[62,26,16,13],
[62,26,16,14],
[62,26,17,0],
[62,26,17,1],
[62,26,17,2],
[62,26,17,3],
[62,26,17,4],
[62,26,17,5],
[62,26,17,6],
[62,26,17,8],
[62,26,17,10],
[62,26,17,11],
[62,26,17,12],
[62,26,17,13],
[62,26,17,14],
[62,26,17,16],
[62,26,19,0],
[62,26,19,1],
[62,26,19,2],
[62,26,19,3],
[62,26,19,4],
[62,26,19,5],
[62,26,19,6],
[62,26,19,8],
[62,26,19,10],
[62,26,19,11],
[62,26,19,12],
[62,26,19,16],
[62,26,19,17],
[62,26,20,0],
[62,26,20,1],
[62,26,20,2],
[62,26,20,3],
[62,26,20,4],
[62,26,20,5],
[62,26,20,6],
[62,26,20,8],
[62,26,20,11],
[62,26,20,12],
[62,26,20,13],
[62,26,20,14],
[62,26,20,16],
[62,26,20,17],
[62,26,20,19],
[62,26,21,0],
[62,26,21,1],
[62,26,21,2],
[62,26,21,4],
[62,26,21,10],
[62,26,21,11],
[62,26,21,12],
[62,26,21,13],
[62,26,21,14],
[62,26,21,16],
[62,26,21,17],
[62,26,21,19],
[62,26,21,20],
[62,26,22,0],
[62,26,22,1],
[62,26,22,2],
[62,26,22,3],
[62,26,22,4],
[62,26,22,5],
[62,26,22,6],
[62,26,22,8],
[62,26,22,11],
[62,26,22,12],
[62,26,22,13],
[62,26,22,14],
[62,26,22,16],
[62,26,22,17],
[62,26,22,19],
[62,26,22,21],
[62,26,23,0],
[62,26,23,1],
[62,26,23,2],
[62,26,23,3],
[62,26,23,4],
[62,26,23,5],
[62,26,23,6],
[62,26,23,8],
[62,26,23,10],
[62,26,23,11],
[62,26,23,12],
[62,26,23,13],
[62,26,23,14],
[62,26,23,16],
[62,26,23,17],
[62,26,23,19],
[62,26,23,20],
[62,26,23,21],
[62,26,23,22],
[62,26,24,0],
[62,26,24,1],
[62,26,24,2],
[62,26,24,3],
[62,26,24,4],
[62,26,24,5],
[62,26,24,6],
[62,26,24,8],
[62,26,24,10],
[62,26,24,11],
[62,26,24,12],
[62,26,24,13],
[62,26,24,14],
[62,26,24,17],
[62,26,24,19],
[62,26,24,20],
[62,26,24,21],
[62,26,24,22],
[62,26,24,23],
[62,27,3,0],
[62,27,3,1],
[62,27,3,2],
[62,27,4,0],
[62,27,4,1],
[62,27,4,2],
[62,27,4,3],
[62,27,5,0],
[62,27,5,1],
[62,27,5,2],
[62,27,5,4],
[62,27,6,0],
[62,27,6,1],
[62,27,6,2],
[62,27,6,4],
[62,27,8,0],
[62,27,8,1],
[62,27,8,2],
[62,27,8,4],
[62,27,9,0],
[62,27,9,1],
[62,27,9,2],
[62,27,9,3],
[62,27,9,4],
[62,27,9,5],
[62,27,9,6],
[62,27,9,8],
[62,27,11,0],
[62,27,11,1],
[62,27,11,2],
[62,27,11,3],
[62,27,11,4],
[62,27,11,5],
[62,27,11,6],
[62,27,11,8],
[62,27,11,9],
[62,27,12,0],
[62,27,12,1],
[62,27,12,2],
[62,27,12,3],
[62,27,12,4],
[62,27,12,5],
[62,27,12,6],
[62,27,12,8],
[62,27,12,9],
[62,27,13,0],
[62,27,13,1],
[62,27,13,2],
[62,27,13,3],
[62,27,13,4],
[62,27,13,5],
[62,27,13,6],
[62,27,13,8],
[62,27,13,9],
[62,27,13,11],
[62,27,13,12],
[62,27,14,0],
[62,27,14,1],
[62,27,14,2],
[62,27,14,3],
[62,27,14,4],
[62,27,14,5],
[62,27,14,6],
[62,27,14,8],
[62,27,14,9],
[62,27,14,11],
[62,27,14,12],
[62,27,15,0],
[62,27,15,1],
[62,27,15,2],
[62,27,15,3],
[62,27,15,4],
[62,27,15,5],
[62,27,15,6],
[62,27,15,8],
[62,27,15,11],
[62,27,15,12],
[62,27,15,13],
[62,27,15,14],
[62,27,16,0],
[62,27,16,1],
[62,27,16,2],
[62,27,16,3],
[62,27,16,4],
[62,27,16,5],
[62,27,16,6],
[62,27,16,8],
[62,27,16,9],
[62,27,16,11],
[62,27,16,12],
[62,27,16,13],
[62,27,16,14],
[62,27,16,15],
[62,27,17,0],
[62,27,17,1],
[62,27,17,2],
[62,27,17,3],
[62,27,17,4],
[62,27,17,5],
[62,27,17,6],
[62,27,17,8],
[62,27,17,9],
[62,27,17,11],
[62,27,17,12],
[62,27,17,13],
[62,27,17,14],
[62,27,17,15],
[62,27,17,16],
[62,27,18,0],
[62,27,18,1],
[62,27,18,2],
[62,27,18,3],
[62,27,18,4],
[62,27,18,5],
[62,27,18,6],
[62,27,18,8],
[62,27,18,11],
[62,27,18,12],
[62,27,18,13],
[62,27,18,14],
[62,27,18,16],
[62,27,18,17],
[62,27,19,0],
[62,27,19,1],
[62,27,19,2],
[62,27,19,3],
[62,27,19,4],
[62,27,19,5],
[62,27,19,6],
[62,27,19,8],
[62,27,19,9],
[62,27,19,11],
[62,27,19,12],
[62,27,19,15],
[62,27,19,16],
[62,27,19,17],
[62,27,19,18],
[62,27,21,0],
[62,27,21,1],
[62,27,21,2],
[62,27,21,4],
[62,27,21,9],
[62,27,21,11],
[62,27,21,12],
[62,27,21,13],
[62,27,21,14],
[62,27,21,15],
[62,27,21,16],
[62,27,21,17],
[62,27,21,18],
[62,27,21,19],
[62,27,23,0],
[62,27,23,1],
[62,27,23,2],
[62,27,23,3],
[62,27,23,4],
[62,27,23,5],
[62,27,23,6],
[62,27,23,8],
[62,27,23,9],
[62,27,23,11],
[62,27,23,12],
[62,27,23,13],
[62,27,23,14],
[62,27,23,15],
[62,27,23,16],
[62,27,23,17],
[62,27,23,18],
[62,27,23,19],
[62,27,23,21],
[62,27,24,0],
[62,27,24,1],
[62,27,24,2],
[62,27,24,3],
[62,27,24,4],
[62,27,24,5],
[62,27,24,6],
[62,27,24,8],
[62,27,24,9],
[62,27,24,11],
[62,27,24,12],
[62,27,24,13],
[62,27,24,14],
[62,27,24,15],
[62,27,24,17],
[62,27,24,18],
[62,27,24,19],
[62,27,24,21],
[62,27,24,23],
[62,27,26,0],
[62,27,26,1],
[62,27,26,2],
[62,27,26,3],
[62,27,26,4],
[62,27,26,5],
[62,27,26,6],
[62,27,26,8],
[62,27,26,11],
[62,27,26,12],
[62,27,26,13],
[62,27,26,14],
[62,27,26,16],
[62,27,26,17],
[62,27,26,19],
[62,27,26,21],
[62,29,3,0],
[62,29,3,1],
[62,29,3,2],
[62,29,4,0],
[62,29,4,1],
[62,29,4,2],
[62,29,4,3],
[62,29,5,0],
[62,29,5,1],
[62,29,5,2],
[62,29,5,4],
[62,29,6,0],
[62,29,6,1],
[62,29,6,2],
[62,29,6,4],
[62,29,8,0],
[62,29,8,1],
[62,29,8,2],
[62,29,8,4],
[62,29,9,0],
[62,29,9,1],
[62,29,9,2],
[62,29,9,3],
[62,29,9,4],
[62,29,9,5],
[62,29,9,6],
[62,29,9,8],
[62,29,10,0],
[62,29,10,1],
[62,29,10,2],
[62,29,10,3],
[62,29,10,4],
[62,29,10,5],
[62,29,10,6],
[62,29,10,8],
[62,29,10,9],
[62,29,13,0],
[62,29,13,1],
[62,29,13,2],
[62,29,13,3],
[62,29,13,4],
[62,29,13,5],
[62,29,13,6],
[62,29,13,8],
[62,29,13,9],
[62,29,13,10],
[62,29,14,0],
[62,29,14,1],
[62,29,14,2],
[62,29,14,3],
[62,29,14,4],
[62,29,14,5],
[62,29,14,6],
[62,29,14,8],
[62,29,14,9],
[62,29,14,10],
[62,29,15,0],
[62,29,15,1],
[62,29,15,2],
[62,29,15,3],
[62,29,15,4],
[62,29,15,5],
[62,29,15,6],
[62,29,15,8],
[62,29,15,10],
[62,29,15,13],
[62,29,15,14],
[62,29,16,0],
[62,29,16,1],
[62,29,16,2],
[62,29,16,3],
[62,29,16,4],
[62,29,16,5],
[62,29,16,6],
[62,29,16,8],
[62,29,16,9],
[62,29,16,10],
[62,29,16,13],
[62,29,16,14],
[62,29,16,15],
[62,29,17,0],
[62,29,17,1],
[62,29,17,2],
[62,29,17,3],
[62,29,17,4],
[62,29,17,5],
[62,29,17,6],
[62,29,17,8],
[62,29,17,9],
[62,29,17,10],
[62,29,17,13],
[62,29,17,14],
[62,29,17,15],
[62,29,17,16],
[62,29,18,0],
[62,29,18,1],
[62,29,18,2],
[62,29,18,3],
[62,29,18,4],
[62,29,18,5],
[62,29,18,6],
[62,29,18,8],
[62,29,18,10],
[62,29,18,13],
[62,29,18,14],
[62,29,18,16],
[62,29,18,17],
[62,29,19,0],
[62,29,19,1],
[62,29,19,2],
[62,29,19,3],
[62,29,19,4],
[62,29,19,5],
[62,29,19,6],
[62,29,19,8],
[62,29,19,9],
[62,29,19,10],
[62,29,19,15],
[62,29,19,16],
[62,29,19,17],
[62,29,19,18],
[62,29,20,0],
[62,29,20,1],
[62,29,20,2],
[62,29,20,3],
[62,29,20,4],
[62,29,20,5],
[62,29,20,6],
[62,29,20,8],
[62,29,20,9],
[62,29,20,13],
[62,29,20,14],
[62,29,20,15],
[62,29,20,16],
[62,29,20,17],
[62,29,20,18],
[62,29,20,19],
[62,29,21,0],
[62,29,21,1],
[62,29,21,2],
[62,29,21,4],
[62,29,21,9],
[62,29,21,10],
[62,29,21,13],
[62,29,21,14],
[62,29,21,15],
[62,29,21,16],
[62,29,21,17],
[62,29,21,18],
[62,29,21,19],
[62,29,21,20],
[62,29,22,0],
[62,29,22,1],
[62,29,22,2],
[62,29,22,3],
[62,29,22,4],
[62,29,22,5],
[62,29,22,6],
[62,29,22,8],
[62,29,22,9],
[62,29,22,13],
[62,29,22,14],
[62,29,22,15],
[62,29,22,16],
[62,29,22,17],
[62,29,22,18],
[62,29,22,19],
[62,29,22,21],
[62,29,23,0],
[62,29,23,1],
[62,29,23,2],
[62,29,23,3],
[62,29,23,4],
[62,29,23,5],
[62,29,23,6],
[62,29,23,8],
[62,29,23,9],
[62,29,23,10],
[62,29,23,13],
[62,29,23,14],
[62,29,23,15],
[62,29,23,16],
[62,29,23,17],
[62,29,23,18],
[62,29,23,19],
[62,29,23,20],
[62,29,23,21],
[62,29,23,22],
[62,29,24,0],
[62,29,24,1],
[62,29,24,2],
[62,29,24,3],
[62,29,24,4],
[62,29,24,5],
[62,29,24,6],
[62,29,24,8],
[62,29,24,9],
[62,29,24,10],
[62,29,24,13],
[62,29,24,14],
[62,29,24,15],
[62,29,24,17],
[62,29,24,18],
[62,29,24,19],
[62,29,24,20],
[62,29,24,21],
[62,29,24,22],
[62,29,24,23],
[62,29,26,0],
[62,29,26,1],
[62,29,26,2],
[62,29,26,3],
[62,29,26,4],
[62,29,26,5],
[62,29,26,6],
[62,29,26,8],
[62,29,26,10],
[62,29,26,13],
[62,29,26,14],
[62,29,26,16],
[62,29,26,17],
[62,29,26,19],
[62,29,26,20],
[62,29,26,22],
[62,29,26,23],
[62,29,26,24],
[62,29,27,0],
[62,29,27,1],
[62,29,27,2],
[62,29,27,3],
[62,29,27,4],
[62,29,27,5],
[62,29,27,6],
[62,29,27,8],
[62,29,27,9],
[62,29,27,13],
[62,29,27,14],
[62,29,27,15],
[62,29,27,16],
[62,29,27,17],
[62,29,27,18],
[62,29,27,19],
[62,29,27,21],
[62,29,27,23],
[62,29,27,24],
[62,29,27,26],
[62,30,3,0],
[62,30,3,1],
[62,30,3,2],
[62,30,4,0],
[62,30,4,1],
[62,30,4,2],
[62,30,4,3],
[62,30,5,0],
[62,30,5,1],
[62,30,5,2],
[62,30,5,4],
[62,30,6,0],
[62,30,6,1],
[62,30,6,2],
[62,30,6,4],
[62,30,8,0],
[62,30,8,1],
[62,30,8,2],
[62,30,8,4],
[62,30,9,0],
[62,30,9,1],
[62,30,9,2],
[62,30,9,3],
[62,30,9,4],
[62,30,9,5],
[62,30,9,6],
[62,30,9,8],
[62,30,10,0],
[62,30,10,1],
[62,30,10,2],
[62,30,10,3],
[62,30,10,4],
[62,30,10,5],
[62,30,10,6],
[62,30,10,8],
[62,30,10,9],
[62,30,11,0],
[62,30,11,1],
[62,30,11,2],
[62,30,11,3],
[62,30,11,4],
[62,30,11,5],
[62,30,11,6],
[62,30,11,8],
[62,30,11,9],
[62,30,11,10],
[62,30,12,0],
[62,30,12,1],
[62,30,12,2],
[62,30,12,3],
[62,30,12,4],
[62,30,12,5],
[62,30,12,6],
[62,30,12,8],
[62,30,12,9],
[62,30,12,10],
[62,30,13,0],
[62,30,13,1],
[62,30,13,2],
[62,30,13,3],
[62,30,13,4],
[62,30,13,5],
[62,30,13,6],
[62,30,13,8],
[62,30,13,9],
[62,30,13,10],
[62,30,13,11],
[62,30,13,12],
[62,30,14,0],
[62,30,14,1],
[62,30,14,2],
[62,30,14,3],
[62,30,14,4],
[62,30,14,5],
[62,30,14,6],
[62,30,14,8],
[62,30,14,9],
[62,30,14,10],
[62,30,14,11],
[62,30,14,12],
[62,30,15,0],
[62,30,15,1],
[62,30,15,2],
[62,30,15,3],
[62,30,15,4],
[62,30,15,5],
[62,30,15,6],
[62,30,15,8],
[62,30,15,10],
[62,30,15,11],
[62,30,15,12],
[62,30,15,13],
[62,30,15,14],
[62,30,16,0],
[62,30,16,1],
[62,30,16,2],
[62,30,16,3],
[62,30,16,4],
[62,30,16,5],
[62,30,16,6],
[62,30,16,8],
[62,30,16,9],
[62,30,16,10],
[62,30,16,11],
[62,30,16,12],
[62,30,16,13],
[62,30,16,14],
[62,30,16,15],
[62,30,17,0],
[62,30,17,1],
[62,30,17,2],
[62,30,17,3],
[62,30,17,4],
[62,30,17,5],
[62,30,17,6],
[62,30,17,8],
[62,30,17,9],
[62,30,17,10],
[62,30,17,11],
[62,30,17,12],
[62,30,17,13],
[62,30,17,14],
[62,30,17,15],
[62,30,17,16],
[62,30,18,0],
[62,30,18,1],
[62,30,18,2],
[62,30,18,3],
[62,30,18,4],
[62,30,18,5],
[62,30,18,6],
[62,30,18,8],
[62,30,18,10],
[62,30,18,11],
[62,30,18,12],
[62,30,18,13],
[62,30,18,14],
[62,30,18,16],
[62,30,18,17],
[62,30,19,0],
[62,30,19,1],
[62,30,19,2],
[62,30,19,3],
[62,30,19,4],
[62,30,19,5],
[62,30,19,6],
[62,30,19,8],
[62,30,19,9],
[62,30,19,10],
[62,30,19,11],
[62,30,19,12],
[62,30,19,15],
[62,30,19,16],
[62,30,19,17],
[62,30,19,18],
[62,30,20,0],
[62,30,20,1],
[62,30,20,2],
[62,30,20,3],
[62,30,20,4],
[62,30,20,5],
[62,30,20,6],
[62,30,20,8],
[62,30,20,9],
[62,30,20,11],
[62,30,20,12],
[62,30,20,13],
[62,30,20,14],
[62,30,20,15],
[62,30,20,16],
[62,30,20,17],
[62,30,20,18],
[62,30,20,19],
[62,30,21,0],
[62,30,21,1],
[62,30,21,2],
[62,30,21,4],
[62,30,21,9],
[62,30,21,10],
[62,30,21,11],
[62,30,21,12],
[62,30,21,13],
[62,30,21,14],
[62,30,21,15],
[62,30,21,16],
[62,30,21,17],
[62,30,21,18],
[62,30,21,19],
[62,30,21,20],
[62,30,22,0],
[62,30,22,1],
[62,30,22,2],
[62,30,22,3],
[62,30,22,4],
[62,30,22,5],
[62,30,22,6],
[62,30,22,8],
[62,30,22,9],
[62,30,22,11],
[62,30,22,12],
[62,30,22,13],
[62,30,22,14],
[62,30,22,15],
[62,30,22,16],
[62,30,22,17],
[62,30,22,18],
[62,30,22,19],
[62,30,22,21],
[62,30,24,0],
[62,30,24,1],
[62,30,24,2],
[62,30,24,3],
[62,30,24,4],
[62,30,24,5],
[62,30,24,6],
[62,30,24,8],
[62,30,24,9],
[62,30,24,10],
[62,30,24,11],
[62,30,24,12],
[62,30,24,13],
[62,30,24,14],
[62,30,24,15],
[62,30,24,17],
[62,30,24,18],
[62,30,24,19],
[62,30,24,20],
[62,30,24,21],
[62,30,24,22],
[62,30,26,0],
[62,30,26,1],
[62,30,26,2],
[62,30,26,3],
[62,30,26,4],
[62,30,26,5],
[62,30,26,6],
[62,30,26,8],
[62,30,26,10],
[62,30,26,11],
[62,30,26,12],
[62,30,26,13],
[62,30,26,14],
[62,30,26,16],
[62,30,26,17],
[62,30,26,19],
[62,30,26,21],
[62,30,26,22],
[62,30,26,24],
[62,30,27,0],
[62,30,27,1],
[62,30,27,2],
[62,30,27,3],
[62,30,27,4],
[62,30,27,5],
[62,30,27,6],
[62,30,27,8],
[62,30,27,9],
[62,30,27,11],
[62,30,27,12],
[62,30,27,13],
[62,30,27,14],
[62,30,27,15],
[62,30,27,16],
[62,30,27,17],
[62,30,27,18],
[62,30,27,21],
[62,30,27,24],
[62,30,27,26],
[62,30,29,0],
[62,30,29,1],
[62,30,29,2],
[62,30,29,3],
[62,30,29,4],
[62,30,29,5],
[62,30,29,6],
[62,30,29,8],
[62,30,29,9],
[62,30,29,10],
[62,30,29,13],
[62,30,29,14],
[62,30,29,15],
[62,30,29,18],
[62,30,29,19],
[62,30,29,20],
[62,30,29,21],
[62,30,29,22],
[62,30,29,24],
[62,30,29,26],
[62,30,29,27],
[62,31,3,0],
[62,31,3,1],
[62,31,3,2],
[62,31,4,0],
[62,31,4,1],
[62,31,4,2],
[62,31,4,3],
[62,31,5,0],
[62,31,5,1],
[62,31,5,2],
[62,31,5,4],
[62,31,6,0],
[62,31,6,1],
[62,31,6,2],
[62,31,6,4],
[62,31,8,0],
[62,31,8,1],
[62,31,8,2],
[62,31,8,4],
[62,31,9,0],
[62,31,9,1],
[62,31,9,2],
[62,31,9,3],
[62,31,9,4],
[62,31,9,5],
[62,31,9,6],
[62,31,9,8],
[62,31,10,0],
[62,31,10,1],
[62,31,10,2],
[62,31,10,3],
[62,31,10,4],
[62,31,10,5],
[62,31,10,6],
[62,31,10,8],
[62,31,10,9],
[62,31,11,0],
[62,31,11,1],
[62,31,11,2],
[62,31,11,3],
[62,31,11,4],
[62,31,11,5],
[62,31,11,6],
[62,31,11,8],
[62,31,11,9],
[62,31,11,10],
[62,31,12,0],
[62,31,12,1],
[62,31,12,2],
[62,31,12,3],
[62,31,12,4],
[62,31,12,5],
[62,31,12,6],
[62,31,12,8],
[62,31,12,9],
[62,31,12,10],
[62,31,13,0],
[62,31,13,1],
[62,31,13,2],
[62,31,13,3],
[62,31,13,4],
[62,31,13,5],
[62,31,13,6],
[62,31,13,8],
[62,31,13,9],
[62,31,13,10],
[62,31,13,11],
[62,31,13,12],
[62,31,14,0],
[62,31,14,1],
[62,31,14,2],
[62,31,14,3],
[62,31,14,4],
[62,31,14,5],
[62,31,14,6],
[62,31,14,8],
[62,31,14,9],
[62,31,14,10],
[62,31,14,11],
[62,31,14,12],
[62,31,15,0],
[62,31,15,1],
[62,31,15,2],
[62,31,15,3],
[62,31,15,4],
[62,31,15,5],
[62,31,15,6],
[62,31,15,8],
[62,31,15,10],
[62,31,15,11],
[62,31,15,12],
[62,31,15,13],
[62,31,15,14],
[62,31,16,0],
[62,31,16,1],
[62,31,16,2],
[62,31,16,3],
[62,31,16,4],
[62,31,16,5],
[62,31,16,6],
[62,31,16,8],
[62,31,16,9],
[62,31,16,10],
[62,31,16,11],
[62,31,16,12],
[62,31,16,13],
[62,31,16,14],
[62,31,16,15],
[62,31,17,0],
[62,31,17,1],
[62,31,17,2],
[62,31,17,3],
[62,31,17,4],
[62,31,17,5],
[62,31,17,6],
[62,31,17,8],
[62,31,17,9],
[62,31,17,10],
[62,31,17,11],
[62,31,17,12],
[62,31,17,13],
[62,31,17,14],
[62,31,17,15],
[62,31,17,16],
[62,31,18,0],
[62,31,18,1],
[62,31,18,2],
[62,31,18,3],
[62,31,18,4],
[62,31,18,5],
[62,31,18,6],
[62,31,18,8],
[62,31,18,10],
[62,31,18,11],
[62,31,18,12],
[62,31,18,13],
[62,31,18,14],
[62,31,18,16],
[62,31,18,17],
[62,31,19,0],
[62,31,19,1],
[62,31,19,2],
[62,31,19,3],
[62,31,19,4],
[62,31,19,5],
[62,31,19,6],
[62,31,19,8],
[62,31,19,9],
[62,31,19,10],
[62,31,19,11],
[62,31,19,12],
[62,31,19,15],
[62,31,19,16],
[62,31,19,17],
[62,31,19,18],
[62,31,20,0],
[62,31,20,1],
[62,31,20,2],
[62,31,20,3],
[62,31,20,4],
[62,31,20,5],
[62,31,20,6],
[62,31,20,8],
[62,31,20,9],
[62,31,20,11],
[62,31,20,12],
[62,31,20,13],
[62,31,20,14],
[62,31,20,15],
[62,31,20,16],
[62,31,20,17],
[62,31,20,18],
[62,31,20,19],
[62,31,21,0],
[62,31,21,1],
[62,31,21,2],
[62,31,21,4],
[62,31,21,9],
[62,31,21,10],
[62,31,21,11],
[62,31,21,12],
[62,31,21,13],
[62,31,21,14],
[62,31,21,15],
[62,31,21,16],
[62,31,21,17],
[62,31,21,18],
[62,31,21,19],
[62,31,21,20],
[62,31,22,0],
[62,31,22,1],
[62,31,22,2],
[62,31,22,3],
[62,31,22,4],
[62,31,22,5],
[62,31,22,6],
[62,31,22,8],
[62,31,22,9],
[62,31,22,11],
[62,31,22,12],
[62,31,22,13],
[62,31,22,14],
[62,31,22,15],
[62,31,22,16],
[62,31,22,17],
[62,31,22,18],
[62,31,22,19],
[62,31,22,21],
[62,31,24,0],
[62,31,24,1],
[62,31,24,2],
[62,31,24,3],
[62,31,24,4],
[62,31,24,5],
[62,31,24,6],
[62,31,24,8],
[62,31,24,9],
[62,31,24,10],
[62,31,24,11],
[62,31,24,12],
[62,31,24,13],
[62,31,24,14],
[62,31,24,15],
[62,31,24,17],
[62,31,24,18],
[62,31,24,19],
[62,31,24,20],
[62,31,24,21],
[62,31,26,0],
[62,31,26,1],
[62,31,26,2],
[62,31,26,3],
[62,31,26,4],
[62,31,26,5],
[62,31,26,6],
[62,31,26,8],
[62,31,26,10],
[62,31,26,11],
[62,31,26,12],
[62,31,26,13],
[62,31,26,14],
[62,31,26,16],
[62,31,26,17],
[62,31,26,20],
[62,31,26,21],
[62,31,26,22],
[62,31,26,24],
[62,31,27,0],
[62,31,27,1],
[62,31,27,2],
[62,31,27,3],
[62,31,27,4],
[62,31,27,5],
[62,31,27,6],
[62,31,27,8],
[62,31,27,9],
[62,31,27,11],
[62,31,27,12],
[62,31,27,13],
[62,31,27,14],
[62,31,27,15],
[62,31,27,16],
[62,31,27,17],
[62,31,27,19],
[62,31,27,21],
[62,31,27,24],
[62,31,27,26],
[62,31,29,0],
[62,31,29,1],
[62,31,29,2],
[62,31,29,3],
[62,31,29,4],
[62,31,29,5],
[62,31,29,6],
[62,31,29,8],
[62,31,29,9],
[62,31,29,10],
[62,31,29,13],
[62,31,29,14],
[62,31,29,16],
[62,31,29,17],
[62,31,29,18],
[62,31,29,19],
[62,31,29,20],
[62,31,29,21],
[62,31,29,22],
[62,31,29,24],
[62,31,29,26],
[62,31,29,27],
[62,32,3,0],
[62,32,3,1],
[62,32,3,2],
[62,32,4,0],
[62,32,4,1],
[62,32,4,2],
[62,32,4,3],
[62,32,5,0],
[62,32,5,1],
[62,32,5,2],
[62,32,5,4],
[62,32,6,0],
[62,32,6,1],
[62,32,6,2],
[62,32,6,4],
[62,32,8,0],
[62,32,8,1],
[62,32,8,2],
[62,32,8,4],
[62,32,9,0],
[62,32,9,1],
[62,32,9,2],
[62,32,9,3],
[62,32,9,4],
[62,32,9,5],
[62,32,9,6],
[62,32,9,8],
[62,32,10,0],
[62,32,10,1],
[62,32,10,2],
[62,32,10,3],
[62,32,10,4],
[62,32,10,5],
[62,32,10,6],
[62,32,10,8],
[62,32,10,9],
[62,32,13,0],
[62,32,13,1],
[62,32,13,2],
[62,32,13,3],
[62,32,13,4],
[62,32,13,5],
[62,32,13,6],
[62,32,13,8],
[62,32,13,9],
[62,32,13,10],
[62,32,14,0],
[62,32,14,1],
[62,32,14,2],
[62,32,14,3],
[62,32,14,4],
[62,32,14,5],
[62,32,14,6],
[62,32,14,8],
[62,32,14,9],
[62,32,14,10],
[62,32,15,0],
[62,32,15,1],
[62,32,15,2],
[62,32,15,3],
[62,32,15,4],
[62,32,15,5],
[62,32,15,6],
[62,32,15,8],
[62,32,15,10],
[62,32,15,13],
[62,32,15,14],
[62,32,16,0],
[62,32,16,1],
[62,32,16,2],
[62,32,16,3],
[62,32,16,4],
[62,32,16,5],
[62,32,16,6],
[62,32,16,8],
[62,32,16,9],
[62,32,16,10],
[62,32,16,13],
[62,32,16,14],
[62,32,16,15],
[62,32,17,0],
[62,32,17,1],
[62,32,17,2],
[62,32,17,3],
[62,32,17,4],
[62,32,17,5],
[62,32,17,6],
[62,32,17,8],
[62,32,17,9],
[62,32,17,10],
[62,32,17,13],
[62,32,17,14],
[62,32,17,15],
[62,32,17,16],
[62,32,18,0],
[62,32,18,1],
[62,32,18,2],
[62,32,18,3],
[62,32,18,4],
[62,32,18,5],
[62,32,18,6],
[62,32,18,8],
[62,32,18,10],
[62,32,18,13],
[62,32,18,14],
[62,32,18,16],
[62,32,18,17],
[62,32,19,0],
[62,32,19,1],
[62,32,19,2],
[62,32,19,3],
[62,32,19,4],
[62,32,19,5],
[62,32,19,6],
[62,32,19,8],
[62,32,19,9],
[62,32,19,10],
[62,32,19,15],
[62,32,19,16],
[62,32,19,17],
[62,32,19,18],
[62,32,20,0],
[62,32,20,1],
[62,32,20,2],
[62,32,20,3],
[62,32,20,4],
[62,32,20,5],
[62,32,20,6],
[62,32,20,8],
[62,32,20,9],
[62,32,20,13],
[62,32,20,14],
[62,32,20,15],
[62,32,20,16],
[62,32,20,17],
[62,32,20,18],
[62,32,20,19],
[62,32,21,0],
[62,32,21,1],
[62,32,21,2],
[62,32,21,4],
[62,32,21,9],
[62,32,21,10],
[62,32,21,13],
[62,32,21,14],
[62,32,21,15],
[62,32,21,16],
[62,32,21,17],
[62,32,21,18],
[62,32,21,19],
[62,32,21,20],
[62,32,22,0],
[62,32,22,1],
[62,32,22,2],
[62,32,22,3],
[62,32,22,4],
[62,32,22,5],
[62,32,22,6],
[62,32,22,8],
[62,32,22,9],
[62,32,22,13],
[62,32,22,14],
[62,32,22,15],
[62,32,22,16],
[62,32,22,17],
[62,32,22,18],
[62,32,22,19],
[62,32,22,21],
[62,32,23,0],
[62,32,23,1],
[62,32,23,2],
[62,32,23,3],
[62,32,23,4],
[62,32,23,5],
[62,32,23,6],
[62,32,23,8],
[62,32,23,9],
[62,32,23,10],
[62,32,23,13],
[62,32,23,14],
[62,32,23,15],
[62,32,23,16],
[62,32,23,17],
[62,32,23,18],
[62,32,23,19],
[62,32,23,20],
[62,32,23,22],
[62,32,24,0],
[62,32,24,1],
[62,32,24,2],
[62,32,24,3],
[62,32,24,4],
[62,32,24,5],
[62,32,24,6],
[62,32,24,8],
[62,32,24,9],
[62,32,24,10],
[62,32,24,13],
[62,32,24,14],
[62,32,24,15],
[62,32,24,17],
[62,32,24,18],
[62,32,24,19],
[62,32,24,20],
[62,32,24,22],
[62,32,24,23],
[62,32,26,0],
[62,32,26,1],
[62,32,26,2],
[62,32,26,3],
[62,32,26,4],
[62,32,26,5],
[62,32,26,6],
[62,32,26,8],
[62,32,26,10],
[62,32,26,13],
[62,32,26,14],
[62,32,26,16],
[62,32,26,17],
[62,32,26,19],
[62,32,26,20],
[62,32,26,21],
[62,32,26,22],
[62,32,26,23],
[62,32,26,24],
[62,32,27,0],
[62,32,27,1],
[62,32,27,2],
[62,32,27,3],
[62,32,27,4],
[62,32,27,5],
[62,32,27,6],
[62,32,27,8],
[62,32,27,9],
[62,32,27,13],
[62,32,27,14],
[62,32,27,15],
[62,32,27,18],
[62,32,27,19],
[62,32,27,21],
[62,32,27,23],
[62,32,27,24],
[62,32,27,26],
[62,32,30,0],
[62,32,30,1],
[62,32,30,2],
[62,32,30,3],
[62,32,30,4],
[62,32,30,5],
[62,32,30,6],
[62,32,30,8],
[62,32,30,9],
[62,32,30,10],
[62,32,30,14],
[62,32,30,15],
[62,32,30,16],
[62,32,30,17],
[62,32,30,18],
[62,32,30,19],
[62,32,30,20],
[62,32,30,21],
[62,32,30,22],
[62,32,30,24],
[62,32,30,26],
[62,32,31,0],
[62,32,31,1],
[62,32,31,2],
[62,32,31,3],
[62,32,31,4],
[62,32,31,5],
[62,32,31,6],
[62,32,31,8],
[62,32,31,9],
[62,32,31,13],
[62,32,31,14],
[62,32,31,15],
[62,32,31,16],
[62,32,31,17],
[62,32,31,18],
[62,32,31,19],
[62,32,31,20],
[62,32,31,21],
[62,32,31,22],
[62,32,31,24],
[62,33,3,0],
[62,33,3,1],
[62,33,3,2],
[62,33,4,0],
[62,33,4,1],
[62,33,4,2],
[62,33,4,3],
[62,33,5,0],
[62,33,5,1],
[62,33,5,2],
[62,33,5,4],
[62,33,6,0],
[62,33,6,1],
[62,33,6,2],
[62,33,6,4],
[62,33,8,0],
[62,33,8,1],
[62,33,8,2],
[62,33,8,4],
[62,33,9,0],
[62,33,9,1],
[62,33,9,2],
[62,33,9,3],
[62,33,9,4],
[62,33,9,5],
[62,33,9,6],
[62,33,9,8],
[62,33,10,0],
[62,33,10,1],
[62,33,10,2],
[62,33,10,3],
[62,33,10,4],
[62,33,10,5],
[62,33,10,6],
[62,33,10,8],
[62,33,10,9],
[62,33,13,0],
[62,33,13,1],
[62,33,13,2],
[62,33,13,3],
[62,33,13,4],
[62,33,13,5],
[62,33,13,6],
[62,33,13,8],
[62,33,13,9],
[62,33,13,10],
[62,33,14,0],
[62,33,14,1],
[62,33,14,2],
[62,33,14,3],
[62,33,14,4],
[62,33,14,5],
[62,33,14,6],
[62,33,14,8],
[62,33,14,9],
[62,33,14,10],
[62,33,15,0],
[62,33,15,1],
[62,33,15,2],
[62,33,15,3],
[62,33,15,4],
[62,33,15,5],
[62,33,15,6],
[62,33,15,8],
[62,33,15,10],
[62,33,15,13],
[62,33,15,14],
[62,33,16,0],
[62,33,16,1],
[62,33,16,2],
[62,33,16,3],
[62,33,16,4],
[62,33,16,5],
[62,33,16,6],
[62,33,16,8],
[62,33,16,9],
[62,33,16,10],
[62,33,16,13],
[62,33,16,14],
[62,33,16,15],
[62,33,17,0],
[62,33,17,1],
[62,33,17,2],
[62,33,17,3],
[62,33,17,4],
[62,33,17,5],
[62,33,17,6],
[62,33,17,8],
[62,33,17,9],
[62,33,17,10],
[62,33,17,13],
[62,33,17,14],
[62,33,17,15],
[62,33,17,16],
[62,33,18,0],
[62,33,18,1],
[62,33,18,2],
[62,33,18,3],
[62,33,18,4],
[62,33,18,5],
[62,33,18,6],
[62,33,18,8],
[62,33,18,10],
[62,33,18,13],
[62,33,18,14],
[62,33,18,16],
[62,33,18,17],
[62,33,19,0],
[62,33,19,1],
[62,33,19,2],
[62,33,19,3],
[62,33,19,4],
[62,33,19,5],
[62,33,19,6],
[62,33,19,8],
[62,33,19,9],
[62,33,19,10],
[62,33,19,15],
[62,33,19,16],
[62,33,19,17],
[62,33,19,18],
[62,33,20,0],
[62,33,20,1],
[62,33,20,2],
[62,33,20,3],
[62,33,20,4],
[62,33,20,5],
[62,33,20,6],
[62,33,20,8],
[62,33,20,9],
[62,33,20,13],
[62,33,20,14],
[62,33,20,15],
[62,33,20,16],
[62,33,20,17],
[62,33,20,18],
[62,33,20,19],
[62,33,21,0],
[62,33,21,1],
[62,33,21,2],
[62,33,21,4],
[62,33,21,9],
[62,33,21,10],
[62,33,21,13],
[62,33,21,14],
[62,33,21,15],
[62,33,21,16],
[62,33,21,17],
[62,33,21,18],
[62,33,21,19],
[62,33,21,20],
[62,33,22,0],
[62,33,22,1],
[62,33,22,2],
[62,33,22,3],
[62,33,22,4],
[62,33,22,5],
[62,33,22,6],
[62,33,22,8],
[62,33,22,9],
[62,33,22,13],
[62,33,22,14],
[62,33,22,15],
[62,33,22,16],
[62,33,22,17],
[62,33,22,18],
[62,33,22,19],
[62,33,23,0],
[62,33,23,1],
[62,33,23,2],
[62,33,23,3],
[62,33,23,4],
[62,33,23,5],
[62,33,23,6],
[62,33,23,8],
[62,33,23,9],
[62,33,23,10],
[62,33,23,13],
[62,33,23,14],
[62,33,23,15],
[62,33,23,16],
[62,33,23,17],
[62,33,23,18],
[62,33,23,19],
[62,33,23,21],
[62,33,23,22],
[62,33,24,0],
[62,33,24,1],
[62,33,24,2],
[62,33,24,3],
[62,33,24,4],
[62,33,24,5],
[62,33,24,6],
[62,33,24,8],
[62,33,24,9],
[62,33,24,10],
[62,33,24,13],
[62,33,24,14],
[62,33,24,15],
[62,33,24,17],
[62,33,24,18],
[62,33,24,19],
[62,33,24,21],
[62,33,24,22],
[62,33,24,23],
[62,33,26,0],
[62,33,26,1],
[62,33,26,2],
[62,33,26,3],
[62,33,26,4],
[62,33,26,5],
[62,33,26,6],
[62,33,26,8],
[62,33,26,10],
[62,33,26,13],
[62,33,26,14],
[62,33,26,19],
[62,33,26,20],
[62,33,26,21],
[62,33,26,22],
[62,33,26,23],
[62,33,26,24],
[62,33,27,0],
[62,33,27,1],
[62,33,27,2],
[62,33,27,3],
[62,33,27,4],
[62,33,27,5],
[62,33,27,6],
[62,33,27,8],
[62,33,27,9],
[62,33,27,13],
[62,33,27,14],
[62,33,27,16],
[62,33,27,17],
[62,33,27,18],
[62,33,27,19],
[62,33,27,21],
[62,33,27,23],
[62,33,27,24],
[62,33,27,26],
[62,33,30,0],
[62,33,30,1],
[62,33,30,2],
[62,33,30,3],
[62,33,30,4],
[62,33,30,5],
[62,33,30,6],
[62,33,30,8],
[62,33,30,9],
[62,33,30,13],
[62,33,30,14],
[62,33,30,15],
[62,33,30,16],
[62,33,30,17],
[62,33,30,18],
[62,33,30,19],
[62,33,30,20],
[62,33,30,21],
[62,33,30,22],
[62,33,30,24],
[62,33,31,0],
[62,33,31,1],
[62,33,31,2],
[62,33,31,3],
[62,33,31,4],
[62,33,31,5],
[62,33,31,6],
[62,33,31,10],
[62,33,31,13],
[62,33,31,14],
[62,33,31,15],
[62,33,31,16],
[62,33,31,17],
[62,33,31,18],
[62,33,31,19],
[62,33,31,20],
[62,33,31,21],
[62,33,31,22],
[62,33,31,24],
[62,35,3,0],
[62,35,3,1],
[62,35,3,2],
[62,35,4,0],
[62,35,4,1],
[62,35,4,2],
[62,35,4,3],
[62,35,5,0],
[62,35,5,1],
[62,35,5,2],
[62,35,5,4],
[62,35,6,0],
[62,35,6,1],
[62,35,6,2],
[62,35,6,4],
[62,35,8,0],
[62,35,8,1],
[62,35,8,2],
[62,35,8,4],
[62,35,10,0],
[62,35,10,1],
[62,35,10,2],
[62,35,10,3],
[62,35,10,4],
[62,35,10,5],
[62,35,10,6],
[62,35,10,8],
[62,35,11,0],
[62,35,11,1],
[62,35,11,2],
[62,35,11,3],
[62,35,11,4],
[62,35,11,5],
[62,35,11,6],
[62,35,11,8],
[62,35,11,10],
[62,35,12,0],
[62,35,12,1],
[62,35,12,2],
[62,35,12,3],
[62,35,12,4],
[62,35,12,5],
[62,35,12,6],
[62,35,12,8],
[62,35,12,10],
[62,35,13,0],
[62,35,13,1],
[62,35,13,2],
[62,35,13,3],
[62,35,13,4],
[62,35,13,5],
[62,35,13,6],
[62,35,13,8],
[62,35,13,10],
[62,35,13,11],
[62,35,13,12],
[62,35,14,0],
[62,35,14,1],
[62,35,14,2],
[62,35,14,3],
[62,35,14,4],
[62,35,14,5],
[62,35,14,6],
[62,35,14,8],
[62,35,14,10],
[62,35,14,11],
[62,35,14,12],
[62,35,16,0],
[62,35,16,1],
[62,35,16,2],
[62,35,16,3],
[62,35,16,4],
[62,35,16,5],
[62,35,16,6],
[62,35,16,8],
[62,35,16,10],
[62,35,16,11],
[62,35,16,12],
[62,35,16,13],
[62,35,16,14],
[62,35,17,0],
[62,35,17,1],
[62,35,17,2],
[62,35,17,3],
[62,35,17,4],
[62,35,17,5],
[62,35,17,6],
[62,35,17,8],
[62,35,17,10],
[62,35,17,11],
[62,35,17,12],
[62,35,17,13],
[62,35,17,14],
[62,35,17,16],
[62,35,19,0],
[62,35,19,1],
[62,35,19,2],
[62,35,19,3],
[62,35,19,4],
[62,35,19,5],
[62,35,19,6],
[62,35,19,8],
[62,35,19,10],
[62,35,19,11],
[62,35,19,12],
[62,35,19,16],
[62,35,19,17],
[62,35,20,0],
[62,35,20,1],
[62,35,20,2],
[62,35,20,3],
[62,35,20,4],
[62,35,20,5],
[62,35,20,6],
[62,35,20,8],
[62,35,20,11],
[62,35,20,12],
[62,35,20,13],
[62,35,20,14],
[62,35,20,16],
[62,35,20,17],
[62,35,20,19],
[62,35,21,0],
[62,35,21,1],
[62,35,21,2],
[62,35,21,4],
[62,35,21,10],
[62,35,21,11],
[62,35,21,12],
[62,35,21,13],
[62,35,21,14],
[62,35,21,16],
[62,35,21,17],
[62,35,21,19],
[62,35,22,0],
[62,35,22,1],
[62,35,22,2],
[62,35,22,3],
[62,35,22,4],
[62,35,22,5],
[62,35,22,6],
[62,35,22,8],
[62,35,22,11],
[62,35,22,12],
[62,35,22,13],
[62,35,22,14],
[62,35,22,16],
[62,35,22,17],
[62,35,22,21],
[62,35,23,0],
[62,35,23,1],
[62,35,23,2],
[62,35,23,3],
[62,35,23,4],
[62,35,23,5],
[62,35,23,6],
[62,35,23,8],
[62,35,23,10],
[62,35,23,11],
[62,35,23,12],
[62,35,23,13],
[62,35,23,14],
[62,35,23,16],
[62,35,23,17],
[62,35,23,19],
[62,35,23,20],
[62,35,23,21],
[62,35,23,22],
[62,35,24,0],
[62,35,24,1],
[62,35,24,2],
[62,35,24,3],
[62,35,24,4],
[62,35,24,5],
[62,35,24,6],
[62,35,24,8],
[62,35,24,10],
[62,35,24,11],
[62,35,24,12],
[62,35,24,13],
[62,35,24,14],
[62,35,24,17],
[62,35,24,19],
[62,35,24,20],
[62,35,24,21],
[62,35,24,22],
[62,35,24,23],
[62,35,27,0],
[62,35,27,1],
[62,35,27,2],
[62,35,27,3],
[62,35,27,4],
[62,35,27,5],
[62,35,27,6],
[62,35,27,8],
[62,35,27,11],
[62,35,27,14],
[62,35,27,16],
[62,35,27,17],
[62,35,27,19],
[62,35,27,21],
[62,35,27,23],
[62,35,27,24],
[62,35,29,0],
[62,35,29,1],
[62,35,29,2],
[62,35,29,3],
[62,35,29,4],
[62,35,29,5],
[62,35,29,6],
[62,35,29,10],
[62,35,29,13],
[62,35,29,14],
[62,35,29,16],
[62,35,29,17],
[62,35,29,19],
[62,35,29,20],
[62,35,29,21],
[62,35,29,22],
[62,35,29,23],
[62,35,29,24],
[62,35,30,0],
[62,35,30,1],
[62,35,30,2],
[62,35,30,3],
[62,35,30,4],
[62,35,30,5],
[62,35,30,8],
[62,35,30,10],
[62,35,30,11],
[62,35,30,12],
[62,35,30,13],
[62,35,30,14],
[62,35,30,16],
[62,35,30,17],
[62,35,30,19],
[62,35,30,20],
[62,35,30,21],
[62,35,30,22],
[62,35,30,24],
[62,35,31,0],
[62,35,31,1],
[62,35,31,2],
[62,35,31,3],
[62,35,31,4],
[62,35,31,6],
[62,35,31,8],
[62,35,31,10],
[62,35,31,11],
[62,35,31,12],
[62,35,31,13],
[62,35,31,14],
[62,35,31,16],
[62,35,31,17],
[62,35,31,19],
[62,35,31,20],
[62,35,31,21],
[62,35,31,22],
[62,35,32,0],
[62,35,32,1],
[62,35,32,2],
[62,35,32,5],
[62,35,32,6],
[62,35,32,8],
[62,35,32,10],
[62,35,32,13],
[62,35,32,14],
[62,35,32,16],
[62,35,32,17],
[62,35,32,19],
[62,35,32,20],
[62,35,32,21],
[62,35,33,3],
[62,35,33,4],
[62,35,33,5],
[62,35,33,6],
[62,35,33,8],
[62,35,33,10],
[62,35,33,13],
[62,35,33,14],
[62,35,33,16],
[62,35,33,17],
[62,35,33,19],
[62,35,33,20],
[62,36,3,0],
[62,36,3,1],
[62,36,3,2],
[62,36,4,0],
[62,36,4,1],
[62,36,4,2],
[62,36,4,3],
[62,36,5,0],
[62,36,5,1],
[62,36,5,2],
[62,36,5,4],
[62,36,6,0],
[62,36,6,1],
[62,36,6,2],
[62,36,6,4],
[62,36,8,0],
[62,36,8,1],
[62,36,8,2],
[62,36,8,4],
[62,36,9,0],
[62,36,9,1],
[62,36,9,2],
[62,36,9,3],
[62,36,9,4],
[62,36,9,5],
[62,36,9,6],
[62,36,9,8],
[62,36,10,0],
[62,36,10,1],
[62,36,10,2],
[62,36,10,3],
[62,36,10,4],
[62,36,10,5],
[62,36,10,6],
[62,36,10,8],
[62,36,10,9],
[62,36,11,0],
[62,36,11,1],
[62,36,11,2],
[62,36,11,3],
[62,36,11,4],
[62,36,11,5],
[62,36,11,6],
[62,36,11,8],
[62,36,11,9],
[62,36,11,10],
[62,36,12,0],
[62,36,12,1],
[62,36,12,2],
[62,36,12,3],
[62,36,12,4],
[62,36,12,5],
[62,36,12,6],
[62,36,12,8],
[62,36,12,9],
[62,36,12,10],
[62,36,15,0],
[62,36,15,1],
[62,36,15,2],
[62,36,15,3],
[62,36,15,4],
[62,36,15,5],
[62,36,15,6],
[62,36,15,8],
[62,36,15,10],
[62,36,15,11],
[62,36,15,12],
[62,36,16,0],
[62,36,16,1],
[62,36,16,2],
[62,36,16,3],
[62,36,16,4],
[62,36,16,5],
[62,36,16,6],
[62,36,16,8],
[62,36,16,9],
[62,36,16,10],
[62,36,16,11],
[62,36,16,12],
[62,36,16,15],
[62,36,17,0],
[62,36,17,1],
[62,36,17,2],
[62,36,17,3],
[62,36,17,4],
[62,36,17,5],
[62,36,17,6],
[62,36,17,8],
[62,36,17,9],
[62,36,17,10],
[62,36,17,11],
[62,36,17,12],
[62,36,17,15],
[62,36,17,16],
[62,36,18,0],
[62,36,18,1],
[62,36,18,2],
[62,36,18,3],
[62,36,18,4],
[62,36,18,5],
[62,36,18,6],
[62,36,18,8],
[62,36,18,10],
[62,36,18,11],
[62,36,18,12],
[62,36,18,16],
[62,36,18,17],
[62,36,20,0],
[62,36,20,1],
[62,36,20,2],
[62,36,20,3],
[62,36,20,4],
[62,36,20,5],
[62,36,20,6],
[62,36,20,8],
[62,36,20,9],
[62,36,20,11],
[62,36,20,12],
[62,36,20,15],
[62,36,20,16],
[62,36,20,17],
[62,36,20,18],
[62,36,21,0],
[62,36,21,1],
[62,36,21,2],
[62,36,21,4],
[62,36,21,9],
[62,36,21,10],
[62,36,21,11],
[62,36,21,12],
[62,36,21,15],
[62,36,21,16],
[62,36,21,17],
[62,36,21,18],
[62,36,21,20],
[62,36,22,0],
[62,36,22,1],
[62,36,22,2],
[62,36,22,3],
[62,36,22,4],
[62,36,22,5],
[62,36,22,6],
[62,36,22,8],
[62,36,22,9],
[62,36,22,11],
[62,36,22,12],
[62,36,22,15],
[62,36,22,16],
[62,36,22,17],
[62,36,22,21],
[62,36,23,0],
[62,36,23,1],
[62,36,23,2],
[62,36,23,3],
[62,36,23,4],
[62,36,23,5],
[62,36,23,6],
[62,36,23,8],
[62,36,23,9],
[62,36,23,10],
[62,36,23,11],
[62,36,23,12],
[62,36,23,15],
[62,36,23,18],
[62,36,23,20],
[62,36,23,21],
[62,36,23,22],
[62,36,24,0],
[62,36,24,1],
[62,36,24,2],
[62,36,24,3],
[62,36,24,4],
[62,36,24,5],
[62,36,24,6],
[62,36,24,8],
[62,36,24,9],
[62,36,24,10],
[62,36,24,11],
[62,36,24,12],
[62,36,24,15],
[62,36,24,18],
[62,36,24,20],
[62,36,24,21],
[62,36,24,22],
[62,36,24,23],
[62,36,26,0],
[62,36,26,1],
[62,36,26,2],
[62,36,26,3],
[62,36,26,4],
[62,36,26,5],
[62,36,26,6],
[62,36,26,8],
[62,36,26,10],
[62,36,26,11],
[62,36,26,16],
[62,36,26,17],
[62,36,26,20],
[62,36,26,21],
[62,36,26,22],
[62,36,26,23],
[62,36,26,24],
[62,36,27,0],
[62,36,27,1],
[62,36,27,2],
[62,36,27,3],
[62,36,27,4],
[62,36,27,5],
[62,36,27,6],
[62,36,27,8],
[62,36,27,9],
[62,36,27,12],
[62,36,27,15],
[62,36,27,16],
[62,36,27,17],
[62,36,27,18],
[62,36,27,21],
[62,36,27,23],
[62,36,27,24],
[62,36,29,0],
[62,36,29,1],
[62,36,29,2],
[62,36,29,3],
[62,36,29,4],
[62,36,29,5],
[62,36,29,8],
[62,36,29,9],
[62,36,29,10],
[62,36,29,15],
[62,36,29,16],
[62,36,29,17],
[62,36,29,18],
[62,36,29,20],
[62,36,29,21],
[62,36,29,22],
[62,36,29,23],
[62,36,29,24],
[62,36,30,0],
[62,36,30,1],
[62,36,30,2],
[62,36,30,3],
[62,36,30,4],
[62,36,30,6],
[62,36,30,8],
[62,36,30,9],
[62,36,30,10],
[62,36,30,11],
[62,36,30,12],
[62,36,30,15],
[62,36,30,16],
[62,36,30,17],
[62,36,30,18],
[62,36,30,20],
[62,36,30,21],
[62,36,30,22],
[62,36,31,0],
[62,36,31,1],
[62,36,31,2],
[62,36,31,5],
[62,36,31,6],
[62,36,31,8],
[62,36,31,9],
[62,36,31,10],
[62,36,31,11],
[62,36,31,12],
[62,36,31,15],
[62,36,31,16],
[62,36,31,17],
[62,36,31,18],
[62,36,31,20],
[62,36,31,21],
[62,36,32,3],
[62,36,32,4],
[62,36,32,5],
[62,36,32,6],
[62,36,32,8],
[62,36,32,9],
[62,36,32,10],
[62,36,32,15],
[62,36,32,16],
[62,36,32,17],
[62,36,32,18],
[62,36,32,20],
[62,36,33,0],
[62,36,33,1],
[62,36,33,2],
[62,36,33,3],
[62,36,33,4],
[62,36,33,5],
[62,36,33,6],
[62,36,33,8],
[62,36,33,9],
[62,36,33,10],
[62,36,33,15],
[62,36,33,16],
[62,36,33,17],
[62,36,33,18],
[62,36,35,0],
[62,36,35,1],
[62,36,35,2],
[62,36,35,3],
[62,36,35,4],
[62,36,35,5],
[62,36,35,6],
[62,36,35,8],
[62,36,35,10],
[62,36,35,11],
[62,36,35,12],
[62,36,35,16],
[62,36,35,17],
[62,37,3,0],
[62,37,3,1],
[62,37,3,2],
[62,37,5,0],
[62,37,5,1],
[62,37,5,2],
[62,37,6,0],
[62,37,6,1],
[62,37,6,2],
[62,37,8,0],
[62,37,8,1],
[62,37,8,2],
[62,37,9,0],
[62,37,9,1],
[62,37,9,2],
[62,37,9,3],
[62,37,9,5],
[62,37,9,6],
[62,37,9,8],
[62,37,10,0],
[62,37,10,1],
[62,37,10,2],
[62,37,10,3],
[62,37,10,5],
[62,37,10,6],
[62,37,10,8],
[62,37,10,9],
[62,37,11,0],
[62,37,11,1],
[62,37,11,2],
[62,37,11,3],
[62,37,11,5],
[62,37,11,6],
[62,37,11,8],
[62,37,11,9],
[62,37,11,10],
[62,37,12,0],
[62,37,12,1],
[62,37,12,2],
[62,37,12,3],
[62,37,12,5],
[62,37,12,6],
[62,37,12,8],
[62,37,12,9],
[62,37,12,10],
[62,37,13,0],
[62,37,13,1],
[62,37,13,2],
[62,37,13,3],
[62,37,13,5],
[62,37,13,6],
[62,37,13,8],
[62,37,13,9],
[62,37,13,10],
[62,37,13,11],
[62,37,13,12],
[62,37,14,0],
[62,37,14,1],
[62,37,14,2],
[62,37,14,3],
[62,37,14,5],
[62,37,14,6],
[62,37,14,8],
[62,37,14,9],
[62,37,14,10],
[62,37,14,11],
[62,37,14,12],
[62,37,15,0],
[62,37,15,1],
[62,37,15,2],
[62,37,15,3],
[62,37,15,5],
[62,37,15,6],
[62,37,15,8],
[62,37,15,10],
[62,37,15,11],
[62,37,15,12],
[62,37,15,13],
[62,37,15,14],
[62,37,16,0],
[62,37,16,1],
[62,37,16,2],
[62,37,16,3],
[62,37,16,5],
[62,37,16,6],
[62,37,16,8],
[62,37,16,9],
[62,37,16,10],
[62,37,16,11],
[62,37,16,12],
[62,37,16,13],
[62,37,16,14],
[62,37,16,15],
[62,37,17,0],
[62,37,17,1],
[62,37,17,2],
[62,37,17,3],
[62,37,17,5],
[62,37,17,6],
[62,37,17,8],
[62,37,17,9],
[62,37,17,10],
[62,37,17,11],
[62,37,17,12],
[62,37,17,13],
[62,37,17,14],
[62,37,17,15],
[62,37,17,16],
[62,37,18,0],
[62,37,18,1],
[62,37,18,2],
[62,37,18,3],
[62,37,18,5],
[62,37,18,6],
[62,37,18,8],
[62,37,18,10],
[62,37,18,11],
[62,37,18,12],
[62,37,18,13],
[62,37,18,14],
[62,37,18,16],
[62,37,18,17],
[62,37,19,0],
[62,37,19,1],
[62,37,19,2],
[62,37,19,3],
[62,37,19,5],
[62,37,19,6],
[62,37,19,8],
[62,37,19,9],
[62,37,19,10],
[62,37,19,11],
[62,37,19,12],
[62,37,19,15],
[62,37,19,16],
[62,37,19,17],
[62,37,19,18],
[62,37,20,0],
[62,37,20,1],
[62,37,20,2],
[62,37,20,3],
[62,37,20,5],
[62,37,20,6],
[62,37,20,8],
[62,37,20,9],
[62,37,20,11],
[62,37,20,12],
[62,37,20,13],
[62,37,20,14],
[62,37,20,15],
[62,37,20,16],
[62,37,20,17],
[62,37,20,18],
[62,37,21,0],
[62,37,21,1],
[62,37,21,2],
[62,37,21,9],
[62,37,21,10],
[62,37,21,11],
[62,37,21,12],
[62,37,21,13],
[62,37,21,14],
[62,37,21,15],
[62,37,21,16],
[62,37,21,17],
[62,37,21,19],
[62,37,21,20],
[62,37,22,0],
[62,37,22,1],
[62,37,22,2],
[62,37,22,3],
[62,37,22,5],
[62,37,22,6],
[62,37,22,8],
[62,37,22,9],
[62,37,22,11],
[62,37,22,12],
[62,37,22,13],
[62,37,22,14],
[62,37,22,15],
[62,37,22,18],
[62,37,22,19],
[62,37,22,21],
[62,37,23,0],
[62,37,23,1],
[62,37,23,2],
[62,37,23,3],
[62,37,23,5],
[62,37,23,6],
[62,37,23,8],
[62,37,23,9],
[62,37,23,10],
[62,37,23,11],
[62,37,23,12],
[62,37,23,13],
[62,37,23,14],
[62,37,23,16],
[62,37,23,17],
[62,37,23,18],
[62,37,23,19],
[62,37,23,20],
[62,37,23,21],
[62,37,23,22],
[62,37,24,0],
[62,37,24,1],
[62,37,24,2],
[62,37,24,3],
[62,37,24,5],
[62,37,24,6],
[62,37,24,8],
[62,37,24,9],
[62,37,24,10],
[62,37,24,11],
[62,37,24,12],
[62,37,24,13],
[62,37,24,14],
[62,37,24,17],
[62,37,24,18],
[62,37,24,19],
[62,37,24,20],
[62,37,24,21],
[62,37,24,22],
[62,37,24,23],
[62,37,26,0],
[62,37,26,1],
[62,37,26,2],
[62,37,26,3],
[62,37,26,5],
[62,37,26,6],
[62,37,26,8],
[62,37,26,12],
[62,37,26,13],
[62,37,26,14],
[62,37,26,16],
[62,37,26,17],
[62,37,26,19],
[62,37,26,20],
[62,37,26,21],
[62,37,26,22],
[62,37,26,23],
[62,37,26,24],
[62,37,27,0],
[62,37,27,1],
[62,37,27,2],
[62,37,27,3],
[62,37,27,5],
[62,37,27,6],
[62,37,27,11],
[62,37,27,12],
[62,37,27,13],
[62,37,27,14],
[62,37,27,15],
[62,37,27,16],
[62,37,27,17],
[62,37,27,18],
[62,37,27,19],
[62,37,27,21],
[62,37,27,23],
[62,37,27,24],
[62,37,29,0],
[62,37,29,1],
[62,37,29,2],
[62,37,29,3],
[62,37,29,6],
[62,37,29,8],
[62,37,29,9],
[62,37,29,10],
[62,37,29,13],
[62,37,29,14],
[62,37,29,15],
[62,37,29,16],
[62,37,29,17],
[62,37,29,18],
[62,37,29,19],
[62,37,29,20],
[62,37,29,21],
[62,37,29,22],
[62,37,30,0],
[62,37,30,1],
[62,37,30,2],
[62,37,30,5],
[62,37,30,6],
[62,37,30,8],
[62,37,30,9],
[62,37,30,10],
[62,37,30,11],
[62,37,30,12],
[62,37,30,13],
[62,37,30,14],
[62,37,30,15],
[62,37,30,16],
[62,37,30,17],
[62,37,30,18],
[62,37,30,19],
[62,37,30,20],
[62,37,30,21],
[62,37,31,3],
[62,37,31,5],
[62,37,31,6],
[62,37,31,8],
[62,37,31,9],
[62,37,31,10],
[62,37,31,11],
[62,37,31,12],
[62,37,31,13],
[62,37,31,14],
[62,37,31,15],
[62,37,31,16],
[62,37,31,17],
[62,37,31,18],
[62,37,31,19],
[62,37,31,20],
[62,37,32,0],
[62,37,32,1],
[62,37,32,2],
[62,37,32,3],
[62,37,32,5],
[62,37,32,6],
[62,37,32,8],
[62,37,32,9],
[62,37,32,10],
[62,37,32,13],
[62,37,32,14],
[62,37,32,15],
[62,37,32,16],
[62,37,32,17],
[62,37,32,18],
[62,37,32,19],
[62,37,33,0],
[62,37,33,1],
[62,37,33,2],
[62,37,33,3],
[62,37,33,5],
[62,37,33,6],
[62,37,33,8],
[62,37,33,9],
[62,37,33,10],
[62,37,33,13],
[62,37,33,14],
[62,37,33,15],
[62,37,33,16],
[62,37,33,17],
[62,37,33,18],
[62,37,35,0],
[62,37,35,1],
[62,37,35,2],
[62,37,35,3],
[62,37,35,5],
[62,37,35,6],
[62,37,35,8],
[62,37,35,10],
[62,37,35,11],
[62,37,35,12],
[62,37,35,13],
[62,37,35,14],
[62,37,36,0],
[62,37,36,1],
[62,37,36,2],
[62,37,36,3],
[62,37,36,5],
[62,37,36,6],
[62,37,36,8],
[62,37,36,9],
[62,37,36,10],
[62,37,36,11],
[62,37,36,12],
[62,39,4,0],
[62,39,4,1],
[62,39,4,2],
[62,39,9,0],
[62,39,9,1],
[62,39,9,2],
[62,39,9,4],
[62,39,10,0],
[62,39,10,1],
[62,39,10,2],
[62,39,10,4],
[62,39,10,9],
[62,39,11,0],
[62,39,11,1],
[62,39,11,2],
[62,39,11,4],
[62,39,11,9],
[62,39,11,10],
[62,39,12,0],
[62,39,12,1],
[62,39,12,2],
[62,39,12,4],
[62,39,12,9],
[62,39,12,10],
[62,39,13,0],
[62,39,13,1],
[62,39,13,2],
[62,39,13,4],
[62,39,13,9],
[62,39,13,10],
[62,39,13,11],
[62,39,13,12],
[62,39,14,0],
[62,39,14,1],
[62,39,14,2],
[62,39,14,4],
[62,39,14,9],
[62,39,14,10],
[62,39,14,11],
[62,39,14,12],
[62,39,15,0],
[62,39,15,1],
[62,39,15,2],
[62,39,15,4],
[62,39,15,10],
[62,39,15,11],
[62,39,15,12],
[62,39,15,13],
[62,39,15,14],
[62,39,16,0],
[62,39,16,1],
[62,39,16,2],
[62,39,16,4],
[62,39,16,9],
[62,39,16,10],
[62,39,16,11],
[62,39,16,12],
[62,39,16,13],
[62,39,16,14],
[62,39,16,15],
[62,39,17,0],
[62,39,17,1],
[62,39,17,2],
[62,39,17,4],
[62,39,17,9],
[62,39,17,10],
[62,39,17,11],
[62,39,17,12],
[62,39,17,13],
[62,39,17,14],
[62,39,17,15],
[62,39,17,16],
[62,39,18,0],
[62,39,18,1],
[62,39,18,2],
[62,39,18,4],
[62,39,18,10],
[62,39,18,11],
[62,39,18,12],
[62,39,18,13],
[62,39,18,14],
[62,39,18,16],
[62,39,18,17],
[62,39,19,0],
[62,39,19,1],
[62,39,19,2],
[62,39,19,4],
[62,39,19,9],
[62,39,19,10],
[62,39,19,11],
[62,39,19,12],
[62,39,19,15],
[62,39,19,16],
[62,39,19,17],
[62,39,20,0],
[62,39,20,1],
[62,39,20,2],
[62,39,20,4],
[62,39,20,9],
[62,39,20,11],
[62,39,20,12],
[62,39,20,13],
[62,39,20,14],
[62,39,20,15],
[62,39,20,18],
[62,39,20,19],
[62,39,22,0],
[62,39,22,1],
[62,39,22,2],
[62,39,22,4],
[62,39,22,9],
[62,39,22,11],
[62,39,22,12],
[62,39,22,13],
[62,39,22,15],
[62,39,22,16],
[62,39,22,17],
[62,39,22,18],
[62,39,22,19],
[62,39,23,0],
[62,39,23,1],
[62,39,23,2],
[62,39,23,4],
[62,39,23,9],
[62,39,23,10],
[62,39,23,11],
[62,39,23,14],
[62,39,23,15],
[62,39,23,16],
[62,39,23,17],
[62,39,23,18],
[62,39,23,19],
[62,39,23,20],
[62,39,23,22],
[62,39,24,0],
[62,39,24,1],
[62,39,24,2],
[62,39,24,4],
[62,39,24,9],
[62,39,24,10],
[62,39,24,11],
[62,39,24,14],
[62,39,24,15],
[62,39,24,17],
[62,39,24,18],
[62,39,24,19],
[62,39,24,20],
[62,39,24,22],
[62,39,24,23],
[62,39,26,0],
[62,39,26,1],
[62,39,26,2],
[62,39,26,4],
[62,39,26,10],
[62,39,26,11],
[62,39,26,12],
[62,39,26,13],
[62,39,26,14],
[62,39,26,16],
[62,39,26,17],
[62,39,26,19],
[62,39,26,20],
[62,39,26,22],
[62,39,26,23],
[62,39,26,24],
[62,39,27,0],
[62,39,27,1],
[62,39,27,2],
[62,39,27,4],
[62,39,27,9],
[62,39,27,11],
[62,39,27,12],
[62,39,27,13],
[62,39,27,14],
[62,39,27,15],
[62,39,27,16],
[62,39,27,17],
[62,39,27,18],
[62,39,27,19],
[62,39,29,4],
[62,39,29,9],
[62,39,29,10],
[62,39,29,13],
[62,39,29,14],
[62,39,29,15],
[62,39,29,16],
[62,39,29,17],
[62,39,29,18],
[62,39,29,19],
[62,39,29,20],
[62,39,30,0],
[62,39,30,1],
[62,39,30,2],
[62,39,30,4],
[62,39,30,9],
[62,39,30,10],
[62,39,30,11],
[62,39,30,12],
[62,39,30,13],
[62,39,30,14],
[62,39,30,15],
[62,39,30,16],
[62,39,30,17],
[62,39,30,18],
[62,39,30,19],
[62,39,31,0],
[62,39,31,1],
[62,39,31,2],
[62,39,31,4],
[62,39,31,9],
[62,39,31,10],
[62,39,31,11],
[62,39,31,12],
[62,39,31,13],
[62,39,31,14],
[62,39,31,15],
[62,39,31,16],
[62,39,31,17],
[62,39,31,18],
[62,39,32,0],
[62,39,32,1],
[62,39,32,2],
[62,39,32,4],
[62,39,32,9],
[62,39,32,10],
[62,39,32,13],
[62,39,32,14],
[62,39,32,15],
[62,39,32,16],
[62,39,32,17],
[62,39,33,0],
[62,39,33,1],
[62,39,33,2],
[62,39,33,4],
[62,39,33,9],
[62,39,33,10],
[62,39,33,13],
[62,39,33,14],
[62,39,33,15],
[62,39,35,0],
[62,39,35,1],
[62,39,35,2],
[62,39,35,4],
[62,39,35,10],
[62,39,35,11],
[62,39,35,12],
[62,39,35,13],
[62,39,36,0],
[62,39,36,1],
[62,39,36,2],
[62,39,36,4],
[62,39,36,9],
[62,39,36,10],
[62,39,36,11],
[62,39,37,0],
[62,39,37,1],
[62,39,37,2],
[62,39,37,9],
[62,40,3,0],
[62,40,3,1],
[62,40,3,2],
[62,40,4,0],
[62,40,4,1],
[62,40,4,2],
[62,40,4,3],
[62,40,5,0],
[62,40,5,1],
[62,40,5,2],
[62,40,5,4],
[62,40,6,0],
[62,40,6,1],
[62,40,6,2],
[62,40,6,4],
[62,40,8,0],
[62,40,8,1],
[62,40,8,2],
[62,40,8,4],
[62,40,10,0],
[62,40,10,1],
[62,40,10,2],
[62,40,10,3],
[62,40,10,4],
[62,40,10,5],
[62,40,10,6],
[62,40,10,8],
[62,40,11,0],
[62,40,11,1],
[62,40,11,2],
[62,40,11,3],
[62,40,11,4],
[62,40,11,5],
[62,40,11,6],
[62,40,11,8],
[62,40,11,10],
[62,40,12,0],
[62,40,12,1],
[62,40,12,2],
[62,40,12,3],
[62,40,12,4],
[62,40,12,5],
[62,40,12,6],
[62,40,12,8],
[62,40,12,10],
[62,40,13,0],
[62,40,13,1],
[62,40,13,2],
[62,40,13,3],
[62,40,13,4],
[62,40,13,5],
[62,40,13,6],
[62,40,13,8],
[62,40,13,10],
[62,40,13,11],
[62,40,13,12],
[62,40,14,0],
[62,40,14,1],
[62,40,14,2],
[62,40,14,3],
[62,40,14,4],
[62,40,14,5],
[62,40,14,6],
[62,40,14,8],
[62,40,14,10],
[62,40,14,11],
[62,40,14,12],
[62,40,16,0],
[62,40,16,1],
[62,40,16,2],
[62,40,16,3],
[62,40,16,4],
[62,40,16,5],
[62,40,16,6],
[62,40,16,8],
[62,40,16,10],
[62,40,16,11],
[62,40,16,12],
[62,40,16,13],
[62,40,16,14],
[62,40,17,0],
[62,40,17,1],
[62,40,17,2],
[62,40,17,3],
[62,40,17,4],
[62,40,17,5],
[62,40,17,6],
[62,40,17,8],
[62,40,17,10],
[62,40,17,11],
[62,40,17,12],
[62,40,17,13],
[62,40,17,14],
[62,40,17,16],
[62,40,19,0],
[62,40,19,1],
[62,40,19,2],
[62,40,19,3],
[62,40,19,4],
[62,40,19,5],
[62,40,19,6],
[62,40,19,8],
[62,40,19,10],
[62,40,19,11],
[62,40,19,12],
[62,40,20,0],
[62,40,20,1],
[62,40,20,2],
[62,40,20,3],
[62,40,20,4],
[62,40,20,5],
[62,40,20,6],
[62,40,20,8],
[62,40,20,11],
[62,40,20,12],
[62,40,20,13],
[62,40,20,14],
[62,40,20,16],
[62,40,20,17],
[62,40,20,19],
[62,40,21,0],
[62,40,21,1],
[62,40,21,2],
[62,40,21,4],
[62,40,21,10],
[62,40,21,11],
[62,40,21,12],
[62,40,21,13],
[62,40,21,16],
[62,40,21,17],
[62,40,21,19],
[62,40,21,20],
[62,40,22,0],
[62,40,22,1],
[62,40,22,2],
[62,40,22,3],
[62,40,22,4],
[62,40,22,5],
[62,40,22,6],
[62,40,22,8],
[62,40,22,11],
[62,40,22,14],
[62,40,22,16],
[62,40,22,17],
[62,40,22,19],
[62,40,22,21],
[62,40,23,0],
[62,40,23,1],
[62,40,23,2],
[62,40,23,3],
[62,40,23,4],
[62,40,23,5],
[62,40,23,6],
[62,40,23,8],
[62,40,23,12],
[62,40,23,13],
[62,40,23,14],
[62,40,23,16],
[62,40,23,17],
[62,40,23,19],
[62,40,23,20],
[62,40,23,21],
[62,40,23,22],
[62,40,24,0],
[62,40,24,1],
[62,40,24,2],
[62,40,24,3],
[62,40,24,4],
[62,40,24,5],
[62,40,24,6],
[62,40,24,8],
[62,40,24,12],
[62,40,24,13],
[62,40,24,14],
[62,40,24,17],
[62,40,24,19],
[62,40,24,20],
[62,40,24,21],
[62,40,24,22],
[62,40,24,23],
[62,40,27,0],
[62,40,27,1],
[62,40,27,2],
[62,40,27,5],
[62,40,27,6],
[62,40,27,8],
[62,40,27,11],
[62,40,27,12],
[62,40,27,13],
[62,40,27,14],
[62,40,27,16],
[62,40,27,17],
[62,40,27,19],
[62,40,27,21],
[62,40,29,0],
[62,40,29,1],
[62,40,29,2],
[62,40,29,3],
[62,40,29,4],
[62,40,29,5],
[62,40,29,6],
[62,40,29,8],
[62,40,29,10],
[62,40,29,13],
[62,40,29,14],
[62,40,29,16],
[62,40,29,17],
[62,40,29,19],
[62,40,30,0],
[62,40,30,1],
[62,40,30,2],
[62,40,30,3],
[62,40,30,4],
[62,40,30,5],
[62,40,30,6],
[62,40,30,8],
[62,40,30,10],
[62,40,30,11],
[62,40,30,12],
[62,40,30,13],
[62,40,30,14],
[62,40,30,16],
[62,40,30,17],
[62,40,31,0],
[62,40,31,1],
[62,40,31,2],
[62,40,31,3],
[62,40,31,4],
[62,40,31,5],
[62,40,31,6],
[62,40,31,8],
[62,40,31,10],
[62,40,31,11],
[62,40,31,12],
[62,40,31,13],
[62,40,31,14],
[62,40,31,16],
[62,40,31,17],
[62,40,32,0],
[62,40,32,1],
[62,40,32,2],
[62,40,32,3],
[62,40,32,4],
[62,40,32,5],
[62,40,32,6],
[62,40,32,8],
[62,40,32,10],
[62,40,32,13],
[62,40,32,14],
[62,40,33,0],
[62,40,33,1],
[62,40,33,2],
[62,40,33,3],
[62,40,33,4],
[62,40,33,5],
[62,40,33,6],
[62,40,33,8],
[62,40,33,10],
[62,40,33,13],
[62,40,33,14],
[62,40,36,0],
[62,40,36,1],
[62,40,36,2],
[62,40,36,3],
[62,40,36,4],
[62,40,36,5],
[62,40,36,6],
[62,40,36,8],
[62,40,37,0],
[62,40,37,1],
[62,40,37,2],
[62,40,37,3],
[62,40,37,5],
[62,40,37,6],
[62,40,39,0],
[62,40,39,1],
[62,40,39,2],
[62,40,39,4],
[62,41,3,0],
[62,41,3,1],
[62,41,3,2],
[62,41,4,0],
[62,41,4,1],
[62,41,4,2],
[62,41,4,3],
[62,41,5,0],
[62,41,5,1],
[62,41,5,2],
[62,41,5,4],
[62,41,6,0],
[62,41,6,1],
[62,41,6,2],
[62,41,6,4],
[62,41,8,0],
[62,41,8,1],
[62,41,8,2],
[62,41,8,4],
[62,41,10,0],
[62,41,10,1],
[62,41,10,2],
[62,41,10,3],
[62,41,10,4],
[62,41,10,5],
[62,41,10,6],
[62,41,10,8],
[62,41,11,0],
[62,41,11,1],
[62,41,11,2],
[62,41,11,3],
[62,41,11,4],
[62,41,11,5],
[62,41,11,6],
[62,41,11,8],
[62,41,11,10],
[62,41,12,0],
[62,41,12,1],
[62,41,12,2],
[62,41,12,3],
[62,41,12,4],
[62,41,12,5],
[62,41,12,6],
[62,41,12,8],
[62,41,12,10],
[62,41,13,0],
[62,41,13,1],
[62,41,13,2],
[62,41,13,3],
[62,41,13,4],
[62,41,13,5],
[62,41,13,6],
[62,41,13,8],
[62,41,13,10],
[62,41,13,11],
[62,41,13,12],
[62,41,14,0],
[62,41,14,1],
[62,41,14,2],
[62,41,14,3],
[62,41,14,4],
[62,41,14,5],
[62,41,14,6],
[62,41,14,8],
[62,41,14,10],
[62,41,14,11],
[62,41,14,12],
[62,41,16,0],
[62,41,16,1],
[62,41,16,2],
[62,41,16,3],
[62,41,16,4],
[62,41,16,5],
[62,41,16,6],
[62,41,16,8],
[62,41,16,10],
[62,41,16,11],
[62,41,16,12],
[62,41,16,13],
[62,41,16,14],
[62,41,17,0],
[62,41,17,1],
[62,41,17,2],
[62,41,17,3],
[62,41,17,4],
[62,41,17,5],
[62,41,17,6],
[62,41,17,8],
[62,41,17,10],
[62,41,17,11],
[62,41,17,12],
[62,41,17,13],
[62,41,17,14],
[62,41,17,16],
[62,41,19,0],
[62,41,19,1],
[62,41,19,2],
[62,41,19,3],
[62,41,19,4],
[62,41,19,5],
[62,41,19,6],
[62,41,19,8],
[62,41,19,10],
[62,41,19,11],
[62,41,19,12],
[62,41,20,0],
[62,41,20,1],
[62,41,20,2],
[62,41,20,3],
[62,41,20,4],
[62,41,20,5],
[62,41,20,6],
[62,41,20,8],
[62,41,20,11],
[62,41,20,12],
[62,41,20,13],
[62,41,20,14],
[62,41,20,16],
[62,41,20,17],
[62,41,20,19],
[62,41,21,0],
[62,41,21,1],
[62,41,21,2],
[62,41,21,4],
[62,41,21,10],
[62,41,21,11],
[62,41,21,12],
[62,41,21,13],
[62,41,21,16],
[62,41,21,17],
[62,41,21,19],
[62,41,21,20],
[62,41,22,0],
[62,41,22,1],
[62,41,22,2],
[62,41,22,3],
[62,41,22,4],
[62,41,22,5],
[62,41,22,6],
[62,41,22,8],
[62,41,22,11],
[62,41,22,14],
[62,41,22,16],
[62,41,22,17],
[62,41,22,19],
[62,41,22,21],
[62,41,23,0],
[62,41,23,1],
[62,41,23,2],
[62,41,23,3],
[62,41,23,4],
[62,41,23,5],
[62,41,23,6],
[62,41,23,8],
[62,41,23,12],
[62,41,23,13],
[62,41,23,14],
[62,41,23,16],
[62,41,23,17],
[62,41,23,19],
[62,41,23,20],
[62,41,23,21],
[62,41,23,22],
[62,41,24,0],
[62,41,24,1],
[62,41,24,2],
[62,41,24,3],
[62,41,24,4],
[62,41,24,5],
[62,41,24,6],
[62,41,24,8],
[62,41,24,12],
[62,41,24,13],
[62,41,24,14],
[62,41,24,17],
[62,41,24,19],
[62,41,24,20],
[62,41,24,21],
[62,41,24,22],
[62,41,24,23],
[62,41,27,0],
[62,41,27,1],
[62,41,27,2],
[62,41,27,5],
[62,41,27,6],
[62,41,27,8],
[62,41,27,11],
[62,41,27,12],
[62,41,27,13],
[62,41,27,14],
[62,41,27,16],
[62,41,27,17],
[62,41,27,19],
[62,41,27,21],
[62,41,29,0],
[62,41,29,1],
[62,41,29,2],
[62,41,29,3],
[62,41,29,4],
[62,41,29,5],
[62,41,29,6],
[62,41,29,8],
[62,41,29,10],
[62,41,29,13],
[62,41,29,14],
[62,41,29,16],
[62,41,29,17],
[62,41,29,19],
[62,41,30,0],
[62,41,30,1],
[62,41,30,2],
[62,41,30,3],
[62,41,30,4],
[62,41,30,5],
[62,41,30,6],
[62,41,30,8],
[62,41,30,10],
[62,41,30,11],
[62,41,30,12],
[62,41,30,13],
[62,41,30,14],
[62,41,30,16],
[62,41,30,17],
[62,41,31,0],
[62,41,31,1],
[62,41,31,2],
[62,41,31,3],
[62,41,31,4],
[62,41,31,5],
[62,41,31,6],
[62,41,31,8],
[62,41,31,10],
[62,41,31,11],
[62,41,31,12],
[62,41,31,13],
[62,41,31,14],
[62,41,31,16],
[62,41,31,17],
[62,41,32,0],
[62,41,32,1],
[62,41,32,2],
[62,41,32,3],
[62,41,32,4],
[62,41,32,5],
[62,41,32,6],
[62,41,32,8],
[62,41,32,10],
[62,41,32,13],
[62,41,32,14],
[62,41,33,0],
[62,41,33,1],
[62,41,33,2],
[62,41,33,3],
[62,41,33,4],
[62,41,33,5],
[62,41,33,6],
[62,41,33,8],
[62,41,33,10],
[62,41,33,13],
[62,41,33,14],
[62,41,36,0],
[62,41,36,1],
[62,41,36,2],
[62,41,36,3],
[62,41,36,4],
[62,41,36,5],
[62,41,36,6],
[62,41,36,8],
[62,41,37,0],
[62,41,37,1],
[62,41,37,2],
[62,41,37,3],
[62,41,37,5],
[62,41,37,6],
[62,41,39,0],
[62,41,39,1],
[62,41,39,2],
[62,41,39,4],
[62,42,3,0],
[62,42,3,1],
[62,42,3,2],
[62,42,4,0],
[62,42,4,1],
[62,42,4,2],
[62,42,4,3],
[62,42,5,0],
[62,42,5,1],
[62,42,5,2],
[62,42,5,4],
[62,42,6,0],
[62,42,6,1],
[62,42,6,2],
[62,42,6,4],
[62,42,8,0],
[62,42,8,1],
[62,42,8,2],
[62,42,8,4],
[62,42,9,0],
[62,42,9,1],
[62,42,9,2],
[62,42,9,3],
[62,42,9,4],
[62,42,9,5],
[62,42,9,6],
[62,42,9,8],
[62,42,11,0],
[62,42,11,1],
[62,42,11,2],
[62,42,11,3],
[62,42,11,4],
[62,42,11,5],
[62,42,11,6],
[62,42,11,8],
[62,42,11,9],
[62,42,12,0],
[62,42,12,1],
[62,42,12,2],
[62,42,12,3],
[62,42,12,4],
[62,42,12,5],
[62,42,12,6],
[62,42,12,8],
[62,42,12,9],
[62,42,13,0],
[62,42,13,1],
[62,42,13,2],
[62,42,13,3],
[62,42,13,4],
[62,42,13,5],
[62,42,13,6],
[62,42,13,8],
[62,42,13,9],
[62,42,13,11],
[62,42,13,12],
[62,42,14,0],
[62,42,14,1],
[62,42,14,2],
[62,42,14,3],
[62,42,14,4],
[62,42,14,5],
[62,42,14,6],
[62,42,14,8],
[62,42,14,9],
[62,42,14,11],
[62,42,14,12],
[62,42,15,0],
[62,42,15,1],
[62,42,15,2],
[62,42,15,3],
[62,42,15,4],
[62,42,15,5],
[62,42,15,6],
[62,42,15,8],
[62,42,15,11],
[62,42,15,12],
[62,42,15,13],
[62,42,15,14],
[62,42,16,0],
[62,42,16,1],
[62,42,16,2],
[62,42,16,3],
[62,42,16,4],
[62,42,16,5],
[62,42,16,6],
[62,42,16,8],
[62,42,16,9],
[62,42,16,11],
[62,42,16,12],
[62,42,16,13],
[62,42,16,14],
[62,42,16,15],
[62,42,17,0],
[62,42,17,1],
[62,42,17,2],
[62,42,17,3],
[62,42,17,4],
[62,42,17,5],
[62,42,17,6],
[62,42,17,8],
[62,42,17,9],
[62,42,17,11],
[62,42,17,12],
[62,42,17,13],
[62,42,17,14],
[62,42,17,15],
[62,42,17,16],
[62,42,18,0],
[62,42,18,1],
[62,42,18,2],
[62,42,18,3],
[62,42,18,4],
[62,42,18,5],
[62,42,18,6],
[62,42,18,8],
[62,42,18,11],
[62,42,18,12],
[62,42,18,13],
[62,42,18,14],
[62,42,19,0],
[62,42,19,1],
[62,42,19,2],
[62,42,19,3],
[62,42,19,4],
[62,42,19,5],
[62,42,19,6],
[62,42,19,8],
[62,42,19,9],
[62,42,19,11],
[62,42,19,12],
[62,42,19,16],
[62,42,19,17],
[62,42,19,18],
[62,42,21,0],
[62,42,21,1],
[62,42,21,2],
[62,42,21,4],
[62,42,21,9],
[62,42,21,11],
[62,42,21,14],
[62,42,21,15],
[62,42,21,16],
[62,42,21,17],
[62,42,21,18],
[62,42,21,19],
[62,42,23,0],
[62,42,23,1],
[62,42,23,2],
[62,42,23,3],
[62,42,23,4],
[62,42,23,5],
[62,42,23,6],
[62,42,23,11],
[62,42,23,12],
[62,42,23,13],
[62,42,23,14],
[62,42,23,15],
[62,42,23,16],
[62,42,23,17],
[62,42,23,18],
[62,42,23,19],
[62,42,23,21],
[62,42,24,0],
[62,42,24,1],
[62,42,24,2],
[62,42,24,3],
[62,42,24,4],
[62,42,24,5],
[62,42,24,6],
[62,42,24,11],
[62,42,24,12],
[62,42,24,13],
[62,42,24,14],
[62,42,24,15],
[62,42,24,17],
[62,42,24,18],
[62,42,24,19],
[62,42,24,21],
[62,42,24,23],
[62,42,26,0],
[62,42,26,1],
[62,42,26,2],
[62,42,26,5],
[62,42,26,6],
[62,42,26,8],
[62,42,26,11],
[62,42,26,12],
[62,42,26,13],
[62,42,26,14],
[62,42,26,16],
[62,42,26,17],
[62,42,26,19],
[62,42,26,21],
[62,42,29,0],
[62,42,29,1],
[62,42,29,2],
[62,42,29,3],
[62,42,29,4],
[62,42,29,5],
[62,42,29,6],
[62,42,29,8],
[62,42,29,9],
[62,42,29,13],
[62,42,29,14],
[62,42,29,15],
[62,42,29,16],
[62,42,29,17],
[62,42,29,18],
[62,42,30,0],
[62,42,30,1],
[62,42,30,2],
[62,42,30,3],
[62,42,30,4],
[62,42,30,5],
[62,42,30,6],
[62,42,30,8],
[62,42,30,9],
[62,42,30,11],
[62,42,30,12],
[62,42,30,13],
[62,42,30,14],
[62,42,30,15],
[62,42,30,16],
[62,42,30,17],
[62,42,31,0],
[62,42,31,1],
[62,42,31,2],
[62,42,31,3],
[62,42,31,4],
[62,42,31,5],
[62,42,31,6],
[62,42,31,8],
[62,42,31,9],
[62,42,31,11],
[62,42,31,12],
[62,42,31,13],
[62,42,31,14],
[62,42,31,15],
[62,42,32,0],
[62,42,32,1],
[62,42,32,2],
[62,42,32,3],
[62,42,32,4],
[62,42,32,5],
[62,42,32,6],
[62,42,32,8],
[62,42,32,9],
[62,42,32,13],
[62,42,32,14],
[62,42,33,0],
[62,42,33,1],
[62,42,33,2],
[62,42,33,3],
[62,42,33,4],
[62,42,33,5],
[62,42,33,6],
[62,42,33,8],
[62,42,33,9],
[62,42,33,13],
[62,42,35,0],
[62,42,35,1],
[62,42,35,2],
[62,42,35,3],
[62,42,35,4],
[62,42,35,5],
[62,42,35,6],
[62,42,35,8],
[62,42,36,0],
[62,42,36,1],
[62,42,36,2],
[62,42,36,3],
[62,42,36,4],
[62,42,36,5],
[62,42,36,6],
[62,42,37,0],
[62,42,37,1],
[62,42,37,2],
[62,42,37,3],
[62,42,37,5],
[62,42,39,0],
[62,42,39,1],
[62,42,39,2],
[62,44,3,0],
[62,44,3,1],
[62,44,3,2],
[62,44,4,0],
[62,44,4,1],
[62,44,4,2],
[62,44,4,3],
[62,44,5,0],
[62,44,5,1],
[62,44,5,2],
[62,44,5,4],
[62,44,6,0],
[62,44,6,1],
[62,44,6,2],
[62,44,6,4],
[62,44,8,0],
[62,44,8,1],
[62,44,8,2],
[62,44,8,4],
[62,44,9,0],
[62,44,9,1],
[62,44,9,2],
[62,44,9,3],
[62,44,9,4],
[62,44,9,5],
[62,44,9,6],
[62,44,9,8],
[62,44,10,0],
[62,44,10,1],
[62,44,10,2],
[62,44,10,3],
[62,44,10,4],
[62,44,10,5],
[62,44,10,6],
[62,44,10,8],
[62,44,10,9],
[62,44,11,0],
[62,44,11,1],
[62,44,11,2],
[62,44,11,3],
[62,44,11,4],
[62,44,11,5],
[62,44,11,6],
[62,44,11,8],
[62,44,11,9],
[62,44,11,10],
[62,44,12,0],
[62,44,12,1],
[62,44,12,2],
[62,44,12,3],
[62,44,12,4],
[62,44,12,5],
[62,44,12,6],
[62,44,12,8],
[62,44,12,9],
[62,44,12,10],
[62,44,13,0],
[62,44,13,1],
[62,44,13,2],
[62,44,13,3],
[62,44,13,4],
[62,44,13,5],
[62,44,13,6],
[62,44,13,8],
[62,44,13,9],
[62,44,13,10],
[62,44,13,11],
[62,44,13,12],
[62,44,14,0],
[62,44,14,1],
[62,44,14,2],
[62,44,14,3],
[62,44,14,4],
[62,44,14,5],
[62,44,14,6],
[62,44,14,8],
[62,44,14,9],
[62,44,14,10],
[62,44,14,11],
[62,44,14,12],
[62,44,15,0],
[62,44,15,1],
[62,44,15,2],
[62,44,15,3],
[62,44,15,4],
[62,44,15,5],
[62,44,15,6],
[62,44,15,8],
[62,44,15,10],
[62,44,15,11],
[62,44,15,12],
[62,44,15,13],
[62,44,15,14],
[62,44,16,0],
[62,44,16,1],
[62,44,16,2],
[62,44,16,3],
[62,44,16,4],
[62,44,16,5],
[62,44,16,6],
[62,44,16,8],
[62,44,16,9],
[62,44,16,10],
[62,44,16,11],
[62,44,16,12],
[62,44,16,13],
[62,44,16,14],
[62,44,18,0],
[62,44,18,1],
[62,44,18,2],
[62,44,18,3],
[62,44,18,4],
[62,44,18,5],
[62,44,18,6],
[62,44,18,8],
[62,44,18,10],
[62,44,18,11],
[62,44,18,12],
[62,44,18,13],
[62,44,18,16],
[62,44,19,0],
[62,44,19,1],
[62,44,19,2],
[62,44,19,3],
[62,44,19,4],
[62,44,19,5],
[62,44,19,6],
[62,44,19,8],
[62,44,19,9],
[62,44,19,10],
[62,44,19,11],
[62,44,19,15],
[62,44,19,16],
[62,44,19,18],
[62,44,20,0],
[62,44,20,1],
[62,44,20,2],
[62,44,20,3],
[62,44,20,4],
[62,44,20,5],
[62,44,20,6],
[62,44,20,8],
[62,44,20,9],
[62,44,20,12],
[62,44,20,13],
[62,44,20,14],
[62,44,20,15],
[62,44,20,16],
[62,44,20,18],
[62,44,20,19],
[62,44,21,0],
[62,44,21,1],
[62,44,21,2],
[62,44,21,4],
[62,44,21,10],
[62,44,21,11],
[62,44,21,12],
[62,44,21,13],
[62,44,21,14],
[62,44,21,15],
[62,44,21,16],
[62,44,21,18],
[62,44,21,19],
[62,44,21,20],
[62,44,22,0],
[62,44,22,1],
[62,44,22,2],
[62,44,22,3],
[62,44,22,4],
[62,44,22,5],
[62,44,22,8],
[62,44,22,9],
[62,44,22,11],
[62,44,22,12],
[62,44,22,13],
[62,44,22,14],
[62,44,22,15],
[62,44,22,16],
[62,44,22,18],
[62,44,22,19],
[62,44,22,21],
[62,44,23,0],
[62,44,23,1],
[62,44,23,2],
[62,44,23,3],
[62,44,23,4],
[62,44,23,6],
[62,44,23,8],
[62,44,23,9],
[62,44,23,10],
[62,44,23,11],
[62,44,23,12],
[62,44,23,13],
[62,44,23,14],
[62,44,23,15],
[62,44,23,16],
[62,44,23,18],
[62,44,23,19],
[62,44,23,20],
[62,44,23,21],
[62,44,23,22],
[62,44,24,0],
[62,44,24,1],
[62,44,24,2],
[62,44,24,3],
[62,44,24,4],
[62,44,24,6],
[62,44,24,8],
[62,44,24,9],
[62,44,24,10],
[62,44,24,11],
[62,44,24,12],
[62,44,24,13],
[62,44,24,14],
[62,44,24,15],
[62,44,24,18],
[62,44,24,19],
[62,44,24,20],
[62,44,24,21],
[62,44,24,22],
[62,44,26,0],
[62,44,26,1],
[62,44,26,2],
[62,44,26,3],
[62,44,26,4],
[62,44,26,5],
[62,44,26,6],
[62,44,26,8],
[62,44,26,10],
[62,44,26,11],
[62,44,26,12],
[62,44,26,13],
[62,44,26,14],
[62,44,26,16],
[62,44,26,19],
[62,44,27,0],
[62,44,27,1],
[62,44,27,2],
[62,44,27,3],
[62,44,27,4],
[62,44,27,5],
[62,44,27,6],
[62,44,27,8],
[62,44,27,9],
[62,44,27,11],
[62,44,27,12],
[62,44,27,13],
[62,44,27,14],
[62,44,27,15],
[62,44,27,16],
[62,44,27,18],
[62,44,29,0],
[62,44,29,1],
[62,44,29,2],
[62,44,29,3],
[62,44,29,4],
[62,44,29,5],
[62,44,29,6],
[62,44,29,8],
[62,44,29,9],
[62,44,29,10],
[62,44,29,13],
[62,44,29,14],
[62,44,29,15],
[62,44,30,0],
[62,44,30,1],
[62,44,30,2],
[62,44,30,3],
[62,44,30,4],
[62,44,30,5],
[62,44,30,6],
[62,44,30,8],
[62,44,30,9],
[62,44,30,10],
[62,44,30,11],
[62,44,30,12],
[62,44,30,13],
[62,44,30,14],
[62,44,31,0],
[62,44,31,1],
[62,44,31,2],
[62,44,31,3],
[62,44,31,4],
[62,44,31,5],
[62,44,31,6],
[62,44,31,8],
[62,44,31,9],
[62,44,31,10],
[62,44,31,11],
[62,44,31,12],
[62,44,31,13],
[62,44,32,0],
[62,44,32,1],
[62,44,32,2],
[62,44,32,3],
[62,44,32,4],
[62,44,32,5],
[62,44,32,6],
[62,44,32,8],
[62,44,32,9],
[62,44,32,10],
[62,44,33,0],
[62,44,33,1],
[62,44,33,2],
[62,44,33,3],
[62,44,33,4],
[62,44,33,5],
[62,44,33,6],
[62,44,33,8],
[62,44,33,9],
[62,44,35,0],
[62,44,35,1],
[62,44,35,2],
[62,44,35,3],
[62,44,35,4],
[62,44,35,5],
[62,44,36,0],
[62,44,36,1],
[62,44,36,2],
[62,44,36,3],
[62,44,36,4],
[62,44,37,0],
[62,44,37,1],
[62,44,37,2],
[62,45,3,0],
[62,45,3,1],
[62,45,3,2],
[62,45,4,0],
[62,45,4,1],
[62,45,4,2],
[62,45,4,3],
[62,45,5,0],
[62,45,5,1],
[62,45,5,2],
[62,45,5,4],
[62,45,6,0],
[62,45,6,1],
[62,45,6,2],
[62,45,6,4],
[62,45,8,0],
[62,45,8,1],
[62,45,8,2],
[62,45,8,4],
[62,45,10,0],
[62,45,10,1],
[62,45,10,2],
[62,45,10,3],
[62,45,10,4],
[62,45,10,5],
[62,45,10,6],
[62,45,10,8],
[62,45,11,0],
[62,45,11,1],
[62,45,11,2],
[62,45,11,3],
[62,45,11,4],
[62,45,11,5],
[62,45,11,6],
[62,45,11,8],
[62,45,11,10],
[62,45,12,0],
[62,45,12,1],
[62,45,12,2],
[62,45,12,3],
[62,45,12,4],
[62,45,12,5],
[62,45,12,6],
[62,45,12,8],
[62,45,12,10],
[62,45,13,0],
[62,45,13,1],
[62,45,13,2],
[62,45,13,3],
[62,45,13,4],
[62,45,13,5],
[62,45,13,6],
[62,45,13,8],
[62,45,13,10],
[62,45,13,11],
[62,45,13,12],
[62,45,14,0],
[62,45,14,1],
[62,45,14,2],
[62,45,14,3],
[62,45,14,4],
[62,45,14,5],
[62,45,14,6],
[62,45,14,8],
[62,45,14,10],
[62,45,14,11],
[62,45,14,12],
[62,45,16,0],
[62,45,16,1],
[62,45,16,2],
[62,45,16,3],
[62,45,16,4],
[62,45,16,5],
[62,45,16,6],
[62,45,16,8],
[62,45,16,10],
[62,45,16,11],
[62,45,16,12],
[62,45,16,13],
[62,45,17,0],
[62,45,17,1],
[62,45,17,2],
[62,45,17,3],
[62,45,17,4],
[62,45,17,5],
[62,45,17,6],
[62,45,17,8],
[62,45,17,10],
[62,45,17,11],
[62,45,17,12],
[62,45,17,13],
[62,45,17,16],
[62,45,19,0],
[62,45,19,1],
[62,45,19,2],
[62,45,19,3],
[62,45,19,4],
[62,45,19,5],
[62,45,19,6],
[62,45,19,8],
[62,45,19,12],
[62,45,19,16],
[62,45,19,17],
[62,45,20,0],
[62,45,20,1],
[62,45,20,2],
[62,45,20,3],
[62,45,20,4],
[62,45,20,5],
[62,45,20,6],
[62,45,20,11],
[62,45,20,12],
[62,45,20,13],
[62,45,20,14],
[62,45,20,16],
[62,45,20,17],
[62,45,20,19],
[62,45,21,0],
[62,45,21,1],
[62,45,21,2],
[62,45,21,4],
[62,45,21,10],
[62,45,21,11],
[62,45,21,12],
[62,45,21,13],
[62,45,21,14],
[62,45,21,16],
[62,45,21,17],
[62,45,21,19],
[62,45,21,20],
[62,45,22,0],
[62,45,22,1],
[62,45,22,2],
[62,45,22,3],
[62,45,22,4],
[62,45,22,6],
[62,45,22,8],
[62,45,22,11],
[62,45,22,12],
[62,45,22,13],
[62,45,22,14],
[62,45,22,16],
[62,45,22,17],
[62,45,22,19],
[62,45,22,21],
[62,45,23,0],
[62,45,23,1],
[62,45,23,2],
[62,45,23,5],
[62,45,23,6],
[62,45,23,8],
[62,45,23,10],
[62,45,23,11],
[62,45,23,12],
[62,45,23,13],
[62,45,23,14],
[62,45,23,16],
[62,45,23,17],
[62,45,23,19],
[62,45,23,20],
[62,45,23,21],
[62,45,24,0],
[62,45,24,1],
[62,45,24,2],
[62,45,24,5],
[62,45,24,6],
[62,45,24,8],
[62,45,24,10],
[62,45,24,11],
[62,45,24,12],
[62,45,24,13],
[62,45,24,14],
[62,45,24,17],
[62,45,24,19],
[62,45,24,20],
[62,45,24,21],
[62,45,27,0],
[62,45,27,1],
[62,45,27,2],
[62,45,27,3],
[62,45,27,4],
[62,45,27,5],
[62,45,27,6],
[62,45,27,8],
[62,45,27,11],
[62,45,27,12],
[62,45,27,13],
[62,45,27,14],
[62,45,27,16],
[62,45,27,17],
[62,45,29,0],
[62,45,29,1],
[62,45,29,2],
[62,45,29,3],
[62,45,29,4],
[62,45,29,5],
[62,45,29,6],
[62,45,29,8],
[62,45,29,10],
[62,45,29,13],
[62,45,29,14],
[62,45,30,0],
[62,45,30,1],
[62,45,30,2],
[62,45,30,3],
[62,45,30,4],
[62,45,30,5],
[62,45,30,6],
[62,45,30,8],
[62,45,30,10],
[62,45,30,11],
[62,45,30,12],
[62,45,30,13],
[62,45,31,0],
[62,45,31,1],
[62,45,31,2],
[62,45,31,3],
[62,45,31,4],
[62,45,31,5],
[62,45,31,6],
[62,45,31,8],
[62,45,31,10],
[62,45,31,11],
[62,45,32,0],
[62,45,32,1],
[62,45,32,2],
[62,45,32,3],
[62,45,32,4],
[62,45,32,5],
[62,45,32,6],
[62,45,32,8],
[62,45,33,0],
[62,45,33,1],
[62,45,33,2],
[62,45,33,3],
[62,45,33,4],
[62,45,33,5],
[62,45,33,6],
[62,45,36,0],
[62,45,36,1],
[62,45,36,2],
[62,46,3,0],
[62,46,3,1],
[62,46,3,2],
[62,46,4,0],
[62,46,4,1],
[62,46,4,2],
[62,46,4,3],
[62,46,5,0],
[62,46,5,1],
[62,46,5,2],
[62,46,5,4],
[62,46,6,0],
[62,46,6,1],
[62,46,6,2],
[62,46,6,4],
[62,46,8,0],
[62,46,8,1],
[62,46,8,2],
[62,46,8,4],
[62,46,10,0],
[62,46,10,1],
[62,46,10,2],
[62,46,10,3],
[62,46,10,4],
[62,46,10,5],
[62,46,10,6],
[62,46,10,8],
[62,46,11,0],
[62,46,11,1],
[62,46,11,2],
[62,46,11,3],
[62,46,11,4],
[62,46,11,5],
[62,46,11,6],
[62,46,11,8],
[62,46,11,10],
[62,46,12,0],
[62,46,12,1],
[62,46,12,2],
[62,46,12,3],
[62,46,12,4],
[62,46,12,5],
[62,46,12,6],
[62,46,12,8],
[62,46,12,10],
[62,46,13,0],
[62,46,13,1],
[62,46,13,2],
[62,46,13,3],
[62,46,13,4],
[62,46,13,5],
[62,46,13,6],
[62,46,13,8],
[62,46,13,10],
[62,46,13,11],
[62,46,13,12],
[62,46,14,0],
[62,46,14,1],
[62,46,14,2],
[62,46,14,3],
[62,46,14,4],
[62,46,14,5],
[62,46,14,6],
[62,46,14,8],
[62,46,14,10],
[62,46,14,11],
[62,46,14,12],
[62,46,16,0],
[62,46,16,1],
[62,46,16,2],
[62,46,16,3],
[62,46,16,4],
[62,46,16,5],
[62,46,16,6],
[62,46,16,8],
[62,46,16,10],
[62,46,16,11],
[62,46,16,14],
[62,46,17,0],
[62,46,17,1],
[62,46,17,2],
[62,46,17,3],
[62,46,17,4],
[62,46,17,5],
[62,46,17,6],
[62,46,17,8],
[62,46,17,10],
[62,46,17,11],
[62,46,17,14],
[62,46,17,16],
[62,46,19,0],
[62,46,19,1],
[62,46,19,2],
[62,46,19,3],
[62,46,19,4],
[62,46,19,5],
[62,46,19,6],
[62,46,19,10],
[62,46,19,11],
[62,46,19,12],
[62,46,19,16],
[62,46,19,17],
[62,46,20,0],
[62,46,20,1],
[62,46,20,2],
[62,46,20,3],
[62,46,20,4],
[62,46,20,5],
[62,46,20,8],
[62,46,20,11],
[62,46,20,12],
[62,46,20,13],
[62,46,20,14],
[62,46,20,16],
[62,46,20,17],
[62,46,20,19],
[62,46,21,0],
[62,46,21,1],
[62,46,21,2],
[62,46,21,4],
[62,46,21,10],
[62,46,21,11],
[62,46,21,12],
[62,46,21,13],
[62,46,21,14],
[62,46,21,16],
[62,46,21,17],
[62,46,21,19],
[62,46,21,20],
[62,46,22,0],
[62,46,22,1],
[62,46,22,2],
[62,46,22,5],
[62,46,22,6],
[62,46,22,8],
[62,46,22,11],
[62,46,22,12],
[62,46,22,13],
[62,46,22,14],
[62,46,22,16],
[62,46,22,17],
[62,46,22,19],
[62,46,22,21],
[62,46,23,3],
[62,46,23,4],
[62,46,23,5],
[62,46,23,6],
[62,46,23,8],
[62,46,23,10],
[62,46,23,11],
[62,46,23,12],
[62,46,23,13],
[62,46,23,14],
[62,46,23,16],
[62,46,23,17],
[62,46,23,19],
[62,46,23,20],
[62,46,24,3],
[62,46,24,4],
[62,46,24,5],
[62,46,24,6],
[62,46,24,8],
[62,46,24,10],
[62,46,24,11],
[62,46,24,12],
[62,46,24,13],
[62,46,24,14],
[62,46,24,17],
[62,46,24,19],
[62,46,24,20],
[62,46,27,0],
[62,46,27,1],
[62,46,27,2],
[62,46,27,3],
[62,46,27,4],
[62,46,27,5],
[62,46,27,6],
[62,46,27,8],
[62,46,27,11],
[62,46,27,12],
[62,46,27,13],
[62,46,27,14],
[62,46,29,0],
[62,46,29,1],
[62,46,29,2],
[62,46,29,3],
[62,46,29,4],
[62,46,29,5],
[62,46,29,6],
[62,46,29,8],
[62,46,29,10],
[62,46,29,13],
[62,46,30,0],
[62,46,30,1],
[62,46,30,2],
[62,46,30,3],
[62,46,30,4],
[62,46,30,5],
[62,46,30,6],
[62,46,30,8],
[62,46,30,10],
[62,46,30,11],
[62,46,31,0],
[62,46,31,1],
[62,46,31,2],
[62,46,31,3],
[62,46,31,4],
[62,46,31,5],
[62,46,31,6],
[62,46,31,8],
[62,46,32,0],
[62,46,32,1],
[62,46,32,2],
[62,46,32,3],
[62,46,32,4],
[62,46,32,5],
[62,46,32,6],
[62,46,33,0],
[62,46,33,1],
[62,46,33,2],
[62,46,33,3],
[62,46,33,4],
[62,46,33,5],
[62,47,3,0],
[62,47,3,1],
[62,47,3,2],
[62,47,4,0],
[62,47,4,1],
[62,47,4,2],
[62,47,4,3],
[62,47,5,0],
[62,47,5,1],
[62,47,5,2],
[62,47,5,4],
[62,47,6,0],
[62,47,6,1],
[62,47,6,2],
[62,47,6,4],
[62,47,8,0],
[62,47,8,1],
[62,47,8,2],
[62,47,8,4],
[62,47,9,0],
[62,47,9,1],
[62,47,9,2],
[62,47,9,3],
[62,47,9,4],
[62,47,9,5],
[62,47,9,6],
[62,47,9,8],
[62,47,10,0],
[62,47,10,1],
[62,47,10,2],
[62,47,10,3],
[62,47,10,4],
[62,47,10,5],
[62,47,10,6],
[62,47,10,8],
[62,47,10,9],
[62,47,11,0],
[62,47,11,1],
[62,47,11,2],
[62,47,11,3],
[62,47,11,4],
[62,47,11,5],
[62,47,11,6],
[62,47,11,8],
[62,47,11,9],
[62,47,11,10],
[62,47,12,0],
[62,47,12,1],
[62,47,12,2],
[62,47,12,3],
[62,47,12,4],
[62,47,12,5],
[62,47,12,6],
[62,47,12,8],
[62,47,12,9],
[62,47,12,10],
[62,47,13,0],
[62,47,13,1],
[62,47,13,2],
[62,47,13,3],
[62,47,13,4],
[62,47,13,5],
[62,47,13,6],
[62,47,13,8],
[62,47,13,9],
[62,47,13,10],
[62,47,13,11],
[62,47,13,12],
[62,47,14,0],
[62,47,14,1],
[62,47,14,2],
[62,47,14,3],
[62,47,14,4],
[62,47,14,5],
[62,47,14,6],
[62,47,14,8],
[62,47,14,9],
[62,47,14,10],
[62,47,14,11],
[62,47,14,12],
[62,47,15,0],
[62,47,15,1],
[62,47,15,2],
[62,47,15,3],
[62,47,15,4],
[62,47,15,5],
[62,47,15,6],
[62,47,15,8],
[62,47,15,10],
[62,47,15,11],
[62,47,15,14],
[62,47,16,0],
[62,47,16,1],
[62,47,16,2],
[62,47,16,3],
[62,47,16,4],
[62,47,16,5],
[62,47,16,6],
[62,47,16,8],
[62,47,16,9],
[62,47,16,12],
[62,47,16,13],
[62,47,16,14],
[62,47,16,15],
[62,47,17,0],
[62,47,17,1],
[62,47,17,2],
[62,47,17,3],
[62,47,17,4],
[62,47,17,5],
[62,47,17,6],
[62,47,17,8],
[62,47,17,9],
[62,47,17,12],
[62,47,17,13],
[62,47,17,14],
[62,47,17,15],
[62,47,17,16],
[62,47,18,0],
[62,47,18,1],
[62,47,18,2],
[62,47,18,3],
[62,47,18,4],
[62,47,18,5],
[62,47,18,6],
[62,47,18,10],
[62,47,18,11],
[62,47,18,12],
[62,47,18,13],
[62,47,18,14],
[62,47,18,16],
[62,47,18,17],
[62,47,19,0],
[62,47,19,1],
[62,47,19,2],
[62,47,19,3],
[62,47,19,4],
[62,47,19,5],
[62,47,19,8],
[62,47,19,9],
[62,47,19,10],
[62,47,19,11],
[62,47,19,12],
[62,47,19,15],
[62,47,19,16],
[62,47,19,17],
[62,47,19,18],
[62,47,20,0],
[62,47,20,1],
[62,47,20,2],
[62,47,20,3],
[62,47,20,4],
[62,47,20,6],
[62,47,20,8],
[62,47,20,9],
[62,47,20,11],
[62,47,20,12],
[62,47,20,13],
[62,47,20,14],
[62,47,20,15],
[62,47,20,16],
[62,47,20,17],
[62,47,20,18],
[62,47,20,19],
[62,47,21,0],
[62,47,21,1],
[62,47,21,2],
[62,47,21,9],
[62,47,21,10],
[62,47,21,11],
[62,47,21,12],
[62,47,21,13],
[62,47,21,14],
[62,47,21,15],
[62,47,21,16],
[62,47,21,17],
[62,47,21,18],
[62,47,21,19],
[62,47,21,20],
[62,47,22,3],
[62,47,22,4],
[62,47,22,5],
[62,47,22,6],
[62,47,22,8],
[62,47,22,9],
[62,47,22,11],
[62,47,22,12],
[62,47,22,13],
[62,47,22,14],
[62,47,22,15],
[62,47,22,16],
[62,47,22,17],
[62,47,22,18],
[62,47,22,19],
[62,47,24,0],
[62,47,24,1],
[62,47,24,2],
[62,47,24,3],
[62,47,24,4],
[62,47,24,5],
[62,47,24,6],
[62,47,24,8],
[62,47,24,9],
[62,47,24,10],
[62,47,24,11],
[62,47,24,12],
[62,47,24,13],
[62,47,24,14],
[62,47,24,15],
[62,47,24,17],
[62,47,24,18],
[62,47,24,19],
[62,47,26,0],
[62,47,26,1],
[62,47,26,2],
[62,47,26,3],
[62,47,26,4],
[62,47,26,5],
[62,47,26,6],
[62,47,26,8],
[62,47,26,10],
[62,47,26,11],
[62,47,26,12],
[62,47,26,13],
[62,47,26,14],
[62,47,27,0],
[62,47,27,1],
[62,47,27,2],
[62,47,27,3],
[62,47,27,4],
[62,47,27,5],
[62,47,27,6],
[62,47,27,8],
[62,47,27,9],
[62,47,27,11],
[62,47,27,12],
[62,47,27,13],
[62,47,27,14],
[62,47,29,0],
[62,47,29,1],
[62,47,29,2],
[62,47,29,3],
[62,47,29,4],
[62,47,29,5],
[62,47,29,6],
[62,47,29,8],
[62,47,29,9],
[62,47,29,10],
[62,47,32,0],
[62,47,32,1],
[62,47,32,2],
[62,47,32,3],
[62,47,32,4],
[62,47,32,5],
[62,47,33,0],
[62,47,33,1],
[62,47,33,2],
[62,47,33,3],
[62,47,33,4],
[62,48,3,0],
[62,48,3,1],
[62,48,3,2],
[62,48,4,0],
[62,48,4,1],
[62,48,4,2],
[62,48,4,3],
[62,48,5,0],
[62,48,5,1],
[62,48,5,2],
[62,48,5,4],
[62,48,6,0],
[62,48,6,1],
[62,48,6,2],
[62,48,6,4],
[62,48,8,0],
[62,48,8,1],
[62,48,8,2],
[62,48,8,4],
[62,48,9,0],
[62,48,9,1],
[62,48,9,2],
[62,48,9,3],
[62,48,9,4],
[62,48,9,5],
[62,48,9,6],
[62,48,9,8],
[62,48,10,0],
[62,48,10,1],
[62,48,10,2],
[62,48,10,3],
[62,48,10,4],
[62,48,10,5],
[62,48,10,6],
[62,48,10,8],
[62,48,10,9],
[62,48,13,0],
[62,48,13,1],
[62,48,13,2],
[62,48,13,3],
[62,48,13,4],
[62,48,13,5],
[62,48,13,6],
[62,48,13,8],
[62,48,13,9],
[62,48,13,10],
[62,48,14,0],
[62,48,14,1],
[62,48,14,2],
[62,48,14,3],
[62,48,14,4],
[62,48,14,5],
[62,48,14,6],
[62,48,14,8],
[62,48,14,9],
[62,48,14,10],
[62,48,15,0],
[62,48,15,1],
[62,48,15,2],
[62,48,15,3],
[62,48,15,4],
[62,48,15,5],
[62,48,15,6],
[62,48,15,8],
[62,48,15,13],
[62,48,15,14],
[62,48,16,0],
[62,48,16,1],
[62,48,16,2],
[62,48,16,3],
[62,48,16,4],
[62,48,16,5],
[62,48,16,6],
[62,48,16,10],
[62,48,16,13],
[62,48,16,14],
[62,48,16,15],
[62,48,17,0],
[62,48,17,1],
[62,48,17,2],
[62,48,17,3],
[62,48,17,4],
[62,48,17,5],
[62,48,17,6],
[62,48,17,10],
[62,48,17,13],
[62,48,17,14],
[62,48,17,15],
[62,48,17,16],
[62,48,18,0],
[62,48,18,1],
[62,48,18,2],
[62,48,18,3],
[62,48,18,4],
[62,48,18,5],
[62,48,18,8],
[62,48,18,10],
[62,48,18,13],
[62,48,18,14],
[62,48,18,16],
[62,48,18,17],
[62,48,19,0],
[62,48,19,1],
[62,48,19,2],
[62,48,19,3],
[62,48,19,4],
[62,48,19,6],
[62,48,19,8],
[62,48,19,9],
[62,48,19,10],
[62,48,19,15],
[62,48,19,16],
[62,48,19,17],
[62,48,19,18],
[62,48,20,0],
[62,48,20,1],
[62,48,20,2],
[62,48,20,5],
[62,48,20,6],
[62,48,20,8],
[62,48,20,9],
[62,48,20,13],
[62,48,20,14],
[62,48,20,15],
[62,48,20,16],
[62,48,20,17],
[62,48,20,18],
[62,48,20,19],
[62,48,21,4],
[62,48,21,9],
[62,48,21,10],
[62,48,21,13],
[62,48,21,14],
[62,48,21,15],
[62,48,21,16],
[62,48,21,17],
[62,48,21,18],
[62,48,21,19],
[62,48,21,20],
[62,48,22,0],
[62,48,22,1],
[62,48,22,2],
[62,48,22,3],
[62,48,22,4],
[62,48,22,5],
[62,48,22,6],
[62,48,22,8],
[62,48,22,9],
[62,48,22,13],
[62,48,22,14],
[62,48,22,15],
[62,48,22,16],
[62,48,22,17],
[62,48,22,18],
[62,48,22,19],
[62,48,23,0],
[62,48,23,1],
[62,48,23,2],
[62,48,23,3],
[62,48,23,4],
[62,48,23,5],
[62,48,23,6],
[62,48,23,8],
[62,48,23,9],
[62,48,23,10],
[62,48,23,13],
[62,48,23,14],
[62,48,23,15],
[62,48,23,16],
[62,48,23,17],
[62,48,23,18],
[62,48,24,0],
[62,48,24,1],
[62,48,24,2],
[62,48,24,3],
[62,48,24,4],
[62,48,24,5],
[62,48,24,6],
[62,48,24,8],
[62,48,24,9],
[62,48,24,10],
[62,48,24,13],
[62,48,24,14],
[62,48,24,15],
[62,48,24,17],
[62,48,24,18],
[62,48,26,0],
[62,48,26,1],
[62,48,26,2],
[62,48,26,3],
[62,48,26,4],
[62,48,26,5],
[62,48,26,6],
[62,48,26,8],
[62,48,26,10],
[62,48,26,13],
[62,48,26,14],
[62,48,27,0],
[62,48,27,1],
[62,48,27,2],
[62,48,27,3],
[62,48,27,4],
[62,48,27,5],
[62,48,27,6],
[62,48,27,8],
[62,48,27,9],
[62,48,27,13],
[62,48,30,0],
[62,48,30,1],
[62,48,30,2],
[62,48,30,3],
[62,48,30,4],
[62,48,30,5],
[62,48,30,6],
[62,48,31,0],
[62,48,31,1],
[62,48,31,2],
[62,48,31,3],
[62,48,31,4],
[62,48,31,5],
[62,49,3,0],
[62,49,3,1],
[62,49,3,2],
[62,49,4,0],
[62,49,4,1],
[62,49,4,2],
[62,49,4,3],
[62,49,5,0],
[62,49,5,1],
[62,49,5,2],
[62,49,5,4],
[62,49,6,0],
[62,49,6,1],
[62,49,6,2],
[62,49,6,4],
[62,49,8,0],
[62,49,8,1],
[62,49,8,2],
[62,49,8,4],
[62,49,10,0],
[62,49,10,1],
[62,49,10,2],
[62,49,10,3],
[62,49,10,4],
[62,49,10,5],
[62,49,10,6],
[62,49,10,8],
[62,49,11,0],
[62,49,11,1],
[62,49,11,2],
[62,49,11,3],
[62,49,11,4],
[62,49,11,5],
[62,49,11,6],
[62,49,11,8],
[62,49,11,10],
[62,49,12,0],
[62,49,12,1],
[62,49,12,2],
[62,49,12,3],
[62,49,12,4],
[62,49,12,5],
[62,49,12,6],
[62,49,12,8],
[62,49,12,10],
[62,49,13,0],
[62,49,13,1],
[62,49,13,2],
[62,49,13,3],
[62,49,13,4],
[62,49,13,5],
[62,49,13,6],
[62,49,13,8],
[62,49,13,10],
[62,49,13,11],
[62,49,13,12],
[62,49,14,0],
[62,49,14,1],
[62,49,14,2],
[62,49,14,3],
[62,49,14,4],
[62,49,14,5],
[62,49,14,6],
[62,49,14,8],
[62,49,14,10],
[62,49,14,11],
[62,49,16,0],
[62,49,16,1],
[62,49,16,2],
[62,49,16,3],
[62,49,16,4],
[62,49,16,5],
[62,49,16,6],
[62,49,16,10],
[62,49,16,11],
[62,49,16,12],
[62,49,16,13],
[62,49,16,14],
[62,49,17,0],
[62,49,17,1],
[62,49,17,2],
[62,49,17,3],
[62,49,17,4],
[62,49,17,5],
[62,49,17,6],
[62,49,17,10],
[62,49,17,11],
[62,49,17,12],
[62,49,17,13],
[62,49,17,14],
[62,49,17,16],
[62,49,19,0],
[62,49,19,1],
[62,49,19,2],
[62,49,19,3],
[62,49,19,4],
[62,49,19,6],
[62,49,19,8],
[62,49,19,10],
[62,49,19,11],
[62,49,19,12],
[62,49,19,16],
[62,49,19,17],
[62,49,20,0],
[62,49,20,1],
[62,49,20,2],
[62,49,20,5],
[62,49,20,6],
[62,49,20,8],
[62,49,20,11],
[62,49,20,12],
[62,49,20,13],
[62,49,20,14],
[62,49,20,16],
[62,49,20,17],
[62,49,20,19],
[62,49,21,4],
[62,49,21,10],
[62,49,21,11],
[62,49,21,12],
[62,49,21,13],
[62,49,21,14],
[62,49,21,16],
[62,49,21,17],
[62,49,21,19],
[62,49,21,20],
[62,49,22,0],
[62,49,22,1],
[62,49,22,2],
[62,49,22,3],
[62,49,22,4],
[62,49,22,5],
[62,49,22,6],
[62,49,22,8],
[62,49,22,11],
[62,49,22,12],
[62,49,22,13],
[62,49,22,14],
[62,49,22,16],
[62,49,22,17],
[62,49,22,19],
[62,49,23,0],
[62,49,23,1],
[62,49,23,2],
[62,49,23,3],
[62,49,23,4],
[62,49,23,5],
[62,49,23,6],
[62,49,23,8],
[62,49,23,10],
[62,49,23,11],
[62,49,23,12],
[62,49,23,13],
[62,49,23,14],
[62,49,23,16],
[62,49,23,17],
[62,49,24,0],
[62,49,24,1],
[62,49,24,2],
[62,49,24,3],
[62,49,24,4],
[62,49,24,5],
[62,49,24,6],
[62,49,24,8],
[62,49,24,10],
[62,49,24,11],
[62,49,24,12],
[62,49,24,13],
[62,49,24,14],
[62,49,24,17],
[62,49,27,0],
[62,49,27,1],
[62,49,27,2],
[62,49,27,3],
[62,49,27,4],
[62,49,27,5],
[62,49,27,6],
[62,49,27,8],
[62,49,27,11],
[62,49,27,12],
[62,49,27,13],
[62,49,29,0],
[62,49,29,1],
[62,49,29,2],
[62,49,29,3],
[62,49,29,4],
[62,49,29,5],
[62,49,29,6],
[62,49,29,8],
[62,49,30,0],
[62,49,30,1],
[62,49,30,2],
[62,49,30,3],
[62,49,30,4],
[62,49,30,5],
[62,49,30,6],
[62,49,31,0],
[62,49,31,1],
[62,49,31,2],
[62,49,31,3],
[62,49,31,4],
[62,49,31,5],
[62,49,32,0],
[62,49,32,1],
[62,49,32,2],
[62,49,32,3],
[62,49,32,4],
[62,49,33,0],
[62,49,33,1],
[62,49,33,2],
[62,50,3,0],
[62,50,3,1],
[62,50,3,2],
[62,50,4,0],
[62,50,4,1],
[62,50,4,2],
[62,50,4,3],
[62,50,5,0],
[62,50,5,1],
[62,50,5,2],
[62,50,5,4],
[62,50,6,0],
[62,50,6,1],
[62,50,6,2],
[62,50,6,4],
[62,50,8,0],
[62,50,8,1],
[62,50,8,2],
[62,50,8,4],
[62,50,10,0],
[62,50,10,1],
[62,50,10,2],
[62,50,10,3],
[62,50,10,4],
[62,50,10,5],
[62,50,10,6],
[62,50,10,8],
[62,50,11,0],
[62,50,11,1],
[62,50,11,2],
[62,50,11,3],
[62,50,11,4],
[62,50,11,5],
[62,50,11,6],
[62,50,11,8],
[62,50,11,10],
[62,50,12,0],
[62,50,12,1],
[62,50,12,2],
[62,50,12,3],
[62,50,12,4],
[62,50,12,5],
[62,50,12,6],
[62,50,12,8],
[62,50,12,10],
[62,50,13,0],
[62,50,13,1],
[62,50,13,2],
[62,50,13,3],
[62,50,13,4],
[62,50,13,5],
[62,50,13,6],
[62,50,13,8],
[62,50,13,10],
[62,50,13,11],
[62,50,13,12],
[62,50,14,0],
[62,50,14,1],
[62,50,14,2],
[62,50,14,3],
[62,50,14,4],
[62,50,14,5],
[62,50,14,6],
[62,50,14,8],
[62,50,14,10],
[62,50,14,11],
[62,50,16,0],
[62,50,16,1],
[62,50,16,2],
[62,50,16,3],
[62,50,16,4],
[62,50,16,5],
[62,50,16,6],
[62,50,16,10],
[62,50,16,11],
[62,50,16,12],
[62,50,16,13],
[62,50,16,14],
[62,50,17,0],
[62,50,17,1],
[62,50,17,2],
[62,50,17,3],
[62,50,17,4],
[62,50,17,5],
[62,50,17,6],
[62,50,17,10],
[62,50,17,11],
[62,50,17,12],
[62,50,17,13],
[62,50,17,14],
[62,50,17,16],
[62,50,19,0],
[62,50,19,1],
[62,50,19,2],
[62,50,19,3],
[62,50,19,4],
[62,50,19,6],
[62,50,19,8],
[62,50,19,10],
[62,50,19,11],
[62,50,19,12],
[62,50,19,16],
[62,50,19,17],
[62,50,20,0],
[62,50,20,1],
[62,50,20,2],
[62,50,20,5],
[62,50,20,6],
[62,50,20,8],
[62,50,20,11],
[62,50,20,12],
[62,50,20,13],
[62,50,20,14],
[62,50,20,16],
[62,50,20,17],
[62,50,20,19],
[62,50,21,4],
[62,50,21,10],
[62,50,21,11],
[62,50,21,12],
[62,50,21,13],
[62,50,21,14],
[62,50,21,16],
[62,50,21,17],
[62,50,21,19],
[62,50,21,20],
[62,50,22,0],
[62,50,22,1],
[62,50,22,2],
[62,50,22,3],
[62,50,22,4],
[62,50,22,5],
[62,50,22,6],
[62,50,22,8],
[62,50,22,11],
[62,50,22,12],
[62,50,22,13],
[62,50,22,14],
[62,50,22,16],
[62,50,22,17],
[62,50,22,19],
[62,50,23,0],
[62,50,23,1],
[62,50,23,2],
[62,50,23,3],
[62,50,23,4],
[62,50,23,5],
[62,50,23,6],
[62,50,23,8],
[62,50,23,10],
[62,50,23,11],
[62,50,23,12],
[62,50,23,13],
[62,50,23,14],
[62,50,23,16],
[62,50,23,17],
[62,50,24,0],
[62,50,24,1],
[62,50,24,2],
[62,50,24,3],
[62,50,24,4],
[62,50,24,5],
[62,50,24,6],
[62,50,24,8],
[62,50,24,10],
[62,50,24,11],
[62,50,24,12],
[62,50,24,13],
[62,50,24,14],
[62,50,24,17],
[62,50,27,0],
[62,50,27,1],
[62,50,27,2],
[62,50,27,3],
[62,50,27,4],
[62,50,27,5],
[62,50,27,6],
[62,50,27,8],
[62,50,27,11],
[62,50,27,12],
[62,50,27,13],
[62,50,29,0],
[62,50,29,1],
[62,50,29,2],
[62,50,29,3],
[62,50,29,4],
[62,50,29,5],
[62,50,29,6],
[62,50,29,8],
[62,50,30,0],
[62,50,30,1],
[62,50,30,2],
[62,50,30,3],
[62,50,30,4],
[62,50,30,5],
[62,50,30,6],
[62,50,31,0],
[62,50,31,1],
[62,50,31,2],
[62,50,31,3],
[62,50,31,4],
[62,50,31,5],
[62,50,32,0],
[62,50,32,1],
[62,50,32,2],
[62,50,32,3],
[62,50,32,4],
[62,50,33,0],
[62,50,33,1],
[62,50,33,2],
[62,51,4,0],
[62,51,4,1],
[62,51,4,2],
[62,51,9,0],
[62,51,9,1],
[62,51,9,2],
[62,51,9,4],
[62,51,10,0],
[62,51,10,1],
[62,51,10,2],
[62,51,10,4],
[62,51,10,9],
[62,51,11,0],
[62,51,11,1],
[62,51,11,2],
[62,51,11,4],
[62,51,11,9],
[62,51,11,10],
[62,51,12,0],
[62,51,12,1],
[62,51,12,2],
[62,51,12,4],
[62,51,12,9],
[62,51,12,10],
[62,51,13,0],
[62,51,13,1],
[62,51,13,2],
[62,51,13,4],
[62,51,13,9],
[62,51,13,10],
[62,51,13,11],
[62,51,13,12],
[62,51,14,0],
[62,51,14,1],
[62,51,14,2],
[62,51,14,4],
[62,51,14,9],
[62,51,14,10],
[62,51,14,11],
[62,51,15,0],
[62,51,15,1],
[62,51,15,2],
[62,51,15,4],
[62,51,15,12],
[62,51,15,13],
[62,51,15,14],
[62,51,16,0],
[62,51,16,1],
[62,51,16,2],
[62,51,16,4],
[62,51,16,10],
[62,51,16,11],
[62,51,16,12],
[62,51,16,13],
[62,51,16,14],
[62,51,16,15],
[62,51,17,0],
[62,51,17,1],
[62,51,17,2],
[62,51,17,4],
[62,51,17,10],
[62,51,17,11],
[62,51,17,12],
[62,51,17,13],
[62,51,17,14],
[62,51,17,15],
[62,51,17,16],
[62,51,18,0],
[62,51,18,1],
[62,51,18,2],
[62,51,18,4],
[62,51,18,10],
[62,51,18,11],
[62,51,18,12],
[62,51,18,13],
[62,51,18,14],
[62,51,18,16],
[62,51,18,17],
[62,51,19,0],
[62,51,19,1],
[62,51,19,2],
[62,51,19,4],
[62,51,19,9],
[62,51,19,10],
[62,51,19,11],
[62,51,19,12],
[62,51,19,15],
[62,51,19,16],
[62,51,19,17],
[62,51,19,18],
[62,51,20,0],
[62,51,20,1],
[62,51,20,2],
[62,51,20,9],
[62,51,20,11],
[62,51,20,12],
[62,51,20,13],
[62,51,20,14],
[62,51,20,15],
[62,51,20,16],
[62,51,20,17],
[62,51,20,18],
[62,51,20,19],
[62,51,22,0],
[62,51,22,1],
[62,51,22,2],
[62,51,22,4],
[62,51,22,9],
[62,51,22,11],
[62,51,22,12],
[62,51,22,13],
[62,51,22,14],
[62,51,22,15],
[62,51,22,16],
[62,51,22,17],
[62,51,22,18],
[62,51,22,19],
[62,51,23,0],
[62,51,23,1],
[62,51,23,2],
[62,51,23,4],
[62,51,23,9],
[62,51,23,10],
[62,51,23,11],
[62,51,23,12],
[62,51,23,13],
[62,51,23,14],
[62,51,23,15],
[62,51,23,16],
[62,51,23,17],
[62,51,23,18],
[62,51,24,0],
[62,51,24,1],
[62,51,24,2],
[62,51,24,4],
[62,51,24,9],
[62,51,24,10],
[62,51,24,11],
[62,51,24,12],
[62,51,24,13],
[62,51,24,14],
[62,51,24,15],
[62,51,24,17],
[62,51,24,18],
[62,51,26,0],
[62,51,26,1],
[62,51,26,2],
[62,51,26,4],
[62,51,26,10],
[62,51,26,11],
[62,51,26,12],
[62,51,26,13],
[62,51,26,14],
[62,51,27,0],
[62,51,27,1],
[62,51,27,2],
[62,51,27,4],
[62,51,27,9],
[62,51,27,11],
[62,51,27,12],
[62,51,27,13],
[62,51,29,0],
[62,51,29,1],
[62,51,29,2],
[62,51,29,4],
[62,51,29,9],
[62,51,30,0],
[62,51,30,1],
[62,51,30,2],
[62,51,30,4],
[62,51,31,0],
[62,51,31,1],
[62,51,31,2],
[62,51,31,4],
[62,51,32,0],
[62,51,32,1],
[62,51,32,2],
[62,51,32,4],
[62,51,33,0],
[62,51,33,1],
[62,51,33,2],
[62,52,3,0],
[62,52,3,1],
[62,52,3,2],
[62,52,4,0],
[62,52,4,1],
[62,52,4,2],
[62,52,4,3],
[62,52,5,0],
[62,52,5,1],
[62,52,5,2],
[62,52,5,4],
[62,52,6,0],
[62,52,6,1],
[62,52,6,2],
[62,52,6,4],
[62,52,8,0],
[62,52,8,1],
[62,52,8,2],
[62,52,8,4],
[62,52,10,0],
[62,52,10,1],
[62,52,10,2],
[62,52,10,3],
[62,52,10,4],
[62,52,10,5],
[62,52,10,6],
[62,52,10,8],
[62,52,11,0],
[62,52,11,1],
[62,52,11,2],
[62,52,11,3],
[62,52,11,4],
[62,52,11,5],
[62,52,11,6],
[62,52,11,8],
[62,52,11,10],
[62,52,12,0],
[62,52,12,1],
[62,52,12,2],
[62,52,12,3],
[62,52,12,4],
[62,52,12,5],
[62,52,12,6],
[62,52,12,8],
[62,52,12,10],
[62,52,13,0],
[62,52,13,1],
[62,52,13,2],
[62,52,13,3],
[62,52,13,4],
[62,52,13,5],
[62,52,13,6],
[62,52,13,8],
[62,52,13,10],
[62,52,13,11],
[62,52,13,12],
[62,52,14,0],
[62,52,14,1],
[62,52,14,2],
[62,52,14,3],
[62,52,14,4],
[62,52,14,5],
[62,52,14,6],
[62,52,14,8],
[62,52,14,10],
[62,52,14,11],
[62,52,16,0],
[62,52,16,1],
[62,52,16,2],
[62,52,16,3],
[62,52,16,4],
[62,52,16,5],
[62,52,16,6],
[62,52,16,10],
[62,52,16,11],
[62,52,16,12],
[62,52,16,13],
[62,52,16,14],
[62,52,17,0],
[62,52,17,1],
[62,52,17,2],
[62,52,17,3],
[62,52,17,4],
[62,52,17,5],
[62,52,17,6],
[62,52,17,10],
[62,52,17,11],
[62,52,17,12],
[62,52,17,13],
[62,52,17,14],
[62,52,17,16],
[62,52,19,0],
[62,52,19,1],
[62,52,19,2],
[62,52,19,3],
[62,52,19,4],
[62,52,19,6],
[62,52,19,8],
[62,52,19,10],
[62,52,19,11],
[62,52,19,12],
[62,52,19,16],
[62,52,19,17],
[62,52,20,0],
[62,52,20,1],
[62,52,20,2],
[62,52,20,5],
[62,52,20,6],
[62,52,20,8],
[62,52,20,11],
[62,52,20,12],
[62,52,20,13],
[62,52,20,14],
[62,52,20,16],
[62,52,20,17],
[62,52,20,19],
[62,52,21,4],
[62,52,21,10],
[62,52,21,11],
[62,52,21,12],
[62,52,21,13],
[62,52,21,14],
[62,52,21,16],
[62,52,21,17],
[62,52,21,19],
[62,52,21,20],
[62,52,22,0],
[62,52,22,1],
[62,52,22,2],
[62,52,22,3],
[62,52,22,4],
[62,52,22,5],
[62,52,22,6],
[62,52,22,8],
[62,52,22,11],
[62,52,22,12],
[62,52,22,13],
[62,52,22,14],
[62,52,22,16],
[62,52,22,17],
[62,52,22,19],
[62,52,23,0],
[62,52,23,1],
[62,52,23,2],
[62,52,23,3],
[62,52,23,4],
[62,52,23,5],
[62,52,23,6],
[62,52,23,8],
[62,52,23,10],
[62,52,23,11],
[62,52,23,12],
[62,52,23,13],
[62,52,23,14],
[62,52,23,16],
[62,52,23,17],
[62,52,24,0],
[62,52,24,1],
[62,52,24,2],
[62,52,24,3],
[62,52,24,4],
[62,52,24,5],
[62,52,24,6],
[62,52,24,8],
[62,52,24,10],
[62,52,24,11],
[62,52,24,12],
[62,52,24,13],
[62,52,24,14],
[62,52,24,17],
[62,52,27,0],
[62,52,27,1],
[62,52,27,2],
[62,52,27,3],
[62,52,27,4],
[62,52,27,5],
[62,52,27,6],
[62,52,27,8],
[62,52,27,11],
[62,52,27,12],
[62,52,27,13],
[62,52,29,0],
[62,52,29,1],
[62,52,29,2],
[62,52,29,3],
[62,52,29,4],
[62,52,29,5],
[62,52,29,6],
[62,52,29,8],
[62,52,30,0],
[62,52,30,1],
[62,52,30,2],
[62,52,30,3],
[62,52,30,4],
[62,52,30,5],
[62,52,30,6],
[62,52,31,0],
[62,52,31,1],
[62,52,31,2],
[62,52,31,3],
[62,52,31,4],
[62,52,31,5],
[62,52,32,0],
[62,52,32,1],
[62,52,32,2],
[62,52,32,3],
[62,52,32,4],
[62,52,33,0],
[62,52,33,1],
[62,52,33,2],
[62,53,3,0],
[62,53,3,1],
[62,53,3,2],
[62,53,4,0],
[62,53,4,1],
[62,53,4,2],
[62,53,4,3],
[62,53,5,0],
[62,53,5,1],
[62,53,5,2],
[62,53,5,4],
[62,53,6,0],
[62,53,6,1],
[62,53,6,2],
[62,53,6,4],
[62,53,8,0],
[62,53,8,1],
[62,53,8,2],
[62,53,8,4],
[62,53,9,0],
[62,53,9,1],
[62,53,9,2],
[62,53,9,3],
[62,53,9,4],
[62,53,9,5],
[62,53,9,6],
[62,53,9,8],
[62,53,11,0],
[62,53,11,1],
[62,53,11,2],
[62,53,11,3],
[62,53,11,4],
[62,53,11,5],
[62,53,11,6],
[62,53,11,8],
[62,53,11,9],
[62,53,12,0],
[62,53,12,1],
[62,53,12,2],
[62,53,12,3],
[62,53,12,4],
[62,53,12,5],
[62,53,12,6],
[62,53,12,8],
[62,53,12,9],
[62,53,13,0],
[62,53,13,1],
[62,53,13,2],
[62,53,13,3],
[62,53,13,4],
[62,53,13,5],
[62,53,13,6],
[62,53,13,8],
[62,53,13,9],
[62,53,13,11],
[62,53,13,12],
[62,53,14,0],
[62,53,14,1],
[62,53,14,2],
[62,53,14,3],
[62,53,14,4],
[62,53,14,5],
[62,53,14,6],
[62,53,14,8],
[62,53,14,9],
[62,53,14,11],
[62,53,15,0],
[62,53,15,1],
[62,53,15,2],
[62,53,15,3],
[62,53,15,4],
[62,53,15,5],
[62,53,15,6],
[62,53,15,8],
[62,53,15,12],
[62,53,15,13],
[62,53,15,14],
[62,53,16,0],
[62,53,16,1],
[62,53,16,2],
[62,53,16,3],
[62,53,16,4],
[62,53,16,5],
[62,53,16,6],
[62,53,16,11],
[62,53,16,12],
[62,53,16,13],
[62,53,16,14],
[62,53,16,15],
[62,53,17,0],
[62,53,17,1],
[62,53,17,2],
[62,53,17,3],
[62,53,17,4],
[62,53,17,5],
[62,53,17,6],
[62,53,17,11],
[62,53,17,12],
[62,53,17,13],
[62,53,17,14],
[62,53,17,15],
[62,53,17,16],
[62,53,18,0],
[62,53,18,1],
[62,53,18,2],
[62,53,18,3],
[62,53,18,4],
[62,53,18,5],
[62,53,18,8],
[62,53,18,11],
[62,53,18,12],
[62,53,18,13],
[62,53,18,14],
[62,53,18,16],
[62,53,18,17],
[62,53,19,0],
[62,53,19,1],
[62,53,19,2],
[62,53,19,3],
[62,53,19,4],
[62,53,19,6],
[62,53,19,8],
[62,53,19,9],
[62,53,19,11],
[62,53,19,12],
[62,53,19,15],
[62,53,19,16],
[62,53,19,17],
[62,53,19,18],
[62,53,21,4],
[62,53,21,9],
[62,53,21,11],
[62,53,21,12],
[62,53,21,13],
[62,53,21,14],
[62,53,21,15],
[62,53,21,16],
[62,53,21,17],
[62,53,21,18],
[62,53,21,19],
[62,53,23,0],
[62,53,23,1],
[62,53,23,2],
[62,53,23,3],
[62,53,23,4],
[62,53,23,5],
[62,53,23,6],
[62,53,23,8],
[62,53,23,9],
[62,53,23,11],
[62,53,23,12],
[62,53,23,13],
[62,53,23,14],
[62,53,23,15],
[62,53,23,16],
[62,53,23,17],
[62,53,23,18],
[62,53,24,0],
[62,53,24,1],
[62,53,24,2],
[62,53,24,3],
[62,53,24,4],
[62,53,24,5],
[62,53,24,6],
[62,53,24,8],
[62,53,24,9],
[62,53,24,11],
[62,53,24,12],
[62,53,24,13],
[62,53,24,14],
[62,53,24,15],
[62,53,24,17],
[62,53,24,18],
[62,53,26,0],
[62,53,26,1],
[62,53,26,2],
[62,53,26,3],
[62,53,26,4],
[62,53,26,5],
[62,53,26,6],
[62,53,26,8],
[62,53,26,11],
[62,53,26,12],
[62,53,26,13],
[62,53,26,14],
[62,53,29,0],
[62,53,29,1],
[62,53,29,2],
[62,53,29,3],
[62,53,29,4],
[62,53,29,5],
[62,53,29,6],
[62,53,29,8],
[62,53,29,9],
[62,53,30,0],
[62,53,30,1],
[62,53,30,2],
[62,53,30,3],
[62,53,30,4],
[62,53,30,5],
[62,53,30,6],
[62,53,31,0],
[62,53,31,1],
[62,53,31,2],
[62,53,31,3],
[62,53,31,4],
[62,53,31,5],
[62,53,32,0],
[62,53,32,1],
[62,53,32,2],
[62,53,32,3],
[62,53,32,4],
[62,53,33,0],
[62,53,33,1],
[62,53,33,2],
[62,54,3,0],
[62,54,3,1],
[62,54,3,2],
[62,54,4,0],
[62,54,4,1],
[62,54,4,2],
[62,54,4,3],
[62,54,5,0],
[62,54,5,1],
[62,54,5,2],
[62,54,5,4],
[62,54,6,0],
[62,54,6,1],
[62,54,6,2],
[62,54,6,4],
[62,54,8,0],
[62,54,8,1],
[62,54,8,2],
[62,54,8,4],
[62,54,9,0],
[62,54,9,1],
[62,54,9,2],
[62,54,9,3],
[62,54,9,4],
[62,54,9,5],
[62,54,9,6],
[62,54,9,8],
[62,54,11,0],
[62,54,11,1],
[62,54,11,2],
[62,54,11,3],
[62,54,11,4],
[62,54,11,5],
[62,54,11,6],
[62,54,11,8],
[62,54,11,9],
[62,54,12,0],
[62,54,12,1],
[62,54,12,2],
[62,54,12,3],
[62,54,12,4],
[62,54,12,5],
[62,54,12,6],
[62,54,12,8],
[62,54,12,9],
[62,54,13,0],
[62,54,13,1],
[62,54,13,2],
[62,54,13,3],
[62,54,13,4],
[62,54,13,5],
[62,54,13,6],
[62,54,13,8],
[62,54,13,9],
[62,54,13,11],
[62,54,13,12],
[62,54,14,0],
[62,54,14,1],
[62,54,14,2],
[62,54,14,3],
[62,54,14,4],
[62,54,14,5],
[62,54,14,6],
[62,54,14,8],
[62,54,14,9],
[62,54,14,11],
[62,54,15,0],
[62,54,15,1],
[62,54,15,2],
[62,54,15,3],
[62,54,15,4],
[62,54,15,5],
[62,54,15,6],
[62,54,15,8],
[62,54,15,12],
[62,54,15,13],
[62,54,15,14],
[62,54,16,0],
[62,54,16,1],
[62,54,16,2],
[62,54,16,3],
[62,54,16,4],
[62,54,16,5],
[62,54,16,6],
[62,54,16,11],
[62,54,16,12],
[62,54,16,13],
[62,54,16,14],
[62,54,16,15],
[62,54,17,0],
[62,54,17,1],
[62,54,17,2],
[62,54,17,3],
[62,54,17,4],
[62,54,17,5],
[62,54,17,6],
[62,54,17,11],
[62,54,17,12],
[62,54,17,13],
[62,54,17,14],
[62,54,17,15],
[62,54,17,16],
[62,54,18,0],
[62,54,18,1],
[62,54,18,2],
[62,54,18,3],
[62,54,18,4],
[62,54,18,5],
[62,54,18,8],
[62,54,18,11],
[62,54,18,12],
[62,54,18,13],
[62,54,18,14],
[62,54,18,16],
[62,54,18,17],
[62,54,19,0],
[62,54,19,1],
[62,54,19,2],
[62,54,19,3],
[62,54,19,4],
[62,54,19,6],
[62,54,19,8],
[62,54,19,9],
[62,54,19,11],
[62,54,19,12],
[62,54,19,15],
[62,54,19,16],
[62,54,19,17],
[62,54,19,18],
[62,54,21,4],
[62,54,21,9],
[62,54,21,11],
[62,54,21,12],
[62,54,21,13],
[62,54,21,14],
[62,54,21,15],
[62,54,21,16],
[62,54,21,17],
[62,54,21,18],
[62,54,21,19],
[62,54,23,0],
[62,54,23,1],
[62,54,23,2],
[62,54,23,3],
[62,54,23,4],
[62,54,23,5],
[62,54,23,6],
[62,54,23,8],
[62,54,23,9],
[62,54,23,11],
[62,54,23,12],
[62,54,23,13],
[62,54,23,14],
[62,54,23,15],
[62,54,23,16],
[62,54,23,17],
[62,54,23,18],
[62,54,24,0],
[62,54,24,1],
[62,54,24,2],
[62,54,24,3],
[62,54,24,4],
[62,54,24,5],
[62,54,24,6],
[62,54,24,8],
[62,54,24,9],
[62,54,24,11],
[62,54,24,12],
[62,54,24,13],
[62,54,24,14],
[62,54,24,15],
[62,54,24,17],
[62,54,24,18],
[62,54,26,0],
[62,54,26,1],
[62,54,26,2],
[62,54,26,3],
[62,54,26,4],
[62,54,26,5],
[62,54,26,6],
[62,54,26,8],
[62,54,26,11],
[62,54,26,12],
[62,54,26,13],
[62,54,26,14],
[62,54,29,0],
[62,54,29,1],
[62,54,29,2],
[62,54,29,3],
[62,54,29,4],
[62,54,29,5],
[62,54,29,6],
[62,54,29,8],
[62,54,29,9],
[62,54,30,0],
[62,54,30,1],
[62,54,30,2],
[62,54,30,3],
[62,54,30,4],
[62,54,30,5],
[62,54,30,6],
[62,54,31,0],
[62,54,31,1],
[62,54,31,2],
[62,54,31,3],
[62,54,31,4],
[62,54,31,5],
[62,54,32,0],
[62,54,32,1],
[62,54,32,2],
[62,54,32,3],
[62,54,32,4],
[62,54,33,0],
[62,54,33,1],
[62,54,33,2],
[62,55,3,0],
[62,55,3,1],
[62,55,3,2],
[62,55,4,0],
[62,55,4,1],
[62,55,4,2],
[62,55,4,3],
[62,55,5,0],
[62,55,5,1],
[62,55,5,2],
[62,55,5,4],
[62,55,6,0],
[62,55,6,1],
[62,55,6,2],
[62,55,6,4],
[62,55,8,0],
[62,55,8,1],
[62,55,8,2],
[62,55,8,4],
[62,55,9,0],
[62,55,9,1],
[62,55,9,2],
[62,55,9,3],
[62,55,9,4],
[62,55,9,5],
[62,55,9,6],
[62,55,9,8],
[62,55,11,0],
[62,55,11,1],
[62,55,11,2],
[62,55,11,3],
[62,55,11,4],
[62,55,11,5],
[62,55,11,6],
[62,55,11,8],
[62,55,11,9],
[62,55,12,0],
[62,55,12,1],
[62,55,12,2],
[62,55,12,3],
[62,55,12,4],
[62,55,12,5],
[62,55,12,6],
[62,55,12,8],
[62,55,12,9],
[62,55,13,0],
[62,55,13,1],
[62,55,13,2],
[62,55,13,3],
[62,55,13,4],
[62,55,13,5],
[62,55,13,6],
[62,55,13,8],
[62,55,13,9],
[62,55,13,11],
[62,55,13,12],
[62,55,14,0],
[62,55,14,1],
[62,55,14,2],
[62,55,14,3],
[62,55,14,4],
[62,55,14,5],
[62,55,14,6],
[62,55,14,8],
[62,55,14,9],
[62,55,14,11],
[62,55,15,0],
[62,55,15,1],
[62,55,15,2],
[62,55,15,3],
[62,55,15,4],
[62,55,15,5],
[62,55,15,6],
[62,55,15,8],
[62,55,15,12],
[62,55,15,13],
[62,55,15,14],
[62,55,16,0],
[62,55,16,1],
[62,55,16,2],
[62,55,16,3],
[62,55,16,4],
[62,55,16,5],
[62,55,16,6],
[62,55,16,11],
[62,55,16,12],
[62,55,16,13],
[62,55,16,14],
[62,55,16,15],
[62,55,17,0],
[62,55,17,1],
[62,55,17,2],
[62,55,17,3],
[62,55,17,4],
[62,55,17,5],
[62,55,17,6],
[62,55,17,11],
[62,55,17,12],
[62,55,17,13],
[62,55,17,14],
[62,55,17,15],
[62,55,17,16],
[62,55,18,0],
[62,55,18,1],
[62,55,18,2],
[62,55,18,3],
[62,55,18,4],
[62,55,18,5],
[62,55,18,8],
[62,55,18,11],
[62,55,18,12],
[62,55,18,13],
[62,55,18,14],
[62,55,18,16],
[62,55,18,17],
[62,55,19,0],
[62,55,19,1],
[62,55,19,2],
[62,55,19,3],
[62,55,19,4],
[62,55,19,6],
[62,55,19,8],
[62,55,19,9],
[62,55,19,11],
[62,55,19,12],
[62,55,19,15],
[62,55,19,16],
[62,55,19,17],
[62,55,19,18],
[62,55,21,4],
[62,55,21,9],
[62,55,21,11],
[62,55,21,12],
[62,55,21,13],
[62,55,21,14],
[62,55,21,15],
[62,55,21,16],
[62,55,21,17],
[62,55,21,18],
[62,55,21,19],
[62,55,23,0],
[62,55,23,1],
[62,55,23,2],
[62,55,23,3],
[62,55,23,4],
[62,55,23,5],
[62,55,23,6],
[62,55,23,8],
[62,55,23,9],
[62,55,23,11],
[62,55,23,12],
[62,55,23,13],
[62,55,23,14],
[62,55,23,15],
[62,55,23,16],
[62,55,23,17],
[62,55,23,18],
[62,55,24,0],
[62,55,24,1],
[62,55,24,2],
[62,55,24,3],
[62,55,24,4],
[62,55,24,5],
[62,55,24,6],
[62,55,24,8],
[62,55,24,9],
[62,55,24,11],
[62,55,24,12],
[62,55,24,13],
[62,55,24,14],
[62,55,24,15],
[62,55,24,17],
[62,55,24,18],
[62,55,26,0],
[62,55,26,1],
[62,55,26,2],
[62,55,26,3],
[62,55,26,4],
[62,55,26,5],
[62,55,26,6],
[62,55,26,8],
[62,55,26,11],
[62,55,26,12],
[62,55,26,13],
[62,55,26,14],
[62,55,29,0],
[62,55,29,1],
[62,55,29,2],
[62,55,29,3],
[62,55,29,4],
[62,55,29,5],
[62,55,29,6],
[62,55,29,8],
[62,55,29,9],
[62,55,30,0],
[62,55,30,1],
[62,55,30,2],
[62,55,30,3],
[62,55,30,4],
[62,55,30,5],
[62,55,30,6],
[62,55,31,0],
[62,55,31,1],
[62,55,31,2],
[62,55,31,3],
[62,55,31,4],
[62,55,31,5],
[62,55,32,0],
[62,55,32,1],
[62,55,32,2],
[62,55,32,3],
[62,55,32,4],
[62,55,33,0],
[62,55,33,1],
[62,55,33,2],
[62,56,3,0],
[62,56,3,1],
[62,56,3,2],
[62,56,4,0],
[62,56,4,1],
[62,56,4,2],
[62,56,4,3],
[62,56,5,0],
[62,56,5,1],
[62,56,5,2],
[62,56,5,4],
[62,56,6,0],
[62,56,6,1],
[62,56,6,2],
[62,56,6,4],
[62,56,8,0],
[62,56,8,1],
[62,56,8,2],
[62,56,8,4],
[62,56,10,0],
[62,56,10,1],
[62,56,10,2],
[62,56,10,3],
[62,56,10,4],
[62,56,10,5],
[62,56,10,6],
[62,56,10,8],
[62,56,11,0],
[62,56,11,1],
[62,56,11,2],
[62,56,11,3],
[62,56,11,4],
[62,56,11,5],
[62,56,11,6],
[62,56,11,8],
[62,56,11,10],
[62,56,12,0],
[62,56,12,1],
[62,56,12,2],
[62,56,12,3],
[62,56,12,4],
[62,56,12,5],
[62,56,12,6],
[62,56,12,8],
[62,56,12,10],
[62,56,13,0],
[62,56,13,1],
[62,56,13,2],
[62,56,13,3],
[62,56,13,4],
[62,56,13,5],
[62,56,13,6],
[62,56,13,8],
[62,56,13,10],
[62,56,13,11],
[62,56,13,12],
[62,56,14,0],
[62,56,14,1],
[62,56,14,2],
[62,56,14,3],
[62,56,14,4],
[62,56,14,5],
[62,56,14,6],
[62,56,14,8],
[62,56,14,10],
[62,56,14,11],
[62,56,16,0],
[62,56,16,1],
[62,56,16,2],
[62,56,16,3],
[62,56,16,4],
[62,56,16,5],
[62,56,16,6],
[62,56,16,10],
[62,56,16,11],
[62,56,16,12],
[62,56,16,13],
[62,56,16,14],
[62,56,17,0],
[62,56,17,1],
[62,56,17,2],
[62,56,17,3],
[62,56,17,4],
[62,56,17,5],
[62,56,17,6],
[62,56,17,10],
[62,56,17,11],
[62,56,17,12],
[62,56,17,13],
[62,56,17,14],
[62,56,17,16],
[62,56,19,0],
[62,56,19,1],
[62,56,19,2],
[62,56,19,3],
[62,56,19,4],
[62,56,19,6],
[62,56,19,8],
[62,56,19,10],
[62,56,19,11],
[62,56,19,12],
[62,56,19,16],
[62,56,19,17],
[62,56,20,0],
[62,56,20,1],
[62,56,20,2],
[62,56,20,5],
[62,56,20,6],
[62,56,20,8],
[62,56,20,11],
[62,56,20,12],
[62,56,20,13],
[62,56,20,14],
[62,56,20,16],
[62,56,20,17],
[62,56,20,19],
[62,56,21,4],
[62,56,21,10],
[62,56,21,11],
[62,56,21,12],
[62,56,21,13],
[62,56,21,14],
[62,56,21,16],
[62,56,21,17],
[62,56,21,19],
[62,56,21,20],
[62,56,22,0],
[62,56,22,1],
[62,56,22,2],
[62,56,22,3],
[62,56,22,4],
[62,56,22,5],
[62,56,22,6],
[62,56,22,8],
[62,56,22,11],
[62,56,22,12],
[62,56,22,13],
[62,56,22,14],
[62,56,22,16],
[62,56,22,17],
[62,56,22,19],
[62,56,23,0],
[62,56,23,1],
[62,56,23,2],
[62,56,23,3],
[62,56,23,4],
[62,56,23,5],
[62,56,23,6],
[62,56,23,8],
[62,56,23,10],
[62,56,23,11],
[62,56,23,12],
[62,56,23,13],
[62,56,23,14],
[62,56,23,16],
[62,56,23,17],
[62,56,24,0],
[62,56,24,1],
[62,56,24,2],
[62,56,24,3],
[62,56,24,4],
[62,56,24,5],
[62,56,24,6],
[62,56,24,8],
[62,56,24,10],
[62,56,24,11],
[62,56,24,12],
[62,56,24,13],
[62,56,24,14],
[62,56,24,17],
[62,56,27,0],
[62,56,27,1],
[62,56,27,2],
[62,56,27,3],
[62,56,27,4],
[62,56,27,5],
[62,56,27,6],
[62,56,27,8],
[62,56,27,11],
[62,56,27,12],
[62,56,27,13],
[62,56,29,0],
[62,56,29,1],
[62,56,29,2],
[62,56,29,3],
[62,56,29,4],
[62,56,29,5],
[62,56,29,6],
[62,56,29,8],
[62,56,30,0],
[62,56,30,1],
[62,56,30,2],
[62,56,30,3],
[62,56,30,4],
[62,56,30,5],
[62,56,30,6],
[62,56,31,0],
[62,56,31,1],
[62,56,31,2],
[62,56,31,3],
[62,56,31,4],
[62,56,31,5],
[62,56,32,0],
[62,56,32,1],
[62,56,32,2],
[62,56,32,3],
[62,56,32,4],
[62,56,33,0],
[62,56,33,1],
[62,56,33,2],
[62,57,3,0],
[62,57,3,1],
[62,57,3,2],
[62,57,4,0],
[62,57,4,1],
[62,57,4,2],
[62,57,4,3],
[62,57,5,0],
[62,57,5,1],
[62,57,5,2],
[62,57,5,4],
[62,57,6,0],
[62,57,6,1],
[62,57,6,2],
[62,57,6,4],
[62,57,8,0],
[62,57,8,1],
[62,57,8,2],
[62,57,8,4],
[62,57,9,0],
[62,57,9,1],
[62,57,9,2],
[62,57,9,3],
[62,57,9,4],
[62,57,9,5],
[62,57,9,6],
[62,57,9,8],
[62,57,10,0],
[62,57,10,1],
[62,57,10,2],
[62,57,10,3],
[62,57,10,4],
[62,57,10,5],
[62,57,10,6],
[62,57,10,8],
[62,57,10,9],
[62,57,11,0],
[62,57,11,1],
[62,57,11,2],
[62,57,11,3],
[62,57,11,4],
[62,57,11,5],
[62,57,11,6],
[62,57,11,8],
[62,57,11,9],
[62,57,11,10],
[62,57,12,0],
[62,57,12,1],
[62,57,12,2],
[62,57,12,3],
[62,57,12,4],
[62,57,12,5],
[62,57,12,6],
[62,57,12,8],
[62,57,12,9],
[62,57,12,10],
[62,57,13,0],
[62,57,13,1],
[62,57,13,2],
[62,57,13,3],
[62,57,13,4],
[62,57,13,5],
[62,57,13,6],
[62,57,13,8],
[62,57,13,9],
[62,57,13,10],
[62,57,13,11],
[62,57,14,0],
[62,57,14,1],
[62,57,14,2],
[62,57,14,3],
[62,57,14,4],
[62,57,14,5],
[62,57,14,6],
[62,57,14,8],
[62,57,14,9],
[62,57,14,12],
[62,57,15,0],
[62,57,15,1],
[62,57,15,2],
[62,57,15,3],
[62,57,15,4],
[62,57,15,5],
[62,57,15,6],
[62,57,15,10],
[62,57,15,11],
[62,57,15,12],
[62,57,15,13],
[62,57,15,14],
[62,57,16,0],
[62,57,16,1],
[62,57,16,2],
[62,57,16,3],
[62,57,16,4],
[62,57,16,5],
[62,57,16,8],
[62,57,16,9],
[62,57,16,10],
[62,57,16,11],
[62,57,16,12],
[62,57,16,13],
[62,57,16,14],
[62,57,16,15],
[62,57,17,0],
[62,57,17,1],
[62,57,17,2],
[62,57,17,3],
[62,57,17,4],
[62,57,17,5],
[62,57,17,8],
[62,57,17,9],
[62,57,17,10],
[62,57,17,11],
[62,57,17,12],
[62,57,17,13],
[62,57,17,14],
[62,57,17,15],
[62,57,17,16],
[62,57,18,0],
[62,57,18,1],
[62,57,18,2],
[62,57,18,3],
[62,57,18,4],
[62,57,18,6],
[62,57,18,8],
[62,57,18,10],
[62,57,18,11],
[62,57,18,12],
[62,57,18,13],
[62,57,18,14],
[62,57,18,16],
[62,57,18,17],
[62,57,19,0],
[62,57,19,1],
[62,57,19,2],
[62,57,19,5],
[62,57,19,6],
[62,57,19,8],
[62,57,19,9],
[62,57,19,10],
[62,57,19,11],
[62,57,19,12],
[62,57,19,15],
[62,57,19,16],
[62,57,19,17],
[62,57,19,18],
[62,57,20,3],
[62,57,20,4],
[62,57,20,5],
[62,57,20,6],
[62,57,20,8],
[62,57,20,9],
[62,57,20,11],
[62,57,20,12],
[62,57,20,13],
[62,57,20,14],
[62,57,20,15],
[62,57,20,16],
[62,57,20,17],
[62,57,20,18],
[62,57,20,19],
[62,57,21,0],
[62,57,21,1],
[62,57,21,2],
[62,57,21,4],
[62,57,21,9],
[62,57,21,10],
[62,57,21,11],
[62,57,21,12],
[62,57,21,13],
[62,57,21,14],
[62,57,21,15],
[62,57,21,16],
[62,57,21,17],
[62,57,21,18],
[62,57,21,19],
[62,57,22,0],
[62,57,22,1],
[62,57,22,2],
[62,57,22,3],
[62,57,22,4],
[62,57,22,5],
[62,57,22,6],
[62,57,22,8],
[62,57,22,9],
[62,57,22,11],
[62,57,22,12],
[62,57,22,13],
[62,57,22,14],
[62,57,22,15],
[62,57,22,16],
[62,57,22,17],
[62,57,22,18],
[62,57,24,0],
[62,57,24,1],
[62,57,24,2],
[62,57,24,3],
[62,57,24,4],
[62,57,24,5],
[62,57,24,6],
[62,57,24,8],
[62,57,24,9],
[62,57,24,10],
[62,57,24,11],
[62,57,24,12],
[62,57,24,13],
[62,57,24,14],
[62,57,24,15],
[62,57,24,17],
[62,57,26,0],
[62,57,26,1],
[62,57,26,2],
[62,57,26,3],
[62,57,26,4],
[62,57,26,5],
[62,57,26,6],
[62,57,26,8],
[62,57,26,10],
[62,57,26,11],
[62,57,26,12],
[62,57,26,13],
[62,57,27,0],
[62,57,27,1],
[62,57,27,2],
[62,57,27,3],
[62,57,27,4],
[62,57,27,5],
[62,57,27,6],
[62,57,27,8],
[62,57,27,9],
[62,57,27,11],
[62,57,29,0],
[62,57,29,1],
[62,57,29,2],
[62,57,29,3],
[62,57,29,4],
[62,57,29,5],
[62,57,29,6],
[62,57,32,0],
[62,57,32,1],
[62,57,32,2],
[62,58,3,0],
[62,58,3,1],
[62,58,3,2],
[62,58,4,0],
[62,58,4,1],
[62,58,4,2],
[62,58,4,3],
[62,58,5,0],
[62,58,5,1],
[62,58,5,2],
[62,58,5,4],
[62,58,6,0],
[62,58,6,1],
[62,58,6,2],
[62,58,6,4],
[62,58,8,0],
[62,58,8,1],
[62,58,8,2],
[62,58,8,4],
[62,58,9,0],
[62,58,9,1],
[62,58,9,2],
[62,58,9,3],
[62,58,9,4],
[62,58,9,5],
[62,58,9,6],
[62,58,9,8],
[62,58,10,0],
[62,58,10,1],
[62,58,10,2],
[62,58,10,3],
[62,58,10,4],
[62,58,10,5],
[62,58,10,6],
[62,58,10,8],
[62,58,10,9],
[62,58,11,0],
[62,58,11,1],
[62,58,11,2],
[62,58,11,3],
[62,58,11,4],
[62,58,11,5],
[62,58,11,6],
[62,58,11,8],
[62,58,11,9],
[62,58,11,10],
[62,58,12,0],
[62,58,12,1],
[62,58,12,2],
[62,58,12,3],
[62,58,12,4],
[62,58,12,5],
[62,58,12,6],
[62,58,12,8],
[62,58,12,9],
[62,58,12,10],
[62,58,13,0],
[62,58,13,1],
[62,58,13,2],
[62,58,13,3],
[62,58,13,4],
[62,58,13,5],
[62,58,13,6],
[62,58,13,8],
[62,58,13,9],
[62,58,13,10],
[62,58,13,11],
[62,58,14,0],
[62,58,14,1],
[62,58,14,2],
[62,58,14,3],
[62,58,14,4],
[62,58,14,5],
[62,58,14,6],
[62,58,14,8],
[62,58,14,9],
[62,58,14,12],
[62,58,15,0],
[62,58,15,1],
[62,58,15,2],
[62,58,15,3],
[62,58,15,4],
[62,58,15,5],
[62,58,15,6],
[62,58,15,10],
[62,58,15,11],
[62,58,15,12],
[62,58,15,13],
[62,58,15,14],
[62,58,17,0],
[62,58,17,1],
[62,58,17,2],
[62,58,17,3],
[62,58,17,4],
[62,58,17,5],
[62,58,17,8],
[62,58,17,9],
[62,58,17,10],
[62,58,17,11],
[62,58,17,12],
[62,58,17,13],
[62,58,17,14],
[62,58,17,15],
[62,58,18,0],
[62,58,18,1],
[62,58,18,2],
[62,58,18,3],
[62,58,18,4],
[62,58,18,6],
[62,58,18,8],
[62,58,18,10],
[62,58,18,11],
[62,58,18,12],
[62,58,18,13],
[62,58,18,14],
[62,58,18,17],
[62,58,19,0],
[62,58,19,1],
[62,58,19,2],
[62,58,19,5],
[62,58,19,6],
[62,58,19,8],
[62,58,19,9],
[62,58,19,10],
[62,58,19,11],
[62,58,19,12],
[62,58,19,15],
[62,58,19,17],
[62,58,19,18],
[62,58,20,3],
[62,58,20,4],
[62,58,20,5],
[62,58,20,6],
[62,58,20,8],
[62,58,20,9],
[62,58,20,11],
[62,58,20,12],
[62,58,20,13],
[62,58,20,14],
[62,58,20,15],
[62,58,20,17],
[62,58,20,18],
[62,58,20,19],
[62,58,21,0],
[62,58,21,1],
[62,58,21,2],
[62,58,21,4],
[62,58,21,9],
[62,58,21,10],
[62,58,21,11],
[62,58,21,12],
[62,58,21,13],
[62,58,21,14],
[62,58,21,15],
[62,58,21,17],
[62,58,21,18],
[62,58,21,19],
[62,58,22,0],
[62,58,22,1],
[62,58,22,2],
[62,58,22,3],
[62,58,22,4],
[62,58,22,5],
[62,58,22,6],
[62,58,22,8],
[62,58,22,9],
[62,58,22,11],
[62,58,22,12],
[62,58,22,13],
[62,58,22,14],
[62,58,22,15],
[62,58,22,17],
[62,58,22,18],
[62,58,23,0],
[62,58,23,1],
[62,58,23,2],
[62,58,23,3],
[62,58,23,4],
[62,58,23,5],
[62,58,23,6],
[62,58,23,8],
[62,58,23,9],
[62,58,23,10],
[62,58,23,11],
[62,58,23,12],
[62,58,23,13],
[62,58,23,14],
[62,58,23,15],
[62,58,23,17],
[62,58,26,0],
[62,58,26,1],
[62,58,26,2],
[62,58,26,3],
[62,58,26,4],
[62,58,26,5],
[62,58,26,6],
[62,58,26,8],
[62,58,26,10],
[62,58,26,11],
[62,58,26,12],
[62,58,26,13],
[62,58,27,0],
[62,58,27,1],
[62,58,27,2],
[62,58,27,3],
[62,58,27,4],
[62,58,27,5],
[62,58,27,6],
[62,58,27,8],
[62,58,27,9],
[62,58,27,11],
[62,58,29,0],
[62,58,29,1],
[62,58,29,2],
[62,58,29,3],
[62,58,29,4],
[62,58,29,5],
[62,58,29,6],
[62,58,30,0],
[62,58,30,1],
[62,58,30,2],
[62,58,30,3],
[62,58,30,4],
[62,58,30,5],
[62,58,31,0],
[62,58,31,1],
[62,58,31,2],
[62,58,31,3],
[62,58,31,4],
[62,58,32,0],
[62,58,32,1],
[62,58,32,2],
[62,59,3,0],
[62,59,3,1],
[62,59,3,2],
[62,59,4,0],
[62,59,4,1],
[62,59,4,2],
[62,59,4,3],
[62,59,5,0],
[62,59,5,1],
[62,59,5,2],
[62,59,5,4],
[62,59,6,0],
[62,59,6,1],
[62,59,6,2],
[62,59,6,4],
[62,59,8,0],
[62,59,8,1],
[62,59,8,2],
[62,59,8,4],
[62,59,10,0],
[62,59,10,1],
[62,59,10,2],
[62,59,10,3],
[62,59,10,4],
[62,59,10,5],
[62,59,10,6],
[62,59,10,8],
[62,59,11,0],
[62,59,11,1],
[62,59,11,2],
[62,59,11,3],
[62,59,11,4],
[62,59,11,5],
[62,59,11,6],
[62,59,11,8],
[62,59,11,10],
[62,59,12,0],
[62,59,12,1],
[62,59,12,2],
[62,59,12,3],
[62,59,12,4],
[62,59,12,5],
[62,59,12,6],
[62,59,12,8],
[62,59,13,0],
[62,59,13,1],
[62,59,13,2],
[62,59,13,3],
[62,59,13,4],
[62,59,13,5],
[62,59,13,6],
[62,59,13,8],
[62,59,13,12],
[62,59,14,0],
[62,59,14,1],
[62,59,14,2],
[62,59,14,3],
[62,59,14,4],
[62,59,14,5],
[62,59,14,6],
[62,59,14,10],
[62,59,14,11],
[62,59,14,12],
[62,59,16,0],
[62,59,16,1],
[62,59,16,2],
[62,59,16,3],
[62,59,16,4],
[62,59,16,6],
[62,59,16,8],
[62,59,16,10],
[62,59,16,11],
[62,59,16,12],
[62,59,16,13],
[62,59,16,14],
[62,59,17,0],
[62,59,17,1],
[62,59,17,2],
[62,59,17,3],
[62,59,17,4],
[62,59,17,6],
[62,59,17,8],
[62,59,17,10],
[62,59,17,11],
[62,59,17,12],
[62,59,17,13],
[62,59,17,14],
[62,59,17,16],
[62,59,19,3],
[62,59,19,4],
[62,59,19,5],
[62,59,19,6],
[62,59,19,8],
[62,59,19,10],
[62,59,19,11],
[62,59,19,12],
[62,59,19,16],
[62,59,19,17],
[62,59,20,0],
[62,59,20,1],
[62,59,20,2],
[62,59,20,3],
[62,59,20,4],
[62,59,20,5],
[62,59,20,6],
[62,59,20,8],
[62,59,20,11],
[62,59,20,12],
[62,59,20,13],
[62,59,20,14],
[62,59,20,16],
[62,59,20,17],
[62,59,20,19],
[62,59,21,0],
[62,59,21,1],
[62,59,21,2],
[62,59,21,4],
[62,59,21,10],
[62,59,21,11],
[62,59,21,12],
[62,59,21,13],
[62,59,21,14],
[62,59,21,16],
[62,59,21,17],
[62,59,22,0],
[62,59,22,1],
[62,59,22,2],
[62,59,22,3],
[62,59,22,4],
[62,59,22,5],
[62,59,22,6],
[62,59,22,8],
[62,59,22,11],
[62,59,22,12],
[62,59,22,13],
[62,59,22,14],
[62,59,22,16],
[62,59,22,17],
[62,59,23,0],
[62,59,23,1],
[62,59,23,2],
[62,59,23,3],
[62,59,23,4],
[62,59,23,5],
[62,59,23,6],
[62,59,23,8],
[62,59,23,10],
[62,59,23,11],
[62,59,23,12],
[62,59,23,13],
[62,59,23,14],
[62,59,24,0],
[62,59,24,1],
[62,59,24,2],
[62,59,24,3],
[62,59,24,4],
[62,59,24,5],
[62,59,24,6],
[62,59,24,8],
[62,59,24,10],
[62,59,24,11],
[62,59,24,12],
[62,59,24,13],
[62,59,24,14],
[62,59,27,0],
[62,59,27,1],
[62,59,27,2],
[62,59,27,3],
[62,59,27,4],
[62,59,27,5],
[62,59,27,6],
[62,59,27,8],
[62,59,29,0],
[62,59,29,1],
[62,59,29,2],
[62,59,29,3],
[62,59,29,4],
[62,59,29,5],
[62,59,30,0],
[62,59,30,1],
[62,59,30,2],
[62,59,30,3],
[62,59,30,4],
[62,59,31,0],
[62,59,31,1],
[62,59,31,2],
[62,60,3,0],
[62,60,3,1],
[62,60,3,2],
[62,60,4,0],
[62,60,4,1],
[62,60,4,2],
[62,60,4,3],
[62,60,5,0],
[62,60,5,1],
[62,60,5,2],
[62,60,5,4],
[62,60,6,0],
[62,60,6,1],
[62,60,6,2],
[62,60,6,4],
[62,60,8,0],
[62,60,8,1],
[62,60,8,2],
[62,60,8,4],
[62,60,9,0],
[62,60,9,1],
[62,60,9,2],
[62,60,9,3],
[62,60,9,4],
[62,60,9,5],
[62,60,9,6],
[62,60,9,8],
[62,60,10,0],
[62,60,10,1],
[62,60,10,2],
[62,60,10,3],
[62,60,10,4],
[62,60,10,5],
[62,60,10,6],
[62,60,10,8],
[62,60,10,9],
[62,60,11,0],
[62,60,11,1],
[62,60,11,2],
[62,60,11,3],
[62,60,11,4],
[62,60,11,5],
[62,60,11,6],
[62,60,11,8],
[62,60,11,9],
[62,60,12,0],
[62,60,12,1],
[62,60,12,2],
[62,60,12,3],
[62,60,12,4],
[62,60,12,5],
[62,60,12,6],
[62,60,12,10],
[62,60,15,0],
[62,60,15,1],
[62,60,15,2],
[62,60,15,3],
[62,60,15,4],
[62,60,15,6],
[62,60,15,8],
[62,60,15,10],
[62,60,15,11],
[62,60,15,12],
[62,60,16,0],
[62,60,16,1],
[62,60,16,2],
[62,60,16,5],
[62,60,16,6],
[62,60,16,8],
[62,60,16,9],
[62,60,16,10],
[62,60,16,11],
[62,60,16,12],
[62,60,16,15],
[62,60,17,0],
[62,60,17,1],
[62,60,17,2],
[62,60,17,5],
[62,60,17,6],
[62,60,17,8],
[62,60,17,9],
[62,60,17,10],
[62,60,17,11],
[62,60,17,12],
[62,60,17,15],
[62,60,17,16],
[62,60,18,3],
[62,60,18,4],
[62,60,18,5],
[62,60,18,6],
[62,60,18,8],
[62,60,18,10],
[62,60,18,11],
[62,60,18,12],
[62,60,18,16],
[62,60,18,17],
[62,60,20,0],
[62,60,20,1],
[62,60,20,2],
[62,60,20,3],
[62,60,20,4],
[62,60,20,5],
[62,60,20,6],
[62,60,20,8],
[62,60,20,9],
[62,60,20,11],
[62,60,20,12],
[62,60,20,15],
[62,60,20,16],
[62,60,20,17],
[62,60,20,18],
[62,60,21,0],
[62,60,21,1],
[62,60,21,2],
[62,60,21,4],
[62,60,21,9],
[62,60,21,10],
[62,60,21,11],
[62,60,21,12],
[62,60,21,15],
[62,60,21,16],
[62,60,21,17],
[62,60,22,0],
[62,60,22,1],
[62,60,22,2],
[62,60,22,3],
[62,60,22,4],
[62,60,22,5],
[62,60,22,6],
[62,60,22,8],
[62,60,22,9],
[62,60,22,11],
[62,60,22,12],
[62,60,22,15],
[62,60,23,0],
[62,60,23,1],
[62,60,23,2],
[62,60,23,3],
[62,60,23,4],
[62,60,23,5],
[62,60,23,6],
[62,60,23,8],
[62,60,23,9],
[62,60,23,10],
[62,60,23,11],
[62,60,23,12],
[62,60,24,0],
[62,60,24,1],
[62,60,24,2],
[62,60,24,3],
[62,60,24,4],
[62,60,24,5],
[62,60,24,6],
[62,60,24,8],
[62,60,24,9],
[62,60,24,10],
[62,60,24,11],
[62,60,24,12],
[62,60,26,0],
[62,60,26,1],
[62,60,26,2],
[62,60,26,3],
[62,60,26,4],
[62,60,26,5],
[62,60,26,6],
[62,60,26,8],
[62,60,27,0],
[62,60,27,1],
[62,60,27,2],
[62,60,27,3],
[62,60,27,4],
[62,60,27,5],
[62,60,27,6],
[62,60,29,0],
[62,60,29,1],
[62,60,29,2],
[62,60,29,3],
[62,60,29,4],
[62,60,30,0],
[62,60,30,1],
[62,60,30,2],
[62,61,3,0],
[62,61,3,1],
[62,61,3,2],
[62,61,5,0],
[62,61,5,1],
[62,61,5,2],
[62,61,6,0],
[62,61,6,1],
[62,61,6,2],
[62,61,8,0],
[62,61,8,1],
[62,61,8,2],
[62,61,9,0],
[62,61,9,1],
[62,61,9,2],
[62,61,9,3],
[62,61,9,5],
[62,61,9,6],
[62,61,9,8],
[62,61,10,0],
[62,61,10,1],
[62,61,10,2],
[62,61,10,3],
[62,61,10,5],
[62,61,10,6],
[62,61,11,0],
[62,61,11,1],
[62,61,11,2],
[62,61,11,3],
[62,61,11,5],
[62,61,11,6],
[62,61,11,10],
[62,61,12,0],
[62,61,12,1],
[62,61,12,2],
[62,61,12,3],
[62,61,12,5],
[62,61,12,8],
[62,61,12,9],
[62,61,12,10],
[62,61,13,0],
[62,61,13,1],
[62,61,13,2],
[62,61,13,3],
[62,61,13,5],
[62,61,13,8],
[62,61,13,9],
[62,61,13,10],
[62,61,13,11],
[62,61,13,12],
[62,61,14,0],
[62,61,14,1],
[62,61,14,2],
[62,61,14,3],
[62,61,14,6],
[62,61,14,8],
[62,61,14,9],
[62,61,14,10],
[62,61,14,11],
[62,61,14,12],
[62,61,15,0],
[62,61,15,1],
[62,61,15,2],
[62,61,15,5],
[62,61,15,6],
[62,61,15,8],
[62,61,15,10],
[62,61,15,11],
[62,61,15,12],
[62,61,15,13],
[62,61,15,14],
[62,61,16,3],
[62,61,16,5],
[62,61,16,6],
[62,61,16,8],
[62,61,16,9],
[62,61,16,10],
[62,61,16,11],
[62,61,16,12],
[62,61,16,13],
[62,61,16,14],
[62,61,16,15],
[62,61,17,3],
[62,61,17,5],
[62,61,17,6],
[62,61,17,8],
[62,61,17,9],
[62,61,17,10],
[62,61,17,11],
[62,61,17,12],
[62,61,17,13],
[62,61,17,14],
[62,61,17,15],
[62,61,17,16],
[62,61,18,0],
[62,61,18,1],
[62,61,18,2],
[62,61,18,3],
[62,61,18,5],
[62,61,18,6],
[62,61,18,8],
[62,61,18,10],
[62,61,18,11],
[62,61,18,12],
[62,61,18,13],
[62,61,18,14],
[62,61,18,16],
[62,61,18,17],
[62,61,19,0],
[62,61,19,1],
[62,61,19,2],
[62,61,19,3],
[62,61,19,5],
[62,61,19,6],
[62,61,19,8],
[62,61,19,9],
[62,61,19,10],
[62,61,19,11],
[62,61,19,12],
[62,61,19,15],
[62,61,19,16],
[62,61,19,17],
[62,61,19,18],
[62,61,20,0],
[62,61,20,1],
[62,61,20,2],
[62,61,20,3],
[62,61,20,5],
[62,61,20,6],
[62,61,20,8],
[62,61,20,9],
[62,61,20,11],
[62,61,20,12],
[62,61,20,13],
[62,61,20,14],
[62,61,20,15],
[62,61,20,16],
[62,61,20,17],
[62,61,21,0],
[62,61,21,1],
[62,61,21,2],
[62,61,21,9],
[62,61,21,10],
[62,61,21,11],
[62,61,21,12],
[62,61,21,13],
[62,61,21,14],
[62,61,21,15],
[62,61,22,0],
[62,61,22,1],
[62,61,22,2],
[62,61,22,3],
[62,61,22,5],
[62,61,22,6],
[62,61,22,8],
[62,61,22,9],
[62,61,22,11],
[62,61,22,12],
[62,61,22,13],
[62,61,22,14],
[62,61,23,0],
[62,61,23,1],
[62,61,23,2],
[62,61,23,3],
[62,61,23,5],
[62,61,23,6],
[62,61,23,8],
[62,61,23,9],
[62,61,23,10],
[62,61,23,11],
[62,61,23,12],
[62,61,23,13],
[62,61,24,0],
[62,61,24,1],
[62,61,24,2],
[62,61,24,3],
[62,61,24,5],
[62,61,24,6],
[62,61,24,8],
[62,61,24,9],
[62,61,24,10],
[62,61,24,11],
[62,61,24,12],
[62,61,24,13],
[62,61,26,0],
[62,61,26,1],
[62,61,26,2],
[62,61,26,3],
[62,61,26,5],
[62,61,26,6],
[62,61,27,0],
[62,61,27,1],
[62,61,27,2],
[62,61,27,3],
[62,61,27,5],
[62,61,29,0],
[62,61,29,1],
[62,61,29,2],
[63,4,3,0],
[63,4,3,1],
[63,4,3,2],
[63,5,4,0],
[63,5,4,1],
[63,5,4,2],
[63,6,4,0],
[63,6,4,1],
[63,6,4,2],
[63,7,3,0],
[63,7,3,1],
[63,7,3,2],
[63,7,4,0],
[63,7,4,1],
[63,7,4,2],
[63,7,4,3],
[63,7,5,0],
[63,7,5,1],
[63,7,5,2],
[63,7,5,4],
[63,7,6,0],
[63,7,6,1],
[63,7,6,2],
[63,7,6,4],
[63,8,4,0],
[63,8,4,1],
[63,8,4,2],
[63,8,7,0],
[63,8,7,1],
[63,8,7,2],
[63,8,7,4],
[63,10,3,0],
[63,10,3,1],
[63,10,3,2],
[63,10,4,0],
[63,10,4,1],
[63,10,4,2],
[63,10,4,3],
[63,10,5,0],
[63,10,5,1],
[63,10,5,2],
[63,10,5,4],
[63,10,6,0],
[63,10,6,1],
[63,10,6,2],
[63,10,6,4],
[63,10,7,0],
[63,10,7,1],
[63,10,7,2],
[63,10,7,3],
[63,10,7,4],
[63,10,7,5],
[63,10,7,6],
[63,10,8,0],
[63,10,8,1],
[63,10,8,2],
[63,10,8,4],
[63,10,8,7],
[63,11,3,0],
[63,11,3,1],
[63,11,3,2],
[63,11,4,0],
[63,11,4,1],
[63,11,4,2],
[63,11,4,3],
[63,11,5,0],
[63,11,5,1],
[63,11,5,2],
[63,11,5,4],
[63,11,6,0],
[63,11,6,1],
[63,11,6,2],
[63,11,6,4],
[63,11,7,0],
[63,11,7,1],
[63,11,7,2],
[63,11,7,3],
[63,11,7,4],
[63,11,7,5],
[63,11,7,6],
[63,11,8,0],
[63,11,8,1],
[63,11,8,2],
[63,11,8,4],
[63,11,8,7],
[63,11,10,0],
[63,11,10,1],
[63,11,10,2],
[63,11,10,3],
[63,11,10,4],
[63,11,10,5],
[63,11,10,6],
[63,11,10,7],
[63,11,10,8],
[63,12,3,0],
[63,12,3,1],
[63,12,3,2],
[63,12,4,0],
[63,12,4,1],
[63,12,4,2],
[63,12,4,3],
[63,12,5,0],
[63,12,5,1],
[63,12,5,2],
[63,12,5,4],
[63,12,6,0],
[63,12,6,1],
[63,12,6,2],
[63,12,6,4],
[63,12,7,0],
[63,12,7,1],
[63,12,7,2],
[63,12,7,3],
[63,12,7,4],
[63,12,7,5],
[63,12,7,6],
[63,12,8,0],
[63,12,8,1],
[63,12,8,2],
[63,12,8,4],
[63,12,8,7],
[63,12,10,0],
[63,12,10,1],
[63,12,10,2],
[63,12,10,3],
[63,12,10,4],
[63,12,10,5],
[63,12,10,6],
[63,12,10,7],
[63,12,10,8],
[63,13,3,0],
[63,13,3,1],
[63,13,3,2],
[63,13,4,0],
[63,13,4,1],
[63,13,4,2],
[63,13,4,3],
[63,13,5,0],
[63,13,5,1],
[63,13,5,2],
[63,13,5,4],
[63,13,6,0],
[63,13,6,1],
[63,13,6,2],
[63,13,6,4],
[63,13,7,0],
[63,13,7,1],
[63,13,7,2],
[63,13,7,3],
[63,13,7,4],
[63,13,7,5],
[63,13,7,6],
[63,13,8,0],
[63,13,8,1],
[63,13,8,2],
[63,13,8,4],
[63,13,8,7],
[63,13,10,0],
[63,13,10,1],
[63,13,10,2],
[63,13,10,3],
[63,13,10,4],
[63,13,10,5],
[63,13,10,6],
[63,13,10,7],
[63,13,10,8],
[63,13,11,0],
[63,13,11,1],
[63,13,11,2],
[63,13,11,3],
[63,13,11,4],
[63,13,11,5],
[63,13,11,6],
[63,13,11,7],
[63,13,11,8],
[63,13,11,10],
[63,13,12,0],
[63,13,12,1],
[63,13,12,2],
[63,13,12,3],
[63,13,12,4],
[63,13,12,5],
[63,13,12,6],
[63,13,12,7],
[63,13,12,8],
[63,13,12,10],
[63,14,3,0],
[63,14,3,1],
[63,14,3,2],
[63,14,4,0],
[63,14,4,1],
[63,14,4,2],
[63,14,4,3],
[63,14,5,0],
[63,14,5,1],
[63,14,5,2],
[63,14,5,4],
[63,14,6,0],
[63,14,6,1],
[63,14,6,2],
[63,14,6,4],
[63,14,7,0],
[63,14,7,1],
[63,14,7,2],
[63,14,7,3],
[63,14,7,4],
[63,14,7,5],
[63,14,7,6],
[63,14,8,0],
[63,14,8,1],
[63,14,8,2],
[63,14,8,4],
[63,14,8,7],
[63,14,10,0],
[63,14,10,1],
[63,14,10,2],
[63,14,10,3],
[63,14,10,4],
[63,14,10,5],
[63,14,10,6],
[63,14,10,7],
[63,14,10,8],
[63,14,11,0],
[63,14,11,1],
[63,14,11,2],
[63,14,11,3],
[63,14,11,4],
[63,14,11,5],
[63,14,11,6],
[63,14,11,7],
[63,14,11,8],
[63,14,11,10],
[63,14,12,0],
[63,14,12,1],
[63,14,12,2],
[63,14,12,3],
[63,14,12,4],
[63,14,12,5],
[63,14,12,6],
[63,14,12,7],
[63,14,12,8],
[63,14,12,10],
[63,16,3,0],
[63,16,3,1],
[63,16,3,2],
[63,16,4,0],
[63,16,4,1],
[63,16,4,2],
[63,16,4,3],
[63,16,5,0],
[63,16,5,1],
[63,16,5,2],
[63,16,5,4],
[63,16,6,0],
[63,16,6,1],
[63,16,6,2],
[63,16,6,4],
[63,16,7,0],
[63,16,7,1],
[63,16,7,2],
[63,16,7,3],
[63,16,7,4],
[63,16,7,5],
[63,16,7,6],
[63,16,8,0],
[63,16,8,1],
[63,16,8,2],
[63,16,8,4],
[63,16,8,7],
[63,16,10,0],
[63,16,10,1],
[63,16,10,2],
[63,16,10,3],
[63,16,10,4],
[63,16,10,5],
[63,16,10,6],
[63,16,10,7],
[63,16,10,8],
[63,16,11,0],
[63,16,11,1],
[63,16,11,2],
[63,16,11,3],
[63,16,11,4],
[63,16,11,5],
[63,16,11,6],
[63,16,11,7],
[63,16,11,8],
[63,16,11,10],
[63,16,12,0],
[63,16,12,1],
[63,16,12,2],
[63,16,12,3],
[63,16,12,4],
[63,16,12,5],
[63,16,12,6],
[63,16,12,7],
[63,16,12,8],
[63,16,12,10],
[63,16,13,0],
[63,16,13,1],
[63,16,13,2],
[63,16,13,3],
[63,16,13,4],
[63,16,13,5],
[63,16,13,6],
[63,16,13,7],
[63,16,13,8],
[63,16,13,10],
[63,16,13,11],
[63,16,13,12],
[63,16,14,0],
[63,16,14,1],
[63,16,14,2],
[63,16,14,3],
[63,16,14,4],
[63,16,14,5],
[63,16,14,6],
[63,16,14,7],
[63,16,14,8],
[63,16,14,10],
[63,16,14,11],
[63,16,14,12],
[63,17,3,0],
[63,17,3,1],
[63,17,3,2],
[63,17,4,0],
[63,17,4,1],
[63,17,4,2],
[63,17,4,3],
[63,17,5,0],
[63,17,5,1],
[63,17,5,2],
[63,17,5,4],
[63,17,6,0],
[63,17,6,1],
[63,17,6,2],
[63,17,6,4],
[63,17,7,0],
[63,17,7,1],
[63,17,7,2],
[63,17,7,3],
[63,17,7,4],
[63,17,7,5],
[63,17,7,6],
[63,17,8,0],
[63,17,8,1],
[63,17,8,2],
[63,17,8,4],
[63,17,8,7],
[63,17,10,0],
[63,17,10,1],
[63,17,10,2],
[63,17,10,3],
[63,17,10,4],
[63,17,10,5],
[63,17,10,6],
[63,17,10,7],
[63,17,10,8],
[63,17,11,0],
[63,17,11,1],
[63,17,11,2],
[63,17,11,3],
[63,17,11,4],
[63,17,11,5],
[63,17,11,6],
[63,17,11,7],
[63,17,11,8],
[63,17,11,10],
[63,17,12,0],
[63,17,12,1],
[63,17,12,2],
[63,17,12,3],
[63,17,12,4],
[63,17,12,5],
[63,17,12,6],
[63,17,12,7],
[63,17,12,8],
[63,17,12,10],
[63,17,13,0],
[63,17,13,1],
[63,17,13,2],
[63,17,13,3],
[63,17,13,4],
[63,17,13,5],
[63,17,13,6],
[63,17,13,7],
[63,17,13,8],
[63,17,13,10],
[63,17,13,11],
[63,17,13,12],
[63,17,14,0],
[63,17,14,1],
[63,17,14,2],
[63,17,14,3],
[63,17,14,4],
[63,17,14,5],
[63,17,14,6],
[63,17,14,7],
[63,17,14,8],
[63,17,14,10],
[63,17,14,11],
[63,17,14,12],
[63,17,16,0],
[63,17,16,1],
[63,17,16,2],
[63,17,16,3],
[63,17,16,4],
[63,17,16,5],
[63,17,16,6],
[63,17,16,7],
[63,17,16,8],
[63,17,16,10],
[63,17,16,11],
[63,17,16,12],
[63,17,16,13],
[63,17,16,14],
[63,19,3,0],
[63,19,3,1],
[63,19,3,2],
[63,19,4,0],
[63,19,4,1],
[63,19,4,2],
[63,19,4,3],
[63,19,5,0],
[63,19,5,1],
[63,19,5,2],
[63,19,5,4],
[63,19,6,0],
[63,19,6,1],
[63,19,6,2],
[63,19,6,4],
[63,19,7,0],
[63,19,7,1],
[63,19,7,2],
[63,19,7,3],
[63,19,7,4],
[63,19,7,5],
[63,19,7,6],
[63,19,8,0],
[63,19,8,1],
[63,19,8,2],
[63,19,8,4],
[63,19,8,7],
[63,19,10,0],
[63,19,10,1],
[63,19,10,2],
[63,19,10,3],
[63,19,10,4],
[63,19,10,5],
[63,19,10,6],
[63,19,10,7],
[63,19,10,8],
[63,19,11,0],
[63,19,11,1],
[63,19,11,2],
[63,19,11,3],
[63,19,11,4],
[63,19,11,5],
[63,19,11,6],
[63,19,11,7],
[63,19,11,8],
[63,19,11,10],
[63,19,12,0],
[63,19,12,1],
[63,19,12,2],
[63,19,12,3],
[63,19,12,4],
[63,19,12,5],
[63,19,12,6],
[63,19,12,7],
[63,19,12,8],
[63,19,12,10],
[63,19,16,0],
[63,19,16,1],
[63,19,16,2],
[63,19,16,3],
[63,19,16,4],
[63,19,16,5],
[63,19,16,6],
[63,19,16,7],
[63,19,16,8],
[63,19,16,10],
[63,19,16,11],
[63,19,16,12],
[63,19,17,0],
[63,19,17,1],
[63,19,17,2],
[63,19,17,3],
[63,19,17,4],
[63,19,17,5],
[63,19,17,6],
[63,19,17,7],
[63,19,17,8],
[63,19,17,10],
[63,19,17,11],
[63,19,17,12],
[63,19,17,16],
[63,20,3,0],
[63,20,3,1],
[63,20,3,2],
[63,20,4,0],
[63,20,4,1],
[63,20,4,2],
[63,20,4,3],
[63,20,5,0],
[63,20,5,1],
[63,20,5,2],
[63,20,5,4],
[63,20,6,0],
[63,20,6,1],
[63,20,6,2],
[63,20,6,4],
[63,20,7,0],
[63,20,7,1],
[63,20,7,2],
[63,20,7,3],
[63,20,7,4],
[63,20,7,5],
[63,20,7,6],
[63,20,8,0],
[63,20,8,1],
[63,20,8,2],
[63,20,8,4],
[63,20,8,7],
[63,20,11,0],
[63,20,11,1],
[63,20,11,2],
[63,20,11,3],
[63,20,11,4],
[63,20,11,5],
[63,20,11,6],
[63,20,11,7],
[63,20,11,8],
[63,20,12,0],
[63,20,12,1],
[63,20,12,2],
[63,20,12,3],
[63,20,12,4],
[63,20,12,5],
[63,20,12,6],
[63,20,12,7],
[63,20,12,8],
[63,20,13,0],
[63,20,13,1],
[63,20,13,2],
[63,20,13,3],
[63,20,13,4],
[63,20,13,5],
[63,20,13,6],
[63,20,13,7],
[63,20,13,8],
[63,20,13,11],
[63,20,13,12],
[63,20,14,0],
[63,20,14,1],
[63,20,14,2],
[63,20,14,3],
[63,20,14,4],
[63,20,14,5],
[63,20,14,6],
[63,20,14,7],
[63,20,14,8],
[63,20,14,11],
[63,20,14,12],
[63,20,16,0],
[63,20,16,1],
[63,20,16,2],
[63,20,16,3],
[63,20,16,4],
[63,20,16,5],
[63,20,16,6],
[63,20,16,7],
[63,20,16,8],
[63,20,16,11],
[63,20,16,12],
[63,20,16,13],
[63,20,16,14],
[63,20,17,0],
[63,20,17,1],
[63,20,17,2],
[63,20,17,3],
[63,20,17,4],
[63,20,17,5],
[63,20,17,6],
[63,20,17,7],
[63,20,17,8],
[63,20,17,11],
[63,20,17,12],
[63,20,17,13],
[63,20,17,14],
[63,20,17,16],
[63,20,19,0],
[63,20,19,1],
[63,20,19,2],
[63,20,19,3],
[63,20,19,4],
[63,20,19,5],
[63,20,19,6],
[63,20,19,7],
[63,20,19,8],
[63,20,19,11],
[63,20,19,12],
[63,20,19,16],
[63,20,19,17],
[63,21,4,0],
[63,21,4,1],
[63,21,4,2],
[63,21,7,0],
[63,21,7,1],
[63,21,7,2],
[63,21,7,4],
[63,21,10,0],
[63,21,10,1],
[63,21,10,2],
[63,21,10,4],
[63,21,10,7],
[63,21,11,0],
[63,21,11,1],
[63,21,11,2],
[63,21,11,4],
[63,21,11,7],
[63,21,11,10],
[63,21,12,0],
[63,21,12,1],
[63,21,12,2],
[63,21,12,4],
[63,21,12,7],
[63,21,12,10],
[63,21,13,0],
[63,21,13,1],
[63,21,13,2],
[63,21,13,4],
[63,21,13,7],
[63,21,13,10],
[63,21,13,11],
[63,21,13,12],
[63,21,14,0],
[63,21,14,1],
[63,21,14,2],
[63,21,14,4],
[63,21,14,7],
[63,21,14,10],
[63,21,14,11],
[63,21,14,12],
[63,21,16,0],
[63,21,16,1],
[63,21,16,2],
[63,21,16,4],
[63,21,16,7],
[63,21,16,10],
[63,21,16,11],
[63,21,16,12],
[63,21,16,13],
[63,21,16,14],
[63,21,17,0],
[63,21,17,1],
[63,21,17,2],
[63,21,17,4],
[63,21,17,7],
[63,21,17,10],
[63,21,17,11],
[63,21,17,12],
[63,21,17,13],
[63,21,17,14],
[63,21,17,16],
[63,21,19,0],
[63,21,19,1],
[63,21,19,2],
[63,21,19,4],
[63,21,19,7],
[63,21,19,10],
[63,21,19,11],
[63,21,19,12],
[63,21,19,16],
[63,21,19,17],
[63,21,20,0],
[63,21,20,1],
[63,21,20,2],
[63,21,20,4],
[63,21,20,7],
[63,21,20,11],
[63,21,20,12],
[63,21,20,13],
[63,21,20,14],
[63,21,20,16],
[63,21,20,17],
[63,21,20,19],
[63,22,3,0],
[63,22,3,1],
[63,22,3,2],
[63,22,4,0],
[63,22,4,1],
[63,22,4,2],
[63,22,4,3],
[63,22,5,0],
[63,22,5,1],
[63,22,5,2],
[63,22,5,4],
[63,22,6,0],
[63,22,6,1],
[63,22,6,2],
[63,22,6,4],
[63,22,7,0],
[63,22,7,1],
[63,22,7,2],
[63,22,7,3],
[63,22,7,4],
[63,22,7,5],
[63,22,7,6],
[63,22,8,0],
[63,22,8,1],
[63,22,8,2],
[63,22,8,4],
[63,22,8,7],
[63,22,11,0],
[63,22,11,1],
[63,22,11,2],
[63,22,11,3],
[63,22,11,4],
[63,22,11,5],
[63,22,11,6],
[63,22,11,7],
[63,22,11,8],
[63,22,12,0],
[63,22,12,1],
[63,22,12,2],
[63,22,12,3],
[63,22,12,4],
[63,22,12,5],
[63,22,12,6],
[63,22,12,7],
[63,22,12,8],
[63,22,13,0],
[63,22,13,1],
[63,22,13,2],
[63,22,13,3],
[63,22,13,4],
[63,22,13,5],
[63,22,13,6],
[63,22,13,7],
[63,22,13,8],
[63,22,13,11],
[63,22,13,12],
[63,22,14,0],
[63,22,14,1],
[63,22,14,2],
[63,22,14,3],
[63,22,14,4],
[63,22,14,5],
[63,22,14,6],
[63,22,14,7],
[63,22,14,8],
[63,22,14,11],
[63,22,14,12],
[63,22,16,0],
[63,22,16,1],
[63,22,16,2],
[63,22,16,3],
[63,22,16,4],
[63,22,16,5],
[63,22,16,6],
[63,22,16,7],
[63,22,16,8],
[63,22,16,11],
[63,22,16,12],
[63,22,16,13],
[63,22,16,14],
[63,22,17,0],
[63,22,17,1],
[63,22,17,2],
[63,22,17,3],
[63,22,17,4],
[63,22,17,5],
[63,22,17,6],
[63,22,17,7],
[63,22,17,8],
[63,22,17,11],
[63,22,17,12],
[63,22,17,13],
[63,22,17,14],
[63,22,17,16],
[63,22,19,0],
[63,22,19,1],
[63,22,19,2],
[63,22,19,3],
[63,22,19,4],
[63,22,19,5],
[63,22,19,6],
[63,22,19,7],
[63,22,19,8],
[63,22,19,11],
[63,22,19,12],
[63,22,19,16],
[63,22,19,17],
[63,22,21,0],
[63,22,21,1],
[63,22,21,2],
[63,22,21,4],
[63,22,21,7],
[63,22,21,11],
[63,22,21,12],
[63,22,21,13],
[63,22,21,14],
[63,22,21,16],
[63,22,21,17],
[63,22,21,19],
[63,23,3,0],
[63,23,3,1],
[63,23,3,2],
[63,23,4,0],
[63,23,4,1],
[63,23,4,2],
[63,23,4,3],
[63,23,5,0],
[63,23,5,1],
[63,23,5,2],
[63,23,5,4],
[63,23,6,0],
[63,23,6,1],
[63,23,6,2],
[63,23,6,4],
[63,23,7,0],
[63,23,7,1],
[63,23,7,2],
[63,23,7,3],
[63,23,7,4],
[63,23,7,5],
[63,23,7,6],
[63,23,8,0],
[63,23,8,1],
[63,23,8,2],
[63,23,8,4],
[63,23,8,7],
[63,23,10,0],
[63,23,10,1],
[63,23,10,2],
[63,23,10,3],
[63,23,10,4],
[63,23,10,5],
[63,23,10,6],
[63,23,10,7],
[63,23,10,8],
[63,23,11,0],
[63,23,11,1],
[63,23,11,2],
[63,23,11,3],
[63,23,11,4],
[63,23,11,5],
[63,23,11,6],
[63,23,11,7],
[63,23,11,8],
[63,23,11,10],
[63,23,12,0],
[63,23,12,1],
[63,23,12,2],
[63,23,12,3],
[63,23,12,4],
[63,23,12,5],
[63,23,12,6],
[63,23,12,7],
[63,23,12,8],
[63,23,12,10],
[63,23,13,0],
[63,23,13,1],
[63,23,13,2],
[63,23,13,3],
[63,23,13,4],
[63,23,13,5],
[63,23,13,6],
[63,23,13,7],
[63,23,13,8],
[63,23,13,10],
[63,23,13,11],
[63,23,13,12],
[63,23,14,0],
[63,23,14,1],
[63,23,14,2],
[63,23,14,3],
[63,23,14,4],
[63,23,14,5],
[63,23,14,6],
[63,23,14,7],
[63,23,14,8],
[63,23,14,10],
[63,23,14,11],
[63,23,14,12],
[63,23,16,0],
[63,23,16,1],
[63,23,16,2],
[63,23,16,3],
[63,23,16,4],
[63,23,16,5],
[63,23,16,6],
[63,23,16,7],
[63,23,16,8],
[63,23,16,10],
[63,23,16,11],
[63,23,16,12],
[63,23,16,13],
[63,23,16,14],
[63,23,17,0],
[63,23,17,1],
[63,23,17,2],
[63,23,17,3],
[63,23,17,4],
[63,23,17,5],
[63,23,17,6],
[63,23,17,7],
[63,23,17,8],
[63,23,17,10],
[63,23,17,11],
[63,23,17,12],
[63,23,17,13],
[63,23,17,14],
[63,23,17,16],
[63,23,19,0],
[63,23,19,1],
[63,23,19,2],
[63,23,19,3],
[63,23,19,4],
[63,23,19,5],
[63,23,19,6],
[63,23,19,7],
[63,23,19,8],
[63,23,19,10],
[63,23,19,11],
[63,23,19,12],
[63,23,19,16],
[63,23,19,17],
[63,23,20,0],
[63,23,20,1],
[63,23,20,2],
[63,23,20,3],
[63,23,20,4],
[63,23,20,5],
[63,23,20,6],
[63,23,20,7],
[63,23,20,8],
[63,23,20,11],
[63,23,20,12],
[63,23,20,13],
[63,23,20,14],
[63,23,20,16],
[63,23,20,17],
[63,23,20,19],
[63,23,21,0],
[63,23,21,1],
[63,23,21,2],
[63,23,21,4],
[63,23,21,7],
[63,23,21,10],
[63,23,21,11],
[63,23,21,12],
[63,23,21,13],
[63,23,21,14],
[63,23,21,16],
[63,23,21,17],
[63,23,21,19],
[63,23,21,20],
[63,23,22,0],
[63,23,22,1],
[63,23,22,2],
[63,23,22,3],
[63,23,22,4],
[63,23,22,5],
[63,23,22,6],
[63,23,22,7],
[63,23,22,8],
[63,23,22,11],
[63,23,22,12],
[63,23,22,13],
[63,23,22,14],
[63,23,22,16],
[63,23,22,17],
[63,23,22,19],
[63,23,22,21],
[63,24,3,0],
[63,24,3,1],
[63,24,3,2],
[63,24,4,0],
[63,24,4,1],
[63,24,4,2],
[63,24,4,3],
[63,24,5,0],
[63,24,5,1],
[63,24,5,2],
[63,24,5,4],
[63,24,6,0],
[63,24,6,1],
[63,24,6,2],
[63,24,6,4],
[63,24,7,0],
[63,24,7,1],
[63,24,7,2],
[63,24,7,3],
[63,24,7,4],
[63,24,7,5],
[63,24,7,6],
[63,24,8,0],
[63,24,8,1],
[63,24,8,2],
[63,24,8,4],
[63,24,8,7],
[63,24,10,0],
[63,24,10,1],
[63,24,10,2],
[63,24,10,3],
[63,24,10,4],
[63,24,10,5],
[63,24,10,6],
[63,24,10,7],
[63,24,10,8],
[63,24,11,0],
[63,24,11,1],
[63,24,11,2],
[63,24,11,3],
[63,24,11,4],
[63,24,11,5],
[63,24,11,6],
[63,24,11,7],
[63,24,11,8],
[63,24,11,10],
[63,24,12,0],
[63,24,12,1],
[63,24,12,2],
[63,24,12,3],
[63,24,12,4],
[63,24,12,5],
[63,24,12,6],
[63,24,12,7],
[63,24,12,8],
[63,24,12,10],
[63,24,13,0],
[63,24,13,1],
[63,24,13,2],
[63,24,13,3],
[63,24,13,4],
[63,24,13,5],
[63,24,13,6],
[63,24,13,7],
[63,24,13,8],
[63,24,13,10],
[63,24,13,11],
[63,24,13,12],
[63,24,14,0],
[63,24,14,1],
[63,24,14,2],
[63,24,14,3],
[63,24,14,4],
[63,24,14,5],
[63,24,14,6],
[63,24,14,7],
[63,24,14,8],
[63,24,14,10],
[63,24,14,11],
[63,24,14,12],
[63,24,17,0],
[63,24,17,1],
[63,24,17,2],
[63,24,17,3],
[63,24,17,4],
[63,24,17,5],
[63,24,17,6],
[63,24,17,7],
[63,24,17,8],
[63,24,17,10],
[63,24,17,11],
[63,24,17,12],
[63,24,17,13],
[63,24,17,14],
[63,24,19,0],
[63,24,19,1],
[63,24,19,2],
[63,24,19,3],
[63,24,19,4],
[63,24,19,5],
[63,24,19,6],
[63,24,19,7],
[63,24,19,8],
[63,24,19,10],
[63,24,19,11],
[63,24,19,12],
[63,24,19,17],
[63,24,20,0],
[63,24,20,1],
[63,24,20,2],
[63,24,20,3],
[63,24,20,4],
[63,24,20,5],
[63,24,20,6],
[63,24,20,7],
[63,24,20,8],
[63,24,20,11],
[63,24,20,12],
[63,24,20,13],
[63,24,20,14],
[63,24,20,17],
[63,24,20,19],
[63,24,21,0],
[63,24,21,1],
[63,24,21,2],
[63,24,21,4],
[63,24,21,7],
[63,24,21,10],
[63,24,21,11],
[63,24,21,12],
[63,24,21,13],
[63,24,21,14],
[63,24,21,17],
[63,24,21,19],
[63,24,21,20],
[63,24,22,0],
[63,24,22,1],
[63,24,22,2],
[63,24,22,3],
[63,24,22,4],
[63,24,22,5],
[63,24,22,6],
[63,24,22,7],
[63,24,22,8],
[63,24,22,11],
[63,24,22,12],
[63,24,22,13],
[63,24,22,14],
[63,24,22,17],
[63,24,22,19],
[63,24,22,21],
[63,24,23,0],
[63,24,23,1],
[63,24,23,2],
[63,24,23,3],
[63,24,23,4],
[63,24,23,5],
[63,24,23,6],
[63,24,23,7],
[63,24,23,8],
[63,24,23,10],
[63,24,23,11],
[63,24,23,12],
[63,24,23,13],
[63,24,23,14],
[63,24,23,17],
[63,24,23,19],
[63,24,23,20],
[63,24,23,21],
[63,24,23,22],
[63,25,3,0],
[63,25,3,1],
[63,25,3,2],
[63,25,4,0],
[63,25,4,1],
[63,25,4,2],
[63,25,4,3],
[63,25,5,0],
[63,25,5,1],
[63,25,5,2],
[63,25,5,4],
[63,25,6,0],
[63,25,6,1],
[63,25,6,2],
[63,25,6,4],
[63,25,8,0],
[63,25,8,1],
[63,25,8,2],
[63,25,8,4],
[63,25,10,0],
[63,25,10,1],
[63,25,10,2],
[63,25,10,3],
[63,25,10,4],
[63,25,10,5],
[63,25,10,6],
[63,25,10,8],
[63,25,11,0],
[63,25,11,1],
[63,25,11,2],
[63,25,11,3],
[63,25,11,4],
[63,25,11,5],
[63,25,11,6],
[63,25,11,8],
[63,25,11,10],
[63,25,12,0],
[63,25,12,1],
[63,25,12,2],
[63,25,12,3],
[63,25,12,4],
[63,25,12,5],
[63,25,12,6],
[63,25,12,8],
[63,25,12,10],
[63,25,13,0],
[63,25,13,1],
[63,25,13,2],
[63,25,13,3],
[63,25,13,4],
[63,25,13,5],
[63,25,13,6],
[63,25,13,8],
[63,25,13,10],
[63,25,13,11],
[63,25,13,12],
[63,25,14,0],
[63,25,14,1],
[63,25,14,2],
[63,25,14,3],
[63,25,14,4],
[63,25,14,5],
[63,25,14,6],
[63,25,14,8],
[63,25,14,10],
[63,25,14,11],
[63,25,14,12],
[63,25,16,0],
[63,25,16,1],
[63,25,16,2],
[63,25,16,3],
[63,25,16,4],
[63,25,16,5],
[63,25,16,6],
[63,25,16,8],
[63,25,16,10],
[63,25,16,11],
[63,25,16,12],
[63,25,16,13],
[63,25,16,14],
[63,25,17,0],
[63,25,17,1],
[63,25,17,2],
[63,25,17,3],
[63,25,17,4],
[63,25,17,5],
[63,25,17,6],
[63,25,17,8],
[63,25,17,10],
[63,25,17,11],
[63,25,17,12],
[63,25,17,13],
[63,25,17,14],
[63,25,17,16],
[63,25,19,0],
[63,25,19,1],
[63,25,19,2],
[63,25,19,3],
[63,25,19,4],
[63,25,19,5],
[63,25,19,6],
[63,25,19,8],
[63,25,19,10],
[63,25,19,11],
[63,25,19,12],
[63,25,19,16],
[63,25,19,17],
[63,25,20,0],
[63,25,20,1],
[63,25,20,2],
[63,25,20,3],
[63,25,20,4],
[63,25,20,5],
[63,25,20,6],
[63,25,20,8],
[63,25,20,11],
[63,25,20,12],
[63,25,20,13],
[63,25,20,14],
[63,25,20,16],
[63,25,20,17],
[63,25,20,19],
[63,25,21,0],
[63,25,21,1],
[63,25,21,2],
[63,25,21,4],
[63,25,21,10],
[63,25,21,11],
[63,25,21,12],
[63,25,21,13],
[63,25,21,14],
[63,25,21,16],
[63,25,21,17],
[63,25,21,19],
[63,25,21,20],
[63,25,22,0],
[63,25,22,1],
[63,25,22,2],
[63,25,22,3],
[63,25,22,4],
[63,25,22,5],
[63,25,22,6],
[63,25,22,8],
[63,25,22,11],
[63,25,22,12],
[63,25,22,13],
[63,25,22,14],
[63,25,22,16],
[63,25,22,17],
[63,25,22,19],
[63,25,22,21],
[63,25,23,0],
[63,25,23,1],
[63,25,23,2],
[63,25,23,3],
[63,25,23,4],
[63,25,23,5],
[63,25,23,6],
[63,25,23,8],
[63,25,23,10],
[63,25,23,11],
[63,25,23,12],
[63,25,23,13],
[63,25,23,14],
[63,25,23,16],
[63,25,23,17],
[63,25,23,19],
[63,25,23,20],
[63,25,23,21],
[63,25,23,22],
[63,25,24,0],
[63,25,24,1],
[63,25,24,2],
[63,25,24,3],
[63,25,24,4],
[63,25,24,5],
[63,25,24,6],
[63,25,24,8],
[63,25,24,10],
[63,25,24,11],
[63,25,24,12],
[63,25,24,13],
[63,25,24,14],
[63,25,24,17],
[63,25,24,19],
[63,25,24,20],
[63,25,24,21],
[63,25,24,22],
[63,25,24,23],
[63,27,3,0],
[63,27,3,1],
[63,27,3,2],
[63,27,4,0],
[63,27,4,1],
[63,27,4,2],
[63,27,4,3],
[63,27,5,0],
[63,27,5,1],
[63,27,5,2],
[63,27,5,4],
[63,27,6,0],
[63,27,6,1],
[63,27,6,2],
[63,27,6,4],
[63,27,7,0],
[63,27,7,1],
[63,27,7,2],
[63,27,7,3],
[63,27,7,4],
[63,27,7,5],
[63,27,7,6],
[63,27,8,0],
[63,27,8,1],
[63,27,8,2],
[63,27,8,4],
[63,27,8,7],
[63,27,11,0],
[63,27,11,1],
[63,27,11,2],
[63,27,11,3],
[63,27,11,4],
[63,27,11,5],
[63,27,11,6],
[63,27,11,7],
[63,27,11,8],
[63,27,12,0],
[63,27,12,1],
[63,27,12,2],
[63,27,12,3],
[63,27,12,4],
[63,27,12,5],
[63,27,12,6],
[63,27,12,7],
[63,27,12,8],
[63,27,13,0],
[63,27,13,1],
[63,27,13,2],
[63,27,13,3],
[63,27,13,4],
[63,27,13,5],
[63,27,13,6],
[63,27,13,7],
[63,27,13,8],
[63,27,13,11],
[63,27,13,12],
[63,27,14,0],
[63,27,14,1],
[63,27,14,2],
[63,27,14,3],
[63,27,14,4],
[63,27,14,5],
[63,27,14,6],
[63,27,14,7],
[63,27,14,8],
[63,27,14,11],
[63,27,14,12],
[63,27,16,0],
[63,27,16,1],
[63,27,16,2],
[63,27,16,3],
[63,27,16,4],
[63,27,16,5],
[63,27,16,6],
[63,27,16,7],
[63,27,16,8],
[63,27,16,11],
[63,27,16,12],
[63,27,16,13],
[63,27,16,14],
[63,27,17,0],
[63,27,17,1],
[63,27,17,2],
[63,27,17,3],
[63,27,17,4],
[63,27,17,5],
[63,27,17,6],
[63,27,17,7],
[63,27,17,8],
[63,27,17,11],
[63,27,17,12],
[63,27,17,13],
[63,27,17,14],
[63,27,17,16],
[63,27,19,0],
[63,27,19,1],
[63,27,19,2],
[63,27,19,3],
[63,27,19,4],
[63,27,19,5],
[63,27,19,6],
[63,27,19,7],
[63,27,19,8],
[63,27,19,11],
[63,27,19,12],
[63,27,19,16],
[63,27,19,17],
[63,27,21,0],
[63,27,21,1],
[63,27,21,2],
[63,27,21,4],
[63,27,21,7],
[63,27,21,11],
[63,27,21,12],
[63,27,21,13],
[63,27,21,14],
[63,27,21,16],
[63,27,21,17],
[63,27,21,19],
[63,27,23,0],
[63,27,23,1],
[63,27,23,2],
[63,27,23,3],
[63,27,23,4],
[63,27,23,5],
[63,27,23,6],
[63,27,23,7],
[63,27,23,8],
[63,27,23,11],
[63,27,23,12],
[63,27,23,13],
[63,27,23,14],
[63,27,23,16],
[63,27,23,17],
[63,27,23,19],
[63,27,23,21],
[63,27,24,0],
[63,27,24,1],
[63,27,24,2],
[63,27,24,3],
[63,27,24,4],
[63,27,24,5],
[63,27,24,6],
[63,27,24,7],
[63,27,24,8],
[63,27,24,11],
[63,27,24,12],
[63,27,24,13],
[63,27,24,14],
[63,27,24,17],
[63,27,24,19],
[63,27,24,21],
[63,27,24,23],
[63,27,25,0],
[63,27,25,1],
[63,27,25,2],
[63,27,25,3],
[63,27,25,4],
[63,27,25,5],
[63,27,25,6],
[63,27,25,8],
[63,27,25,11],
[63,27,25,12],
[63,27,25,13],
[63,27,25,14],
[63,27,25,16],
[63,27,25,17],
[63,27,25,19],
[63,27,25,21],
[63,28,3,0],
[63,28,3,1],
[63,28,3,2],
[63,28,4,0],
[63,28,4,1],
[63,28,4,2],
[63,28,4,3],
[63,28,5,0],
[63,28,5,1],
[63,28,5,2],
[63,28,5,4],
[63,28,6,0],
[63,28,6,1],
[63,28,6,2],
[63,28,6,4],
[63,28,8,0],
[63,28,8,1],
[63,28,8,2],
[63,28,8,4],
[63,28,10,0],
[63,28,10,1],
[63,28,10,2],
[63,28,10,3],
[63,28,10,4],
[63,28,10,5],
[63,28,10,6],
[63,28,10,8],
[63,28,11,0],
[63,28,11,1],
[63,28,11,2],
[63,28,11,3],
[63,28,11,4],
[63,28,11,5],
[63,28,11,6],
[63,28,11,8],
[63,28,11,10],
[63,28,12,0],
[63,28,12,1],
[63,28,12,2],
[63,28,12,3],
[63,28,12,4],
[63,28,12,5],
[63,28,12,6],
[63,28,12,8],
[63,28,12,10],
[63,28,13,0],
[63,28,13,1],
[63,28,13,2],
[63,28,13,3],
[63,28,13,4],
[63,28,13,5],
[63,28,13,6],
[63,28,13,8],
[63,28,13,10],
[63,28,13,11],
[63,28,13,12],
[63,28,14,0],
[63,28,14,1],
[63,28,14,2],
[63,28,14,3],
[63,28,14,4],
[63,28,14,5],
[63,28,14,6],
[63,28,14,8],
[63,28,14,10],
[63,28,14,11],
[63,28,14,12],
[63,28,16,0],
[63,28,16,1],
[63,28,16,2],
[63,28,16,3],
[63,28,16,4],
[63,28,16,5],
[63,28,16,6],
[63,28,16,8],
[63,28,16,10],
[63,28,16,11],
[63,28,16,12],
[63,28,16,13],
[63,28,16,14],
[63,28,17,0],
[63,28,17,1],
[63,28,17,2],
[63,28,17,3],
[63,28,17,4],
[63,28,17,5],
[63,28,17,6],
[63,28,17,8],
[63,28,17,10],
[63,28,17,11],
[63,28,17,12],
[63,28,17,13],
[63,28,17,14],
[63,28,17,16],
[63,28,19,0],
[63,28,19,1],
[63,28,19,2],
[63,28,19,3],
[63,28,19,4],
[63,28,19,5],
[63,28,19,6],
[63,28,19,8],
[63,28,19,10],
[63,28,19,11],
[63,28,19,12],
[63,28,19,16],
[63,28,19,17],
[63,28,20,0],
[63,28,20,1],
[63,28,20,2],
[63,28,20,3],
[63,28,20,4],
[63,28,20,5],
[63,28,20,6],
[63,28,20,8],
[63,28,20,11],
[63,28,20,12],
[63,28,20,13],
[63,28,20,14],
[63,28,20,16],
[63,28,20,17],
[63,28,20,19],
[63,28,21,0],
[63,28,21,1],
[63,28,21,2],
[63,28,21,4],
[63,28,21,10],
[63,28,21,11],
[63,28,21,12],
[63,28,21,13],
[63,28,21,14],
[63,28,21,16],
[63,28,21,17],
[63,28,21,19],
[63,28,21,20],
[63,28,22,0],
[63,28,22,1],
[63,28,22,2],
[63,28,22,3],
[63,28,22,4],
[63,28,22,5],
[63,28,22,6],
[63,28,22,8],
[63,28,22,11],
[63,28,22,12],
[63,28,22,13],
[63,28,22,14],
[63,28,22,16],
[63,28,22,17],
[63,28,22,19],
[63,28,22,21],
[63,28,23,0],
[63,28,23,1],
[63,28,23,2],
[63,28,23,3],
[63,28,23,4],
[63,28,23,5],
[63,28,23,6],
[63,28,23,8],
[63,28,23,10],
[63,28,23,11],
[63,28,23,12],
[63,28,23,13],
[63,28,23,14],
[63,28,23,16],
[63,28,23,17],
[63,28,23,19],
[63,28,23,20],
[63,28,23,21],
[63,28,23,22],
[63,28,24,0],
[63,28,24,1],
[63,28,24,2],
[63,28,24,3],
[63,28,24,4],
[63,28,24,5],
[63,28,24,6],
[63,28,24,8],
[63,28,24,10],
[63,28,24,11],
[63,28,24,12],
[63,28,24,13],
[63,28,24,14],
[63,28,24,17],
[63,28,24,19],
[63,28,24,20],
[63,28,24,21],
[63,28,24,22],
[63,28,24,23],
[63,28,27,0],
[63,28,27,1],
[63,28,27,2],
[63,28,27,3],
[63,28,27,4],
[63,28,27,5],
[63,28,27,6],
[63,28,27,8],
[63,28,27,11],
[63,28,27,12],
[63,28,27,13],
[63,28,27,14],
[63,28,27,16],
[63,28,27,17],
[63,28,27,19],
[63,28,27,21],
[63,28,27,23],
[63,28,27,24],
[63,29,3,0],
[63,29,3,1],
[63,29,3,2],
[63,29,4,0],
[63,29,4,1],
[63,29,4,2],
[63,29,4,3],
[63,29,5,0],
[63,29,5,1],
[63,29,5,2],
[63,29,5,4],
[63,29,6,0],
[63,29,6,1],
[63,29,6,2],
[63,29,6,4],
[63,29,7,0],
[63,29,7,1],
[63,29,7,2],
[63,29,7,3],
[63,29,7,4],
[63,29,7,5],
[63,29,7,6],
[63,29,8,0],
[63,29,8,1],
[63,29,8,2],
[63,29,8,4],
[63,29,8,7],
[63,29,10,0],
[63,29,10,1],
[63,29,10,2],
[63,29,10,3],
[63,29,10,4],
[63,29,10,5],
[63,29,10,6],
[63,29,10,7],
[63,29,10,8],
[63,29,13,0],
[63,29,13,1],
[63,29,13,2],
[63,29,13,3],
[63,29,13,4],
[63,29,13,5],
[63,29,13,6],
[63,29,13,7],
[63,29,13,8],
[63,29,13,10],
[63,29,14,0],
[63,29,14,1],
[63,29,14,2],
[63,29,14,3],
[63,29,14,4],
[63,29,14,5],
[63,29,14,6],
[63,29,14,7],
[63,29,14,8],
[63,29,14,10],
[63,29,16,0],
[63,29,16,1],
[63,29,16,2],
[63,29,16,3],
[63,29,16,4],
[63,29,16,5],
[63,29,16,6],
[63,29,16,7],
[63,29,16,8],
[63,29,16,10],
[63,29,16,13],
[63,29,16,14],
[63,29,17,0],
[63,29,17,1],
[63,29,17,2],
[63,29,17,3],
[63,29,17,4],
[63,29,17,5],
[63,29,17,6],
[63,29,17,7],
[63,29,17,8],
[63,29,17,10],
[63,29,17,13],
[63,29,17,14],
[63,29,17,16],
[63,29,19,0],
[63,29,19,1],
[63,29,19,2],
[63,29,19,3],
[63,29,19,4],
[63,29,19,5],
[63,29,19,6],
[63,29,19,7],
[63,29,19,8],
[63,29,19,10],
[63,29,19,16],
[63,29,19,17],
[63,29,20,0],
[63,29,20,1],
[63,29,20,2],
[63,29,20,3],
[63,29,20,4],
[63,29,20,5],
[63,29,20,6],
[63,29,20,7],
[63,29,20,8],
[63,29,20,13],
[63,29,20,14],
[63,29,20,16],
[63,29,20,17],
[63,29,20,19],
[63,29,21,0],
[63,29,21,1],
[63,29,21,2],
[63,29,21,4],
[63,29,21,7],
[63,29,21,10],
[63,29,21,13],
[63,29,21,14],
[63,29,21,16],
[63,29,21,17],
[63,29,21,19],
[63,29,21,20],
[63,29,22,0],
[63,29,22,1],
[63,29,22,2],
[63,29,22,3],
[63,29,22,4],
[63,29,22,5],
[63,29,22,6],
[63,29,22,7],
[63,29,22,8],
[63,29,22,13],
[63,29,22,14],
[63,29,22,16],
[63,29,22,17],
[63,29,22,19],
[63,29,22,21],
[63,29,23,0],
[63,29,23,1],
[63,29,23,2],
[63,29,23,3],
[63,29,23,4],
[63,29,23,5],
[63,29,23,6],
[63,29,23,7],
[63,29,23,8],
[63,29,23,10],
[63,29,23,13],
[63,29,23,14],
[63,29,23,16],
[63,29,23,17],
[63,29,23,19],
[63,29,23,20],
[63,29,23,21],
[63,29,23,22],
[63,29,24,0],
[63,29,24,1],
[63,29,24,2],
[63,29,24,3],
[63,29,24,4],
[63,29,24,5],
[63,29,24,6],
[63,29,24,7],
[63,29,24,8],
[63,29,24,10],
[63,29,24,13],
[63,29,24,14],
[63,29,24,17],
[63,29,24,19],
[63,29,24,20],
[63,29,24,21],
[63,29,24,22],
[63,29,25,0],
[63,29,25,1],
[63,29,25,2],
[63,29,25,3],
[63,29,25,4],
[63,29,25,5],
[63,29,25,6],
[63,29,25,8],
[63,29,25,10],
[63,29,25,13],
[63,29,25,14],
[63,29,25,16],
[63,29,25,17],
[63,29,25,19],
[63,29,25,20],
[63,29,25,22],
[63,29,25,23],
[63,29,25,24],
[63,29,27,0],
[63,29,27,1],
[63,29,27,2],
[63,29,27,3],
[63,29,27,4],
[63,29,27,5],
[63,29,27,6],
[63,29,27,7],
[63,29,27,8],
[63,29,27,13],
[63,29,27,14],
[63,29,27,16],
[63,29,27,17],
[63,29,27,21],
[63,29,27,23],
[63,29,27,24],
[63,29,27,25],
[63,29,28,0],
[63,29,28,1],
[63,29,28,2],
[63,29,28,3],
[63,29,28,4],
[63,29,28,5],
[63,29,28,6],
[63,29,28,8],
[63,29,28,10],
[63,29,28,13],
[63,29,28,14],
[63,29,28,16],
[63,29,28,17],
[63,29,28,19],
[63,29,28,20],
[63,29,28,21],
[63,29,28,22],
[63,29,28,23],
[63,29,28,24],
[63,29,28,27],
[63,30,3,0],
[63,30,3,1],
[63,30,3,2],
[63,30,4,0],
[63,30,4,1],
[63,30,4,2],
[63,30,4,3],
[63,30,5,0],
[63,30,5,1],
[63,30,5,2],
[63,30,5,4],
[63,30,6,0],
[63,30,6,1],
[63,30,6,2],
[63,30,6,4],
[63,30,7,0],
[63,30,7,1],
[63,30,7,2],
[63,30,7,3],
[63,30,7,4],
[63,30,7,5],
[63,30,7,6],
[63,30,8,0],
[63,30,8,1],
[63,30,8,2],
[63,30,8,4],
[63,30,8,7],
[63,30,10,0],
[63,30,10,1],
[63,30,10,2],
[63,30,10,3],
[63,30,10,4],
[63,30,10,5],
[63,30,10,6],
[63,30,10,7],
[63,30,10,8],
[63,30,11,0],
[63,30,11,1],
[63,30,11,2],
[63,30,11,3],
[63,30,11,4],
[63,30,11,5],
[63,30,11,6],
[63,30,11,7],
[63,30,11,8],
[63,30,11,10],
[63,30,12,0],
[63,30,12,1],
[63,30,12,2],
[63,30,12,3],
[63,30,12,4],
[63,30,12,5],
[63,30,12,6],
[63,30,12,7],
[63,30,12,8],
[63,30,12,10],
[63,30,13,0],
[63,30,13,1],
[63,30,13,2],
[63,30,13,3],
[63,30,13,4],
[63,30,13,5],
[63,30,13,6],
[63,30,13,7],
[63,30,13,8],
[63,30,13,10],
[63,30,13,11],
[63,30,13,12],
[63,30,14,0],
[63,30,14,1],
[63,30,14,2],
[63,30,14,3],
[63,30,14,4],
[63,30,14,5],
[63,30,14,6],
[63,30,14,7],
[63,30,14,8],
[63,30,14,10],
[63,30,14,11],
[63,30,14,12],
[63,30,16,0],
[63,30,16,1],
[63,30,16,2],
[63,30,16,3],
[63,30,16,4],
[63,30,16,5],
[63,30,16,6],
[63,30,16,7],
[63,30,16,8],
[63,30,16,10],
[63,30,16,11],
[63,30,16,12],
[63,30,16,13],
[63,30,16,14],
[63,30,17,0],
[63,30,17,1],
[63,30,17,2],
[63,30,17,3],
[63,30,17,4],
[63,30,17,5],
[63,30,17,6],
[63,30,17,7],
[63,30,17,8],
[63,30,17,10],
[63,30,17,11],
[63,30,17,12],
[63,30,17,13],
[63,30,17,14],
[63,30,17,16],
[63,30,19,0],
[63,30,19,1],
[63,30,19,2],
[63,30,19,3],
[63,30,19,4],
[63,30,19,5],
[63,30,19,6],
[63,30,19,7],
[63,30,19,8],
[63,30,19,10],
[63,30,19,11],
[63,30,19,12],
[63,30,19,16],
[63,30,19,17],
[63,30,20,0],
[63,30,20,1],
[63,30,20,2],
[63,30,20,3],
[63,30,20,4],
[63,30,20,5],
[63,30,20,6],
[63,30,20,7],
[63,30,20,8],
[63,30,20,11],
[63,30,20,12],
[63,30,20,13],
[63,30,20,14],
[63,30,20,16],
[63,30,20,17],
[63,30,20,19],
[63,30,21,0],
[63,30,21,1],
[63,30,21,2],
[63,30,21,4],
[63,30,21,7],
[63,30,21,10],
[63,30,21,11],
[63,30,21,12],
[63,30,21,13],
[63,30,21,14],
[63,30,21,16],
[63,30,21,17],
[63,30,21,19],
[63,30,21,20],
[63,30,22,0],
[63,30,22,1],
[63,30,22,2],
[63,30,22,3],
[63,30,22,4],
[63,30,22,5],
[63,30,22,6],
[63,30,22,7],
[63,30,22,8],
[63,30,22,11],
[63,30,22,12],
[63,30,22,13],
[63,30,22,14],
[63,30,22,16],
[63,30,22,17],
[63,30,22,19],
[63,30,22,21],
[63,30,24,0],
[63,30,24,1],
[63,30,24,2],
[63,30,24,3],
[63,30,24,4],
[63,30,24,5],
[63,30,24,6],
[63,30,24,7],
[63,30,24,8],
[63,30,24,10],
[63,30,24,11],
[63,30,24,12],
[63,30,24,13],
[63,30,24,14],
[63,30,24,17],
[63,30,24,19],
[63,30,24,20],
[63,30,24,21],
[63,30,25,0],
[63,30,25,1],
[63,30,25,2],
[63,30,25,3],
[63,30,25,4],
[63,30,25,5],
[63,30,25,6],
[63,30,25,8],
[63,30,25,10],
[63,30,25,11],
[63,30,25,12],
[63,30,25,13],
[63,30,25,14],
[63,30,25,16],
[63,30,25,17],
[63,30,25,19],
[63,30,25,21],
[63,30,25,22],
[63,30,25,24],
[63,30,27,0],
[63,30,27,1],
[63,30,27,2],
[63,30,27,3],
[63,30,27,4],
[63,30,27,5],
[63,30,27,6],
[63,30,27,7],
[63,30,27,8],
[63,30,27,11],
[63,30,27,12],
[63,30,27,13],
[63,30,27,14],
[63,30,27,16],
[63,30,27,17],
[63,30,27,19],
[63,30,27,21],
[63,30,27,24],
[63,30,27,25],
[63,30,28,0],
[63,30,28,1],
[63,30,28,2],
[63,30,28,3],
[63,30,28,4],
[63,30,28,5],
[63,30,28,6],
[63,30,28,8],
[63,30,28,10],
[63,30,28,11],
[63,30,28,12],
[63,30,28,13],
[63,30,28,14],
[63,30,28,19],
[63,30,28,20],
[63,30,28,21],
[63,30,28,22],
[63,30,28,24],
[63,30,28,27],
[63,30,29,0],
[63,30,29,1],
[63,30,29,2],
[63,30,29,3],
[63,30,29,4],
[63,30,29,5],
[63,30,29,6],
[63,30,29,7],
[63,30,29,8],
[63,30,29,10],
[63,30,29,13],
[63,30,29,14],
[63,30,29,16],
[63,30,29,17],
[63,30,29,19],
[63,30,29,20],
[63,30,29,21],
[63,30,29,22],
[63,30,29,24],
[63,30,29,25],
[63,30,29,27],
[63,30,29,28],
[63,31,3,0],
[63,31,3,1],
[63,31,3,2],
[63,31,4,0],
[63,31,4,1],
[63,31,4,2],
[63,31,4,3],
[63,31,5,0],
[63,31,5,1],
[63,31,5,2],
[63,31,5,4],
[63,31,6,0],
[63,31,6,1],
[63,31,6,2],
[63,31,6,4],
[63,31,7,0],
[63,31,7,1],
[63,31,7,2],
[63,31,7,3],
[63,31,7,4],
[63,31,7,5],
[63,31,7,6],
[63,31,8,0],
[63,31,8,1],
[63,31,8,2],
[63,31,8,4],
[63,31,8,7],
[63,31,10,0],
[63,31,10,1],
[63,31,10,2],
[63,31,10,3],
[63,31,10,4],
[63,31,10,5],
[63,31,10,6],
[63,31,10,7],
[63,31,10,8],
[63,31,11,0],
[63,31,11,1],
[63,31,11,2],
[63,31,11,3],
[63,31,11,4],
[63,31,11,5],
[63,31,11,6],
[63,31,11,7],
[63,31,11,8],
[63,31,11,10],
[63,31,12,0],
[63,31,12,1],
[63,31,12,2],
[63,31,12,3],
[63,31,12,4],
[63,31,12,5],
[63,31,12,6],
[63,31,12,7],
[63,31,12,8],
[63,31,12,10],
[63,31,13,0],
[63,31,13,1],
[63,31,13,2],
[63,31,13,3],
[63,31,13,4],
[63,31,13,5],
[63,31,13,6],
[63,31,13,7],
[63,31,13,8],
[63,31,13,10],
[63,31,13,11],
[63,31,13,12],
[63,31,14,0],
[63,31,14,1],
[63,31,14,2],
[63,31,14,3],
[63,31,14,4],
[63,31,14,5],
[63,31,14,6],
[63,31,14,7],
[63,31,14,8],
[63,31,14,10],
[63,31,14,11],
[63,31,14,12],
[63,31,16,0],
[63,31,16,1],
[63,31,16,2],
[63,31,16,3],
[63,31,16,4],
[63,31,16,5],
[63,31,16,6],
[63,31,16,7],
[63,31,16,8],
[63,31,16,10],
[63,31,16,11],
[63,31,16,12],
[63,31,16,13],
[63,31,16,14],
[63,31,17,0],
[63,31,17,1],
[63,31,17,2],
[63,31,17,3],
[63,31,17,4],
[63,31,17,5],
[63,31,17,6],
[63,31,17,7],
[63,31,17,8],
[63,31,17,10],
[63,31,17,11],
[63,31,17,12],
[63,31,17,13],
[63,31,17,14],
[63,31,17,16],
[63,31,19,0],
[63,31,19,1],
[63,31,19,2],
[63,31,19,3],
[63,31,19,4],
[63,31,19,5],
[63,31,19,6],
[63,31,19,7],
[63,31,19,8],
[63,31,19,10],
[63,31,19,11],
[63,31,19,12],
[63,31,19,16],
[63,31,19,17],
[63,31,20,0],
[63,31,20,1],
[63,31,20,2],
[63,31,20,3],
[63,31,20,4],
[63,31,20,5],
[63,31,20,6],
[63,31,20,7],
[63,31,20,8],
[63,31,20,11],
[63,31,20,12],
[63,31,20,13],
[63,31,20,14],
[63,31,20,16],
[63,31,20,17],
[63,31,20,19],
[63,31,21,0],
[63,31,21,1],
[63,31,21,2],
[63,31,21,4],
[63,31,21,7],
[63,31,21,10],
[63,31,21,11],
[63,31,21,12],
[63,31,21,13],
[63,31,21,14],
[63,31,21,16],
[63,31,21,17],
[63,31,21,19],
[63,31,21,20],
[63,31,22,0],
[63,31,22,1],
[63,31,22,2],
[63,31,22,3],
[63,31,22,4],
[63,31,22,5],
[63,31,22,6],
[63,31,22,7],
[63,31,22,8],
[63,31,22,11],
[63,31,22,12],
[63,31,22,13],
[63,31,22,14],
[63,31,22,16],
[63,31,22,17],
[63,31,22,19],
[63,31,22,21],
[63,31,24,0],
[63,31,24,1],
[63,31,24,2],
[63,31,24,3],
[63,31,24,4],
[63,31,24,5],
[63,31,24,6],
[63,31,24,7],
[63,31,24,8],
[63,31,24,10],
[63,31,24,11],
[63,31,24,12],
[63,31,24,13],
[63,31,24,14],
[63,31,24,17],
[63,31,24,19],
[63,31,24,20],
[63,31,24,22],
[63,31,25,0],
[63,31,25,1],
[63,31,25,2],
[63,31,25,3],
[63,31,25,4],
[63,31,25,5],
[63,31,25,6],
[63,31,25,8],
[63,31,25,10],
[63,31,25,11],
[63,31,25,12],
[63,31,25,13],
[63,31,25,14],
[63,31,25,16],
[63,31,25,17],
[63,31,25,20],
[63,31,25,21],
[63,31,25,22],
[63,31,25,24],
[63,31,27,0],
[63,31,27,1],
[63,31,27,2],
[63,31,27,3],
[63,31,27,4],
[63,31,27,5],
[63,31,27,6],
[63,31,27,7],
[63,31,27,8],
[63,31,27,11],
[63,31,27,12],
[63,31,27,13],
[63,31,27,14],
[63,31,27,19],
[63,31,27,21],
[63,31,27,24],
[63,31,27,25],
[63,31,28,0],
[63,31,28,1],
[63,31,28,2],
[63,31,28,3],
[63,31,28,4],
[63,31,28,5],
[63,31,28,6],
[63,31,28,8],
[63,31,28,10],
[63,31,28,11],
[63,31,28,12],
[63,31,28,13],
[63,31,28,14],
[63,31,28,16],
[63,31,28,17],
[63,31,28,19],
[63,31,28,20],
[63,31,28,21],
[63,31,28,22],
[63,31,28,24],
[63,31,28,27],
[63,31,29,0],
[63,31,29,1],
[63,31,29,2],
[63,31,29,3],
[63,31,29,4],
[63,31,29,5],
[63,31,29,6],
[63,31,29,7],
[63,31,29,8],
[63,31,29,10],
[63,31,29,13],
[63,31,29,16],
[63,31,29,17],
[63,31,29,19],
[63,31,29,20],
[63,31,29,21],
[63,31,29,22],
[63,31,29,24],
[63,31,29,25],
[63,31,29,27],
[63,32,3,0],
[63,32,3,1],
[63,32,3,2],
[63,32,4,0],
[63,32,4,1],
[63,32,4,2],
[63,32,4,3],
[63,32,5,0],
[63,32,5,1],
[63,32,5,2],
[63,32,5,4],
[63,32,6,0],
[63,32,6,1],
[63,32,6,2],
[63,32,6,4],
[63,32,7,0],
[63,32,7,1],
[63,32,7,2],
[63,32,7,3],
[63,32,7,4],
[63,32,7,5],
[63,32,7,6],
[63,32,8,0],
[63,32,8,1],
[63,32,8,2],
[63,32,8,4],
[63,32,8,7],
[63,32,10,0],
[63,32,10,1],
[63,32,10,2],
[63,32,10,3],
[63,32,10,4],
[63,32,10,5],
[63,32,10,6],
[63,32,10,7],
[63,32,10,8],
[63,32,13,0],
[63,32,13,1],
[63,32,13,2],
[63,32,13,3],
[63,32,13,4],
[63,32,13,5],
[63,32,13,6],
[63,32,13,7],
[63,32,13,8],
[63,32,13,10],
[63,32,14,0],
[63,32,14,1],
[63,32,14,2],
[63,32,14,3],
[63,32,14,4],
[63,32,14,5],
[63,32,14,6],
[63,32,14,7],
[63,32,14,8],
[63,32,14,10],
[63,32,16,0],
[63,32,16,1],
[63,32,16,2],
[63,32,16,3],
[63,32,16,4],
[63,32,16,5],
[63,32,16,6],
[63,32,16,7],
[63,32,16,8],
[63,32,16,10],
[63,32,16,13],
[63,32,16,14],
[63,32,17,0],
[63,32,17,1],
[63,32,17,2],
[63,32,17,3],
[63,32,17,4],
[63,32,17,5],
[63,32,17,6],
[63,32,17,7],
[63,32,17,8],
[63,32,17,10],
[63,32,17,13],
[63,32,17,14],
[63,32,17,16],
[63,32,19,0],
[63,32,19,1],
[63,32,19,2],
[63,32,19,3],
[63,32,19,4],
[63,32,19,5],
[63,32,19,6],
[63,32,19,7],
[63,32,19,8],
[63,32,19,10],
[63,32,19,16],
[63,32,19,17],
[63,32,20,0],
[63,32,20,1],
[63,32,20,2],
[63,32,20,3],
[63,32,20,4],
[63,32,20,5],
[63,32,20,6],
[63,32,20,7],
[63,32,20,8],
[63,32,20,13],
[63,32,20,14],
[63,32,20,16],
[63,32,20,17],
[63,32,20,19],
[63,32,21,0],
[63,32,21,1],
[63,32,21,2],
[63,32,21,4],
[63,32,21,7],
[63,32,21,10],
[63,32,21,13],
[63,32,21,14],
[63,32,21,16],
[63,32,21,17],
[63,32,21,19],
[63,32,21,20],
[63,32,22,0],
[63,32,22,1],
[63,32,22,2],
[63,32,22,3],
[63,32,22,4],
[63,32,22,5],
[63,32,22,6],
[63,32,22,7],
[63,32,22,8],
[63,32,22,13],
[63,32,22,14],
[63,32,22,16],
[63,32,22,17],
[63,32,22,19],
[63,32,23,0],
[63,32,23,1],
[63,32,23,2],
[63,32,23,3],
[63,32,23,4],
[63,32,23,5],
[63,32,23,6],
[63,32,23,7],
[63,32,23,8],
[63,32,23,10],
[63,32,23,13],
[63,32,23,14],
[63,32,23,16],
[63,32,23,17],
[63,32,23,19],
[63,32,23,21],
[63,32,23,22],
[63,32,24,0],
[63,32,24,1],
[63,32,24,2],
[63,32,24,3],
[63,32,24,4],
[63,32,24,5],
[63,32,24,6],
[63,32,24,7],
[63,32,24,8],
[63,32,24,10],
[63,32,24,13],
[63,32,24,14],
[63,32,24,17],
[63,32,24,19],
[63,32,24,21],
[63,32,24,22],
[63,32,24,23],
[63,32,25,0],
[63,32,25,1],
[63,32,25,2],
[63,32,25,3],
[63,32,25,4],
[63,32,25,5],
[63,32,25,6],
[63,32,25,8],
[63,32,25,10],
[63,32,25,13],
[63,32,25,14],
[63,32,25,16],
[63,32,25,17],
[63,32,25,19],
[63,32,25,20],
[63,32,25,21],
[63,32,25,22],
[63,32,25,23],
[63,32,25,24],
[63,32,27,0],
[63,32,27,1],
[63,32,27,2],
[63,32,27,3],
[63,32,27,4],
[63,32,27,5],
[63,32,27,6],
[63,32,27,7],
[63,32,27,8],
[63,32,27,13],
[63,32,27,14],
[63,32,27,16],
[63,32,27,17],
[63,32,27,19],
[63,32,27,21],
[63,32,27,23],
[63,32,27,24],
[63,32,27,25],
[63,32,28,0],
[63,32,28,1],
[63,32,28,2],
[63,32,28,3],
[63,32,28,4],
[63,32,28,5],
[63,32,28,6],
[63,32,28,8],
[63,32,28,10],
[63,32,28,13],
[63,32,28,16],
[63,32,28,17],
[63,32,28,19],
[63,32,28,20],
[63,32,28,21],
[63,32,28,22],
[63,32,28,23],
[63,32,28,24],
[63,32,28,27],
[63,32,30,0],
[63,32,30,1],
[63,32,30,2],
[63,32,30,3],
[63,32,30,4],
[63,32,30,5],
[63,32,30,6],
[63,32,30,7],
[63,32,30,8],
[63,32,30,13],
[63,32,30,14],
[63,32,30,16],
[63,32,30,17],
[63,32,30,19],
[63,32,30,20],
[63,32,30,21],
[63,32,30,22],
[63,32,30,24],
[63,32,30,25],
[63,32,31,0],
[63,32,31,1],
[63,32,31,2],
[63,32,31,3],
[63,32,31,4],
[63,32,31,5],
[63,32,31,6],
[63,32,31,7],
[63,32,31,10],
[63,32,31,13],
[63,32,31,14],
[63,32,31,16],
[63,32,31,17],
[63,32,31,19],
[63,32,31,20],
[63,32,31,21],
[63,32,31,22],
[63,32,31,24],
[63,33,3,0],
[63,33,3,1],
[63,33,3,2],
[63,33,4,0],
[63,33,4,1],
[63,33,4,2],
[63,33,4,3],
[63,33,5,0],
[63,33,5,1],
[63,33,5,2],
[63,33,5,4],
[63,33,6,0],
[63,33,6,1],
[63,33,6,2],
[63,33,6,4],
[63,33,7,0],
[63,33,7,1],
[63,33,7,2],
[63,33,7,3],
[63,33,7,4],
[63,33,7,5],
[63,33,7,6],
[63,33,8,0],
[63,33,8,1],
[63,33,8,2],
[63,33,8,4],
[63,33,8,7],
[63,33,10,0],
[63,33,10,1],
[63,33,10,2],
[63,33,10,3],
[63,33,10,4],
[63,33,10,5],
[63,33,10,6],
[63,33,10,7],
[63,33,10,8],
[63,33,13,0],
[63,33,13,1],
[63,33,13,2],
[63,33,13,3],
[63,33,13,4],
[63,33,13,5],
[63,33,13,6],
[63,33,13,7],
[63,33,13,8],
[63,33,13,10],
[63,33,14,0],
[63,33,14,1],
[63,33,14,2],
[63,33,14,3],
[63,33,14,4],
[63,33,14,5],
[63,33,14,6],
[63,33,14,7],
[63,33,14,8],
[63,33,14,10],
[63,33,16,0],
[63,33,16,1],
[63,33,16,2],
[63,33,16,3],
[63,33,16,4],
[63,33,16,5],
[63,33,16,6],
[63,33,16,7],
[63,33,16,8],
[63,33,16,10],
[63,33,16,13],
[63,33,16,14],
[63,33,17,0],
[63,33,17,1],
[63,33,17,2],
[63,33,17,3],
[63,33,17,4],
[63,33,17,5],
[63,33,17,6],
[63,33,17,7],
[63,33,17,8],
[63,33,17,10],
[63,33,17,13],
[63,33,17,14],
[63,33,17,16],
[63,33,19,0],
[63,33,19,1],
[63,33,19,2],
[63,33,19,3],
[63,33,19,4],
[63,33,19,5],
[63,33,19,6],
[63,33,19,7],
[63,33,19,8],
[63,33,19,10],
[63,33,19,16],
[63,33,19,17],
[63,33,20,0],
[63,33,20,1],
[63,33,20,2],
[63,33,20,3],
[63,33,20,4],
[63,33,20,5],
[63,33,20,6],
[63,33,20,7],
[63,33,20,8],
[63,33,20,13],
[63,33,20,14],
[63,33,20,16],
[63,33,20,17],
[63,33,20,19],
[63,33,21,0],
[63,33,21,1],
[63,33,21,2],
[63,33,21,4],
[63,33,21,7],
[63,33,21,10],
[63,33,21,13],
[63,33,21,14],
[63,33,21,16],
[63,33,21,17],
[63,33,21,19],
[63,33,21,20],
[63,33,22,0],
[63,33,22,1],
[63,33,22,2],
[63,33,22,3],
[63,33,22,4],
[63,33,22,5],
[63,33,22,6],
[63,33,22,7],
[63,33,22,8],
[63,33,22,13],
[63,33,22,14],
[63,33,22,16],
[63,33,22,17],
[63,33,22,19],
[63,33,22,21],
[63,33,23,0],
[63,33,23,1],
[63,33,23,2],
[63,33,23,3],
[63,33,23,4],
[63,33,23,5],
[63,33,23,6],
[63,33,23,7],
[63,33,23,8],
[63,33,23,10],
[63,33,23,13],
[63,33,23,14],
[63,33,23,16],
[63,33,23,17],
[63,33,23,20],
[63,33,23,21],
[63,33,23,22],
[63,33,24,0],
[63,33,24,1],
[63,33,24,2],
[63,33,24,3],
[63,33,24,4],
[63,33,24,5],
[63,33,24,6],
[63,33,24,7],
[63,33,24,8],
[63,33,24,10],
[63,33,24,13],
[63,33,24,14],
[63,33,24,17],
[63,33,24,20],
[63,33,24,21],
[63,33,24,22],
[63,33,24,23],
[63,33,25,0],
[63,33,25,1],
[63,33,25,2],
[63,33,25,3],
[63,33,25,4],
[63,33,25,5],
[63,33,25,6],
[63,33,25,8],
[63,33,25,10],
[63,33,25,13],
[63,33,25,14],
[63,33,25,19],
[63,33,25,20],
[63,33,25,21],
[63,33,25,22],
[63,33,25,23],
[63,33,25,24],
[63,33,27,0],
[63,33,27,1],
[63,33,27,2],
[63,33,27,3],
[63,33,27,4],
[63,33,27,5],
[63,33,27,6],
[63,33,27,7],
[63,33,27,8],
[63,33,27,13],
[63,33,27,16],
[63,33,27,17],
[63,33,27,19],
[63,33,27,21],
[63,33,27,23],
[63,33,27,24],
[63,33,27,25],
[63,33,28,0],
[63,33,28,1],
[63,33,28,2],
[63,33,28,3],
[63,33,28,4],
[63,33,28,5],
[63,33,28,6],
[63,33,28,8],
[63,33,28,10],
[63,33,28,14],
[63,33,28,16],
[63,33,28,17],
[63,33,28,19],
[63,33,28,20],
[63,33,28,21],
[63,33,28,22],
[63,33,28,23],
[63,33,28,24],
[63,33,30,0],
[63,33,30,1],
[63,33,30,2],
[63,33,30,3],
[63,33,30,4],
[63,33,30,5],
[63,33,30,6],
[63,33,30,7],
[63,33,30,10],
[63,33,30,13],
[63,33,30,14],
[63,33,30,16],
[63,33,30,17],
[63,33,30,19],
[63,33,30,20],
[63,33,30,21],
[63,33,30,22],
[63,33,30,24],
[63,33,31,0],
[63,33,31,1],
[63,33,31,2],
[63,33,31,3],
[63,33,31,4],
[63,33,31,5],
[63,33,31,8],
[63,33,31,10],
[63,33,31,13],
[63,33,31,14],
[63,33,31,16],
[63,33,31,17],
[63,33,31,19],
[63,33,31,20],
[63,33,31,21],
[63,33,31,22],
[63,33,31,24],
[63,34,3,0],
[63,34,3,1],
[63,34,3,2],
[63,34,4,0],
[63,34,4,1],
[63,34,4,2],
[63,34,4,3],
[63,34,5,0],
[63,34,5,1],
[63,34,5,2],
[63,34,5,4],
[63,34,6,0],
[63,34,6,1],
[63,34,6,2],
[63,34,6,4],
[63,34,8,0],
[63,34,8,1],
[63,34,8,2],
[63,34,8,4],
[63,34,10,0],
[63,34,10,1],
[63,34,10,2],
[63,34,10,3],
[63,34,10,4],
[63,34,10,5],
[63,34,10,6],
[63,34,10,8],
[63,34,11,0],
[63,34,11,1],
[63,34,11,2],
[63,34,11,3],
[63,34,11,4],
[63,34,11,5],
[63,34,11,6],
[63,34,11,8],
[63,34,11,10],
[63,34,12,0],
[63,34,12,1],
[63,34,12,2],
[63,34,12,3],
[63,34,12,4],
[63,34,12,5],
[63,34,12,6],
[63,34,12,8],
[63,34,12,10],
[63,34,13,0],
[63,34,13,1],
[63,34,13,2],
[63,34,13,3],
[63,34,13,4],
[63,34,13,5],
[63,34,13,6],
[63,34,13,8],
[63,34,13,10],
[63,34,13,11],
[63,34,13,12],
[63,34,14,0],
[63,34,14,1],
[63,34,14,2],
[63,34,14,3],
[63,34,14,4],
[63,34,14,5],
[63,34,14,6],
[63,34,14,8],
[63,34,14,10],
[63,34,14,11],
[63,34,14,12],
[63,34,16,0],
[63,34,16,1],
[63,34,16,2],
[63,34,16,3],
[63,34,16,4],
[63,34,16,5],
[63,34,16,6],
[63,34,16,8],
[63,34,16,10],
[63,34,16,11],
[63,34,16,12],
[63,34,16,13],
[63,34,16,14],
[63,34,17,0],
[63,34,17,1],
[63,34,17,2],
[63,34,17,3],
[63,34,17,4],
[63,34,17,5],
[63,34,17,6],
[63,34,17,8],
[63,34,17,10],
[63,34,17,11],
[63,34,17,12],
[63,34,17,13],
[63,34,17,14],
[63,34,17,16],
[63,34,19,0],
[63,34,19,1],
[63,34,19,2],
[63,34,19,3],
[63,34,19,4],
[63,34,19,5],
[63,34,19,6],
[63,34,19,8],
[63,34,19,10],
[63,34,19,11],
[63,34,19,12],
[63,34,19,16],
[63,34,19,17],
[63,34,20,0],
[63,34,20,1],
[63,34,20,2],
[63,34,20,3],
[63,34,20,4],
[63,34,20,5],
[63,34,20,6],
[63,34,20,8],
[63,34,20,11],
[63,34,20,12],
[63,34,20,13],
[63,34,20,14],
[63,34,20,16],
[63,34,20,17],
[63,34,20,19],
[63,34,21,0],
[63,34,21,1],
[63,34,21,2],
[63,34,21,4],
[63,34,21,10],
[63,34,21,11],
[63,34,21,12],
[63,34,21,13],
[63,34,21,14],
[63,34,21,16],
[63,34,21,17],
[63,34,21,19],
[63,34,22,0],
[63,34,22,1],
[63,34,22,2],
[63,34,22,3],
[63,34,22,4],
[63,34,22,5],
[63,34,22,6],
[63,34,22,8],
[63,34,22,11],
[63,34,22,12],
[63,34,22,13],
[63,34,22,14],
[63,34,22,16],
[63,34,22,17],
[63,34,22,21],
[63,34,23,0],
[63,34,23,1],
[63,34,23,2],
[63,34,23,3],
[63,34,23,4],
[63,34,23,5],
[63,34,23,6],
[63,34,23,8],
[63,34,23,10],
[63,34,23,11],
[63,34,23,12],
[63,34,23,13],
[63,34,23,14],
[63,34,23,16],
[63,34,23,17],
[63,34,23,19],
[63,34,23,20],
[63,34,23,21],
[63,34,23,22],
[63,34,24,0],
[63,34,24,1],
[63,34,24,2],
[63,34,24,3],
[63,34,24,4],
[63,34,24,5],
[63,34,24,6],
[63,34,24,8],
[63,34,24,10],
[63,34,24,11],
[63,34,24,12],
[63,34,24,13],
[63,34,24,14],
[63,34,24,17],
[63,34,24,19],
[63,34,24,20],
[63,34,24,21],
[63,34,24,22],
[63,34,24,23],
[63,34,27,0],
[63,34,27,1],
[63,34,27,2],
[63,34,27,3],
[63,34,27,4],
[63,34,27,5],
[63,34,27,6],
[63,34,27,8],
[63,34,27,11],
[63,34,27,14],
[63,34,27,16],
[63,34,27,17],
[63,34,27,19],
[63,34,27,21],
[63,34,27,23],
[63,34,27,24],
[63,34,29,0],
[63,34,29,1],
[63,34,29,2],
[63,34,29,3],
[63,34,29,4],
[63,34,29,5],
[63,34,29,6],
[63,34,29,10],
[63,34,29,13],
[63,34,29,14],
[63,34,29,16],
[63,34,29,17],
[63,34,29,19],
[63,34,29,20],
[63,34,29,21],
[63,34,29,22],
[63,34,29,23],
[63,34,29,24],
[63,34,30,0],
[63,34,30,1],
[63,34,30,2],
[63,34,30,3],
[63,34,30,4],
[63,34,30,5],
[63,34,30,8],
[63,34,30,10],
[63,34,30,11],
[63,34,30,12],
[63,34,30,13],
[63,34,30,14],
[63,34,30,16],
[63,34,30,17],
[63,34,30,19],
[63,34,30,20],
[63,34,30,21],
[63,34,30,22],
[63,34,30,24],
[63,34,31,0],
[63,34,31,1],
[63,34,31,2],
[63,34,31,3],
[63,34,31,4],
[63,34,31,6],
[63,34,31,8],
[63,34,31,10],
[63,34,31,11],
[63,34,31,12],
[63,34,31,13],
[63,34,31,14],
[63,34,31,16],
[63,34,31,17],
[63,34,31,19],
[63,34,31,20],
[63,34,31,21],
[63,34,31,22],
[63,34,32,0],
[63,34,32,1],
[63,34,32,2],
[63,34,32,5],
[63,34,32,6],
[63,34,32,8],
[63,34,32,10],
[63,34,32,13],
[63,34,32,14],
[63,34,32,16],
[63,34,32,17],
[63,34,32,19],
[63,34,32,20],
[63,34,32,21],
[63,34,33,3],
[63,34,33,4],
[63,34,33,5],
[63,34,33,6],
[63,34,33,8],
[63,34,33,10],
[63,34,33,13],
[63,34,33,14],
[63,34,33,16],
[63,34,33,17],
[63,34,33,19],
[63,34,33,20],
[63,36,3,0],
[63,36,3,1],
[63,36,3,2],
[63,36,4,0],
[63,36,4,1],
[63,36,4,2],
[63,36,4,3],
[63,36,5,0],
[63,36,5,1],
[63,36,5,2],
[63,36,5,4],
[63,36,6,0],
[63,36,6,1],
[63,36,6,2],
[63,36,6,4],
[63,36,7,0],
[63,36,7,1],
[63,36,7,2],
[63,36,7,3],
[63,36,7,4],
[63,36,7,5],
[63,36,7,6],
[63,36,8,0],
[63,36,8,1],
[63,36,8,2],
[63,36,8,4],
[63,36,8,7],
[63,36,10,0],
[63,36,10,1],
[63,36,10,2],
[63,36,10,3],
[63,36,10,4],
[63,36,10,5],
[63,36,10,6],
[63,36,10,7],
[63,36,10,8],
[63,36,11,0],
[63,36,11,1],
[63,36,11,2],
[63,36,11,3],
[63,36,11,4],
[63,36,11,5],
[63,36,11,6],
[63,36,11,7],
[63,36,11,8],
[63,36,11,10],
[63,36,12,0],
[63,36,12,1],
[63,36,12,2],
[63,36,12,3],
[63,36,12,4],
[63,36,12,5],
[63,36,12,6],
[63,36,12,7],
[63,36,12,8],
[63,36,12,10],
[63,36,16,0],
[63,36,16,1],
[63,36,16,2],
[63,36,16,3],
[63,36,16,4],
[63,36,16,5],
[63,36,16,6],
[63,36,16,7],
[63,36,16,8],
[63,36,16,10],
[63,36,16,11],
[63,36,16,12],
[63,36,17,0],
[63,36,17,1],
[63,36,17,2],
[63,36,17,3],
[63,36,17,4],
[63,36,17,5],
[63,36,17,6],
[63,36,17,7],
[63,36,17,8],
[63,36,17,10],
[63,36,17,11],
[63,36,17,12],
[63,36,17,16],
[63,36,20,0],
[63,36,20,1],
[63,36,20,2],
[63,36,20,3],
[63,36,20,4],
[63,36,20,5],
[63,36,20,6],
[63,36,20,7],
[63,36,20,8],
[63,36,20,11],
[63,36,20,12],
[63,36,20,16],
[63,36,20,17],
[63,36,21,0],
[63,36,21,1],
[63,36,21,2],
[63,36,21,4],
[63,36,21,7],
[63,36,21,10],
[63,36,21,11],
[63,36,21,12],
[63,36,21,16],
[63,36,21,17],
[63,36,21,20],
[63,36,22,0],
[63,36,22,1],
[63,36,22,2],
[63,36,22,3],
[63,36,22,4],
[63,36,22,5],
[63,36,22,6],
[63,36,22,7],
[63,36,22,8],
[63,36,22,11],
[63,36,22,12],
[63,36,22,21],
[63,36,23,0],
[63,36,23,1],
[63,36,23,2],
[63,36,23,3],
[63,36,23,4],
[63,36,23,5],
[63,36,23,6],
[63,36,23,7],
[63,36,23,8],
[63,36,23,10],
[63,36,23,11],
[63,36,23,12],
[63,36,23,16],
[63,36,23,17],
[63,36,23,20],
[63,36,23,21],
[63,36,23,22],
[63,36,24,0],
[63,36,24,1],
[63,36,24,2],
[63,36,24,3],
[63,36,24,4],
[63,36,24,5],
[63,36,24,6],
[63,36,24,7],
[63,36,24,8],
[63,36,24,10],
[63,36,24,11],
[63,36,24,12],
[63,36,24,17],
[63,36,24,20],
[63,36,24,21],
[63,36,24,22],
[63,36,24,23],
[63,36,25,0],
[63,36,25,1],
[63,36,25,2],
[63,36,25,3],
[63,36,25,4],
[63,36,25,5],
[63,36,25,6],
[63,36,25,8],
[63,36,25,10],
[63,36,25,11],
[63,36,25,16],
[63,36,25,17],
[63,36,25,20],
[63,36,25,21],
[63,36,25,22],
[63,36,25,23],
[63,36,25,24],
[63,36,27,0],
[63,36,27,1],
[63,36,27,2],
[63,36,27,3],
[63,36,27,4],
[63,36,27,5],
[63,36,27,6],
[63,36,27,7],
[63,36,27,11],
[63,36,27,12],
[63,36,27,16],
[63,36,27,17],
[63,36,27,21],
[63,36,27,23],
[63,36,27,24],
[63,36,28,0],
[63,36,28,1],
[63,36,28,2],
[63,36,28,3],
[63,36,28,4],
[63,36,28,5],
[63,36,28,8],
[63,36,28,10],
[63,36,28,11],
[63,36,28,12],
[63,36,28,16],
[63,36,28,17],
[63,36,28,20],
[63,36,28,21],
[63,36,28,22],
[63,36,28,23],
[63,36,28,24],
[63,36,29,0],
[63,36,29,1],
[63,36,29,2],
[63,36,29,3],
[63,36,29,4],
[63,36,29,6],
[63,36,29,7],
[63,36,29,8],
[63,36,29,10],
[63,36,29,16],
[63,36,29,17],
[63,36,29,20],
[63,36,29,21],
[63,36,29,22],
[63,36,30,0],
[63,36,30,1],
[63,36,30,2],
[63,36,30,5],
[63,36,30,6],
[63,36,30,7],
[63,36,30,8],
[63,36,30,10],
[63,36,30,11],
[63,36,30,12],
[63,36,30,16],
[63,36,30,17],
[63,36,30,20],
[63,36,30,21],
[63,36,31,3],
[63,36,31,4],
[63,36,31,5],
[63,36,31,6],
[63,36,31,7],
[63,36,31,8],
[63,36,31,10],
[63,36,31,11],
[63,36,31,12],
[63,36,31,16],
[63,36,31,17],
[63,36,31,20],
[63,36,32,0],
[63,36,32,1],
[63,36,32,2],
[63,36,32,3],
[63,36,32,4],
[63,36,32,5],
[63,36,32,6],
[63,36,32,7],
[63,36,32,8],
[63,36,32,10],
[63,36,32,16],
[63,36,32,17],
[63,36,33,0],
[63,36,33,1],
[63,36,33,2],
[63,36,33,3],
[63,36,33,4],
[63,36,33,5],
[63,36,33,6],
[63,36,33,7],
[63,36,33,8],
[63,36,33,10],
[63,36,33,16],
[63,36,33,17],
[63,36,34,0],
[63,36,34,1],
[63,36,34,2],
[63,36,34,3],
[63,36,34,4],
[63,36,34,5],
[63,36,34,6],
[63,36,34,8],
[63,36,34,10],
[63,36,34,11],
[63,36,34,12],
[63,36,34,16],
[63,36,34,17],
[63,37,3,0],
[63,37,3,1],
[63,37,3,2],
[63,37,5,0],
[63,37,5,1],
[63,37,5,2],
[63,37,6,0],
[63,37,6,1],
[63,37,6,2],
[63,37,7,0],
[63,37,7,1],
[63,37,7,2],
[63,37,7,3],
[63,37,7,5],
[63,37,7,6],
[63,37,8,0],
[63,37,8,1],
[63,37,8,2],
[63,37,8,7],
[63,37,10,0],
[63,37,10,1],
[63,37,10,2],
[63,37,10,3],
[63,37,10,5],
[63,37,10,6],
[63,37,10,7],
[63,37,10,8],
[63,37,11,0],
[63,37,11,1],
[63,37,11,2],
[63,37,11,3],
[63,37,11,5],
[63,37,11,6],
[63,37,11,7],
[63,37,11,8],
[63,37,11,10],
[63,37,12,0],
[63,37,12,1],
[63,37,12,2],
[63,37,12,3],
[63,37,12,5],
[63,37,12,6],
[63,37,12,7],
[63,37,12,8],
[63,37,12,10],
[63,37,13,0],
[63,37,13,1],
[63,37,13,2],
[63,37,13,3],
[63,37,13,5],
[63,37,13,6],
[63,37,13,7],
[63,37,13,8],
[63,37,13,10],
[63,37,13,11],
[63,37,13,12],
[63,37,14,0],
[63,37,14,1],
[63,37,14,2],
[63,37,14,3],
[63,37,14,5],
[63,37,14,6],
[63,37,14,7],
[63,37,14,8],
[63,37,14,10],
[63,37,14,11],
[63,37,14,12],
[63,37,16,0],
[63,37,16,1],
[63,37,16,2],
[63,37,16,3],
[63,37,16,5],
[63,37,16,6],
[63,37,16,7],
[63,37,16,8],
[63,37,16,10],
[63,37,16,11],
[63,37,16,12],
[63,37,16,13],
[63,37,16,14],
[63,37,17,0],
[63,37,17,1],
[63,37,17,2],
[63,37,17,3],
[63,37,17,5],
[63,37,17,6],
[63,37,17,7],
[63,37,17,8],
[63,37,17,10],
[63,37,17,11],
[63,37,17,12],
[63,37,17,13],
[63,37,17,14],
[63,37,17,16],
[63,37,19,0],
[63,37,19,1],
[63,37,19,2],
[63,37,19,3],
[63,37,19,5],
[63,37,19,6],
[63,37,19,7],
[63,37,19,8],
[63,37,19,10],
[63,37,19,11],
[63,37,19,12],
[63,37,19,16],
[63,37,19,17],
[63,37,20,0],
[63,37,20,1],
[63,37,20,2],
[63,37,20,3],
[63,37,20,5],
[63,37,20,6],
[63,37,20,7],
[63,37,20,8],
[63,37,20,11],
[63,37,20,12],
[63,37,20,13],
[63,37,20,14],
[63,37,20,16],
[63,37,20,17],
[63,37,20,19],
[63,37,21,0],
[63,37,21,1],
[63,37,21,2],
[63,37,21,7],
[63,37,21,10],
[63,37,21,11],
[63,37,21,12],
[63,37,21,13],
[63,37,21,14],
[63,37,21,19],
[63,37,21,20],
[63,37,22,0],
[63,37,22,1],
[63,37,22,2],
[63,37,22,3],
[63,37,22,5],
[63,37,22,6],
[63,37,22,7],
[63,37,22,8],
[63,37,22,11],
[63,37,22,12],
[63,37,22,13],
[63,37,22,14],
[63,37,22,16],
[63,37,22,17],
[63,37,22,19],
[63,37,22,21],
[63,37,23,0],
[63,37,23,1],
[63,37,23,2],
[63,37,23,3],
[63,37,23,5],
[63,37,23,6],
[63,37,23,7],
[63,37,23,8],
[63,37,23,10],
[63,37,23,11],
[63,37,23,12],
[63,37,23,13],
[63,37,23,16],
[63,37,23,17],
[63,37,23,19],
[63,37,23,20],
[63,37,23,21],
[63,37,23,22],
[63,37,24,0],
[63,37,24,1],
[63,37,24,2],
[63,37,24,3],
[63,37,24,5],
[63,37,24,6],
[63,37,24,7],
[63,37,24,8],
[63,37,24,10],
[63,37,24,11],
[63,37,24,12],
[63,37,24,13],
[63,37,24,17],
[63,37,24,19],
[63,37,24,20],
[63,37,24,21],
[63,37,24,22],
[63,37,24,23],
[63,37,25,0],
[63,37,25,1],
[63,37,25,2],
[63,37,25,3],
[63,37,25,5],
[63,37,25,6],
[63,37,25,8],
[63,37,25,12],
[63,37,25,13],
[63,37,25,14],
[63,37,25,16],
[63,37,25,17],
[63,37,25,19],
[63,37,25,20],
[63,37,25,21],
[63,37,25,22],
[63,37,25,23],
[63,37,25,24],
[63,37,27,0],
[63,37,27,1],
[63,37,27,2],
[63,37,27,3],
[63,37,27,5],
[63,37,27,8],
[63,37,27,11],
[63,37,27,12],
[63,37,27,13],
[63,37,27,14],
[63,37,27,16],
[63,37,27,17],
[63,37,27,19],
[63,37,27,21],
[63,37,27,23],
[63,37,27,24],
[63,37,28,0],
[63,37,28,1],
[63,37,28,2],
[63,37,28,3],
[63,37,28,6],
[63,37,28,8],
[63,37,28,10],
[63,37,28,11],
[63,37,28,12],
[63,37,28,13],
[63,37,28,14],
[63,37,28,16],
[63,37,28,17],
[63,37,28,19],
[63,37,28,20],
[63,37,28,21],
[63,37,28,22],
[63,37,29,0],
[63,37,29,1],
[63,37,29,2],
[63,37,29,5],
[63,37,29,6],
[63,37,29,7],
[63,37,29,8],
[63,37,29,10],
[63,37,29,13],
[63,37,29,14],
[63,37,29,16],
[63,37,29,17],
[63,37,29,19],
[63,37,29,20],
[63,37,29,21],
[63,37,30,3],
[63,37,30,5],
[63,37,30,6],
[63,37,30,7],
[63,37,30,8],
[63,37,30,10],
[63,37,30,11],
[63,37,30,12],
[63,37,30,13],
[63,37,30,14],
[63,37,30,16],
[63,37,30,17],
[63,37,30,19],
[63,37,30,20],
[63,37,31,0],
[63,37,31,1],
[63,37,31,2],
[63,37,31,3],
[63,37,31,5],
[63,37,31,6],
[63,37,31,7],
[63,37,31,8],
[63,37,31,10],
[63,37,31,11],
[63,37,31,12],
[63,37,31,13],
[63,37,31,14],
[63,37,31,16],
[63,37,31,17],
[63,37,31,19],
[63,37,32,0],
[63,37,32,1],
[63,37,32,2],
[63,37,32,3],
[63,37,32,5],
[63,37,32,6],
[63,37,32,7],
[63,37,32,8],
[63,37,32,10],
[63,37,32,13],
[63,37,32,14],
[63,37,32,16],
[63,37,32,17],
[63,37,33,0],
[63,37,33,1],
[63,37,33,2],
[63,37,33,3],
[63,37,33,5],
[63,37,33,6],
[63,37,33,7],
[63,37,33,8],
[63,37,33,10],
[63,37,33,13],
[63,37,33,14],
[63,37,33,16],
[63,37,33,17],
[63,37,34,0],
[63,37,34,1],
[63,37,34,2],
[63,37,34,3],
[63,37,34,5],
[63,37,34,6],
[63,37,34,8],
[63,37,34,10],
[63,37,34,11],
[63,37,34,12],
[63,37,34,13],
[63,37,34,14],
[63,37,36,0],
[63,37,36,1],
[63,37,36,2],
[63,37,36,3],
[63,37,36,5],
[63,37,36,6],
[63,37,36,7],
[63,37,36,8],
[63,37,36,10],
[63,37,36,11],
[63,37,36,12],
[63,38,3,0],
[63,38,3,1],
[63,38,3,2],
[63,38,4,0],
[63,38,4,1],
[63,38,4,2],
[63,38,4,3],
[63,38,5,0],
[63,38,5,1],
[63,38,5,2],
[63,38,5,4],
[63,38,6,0],
[63,38,6,1],
[63,38,6,2],
[63,38,6,4],
[63,38,8,0],
[63,38,8,1],
[63,38,8,2],
[63,38,8,4],
[63,38,10,0],
[63,38,10,1],
[63,38,10,2],
[63,38,10,3],
[63,38,10,4],
[63,38,10,5],
[63,38,10,6],
[63,38,10,8],
[63,38,11,0],
[63,38,11,1],
[63,38,11,2],
[63,38,11,3],
[63,38,11,4],
[63,38,11,5],
[63,38,11,6],
[63,38,11,8],
[63,38,11,10],
[63,38,12,0],
[63,38,12,1],
[63,38,12,2],
[63,38,12,3],
[63,38,12,4],
[63,38,12,5],
[63,38,12,6],
[63,38,12,8],
[63,38,12,10],
[63,38,13,0],
[63,38,13,1],
[63,38,13,2],
[63,38,13,3],
[63,38,13,4],
[63,38,13,5],
[63,38,13,6],
[63,38,13,8],
[63,38,13,10],
[63,38,13,11],
[63,38,13,12],
[63,38,14,0],
[63,38,14,1],
[63,38,14,2],
[63,38,14,3],
[63,38,14,4],
[63,38,14,5],
[63,38,14,6],
[63,38,14,8],
[63,38,14,10],
[63,38,14,11],
[63,38,14,12],
[63,38,16,0],
[63,38,16,1],
[63,38,16,2],
[63,38,16,3],
[63,38,16,4],
[63,38,16,5],
[63,38,16,6],
[63,38,16,8],
[63,38,16,10],
[63,38,16,11],
[63,38,16,12],
[63,38,16,13],
[63,38,16,14],
[63,38,17,0],
[63,38,17,1],
[63,38,17,2],
[63,38,17,3],
[63,38,17,4],
[63,38,17,5],
[63,38,17,6],
[63,38,17,8],
[63,38,17,10],
[63,38,17,11],
[63,38,17,12],
[63,38,17,13],
[63,38,17,14],
[63,38,17,16],
[63,38,19,0],
[63,38,19,1],
[63,38,19,2],
[63,38,19,3],
[63,38,19,4],
[63,38,19,5],
[63,38,19,6],
[63,38,19,8],
[63,38,19,10],
[63,38,19,11],
[63,38,19,12],
[63,38,19,16],
[63,38,19,17],
[63,38,20,0],
[63,38,20,1],
[63,38,20,2],
[63,38,20,3],
[63,38,20,4],
[63,38,20,5],
[63,38,20,6],
[63,38,20,8],
[63,38,20,11],
[63,38,20,12],
[63,38,20,13],
[63,38,20,14],
[63,38,20,19],
[63,38,21,0],
[63,38,21,1],
[63,38,21,2],
[63,38,21,4],
[63,38,21,10],
[63,38,21,11],
[63,38,21,12],
[63,38,21,13],
[63,38,21,14],
[63,38,21,16],
[63,38,21,17],
[63,38,21,19],
[63,38,21,20],
[63,38,22,0],
[63,38,22,1],
[63,38,22,2],
[63,38,22,3],
[63,38,22,4],
[63,38,22,5],
[63,38,22,6],
[63,38,22,8],
[63,38,22,11],
[63,38,22,12],
[63,38,22,13],
[63,38,22,16],
[63,38,22,17],
[63,38,22,19],
[63,38,22,21],
[63,38,23,0],
[63,38,23,1],
[63,38,23,2],
[63,38,23,3],
[63,38,23,4],
[63,38,23,5],
[63,38,23,6],
[63,38,23,8],
[63,38,23,10],
[63,38,23,11],
[63,38,23,14],
[63,38,23,16],
[63,38,23,17],
[63,38,23,19],
[63,38,23,20],
[63,38,23,21],
[63,38,23,22],
[63,38,24,0],
[63,38,24,1],
[63,38,24,2],
[63,38,24,3],
[63,38,24,4],
[63,38,24,5],
[63,38,24,6],
[63,38,24,8],
[63,38,24,10],
[63,38,24,11],
[63,38,24,14],
[63,38,24,17],
[63,38,24,19],
[63,38,24,20],
[63,38,24,21],
[63,38,24,22],
[63,38,24,23],
[63,38,27,0],
[63,38,27,1],
[63,38,27,2],
[63,38,27,3],
[63,38,27,4],
[63,38,27,6],
[63,38,27,8],
[63,38,27,11],
[63,38,27,12],
[63,38,27,13],
[63,38,27,14],
[63,38,27,16],
[63,38,27,17],
[63,38,27,19],
[63,38,27,21],
[63,38,29,3],
[63,38,29,4],
[63,38,29,5],
[63,38,29,6],
[63,38,29,8],
[63,38,29,10],
[63,38,29,13],
[63,38,29,14],
[63,38,29,16],
[63,38,29,17],
[63,38,29,19],
[63,38,29,20],
[63,38,30,0],
[63,38,30,1],
[63,38,30,2],
[63,38,30,3],
[63,38,30,4],
[63,38,30,5],
[63,38,30,6],
[63,38,30,8],
[63,38,30,10],
[63,38,30,11],
[63,38,30,12],
[63,38,30,13],
[63,38,30,14],
[63,38,30,16],
[63,38,30,17],
[63,38,30,19],
[63,38,31,0],
[63,38,31,1],
[63,38,31,2],
[63,38,31,3],
[63,38,31,4],
[63,38,31,5],
[63,38,31,6],
[63,38,31,8],
[63,38,31,10],
[63,38,31,11],
[63,38,31,12],
[63,38,31,13],
[63,38,31,14],
[63,38,31,16],
[63,38,31,17],
[63,38,32,0],
[63,38,32,1],
[63,38,32,2],
[63,38,32,3],
[63,38,32,4],
[63,38,32,5],
[63,38,32,6],
[63,38,32,8],
[63,38,32,10],
[63,38,32,13],
[63,38,32,14],
[63,38,32,16],
[63,38,32,17],
[63,38,33,0],
[63,38,33,1],
[63,38,33,2],
[63,38,33,3],
[63,38,33,4],
[63,38,33,5],
[63,38,33,6],
[63,38,33,8],
[63,38,33,10],
[63,38,33,13],
[63,38,33,14],
[63,38,36,0],
[63,38,36,1],
[63,38,36,2],
[63,38,36,3],
[63,38,36,4],
[63,38,36,5],
[63,38,36,6],
[63,38,36,8],
[63,38,36,10],
[63,38,36,11],
[63,38,37,0],
[63,38,37,1],
[63,38,37,2],
[63,38,37,3],
[63,38,37,5],
[63,38,37,6],
[63,38,37,8],
[63,39,4,0],
[63,39,4,1],
[63,39,4,2],
[63,39,7,0],
[63,39,7,1],
[63,39,7,2],
[63,39,7,4],
[63,39,10,0],
[63,39,10,1],
[63,39,10,2],
[63,39,10,4],
[63,39,10,7],
[63,39,11,0],
[63,39,11,1],
[63,39,11,2],
[63,39,11,4],
[63,39,11,7],
[63,39,11,10],
[63,39,12,0],
[63,39,12,1],
[63,39,12,2],
[63,39,12,4],
[63,39,12,7],
[63,39,12,10],
[63,39,13,0],
[63,39,13,1],
[63,39,13,2],
[63,39,13,4],
[63,39,13,7],
[63,39,13,10],
[63,39,13,11],
[63,39,13,12],
[63,39,14,0],
[63,39,14,1],
[63,39,14,2],
[63,39,14,4],
[63,39,14,7],
[63,39,14,10],
[63,39,14,11],
[63,39,14,12],
[63,39,16,0],
[63,39,16,1],
[63,39,16,2],
[63,39,16,4],
[63,39,16,7],
[63,39,16,10],
[63,39,16,11],
[63,39,16,12],
[63,39,16,13],
[63,39,16,14],
[63,39,17,0],
[63,39,17,1],
[63,39,17,2],
[63,39,17,4],
[63,39,17,7],
[63,39,17,10],
[63,39,17,11],
[63,39,17,12],
[63,39,17,13],
[63,39,17,14],
[63,39,17,16],
[63,39,19,0],
[63,39,19,1],
[63,39,19,2],
[63,39,19,4],
[63,39,19,7],
[63,39,19,10],
[63,39,19,11],
[63,39,19,12],
[63,39,20,0],
[63,39,20,1],
[63,39,20,2],
[63,39,20,4],
[63,39,20,7],
[63,39,20,11],
[63,39,20,12],
[63,39,20,13],
[63,39,20,14],
[63,39,20,16],
[63,39,20,17],
[63,39,20,19],
[63,39,22,0],
[63,39,22,1],
[63,39,22,2],
[63,39,22,4],
[63,39,22,7],
[63,39,22,11],
[63,39,22,14],
[63,39,22,16],
[63,39,22,17],
[63,39,22,19],
[63,39,23,0],
[63,39,23,1],
[63,39,23,2],
[63,39,23,4],
[63,39,23,7],
[63,39,23,12],
[63,39,23,13],
[63,39,23,14],
[63,39,23,16],
[63,39,23,17],
[63,39,23,19],
[63,39,23,20],
[63,39,23,22],
[63,39,24,0],
[63,39,24,1],
[63,39,24,2],
[63,39,24,4],
[63,39,24,7],
[63,39,24,12],
[63,39,24,13],
[63,39,24,14],
[63,39,24,17],
[63,39,24,19],
[63,39,24,20],
[63,39,24,22],
[63,39,24,23],
[63,39,25,0],
[63,39,25,1],
[63,39,25,2],
[63,39,25,4],
[63,39,25,10],
[63,39,25,11],
[63,39,25,12],
[63,39,25,13],
[63,39,25,14],
[63,39,25,16],
[63,39,25,17],
[63,39,25,19],
[63,39,25,20],
[63,39,25,22],
[63,39,25,23],
[63,39,25,24],
[63,39,27,0],
[63,39,27,1],
[63,39,27,2],
[63,39,27,7],
[63,39,27,11],
[63,39,27,12],
[63,39,27,13],
[63,39,27,14],
[63,39,27,16],
[63,39,27,17],
[63,39,27,19],
[63,39,28,4],
[63,39,28,10],
[63,39,28,11],
[63,39,28,12],
[63,39,28,13],
[63,39,28,14],
[63,39,28,16],
[63,39,28,17],
[63,39,28,19],
[63,39,28,20],
[63,39,29,0],
[63,39,29,1],
[63,39,29,2],
[63,39,29,4],
[63,39,29,7],
[63,39,29,10],
[63,39,29,13],
[63,39,29,14],
[63,39,29,16],
[63,39,29,17],
[63,39,29,19],
[63,39,30,0],
[63,39,30,1],
[63,39,30,2],
[63,39,30,4],
[63,39,30,7],
[63,39,30,10],
[63,39,30,11],
[63,39,30,12],
[63,39,30,13],
[63,39,30,14],
[63,39,30,16],
[63,39,30,17],
[63,39,31,0],
[63,39,31,1],
[63,39,31,2],
[63,39,31,4],
[63,39,31,7],
[63,39,31,10],
[63,39,31,11],
[63,39,31,12],
[63,39,31,13],
[63,39,31,14],
[63,39,31,16],
[63,39,31,17],
[63,39,32,0],
[63,39,32,1],
[63,39,32,2],
[63,39,32,4],
[63,39,32,7],
[63,39,32,10],
[63,39,32,13],
[63,39,32,14],
[63,39,33,0],
[63,39,33,1],
[63,39,33,2],
[63,39,33,4],
[63,39,33,7],
[63,39,33,10],
[63,39,33,13],
[63,39,33,14],
[63,39,34,0],
[63,39,34,1],
[63,39,34,2],
[63,39,34,4],
[63,39,34,10],
[63,39,34,11],
[63,39,34,12],
[63,39,34,13],
[63,39,36,0],
[63,39,36,1],
[63,39,36,2],
[63,39,36,4],
[63,39,36,7],
[63,39,37,0],
[63,39,37,1],
[63,39,37,2],
[63,39,37,7],
[63,39,38,0],
[63,39,38,1],
[63,39,38,2],
[63,39,38,4],
[63,42,3,0],
[63,42,3,1],
[63,42,3,2],
[63,42,4,0],
[63,42,4,1],
[63,42,4,2],
[63,42,4,3],
[63,42,5,0],
[63,42,5,1],
[63,42,5,2],
[63,42,5,4],
[63,42,6,0],
[63,42,6,1],
[63,42,6,2],
[63,42,6,4],
[63,42,7,0],
[63,42,7,1],
[63,42,7,2],
[63,42,7,3],
[63,42,7,4],
[63,42,7,5],
[63,42,7,6],
[63,42,8,0],
[63,42,8,1],
[63,42,8,2],
[63,42,8,4],
[63,42,8,7],
[63,42,11,0],
[63,42,11,1],
[63,42,11,2],
[63,42,11,3],
[63,42,11,4],
[63,42,11,5],
[63,42,11,6],
[63,42,11,7],
[63,42,11,8],
[63,42,12,0],
[63,42,12,1],
[63,42,12,2],
[63,42,12,3],
[63,42,12,4],
[63,42,12,5],
[63,42,12,6],
[63,42,12,7],
[63,42,12,8],
[63,42,13,0],
[63,42,13,1],
[63,42,13,2],
[63,42,13,3],
[63,42,13,4],
[63,42,13,5],
[63,42,13,6],
[63,42,13,7],
[63,42,13,8],
[63,42,13,11],
[63,42,13,12],
[63,42,14,0],
[63,42,14,1],
[63,42,14,2],
[63,42,14,3],
[63,42,14,4],
[63,42,14,5],
[63,42,14,6],
[63,42,14,7],
[63,42,14,8],
[63,42,14,11],
[63,42,14,12],
[63,42,16,0],
[63,42,16,1],
[63,42,16,2],
[63,42,16,3],
[63,42,16,4],
[63,42,16,5],
[63,42,16,6],
[63,42,16,7],
[63,42,16,8],
[63,42,16,11],
[63,42,16,12],
[63,42,16,13],
[63,42,16,14],
[63,42,17,0],
[63,42,17,1],
[63,42,17,2],
[63,42,17,3],
[63,42,17,4],
[63,42,17,5],
[63,42,17,6],
[63,42,17,7],
[63,42,17,8],
[63,42,17,11],
[63,42,17,12],
[63,42,17,13],
[63,42,17,14],
[63,42,19,0],
[63,42,19,1],
[63,42,19,2],
[63,42,19,3],
[63,42,19,4],
[63,42,19,5],
[63,42,19,6],
[63,42,19,7],
[63,42,19,8],
[63,42,19,11],
[63,42,19,12],
[63,42,19,16],
[63,42,19,17],
[63,42,21,0],
[63,42,21,1],
[63,42,21,2],
[63,42,21,4],
[63,42,21,7],
[63,42,21,12],
[63,42,21,13],
[63,42,21,14],
[63,42,21,16],
[63,42,21,17],
[63,42,21,19],
[63,42,23,0],
[63,42,23,1],
[63,42,23,2],
[63,42,23,3],
[63,42,23,4],
[63,42,23,5],
[63,42,23,8],
[63,42,23,11],
[63,42,23,12],
[63,42,23,13],
[63,42,23,14],
[63,42,23,16],
[63,42,23,17],
[63,42,23,19],
[63,42,23,21],
[63,42,24,0],
[63,42,24,1],
[63,42,24,2],
[63,42,24,3],
[63,42,24,4],
[63,42,24,5],
[63,42,24,8],
[63,42,24,11],
[63,42,24,12],
[63,42,24,13],
[63,42,24,14],
[63,42,24,17],
[63,42,24,19],
[63,42,24,21],
[63,42,24,23],
[63,42,25,0],
[63,42,25,1],
[63,42,25,2],
[63,42,25,5],
[63,42,25,6],
[63,42,25,8],
[63,42,25,11],
[63,42,25,12],
[63,42,25,13],
[63,42,25,14],
[63,42,25,16],
[63,42,25,17],
[63,42,25,19],
[63,42,25,21],
[63,42,28,0],
[63,42,28,1],
[63,42,28,2],
[63,42,28,3],
[63,42,28,4],
[63,42,28,5],
[63,42,28,6],
[63,42,28,8],
[63,42,28,11],
[63,42,28,12],
[63,42,28,13],
[63,42,28,14],
[63,42,28,16],
[63,42,28,17],
[63,42,29,0],
[63,42,29,1],
[63,42,29,2],
[63,42,29,3],
[63,42,29,4],
[63,42,29,5],
[63,42,29,6],
[63,42,29,7],
[63,42,29,8],
[63,42,29,13],
[63,42,29,14],
[63,42,29,16],
[63,42,29,17],
[63,42,30,0],
[63,42,30,1],
[63,42,30,2],
[63,42,30,3],
[63,42,30,4],
[63,42,30,5],
[63,42,30,6],
[63,42,30,7],
[63,42,30,8],
[63,42,30,11],
[63,42,30,12],
[63,42,30,13],
[63,42,30,14],
[63,42,31,0],
[63,42,31,1],
[63,42,31,2],
[63,42,31,3],
[63,42,31,4],
[63,42,31,5],
[63,42,31,6],
[63,42,31,7],
[63,42,31,8],
[63,42,31,11],
[63,42,31,12],
[63,42,31,13],
[63,42,31,14],
[63,42,32,0],
[63,42,32,1],
[63,42,32,2],
[63,42,32,3],
[63,42,32,4],
[63,42,32,5],
[63,42,32,6],
[63,42,32,7],
[63,42,32,8],
[63,42,32,13],
[63,42,33,0],
[63,42,33,1],
[63,42,33,2],
[63,42,33,3],
[63,42,33,4],
[63,42,33,5],
[63,42,33,6],
[63,42,33,7],
[63,42,33,8],
[63,42,34,0],
[63,42,34,1],
[63,42,34,2],
[63,42,34,3],
[63,42,34,4],
[63,42,34,5],
[63,42,34,6],
[63,42,34,8],
[63,42,36,0],
[63,42,36,1],
[63,42,36,2],
[63,42,36,3],
[63,42,36,4],
[63,42,36,5],
[63,42,37,0],
[63,42,37,1],
[63,42,37,2],
[63,42,37,3],
[63,42,38,0],
[63,42,38,1],
[63,42,38,2],
[63,43,3,0],
[63,43,3,1],
[63,43,3,2],
[63,43,4,0],
[63,43,4,1],
[63,43,4,2],
[63,43,4,3],
[63,43,5,0],
[63,43,5,1],
[63,43,5,2],
[63,43,5,4],
[63,43,6,0],
[63,43,6,1],
[63,43,6,2],
[63,43,6,4],
[63,43,8,0],
[63,43,8,1],
[63,43,8,2],
[63,43,8,4],
[63,43,10,0],
[63,43,10,1],
[63,43,10,2],
[63,43,10,3],
[63,43,10,4],
[63,43,10,5],
[63,43,10,6],
[63,43,10,8],
[63,43,11,0],
[63,43,11,1],
[63,43,11,2],
[63,43,11,3],
[63,43,11,4],
[63,43,11,5],
[63,43,11,6],
[63,43,11,8],
[63,43,11,10],
[63,43,12,0],
[63,43,12,1],
[63,43,12,2],
[63,43,12,3],
[63,43,12,4],
[63,43,12,5],
[63,43,12,6],
[63,43,12,8],
[63,43,12,10],
[63,43,13,0],
[63,43,13,1],
[63,43,13,2],
[63,43,13,3],
[63,43,13,4],
[63,43,13,5],
[63,43,13,6],
[63,43,13,8],
[63,43,13,10],
[63,43,13,11],
[63,43,13,12],
[63,43,14,0],
[63,43,14,1],
[63,43,14,2],
[63,43,14,3],
[63,43,14,4],
[63,43,14,5],
[63,43,14,6],
[63,43,14,8],
[63,43,14,10],
[63,43,14,11],
[63,43,14,12],
[63,43,16,0],
[63,43,16,1],
[63,43,16,2],
[63,43,16,3],
[63,43,16,4],
[63,43,16,5],
[63,43,16,6],
[63,43,16,8],
[63,43,16,10],
[63,43,16,11],
[63,43,16,12],
[63,43,16,13],
[63,43,16,14],
[63,43,17,0],
[63,43,17,1],
[63,43,17,2],
[63,43,17,3],
[63,43,17,4],
[63,43,17,5],
[63,43,17,6],
[63,43,17,8],
[63,43,17,10],
[63,43,17,11],
[63,43,17,12],
[63,43,17,13],
[63,43,17,14],
[63,43,17,16],
[63,43,19,0],
[63,43,19,1],
[63,43,19,2],
[63,43,19,3],
[63,43,19,4],
[63,43,19,5],
[63,43,19,6],
[63,43,19,8],
[63,43,19,10],
[63,43,19,11],
[63,43,19,16],
[63,43,19,17],
[63,43,20,0],
[63,43,20,1],
[63,43,20,2],
[63,43,20,3],
[63,43,20,4],
[63,43,20,5],
[63,43,20,6],
[63,43,20,8],
[63,43,20,12],
[63,43,20,13],
[63,43,20,14],
[63,43,20,16],
[63,43,20,17],
[63,43,20,19],
[63,43,21,0],
[63,43,21,1],
[63,43,21,2],
[63,43,21,4],
[63,43,21,10],
[63,43,21,11],
[63,43,21,12],
[63,43,21,13],
[63,43,21,14],
[63,43,21,16],
[63,43,21,17],
[63,43,21,19],
[63,43,21,20],
[63,43,22,0],
[63,43,22,1],
[63,43,22,2],
[63,43,22,3],
[63,43,22,4],
[63,43,22,5],
[63,43,22,8],
[63,43,22,11],
[63,43,22,12],
[63,43,22,13],
[63,43,22,14],
[63,43,22,16],
[63,43,22,17],
[63,43,22,19],
[63,43,22,21],
[63,43,23,0],
[63,43,23,1],
[63,43,23,2],
[63,43,23,3],
[63,43,23,4],
[63,43,23,6],
[63,43,23,8],
[63,43,23,10],
[63,43,23,11],
[63,43,23,12],
[63,43,23,13],
[63,43,23,14],
[63,43,23,16],
[63,43,23,17],
[63,43,23,19],
[63,43,23,20],
[63,43,23,21],
[63,43,23,22],
[63,43,24,0],
[63,43,24,1],
[63,43,24,2],
[63,43,24,3],
[63,43,24,4],
[63,43,24,6],
[63,43,24,8],
[63,43,24,10],
[63,43,24,11],
[63,43,24,12],
[63,43,24,13],
[63,43,24,14],
[63,43,24,17],
[63,43,24,19],
[63,43,24,20],
[63,43,24,21],
[63,43,24,22],
[63,43,27,0],
[63,43,27,1],
[63,43,27,2],
[63,43,27,3],
[63,43,27,4],
[63,43,27,5],
[63,43,27,6],
[63,43,27,8],
[63,43,27,11],
[63,43,27,12],
[63,43,27,13],
[63,43,27,14],
[63,43,27,16],
[63,43,27,17],
[63,43,29,0],
[63,43,29,1],
[63,43,29,2],
[63,43,29,3],
[63,43,29,4],
[63,43,29,5],
[63,43,29,6],
[63,43,29,8],
[63,43,29,10],
[63,43,29,13],
[63,43,29,14],
[63,43,30,0],
[63,43,30,1],
[63,43,30,2],
[63,43,30,3],
[63,43,30,4],
[63,43,30,5],
[63,43,30,6],
[63,43,30,8],
[63,43,30,10],
[63,43,30,11],
[63,43,30,12],
[63,43,30,13],
[63,43,30,14],
[63,43,31,0],
[63,43,31,1],
[63,43,31,2],
[63,43,31,3],
[63,43,31,4],
[63,43,31,5],
[63,43,31,6],
[63,43,31,8],
[63,43,31,10],
[63,43,31,11],
[63,43,31,12],
[63,43,31,13],
[63,43,32,0],
[63,43,32,1],
[63,43,32,2],
[63,43,32,3],
[63,43,32,4],
[63,43,32,5],
[63,43,32,6],
[63,43,32,8],
[63,43,32,10],
[63,43,33,0],
[63,43,33,1],
[63,43,33,2],
[63,43,33,3],
[63,43,33,4],
[63,43,33,5],
[63,43,33,6],
[63,43,33,8],
[63,43,36,0],
[63,43,36,1],
[63,43,36,2],
[63,43,36,3],
[63,43,36,4],
[63,43,37,0],
[63,43,37,1],
[63,43,37,2],
[63,44,3,0],
[63,44,3,1],
[63,44,3,2],
[63,44,4,0],
[63,44,4,1],
[63,44,4,2],
[63,44,4,3],
[63,44,5,0],
[63,44,5,1],
[63,44,5,2],
[63,44,5,4],
[63,44,6,0],
[63,44,6,1],
[63,44,6,2],
[63,44,6,4],
[63,44,7,0],
[63,44,7,1],
[63,44,7,2],
[63,44,7,3],
[63,44,7,4],
[63,44,7,5],
[63,44,7,6],
[63,44,8,0],
[63,44,8,1],
[63,44,8,2],
[63,44,8,4],
[63,44,8,7],
[63,44,10,0],
[63,44,10,1],
[63,44,10,2],
[63,44,10,3],
[63,44,10,4],
[63,44,10,5],
[63,44,10,6],
[63,44,10,7],
[63,44,10,8],
[63,44,11,0],
[63,44,11,1],
[63,44,11,2],
[63,44,11,3],
[63,44,11,4],
[63,44,11,5],
[63,44,11,6],
[63,44,11,7],
[63,44,11,8],
[63,44,11,10],
[63,44,12,0],
[63,44,12,1],
[63,44,12,2],
[63,44,12,3],
[63,44,12,4],
[63,44,12,5],
[63,44,12,6],
[63,44,12,7],
[63,44,12,8],
[63,44,12,10],
[63,44,13,0],
[63,44,13,1],
[63,44,13,2],
[63,44,13,3],
[63,44,13,4],
[63,44,13,5],
[63,44,13,6],
[63,44,13,7],
[63,44,13,8],
[63,44,13,10],
[63,44,13,11],
[63,44,13,12],
[63,44,14,0],
[63,44,14,1],
[63,44,14,2],
[63,44,14,3],
[63,44,14,4],
[63,44,14,5],
[63,44,14,6],
[63,44,14,7],
[63,44,14,8],
[63,44,14,10],
[63,44,14,11],
[63,44,14,12],
[63,44,16,0],
[63,44,16,1],
[63,44,16,2],
[63,44,16,3],
[63,44,16,4],
[63,44,16,5],
[63,44,16,6],
[63,44,16,7],
[63,44,16,8],
[63,44,16,10],
[63,44,16,11],
[63,44,16,12],
[63,44,16,13],
[63,44,19,0],
[63,44,19,1],
[63,44,19,2],
[63,44,19,3],
[63,44,19,4],
[63,44,19,5],
[63,44,19,6],
[63,44,19,7],
[63,44,19,8],
[63,44,19,12],
[63,44,19,16],
[63,44,20,0],
[63,44,20,1],
[63,44,20,2],
[63,44,20,3],
[63,44,20,4],
[63,44,20,5],
[63,44,20,6],
[63,44,20,7],
[63,44,20,11],
[63,44,20,12],
[63,44,20,13],
[63,44,20,14],
[63,44,20,16],
[63,44,20,19],
[63,44,21,0],
[63,44,21,1],
[63,44,21,2],
[63,44,21,4],
[63,44,21,10],
[63,44,21,11],
[63,44,21,12],
[63,44,21,13],
[63,44,21,14],
[63,44,21,16],
[63,44,21,19],
[63,44,21,20],
[63,44,22,0],
[63,44,22,1],
[63,44,22,2],
[63,44,22,3],
[63,44,22,4],
[63,44,22,6],
[63,44,22,7],
[63,44,22,8],
[63,44,22,11],
[63,44,22,12],
[63,44,22,13],
[63,44,22,14],
[63,44,22,16],
[63,44,22,19],
[63,44,22,21],
[63,44,23,0],
[63,44,23,1],
[63,44,23,2],
[63,44,23,5],
[63,44,23,6],
[63,44,23,7],
[63,44,23,8],
[63,44,23,10],
[63,44,23,11],
[63,44,23,12],
[63,44,23,13],
[63,44,23,14],
[63,44,23,16],
[63,44,23,19],
[63,44,23,20],
[63,44,23,21],
[63,44,24,0],
[63,44,24,1],
[63,44,24,2],
[63,44,24,5],
[63,44,24,6],
[63,44,24,7],
[63,44,24,8],
[63,44,24,10],
[63,44,24,11],
[63,44,24,12],
[63,44,24,13],
[63,44,24,14],
[63,44,24,19],
[63,44,24,20],
[63,44,24,21],
[63,44,25,0],
[63,44,25,1],
[63,44,25,2],
[63,44,25,3],
[63,44,25,4],
[63,44,25,5],
[63,44,25,6],
[63,44,25,8],
[63,44,25,10],
[63,44,25,11],
[63,44,25,12],
[63,44,25,13],
[63,44,25,14],
[63,44,25,16],
[63,44,25,19],
[63,44,27,0],
[63,44,27,1],
[63,44,27,2],
[63,44,27,3],
[63,44,27,4],
[63,44,27,5],
[63,44,27,6],
[63,44,27,7],
[63,44,27,8],
[63,44,27,11],
[63,44,27,12],
[63,44,27,13],
[63,44,27,14],
[63,44,27,16],
[63,44,28,0],
[63,44,28,1],
[63,44,28,2],
[63,44,28,3],
[63,44,28,4],
[63,44,28,5],
[63,44,28,6],
[63,44,28,8],
[63,44,28,10],
[63,44,28,11],
[63,44,28,12],
[63,44,28,13],
[63,44,28,14],
[63,44,29,0],
[63,44,29,1],
[63,44,29,2],
[63,44,29,3],
[63,44,29,4],
[63,44,29,5],
[63,44,29,6],
[63,44,29,7],
[63,44,29,8],
[63,44,29,10],
[63,44,29,13],
[63,44,29,14],
[63,44,30,0],
[63,44,30,1],
[63,44,30,2],
[63,44,30,3],
[63,44,30,4],
[63,44,30,5],
[63,44,30,6],
[63,44,30,7],
[63,44,30,8],
[63,44,30,10],
[63,44,30,11],
[63,44,30,12],
[63,44,30,13],
[63,44,31,0],
[63,44,31,1],
[63,44,31,2],
[63,44,31,3],
[63,44,31,4],
[63,44,31,5],
[63,44,31,6],
[63,44,31,7],
[63,44,31,8],
[63,44,31,10],
[63,44,31,11],
[63,44,32,0],
[63,44,32,1],
[63,44,32,2],
[63,44,32,3],
[63,44,32,4],
[63,44,32,5],
[63,44,32,6],
[63,44,32,7],
[63,44,32,8],
[63,44,33,0],
[63,44,33,1],
[63,44,33,2],
[63,44,33,3],
[63,44,33,4],
[63,44,33,5],
[63,44,33,6],
[63,44,33,7],
[63,44,34,0],
[63,44,34,1],
[63,44,34,2],
[63,44,34,3],
[63,44,34,4],
[63,44,34,5],
[63,44,36,0],
[63,44,36,1],
[63,44,36,2],
[63,47,3,0],
[63,47,3,1],
[63,47,3,2],
[63,47,4,0],
[63,47,4,1],
[63,47,4,2],
[63,47,4,3],
[63,47,5,0],
[63,47,5,1],
[63,47,5,2],
[63,47,5,4],
[63,47,6,0],
[63,47,6,1],
[63,47,6,2],
[63,47,6,4],
[63,47,7,0],
[63,47,7,1],
[63,47,7,2],
[63,47,7,3],
[63,47,7,4],
[63,47,7,5],
[63,47,7,6],
[63,47,8,0],
[63,47,8,1],
[63,47,8,2],
[63,47,8,4],
[63,47,8,7],
[63,47,10,0],
[63,47,10,1],
[63,47,10,2],
[63,47,10,3],
[63,47,10,4],
[63,47,10,5],
[63,47,10,6],
[63,47,10,7],
[63,47,10,8],
[63,47,11,0],
[63,47,11,1],
[63,47,11,2],
[63,47,11,3],
[63,47,11,4],
[63,47,11,5],
[63,47,11,6],
[63,47,11,7],
[63,47,11,8],
[63,47,11,10],
[63,47,12,0],
[63,47,12,1],
[63,47,12,2],
[63,47,12,3],
[63,47,12,4],
[63,47,12,5],
[63,47,12,6],
[63,47,12,7],
[63,47,12,8],
[63,47,12,10],
[63,47,13,0],
[63,47,13,1],
[63,47,13,2],
[63,47,13,3],
[63,47,13,4],
[63,47,13,5],
[63,47,13,6],
[63,47,13,7],
[63,47,13,8],
[63,47,13,10],
[63,47,13,11],
[63,47,13,12],
[63,47,14,0],
[63,47,14,1],
[63,47,14,2],
[63,47,14,3],
[63,47,14,4],
[63,47,14,5],
[63,47,14,6],
[63,47,14,7],
[63,47,14,8],
[63,47,14,10],
[63,47,14,11],
[63,47,16,0],
[63,47,16,1],
[63,47,16,2],
[63,47,16,3],
[63,47,16,4],
[63,47,16,5],
[63,47,16,6],
[63,47,16,7],
[63,47,16,10],
[63,47,16,11],
[63,47,16,12],
[63,47,16,13],
[63,47,16,14],
[63,47,17,0],
[63,47,17,1],
[63,47,17,2],
[63,47,17,3],
[63,47,17,4],
[63,47,17,5],
[63,47,17,6],
[63,47,17,7],
[63,47,17,10],
[63,47,17,11],
[63,47,17,12],
[63,47,17,13],
[63,47,17,14],
[63,47,17,16],
[63,47,19,0],
[63,47,19,1],
[63,47,19,2],
[63,47,19,3],
[63,47,19,4],
[63,47,19,6],
[63,47,19,7],
[63,47,19,8],
[63,47,19,10],
[63,47,19,11],
[63,47,19,12],
[63,47,19,16],
[63,47,19,17],
[63,47,20,0],
[63,47,20,1],
[63,47,20,2],
[63,47,20,5],
[63,47,20,6],
[63,47,20,7],
[63,47,20,8],
[63,47,20,11],
[63,47,20,12],
[63,47,20,13],
[63,47,20,14],
[63,47,20,16],
[63,47,20,17],
[63,47,20,19],
[63,47,21,4],
[63,47,21,7],
[63,47,21,10],
[63,47,21,11],
[63,47,21,12],
[63,47,21,13],
[63,47,21,14],
[63,47,21,16],
[63,47,21,17],
[63,47,21,19],
[63,47,21,20],
[63,47,22,0],
[63,47,22,1],
[63,47,22,2],
[63,47,22,3],
[63,47,22,4],
[63,47,22,5],
[63,47,22,6],
[63,47,22,7],
[63,47,22,8],
[63,47,22,11],
[63,47,22,12],
[63,47,22,13],
[63,47,22,14],
[63,47,22,16],
[63,47,22,17],
[63,47,22,19],
[63,47,24,0],
[63,47,24,1],
[63,47,24,2],
[63,47,24,3],
[63,47,24,4],
[63,47,24,5],
[63,47,24,6],
[63,47,24,7],
[63,47,24,8],
[63,47,24,10],
[63,47,24,11],
[63,47,24,12],
[63,47,24,13],
[63,47,24,14],
[63,47,24,17],
[63,47,25,0],
[63,47,25,1],
[63,47,25,2],
[63,47,25,3],
[63,47,25,4],
[63,47,25,5],
[63,47,25,6],
[63,47,25,8],
[63,47,25,10],
[63,47,25,11],
[63,47,25,12],
[63,47,25,13],
[63,47,25,14],
[63,47,27,0],
[63,47,27,1],
[63,47,27,2],
[63,47,27,3],
[63,47,27,4],
[63,47,27,5],
[63,47,27,6],
[63,47,27,7],
[63,47,27,8],
[63,47,27,11],
[63,47,27,12],
[63,47,27,13],
[63,47,28,0],
[63,47,28,1],
[63,47,28,2],
[63,47,28,3],
[63,47,28,4],
[63,47,28,5],
[63,47,28,6],
[63,47,28,8],
[63,47,28,10],
[63,47,28,11],
[63,47,29,0],
[63,47,29,1],
[63,47,29,2],
[63,47,29,3],
[63,47,29,4],
[63,47,29,5],
[63,47,29,6],
[63,47,29,7],
[63,47,29,8],
[63,47,32,0],
[63,47,32,1],
[63,47,32,2],
[63,47,32,3],
[63,47,32,4],
[63,47,33,0],
[63,47,33,1],
[63,47,33,2],
[63,48,3,0],
[63,48,3,1],
[63,48,3,2],
[63,48,4,0],
[63,48,4,1],
[63,48,4,2],
[63,48,4,3],
[63,48,5,0],
[63,48,5,1],
[63,48,5,2],
[63,48,5,4],
[63,48,6,0],
[63,48,6,1],
[63,48,6,2],
[63,48,6,4],
[63,48,7,0],
[63,48,7,1],
[63,48,7,2],
[63,48,7,3],
[63,48,7,4],
[63,48,7,5],
[63,48,7,6],
[63,48,8,0],
[63,48,8,1],
[63,48,8,2],
[63,48,8,4],
[63,48,8,7],
[63,48,10,0],
[63,48,10,1],
[63,48,10,2],
[63,48,10,3],
[63,48,10,4],
[63,48,10,5],
[63,48,10,6],
[63,48,10,7],
[63,48,10,8],
[63,48,13,0],
[63,48,13,1],
[63,48,13,2],
[63,48,13,3],
[63,48,13,4],
[63,48,13,5],
[63,48,13,6],
[63,48,13,7],
[63,48,13,8],
[63,48,13,10],
[63,48,14,0],
[63,48,14,1],
[63,48,14,2],
[63,48,14,3],
[63,48,14,4],
[63,48,14,5],
[63,48,14,6],
[63,48,14,7],
[63,48,14,8],
[63,48,16,0],
[63,48,16,1],
[63,48,16,2],
[63,48,16,3],
[63,48,16,4],
[63,48,16,5],
[63,48,16,8],
[63,48,16,10],
[63,48,16,13],
[63,48,16,14],
[63,48,17,0],
[63,48,17,1],
[63,48,17,2],
[63,48,17,3],
[63,48,17,4],
[63,48,17,5],
[63,48,17,8],
[63,48,17,10],
[63,48,17,13],
[63,48,17,14],
[63,48,17,16],
[63,48,19,0],
[63,48,19,1],
[63,48,19,2],
[63,48,19,5],
[63,48,19,6],
[63,48,19,7],
[63,48,19,8],
[63,48,19,10],
[63,48,19,16],
[63,48,19,17],
[63,48,20,3],
[63,48,20,4],
[63,48,20,5],
[63,48,20,6],
[63,48,20,7],
[63,48,20,8],
[63,48,20,13],
[63,48,20,14],
[63,48,20,16],
[63,48,20,17],
[63,48,20,19],
[63,48,21,0],
[63,48,21,1],
[63,48,21,2],
[63,48,21,4],
[63,48,21,7],
[63,48,21,10],
[63,48,21,13],
[63,48,21,14],
[63,48,21,16],
[63,48,21,17],
[63,48,21,19],
[63,48,22,0],
[63,48,22,1],
[63,48,22,2],
[63,48,22,3],
[63,48,22,4],
[63,48,22,5],
[63,48,22,6],
[63,48,22,7],
[63,48,22,8],
[63,48,22,13],
[63,48,22,14],
[63,48,22,16],
[63,48,22,17],
[63,48,23,0],
[63,48,23,1],
[63,48,23,2],
[63,48,23,3],
[63,48,23,4],
[63,48,23,5],
[63,48,23,6],
[63,48,23,7],
[63,48,23,8],
[63,48,23,10],
[63,48,23,13],
[63,48,23,14],
[63,48,23,16],
[63,48,23,17],
[63,48,24,0],
[63,48,24,1],
[63,48,24,2],
[63,48,24,3],
[63,48,24,4],
[63,48,24,5],
[63,48,24,6],
[63,48,24,7],
[63,48,24,8],
[63,48,24,10],
[63,48,24,13],
[63,48,24,14],
[63,48,24,17],
[63,48,25,0],
[63,48,25,1],
[63,48,25,2],
[63,48,25,3],
[63,48,25,4],
[63,48,25,5],
[63,48,25,6],
[63,48,25,8],
[63,48,25,10],
[63,48,25,13],
[63,48,25,14],
[63,48,27,0],
[63,48,27,1],
[63,48,27,2],
[63,48,27,3],
[63,48,27,4],
[63,48,27,5],
[63,48,27,6],
[63,48,27,7],
[63,48,27,8],
[63,48,28,0],
[63,48,28,1],
[63,48,28,2],
[63,48,28,3],
[63,48,28,4],
[63,48,28,5],
[63,48,28,6],
[63,48,28,8],
[63,48,30,0],
[63,48,30,1],
[63,48,30,2],
[63,48,30,3],
[63,48,30,4],
[63,48,30,5],
[63,48,31,0],
[63,48,31,1],
[63,48,31,2],
[63,48,31,3],
[63,48,31,4],
[63,51,4,0],
[63,51,4,1],
[63,51,4,2],
[63,51,7,0],
[63,51,7,1],
[63,51,7,2],
[63,51,7,4],
[63,51,10,0],
[63,51,10,1],
[63,51,10,2],
[63,51,10,4],
[63,51,10,7],
[63,51,11,0],
[63,51,11,1],
[63,51,11,2],
[63,51,11,4],
[63,51,11,7],
[63,51,11,10],
[63,51,12,0],
[63,51,12,1],
[63,51,12,2],
[63,51,12,4],
[63,51,12,7],
[63,51,12,10],
[63,51,13,0],
[63,51,13,1],
[63,51,13,2],
[63,51,13,4],
[63,51,13,7],
[63,51,13,10],
[63,51,13,11],
[63,51,14,0],
[63,51,14,1],
[63,51,14,2],
[63,51,14,4],
[63,51,14,7],
[63,51,14,12],
[63,51,16,0],
[63,51,16,1],
[63,51,16,2],
[63,51,16,4],
[63,51,16,10],
[63,51,16,11],
[63,51,16,12],
[63,51,16,13],
[63,51,16,14],
[63,51,17,0],
[63,51,17,1],
[63,51,17,2],
[63,51,17,4],
[63,51,17,10],
[63,51,17,11],
[63,51,17,12],
[63,51,17,13],
[63,51,17,14],
[63,51,17,16],
[63,51,19,0],
[63,51,19,1],
[63,51,19,2],
[63,51,19,7],
[63,51,19,10],
[63,51,19,11],
[63,51,19,12],
[63,51,19,16],
[63,51,19,17],
[63,51,20,4],
[63,51,20,7],
[63,51,20,11],
[63,51,20,12],
[63,51,20,13],
[63,51,20,14],
[63,51,20,16],
[63,51,20,17],
[63,51,20,19],
[63,51,22,0],
[63,51,22,1],
[63,51,22,2],
[63,51,22,4],
[63,51,22,7],
[63,51,22,11],
[63,51,22,12],
[63,51,22,13],
[63,51,22,14],
[63,51,22,16],
[63,51,22,17],
[63,51,23,0],
[63,51,23,1],
[63,51,23,2],
[63,51,23,4],
[63,51,23,7],
[63,51,23,10],
[63,51,23,11],
[63,51,23,12],
[63,51,23,13],
[63,51,23,14],
[63,51,23,16],
[63,51,23,17],
[63,51,24,0],
[63,51,24,1],
[63,51,24,2],
[63,51,24,4],
[63,51,24,7],
[63,51,24,10],
[63,51,24,11],
[63,51,24,12],
[63,51,24,13],
[63,51,24,14],
[63,51,24,17],
[63,51,25,0],
[63,51,25,1],
[63,51,25,2],
[63,51,25,4],
[63,51,25,10],
[63,51,25,11],
[63,51,25,12],
[63,51,25,13],
[63,51,25,14],
[63,51,27,0],
[63,51,27,1],
[63,51,27,2],
[63,51,27,4],
[63,51,27,7],
[63,51,27,11],
[63,51,28,0],
[63,51,28,1],
[63,51,28,2],
[63,51,28,4],
[63,51,29,0],
[63,51,29,1],
[63,51,29,2],
[63,51,29,4],
[63,51,29,7],
[63,51,30,0],
[63,51,30,1],
[63,51,30,2],
[63,51,30,4],
[63,51,31,0],
[63,51,31,1],
[63,51,31,2],
[63,51,31,4],
[63,51,32,0],
[63,51,32,1],
[63,51,32,2],
[63,53,3,0],
[63,53,3,1],
[63,53,3,2],
[63,53,4,0],
[63,53,4,1],
[63,53,4,2],
[63,53,4,3],
[63,53,5,0],
[63,53,5,1],
[63,53,5,2],
[63,53,5,4],
[63,53,6,0],
[63,53,6,1],
[63,53,6,2],
[63,53,6,4],
[63,53,7,0],
[63,53,7,1],
[63,53,7,2],
[63,53,7,3],
[63,53,7,4],
[63,53,7,5],
[63,53,7,6],
[63,53,8,0],
[63,53,8,1],
[63,53,8,2],
[63,53,8,4],
[63,53,8,7],
[63,53,11,0],
[63,53,11,1],
[63,53,11,2],
[63,53,11,3],
[63,53,11,4],
[63,53,11,5],
[63,53,11,6],
[63,53,11,7],
[63,53,11,8],
[63,53,12,0],
[63,53,12,1],
[63,53,12,2],
[63,53,12,3],
[63,53,12,4],
[63,53,12,5],
[63,53,12,6],
[63,53,12,7],
[63,53,12,8],
[63,53,13,0],
[63,53,13,1],
[63,53,13,2],
[63,53,13,3],
[63,53,13,4],
[63,53,13,5],
[63,53,13,6],
[63,53,13,7],
[63,53,13,8],
[63,53,13,11],
[63,53,14,0],
[63,53,14,1],
[63,53,14,2],
[63,53,14,3],
[63,53,14,4],
[63,53,14,5],
[63,53,14,6],
[63,53,14,7],
[63,53,14,8],
[63,53,14,12],
[63,53,16,0],
[63,53,16,1],
[63,53,16,2],
[63,53,16,3],
[63,53,16,4],
[63,53,16,5],
[63,53,16,8],
[63,53,16,11],
[63,53,16,12],
[63,53,16,13],
[63,53,16,14],
[63,53,17,0],
[63,53,17,1],
[63,53,17,2],
[63,53,17,3],
[63,53,17,4],
[63,53,17,5],
[63,53,17,8],
[63,53,17,11],
[63,53,17,12],
[63,53,17,13],
[63,53,17,14],
[63,53,17,16],
[63,53,19,0],
[63,53,19,1],
[63,53,19,2],
[63,53,19,5],
[63,53,19,6],
[63,53,19,7],
[63,53,19,8],
[63,53,19,11],
[63,53,19,12],
[63,53,19,16],
[63,53,19,17],
[63,53,21,0],
[63,53,21,1],
[63,53,21,2],
[63,53,21,4],
[63,53,21,7],
[63,53,21,11],
[63,53,21,12],
[63,53,21,13],
[63,53,21,14],
[63,53,21,16],
[63,53,21,17],
[63,53,21,19],
[63,53,23,0],
[63,53,23,1],
[63,53,23,2],
[63,53,23,3],
[63,53,23,4],
[63,53,23,5],
[63,53,23,6],
[63,53,23,7],
[63,53,23,8],
[63,53,23,11],
[63,53,23,12],
[63,53,23,13],
[63,53,23,14],
[63,53,23,16],
[63,53,23,17],
[63,53,24,0],
[63,53,24,1],
[63,53,24,2],
[63,53,24,3],
[63,53,24,4],
[63,53,24,5],
[63,53,24,6],
[63,53,24,7],
[63,53,24,8],
[63,53,24,11],
[63,53,24,12],
[63,53,24,13],
[63,53,24,14],
[63,53,24,17],
[63,53,25,0],
[63,53,25,1],
[63,53,25,2],
[63,53,25,3],
[63,53,25,4],
[63,53,25,5],
[63,53,25,6],
[63,53,25,8],
[63,53,25,11],
[63,53,25,12],
[63,53,25,13],
[63,53,25,14],
[63,53,28,0],
[63,53,28,1],
[63,53,28,2],
[63,53,28,3],
[63,53,28,4],
[63,53,28,5],
[63,53,28,6],
[63,53,28,8],
[63,53,29,0],
[63,53,29,1],
[63,53,29,2],
[63,53,29,3],
[63,53,29,4],
[63,53,29,5],
[63,53,29,6],
[63,53,29,7],
[63,53,30,0],
[63,53,30,1],
[63,53,30,2],
[63,53,30,3],
[63,53,30,4],
[63,53,30,5],
[63,53,31,0],
[63,53,31,1],
[63,53,31,2],
[63,53,31,3],
[63,53,31,4],
[63,53,32,0],
[63,53,32,1],
[63,53,32,2],
[63,54,3,0],
[63,54,3,1],
[63,54,3,2],
[63,54,4,0],
[63,54,4,1],
[63,54,4,2],
[63,54,4,3],
[63,54,5,0],
[63,54,5,1],
[63,54,5,2],
[63,54,5,4],
[63,54,6,0],
[63,54,6,1],
[63,54,6,2],
[63,54,6,4],
[63,54,7,0],
[63,54,7,1],
[63,54,7,2],
[63,54,7,3],
[63,54,7,4],
[63,54,7,5],
[63,54,7,6],
[63,54,8,0],
[63,54,8,1],
[63,54,8,2],
[63,54,8,4],
[63,54,8,7],
[63,54,11,0],
[63,54,11,1],
[63,54,11,2],
[63,54,11,3],
[63,54,11,4],
[63,54,11,5],
[63,54,11,6],
[63,54,11,7],
[63,54,11,8],
[63,54,12,0],
[63,54,12,1],
[63,54,12,2],
[63,54,12,3],
[63,54,12,4],
[63,54,12,5],
[63,54,12,6],
[63,54,12,7],
[63,54,12,8],
[63,54,13,0],
[63,54,13,1],
[63,54,13,2],
[63,54,13,3],
[63,54,13,4],
[63,54,13,5],
[63,54,13,6],
[63,54,13,7],
[63,54,13,8],
[63,54,13,11],
[63,54,14,0],
[63,54,14,1],
[63,54,14,2],
[63,54,14,3],
[63,54,14,4],
[63,54,14,5],
[63,54,14,6],
[63,54,14,7],
[63,54,14,8],
[63,54,14,12],
[63,54,16,0],
[63,54,16,1],
[63,54,16,2],
[63,54,16,3],
[63,54,16,4],
[63,54,16,5],
[63,54,16,8],
[63,54,16,11],
[63,54,16,12],
[63,54,16,13],
[63,54,16,14],
[63,54,17,0],
[63,54,17,1],
[63,54,17,2],
[63,54,17,3],
[63,54,17,4],
[63,54,17,5],
[63,54,17,8],
[63,54,17,11],
[63,54,17,12],
[63,54,17,13],
[63,54,17,14],
[63,54,17,16],
[63,54,19,0],
[63,54,19,1],
[63,54,19,2],
[63,54,19,5],
[63,54,19,6],
[63,54,19,7],
[63,54,19,8],
[63,54,19,11],
[63,54,19,12],
[63,54,19,16],
[63,54,19,17],
[63,54,21,0],
[63,54,21,1],
[63,54,21,2],
[63,54,21,4],
[63,54,21,7],
[63,54,21,11],
[63,54,21,12],
[63,54,21,13],
[63,54,21,14],
[63,54,21,16],
[63,54,21,17],
[63,54,21,19],
[63,54,23,0],
[63,54,23,1],
[63,54,23,2],
[63,54,23,3],
[63,54,23,4],
[63,54,23,5],
[63,54,23,6],
[63,54,23,7],
[63,54,23,8],
[63,54,23,11],
[63,54,23,12],
[63,54,23,13],
[63,54,23,14],
[63,54,23,16],
[63,54,23,17],
[63,54,24,0],
[63,54,24,1],
[63,54,24,2],
[63,54,24,3],
[63,54,24,4],
[63,54,24,5],
[63,54,24,6],
[63,54,24,7],
[63,54,24,8],
[63,54,24,11],
[63,54,24,12],
[63,54,24,13],
[63,54,24,14],
[63,54,24,17],
[63,54,25,0],
[63,54,25,1],
[63,54,25,2],
[63,54,25,3],
[63,54,25,4],
[63,54,25,5],
[63,54,25,6],
[63,54,25,8],
[63,54,25,11],
[63,54,25,12],
[63,54,25,13],
[63,54,25,14],
[63,54,28,0],
[63,54,28,1],
[63,54,28,2],
[63,54,28,3],
[63,54,28,4],
[63,54,28,5],
[63,54,28,6],
[63,54,28,8],
[63,54,29,0],
[63,54,29,1],
[63,54,29,2],
[63,54,29,3],
[63,54,29,4],
[63,54,29,5],
[63,54,29,6],
[63,54,29,7],
[63,54,30,0],
[63,54,30,1],
[63,54,30,2],
[63,54,30,3],
[63,54,30,4],
[63,54,30,5],
[63,54,31,0],
[63,54,31,1],
[63,54,31,2],
[63,54,31,3],
[63,54,31,4],
[63,54,32,0],
[63,54,32,1],
[63,54,32,2],
[63,55,3,0],
[63,55,3,1],
[63,55,3,2],
[63,55,4,0],
[63,55,4,1],
[63,55,4,2],
[63,55,4,3],
[63,55,5,0],
[63,55,5,1],
[63,55,5,2],
[63,55,5,4],
[63,55,6,0],
[63,55,6,1],
[63,55,6,2],
[63,55,6,4],
[63,55,7,0],
[63,55,7,1],
[63,55,7,2],
[63,55,7,3],
[63,55,7,4],
[63,55,7,5],
[63,55,7,6],
[63,55,8,0],
[63,55,8,1],
[63,55,8,2],
[63,55,8,4],
[63,55,8,7],
[63,55,11,0],
[63,55,11,1],
[63,55,11,2],
[63,55,11,3],
[63,55,11,4],
[63,55,11,5],
[63,55,11,6],
[63,55,11,7],
[63,55,11,8],
[63,55,12,0],
[63,55,12,1],
[63,55,12,2],
[63,55,12,3],
[63,55,12,4],
[63,55,12,5],
[63,55,12,6],
[63,55,12,7],
[63,55,12,8],
[63,55,13,0],
[63,55,13,1],
[63,55,13,2],
[63,55,13,3],
[63,55,13,4],
[63,55,13,5],
[63,55,13,6],
[63,55,13,7],
[63,55,13,8],
[63,55,13,11],
[63,55,14,0],
[63,55,14,1],
[63,55,14,2],
[63,55,14,3],
[63,55,14,4],
[63,55,14,5],
[63,55,14,6],
[63,55,14,7],
[63,55,14,8],
[63,55,14,12],
[63,55,16,0],
[63,55,16,1],
[63,55,16,2],
[63,55,16,3],
[63,55,16,4],
[63,55,16,5],
[63,55,16,8],
[63,55,16,11],
[63,55,16,12],
[63,55,16,13],
[63,55,16,14],
[63,55,17,0],
[63,55,17,1],
[63,55,17,2],
[63,55,17,3],
[63,55,17,4],
[63,55,17,5],
[63,55,17,8],
[63,55,17,11],
[63,55,17,12],
[63,55,17,13],
[63,55,17,14],
[63,55,17,16],
[63,55,19,0],
[63,55,19,1],
[63,55,19,2],
[63,55,19,5],
[63,55,19,6],
[63,55,19,7],
[63,55,19,8],
[63,55,19,11],
[63,55,19,12],
[63,55,19,16],
[63,55,19,17],
[63,55,21,0],
[63,55,21,1],
[63,55,21,2],
[63,55,21,4],
[63,55,21,7],
[63,55,21,11],
[63,55,21,12],
[63,55,21,13],
[63,55,21,14],
[63,55,21,16],
[63,55,21,17],
[63,55,21,19],
[63,55,23,0],
[63,55,23,1],
[63,55,23,2],
[63,55,23,3],
[63,55,23,4],
[63,55,23,5],
[63,55,23,6],
[63,55,23,7],
[63,55,23,8],
[63,55,23,11],
[63,55,23,12],
[63,55,23,13],
[63,55,23,14],
[63,55,23,16],
[63,55,23,17],
[63,55,24,0],
[63,55,24,1],
[63,55,24,2],
[63,55,24,3],
[63,55,24,4],
[63,55,24,5],
[63,55,24,6],
[63,55,24,7],
[63,55,24,8],
[63,55,24,11],
[63,55,24,12],
[63,55,24,13],
[63,55,24,14],
[63,55,24,17],
[63,55,25,0],
[63,55,25,1],
[63,55,25,2],
[63,55,25,3],
[63,55,25,4],
[63,55,25,5],
[63,55,25,6],
[63,55,25,8],
[63,55,25,11],
[63,55,25,12],
[63,55,25,13],
[63,55,25,14],
[63,55,28,0],
[63,55,28,1],
[63,55,28,2],
[63,55,28,3],
[63,55,28,4],
[63,55,28,5],
[63,55,28,6],
[63,55,28,8],
[63,55,29,0],
[63,55,29,1],
[63,55,29,2],
[63,55,29,3],
[63,55,29,4],
[63,55,29,5],
[63,55,29,6],
[63,55,29,7],
[63,55,30,0],
[63,55,30,1],
[63,55,30,2],
[63,55,30,3],
[63,55,30,4],
[63,55,30,5],
[63,55,31,0],
[63,55,31,1],
[63,55,31,2],
[63,55,31,3],
[63,55,31,4],
[63,55,32,0],
[63,55,32,1],
[63,55,32,2],
[63,57,3,0],
[63,57,3,1],
[63,57,3,2],
[63,57,4,0],
[63,57,4,1],
[63,57,4,2],
[63,57,4,3],
[63,57,5,0],
[63,57,5,1],
[63,57,5,2],
[63,57,5,4],
[63,57,6,0],
[63,57,6,1],
[63,57,6,2],
[63,57,6,4],
[63,57,7,0],
[63,57,7,1],
[63,57,7,2],
[63,57,7,3],
[63,57,7,4],
[63,57,7,5],
[63,57,7,6],
[63,57,8,0],
[63,57,8,1],
[63,57,8,2],
[63,57,8,4],
[63,57,8,7],
[63,57,10,0],
[63,57,10,1],
[63,57,10,2],
[63,57,10,3],
[63,57,10,4],
[63,57,10,5],
[63,57,10,6],
[63,57,10,7],
[63,57,10,8],
[63,57,11,0],
[63,57,11,1],
[63,57,11,2],
[63,57,11,3],
[63,57,11,4],
[63,57,11,5],
[63,57,11,6],
[63,57,11,7],
[63,57,11,8],
[63,57,11,10],
[63,57,12,0],
[63,57,12,1],
[63,57,12,2],
[63,57,12,3],
[63,57,12,4],
[63,57,12,5],
[63,57,12,6],
[63,57,12,7],
[63,57,12,8],
[63,57,13,0],
[63,57,13,1],
[63,57,13,2],
[63,57,13,3],
[63,57,13,4],
[63,57,13,5],
[63,57,13,6],
[63,57,13,7],
[63,57,13,8],
[63,57,13,12],
[63,57,14,0],
[63,57,14,1],
[63,57,14,2],
[63,57,14,3],
[63,57,14,4],
[63,57,14,5],
[63,57,14,6],
[63,57,14,7],
[63,57,14,10],
[63,57,14,11],
[63,57,14,12],
[63,57,16,0],
[63,57,16,1],
[63,57,16,2],
[63,57,16,3],
[63,57,16,4],
[63,57,16,6],
[63,57,16,7],
[63,57,16,8],
[63,57,16,10],
[63,57,16,11],
[63,57,16,12],
[63,57,16,13],
[63,57,16,14],
[63,57,17,0],
[63,57,17,1],
[63,57,17,2],
[63,57,17,3],
[63,57,17,4],
[63,57,17,6],
[63,57,17,7],
[63,57,17,8],
[63,57,17,10],
[63,57,17,11],
[63,57,17,12],
[63,57,17,13],
[63,57,17,14],
[63,57,17,16],
[63,57,19,3],
[63,57,19,4],
[63,57,19,5],
[63,57,19,6],
[63,57,19,7],
[63,57,19,8],
[63,57,19,10],
[63,57,19,11],
[63,57,19,12],
[63,57,19,16],
[63,57,19,17],
[63,57,20,0],
[63,57,20,1],
[63,57,20,2],
[63,57,20,3],
[63,57,20,4],
[63,57,20,5],
[63,57,20,6],
[63,57,20,7],
[63,57,20,8],
[63,57,20,11],
[63,57,20,12],
[63,57,20,13],
[63,57,20,14],
[63,57,20,16],
[63,57,20,17],
[63,57,20,19],
[63,57,21,0],
[63,57,21,1],
[63,57,21,2],
[63,57,21,4],
[63,57,21,7],
[63,57,21,10],
[63,57,21,11],
[63,57,21,12],
[63,57,21,13],
[63,57,21,14],
[63,57,21,16],
[63,57,21,17],
[63,57,22,0],
[63,57,22,1],
[63,57,22,2],
[63,57,22,3],
[63,57,22,4],
[63,57,22,5],
[63,57,22,6],
[63,57,22,7],
[63,57,22,8],
[63,57,22,11],
[63,57,22,12],
[63,57,22,13],
[63,57,22,14],
[63,57,22,16],
[63,57,22,17],
[63,57,24,0],
[63,57,24,1],
[63,57,24,2],
[63,57,24,3],
[63,57,24,4],
[63,57,24,5],
[63,57,24,6],
[63,57,24,7],
[63,57,24,8],
[63,57,24,10],
[63,57,24,11],
[63,57,24,12],
[63,57,24,13],
[63,57,24,14],
[63,57,25,0],
[63,57,25,1],
[63,57,25,2],
[63,57,25,3],
[63,57,25,4],
[63,57,25,5],
[63,57,25,6],
[63,57,25,8],
[63,57,25,10],
[63,57,25,11],
[63,57,25,12],
[63,57,25,13],
[63,57,27,0],
[63,57,27,1],
[63,57,27,2],
[63,57,27,3],
[63,57,27,4],
[63,57,27,5],
[63,57,27,6],
[63,57,27,7],
[63,57,27,8],
[63,57,28,0],
[63,57,28,1],
[63,57,28,2],
[63,57,28,3],
[63,57,28,4],
[63,57,28,5],
[63,57,28,6],
[63,57,29,0],
[63,57,29,1],
[63,57,29,2],
[63,57,29,3],
[63,57,29,4],
[63,57,29,5],
[63,58,3,0],
[63,58,3,1],
[63,58,3,2],
[63,58,4,0],
[63,58,4,1],
[63,58,4,2],
[63,58,4,3],
[63,58,5,0],
[63,58,5,1],
[63,58,5,2],
[63,58,5,4],
[63,58,6,0],
[63,58,6,1],
[63,58,6,2],
[63,58,6,4],
[63,58,7,0],
[63,58,7,1],
[63,58,7,2],
[63,58,7,3],
[63,58,7,4],
[63,58,7,5],
[63,58,7,6],
[63,58,8,0],
[63,58,8,1],
[63,58,8,2],
[63,58,8,4],
[63,58,8,7],
[63,58,10,0],
[63,58,10,1],
[63,58,10,2],
[63,58,10,3],
[63,58,10,4],
[63,58,10,5],
[63,58,10,6],
[63,58,10,7],
[63,58,10,8],
[63,58,11,0],
[63,58,11,1],
[63,58,11,2],
[63,58,11,3],
[63,58,11,4],
[63,58,11,5],
[63,58,11,6],
[63,58,11,7],
[63,58,11,8],
[63,58,11,10],
[63,58,12,0],
[63,58,12,1],
[63,58,12,2],
[63,58,12,3],
[63,58,12,4],
[63,58,12,5],
[63,58,12,6],
[63,58,12,7],
[63,58,12,8],
[63,58,13,0],
[63,58,13,1],
[63,58,13,2],
[63,58,13,3],
[63,58,13,4],
[63,58,13,5],
[63,58,13,6],
[63,58,13,7],
[63,58,13,8],
[63,58,13,12],
[63,58,14,0],
[63,58,14,1],
[63,58,14,2],
[63,58,14,3],
[63,58,14,4],
[63,58,14,5],
[63,58,14,6],
[63,58,14,7],
[63,58,14,10],
[63,58,14,11],
[63,58,14,12],
[63,58,17,0],
[63,58,17,1],
[63,58,17,2],
[63,58,17,3],
[63,58,17,4],
[63,58,17,6],
[63,58,17,7],
[63,58,17,8],
[63,58,17,10],
[63,58,17,11],
[63,58,17,12],
[63,58,17,13],
[63,58,17,14],
[63,58,19,3],
[63,58,19,4],
[63,58,19,5],
[63,58,19,6],
[63,58,19,7],
[63,58,19,8],
[63,58,19,10],
[63,58,19,11],
[63,58,19,12],
[63,58,19,17],
[63,58,20,0],
[63,58,20,1],
[63,58,20,2],
[63,58,20,3],
[63,58,20,4],
[63,58,20,5],
[63,58,20,6],
[63,58,20,7],
[63,58,20,8],
[63,58,20,11],
[63,58,20,12],
[63,58,20,13],
[63,58,20,14],
[63,58,20,17],
[63,58,20,19],
[63,58,21,0],
[63,58,21,1],
[63,58,21,2],
[63,58,21,4],
[63,58,21,7],
[63,58,21,10],
[63,58,21,11],
[63,58,21,12],
[63,58,21,13],
[63,58,21,14],
[63,58,21,17],
[63,58,22,0],
[63,58,22,1],
[63,58,22,2],
[63,58,22,3],
[63,58,22,4],
[63,58,22,5],
[63,58,22,6],
[63,58,22,7],
[63,58,22,8],
[63,58,22,11],
[63,58,22,12],
[63,58,22,13],
[63,58,22,14],
[63,58,22,17],
[63,58,23,0],
[63,58,23,1],
[63,58,23,2],
[63,58,23,3],
[63,58,23,4],
[63,58,23,5],
[63,58,23,6],
[63,58,23,7],
[63,58,23,8],
[63,58,23,10],
[63,58,23,11],
[63,58,23,12],
[63,58,23,13],
[63,58,23,14],
[63,58,25,0],
[63,58,25,1],
[63,58,25,2],
[63,58,25,3],
[63,58,25,4],
[63,58,25,5],
[63,58,25,6],
[63,58,25,8],
[63,58,25,10],
[63,58,25,11],
[63,58,25,12],
[63,58,25,13],
[63,58,27,0],
[63,58,27,1],
[63,58,27,2],
[63,58,27,3],
[63,58,27,4],
[63,58,27,5],
[63,58,27,6],
[63,58,27,7],
[63,58,27,8],
[63,58,28,0],
[63,58,28,1],
[63,58,28,2],
[63,58,28,3],
[63,58,28,4],
[63,58,28,5],
[63,58,28,6],
[63,58,29,0],
[63,58,29,1],
[63,58,29,2],
[63,58,29,3],
[63,58,29,4],
[63,58,29,5],
[63,58,30,0],
[63,58,30,1],
[63,58,30,2],
[63,58,30,3],
[63,58,30,4],
[63,58,31,0],
[63,58,31,1],
[63,58,31,2],
[63,60,3,0],
[63,60,3,1],
[63,60,3,2],
[63,60,4,0],
[63,60,4,1],
[63,60,4,2],
[63,60,4,3],
[63,60,5,0],
[63,60,5,1],
[63,60,5,2],
[63,60,5,4],
[63,60,6,0],
[63,60,6,1],
[63,60,6,2],
[63,60,6,4],
[63,60,7,0],
[63,60,7,1],
[63,60,7,2],
[63,60,7,3],
[63,60,7,4],
[63,60,7,5],
[63,60,7,6],
[63,60,8,0],
[63,60,8,1],
[63,60,8,2],
[63,60,8,4],
[63,60,8,7],
[63,60,10,0],
[63,60,10,1],
[63,60,10,2],
[63,60,10,3],
[63,60,10,4],
[63,60,10,5],
[63,60,10,6],
[63,60,10,7],
[63,60,11,0],
[63,60,11,1],
[63,60,11,2],
[63,60,11,3],
[63,60,11,4],
[63,60,11,5],
[63,60,11,6],
[63,60,11,7],
[63,60,11,10],
[63,60,12,0],
[63,60,12,1],
[63,60,12,2],
[63,60,12,3],
[63,60,12,4],
[63,60,12,5],
[63,60,12,8],
[63,60,12,10],
[63,60,16,3],
[63,60,16,4],
[63,60,16,5],
[63,60,16,6],
[63,60,16,7],
[63,60,16,8],
[63,60,16,10],
[63,60,16,11],
[63,60,16,12],
[63,60,17,3],
[63,60,17,4],
[63,60,17,5],
[63,60,17,6],
[63,60,17,7],
[63,60,17,8],
[63,60,17,10],
[63,60,17,11],
[63,60,17,12],
[63,60,17,16],
[63,60,20,0],
[63,60,20,1],
[63,60,20,2],
[63,60,20,3],
[63,60,20,4],
[63,60,20,5],
[63,60,20,6],
[63,60,20,7],
[63,60,20,8],
[63,60,20,11],
[63,60,20,12],
[63,60,20,16],
[63,60,20,17],
[63,60,21,0],
[63,60,21,1],
[63,60,21,2],
[63,60,21,4],
[63,60,21,7],
[63,60,21,10],
[63,60,21,11],
[63,60,21,12],
[63,60,22,0],
[63,60,22,1],
[63,60,22,2],
[63,60,22,3],
[63,60,22,4],
[63,60,22,5],
[63,60,22,6],
[63,60,22,7],
[63,60,22,8],
[63,60,22,11],
[63,60,22,12],
[63,60,23,0],
[63,60,23,1],
[63,60,23,2],
[63,60,23,3],
[63,60,23,4],
[63,60,23,5],
[63,60,23,6],
[63,60,23,7],
[63,60,23,8],
[63,60,23,10],
[63,60,23,11],
[63,60,23,12],
[63,60,24,0],
[63,60,24,1],
[63,60,24,2],
[63,60,24,3],
[63,60,24,4],
[63,60,24,5],
[63,60,24,6],
[63,60,24,7],
[63,60,24,8],
[63,60,24,10],
[63,60,24,11],
[63,60,24,12],
[63,60,25,0],
[63,60,25,1],
[63,60,25,2],
[63,60,25,3],
[63,60,25,4],
[63,60,25,5],
[63,60,25,6],
[63,60,25,8],
[63,60,27,0],
[63,60,27,1],
[63,60,27,2],
[63,60,27,3],
[63,60,27,4],
[63,60,27,5],
[63,60,28,0],
[63,60,28,1],
[63,60,28,2],
[63,60,28,3],
[63,60,28,4],
[63,60,29,0],
[63,60,29,1],
[63,60,29,2],
[63,61,3,0],
[63,61,3,1],
[63,61,3,2],
[63,61,5,0],
[63,61,5,1],
[63,61,5,2],
[63,61,6,0],
[63,61,6,1],
[63,61,6,2],
[63,61,7,0],
[63,61,7,1],
[63,61,7,2],
[63,61,7,3],
[63,61,7,5],
[63,61,7,6],
[63,61,8,0],
[63,61,8,1],
[63,61,8,2],
[63,61,8,7],
[63,61,10,0],
[63,61,10,1],
[63,61,10,2],
[63,61,10,3],
[63,61,10,5],
[63,61,10,8],
[63,61,11,0],
[63,61,11,1],
[63,61,11,2],
[63,61,11,3],
[63,61,11,5],
[63,61,11,8],
[63,61,11,10],
[63,61,12,0],
[63,61,12,1],
[63,61,12,2],
[63,61,12,3],
[63,61,12,6],
[63,61,12,7],
[63,61,12,8],
[63,61,12,10],
[63,61,13,0],
[63,61,13,1],
[63,61,13,2],
[63,61,13,3],
[63,61,13,6],
[63,61,13,7],
[63,61,13,8],
[63,61,13,10],
[63,61,13,11],
[63,61,13,12],
[63,61,14,0],
[63,61,14,1],
[63,61,14,2],
[63,61,14,5],
[63,61,14,6],
[63,61,14,7],
[63,61,14,8],
[63,61,14,10],
[63,61,14,11],
[63,61,14,12],
[63,61,16,0],
[63,61,16,1],
[63,61,16,2],
[63,61,16,3],
[63,61,16,5],
[63,61,16,6],
[63,61,16,7],
[63,61,16,8],
[63,61,16,10],
[63,61,16,11],
[63,61,16,12],
[63,61,16,13],
[63,61,16,14],
[63,61,17,0],
[63,61,17,1],
[63,61,17,2],
[63,61,17,3],
[63,61,17,5],
[63,61,17,6],
[63,61,17,7],
[63,61,17,8],
[63,61,17,10],
[63,61,17,11],
[63,61,17,12],
[63,61,17,13],
[63,61,17,14],
[63,61,17,16],
[63,61,19,0],
[63,61,19,1],
[63,61,19,2],
[63,61,19,3],
[63,61,19,5],
[63,61,19,6],
[63,61,19,7],
[63,61,19,8],
[63,61,19,10],
[63,61,19,11],
[63,61,19,12],
[63,61,19,16],
[63,61,19,17],
[63,61,20,0],
[63,61,20,1],
[63,61,20,2],
[63,61,20,3],
[63,61,20,5],
[63,61,20,6],
[63,61,20,7],
[63,61,20,8],
[63,61,20,11],
[63,61,20,12],
[63,61,20,13],
[63,61,20,14],
[63,61,21,0],
[63,61,21,1],
[63,61,21,2],
[63,61,21,7],
[63,61,21,10],
[63,61,21,11],
[63,61,21,12],
[63,61,21,13],
[63,61,21,14],
[63,61,22,0],
[63,61,22,1],
[63,61,22,2],
[63,61,22,3],
[63,61,22,5],
[63,61,22,6],
[63,61,22,7],
[63,61,22,8],
[63,61,22,11],
[63,61,22,12],
[63,61,22,13],
[63,61,23,0],
[63,61,23,1],
[63,61,23,2],
[63,61,23,3],
[63,61,23,5],
[63,61,23,6],
[63,61,23,7],
[63,61,23,8],
[63,61,23,10],
[63,61,23,11],
[63,61,24,0],
[63,61,24,1],
[63,61,24,2],
[63,61,24,3],
[63,61,24,5],
[63,61,24,6],
[63,61,24,7],
[63,61,24,8],
[63,61,24,10],
[63,61,24,11],
[63,61,25,0],
[63,61,25,1],
[63,61,25,2],
[63,61,25,3],
[63,61,25,5],
[63,61,25,6],
[63,61,27,0],
[63,61,27,1],
[63,61,27,2],
[63,61,27,3],
[63,61,28,0],
[63,61,28,1],
[63,61,28,2],
[63,62,3,0],
[63,62,3,1],
[63,62,3,2],
[63,62,4,0],
[63,62,4,1],
[63,62,4,2],
[63,62,4,3],
[63,62,5,0],
[63,62,5,1],
[63,62,5,2],
[63,62,5,4],
[63,62,6,0],
[63,62,6,1],
[63,62,6,2],
[63,62,6,4],
[63,62,8,0],
[63,62,8,1],
[63,62,8,2],
[63,62,8,4],
[63,62,10,0],
[63,62,10,1],
[63,62,10,2],
[63,62,10,3],
[63,62,10,4],
[63,62,10,5],
[63,62,10,8],
[63,62,11,0],
[63,62,11,1],
[63,62,11,2],
[63,62,11,3],
[63,62,11,4],
[63,62,11,5],
[63,62,11,8],
[63,62,11,10],
[63,62,12,0],
[63,62,12,1],
[63,62,12,2],
[63,62,12,3],
[63,62,12,4],
[63,62,12,6],
[63,62,12,8],
[63,62,12,10],
[63,62,13,0],
[63,62,13,1],
[63,62,13,2],
[63,62,13,3],
[63,62,13,4],
[63,62,13,6],
[63,62,13,8],
[63,62,13,10],
[63,62,13,11],
[63,62,13,12],
[63,62,14,0],
[63,62,14,1],
[63,62,14,2],
[63,62,14,5],
[63,62,14,6],
[63,62,14,8],
[63,62,14,10],
[63,62,14,11],
[63,62,14,12],
[63,62,16,0],
[63,62,16,1],
[63,62,16,2],
[63,62,16,3],
[63,62,16,4],
[63,62,16,5],
[63,62,16,6],
[63,62,16,8],
[63,62,16,10],
[63,62,16,11],
[63,62,16,12],
[63,62,16,13],
[63,62,16,14],
[63,62,17,0],
[63,62,17,1],
[63,62,17,2],
[63,62,17,3],
[63,62,17,4],
[63,62,17,5],
[63,62,17,6],
[63,62,17,8],
[63,62,17,10],
[63,62,17,11],
[63,62,17,12],
[63,62,17,13],
[63,62,17,14],
[63,62,17,16],
[63,62,19,0],
[63,62,19,1],
[63,62,19,2],
[63,62,19,3],
[63,62,19,4],
[63,62,19,5],
[63,62,19,6],
[63,62,19,8],
[63,62,19,10],
[63,62,19,11],
[63,62,19,12],
[63,62,19,16],
[63,62,19,17],
[63,62,20,0],
[63,62,20,1],
[63,62,20,2],
[63,62,20,3],
[63,62,20,4],
[63,62,20,5],
[63,62,20,6],
[63,62,20,8],
[63,62,20,11],
[63,62,20,12],
[63,62,20,13],
[63,62,20,14],
[63,62,21,0],
[63,62,21,1],
[63,62,21,2],
[63,62,21,4],
[63,62,21,10],
[63,62,21,11],
[63,62,21,12],
[63,62,21,13],
[63,62,21,14],
[63,62,22,0],
[63,62,22,1],
[63,62,22,2],
[63,62,22,3],
[63,62,22,4],
[63,62,22,5],
[63,62,22,6],
[63,62,22,8],
[63,62,22,11],
[63,62,22,12],
[63,62,22,13],
[63,62,23,0],
[63,62,23,1],
[63,62,23,2],
[63,62,23,3],
[63,62,23,4],
[63,62,23,5],
[63,62,23,6],
[63,62,23,8],
[63,62,23,10],
[63,62,23,11],
[63,62,24,0],
[63,62,24,1],
[63,62,24,2],
[63,62,24,3],
[63,62,24,4],
[63,62,24,5],
[63,62,24,6],
[63,62,24,8],
[63,62,24,10],
[63,62,24,11],
[63,62,27,0],
[63,62,27,1],
[63,62,27,2],
[63,62,27,3],
[63,62,27,4],
[64,7,3,0],
[64,7,3,1],
[64,7,3,2],
[64,7,5,0],
[64,7,5,1],
[64,7,5,2],
[64,7,6,0],
[64,7,6,1],
[64,7,6,2],
[64,8,7,0],
[64,8,7,1],
[64,8,7,2],
[64,9,3,0],
[64,9,3,1],
[64,9,3,2],
[64,9,5,0],
[64,9,5,1],
[64,9,5,2],
[64,9,6,0],
[64,9,6,1],
[64,9,6,2],
[64,9,7,0],
[64,9,7,1],
[64,9,7,2],
[64,9,7,3],
[64,9,7,5],
[64,9,7,6],
[64,9,8,0],
[64,9,8,1],
[64,9,8,2],
[64,9,8,7],
[64,10,3,0],
[64,10,3,1],
[64,10,3,2],
[64,10,5,0],
[64,10,5,1],
[64,10,5,2],
[64,10,6,0],
[64,10,6,1],
[64,10,6,2],
[64,10,7,0],
[64,10,7,1],
[64,10,7,2],
[64,10,7,3],
[64,10,7,5],
[64,10,7,6],
[64,10,8,0],
[64,10,8,1],
[64,10,8,2],
[64,10,8,7],
[64,10,9,0],
[64,10,9,1],
[64,10,9,2],
[64,10,9,3],
[64,10,9,5],
[64,10,9,6],
[64,10,9,7],
[64,10,9,8],
[64,11,3,0],
[64,11,3,1],
[64,11,3,2],
[64,11,5,0],
[64,11,5,1],
[64,11,5,2],
[64,11,6,0],
[64,11,6,1],
[64,11,6,2],
[64,11,7,0],
[64,11,7,1],
[64,11,7,2],
[64,11,7,3],
[64,11,7,5],
[64,11,7,6],
[64,11,8,0],
[64,11,8,1],
[64,11,8,2],
[64,11,8,7],
[64,11,9,0],
[64,11,9,1],
[64,11,9,2],
[64,11,9,3],
[64,11,9,5],
[64,11,9,6],
[64,11,9,7],
[64,11,9,8],
[64,11,10,0],
[64,11,10,1],
[64,11,10,2],
[64,11,10,3],
[64,11,10,5],
[64,11,10,6],
[64,11,10,7],
[64,11,10,8],
[64,11,10,9],
[64,12,3,0],
[64,12,3,1],
[64,12,3,2],
[64,12,5,0],
[64,12,5,1],
[64,12,5,2],
[64,12,6,0],
[64,12,6,1],
[64,12,6,2],
[64,12,7,0],
[64,12,7,1],
[64,12,7,2],
[64,12,7,3],
[64,12,7,5],
[64,12,7,6],
[64,12,8,0],
[64,12,8,1],
[64,12,8,2],
[64,12,8,7],
[64,12,9,0],
[64,12,9,1],
[64,12,9,2],
[64,12,9,3],
[64,12,9,5],
[64,12,9,6],
[64,12,9,7],
[64,12,9,8],
[64,12,10,0],
[64,12,10,1],
[64,12,10,2],
[64,12,10,3],
[64,12,10,5],
[64,12,10,6],
[64,12,10,7],
[64,12,10,8],
[64,12,10,9],
[64,13,3,0],
[64,13,3,1],
[64,13,3,2],
[64,13,5,0],
[64,13,5,1],
[64,13,5,2],
[64,13,6,0],
[64,13,6,1],
[64,13,6,2],
[64,13,7,0],
[64,13,7,1],
[64,13,7,2],
[64,13,7,3],
[64,13,7,5],
[64,13,7,6],
[64,13,8,0],
[64,13,8,1],
[64,13,8,2],
[64,13,8,7],
[64,13,9,0],
[64,13,9,1],
[64,13,9,2],
[64,13,9,3],
[64,13,9,5],
[64,13,9,6],
[64,13,9,7],
[64,13,9,8],
[64,13,10,0],
[64,13,10,1],
[64,13,10,2],
[64,13,10,3],
[64,13,10,5],
[64,13,10,6],
[64,13,10,7],
[64,13,10,8],
[64,13,10,9],
[64,13,11,0],
[64,13,11,1],
[64,13,11,2],
[64,13,11,3],
[64,13,11,5],
[64,13,11,6],
[64,13,11,7],
[64,13,11,8],
[64,13,11,9],
[64,13,11,10],
[64,13,12,0],
[64,13,12,1],
[64,13,12,2],
[64,13,12,3],
[64,13,12,5],
[64,13,12,6],
[64,13,12,7],
[64,13,12,8],
[64,13,12,9],
[64,13,12,10],
[64,14,3,0],
[64,14,3,1],
[64,14,3,2],
[64,14,5,0],
[64,14,5,1],
[64,14,5,2],
[64,14,6,0],
[64,14,6,1],
[64,14,6,2],
[64,14,7,0],
[64,14,7,1],
[64,14,7,2],
[64,14,7,3],
[64,14,7,5],
[64,14,7,6],
[64,14,8,0],
[64,14,8,1],
[64,14,8,2],
[64,14,8,7],
[64,14,9,0],
[64,14,9,1],
[64,14,9,2],
[64,14,9,3],
[64,14,9,5],
[64,14,9,6],
[64,14,9,7],
[64,14,9,8],
[64,14,10,0],
[64,14,10,1],
[64,14,10,2],
[64,14,10,3],
[64,14,10,5],
[64,14,10,6],
[64,14,10,7],
[64,14,10,8],
[64,14,10,9],
[64,14,11,0],
[64,14,11,1],
[64,14,11,2],
[64,14,11,3],
[64,14,11,5],
[64,14,11,6],
[64,14,11,7],
[64,14,11,8],
[64,14,11,9],
[64,14,11,10],
[64,14,12,0],
[64,14,12,1],
[64,14,12,2],
[64,14,12,3],
[64,14,12,5],
[64,14,12,6],
[64,14,12,7],
[64,14,12,8],
[64,14,12,9],
[64,14,12,10],
[64,15,3,0],
[64,15,3,1],
[64,15,3,2],
[64,15,5,0],
[64,15,5,1],
[64,15,5,2],
[64,15,6,0],
[64,15,6,1],
[64,15,6,2],
[64,15,7,0],
[64,15,7,1],
[64,15,7,2],
[64,15,7,3],
[64,15,7,5],
[64,15,7,6],
[64,15,8,0],
[64,15,8,1],
[64,15,8,2],
[64,15,8,7],
[64,15,10,0],
[64,15,10,1],
[64,15,10,2],
[64,15,10,3],
[64,15,10,5],
[64,15,10,6],
[64,15,10,7],
[64,15,10,8],
[64,15,11,0],
[64,15,11,1],
[64,15,11,2],
[64,15,11,3],
[64,15,11,5],
[64,15,11,6],
[64,15,11,7],
[64,15,11,8],
[64,15,11,10],
[64,15,12,0],
[64,15,12,1],
[64,15,12,2],
[64,15,12,3],
[64,15,12,5],
[64,15,12,6],
[64,15,12,7],
[64,15,12,8],
[64,15,12,10],
[64,15,13,0],
[64,15,13,1],
[64,15,13,2],
[64,15,13,3],
[64,15,13,5],
[64,15,13,6],
[64,15,13,7],
[64,15,13,8],
[64,15,13,10],
[64,15,13,11],
[64,15,13,12],
[64,15,14,0],
[64,15,14,1],
[64,15,14,2],
[64,15,14,3],
[64,15,14,5],
[64,15,14,6],
[64,15,14,7],
[64,15,14,8],
[64,15,14,10],
[64,15,14,11],
[64,15,14,12],
[64,16,3,0],
[64,16,3,1],
[64,16,3,2],
[64,16,5,0],
[64,16,5,1],
[64,16,5,2],
[64,16,6,0],
[64,16,6,1],
[64,16,6,2],
[64,16,7,0],
[64,16,7,1],
[64,16,7,2],
[64,16,7,3],
[64,16,7,5],
[64,16,7,6],
[64,16,8,0],
[64,16,8,1],
[64,16,8,2],
[64,16,8,7],
[64,16,9,0],
[64,16,9,1],
[64,16,9,2],
[64,16,9,3],
[64,16,9,5],
[64,16,9,6],
[64,16,9,7],
[64,16,9,8],
[64,16,10,0],
[64,16,10,1],
[64,16,10,2],
[64,16,10,3],
[64,16,10,5],
[64,16,10,6],
[64,16,10,7],
[64,16,10,8],
[64,16,10,9],
[64,16,11,0],
[64,16,11,1],
[64,16,11,2],
[64,16,11,3],
[64,16,11,5],
[64,16,11,6],
[64,16,11,7],
[64,16,11,8],
[64,16,11,9],
[64,16,11,10],
[64,16,12,0],
[64,16,12,1],
[64,16,12,2],
[64,16,12,3],
[64,16,12,5],
[64,16,12,6],
[64,16,12,7],
[64,16,12,8],
[64,16,12,9],
[64,16,12,10],
[64,16,13,0],
[64,16,13,1],
[64,16,13,2],
[64,16,13,3],
[64,16,13,5],
[64,16,13,6],
[64,16,13,7],
[64,16,13,8],
[64,16,13,9],
[64,16,13,10],
[64,16,13,11],
[64,16,13,12],
[64,16,14,0],
[64,16,14,1],
[64,16,14,2],
[64,16,14,3],
[64,16,14,5],
[64,16,14,6],
[64,16,14,7],
[64,16,14,8],
[64,16,14,9],
[64,16,14,10],
[64,16,14,11],
[64,16,14,12],
[64,16,15,0],
[64,16,15,1],
[64,16,15,2],
[64,16,15,3],
[64,16,15,5],
[64,16,15,6],
[64,16,15,7],
[64,16,15,8],
[64,16,15,10],
[64,16,15,11],
[64,16,15,12],
[64,16,15,13],
[64,16,15,14],
[64,17,3,0],
[64,17,3,1],
[64,17,3,2],
[64,17,5,0],
[64,17,5,1],
[64,17,5,2],
[64,17,6,0],
[64,17,6,1],
[64,17,6,2],
[64,17,7,0],
[64,17,7,1],
[64,17,7,2],
[64,17,7,3],
[64,17,7,5],
[64,17,7,6],
[64,17,8,0],
[64,17,8,1],
[64,17,8,2],
[64,17,8,7],
[64,17,9,0],
[64,17,9,1],
[64,17,9,2],
[64,17,9,3],
[64,17,9,5],
[64,17,9,6],
[64,17,9,7],
[64,17,9,8],
[64,17,10,0],
[64,17,10,1],
[64,17,10,2],
[64,17,10,3],
[64,17,10,5],
[64,17,10,6],
[64,17,10,7],
[64,17,10,8],
[64,17,10,9],
[64,17,11,0],
[64,17,11,1],
[64,17,11,2],
[64,17,11,3],
[64,17,11,5],
[64,17,11,6],
[64,17,11,7],
[64,17,11,8],
[64,17,11,9],
[64,17,11,10],
[64,17,12,0],
[64,17,12,1],
[64,17,12,2],
[64,17,12,3],
[64,17,12,5],
[64,17,12,6],
[64,17,12,7],
[64,17,12,8],
[64,17,12,9],
[64,17,12,10],
[64,17,13,0],
[64,17,13,1],
[64,17,13,2],
[64,17,13,3],
[64,17,13,5],
[64,17,13,6],
[64,17,13,7],
[64,17,13,8],
[64,17,13,9],
[64,17,13,10],
[64,17,13,11],
[64,17,13,12],
[64,17,14,0],
[64,17,14,1],
[64,17,14,2],
[64,17,14,3],
[64,17,14,5],
[64,17,14,6],
[64,17,14,7],
[64,17,14,8],
[64,17,14,9],
[64,17,14,10],
[64,17,14,11],
[64,17,14,12],
[64,17,15,0],
[64,17,15,1],
[64,17,15,2],
[64,17,15,3],
[64,17,15,5],
[64,17,15,6],
[64,17,15,7],
[64,17,15,8],
[64,17,15,10],
[64,17,15,11],
[64,17,15,12],
[64,17,15,13],
[64,17,15,14],
[64,17,16,0],
[64,17,16,1],
[64,17,16,2],
[64,17,16,3],
[64,17,16,5],
[64,17,16,6],
[64,17,16,7],
[64,17,16,8],
[64,17,16,9],
[64,17,16,10],
[64,17,16,11],
[64,17,16,12],
[64,17,16,13],
[64,17,16,14],
[64,17,16,15],
[64,18,3,0],
[64,18,3,1],
[64,18,3,2],
[64,18,5,0],
[64,18,5,1],
[64,18,5,2],
[64,18,6,0],
[64,18,6,1],
[64,18,6,2],
[64,18,7,0],
[64,18,7,1],
[64,18,7,2],
[64,18,7,3],
[64,18,7,5],
[64,18,7,6],
[64,18,8,0],
[64,18,8,1],
[64,18,8,2],
[64,18,8,7],
[64,18,10,0],
[64,18,10,1],
[64,18,10,2],
[64,18,10,3],
[64,18,10,5],
[64,18,10,6],
[64,18,10,7],
[64,18,10,8],
[64,18,11,0],
[64,18,11,1],
[64,18,11,2],
[64,18,11,3],
[64,18,11,5],
[64,18,11,6],
[64,18,11,7],
[64,18,11,8],
[64,18,11,10],
[64,18,12,0],
[64,18,12,1],
[64,18,12,2],
[64,18,12,3],
[64,18,12,5],
[64,18,12,6],
[64,18,12,7],
[64,18,12,8],
[64,18,12,10],
[64,18,13,0],
[64,18,13,1],
[64,18,13,2],
[64,18,13,3],
[64,18,13,5],
[64,18,13,6],
[64,18,13,7],
[64,18,13,8],
[64,18,13,10],
[64,18,13,11],
[64,18,13,12],
[64,18,14,0],
[64,18,14,1],
[64,18,14,2],
[64,18,14,3],
[64,18,14,5],
[64,18,14,6],
[64,18,14,7],
[64,18,14,8],
[64,18,14,10],
[64,18,14,11],
[64,18,14,12],
[64,18,16,0],
[64,18,16,1],
[64,18,16,2],
[64,18,16,3],
[64,18,16,5],
[64,18,16,6],
[64,18,16,7],
[64,18,16,8],
[64,18,16,10],
[64,18,16,11],
[64,18,16,12],
[64,18,16,13],
[64,18,16,14],
[64,18,17,0],
[64,18,17,1],
[64,18,17,2],
[64,18,17,3],
[64,18,17,5],
[64,18,17,6],
[64,18,17,7],
[64,18,17,8],
[64,18,17,10],
[64,18,17,11],
[64,18,17,12],
[64,18,17,13],
[64,18,17,14],
[64,18,17,16],
[64,19,3,0],
[64,19,3,1],
[64,19,3,2],
[64,19,5,0],
[64,19,5,1],
[64,19,5,2],
[64,19,6,0],
[64,19,6,1],
[64,19,6,2],
[64,19,7,0],
[64,19,7,1],
[64,19,7,2],
[64,19,7,3],
[64,19,7,5],
[64,19,7,6],
[64,19,8,0],
[64,19,8,1],
[64,19,8,2],
[64,19,8,7],
[64,19,9,0],
[64,19,9,1],
[64,19,9,2],
[64,19,9,3],
[64,19,9,5],
[64,19,9,6],
[64,19,9,7],
[64,19,9,8],
[64,19,10,0],
[64,19,10,1],
[64,19,10,2],
[64,19,10,3],
[64,19,10,5],
[64,19,10,6],
[64,19,10,7],
[64,19,10,8],
[64,19,10,9],
[64,19,11,0],
[64,19,11,1],
[64,19,11,2],
[64,19,11,3],
[64,19,11,5],
[64,19,11,6],
[64,19,11,7],
[64,19,11,8],
[64,19,11,9],
[64,19,11,10],
[64,19,12,0],
[64,19,12,1],
[64,19,12,2],
[64,19,12,3],
[64,19,12,5],
[64,19,12,6],
[64,19,12,7],
[64,19,12,8],
[64,19,12,9],
[64,19,12,10],
[64,19,15,0],
[64,19,15,1],
[64,19,15,2],
[64,19,15,3],
[64,19,15,5],
[64,19,15,6],
[64,19,15,7],
[64,19,15,8],
[64,19,15,10],
[64,19,15,11],
[64,19,15,12],
[64,19,16,0],
[64,19,16,1],
[64,19,16,2],
[64,19,16,3],
[64,19,16,5],
[64,19,16,6],
[64,19,16,7],
[64,19,16,8],
[64,19,16,9],
[64,19,16,10],
[64,19,16,11],
[64,19,16,12],
[64,19,16,15],
[64,19,17,0],
[64,19,17,1],
[64,19,17,2],
[64,19,17,3],
[64,19,17,5],
[64,19,17,6],
[64,19,17,7],
[64,19,17,8],
[64,19,17,9],
[64,19,17,10],
[64,19,17,11],
[64,19,17,12],
[64,19,17,15],
[64,19,17,16],
[64,19,18,0],
[64,19,18,1],
[64,19,18,2],
[64,19,18,3],
[64,19,18,5],
[64,19,18,6],
[64,19,18,7],
[64,19,18,8],
[64,19,18,10],
[64,19,18,11],
[64,19,18,12],
[64,19,18,16],
[64,19,18,17],
[64,20,3,0],
[64,20,3,1],
[64,20,3,2],
[64,20,5,0],
[64,20,5,1],
[64,20,5,2],
[64,20,6,0],
[64,20,6,1],
[64,20,6,2],
[64,20,7,0],
[64,20,7,1],
[64,20,7,2],
[64,20,7,3],
[64,20,7,5],
[64,20,7,6],
[64,20,8,0],
[64,20,8,1],
[64,20,8,2],
[64,20,8,7],
[64,20,9,0],
[64,20,9,1],
[64,20,9,2],
[64,20,9,3],
[64,20,9,5],
[64,20,9,6],
[64,20,9,7],
[64,20,9,8],
[64,20,11,0],
[64,20,11,1],
[64,20,11,2],
[64,20,11,3],
[64,20,11,5],
[64,20,11,6],
[64,20,11,7],
[64,20,11,8],
[64,20,11,9],
[64,20,12,0],
[64,20,12,1],
[64,20,12,2],
[64,20,12,3],
[64,20,12,5],
[64,20,12,6],
[64,20,12,7],
[64,20,12,8],
[64,20,12,9],
[64,20,13,0],
[64,20,13,1],
[64,20,13,2],
[64,20,13,3],
[64,20,13,5],
[64,20,13,6],
[64,20,13,7],
[64,20,13,8],
[64,20,13,9],
[64,20,13,11],
[64,20,13,12],
[64,20,14,0],
[64,20,14,1],
[64,20,14,2],
[64,20,14,3],
[64,20,14,5],
[64,20,14,6],
[64,20,14,7],
[64,20,14,8],
[64,20,14,9],
[64,20,14,11],
[64,20,14,12],
[64,20,15,0],
[64,20,15,1],
[64,20,15,2],
[64,20,15,3],
[64,20,15,5],
[64,20,15,6],
[64,20,15,7],
[64,20,15,8],
[64,20,15,11],
[64,20,15,12],
[64,20,15,13],
[64,20,15,14],
[64,20,16,0],
[64,20,16,1],
[64,20,16,2],
[64,20,16,3],
[64,20,16,5],
[64,20,16,6],
[64,20,16,7],
[64,20,16,8],
[64,20,16,9],
[64,20,16,11],
[64,20,16,12],
[64,20,16,13],
[64,20,16,14],
[64,20,16,15],
[64,20,17,0],
[64,20,17,1],
[64,20,17,2],
[64,20,17,3],
[64,20,17,5],
[64,20,17,6],
[64,20,17,7],
[64,20,17,8],
[64,20,17,9],
[64,20,17,11],
[64,20,17,12],
[64,20,17,13],
[64,20,17,14],
[64,20,17,15],
[64,20,17,16],
[64,20,18,0],
[64,20,18,1],
[64,20,18,2],
[64,20,18,3],
[64,20,18,5],
[64,20,18,6],
[64,20,18,7],
[64,20,18,8],
[64,20,18,11],
[64,20,18,12],
[64,20,18,13],
[64,20,18,14],
[64,20,18,16],
[64,20,18,17],
[64,20,19,0],
[64,20,19,1],
[64,20,19,2],
[64,20,19,3],
[64,20,19,5],
[64,20,19,6],
[64,20,19,7],
[64,20,19,8],
[64,20,19,9],
[64,20,19,11],
[64,20,19,12],
[64,20,19,15],
[64,20,19,16],
[64,20,19,17],
[64,20,19,18],
[64,21,7,0],
[64,21,7,1],
[64,21,7,2],
[64,21,9,0],
[64,21,9,1],
[64,21,9,2],
[64,21,9,7],
[64,21,10,0],
[64,21,10,1],
[64,21,10,2],
[64,21,10,7],
[64,21,10,9],
[64,21,11,0],
[64,21,11,1],
[64,21,11,2],
[64,21,11,7],
[64,21,11,9],
[64,21,11,10],
[64,21,12,0],
[64,21,12,1],
[64,21,12,2],
[64,21,12,7],
[64,21,12,9],
[64,21,12,10],
[64,21,13,0],
[64,21,13,1],
[64,21,13,2],
[64,21,13,7],
[64,21,13,9],
[64,21,13,10],
[64,21,13,11],
[64,21,13,12],
[64,21,14,0],
[64,21,14,1],
[64,21,14,2],
[64,21,14,7],
[64,21,14,9],
[64,21,14,10],
[64,21,14,11],
[64,21,14,12],
[64,21,15,0],
[64,21,15,1],
[64,21,15,2],
[64,21,15,7],
[64,21,15,10],
[64,21,15,11],
[64,21,15,12],
[64,21,15,13],
[64,21,15,14],
[64,21,16,0],
[64,21,16,1],
[64,21,16,2],
[64,21,16,7],
[64,21,16,9],
[64,21,16,10],
[64,21,16,11],
[64,21,16,12],
[64,21,16,13],
[64,21,16,14],
[64,21,16,15],
[64,21,17,0],
[64,21,17,1],
[64,21,17,2],
[64,21,17,7],
[64,21,17,9],
[64,21,17,10],
[64,21,17,11],
[64,21,17,12],
[64,21,17,13],
[64,21,17,14],
[64,21,17,15],
[64,21,17,16],
[64,21,18,0],
[64,21,18,1],
[64,21,18,2],
[64,21,18,7],
[64,21,18,10],
[64,21,18,11],
[64,21,18,12],
[64,21,18,13],
[64,21,18,14],
[64,21,18,16],
[64,21,18,17],
[64,21,19,0],
[64,21,19,1],
[64,21,19,2],
[64,21,19,7],
[64,21,19,9],
[64,21,19,10],
[64,21,19,11],
[64,21,19,12],
[64,21,19,15],
[64,21,19,16],
[64,21,19,17],
[64,21,19,18],
[64,21,20,0],
[64,21,20,1],
[64,21,20,2],
[64,21,20,7],
[64,21,20,9],
[64,21,20,11],
[64,21,20,12],
[64,21,20,13],
[64,21,20,14],
[64,21,20,15],
[64,21,20,16],
[64,21,20,17],
[64,21,20,18],
[64,21,20,19],
[64,22,3,0],
[64,22,3,1],
[64,22,3,2],
[64,22,5,0],
[64,22,5,1],
[64,22,5,2],
[64,22,6,0],
[64,22,6,1],
[64,22,6,2],
[64,22,7,0],
[64,22,7,1],
[64,22,7,2],
[64,22,7,3],
[64,22,7,5],
[64,22,7,6],
[64,22,8,0],
[64,22,8,1],
[64,22,8,2],
[64,22,8,7],
[64,22,9,0],
[64,22,9,1],
[64,22,9,2],
[64,22,9,3],
[64,22,9,5],
[64,22,9,6],
[64,22,9,7],
[64,22,9,8],
[64,22,11,0],
[64,22,11,1],
[64,22,11,2],
[64,22,11,3],
[64,22,11,5],
[64,22,11,6],
[64,22,11,7],
[64,22,11,8],
[64,22,11,9],
[64,22,12,0],
[64,22,12,1],
[64,22,12,2],
[64,22,12,3],
[64,22,12,5],
[64,22,12,6],
[64,22,12,7],
[64,22,12,8],
[64,22,12,9],
[64,22,13,0],
[64,22,13,1],
[64,22,13,2],
[64,22,13,3],
[64,22,13,5],
[64,22,13,6],
[64,22,13,7],
[64,22,13,8],
[64,22,13,9],
[64,22,13,11],
[64,22,13,12],
[64,22,14,0],
[64,22,14,1],
[64,22,14,2],
[64,22,14,3],
[64,22,14,5],
[64,22,14,6],
[64,22,14,7],
[64,22,14,8],
[64,22,14,9],
[64,22,14,11],
[64,22,14,12],
[64,22,15,0],
[64,22,15,1],
[64,22,15,2],
[64,22,15,3],
[64,22,15,5],
[64,22,15,6],
[64,22,15,7],
[64,22,15,8],
[64,22,15,11],
[64,22,15,12],
[64,22,15,13],
[64,22,15,14],
[64,22,16,0],
[64,22,16,1],
[64,22,16,2],
[64,22,16,3],
[64,22,16,5],
[64,22,16,6],
[64,22,16,7],
[64,22,16,8],
[64,22,16,9],
[64,22,16,11],
[64,22,16,12],
[64,22,16,13],
[64,22,16,14],
[64,22,16,15],
[64,22,17,0],
[64,22,17,1],
[64,22,17,2],
[64,22,17,3],
[64,22,17,5],
[64,22,17,6],
[64,22,17,7],
[64,22,17,8],
[64,22,17,9],
[64,22,17,11],
[64,22,17,12],
[64,22,17,13],
[64,22,17,14],
[64,22,17,15],
[64,22,17,16],
[64,22,18,0],
[64,22,18,1],
[64,22,18,2],
[64,22,18,3],
[64,22,18,5],
[64,22,18,6],
[64,22,18,7],
[64,22,18,8],
[64,22,18,11],
[64,22,18,12],
[64,22,18,13],
[64,22,18,14],
[64,22,18,16],
[64,22,18,17],
[64,22,19,0],
[64,22,19,1],
[64,22,19,2],
[64,22,19,3],
[64,22,19,5],
[64,22,19,6],
[64,22,19,7],
[64,22,19,8],
[64,22,19,9],
[64,22,19,11],
[64,22,19,12],
[64,22,19,15],
[64,22,19,16],
[64,22,19,17],
[64,22,19,18],
[64,22,21,0],
[64,22,21,1],
[64,22,21,2],
[64,22,21,7],
[64,22,21,9],
[64,22,21,11],
[64,22,21,12],
[64,22,21,13],
[64,22,21,14],
[64,22,21,15],
[64,22,21,16],
[64,22,21,17],
[64,22,21,18],
[64,22,21,19],
[64,23,3,0],
[64,23,3,1],
[64,23,3,2],
[64,23,5,0],
[64,23,5,1],
[64,23,5,2],
[64,23,6,0],
[64,23,6,1],
[64,23,6,2],
[64,23,7,0],
[64,23,7,1],
[64,23,7,2],
[64,23,7,3],
[64,23,7,5],
[64,23,7,6],
[64,23,8,0],
[64,23,8,1],
[64,23,8,2],
[64,23,8,7],
[64,23,9,0],
[64,23,9,1],
[64,23,9,2],
[64,23,9,3],
[64,23,9,5],
[64,23,9,6],
[64,23,9,7],
[64,23,9,8],
[64,23,10,0],
[64,23,10,1],
[64,23,10,2],
[64,23,10,3],
[64,23,10,5],
[64,23,10,6],
[64,23,10,7],
[64,23,10,8],
[64,23,10,9],
[64,23,11,0],
[64,23,11,1],
[64,23,11,2],
[64,23,11,3],
[64,23,11,5],
[64,23,11,6],
[64,23,11,7],
[64,23,11,8],
[64,23,11,9],
[64,23,11,10],
[64,23,12,0],
[64,23,12,1],
[64,23,12,2],
[64,23,12,3],
[64,23,12,5],
[64,23,12,6],
[64,23,12,7],
[64,23,12,8],
[64,23,12,9],
[64,23,12,10],
[64,23,13,0],
[64,23,13,1],
[64,23,13,2],
[64,23,13,3],
[64,23,13,5],
[64,23,13,6],
[64,23,13,7],
[64,23,13,8],
[64,23,13,9],
[64,23,13,10],
[64,23,13,11],
[64,23,13,12],
[64,23,14,0],
[64,23,14,1],
[64,23,14,2],
[64,23,14,3],
[64,23,14,5],
[64,23,14,6],
[64,23,14,7],
[64,23,14,8],
[64,23,14,9],
[64,23,14,10],
[64,23,14,11],
[64,23,14,12],
[64,23,15,0],
[64,23,15,1],
[64,23,15,2],
[64,23,15,3],
[64,23,15,5],
[64,23,15,6],
[64,23,15,7],
[64,23,15,8],
[64,23,15,10],
[64,23,15,11],
[64,23,15,12],
[64,23,15,13],
[64,23,15,14],
[64,23,16,0],
[64,23,16,1],
[64,23,16,2],
[64,23,16,3],
[64,23,16,5],
[64,23,16,6],
[64,23,16,7],
[64,23,16,8],
[64,23,16,9],
[64,23,16,10],
[64,23,16,11],
[64,23,16,12],
[64,23,16,13],
[64,23,16,14],
[64,23,16,15],
[64,23,17,0],
[64,23,17,1],
[64,23,17,2],
[64,23,17,3],
[64,23,17,5],
[64,23,17,6],
[64,23,17,7],
[64,23,17,8],
[64,23,17,9],
[64,23,17,10],
[64,23,17,11],
[64,23,17,12],
[64,23,17,13],
[64,23,17,14],
[64,23,17,15],
[64,23,17,16],
[64,23,18,0],
[64,23,18,1],
[64,23,18,2],
[64,23,18,3],
[64,23,18,5],
[64,23,18,6],
[64,23,18,7],
[64,23,18,8],
[64,23,18,10],
[64,23,18,11],
[64,23,18,12],
[64,23,18,13],
[64,23,18,14],
[64,23,18,16],
[64,23,18,17],
[64,23,19,0],
[64,23,19,1],
[64,23,19,2],
[64,23,19,3],
[64,23,19,5],
[64,23,19,6],
[64,23,19,7],
[64,23,19,8],
[64,23,19,9],
[64,23,19,10],
[64,23,19,11],
[64,23,19,12],
[64,23,19,15],
[64,23,19,16],
[64,23,19,17],
[64,23,19,18],
[64,23,20,0],
[64,23,20,1],
[64,23,20,2],
[64,23,20,3],
[64,23,20,5],
[64,23,20,6],
[64,23,20,7],
[64,23,20,8],
[64,23,20,9],
[64,23,20,11],
[64,23,20,12],
[64,23,20,13],
[64,23,20,14],
[64,23,20,15],
[64,23,20,16],
[64,23,20,17],
[64,23,20,18],
[64,23,20,19],
[64,23,21,0],
[64,23,21,1],
[64,23,21,2],
[64,23,21,7],
[64,23,21,9],
[64,23,21,10],
[64,23,21,11],
[64,23,21,12],
[64,23,21,13],
[64,23,21,14],
[64,23,21,15],
[64,23,21,16],
[64,23,21,17],
[64,23,21,18],
[64,23,21,19],
[64,23,21,20],
[64,23,22,0],
[64,23,22,1],
[64,23,22,2],
[64,23,22,3],
[64,23,22,5],
[64,23,22,6],
[64,23,22,7],
[64,23,22,8],
[64,23,22,9],
[64,23,22,11],
[64,23,22,12],
[64,23,22,13],
[64,23,22,14],
[64,23,22,15],
[64,23,22,16],
[64,23,22,17],
[64,23,22,18],
[64,23,22,19],
[64,23,22,21],
[64,24,3,0],
[64,24,3,1],
[64,24,3,2],
[64,24,5,0],
[64,24,5,1],
[64,24,5,2],
[64,24,6,0],
[64,24,6,1],
[64,24,6,2],
[64,24,7,0],
[64,24,7,1],
[64,24,7,2],
[64,24,7,3],
[64,24,7,5],
[64,24,7,6],
[64,24,8,0],
[64,24,8,1],
[64,24,8,2],
[64,24,8,7],
[64,24,9,0],
[64,24,9,1],
[64,24,9,2],
[64,24,9,3],
[64,24,9,5],
[64,24,9,6],
[64,24,9,7],
[64,24,9,8],
[64,24,10,0],
[64,24,10,1],
[64,24,10,2],
[64,24,10,3],
[64,24,10,5],
[64,24,10,6],
[64,24,10,7],
[64,24,10,8],
[64,24,10,9],
[64,24,11,0],
[64,24,11,1],
[64,24,11,2],
[64,24,11,3],
[64,24,11,5],
[64,24,11,6],
[64,24,11,7],
[64,24,11,8],
[64,24,11,9],
[64,24,11,10],
[64,24,12,0],
[64,24,12,1],
[64,24,12,2],
[64,24,12,3],
[64,24,12,5],
[64,24,12,6],
[64,24,12,7],
[64,24,12,8],
[64,24,12,9],
[64,24,12,10],
[64,24,13,0],
[64,24,13,1],
[64,24,13,2],
[64,24,13,3],
[64,24,13,5],
[64,24,13,6],
[64,24,13,7],
[64,24,13,8],
[64,24,13,9],
[64,24,13,10],
[64,24,13,11],
[64,24,13,12],
[64,24,14,0],
[64,24,14,1],
[64,24,14,2],
[64,24,14,3],
[64,24,14,5],
[64,24,14,6],
[64,24,14,7],
[64,24,14,8],
[64,24,14,9],
[64,24,14,10],
[64,24,14,11],
[64,24,14,12],
[64,24,15,0],
[64,24,15,1],
[64,24,15,2],
[64,24,15,3],
[64,24,15,5],
[64,24,15,6],
[64,24,15,7],
[64,24,15,8],
[64,24,15,10],
[64,24,15,11],
[64,24,15,12],
[64,24,15,13],
[64,24,15,14],
[64,24,17,0],
[64,24,17,1],
[64,24,17,2],
[64,24,17,3],
[64,24,17,5],
[64,24,17,6],
[64,24,17,7],
[64,24,17,8],
[64,24,17,9],
[64,24,17,10],
[64,24,17,11],
[64,24,17,12],
[64,24,17,13],
[64,24,17,14],
[64,24,17,15],
[64,24,18,0],
[64,24,18,1],
[64,24,18,2],
[64,24,18,3],
[64,24,18,5],
[64,24,18,6],
[64,24,18,7],
[64,24,18,8],
[64,24,18,10],
[64,24,18,11],
[64,24,18,12],
[64,24,18,13],
[64,24,18,14],
[64,24,18,17],
[64,24,19,0],
[64,24,19,1],
[64,24,19,2],
[64,24,19,3],
[64,24,19,5],
[64,24,19,6],
[64,24,19,7],
[64,24,19,8],
[64,24,19,9],
[64,24,19,10],
[64,24,19,11],
[64,24,19,12],
[64,24,19,15],
[64,24,19,17],
[64,24,19,18],
[64,24,20,0],
[64,24,20,1],
[64,24,20,2],
[64,24,20,3],
[64,24,20,5],
[64,24,20,6],
[64,24,20,7],
[64,24,20,8],
[64,24,20,9],
[64,24,20,11],
[64,24,20,12],
[64,24,20,13],
[64,24,20,14],
[64,24,20,15],
[64,24,20,17],
[64,24,20,18],
[64,24,20,19],
[64,24,21,0],
[64,24,21,1],
[64,24,21,2],
[64,24,21,7],
[64,24,21,9],
[64,24,21,10],
[64,24,21,11],
[64,24,21,12],
[64,24,21,13],
[64,24,21,14],
[64,24,21,15],
[64,24,21,17],
[64,24,21,18],
[64,24,21,19],
[64,24,21,20],
[64,24,22,0],
[64,24,22,1],
[64,24,22,2],
[64,24,22,3],
[64,24,22,5],
[64,24,22,6],
[64,24,22,7],
[64,24,22,8],
[64,24,22,9],
[64,24,22,11],
[64,24,22,12],
[64,24,22,13],
[64,24,22,14],
[64,24,22,15],
[64,24,22,17],
[64,24,22,18],
[64,24,22,19],
[64,24,22,21],
[64,24,23,0],
[64,24,23,1],
[64,24,23,2],
[64,24,23,3],
[64,24,23,5],
[64,24,23,6],
[64,24,23,7],
[64,24,23,8],
[64,24,23,9],
[64,24,23,10],
[64,24,23,11],
[64,24,23,12],
[64,24,23,13],
[64,24,23,14],
[64,24,23,15],
[64,24,23,17],
[64,24,23,18],
[64,24,23,19],
[64,24,23,20],
[64,24,23,21],
[64,24,23,22],
[64,25,3,0],
[64,25,3,1],
[64,25,3,2],
[64,25,5,0],
[64,25,5,1],
[64,25,5,2],
[64,25,6,0],
[64,25,6,1],
[64,25,6,2],
[64,25,8,0],
[64,25,8,1],
[64,25,8,2],
[64,25,9,0],
[64,25,9,1],
[64,25,9,2],
[64,25,9,3],
[64,25,9,5],
[64,25,9,6],
[64,25,9,8],
[64,25,10,0],
[64,25,10,1],
[64,25,10,2],
[64,25,10,3],
[64,25,10,5],
[64,25,10,6],
[64,25,10,8],
[64,25,10,9],
[64,25,11,0],
[64,25,11,1],
[64,25,11,2],
[64,25,11,3],
[64,25,11,5],
[64,25,11,6],
[64,25,11,8],
[64,25,11,9],
[64,25,11,10],
[64,25,12,0],
[64,25,12,1],
[64,25,12,2],
[64,25,12,3],
[64,25,12,5],
[64,25,12,6],
[64,25,12,8],
[64,25,12,9],
[64,25,12,10],
[64,25,13,0],
[64,25,13,1],
[64,25,13,2],
[64,25,13,3],
[64,25,13,5],
[64,25,13,6],
[64,25,13,8],
[64,25,13,9],
[64,25,13,10],
[64,25,13,11],
[64,25,13,12],
[64,25,14,0],
[64,25,14,1],
[64,25,14,2],
[64,25,14,3],
[64,25,14,5],
[64,25,14,6],
[64,25,14,8],
[64,25,14,9],
[64,25,14,10],
[64,25,14,11],
[64,25,14,12],
[64,25,15,0],
[64,25,15,1],
[64,25,15,2],
[64,25,15,3],
[64,25,15,5],
[64,25,15,6],
[64,25,15,8],
[64,25,15,10],
[64,25,15,11],
[64,25,15,12],
[64,25,15,13],
[64,25,15,14],
[64,25,16,0],
[64,25,16,1],
[64,25,16,2],
[64,25,16,3],
[64,25,16,5],
[64,25,16,6],
[64,25,16,8],
[64,25,16,9],
[64,25,16,10],
[64,25,16,11],
[64,25,16,12],
[64,25,16,13],
[64,25,16,14],
[64,25,16,15],
[64,25,17,0],
[64,25,17,1],
[64,25,17,2],
[64,25,17,3],
[64,25,17,5],
[64,25,17,6],
[64,25,17,8],
[64,25,17,9],
[64,25,17,10],
[64,25,17,11],
[64,25,17,12],
[64,25,17,13],
[64,25,17,14],
[64,25,17,15],
[64,25,17,16],
[64,25,18,0],
[64,25,18,1],
[64,25,18,2],
[64,25,18,3],
[64,25,18,5],
[64,25,18,6],
[64,25,18,8],
[64,25,18,10],
[64,25,18,11],
[64,25,18,12],
[64,25,18,13],
[64,25,18,14],
[64,25,18,16],
[64,25,18,17],
[64,25,19,0],
[64,25,19,1],
[64,25,19,2],
[64,25,19,3],
[64,25,19,5],
[64,25,19,6],
[64,25,19,8],
[64,25,19,9],
[64,25,19,10],
[64,25,19,11],
[64,25,19,12],
[64,25,19,15],
[64,25,19,16],
[64,25,19,17],
[64,25,19,18],
[64,25,20,0],
[64,25,20,1],
[64,25,20,2],
[64,25,20,3],
[64,25,20,5],
[64,25,20,6],
[64,25,20,8],
[64,25,20,9],
[64,25,20,11],
[64,25,20,12],
[64,25,20,13],
[64,25,20,14],
[64,25,20,15],
[64,25,20,16],
[64,25,20,17],
[64,25,20,18],
[64,25,20,19],
[64,25,21,0],
[64,25,21,1],
[64,25,21,2],
[64,25,21,9],
[64,25,21,10],
[64,25,21,11],
[64,25,21,12],
[64,25,21,13],
[64,25,21,14],
[64,25,21,15],
[64,25,21,16],
[64,25,21,17],
[64,25,21,18],
[64,25,21,19],
[64,25,21,20],
[64,25,22,0],
[64,25,22,1],
[64,25,22,2],
[64,25,22,3],
[64,25,22,5],
[64,25,22,6],
[64,25,22,8],
[64,25,22,9],
[64,25,22,11],
[64,25,22,12],
[64,25,22,13],
[64,25,22,14],
[64,25,22,15],
[64,25,22,16],
[64,25,22,17],
[64,25,22,18],
[64,25,22,19],
[64,25,22,21],
[64,25,23,0],
[64,25,23,1],
[64,25,23,2],
[64,25,23,3],
[64,25,23,5],
[64,25,23,6],
[64,25,23,8],
[64,25,23,9],
[64,25,23,10],
[64,25,23,11],
[64,25,23,12],
[64,25,23,13],
[64,25,23,14],
[64,25,23,15],
[64,25,23,16],
[64,25,23,17],
[64,25,23,18],
[64,25,23,19],
[64,25,23,20],
[64,25,23,21],
[64,25,23,22],
[64,25,24,0],
[64,25,24,1],
[64,25,24,2],
[64,25,24,3],
[64,25,24,5],
[64,25,24,6],
[64,25,24,8],
[64,25,24,9],
[64,25,24,10],
[64,25,24,11],
[64,25,24,12],
[64,25,24,13],
[64,25,24,14],
[64,25,24,15],
[64,25,24,17],
[64,25,24,18],
[64,25,24,19],
[64,25,24,20],
[64,25,24,21],
[64,25,24,22],
[64,25,24,23],
[64,26,3,0],
[64,26,3,1],
[64,26,3,2],
[64,26,5,0],
[64,26,5,1],
[64,26,5,2],
[64,26,6,0],
[64,26,6,1],
[64,26,6,2],
[64,26,7,0],
[64,26,7,1],
[64,26,7,2],
[64,26,7,3],
[64,26,7,5],
[64,26,7,6],
[64,26,8,0],
[64,26,8,1],
[64,26,8,2],
[64,26,8,7],
[64,26,10,0],
[64,26,10,1],
[64,26,10,2],
[64,26,10,3],
[64,26,10,5],
[64,26,10,6],
[64,26,10,7],
[64,26,10,8],
[64,26,11,0],
[64,26,11,1],
[64,26,11,2],
[64,26,11,3],
[64,26,11,5],
[64,26,11,6],
[64,26,11,7],
[64,26,11,8],
[64,26,11,10],
[64,26,12,0],
[64,26,12,1],
[64,26,12,2],
[64,26,12,3],
[64,26,12,5],
[64,26,12,6],
[64,26,12,7],
[64,26,12,8],
[64,26,12,10],
[64,26,13,0],
[64,26,13,1],
[64,26,13,2],
[64,26,13,3],
[64,26,13,5],
[64,26,13,6],
[64,26,13,7],
[64,26,13,8],
[64,26,13,10],
[64,26,13,11],
[64,26,13,12],
[64,26,14,0],
[64,26,14,1],
[64,26,14,2],
[64,26,14,3],
[64,26,14,5],
[64,26,14,6],
[64,26,14,7],
[64,26,14,8],
[64,26,14,10],
[64,26,14,11],
[64,26,14,12],
[64,26,16,0],
[64,26,16,1],
[64,26,16,2],
[64,26,16,3],
[64,26,16,5],
[64,26,16,6],
[64,26,16,7],
[64,26,16,8],
[64,26,16,10],
[64,26,16,11],
[64,26,16,12],
[64,26,16,13],
[64,26,16,14],
[64,26,17,0],
[64,26,17,1],
[64,26,17,2],
[64,26,17,3],
[64,26,17,5],
[64,26,17,6],
[64,26,17,7],
[64,26,17,8],
[64,26,17,10],
[64,26,17,11],
[64,26,17,12],
[64,26,17,13],
[64,26,17,14],
[64,26,17,16],
[64,26,19,0],
[64,26,19,1],
[64,26,19,2],
[64,26,19,3],
[64,26,19,5],
[64,26,19,6],
[64,26,19,7],
[64,26,19,8],
[64,26,19,10],
[64,26,19,11],
[64,26,19,12],
[64,26,19,16],
[64,26,19,17],
[64,26,20,0],
[64,26,20,1],
[64,26,20,2],
[64,26,20,3],
[64,26,20,5],
[64,26,20,6],
[64,26,20,7],
[64,26,20,8],
[64,26,20,11],
[64,26,20,12],
[64,26,20,13],
[64,26,20,14],
[64,26,20,16],
[64,26,20,17],
[64,26,20,19],
[64,26,21,0],
[64,26,21,1],
[64,26,21,2],
[64,26,21,7],
[64,26,21,10],
[64,26,21,11],
[64,26,21,12],
[64,26,21,13],
[64,26,21,14],
[64,26,21,16],
[64,26,21,17],
[64,26,21,19],
[64,26,21,20],
[64,26,22,0],
[64,26,22,1],
[64,26,22,2],
[64,26,22,3],
[64,26,22,5],
[64,26,22,6],
[64,26,22,7],
[64,26,22,8],
[64,26,22,11],
[64,26,22,12],
[64,26,22,13],
[64,26,22,14],
[64,26,22,16],
[64,26,22,17],
[64,26,22,19],
[64,26,22,21],
[64,26,23,0],
[64,26,23,1],
[64,26,23,2],
[64,26,23,3],
[64,26,23,5],
[64,26,23,6],
[64,26,23,7],
[64,26,23,8],
[64,26,23,10],
[64,26,23,11],
[64,26,23,12],
[64,26,23,13],
[64,26,23,14],
[64,26,23,16],
[64,26,23,17],
[64,26,23,19],
[64,26,23,20],
[64,26,23,21],
[64,26,23,22],
[64,26,24,0],
[64,26,24,1],
[64,26,24,2],
[64,26,24,3],
[64,26,24,5],
[64,26,24,6],
[64,26,24,7],
[64,26,24,8],
[64,26,24,10],
[64,26,24,11],
[64,26,24,12],
[64,26,24,13],
[64,26,24,14],
[64,26,24,17],
[64,26,24,19],
[64,26,24,20],
[64,26,24,21],
[64,26,24,22],
[64,26,24,23],
[64,26,25,0],
[64,26,25,1],
[64,26,25,2],
[64,26,25,3],
[64,26,25,5],
[64,26,25,6],
[64,26,25,8],
[64,26,25,10],
[64,26,25,11],
[64,26,25,12],
[64,26,25,13],
[64,26,25,14],
[64,26,25,16],
[64,26,25,17],
[64,26,25,19],
[64,26,25,20],
[64,26,25,21],
[64,26,25,22],
[64,27,3,0],
[64,27,3,1],
[64,27,3,2],
[64,27,5,0],
[64,27,5,1],
[64,27,5,2],
[64,27,6,0],
[64,27,6,1],
[64,27,6,2],
[64,27,7,0],
[64,27,7,1],
[64,27,7,2],
[64,27,7,3],
[64,27,7,5],
[64,27,7,6],
[64,27,8,0],
[64,27,8,1],
[64,27,8,2],
[64,27,8,7],
[64,27,9,0],
[64,27,9,1],
[64,27,9,2],
[64,27,9,3],
[64,27,9,5],
[64,27,9,6],
[64,27,9,7],
[64,27,9,8],
[64,27,11,0],
[64,27,11,1],
[64,27,11,2],
[64,27,11,3],
[64,27,11,5],
[64,27,11,6],
[64,27,11,7],
[64,27,11,8],
[64,27,11,9],
[64,27,12,0],
[64,27,12,1],
[64,27,12,2],
[64,27,12,3],
[64,27,12,5],
[64,27,12,6],
[64,27,12,7],
[64,27,12,8],
[64,27,12,9],
[64,27,13,0],
[64,27,13,1],
[64,27,13,2],
[64,27,13,3],
[64,27,13,5],
[64,27,13,6],
[64,27,13,7],
[64,27,13,8],
[64,27,13,9],
[64,27,13,11],
[64,27,13,12],
[64,27,14,0],
[64,27,14,1],
[64,27,14,2],
[64,27,14,3],
[64,27,14,5],
[64,27,14,6],
[64,27,14,7],
[64,27,14,8],
[64,27,14,9],
[64,27,14,11],
[64,27,14,12],
[64,27,15,0],
[64,27,15,1],
[64,27,15,2],
[64,27,15,3],
[64,27,15,5],
[64,27,15,6],
[64,27,15,7],
[64,27,15,8],
[64,27,15,11],
[64,27,15,12],
[64,27,15,13],
[64,27,15,14],
[64,27,16,0],
[64,27,16,1],
[64,27,16,2],
[64,27,16,3],
[64,27,16,5],
[64,27,16,6],
[64,27,16,7],
[64,27,16,8],
[64,27,16,9],
[64,27,16,11],
[64,27,16,12],
[64,27,16,13],
[64,27,16,14],
[64,27,16,15],
[64,27,17,0],
[64,27,17,1],
[64,27,17,2],
[64,27,17,3],
[64,27,17,5],
[64,27,17,6],
[64,27,17,7],
[64,27,17,8],
[64,27,17,9],
[64,27,17,11],
[64,27,17,12],
[64,27,17,13],
[64,27,17,14],
[64,27,17,15],
[64,27,17,16],
[64,27,18,0],
[64,27,18,1],
[64,27,18,2],
[64,27,18,3],
[64,27,18,5],
[64,27,18,6],
[64,27,18,7],
[64,27,18,8],
[64,27,18,11],
[64,27,18,12],
[64,27,18,13],
[64,27,18,14],
[64,27,18,16],
[64,27,18,17],
[64,27,19,0],
[64,27,19,1],
[64,27,19,2],
[64,27,19,3],
[64,27,19,5],
[64,27,19,6],
[64,27,19,7],
[64,27,19,8],
[64,27,19,9],
[64,27,19,11],
[64,27,19,12],
[64,27,19,15],
[64,27,19,16],
[64,27,19,17],
[64,27,19,18],
[64,27,21,0],
[64,27,21,1],
[64,27,21,2],
[64,27,21,7],
[64,27,21,9],
[64,27,21,11],
[64,27,21,12],
[64,27,21,13],
[64,27,21,14],
[64,27,21,15],
[64,27,21,16],
[64,27,21,17],
[64,27,21,18],
[64,27,21,19],
[64,27,23,0],
[64,27,23,1],
[64,27,23,2],
[64,27,23,3],
[64,27,23,5],
[64,27,23,6],
[64,27,23,7],
[64,27,23,8],
[64,27,23,9],
[64,27,23,11],
[64,27,23,12],
[64,27,23,13],
[64,27,23,14],
[64,27,23,15],
[64,27,23,16],
[64,27,23,17],
[64,27,23,18],
[64,27,23,19],
[64,27,23,21],
[64,27,24,0],
[64,27,24,1],
[64,27,24,2],
[64,27,24,3],
[64,27,24,5],
[64,27,24,6],
[64,27,24,7],
[64,27,24,8],
[64,27,24,9],
[64,27,24,11],
[64,27,24,12],
[64,27,24,13],
[64,27,24,14],
[64,27,24,15],
[64,27,24,17],
[64,27,24,18],
[64,27,24,19],
[64,27,24,21],
[64,27,24,23],
[64,27,25,0],
[64,27,25,1],
[64,27,25,2],
[64,27,25,3],
[64,27,25,5],
[64,27,25,6],
[64,27,25,8],
[64,27,25,9],
[64,27,25,11],
[64,27,25,12],
[64,27,25,13],
[64,27,25,14],
[64,27,25,15],
[64,27,25,16],
[64,27,25,17],
[64,27,25,18],
[64,27,25,19],
[64,27,25,21],
[64,27,25,23],
[64,27,25,24],
[64,27,26,0],
[64,27,26,1],
[64,27,26,2],
[64,27,26,3],
[64,27,26,5],
[64,27,26,6],
[64,27,26,7],
[64,27,26,8],
[64,27,26,11],
[64,27,26,12],
[64,27,26,13],
[64,27,26,14],
[64,27,26,16],
[64,27,26,17],
[64,27,26,19],
[64,27,26,23],
[64,27,26,24],
[64,27,26,25],
[64,28,3,0],
[64,28,3,1],
[64,28,3,2],
[64,28,5,0],
[64,28,5,1],
[64,28,5,2],
[64,28,6,0],
[64,28,6,1],
[64,28,6,2],
[64,28,8,0],
[64,28,8,1],
[64,28,8,2],
[64,28,9,0],
[64,28,9,1],
[64,28,9,2],
[64,28,9,3],
[64,28,9,5],
[64,28,9,6],
[64,28,9,8],
[64,28,10,0],
[64,28,10,1],
[64,28,10,2],
[64,28,10,3],
[64,28,10,5],
[64,28,10,6],
[64,28,10,8],
[64,28,10,9],
[64,28,11,0],
[64,28,11,1],
[64,28,11,2],
[64,28,11,3],
[64,28,11,5],
[64,28,11,6],
[64,28,11,8],
[64,28,11,9],
[64,28,11,10],
[64,28,12,0],
[64,28,12,1],
[64,28,12,2],
[64,28,12,3],
[64,28,12,5],
[64,28,12,6],
[64,28,12,8],
[64,28,12,9],
[64,28,12,10],
[64,28,13,0],
[64,28,13,1],
[64,28,13,2],
[64,28,13,3],
[64,28,13,5],
[64,28,13,6],
[64,28,13,8],
[64,28,13,9],
[64,28,13,10],
[64,28,13,11],
[64,28,13,12],
[64,28,14,0],
[64,28,14,1],
[64,28,14,2],
[64,28,14,3],
[64,28,14,5],
[64,28,14,6],
[64,28,14,8],
[64,28,14,9],
[64,28,14,10],
[64,28,14,11],
[64,28,14,12],
[64,28,15,0],
[64,28,15,1],
[64,28,15,2],
[64,28,15,3],
[64,28,15,5],
[64,28,15,6],
[64,28,15,8],
[64,28,15,10],
[64,28,15,11],
[64,28,15,12],
[64,28,15,13],
[64,28,15,14],
[64,28,16,0],
[64,28,16,1],
[64,28,16,2],
[64,28,16,3],
[64,28,16,5],
[64,28,16,6],
[64,28,16,8],
[64,28,16,9],
[64,28,16,10],
[64,28,16,11],
[64,28,16,12],
[64,28,16,13],
[64,28,16,14],
[64,28,16,15],
[64,28,17,0],
[64,28,17,1],
[64,28,17,2],
[64,28,17,3],
[64,28,17,5],
[64,28,17,6],
[64,28,17,8],
[64,28,17,9],
[64,28,17,10],
[64,28,17,11],
[64,28,17,12],
[64,28,17,13],
[64,28,17,14],
[64,28,17,15],
[64,28,17,16],
[64,28,18,0],
[64,28,18,1],
[64,28,18,2],
[64,28,18,3],
[64,28,18,5],
[64,28,18,6],
[64,28,18,8],
[64,28,18,10],
[64,28,18,11],
[64,28,18,12],
[64,28,18,13],
[64,28,18,14],
[64,28,18,16],
[64,28,18,17],
[64,28,19,0],
[64,28,19,1],
[64,28,19,2],
[64,28,19,3],
[64,28,19,5],
[64,28,19,6],
[64,28,19,8],
[64,28,19,9],
[64,28,19,10],
[64,28,19,11],
[64,28,19,12],
[64,28,19,15],
[64,28,19,16],
[64,28,19,17],
[64,28,19,18],
[64,28,20,0],
[64,28,20,1],
[64,28,20,2],
[64,28,20,3],
[64,28,20,5],
[64,28,20,6],
[64,28,20,8],
[64,28,20,9],
[64,28,20,11],
[64,28,20,12],
[64,28,20,13],
[64,28,20,14],
[64,28,20,15],
[64,28,20,16],
[64,28,20,17],
[64,28,20,18],
[64,28,20,19],
[64,28,21,0],
[64,28,21,1],
[64,28,21,2],
[64,28,21,9],
[64,28,21,10],
[64,28,21,11],
[64,28,21,12],
[64,28,21,13],
[64,28,21,14],
[64,28,21,15],
[64,28,21,16],
[64,28,21,17],
[64,28,21,18],
[64,28,21,19],
[64,28,21,20],
[64,28,22,0],
[64,28,22,1],
[64,28,22,2],
[64,28,22,3],
[64,28,22,5],
[64,28,22,6],
[64,28,22,8],
[64,28,22,9],
[64,28,22,11],
[64,28,22,12],
[64,28,22,13],
[64,28,22,14],
[64,28,22,15],
[64,28,22,16],
[64,28,22,17],
[64,28,22,18],
[64,28,22,19],
[64,28,22,21],
[64,28,23,0],
[64,28,23,1],
[64,28,23,2],
[64,28,23,3],
[64,28,23,5],
[64,28,23,6],
[64,28,23,8],
[64,28,23,9],
[64,28,23,10],
[64,28,23,11],
[64,28,23,12],
[64,28,23,13],
[64,28,23,14],
[64,28,23,15],
[64,28,23,16],
[64,28,23,17],
[64,28,23,18],
[64,28,23,19],
[64,28,23,20],
[64,28,23,21],
[64,28,23,22],
[64,28,24,0],
[64,28,24,1],
[64,28,24,2],
[64,28,24,3],
[64,28,24,5],
[64,28,24,6],
[64,28,24,8],
[64,28,24,9],
[64,28,24,10],
[64,28,24,11],
[64,28,24,12],
[64,28,24,13],
[64,28,24,14],
[64,28,24,15],
[64,28,24,17],
[64,28,24,18],
[64,28,24,19],
[64,28,24,20],
[64,28,24,21],
[64,28,24,22],
[64,28,26,0],
[64,28,26,1],
[64,28,26,2],
[64,28,26,3],
[64,28,26,5],
[64,28,26,6],
[64,28,26,8],
[64,28,26,10],
[64,28,26,11],
[64,28,26,12],
[64,28,26,13],
[64,28,26,14],
[64,28,26,16],
[64,28,26,17],
[64,28,26,19],
[64,28,26,21],
[64,28,26,22],
[64,28,26,23],
[64,28,26,24],
[64,28,27,0],
[64,28,27,1],
[64,28,27,2],
[64,28,27,3],
[64,28,27,5],
[64,28,27,6],
[64,28,27,8],
[64,28,27,9],
[64,28,27,11],
[64,28,27,12],
[64,28,27,13],
[64,28,27,14],
[64,28,27,15],
[64,28,27,16],
[64,28,27,17],
[64,28,27,18],
[64,28,27,21],
[64,28,27,23],
[64,28,27,24],
[64,28,27,26],
[64,29,3,0],
[64,29,3,1],
[64,29,3,2],
[64,29,5,0],
[64,29,5,1],
[64,29,5,2],
[64,29,6,0],
[64,29,6,1],
[64,29,6,2],
[64,29,7,0],
[64,29,7,1],
[64,29,7,2],
[64,29,7,3],
[64,29,7,5],
[64,29,7,6],
[64,29,8,0],
[64,29,8,1],
[64,29,8,2],
[64,29,8,7],
[64,29,9,0],
[64,29,9,1],
[64,29,9,2],
[64,29,9,3],
[64,29,9,5],
[64,29,9,6],
[64,29,9,7],
[64,29,9,8],
[64,29,10,0],
[64,29,10,1],
[64,29,10,2],
[64,29,10,3],
[64,29,10,5],
[64,29,10,6],
[64,29,10,7],
[64,29,10,8],
[64,29,10,9],
[64,29,13,0],
[64,29,13,1],
[64,29,13,2],
[64,29,13,3],
[64,29,13,5],
[64,29,13,6],
[64,29,13,7],
[64,29,13,8],
[64,29,13,9],
[64,29,13,10],
[64,29,14,0],
[64,29,14,1],
[64,29,14,2],
[64,29,14,3],
[64,29,14,5],
[64,29,14,6],
[64,29,14,7],
[64,29,14,8],
[64,29,14,9],
[64,29,14,10],
[64,29,15,0],
[64,29,15,1],
[64,29,15,2],
[64,29,15,3],
[64,29,15,5],
[64,29,15,6],
[64,29,15,7],
[64,29,15,8],
[64,29,15,10],
[64,29,15,13],
[64,29,15,14],
[64,29,16,0],
[64,29,16,1],
[64,29,16,2],
[64,29,16,3],
[64,29,16,5],
[64,29,16,6],
[64,29,16,7],
[64,29,16,8],
[64,29,16,9],
[64,29,16,10],
[64,29,16,13],
[64,29,16,14],
[64,29,16,15],
[64,29,17,0],
[64,29,17,1],
[64,29,17,2],
[64,29,17,3],
[64,29,17,5],
[64,29,17,6],
[64,29,17,7],
[64,29,17,8],
[64,29,17,9],
[64,29,17,10],
[64,29,17,13],
[64,29,17,14],
[64,29,17,15],
[64,29,17,16],
[64,29,18,0],
[64,29,18,1],
[64,29,18,2],
[64,29,18,3],
[64,29,18,5],
[64,29,18,6],
[64,29,18,7],
[64,29,18,8],
[64,29,18,10],
[64,29,18,13],
[64,29,18,14],
[64,29,18,16],
[64,29,18,17],
[64,29,19,0],
[64,29,19,1],
[64,29,19,2],
[64,29,19,3],
[64,29,19,5],
[64,29,19,6],
[64,29,19,7],
[64,29,19,8],
[64,29,19,9],
[64,29,19,10],
[64,29,19,15],
[64,29,19,16],
[64,29,19,17],
[64,29,19,18],
[64,29,20,0],
[64,29,20,1],
[64,29,20,2],
[64,29,20,3],
[64,29,20,5],
[64,29,20,6],
[64,29,20,7],
[64,29,20,8],
[64,29,20,9],
[64,29,20,13],
[64,29,20,14],
[64,29,20,15],
[64,29,20,16],
[64,29,20,17],
[64,29,20,18],
[64,29,20,19],
[64,29,21,0],
[64,29,21,1],
[64,29,21,2],
[64,29,21,7],
[64,29,21,9],
[64,29,21,10],
[64,29,21,13],
[64,29,21,14],
[64,29,21,15],
[64,29,21,16],
[64,29,21,17],
[64,29,21,18],
[64,29,21,19],
[64,29,21,20],
[64,29,22,0],
[64,29,22,1],
[64,29,22,2],
[64,29,22,3],
[64,29,22,5],
[64,29,22,6],
[64,29,22,7],
[64,29,22,8],
[64,29,22,9],
[64,29,22,13],
[64,29,22,14],
[64,29,22,15],
[64,29,22,16],
[64,29,22,17],
[64,29,22,18],
[64,29,22,19],
[64,29,22,21],
[64,29,23,0],
[64,29,23,1],
[64,29,23,2],
[64,29,23,3],
[64,29,23,5],
[64,29,23,6],
[64,29,23,7],
[64,29,23,8],
[64,29,23,9],
[64,29,23,10],
[64,29,23,13],
[64,29,23,14],
[64,29,23,15],
[64,29,23,16],
[64,29,23,17],
[64,29,23,18],
[64,29,23,19],
[64,29,23,20],
[64,29,23,21],
[64,29,24,0],
[64,29,24,1],
[64,29,24,2],
[64,29,24,3],
[64,29,24,5],
[64,29,24,6],
[64,29,24,7],
[64,29,24,8],
[64,29,24,9],
[64,29,24,10],
[64,29,24,13],
[64,29,24,14],
[64,29,24,15],
[64,29,24,17],
[64,29,24,18],
[64,29,24,19],
[64,29,24,20],
[64,29,24,21],
[64,29,24,23],
[64,29,25,0],
[64,29,25,1],
[64,29,25,2],
[64,29,25,3],
[64,29,25,5],
[64,29,25,6],
[64,29,25,8],
[64,29,25,9],
[64,29,25,10],
[64,29,25,13],
[64,29,25,14],
[64,29,25,15],
[64,29,25,16],
[64,29,25,17],
[64,29,25,18],
[64,29,25,19],
[64,29,25,21],
[64,29,25,22],
[64,29,25,23],
[64,29,25,24],
[64,29,26,0],
[64,29,26,1],
[64,29,26,2],
[64,29,26,3],
[64,29,26,5],
[64,29,26,6],
[64,29,26,7],
[64,29,26,8],
[64,29,26,10],
[64,29,26,13],
[64,29,26,14],
[64,29,26,16],
[64,29,26,17],
[64,29,26,20],
[64,29,26,21],
[64,29,26,22],
[64,29,26,23],
[64,29,26,24],
[64,29,26,25],
[64,29,27,0],
[64,29,27,1],
[64,29,27,2],
[64,29,27,3],
[64,29,27,5],
[64,29,27,6],
[64,29,27,7],
[64,29,27,8],
[64,29,27,9],
[64,29,27,13],
[64,29,27,14],
[64,29,27,15],
[64,29,27,16],
[64,29,27,17],
[64,29,27,19],
[64,29,27,21],
[64,29,27,23],
[64,29,27,24],
[64,29,27,25],
[64,29,27,26],
[64,29,28,0],
[64,29,28,1],
[64,29,28,2],
[64,29,28,3],
[64,29,28,5],
[64,29,28,6],
[64,29,28,8],
[64,29,28,9],
[64,29,28,10],
[64,29,28,13],
[64,29,28,14],
[64,29,28,15],
[64,29,28,18],
[64,29,28,19],
[64,29,28,20],
[64,29,28,21],
[64,29,28,22],
[64,29,28,23],
[64,29,28,24],
[64,29,28,26],
[64,29,28,27],
[64,30,3,0],
[64,30,3,1],
[64,30,3,2],
[64,30,5,0],
[64,30,5,1],
[64,30,5,2],
[64,30,6,0],
[64,30,6,1],
[64,30,6,2],
[64,30,7,0],
[64,30,7,1],
[64,30,7,2],
[64,30,7,3],
[64,30,7,5],
[64,30,7,6],
[64,30,8,0],
[64,30,8,1],
[64,30,8,2],
[64,30,8,7],
[64,30,9,0],
[64,30,9,1],
[64,30,9,2],
[64,30,9,3],
[64,30,9,5],
[64,30,9,6],
[64,30,9,7],
[64,30,9,8],
[64,30,10,0],
[64,30,10,1],
[64,30,10,2],
[64,30,10,3],
[64,30,10,5],
[64,30,10,6],
[64,30,10,7],
[64,30,10,8],
[64,30,10,9],
[64,30,11,0],
[64,30,11,1],
[64,30,11,2],
[64,30,11,3],
[64,30,11,5],
[64,30,11,6],
[64,30,11,7],
[64,30,11,8],
[64,30,11,9],
[64,30,11,10],
[64,30,12,0],
[64,30,12,1],
[64,30,12,2],
[64,30,12,3],
[64,30,12,5],
[64,30,12,6],
[64,30,12,7],
[64,30,12,8],
[64,30,12,9],
[64,30,12,10],
[64,30,13,0],
[64,30,13,1],
[64,30,13,2],
[64,30,13,3],
[64,30,13,5],
[64,30,13,6],
[64,30,13,7],
[64,30,13,8],
[64,30,13,9],
[64,30,13,10],
[64,30,13,11],
[64,30,13,12],
[64,30,14,0],
[64,30,14,1],
[64,30,14,2],
[64,30,14,3],
[64,30,14,5],
[64,30,14,6],
[64,30,14,7],
[64,30,14,8],
[64,30,14,9],
[64,30,14,10],
[64,30,14,11],
[64,30,14,12],
[64,30,15,0],
[64,30,15,1],
[64,30,15,2],
[64,30,15,3],
[64,30,15,5],
[64,30,15,6],
[64,30,15,7],
[64,30,15,8],
[64,30,15,10],
[64,30,15,11],
[64,30,15,12],
[64,30,15,13],
[64,30,15,14],
[64,30,16,0],
[64,30,16,1],
[64,30,16,2],
[64,30,16,3],
[64,30,16,5],
[64,30,16,6],
[64,30,16,7],
[64,30,16,8],
[64,30,16,9],
[64,30,16,10],
[64,30,16,11],
[64,30,16,12],
[64,30,16,13],
[64,30,16,14],
[64,30,16,15],
[64,30,17,0],
[64,30,17,1],
[64,30,17,2],
[64,30,17,3],
[64,30,17,5],
[64,30,17,6],
[64,30,17,7],
[64,30,17,8],
[64,30,17,9],
[64,30,17,10],
[64,30,17,11],
[64,30,17,12],
[64,30,17,13],
[64,30,17,14],
[64,30,17,15],
[64,30,17,16],
[64,30,18,0],
[64,30,18,1],
[64,30,18,2],
[64,30,18,3],
[64,30,18,5],
[64,30,18,6],
[64,30,18,7],
[64,30,18,8],
[64,30,18,10],
[64,30,18,11],
[64,30,18,12],
[64,30,18,13],
[64,30,18,14],
[64,30,18,16],
[64,30,18,17],
[64,30,19,0],
[64,30,19,1],
[64,30,19,2],
[64,30,19,3],
[64,30,19,5],
[64,30,19,6],
[64,30,19,7],
[64,30,19,8],
[64,30,19,9],
[64,30,19,10],
[64,30,19,11],
[64,30,19,12],
[64,30,19,15],
[64,30,19,16],
[64,30,19,17],
[64,30,19,18],
[64,30,20,0],
[64,30,20,1],
[64,30,20,2],
[64,30,20,3],
[64,30,20,5],
[64,30,20,6],
[64,30,20,7],
[64,30,20,8],
[64,30,20,9],
[64,30,20,11],
[64,30,20,12],
[64,30,20,13],
[64,30,20,14],
[64,30,20,15],
[64,30,20,16],
[64,30,20,17],
[64,30,20,18],
[64,30,20,19],
[64,30,21,0],
[64,30,21,1],
[64,30,21,2],
[64,30,21,7],
[64,30,21,9],
[64,30,21,10],
[64,30,21,11],
[64,30,21,12],
[64,30,21,13],
[64,30,21,14],
[64,30,21,15],
[64,30,21,16],
[64,30,21,17],
[64,30,21,18],
[64,30,21,19],
[64,30,21,20],
[64,30,22,0],
[64,30,22,1],
[64,30,22,2],
[64,30,22,3],
[64,30,22,5],
[64,30,22,6],
[64,30,22,7],
[64,30,22,8],
[64,30,22,9],
[64,30,22,11],
[64,30,22,12],
[64,30,22,13],
[64,30,22,14],
[64,30,22,15],
[64,30,22,16],
[64,30,22,17],
[64,30,22,18],
[64,30,22,19],
[64,30,22,21],
[64,30,24,0],
[64,30,24,1],
[64,30,24,2],
[64,30,24,3],
[64,30,24,5],
[64,30,24,6],
[64,30,24,7],
[64,30,24,8],
[64,30,24,9],
[64,30,24,10],
[64,30,24,11],
[64,30,24,12],
[64,30,24,13],
[64,30,24,14],
[64,30,24,15],
[64,30,24,17],
[64,30,24,18],
[64,30,24,19],
[64,30,24,20],
[64,30,24,22],
[64,30,25,0],
[64,30,25,1],
[64,30,25,2],
[64,30,25,3],
[64,30,25,5],
[64,30,25,6],
[64,30,25,8],
[64,30,25,9],
[64,30,25,10],
[64,30,25,11],
[64,30,25,12],
[64,30,25,13],
[64,30,25,14],
[64,30,25,15],
[64,30,25,16],
[64,30,25,17],
[64,30,25,18],
[64,30,25,20],
[64,30,25,21],
[64,30,25,22],
[64,30,25,24],
[64,30,26,0],
[64,30,26,1],
[64,30,26,2],
[64,30,26,3],
[64,30,26,5],
[64,30,26,6],
[64,30,26,7],
[64,30,26,8],
[64,30,26,10],
[64,30,26,11],
[64,30,26,12],
[64,30,26,13],
[64,30,26,14],
[64,30,26,16],
[64,30,26,17],
[64,30,26,19],
[64,30,26,20],
[64,30,26,21],
[64,30,26,22],
[64,30,26,24],
[64,30,26,25],
[64,30,27,0],
[64,30,27,1],
[64,30,27,2],
[64,30,27,3],
[64,30,27,5],
[64,30,27,6],
[64,30,27,7],
[64,30,27,8],
[64,30,27,9],
[64,30,27,11],
[64,30,27,12],
[64,30,27,13],
[64,30,27,14],
[64,30,27,15],
[64,30,27,18],
[64,30,27,19],
[64,30,27,21],
[64,30,27,24],
[64,30,27,25],
[64,30,27,26],
[64,30,28,0],
[64,30,28,1],
[64,30,28,2],
[64,30,28,3],
[64,30,28,5],
[64,30,28,6],
[64,30,28,8],
[64,30,28,9],
[64,30,28,10],
[64,30,28,11],
[64,30,28,12],
[64,30,28,13],
[64,30,28,14],
[64,30,28,16],
[64,30,28,17],
[64,30,28,18],
[64,30,28,19],
[64,30,28,20],
[64,30,28,21],
[64,30,28,22],
[64,30,28,24],
[64,30,28,26],
[64,30,28,27],
[64,30,29,0],
[64,30,29,1],
[64,30,29,2],
[64,30,29,3],
[64,30,29,5],
[64,30,29,6],
[64,30,29,7],
[64,30,29,8],
[64,30,29,9],
[64,30,29,10],
[64,30,29,13],
[64,30,29,15],
[64,30,29,16],
[64,30,29,17],
[64,30,29,18],
[64,30,29,19],
[64,30,29,20],
[64,30,29,21],
[64,30,29,22],
[64,30,29,24],
[64,30,29,25],
[64,30,29,26],
[64,30,29,27],
[64,31,3,0],
[64,31,3,1],
[64,31,3,2],
[64,31,5,0],
[64,31,5,1],
[64,31,5,2],
[64,31,6,0],
[64,31,6,1],
[64,31,6,2],
[64,31,7,0],
[64,31,7,1],
[64,31,7,2],
[64,31,7,3],
[64,31,7,5],
[64,31,7,6],
[64,31,8,0],
[64,31,8,1],
[64,31,8,2],
[64,31,8,7],
[64,31,9,0],
[64,31,9,1],
[64,31,9,2],
[64,31,9,3],
[64,31,9,5],
[64,31,9,6],
[64,31,9,7],
[64,31,9,8],
[64,31,10,0],
[64,31,10,1],
[64,31,10,2],
[64,31,10,3],
[64,31,10,5],
[64,31,10,6],
[64,31,10,7],
[64,31,10,8],
[64,31,10,9],
[64,31,11,0],
[64,31,11,1],
[64,31,11,2],
[64,31,11,3],
[64,31,11,5],
[64,31,11,6],
[64,31,11,7],
[64,31,11,8],
[64,31,11,9],
[64,31,11,10],
[64,31,12,0],
[64,31,12,1],
[64,31,12,2],
[64,31,12,3],
[64,31,12,5],
[64,31,12,6],
[64,31,12,7],
[64,31,12,8],
[64,31,12,9],
[64,31,12,10],
[64,31,13,0],
[64,31,13,1],
[64,31,13,2],
[64,31,13,3],
[64,31,13,5],
[64,31,13,6],
[64,31,13,7],
[64,31,13,8],
[64,31,13,9],
[64,31,13,10],
[64,31,13,11],
[64,31,13,12],
[64,31,14,0],
[64,31,14,1],
[64,31,14,2],
[64,31,14,3],
[64,31,14,5],
[64,31,14,6],
[64,31,14,7],
[64,31,14,8],
[64,31,14,9],
[64,31,14,10],
[64,31,14,11],
[64,31,14,12],
[64,31,15,0],
[64,31,15,1],
[64,31,15,2],
[64,31,15,3],
[64,31,15,5],
[64,31,15,6],
[64,31,15,7],
[64,31,15,8],
[64,31,15,10],
[64,31,15,11],
[64,31,15,12],
[64,31,15,13],
[64,31,15,14],
[64,31,16,0],
[64,31,16,1],
[64,31,16,2],
[64,31,16,3],
[64,31,16,5],
[64,31,16,6],
[64,31,16,7],
[64,31,16,8],
[64,31,16,9],
[64,31,16,10],
[64,31,16,11],
[64,31,16,12],
[64,31,16,13],
[64,31,16,14],
[64,31,16,15],
[64,31,17,0],
[64,31,17,1],
[64,31,17,2],
[64,31,17,3],
[64,31,17,5],
[64,31,17,6],
[64,31,17,7],
[64,31,17,8],
[64,31,17,9],
[64,31,17,10],
[64,31,17,11],
[64,31,17,12],
[64,31,17,13],
[64,31,17,14],
[64,31,17,15],
[64,31,17,16],
[64,31,18,0],
[64,31,18,1],
[64,31,18,2],
[64,31,18,3],
[64,31,18,5],
[64,31,18,6],
[64,31,18,7],
[64,31,18,8],
[64,31,18,10],
[64,31,18,11],
[64,31,18,12],
[64,31,18,13],
[64,31,18,14],
[64,31,18,16],
[64,31,18,17],
[64,31,19,0],
[64,31,19,1],
[64,31,19,2],
[64,31,19,3],
[64,31,19,5],
[64,31,19,6],
[64,31,19,7],
[64,31,19,8],
[64,31,19,9],
[64,31,19,10],
[64,31,19,11],
[64,31,19,12],
[64,31,19,15],
[64,31,19,16],
[64,31,19,17],
[64,31,19,18],
[64,31,20,0],
[64,31,20,1],
[64,31,20,2],
[64,31,20,3],
[64,31,20,5],
[64,31,20,6],
[64,31,20,7],
[64,31,20,8],
[64,31,20,9],
[64,31,20,11],
[64,31,20,12],
[64,31,20,13],
[64,31,20,14],
[64,31,20,15],
[64,31,20,16],
[64,31,20,17],
[64,31,20,18],
[64,31,20,19],
[64,31,21,0],
[64,31,21,1],
[64,31,21,2],
[64,31,21,7],
[64,31,21,9],
[64,31,21,10],
[64,31,21,11],
[64,31,21,12],
[64,31,21,13],
[64,31,21,14],
[64,31,21,15],
[64,31,21,16],
[64,31,21,17],
[64,31,21,18],
[64,31,21,19],
[64,31,21,20],
[64,31,22,0],
[64,31,22,1],
[64,31,22,2],
[64,31,22,3],
[64,31,22,5],
[64,31,22,6],
[64,31,22,7],
[64,31,22,8],
[64,31,22,9],
[64,31,22,11],
[64,31,22,12],
[64,31,22,13],
[64,31,22,14],
[64,31,22,15],
[64,31,22,16],
[64,31,22,17],
[64,31,22,18],
[64,31,22,19],
[64,31,24,0],
[64,31,24,1],
[64,31,24,2],
[64,31,24,3],
[64,31,24,5],
[64,31,24,6],
[64,31,24,7],
[64,31,24,8],
[64,31,24,9],
[64,31,24,10],
[64,31,24,11],
[64,31,24,12],
[64,31,24,13],
[64,31,24,14],
[64,31,24,15],
[64,31,24,17],
[64,31,24,18],
[64,31,24,19],
[64,31,24,21],
[64,31,24,22],
[64,31,25,0],
[64,31,25,1],
[64,31,25,2],
[64,31,25,3],
[64,31,25,5],
[64,31,25,6],
[64,31,25,8],
[64,31,25,9],
[64,31,25,10],
[64,31,25,11],
[64,31,25,12],
[64,31,25,13],
[64,31,25,14],
[64,31,25,15],
[64,31,25,16],
[64,31,25,17],
[64,31,25,19],
[64,31,25,20],
[64,31,25,21],
[64,31,25,22],
[64,31,25,24],
[64,31,26,0],
[64,31,26,1],
[64,31,26,2],
[64,31,26,3],
[64,31,26,5],
[64,31,26,6],
[64,31,26,7],
[64,31,26,8],
[64,31,26,10],
[64,31,26,11],
[64,31,26,12],
[64,31,26,13],
[64,31,26,14],
[64,31,26,19],
[64,31,26,20],
[64,31,26,21],
[64,31,26,22],
[64,31,26,24],
[64,31,26,25],
[64,31,27,0],
[64,31,27,1],
[64,31,27,2],
[64,31,27,3],
[64,31,27,5],
[64,31,27,6],
[64,31,27,7],
[64,31,27,8],
[64,31,27,9],
[64,31,27,11],
[64,31,27,12],
[64,31,27,13],
[64,31,27,14],
[64,31,27,16],
[64,31,27,17],
[64,31,27,18],
[64,31,27,19],
[64,31,27,21],
[64,31,27,24],
[64,31,27,25],
[64,31,27,26],
[64,31,28,0],
[64,31,28,1],
[64,31,28,2],
[64,31,28,3],
[64,31,28,5],
[64,31,28,6],
[64,31,28,8],
[64,31,28,9],
[64,31,28,10],
[64,31,28,11],
[64,31,28,12],
[64,31,28,13],
[64,31,28,15],
[64,31,28,16],
[64,31,28,17],
[64,31,28,18],
[64,31,28,19],
[64,31,28,20],
[64,31,28,21],
[64,31,28,22],
[64,31,28,24],
[64,31,28,26],
[64,31,28,27],
[64,31,29,0],
[64,31,29,1],
[64,31,29,2],
[64,31,29,3],
[64,31,29,5],
[64,31,29,6],
[64,31,29,7],
[64,31,29,8],
[64,31,29,9],
[64,31,29,10],
[64,31,29,14],
[64,31,29,15],
[64,31,29,16],
[64,31,29,17],
[64,31,29,18],
[64,31,29,19],
[64,31,29,20],
[64,31,29,21],
[64,31,29,22],
[64,31,29,24],
[64,31,29,25],
[64,31,29,26],
[64,32,3,0],
[64,32,3,1],
[64,32,3,2],
[64,32,5,0],
[64,32,5,1],
[64,32,5,2],
[64,32,6,0],
[64,32,6,1],
[64,32,6,2],
[64,32,7,0],
[64,32,7,1],
[64,32,7,2],
[64,32,7,3],
[64,32,7,5],
[64,32,7,6],
[64,32,8,0],
[64,32,8,1],
[64,32,8,2],
[64,32,8,7],
[64,32,9,0],
[64,32,9,1],
[64,32,9,2],
[64,32,9,3],
[64,32,9,5],
[64,32,9,6],
[64,32,9,7],
[64,32,9,8],
[64,32,10,0],
[64,32,10,1],
[64,32,10,2],
[64,32,10,3],
[64,32,10,5],
[64,32,10,6],
[64,32,10,7],
[64,32,10,8],
[64,32,10,9],
[64,32,13,0],
[64,32,13,1],
[64,32,13,2],
[64,32,13,3],
[64,32,13,5],
[64,32,13,6],
[64,32,13,7],
[64,32,13,8],
[64,32,13,9],
[64,32,13,10],
[64,32,14,0],
[64,32,14,1],
[64,32,14,2],
[64,32,14,3],
[64,32,14,5],
[64,32,14,6],
[64,32,14,7],
[64,32,14,8],
[64,32,14,9],
[64,32,14,10],
[64,32,15,0],
[64,32,15,1],
[64,32,15,2],
[64,32,15,3],
[64,32,15,5],
[64,32,15,6],
[64,32,15,7],
[64,32,15,8],
[64,32,15,10],
[64,32,15,13],
[64,32,15,14],
[64,32,16,0],
[64,32,16,1],
[64,32,16,2],
[64,32,16,3],
[64,32,16,5],
[64,32,16,6],
[64,32,16,7],
[64,32,16,8],
[64,32,16,9],
[64,32,16,10],
[64,32,16,13],
[64,32,16,14],
[64,32,16,15],
[64,32,17,0],
[64,32,17,1],
[64,32,17,2],
[64,32,17,3],
[64,32,17,5],
[64,32,17,6],
[64,32,17,7],
[64,32,17,8],
[64,32,17,9],
[64,32,17,10],
[64,32,17,13],
[64,32,17,14],
[64,32,17,15],
[64,32,17,16],
[64,32,18,0],
[64,32,18,1],
[64,32,18,2],
[64,32,18,3],
[64,32,18,5],
[64,32,18,6],
[64,32,18,7],
[64,32,18,8],
[64,32,18,10],
[64,32,18,13],
[64,32,18,14],
[64,32,18,16],
[64,32,18,17],
[64,32,19,0],
[64,32,19,1],
[64,32,19,2],
[64,32,19,3],
[64,32,19,5],
[64,32,19,6],
[64,32,19,7],
[64,32,19,8],
[64,32,19,9],
[64,32,19,10],
[64,32,19,15],
[64,32,19,16],
[64,32,19,17],
[64,32,19,18],
[64,32,20,0],
[64,32,20,1],
[64,32,20,2],
[64,32,20,3],
[64,32,20,5],
[64,32,20,6],
[64,32,20,7],
[64,32,20,8],
[64,32,20,9],
[64,32,20,13],
[64,32,20,14],
[64,32,20,15],
[64,32,20,16],
[64,32,20,17],
[64,32,20,18],
[64,32,20,19],
[64,32,21,0],
[64,32,21,1],
[64,32,21,2],
[64,32,21,7],
[64,32,21,9],
[64,32,21,10],
[64,32,21,13],
[64,32,21,14],
[64,32,21,15],
[64,32,21,16],
[64,32,21,17],
[64,32,21,18],
[64,32,21,19],
[64,32,21,20],
[64,32,22,0],
[64,32,22,1],
[64,32,22,2],
[64,32,22,3],
[64,32,22,5],
[64,32,22,6],
[64,32,22,7],
[64,32,22,8],
[64,32,22,9],
[64,32,22,13],
[64,32,22,14],
[64,32,22,15],
[64,32,22,16],
[64,32,22,17],
[64,32,22,18],
[64,32,22,19],
[64,32,22,21],
[64,32,23,0],
[64,32,23,1],
[64,32,23,2],
[64,32,23,3],
[64,32,23,5],
[64,32,23,6],
[64,32,23,7],
[64,32,23,8],
[64,32,23,9],
[64,32,23,10],
[64,32,23,13],
[64,32,23,14],
[64,32,23,15],
[64,32,23,16],
[64,32,23,17],
[64,32,23,18],
[64,32,23,20],
[64,32,23,21],
[64,32,23,22],
[64,32,24,0],
[64,32,24,1],
[64,32,24,2],
[64,32,24,3],
[64,32,24,5],
[64,32,24,6],
[64,32,24,7],
[64,32,24,8],
[64,32,24,9],
[64,32,24,10],
[64,32,24,13],
[64,32,24,14],
[64,32,24,15],
[64,32,24,17],
[64,32,24,18],
[64,32,24,20],
[64,32,24,21],
[64,32,24,22],
[64,32,24,23],
[64,32,25,0],
[64,32,25,1],
[64,32,25,2],
[64,32,25,3],
[64,32,25,5],
[64,32,25,6],
[64,32,25,8],
[64,32,25,9],
[64,32,25,10],
[64,32,25,13],
[64,32,25,14],
[64,32,25,15],
[64,32,25,18],
[64,32,25,19],
[64,32,25,20],
[64,32,25,21],
[64,32,25,22],
[64,32,25,23],
[64,32,25,24],
[64,32,26,0],
[64,32,26,1],
[64,32,26,2],
[64,32,26,3],
[64,32,26,5],
[64,32,26,6],
[64,32,26,7],
[64,32,26,8],
[64,32,26,10],
[64,32,26,13],
[64,32,26,14],
[64,32,26,16],
[64,32,26,17],
[64,32,26,19],
[64,32,26,20],
[64,32,26,21],
[64,32,26,22],
[64,32,26,23],
[64,32,26,24],
[64,32,26,25],
[64,32,27,0],
[64,32,27,1],
[64,32,27,2],
[64,32,27,3],
[64,32,27,5],
[64,32,27,6],
[64,32,27,7],
[64,32,27,8],
[64,32,27,9],
[64,32,27,13],
[64,32,27,15],
[64,32,27,16],
[64,32,27,17],
[64,32,27,18],
[64,32,27,19],
[64,32,27,21],
[64,32,27,23],
[64,32,27,24],
[64,32,27,25],
[64,32,27,26],
[64,32,28,0],
[64,32,28,1],
[64,32,28,2],
[64,32,28,3],
[64,32,28,5],
[64,32,28,6],
[64,32,28,8],
[64,32,28,9],
[64,32,28,10],
[64,32,28,14],
[64,32,28,15],
[64,32,28,16],
[64,32,28,17],
[64,32,28,18],
[64,32,28,19],
[64,32,28,20],
[64,32,28,21],
[64,32,28,22],
[64,32,28,23],
[64,32,28,24],
[64,32,28,26],
[64,32,30,0],
[64,32,30,1],
[64,32,30,2],
[64,32,30,3],
[64,32,30,5],
[64,32,30,6],
[64,32,30,7],
[64,32,30,10],
[64,32,30,13],
[64,32,30,14],
[64,32,30,15],
[64,32,30,16],
[64,32,30,17],
[64,32,30,18],
[64,32,30,19],
[64,32,30,20],
[64,32,30,21],
[64,32,30,22],
[64,32,30,24],
[64,32,31,0],
[64,32,31,1],
[64,32,31,2],
[64,32,31,3],
[64,32,31,5],
[64,32,31,8],
[64,32,31,9],
[64,32,31,10],
[64,32,31,13],
[64,32,31,14],
[64,32,31,15],
[64,32,31,16],
[64,32,31,17],
[64,32,31,18],
[64,32,31,19],
[64,32,31,20],
[64,32,31,21],
[64,32,31,22],
[64,32,31,24],
[64,33,3,0],
[64,33,3,1],
[64,33,3,2],
[64,33,5,0],
[64,33,5,1],
[64,33,5,2],
[64,33,6,0],
[64,33,6,1],
[64,33,6,2],
[64,33,7,0],
[64,33,7,1],
[64,33,7,2],
[64,33,7,3],
[64,33,7,5],
[64,33,7,6],
[64,33,8,0],
[64,33,8,1],
[64,33,8,2],
[64,33,8,7],
[64,33,9,0],
[64,33,9,1],
[64,33,9,2],
[64,33,9,3],
[64,33,9,5],
[64,33,9,6],
[64,33,9,7],
[64,33,9,8],
[64,33,10,0],
[64,33,10,1],
[64,33,10,2],
[64,33,10,3],
[64,33,10,5],
[64,33,10,6],
[64,33,10,7],
[64,33,10,8],
[64,33,10,9],
[64,33,13,0],
[64,33,13,1],
[64,33,13,2],
[64,33,13,3],
[64,33,13,5],
[64,33,13,6],
[64,33,13,7],
[64,33,13,8],
[64,33,13,9],
[64,33,13,10],
[64,33,14,0],
[64,33,14,1],
[64,33,14,2],
[64,33,14,3],
[64,33,14,5],
[64,33,14,6],
[64,33,14,7],
[64,33,14,8],
[64,33,14,9],
[64,33,14,10],
[64,33,15,0],
[64,33,15,1],
[64,33,15,2],
[64,33,15,3],
[64,33,15,5],
[64,33,15,6],
[64,33,15,7],
[64,33,15,8],
[64,33,15,10],
[64,33,15,13],
[64,33,15,14],
[64,33,16,0],
[64,33,16,1],
[64,33,16,2],
[64,33,16,3],
[64,33,16,5],
[64,33,16,6],
[64,33,16,7],
[64,33,16,8],
[64,33,16,9],
[64,33,16,10],
[64,33,16,13],
[64,33,16,14],
[64,33,16,15],
[64,33,17,0],
[64,33,17,1],
[64,33,17,2],
[64,33,17,3],
[64,33,17,5],
[64,33,17,6],
[64,33,17,7],
[64,33,17,8],
[64,33,17,9],
[64,33,17,10],
[64,33,17,13],
[64,33,17,14],
[64,33,17,15],
[64,33,17,16],
[64,33,18,0],
[64,33,18,1],
[64,33,18,2],
[64,33,18,3],
[64,33,18,5],
[64,33,18,6],
[64,33,18,7],
[64,33,18,8],
[64,33,18,10],
[64,33,18,13],
[64,33,18,14],
[64,33,18,16],
[64,33,18,17],
[64,33,19,0],
[64,33,19,1],
[64,33,19,2],
[64,33,19,3],
[64,33,19,5],
[64,33,19,6],
[64,33,19,7],
[64,33,19,8],
[64,33,19,9],
[64,33,19,10],
[64,33,19,15],
[64,33,19,16],
[64,33,19,17],
[64,33,19,18],
[64,33,20,0],
[64,33,20,1],
[64,33,20,2],
[64,33,20,3],
[64,33,20,5],
[64,33,20,6],
[64,33,20,7],
[64,33,20,8],
[64,33,20,9],
[64,33,20,13],
[64,33,20,14],
[64,33,20,15],
[64,33,20,16],
[64,33,20,17],
[64,33,20,18],
[64,33,20,19],
[64,33,21,0],
[64,33,21,1],
[64,33,21,2],
[64,33,21,7],
[64,33,21,9],
[64,33,21,10],
[64,33,21,13],
[64,33,21,14],
[64,33,21,15],
[64,33,21,16],
[64,33,21,17],
[64,33,21,18],
[64,33,21,19],
[64,33,22,0],
[64,33,22,1],
[64,33,22,2],
[64,33,22,3],
[64,33,22,5],
[64,33,22,6],
[64,33,22,7],
[64,33,22,8],
[64,33,22,9],
[64,33,22,13],
[64,33,22,14],
[64,33,22,15],
[64,33,22,16],
[64,33,22,17],
[64,33,22,18],
[64,33,22,21],
[64,33,23,0],
[64,33,23,1],
[64,33,23,2],
[64,33,23,3],
[64,33,23,5],
[64,33,23,6],
[64,33,23,7],
[64,33,23,8],
[64,33,23,9],
[64,33,23,10],
[64,33,23,13],
[64,33,23,14],
[64,33,23,15],
[64,33,23,16],
[64,33,23,17],
[64,33,23,19],
[64,33,23,20],
[64,33,23,21],
[64,33,23,22],
[64,33,24,0],
[64,33,24,1],
[64,33,24,2],
[64,33,24,3],
[64,33,24,5],
[64,33,24,6],
[64,33,24,7],
[64,33,24,8],
[64,33,24,9],
[64,33,24,10],
[64,33,24,13],
[64,33,24,14],
[64,33,24,15],
[64,33,24,17],
[64,33,24,19],
[64,33,24,20],
[64,33,24,21],
[64,33,24,22],
[64,33,24,23],
[64,33,25,0],
[64,33,25,1],
[64,33,25,2],
[64,33,25,3],
[64,33,25,5],
[64,33,25,6],
[64,33,25,8],
[64,33,25,9],
[64,33,25,10],
[64,33,25,13],
[64,33,25,14],
[64,33,25,16],
[64,33,25,17],
[64,33,25,18],
[64,33,25,19],
[64,33,25,20],
[64,33,25,21],
[64,33,25,22],
[64,33,25,23],
[64,33,25,24],
[64,33,26,0],
[64,33,26,1],
[64,33,26,2],
[64,33,26,3],
[64,33,26,5],
[64,33,26,6],
[64,33,26,7],
[64,33,26,8],
[64,33,26,10],
[64,33,26,13],
[64,33,26,16],
[64,33,26,17],
[64,33,26,19],
[64,33,26,20],
[64,33,26,21],
[64,33,26,22],
[64,33,26,23],
[64,33,26,24],
[64,33,26,25],
[64,33,27,0],
[64,33,27,1],
[64,33,27,2],
[64,33,27,3],
[64,33,27,5],
[64,33,27,6],
[64,33,27,7],
[64,33,27,8],
[64,33,27,9],
[64,33,27,14],
[64,33,27,15],
[64,33,27,16],
[64,33,27,17],
[64,33,27,18],
[64,33,27,19],
[64,33,27,21],
[64,33,27,23],
[64,33,27,24],
[64,33,27,25],
[64,33,27,26],
[64,33,28,0],
[64,33,28,1],
[64,33,28,2],
[64,33,28,3],
[64,33,28,5],
[64,33,28,6],
[64,33,28,8],
[64,33,28,9],
[64,33,28,13],
[64,33,28,14],
[64,33,28,15],
[64,33,28,16],
[64,33,28,17],
[64,33,28,18],
[64,33,28,19],
[64,33,28,20],
[64,33,28,21],
[64,33,28,22],
[64,33,28,23],
[64,33,28,24],
[64,33,30,0],
[64,33,30,1],
[64,33,30,2],
[64,33,30,3],
[64,33,30,5],
[64,33,30,8],
[64,33,30,9],
[64,33,30,10],
[64,33,30,13],
[64,33,30,14],
[64,33,30,15],
[64,33,30,16],
[64,33,30,17],
[64,33,30,18],
[64,33,30,19],
[64,33,30,20],
[64,33,30,21],
[64,33,30,22],
[64,33,30,24],
[64,33,31,0],
[64,33,31,1],
[64,33,31,2],
[64,33,31,3],
[64,33,31,6],
[64,33,31,7],
[64,33,31,8],
[64,33,31,9],
[64,33,31,10],
[64,33,31,13],
[64,33,31,14],
[64,33,31,15],
[64,33,31,16],
[64,33,31,17],
[64,33,31,18],
[64,33,31,19],
[64,33,31,20],
[64,33,31,21],
[64,33,31,22],
[64,34,3,0],
[64,34,3,1],
[64,34,3,2],
[64,34,5,0],
[64,34,5,1],
[64,34,5,2],
[64,34,6,0],
[64,34,6,1],
[64,34,6,2],
[64,34,8,0],
[64,34,8,1],
[64,34,8,2],
[64,34,9,0],
[64,34,9,1],
[64,34,9,2],
[64,34,9,3],
[64,34,9,5],
[64,34,9,6],
[64,34,9,8],
[64,34,10,0],
[64,34,10,1],
[64,34,10,2],
[64,34,10,3],
[64,34,10,5],
[64,34,10,6],
[64,34,10,8],
[64,34,10,9],
[64,34,11,0],
[64,34,11,1],
[64,34,11,2],
[64,34,11,3],
[64,34,11,5],
[64,34,11,6],
[64,34,11,8],
[64,34,11,9],
[64,34,11,10],
[64,34,12,0],
[64,34,12,1],
[64,34,12,2],
[64,34,12,3],
[64,34,12,5],
[64,34,12,6],
[64,34,12,8],
[64,34,12,9],
[64,34,12,10],
[64,34,13,0],
[64,34,13,1],
[64,34,13,2],
[64,34,13,3],
[64,34,13,5],
[64,34,13,6],
[64,34,13,8],
[64,34,13,9],
[64,34,13,10],
[64,34,13,11],
[64,34,13,12],
[64,34,14,0],
[64,34,14,1],
[64,34,14,2],
[64,34,14,3],
[64,34,14,5],
[64,34,14,6],
[64,34,14,8],
[64,34,14,9],
[64,34,14,10],
[64,34,14,11],
[64,34,14,12],
[64,34,15,0],
[64,34,15,1],
[64,34,15,2],
[64,34,15,3],
[64,34,15,5],
[64,34,15,6],
[64,34,15,8],
[64,34,15,10],
[64,34,15,11],
[64,34,15,12],
[64,34,15,13],
[64,34,15,14],
[64,34,16,0],
[64,34,16,1],
[64,34,16,2],
[64,34,16,3],
[64,34,16,5],
[64,34,16,6],
[64,34,16,8],
[64,34,16,9],
[64,34,16,10],
[64,34,16,11],
[64,34,16,12],
[64,34,16,13],
[64,34,16,14],
[64,34,16,15],
[64,34,17,0],
[64,34,17,1],
[64,34,17,2],
[64,34,17,3],
[64,34,17,5],
[64,34,17,6],
[64,34,17,8],
[64,34,17,9],
[64,34,17,10],
[64,34,17,11],
[64,34,17,12],
[64,34,17,13],
[64,34,17,14],
[64,34,17,15],
[64,34,17,16],
[64,34,18,0],
[64,34,18,1],
[64,34,18,2],
[64,34,18,3],
[64,34,18,5],
[64,34,18,6],
[64,34,18,8],
[64,34,18,10],
[64,34,18,11],
[64,34,18,12],
[64,34,18,13],
[64,34,18,14],
[64,34,18,16],
[64,34,18,17],
[64,34,19,0],
[64,34,19,1],
[64,34,19,2],
[64,34,19,3],
[64,34,19,5],
[64,34,19,6],
[64,34,19,8],
[64,34,19,9],
[64,34,19,10],
[64,34,19,11],
[64,34,19,12],
[64,34,19,15],
[64,34,19,16],
[64,34,19,17],
[64,34,19,18],
[64,34,20,0],
[64,34,20,1],
[64,34,20,2],
[64,34,20,3],
[64,34,20,5],
[64,34,20,6],
[64,34,20,8],
[64,34,20,9],
[64,34,20,11],
[64,34,20,12],
[64,34,20,13],
[64,34,20,14],
[64,34,20,15],
[64,34,20,16],
[64,34,20,17],
[64,34,20,18],
[64,34,20,19],
[64,34,21,0],
[64,34,21,1],
[64,34,21,2],
[64,34,21,9],
[64,34,21,10],
[64,34,21,11],
[64,34,21,12],
[64,34,21,13],
[64,34,21,14],
[64,34,21,15],
[64,34,21,16],
[64,34,21,17],
[64,34,21,18],
[64,34,21,20],
[64,34,22,0],
[64,34,22,1],
[64,34,22,2],
[64,34,22,3],
[64,34,22,5],
[64,34,22,6],
[64,34,22,8],
[64,34,22,9],
[64,34,22,11],
[64,34,22,12],
[64,34,22,13],
[64,34,22,14],
[64,34,22,15],
[64,34,22,16],
[64,34,22,17],
[64,34,22,19],
[64,34,22,21],
[64,34,23,0],
[64,34,23,1],
[64,34,23,2],
[64,34,23,3],
[64,34,23,5],
[64,34,23,6],
[64,34,23,8],
[64,34,23,9],
[64,34,23,10],
[64,34,23,11],
[64,34,23,12],
[64,34,23,13],
[64,34,23,14],
[64,34,23,15],
[64,34,23,18],
[64,34,23,19],
[64,34,23,20],
[64,34,23,21],
[64,34,23,22],
[64,34,24,0],
[64,34,24,1],
[64,34,24,2],
[64,34,24,3],
[64,34,24,5],
[64,34,24,6],
[64,34,24,8],
[64,34,24,9],
[64,34,24,10],
[64,34,24,11],
[64,34,24,12],
[64,34,24,13],
[64,34,24,14],
[64,34,24,15],
[64,34,24,18],
[64,34,24,19],
[64,34,24,20],
[64,34,24,21],
[64,34,24,22],
[64,34,24,23],
[64,34,26,0],
[64,34,26,1],
[64,34,26,2],
[64,34,26,3],
[64,34,26,5],
[64,34,26,6],
[64,34,26,8],
[64,34,26,10],
[64,34,26,11],
[64,34,26,14],
[64,34,26,16],
[64,34,26,17],
[64,34,26,19],
[64,34,26,20],
[64,34,26,21],
[64,34,26,22],
[64,34,26,23],
[64,34,26,24],
[64,34,27,0],
[64,34,27,1],
[64,34,27,2],
[64,34,27,3],
[64,34,27,5],
[64,34,27,6],
[64,34,27,8],
[64,34,27,9],
[64,34,27,12],
[64,34,27,13],
[64,34,27,14],
[64,34,27,15],
[64,34,27,16],
[64,34,27,17],
[64,34,27,18],
[64,34,27,19],
[64,34,27,21],
[64,34,27,23],
[64,34,27,24],
[64,34,29,0],
[64,34,29,1],
[64,34,29,2],
[64,34,29,3],
[64,34,29,5],
[64,34,29,8],
[64,34,29,9],
[64,34,29,10],
[64,34,29,13],
[64,34,29,14],
[64,34,29,15],
[64,34,29,16],
[64,34,29,17],
[64,34,29,18],
[64,34,29,19],
[64,34,29,20],
[64,34,29,21],
[64,34,29,22],
[64,34,29,23],
[64,34,29,24],
[64,34,30,0],
[64,34,30,1],
[64,34,30,2],
[64,34,30,3],
[64,34,30,6],
[64,34,30,8],
[64,34,30,9],
[64,34,30,10],
[64,34,30,11],
[64,34,30,12],
[64,34,30,13],
[64,34,30,14],
[64,34,30,15],
[64,34,30,16],
[64,34,30,17],
[64,34,30,18],
[64,34,30,19],
[64,34,30,20],
[64,34,30,21],
[64,34,30,22],
[64,34,31,0],
[64,34,31,1],
[64,34,31,2],
[64,34,31,5],
[64,34,31,6],
[64,34,31,8],
[64,34,31,9],
[64,34,31,10],
[64,34,31,11],
[64,34,31,12],
[64,34,31,13],
[64,34,31,14],
[64,34,31,15],
[64,34,31,16],
[64,34,31,17],
[64,34,31,18],
[64,34,31,19],
[64,34,31,20],
[64,34,31,21],
[64,34,32,3],
[64,34,32,5],
[64,34,32,6],
[64,34,32,8],
[64,34,32,9],
[64,34,32,10],
[64,34,32,13],
[64,34,32,14],
[64,34,32,15],
[64,34,32,16],
[64,34,32,17],
[64,34,32,18],
[64,34,32,19],
[64,34,32,20],
[64,34,33,0],
[64,34,33,1],
[64,34,33,2],
[64,34,33,3],
[64,34,33,5],
[64,34,33,6],
[64,34,33,8],
[64,34,33,9],
[64,34,33,10],
[64,34,33,13],
[64,34,33,14],
[64,34,33,15],
[64,34,33,16],
[64,34,33,17],
[64,34,33,18],
[64,34,33,19],
[64,35,3,0],
[64,35,3,1],
[64,35,3,2],
[64,35,5,0],
[64,35,5,1],
[64,35,5,2],
[64,35,6,0],
[64,35,6,1],
[64,35,6,2],
[64,35,7,0],
[64,35,7,1],
[64,35,7,2],
[64,35,7,3],
[64,35,7,5],
[64,35,7,6],
[64,35,8,0],
[64,35,8,1],
[64,35,8,2],
[64,35,8,7],
[64,35,10,0],
[64,35,10,1],
[64,35,10,2],
[64,35,10,3],
[64,35,10,5],
[64,35,10,6],
[64,35,10,7],
[64,35,10,8],
[64,35,11,0],
[64,35,11,1],
[64,35,11,2],
[64,35,11,3],
[64,35,11,5],
[64,35,11,6],
[64,35,11,7],
[64,35,11,8],
[64,35,11,10],
[64,35,12,0],
[64,35,12,1],
[64,35,12,2],
[64,35,12,3],
[64,35,12,5],
[64,35,12,6],
[64,35,12,7],
[64,35,12,8],
[64,35,12,10],
[64,35,13,0],
[64,35,13,1],
[64,35,13,2],
[64,35,13,3],
[64,35,13,5],
[64,35,13,6],
[64,35,13,7],
[64,35,13,8],
[64,35,13,10],
[64,35,13,11],
[64,35,13,12],
[64,35,14,0],
[64,35,14,1],
[64,35,14,2],
[64,35,14,3],
[64,35,14,5],
[64,35,14,6],
[64,35,14,7],
[64,35,14,8],
[64,35,14,10],
[64,35,14,11],
[64,35,14,12],
[64,35,16,0],
[64,35,16,1],
[64,35,16,2],
[64,35,16,3],
[64,35,16,5],
[64,35,16,6],
[64,35,16,7],
[64,35,16,8],
[64,35,16,10],
[64,35,16,11],
[64,35,16,12],
[64,35,16,13],
[64,35,16,14],
[64,35,17,0],
[64,35,17,1],
[64,35,17,2],
[64,35,17,3],
[64,35,17,5],
[64,35,17,6],
[64,35,17,7],
[64,35,17,8],
[64,35,17,10],
[64,35,17,11],
[64,35,17,12],
[64,35,17,13],
[64,35,17,14],
[64,35,17,16],
[64,35,19,0],
[64,35,19,1],
[64,35,19,2],
[64,35,19,3],
[64,35,19,5],
[64,35,19,6],
[64,35,19,7],
[64,35,19,8],
[64,35,19,10],
[64,35,19,11],
[64,35,19,12],
[64,35,19,16],
[64,35,19,17],
[64,35,20,0],
[64,35,20,1],
[64,35,20,2],
[64,35,20,3],
[64,35,20,5],
[64,35,20,6],
[64,35,20,7],
[64,35,20,8],
[64,35,20,11],
[64,35,20,12],
[64,35,20,13],
[64,35,20,14],
[64,35,20,16],
[64,35,20,17],
[64,35,21,0],
[64,35,21,1],
[64,35,21,2],
[64,35,21,7],
[64,35,21,10],
[64,35,21,11],
[64,35,21,12],
[64,35,21,13],
[64,35,21,14],
[64,35,21,16],
[64,35,21,17],
[64,35,21,19],
[64,35,21,20],
[64,35,22,0],
[64,35,22,1],
[64,35,22,2],
[64,35,22,3],
[64,35,22,5],
[64,35,22,6],
[64,35,22,7],
[64,35,22,8],
[64,35,22,11],
[64,35,22,12],
[64,35,22,13],
[64,35,22,14],
[64,35,22,19],
[64,35,22,21],
[64,35,23,0],
[64,35,23,1],
[64,35,23,2],
[64,35,23,3],
[64,35,23,5],
[64,35,23,6],
[64,35,23,7],
[64,35,23,8],
[64,35,23,10],
[64,35,23,11],
[64,35,23,12],
[64,35,23,13],
[64,35,23,14],
[64,35,23,16],
[64,35,23,17],
[64,35,23,19],
[64,35,23,20],
[64,35,23,21],
[64,35,23,22],
[64,35,24,0],
[64,35,24,1],
[64,35,24,2],
[64,35,24,3],
[64,35,24,5],
[64,35,24,6],
[64,35,24,7],
[64,35,24,8],
[64,35,24,10],
[64,35,24,11],
[64,35,24,12],
[64,35,24,13],
[64,35,24,14],
[64,35,24,17],
[64,35,24,19],
[64,35,24,20],
[64,35,24,21],
[64,35,24,22],
[64,35,24,23],
[64,35,25,0],
[64,35,25,1],
[64,35,25,2],
[64,35,25,3],
[64,35,25,5],
[64,35,25,6],
[64,35,25,8],
[64,35,25,10],
[64,35,25,11],
[64,35,25,14],
[64,35,25,16],
[64,35,25,17],
[64,35,25,19],
[64,35,25,20],
[64,35,25,21],
[64,35,25,22],
[64,35,25,23],
[64,35,25,24],
[64,35,27,0],
[64,35,27,1],
[64,35,27,2],
[64,35,27,3],
[64,35,27,5],
[64,35,27,6],
[64,35,27,7],
[64,35,27,11],
[64,35,27,12],
[64,35,27,13],
[64,35,27,14],
[64,35,27,16],
[64,35,27,17],
[64,35,27,19],
[64,35,27,21],
[64,35,27,23],
[64,35,27,24],
[64,35,28,0],
[64,35,28,1],
[64,35,28,2],
[64,35,28,3],
[64,35,28,5],
[64,35,28,8],
[64,35,28,10],
[64,35,28,11],
[64,35,28,12],
[64,35,28,13],
[64,35,28,14],
[64,35,28,16],
[64,35,28,17],
[64,35,28,19],
[64,35,28,20],
[64,35,28,21],
[64,35,28,22],
[64,35,28,23],
[64,35,28,24],
[64,35,29,0],
[64,35,29,1],
[64,35,29,2],
[64,35,29,3],
[64,35,29,6],
[64,35,29,7],
[64,35,29,8],
[64,35,29,10],
[64,35,29,13],
[64,35,29,14],
[64,35,29,16],
[64,35,29,17],
[64,35,29,19],
[64,35,29,20],
[64,35,29,21],
[64,35,29,22],
[64,35,30,0],
[64,35,30,1],
[64,35,30,2],
[64,35,30,5],
[64,35,30,6],
[64,35,30,7],
[64,35,30,8],
[64,35,30,10],
[64,35,30,11],
[64,35,30,12],
[64,35,30,13],
[64,35,30,14],
[64,35,30,16],
[64,35,30,17],
[64,35,30,19],
[64,35,30,20],
[64,35,30,21],
[64,35,31,3],
[64,35,31,5],
[64,35,31,6],
[64,35,31,7],
[64,35,31,8],
[64,35,31,10],
[64,35,31,11],
[64,35,31,12],
[64,35,31,13],
[64,35,31,14],
[64,35,31,16],
[64,35,31,17],
[64,35,31,19],
[64,35,31,20],
[64,35,32,0],
[64,35,32,1],
[64,35,32,2],
[64,35,32,3],
[64,35,32,5],
[64,35,32,6],
[64,35,32,7],
[64,35,32,8],
[64,35,32,10],
[64,35,32,13],
[64,35,32,14],
[64,35,32,16],
[64,35,32,17],
[64,35,32,19],
[64,35,33,0],
[64,35,33,1],
[64,35,33,2],
[64,35,33,3],
[64,35,33,5],
[64,35,33,6],
[64,35,33,7],
[64,35,33,8],
[64,35,33,10],
[64,35,33,13],
[64,35,33,14],
[64,35,33,16],
[64,35,33,17],
[64,35,34,0],
[64,35,34,1],
[64,35,34,2],
[64,35,34,3],
[64,35,34,5],
[64,35,34,6],
[64,35,34,8],
[64,35,34,10],
[64,35,34,11],
[64,35,34,12],
[64,35,34,13],
[64,35,34,14],
[64,35,34,16],
[64,35,34,17],
[64,36,3,0],
[64,36,3,1],
[64,36,3,2],
[64,36,5,0],
[64,36,5,1],
[64,36,5,2],
[64,36,6,0],
[64,36,6,1],
[64,36,6,2],
[64,36,7,0],
[64,36,7,1],
[64,36,7,2],
[64,36,7,3],
[64,36,7,5],
[64,36,7,6],
[64,36,8,0],
[64,36,8,1],
[64,36,8,2],
[64,36,8,7],
[64,36,9,0],
[64,36,9,1],
[64,36,9,2],
[64,36,9,3],
[64,36,9,5],
[64,36,9,6],
[64,36,9,7],
[64,36,9,8],
[64,36,10,0],
[64,36,10,1],
[64,36,10,2],
[64,36,10,3],
[64,36,10,5],
[64,36,10,6],
[64,36,10,7],
[64,36,10,8],
[64,36,10,9],
[64,36,11,0],
[64,36,11,1],
[64,36,11,2],
[64,36,11,3],
[64,36,11,5],
[64,36,11,6],
[64,36,11,7],
[64,36,11,8],
[64,36,11,9],
[64,36,11,10],
[64,36,12,0],
[64,36,12,1],
[64,36,12,2],
[64,36,12,3],
[64,36,12,5],
[64,36,12,6],
[64,36,12,7],
[64,36,12,8],
[64,36,12,9],
[64,36,12,10],
[64,36,15,0],
[64,36,15,1],
[64,36,15,2],
[64,36,15,3],
[64,36,15,5],
[64,36,15,6],
[64,36,15,7],
[64,36,15,8],
[64,36,15,10],
[64,36,15,11],
[64,36,15,12],
[64,36,16,0],
[64,36,16,1],
[64,36,16,2],
[64,36,16,3],
[64,36,16,5],
[64,36,16,6],
[64,36,16,7],
[64,36,16,8],
[64,36,16,9],
[64,36,16,10],
[64,36,16,11],
[64,36,16,12],
[64,36,16,15],
[64,36,17,0],
[64,36,17,1],
[64,36,17,2],
[64,36,17,3],
[64,36,17,5],
[64,36,17,6],
[64,36,17,7],
[64,36,17,8],
[64,36,17,9],
[64,36,17,10],
[64,36,17,11],
[64,36,17,12],
[64,36,17,15],
[64,36,17,16],
[64,36,18,0],
[64,36,18,1],
[64,36,18,2],
[64,36,18,3],
[64,36,18,5],
[64,36,18,6],
[64,36,18,7],
[64,36,18,8],
[64,36,18,10],
[64,36,18,11],
[64,36,18,12],
[64,36,18,16],
[64,36,18,17],
[64,36,20,0],
[64,36,20,1],
[64,36,20,2],
[64,36,20,3],
[64,36,20,5],
[64,36,20,6],
[64,36,20,7],
[64,36,20,8],
[64,36,20,9],
[64,36,20,11],
[64,36,20,12],
[64,36,20,15],
[64,36,20,16],
[64,36,20,17],
[64,36,21,0],
[64,36,21,1],
[64,36,21,2],
[64,36,21,7],
[64,36,21,9],
[64,36,21,10],
[64,36,21,11],
[64,36,21,12],
[64,36,21,15],
[64,36,21,18],
[64,36,21,20],
[64,36,22,0],
[64,36,22,1],
[64,36,22,2],
[64,36,22,3],
[64,36,22,5],
[64,36,22,6],
[64,36,22,7],
[64,36,22,8],
[64,36,22,9],
[64,36,22,11],
[64,36,22,12],
[64,36,22,16],
[64,36,22,17],
[64,36,22,18],
[64,36,22,21],
[64,36,23,0],
[64,36,23,1],
[64,36,23,2],
[64,36,23,3],
[64,36,23,5],
[64,36,23,6],
[64,36,23,7],
[64,36,23,8],
[64,36,23,9],
[64,36,23,10],
[64,36,23,11],
[64,36,23,12],
[64,36,23,15],
[64,36,23,16],
[64,36,23,17],
[64,36,23,18],
[64,36,23,20],
[64,36,23,21],
[64,36,23,22],
[64,36,24,0],
[64,36,24,1],
[64,36,24,2],
[64,36,24,3],
[64,36,24,5],
[64,36,24,6],
[64,36,24,7],
[64,36,24,8],
[64,36,24,9],
[64,36,24,10],
[64,36,24,11],
[64,36,24,12],
[64,36,24,15],
[64,36,24,17],
[64,36,24,18],
[64,36,24,20],
[64,36,24,21],
[64,36,24,22],
[64,36,24,23],
[64,36,25,0],
[64,36,25,1],
[64,36,25,2],
[64,36,25,3],
[64,36,25,5],
[64,36,25,6],
[64,36,25,8],
[64,36,25,9],
[64,36,25,12],
[64,36,25,15],
[64,36,25,16],
[64,36,25,17],
[64,36,25,18],
[64,36,25,20],
[64,36,25,21],
[64,36,25,22],
[64,36,25,23],
[64,36,25,24],
[64,36,26,0],
[64,36,26,1],
[64,36,26,2],
[64,36,26,3],
[64,36,26,5],
[64,36,26,6],
[64,36,26,7],
[64,36,26,10],
[64,36,26,11],
[64,36,26,12],
[64,36,26,16],
[64,36,26,17],
[64,36,26,20],
[64,36,26,21],
[64,36,26,22],
[64,36,26,23],
[64,36,26,24],
[64,36,27,0],
[64,36,27,1],
[64,36,27,2],
[64,36,27,3],
[64,36,27,5],
[64,36,27,8],
[64,36,27,9],
[64,36,27,11],
[64,36,27,12],
[64,36,27,15],
[64,36,27,16],
[64,36,27,17],
[64,36,27,18],
[64,36,27,21],
[64,36,27,23],
[64,36,27,24],
[64,36,28,0],
[64,36,28,1],
[64,36,28,2],
[64,36,28,3],
[64,36,28,6],
[64,36,28,8],
[64,36,28,9],
[64,36,28,10],
[64,36,28,11],
[64,36,28,12],
[64,36,28,15],
[64,36,28,16],
[64,36,28,17],
[64,36,28,18],
[64,36,28,20],
[64,36,28,21],
[64,36,28,22],
[64,36,29,0],
[64,36,29,1],
[64,36,29,2],
[64,36,29,5],
[64,36,29,6],
[64,36,29,7],
[64,36,29,8],
[64,36,29,9],
[64,36,29,10],
[64,36,29,15],
[64,36,29,16],
[64,36,29,17],
[64,36,29,18],
[64,36,29,20],
[64,36,29,21],
[64,36,30,3],
[64,36,30,5],
[64,36,30,6],
[64,36,30,7],
[64,36,30,8],
[64,36,30,9],
[64,36,30,10],
[64,36,30,11],
[64,36,30,12],
[64,36,30,15],
[64,36,30,16],
[64,36,30,17],
[64,36,30,18],
[64,36,30,20],
[64,36,31,0],
[64,36,31,1],
[64,36,31,2],
[64,36,31,3],
[64,36,31,5],
[64,36,31,6],
[64,36,31,7],
[64,36,31,8],
[64,36,31,9],
[64,36,31,10],
[64,36,31,11],
[64,36,31,12],
[64,36,31,15],
[64,36,31,16],
[64,36,31,17],
[64,36,31,18],
[64,36,32,0],
[64,36,32,1],
[64,36,32,2],
[64,36,32,3],
[64,36,32,5],
[64,36,32,6],
[64,36,32,7],
[64,36,32,8],
[64,36,32,9],
[64,36,32,10],
[64,36,32,15],
[64,36,32,16],
[64,36,32,17],
[64,36,32,18],
[64,36,33,0],
[64,36,33,1],
[64,36,33,2],
[64,36,33,3],
[64,36,33,5],
[64,36,33,6],
[64,36,33,7],
[64,36,33,8],
[64,36,33,9],
[64,36,33,10],
[64,36,33,15],
[64,36,33,16],
[64,36,33,17],
[64,36,34,0],
[64,36,34,1],
[64,36,34,2],
[64,36,34,3],
[64,36,34,5],
[64,36,34,6],
[64,36,34,8],
[64,36,34,9],
[64,36,34,10],
[64,36,34,11],
[64,36,34,12],
[64,36,34,15],
[64,36,35,0],
[64,36,35,1],
[64,36,35,2],
[64,36,35,3],
[64,36,35,5],
[64,36,35,6],
[64,36,35,7],
[64,36,35,8],
[64,36,35,10],
[64,36,35,11],
[64,36,35,12],
[64,38,3,0],
[64,38,3,1],
[64,38,3,2],
[64,38,5,0],
[64,38,5,1],
[64,38,5,2],
[64,38,6,0],
[64,38,6,1],
[64,38,6,2],
[64,38,8,0],
[64,38,8,1],
[64,38,8,2],
[64,38,9,0],
[64,38,9,1],
[64,38,9,2],
[64,38,9,3],
[64,38,9,5],
[64,38,9,6],
[64,38,9,8],
[64,38,10,0],
[64,38,10,1],
[64,38,10,2],
[64,38,10,3],
[64,38,10,5],
[64,38,10,6],
[64,38,10,8],
[64,38,10,9],
[64,38,11,0],
[64,38,11,1],
[64,38,11,2],
[64,38,11,3],
[64,38,11,5],
[64,38,11,6],
[64,38,11,8],
[64,38,11,9],
[64,38,11,10],
[64,38,12,0],
[64,38,12,1],
[64,38,12,2],
[64,38,12,3],
[64,38,12,5],
[64,38,12,6],
[64,38,12,8],
[64,38,12,9],
[64,38,12,10],
[64,38,13,0],
[64,38,13,1],
[64,38,13,2],
[64,38,13,3],
[64,38,13,5],
[64,38,13,6],
[64,38,13,8],
[64,38,13,9],
[64,38,13,10],
[64,38,13,11],
[64,38,13,12],
[64,38,14,0],
[64,38,14,1],
[64,38,14,2],
[64,38,14,3],
[64,38,14,5],
[64,38,14,6],
[64,38,14,8],
[64,38,14,9],
[64,38,14,10],
[64,38,14,11],
[64,38,14,12],
[64,38,15,0],
[64,38,15,1],
[64,38,15,2],
[64,38,15,3],
[64,38,15,5],
[64,38,15,6],
[64,38,15,8],
[64,38,15,10],
[64,38,15,11],
[64,38,15,12],
[64,38,15,13],
[64,38,15,14],
[64,38,16,0],
[64,38,16,1],
[64,38,16,2],
[64,38,16,3],
[64,38,16,5],
[64,38,16,6],
[64,38,16,8],
[64,38,16,9],
[64,38,16,10],
[64,38,16,11],
[64,38,16,12],
[64,38,16,13],
[64,38,16,14],
[64,38,16,15],
[64,38,17,0],
[64,38,17,1],
[64,38,17,2],
[64,38,17,3],
[64,38,17,5],
[64,38,17,6],
[64,38,17,8],
[64,38,17,9],
[64,38,17,10],
[64,38,17,11],
[64,38,17,12],
[64,38,17,13],
[64,38,17,14],
[64,38,17,15],
[64,38,17,16],
[64,38,18,0],
[64,38,18,1],
[64,38,18,2],
[64,38,18,3],
[64,38,18,5],
[64,38,18,6],
[64,38,18,8],
[64,38,18,10],
[64,38,18,11],
[64,38,18,12],
[64,38,18,13],
[64,38,18,14],
[64,38,18,16],
[64,38,18,17],
[64,38,19,0],
[64,38,19,1],
[64,38,19,2],
[64,38,19,3],
[64,38,19,5],
[64,38,19,6],
[64,38,19,8],
[64,38,19,9],
[64,38,19,10],
[64,38,19,11],
[64,38,19,12],
[64,38,19,15],
[64,38,19,18],
[64,38,20,0],
[64,38,20,1],
[64,38,20,2],
[64,38,20,3],
[64,38,20,5],
[64,38,20,6],
[64,38,20,8],
[64,38,20,9],
[64,38,20,11],
[64,38,20,12],
[64,38,20,13],
[64,38,20,14],
[64,38,20,16],
[64,38,20,17],
[64,38,20,18],
[64,38,20,19],
[64,38,21,0],
[64,38,21,1],
[64,38,21,2],
[64,38,21,9],
[64,38,21,10],
[64,38,21,11],
[64,38,21,12],
[64,38,21,13],
[64,38,21,15],
[64,38,21,16],
[64,38,21,17],
[64,38,21,18],
[64,38,21,19],
[64,38,21,20],
[64,38,22,0],
[64,38,22,1],
[64,38,22,2],
[64,38,22,3],
[64,38,22,5],
[64,38,22,6],
[64,38,22,8],
[64,38,22,9],
[64,38,22,11],
[64,38,22,14],
[64,38,22,15],
[64,38,22,16],
[64,38,22,17],
[64,38,22,18],
[64,38,22,19],
[64,38,22,21],
[64,38,23,0],
[64,38,23,1],
[64,38,23,2],
[64,38,23,3],
[64,38,23,5],
[64,38,23,6],
[64,38,23,8],
[64,38,23,9],
[64,38,23,12],
[64,38,23,13],
[64,38,23,14],
[64,38,23,15],
[64,38,23,16],
[64,38,23,17],
[64,38,23,18],
[64,38,23,19],
[64,38,23,20],
[64,38,23,21],
[64,38,23,22],
[64,38,24,0],
[64,38,24,1],
[64,38,24,2],
[64,38,24,3],
[64,38,24,5],
[64,38,24,6],
[64,38,24,8],
[64,38,24,9],
[64,38,24,12],
[64,38,24,13],
[64,38,24,14],
[64,38,24,15],
[64,38,24,17],
[64,38,24,18],
[64,38,24,19],
[64,38,24,20],
[64,38,24,21],
[64,38,24,22],
[64,38,24,23],
[64,38,26,0],
[64,38,26,1],
[64,38,26,2],
[64,38,26,3],
[64,38,26,6],
[64,38,26,8],
[64,38,26,10],
[64,38,26,11],
[64,38,26,12],
[64,38,26,13],
[64,38,26,14],
[64,38,26,16],
[64,38,26,17],
[64,38,26,19],
[64,38,26,20],
[64,38,26,21],
[64,38,26,22],
[64,38,27,0],
[64,38,27,1],
[64,38,27,2],
[64,38,27,5],
[64,38,27,6],
[64,38,27,8],
[64,38,27,9],
[64,38,27,11],
[64,38,27,12],
[64,38,27,13],
[64,38,27,14],
[64,38,27,15],
[64,38,27,16],
[64,38,27,17],
[64,38,27,18],
[64,38,27,19],
[64,38,27,21],
[64,38,29,0],
[64,38,29,1],
[64,38,29,2],
[64,38,29,3],
[64,38,29,5],
[64,38,29,6],
[64,38,29,8],
[64,38,29,9],
[64,38,29,10],
[64,38,29,13],
[64,38,29,14],
[64,38,29,15],
[64,38,29,16],
[64,38,29,17],
[64,38,29,18],
[64,38,29,19],
[64,38,30,0],
[64,38,30,1],
[64,38,30,2],
[64,38,30,3],
[64,38,30,5],
[64,38,30,6],
[64,38,30,8],
[64,38,30,9],
[64,38,30,10],
[64,38,30,11],
[64,38,30,12],
[64,38,30,13],
[64,38,30,14],
[64,38,30,15],
[64,38,30,16],
[64,38,30,17],
[64,38,30,18],
[64,38,31,0],
[64,38,31,1],
[64,38,31,2],
[64,38,31,3],
[64,38,31,5],
[64,38,31,6],
[64,38,31,8],
[64,38,31,9],
[64,38,31,10],
[64,38,31,11],
[64,38,31,12],
[64,38,31,13],
[64,38,31,14],
[64,38,31,15],
[64,38,31,16],
[64,38,31,17],
[64,38,32,0],
[64,38,32,1],
[64,38,32,2],
[64,38,32,3],
[64,38,32,5],
[64,38,32,6],
[64,38,32,8],
[64,38,32,9],
[64,38,32,10],
[64,38,32,13],
[64,38,32,14],
[64,38,32,15],
[64,38,33,0],
[64,38,33,1],
[64,38,33,2],
[64,38,33,3],
[64,38,33,5],
[64,38,33,6],
[64,38,33,8],
[64,38,33,9],
[64,38,33,10],
[64,38,33,13],
[64,38,33,14],
[64,38,35,0],
[64,38,35,1],
[64,38,35,2],
[64,38,35,3],
[64,38,35,5],
[64,38,35,6],
[64,38,35,8],
[64,38,35,10],
[64,38,35,11],
[64,38,36,0],
[64,38,36,1],
[64,38,36,2],
[64,38,36,3],
[64,38,36,5],
[64,38,36,6],
[64,38,36,8],
[64,38,36,9],
[64,39,7,0],
[64,39,7,1],
[64,39,7,2],
[64,39,9,0],
[64,39,9,1],
[64,39,9,2],
[64,39,9,7],
[64,39,10,0],
[64,39,10,1],
[64,39,10,2],
[64,39,10,7],
[64,39,10,9],
[64,39,11,0],
[64,39,11,1],
[64,39,11,2],
[64,39,11,7],
[64,39,11,9],
[64,39,11,10],
[64,39,12,0],
[64,39,12,1],
[64,39,12,2],
[64,39,12,7],
[64,39,12,9],
[64,39,12,10],
[64,39,13,0],
[64,39,13,1],
[64,39,13,2],
[64,39,13,7],
[64,39,13,9],
[64,39,13,10],
[64,39,13,11],
[64,39,13,12],
[64,39,14,0],
[64,39,14,1],
[64,39,14,2],
[64,39,14,7],
[64,39,14,9],
[64,39,14,10],
[64,39,14,11],
[64,39,14,12],
[64,39,15,0],
[64,39,15,1],
[64,39,15,2],
[64,39,15,7],
[64,39,15,10],
[64,39,15,11],
[64,39,15,12],
[64,39,15,13],
[64,39,15,14],
[64,39,16,0],
[64,39,16,1],
[64,39,16,2],
[64,39,16,7],
[64,39,16,9],
[64,39,16,10],
[64,39,16,11],
[64,39,16,12],
[64,39,16,13],
[64,39,16,14],
[64,39,16,15],
[64,39,17,0],
[64,39,17,1],
[64,39,17,2],
[64,39,17,7],
[64,39,17,9],
[64,39,17,10],
[64,39,17,11],
[64,39,17,12],
[64,39,17,13],
[64,39,17,14],
[64,39,17,15],
[64,39,17,16],
[64,39,18,0],
[64,39,18,1],
[64,39,18,2],
[64,39,18,7],
[64,39,18,10],
[64,39,18,11],
[64,39,18,12],
[64,39,18,13],
[64,39,18,14],
[64,39,19,0],
[64,39,19,1],
[64,39,19,2],
[64,39,19,7],
[64,39,19,9],
[64,39,19,10],
[64,39,19,11],
[64,39,19,12],
[64,39,19,16],
[64,39,19,17],
[64,39,19,18],
[64,39,20,0],
[64,39,20,1],
[64,39,20,2],
[64,39,20,7],
[64,39,20,9],
[64,39,20,11],
[64,39,20,12],
[64,39,20,13],
[64,39,20,15],
[64,39,20,16],
[64,39,20,17],
[64,39,20,18],
[64,39,20,19],
[64,39,22,0],
[64,39,22,1],
[64,39,22,2],
[64,39,22,7],
[64,39,22,9],
[64,39,22,12],
[64,39,22,13],
[64,39,22,14],
[64,39,22,15],
[64,39,22,16],
[64,39,22,17],
[64,39,22,18],
[64,39,22,19],
[64,39,23,0],
[64,39,23,1],
[64,39,23,2],
[64,39,23,7],
[64,39,23,10],
[64,39,23,11],
[64,39,23,12],
[64,39,23,13],
[64,39,23,14],
[64,39,23,15],
[64,39,23,16],
[64,39,23,17],
[64,39,23,18],
[64,39,23,19],
[64,39,23,20],
[64,39,23,22],
[64,39,24,0],
[64,39,24,1],
[64,39,24,2],
[64,39,24,7],
[64,39,24,10],
[64,39,24,11],
[64,39,24,12],
[64,39,24,13],
[64,39,24,14],
[64,39,24,15],
[64,39,24,17],
[64,39,24,18],
[64,39,24,19],
[64,39,24,20],
[64,39,24,22],
[64,39,24,23],
[64,39,25,0],
[64,39,25,1],
[64,39,25,2],
[64,39,25,9],
[64,39,25,10],
[64,39,25,11],
[64,39,25,12],
[64,39,25,13],
[64,39,25,14],
[64,39,25,15],
[64,39,25,16],
[64,39,25,17],
[64,39,25,18],
[64,39,25,19],
[64,39,25,20],
[64,39,25,22],
[64,39,26,0],
[64,39,26,1],
[64,39,26,2],
[64,39,26,7],
[64,39,26,10],
[64,39,26,11],
[64,39,26,12],
[64,39,26,13],
[64,39,26,14],
[64,39,26,16],
[64,39,26,17],
[64,39,26,19],
[64,39,26,20],
[64,39,27,7],
[64,39,27,9],
[64,39,27,11],
[64,39,27,12],
[64,39,27,13],
[64,39,27,14],
[64,39,27,15],
[64,39,27,16],
[64,39,27,17],
[64,39,27,18],
[64,39,27,19],
[64,39,28,0],
[64,39,28,1],
[64,39,28,2],
[64,39,28,9],
[64,39,28,10],
[64,39,28,11],
[64,39,28,12],
[64,39,28,13],
[64,39,28,14],
[64,39,28,15],
[64,39,28,16],
[64,39,28,17],
[64,39,28,18],
[64,39,28,19],
[64,39,29,0],
[64,39,29,1],
[64,39,29,2],
[64,39,29,7],
[64,39,29,9],
[64,39,29,10],
[64,39,29,13],
[64,39,29,14],
[64,39,29,15],
[64,39,29,16],
[64,39,29,17],
[64,39,29,18],
[64,39,30,0],
[64,39,30,1],
[64,39,30,2],
[64,39,30,7],
[64,39,30,9],
[64,39,30,10],
[64,39,30,11],
[64,39,30,12],
[64,39,30,13],
[64,39,30,14],
[64,39,30,15],
[64,39,30,16],
[64,39,30,17],
[64,39,31,0],
[64,39,31,1],
[64,39,31,2],
[64,39,31,7],
[64,39,31,9],
[64,39,31,10],
[64,39,31,11],
[64,39,31,12],
[64,39,31,13],
[64,39,31,14],
[64,39,31,15],
[64,39,32,0],
[64,39,32,1],
[64,39,32,2],
[64,39,32,7],
[64,39,32,9],
[64,39,32,10],
[64,39,32,13],
[64,39,32,14],
[64,39,33,0],
[64,39,33,1],
[64,39,33,2],
[64,39,33,7],
[64,39,33,9],
[64,39,33,10],
[64,39,33,13],
[64,39,34,0],
[64,39,34,1],
[64,39,34,2],
[64,39,34,9],
[64,39,34,10],
[64,39,34,11],
[64,39,35,0],
[64,39,35,1],
[64,39,35,2],
[64,39,35,7],
[64,39,36,0],
[64,39,36,1],
[64,39,36,2],
[64,39,36,7],
[64,39,38,0],
[64,39,38,1],
[64,39,38,2],
[64,40,3,0],
[64,40,3,1],
[64,40,3,2],
[64,40,5,0],
[64,40,5,1],
[64,40,5,2],
[64,40,6,0],
[64,40,6,1],
[64,40,6,2],
[64,40,7,0],
[64,40,7,1],
[64,40,7,2],
[64,40,7,3],
[64,40,7,5],
[64,40,7,6],
[64,40,8,0],
[64,40,8,1],
[64,40,8,2],
[64,40,8,7],
[64,40,10,0],
[64,40,10,1],
[64,40,10,2],
[64,40,10,3],
[64,40,10,5],
[64,40,10,6],
[64,40,10,7],
[64,40,10,8],
[64,40,11,0],
[64,40,11,1],
[64,40,11,2],
[64,40,11,3],
[64,40,11,5],
[64,40,11,6],
[64,40,11,7],
[64,40,11,8],
[64,40,11,10],
[64,40,12,0],
[64,40,12,1],
[64,40,12,2],
[64,40,12,3],
[64,40,12,5],
[64,40,12,6],
[64,40,12,7],
[64,40,12,8],
[64,40,12,10],
[64,40,13,0],
[64,40,13,1],
[64,40,13,2],
[64,40,13,3],
[64,40,13,5],
[64,40,13,6],
[64,40,13,7],
[64,40,13,8],
[64,40,13,10],
[64,40,13,11],
[64,40,13,12],
[64,40,14,0],
[64,40,14,1],
[64,40,14,2],
[64,40,14,3],
[64,40,14,5],
[64,40,14,6],
[64,40,14,7],
[64,40,14,8],
[64,40,14,10],
[64,40,14,11],
[64,40,14,12],
[64,40,16,0],
[64,40,16,1],
[64,40,16,2],
[64,40,16,3],
[64,40,16,5],
[64,40,16,6],
[64,40,16,7],
[64,40,16,8],
[64,40,16,10],
[64,40,16,11],
[64,40,16,12],
[64,40,16,13],
[64,40,16,14],
[64,40,17,0],
[64,40,17,1],
[64,40,17,2],
[64,40,17,3],
[64,40,17,5],
[64,40,17,6],
[64,40,17,7],
[64,40,17,8],
[64,40,17,10],
[64,40,17,11],
[64,40,17,12],
[64,40,17,13],
[64,40,17,14],
[64,40,19,0],
[64,40,19,1],
[64,40,19,2],
[64,40,19,3],
[64,40,19,5],
[64,40,19,6],
[64,40,19,7],
[64,40,19,8],
[64,40,19,10],
[64,40,19,11],
[64,40,19,12],
[64,40,19,16],
[64,40,19,17],
[64,40,20,0],
[64,40,20,1],
[64,40,20,2],
[64,40,20,3],
[64,40,20,5],
[64,40,20,6],
[64,40,20,7],
[64,40,20,8],
[64,40,20,11],
[64,40,20,14],
[64,40,20,16],
[64,40,20,17],
[64,40,20,19],
[64,40,21,0],
[64,40,21,1],
[64,40,21,2],
[64,40,21,7],
[64,40,21,12],
[64,40,21,13],
[64,40,21,14],
[64,40,21,16],
[64,40,21,17],
[64,40,21,19],
[64,40,21,20],
[64,40,22,0],
[64,40,22,1],
[64,40,22,2],
[64,40,22,3],
[64,40,22,5],
[64,40,22,6],
[64,40,22,7],
[64,40,22,11],
[64,40,22,12],
[64,40,22,13],
[64,40,22,14],
[64,40,22,16],
[64,40,22,17],
[64,40,22,19],
[64,40,22,21],
[64,40,23,0],
[64,40,23,1],
[64,40,23,2],
[64,40,23,3],
[64,40,23,5],
[64,40,23,8],
[64,40,23,10],
[64,40,23,11],
[64,40,23,12],
[64,40,23,13],
[64,40,23,14],
[64,40,23,16],
[64,40,23,17],
[64,40,23,19],
[64,40,23,20],
[64,40,23,21],
[64,40,23,22],
[64,40,24,0],
[64,40,24,1],
[64,40,24,2],
[64,40,24,3],
[64,40,24,5],
[64,40,24,8],
[64,40,24,10],
[64,40,24,11],
[64,40,24,12],
[64,40,24,13],
[64,40,24,14],
[64,40,24,17],
[64,40,24,19],
[64,40,24,20],
[64,40,24,21],
[64,40,24,22],
[64,40,24,23],
[64,40,25,0],
[64,40,25,1],
[64,40,25,2],
[64,40,25,5],
[64,40,25,6],
[64,40,25,8],
[64,40,25,10],
[64,40,25,11],
[64,40,25,12],
[64,40,25,13],
[64,40,25,14],
[64,40,25,16],
[64,40,25,17],
[64,40,25,19],
[64,40,25,20],
[64,40,25,21],
[64,40,27,0],
[64,40,27,1],
[64,40,27,2],
[64,40,27,3],
[64,40,27,5],
[64,40,27,6],
[64,40,27,7],
[64,40,27,8],
[64,40,27,11],
[64,40,27,12],
[64,40,27,13],
[64,40,27,14],
[64,40,27,16],
[64,40,27,17],
[64,40,27,19],
[64,40,28,0],
[64,40,28,1],
[64,40,28,2],
[64,40,28,3],
[64,40,28,5],
[64,40,28,6],
[64,40,28,8],
[64,40,28,10],
[64,40,28,11],
[64,40,28,12],
[64,40,28,13],
[64,40,28,14],
[64,40,28,16],
[64,40,28,17],
[64,40,29,0],
[64,40,29,1],
[64,40,29,2],
[64,40,29,3],
[64,40,29,5],
[64,40,29,6],
[64,40,29,7],
[64,40,29,8],
[64,40,29,10],
[64,40,29,13],
[64,40,29,14],
[64,40,29,16],
[64,40,29,17],
[64,40,30,0],
[64,40,30,1],
[64,40,30,2],
[64,40,30,3],
[64,40,30,5],
[64,40,30,6],
[64,40,30,7],
[64,40,30,8],
[64,40,30,10],
[64,40,30,11],
[64,40,30,12],
[64,40,30,13],
[64,40,30,14],
[64,40,31,0],
[64,40,31,1],
[64,40,31,2],
[64,40,31,3],
[64,40,31,5],
[64,40,31,6],
[64,40,31,7],
[64,40,31,8],
[64,40,31,10],
[64,40,31,11],
[64,40,31,12],
[64,40,31,13],
[64,40,31,14],
[64,40,32,0],
[64,40,32,1],
[64,40,32,2],
[64,40,32,3],
[64,40,32,5],
[64,40,32,6],
[64,40,32,7],
[64,40,32,8],
[64,40,32,10],
[64,40,32,13],
[64,40,33,0],
[64,40,33,1],
[64,40,33,2],
[64,40,33,3],
[64,40,33,5],
[64,40,33,6],
[64,40,33,7],
[64,40,33,8],
[64,40,33,10],
[64,40,34,0],
[64,40,34,1],
[64,40,34,2],
[64,40,34,3],
[64,40,34,5],
[64,40,34,6],
[64,40,34,8],
[64,40,36,0],
[64,40,36,1],
[64,40,36,2],
[64,40,36,3],
[64,40,36,5],
[64,40,38,0],
[64,40,38,1],
[64,40,38,2],
[64,41,3,0],
[64,41,3,1],
[64,41,3,2],
[64,41,5,0],
[64,41,5,1],
[64,41,5,2],
[64,41,6,0],
[64,41,6,1],
[64,41,6,2],
[64,41,7,0],
[64,41,7,1],
[64,41,7,2],
[64,41,7,3],
[64,41,7,5],
[64,41,7,6],
[64,41,8,0],
[64,41,8,1],
[64,41,8,2],
[64,41,8,7],
[64,41,10,0],
[64,41,10,1],
[64,41,10,2],
[64,41,10,3],
[64,41,10,5],
[64,41,10,6],
[64,41,10,7],
[64,41,10,8],
[64,41,11,0],
[64,41,11,1],
[64,41,11,2],
[64,41,11,3],
[64,41,11,5],
[64,41,11,6],
[64,41,11,7],
[64,41,11,8],
[64,41,11,10],
[64,41,12,0],
[64,41,12,1],
[64,41,12,2],
[64,41,12,3],
[64,41,12,5],
[64,41,12,6],
[64,41,12,7],
[64,41,12,8],
[64,41,12,10],
[64,41,13,0],
[64,41,13,1],
[64,41,13,2],
[64,41,13,3],
[64,41,13,5],
[64,41,13,6],
[64,41,13,7],
[64,41,13,8],
[64,41,13,10],
[64,41,13,11],
[64,41,13,12],
[64,41,14,0],
[64,41,14,1],
[64,41,14,2],
[64,41,14,3],
[64,41,14,5],
[64,41,14,6],
[64,41,14,7],
[64,41,14,8],
[64,41,14,10],
[64,41,14,11],
[64,41,14,12],
[64,41,16,0],
[64,41,16,1],
[64,41,16,2],
[64,41,16,3],
[64,41,16,5],
[64,41,16,6],
[64,41,16,7],
[64,41,16,8],
[64,41,16,10],
[64,41,16,11],
[64,41,16,12],
[64,41,16,13],
[64,41,16,14],
[64,41,17,0],
[64,41,17,1],
[64,41,17,2],
[64,41,17,3],
[64,41,17,5],
[64,41,17,6],
[64,41,17,7],
[64,41,17,8],
[64,41,17,10],
[64,41,17,11],
[64,41,17,12],
[64,41,17,13],
[64,41,17,14],
[64,41,19,0],
[64,41,19,1],
[64,41,19,2],
[64,41,19,3],
[64,41,19,5],
[64,41,19,6],
[64,41,19,7],
[64,41,19,8],
[64,41,19,10],
[64,41,19,11],
[64,41,19,12],
[64,41,19,16],
[64,41,19,17],
[64,41,20,0],
[64,41,20,1],
[64,41,20,2],
[64,41,20,3],
[64,41,20,5],
[64,41,20,6],
[64,41,20,7],
[64,41,20,8],
[64,41,20,11],
[64,41,20,14],
[64,41,20,16],
[64,41,20,17],
[64,41,20,19],
[64,41,21,0],
[64,41,21,1],
[64,41,21,2],
[64,41,21,7],
[64,41,21,12],
[64,41,21,13],
[64,41,21,14],
[64,41,21,16],
[64,41,21,17],
[64,41,21,19],
[64,41,21,20],
[64,41,22,0],
[64,41,22,1],
[64,41,22,2],
[64,41,22,3],
[64,41,22,5],
[64,41,22,6],
[64,41,22,7],
[64,41,22,11],
[64,41,22,12],
[64,41,22,13],
[64,41,22,14],
[64,41,22,16],
[64,41,22,17],
[64,41,22,19],
[64,41,22,21],
[64,41,23,0],
[64,41,23,1],
[64,41,23,2],
[64,41,23,3],
[64,41,23,5],
[64,41,23,8],
[64,41,23,10],
[64,41,23,11],
[64,41,23,12],
[64,41,23,13],
[64,41,23,14],
[64,41,23,16],
[64,41,23,17],
[64,41,23,19],
[64,41,23,20],
[64,41,23,21],
[64,41,23,22],
[64,41,24,0],
[64,41,24,1],
[64,41,24,2],
[64,41,24,3],
[64,41,24,5],
[64,41,24,8],
[64,41,24,10],
[64,41,24,11],
[64,41,24,12],
[64,41,24,13],
[64,41,24,14],
[64,41,24,17],
[64,41,24,19],
[64,41,24,20],
[64,41,24,21],
[64,41,24,22],
[64,41,24,23],
[64,41,25,0],
[64,41,25,1],
[64,41,25,2],
[64,41,25,5],
[64,41,25,6],
[64,41,25,8],
[64,41,25,10],
[64,41,25,11],
[64,41,25,12],
[64,41,25,13],
[64,41,25,14],
[64,41,25,16],
[64,41,25,17],
[64,41,25,19],
[64,41,25,20],
[64,41,25,21],
[64,41,27,0],
[64,41,27,1],
[64,41,27,2],
[64,41,27,3],
[64,41,27,5],
[64,41,27,6],
[64,41,27,7],
[64,41,27,8],
[64,41,27,11],
[64,41,27,12],
[64,41,27,13],
[64,41,27,14],
[64,41,27,16],
[64,41,27,17],
[64,41,27,19],
[64,41,28,0],
[64,41,28,1],
[64,41,28,2],
[64,41,28,3],
[64,41,28,5],
[64,41,28,6],
[64,41,28,8],
[64,41,28,10],
[64,41,28,11],
[64,41,28,12],
[64,41,28,13],
[64,41,28,14],
[64,41,28,16],
[64,41,28,17],
[64,41,29,0],
[64,41,29,1],
[64,41,29,2],
[64,41,29,3],
[64,41,29,5],
[64,41,29,6],
[64,41,29,7],
[64,41,29,8],
[64,41,29,10],
[64,41,29,13],
[64,41,29,14],
[64,41,29,16],
[64,41,29,17],
[64,41,30,0],
[64,41,30,1],
[64,41,30,2],
[64,41,30,3],
[64,41,30,5],
[64,41,30,6],
[64,41,30,7],
[64,41,30,8],
[64,41,30,10],
[64,41,30,11],
[64,41,30,12],
[64,41,30,13],
[64,41,30,14],
[64,41,31,0],
[64,41,31,1],
[64,41,31,2],
[64,41,31,3],
[64,41,31,5],
[64,41,31,6],
[64,41,31,7],
[64,41,31,8],
[64,41,31,10],
[64,41,31,11],
[64,41,31,12],
[64,41,31,13],
[64,41,31,14],
[64,41,32,0],
[64,41,32,1],
[64,41,32,2],
[64,41,32,3],
[64,41,32,5],
[64,41,32,6],
[64,41,32,7],
[64,41,32,8],
[64,41,32,10],
[64,41,32,13],
[64,41,33,0],
[64,41,33,1],
[64,41,33,2],
[64,41,33,3],
[64,41,33,5],
[64,41,33,6],
[64,41,33,7],
[64,41,33,8],
[64,41,33,10],
[64,41,34,0],
[64,41,34,1],
[64,41,34,2],
[64,41,34,3],
[64,41,34,5],
[64,41,34,6],
[64,41,34,8],
[64,41,36,0],
[64,41,36,1],
[64,41,36,2],
[64,41,36,3],
[64,41,36,5],
[64,41,38,0],
[64,41,38,1],
[64,41,38,2],
[64,42,3,0],
[64,42,3,1],
[64,42,3,2],
[64,42,5,0],
[64,42,5,1],
[64,42,5,2],
[64,42,6,0],
[64,42,6,1],
[64,42,6,2],
[64,42,7,0],
[64,42,7,1],
[64,42,7,2],
[64,42,7,3],
[64,42,7,5],
[64,42,7,6],
[64,42,8,0],
[64,42,8,1],
[64,42,8,2],
[64,42,8,7],
[64,42,9,0],
[64,42,9,1],
[64,42,9,2],
[64,42,9,3],
[64,42,9,5],
[64,42,9,6],
[64,42,9,7],
[64,42,9,8],
[64,42,11,0],
[64,42,11,1],
[64,42,11,2],
[64,42,11,3],
[64,42,11,5],
[64,42,11,6],
[64,42,11,7],
[64,42,11,8],
[64,42,11,9],
[64,42,12,0],
[64,42,12,1],
[64,42,12,2],
[64,42,12,3],
[64,42,12,5],
[64,42,12,6],
[64,42,12,7],
[64,42,12,8],
[64,42,12,9],
[64,42,13,0],
[64,42,13,1],
[64,42,13,2],
[64,42,13,3],
[64,42,13,5],
[64,42,13,6],
[64,42,13,7],
[64,42,13,8],
[64,42,13,9],
[64,42,13,11],
[64,42,13,12],
[64,42,14,0],
[64,42,14,1],
[64,42,14,2],
[64,42,14,3],
[64,42,14,5],
[64,42,14,6],
[64,42,14,7],
[64,42,14,8],
[64,42,14,9],
[64,42,14,11],
[64,42,14,12],
[64,42,15,0],
[64,42,15,1],
[64,42,15,2],
[64,42,15,3],
[64,42,15,5],
[64,42,15,6],
[64,42,15,7],
[64,42,15,8],
[64,42,15,11],
[64,42,15,12],
[64,42,15,13],
[64,42,15,14],
[64,42,16,0],
[64,42,16,1],
[64,42,16,2],
[64,42,16,3],
[64,42,16,5],
[64,42,16,6],
[64,42,16,7],
[64,42,16,8],
[64,42,16,9],
[64,42,16,11],
[64,42,16,12],
[64,42,16,13],
[64,42,16,14],
[64,42,17,0],
[64,42,17,1],
[64,42,17,2],
[64,42,17,3],
[64,42,17,5],
[64,42,17,6],
[64,42,17,7],
[64,42,17,8],
[64,42,17,9],
[64,42,17,11],
[64,42,17,12],
[64,42,17,13],
[64,42,17,14],
[64,42,17,16],
[64,42,18,0],
[64,42,18,1],
[64,42,18,2],
[64,42,18,3],
[64,42,18,5],
[64,42,18,6],
[64,42,18,7],
[64,42,18,8],
[64,42,18,11],
[64,42,18,12],
[64,42,18,13],
[64,42,18,16],
[64,42,18,17],
[64,42,19,0],
[64,42,19,1],
[64,42,19,2],
[64,42,19,3],
[64,42,19,5],
[64,42,19,6],
[64,42,19,7],
[64,42,19,8],
[64,42,19,9],
[64,42,19,11],
[64,42,19,15],
[64,42,19,16],
[64,42,19,17],
[64,42,19,18],
[64,42,21,0],
[64,42,21,1],
[64,42,21,2],
[64,42,21,7],
[64,42,21,11],
[64,42,21,12],
[64,42,21,13],
[64,42,21,14],
[64,42,21,15],
[64,42,21,16],
[64,42,21,17],
[64,42,21,18],
[64,42,21,19],
[64,42,23,0],
[64,42,23,1],
[64,42,23,2],
[64,42,23,3],
[64,42,23,6],
[64,42,23,7],
[64,42,23,8],
[64,42,23,9],
[64,42,23,11],
[64,42,23,12],
[64,42,23,13],
[64,42,23,14],
[64,42,23,15],
[64,42,23,16],
[64,42,23,17],
[64,42,23,18],
[64,42,23,19],
[64,42,23,21],
[64,42,24,0],
[64,42,24,1],
[64,42,24,2],
[64,42,24,3],
[64,42,24,6],
[64,42,24,7],
[64,42,24,8],
[64,42,24,9],
[64,42,24,11],
[64,42,24,12],
[64,42,24,13],
[64,42,24,14],
[64,42,24,15],
[64,42,24,17],
[64,42,24,18],
[64,42,24,19],
[64,42,24,21],
[64,42,25,3],
[64,42,25,5],
[64,42,25,6],
[64,42,25,8],
[64,42,25,9],
[64,42,25,11],
[64,42,25,12],
[64,42,25,13],
[64,42,25,14],
[64,42,25,15],
[64,42,25,16],
[64,42,25,17],
[64,42,25,18],
[64,42,25,19],
[64,42,26,0],
[64,42,26,1],
[64,42,26,2],
[64,42,26,3],
[64,42,26,5],
[64,42,26,6],
[64,42,26,7],
[64,42,26,8],
[64,42,26,11],
[64,42,26,12],
[64,42,26,13],
[64,42,26,14],
[64,42,26,16],
[64,42,26,17],
[64,42,26,19],
[64,42,28,0],
[64,42,28,1],
[64,42,28,2],
[64,42,28,3],
[64,42,28,5],
[64,42,28,6],
[64,42,28,8],
[64,42,28,9],
[64,42,28,11],
[64,42,28,12],
[64,42,28,13],
[64,42,28,14],
[64,42,28,15],
[64,42,28,16],
[64,42,28,17],
[64,42,29,0],
[64,42,29,1],
[64,42,29,2],
[64,42,29,3],
[64,42,29,5],
[64,42,29,6],
[64,42,29,7],
[64,42,29,8],
[64,42,29,9],
[64,42,29,13],
[64,42,29,14],
[64,42,29,15],
[64,42,30,0],
[64,42,30,1],
[64,42,30,2],
[64,42,30,3],
[64,42,30,5],
[64,42,30,6],
[64,42,30,7],
[64,42,30,8],
[64,42,30,9],
[64,42,30,11],
[64,42,30,12],
[64,42,30,13],
[64,42,30,14],
[64,42,31,0],
[64,42,31,1],
[64,42,31,2],
[64,42,31,3],
[64,42,31,5],
[64,42,31,6],
[64,42,31,7],
[64,42,31,8],
[64,42,31,9],
[64,42,31,11],
[64,42,31,12],
[64,42,31,13],
[64,42,32,0],
[64,42,32,1],
[64,42,32,2],
[64,42,32,3],
[64,42,32,5],
[64,42,32,6],
[64,42,32,7],
[64,42,32,8],
[64,42,32,9],
[64,42,33,0],
[64,42,33,1],
[64,42,33,2],
[64,42,33,3],
[64,42,33,5],
[64,42,33,6],
[64,42,33,7],
[64,42,33,8],
[64,42,33,9],
[64,42,34,0],
[64,42,34,1],
[64,42,34,2],
[64,42,34,3],
[64,42,34,5],
[64,42,34,6],
[64,42,35,0],
[64,42,35,1],
[64,42,35,2],
[64,42,35,3],
[64,42,35,5],
[64,42,36,0],
[64,42,36,1],
[64,42,36,2],
[64,42,36,3],
[64,43,3,0],
[64,43,3,1],
[64,43,3,2],
[64,43,5,0],
[64,43,5,1],
[64,43,5,2],
[64,43,6,0],
[64,43,6,1],
[64,43,6,2],
[64,43,8,0],
[64,43,8,1],
[64,43,8,2],
[64,43,9,0],
[64,43,9,1],
[64,43,9,2],
[64,43,9,3],
[64,43,9,5],
[64,43,9,6],
[64,43,9,8],
[64,43,10,0],
[64,43,10,1],
[64,43,10,2],
[64,43,10,3],
[64,43,10,5],
[64,43,10,6],
[64,43,10,8],
[64,43,10,9],
[64,43,11,0],
[64,43,11,1],
[64,43,11,2],
[64,43,11,3],
[64,43,11,5],
[64,43,11,6],
[64,43,11,8],
[64,43,11,9],
[64,43,11,10],
[64,43,12,0],
[64,43,12,1],
[64,43,12,2],
[64,43,12,3],
[64,43,12,5],
[64,43,12,6],
[64,43,12,8],
[64,43,12,9],
[64,43,12,10],
[64,43,13,0],
[64,43,13,1],
[64,43,13,2],
[64,43,13,3],
[64,43,13,5],
[64,43,13,6],
[64,43,13,8],
[64,43,13,9],
[64,43,13,10],
[64,43,13,11],
[64,43,13,12],
[64,43,14,0],
[64,43,14,1],
[64,43,14,2],
[64,43,14,3],
[64,43,14,5],
[64,43,14,6],
[64,43,14,8],
[64,43,14,9],
[64,43,14,10],
[64,43,14,11],
[64,43,14,12],
[64,43,15,0],
[64,43,15,1],
[64,43,15,2],
[64,43,15,3],
[64,43,15,5],
[64,43,15,6],
[64,43,15,8],
[64,43,15,10],
[64,43,15,11],
[64,43,15,12],
[64,43,15,13],
[64,43,15,14],
[64,43,16,0],
[64,43,16,1],
[64,43,16,2],
[64,43,16,3],
[64,43,16,5],
[64,43,16,6],
[64,43,16,8],
[64,43,16,9],
[64,43,16,10],
[64,43,16,11],
[64,43,16,12],
[64,43,16,13],
[64,43,16,15],
[64,43,17,0],
[64,43,17,1],
[64,43,17,2],
[64,43,17,3],
[64,43,17,5],
[64,43,17,6],
[64,43,17,8],
[64,43,17,9],
[64,43,17,10],
[64,43,17,11],
[64,43,17,12],
[64,43,17,13],
[64,43,17,15],
[64,43,17,16],
[64,43,18,0],
[64,43,18,1],
[64,43,18,2],
[64,43,18,3],
[64,43,18,5],
[64,43,18,6],
[64,43,18,8],
[64,43,18,10],
[64,43,18,11],
[64,43,18,14],
[64,43,18,16],
[64,43,18,17],
[64,43,19,0],
[64,43,19,1],
[64,43,19,2],
[64,43,19,3],
[64,43,19,5],
[64,43,19,6],
[64,43,19,8],
[64,43,19,9],
[64,43,19,12],
[64,43,19,15],
[64,43,19,16],
[64,43,19,17],
[64,43,19,18],
[64,43,20,0],
[64,43,20,1],
[64,43,20,2],
[64,43,20,3],
[64,43,20,5],
[64,43,20,6],
[64,43,20,11],
[64,43,20,12],
[64,43,20,13],
[64,43,20,14],
[64,43,20,15],
[64,43,20,16],
[64,43,20,17],
[64,43,20,18],
[64,43,20,19],
[64,43,21,0],
[64,43,21,1],
[64,43,21,2],
[64,43,21,9],
[64,43,21,10],
[64,43,21,11],
[64,43,21,12],
[64,43,21,13],
[64,43,21,14],
[64,43,21,15],
[64,43,21,16],
[64,43,21,17],
[64,43,21,18],
[64,43,21,19],
[64,43,21,20],
[64,43,22,0],
[64,43,22,1],
[64,43,22,2],
[64,43,22,3],
[64,43,22,6],
[64,43,22,8],
[64,43,22,9],
[64,43,22,11],
[64,43,22,12],
[64,43,22,13],
[64,43,22,14],
[64,43,22,15],
[64,43,22,16],
[64,43,22,17],
[64,43,22,18],
[64,43,22,19],
[64,43,22,21],
[64,43,23,0],
[64,43,23,1],
[64,43,23,2],
[64,43,23,5],
[64,43,23,6],
[64,43,23,8],
[64,43,23,9],
[64,43,23,10],
[64,43,23,11],
[64,43,23,12],
[64,43,23,13],
[64,43,23,14],
[64,43,23,15],
[64,43,23,16],
[64,43,23,17],
[64,43,23,18],
[64,43,23,19],
[64,43,23,20],
[64,43,23,21],
[64,43,24,0],
[64,43,24,1],
[64,43,24,2],
[64,43,24,5],
[64,43,24,6],
[64,43,24,8],
[64,43,24,9],
[64,43,24,10],
[64,43,24,11],
[64,43,24,12],
[64,43,24,13],
[64,43,24,14],
[64,43,24,15],
[64,43,24,17],
[64,43,24,18],
[64,43,24,19],
[64,43,24,20],
[64,43,24,21],
[64,43,26,0],
[64,43,26,1],
[64,43,26,2],
[64,43,26,3],
[64,43,26,5],
[64,43,26,6],
[64,43,26,8],
[64,43,26,10],
[64,43,26,11],
[64,43,26,12],
[64,43,26,13],
[64,43,26,14],
[64,43,26,16],
[64,43,26,17],
[64,43,27,0],
[64,43,27,1],
[64,43,27,2],
[64,43,27,3],
[64,43,27,5],
[64,43,27,6],
[64,43,27,8],
[64,43,27,9],
[64,43,27,11],
[64,43,27,12],
[64,43,27,13],
[64,43,27,14],
[64,43,27,15],
[64,43,27,16],
[64,43,27,17],
[64,43,29,0],
[64,43,29,1],
[64,43,29,2],
[64,43,29,3],
[64,43,29,5],
[64,43,29,6],
[64,43,29,8],
[64,43,29,9],
[64,43,29,10],
[64,43,29,13],
[64,43,29,14],
[64,43,30,0],
[64,43,30,1],
[64,43,30,2],
[64,43,30,3],
[64,43,30,5],
[64,43,30,6],
[64,43,30,8],
[64,43,30,9],
[64,43,30,10],
[64,43,30,11],
[64,43,30,12],
[64,43,30,13],
[64,43,31,0],
[64,43,31,1],
[64,43,31,2],
[64,43,31,3],
[64,43,31,5],
[64,43,31,6],
[64,43,31,8],
[64,43,31,9],
[64,43,31,10],
[64,43,31,11],
[64,43,32,0],
[64,43,32,1],
[64,43,32,2],
[64,43,32,3],
[64,43,32,5],
[64,43,32,6],
[64,43,32,8],
[64,43,32,9],
[64,43,33,0],
[64,43,33,1],
[64,43,33,2],
[64,43,33,3],
[64,43,33,5],
[64,43,33,6],
[64,43,35,0],
[64,43,35,1],
[64,43,35,2],
[64,43,35,3],
[64,43,36,0],
[64,43,36,1],
[64,43,36,2],
[64,44,3,0],
[64,44,3,1],
[64,44,3,2],
[64,44,5,0],
[64,44,5,1],
[64,44,5,2],
[64,44,6,0],
[64,44,6,1],
[64,44,6,2],
[64,44,7,0],
[64,44,7,1],
[64,44,7,2],
[64,44,7,3],
[64,44,7,5],
[64,44,7,6],
[64,44,8,0],
[64,44,8,1],
[64,44,8,2],
[64,44,8,7],
[64,44,9,0],
[64,44,9,1],
[64,44,9,2],
[64,44,9,3],
[64,44,9,5],
[64,44,9,6],
[64,44,9,7],
[64,44,9,8],
[64,44,10,0],
[64,44,10,1],
[64,44,10,2],
[64,44,10,3],
[64,44,10,5],
[64,44,10,6],
[64,44,10,7],
[64,44,10,8],
[64,44,10,9],
[64,44,11,0],
[64,44,11,1],
[64,44,11,2],
[64,44,11,3],
[64,44,11,5],
[64,44,11,6],
[64,44,11,7],
[64,44,11,8],
[64,44,11,9],
[64,44,11,10],
[64,44,12,0],
[64,44,12,1],
[64,44,12,2],
[64,44,12,3],
[64,44,12,5],
[64,44,12,6],
[64,44,12,7],
[64,44,12,8],
[64,44,12,9],
[64,44,12,10],
[64,44,13,0],
[64,44,13,1],
[64,44,13,2],
[64,44,13,3],
[64,44,13,5],
[64,44,13,6],
[64,44,13,7],
[64,44,13,8],
[64,44,13,9],
[64,44,13,10],
[64,44,13,11],
[64,44,13,12],
[64,44,14,0],
[64,44,14,1],
[64,44,14,2],
[64,44,14,3],
[64,44,14,5],
[64,44,14,6],
[64,44,14,7],
[64,44,14,8],
[64,44,14,9],
[64,44,14,10],
[64,44,14,11],
[64,44,14,12],
[64,44,15,0],
[64,44,15,1],
[64,44,15,2],
[64,44,15,3],
[64,44,15,5],
[64,44,15,6],
[64,44,15,7],
[64,44,15,8],
[64,44,15,10],
[64,44,15,11],
[64,44,15,12],
[64,44,15,13],
[64,44,16,0],
[64,44,16,1],
[64,44,16,2],
[64,44,16,3],
[64,44,16,5],
[64,44,16,6],
[64,44,16,7],
[64,44,16,8],
[64,44,16,9],
[64,44,16,10],
[64,44,16,11],
[64,44,16,14],
[64,44,16,15],
[64,44,18,0],
[64,44,18,1],
[64,44,18,2],
[64,44,18,3],
[64,44,18,5],
[64,44,18,6],
[64,44,18,7],
[64,44,18,8],
[64,44,18,12],
[64,44,18,13],
[64,44,18,14],
[64,44,18,16],
[64,44,19,0],
[64,44,19,1],
[64,44,19,2],
[64,44,19,3],
[64,44,19,5],
[64,44,19,6],
[64,44,19,7],
[64,44,19,10],
[64,44,19,11],
[64,44,19,12],
[64,44,19,15],
[64,44,19,16],
[64,44,19,18],
[64,44,20,0],
[64,44,20,1],
[64,44,20,2],
[64,44,20,3],
[64,44,20,5],
[64,44,20,8],
[64,44,20,9],
[64,44,20,11],
[64,44,20,12],
[64,44,20,13],
[64,44,20,14],
[64,44,20,15],
[64,44,20,16],
[64,44,20,18],
[64,44,20,19],
[64,44,21,0],
[64,44,21,1],
[64,44,21,2],
[64,44,21,7],
[64,44,21,9],
[64,44,21,10],
[64,44,21,11],
[64,44,21,12],
[64,44,21,13],
[64,44,21,14],
[64,44,21,15],
[64,44,21,16],
[64,44,21,18],
[64,44,21,19],
[64,44,21,20],
[64,44,22,0],
[64,44,22,1],
[64,44,22,2],
[64,44,22,5],
[64,44,22,6],
[64,44,22,7],
[64,44,22,8],
[64,44,22,9],
[64,44,22,11],
[64,44,22,12],
[64,44,22,13],
[64,44,22,14],
[64,44,22,15],
[64,44,22,16],
[64,44,22,18],
[64,44,22,19],
[64,44,22,21],
[64,44,23,3],
[64,44,23,5],
[64,44,23,6],
[64,44,23,7],
[64,44,23,8],
[64,44,23,9],
[64,44,23,10],
[64,44,23,11],
[64,44,23,12],
[64,44,23,13],
[64,44,23,14],
[64,44,23,15],
[64,44,23,16],
[64,44,23,18],
[64,44,23,19],
[64,44,23,20],
[64,44,24,3],
[64,44,24,5],
[64,44,24,6],
[64,44,24,7],
[64,44,24,8],
[64,44,24,9],
[64,44,24,10],
[64,44,24,11],
[64,44,24,12],
[64,44,24,13],
[64,44,24,14],
[64,44,24,15],
[64,44,24,18],
[64,44,24,19],
[64,44,24,20],
[64,44,25,0],
[64,44,25,1],
[64,44,25,2],
[64,44,25,3],
[64,44,25,5],
[64,44,25,6],
[64,44,25,8],
[64,44,25,9],
[64,44,25,10],
[64,44,25,11],
[64,44,25,12],
[64,44,25,13],
[64,44,25,14],
[64,44,25,15],
[64,44,25,16],
[64,44,25,18],
[64,44,26,0],
[64,44,26,1],
[64,44,26,2],
[64,44,26,3],
[64,44,26,5],
[64,44,26,6],
[64,44,26,7],
[64,44,26,8],
[64,44,26,10],
[64,44,26,11],
[64,44,26,12],
[64,44,26,13],
[64,44,26,14],
[64,44,26,16],
[64,44,27,0],
[64,44,27,1],
[64,44,27,2],
[64,44,27,3],
[64,44,27,5],
[64,44,27,6],
[64,44,27,7],
[64,44,27,8],
[64,44,27,9],
[64,44,27,11],
[64,44,27,12],
[64,44,27,13],
[64,44,27,14],
[64,44,27,15],
[64,44,28,0],
[64,44,28,1],
[64,44,28,2],
[64,44,28,3],
[64,44,28,5],
[64,44,28,6],
[64,44,28,8],
[64,44,28,9],
[64,44,28,10],
[64,44,28,11],
[64,44,28,12],
[64,44,28,13],
[64,44,28,14],
[64,44,29,0],
[64,44,29,1],
[64,44,29,2],
[64,44,29,3],
[64,44,29,5],
[64,44,29,6],
[64,44,29,7],
[64,44,29,8],
[64,44,29,9],
[64,44,29,10],
[64,44,29,13],
[64,44,30,0],
[64,44,30,1],
[64,44,30,2],
[64,44,30,3],
[64,44,30,5],
[64,44,30,6],
[64,44,30,7],
[64,44,30,8],
[64,44,30,9],
[64,44,30,10],
[64,44,30,11],
[64,44,31,0],
[64,44,31,1],
[64,44,31,2],
[64,44,31,3],
[64,44,31,5],
[64,44,31,6],
[64,44,31,7],
[64,44,31,8],
[64,44,31,9],
[64,44,32,0],
[64,44,32,1],
[64,44,32,2],
[64,44,32,3],
[64,44,32,5],
[64,44,32,6],
[64,44,32,7],
[64,44,33,0],
[64,44,33,1],
[64,44,33,2],
[64,44,33,3],
[64,44,33,5],
[64,44,34,0],
[64,44,34,1],
[64,44,34,2],
[64,44,34,3],
[64,44,35,0],
[64,44,35,1],
[64,44,35,2],
[64,45,3,0],
[64,45,3,1],
[64,45,3,2],
[64,45,5,0],
[64,45,5,1],
[64,45,5,2],
[64,45,6,0],
[64,45,6,1],
[64,45,6,2],
[64,45,7,0],
[64,45,7,1],
[64,45,7,2],
[64,45,7,3],
[64,45,7,5],
[64,45,7,6],
[64,45,8,0],
[64,45,8,1],
[64,45,8,2],
[64,45,8,7],
[64,45,10,0],
[64,45,10,1],
[64,45,10,2],
[64,45,10,3],
[64,45,10,5],
[64,45,10,6],
[64,45,10,7],
[64,45,10,8],
[64,45,11,0],
[64,45,11,1],
[64,45,11,2],
[64,45,11,3],
[64,45,11,5],
[64,45,11,6],
[64,45,11,7],
[64,45,11,8],
[64,45,11,10],
[64,45,12,0],
[64,45,12,1],
[64,45,12,2],
[64,45,12,3],
[64,45,12,5],
[64,45,12,6],
[64,45,12,7],
[64,45,12,8],
[64,45,12,10],
[64,45,13,0],
[64,45,13,1],
[64,45,13,2],
[64,45,13,3],
[64,45,13,5],
[64,45,13,6],
[64,45,13,7],
[64,45,13,8],
[64,45,13,10],
[64,45,13,11],
[64,45,13,12],
[64,45,14,0],
[64,45,14,1],
[64,45,14,2],
[64,45,14,3],
[64,45,14,5],
[64,45,14,6],
[64,45,14,7],
[64,45,14,8],
[64,45,14,10],
[64,45,14,11],
[64,45,14,12],
[64,45,16,0],
[64,45,16,1],
[64,45,16,2],
[64,45,16,3],
[64,45,16,5],
[64,45,16,6],
[64,45,16,7],
[64,45,16,8],
[64,45,16,12],
[64,45,16,13],
[64,45,16,14],
[64,45,17,0],
[64,45,17,1],
[64,45,17,2],
[64,45,17,3],
[64,45,17,5],
[64,45,17,6],
[64,45,17,7],
[64,45,17,8],
[64,45,17,12],
[64,45,17,13],
[64,45,17,14],
[64,45,17,16],
[64,45,19,0],
[64,45,19,1],
[64,45,19,2],
[64,45,19,3],
[64,45,19,5],
[64,45,19,8],
[64,45,19,10],
[64,45,19,11],
[64,45,19,12],
[64,45,19,16],
[64,45,19,17],
[64,45,20,0],
[64,45,20,1],
[64,45,20,2],
[64,45,20,3],
[64,45,20,6],
[64,45,20,7],
[64,45,20,8],
[64,45,20,11],
[64,45,20,12],
[64,45,20,13],
[64,45,20,14],
[64,45,20,16],
[64,45,20,17],
[64,45,20,19],
[64,45,21,0],
[64,45,21,1],
[64,45,21,2],
[64,45,21,7],
[64,45,21,10],
[64,45,21,11],
[64,45,21,12],
[64,45,21,13],
[64,45,21,14],
[64,45,21,16],
[64,45,21,17],
[64,45,21,19],
[64,45,21,20],
[64,45,22,3],
[64,45,22,5],
[64,45,22,6],
[64,45,22,7],
[64,45,22,8],
[64,45,22,11],
[64,45,22,12],
[64,45,22,13],
[64,45,22,14],
[64,45,22,16],
[64,45,22,17],
[64,45,22,19],
[64,45,23,0],
[64,45,23,1],
[64,45,23,2],
[64,45,23,3],
[64,45,23,5],
[64,45,23,6],
[64,45,23,7],
[64,45,23,8],
[64,45,23,10],
[64,45,23,11],
[64,45,23,12],
[64,45,23,13],
[64,45,23,14],
[64,45,23,16],
[64,45,23,17],
[64,45,23,19],
[64,45,24,0],
[64,45,24,1],
[64,45,24,2],
[64,45,24,3],
[64,45,24,5],
[64,45,24,6],
[64,45,24,7],
[64,45,24,8],
[64,45,24,10],
[64,45,24,11],
[64,45,24,12],
[64,45,24,13],
[64,45,24,14],
[64,45,24,17],
[64,45,24,19],
[64,45,25,0],
[64,45,25,1],
[64,45,25,2],
[64,45,25,3],
[64,45,25,5],
[64,45,25,6],
[64,45,25,8],
[64,45,25,10],
[64,45,25,11],
[64,45,25,12],
[64,45,25,13],
[64,45,25,14],
[64,45,25,16],
[64,45,25,17],
[64,45,27,0],
[64,45,27,1],
[64,45,27,2],
[64,45,27,3],
[64,45,27,5],
[64,45,27,6],
[64,45,27,7],
[64,45,27,8],
[64,45,27,11],
[64,45,27,12],
[64,45,27,13],
[64,45,27,14],
[64,45,28,0],
[64,45,28,1],
[64,45,28,2],
[64,45,28,3],
[64,45,28,5],
[64,45,28,6],
[64,45,28,8],
[64,45,28,10],
[64,45,28,11],
[64,45,28,12],
[64,45,28,13],
[64,45,29,0],
[64,45,29,1],
[64,45,29,2],
[64,45,29,3],
[64,45,29,5],
[64,45,29,6],
[64,45,29,7],
[64,45,29,8],
[64,45,29,10],
[64,45,30,0],
[64,45,30,1],
[64,45,30,2],
[64,45,30,3],
[64,45,30,5],
[64,45,30,6],
[64,45,30,7],
[64,45,30,8],
[64,45,31,0],
[64,45,31,1],
[64,45,31,2],
[64,45,31,3],
[64,45,31,5],
[64,45,31,6],
[64,45,31,7],
[64,45,32,0],
[64,45,32,1],
[64,45,32,2],
[64,45,32,3],
[64,45,32,5],
[64,45,33,0],
[64,45,33,1],
[64,45,33,2],
[64,45,33,3],
[64,45,34,0],
[64,45,34,1],
[64,45,34,2],
[64,46,3,0],
[64,46,3,1],
[64,46,3,2],
[64,46,5,0],
[64,46,5,1],
[64,46,5,2],
[64,46,6,0],
[64,46,6,1],
[64,46,6,2],
[64,46,7,0],
[64,46,7,1],
[64,46,7,2],
[64,46,7,3],
[64,46,7,5],
[64,46,7,6],
[64,46,8,0],
[64,46,8,1],
[64,46,8,2],
[64,46,8,7],
[64,46,10,0],
[64,46,10,1],
[64,46,10,2],
[64,46,10,3],
[64,46,10,5],
[64,46,10,6],
[64,46,10,7],
[64,46,10,8],
[64,46,11,0],
[64,46,11,1],
[64,46,11,2],
[64,46,11,3],
[64,46,11,5],
[64,46,11,6],
[64,46,11,7],
[64,46,11,8],
[64,46,11,10],
[64,46,12,0],
[64,46,12,1],
[64,46,12,2],
[64,46,12,3],
[64,46,12,5],
[64,46,12,6],
[64,46,12,7],
[64,46,12,8],
[64,46,12,10],
[64,46,13,0],
[64,46,13,1],
[64,46,13,2],
[64,46,13,3],
[64,46,13,5],
[64,46,13,6],
[64,46,13,7],
[64,46,13,8],
[64,46,13,10],
[64,46,13,11],
[64,46,13,12],
[64,46,14,0],
[64,46,14,1],
[64,46,14,2],
[64,46,14,3],
[64,46,14,5],
[64,46,14,6],
[64,46,14,7],
[64,46,14,8],
[64,46,14,10],
[64,46,14,11],
[64,46,16,0],
[64,46,16,1],
[64,46,16,2],
[64,46,16,3],
[64,46,16,5],
[64,46,16,6],
[64,46,16,7],
[64,46,16,10],
[64,46,16,11],
[64,46,16,12],
[64,46,16,13],
[64,46,16,14],
[64,46,17,0],
[64,46,17,1],
[64,46,17,2],
[64,46,17,3],
[64,46,17,5],
[64,46,17,6],
[64,46,17,7],
[64,46,17,10],
[64,46,17,11],
[64,46,17,12],
[64,46,17,13],
[64,46,17,14],
[64,46,17,16],
[64,46,19,0],
[64,46,19,1],
[64,46,19,2],
[64,46,19,3],
[64,46,19,6],
[64,46,19,7],
[64,46,19,8],
[64,46,19,10],
[64,46,19,11],
[64,46,19,12],
[64,46,19,16],
[64,46,19,17],
[64,46,20,0],
[64,46,20,1],
[64,46,20,2],
[64,46,20,5],
[64,46,20,6],
[64,46,20,7],
[64,46,20,8],
[64,46,20,11],
[64,46,20,12],
[64,46,20,13],
[64,46,20,14],
[64,46,20,16],
[64,46,20,17],
[64,46,20,19],
[64,46,21,7],
[64,46,21,10],
[64,46,21,11],
[64,46,21,12],
[64,46,21,13],
[64,46,21,14],
[64,46,21,16],
[64,46,21,17],
[64,46,21,19],
[64,46,21,20],
[64,46,22,0],
[64,46,22,1],
[64,46,22,2],
[64,46,22,3],
[64,46,22,5],
[64,46,22,6],
[64,46,22,7],
[64,46,22,8],
[64,46,22,11],
[64,46,22,12],
[64,46,22,13],
[64,46,22,14],
[64,46,22,16],
[64,46,22,17],
[64,46,22,19],
[64,46,23,0],
[64,46,23,1],
[64,46,23,2],
[64,46,23,3],
[64,46,23,5],
[64,46,23,6],
[64,46,23,7],
[64,46,23,8],
[64,46,23,10],
[64,46,23,11],
[64,46,23,12],
[64,46,23,13],
[64,46,23,14],
[64,46,23,16],
[64,46,23,17],
[64,46,24,0],
[64,46,24,1],
[64,46,24,2],
[64,46,24,3],
[64,46,24,5],
[64,46,24,6],
[64,46,24,7],
[64,46,24,8],
[64,46,24,10],
[64,46,24,11],
[64,46,24,12],
[64,46,24,13],
[64,46,24,14],
[64,46,24,17],
[64,46,25,0],
[64,46,25,1],
[64,46,25,2],
[64,46,25,3],
[64,46,25,5],
[64,46,25,6],
[64,46,25,8],
[64,46,25,10],
[64,46,25,11],
[64,46,25,12],
[64,46,25,13],
[64,46,25,14],
[64,46,27,0],
[64,46,27,1],
[64,46,27,2],
[64,46,27,3],
[64,46,27,5],
[64,46,27,6],
[64,46,27,7],
[64,46,27,8],
[64,46,27,11],
[64,46,27,12],
[64,46,27,13],
[64,46,28,0],
[64,46,28,1],
[64,46,28,2],
[64,46,28,3],
[64,46,28,5],
[64,46,28,6],
[64,46,28,8],
[64,46,28,10],
[64,46,28,11],
[64,46,29,0],
[64,46,29,1],
[64,46,29,2],
[64,46,29,3],
[64,46,29,5],
[64,46,29,6],
[64,46,29,7],
[64,46,29,8],
[64,46,30,0],
[64,46,30,1],
[64,46,30,2],
[64,46,30,3],
[64,46,30,5],
[64,46,30,6],
[64,46,30,7],
[64,46,31,0],
[64,46,31,1],
[64,46,31,2],
[64,46,31,3],
[64,46,31,5],
[64,46,32,0],
[64,46,32,1],
[64,46,32,2],
[64,46,32,3],
[64,46,33,0],
[64,46,33,1],
[64,46,33,2],
[64,47,3,0],
[64,47,3,1],
[64,47,3,2],
[64,47,5,0],
[64,47,5,1],
[64,47,5,2],
[64,47,6,0],
[64,47,6,1],
[64,47,6,2],
[64,47,7,0],
[64,47,7,1],
[64,47,7,2],
[64,47,7,3],
[64,47,7,5],
[64,47,7,6],
[64,47,8,0],
[64,47,8,1],
[64,47,8,2],
[64,47,8,7],
[64,47,9,0],
[64,47,9,1],
[64,47,9,2],
[64,47,9,3],
[64,47,9,5],
[64,47,9,6],
[64,47,9,7],
[64,47,9,8],
[64,47,10,0],
[64,47,10,1],
[64,47,10,2],
[64,47,10,3],
[64,47,10,5],
[64,47,10,6],
[64,47,10,7],
[64,47,10,8],
[64,47,10,9],
[64,47,11,0],
[64,47,11,1],
[64,47,11,2],
[64,47,11,3],
[64,47,11,5],
[64,47,11,6],
[64,47,11,7],
[64,47,11,8],
[64,47,11,9],
[64,47,11,10],
[64,47,12,0],
[64,47,12,1],
[64,47,12,2],
[64,47,12,3],
[64,47,12,5],
[64,47,12,6],
[64,47,12,7],
[64,47,12,8],
[64,47,12,9],
[64,47,12,10],
[64,47,13,0],
[64,47,13,1],
[64,47,13,2],
[64,47,13,3],
[64,47,13,5],
[64,47,13,6],
[64,47,13,7],
[64,47,13,8],
[64,47,13,9],
[64,47,13,10],
[64,47,13,11],
[64,47,14,0],
[64,47,14,1],
[64,47,14,2],
[64,47,14,3],
[64,47,14,5],
[64,47,14,6],
[64,47,14,7],
[64,47,14,8],
[64,47,14,9],
[64,47,14,12],
[64,47,15,0],
[64,47,15,1],
[64,47,15,2],
[64,47,15,3],
[64,47,15,5],
[64,47,15,6],
[64,47,15,7],
[64,47,15,10],
[64,47,15,11],
[64,47,15,12],
[64,47,15,13],
[64,47,15,14],
[64,47,16,0],
[64,47,16,1],
[64,47,16,2],
[64,47,16,3],
[64,47,16,5],
[64,47,16,8],
[64,47,16,9],
[64,47,16,10],
[64,47,16,11],
[64,47,16,12],
[64,47,16,13],
[64,47,16,14],
[64,47,16,15],
[64,47,17,0],
[64,47,17,1],
[64,47,17,2],
[64,47,17,3],
[64,47,17,5],
[64,47,17,8],
[64,47,17,9],
[64,47,17,10],
[64,47,17,11],
[64,47,17,12],
[64,47,17,13],
[64,47,17,14],
[64,47,17,15],
[64,47,17,16],
[64,47,18,0],
[64,47,18,1],
[64,47,18,2],
[64,47,18,3],
[64,47,18,6],
[64,47,18,7],
[64,47,18,8],
[64,47,18,10],
[64,47,18,11],
[64,47,18,12],
[64,47,18,13],
[64,47,18,14],
[64,47,18,16],
[64,47,18,17],
[64,47,19,0],
[64,47,19,1],
[64,47,19,2],
[64,47,19,5],
[64,47,19,6],
[64,47,19,7],
[64,47,19,8],
[64,47,19,9],
[64,47,19,10],
[64,47,19,11],
[64,47,19,12],
[64,47,19,15],
[64,47,19,16],
[64,47,19,17],
[64,47,19,18],
[64,47,20,3],
[64,47,20,5],
[64,47,20,6],
[64,47,20,7],
[64,47,20,8],
[64,47,20,9],
[64,47,20,11],
[64,47,20,12],
[64,47,20,13],
[64,47,20,14],
[64,47,20,15],
[64,47,20,16],
[64,47,20,17],
[64,47,20,18],
[64,47,20,19],
[64,47,21,0],
[64,47,21,1],
[64,47,21,2],
[64,47,21,7],
[64,47,21,9],
[64,47,21,10],
[64,47,21,11],
[64,47,21,12],
[64,47,21,13],
[64,47,21,14],
[64,47,21,15],
[64,47,21,16],
[64,47,21,17],
[64,47,21,18],
[64,47,21,19],
[64,47,22,0],
[64,47,22,1],
[64,47,22,2],
[64,47,22,3],
[64,47,22,5],
[64,47,22,6],
[64,47,22,7],
[64,47,22,8],
[64,47,22,9],
[64,47,22,11],
[64,47,22,12],
[64,47,22,13],
[64,47,22,14],
[64,47,22,15],
[64,47,22,16],
[64,47,22,17],
[64,47,22,18],
[64,47,24,0],
[64,47,24,1],
[64,47,24,2],
[64,47,24,3],
[64,47,24,5],
[64,47,24,6],
[64,47,24,7],
[64,47,24,8],
[64,47,24,9],
[64,47,24,10],
[64,47,24,11],
[64,47,24,12],
[64,47,24,13],
[64,47,24,14],
[64,47,24,15],
[64,47,24,17],
[64,47,25,0],
[64,47,25,1],
[64,47,25,2],
[64,47,25,3],
[64,47,25,5],
[64,47,25,6],
[64,47,25,8],
[64,47,25,9],
[64,47,25,10],
[64,47,25,11],
[64,47,25,12],
[64,47,25,13],
[64,47,25,14],
[64,47,26,0],
[64,47,26,1],
[64,47,26,2],
[64,47,26,3],
[64,47,26,5],
[64,47,26,6],
[64,47,26,7],
[64,47,26,8],
[64,47,26,10],
[64,47,26,11],
[64,47,26,12],
[64,47,26,13],
[64,47,27,0],
[64,47,27,1],
[64,47,27,2],
[64,47,27,3],
[64,47,27,5],
[64,47,27,6],
[64,47,27,7],
[64,47,27,8],
[64,47,27,9],
[64,47,27,11],
[64,47,28,0],
[64,47,28,1],
[64,47,28,2],
[64,47,28,3],
[64,47,28,5],
[64,47,28,6],
[64,47,28,8],
[64,47,28,9],
[64,47,29,0],
[64,47,29,1],
[64,47,29,2],
[64,47,29,3],
[64,47,29,5],
[64,47,29,6],
[64,47,29,7],
[64,47,32,0],
[64,47,32,1],
[64,47,32,2],
[64,48,3,0],
[64,48,3,1],
[64,48,3,2],
[64,48,5,0],
[64,48,5,1],
[64,48,5,2],
[64,48,6,0],
[64,48,6,1],
[64,48,6,2],
[64,48,7,0],
[64,48,7,1],
[64,48,7,2],
[64,48,7,3],
[64,48,7,5],
[64,48,7,6],
[64,48,8,0],
[64,48,8,1],
[64,48,8,2],
[64,48,8,7],
[64,48,9,0],
[64,48,9,1],
[64,48,9,2],
[64,48,9,3],
[64,48,9,5],
[64,48,9,6],
[64,48,9,7],
[64,48,9,8],
[64,48,10,0],
[64,48,10,1],
[64,48,10,2],
[64,48,10,3],
[64,48,10,5],
[64,48,10,6],
[64,48,10,7],
[64,48,10,8],
[64,48,10,9],
[64,48,13,0],
[64,48,13,1],
[64,48,13,2],
[64,48,13,3],
[64,48,13,5],
[64,48,13,6],
[64,48,13,7],
[64,48,13,8],
[64,48,13,9],
[64,48,14,0],
[64,48,14,1],
[64,48,14,2],
[64,48,14,3],
[64,48,14,5],
[64,48,14,6],
[64,48,14,7],
[64,48,14,10],
[64,48,15,0],
[64,48,15,1],
[64,48,15,2],
[64,48,15,3],
[64,48,15,5],
[64,48,15,8],
[64,48,15,10],
[64,48,15,13],
[64,48,15,14],
[64,48,16,0],
[64,48,16,1],
[64,48,16,2],
[64,48,16,3],
[64,48,16,6],
[64,48,16,7],
[64,48,16,8],
[64,48,16,9],
[64,48,16,10],
[64,48,16,13],
[64,48,16,14],
[64,48,16,15],
[64,48,17,0],
[64,48,17,1],
[64,48,17,2],
[64,48,17,3],
[64,48,17,6],
[64,48,17,7],
[64,48,17,8],
[64,48,17,9],
[64,48,17,10],
[64,48,17,13],
[64,48,17,14],
[64,48,17,15],
[64,48,17,16],
[64,48,18,0],
[64,48,18,1],
[64,48,18,2],
[64,48,18,5],
[64,48,18,6],
[64,48,18,7],
[64,48,18,8],
[64,48,18,10],
[64,48,18,13],
[64,48,18,14],
[64,48,18,16],
[64,48,18,17],
[64,48,19,3],
[64,48,19,5],
[64,48,19,6],
[64,48,19,7],
[64,48,19,8],
[64,48,19,9],
[64,48,19,10],
[64,48,19,15],
[64,48,19,16],
[64,48,19,17],
[64,48,19,18],
[64,48,20,0],
[64,48,20,1],
[64,48,20,2],
[64,48,20,3],
[64,48,20,5],
[64,48,20,6],
[64,48,20,7],
[64,48,20,8],
[64,48,20,9],
[64,48,20,13],
[64,48,20,14],
[64,48,20,15],
[64,48,20,16],
[64,48,20,17],
[64,48,20,18],
[64,48,20,19],
[64,48,21,0],
[64,48,21,1],
[64,48,21,2],
[64,48,21,7],
[64,48,21,9],
[64,48,21,10],
[64,48,21,13],
[64,48,21,14],
[64,48,21,15],
[64,48,21,16],
[64,48,21,17],
[64,48,21,18],
[64,48,22,0],
[64,48,22,1],
[64,48,22,2],
[64,48,22,3],
[64,48,22,5],
[64,48,22,6],
[64,48,22,7],
[64,48,22,8],
[64,48,22,9],
[64,48,22,13],
[64,48,22,14],
[64,48,22,15],
[64,48,22,16],
[64,48,22,17],
[64,48,23,0],
[64,48,23,1],
[64,48,23,2],
[64,48,23,3],
[64,48,23,5],
[64,48,23,6],
[64,48,23,7],
[64,48,23,8],
[64,48,23,9],
[64,48,23,10],
[64,48,23,13],
[64,48,23,14],
[64,48,23,15],
[64,48,24,0],
[64,48,24,1],
[64,48,24,2],
[64,48,24,3],
[64,48,24,5],
[64,48,24,6],
[64,48,24,7],
[64,48,24,8],
[64,48,24,9],
[64,48,24,10],
[64,48,24,13],
[64,48,24,14],
[64,48,24,15],
[64,48,25,0],
[64,48,25,1],
[64,48,25,2],
[64,48,25,3],
[64,48,25,5],
[64,48,25,6],
[64,48,25,8],
[64,48,25,9],
[64,48,25,10],
[64,48,25,13],
[64,48,26,0],
[64,48,26,1],
[64,48,26,2],
[64,48,26,3],
[64,48,26,5],
[64,48,26,6],
[64,48,26,7],
[64,48,26,8],
[64,48,26,10],
[64,48,27,0],
[64,48,27,1],
[64,48,27,2],
[64,48,27,3],
[64,48,27,5],
[64,48,27,6],
[64,48,27,7],
[64,48,27,8],
[64,48,27,9],
[64,48,28,0],
[64,48,28,1],
[64,48,28,2],
[64,48,28,3],
[64,48,28,5],
[64,48,28,6],
[64,48,30,0],
[64,48,30,1],
[64,48,30,2],
[64,48,30,3],
[64,48,31,0],
[64,48,31,1],
[64,48,31,2],
[64,49,3,0],
[64,49,3,1],
[64,49,3,2],
[64,49,5,0],
[64,49,5,1],
[64,49,5,2],
[64,49,6,0],
[64,49,6,1],
[64,49,6,2],
[64,49,7,0],
[64,49,7,1],
[64,49,7,2],
[64,49,7,3],
[64,49,7,5],
[64,49,7,6],
[64,49,8,0],
[64,49,8,1],
[64,49,8,2],
[64,49,8,7],
[64,49,10,0],
[64,49,10,1],
[64,49,10,2],
[64,49,10,3],
[64,49,10,5],
[64,49,10,6],
[64,49,10,7],
[64,49,10,8],
[64,49,11,0],
[64,49,11,1],
[64,49,11,2],
[64,49,11,3],
[64,49,11,5],
[64,49,11,6],
[64,49,11,7],
[64,49,11,8],
[64,49,11,10],
[64,49,12,0],
[64,49,12,1],
[64,49,12,2],
[64,49,12,3],
[64,49,12,5],
[64,49,12,6],
[64,49,12,7],
[64,49,12,8],
[64,49,13,0],
[64,49,13,1],
[64,49,13,2],
[64,49,13,3],
[64,49,13,5],
[64,49,13,6],
[64,49,13,7],
[64,49,13,8],
[64,49,13,12],
[64,49,14,0],
[64,49,14,1],
[64,49,14,2],
[64,49,14,3],
[64,49,14,5],
[64,49,14,6],
[64,49,14,7],
[64,49,14,10],
[64,49,14,11],
[64,49,14,12],
[64,49,16,0],
[64,49,16,1],
[64,49,16,2],
[64,49,16,3],
[64,49,16,6],
[64,49,16,7],
[64,49,16,8],
[64,49,16,10],
[64,49,16,11],
[64,49,16,12],
[64,49,16,13],
[64,49,16,14],
[64,49,17,0],
[64,49,17,1],
[64,49,17,2],
[64,49,17,3],
[64,49,17,6],
[64,49,17,7],
[64,49,17,8],
[64,49,17,10],
[64,49,17,11],
[64,49,17,12],
[64,49,17,13],
[64,49,17,14],
[64,49,17,16],
[64,49,19,3],
[64,49,19,5],
[64,49,19,6],
[64,49,19,7],
[64,49,19,8],
[64,49,19,10],
[64,49,19,11],
[64,49,19,12],
[64,49,19,16],
[64,49,19,17],
[64,49,20,0],
[64,49,20,1],
[64,49,20,2],
[64,49,20,3],
[64,49,20,5],
[64,49,20,6],
[64,49,20,7],
[64,49,20,8],
[64,49,20,11],
[64,49,20,12],
[64,49,20,13],
[64,49,20,14],
[64,49,20,16],
[64,49,20,17],
[64,49,20,19],
[64,49,21,0],
[64,49,21,1],
[64,49,21,2],
[64,49,21,7],
[64,49,21,10],
[64,49,21,11],
[64,49,21,12],
[64,49,21,13],
[64,49,21,14],
[64,49,21,16],
[64,49,21,17],
[64,49,22,0],
[64,49,22,1],
[64,49,22,2],
[64,49,22,3],
[64,49,22,5],
[64,49,22,6],
[64,49,22,7],
[64,49,22,8],
[64,49,22,11],
[64,49,22,12],
[64,49,22,13],
[64,49,22,14],
[64,49,22,16],
[64,49,22,17],
[64,49,23,0],
[64,49,23,1],
[64,49,23,2],
[64,49,23,3],
[64,49,23,5],
[64,49,23,6],
[64,49,23,7],
[64,49,23,8],
[64,49,23,10],
[64,49,23,11],
[64,49,23,12],
[64,49,23,13],
[64,49,23,14],
[64,49,24,0],
[64,49,24,1],
[64,49,24,2],
[64,49,24,3],
[64,49,24,5],
[64,49,24,6],
[64,49,24,7],
[64,49,24,8],
[64,49,24,10],
[64,49,24,11],
[64,49,24,12],
[64,49,24,13],
[64,49,24,14],
[64,49,25,0],
[64,49,25,1],
[64,49,25,2],
[64,49,25,3],
[64,49,25,5],
[64,49,25,6],
[64,49,25,8],
[64,49,25,10],
[64,49,25,11],
[64,49,25,12],
[64,49,25,13],
[64,49,27,0],
[64,49,27,1],
[64,49,27,2],
[64,49,27,3],
[64,49,27,5],
[64,49,27,6],
[64,49,27,7],
[64,49,27,8],
[64,49,28,0],
[64,49,28,1],
[64,49,28,2],
[64,49,28,3],
[64,49,28,5],
[64,49,28,6],
[64,49,29,0],
[64,49,29,1],
[64,49,29,2],
[64,49,29,3],
[64,49,29,5],
[64,49,30,0],
[64,49,30,1],
[64,49,30,2],
[64,49,30,3],
[64,49,31,0],
[64,49,31,1],
[64,49,31,2],
[64,50,3,0],
[64,50,3,1],
[64,50,3,2],
[64,50,5,0],
[64,50,5,1],
[64,50,5,2],
[64,50,6,0],
[64,50,6,1],
[64,50,6,2],
[64,50,7,0],
[64,50,7,1],
[64,50,7,2],
[64,50,7,3],
[64,50,7,5],
[64,50,7,6],
[64,50,8,0],
[64,50,8,1],
[64,50,8,2],
[64,50,8,7],
[64,50,10,0],
[64,50,10,1],
[64,50,10,2],
[64,50,10,3],
[64,50,10,5],
[64,50,10,6],
[64,50,10,7],
[64,50,10,8],
[64,50,11,0],
[64,50,11,1],
[64,50,11,2],
[64,50,11,3],
[64,50,11,5],
[64,50,11,6],
[64,50,11,7],
[64,50,11,8],
[64,50,11,10],
[64,50,12,0],
[64,50,12,1],
[64,50,12,2],
[64,50,12,3],
[64,50,12,5],
[64,50,12,6],
[64,50,12,7],
[64,50,12,8],
[64,50,13,0],
[64,50,13,1],
[64,50,13,2],
[64,50,13,3],
[64,50,13,5],
[64,50,13,6],
[64,50,13,7],
[64,50,13,8],
[64,50,13,12],
[64,50,14,0],
[64,50,14,1],
[64,50,14,2],
[64,50,14,3],
[64,50,14,5],
[64,50,14,6],
[64,50,14,7],
[64,50,14,10],
[64,50,14,11],
[64,50,14,12],
[64,50,16,0],
[64,50,16,1],
[64,50,16,2],
[64,50,16,3],
[64,50,16,6],
[64,50,16,7],
[64,50,16,8],
[64,50,16,10],
[64,50,16,11],
[64,50,16,12],
[64,50,16,13],
[64,50,16,14],
[64,50,17,0],
[64,50,17,1],
[64,50,17,2],
[64,50,17,3],
[64,50,17,6],
[64,50,17,7],
[64,50,17,8],
[64,50,17,10],
[64,50,17,11],
[64,50,17,12],
[64,50,17,13],
[64,50,17,14],
[64,50,17,16],
[64,50,19,3],
[64,50,19,5],
[64,50,19,6],
[64,50,19,7],
[64,50,19,8],
[64,50,19,10],
[64,50,19,11],
[64,50,19,12],
[64,50,19,16],
[64,50,19,17],
[64,50,20,0],
[64,50,20,1],
[64,50,20,2],
[64,50,20,3],
[64,50,20,5],
[64,50,20,6],
[64,50,20,7],
[64,50,20,8],
[64,50,20,11],
[64,50,20,12],
[64,50,20,13],
[64,50,20,14],
[64,50,20,16],
[64,50,20,17],
[64,50,20,19],
[64,50,21,0],
[64,50,21,1],
[64,50,21,2],
[64,50,21,7],
[64,50,21,10],
[64,50,21,11],
[64,50,21,12],
[64,50,21,13],
[64,50,21,14],
[64,50,21,16],
[64,50,21,17],
[64,50,22,0],
[64,50,22,1],
[64,50,22,2],
[64,50,22,3],
[64,50,22,5],
[64,50,22,6],
[64,50,22,7],
[64,50,22,8],
[64,50,22,11],
[64,50,22,12],
[64,50,22,13],
[64,50,22,14],
[64,50,22,16],
[64,50,22,17],
[64,50,23,0],
[64,50,23,1],
[64,50,23,2],
[64,50,23,3],
[64,50,23,5],
[64,50,23,6],
[64,50,23,7],
[64,50,23,8],
[64,50,23,10],
[64,50,23,11],
[64,50,23,12],
[64,50,23,13],
[64,50,23,14],
[64,50,24,0],
[64,50,24,1],
[64,50,24,2],
[64,50,24,3],
[64,50,24,5],
[64,50,24,6],
[64,50,24,7],
[64,50,24,8],
[64,50,24,10],
[64,50,24,11],
[64,50,24,12],
[64,50,24,13],
[64,50,24,14],
[64,50,25,0],
[64,50,25,1],
[64,50,25,2],
[64,50,25,3],
[64,50,25,5],
[64,50,25,6],
[64,50,25,8],
[64,50,25,10],
[64,50,25,11],
[64,50,25,12],
[64,50,25,13],
[64,50,27,0],
[64,50,27,1],
[64,50,27,2],
[64,50,27,3],
[64,50,27,5],
[64,50,27,6],
[64,50,27,7],
[64,50,27,8],
[64,50,28,0],
[64,50,28,1],
[64,50,28,2],
[64,50,28,3],
[64,50,28,5],
[64,50,28,6],
[64,50,29,0],
[64,50,29,1],
[64,50,29,2],
[64,50,29,3],
[64,50,29,5],
[64,50,30,0],
[64,50,30,1],
[64,50,30,2],
[64,50,30,3],
[64,50,31,0],
[64,50,31,1],
[64,50,31,2],
[64,51,7,0],
[64,51,7,1],
[64,51,7,2],
[64,51,9,0],
[64,51,9,1],
[64,51,9,2],
[64,51,9,7],
[64,51,10,0],
[64,51,10,1],
[64,51,10,2],
[64,51,10,7],
[64,51,10,9],
[64,51,11,0],
[64,51,11,1],
[64,51,11,2],
[64,51,11,7],
[64,51,11,9],
[64,51,11,10],
[64,51,12,0],
[64,51,12,1],
[64,51,12,2],
[64,51,12,7],
[64,51,12,9],
[64,51,13,0],
[64,51,13,1],
[64,51,13,2],
[64,51,13,7],
[64,51,13,9],
[64,51,13,12],
[64,51,14,0],
[64,51,14,1],
[64,51,14,2],
[64,51,14,7],
[64,51,14,10],
[64,51,14,11],
[64,51,14,12],
[64,51,15,0],
[64,51,15,1],
[64,51,15,2],
[64,51,15,10],
[64,51,15,11],
[64,51,15,12],
[64,51,15,13],
[64,51,15,14],
[64,51,16,0],
[64,51,16,1],
[64,51,16,2],
[64,51,16,7],
[64,51,16,9],
[64,51,16,10],
[64,51,16,11],
[64,51,16,12],
[64,51,16,13],
[64,51,16,14],
[64,51,16,15],
[64,51,17,0],
[64,51,17,1],
[64,51,17,2],
[64,51,17,7],
[64,51,17,9],
[64,51,17,10],
[64,51,17,11],
[64,51,17,12],
[64,51,17,13],
[64,51,17,14],
[64,51,17,15],
[64,51,17,16],
[64,51,18,0],
[64,51,18,1],
[64,51,18,2],
[64,51,18,7],
[64,51,18,10],
[64,51,18,11],
[64,51,18,12],
[64,51,18,13],
[64,51,18,14],
[64,51,18,16],
[64,51,18,17],
[64,51,19,7],
[64,51,19,9],
[64,51,19,10],
[64,51,19,11],
[64,51,19,12],
[64,51,19,15],
[64,51,19,16],
[64,51,19,17],
[64,51,19,18],
[64,51,20,0],
[64,51,20,1],
[64,51,20,2],
[64,51,20,7],
[64,51,20,9],
[64,51,20,11],
[64,51,20,12],
[64,51,20,13],
[64,51,20,14],
[64,51,20,15],
[64,51,20,16],
[64,51,20,17],
[64,51,20,18],
[64,51,20,19],
[64,51,22,0],
[64,51,22,1],
[64,51,22,2],
[64,51,22,7],
[64,51,22,9],
[64,51,22,11],
[64,51,22,12],
[64,51,22,13],
[64,51,22,14],
[64,51,22,15],
[64,51,22,16],
[64,51,22,17],
[64,51,23,0],
[64,51,23,1],
[64,51,23,2],
[64,51,23,7],
[64,51,23,9],
[64,51,23,10],
[64,51,23,11],
[64,51,23,12],
[64,51,23,13],
[64,51,23,14],
[64,51,23,15],
[64,51,24,0],
[64,51,24,1],
[64,51,24,2],
[64,51,24,7],
[64,51,24,9],
[64,51,24,10],
[64,51,24,11],
[64,51,24,12],
[64,51,24,13],
[64,51,24,14],
[64,51,24,15],
[64,51,25,0],
[64,51,25,1],
[64,51,25,2],
[64,51,25,9],
[64,51,25,10],
[64,51,25,11],
[64,51,25,12],
[64,51,25,13],
[64,51,26,0],
[64,51,26,1],
[64,51,26,2],
[64,51,26,7],
[64,51,26,10],
[64,51,26,11],
[64,51,27,0],
[64,51,27,1],
[64,51,27,2],
[64,51,27,7],
[64,51,27,9],
[64,51,28,0],
[64,51,28,1],
[64,51,28,2],
[64,51,29,0],
[64,51,29,1],
[64,51,29,2],
[64,51,30,0],
[64,51,30,1],
[64,51,30,2],
[64,51,31,0],
[64,51,31,1],
[64,51,31,2],
[64,52,3,0],
[64,52,3,1],
[64,52,3,2],
[64,52,5,0],
[64,52,5,1],
[64,52,5,2],
[64,52,6,0],
[64,52,6,1],
[64,52,6,2],
[64,52,7,0],
[64,52,7,1],
[64,52,7,2],
[64,52,7,3],
[64,52,7,5],
[64,52,7,6],
[64,52,8,0],
[64,52,8,1],
[64,52,8,2],
[64,52,8,7],
[64,52,10,0],
[64,52,10,1],
[64,52,10,2],
[64,52,10,3],
[64,52,10,5],
[64,52,10,6],
[64,52,10,7],
[64,52,10,8],
[64,52,11,0],
[64,52,11,1],
[64,52,11,2],
[64,52,11,3],
[64,52,11,5],
[64,52,11,6],
[64,52,11,7],
[64,52,11,8],
[64,52,11,10],
[64,52,12,0],
[64,52,12,1],
[64,52,12,2],
[64,52,12,3],
[64,52,12,5],
[64,52,12,6],
[64,52,12,7],
[64,52,12,8],
[64,52,13,0],
[64,52,13,1],
[64,52,13,2],
[64,52,13,3],
[64,52,13,5],
[64,52,13,6],
[64,52,13,7],
[64,52,13,8],
[64,52,13,12],
[64,52,14,0],
[64,52,14,1],
[64,52,14,2],
[64,52,14,3],
[64,52,14,5],
[64,52,14,6],
[64,52,14,7],
[64,52,14,10],
[64,52,14,11],
[64,52,14,12],
[64,52,16,0],
[64,52,16,1],
[64,52,16,2],
[64,52,16,3],
[64,52,16,6],
[64,52,16,7],
[64,52,16,8],
[64,52,16,10],
[64,52,16,11],
[64,52,16,12],
[64,52,16,13],
[64,52,16,14],
[64,52,17,0],
[64,52,17,1],
[64,52,17,2],
[64,52,17,3],
[64,52,17,6],
[64,52,17,7],
[64,52,17,8],
[64,52,17,10],
[64,52,17,11],
[64,52,17,12],
[64,52,17,13],
[64,52,17,14],
[64,52,17,16],
[64,52,19,3],
[64,52,19,5],
[64,52,19,6],
[64,52,19,7],
[64,52,19,8],
[64,52,19,10],
[64,52,19,11],
[64,52,19,12],
[64,52,19,16],
[64,52,19,17],
[64,52,20,0],
[64,52,20,1],
[64,52,20,2],
[64,52,20,3],
[64,52,20,5],
[64,52,20,6],
[64,52,20,7],
[64,52,20,8],
[64,52,20,11],
[64,52,20,12],
[64,52,20,13],
[64,52,20,14],
[64,52,20,16],
[64,52,20,17],
[64,52,20,19],
[64,52,21,0],
[64,52,21,1],
[64,52,21,2],
[64,52,21,7],
[64,52,21,10],
[64,52,21,11],
[64,52,21,12],
[64,52,21,13],
[64,52,21,14],
[64,52,21,16],
[64,52,21,17],
[64,52,22,0],
[64,52,22,1],
[64,52,22,2],
[64,52,22,3],
[64,52,22,5],
[64,52,22,6],
[64,52,22,7],
[64,52,22,8],
[64,52,22,11],
[64,52,22,12],
[64,52,22,13],
[64,52,22,14],
[64,52,22,16],
[64,52,22,17],
[64,52,23,0],
[64,52,23,1],
[64,52,23,2],
[64,52,23,3],
[64,52,23,5],
[64,52,23,6],
[64,52,23,7],
[64,52,23,8],
[64,52,23,10],
[64,52,23,11],
[64,52,23,12],
[64,52,23,13],
[64,52,23,14],
[64,52,24,0],
[64,52,24,1],
[64,52,24,2],
[64,52,24,3],
[64,52,24,5],
[64,52,24,6],
[64,52,24,7],
[64,52,24,8],
[64,52,24,10],
[64,52,24,11],
[64,52,24,12],
[64,52,24,13],
[64,52,24,14],
[64,52,25,0],
[64,52,25,1],
[64,52,25,2],
[64,52,25,3],
[64,52,25,5],
[64,52,25,6],
[64,52,25,8],
[64,52,25,10],
[64,52,25,11],
[64,52,25,12],
[64,52,25,13],
[64,52,27,0],
[64,52,27,1],
[64,52,27,2],
[64,52,27,3],
[64,52,27,5],
[64,52,27,6],
[64,52,27,7],
[64,52,27,8],
[64,52,28,0],
[64,52,28,1],
[64,52,28,2],
[64,52,28,3],
[64,52,28,5],
[64,52,28,6],
[64,52,29,0],
[64,52,29,1],
[64,52,29,2],
[64,52,29,3],
[64,52,29,5],
[64,52,30,0],
[64,52,30,1],
[64,52,30,2],
[64,52,30,3],
[64,52,31,0],
[64,52,31,1],
[64,52,31,2],
[64,53,3,0],
[64,53,3,1],
[64,53,3,2],
[64,53,5,0],
[64,53,5,1],
[64,53,5,2],
[64,53,6,0],
[64,53,6,1],
[64,53,6,2],
[64,53,7,0],
[64,53,7,1],
[64,53,7,2],
[64,53,7,3],
[64,53,7,5],
[64,53,7,6],
[64,53,8,0],
[64,53,8,1],
[64,53,8,2],
[64,53,8,7],
[64,53,9,0],
[64,53,9,1],
[64,53,9,2],
[64,53,9,3],
[64,53,9,5],
[64,53,9,6],
[64,53,9,7],
[64,53,9,8],
[64,53,11,0],
[64,53,11,1],
[64,53,11,2],
[64,53,11,3],
[64,53,11,5],
[64,53,11,6],
[64,53,11,7],
[64,53,11,8],
[64,53,11,9],
[64,53,12,0],
[64,53,12,1],
[64,53,12,2],
[64,53,12,3],
[64,53,12,5],
[64,53,12,6],
[64,53,12,7],
[64,53,12,8],
[64,53,12,9],
[64,53,13,0],
[64,53,13,1],
[64,53,13,2],
[64,53,13,3],
[64,53,13,5],
[64,53,13,6],
[64,53,13,7],
[64,53,13,8],
[64,53,13,9],
[64,53,13,12],
[64,53,14,0],
[64,53,14,1],
[64,53,14,2],
[64,53,14,3],
[64,53,14,5],
[64,53,14,6],
[64,53,14,7],
[64,53,14,11],
[64,53,14,12],
[64,53,15,0],
[64,53,15,1],
[64,53,15,2],
[64,53,15,3],
[64,53,15,5],
[64,53,15,8],
[64,53,15,11],
[64,53,15,12],
[64,53,15,13],
[64,53,15,14],
[64,53,16,0],
[64,53,16,1],
[64,53,16,2],
[64,53,16,3],
[64,53,16,6],
[64,53,16,7],
[64,53,16,8],
[64,53,16,9],
[64,53,16,11],
[64,53,16,12],
[64,53,16,13],
[64,53,16,14],
[64,53,16,15],
[64,53,17,0],
[64,53,17,1],
[64,53,17,2],
[64,53,17,3],
[64,53,17,6],
[64,53,17,7],
[64,53,17,8],
[64,53,17,9],
[64,53,17,11],
[64,53,17,12],
[64,53,17,13],
[64,53,17,14],
[64,53,17,15],
[64,53,17,16],
[64,53,18,0],
[64,53,18,1],
[64,53,18,2],
[64,53,18,5],
[64,53,18,6],
[64,53,18,7],
[64,53,18,8],
[64,53,18,11],
[64,53,18,12],
[64,53,18,13],
[64,53,18,14],
[64,53,18,16],
[64,53,18,17],
[64,53,19,3],
[64,53,19,5],
[64,53,19,6],
[64,53,19,7],
[64,53,19,8],
[64,53,19,9],
[64,53,19,11],
[64,53,19,12],
[64,53,19,15],
[64,53,19,16],
[64,53,19,17],
[64,53,19,18],
[64,53,21,0],
[64,53,21,1],
[64,53,21,2],
[64,53,21,7],
[64,53,21,9],
[64,53,21,11],
[64,53,21,12],
[64,53,21,13],
[64,53,21,14],
[64,53,21,15],
[64,53,21,16],
[64,53,21,17],
[64,53,21,18],
[64,53,23,0],
[64,53,23,1],
[64,53,23,2],
[64,53,23,3],
[64,53,23,5],
[64,53,23,6],
[64,53,23,7],
[64,53,23,8],
[64,53,23,9],
[64,53,23,11],
[64,53,23,12],
[64,53,23,13],
[64,53,23,14],
[64,53,23,15],
[64,53,24,0],
[64,53,24,1],
[64,53,24,2],
[64,53,24,3],
[64,53,24,5],
[64,53,24,6],
[64,53,24,7],
[64,53,24,8],
[64,53,24,9],
[64,53,24,11],
[64,53,24,12],
[64,53,24,13],
[64,53,24,14],
[64,53,24,15],
[64,53,25,0],
[64,53,25,1],
[64,53,25,2],
[64,53,25,3],
[64,53,25,5],
[64,53,25,6],
[64,53,25,8],
[64,53,25,9],
[64,53,25,11],
[64,53,25,12],
[64,53,25,13],
[64,53,26,0],
[64,53,26,1],
[64,53,26,2],
[64,53,26,3],
[64,53,26,5],
[64,53,26,6],
[64,53,26,7],
[64,53,26,8],
[64,53,26,11],
[64,53,28,0],
[64,53,28,1],
[64,53,28,2],
[64,53,28,3],
[64,53,28,5],
[64,53,28,6],
[64,53,29,0],
[64,53,29,1],
[64,53,29,2],
[64,53,29,3],
[64,53,29,5],
[64,53,30,0],
[64,53,30,1],
[64,53,30,2],
[64,53,30,3],
[64,53,31,0],
[64,53,31,1],
[64,53,31,2],
[64,54,3,0],
[64,54,3,1],
[64,54,3,2],
[64,54,5,0],
[64,54,5,1],
[64,54,5,2],
[64,54,6,0],
[64,54,6,1],
[64,54,6,2],
[64,54,7,0],
[64,54,7,1],
[64,54,7,2],
[64,54,7,3],
[64,54,7,5],
[64,54,7,6],
[64,54,8,0],
[64,54,8,1],
[64,54,8,2],
[64,54,8,7],
[64,54,9,0],
[64,54,9,1],
[64,54,9,2],
[64,54,9,3],
[64,54,9,5],
[64,54,9,6],
[64,54,9,7],
[64,54,9,8],
[64,54,11,0],
[64,54,11,1],
[64,54,11,2],
[64,54,11,3],
[64,54,11,5],
[64,54,11,6],
[64,54,11,7],
[64,54,11,8],
[64,54,11,9],
[64,54,12,0],
[64,54,12,1],
[64,54,12,2],
[64,54,12,3],
[64,54,12,5],
[64,54,12,6],
[64,54,12,7],
[64,54,12,8],
[64,54,12,9],
[64,54,13,0],
[64,54,13,1],
[64,54,13,2],
[64,54,13,3],
[64,54,13,5],
[64,54,13,6],
[64,54,13,7],
[64,54,13,8],
[64,54,13,9],
[64,54,13,12],
[64,54,14,0],
[64,54,14,1],
[64,54,14,2],
[64,54,14,3],
[64,54,14,5],
[64,54,14,6],
[64,54,14,7],
[64,54,14,11],
[64,54,14,12],
[64,54,15,0],
[64,54,15,1],
[64,54,15,2],
[64,54,15,3],
[64,54,15,5],
[64,54,15,8],
[64,54,15,11],
[64,54,15,12],
[64,54,15,13],
[64,54,15,14],
[64,54,16,0],
[64,54,16,1],
[64,54,16,2],
[64,54,16,3],
[64,54,16,6],
[64,54,16,7],
[64,54,16,8],
[64,54,16,9],
[64,54,16,11],
[64,54,16,12],
[64,54,16,13],
[64,54,16,14],
[64,54,16,15],
[64,54,17,0],
[64,54,17,1],
[64,54,17,2],
[64,54,17,3],
[64,54,17,6],
[64,54,17,7],
[64,54,17,8],
[64,54,17,9],
[64,54,17,11],
[64,54,17,12],
[64,54,17,13],
[64,54,17,14],
[64,54,17,15],
[64,54,17,16],
[64,54,18,0],
[64,54,18,1],
[64,54,18,2],
[64,54,18,5],
[64,54,18,6],
[64,54,18,7],
[64,54,18,8],
[64,54,18,11],
[64,54,18,12],
[64,54,18,13],
[64,54,18,14],
[64,54,18,16],
[64,54,18,17],
[64,54,19,3],
[64,54,19,5],
[64,54,19,6],
[64,54,19,7],
[64,54,19,8],
[64,54,19,9],
[64,54,19,11],
[64,54,19,12],
[64,54,19,15],
[64,54,19,16],
[64,54,19,17],
[64,54,19,18],
[64,54,21,0],
[64,54,21,1],
[64,54,21,2],
[64,54,21,7],
[64,54,21,9],
[64,54,21,11],
[64,54,21,12],
[64,54,21,13],
[64,54,21,14],
[64,54,21,15],
[64,54,21,16],
[64,54,21,17],
[64,54,21,18],
[64,54,23,0],
[64,54,23,1],
[64,54,23,2],
[64,54,23,3],
[64,54,23,5],
[64,54,23,6],
[64,54,23,7],
[64,54,23,8],
[64,54,23,9],
[64,54,23,11],
[64,54,23,12],
[64,54,23,13],
[64,54,23,14],
[64,54,23,15],
[64,54,24,0],
[64,54,24,1],
[64,54,24,2],
[64,54,24,3],
[64,54,24,5],
[64,54,24,6],
[64,54,24,7],
[64,54,24,8],
[64,54,24,9],
[64,54,24,11],
[64,54,24,12],
[64,54,24,13],
[64,54,24,14],
[64,54,24,15],
[64,54,25,0],
[64,54,25,1],
[64,54,25,2],
[64,54,25,3],
[64,54,25,5],
[64,54,25,6],
[64,54,25,8],
[64,54,25,9],
[64,54,25,11],
[64,54,25,12],
[64,54,25,13],
[64,54,26,0],
[64,54,26,1],
[64,54,26,2],
[64,54,26,3],
[64,54,26,5],
[64,54,26,6],
[64,54,26,7],
[64,54,26,8],
[64,54,26,11],
[64,54,28,0],
[64,54,28,1],
[64,54,28,2],
[64,54,28,3],
[64,54,28,5],
[64,54,28,6],
[64,54,29,0],
[64,54,29,1],
[64,54,29,2],
[64,54,29,3],
[64,54,29,5],
[64,54,30,0],
[64,54,30,1],
[64,54,30,2],
[64,54,30,3],
[64,54,31,0],
[64,54,31,1],
[64,54,31,2],
[64,55,3,0],
[64,55,3,1],
[64,55,3,2],
[64,55,5,0],
[64,55,5,1],
[64,55,5,2],
[64,55,6,0],
[64,55,6,1],
[64,55,6,2],
[64,55,7,0],
[64,55,7,1],
[64,55,7,2],
[64,55,7,3],
[64,55,7,5],
[64,55,7,6],
[64,55,8,0],
[64,55,8,1],
[64,55,8,2],
[64,55,8,7],
[64,55,9,0],
[64,55,9,1],
[64,55,9,2],
[64,55,9,3],
[64,55,9,5],
[64,55,9,6],
[64,55,9,7],
[64,55,9,8],
[64,55,11,0],
[64,55,11,1],
[64,55,11,2],
[64,55,11,3],
[64,55,11,5],
[64,55,11,6],
[64,55,11,7],
[64,55,11,8],
[64,55,11,9],
[64,55,12,0],
[64,55,12,1],
[64,55,12,2],
[64,55,12,3],
[64,55,12,5],
[64,55,12,6],
[64,55,12,7],
[64,55,12,8],
[64,55,12,9],
[64,55,13,0],
[64,55,13,1],
[64,55,13,2],
[64,55,13,3],
[64,55,13,5],
[64,55,13,6],
[64,55,13,7],
[64,55,13,8],
[64,55,13,9],
[64,55,13,12],
[64,55,14,0],
[64,55,14,1],
[64,55,14,2],
[64,55,14,3],
[64,55,14,5],
[64,55,14,6],
[64,55,14,7],
[64,55,14,11],
[64,55,14,12],
[64,55,15,0],
[64,55,15,1],
[64,55,15,2],
[64,55,15,3],
[64,55,15,5],
[64,55,15,8],
[64,55,15,11],
[64,55,15,12],
[64,55,15,13],
[64,55,15,14],
[64,55,16,0],
[64,55,16,1],
[64,55,16,2],
[64,55,16,3],
[64,55,16,6],
[64,55,16,7],
[64,55,16,8],
[64,55,16,9],
[64,55,16,11],
[64,55,16,12],
[64,55,16,13],
[64,55,16,14],
[64,55,16,15],
[64,55,17,0],
[64,55,17,1],
[64,55,17,2],
[64,55,17,3],
[64,55,17,6],
[64,55,17,7],
[64,55,17,8],
[64,55,17,9],
[64,55,17,11],
[64,55,17,12],
[64,55,17,13],
[64,55,17,14],
[64,55,17,15],
[64,55,17,16],
[64,55,18,0],
[64,55,18,1],
[64,55,18,2],
[64,55,18,5],
[64,55,18,6],
[64,55,18,7],
[64,55,18,8],
[64,55,18,11],
[64,55,18,12],
[64,55,18,13],
[64,55,18,14],
[64,55,18,16],
[64,55,18,17],
[64,55,19,3],
[64,55,19,5],
[64,55,19,6],
[64,55,19,7],
[64,55,19,8],
[64,55,19,9],
[64,55,19,11],
[64,55,19,12],
[64,55,19,15],
[64,55,19,16],
[64,55,19,17],
[64,55,19,18],
[64,55,21,0],
[64,55,21,1],
[64,55,21,2],
[64,55,21,7],
[64,55,21,9],
[64,55,21,11],
[64,55,21,12],
[64,55,21,13],
[64,55,21,14],
[64,55,21,15],
[64,55,21,16],
[64,55,21,17],
[64,55,21,18],
[64,55,23,0],
[64,55,23,1],
[64,55,23,2],
[64,55,23,3],
[64,55,23,5],
[64,55,23,6],
[64,55,23,7],
[64,55,23,8],
[64,55,23,9],
[64,55,23,11],
[64,55,23,12],
[64,55,23,13],
[64,55,23,14],
[64,55,23,15],
[64,55,24,0],
[64,55,24,1],
[64,55,24,2],
[64,55,24,3],
[64,55,24,5],
[64,55,24,6],
[64,55,24,7],
[64,55,24,8],
[64,55,24,9],
[64,55,24,11],
[64,55,24,12],
[64,55,24,13],
[64,55,24,14],
[64,55,24,15],
[64,55,25,0],
[64,55,25,1],
[64,55,25,2],
[64,55,25,3],
[64,55,25,5],
[64,55,25,6],
[64,55,25,8],
[64,55,25,9],
[64,55,25,11],
[64,55,25,12],
[64,55,25,13],
[64,55,26,0],
[64,55,26,1],
[64,55,26,2],
[64,55,26,3],
[64,55,26,5],
[64,55,26,6],
[64,55,26,7],
[64,55,26,8],
[64,55,26,11],
[64,55,28,0],
[64,55,28,1],
[64,55,28,2],
[64,55,28,3],
[64,55,28,5],
[64,55,28,6],
[64,55,29,0],
[64,55,29,1],
[64,55,29,2],
[64,55,29,3],
[64,55,29,5],
[64,55,30,0],
[64,55,30,1],
[64,55,30,2],
[64,55,30,3],
[64,55,31,0],
[64,55,31,1],
[64,55,31,2],
[64,56,3,0],
[64,56,3,1],
[64,56,3,2],
[64,56,5,0],
[64,56,5,1],
[64,56,5,2],
[64,56,6,0],
[64,56,6,1],
[64,56,6,2],
[64,56,7,0],
[64,56,7,1],
[64,56,7,2],
[64,56,7,3],
[64,56,7,5],
[64,56,7,6],
[64,56,8,0],
[64,56,8,1],
[64,56,8,2],
[64,56,8,7],
[64,56,10,0],
[64,56,10,1],
[64,56,10,2],
[64,56,10,3],
[64,56,10,5],
[64,56,10,6],
[64,56,10,7],
[64,56,10,8],
[64,56,11,0],
[64,56,11,1],
[64,56,11,2],
[64,56,11,3],
[64,56,11,5],
[64,56,11,6],
[64,56,11,7],
[64,56,11,8],
[64,56,11,10],
[64,56,12,0],
[64,56,12,1],
[64,56,12,2],
[64,56,12,3],
[64,56,12,5],
[64,56,12,6],
[64,56,12,7],
[64,56,12,8],
[64,56,13,0],
[64,56,13,1],
[64,56,13,2],
[64,56,13,3],
[64,56,13,5],
[64,56,13,6],
[64,56,13,7],
[64,56,13,8],
[64,56,13,12],
[64,56,14,0],
[64,56,14,1],
[64,56,14,2],
[64,56,14,3],
[64,56,14,5],
[64,56,14,6],
[64,56,14,7],
[64,56,14,10],
[64,56,14,11],
[64,56,14,12],
[64,56,16,0],
[64,56,16,1],
[64,56,16,2],
[64,56,16,3],
[64,56,16,6],
[64,56,16,7],
[64,56,16,8],
[64,56,16,10],
[64,56,16,11],
[64,56,16,12],
[64,56,16,13],
[64,56,16,14],
[64,56,17,0],
[64,56,17,1],
[64,56,17,2],
[64,56,17,3],
[64,56,17,6],
[64,56,17,7],
[64,56,17,8],
[64,56,17,10],
[64,56,17,11],
[64,56,17,12],
[64,56,17,13],
[64,56,17,14],
[64,56,17,16],
[64,56,19,3],
[64,56,19,5],
[64,56,19,6],
[64,56,19,7],
[64,56,19,8],
[64,56,19,10],
[64,56,19,11],
[64,56,19,12],
[64,56,19,16],
[64,56,19,17],
[64,56,20,0],
[64,56,20,1],
[64,56,20,2],
[64,56,20,3],
[64,56,20,5],
[64,56,20,6],
[64,56,20,7],
[64,56,20,8],
[64,56,20,11],
[64,56,20,12],
[64,56,20,13],
[64,56,20,14],
[64,56,20,16],
[64,56,20,17],
[64,56,20,19],
[64,56,21,0],
[64,56,21,1],
[64,56,21,2],
[64,56,21,7],
[64,56,21,10],
[64,56,21,11],
[64,56,21,12],
[64,56,21,13],
[64,56,21,14],
[64,56,21,16],
[64,56,21,17],
[64,56,22,0],
[64,56,22,1],
[64,56,22,2],
[64,56,22,3],
[64,56,22,5],
[64,56,22,6],
[64,56,22,7],
[64,56,22,8],
[64,56,22,11],
[64,56,22,12],
[64,56,22,13],
[64,56,22,14],
[64,56,22,16],
[64,56,22,17],
[64,56,23,0],
[64,56,23,1],
[64,56,23,2],
[64,56,23,3],
[64,56,23,5],
[64,56,23,6],
[64,56,23,7],
[64,56,23,8],
[64,56,23,10],
[64,56,23,11],
[64,56,23,12],
[64,56,23,13],
[64,56,23,14],
[64,56,24,0],
[64,56,24,1],
[64,56,24,2],
[64,56,24,3],
[64,56,24,5],
[64,56,24,6],
[64,56,24,7],
[64,56,24,8],
[64,56,24,10],
[64,56,24,11],
[64,56,24,12],
[64,56,24,13],
[64,56,24,14],
[64,56,25,0],
[64,56,25,1],
[64,56,25,2],
[64,56,25,3],
[64,56,25,5],
[64,56,25,6],
[64,56,25,8],
[64,56,25,10],
[64,56,25,11],
[64,56,25,12],
[64,56,25,13],
[64,56,27,0],
[64,56,27,1],
[64,56,27,2],
[64,56,27,3],
[64,56,27,5],
[64,56,27,6],
[64,56,27,7],
[64,56,27,8],
[64,56,28,0],
[64,56,28,1],
[64,56,28,2],
[64,56,28,3],
[64,56,28,5],
[64,56,28,6],
[64,56,29,0],
[64,56,29,1],
[64,56,29,2],
[64,56,29,3],
[64,56,29,5],
[64,56,30,0],
[64,56,30,1],
[64,56,30,2],
[64,56,30,3],
[64,56,31,0],
[64,56,31,1],
[64,56,31,2],
[64,57,3,0],
[64,57,3,1],
[64,57,3,2],
[64,57,5,0],
[64,57,5,1],
[64,57,5,2],
[64,57,6,0],
[64,57,6,1],
[64,57,6,2],
[64,57,7,0],
[64,57,7,1],
[64,57,7,2],
[64,57,7,3],
[64,57,7,5],
[64,57,7,6],
[64,57,8,0],
[64,57,8,1],
[64,57,8,2],
[64,57,8,7],
[64,57,9,0],
[64,57,9,1],
[64,57,9,2],
[64,57,9,3],
[64,57,9,5],
[64,57,9,6],
[64,57,9,7],
[64,57,9,8],
[64,57,10,0],
[64,57,10,1],
[64,57,10,2],
[64,57,10,3],
[64,57,10,5],
[64,57,10,6],
[64,57,10,7],
[64,57,10,8],
[64,57,10,9],
[64,57,11,0],
[64,57,11,1],
[64,57,11,2],
[64,57,11,3],
[64,57,11,5],
[64,57,11,6],
[64,57,11,7],
[64,57,11,8],
[64,57,11,9],
[64,57,12,0],
[64,57,12,1],
[64,57,12,2],
[64,57,12,3],
[64,57,12,5],
[64,57,12,6],
[64,57,12,7],
[64,57,12,10],
[64,57,13,0],
[64,57,13,1],
[64,57,13,2],
[64,57,13,3],
[64,57,13,5],
[64,57,13,6],
[64,57,13,7],
[64,57,13,10],
[64,57,13,11],
[64,57,13,12],
[64,57,14,0],
[64,57,14,1],
[64,57,14,2],
[64,57,14,3],
[64,57,14,5],
[64,57,14,8],
[64,57,14,9],
[64,57,14,10],
[64,57,14,11],
[64,57,14,12],
[64,57,15,0],
[64,57,15,1],
[64,57,15,2],
[64,57,15,3],
[64,57,15,6],
[64,57,15,7],
[64,57,15,8],
[64,57,15,10],
[64,57,15,11],
[64,57,15,12],
[64,57,15,13],
[64,57,15,14],
[64,57,16,0],
[64,57,16,1],
[64,57,16,2],
[64,57,16,5],
[64,57,16,6],
[64,57,16,7],
[64,57,16,8],
[64,57,16,9],
[64,57,16,10],
[64,57,16,11],
[64,57,16,12],
[64,57,16,13],
[64,57,16,14],
[64,57,16,15],
[64,57,17,0],
[64,57,17,1],
[64,57,17,2],
[64,57,17,5],
[64,57,17,6],
[64,57,17,7],
[64,57,17,8],
[64,57,17,9],
[64,57,17,10],
[64,57,17,11],
[64,57,17,12],
[64,57,17,13],
[64,57,17,14],
[64,57,17,15],
[64,57,17,16],
[64,57,18,3],
[64,57,18,5],
[64,57,18,6],
[64,57,18,7],
[64,57,18,8],
[64,57,18,10],
[64,57,18,11],
[64,57,18,12],
[64,57,18,13],
[64,57,18,14],
[64,57,18,16],
[64,57,18,17],
[64,57,19,0],
[64,57,19,1],
[64,57,19,2],
[64,57,19,3],
[64,57,19,5],
[64,57,19,6],
[64,57,19,7],
[64,57,19,8],
[64,57,19,9],
[64,57,19,10],
[64,57,19,11],
[64,57,19,12],
[64,57,19,15],
[64,57,19,16],
[64,57,19,17],
[64,57,19,18],
[64,57,20,0],
[64,57,20,1],
[64,57,20,2],
[64,57,20,3],
[64,57,20,5],
[64,57,20,6],
[64,57,20,7],
[64,57,20,8],
[64,57,20,9],
[64,57,20,11],
[64,57,20,12],
[64,57,20,13],
[64,57,20,14],
[64,57,20,15],
[64,57,20,16],
[64,57,20,17],
[64,57,20,18],
[64,57,21,0],
[64,57,21,1],
[64,57,21,2],
[64,57,21,7],
[64,57,21,9],
[64,57,21,10],
[64,57,21,11],
[64,57,21,12],
[64,57,21,13],
[64,57,21,14],
[64,57,21,15],
[64,57,21,16],
[64,57,21,17],
[64,57,22,0],
[64,57,22,1],
[64,57,22,2],
[64,57,22,3],
[64,57,22,5],
[64,57,22,6],
[64,57,22,7],
[64,57,22,8],
[64,57,22,9],
[64,57,22,11],
[64,57,22,12],
[64,57,22,13],
[64,57,22,14],
[64,57,22,15],
[64,57,24,0],
[64,57,24,1],
[64,57,24,2],
[64,57,24,3],
[64,57,24,5],
[64,57,24,6],
[64,57,24,7],
[64,57,24,8],
[64,57,24,9],
[64,57,24,10],
[64,57,24,11],
[64,57,24,12],
[64,57,24,13],
[64,57,24,14],
[64,57,25,0],
[64,57,25,1],
[64,57,25,2],
[64,57,25,3],
[64,57,25,5],
[64,57,25,6],
[64,57,25,8],
[64,57,25,9],
[64,57,25,10],
[64,57,25,11],
[64,57,26,0],
[64,57,26,1],
[64,57,26,2],
[64,57,26,3],
[64,57,26,5],
[64,57,26,6],
[64,57,26,7],
[64,57,26,8],
[64,57,27,0],
[64,57,27,1],
[64,57,27,2],
[64,57,27,3],
[64,57,27,5],
[64,57,27,6],
[64,57,27,7],
[64,57,28,0],
[64,57,28,1],
[64,57,28,2],
[64,57,28,3],
[64,57,28,5],
[64,57,29,0],
[64,57,29,1],
[64,57,29,2],
[64,57,29,3],
[64,58,3,0],
[64,58,3,1],
[64,58,3,2],
[64,58,5,0],
[64,58,5,1],
[64,58,5,2],
[64,58,6,0],
[64,58,6,1],
[64,58,6,2],
[64,58,7,0],
[64,58,7,1],
[64,58,7,2],
[64,58,7,3],
[64,58,7,5],
[64,58,7,6],
[64,58,8,0],
[64,58,8,1],
[64,58,8,2],
[64,58,8,7],
[64,58,9,0],
[64,58,9,1],
[64,58,9,2],
[64,58,9,3],
[64,58,9,5],
[64,58,9,6],
[64,58,9,7],
[64,58,9,8],
[64,58,10,0],
[64,58,10,1],
[64,58,10,2],
[64,58,10,3],
[64,58,10,5],
[64,58,10,6],
[64,58,10,7],
[64,58,10,8],
[64,58,10,9],
[64,58,11,0],
[64,58,11,1],
[64,58,11,2],
[64,58,11,3],
[64,58,11,5],
[64,58,11,6],
[64,58,11,7],
[64,58,11,8],
[64,58,11,9],
[64,58,12,0],
[64,58,12,1],
[64,58,12,2],
[64,58,12,3],
[64,58,12,5],
[64,58,12,6],
[64,58,12,7],
[64,58,12,10],
[64,58,13,0],
[64,58,13,1],
[64,58,13,2],
[64,58,13,3],
[64,58,13,5],
[64,58,13,6],
[64,58,13,7],
[64,58,13,10],
[64,58,13,11],
[64,58,13,12],
[64,58,14,0],
[64,58,14,1],
[64,58,14,2],
[64,58,14,3],
[64,58,14,5],
[64,58,14,8],
[64,58,14,9],
[64,58,14,10],
[64,58,14,11],
[64,58,14,12],
[64,58,15,0],
[64,58,15,1],
[64,58,15,2],
[64,58,15,3],
[64,58,15,6],
[64,58,15,7],
[64,58,15,8],
[64,58,15,10],
[64,58,15,11],
[64,58,15,12],
[64,58,15,13],
[64,58,15,14],
[64,58,17,0],
[64,58,17,1],
[64,58,17,2],
[64,58,17,5],
[64,58,17,6],
[64,58,17,7],
[64,58,17,8],
[64,58,17,9],
[64,58,17,10],
[64,58,17,11],
[64,58,17,12],
[64,58,17,13],
[64,58,17,14],
[64,58,17,15],
[64,58,18,3],
[64,58,18,5],
[64,58,18,6],
[64,58,18,7],
[64,58,18,8],
[64,58,18,10],
[64,58,18,11],
[64,58,18,12],
[64,58,18,13],
[64,58,18,14],
[64,58,18,17],
[64,58,19,0],
[64,58,19,1],
[64,58,19,2],
[64,58,19,3],
[64,58,19,5],
[64,58,19,6],
[64,58,19,7],
[64,58,19,8],
[64,58,19,9],
[64,58,19,10],
[64,58,19,11],
[64,58,19,12],
[64,58,19,15],
[64,58,19,17],
[64,58,19,18],
[64,58,20,0],
[64,58,20,1],
[64,58,20,2],
[64,58,20,3],
[64,58,20,5],
[64,58,20,6],
[64,58,20,7],
[64,58,20,8],
[64,58,20,9],
[64,58,20,11],
[64,58,20,12],
[64,58,20,13],
[64,58,20,14],
[64,58,20,15],
[64,58,20,17],
[64,58,20,18],
[64,58,21,0],
[64,58,21,1],
[64,58,21,2],
[64,58,21,7],
[64,58,21,9],
[64,58,21,10],
[64,58,21,11],
[64,58,21,12],
[64,58,21,13],
[64,58,21,14],
[64,58,21,15],
[64,58,21,17],
[64,58,22,0],
[64,58,22,1],
[64,58,22,2],
[64,58,22,3],
[64,58,22,5],
[64,58,22,6],
[64,58,22,7],
[64,58,22,8],
[64,58,22,9],
[64,58,22,11],
[64,58,22,12],
[64,58,22,13],
[64,58,22,14],
[64,58,22,15],
[64,58,23,0],
[64,58,23,1],
[64,58,23,2],
[64,58,23,3],
[64,58,23,5],
[64,58,23,6],
[64,58,23,7],
[64,58,23,8],
[64,58,23,9],
[64,58,23,10],
[64,58,23,11],
[64,58,23,12],
[64,58,23,13],
[64,58,23,14],
[64,58,25,0],
[64,58,25,1],
[64,58,25,2],
[64,58,25,3],
[64,58,25,5],
[64,58,25,6],
[64,58,25,8],
[64,58,25,9],
[64,58,25,10],
[64,58,25,11],
[64,58,26,0],
[64,58,26,1],
[64,58,26,2],
[64,58,26,3],
[64,58,26,5],
[64,58,26,6],
[64,58,26,7],
[64,58,26,8],
[64,58,27,0],
[64,58,27,1],
[64,58,27,2],
[64,58,27,3],
[64,58,27,5],
[64,58,27,6],
[64,58,27,7],
[64,58,28,0],
[64,58,28,1],
[64,58,28,2],
[64,58,28,3],
[64,58,28,5],
[64,58,29,0],
[64,58,29,1],
[64,58,29,2],
[64,58,29,3],
[64,58,30,0],
[64,58,30,1],
[64,58,30,2],
[64,59,3,0],
[64,59,3,1],
[64,59,3,2],
[64,59,5,0],
[64,59,5,1],
[64,59,5,2],
[64,59,6,0],
[64,59,6,1],
[64,59,6,2],
[64,59,7,0],
[64,59,7,1],
[64,59,7,2],
[64,59,7,3],
[64,59,7,5],
[64,59,7,6],
[64,59,8,0],
[64,59,8,1],
[64,59,8,2],
[64,59,8,7],
[64,59,10,0],
[64,59,10,1],
[64,59,10,2],
[64,59,10,3],
[64,59,10,5],
[64,59,10,6],
[64,59,10,7],
[64,59,11,0],
[64,59,11,1],
[64,59,11,2],
[64,59,11,3],
[64,59,11,5],
[64,59,11,6],
[64,59,11,7],
[64,59,11,10],
[64,59,12,0],
[64,59,12,1],
[64,59,12,2],
[64,59,12,3],
[64,59,12,5],
[64,59,12,8],
[64,59,12,10],
[64,59,13,0],
[64,59,13,1],
[64,59,13,2],
[64,59,13,3],
[64,59,13,5],
[64,59,13,8],
[64,59,13,10],
[64,59,13,11],
[64,59,13,12],
[64,59,14,0],
[64,59,14,1],
[64,59,14,2],
[64,59,14,3],
[64,59,14,6],
[64,59,14,7],
[64,59,14,8],
[64,59,14,10],
[64,59,14,11],
[64,59,14,12],
[64,59,16,3],
[64,59,16,5],
[64,59,16,6],
[64,59,16,7],
[64,59,16,8],
[64,59,16,10],
[64,59,16,11],
[64,59,16,12],
[64,59,16,13],
[64,59,16,14],
[64,59,17,3],
[64,59,17,5],
[64,59,17,6],
[64,59,17,7],
[64,59,17,8],
[64,59,17,10],
[64,59,17,11],
[64,59,17,12],
[64,59,17,13],
[64,59,17,14],
[64,59,17,16],
[64,59,19,0],
[64,59,19,1],
[64,59,19,2],
[64,59,19,3],
[64,59,19,5],
[64,59,19,6],
[64,59,19,7],
[64,59,19,8],
[64,59,19,10],
[64,59,19,11],
[64,59,19,12],
[64,59,19,16],
[64,59,19,17],
[64,59,20,0],
[64,59,20,1],
[64,59,20,2],
[64,59,20,3],
[64,59,20,5],
[64,59,20,6],
[64,59,20,7],
[64,59,20,8],
[64,59,20,11],
[64,59,20,12],
[64,59,20,13],
[64,59,20,14],
[64,59,20,16],
[64,59,20,17],
[64,59,21,0],
[64,59,21,1],
[64,59,21,2],
[64,59,21,7],
[64,59,21,10],
[64,59,21,11],
[64,59,21,12],
[64,59,21,13],
[64,59,21,14],
[64,59,22,0],
[64,59,22,1],
[64,59,22,2],
[64,59,22,3],
[64,59,22,5],
[64,59,22,6],
[64,59,22,7],
[64,59,22,8],
[64,59,22,11],
[64,59,22,12],
[64,59,22,13],
[64,59,22,14],
[64,59,23,0],
[64,59,23,1],
[64,59,23,2],
[64,59,23,3],
[64,59,23,5],
[64,59,23,6],
[64,59,23,7],
[64,59,23,8],
[64,59,23,10],
[64,59,23,11],
[64,59,23,12],
[64,59,23,13],
[64,59,24,0],
[64,59,24,1],
[64,59,24,2],
[64,59,24,3],
[64,59,24,5],
[64,59,24,6],
[64,59,24,7],
[64,59,24,8],
[64,59,24,10],
[64,59,24,11],
[64,59,24,12],
[64,59,24,13],
[64,59,25,0],
[64,59,25,1],
[64,59,25,2],
[64,59,25,3],
[64,59,25,5],
[64,59,25,6],
[64,59,25,8],
[64,59,27,0],
[64,59,27,1],
[64,59,27,2],
[64,59,27,3],
[64,59,27,5],
[64,59,28,0],
[64,59,28,1],
[64,59,28,2],
[64,59,28,3],
[64,59,29,0],
[64,59,29,1],
[64,59,29,2],
[64,60,3,0],
[64,60,3,1],
[64,60,3,2],
[64,60,5,0],
[64,60,5,1],
[64,60,5,2],
[64,60,6,0],
[64,60,6,1],
[64,60,6,2],
[64,60,7,0],
[64,60,7,1],
[64,60,7,2],
[64,60,7,3],
[64,60,7,5],
[64,60,7,6],
[64,60,8,0],
[64,60,8,1],
[64,60,8,2],
[64,60,8,7],
[64,60,9,0],
[64,60,9,1],
[64,60,9,2],
[64,60,9,3],
[64,60,9,5],
[64,60,9,6],
[64,60,9,7],
[64,60,10,0],
[64,60,10,1],
[64,60,10,2],
[64,60,10,3],
[64,60,10,5],
[64,60,10,8],
[64,60,10,9],
[64,60,11,0],
[64,60,11,1],
[64,60,11,2],
[64,60,11,3],
[64,60,11,5],
[64,60,11,8],
[64,60,11,9],
[64,60,11,10],
[64,60,12,0],
[64,60,12,1],
[64,60,12,2],
[64,60,12,3],
[64,60,12,6],
[64,60,12,7],
[64,60,12,8],
[64,60,12,9],
[64,60,12,10],
[64,60,15,3],
[64,60,15,5],
[64,60,15,6],
[64,60,15,7],
[64,60,15,8],
[64,60,15,10],
[64,60,15,11],
[64,60,15,12],
[64,60,16,0],
[64,60,16,1],
[64,60,16,2],
[64,60,16,3],
[64,60,16,5],
[64,60,16,6],
[64,60,16,7],
[64,60,16,8],
[64,60,16,9],
[64,60,16,10],
[64,60,16,11],
[64,60,16,12],
[64,60,16,15],
[64,60,17,0],
[64,60,17,1],
[64,60,17,2],
[64,60,17,3],
[64,60,17,5],
[64,60,17,6],
[64,60,17,7],
[64,60,17,8],
[64,60,17,9],
[64,60,17,10],
[64,60,17,11],
[64,60,17,12],
[64,60,17,15],
[64,60,17,16],
[64,60,18,0],
[64,60,18,1],
[64,60,18,2],
[64,60,18,3],
[64,60,18,5],
[64,60,18,6],
[64,60,18,7],
[64,60,18,8],
[64,60,18,10],
[64,60,18,11],
[64,60,18,12],
[64,60,18,16],
[64,60,18,17],
[64,60,20,0],
[64,60,20,1],
[64,60,20,2],
[64,60,20,3],
[64,60,20,5],
[64,60,20,6],
[64,60,20,7],
[64,60,20,8],
[64,60,20,9],
[64,60,20,11],
[64,60,20,12],
[64,60,20,15],
[64,60,21,0],
[64,60,21,1],
[64,60,21,2],
[64,60,21,7],
[64,60,21,9],
[64,60,21,10],
[64,60,21,11],
[64,60,21,12],
[64,60,22,0],
[64,60,22,1],
[64,60,22,2],
[64,60,22,3],
[64,60,22,5],
[64,60,22,6],
[64,60,22,7],
[64,60,22,8],
[64,60,22,9],
[64,60,22,11],
[64,60,22,12],
[64,60,23,0],
[64,60,23,1],
[64,60,23,2],
[64,60,23,3],
[64,60,23,5],
[64,60,23,6],
[64,60,23,7],
[64,60,23,8],
[64,60,23,9],
[64,60,23,10],
[64,60,23,11],
[64,60,24,0],
[64,60,24,1],
[64,60,24,2],
[64,60,24,3],
[64,60,24,5],
[64,60,24,6],
[64,60,24,7],
[64,60,24,8],
[64,60,24,9],
[64,60,24,10],
[64,60,24,11],
[64,60,25,0],
[64,60,25,1],
[64,60,25,2],
[64,60,25,3],
[64,60,25,5],
[64,60,25,6],
[64,60,26,0],
[64,60,26,1],
[64,60,26,2],
[64,60,26,3],
[64,60,26,5],
[64,60,27,0],
[64,60,27,1],
[64,60,27,2],
[64,60,27,3],
[64,60,28,0],
[64,60,28,1],
[64,60,28,2],
[64,62,3,0],
[64,62,3,1],
[64,62,3,2],
[64,62,5,0],
[64,62,5,1],
[64,62,5,2],
[64,62,6,0],
[64,62,6,1],
[64,62,6,2],
[64,62,8,0],
[64,62,8,1],
[64,62,8,2],
[64,62,9,0],
[64,62,9,1],
[64,62,9,2],
[64,62,9,3],
[64,62,9,5],
[64,62,9,8],
[64,62,10,0],
[64,62,10,1],
[64,62,10,2],
[64,62,10,3],
[64,62,10,6],
[64,62,10,8],
[64,62,10,9],
[64,62,11,0],
[64,62,11,1],
[64,62,11,2],
[64,62,11,3],
[64,62,11,6],
[64,62,11,8],
[64,62,11,9],
[64,62,11,10],
[64,62,12,0],
[64,62,12,1],
[64,62,12,2],
[64,62,12,5],
[64,62,12,6],
[64,62,12,8],
[64,62,12,9],
[64,62,12,10],
[64,62,13,0],
[64,62,13,1],
[64,62,13,2],
[64,62,13,5],
[64,62,13,6],
[64,62,13,8],
[64,62,13,9],
[64,62,13,10],
[64,62,13,11],
[64,62,13,12],
[64,62,14,3],
[64,62,14,5],
[64,62,14,6],
[64,62,14,8],
[64,62,14,9],
[64,62,14,10],
[64,62,14,11],
[64,62,14,12],
[64,62,15,0],
[64,62,15,1],
[64,62,15,2],
[64,62,15,3],
[64,62,15,5],
[64,62,15,6],
[64,62,15,8],
[64,62,15,10],
[64,62,15,11],
[64,62,15,12],
[64,62,15,13],
[64,62,15,14],
[64,62,16,0],
[64,62,16,1],
[64,62,16,2],
[64,62,16,3],
[64,62,16,5],
[64,62,16,6],
[64,62,16,8],
[64,62,16,9],
[64,62,16,10],
[64,62,16,11],
[64,62,16,12],
[64,62,16,13],
[64,62,16,14],
[64,62,16,15],
[64,62,17,0],
[64,62,17,1],
[64,62,17,2],
[64,62,17,3],
[64,62,17,5],
[64,62,17,6],
[64,62,17,8],
[64,62,17,9],
[64,62,17,10],
[64,62,17,11],
[64,62,17,12],
[64,62,17,13],
[64,62,17,14],
[64,62,17,15],
[64,62,17,16],
[64,62,18,0],
[64,62,18,1],
[64,62,18,2],
[64,62,18,3],
[64,62,18,5],
[64,62,18,6],
[64,62,18,8],
[64,62,18,10],
[64,62,18,11],
[64,62,18,12],
[64,62,18,13],
[64,62,18,14],
[64,62,18,16],
[64,62,18,17],
[64,62,19,0],
[64,62,19,1],
[64,62,19,2],
[64,62,19,3],
[64,62,19,5],
[64,62,19,6],
[64,62,19,8],
[64,62,19,9],
[64,62,19,10],
[64,62,19,11],
[64,62,19,12],
[64,62,19,15],
[64,62,20,0],
[64,62,20,1],
[64,62,20,2],
[64,62,20,3],
[64,62,20,5],
[64,62,20,6],
[64,62,20,8],
[64,62,20,9],
[64,62,20,11],
[64,62,20,12],
[64,62,20,13],
[64,62,20,14],
[64,62,21,0],
[64,62,21,1],
[64,62,21,2],
[64,62,21,9],
[64,62,21,10],
[64,62,21,11],
[64,62,21,12],
[64,62,21,13],
[64,62,22,0],
[64,62,22,1],
[64,62,22,2],
[64,62,22,3],
[64,62,22,5],
[64,62,22,6],
[64,62,22,8],
[64,62,22,9],
[64,62,22,11],
[64,62,23,0],
[64,62,23,1],
[64,62,23,2],
[64,62,23,3],
[64,62,23,5],
[64,62,23,6],
[64,62,23,8],
[64,62,23,9],
[64,62,24,0],
[64,62,24,1],
[64,62,24,2],
[64,62,24,3],
[64,62,24,5],
[64,62,24,6],
[64,62,24,8],
[64,62,24,9],
[64,62,26,0],
[64,62,26,1],
[64,62,26,2],
[64,62,26,3],
[64,62,27,0],
[64,62,27,1],
[64,62,27,2],
[64,63,3,0],
[64,63,3,1],
[64,63,3,2],
[64,63,5,0],
[64,63,5,1],
[64,63,5,2],
[64,63,6,0],
[64,63,6,1],
[64,63,6,2],
[64,63,7,0],
[64,63,7,1],
[64,63,7,2],
[64,63,7,3],
[64,63,7,5],
[64,63,8,0],
[64,63,8,1],
[64,63,8,2],
[64,63,8,7],
[64,63,10,0],
[64,63,10,1],
[64,63,10,2],
[64,63,10,5],
[64,63,10,6],
[64,63,10,7],
[64,63,10,8],
[64,63,11,0],
[64,63,11,1],
[64,63,11,2],
[64,63,11,5],
[64,63,11,6],
[64,63,11,7],
[64,63,11,8],
[64,63,11,10],
[64,63,12,3],
[64,63,12,5],
[64,63,12,6],
[64,63,12,7],
[64,63,12,8],
[64,63,12,10],
[64,63,13,3],
[64,63,13,5],
[64,63,13,6],
[64,63,13,7],
[64,63,13,8],
[64,63,13,10],
[64,63,13,11],
[64,63,13,12],
[64,63,14,0],
[64,63,14,1],
[64,63,14,2],
[64,63,14,3],
[64,63,14,5],
[64,63,14,6],
[64,63,14,7],
[64,63,14,8],
[64,63,14,10],
[64,63,14,11],
[64,63,14,12],
[64,63,16,0],
[64,63,16,1],
[64,63,16,2],
[64,63,16,3],
[64,63,16,5],
[64,63,16,6],
[64,63,16,7],
[64,63,16,8],
[64,63,16,10],
[64,63,16,11],
[64,63,16,12],
[64,63,16,13],
[64,63,16,14],
[64,63,17,0],
[64,63,17,1],
[64,63,17,2],
[64,63,17,3],
[64,63,17,5],
[64,63,17,6],
[64,63,17,7],
[64,63,17,8],
[64,63,17,10],
[64,63,17,11],
[64,63,17,12],
[64,63,17,13],
[64,63,17,14],
[64,63,17,16],
[64,63,19,0],
[64,63,19,1],
[64,63,19,2],
[64,63,19,3],
[64,63,19,5],
[64,63,19,6],
[64,63,19,7],
[64,63,19,8],
[64,63,19,10],
[64,63,19,11],
[64,63,19,12],
[64,63,20,0],
[64,63,20,1],
[64,63,20,2],
[64,63,20,3],
[64,63,20,5],
[64,63,20,6],
[64,63,20,7],
[64,63,20,8],
[64,63,20,11],
[64,63,20,12],
[64,63,20,13],
[64,63,21,0],
[64,63,21,1],
[64,63,21,2],
[64,63,21,7],
[64,63,21,10],
[64,63,21,11],
[64,63,22,0],
[64,63,22,1],
[64,63,22,2],
[64,63,22,3],
[64,63,22,5],
[64,63,22,6],
[64,63,22,7],
[64,63,22,8],
[64,63,23,0],
[64,63,23,1],
[64,63,23,2],
[64,63,23,3],
[64,63,23,5],
[64,63,23,6],
[64,63,23,7],
[64,63,24,0],
[64,63,24,1],
[64,63,24,2],
[64,63,24,3],
[64,63,24,5],
[64,63,24,6],
[64,63,24,7],
[64,63,25,0],
[64,63,25,1],
[64,63,25,2],
[64,63,25,3],
[65,4,3,0],
[65,4,3,1],
[65,4,3,2],
[65,5,4,0],
[65,5,4,1],
[65,5,4,2],
[65,6,4,0],
[65,6,4,1],
[65,6,4,2],
[65,7,3,0],
[65,7,3,1],
[65,7,3,2],
[65,7,4,0],
[65,7,4,1],
[65,7,4,2],
[65,7,4,3],
[65,7,5,0],
[65,7,5,1],
[65,7,5,2],
[65,7,5,4],
[65,7,6,0],
[65,7,6,1],
[65,7,6,2],
[65,7,6,4],
[65,8,4,0],
[65,8,4,1],
[65,8,4,2],
[65,8,7,0],
[65,8,7,1],
[65,8,7,2],
[65,8,7,4],
[65,9,3,0],
[65,9,3,1],
[65,9,3,2],
[65,9,4,0],
[65,9,4,1],
[65,9,4,2],
[65,9,4,3],
[65,9,5,0],
[65,9,5,1],
[65,9,5,2],
[65,9,5,4],
[65,9,6,0],
[65,9,6,1],
[65,9,6,2],
[65,9,6,4],
[65,9,7,0],
[65,9,7,1],
[65,9,7,2],
[65,9,7,3],
[65,9,7,4],
[65,9,7,5],
[65,9,7,6],
[65,9,8,0],
[65,9,8,1],
[65,9,8,2],
[65,9,8,4],
[65,9,8,7],
[65,10,3,0],
[65,10,3,1],
[65,10,3,2],
[65,10,4,0],
[65,10,4,1],
[65,10,4,2],
[65,10,4,3],
[65,10,5,0],
[65,10,5,1],
[65,10,5,2],
[65,10,5,4],
[65,10,6,0],
[65,10,6,1],
[65,10,6,2],
[65,10,6,4],
[65,10,7,0],
[65,10,7,1],
[65,10,7,2],
[65,10,7,3],
[65,10,7,4],
[65,10,7,5],
[65,10,7,6],
[65,10,8,0],
[65,10,8,1],
[65,10,8,2],
[65,10,8,4],
[65,10,8,7],
[65,10,9,0],
[65,10,9,1],
[65,10,9,2],
[65,10,9,3],
[65,10,9,4],
[65,10,9,5],
[65,10,9,6],
[65,10,9,7],
[65,10,9,8],
[65,11,3,0],
[65,11,3,1],
[65,11,3,2],
[65,11,4,0],
[65,11,4,1],
[65,11,4,2],
[65,11,4,3],
[65,11,5,0],
[65,11,5,1],
[65,11,5,2],
[65,11,5,4],
[65,11,6,0],
[65,11,6,1],
[65,11,6,2],
[65,11,6,4],
[65,11,7,0],
[65,11,7,1],
[65,11,7,2],
[65,11,7,3],
[65,11,7,4],
[65,11,7,5],
[65,11,7,6],
[65,11,8,0],
[65,11,8,1],
[65,11,8,2],
[65,11,8,4],
[65,11,8,7],
[65,11,9,0],
[65,11,9,1],
[65,11,9,2],
[65,11,9,3],
[65,11,9,4],
[65,11,9,5],
[65,11,9,6],
[65,11,9,7],
[65,11,9,8],
[65,11,10,0],
[65,11,10,1],
[65,11,10,2],
[65,11,10,3],
[65,11,10,4],
[65,11,10,5],
[65,11,10,6],
[65,11,10,7],
[65,11,10,8],
[65,11,10,9],
[65,12,3,0],
[65,12,3,1],
[65,12,3,2],
[65,12,4,0],
[65,12,4,1],
[65,12,4,2],
[65,12,4,3],
[65,12,5,0],
[65,12,5,1],
[65,12,5,2],
[65,12,5,4],
[65,12,6,0],
[65,12,6,1],
[65,12,6,2],
[65,12,6,4],
[65,12,7,0],
[65,12,7,1],
[65,12,7,2],
[65,12,7,3],
[65,12,7,4],
[65,12,7,5],
[65,12,7,6],
[65,12,8,0],
[65,12,8,1],
[65,12,8,2],
[65,12,8,4],
[65,12,8,7],
[65,12,9,0],
[65,12,9,1],
[65,12,9,2],
[65,12,9,3],
[65,12,9,4],
[65,12,9,5],
[65,12,9,6],
[65,12,9,7],
[65,12,9,8],
[65,12,10,0],
[65,12,10,1],
[65,12,10,2],
[65,12,10,3],
[65,12,10,4],
[65,12,10,5],
[65,12,10,6],
[65,12,10,7],
[65,12,10,8],
[65,12,10,9],
[65,13,3,0],
[65,13,3,1],
[65,13,3,2],
[65,13,4,0],
[65,13,4,1],
[65,13,4,2],
[65,13,4,3],
[65,13,5,0],
[65,13,5,1],
[65,13,5,2],
[65,13,5,4],
[65,13,6,0],
[65,13,6,1],
[65,13,6,2],
[65,13,6,4],
[65,13,7,0],
[65,13,7,1],
[65,13,7,2],
[65,13,7,3],
[65,13,7,4],
[65,13,7,5],
[65,13,7,6],
[65,13,8,0],
[65,13,8,1],
[65,13,8,2],
[65,13,8,4],
[65,13,8,7],
[65,13,9,0],
[65,13,9,1],
[65,13,9,2],
[65,13,9,3],
[65,13,9,4],
[65,13,9,5],
[65,13,9,6],
[65,13,9,7],
[65,13,9,8],
[65,13,10,0],
[65,13,10,1],
[65,13,10,2],
[65,13,10,3],
[65,13,10,4],
[65,13,10,5],
[65,13,10,6],
[65,13,10,7],
[65,13,10,8],
[65,13,10,9],
[65,13,11,0],
[65,13,11,1],
[65,13,11,2],
[65,13,11,3],
[65,13,11,4],
[65,13,11,5],
[65,13,11,6],
[65,13,11,7],
[65,13,11,8],
[65,13,11,9],
[65,13,11,10],
[65,13,12,0],
[65,13,12,1],
[65,13,12,2],
[65,13,12,3],
[65,13,12,4],
[65,13,12,5],
[65,13,12,6],
[65,13,12,7],
[65,13,12,8],
[65,13,12,9],
[65,13,12,10],
[65,14,3,0],
[65,14,3,1],
[65,14,3,2],
[65,14,4,0],
[65,14,4,1],
[65,14,4,2],
[65,14,4,3],
[65,14,5,0],
[65,14,5,1],
[65,14,5,2],
[65,14,5,4],
[65,14,6,0],
[65,14,6,1],
[65,14,6,2],
[65,14,6,4],
[65,14,7,0],
[65,14,7,1],
[65,14,7,2],
[65,14,7,3],
[65,14,7,4],
[65,14,7,5],
[65,14,7,6],
[65,14,8,0],
[65,14,8,1],
[65,14,8,2],
[65,14,8,4],
[65,14,8,7],
[65,14,9,0],
[65,14,9,1],
[65,14,9,2],
[65,14,9,3],
[65,14,9,4],
[65,14,9,5],
[65,14,9,6],
[65,14,9,7],
[65,14,9,8],
[65,14,10,0],
[65,14,10,1],
[65,14,10,2],
[65,14,10,3],
[65,14,10,4],
[65,14,10,5],
[65,14,10,6],
[65,14,10,7],
[65,14,10,8],
[65,14,10,9],
[65,14,11,0],
[65,14,11,1],
[65,14,11,2],
[65,14,11,3],
[65,14,11,4],
[65,14,11,5],
[65,14,11,6],
[65,14,11,7],
[65,14,11,8],
[65,14,11,9],
[65,14,11,10],
[65,14,12,0],
[65,14,12,1],
[65,14,12,2],
[65,14,12,3],
[65,14,12,4],
[65,14,12,5],
[65,14,12,6],
[65,14,12,7],
[65,14,12,8],
[65,14,12,9],
[65,14,12,10],
[65,15,3,0],
[65,15,3,1],
[65,15,3,2],
[65,15,4,0],
[65,15,4,1],
[65,15,4,2],
[65,15,4,3],
[65,15,5,0],
[65,15,5,1],
[65,15,5,2],
[65,15,5,4],
[65,15,6,0],
[65,15,6,1],
[65,15,6,2],
[65,15,6,4],
[65,15,7,0],
[65,15,7,1],
[65,15,7,2],
[65,15,7,3],
[65,15,7,4],
[65,15,7,5],
[65,15,7,6],
[65,15,8,0],
[65,15,8,1],
[65,15,8,2],
[65,15,8,4],
[65,15,8,7],
[65,15,10,0],
[65,15,10,1],
[65,15,10,2],
[65,15,10,3],
[65,15,10,4],
[65,15,10,5],
[65,15,10,6],
[65,15,10,7],
[65,15,10,8],
[65,15,11,0],
[65,15,11,1],
[65,15,11,2],
[65,15,11,3],
[65,15,11,4],
[65,15,11,5],
[65,15,11,6],
[65,15,11,7],
[65,15,11,8],
[65,15,11,10],
[65,15,12,0],
[65,15,12,1],
[65,15,12,2],
[65,15,12,3],
[65,15,12,4],
[65,15,12,5],
[65,15,12,6],
[65,15,12,7],
[65,15,12,8],
[65,15,12,10],
[65,15,13,0],
[65,15,13,1],
[65,15,13,2],
[65,15,13,3],
[65,15,13,4],
[65,15,13,5],
[65,15,13,6],
[65,15,13,7],
[65,15,13,8],
[65,15,13,10],
[65,15,13,11],
[65,15,13,12],
[65,15,14,0],
[65,15,14,1],
[65,15,14,2],
[65,15,14,3],
[65,15,14,4],
[65,15,14,5],
[65,15,14,6],
[65,15,14,7],
[65,15,14,8],
[65,15,14,10],
[65,15,14,11],
[65,15,14,12],
[65,16,3,0],
[65,16,3,1],
[65,16,3,2],
[65,16,4,0],
[65,16,4,1],
[65,16,4,2],
[65,16,4,3],
[65,16,5,0],
[65,16,5,1],
[65,16,5,2],
[65,16,5,4],
[65,16,6,0],
[65,16,6,1],
[65,16,6,2],
[65,16,6,4],
[65,16,7,0],
[65,16,7,1],
[65,16,7,2],
[65,16,7,3],
[65,16,7,4],
[65,16,7,5],
[65,16,7,6],
[65,16,8,0],
[65,16,8,1],
[65,16,8,2],
[65,16,8,4],
[65,16,8,7],
[65,16,9,0],
[65,16,9,1],
[65,16,9,2],
[65,16,9,3],
[65,16,9,4],
[65,16,9,5],
[65,16,9,6],
[65,16,9,7],
[65,16,9,8],
[65,16,10,0],
[65,16,10,1],
[65,16,10,2],
[65,16,10,3],
[65,16,10,4],
[65,16,10,5],
[65,16,10,6],
[65,16,10,7],
[65,16,10,8],
[65,16,10,9],
[65,16,11,0],
[65,16,11,1],
[65,16,11,2],
[65,16,11,3],
[65,16,11,4],
[65,16,11,5],
[65,16,11,6],
[65,16,11,7],
[65,16,11,8],
[65,16,11,9],
[65,16,11,10],
[65,16,12,0],
[65,16,12,1],
[65,16,12,2],
[65,16,12,3],
[65,16,12,4],
[65,16,12,5],
[65,16,12,6],
[65,16,12,7],
[65,16,12,8],
[65,16,12,9],
[65,16,12,10],
[65,16,13,0],
[65,16,13,1],
[65,16,13,2],
[65,16,13,3],
[65,16,13,4],
[65,16,13,5],
[65,16,13,6],
[65,16,13,7],
[65,16,13,8],
[65,16,13,9],
[65,16,13,10],
[65,16,13,11],
[65,16,13,12],
[65,16,14,0],
[65,16,14,1],
[65,16,14,2],
[65,16,14,3],
[65,16,14,4],
[65,16,14,5],
[65,16,14,6],
[65,16,14,7],
[65,16,14,8],
[65,16,14,9],
[65,16,14,10],
[65,16,14,11],
[65,16,14,12],
[65,16,15,0],
[65,16,15,1],
[65,16,15,2],
[65,16,15,3],
[65,16,15,4],
[65,16,15,5],
[65,16,15,6],
[65,16,15,7],
[65,16,15,8],
[65,16,15,10],
[65,16,15,11],
[65,16,15,12],
[65,16,15,13],
[65,16,15,14],
[65,17,3,0],
[65,17,3,1],
[65,17,3,2],
[65,17,4,0],
[65,17,4,1],
[65,17,4,2],
[65,17,4,3],
[65,17,5,0],
[65,17,5,1],
[65,17,5,2],
[65,17,5,4],
[65,17,6,0],
[65,17,6,1],
[65,17,6,2],
[65,17,6,4],
[65,17,7,0],
[65,17,7,1],
[65,17,7,2],
[65,17,7,3],
[65,17,7,4],
[65,17,7,5],
[65,17,7,6],
[65,17,8,0],
[65,17,8,1],
[65,17,8,2],
[65,17,8,4],
[65,17,8,7],
[65,17,9,0],
[65,17,9,1],
[65,17,9,2],
[65,17,9,3],
[65,17,9,4],
[65,17,9,5],
[65,17,9,6],
[65,17,9,7],
[65,17,9,8],
[65,17,10,0],
[65,17,10,1],
[65,17,10,2],
[65,17,10,3],
[65,17,10,4],
[65,17,10,5],
[65,17,10,6],
[65,17,10,7],
[65,17,10,8],
[65,17,10,9],
[65,17,11,0],
[65,17,11,1],
[65,17,11,2],
[65,17,11,3],
[65,17,11,4],
[65,17,11,5],
[65,17,11,6],
[65,17,11,7],
[65,17,11,8],
[65,17,11,9],
[65,17,11,10],
[65,17,12,0],
[65,17,12,1],
[65,17,12,2],
[65,17,12,3],
[65,17,12,4],
[65,17,12,5],
[65,17,12,6],
[65,17,12,7],
[65,17,12,8],
[65,17,12,9],
[65,17,12,10],
[65,17,13,0],
[65,17,13,1],
[65,17,13,2],
[65,17,13,3],
[65,17,13,4],
[65,17,13,5],
[65,17,13,6],
[65,17,13,7],
[65,17,13,8],
[65,17,13,9],
[65,17,13,10],
[65,17,13,11],
[65,17,13,12],
[65,17,14,0],
[65,17,14,1],
[65,17,14,2],
[65,17,14,3],
[65,17,14,4],
[65,17,14,5],
[65,17,14,6],
[65,17,14,7],
[65,17,14,8],
[65,17,14,9],
[65,17,14,10],
[65,17,14,11],
[65,17,14,12],
[65,17,15,0],
[65,17,15,1],
[65,17,15,2],
[65,17,15,3],
[65,17,15,4],
[65,17,15,5],
[65,17,15,6],
[65,17,15,7],
[65,17,15,8],
[65,17,15,10],
[65,17,15,11],
[65,17,15,12],
[65,17,15,13],
[65,17,15,14],
[65,17,16,0],
[65,17,16,1],
[65,17,16,2],
[65,17,16,3],
[65,17,16,4],
[65,17,16,5],
[65,17,16,6],
[65,17,16,7],
[65,17,16,8],
[65,17,16,9],
[65,17,16,10],
[65,17,16,11],
[65,17,16,12],
[65,17,16,13],
[65,17,16,14],
[65,17,16,15],
[65,18,3,0],
[65,18,3,1],
[65,18,3,2],
[65,18,4,0],
[65,18,4,1],
[65,18,4,2],
[65,18,4,3],
[65,18,5,0],
[65,18,5,1],
[65,18,5,2],
[65,18,5,4],
[65,18,6,0],
[65,18,6,1],
[65,18,6,2],
[65,18,6,4],
[65,18,7,0],
[65,18,7,1],
[65,18,7,2],
[65,18,7,3],
[65,18,7,4],
[65,18,7,5],
[65,18,7,6],
[65,18,8,0],
[65,18,8,1],
[65,18,8,2],
[65,18,8,4],
[65,18,8,7],
[65,18,10,0],
[65,18,10,1],
[65,18,10,2],
[65,18,10,3],
[65,18,10,4],
[65,18,10,5],
[65,18,10,6],
[65,18,10,7],
[65,18,10,8],
[65,18,11,0],
[65,18,11,1],
[65,18,11,2],
[65,18,11,3],
[65,18,11,4],
[65,18,11,5],
[65,18,11,6],
[65,18,11,7],
[65,18,11,8],
[65,18,11,10],
[65,18,12,0],
[65,18,12,1],
[65,18,12,2],
[65,18,12,3],
[65,18,12,4],
[65,18,12,5],
[65,18,12,6],
[65,18,12,7],
[65,18,12,8],
[65,18,12,10],
[65,18,13,0],
[65,18,13,1],
[65,18,13,2],
[65,18,13,3],
[65,18,13,4],
[65,18,13,5],
[65,18,13,6],
[65,18,13,7],
[65,18,13,8],
[65,18,13,10],
[65,18,13,11],
[65,18,13,12],
[65,18,14,0],
[65,18,14,1],
[65,18,14,2],
[65,18,14,3],
[65,18,14,4],
[65,18,14,5],
[65,18,14,6],
[65,18,14,7],
[65,18,14,8],
[65,18,14,10],
[65,18,14,11],
[65,18,14,12],
[65,18,16,0],
[65,18,16,1],
[65,18,16,2],
[65,18,16,3],
[65,18,16,4],
[65,18,16,5],
[65,18,16,6],
[65,18,16,7],
[65,18,16,8],
[65,18,16,10],
[65,18,16,11],
[65,18,16,12],
[65,18,16,13],
[65,18,16,14],
[65,18,17,0],
[65,18,17,1],
[65,18,17,2],
[65,18,17,3],
[65,18,17,4],
[65,18,17,5],
[65,18,17,6],
[65,18,17,7],
[65,18,17,8],
[65,18,17,10],
[65,18,17,11],
[65,18,17,12],
[65,18,17,13],
[65,18,17,14],
[65,18,17,16],
[65,19,3,0],
[65,19,3,1],
[65,19,3,2],
[65,19,4,0],
[65,19,4,1],
[65,19,4,2],
[65,19,4,3],
[65,19,5,0],
[65,19,5,1],
[65,19,5,2],
[65,19,5,4],
[65,19,6,0],
[65,19,6,1],
[65,19,6,2],
[65,19,6,4],
[65,19,7,0],
[65,19,7,1],
[65,19,7,2],
[65,19,7,3],
[65,19,7,4],
[65,19,7,5],
[65,19,7,6],
[65,19,8,0],
[65,19,8,1],
[65,19,8,2],
[65,19,8,4],
[65,19,8,7],
[65,19,9,0],
[65,19,9,1],
[65,19,9,2],
[65,19,9,3],
[65,19,9,4],
[65,19,9,5],
[65,19,9,6],
[65,19,9,7],
[65,19,9,8],
[65,19,10,0],
[65,19,10,1],
[65,19,10,2],
[65,19,10,3],
[65,19,10,4],
[65,19,10,5],
[65,19,10,6],
[65,19,10,7],
[65,19,10,8],
[65,19,10,9],
[65,19,11,0],
[65,19,11,1],
[65,19,11,2],
[65,19,11,3],
[65,19,11,4],
[65,19,11,5],
[65,19,11,6],
[65,19,11,7],
[65,19,11,8],
[65,19,11,9],
[65,19,11,10],
[65,19,12,0],
[65,19,12,1],
[65,19,12,2],
[65,19,12,3],
[65,19,12,4],
[65,19,12,5],
[65,19,12,6],
[65,19,12,7],
[65,19,12,8],
[65,19,12,9],
[65,19,12,10],
[65,19,15,0],
[65,19,15,1],
[65,19,15,2],
[65,19,15,3],
[65,19,15,4],
[65,19,15,5],
[65,19,15,6],
[65,19,15,7],
[65,19,15,8],
[65,19,15,10],
[65,19,15,11],
[65,19,15,12],
[65,19,16,0],
[65,19,16,1],
[65,19,16,2],
[65,19,16,3],
[65,19,16,4],
[65,19,16,5],
[65,19,16,6],
[65,19,16,7],
[65,19,16,8],
[65,19,16,9],
[65,19,16,10],
[65,19,16,11],
[65,19,16,12],
[65,19,16,15],
[65,19,17,0],
[65,19,17,1],
[65,19,17,2],
[65,19,17,3],
[65,19,17,4],
[65,19,17,5],
[65,19,17,6],
[65,19,17,7],
[65,19,17,8],
[65,19,17,9],
[65,19,17,10],
[65,19,17,11],
[65,19,17,12],
[65,19,17,15],
[65,19,17,16],
[65,19,18,0],
[65,19,18,1],
[65,19,18,2],
[65,19,18,3],
[65,19,18,4],
[65,19,18,5],
[65,19,18,6],
[65,19,18,7],
[65,19,18,8],
[65,19,18,10],
[65,19,18,11],
[65,19,18,12],
[65,19,18,16],
[65,19,18,17],
[65,20,3,0],
[65,20,3,1],
[65,20,3,2],
[65,20,4,0],
[65,20,4,1],
[65,20,4,2],
[65,20,4,3],
[65,20,5,0],
[65,20,5,1],
[65,20,5,2],
[65,20,5,4],
[65,20,6,0],
[65,20,6,1],
[65,20,6,2],
[65,20,6,4],
[65,20,7,0],
[65,20,7,1],
[65,20,7,2],
[65,20,7,3],
[65,20,7,4],
[65,20,7,5],
[65,20,7,6],
[65,20,8,0],
[65,20,8,1],
[65,20,8,2],
[65,20,8,4],
[65,20,8,7],
[65,20,9,0],
[65,20,9,1],
[65,20,9,2],
[65,20,9,3],
[65,20,9,4],
[65,20,9,5],
[65,20,9,6],
[65,20,9,7],
[65,20,9,8],
[65,20,11,0],
[65,20,11,1],
[65,20,11,2],
[65,20,11,3],
[65,20,11,4],
[65,20,11,5],
[65,20,11,6],
[65,20,11,7],
[65,20,11,8],
[65,20,11,9],
[65,20,12,0],
[65,20,12,1],
[65,20,12,2],
[65,20,12,3],
[65,20,12,4],
[65,20,12,5],
[65,20,12,6],
[65,20,12,7],
[65,20,12,8],
[65,20,12,9],
[65,20,13,0],
[65,20,13,1],
[65,20,13,2],
[65,20,13,3],
[65,20,13,4],
[65,20,13,5],
[65,20,13,6],
[65,20,13,7],
[65,20,13,8],
[65,20,13,9],
[65,20,13,11],
[65,20,13,12],
[65,20,14,0],
[65,20,14,1],
[65,20,14,2],
[65,20,14,3],
[65,20,14,4],
[65,20,14,5],
[65,20,14,6],
[65,20,14,7],
[65,20,14,8],
[65,20,14,9],
[65,20,14,11],
[65,20,14,12],
[65,20,15,0],
[65,20,15,1],
[65,20,15,2],
[65,20,15,3],
[65,20,15,4],
[65,20,15,5],
[65,20,15,6],
[65,20,15,7],
[65,20,15,8],
[65,20,15,11],
[65,20,15,12],
[65,20,15,13],
[65,20,15,14],
[65,20,16,0],
[65,20,16,1],
[65,20,16,2],
[65,20,16,3],
[65,20,16,4],
[65,20,16,5],
[65,20,16,6],
[65,20,16,7],
[65,20,16,8],
[65,20,16,9],
[65,20,16,11],
[65,20,16,12],
[65,20,16,13],
[65,20,16,14],
[65,20,16,15],
[65,20,17,0],
[65,20,17,1],
[65,20,17,2],
[65,20,17,3],
[65,20,17,4],
[65,20,17,5],
[65,20,17,6],
[65,20,17,7],
[65,20,17,8],
[65,20,17,9],
[65,20,17,11],
[65,20,17,12],
[65,20,17,13],
[65,20,17,14],
[65,20,17,15],
[65,20,17,16],
[65,20,18,0],
[65,20,18,1],
[65,20,18,2],
[65,20,18,3],
[65,20,18,4],
[65,20,18,5],
[65,20,18,6],
[65,20,18,7],
[65,20,18,8],
[65,20,18,11],
[65,20,18,12],
[65,20,18,13],
[65,20,18,14],
[65,20,18,16],
[65,20,18,17],
[65,20,19,0],
[65,20,19,1],
[65,20,19,2],
[65,20,19,3],
[65,20,19,4],
[65,20,19,5],
[65,20,19,6],
[65,20,19,7],
[65,20,19,8],
[65,20,19,9],
[65,20,19,11],
[65,20,19,12],
[65,20,19,15],
[65,20,19,16],
[65,20,19,17],
[65,20,19,18],
[65,21,4,0],
[65,21,4,1],
[65,21,4,2],
[65,21,7,0],
[65,21,7,1],
[65,21,7,2],
[65,21,7,4],
[65,21,9,0],
[65,21,9,1],
[65,21,9,2],
[65,21,9,4],
[65,21,9,7],
[65,21,10,0],
[65,21,10,1],
[65,21,10,2],
[65,21,10,4],
[65,21,10,7],
[65,21,10,9],
[65,21,11,0],
[65,21,11,1],
[65,21,11,2],
[65,21,11,4],
[65,21,11,7],
[65,21,11,9],
[65,21,11,10],
[65,21,12,0],
[65,21,12,1],
[65,21,12,2],
[65,21,12,4],
[65,21,12,7],
[65,21,12,9],
[65,21,12,10],
[65,21,13,0],
[65,21,13,1],
[65,21,13,2],
[65,21,13,4],
[65,21,13,7],
[65,21,13,9],
[65,21,13,10],
[65,21,13,11],
[65,21,13,12],
[65,21,14,0],
[65,21,14,1],
[65,21,14,2],
[65,21,14,4],
[65,21,14,7],
[65,21,14,9],
[65,21,14,10],
[65,21,14,11],
[65,21,14,12],
[65,21,15,0],
[65,21,15,1],
[65,21,15,2],
[65,21,15,4],
[65,21,15,7],
[65,21,15,10],
[65,21,15,11],
[65,21,15,12],
[65,21,15,13],
[65,21,15,14],
[65,21,16,0],
[65,21,16,1],
[65,21,16,2],
[65,21,16,4],
[65,21,16,7],
[65,21,16,9],
[65,21,16,10],
[65,21,16,11],
[65,21,16,12],
[65,21,16,13],
[65,21,16,14],
[65,21,16,15],
[65,21,17,0],
[65,21,17,1],
[65,21,17,2],
[65,21,17,4],
[65,21,17,7],
[65,21,17,9],
[65,21,17,10],
[65,21,17,11],
[65,21,17,12],
[65,21,17,13],
[65,21,17,14],
[65,21,17,15],
[65,21,17,16],
[65,21,18,0],
[65,21,18,1],
[65,21,18,2],
[65,21,18,4],
[65,21,18,7],
[65,21,18,10],
[65,21,18,11],
[65,21,18,12],
[65,21,18,13],
[65,21,18,14],
[65,21,18,16],
[65,21,18,17],
[65,21,19,0],
[65,21,19,1],
[65,21,19,2],
[65,21,19,4],
[65,21,19,7],
[65,21,19,9],
[65,21,19,10],
[65,21,19,11],
[65,21,19,12],
[65,21,19,15],
[65,21,19,16],
[65,21,19,17],
[65,21,19,18],
[65,21,20,0],
[65,21,20,1],
[65,21,20,2],
[65,21,20,4],
[65,21,20,7],
[65,21,20,9],
[65,21,20,11],
[65,21,20,12],
[65,21,20,13],
[65,21,20,14],
[65,21,20,15],
[65,21,20,16],
[65,21,20,17],
[65,21,20,18],
[65,21,20,19],
[65,22,3,0],
[65,22,3,1],
[65,22,3,2],
[65,22,4,0],
[65,22,4,1],
[65,22,4,2],
[65,22,4,3],
[65,22,5,0],
[65,22,5,1],
[65,22,5,2],
[65,22,5,4],
[65,22,6,0],
[65,22,6,1],
[65,22,6,2],
[65,22,6,4],
[65,22,7,0],
[65,22,7,1],
[65,22,7,2],
[65,22,7,3],
[65,22,7,4],
[65,22,7,5],
[65,22,7,6],
[65,22,8,0],
[65,22,8,1],
[65,22,8,2],
[65,22,8,4],
[65,22,8,7],
[65,22,9,0],
[65,22,9,1],
[65,22,9,2],
[65,22,9,3],
[65,22,9,4],
[65,22,9,5],
[65,22,9,6],
[65,22,9,7],
[65,22,9,8],
[65,22,11,0],
[65,22,11,1],
[65,22,11,2],
[65,22,11,3],
[65,22,11,4],
[65,22,11,5],
[65,22,11,6],
[65,22,11,7],
[65,22,11,8],
[65,22,11,9],
[65,22,12,0],
[65,22,12,1],
[65,22,12,2],
[65,22,12,3],
[65,22,12,4],
[65,22,12,5],
[65,22,12,6],
[65,22,12,7],
[65,22,12,8],
[65,22,12,9],
[65,22,13,0],
[65,22,13,1],
[65,22,13,2],
[65,22,13,3],
[65,22,13,4],
[65,22,13,5],
[65,22,13,6],
[65,22,13,7],
[65,22,13,8],
[65,22,13,9],
[65,22,13,11],
[65,22,13,12],
[65,22,14,0],
[65,22,14,1],
[65,22,14,2],
[65,22,14,3],
[65,22,14,4],
[65,22,14,5],
[65,22,14,6],
[65,22,14,7],
[65,22,14,8],
[65,22,14,9],
[65,22,14,11],
[65,22,14,12],
[65,22,15,0],
[65,22,15,1],
[65,22,15,2],
[65,22,15,3],
[65,22,15,4],
[65,22,15,5],
[65,22,15,6],
[65,22,15,7],
[65,22,15,8],
[65,22,15,11],
[65,22,15,12],
[65,22,15,13],
[65,22,15,14],
[65,22,16,0],
[65,22,16,1],
[65,22,16,2],
[65,22,16,3],
[65,22,16,4],
[65,22,16,5],
[65,22,16,6],
[65,22,16,7],
[65,22,16,8],
[65,22,16,9],
[65,22,16,11],
[65,22,16,12],
[65,22,16,13],
[65,22,16,14],
[65,22,16,15],
[65,22,17,0],
[65,22,17,1],
[65,22,17,2],
[65,22,17,3],
[65,22,17,4],
[65,22,17,5],
[65,22,17,6],
[65,22,17,7],
[65,22,17,8],
[65,22,17,9],
[65,22,17,11],
[65,22,17,12],
[65,22,17,13],
[65,22,17,14],
[65,22,17,15],
[65,22,17,16],
[65,22,18,0],
[65,22,18,1],
[65,22,18,2],
[65,22,18,3],
[65,22,18,4],
[65,22,18,5],
[65,22,18,6],
[65,22,18,7],
[65,22,18,8],
[65,22,18,11],
[65,22,18,12],
[65,22,18,13],
[65,22,18,14],
[65,22,18,16],
[65,22,18,17],
[65,22,19,0],
[65,22,19,1],
[65,22,19,2],
[65,22,19,3],
[65,22,19,4],
[65,22,19,5],
[65,22,19,6],
[65,22,19,7],
[65,22,19,8],
[65,22,19,9],
[65,22,19,11],
[65,22,19,12],
[65,22,19,15],
[65,22,19,16],
[65,22,19,17],
[65,22,19,18],
[65,22,21,0],
[65,22,21,1],
[65,22,21,2],
[65,22,21,4],
[65,22,21,7],
[65,22,21,9],
[65,22,21,11],
[65,22,21,12],
[65,22,21,13],
[65,22,21,14],
[65,22,21,15],
[65,22,21,16],
[65,22,21,17],
[65,22,21,18],
[65,22,21,19],
[65,24,3,0],
[65,24,3,1],
[65,24,3,2],
[65,24,4,0],
[65,24,4,1],
[65,24,4,2],
[65,24,4,3],
[65,24,5,0],
[65,24,5,1],
[65,24,5,2],
[65,24,5,4],
[65,24,6,0],
[65,24,6,1],
[65,24,6,2],
[65,24,6,4],
[65,24,7,0],
[65,24,7,1],
[65,24,7,2],
[65,24,7,3],
[65,24,7,4],
[65,24,7,5],
[65,24,7,6],
[65,24,8,0],
[65,24,8,1],
[65,24,8,2],
[65,24,8,4],
[65,24,8,7],
[65,24,9,0],
[65,24,9,1],
[65,24,9,2],
[65,24,9,3],
[65,24,9,4],
[65,24,9,5],
[65,24,9,6],
[65,24,9,7],
[65,24,9,8],
[65,24,10,0],
[65,24,10,1],
[65,24,10,2],
[65,24,10,3],
[65,24,10,4],
[65,24,10,5],
[65,24,10,6],
[65,24,10,7],
[65,24,10,8],
[65,24,10,9],
[65,24,11,0],
[65,24,11,1],
[65,24,11,2],
[65,24,11,3],
[65,24,11,4],
[65,24,11,5],
[65,24,11,6],
[65,24,11,7],
[65,24,11,8],
[65,24,11,9],
[65,24,11,10],
[65,24,12,0],
[65,24,12,1],
[65,24,12,2],
[65,24,12,3],
[65,24,12,4],
[65,24,12,5],
[65,24,12,6],
[65,24,12,7],
[65,24,12,8],
[65,24,12,9],
[65,24,12,10],
[65,24,13,0],
[65,24,13,1],
[65,24,13,2],
[65,24,13,3],
[65,24,13,4],
[65,24,13,5],
[65,24,13,6],
[65,24,13,7],
[65,24,13,8],
[65,24,13,9],
[65,24,13,10],
[65,24,13,11],
[65,24,13,12],
[65,24,14,0],
[65,24,14,1],
[65,24,14,2],
[65,24,14,3],
[65,24,14,4],
[65,24,14,5],
[65,24,14,6],
[65,24,14,7],
[65,24,14,8],
[65,24,14,9],
[65,24,14,10],
[65,24,14,11],
[65,24,14,12],
[65,24,15,0],
[65,24,15,1],
[65,24,15,2],
[65,24,15,3],
[65,24,15,4],
[65,24,15,5],
[65,24,15,6],
[65,24,15,7],
[65,24,15,8],
[65,24,15,10],
[65,24,15,11],
[65,24,15,12],
[65,24,15,13],
[65,24,15,14],
[65,24,17,0],
[65,24,17,1],
[65,24,17,2],
[65,24,17,3],
[65,24,17,4],
[65,24,17,5],
[65,24,17,6],
[65,24,17,7],
[65,24,17,8],
[65,24,17,9],
[65,24,17,10],
[65,24,17,11],
[65,24,17,12],
[65,24,17,13],
[65,24,17,14],
[65,24,17,15],
[65,24,18,0],
[65,24,18,1],
[65,24,18,2],
[65,24,18,3],
[65,24,18,4],
[65,24,18,5],
[65,24,18,6],
[65,24,18,7],
[65,24,18,8],
[65,24,18,10],
[65,24,18,11],
[65,24,18,12],
[65,24,18,13],
[65,24,18,14],
[65,24,18,17],
[65,24,19,0],
[65,24,19,1],
[65,24,19,2],
[65,24,19,3],
[65,24,19,4],
[65,24,19,5],
[65,24,19,6],
[65,24,19,7],
[65,24,19,8],
[65,24,19,9],
[65,24,19,10],
[65,24,19,11],
[65,24,19,12],
[65,24,19,15],
[65,24,19,17],
[65,24,19,18],
[65,24,20,0],
[65,24,20,1],
[65,24,20,2],
[65,24,20,3],
[65,24,20,4],
[65,24,20,5],
[65,24,20,6],
[65,24,20,7],
[65,24,20,8],
[65,24,20,9],
[65,24,20,11],
[65,24,20,12],
[65,24,20,13],
[65,24,20,14],
[65,24,20,15],
[65,24,20,17],
[65,24,20,18],
[65,24,20,19],
[65,24,21,0],
[65,24,21,1],
[65,24,21,2],
[65,24,21,4],
[65,24,21,7],
[65,24,21,9],
[65,24,21,10],
[65,24,21,11],
[65,24,21,12],
[65,24,21,13],
[65,24,21,14],
[65,24,21,15],
[65,24,21,17],
[65,24,21,18],
[65,24,21,19],
[65,24,21,20],
[65,24,22,0],
[65,24,22,1],
[65,24,22,2],
[65,24,22,3],
[65,24,22,4],
[65,24,22,5],
[65,24,22,6],
[65,24,22,7],
[65,24,22,8],
[65,24,22,9],
[65,24,22,11],
[65,24,22,12],
[65,24,22,13],
[65,24,22,14],
[65,24,22,15],
[65,24,22,17],
[65,24,22,18],
[65,24,22,19],
[65,24,22,21],
[65,25,3,0],
[65,25,3,1],
[65,25,3,2],
[65,25,4,0],
[65,25,4,1],
[65,25,4,2],
[65,25,4,3],
[65,25,5,0],
[65,25,5,1],
[65,25,5,2],
[65,25,5,4],
[65,25,6,0],
[65,25,6,1],
[65,25,6,2],
[65,25,6,4],
[65,25,8,0],
[65,25,8,1],
[65,25,8,2],
[65,25,8,4],
[65,25,9,0],
[65,25,9,1],
[65,25,9,2],
[65,25,9,3],
[65,25,9,4],
[65,25,9,5],
[65,25,9,6],
[65,25,9,8],
[65,25,10,0],
[65,25,10,1],
[65,25,10,2],
[65,25,10,3],
[65,25,10,4],
[65,25,10,5],
[65,25,10,6],
[65,25,10,8],
[65,25,10,9],
[65,25,11,0],
[65,25,11,1],
[65,25,11,2],
[65,25,11,3],
[65,25,11,4],
[65,25,11,5],
[65,25,11,6],
[65,25,11,8],
[65,25,11,9],
[65,25,11,10],
[65,25,12,0],
[65,25,12,1],
[65,25,12,2],
[65,25,12,3],
[65,25,12,4],
[65,25,12,5],
[65,25,12,6],
[65,25,12,8],
[65,25,12,9],
[65,25,12,10],
[65,25,13,0],
[65,25,13,1],
[65,25,13,2],
[65,25,13,3],
[65,25,13,4],
[65,25,13,5],
[65,25,13,6],
[65,25,13,8],
[65,25,13,9],
[65,25,13,10],
[65,25,13,11],
[65,25,13,12],
[65,25,14,0],
[65,25,14,1],
[65,25,14,2],
[65,25,14,3],
[65,25,14,4],
[65,25,14,5],
[65,25,14,6],
[65,25,14,8],
[65,25,14,9],
[65,25,14,10],
[65,25,14,11],
[65,25,14,12],
[65,25,15,0],
[65,25,15,1],
[65,25,15,2],
[65,25,15,3],
[65,25,15,4],
[65,25,15,5],
[65,25,15,6],
[65,25,15,8],
[65,25,15,10],
[65,25,15,11],
[65,25,15,12],
[65,25,15,13],
[65,25,15,14],
[65,25,16,0],
[65,25,16,1],
[65,25,16,2],
[65,25,16,3],
[65,25,16,4],
[65,25,16,5],
[65,25,16,6],
[65,25,16,8],
[65,25,16,9],
[65,25,16,10],
[65,25,16,11],
[65,25,16,12],
[65,25,16,13],
[65,25,16,14],
[65,25,16,15],
[65,25,17,0],
[65,25,17,1],
[65,25,17,2],
[65,25,17,3],
[65,25,17,4],
[65,25,17,5],
[65,25,17,6],
[65,25,17,8],
[65,25,17,9],
[65,25,17,10],
[65,25,17,11],
[65,25,17,12],
[65,25,17,13],
[65,25,17,14],
[65,25,17,15],
[65,25,17,16],
[65,25,18,0],
[65,25,18,1],
[65,25,18,2],
[65,25,18,3],
[65,25,18,4],
[65,25,18,5],
[65,25,18,6],
[65,25,18,8],
[65,25,18,10],
[65,25,18,11],
[65,25,18,12],
[65,25,18,13],
[65,25,18,14],
[65,25,18,16],
[65,25,18,17],
[65,25,19,0],
[65,25,19,1],
[65,25,19,2],
[65,25,19,3],
[65,25,19,4],
[65,25,19,5],
[65,25,19,6],
[65,25,19,8],
[65,25,19,9],
[65,25,19,10],
[65,25,19,11],
[65,25,19,12],
[65,25,19,15],
[65,25,19,16],
[65,25,19,17],
[65,25,19,18],
[65,25,20,0],
[65,25,20,1],
[65,25,20,2],
[65,25,20,3],
[65,25,20,4],
[65,25,20,5],
[65,25,20,6],
[65,25,20,8],
[65,25,20,9],
[65,25,20,11],
[65,25,20,12],
[65,25,20,13],
[65,25,20,14],
[65,25,20,15],
[65,25,20,16],
[65,25,20,17],
[65,25,20,18],
[65,25,20,19],
[65,25,21,0],
[65,25,21,1],
[65,25,21,2],
[65,25,21,4],
[65,25,21,9],
[65,25,21,10],
[65,25,21,11],
[65,25,21,12],
[65,25,21,13],
[65,25,21,14],
[65,25,21,15],
[65,25,21,16],
[65,25,21,17],
[65,25,21,18],
[65,25,21,19],
[65,25,21,20],
[65,25,22,0],
[65,25,22,1],
[65,25,22,2],
[65,25,22,3],
[65,25,22,4],
[65,25,22,5],
[65,25,22,6],
[65,25,22,8],
[65,25,22,9],
[65,25,22,11],
[65,25,22,12],
[65,25,22,13],
[65,25,22,14],
[65,25,22,15],
[65,25,22,16],
[65,25,22,17],
[65,25,22,18],
[65,25,22,19],
[65,25,22,21],
[65,25,24,0],
[65,25,24,1],
[65,25,24,2],
[65,25,24,3],
[65,25,24,4],
[65,25,24,5],
[65,25,24,6],
[65,25,24,8],
[65,25,24,9],
[65,25,24,10],
[65,25,24,11],
[65,25,24,12],
[65,25,24,13],
[65,25,24,14],
[65,25,24,15],
[65,25,24,17],
[65,25,24,18],
[65,25,24,19],
[65,25,24,20],
[65,25,24,21],
[65,25,24,22],
[65,26,3,0],
[65,26,3,1],
[65,26,3,2],
[65,26,4,0],
[65,26,4,1],
[65,26,4,2],
[65,26,4,3],
[65,26,5,0],
[65,26,5,1],
[65,26,5,2],
[65,26,5,4],
[65,26,6,0],
[65,26,6,1],
[65,26,6,2],
[65,26,6,4],
[65,26,7,0],
[65,26,7,1],
[65,26,7,2],
[65,26,7,3],
[65,26,7,4],
[65,26,7,5],
[65,26,7,6],
[65,26,8,0],
[65,26,8,1],
[65,26,8,2],
[65,26,8,4],
[65,26,8,7],
[65,26,10,0],
[65,26,10,1],
[65,26,10,2],
[65,26,10,3],
[65,26,10,4],
[65,26,10,5],
[65,26,10,6],
[65,26,10,7],
[65,26,10,8],
[65,26,11,0],
[65,26,11,1],
[65,26,11,2],
[65,26,11,3],
[65,26,11,4],
[65,26,11,5],
[65,26,11,6],
[65,26,11,7],
[65,26,11,8],
[65,26,11,10],
[65,26,12,0],
[65,26,12,1],
[65,26,12,2],
[65,26,12,3],
[65,26,12,4],
[65,26,12,5],
[65,26,12,6],
[65,26,12,7],
[65,26,12,8],
[65,26,12,10],
[65,26,13,0],
[65,26,13,1],
[65,26,13,2],
[65,26,13,3],
[65,26,13,4],
[65,26,13,5],
[65,26,13,6],
[65,26,13,7],
[65,26,13,8],
[65,26,13,10],
[65,26,13,11],
[65,26,13,12],
[65,26,14,0],
[65,26,14,1],
[65,26,14,2],
[65,26,14,3],
[65,26,14,4],
[65,26,14,5],
[65,26,14,6],
[65,26,14,7],
[65,26,14,8],
[65,26,14,10],
[65,26,14,11],
[65,26,14,12],
[65,26,16,0],
[65,26,16,1],
[65,26,16,2],
[65,26,16,3],
[65,26,16,4],
[65,26,16,5],
[65,26,16,6],
[65,26,16,7],
[65,26,16,8],
[65,26,16,10],
[65,26,16,11],
[65,26,16,12],
[65,26,16,13],
[65,26,16,14],
[65,26,17,0],
[65,26,17,1],
[65,26,17,2],
[65,26,17,3],
[65,26,17,4],
[65,26,17,5],
[65,26,17,6],
[65,26,17,7],
[65,26,17,8],
[65,26,17,10],
[65,26,17,11],
[65,26,17,12],
[65,26,17,13],
[65,26,17,14],
[65,26,17,16],
[65,26,19,0],
[65,26,19,1],
[65,26,19,2],
[65,26,19,3],
[65,26,19,4],
[65,26,19,5],
[65,26,19,6],
[65,26,19,7],
[65,26,19,8],
[65,26,19,10],
[65,26,19,11],
[65,26,19,12],
[65,26,19,16],
[65,26,19,17],
[65,26,20,0],
[65,26,20,1],
[65,26,20,2],
[65,26,20,3],
[65,26,20,4],
[65,26,20,5],
[65,26,20,6],
[65,26,20,7],
[65,26,20,8],
[65,26,20,11],
[65,26,20,12],
[65,26,20,13],
[65,26,20,14],
[65,26,20,16],
[65,26,20,17],
[65,26,20,19],
[65,26,21,0],
[65,26,21,1],
[65,26,21,2],
[65,26,21,4],
[65,26,21,7],
[65,26,21,10],
[65,26,21,11],
[65,26,21,12],
[65,26,21,13],
[65,26,21,14],
[65,26,21,16],
[65,26,21,17],
[65,26,21,19],
[65,26,21,20],
[65,26,22,0],
[65,26,22,1],
[65,26,22,2],
[65,26,22,3],
[65,26,22,4],
[65,26,22,5],
[65,26,22,6],
[65,26,22,7],
[65,26,22,8],
[65,26,22,11],
[65,26,22,12],
[65,26,22,13],
[65,26,22,14],
[65,26,22,16],
[65,26,22,17],
[65,26,22,19],
[65,26,22,21],
[65,26,24,0],
[65,26,24,1],
[65,26,24,2],
[65,26,24,3],
[65,26,24,4],
[65,26,24,5],
[65,26,24,6],
[65,26,24,7],
[65,26,24,8],
[65,26,24,10],
[65,26,24,11],
[65,26,24,12],
[65,26,24,13],
[65,26,24,14],
[65,26,24,17],
[65,26,24,19],
[65,26,24,20],
[65,26,24,21],
[65,26,24,22],
[65,26,25,0],
[65,26,25,1],
[65,26,25,2],
[65,26,25,3],
[65,26,25,4],
[65,26,25,5],
[65,26,25,6],
[65,26,25,8],
[65,26,25,10],
[65,26,25,11],
[65,26,25,12],
[65,26,25,13],
[65,26,25,14],
[65,26,25,16],
[65,26,25,17],
[65,26,25,19],
[65,26,25,20],
[65,26,25,21],
[65,26,25,24],
[65,27,3,0],
[65,27,3,1],
[65,27,3,2],
[65,27,4,0],
[65,27,4,1],
[65,27,4,2],
[65,27,4,3],
[65,27,5,0],
[65,27,5,1],
[65,27,5,2],
[65,27,5,4],
[65,27,6,0],
[65,27,6,1],
[65,27,6,2],
[65,27,6,4],
[65,27,7,0],
[65,27,7,1],
[65,27,7,2],
[65,27,7,3],
[65,27,7,4],
[65,27,7,5],
[65,27,7,6],
[65,27,8,0],
[65,27,8,1],
[65,27,8,2],
[65,27,8,4],
[65,27,8,7],
[65,27,9,0],
[65,27,9,1],
[65,27,9,2],
[65,27,9,3],
[65,27,9,4],
[65,27,9,5],
[65,27,9,6],
[65,27,9,7],
[65,27,9,8],
[65,27,11,0],
[65,27,11,1],
[65,27,11,2],
[65,27,11,3],
[65,27,11,4],
[65,27,11,5],
[65,27,11,6],
[65,27,11,7],
[65,27,11,8],
[65,27,11,9],
[65,27,12,0],
[65,27,12,1],
[65,27,12,2],
[65,27,12,3],
[65,27,12,4],
[65,27,12,5],
[65,27,12,6],
[65,27,12,7],
[65,27,12,8],
[65,27,12,9],
[65,27,13,0],
[65,27,13,1],
[65,27,13,2],
[65,27,13,3],
[65,27,13,4],
[65,27,13,5],
[65,27,13,6],
[65,27,13,7],
[65,27,13,8],
[65,27,13,9],
[65,27,13,11],
[65,27,13,12],
[65,27,14,0],
[65,27,14,1],
[65,27,14,2],
[65,27,14,3],
[65,27,14,4],
[65,27,14,5],
[65,27,14,6],
[65,27,14,7],
[65,27,14,8],
[65,27,14,9],
[65,27,14,11],
[65,27,14,12],
[65,27,15,0],
[65,27,15,1],
[65,27,15,2],
[65,27,15,3],
[65,27,15,4],
[65,27,15,5],
[65,27,15,6],
[65,27,15,7],
[65,27,15,8],
[65,27,15,11],
[65,27,15,12],
[65,27,15,13],
[65,27,15,14],
[65,27,16,0],
[65,27,16,1],
[65,27,16,2],
[65,27,16,3],
[65,27,16,4],
[65,27,16,5],
[65,27,16,6],
[65,27,16,7],
[65,27,16,8],
[65,27,16,9],
[65,27,16,11],
[65,27,16,12],
[65,27,16,13],
[65,27,16,14],
[65,27,16,15],
[65,27,17,0],
[65,27,17,1],
[65,27,17,2],
[65,27,17,3],
[65,27,17,4],
[65,27,17,5],
[65,27,17,6],
[65,27,17,7],
[65,27,17,8],
[65,27,17,9],
[65,27,17,11],
[65,27,17,12],
[65,27,17,13],
[65,27,17,14],
[65,27,17,15],
[65,27,17,16],
[65,27,18,0],
[65,27,18,1],
[65,27,18,2],
[65,27,18,3],
[65,27,18,4],
[65,27,18,5],
[65,27,18,6],
[65,27,18,7],
[65,27,18,8],
[65,27,18,11],
[65,27,18,12],
[65,27,18,13],
[65,27,18,14],
[65,27,18,16],
[65,27,18,17],
[65,27,19,0],
[65,27,19,1],
[65,27,19,2],
[65,27,19,3],
[65,27,19,4],
[65,27,19,5],
[65,27,19,6],
[65,27,19,7],
[65,27,19,8],
[65,27,19,9],
[65,27,19,11],
[65,27,19,12],
[65,27,19,15],
[65,27,19,16],
[65,27,19,17],
[65,27,19,18],
[65,27,21,0],
[65,27,21,1],
[65,27,21,2],
[65,27,21,4],
[65,27,21,7],
[65,27,21,9],
[65,27,21,11],
[65,27,21,12],
[65,27,21,13],
[65,27,21,14],
[65,27,21,15],
[65,27,21,16],
[65,27,21,17],
[65,27,21,18],
[65,27,21,19],
[65,27,24,0],
[65,27,24,1],
[65,27,24,2],
[65,27,24,3],
[65,27,24,4],
[65,27,24,5],
[65,27,24,6],
[65,27,24,7],
[65,27,24,8],
[65,27,24,9],
[65,27,24,11],
[65,27,24,12],
[65,27,24,13],
[65,27,24,14],
[65,27,24,15],
[65,27,24,17],
[65,27,24,18],
[65,27,24,19],
[65,27,24,21],
[65,27,25,0],
[65,27,25,1],
[65,27,25,2],
[65,27,25,3],
[65,27,25,4],
[65,27,25,5],
[65,27,25,6],
[65,27,25,8],
[65,27,25,9],
[65,27,25,11],
[65,27,25,12],
[65,27,25,13],
[65,27,25,14],
[65,27,25,15],
[65,27,25,16],
[65,27,25,17],
[65,27,25,18],
[65,27,25,19],
[65,27,25,24],
[65,27,26,0],
[65,27,26,1],
[65,27,26,2],
[65,27,26,3],
[65,27,26,4],
[65,27,26,5],
[65,27,26,6],
[65,27,26,7],
[65,27,26,8],
[65,27,26,11],
[65,27,26,12],
[65,27,26,13],
[65,27,26,14],
[65,27,26,16],
[65,27,26,17],
[65,27,26,19],
[65,27,26,21],
[65,27,26,24],
[65,27,26,25],
[65,28,3,0],
[65,28,3,1],
[65,28,3,2],
[65,28,4,0],
[65,28,4,1],
[65,28,4,2],
[65,28,4,3],
[65,28,5,0],
[65,28,5,1],
[65,28,5,2],
[65,28,5,4],
[65,28,6,0],
[65,28,6,1],
[65,28,6,2],
[65,28,6,4],
[65,28,8,0],
[65,28,8,1],
[65,28,8,2],
[65,28,8,4],
[65,28,9,0],
[65,28,9,1],
[65,28,9,2],
[65,28,9,3],
[65,28,9,4],
[65,28,9,5],
[65,28,9,6],
[65,28,9,8],
[65,28,10,0],
[65,28,10,1],
[65,28,10,2],
[65,28,10,3],
[65,28,10,4],
[65,28,10,5],
[65,28,10,6],
[65,28,10,8],
[65,28,10,9],
[65,28,11,0],
[65,28,11,1],
[65,28,11,2],
[65,28,11,3],
[65,28,11,4],
[65,28,11,5],
[65,28,11,6],
[65,28,11,8],
[65,28,11,9],
[65,28,11,10],
[65,28,12,0],
[65,28,12,1],
[65,28,12,2],
[65,28,12,3],
[65,28,12,4],
[65,28,12,5],
[65,28,12,6],
[65,28,12,8],
[65,28,12,9],
[65,28,12,10],
[65,28,13,0],
[65,28,13,1],
[65,28,13,2],
[65,28,13,3],
[65,28,13,4],
[65,28,13,5],
[65,28,13,6],
[65,28,13,8],
[65,28,13,9],
[65,28,13,10],
[65,28,13,11],
[65,28,13,12],
[65,28,14,0],
[65,28,14,1],
[65,28,14,2],
[65,28,14,3],
[65,28,14,4],
[65,28,14,5],
[65,28,14,6],
[65,28,14,8],
[65,28,14,9],
[65,28,14,10],
[65,28,14,11],
[65,28,14,12],
[65,28,15,0],
[65,28,15,1],
[65,28,15,2],
[65,28,15,3],
[65,28,15,4],
[65,28,15,5],
[65,28,15,6],
[65,28,15,8],
[65,28,15,10],
[65,28,15,11],
[65,28,15,12],
[65,28,15,13],
[65,28,15,14],
[65,28,16,0],
[65,28,16,1],
[65,28,16,2],
[65,28,16,3],
[65,28,16,4],
[65,28,16,5],
[65,28,16,6],
[65,28,16,8],
[65,28,16,9],
[65,28,16,10],
[65,28,16,11],
[65,28,16,12],
[65,28,16,13],
[65,28,16,14],
[65,28,16,15],
[65,28,17,0],
[65,28,17,1],
[65,28,17,2],
[65,28,17,3],
[65,28,17,4],
[65,28,17,5],
[65,28,17,6],
[65,28,17,8],
[65,28,17,9],
[65,28,17,10],
[65,28,17,11],
[65,28,17,12],
[65,28,17,13],
[65,28,17,14],
[65,28,17,15],
[65,28,17,16],
[65,28,18,0],
[65,28,18,1],
[65,28,18,2],
[65,28,18,3],
[65,28,18,4],
[65,28,18,5],
[65,28,18,6],
[65,28,18,8],
[65,28,18,10],
[65,28,18,11],
[65,28,18,12],
[65,28,18,13],
[65,28,18,14],
[65,28,18,16],
[65,28,18,17],
[65,28,19,0],
[65,28,19,1],
[65,28,19,2],
[65,28,19,3],
[65,28,19,4],
[65,28,19,5],
[65,28,19,6],
[65,28,19,8],
[65,28,19,9],
[65,28,19,10],
[65,28,19,11],
[65,28,19,12],
[65,28,19,15],
[65,28,19,16],
[65,28,19,17],
[65,28,19,18],
[65,28,20,0],
[65,28,20,1],
[65,28,20,2],
[65,28,20,3],
[65,28,20,4],
[65,28,20,5],
[65,28,20,6],
[65,28,20,8],
[65,28,20,9],
[65,28,20,11],
[65,28,20,12],
[65,28,20,13],
[65,28,20,14],
[65,28,20,15],
[65,28,20,16],
[65,28,20,17],
[65,28,20,18],
[65,28,20,19],
[65,28,21,0],
[65,28,21,1],
[65,28,21,2],
[65,28,21,4],
[65,28,21,9],
[65,28,21,10],
[65,28,21,11],
[65,28,21,12],
[65,28,21,13],
[65,28,21,14],
[65,28,21,15],
[65,28,21,16],
[65,28,21,17],
[65,28,21,18],
[65,28,21,19],
[65,28,21,20],
[65,28,22,0],
[65,28,22,1],
[65,28,22,2],
[65,28,22,3],
[65,28,22,4],
[65,28,22,5],
[65,28,22,6],
[65,28,22,8],
[65,28,22,9],
[65,28,22,11],
[65,28,22,12],
[65,28,22,13],
[65,28,22,14],
[65,28,22,15],
[65,28,22,16],
[65,28,22,17],
[65,28,22,18],
[65,28,22,19],
[65,28,22,21],
[65,28,24,0],
[65,28,24,1],
[65,28,24,2],
[65,28,24,3],
[65,28,24,4],
[65,28,24,5],
[65,28,24,6],
[65,28,24,8],
[65,28,24,9],
[65,28,24,10],
[65,28,24,11],
[65,28,24,12],
[65,28,24,13],
[65,28,24,14],
[65,28,24,15],
[65,28,24,17],
[65,28,24,18],
[65,28,24,19],
[65,28,24,20],
[65,28,24,21],
[65,28,26,0],
[65,28,26,1],
[65,28,26,2],
[65,28,26,3],
[65,28,26,4],
[65,28,26,5],
[65,28,26,6],
[65,28,26,8],
[65,28,26,10],
[65,28,26,11],
[65,28,26,12],
[65,28,26,13],
[65,28,26,14],
[65,28,26,16],
[65,28,26,17],
[65,28,26,20],
[65,28,26,21],
[65,28,26,22],
[65,28,26,24],
[65,28,27,0],
[65,28,27,1],
[65,28,27,2],
[65,28,27,3],
[65,28,27,4],
[65,28,27,5],
[65,28,27,6],
[65,28,27,8],
[65,28,27,9],
[65,28,27,11],
[65,28,27,12],
[65,28,27,13],
[65,28,27,14],
[65,28,27,15],
[65,28,27,16],
[65,28,27,17],
[65,28,27,19],
[65,28,27,21],
[65,28,27,24],
[65,28,27,26],
[65,29,3,0],
[65,29,3,1],
[65,29,3,2],
[65,29,4,0],
[65,29,4,1],
[65,29,4,2],
[65,29,4,3],
[65,29,5,0],
[65,29,5,1],
[65,29,5,2],
[65,29,5,4],
[65,29,6,0],
[65,29,6,1],
[65,29,6,2],
[65,29,6,4],
[65,29,7,0],
[65,29,7,1],
[65,29,7,2],
[65,29,7,3],
[65,29,7,4],
[65,29,7,5],
[65,29,7,6],
[65,29,8,0],
[65,29,8,1],
[65,29,8,2],
[65,29,8,4],
[65,29,8,7],
[65,29,9,0],
[65,29,9,1],
[65,29,9,2],
[65,29,9,3],
[65,29,9,4],
[65,29,9,5],
[65,29,9,6],
[65,29,9,7],
[65,29,9,8],
[65,29,10,0],
[65,29,10,1],
[65,29,10,2],
[65,29,10,3],
[65,29,10,4],
[65,29,10,5],
[65,29,10,6],
[65,29,10,7],
[65,29,10,8],
[65,29,10,9],
[65,29,13,0],
[65,29,13,1],
[65,29,13,2],
[65,29,13,3],
[65,29,13,4],
[65,29,13,5],
[65,29,13,6],
[65,29,13,7],
[65,29,13,8],
[65,29,13,9],
[65,29,13,10],
[65,29,14,0],
[65,29,14,1],
[65,29,14,2],
[65,29,14,3],
[65,29,14,4],
[65,29,14,5],
[65,29,14,6],
[65,29,14,7],
[65,29,14,8],
[65,29,14,9],
[65,29,14,10],
[65,29,15,0],
[65,29,15,1],
[65,29,15,2],
[65,29,15,3],
[65,29,15,4],
[65,29,15,5],
[65,29,15,6],
[65,29,15,7],
[65,29,15,8],
[65,29,15,10],
[65,29,15,13],
[65,29,15,14],
[65,29,16,0],
[65,29,16,1],
[65,29,16,2],
[65,29,16,3],
[65,29,16,4],
[65,29,16,5],
[65,29,16,6],
[65,29,16,7],
[65,29,16,8],
[65,29,16,9],
[65,29,16,10],
[65,29,16,13],
[65,29,16,14],
[65,29,16,15],
[65,29,17,0],
[65,29,17,1],
[65,29,17,2],
[65,29,17,3],
[65,29,17,4],
[65,29,17,5],
[65,29,17,6],
[65,29,17,7],
[65,29,17,8],
[65,29,17,9],
[65,29,17,10],
[65,29,17,13],
[65,29,17,14],
[65,29,17,15],
[65,29,17,16],
[65,29,18,0],
[65,29,18,1],
[65,29,18,2],
[65,29,18,3],
[65,29,18,4],
[65,29,18,5],
[65,29,18,6],
[65,29,18,7],
[65,29,18,8],
[65,29,18,10],
[65,29,18,13],
[65,29,18,14],
[65,29,18,16],
[65,29,18,17],
[65,29,19,0],
[65,29,19,1],
[65,29,19,2],
[65,29,19,3],
[65,29,19,4],
[65,29,19,5],
[65,29,19,6],
[65,29,19,7],
[65,29,19,8],
[65,29,19,9],
[65,29,19,10],
[65,29,19,15],
[65,29,19,16],
[65,29,19,17],
[65,29,19,18],
[65,29,20,0],
[65,29,20,1],
[65,29,20,2],
[65,29,20,3],
[65,29,20,4],
[65,29,20,5],
[65,29,20,6],
[65,29,20,7],
[65,29,20,8],
[65,29,20,9],
[65,29,20,13],
[65,29,20,14],
[65,29,20,15],
[65,29,20,16],
[65,29,20,17],
[65,29,20,18],
[65,29,20,19],
[65,29,21,0],
[65,29,21,1],
[65,29,21,2],
[65,29,21,4],
[65,29,21,7],
[65,29,21,9],
[65,29,21,10],
[65,29,21,13],
[65,29,21,14],
[65,29,21,15],
[65,29,21,16],
[65,29,21,17],
[65,29,21,18],
[65,29,21,19],
[65,29,21,20],
[65,29,22,0],
[65,29,22,1],
[65,29,22,2],
[65,29,22,3],
[65,29,22,4],
[65,29,22,5],
[65,29,22,6],
[65,29,22,7],
[65,29,22,8],
[65,29,22,9],
[65,29,22,13],
[65,29,22,14],
[65,29,22,15],
[65,29,22,16],
[65,29,22,17],
[65,29,22,18],
[65,29,22,19],
[65,29,22,21],
[65,29,24,0],
[65,29,24,1],
[65,29,24,2],
[65,29,24,3],
[65,29,24,4],
[65,29,24,5],
[65,29,24,6],
[65,29,24,7],
[65,29,24,8],
[65,29,24,9],
[65,29,24,10],
[65,29,24,13],
[65,29,24,14],
[65,29,24,15],
[65,29,24,17],
[65,29,24,18],
[65,29,24,19],
[65,29,24,20],
[65,29,24,22],
[65,29,25,0],
[65,29,25,1],
[65,29,25,2],
[65,29,25,3],
[65,29,25,4],
[65,29,25,5],
[65,29,25,6],
[65,29,25,8],
[65,29,25,9],
[65,29,25,10],
[65,29,25,13],
[65,29,25,14],
[65,29,25,15],
[65,29,25,16],
[65,29,25,17],
[65,29,25,18],
[65,29,25,20],
[65,29,25,21],
[65,29,25,22],
[65,29,25,24],
[65,29,26,0],
[65,29,26,1],
[65,29,26,2],
[65,29,26,3],
[65,29,26,4],
[65,29,26,5],
[65,29,26,6],
[65,29,26,7],
[65,29,26,8],
[65,29,26,10],
[65,29,26,13],
[65,29,26,14],
[65,29,26,16],
[65,29,26,17],
[65,29,26,19],
[65,29,26,20],
[65,29,26,21],
[65,29,26,22],
[65,29,26,24],
[65,29,26,25],
[65,29,27,0],
[65,29,27,1],
[65,29,27,2],
[65,29,27,3],
[65,29,27,4],
[65,29,27,5],
[65,29,27,6],
[65,29,27,7],
[65,29,27,8],
[65,29,27,9],
[65,29,27,13],
[65,29,27,14],
[65,29,27,15],
[65,29,27,18],
[65,29,27,19],
[65,29,27,21],
[65,29,27,24],
[65,29,27,25],
[65,29,27,26],
[65,29,28,0],
[65,29,28,1],
[65,29,28,2],
[65,29,28,3],
[65,29,28,4],
[65,29,28,5],
[65,29,28,6],
[65,29,28,8],
[65,29,28,9],
[65,29,28,10],
[65,29,28,13],
[65,29,28,14],
[65,29,28,16],
[65,29,28,17],
[65,29,28,18],
[65,29,28,19],
[65,29,28,20],
[65,29,28,21],
[65,29,28,22],
[65,29,28,24],
[65,29,28,26],
[65,29,28,27],
[65,32,3,0],
[65,32,3,1],
[65,32,3,2],
[65,32,4,0],
[65,32,4,1],
[65,32,4,2],
[65,32,4,3],
[65,32,5,0],
[65,32,5,1],
[65,32,5,2],
[65,32,5,4],
[65,32,6,0],
[65,32,6,1],
[65,32,6,2],
[65,32,6,4],
[65,32,7,0],
[65,32,7,1],
[65,32,7,2],
[65,32,7,3],
[65,32,7,4],
[65,32,7,5],
[65,32,7,6],
[65,32,8,0],
[65,32,8,1],
[65,32,8,2],
[65,32,8,4],
[65,32,8,7],
[65,32,9,0],
[65,32,9,1],
[65,32,9,2],
[65,32,9,3],
[65,32,9,4],
[65,32,9,5],
[65,32,9,6],
[65,32,9,7],
[65,32,9,8],
[65,32,10,0],
[65,32,10,1],
[65,32,10,2],
[65,32,10,3],
[65,32,10,4],
[65,32,10,5],
[65,32,10,6],
[65,32,10,7],
[65,32,10,8],
[65,32,10,9],
[65,32,13,0],
[65,32,13,1],
[65,32,13,2],
[65,32,13,3],
[65,32,13,4],
[65,32,13,5],
[65,32,13,6],
[65,32,13,7],
[65,32,13,8],
[65,32,13,9],
[65,32,13,10],
[65,32,14,0],
[65,32,14,1],
[65,32,14,2],
[65,32,14,3],
[65,32,14,4],
[65,32,14,5],
[65,32,14,6],
[65,32,14,7],
[65,32,14,8],
[65,32,14,9],
[65,32,14,10],
[65,32,15,0],
[65,32,15,1],
[65,32,15,2],
[65,32,15,3],
[65,32,15,4],
[65,32,15,5],
[65,32,15,6],
[65,32,15,7],
[65,32,15,8],
[65,32,15,10],
[65,32,15,13],
[65,32,15,14],
[65,32,16,0],
[65,32,16,1],
[65,32,16,2],
[65,32,16,3],
[65,32,16,4],
[65,32,16,5],
[65,32,16,6],
[65,32,16,7],
[65,32,16,8],
[65,32,16,9],
[65,32,16,10],
[65,32,16,13],
[65,32,16,14],
[65,32,16,15],
[65,32,17,0],
[65,32,17,1],
[65,32,17,2],
[65,32,17,3],
[65,32,17,4],
[65,32,17,5],
[65,32,17,6],
[65,32,17,7],
[65,32,17,8],
[65,32,17,9],
[65,32,17,10],
[65,32,17,13],
[65,32,17,14],
[65,32,17,15],
[65,32,17,16],
[65,32,18,0],
[65,32,18,1],
[65,32,18,2],
[65,32,18,3],
[65,32,18,4],
[65,32,18,5],
[65,32,18,6],
[65,32,18,7],
[65,32,18,8],
[65,32,18,10],
[65,32,18,13],
[65,32,18,14],
[65,32,18,16],
[65,32,18,17],
[65,32,19,0],
[65,32,19,1],
[65,32,19,2],
[65,32,19,3],
[65,32,19,4],
[65,32,19,5],
[65,32,19,6],
[65,32,19,7],
[65,32,19,8],
[65,32,19,9],
[65,32,19,10],
[65,32,19,15],
[65,32,19,16],
[65,32,19,17],
[65,32,19,18],
[65,32,20,0],
[65,32,20,1],
[65,32,20,2],
[65,32,20,3],
[65,32,20,4],
[65,32,20,5],
[65,32,20,6],
[65,32,20,7],
[65,32,20,8],
[65,32,20,9],
[65,32,20,13],
[65,32,20,14],
[65,32,20,15],
[65,32,20,16],
[65,32,20,17],
[65,32,20,18],
[65,32,20,19],
[65,32,21,0],
[65,32,21,1],
[65,32,21,2],
[65,32,21,4],
[65,32,21,7],
[65,32,21,9],
[65,32,21,10],
[65,32,21,13],
[65,32,21,14],
[65,32,21,15],
[65,32,21,16],
[65,32,21,17],
[65,32,21,18],
[65,32,21,19],
[65,32,22,0],
[65,32,22,1],
[65,32,22,2],
[65,32,22,3],
[65,32,22,4],
[65,32,22,5],
[65,32,22,6],
[65,32,22,7],
[65,32,22,8],
[65,32,22,9],
[65,32,22,13],
[65,32,22,14],
[65,32,22,15],
[65,32,22,16],
[65,32,22,17],
[65,32,22,18],
[65,32,22,21],
[65,32,24,0],
[65,32,24,1],
[65,32,24,2],
[65,32,24,3],
[65,32,24,4],
[65,32,24,5],
[65,32,24,6],
[65,32,24,7],
[65,32,24,8],
[65,32,24,9],
[65,32,24,10],
[65,32,24,13],
[65,32,24,14],
[65,32,24,15],
[65,32,24,17],
[65,32,24,19],
[65,32,24,20],
[65,32,24,21],
[65,32,24,22],
[65,32,25,0],
[65,32,25,1],
[65,32,25,2],
[65,32,25,3],
[65,32,25,4],
[65,32,25,5],
[65,32,25,6],
[65,32,25,8],
[65,32,25,9],
[65,32,25,10],
[65,32,25,13],
[65,32,25,14],
[65,32,25,16],
[65,32,25,17],
[65,32,25,18],
[65,32,25,19],
[65,32,25,20],
[65,32,25,21],
[65,32,25,22],
[65,32,25,24],
[65,32,26,0],
[65,32,26,1],
[65,32,26,2],
[65,32,26,3],
[65,32,26,4],
[65,32,26,5],
[65,32,26,6],
[65,32,26,7],
[65,32,26,8],
[65,32,26,10],
[65,32,26,13],
[65,32,26,16],
[65,32,26,17],
[65,32,26,19],
[65,32,26,20],
[65,32,26,21],
[65,32,26,22],
[65,32,26,24],
[65,32,26,25],
[65,32,27,0],
[65,32,27,1],
[65,32,27,2],
[65,32,27,3],
[65,32,27,4],
[65,32,27,5],
[65,32,27,6],
[65,32,27,7],
[65,32,27,8],
[65,32,27,9],
[65,32,27,14],
[65,32,27,15],
[65,32,27,16],
[65,32,27,17],
[65,32,27,18],
[65,32,27,19],
[65,32,27,21],
[65,32,27,24],
[65,32,27,25],
[65,32,27,26],
[65,32,28,0],
[65,32,28,1],
[65,32,28,2],
[65,32,28,3],
[65,32,28,4],
[65,32,28,5],
[65,32,28,6],
[65,32,28,8],
[65,32,28,9],
[65,32,28,13],
[65,32,28,14],
[65,32,28,15],
[65,32,28,16],
[65,32,28,17],
[65,32,28,18],
[65,32,28,19],
[65,32,28,20],
[65,32,28,21],
[65,32,28,22],
[65,32,28,24],
[65,33,3,0],
[65,33,3,1],
[65,33,3,2],
[65,33,4,0],
[65,33,4,1],
[65,33,4,2],
[65,33,4,3],
[65,33,5,0],
[65,33,5,1],
[65,33,5,2],
[65,33,5,4],
[65,33,6,0],
[65,33,6,1],
[65,33,6,2],
[65,33,6,4],
[65,33,7,0],
[65,33,7,1],
[65,33,7,2],
[65,33,7,3],
[65,33,7,4],
[65,33,7,5],
[65,33,7,6],
[65,33,8,0],
[65,33,8,1],
[65,33,8,2],
[65,33,8,4],
[65,33,8,7],
[65,33,9,0],
[65,33,9,1],
[65,33,9,2],
[65,33,9,3],
[65,33,9,4],
[65,33,9,5],
[65,33,9,6],
[65,33,9,7],
[65,33,9,8],
[65,33,10,0],
[65,33,10,1],
[65,33,10,2],
[65,33,10,3],
[65,33,10,4],
[65,33,10,5],
[65,33,10,6],
[65,33,10,7],
[65,33,10,8],
[65,33,10,9],
[65,33,13,0],
[65,33,13,1],
[65,33,13,2],
[65,33,13,3],
[65,33,13,4],
[65,33,13,5],
[65,33,13,6],
[65,33,13,7],
[65,33,13,8],
[65,33,13,9],
[65,33,13,10],
[65,33,14,0],
[65,33,14,1],
[65,33,14,2],
[65,33,14,3],
[65,33,14,4],
[65,33,14,5],
[65,33,14,6],
[65,33,14,7],
[65,33,14,8],
[65,33,14,9],
[65,33,14,10],
[65,33,15,0],
[65,33,15,1],
[65,33,15,2],
[65,33,15,3],
[65,33,15,4],
[65,33,15,5],
[65,33,15,6],
[65,33,15,7],
[65,33,15,8],
[65,33,15,10],
[65,33,15,13],
[65,33,15,14],
[65,33,16,0],
[65,33,16,1],
[65,33,16,2],
[65,33,16,3],
[65,33,16,4],
[65,33,16,5],
[65,33,16,6],
[65,33,16,7],
[65,33,16,8],
[65,33,16,9],
[65,33,16,10],
[65,33,16,13],
[65,33,16,14],
[65,33,16,15],
[65,33,17,0],
[65,33,17,1],
[65,33,17,2],
[65,33,17,3],
[65,33,17,4],
[65,33,17,5],
[65,33,17,6],
[65,33,17,7],
[65,33,17,8],
[65,33,17,9],
[65,33,17,10],
[65,33,17,13],
[65,33,17,14],
[65,33,17,15],
[65,33,17,16],
[65,33,18,0],
[65,33,18,1],
[65,33,18,2],
[65,33,18,3],
[65,33,18,4],
[65,33,18,5],
[65,33,18,6],
[65,33,18,7],
[65,33,18,8],
[65,33,18,10],
[65,33,18,13],
[65,33,18,14],
[65,33,18,16],
[65,33,18,17],
[65,33,19,0],
[65,33,19,1],
[65,33,19,2],
[65,33,19,3],
[65,33,19,4],
[65,33,19,5],
[65,33,19,6],
[65,33,19,7],
[65,33,19,8],
[65,33,19,9],
[65,33,19,10],
[65,33,19,15],
[65,33,19,16],
[65,33,19,17],
[65,33,19,18],
[65,33,20,0],
[65,33,20,1],
[65,33,20,2],
[65,33,20,3],
[65,33,20,4],
[65,33,20,5],
[65,33,20,6],
[65,33,20,7],
[65,33,20,8],
[65,33,20,9],
[65,33,20,13],
[65,33,20,14],
[65,33,20,15],
[65,33,20,16],
[65,33,20,17],
[65,33,20,18],
[65,33,20,19],
[65,33,21,0],
[65,33,21,1],
[65,33,21,2],
[65,33,21,4],
[65,33,21,7],
[65,33,21,9],
[65,33,21,10],
[65,33,21,13],
[65,33,21,14],
[65,33,21,15],
[65,33,21,16],
[65,33,21,17],
[65,33,21,18],
[65,33,21,20],
[65,33,22,0],
[65,33,22,1],
[65,33,22,2],
[65,33,22,3],
[65,33,22,4],
[65,33,22,5],
[65,33,22,6],
[65,33,22,7],
[65,33,22,8],
[65,33,22,9],
[65,33,22,13],
[65,33,22,14],
[65,33,22,15],
[65,33,22,16],
[65,33,22,17],
[65,33,22,19],
[65,33,22,21],
[65,33,24,0],
[65,33,24,1],
[65,33,24,2],
[65,33,24,3],
[65,33,24,4],
[65,33,24,5],
[65,33,24,6],
[65,33,24,7],
[65,33,24,8],
[65,33,24,9],
[65,33,24,10],
[65,33,24,13],
[65,33,24,14],
[65,33,24,15],
[65,33,24,18],
[65,33,24,19],
[65,33,24,20],
[65,33,24,21],
[65,33,24,22],
[65,33,25,0],
[65,33,25,1],
[65,33,25,2],
[65,33,25,3],
[65,33,25,4],
[65,33,25,5],
[65,33,25,6],
[65,33,25,8],
[65,33,25,9],
[65,33,25,10],
[65,33,25,13],
[65,33,25,15],
[65,33,25,16],
[65,33,25,17],
[65,33,25,18],
[65,33,25,19],
[65,33,25,20],
[65,33,25,21],
[65,33,25,22],
[65,33,25,24],
[65,33,26,0],
[65,33,26,1],
[65,33,26,2],
[65,33,26,3],
[65,33,26,4],
[65,33,26,5],
[65,33,26,6],
[65,33,26,7],
[65,33,26,8],
[65,33,26,10],
[65,33,26,14],
[65,33,26,16],
[65,33,26,17],
[65,33,26,19],
[65,33,26,20],
[65,33,26,21],
[65,33,26,22],
[65,33,26,24],
[65,33,26,25],
[65,33,27,0],
[65,33,27,1],
[65,33,27,2],
[65,33,27,3],
[65,33,27,4],
[65,33,27,5],
[65,33,27,6],
[65,33,27,7],
[65,33,27,8],
[65,33,27,9],
[65,33,27,13],
[65,33,27,14],
[65,33,27,15],
[65,33,27,16],
[65,33,27,17],
[65,33,27,18],
[65,33,27,19],
[65,33,27,21],
[65,33,27,24],
[65,33,27,25],
[65,33,28,0],
[65,33,28,1],
[65,33,28,2],
[65,33,28,3],
[65,33,28,4],
[65,33,28,5],
[65,33,28,6],
[65,33,28,10],
[65,33,28,13],
[65,33,28,14],
[65,33,28,15],
[65,33,28,16],
[65,33,28,17],
[65,33,28,18],
[65,33,28,19],
[65,33,28,20],
[65,33,28,21],
[65,33,28,22],
[65,33,28,24],
[65,34,3,0],
[65,34,3,1],
[65,34,3,2],
[65,34,4,0],
[65,34,4,1],
[65,34,4,2],
[65,34,4,3],
[65,34,5,0],
[65,34,5,1],
[65,34,5,2],
[65,34,5,4],
[65,34,6,0],
[65,34,6,1],
[65,34,6,2],
[65,34,6,4],
[65,34,8,0],
[65,34,8,1],
[65,34,8,2],
[65,34,8,4],
[65,34,9,0],
[65,34,9,1],
[65,34,9,2],
[65,34,9,3],
[65,34,9,4],
[65,34,9,5],
[65,34,9,6],
[65,34,9,8],
[65,34,10,0],
[65,34,10,1],
[65,34,10,2],
[65,34,10,3],
[65,34,10,4],
[65,34,10,5],
[65,34,10,6],
[65,34,10,8],
[65,34,10,9],
[65,34,11,0],
[65,34,11,1],
[65,34,11,2],
[65,34,11,3],
[65,34,11,4],
[65,34,11,5],
[65,34,11,6],
[65,34,11,8],
[65,34,11,9],
[65,34,11,10],
[65,34,12,0],
[65,34,12,1],
[65,34,12,2],
[65,34,12,3],
[65,34,12,4],
[65,34,12,5],
[65,34,12,6],
[65,34,12,8],
[65,34,12,9],
[65,34,12,10],
[65,34,13,0],
[65,34,13,1],
[65,34,13,2],
[65,34,13,3],
[65,34,13,4],
[65,34,13,5],
[65,34,13,6],
[65,34,13,8],
[65,34,13,9],
[65,34,13,10],
[65,34,13,11],
[65,34,13,12],
[65,34,14,0],
[65,34,14,1],
[65,34,14,2],
[65,34,14,3],
[65,34,14,4],
[65,34,14,5],
[65,34,14,6],
[65,34,14,8],
[65,34,14,9],
[65,34,14,10],
[65,34,14,11],
[65,34,14,12],
[65,34,15,0],
[65,34,15,1],
[65,34,15,2],
[65,34,15,3],
[65,34,15,4],
[65,34,15,5],
[65,34,15,6],
[65,34,15,8],
[65,34,15,10],
[65,34,15,11],
[65,34,15,12],
[65,34,15,13],
[65,34,15,14],
[65,34,16,0],
[65,34,16,1],
[65,34,16,2],
[65,34,16,3],
[65,34,16,4],
[65,34,16,5],
[65,34,16,6],
[65,34,16,8],
[65,34,16,9],
[65,34,16,10],
[65,34,16,11],
[65,34,16,12],
[65,34,16,13],
[65,34,16,14],
[65,34,16,15],
[65,34,17,0],
[65,34,17,1],
[65,34,17,2],
[65,34,17,3],
[65,34,17,4],
[65,34,17,5],
[65,34,17,6],
[65,34,17,8],
[65,34,17,9],
[65,34,17,10],
[65,34,17,11],
[65,34,17,12],
[65,34,17,13],
[65,34,17,14],
[65,34,17,15],
[65,34,17,16],
[65,34,18,0],
[65,34,18,1],
[65,34,18,2],
[65,34,18,3],
[65,34,18,4],
[65,34,18,5],
[65,34,18,6],
[65,34,18,8],
[65,34,18,10],
[65,34,18,11],
[65,34,18,12],
[65,34,18,13],
[65,34,18,14],
[65,34,18,16],
[65,34,18,17],
[65,34,19,0],
[65,34,19,1],
[65,34,19,2],
[65,34,19,3],
[65,34,19,4],
[65,34,19,5],
[65,34,19,6],
[65,34,19,8],
[65,34,19,9],
[65,34,19,10],
[65,34,19,11],
[65,34,19,12],
[65,34,19,15],
[65,34,19,16],
[65,34,19,17],
[65,34,19,18],
[65,34,20,0],
[65,34,20,1],
[65,34,20,2],
[65,34,20,3],
[65,34,20,4],
[65,34,20,5],
[65,34,20,6],
[65,34,20,8],
[65,34,20,9],
[65,34,20,11],
[65,34,20,12],
[65,34,20,13],
[65,34,20,14],
[65,34,20,15],
[65,34,20,16],
[65,34,20,17],
[65,34,20,18],
[65,34,21,0],
[65,34,21,1],
[65,34,21,2],
[65,34,21,4],
[65,34,21,9],
[65,34,21,10],
[65,34,21,11],
[65,34,21,12],
[65,34,21,13],
[65,34,21,14],
[65,34,21,15],
[65,34,21,16],
[65,34,21,17],
[65,34,21,19],
[65,34,21,20],
[65,34,22,0],
[65,34,22,1],
[65,34,22,2],
[65,34,22,3],
[65,34,22,4],
[65,34,22,5],
[65,34,22,6],
[65,34,22,8],
[65,34,22,9],
[65,34,22,11],
[65,34,22,12],
[65,34,22,13],
[65,34,22,14],
[65,34,22,15],
[65,34,22,18],
[65,34,22,19],
[65,34,22,21],
[65,34,24,0],
[65,34,24,1],
[65,34,24,2],
[65,34,24,3],
[65,34,24,4],
[65,34,24,5],
[65,34,24,6],
[65,34,24,8],
[65,34,24,9],
[65,34,24,10],
[65,34,24,11],
[65,34,24,12],
[65,34,24,13],
[65,34,24,14],
[65,34,24,17],
[65,34,24,18],
[65,34,24,19],
[65,34,24,20],
[65,34,24,21],
[65,34,24,22],
[65,34,26,0],
[65,34,26,1],
[65,34,26,2],
[65,34,26,3],
[65,34,26,4],
[65,34,26,5],
[65,34,26,6],
[65,34,26,8],
[65,34,26,12],
[65,34,26,13],
[65,34,26,14],
[65,34,26,16],
[65,34,26,17],
[65,34,26,19],
[65,34,26,20],
[65,34,26,21],
[65,34,26,22],
[65,34,26,24],
[65,34,27,0],
[65,34,27,1],
[65,34,27,2],
[65,34,27,3],
[65,34,27,4],
[65,34,27,5],
[65,34,27,6],
[65,34,27,11],
[65,34,27,12],
[65,34,27,13],
[65,34,27,14],
[65,34,27,15],
[65,34,27,16],
[65,34,27,17],
[65,34,27,18],
[65,34,27,19],
[65,34,27,21],
[65,34,27,24],
[65,34,29,0],
[65,34,29,1],
[65,34,29,2],
[65,34,29,3],
[65,34,29,4],
[65,34,29,6],
[65,34,29,8],
[65,34,29,9],
[65,34,29,10],
[65,34,29,13],
[65,34,29,14],
[65,34,29,15],
[65,34,29,16],
[65,34,29,17],
[65,34,29,18],
[65,34,29,19],
[65,34,29,20],
[65,34,29,21],
[65,34,29,22],
[65,34,32,0],
[65,34,32,1],
[65,34,32,2],
[65,34,32,3],
[65,34,32,4],
[65,34,32,5],
[65,34,32,6],
[65,34,32,8],
[65,34,32,9],
[65,34,32,10],
[65,34,32,13],
[65,34,32,14],
[65,34,32,15],
[65,34,32,16],
[65,34,32,17],
[65,34,32,18],
[65,34,32,19],
[65,34,33,0],
[65,34,33,1],
[65,34,33,2],
[65,34,33,3],
[65,34,33,4],
[65,34,33,5],
[65,34,33,6],
[65,34,33,8],
[65,34,33,9],
[65,34,33,10],
[65,34,33,13],
[65,34,33,14],
[65,34,33,15],
[65,34,33,16],
[65,34,33,17],
[65,34,33,18],
[65,35,3,0],
[65,35,3,1],
[65,35,3,2],
[65,35,4,0],
[65,35,4,1],
[65,35,4,2],
[65,35,4,3],
[65,35,5,0],
[65,35,5,1],
[65,35,5,2],
[65,35,5,4],
[65,35,6,0],
[65,35,6,1],
[65,35,6,2],
[65,35,6,4],
[65,35,7,0],
[65,35,7,1],
[65,35,7,2],
[65,35,7,3],
[65,35,7,4],
[65,35,7,5],
[65,35,7,6],
[65,35,8,0],
[65,35,8,1],
[65,35,8,2],
[65,35,8,4],
[65,35,8,7],
[65,35,10,0],
[65,35,10,1],
[65,35,10,2],
[65,35,10,3],
[65,35,10,4],
[65,35,10,5],
[65,35,10,6],
[65,35,10,7],
[65,35,10,8],
[65,35,11,0],
[65,35,11,1],
[65,35,11,2],
[65,35,11,3],
[65,35,11,4],
[65,35,11,5],
[65,35,11,6],
[65,35,11,7],
[65,35,11,8],
[65,35,11,10],
[65,35,12,0],
[65,35,12,1],
[65,35,12,2],
[65,35,12,3],
[65,35,12,4],
[65,35,12,5],
[65,35,12,6],
[65,35,12,7],
[65,35,12,8],
[65,35,12,10],
[65,35,13,0],
[65,35,13,1],
[65,35,13,2],
[65,35,13,3],
[65,35,13,4],
[65,35,13,5],
[65,35,13,6],
[65,35,13,7],
[65,35,13,8],
[65,35,13,10],
[65,35,13,11],
[65,35,13,12],
[65,35,14,0],
[65,35,14,1],
[65,35,14,2],
[65,35,14,3],
[65,35,14,4],
[65,35,14,5],
[65,35,14,6],
[65,35,14,7],
[65,35,14,8],
[65,35,14,10],
[65,35,14,11],
[65,35,14,12],
[65,35,16,0],
[65,35,16,1],
[65,35,16,2],
[65,35,16,3],
[65,35,16,4],
[65,35,16,5],
[65,35,16,6],
[65,35,16,7],
[65,35,16,8],
[65,35,16,10],
[65,35,16,11],
[65,35,16,12],
[65,35,16,13],
[65,35,16,14],
[65,35,17,0],
[65,35,17,1],
[65,35,17,2],
[65,35,17,3],
[65,35,17,4],
[65,35,17,5],
[65,35,17,6],
[65,35,17,7],
[65,35,17,8],
[65,35,17,10],
[65,35,17,11],
[65,35,17,12],
[65,35,17,13],
[65,35,17,14],
[65,35,17,16],
[65,35,19,0],
[65,35,19,1],
[65,35,19,2],
[65,35,19,3],
[65,35,19,4],
[65,35,19,5],
[65,35,19,6],
[65,35,19,7],
[65,35,19,8],
[65,35,19,10],
[65,35,19,11],
[65,35,19,12],
[65,35,19,16],
[65,35,19,17],
[65,35,20,0],
[65,35,20,1],
[65,35,20,2],
[65,35,20,3],
[65,35,20,4],
[65,35,20,5],
[65,35,20,6],
[65,35,20,7],
[65,35,20,8],
[65,35,20,11],
[65,35,20,12],
[65,35,20,13],
[65,35,20,14],
[65,35,20,16],
[65,35,20,17],
[65,35,20,19],
[65,35,21,0],
[65,35,21,1],
[65,35,21,2],
[65,35,21,4],
[65,35,21,7],
[65,35,21,10],
[65,35,21,11],
[65,35,21,12],
[65,35,21,13],
[65,35,21,14],
[65,35,21,19],
[65,35,21,20],
[65,35,22,0],
[65,35,22,1],
[65,35,22,2],
[65,35,22,3],
[65,35,22,4],
[65,35,22,5],
[65,35,22,6],
[65,35,22,7],
[65,35,22,8],
[65,35,22,11],
[65,35,22,12],
[65,35,22,13],
[65,35,22,14],
[65,35,22,16],
[65,35,22,17],
[65,35,22,19],
[65,35,22,21],
[65,35,24,0],
[65,35,24,1],
[65,35,24,2],
[65,35,24,3],
[65,35,24,4],
[65,35,24,5],
[65,35,24,6],
[65,35,24,7],
[65,35,24,8],
[65,35,24,10],
[65,35,24,11],
[65,35,24,12],
[65,35,24,13],
[65,35,24,17],
[65,35,24,19],
[65,35,24,20],
[65,35,24,21],
[65,35,24,22],
[65,35,25,0],
[65,35,25,1],
[65,35,25,2],
[65,35,25,3],
[65,35,25,4],
[65,35,25,5],
[65,35,25,6],
[65,35,25,8],
[65,35,25,12],
[65,35,25,13],
[65,35,25,14],
[65,35,25,16],
[65,35,25,17],
[65,35,25,19],
[65,35,25,20],
[65,35,25,21],
[65,35,25,22],
[65,35,25,24],
[65,35,27,0],
[65,35,27,1],
[65,35,27,2],
[65,35,27,3],
[65,35,27,4],
[65,35,27,5],
[65,35,27,8],
[65,35,27,11],
[65,35,27,12],
[65,35,27,13],
[65,35,27,14],
[65,35,27,16],
[65,35,27,17],
[65,35,27,19],
[65,35,27,21],
[65,35,27,24],
[65,35,28,0],
[65,35,28,1],
[65,35,28,2],
[65,35,28,3],
[65,35,28,4],
[65,35,28,6],
[65,35,28,8],
[65,35,28,10],
[65,35,28,11],
[65,35,28,12],
[65,35,28,13],
[65,35,28,14],
[65,35,28,16],
[65,35,28,17],
[65,35,28,19],
[65,35,28,20],
[65,35,28,21],
[65,35,28,22],
[65,35,29,0],
[65,35,29,1],
[65,35,29,2],
[65,35,29,5],
[65,35,29,6],
[65,35,29,7],
[65,35,29,8],
[65,35,29,10],
[65,35,29,13],
[65,35,29,14],
[65,35,29,16],
[65,35,29,17],
[65,35,29,19],
[65,35,29,20],
[65,35,29,21],
[65,35,32,0],
[65,35,32,1],
[65,35,32,2],
[65,35,32,3],
[65,35,32,4],
[65,35,32,5],
[65,35,32,6],
[65,35,32,7],
[65,35,32,8],
[65,35,32,10],
[65,35,32,13],
[65,35,32,14],
[65,35,32,16],
[65,35,32,17],
[65,35,33,0],
[65,35,33,1],
[65,35,33,2],
[65,35,33,3],
[65,35,33,4],
[65,35,33,5],
[65,35,33,6],
[65,35,33,7],
[65,35,33,8],
[65,35,33,10],
[65,35,33,13],
[65,35,33,14],
[65,35,33,16],
[65,35,33,17],
[65,35,34,0],
[65,35,34,1],
[65,35,34,2],
[65,35,34,3],
[65,35,34,4],
[65,35,34,5],
[65,35,34,6],
[65,35,34,8],
[65,35,34,10],
[65,35,34,11],
[65,35,34,12],
[65,35,34,13],
[65,35,34,14],
[65,36,3,0],
[65,36,3,1],
[65,36,3,2],
[65,36,4,0],
[65,36,4,1],
[65,36,4,2],
[65,36,4,3],
[65,36,5,0],
[65,36,5,1],
[65,36,5,2],
[65,36,5,4],
[65,36,6,0],
[65,36,6,1],
[65,36,6,2],
[65,36,6,4],
[65,36,7,0],
[65,36,7,1],
[65,36,7,2],
[65,36,7,3],
[65,36,7,4],
[65,36,7,5],
[65,36,7,6],
[65,36,8,0],
[65,36,8,1],
[65,36,8,2],
[65,36,8,4],
[65,36,8,7],
[65,36,9,0],
[65,36,9,1],
[65,36,9,2],
[65,36,9,3],
[65,36,9,4],
[65,36,9,5],
[65,36,9,6],
[65,36,9,7],
[65,36,9,8],
[65,36,10,0],
[65,36,10,1],
[65,36,10,2],
[65,36,10,3],
[65,36,10,4],
[65,36,10,5],
[65,36,10,6],
[65,36,10,7],
[65,36,10,8],
[65,36,10,9],
[65,36,11,0],
[65,36,11,1],
[65,36,11,2],
[65,36,11,3],
[65,36,11,4],
[65,36,11,5],
[65,36,11,6],
[65,36,11,7],
[65,36,11,8],
[65,36,11,9],
[65,36,11,10],
[65,36,12,0],
[65,36,12,1],
[65,36,12,2],
[65,36,12,3],
[65,36,12,4],
[65,36,12,5],
[65,36,12,6],
[65,36,12,7],
[65,36,12,8],
[65,36,12,9],
[65,36,12,10],
[65,36,15,0],
[65,36,15,1],
[65,36,15,2],
[65,36,15,3],
[65,36,15,4],
[65,36,15,5],
[65,36,15,6],
[65,36,15,7],
[65,36,15,8],
[65,36,15,10],
[65,36,15,11],
[65,36,15,12],
[65,36,16,0],
[65,36,16,1],
[65,36,16,2],
[65,36,16,3],
[65,36,16,4],
[65,36,16,5],
[65,36,16,6],
[65,36,16,7],
[65,36,16,8],
[65,36,16,9],
[65,36,16,10],
[65,36,16,11],
[65,36,16,12],
[65,36,16,15],
[65,36,17,0],
[65,36,17,1],
[65,36,17,2],
[65,36,17,3],
[65,36,17,4],
[65,36,17,5],
[65,36,17,6],
[65,36,17,7],
[65,36,17,8],
[65,36,17,9],
[65,36,17,10],
[65,36,17,11],
[65,36,17,12],
[65,36,17,15],
[65,36,17,16],
[65,36,18,0],
[65,36,18,1],
[65,36,18,2],
[65,36,18,3],
[65,36,18,4],
[65,36,18,5],
[65,36,18,6],
[65,36,18,7],
[65,36,18,8],
[65,36,18,10],
[65,36,18,11],
[65,36,18,12],
[65,36,18,16],
[65,36,18,17],
[65,36,20,0],
[65,36,20,1],
[65,36,20,2],
[65,36,20,3],
[65,36,20,4],
[65,36,20,5],
[65,36,20,6],
[65,36,20,7],
[65,36,20,8],
[65,36,20,9],
[65,36,20,11],
[65,36,20,12],
[65,36,20,15],
[65,36,20,18],
[65,36,21,0],
[65,36,21,1],
[65,36,21,2],
[65,36,21,4],
[65,36,21,7],
[65,36,21,9],
[65,36,21,10],
[65,36,21,11],
[65,36,21,12],
[65,36,21,16],
[65,36,21,17],
[65,36,21,18],
[65,36,21,20],
[65,36,22,0],
[65,36,22,1],
[65,36,22,2],
[65,36,22,3],
[65,36,22,4],
[65,36,22,5],
[65,36,22,6],
[65,36,22,7],
[65,36,22,8],
[65,36,22,9],
[65,36,22,11],
[65,36,22,12],
[65,36,22,15],
[65,36,22,16],
[65,36,22,17],
[65,36,22,18],
[65,36,22,21],
[65,36,24,0],
[65,36,24,1],
[65,36,24,2],
[65,36,24,3],
[65,36,24,4],
[65,36,24,5],
[65,36,24,6],
[65,36,24,7],
[65,36,24,8],
[65,36,24,9],
[65,36,24,10],
[65,36,24,11],
[65,36,24,15],
[65,36,24,17],
[65,36,24,18],
[65,36,24,20],
[65,36,24,21],
[65,36,24,22],
[65,36,25,0],
[65,36,25,1],
[65,36,25,2],
[65,36,25,3],
[65,36,25,4],
[65,36,25,5],
[65,36,25,6],
[65,36,25,10],
[65,36,25,11],
[65,36,25,12],
[65,36,25,15],
[65,36,25,16],
[65,36,25,17],
[65,36,25,18],
[65,36,25,20],
[65,36,25,21],
[65,36,25,22],
[65,36,25,24],
[65,36,26,0],
[65,36,26,1],
[65,36,26,2],
[65,36,26,3],
[65,36,26,4],
[65,36,26,5],
[65,36,26,8],
[65,36,26,10],
[65,36,26,11],
[65,36,26,12],
[65,36,26,16],
[65,36,26,17],
[65,36,26,20],
[65,36,26,21],
[65,36,26,22],
[65,36,26,24],
[65,36,27,0],
[65,36,27,1],
[65,36,27,2],
[65,36,27,3],
[65,36,27,4],
[65,36,27,6],
[65,36,27,7],
[65,36,27,8],
[65,36,27,9],
[65,36,27,11],
[65,36,27,12],
[65,36,27,15],
[65,36,27,16],
[65,36,27,17],
[65,36,27,18],
[65,36,27,21],
[65,36,28,0],
[65,36,28,1],
[65,36,28,2],
[65,36,28,5],
[65,36,28,6],
[65,36,28,8],
[65,36,28,9],
[65,36,28,10],
[65,36,28,11],
[65,36,28,12],
[65,36,28,15],
[65,36,28,16],
[65,36,28,17],
[65,36,28,18],
[65,36,28,20],
[65,36,28,21],
[65,36,29,3],
[65,36,29,4],
[65,36,29,5],
[65,36,29,6],
[65,36,29,7],
[65,36,29,8],
[65,36,29,9],
[65,36,29,10],
[65,36,29,15],
[65,36,29,16],
[65,36,29,17],
[65,36,29,18],
[65,36,29,20],
[65,36,32,0],
[65,36,32,1],
[65,36,32,2],
[65,36,32,3],
[65,36,32,4],
[65,36,32,5],
[65,36,32,6],
[65,36,32,7],
[65,36,32,8],
[65,36,32,9],
[65,36,32,10],
[65,36,32,15],
[65,36,32,16],
[65,36,32,17],
[65,36,33,0],
[65,36,33,1],
[65,36,33,2],
[65,36,33,3],
[65,36,33,4],
[65,36,33,5],
[65,36,33,6],
[65,36,33,7],
[65,36,33,8],
[65,36,33,9],
[65,36,33,10],
[65,36,33,15],
[65,36,34,0],
[65,36,34,1],
[65,36,34,2],
[65,36,34,3],
[65,36,34,4],
[65,36,34,5],
[65,36,34,6],
[65,36,34,8],
[65,36,34,9],
[65,36,34,10],
[65,36,34,11],
[65,36,34,12],
[65,36,35,0],
[65,36,35,1],
[65,36,35,2],
[65,36,35,3],
[65,36,35,4],
[65,36,35,5],
[65,36,35,6],
[65,36,35,7],
[65,36,35,8],
[65,36,35,10],
[65,36,35,11],
[65,36,35,12],
[65,37,3,0],
[65,37,3,1],
[65,37,3,2],
[65,37,5,0],
[65,37,5,1],
[65,37,5,2],
[65,37,6,0],
[65,37,6,1],
[65,37,6,2],
[65,37,7,0],
[65,37,7,1],
[65,37,7,2],
[65,37,7,3],
[65,37,7,5],
[65,37,7,6],
[65,37,8,0],
[65,37,8,1],
[65,37,8,2],
[65,37,8,7],
[65,37,9,0],
[65,37,9,1],
[65,37,9,2],
[65,37,9,3],
[65,37,9,5],
[65,37,9,6],
[65,37,9,7],
[65,37,9,8],
[65,37,10,0],
[65,37,10,1],
[65,37,10,2],
[65,37,10,3],
[65,37,10,5],
[65,37,10,6],
[65,37,10,7],
[65,37,10,8],
[65,37,10,9],
[65,37,11,0],
[65,37,11,1],
[65,37,11,2],
[65,37,11,3],
[65,37,11,5],
[65,37,11,6],
[65,37,11,7],
[65,37,11,8],
[65,37,11,9],
[65,37,11,10],
[65,37,12,0],
[65,37,12,1],
[65,37,12,2],
[65,37,12,3],
[65,37,12,5],
[65,37,12,6],
[65,37,12,7],
[65,37,12,8],
[65,37,12,9],
[65,37,12,10],
[65,37,13,0],
[65,37,13,1],
[65,37,13,2],
[65,37,13,3],
[65,37,13,5],
[65,37,13,6],
[65,37,13,7],
[65,37,13,8],
[65,37,13,9],
[65,37,13,10],
[65,37,13,11],
[65,37,13,12],
[65,37,14,0],
[65,37,14,1],
[65,37,14,2],
[65,37,14,3],
[65,37,14,5],
[65,37,14,6],
[65,37,14,7],
[65,37,14,8],
[65,37,14,9],
[65,37,14,10],
[65,37,14,11],
[65,37,14,12],
[65,37,15,0],
[65,37,15,1],
[65,37,15,2],
[65,37,15,3],
[65,37,15,5],
[65,37,15,6],
[65,37,15,7],
[65,37,15,8],
[65,37,15,10],
[65,37,15,11],
[65,37,15,12],
[65,37,15,13],
[65,37,15,14],
[65,37,16,0],
[65,37,16,1],
[65,37,16,2],
[65,37,16,3],
[65,37,16,5],
[65,37,16,6],
[65,37,16,7],
[65,37,16,8],
[65,37,16,9],
[65,37,16,10],
[65,37,16,11],
[65,37,16,12],
[65,37,16,13],
[65,37,16,14],
[65,37,16,15],
[65,37,17,0],
[65,37,17,1],
[65,37,17,2],
[65,37,17,3],
[65,37,17,5],
[65,37,17,6],
[65,37,17,7],
[65,37,17,8],
[65,37,17,9],
[65,37,17,10],
[65,37,17,11],
[65,37,17,12],
[65,37,17,13],
[65,37,17,14],
[65,37,17,15],
[65,37,17,16],
[65,37,18,0],
[65,37,18,1],
[65,37,18,2],
[65,37,18,3],
[65,37,18,5],
[65,37,18,6],
[65,37,18,7],
[65,37,18,8],
[65,37,18,10],
[65,37,18,11],
[65,37,18,12],
[65,37,18,13],
[65,37,18,14],
[65,37,18,16],
[65,37,18,17],
[65,37,19,0],
[65,37,19,1],
[65,37,19,2],
[65,37,19,3],
[65,37,19,5],
[65,37,19,6],
[65,37,19,7],
[65,37,19,8],
[65,37,19,9],
[65,37,19,10],
[65,37,19,11],
[65,37,19,12],
[65,37,19,15],
[65,37,19,18],
[65,37,20,0],
[65,37,20,1],
[65,37,20,2],
[65,37,20,3],
[65,37,20,5],
[65,37,20,6],
[65,37,20,7],
[65,37,20,8],
[65,37,20,9],
[65,37,20,11],
[65,37,20,12],
[65,37,20,13],
[65,37,20,14],
[65,37,20,16],
[65,37,20,17],
[65,37,20,18],
[65,37,20,19],
[65,37,21,0],
[65,37,21,1],
[65,37,21,2],
[65,37,21,7],
[65,37,21,9],
[65,37,21,10],
[65,37,21,11],
[65,37,21,12],
[65,37,21,13],
[65,37,21,15],
[65,37,21,16],
[65,37,21,17],
[65,37,21,18],
[65,37,21,19],
[65,37,21,20],
[65,37,22,0],
[65,37,22,1],
[65,37,22,2],
[65,37,22,3],
[65,37,22,5],
[65,37,22,6],
[65,37,22,7],
[65,37,22,8],
[65,37,22,9],
[65,37,22,11],
[65,37,22,14],
[65,37,22,15],
[65,37,22,16],
[65,37,22,17],
[65,37,22,18],
[65,37,22,19],
[65,37,22,21],
[65,37,24,0],
[65,37,24,1],
[65,37,24,2],
[65,37,24,3],
[65,37,24,5],
[65,37,24,6],
[65,37,24,7],
[65,37,24,8],
[65,37,24,9],
[65,37,24,12],
[65,37,24,13],
[65,37,24,14],
[65,37,24,15],
[65,37,24,17],
[65,37,24,18],
[65,37,24,19],
[65,37,24,20],
[65,37,24,21],
[65,37,24,22],
[65,37,25,0],
[65,37,25,1],
[65,37,25,2],
[65,37,25,3],
[65,37,25,5],
[65,37,25,8],
[65,37,25,9],
[65,37,25,10],
[65,37,25,11],
[65,37,25,12],
[65,37,25,13],
[65,37,25,14],
[65,37,25,15],
[65,37,25,16],
[65,37,25,17],
[65,37,25,18],
[65,37,25,19],
[65,37,25,20],
[65,37,25,21],
[65,37,25,22],
[65,37,25,24],
[65,37,26,0],
[65,37,26,1],
[65,37,26,2],
[65,37,26,3],
[65,37,26,6],
[65,37,26,7],
[65,37,26,8],
[65,37,26,10],
[65,37,26,11],
[65,37,26,12],
[65,37,26,13],
[65,37,26,14],
[65,37,26,16],
[65,37,26,17],
[65,37,26,19],
[65,37,26,20],
[65,37,26,21],
[65,37,26,22],
[65,37,27,0],
[65,37,27,1],
[65,37,27,2],
[65,37,27,5],
[65,37,27,6],
[65,37,27,7],
[65,37,27,8],
[65,37,27,9],
[65,37,27,11],
[65,37,27,12],
[65,37,27,13],
[65,37,27,14],
[65,37,27,15],
[65,37,27,16],
[65,37,27,17],
[65,37,27,18],
[65,37,27,19],
[65,37,27,21],
[65,37,28,3],
[65,37,28,5],
[65,37,28,6],
[65,37,28,8],
[65,37,28,9],
[65,37,28,10],
[65,37,28,11],
[65,37,28,12],
[65,37,28,13],
[65,37,28,14],
[65,37,28,15],
[65,37,28,16],
[65,37,28,17],
[65,37,28,18],
[65,37,28,19],
[65,37,28,20],
[65,37,29,0],
[65,37,29,1],
[65,37,29,2],
[65,37,29,3],
[65,37,29,5],
[65,37,29,6],
[65,37,29,7],
[65,37,29,8],
[65,37,29,9],
[65,37,29,10],
[65,37,29,13],
[65,37,29,14],
[65,37,29,15],
[65,37,29,16],
[65,37,29,17],
[65,37,29,18],
[65,37,29,19],
[65,37,32,0],
[65,37,32,1],
[65,37,32,2],
[65,37,32,3],
[65,37,32,5],
[65,37,32,6],
[65,37,32,7],
[65,37,32,8],
[65,37,32,9],
[65,37,32,10],
[65,37,32,13],
[65,37,32,14],
[65,37,32,15],
[65,37,33,0],
[65,37,33,1],
[65,37,33,2],
[65,37,33,3],
[65,37,33,5],
[65,37,33,6],
[65,37,33,7],
[65,37,33,8],
[65,37,33,9],
[65,37,33,10],
[65,37,33,13],
[65,37,33,14],
[65,37,34,0],
[65,37,34,1],
[65,37,34,2],
[65,37,34,3],
[65,37,34,5],
[65,37,34,6],
[65,37,34,8],
[65,37,34,9],
[65,37,34,10],
[65,37,34,11],
[65,37,34,12],
[65,37,34,13],
[65,37,35,0],
[65,37,35,1],
[65,37,35,2],
[65,37,35,3],
[65,37,35,5],
[65,37,35,6],
[65,37,35,7],
[65,37,35,8],
[65,37,35,10],
[65,37,35,11],
[65,37,36,0],
[65,37,36,1],
[65,37,36,2],
[65,37,36,3],
[65,37,36,5],
[65,37,36,6],
[65,37,36,7],
[65,37,36,8],
[65,37,36,9],
[65,38,3,0],
[65,38,3,1],
[65,38,3,2],
[65,38,4,0],
[65,38,4,1],
[65,38,4,2],
[65,38,4,3],
[65,38,5,0],
[65,38,5,1],
[65,38,5,2],
[65,38,5,4],
[65,38,6,0],
[65,38,6,1],
[65,38,6,2],
[65,38,6,4],
[65,38,8,0],
[65,38,8,1],
[65,38,8,2],
[65,38,8,4],
[65,38,9,0],
[65,38,9,1],
[65,38,9,2],
[65,38,9,3],
[65,38,9,4],
[65,38,9,5],
[65,38,9,6],
[65,38,9,8],
[65,38,10,0],
[65,38,10,1],
[65,38,10,2],
[65,38,10,3],
[65,38,10,4],
[65,38,10,5],
[65,38,10,6],
[65,38,10,8],
[65,38,10,9],
[65,38,11,0],
[65,38,11,1],
[65,38,11,2],
[65,38,11,3],
[65,38,11,4],
[65,38,11,5],
[65,38,11,6],
[65,38,11,8],
[65,38,11,9],
[65,38,11,10],
[65,38,12,0],
[65,38,12,1],
[65,38,12,2],
[65,38,12,3],
[65,38,12,4],
[65,38,12,5],
[65,38,12,6],
[65,38,12,8],
[65,38,12,9],
[65,38,12,10],
[65,38,13,0],
[65,38,13,1],
[65,38,13,2],
[65,38,13,3],
[65,38,13,4],
[65,38,13,5],
[65,38,13,6],
[65,38,13,8],
[65,38,13,9],
[65,38,13,10],
[65,38,13,11],
[65,38,13,12],
[65,38,14,0],
[65,38,14,1],
[65,38,14,2],
[65,38,14,3],
[65,38,14,4],
[65,38,14,5],
[65,38,14,6],
[65,38,14,8],
[65,38,14,9],
[65,38,14,10],
[65,38,14,11],
[65,38,14,12],
[65,38,15,0],
[65,38,15,1],
[65,38,15,2],
[65,38,15,3],
[65,38,15,4],
[65,38,15,5],
[65,38,15,6],
[65,38,15,8],
[65,38,15,10],
[65,38,15,11],
[65,38,15,12],
[65,38,15,13],
[65,38,15,14],
[65,38,16,0],
[65,38,16,1],
[65,38,16,2],
[65,38,16,3],
[65,38,16,4],
[65,38,16,5],
[65,38,16,6],
[65,38,16,8],
[65,38,16,9],
[65,38,16,10],
[65,38,16,11],
[65,38,16,12],
[65,38,16,13],
[65,38,16,14],
[65,38,16,15],
[65,38,17,0],
[65,38,17,1],
[65,38,17,2],
[65,38,17,3],
[65,38,17,4],
[65,38,17,5],
[65,38,17,6],
[65,38,17,8],
[65,38,17,9],
[65,38,17,10],
[65,38,17,11],
[65,38,17,12],
[65,38,17,13],
[65,38,17,14],
[65,38,17,15],
[65,38,17,16],
[65,38,18,0],
[65,38,18,1],
[65,38,18,2],
[65,38,18,3],
[65,38,18,4],
[65,38,18,5],
[65,38,18,6],
[65,38,18,8],
[65,38,18,10],
[65,38,18,11],
[65,38,18,12],
[65,38,18,13],
[65,38,18,14],
[65,38,19,0],
[65,38,19,1],
[65,38,19,2],
[65,38,19,3],
[65,38,19,4],
[65,38,19,5],
[65,38,19,6],
[65,38,19,8],
[65,38,19,9],
[65,38,19,10],
[65,38,19,11],
[65,38,19,12],
[65,38,19,16],
[65,38,19,17],
[65,38,19,18],
[65,38,20,0],
[65,38,20,1],
[65,38,20,2],
[65,38,20,3],
[65,38,20,4],
[65,38,20,5],
[65,38,20,6],
[65,38,20,8],
[65,38,20,9],
[65,38,20,11],
[65,38,20,12],
[65,38,20,13],
[65,38,20,15],
[65,38,20,16],
[65,38,20,17],
[65,38,20,18],
[65,38,20,19],
[65,38,21,0],
[65,38,21,1],
[65,38,21,2],
[65,38,21,4],
[65,38,21,9],
[65,38,21,10],
[65,38,21,11],
[65,38,21,14],
[65,38,21,15],
[65,38,21,16],
[65,38,21,17],
[65,38,21,18],
[65,38,21,19],
[65,38,21,20],
[65,38,22,0],
[65,38,22,1],
[65,38,22,2],
[65,38,22,3],
[65,38,22,4],
[65,38,22,5],
[65,38,22,6],
[65,38,22,8],
[65,38,22,9],
[65,38,22,12],
[65,38,22,13],
[65,38,22,14],
[65,38,22,15],
[65,38,22,16],
[65,38,22,17],
[65,38,22,18],
[65,38,22,19],
[65,38,22,21],
[65,38,24,0],
[65,38,24,1],
[65,38,24,2],
[65,38,24,3],
[65,38,24,4],
[65,38,24,5],
[65,38,24,6],
[65,38,24,10],
[65,38,24,11],
[65,38,24,12],
[65,38,24,13],
[65,38,24,14],
[65,38,24,15],
[65,38,24,17],
[65,38,24,18],
[65,38,24,19],
[65,38,24,20],
[65,38,24,21],
[65,38,24,22],
[65,38,26,0],
[65,38,26,1],
[65,38,26,2],
[65,38,26,5],
[65,38,26,6],
[65,38,26,8],
[65,38,26,10],
[65,38,26,11],
[65,38,26,12],
[65,38,26,13],
[65,38,26,14],
[65,38,26,16],
[65,38,26,17],
[65,38,26,19],
[65,38,26,20],
[65,38,26,21],
[65,38,27,3],
[65,38,27,4],
[65,38,27,5],
[65,38,27,6],
[65,38,27,8],
[65,38,27,9],
[65,38,27,11],
[65,38,27,12],
[65,38,27,13],
[65,38,27,14],
[65,38,27,15],
[65,38,27,16],
[65,38,27,17],
[65,38,27,18],
[65,38,27,19],
[65,38,29,0],
[65,38,29,1],
[65,38,29,2],
[65,38,29,3],
[65,38,29,4],
[65,38,29,5],
[65,38,29,6],
[65,38,29,8],
[65,38,29,9],
[65,38,29,10],
[65,38,29,13],
[65,38,29,14],
[65,38,29,15],
[65,38,29,16],
[65,38,29,17],
[65,38,29,18],
[65,38,32,0],
[65,38,32,1],
[65,38,32,2],
[65,38,32,3],
[65,38,32,4],
[65,38,32,5],
[65,38,32,6],
[65,38,32,8],
[65,38,32,9],
[65,38,32,10],
[65,38,32,13],
[65,38,32,14],
[65,38,33,0],
[65,38,33,1],
[65,38,33,2],
[65,38,33,3],
[65,38,33,4],
[65,38,33,5],
[65,38,33,6],
[65,38,33,8],
[65,38,33,9],
[65,38,33,10],
[65,38,33,13],
[65,38,35,0],
[65,38,35,1],
[65,38,35,2],
[65,38,35,3],
[65,38,35,4],
[65,38,35,5],
[65,38,35,6],
[65,38,35,8],
[65,38,36,0],
[65,38,36,1],
[65,38,36,2],
[65,38,36,3],
[65,38,36,4],
[65,38,36,5],
[65,38,36,6],
[65,38,37,0],
[65,38,37,1],
[65,38,37,2],
[65,38,37,3],
[65,38,37,5],
[65,39,4,0],
[65,39,4,1],
[65,39,4,2],
[65,39,7,0],
[65,39,7,1],
[65,39,7,2],
[65,39,7,4],
[65,39,9,0],
[65,39,9,1],
[65,39,9,2],
[65,39,9,4],
[65,39,9,7],
[65,39,10,0],
[65,39,10,1],
[65,39,10,2],
[65,39,10,4],
[65,39,10,7],
[65,39,10,9],
[65,39,11,0],
[65,39,11,1],
[65,39,11,2],
[65,39,11,4],
[65,39,11,7],
[65,39,11,9],
[65,39,11,10],
[65,39,12,0],
[65,39,12,1],
[65,39,12,2],
[65,39,12,4],
[65,39,12,7],
[65,39,12,9],
[65,39,12,10],
[65,39,13,0],
[65,39,13,1],
[65,39,13,2],
[65,39,13,4],
[65,39,13,7],
[65,39,13,9],
[65,39,13,10],
[65,39,13,11],
[65,39,13,12],
[65,39,14,0],
[65,39,14,1],
[65,39,14,2],
[65,39,14,4],
[65,39,14,7],
[65,39,14,9],
[65,39,14,10],
[65,39,14,11],
[65,39,14,12],
[65,39,15,0],
[65,39,15,1],
[65,39,15,2],
[65,39,15,4],
[65,39,15,7],
[65,39,15,10],
[65,39,15,11],
[65,39,15,12],
[65,39,15,13],
[65,39,15,14],
[65,39,16,0],
[65,39,16,1],
[65,39,16,2],
[65,39,16,4],
[65,39,16,7],
[65,39,16,9],
[65,39,16,10],
[65,39,16,11],
[65,39,16,12],
[65,39,16,13],
[65,39,16,14],
[65,39,16,15],
[65,39,17,0],
[65,39,17,1],
[65,39,17,2],
[65,39,17,4],
[65,39,17,7],
[65,39,17,9],
[65,39,17,10],
[65,39,17,11],
[65,39,17,12],
[65,39,17,13],
[65,39,17,14],
[65,39,17,15],
[65,39,18,0],
[65,39,18,1],
[65,39,18,2],
[65,39,18,4],
[65,39,18,7],
[65,39,18,10],
[65,39,18,11],
[65,39,18,12],
[65,39,18,13],
[65,39,18,14],
[65,39,18,16],
[65,39,18,17],
[65,39,19,0],
[65,39,19,1],
[65,39,19,2],
[65,39,19,4],
[65,39,19,7],
[65,39,19,9],
[65,39,19,10],
[65,39,19,11],
[65,39,19,12],
[65,39,19,15],
[65,39,19,16],
[65,39,19,17],
[65,39,19,18],
[65,39,20,0],
[65,39,20,1],
[65,39,20,2],
[65,39,20,4],
[65,39,20,7],
[65,39,20,9],
[65,39,20,11],
[65,39,20,14],
[65,39,20,15],
[65,39,20,16],
[65,39,20,17],
[65,39,20,18],
[65,39,20,19],
[65,39,22,0],
[65,39,22,1],
[65,39,22,2],
[65,39,22,4],
[65,39,22,7],
[65,39,22,11],
[65,39,22,12],
[65,39,22,13],
[65,39,22,14],
[65,39,22,15],
[65,39,22,16],
[65,39,22,17],
[65,39,22,18],
[65,39,22,19],
[65,39,24,0],
[65,39,24,1],
[65,39,24,2],
[65,39,24,4],
[65,39,24,9],
[65,39,24,10],
[65,39,24,11],
[65,39,24,12],
[65,39,24,13],
[65,39,24,14],
[65,39,24,15],
[65,39,24,17],
[65,39,24,18],
[65,39,24,19],
[65,39,24,20],
[65,39,24,22],
[65,39,25,0],
[65,39,25,1],
[65,39,25,2],
[65,39,25,9],
[65,39,25,10],
[65,39,25,11],
[65,39,25,12],
[65,39,25,13],
[65,39,25,14],
[65,39,25,15],
[65,39,25,16],
[65,39,25,17],
[65,39,25,18],
[65,39,25,19],
[65,39,25,20],
[65,39,26,4],
[65,39,26,7],
[65,39,26,10],
[65,39,26,11],
[65,39,26,12],
[65,39,26,13],
[65,39,26,14],
[65,39,26,16],
[65,39,26,17],
[65,39,26,19],
[65,39,26,20],
[65,39,27,0],
[65,39,27,1],
[65,39,27,2],
[65,39,27,4],
[65,39,27,7],
[65,39,27,9],
[65,39,27,11],
[65,39,27,12],
[65,39,27,13],
[65,39,27,14],
[65,39,27,15],
[65,39,27,16],
[65,39,27,17],
[65,39,27,18],
[65,39,27,19],
[65,39,28,0],
[65,39,28,1],
[65,39,28,2],
[65,39,28,4],
[65,39,28,9],
[65,39,28,10],
[65,39,28,11],
[65,39,28,12],
[65,39,28,13],
[65,39,28,14],
[65,39,28,15],
[65,39,28,16],
[65,39,28,17],
[65,39,28,18],
[65,39,29,0],
[65,39,29,1],
[65,39,29,2],
[65,39,29,4],
[65,39,29,7],
[65,39,29,9],
[65,39,29,10],
[65,39,29,13],
[65,39,29,14],
[65,39,29,15],
[65,39,29,16],
[65,39,29,17],
[65,39,32,0],
[65,39,32,1],
[65,39,32,2],
[65,39,32,4],
[65,39,32,7],
[65,39,32,9],
[65,39,32,10],
[65,39,32,13],
[65,39,33,0],
[65,39,33,1],
[65,39,33,2],
[65,39,33,4],
[65,39,33,7],
[65,39,33,9],
[65,39,33,10],
[65,39,34,0],
[65,39,34,1],
[65,39,34,2],
[65,39,34,4],
[65,39,34,9],
[65,39,35,0],
[65,39,35,1],
[65,39,35,2],
[65,39,35,4],
[65,39,35,7],
[65,39,36,0],
[65,39,36,1],
[65,39,36,2],
[65,39,36,4],
[65,39,37,0],
[65,39,37,1],
[65,39,37,2],
[65,39,38,0],
[65,39,38,1],
[65,39,38,2],
[65,40,3,0],
[65,40,3,1],
[65,40,3,2],
[65,40,4,0],
[65,40,4,1],
[65,40,4,2],
[65,40,4,3],
[65,40,5,0],
[65,40,5,1],
[65,40,5,2],
[65,40,5,4],
[65,40,6,0],
[65,40,6,1],
[65,40,6,2],
[65,40,6,4],
[65,40,7,0],
[65,40,7,1],
[65,40,7,2],
[65,40,7,3],
[65,40,7,4],
[65,40,7,5],
[65,40,7,6],
[65,40,8,0],
[65,40,8,1],
[65,40,8,2],
[65,40,8,4],
[65,40,8,7],
[65,40,10,0],
[65,40,10,1],
[65,40,10,2],
[65,40,10,3],
[65,40,10,4],
[65,40,10,5],
[65,40,10,6],
[65,40,10,7],
[65,40,10,8],
[65,40,11,0],
[65,40,11,1],
[65,40,11,2],
[65,40,11,3],
[65,40,11,4],
[65,40,11,5],
[65,40,11,6],
[65,40,11,7],
[65,40,11,8],
[65,40,11,10],
[65,40,12,0],
[65,40,12,1],
[65,40,12,2],
[65,40,12,3],
[65,40,12,4],
[65,40,12,5],
[65,40,12,6],
[65,40,12,7],
[65,40,12,8],
[65,40,12,10],
[65,40,13,0],
[65,40,13,1],
[65,40,13,2],
[65,40,13,3],
[65,40,13,4],
[65,40,13,5],
[65,40,13,6],
[65,40,13,7],
[65,40,13,8],
[65,40,13,10],
[65,40,13,11],
[65,40,13,12],
[65,40,14,0],
[65,40,14,1],
[65,40,14,2],
[65,40,14,3],
[65,40,14,4],
[65,40,14,5],
[65,40,14,6],
[65,40,14,7],
[65,40,14,8],
[65,40,14,10],
[65,40,14,11],
[65,40,14,12],
[65,40,16,0],
[65,40,16,1],
[65,40,16,2],
[65,40,16,3],
[65,40,16,4],
[65,40,16,5],
[65,40,16,6],
[65,40,16,7],
[65,40,16,8],
[65,40,16,10],
[65,40,16,11],
[65,40,16,12],
[65,40,16,13],
[65,40,16,14],
[65,40,17,0],
[65,40,17,1],
[65,40,17,2],
[65,40,17,3],
[65,40,17,4],
[65,40,17,5],
[65,40,17,6],
[65,40,17,7],
[65,40,17,8],
[65,40,17,10],
[65,40,17,11],
[65,40,17,12],
[65,40,17,13],
[65,40,17,14],
[65,40,17,16],
[65,40,19,0],
[65,40,19,1],
[65,40,19,2],
[65,40,19,3],
[65,40,19,4],
[65,40,19,5],
[65,40,19,6],
[65,40,19,7],
[65,40,19,8],
[65,40,19,10],
[65,40,19,11],
[65,40,19,16],
[65,40,19,17],
[65,40,20,0],
[65,40,20,1],
[65,40,20,2],
[65,40,20,3],
[65,40,20,4],
[65,40,20,5],
[65,40,20,6],
[65,40,20,7],
[65,40,20,8],
[65,40,20,12],
[65,40,20,13],
[65,40,20,14],
[65,40,20,16],
[65,40,20,17],
[65,40,20,19],
[65,40,21,0],
[65,40,21,1],
[65,40,21,2],
[65,40,21,4],
[65,40,21,7],
[65,40,21,10],
[65,40,21,11],
[65,40,21,12],
[65,40,21,13],
[65,40,21,14],
[65,40,21,16],
[65,40,21,17],
[65,40,21,19],
[65,40,21,20],
[65,40,22,0],
[65,40,22,1],
[65,40,22,2],
[65,40,22,3],
[65,40,22,4],
[65,40,22,5],
[65,40,22,8],
[65,40,22,11],
[65,40,22,12],
[65,40,22,13],
[65,40,22,14],
[65,40,22,16],
[65,40,22,17],
[65,40,22,19],
[65,40,22,21],
[65,40,24,0],
[65,40,24,1],
[65,40,24,2],
[65,40,24,3],
[65,40,24,4],
[65,40,24,6],
[65,40,24,7],
[65,40,24,8],
[65,40,24,10],
[65,40,24,11],
[65,40,24,12],
[65,40,24,13],
[65,40,24,14],
[65,40,24,17],
[65,40,24,19],
[65,40,24,20],
[65,40,24,21],
[65,40,24,22],
[65,40,25,3],
[65,40,25,4],
[65,40,25,5],
[65,40,25,6],
[65,40,25,8],
[65,40,25,10],
[65,40,25,11],
[65,40,25,12],
[65,40,25,13],
[65,40,25,14],
[65,40,25,16],
[65,40,25,17],
[65,40,25,19],
[65,40,25,20],
[65,40,27,0],
[65,40,27,1],
[65,40,27,2],
[65,40,27,3],
[65,40,27,4],
[65,40,27,5],
[65,40,27,6],
[65,40,27,7],
[65,40,27,8],
[65,40,27,11],
[65,40,27,12],
[65,40,27,13],
[65,40,27,14],
[65,40,27,16],
[65,40,27,17],
[65,40,28,0],
[65,40,28,1],
[65,40,28,2],
[65,40,28,3],
[65,40,28,4],
[65,40,28,5],
[65,40,28,6],
[65,40,28,8],
[65,40,28,10],
[65,40,28,11],
[65,40,28,12],
[65,40,28,13],
[65,40,28,14],
[65,40,28,16],
[65,40,28,17],
[65,40,29,0],
[65,40,29,1],
[65,40,29,2],
[65,40,29,3],
[65,40,29,4],
[65,40,29,5],
[65,40,29,6],
[65,40,29,7],
[65,40,29,8],
[65,40,29,10],
[65,40,29,13],
[65,40,29,14],
[65,40,32,0],
[65,40,32,1],
[65,40,32,2],
[65,40,32,3],
[65,40,32,4],
[65,40,32,5],
[65,40,32,6],
[65,40,32,7],
[65,40,32,8],
[65,40,32,10],
[65,40,33,0],
[65,40,33,1],
[65,40,33,2],
[65,40,33,3],
[65,40,33,4],
[65,40,33,5],
[65,40,33,6],
[65,40,33,7],
[65,40,33,8],
[65,40,34,0],
[65,40,34,1],
[65,40,34,2],
[65,40,34,3],
[65,40,34,4],
[65,40,34,5],
[65,40,34,6],
[65,40,36,0],
[65,40,36,1],
[65,40,36,2],
[65,40,36,3],
[65,40,36,4],
[65,40,37,0],
[65,40,37,1],
[65,40,37,2],
[65,41,3,0],
[65,41,3,1],
[65,41,3,2],
[65,41,4,0],
[65,41,4,1],
[65,41,4,2],
[65,41,4,3],
[65,41,5,0],
[65,41,5,1],
[65,41,5,2],
[65,41,5,4],
[65,41,6,0],
[65,41,6,1],
[65,41,6,2],
[65,41,6,4],
[65,41,7,0],
[65,41,7,1],
[65,41,7,2],
[65,41,7,3],
[65,41,7,4],
[65,41,7,5],
[65,41,7,6],
[65,41,8,0],
[65,41,8,1],
[65,41,8,2],
[65,41,8,4],
[65,41,8,7],
[65,41,10,0],
[65,41,10,1],
[65,41,10,2],
[65,41,10,3],
[65,41,10,4],
[65,41,10,5],
[65,41,10,6],
[65,41,10,7],
[65,41,10,8],
[65,41,11,0],
[65,41,11,1],
[65,41,11,2],
[65,41,11,3],
[65,41,11,4],
[65,41,11,5],
[65,41,11,6],
[65,41,11,7],
[65,41,11,8],
[65,41,11,10],
[65,41,12,0],
[65,41,12,1],
[65,41,12,2],
[65,41,12,3],
[65,41,12,4],
[65,41,12,5],
[65,41,12,6],
[65,41,12,7],
[65,41,12,8],
[65,41,12,10],
[65,41,13,0],
[65,41,13,1],
[65,41,13,2],
[65,41,13,3],
[65,41,13,4],
[65,41,13,5],
[65,41,13,6],
[65,41,13,7],
[65,41,13,8],
[65,41,13,10],
[65,41,13,11],
[65,41,13,12],
[65,41,14,0],
[65,41,14,1],
[65,41,14,2],
[65,41,14,3],
[65,41,14,4],
[65,41,14,5],
[65,41,14,6],
[65,41,14,7],
[65,41,14,8],
[65,41,14,10],
[65,41,14,11],
[65,41,14,12],
[65,41,16,0],
[65,41,16,1],
[65,41,16,2],
[65,41,16,3],
[65,41,16,4],
[65,41,16,5],
[65,41,16,6],
[65,41,16,7],
[65,41,16,8],
[65,41,16,10],
[65,41,16,11],
[65,41,16,12],
[65,41,16,13],
[65,41,16,14],
[65,41,17,0],
[65,41,17,1],
[65,41,17,2],
[65,41,17,3],
[65,41,17,4],
[65,41,17,5],
[65,41,17,6],
[65,41,17,7],
[65,41,17,8],
[65,41,17,10],
[65,41,17,11],
[65,41,17,12],
[65,41,17,13],
[65,41,17,14],
[65,41,17,16],
[65,41,19,0],
[65,41,19,1],
[65,41,19,2],
[65,41,19,3],
[65,41,19,4],
[65,41,19,5],
[65,41,19,6],
[65,41,19,7],
[65,41,19,8],
[65,41,19,10],
[65,41,19,11],
[65,41,19,16],
[65,41,19,17],
[65,41,20,0],
[65,41,20,1],
[65,41,20,2],
[65,41,20,3],
[65,41,20,4],
[65,41,20,5],
[65,41,20,6],
[65,41,20,7],
[65,41,20,8],
[65,41,20,12],
[65,41,20,13],
[65,41,20,14],
[65,41,20,16],
[65,41,20,17],
[65,41,20,19],
[65,41,21,0],
[65,41,21,1],
[65,41,21,2],
[65,41,21,4],
[65,41,21,7],
[65,41,21,10],
[65,41,21,11],
[65,41,21,12],
[65,41,21,13],
[65,41,21,14],
[65,41,21,16],
[65,41,21,17],
[65,41,21,19],
[65,41,21,20],
[65,41,22,0],
[65,41,22,1],
[65,41,22,2],
[65,41,22,3],
[65,41,22,4],
[65,41,22,5],
[65,41,22,8],
[65,41,22,11],
[65,41,22,12],
[65,41,22,13],
[65,41,22,14],
[65,41,22,16],
[65,41,22,17],
[65,41,22,19],
[65,41,22,21],
[65,41,24,0],
[65,41,24,1],
[65,41,24,2],
[65,41,24,3],
[65,41,24,4],
[65,41,24,6],
[65,41,24,7],
[65,41,24,8],
[65,41,24,10],
[65,41,24,11],
[65,41,24,12],
[65,41,24,13],
[65,41,24,14],
[65,41,24,17],
[65,41,24,19],
[65,41,24,20],
[65,41,24,21],
[65,41,24,22],
[65,41,25,3],
[65,41,25,4],
[65,41,25,5],
[65,41,25,6],
[65,41,25,8],
[65,41,25,10],
[65,41,25,11],
[65,41,25,12],
[65,41,25,13],
[65,41,25,14],
[65,41,25,16],
[65,41,25,17],
[65,41,25,19],
[65,41,25,20],
[65,41,27,0],
[65,41,27,1],
[65,41,27,2],
[65,41,27,3],
[65,41,27,4],
[65,41,27,5],
[65,41,27,6],
[65,41,27,7],
[65,41,27,8],
[65,41,27,11],
[65,41,27,12],
[65,41,27,13],
[65,41,27,14],
[65,41,27,16],
[65,41,27,17],
[65,41,28,0],
[65,41,28,1],
[65,41,28,2],
[65,41,28,3],
[65,41,28,4],
[65,41,28,5],
[65,41,28,6],
[65,41,28,8],
[65,41,28,10],
[65,41,28,11],
[65,41,28,12],
[65,41,28,13],
[65,41,28,14],
[65,41,28,16],
[65,41,28,17],
[65,41,29,0],
[65,41,29,1],
[65,41,29,2],
[65,41,29,3],
[65,41,29,4],
[65,41,29,5],
[65,41,29,6],
[65,41,29,7],
[65,41,29,8],
[65,41,29,10],
[65,41,29,13],
[65,41,29,14],
[65,41,32,0],
[65,41,32,1],
[65,41,32,2],
[65,41,32,3],
[65,41,32,4],
[65,41,32,5],
[65,41,32,6],
[65,41,32,7],
[65,41,32,8],
[65,41,32,10],
[65,41,33,0],
[65,41,33,1],
[65,41,33,2],
[65,41,33,3],
[65,41,33,4],
[65,41,33,5],
[65,41,33,6],
[65,41,33,7],
[65,41,33,8],
[65,41,34,0],
[65,41,34,1],
[65,41,34,2],
[65,41,34,3],
[65,41,34,4],
[65,41,34,5],
[65,41,34,6],
[65,41,36,0],
[65,41,36,1],
[65,41,36,2],
[65,41,36,3],
[65,41,36,4],
[65,41,37,0],
[65,41,37,1],
[65,41,37,2],
[65,42,3,0],
[65,42,3,1],
[65,42,3,2],
[65,42,4,0],
[65,42,4,1],
[65,42,4,2],
[65,42,4,3],
[65,42,5,0],
[65,42,5,1],
[65,42,5,2],
[65,42,5,4],
[65,42,6,0],
[65,42,6,1],
[65,42,6,2],
[65,42,6,4],
[65,42,7,0],
[65,42,7,1],
[65,42,7,2],
[65,42,7,3],
[65,42,7,4],
[65,42,7,5],
[65,42,7,6],
[65,42,8,0],
[65,42,8,1],
[65,42,8,2],
[65,42,8,4],
[65,42,8,7],
[65,42,9,0],
[65,42,9,1],
[65,42,9,2],
[65,42,9,3],
[65,42,9,4],
[65,42,9,5],
[65,42,9,6],
[65,42,9,7],
[65,42,9,8],
[65,42,11,0],
[65,42,11,1],
[65,42,11,2],
[65,42,11,3],
[65,42,11,4],
[65,42,11,5],
[65,42,11,6],
[65,42,11,7],
[65,42,11,8],
[65,42,11,9],
[65,42,12,0],
[65,42,12,1],
[65,42,12,2],
[65,42,12,3],
[65,42,12,4],
[65,42,12,5],
[65,42,12,6],
[65,42,12,7],
[65,42,12,8],
[65,42,12,9],
[65,42,13,0],
[65,42,13,1],
[65,42,13,2],
[65,42,13,3],
[65,42,13,4],
[65,42,13,5],
[65,42,13,6],
[65,42,13,7],
[65,42,13,8],
[65,42,13,9],
[65,42,13,11],
[65,42,13,12],
[65,42,14,0],
[65,42,14,1],
[65,42,14,2],
[65,42,14,3],
[65,42,14,4],
[65,42,14,5],
[65,42,14,6],
[65,42,14,7],
[65,42,14,8],
[65,42,14,9],
[65,42,14,11],
[65,42,14,12],
[65,42,15,0],
[65,42,15,1],
[65,42,15,2],
[65,42,15,3],
[65,42,15,4],
[65,42,15,5],
[65,42,15,6],
[65,42,15,7],
[65,42,15,8],
[65,42,15,11],
[65,42,15,12],
[65,42,15,13],
[65,42,15,14],
[65,42,16,0],
[65,42,16,1],
[65,42,16,2],
[65,42,16,3],
[65,42,16,4],
[65,42,16,5],
[65,42,16,6],
[65,42,16,7],
[65,42,16,8],
[65,42,16,9],
[65,42,16,11],
[65,42,16,12],
[65,42,16,13],
[65,42,16,15],
[65,42,17,0],
[65,42,17,1],
[65,42,17,2],
[65,42,17,3],
[65,42,17,4],
[65,42,17,5],
[65,42,17,6],
[65,42,17,7],
[65,42,17,8],
[65,42,17,9],
[65,42,17,11],
[65,42,17,12],
[65,42,17,13],
[65,42,17,15],
[65,42,17,16],
[65,42,18,0],
[65,42,18,1],
[65,42,18,2],
[65,42,18,3],
[65,42,18,4],
[65,42,18,5],
[65,42,18,6],
[65,42,18,7],
[65,42,18,8],
[65,42,18,11],
[65,42,18,14],
[65,42,18,16],
[65,42,18,17],
[65,42,19,0],
[65,42,19,1],
[65,42,19,2],
[65,42,19,3],
[65,42,19,4],
[65,42,19,5],
[65,42,19,6],
[65,42,19,7],
[65,42,19,8],
[65,42,19,9],
[65,42,19,12],
[65,42,19,15],
[65,42,19,16],
[65,42,19,17],
[65,42,19,18],
[65,42,21,0],
[65,42,21,1],
[65,42,21,2],
[65,42,21,4],
[65,42,21,9],
[65,42,21,11],
[65,42,21,12],
[65,42,21,13],
[65,42,21,14],
[65,42,21,15],
[65,42,21,16],
[65,42,21,17],
[65,42,21,18],
[65,42,21,19],
[65,42,24,0],
[65,42,24,1],
[65,42,24,2],
[65,42,24,5],
[65,42,24,6],
[65,42,24,7],
[65,42,24,8],
[65,42,24,9],
[65,42,24,11],
[65,42,24,12],
[65,42,24,13],
[65,42,24,14],
[65,42,24,15],
[65,42,24,17],
[65,42,24,18],
[65,42,24,19],
[65,42,24,21],
[65,42,25,0],
[65,42,25,1],
[65,42,25,2],
[65,42,25,3],
[65,42,25,4],
[65,42,25,5],
[65,42,25,6],
[65,42,25,8],
[65,42,25,9],
[65,42,25,11],
[65,42,25,12],
[65,42,25,13],
[65,42,25,14],
[65,42,25,15],
[65,42,25,16],
[65,42,25,17],
[65,42,25,18],
[65,42,25,19],
[65,42,26,0],
[65,42,26,1],
[65,42,26,2],
[65,42,26,3],
[65,42,26,4],
[65,42,26,5],
[65,42,26,6],
[65,42,26,7],
[65,42,26,8],
[65,42,26,11],
[65,42,26,12],
[65,42,26,13],
[65,42,26,14],
[65,42,26,16],
[65,42,26,17],
[65,42,28,0],
[65,42,28,1],
[65,42,28,2],
[65,42,28,3],
[65,42,28,4],
[65,42,28,5],
[65,42,28,6],
[65,42,28,8],
[65,42,28,9],
[65,42,28,11],
[65,42,28,12],
[65,42,28,13],
[65,42,28,14],
[65,42,28,15],
[65,42,29,0],
[65,42,29,1],
[65,42,29,2],
[65,42,29,3],
[65,42,29,4],
[65,42,29,5],
[65,42,29,6],
[65,42,29,7],
[65,42,29,8],
[65,42,29,9],
[65,42,29,13],
[65,42,29,14],
[65,42,32,0],
[65,42,32,1],
[65,42,32,2],
[65,42,32,3],
[65,42,32,4],
[65,42,32,5],
[65,42,32,6],
[65,42,32,7],
[65,42,32,8],
[65,42,32,9],
[65,42,33,0],
[65,42,33,1],
[65,42,33,2],
[65,42,33,3],
[65,42,33,4],
[65,42,33,5],
[65,42,33,6],
[65,42,33,7],
[65,42,34,0],
[65,42,34,1],
[65,42,34,2],
[65,42,34,3],
[65,42,34,4],
[65,42,34,5],
[65,42,35,0],
[65,42,35,1],
[65,42,35,2],
[65,42,35,3],
[65,42,35,4],
[65,42,36,0],
[65,42,36,1],
[65,42,36,2],
[65,43,3,0],
[65,43,3,1],
[65,43,3,2],
[65,43,4,0],
[65,43,4,1],
[65,43,4,2],
[65,43,4,3],
[65,43,5,0],
[65,43,5,1],
[65,43,5,2],
[65,43,5,4],
[65,43,6,0],
[65,43,6,1],
[65,43,6,2],
[65,43,6,4],
[65,43,8,0],
[65,43,8,1],
[65,43,8,2],
[65,43,8,4],
[65,43,9,0],
[65,43,9,1],
[65,43,9,2],
[65,43,9,3],
[65,43,9,4],
[65,43,9,5],
[65,43,9,6],
[65,43,9,8],
[65,43,10,0],
[65,43,10,1],
[65,43,10,2],
[65,43,10,3],
[65,43,10,4],
[65,43,10,5],
[65,43,10,6],
[65,43,10,8],
[65,43,10,9],
[65,43,11,0],
[65,43,11,1],
[65,43,11,2],
[65,43,11,3],
[65,43,11,4],
[65,43,11,5],
[65,43,11,6],
[65,43,11,8],
[65,43,11,9],
[65,43,11,10],
[65,43,12,0],
[65,43,12,1],
[65,43,12,2],
[65,43,12,3],
[65,43,12,4],
[65,43,12,5],
[65,43,12,6],
[65,43,12,8],
[65,43,12,9],
[65,43,12,10],
[65,43,13,0],
[65,43,13,1],
[65,43,13,2],
[65,43,13,3],
[65,43,13,4],
[65,43,13,5],
[65,43,13,6],
[65,43,13,8],
[65,43,13,9],
[65,43,13,10],
[65,43,13,11],
[65,43,13,12],
[65,43,14,0],
[65,43,14,1],
[65,43,14,2],
[65,43,14,3],
[65,43,14,4],
[65,43,14,5],
[65,43,14,6],
[65,43,14,8],
[65,43,14,9],
[65,43,14,10],
[65,43,14,11],
[65,43,14,12],
[65,43,15,0],
[65,43,15,1],
[65,43,15,2],
[65,43,15,3],
[65,43,15,4],
[65,43,15,5],
[65,43,15,6],
[65,43,15,8],
[65,43,15,10],
[65,43,15,11],
[65,43,15,12],
[65,43,15,13],
[65,43,16,0],
[65,43,16,1],
[65,43,16,2],
[65,43,16,3],
[65,43,16,4],
[65,43,16,5],
[65,43,16,6],
[65,43,16,8],
[65,43,16,9],
[65,43,16,10],
[65,43,16,11],
[65,43,16,14],
[65,43,16,15],
[65,43,17,0],
[65,43,17,1],
[65,43,17,2],
[65,43,17,3],
[65,43,17,4],
[65,43,17,5],
[65,43,17,6],
[65,43,17,8],
[65,43,17,9],
[65,43,17,10],
[65,43,17,11],
[65,43,17,14],
[65,43,17,15],
[65,43,17,16],
[65,43,18,0],
[65,43,18,1],
[65,43,18,2],
[65,43,18,3],
[65,43,18,4],
[65,43,18,5],
[65,43,18,6],
[65,43,18,8],
[65,43,18,12],
[65,43,18,13],
[65,43,18,14],
[65,43,18,16],
[65,43,18,17],
[65,43,19,0],
[65,43,19,1],
[65,43,19,2],
[65,43,19,3],
[65,43,19,4],
[65,43,19,5],
[65,43,19,6],
[65,43,19,10],
[65,43,19,11],
[65,43,19,12],
[65,43,19,15],
[65,43,19,16],
[65,43,19,17],
[65,43,19,18],
[65,43,20,0],
[65,43,20,1],
[65,43,20,2],
[65,43,20,3],
[65,43,20,4],
[65,43,20,5],
[65,43,20,8],
[65,43,20,9],
[65,43,20,11],
[65,43,20,12],
[65,43,20,13],
[65,43,20,14],
[65,43,20,15],
[65,43,20,16],
[65,43,20,17],
[65,43,20,18],
[65,43,20,19],
[65,43,21,0],
[65,43,21,1],
[65,43,21,2],
[65,43,21,4],
[65,43,21,9],
[65,43,21,10],
[65,43,21,11],
[65,43,21,12],
[65,43,21,13],
[65,43,21,14],
[65,43,21,15],
[65,43,21,16],
[65,43,21,17],
[65,43,21,18],
[65,43,21,19],
[65,43,21,20],
[65,43,22,0],
[65,43,22,1],
[65,43,22,2],
[65,43,22,5],
[65,43,22,6],
[65,43,22,8],
[65,43,22,9],
[65,43,22,11],
[65,43,22,12],
[65,43,22,13],
[65,43,22,14],
[65,43,22,15],
[65,43,22,16],
[65,43,22,17],
[65,43,22,18],
[65,43,22,19],
[65,43,22,21],
[65,43,24,3],
[65,43,24,4],
[65,43,24,5],
[65,43,24,6],
[65,43,24,8],
[65,43,24,9],
[65,43,24,10],
[65,43,24,11],
[65,43,24,12],
[65,43,24,13],
[65,43,24,14],
[65,43,24,15],
[65,43,24,17],
[65,43,24,18],
[65,43,24,19],
[65,43,24,20],
[65,43,26,0],
[65,43,26,1],
[65,43,26,2],
[65,43,26,3],
[65,43,26,4],
[65,43,26,5],
[65,43,26,6],
[65,43,26,8],
[65,43,26,10],
[65,43,26,11],
[65,43,26,12],
[65,43,26,13],
[65,43,26,14],
[65,43,26,16],
[65,43,26,17],
[65,43,27,0],
[65,43,27,1],
[65,43,27,2],
[65,43,27,3],
[65,43,27,4],
[65,43,27,5],
[65,43,27,6],
[65,43,27,8],
[65,43,27,9],
[65,43,27,11],
[65,43,27,12],
[65,43,27,13],
[65,43,27,14],
[65,43,27,15],
[65,43,29,0],
[65,43,29,1],
[65,43,29,2],
[65,43,29,3],
[65,43,29,4],
[65,43,29,5],
[65,43,29,6],
[65,43,29,8],
[65,43,29,9],
[65,43,29,10],
[65,43,29,13],
[65,43,32,0],
[65,43,32,1],
[65,43,32,2],
[65,43,32,3],
[65,43,32,4],
[65,43,32,5],
[65,43,32,6],
[65,43,33,0],
[65,43,33,1],
[65,43,33,2],
[65,43,33,3],
[65,43,33,4],
[65,43,33,5],
[65,43,35,0],
[65,43,35,1],
[65,43,35,2],
[65,44,3,0],
[65,44,3,1],
[65,44,3,2],
[65,44,4,0],
[65,44,4,1],
[65,44,4,2],
[65,44,4,3],
[65,44,5,0],
[65,44,5,1],
[65,44,5,2],
[65,44,5,4],
[65,44,6,0],
[65,44,6,1],
[65,44,6,2],
[65,44,6,4],
[65,44,7,0],
[65,44,7,1],
[65,44,7,2],
[65,44,7,3],
[65,44,7,4],
[65,44,7,5],
[65,44,7,6],
[65,44,8,0],
[65,44,8,1],
[65,44,8,2],
[65,44,8,4],
[65,44,8,7],
[65,44,9,0],
[65,44,9,1],
[65,44,9,2],
[65,44,9,3],
[65,44,9,4],
[65,44,9,5],
[65,44,9,6],
[65,44,9,7],
[65,44,9,8],
[65,44,10,0],
[65,44,10,1],
[65,44,10,2],
[65,44,10,3],
[65,44,10,4],
[65,44,10,5],
[65,44,10,6],
[65,44,10,7],
[65,44,10,8],
[65,44,10,9],
[65,44,11,0],
[65,44,11,1],
[65,44,11,2],
[65,44,11,3],
[65,44,11,4],
[65,44,11,5],
[65,44,11,6],
[65,44,11,7],
[65,44,11,8],
[65,44,11,9],
[65,44,11,10],
[65,44,12,0],
[65,44,12,1],
[65,44,12,2],
[65,44,12,3],
[65,44,12,4],
[65,44,12,5],
[65,44,12,6],
[65,44,12,7],
[65,44,12,8],
[65,44,12,9],
[65,44,12,10],
[65,44,13,0],
[65,44,13,1],
[65,44,13,2],
[65,44,13,3],
[65,44,13,4],
[65,44,13,5],
[65,44,13,6],
[65,44,13,7],
[65,44,13,8],
[65,44,13,9],
[65,44,13,10],
[65,44,13,11],
[65,44,13,12],
[65,44,14,0],
[65,44,14,1],
[65,44,14,2],
[65,44,14,3],
[65,44,14,4],
[65,44,14,5],
[65,44,14,6],
[65,44,14,7],
[65,44,14,8],
[65,44,14,9],
[65,44,14,10],
[65,44,14,11],
[65,44,14,12],
[65,44,15,0],
[65,44,15,1],
[65,44,15,2],
[65,44,15,3],
[65,44,15,4],
[65,44,15,5],
[65,44,15,6],
[65,44,15,7],
[65,44,15,8],
[65,44,15,10],
[65,44,15,11],
[65,44,15,14],
[65,44,16,0],
[65,44,16,1],
[65,44,16,2],
[65,44,16,3],
[65,44,16,4],
[65,44,16,5],
[65,44,16,6],
[65,44,16,7],
[65,44,16,8],
[65,44,16,9],
[65,44,16,12],
[65,44,16,13],
[65,44,16,14],
[65,44,16,15],
[65,44,18,0],
[65,44,18,1],
[65,44,18,2],
[65,44,18,3],
[65,44,18,4],
[65,44,18,5],
[65,44,18,6],
[65,44,18,7],
[65,44,18,10],
[65,44,18,11],
[65,44,18,12],
[65,44,18,13],
[65,44,18,14],
[65,44,18,16],
[65,44,19,0],
[65,44,19,1],
[65,44,19,2],
[65,44,19,3],
[65,44,19,4],
[65,44,19,5],
[65,44,19,8],
[65,44,19,9],
[65,44,19,10],
[65,44,19,11],
[65,44,19,12],
[65,44,19,15],
[65,44,19,16],
[65,44,19,18],
[65,44,20,0],
[65,44,20,1],
[65,44,20,2],
[65,44,20,3],
[65,44,20,4],
[65,44,20,6],
[65,44,20,7],
[65,44,20,8],
[65,44,20,9],
[65,44,20,11],
[65,44,20,12],
[65,44,20,13],
[65,44,20,14],
[65,44,20,15],
[65,44,20,16],
[65,44,20,18],
[65,44,20,19],
[65,44,21,0],
[65,44,21,1],
[65,44,21,2],
[65,44,21,7],
[65,44,21,9],
[65,44,21,10],
[65,44,21,11],
[65,44,21,12],
[65,44,21,13],
[65,44,21,14],
[65,44,21,15],
[65,44,21,16],
[65,44,21,18],
[65,44,21,19],
[65,44,21,20],
[65,44,22,3],
[65,44,22,4],
[65,44,22,5],
[65,44,22,6],
[65,44,22,7],
[65,44,22,8],
[65,44,22,9],
[65,44,22,11],
[65,44,22,12],
[65,44,22,13],
[65,44,22,14],
[65,44,22,15],
[65,44,22,16],
[65,44,22,18],
[65,44,22,19],
[65,44,24,0],
[65,44,24,1],
[65,44,24,2],
[65,44,24,3],
[65,44,24,4],
[65,44,24,5],
[65,44,24,6],
[65,44,24,7],
[65,44,24,8],
[65,44,24,9],
[65,44,24,10],
[65,44,24,11],
[65,44,24,12],
[65,44,24,13],
[65,44,24,14],
[65,44,24,15],
[65,44,24,18],
[65,44,24,19],
[65,44,25,0],
[65,44,25,1],
[65,44,25,2],
[65,44,25,3],
[65,44,25,4],
[65,44,25,5],
[65,44,25,6],
[65,44,25,8],
[65,44,25,9],
[65,44,25,10],
[65,44,25,11],
[65,44,25,12],
[65,44,25,13],
[65,44,25,14],
[65,44,25,15],
[65,44,25,16],
[65,44,26,0],
[65,44,26,1],
[65,44,26,2],
[65,44,26,3],
[65,44,26,4],
[65,44,26,5],
[65,44,26,6],
[65,44,26,7],
[65,44,26,8],
[65,44,26,10],
[65,44,26,11],
[65,44,26,12],
[65,44,26,13],
[65,44,26,14],
[65,44,27,0],
[65,44,27,1],
[65,44,27,2],
[65,44,27,3],
[65,44,27,4],
[65,44,27,5],
[65,44,27,6],
[65,44,27,7],
[65,44,27,8],
[65,44,27,9],
[65,44,27,11],
[65,44,27,12],
[65,44,27,13],
[65,44,27,14],
[65,44,28,0],
[65,44,28,1],
[65,44,28,2],
[65,44,28,3],
[65,44,28,4],
[65,44,28,5],
[65,44,28,6],
[65,44,28,8],
[65,44,28,9],
[65,44,28,10],
[65,44,28,11],
[65,44,28,12],
[65,44,28,13],
[65,44,29,0],
[65,44,29,1],
[65,44,29,2],
[65,44,29,3],
[65,44,29,4],
[65,44,29,5],
[65,44,29,6],
[65,44,29,7],
[65,44,29,8],
[65,44,29,9],
[65,44,29,10],
[65,44,32,0],
[65,44,32,1],
[65,44,32,2],
[65,44,32,3],
[65,44,32,4],
[65,44,32,5],
[65,44,33,0],
[65,44,33,1],
[65,44,33,2],
[65,44,33,3],
[65,44,33,4],
[65,44,34,0],
[65,44,34,1],
[65,44,34,2],
[65,45,3,0],
[65,45,3,1],
[65,45,3,2],
[65,45,4,0],
[65,45,4,1],
[65,45,4,2],
[65,45,4,3],
[65,45,5,0],
[65,45,5,1],
[65,45,5,2],
[65,45,5,4],
[65,45,6,0],
[65,45,6,1],
[65,45,6,2],
[65,45,6,4],
[65,45,7,0],
[65,45,7,1],
[65,45,7,2],
[65,45,7,3],
[65,45,7,4],
[65,45,7,5],
[65,45,7,6],
[65,45,8,0],
[65,45,8,1],
[65,45,8,2],
[65,45,8,4],
[65,45,8,7],
[65,45,10,0],
[65,45,10,1],
[65,45,10,2],
[65,45,10,3],
[65,45,10,4],
[65,45,10,5],
[65,45,10,6],
[65,45,10,7],
[65,45,10,8],
[65,45,11,0],
[65,45,11,1],
[65,45,11,2],
[65,45,11,3],
[65,45,11,4],
[65,45,11,5],
[65,45,11,6],
[65,45,11,7],
[65,45,11,8],
[65,45,11,10],
[65,45,12,0],
[65,45,12,1],
[65,45,12,2],
[65,45,12,3],
[65,45,12,4],
[65,45,12,5],
[65,45,12,6],
[65,45,12,7],
[65,45,12,8],
[65,45,12,10],
[65,45,13,0],
[65,45,13,1],
[65,45,13,2],
[65,45,13,3],
[65,45,13,4],
[65,45,13,5],
[65,45,13,6],
[65,45,13,7],
[65,45,13,8],
[65,45,13,10],
[65,45,13,11],
[65,45,13,12],
[65,45,14,0],
[65,45,14,1],
[65,45,14,2],
[65,45,14,3],
[65,45,14,4],
[65,45,14,5],
[65,45,14,6],
[65,45,14,7],
[65,45,14,8],
[65,45,14,10],
[65,45,14,11],
[65,45,16,0],
[65,45,16,1],
[65,45,16,2],
[65,45,16,3],
[65,45,16,4],
[65,45,16,5],
[65,45,16,6],
[65,45,16,7],
[65,45,16,10],
[65,45,16,11],
[65,45,16,12],
[65,45,16,13],
[65,45,16,14],
[65,45,17,0],
[65,45,17,1],
[65,45,17,2],
[65,45,17,3],
[65,45,17,4],
[65,45,17,5],
[65,45,17,6],
[65,45,17,7],
[65,45,17,10],
[65,45,17,11],
[65,45,17,12],
[65,45,17,13],
[65,45,17,14],
[65,45,17,16],
[65,45,19,0],
[65,45,19,1],
[65,45,19,2],
[65,45,19,3],
[65,45,19,4],
[65,45,19,6],
[65,45,19,7],
[65,45,19,8],
[65,45,19,10],
[65,45,19,11],
[65,45,19,12],
[65,45,19,16],
[65,45,19,17],
[65,45,20,0],
[65,45,20,1],
[65,45,20,2],
[65,45,20,5],
[65,45,20,6],
[65,45,20,7],
[65,45,20,8],
[65,45,20,11],
[65,45,20,12],
[65,45,20,13],
[65,45,20,14],
[65,45,20,16],
[65,45,20,17],
[65,45,20,19],
[65,45,21,4],
[65,45,21,7],
[65,45,21,10],
[65,45,21,11],
[65,45,21,12],
[65,45,21,13],
[65,45,21,14],
[65,45,21,16],
[65,45,21,17],
[65,45,21,19],
[65,45,21,20],
[65,45,22,0],
[65,45,22,1],
[65,45,22,2],
[65,45,22,3],
[65,45,22,4],
[65,45,22,5],
[65,45,22,6],
[65,45,22,7],
[65,45,22,8],
[65,45,22,11],
[65,45,22,12],
[65,45,22,13],
[65,45,22,14],
[65,45,22,16],
[65,45,22,17],
[65,45,22,19],
[65,45,24,0],
[65,45,24,1],
[65,45,24,2],
[65,45,24,3],
[65,45,24,4],
[65,45,24,5],
[65,45,24,6],
[65,45,24,7],
[65,45,24,8],
[65,45,24,10],
[65,45,24,11],
[65,45,24,12],
[65,45,24,13],
[65,45,24,14],
[65,45,24,17],
[65,45,25,0],
[65,45,25,1],
[65,45,25,2],
[65,45,25,3],
[65,45,25,4],
[65,45,25,5],
[65,45,25,6],
[65,45,25,8],
[65,45,25,10],
[65,45,25,11],
[65,45,25,12],
[65,45,25,13],
[65,45,25,14],
[65,45,27,0],
[65,45,27,1],
[65,45,27,2],
[65,45,27,3],
[65,45,27,4],
[65,45,27,5],
[65,45,27,6],
[65,45,27,7],
[65,45,27,8],
[65,45,27,11],
[65,45,27,12],
[65,45,27,13],
[65,45,28,0],
[65,45,28,1],
[65,45,28,2],
[65,45,28,3],
[65,45,28,4],
[65,45,28,5],
[65,45,28,6],
[65,45,28,8],
[65,45,28,10],
[65,45,28,11],
[65,45,29,0],
[65,45,29,1],
[65,45,29,2],
[65,45,29,3],
[65,45,29,4],
[65,45,29,5],
[65,45,29,6],
[65,45,29,7],
[65,45,29,8],
[65,45,32,0],
[65,45,32,1],
[65,45,32,2],
[65,45,32,3],
[65,45,32,4],
[65,45,33,0],
[65,45,33,1],
[65,45,33,2],
[65,46,3,0],
[65,46,3,1],
[65,46,3,2],
[65,46,4,0],
[65,46,4,1],
[65,46,4,2],
[65,46,4,3],
[65,46,5,0],
[65,46,5,1],
[65,46,5,2],
[65,46,5,4],
[65,46,6,0],
[65,46,6,1],
[65,46,6,2],
[65,46,6,4],
[65,46,7,0],
[65,46,7,1],
[65,46,7,2],
[65,46,7,3],
[65,46,7,4],
[65,46,7,5],
[65,46,7,6],
[65,46,8,0],
[65,46,8,1],
[65,46,8,2],
[65,46,8,4],
[65,46,8,7],
[65,46,10,0],
[65,46,10,1],
[65,46,10,2],
[65,46,10,3],
[65,46,10,4],
[65,46,10,5],
[65,46,10,6],
[65,46,10,7],
[65,46,10,8],
[65,46,11,0],
[65,46,11,1],
[65,46,11,2],
[65,46,11,3],
[65,46,11,4],
[65,46,11,5],
[65,46,11,6],
[65,46,11,7],
[65,46,11,8],
[65,46,11,10],
[65,46,12,0],
[65,46,12,1],
[65,46,12,2],
[65,46,12,3],
[65,46,12,4],
[65,46,12,5],
[65,46,12,6],
[65,46,12,7],
[65,46,12,8],
[65,46,12,10],
[65,46,13,0],
[65,46,13,1],
[65,46,13,2],
[65,46,13,3],
[65,46,13,4],
[65,46,13,5],
[65,46,13,6],
[65,46,13,7],
[65,46,13,8],
[65,46,13,10],
[65,46,13,11],
[65,46,14,0],
[65,46,14,1],
[65,46,14,2],
[65,46,14,3],
[65,46,14,4],
[65,46,14,5],
[65,46,14,6],
[65,46,14,7],
[65,46,14,8],
[65,46,14,12],
[65,46,16,0],
[65,46,16,1],
[65,46,16,2],
[65,46,16,3],
[65,46,16,4],
[65,46,16,5],
[65,46,16,8],
[65,46,16,10],
[65,46,16,11],
[65,46,16,12],
[65,46,16,13],
[65,46,16,14],
[65,46,17,0],
[65,46,17,1],
[65,46,17,2],
[65,46,17,3],
[65,46,17,4],
[65,46,17,5],
[65,46,17,8],
[65,46,17,10],
[65,46,17,11],
[65,46,17,12],
[65,46,17,13],
[65,46,17,14],
[65,46,17,16],
[65,46,19,0],
[65,46,19,1],
[65,46,19,2],
[65,46,19,5],
[65,46,19,6],
[65,46,19,7],
[65,46,19,8],
[65,46,19,10],
[65,46,19,11],
[65,46,19,12],
[65,46,19,16],
[65,46,19,17],
[65,46,20,3],
[65,46,20,4],
[65,46,20,5],
[65,46,20,6],
[65,46,20,7],
[65,46,20,8],
[65,46,20,11],
[65,46,20,12],
[65,46,20,13],
[65,46,20,14],
[65,46,20,16],
[65,46,20,17],
[65,46,20,19],
[65,46,21,0],
[65,46,21,1],
[65,46,21,2],
[65,46,21,4],
[65,46,21,7],
[65,46,21,10],
[65,46,21,11],
[65,46,21,12],
[65,46,21,13],
[65,46,21,14],
[65,46,21,16],
[65,46,21,17],
[65,46,21,19],
[65,46,22,0],
[65,46,22,1],
[65,46,22,2],
[65,46,22,3],
[65,46,22,4],
[65,46,22,5],
[65,46,22,6],
[65,46,22,7],
[65,46,22,8],
[65,46,22,11],
[65,46,22,12],
[65,46,22,13],
[65,46,22,14],
[65,46,22,16],
[65,46,22,17],
[65,46,24,0],
[65,46,24,1],
[65,46,24,2],
[65,46,24,3],
[65,46,24,4],
[65,46,24,5],
[65,46,24,6],
[65,46,24,7],
[65,46,24,8],
[65,46,24,10],
[65,46,24,11],
[65,46,24,12],
[65,46,24,13],
[65,46,24,14],
[65,46,24,17],
[65,46,25,0],
[65,46,25,1],
[65,46,25,2],
[65,46,25,3],
[65,46,25,4],
[65,46,25,5],
[65,46,25,6],
[65,46,25,8],
[65,46,25,10],
[65,46,25,11],
[65,46,25,12],
[65,46,25,13],
[65,46,25,14],
[65,46,27,0],
[65,46,27,1],
[65,46,27,2],
[65,46,27,3],
[65,46,27,4],
[65,46,27,5],
[65,46,27,6],
[65,46,27,7],
[65,46,27,8],
[65,46,27,11],
[65,46,28,0],
[65,46,28,1],
[65,46,28,2],
[65,46,28,3],
[65,46,28,4],
[65,46,28,5],
[65,46,28,6],
[65,46,28,8],
[65,46,29,0],
[65,46,29,1],
[65,46,29,2],
[65,46,29,3],
[65,46,29,4],
[65,46,29,5],
[65,46,29,6],
[65,46,29,7],
[65,46,32,0],
[65,46,32,1],
[65,46,32,2],
[65,48,3,0],
[65,48,3,1],
[65,48,3,2],
[65,48,4,0],
[65,48,4,1],
[65,48,4,2],
[65,48,4,3],
[65,48,5,0],
[65,48,5,1],
[65,48,5,2],
[65,48,5,4],
[65,48,6,0],
[65,48,6,1],
[65,48,6,2],
[65,48,6,4],
[65,48,7,0],
[65,48,7,1],
[65,48,7,2],
[65,48,7,3],
[65,48,7,4],
[65,48,7,5],
[65,48,7,6],
[65,48,8,0],
[65,48,8,1],
[65,48,8,2],
[65,48,8,4],
[65,48,8,7],
[65,48,9,0],
[65,48,9,1],
[65,48,9,2],
[65,48,9,3],
[65,48,9,4],
[65,48,9,5],
[65,48,9,6],
[65,48,9,7],
[65,48,9,8],
[65,48,10,0],
[65,48,10,1],
[65,48,10,2],
[65,48,10,3],
[65,48,10,4],
[65,48,10,5],
[65,48,10,6],
[65,48,10,7],
[65,48,10,8],
[65,48,10,9],
[65,48,13,0],
[65,48,13,1],
[65,48,13,2],
[65,48,13,3],
[65,48,13,4],
[65,48,13,5],
[65,48,13,6],
[65,48,13,7],
[65,48,13,10],
[65,48,14,0],
[65,48,14,1],
[65,48,14,2],
[65,48,14,3],
[65,48,14,4],
[65,48,14,5],
[65,48,14,8],
[65,48,14,9],
[65,48,14,10],
[65,48,15,0],
[65,48,15,1],
[65,48,15,2],
[65,48,15,3],
[65,48,15,4],
[65,48,15,6],
[65,48,15,7],
[65,48,15,8],
[65,48,15,10],
[65,48,15,13],
[65,48,15,14],
[65,48,16,0],
[65,48,16,1],
[65,48,16,2],
[65,48,16,5],
[65,48,16,6],
[65,48,16,7],
[65,48,16,8],
[65,48,16,9],
[65,48,16,10],
[65,48,16,13],
[65,48,16,14],
[65,48,16,15],
[65,48,17,0],
[65,48,17,1],
[65,48,17,2],
[65,48,17,5],
[65,48,17,6],
[65,48,17,7],
[65,48,17,8],
[65,48,17,9],
[65,48,17,10],
[65,48,17,13],
[65,48,17,14],
[65,48,17,15],
[65,48,17,16],
[65,48,18,3],
[65,48,18,4],
[65,48,18,5],
[65,48,18,6],
[65,48,18,7],
[65,48,18,8],
[65,48,18,10],
[65,48,18,13],
[65,48,18,14],
[65,48,18,16],
[65,48,18,17],
[65,48,19,0],
[65,48,19,1],
[65,48,19,2],
[65,48,19,3],
[65,48,19,4],
[65,48,19,5],
[65,48,19,6],
[65,48,19,7],
[65,48,19,8],
[65,48,19,9],
[65,48,19,10],
[65,48,19,15],
[65,48,19,16],
[65,48,19,17],
[65,48,19,18],
[65,48,20,0],
[65,48,20,1],
[65,48,20,2],
[65,48,20,3],
[65,48,20,4],
[65,48,20,5],
[65,48,20,6],
[65,48,20,7],
[65,48,20,8],
[65,48,20,9],
[65,48,20,13],
[65,48,20,14],
[65,48,20,15],
[65,48,20,16],
[65,48,20,17],
[65,48,20,18],
[65,48,21,0],
[65,48,21,1],
[65,48,21,2],
[65,48,21,4],
[65,48,21,7],
[65,48,21,9],
[65,48,21,10],
[65,48,21,13],
[65,48,21,14],
[65,48,21,15],
[65,48,21,16],
[65,48,21,17],
[65,48,22,0],
[65,48,22,1],
[65,48,22,2],
[65,48,22,3],
[65,48,22,4],
[65,48,22,5],
[65,48,22,6],
[65,48,22,7],
[65,48,22,8],
[65,48,22,9],
[65,48,22,13],
[65,48,22,14],
[65,48,22,15],
[65,48,24,0],
[65,48,24,1],
[65,48,24,2],
[65,48,24,3],
[65,48,24,4],
[65,48,24,5],
[65,48,24,6],
[65,48,24,7],
[65,48,24,8],
[65,48,24,9],
[65,48,24,10],
[65,48,24,13],
[65,48,24,14],
[65,48,25,0],
[65,48,25,1],
[65,48,25,2],
[65,48,25,3],
[65,48,25,4],
[65,48,25,5],
[65,48,25,6],
[65,48,25,8],
[65,48,25,9],
[65,48,25,10],
[65,48,26,0],
[65,48,26,1],
[65,48,26,2],
[65,48,26,3],
[65,48,26,4],
[65,48,26,5],
[65,48,26,6],
[65,48,26,7],
[65,48,26,8],
[65,48,27,0],
[65,48,27,1],
[65,48,27,2],
[65,48,27,3],
[65,48,27,4],
[65,48,27,5],
[65,48,27,6],
[65,48,27,7],
[65,48,28,0],
[65,48,28,1],
[65,48,28,2],
[65,48,28,3],
[65,48,28,4],
[65,48,28,5],
[65,49,3,0],
[65,49,3,1],
[65,49,3,2],
[65,49,4,0],
[65,49,4,1],
[65,49,4,2],
[65,49,4,3],
[65,49,5,0],
[65,49,5,1],
[65,49,5,2],
[65,49,5,4],
[65,49,6,0],
[65,49,6,1],
[65,49,6,2],
[65,49,6,4],
[65,49,7,0],
[65,49,7,1],
[65,49,7,2],
[65,49,7,3],
[65,49,7,4],
[65,49,7,5],
[65,49,7,6],
[65,49,8,0],
[65,49,8,1],
[65,49,8,2],
[65,49,8,4],
[65,49,8,7],
[65,49,10,0],
[65,49,10,1],
[65,49,10,2],
[65,49,10,3],
[65,49,10,4],
[65,49,10,5],
[65,49,10,6],
[65,49,10,7],
[65,49,10,8],
[65,49,11,0],
[65,49,11,1],
[65,49,11,2],
[65,49,11,3],
[65,49,11,4],
[65,49,11,5],
[65,49,11,6],
[65,49,11,7],
[65,49,11,8],
[65,49,12,0],
[65,49,12,1],
[65,49,12,2],
[65,49,12,3],
[65,49,12,4],
[65,49,12,5],
[65,49,12,6],
[65,49,12,7],
[65,49,12,10],
[65,49,13,0],
[65,49,13,1],
[65,49,13,2],
[65,49,13,3],
[65,49,13,4],
[65,49,13,5],
[65,49,13,6],
[65,49,13,7],
[65,49,13,10],
[65,49,13,11],
[65,49,13,12],
[65,49,14,0],
[65,49,14,1],
[65,49,14,2],
[65,49,14,3],
[65,49,14,4],
[65,49,14,5],
[65,49,14,8],
[65,49,14,10],
[65,49,14,11],
[65,49,14,12],
[65,49,16,0],
[65,49,16,1],
[65,49,16,2],
[65,49,16,5],
[65,49,16,6],
[65,49,16,7],
[65,49,16,8],
[65,49,16,10],
[65,49,16,11],
[65,49,16,12],
[65,49,16,13],
[65,49,16,14],
[65,49,17,0],
[65,49,17,1],
[65,49,17,2],
[65,49,17,5],
[65,49,17,6],
[65,49,17,7],
[65,49,17,8],
[65,49,17,10],
[65,49,17,11],
[65,49,17,12],
[65,49,17,13],
[65,49,17,14],
[65,49,17,16],
[65,49,19,0],
[65,49,19,1],
[65,49,19,2],
[65,49,19,3],
[65,49,19,4],
[65,49,19,5],
[65,49,19,6],
[65,49,19,7],
[65,49,19,8],
[65,49,19,10],
[65,49,19,11],
[65,49,19,12],
[65,49,19,16],
[65,49,19,17],
[65,49,20,0],
[65,49,20,1],
[65,49,20,2],
[65,49,20,3],
[65,49,20,4],
[65,49,20,5],
[65,49,20,6],
[65,49,20,7],
[65,49,20,8],
[65,49,20,11],
[65,49,20,12],
[65,49,20,13],
[65,49,20,14],
[65,49,20,16],
[65,49,20,17],
[65,49,21,0],
[65,49,21,1],
[65,49,21,2],
[65,49,21,4],
[65,49,21,7],
[65,49,21,10],
[65,49,21,11],
[65,49,21,12],
[65,49,21,13],
[65,49,21,14],
[65,49,21,16],
[65,49,21,17],
[65,49,22,0],
[65,49,22,1],
[65,49,22,2],
[65,49,22,3],
[65,49,22,4],
[65,49,22,5],
[65,49,22,6],
[65,49,22,7],
[65,49,22,8],
[65,49,22,11],
[65,49,22,12],
[65,49,22,13],
[65,49,22,14],
[65,49,24,0],
[65,49,24,1],
[65,49,24,2],
[65,49,24,3],
[65,49,24,4],
[65,49,24,5],
[65,49,24,6],
[65,49,24,7],
[65,49,24,8],
[65,49,24,10],
[65,49,24,11],
[65,49,24,12],
[65,49,24,13],
[65,49,24,14],
[65,49,25,0],
[65,49,25,1],
[65,49,25,2],
[65,49,25,3],
[65,49,25,4],
[65,49,25,5],
[65,49,25,6],
[65,49,25,8],
[65,49,25,10],
[65,49,25,11],
[65,49,27,0],
[65,49,27,1],
[65,49,27,2],
[65,49,27,3],
[65,49,27,4],
[65,49,27,5],
[65,49,27,6],
[65,49,27,7],
[65,49,28,0],
[65,49,28,1],
[65,49,28,2],
[65,49,28,3],
[65,49,28,4],
[65,49,28,5],
[65,49,29,0],
[65,49,29,1],
[65,49,29,2],
[65,49,29,3],
[65,49,29,4],
[65,50,3,0],
[65,50,3,1],
[65,50,3,2],
[65,50,4,0],
[65,50,4,1],
[65,50,4,2],
[65,50,4,3],
[65,50,5,0],
[65,50,5,1],
[65,50,5,2],
[65,50,5,4],
[65,50,6,0],
[65,50,6,1],
[65,50,6,2],
[65,50,6,4],
[65,50,7,0],
[65,50,7,1],
[65,50,7,2],
[65,50,7,3],
[65,50,7,4],
[65,50,7,5],
[65,50,7,6],
[65,50,8,0],
[65,50,8,1],
[65,50,8,2],
[65,50,8,4],
[65,50,8,7],
[65,50,10,0],
[65,50,10,1],
[65,50,10,2],
[65,50,10,3],
[65,50,10,4],
[65,50,10,5],
[65,50,10,6],
[65,50,10,7],
[65,50,10,8],
[65,50,11,0],
[65,50,11,1],
[65,50,11,2],
[65,50,11,3],
[65,50,11,4],
[65,50,11,5],
[65,50,11,6],
[65,50,11,7],
[65,50,11,8],
[65,50,12,0],
[65,50,12,1],
[65,50,12,2],
[65,50,12,3],
[65,50,12,4],
[65,50,12,5],
[65,50,12,6],
[65,50,12,7],
[65,50,12,10],
[65,50,13,0],
[65,50,13,1],
[65,50,13,2],
[65,50,13,3],
[65,50,13,4],
[65,50,13,5],
[65,50,13,6],
[65,50,13,7],
[65,50,13,10],
[65,50,13,11],
[65,50,13,12],
[65,50,14,0],
[65,50,14,1],
[65,50,14,2],
[65,50,14,3],
[65,50,14,4],
[65,50,14,5],
[65,50,14,8],
[65,50,14,10],
[65,50,14,11],
[65,50,14,12],
[65,50,16,0],
[65,50,16,1],
[65,50,16,2],
[65,50,16,5],
[65,50,16,6],
[65,50,16,7],
[65,50,16,8],
[65,50,16,10],
[65,50,16,11],
[65,50,16,12],
[65,50,16,13],
[65,50,16,14],
[65,50,17,0],
[65,50,17,1],
[65,50,17,2],
[65,50,17,5],
[65,50,17,6],
[65,50,17,7],
[65,50,17,8],
[65,50,17,10],
[65,50,17,11],
[65,50,17,12],
[65,50,17,13],
[65,50,17,14],
[65,50,17,16],
[65,50,19,0],
[65,50,19,1],
[65,50,19,2],
[65,50,19,3],
[65,50,19,4],
[65,50,19,5],
[65,50,19,6],
[65,50,19,7],
[65,50,19,8],
[65,50,19,10],
[65,50,19,11],
[65,50,19,12],
[65,50,19,16],
[65,50,19,17],
[65,50,20,0],
[65,50,20,1],
[65,50,20,2],
[65,50,20,3],
[65,50,20,4],
[65,50,20,5],
[65,50,20,6],
[65,50,20,7],
[65,50,20,8],
[65,50,20,11],
[65,50,20,12],
[65,50,20,13],
[65,50,20,14],
[65,50,20,16],
[65,50,20,17],
[65,50,21,0],
[65,50,21,1],
[65,50,21,2],
[65,50,21,4],
[65,50,21,7],
[65,50,21,10],
[65,50,21,11],
[65,50,21,12],
[65,50,21,13],
[65,50,21,14],
[65,50,21,16],
[65,50,21,17],
[65,50,22,0],
[65,50,22,1],
[65,50,22,2],
[65,50,22,3],
[65,50,22,4],
[65,50,22,5],
[65,50,22,6],
[65,50,22,7],
[65,50,22,8],
[65,50,22,11],
[65,50,22,12],
[65,50,22,13],
[65,50,22,14],
[65,50,24,0],
[65,50,24,1],
[65,50,24,2],
[65,50,24,3],
[65,50,24,4],
[65,50,24,5],
[65,50,24,6],
[65,50,24,7],
[65,50,24,8],
[65,50,24,10],
[65,50,24,11],
[65,50,24,12],
[65,50,24,13],
[65,50,24,14],
[65,50,25,0],
[65,50,25,1],
[65,50,25,2],
[65,50,25,3],
[65,50,25,4],
[65,50,25,5],
[65,50,25,6],
[65,50,25,8],
[65,50,25,10],
[65,50,25,11],
[65,50,27,0],
[65,50,27,1],
[65,50,27,2],
[65,50,27,3],
[65,50,27,4],
[65,50,27,5],
[65,50,27,6],
[65,50,27,7],
[65,50,28,0],
[65,50,28,1],
[65,50,28,2],
[65,50,28,3],
[65,50,28,4],
[65,50,28,5],
[65,50,29,0],
[65,50,29,1],
[65,50,29,2],
[65,50,29,3],
[65,50,29,4],
[65,51,4,0],
[65,51,4,1],
[65,51,4,2],
[65,51,7,0],
[65,51,7,1],
[65,51,7,2],
[65,51,7,4],
[65,51,9,0],
[65,51,9,1],
[65,51,9,2],
[65,51,9,4],
[65,51,9,7],
[65,51,10,0],
[65,51,10,1],
[65,51,10,2],
[65,51,10,4],
[65,51,10,7],
[65,51,10,9],
[65,51,11,0],
[65,51,11,1],
[65,51,11,2],
[65,51,11,4],
[65,51,11,7],
[65,51,11,9],
[65,51,12,0],
[65,51,12,1],
[65,51,12,2],
[65,51,12,4],
[65,51,12,7],
[65,51,12,10],
[65,51,13,0],
[65,51,13,1],
[65,51,13,2],
[65,51,13,4],
[65,51,13,7],
[65,51,13,10],
[65,51,13,11],
[65,51,13,12],
[65,51,14,0],
[65,51,14,1],
[65,51,14,2],
[65,51,14,4],
[65,51,14,9],
[65,51,14,10],
[65,51,14,11],
[65,51,14,12],
[65,51,15,0],
[65,51,15,1],
[65,51,15,2],
[65,51,15,4],
[65,51,15,7],
[65,51,15,10],
[65,51,15,11],
[65,51,15,12],
[65,51,15,13],
[65,51,15,14],
[65,51,16,0],
[65,51,16,1],
[65,51,16,2],
[65,51,16,7],
[65,51,16,9],
[65,51,16,10],
[65,51,16,11],
[65,51,16,12],
[65,51,16,13],
[65,51,16,14],
[65,51,16,15],
[65,51,17,0],
[65,51,17,1],
[65,51,17,2],
[65,51,17,7],
[65,51,17,9],
[65,51,17,10],
[65,51,17,11],
[65,51,17,12],
[65,51,17,13],
[65,51,17,14],
[65,51,17,15],
[65,51,17,16],
[65,51,18,4],
[65,51,18,7],
[65,51,18,10],
[65,51,18,11],
[65,51,18,12],
[65,51,18,13],
[65,51,18,14],
[65,51,18,16],
[65,51,18,17],
[65,51,19,0],
[65,51,19,1],
[65,51,19,2],
[65,51,19,4],
[65,51,19,7],
[65,51,19,9],
[65,51,19,10],
[65,51,19,11],
[65,51,19,12],
[65,51,19,15],
[65,51,19,16],
[65,51,19,17],
[65,51,19,18],
[65,51,20,0],
[65,51,20,1],
[65,51,20,2],
[65,51,20,4],
[65,51,20,7],
[65,51,20,9],
[65,51,20,11],
[65,51,20,12],
[65,51,20,13],
[65,51,20,14],
[65,51,20,15],
[65,51,20,16],
[65,51,20,17],
[65,51,20,18],
[65,51,22,0],
[65,51,22,1],
[65,51,22,2],
[65,51,22,4],
[65,51,22,7],
[65,51,22,9],
[65,51,22,11],
[65,51,22,12],
[65,51,22,13],
[65,51,22,14],
[65,51,22,15],
[65,51,24,0],
[65,51,24,1],
[65,51,24,2],
[65,51,24,4],
[65,51,24,7],
[65,51,24,9],
[65,51,24,10],
[65,51,24,11],
[65,51,24,12],
[65,51,24,13],
[65,51,24,14],
[65,51,25,0],
[65,51,25,1],
[65,51,25,2],
[65,51,25,4],
[65,51,25,9],
[65,51,25,10],
[65,51,25,11],
[65,51,26,0],
[65,51,26,1],
[65,51,26,2],
[65,51,26,4],
[65,51,26,7],
[65,51,27,0],
[65,51,27,1],
[65,51,27,2],
[65,51,27,4],
[65,51,27,7],
[65,51,28,0],
[65,51,28,1],
[65,51,28,2],
[65,51,28,4],
[65,51,29,0],
[65,51,29,1],
[65,51,29,2],
[65,51,29,4],
[65,52,3,0],
[65,52,3,1],
[65,52,3,2],
[65,52,4,0],
[65,52,4,1],
[65,52,4,2],
[65,52,4,3],
[65,52,5,0],
[65,52,5,1],
[65,52,5,2],
[65,52,5,4],
[65,52,6,0],
[65,52,6,1],
[65,52,6,2],
[65,52,6,4],
[65,52,7,0],
[65,52,7,1],
[65,52,7,2],
[65,52,7,3],
[65,52,7,4],
[65,52,7,5],
[65,52,7,6],
[65,52,8,0],
[65,52,8,1],
[65,52,8,2],
[65,52,8,4],
[65,52,8,7],
[65,52,10,0],
[65,52,10,1],
[65,52,10,2],
[65,52,10,3],
[65,52,10,4],
[65,52,10,5],
[65,52,10,6],
[65,52,10,7],
[65,52,10,8],
[65,52,11,0],
[65,52,11,1],
[65,52,11,2],
[65,52,11,3],
[65,52,11,4],
[65,52,11,5],
[65,52,11,6],
[65,52,11,7],
[65,52,11,8],
[65,52,12,0],
[65,52,12,1],
[65,52,12,2],
[65,52,12,3],
[65,52,12,4],
[65,52,12,5],
[65,52,12,6],
[65,52,12,7],
[65,52,12,10],
[65,52,13,0],
[65,52,13,1],
[65,52,13,2],
[65,52,13,3],
[65,52,13,4],
[65,52,13,5],
[65,52,13,6],
[65,52,13,7],
[65,52,13,10],
[65,52,13,11],
[65,52,13,12],
[65,52,14,0],
[65,52,14,1],
[65,52,14,2],
[65,52,14,3],
[65,52,14,4],
[65,52,14,5],
[65,52,14,8],
[65,52,14,10],
[65,52,14,11],
[65,52,14,12],
[65,52,16,0],
[65,52,16,1],
[65,52,16,2],
[65,52,16,5],
[65,52,16,6],
[65,52,16,7],
[65,52,16,8],
[65,52,16,10],
[65,52,16,11],
[65,52,16,12],
[65,52,16,13],
[65,52,16,14],
[65,52,17,0],
[65,52,17,1],
[65,52,17,2],
[65,52,17,5],
[65,52,17,6],
[65,52,17,7],
[65,52,17,8],
[65,52,17,10],
[65,52,17,11],
[65,52,17,12],
[65,52,17,13],
[65,52,17,14],
[65,52,17,16],
[65,52,19,0],
[65,52,19,1],
[65,52,19,2],
[65,52,19,3],
[65,52,19,4],
[65,52,19,5],
[65,52,19,6],
[65,52,19,7],
[65,52,19,8],
[65,52,19,10],
[65,52,19,11],
[65,52,19,12],
[65,52,19,16],
[65,52,19,17],
[65,52,20,0],
[65,52,20,1],
[65,52,20,2],
[65,52,20,3],
[65,52,20,4],
[65,52,20,5],
[65,52,20,6],
[65,52,20,7],
[65,52,20,8],
[65,52,20,11],
[65,52,20,12],
[65,52,20,13],
[65,52,20,14],
[65,52,20,16],
[65,52,20,17],
[65,52,21,0],
[65,52,21,1],
[65,52,21,2],
[65,52,21,4],
[65,52,21,7],
[65,52,21,10],
[65,52,21,11],
[65,52,21,12],
[65,52,21,13],
[65,52,21,14],
[65,52,21,16],
[65,52,21,17],
[65,52,22,0],
[65,52,22,1],
[65,52,22,2],
[65,52,22,3],
[65,52,22,4],
[65,52,22,5],
[65,52,22,6],
[65,52,22,7],
[65,52,22,8],
[65,52,22,11],
[65,52,22,12],
[65,52,22,13],
[65,52,22,14],
[65,52,24,0],
[65,52,24,1],
[65,52,24,2],
[65,52,24,3],
[65,52,24,4],
[65,52,24,5],
[65,52,24,6],
[65,52,24,7],
[65,52,24,8],
[65,52,24,10],
[65,52,24,11],
[65,52,24,12],
[65,52,24,13],
[65,52,24,14],
[65,52,25,0],
[65,52,25,1],
[65,52,25,2],
[65,52,25,3],
[65,52,25,4],
[65,52,25,5],
[65,52,25,6],
[65,52,25,8],
[65,52,25,10],
[65,52,25,11],
[65,52,27,0],
[65,52,27,1],
[65,52,27,2],
[65,52,27,3],
[65,52,27,4],
[65,52,27,5],
[65,52,27,6],
[65,52,27,7],
[65,52,28,0],
[65,52,28,1],
[65,52,28,2],
[65,52,28,3],
[65,52,28,4],
[65,52,28,5],
[65,52,29,0],
[65,52,29,1],
[65,52,29,2],
[65,52,29,3],
[65,52,29,4],
[65,53,3,0],
[65,53,3,1],
[65,53,3,2],
[65,53,4,0],
[65,53,4,1],
[65,53,4,2],
[65,53,4,3],
[65,53,5,0],
[65,53,5,1],
[65,53,5,2],
[65,53,5,4],
[65,53,6,0],
[65,53,6,1],
[65,53,6,2],
[65,53,6,4],
[65,53,7,0],
[65,53,7,1],
[65,53,7,2],
[65,53,7,3],
[65,53,7,4],
[65,53,7,5],
[65,53,7,6],
[65,53,8,0],
[65,53,8,1],
[65,53,8,2],
[65,53,8,4],
[65,53,8,7],
[65,53,9,0],
[65,53,9,1],
[65,53,9,2],
[65,53,9,3],
[65,53,9,4],
[65,53,9,5],
[65,53,9,6],
[65,53,9,7],
[65,53,9,8],
[65,53,11,0],
[65,53,11,1],
[65,53,11,2],
[65,53,11,3],
[65,53,11,4],
[65,53,11,5],
[65,53,11,6],
[65,53,11,7],
[65,53,11,8],
[65,53,11,9],
[65,53,12,0],
[65,53,12,1],
[65,53,12,2],
[65,53,12,3],
[65,53,12,4],
[65,53,12,5],
[65,53,12,6],
[65,53,12,7],
[65,53,13,0],
[65,53,13,1],
[65,53,13,2],
[65,53,13,3],
[65,53,13,4],
[65,53,13,5],
[65,53,13,6],
[65,53,13,7],
[65,53,13,11],
[65,53,13,12],
[65,53,14,0],
[65,53,14,1],
[65,53,14,2],
[65,53,14,3],
[65,53,14,4],
[65,53,14,5],
[65,53,14,8],
[65,53,14,9],
[65,53,14,11],
[65,53,14,12],
[65,53,15,0],
[65,53,15,1],
[65,53,15,2],
[65,53,15,3],
[65,53,15,4],
[65,53,15,6],
[65,53,15,7],
[65,53,15,8],
[65,53,15,11],
[65,53,15,12],
[65,53,15,13],
[65,53,15,14],
[65,53,16,0],
[65,53,16,1],
[65,53,16,2],
[65,53,16,5],
[65,53,16,6],
[65,53,16,7],
[65,53,16,8],
[65,53,16,9],
[65,53,16,11],
[65,53,16,12],
[65,53,16,13],
[65,53,16,14],
[65,53,16,15],
[65,53,17,0],
[65,53,17,1],
[65,53,17,2],
[65,53,17,5],
[65,53,17,6],
[65,53,17,7],
[65,53,17,8],
[65,53,17,9],
[65,53,17,11],
[65,53,17,12],
[65,53,17,13],
[65,53,17,14],
[65,53,17,15],
[65,53,17,16],
[65,53,18,3],
[65,53,18,4],
[65,53,18,5],
[65,53,18,6],
[65,53,18,7],
[65,53,18,8],
[65,53,18,11],
[65,53,18,12],
[65,53,18,13],
[65,53,18,14],
[65,53,18,16],
[65,53,18,17],
[65,53,19,0],
[65,53,19,1],
[65,53,19,2],
[65,53,19,3],
[65,53,19,4],
[65,53,19,5],
[65,53,19,6],
[65,53,19,7],
[65,53,19,8],
[65,53,19,9],
[65,53,19,11],
[65,53,19,12],
[65,53,19,15],
[65,53,19,16],
[65,53,19,17],
[65,53,19,18],
[65,53,21,0],
[65,53,21,1],
[65,53,21,2],
[65,53,21,4],
[65,53,21,7],
[65,53,21,9],
[65,53,21,11],
[65,53,21,12],
[65,53,21,13],
[65,53,21,14],
[65,53,21,15],
[65,53,21,16],
[65,53,21,17],
[65,53,24,0],
[65,53,24,1],
[65,53,24,2],
[65,53,24,3],
[65,53,24,4],
[65,53,24,5],
[65,53,24,6],
[65,53,24,7],
[65,53,24,8],
[65,53,24,9],
[65,53,24,11],
[65,53,24,12],
[65,53,24,13],
[65,53,24,14],
[65,53,25,0],
[65,53,25,1],
[65,53,25,2],
[65,53,25,3],
[65,53,25,4],
[65,53,25,5],
[65,53,25,6],
[65,53,25,8],
[65,53,25,9],
[65,53,25,11],
[65,53,26,0],
[65,53,26,1],
[65,53,26,2],
[65,53,26,3],
[65,53,26,4],
[65,53,26,5],
[65,53,26,6],
[65,53,26,7],
[65,53,26,8],
[65,53,28,0],
[65,53,28,1],
[65,53,28,2],
[65,53,28,3],
[65,53,28,4],
[65,53,28,5],
[65,53,29,0],
[65,53,29,1],
[65,53,29,2],
[65,53,29,3],
[65,53,29,4],
[65,54,3,0],
[65,54,3,1],
[65,54,3,2],
[65,54,4,0],
[65,54,4,1],
[65,54,4,2],
[65,54,4,3],
[65,54,5,0],
[65,54,5,1],
[65,54,5,2],
[65,54,5,4],
[65,54,6,0],
[65,54,6,1],
[65,54,6,2],
[65,54,6,4],
[65,54,7,0],
[65,54,7,1],
[65,54,7,2],
[65,54,7,3],
[65,54,7,4],
[65,54,7,5],
[65,54,7,6],
[65,54,8,0],
[65,54,8,1],
[65,54,8,2],
[65,54,8,4],
[65,54,8,7],
[65,54,9,0],
[65,54,9,1],
[65,54,9,2],
[65,54,9,3],
[65,54,9,4],
[65,54,9,5],
[65,54,9,6],
[65,54,9,7],
[65,54,9,8],
[65,54,11,0],
[65,54,11,1],
[65,54,11,2],
[65,54,11,3],
[65,54,11,4],
[65,54,11,5],
[65,54,11,6],
[65,54,11,7],
[65,54,11,8],
[65,54,11,9],
[65,54,12,0],
[65,54,12,1],
[65,54,12,2],
[65,54,12,3],
[65,54,12,4],
[65,54,12,5],
[65,54,12,6],
[65,54,12,7],
[65,54,13,0],
[65,54,13,1],
[65,54,13,2],
[65,54,13,3],
[65,54,13,4],
[65,54,13,5],
[65,54,13,6],
[65,54,13,7],
[65,54,13,11],
[65,54,13,12],
[65,54,14,0],
[65,54,14,1],
[65,54,14,2],
[65,54,14,3],
[65,54,14,4],
[65,54,14,5],
[65,54,14,8],
[65,54,14,9],
[65,54,14,11],
[65,54,14,12],
[65,54,15,0],
[65,54,15,1],
[65,54,15,2],
[65,54,15,3],
[65,54,15,4],
[65,54,15,6],
[65,54,15,7],
[65,54,15,8],
[65,54,15,11],
[65,54,15,12],
[65,54,15,13],
[65,54,15,14],
[65,54,16,0],
[65,54,16,1],
[65,54,16,2],
[65,54,16,5],
[65,54,16,6],
[65,54,16,7],
[65,54,16,8],
[65,54,16,9],
[65,54,16,11],
[65,54,16,12],
[65,54,16,13],
[65,54,16,14],
[65,54,16,15],
[65,54,17,0],
[65,54,17,1],
[65,54,17,2],
[65,54,17,5],
[65,54,17,6],
[65,54,17,7],
[65,54,17,8],
[65,54,17,9],
[65,54,17,11],
[65,54,17,12],
[65,54,17,13],
[65,54,17,14],
[65,54,17,15],
[65,54,17,16],
[65,54,18,3],
[65,54,18,4],
[65,54,18,5],
[65,54,18,6],
[65,54,18,7],
[65,54,18,8],
[65,54,18,11],
[65,54,18,12],
[65,54,18,13],
[65,54,18,14],
[65,54,18,16],
[65,54,18,17],
[65,54,19,0],
[65,54,19,1],
[65,54,19,2],
[65,54,19,3],
[65,54,19,4],
[65,54,19,5],
[65,54,19,6],
[65,54,19,7],
[65,54,19,8],
[65,54,19,9],
[65,54,19,11],
[65,54,19,12],
[65,54,19,15],
[65,54,19,16],
[65,54,19,17],
[65,54,19,18],
[65,54,21,0],
[65,54,21,1],
[65,54,21,2],
[65,54,21,4],
[65,54,21,7],
[65,54,21,9],
[65,54,21,11],
[65,54,21,12],
[65,54,21,13],
[65,54,21,14],
[65,54,21,15],
[65,54,21,16],
[65,54,21,17],
[65,54,24,0],
[65,54,24,1],
[65,54,24,2],
[65,54,24,3],
[65,54,24,4],
[65,54,24,5],
[65,54,24,6],
[65,54,24,7],
[65,54,24,8],
[65,54,24,9],
[65,54,24,11],
[65,54,24,12],
[65,54,24,13],
[65,54,24,14],
[65,54,25,0],
[65,54,25,1],
[65,54,25,2],
[65,54,25,3],
[65,54,25,4],
[65,54,25,5],
[65,54,25,6],
[65,54,25,8],
[65,54,25,9],
[65,54,25,11],
[65,54,26,0],
[65,54,26,1],
[65,54,26,2],
[65,54,26,3],
[65,54,26,4],
[65,54,26,5],
[65,54,26,6],
[65,54,26,7],
[65,54,26,8],
[65,54,28,0],
[65,54,28,1],
[65,54,28,2],
[65,54,28,3],
[65,54,28,4],
[65,54,28,5],
[65,54,29,0],
[65,54,29,1],
[65,54,29,2],
[65,54,29,3],
[65,54,29,4],
[65,55,3,0],
[65,55,3,1],
[65,55,3,2],
[65,55,4,0],
[65,55,4,1],
[65,55,4,2],
[65,55,4,3],
[65,55,5,0],
[65,55,5,1],
[65,55,5,2],
[65,55,5,4],
[65,55,6,0],
[65,55,6,1],
[65,55,6,2],
[65,55,6,4],
[65,55,7,0],
[65,55,7,1],
[65,55,7,2],
[65,55,7,3],
[65,55,7,4],
[65,55,7,5],
[65,55,7,6],
[65,55,8,0],
[65,55,8,1],
[65,55,8,2],
[65,55,8,4],
[65,55,8,7],
[65,55,9,0],
[65,55,9,1],
[65,55,9,2],
[65,55,9,3],
[65,55,9,4],
[65,55,9,5],
[65,55,9,6],
[65,55,9,7],
[65,55,9,8],
[65,55,11,0],
[65,55,11,1],
[65,55,11,2],
[65,55,11,3],
[65,55,11,4],
[65,55,11,5],
[65,55,11,6],
[65,55,11,7],
[65,55,11,8],
[65,55,11,9],
[65,55,12,0],
[65,55,12,1],
[65,55,12,2],
[65,55,12,3],
[65,55,12,4],
[65,55,12,5],
[65,55,12,6],
[65,55,12,7],
[65,55,13,0],
[65,55,13,1],
[65,55,13,2],
[65,55,13,3],
[65,55,13,4],
[65,55,13,5],
[65,55,13,6],
[65,55,13,7],
[65,55,13,11],
[65,55,13,12],
[65,55,14,0],
[65,55,14,1],
[65,55,14,2],
[65,55,14,3],
[65,55,14,4],
[65,55,14,5],
[65,55,14,8],
[65,55,14,9],
[65,55,14,11],
[65,55,14,12],
[65,55,15,0],
[65,55,15,1],
[65,55,15,2],
[65,55,15,3],
[65,55,15,4],
[65,55,15,6],
[65,55,15,7],
[65,55,15,8],
[65,55,15,11],
[65,55,15,12],
[65,55,15,13],
[65,55,15,14],
[65,55,16,0],
[65,55,16,1],
[65,55,16,2],
[65,55,16,5],
[65,55,16,6],
[65,55,16,7],
[65,55,16,8],
[65,55,16,9],
[65,55,16,11],
[65,55,16,12],
[65,55,16,13],
[65,55,16,14],
[65,55,16,15],
[65,55,17,0],
[65,55,17,1],
[65,55,17,2],
[65,55,17,5],
[65,55,17,6],
[65,55,17,7],
[65,55,17,8],
[65,55,17,9],
[65,55,17,11],
[65,55,17,12],
[65,55,17,13],
[65,55,17,14],
[65,55,17,15],
[65,55,17,16],
[65,55,18,3],
[65,55,18,4],
[65,55,18,5],
[65,55,18,6],
[65,55,18,7],
[65,55,18,8],
[65,55,18,11],
[65,55,18,12],
[65,55,18,13],
[65,55,18,14],
[65,55,18,16],
[65,55,18,17],
[65,55,19,0],
[65,55,19,1],
[65,55,19,2],
[65,55,19,3],
[65,55,19,4],
[65,55,19,5],
[65,55,19,6],
[65,55,19,7],
[65,55,19,8],
[65,55,19,9],
[65,55,19,11],
[65,55,19,12],
[65,55,19,15],
[65,55,19,16],
[65,55,19,17],
[65,55,19,18],
[65,55,21,0],
[65,55,21,1],
[65,55,21,2],
[65,55,21,4],
[65,55,21,7],
[65,55,21,9],
[65,55,21,11],
[65,55,21,12],
[65,55,21,13],
[65,55,21,14],
[65,55,21,15],
[65,55,21,16],
[65,55,21,17],
[65,55,24,0],
[65,55,24,1],
[65,55,24,2],
[65,55,24,3],
[65,55,24,4],
[65,55,24,5],
[65,55,24,6],
[65,55,24,7],
[65,55,24,8],
[65,55,24,9],
[65,55,24,11],
[65,55,24,12],
[65,55,24,13],
[65,55,24,14],
[65,55,25,0],
[65,55,25,1],
[65,55,25,2],
[65,55,25,3],
[65,55,25,4],
[65,55,25,5],
[65,55,25,6],
[65,55,25,8],
[65,55,25,9],
[65,55,25,11],
[65,55,26,0],
[65,55,26,1],
[65,55,26,2],
[65,55,26,3],
[65,55,26,4],
[65,55,26,5],
[65,55,26,6],
[65,55,26,7],
[65,55,26,8],
[65,55,28,0],
[65,55,28,1],
[65,55,28,2],
[65,55,28,3],
[65,55,28,4],
[65,55,28,5],
[65,55,29,0],
[65,55,29,1],
[65,55,29,2],
[65,55,29,3],
[65,55,29,4],
[65,56,3,0],
[65,56,3,1],
[65,56,3,2],
[65,56,4,0],
[65,56,4,1],
[65,56,4,2],
[65,56,4,3],
[65,56,5,0],
[65,56,5,1],
[65,56,5,2],
[65,56,5,4],
[65,56,6,0],
[65,56,6,1],
[65,56,6,2],
[65,56,6,4],
[65,56,7,0],
[65,56,7,1],
[65,56,7,2],
[65,56,7,3],
[65,56,7,4],
[65,56,7,5],
[65,56,7,6],
[65,56,8,0],
[65,56,8,1],
[65,56,8,2],
[65,56,8,4],
[65,56,8,7],
[65,56,10,0],
[65,56,10,1],
[65,56,10,2],
[65,56,10,3],
[65,56,10,4],
[65,56,10,5],
[65,56,10,6],
[65,56,10,7],
[65,56,10,8],
[65,56,11,0],
[65,56,11,1],
[65,56,11,2],
[65,56,11,3],
[65,56,11,4],
[65,56,11,5],
[65,56,11,6],
[65,56,11,7],
[65,56,11,8],
[65,56,12,0],
[65,56,12,1],
[65,56,12,2],
[65,56,12,3],
[65,56,12,4],
[65,56,12,5],
[65,56,12,6],
[65,56,12,7],
[65,56,12,10],
[65,56,13,0],
[65,56,13,1],
[65,56,13,2],
[65,56,13,3],
[65,56,13,4],
[65,56,13,5],
[65,56,13,6],
[65,56,13,7],
[65,56,13,10],
[65,56,13,11],
[65,56,13,12],
[65,56,14,0],
[65,56,14,1],
[65,56,14,2],
[65,56,14,3],
[65,56,14,4],
[65,56,14,5],
[65,56,14,8],
[65,56,14,10],
[65,56,14,11],
[65,56,14,12],
[65,56,16,0],
[65,56,16,1],
[65,56,16,2],
[65,56,16,5],
[65,56,16,6],
[65,56,16,7],
[65,56,16,8],
[65,56,16,10],
[65,56,16,11],
[65,56,16,12],
[65,56,16,13],
[65,56,16,14],
[65,56,17,0],
[65,56,17,1],
[65,56,17,2],
[65,56,17,5],
[65,56,17,6],
[65,56,17,7],
[65,56,17,8],
[65,56,17,10],
[65,56,17,11],
[65,56,17,12],
[65,56,17,13],
[65,56,17,14],
[65,56,17,16],
[65,56,19,0],
[65,56,19,1],
[65,56,19,2],
[65,56,19,3],
[65,56,19,4],
[65,56,19,5],
[65,56,19,6],
[65,56,19,7],
[65,56,19,8],
[65,56,19,10],
[65,56,19,11],
[65,56,19,12],
[65,56,19,16],
[65,56,19,17],
[65,56,20,0],
[65,56,20,1],
[65,56,20,2],
[65,56,20,3],
[65,56,20,4],
[65,56,20,5],
[65,56,20,6],
[65,56,20,7],
[65,56,20,8],
[65,56,20,11],
[65,56,20,12],
[65,56,20,13],
[65,56,20,14],
[65,56,20,16],
[65,56,20,17],
[65,56,21,0],
[65,56,21,1],
[65,56,21,2],
[65,56,21,4],
[65,56,21,7],
[65,56,21,10],
[65,56,21,11],
[65,56,21,12],
[65,56,21,13],
[65,56,21,14],
[65,56,21,16],
[65,56,21,17],
[65,56,22,0],
[65,56,22,1],
[65,56,22,2],
[65,56,22,3],
[65,56,22,4],
[65,56,22,5],
[65,56,22,6],
[65,56,22,7],
[65,56,22,8],
[65,56,22,11],
[65,56,22,12],
[65,56,22,13],
[65,56,22,14],
[65,56,24,0],
[65,56,24,1],
[65,56,24,2],
[65,56,24,3],
[65,56,24,4],
[65,56,24,5],
[65,56,24,6],
[65,56,24,7],
[65,56,24,8],
[65,56,24,10],
[65,56,24,11],
[65,56,24,12],
[65,56,24,13],
[65,56,24,14],
[65,56,25,0],
[65,56,25,1],
[65,56,25,2],
[65,56,25,3],
[65,56,25,4],
[65,56,25,5],
[65,56,25,6],
[65,56,25,8],
[65,56,25,10],
[65,56,25,11],
[65,56,27,0],
[65,56,27,1],
[65,56,27,2],
[65,56,27,3],
[65,56,27,4],
[65,56,27,5],
[65,56,27,6],
[65,56,27,7],
[65,56,28,0],
[65,56,28,1],
[65,56,28,2],
[65,56,28,3],
[65,56,28,4],
[65,56,28,5],
[65,56,29,0],
[65,56,29,1],
[65,56,29,2],
[65,56,29,3],
[65,56,29,4],
[65,58,3,0],
[65,58,3,1],
[65,58,3,2],
[65,58,4,0],
[65,58,4,1],
[65,58,4,2],
[65,58,4,3],
[65,58,5,0],
[65,58,5,1],
[65,58,5,2],
[65,58,5,4],
[65,58,6,0],
[65,58,6,1],
[65,58,6,2],
[65,58,6,4],
[65,58,7,0],
[65,58,7,1],
[65,58,7,2],
[65,58,7,3],
[65,58,7,4],
[65,58,7,5],
[65,58,7,6],
[65,58,8,0],
[65,58,8,1],
[65,58,8,2],
[65,58,8,4],
[65,58,8,7],
[65,58,9,0],
[65,58,9,1],
[65,58,9,2],
[65,58,9,3],
[65,58,9,4],
[65,58,9,5],
[65,58,9,6],
[65,58,9,7],
[65,58,9,8],
[65,58,10,0],
[65,58,10,1],
[65,58,10,2],
[65,58,10,3],
[65,58,10,4],
[65,58,10,5],
[65,58,10,6],
[65,58,10,7],
[65,58,11,0],
[65,58,11,1],
[65,58,11,2],
[65,58,11,3],
[65,58,11,4],
[65,58,11,5],
[65,58,11,6],
[65,58,11,7],
[65,58,11,10],
[65,58,12,0],
[65,58,12,1],
[65,58,12,2],
[65,58,12,3],
[65,58,12,4],
[65,58,12,5],
[65,58,12,8],
[65,58,12,9],
[65,58,12,10],
[65,58,13,0],
[65,58,13,1],
[65,58,13,2],
[65,58,13,3],
[65,58,13,4],
[65,58,13,5],
[65,58,13,8],
[65,58,13,9],
[65,58,13,10],
[65,58,13,11],
[65,58,13,12],
[65,58,14,0],
[65,58,14,1],
[65,58,14,2],
[65,58,14,3],
[65,58,14,4],
[65,58,14,6],
[65,58,14,7],
[65,58,14,8],
[65,58,14,9],
[65,58,14,10],
[65,58,14,11],
[65,58,14,12],
[65,58,15,0],
[65,58,15,1],
[65,58,15,2],
[65,58,15,5],
[65,58,15,6],
[65,58,15,7],
[65,58,15,8],
[65,58,15,10],
[65,58,15,11],
[65,58,15,12],
[65,58,15,13],
[65,58,15,14],
[65,58,17,3],
[65,58,17,4],
[65,58,17,5],
[65,58,17,6],
[65,58,17,7],
[65,58,17,8],
[65,58,17,9],
[65,58,17,10],
[65,58,17,11],
[65,58,17,12],
[65,58,17,13],
[65,58,17,14],
[65,58,17,15],
[65,58,18,0],
[65,58,18,1],
[65,58,18,2],
[65,58,18,3],
[65,58,18,4],
[65,58,18,5],
[65,58,18,6],
[65,58,18,7],
[65,58,18,8],
[65,58,18,10],
[65,58,18,11],
[65,58,18,12],
[65,58,18,13],
[65,58,18,14],
[65,58,18,17],
[65,58,19,0],
[65,58,19,1],
[65,58,19,2],
[65,58,19,3],
[65,58,19,4],
[65,58,19,5],
[65,58,19,6],
[65,58,19,7],
[65,58,19,8],
[65,58,19,9],
[65,58,19,10],
[65,58,19,11],
[65,58,19,12],
[65,58,19,15],
[65,58,19,17],
[65,58,19,18],
[65,58,20,0],
[65,58,20,1],
[65,58,20,2],
[65,58,20,3],
[65,58,20,4],
[65,58,20,5],
[65,58,20,6],
[65,58,20,7],
[65,58,20,8],
[65,58,20,9],
[65,58,20,11],
[65,58,20,12],
[65,58,20,13],
[65,58,20,14],
[65,58,20,15],
[65,58,20,17],
[65,58,21,0],
[65,58,21,1],
[65,58,21,2],
[65,58,21,4],
[65,58,21,7],
[65,58,21,9],
[65,58,21,10],
[65,58,21,11],
[65,58,21,12],
[65,58,21,13],
[65,58,21,14],
[65,58,21,15],
[65,58,22,0],
[65,58,22,1],
[65,58,22,2],
[65,58,22,3],
[65,58,22,4],
[65,58,22,5],
[65,58,22,6],
[65,58,22,7],
[65,58,22,8],
[65,58,22,9],
[65,58,22,11],
[65,58,22,12],
[65,58,22,13],
[65,58,22,14],
[65,58,25,0],
[65,58,25,1],
[65,58,25,2],
[65,58,25,3],
[65,58,25,4],
[65,58,25,5],
[65,58,25,6],
[65,58,25,8],
[65,58,25,9],
[65,58,26,0],
[65,58,26,1],
[65,58,26,2],
[65,58,26,3],
[65,58,26,4],
[65,58,26,5],
[65,58,26,6],
[65,58,26,7],
[65,58,27,0],
[65,58,27,1],
[65,58,27,2],
[65,58,27,3],
[65,58,27,4],
[65,58,27,5],
[65,58,28,0],
[65,58,28,1],
[65,58,28,2],
[65,58,28,3],
[65,58,28,4],
[65,58,29,0],
[65,58,29,1],
[65,58,29,2],
[65,59,3,0],
[65,59,3,1],
[65,59,3,2],
[65,59,4,0],
[65,59,4,1],
[65,59,4,2],
[65,59,4,3],
[65,59,5,0],
[65,59,5,1],
[65,59,5,2],
[65,59,5,4],
[65,59,6,0],
[65,59,6,1],
[65,59,6,2],
[65,59,6,4],
[65,59,7,0],
[65,59,7,1],
[65,59,7,2],
[65,59,7,3],
[65,59,7,4],
[65,59,7,5],
[65,59,7,6],
[65,59,8,0],
[65,59,8,1],
[65,59,8,2],
[65,59,8,4],
[65,59,8,7],
[65,59,10,0],
[65,59,10,1],
[65,59,10,2],
[65,59,10,3],
[65,59,10,4],
[65,59,10,5],
[65,59,10,8],
[65,59,11,0],
[65,59,11,1],
[65,59,11,2],
[65,59,11,3],
[65,59,11,4],
[65,59,11,5],
[65,59,11,8],
[65,59,11,10],
[65,59,12,0],
[65,59,12,1],
[65,59,12,2],
[65,59,12,3],
[65,59,12,4],
[65,59,12,6],
[65,59,12,7],
[65,59,12,8],
[65,59,12,10],
[65,59,13,0],
[65,59,13,1],
[65,59,13,2],
[65,59,13,3],
[65,59,13,4],
[65,59,13,6],
[65,59,13,7],
[65,59,13,8],
[65,59,13,10],
[65,59,13,11],
[65,59,13,12],
[65,59,14,0],
[65,59,14,1],
[65,59,14,2],
[65,59,14,5],
[65,59,14,6],
[65,59,14,7],
[65,59,14,8],
[65,59,14,10],
[65,59,14,11],
[65,59,14,12],
[65,59,16,0],
[65,59,16,1],
[65,59,16,2],
[65,59,16,3],
[65,59,16,4],
[65,59,16,5],
[65,59,16,6],
[65,59,16,7],
[65,59,16,8],
[65,59,16,10],
[65,59,16,11],
[65,59,16,12],
[65,59,16,13],
[65,59,16,14],
[65,59,17,0],
[65,59,17,1],
[65,59,17,2],
[65,59,17,3],
[65,59,17,4],
[65,59,17,5],
[65,59,17,6],
[65,59,17,7],
[65,59,17,8],
[65,59,17,10],
[65,59,17,11],
[65,59,17,12],
[65,59,17,13],
[65,59,17,14],
[65,59,17,16],
[65,59,19,0],
[65,59,19,1],
[65,59,19,2],
[65,59,19,3],
[65,59,19,4],
[65,59,19,5],
[65,59,19,6],
[65,59,19,7],
[65,59,19,8],
[65,59,19,10],
[65,59,19,11],
[65,59,19,12],
[65,59,19,16],
[65,59,19,17],
[65,59,20,0],
[65,59,20,1],
[65,59,20,2],
[65,59,20,3],
[65,59,20,4],
[65,59,20,5],
[65,59,20,6],
[65,59,20,7],
[65,59,20,8],
[65,59,20,11],
[65,59,20,12],
[65,59,20,13],
[65,59,20,14],
[65,59,21,0],
[65,59,21,1],
[65,59,21,2],
[65,59,21,4],
[65,59,21,7],
[65,59,21,10],
[65,59,21,11],
[65,59,21,12],
[65,59,21,13],
[65,59,21,14],
[65,59,22,0],
[65,59,22,1],
[65,59,22,2],
[65,59,22,3],
[65,59,22,4],
[65,59,22,5],
[65,59,22,6],
[65,59,22,7],
[65,59,22,8],
[65,59,22,11],
[65,59,22,12],
[65,59,22,13],
[65,59,24,0],
[65,59,24,1],
[65,59,24,2],
[65,59,24,3],
[65,59,24,4],
[65,59,24,5],
[65,59,24,6],
[65,59,24,7],
[65,59,24,8],
[65,59,24,10],
[65,59,24,11],
[65,59,25,0],
[65,59,25,1],
[65,59,25,2],
[65,59,25,3],
[65,59,25,4],
[65,59,25,5],
[65,59,25,6],
[65,59,27,0],
[65,59,27,1],
[65,59,27,2],
[65,59,27,3],
[65,59,27,4],
[65,59,28,0],
[65,59,28,1],
[65,59,28,2],
[65,60,3,0],
[65,60,3,1],
[65,60,3,2],
[65,60,4,0],
[65,60,4,1],
[65,60,4,2],
[65,60,4,3],
[65,60,5,0],
[65,60,5,1],
[65,60,5,2],
[65,60,5,4],
[65,60,6,0],
[65,60,6,1],
[65,60,6,2],
[65,60,6,4],
[65,60,7,0],
[65,60,7,1],
[65,60,7,2],
[65,60,7,3],
[65,60,7,4],
[65,60,7,5],
[65,60,7,6],
[65,60,8,0],
[65,60,8,1],
[65,60,8,2],
[65,60,8,4],
[65,60,9,0],
[65,60,9,1],
[65,60,9,2],
[65,60,9,3],
[65,60,9,4],
[65,60,9,5],
[65,60,9,8],
[65,60,10,0],
[65,60,10,1],
[65,60,10,2],
[65,60,10,3],
[65,60,10,4],
[65,60,10,6],
[65,60,10,7],
[65,60,10,8],
[65,60,10,9],
[65,60,11,0],
[65,60,11,1],
[65,60,11,2],
[65,60,11,3],
[65,60,11,4],
[65,60,11,6],
[65,60,11,7],
[65,60,11,8],
[65,60,11,9],
[65,60,11,10],
[65,60,12,0],
[65,60,12,1],
[65,60,12,2],
[65,60,12,5],
[65,60,12,6],
[65,60,12,7],
[65,60,12,8],
[65,60,12,9],
[65,60,12,10],
[65,60,15,0],
[65,60,15,1],
[65,60,15,2],
[65,60,15,3],
[65,60,15,4],
[65,60,15,5],
[65,60,15,6],
[65,60,15,7],
[65,60,15,8],
[65,60,15,10],
[65,60,15,11],
[65,60,15,12],
[65,60,16,0],
[65,60,16,1],
[65,60,16,2],
[65,60,16,3],
[65,60,16,4],
[65,60,16,5],
[65,60,16,6],
[65,60,16,7],
[65,60,16,8],
[65,60,16,9],
[65,60,16,10],
[65,60,16,11],
[65,60,16,12],
[65,60,16,15],
[65,60,17,0],
[65,60,17,1],
[65,60,17,2],
[65,60,17,3],
[65,60,17,4],
[65,60,17,5],
[65,60,17,6],
[65,60,17,7],
[65,60,17,8],
[65,60,17,9],
[65,60,17,10],
[65,60,17,11],
[65,60,17,12],
[65,60,17,15],
[65,60,17,16],
[65,60,18,0],
[65,60,18,1],
[65,60,18,2],
[65,60,18,3],
[65,60,18,4],
[65,60,18,5],
[65,60,18,6],
[65,60,18,7],
[65,60,18,8],
[65,60,18,10],
[65,60,18,11],
[65,60,18,12],
[65,60,18,16],
[65,60,18,17],
[65,60,20,0],
[65,60,20,1],
[65,60,20,2],
[65,60,20,3],
[65,60,20,4],
[65,60,20,5],
[65,60,20,6],
[65,60,20,7],
[65,60,20,8],
[65,60,20,9],
[65,60,20,11],
[65,60,20,12],
[65,60,21,0],
[65,60,21,1],
[65,60,21,2],
[65,60,21,4],
[65,60,21,7],
[65,60,21,9],
[65,60,21,10],
[65,60,21,11],
[65,60,21,12],
[65,60,22,0],
[65,60,22,1],
[65,60,22,2],
[65,60,22,3],
[65,60,22,4],
[65,60,22,5],
[65,60,22,6],
[65,60,22,7],
[65,60,22,8],
[65,60,22,9],
[65,60,22,11],
[65,60,24,0],
[65,60,24,1],
[65,60,24,2],
[65,60,24,3],
[65,60,24,4],
[65,60,24,5],
[65,60,24,6],
[65,60,24,7],
[65,60,24,8],
[65,60,24,9],
[65,60,25,0],
[65,60,25,1],
[65,60,25,2],
[65,60,25,3],
[65,60,25,4],
[65,60,25,5],
[65,60,26,0],
[65,60,26,1],
[65,60,26,2],
[65,60,26,3],
[65,60,26,4],
[65,60,27,0],
[65,60,27,1],
[65,60,27,2],
[65,61,3,0],
[65,61,3,1],
[65,61,3,2],
[65,61,5,0],
[65,61,5,1],
[65,61,5,2],
[65,61,6,0],
[65,61,6,1],
[65,61,6,2],
[65,61,7,0],
[65,61,7,1],
[65,61,7,2],
[65,61,7,3],
[65,61,7,5],
[65,61,8,0],
[65,61,8,1],
[65,61,8,2],
[65,61,8,7],
[65,61,9,0],
[65,61,9,1],
[65,61,9,2],
[65,61,9,3],
[65,61,9,6],
[65,61,9,7],
[65,61,9,8],
[65,61,10,0],
[65,61,10,1],
[65,61,10,2],
[65,61,10,5],
[65,61,10,6],
[65,61,10,7],
[65,61,10,8],
[65,61,10,9],
[65,61,11,0],
[65,61,11,1],
[65,61,11,2],
[65,61,11,5],
[65,61,11,6],
[65,61,11,7],
[65,61,11,8],
[65,61,11,9],
[65,61,11,10],
[65,61,12,3],
[65,61,12,5],
[65,61,12,6],
[65,61,12,7],
[65,61,12,8],
[65,61,12,9],
[65,61,12,10],
[65,61,13,3],
[65,61,13,5],
[65,61,13,6],
[65,61,13,7],
[65,61,13,8],
[65,61,13,9],
[65,61,13,10],
[65,61,13,11],
[65,61,13,12],
[65,61,14,0],
[65,61,14,1],
[65,61,14,2],
[65,61,14,3],
[65,61,14,5],
[65,61,14,6],
[65,61,14,7],
[65,61,14,8],
[65,61,14,9],
[65,61,14,10],
[65,61,14,11],
[65,61,14,12],
[65,61,15,0],
[65,61,15,1],
[65,61,15,2],
[65,61,15,3],
[65,61,15,5],
[65,61,15,6],
[65,61,15,7],
[65,61,15,8],
[65,61,15,10],
[65,61,15,11],
[65,61,15,12],
[65,61,15,13],
[65,61,15,14],
[65,61,16,0],
[65,61,16,1],
[65,61,16,2],
[65,61,16,3],
[65,61,16,5],
[65,61,16,6],
[65,61,16,7],
[65,61,16,8],
[65,61,16,9],
[65,61,16,10],
[65,61,16,11],
[65,61,16,12],
[65,61,16,13],
[65,61,16,14],
[65,61,16,15],
[65,61,17,0],
[65,61,17,1],
[65,61,17,2],
[65,61,17,3],
[65,61,17,5],
[65,61,17,6],
[65,61,17,7],
[65,61,17,8],
[65,61,17,9],
[65,61,17,10],
[65,61,17,11],
[65,61,17,12],
[65,61,17,13],
[65,61,17,14],
[65,61,17,15],
[65,61,17,16],
[65,61,18,0],
[65,61,18,1],
[65,61,18,2],
[65,61,18,3],
[65,61,18,5],
[65,61,18,6],
[65,61,18,7],
[65,61,18,8],
[65,61,18,10],
[65,61,18,11],
[65,61,18,12],
[65,61,18,13],
[65,61,18,14],
[65,61,19,0],
[65,61,19,1],
[65,61,19,2],
[65,61,19,3],
[65,61,19,5],
[65,61,19,6],
[65,61,19,7],
[65,61,19,8],
[65,61,19,9],
[65,61,19,10],
[65,61,19,11],
[65,61,19,12],
[65,61,20,0],
[65,61,20,1],
[65,61,20,2],
[65,61,20,3],
[65,61,20,5],
[65,61,20,6],
[65,61,20,7],
[65,61,20,8],
[65,61,20,9],
[65,61,20,11],
[65,61,20,12],
[65,61,20,13],
[65,61,21,0],
[65,61,21,1],
[65,61,21,2],
[65,61,21,7],
[65,61,21,9],
[65,61,21,10],
[65,61,21,11],
[65,61,22,0],
[65,61,22,1],
[65,61,22,2],
[65,61,22,3],
[65,61,22,5],
[65,61,22,6],
[65,61,22,7],
[65,61,22,8],
[65,61,22,9],
[65,61,24,0],
[65,61,24,1],
[65,61,24,2],
[65,61,24,3],
[65,61,24,5],
[65,61,24,6],
[65,61,24,7],
[65,61,25,0],
[65,61,25,1],
[65,61,25,2],
[65,61,25,3],
[65,61,26,0],
[65,61,26,1],
[65,61,26,2],
[65,62,3,0],
[65,62,3,1],
[65,62,3,2],
[65,62,4,0],
[65,62,4,1],
[65,62,4,2],
[65,62,4,3],
[65,62,5,0],
[65,62,5,1],
[65,62,5,2],
[65,62,5,4],
[65,62,6,0],
[65,62,6,1],
[65,62,6,2],
[65,62,6,4],
[65,62,8,0],
[65,62,8,1],
[65,62,8,2],
[65,62,8,4],
[65,62,9,0],
[65,62,9,1],
[65,62,9,2],
[65,62,9,3],
[65,62,9,4],
[65,62,9,6],
[65,62,9,8],
[65,62,10,0],
[65,62,10,1],
[65,62,10,2],
[65,62,10,5],
[65,62,10,6],
[65,62,10,8],
[65,62,10,9],
[65,62,11,0],
[65,62,11,1],
[65,62,11,2],
[65,62,11,5],
[65,62,11,6],
[65,62,11,8],
[65,62,11,9],
[65,62,11,10],
[65,62,12,3],
[65,62,12,4],
[65,62,12,5],
[65,62,12,6],
[65,62,12,8],
[65,62,12,9],
[65,62,12,10],
[65,62,13,3],
[65,62,13,4],
[65,62,13,5],
[65,62,13,6],
[65,62,13,8],
[65,62,13,9],
[65,62,13,10],
[65,62,13,11],
[65,62,13,12],
[65,62,14,0],
[65,62,14,1],
[65,62,14,2],
[65,62,14,3],
[65,62,14,4],
[65,62,14,5],
[65,62,14,6],
[65,62,14,8],
[65,62,14,9],
[65,62,14,10],
[65,62,14,11],
[65,62,14,12],
[65,62,15,0],
[65,62,15,1],
[65,62,15,2],
[65,62,15,3],
[65,62,15,4],
[65,62,15,5],
[65,62,15,6],
[65,62,15,8],
[65,62,15,10],
[65,62,15,11],
[65,62,15,12],
[65,62,15,13],
[65,62,15,14],
[65,62,16,0],
[65,62,16,1],
[65,62,16,2],
[65,62,16,3],
[65,62,16,4],
[65,62,16,5],
[65,62,16,6],
[65,62,16,8],
[65,62,16,9],
[65,62,16,10],
[65,62,16,11],
[65,62,16,12],
[65,62,16,13],
[65,62,16,14],
[65,62,16,15],
[65,62,17,0],
[65,62,17,1],
[65,62,17,2],
[65,62,17,3],
[65,62,17,4],
[65,62,17,5],
[65,62,17,6],
[65,62,17,8],
[65,62,17,9],
[65,62,17,10],
[65,62,17,11],
[65,62,17,12],
[65,62,17,13],
[65,62,17,14],
[65,62,17,15],
[65,62,17,16],
[65,62,18,0],
[65,62,18,1],
[65,62,18,2],
[65,62,18,3],
[65,62,18,4],
[65,62,18,5],
[65,62,18,6],
[65,62,18,8],
[65,62,18,10],
[65,62,18,11],
[65,62,18,12],
[65,62,18,13],
[65,62,18,14],
[65,62,19,0],
[65,62,19,1],
[65,62,19,2],
[65,62,19,3],
[65,62,19,4],
[65,62,19,5],
[65,62,19,6],
[65,62,19,8],
[65,62,19,9],
[65,62,19,10],
[65,62,19,11],
[65,62,19,12],
[65,62,20,0],
[65,62,20,1],
[65,62,20,2],
[65,62,20,3],
[65,62,20,4],
[65,62,20,5],
[65,62,20,6],
[65,62,20,8],
[65,62,20,9],
[65,62,20,11],
[65,62,20,12],
[65,62,20,13],
[65,62,21,0],
[65,62,21,1],
[65,62,21,2],
[65,62,21,4],
[65,62,21,9],
[65,62,21,10],
[65,62,21,11],
[65,62,22,0],
[65,62,22,1],
[65,62,22,2],
[65,62,22,3],
[65,62,22,4],
[65,62,22,5],
[65,62,22,6],
[65,62,22,8],
[65,62,22,9],
[65,62,24,0],
[65,62,24,1],
[65,62,24,2],
[65,62,24,3],
[65,62,24,4],
[65,62,24,5],
[65,62,24,6],
[65,62,26,0],
[65,62,26,1],
[65,62,26,2],
[65,63,3,0],
[65,63,3,1],
[65,63,3,2],
[65,63,4,0],
[65,63,4,1],
[65,63,4,2],
[65,63,4,3],
[65,63,5,0],
[65,63,5,1],
[65,63,5,2],
[65,63,5,4],
[65,63,6,0],
[65,63,6,1],
[65,63,6,2],
[65,63,6,4],
[65,63,7,0],
[65,63,7,1],
[65,63,7,2],
[65,63,7,3],
[65,63,7,4],
[65,63,7,6],
[65,63,8,0],
[65,63,8,1],
[65,63,8,2],
[65,63,8,7],
[65,63,10,3],
[65,63,10,4],
[65,63,10,5],
[65,63,10,6],
[65,63,10,7],
[65,63,10,8],
[65,63,11,3],
[65,63,11,4],
[65,63,11,5],
[65,63,11,6],
[65,63,11,7],
[65,63,11,8],
[65,63,11,10],
[65,63,12,0],
[65,63,12,1],
[65,63,12,2],
[65,63,12,3],
[65,63,12,4],
[65,63,12,5],
[65,63,12,6],
[65,63,12,7],
[65,63,12,8],
[65,63,12,10],
[65,63,13,0],
[65,63,13,1],
[65,63,13,2],
[65,63,13,3],
[65,63,13,4],
[65,63,13,5],
[65,63,13,6],
[65,63,13,7],
[65,63,13,8],
[65,63,13,10],
[65,63,13,11],
[65,63,13,12],
[65,63,14,0],
[65,63,14,1],
[65,63,14,2],
[65,63,14,3],
[65,63,14,4],
[65,63,14,5],
[65,63,14,6],
[65,63,14,7],
[65,63,14,8],
[65,63,14,10],
[65,63,14,11],
[65,63,14,12],
[65,63,16,0],
[65,63,16,1],
[65,63,16,2],
[65,63,16,3],
[65,63,16,4],
[65,63,16,5],
[65,63,16,6],
[65,63,16,7],
[65,63,16,8],
[65,63,16,10],
[65,63,16,11],
[65,63,16,12],
[65,63,16,13],
[65,63,16,14],
[65,63,17,0],
[65,63,17,1],
[65,63,17,2],
[65,63,17,3],
[65,63,17,4],
[65,63,17,5],
[65,63,17,6],
[65,63,17,7],
[65,63,17,8],
[65,63,17,10],
[65,63,17,11],
[65,63,17,12],
[65,63,17,13],
[65,63,17,14],
[65,63,19,0],
[65,63,19,1],
[65,63,19,2],
[65,63,19,3],
[65,63,19,4],
[65,63,19,5],
[65,63,19,6],
[65,63,19,7],
[65,63,19,8],
[65,63,19,10],
[65,63,19,11],
[65,63,19,12],
[65,63,20,0],
[65,63,20,1],
[65,63,20,2],
[65,63,20,3],
[65,63,20,4],
[65,63,20,5],
[65,63,20,6],
[65,63,20,7],
[65,63,20,8],
[65,63,20,11],
[65,63,21,0],
[65,63,21,1],
[65,63,21,2],
[65,63,21,4],
[65,63,21,7],
[65,63,22,0],
[65,63,22,1],
[65,63,22,2],
[65,63,22,3],
[65,63,22,4],
[65,63,22,5],
[65,63,22,6],
[65,63,22,7],
[65,63,24,0],
[65,63,24,1],
[65,63,24,2],
[65,63,24,3],
[65,63,24,4],
[65,63,24,5],
[65,63,25,0],
[65,63,25,1],
[65,63,25,2],
[65,64,3,0],
[65,64,3,1],
[65,64,3,2],
[65,64,5,0],
[65,64,5,1],
[65,64,5,2],
[65,64,6,0],
[65,64,6,1],
[65,64,6,2],
[65,64,7,0],
[65,64,7,1],
[65,64,7,2],
[65,64,7,5],
[65,64,7,6],
[65,64,8,7],
[65,64,9,3],
[65,64,9,5],
[65,64,9,6],
[65,64,9,7],
[65,64,9,8],
[65,64,10,0],
[65,64,10,1],
[65,64,10,2],
[65,64,10,3],
[65,64,10,5],
[65,64,10,6],
[65,64,10,7],
[65,64,10,8],
[65,64,10,9],
[65,64,11,0],
[65,64,11,1],
[65,64,11,2],
[65,64,11,3],
[65,64,11,5],
[65,64,11,6],
[65,64,11,7],
[65,64,11,8],
[65,64,11,9],
[65,64,11,10],
[65,64,12,0],
[65,64,12,1],
[65,64,12,2],
[65,64,12,3],
[65,64,12,5],
[65,64,12,6],
[65,64,12,7],
[65,64,12,8],
[65,64,12,9],
[65,64,12,10],
[65,64,13,0],
[65,64,13,1],
[65,64,13,2],
[65,64,13,3],
[65,64,13,5],
[65,64,13,6],
[65,64,13,7],
[65,64,13,8],
[65,64,13,9],
[65,64,13,10],
[65,64,13,11],
[65,64,13,12],
[65,64,14,0],
[65,64,14,1],
[65,64,14,2],
[65,64,14,3],
[65,64,14,5],
[65,64,14,6],
[65,64,14,7],
[65,64,14,8],
[65,64,14,9],
[65,64,14,10],
[65,64,14,11],
[65,64,14,12],
[65,64,15,0],
[65,64,15,1],
[65,64,15,2],
[65,64,15,3],
[65,64,15,5],
[65,64,15,6],
[65,64,15,7],
[65,64,15,8],
[65,64,15,10],
[65,64,15,11],
[65,64,15,12],
[65,64,15,13],
[65,64,15,14],
[65,64,16,0],
[65,64,16,1],
[65,64,16,2],
[65,64,16,3],
[65,64,16,5],
[65,64,16,6],
[65,64,16,7],
[65,64,16,8],
[65,64,16,9],
[65,64,16,10],
[65,64,16,11],
[65,64,16,12],
[65,64,16,13],
[65,64,16,14],
[65,64,17,0],
[65,64,17,1],
[65,64,17,2],
[65,64,17,3],
[65,64,17,5],
[65,64,17,6],
[65,64,17,7],
[65,64,17,8],
[65,64,17,9],
[65,64,17,10],
[65,64,17,11],
[65,64,17,12],
[65,64,17,13],
[65,64,17,14],
[65,64,18,0],
[65,64,18,1],
[65,64,18,2],
[65,64,18,3],
[65,64,18,5],
[65,64,18,6],
[65,64,18,7],
[65,64,18,8],
[65,64,18,10],
[65,64,18,11],
[65,64,18,12],
[65,64,18,13],
[65,64,19,0],
[65,64,19,1],
[65,64,19,2],
[65,64,19,3],
[65,64,19,5],
[65,64,19,6],
[65,64,19,7],
[65,64,19,8],
[65,64,19,9],
[65,64,19,10],
[65,64,19,11],
[65,64,20,0],
[65,64,20,1],
[65,64,20,2],
[65,64,20,3],
[65,64,20,5],
[65,64,20,6],
[65,64,20,7],
[65,64,20,8],
[65,64,20,9],
[65,64,21,0],
[65,64,21,1],
[65,64,21,2],
[65,64,21,7],
[65,64,22,0],
[65,64,22,1],
[65,64,22,2],
[65,64,22,3],
[65,64,22,5],
[65,64,24,0],
[65,64,24,1],
[65,64,24,2],
[65,64,24,3],
[66,4,3,0],
[66,4,3,1],
[66,4,3,2],
[66,5,4,0],
[66,5,4,1],
[66,5,4,2],
[66,6,4,0],
[66,6,4,1],
[66,6,4,2],
[66,8,4,0],
[66,8,4,1],
[66,8,4,2],
[66,9,3,0],
[66,9,3,1],
[66,9,3,2],
[66,9,4,0],
[66,9,4,1],
[66,9,4,2],
[66,9,4,3],
[66,9,5,0],
[66,9,5,1],
[66,9,5,2],
[66,9,5,4],
[66,9,6,0],
[66,9,6,1],
[66,9,6,2],
[66,9,6,4],
[66,9,8,0],
[66,9,8,1],
[66,9,8,2],
[66,9,8,4],
[66,10,3,0],
[66,10,3,1],
[66,10,3,2],
[66,10,4,0],
[66,10,4,1],
[66,10,4,2],
[66,10,4,3],
[66,10,5,0],
[66,10,5,1],
[66,10,5,2],
[66,10,5,4],
[66,10,6,0],
[66,10,6,1],
[66,10,6,2],
[66,10,6,4],
[66,10,8,0],
[66,10,8,1],
[66,10,8,2],
[66,10,8,4],
[66,10,9,0],
[66,10,9,1],
[66,10,9,2],
[66,10,9,3],
[66,10,9,4],
[66,10,9,5],
[66,10,9,6],
[66,10,9,8],
[66,11,3,0],
[66,11,3,1],
[66,11,3,2],
[66,11,4,0],
[66,11,4,1],
[66,11,4,2],
[66,11,4,3],
[66,11,5,0],
[66,11,5,1],
[66,11,5,2],
[66,11,5,4],
[66,11,6,0],
[66,11,6,1],
[66,11,6,2],
[66,11,6,4],
[66,11,8,0],
[66,11,8,1],
[66,11,8,2],
[66,11,8,4],
[66,11,9,0],
[66,11,9,1],
[66,11,9,2],
[66,11,9,3],
[66,11,9,4],
[66,11,9,5],
[66,11,9,6],
[66,11,9,8],
[66,11,10,0],
[66,11,10,1],
[66,11,10,2],
[66,11,10,3],
[66,11,10,4],
[66,11,10,5],
[66,11,10,6],
[66,11,10,8],
[66,11,10,9],
[66,12,3,0],
[66,12,3,1],
[66,12,3,2],
[66,12,4,0],
[66,12,4,1],
[66,12,4,2],
[66,12,4,3],
[66,12,5,0],
[66,12,5,1],
[66,12,5,2],
[66,12,5,4],
[66,12,6,0],
[66,12,6,1],
[66,12,6,2],
[66,12,6,4],
[66,12,8,0],
[66,12,8,1],
[66,12,8,2],
[66,12,8,4],
[66,12,9,0],
[66,12,9,1],
[66,12,9,2],
[66,12,9,3],
[66,12,9,4],
[66,12,9,5],
[66,12,9,6],
[66,12,9,8],
[66,12,10,0],
[66,12,10,1],
[66,12,10,2],
[66,12,10,3],
[66,12,10,4],
[66,12,10,5],
[66,12,10,6],
[66,12,10,8],
[66,12,10,9],
[66,13,3,0],
[66,13,3,1],
[66,13,3,2],
[66,13,4,0],
[66,13,4,1],
[66,13,4,2],
[66,13,4,3],
[66,13,5,0],
[66,13,5,1],
[66,13,5,2],
[66,13,5,4],
[66,13,6,0],
[66,13,6,1],
[66,13,6,2],
[66,13,6,4],
[66,13,8,0],
[66,13,8,1],
[66,13,8,2],
[66,13,8,4],
[66,13,9,0],
[66,13,9,1],
[66,13,9,2],
[66,13,9,3],
[66,13,9,4],
[66,13,9,5],
[66,13,9,6],
[66,13,9,8],
[66,13,10,0],
[66,13,10,1],
[66,13,10,2],
[66,13,10,3],
[66,13,10,4],
[66,13,10,5],
[66,13,10,6],
[66,13,10,8],
[66,13,10,9],
[66,13,11,0],
[66,13,11,1],
[66,13,11,2],
[66,13,11,3],
[66,13,11,4],
[66,13,11,5],
[66,13,11,6],
[66,13,11,8],
[66,13,11,9],
[66,13,11,10],
[66,13,12,0],
[66,13,12,1],
[66,13,12,2],
[66,13,12,3],
[66,13,12,4],
[66,13,12,5],
[66,13,12,6],
[66,13,12,8],
[66,13,12,9],
[66,13,12,10],
[66,14,3,0],
[66,14,3,1],
[66,14,3,2],
[66,14,4,0],
[66,14,4,1],
[66,14,4,2],
[66,14,4,3],
[66,14,5,0],
[66,14,5,1],
[66,14,5,2],
[66,14,5,4],
[66,14,6,0],
[66,14,6,1],
[66,14,6,2],
[66,14,6,4],
[66,14,8,0],
[66,14,8,1],
[66,14,8,2],
[66,14,8,4],
[66,14,9,0],
[66,14,9,1],
[66,14,9,2],
[66,14,9,3],
[66,14,9,4],
[66,14,9,5],
[66,14,9,6],
[66,14,9,8],
[66,14,10,0],
[66,14,10,1],
[66,14,10,2],
[66,14,10,3],
[66,14,10,4],
[66,14,10,5],
[66,14,10,6],
[66,14,10,8],
[66,14,10,9],
[66,14,11,0],
[66,14,11,1],
[66,14,11,2],
[66,14,11,3],
[66,14,11,4],
[66,14,11,5],
[66,14,11,6],
[66,14,11,8],
[66,14,11,9],
[66,14,11,10],
[66,14,12,0],
[66,14,12,1],
[66,14,12,2],
[66,14,12,3],
[66,14,12,4],
[66,14,12,5],
[66,14,12,6],
[66,14,12,8],
[66,14,12,9],
[66,14,12,10],
[66,15,3,0],
[66,15,3,1],
[66,15,3,2],
[66,15,4,0],
[66,15,4,1],
[66,15,4,2],
[66,15,4,3],
[66,15,5,0],
[66,15,5,1],
[66,15,5,2],
[66,15,5,4],
[66,15,6,0],
[66,15,6,1],
[66,15,6,2],
[66,15,6,4],
[66,15,8,0],
[66,15,8,1],
[66,15,8,2],
[66,15,8,4],
[66,15,10,0],
[66,15,10,1],
[66,15,10,2],
[66,15,10,3],
[66,15,10,4],
[66,15,10,5],
[66,15,10,6],
[66,15,10,8],
[66,15,11,0],
[66,15,11,1],
[66,15,11,2],
[66,15,11,3],
[66,15,11,4],
[66,15,11,5],
[66,15,11,6],
[66,15,11,8],
[66,15,11,10],
[66,15,12,0],
[66,15,12,1],
[66,15,12,2],
[66,15,12,3],
[66,15,12,4],
[66,15,12,5],
[66,15,12,6],
[66,15,12,8],
[66,15,12,10],
[66,15,13,0],
[66,15,13,1],
[66,15,13,2],
[66,15,13,3],
[66,15,13,4],
[66,15,13,5],
[66,15,13,6],
[66,15,13,8],
[66,15,13,10],
[66,15,13,11],
[66,15,13,12],
[66,15,14,0],
[66,15,14,1],
[66,15,14,2],
[66,15,14,3],
[66,15,14,4],
[66,15,14,5],
[66,15,14,6],
[66,15,14,8],
[66,15,14,10],
[66,15,14,11],
[66,15,14,12],
[66,16,3,0],
[66,16,3,1],
[66,16,3,2],
[66,16,4,0],
[66,16,4,1],
[66,16,4,2],
[66,16,4,3],
[66,16,5,0],
[66,16,5,1],
[66,16,5,2],
[66,16,5,4],
[66,16,6,0],
[66,16,6,1],
[66,16,6,2],
[66,16,6,4],
[66,16,8,0],
[66,16,8,1],
[66,16,8,2],
[66,16,8,4],
[66,16,9,0],
[66,16,9,1],
[66,16,9,2],
[66,16,9,3],
[66,16,9,4],
[66,16,9,5],
[66,16,9,6],
[66,16,9,8],
[66,16,10,0],
[66,16,10,1],
[66,16,10,2],
[66,16,10,3],
[66,16,10,4],
[66,16,10,5],
[66,16,10,6],
[66,16,10,8],
[66,16,10,9],
[66,16,11,0],
[66,16,11,1],
[66,16,11,2],
[66,16,11,3],
[66,16,11,4],
[66,16,11,5],
[66,16,11,6],
[66,16,11,8],
[66,16,11,9],
[66,16,11,10],
[66,16,12,0],
[66,16,12,1],
[66,16,12,2],
[66,16,12,3],
[66,16,12,4],
[66,16,12,5],
[66,16,12,6],
[66,16,12,8],
[66,16,12,9],
[66,16,12,10],
[66,16,13,0],
[66,16,13,1],
[66,16,13,2],
[66,16,13,3],
[66,16,13,4],
[66,16,13,5],
[66,16,13,6],
[66,16,13,8],
[66,16,13,9],
[66,16,13,10],
[66,16,13,11],
[66,16,13,12],
[66,16,14,0],
[66,16,14,1],
[66,16,14,2],
[66,16,14,3],
[66,16,14,4],
[66,16,14,5],
[66,16,14,6],
[66,16,14,8],
[66,16,14,9],
[66,16,14,10],
[66,16,14,11],
[66,16,14,12],
[66,16,15,0],
[66,16,15,1],
[66,16,15,2],
[66,16,15,3],
[66,16,15,4],
[66,16,15,5],
[66,16,15,6],
[66,16,15,8],
[66,16,15,10],
[66,16,15,11],
[66,16,15,12],
[66,16,15,13],
[66,16,15,14],
[66,17,3,0],
[66,17,3,1],
[66,17,3,2],
[66,17,4,0],
[66,17,4,1],
[66,17,4,2],
[66,17,4,3],
[66,17,5,0],
[66,17,5,1],
[66,17,5,2],
[66,17,5,4],
[66,17,6,0],
[66,17,6,1],
[66,17,6,2],
[66,17,6,4],
[66,17,8,0],
[66,17,8,1],
[66,17,8,2],
[66,17,8,4],
[66,17,9,0],
[66,17,9,1],
[66,17,9,2],
[66,17,9,3],
[66,17,9,4],
[66,17,9,5],
[66,17,9,6],
[66,17,9,8],
[66,17,10,0],
[66,17,10,1],
[66,17,10,2],
[66,17,10,3],
[66,17,10,4],
[66,17,10,5],
[66,17,10,6],
[66,17,10,8],
[66,17,10,9],
[66,17,11,0],
[66,17,11,1],
[66,17,11,2],
[66,17,11,3],
[66,17,11,4],
[66,17,11,5],
[66,17,11,6],
[66,17,11,8],
[66,17,11,9],
[66,17,11,10],
[66,17,12,0],
[66,17,12,1],
[66,17,12,2],
[66,17,12,3],
[66,17,12,4],
[66,17,12,5],
[66,17,12,6],
[66,17,12,8],
[66,17,12,9],
[66,17,12,10],
[66,17,13,0],
[66,17,13,1],
[66,17,13,2],
[66,17,13,3],
[66,17,13,4],
[66,17,13,5],
[66,17,13,6],
[66,17,13,8],
[66,17,13,9],
[66,17,13,10],
[66,17,13,11],
[66,17,13,12],
[66,17,14,0],
[66,17,14,1],
[66,17,14,2],
[66,17,14,3],
[66,17,14,4],
[66,17,14,5],
[66,17,14,6],
[66,17,14,8],
[66,17,14,9],
[66,17,14,10],
[66,17,14,11],
[66,17,14,12],
[66,17,15,0],
[66,17,15,1],
[66,17,15,2],
[66,17,15,3],
[66,17,15,4],
[66,17,15,5],
[66,17,15,6],
[66,17,15,8],
[66,17,15,10],
[66,17,15,11],
[66,17,15,12],
[66,17,15,13],
[66,17,15,14],
[66,17,16,0],
[66,17,16,1],
[66,17,16,2],
[66,17,16,3],
[66,17,16,4],
[66,17,16,5],
[66,17,16,6],
[66,17,16,8],
[66,17,16,9],
[66,17,16,10],
[66,17,16,11],
[66,17,16,12],
[66,17,16,13],
[66,17,16,14],
[66,17,16,15],
[66,18,3,0],
[66,18,3,1],
[66,18,3,2],
[66,18,4,0],
[66,18,4,1],
[66,18,4,2],
[66,18,4,3],
[66,18,5,0],
[66,18,5,1],
[66,18,5,2],
[66,18,5,4],
[66,18,6,0],
[66,18,6,1],
[66,18,6,2],
[66,18,6,4],
[66,18,8,0],
[66,18,8,1],
[66,18,8,2],
[66,18,8,4],
[66,18,10,0],
[66,18,10,1],
[66,18,10,2],
[66,18,10,3],
[66,18,10,4],
[66,18,10,5],
[66,18,10,6],
[66,18,10,8],
[66,18,11,0],
[66,18,11,1],
[66,18,11,2],
[66,18,11,3],
[66,18,11,4],
[66,18,11,5],
[66,18,11,6],
[66,18,11,8],
[66,18,11,10],
[66,18,12,0],
[66,18,12,1],
[66,18,12,2],
[66,18,12,3],
[66,18,12,4],
[66,18,12,5],
[66,18,12,6],
[66,18,12,8],
[66,18,12,10],
[66,18,13,0],
[66,18,13,1],
[66,18,13,2],
[66,18,13,3],
[66,18,13,4],
[66,18,13,5],
[66,18,13,6],
[66,18,13,8],
[66,18,13,10],
[66,18,13,11],
[66,18,13,12],
[66,18,14,0],
[66,18,14,1],
[66,18,14,2],
[66,18,14,3],
[66,18,14,4],
[66,18,14,5],
[66,18,14,6],
[66,18,14,8],
[66,18,14,10],
[66,18,14,11],
[66,18,14,12],
[66,18,16,0],
[66,18,16,1],
[66,18,16,2],
[66,18,16,3],
[66,18,16,4],
[66,18,16,5],
[66,18,16,6],
[66,18,16,8],
[66,18,16,10],
[66,18,16,11],
[66,18,16,12],
[66,18,16,13],
[66,18,16,14],
[66,18,17,0],
[66,18,17,1],
[66,18,17,2],
[66,18,17,3],
[66,18,17,4],
[66,18,17,5],
[66,18,17,6],
[66,18,17,8],
[66,18,17,10],
[66,18,17,11],
[66,18,17,12],
[66,18,17,13],
[66,18,17,14],
[66,18,17,16],
[66,19,3,0],
[66,19,3,1],
[66,19,3,2],
[66,19,4,0],
[66,19,4,1],
[66,19,4,2],
[66,19,4,3],
[66,19,5,0],
[66,19,5,1],
[66,19,5,2],
[66,19,5,4],
[66,19,6,0],
[66,19,6,1],
[66,19,6,2],
[66,19,6,4],
[66,19,8,0],
[66,19,8,1],
[66,19,8,2],
[66,19,8,4],
[66,19,9,0],
[66,19,9,1],
[66,19,9,2],
[66,19,9,3],
[66,19,9,4],
[66,19,9,5],
[66,19,9,6],
[66,19,9,8],
[66,19,10,0],
[66,19,10,1],
[66,19,10,2],
[66,19,10,3],
[66,19,10,4],
[66,19,10,5],
[66,19,10,6],
[66,19,10,8],
[66,19,10,9],
[66,19,11,0],
[66,19,11,1],
[66,19,11,2],
[66,19,11,3],
[66,19,11,4],
[66,19,11,5],
[66,19,11,6],
[66,19,11,8],
[66,19,11,9],
[66,19,11,10],
[66,19,12,0],
[66,19,12,1],
[66,19,12,2],
[66,19,12,3],
[66,19,12,4],
[66,19,12,5],
[66,19,12,6],
[66,19,12,8],
[66,19,12,9],
[66,19,12,10],
[66,19,15,0],
[66,19,15,1],
[66,19,15,2],
[66,19,15,3],
[66,19,15,4],
[66,19,15,5],
[66,19,15,6],
[66,19,15,8],
[66,19,15,10],
[66,19,15,11],
[66,19,15,12],
[66,19,16,0],
[66,19,16,1],
[66,19,16,2],
[66,19,16,3],
[66,19,16,4],
[66,19,16,5],
[66,19,16,6],
[66,19,16,8],
[66,19,16,9],
[66,19,16,10],
[66,19,16,11],
[66,19,16,12],
[66,19,16,15],
[66,19,17,0],
[66,19,17,1],
[66,19,17,2],
[66,19,17,3],
[66,19,17,4],
[66,19,17,5],
[66,19,17,6],
[66,19,17,8],
[66,19,17,9],
[66,19,17,10],
[66,19,17,11],
[66,19,17,12],
[66,19,17,15],
[66,19,17,16],
[66,19,18,0],
[66,19,18,1],
[66,19,18,2],
[66,19,18,3],
[66,19,18,4],
[66,19,18,5],
[66,19,18,6],
[66,19,18,8],
[66,19,18,10],
[66,19,18,11],
[66,19,18,12],
[66,19,18,16],
[66,19,18,17],
[66,20,3,0],
[66,20,3,1],
[66,20,3,2],
[66,20,4,0],
[66,20,4,1],
[66,20,4,2],
[66,20,4,3],
[66,20,5,0],
[66,20,5,1],
[66,20,5,2],
[66,20,5,4],
[66,20,6,0],
[66,20,6,1],
[66,20,6,2],
[66,20,6,4],
[66,20,8,0],
[66,20,8,1],
[66,20,8,2],
[66,20,8,4],
[66,20,9,0],
[66,20,9,1],
[66,20,9,2],
[66,20,9,3],
[66,20,9,4],
[66,20,9,5],
[66,20,9,6],
[66,20,9,8],
[66,20,11,0],
[66,20,11,1],
[66,20,11,2],
[66,20,11,3],
[66,20,11,4],
[66,20,11,5],
[66,20,11,6],
[66,20,11,8],
[66,20,11,9],
[66,20,12,0],
[66,20,12,1],
[66,20,12,2],
[66,20,12,3],
[66,20,12,4],
[66,20,12,5],
[66,20,12,6],
[66,20,12,8],
[66,20,12,9],
[66,20,13,0],
[66,20,13,1],
[66,20,13,2],
[66,20,13,3],
[66,20,13,4],
[66,20,13,5],
[66,20,13,6],
[66,20,13,8],
[66,20,13,9],
[66,20,13,11],
[66,20,13,12],
[66,20,14,0],
[66,20,14,1],
[66,20,14,2],
[66,20,14,3],
[66,20,14,4],
[66,20,14,5],
[66,20,14,6],
[66,20,14,8],
[66,20,14,9],
[66,20,14,11],
[66,20,14,12],
[66,20,15,0],
[66,20,15,1],
[66,20,15,2],
[66,20,15,3],
[66,20,15,4],
[66,20,15,5],
[66,20,15,6],
[66,20,15,8],
[66,20,15,11],
[66,20,15,12],
[66,20,15,13],
[66,20,15,14],
[66,20,16,0],
[66,20,16,1],
[66,20,16,2],
[66,20,16,3],
[66,20,16,4],
[66,20,16,5],
[66,20,16,6],
[66,20,16,8],
[66,20,16,9],
[66,20,16,11],
[66,20,16,12],
[66,20,16,13],
[66,20,16,14],
[66,20,16,15],
[66,20,17,0],
[66,20,17,1],
[66,20,17,2],
[66,20,17,3],
[66,20,17,4],
[66,20,17,5],
[66,20,17,6],
[66,20,17,8],
[66,20,17,9],
[66,20,17,11],
[66,20,17,12],
[66,20,17,13],
[66,20,17,14],
[66,20,17,15],
[66,20,17,16],
[66,20,18,0],
[66,20,18,1],
[66,20,18,2],
[66,20,18,3],
[66,20,18,4],
[66,20,18,5],
[66,20,18,6],
[66,20,18,8],
[66,20,18,11],
[66,20,18,12],
[66,20,18,13],
[66,20,18,14],
[66,20,18,16],
[66,20,18,17],
[66,20,19,0],
[66,20,19,1],
[66,20,19,2],
[66,20,19,3],
[66,20,19,4],
[66,20,19,5],
[66,20,19,6],
[66,20,19,8],
[66,20,19,9],
[66,20,19,11],
[66,20,19,12],
[66,20,19,15],
[66,20,19,16],
[66,20,19,17],
[66,20,19,18],
[66,21,4,0],
[66,21,4,1],
[66,21,4,2],
[66,21,9,0],
[66,21,9,1],
[66,21,9,2],
[66,21,9,4],
[66,21,10,0],
[66,21,10,1],
[66,21,10,2],
[66,21,10,4],
[66,21,10,9],
[66,21,11,0],
[66,21,11,1],
[66,21,11,2],
[66,21,11,4],
[66,21,11,9],
[66,21,11,10],
[66,21,12,0],
[66,21,12,1],
[66,21,12,2],
[66,21,12,4],
[66,21,12,9],
[66,21,12,10],
[66,21,13,0],
[66,21,13,1],
[66,21,13,2],
[66,21,13,4],
[66,21,13,9],
[66,21,13,10],
[66,21,13,11],
[66,21,13,12],
[66,21,14,0],
[66,21,14,1],
[66,21,14,2],
[66,21,14,4],
[66,21,14,9],
[66,21,14,10],
[66,21,14,11],
[66,21,14,12],
[66,21,15,0],
[66,21,15,1],
[66,21,15,2],
[66,21,15,4],
[66,21,15,10],
[66,21,15,11],
[66,21,15,12],
[66,21,15,13],
[66,21,15,14],
[66,21,16,0],
[66,21,16,1],
[66,21,16,2],
[66,21,16,4],
[66,21,16,9],
[66,21,16,10],
[66,21,16,11],
[66,21,16,12],
[66,21,16,13],
[66,21,16,14],
[66,21,16,15],
[66,21,17,0],
[66,21,17,1],
[66,21,17,2],
[66,21,17,4],
[66,21,17,9],
[66,21,17,10],
[66,21,17,11],
[66,21,17,12],
[66,21,17,13],
[66,21,17,14],
[66,21,17,15],
[66,21,17,16],
[66,21,18,0],
[66,21,18,1],
[66,21,18,2],
[66,21,18,4],
[66,21,18,10],
[66,21,18,11],
[66,21,18,12],
[66,21,18,13],
[66,21,18,14],
[66,21,18,16],
[66,21,18,17],
[66,21,19,0],
[66,21,19,1],
[66,21,19,2],
[66,21,19,4],
[66,21,19,9],
[66,21,19,10],
[66,21,19,11],
[66,21,19,12],
[66,21,19,15],
[66,21,19,16],
[66,21,19,17],
[66,21,19,18],
[66,21,20,0],
[66,21,20,1],
[66,21,20,2],
[66,21,20,4],
[66,21,20,9],
[66,21,20,11],
[66,21,20,12],
[66,21,20,13],
[66,21,20,14],
[66,21,20,15],
[66,21,20,16],
[66,21,20,17],
[66,21,20,18],
[66,21,20,19],
[66,22,3,0],
[66,22,3,1],
[66,22,3,2],
[66,22,4,0],
[66,22,4,1],
[66,22,4,2],
[66,22,4,3],
[66,22,5,0],
[66,22,5,1],
[66,22,5,2],
[66,22,5,4],
[66,22,6,0],
[66,22,6,1],
[66,22,6,2],
[66,22,6,4],
[66,22,8,0],
[66,22,8,1],
[66,22,8,2],
[66,22,8,4],
[66,22,9,0],
[66,22,9,1],
[66,22,9,2],
[66,22,9,3],
[66,22,9,4],
[66,22,9,5],
[66,22,9,6],
[66,22,9,8],
[66,22,11,0],
[66,22,11,1],
[66,22,11,2],
[66,22,11,3],
[66,22,11,4],
[66,22,11,5],
[66,22,11,6],
[66,22,11,8],
[66,22,11,9],
[66,22,12,0],
[66,22,12,1],
[66,22,12,2],
[66,22,12,3],
[66,22,12,4],
[66,22,12,5],
[66,22,12,6],
[66,22,12,8],
[66,22,12,9],
[66,22,13,0],
[66,22,13,1],
[66,22,13,2],
[66,22,13,3],
[66,22,13,4],
[66,22,13,5],
[66,22,13,6],
[66,22,13,8],
[66,22,13,9],
[66,22,13,11],
[66,22,13,12],
[66,22,14,0],
[66,22,14,1],
[66,22,14,2],
[66,22,14,3],
[66,22,14,4],
[66,22,14,5],
[66,22,14,6],
[66,22,14,8],
[66,22,14,9],
[66,22,14,11],
[66,22,14,12],
[66,22,15,0],
[66,22,15,1],
[66,22,15,2],
[66,22,15,3],
[66,22,15,4],
[66,22,15,5],
[66,22,15,6],
[66,22,15,8],
[66,22,15,11],
[66,22,15,12],
[66,22,15,13],
[66,22,15,14],
[66,22,16,0],
[66,22,16,1],
[66,22,16,2],
[66,22,16,3],
[66,22,16,4],
[66,22,16,5],
[66,22,16,6],
[66,22,16,8],
[66,22,16,9],
[66,22,16,11],
[66,22,16,12],
[66,22,16,13],
[66,22,16,14],
[66,22,16,15],
[66,22,17,0],
[66,22,17,1],
[66,22,17,2],
[66,22,17,3],
[66,22,17,4],
[66,22,17,5],
[66,22,17,6],
[66,22,17,8],
[66,22,17,9],
[66,22,17,11],
[66,22,17,12],
[66,22,17,13],
[66,22,17,14],
[66,22,17,15],
[66,22,17,16],
[66,22,18,0],
[66,22,18,1],
[66,22,18,2],
[66,22,18,3],
[66,22,18,4],
[66,22,18,5],
[66,22,18,6],
[66,22,18,8],
[66,22,18,11],
[66,22,18,12],
[66,22,18,13],
[66,22,18,14],
[66,22,18,16],
[66,22,18,17],
[66,22,19,0],
[66,22,19,1],
[66,22,19,2],
[66,22,19,3],
[66,22,19,4],
[66,22,19,5],
[66,22,19,6],
[66,22,19,8],
[66,22,19,9],
[66,22,19,11],
[66,22,19,12],
[66,22,19,15],
[66,22,19,16],
[66,22,19,17],
[66,22,19,18],
[66,22,21,0],
[66,22,21,1],
[66,22,21,2],
[66,22,21,4],
[66,22,21,9],
[66,22,21,11],
[66,22,21,12],
[66,22,21,13],
[66,22,21,14],
[66,22,21,15],
[66,22,21,16],
[66,22,21,17],
[66,22,21,18],
[66,22,21,19],
[66,23,3,0],
[66,23,3,1],
[66,23,3,2],
[66,23,4,0],
[66,23,4,1],
[66,23,4,2],
[66,23,4,3],
[66,23,5,0],
[66,23,5,1],
[66,23,5,2],
[66,23,5,4],
[66,23,6,0],
[66,23,6,1],
[66,23,6,2],
[66,23,6,4],
[66,23,8,0],
[66,23,8,1],
[66,23,8,2],
[66,23,8,4],
[66,23,9,0],
[66,23,9,1],
[66,23,9,2],
[66,23,9,3],
[66,23,9,4],
[66,23,9,5],
[66,23,9,6],
[66,23,9,8],
[66,23,10,0],
[66,23,10,1],
[66,23,10,2],
[66,23,10,3],
[66,23,10,4],
[66,23,10,5],
[66,23,10,6],
[66,23,10,8],
[66,23,10,9],
[66,23,11,0],
[66,23,11,1],
[66,23,11,2],
[66,23,11,3],
[66,23,11,4],
[66,23,11,5],
[66,23,11,6],
[66,23,11,8],
[66,23,11,9],
[66,23,11,10],
[66,23,12,0],
[66,23,12,1],
[66,23,12,2],
[66,23,12,3],
[66,23,12,4],
[66,23,12,5],
[66,23,12,6],
[66,23,12,8],
[66,23,12,9],
[66,23,12,10],
[66,23,13,0],
[66,23,13,1],
[66,23,13,2],
[66,23,13,3],
[66,23,13,4],
[66,23,13,5],
[66,23,13,6],
[66,23,13,8],
[66,23,13,9],
[66,23,13,10],
[66,23,13,11],
[66,23,13,12],
[66,23,14,0],
[66,23,14,1],
[66,23,14,2],
[66,23,14,3],
[66,23,14,4],
[66,23,14,5],
[66,23,14,6],
[66,23,14,8],
[66,23,14,9],
[66,23,14,10],
[66,23,14,11],
[66,23,14,12],
[66,23,15,0],
[66,23,15,1],
[66,23,15,2],
[66,23,15,3],
[66,23,15,4],
[66,23,15,5],
[66,23,15,6],
[66,23,15,8],
[66,23,15,10],
[66,23,15,11],
[66,23,15,12],
[66,23,15,13],
[66,23,15,14],
[66,23,16,0],
[66,23,16,1],
[66,23,16,2],
[66,23,16,3],
[66,23,16,4],
[66,23,16,5],
[66,23,16,6],
[66,23,16,8],
[66,23,16,9],
[66,23,16,10],
[66,23,16,11],
[66,23,16,12],
[66,23,16,13],
[66,23,16,14],
[66,23,16,15],
[66,23,17,0],
[66,23,17,1],
[66,23,17,2],
[66,23,17,3],
[66,23,17,4],
[66,23,17,5],
[66,23,17,6],
[66,23,17,8],
[66,23,17,9],
[66,23,17,10],
[66,23,17,11],
[66,23,17,12],
[66,23,17,13],
[66,23,17,14],
[66,23,17,15],
[66,23,17,16],
[66,23,18,0],
[66,23,18,1],
[66,23,18,2],
[66,23,18,3],
[66,23,18,4],
[66,23,18,5],
[66,23,18,6],
[66,23,18,8],
[66,23,18,10],
[66,23,18,11],
[66,23,18,12],
[66,23,18,13],
[66,23,18,14],
[66,23,18,16],
[66,23,18,17],
[66,23,19,0],
[66,23,19,1],
[66,23,19,2],
[66,23,19,3],
[66,23,19,4],
[66,23,19,5],
[66,23,19,6],
[66,23,19,8],
[66,23,19,9],
[66,23,19,10],
[66,23,19,11],
[66,23,19,12],
[66,23,19,15],
[66,23,19,16],
[66,23,19,17],
[66,23,19,18],
[66,23,20,0],
[66,23,20,1],
[66,23,20,2],
[66,23,20,3],
[66,23,20,4],
[66,23,20,5],
[66,23,20,6],
[66,23,20,8],
[66,23,20,9],
[66,23,20,11],
[66,23,20,12],
[66,23,20,13],
[66,23,20,14],
[66,23,20,15],
[66,23,20,16],
[66,23,20,17],
[66,23,20,18],
[66,23,20,19],
[66,23,21,0],
[66,23,21,1],
[66,23,21,2],
[66,23,21,4],
[66,23,21,9],
[66,23,21,10],
[66,23,21,11],
[66,23,21,12],
[66,23,21,13],
[66,23,21,14],
[66,23,21,15],
[66,23,21,16],
[66,23,21,17],
[66,23,21,18],
[66,23,21,19],
[66,23,21,20],
[66,23,22,0],
[66,23,22,1],
[66,23,22,2],
[66,23,22,3],
[66,23,22,4],
[66,23,22,5],
[66,23,22,6],
[66,23,22,8],
[66,23,22,9],
[66,23,22,11],
[66,23,22,12],
[66,23,22,13],
[66,23,22,14],
[66,23,22,15],
[66,23,22,16],
[66,23,22,17],
[66,23,22,18],
[66,23,22,19],
[66,23,22,21],
[66,24,3,0],
[66,24,3,1],
[66,24,3,2],
[66,24,4,0],
[66,24,4,1],
[66,24,4,2],
[66,24,4,3],
[66,24,5,0],
[66,24,5,1],
[66,24,5,2],
[66,24,5,4],
[66,24,6,0],
[66,24,6,1],
[66,24,6,2],
[66,24,6,4],
[66,24,8,0],
[66,24,8,1],
[66,24,8,2],
[66,24,8,4],
[66,24,9,0],
[66,24,9,1],
[66,24,9,2],
[66,24,9,3],
[66,24,9,4],
[66,24,9,5],
[66,24,9,6],
[66,24,9,8],
[66,24,10,0],
[66,24,10,1],
[66,24,10,2],
[66,24,10,3],
[66,24,10,4],
[66,24,10,5],
[66,24,10,6],
[66,24,10,8],
[66,24,10,9],
[66,24,11,0],
[66,24,11,1],
[66,24,11,2],
[66,24,11,3],
[66,24,11,4],
[66,24,11,5],
[66,24,11,6],
[66,24,11,8],
[66,24,11,9],
[66,24,11,10],
[66,24,12,0],
[66,24,12,1],
[66,24,12,2],
[66,24,12,3],
[66,24,12,4],
[66,24,12,5],
[66,24,12,6],
[66,24,12,8],
[66,24,12,9],
[66,24,12,10],
[66,24,13,0],
[66,24,13,1],
[66,24,13,2],
[66,24,13,3],
[66,24,13,4],
[66,24,13,5],
[66,24,13,6],
[66,24,13,8],
[66,24,13,9],
[66,24,13,10],
[66,24,13,11],
[66,24,13,12],
[66,24,14,0],
[66,24,14,1],
[66,24,14,2],
[66,24,14,3],
[66,24,14,4],
[66,24,14,5],
[66,24,14,6],
[66,24,14,8],
[66,24,14,9],
[66,24,14,10],
[66,24,14,11],
[66,24,14,12],
[66,24,15,0],
[66,24,15,1],
[66,24,15,2],
[66,24,15,3],
[66,24,15,4],
[66,24,15,5],
[66,24,15,6],
[66,24,15,8],
[66,24,15,10],
[66,24,15,11],
[66,24,15,12],
[66,24,15,13],
[66,24,15,14],
[66,24,17,0],
[66,24,17,1],
[66,24,17,2],
[66,24,17,3],
[66,24,17,4],
[66,24,17,5],
[66,24,17,6],
[66,24,17,8],
[66,24,17,9],
[66,24,17,10],
[66,24,17,11],
[66,24,17,12],
[66,24,17,13],
[66,24,17,14],
[66,24,17,15],
[66,24,18,0],
[66,24,18,1],
[66,24,18,2],
[66,24,18,3],
[66,24,18,4],
[66,24,18,5],
[66,24,18,6],
[66,24,18,8],
[66,24,18,10],
[66,24,18,11],
[66,24,18,12],
[66,24,18,13],
[66,24,18,14],
[66,24,18,17],
[66,24,19,0],
[66,24,19,1],
[66,24,19,2],
[66,24,19,3],
[66,24,19,4],
[66,24,19,5],
[66,24,19,6],
[66,24,19,8],
[66,24,19,9],
[66,24,19,10],
[66,24,19,11],
[66,24,19,12],
[66,24,19,15],
[66,24,19,17],
[66,24,19,18],
[66,24,20,0],
[66,24,20,1],
[66,24,20,2],
[66,24,20,3],
[66,24,20,4],
[66,24,20,5],
[66,24,20,6],
[66,24,20,8],
[66,24,20,9],
[66,24,20,11],
[66,24,20,12],
[66,24,20,13],
[66,24,20,14],
[66,24,20,15],
[66,24,20,17],
[66,24,20,18],
[66,24,20,19],
[66,24,21,0],
[66,24,21,1],
[66,24,21,2],
[66,24,21,4],
[66,24,21,9],
[66,24,21,10],
[66,24,21,11],
[66,24,21,12],
[66,24,21,13],
[66,24,21,14],
[66,24,21,15],
[66,24,21,17],
[66,24,21,18],
[66,24,21,19],
[66,24,21,20],
[66,24,22,0],
[66,24,22,1],
[66,24,22,2],
[66,24,22,3],
[66,24,22,4],
[66,24,22,5],
[66,24,22,6],
[66,24,22,8],
[66,24,22,9],
[66,24,22,11],
[66,24,22,12],
[66,24,22,13],
[66,24,22,14],
[66,24,22,15],
[66,24,22,17],
[66,24,22,18],
[66,24,22,19],
[66,24,22,21],
[66,24,23,0],
[66,24,23,1],
[66,24,23,2],
[66,24,23,3],
[66,24,23,4],
[66,24,23,5],
[66,24,23,6],
[66,24,23,8],
[66,24,23,9],
[66,24,23,10],
[66,24,23,11],
[66,24,23,12],
[66,24,23,13],
[66,24,23,14],
[66,24,23,15],
[66,24,23,17],
[66,24,23,18],
[66,24,23,19],
[66,24,23,20],
[66,24,23,21],
[66,24,23,22],
[66,26,3,0],
[66,26,3,1],
[66,26,3,2],
[66,26,4,0],
[66,26,4,1],
[66,26,4,2],
[66,26,4,3],
[66,26,5,0],
[66,26,5,1],
[66,26,5,2],
[66,26,5,4],
[66,26,6,0],
[66,26,6,1],
[66,26,6,2],
[66,26,6,4],
[66,26,8,0],
[66,26,8,1],
[66,26,8,2],
[66,26,8,4],
[66,26,10,0],
[66,26,10,1],
[66,26,10,2],
[66,26,10,3],
[66,26,10,4],
[66,26,10,5],
[66,26,10,6],
[66,26,10,8],
[66,26,11,0],
[66,26,11,1],
[66,26,11,2],
[66,26,11,3],
[66,26,11,4],
[66,26,11,5],
[66,26,11,6],
[66,26,11,8],
[66,26,11,10],
[66,26,12,0],
[66,26,12,1],
[66,26,12,2],
[66,26,12,3],
[66,26,12,4],
[66,26,12,5],
[66,26,12,6],
[66,26,12,8],
[66,26,12,10],
[66,26,13,0],
[66,26,13,1],
[66,26,13,2],
[66,26,13,3],
[66,26,13,4],
[66,26,13,5],
[66,26,13,6],
[66,26,13,8],
[66,26,13,10],
[66,26,13,11],
[66,26,13,12],
[66,26,14,0],
[66,26,14,1],
[66,26,14,2],
[66,26,14,3],
[66,26,14,4],
[66,26,14,5],
[66,26,14,6],
[66,26,14,8],
[66,26,14,10],
[66,26,14,11],
[66,26,14,12],
[66,26,16,0],
[66,26,16,1],
[66,26,16,2],
[66,26,16,3],
[66,26,16,4],
[66,26,16,5],
[66,26,16,6],
[66,26,16,8],
[66,26,16,10],
[66,26,16,11],
[66,26,16,12],
[66,26,16,13],
[66,26,16,14],
[66,26,17,0],
[66,26,17,1],
[66,26,17,2],
[66,26,17,3],
[66,26,17,4],
[66,26,17,5],
[66,26,17,6],
[66,26,17,8],
[66,26,17,10],
[66,26,17,11],
[66,26,17,12],
[66,26,17,13],
[66,26,17,14],
[66,26,17,16],
[66,26,19,0],
[66,26,19,1],
[66,26,19,2],
[66,26,19,3],
[66,26,19,4],
[66,26,19,5],
[66,26,19,6],
[66,26,19,8],
[66,26,19,10],
[66,26,19,11],
[66,26,19,12],
[66,26,19,16],
[66,26,19,17],
[66,26,20,0],
[66,26,20,1],
[66,26,20,2],
[66,26,20,3],
[66,26,20,4],
[66,26,20,5],
[66,26,20,6],
[66,26,20,8],
[66,26,20,11],
[66,26,20,12],
[66,26,20,13],
[66,26,20,14],
[66,26,20,16],
[66,26,20,17],
[66,26,20,19],
[66,26,21,0],
[66,26,21,1],
[66,26,21,2],
[66,26,21,4],
[66,26,21,10],
[66,26,21,11],
[66,26,21,12],
[66,26,21,13],
[66,26,21,14],
[66,26,21,16],
[66,26,21,17],
[66,26,21,19],
[66,26,21,20],
[66,26,22,0],
[66,26,22,1],
[66,26,22,2],
[66,26,22,3],
[66,26,22,4],
[66,26,22,5],
[66,26,22,6],
[66,26,22,8],
[66,26,22,11],
[66,26,22,12],
[66,26,22,13],
[66,26,22,14],
[66,26,22,16],
[66,26,22,17],
[66,26,22,19],
[66,26,22,21],
[66,26,23,0],
[66,26,23,1],
[66,26,23,2],
[66,26,23,3],
[66,26,23,4],
[66,26,23,5],
[66,26,23,6],
[66,26,23,8],
[66,26,23,10],
[66,26,23,11],
[66,26,23,12],
[66,26,23,13],
[66,26,23,14],
[66,26,23,16],
[66,26,23,17],
[66,26,23,19],
[66,26,23,20],
[66,26,23,21],
[66,26,23,22],
[66,26,24,0],
[66,26,24,1],
[66,26,24,2],
[66,26,24,3],
[66,26,24,4],
[66,26,24,5],
[66,26,24,6],
[66,26,24,8],
[66,26,24,10],
[66,26,24,11],
[66,26,24,12],
[66,26,24,13],
[66,26,24,14],
[66,26,24,17],
[66,26,24,19],
[66,26,24,20],
[66,26,24,21],
[66,26,24,22],
[66,27,3,0],
[66,27,3,1],
[66,27,3,2],
[66,27,4,0],
[66,27,4,1],
[66,27,4,2],
[66,27,4,3],
[66,27,5,0],
[66,27,5,1],
[66,27,5,2],
[66,27,5,4],
[66,27,6,0],
[66,27,6,1],
[66,27,6,2],
[66,27,6,4],
[66,27,8,0],
[66,27,8,1],
[66,27,8,2],
[66,27,8,4],
[66,27,9,0],
[66,27,9,1],
[66,27,9,2],
[66,27,9,3],
[66,27,9,4],
[66,27,9,5],
[66,27,9,6],
[66,27,9,8],
[66,27,11,0],
[66,27,11,1],
[66,27,11,2],
[66,27,11,3],
[66,27,11,4],
[66,27,11,5],
[66,27,11,6],
[66,27,11,8],
[66,27,11,9],
[66,27,12,0],
[66,27,12,1],
[66,27,12,2],
[66,27,12,3],
[66,27,12,4],
[66,27,12,5],
[66,27,12,6],
[66,27,12,8],
[66,27,12,9],
[66,27,13,0],
[66,27,13,1],
[66,27,13,2],
[66,27,13,3],
[66,27,13,4],
[66,27,13,5],
[66,27,13,6],
[66,27,13,8],
[66,27,13,9],
[66,27,13,11],
[66,27,13,12],
[66,27,14,0],
[66,27,14,1],
[66,27,14,2],
[66,27,14,3],
[66,27,14,4],
[66,27,14,5],
[66,27,14,6],
[66,27,14,8],
[66,27,14,9],
[66,27,14,11],
[66,27,14,12],
[66,27,15,0],
[66,27,15,1],
[66,27,15,2],
[66,27,15,3],
[66,27,15,4],
[66,27,15,5],
[66,27,15,6],
[66,27,15,8],
[66,27,15,11],
[66,27,15,12],
[66,27,15,13],
[66,27,15,14],
[66,27,16,0],
[66,27,16,1],
[66,27,16,2],
[66,27,16,3],
[66,27,16,4],
[66,27,16,5],
[66,27,16,6],
[66,27,16,8],
[66,27,16,9],
[66,27,16,11],
[66,27,16,12],
[66,27,16,13],
[66,27,16,14],
[66,27,16,15],
[66,27,17,0],
[66,27,17,1],
[66,27,17,2],
[66,27,17,3],
[66,27,17,4],
[66,27,17,5],
[66,27,17,6],
[66,27,17,8],
[66,27,17,9],
[66,27,17,11],
[66,27,17,12],
[66,27,17,13],
[66,27,17,14],
[66,27,17,15],
[66,27,17,16],
[66,27,18,0],
[66,27,18,1],
[66,27,18,2],
[66,27,18,3],
[66,27,18,4],
[66,27,18,5],
[66,27,18,6],
[66,27,18,8],
[66,27,18,11],
[66,27,18,12],
[66,27,18,13],
[66,27,18,14],
[66,27,18,16],
[66,27,18,17],
[66,27,19,0],
[66,27,19,1],
[66,27,19,2],
[66,27,19,3],
[66,27,19,4],
[66,27,19,5],
[66,27,19,6],
[66,27,19,8],
[66,27,19,9],
[66,27,19,11],
[66,27,19,12],
[66,27,19,15],
[66,27,19,16],
[66,27,19,17],
[66,27,19,18],
[66,27,21,0],
[66,27,21,1],
[66,27,21,2],
[66,27,21,4],
[66,27,21,9],
[66,27,21,11],
[66,27,21,12],
[66,27,21,13],
[66,27,21,14],
[66,27,21,15],
[66,27,21,16],
[66,27,21,17],
[66,27,21,18],
[66,27,21,19],
[66,27,23,0],
[66,27,23,1],
[66,27,23,2],
[66,27,23,3],
[66,27,23,4],
[66,27,23,5],
[66,27,23,6],
[66,27,23,8],
[66,27,23,9],
[66,27,23,11],
[66,27,23,12],
[66,27,23,13],
[66,27,23,14],
[66,27,23,15],
[66,27,23,16],
[66,27,23,17],
[66,27,23,18],
[66,27,23,19],
[66,27,23,21],
[66,27,24,0],
[66,27,24,1],
[66,27,24,2],
[66,27,24,3],
[66,27,24,4],
[66,27,24,5],
[66,27,24,6],
[66,27,24,8],
[66,27,24,9],
[66,27,24,11],
[66,27,24,12],
[66,27,24,13],
[66,27,24,14],
[66,27,24,15],
[66,27,24,17],
[66,27,24,18],
[66,27,24,19],
[66,27,24,21],
[66,27,24,23],
[66,27,26,0],
[66,27,26,1],
[66,27,26,2],
[66,27,26,3],
[66,27,26,4],
[66,27,26,5],
[66,27,26,6],
[66,27,26,8],
[66,27,26,11],
[66,27,26,12],
[66,27,26,13],
[66,27,26,14],
[66,27,26,16],
[66,27,26,17],
[66,27,26,21],
[66,27,26,23],
[66,27,26,24],
[66,29,3,0],
[66,29,3,1],
[66,29,3,2],
[66,29,4,0],
[66,29,4,1],
[66,29,4,2],
[66,29,4,3],
[66,29,5,0],
[66,29,5,1],
[66,29,5,2],
[66,29,5,4],
[66,29,6,0],
[66,29,6,1],
[66,29,6,2],
[66,29,6,4],
[66,29,8,0],
[66,29,8,1],
[66,29,8,2],
[66,29,8,4],
[66,29,9,0],
[66,29,9,1],
[66,29,9,2],
[66,29,9,3],
[66,29,9,4],
[66,29,9,5],
[66,29,9,6],
[66,29,9,8],
[66,29,10,0],
[66,29,10,1],
[66,29,10,2],
[66,29,10,3],
[66,29,10,4],
[66,29,10,5],
[66,29,10,6],
[66,29,10,8],
[66,29,10,9],
[66,29,13,0],
[66,29,13,1],
[66,29,13,2],
[66,29,13,3],
[66,29,13,4],
[66,29,13,5],
[66,29,13,6],
[66,29,13,8],
[66,29,13,9],
[66,29,13,10],
[66,29,14,0],
[66,29,14,1],
[66,29,14,2],
[66,29,14,3],
[66,29,14,4],
[66,29,14,5],
[66,29,14,6],
[66,29,14,8],
[66,29,14,9],
[66,29,14,10],
[66,29,15,0],
[66,29,15,1],
[66,29,15,2],
[66,29,15,3],
[66,29,15,4],
[66,29,15,5],
[66,29,15,6],
[66,29,15,8],
[66,29,15,10],
[66,29,15,13],
[66,29,15,14],
[66,29,16,0],
[66,29,16,1],
[66,29,16,2],
[66,29,16,3],
[66,29,16,4],
[66,29,16,5],
[66,29,16,6],
[66,29,16,8],
[66,29,16,9],
[66,29,16,10],
[66,29,16,13],
[66,29,16,14],
[66,29,16,15],
[66,29,17,0],
[66,29,17,1],
[66,29,17,2],
[66,29,17,3],
[66,29,17,4],
[66,29,17,5],
[66,29,17,6],
[66,29,17,8],
[66,29,17,9],
[66,29,17,10],
[66,29,17,13],
[66,29,17,14],
[66,29,17,15],
[66,29,17,16],
[66,29,18,0],
[66,29,18,1],
[66,29,18,2],
[66,29,18,3],
[66,29,18,4],
[66,29,18,5],
[66,29,18,6],
[66,29,18,8],
[66,29,18,10],
[66,29,18,13],
[66,29,18,14],
[66,29,18,16],
[66,29,18,17],
[66,29,19,0],
[66,29,19,1],
[66,29,19,2],
[66,29,19,3],
[66,29,19,4],
[66,29,19,5],
[66,29,19,6],
[66,29,19,8],
[66,29,19,9],
[66,29,19,10],
[66,29,19,15],
[66,29,19,16],
[66,29,19,17],
[66,29,19,18],
[66,29,20,0],
[66,29,20,1],
[66,29,20,2],
[66,29,20,3],
[66,29,20,4],
[66,29,20,5],
[66,29,20,6],
[66,29,20,8],
[66,29,20,9],
[66,29,20,13],
[66,29,20,14],
[66,29,20,15],
[66,29,20,16],
[66,29,20,17],
[66,29,20,18],
[66,29,20,19],
[66,29,21,0],
[66,29,21,1],
[66,29,21,2],
[66,29,21,4],
[66,29,21,9],
[66,29,21,10],
[66,29,21,13],
[66,29,21,14],
[66,29,21,15],
[66,29,21,16],
[66,29,21,17],
[66,29,21,18],
[66,29,21,19],
[66,29,21,20],
[66,29,22,0],
[66,29,22,1],
[66,29,22,2],
[66,29,22,3],
[66,29,22,4],
[66,29,22,5],
[66,29,22,6],
[66,29,22,8],
[66,29,22,9],
[66,29,22,13],
[66,29,22,14],
[66,29,22,15],
[66,29,22,16],
[66,29,22,17],
[66,29,22,18],
[66,29,22,19],
[66,29,23,0],
[66,29,23,1],
[66,29,23,2],
[66,29,23,3],
[66,29,23,4],
[66,29,23,5],
[66,29,23,6],
[66,29,23,8],
[66,29,23,9],
[66,29,23,10],
[66,29,23,13],
[66,29,23,14],
[66,29,23,15],
[66,29,23,16],
[66,29,23,17],
[66,29,23,18],
[66,29,23,19],
[66,29,23,21],
[66,29,23,22],
[66,29,24,0],
[66,29,24,1],
[66,29,24,2],
[66,29,24,3],
[66,29,24,4],
[66,29,24,5],
[66,29,24,6],
[66,29,24,8],
[66,29,24,9],
[66,29,24,10],
[66,29,24,13],
[66,29,24,14],
[66,29,24,15],
[66,29,24,17],
[66,29,24,18],
[66,29,24,19],
[66,29,24,21],
[66,29,24,22],
[66,29,24,23],
[66,29,26,0],
[66,29,26,1],
[66,29,26,2],
[66,29,26,3],
[66,29,26,4],
[66,29,26,5],
[66,29,26,6],
[66,29,26,8],
[66,29,26,10],
[66,29,26,13],
[66,29,26,14],
[66,29,26,19],
[66,29,26,20],
[66,29,26,21],
[66,29,26,22],
[66,29,26,23],
[66,29,26,24],
[66,29,27,0],
[66,29,27,1],
[66,29,27,2],
[66,29,27,3],
[66,29,27,4],
[66,29,27,5],
[66,29,27,6],
[66,29,27,8],
[66,29,27,9],
[66,29,27,13],
[66,29,27,14],
[66,29,27,16],
[66,29,27,17],
[66,29,27,18],
[66,29,27,19],
[66,29,27,21],
[66,29,27,23],
[66,29,27,24],
[66,29,27,26],
[66,30,3,0],
[66,30,3,1],
[66,30,3,2],
[66,30,4,0],
[66,30,4,1],
[66,30,4,2],
[66,30,4,3],
[66,30,5,0],
[66,30,5,1],
[66,30,5,2],
[66,30,5,4],
[66,30,6,0],
[66,30,6,1],
[66,30,6,2],
[66,30,6,4],
[66,30,8,0],
[66,30,8,1],
[66,30,8,2],
[66,30,8,4],
[66,30,9,0],
[66,30,9,1],
[66,30,9,2],
[66,30,9,3],
[66,30,9,4],
[66,30,9,5],
[66,30,9,6],
[66,30,9,8],
[66,30,10,0],
[66,30,10,1],
[66,30,10,2],
[66,30,10,3],
[66,30,10,4],
[66,30,10,5],
[66,30,10,6],
[66,30,10,8],
[66,30,10,9],
[66,30,11,0],
[66,30,11,1],
[66,30,11,2],
[66,30,11,3],
[66,30,11,4],
[66,30,11,5],
[66,30,11,6],
[66,30,11,8],
[66,30,11,9],
[66,30,11,10],
[66,30,12,0],
[66,30,12,1],
[66,30,12,2],
[66,30,12,3],
[66,30,12,4],
[66,30,12,5],
[66,30,12,6],
[66,30,12,8],
[66,30,12,9],
[66,30,12,10],
[66,30,13,0],
[66,30,13,1],
[66,30,13,2],
[66,30,13,3],
[66,30,13,4],
[66,30,13,5],
[66,30,13,6],
[66,30,13,8],
[66,30,13,9],
[66,30,13,10],
[66,30,13,11],
[66,30,13,12],
[66,30,14,0],
[66,30,14,1],
[66,30,14,2],
[66,30,14,3],
[66,30,14,4],
[66,30,14,5],
[66,30,14,6],
[66,30,14,8],
[66,30,14,9],
[66,30,14,10],
[66,30,14,11],
[66,30,14,12],
[66,30,15,0],
[66,30,15,1],
[66,30,15,2],
[66,30,15,3],
[66,30,15,4],
[66,30,15,5],
[66,30,15,6],
[66,30,15,8],
[66,30,15,10],
[66,30,15,11],
[66,30,15,12],
[66,30,15,13],
[66,30,15,14],
[66,30,16,0],
[66,30,16,1],
[66,30,16,2],
[66,30,16,3],
[66,30,16,4],
[66,30,16,5],
[66,30,16,6],
[66,30,16,8],
[66,30,16,9],
[66,30,16,10],
[66,30,16,11],
[66,30,16,12],
[66,30,16,13],
[66,30,16,14],
[66,30,16,15],
[66,30,17,0],
[66,30,17,1],
[66,30,17,2],
[66,30,17,3],
[66,30,17,4],
[66,30,17,5],
[66,30,17,6],
[66,30,17,8],
[66,30,17,9],
[66,30,17,10],
[66,30,17,11],
[66,30,17,12],
[66,30,17,13],
[66,30,17,14],
[66,30,17,15],
[66,30,17,16],
[66,30,18,0],
[66,30,18,1],
[66,30,18,2],
[66,30,18,3],
[66,30,18,4],
[66,30,18,5],
[66,30,18,6],
[66,30,18,8],
[66,30,18,10],
[66,30,18,11],
[66,30,18,12],
[66,30,18,13],
[66,30,18,14],
[66,30,18,16],
[66,30,18,17],
[66,30,19,0],
[66,30,19,1],
[66,30,19,2],
[66,30,19,3],
[66,30,19,4],
[66,30,19,5],
[66,30,19,6],
[66,30,19,8],
[66,30,19,9],
[66,30,19,10],
[66,30,19,11],
[66,30,19,12],
[66,30,19,15],
[66,30,19,16],
[66,30,19,17],
[66,30,19,18],
[66,30,20,0],
[66,30,20,1],
[66,30,20,2],
[66,30,20,3],
[66,30,20,4],
[66,30,20,5],
[66,30,20,6],
[66,30,20,8],
[66,30,20,9],
[66,30,20,11],
[66,30,20,12],
[66,30,20,13],
[66,30,20,14],
[66,30,20,15],
[66,30,20,16],
[66,30,20,17],
[66,30,20,18],
[66,30,20,19],
[66,30,21,0],
[66,30,21,1],
[66,30,21,2],
[66,30,21,4],
[66,30,21,9],
[66,30,21,10],
[66,30,21,11],
[66,30,21,12],
[66,30,21,13],
[66,30,21,14],
[66,30,21,15],
[66,30,21,16],
[66,30,21,17],
[66,30,21,18],
[66,30,21,19],
[66,30,21,20],
[66,30,22,0],
[66,30,22,1],
[66,30,22,2],
[66,30,22,3],
[66,30,22,4],
[66,30,22,5],
[66,30,22,6],
[66,30,22,8],
[66,30,22,9],
[66,30,22,11],
[66,30,22,12],
[66,30,22,13],
[66,30,22,14],
[66,30,22,15],
[66,30,22,16],
[66,30,22,17],
[66,30,22,18],
[66,30,22,19],
[66,30,22,21],
[66,30,24,0],
[66,30,24,1],
[66,30,24,2],
[66,30,24,3],
[66,30,24,4],
[66,30,24,5],
[66,30,24,6],
[66,30,24,8],
[66,30,24,9],
[66,30,24,10],
[66,30,24,11],
[66,30,24,12],
[66,30,24,13],
[66,30,24,14],
[66,30,24,15],
[66,30,24,17],
[66,30,24,18],
[66,30,24,20],
[66,30,24,21],
[66,30,24,22],
[66,30,26,0],
[66,30,26,1],
[66,30,26,2],
[66,30,26,3],
[66,30,26,4],
[66,30,26,5],
[66,30,26,6],
[66,30,26,8],
[66,30,26,10],
[66,30,26,11],
[66,30,26,12],
[66,30,26,13],
[66,30,26,14],
[66,30,26,16],
[66,30,26,17],
[66,30,26,19],
[66,30,26,20],
[66,30,26,21],
[66,30,26,22],
[66,30,26,24],
[66,30,27,0],
[66,30,27,1],
[66,30,27,2],
[66,30,27,3],
[66,30,27,4],
[66,30,27,5],
[66,30,27,6],
[66,30,27,8],
[66,30,27,9],
[66,30,27,11],
[66,30,27,12],
[66,30,27,13],
[66,30,27,15],
[66,30,27,16],
[66,30,27,17],
[66,30,27,18],
[66,30,27,19],
[66,30,27,21],
[66,30,27,24],
[66,30,27,26],
[66,30,29,0],
[66,30,29,1],
[66,30,29,2],
[66,30,29,3],
[66,30,29,4],
[66,30,29,5],
[66,30,29,6],
[66,30,29,8],
[66,30,29,9],
[66,30,29,13],
[66,30,29,14],
[66,30,29,15],
[66,30,29,16],
[66,30,29,17],
[66,30,29,18],
[66,30,29,19],
[66,30,29,20],
[66,30,29,21],
[66,30,29,22],
[66,30,29,24],
[66,31,3,0],
[66,31,3,1],
[66,31,3,2],
[66,31,4,0],
[66,31,4,1],
[66,31,4,2],
[66,31,4,3],
[66,31,5,0],
[66,31,5,1],
[66,31,5,2],
[66,31,5,4],
[66,31,6,0],
[66,31,6,1],
[66,31,6,2],
[66,31,6,4],
[66,31,8,0],
[66,31,8,1],
[66,31,8,2],
[66,31,8,4],
[66,31,9,0],
[66,31,9,1],
[66,31,9,2],
[66,31,9,3],
[66,31,9,4],
[66,31,9,5],
[66,31,9,6],
[66,31,9,8],
[66,31,10,0],
[66,31,10,1],
[66,31,10,2],
[66,31,10,3],
[66,31,10,4],
[66,31,10,5],
[66,31,10,6],
[66,31,10,8],
[66,31,10,9],
[66,31,11,0],
[66,31,11,1],
[66,31,11,2],
[66,31,11,3],
[66,31,11,4],
[66,31,11,5],
[66,31,11,6],
[66,31,11,8],
[66,31,11,9],
[66,31,11,10],
[66,31,12,0],
[66,31,12,1],
[66,31,12,2],
[66,31,12,3],
[66,31,12,4],
[66,31,12,5],
[66,31,12,6],
[66,31,12,8],
[66,31,12,9],
[66,31,12,10],
[66,31,13,0],
[66,31,13,1],
[66,31,13,2],
[66,31,13,3],
[66,31,13,4],
[66,31,13,5],
[66,31,13,6],
[66,31,13,8],
[66,31,13,9],
[66,31,13,10],
[66,31,13,11],
[66,31,13,12],
[66,31,14,0],
[66,31,14,1],
[66,31,14,2],
[66,31,14,3],
[66,31,14,4],
[66,31,14,5],
[66,31,14,6],
[66,31,14,8],
[66,31,14,9],
[66,31,14,10],
[66,31,14,11],
[66,31,14,12],
[66,31,15,0],
[66,31,15,1],
[66,31,15,2],
[66,31,15,3],
[66,31,15,4],
[66,31,15,5],
[66,31,15,6],
[66,31,15,8],
[66,31,15,10],
[66,31,15,11],
[66,31,15,12],
[66,31,15,13],
[66,31,15,14],
[66,31,16,0],
[66,31,16,1],
[66,31,16,2],
[66,31,16,3],
[66,31,16,4],
[66,31,16,5],
[66,31,16,6],
[66,31,16,8],
[66,31,16,9],
[66,31,16,10],
[66,31,16,11],
[66,31,16,12],
[66,31,16,13],
[66,31,16,14],
[66,31,16,15],
[66,31,17,0],
[66,31,17,1],
[66,31,17,2],
[66,31,17,3],
[66,31,17,4],
[66,31,17,5],
[66,31,17,6],
[66,31,17,8],
[66,31,17,9],
[66,31,17,10],
[66,31,17,11],
[66,31,17,12],
[66,31,17,13],
[66,31,17,14],
[66,31,17,15],
[66,31,17,16],
[66,31,18,0],
[66,31,18,1],
[66,31,18,2],
[66,31,18,3],
[66,31,18,4],
[66,31,18,5],
[66,31,18,6],
[66,31,18,8],
[66,31,18,10],
[66,31,18,11],
[66,31,18,12],
[66,31,18,13],
[66,31,18,14],
[66,31,18,16],
[66,31,18,17],
[66,31,19,0],
[66,31,19,1],
[66,31,19,2],
[66,31,19,3],
[66,31,19,4],
[66,31,19,5],
[66,31,19,6],
[66,31,19,8],
[66,31,19,9],
[66,31,19,10],
[66,31,19,11],
[66,31,19,12],
[66,31,19,15],
[66,31,19,16],
[66,31,19,17],
[66,31,19,18],
[66,31,20,0],
[66,31,20,1],
[66,31,20,2],
[66,31,20,3],
[66,31,20,4],
[66,31,20,5],
[66,31,20,6],
[66,31,20,8],
[66,31,20,9],
[66,31,20,11],
[66,31,20,12],
[66,31,20,13],
[66,31,20,14],
[66,31,20,15],
[66,31,20,16],
[66,31,20,17],
[66,31,20,18],
[66,31,20,19],
[66,31,21,0],
[66,31,21,1],
[66,31,21,2],
[66,31,21,4],
[66,31,21,9],
[66,31,21,10],
[66,31,21,11],
[66,31,21,12],
[66,31,21,13],
[66,31,21,14],
[66,31,21,15],
[66,31,21,16],
[66,31,21,17],
[66,31,21,18],
[66,31,21,19],
[66,31,22,0],
[66,31,22,1],
[66,31,22,2],
[66,31,22,3],
[66,31,22,4],
[66,31,22,5],
[66,31,22,6],
[66,31,22,8],
[66,31,22,9],
[66,31,22,11],
[66,31,22,12],
[66,31,22,13],
[66,31,22,14],
[66,31,22,15],
[66,31,22,16],
[66,31,22,17],
[66,31,22,18],
[66,31,22,21],
[66,31,24,0],
[66,31,24,1],
[66,31,24,2],
[66,31,24,3],
[66,31,24,4],
[66,31,24,5],
[66,31,24,6],
[66,31,24,8],
[66,31,24,9],
[66,31,24,10],
[66,31,24,11],
[66,31,24,12],
[66,31,24,13],
[66,31,24,14],
[66,31,24,15],
[66,31,24,17],
[66,31,24,19],
[66,31,24,20],
[66,31,24,21],
[66,31,24,22],
[66,31,26,0],
[66,31,26,1],
[66,31,26,2],
[66,31,26,3],
[66,31,26,4],
[66,31,26,5],
[66,31,26,6],
[66,31,26,8],
[66,31,26,10],
[66,31,26,11],
[66,31,26,12],
[66,31,26,13],
[66,31,26,16],
[66,31,26,17],
[66,31,26,19],
[66,31,26,20],
[66,31,26,21],
[66,31,26,22],
[66,31,26,24],
[66,31,27,0],
[66,31,27,1],
[66,31,27,2],
[66,31,27,3],
[66,31,27,4],
[66,31,27,5],
[66,31,27,6],
[66,31,27,8],
[66,31,27,9],
[66,31,27,11],
[66,31,27,14],
[66,31,27,15],
[66,31,27,16],
[66,31,27,17],
[66,31,27,18],
[66,31,27,19],
[66,31,27,21],
[66,31,27,24],
[66,31,27,26],
[66,31,29,0],
[66,31,29,1],
[66,31,29,2],
[66,31,29,3],
[66,31,29,4],
[66,31,29,5],
[66,31,29,6],
[66,31,29,10],
[66,31,29,13],
[66,31,29,14],
[66,31,29,15],
[66,31,29,16],
[66,31,29,17],
[66,31,29,18],
[66,31,29,19],
[66,31,29,20],
[66,31,29,21],
[66,31,29,22],
[66,31,29,24],
[66,32,3,0],
[66,32,3,1],
[66,32,3,2],
[66,32,4,0],
[66,32,4,1],
[66,32,4,2],
[66,32,4,3],
[66,32,5,0],
[66,32,5,1],
[66,32,5,2],
[66,32,5,4],
[66,32,6,0],
[66,32,6,1],
[66,32,6,2],
[66,32,6,4],
[66,32,8,0],
[66,32,8,1],
[66,32,8,2],
[66,32,8,4],
[66,32,9,0],
[66,32,9,1],
[66,32,9,2],
[66,32,9,3],
[66,32,9,4],
[66,32,9,5],
[66,32,9,6],
[66,32,9,8],
[66,32,10,0],
[66,32,10,1],
[66,32,10,2],
[66,32,10,3],
[66,32,10,4],
[66,32,10,5],
[66,32,10,6],
[66,32,10,8],
[66,32,10,9],
[66,32,13,0],
[66,32,13,1],
[66,32,13,2],
[66,32,13,3],
[66,32,13,4],
[66,32,13,5],
[66,32,13,6],
[66,32,13,8],
[66,32,13,9],
[66,32,13,10],
[66,32,14,0],
[66,32,14,1],
[66,32,14,2],
[66,32,14,3],
[66,32,14,4],
[66,32,14,5],
[66,32,14,6],
[66,32,14,8],
[66,32,14,9],
[66,32,14,10],
[66,32,15,0],
[66,32,15,1],
[66,32,15,2],
[66,32,15,3],
[66,32,15,4],
[66,32,15,5],
[66,32,15,6],
[66,32,15,8],
[66,32,15,10],
[66,32,15,13],
[66,32,15,14],
[66,32,16,0],
[66,32,16,1],
[66,32,16,2],
[66,32,16,3],
[66,32,16,4],
[66,32,16,5],
[66,32,16,6],
[66,32,16,8],
[66,32,16,9],
[66,32,16,10],
[66,32,16,13],
[66,32,16,14],
[66,32,16,15],
[66,32,17,0],
[66,32,17,1],
[66,32,17,2],
[66,32,17,3],
[66,32,17,4],
[66,32,17,5],
[66,32,17,6],
[66,32,17,8],
[66,32,17,9],
[66,32,17,10],
[66,32,17,13],
[66,32,17,14],
[66,32,17,15],
[66,32,17,16],
[66,32,18,0],
[66,32,18,1],
[66,32,18,2],
[66,32,18,3],
[66,32,18,4],
[66,32,18,5],
[66,32,18,6],
[66,32,18,8],
[66,32,18,10],
[66,32,18,13],
[66,32,18,14],
[66,32,18,16],
[66,32,18,17],
[66,32,19,0],
[66,32,19,1],
[66,32,19,2],
[66,32,19,3],
[66,32,19,4],
[66,32,19,5],
[66,32,19,6],
[66,32,19,8],
[66,32,19,9],
[66,32,19,10],
[66,32,19,15],
[66,32,19,16],
[66,32,19,17],
[66,32,19,18],
[66,32,20,0],
[66,32,20,1],
[66,32,20,2],
[66,32,20,3],
[66,32,20,4],
[66,32,20,5],
[66,32,20,6],
[66,32,20,8],
[66,32,20,9],
[66,32,20,13],
[66,32,20,14],
[66,32,20,15],
[66,32,20,16],
[66,32,20,17],
[66,32,20,18],
[66,32,20,19],
[66,32,21,0],
[66,32,21,1],
[66,32,21,2],
[66,32,21,4],
[66,32,21,9],
[66,32,21,10],
[66,32,21,13],
[66,32,21,14],
[66,32,21,15],
[66,32,21,16],
[66,32,21,17],
[66,32,21,18],
[66,32,21,20],
[66,32,22,0],
[66,32,22,1],
[66,32,22,2],
[66,32,22,3],
[66,32,22,4],
[66,32,22,5],
[66,32,22,6],
[66,32,22,8],
[66,32,22,9],
[66,32,22,13],
[66,32,22,14],
[66,32,22,15],
[66,32,22,16],
[66,32,22,17],
[66,32,22,19],
[66,32,22,21],
[66,32,23,0],
[66,32,23,1],
[66,32,23,2],
[66,32,23,3],
[66,32,23,4],
[66,32,23,5],
[66,32,23,6],
[66,32,23,8],
[66,32,23,9],
[66,32,23,10],
[66,32,23,13],
[66,32,23,14],
[66,32,23,15],
[66,32,23,18],
[66,32,23,19],
[66,32,23,20],
[66,32,23,21],
[66,32,23,22],
[66,32,24,0],
[66,32,24,1],
[66,32,24,2],
[66,32,24,3],
[66,32,24,4],
[66,32,24,5],
[66,32,24,6],
[66,32,24,8],
[66,32,24,9],
[66,32,24,10],
[66,32,24,13],
[66,32,24,14],
[66,32,24,15],
[66,32,24,18],
[66,32,24,19],
[66,32,24,20],
[66,32,24,21],
[66,32,24,22],
[66,32,24,23],
[66,32,26,0],
[66,32,26,1],
[66,32,26,2],
[66,32,26,3],
[66,32,26,4],
[66,32,26,5],
[66,32,26,6],
[66,32,26,8],
[66,32,26,10],
[66,32,26,14],
[66,32,26,16],
[66,32,26,17],
[66,32,26,19],
[66,32,26,20],
[66,32,26,21],
[66,32,26,22],
[66,32,26,23],
[66,32,26,24],
[66,32,27,0],
[66,32,27,1],
[66,32,27,2],
[66,32,27,3],
[66,32,27,4],
[66,32,27,5],
[66,32,27,6],
[66,32,27,8],
[66,32,27,9],
[66,32,27,13],
[66,32,27,14],
[66,32,27,15],
[66,32,27,16],
[66,32,27,17],
[66,32,27,18],
[66,32,27,19],
[66,32,27,21],
[66,32,27,23],
[66,32,27,24],
[66,32,30,0],
[66,32,30,1],
[66,32,30,2],
[66,32,30,3],
[66,32,30,4],
[66,32,30,6],
[66,32,30,8],
[66,32,30,9],
[66,32,30,10],
[66,32,30,13],
[66,32,30,14],
[66,32,30,15],
[66,32,30,16],
[66,32,30,17],
[66,32,30,18],
[66,32,30,19],
[66,32,30,20],
[66,32,30,21],
[66,32,30,22],
[66,32,31,0],
[66,32,31,1],
[66,32,31,2],
[66,32,31,5],
[66,32,31,6],
[66,32,31,8],
[66,32,31,9],
[66,32,31,10],
[66,32,31,13],
[66,32,31,14],
[66,32,31,15],
[66,32,31,16],
[66,32,31,17],
[66,32,31,18],
[66,32,31,19],
[66,32,31,20],
[66,32,31,21],
[66,33,3,0],
[66,33,3,1],
[66,33,3,2],
[66,33,4,0],
[66,33,4,1],
[66,33,4,2],
[66,33,4,3],
[66,33,5,0],
[66,33,5,1],
[66,33,5,2],
[66,33,5,4],
[66,33,6,0],
[66,33,6,1],
[66,33,6,2],
[66,33,6,4],
[66,33,8,0],
[66,33,8,1],
[66,33,8,2],
[66,33,8,4],
[66,33,9,0],
[66,33,9,1],
[66,33,9,2],
[66,33,9,3],
[66,33,9,4],
[66,33,9,5],
[66,33,9,6],
[66,33,9,8],
[66,33,10,0],
[66,33,10,1],
[66,33,10,2],
[66,33,10,3],
[66,33,10,4],
[66,33,10,5],
[66,33,10,6],
[66,33,10,8],
[66,33,10,9],
[66,33,13,0],
[66,33,13,1],
[66,33,13,2],
[66,33,13,3],
[66,33,13,4],
[66,33,13,5],
[66,33,13,6],
[66,33,13,8],
[66,33,13,9],
[66,33,13,10],
[66,33,14,0],
[66,33,14,1],
[66,33,14,2],
[66,33,14,3],
[66,33,14,4],
[66,33,14,5],
[66,33,14,6],
[66,33,14,8],
[66,33,14,9],
[66,33,14,10],
[66,33,15,0],
[66,33,15,1],
[66,33,15,2],
[66,33,15,3],
[66,33,15,4],
[66,33,15,5],
[66,33,15,6],
[66,33,15,8],
[66,33,15,10],
[66,33,15,13],
[66,33,15,14],
[66,33,16,0],
[66,33,16,1],
[66,33,16,2],
[66,33,16,3],
[66,33,16,4],
[66,33,16,5],
[66,33,16,6],
[66,33,16,8],
[66,33,16,9],
[66,33,16,10],
[66,33,16,13],
[66,33,16,14],
[66,33,16,15],
[66,33,17,0],
[66,33,17,1],
[66,33,17,2],
[66,33,17,3],
[66,33,17,4],
[66,33,17,5],
[66,33,17,6],
[66,33,17,8],
[66,33,17,9],
[66,33,17,10],
[66,33,17,13],
[66,33,17,14],
[66,33,17,15],
[66,33,17,16],
[66,33,18,0],
[66,33,18,1],
[66,33,18,2],
[66,33,18,3],
[66,33,18,4],
[66,33,18,5],
[66,33,18,6],
[66,33,18,8],
[66,33,18,10],
[66,33,18,13],
[66,33,18,14],
[66,33,18,16],
[66,33,18,17],
[66,33,19,0],
[66,33,19,1],
[66,33,19,2],
[66,33,19,3],
[66,33,19,4],
[66,33,19,5],
[66,33,19,6],
[66,33,19,8],
[66,33,19,9],
[66,33,19,10],
[66,33,19,15],
[66,33,19,16],
[66,33,19,17],
[66,33,19,18],
[66,33,20,0],
[66,33,20,1],
[66,33,20,2],
[66,33,20,3],
[66,33,20,4],
[66,33,20,5],
[66,33,20,6],
[66,33,20,8],
[66,33,20,9],
[66,33,20,13],
[66,33,20,14],
[66,33,20,15],
[66,33,20,16],
[66,33,20,17],
[66,33,20,18],
[66,33,21,0],
[66,33,21,1],
[66,33,21,2],
[66,33,21,4],
[66,33,21,9],
[66,33,21,10],
[66,33,21,13],
[66,33,21,14],
[66,33,21,15],
[66,33,21,16],
[66,33,21,17],
[66,33,21,19],
[66,33,21,20],
[66,33,22,0],
[66,33,22,1],
[66,33,22,2],
[66,33,22,3],
[66,33,22,4],
[66,33,22,5],
[66,33,22,6],
[66,33,22,8],
[66,33,22,9],
[66,33,22,13],
[66,33,22,14],
[66,33,22,15],
[66,33,22,18],
[66,33,22,19],
[66,33,22,21],
[66,33,23,0],
[66,33,23,1],
[66,33,23,2],
[66,33,23,3],
[66,33,23,4],
[66,33,23,5],
[66,33,23,6],
[66,33,23,8],
[66,33,23,9],
[66,33,23,10],
[66,33,23,13],
[66,33,23,14],
[66,33,23,16],
[66,33,23,17],
[66,33,23,18],
[66,33,23,19],
[66,33,23,20],
[66,33,23,21],
[66,33,23,22],
[66,33,24,0],
[66,33,24,1],
[66,33,24,2],
[66,33,24,3],
[66,33,24,4],
[66,33,24,5],
[66,33,24,6],
[66,33,24,8],
[66,33,24,9],
[66,33,24,10],
[66,33,24,13],
[66,33,24,14],
[66,33,24,17],
[66,33,24,18],
[66,33,24,19],
[66,33,24,20],
[66,33,24,21],
[66,33,24,22],
[66,33,24,23],
[66,33,26,0],
[66,33,26,1],
[66,33,26,2],
[66,33,26,3],
[66,33,26,4],
[66,33,26,5],
[66,33,26,6],
[66,33,26,8],
[66,33,26,13],
[66,33,26,14],
[66,33,26,16],
[66,33,26,17],
[66,33,26,19],
[66,33,26,20],
[66,33,26,21],
[66,33,26,22],
[66,33,26,23],
[66,33,26,24],
[66,33,27,0],
[66,33,27,1],
[66,33,27,2],
[66,33,27,3],
[66,33,27,4],
[66,33,27,5],
[66,33,27,6],
[66,33,27,13],
[66,33,27,14],
[66,33,27,15],
[66,33,27,16],
[66,33,27,17],
[66,33,27,18],
[66,33,27,19],
[66,33,27,21],
[66,33,27,23],
[66,33,27,24],
[66,33,30,0],
[66,33,30,1],
[66,33,30,2],
[66,33,30,5],
[66,33,30,6],
[66,33,30,8],
[66,33,30,9],
[66,33,30,10],
[66,33,30,13],
[66,33,30,14],
[66,33,30,15],
[66,33,30,16],
[66,33,30,17],
[66,33,30,18],
[66,33,30,19],
[66,33,30,20],
[66,33,30,21],
[66,33,31,3],
[66,33,31,4],
[66,33,31,5],
[66,33,31,6],
[66,33,31,8],
[66,33,31,9],
[66,33,31,10],
[66,33,31,13],
[66,33,31,14],
[66,33,31,15],
[66,33,31,16],
[66,33,31,17],
[66,33,31,18],
[66,33,31,19],
[66,33,31,20],
[66,35,3,0],
[66,35,3,1],
[66,35,3,2],
[66,35,4,0],
[66,35,4,1],
[66,35,4,2],
[66,35,4,3],
[66,35,5,0],
[66,35,5,1],
[66,35,5,2],
[66,35,5,4],
[66,35,6,0],
[66,35,6,1],
[66,35,6,2],
[66,35,6,4],
[66,35,8,0],
[66,35,8,1],
[66,35,8,2],
[66,35,8,4],
[66,35,10,0],
[66,35,10,1],
[66,35,10,2],
[66,35,10,3],
[66,35,10,4],
[66,35,10,5],
[66,35,10,6],
[66,35,10,8],
[66,35,11,0],
[66,35,11,1],
[66,35,11,2],
[66,35,11,3],
[66,35,11,4],
[66,35,11,5],
[66,35,11,6],
[66,35,11,8],
[66,35,11,10],
[66,35,12,0],
[66,35,12,1],
[66,35,12,2],
[66,35,12,3],
[66,35,12,4],
[66,35,12,5],
[66,35,12,6],
[66,35,12,8],
[66,35,12,10],
[66,35,13,0],
[66,35,13,1],
[66,35,13,2],
[66,35,13,3],
[66,35,13,4],
[66,35,13,5],
[66,35,13,6],
[66,35,13,8],
[66,35,13,10],
[66,35,13,11],
[66,35,13,12],
[66,35,14,0],
[66,35,14,1],
[66,35,14,2],
[66,35,14,3],
[66,35,14,4],
[66,35,14,5],
[66,35,14,6],
[66,35,14,8],
[66,35,14,10],
[66,35,14,11],
[66,35,14,12],
[66,35,16,0],
[66,35,16,1],
[66,35,16,2],
[66,35,16,3],
[66,35,16,4],
[66,35,16,5],
[66,35,16,6],
[66,35,16,8],
[66,35,16,10],
[66,35,16,11],
[66,35,16,12],
[66,35,16,13],
[66,35,16,14],
[66,35,17,0],
[66,35,17,1],
[66,35,17,2],
[66,35,17,3],
[66,35,17,4],
[66,35,17,5],
[66,35,17,6],
[66,35,17,8],
[66,35,17,10],
[66,35,17,11],
[66,35,17,12],
[66,35,17,13],
[66,35,17,14],
[66,35,17,16],
[66,35,19,0],
[66,35,19,1],
[66,35,19,2],
[66,35,19,3],
[66,35,19,4],
[66,35,19,5],
[66,35,19,6],
[66,35,19,8],
[66,35,19,10],
[66,35,19,11],
[66,35,19,12],
[66,35,19,16],
[66,35,19,17],
[66,35,20,0],
[66,35,20,1],
[66,35,20,2],
[66,35,20,3],
[66,35,20,4],
[66,35,20,5],
[66,35,20,6],
[66,35,20,8],
[66,35,20,11],
[66,35,20,12],
[66,35,20,13],
[66,35,20,14],
[66,35,20,19],
[66,35,21,0],
[66,35,21,1],
[66,35,21,2],
[66,35,21,4],
[66,35,21,10],
[66,35,21,11],
[66,35,21,12],
[66,35,21,13],
[66,35,21,14],
[66,35,21,16],
[66,35,21,17],
[66,35,21,19],
[66,35,21,20],
[66,35,22,0],
[66,35,22,1],
[66,35,22,2],
[66,35,22,3],
[66,35,22,4],
[66,35,22,5],
[66,35,22,6],
[66,35,22,8],
[66,35,22,11],
[66,35,22,12],
[66,35,22,13],
[66,35,22,16],
[66,35,22,17],
[66,35,22,19],
[66,35,22,21],
[66,35,23,0],
[66,35,23,1],
[66,35,23,2],
[66,35,23,3],
[66,35,23,4],
[66,35,23,5],
[66,35,23,6],
[66,35,23,8],
[66,35,23,10],
[66,35,23,11],
[66,35,23,14],
[66,35,23,16],
[66,35,23,17],
[66,35,23,19],
[66,35,23,20],
[66,35,23,21],
[66,35,23,22],
[66,35,24,0],
[66,35,24,1],
[66,35,24,2],
[66,35,24,3],
[66,35,24,4],
[66,35,24,5],
[66,35,24,6],
[66,35,24,8],
[66,35,24,10],
[66,35,24,11],
[66,35,24,14],
[66,35,24,17],
[66,35,24,19],
[66,35,24,20],
[66,35,24,21],
[66,35,24,22],
[66,35,24,23],
[66,35,27,0],
[66,35,27,1],
[66,35,27,2],
[66,35,27,3],
[66,35,27,4],
[66,35,27,6],
[66,35,27,8],
[66,35,27,11],
[66,35,27,12],
[66,35,27,13],
[66,35,27,14],
[66,35,27,16],
[66,35,27,17],
[66,35,27,19],
[66,35,27,21],
[66,35,29,3],
[66,35,29,4],
[66,35,29,5],
[66,35,29,6],
[66,35,29,8],
[66,35,29,10],
[66,35,29,13],
[66,35,29,14],
[66,35,29,16],
[66,35,29,17],
[66,35,29,19],
[66,35,29,20],
[66,35,30,0],
[66,35,30,1],
[66,35,30,2],
[66,35,30,3],
[66,35,30,4],
[66,35,30,5],
[66,35,30,6],
[66,35,30,8],
[66,35,30,10],
[66,35,30,11],
[66,35,30,12],
[66,35,30,13],
[66,35,30,14],
[66,35,30,16],
[66,35,30,17],
[66,35,30,19],
[66,35,31,0],
[66,35,31,1],
[66,35,31,2],
[66,35,31,3],
[66,35,31,4],
[66,35,31,5],
[66,35,31,6],
[66,35,31,8],
[66,35,31,10],
[66,35,31,11],
[66,35,31,12],
[66,35,31,13],
[66,35,31,14],
[66,35,31,16],
[66,35,31,17],
[66,35,32,0],
[66,35,32,1],
[66,35,32,2],
[66,35,32,3],
[66,35,32,4],
[66,35,32,5],
[66,35,32,6],
[66,35,32,8],
[66,35,32,10],
[66,35,32,13],
[66,35,32,14],
[66,35,32,16],
[66,35,32,17],
[66,35,33,0],
[66,35,33,1],
[66,35,33,2],
[66,35,33,3],
[66,35,33,4],
[66,35,33,5],
[66,35,33,6],
[66,35,33,8],
[66,35,33,10],
[66,35,33,13],
[66,35,33,14],
[66,36,3,0],
[66,36,3,1],
[66,36,3,2],
[66,36,4,0],
[66,36,4,1],
[66,36,4,2],
[66,36,4,3],
[66,36,5,0],
[66,36,5,1],
[66,36,5,2],
[66,36,5,4],
[66,36,6,0],
[66,36,6,1],
[66,36,6,2],
[66,36,6,4],
[66,36,8,0],
[66,36,8,1],
[66,36,8,2],
[66,36,8,4],
[66,36,9,0],
[66,36,9,1],
[66,36,9,2],
[66,36,9,3],
[66,36,9,4],
[66,36,9,5],
[66,36,9,6],
[66,36,9,8],
[66,36,10,0],
[66,36,10,1],
[66,36,10,2],
[66,36,10,3],
[66,36,10,4],
[66,36,10,5],
[66,36,10,6],
[66,36,10,8],
[66,36,10,9],
[66,36,11,0],
[66,36,11,1],
[66,36,11,2],
[66,36,11,3],
[66,36,11,4],
[66,36,11,5],
[66,36,11,6],
[66,36,11,8],
[66,36,11,9],
[66,36,11,10],
[66,36,12,0],
[66,36,12,1],
[66,36,12,2],
[66,36,12,3],
[66,36,12,4],
[66,36,12,5],
[66,36,12,6],
[66,36,12,8],
[66,36,12,9],
[66,36,12,10],
[66,36,15,0],
[66,36,15,1],
[66,36,15,2],
[66,36,15,3],
[66,36,15,4],
[66,36,15,5],
[66,36,15,6],
[66,36,15,8],
[66,36,15,10],
[66,36,15,11],
[66,36,15,12],
[66,36,16,0],
[66,36,16,1],
[66,36,16,2],
[66,36,16,3],
[66,36,16,4],
[66,36,16,5],
[66,36,16,6],
[66,36,16,8],
[66,36,16,9],
[66,36,16,10],
[66,36,16,11],
[66,36,16,12],
[66,36,16,15],
[66,36,17,0],
[66,36,17,1],
[66,36,17,2],
[66,36,17,3],
[66,36,17,4],
[66,36,17,5],
[66,36,17,6],
[66,36,17,8],
[66,36,17,9],
[66,36,17,10],
[66,36,17,11],
[66,36,17,12],
[66,36,17,15],
[66,36,17,16],
[66,36,18,0],
[66,36,18,1],
[66,36,18,2],
[66,36,18,3],
[66,36,18,4],
[66,36,18,5],
[66,36,18,6],
[66,36,18,8],
[66,36,18,10],
[66,36,18,11],
[66,36,18,12],
[66,36,18,16],
[66,36,18,17],
[66,36,20,0],
[66,36,20,1],
[66,36,20,2],
[66,36,20,3],
[66,36,20,4],
[66,36,20,5],
[66,36,20,6],
[66,36,20,8],
[66,36,20,9],
[66,36,20,11],
[66,36,20,12],
[66,36,20,16],
[66,36,20,17],
[66,36,20,18],
[66,36,21,0],
[66,36,21,1],
[66,36,21,2],
[66,36,21,4],
[66,36,21,9],
[66,36,21,10],
[66,36,21,11],
[66,36,21,12],
[66,36,21,15],
[66,36,21,16],
[66,36,21,17],
[66,36,21,18],
[66,36,21,20],
[66,36,22,0],
[66,36,22,1],
[66,36,22,2],
[66,36,22,3],
[66,36,22,4],
[66,36,22,5],
[66,36,22,6],
[66,36,22,8],
[66,36,22,9],
[66,36,22,11],
[66,36,22,15],
[66,36,22,16],
[66,36,22,17],
[66,36,22,18],
[66,36,22,21],
[66,36,23,0],
[66,36,23,1],
[66,36,23,2],
[66,36,23,3],
[66,36,23,4],
[66,36,23,5],
[66,36,23,6],
[66,36,23,8],
[66,36,23,9],
[66,36,23,12],
[66,36,23,15],
[66,36,23,16],
[66,36,23,17],
[66,36,23,18],
[66,36,23,20],
[66,36,23,21],
[66,36,23,22],
[66,36,24,0],
[66,36,24,1],
[66,36,24,2],
[66,36,24,3],
[66,36,24,4],
[66,36,24,5],
[66,36,24,6],
[66,36,24,8],
[66,36,24,9],
[66,36,24,12],
[66,36,24,15],
[66,36,24,17],
[66,36,24,18],
[66,36,24,20],
[66,36,24,21],
[66,36,24,22],
[66,36,24,23],
[66,36,26,0],
[66,36,26,1],
[66,36,26,2],
[66,36,26,3],
[66,36,26,4],
[66,36,26,6],
[66,36,26,8],
[66,36,26,10],
[66,36,26,11],
[66,36,26,12],
[66,36,26,16],
[66,36,26,17],
[66,36,26,20],
[66,36,26,21],
[66,36,26,22],
[66,36,27,0],
[66,36,27,1],
[66,36,27,2],
[66,36,27,5],
[66,36,27,6],
[66,36,27,8],
[66,36,27,9],
[66,36,27,11],
[66,36,27,12],
[66,36,27,15],
[66,36,27,16],
[66,36,27,17],
[66,36,27,18],
[66,36,27,21],
[66,36,29,0],
[66,36,29,1],
[66,36,29,2],
[66,36,29,3],
[66,36,29,4],
[66,36,29,5],
[66,36,29,6],
[66,36,29,8],
[66,36,29,9],
[66,36,29,10],
[66,36,29,15],
[66,36,29,16],
[66,36,29,17],
[66,36,29,18],
[66,36,30,0],
[66,36,30,1],
[66,36,30,2],
[66,36,30,3],
[66,36,30,4],
[66,36,30,5],
[66,36,30,6],
[66,36,30,8],
[66,36,30,9],
[66,36,30,10],
[66,36,30,11],
[66,36,30,12],
[66,36,30,15],
[66,36,30,16],
[66,36,30,17],
[66,36,30,18],
[66,36,31,0],
[66,36,31,1],
[66,36,31,2],
[66,36,31,3],
[66,36,31,4],
[66,36,31,5],
[66,36,31,6],
[66,36,31,8],
[66,36,31,9],
[66,36,31,10],
[66,36,31,11],
[66,36,31,12],
[66,36,31,15],
[66,36,31,16],
[66,36,31,17],
[66,36,32,0],
[66,36,32,1],
[66,36,32,2],
[66,36,32,3],
[66,36,32,4],
[66,36,32,5],
[66,36,32,6],
[66,36,32,8],
[66,36,32,9],
[66,36,32,10],
[66,36,32,15],
[66,36,33,0],
[66,36,33,1],
[66,36,33,2],
[66,36,33,3],
[66,36,33,4],
[66,36,33,5],
[66,36,33,6],
[66,36,33,8],
[66,36,33,9],
[66,36,33,10],
[66,36,35,0],
[66,36,35,1],
[66,36,35,2],
[66,36,35,3],
[66,36,35,4],
[66,36,35,5],
[66,36,35,6],
[66,36,35,8],
[66,36,35,10],
[66,36,35,11],
[66,37,3,0],
[66,37,3,1],
[66,37,3,2],
[66,37,5,0],
[66,37,5,1],
[66,37,5,2],
[66,37,6,0],
[66,37,6,1],
[66,37,6,2],
[66,37,8,0],
[66,37,8,1],
[66,37,8,2],
[66,37,9,0],
[66,37,9,1],
[66,37,9,2],
[66,37,9,3],
[66,37,9,5],
[66,37,9,6],
[66,37,9,8],
[66,37,10,0],
[66,37,10,1],
[66,37,10,2],
[66,37,10,3],
[66,37,10,5],
[66,37,10,6],
[66,37,10,8],
[66,37,10,9],
[66,37,11,0],
[66,37,11,1],
[66,37,11,2],
[66,37,11,3],
[66,37,11,5],
[66,37,11,6],
[66,37,11,8],
[66,37,11,9],
[66,37,11,10],
[66,37,12,0],
[66,37,12,1],
[66,37,12,2],
[66,37,12,3],
[66,37,12,5],
[66,37,12,6],
[66,37,12,8],
[66,37,12,9],
[66,37,12,10],
[66,37,13,0],
[66,37,13,1],
[66,37,13,2],
[66,37,13,3],
[66,37,13,5],
[66,37,13,6],
[66,37,13,8],
[66,37,13,9],
[66,37,13,10],
[66,37,13,11],
[66,37,13,12],
[66,37,14,0],
[66,37,14,1],
[66,37,14,2],
[66,37,14,3],
[66,37,14,5],
[66,37,14,6],
[66,37,14,8],
[66,37,14,9],
[66,37,14,10],
[66,37,14,11],
[66,37,14,12],
[66,37,15,0],
[66,37,15,1],
[66,37,15,2],
[66,37,15,3],
[66,37,15,5],
[66,37,15,6],
[66,37,15,8],
[66,37,15,10],
[66,37,15,11],
[66,37,15,12],
[66,37,15,13],
[66,37,15,14],
[66,37,16,0],
[66,37,16,1],
[66,37,16,2],
[66,37,16,3],
[66,37,16,5],
[66,37,16,6],
[66,37,16,8],
[66,37,16,9],
[66,37,16,10],
[66,37,16,11],
[66,37,16,12],
[66,37,16,13],
[66,37,16,14],
[66,37,16,15],
[66,37,17,0],
[66,37,17,1],
[66,37,17,2],
[66,37,17,3],
[66,37,17,5],
[66,37,17,6],
[66,37,17,8],
[66,37,17,9],
[66,37,17,10],
[66,37,17,11],
[66,37,17,12],
[66,37,17,13],
[66,37,17,14],
[66,37,17,15],
[66,37,17,16],
[66,37,18,0],
[66,37,18,1],
[66,37,18,2],
[66,37,18,3],
[66,37,18,5],
[66,37,18,6],
[66,37,18,8],
[66,37,18,10],
[66,37,18,11],
[66,37,18,12],
[66,37,18,13],
[66,37,18,14],
[66,37,19,0],
[66,37,19,1],
[66,37,19,2],
[66,37,19,3],
[66,37,19,5],
[66,37,19,6],
[66,37,19,8],
[66,37,19,9],
[66,37,19,10],
[66,37,19,11],
[66,37,19,12],
[66,37,19,16],
[66,37,19,17],
[66,37,19,18],
[66,37,20,0],
[66,37,20,1],
[66,37,20,2],
[66,37,20,3],
[66,37,20,5],
[66,37,20,6],
[66,37,20,8],
[66,37,20,9],
[66,37,20,11],
[66,37,20,12],
[66,37,20,13],
[66,37,20,15],
[66,37,20,16],
[66,37,20,17],
[66,37,20,18],
[66,37,20,19],
[66,37,21,0],
[66,37,21,1],
[66,37,21,2],
[66,37,21,9],
[66,37,21,10],
[66,37,21,11],
[66,37,21,14],
[66,37,21,15],
[66,37,21,16],
[66,37,21,17],
[66,37,21,18],
[66,37,21,19],
[66,37,21,20],
[66,37,22,0],
[66,37,22,1],
[66,37,22,2],
[66,37,22,3],
[66,37,22,5],
[66,37,22,6],
[66,37,22,8],
[66,37,22,9],
[66,37,22,12],
[66,37,22,13],
[66,37,22,14],
[66,37,22,15],
[66,37,22,16],
[66,37,22,17],
[66,37,22,18],
[66,37,22,19],
[66,37,22,21],
[66,37,23,0],
[66,37,23,1],
[66,37,23,2],
[66,37,23,3],
[66,37,23,5],
[66,37,23,6],
[66,37,23,10],
[66,37,23,11],
[66,37,23,12],
[66,37,23,13],
[66,37,23,14],
[66,37,23,15],
[66,37,23,16],
[66,37,23,17],
[66,37,23,18],
[66,37,23,19],
[66,37,23,20],
[66,37,23,21],
[66,37,23,22],
[66,37,24,0],
[66,37,24,1],
[66,37,24,2],
[66,37,24,3],
[66,37,24,5],
[66,37,24,6],
[66,37,24,10],
[66,37,24,11],
[66,37,24,12],
[66,37,24,13],
[66,37,24,14],
[66,37,24,15],
[66,37,24,17],
[66,37,24,18],
[66,37,24,19],
[66,37,24,20],
[66,37,24,21],
[66,37,24,22],
[66,37,24,23],
[66,37,26,0],
[66,37,26,1],
[66,37,26,2],
[66,37,26,5],
[66,37,26,6],
[66,37,26,8],
[66,37,26,10],
[66,37,26,11],
[66,37,26,12],
[66,37,26,13],
[66,37,26,14],
[66,37,26,16],
[66,37,26,17],
[66,37,26,19],
[66,37,26,20],
[66,37,26,21],
[66,37,27,3],
[66,37,27,5],
[66,37,27,6],
[66,37,27,8],
[66,37,27,9],
[66,37,27,11],
[66,37,27,12],
[66,37,27,13],
[66,37,27,14],
[66,37,27,15],
[66,37,27,16],
[66,37,27,17],
[66,37,27,18],
[66,37,27,19],
[66,37,29,0],
[66,37,29,1],
[66,37,29,2],
[66,37,29,3],
[66,37,29,5],
[66,37,29,6],
[66,37,29,8],
[66,37,29,9],
[66,37,29,10],
[66,37,29,13],
[66,37,29,14],
[66,37,29,15],
[66,37,29,16],
[66,37,29,17],
[66,37,29,18],
[66,37,30,0],
[66,37,30,1],
[66,37,30,2],
[66,37,30,3],
[66,37,30,5],
[66,37,30,6],
[66,37,30,8],
[66,37,30,9],
[66,37,30,10],
[66,37,30,11],
[66,37,30,12],
[66,37,30,13],
[66,37,30,14],
[66,37,30,15],
[66,37,30,16],
[66,37,30,17],
[66,37,31,0],
[66,37,31,1],
[66,37,31,2],
[66,37,31,3],
[66,37,31,5],
[66,37,31,6],
[66,37,31,8],
[66,37,31,9],
[66,37,31,10],
[66,37,31,11],
[66,37,31,12],
[66,37,31,13],
[66,37,31,14],
[66,37,31,15],
[66,37,32,0],
[66,37,32,1],
[66,37,32,2],
[66,37,32,3],
[66,37,32,5],
[66,37,32,6],
[66,37,32,8],
[66,37,32,9],
[66,37,32,10],
[66,37,32,13],
[66,37,32,14],
[66,37,33,0],
[66,37,33,1],
[66,37,33,2],
[66,37,33,3],
[66,37,33,5],
[66,37,33,6],
[66,37,33,8],
[66,37,33,9],
[66,37,33,10],
[66,37,33,13],
[66,37,35,0],
[66,37,35,1],
[66,37,35,2],
[66,37,35,3],
[66,37,35,5],
[66,37,35,6],
[66,37,35,8],
[66,37,36,0],
[66,37,36,1],
[66,37,36,2],
[66,37,36,3],
[66,37,36,5],
[66,37,36,6],
[66,39,4,0],
[66,39,4,1],
[66,39,4,2],
[66,39,9,0],
[66,39,9,1],
[66,39,9,2],
[66,39,9,4],
[66,39,10,0],
[66,39,10,1],
[66,39,10,2],
[66,39,10,4],
[66,39,10,9],
[66,39,11,0],
[66,39,11,1],
[66,39,11,2],
[66,39,11,4],
[66,39,11,9],
[66,39,11,10],
[66,39,12,0],
[66,39,12,1],
[66,39,12,2],
[66,39,12,4],
[66,39,12,9],
[66,39,12,10],
[66,39,13,0],
[66,39,13,1],
[66,39,13,2],
[66,39,13,4],
[66,39,13,9],
[66,39,13,10],
[66,39,13,11],
[66,39,13,12],
[66,39,14,0],
[66,39,14,1],
[66,39,14,2],
[66,39,14,4],
[66,39,14,9],
[66,39,14,10],
[66,39,14,11],
[66,39,14,12],
[66,39,15,0],
[66,39,15,1],
[66,39,15,2],
[66,39,15,4],
[66,39,15,10],
[66,39,15,11],
[66,39,15,12],
[66,39,15,13],
[66,39,15,14],
[66,39,16,0],
[66,39,16,1],
[66,39,16,2],
[66,39,16,4],
[66,39,16,9],
[66,39,16,10],
[66,39,16,11],
[66,39,16,12],
[66,39,16,13],
[66,39,16,14],
[66,39,17,0],
[66,39,17,1],
[66,39,17,2],
[66,39,17,4],
[66,39,17,9],
[66,39,17,10],
[66,39,17,11],
[66,39,17,12],
[66,39,17,13],
[66,39,17,14],
[66,39,17,16],
[66,39,18,0],
[66,39,18,1],
[66,39,18,2],
[66,39,18,4],
[66,39,18,10],
[66,39,18,11],
[66,39,18,12],
[66,39,18,13],
[66,39,18,16],
[66,39,18,17],
[66,39,19,0],
[66,39,19,1],
[66,39,19,2],
[66,39,19,4],
[66,39,19,9],
[66,39,19,10],
[66,39,19,11],
[66,39,19,15],
[66,39,19,16],
[66,39,19,17],
[66,39,19,18],
[66,39,20,0],
[66,39,20,1],
[66,39,20,2],
[66,39,20,4],
[66,39,20,9],
[66,39,20,12],
[66,39,20,13],
[66,39,20,14],
[66,39,20,15],
[66,39,20,16],
[66,39,20,17],
[66,39,20,18],
[66,39,20,19],
[66,39,22,0],
[66,39,22,1],
[66,39,22,2],
[66,39,22,4],
[66,39,22,9],
[66,39,22,11],
[66,39,22,12],
[66,39,22,13],
[66,39,22,14],
[66,39,22,15],
[66,39,22,16],
[66,39,22,17],
[66,39,22,18],
[66,39,22,19],
[66,39,23,0],
[66,39,23,1],
[66,39,23,2],
[66,39,23,4],
[66,39,23,9],
[66,39,23,10],
[66,39,23,11],
[66,39,23,12],
[66,39,23,13],
[66,39,23,14],
[66,39,23,15],
[66,39,23,16],
[66,39,23,17],
[66,39,23,18],
[66,39,23,19],
[66,39,23,20],
[66,39,23,22],
[66,39,24,0],
[66,39,24,1],
[66,39,24,2],
[66,39,24,4],
[66,39,24,9],
[66,39,24,10],
[66,39,24,11],
[66,39,24,12],
[66,39,24,13],
[66,39,24,14],
[66,39,24,15],
[66,39,24,17],
[66,39,24,18],
[66,39,24,19],
[66,39,24,20],
[66,39,24,22],
[66,39,26,0],
[66,39,26,1],
[66,39,26,2],
[66,39,26,4],
[66,39,26,10],
[66,39,26,11],
[66,39,26,12],
[66,39,26,13],
[66,39,26,14],
[66,39,26,16],
[66,39,26,17],
[66,39,26,19],
[66,39,27,0],
[66,39,27,1],
[66,39,27,2],
[66,39,27,4],
[66,39,27,9],
[66,39,27,11],
[66,39,27,12],
[66,39,27,13],
[66,39,27,14],
[66,39,27,15],
[66,39,27,16],
[66,39,27,17],
[66,39,27,18],
[66,39,29,0],
[66,39,29,1],
[66,39,29,2],
[66,39,29,4],
[66,39,29,9],
[66,39,29,10],
[66,39,29,13],
[66,39,29,14],
[66,39,29,15],
[66,39,30,0],
[66,39,30,1],
[66,39,30,2],
[66,39,30,4],
[66,39,30,9],
[66,39,30,10],
[66,39,30,11],
[66,39,30,12],
[66,39,30,13],
[66,39,30,14],
[66,39,31,0],
[66,39,31,1],
[66,39,31,2],
[66,39,31,4],
[66,39,31,9],
[66,39,31,10],
[66,39,31,11],
[66,39,31,12],
[66,39,31,13],
[66,39,32,0],
[66,39,32,1],
[66,39,32,2],
[66,39,32,4],
[66,39,32,9],
[66,39,32,10],
[66,39,33,0],
[66,39,33,1],
[66,39,33,2],
[66,39,33,4],
[66,39,33,9],
[66,39,35,0],
[66,39,35,1],
[66,39,35,2],
[66,39,35,4],
[66,39,36,0],
[66,39,36,1],
[66,39,36,2],
[66,39,36,4],
[66,39,37,0],
[66,39,37,1],
[66,39,37,2],
[66,40,3,0],
[66,40,3,1],
[66,40,3,2],
[66,40,4,0],
[66,40,4,1],
[66,40,4,2],
[66,40,4,3],
[66,40,5,0],
[66,40,5,1],
[66,40,5,2],
[66,40,5,4],
[66,40,6,0],
[66,40,6,1],
[66,40,6,2],
[66,40,6,4],
[66,40,8,0],
[66,40,8,1],
[66,40,8,2],
[66,40,8,4],
[66,40,10,0],
[66,40,10,1],
[66,40,10,2],
[66,40,10,3],
[66,40,10,4],
[66,40,10,5],
[66,40,10,6],
[66,40,10,8],
[66,40,11,0],
[66,40,11,1],
[66,40,11,2],
[66,40,11,3],
[66,40,11,4],
[66,40,11,5],
[66,40,11,6],
[66,40,11,8],
[66,40,11,10],
[66,40,12,0],
[66,40,12,1],
[66,40,12,2],
[66,40,12,3],
[66,40,12,4],
[66,40,12,5],
[66,40,12,6],
[66,40,12,8],
[66,40,12,10],
[66,40,13,0],
[66,40,13,1],
[66,40,13,2],
[66,40,13,3],
[66,40,13,4],
[66,40,13,5],
[66,40,13,6],
[66,40,13,8],
[66,40,13,10],
[66,40,13,11],
[66,40,13,12],
[66,40,14,0],
[66,40,14,1],
[66,40,14,2],
[66,40,14,3],
[66,40,14,4],
[66,40,14,5],
[66,40,14,6],
[66,40,14,8],
[66,40,14,10],
[66,40,14,11],
[66,40,14,12],
[66,40,16,0],
[66,40,16,1],
[66,40,16,2],
[66,40,16,3],
[66,40,16,4],
[66,40,16,5],
[66,40,16,6],
[66,40,16,8],
[66,40,16,10],
[66,40,16,11],
[66,40,16,12],
[66,40,16,13],
[66,40,17,0],
[66,40,17,1],
[66,40,17,2],
[66,40,17,3],
[66,40,17,4],
[66,40,17,5],
[66,40,17,6],
[66,40,17,8],
[66,40,17,10],
[66,40,17,11],
[66,40,17,12],
[66,40,17,13],
[66,40,17,16],
[66,40,19,0],
[66,40,19,1],
[66,40,19,2],
[66,40,19,3],
[66,40,19,4],
[66,40,19,5],
[66,40,19,6],
[66,40,19,8],
[66,40,19,12],
[66,40,19,16],
[66,40,19,17],
[66,40,20,0],
[66,40,20,1],
[66,40,20,2],
[66,40,20,3],
[66,40,20,4],
[66,40,20,5],
[66,40,20,6],
[66,40,20,11],
[66,40,20,12],
[66,40,20,13],
[66,40,20,14],
[66,40,20,16],
[66,40,20,17],
[66,40,20,19],
[66,40,21,0],
[66,40,21,1],
[66,40,21,2],
[66,40,21,4],
[66,40,21,10],
[66,40,21,11],
[66,40,21,12],
[66,40,21,13],
[66,40,21,14],
[66,40,21,16],
[66,40,21,17],
[66,40,21,19],
[66,40,21,20],
[66,40,22,0],
[66,40,22,1],
[66,40,22,2],
[66,40,22,3],
[66,40,22,4],
[66,40,22,6],
[66,40,22,8],
[66,40,22,11],
[66,40,22,12],
[66,40,22,13],
[66,40,22,14],
[66,40,22,16],
[66,40,22,17],
[66,40,22,19],
[66,40,22,21],
[66,40,23,0],
[66,40,23,1],
[66,40,23,2],
[66,40,23,5],
[66,40,23,6],
[66,40,23,8],
[66,40,23,10],
[66,40,23,11],
[66,40,23,12],
[66,40,23,13],
[66,40,23,14],
[66,40,23,16],
[66,40,23,17],
[66,40,23,19],
[66,40,23,20],
[66,40,23,21],
[66,40,24,0],
[66,40,24,1],
[66,40,24,2],
[66,40,24,5],
[66,40,24,6],
[66,40,24,8],
[66,40,24,10],
[66,40,24,11],
[66,40,24,12],
[66,40,24,13],
[66,40,24,14],
[66,40,24,17],
[66,40,24,19],
[66,40,24,20],
[66,40,24,21],
[66,40,27,0],
[66,40,27,1],
[66,40,27,2],
[66,40,27,3],
[66,40,27,4],
[66,40,27,5],
[66,40,27,6],
[66,40,27,8],
[66,40,27,11],
[66,40,27,12],
[66,40,27,13],
[66,40,27,14],
[66,40,27,16],
[66,40,27,17],
[66,40,29,0],
[66,40,29,1],
[66,40,29,2],
[66,40,29,3],
[66,40,29,4],
[66,40,29,5],
[66,40,29,6],
[66,40,29,8],
[66,40,29,10],
[66,40,29,13],
[66,40,29,14],
[66,40,30,0],
[66,40,30,1],
[66,40,30,2],
[66,40,30,3],
[66,40,30,4],
[66,40,30,5],
[66,40,30,6],
[66,40,30,8],
[66,40,30,10],
[66,40,30,11],
[66,40,30,12],
[66,40,30,13],
[66,40,31,0],
[66,40,31,1],
[66,40,31,2],
[66,40,31,3],
[66,40,31,4],
[66,40,31,5],
[66,40,31,6],
[66,40,31,8],
[66,40,31,10],
[66,40,31,11],
[66,40,32,0],
[66,40,32,1],
[66,40,32,2],
[66,40,32,3],
[66,40,32,4],
[66,40,32,5],
[66,40,32,6],
[66,40,32,8],
[66,40,33,0],
[66,40,33,1],
[66,40,33,2],
[66,40,33,3],
[66,40,33,4],
[66,40,33,5],
[66,40,33,6],
[66,40,36,0],
[66,40,36,1],
[66,40,36,2],
[66,41,3,0],
[66,41,3,1],
[66,41,3,2],
[66,41,4,0],
[66,41,4,1],
[66,41,4,2],
[66,41,4,3],
[66,41,5,0],
[66,41,5,1],
[66,41,5,2],
[66,41,5,4],
[66,41,6,0],
[66,41,6,1],
[66,41,6,2],
[66,41,6,4],
[66,41,8,0],
[66,41,8,1],
[66,41,8,2],
[66,41,8,4],
[66,41,10,0],
[66,41,10,1],
[66,41,10,2],
[66,41,10,3],
[66,41,10,4],
[66,41,10,5],
[66,41,10,6],
[66,41,10,8],
[66,41,11,0],
[66,41,11,1],
[66,41,11,2],
[66,41,11,3],
[66,41,11,4],
[66,41,11,5],
[66,41,11,6],
[66,41,11,8],
[66,41,11,10],
[66,41,12,0],
[66,41,12,1],
[66,41,12,2],
[66,41,12,3],
[66,41,12,4],
[66,41,12,5],
[66,41,12,6],
[66,41,12,8],
[66,41,12,10],
[66,41,13,0],
[66,41,13,1],
[66,41,13,2],
[66,41,13,3],
[66,41,13,4],
[66,41,13,5],
[66,41,13,6],
[66,41,13,8],
[66,41,13,10],
[66,41,13,11],
[66,41,13,12],
[66,41,14,0],
[66,41,14,1],
[66,41,14,2],
[66,41,14,3],
[66,41,14,4],
[66,41,14,5],
[66,41,14,6],
[66,41,14,8],
[66,41,14,10],
[66,41,14,11],
[66,41,14,12],
[66,41,16,0],
[66,41,16,1],
[66,41,16,2],
[66,41,16,3],
[66,41,16,4],
[66,41,16,5],
[66,41,16,6],
[66,41,16,8],
[66,41,16,10],
[66,41,16,11],
[66,41,16,12],
[66,41,16,13],
[66,41,17,0],
[66,41,17,1],
[66,41,17,2],
[66,41,17,3],
[66,41,17,4],
[66,41,17,5],
[66,41,17,6],
[66,41,17,8],
[66,41,17,10],
[66,41,17,11],
[66,41,17,12],
[66,41,17,13],
[66,41,17,16],
[66,41,19,0],
[66,41,19,1],
[66,41,19,2],
[66,41,19,3],
[66,41,19,4],
[66,41,19,5],
[66,41,19,6],
[66,41,19,8],
[66,41,19,12],
[66,41,19,16],
[66,41,19,17],
[66,41,20,0],
[66,41,20,1],
[66,41,20,2],
[66,41,20,3],
[66,41,20,4],
[66,41,20,5],
[66,41,20,6],
[66,41,20,11],
[66,41,20,12],
[66,41,20,13],
[66,41,20,14],
[66,41,20,16],
[66,41,20,17],
[66,41,20,19],
[66,41,21,0],
[66,41,21,1],
[66,41,21,2],
[66,41,21,4],
[66,41,21,10],
[66,41,21,11],
[66,41,21,12],
[66,41,21,13],
[66,41,21,14],
[66,41,21,16],
[66,41,21,17],
[66,41,21,19],
[66,41,21,20],
[66,41,22,0],
[66,41,22,1],
[66,41,22,2],
[66,41,22,3],
[66,41,22,4],
[66,41,22,6],
[66,41,22,8],
[66,41,22,11],
[66,41,22,12],
[66,41,22,13],
[66,41,22,14],
[66,41,22,16],
[66,41,22,17],
[66,41,22,19],
[66,41,22,21],
[66,41,23,0],
[66,41,23,1],
[66,41,23,2],
[66,41,23,5],
[66,41,23,6],
[66,41,23,8],
[66,41,23,10],
[66,41,23,11],
[66,41,23,12],
[66,41,23,13],
[66,41,23,14],
[66,41,23,16],
[66,41,23,17],
[66,41,23,19],
[66,41,23,20],
[66,41,23,21],
[66,41,24,0],
[66,41,24,1],
[66,41,24,2],
[66,41,24,5],
[66,41,24,6],
[66,41,24,8],
[66,41,24,10],
[66,41,24,11],
[66,41,24,12],
[66,41,24,13],
[66,41,24,14],
[66,41,24,17],
[66,41,24,19],
[66,41,24,20],
[66,41,24,21],
[66,41,27,0],
[66,41,27,1],
[66,41,27,2],
[66,41,27,3],
[66,41,27,4],
[66,41,27,5],
[66,41,27,6],
[66,41,27,8],
[66,41,27,11],
[66,41,27,12],
[66,41,27,13],
[66,41,27,14],
[66,41,27,16],
[66,41,27,17],
[66,41,29,0],
[66,41,29,1],
[66,41,29,2],
[66,41,29,3],
[66,41,29,4],
[66,41,29,5],
[66,41,29,6],
[66,41,29,8],
[66,41,29,10],
[66,41,29,13],
[66,41,29,14],
[66,41,30,0],
[66,41,30,1],
[66,41,30,2],
[66,41,30,3],
[66,41,30,4],
[66,41,30,5],
[66,41,30,6],
[66,41,30,8],
[66,41,30,10],
[66,41,30,11],
[66,41,30,12],
[66,41,30,13],
[66,41,31,0],
[66,41,31,1],
[66,41,31,2],
[66,41,31,3],
[66,41,31,4],
[66,41,31,5],
[66,41,31,6],
[66,41,31,8],
[66,41,31,10],
[66,41,31,11],
[66,41,32,0],
[66,41,32,1],
[66,41,32,2],
[66,41,32,3],
[66,41,32,4],
[66,41,32,5],
[66,41,32,6],
[66,41,32,8],
[66,41,33,0],
[66,41,33,1],
[66,41,33,2],
[66,41,33,3],
[66,41,33,4],
[66,41,33,5],
[66,41,33,6],
[66,41,36,0],
[66,41,36,1],
[66,41,36,2],
[66,42,3,0],
[66,42,3,1],
[66,42,3,2],
[66,42,4,0],
[66,42,4,1],
[66,42,4,2],
[66,42,4,3],
[66,42,5,0],
[66,42,5,1],
[66,42,5,2],
[66,42,5,4],
[66,42,6,0],
[66,42,6,1],
[66,42,6,2],
[66,42,6,4],
[66,42,8,0],
[66,42,8,1],
[66,42,8,2],
[66,42,8,4],
[66,42,9,0],
[66,42,9,1],
[66,42,9,2],
[66,42,9,3],
[66,42,9,4],
[66,42,9,5],
[66,42,9,6],
[66,42,9,8],
[66,42,11,0],
[66,42,11,1],
[66,42,11,2],
[66,42,11,3],
[66,42,11,4],
[66,42,11,5],
[66,42,11,6],
[66,42,11,8],
[66,42,11,9],
[66,42,12,0],
[66,42,12,1],
[66,42,12,2],
[66,42,12,3],
[66,42,12,4],
[66,42,12,5],
[66,42,12,6],
[66,42,12,8],
[66,42,12,9],
[66,42,13,0],
[66,42,13,1],
[66,42,13,2],
[66,42,13,3],
[66,42,13,4],
[66,42,13,5],
[66,42,13,6],
[66,42,13,8],
[66,42,13,9],
[66,42,13,11],
[66,42,13,12],
[66,42,14,0],
[66,42,14,1],
[66,42,14,2],
[66,42,14,3],
[66,42,14,4],
[66,42,14,5],
[66,42,14,6],
[66,42,14,8],
[66,42,14,9],
[66,42,14,11],
[66,42,14,12],
[66,42,15,0],
[66,42,15,1],
[66,42,15,2],
[66,42,15,3],
[66,42,15,4],
[66,42,15,5],
[66,42,15,6],
[66,42,15,8],
[66,42,15,11],
[66,42,15,12],
[66,42,15,13],
[66,42,16,0],
[66,42,16,1],
[66,42,16,2],
[66,42,16,3],
[66,42,16,4],
[66,42,16,5],
[66,42,16,6],
[66,42,16,8],
[66,42,16,9],
[66,42,16,11],
[66,42,16,14],
[66,42,16,15],
[66,42,17,0],
[66,42,17,1],
[66,42,17,2],
[66,42,17,3],
[66,42,17,4],
[66,42,17,5],
[66,42,17,6],
[66,42,17,8],
[66,42,17,9],
[66,42,17,11],
[66,42,17,14],
[66,42,17,15],
[66,42,17,16],
[66,42,18,0],
[66,42,18,1],
[66,42,18,2],
[66,42,18,3],
[66,42,18,4],
[66,42,18,5],
[66,42,18,6],
[66,42,18,8],
[66,42,18,12],
[66,42,18,13],
[66,42,18,14],
[66,42,18,16],
[66,42,18,17],
[66,42,19,0],
[66,42,19,1],
[66,42,19,2],
[66,42,19,3],
[66,42,19,4],
[66,42,19,5],
[66,42,19,6],
[66,42,19,11],
[66,42,19,12],
[66,42,19,15],
[66,42,19,16],
[66,42,19,17],
[66,42,19,18],
[66,42,21,0],
[66,42,21,1],
[66,42,21,2],
[66,42,21,4],
[66,42,21,9],
[66,42,21,11],
[66,42,21,12],
[66,42,21,13],
[66,42,21,14],
[66,42,21,15],
[66,42,21,16],
[66,42,21,17],
[66,42,21,18],
[66,42,21,19],
[66,42,23,3],
[66,42,23,4],
[66,42,23,5],
[66,42,23,6],
[66,42,23,8],
[66,42,23,9],
[66,42,23,11],
[66,42,23,12],
[66,42,23,13],
[66,42,23,14],
[66,42,23,15],
[66,42,23,16],
[66,42,23,17],
[66,42,23,18],
[66,42,23,19],
[66,42,24,3],
[66,42,24,4],
[66,42,24,5],
[66,42,24,6],
[66,42,24,8],
[66,42,24,9],
[66,42,24,11],
[66,42,24,12],
[66,42,24,13],
[66,42,24,14],
[66,42,24,15],
[66,42,24,17],
[66,42,24,18],
[66,42,24,19],
[66,42,26,0],
[66,42,26,1],
[66,42,26,2],
[66,42,26,3],
[66,42,26,4],
[66,42,26,5],
[66,42,26,6],
[66,42,26,8],
[66,42,26,11],
[66,42,26,12],
[66,42,26,13],
[66,42,26,14],
[66,42,26,16],
[66,42,26,17],
[66,42,29,0],
[66,42,29,1],
[66,42,29,2],
[66,42,29,3],
[66,42,29,4],
[66,42,29,5],
[66,42,29,6],
[66,42,29,8],
[66,42,29,9],
[66,42,29,13],
[66,42,30,0],
[66,42,30,1],
[66,42,30,2],
[66,42,30,3],
[66,42,30,4],
[66,42,30,5],
[66,42,30,6],
[66,42,30,8],
[66,42,30,9],
[66,42,30,11],
[66,42,31,0],
[66,42,31,1],
[66,42,31,2],
[66,42,31,3],
[66,42,31,4],
[66,42,31,5],
[66,42,31,6],
[66,42,31,8],
[66,42,31,9],
[66,42,32,0],
[66,42,32,1],
[66,42,32,2],
[66,42,32,3],
[66,42,32,4],
[66,42,32,5],
[66,42,32,6],
[66,42,33,0],
[66,42,33,1],
[66,42,33,2],
[66,42,33,3],
[66,42,33,4],
[66,42,33,5],
[66,42,35,0],
[66,42,35,1],
[66,42,35,2],
[66,44,3,0],
[66,44,3,1],
[66,44,3,2],
[66,44,4,0],
[66,44,4,1],
[66,44,4,2],
[66,44,4,3],
[66,44,5,0],
[66,44,5,1],
[66,44,5,2],
[66,44,5,4],
[66,44,6,0],
[66,44,6,1],
[66,44,6,2],
[66,44,6,4],
[66,44,8,0],
[66,44,8,1],
[66,44,8,2],
[66,44,8,4],
[66,44,9,0],
[66,44,9,1],
[66,44,9,2],
[66,44,9,3],
[66,44,9,4],
[66,44,9,5],
[66,44,9,6],
[66,44,9,8],
[66,44,10,0],
[66,44,10,1],
[66,44,10,2],
[66,44,10,3],
[66,44,10,4],
[66,44,10,5],
[66,44,10,6],
[66,44,10,8],
[66,44,10,9],
[66,44,11,0],
[66,44,11,1],
[66,44,11,2],
[66,44,11,3],
[66,44,11,4],
[66,44,11,5],
[66,44,11,6],
[66,44,11,8],
[66,44,11,9],
[66,44,11,10],
[66,44,12,0],
[66,44,12,1],
[66,44,12,2],
[66,44,12,3],
[66,44,12,4],
[66,44,12,5],
[66,44,12,6],
[66,44,12,8],
[66,44,12,9],
[66,44,12,10],
[66,44,13,0],
[66,44,13,1],
[66,44,13,2],
[66,44,13,3],
[66,44,13,4],
[66,44,13,5],
[66,44,13,6],
[66,44,13,8],
[66,44,13,9],
[66,44,13,10],
[66,44,13,11],
[66,44,13,12],
[66,44,14,0],
[66,44,14,1],
[66,44,14,2],
[66,44,14,3],
[66,44,14,4],
[66,44,14,5],
[66,44,14,6],
[66,44,14,8],
[66,44,14,9],
[66,44,14,10],
[66,44,14,11],
[66,44,15,0],
[66,44,15,1],
[66,44,15,2],
[66,44,15,3],
[66,44,15,4],
[66,44,15,5],
[66,44,15,6],
[66,44,15,8],
[66,44,15,12],
[66,44,15,13],
[66,44,15,14],
[66,44,16,0],
[66,44,16,1],
[66,44,16,2],
[66,44,16,3],
[66,44,16,4],
[66,44,16,5],
[66,44,16,6],
[66,44,16,10],
[66,44,16,11],
[66,44,16,12],
[66,44,16,13],
[66,44,16,14],
[66,44,16,15],
[66,44,18,0],
[66,44,18,1],
[66,44,18,2],
[66,44,18,3],
[66,44,18,4],
[66,44,18,5],
[66,44,18,8],
[66,44,18,10],
[66,44,18,11],
[66,44,18,12],
[66,44,18,13],
[66,44,18,14],
[66,44,18,16],
[66,44,19,0],
[66,44,19,1],
[66,44,19,2],
[66,44,19,3],
[66,44,19,4],
[66,44,19,6],
[66,44,19,8],
[66,44,19,9],
[66,44,19,10],
[66,44,19,11],
[66,44,19,12],
[66,44,19,15],
[66,44,19,16],
[66,44,19,18],
[66,44,20,0],
[66,44,20,1],
[66,44,20,2],
[66,44,20,5],
[66,44,20,6],
[66,44,20,8],
[66,44,20,9],
[66,44,20,11],
[66,44,20,12],
[66,44,20,13],
[66,44,20,14],
[66,44,20,15],
[66,44,20,16],
[66,44,20,18],
[66,44,20,19],
[66,44,21,4],
[66,44,21,9],
[66,44,21,10],
[66,44,21,11],
[66,44,21,12],
[66,44,21,13],
[66,44,21,14],
[66,44,21,15],
[66,44,21,16],
[66,44,21,18],
[66,44,21,19],
[66,44,21,20],
[66,44,22,0],
[66,44,22,1],
[66,44,22,2],
[66,44,22,3],
[66,44,22,4],
[66,44,22,5],
[66,44,22,6],
[66,44,22,8],
[66,44,22,9],
[66,44,22,11],
[66,44,22,12],
[66,44,22,13],
[66,44,22,14],
[66,44,22,15],
[66,44,22,16],
[66,44,22,18],
[66,44,22,19],
[66,44,23,0],
[66,44,23,1],
[66,44,23,2],
[66,44,23,3],
[66,44,23,4],
[66,44,23,5],
[66,44,23,6],
[66,44,23,8],
[66,44,23,9],
[66,44,23,10],
[66,44,23,11],
[66,44,23,12],
[66,44,23,13],
[66,44,23,14],
[66,44,23,15],
[66,44,23,16],
[66,44,23,18],
[66,44,24,0],
[66,44,24,1],
[66,44,24,2],
[66,44,24,3],
[66,44,24,4],
[66,44,24,5],
[66,44,24,6],
[66,44,24,8],
[66,44,24,9],
[66,44,24,10],
[66,44,24,11],
[66,44,24,12],
[66,44,24,13],
[66,44,24,14],
[66,44,24,15],
[66,44,24,18],
[66,44,26,0],
[66,44,26,1],
[66,44,26,2],
[66,44,26,3],
[66,44,26,4],
[66,44,26,5],
[66,44,26,6],
[66,44,26,8],
[66,44,26,10],
[66,44,26,11],
[66,44,26,12],
[66,44,26,13],
[66,44,26,14],
[66,44,27,0],
[66,44,27,1],
[66,44,27,2],
[66,44,27,3],
[66,44,27,4],
[66,44,27,5],
[66,44,27,6],
[66,44,27,8],
[66,44,27,9],
[66,44,27,11],
[66,44,27,12],
[66,44,27,13],
[66,44,29,0],
[66,44,29,1],
[66,44,29,2],
[66,44,29,3],
[66,44,29,4],
[66,44,29,5],
[66,44,29,6],
[66,44,29,8],
[66,44,29,9],
[66,44,30,0],
[66,44,30,1],
[66,44,30,2],
[66,44,30,3],
[66,44,30,4],
[66,44,30,5],
[66,44,30,6],
[66,44,31,0],
[66,44,31,1],
[66,44,31,2],
[66,44,31,3],
[66,44,31,4],
[66,44,31,5],
[66,44,32,0],
[66,44,32,1],
[66,44,32,2],
[66,44,32,3],
[66,44,32,4],
[66,44,33,0],
[66,44,33,1],
[66,44,33,2],
[66,45,3,0],
[66,45,3,1],
[66,45,3,2],
[66,45,4,0],
[66,45,4,1],
[66,45,4,2],
[66,45,4,3],
[66,45,5,0],
[66,45,5,1],
[66,45,5,2],
[66,45,5,4],
[66,45,6,0],
[66,45,6,1],
[66,45,6,2],
[66,45,6,4],
[66,45,8,0],
[66,45,8,1],
[66,45,8,2],
[66,45,8,4],
[66,45,10,0],
[66,45,10,1],
[66,45,10,2],
[66,45,10,3],
[66,45,10,4],
[66,45,10,5],
[66,45,10,6],
[66,45,10,8],
[66,45,11,0],
[66,45,11,1],
[66,45,11,2],
[66,45,11,3],
[66,45,11,4],
[66,45,11,5],
[66,45,11,6],
[66,45,11,8],
[66,45,11,10],
[66,45,12,0],
[66,45,12,1],
[66,45,12,2],
[66,45,12,3],
[66,45,12,4],
[66,45,12,5],
[66,45,12,6],
[66,45,12,8],
[66,45,12,10],
[66,45,13,0],
[66,45,13,1],
[66,45,13,2],
[66,45,13,3],
[66,45,13,4],
[66,45,13,5],
[66,45,13,6],
[66,45,13,8],
[66,45,13,10],
[66,45,13,11],
[66,45,14,0],
[66,45,14,1],
[66,45,14,2],
[66,45,14,3],
[66,45,14,4],
[66,45,14,5],
[66,45,14,6],
[66,45,14,8],
[66,45,14,12],
[66,45,16,0],
[66,45,16,1],
[66,45,16,2],
[66,45,16,3],
[66,45,16,4],
[66,45,16,5],
[66,45,16,8],
[66,45,16,10],
[66,45,16,11],
[66,45,16,12],
[66,45,16,13],
[66,45,16,14],
[66,45,17,0],
[66,45,17,1],
[66,45,17,2],
[66,45,17,3],
[66,45,17,4],
[66,45,17,5],
[66,45,17,8],
[66,45,17,10],
[66,45,17,11],
[66,45,17,12],
[66,45,17,13],
[66,45,17,14],
[66,45,17,16],
[66,45,19,0],
[66,45,19,1],
[66,45,19,2],
[66,45,19,5],
[66,45,19,6],
[66,45,19,8],
[66,45,19,10],
[66,45,19,11],
[66,45,19,12],
[66,45,19,16],
[66,45,19,17],
[66,45,20,3],
[66,45,20,4],
[66,45,20,5],
[66,45,20,6],
[66,45,20,8],
[66,45,20,11],
[66,45,20,12],
[66,45,20,13],
[66,45,20,14],
[66,45,20,16],
[66,45,20,17],
[66,45,20,19],
[66,45,21,0],
[66,45,21,1],
[66,45,21,2],
[66,45,21,4],
[66,45,21,10],
[66,45,21,11],
[66,45,21,12],
[66,45,21,13],
[66,45,21,14],
[66,45,21,16],
[66,45,21,17],
[66,45,21,19],
[66,45,22,0],
[66,45,22,1],
[66,45,22,2],
[66,45,22,3],
[66,45,22,4],
[66,45,22,5],
[66,45,22,6],
[66,45,22,8],
[66,45,22,11],
[66,45,22,12],
[66,45,22,13],
[66,45,22,14],
[66,45,22,16],
[66,45,22,17],
[66,45,23,0],
[66,45,23,1],
[66,45,23,2],
[66,45,23,3],
[66,45,23,4],
[66,45,23,5],
[66,45,23,6],
[66,45,23,8],
[66,45,23,10],
[66,45,23,11],
[66,45,23,12],
[66,45,23,13],
[66,45,23,14],
[66,45,23,16],
[66,45,23,17],
[66,45,24,0],
[66,45,24,1],
[66,45,24,2],
[66,45,24,3],
[66,45,24,4],
[66,45,24,5],
[66,45,24,6],
[66,45,24,8],
[66,45,24,10],
[66,45,24,11],
[66,45,24,12],
[66,45,24,13],
[66,45,24,14],
[66,45,24,17],
[66,45,27,0],
[66,45,27,1],
[66,45,27,2],
[66,45,27,3],
[66,45,27,4],
[66,45,27,5],
[66,45,27,6],
[66,45,27,8],
[66,45,27,11],
[66,45,29,0],
[66,45,29,1],
[66,45,29,2],
[66,45,29,3],
[66,45,29,4],
[66,45,29,5],
[66,45,29,6],
[66,45,30,0],
[66,45,30,1],
[66,45,30,2],
[66,45,30,3],
[66,45,30,4],
[66,45,30,5],
[66,45,31,0],
[66,45,31,1],
[66,45,31,2],
[66,45,31,3],
[66,45,31,4],
[66,45,32,0],
[66,45,32,1],
[66,45,32,2],
[66,46,3,0],
[66,46,3,1],
[66,46,3,2],
[66,46,4,0],
[66,46,4,1],
[66,46,4,2],
[66,46,4,3],
[66,46,5,0],
[66,46,5,1],
[66,46,5,2],
[66,46,5,4],
[66,46,6,0],
[66,46,6,1],
[66,46,6,2],
[66,46,6,4],
[66,46,8,0],
[66,46,8,1],
[66,46,8,2],
[66,46,8,4],
[66,46,10,0],
[66,46,10,1],
[66,46,10,2],
[66,46,10,3],
[66,46,10,4],
[66,46,10,5],
[66,46,10,6],
[66,46,10,8],
[66,46,11,0],
[66,46,11,1],
[66,46,11,2],
[66,46,11,3],
[66,46,11,4],
[66,46,11,5],
[66,46,11,6],
[66,46,11,8],
[66,46,11,10],
[66,46,12,0],
[66,46,12,1],
[66,46,12,2],
[66,46,12,3],
[66,46,12,4],
[66,46,12,5],
[66,46,12,6],
[66,46,12,8],
[66,46,13,0],
[66,46,13,1],
[66,46,13,2],
[66,46,13,3],
[66,46,13,4],
[66,46,13,5],
[66,46,13,6],
[66,46,13,8],
[66,46,13,12],
[66,46,14,0],
[66,46,14,1],
[66,46,14,2],
[66,46,14,3],
[66,46,14,4],
[66,46,14,5],
[66,46,14,6],
[66,46,14,10],
[66,46,14,11],
[66,46,14,12],
[66,46,16,0],
[66,46,16,1],
[66,46,16,2],
[66,46,16,3],
[66,46,16,4],
[66,46,16,6],
[66,46,16,8],
[66,46,16,10],
[66,46,16,11],
[66,46,16,12],
[66,46,16,13],
[66,46,16,14],
[66,46,17,0],
[66,46,17,1],
[66,46,17,2],
[66,46,17,3],
[66,46,17,4],
[66,46,17,6],
[66,46,17,8],
[66,46,17,10],
[66,46,17,11],
[66,46,17,12],
[66,46,17,13],
[66,46,17,14],
[66,46,17,16],
[66,46,19,3],
[66,46,19,4],
[66,46,19,5],
[66,46,19,6],
[66,46,19,8],
[66,46,19,10],
[66,46,19,11],
[66,46,19,12],
[66,46,19,16],
[66,46,19,17],
[66,46,20,0],
[66,46,20,1],
[66,46,20,2],
[66,46,20,3],
[66,46,20,4],
[66,46,20,5],
[66,46,20,6],
[66,46,20,8],
[66,46,20,11],
[66,46,20,12],
[66,46,20,13],
[66,46,20,14],
[66,46,20,16],
[66,46,20,17],
[66,46,20,19],
[66,46,21,0],
[66,46,21,1],
[66,46,21,2],
[66,46,21,4],
[66,46,21,10],
[66,46,21,11],
[66,46,21,12],
[66,46,21,13],
[66,46,21,14],
[66,46,21,16],
[66,46,21,17],
[66,46,22,0],
[66,46,22,1],
[66,46,22,2],
[66,46,22,3],
[66,46,22,4],
[66,46,22,5],
[66,46,22,6],
[66,46,22,8],
[66,46,22,11],
[66,46,22,12],
[66,46,22,13],
[66,46,22,14],
[66,46,22,16],
[66,46,22,17],
[66,46,23,0],
[66,46,23,1],
[66,46,23,2],
[66,46,23,3],
[66,46,23,4],
[66,46,23,5],
[66,46,23,6],
[66,46,23,8],
[66,46,23,10],
[66,46,23,11],
[66,46,23,12],
[66,46,23,13],
[66,46,23,14],
[66,46,24,0],
[66,46,24,1],
[66,46,24,2],
[66,46,24,3],
[66,46,24,4],
[66,46,24,5],
[66,46,24,6],
[66,46,24,8],
[66,46,24,10],
[66,46,24,11],
[66,46,24,12],
[66,46,24,13],
[66,46,24,14],
[66,46,27,0],
[66,46,27,1],
[66,46,27,2],
[66,46,27,3],
[66,46,27,4],
[66,46,27,5],
[66,46,27,6],
[66,46,27,8],
[66,46,29,0],
[66,46,29,1],
[66,46,29,2],
[66,46,29,3],
[66,46,29,4],
[66,46,29,5],
[66,46,30,0],
[66,46,30,1],
[66,46,30,2],
[66,46,30,3],
[66,46,30,4],
[66,46,31,0],
[66,46,31,1],
[66,46,31,2],
[66,47,3,0],
[66,47,3,1],
[66,47,3,2],
[66,47,4,0],
[66,47,4,1],
[66,47,4,2],
[66,47,4,3],
[66,47,5,0],
[66,47,5,1],
[66,47,5,2],
[66,47,5,4],
[66,47,6,0],
[66,47,6,1],
[66,47,6,2],
[66,47,6,4],
[66,47,8,0],
[66,47,8,1],
[66,47,8,2],
[66,47,8,4],
[66,47,9,0],
[66,47,9,1],
[66,47,9,2],
[66,47,9,3],
[66,47,9,4],
[66,47,9,5],
[66,47,9,6],
[66,47,9,8],
[66,47,10,0],
[66,47,10,1],
[66,47,10,2],
[66,47,10,3],
[66,47,10,4],
[66,47,10,5],
[66,47,10,6],
[66,47,10,8],
[66,47,10,9],
[66,47,11,0],
[66,47,11,1],
[66,47,11,2],
[66,47,11,3],
[66,47,11,4],
[66,47,11,5],
[66,47,11,6],
[66,47,11,8],
[66,47,11,9],
[66,47,12,0],
[66,47,12,1],
[66,47,12,2],
[66,47,12,3],
[66,47,12,4],
[66,47,12,5],
[66,47,12,6],
[66,47,12,10],
[66,47,13,0],
[66,47,13,1],
[66,47,13,2],
[66,47,13,3],
[66,47,13,4],
[66,47,13,5],
[66,47,13,6],
[66,47,13,10],
[66,47,13,11],
[66,47,13,12],
[66,47,14,0],
[66,47,14,1],
[66,47,14,2],
[66,47,14,3],
[66,47,14,4],
[66,47,14,5],
[66,47,14,8],
[66,47,14,9],
[66,47,14,10],
[66,47,14,11],
[66,47,14,12],
[66,47,15,0],
[66,47,15,1],
[66,47,15,2],
[66,47,15,3],
[66,47,15,4],
[66,47,15,6],
[66,47,15,8],
[66,47,15,10],
[66,47,15,11],
[66,47,15,12],
[66,47,15,13],
[66,47,15,14],
[66,47,16,0],
[66,47,16,1],
[66,47,16,2],
[66,47,16,5],
[66,47,16,6],
[66,47,16,8],
[66,47,16,9],
[66,47,16,10],
[66,47,16,11],
[66,47,16,12],
[66,47,16,13],
[66,47,16,14],
[66,47,16,15],
[66,47,17,0],
[66,47,17,1],
[66,47,17,2],
[66,47,17,5],
[66,47,17,6],
[66,47,17,8],
[66,47,17,9],
[66,47,17,10],
[66,47,17,11],
[66,47,17,12],
[66,47,17,13],
[66,47,17,14],
[66,47,17,15],
[66,47,17,16],
[66,47,18,3],
[66,47,18,4],
[66,47,18,5],
[66,47,18,6],
[66,47,18,8],
[66,47,18,10],
[66,47,18,11],
[66,47,18,12],
[66,47,18,13],
[66,47,18,14],
[66,47,18,16],
[66,47,18,17],
[66,47,19,0],
[66,47,19,1],
[66,47,19,2],
[66,47,19,3],
[66,47,19,4],
[66,47,19,5],
[66,47,19,6],
[66,47,19,8],
[66,47,19,9],
[66,47,19,10],
[66,47,19,11],
[66,47,19,12],
[66,47,19,15],
[66,47,19,16],
[66,47,19,17],
[66,47,19,18],
[66,47,20,0],
[66,47,20,1],
[66,47,20,2],
[66,47,20,3],
[66,47,20,4],
[66,47,20,5],
[66,47,20,6],
[66,47,20,8],
[66,47,20,9],
[66,47,20,11],
[66,47,20,12],
[66,47,20,13],
[66,47,20,14],
[66,47,20,15],
[66,47,20,16],
[66,47,20,17],
[66,47,20,18],
[66,47,21,0],
[66,47,21,1],
[66,47,21,2],
[66,47,21,4],
[66,47,21,9],
[66,47,21,10],
[66,47,21,11],
[66,47,21,12],
[66,47,21,13],
[66,47,21,14],
[66,47,21,15],
[66,47,21,16],
[66,47,21,17],
[66,47,22,0],
[66,47,22,1],
[66,47,22,2],
[66,47,22,3],
[66,47,22,4],
[66,47,22,5],
[66,47,22,6],
[66,47,22,8],
[66,47,22,9],
[66,47,22,11],
[66,47,22,12],
[66,47,22,13],
[66,47,22,14],
[66,47,22,15],
[66,47,24,0],
[66,47,24,1],
[66,47,24,2],
[66,47,24,3],
[66,47,24,4],
[66,47,24,5],
[66,47,24,6],
[66,47,24,8],
[66,47,24,9],
[66,47,24,10],
[66,47,24,11],
[66,47,24,12],
[66,47,24,13],
[66,47,24,14],
[66,47,26,0],
[66,47,26,1],
[66,47,26,2],
[66,47,26,3],
[66,47,26,4],
[66,47,26,5],
[66,47,26,6],
[66,47,26,8],
[66,47,27,0],
[66,47,27,1],
[66,47,27,2],
[66,47,27,3],
[66,47,27,4],
[66,47,27,5],
[66,47,27,6],
[66,47,29,0],
[66,47,29,1],
[66,47,29,2],
[66,47,29,3],
[66,47,29,4],
[66,48,3,0],
[66,48,3,1],
[66,48,3,2],
[66,48,4,0],
[66,48,4,1],
[66,48,4,2],
[66,48,4,3],
[66,48,5,0],
[66,48,5,1],
[66,48,5,2],
[66,48,5,4],
[66,48,6,0],
[66,48,6,1],
[66,48,6,2],
[66,48,6,4],
[66,48,8,0],
[66,48,8,1],
[66,48,8,2],
[66,48,8,4],
[66,48,9,0],
[66,48,9,1],
[66,48,9,2],
[66,48,9,3],
[66,48,9,4],
[66,48,9,5],
[66,48,9,6],
[66,48,9,8],
[66,48,10,0],
[66,48,10,1],
[66,48,10,2],
[66,48,10,3],
[66,48,10,4],
[66,48,10,5],
[66,48,10,6],
[66,48,13,0],
[66,48,13,1],
[66,48,13,2],
[66,48,13,3],
[66,48,13,4],
[66,48,13,5],
[66,48,13,8],
[66,48,13,9],
[66,48,13,10],
[66,48,14,0],
[66,48,14,1],
[66,48,14,2],
[66,48,14,3],
[66,48,14,4],
[66,48,14,6],
[66,48,14,8],
[66,48,14,9],
[66,48,14,10],
[66,48,15,0],
[66,48,15,1],
[66,48,15,2],
[66,48,15,5],
[66,48,15,6],
[66,48,15,8],
[66,48,15,10],
[66,48,15,13],
[66,48,15,14],
[66,48,16,3],
[66,48,16,4],
[66,48,16,5],
[66,48,16,6],
[66,48,16,8],
[66,48,16,9],
[66,48,16,10],
[66,48,16,13],
[66,48,16,14],
[66,48,16,15],
[66,48,17,3],
[66,48,17,4],
[66,48,17,5],
[66,48,17,6],
[66,48,17,8],
[66,48,17,9],
[66,48,17,10],
[66,48,17,13],
[66,48,17,14],
[66,48,17,15],
[66,48,17,16],
[66,48,18,0],
[66,48,18,1],
[66,48,18,2],
[66,48,18,3],
[66,48,18,4],
[66,48,18,5],
[66,48,18,6],
[66,48,18,8],
[66,48,18,10],
[66,48,18,13],
[66,48,18,14],
[66,48,18,16],
[66,48,18,17],
[66,48,19,0],
[66,48,19,1],
[66,48,19,2],
[66,48,19,3],
[66,48,19,4],
[66,48,19,5],
[66,48,19,6],
[66,48,19,8],
[66,48,19,9],
[66,48,19,10],
[66,48,19,15],
[66,48,19,16],
[66,48,19,17],
[66,48,19,18],
[66,48,20,0],
[66,48,20,1],
[66,48,20,2],
[66,48,20,3],
[66,48,20,4],
[66,48,20,5],
[66,48,20,6],
[66,48,20,8],
[66,48,20,9],
[66,48,20,13],
[66,48,20,14],
[66,48,20,15],
[66,48,20,16],
[66,48,20,17],
[66,48,21,0],
[66,48,21,1],
[66,48,21,2],
[66,48,21,4],
[66,48,21,9],
[66,48,21,10],
[66,48,21,13],
[66,48,21,14],
[66,48,21,15],
[66,48,22,0],
[66,48,22,1],
[66,48,22,2],
[66,48,22,3],
[66,48,22,4],
[66,48,22,5],
[66,48,22,6],
[66,48,22,8],
[66,48,22,9],
[66,48,22,13],
[66,48,22,14],
[66,48,23,0],
[66,48,23,1],
[66,48,23,2],
[66,48,23,3],
[66,48,23,4],
[66,48,23,5],
[66,48,23,6],
[66,48,23,8],
[66,48,23,9],
[66,48,23,10],
[66,48,23,13],
[66,48,24,0],
[66,48,24,1],
[66,48,24,2],
[66,48,24,3],
[66,48,24,4],
[66,48,24,5],
[66,48,24,6],
[66,48,24,8],
[66,48,24,9],
[66,48,24,10],
[66,48,24,13],
[66,48,26,0],
[66,48,26,1],
[66,48,26,2],
[66,48,26,3],
[66,48,26,4],
[66,48,26,5],
[66,48,26,6],
[66,48,27,0],
[66,48,27,1],
[66,48,27,2],
[66,48,27,3],
[66,48,27,4],
[66,48,27,5],
[66,49,3,0],
[66,49,3,1],
[66,49,3,2],
[66,49,4,0],
[66,49,4,1],
[66,49,4,2],
[66,49,4,3],
[66,49,5,0],
[66,49,5,1],
[66,49,5,2],
[66,49,5,4],
[66,49,6,0],
[66,49,6,1],
[66,49,6,2],
[66,49,6,4],
[66,49,8,0],
[66,49,8,1],
[66,49,8,2],
[66,49,8,4],
[66,49,10,0],
[66,49,10,1],
[66,49,10,2],
[66,49,10,3],
[66,49,10,4],
[66,49,10,5],
[66,49,10,6],
[66,49,11,0],
[66,49,11,1],
[66,49,11,2],
[66,49,11,3],
[66,49,11,4],
[66,49,11,5],
[66,49,11,6],
[66,49,11,10],
[66,49,12,0],
[66,49,12,1],
[66,49,12,2],
[66,49,12,3],
[66,49,12,4],
[66,49,12,5],
[66,49,12,8],
[66,49,12,10],
[66,49,13,0],
[66,49,13,1],
[66,49,13,2],
[66,49,13,3],
[66,49,13,4],
[66,49,13,5],
[66,49,13,8],
[66,49,13,10],
[66,49,13,11],
[66,49,13,12],
[66,49,14,0],
[66,49,14,1],
[66,49,14,2],
[66,49,14,3],
[66,49,14,4],
[66,49,14,6],
[66,49,14,8],
[66,49,14,10],
[66,49,14,11],
[66,49,14,12],
[66,49,16,3],
[66,49,16,4],
[66,49,16,5],
[66,49,16,6],
[66,49,16,8],
[66,49,16,10],
[66,49,16,11],
[66,49,16,12],
[66,49,16,13],
[66,49,16,14],
[66,49,17,3],
[66,49,17,4],
[66,49,17,5],
[66,49,17,6],
[66,49,17,8],
[66,49,17,10],
[66,49,17,11],
[66,49,17,12],
[66,49,17,13],
[66,49,17,14],
[66,49,17,16],
[66,49,19,0],
[66,49,19,1],
[66,49,19,2],
[66,49,19,3],
[66,49,19,4],
[66,49,19,5],
[66,49,19,6],
[66,49,19,8],
[66,49,19,10],
[66,49,19,11],
[66,49,19,12],
[66,49,19,16],
[66,49,19,17],
[66,49,20,0],
[66,49,20,1],
[66,49,20,2],
[66,49,20,3],
[66,49,20,4],
[66,49,20,5],
[66,49,20,6],
[66,49,20,8],
[66,49,20,11],
[66,49,20,12],
[66,49,20,13],
[66,49,20,14],
[66,49,20,16],
[66,49,20,17],
[66,49,21,0],
[66,49,21,1],
[66,49,21,2],
[66,49,21,4],
[66,49,21,10],
[66,49,21,11],
[66,49,21,12],
[66,49,21,13],
[66,49,21,14],
[66,49,22,0],
[66,49,22,1],
[66,49,22,2],
[66,49,22,3],
[66,49,22,4],
[66,49,22,5],
[66,49,22,6],
[66,49,22,8],
[66,49,22,11],
[66,49,22,12],
[66,49,22,13],
[66,49,22,14],
[66,49,23,0],
[66,49,23,1],
[66,49,23,2],
[66,49,23,3],
[66,49,23,4],
[66,49,23,5],
[66,49,23,6],
[66,49,23,8],
[66,49,23,10],
[66,49,23,11],
[66,49,23,12],
[66,49,23,13],
[66,49,24,0],
[66,49,24,1],
[66,49,24,2],
[66,49,24,3],
[66,49,24,4],
[66,49,24,5],
[66,49,24,6],
[66,49,24,8],
[66,49,24,10],
[66,49,24,11],
[66,49,24,12],
[66,49,24,13],
[66,49,27,0],
[66,49,27,1],
[66,49,27,2],
[66,49,27,3],
[66,49,27,4],
[66,49,27,5],
[66,49,29,0],
[66,49,29,1],
[66,49,29,2],
[66,50,3,0],
[66,50,3,1],
[66,50,3,2],
[66,50,4,0],
[66,50,4,1],
[66,50,4,2],
[66,50,4,3],
[66,50,5,0],
[66,50,5,1],
[66,50,5,2],
[66,50,5,4],
[66,50,6,0],
[66,50,6,1],
[66,50,6,2],
[66,50,6,4],
[66,50,8,0],
[66,50,8,1],
[66,50,8,2],
[66,50,8,4],
[66,50,10,0],
[66,50,10,1],
[66,50,10,2],
[66,50,10,3],
[66,50,10,4],
[66,50,10,5],
[66,50,10,6],
[66,50,11,0],
[66,50,11,1],
[66,50,11,2],
[66,50,11,3],
[66,50,11,4],
[66,50,11,5],
[66,50,11,6],
[66,50,11,10],
[66,50,12,0],
[66,50,12,1],
[66,50,12,2],
[66,50,12,3],
[66,50,12,4],
[66,50,12,5],
[66,50,12,8],
[66,50,12,10],
[66,50,13,0],
[66,50,13,1],
[66,50,13,2],
[66,50,13,3],
[66,50,13,4],
[66,50,13,5],
[66,50,13,8],
[66,50,13,10],
[66,50,13,11],
[66,50,13,12],
[66,50,14,0],
[66,50,14,1],
[66,50,14,2],
[66,50,14,3],
[66,50,14,4],
[66,50,14,6],
[66,50,14,8],
[66,50,14,10],
[66,50,14,11],
[66,50,14,12],
[66,50,16,3],
[66,50,16,4],
[66,50,16,5],
[66,50,16,6],
[66,50,16,8],
[66,50,16,10],
[66,50,16,11],
[66,50,16,12],
[66,50,16,13],
[66,50,16,14],
[66,50,17,3],
[66,50,17,4],
[66,50,17,5],
[66,50,17,6],
[66,50,17,8],
[66,50,17,10],
[66,50,17,11],
[66,50,17,12],
[66,50,17,13],
[66,50,17,14],
[66,50,17,16],
[66,50,19,0],
[66,50,19,1],
[66,50,19,2],
[66,50,19,3],
[66,50,19,4],
[66,50,19,5],
[66,50,19,6],
[66,50,19,8],
[66,50,19,10],
[66,50,19,11],
[66,50,19,12],
[66,50,19,16],
[66,50,19,17],
[66,50,20,0],
[66,50,20,1],
[66,50,20,2],
[66,50,20,3],
[66,50,20,4],
[66,50,20,5],
[66,50,20,6],
[66,50,20,8],
[66,50,20,11],
[66,50,20,12],
[66,50,20,13],
[66,50,20,14],
[66,50,20,16],
[66,50,20,17],
[66,50,21,0],
[66,50,21,1],
[66,50,21,2],
[66,50,21,4],
[66,50,21,10],
[66,50,21,11],
[66,50,21,12],
[66,50,21,13],
[66,50,21,14],
[66,50,22,0],
[66,50,22,1],
[66,50,22,2],
[66,50,22,3],
[66,50,22,4],
[66,50,22,5],
[66,50,22,6],
[66,50,22,8],
[66,50,22,11],
[66,50,22,12],
[66,50,22,13],
[66,50,22,14],
[66,50,23,0],
[66,50,23,1],
[66,50,23,2],
[66,50,23,3],
[66,50,23,4],
[66,50,23,5],
[66,50,23,6],
[66,50,23,8],
[66,50,23,10],
[66,50,23,11],
[66,50,23,12],
[66,50,23,13],
[66,50,24,0],
[66,50,24,1],
[66,50,24,2],
[66,50,24,3],
[66,50,24,4],
[66,50,24,5],
[66,50,24,6],
[66,50,24,8],
[66,50,24,10],
[66,50,24,11],
[66,50,24,12],
[66,50,24,13],
[66,50,27,0],
[66,50,27,1],
[66,50,27,2],
[66,50,27,3],
[66,50,27,4],
[66,50,27,5],
[66,50,29,0],
[66,50,29,1],
[66,50,29,2],
[66,51,4,0],
[66,51,4,1],
[66,51,4,2],
[66,51,9,0],
[66,51,9,1],
[66,51,9,2],
[66,51,9,4],
[66,51,10,0],
[66,51,10,1],
[66,51,10,2],
[66,51,10,4],
[66,51,11,0],
[66,51,11,1],
[66,51,11,2],
[66,51,11,4],
[66,51,11,10],
[66,51,12,0],
[66,51,12,1],
[66,51,12,2],
[66,51,12,4],
[66,51,12,9],
[66,51,12,10],
[66,51,13,0],
[66,51,13,1],
[66,51,13,2],
[66,51,13,4],
[66,51,13,9],
[66,51,13,10],
[66,51,13,11],
[66,51,13,12],
[66,51,14,0],
[66,51,14,1],
[66,51,14,2],
[66,51,14,4],
[66,51,14,9],
[66,51,14,10],
[66,51,14,11],
[66,51,14,12],
[66,51,15,0],
[66,51,15,1],
[66,51,15,2],
[66,51,15,10],
[66,51,15,11],
[66,51,15,12],
[66,51,15,13],
[66,51,15,14],
[66,51,16,4],
[66,51,16,9],
[66,51,16,10],
[66,51,16,11],
[66,51,16,12],
[66,51,16,13],
[66,51,16,14],
[66,51,16,15],
[66,51,17,4],
[66,51,17,9],
[66,51,17,10],
[66,51,17,11],
[66,51,17,12],
[66,51,17,13],
[66,51,17,14],
[66,51,17,15],
[66,51,17,16],
[66,51,18,0],
[66,51,18,1],
[66,51,18,2],
[66,51,18,4],
[66,51,18,10],
[66,51,18,11],
[66,51,18,12],
[66,51,18,13],
[66,51,18,14],
[66,51,18,16],
[66,51,18,17],
[66,51,19,0],
[66,51,19,1],
[66,51,19,2],
[66,51,19,4],
[66,51,19,9],
[66,51,19,10],
[66,51,19,11],
[66,51,19,12],
[66,51,19,15],
[66,51,19,16],
[66,51,19,17],
[66,51,19,18],
[66,51,20,0],
[66,51,20,1],
[66,51,20,2],
[66,51,20,4],
[66,51,20,9],
[66,51,20,11],
[66,51,20,12],
[66,51,20,13],
[66,51,20,14],
[66,51,20,15],
[66,51,20,16],
[66,51,20,17],
[66,51,22,0],
[66,51,22,1],
[66,51,22,2],
[66,51,22,4],
[66,51,22,9],
[66,51,22,11],
[66,51,22,12],
[66,51,22,13],
[66,51,22,14],
[66,51,23,0],
[66,51,23,1],
[66,51,23,2],
[66,51,23,4],
[66,51,23,9],
[66,51,23,10],
[66,51,23,11],
[66,51,23,12],
[66,51,23,13],
[66,51,24,0],
[66,51,24,1],
[66,51,24,2],
[66,51,24,4],
[66,51,24,9],
[66,51,24,10],
[66,51,24,11],
[66,51,24,12],
[66,51,24,13],
[66,51,26,0],
[66,51,26,1],
[66,51,26,2],
[66,51,26,4],
[66,51,27,0],
[66,51,27,1],
[66,51,27,2],
[66,51,27,4],
[66,51,29,0],
[66,51,29,1],
[66,51,29,2],
[66,52,3,0],
[66,52,3,1],
[66,52,3,2],
[66,52,4,0],
[66,52,4,1],
[66,52,4,2],
[66,52,4,3],
[66,52,5,0],
[66,52,5,1],
[66,52,5,2],
[66,52,5,4],
[66,52,6,0],
[66,52,6,1],
[66,52,6,2],
[66,52,6,4],
[66,52,8,0],
[66,52,8,1],
[66,52,8,2],
[66,52,8,4],
[66,52,10,0],
[66,52,10,1],
[66,52,10,2],
[66,52,10,3],
[66,52,10,4],
[66,52,10,5],
[66,52,10,6],
[66,52,11,0],
[66,52,11,1],
[66,52,11,2],
[66,52,11,3],
[66,52,11,4],
[66,52,11,5],
[66,52,11,6],
[66,52,11,10],
[66,52,12,0],
[66,52,12,1],
[66,52,12,2],
[66,52,12,3],
[66,52,12,4],
[66,52,12,5],
[66,52,12,8],
[66,52,12,10],
[66,52,13,0],
[66,52,13,1],
[66,52,13,2],
[66,52,13,3],
[66,52,13,4],
[66,52,13,5],
[66,52,13,8],
[66,52,13,10],
[66,52,13,11],
[66,52,13,12],
[66,52,14,0],
[66,52,14,1],
[66,52,14,2],
[66,52,14,3],
[66,52,14,4],
[66,52,14,6],
[66,52,14,8],
[66,52,14,10],
[66,52,14,11],
[66,52,14,12],
[66,52,16,3],
[66,52,16,4],
[66,52,16,5],
[66,52,16,6],
[66,52,16,8],
[66,52,16,10],
[66,52,16,11],
[66,52,16,12],
[66,52,16,13],
[66,52,16,14],
[66,52,17,3],
[66,52,17,4],
[66,52,17,5],
[66,52,17,6],
[66,52,17,8],
[66,52,17,10],
[66,52,17,11],
[66,52,17,12],
[66,52,17,13],
[66,52,17,14],
[66,52,17,16],
[66,52,19,0],
[66,52,19,1],
[66,52,19,2],
[66,52,19,3],
[66,52,19,4],
[66,52,19,5],
[66,52,19,6],
[66,52,19,8],
[66,52,19,10],
[66,52,19,11],
[66,52,19,12],
[66,52,19,16],
[66,52,19,17],
[66,52,20,0],
[66,52,20,1],
[66,52,20,2],
[66,52,20,3],
[66,52,20,4],
[66,52,20,5],
[66,52,20,6],
[66,52,20,8],
[66,52,20,11],
[66,52,20,12],
[66,52,20,13],
[66,52,20,14],
[66,52,20,16],
[66,52,20,17],
[66,52,21,0],
[66,52,21,1],
[66,52,21,2],
[66,52,21,4],
[66,52,21,10],
[66,52,21,11],
[66,52,21,12],
[66,52,21,13],
[66,52,21,14],
[66,52,22,0],
[66,52,22,1],
[66,52,22,2],
[66,52,22,3],
[66,52,22,4],
[66,52,22,5],
[66,52,22,6],
[66,52,22,8],
[66,52,22,11],
[66,52,22,12],
[66,52,22,13],
[66,52,22,14],
[66,52,23,0],
[66,52,23,1],
[66,52,23,2],
[66,52,23,3],
[66,52,23,4],
[66,52,23,5],
[66,52,23,6],
[66,52,23,8],
[66,52,23,10],
[66,52,23,11],
[66,52,23,12],
[66,52,23,13],
[66,52,24,0],
[66,52,24,1],
[66,52,24,2],
[66,52,24,3],
[66,52,24,4],
[66,52,24,5],
[66,52,24,6],
[66,52,24,8],
[66,52,24,10],
[66,52,24,11],
[66,52,24,12],
[66,52,24,13],
[66,52,27,0],
[66,52,27,1],
[66,52,27,2],
[66,52,27,3],
[66,52,27,4],
[66,52,27,5],
[66,52,29,0],
[66,52,29,1],
[66,52,29,2],
[66,53,3,0],
[66,53,3,1],
[66,53,3,2],
[66,53,4,0],
[66,53,4,1],
[66,53,4,2],
[66,53,4,3],
[66,53,5,0],
[66,53,5,1],
[66,53,5,2],
[66,53,5,4],
[66,53,6,0],
[66,53,6,1],
[66,53,6,2],
[66,53,6,4],
[66,53,8,0],
[66,53,8,1],
[66,53,8,2],
[66,53,8,4],
[66,53,9,0],
[66,53,9,1],
[66,53,9,2],
[66,53,9,3],
[66,53,9,4],
[66,53,9,5],
[66,53,9,6],
[66,53,9,8],
[66,53,11,0],
[66,53,11,1],
[66,53,11,2],
[66,53,11,3],
[66,53,11,4],
[66,53,11,5],
[66,53,11,6],
[66,53,12,0],
[66,53,12,1],
[66,53,12,2],
[66,53,12,3],
[66,53,12,4],
[66,53,12,5],
[66,53,12,8],
[66,53,12,9],
[66,53,13,0],
[66,53,13,1],
[66,53,13,2],
[66,53,13,3],
[66,53,13,4],
[66,53,13,5],
[66,53,13,8],
[66,53,13,9],
[66,53,13,11],
[66,53,13,12],
[66,53,14,0],
[66,53,14,1],
[66,53,14,2],
[66,53,14,3],
[66,53,14,4],
[66,53,14,6],
[66,53,14,8],
[66,53,14,9],
[66,53,14,11],
[66,53,14,12],
[66,53,15,0],
[66,53,15,1],
[66,53,15,2],
[66,53,15,5],
[66,53,15,6],
[66,53,15,8],
[66,53,15,11],
[66,53,15,12],
[66,53,15,13],
[66,53,15,14],
[66,53,16,3],
[66,53,16,4],
[66,53,16,5],
[66,53,16,6],
[66,53,16,8],
[66,53,16,9],
[66,53,16,11],
[66,53,16,12],
[66,53,16,13],
[66,53,16,14],
[66,53,16,15],
[66,53,17,3],
[66,53,17,4],
[66,53,17,5],
[66,53,17,6],
[66,53,17,8],
[66,53,17,9],
[66,53,17,11],
[66,53,17,12],
[66,53,17,13],
[66,53,17,14],
[66,53,17,15],
[66,53,17,16],
[66,53,18,0],
[66,53,18,1],
[66,53,18,2],
[66,53,18,3],
[66,53,18,4],
[66,53,18,5],
[66,53,18,6],
[66,53,18,8],
[66,53,18,11],
[66,53,18,12],
[66,53,18,13],
[66,53,18,14],
[66,53,18,16],
[66,53,18,17],
[66,53,19,0],
[66,53,19,1],
[66,53,19,2],
[66,53,19,3],
[66,53,19,4],
[66,53,19,5],
[66,53,19,6],
[66,53,19,8],
[66,53,19,9],
[66,53,19,11],
[66,53,19,12],
[66,53,19,15],
[66,53,19,16],
[66,53,19,17],
[66,53,19,18],
[66,53,21,0],
[66,53,21,1],
[66,53,21,2],
[66,53,21,4],
[66,53,21,9],
[66,53,21,11],
[66,53,21,12],
[66,53,21,13],
[66,53,21,14],
[66,53,21,15],
[66,53,23,0],
[66,53,23,1],
[66,53,23,2],
[66,53,23,3],
[66,53,23,4],
[66,53,23,5],
[66,53,23,6],
[66,53,23,8],
[66,53,23,9],
[66,53,23,11],
[66,53,23,12],
[66,53,23,13],
[66,53,24,0],
[66,53,24,1],
[66,53,24,2],
[66,53,24,3],
[66,53,24,4],
[66,53,24,5],
[66,53,24,6],
[66,53,24,8],
[66,53,24,9],
[66,53,24,11],
[66,53,24,12],
[66,53,24,13],
[66,53,26,0],
[66,53,26,1],
[66,53,26,2],
[66,53,26,3],
[66,53,26,4],
[66,53,26,5],
[66,53,26,6],
[66,53,29,0],
[66,53,29,1],
[66,53,29,2],
[66,54,3,0],
[66,54,3,1],
[66,54,3,2],
[66,54,4,0],
[66,54,4,1],
[66,54,4,2],
[66,54,4,3],
[66,54,5,0],
[66,54,5,1],
[66,54,5,2],
[66,54,5,4],
[66,54,6,0],
[66,54,6,1],
[66,54,6,2],
[66,54,6,4],
[66,54,8,0],
[66,54,8,1],
[66,54,8,2],
[66,54,8,4],
[66,54,9,0],
[66,54,9,1],
[66,54,9,2],
[66,54,9,3],
[66,54,9,4],
[66,54,9,5],
[66,54,9,6],
[66,54,9,8],
[66,54,11,0],
[66,54,11,1],
[66,54,11,2],
[66,54,11,3],
[66,54,11,4],
[66,54,11,5],
[66,54,11,6],
[66,54,12,0],
[66,54,12,1],
[66,54,12,2],
[66,54,12,3],
[66,54,12,4],
[66,54,12,5],
[66,54,12,8],
[66,54,12,9],
[66,54,13,0],
[66,54,13,1],
[66,54,13,2],
[66,54,13,3],
[66,54,13,4],
[66,54,13,5],
[66,54,13,8],
[66,54,13,9],
[66,54,13,11],
[66,54,13,12],
[66,54,14,0],
[66,54,14,1],
[66,54,14,2],
[66,54,14,3],
[66,54,14,4],
[66,54,14,6],
[66,54,14,8],
[66,54,14,9],
[66,54,14,11],
[66,54,14,12],
[66,54,15,0],
[66,54,15,1],
[66,54,15,2],
[66,54,15,5],
[66,54,15,6],
[66,54,15,8],
[66,54,15,11],
[66,54,15,12],
[66,54,15,13],
[66,54,15,14],
[66,54,16,3],
[66,54,16,4],
[66,54,16,5],
[66,54,16,6],
[66,54,16,8],
[66,54,16,9],
[66,54,16,11],
[66,54,16,12],
[66,54,16,13],
[66,54,16,14],
[66,54,16,15],
[66,54,17,3],
[66,54,17,4],
[66,54,17,5],
[66,54,17,6],
[66,54,17,8],
[66,54,17,9],
[66,54,17,11],
[66,54,17,12],
[66,54,17,13],
[66,54,17,14],
[66,54,17,15],
[66,54,17,16],
[66,54,18,0],
[66,54,18,1],
[66,54,18,2],
[66,54,18,3],
[66,54,18,4],
[66,54,18,5],
[66,54,18,6],
[66,54,18,8],
[66,54,18,11],
[66,54,18,12],
[66,54,18,13],
[66,54,18,14],
[66,54,18,16],
[66,54,18,17],
[66,54,19,0],
[66,54,19,1],
[66,54,19,2],
[66,54,19,3],
[66,54,19,4],
[66,54,19,5],
[66,54,19,6],
[66,54,19,8],
[66,54,19,9],
[66,54,19,11],
[66,54,19,12],
[66,54,19,15],
[66,54,19,16],
[66,54,19,17],
[66,54,19,18],
[66,54,21,0],
[66,54,21,1],
[66,54,21,2],
[66,54,21,4],
[66,54,21,9],
[66,54,21,11],
[66,54,21,12],
[66,54,21,13],
[66,54,21,14],
[66,54,21,15],
[66,54,23,0],
[66,54,23,1],
[66,54,23,2],
[66,54,23,3],
[66,54,23,4],
[66,54,23,5],
[66,54,23,6],
[66,54,23,8],
[66,54,23,9],
[66,54,23,11],
[66,54,23,12],
[66,54,23,13],
[66,54,24,0],
[66,54,24,1],
[66,54,24,2],
[66,54,24,3],
[66,54,24,4],
[66,54,24,5],
[66,54,24,6],
[66,54,24,8],
[66,54,24,9],
[66,54,24,11],
[66,54,24,12],
[66,54,24,13],
[66,54,26,0],
[66,54,26,1],
[66,54,26,2],
[66,54,26,3],
[66,54,26,4],
[66,54,26,5],
[66,54,26,6],
[66,54,29,0],
[66,54,29,1],
[66,54,29,2],
[66,55,3,0],
[66,55,3,1],
[66,55,3,2],
[66,55,4,0],
[66,55,4,1],
[66,55,4,2],
[66,55,4,3],
[66,55,5,0],
[66,55,5,1],
[66,55,5,2],
[66,55,5,4],
[66,55,6,0],
[66,55,6,1],
[66,55,6,2],
[66,55,6,4],
[66,55,8,0],
[66,55,8,1],
[66,55,8,2],
[66,55,8,4],
[66,55,9,0],
[66,55,9,1],
[66,55,9,2],
[66,55,9,3],
[66,55,9,4],
[66,55,9,5],
[66,55,9,6],
[66,55,9,8],
[66,55,11,0],
[66,55,11,1],
[66,55,11,2],
[66,55,11,3],
[66,55,11,4],
[66,55,11,5],
[66,55,11,6],
[66,55,12,0],
[66,55,12,1],
[66,55,12,2],
[66,55,12,3],
[66,55,12,4],
[66,55,12,5],
[66,55,12,8],
[66,55,12,9],
[66,55,13,0],
[66,55,13,1],
[66,55,13,2],
[66,55,13,3],
[66,55,13,4],
[66,55,13,5],
[66,55,13,8],
[66,55,13,9],
[66,55,13,11],
[66,55,13,12],
[66,55,14,0],
[66,55,14,1],
[66,55,14,2],
[66,55,14,3],
[66,55,14,4],
[66,55,14,6],
[66,55,14,8],
[66,55,14,9],
[66,55,14,11],
[66,55,14,12],
[66,55,15,0],
[66,55,15,1],
[66,55,15,2],
[66,55,15,5],
[66,55,15,6],
[66,55,15,8],
[66,55,15,11],
[66,55,15,12],
[66,55,15,13],
[66,55,15,14],
[66,55,16,3],
[66,55,16,4],
[66,55,16,5],
[66,55,16,6],
[66,55,16,8],
[66,55,16,9],
[66,55,16,11],
[66,55,16,12],
[66,55,16,13],
[66,55,16,14],
[66,55,16,15],
[66,55,17,3],
[66,55,17,4],
[66,55,17,5],
[66,55,17,6],
[66,55,17,8],
[66,55,17,9],
[66,55,17,11],
[66,55,17,12],
[66,55,17,13],
[66,55,17,14],
[66,55,17,15],
[66,55,17,16],
[66,55,18,0],
[66,55,18,1],
[66,55,18,2],
[66,55,18,3],
[66,55,18,4],
[66,55,18,5],
[66,55,18,6],
[66,55,18,8],
[66,55,18,11],
[66,55,18,12],
[66,55,18,13],
[66,55,18,14],
[66,55,18,16],
[66,55,18,17],
[66,55,19,0],
[66,55,19,1],
[66,55,19,2],
[66,55,19,3],
[66,55,19,4],
[66,55,19,5],
[66,55,19,6],
[66,55,19,8],
[66,55,19,9],
[66,55,19,11],
[66,55,19,12],
[66,55,19,15],
[66,55,19,16],
[66,55,19,17],
[66,55,19,18],
[66,55,21,0],
[66,55,21,1],
[66,55,21,2],
[66,55,21,4],
[66,55,21,9],
[66,55,21,11],
[66,55,21,12],
[66,55,21,13],
[66,55,21,14],
[66,55,21,15],
[66,55,23,0],
[66,55,23,1],
[66,55,23,2],
[66,55,23,3],
[66,55,23,4],
[66,55,23,5],
[66,55,23,6],
[66,55,23,8],
[66,55,23,9],
[66,55,23,11],
[66,55,23,12],
[66,55,23,13],
[66,55,24,0],
[66,55,24,1],
[66,55,24,2],
[66,55,24,3],
[66,55,24,4],
[66,55,24,5],
[66,55,24,6],
[66,55,24,8],
[66,55,24,9],
[66,55,24,11],
[66,55,24,12],
[66,55,24,13],
[66,55,26,0],
[66,55,26,1],
[66,55,26,2],
[66,55,26,3],
[66,55,26,4],
[66,55,26,5],
[66,55,26,6],
[66,55,29,0],
[66,55,29,1],
[66,55,29,2],
[66,56,3,0],
[66,56,3,1],
[66,56,3,2],
[66,56,4,0],
[66,56,4,1],
[66,56,4,2],
[66,56,4,3],
[66,56,5,0],
[66,56,5,1],
[66,56,5,2],
[66,56,5,4],
[66,56,6,0],
[66,56,6,1],
[66,56,6,2],
[66,56,6,4],
[66,56,8,0],
[66,56,8,1],
[66,56,8,2],
[66,56,8,4],
[66,56,10,0],
[66,56,10,1],
[66,56,10,2],
[66,56,10,3],
[66,56,10,4],
[66,56,10,5],
[66,56,10,6],
[66,56,11,0],
[66,56,11,1],
[66,56,11,2],
[66,56,11,3],
[66,56,11,4],
[66,56,11,5],
[66,56,11,6],
[66,56,11,10],
[66,56,12,0],
[66,56,12,1],
[66,56,12,2],
[66,56,12,3],
[66,56,12,4],
[66,56,12,5],
[66,56,12,8],
[66,56,12,10],
[66,56,13,0],
[66,56,13,1],
[66,56,13,2],
[66,56,13,3],
[66,56,13,4],
[66,56,13,5],
[66,56,13,8],
[66,56,13,10],
[66,56,13,11],
[66,56,13,12],
[66,56,14,0],
[66,56,14,1],
[66,56,14,2],
[66,56,14,3],
[66,56,14,4],
[66,56,14,6],
[66,56,14,8],
[66,56,14,10],
[66,56,14,11],
[66,56,14,12],
[66,56,16,3],
[66,56,16,4],
[66,56,16,5],
[66,56,16,6],
[66,56,16,8],
[66,56,16,10],
[66,56,16,11],
[66,56,16,12],
[66,56,16,13],
[66,56,16,14],
[66,56,17,3],
[66,56,17,4],
[66,56,17,5],
[66,56,17,6],
[66,56,17,8],
[66,56,17,10],
[66,56,17,11],
[66,56,17,12],
[66,56,17,13],
[66,56,17,14],
[66,56,17,16],
[66,56,19,0],
[66,56,19,1],
[66,56,19,2],
[66,56,19,3],
[66,56,19,4],
[66,56,19,5],
[66,56,19,6],
[66,56,19,8],
[66,56,19,10],
[66,56,19,11],
[66,56,19,12],
[66,56,19,16],
[66,56,19,17],
[66,56,20,0],
[66,56,20,1],
[66,56,20,2],
[66,56,20,3],
[66,56,20,4],
[66,56,20,5],
[66,56,20,6],
[66,56,20,8],
[66,56,20,11],
[66,56,20,12],
[66,56,20,13],
[66,56,20,14],
[66,56,20,16],
[66,56,20,17],
[66,56,21,0],
[66,56,21,1],
[66,56,21,2],
[66,56,21,4],
[66,56,21,10],
[66,56,21,11],
[66,56,21,12],
[66,56,21,13],
[66,56,21,14],
[66,56,22,0],
[66,56,22,1],
[66,56,22,2],
[66,56,22,3],
[66,56,22,4],
[66,56,22,5],
[66,56,22,6],
[66,56,22,8],
[66,56,22,11],
[66,56,22,12],
[66,56,22,13],
[66,56,22,14],
[66,56,23,0],
[66,56,23,1],
[66,56,23,2],
[66,56,23,3],
[66,56,23,4],
[66,56,23,5],
[66,56,23,6],
[66,56,23,8],
[66,56,23,10],
[66,56,23,11],
[66,56,23,12],
[66,56,23,13],
[66,56,24,0],
[66,56,24,1],
[66,56,24,2],
[66,56,24,3],
[66,56,24,4],
[66,56,24,5],
[66,56,24,6],
[66,56,24,8],
[66,56,24,10],
[66,56,24,11],
[66,56,24,12],
[66,56,24,13],
[66,56,27,0],
[66,56,27,1],
[66,56,27,2],
[66,56,27,3],
[66,56,27,4],
[66,56,27,5],
[66,56,29,0],
[66,56,29,1],
[66,56,29,2],
[66,57,3,0],
[66,57,3,1],
[66,57,3,2],
[66,57,4,0],
[66,57,4,1],
[66,57,4,2],
[66,57,4,3],
[66,57,5,0],
[66,57,5,1],
[66,57,5,2],
[66,57,5,4],
[66,57,6,0],
[66,57,6,1],
[66,57,6,2],
[66,57,6,4],
[66,57,8,0],
[66,57,8,1],
[66,57,8,2],
[66,57,8,4],
[66,57,9,0],
[66,57,9,1],
[66,57,9,2],
[66,57,9,3],
[66,57,9,4],
[66,57,9,5],
[66,57,9,6],
[66,57,10,0],
[66,57,10,1],
[66,57,10,2],
[66,57,10,3],
[66,57,10,4],
[66,57,10,5],
[66,57,10,8],
[66,57,10,9],
[66,57,11,0],
[66,57,11,1],
[66,57,11,2],
[66,57,11,3],
[66,57,11,4],
[66,57,11,5],
[66,57,11,8],
[66,57,11,9],
[66,57,11,10],
[66,57,12,0],
[66,57,12,1],
[66,57,12,2],
[66,57,12,3],
[66,57,12,4],
[66,57,12,6],
[66,57,12,8],
[66,57,12,9],
[66,57,12,10],
[66,57,13,0],
[66,57,13,1],
[66,57,13,2],
[66,57,13,3],
[66,57,13,4],
[66,57,13,6],
[66,57,13,8],
[66,57,13,9],
[66,57,13,10],
[66,57,13,11],
[66,57,13,12],
[66,57,14,0],
[66,57,14,1],
[66,57,14,2],
[66,57,14,5],
[66,57,14,6],
[66,57,14,8],
[66,57,14,9],
[66,57,14,10],
[66,57,14,11],
[66,57,14,12],
[66,57,15,3],
[66,57,15,4],
[66,57,15,5],
[66,57,15,6],
[66,57,15,8],
[66,57,15,10],
[66,57,15,11],
[66,57,15,12],
[66,57,15,13],
[66,57,15,14],
[66,57,16,0],
[66,57,16,1],
[66,57,16,2],
[66,57,16,3],
[66,57,16,4],
[66,57,16,5],
[66,57,16,6],
[66,57,16,8],
[66,57,16,9],
[66,57,16,10],
[66,57,16,11],
[66,57,16,12],
[66,57,16,13],
[66,57,16,14],
[66,57,16,15],
[66,57,17,0],
[66,57,17,1],
[66,57,17,2],
[66,57,17,3],
[66,57,17,4],
[66,57,17,5],
[66,57,17,6],
[66,57,17,8],
[66,57,17,9],
[66,57,17,10],
[66,57,17,11],
[66,57,17,12],
[66,57,17,13],
[66,57,17,14],
[66,57,17,15],
[66,57,17,16],
[66,57,18,0],
[66,57,18,1],
[66,57,18,2],
[66,57,18,3],
[66,57,18,4],
[66,57,18,5],
[66,57,18,6],
[66,57,18,8],
[66,57,18,10],
[66,57,18,11],
[66,57,18,12],
[66,57,18,13],
[66,57,18,14],
[66,57,18,16],
[66,57,18,17],
[66,57,19,0],
[66,57,19,1],
[66,57,19,2],
[66,57,19,3],
[66,57,19,4],
[66,57,19,5],
[66,57,19,6],
[66,57,19,8],
[66,57,19,9],
[66,57,19,10],
[66,57,19,11],
[66,57,19,12],
[66,57,19,15],
[66,57,19,16],
[66,57,19,17],
[66,57,20,0],
[66,57,20,1],
[66,57,20,2],
[66,57,20,3],
[66,57,20,4],
[66,57,20,5],
[66,57,20,6],
[66,57,20,8],
[66,57,20,9],
[66,57,20,11],
[66,57,20,12],
[66,57,20,13],
[66,57,20,14],
[66,57,20,15],
[66,57,21,0],
[66,57,21,1],
[66,57,21,2],
[66,57,21,4],
[66,57,21,9],
[66,57,21,10],
[66,57,21,11],
[66,57,21,12],
[66,57,21,13],
[66,57,21,14],
[66,57,22,0],
[66,57,22,1],
[66,57,22,2],
[66,57,22,3],
[66,57,22,4],
[66,57,22,5],
[66,57,22,6],
[66,57,22,8],
[66,57,22,9],
[66,57,22,11],
[66,57,22,12],
[66,57,22,13],
[66,57,24,0],
[66,57,24,1],
[66,57,24,2],
[66,57,24,3],
[66,57,24,4],
[66,57,24,5],
[66,57,24,6],
[66,57,24,8],
[66,57,24,9],
[66,57,24,10],
[66,57,24,11],
[66,57,26,0],
[66,57,26,1],
[66,57,26,2],
[66,57,26,3],
[66,57,26,4],
[66,57,26,5],
[66,57,27,0],
[66,57,27,1],
[66,57,27,2],
[66,57,27,3],
[66,57,27,4],
[66,58,3,0],
[66,58,3,1],
[66,58,3,2],
[66,58,4,0],
[66,58,4,1],
[66,58,4,2],
[66,58,4,3],
[66,58,5,0],
[66,58,5,1],
[66,58,5,2],
[66,58,5,4],
[66,58,6,0],
[66,58,6,1],
[66,58,6,2],
[66,58,6,4],
[66,58,8,0],
[66,58,8,1],
[66,58,8,2],
[66,58,8,4],
[66,58,9,0],
[66,58,9,1],
[66,58,9,2],
[66,58,9,3],
[66,58,9,4],
[66,58,9,5],
[66,58,9,6],
[66,58,10,0],
[66,58,10,1],
[66,58,10,2],
[66,58,10,3],
[66,58,10,4],
[66,58,10,5],
[66,58,10,8],
[66,58,10,9],
[66,58,11,0],
[66,58,11,1],
[66,58,11,2],
[66,58,11,3],
[66,58,11,4],
[66,58,11,5],
[66,58,11,8],
[66,58,11,9],
[66,58,11,10],
[66,58,12,0],
[66,58,12,1],
[66,58,12,2],
[66,58,12,3],
[66,58,12,4],
[66,58,12,6],
[66,58,12,8],
[66,58,12,9],
[66,58,12,10],
[66,58,13,0],
[66,58,13,1],
[66,58,13,2],
[66,58,13,3],
[66,58,13,4],
[66,58,13,6],
[66,58,13,8],
[66,58,13,9],
[66,58,13,10],
[66,58,13,11],
[66,58,13,12],
[66,58,14,0],
[66,58,14,1],
[66,58,14,2],
[66,58,14,5],
[66,58,14,6],
[66,58,14,8],
[66,58,14,9],
[66,58,14,10],
[66,58,14,11],
[66,58,14,12],
[66,58,15,3],
[66,58,15,4],
[66,58,15,5],
[66,58,15,6],
[66,58,15,8],
[66,58,15,10],
[66,58,15,11],
[66,58,15,12],
[66,58,15,13],
[66,58,15,14],
[66,58,17,0],
[66,58,17,1],
[66,58,17,2],
[66,58,17,3],
[66,58,17,4],
[66,58,17,5],
[66,58,17,6],
[66,58,17,8],
[66,58,17,9],
[66,58,17,10],
[66,58,17,11],
[66,58,17,12],
[66,58,17,13],
[66,58,17,14],
[66,58,17,15],
[66,58,18,0],
[66,58,18,1],
[66,58,18,2],
[66,58,18,3],
[66,58,18,4],
[66,58,18,5],
[66,58,18,6],
[66,58,18,8],
[66,58,18,10],
[66,58,18,11],
[66,58,18,12],
[66,58,18,13],
[66,58,18,14],
[66,58,18,17],
[66,58,19,0],
[66,58,19,1],
[66,58,19,2],
[66,58,19,3],
[66,58,19,4],
[66,58,19,5],
[66,58,19,6],
[66,58,19,8],
[66,58,19,9],
[66,58,19,10],
[66,58,19,11],
[66,58,19,12],
[66,58,19,15],
[66,58,19,17],
[66,58,20,0],
[66,58,20,1],
[66,58,20,2],
[66,58,20,3],
[66,58,20,4],
[66,58,20,5],
[66,58,20,6],
[66,58,20,8],
[66,58,20,9],
[66,58,20,11],
[66,58,20,12],
[66,58,20,13],
[66,58,20,14],
[66,58,20,15],
[66,58,21,0],
[66,58,21,1],
[66,58,21,2],
[66,58,21,4],
[66,58,21,9],
[66,58,21,10],
[66,58,21,11],
[66,58,21,12],
[66,58,21,13],
[66,58,21,14],
[66,58,22,0],
[66,58,22,1],
[66,58,22,2],
[66,58,22,3],
[66,58,22,4],
[66,58,22,5],
[66,58,22,6],
[66,58,22,8],
[66,58,22,9],
[66,58,22,11],
[66,58,22,12],
[66,58,22,13],
[66,58,23,0],
[66,58,23,1],
[66,58,23,2],
[66,58,23,3],
[66,58,23,4],
[66,58,23,5],
[66,58,23,6],
[66,58,23,8],
[66,58,23,9],
[66,58,23,10],
[66,58,23,11],
[66,58,26,0],
[66,58,26,1],
[66,58,26,2],
[66,58,26,3],
[66,58,26,4],
[66,58,26,5],
[66,58,27,0],
[66,58,27,1],
[66,58,27,2],
[66,58,27,3],
[66,58,27,4],
[66,59,3,0],
[66,59,3,1],
[66,59,3,2],
[66,59,4,0],
[66,59,4,1],
[66,59,4,2],
[66,59,4,3],
[66,59,5,0],
[66,59,5,1],
[66,59,5,2],
[66,59,5,4],
[66,59,6,0],
[66,59,6,1],
[66,59,6,2],
[66,59,6,4],
[66,59,8,0],
[66,59,8,1],
[66,59,8,2],
[66,59,8,4],
[66,59,10,0],
[66,59,10,1],
[66,59,10,2],
[66,59,10,3],
[66,59,10,4],
[66,59,10,6],
[66,59,10,8],
[66,59,11,0],
[66,59,11,1],
[66,59,11,2],
[66,59,11,3],
[66,59,11,4],
[66,59,11,6],
[66,59,11,8],
[66,59,11,10],
[66,59,12,0],
[66,59,12,1],
[66,59,12,2],
[66,59,12,5],
[66,59,12,6],
[66,59,12,8],
[66,59,12,10],
[66,59,13,0],
[66,59,13,1],
[66,59,13,2],
[66,59,13,5],
[66,59,13,6],
[66,59,13,8],
[66,59,13,10],
[66,59,13,11],
[66,59,13,12],
[66,59,14,3],
[66,59,14,4],
[66,59,14,5],
[66,59,14,6],
[66,59,14,8],
[66,59,14,10],
[66,59,14,11],
[66,59,14,12],
[66,59,16,0],
[66,59,16,1],
[66,59,16,2],
[66,59,16,3],
[66,59,16,4],
[66,59,16,5],
[66,59,16,6],
[66,59,16,8],
[66,59,16,10],
[66,59,16,11],
[66,59,16,12],
[66,59,16,13],
[66,59,16,14],
[66,59,17,0],
[66,59,17,1],
[66,59,17,2],
[66,59,17,3],
[66,59,17,4],
[66,59,17,5],
[66,59,17,6],
[66,59,17,8],
[66,59,17,10],
[66,59,17,11],
[66,59,17,12],
[66,59,17,13],
[66,59,17,14],
[66,59,17,16],
[66,59,19,0],
[66,59,19,1],
[66,59,19,2],
[66,59,19,3],
[66,59,19,4],
[66,59,19,5],
[66,59,19,6],
[66,59,19,8],
[66,59,19,10],
[66,59,19,11],
[66,59,19,12],
[66,59,20,0],
[66,59,20,1],
[66,59,20,2],
[66,59,20,3],
[66,59,20,4],
[66,59,20,5],
[66,59,20,6],
[66,59,20,8],
[66,59,20,11],
[66,59,20,12],
[66,59,20,13],
[66,59,20,14],
[66,59,21,0],
[66,59,21,1],
[66,59,21,2],
[66,59,21,4],
[66,59,21,10],
[66,59,21,11],
[66,59,21,12],
[66,59,21,13],
[66,59,22,0],
[66,59,22,1],
[66,59,22,2],
[66,59,22,3],
[66,59,22,4],
[66,59,22,5],
[66,59,22,6],
[66,59,22,8],
[66,59,22,11],
[66,59,23,0],
[66,59,23,1],
[66,59,23,2],
[66,59,23,3],
[66,59,23,4],
[66,59,23,5],
[66,59,23,6],
[66,59,23,8],
[66,59,24,0],
[66,59,24,1],
[66,59,24,2],
[66,59,24,3],
[66,59,24,4],
[66,59,24,5],
[66,59,24,6],
[66,59,24,8],
[66,59,27,0],
[66,59,27,1],
[66,59,27,2],
[66,60,3,0],
[66,60,3,1],
[66,60,3,2],
[66,60,4,0],
[66,60,4,1],
[66,60,4,2],
[66,60,4,3],
[66,60,5,0],
[66,60,5,1],
[66,60,5,2],
[66,60,5,4],
[66,60,6,0],
[66,60,6,1],
[66,60,6,2],
[66,60,6,4],
[66,60,8,0],
[66,60,8,1],
[66,60,8,2],
[66,60,8,4],
[66,60,9,0],
[66,60,9,1],
[66,60,9,2],
[66,60,9,3],
[66,60,9,4],
[66,60,9,6],
[66,60,9,8],
[66,60,10,0],
[66,60,10,1],
[66,60,10,2],
[66,60,10,5],
[66,60,10,6],
[66,60,10,8],
[66,60,10,9],
[66,60,11,0],
[66,60,11,1],
[66,60,11,2],
[66,60,11,5],
[66,60,11,6],
[66,60,11,8],
[66,60,11,9],
[66,60,11,10],
[66,60,12,3],
[66,60,12,4],
[66,60,12,5],
[66,60,12,6],
[66,60,12,8],
[66,60,12,9],
[66,60,12,10],
[66,60,15,0],
[66,60,15,1],
[66,60,15,2],
[66,60,15,3],
[66,60,15,4],
[66,60,15,5],
[66,60,15,6],
[66,60,15,8],
[66,60,15,10],
[66,60,15,11],
[66,60,15,12],
[66,60,16,0],
[66,60,16,1],
[66,60,16,2],
[66,60,16,3],
[66,60,16,4],
[66,60,16,5],
[66,60,16,6],
[66,60,16,8],
[66,60,16,9],
[66,60,16,10],
[66,60,16,11],
[66,60,16,12],
[66,60,16,15],
[66,60,17,0],
[66,60,17,1],
[66,60,17,2],
[66,60,17,3],
[66,60,17,4],
[66,60,17,5],
[66,60,17,6],
[66,60,17,8],
[66,60,17,9],
[66,60,17,10],
[66,60,17,11],
[66,60,17,12],
[66,60,17,15],
[66,60,17,16],
[66,60,18,0],
[66,60,18,1],
[66,60,18,2],
[66,60,18,3],
[66,60,18,4],
[66,60,18,5],
[66,60,18,6],
[66,60,18,8],
[66,60,18,10],
[66,60,18,11],
[66,60,18,12],
[66,60,20,0],
[66,60,20,1],
[66,60,20,2],
[66,60,20,3],
[66,60,20,4],
[66,60,20,5],
[66,60,20,6],
[66,60,20,8],
[66,60,20,9],
[66,60,20,11],
[66,60,20,12],
[66,60,21,0],
[66,60,21,1],
[66,60,21,2],
[66,60,21,4],
[66,60,21,9],
[66,60,21,10],
[66,60,21,11],
[66,60,22,0],
[66,60,22,1],
[66,60,22,2],
[66,60,22,3],
[66,60,22,4],
[66,60,22,5],
[66,60,22,6],
[66,60,22,8],
[66,60,22,9],
[66,60,23,0],
[66,60,23,1],
[66,60,23,2],
[66,60,23,3],
[66,60,23,4],
[66,60,23,5],
[66,60,23,6],
[66,60,24,0],
[66,60,24,1],
[66,60,24,2],
[66,60,24,3],
[66,60,24,4],
[66,60,24,5],
[66,60,24,6],
[66,60,26,0],
[66,60,26,1],
[66,60,26,2],
[66,61,3,0],
[66,61,3,1],
[66,61,3,2],
[66,61,5,0],
[66,61,5,1],
[66,61,5,2],
[66,61,6,0],
[66,61,6,1],
[66,61,6,2],
[66,61,8,0],
[66,61,8,1],
[66,61,8,2],
[66,61,9,0],
[66,61,9,1],
[66,61,9,2],
[66,61,9,5],
[66,61,9,6],
[66,61,9,8],
[66,61,10,3],
[66,61,10,5],
[66,61,10,6],
[66,61,10,8],
[66,61,10,9],
[66,61,11,3],
[66,61,11,5],
[66,61,11,6],
[66,61,11,8],
[66,61,11,9],
[66,61,11,10],
[66,61,12,0],
[66,61,12,1],
[66,61,12,2],
[66,61,12,3],
[66,61,12,5],
[66,61,12,6],
[66,61,12,8],
[66,61,12,9],
[66,61,12,10],
[66,61,13,0],
[66,61,13,1],
[66,61,13,2],
[66,61,13,3],
[66,61,13,5],
[66,61,13,6],
[66,61,13,8],
[66,61,13,9],
[66,61,13,10],
[66,61,13,11],
[66,61,13,12],
[66,61,14,0],
[66,61,14,1],
[66,61,14,2],
[66,61,14,3],
[66,61,14,5],
[66,61,14,6],
[66,61,14,8],
[66,61,14,9],
[66,61,14,10],
[66,61,14,11],
[66,61,14,12],
[66,61,15,0],
[66,61,15,1],
[66,61,15,2],
[66,61,15,3],
[66,61,15,5],
[66,61,15,6],
[66,61,15,8],
[66,61,15,10],
[66,61,15,11],
[66,61,15,12],
[66,61,15,13],
[66,61,15,14],
[66,61,16,0],
[66,61,16,1],
[66,61,16,2],
[66,61,16,3],
[66,61,16,5],
[66,61,16,6],
[66,61,16,8],
[66,61,16,9],
[66,61,16,10],
[66,61,16,11],
[66,61,16,12],
[66,61,16,13],
[66,61,16,14],
[66,61,16,15],
[66,61,17,0],
[66,61,17,1],
[66,61,17,2],
[66,61,17,3],
[66,61,17,5],
[66,61,17,6],
[66,61,17,8],
[66,61,17,9],
[66,61,17,10],
[66,61,17,11],
[66,61,17,12],
[66,61,17,13],
[66,61,17,14],
[66,61,17,15],
[66,61,18,0],
[66,61,18,1],
[66,61,18,2],
[66,61,18,3],
[66,61,18,5],
[66,61,18,6],
[66,61,18,8],
[66,61,18,10],
[66,61,18,11],
[66,61,18,12],
[66,61,18,13],
[66,61,18,14],
[66,61,19,0],
[66,61,19,1],
[66,61,19,2],
[66,61,19,3],
[66,61,19,5],
[66,61,19,6],
[66,61,19,8],
[66,61,19,9],
[66,61,19,10],
[66,61,19,11],
[66,61,19,12],
[66,61,20,0],
[66,61,20,1],
[66,61,20,2],
[66,61,20,3],
[66,61,20,5],
[66,61,20,6],
[66,61,20,8],
[66,61,20,9],
[66,61,20,11],
[66,61,21,0],
[66,61,21,1],
[66,61,21,2],
[66,61,21,9],
[66,61,22,0],
[66,61,22,1],
[66,61,22,2],
[66,61,22,3],
[66,61,22,5],
[66,61,22,6],
[66,61,23,0],
[66,61,23,1],
[66,61,23,2],
[66,61,23,3],
[66,61,23,5],
[66,61,24,0],
[66,61,24,1],
[66,61,24,2],
[66,61,24,3],
[66,61,24,5],
[66,63,3,0],
[66,63,3,1],
[66,63,3,2],
[66,63,4,0],
[66,63,4,1],
[66,63,4,2],
[66,63,4,3],
[66,63,5,0],
[66,63,5,1],
[66,63,5,2],
[66,63,5,4],
[66,63,6,0],
[66,63,6,1],
[66,63,6,2],
[66,63,8,4],
[66,63,10,0],
[66,63,10,1],
[66,63,10,2],
[66,63,10,3],
[66,63,10,4],
[66,63,10,5],
[66,63,10,6],
[66,63,10,8],
[66,63,11,0],
[66,63,11,1],
[66,63,11,2],
[66,63,11,3],
[66,63,11,4],
[66,63,11,5],
[66,63,11,6],
[66,63,11,8],
[66,63,11,10],
[66,63,12,0],
[66,63,12,1],
[66,63,12,2],
[66,63,12,3],
[66,63,12,4],
[66,63,12,5],
[66,63,12,6],
[66,63,12,8],
[66,63,12,10],
[66,63,13,0],
[66,63,13,1],
[66,63,13,2],
[66,63,13,3],
[66,63,13,4],
[66,63,13,5],
[66,63,13,6],
[66,63,13,8],
[66,63,13,10],
[66,63,13,11],
[66,63,13,12],
[66,63,14,0],
[66,63,14,1],
[66,63,14,2],
[66,63,14,3],
[66,63,14,4],
[66,63,14,5],
[66,63,14,6],
[66,63,14,8],
[66,63,14,10],
[66,63,14,11],
[66,63,14,12],
[66,63,16,0],
[66,63,16,1],
[66,63,16,2],
[66,63,16,3],
[66,63,16,4],
[66,63,16,5],
[66,63,16,6],
[66,63,16,8],
[66,63,16,10],
[66,63,16,11],
[66,63,16,12],
[66,63,16,13],
[66,63,16,14],
[66,63,17,0],
[66,63,17,1],
[66,63,17,2],
[66,63,17,3],
[66,63,17,4],
[66,63,17,5],
[66,63,17,6],
[66,63,17,8],
[66,63,17,10],
[66,63,17,11],
[66,63,17,12],
[66,63,17,13],
[66,63,17,14],
[66,63,19,0],
[66,63,19,1],
[66,63,19,2],
[66,63,19,3],
[66,63,19,4],
[66,63,19,5],
[66,63,19,6],
[66,63,19,8],
[66,63,19,10],
[66,63,19,11],
[66,63,20,0],
[66,63,20,1],
[66,63,20,2],
[66,63,20,3],
[66,63,20,4],
[66,63,20,5],
[66,63,20,6],
[66,63,20,8],
[66,63,21,0],
[66,63,21,1],
[66,63,21,2],
[66,63,21,4],
[66,63,22,0],
[66,63,22,1],
[66,63,22,2],
[66,63,22,3],
[66,63,22,4],
[66,63,22,5],
[66,63,23,0],
[66,63,23,1],
[66,63,23,2],
[66,63,23,3],
[66,63,23,4],
[66,63,24,0],
[66,63,24,1],
[66,63,24,2],
[66,63,24,3],
[66,63,24,4],
[66,64,3,0],
[66,64,3,1],
[66,64,3,2],
[66,64,5,0],
[66,64,5,1],
[66,64,5,2],
[66,64,8,0],
[66,64,8,1],
[66,64,8,2],
[66,64,9,0],
[66,64,9,1],
[66,64,9,2],
[66,64,9,3],
[66,64,9,5],
[66,64,9,6],
[66,64,9,8],
[66,64,10,0],
[66,64,10,1],
[66,64,10,2],
[66,64,10,3],
[66,64,10,5],
[66,64,10,6],
[66,64,10,8],
[66,64,10,9],
[66,64,11,0],
[66,64,11,1],
[66,64,11,2],
[66,64,11,3],
[66,64,11,5],
[66,64,11,6],
[66,64,11,8],
[66,64,11,9],
[66,64,11,10],
[66,64,12,0],
[66,64,12,1],
[66,64,12,2],
[66,64,12,3],
[66,64,12,5],
[66,64,12,6],
[66,64,12,8],
[66,64,12,9],
[66,64,12,10],
[66,64,13,0],
[66,64,13,1],
[66,64,13,2],
[66,64,13,3],
[66,64,13,5],
[66,64,13,6],
[66,64,13,8],
[66,64,13,9],
[66,64,13,10],
[66,64,13,11],
[66,64,13,12],
[66,64,14,0],
[66,64,14,1],
[66,64,14,2],
[66,64,14,3],
[66,64,14,5],
[66,64,14,6],
[66,64,14,8],
[66,64,14,9],
[66,64,14,10],
[66,64,14,11],
[66,64,14,12],
[66,64,15,0],
[66,64,15,1],
[66,64,15,2],
[66,64,15,3],
[66,64,15,5],
[66,64,15,6],
[66,64,15,8],
[66,64,15,10],
[66,64,15,11],
[66,64,15,12],
[66,64,15,13],
[66,64,15,14],
[66,64,16,0],
[66,64,16,1],
[66,64,16,2],
[66,64,16,3],
[66,64,16,5],
[66,64,16,6],
[66,64,16,8],
[66,64,16,9],
[66,64,16,10],
[66,64,16,11],
[66,64,16,12],
[66,64,16,13],
[66,64,17,0],
[66,64,17,1],
[66,64,17,2],
[66,64,17,3],
[66,64,17,5],
[66,64,17,6],
[66,64,17,8],
[66,64,17,9],
[66,64,17,10],
[66,64,17,11],
[66,64,17,12],
[66,64,17,13],
[66,64,18,0],
[66,64,18,1],
[66,64,18,2],
[66,64,18,3],
[66,64,18,5],
[66,64,18,6],
[66,64,18,8],
[66,64,18,10],
[66,64,18,11],
[66,64,19,0],
[66,64,19,1],
[66,64,19,2],
[66,64,19,3],
[66,64,19,5],
[66,64,19,6],
[66,64,19,8],
[66,64,19,9],
[66,64,20,0],
[66,64,20,1],
[66,64,20,2],
[66,64,20,3],
[66,64,20,5],
[66,64,20,6],
[66,64,21,0],
[66,64,21,1],
[66,64,21,2],
[66,64,22,0],
[66,64,22,1],
[66,64,22,2],
[66,64,22,3],
[66,64,23,0],
[66,64,23,1],
[66,64,23,2],
[66,64,24,0],
[66,64,24,1],
[66,64,24,2],
[66,65,3,0],
[66,65,3,1],
[66,65,3,2],
[66,65,4,0],
[66,65,4,1],
[66,65,4,2],
[66,65,5,4],
[66,65,6,0],
[66,65,6,1],
[66,65,6,2],
[66,65,6,4],
[66,65,8,0],
[66,65,8,1],
[66,65,8,2],
[66,65,8,4],
[66,65,9,0],
[66,65,9,1],
[66,65,9,2],
[66,65,9,3],
[66,65,9,4],
[66,65,9,5],
[66,65,9,6],
[66,65,9,8],
[66,65,10,0],
[66,65,10,1],
[66,65,10,2],
[66,65,10,3],
[66,65,10,4],
[66,65,10,5],
[66,65,10,6],
[66,65,10,8],
[66,65,10,9],
[66,65,11,0],
[66,65,11,1],
[66,65,11,2],
[66,65,11,3],
[66,65,11,4],
[66,65,11,5],
[66,65,11,6],
[66,65,11,8],
[66,65,11,9],
[66,65,11,10],
[66,65,12,0],
[66,65,12,1],
[66,65,12,2],
[66,65,12,3],
[66,65,12,4],
[66,65,12,5],
[66,65,12,6],
[66,65,12,8],
[66,65,12,9],
[66,65,12,10],
[66,65,13,0],
[66,65,13,1],
[66,65,13,2],
[66,65,13,3],
[66,65,13,4],
[66,65,13,5],
[66,65,13,6],
[66,65,13,8],
[66,65,13,9],
[66,65,13,10],
[66,65,13,11],
[66,65,13,12],
[66,65,14,0],
[66,65,14,1],
[66,65,14,2],
[66,65,14,3],
[66,65,14,4],
[66,65,14,5],
[66,65,14,6],
[66,65,14,8],
[66,65,14,9],
[66,65,14,10],
[66,65,14,11],
[66,65,14,12],
[66,65,15,0],
[66,65,15,1],
[66,65,15,2],
[66,65,15,3],
[66,65,15,4],
[66,65,15,5],
[66,65,15,6],
[66,65,15,8],
[66,65,15,10],
[66,65,15,11],
[66,65,15,12],
[66,65,15,13],
[66,65,16,0],
[66,65,16,1],
[66,65,16,2],
[66,65,16,3],
[66,65,16,4],
[66,65,16,5],
[66,65,16,6],
[66,65,16,8],
[66,65,16,9],
[66,65,16,10],
[66,65,16,11],
[66,65,17,0],
[66,65,17,1],
[66,65,17,2],
[66,65,17,3],
[66,65,17,4],
[66,65,17,5],
[66,65,17,6],
[66,65,17,8],
[66,65,17,9],
[66,65,17,10],
[66,65,17,11],
[66,65,18,0],
[66,65,18,1],
[66,65,18,2],
[66,65,18,3],
[66,65,18,4],
[66,65,18,5],
[66,65,18,6],
[66,65,18,8],
[66,65,19,0],
[66,65,19,1],
[66,65,19,2],
[66,65,19,3],
[66,65,19,4],
[66,65,19,5],
[66,65,19,6],
[66,65,20,0],
[66,65,20,1],
[66,65,20,2],
[66,65,20,3],
[66,65,20,4],
[66,65,20,5],
[66,65,21,0],
[66,65,21,1],
[66,65,21,2],
[66,65,21,4],
[66,65,22,0],
[66,65,22,1],
[66,65,22,2],
[67,4,3,0],
[67,4,3,1],
[67,4,3,2],
[67,5,4,0],
[67,5,4,1],
[67,5,4,2],
[67,6,4,0],
[67,6,4,1],
[67,6,4,2],
[67,8,4,0],
[67,8,4,1],
[67,8,4,2],
[67,9,3,0],
[67,9,3,1],
[67,9,3,2],
[67,9,4,0],
[67,9,4,1],
[67,9,4,2],
[67,9,4,3],
[67,9,5,0],
[67,9,5,1],
[67,9,5,2],
[67,9,5,4],
[67,9,6,0],
[67,9,6,1],
[67,9,6,2],
[67,9,6,4],
[67,9,8,0],
[67,9,8,1],
[67,9,8,2],
[67,9,8,4],
[67,10,3,0],
[67,10,3,1],
[67,10,3,2],
[67,10,4,0],
[67,10,4,1],
[67,10,4,2],
[67,10,4,3],
[67,10,5,0],
[67,10,5,1],
[67,10,5,2],
[67,10,5,4],
[67,10,6,0],
[67,10,6,1],
[67,10,6,2],
[67,10,6,4],
[67,10,8,0],
[67,10,8,1],
[67,10,8,2],
[67,10,8,4],
[67,10,9,0],
[67,10,9,1],
[67,10,9,2],
[67,10,9,3],
[67,10,9,4],
[67,10,9,5],
[67,10,9,6],
[67,10,9,8],
[67,11,3,0],
[67,11,3,1],
[67,11,3,2],
[67,11,4,0],
[67,11,4,1],
[67,11,4,2],
[67,11,4,3],
[67,11,5,0],
[67,11,5,1],
[67,11,5,2],
[67,11,5,4],
[67,11,6,0],
[67,11,6,1],
[67,11,6,2],
[67,11,6,4],
[67,11,8,0],
[67,11,8,1],
[67,11,8,2],
[67,11,8,4],
[67,11,9,0],
[67,11,9,1],
[67,11,9,2],
[67,11,9,3],
[67,11,9,4],
[67,11,9,5],
[67,11,9,6],
[67,11,9,8],
[67,11,10,0],
[67,11,10,1],
[67,11,10,2],
[67,11,10,3],
[67,11,10,4],
[67,11,10,5],
[67,11,10,6],
[67,11,10,8],
[67,11,10,9],
[67,12,3,0],
[67,12,3,1],
[67,12,3,2],
[67,12,4,0],
[67,12,4,1],
[67,12,4,2],
[67,12,4,3],
[67,12,5,0],
[67,12,5,1],
[67,12,5,2],
[67,12,5,4],
[67,12,6,0],
[67,12,6,1],
[67,12,6,2],
[67,12,6,4],
[67,12,8,0],
[67,12,8,1],
[67,12,8,2],
[67,12,8,4],
[67,12,9,0],
[67,12,9,1],
[67,12,9,2],
[67,12,9,3],
[67,12,9,4],
[67,12,9,5],
[67,12,9,6],
[67,12,9,8],
[67,12,10,0],
[67,12,10,1],
[67,12,10,2],
[67,12,10,3],
[67,12,10,4],
[67,12,10,5],
[67,12,10,6],
[67,12,10,8],
[67,12,10,9],
[67,13,3,0],
[67,13,3,1],
[67,13,3,2],
[67,13,4,0],
[67,13,4,1],
[67,13,4,2],
[67,13,4,3],
[67,13,5,0],
[67,13,5,1],
[67,13,5,2],
[67,13,5,4],
[67,13,6,0],
[67,13,6,1],
[67,13,6,2],
[67,13,6,4],
[67,13,8,0],
[67,13,8,1],
[67,13,8,2],
[67,13,8,4],
[67,13,9,0],
[67,13,9,1],
[67,13,9,2],
[67,13,9,3],
[67,13,9,4],
[67,13,9,5],
[67,13,9,6],
[67,13,9,8],
[67,13,10,0],
[67,13,10,1],
[67,13,10,2],
[67,13,10,3],
[67,13,10,4],
[67,13,10,5],
[67,13,10,6],
[67,13,10,8],
[67,13,10,9],
[67,13,11,0],
[67,13,11,1],
[67,13,11,2],
[67,13,11,3],
[67,13,11,4],
[67,13,11,5],
[67,13,11,6],
[67,13,11,8],
[67,13,11,9],
[67,13,11,10],
[67,13,12,0],
[67,13,12,1],
[67,13,12,2],
[67,13,12,3],
[67,13,12,4],
[67,13,12,5],
[67,13,12,6],
[67,13,12,8],
[67,13,12,9],
[67,13,12,10],
[67,14,3,0],
[67,14,3,1],
[67,14,3,2],
[67,14,4,0],
[67,14,4,1],
[67,14,4,2],
[67,14,4,3],
[67,14,5,0],
[67,14,5,1],
[67,14,5,2],
[67,14,5,4],
[67,14,6,0],
[67,14,6,1],
[67,14,6,2],
[67,14,6,4],
[67,14,8,0],
[67,14,8,1],
[67,14,8,2],
[67,14,8,4],
[67,14,9,0],
[67,14,9,1],
[67,14,9,2],
[67,14,9,3],
[67,14,9,4],
[67,14,9,5],
[67,14,9,6],
[67,14,9,8],
[67,14,10,0],
[67,14,10,1],
[67,14,10,2],
[67,14,10,3],
[67,14,10,4],
[67,14,10,5],
[67,14,10,6],
[67,14,10,8],
[67,14,10,9],
[67,14,11,0],
[67,14,11,1],
[67,14,11,2],
[67,14,11,3],
[67,14,11,4],
[67,14,11,5],
[67,14,11,6],
[67,14,11,8],
[67,14,11,9],
[67,14,11,10],
[67,14,12,0],
[67,14,12,1],
[67,14,12,2],
[67,14,12,3],
[67,14,12,4],
[67,14,12,5],
[67,14,12,6],
[67,14,12,8],
[67,14,12,9],
[67,14,12,10],
[67,15,3,0],
[67,15,3,1],
[67,15,3,2],
[67,15,4,0],
[67,15,4,1],
[67,15,4,2],
[67,15,4,3],
[67,15,5,0],
[67,15,5,1],
[67,15,5,2],
[67,15,5,4],
[67,15,6,0],
[67,15,6,1],
[67,15,6,2],
[67,15,6,4],
[67,15,8,0],
[67,15,8,1],
[67,15,8,2],
[67,15,8,4],
[67,15,10,0],
[67,15,10,1],
[67,15,10,2],
[67,15,10,3],
[67,15,10,4],
[67,15,10,5],
[67,15,10,6],
[67,15,10,8],
[67,15,11,0],
[67,15,11,1],
[67,15,11,2],
[67,15,11,3],
[67,15,11,4],
[67,15,11,5],
[67,15,11,6],
[67,15,11,8],
[67,15,11,10],
[67,15,12,0],
[67,15,12,1],
[67,15,12,2],
[67,15,12,3],
[67,15,12,4],
[67,15,12,5],
[67,15,12,6],
[67,15,12,8],
[67,15,12,10],
[67,15,13,0],
[67,15,13,1],
[67,15,13,2],
[67,15,13,3],
[67,15,13,4],
[67,15,13,5],
[67,15,13,6],
[67,15,13,8],
[67,15,13,10],
[67,15,13,11],
[67,15,13,12],
[67,15,14,0],
[67,15,14,1],
[67,15,14,2],
[67,15,14,3],
[67,15,14,4],
[67,15,14,5],
[67,15,14,6],
[67,15,14,8],
[67,15,14,10],
[67,15,14,11],
[67,15,14,12],
[67,16,3,0],
[67,16,3,1],
[67,16,3,2],
[67,16,4,0],
[67,16,4,1],
[67,16,4,2],
[67,16,4,3],
[67,16,5,0],
[67,16,5,1],
[67,16,5,2],
[67,16,5,4],
[67,16,6,0],
[67,16,6,1],
[67,16,6,2],
[67,16,6,4],
[67,16,8,0],
[67,16,8,1],
[67,16,8,2],
[67,16,8,4],
[67,16,9,0],
[67,16,9,1],
[67,16,9,2],
[67,16,9,3],
[67,16,9,4],
[67,16,9,5],
[67,16,9,6],
[67,16,9,8],
[67,16,10,0],
[67,16,10,1],
[67,16,10,2],
[67,16,10,3],
[67,16,10,4],
[67,16,10,5],
[67,16,10,6],
[67,16,10,8],
[67,16,10,9],
[67,16,11,0],
[67,16,11,1],
[67,16,11,2],
[67,16,11,3],
[67,16,11,4],
[67,16,11,5],
[67,16,11,6],
[67,16,11,8],
[67,16,11,9],
[67,16,11,10],
[67,16,12,0],
[67,16,12,1],
[67,16,12,2],
[67,16,12,3],
[67,16,12,4],
[67,16,12,5],
[67,16,12,6],
[67,16,12,8],
[67,16,12,9],
[67,16,12,10],
[67,16,13,0],
[67,16,13,1],
[67,16,13,2],
[67,16,13,3],
[67,16,13,4],
[67,16,13,5],
[67,16,13,6],
[67,16,13,8],
[67,16,13,9],
[67,16,13,10],
[67,16,13,11],
[67,16,13,12],
[67,16,14,0],
[67,16,14,1],
[67,16,14,2],
[67,16,14,3],
[67,16,14,4],
[67,16,14,5],
[67,16,14,6],
[67,16,14,8],
[67,16,14,9],
[67,16,14,10],
[67,16,14,11],
[67,16,14,12],
[67,16,15,0],
[67,16,15,1],
[67,16,15,2],
[67,16,15,3],
[67,16,15,4],
[67,16,15,5],
[67,16,15,6],
[67,16,15,8],
[67,16,15,10],
[67,16,15,11],
[67,16,15,12],
[67,16,15,13],
[67,16,15,14],
[67,17,3,0],
[67,17,3,1],
[67,17,3,2],
[67,17,4,0],
[67,17,4,1],
[67,17,4,2],
[67,17,4,3],
[67,17,5,0],
[67,17,5,1],
[67,17,5,2],
[67,17,5,4],
[67,17,6,0],
[67,17,6,1],
[67,17,6,2],
[67,17,6,4],
[67,17,8,0],
[67,17,8,1],
[67,17,8,2],
[67,17,8,4],
[67,17,9,0],
[67,17,9,1],
[67,17,9,2],
[67,17,9,3],
[67,17,9,4],
[67,17,9,5],
[67,17,9,6],
[67,17,9,8],
[67,17,10,0],
[67,17,10,1],
[67,17,10,2],
[67,17,10,3],
[67,17,10,4],
[67,17,10,5],
[67,17,10,6],
[67,17,10,8],
[67,17,10,9],
[67,17,11,0],
[67,17,11,1],
[67,17,11,2],
[67,17,11,3],
[67,17,11,4],
[67,17,11,5],
[67,17,11,6],
[67,17,11,8],
[67,17,11,9],
[67,17,11,10],
[67,17,12,0],
[67,17,12,1],
[67,17,12,2],
[67,17,12,3],
[67,17,12,4],
[67,17,12,5],
[67,17,12,6],
[67,17,12,8],
[67,17,12,9],
[67,17,12,10],
[67,17,13,0],
[67,17,13,1],
[67,17,13,2],
[67,17,13,3],
[67,17,13,4],
[67,17,13,5],
[67,17,13,6],
[67,17,13,8],
[67,17,13,9],
[67,17,13,10],
[67,17,13,11],
[67,17,13,12],
[67,17,14,0],
[67,17,14,1],
[67,17,14,2],
[67,17,14,3],
[67,17,14,4],
[67,17,14,5],
[67,17,14,6],
[67,17,14,8],
[67,17,14,9],
[67,17,14,10],
[67,17,14,11],
[67,17,14,12],
[67,17,15,0],
[67,17,15,1],
[67,17,15,2],
[67,17,15,3],
[67,17,15,4],
[67,17,15,5],
[67,17,15,6],
[67,17,15,8],
[67,17,15,10],
[67,17,15,11],
[67,17,15,12],
[67,17,15,13],
[67,17,15,14],
[67,17,16,0],
[67,17,16,1],
[67,17,16,2],
[67,17,16,3],
[67,17,16,4],
[67,17,16,5],
[67,17,16,6],
[67,17,16,8],
[67,17,16,9],
[67,17,16,10],
[67,17,16,11],
[67,17,16,12],
[67,17,16,13],
[67,17,16,14],
[67,17,16,15],
[67,18,3,0],
[67,18,3,1],
[67,18,3,2],
[67,18,4,0],
[67,18,4,1],
[67,18,4,2],
[67,18,4,3],
[67,18,5,0],
[67,18,5,1],
[67,18,5,2],
[67,18,5,4],
[67,18,6,0],
[67,18,6,1],
[67,18,6,2],
[67,18,6,4],
[67,18,8,0],
[67,18,8,1],
[67,18,8,2],
[67,18,8,4],
[67,18,10,0],
[67,18,10,1],
[67,18,10,2],
[67,18,10,3],
[67,18,10,4],
[67,18,10,5],
[67,18,10,6],
[67,18,10,8],
[67,18,11,0],
[67,18,11,1],
[67,18,11,2],
[67,18,11,3],
[67,18,11,4],
[67,18,11,5],
[67,18,11,6],
[67,18,11,8],
[67,18,11,10],
[67,18,12,0],
[67,18,12,1],
[67,18,12,2],
[67,18,12,3],
[67,18,12,4],
[67,18,12,5],
[67,18,12,6],
[67,18,12,8],
[67,18,12,10],
[67,18,13,0],
[67,18,13,1],
[67,18,13,2],
[67,18,13,3],
[67,18,13,4],
[67,18,13,5],
[67,18,13,6],
[67,18,13,8],
[67,18,13,10],
[67,18,13,11],
[67,18,13,12],
[67,18,14,0],
[67,18,14,1],
[67,18,14,2],
[67,18,14,3],
[67,18,14,4],
[67,18,14,5],
[67,18,14,6],
[67,18,14,8],
[67,18,14,10],
[67,18,14,11],
[67,18,14,12],
[67,18,16,0],
[67,18,16,1],
[67,18,16,2],
[67,18,16,3],
[67,18,16,4],
[67,18,16,5],
[67,18,16,6],
[67,18,16,8],
[67,18,16,10],
[67,18,16,11],
[67,18,16,12],
[67,18,16,13],
[67,18,16,14],
[67,18,17,0],
[67,18,17,1],
[67,18,17,2],
[67,18,17,3],
[67,18,17,4],
[67,18,17,5],
[67,18,17,6],
[67,18,17,8],
[67,18,17,10],
[67,18,17,11],
[67,18,17,12],
[67,18,17,13],
[67,18,17,14],
[67,18,17,16],
[67,19,3,0],
[67,19,3,1],
[67,19,3,2],
[67,19,4,0],
[67,19,4,1],
[67,19,4,2],
[67,19,4,3],
[67,19,5,0],
[67,19,5,1],
[67,19,5,2],
[67,19,5,4],
[67,19,6,0],
[67,19,6,1],
[67,19,6,2],
[67,19,6,4],
[67,19,8,0],
[67,19,8,1],
[67,19,8,2],
[67,19,8,4],
[67,19,9,0],
[67,19,9,1],
[67,19,9,2],
[67,19,9,3],
[67,19,9,4],
[67,19,9,5],
[67,19,9,6],
[67,19,9,8],
[67,19,10,0],
[67,19,10,1],
[67,19,10,2],
[67,19,10,3],
[67,19,10,4],
[67,19,10,5],
[67,19,10,6],
[67,19,10,8],
[67,19,10,9],
[67,19,11,0],
[67,19,11,1],
[67,19,11,2],
[67,19,11,3],
[67,19,11,4],
[67,19,11,5],
[67,19,11,6],
[67,19,11,8],
[67,19,11,9],
[67,19,11,10],
[67,19,12,0],
[67,19,12,1],
[67,19,12,2],
[67,19,12,3],
[67,19,12,4],
[67,19,12,5],
[67,19,12,6],
[67,19,12,8],
[67,19,12,9],
[67,19,12,10],
[67,19,15,0],
[67,19,15,1],
[67,19,15,2],
[67,19,15,3],
[67,19,15,4],
[67,19,15,5],
[67,19,15,6],
[67,19,15,8],
[67,19,15,10],
[67,19,15,11],
[67,19,15,12],
[67,19,16,0],
[67,19,16,1],
[67,19,16,2],
[67,19,16,3],
[67,19,16,4],
[67,19,16,5],
[67,19,16,6],
[67,19,16,8],
[67,19,16,9],
[67,19,16,10],
[67,19,16,11],
[67,19,16,12],
[67,19,16,15],
[67,19,17,0],
[67,19,17,1],
[67,19,17,2],
[67,19,17,3],
[67,19,17,4],
[67,19,17,5],
[67,19,17,6],
[67,19,17,8],
[67,19,17,9],
[67,19,17,10],
[67,19,17,11],
[67,19,17,12],
[67,19,17,15],
[67,19,17,16],
[67,19,18,0],
[67,19,18,1],
[67,19,18,2],
[67,19,18,3],
[67,19,18,4],
[67,19,18,5],
[67,19,18,6],
[67,19,18,8],
[67,19,18,10],
[67,19,18,11],
[67,19,18,12],
[67,19,18,16],
[67,19,18,17],
[67,20,3,0],
[67,20,3,1],
[67,20,3,2],
[67,20,4,0],
[67,20,4,1],
[67,20,4,2],
[67,20,4,3],
[67,20,5,0],
[67,20,5,1],
[67,20,5,2],
[67,20,5,4],
[67,20,6,0],
[67,20,6,1],
[67,20,6,2],
[67,20,6,4],
[67,20,8,0],
[67,20,8,1],
[67,20,8,2],
[67,20,8,4],
[67,20,9,0],
[67,20,9,1],
[67,20,9,2],
[67,20,9,3],
[67,20,9,4],
[67,20,9,5],
[67,20,9,6],
[67,20,9,8],
[67,20,11,0],
[67,20,11,1],
[67,20,11,2],
[67,20,11,3],
[67,20,11,4],
[67,20,11,5],
[67,20,11,6],
[67,20,11,8],
[67,20,11,9],
[67,20,12,0],
[67,20,12,1],
[67,20,12,2],
[67,20,12,3],
[67,20,12,4],
[67,20,12,5],
[67,20,12,6],
[67,20,12,8],
[67,20,12,9],
[67,20,13,0],
[67,20,13,1],
[67,20,13,2],
[67,20,13,3],
[67,20,13,4],
[67,20,13,5],
[67,20,13,6],
[67,20,13,8],
[67,20,13,9],
[67,20,13,11],
[67,20,13,12],
[67,20,14,0],
[67,20,14,1],
[67,20,14,2],
[67,20,14,3],
[67,20,14,4],
[67,20,14,5],
[67,20,14,6],
[67,20,14,8],
[67,20,14,9],
[67,20,14,11],
[67,20,14,12],
[67,20,15,0],
[67,20,15,1],
[67,20,15,2],
[67,20,15,3],
[67,20,15,4],
[67,20,15,5],
[67,20,15,6],
[67,20,15,8],
[67,20,15,11],
[67,20,15,12],
[67,20,15,13],
[67,20,15,14],
[67,20,16,0],
[67,20,16,1],
[67,20,16,2],
[67,20,16,3],
[67,20,16,4],
[67,20,16,5],
[67,20,16,6],
[67,20,16,8],
[67,20,16,9],
[67,20,16,11],
[67,20,16,12],
[67,20,16,13],
[67,20,16,14],
[67,20,16,15],
[67,20,17,0],
[67,20,17,1],
[67,20,17,2],
[67,20,17,3],
[67,20,17,4],
[67,20,17,5],
[67,20,17,6],
[67,20,17,8],
[67,20,17,9],
[67,20,17,11],
[67,20,17,12],
[67,20,17,13],
[67,20,17,14],
[67,20,17,15],
[67,20,17,16],
[67,20,18,0],
[67,20,18,1],
[67,20,18,2],
[67,20,18,3],
[67,20,18,4],
[67,20,18,5],
[67,20,18,6],
[67,20,18,8],
[67,20,18,11],
[67,20,18,12],
[67,20,18,13],
[67,20,18,14],
[67,20,18,16],
[67,20,18,17],
[67,20,19,0],
[67,20,19,1],
[67,20,19,2],
[67,20,19,3],
[67,20,19,4],
[67,20,19,5],
[67,20,19,6],
[67,20,19,8],
[67,20,19,9],
[67,20,19,11],
[67,20,19,12],
[67,20,19,15],
[67,20,19,16],
[67,20,19,17],
[67,20,19,18],
[67,21,4,0],
[67,21,4,1],
[67,21,4,2],
[67,21,9,0],
[67,21,9,1],
[67,21,9,2],
[67,21,9,4],
[67,21,10,0],
[67,21,10,1],
[67,21,10,2],
[67,21,10,4],
[67,21,10,9],
[67,21,11,0],
[67,21,11,1],
[67,21,11,2],
[67,21,11,4],
[67,21,11,9],
[67,21,11,10],
[67,21,12,0],
[67,21,12,1],
[67,21,12,2],
[67,21,12,4],
[67,21,12,9],
[67,21,12,10],
[67,21,13,0],
[67,21,13,1],
[67,21,13,2],
[67,21,13,4],
[67,21,13,9],
[67,21,13,10],
[67,21,13,11],
[67,21,13,12],
[67,21,14,0],
[67,21,14,1],
[67,21,14,2],
[67,21,14,4],
[67,21,14,9],
[67,21,14,10],
[67,21,14,11],
[67,21,14,12],
[67,21,15,0],
[67,21,15,1],
[67,21,15,2],
[67,21,15,4],
[67,21,15,10],
[67,21,15,11],
[67,21,15,12],
[67,21,15,13],
[67,21,15,14],
[67,21,16,0],
[67,21,16,1],
[67,21,16,2],
[67,21,16,4],
[67,21,16,9],
[67,21,16,10],
[67,21,16,11],
[67,21,16,12],
[67,21,16,13],
[67,21,16,14],
[67,21,16,15],
[67,21,17,0],
[67,21,17,1],
[67,21,17,2],
[67,21,17,4],
[67,21,17,9],
[67,21,17,10],
[67,21,17,11],
[67,21,17,12],
[67,21,17,13],
[67,21,17,14],
[67,21,17,15],
[67,21,17,16],
[67,21,18,0],
[67,21,18,1],
[67,21,18,2],
[67,21,18,4],
[67,21,18,10],
[67,21,18,11],
[67,21,18,12],
[67,21,18,13],
[67,21,18,14],
[67,21,18,16],
[67,21,18,17],
[67,21,19,0],
[67,21,19,1],
[67,21,19,2],
[67,21,19,4],
[67,21,19,9],
[67,21,19,10],
[67,21,19,11],
[67,21,19,12],
[67,21,19,15],
[67,21,19,16],
[67,21,19,17],
[67,21,19,18],
[67,21,20,0],
[67,21,20,1],
[67,21,20,2],
[67,21,20,4],
[67,21,20,9],
[67,21,20,11],
[67,21,20,12],
[67,21,20,13],
[67,21,20,14],
[67,21,20,15],
[67,21,20,16],
[67,21,20,17],
[67,21,20,18],
[67,21,20,19],
[67,22,3,0],
[67,22,3,1],
[67,22,3,2],
[67,22,4,0],
[67,22,4,1],
[67,22,4,2],
[67,22,4,3],
[67,22,5,0],
[67,22,5,1],
[67,22,5,2],
[67,22,5,4],
[67,22,6,0],
[67,22,6,1],
[67,22,6,2],
[67,22,6,4],
[67,22,8,0],
[67,22,8,1],
[67,22,8,2],
[67,22,8,4],
[67,22,9,0],
[67,22,9,1],
[67,22,9,2],
[67,22,9,3],
[67,22,9,4],
[67,22,9,5],
[67,22,9,6],
[67,22,9,8],
[67,22,11,0],
[67,22,11,1],
[67,22,11,2],
[67,22,11,3],
[67,22,11,4],
[67,22,11,5],
[67,22,11,6],
[67,22,11,8],
[67,22,11,9],
[67,22,12,0],
[67,22,12,1],
[67,22,12,2],
[67,22,12,3],
[67,22,12,4],
[67,22,12,5],
[67,22,12,6],
[67,22,12,8],
[67,22,12,9],
[67,22,13,0],
[67,22,13,1],
[67,22,13,2],
[67,22,13,3],
[67,22,13,4],
[67,22,13,5],
[67,22,13,6],
[67,22,13,8],
[67,22,13,9],
[67,22,13,11],
[67,22,13,12],
[67,22,14,0],
[67,22,14,1],
[67,22,14,2],
[67,22,14,3],
[67,22,14,4],
[67,22,14,5],
[67,22,14,6],
[67,22,14,8],
[67,22,14,9],
[67,22,14,11],
[67,22,14,12],
[67,22,15,0],
[67,22,15,1],
[67,22,15,2],
[67,22,15,3],
[67,22,15,4],
[67,22,15,5],
[67,22,15,6],
[67,22,15,8],
[67,22,15,11],
[67,22,15,12],
[67,22,15,13],
[67,22,15,14],
[67,22,16,0],
[67,22,16,1],
[67,22,16,2],
[67,22,16,3],
[67,22,16,4],
[67,22,16,5],
[67,22,16,6],
[67,22,16,8],
[67,22,16,9],
[67,22,16,11],
[67,22,16,12],
[67,22,16,13],
[67,22,16,14],
[67,22,16,15],
[67,22,17,0],
[67,22,17,1],
[67,22,17,2],
[67,22,17,3],
[67,22,17,4],
[67,22,17,5],
[67,22,17,6],
[67,22,17,8],
[67,22,17,9],
[67,22,17,11],
[67,22,17,12],
[67,22,17,13],
[67,22,17,14],
[67,22,17,15],
[67,22,17,16],
[67,22,18,0],
[67,22,18,1],
[67,22,18,2],
[67,22,18,3],
[67,22,18,4],
[67,22,18,5],
[67,22,18,6],
[67,22,18,8],
[67,22,18,11],
[67,22,18,12],
[67,22,18,13],
[67,22,18,14],
[67,22,18,16],
[67,22,18,17],
[67,22,19,0],
[67,22,19,1],
[67,22,19,2],
[67,22,19,3],
[67,22,19,4],
[67,22,19,5],
[67,22,19,6],
[67,22,19,8],
[67,22,19,9],
[67,22,19,11],
[67,22,19,12],
[67,22,19,15],
[67,22,19,16],
[67,22,19,17],
[67,22,19,18],
[67,22,21,0],
[67,22,21,1],
[67,22,21,2],
[67,22,21,4],
[67,22,21,9],
[67,22,21,11],
[67,22,21,12],
[67,22,21,13],
[67,22,21,14],
[67,22,21,15],
[67,22,21,16],
[67,22,21,17],
[67,22,21,18],
[67,22,21,19],
[67,23,3,0],
[67,23,3,1],
[67,23,3,2],
[67,23,4,0],
[67,23,4,1],
[67,23,4,2],
[67,23,4,3],
[67,23,5,0],
[67,23,5,1],
[67,23,5,2],
[67,23,5,4],
[67,23,6,0],
[67,23,6,1],
[67,23,6,2],
[67,23,6,4],
[67,23,8,0],
[67,23,8,1],
[67,23,8,2],
[67,23,8,4],
[67,23,9,0],
[67,23,9,1],
[67,23,9,2],
[67,23,9,3],
[67,23,9,4],
[67,23,9,5],
[67,23,9,6],
[67,23,9,8],
[67,23,10,0],
[67,23,10,1],
[67,23,10,2],
[67,23,10,3],
[67,23,10,4],
[67,23,10,5],
[67,23,10,6],
[67,23,10,8],
[67,23,10,9],
[67,23,11,0],
[67,23,11,1],
[67,23,11,2],
[67,23,11,3],
[67,23,11,4],
[67,23,11,5],
[67,23,11,6],
[67,23,11,8],
[67,23,11,9],
[67,23,11,10],
[67,23,12,0],
[67,23,12,1],
[67,23,12,2],
[67,23,12,3],
[67,23,12,4],
[67,23,12,5],
[67,23,12,6],
[67,23,12,8],
[67,23,12,9],
[67,23,12,10],
[67,23,13,0],
[67,23,13,1],
[67,23,13,2],
[67,23,13,3],
[67,23,13,4],
[67,23,13,5],
[67,23,13,6],
[67,23,13,8],
[67,23,13,9],
[67,23,13,10],
[67,23,13,11],
[67,23,13,12],
[67,23,14,0],
[67,23,14,1],
[67,23,14,2],
[67,23,14,3],
[67,23,14,4],
[67,23,14,5],
[67,23,14,6],
[67,23,14,8],
[67,23,14,9],
[67,23,14,10],
[67,23,14,11],
[67,23,14,12],
[67,23,15,0],
[67,23,15,1],
[67,23,15,2],
[67,23,15,3],
[67,23,15,4],
[67,23,15,5],
[67,23,15,6],
[67,23,15,8],
[67,23,15,10],
[67,23,15,11],
[67,23,15,12],
[67,23,15,13],
[67,23,15,14],
[67,23,16,0],
[67,23,16,1],
[67,23,16,2],
[67,23,16,3],
[67,23,16,4],
[67,23,16,5],
[67,23,16,6],
[67,23,16,8],
[67,23,16,9],
[67,23,16,10],
[67,23,16,11],
[67,23,16,12],
[67,23,16,13],
[67,23,16,14],
[67,23,16,15],
[67,23,17,0],
[67,23,17,1],
[67,23,17,2],
[67,23,17,3],
[67,23,17,4],
[67,23,17,5],
[67,23,17,6],
[67,23,17,8],
[67,23,17,9],
[67,23,17,10],
[67,23,17,11],
[67,23,17,12],
[67,23,17,13],
[67,23,17,14],
[67,23,17,15],
[67,23,17,16],
[67,23,18,0],
[67,23,18,1],
[67,23,18,2],
[67,23,18,3],
[67,23,18,4],
[67,23,18,5],
[67,23,18,6],
[67,23,18,8],
[67,23,18,10],
[67,23,18,11],
[67,23,18,12],
[67,23,18,13],
[67,23,18,14],
[67,23,18,16],
[67,23,18,17],
[67,23,19,0],
[67,23,19,1],
[67,23,19,2],
[67,23,19,3],
[67,23,19,4],
[67,23,19,5],
[67,23,19,6],
[67,23,19,8],
[67,23,19,9],
[67,23,19,10],
[67,23,19,11],
[67,23,19,12],
[67,23,19,15],
[67,23,19,16],
[67,23,19,17],
[67,23,19,18],
[67,23,20,0],
[67,23,20,1],
[67,23,20,2],
[67,23,20,3],
[67,23,20,4],
[67,23,20,5],
[67,23,20,6],
[67,23,20,8],
[67,23,20,9],
[67,23,20,11],
[67,23,20,12],
[67,23,20,13],
[67,23,20,14],
[67,23,20,15],
[67,23,20,16],
[67,23,20,17],
[67,23,20,18],
[67,23,20,19],
[67,23,21,0],
[67,23,21,1],
[67,23,21,2],
[67,23,21,4],
[67,23,21,9],
[67,23,21,10],
[67,23,21,11],
[67,23,21,12],
[67,23,21,13],
[67,23,21,14],
[67,23,21,15],
[67,23,21,16],
[67,23,21,17],
[67,23,21,18],
[67,23,21,19],
[67,23,21,20],
[67,23,22,0],
[67,23,22,1],
[67,23,22,2],
[67,23,22,3],
[67,23,22,4],
[67,23,22,5],
[67,23,22,6],
[67,23,22,8],
[67,23,22,9],
[67,23,22,11],
[67,23,22,12],
[67,23,22,13],
[67,23,22,14],
[67,23,22,15],
[67,23,22,16],
[67,23,22,17],
[67,23,22,18],
[67,23,22,19],
[67,23,22,21],
[67,24,3,0],
[67,24,3,1],
[67,24,3,2],
[67,24,4,0],
[67,24,4,1],
[67,24,4,2],
[67,24,4,3],
[67,24,5,0],
[67,24,5,1],
[67,24,5,2],
[67,24,5,4],
[67,24,6,0],
[67,24,6,1],
[67,24,6,2],
[67,24,6,4],
[67,24,8,0],
[67,24,8,1],
[67,24,8,2],
[67,24,8,4],
[67,24,9,0],
[67,24,9,1],
[67,24,9,2],
[67,24,9,3],
[67,24,9,4],
[67,24,9,5],
[67,24,9,6],
[67,24,9,8],
[67,24,10,0],
[67,24,10,1],
[67,24,10,2],
[67,24,10,3],
[67,24,10,4],
[67,24,10,5],
[67,24,10,6],
[67,24,10,8],
[67,24,10,9],
[67,24,11,0],
[67,24,11,1],
[67,24,11,2],
[67,24,11,3],
[67,24,11,4],
[67,24,11,5],
[67,24,11,6],
[67,24,11,8],
[67,24,11,9],
[67,24,11,10],
[67,24,12,0],
[67,24,12,1],
[67,24,12,2],
[67,24,12,3],
[67,24,12,4],
[67,24,12,5],
[67,24,12,6],
[67,24,12,8],
[67,24,12,9],
[67,24,12,10],
[67,24,13,0],
[67,24,13,1],
[67,24,13,2],
[67,24,13,3],
[67,24,13,4],
[67,24,13,5],
[67,24,13,6],
[67,24,13,8],
[67,24,13,9],
[67,24,13,10],
[67,24,13,11],
[67,24,13,12],
[67,24,14,0],
[67,24,14,1],
[67,24,14,2],
[67,24,14,3],
[67,24,14,4],
[67,24,14,5],
[67,24,14,6],
[67,24,14,8],
[67,24,14,9],
[67,24,14,10],
[67,24,14,11],
[67,24,14,12],
[67,24,15,0],
[67,24,15,1],
[67,24,15,2],
[67,24,15,3],
[67,24,15,4],
[67,24,15,5],
[67,24,15,6],
[67,24,15,8],
[67,24,15,10],
[67,24,15,11],
[67,24,15,12],
[67,24,15,13],
[67,24,15,14],
[67,24,17,0],
[67,24,17,1],
[67,24,17,2],
[67,24,17,3],
[67,24,17,4],
[67,24,17,5],
[67,24,17,6],
[67,24,17,8],
[67,24,17,9],
[67,24,17,10],
[67,24,17,11],
[67,24,17,12],
[67,24,17,13],
[67,24,17,14],
[67,24,17,15],
[67,24,18,0],
[67,24,18,1],
[67,24,18,2],
[67,24,18,3],
[67,24,18,4],
[67,24,18,5],
[67,24,18,6],
[67,24,18,8],
[67,24,18,10],
[67,24,18,11],
[67,24,18,12],
[67,24,18,13],
[67,24,18,14],
[67,24,18,17],
[67,24,19,0],
[67,24,19,1],
[67,24,19,2],
[67,24,19,3],
[67,24,19,4],
[67,24,19,5],
[67,24,19,6],
[67,24,19,8],
[67,24,19,9],
[67,24,19,10],
[67,24,19,11],
[67,24,19,12],
[67,24,19,15],
[67,24,19,17],
[67,24,19,18],
[67,24,20,0],
[67,24,20,1],
[67,24,20,2],
[67,24,20,3],
[67,24,20,4],
[67,24,20,5],
[67,24,20,6],
[67,24,20,8],
[67,24,20,9],
[67,24,20,11],
[67,24,20,12],
[67,24,20,13],
[67,24,20,14],
[67,24,20,15],
[67,24,20,17],
[67,24,20,18],
[67,24,20,19],
[67,24,21,0],
[67,24,21,1],
[67,24,21,2],
[67,24,21,4],
[67,24,21,9],
[67,24,21,10],
[67,24,21,11],
[67,24,21,12],
[67,24,21,13],
[67,24,21,14],
[67,24,21,15],
[67,24,21,17],
[67,24,21,18],
[67,24,21,19],
[67,24,21,20],
[67,24,22,0],
[67,24,22,1],
[67,24,22,2],
[67,24,22,3],
[67,24,22,4],
[67,24,22,5],
[67,24,22,6],
[67,24,22,8],
[67,24,22,9],
[67,24,22,11],
[67,24,22,12],
[67,24,22,13],
[67,24,22,14],
[67,24,22,15],
[67,24,22,17],
[67,24,22,18],
[67,24,22,19],
[67,24,22,21],
[67,24,23,0],
[67,24,23,1],
[67,24,23,2],
[67,24,23,3],
[67,24,23,4],
[67,24,23,5],
[67,24,23,6],
[67,24,23,8],
[67,24,23,9],
[67,24,23,10],
[67,24,23,11],
[67,24,23,12],
[67,24,23,13],
[67,24,23,14],
[67,24,23,15],
[67,24,23,17],
[67,24,23,18],
[67,24,23,19],
[67,24,23,20],
[67,24,23,21],
[67,24,23,22],
[67,26,3,0],
[67,26,3,1],
[67,26,3,2],
[67,26,4,0],
[67,26,4,1],
[67,26,4,2],
[67,26,4,3],
[67,26,5,0],
[67,26,5,1],
[67,26,5,2],
[67,26,5,4],
[67,26,6,0],
[67,26,6,1],
[67,26,6,2],
[67,26,6,4],
[67,26,8,0],
[67,26,8,1],
[67,26,8,2],
[67,26,8,4],
[67,26,10,0],
[67,26,10,1],
[67,26,10,2],
[67,26,10,3],
[67,26,10,4],
[67,26,10,5],
[67,26,10,6],
[67,26,10,8],
[67,26,11,0],
[67,26,11,1],
[67,26,11,2],
[67,26,11,3],
[67,26,11,4],
[67,26,11,5],
[67,26,11,6],
[67,26,11,8],
[67,26,11,10],
[67,26,12,0],
[67,26,12,1],
[67,26,12,2],
[67,26,12,3],
[67,26,12,4],
[67,26,12,5],
[67,26,12,6],
[67,26,12,8],
[67,26,12,10],
[67,26,13,0],
[67,26,13,1],
[67,26,13,2],
[67,26,13,3],
[67,26,13,4],
[67,26,13,5],
[67,26,13,6],
[67,26,13,8],
[67,26,13,10],
[67,26,13,11],
[67,26,13,12],
[67,26,14,0],
[67,26,14,1],
[67,26,14,2],
[67,26,14,3],
[67,26,14,4],
[67,26,14,5],
[67,26,14,6],
[67,26,14,8],
[67,26,14,10],
[67,26,14,11],
[67,26,14,12],
[67,26,16,0],
[67,26,16,1],
[67,26,16,2],
[67,26,16,3],
[67,26,16,4],
[67,26,16,5],
[67,26,16,6],
[67,26,16,8],
[67,26,16,10],
[67,26,16,11],
[67,26,16,12],
[67,26,16,13],
[67,26,16,14],
[67,26,17,0],
[67,26,17,1],
[67,26,17,2],
[67,26,17,3],
[67,26,17,4],
[67,26,17,5],
[67,26,17,6],
[67,26,17,8],
[67,26,17,10],
[67,26,17,11],
[67,26,17,12],
[67,26,17,13],
[67,26,17,14],
[67,26,17,16],
[67,26,19,0],
[67,26,19,1],
[67,26,19,2],
[67,26,19,3],
[67,26,19,4],
[67,26,19,5],
[67,26,19,6],
[67,26,19,8],
[67,26,19,10],
[67,26,19,11],
[67,26,19,12],
[67,26,19,16],
[67,26,19,17],
[67,26,20,0],
[67,26,20,1],
[67,26,20,2],
[67,26,20,3],
[67,26,20,4],
[67,26,20,5],
[67,26,20,6],
[67,26,20,8],
[67,26,20,11],
[67,26,20,12],
[67,26,20,13],
[67,26,20,14],
[67,26,20,16],
[67,26,20,17],
[67,26,20,19],
[67,26,21,0],
[67,26,21,1],
[67,26,21,2],
[67,26,21,4],
[67,26,21,10],
[67,26,21,11],
[67,26,21,12],
[67,26,21,13],
[67,26,21,14],
[67,26,21,16],
[67,26,21,17],
[67,26,21,19],
[67,26,21,20],
[67,26,22,0],
[67,26,22,1],
[67,26,22,2],
[67,26,22,3],
[67,26,22,4],
[67,26,22,5],
[67,26,22,6],
[67,26,22,8],
[67,26,22,11],
[67,26,22,12],
[67,26,22,13],
[67,26,22,14],
[67,26,22,16],
[67,26,22,17],
[67,26,22,19],
[67,26,22,21],
[67,26,23,0],
[67,26,23,1],
[67,26,23,2],
[67,26,23,3],
[67,26,23,4],
[67,26,23,5],
[67,26,23,6],
[67,26,23,8],
[67,26,23,10],
[67,26,23,11],
[67,26,23,12],
[67,26,23,13],
[67,26,23,14],
[67,26,23,16],
[67,26,23,17],
[67,26,23,19],
[67,26,23,20],
[67,26,23,21],
[67,26,24,0],
[67,26,24,1],
[67,26,24,2],
[67,26,24,3],
[67,26,24,4],
[67,26,24,5],
[67,26,24,6],
[67,26,24,8],
[67,26,24,10],
[67,26,24,11],
[67,26,24,12],
[67,26,24,13],
[67,26,24,14],
[67,26,24,17],
[67,26,24,19],
[67,26,24,20],
[67,26,24,21],
[67,26,24,23],
[67,27,3,0],
[67,27,3,1],
[67,27,3,2],
[67,27,4,0],
[67,27,4,1],
[67,27,4,2],
[67,27,4,3],
[67,27,5,0],
[67,27,5,1],
[67,27,5,2],
[67,27,5,4],
[67,27,6,0],
[67,27,6,1],
[67,27,6,2],
[67,27,6,4],
[67,27,8,0],
[67,27,8,1],
[67,27,8,2],
[67,27,8,4],
[67,27,9,0],
[67,27,9,1],
[67,27,9,2],
[67,27,9,3],
[67,27,9,4],
[67,27,9,5],
[67,27,9,6],
[67,27,9,8],
[67,27,11,0],
[67,27,11,1],
[67,27,11,2],
[67,27,11,3],
[67,27,11,4],
[67,27,11,5],
[67,27,11,6],
[67,27,11,8],
[67,27,11,9],
[67,27,12,0],
[67,27,12,1],
[67,27,12,2],
[67,27,12,3],
[67,27,12,4],
[67,27,12,5],
[67,27,12,6],
[67,27,12,8],
[67,27,12,9],
[67,27,13,0],
[67,27,13,1],
[67,27,13,2],
[67,27,13,3],
[67,27,13,4],
[67,27,13,5],
[67,27,13,6],
[67,27,13,8],
[67,27,13,9],
[67,27,13,11],
[67,27,13,12],
[67,27,14,0],
[67,27,14,1],
[67,27,14,2],
[67,27,14,3],
[67,27,14,4],
[67,27,14,5],
[67,27,14,6],
[67,27,14,8],
[67,27,14,9],
[67,27,14,11],
[67,27,14,12],
[67,27,15,0],
[67,27,15,1],
[67,27,15,2],
[67,27,15,3],
[67,27,15,4],
[67,27,15,5],
[67,27,15,6],
[67,27,15,8],
[67,27,15,11],
[67,27,15,12],
[67,27,15,13],
[67,27,15,14],
[67,27,16,0],
[67,27,16,1],
[67,27,16,2],
[67,27,16,3],
[67,27,16,4],
[67,27,16,5],
[67,27,16,6],
[67,27,16,8],
[67,27,16,9],
[67,27,16,11],
[67,27,16,12],
[67,27,16,13],
[67,27,16,14],
[67,27,16,15],
[67,27,17,0],
[67,27,17,1],
[67,27,17,2],
[67,27,17,3],
[67,27,17,4],
[67,27,17,5],
[67,27,17,6],
[67,27,17,8],
[67,27,17,9],
[67,27,17,11],
[67,27,17,12],
[67,27,17,13],
[67,27,17,14],
[67,27,17,15],
[67,27,17,16],
[67,27,18,0],
[67,27,18,1],
[67,27,18,2],
[67,27,18,3],
[67,27,18,4],
[67,27,18,5],
[67,27,18,6],
[67,27,18,8],
[67,27,18,11],
[67,27,18,12],
[67,27,18,13],
[67,27,18,14],
[67,27,18,16],
[67,27,18,17],
[67,27,19,0],
[67,27,19,1],
[67,27,19,2],
[67,27,19,3],
[67,27,19,4],
[67,27,19,5],
[67,27,19,6],
[67,27,19,8],
[67,27,19,9],
[67,27,19,11],
[67,27,19,12],
[67,27,19,15],
[67,27,19,16],
[67,27,19,17],
[67,27,19,18],
[67,27,21,0],
[67,27,21,1],
[67,27,21,2],
[67,27,21,4],
[67,27,21,9],
[67,27,21,11],
[67,27,21,12],
[67,27,21,13],
[67,27,21,14],
[67,27,21,15],
[67,27,21,16],
[67,27,21,17],
[67,27,21,18],
[67,27,21,19],
[67,27,23,0],
[67,27,23,1],
[67,27,23,2],
[67,27,23,3],
[67,27,23,4],
[67,27,23,5],
[67,27,23,6],
[67,27,23,8],
[67,27,23,9],
[67,27,23,11],
[67,27,23,12],
[67,27,23,13],
[67,27,23,14],
[67,27,23,15],
[67,27,23,16],
[67,27,23,17],
[67,27,23,18],
[67,27,23,19],
[67,27,24,0],
[67,27,24,1],
[67,27,24,2],
[67,27,24,3],
[67,27,24,4],
[67,27,24,5],
[67,27,24,6],
[67,27,24,8],
[67,27,24,9],
[67,27,24,11],
[67,27,24,12],
[67,27,24,13],
[67,27,24,14],
[67,27,24,15],
[67,27,24,17],
[67,27,24,18],
[67,27,24,19],
[67,27,24,23],
[67,27,26,0],
[67,27,26,1],
[67,27,26,2],
[67,27,26,3],
[67,27,26,4],
[67,27,26,5],
[67,27,26,6],
[67,27,26,8],
[67,27,26,11],
[67,27,26,12],
[67,27,26,13],
[67,27,26,14],
[67,27,26,16],
[67,27,26,17],
[67,27,26,19],
[67,27,26,21],
[67,27,26,23],
[67,27,26,24],
[67,29,3,0],
[67,29,3,1],
[67,29,3,2],
[67,29,4,0],
[67,29,4,1],
[67,29,4,2],
[67,29,4,3],
[67,29,5,0],
[67,29,5,1],
[67,29,5,2],
[67,29,5,4],
[67,29,6,0],
[67,29,6,1],
[67,29,6,2],
[67,29,6,4],
[67,29,8,0],
[67,29,8,1],
[67,29,8,2],
[67,29,8,4],
[67,29,9,0],
[67,29,9,1],
[67,29,9,2],
[67,29,9,3],
[67,29,9,4],
[67,29,9,5],
[67,29,9,6],
[67,29,9,8],
[67,29,10,0],
[67,29,10,1],
[67,29,10,2],
[67,29,10,3],
[67,29,10,4],
[67,29,10,5],
[67,29,10,6],
[67,29,10,8],
[67,29,10,9],
[67,29,13,0],
[67,29,13,1],
[67,29,13,2],
[67,29,13,3],
[67,29,13,4],
[67,29,13,5],
[67,29,13,6],
[67,29,13,8],
[67,29,13,9],
[67,29,13,10],
[67,29,14,0],
[67,29,14,1],
[67,29,14,2],
[67,29,14,3],
[67,29,14,4],
[67,29,14,5],
[67,29,14,6],
[67,29,14,8],
[67,29,14,9],
[67,29,14,10],
[67,29,15,0],
[67,29,15,1],
[67,29,15,2],
[67,29,15,3],
[67,29,15,4],
[67,29,15,5],
[67,29,15,6],
[67,29,15,8],
[67,29,15,10],
[67,29,15,13],
[67,29,15,14],
[67,29,16,0],
[67,29,16,1],
[67,29,16,2],
[67,29,16,3],
[67,29,16,4],
[67,29,16,5],
[67,29,16,6],
[67,29,16,8],
[67,29,16,9],
[67,29,16,10],
[67,29,16,13],
[67,29,16,14],
[67,29,16,15],
[67,29,17,0],
[67,29,17,1],
[67,29,17,2],
[67,29,17,3],
[67,29,17,4],
[67,29,17,5],
[67,29,17,6],
[67,29,17,8],
[67,29,17,9],
[67,29,17,10],
[67,29,17,13],
[67,29,17,14],
[67,29,17,15],
[67,29,17,16],
[67,29,18,0],
[67,29,18,1],
[67,29,18,2],
[67,29,18,3],
[67,29,18,4],
[67,29,18,5],
[67,29,18,6],
[67,29,18,8],
[67,29,18,10],
[67,29,18,13],
[67,29,18,14],
[67,29,18,16],
[67,29,18,17],
[67,29,19,0],
[67,29,19,1],
[67,29,19,2],
[67,29,19,3],
[67,29,19,4],
[67,29,19,5],
[67,29,19,6],
[67,29,19,8],
[67,29,19,9],
[67,29,19,10],
[67,29,19,15],
[67,29,19,16],
[67,29,19,17],
[67,29,19,18],
[67,29,20,0],
[67,29,20,1],
[67,29,20,2],
[67,29,20,3],
[67,29,20,4],
[67,29,20,5],
[67,29,20,6],
[67,29,20,8],
[67,29,20,9],
[67,29,20,13],
[67,29,20,14],
[67,29,20,15],
[67,29,20,16],
[67,29,20,17],
[67,29,20,18],
[67,29,20,19],
[67,29,21,0],
[67,29,21,1],
[67,29,21,2],
[67,29,21,4],
[67,29,21,9],
[67,29,21,10],
[67,29,21,13],
[67,29,21,14],
[67,29,21,15],
[67,29,21,16],
[67,29,21,17],
[67,29,21,18],
[67,29,21,19],
[67,29,21,20],
[67,29,22,0],
[67,29,22,1],
[67,29,22,2],
[67,29,22,3],
[67,29,22,4],
[67,29,22,5],
[67,29,22,6],
[67,29,22,8],
[67,29,22,9],
[67,29,22,13],
[67,29,22,14],
[67,29,22,15],
[67,29,22,16],
[67,29,22,17],
[67,29,22,18],
[67,29,22,19],
[67,29,22,21],
[67,29,23,0],
[67,29,23,1],
[67,29,23,2],
[67,29,23,3],
[67,29,23,4],
[67,29,23,5],
[67,29,23,6],
[67,29,23,8],
[67,29,23,9],
[67,29,23,10],
[67,29,23,13],
[67,29,23,14],
[67,29,23,15],
[67,29,23,16],
[67,29,23,17],
[67,29,23,18],
[67,29,23,20],
[67,29,23,21],
[67,29,23,22],
[67,29,24,0],
[67,29,24,1],
[67,29,24,2],
[67,29,24,3],
[67,29,24,4],
[67,29,24,5],
[67,29,24,6],
[67,29,24,8],
[67,29,24,9],
[67,29,24,10],
[67,29,24,13],
[67,29,24,14],
[67,29,24,15],
[67,29,24,17],
[67,29,24,18],
[67,29,24,20],
[67,29,24,21],
[67,29,24,22],
[67,29,24,23],
[67,29,26,0],
[67,29,26,1],
[67,29,26,2],
[67,29,26,3],
[67,29,26,4],
[67,29,26,5],
[67,29,26,6],
[67,29,26,8],
[67,29,26,10],
[67,29,26,13],
[67,29,26,14],
[67,29,26,16],
[67,29,26,17],
[67,29,26,19],
[67,29,26,20],
[67,29,26,21],
[67,29,26,22],
[67,29,26,23],
[67,29,26,24],
[67,29,27,0],
[67,29,27,1],
[67,29,27,2],
[67,29,27,3],
[67,29,27,4],
[67,29,27,5],
[67,29,27,6],
[67,29,27,8],
[67,29,27,9],
[67,29,27,13],
[67,29,27,15],
[67,29,27,16],
[67,29,27,17],
[67,29,27,18],
[67,29,27,19],
[67,29,27,21],
[67,29,27,23],
[67,29,27,24],
[67,29,27,26],
[67,30,3,0],
[67,30,3,1],
[67,30,3,2],
[67,30,4,0],
[67,30,4,1],
[67,30,4,2],
[67,30,4,3],
[67,30,5,0],
[67,30,5,1],
[67,30,5,2],
[67,30,5,4],
[67,30,6,0],
[67,30,6,1],
[67,30,6,2],
[67,30,6,4],
[67,30,8,0],
[67,30,8,1],
[67,30,8,2],
[67,30,8,4],
[67,30,9,0],
[67,30,9,1],
[67,30,9,2],
[67,30,9,3],
[67,30,9,4],
[67,30,9,5],
[67,30,9,6],
[67,30,9,8],
[67,30,10,0],
[67,30,10,1],
[67,30,10,2],
[67,30,10,3],
[67,30,10,4],
[67,30,10,5],
[67,30,10,6],
[67,30,10,8],
[67,30,10,9],
[67,30,11,0],
[67,30,11,1],
[67,30,11,2],
[67,30,11,3],
[67,30,11,4],
[67,30,11,5],
[67,30,11,6],
[67,30,11,8],
[67,30,11,9],
[67,30,11,10],
[67,30,12,0],
[67,30,12,1],
[67,30,12,2],
[67,30,12,3],
[67,30,12,4],
[67,30,12,5],
[67,30,12,6],
[67,30,12,8],
[67,30,12,9],
[67,30,12,10],
[67,30,13,0],
[67,30,13,1],
[67,30,13,2],
[67,30,13,3],
[67,30,13,4],
[67,30,13,5],
[67,30,13,6],
[67,30,13,8],
[67,30,13,9],
[67,30,13,10],
[67,30,13,11],
[67,30,13,12],
[67,30,14,0],
[67,30,14,1],
[67,30,14,2],
[67,30,14,3],
[67,30,14,4],
[67,30,14,5],
[67,30,14,6],
[67,30,14,8],
[67,30,14,9],
[67,30,14,10],
[67,30,14,11],
[67,30,14,12],
[67,30,15,0],
[67,30,15,1],
[67,30,15,2],
[67,30,15,3],
[67,30,15,4],
[67,30,15,5],
[67,30,15,6],
[67,30,15,8],
[67,30,15,10],
[67,30,15,11],
[67,30,15,12],
[67,30,15,13],
[67,30,15,14],
[67,30,16,0],
[67,30,16,1],
[67,30,16,2],
[67,30,16,3],
[67,30,16,4],
[67,30,16,5],
[67,30,16,6],
[67,30,16,8],
[67,30,16,9],
[67,30,16,10],
[67,30,16,11],
[67,30,16,12],
[67,30,16,13],
[67,30,16,14],
[67,30,16,15],
[67,30,17,0],
[67,30,17,1],
[67,30,17,2],
[67,30,17,3],
[67,30,17,4],
[67,30,17,5],
[67,30,17,6],
[67,30,17,8],
[67,30,17,9],
[67,30,17,10],
[67,30,17,11],
[67,30,17,12],
[67,30,17,13],
[67,30,17,14],
[67,30,17,15],
[67,30,17,16],
[67,30,18,0],
[67,30,18,1],
[67,30,18,2],
[67,30,18,3],
[67,30,18,4],
[67,30,18,5],
[67,30,18,6],
[67,30,18,8],
[67,30,18,10],
[67,30,18,11],
[67,30,18,12],
[67,30,18,13],
[67,30,18,14],
[67,30,18,16],
[67,30,18,17],
[67,30,19,0],
[67,30,19,1],
[67,30,19,2],
[67,30,19,3],
[67,30,19,4],
[67,30,19,5],
[67,30,19,6],
[67,30,19,8],
[67,30,19,9],
[67,30,19,10],
[67,30,19,11],
[67,30,19,12],
[67,30,19,15],
[67,30,19,16],
[67,30,19,17],
[67,30,19,18],
[67,30,20,0],
[67,30,20,1],
[67,30,20,2],
[67,30,20,3],
[67,30,20,4],
[67,30,20,5],
[67,30,20,6],
[67,30,20,8],
[67,30,20,9],
[67,30,20,11],
[67,30,20,12],
[67,30,20,13],
[67,30,20,14],
[67,30,20,15],
[67,30,20,16],
[67,30,20,17],
[67,30,20,18],
[67,30,20,19],
[67,30,21,0],
[67,30,21,1],
[67,30,21,2],
[67,30,21,4],
[67,30,21,9],
[67,30,21,10],
[67,30,21,11],
[67,30,21,12],
[67,30,21,13],
[67,30,21,14],
[67,30,21,15],
[67,30,21,16],
[67,30,21,17],
[67,30,21,18],
[67,30,21,19],
[67,30,22,0],
[67,30,22,1],
[67,30,22,2],
[67,30,22,3],
[67,30,22,4],
[67,30,22,5],
[67,30,22,6],
[67,30,22,8],
[67,30,22,9],
[67,30,22,11],
[67,30,22,12],
[67,30,22,13],
[67,30,22,14],
[67,30,22,15],
[67,30,22,16],
[67,30,22,17],
[67,30,22,18],
[67,30,22,21],
[67,30,24,0],
[67,30,24,1],
[67,30,24,2],
[67,30,24,3],
[67,30,24,4],
[67,30,24,5],
[67,30,24,6],
[67,30,24,8],
[67,30,24,9],
[67,30,24,10],
[67,30,24,11],
[67,30,24,12],
[67,30,24,13],
[67,30,24,14],
[67,30,24,15],
[67,30,24,17],
[67,30,24,19],
[67,30,24,20],
[67,30,24,21],
[67,30,24,22],
[67,30,26,0],
[67,30,26,1],
[67,30,26,2],
[67,30,26,3],
[67,30,26,4],
[67,30,26,5],
[67,30,26,6],
[67,30,26,8],
[67,30,26,10],
[67,30,26,11],
[67,30,26,12],
[67,30,26,13],
[67,30,26,16],
[67,30,26,17],
[67,30,26,19],
[67,30,26,20],
[67,30,26,21],
[67,30,26,22],
[67,30,26,24],
[67,30,27,0],
[67,30,27,1],
[67,30,27,2],
[67,30,27,3],
[67,30,27,4],
[67,30,27,5],
[67,30,27,6],
[67,30,27,8],
[67,30,27,9],
[67,30,27,11],
[67,30,27,14],
[67,30,27,15],
[67,30,27,16],
[67,30,27,17],
[67,30,27,18],
[67,30,27,19],
[67,30,27,21],
[67,30,27,24],
[67,30,27,26],
[67,30,29,0],
[67,30,29,1],
[67,30,29,2],
[67,30,29,3],
[67,30,29,4],
[67,30,29,5],
[67,30,29,6],
[67,30,29,10],
[67,30,29,13],
[67,30,29,14],
[67,30,29,15],
[67,30,29,16],
[67,30,29,17],
[67,30,29,18],
[67,30,29,19],
[67,30,29,20],
[67,30,29,21],
[67,30,29,22],
[67,30,29,24],
[67,31,3,0],
[67,31,3,1],
[67,31,3,2],
[67,31,4,0],
[67,31,4,1],
[67,31,4,2],
[67,31,4,3],
[67,31,5,0],
[67,31,5,1],
[67,31,5,2],
[67,31,5,4],
[67,31,6,0],
[67,31,6,1],
[67,31,6,2],
[67,31,6,4],
[67,31,8,0],
[67,31,8,1],
[67,31,8,2],
[67,31,8,4],
[67,31,9,0],
[67,31,9,1],
[67,31,9,2],
[67,31,9,3],
[67,31,9,4],
[67,31,9,5],
[67,31,9,6],
[67,31,9,8],
[67,31,10,0],
[67,31,10,1],
[67,31,10,2],
[67,31,10,3],
[67,31,10,4],
[67,31,10,5],
[67,31,10,6],
[67,31,10,8],
[67,31,10,9],
[67,31,11,0],
[67,31,11,1],
[67,31,11,2],
[67,31,11,3],
[67,31,11,4],
[67,31,11,5],
[67,31,11,6],
[67,31,11,8],
[67,31,11,9],
[67,31,11,10],
[67,31,12,0],
[67,31,12,1],
[67,31,12,2],
[67,31,12,3],
[67,31,12,4],
[67,31,12,5],
[67,31,12,6],
[67,31,12,8],
[67,31,12,9],
[67,31,12,10],
[67,31,13,0],
[67,31,13,1],
[67,31,13,2],
[67,31,13,3],
[67,31,13,4],
[67,31,13,5],
[67,31,13,6],
[67,31,13,8],
[67,31,13,9],
[67,31,13,10],
[67,31,13,11],
[67,31,13,12],
[67,31,14,0],
[67,31,14,1],
[67,31,14,2],
[67,31,14,3],
[67,31,14,4],
[67,31,14,5],
[67,31,14,6],
[67,31,14,8],
[67,31,14,9],
[67,31,14,10],
[67,31,14,11],
[67,31,14,12],
[67,31,15,0],
[67,31,15,1],
[67,31,15,2],
[67,31,15,3],
[67,31,15,4],
[67,31,15,5],
[67,31,15,6],
[67,31,15,8],
[67,31,15,10],
[67,31,15,11],
[67,31,15,12],
[67,31,15,13],
[67,31,15,14],
[67,31,16,0],
[67,31,16,1],
[67,31,16,2],
[67,31,16,3],
[67,31,16,4],
[67,31,16,5],
[67,31,16,6],
[67,31,16,8],
[67,31,16,9],
[67,31,16,10],
[67,31,16,11],
[67,31,16,12],
[67,31,16,13],
[67,31,16,14],
[67,31,16,15],
[67,31,17,0],
[67,31,17,1],
[67,31,17,2],
[67,31,17,3],
[67,31,17,4],
[67,31,17,5],
[67,31,17,6],
[67,31,17,8],
[67,31,17,9],
[67,31,17,10],
[67,31,17,11],
[67,31,17,12],
[67,31,17,13],
[67,31,17,14],
[67,31,17,15],
[67,31,17,16],
[67,31,18,0],
[67,31,18,1],
[67,31,18,2],
[67,31,18,3],
[67,31,18,4],
[67,31,18,5],
[67,31,18,6],
[67,31,18,8],
[67,31,18,10],
[67,31,18,11],
[67,31,18,12],
[67,31,18,13],
[67,31,18,14],
[67,31,18,16],
[67,31,18,17],
[67,31,19,0],
[67,31,19,1],
[67,31,19,2],
[67,31,19,3],
[67,31,19,4],
[67,31,19,5],
[67,31,19,6],
[67,31,19,8],
[67,31,19,9],
[67,31,19,10],
[67,31,19,11],
[67,31,19,12],
[67,31,19,15],
[67,31,19,16],
[67,31,19,17],
[67,31,19,18],
[67,31,20,0],
[67,31,20,1],
[67,31,20,2],
[67,31,20,3],
[67,31,20,4],
[67,31,20,5],
[67,31,20,6],
[67,31,20,8],
[67,31,20,9],
[67,31,20,11],
[67,31,20,12],
[67,31,20,13],
[67,31,20,14],
[67,31,20,15],
[67,31,20,16],
[67,31,20,17],
[67,31,20,18],
[67,31,20,19],
[67,31,21,0],
[67,31,21,1],
[67,31,21,2],
[67,31,21,4],
[67,31,21,9],
[67,31,21,10],
[67,31,21,11],
[67,31,21,12],
[67,31,21,13],
[67,31,21,14],
[67,31,21,15],
[67,31,21,16],
[67,31,21,17],
[67,31,21,18],
[67,31,21,20],
[67,31,22,0],
[67,31,22,1],
[67,31,22,2],
[67,31,22,3],
[67,31,22,4],
[67,31,22,5],
[67,31,22,6],
[67,31,22,8],
[67,31,22,9],
[67,31,22,11],
[67,31,22,12],
[67,31,22,13],
[67,31,22,14],
[67,31,22,15],
[67,31,22,16],
[67,31,22,17],
[67,31,22,19],
[67,31,22,21],
[67,31,24,0],
[67,31,24,1],
[67,31,24,2],
[67,31,24,3],
[67,31,24,4],
[67,31,24,5],
[67,31,24,6],
[67,31,24,8],
[67,31,24,9],
[67,31,24,10],
[67,31,24,11],
[67,31,24,12],
[67,31,24,13],
[67,31,24,14],
[67,31,24,15],
[67,31,24,18],
[67,31,24,19],
[67,31,24,20],
[67,31,24,21],
[67,31,24,22],
[67,31,26,0],
[67,31,26,1],
[67,31,26,2],
[67,31,26,3],
[67,31,26,4],
[67,31,26,5],
[67,31,26,6],
[67,31,26,8],
[67,31,26,10],
[67,31,26,11],
[67,31,26,14],
[67,31,26,16],
[67,31,26,17],
[67,31,26,19],
[67,31,26,20],
[67,31,26,21],
[67,31,26,22],
[67,31,26,24],
[67,31,27,0],
[67,31,27,1],
[67,31,27,2],
[67,31,27,3],
[67,31,27,4],
[67,31,27,5],
[67,31,27,6],
[67,31,27,8],
[67,31,27,9],
[67,31,27,12],
[67,31,27,13],
[67,31,27,14],
[67,31,27,15],
[67,31,27,16],
[67,31,27,17],
[67,31,27,18],
[67,31,27,19],
[67,31,27,21],
[67,31,27,24],
[67,31,29,0],
[67,31,29,1],
[67,31,29,2],
[67,31,29,3],
[67,31,29,4],
[67,31,29,5],
[67,31,29,8],
[67,31,29,9],
[67,31,29,10],
[67,31,29,13],
[67,31,29,14],
[67,31,29,15],
[67,31,29,16],
[67,31,29,17],
[67,31,29,18],
[67,31,29,19],
[67,31,29,20],
[67,31,29,21],
[67,31,29,22],
[67,31,29,24],
[67,32,3,0],
[67,32,3,1],
[67,32,3,2],
[67,32,4,0],
[67,32,4,1],
[67,32,4,2],
[67,32,4,3],
[67,32,5,0],
[67,32,5,1],
[67,32,5,2],
[67,32,5,4],
[67,32,6,0],
[67,32,6,1],
[67,32,6,2],
[67,32,6,4],
[67,32,8,0],
[67,32,8,1],
[67,32,8,2],
[67,32,8,4],
[67,32,9,0],
[67,32,9,1],
[67,32,9,2],
[67,32,9,3],
[67,32,9,4],
[67,32,9,5],
[67,32,9,6],
[67,32,9,8],
[67,32,10,0],
[67,32,10,1],
[67,32,10,2],
[67,32,10,3],
[67,32,10,4],
[67,32,10,5],
[67,32,10,6],
[67,32,10,8],
[67,32,10,9],
[67,32,13,0],
[67,32,13,1],
[67,32,13,2],
[67,32,13,3],
[67,32,13,4],
[67,32,13,5],
[67,32,13,6],
[67,32,13,8],
[67,32,13,9],
[67,32,13,10],
[67,32,14,0],
[67,32,14,1],
[67,32,14,2],
[67,32,14,3],
[67,32,14,4],
[67,32,14,5],
[67,32,14,6],
[67,32,14,8],
[67,32,14,9],
[67,32,14,10],
[67,32,15,0],
[67,32,15,1],
[67,32,15,2],
[67,32,15,3],
[67,32,15,4],
[67,32,15,5],
[67,32,15,6],
[67,32,15,8],
[67,32,15,10],
[67,32,15,13],
[67,32,15,14],
[67,32,16,0],
[67,32,16,1],
[67,32,16,2],
[67,32,16,3],
[67,32,16,4],
[67,32,16,5],
[67,32,16,6],
[67,32,16,8],
[67,32,16,9],
[67,32,16,10],
[67,32,16,13],
[67,32,16,14],
[67,32,16,15],
[67,32,17,0],
[67,32,17,1],
[67,32,17,2],
[67,32,17,3],
[67,32,17,4],
[67,32,17,5],
[67,32,17,6],
[67,32,17,8],
[67,32,17,9],
[67,32,17,10],
[67,32,17,13],
[67,32,17,14],
[67,32,17,15],
[67,32,17,16],
[67,32,18,0],
[67,32,18,1],
[67,32,18,2],
[67,32,18,3],
[67,32,18,4],
[67,32,18,5],
[67,32,18,6],
[67,32,18,8],
[67,32,18,10],
[67,32,18,13],
[67,32,18,14],
[67,32,18,16],
[67,32,18,17],
[67,32,19,0],
[67,32,19,1],
[67,32,19,2],
[67,32,19,3],
[67,32,19,4],
[67,32,19,5],
[67,32,19,6],
[67,32,19,8],
[67,32,19,9],
[67,32,19,10],
[67,32,19,15],
[67,32,19,16],
[67,32,19,17],
[67,32,19,18],
[67,32,20,0],
[67,32,20,1],
[67,32,20,2],
[67,32,20,3],
[67,32,20,4],
[67,32,20,5],
[67,32,20,6],
[67,32,20,8],
[67,32,20,9],
[67,32,20,13],
[67,32,20,14],
[67,32,20,15],
[67,32,20,16],
[67,32,20,17],
[67,32,20,18],
[67,32,21,0],
[67,32,21,1],
[67,32,21,2],
[67,32,21,4],
[67,32,21,9],
[67,32,21,10],
[67,32,21,13],
[67,32,21,14],
[67,32,21,15],
[67,32,21,16],
[67,32,21,17],
[67,32,21,19],
[67,32,21,20],
[67,32,22,0],
[67,32,22,1],
[67,32,22,2],
[67,32,22,3],
[67,32,22,4],
[67,32,22,5],
[67,32,22,6],
[67,32,22,8],
[67,32,22,9],
[67,32,22,13],
[67,32,22,14],
[67,32,22,15],
[67,32,22,18],
[67,32,22,19],
[67,32,22,21],
[67,32,23,0],
[67,32,23,1],
[67,32,23,2],
[67,32,23,3],
[67,32,23,4],
[67,32,23,5],
[67,32,23,6],
[67,32,23,8],
[67,32,23,9],
[67,32,23,10],
[67,32,23,13],
[67,32,23,14],
[67,32,23,16],
[67,32,23,17],
[67,32,23,18],
[67,32,23,19],
[67,32,23,20],
[67,32,23,21],
[67,32,23,22],
[67,32,24,0],
[67,32,24,1],
[67,32,24,2],
[67,32,24,3],
[67,32,24,4],
[67,32,24,5],
[67,32,24,6],
[67,32,24,8],
[67,32,24,9],
[67,32,24,10],
[67,32,24,13],
[67,32,24,14],
[67,32,24,17],
[67,32,24,18],
[67,32,24,19],
[67,32,24,20],
[67,32,24,21],
[67,32,24,22],
[67,32,24,23],
[67,32,26,0],
[67,32,26,1],
[67,32,26,2],
[67,32,26,3],
[67,32,26,4],
[67,32,26,5],
[67,32,26,6],
[67,32,26,8],
[67,32,26,13],
[67,32,26,14],
[67,32,26,16],
[67,32,26,17],
[67,32,26,19],
[67,32,26,20],
[67,32,26,21],
[67,32,26,22],
[67,32,26,23],
[67,32,26,24],
[67,32,27,0],
[67,32,27,1],
[67,32,27,2],
[67,32,27,3],
[67,32,27,4],
[67,32,27,5],
[67,32,27,6],
[67,32,27,13],
[67,32,27,14],
[67,32,27,15],
[67,32,27,16],
[67,32,27,17],
[67,32,27,18],
[67,32,27,19],
[67,32,27,21],
[67,32,27,23],
[67,32,27,24],
[67,32,30,0],
[67,32,30,1],
[67,32,30,2],
[67,32,30,5],
[67,32,30,6],
[67,32,30,8],
[67,32,30,9],
[67,32,30,10],
[67,32,30,13],
[67,32,30,14],
[67,32,30,15],
[67,32,30,16],
[67,32,30,17],
[67,32,30,18],
[67,32,30,19],
[67,32,30,20],
[67,32,30,21],
[67,32,31,3],
[67,32,31,4],
[67,32,31,5],
[67,32,31,6],
[67,32,31,8],
[67,32,31,9],
[67,32,31,10],
[67,32,31,13],
[67,32,31,14],
[67,32,31,15],
[67,32,31,16],
[67,32,31,17],
[67,32,31,18],
[67,32,31,19],
[67,32,31,20],
[67,33,3,0],
[67,33,3,1],
[67,33,3,2],
[67,33,4,0],
[67,33,4,1],
[67,33,4,2],
[67,33,4,3],
[67,33,5,0],
[67,33,5,1],
[67,33,5,2],
[67,33,5,4],
[67,33,6,0],
[67,33,6,1],
[67,33,6,2],
[67,33,6,4],
[67,33,8,0],
[67,33,8,1],
[67,33,8,2],
[67,33,8,4],
[67,33,9,0],
[67,33,9,1],
[67,33,9,2],
[67,33,9,3],
[67,33,9,4],
[67,33,9,5],
[67,33,9,6],
[67,33,9,8],
[67,33,10,0],
[67,33,10,1],
[67,33,10,2],
[67,33,10,3],
[67,33,10,4],
[67,33,10,5],
[67,33,10,6],
[67,33,10,8],
[67,33,10,9],
[67,33,13,0],
[67,33,13,1],
[67,33,13,2],
[67,33,13,3],
[67,33,13,4],
[67,33,13,5],
[67,33,13,6],
[67,33,13,8],
[67,33,13,9],
[67,33,13,10],
[67,33,14,0],
[67,33,14,1],
[67,33,14,2],
[67,33,14,3],
[67,33,14,4],
[67,33,14,5],
[67,33,14,6],
[67,33,14,8],
[67,33,14,9],
[67,33,14,10],
[67,33,15,0],
[67,33,15,1],
[67,33,15,2],
[67,33,15,3],
[67,33,15,4],
[67,33,15,5],
[67,33,15,6],
[67,33,15,8],
[67,33,15,10],
[67,33,15,13],
[67,33,15,14],
[67,33,16,0],
[67,33,16,1],
[67,33,16,2],
[67,33,16,3],
[67,33,16,4],
[67,33,16,5],
[67,33,16,6],
[67,33,16,8],
[67,33,16,9],
[67,33,16,10],
[67,33,16,13],
[67,33,16,14],
[67,33,16,15],
[67,33,17,0],
[67,33,17,1],
[67,33,17,2],
[67,33,17,3],
[67,33,17,4],
[67,33,17,5],
[67,33,17,6],
[67,33,17,8],
[67,33,17,9],
[67,33,17,10],
[67,33,17,13],
[67,33,17,14],
[67,33,17,15],
[67,33,17,16],
[67,33,18,0],
[67,33,18,1],
[67,33,18,2],
[67,33,18,3],
[67,33,18,4],
[67,33,18,5],
[67,33,18,6],
[67,33,18,8],
[67,33,18,10],
[67,33,18,13],
[67,33,18,14],
[67,33,18,16],
[67,33,18,17],
[67,33,19,0],
[67,33,19,1],
[67,33,19,2],
[67,33,19,3],
[67,33,19,4],
[67,33,19,5],
[67,33,19,6],
[67,33,19,8],
[67,33,19,9],
[67,33,19,10],
[67,33,19,15],
[67,33,19,16],
[67,33,19,17],
[67,33,19,18],
[67,33,20,0],
[67,33,20,1],
[67,33,20,2],
[67,33,20,3],
[67,33,20,4],
[67,33,20,5],
[67,33,20,6],
[67,33,20,8],
[67,33,20,9],
[67,33,20,13],
[67,33,20,14],
[67,33,20,15],
[67,33,20,16],
[67,33,20,17],
[67,33,20,19],
[67,33,21,0],
[67,33,21,1],
[67,33,21,2],
[67,33,21,4],
[67,33,21,9],
[67,33,21,10],
[67,33,21,13],
[67,33,21,14],
[67,33,21,15],
[67,33,21,18],
[67,33,21,19],
[67,33,21,20],
[67,33,22,0],
[67,33,22,1],
[67,33,22,2],
[67,33,22,3],
[67,33,22,4],
[67,33,22,5],
[67,33,22,6],
[67,33,22,8],
[67,33,22,9],
[67,33,22,13],
[67,33,22,14],
[67,33,22,16],
[67,33,22,17],
[67,33,22,18],
[67,33,22,19],
[67,33,22,21],
[67,33,23,0],
[67,33,23,1],
[67,33,23,2],
[67,33,23,3],
[67,33,23,4],
[67,33,23,5],
[67,33,23,6],
[67,33,23,8],
[67,33,23,9],
[67,33,23,10],
[67,33,23,13],
[67,33,23,15],
[67,33,23,16],
[67,33,23,17],
[67,33,23,18],
[67,33,23,19],
[67,33,23,20],
[67,33,23,21],
[67,33,23,22],
[67,33,24,0],
[67,33,24,1],
[67,33,24,2],
[67,33,24,3],
[67,33,24,4],
[67,33,24,5],
[67,33,24,6],
[67,33,24,8],
[67,33,24,9],
[67,33,24,10],
[67,33,24,13],
[67,33,24,15],
[67,33,24,17],
[67,33,24,18],
[67,33,24,19],
[67,33,24,20],
[67,33,24,21],
[67,33,24,22],
[67,33,24,23],
[67,33,26,0],
[67,33,26,1],
[67,33,26,2],
[67,33,26,3],
[67,33,26,4],
[67,33,26,5],
[67,33,26,6],
[67,33,26,10],
[67,33,26,13],
[67,33,26,14],
[67,33,26,16],
[67,33,26,17],
[67,33,26,19],
[67,33,26,20],
[67,33,26,21],
[67,33,26,22],
[67,33,26,23],
[67,33,26,24],
[67,33,27,0],
[67,33,27,1],
[67,33,27,2],
[67,33,27,3],
[67,33,27,4],
[67,33,27,5],
[67,33,27,8],
[67,33,27,9],
[67,33,27,13],
[67,33,27,14],
[67,33,27,15],
[67,33,27,16],
[67,33,27,17],
[67,33,27,18],
[67,33,27,19],
[67,33,27,21],
[67,33,27,23],
[67,33,27,24],
[67,33,30,3],
[67,33,30,4],
[67,33,30,5],
[67,33,30,6],
[67,33,30,8],
[67,33,30,9],
[67,33,30,10],
[67,33,30,13],
[67,33,30,14],
[67,33,30,15],
[67,33,30,16],
[67,33,30,17],
[67,33,30,18],
[67,33,30,19],
[67,33,30,20],
[67,33,31,0],
[67,33,31,1],
[67,33,31,2],
[67,33,31,3],
[67,33,31,4],
[67,33,31,5],
[67,33,31,6],
[67,33,31,8],
[67,33,31,9],
[67,33,31,10],
[67,33,31,13],
[67,33,31,14],
[67,33,31,15],
[67,33,31,16],
[67,33,31,17],
[67,33,31,18],
[67,33,31,19],
[67,35,3,0],
[67,35,3,1],
[67,35,3,2],
[67,35,4,0],
[67,35,4,1],
[67,35,4,2],
[67,35,4,3],
[67,35,5,0],
[67,35,5,1],
[67,35,5,2],
[67,35,5,4],
[67,35,6,0],
[67,35,6,1],
[67,35,6,2],
[67,35,6,4],
[67,35,8,0],
[67,35,8,1],
[67,35,8,2],
[67,35,8,4],
[67,35,10,0],
[67,35,10,1],
[67,35,10,2],
[67,35,10,3],
[67,35,10,4],
[67,35,10,5],
[67,35,10,6],
[67,35,10,8],
[67,35,11,0],
[67,35,11,1],
[67,35,11,2],
[67,35,11,3],
[67,35,11,4],
[67,35,11,5],
[67,35,11,6],
[67,35,11,8],
[67,35,11,10],
[67,35,12,0],
[67,35,12,1],
[67,35,12,2],
[67,35,12,3],
[67,35,12,4],
[67,35,12,5],
[67,35,12,6],
[67,35,12,8],
[67,35,12,10],
[67,35,13,0],
[67,35,13,1],
[67,35,13,2],
[67,35,13,3],
[67,35,13,4],
[67,35,13,5],
[67,35,13,6],
[67,35,13,8],
[67,35,13,10],
[67,35,13,11],
[67,35,13,12],
[67,35,14,0],
[67,35,14,1],
[67,35,14,2],
[67,35,14,3],
[67,35,14,4],
[67,35,14,5],
[67,35,14,6],
[67,35,14,8],
[67,35,14,10],
[67,35,14,11],
[67,35,14,12],
[67,35,16,0],
[67,35,16,1],
[67,35,16,2],
[67,35,16,3],
[67,35,16,4],
[67,35,16,5],
[67,35,16,6],
[67,35,16,8],
[67,35,16,10],
[67,35,16,11],
[67,35,16,12],
[67,35,16,13],
[67,35,16,14],
[67,35,17,0],
[67,35,17,1],
[67,35,17,2],
[67,35,17,3],
[67,35,17,4],
[67,35,17,5],
[67,35,17,6],
[67,35,17,8],
[67,35,17,10],
[67,35,17,11],
[67,35,17,12],
[67,35,17,13],
[67,35,17,14],
[67,35,17,16],
[67,35,19,0],
[67,35,19,1],
[67,35,19,2],
[67,35,19,3],
[67,35,19,4],
[67,35,19,5],
[67,35,19,6],
[67,35,19,8],
[67,35,19,10],
[67,35,19,11],
[67,35,19,12],
[67,35,20,0],
[67,35,20,1],
[67,35,20,2],
[67,35,20,3],
[67,35,20,4],
[67,35,20,5],
[67,35,20,6],
[67,35,20,8],
[67,35,20,11],
[67,35,20,12],
[67,35,20,13],
[67,35,20,14],
[67,35,20,16],
[67,35,20,17],
[67,35,20,19],
[67,35,21,0],
[67,35,21,1],
[67,35,21,2],
[67,35,21,4],
[67,35,21,10],
[67,35,21,11],
[67,35,21,12],
[67,35,21,13],
[67,35,21,16],
[67,35,21,17],
[67,35,21,19],
[67,35,21,20],
[67,35,22,0],
[67,35,22,1],
[67,35,22,2],
[67,35,22,3],
[67,35,22,4],
[67,35,22,5],
[67,35,22,6],
[67,35,22,8],
[67,35,22,11],
[67,35,22,14],
[67,35,22,16],
[67,35,22,17],
[67,35,22,19],
[67,35,22,21],
[67,35,23,0],
[67,35,23,1],
[67,35,23,2],
[67,35,23,3],
[67,35,23,4],
[67,35,23,5],
[67,35,23,6],
[67,35,23,8],
[67,35,23,12],
[67,35,23,13],
[67,35,23,14],
[67,35,23,16],
[67,35,23,17],
[67,35,23,19],
[67,35,23,20],
[67,35,23,21],
[67,35,23,22],
[67,35,24,0],
[67,35,24,1],
[67,35,24,2],
[67,35,24,3],
[67,35,24,4],
[67,35,24,5],
[67,35,24,6],
[67,35,24,8],
[67,35,24,12],
[67,35,24,13],
[67,35,24,14],
[67,35,24,17],
[67,35,24,19],
[67,35,24,20],
[67,35,24,21],
[67,35,24,22],
[67,35,24,23],
[67,35,27,0],
[67,35,27,1],
[67,35,27,2],
[67,35,27,5],
[67,35,27,6],
[67,35,27,8],
[67,35,27,11],
[67,35,27,12],
[67,35,27,13],
[67,35,27,14],
[67,35,27,16],
[67,35,27,17],
[67,35,27,19],
[67,35,27,21],
[67,35,29,0],
[67,35,29,1],
[67,35,29,2],
[67,35,29,3],
[67,35,29,4],
[67,35,29,5],
[67,35,29,6],
[67,35,29,8],
[67,35,29,10],
[67,35,29,13],
[67,35,29,14],
[67,35,29,16],
[67,35,29,17],
[67,35,29,19],
[67,35,30,0],
[67,35,30,1],
[67,35,30,2],
[67,35,30,3],
[67,35,30,4],
[67,35,30,5],
[67,35,30,6],
[67,35,30,8],
[67,35,30,10],
[67,35,30,11],
[67,35,30,12],
[67,35,30,13],
[67,35,30,14],
[67,35,30,16],
[67,35,30,17],
[67,35,31,0],
[67,35,31,1],
[67,35,31,2],
[67,35,31,3],
[67,35,31,4],
[67,35,31,5],
[67,35,31,6],
[67,35,31,8],
[67,35,31,10],
[67,35,31,11],
[67,35,31,12],
[67,35,31,13],
[67,35,31,14],
[67,35,31,16],
[67,35,31,17],
[67,35,32,0],
[67,35,32,1],
[67,35,32,2],
[67,35,32,3],
[67,35,32,4],
[67,35,32,5],
[67,35,32,6],
[67,35,32,8],
[67,35,32,10],
[67,35,32,13],
[67,35,32,14],
[67,35,33,0],
[67,35,33,1],
[67,35,33,2],
[67,35,33,3],
[67,35,33,4],
[67,35,33,5],
[67,35,33,6],
[67,35,33,8],
[67,35,33,10],
[67,35,33,13],
[67,35,33,14],
[67,36,3,0],
[67,36,3,1],
[67,36,3,2],
[67,36,4,0],
[67,36,4,1],
[67,36,4,2],
[67,36,4,3],
[67,36,5,0],
[67,36,5,1],
[67,36,5,2],
[67,36,5,4],
[67,36,6,0],
[67,36,6,1],
[67,36,6,2],
[67,36,6,4],
[67,36,8,0],
[67,36,8,1],
[67,36,8,2],
[67,36,8,4],
[67,36,9,0],
[67,36,9,1],
[67,36,9,2],
[67,36,9,3],
[67,36,9,4],
[67,36,9,5],
[67,36,9,6],
[67,36,9,8],
[67,36,10,0],
[67,36,10,1],
[67,36,10,2],
[67,36,10,3],
[67,36,10,4],
[67,36,10,5],
[67,36,10,6],
[67,36,10,8],
[67,36,10,9],
[67,36,11,0],
[67,36,11,1],
[67,36,11,2],
[67,36,11,3],
[67,36,11,4],
[67,36,11,5],
[67,36,11,6],
[67,36,11,8],
[67,36,11,9],
[67,36,11,10],
[67,36,12,0],
[67,36,12,1],
[67,36,12,2],
[67,36,12,3],
[67,36,12,4],
[67,36,12,5],
[67,36,12,6],
[67,36,12,8],
[67,36,12,9],
[67,36,12,10],
[67,36,15,0],
[67,36,15,1],
[67,36,15,2],
[67,36,15,3],
[67,36,15,4],
[67,36,15,5],
[67,36,15,6],
[67,36,15,8],
[67,36,15,10],
[67,36,15,11],
[67,36,15,12],
[67,36,16,0],
[67,36,16,1],
[67,36,16,2],
[67,36,16,3],
[67,36,16,4],
[67,36,16,5],
[67,36,16,6],
[67,36,16,8],
[67,36,16,9],
[67,36,16,10],
[67,36,16,11],
[67,36,16,12],
[67,36,16,15],
[67,36,17,0],
[67,36,17,1],
[67,36,17,2],
[67,36,17,3],
[67,36,17,4],
[67,36,17,5],
[67,36,17,6],
[67,36,17,8],
[67,36,17,9],
[67,36,17,10],
[67,36,17,11],
[67,36,17,12],
[67,36,17,15],
[67,36,17,16],
[67,36,18,0],
[67,36,18,1],
[67,36,18,2],
[67,36,18,3],
[67,36,18,4],
[67,36,18,5],
[67,36,18,6],
[67,36,18,8],
[67,36,18,10],
[67,36,18,11],
[67,36,18,12],
[67,36,20,0],
[67,36,20,1],
[67,36,20,2],
[67,36,20,3],
[67,36,20,4],
[67,36,20,5],
[67,36,20,6],
[67,36,20,8],
[67,36,20,9],
[67,36,20,11],
[67,36,20,12],
[67,36,20,15],
[67,36,20,16],
[67,36,20,17],
[67,36,20,18],
[67,36,21,0],
[67,36,21,1],
[67,36,21,2],
[67,36,21,4],
[67,36,21,9],
[67,36,21,10],
[67,36,21,11],
[67,36,21,15],
[67,36,21,16],
[67,36,21,17],
[67,36,21,18],
[67,36,21,20],
[67,36,22,0],
[67,36,22,1],
[67,36,22,2],
[67,36,22,3],
[67,36,22,4],
[67,36,22,5],
[67,36,22,6],
[67,36,22,8],
[67,36,22,9],
[67,36,22,12],
[67,36,22,15],
[67,36,22,16],
[67,36,22,17],
[67,36,22,18],
[67,36,22,21],
[67,36,23,0],
[67,36,23,1],
[67,36,23,2],
[67,36,23,3],
[67,36,23,4],
[67,36,23,5],
[67,36,23,6],
[67,36,23,10],
[67,36,23,11],
[67,36,23,12],
[67,36,23,15],
[67,36,23,16],
[67,36,23,17],
[67,36,23,18],
[67,36,23,20],
[67,36,23,21],
[67,36,23,22],
[67,36,24,0],
[67,36,24,1],
[67,36,24,2],
[67,36,24,3],
[67,36,24,4],
[67,36,24,5],
[67,36,24,6],
[67,36,24,10],
[67,36,24,11],
[67,36,24,12],
[67,36,24,15],
[67,36,24,17],
[67,36,24,18],
[67,36,24,20],
[67,36,24,21],
[67,36,24,22],
[67,36,24,23],
[67,36,26,0],
[67,36,26,1],
[67,36,26,2],
[67,36,26,5],
[67,36,26,6],
[67,36,26,8],
[67,36,26,10],
[67,36,26,11],
[67,36,26,12],
[67,36,26,16],
[67,36,26,17],
[67,36,26,20],
[67,36,26,21],
[67,36,27,3],
[67,36,27,4],
[67,36,27,5],
[67,36,27,6],
[67,36,27,8],
[67,36,27,9],
[67,36,27,11],
[67,36,27,12],
[67,36,27,15],
[67,36,27,16],
[67,36,27,17],
[67,36,27,18],
[67,36,29,0],
[67,36,29,1],
[67,36,29,2],
[67,36,29,3],
[67,36,29,4],
[67,36,29,5],
[67,36,29,6],
[67,36,29,8],
[67,36,29,9],
[67,36,29,10],
[67,36,29,15],
[67,36,29,16],
[67,36,29,17],
[67,36,29,18],
[67,36,30,0],
[67,36,30,1],
[67,36,30,2],
[67,36,30,3],
[67,36,30,4],
[67,36,30,5],
[67,36,30,6],
[67,36,30,8],
[67,36,30,9],
[67,36,30,10],
[67,36,30,11],
[67,36,30,12],
[67,36,30,15],
[67,36,30,16],
[67,36,30,17],
[67,36,31,0],
[67,36,31,1],
[67,36,31,2],
[67,36,31,3],
[67,36,31,4],
[67,36,31,5],
[67,36,31,6],
[67,36,31,8],
[67,36,31,9],
[67,36,31,10],
[67,36,31,11],
[67,36,31,12],
[67,36,31,15],
[67,36,32,0],
[67,36,32,1],
[67,36,32,2],
[67,36,32,3],
[67,36,32,4],
[67,36,32,5],
[67,36,32,6],
[67,36,32,8],
[67,36,32,9],
[67,36,32,10],
[67,36,33,0],
[67,36,33,1],
[67,36,33,2],
[67,36,33,3],
[67,36,33,4],
[67,36,33,5],
[67,36,33,6],
[67,36,33,8],
[67,36,33,9],
[67,36,33,10],
[67,36,35,0],
[67,36,35,1],
[67,36,35,2],
[67,36,35,3],
[67,36,35,4],
[67,36,35,5],
[67,36,35,6],
[67,36,35,8],
[67,37,3,0],
[67,37,3,1],
[67,37,3,2],
[67,37,5,0],
[67,37,5,1],
[67,37,5,2],
[67,37,6,0],
[67,37,6,1],
[67,37,6,2],
[67,37,8,0],
[67,37,8,1],
[67,37,8,2],
[67,37,9,0],
[67,37,9,1],
[67,37,9,2],
[67,37,9,3],
[67,37,9,5],
[67,37,9,6],
[67,37,9,8],
[67,37,10,0],
[67,37,10,1],
[67,37,10,2],
[67,37,10,3],
[67,37,10,5],
[67,37,10,6],
[67,37,10,8],
[67,37,10,9],
[67,37,11,0],
[67,37,11,1],
[67,37,11,2],
[67,37,11,3],
[67,37,11,5],
[67,37,11,6],
[67,37,11,8],
[67,37,11,9],
[67,37,11,10],
[67,37,12,0],
[67,37,12,1],
[67,37,12,2],
[67,37,12,3],
[67,37,12,5],
[67,37,12,6],
[67,37,12,8],
[67,37,12,9],
[67,37,12,10],
[67,37,13,0],
[67,37,13,1],
[67,37,13,2],
[67,37,13,3],
[67,37,13,5],
[67,37,13,6],
[67,37,13,8],
[67,37,13,9],
[67,37,13,10],
[67,37,13,11],
[67,37,13,12],
[67,37,14,0],
[67,37,14,1],
[67,37,14,2],
[67,37,14,3],
[67,37,14,5],
[67,37,14,6],
[67,37,14,8],
[67,37,14,9],
[67,37,14,10],
[67,37,14,11],
[67,37,14,12],
[67,37,15,0],
[67,37,15,1],
[67,37,15,2],
[67,37,15,3],
[67,37,15,5],
[67,37,15,6],
[67,37,15,8],
[67,37,15,10],
[67,37,15,11],
[67,37,15,12],
[67,37,15,13],
[67,37,15,14],
[67,37,16,0],
[67,37,16,1],
[67,37,16,2],
[67,37,16,3],
[67,37,16,5],
[67,37,16,6],
[67,37,16,8],
[67,37,16,9],
[67,37,16,10],
[67,37,16,11],
[67,37,16,12],
[67,37,16,13],
[67,37,16,14],
[67,37,16,15],
[67,37,17,0],
[67,37,17,1],
[67,37,17,2],
[67,37,17,3],
[67,37,17,5],
[67,37,17,6],
[67,37,17,8],
[67,37,17,9],
[67,37,17,10],
[67,37,17,11],
[67,37,17,12],
[67,37,17,13],
[67,37,17,14],
[67,37,17,15],
[67,37,18,0],
[67,37,18,1],
[67,37,18,2],
[67,37,18,3],
[67,37,18,5],
[67,37,18,6],
[67,37,18,8],
[67,37,18,10],
[67,37,18,11],
[67,37,18,12],
[67,37,18,13],
[67,37,18,14],
[67,37,18,16],
[67,37,18,17],
[67,37,19,0],
[67,37,19,1],
[67,37,19,2],
[67,37,19,3],
[67,37,19,5],
[67,37,19,6],
[67,37,19,8],
[67,37,19,9],
[67,37,19,10],
[67,37,19,11],
[67,37,19,12],
[67,37,19,15],
[67,37,19,16],
[67,37,19,17],
[67,37,19,18],
[67,37,20,0],
[67,37,20,1],
[67,37,20,2],
[67,37,20,3],
[67,37,20,5],
[67,37,20,6],
[67,37,20,8],
[67,37,20,9],
[67,37,20,11],
[67,37,20,14],
[67,37,20,15],
[67,37,20,16],
[67,37,20,17],
[67,37,20,18],
[67,37,20,19],
[67,37,21,0],
[67,37,21,1],
[67,37,21,2],
[67,37,21,9],
[67,37,21,12],
[67,37,21,13],
[67,37,21,14],
[67,37,21,15],
[67,37,21,16],
[67,37,21,17],
[67,37,21,18],
[67,37,21,19],
[67,37,21,20],
[67,37,22,0],
[67,37,22,1],
[67,37,22,2],
[67,37,22,3],
[67,37,22,5],
[67,37,22,6],
[67,37,22,11],
[67,37,22,12],
[67,37,22,13],
[67,37,22,14],
[67,37,22,15],
[67,37,22,16],
[67,37,22,17],
[67,37,22,18],
[67,37,22,19],
[67,37,22,21],
[67,37,23,0],
[67,37,23,1],
[67,37,23,2],
[67,37,23,3],
[67,37,23,5],
[67,37,23,8],
[67,37,23,9],
[67,37,23,10],
[67,37,23,11],
[67,37,23,12],
[67,37,23,13],
[67,37,23,14],
[67,37,23,15],
[67,37,23,16],
[67,37,23,17],
[67,37,23,18],
[67,37,23,19],
[67,37,23,20],
[67,37,23,21],
[67,37,23,22],
[67,37,24,0],
[67,37,24,1],
[67,37,24,2],
[67,37,24,3],
[67,37,24,5],
[67,37,24,8],
[67,37,24,9],
[67,37,24,10],
[67,37,24,11],
[67,37,24,12],
[67,37,24,13],
[67,37,24,14],
[67,37,24,15],
[67,37,24,17],
[67,37,24,18],
[67,37,24,19],
[67,37,24,20],
[67,37,24,21],
[67,37,24,22],
[67,37,24,23],
[67,37,26,3],
[67,37,26,5],
[67,37,26,6],
[67,37,26,8],
[67,37,26,10],
[67,37,26,11],
[67,37,26,12],
[67,37,26,13],
[67,37,26,14],
[67,37,26,16],
[67,37,26,17],
[67,37,26,19],
[67,37,26,20],
[67,37,27,0],
[67,37,27,1],
[67,37,27,2],
[67,37,27,3],
[67,37,27,5],
[67,37,27,6],
[67,37,27,8],
[67,37,27,9],
[67,37,27,11],
[67,37,27,12],
[67,37,27,13],
[67,37,27,14],
[67,37,27,15],
[67,37,27,16],
[67,37,27,17],
[67,37,27,18],
[67,37,27,19],
[67,37,29,0],
[67,37,29,1],
[67,37,29,2],
[67,37,29,3],
[67,37,29,5],
[67,37,29,6],
[67,37,29,8],
[67,37,29,9],
[67,37,29,10],
[67,37,29,13],
[67,37,29,14],
[67,37,29,15],
[67,37,29,16],
[67,37,29,17],
[67,37,30,0],
[67,37,30,1],
[67,37,30,2],
[67,37,30,3],
[67,37,30,5],
[67,37,30,6],
[67,37,30,8],
[67,37,30,9],
[67,37,30,10],
[67,37,30,11],
[67,37,30,12],
[67,37,30,13],
[67,37,30,14],
[67,37,30,15],
[67,37,31,0],
[67,37,31,1],
[67,37,31,2],
[67,37,31,3],
[67,37,31,5],
[67,37,31,6],
[67,37,31,8],
[67,37,31,9],
[67,37,31,10],
[67,37,31,11],
[67,37,31,12],
[67,37,31,13],
[67,37,31,14],
[67,37,32,0],
[67,37,32,1],
[67,37,32,2],
[67,37,32,3],
[67,37,32,5],
[67,37,32,6],
[67,37,32,8],
[67,37,32,9],
[67,37,32,10],
[67,37,32,13],
[67,37,33,0],
[67,37,33,1],
[67,37,33,2],
[67,37,33,3],
[67,37,33,5],
[67,37,33,6],
[67,37,33,8],
[67,37,33,9],
[67,37,33,10],
[67,37,35,0],
[67,37,35,1],
[67,37,35,2],
[67,37,35,3],
[67,37,35,5],
[67,37,35,6],
[67,37,36,0],
[67,37,36,1],
[67,37,36,2],
[67,37,36,3],
[67,37,36,5],
[67,39,4,0],
[67,39,4,1],
[67,39,4,2],
[67,39,9,0],
[67,39,9,1],
[67,39,9,2],
[67,39,9,4],
[67,39,10,0],
[67,39,10,1],
[67,39,10,2],
[67,39,10,4],
[67,39,10,9],
[67,39,11,0],
[67,39,11,1],
[67,39,11,2],
[67,39,11,4],
[67,39,11,9],
[67,39,11,10],
[67,39,12,0],
[67,39,12,1],
[67,39,12,2],
[67,39,12,4],
[67,39,12,9],
[67,39,12,10],
[67,39,13,0],
[67,39,13,1],
[67,39,13,2],
[67,39,13,4],
[67,39,13,9],
[67,39,13,10],
[67,39,13,11],
[67,39,13,12],
[67,39,14,0],
[67,39,14,1],
[67,39,14,2],
[67,39,14,4],
[67,39,14,9],
[67,39,14,10],
[67,39,14,11],
[67,39,14,12],
[67,39,15,0],
[67,39,15,1],
[67,39,15,2],
[67,39,15,4],
[67,39,15,10],
[67,39,15,11],
[67,39,15,12],
[67,39,15,13],
[67,39,15,14],
[67,39,16,0],
[67,39,16,1],
[67,39,16,2],
[67,39,16,4],
[67,39,16,9],
[67,39,16,10],
[67,39,16,11],
[67,39,16,12],
[67,39,16,13],
[67,39,16,15],
[67,39,17,0],
[67,39,17,1],
[67,39,17,2],
[67,39,17,4],
[67,39,17,9],
[67,39,17,10],
[67,39,17,11],
[67,39,17,12],
[67,39,17,13],
[67,39,17,15],
[67,39,17,16],
[67,39,18,0],
[67,39,18,1],
[67,39,18,2],
[67,39,18,4],
[67,39,18,10],
[67,39,18,11],
[67,39,18,14],
[67,39,18,16],
[67,39,18,17],
[67,39,19,0],
[67,39,19,1],
[67,39,19,2],
[67,39,19,4],
[67,39,19,9],
[67,39,19,12],
[67,39,19,15],
[67,39,19,16],
[67,39,19,17],
[67,39,19,18],
[67,39,20,0],
[67,39,20,1],
[67,39,20,2],
[67,39,20,4],
[67,39,20,11],
[67,39,20,12],
[67,39,20,13],
[67,39,20,14],
[67,39,20,15],
[67,39,20,16],
[67,39,20,17],
[67,39,20,18],
[67,39,20,19],
[67,39,22,0],
[67,39,22,1],
[67,39,22,2],
[67,39,22,4],
[67,39,22,9],
[67,39,22,11],
[67,39,22,12],
[67,39,22,13],
[67,39,22,14],
[67,39,22,15],
[67,39,22,16],
[67,39,22,17],
[67,39,22,18],
[67,39,22,19],
[67,39,23,0],
[67,39,23,1],
[67,39,23,2],
[67,39,23,9],
[67,39,23,10],
[67,39,23,11],
[67,39,23,12],
[67,39,23,13],
[67,39,23,14],
[67,39,23,15],
[67,39,23,16],
[67,39,23,17],
[67,39,23,18],
[67,39,23,19],
[67,39,23,20],
[67,39,24,0],
[67,39,24,1],
[67,39,24,2],
[67,39,24,9],
[67,39,24,10],
[67,39,24,11],
[67,39,24,12],
[67,39,24,13],
[67,39,24,14],
[67,39,24,15],
[67,39,24,17],
[67,39,24,18],
[67,39,24,19],
[67,39,24,20],
[67,39,26,0],
[67,39,26,1],
[67,39,26,2],
[67,39,26,4],
[67,39,26,10],
[67,39,26,11],
[67,39,26,12],
[67,39,26,13],
[67,39,26,14],
[67,39,26,16],
[67,39,26,17],
[67,39,27,0],
[67,39,27,1],
[67,39,27,2],
[67,39,27,4],
[67,39,27,9],
[67,39,27,11],
[67,39,27,12],
[67,39,27,13],
[67,39,27,14],
[67,39,27,15],
[67,39,27,16],
[67,39,27,17],
[67,39,29,0],
[67,39,29,1],
[67,39,29,2],
[67,39,29,4],
[67,39,29,9],
[67,39,29,10],
[67,39,29,13],
[67,39,29,14],
[67,39,30,0],
[67,39,30,1],
[67,39,30,2],
[67,39,30,4],
[67,39,30,9],
[67,39,30,10],
[67,39,30,11],
[67,39,30,12],
[67,39,30,13],
[67,39,31,0],
[67,39,31,1],
[67,39,31,2],
[67,39,31,4],
[67,39,31,9],
[67,39,31,10],
[67,39,31,11],
[67,39,32,0],
[67,39,32,1],
[67,39,32,2],
[67,39,32,4],
[67,39,32,9],
[67,39,33,0],
[67,39,33,1],
[67,39,33,2],
[67,39,33,4],
[67,39,35,0],
[67,39,35,1],
[67,39,35,2],
[67,39,35,4],
[67,39,36,0],
[67,39,36,1],
[67,39,36,2],
[67,40,3,0],
[67,40,3,1],
[67,40,3,2],
[67,40,4,0],
[67,40,4,1],
[67,40,4,2],
[67,40,4,3],
[67,40,5,0],
[67,40,5,1],
[67,40,5,2],
[67,40,5,4],
[67,40,6,0],
[67,40,6,1],
[67,40,6,2],
[67,40,6,4],
[67,40,8,0],
[67,40,8,1],
[67,40,8,2],
[67,40,8,4],
[67,40,10,0],
[67,40,10,1],
[67,40,10,2],
[67,40,10,3],
[67,40,10,4],
[67,40,10,5],
[67,40,10,6],
[67,40,10,8],
[67,40,11,0],
[67,40,11,1],
[67,40,11,2],
[67,40,11,3],
[67,40,11,4],
[67,40,11,5],
[67,40,11,6],
[67,40,11,8],
[67,40,11,10],
[67,40,12,0],
[67,40,12,1],
[67,40,12,2],
[67,40,12,3],
[67,40,12,4],
[67,40,12,5],
[67,40,12,6],
[67,40,12,8],
[67,40,12,10],
[67,40,13,0],
[67,40,13,1],
[67,40,13,2],
[67,40,13,3],
[67,40,13,4],
[67,40,13,5],
[67,40,13,6],
[67,40,13,8],
[67,40,13,10],
[67,40,13,11],
[67,40,13,12],
[67,40,14,0],
[67,40,14,1],
[67,40,14,2],
[67,40,14,3],
[67,40,14,4],
[67,40,14,5],
[67,40,14,6],
[67,40,14,8],
[67,40,14,10],
[67,40,14,11],
[67,40,14,12],
[67,40,16,0],
[67,40,16,1],
[67,40,16,2],
[67,40,16,3],
[67,40,16,4],
[67,40,16,5],
[67,40,16,6],
[67,40,16,8],
[67,40,16,10],
[67,40,16,11],
[67,40,16,14],
[67,40,17,0],
[67,40,17,1],
[67,40,17,2],
[67,40,17,3],
[67,40,17,4],
[67,40,17,5],
[67,40,17,6],
[67,40,17,8],
[67,40,17,10],
[67,40,17,11],
[67,40,17,14],
[67,40,17,16],
[67,40,19,0],
[67,40,19,1],
[67,40,19,2],
[67,40,19,3],
[67,40,19,4],
[67,40,19,5],
[67,40,19,6],
[67,40,19,10],
[67,40,19,11],
[67,40,19,12],
[67,40,19,16],
[67,40,19,17],
[67,40,20,0],
[67,40,20,1],
[67,40,20,2],
[67,40,20,3],
[67,40,20,4],
[67,40,20,5],
[67,40,20,8],
[67,40,20,11],
[67,40,20,12],
[67,40,20,13],
[67,40,20,14],
[67,40,20,16],
[67,40,20,17],
[67,40,20,19],
[67,40,21,0],
[67,40,21,1],
[67,40,21,2],
[67,40,21,4],
[67,40,21,10],
[67,40,21,11],
[67,40,21,12],
[67,40,21,13],
[67,40,21,14],
[67,40,21,16],
[67,40,21,17],
[67,40,21,19],
[67,40,21,20],
[67,40,22,0],
[67,40,22,1],
[67,40,22,2],
[67,40,22,5],
[67,40,22,6],
[67,40,22,8],
[67,40,22,11],
[67,40,22,12],
[67,40,22,13],
[67,40,22,14],
[67,40,22,16],
[67,40,22,17],
[67,40,22,19],
[67,40,22,21],
[67,40,23,3],
[67,40,23,4],
[67,40,23,5],
[67,40,23,6],
[67,40,23,8],
[67,40,23,10],
[67,40,23,11],
[67,40,23,12],
[67,40,23,13],
[67,40,23,14],
[67,40,23,16],
[67,40,23,17],
[67,40,23,19],
[67,40,23,20],
[67,40,24,3],
[67,40,24,4],
[67,40,24,5],
[67,40,24,6],
[67,40,24,8],
[67,40,24,10],
[67,40,24,11],
[67,40,24,12],
[67,40,24,13],
[67,40,24,14],
[67,40,24,17],
[67,40,24,19],
[67,40,24,20],
[67,40,27,0],
[67,40,27,1],
[67,40,27,2],
[67,40,27,3],
[67,40,27,4],
[67,40,27,5],
[67,40,27,6],
[67,40,27,8],
[67,40,27,11],
[67,40,27,12],
[67,40,27,13],
[67,40,27,14],
[67,40,29,0],
[67,40,29,1],
[67,40,29,2],
[67,40,29,3],
[67,40,29,4],
[67,40,29,5],
[67,40,29,6],
[67,40,29,8],
[67,40,29,10],
[67,40,29,13],
[67,40,30,0],
[67,40,30,1],
[67,40,30,2],
[67,40,30,3],
[67,40,30,4],
[67,40,30,5],
[67,40,30,6],
[67,40,30,8],
[67,40,30,10],
[67,40,30,11],
[67,40,31,0],
[67,40,31,1],
[67,40,31,2],
[67,40,31,3],
[67,40,31,4],
[67,40,31,5],
[67,40,31,6],
[67,40,31,8],
[67,40,32,0],
[67,40,32,1],
[67,40,32,2],
[67,40,32,3],
[67,40,32,4],
[67,40,32,5],
[67,40,32,6],
[67,40,33,0],
[67,40,33,1],
[67,40,33,2],
[67,40,33,3],
[67,40,33,4],
[67,40,33,5],
[67,41,3,0],
[67,41,3,1],
[67,41,3,2],
[67,41,4,0],
[67,41,4,1],
[67,41,4,2],
[67,41,4,3],
[67,41,5,0],
[67,41,5,1],
[67,41,5,2],
[67,41,5,4],
[67,41,6,0],
[67,41,6,1],
[67,41,6,2],
[67,41,6,4],
[67,41,8,0],
[67,41,8,1],
[67,41,8,2],
[67,41,8,4],
[67,41,10,0],
[67,41,10,1],
[67,41,10,2],
[67,41,10,3],
[67,41,10,4],
[67,41,10,5],
[67,41,10,6],
[67,41,10,8],
[67,41,11,0],
[67,41,11,1],
[67,41,11,2],
[67,41,11,3],
[67,41,11,4],
[67,41,11,5],
[67,41,11,6],
[67,41,11,8],
[67,41,11,10],
[67,41,12,0],
[67,41,12,1],
[67,41,12,2],
[67,41,12,3],
[67,41,12,4],
[67,41,12,5],
[67,41,12,6],
[67,41,12,8],
[67,41,12,10],
[67,41,13,0],
[67,41,13,1],
[67,41,13,2],
[67,41,13,3],
[67,41,13,4],
[67,41,13,5],
[67,41,13,6],
[67,41,13,8],
[67,41,13,10],
[67,41,13,11],
[67,41,13,12],
[67,41,14,0],
[67,41,14,1],
[67,41,14,2],
[67,41,14,3],
[67,41,14,4],
[67,41,14,5],
[67,41,14,6],
[67,41,14,8],
[67,41,14,10],
[67,41,14,11],
[67,41,14,12],
[67,41,16,0],
[67,41,16,1],
[67,41,16,2],
[67,41,16,3],
[67,41,16,4],
[67,41,16,5],
[67,41,16,6],
[67,41,16,8],
[67,41,16,10],
[67,41,16,11],
[67,41,16,14],
[67,41,17,0],
[67,41,17,1],
[67,41,17,2],
[67,41,17,3],
[67,41,17,4],
[67,41,17,5],
[67,41,17,6],
[67,41,17,8],
[67,41,17,10],
[67,41,17,11],
[67,41,17,14],
[67,41,17,16],
[67,41,19,0],
[67,41,19,1],
[67,41,19,2],
[67,41,19,3],
[67,41,19,4],
[67,41,19,5],
[67,41,19,6],
[67,41,19,10],
[67,41,19,11],
[67,41,19,12],
[67,41,19,16],
[67,41,19,17],
[67,41,20,0],
[67,41,20,1],
[67,41,20,2],
[67,41,20,3],
[67,41,20,4],
[67,41,20,5],
[67,41,20,8],
[67,41,20,11],
[67,41,20,12],
[67,41,20,13],
[67,41,20,14],
[67,41,20,16],
[67,41,20,17],
[67,41,20,19],
[67,41,21,0],
[67,41,21,1],
[67,41,21,2],
[67,41,21,4],
[67,41,21,10],
[67,41,21,11],
[67,41,21,12],
[67,41,21,13],
[67,41,21,14],
[67,41,21,16],
[67,41,21,17],
[67,41,21,19],
[67,41,21,20],
[67,41,22,0],
[67,41,22,1],
[67,41,22,2],
[67,41,22,5],
[67,41,22,6],
[67,41,22,8],
[67,41,22,11],
[67,41,22,12],
[67,41,22,13],
[67,41,22,14],
[67,41,22,16],
[67,41,22,17],
[67,41,22,19],
[67,41,22,21],
[67,41,23,3],
[67,41,23,4],
[67,41,23,5],
[67,41,23,6],
[67,41,23,8],
[67,41,23,10],
[67,41,23,11],
[67,41,23,12],
[67,41,23,13],
[67,41,23,14],
[67,41,23,16],
[67,41,23,17],
[67,41,23,19],
[67,41,23,20],
[67,41,24,3],
[67,41,24,4],
[67,41,24,5],
[67,41,24,6],
[67,41,24,8],
[67,41,24,10],
[67,41,24,11],
[67,41,24,12],
[67,41,24,13],
[67,41,24,14],
[67,41,24,17],
[67,41,24,19],
[67,41,24,20],
[67,41,27,0],
[67,41,27,1],
[67,41,27,2],
[67,41,27,3],
[67,41,27,4],
[67,41,27,5],
[67,41,27,6],
[67,41,27,8],
[67,41,27,11],
[67,41,27,12],
[67,41,27,13],
[67,41,27,14],
[67,41,29,0],
[67,41,29,1],
[67,41,29,2],
[67,41,29,3],
[67,41,29,4],
[67,41,29,5],
[67,41,29,6],
[67,41,29,8],
[67,41,29,10],
[67,41,29,13],
[67,41,30,0],
[67,41,30,1],
[67,41,30,2],
[67,41,30,3],
[67,41,30,4],
[67,41,30,5],
[67,41,30,6],
[67,41,30,8],
[67,41,30,10],
[67,41,30,11],
[67,41,31,0],
[67,41,31,1],
[67,41,31,2],
[67,41,31,3],
[67,41,31,4],
[67,41,31,5],
[67,41,31,6],
[67,41,31,8],
[67,41,32,0],
[67,41,32,1],
[67,41,32,2],
[67,41,32,3],
[67,41,32,4],
[67,41,32,5],
[67,41,32,6],
[67,41,33,0],
[67,41,33,1],
[67,41,33,2],
[67,41,33,3],
[67,41,33,4],
[67,41,33,5],
[67,42,3,0],
[67,42,3,1],
[67,42,3,2],
[67,42,4,0],
[67,42,4,1],
[67,42,4,2],
[67,42,4,3],
[67,42,5,0],
[67,42,5,1],
[67,42,5,2],
[67,42,5,4],
[67,42,6,0],
[67,42,6,1],
[67,42,6,2],
[67,42,6,4],
[67,42,8,0],
[67,42,8,1],
[67,42,8,2],
[67,42,8,4],
[67,42,9,0],
[67,42,9,1],
[67,42,9,2],
[67,42,9,3],
[67,42,9,4],
[67,42,9,5],
[67,42,9,6],
[67,42,9,8],
[67,42,11,0],
[67,42,11,1],
[67,42,11,2],
[67,42,11,3],
[67,42,11,4],
[67,42,11,5],
[67,42,11,6],
[67,42,11,8],
[67,42,11,9],
[67,42,12,0],
[67,42,12,1],
[67,42,12,2],
[67,42,12,3],
[67,42,12,4],
[67,42,12,5],
[67,42,12,6],
[67,42,12,8],
[67,42,12,9],
[67,42,13,0],
[67,42,13,1],
[67,42,13,2],
[67,42,13,3],
[67,42,13,4],
[67,42,13,5],
[67,42,13,6],
[67,42,13,8],
[67,42,13,9],
[67,42,13,11],
[67,42,13,12],
[67,42,14,0],
[67,42,14,1],
[67,42,14,2],
[67,42,14,3],
[67,42,14,4],
[67,42,14,5],
[67,42,14,6],
[67,42,14,8],
[67,42,14,9],
[67,42,14,11],
[67,42,14,12],
[67,42,15,0],
[67,42,15,1],
[67,42,15,2],
[67,42,15,3],
[67,42,15,4],
[67,42,15,5],
[67,42,15,6],
[67,42,15,8],
[67,42,15,11],
[67,42,15,14],
[67,42,16,0],
[67,42,16,1],
[67,42,16,2],
[67,42,16,3],
[67,42,16,4],
[67,42,16,5],
[67,42,16,6],
[67,42,16,8],
[67,42,16,9],
[67,42,16,12],
[67,42,16,13],
[67,42,16,14],
[67,42,16,15],
[67,42,17,0],
[67,42,17,1],
[67,42,17,2],
[67,42,17,3],
[67,42,17,4],
[67,42,17,5],
[67,42,17,6],
[67,42,17,8],
[67,42,17,9],
[67,42,17,12],
[67,42,17,13],
[67,42,17,14],
[67,42,17,15],
[67,42,17,16],
[67,42,18,0],
[67,42,18,1],
[67,42,18,2],
[67,42,18,3],
[67,42,18,4],
[67,42,18,5],
[67,42,18,6],
[67,42,18,11],
[67,42,18,12],
[67,42,18,13],
[67,42,18,14],
[67,42,18,16],
[67,42,18,17],
[67,42,19,0],
[67,42,19,1],
[67,42,19,2],
[67,42,19,3],
[67,42,19,4],
[67,42,19,5],
[67,42,19,8],
[67,42,19,9],
[67,42,19,11],
[67,42,19,12],
[67,42,19,15],
[67,42,19,16],
[67,42,19,17],
[67,42,19,18],
[67,42,21,0],
[67,42,21,1],
[67,42,21,2],
[67,42,21,9],
[67,42,21,11],
[67,42,21,12],
[67,42,21,13],
[67,42,21,14],
[67,42,21,15],
[67,42,21,16],
[67,42,21,17],
[67,42,21,18],
[67,42,21,19],
[67,42,23,0],
[67,42,23,1],
[67,42,23,2],
[67,42,23,3],
[67,42,23,4],
[67,42,23,5],
[67,42,23,6],
[67,42,23,8],
[67,42,23,9],
[67,42,23,11],
[67,42,23,12],
[67,42,23,13],
[67,42,23,14],
[67,42,23,15],
[67,42,23,16],
[67,42,23,17],
[67,42,23,18],
[67,42,23,19],
[67,42,24,0],
[67,42,24,1],
[67,42,24,2],
[67,42,24,3],
[67,42,24,4],
[67,42,24,5],
[67,42,24,6],
[67,42,24,8],
[67,42,24,9],
[67,42,24,11],
[67,42,24,12],
[67,42,24,13],
[67,42,24,14],
[67,42,24,15],
[67,42,24,17],
[67,42,24,18],
[67,42,24,19],
[67,42,26,0],
[67,42,26,1],
[67,42,26,2],
[67,42,26,3],
[67,42,26,4],
[67,42,26,5],
[67,42,26,6],
[67,42,26,8],
[67,42,26,11],
[67,42,26,12],
[67,42,26,13],
[67,42,26,14],
[67,42,29,0],
[67,42,29,1],
[67,42,29,2],
[67,42,29,3],
[67,42,29,4],
[67,42,29,5],
[67,42,29,6],
[67,42,29,8],
[67,42,29,9],
[67,42,30,0],
[67,42,30,1],
[67,42,30,2],
[67,42,30,3],
[67,42,30,4],
[67,42,30,5],
[67,42,30,6],
[67,42,30,8],
[67,42,30,9],
[67,42,31,0],
[67,42,31,1],
[67,42,31,2],
[67,42,31,3],
[67,42,31,4],
[67,42,31,5],
[67,42,31,6],
[67,42,32,0],
[67,42,32,1],
[67,42,32,2],
[67,42,32,3],
[67,42,32,4],
[67,42,32,5],
[67,42,33,0],
[67,42,33,1],
[67,42,33,2],
[67,42,33,3],
[67,42,33,4],
[67,44,3,0],
[67,44,3,1],
[67,44,3,2],
[67,44,4,0],
[67,44,4,1],
[67,44,4,2],
[67,44,4,3],
[67,44,5,0],
[67,44,5,1],
[67,44,5,2],
[67,44,5,4],
[67,44,6,0],
[67,44,6,1],
[67,44,6,2],
[67,44,6,4],
[67,44,8,0],
[67,44,8,1],
[67,44,8,2],
[67,44,8,4],
[67,44,9,0],
[67,44,9,1],
[67,44,9,2],
[67,44,9,3],
[67,44,9,4],
[67,44,9,5],
[67,44,9,6],
[67,44,9,8],
[67,44,10,0],
[67,44,10,1],
[67,44,10,2],
[67,44,10,3],
[67,44,10,4],
[67,44,10,5],
[67,44,10,6],
[67,44,10,8],
[67,44,10,9],
[67,44,11,0],
[67,44,11,1],
[67,44,11,2],
[67,44,11,3],
[67,44,11,4],
[67,44,11,5],
[67,44,11,6],
[67,44,11,8],
[67,44,11,9],
[67,44,11,10],
[67,44,12,0],
[67,44,12,1],
[67,44,12,2],
[67,44,12,3],
[67,44,12,4],
[67,44,12,5],
[67,44,12,6],
[67,44,12,8],
[67,44,12,9],
[67,44,12,10],
[67,44,13,0],
[67,44,13,1],
[67,44,13,2],
[67,44,13,3],
[67,44,13,4],
[67,44,13,5],
[67,44,13,6],
[67,44,13,8],
[67,44,13,9],
[67,44,13,10],
[67,44,13,11],
[67,44,14,0],
[67,44,14,1],
[67,44,14,2],
[67,44,14,3],
[67,44,14,4],
[67,44,14,5],
[67,44,14,6],
[67,44,14,8],
[67,44,14,9],
[67,44,14,12],
[67,44,15,0],
[67,44,15,1],
[67,44,15,2],
[67,44,15,3],
[67,44,15,4],
[67,44,15,5],
[67,44,15,6],
[67,44,15,10],
[67,44,15,11],
[67,44,15,12],
[67,44,15,13],
[67,44,15,14],
[67,44,16,0],
[67,44,16,1],
[67,44,16,2],
[67,44,16,3],
[67,44,16,4],
[67,44,16,5],
[67,44,16,8],
[67,44,16,9],
[67,44,16,10],
[67,44,16,11],
[67,44,16,12],
[67,44,16,13],
[67,44,16,14],
[67,44,16,15],
[67,44,18,0],
[67,44,18,1],
[67,44,18,2],
[67,44,18,3],
[67,44,18,4],
[67,44,18,6],
[67,44,18,8],
[67,44,18,10],
[67,44,18,11],
[67,44,18,12],
[67,44,18,13],
[67,44,18,14],
[67,44,18,16],
[67,44,19,0],
[67,44,19,1],
[67,44,19,2],
[67,44,19,5],
[67,44,19,6],
[67,44,19,8],
[67,44,19,9],
[67,44,19,10],
[67,44,19,11],
[67,44,19,12],
[67,44,19,15],
[67,44,19,16],
[67,44,19,18],
[67,44,20,3],
[67,44,20,4],
[67,44,20,5],
[67,44,20,6],
[67,44,20,8],
[67,44,20,9],
[67,44,20,11],
[67,44,20,12],
[67,44,20,13],
[67,44,20,14],
[67,44,20,15],
[67,44,20,16],
[67,44,20,18],
[67,44,20,19],
[67,44,21,0],
[67,44,21,1],
[67,44,21,2],
[67,44,21,4],
[67,44,21,9],
[67,44,21,10],
[67,44,21,11],
[67,44,21,12],
[67,44,21,13],
[67,44,21,14],
[67,44,21,15],
[67,44,21,16],
[67,44,21,18],
[67,44,21,19],
[67,44,22,0],
[67,44,22,1],
[67,44,22,2],
[67,44,22,3],
[67,44,22,4],
[67,44,22,5],
[67,44,22,6],
[67,44,22,8],
[67,44,22,9],
[67,44,22,11],
[67,44,22,12],
[67,44,22,13],
[67,44,22,14],
[67,44,22,15],
[67,44,22,16],
[67,44,22,18],
[67,44,23,0],
[67,44,23,1],
[67,44,23,2],
[67,44,23,3],
[67,44,23,4],
[67,44,23,5],
[67,44,23,6],
[67,44,23,8],
[67,44,23,9],
[67,44,23,10],
[67,44,23,11],
[67,44,23,12],
[67,44,23,13],
[67,44,23,14],
[67,44,23,15],
[67,44,23,16],
[67,44,24,0],
[67,44,24,1],
[67,44,24,2],
[67,44,24,3],
[67,44,24,4],
[67,44,24,5],
[67,44,24,6],
[67,44,24,8],
[67,44,24,9],
[67,44,24,10],
[67,44,24,11],
[67,44,24,12],
[67,44,24,13],
[67,44,24,14],
[67,44,24,15],
[67,44,26,0],
[67,44,26,1],
[67,44,26,2],
[67,44,26,3],
[67,44,26,4],
[67,44,26,5],
[67,44,26,6],
[67,44,26,8],
[67,44,26,10],
[67,44,26,11],
[67,44,26,12],
[67,44,26,13],
[67,44,27,0],
[67,44,27,1],
[67,44,27,2],
[67,44,27,3],
[67,44,27,4],
[67,44,27,5],
[67,44,27,6],
[67,44,27,8],
[67,44,27,9],
[67,44,27,11],
[67,44,29,0],
[67,44,29,1],
[67,44,29,2],
[67,44,29,3],
[67,44,29,4],
[67,44,29,5],
[67,44,29,6],
[67,44,30,0],
[67,44,30,1],
[67,44,30,2],
[67,44,30,3],
[67,44,30,4],
[67,44,30,5],
[67,44,31,0],
[67,44,31,1],
[67,44,31,2],
[67,44,31,3],
[67,44,31,4],
[67,44,32,0],
[67,44,32,1],
[67,44,32,2],
[67,45,3,0],
[67,45,3,1],
[67,45,3,2],
[67,45,4,0],
[67,45,4,1],
[67,45,4,2],
[67,45,4,3],
[67,45,5,0],
[67,45,5,1],
[67,45,5,2],
[67,45,5,4],
[67,45,6,0],
[67,45,6,1],
[67,45,6,2],
[67,45,6,4],
[67,45,8,0],
[67,45,8,1],
[67,45,8,2],
[67,45,8,4],
[67,45,10,0],
[67,45,10,1],
[67,45,10,2],
[67,45,10,3],
[67,45,10,4],
[67,45,10,5],
[67,45,10,6],
[67,45,10,8],
[67,45,11,0],
[67,45,11,1],
[67,45,11,2],
[67,45,11,3],
[67,45,11,4],
[67,45,11,5],
[67,45,11,6],
[67,45,11,8],
[67,45,11,10],
[67,45,12,0],
[67,45,12,1],
[67,45,12,2],
[67,45,12,3],
[67,45,12,4],
[67,45,12,5],
[67,45,12,6],
[67,45,12,8],
[67,45,13,0],
[67,45,13,1],
[67,45,13,2],
[67,45,13,3],
[67,45,13,4],
[67,45,13,5],
[67,45,13,6],
[67,45,13,8],
[67,45,13,12],
[67,45,14,0],
[67,45,14,1],
[67,45,14,2],
[67,45,14,3],
[67,45,14,4],
[67,45,14,5],
[67,45,14,6],
[67,45,14,10],
[67,45,14,11],
[67,45,14,12],
[67,45,16,0],
[67,45,16,1],
[67,45,16,2],
[67,45,16,3],
[67,45,16,4],
[67,45,16,6],
[67,45,16,8],
[67,45,16,10],
[67,45,16,11],
[67,45,16,12],
[67,45,16,13],
[67,45,16,14],
[67,45,17,0],
[67,45,17,1],
[67,45,17,2],
[67,45,17,3],
[67,45,17,4],
[67,45,17,6],
[67,45,17,8],
[67,45,17,10],
[67,45,17,11],
[67,45,17,12],
[67,45,17,13],
[67,45,17,14],
[67,45,17,16],
[67,45,19,3],
[67,45,19,4],
[67,45,19,5],
[67,45,19,6],
[67,45,19,8],
[67,45,19,10],
[67,45,19,11],
[67,45,19,12],
[67,45,19,16],
[67,45,19,17],
[67,45,20,0],
[67,45,20,1],
[67,45,20,2],
[67,45,20,3],
[67,45,20,4],
[67,45,20,5],
[67,45,20,6],
[67,45,20,8],
[67,45,20,11],
[67,45,20,12],
[67,45,20,13],
[67,45,20,14],
[67,45,20,16],
[67,45,20,17],
[67,45,20,19],
[67,45,21,0],
[67,45,21,1],
[67,45,21,2],
[67,45,21,4],
[67,45,21,10],
[67,45,21,11],
[67,45,21,12],
[67,45,21,13],
[67,45,21,14],
[67,45,21,16],
[67,45,21,17],
[67,45,22,0],
[67,45,22,1],
[67,45,22,2],
[67,45,22,3],
[67,45,22,4],
[67,45,22,5],
[67,45,22,6],
[67,45,22,8],
[67,45,22,11],
[67,45,22,12],
[67,45,22,13],
[67,45,22,14],
[67,45,22,16],
[67,45,22,17],
[67,45,23,0],
[67,45,23,1],
[67,45,23,2],
[67,45,23,3],
[67,45,23,4],
[67,45,23,5],
[67,45,23,6],
[67,45,23,8],
[67,45,23,10],
[67,45,23,11],
[67,45,23,12],
[67,45,23,13],
[67,45,23,14],
[67,45,24,0],
[67,45,24,1],
[67,45,24,2],
[67,45,24,3],
[67,45,24,4],
[67,45,24,5],
[67,45,24,6],
[67,45,24,8],
[67,45,24,10],
[67,45,24,11],
[67,45,24,12],
[67,45,24,13],
[67,45,24,14],
[67,45,27,0],
[67,45,27,1],
[67,45,27,2],
[67,45,27,3],
[67,45,27,4],
[67,45,27,5],
[67,45,27,6],
[67,45,27,8],
[67,45,29,0],
[67,45,29,1],
[67,45,29,2],
[67,45,29,3],
[67,45,29,4],
[67,45,29,5],
[67,45,30,0],
[67,45,30,1],
[67,45,30,2],
[67,45,30,3],
[67,45,30,4],
[67,45,31,0],
[67,45,31,1],
[67,45,31,2],
[67,46,3,0],
[67,46,3,1],
[67,46,3,2],
[67,46,4,0],
[67,46,4,1],
[67,46,4,2],
[67,46,4,3],
[67,46,5,0],
[67,46,5,1],
[67,46,5,2],
[67,46,5,4],
[67,46,6,0],
[67,46,6,1],
[67,46,6,2],
[67,46,6,4],
[67,46,8,0],
[67,46,8,1],
[67,46,8,2],
[67,46,8,4],
[67,46,10,0],
[67,46,10,1],
[67,46,10,2],
[67,46,10,3],
[67,46,10,4],
[67,46,10,5],
[67,46,10,6],
[67,46,10,8],
[67,46,11,0],
[67,46,11,1],
[67,46,11,2],
[67,46,11,3],
[67,46,11,4],
[67,46,11,5],
[67,46,11,6],
[67,46,11,8],
[67,46,12,0],
[67,46,12,1],
[67,46,12,2],
[67,46,12,3],
[67,46,12,4],
[67,46,12,5],
[67,46,12,6],
[67,46,12,10],
[67,46,13,0],
[67,46,13,1],
[67,46,13,2],
[67,46,13,3],
[67,46,13,4],
[67,46,13,5],
[67,46,13,6],
[67,46,13,10],
[67,46,13,11],
[67,46,13,12],
[67,46,14,0],
[67,46,14,1],
[67,46,14,2],
[67,46,14,3],
[67,46,14,4],
[67,46,14,5],
[67,46,14,8],
[67,46,14,10],
[67,46,14,11],
[67,46,14,12],
[67,46,16,0],
[67,46,16,1],
[67,46,16,2],
[67,46,16,5],
[67,46,16,6],
[67,46,16,8],
[67,46,16,10],
[67,46,16,11],
[67,46,16,12],
[67,46,16,13],
[67,46,16,14],
[67,46,17,0],
[67,46,17,1],
[67,46,17,2],
[67,46,17,5],
[67,46,17,6],
[67,46,17,8],
[67,46,17,10],
[67,46,17,11],
[67,46,17,12],
[67,46,17,13],
[67,46,17,14],
[67,46,17,16],
[67,46,19,0],
[67,46,19,1],
[67,46,19,2],
[67,46,19,3],
[67,46,19,4],
[67,46,19,5],
[67,46,19,6],
[67,46,19,8],
[67,46,19,10],
[67,46,19,11],
[67,46,19,12],
[67,46,19,16],
[67,46,19,17],
[67,46,20,0],
[67,46,20,1],
[67,46,20,2],
[67,46,20,3],
[67,46,20,4],
[67,46,20,5],
[67,46,20,6],
[67,46,20,8],
[67,46,20,11],
[67,46,20,12],
[67,46,20,13],
[67,46,20,14],
[67,46,20,16],
[67,46,20,17],
[67,46,21,0],
[67,46,21,1],
[67,46,21,2],
[67,46,21,4],
[67,46,21,10],
[67,46,21,11],
[67,46,21,12],
[67,46,21,13],
[67,46,21,14],
[67,46,21,16],
[67,46,21,17],
[67,46,22,0],
[67,46,22,1],
[67,46,22,2],
[67,46,22,3],
[67,46,22,4],
[67,46,22,5],
[67,46,22,6],
[67,46,22,8],
[67,46,22,11],
[67,46,22,12],
[67,46,22,13],
[67,46,22,14],
[67,46,23,0],
[67,46,23,1],
[67,46,23,2],
[67,46,23,3],
[67,46,23,4],
[67,46,23,5],
[67,46,23,6],
[67,46,23,8],
[67,46,23,10],
[67,46,23,11],
[67,46,23,12],
[67,46,23,13],
[67,46,23,14],
[67,46,24,0],
[67,46,24,1],
[67,46,24,2],
[67,46,24,3],
[67,46,24,4],
[67,46,24,5],
[67,46,24,6],
[67,46,24,8],
[67,46,24,10],
[67,46,24,11],
[67,46,24,12],
[67,46,24,13],
[67,46,24,14],
[67,46,27,0],
[67,46,27,1],
[67,46,27,2],
[67,46,27,3],
[67,46,27,4],
[67,46,27,5],
[67,46,27,6],
[67,46,29,0],
[67,46,29,1],
[67,46,29,2],
[67,46,29,3],
[67,46,29,4],
[67,46,30,0],
[67,46,30,1],
[67,46,30,2],
[67,47,3,0],
[67,47,3,1],
[67,47,3,2],
[67,47,4,0],
[67,47,4,1],
[67,47,4,2],
[67,47,4,3],
[67,47,5,0],
[67,47,5,1],
[67,47,5,2],
[67,47,5,4],
[67,47,6,0],
[67,47,6,1],
[67,47,6,2],
[67,47,6,4],
[67,47,8,0],
[67,47,8,1],
[67,47,8,2],
[67,47,8,4],
[67,47,9,0],
[67,47,9,1],
[67,47,9,2],
[67,47,9,3],
[67,47,9,4],
[67,47,9,5],
[67,47,9,6],
[67,47,9,8],
[67,47,10,0],
[67,47,10,1],
[67,47,10,2],
[67,47,10,3],
[67,47,10,4],
[67,47,10,5],
[67,47,10,6],
[67,47,11,0],
[67,47,11,1],
[67,47,11,2],
[67,47,11,3],
[67,47,11,4],
[67,47,11,5],
[67,47,11,6],
[67,47,11,10],
[67,47,12,0],
[67,47,12,1],
[67,47,12,2],
[67,47,12,3],
[67,47,12,4],
[67,47,12,5],
[67,47,12,8],
[67,47,12,9],
[67,47,12,10],
[67,47,13,0],
[67,47,13,1],
[67,47,13,2],
[67,47,13,3],
[67,47,13,4],
[67,47,13,5],
[67,47,13,8],
[67,47,13,9],
[67,47,13,10],
[67,47,13,11],
[67,47,13,12],
[67,47,14,0],
[67,47,14,1],
[67,47,14,2],
[67,47,14,3],
[67,47,14,4],
[67,47,14,6],
[67,47,14,8],
[67,47,14,9],
[67,47,14,10],
[67,47,14,11],
[67,47,14,12],
[67,47,15,0],
[67,47,15,1],
[67,47,15,2],
[67,47,15,5],
[67,47,15,6],
[67,47,15,8],
[67,47,15,10],
[67,47,15,11],
[67,47,15,12],
[67,47,15,13],
[67,47,15,14],
[67,47,16,3],
[67,47,16,4],
[67,47,16,5],
[67,47,16,6],
[67,47,16,8],
[67,47,16,9],
[67,47,16,10],
[67,47,16,11],
[67,47,16,12],
[67,47,16,13],
[67,47,16,14],
[67,47,16,15],
[67,47,17,3],
[67,47,17,4],
[67,47,17,5],
[67,47,17,6],
[67,47,17,8],
[67,47,17,9],
[67,47,17,10],
[67,47,17,11],
[67,47,17,12],
[67,47,17,13],
[67,47,17,14],
[67,47,17,15],
[67,47,17,16],
[67,47,18,0],
[67,47,18,1],
[67,47,18,2],
[67,47,18,3],
[67,47,18,4],
[67,47,18,5],
[67,47,18,6],
[67,47,18,8],
[67,47,18,10],
[67,47,18,11],
[67,47,18,12],
[67,47,18,13],
[67,47,18,14],
[67,47,18,16],
[67,47,18,17],
[67,47,19,0],
[67,47,19,1],
[67,47,19,2],
[67,47,19,3],
[67,47,19,4],
[67,47,19,5],
[67,47,19,6],
[67,47,19,8],
[67,47,19,9],
[67,47,19,10],
[67,47,19,11],
[67,47,19,12],
[67,47,19,15],
[67,47,19,16],
[67,47,19,17],
[67,47,19,18],
[67,47,20,0],
[67,47,20,1],
[67,47,20,2],
[67,47,20,3],
[67,47,20,4],
[67,47,20,5],
[67,47,20,6],
[67,47,20,8],
[67,47,20,9],
[67,47,20,11],
[67,47,20,12],
[67,47,20,13],
[67,47,20,14],
[67,47,20,15],
[67,47,20,16],
[67,47,20,17],
[67,47,21,0],
[67,47,21,1],
[67,47,21,2],
[67,47,21,4],
[67,47,21,9],
[67,47,21,10],
[67,47,21,11],
[67,47,21,12],
[67,47,21,13],
[67,47,21,14],
[67,47,21,15],
[67,47,22,0],
[67,47,22,1],
[67,47,22,2],
[67,47,22,3],
[67,47,22,4],
[67,47,22,5],
[67,47,22,6],
[67,47,22,8],
[67,47,22,9],
[67,47,22,11],
[67,47,22,12],
[67,47,22,13],
[67,47,22,14],
[67,47,24,0],
[67,47,24,1],
[67,47,24,2],
[67,47,24,3],
[67,47,24,4],
[67,47,24,5],
[67,47,24,6],
[67,47,24,8],
[67,47,24,9],
[67,47,24,10],
[67,47,24,11],
[67,47,24,12],
[67,47,24,13],
[67,47,26,0],
[67,47,26,1],
[67,47,26,2],
[67,47,26,3],
[67,47,26,4],
[67,47,26,5],
[67,47,26,6],
[67,47,27,0],
[67,47,27,1],
[67,47,27,2],
[67,47,27,3],
[67,47,27,4],
[67,47,27,5],
[67,47,29,0],
[67,47,29,1],
[67,47,29,2],
[67,48,3,0],
[67,48,3,1],
[67,48,3,2],
[67,48,4,0],
[67,48,4,1],
[67,48,4,2],
[67,48,4,3],
[67,48,5,0],
[67,48,5,1],
[67,48,5,2],
[67,48,5,4],
[67,48,6,0],
[67,48,6,1],
[67,48,6,2],
[67,48,6,4],
[67,48,8,0],
[67,48,8,1],
[67,48,8,2],
[67,48,8,4],
[67,48,9,0],
[67,48,9,1],
[67,48,9,2],
[67,48,9,3],
[67,48,9,4],
[67,48,9,5],
[67,48,9,6],
[67,48,10,0],
[67,48,10,1],
[67,48,10,2],
[67,48,10,3],
[67,48,10,4],
[67,48,10,5],
[67,48,10,8],
[67,48,10,9],
[67,48,13,0],
[67,48,13,1],
[67,48,13,2],
[67,48,13,3],
[67,48,13,4],
[67,48,13,6],
[67,48,13,8],
[67,48,13,9],
[67,48,13,10],
[67,48,14,0],
[67,48,14,1],
[67,48,14,2],
[67,48,14,5],
[67,48,14,6],
[67,48,14,8],
[67,48,14,9],
[67,48,14,10],
[67,48,15,3],
[67,48,15,4],
[67,48,15,5],
[67,48,15,6],
[67,48,15,8],
[67,48,15,10],
[67,48,15,13],
[67,48,15,14],
[67,48,16,0],
[67,48,16,1],
[67,48,16,2],
[67,48,16,3],
[67,48,16,4],
[67,48,16,5],
[67,48,16,6],
[67,48,16,8],
[67,48,16,9],
[67,48,16,10],
[67,48,16,13],
[67,48,16,14],
[67,48,16,15],
[67,48,17,0],
[67,48,17,1],
[67,48,17,2],
[67,48,17,3],
[67,48,17,4],
[67,48,17,5],
[67,48,17,6],
[67,48,17,8],
[67,48,17,9],
[67,48,17,10],
[67,48,17,13],
[67,48,17,14],
[67,48,17,15],
[67,48,17,16],
[67,48,18,0],
[67,48,18,1],
[67,48,18,2],
[67,48,18,3],
[67,48,18,4],
[67,48,18,5],
[67,48,18,6],
[67,48,18,8],
[67,48,18,10],
[67,48,18,13],
[67,48,18,14],
[67,48,18,16],
[67,48,18,17],
[67,48,19,0],
[67,48,19,1],
[67,48,19,2],
[67,48,19,3],
[67,48,19,4],
[67,48,19,5],
[67,48,19,6],
[67,48,19,8],
[67,48,19,9],
[67,48,19,10],
[67,48,19,15],
[67,48,19,16],
[67,48,19,17],
[67,48,20,0],
[67,48,20,1],
[67,48,20,2],
[67,48,20,3],
[67,48,20,4],
[67,48,20,5],
[67,48,20,6],
[67,48,20,8],
[67,48,20,9],
[67,48,20,13],
[67,48,20,14],
[67,48,20,15],
[67,48,21,0],
[67,48,21,1],
[67,48,21,2],
[67,48,21,4],
[67,48,21,9],
[67,48,21,10],
[67,48,21,13],
[67,48,21,14],
[67,48,22,0],
[67,48,22,1],
[67,48,22,2],
[67,48,22,3],
[67,48,22,4],
[67,48,22,5],
[67,48,22,6],
[67,48,22,8],
[67,48,22,9],
[67,48,22,13],
[67,48,23,0],
[67,48,23,1],
[67,48,23,2],
[67,48,23,3],
[67,48,23,4],
[67,48,23,5],
[67,48,23,6],
[67,48,23,8],
[67,48,23,9],
[67,48,23,10],
[67,48,24,0],
[67,48,24,1],
[67,48,24,2],
[67,48,24,3],
[67,48,24,4],
[67,48,24,5],
[67,48,24,6],
[67,48,24,8],
[67,48,24,9],
[67,48,24,10],
[67,48,26,0],
[67,48,26,1],
[67,48,26,2],
[67,48,26,3],
[67,48,26,4],
[67,48,26,5],
[67,48,27,0],
[67,48,27,1],
[67,48,27,2],
[67,48,27,3],
[67,48,27,4],
[67,49,3,0],
[67,49,3,1],
[67,49,3,2],
[67,49,4,0],
[67,49,4,1],
[67,49,4,2],
[67,49,4,3],
[67,49,5,0],
[67,49,5,1],
[67,49,5,2],
[67,49,5,4],
[67,49,6,0],
[67,49,6,1],
[67,49,6,2],
[67,49,6,4],
[67,49,8,0],
[67,49,8,1],
[67,49,8,2],
[67,49,8,4],
[67,49,10,0],
[67,49,10,1],
[67,49,10,2],
[67,49,10,3],
[67,49,10,4],
[67,49,10,5],
[67,49,10,8],
[67,49,11,0],
[67,49,11,1],
[67,49,11,2],
[67,49,11,3],
[67,49,11,4],
[67,49,11,5],
[67,49,11,8],
[67,49,11,10],
[67,49,12,0],
[67,49,12,1],
[67,49,12,2],
[67,49,12,3],
[67,49,12,4],
[67,49,12,6],
[67,49,12,8],
[67,49,12,10],
[67,49,13,0],
[67,49,13,1],
[67,49,13,2],
[67,49,13,3],
[67,49,13,4],
[67,49,13,6],
[67,49,13,8],
[67,49,13,10],
[67,49,13,11],
[67,49,13,12],
[67,49,14,0],
[67,49,14,1],
[67,49,14,2],
[67,49,14,5],
[67,49,14,6],
[67,49,14,8],
[67,49,14,10],
[67,49,14,11],
[67,49,14,12],
[67,49,16,0],
[67,49,16,1],
[67,49,16,2],
[67,49,16,3],
[67,49,16,4],
[67,49,16,5],
[67,49,16,6],
[67,49,16,8],
[67,49,16,10],
[67,49,16,11],
[67,49,16,12],
[67,49,16,13],
[67,49,16,14],
[67,49,17,0],
[67,49,17,1],
[67,49,17,2],
[67,49,17,3],
[67,49,17,4],
[67,49,17,5],
[67,49,17,6],
[67,49,17,8],
[67,49,17,10],
[67,49,17,11],
[67,49,17,12],
[67,49,17,13],
[67,49,17,14],
[67,49,17,16],
[67,49,19,0],
[67,49,19,1],
[67,49,19,2],
[67,49,19,3],
[67,49,19,4],
[67,49,19,5],
[67,49,19,6],
[67,49,19,8],
[67,49,19,10],
[67,49,19,11],
[67,49,19,12],
[67,49,19,16],
[67,49,19,17],
[67,49,20,0],
[67,49,20,1],
[67,49,20,2],
[67,49,20,3],
[67,49,20,4],
[67,49,20,5],
[67,49,20,6],
[67,49,20,8],
[67,49,20,11],
[67,49,20,12],
[67,49,20,13],
[67,49,20,14],
[67,49,21,0],
[67,49,21,1],
[67,49,21,2],
[67,49,21,4],
[67,49,21,10],
[67,49,21,11],
[67,49,21,12],
[67,49,21,13],
[67,49,21,14],
[67,49,22,0],
[67,49,22,1],
[67,49,22,2],
[67,49,22,3],
[67,49,22,4],
[67,49,22,5],
[67,49,22,6],
[67,49,22,8],
[67,49,22,11],
[67,49,22,12],
[67,49,22,13],
[67,49,23,0],
[67,49,23,1],
[67,49,23,2],
[67,49,23,3],
[67,49,23,4],
[67,49,23,5],
[67,49,23,6],
[67,49,23,8],
[67,49,23,10],
[67,49,23,11],
[67,49,24,0],
[67,49,24,1],
[67,49,24,2],
[67,49,24,3],
[67,49,24,4],
[67,49,24,5],
[67,49,24,6],
[67,49,24,8],
[67,49,24,10],
[67,49,24,11],
[67,49,27,0],
[67,49,27,1],
[67,49,27,2],
[67,49,27,3],
[67,49,27,4],
[67,50,3,0],
[67,50,3,1],
[67,50,3,2],
[67,50,4,0],
[67,50,4,1],
[67,50,4,2],
[67,50,4,3],
[67,50,5,0],
[67,50,5,1],
[67,50,5,2],
[67,50,5,4],
[67,50,6,0],
[67,50,6,1],
[67,50,6,2],
[67,50,6,4],
[67,50,8,0],
[67,50,8,1],
[67,50,8,2],
[67,50,8,4],
[67,50,10,0],
[67,50,10,1],
[67,50,10,2],
[67,50,10,3],
[67,50,10,4],
[67,50,10,5],
[67,50,10,8],
[67,50,11,0],
[67,50,11,1],
[67,50,11,2],
[67,50,11,3],
[67,50,11,4],
[67,50,11,5],
[67,50,11,8],
[67,50,11,10],
[67,50,12,0],
[67,50,12,1],
[67,50,12,2],
[67,50,12,3],
[67,50,12,4],
[67,50,12,6],
[67,50,12,8],
[67,50,12,10],
[67,50,13,0],
[67,50,13,1],
[67,50,13,2],
[67,50,13,3],
[67,50,13,4],
[67,50,13,6],
[67,50,13,8],
[67,50,13,10],
[67,50,13,11],
[67,50,13,12],
[67,50,14,0],
[67,50,14,1],
[67,50,14,2],
[67,50,14,5],
[67,50,14,6],
[67,50,14,8],
[67,50,14,10],
[67,50,14,11],
[67,50,14,12],
[67,50,16,0],
[67,50,16,1],
[67,50,16,2],
[67,50,16,3],
[67,50,16,4],
[67,50,16,5],
[67,50,16,6],
[67,50,16,8],
[67,50,16,10],
[67,50,16,11],
[67,50,16,12],
[67,50,16,13],
[67,50,16,14],
[67,50,17,0],
[67,50,17,1],
[67,50,17,2],
[67,50,17,3],
[67,50,17,4],
[67,50,17,5],
[67,50,17,6],
[67,50,17,8],
[67,50,17,10],
[67,50,17,11],
[67,50,17,12],
[67,50,17,13],
[67,50,17,14],
[67,50,17,16],
[67,50,19,0],
[67,50,19,1],
[67,50,19,2],
[67,50,19,3],
[67,50,19,4],
[67,50,19,5],
[67,50,19,6],
[67,50,19,8],
[67,50,19,10],
[67,50,19,11],
[67,50,19,12],
[67,50,19,16],
[67,50,19,17],
[67,50,20,0],
[67,50,20,1],
[67,50,20,2],
[67,50,20,3],
[67,50,20,4],
[67,50,20,5],
[67,50,20,6],
[67,50,20,8],
[67,50,20,11],
[67,50,20,12],
[67,50,20,13],
[67,50,20,14],
[67,50,21,0],
[67,50,21,1],
[67,50,21,2],
[67,50,21,4],
[67,50,21,10],
[67,50,21,11],
[67,50,21,12],
[67,50,21,13],
[67,50,21,14],
[67,50,22,0],
[67,50,22,1],
[67,50,22,2],
[67,50,22,3],
[67,50,22,4],
[67,50,22,5],
[67,50,22,6],
[67,50,22,8],
[67,50,22,11],
[67,50,22,12],
[67,50,22,13],
[67,50,23,0],
[67,50,23,1],
[67,50,23,2],
[67,50,23,3],
[67,50,23,4],
[67,50,23,5],
[67,50,23,6],
[67,50,23,8],
[67,50,23,10],
[67,50,23,11],
[67,50,24,0],
[67,50,24,1],
[67,50,24,2],
[67,50,24,3],
[67,50,24,4],
[67,50,24,5],
[67,50,24,6],
[67,50,24,8],
[67,50,24,10],
[67,50,24,11],
[67,50,27,0],
[67,50,27,1],
[67,50,27,2],
[67,50,27,3],
[67,50,27,4],
[67,51,4,0],
[67,51,4,1],
[67,51,4,2],
[67,51,9,0],
[67,51,9,1],
[67,51,9,2],
[67,51,9,4],
[67,51,10,0],
[67,51,10,1],
[67,51,10,2],
[67,51,10,4],
[67,51,10,9],
[67,51,11,0],
[67,51,11,1],
[67,51,11,2],
[67,51,11,4],
[67,51,11,9],
[67,51,11,10],
[67,51,12,0],
[67,51,12,1],
[67,51,12,2],
[67,51,12,4],
[67,51,12,9],
[67,51,12,10],
[67,51,13,0],
[67,51,13,1],
[67,51,13,2],
[67,51,13,4],
[67,51,13,9],
[67,51,13,10],
[67,51,13,11],
[67,51,13,12],
[67,51,14,0],
[67,51,14,1],
[67,51,14,2],
[67,51,14,9],
[67,51,14,10],
[67,51,14,11],
[67,51,14,12],
[67,51,15,4],
[67,51,15,10],
[67,51,15,11],
[67,51,15,12],
[67,51,15,13],
[67,51,15,14],
[67,51,16,0],
[67,51,16,1],
[67,51,16,2],
[67,51,16,4],
[67,51,16,9],
[67,51,16,10],
[67,51,16,11],
[67,51,16,12],
[67,51,16,13],
[67,51,16,14],
[67,51,16,15],
[67,51,17,0],
[67,51,17,1],
[67,51,17,2],
[67,51,17,4],
[67,51,17,9],
[67,51,17,10],
[67,51,17,11],
[67,51,17,12],
[67,51,17,13],
[67,51,17,14],
[67,51,17,15],
[67,51,17,16],
[67,51,18,0],
[67,51,18,1],
[67,51,18,2],
[67,51,18,4],
[67,51,18,10],
[67,51,18,11],
[67,51,18,12],
[67,51,18,13],
[67,51,18,14],
[67,51,18,16],
[67,51,18,17],
[67,51,19,0],
[67,51,19,1],
[67,51,19,2],
[67,51,19,4],
[67,51,19,9],
[67,51,19,10],
[67,51,19,11],
[67,51,19,12],
[67,51,19,15],
[67,51,19,16],
[67,51,19,17],
[67,51,20,0],
[67,51,20,1],
[67,51,20,2],
[67,51,20,4],
[67,51,20,9],
[67,51,20,11],
[67,51,20,12],
[67,51,20,13],
[67,51,20,14],
[67,51,20,15],
[67,51,22,0],
[67,51,22,1],
[67,51,22,2],
[67,51,22,4],
[67,51,22,9],
[67,51,22,11],
[67,51,22,12],
[67,51,22,13],
[67,51,23,0],
[67,51,23,1],
[67,51,23,2],
[67,51,23,4],
[67,51,23,9],
[67,51,23,10],
[67,51,23,11],
[67,51,24,0],
[67,51,24,1],
[67,51,24,2],
[67,51,24,4],
[67,51,24,9],
[67,51,24,10],
[67,51,24,11],
[67,51,26,0],
[67,51,26,1],
[67,51,26,2],
[67,51,26,4],
[67,51,27,0],
[67,51,27,1],
[67,51,27,2],
[67,51,27,4],
[67,52,3,0],
[67,52,3,1],
[67,52,3,2],
[67,52,4,0],
[67,52,4,1],
[67,52,4,2],
[67,52,4,3],
[67,52,5,0],
[67,52,5,1],
[67,52,5,2],
[67,52,5,4],
[67,52,6,0],
[67,52,6,1],
[67,52,6,2],
[67,52,6,4],
[67,52,8,0],
[67,52,8,1],
[67,52,8,2],
[67,52,8,4],
[67,52,10,0],
[67,52,10,1],
[67,52,10,2],
[67,52,10,3],
[67,52,10,4],
[67,52,10,5],
[67,52,10,8],
[67,52,11,0],
[67,52,11,1],
[67,52,11,2],
[67,52,11,3],
[67,52,11,4],
[67,52,11,5],
[67,52,11,8],
[67,52,11,10],
[67,52,12,0],
[67,52,12,1],
[67,52,12,2],
[67,52,12,3],
[67,52,12,4],
[67,52,12,6],
[67,52,12,8],
[67,52,12,10],
[67,52,13,0],
[67,52,13,1],
[67,52,13,2],
[67,52,13,3],
[67,52,13,4],
[67,52,13,6],
[67,52,13,8],
[67,52,13,10],
[67,52,13,11],
[67,52,13,12],
[67,52,14,0],
[67,52,14,1],
[67,52,14,2],
[67,52,14,5],
[67,52,14,6],
[67,52,14,8],
[67,52,14,10],
[67,52,14,11],
[67,52,14,12],
[67,52,16,0],
[67,52,16,1],
[67,52,16,2],
[67,52,16,3],
[67,52,16,4],
[67,52,16,5],
[67,52,16,6],
[67,52,16,8],
[67,52,16,10],
[67,52,16,11],
[67,52,16,12],
[67,52,16,13],
[67,52,16,14],
[67,52,17,0],
[67,52,17,1],
[67,52,17,2],
[67,52,17,3],
[67,52,17,4],
[67,52,17,5],
[67,52,17,6],
[67,52,17,8],
[67,52,17,10],
[67,52,17,11],
[67,52,17,12],
[67,52,17,13],
[67,52,17,14],
[67,52,17,16],
[67,52,19,0],
[67,52,19,1],
[67,52,19,2],
[67,52,19,3],
[67,52,19,4],
[67,52,19,5],
[67,52,19,6],
[67,52,19,8],
[67,52,19,10],
[67,52,19,11],
[67,52,19,12],
[67,52,19,16],
[67,52,19,17],
[67,52,20,0],
[67,52,20,1],
[67,52,20,2],
[67,52,20,3],
[67,52,20,4],
[67,52,20,5],
[67,52,20,6],
[67,52,20,8],
[67,52,20,11],
[67,52,20,12],
[67,52,20,13],
[67,52,20,14],
[67,52,21,0],
[67,52,21,1],
[67,52,21,2],
[67,52,21,4],
[67,52,21,10],
[67,52,21,11],
[67,52,21,12],
[67,52,21,13],
[67,52,21,14],
[67,52,22,0],
[67,52,22,1],
[67,52,22,2],
[67,52,22,3],
[67,52,22,4],
[67,52,22,5],
[67,52,22,6],
[67,52,22,8],
[67,52,22,11],
[67,52,22,12],
[67,52,22,13],
[67,52,23,0],
[67,52,23,1],
[67,52,23,2],
[67,52,23,3],
[67,52,23,4],
[67,52,23,5],
[67,52,23,6],
[67,52,23,8],
[67,52,23,10],
[67,52,23,11],
[67,52,24,0],
[67,52,24,1],
[67,52,24,2],
[67,52,24,3],
[67,52,24,4],
[67,52,24,5],
[67,52,24,6],
[67,52,24,8],
[67,52,24,10],
[67,52,24,11],
[67,52,27,0],
[67,52,27,1],
[67,52,27,2],
[67,52,27,3],
[67,52,27,4],
[67,53,3,0],
[67,53,3,1],
[67,53,3,2],
[67,53,4,0],
[67,53,4,1],
[67,53,4,2],
[67,53,4,3],
[67,53,5,0],
[67,53,5,1],
[67,53,5,2],
[67,53,5,4],
[67,53,6,0],
[67,53,6,1],
[67,53,6,2],
[67,53,6,4],
[67,53,8,0],
[67,53,8,1],
[67,53,8,2],
[67,53,8,4],
[67,53,9,0],
[67,53,9,1],
[67,53,9,2],
[67,53,9,3],
[67,53,9,4],
[67,53,9,5],
[67,53,9,6],
[67,53,11,0],
[67,53,11,1],
[67,53,11,2],
[67,53,11,3],
[67,53,11,4],
[67,53,11,5],
[67,53,11,8],
[67,53,11,9],
[67,53,12,0],
[67,53,12,1],
[67,53,12,2],
[67,53,12,3],
[67,53,12,4],
[67,53,12,6],
[67,53,12,8],
[67,53,12,9],
[67,53,13,0],
[67,53,13,1],
[67,53,13,2],
[67,53,13,3],
[67,53,13,4],
[67,53,13,6],
[67,53,13,8],
[67,53,13,9],
[67,53,13,11],
[67,53,13,12],
[67,53,14,0],
[67,53,14,1],
[67,53,14,2],
[67,53,14,5],
[67,53,14,6],
[67,53,14,8],
[67,53,14,9],
[67,53,14,11],
[67,53,14,12],
[67,53,15,3],
[67,53,15,4],
[67,53,15,5],
[67,53,15,6],
[67,53,15,8],
[67,53,15,11],
[67,53,15,12],
[67,53,15,13],
[67,53,15,14],
[67,53,16,0],
[67,53,16,1],
[67,53,16,2],
[67,53,16,3],
[67,53,16,4],
[67,53,16,5],
[67,53,16,6],
[67,53,16,8],
[67,53,16,9],
[67,53,16,11],
[67,53,16,12],
[67,53,16,13],
[67,53,16,14],
[67,53,16,15],
[67,53,17,0],
[67,53,17,1],
[67,53,17,2],
[67,53,17,3],
[67,53,17,4],
[67,53,17,5],
[67,53,17,6],
[67,53,17,8],
[67,53,17,9],
[67,53,17,11],
[67,53,17,12],
[67,53,17,13],
[67,53,17,14],
[67,53,17,15],
[67,53,17,16],
[67,53,18,0],
[67,53,18,1],
[67,53,18,2],
[67,53,18,3],
[67,53,18,4],
[67,53,18,5],
[67,53,18,6],
[67,53,18,8],
[67,53,18,11],
[67,53,18,12],
[67,53,18,13],
[67,53,18,14],
[67,53,18,16],
[67,53,18,17],
[67,53,19,0],
[67,53,19,1],
[67,53,19,2],
[67,53,19,3],
[67,53,19,4],
[67,53,19,5],
[67,53,19,6],
[67,53,19,8],
[67,53,19,9],
[67,53,19,11],
[67,53,19,12],
[67,53,19,15],
[67,53,19,16],
[67,53,19,17],
[67,53,21,0],
[67,53,21,1],
[67,53,21,2],
[67,53,21,4],
[67,53,21,9],
[67,53,21,11],
[67,53,21,12],
[67,53,21,13],
[67,53,21,14],
[67,53,23,0],
[67,53,23,1],
[67,53,23,2],
[67,53,23,3],
[67,53,23,4],
[67,53,23,5],
[67,53,23,6],
[67,53,23,8],
[67,53,23,9],
[67,53,23,11],
[67,53,24,0],
[67,53,24,1],
[67,53,24,2],
[67,53,24,3],
[67,53,24,4],
[67,53,24,5],
[67,53,24,6],
[67,53,24,8],
[67,53,24,9],
[67,53,24,11],
[67,53,26,0],
[67,53,26,1],
[67,53,26,2],
[67,53,26,3],
[67,53,26,4],
[67,53,26,5],
[67,54,3,0],
[67,54,3,1],
[67,54,3,2],
[67,54,4,0],
[67,54,4,1],
[67,54,4,2],
[67,54,4,3],
[67,54,5,0],
[67,54,5,1],
[67,54,5,2],
[67,54,5,4],
[67,54,6,0],
[67,54,6,1],
[67,54,6,2],
[67,54,6,4],
[67,54,8,0],
[67,54,8,1],
[67,54,8,2],
[67,54,8,4],
[67,54,9,0],
[67,54,9,1],
[67,54,9,2],
[67,54,9,3],
[67,54,9,4],
[67,54,9,5],
[67,54,9,6],
[67,54,11,0],
[67,54,11,1],
[67,54,11,2],
[67,54,11,3],
[67,54,11,4],
[67,54,11,5],
[67,54,11,8],
[67,54,11,9],
[67,54,12,0],
[67,54,12,1],
[67,54,12,2],
[67,54,12,3],
[67,54,12,4],
[67,54,12,6],
[67,54,12,8],
[67,54,12,9],
[67,54,13,0],
[67,54,13,1],
[67,54,13,2],
[67,54,13,3],
[67,54,13,4],
[67,54,13,6],
[67,54,13,8],
[67,54,13,9],
[67,54,13,11],
[67,54,13,12],
[67,54,14,0],
[67,54,14,1],
[67,54,14,2],
[67,54,14,5],
[67,54,14,6],
[67,54,14,8],
[67,54,14,9],
[67,54,14,11],
[67,54,14,12],
[67,54,15,3],
[67,54,15,4],
[67,54,15,5],
[67,54,15,6],
[67,54,15,8],
[67,54,15,11],
[67,54,15,12],
[67,54,15,13],
[67,54,15,14],
[67,54,16,0],
[67,54,16,1],
[67,54,16,2],
[67,54,16,3],
[67,54,16,4],
[67,54,16,5],
[67,54,16,6],
[67,54,16,8],
[67,54,16,9],
[67,54,16,11],
[67,54,16,12],
[67,54,16,13],
[67,54,16,14],
[67,54,16,15],
[67,54,17,0],
[67,54,17,1],
[67,54,17,2],
[67,54,17,3],
[67,54,17,4],
[67,54,17,5],
[67,54,17,6],
[67,54,17,8],
[67,54,17,9],
[67,54,17,11],
[67,54,17,12],
[67,54,17,13],
[67,54,17,14],
[67,54,17,15],
[67,54,17,16],
[67,54,18,0],
[67,54,18,1],
[67,54,18,2],
[67,54,18,3],
[67,54,18,4],
[67,54,18,5],
[67,54,18,6],
[67,54,18,8],
[67,54,18,11],
[67,54,18,12],
[67,54,18,13],
[67,54,18,14],
[67,54,18,16],
[67,54,18,17],
[67,54,19,0],
[67,54,19,1],
[67,54,19,2],
[67,54,19,3],
[67,54,19,4],
[67,54,19,5],
[67,54,19,6],
[67,54,19,8],
[67,54,19,9],
[67,54,19,11],
[67,54,19,12],
[67,54,19,15],
[67,54,19,16],
[67,54,19,17],
[67,54,21,0],
[67,54,21,1],
[67,54,21,2],
[67,54,21,4],
[67,54,21,9],
[67,54,21,11],
[67,54,21,12],
[67,54,21,13],
[67,54,21,14],
[67,54,23,0],
[67,54,23,1],
[67,54,23,2],
[67,54,23,3],
[67,54,23,4],
[67,54,23,5],
[67,54,23,6],
[67,54,23,8],
[67,54,23,9],
[67,54,23,11],
[67,54,24,0],
[67,54,24,1],
[67,54,24,2],
[67,54,24,3],
[67,54,24,4],
[67,54,24,5],
[67,54,24,6],
[67,54,24,8],
[67,54,24,9],
[67,54,24,11],
[67,54,26,0],
[67,54,26,1],
[67,54,26,2],
[67,54,26,3],
[67,54,26,4],
[67,54,26,5],
[67,55,3,0],
[67,55,3,1],
[67,55,3,2],
[67,55,4,0],
[67,55,4,1],
[67,55,4,2],
[67,55,4,3],
[67,55,5,0],
[67,55,5,1],
[67,55,5,2],
[67,55,5,4],
[67,55,6,0],
[67,55,6,1],
[67,55,6,2],
[67,55,6,4],
[67,55,8,0],
[67,55,8,1],
[67,55,8,2],
[67,55,8,4],
[67,55,9,0],
[67,55,9,1],
[67,55,9,2],
[67,55,9,3],
[67,55,9,4],
[67,55,9,5],
[67,55,9,6],
[67,55,11,0],
[67,55,11,1],
[67,55,11,2],
[67,55,11,3],
[67,55,11,4],
[67,55,11,5],
[67,55,11,8],
[67,55,11,9],
[67,55,12,0],
[67,55,12,1],
[67,55,12,2],
[67,55,12,3],
[67,55,12,4],
[67,55,12,6],
[67,55,12,8],
[67,55,12,9],
[67,55,13,0],
[67,55,13,1],
[67,55,13,2],
[67,55,13,3],
[67,55,13,4],
[67,55,13,6],
[67,55,13,8],
[67,55,13,9],
[67,55,13,11],
[67,55,13,12],
[67,55,14,0],
[67,55,14,1],
[67,55,14,2],
[67,55,14,5],
[67,55,14,6],
[67,55,14,8],
[67,55,14,9],
[67,55,14,11],
[67,55,14,12],
[67,55,15,3],
[67,55,15,4],
[67,55,15,5],
[67,55,15,6],
[67,55,15,8],
[67,55,15,11],
[67,55,15,12],
[67,55,15,13],
[67,55,15,14],
[67,55,16,0],
[67,55,16,1],
[67,55,16,2],
[67,55,16,3],
[67,55,16,4],
[67,55,16,5],
[67,55,16,6],
[67,55,16,8],
[67,55,16,9],
[67,55,16,11],
[67,55,16,12],
[67,55,16,13],
[67,55,16,14],
[67,55,16,15],
[67,55,17,0],
[67,55,17,1],
[67,55,17,2],
[67,55,17,3],
[67,55,17,4],
[67,55,17,5],
[67,55,17,6],
[67,55,17,8],
[67,55,17,9],
[67,55,17,11],
[67,55,17,12],
[67,55,17,13],
[67,55,17,14],
[67,55,17,15],
[67,55,17,16],
[67,55,18,0],
[67,55,18,1],
[67,55,18,2],
[67,55,18,3],
[67,55,18,4],
[67,55,18,5],
[67,55,18,6],
[67,55,18,8],
[67,55,18,11],
[67,55,18,12],
[67,55,18,13],
[67,55,18,14],
[67,55,18,16],
[67,55,18,17],
[67,55,19,0],
[67,55,19,1],
[67,55,19,2],
[67,55,19,3],
[67,55,19,4],
[67,55,19,5],
[67,55,19,6],
[67,55,19,8],
[67,55,19,9],
[67,55,19,11],
[67,55,19,12],
[67,55,19,15],
[67,55,19,16],
[67,55,19,17],
[67,55,21,0],
[67,55,21,1],
[67,55,21,2],
[67,55,21,4],
[67,55,21,9],
[67,55,21,11],
[67,55,21,12],
[67,55,21,13],
[67,55,21,14],
[67,55,23,0],
[67,55,23,1],
[67,55,23,2],
[67,55,23,3],
[67,55,23,4],
[67,55,23,5],
[67,55,23,6],
[67,55,23,8],
[67,55,23,9],
[67,55,23,11],
[67,55,24,0],
[67,55,24,1],
[67,55,24,2],
[67,55,24,3],
[67,55,24,4],
[67,55,24,5],
[67,55,24,6],
[67,55,24,8],
[67,55,24,9],
[67,55,24,11],
[67,55,26,0],
[67,55,26,1],
[67,55,26,2],
[67,55,26,3],
[67,55,26,4],
[67,55,26,5],
[67,56,3,0],
[67,56,3,1],
[67,56,3,2],
[67,56,4,0],
[67,56,4,1],
[67,56,4,2],
[67,56,4,3],
[67,56,5,0],
[67,56,5,1],
[67,56,5,2],
[67,56,5,4],
[67,56,6,0],
[67,56,6,1],
[67,56,6,2],
[67,56,6,4],
[67,56,8,0],
[67,56,8,1],
[67,56,8,2],
[67,56,8,4],
[67,56,10,0],
[67,56,10,1],
[67,56,10,2],
[67,56,10,3],
[67,56,10,4],
[67,56,10,5],
[67,56,10,8],
[67,56,11,0],
[67,56,11,1],
[67,56,11,2],
[67,56,11,3],
[67,56,11,4],
[67,56,11,5],
[67,56,11,8],
[67,56,11,10],
[67,56,12,0],
[67,56,12,1],
[67,56,12,2],
[67,56,12,3],
[67,56,12,4],
[67,56,12,6],
[67,56,12,8],
[67,56,12,10],
[67,56,13,0],
[67,56,13,1],
[67,56,13,2],
[67,56,13,3],
[67,56,13,4],
[67,56,13,6],
[67,56,13,8],
[67,56,13,10],
[67,56,13,11],
[67,56,13,12],
[67,56,14,0],
[67,56,14,1],
[67,56,14,2],
[67,56,14,5],
[67,56,14,6],
[67,56,14,8],
[67,56,14,10],
[67,56,14,11],
[67,56,14,12],
[67,56,16,0],
[67,56,16,1],
[67,56,16,2],
[67,56,16,3],
[67,56,16,4],
[67,56,16,5],
[67,56,16,6],
[67,56,16,8],
[67,56,16,10],
[67,56,16,11],
[67,56,16,12],
[67,56,16,13],
[67,56,16,14],
[67,56,17,0],
[67,56,17,1],
[67,56,17,2],
[67,56,17,3],
[67,56,17,4],
[67,56,17,5],
[67,56,17,6],
[67,56,17,8],
[67,56,17,10],
[67,56,17,11],
[67,56,17,12],
[67,56,17,13],
[67,56,17,14],
[67,56,17,16],
[67,56,19,0],
[67,56,19,1],
[67,56,19,2],
[67,56,19,3],
[67,56,19,4],
[67,56,19,5],
[67,56,19,6],
[67,56,19,8],
[67,56,19,10],
[67,56,19,11],
[67,56,19,12],
[67,56,19,16],
[67,56,19,17],
[67,56,20,0],
[67,56,20,1],
[67,56,20,2],
[67,56,20,3],
[67,56,20,4],
[67,56,20,5],
[67,56,20,6],
[67,56,20,8],
[67,56,20,11],
[67,56,20,12],
[67,56,20,13],
[67,56,20,14],
[67,56,21,0],
[67,56,21,1],
[67,56,21,2],
[67,56,21,4],
[67,56,21,10],
[67,56,21,11],
[67,56,21,12],
[67,56,21,13],
[67,56,21,14],
[67,56,22,0],
[67,56,22,1],
[67,56,22,2],
[67,56,22,3],
[67,56,22,4],
[67,56,22,5],
[67,56,22,6],
[67,56,22,8],
[67,56,22,11],
[67,56,22,12],
[67,56,22,13],
[67,56,23,0],
[67,56,23,1],
[67,56,23,2],
[67,56,23,3],
[67,56,23,4],
[67,56,23,5],
[67,56,23,6],
[67,56,23,8],
[67,56,23,10],
[67,56,23,11],
[67,56,24,0],
[67,56,24,1],
[67,56,24,2],
[67,56,24,3],
[67,56,24,4],
[67,56,24,5],
[67,56,24,6],
[67,56,24,8],
[67,56,24,10],
[67,56,24,11],
[67,56,27,0],
[67,56,27,1],
[67,56,27,2],
[67,56,27,3],
[67,56,27,4],
[67,57,3,0],
[67,57,3,1],
[67,57,3,2],
[67,57,4,0],
[67,57,4,1],
[67,57,4,2],
[67,57,4,3],
[67,57,5,0],
[67,57,5,1],
[67,57,5,2],
[67,57,5,4],
[67,57,6,0],
[67,57,6,1],
[67,57,6,2],
[67,57,6,4],
[67,57,8,0],
[67,57,8,1],
[67,57,8,2],
[67,57,8,4],
[67,57,9,0],
[67,57,9,1],
[67,57,9,2],
[67,57,9,3],
[67,57,9,4],
[67,57,9,5],
[67,57,9,8],
[67,57,10,0],
[67,57,10,1],
[67,57,10,2],
[67,57,10,3],
[67,57,10,4],
[67,57,10,6],
[67,57,10,8],
[67,57,10,9],
[67,57,11,0],
[67,57,11,1],
[67,57,11,2],
[67,57,11,3],
[67,57,11,4],
[67,57,11,6],
[67,57,11,8],
[67,57,11,9],
[67,57,11,10],
[67,57,12,0],
[67,57,12,1],
[67,57,12,2],
[67,57,12,5],
[67,57,12,6],
[67,57,12,8],
[67,57,12,9],
[67,57,12,10],
[67,57,13,0],
[67,57,13,1],
[67,57,13,2],
[67,57,13,5],
[67,57,13,6],
[67,57,13,8],
[67,57,13,9],
[67,57,13,10],
[67,57,13,11],
[67,57,13,12],
[67,57,14,3],
[67,57,14,4],
[67,57,14,5],
[67,57,14,6],
[67,57,14,8],
[67,57,14,9],
[67,57,14,10],
[67,57,14,11],
[67,57,14,12],
[67,57,15,0],
[67,57,15,1],
[67,57,15,2],
[67,57,15,3],
[67,57,15,4],
[67,57,15,5],
[67,57,15,6],
[67,57,15,8],
[67,57,15,10],
[67,57,15,11],
[67,57,15,12],
[67,57,15,13],
[67,57,15,14],
[67,57,16,0],
[67,57,16,1],
[67,57,16,2],
[67,57,16,3],
[67,57,16,4],
[67,57,16,5],
[67,57,16,6],
[67,57,16,8],
[67,57,16,9],
[67,57,16,10],
[67,57,16,11],
[67,57,16,12],
[67,57,16,13],
[67,57,16,14],
[67,57,16,15],
[67,57,17,0],
[67,57,17,1],
[67,57,17,2],
[67,57,17,3],
[67,57,17,4],
[67,57,17,5],
[67,57,17,6],
[67,57,17,8],
[67,57,17,9],
[67,57,17,10],
[67,57,17,11],
[67,57,17,12],
[67,57,17,13],
[67,57,17,14],
[67,57,17,15],
[67,57,17,16],
[67,57,18,0],
[67,57,18,1],
[67,57,18,2],
[67,57,18,3],
[67,57,18,4],
[67,57,18,5],
[67,57,18,6],
[67,57,18,8],
[67,57,18,10],
[67,57,18,11],
[67,57,18,12],
[67,57,18,13],
[67,57,18,14],
[67,57,18,16],
[67,57,18,17],
[67,57,19,0],
[67,57,19,1],
[67,57,19,2],
[67,57,19,3],
[67,57,19,4],
[67,57,19,5],
[67,57,19,6],
[67,57,19,8],
[67,57,19,9],
[67,57,19,10],
[67,57,19,11],
[67,57,19,12],
[67,57,19,15],
[67,57,20,0],
[67,57,20,1],
[67,57,20,2],
[67,57,20,3],
[67,57,20,4],
[67,57,20,5],
[67,57,20,6],
[67,57,20,8],
[67,57,20,9],
[67,57,20,11],
[67,57,20,12],
[67,57,20,13],
[67,57,20,14],
[67,57,21,0],
[67,57,21,1],
[67,57,21,2],
[67,57,21,4],
[67,57,21,9],
[67,57,21,10],
[67,57,21,11],
[67,57,21,12],
[67,57,21,13],
[67,57,22,0],
[67,57,22,1],
[67,57,22,2],
[67,57,22,3],
[67,57,22,4],
[67,57,22,5],
[67,57,22,6],
[67,57,22,8],
[67,57,22,9],
[67,57,22,11],
[67,57,24,0],
[67,57,24,1],
[67,57,24,2],
[67,57,24,3],
[67,57,24,4],
[67,57,24,5],
[67,57,24,6],
[67,57,24,8],
[67,57,24,9],
[67,57,26,0],
[67,57,26,1],
[67,57,26,2],
[67,57,26,3],
[67,57,26,4],
[67,57,27,0],
[67,57,27,1],
[67,57,27,2],
[67,58,3,0],
[67,58,3,1],
[67,58,3,2],
[67,58,4,0],
[67,58,4,1],
[67,58,4,2],
[67,58,4,3],
[67,58,5,0],
[67,58,5,1],
[67,58,5,2],
[67,58,5,4],
[67,58,6,0],
[67,58,6,1],
[67,58,6,2],
[67,58,6,4],
[67,58,8,0],
[67,58,8,1],
[67,58,8,2],
[67,58,8,4],
[67,58,9,0],
[67,58,9,1],
[67,58,9,2],
[67,58,9,3],
[67,58,9,4],
[67,58,9,5],
[67,58,9,8],
[67,58,10,0],
[67,58,10,1],
[67,58,10,2],
[67,58,10,3],
[67,58,10,4],
[67,58,10,6],
[67,58,10,8],
[67,58,10,9],
[67,58,11,0],
[67,58,11,1],
[67,58,11,2],
[67,58,11,3],
[67,58,11,4],
[67,58,11,6],
[67,58,11,8],
[67,58,11,9],
[67,58,11,10],
[67,58,12,0],
[67,58,12,1],
[67,58,12,2],
[67,58,12,5],
[67,58,12,6],
[67,58,12,8],
[67,58,12,9],
[67,58,12,10],
[67,58,13,0],
[67,58,13,1],
[67,58,13,2],
[67,58,13,5],
[67,58,13,6],
[67,58,13,8],
[67,58,13,9],
[67,58,13,10],
[67,58,13,11],
[67,58,13,12],
[67,58,14,3],
[67,58,14,4],
[67,58,14,5],
[67,58,14,6],
[67,58,14,8],
[67,58,14,9],
[67,58,14,10],
[67,58,14,11],
[67,58,14,12],
[67,58,15,0],
[67,58,15,1],
[67,58,15,2],
[67,58,15,3],
[67,58,15,4],
[67,58,15,5],
[67,58,15,6],
[67,58,15,8],
[67,58,15,10],
[67,58,15,11],
[67,58,15,12],
[67,58,15,13],
[67,58,15,14],
[67,58,17,0],
[67,58,17,1],
[67,58,17,2],
[67,58,17,3],
[67,58,17,4],
[67,58,17,5],
[67,58,17,6],
[67,58,17,8],
[67,58,17,9],
[67,58,17,10],
[67,58,17,11],
[67,58,17,12],
[67,58,17,13],
[67,58,17,14],
[67,58,17,15],
[67,58,18,0],
[67,58,18,1],
[67,58,18,2],
[67,58,18,3],
[67,58,18,4],
[67,58,18,5],
[67,58,18,6],
[67,58,18,8],
[67,58,18,10],
[67,58,18,11],
[67,58,18,12],
[67,58,18,13],
[67,58,18,14],
[67,58,18,17],
[67,58,19,0],
[67,58,19,1],
[67,58,19,2],
[67,58,19,3],
[67,58,19,4],
[67,58,19,5],
[67,58,19,6],
[67,58,19,8],
[67,58,19,9],
[67,58,19,10],
[67,58,19,11],
[67,58,19,12],
[67,58,19,15],
[67,58,20,0],
[67,58,20,1],
[67,58,20,2],
[67,58,20,3],
[67,58,20,4],
[67,58,20,5],
[67,58,20,6],
[67,58,20,8],
[67,58,20,9],
[67,58,20,11],
[67,58,20,12],
[67,58,20,13],
[67,58,20,14],
[67,58,21,0],
[67,58,21,1],
[67,58,21,2],
[67,58,21,4],
[67,58,21,9],
[67,58,21,10],
[67,58,21,11],
[67,58,21,12],
[67,58,21,13],
[67,58,22,0],
[67,58,22,1],
[67,58,22,2],
[67,58,22,3],
[67,58,22,4],
[67,58,22,5],
[67,58,22,6],
[67,58,22,8],
[67,58,22,9],
[67,58,22,11],
[67,58,23,0],
[67,58,23,1],
[67,58,23,2],
[67,58,23,3],
[67,58,23,4],
[67,58,23,5],
[67,58,23,6],
[67,58,23,8],
[67,58,23,9],
[67,58,26,0],
[67,58,26,1],
[67,58,26,2],
[67,58,26,3],
[67,58,26,4],
[67,58,27,0],
[67,58,27,1],
[67,58,27,2],
[67,59,3,0],
[67,59,3,1],
[67,59,3,2],
[67,59,4,0],
[67,59,4,1],
[67,59,4,2],
[67,59,4,3],
[67,59,5,0],
[67,59,5,1],
[67,59,5,2],
[67,59,5,4],
[67,59,6,0],
[67,59,6,1],
[67,59,6,2],
[67,59,6,4],
[67,59,8,0],
[67,59,8,1],
[67,59,8,2],
[67,59,8,4],
[67,59,10,0],
[67,59,10,1],
[67,59,10,2],
[67,59,10,5],
[67,59,10,6],
[67,59,10,8],
[67,59,11,0],
[67,59,11,1],
[67,59,11,2],
[67,59,11,5],
[67,59,11,6],
[67,59,11,8],
[67,59,11,10],
[67,59,12,3],
[67,59,12,4],
[67,59,12,5],
[67,59,12,6],
[67,59,12,8],
[67,59,12,10],
[67,59,13,3],
[67,59,13,4],
[67,59,13,5],
[67,59,13,6],
[67,59,13,8],
[67,59,13,10],
[67,59,13,11],
[67,59,13,12],
[67,59,14,0],
[67,59,14,1],
[67,59,14,2],
[67,59,14,3],
[67,59,14,4],
[67,59,14,5],
[67,59,14,6],
[67,59,14,8],
[67,59,14,10],
[67,59,14,11],
[67,59,14,12],
[67,59,16,0],
[67,59,16,1],
[67,59,16,2],
[67,59,16,3],
[67,59,16,4],
[67,59,16,5],
[67,59,16,6],
[67,59,16,8],
[67,59,16,10],
[67,59,16,11],
[67,59,16,12],
[67,59,16,13],
[67,59,16,14],
[67,59,17,0],
[67,59,17,1],
[67,59,17,2],
[67,59,17,3],
[67,59,17,4],
[67,59,17,5],
[67,59,17,6],
[67,59,17,8],
[67,59,17,10],
[67,59,17,11],
[67,59,17,12],
[67,59,17,13],
[67,59,17,14],
[67,59,17,16],
[67,59,19,0],
[67,59,19,1],
[67,59,19,2],
[67,59,19,3],
[67,59,19,4],
[67,59,19,5],
[67,59,19,6],
[67,59,19,8],
[67,59,19,10],
[67,59,19,11],
[67,59,19,12],
[67,59,20,0],
[67,59,20,1],
[67,59,20,2],
[67,59,20,3],
[67,59,20,4],
[67,59,20,5],
[67,59,20,6],
[67,59,20,8],
[67,59,20,11],
[67,59,20,12],
[67,59,20,13],
[67,59,21,0],
[67,59,21,1],
[67,59,21,2],
[67,59,21,4],
[67,59,21,10],
[67,59,21,11],
[67,59,22,0],
[67,59,22,1],
[67,59,22,2],
[67,59,22,3],
[67,59,22,4],
[67,59,22,5],
[67,59,22,6],
[67,59,22,8],
[67,59,23,0],
[67,59,23,1],
[67,59,23,2],
[67,59,23,3],
[67,59,23,4],
[67,59,23,5],
[67,59,23,6],
[67,59,24,0],
[67,59,24,1],
[67,59,24,2],
[67,59,24,3],
[67,59,24,4],
[67,59,24,5],
[67,59,24,6],
[67,60,3,0],
[67,60,3,1],
[67,60,3,2],
[67,60,4,0],
[67,60,4,1],
[67,60,4,2],
[67,60,4,3],
[67,60,5,0],
[67,60,5,1],
[67,60,5,2],
[67,60,5,4],
[67,60,6,0],
[67,60,6,1],
[67,60,6,2],
[67,60,6,4],
[67,60,8,0],
[67,60,8,1],
[67,60,8,2],
[67,60,9,0],
[67,60,9,1],
[67,60,9,2],
[67,60,9,5],
[67,60,9,6],
[67,60,9,8],
[67,60,10,3],
[67,60,10,4],
[67,60,10,5],
[67,60,10,6],
[67,60,10,8],
[67,60,10,9],
[67,60,11,3],
[67,60,11,4],
[67,60,11,5],
[67,60,11,6],
[67,60,11,8],
[67,60,11,9],
[67,60,11,10],
[67,60,12,0],
[67,60,12,1],
[67,60,12,2],
[67,60,12,3],
[67,60,12,4],
[67,60,12,5],
[67,60,12,6],
[67,60,12,8],
[67,60,12,9],
[67,60,12,10],
[67,60,15,0],
[67,60,15,1],
[67,60,15,2],
[67,60,15,3],
[67,60,15,4],
[67,60,15,5],
[67,60,15,6],
[67,60,15,8],
[67,60,15,10],
[67,60,15,11],
[67,60,15,12],
[67,60,16,0],
[67,60,16,1],
[67,60,16,2],
[67,60,16,3],
[67,60,16,4],
[67,60,16,5],
[67,60,16,6],
[67,60,16,8],
[67,60,16,9],
[67,60,16,10],
[67,60,16,11],
[67,60,16,12],
[67,60,16,15],
[67,60,17,0],
[67,60,17,1],
[67,60,17,2],
[67,60,17,3],
[67,60,17,4],
[67,60,17,5],
[67,60,17,6],
[67,60,17,8],
[67,60,17,9],
[67,60,17,10],
[67,60,17,11],
[67,60,17,12],
[67,60,17,15],
[67,60,18,0],
[67,60,18,1],
[67,60,18,2],
[67,60,18,3],
[67,60,18,4],
[67,60,18,5],
[67,60,18,6],
[67,60,18,8],
[67,60,18,10],
[67,60,18,11],
[67,60,18,12],
[67,60,20,0],
[67,60,20,1],
[67,60,20,2],
[67,60,20,3],
[67,60,20,4],
[67,60,20,5],
[67,60,20,6],
[67,60,20,8],
[67,60,20,9],
[67,60,20,11],
[67,60,21,0],
[67,60,21,1],
[67,60,21,2],
[67,60,21,4],
[67,60,21,9],
[67,60,22,0],
[67,60,22,1],
[67,60,22,2],
[67,60,22,3],
[67,60,22,4],
[67,60,22,5],
[67,60,22,6],
[67,60,23,0],
[67,60,23,1],
[67,60,23,2],
[67,60,23,3],
[67,60,23,4],
[67,60,23,5],
[67,60,24,0],
[67,60,24,1],
[67,60,24,2],
[67,60,24,3],
[67,60,24,4],
[67,60,24,5],
[67,61,3,0],
[67,61,3,1],
[67,61,3,2],
[67,61,5,0],
[67,61,5,1],
[67,61,5,2],
[67,61,6,0],
[67,61,6,1],
[67,61,6,2],
[67,61,9,3],
[67,61,9,5],
[67,61,9,6],
[67,61,9,8],
[67,61,10,0],
[67,61,10,1],
[67,61,10,2],
[67,61,10,3],
[67,61,10,5],
[67,61,10,6],
[67,61,10,8],
[67,61,10,9],
[67,61,11,0],
[67,61,11,1],
[67,61,11,2],
[67,61,11,3],
[67,61,11,5],
[67,61,11,6],
[67,61,11,8],
[67,61,11,9],
[67,61,11,10],
[67,61,12,0],
[67,61,12,1],
[67,61,12,2],
[67,61,12,3],
[67,61,12,5],
[67,61,12,6],
[67,61,12,8],
[67,61,12,9],
[67,61,12,10],
[67,61,13,0],
[67,61,13,1],
[67,61,13,2],
[67,61,13,3],
[67,61,13,5],
[67,61,13,6],
[67,61,13,8],
[67,61,13,9],
[67,61,13,10],
[67,61,13,11],
[67,61,13,12],
[67,61,14,0],
[67,61,14,1],
[67,61,14,2],
[67,61,14,3],
[67,61,14,5],
[67,61,14,6],
[67,61,14,8],
[67,61,14,9],
[67,61,14,10],
[67,61,14,11],
[67,61,14,12],
[67,61,15,0],
[67,61,15,1],
[67,61,15,2],
[67,61,15,3],
[67,61,15,5],
[67,61,15,6],
[67,61,15,8],
[67,61,15,10],
[67,61,15,11],
[67,61,15,12],
[67,61,15,13],
[67,61,15,14],
[67,61,16,0],
[67,61,16,1],
[67,61,16,2],
[67,61,16,3],
[67,61,16,5],
[67,61,16,6],
[67,61,16,8],
[67,61,16,9],
[67,61,16,10],
[67,61,16,11],
[67,61,16,12],
[67,61,16,13],
[67,61,16,14],
[67,61,17,0],
[67,61,17,1],
[67,61,17,2],
[67,61,17,3],
[67,61,17,5],
[67,61,17,6],
[67,61,17,8],
[67,61,17,9],
[67,61,17,10],
[67,61,17,11],
[67,61,17,12],
[67,61,17,13],
[67,61,17,14],
[67,61,18,0],
[67,61,18,1],
[67,61,18,2],
[67,61,18,3],
[67,61,18,5],
[67,61,18,6],
[67,61,18,8],
[67,61,18,10],
[67,61,18,11],
[67,61,18,12],
[67,61,18,13],
[67,61,19,0],
[67,61,19,1],
[67,61,19,2],
[67,61,19,3],
[67,61,19,5],
[67,61,19,6],
[67,61,19,8],
[67,61,19,9],
[67,61,19,10],
[67,61,19,11],
[67,61,20,0],
[67,61,20,1],
[67,61,20,2],
[67,61,20,3],
[67,61,20,5],
[67,61,20,6],
[67,61,20,8],
[67,61,20,9],
[67,61,21,0],
[67,61,21,1],
[67,61,21,2],
[67,61,22,0],
[67,61,22,1],
[67,61,22,2],
[67,61,22,3],
[67,61,22,5],
[67,61,23,0],
[67,61,23,1],
[67,61,23,2],
[67,61,23,3],
[67,61,24,0],
[67,61,24,1],
[67,61,24,2],
[67,61,24,3],
[67,63,3,0],
[67,63,3,1],
[67,63,3,2],
[67,63,4,0],
[67,63,4,1],
[67,63,4,2],
[67,63,4,3],
[67,63,5,0],
[67,63,5,1],
[67,63,5,2],
[67,63,6,4],
[67,63,8,0],
[67,63,8,1],
[67,63,8,2],
[67,63,8,4],
[67,63,10,0],
[67,63,10,1],
[67,63,10,2],
[67,63,10,3],
[67,63,10,4],
[67,63,10,5],
[67,63,10,6],
[67,63,10,8],
[67,63,11,0],
[67,63,11,1],
[67,63,11,2],
[67,63,11,3],
[67,63,11,4],
[67,63,11,5],
[67,63,11,6],
[67,63,11,8],
[67,63,11,10],
[67,63,12,0],
[67,63,12,1],
[67,63,12,2],
[67,63,12,3],
[67,63,12,4],
[67,63,12,5],
[67,63,12,6],
[67,63,12,8],
[67,63,12,10],
[67,63,13,0],
[67,63,13,1],
[67,63,13,2],
[67,63,13,3],
[67,63,13,4],
[67,63,13,5],
[67,63,13,6],
[67,63,13,8],
[67,63,13,10],
[67,63,13,11],
[67,63,13,12],
[67,63,14,0],
[67,63,14,1],
[67,63,14,2],
[67,63,14,3],
[67,63,14,4],
[67,63,14,5],
[67,63,14,6],
[67,63,14,8],
[67,63,14,10],
[67,63,14,11],
[67,63,14,12],
[67,63,16,0],
[67,63,16,1],
[67,63,16,2],
[67,63,16,3],
[67,63,16,4],
[67,63,16,5],
[67,63,16,6],
[67,63,16,8],
[67,63,16,10],
[67,63,16,11],
[67,63,16,12],
[67,63,16,13],
[67,63,17,0],
[67,63,17,1],
[67,63,17,2],
[67,63,17,3],
[67,63,17,4],
[67,63,17,5],
[67,63,17,6],
[67,63,17,8],
[67,63,17,10],
[67,63,17,11],
[67,63,17,12],
[67,63,17,13],
[67,63,19,0],
[67,63,19,1],
[67,63,19,2],
[67,63,19,3],
[67,63,19,4],
[67,63,19,5],
[67,63,19,6],
[67,63,19,8],
[67,63,20,0],
[67,63,20,1],
[67,63,20,2],
[67,63,20,3],
[67,63,20,4],
[67,63,20,5],
[67,63,20,6],
[67,63,21,0],
[67,63,21,1],
[67,63,21,2],
[67,63,21,4],
[67,63,22,0],
[67,63,22,1],
[67,63,22,2],
[67,63,22,3],
[67,63,22,4],
[67,63,23,0],
[67,63,23,1],
[67,63,23,2],
[67,63,24,0],
[67,63,24,1],
[67,63,24,2],
[67,64,3,0],
[67,64,3,1],
[67,64,3,2],
[67,64,6,0],
[67,64,6,1],
[67,64,6,2],
[67,64,8,0],
[67,64,8,1],
[67,64,8,2],
[67,64,9,0],
[67,64,9,1],
[67,64,9,2],
[67,64,9,3],
[67,64,9,5],
[67,64,9,6],
[67,64,9,8],
[67,64,10,0],
[67,64,10,1],
[67,64,10,2],
[67,64,10,3],
[67,64,10,5],
[67,64,10,6],
[67,64,10,8],
[67,64,10,9],
[67,64,11,0],
[67,64,11,1],
[67,64,11,2],
[67,64,11,3],
[67,64,11,5],
[67,64,11,6],
[67,64,11,8],
[67,64,11,9],
[67,64,11,10],
[67,64,12,0],
[67,64,12,1],
[67,64,12,2],
[67,64,12,3],
[67,64,12,5],
[67,64,12,6],
[67,64,12,8],
[67,64,12,9],
[67,64,12,10],
[67,64,13,0],
[67,64,13,1],
[67,64,13,2],
[67,64,13,3],
[67,64,13,5],
[67,64,13,6],
[67,64,13,8],
[67,64,13,9],
[67,64,13,10],
[67,64,13,11],
[67,64,13,12],
[67,64,14,0],
[67,64,14,1],
[67,64,14,2],
[67,64,14,3],
[67,64,14,5],
[67,64,14,6],
[67,64,14,8],
[67,64,14,9],
[67,64,14,10],
[67,64,14,11],
[67,64,14,12],
[67,64,15,0],
[67,64,15,1],
[67,64,15,2],
[67,64,15,3],
[67,64,15,5],
[67,64,15,6],
[67,64,15,8],
[67,64,15,10],
[67,64,15,11],
[67,64,15,12],
[67,64,15,13],
[67,64,16,0],
[67,64,16,1],
[67,64,16,2],
[67,64,16,3],
[67,64,16,5],
[67,64,16,6],
[67,64,16,8],
[67,64,16,9],
[67,64,16,10],
[67,64,16,11],
[67,64,17,0],
[67,64,17,1],
[67,64,17,2],
[67,64,17,3],
[67,64,17,5],
[67,64,17,6],
[67,64,17,8],
[67,64,17,9],
[67,64,17,10],
[67,64,17,11],
[67,64,18,0],
[67,64,18,1],
[67,64,18,2],
[67,64,18,3],
[67,64,18,5],
[67,64,18,6],
[67,64,18,8],
[67,64,19,0],
[67,64,19,1],
[67,64,19,2],
[67,64,19,3],
[67,64,19,5],
[67,64,19,6],
[67,64,20,0],
[67,64,20,1],
[67,64,20,2],
[67,64,20,3],
[67,64,20,5],
[67,64,21,0],
[67,64,21,1],
[67,64,21,2],
[67,64,22,0],
[67,64,22,1],
[67,64,22,2],
[67,65,4,3],
[67,65,5,0],
[67,65,5,1],
[67,65,5,2],
[67,65,5,4],
[67,65,6,0],
[67,65,6,1],
[67,65,6,2],
[67,65,6,4],
[67,65,8,0],
[67,65,8,1],
[67,65,8,2],
[67,65,8,4],
[67,65,9,0],
[67,65,9,1],
[67,65,9,2],
[67,65,9,3],
[67,65,9,4],
[67,65,9,5],
[67,65,9,6],
[67,65,9,8],
[67,65,10,0],
[67,65,10,1],
[67,65,10,2],
[67,65,10,3],
[67,65,10,4],
[67,65,10,5],
[67,65,10,6],
[67,65,10,8],
[67,65,10,9],
[67,65,11,0],
[67,65,11,1],
[67,65,11,2],
[67,65,11,3],
[67,65,11,4],
[67,65,11,5],
[67,65,11,6],
[67,65,11,8],
[67,65,11,9],
[67,65,11,10],
[67,65,12,0],
[67,65,12,1],
[67,65,12,2],
[67,65,12,3],
[67,65,12,4],
[67,65,12,5],
[67,65,12,6],
[67,65,12,8],
[67,65,12,9],
[67,65,12,10],
[67,65,13,0],
[67,65,13,1],
[67,65,13,2],
[67,65,13,3],
[67,65,13,4],
[67,65,13,5],
[67,65,13,6],
[67,65,13,8],
[67,65,13,9],
[67,65,13,10],
[67,65,13,11],
[67,65,13,12],
[67,65,14,0],
[67,65,14,1],
[67,65,14,2],
[67,65,14,3],
[67,65,14,4],
[67,65,14,5],
[67,65,14,6],
[67,65,14,8],
[67,65,14,9],
[67,65,14,10],
[67,65,14,11],
[67,65,14,12],
[67,65,15,0],
[67,65,15,1],
[67,65,15,2],
[67,65,15,3],
[67,65,15,4],
[67,65,15,5],
[67,65,15,6],
[67,65,15,8],
[67,65,15,10],
[67,65,15,11],
[67,65,16,0],
[67,65,16,1],
[67,65,16,2],
[67,65,16,3],
[67,65,16,4],
[67,65,16,5],
[67,65,16,6],
[67,65,16,8],
[67,65,16,9],
[67,65,17,0],
[67,65,17,1],
[67,65,17,2],
[67,65,17,3],
[67,65,17,4],
[67,65,17,5],
[67,65,17,6],
[67,65,17,8],
[67,65,17,9],
[67,65,18,0],
[67,65,18,1],
[67,65,18,2],
[67,65,18,3],
[67,65,18,4],
[67,65,18,5],
[67,65,18,6],
[67,65,19,0],
[67,65,19,1],
[67,65,19,2],
[67,65,19,3],
[67,65,19,4],
[67,65,19,5],
[67,65,20,0],
[67,65,20,1],
[67,65,20,2],
[67,65,20,3],
[67,65,20,4],
[67,65,21,0],
[67,65,21,1],
[67,65,21,2],
[68,4,3,0],
[68,4,3,1],
[68,4,3,2],
[68,5,4,0],
[68,5,4,1],
[68,5,4,2],
[68,6,4,0],
[68,6,4,1],
[68,6,4,2],
[68,8,4,0],
[68,8,4,1],
[68,8,4,2],
[68,9,3,0],
[68,9,3,1],
[68,9,3,2],
[68,9,4,0],
[68,9,4,1],
[68,9,4,2],
[68,9,4,3],
[68,9,5,0],
[68,9,5,1],
[68,9,5,2],
[68,9,5,4],
[68,9,6,0],
[68,9,6,1],
[68,9,6,2],
[68,9,6,4],
[68,9,8,0],
[68,9,8,1],
[68,9,8,2],
[68,9,8,4],
[68,10,3,0],
[68,10,3,1],
[68,10,3,2],
[68,10,4,0],
[68,10,4,1],
[68,10,4,2],
[68,10,4,3],
[68,10,5,0],
[68,10,5,1],
[68,10,5,2],
[68,10,5,4],
[68,10,6,0],
[68,10,6,1],
[68,10,6,2],
[68,10,6,4],
[68,10,8,0],
[68,10,8,1],
[68,10,8,2],
[68,10,8,4],
[68,10,9,0],
[68,10,9,1],
[68,10,9,2],
[68,10,9,3],
[68,10,9,4],
[68,10,9,5],
[68,10,9,6],
[68,10,9,8],
[68,11,3,0],
[68,11,3,1],
[68,11,3,2],
[68,11,4,0],
[68,11,4,1],
[68,11,4,2],
[68,11,4,3],
[68,11,5,0],
[68,11,5,1],
[68,11,5,2],
[68,11,5,4],
[68,11,6,0],
[68,11,6,1],
[68,11,6,2],
[68,11,6,4],
[68,11,8,0],
[68,11,8,1],
[68,11,8,2],
[68,11,8,4],
[68,11,9,0],
[68,11,9,1],
[68,11,9,2],
[68,11,9,3],
[68,11,9,4],
[68,11,9,5],
[68,11,9,6],
[68,11,9,8],
[68,11,10,0],
[68,11,10,1],
[68,11,10,2],
[68,11,10,3],
[68,11,10,4],
[68,11,10,5],
[68,11,10,6],
[68,11,10,8],
[68,11,10,9],
[68,12,3,0],
[68,12,3,1],
[68,12,3,2],
[68,12,4,0],
[68,12,4,1],
[68,12,4,2],
[68,12,4,3],
[68,12,5,0],
[68,12,5,1],
[68,12,5,2],
[68,12,5,4],
[68,12,6,0],
[68,12,6,1],
[68,12,6,2],
[68,12,6,4],
[68,12,8,0],
[68,12,8,1],
[68,12,8,2],
[68,12,8,4],
[68,12,9,0],
[68,12,9,1],
[68,12,9,2],
[68,12,9,3],
[68,12,9,4],
[68,12,9,5],
[68,12,9,6],
[68,12,9,8],
[68,12,10,0],
[68,12,10,1],
[68,12,10,2],
[68,12,10,3],
[68,12,10,4],
[68,12,10,5],
[68,12,10,6],
[68,12,10,8],
[68,12,10,9],
[68,13,3,0],
[68,13,3,1],
[68,13,3,2],
[68,13,4,0],
[68,13,4,1],
[68,13,4,2],
[68,13,4,3],
[68,13,5,0],
[68,13,5,1],
[68,13,5,2],
[68,13,5,4],
[68,13,6,0],
[68,13,6,1],
[68,13,6,2],
[68,13,6,4],
[68,13,8,0],
[68,13,8,1],
[68,13,8,2],
[68,13,8,4],
[68,13,9,0],
[68,13,9,1],
[68,13,9,2],
[68,13,9,3],
[68,13,9,4],
[68,13,9,5],
[68,13,9,6],
[68,13,9,8],
[68,13,10,0],
[68,13,10,1],
[68,13,10,2],
[68,13,10,3],
[68,13,10,4],
[68,13,10,5],
[68,13,10,6],
[68,13,10,8],
[68,13,10,9],
[68,13,11,0],
[68,13,11,1],
[68,13,11,2],
[68,13,11,3],
[68,13,11,4],
[68,13,11,5],
[68,13,11,6],
[68,13,11,8],
[68,13,11,9],
[68,13,11,10],
[68,13,12,0],
[68,13,12,1],
[68,13,12,2],
[68,13,12,3],
[68,13,12,4],
[68,13,12,5],
[68,13,12,6],
[68,13,12,8],
[68,13,12,9],
[68,13,12,10],
[68,14,3,0],
[68,14,3,1],
[68,14,3,2],
[68,14,4,0],
[68,14,4,1],
[68,14,4,2],
[68,14,4,3],
[68,14,5,0],
[68,14,5,1],
[68,14,5,2],
[68,14,5,4],
[68,14,6,0],
[68,14,6,1],
[68,14,6,2],
[68,14,6,4],
[68,14,8,0],
[68,14,8,1],
[68,14,8,2],
[68,14,8,4],
[68,14,9,0],
[68,14,9,1],
[68,14,9,2],
[68,14,9,3],
[68,14,9,4],
[68,14,9,5],
[68,14,9,6],
[68,14,9,8],
[68,14,10,0],
[68,14,10,1],
[68,14,10,2],
[68,14,10,3],
[68,14,10,4],
[68,14,10,5],
[68,14,10,6],
[68,14,10,8],
[68,14,10,9],
[68,14,11,0],
[68,14,11,1],
[68,14,11,2],
[68,14,11,3],
[68,14,11,4],
[68,14,11,5],
[68,14,11,6],
[68,14,11,8],
[68,14,11,9],
[68,14,11,10],
[68,14,12,0],
[68,14,12,1],
[68,14,12,2],
[68,14,12,3],
[68,14,12,4],
[68,14,12,5],
[68,14,12,6],
[68,14,12,8],
[68,14,12,9],
[68,14,12,10],
[68,15,3,0],
[68,15,3,1],
[68,15,3,2],
[68,15,4,0],
[68,15,4,1],
[68,15,4,2],
[68,15,4,3],
[68,15,5,0],
[68,15,5,1],
[68,15,5,2],
[68,15,5,4],
[68,15,6,0],
[68,15,6,1],
[68,15,6,2],
[68,15,6,4],
[68,15,8,0],
[68,15,8,1],
[68,15,8,2],
[68,15,8,4],
[68,15,10,0],
[68,15,10,1],
[68,15,10,2],
[68,15,10,3],
[68,15,10,4],
[68,15,10,5],
[68,15,10,6],
[68,15,10,8],
[68,15,11,0],
[68,15,11,1],
[68,15,11,2],
[68,15,11,3],
[68,15,11,4],
[68,15,11,5],
[68,15,11,6],
[68,15,11,8],
[68,15,11,10],
[68,15,12,0],
[68,15,12,1],
[68,15,12,2],
[68,15,12,3],
[68,15,12,4],
[68,15,12,5],
[68,15,12,6],
[68,15,12,8],
[68,15,12,10],
[68,15,13,0],
[68,15,13,1],
[68,15,13,2],
[68,15,13,3],
[68,15,13,4],
[68,15,13,5],
[68,15,13,6],
[68,15,13,8],
[68,15,13,10],
[68,15,13,11],
[68,15,13,12],
[68,15,14,0],
[68,15,14,1],
[68,15,14,2],
[68,15,14,3],
[68,15,14,4],
[68,15,14,5],
[68,15,14,6],
[68,15,14,8],
[68,15,14,10],
[68,15,14,11],
[68,15,14,12],
[68,16,3,0],
[68,16,3,1],
[68,16,3,2],
[68,16,4,0],
[68,16,4,1],
[68,16,4,2],
[68,16,4,3],
[68,16,5,0],
[68,16,5,1],
[68,16,5,2],
[68,16,5,4],
[68,16,6,0],
[68,16,6,1],
[68,16,6,2],
[68,16,6,4],
[68,16,8,0],
[68,16,8,1],
[68,16,8,2],
[68,16,8,4],
[68,16,9,0],
[68,16,9,1],
[68,16,9,2],
[68,16,9,3],
[68,16,9,4],
[68,16,9,5],
[68,16,9,6],
[68,16,9,8],
[68,16,10,0],
[68,16,10,1],
[68,16,10,2],
[68,16,10,3],
[68,16,10,4],
[68,16,10,5],
[68,16,10,6],
[68,16,10,8],
[68,16,10,9],
[68,16,11,0],
[68,16,11,1],
[68,16,11,2],
[68,16,11,3],
[68,16,11,4],
[68,16,11,5],
[68,16,11,6],
[68,16,11,8],
[68,16,11,9],
[68,16,11,10],
[68,16,12,0],
[68,16,12,1],
[68,16,12,2],
[68,16,12,3],
[68,16,12,4],
[68,16,12,5],
[68,16,12,6],
[68,16,12,8],
[68,16,12,9],
[68,16,12,10],
[68,16,13,0],
[68,16,13,1],
[68,16,13,2],
[68,16,13,3],
[68,16,13,4],
[68,16,13,5],
[68,16,13,6],
[68,16,13,8],
[68,16,13,9],
[68,16,13,10],
[68,16,13,11],
[68,16,13,12],
[68,16,14,0],
[68,16,14,1],
[68,16,14,2],
[68,16,14,3],
[68,16,14,4],
[68,16,14,5],
[68,16,14,6],
[68,16,14,8],
[68,16,14,9],
[68,16,14,10],
[68,16,14,11],
[68,16,14,12],
[68,16,15,0],
[68,16,15,1],
[68,16,15,2],
[68,16,15,3],
[68,16,15,4],
[68,16,15,5],
[68,16,15,6],
[68,16,15,8],
[68,16,15,10],
[68,16,15,11],
[68,16,15,12],
[68,16,15,13],
[68,16,15,14],
[68,17,3,0],
[68,17,3,1],
[68,17,3,2],
[68,17,4,0],
[68,17,4,1],
[68,17,4,2],
[68,17,4,3],
[68,17,5,0],
[68,17,5,1],
[68,17,5,2],
[68,17,5,4],
[68,17,6,0],
[68,17,6,1],
[68,17,6,2],
[68,17,6,4],
[68,17,8,0],
[68,17,8,1],
[68,17,8,2],
[68,17,8,4],
[68,17,9,0],
[68,17,9,1],
[68,17,9,2],
[68,17,9,3],
[68,17,9,4],
[68,17,9,5],
[68,17,9,6],
[68,17,9,8],
[68,17,10,0],
[68,17,10,1],
[68,17,10,2],
[68,17,10,3],
[68,17,10,4],
[68,17,10,5],
[68,17,10,6],
[68,17,10,8],
[68,17,10,9],
[68,17,11,0],
[68,17,11,1],
[68,17,11,2],
[68,17,11,3],
[68,17,11,4],
[68,17,11,5],
[68,17,11,6],
[68,17,11,8],
[68,17,11,9],
[68,17,11,10],
[68,17,12,0],
[68,17,12,1],
[68,17,12,2],
[68,17,12,3],
[68,17,12,4],
[68,17,12,5],
[68,17,12,6],
[68,17,12,8],
[68,17,12,9],
[68,17,12,10],
[68,17,13,0],
[68,17,13,1],
[68,17,13,2],
[68,17,13,3],
[68,17,13,4],
[68,17,13,5],
[68,17,13,6],
[68,17,13,8],
[68,17,13,9],
[68,17,13,10],
[68,17,13,11],
[68,17,13,12],
[68,17,14,0],
[68,17,14,1],
[68,17,14,2],
[68,17,14,3],
[68,17,14,4],
[68,17,14,5],
[68,17,14,6],
[68,17,14,8],
[68,17,14,9],
[68,17,14,10],
[68,17,14,11],
[68,17,14,12],
[68,17,15,0],
[68,17,15,1],
[68,17,15,2],
[68,17,15,3],
[68,17,15,4],
[68,17,15,5],
[68,17,15,6],
[68,17,15,8],
[68,17,15,10],
[68,17,15,11],
[68,17,15,12],
[68,17,15,13],
[68,17,15,14],
[68,17,16,0],
[68,17,16,1],
[68,17,16,2],
[68,17,16,3],
[68,17,16,4],
[68,17,16,5],
[68,17,16,6],
[68,17,16,8],
[68,17,16,9],
[68,17,16,10],
[68,17,16,11],
[68,17,16,12],
[68,17,16,13],
[68,17,16,14],
[68,17,16,15],
[68,18,3,0],
[68,18,3,1],
[68,18,3,2],
[68,18,4,0],
[68,18,4,1],
[68,18,4,2],
[68,18,4,3],
[68,18,5,0],
[68,18,5,1],
[68,18,5,2],
[68,18,5,4],
[68,18,6,0],
[68,18,6,1],
[68,18,6,2],
[68,18,6,4],
[68,18,8,0],
[68,18,8,1],
[68,18,8,2],
[68,18,8,4],
[68,18,10,0],
[68,18,10,1],
[68,18,10,2],
[68,18,10,3],
[68,18,10,4],
[68,18,10,5],
[68,18,10,6],
[68,18,10,8],
[68,18,11,0],
[68,18,11,1],
[68,18,11,2],
[68,18,11,3],
[68,18,11,4],
[68,18,11,5],
[68,18,11,6],
[68,18,11,8],
[68,18,11,10],
[68,18,12,0],
[68,18,12,1],
[68,18,12,2],
[68,18,12,3],
[68,18,12,4],
[68,18,12,5],
[68,18,12,6],
[68,18,12,8],
[68,18,12,10],
[68,18,13,0],
[68,18,13,1],
[68,18,13,2],
[68,18,13,3],
[68,18,13,4],
[68,18,13,5],
[68,18,13,6],
[68,18,13,8],
[68,18,13,10],
[68,18,13,11],
[68,18,13,12],
[68,18,14,0],
[68,18,14,1],
[68,18,14,2],
[68,18,14,3],
[68,18,14,4],
[68,18,14,5],
[68,18,14,6],
[68,18,14,8],
[68,18,14,10],
[68,18,14,11],
[68,18,14,12],
[68,18,16,0],
[68,18,16,1],
[68,18,16,2],
[68,18,16,3],
[68,18,16,4],
[68,18,16,5],
[68,18,16,6],
[68,18,16,8],
[68,18,16,10],
[68,18,16,11],
[68,18,16,12],
[68,18,16,13],
[68,18,16,14],
[68,18,17,0],
[68,18,17,1],
[68,18,17,2],
[68,18,17,3],
[68,18,17,4],
[68,18,17,5],
[68,18,17,6],
[68,18,17,8],
[68,18,17,10],
[68,18,17,11],
[68,18,17,12],
[68,18,17,13],
[68,18,17,14],
[68,18,17,16],
[68,19,3,0],
[68,19,3,1],
[68,19,3,2],
[68,19,4,0],
[68,19,4,1],
[68,19,4,2],
[68,19,4,3],
[68,19,5,0],
[68,19,5,1],
[68,19,5,2],
[68,19,5,4],
[68,19,6,0],
[68,19,6,1],
[68,19,6,2],
[68,19,6,4],
[68,19,8,0],
[68,19,8,1],
[68,19,8,2],
[68,19,8,4],
[68,19,9,0],
[68,19,9,1],
[68,19,9,2],
[68,19,9,3],
[68,19,9,4],
[68,19,9,5],
[68,19,9,6],
[68,19,9,8],
[68,19,10,0],
[68,19,10,1],
[68,19,10,2],
[68,19,10,3],
[68,19,10,4],
[68,19,10,5],
[68,19,10,6],
[68,19,10,8],
[68,19,10,9],
[68,19,11,0],
[68,19,11,1],
[68,19,11,2],
[68,19,11,3],
[68,19,11,4],
[68,19,11,5],
[68,19,11,6],
[68,19,11,8],
[68,19,11,9],
[68,19,11,10],
[68,19,12,0],
[68,19,12,1],
[68,19,12,2],
[68,19,12,3],
[68,19,12,4],
[68,19,12,5],
[68,19,12,6],
[68,19,12,8],
[68,19,12,9],
[68,19,12,10],
[68,19,15,0],
[68,19,15,1],
[68,19,15,2],
[68,19,15,3],
[68,19,15,4],
[68,19,15,5],
[68,19,15,6],
[68,19,15,8],
[68,19,15,10],
[68,19,15,11],
[68,19,15,12],
[68,19,16,0],
[68,19,16,1],
[68,19,16,2],
[68,19,16,3],
[68,19,16,4],
[68,19,16,5],
[68,19,16,6],
[68,19,16,8],
[68,19,16,9],
[68,19,16,10],
[68,19,16,11],
[68,19,16,12],
[68,19,16,15],
[68,19,17,0],
[68,19,17,1],
[68,19,17,2],
[68,19,17,3],
[68,19,17,4],
[68,19,17,5],
[68,19,17,6],
[68,19,17,8],
[68,19,17,9],
[68,19,17,10],
[68,19,17,11],
[68,19,17,12],
[68,19,17,15],
[68,19,17,16],
[68,19,18,0],
[68,19,18,1],
[68,19,18,2],
[68,19,18,3],
[68,19,18,4],
[68,19,18,5],
[68,19,18,6],
[68,19,18,8],
[68,19,18,10],
[68,19,18,11],
[68,19,18,12],
[68,19,18,16],
[68,19,18,17],
[68,20,3,0],
[68,20,3,1],
[68,20,3,2],
[68,20,4,0],
[68,20,4,1],
[68,20,4,2],
[68,20,4,3],
[68,20,5,0],
[68,20,5,1],
[68,20,5,2],
[68,20,5,4],
[68,20,6,0],
[68,20,6,1],
[68,20,6,2],
[68,20,6,4],
[68,20,8,0],
[68,20,8,1],
[68,20,8,2],
[68,20,8,4],
[68,20,9,0],
[68,20,9,1],
[68,20,9,2],
[68,20,9,3],
[68,20,9,4],
[68,20,9,5],
[68,20,9,6],
[68,20,9,8],
[68,20,11,0],
[68,20,11,1],
[68,20,11,2],
[68,20,11,3],
[68,20,11,4],
[68,20,11,5],
[68,20,11,6],
[68,20,11,8],
[68,20,11,9],
[68,20,12,0],
[68,20,12,1],
[68,20,12,2],
[68,20,12,3],
[68,20,12,4],
[68,20,12,5],
[68,20,12,6],
[68,20,12,8],
[68,20,12,9],
[68,20,13,0],
[68,20,13,1],
[68,20,13,2],
[68,20,13,3],
[68,20,13,4],
[68,20,13,5],
[68,20,13,6],
[68,20,13,8],
[68,20,13,9],
[68,20,13,11],
[68,20,13,12],
[68,20,14,0],
[68,20,14,1],
[68,20,14,2],
[68,20,14,3],
[68,20,14,4],
[68,20,14,5],
[68,20,14,6],
[68,20,14,8],
[68,20,14,9],
[68,20,14,11],
[68,20,14,12],
[68,20,15,0],
[68,20,15,1],
[68,20,15,2],
[68,20,15,3],
[68,20,15,4],
[68,20,15,5],
[68,20,15,6],
[68,20,15,8],
[68,20,15,11],
[68,20,15,12],
[68,20,15,13],
[68,20,15,14],
[68,20,16,0],
[68,20,16,1],
[68,20,16,2],
[68,20,16,3],
[68,20,16,4],
[68,20,16,5],
[68,20,16,6],
[68,20,16,8],
[68,20,16,9],
[68,20,16,11],
[68,20,16,12],
[68,20,16,13],
[68,20,16,14],
[68,20,16,15],
[68,20,17,0],
[68,20,17,1],
[68,20,17,2],
[68,20,17,3],
[68,20,17,4],
[68,20,17,5],
[68,20,17,6],
[68,20,17,8],
[68,20,17,9],
[68,20,17,11],
[68,20,17,12],
[68,20,17,13],
[68,20,17,14],
[68,20,17,15],
[68,20,17,16],
[68,20,18,0],
[68,20,18,1],
[68,20,18,2],
[68,20,18,3],
[68,20,18,4],
[68,20,18,5],
[68,20,18,6],
[68,20,18,8],
[68,20,18,11],
[68,20,18,12],
[68,20,18,13],
[68,20,18,14],
[68,20,18,16],
[68,20,18,17],
[68,20,19,0],
[68,20,19,1],
[68,20,19,2],
[68,20,19,3],
[68,20,19,4],
[68,20,19,5],
[68,20,19,6],
[68,20,19,8],
[68,20,19,9],
[68,20,19,11],
[68,20,19,12],
[68,20,19,15],
[68,20,19,16],
[68,20,19,17],
[68,20,19,18],
[68,21,4,0],
[68,21,4,1],
[68,21,4,2],
[68,21,9,0],
[68,21,9,1],
[68,21,9,2],
[68,21,9,4],
[68,21,10,0],
[68,21,10,1],
[68,21,10,2],
[68,21,10,4],
[68,21,10,9],
[68,21,11,0],
[68,21,11,1],
[68,21,11,2],
[68,21,11,4],
[68,21,11,9],
[68,21,11,10],
[68,21,12,0],
[68,21,12,1],
[68,21,12,2],
[68,21,12,4],
[68,21,12,9],
[68,21,12,10],
[68,21,13,0],
[68,21,13,1],
[68,21,13,2],
[68,21,13,4],
[68,21,13,9],
[68,21,13,10],
[68,21,13,11],
[68,21,13,12],
[68,21,14,0],
[68,21,14,1],
[68,21,14,2],
[68,21,14,4],
[68,21,14,9],
[68,21,14,10],
[68,21,14,11],
[68,21,14,12],
[68,21,15,0],
[68,21,15,1],
[68,21,15,2],
[68,21,15,4],
[68,21,15,10],
[68,21,15,11],
[68,21,15,12],
[68,21,15,13],
[68,21,15,14],
[68,21,16,0],
[68,21,16,1],
[68,21,16,2],
[68,21,16,4],
[68,21,16,9],
[68,21,16,10],
[68,21,16,11],
[68,21,16,12],
[68,21,16,13],
[68,21,16,14],
[68,21,16,15],
[68,21,17,0],
[68,21,17,1],
[68,21,17,2],
[68,21,17,4],
[68,21,17,9],
[68,21,17,10],
[68,21,17,11],
[68,21,17,12],
[68,21,17,13],
[68,21,17,14],
[68,21,17,15],
[68,21,17,16],
[68,21,18,0],
[68,21,18,1],
[68,21,18,2],
[68,21,18,4],
[68,21,18,10],
[68,21,18,11],
[68,21,18,12],
[68,21,18,13],
[68,21,18,14],
[68,21,18,16],
[68,21,18,17],
[68,21,19,0],
[68,21,19,1],
[68,21,19,2],
[68,21,19,4],
[68,21,19,9],
[68,21,19,10],
[68,21,19,11],
[68,21,19,12],
[68,21,19,15],
[68,21,19,16],
[68,21,19,17],
[68,21,19,18],
[68,21,20,0],
[68,21,20,1],
[68,21,20,2],
[68,21,20,4],
[68,21,20,9],
[68,21,20,11],
[68,21,20,12],
[68,21,20,13],
[68,21,20,14],
[68,21,20,15],
[68,21,20,16],
[68,21,20,17],
[68,21,20,18],
[68,21,20,19],
[68,22,3,0],
[68,22,3,1],
[68,22,3,2],
[68,22,4,0],
[68,22,4,1],
[68,22,4,2],
[68,22,4,3],
[68,22,5,0],
[68,22,5,1],
[68,22,5,2],
[68,22,5,4],
[68,22,6,0],
[68,22,6,1],
[68,22,6,2],
[68,22,6,4],
[68,22,8,0],
[68,22,8,1],
[68,22,8,2],
[68,22,8,4],
[68,22,9,0],
[68,22,9,1],
[68,22,9,2],
[68,22,9,3],
[68,22,9,4],
[68,22,9,5],
[68,22,9,6],
[68,22,9,8],
[68,22,11,0],
[68,22,11,1],
[68,22,11,2],
[68,22,11,3],
[68,22,11,4],
[68,22,11,5],
[68,22,11,6],
[68,22,11,8],
[68,22,11,9],
[68,22,12,0],
[68,22,12,1],
[68,22,12,2],
[68,22,12,3],
[68,22,12,4],
[68,22,12,5],
[68,22,12,6],
[68,22,12,8],
[68,22,12,9],
[68,22,13,0],
[68,22,13,1],
[68,22,13,2],
[68,22,13,3],
[68,22,13,4],
[68,22,13,5],
[68,22,13,6],
[68,22,13,8],
[68,22,13,9],
[68,22,13,11],
[68,22,13,12],
[68,22,14,0],
[68,22,14,1],
[68,22,14,2],
[68,22,14,3],
[68,22,14,4],
[68,22,14,5],
[68,22,14,6],
[68,22,14,8],
[68,22,14,9],
[68,22,14,11],
[68,22,14,12],
[68,22,15,0],
[68,22,15,1],
[68,22,15,2],
[68,22,15,3],
[68,22,15,4],
[68,22,15,5],
[68,22,15,6],
[68,22,15,8],
[68,22,15,11],
[68,22,15,12],
[68,22,15,13],
[68,22,15,14],
[68,22,16,0],
[68,22,16,1],
[68,22,16,2],
[68,22,16,3],
[68,22,16,4],
[68,22,16,5],
[68,22,16,6],
[68,22,16,8],
[68,22,16,9],
[68,22,16,11],
[68,22,16,12],
[68,22,16,13],
[68,22,16,14],
[68,22,16,15],
[68,22,17,0],
[68,22,17,1],
[68,22,17,2],
[68,22,17,3],
[68,22,17,4],
[68,22,17,5],
[68,22,17,6],
[68,22,17,8],
[68,22,17,9],
[68,22,17,11],
[68,22,17,12],
[68,22,17,13],
[68,22,17,14],
[68,22,17,15],
[68,22,17,16],
[68,22,18,0],
[68,22,18,1],
[68,22,18,2],
[68,22,18,3],
[68,22,18,4],
[68,22,18,5],
[68,22,18,6],
[68,22,18,8],
[68,22,18,11],
[68,22,18,12],
[68,22,18,13],
[68,22,18,14],
[68,22,18,16],
[68,22,18,17],
[68,22,19,0],
[68,22,19,1],
[68,22,19,2],
[68,22,19,3],
[68,22,19,4],
[68,22,19,5],
[68,22,19,6],
[68,22,19,8],
[68,22,19,9],
[68,22,19,11],
[68,22,19,12],
[68,22,19,15],
[68,22,19,16],
[68,22,19,17],
[68,22,19,18],
[68,22,21,0],
[68,22,21,1],
[68,22,21,2],
[68,22,21,4],
[68,22,21,9],
[68,22,21,11],
[68,22,21,12],
[68,22,21,13],
[68,22,21,14],
[68,22,21,15],
[68,22,21,16],
[68,22,21,17],
[68,22,21,18],
[68,22,21,19],
[68,23,3,0],
[68,23,3,1],
[68,23,3,2],
[68,23,4,0],
[68,23,4,1],
[68,23,4,2],
[68,23,4,3],
[68,23,5,0],
[68,23,5,1],
[68,23,5,2],
[68,23,5,4],
[68,23,6,0],
[68,23,6,1],
[68,23,6,2],
[68,23,6,4],
[68,23,8,0],
[68,23,8,1],
[68,23,8,2],
[68,23,8,4],
[68,23,9,0],
[68,23,9,1],
[68,23,9,2],
[68,23,9,3],
[68,23,9,4],
[68,23,9,5],
[68,23,9,6],
[68,23,9,8],
[68,23,10,0],
[68,23,10,1],
[68,23,10,2],
[68,23,10,3],
[68,23,10,4],
[68,23,10,5],
[68,23,10,6],
[68,23,10,8],
[68,23,10,9],
[68,23,11,0],
[68,23,11,1],
[68,23,11,2],
[68,23,11,3],
[68,23,11,4],
[68,23,11,5],
[68,23,11,6],
[68,23,11,8],
[68,23,11,9],
[68,23,11,10],
[68,23,12,0],
[68,23,12,1],
[68,23,12,2],
[68,23,12,3],
[68,23,12,4],
[68,23,12,5],
[68,23,12,6],
[68,23,12,8],
[68,23,12,9],
[68,23,12,10],
[68,23,13,0],
[68,23,13,1],
[68,23,13,2],
[68,23,13,3],
[68,23,13,4],
[68,23,13,5],
[68,23,13,6],
[68,23,13,8],
[68,23,13,9],
[68,23,13,10],
[68,23,13,11],
[68,23,13,12],
[68,23,14,0],
[68,23,14,1],
[68,23,14,2],
[68,23,14,3],
[68,23,14,4],
[68,23,14,5],
[68,23,14,6],
[68,23,14,8],
[68,23,14,9],
[68,23,14,10],
[68,23,14,11],
[68,23,14,12],
[68,23,15,0],
[68,23,15,1],
[68,23,15,2],
[68,23,15,3],
[68,23,15,4],
[68,23,15,5],
[68,23,15,6],
[68,23,15,8],
[68,23,15,10],
[68,23,15,11],
[68,23,15,12],
[68,23,15,13],
[68,23,15,14],
[68,23,16,0],
[68,23,16,1],
[68,23,16,2],
[68,23,16,3],
[68,23,16,4],
[68,23,16,5],
[68,23,16,6],
[68,23,16,8],
[68,23,16,9],
[68,23,16,10],
[68,23,16,11],
[68,23,16,12],
[68,23,16,13],
[68,23,16,14],
[68,23,16,15],
[68,23,17,0],
[68,23,17,1],
[68,23,17,2],
[68,23,17,3],
[68,23,17,4],
[68,23,17,5],
[68,23,17,6],
[68,23,17,8],
[68,23,17,9],
[68,23,17,10],
[68,23,17,11],
[68,23,17,12],
[68,23,17,13],
[68,23,17,14],
[68,23,17,15],
[68,23,17,16],
[68,23,18,0],
[68,23,18,1],
[68,23,18,2],
[68,23,18,3],
[68,23,18,4],
[68,23,18,5],
[68,23,18,6],
[68,23,18,8],
[68,23,18,10],
[68,23,18,11],
[68,23,18,12],
[68,23,18,13],
[68,23,18,14],
[68,23,18,16],
[68,23,18,17],
[68,23,19,0],
[68,23,19,1],
[68,23,19,2],
[68,23,19,3],
[68,23,19,4],
[68,23,19,5],
[68,23,19,6],
[68,23,19,8],
[68,23,19,9],
[68,23,19,10],
[68,23,19,11],
[68,23,19,12],
[68,23,19,15],
[68,23,19,16],
[68,23,19,17],
[68,23,19,18],
[68,23,20,0],
[68,23,20,1],
[68,23,20,2],
[68,23,20,3],
[68,23,20,4],
[68,23,20,5],
[68,23,20,6],
[68,23,20,8],
[68,23,20,9],
[68,23,20,11],
[68,23,20,12],
[68,23,20,13],
[68,23,20,14],
[68,23,20,15],
[68,23,20,16],
[68,23,20,17],
[68,23,20,18],
[68,23,20,19],
[68,23,21,0],
[68,23,21,1],
[68,23,21,2],
[68,23,21,4],
[68,23,21,9],
[68,23,21,10],
[68,23,21,11],
[68,23,21,12],
[68,23,21,13],
[68,23,21,14],
[68,23,21,15],
[68,23,21,16],
[68,23,21,17],
[68,23,21,18],
[68,23,21,19],
[68,23,21,20],
[68,23,22,0],
[68,23,22,1],
[68,23,22,2],
[68,23,22,3],
[68,23,22,4],
[68,23,22,5],
[68,23,22,6],
[68,23,22,8],
[68,23,22,9],
[68,23,22,11],
[68,23,22,12],
[68,23,22,13],
[68,23,22,14],
[68,23,22,15],
[68,23,22,16],
[68,23,22,17],
[68,23,22,18],
[68,23,22,19],
[68,23,22,21],
[68,24,3,0],
[68,24,3,1],
[68,24,3,2],
[68,24,4,0],
[68,24,4,1],
[68,24,4,2],
[68,24,4,3],
[68,24,5,0],
[68,24,5,1],
[68,24,5,2],
[68,24,5,4],
[68,24,6,0],
[68,24,6,1],
[68,24,6,2],
[68,24,6,4],
[68,24,8,0],
[68,24,8,1],
[68,24,8,2],
[68,24,8,4],
[68,24,9,0],
[68,24,9,1],
[68,24,9,2],
[68,24,9,3],
[68,24,9,4],
[68,24,9,5],
[68,24,9,6],
[68,24,9,8],
[68,24,10,0],
[68,24,10,1],
[68,24,10,2],
[68,24,10,3],
[68,24,10,4],
[68,24,10,5],
[68,24,10,6],
[68,24,10,8],
[68,24,10,9],
[68,24,11,0],
[68,24,11,1],
[68,24,11,2],
[68,24,11,3],
[68,24,11,4],
[68,24,11,5],
[68,24,11,6],
[68,24,11,8],
[68,24,11,9],
[68,24,11,10],
[68,24,12,0],
[68,24,12,1],
[68,24,12,2],
[68,24,12,3],
[68,24,12,4],
[68,24,12,5],
[68,24,12,6],
[68,24,12,8],
[68,24,12,9],
[68,24,12,10],
[68,24,13,0],
[68,24,13,1],
[68,24,13,2],
[68,24,13,3],
[68,24,13,4],
[68,24,13,5],
[68,24,13,6],
[68,24,13,8],
[68,24,13,9],
[68,24,13,10],
[68,24,13,11],
[68,24,13,12],
[68,24,14,0],
[68,24,14,1],
[68,24,14,2],
[68,24,14,3],
[68,24,14,4],
[68,24,14,5],
[68,24,14,6],
[68,24,14,8],
[68,24,14,9],
[68,24,14,10],
[68,24,14,11],
[68,24,14,12],
[68,24,15,0],
[68,24,15,1],
[68,24,15,2],
[68,24,15,3],
[68,24,15,4],
[68,24,15,5],
[68,24,15,6],
[68,24,15,8],
[68,24,15,10],
[68,24,15,11],
[68,24,15,12],
[68,24,15,13],
[68,24,15,14],
[68,24,17,0],
[68,24,17,1],
[68,24,17,2],
[68,24,17,3],
[68,24,17,4],
[68,24,17,5],
[68,24,17,6],
[68,24,17,8],
[68,24,17,9],
[68,24,17,10],
[68,24,17,11],
[68,24,17,12],
[68,24,17,13],
[68,24,17,14],
[68,24,17,15],
[68,24,18,0],
[68,24,18,1],
[68,24,18,2],
[68,24,18,3],
[68,24,18,4],
[68,24,18,5],
[68,24,18,6],
[68,24,18,8],
[68,24,18,10],
[68,24,18,11],
[68,24,18,12],
[68,24,18,13],
[68,24,18,14],
[68,24,18,17],
[68,24,19,0],
[68,24,19,1],
[68,24,19,2],
[68,24,19,3],
[68,24,19,4],
[68,24,19,5],
[68,24,19,6],
[68,24,19,8],
[68,24,19,9],
[68,24,19,10],
[68,24,19,11],
[68,24,19,12],
[68,24,19,15],
[68,24,19,17],
[68,24,19,18],
[68,24,20,0],
[68,24,20,1],
[68,24,20,2],
[68,24,20,3],
[68,24,20,4],
[68,24,20,5],
[68,24,20,6],
[68,24,20,8],
[68,24,20,9],
[68,24,20,11],
[68,24,20,12],
[68,24,20,13],
[68,24,20,14],
[68,24,20,15],
[68,24,20,17],
[68,24,20,18],
[68,24,20,19],
[68,24,21,0],
[68,24,21,1],
[68,24,21,2],
[68,24,21,4],
[68,24,21,9],
[68,24,21,10],
[68,24,21,11],
[68,24,21,12],
[68,24,21,13],
[68,24,21,14],
[68,24,21,15],
[68,24,21,17],
[68,24,21,18],
[68,24,21,19],
[68,24,21,20],
[68,24,22,0],
[68,24,22,1],
[68,24,22,2],
[68,24,22,3],
[68,24,22,4],
[68,24,22,5],
[68,24,22,6],
[68,24,22,8],
[68,24,22,9],
[68,24,22,11],
[68,24,22,12],
[68,24,22,13],
[68,24,22,14],
[68,24,22,15],
[68,24,22,17],
[68,24,22,18],
[68,24,22,19],
[68,24,22,21],
[68,24,23,0],
[68,24,23,1],
[68,24,23,2],
[68,24,23,3],
[68,24,23,4],
[68,24,23,5],
[68,24,23,6],
[68,24,23,8],
[68,24,23,9],
[68,24,23,10],
[68,24,23,11],
[68,24,23,12],
[68,24,23,13],
[68,24,23,14],
[68,24,23,15],
[68,24,23,17],
[68,24,23,18],
[68,24,23,19],
[68,24,23,20],
[68,24,23,21],
[68,24,23,22],
[68,26,3,0],
[68,26,3,1],
[68,26,3,2],
[68,26,4,0],
[68,26,4,1],
[68,26,4,2],
[68,26,4,3],
[68,26,5,0],
[68,26,5,1],
[68,26,5,2],
[68,26,5,4],
[68,26,6,0],
[68,26,6,1],
[68,26,6,2],
[68,26,6,4],
[68,26,8,0],
[68,26,8,1],
[68,26,8,2],
[68,26,8,4],
[68,26,10,0],
[68,26,10,1],
[68,26,10,2],
[68,26,10,3],
[68,26,10,4],
[68,26,10,5],
[68,26,10,6],
[68,26,10,8],
[68,26,11,0],
[68,26,11,1],
[68,26,11,2],
[68,26,11,3],
[68,26,11,4],
[68,26,11,5],
[68,26,11,6],
[68,26,11,8],
[68,26,11,10],
[68,26,12,0],
[68,26,12,1],
[68,26,12,2],
[68,26,12,3],
[68,26,12,4],
[68,26,12,5],
[68,26,12,6],
[68,26,12,8],
[68,26,12,10],
[68,26,13,0],
[68,26,13,1],
[68,26,13,2],
[68,26,13,3],
[68,26,13,4],
[68,26,13,5],
[68,26,13,6],
[68,26,13,8],
[68,26,13,10],
[68,26,13,11],
[68,26,13,12],
[68,26,14,0],
[68,26,14,1],
[68,26,14,2],
[68,26,14,3],
[68,26,14,4],
[68,26,14,5],
[68,26,14,6],
[68,26,14,8],
[68,26,14,10],
[68,26,14,11],
[68,26,14,12],
[68,26,16,0],
[68,26,16,1],
[68,26,16,2],
[68,26,16,3],
[68,26,16,4],
[68,26,16,5],
[68,26,16,6],
[68,26,16,8],
[68,26,16,10],
[68,26,16,11],
[68,26,16,12],
[68,26,16,13],
[68,26,16,14],
[68,26,17,0],
[68,26,17,1],
[68,26,17,2],
[68,26,17,3],
[68,26,17,4],
[68,26,17,5],
[68,26,17,6],
[68,26,17,8],
[68,26,17,10],
[68,26,17,11],
[68,26,17,12],
[68,26,17,13],
[68,26,17,14],
[68,26,17,16],
[68,26,19,0],
[68,26,19,1],
[68,26,19,2],
[68,26,19,3],
[68,26,19,4],
[68,26,19,5],
[68,26,19,6],
[68,26,19,8],
[68,26,19,10],
[68,26,19,11],
[68,26,19,12],
[68,26,19,16],
[68,26,19,17],
[68,26,20,0],
[68,26,20,1],
[68,26,20,2],
[68,26,20,3],
[68,26,20,4],
[68,26,20,5],
[68,26,20,6],
[68,26,20,8],
[68,26,20,11],
[68,26,20,12],
[68,26,20,13],
[68,26,20,14],
[68,26,20,16],
[68,26,20,17],
[68,26,20,19],
[68,26,21,0],
[68,26,21,1],
[68,26,21,2],
[68,26,21,4],
[68,26,21,10],
[68,26,21,11],
[68,26,21,12],
[68,26,21,13],
[68,26,21,14],
[68,26,21,16],
[68,26,21,17],
[68,26,21,19],
[68,26,21,20],
[68,26,22,0],
[68,26,22,1],
[68,26,22,2],
[68,26,22,3],
[68,26,22,4],
[68,26,22,5],
[68,26,22,6],
[68,26,22,8],
[68,26,22,11],
[68,26,22,12],
[68,26,22,13],
[68,26,22,14],
[68,26,22,16],
[68,26,22,17],
[68,26,22,19],
[68,26,22,21],
[68,26,23,0],
[68,26,23,1],
[68,26,23,2],
[68,26,23,3],
[68,26,23,4],
[68,26,23,5],
[68,26,23,6],
[68,26,23,8],
[68,26,23,10],
[68,26,23,11],
[68,26,23,12],
[68,26,23,13],
[68,26,23,14],
[68,26,23,16],
[68,26,23,17],
[68,26,23,19],
[68,26,23,20],
[68,26,23,22],
[68,26,24,0],
[68,26,24,1],
[68,26,24,2],
[68,26,24,3],
[68,26,24,4],
[68,26,24,5],
[68,26,24,6],
[68,26,24,8],
[68,26,24,10],
[68,26,24,11],
[68,26,24,12],
[68,26,24,13],
[68,26,24,14],
[68,26,24,17],
[68,26,24,19],
[68,26,24,20],
[68,26,24,22],
[68,26,24,23],
[68,27,3,0],
[68,27,3,1],
[68,27,3,2],
[68,27,4,0],
[68,27,4,1],
[68,27,4,2],
[68,27,4,3],
[68,27,5,0],
[68,27,5,1],
[68,27,5,2],
[68,27,5,4],
[68,27,6,0],
[68,27,6,1],
[68,27,6,2],
[68,27,6,4],
[68,27,8,0],
[68,27,8,1],
[68,27,8,2],
[68,27,8,4],
[68,27,9,0],
[68,27,9,1],
[68,27,9,2],
[68,27,9,3],
[68,27,9,4],
[68,27,9,5],
[68,27,9,6],
[68,27,9,8],
[68,27,11,0],
[68,27,11,1],
[68,27,11,2],
[68,27,11,3],
[68,27,11,4],
[68,27,11,5],
[68,27,11,6],
[68,27,11,8],
[68,27,11,9],
[68,27,12,0],
[68,27,12,1],
[68,27,12,2],
[68,27,12,3],
[68,27,12,4],
[68,27,12,5],
[68,27,12,6],
[68,27,12,8],
[68,27,12,9],
[68,27,13,0],
[68,27,13,1],
[68,27,13,2],
[68,27,13,3],
[68,27,13,4],
[68,27,13,5],
[68,27,13,6],
[68,27,13,8],
[68,27,13,9],
[68,27,13,11],
[68,27,13,12],
[68,27,14,0],
[68,27,14,1],
[68,27,14,2],
[68,27,14,3],
[68,27,14,4],
[68,27,14,5],
[68,27,14,6],
[68,27,14,8],
[68,27,14,9],
[68,27,14,11],
[68,27,14,12],
[68,27,15,0],
[68,27,15,1],
[68,27,15,2],
[68,27,15,3],
[68,27,15,4],
[68,27,15,5],
[68,27,15,6],
[68,27,15,8],
[68,27,15,11],
[68,27,15,12],
[68,27,15,13],
[68,27,15,14],
[68,27,16,0],
[68,27,16,1],
[68,27,16,2],
[68,27,16,3],
[68,27,16,4],
[68,27,16,5],
[68,27,16,6],
[68,27,16,8],
[68,27,16,9],
[68,27,16,11],
[68,27,16,12],
[68,27,16,13],
[68,27,16,14],
[68,27,16,15],
[68,27,17,0],
[68,27,17,1],
[68,27,17,2],
[68,27,17,3],
[68,27,17,4],
[68,27,17,5],
[68,27,17,6],
[68,27,17,8],
[68,27,17,9],
[68,27,17,11],
[68,27,17,12],
[68,27,17,13],
[68,27,17,14],
[68,27,17,15],
[68,27,17,16],
[68,27,18,0],
[68,27,18,1],
[68,27,18,2],
[68,27,18,3],
[68,27,18,4],
[68,27,18,5],
[68,27,18,6],
[68,27,18,8],
[68,27,18,11],
[68,27,18,12],
[68,27,18,13],
[68,27,18,14],
[68,27,18,16],
[68,27,18,17],
[68,27,19,0],
[68,27,19,1],
[68,27,19,2],
[68,27,19,3],
[68,27,19,4],
[68,27,19,5],
[68,27,19,6],
[68,27,19,8],
[68,27,19,9],
[68,27,19,11],
[68,27,19,12],
[68,27,19,15],
[68,27,19,16],
[68,27,19,17],
[68,27,19,18],
[68,27,21,0],
[68,27,21,1],
[68,27,21,2],
[68,27,21,4],
[68,27,21,9],
[68,27,21,11],
[68,27,21,12],
[68,27,21,13],
[68,27,21,14],
[68,27,21,15],
[68,27,21,16],
[68,27,21,17],
[68,27,21,18],
[68,27,21,19],
[68,27,23,0],
[68,27,23,1],
[68,27,23,2],
[68,27,23,3],
[68,27,23,4],
[68,27,23,5],
[68,27,23,6],
[68,27,23,8],
[68,27,23,9],
[68,27,23,11],
[68,27,23,12],
[68,27,23,13],
[68,27,23,14],
[68,27,23,15],
[68,27,23,16],
[68,27,23,17],
[68,27,23,18],
[68,27,23,19],
[68,27,23,21],
[68,27,24,0],
[68,27,24,1],
[68,27,24,2],
[68,27,24,3],
[68,27,24,4],
[68,27,24,5],
[68,27,24,6],
[68,27,24,8],
[68,27,24,9],
[68,27,24,11],
[68,27,24,12],
[68,27,24,13],
[68,27,24,14],
[68,27,24,15],
[68,27,24,17],
[68,27,24,18],
[68,27,24,19],
[68,27,24,21],
[68,27,24,23],
[68,27,26,0],
[68,27,26,1],
[68,27,26,2],
[68,27,26,3],
[68,27,26,4],
[68,27,26,5],
[68,27,26,6],
[68,27,26,8],
[68,27,26,11],
[68,27,26,12],
[68,27,26,13],
[68,27,26,14],
[68,27,26,19],
[68,27,26,21],
[68,27,26,23],
[68,27,26,24],
[68,29,3,0],
[68,29,3,1],
[68,29,3,2],
[68,29,4,0],
[68,29,4,1],
[68,29,4,2],
[68,29,4,3],
[68,29,5,0],
[68,29,5,1],
[68,29,5,2],
[68,29,5,4],
[68,29,6,0],
[68,29,6,1],
[68,29,6,2],
[68,29,6,4],
[68,29,8,0],
[68,29,8,1],
[68,29,8,2],
[68,29,8,4],
[68,29,9,0],
[68,29,9,1],
[68,29,9,2],
[68,29,9,3],
[68,29,9,4],
[68,29,9,5],
[68,29,9,6],
[68,29,9,8],
[68,29,10,0],
[68,29,10,1],
[68,29,10,2],
[68,29,10,3],
[68,29,10,4],
[68,29,10,5],
[68,29,10,6],
[68,29,10,8],
[68,29,10,9],
[68,29,13,0],
[68,29,13,1],
[68,29,13,2],
[68,29,13,3],
[68,29,13,4],
[68,29,13,5],
[68,29,13,6],
[68,29,13,8],
[68,29,13,9],
[68,29,13,10],
[68,29,14,0],
[68,29,14,1],
[68,29,14,2],
[68,29,14,3],
[68,29,14,4],
[68,29,14,5],
[68,29,14,6],
[68,29,14,8],
[68,29,14,9],
[68,29,14,10],
[68,29,15,0],
[68,29,15,1],
[68,29,15,2],
[68,29,15,3],
[68,29,15,4],
[68,29,15,5],
[68,29,15,6],
[68,29,15,8],
[68,29,15,10],
[68,29,15,13],
[68,29,15,14],
[68,29,16,0],
[68,29,16,1],
[68,29,16,2],
[68,29,16,3],
[68,29,16,4],
[68,29,16,5],
[68,29,16,6],
[68,29,16,8],
[68,29,16,9],
[68,29,16,10],
[68,29,16,13],
[68,29,16,14],
[68,29,16,15],
[68,29,17,0],
[68,29,17,1],
[68,29,17,2],
[68,29,17,3],
[68,29,17,4],
[68,29,17,5],
[68,29,17,6],
[68,29,17,8],
[68,29,17,9],
[68,29,17,10],
[68,29,17,13],
[68,29,17,14],
[68,29,17,15],
[68,29,17,16],
[68,29,18,0],
[68,29,18,1],
[68,29,18,2],
[68,29,18,3],
[68,29,18,4],
[68,29,18,5],
[68,29,18,6],
[68,29,18,8],
[68,29,18,10],
[68,29,18,13],
[68,29,18,14],
[68,29,18,16],
[68,29,18,17],
[68,29,19,0],
[68,29,19,1],
[68,29,19,2],
[68,29,19,3],
[68,29,19,4],
[68,29,19,5],
[68,29,19,6],
[68,29,19,8],
[68,29,19,9],
[68,29,19,10],
[68,29,19,15],
[68,29,19,16],
[68,29,19,17],
[68,29,19,18],
[68,29,20,0],
[68,29,20,1],
[68,29,20,2],
[68,29,20,3],
[68,29,20,4],
[68,29,20,5],
[68,29,20,6],
[68,29,20,8],
[68,29,20,9],
[68,29,20,13],
[68,29,20,14],
[68,29,20,15],
[68,29,20,16],
[68,29,20,17],
[68,29,20,18],
[68,29,20,19],
[68,29,21,0],
[68,29,21,1],
[68,29,21,2],
[68,29,21,4],
[68,29,21,9],
[68,29,21,10],
[68,29,21,13],
[68,29,21,14],
[68,29,21,15],
[68,29,21,16],
[68,29,21,17],
[68,29,21,18],
[68,29,21,19],
[68,29,22,0],
[68,29,22,1],
[68,29,22,2],
[68,29,22,3],
[68,29,22,4],
[68,29,22,5],
[68,29,22,6],
[68,29,22,8],
[68,29,22,9],
[68,29,22,13],
[68,29,22,14],
[68,29,22,15],
[68,29,22,16],
[68,29,22,17],
[68,29,22,18],
[68,29,22,21],
[68,29,23,0],
[68,29,23,1],
[68,29,23,2],
[68,29,23,3],
[68,29,23,4],
[68,29,23,5],
[68,29,23,6],
[68,29,23,8],
[68,29,23,9],
[68,29,23,10],
[68,29,23,13],
[68,29,23,14],
[68,29,23,15],
[68,29,23,16],
[68,29,23,17],
[68,29,23,19],
[68,29,23,20],
[68,29,23,21],
[68,29,23,22],
[68,29,24,0],
[68,29,24,1],
[68,29,24,2],
[68,29,24,3],
[68,29,24,4],
[68,29,24,5],
[68,29,24,6],
[68,29,24,8],
[68,29,24,9],
[68,29,24,10],
[68,29,24,13],
[68,29,24,14],
[68,29,24,15],
[68,29,24,17],
[68,29,24,19],
[68,29,24,20],
[68,29,24,21],
[68,29,24,22],
[68,29,24,23],
[68,29,26,0],
[68,29,26,1],
[68,29,26,2],
[68,29,26,3],
[68,29,26,4],
[68,29,26,5],
[68,29,26,6],
[68,29,26,8],
[68,29,26,10],
[68,29,26,13],
[68,29,26,16],
[68,29,26,17],
[68,29,26,19],
[68,29,26,20],
[68,29,26,21],
[68,29,26,22],
[68,29,26,23],
[68,29,26,24],
[68,29,27,0],
[68,29,27,1],
[68,29,27,2],
[68,29,27,3],
[68,29,27,4],
[68,29,27,5],
[68,29,27,6],
[68,29,27,8],
[68,29,27,9],
[68,29,27,14],
[68,29,27,15],
[68,29,27,16],
[68,29,27,17],
[68,29,27,18],
[68,29,27,19],
[68,29,27,21],
[68,29,27,23],
[68,29,27,24],
[68,29,27,26],
[68,30,3,0],
[68,30,3,1],
[68,30,3,2],
[68,30,4,0],
[68,30,4,1],
[68,30,4,2],
[68,30,4,3],
[68,30,5,0],
[68,30,5,1],
[68,30,5,2],
[68,30,5,4],
[68,30,6,0],
[68,30,6,1],
[68,30,6,2],
[68,30,6,4],
[68,30,8,0],
[68,30,8,1],
[68,30,8,2],
[68,30,8,4],
[68,30,9,0],
[68,30,9,1],
[68,30,9,2],
[68,30,9,3],
[68,30,9,4],
[68,30,9,5],
[68,30,9,6],
[68,30,9,8],
[68,30,10,0],
[68,30,10,1],
[68,30,10,2],
[68,30,10,3],
[68,30,10,4],
[68,30,10,5],
[68,30,10,6],
[68,30,10,8],
[68,30,10,9],
[68,30,11,0],
[68,30,11,1],
[68,30,11,2],
[68,30,11,3],
[68,30,11,4],
[68,30,11,5],
[68,30,11,6],
[68,30,11,8],
[68,30,11,9],
[68,30,11,10],
[68,30,12,0],
[68,30,12,1],
[68,30,12,2],
[68,30,12,3],
[68,30,12,4],
[68,30,12,5],
[68,30,12,6],
[68,30,12,8],
[68,30,12,9],
[68,30,12,10],
[68,30,13,0],
[68,30,13,1],
[68,30,13,2],
[68,30,13,3],
[68,30,13,4],
[68,30,13,5],
[68,30,13,6],
[68,30,13,8],
[68,30,13,9],
[68,30,13,10],
[68,30,13,11],
[68,30,13,12],
[68,30,14,0],
[68,30,14,1],
[68,30,14,2],
[68,30,14,3],
[68,30,14,4],
[68,30,14,5],
[68,30,14,6],
[68,30,14,8],
[68,30,14,9],
[68,30,14,10],
[68,30,14,11],
[68,30,14,12],
[68,30,15,0],
[68,30,15,1],
[68,30,15,2],
[68,30,15,3],
[68,30,15,4],
[68,30,15,5],
[68,30,15,6],
[68,30,15,8],
[68,30,15,10],
[68,30,15,11],
[68,30,15,12],
[68,30,15,13],
[68,30,15,14],
[68,30,16,0],
[68,30,16,1],
[68,30,16,2],
[68,30,16,3],
[68,30,16,4],
[68,30,16,5],
[68,30,16,6],
[68,30,16,8],
[68,30,16,9],
[68,30,16,10],
[68,30,16,11],
[68,30,16,12],
[68,30,16,13],
[68,30,16,14],
[68,30,16,15],
[68,30,17,0],
[68,30,17,1],
[68,30,17,2],
[68,30,17,3],
[68,30,17,4],
[68,30,17,5],
[68,30,17,6],
[68,30,17,8],
[68,30,17,9],
[68,30,17,10],
[68,30,17,11],
[68,30,17,12],
[68,30,17,13],
[68,30,17,14],
[68,30,17,15],
[68,30,17,16],
[68,30,18,0],
[68,30,18,1],
[68,30,18,2],
[68,30,18,3],
[68,30,18,4],
[68,30,18,5],
[68,30,18,6],
[68,30,18,8],
[68,30,18,10],
[68,30,18,11],
[68,30,18,12],
[68,30,18,13],
[68,30,18,14],
[68,30,18,16],
[68,30,18,17],
[68,30,19,0],
[68,30,19,1],
[68,30,19,2],
[68,30,19,3],
[68,30,19,4],
[68,30,19,5],
[68,30,19,6],
[68,30,19,8],
[68,30,19,9],
[68,30,19,10],
[68,30,19,11],
[68,30,19,12],
[68,30,19,15],
[68,30,19,16],
[68,30,19,17],
[68,30,19,18],
[68,30,20,0],
[68,30,20,1],
[68,30,20,2],
[68,30,20,3],
[68,30,20,4],
[68,30,20,5],
[68,30,20,6],
[68,30,20,8],
[68,30,20,9],
[68,30,20,11],
[68,30,20,12],
[68,30,20,13],
[68,30,20,14],
[68,30,20,15],
[68,30,20,16],
[68,30,20,17],
[68,30,20,18],
[68,30,20,19],
[68,30,21,0],
[68,30,21,1],
[68,30,21,2],
[68,30,21,4],
[68,30,21,9],
[68,30,21,10],
[68,30,21,11],
[68,30,21,12],
[68,30,21,13],
[68,30,21,14],
[68,30,21,15],
[68,30,21,16],
[68,30,21,17],
[68,30,21,18],
[68,30,21,20],
[68,30,22,0],
[68,30,22,1],
[68,30,22,2],
[68,30,22,3],
[68,30,22,4],
[68,30,22,5],
[68,30,22,6],
[68,30,22,8],
[68,30,22,9],
[68,30,22,11],
[68,30,22,12],
[68,30,22,13],
[68,30,22,14],
[68,30,22,15],
[68,30,22,16],
[68,30,22,17],
[68,30,22,19],
[68,30,22,21],
[68,30,24,0],
[68,30,24,1],
[68,30,24,2],
[68,30,24,3],
[68,30,24,4],
[68,30,24,5],
[68,30,24,6],
[68,30,24,8],
[68,30,24,9],
[68,30,24,10],
[68,30,24,11],
[68,30,24,12],
[68,30,24,13],
[68,30,24,14],
[68,30,24,15],
[68,30,24,18],
[68,30,24,19],
[68,30,24,20],
[68,30,24,21],
[68,30,24,22],
[68,30,26,0],
[68,30,26,1],
[68,30,26,2],
[68,30,26,3],
[68,30,26,4],
[68,30,26,5],
[68,30,26,6],
[68,30,26,8],
[68,30,26,10],
[68,30,26,11],
[68,30,26,14],
[68,30,26,16],
[68,30,26,17],
[68,30,26,19],
[68,30,26,20],
[68,30,26,21],
[68,30,26,22],
[68,30,26,24],
[68,30,27,0],
[68,30,27,1],
[68,30,27,2],
[68,30,27,3],
[68,30,27,4],
[68,30,27,5],
[68,30,27,6],
[68,30,27,8],
[68,30,27,9],
[68,30,27,12],
[68,30,27,13],
[68,30,27,14],
[68,30,27,15],
[68,30,27,16],
[68,30,27,17],
[68,30,27,18],
[68,30,27,19],
[68,30,27,21],
[68,30,27,24],
[68,30,29,0],
[68,30,29,1],
[68,30,29,2],
[68,30,29,3],
[68,30,29,4],
[68,30,29,5],
[68,30,29,8],
[68,30,29,9],
[68,30,29,10],
[68,30,29,13],
[68,30,29,14],
[68,30,29,15],
[68,30,29,16],
[68,30,29,17],
[68,30,29,18],
[68,30,29,19],
[68,30,29,20],
[68,30,29,21],
[68,30,29,22],
[68,30,29,24],
[68,31,3,0],
[68,31,3,1],
[68,31,3,2],
[68,31,4,0],
[68,31,4,1],
[68,31,4,2],
[68,31,4,3],
[68,31,5,0],
[68,31,5,1],
[68,31,5,2],
[68,31,5,4],
[68,31,6,0],
[68,31,6,1],
[68,31,6,2],
[68,31,6,4],
[68,31,8,0],
[68,31,8,1],
[68,31,8,2],
[68,31,8,4],
[68,31,9,0],
[68,31,9,1],
[68,31,9,2],
[68,31,9,3],
[68,31,9,4],
[68,31,9,5],
[68,31,9,6],
[68,31,9,8],
[68,31,10,0],
[68,31,10,1],
[68,31,10,2],
[68,31,10,3],
[68,31,10,4],
[68,31,10,5],
[68,31,10,6],
[68,31,10,8],
[68,31,10,9],
[68,31,11,0],
[68,31,11,1],
[68,31,11,2],
[68,31,11,3],
[68,31,11,4],
[68,31,11,5],
[68,31,11,6],
[68,31,11,8],
[68,31,11,9],
[68,31,11,10],
[68,31,12,0],
[68,31,12,1],
[68,31,12,2],
[68,31,12,3],
[68,31,12,4],
[68,31,12,5],
[68,31,12,6],
[68,31,12,8],
[68,31,12,9],
[68,31,12,10],
[68,31,13,0],
[68,31,13,1],
[68,31,13,2],
[68,31,13,3],
[68,31,13,4],
[68,31,13,5],
[68,31,13,6],
[68,31,13,8],
[68,31,13,9],
[68,31,13,10],
[68,31,13,11],
[68,31,13,12],
[68,31,14,0],
[68,31,14,1],
[68,31,14,2],
[68,31,14,3],
[68,31,14,4],
[68,31,14,5],
[68,31,14,6],
[68,31,14,8],
[68,31,14,9],
[68,31,14,10],
[68,31,14,11],
[68,31,14,12],
[68,31,15,0],
[68,31,15,1],
[68,31,15,2],
[68,31,15,3],
[68,31,15,4],
[68,31,15,5],
[68,31,15,6],
[68,31,15,8],
[68,31,15,10],
[68,31,15,11],
[68,31,15,12],
[68,31,15,13],
[68,31,15,14],
[68,31,16,0],
[68,31,16,1],
[68,31,16,2],
[68,31,16,3],
[68,31,16,4],
[68,31,16,5],
[68,31,16,6],
[68,31,16,8],
[68,31,16,9],
[68,31,16,10],
[68,31,16,11],
[68,31,16,12],
[68,31,16,13],
[68,31,16,14],
[68,31,16,15],
[68,31,17,0],
[68,31,17,1],
[68,31,17,2],
[68,31,17,3],
[68,31,17,4],
[68,31,17,5],
[68,31,17,6],
[68,31,17,8],
[68,31,17,9],
[68,31,17,10],
[68,31,17,11],
[68,31,17,12],
[68,31,17,13],
[68,31,17,14],
[68,31,17,15],
[68,31,17,16],
[68,31,18,0],
[68,31,18,1],
[68,31,18,2],
[68,31,18,3],
[68,31,18,4],
[68,31,18,5],
[68,31,18,6],
[68,31,18,8],
[68,31,18,10],
[68,31,18,11],
[68,31,18,12],
[68,31,18,13],
[68,31,18,14],
[68,31,18,16],
[68,31,18,17],
[68,31,19,0],
[68,31,19,1],
[68,31,19,2],
[68,31,19,3],
[68,31,19,4],
[68,31,19,5],
[68,31,19,6],
[68,31,19,8],
[68,31,19,9],
[68,31,19,10],
[68,31,19,11],
[68,31,19,12],
[68,31,19,15],
[68,31,19,16],
[68,31,19,17],
[68,31,19,18],
[68,31,20,0],
[68,31,20,1],
[68,31,20,2],
[68,31,20,3],
[68,31,20,4],
[68,31,20,5],
[68,31,20,6],
[68,31,20,8],
[68,31,20,9],
[68,31,20,11],
[68,31,20,12],
[68,31,20,13],
[68,31,20,14],
[68,31,20,15],
[68,31,20,16],
[68,31,20,17],
[68,31,20,18],
[68,31,21,0],
[68,31,21,1],
[68,31,21,2],
[68,31,21,4],
[68,31,21,9],
[68,31,21,10],
[68,31,21,11],
[68,31,21,12],
[68,31,21,13],
[68,31,21,14],
[68,31,21,15],
[68,31,21,16],
[68,31,21,17],
[68,31,21,19],
[68,31,21,20],
[68,31,22,0],
[68,31,22,1],
[68,31,22,2],
[68,31,22,3],
[68,31,22,4],
[68,31,22,5],
[68,31,22,6],
[68,31,22,8],
[68,31,22,9],
[68,31,22,11],
[68,31,22,12],
[68,31,22,13],
[68,31,22,14],
[68,31,22,15],
[68,31,22,18],
[68,31,22,19],
[68,31,22,21],
[68,31,24,0],
[68,31,24,1],
[68,31,24,2],
[68,31,24,3],
[68,31,24,4],
[68,31,24,5],
[68,31,24,6],
[68,31,24,8],
[68,31,24,9],
[68,31,24,10],
[68,31,24,11],
[68,31,24,12],
[68,31,24,13],
[68,31,24,14],
[68,31,24,17],
[68,31,24,18],
[68,31,24,19],
[68,31,24,20],
[68,31,24,21],
[68,31,24,22],
[68,31,26,0],
[68,31,26,1],
[68,31,26,2],
[68,31,26,3],
[68,31,26,4],
[68,31,26,5],
[68,31,26,6],
[68,31,26,8],
[68,31,26,12],
[68,31,26,13],
[68,31,26,14],
[68,31,26,16],
[68,31,26,17],
[68,31,26,19],
[68,31,26,20],
[68,31,26,21],
[68,31,26,22],
[68,31,26,24],
[68,31,27,0],
[68,31,27,1],
[68,31,27,2],
[68,31,27,3],
[68,31,27,4],
[68,31,27,5],
[68,31,27,6],
[68,31,27,11],
[68,31,27,12],
[68,31,27,13],
[68,31,27,14],
[68,31,27,15],
[68,31,27,16],
[68,31,27,17],
[68,31,27,18],
[68,31,27,19],
[68,31,27,21],
[68,31,27,24],
[68,31,29,0],
[68,31,29,1],
[68,31,29,2],
[68,31,29,3],
[68,31,29,4],
[68,31,29,6],
[68,31,29,8],
[68,31,29,9],
[68,31,29,10],
[68,31,29,13],
[68,31,29,14],
[68,31,29,15],
[68,31,29,16],
[68,31,29,17],
[68,31,29,18],
[68,31,29,19],
[68,31,29,20],
[68,31,29,21],
[68,31,29,22],
[68,32,3,0],
[68,32,3,1],
[68,32,3,2],
[68,32,4,0],
[68,32,4,1],
[68,32,4,2],
[68,32,4,3],
[68,32,5,0],
[68,32,5,1],
[68,32,5,2],
[68,32,5,4],
[68,32,6,0],
[68,32,6,1],
[68,32,6,2],
[68,32,6,4],
[68,32,8,0],
[68,32,8,1],
[68,32,8,2],
[68,32,8,4],
[68,32,9,0],
[68,32,9,1],
[68,32,9,2],
[68,32,9,3],
[68,32,9,4],
[68,32,9,5],
[68,32,9,6],
[68,32,9,8],
[68,32,10,0],
[68,32,10,1],
[68,32,10,2],
[68,32,10,3],
[68,32,10,4],
[68,32,10,5],
[68,32,10,6],
[68,32,10,8],
[68,32,10,9],
[68,32,13,0],
[68,32,13,1],
[68,32,13,2],
[68,32,13,3],
[68,32,13,4],
[68,32,13,5],
[68,32,13,6],
[68,32,13,8],
[68,32,13,9],
[68,32,13,10],
[68,32,14,0],
[68,32,14,1],
[68,32,14,2],
[68,32,14,3],
[68,32,14,4],
[68,32,14,5],
[68,32,14,6],
[68,32,14,8],
[68,32,14,9],
[68,32,14,10],
[68,32,15,0],
[68,32,15,1],
[68,32,15,2],
[68,32,15,3],
[68,32,15,4],
[68,32,15,5],
[68,32,15,6],
[68,32,15,8],
[68,32,15,10],
[68,32,15,13],
[68,32,15,14],
[68,32,16,0],
[68,32,16,1],
[68,32,16,2],
[68,32,16,3],
[68,32,16,4],
[68,32,16,5],
[68,32,16,6],
[68,32,16,8],
[68,32,16,9],
[68,32,16,10],
[68,32,16,13],
[68,32,16,14],
[68,32,16,15],
[68,32,17,0],
[68,32,17,1],
[68,32,17,2],
[68,32,17,3],
[68,32,17,4],
[68,32,17,5],
[68,32,17,6],
[68,32,17,8],
[68,32,17,9],
[68,32,17,10],
[68,32,17,13],
[68,32,17,14],
[68,32,17,15],
[68,32,17,16],
[68,32,18,0],
[68,32,18,1],
[68,32,18,2],
[68,32,18,3],
[68,32,18,4],
[68,32,18,5],
[68,32,18,6],
[68,32,18,8],
[68,32,18,10],
[68,32,18,13],
[68,32,18,14],
[68,32,18,16],
[68,32,18,17],
[68,32,19,0],
[68,32,19,1],
[68,32,19,2],
[68,32,19,3],
[68,32,19,4],
[68,32,19,5],
[68,32,19,6],
[68,32,19,8],
[68,32,19,9],
[68,32,19,10],
[68,32,19,15],
[68,32,19,16],
[68,32,19,17],
[68,32,19,18],
[68,32,20,0],
[68,32,20,1],
[68,32,20,2],
[68,32,20,3],
[68,32,20,4],
[68,32,20,5],
[68,32,20,6],
[68,32,20,8],
[68,32,20,9],
[68,32,20,13],
[68,32,20,14],
[68,32,20,15],
[68,32,20,16],
[68,32,20,17],
[68,32,20,19],
[68,32,21,0],
[68,32,21,1],
[68,32,21,2],
[68,32,21,4],
[68,32,21,9],
[68,32,21,10],
[68,32,21,13],
[68,32,21,14],
[68,32,21,15],
[68,32,21,18],
[68,32,21,19],
[68,32,21,20],
[68,32,22,0],
[68,32,22,1],
[68,32,22,2],
[68,32,22,3],
[68,32,22,4],
[68,32,22,5],
[68,32,22,6],
[68,32,22,8],
[68,32,22,9],
[68,32,22,13],
[68,32,22,14],
[68,32,22,16],
[68,32,22,17],
[68,32,22,18],
[68,32,22,19],
[68,32,22,21],
[68,32,23,0],
[68,32,23,1],
[68,32,23,2],
[68,32,23,3],
[68,32,23,4],
[68,32,23,5],
[68,32,23,6],
[68,32,23,8],
[68,32,23,9],
[68,32,23,10],
[68,32,23,13],
[68,32,23,15],
[68,32,23,16],
[68,32,23,17],
[68,32,23,18],
[68,32,23,19],
[68,32,23,20],
[68,32,23,21],
[68,32,23,22],
[68,32,24,0],
[68,32,24,1],
[68,32,24,2],
[68,32,24,3],
[68,32,24,4],
[68,32,24,5],
[68,32,24,6],
[68,32,24,8],
[68,32,24,9],
[68,32,24,10],
[68,32,24,13],
[68,32,24,15],
[68,32,24,17],
[68,32,24,18],
[68,32,24,19],
[68,32,24,20],
[68,32,24,21],
[68,32,24,22],
[68,32,24,23],
[68,32,26,0],
[68,32,26,1],
[68,32,26,2],
[68,32,26,3],
[68,32,26,4],
[68,32,26,5],
[68,32,26,6],
[68,32,26,10],
[68,32,26,13],
[68,32,26,14],
[68,32,26,16],
[68,32,26,17],
[68,32,26,19],
[68,32,26,20],
[68,32,26,21],
[68,32,26,22],
[68,32,26,23],
[68,32,26,24],
[68,32,27,0],
[68,32,27,1],
[68,32,27,2],
[68,32,27,3],
[68,32,27,4],
[68,32,27,5],
[68,32,27,8],
[68,32,27,9],
[68,32,27,13],
[68,32,27,14],
[68,32,27,15],
[68,32,27,16],
[68,32,27,17],
[68,32,27,18],
[68,32,27,19],
[68,32,27,21],
[68,32,27,23],
[68,32,27,24],
[68,32,30,3],
[68,32,30,4],
[68,32,30,5],
[68,32,30,6],
[68,32,30,8],
[68,32,30,9],
[68,32,30,10],
[68,32,30,13],
[68,32,30,14],
[68,32,30,15],
[68,32,30,16],
[68,32,30,17],
[68,32,30,18],
[68,32,30,19],
[68,32,30,20],
[68,32,31,0],
[68,32,31,1],
[68,32,31,2],
[68,32,31,3],
[68,32,31,4],
[68,32,31,5],
[68,32,31,6],
[68,32,31,8],
[68,32,31,9],
[68,32,31,10],
[68,32,31,13],
[68,32,31,14],
[68,32,31,15],
[68,32,31,16],
[68,32,31,17],
[68,32,31,18],
[68,32,31,19],
[68,33,3,0],
[68,33,3,1],
[68,33,3,2],
[68,33,4,0],
[68,33,4,1],
[68,33,4,2],
[68,33,4,3],
[68,33,5,0],
[68,33,5,1],
[68,33,5,2],
[68,33,5,4],
[68,33,6,0],
[68,33,6,1],
[68,33,6,2],
[68,33,6,4],
[68,33,8,0],
[68,33,8,1],
[68,33,8,2],
[68,33,8,4],
[68,33,9,0],
[68,33,9,1],
[68,33,9,2],
[68,33,9,3],
[68,33,9,4],
[68,33,9,5],
[68,33,9,6],
[68,33,9,8],
[68,33,10,0],
[68,33,10,1],
[68,33,10,2],
[68,33,10,3],
[68,33,10,4],
[68,33,10,5],
[68,33,10,6],
[68,33,10,8],
[68,33,10,9],
[68,33,13,0],
[68,33,13,1],
[68,33,13,2],
[68,33,13,3],
[68,33,13,4],
[68,33,13,5],
[68,33,13,6],
[68,33,13,8],
[68,33,13,9],
[68,33,13,10],
[68,33,14,0],
[68,33,14,1],
[68,33,14,2],
[68,33,14,3],
[68,33,14,4],
[68,33,14,5],
[68,33,14,6],
[68,33,14,8],
[68,33,14,9],
[68,33,14,10],
[68,33,15,0],
[68,33,15,1],
[68,33,15,2],
[68,33,15,3],
[68,33,15,4],
[68,33,15,5],
[68,33,15,6],
[68,33,15,8],
[68,33,15,10],
[68,33,15,13],
[68,33,15,14],
[68,33,16,0],
[68,33,16,1],
[68,33,16,2],
[68,33,16,3],
[68,33,16,4],
[68,33,16,5],
[68,33,16,6],
[68,33,16,8],
[68,33,16,9],
[68,33,16,10],
[68,33,16,13],
[68,33,16,14],
[68,33,16,15],
[68,33,17,0],
[68,33,17,1],
[68,33,17,2],
[68,33,17,3],
[68,33,17,4],
[68,33,17,5],
[68,33,17,6],
[68,33,17,8],
[68,33,17,9],
[68,33,17,10],
[68,33,17,13],
[68,33,17,14],
[68,33,17,15],
[68,33,17,16],
[68,33,18,0],
[68,33,18,1],
[68,33,18,2],
[68,33,18,3],
[68,33,18,4],
[68,33,18,5],
[68,33,18,6],
[68,33,18,8],
[68,33,18,10],
[68,33,18,13],
[68,33,18,14],
[68,33,18,16],
[68,33,18,17],
[68,33,19,0],
[68,33,19,1],
[68,33,19,2],
[68,33,19,3],
[68,33,19,4],
[68,33,19,5],
[68,33,19,6],
[68,33,19,8],
[68,33,19,9],
[68,33,19,10],
[68,33,19,15],
[68,33,19,16],
[68,33,19,17],
[68,33,20,0],
[68,33,20,1],
[68,33,20,2],
[68,33,20,3],
[68,33,20,4],
[68,33,20,5],
[68,33,20,6],
[68,33,20,8],
[68,33,20,9],
[68,33,20,13],
[68,33,20,14],
[68,33,20,15],
[68,33,20,18],
[68,33,20,19],
[68,33,21,0],
[68,33,21,1],
[68,33,21,2],
[68,33,21,4],
[68,33,21,9],
[68,33,21,10],
[68,33,21,13],
[68,33,21,14],
[68,33,21,16],
[68,33,21,17],
[68,33,21,18],
[68,33,21,19],
[68,33,21,20],
[68,33,22,0],
[68,33,22,1],
[68,33,22,2],
[68,33,22,3],
[68,33,22,4],
[68,33,22,5],
[68,33,22,6],
[68,33,22,8],
[68,33,22,9],
[68,33,22,13],
[68,33,22,15],
[68,33,22,16],
[68,33,22,17],
[68,33,22,18],
[68,33,22,19],
[68,33,22,21],
[68,33,23,0],
[68,33,23,1],
[68,33,23,2],
[68,33,23,3],
[68,33,23,4],
[68,33,23,5],
[68,33,23,6],
[68,33,23,8],
[68,33,23,9],
[68,33,23,10],
[68,33,23,14],
[68,33,23,15],
[68,33,23,16],
[68,33,23,17],
[68,33,23,18],
[68,33,23,19],
[68,33,23,20],
[68,33,23,21],
[68,33,23,22],
[68,33,24,0],
[68,33,24,1],
[68,33,24,2],
[68,33,24,3],
[68,33,24,4],
[68,33,24,5],
[68,33,24,6],
[68,33,24,8],
[68,33,24,9],
[68,33,24,10],
[68,33,24,14],
[68,33,24,15],
[68,33,24,17],
[68,33,24,18],
[68,33,24,19],
[68,33,24,20],
[68,33,24,21],
[68,33,24,22],
[68,33,24,23],
[68,33,26,0],
[68,33,26,1],
[68,33,26,2],
[68,33,26,3],
[68,33,26,4],
[68,33,26,5],
[68,33,26,8],
[68,33,26,10],
[68,33,26,13],
[68,33,26,14],
[68,33,26,16],
[68,33,26,17],
[68,33,26,19],
[68,33,26,20],
[68,33,26,21],
[68,33,26,22],
[68,33,26,23],
[68,33,26,24],
[68,33,27,0],
[68,33,27,1],
[68,33,27,2],
[68,33,27,3],
[68,33,27,4],
[68,33,27,6],
[68,33,27,8],
[68,33,27,9],
[68,33,27,13],
[68,33,27,14],
[68,33,27,15],
[68,33,27,16],
[68,33,27,17],
[68,33,27,18],
[68,33,27,19],
[68,33,27,21],
[68,33,30,0],
[68,33,30,1],
[68,33,30,2],
[68,33,30,3],
[68,33,30,4],
[68,33,30,5],
[68,33,30,6],
[68,33,30,8],
[68,33,30,9],
[68,33,30,10],
[68,33,30,13],
[68,33,30,14],
[68,33,30,15],
[68,33,30,16],
[68,33,30,17],
[68,33,30,18],
[68,33,30,19],
[68,33,31,0],
[68,33,31,1],
[68,33,31,2],
[68,33,31,3],
[68,33,31,4],
[68,33,31,5],
[68,33,31,6],
[68,33,31,8],
[68,33,31,9],
[68,33,31,10],
[68,33,31,13],
[68,33,31,14],
[68,33,31,15],
[68,33,31,16],
[68,33,31,17],
[68,33,31,18],
[68,35,3,0],
[68,35,3,1],
[68,35,3,2],
[68,35,4,0],
[68,35,4,1],
[68,35,4,2],
[68,35,4,3],
[68,35,5,0],
[68,35,5,1],
[68,35,5,2],
[68,35,5,4],
[68,35,6,0],
[68,35,6,1],
[68,35,6,2],
[68,35,6,4],
[68,35,8,0],
[68,35,8,1],
[68,35,8,2],
[68,35,8,4],
[68,35,10,0],
[68,35,10,1],
[68,35,10,2],
[68,35,10,3],
[68,35,10,4],
[68,35,10,5],
[68,35,10,6],
[68,35,10,8],
[68,35,11,0],
[68,35,11,1],
[68,35,11,2],
[68,35,11,3],
[68,35,11,4],
[68,35,11,5],
[68,35,11,6],
[68,35,11,8],
[68,35,11,10],
[68,35,12,0],
[68,35,12,1],
[68,35,12,2],
[68,35,12,3],
[68,35,12,4],
[68,35,12,5],
[68,35,12,6],
[68,35,12,8],
[68,35,12,10],
[68,35,13,0],
[68,35,13,1],
[68,35,13,2],
[68,35,13,3],
[68,35,13,4],
[68,35,13,5],
[68,35,13,6],
[68,35,13,8],
[68,35,13,10],
[68,35,13,11],
[68,35,13,12],
[68,35,14,0],
[68,35,14,1],
[68,35,14,2],
[68,35,14,3],
[68,35,14,4],
[68,35,14,5],
[68,35,14,6],
[68,35,14,8],
[68,35,14,10],
[68,35,14,11],
[68,35,14,12],
[68,35,16,0],
[68,35,16,1],
[68,35,16,2],
[68,35,16,3],
[68,35,16,4],
[68,35,16,5],
[68,35,16,6],
[68,35,16,8],
[68,35,16,10],
[68,35,16,11],
[68,35,16,12],
[68,35,16,13],
[68,35,16,14],
[68,35,17,0],
[68,35,17,1],
[68,35,17,2],
[68,35,17,3],
[68,35,17,4],
[68,35,17,5],
[68,35,17,6],
[68,35,17,8],
[68,35,17,10],
[68,35,17,11],
[68,35,17,12],
[68,35,17,13],
[68,35,17,14],
[68,35,17,16],
[68,35,19,0],
[68,35,19,1],
[68,35,19,2],
[68,35,19,3],
[68,35,19,4],
[68,35,19,5],
[68,35,19,6],
[68,35,19,8],
[68,35,19,10],
[68,35,19,11],
[68,35,19,12],
[68,35,19,16],
[68,35,19,17],
[68,35,20,0],
[68,35,20,1],
[68,35,20,2],
[68,35,20,3],
[68,35,20,4],
[68,35,20,5],
[68,35,20,6],
[68,35,20,8],
[68,35,20,11],
[68,35,20,12],
[68,35,20,13],
[68,35,20,16],
[68,35,20,17],
[68,35,20,19],
[68,35,21,0],
[68,35,21,1],
[68,35,21,2],
[68,35,21,4],
[68,35,21,10],
[68,35,21,11],
[68,35,21,14],
[68,35,21,16],
[68,35,21,17],
[68,35,21,19],
[68,35,21,20],
[68,35,22,0],
[68,35,22,1],
[68,35,22,2],
[68,35,22,3],
[68,35,22,4],
[68,35,22,5],
[68,35,22,6],
[68,35,22,8],
[68,35,22,12],
[68,35,22,13],
[68,35,22,14],
[68,35,22,16],
[68,35,22,17],
[68,35,22,19],
[68,35,22,21],
[68,35,23,0],
[68,35,23,1],
[68,35,23,2],
[68,35,23,3],
[68,35,23,4],
[68,35,23,5],
[68,35,23,6],
[68,35,23,10],
[68,35,23,11],
[68,35,23,12],
[68,35,23,13],
[68,35,23,14],
[68,35,23,16],
[68,35,23,17],
[68,35,23,19],
[68,35,23,20],
[68,35,23,21],
[68,35,23,22],
[68,35,24,0],
[68,35,24,1],
[68,35,24,2],
[68,35,24,3],
[68,35,24,4],
[68,35,24,5],
[68,35,24,6],
[68,35,24,10],
[68,35,24,11],
[68,35,24,12],
[68,35,24,13],
[68,35,24,14],
[68,35,24,17],
[68,35,24,19],
[68,35,24,20],
[68,35,24,21],
[68,35,24,22],
[68,35,24,23],
[68,35,27,3],
[68,35,27,4],
[68,35,27,5],
[68,35,27,6],
[68,35,27,8],
[68,35,27,11],
[68,35,27,12],
[68,35,27,13],
[68,35,27,14],
[68,35,27,16],
[68,35,27,17],
[68,35,27,19],
[68,35,29,0],
[68,35,29,1],
[68,35,29,2],
[68,35,29,3],
[68,35,29,4],
[68,35,29,5],
[68,35,29,6],
[68,35,29,8],
[68,35,29,10],
[68,35,29,13],
[68,35,29,14],
[68,35,29,16],
[68,35,29,17],
[68,35,30,0],
[68,35,30,1],
[68,35,30,2],
[68,35,30,3],
[68,35,30,4],
[68,35,30,5],
[68,35,30,6],
[68,35,30,8],
[68,35,30,10],
[68,35,30,11],
[68,35,30,12],
[68,35,30,13],
[68,35,30,14],
[68,35,30,16],
[68,35,30,17],
[68,35,31,0],
[68,35,31,1],
[68,35,31,2],
[68,35,31,3],
[68,35,31,4],
[68,35,31,5],
[68,35,31,6],
[68,35,31,8],
[68,35,31,10],
[68,35,31,11],
[68,35,31,12],
[68,35,31,13],
[68,35,31,14],
[68,35,32,0],
[68,35,32,1],
[68,35,32,2],
[68,35,32,3],
[68,35,32,4],
[68,35,32,5],
[68,35,32,6],
[68,35,32,8],
[68,35,32,10],
[68,35,32,13],
[68,35,32,14],
[68,35,33,0],
[68,35,33,1],
[68,35,33,2],
[68,35,33,3],
[68,35,33,4],
[68,35,33,5],
[68,35,33,6],
[68,35,33,8],
[68,35,33,10],
[68,35,33,13],
[68,36,3,0],
[68,36,3,1],
[68,36,3,2],
[68,36,4,0],
[68,36,4,1],
[68,36,4,2],
[68,36,4,3],
[68,36,5,0],
[68,36,5,1],
[68,36,5,2],
[68,36,5,4],
[68,36,6,0],
[68,36,6,1],
[68,36,6,2],
[68,36,6,4],
[68,36,8,0],
[68,36,8,1],
[68,36,8,2],
[68,36,8,4],
[68,36,9,0],
[68,36,9,1],
[68,36,9,2],
[68,36,9,3],
[68,36,9,4],
[68,36,9,5],
[68,36,9,6],
[68,36,9,8],
[68,36,10,0],
[68,36,10,1],
[68,36,10,2],
[68,36,10,3],
[68,36,10,4],
[68,36,10,5],
[68,36,10,6],
[68,36,10,8],
[68,36,10,9],
[68,36,11,0],
[68,36,11,1],
[68,36,11,2],
[68,36,11,3],
[68,36,11,4],
[68,36,11,5],
[68,36,11,6],
[68,36,11,8],
[68,36,11,9],
[68,36,11,10],
[68,36,12,0],
[68,36,12,1],
[68,36,12,2],
[68,36,12,3],
[68,36,12,4],
[68,36,12,5],
[68,36,12,6],
[68,36,12,8],
[68,36,12,9],
[68,36,12,10],
[68,36,15,0],
[68,36,15,1],
[68,36,15,2],
[68,36,15,3],
[68,36,15,4],
[68,36,15,5],
[68,36,15,6],
[68,36,15,8],
[68,36,15,10],
[68,36,15,11],
[68,36,15,12],
[68,36,16,0],
[68,36,16,1],
[68,36,16,2],
[68,36,16,3],
[68,36,16,4],
[68,36,16,5],
[68,36,16,6],
[68,36,16,8],
[68,36,16,9],
[68,36,16,10],
[68,36,16,11],
[68,36,16,12],
[68,36,16,15],
[68,36,17,0],
[68,36,17,1],
[68,36,17,2],
[68,36,17,3],
[68,36,17,4],
[68,36,17,5],
[68,36,17,6],
[68,36,17,8],
[68,36,17,9],
[68,36,17,10],
[68,36,17,11],
[68,36,17,12],
[68,36,17,15],
[68,36,18,0],
[68,36,18,1],
[68,36,18,2],
[68,36,18,3],
[68,36,18,4],
[68,36,18,5],
[68,36,18,6],
[68,36,18,8],
[68,36,18,10],
[68,36,18,11],
[68,36,18,12],
[68,36,18,16],
[68,36,18,17],
[68,36,20,0],
[68,36,20,1],
[68,36,20,2],
[68,36,20,3],
[68,36,20,4],
[68,36,20,5],
[68,36,20,6],
[68,36,20,8],
[68,36,20,9],
[68,36,20,11],
[68,36,20,15],
[68,36,20,16],
[68,36,20,17],
[68,36,20,18],
[68,36,21,0],
[68,36,21,1],
[68,36,21,2],
[68,36,21,4],
[68,36,21,9],
[68,36,21,12],
[68,36,21,15],
[68,36,21,16],
[68,36,21,17],
[68,36,21,18],
[68,36,21,20],
[68,36,22,0],
[68,36,22,1],
[68,36,22,2],
[68,36,22,3],
[68,36,22,4],
[68,36,22,5],
[68,36,22,6],
[68,36,22,11],
[68,36,22,12],
[68,36,22,15],
[68,36,22,16],
[68,36,22,17],
[68,36,22,18],
[68,36,22,21],
[68,36,23,0],
[68,36,23,1],
[68,36,23,2],
[68,36,23,3],
[68,36,23,4],
[68,36,23,5],
[68,36,23,8],
[68,36,23,9],
[68,36,23,10],
[68,36,23,11],
[68,36,23,12],
[68,36,23,15],
[68,36,23,16],
[68,36,23,17],
[68,36,23,18],
[68,36,23,20],
[68,36,23,21],
[68,36,23,22],
[68,36,24,0],
[68,36,24,1],
[68,36,24,2],
[68,36,24,3],
[68,36,24,4],
[68,36,24,5],
[68,36,24,8],
[68,36,24,9],
[68,36,24,10],
[68,36,24,11],
[68,36,24,12],
[68,36,24,15],
[68,36,24,17],
[68,36,24,18],
[68,36,24,20],
[68,36,24,21],
[68,36,24,22],
[68,36,24,23],
[68,36,26,3],
[68,36,26,4],
[68,36,26,5],
[68,36,26,6],
[68,36,26,8],
[68,36,26,10],
[68,36,26,11],
[68,36,26,12],
[68,36,26,16],
[68,36,26,17],
[68,36,26,20],
[68,36,27,0],
[68,36,27,1],
[68,36,27,2],
[68,36,27,3],
[68,36,27,4],
[68,36,27,5],
[68,36,27,6],
[68,36,27,8],
[68,36,27,9],
[68,36,27,11],
[68,36,27,12],
[68,36,27,15],
[68,36,27,16],
[68,36,27,17],
[68,36,27,18],
[68,36,29,0],
[68,36,29,1],
[68,36,29,2],
[68,36,29,3],
[68,36,29,4],
[68,36,29,5],
[68,36,29,6],
[68,36,29,8],
[68,36,29,9],
[68,36,29,10],
[68,36,29,15],
[68,36,29,16],
[68,36,29,17],
[68,36,30,0],
[68,36,30,1],
[68,36,30,2],
[68,36,30,3],
[68,36,30,4],
[68,36,30,5],
[68,36,30,6],
[68,36,30,8],
[68,36,30,9],
[68,36,30,10],
[68,36,30,11],
[68,36,30,12],
[68,36,30,15],
[68,36,31,0],
[68,36,31,1],
[68,36,31,2],
[68,36,31,3],
[68,36,31,4],
[68,36,31,5],
[68,36,31,6],
[68,36,31,8],
[68,36,31,9],
[68,36,31,10],
[68,36,31,11],
[68,36,31,12],
[68,36,32,0],
[68,36,32,1],
[68,36,32,2],
[68,36,32,3],
[68,36,32,4],
[68,36,32,5],
[68,36,32,6],
[68,36,32,8],
[68,36,32,9],
[68,36,32,10],
[68,36,33,0],
[68,36,33,1],
[68,36,33,2],
[68,36,33,3],
[68,36,33,4],
[68,36,33,5],
[68,36,33,6],
[68,36,33,8],
[68,36,33,9],
[68,36,33,10],
[68,36,35,0],
[68,36,35,1],
[68,36,35,2],
[68,36,35,3],
[68,36,35,4],
[68,36,35,5],
[68,36,35,6],
[68,37,3,0],
[68,37,3,1],
[68,37,3,2],
[68,37,5,0],
[68,37,5,1],
[68,37,5,2],
[68,37,6,0],
[68,37,6,1],
[68,37,6,2],
[68,37,8,0],
[68,37,8,1],
[68,37,8,2],
[68,37,9,0],
[68,37,9,1],
[68,37,9,2],
[68,37,9,3],
[68,37,9,5],
[68,37,9,6],
[68,37,9,8],
[68,37,10,0],
[68,37,10,1],
[68,37,10,2],
[68,37,10,3],
[68,37,10,5],
[68,37,10,6],
[68,37,10,8],
[68,37,10,9],
[68,37,11,0],
[68,37,11,1],
[68,37,11,2],
[68,37,11,3],
[68,37,11,5],
[68,37,11,6],
[68,37,11,8],
[68,37,11,9],
[68,37,11,10],
[68,37,12,0],
[68,37,12,1],
[68,37,12,2],
[68,37,12,3],
[68,37,12,5],
[68,37,12,6],
[68,37,12,8],
[68,37,12,9],
[68,37,12,10],
[68,37,13,0],
[68,37,13,1],
[68,37,13,2],
[68,37,13,3],
[68,37,13,5],
[68,37,13,6],
[68,37,13,8],
[68,37,13,9],
[68,37,13,10],
[68,37,13,11],
[68,37,13,12],
[68,37,14,0],
[68,37,14,1],
[68,37,14,2],
[68,37,14,3],
[68,37,14,5],
[68,37,14,6],
[68,37,14,8],
[68,37,14,9],
[68,37,14,10],
[68,37,14,11],
[68,37,14,12],
[68,37,15,0],
[68,37,15,1],
[68,37,15,2],
[68,37,15,3],
[68,37,15,5],
[68,37,15,6],
[68,37,15,8],
[68,37,15,10],
[68,37,15,11],
[68,37,15,12],
[68,37,15,13],
[68,37,15,14],
[68,37,16,0],
[68,37,16,1],
[68,37,16,2],
[68,37,16,3],
[68,37,16,5],
[68,37,16,6],
[68,37,16,8],
[68,37,16,9],
[68,37,16,10],
[68,37,16,11],
[68,37,16,12],
[68,37,16,13],
[68,37,16,14],
[68,37,17,0],
[68,37,17,1],
[68,37,17,2],
[68,37,17,3],
[68,37,17,5],
[68,37,17,6],
[68,37,17,8],
[68,37,17,9],
[68,37,17,10],
[68,37,17,11],
[68,37,17,12],
[68,37,17,13],
[68,37,17,14],
[68,37,17,16],
[68,37,18,0],
[68,37,18,1],
[68,37,18,2],
[68,37,18,3],
[68,37,18,5],
[68,37,18,6],
[68,37,18,8],
[68,37,18,10],
[68,37,18,11],
[68,37,18,12],
[68,37,18,13],
[68,37,18,16],
[68,37,18,17],
[68,37,19,0],
[68,37,19,1],
[68,37,19,2],
[68,37,19,3],
[68,37,19,5],
[68,37,19,6],
[68,37,19,8],
[68,37,19,9],
[68,37,19,10],
[68,37,19,11],
[68,37,19,15],
[68,37,19,16],
[68,37,19,17],
[68,37,19,18],
[68,37,20,0],
[68,37,20,1],
[68,37,20,2],
[68,37,20,3],
[68,37,20,5],
[68,37,20,6],
[68,37,20,8],
[68,37,20,9],
[68,37,20,12],
[68,37,20,13],
[68,37,20,14],
[68,37,20,15],
[68,37,20,16],
[68,37,20,17],
[68,37,20,18],
[68,37,20,19],
[68,37,21,0],
[68,37,21,1],
[68,37,21,2],
[68,37,21,10],
[68,37,21,11],
[68,37,21,12],
[68,37,21,13],
[68,37,21,14],
[68,37,21,15],
[68,37,21,16],
[68,37,21,17],
[68,37,21,18],
[68,37,21,19],
[68,37,21,20],
[68,37,22,0],
[68,37,22,1],
[68,37,22,2],
[68,37,22,3],
[68,37,22,5],
[68,37,22,8],
[68,37,22,9],
[68,37,22,11],
[68,37,22,12],
[68,37,22,13],
[68,37,22,14],
[68,37,22,15],
[68,37,22,16],
[68,37,22,17],
[68,37,22,18],
[68,37,22,19],
[68,37,22,21],
[68,37,23,0],
[68,37,23,1],
[68,37,23,2],
[68,37,23,3],
[68,37,23,6],
[68,37,23,8],
[68,37,23,9],
[68,37,23,10],
[68,37,23,11],
[68,37,23,12],
[68,37,23,13],
[68,37,23,14],
[68,37,23,15],
[68,37,23,16],
[68,37,23,17],
[68,37,23,18],
[68,37,23,19],
[68,37,23,20],
[68,37,23,21],
[68,37,23,22],
[68,37,24,0],
[68,37,24,1],
[68,37,24,2],
[68,37,24,3],
[68,37,24,6],
[68,37,24,8],
[68,37,24,9],
[68,37,24,10],
[68,37,24,11],
[68,37,24,12],
[68,37,24,13],
[68,37,24,14],
[68,37,24,15],
[68,37,24,17],
[68,37,24,18],
[68,37,24,19],
[68,37,24,20],
[68,37,24,21],
[68,37,24,22],
[68,37,26,0],
[68,37,26,1],
[68,37,26,2],
[68,37,26,3],
[68,37,26,5],
[68,37,26,6],
[68,37,26,8],
[68,37,26,10],
[68,37,26,11],
[68,37,26,12],
[68,37,26,13],
[68,37,26,14],
[68,37,26,16],
[68,37,26,17],
[68,37,26,19],
[68,37,27,0],
[68,37,27,1],
[68,37,27,2],
[68,37,27,3],
[68,37,27,5],
[68,37,27,6],
[68,37,27,8],
[68,37,27,9],
[68,37,27,11],
[68,37,27,12],
[68,37,27,13],
[68,37,27,14],
[68,37,27,15],
[68,37,27,16],
[68,37,27,17],
[68,37,27,18],
[68,37,29,0],
[68,37,29,1],
[68,37,29,2],
[68,37,29,3],
[68,37,29,5],
[68,37,29,6],
[68,37,29,8],
[68,37,29,9],
[68,37,29,10],
[68,37,29,13],
[68,37,29,14],
[68,37,29,15],
[68,37,30,0],
[68,37,30,1],
[68,37,30,2],
[68,37,30,3],
[68,37,30,5],
[68,37,30,6],
[68,37,30,8],
[68,37,30,9],
[68,37,30,10],
[68,37,30,11],
[68,37,30,12],
[68,37,30,13],
[68,37,30,14],
[68,37,31,0],
[68,37,31,1],
[68,37,31,2],
[68,37,31,3],
[68,37,31,5],
[68,37,31,6],
[68,37,31,8],
[68,37,31,9],
[68,37,31,10],
[68,37,31,11],
[68,37,31,12],
[68,37,31,13],
[68,37,32,0],
[68,37,32,1],
[68,37,32,2],
[68,37,32,3],
[68,37,32,5],
[68,37,32,6],
[68,37,32,8],
[68,37,32,9],
[68,37,32,10],
[68,37,33,0],
[68,37,33,1],
[68,37,33,2],
[68,37,33,3],
[68,37,33,5],
[68,37,33,6],
[68,37,33,8],
[68,37,33,9],
[68,37,35,0],
[68,37,35,1],
[68,37,35,2],
[68,37,35,3],
[68,37,35,5],
[68,37,36,0],
[68,37,36,1],
[68,37,36,2],
[68,37,36,3],
[68,39,4,0],
[68,39,4,1],
[68,39,4,2],
[68,39,9,0],
[68,39,9,1],
[68,39,9,2],
[68,39,9,4],
[68,39,10,0],
[68,39,10,1],
[68,39,10,2],
[68,39,10,4],
[68,39,10,9],
[68,39,11,0],
[68,39,11,1],
[68,39,11,2],
[68,39,11,4],
[68,39,11,9],
[68,39,11,10],
[68,39,12,0],
[68,39,12,1],
[68,39,12,2],
[68,39,12,4],
[68,39,12,9],
[68,39,12,10],
[68,39,13,0],
[68,39,13,1],
[68,39,13,2],
[68,39,13,4],
[68,39,13,9],
[68,39,13,10],
[68,39,13,11],
[68,39,13,12],
[68,39,14,0],
[68,39,14,1],
[68,39,14,2],
[68,39,14,4],
[68,39,14,9],
[68,39,14,10],
[68,39,14,11],
[68,39,14,12],
[68,39,15,0],
[68,39,15,1],
[68,39,15,2],
[68,39,15,4],
[68,39,15,10],
[68,39,15,11],
[68,39,15,12],
[68,39,15,13],
[68,39,16,0],
[68,39,16,1],
[68,39,16,2],
[68,39,16,4],
[68,39,16,9],
[68,39,16,10],
[68,39,16,11],
[68,39,16,14],
[68,39,16,15],
[68,39,17,0],
[68,39,17,1],
[68,39,17,2],
[68,39,17,4],
[68,39,17,9],
[68,39,17,10],
[68,39,17,11],
[68,39,17,14],
[68,39,17,15],
[68,39,17,16],
[68,39,18,0],
[68,39,18,1],
[68,39,18,2],
[68,39,18,4],
[68,39,18,12],
[68,39,18,13],
[68,39,18,14],
[68,39,18,16],
[68,39,18,17],
[68,39,19,0],
[68,39,19,1],
[68,39,19,2],
[68,39,19,4],
[68,39,19,10],
[68,39,19,11],
[68,39,19,12],
[68,39,19,15],
[68,39,19,16],
[68,39,19,17],
[68,39,19,18],
[68,39,20,0],
[68,39,20,1],
[68,39,20,2],
[68,39,20,4],
[68,39,20,9],
[68,39,20,11],
[68,39,20,12],
[68,39,20,13],
[68,39,20,14],
[68,39,20,15],
[68,39,20,16],
[68,39,20,17],
[68,39,20,18],
[68,39,20,19],
[68,39,22,0],
[68,39,22,1],
[68,39,22,2],
[68,39,22,9],
[68,39,22,11],
[68,39,22,12],
[68,39,22,13],
[68,39,22,14],
[68,39,22,15],
[68,39,22,16],
[68,39,22,17],
[68,39,22,18],
[68,39,22,19],
[68,39,23,4],
[68,39,23,9],
[68,39,23,10],
[68,39,23,11],
[68,39,23,12],
[68,39,23,13],
[68,39,23,14],
[68,39,23,15],
[68,39,23,16],
[68,39,23,17],
[68,39,23,18],
[68,39,23,19],
[68,39,23,20],
[68,39,24,4],
[68,39,24,9],
[68,39,24,10],
[68,39,24,11],
[68,39,24,12],
[68,39,24,13],
[68,39,24,14],
[68,39,24,15],
[68,39,24,17],
[68,39,24,18],
[68,39,24,19],
[68,39,24,20],
[68,39,26,0],
[68,39,26,1],
[68,39,26,2],
[68,39,26,4],
[68,39,26,10],
[68,39,26,11],
[68,39,26,12],
[68,39,26,13],
[68,39,26,14],
[68,39,26,16],
[68,39,26,17],
[68,39,27,0],
[68,39,27,1],
[68,39,27,2],
[68,39,27,4],
[68,39,27,9],
[68,39,27,11],
[68,39,27,12],
[68,39,27,13],
[68,39,27,14],
[68,39,27,15],
[68,39,29,0],
[68,39,29,1],
[68,39,29,2],
[68,39,29,4],
[68,39,29,9],
[68,39,29,10],
[68,39,29,13],
[68,39,30,0],
[68,39,30,1],
[68,39,30,2],
[68,39,30,4],
[68,39,30,9],
[68,39,30,10],
[68,39,30,11],
[68,39,31,0],
[68,39,31,1],
[68,39,31,2],
[68,39,31,4],
[68,39,31,9],
[68,39,32,0],
[68,39,32,1],
[68,39,32,2],
[68,39,32,4],
[68,39,33,0],
[68,39,33,1],
[68,39,33,2],
[68,39,33,4],
[68,39,35,0],
[68,39,35,1],
[68,39,35,2],
[68,40,3,0],
[68,40,3,1],
[68,40,3,2],
[68,40,4,0],
[68,40,4,1],
[68,40,4,2],
[68,40,4,3],
[68,40,5,0],
[68,40,5,1],
[68,40,5,2],
[68,40,5,4],
[68,40,6,0],
[68,40,6,1],
[68,40,6,2],
[68,40,6,4],
[68,40,8,0],
[68,40,8,1],
[68,40,8,2],
[68,40,8,4],
[68,40,10,0],
[68,40,10,1],
[68,40,10,2],
[68,40,10,3],
[68,40,10,4],
[68,40,10,5],
[68,40,10,6],
[68,40,10,8],
[68,40,11,0],
[68,40,11,1],
[68,40,11,2],
[68,40,11,3],
[68,40,11,4],
[68,40,11,5],
[68,40,11,6],
[68,40,11,8],
[68,40,11,10],
[68,40,12,0],
[68,40,12,1],
[68,40,12,2],
[68,40,12,3],
[68,40,12,4],
[68,40,12,5],
[68,40,12,6],
[68,40,12,8],
[68,40,12,10],
[68,40,13,0],
[68,40,13,1],
[68,40,13,2],
[68,40,13,3],
[68,40,13,4],
[68,40,13,5],
[68,40,13,6],
[68,40,13,8],
[68,40,13,10],
[68,40,13,11],
[68,40,13,12],
[68,40,14,0],
[68,40,14,1],
[68,40,14,2],
[68,40,14,3],
[68,40,14,4],
[68,40,14,5],
[68,40,14,6],
[68,40,14,8],
[68,40,14,10],
[68,40,14,11],
[68,40,14,12],
[68,40,16,0],
[68,40,16,1],
[68,40,16,2],
[68,40,16,3],
[68,40,16,4],
[68,40,16,5],
[68,40,16,6],
[68,40,16,8],
[68,40,16,12],
[68,40,16,13],
[68,40,16,14],
[68,40,17,0],
[68,40,17,1],
[68,40,17,2],
[68,40,17,3],
[68,40,17,4],
[68,40,17,5],
[68,40,17,6],
[68,40,17,8],
[68,40,17,12],
[68,40,17,13],
[68,40,17,14],
[68,40,17,16],
[68,40,19,0],
[68,40,19,1],
[68,40,19,2],
[68,40,19,3],
[68,40,19,4],
[68,40,19,5],
[68,40,19,8],
[68,40,19,10],
[68,40,19,11],
[68,40,19,12],
[68,40,19,16],
[68,40,19,17],
[68,40,20,0],
[68,40,20,1],
[68,40,20,2],
[68,40,20,3],
[68,40,20,4],
[68,40,20,6],
[68,40,20,8],
[68,40,20,11],
[68,40,20,12],
[68,40,20,13],
[68,40,20,14],
[68,40,20,16],
[68,40,20,17],
[68,40,20,19],
[68,40,21,0],
[68,40,21,1],
[68,40,21,2],
[68,40,21,10],
[68,40,21,11],
[68,40,21,12],
[68,40,21,13],
[68,40,21,14],
[68,40,21,16],
[68,40,21,17],
[68,40,21,19],
[68,40,21,20],
[68,40,22,3],
[68,40,22,4],
[68,40,22,5],
[68,40,22,6],
[68,40,22,8],
[68,40,22,11],
[68,40,22,12],
[68,40,22,13],
[68,40,22,14],
[68,40,22,16],
[68,40,22,17],
[68,40,22,19],
[68,40,23,0],
[68,40,23,1],
[68,40,23,2],
[68,40,23,3],
[68,40,23,4],
[68,40,23,5],
[68,40,23,6],
[68,40,23,8],
[68,40,23,10],
[68,40,23,11],
[68,40,23,12],
[68,40,23,13],
[68,40,23,14],
[68,40,23,16],
[68,40,23,17],
[68,40,23,19],
[68,40,24,0],
[68,40,24,1],
[68,40,24,2],
[68,40,24,3],
[68,40,24,4],
[68,40,24,5],
[68,40,24,6],
[68,40,24,8],
[68,40,24,10],
[68,40,24,11],
[68,40,24,12],
[68,40,24,13],
[68,40,24,14],
[68,40,24,17],
[68,40,24,19],
[68,40,27,0],
[68,40,27,1],
[68,40,27,2],
[68,40,27,3],
[68,40,27,4],
[68,40,27,5],
[68,40,27,6],
[68,40,27,8],
[68,40,27,11],
[68,40,27,12],
[68,40,27,13],
[68,40,27,14],
[68,40,29,0],
[68,40,29,1],
[68,40,29,2],
[68,40,29,3],
[68,40,29,4],
[68,40,29,5],
[68,40,29,6],
[68,40,29,8],
[68,40,29,10],
[68,40,30,0],
[68,40,30,1],
[68,40,30,2],
[68,40,30,3],
[68,40,30,4],
[68,40,30,5],
[68,40,30,6],
[68,40,30,8],
[68,40,31,0],
[68,40,31,1],
[68,40,31,2],
[68,40,31,3],
[68,40,31,4],
[68,40,31,5],
[68,40,31,6],
[68,40,32,0],
[68,40,32,1],
[68,40,32,2],
[68,40,32,3],
[68,40,32,4],
[68,40,32,5],
[68,40,33,0],
[68,40,33,1],
[68,40,33,2],
[68,40,33,3],
[68,40,33,4],
[68,41,3,0],
[68,41,3,1],
[68,41,3,2],
[68,41,4,0],
[68,41,4,1],
[68,41,4,2],
[68,41,4,3],
[68,41,5,0],
[68,41,5,1],
[68,41,5,2],
[68,41,5,4],
[68,41,6,0],
[68,41,6,1],
[68,41,6,2],
[68,41,6,4],
[68,41,8,0],
[68,41,8,1],
[68,41,8,2],
[68,41,8,4],
[68,41,10,0],
[68,41,10,1],
[68,41,10,2],
[68,41,10,3],
[68,41,10,4],
[68,41,10,5],
[68,41,10,6],
[68,41,10,8],
[68,41,11,0],
[68,41,11,1],
[68,41,11,2],
[68,41,11,3],
[68,41,11,4],
[68,41,11,5],
[68,41,11,6],
[68,41,11,8],
[68,41,11,10],
[68,41,12,0],
[68,41,12,1],
[68,41,12,2],
[68,41,12,3],
[68,41,12,4],
[68,41,12,5],
[68,41,12,6],
[68,41,12,8],
[68,41,12,10],
[68,41,13,0],
[68,41,13,1],
[68,41,13,2],
[68,41,13,3],
[68,41,13,4],
[68,41,13,5],
[68,41,13,6],
[68,41,13,8],
[68,41,13,10],
[68,41,13,11],
[68,41,13,12],
[68,41,14,0],
[68,41,14,1],
[68,41,14,2],
[68,41,14,3],
[68,41,14,4],
[68,41,14,5],
[68,41,14,6],
[68,41,14,8],
[68,41,14,10],
[68,41,14,11],
[68,41,14,12],
[68,41,16,0],
[68,41,16,1],
[68,41,16,2],
[68,41,16,3],
[68,41,16,4],
[68,41,16,5],
[68,41,16,6],
[68,41,16,8],
[68,41,16,12],
[68,41,16,13],
[68,41,16,14],
[68,41,17,0],
[68,41,17,1],
[68,41,17,2],
[68,41,17,3],
[68,41,17,4],
[68,41,17,5],
[68,41,17,6],
[68,41,17,8],
[68,41,17,12],
[68,41,17,13],
[68,41,17,14],
[68,41,17,16],
[68,41,19,0],
[68,41,19,1],
[68,41,19,2],
[68,41,19,3],
[68,41,19,4],
[68,41,19,5],
[68,41,19,8],
[68,41,19,10],
[68,41,19,11],
[68,41,19,12],
[68,41,19,16],
[68,41,19,17],
[68,41,20,0],
[68,41,20,1],
[68,41,20,2],
[68,41,20,3],
[68,41,20,4],
[68,41,20,6],
[68,41,20,8],
[68,41,20,11],
[68,41,20,12],
[68,41,20,13],
[68,41,20,14],
[68,41,20,16],
[68,41,20,17],
[68,41,20,19],
[68,41,21,0],
[68,41,21,1],
[68,41,21,2],
[68,41,21,10],
[68,41,21,11],
[68,41,21,12],
[68,41,21,13],
[68,41,21,14],
[68,41,21,16],
[68,41,21,17],
[68,41,21,19],
[68,41,21,20],
[68,41,22,3],
[68,41,22,4],
[68,41,22,5],
[68,41,22,6],
[68,41,22,8],
[68,41,22,11],
[68,41,22,12],
[68,41,22,13],
[68,41,22,14],
[68,41,22,16],
[68,41,22,17],
[68,41,22,19],
[68,41,23,0],
[68,41,23,1],
[68,41,23,2],
[68,41,23,3],
[68,41,23,4],
[68,41,23,5],
[68,41,23,6],
[68,41,23,8],
[68,41,23,10],
[68,41,23,11],
[68,41,23,12],
[68,41,23,13],
[68,41,23,14],
[68,41,23,16],
[68,41,23,17],
[68,41,23,19],
[68,41,24,0],
[68,41,24,1],
[68,41,24,2],
[68,41,24,3],
[68,41,24,4],
[68,41,24,5],
[68,41,24,6],
[68,41,24,8],
[68,41,24,10],
[68,41,24,11],
[68,41,24,12],
[68,41,24,13],
[68,41,24,14],
[68,41,24,17],
[68,41,24,19],
[68,41,27,0],
[68,41,27,1],
[68,41,27,2],
[68,41,27,3],
[68,41,27,4],
[68,41,27,5],
[68,41,27,6],
[68,41,27,8],
[68,41,27,11],
[68,41,27,12],
[68,41,27,13],
[68,41,27,14],
[68,41,29,0],
[68,41,29,1],
[68,41,29,2],
[68,41,29,3],
[68,41,29,4],
[68,41,29,5],
[68,41,29,6],
[68,41,29,8],
[68,41,29,10],
[68,41,30,0],
[68,41,30,1],
[68,41,30,2],
[68,41,30,3],
[68,41,30,4],
[68,41,30,5],
[68,41,30,6],
[68,41,30,8],
[68,41,31,0],
[68,41,31,1],
[68,41,31,2],
[68,41,31,3],
[68,41,31,4],
[68,41,31,5],
[68,41,31,6],
[68,41,32,0],
[68,41,32,1],
[68,41,32,2],
[68,41,32,3],
[68,41,32,4],
[68,41,32,5],
[68,41,33,0],
[68,41,33,1],
[68,41,33,2],
[68,41,33,3],
[68,41,33,4],
[68,42,3,0],
[68,42,3,1],
[68,42,3,2],
[68,42,4,0],
[68,42,4,1],
[68,42,4,2],
[68,42,4,3],
[68,42,5,0],
[68,42,5,1],
[68,42,5,2],
[68,42,5,4],
[68,42,6,0],
[68,42,6,1],
[68,42,6,2],
[68,42,6,4],
[68,42,8,0],
[68,42,8,1],
[68,42,8,2],
[68,42,8,4],
[68,42,9,0],
[68,42,9,1],
[68,42,9,2],
[68,42,9,3],
[68,42,9,4],
[68,42,9,5],
[68,42,9,6],
[68,42,9,8],
[68,42,11,0],
[68,42,11,1],
[68,42,11,2],
[68,42,11,3],
[68,42,11,4],
[68,42,11,5],
[68,42,11,6],
[68,42,11,8],
[68,42,11,9],
[68,42,12,0],
[68,42,12,1],
[68,42,12,2],
[68,42,12,3],
[68,42,12,4],
[68,42,12,5],
[68,42,12,6],
[68,42,12,8],
[68,42,12,9],
[68,42,13,0],
[68,42,13,1],
[68,42,13,2],
[68,42,13,3],
[68,42,13,4],
[68,42,13,5],
[68,42,13,6],
[68,42,13,8],
[68,42,13,9],
[68,42,13,11],
[68,42,13,12],
[68,42,14,0],
[68,42,14,1],
[68,42,14,2],
[68,42,14,3],
[68,42,14,4],
[68,42,14,5],
[68,42,14,6],
[68,42,14,8],
[68,42,14,9],
[68,42,14,11],
[68,42,15,0],
[68,42,15,1],
[68,42,15,2],
[68,42,15,3],
[68,42,15,4],
[68,42,15,5],
[68,42,15,6],
[68,42,15,8],
[68,42,15,12],
[68,42,15,13],
[68,42,15,14],
[68,42,16,0],
[68,42,16,1],
[68,42,16,2],
[68,42,16,3],
[68,42,16,4],
[68,42,16,5],
[68,42,16,6],
[68,42,16,11],
[68,42,16,12],
[68,42,16,13],
[68,42,16,14],
[68,42,16,15],
[68,42,17,0],
[68,42,17,1],
[68,42,17,2],
[68,42,17,3],
[68,42,17,4],
[68,42,17,5],
[68,42,17,6],
[68,42,17,11],
[68,42,17,12],
[68,42,17,13],
[68,42,17,14],
[68,42,17,15],
[68,42,17,16],
[68,42,18,0],
[68,42,18,1],
[68,42,18,2],
[68,42,18,3],
[68,42,18,4],
[68,42,18,5],
[68,42,18,8],
[68,42,18,11],
[68,42,18,12],
[68,42,18,13],
[68,42,18,14],
[68,42,18,16],
[68,42,18,17],
[68,42,19,0],
[68,42,19,1],
[68,42,19,2],
[68,42,19,3],
[68,42,19,4],
[68,42,19,6],
[68,42,19,8],
[68,42,19,9],
[68,42,19,11],
[68,42,19,12],
[68,42,19,15],
[68,42,19,16],
[68,42,19,17],
[68,42,19,18],
[68,42,21,4],
[68,42,21,9],
[68,42,21,11],
[68,42,21,12],
[68,42,21,13],
[68,42,21,14],
[68,42,21,15],
[68,42,21,16],
[68,42,21,17],
[68,42,21,18],
[68,42,21,19],
[68,42,23,0],
[68,42,23,1],
[68,42,23,2],
[68,42,23,3],
[68,42,23,4],
[68,42,23,5],
[68,42,23,6],
[68,42,23,8],
[68,42,23,9],
[68,42,23,11],
[68,42,23,12],
[68,42,23,13],
[68,42,23,14],
[68,42,23,15],
[68,42,23,16],
[68,42,23,17],
[68,42,23,18],
[68,42,24,0],
[68,42,24,1],
[68,42,24,2],
[68,42,24,3],
[68,42,24,4],
[68,42,24,5],
[68,42,24,6],
[68,42,24,8],
[68,42,24,9],
[68,42,24,11],
[68,42,24,12],
[68,42,24,13],
[68,42,24,14],
[68,42,24,15],
[68,42,24,17],
[68,42,24,18],
[68,42,26,0],
[68,42,26,1],
[68,42,26,2],
[68,42,26,3],
[68,42,26,4],
[68,42,26,5],
[68,42,26,6],
[68,42,26,8],
[68,42,26,11],
[68,42,26,12],
[68,42,26,13],
[68,42,26,14],
[68,42,29,0],
[68,42,29,1],
[68,42,29,2],
[68,42,29,3],
[68,42,29,4],
[68,42,29,5],
[68,42,29,6],
[68,42,29,8],
[68,42,29,9],
[68,42,30,0],
[68,42,30,1],
[68,42,30,2],
[68,42,30,3],
[68,42,30,4],
[68,42,30,5],
[68,42,30,6],
[68,42,31,0],
[68,42,31,1],
[68,42,31,2],
[68,42,31,3],
[68,42,31,4],
[68,42,31,5],
[68,42,32,0],
[68,42,32,1],
[68,42,32,2],
[68,42,32,3],
[68,42,32,4],
[68,42,33,0],
[68,42,33,1],
[68,42,33,2],
[68,44,3,0],
[68,44,3,1],
[68,44,3,2],
[68,44,4,0],
[68,44,4,1],
[68,44,4,2],
[68,44,4,3],
[68,44,5,0],
[68,44,5,1],
[68,44,5,2],
[68,44,5,4],
[68,44,6,0],
[68,44,6,1],
[68,44,6,2],
[68,44,6,4],
[68,44,8,0],
[68,44,8,1],
[68,44,8,2],
[68,44,8,4],
[68,44,9,0],
[68,44,9,1],
[68,44,9,2],
[68,44,9,3],
[68,44,9,4],
[68,44,9,5],
[68,44,9,6],
[68,44,9,8],
[68,44,10,0],
[68,44,10,1],
[68,44,10,2],
[68,44,10,3],
[68,44,10,4],
[68,44,10,5],
[68,44,10,6],
[68,44,10,8],
[68,44,10,9],
[68,44,11,0],
[68,44,11,1],
[68,44,11,2],
[68,44,11,3],
[68,44,11,4],
[68,44,11,5],
[68,44,11,6],
[68,44,11,8],
[68,44,11,9],
[68,44,11,10],
[68,44,12,0],
[68,44,12,1],
[68,44,12,2],
[68,44,12,3],
[68,44,12,4],
[68,44,12,5],
[68,44,12,6],
[68,44,12,8],
[68,44,12,9],
[68,44,13,0],
[68,44,13,1],
[68,44,13,2],
[68,44,13,3],
[68,44,13,4],
[68,44,13,5],
[68,44,13,6],
[68,44,13,8],
[68,44,13,9],
[68,44,13,12],
[68,44,14,0],
[68,44,14,1],
[68,44,14,2],
[68,44,14,3],
[68,44,14,4],
[68,44,14,5],
[68,44,14,6],
[68,44,14,10],
[68,44,14,11],
[68,44,14,12],
[68,44,15,0],
[68,44,15,1],
[68,44,15,2],
[68,44,15,3],
[68,44,15,4],
[68,44,15,5],
[68,44,15,8],
[68,44,15,10],
[68,44,15,11],
[68,44,15,12],
[68,44,15,13],
[68,44,15,14],
[68,44,16,0],
[68,44,16,1],
[68,44,16,2],
[68,44,16,3],
[68,44,16,4],
[68,44,16,6],
[68,44,16,8],
[68,44,16,9],
[68,44,16,10],
[68,44,16,11],
[68,44,16,12],
[68,44,16,13],
[68,44,16,14],
[68,44,16,15],
[68,44,18,0],
[68,44,18,1],
[68,44,18,2],
[68,44,18,5],
[68,44,18,6],
[68,44,18,8],
[68,44,18,10],
[68,44,18,11],
[68,44,18,12],
[68,44,18,13],
[68,44,18,14],
[68,44,18,16],
[68,44,19,3],
[68,44,19,4],
[68,44,19,5],
[68,44,19,6],
[68,44,19,8],
[68,44,19,9],
[68,44,19,10],
[68,44,19,11],
[68,44,19,12],
[68,44,19,15],
[68,44,19,16],
[68,44,19,18],
[68,44,20,0],
[68,44,20,1],
[68,44,20,2],
[68,44,20,3],
[68,44,20,4],
[68,44,20,5],
[68,44,20,6],
[68,44,20,8],
[68,44,20,9],
[68,44,20,11],
[68,44,20,12],
[68,44,20,13],
[68,44,20,14],
[68,44,20,15],
[68,44,20,16],
[68,44,20,18],
[68,44,20,19],
[68,44,21,0],
[68,44,21,1],
[68,44,21,2],
[68,44,21,4],
[68,44,21,9],
[68,44,21,10],
[68,44,21,11],
[68,44,21,12],
[68,44,21,13],
[68,44,21,14],
[68,44,21,15],
[68,44,21,16],
[68,44,21,18],
[68,44,22,0],
[68,44,22,1],
[68,44,22,2],
[68,44,22,3],
[68,44,22,4],
[68,44,22,5],
[68,44,22,6],
[68,44,22,8],
[68,44,22,9],
[68,44,22,11],
[68,44,22,12],
[68,44,22,13],
[68,44,22,14],
[68,44,22,15],
[68,44,22,16],
[68,44,23,0],
[68,44,23,1],
[68,44,23,2],
[68,44,23,3],
[68,44,23,4],
[68,44,23,5],
[68,44,23,6],
[68,44,23,8],
[68,44,23,9],
[68,44,23,10],
[68,44,23,11],
[68,44,23,12],
[68,44,23,13],
[68,44,23,14],
[68,44,23,15],
[68,44,24,0],
[68,44,24,1],
[68,44,24,2],
[68,44,24,3],
[68,44,24,4],
[68,44,24,5],
[68,44,24,6],
[68,44,24,8],
[68,44,24,9],
[68,44,24,10],
[68,44,24,11],
[68,44,24,12],
[68,44,24,13],
[68,44,24,14],
[68,44,24,15],
[68,44,26,0],
[68,44,26,1],
[68,44,26,2],
[68,44,26,3],
[68,44,26,4],
[68,44,26,5],
[68,44,26,6],
[68,44,26,8],
[68,44,26,10],
[68,44,26,11],
[68,44,27,0],
[68,44,27,1],
[68,44,27,2],
[68,44,27,3],
[68,44,27,4],
[68,44,27,5],
[68,44,27,6],
[68,44,27,8],
[68,44,27,9],
[68,44,29,0],
[68,44,29,1],
[68,44,29,2],
[68,44,29,3],
[68,44,29,4],
[68,44,29,5],
[68,44,30,0],
[68,44,30,1],
[68,44,30,2],
[68,44,30,3],
[68,44,30,4],
[68,44,31,0],
[68,44,31,1],
[68,44,31,2],
[68,45,3,0],
[68,45,3,1],
[68,45,3,2],
[68,45,4,0],
[68,45,4,1],
[68,45,4,2],
[68,45,4,3],
[68,45,5,0],
[68,45,5,1],
[68,45,5,2],
[68,45,5,4],
[68,45,6,0],
[68,45,6,1],
[68,45,6,2],
[68,45,6,4],
[68,45,8,0],
[68,45,8,1],
[68,45,8,2],
[68,45,8,4],
[68,45,10,0],
[68,45,10,1],
[68,45,10,2],
[68,45,10,3],
[68,45,10,4],
[68,45,10,5],
[68,45,10,6],
[68,45,10,8],
[68,45,11,0],
[68,45,11,1],
[68,45,11,2],
[68,45,11,3],
[68,45,11,4],
[68,45,11,5],
[68,45,11,6],
[68,45,11,8],
[68,45,12,0],
[68,45,12,1],
[68,45,12,2],
[68,45,12,3],
[68,45,12,4],
[68,45,12,5],
[68,45,12,6],
[68,45,12,10],
[68,45,13,0],
[68,45,13,1],
[68,45,13,2],
[68,45,13,3],
[68,45,13,4],
[68,45,13,5],
[68,45,13,6],
[68,45,13,10],
[68,45,13,11],
[68,45,13,12],
[68,45,14,0],
[68,45,14,1],
[68,45,14,2],
[68,45,14,3],
[68,45,14,4],
[68,45,14,5],
[68,45,14,8],
[68,45,14,10],
[68,45,14,11],
[68,45,14,12],
[68,45,16,0],
[68,45,16,1],
[68,45,16,2],
[68,45,16,5],
[68,45,16,6],
[68,45,16,8],
[68,45,16,10],
[68,45,16,11],
[68,45,16,12],
[68,45,16,13],
[68,45,16,14],
[68,45,17,0],
[68,45,17,1],
[68,45,17,2],
[68,45,17,5],
[68,45,17,6],
[68,45,17,8],
[68,45,17,10],
[68,45,17,11],
[68,45,17,12],
[68,45,17,13],
[68,45,17,14],
[68,45,17,16],
[68,45,19,0],
[68,45,19,1],
[68,45,19,2],
[68,45,19,3],
[68,45,19,4],
[68,45,19,5],
[68,45,19,6],
[68,45,19,8],
[68,45,19,10],
[68,45,19,11],
[68,45,19,12],
[68,45,19,16],
[68,45,19,17],
[68,45,20,0],
[68,45,20,1],
[68,45,20,2],
[68,45,20,3],
[68,45,20,4],
[68,45,20,5],
[68,45,20,6],
[68,45,20,8],
[68,45,20,11],
[68,45,20,12],
[68,45,20,13],
[68,45,20,14],
[68,45,20,16],
[68,45,20,17],
[68,45,21,0],
[68,45,21,1],
[68,45,21,2],
[68,45,21,4],
[68,45,21,10],
[68,45,21,11],
[68,45,21,12],
[68,45,21,13],
[68,45,21,14],
[68,45,21,16],
[68,45,21,17],
[68,45,22,0],
[68,45,22,1],
[68,45,22,2],
[68,45,22,3],
[68,45,22,4],
[68,45,22,5],
[68,45,22,6],
[68,45,22,8],
[68,45,22,11],
[68,45,22,12],
[68,45,22,13],
[68,45,22,14],
[68,45,23,0],
[68,45,23,1],
[68,45,23,2],
[68,45,23,3],
[68,45,23,4],
[68,45,23,5],
[68,45,23,6],
[68,45,23,8],
[68,45,23,10],
[68,45,23,11],
[68,45,23,12],
[68,45,23,13],
[68,45,23,14],
[68,45,24,0],
[68,45,24,1],
[68,45,24,2],
[68,45,24,3],
[68,45,24,4],
[68,45,24,5],
[68,45,24,6],
[68,45,24,8],
[68,45,24,10],
[68,45,24,11],
[68,45,24,12],
[68,45,24,13],
[68,45,24,14],
[68,45,27,0],
[68,45,27,1],
[68,45,27,2],
[68,45,27,3],
[68,45,27,4],
[68,45,27,5],
[68,45,27,6],
[68,45,29,0],
[68,45,29,1],
[68,45,29,2],
[68,45,29,3],
[68,45,29,4],
[68,45,30,0],
[68,45,30,1],
[68,45,30,2],
[68,46,3,0],
[68,46,3,1],
[68,46,3,2],
[68,46,4,0],
[68,46,4,1],
[68,46,4,2],
[68,46,4,3],
[68,46,5,0],
[68,46,5,1],
[68,46,5,2],
[68,46,5,4],
[68,46,6,0],
[68,46,6,1],
[68,46,6,2],
[68,46,6,4],
[68,46,8,0],
[68,46,8,1],
[68,46,8,2],
[68,46,8,4],
[68,46,10,0],
[68,46,10,1],
[68,46,10,2],
[68,46,10,3],
[68,46,10,4],
[68,46,10,5],
[68,46,10,6],
[68,46,11,0],
[68,46,11,1],
[68,46,11,2],
[68,46,11,3],
[68,46,11,4],
[68,46,11,5],
[68,46,11,6],
[68,46,11,10],
[68,46,12,0],
[68,46,12,1],
[68,46,12,2],
[68,46,12,3],
[68,46,12,4],
[68,46,12,5],
[68,46,12,8],
[68,46,12,10],
[68,46,13,0],
[68,46,13,1],
[68,46,13,2],
[68,46,13,3],
[68,46,13,4],
[68,46,13,5],
[68,46,13,8],
[68,46,13,10],
[68,46,13,11],
[68,46,13,12],
[68,46,14,0],
[68,46,14,1],
[68,46,14,2],
[68,46,14,3],
[68,46,14,4],
[68,46,14,6],
[68,46,14,8],
[68,46,14,10],
[68,46,14,11],
[68,46,14,12],
[68,46,16,3],
[68,46,16,4],
[68,46,16,5],
[68,46,16,6],
[68,46,16,8],
[68,46,16,10],
[68,46,16,11],
[68,46,16,12],
[68,46,16,13],
[68,46,16,14],
[68,46,17,3],
[68,46,17,4],
[68,46,17,5],
[68,46,17,6],
[68,46,17,8],
[68,46,17,10],
[68,46,17,11],
[68,46,17,12],
[68,46,17,13],
[68,46,17,14],
[68,46,17,16],
[68,46,19,0],
[68,46,19,1],
[68,46,19,2],
[68,46,19,3],
[68,46,19,4],
[68,46,19,5],
[68,46,19,6],
[68,46,19,8],
[68,46,19,10],
[68,46,19,11],
[68,46,19,12],
[68,46,19,16],
[68,46,19,17],
[68,46,20,0],
[68,46,20,1],
[68,46,20,2],
[68,46,20,3],
[68,46,20,4],
[68,46,20,5],
[68,46,20,6],
[68,46,20,8],
[68,46,20,11],
[68,46,20,12],
[68,46,20,13],
[68,46,20,14],
[68,46,20,16],
[68,46,20,17],
[68,46,21,0],
[68,46,21,1],
[68,46,21,2],
[68,46,21,4],
[68,46,21,10],
[68,46,21,11],
[68,46,21,12],
[68,46,21,13],
[68,46,21,14],
[68,46,22,0],
[68,46,22,1],
[68,46,22,2],
[68,46,22,3],
[68,46,22,4],
[68,46,22,5],
[68,46,22,6],
[68,46,22,8],
[68,46,22,11],
[68,46,22,12],
[68,46,22,13],
[68,46,22,14],
[68,46,23,0],
[68,46,23,1],
[68,46,23,2],
[68,46,23,3],
[68,46,23,4],
[68,46,23,5],
[68,46,23,6],
[68,46,23,8],
[68,46,23,10],
[68,46,23,11],
[68,46,23,12],
[68,46,23,13],
[68,46,24,0],
[68,46,24,1],
[68,46,24,2],
[68,46,24,3],
[68,46,24,4],
[68,46,24,5],
[68,46,24,6],
[68,46,24,8],
[68,46,24,10],
[68,46,24,11],
[68,46,24,12],
[68,46,24,13],
[68,46,27,0],
[68,46,27,1],
[68,46,27,2],
[68,46,27,3],
[68,46,27,4],
[68,46,27,5],
[68,46,29,0],
[68,46,29,1],
[68,46,29,2],
[68,47,3,0],
[68,47,3,1],
[68,47,3,2],
[68,47,4,0],
[68,47,4,1],
[68,47,4,2],
[68,47,4,3],
[68,47,5,0],
[68,47,5,1],
[68,47,5,2],
[68,47,5,4],
[68,47,6,0],
[68,47,6,1],
[68,47,6,2],
[68,47,6,4],
[68,47,8,0],
[68,47,8,1],
[68,47,8,2],
[68,47,8,4],
[68,47,9,0],
[68,47,9,1],
[68,47,9,2],
[68,47,9,3],
[68,47,9,4],
[68,47,9,5],
[68,47,9,6],
[68,47,10,0],
[68,47,10,1],
[68,47,10,2],
[68,47,10,3],
[68,47,10,4],
[68,47,10,5],
[68,47,10,8],
[68,47,10,9],
[68,47,11,0],
[68,47,11,1],
[68,47,11,2],
[68,47,11,3],
[68,47,11,4],
[68,47,11,5],
[68,47,11,8],
[68,47,11,9],
[68,47,11,10],
[68,47,12,0],
[68,47,12,1],
[68,47,12,2],
[68,47,12,3],
[68,47,12,4],
[68,47,12,6],
[68,47,12,8],
[68,47,12,9],
[68,47,12,10],
[68,47,13,0],
[68,47,13,1],
[68,47,13,2],
[68,47,13,3],
[68,47,13,4],
[68,47,13,6],
[68,47,13,8],
[68,47,13,9],
[68,47,13,10],
[68,47,13,11],
[68,47,13,12],
[68,47,14,0],
[68,47,14,1],
[68,47,14,2],
[68,47,14,5],
[68,47,14,6],
[68,47,14,8],
[68,47,14,9],
[68,47,14,10],
[68,47,14,11],
[68,47,14,12],
[68,47,15,3],
[68,47,15,4],
[68,47,15,5],
[68,47,15,6],
[68,47,15,8],
[68,47,15,10],
[68,47,15,11],
[68,47,15,12],
[68,47,15,13],
[68,47,15,14],
[68,47,16,0],
[68,47,16,1],
[68,47,16,2],
[68,47,16,3],
[68,47,16,4],
[68,47,16,5],
[68,47,16,6],
[68,47,16,8],
[68,47,16,9],
[68,47,16,10],
[68,47,16,11],
[68,47,16,12],
[68,47,16,13],
[68,47,16,14],
[68,47,16,15],
[68,47,17,0],
[68,47,17,1],
[68,47,17,2],
[68,47,17,3],
[68,47,17,4],
[68,47,17,5],
[68,47,17,6],
[68,47,17,8],
[68,47,17,9],
[68,47,17,10],
[68,47,17,11],
[68,47,17,12],
[68,47,17,13],
[68,47,17,14],
[68,47,17,15],
[68,47,17,16],
[68,47,18,0],
[68,47,18,1],
[68,47,18,2],
[68,47,18,3],
[68,47,18,4],
[68,47,18,5],
[68,47,18,6],
[68,47,18,8],
[68,47,18,10],
[68,47,18,11],
[68,47,18,12],
[68,47,18,13],
[68,47,18,14],
[68,47,18,16],
[68,47,18,17],
[68,47,19,0],
[68,47,19,1],
[68,47,19,2],
[68,47,19,3],
[68,47,19,4],
[68,47,19,5],
[68,47,19,6],
[68,47,19,8],
[68,47,19,9],
[68,47,19,10],
[68,47,19,11],
[68,47,19,12],
[68,47,19,15],
[68,47,19,16],
[68,47,19,17],
[68,47,20,0],
[68,47,20,1],
[68,47,20,2],
[68,47,20,3],
[68,47,20,4],
[68,47,20,5],
[68,47,20,6],
[68,47,20,8],
[68,47,20,9],
[68,47,20,11],
[68,47,20,12],
[68,47,20,13],
[68,47,20,14],
[68,47,20,15],
[68,47,21,0],
[68,47,21,1],
[68,47,21,2],
[68,47,21,4],
[68,47,21,9],
[68,47,21,10],
[68,47,21,11],
[68,47,21,12],
[68,47,21,13],
[68,47,21,14],
[68,47,22,0],
[68,47,22,1],
[68,47,22,2],
[68,47,22,3],
[68,47,22,4],
[68,47,22,5],
[68,47,22,6],
[68,47,22,8],
[68,47,22,9],
[68,47,22,11],
[68,47,22,12],
[68,47,22,13],
[68,47,24,0],
[68,47,24,1],
[68,47,24,2],
[68,47,24,3],
[68,47,24,4],
[68,47,24,5],
[68,47,24,6],
[68,47,24,8],
[68,47,24,9],
[68,47,24,10],
[68,47,24,11],
[68,47,26,0],
[68,47,26,1],
[68,47,26,2],
[68,47,26,3],
[68,47,26,4],
[68,47,26,5],
[68,47,27,0],
[68,47,27,1],
[68,47,27,2],
[68,47,27,3],
[68,47,27,4],
[68,48,3,0],
[68,48,3,1],
[68,48,3,2],
[68,48,4,0],
[68,48,4,1],
[68,48,4,2],
[68,48,4,3],
[68,48,5,0],
[68,48,5,1],
[68,48,5,2],
[68,48,5,4],
[68,48,6,0],
[68,48,6,1],
[68,48,6,2],
[68,48,6,4],
[68,48,8,0],
[68,48,8,1],
[68,48,8,2],
[68,48,8,4],
[68,48,9,0],
[68,48,9,1],
[68,48,9,2],
[68,48,9,3],
[68,48,9,4],
[68,48,9,5],
[68,48,9,8],
[68,48,10,0],
[68,48,10,1],
[68,48,10,2],
[68,48,10,3],
[68,48,10,4],
[68,48,10,6],
[68,48,10,8],
[68,48,10,9],
[68,48,13,0],
[68,48,13,1],
[68,48,13,2],
[68,48,13,5],
[68,48,13,6],
[68,48,13,8],
[68,48,13,9],
[68,48,13,10],
[68,48,14,3],
[68,48,14,4],
[68,48,14,5],
[68,48,14,6],
[68,48,14,8],
[68,48,14,9],
[68,48,14,10],
[68,48,15,0],
[68,48,15,1],
[68,48,15,2],
[68,48,15,3],
[68,48,15,4],
[68,48,15,5],
[68,48,15,6],
[68,48,15,8],
[68,48,15,10],
[68,48,15,13],
[68,48,15,14],
[68,48,16,0],
[68,48,16,1],
[68,48,16,2],
[68,48,16,3],
[68,48,16,4],
[68,48,16,5],
[68,48,16,6],
[68,48,16,8],
[68,48,16,9],
[68,48,16,10],
[68,48,16,13],
[68,48,16,14],
[68,48,16,15],
[68,48,17,0],
[68,48,17,1],
[68,48,17,2],
[68,48,17,3],
[68,48,17,4],
[68,48,17,5],
[68,48,17,6],
[68,48,17,8],
[68,48,17,9],
[68,48,17,10],
[68,48,17,13],
[68,48,17,14],
[68,48,17,15],
[68,48,17,16],
[68,48,18,0],
[68,48,18,1],
[68,48,18,2],
[68,48,18,3],
[68,48,18,4],
[68,48,18,5],
[68,48,18,6],
[68,48,18,8],
[68,48,18,10],
[68,48,18,13],
[68,48,18,14],
[68,48,18,16],
[68,48,18,17],
[68,48,19,0],
[68,48,19,1],
[68,48,19,2],
[68,48,19,3],
[68,48,19,4],
[68,48,19,5],
[68,48,19,6],
[68,48,19,8],
[68,48,19,9],
[68,48,19,10],
[68,48,19,15],
[68,48,20,0],
[68,48,20,1],
[68,48,20,2],
[68,48,20,3],
[68,48,20,4],
[68,48,20,5],
[68,48,20,6],
[68,48,20,8],
[68,48,20,9],
[68,48,20,13],
[68,48,20,14],
[68,48,21,0],
[68,48,21,1],
[68,48,21,2],
[68,48,21,4],
[68,48,21,9],
[68,48,21,10],
[68,48,21,13],
[68,48,22,0],
[68,48,22,1],
[68,48,22,2],
[68,48,22,3],
[68,48,22,4],
[68,48,22,5],
[68,48,22,6],
[68,48,22,8],
[68,48,22,9],
[68,48,23,0],
[68,48,23,1],
[68,48,23,2],
[68,48,23,3],
[68,48,23,4],
[68,48,23,5],
[68,48,23,6],
[68,48,23,8],
[68,48,23,9],
[68,48,24,0],
[68,48,24,1],
[68,48,24,2],
[68,48,24,3],
[68,48,24,4],
[68,48,24,5],
[68,48,24,6],
[68,48,24,8],
[68,48,24,9],
[68,48,26,0],
[68,48,26,1],
[68,48,26,2],
[68,48,26,3],
[68,48,26,4],
[68,48,27,0],
[68,48,27,1],
[68,48,27,2],
[68,49,3,0],
[68,49,3,1],
[68,49,3,2],
[68,49,4,0],
[68,49,4,1],
[68,49,4,2],
[68,49,4,3],
[68,49,5,0],
[68,49,5,1],
[68,49,5,2],
[68,49,5,4],
[68,49,6,0],
[68,49,6,1],
[68,49,6,2],
[68,49,6,4],
[68,49,8,0],
[68,49,8,1],
[68,49,8,2],
[68,49,8,4],
[68,49,10,0],
[68,49,10,1],
[68,49,10,2],
[68,49,10,3],
[68,49,10,4],
[68,49,10,6],
[68,49,10,8],
[68,49,11,0],
[68,49,11,1],
[68,49,11,2],
[68,49,11,3],
[68,49,11,4],
[68,49,11,6],
[68,49,11,8],
[68,49,11,10],
[68,49,12,0],
[68,49,12,1],
[68,49,12,2],
[68,49,12,5],
[68,49,12,6],
[68,49,12,8],
[68,49,12,10],
[68,49,13,0],
[68,49,13,1],
[68,49,13,2],
[68,49,13,5],
[68,49,13,6],
[68,49,13,8],
[68,49,13,10],
[68,49,13,11],
[68,49,13,12],
[68,49,14,3],
[68,49,14,4],
[68,49,14,5],
[68,49,14,6],
[68,49,14,8],
[68,49,14,10],
[68,49,14,11],
[68,49,14,12],
[68,49,16,0],
[68,49,16,1],
[68,49,16,2],
[68,49,16,3],
[68,49,16,4],
[68,49,16,5],
[68,49,16,6],
[68,49,16,8],
[68,49,16,10],
[68,49,16,11],
[68,49,16,12],
[68,49,16,13],
[68,49,16,14],
[68,49,17,0],
[68,49,17,1],
[68,49,17,2],
[68,49,17,3],
[68,49,17,4],
[68,49,17,5],
[68,49,17,6],
[68,49,17,8],
[68,49,17,10],
[68,49,17,11],
[68,49,17,12],
[68,49,17,13],
[68,49,17,14],
[68,49,17,16],
[68,49,19,0],
[68,49,19,1],
[68,49,19,2],
[68,49,19,3],
[68,49,19,4],
[68,49,19,5],
[68,49,19,6],
[68,49,19,8],
[68,49,19,10],
[68,49,19,11],
[68,49,19,12],
[68,49,20,0],
[68,49,20,1],
[68,49,20,2],
[68,49,20,3],
[68,49,20,4],
[68,49,20,5],
[68,49,20,6],
[68,49,20,8],
[68,49,20,11],
[68,49,20,12],
[68,49,20,13],
[68,49,20,14],
[68,49,21,0],
[68,49,21,1],
[68,49,21,2],
[68,49,21,4],
[68,49,21,10],
[68,49,21,11],
[68,49,21,12],
[68,49,21,13],
[68,49,22,0],
[68,49,22,1],
[68,49,22,2],
[68,49,22,3],
[68,49,22,4],
[68,49,22,5],
[68,49,22,6],
[68,49,22,8],
[68,49,22,11],
[68,49,23,0],
[68,49,23,1],
[68,49,23,2],
[68,49,23,3],
[68,49,23,4],
[68,49,23,5],
[68,49,23,6],
[68,49,23,8],
[68,49,24,0],
[68,49,24,1],
[68,49,24,2],
[68,49,24,3],
[68,49,24,4],
[68,49,24,5],
[68,49,24,6],
[68,49,24,8],
[68,49,27,0],
[68,49,27,1],
[68,49,27,2],
[68,50,3,0],
[68,50,3,1],
[68,50,3,2],
[68,50,4,0],
[68,50,4,1],
[68,50,4,2],
[68,50,4,3],
[68,50,5,0],
[68,50,5,1],
[68,50,5,2],
[68,50,5,4],
[68,50,6,0],
[68,50,6,1],
[68,50,6,2],
[68,50,6,4],
[68,50,8,0],
[68,50,8,1],
[68,50,8,2],
[68,50,8,4],
[68,50,10,0],
[68,50,10,1],
[68,50,10,2],
[68,50,10,3],
[68,50,10,4],
[68,50,10,6],
[68,50,10,8],
[68,50,11,0],
[68,50,11,1],
[68,50,11,2],
[68,50,11,3],
[68,50,11,4],
[68,50,11,6],
[68,50,11,8],
[68,50,11,10],
[68,50,12,0],
[68,50,12,1],
[68,50,12,2],
[68,50,12,5],
[68,50,12,6],
[68,50,12,8],
[68,50,12,10],
[68,50,13,0],
[68,50,13,1],
[68,50,13,2],
[68,50,13,5],
[68,50,13,6],
[68,50,13,8],
[68,50,13,10],
[68,50,13,11],
[68,50,13,12],
[68,50,14,3],
[68,50,14,4],
[68,50,14,5],
[68,50,14,6],
[68,50,14,8],
[68,50,14,10],
[68,50,14,11],
[68,50,14,12],
[68,50,16,0],
[68,50,16,1],
[68,50,16,2],
[68,50,16,3],
[68,50,16,4],
[68,50,16,5],
[68,50,16,6],
[68,50,16,8],
[68,50,16,10],
[68,50,16,11],
[68,50,16,12],
[68,50,16,13],
[68,50,16,14],
[68,50,17,0],
[68,50,17,1],
[68,50,17,2],
[68,50,17,3],
[68,50,17,4],
[68,50,17,5],
[68,50,17,6],
[68,50,17,8],
[68,50,17,10],
[68,50,17,11],
[68,50,17,12],
[68,50,17,13],
[68,50,17,14],
[68,50,17,16],
[68,50,19,0],
[68,50,19,1],
[68,50,19,2],
[68,50,19,3],
[68,50,19,4],
[68,50,19,5],
[68,50,19,6],
[68,50,19,8],
[68,50,19,10],
[68,50,19,11],
[68,50,19,12],
[68,50,20,0],
[68,50,20,1],
[68,50,20,2],
[68,50,20,3],
[68,50,20,4],
[68,50,20,5],
[68,50,20,6],
[68,50,20,8],
[68,50,20,11],
[68,50,20,12],
[68,50,20,13],
[68,50,20,14],
[68,50,21,0],
[68,50,21,1],
[68,50,21,2],
[68,50,21,4],
[68,50,21,10],
[68,50,21,11],
[68,50,21,12],
[68,50,21,13],
[68,50,22,0],
[68,50,22,1],
[68,50,22,2],
[68,50,22,3],
[68,50,22,4],
[68,50,22,5],
[68,50,22,6],
[68,50,22,8],
[68,50,22,11],
[68,50,23,0],
[68,50,23,1],
[68,50,23,2],
[68,50,23,3],
[68,50,23,4],
[68,50,23,5],
[68,50,23,6],
[68,50,23,8],
[68,50,24,0],
[68,50,24,1],
[68,50,24,2],
[68,50,24,3],
[68,50,24,4],
[68,50,24,5],
[68,50,24,6],
[68,50,24,8],
[68,50,27,0],
[68,50,27,1],
[68,50,27,2],
[68,51,4,0],
[68,51,4,1],
[68,51,4,2],
[68,51,9,0],
[68,51,9,1],
[68,51,9,2],
[68,51,9,4],
[68,51,10,0],
[68,51,10,1],
[68,51,10,2],
[68,51,10,4],
[68,51,10,9],
[68,51,11,0],
[68,51,11,1],
[68,51,11,2],
[68,51,11,4],
[68,51,11,9],
[68,51,11,10],
[68,51,12,0],
[68,51,12,1],
[68,51,12,2],
[68,51,12,9],
[68,51,12,10],
[68,51,13,0],
[68,51,13,1],
[68,51,13,2],
[68,51,13,9],
[68,51,13,10],
[68,51,13,11],
[68,51,13,12],
[68,51,14,4],
[68,51,14,9],
[68,51,14,10],
[68,51,14,11],
[68,51,14,12],
[68,51,15,0],
[68,51,15,1],
[68,51,15,2],
[68,51,15,4],
[68,51,15,10],
[68,51,15,11],
[68,51,15,12],
[68,51,15,13],
[68,51,15,14],
[68,51,16,0],
[68,51,16,1],
[68,51,16,2],
[68,51,16,4],
[68,51,16,9],
[68,51,16,10],
[68,51,16,11],
[68,51,16,12],
[68,51,16,13],
[68,51,16,14],
[68,51,16,15],
[68,51,17,0],
[68,51,17,1],
[68,51,17,2],
[68,51,17,4],
[68,51,17,9],
[68,51,17,10],
[68,51,17,11],
[68,51,17,12],
[68,51,17,13],
[68,51,17,14],
[68,51,17,15],
[68,51,17,16],
[68,51,18,0],
[68,51,18,1],
[68,51,18,2],
[68,51,18,4],
[68,51,18,10],
[68,51,18,11],
[68,51,18,12],
[68,51,18,13],
[68,51,18,14],
[68,51,18,16],
[68,51,18,17],
[68,51,19,0],
[68,51,19,1],
[68,51,19,2],
[68,51,19,4],
[68,51,19,9],
[68,51,19,10],
[68,51,19,11],
[68,51,19,12],
[68,51,19,15],
[68,51,20,0],
[68,51,20,1],
[68,51,20,2],
[68,51,20,4],
[68,51,20,9],
[68,51,20,11],
[68,51,20,12],
[68,51,20,13],
[68,51,20,14],
[68,51,22,0],
[68,51,22,1],
[68,51,22,2],
[68,51,22,4],
[68,51,22,9],
[68,51,22,11],
[68,51,23,0],
[68,51,23,1],
[68,51,23,2],
[68,51,23,4],
[68,51,23,9],
[68,51,24,0],
[68,51,24,1],
[68,51,24,2],
[68,51,24,4],
[68,51,24,9],
[68,51,26,0],
[68,51,26,1],
[68,51,26,2],
[68,51,26,4],
[68,51,27,0],
[68,51,27,1],
[68,51,27,2],
[68,52,3,0],
[68,52,3,1],
[68,52,3,2],
[68,52,4,0],
[68,52,4,1],
[68,52,4,2],
[68,52,4,3],
[68,52,5,0],
[68,52,5,1],
[68,52,5,2],
[68,52,5,4],
[68,52,6,0],
[68,52,6,1],
[68,52,6,2],
[68,52,6,4],
[68,52,8,0],
[68,52,8,1],
[68,52,8,2],
[68,52,8,4],
[68,52,10,0],
[68,52,10,1],
[68,52,10,2],
[68,52,10,3],
[68,52,10,4],
[68,52,10,6],
[68,52,10,8],
[68,52,11,0],
[68,52,11,1],
[68,52,11,2],
[68,52,11,3],
[68,52,11,4],
[68,52,11,6],
[68,52,11,8],
[68,52,11,10],
[68,52,12,0],
[68,52,12,1],
[68,52,12,2],
[68,52,12,5],
[68,52,12,6],
[68,52,12,8],
[68,52,12,10],
[68,52,13,0],
[68,52,13,1],
[68,52,13,2],
[68,52,13,5],
[68,52,13,6],
[68,52,13,8],
[68,52,13,10],
[68,52,13,11],
[68,52,13,12],
[68,52,14,3],
[68,52,14,4],
[68,52,14,5],
[68,52,14,6],
[68,52,14,8],
[68,52,14,10],
[68,52,14,11],
[68,52,14,12],
[68,52,16,0],
[68,52,16,1],
[68,52,16,2],
[68,52,16,3],
[68,52,16,4],
[68,52,16,5],
[68,52,16,6],
[68,52,16,8],
[68,52,16,10],
[68,52,16,11],
[68,52,16,12],
[68,52,16,13],
[68,52,16,14],
[68,52,17,0],
[68,52,17,1],
[68,52,17,2],
[68,52,17,3],
[68,52,17,4],
[68,52,17,5],
[68,52,17,6],
[68,52,17,8],
[68,52,17,10],
[68,52,17,11],
[68,52,17,12],
[68,52,17,13],
[68,52,17,14],
[68,52,17,16],
[68,52,19,0],
[68,52,19,1],
[68,52,19,2],
[68,52,19,3],
[68,52,19,4],
[68,52,19,5],
[68,52,19,6],
[68,52,19,8],
[68,52,19,10],
[68,52,19,11],
[68,52,19,12],
[68,52,20,0],
[68,52,20,1],
[68,52,20,2],
[68,52,20,3],
[68,52,20,4],
[68,52,20,5],
[68,52,20,6],
[68,52,20,8],
[68,52,20,11],
[68,52,20,12],
[68,52,20,13],
[68,52,20,14],
[68,52,21,0],
[68,52,21,1],
[68,52,21,2],
[68,52,21,4],
[68,52,21,10],
[68,52,21,11],
[68,52,21,12],
[68,52,21,13],
[68,52,22,0],
[68,52,22,1],
[68,52,22,2],
[68,52,22,3],
[68,52,22,4],
[68,52,22,5],
[68,52,22,6],
[68,52,22,8],
[68,52,22,11],
[68,52,23,0],
[68,52,23,1],
[68,52,23,2],
[68,52,23,3],
[68,52,23,4],
[68,52,23,5],
[68,52,23,6],
[68,52,23,8],
[68,52,24,0],
[68,52,24,1],
[68,52,24,2],
[68,52,24,3],
[68,52,24,4],
[68,52,24,5],
[68,52,24,6],
[68,52,24,8],
[68,52,27,0],
[68,52,27,1],
[68,52,27,2],
[68,53,3,0],
[68,53,3,1],
[68,53,3,2],
[68,53,4,0],
[68,53,4,1],
[68,53,4,2],
[68,53,4,3],
[68,53,5,0],
[68,53,5,1],
[68,53,5,2],
[68,53,5,4],
[68,53,6,0],
[68,53,6,1],
[68,53,6,2],
[68,53,6,4],
[68,53,8,0],
[68,53,8,1],
[68,53,8,2],
[68,53,8,4],
[68,53,9,0],
[68,53,9,1],
[68,53,9,2],
[68,53,9,3],
[68,53,9,4],
[68,53,9,5],
[68,53,9,8],
[68,53,11,0],
[68,53,11,1],
[68,53,11,2],
[68,53,11,3],
[68,53,11,4],
[68,53,11,6],
[68,53,11,8],
[68,53,11,9],
[68,53,12,0],
[68,53,12,1],
[68,53,12,2],
[68,53,12,5],
[68,53,12,6],
[68,53,12,8],
[68,53,12,9],
[68,53,13,0],
[68,53,13,1],
[68,53,13,2],
[68,53,13,5],
[68,53,13,6],
[68,53,13,8],
[68,53,13,9],
[68,53,13,11],
[68,53,13,12],
[68,53,14,3],
[68,53,14,4],
[68,53,14,5],
[68,53,14,6],
[68,53,14,8],
[68,53,14,9],
[68,53,14,11],
[68,53,14,12],
[68,53,15,0],
[68,53,15,1],
[68,53,15,2],
[68,53,15,3],
[68,53,15,4],
[68,53,15,5],
[68,53,15,6],
[68,53,15,8],
[68,53,15,11],
[68,53,15,12],
[68,53,15,13],
[68,53,15,14],
[68,53,16,0],
[68,53,16,1],
[68,53,16,2],
[68,53,16,3],
[68,53,16,4],
[68,53,16,5],
[68,53,16,6],
[68,53,16,8],
[68,53,16,9],
[68,53,16,11],
[68,53,16,12],
[68,53,16,13],
[68,53,16,14],
[68,53,16,15],
[68,53,17,0],
[68,53,17,1],
[68,53,17,2],
[68,53,17,3],
[68,53,17,4],
[68,53,17,5],
[68,53,17,6],
[68,53,17,8],
[68,53,17,9],
[68,53,17,11],
[68,53,17,12],
[68,53,17,13],
[68,53,17,14],
[68,53,17,15],
[68,53,17,16],
[68,53,18,0],
[68,53,18,1],
[68,53,18,2],
[68,53,18,3],
[68,53,18,4],
[68,53,18,5],
[68,53,18,6],
[68,53,18,8],
[68,53,18,11],
[68,53,18,12],
[68,53,18,13],
[68,53,18,14],
[68,53,18,16],
[68,53,18,17],
[68,53,19,0],
[68,53,19,1],
[68,53,19,2],
[68,53,19,3],
[68,53,19,4],
[68,53,19,5],
[68,53,19,6],
[68,53,19,8],
[68,53,19,9],
[68,53,19,11],
[68,53,19,12],
[68,53,19,15],
[68,53,21,0],
[68,53,21,1],
[68,53,21,2],
[68,53,21,4],
[68,53,21,9],
[68,53,21,11],
[68,53,21,12],
[68,53,21,13],
[68,53,23,0],
[68,53,23,1],
[68,53,23,2],
[68,53,23,3],
[68,53,23,4],
[68,53,23,5],
[68,53,23,6],
[68,53,23,8],
[68,53,23,9],
[68,53,24,0],
[68,53,24,1],
[68,53,24,2],
[68,53,24,3],
[68,53,24,4],
[68,53,24,5],
[68,53,24,6],
[68,53,24,8],
[68,53,24,9],
[68,53,26,0],
[68,53,26,1],
[68,53,26,2],
[68,53,26,3],
[68,53,26,4],
[68,54,3,0],
[68,54,3,1],
[68,54,3,2],
[68,54,4,0],
[68,54,4,1],
[68,54,4,2],
[68,54,4,3],
[68,54,5,0],
[68,54,5,1],
[68,54,5,2],
[68,54,5,4],
[68,54,6,0],
[68,54,6,1],
[68,54,6,2],
[68,54,6,4],
[68,54,8,0],
[68,54,8,1],
[68,54,8,2],
[68,54,8,4],
[68,54,9,0],
[68,54,9,1],
[68,54,9,2],
[68,54,9,3],
[68,54,9,4],
[68,54,9,5],
[68,54,9,8],
[68,54,11,0],
[68,54,11,1],
[68,54,11,2],
[68,54,11,3],
[68,54,11,4],
[68,54,11,6],
[68,54,11,8],
[68,54,11,9],
[68,54,12,0],
[68,54,12,1],
[68,54,12,2],
[68,54,12,5],
[68,54,12,6],
[68,54,12,8],
[68,54,12,9],
[68,54,13,0],
[68,54,13,1],
[68,54,13,2],
[68,54,13,5],
[68,54,13,6],
[68,54,13,8],
[68,54,13,9],
[68,54,13,11],
[68,54,13,12],
[68,54,14,3],
[68,54,14,4],
[68,54,14,5],
[68,54,14,6],
[68,54,14,8],
[68,54,14,9],
[68,54,14,11],
[68,54,14,12],
[68,54,15,0],
[68,54,15,1],
[68,54,15,2],
[68,54,15,3],
[68,54,15,4],
[68,54,15,5],
[68,54,15,6],
[68,54,15,8],
[68,54,15,11],
[68,54,15,12],
[68,54,15,13],
[68,54,15,14],
[68,54,16,0],
[68,54,16,1],
[68,54,16,2],
[68,54,16,3],
[68,54,16,4],
[68,54,16,5],
[68,54,16,6],
[68,54,16,8],
[68,54,16,9],
[68,54,16,11],
[68,54,16,12],
[68,54,16,13],
[68,54,16,14],
[68,54,16,15],
[68,54,17,0],
[68,54,17,1],
[68,54,17,2],
[68,54,17,3],
[68,54,17,4],
[68,54,17,5],
[68,54,17,6],
[68,54,17,8],
[68,54,17,9],
[68,54,17,11],
[68,54,17,12],
[68,54,17,13],
[68,54,17,14],
[68,54,17,15],
[68,54,17,16],
[68,54,18,0],
[68,54,18,1],
[68,54,18,2],
[68,54,18,3],
[68,54,18,4],
[68,54,18,5],
[68,54,18,6],
[68,54,18,8],
[68,54,18,11],
[68,54,18,12],
[68,54,18,13],
[68,54,18,14],
[68,54,18,16],
[68,54,18,17],
[68,54,19,0],
[68,54,19,1],
[68,54,19,2],
[68,54,19,3],
[68,54,19,4],
[68,54,19,5],
[68,54,19,6],
[68,54,19,8],
[68,54,19,9],
[68,54,19,11],
[68,54,19,12],
[68,54,19,15],
[68,54,21,0],
[68,54,21,1],
[68,54,21,2],
[68,54,21,4],
[68,54,21,9],
[68,54,21,11],
[68,54,21,12],
[68,54,21,13],
[68,54,23,0],
[68,54,23,1],
[68,54,23,2],
[68,54,23,3],
[68,54,23,4],
[68,54,23,5],
[68,54,23,6],
[68,54,23,8],
[68,54,23,9],
[68,54,24,0],
[68,54,24,1],
[68,54,24,2],
[68,54,24,3],
[68,54,24,4],
[68,54,24,5],
[68,54,24,6],
[68,54,24,8],
[68,54,24,9],
[68,54,26,0],
[68,54,26,1],
[68,54,26,2],
[68,54,26,3],
[68,54,26,4],
[68,55,3,0],
[68,55,3,1],
[68,55,3,2],
[68,55,4,0],
[68,55,4,1],
[68,55,4,2],
[68,55,4,3],
[68,55,5,0],
[68,55,5,1],
[68,55,5,2],
[68,55,5,4],
[68,55,6,0],
[68,55,6,1],
[68,55,6,2],
[68,55,6,4],
[68,55,8,0],
[68,55,8,1],
[68,55,8,2],
[68,55,8,4],
[68,55,9,0],
[68,55,9,1],
[68,55,9,2],
[68,55,9,3],
[68,55,9,4],
[68,55,9,5],
[68,55,9,8],
[68,55,11,0],
[68,55,11,1],
[68,55,11,2],
[68,55,11,3],
[68,55,11,4],
[68,55,11,6],
[68,55,11,8],
[68,55,11,9],
[68,55,12,0],
[68,55,12,1],
[68,55,12,2],
[68,55,12,5],
[68,55,12,6],
[68,55,12,8],
[68,55,12,9],
[68,55,13,0],
[68,55,13,1],
[68,55,13,2],
[68,55,13,5],
[68,55,13,6],
[68,55,13,8],
[68,55,13,9],
[68,55,13,11],
[68,55,13,12],
[68,55,14,3],
[68,55,14,4],
[68,55,14,5],
[68,55,14,6],
[68,55,14,8],
[68,55,14,9],
[68,55,14,11],
[68,55,14,12],
[68,55,15,0],
[68,55,15,1],
[68,55,15,2],
[68,55,15,3],
[68,55,15,4],
[68,55,15,5],
[68,55,15,6],
[68,55,15,8],
[68,55,15,11],
[68,55,15,12],
[68,55,15,13],
[68,55,15,14],
[68,55,16,0],
[68,55,16,1],
[68,55,16,2],
[68,55,16,3],
[68,55,16,4],
[68,55,16,5],
[68,55,16,6],
[68,55,16,8],
[68,55,16,9],
[68,55,16,11],
[68,55,16,12],
[68,55,16,13],
[68,55,16,14],
[68,55,16,15],
[68,55,17,0],
[68,55,17,1],
[68,55,17,2],
[68,55,17,3],
[68,55,17,4],
[68,55,17,5],
[68,55,17,6],
[68,55,17,8],
[68,55,17,9],
[68,55,17,11],
[68,55,17,12],
[68,55,17,13],
[68,55,17,14],
[68,55,17,15],
[68,55,17,16],
[68,55,18,0],
[68,55,18,1],
[68,55,18,2],
[68,55,18,3],
[68,55,18,4],
[68,55,18,5],
[68,55,18,6],
[68,55,18,8],
[68,55,18,11],
[68,55,18,12],
[68,55,18,13],
[68,55,18,14],
[68,55,18,16],
[68,55,18,17],
[68,55,19,0],
[68,55,19,1],
[68,55,19,2],
[68,55,19,3],
[68,55,19,4],
[68,55,19,5],
[68,55,19,6],
[68,55,19,8],
[68,55,19,9],
[68,55,19,11],
[68,55,19,12],
[68,55,19,15],
[68,55,21,0],
[68,55,21,1],
[68,55,21,2],
[68,55,21,4],
[68,55,21,9],
[68,55,21,11],
[68,55,21,12],
[68,55,21,13],
[68,55,23,0],
[68,55,23,1],
[68,55,23,2],
[68,55,23,3],
[68,55,23,4],
[68,55,23,5],
[68,55,23,6],
[68,55,23,8],
[68,55,23,9],
[68,55,24,0],
[68,55,24,1],
[68,55,24,2],
[68,55,24,3],
[68,55,24,4],
[68,55,24,5],
[68,55,24,6],
[68,55,24,8],
[68,55,24,9],
[68,55,26,0],
[68,55,26,1],
[68,55,26,2],
[68,55,26,3],
[68,55,26,4],
[68,56,3,0],
[68,56,3,1],
[68,56,3,2],
[68,56,4,0],
[68,56,4,1],
[68,56,4,2],
[68,56,4,3],
[68,56,5,0],
[68,56,5,1],
[68,56,5,2],
[68,56,5,4],
[68,56,6,0],
[68,56,6,1],
[68,56,6,2],
[68,56,6,4],
[68,56,8,0],
[68,56,8,1],
[68,56,8,2],
[68,56,8,4],
[68,56,10,0],
[68,56,10,1],
[68,56,10,2],
[68,56,10,3],
[68,56,10,4],
[68,56,10,6],
[68,56,10,8],
[68,56,11,0],
[68,56,11,1],
[68,56,11,2],
[68,56,11,3],
[68,56,11,4],
[68,56,11,6],
[68,56,11,8],
[68,56,11,10],
[68,56,12,0],
[68,56,12,1],
[68,56,12,2],
[68,56,12,5],
[68,56,12,6],
[68,56,12,8],
[68,56,12,10],
[68,56,13,0],
[68,56,13,1],
[68,56,13,2],
[68,56,13,5],
[68,56,13,6],
[68,56,13,8],
[68,56,13,10],
[68,56,13,11],
[68,56,13,12],
[68,56,14,3],
[68,56,14,4],
[68,56,14,5],
[68,56,14,6],
[68,56,14,8],
[68,56,14,10],
[68,56,14,11],
[68,56,14,12],
[68,56,16,0],
[68,56,16,1],
[68,56,16,2],
[68,56,16,3],
[68,56,16,4],
[68,56,16,5],
[68,56,16,6],
[68,56,16,8],
[68,56,16,10],
[68,56,16,11],
[68,56,16,12],
[68,56,16,13],
[68,56,16,14],
[68,56,17,0],
[68,56,17,1],
[68,56,17,2],
[68,56,17,3],
[68,56,17,4],
[68,56,17,5],
[68,56,17,6],
[68,56,17,8],
[68,56,17,10],
[68,56,17,11],
[68,56,17,12],
[68,56,17,13],
[68,56,17,14],
[68,56,17,16],
[68,56,19,0],
[68,56,19,1],
[68,56,19,2],
[68,56,19,3],
[68,56,19,4],
[68,56,19,5],
[68,56,19,6],
[68,56,19,8],
[68,56,19,10],
[68,56,19,11],
[68,56,19,12],
[68,56,20,0],
[68,56,20,1],
[68,56,20,2],
[68,56,20,3],
[68,56,20,4],
[68,56,20,5],
[68,56,20,6],
[68,56,20,8],
[68,56,20,11],
[68,56,20,12],
[68,56,20,13],
[68,56,20,14],
[68,56,21,0],
[68,56,21,1],
[68,56,21,2],
[68,56,21,4],
[68,56,21,10],
[68,56,21,11],
[68,56,21,12],
[68,56,21,13],
[68,56,22,0],
[68,56,22,1],
[68,56,22,2],
[68,56,22,3],
[68,56,22,4],
[68,56,22,5],
[68,56,22,6],
[68,56,22,8],
[68,56,22,11],
[68,56,23,0],
[68,56,23,1],
[68,56,23,2],
[68,56,23,3],
[68,56,23,4],
[68,56,23,5],
[68,56,23,6],
[68,56,23,8],
[68,56,24,0],
[68,56,24,1],
[68,56,24,2],
[68,56,24,3],
[68,56,24,4],
[68,56,24,5],
[68,56,24,6],
[68,56,24,8],
[68,56,27,0],
[68,56,27,1],
[68,56,27,2],
[68,57,3,0],
[68,57,3,1],
[68,57,3,2],
[68,57,4,0],
[68,57,4,1],
[68,57,4,2],
[68,57,4,3],
[68,57,5,0],
[68,57,5,1],
[68,57,5,2],
[68,57,5,4],
[68,57,6,0],
[68,57,6,1],
[68,57,6,2],
[68,57,6,4],
[68,57,8,0],
[68,57,8,1],
[68,57,8,2],
[68,57,8,4],
[68,57,9,0],
[68,57,9,1],
[68,57,9,2],
[68,57,9,3],
[68,57,9,4],
[68,57,9,6],
[68,57,9,8],
[68,57,10,0],
[68,57,10,1],
[68,57,10,2],
[68,57,10,5],
[68,57,10,6],
[68,57,10,8],
[68,57,10,9],
[68,57,11,0],
[68,57,11,1],
[68,57,11,2],
[68,57,11,5],
[68,57,11,6],
[68,57,11,8],
[68,57,11,9],
[68,57,11,10],
[68,57,12,3],
[68,57,12,4],
[68,57,12,5],
[68,57,12,6],
[68,57,12,8],
[68,57,12,9],
[68,57,12,10],
[68,57,13,3],
[68,57,13,4],
[68,57,13,5],
[68,57,13,6],
[68,57,13,8],
[68,57,13,9],
[68,57,13,10],
[68,57,13,11],
[68,57,13,12],
[68,57,14,0],
[68,57,14,1],
[68,57,14,2],
[68,57,14,3],
[68,57,14,4],
[68,57,14,5],
[68,57,14,6],
[68,57,14,8],
[68,57,14,9],
[68,57,14,10],
[68,57,14,11],
[68,57,14,12],
[68,57,15,0],
[68,57,15,1],
[68,57,15,2],
[68,57,15,3],
[68,57,15,4],
[68,57,15,5],
[68,57,15,6],
[68,57,15,8],
[68,57,15,10],
[68,57,15,11],
[68,57,15,12],
[68,57,15,13],
[68,57,15,14],
[68,57,16,0],
[68,57,16,1],
[68,57,16,2],
[68,57,16,3],
[68,57,16,4],
[68,57,16,5],
[68,57,16,6],
[68,57,16,8],
[68,57,16,9],
[68,57,16,10],
[68,57,16,11],
[68,57,16,12],
[68,57,16,13],
[68,57,16,14],
[68,57,16,15],
[68,57,17,0],
[68,57,17,1],
[68,57,17,2],
[68,57,17,3],
[68,57,17,4],
[68,57,17,5],
[68,57,17,6],
[68,57,17,8],
[68,57,17,9],
[68,57,17,10],
[68,57,17,11],
[68,57,17,12],
[68,57,17,13],
[68,57,17,14],
[68,57,17,15],
[68,57,17,16],
[68,57,18,0],
[68,57,18,1],
[68,57,18,2],
[68,57,18,3],
[68,57,18,4],
[68,57,18,5],
[68,57,18,6],
[68,57,18,8],
[68,57,18,10],
[68,57,18,11],
[68,57,18,12],
[68,57,18,13],
[68,57,18,14],
[68,57,19,0],
[68,57,19,1],
[68,57,19,2],
[68,57,19,3],
[68,57,19,4],
[68,57,19,5],
[68,57,19,6],
[68,57,19,8],
[68,57,19,9],
[68,57,19,10],
[68,57,19,11],
[68,57,19,12],
[68,57,20,0],
[68,57,20,1],
[68,57,20,2],
[68,57,20,3],
[68,57,20,4],
[68,57,20,5],
[68,57,20,6],
[68,57,20,8],
[68,57,20,9],
[68,57,20,11],
[68,57,20,12],
[68,57,20,13],
[68,57,21,0],
[68,57,21,1],
[68,57,21,2],
[68,57,21,4],
[68,57,21,9],
[68,57,21,10],
[68,57,21,11],
[68,57,22,0],
[68,57,22,1],
[68,57,22,2],
[68,57,22,3],
[68,57,22,4],
[68,57,22,5],
[68,57,22,6],
[68,57,22,8],
[68,57,22,9],
[68,57,24,0],
[68,57,24,1],
[68,57,24,2],
[68,57,24,3],
[68,57,24,4],
[68,57,24,5],
[68,57,24,6],
[68,57,26,0],
[68,57,26,1],
[68,57,26,2],
[68,58,3,0],
[68,58,3,1],
[68,58,3,2],
[68,58,4,0],
[68,58,4,1],
[68,58,4,2],
[68,58,4,3],
[68,58,5,0],
[68,58,5,1],
[68,58,5,2],
[68,58,5,4],
[68,58,6,0],
[68,58,6,1],
[68,58,6,2],
[68,58,6,4],
[68,58,8,0],
[68,58,8,1],
[68,58,8,2],
[68,58,8,4],
[68,58,9,0],
[68,58,9,1],
[68,58,9,2],
[68,58,9,3],
[68,58,9,4],
[68,58,9,6],
[68,58,9,8],
[68,58,10,0],
[68,58,10,1],
[68,58,10,2],
[68,58,10,5],
[68,58,10,6],
[68,58,10,8],
[68,58,10,9],
[68,58,11,0],
[68,58,11,1],
[68,58,11,2],
[68,58,11,5],
[68,58,11,6],
[68,58,11,8],
[68,58,11,9],
[68,58,11,10],
[68,58,12,3],
[68,58,12,4],
[68,58,12,5],
[68,58,12,6],
[68,58,12,8],
[68,58,12,9],
[68,58,12,10],
[68,58,13,3],
[68,58,13,4],
[68,58,13,5],
[68,58,13,6],
[68,58,13,8],
[68,58,13,9],
[68,58,13,10],
[68,58,13,11],
[68,58,13,12],
[68,58,14,0],
[68,58,14,1],
[68,58,14,2],
[68,58,14,3],
[68,58,14,4],
[68,58,14,5],
[68,58,14,6],
[68,58,14,8],
[68,58,14,9],
[68,58,14,10],
[68,58,14,11],
[68,58,14,12],
[68,58,15,0],
[68,58,15,1],
[68,58,15,2],
[68,58,15,3],
[68,58,15,4],
[68,58,15,5],
[68,58,15,6],
[68,58,15,8],
[68,58,15,10],
[68,58,15,11],
[68,58,15,12],
[68,58,15,13],
[68,58,15,14],
[68,58,17,0],
[68,58,17,1],
[68,58,17,2],
[68,58,17,3],
[68,58,17,4],
[68,58,17,5],
[68,58,17,6],
[68,58,17,8],
[68,58,17,9],
[68,58,17,10],
[68,58,17,11],
[68,58,17,12],
[68,58,17,13],
[68,58,17,14],
[68,58,17,15],
[68,58,18,0],
[68,58,18,1],
[68,58,18,2],
[68,58,18,3],
[68,58,18,4],
[68,58,18,5],
[68,58,18,6],
[68,58,18,8],
[68,58,18,10],
[68,58,18,11],
[68,58,18,12],
[68,58,18,13],
[68,58,18,14],
[68,58,19,0],
[68,58,19,1],
[68,58,19,2],
[68,58,19,3],
[68,58,19,4],
[68,58,19,5],
[68,58,19,6],
[68,58,19,8],
[68,58,19,9],
[68,58,19,10],
[68,58,19,11],
[68,58,19,12],
[68,58,20,0],
[68,58,20,1],
[68,58,20,2],
[68,58,20,3],
[68,58,20,4],
[68,58,20,5],
[68,58,20,6],
[68,58,20,8],
[68,58,20,9],
[68,58,20,11],
[68,58,20,12],
[68,58,20,13],
[68,58,21,0],
[68,58,21,1],
[68,58,21,2],
[68,58,21,4],
[68,58,21,9],
[68,58,21,10],
[68,58,21,11],
[68,58,22,0],
[68,58,22,1],
[68,58,22,2],
[68,58,22,3],
[68,58,22,4],
[68,58,22,5],
[68,58,22,6],
[68,58,22,8],
[68,58,22,9],
[68,58,23,0],
[68,58,23,1],
[68,58,23,2],
[68,58,23,3],
[68,58,23,4],
[68,58,23,5],
[68,58,23,6],
[68,58,26,0],
[68,58,26,1],
[68,58,26,2],
[68,59,3,0],
[68,59,3,1],
[68,59,3,2],
[68,59,4,0],
[68,59,4,1],
[68,59,4,2],
[68,59,4,3],
[68,59,5,0],
[68,59,5,1],
[68,59,5,2],
[68,59,5,4],
[68,59,6,0],
[68,59,6,1],
[68,59,6,2],
[68,59,6,4],
[68,59,8,0],
[68,59,8,1],
[68,59,8,2],
[68,59,10,3],
[68,59,10,4],
[68,59,10,5],
[68,59,10,6],
[68,59,10,8],
[68,59,11,3],
[68,59,11,4],
[68,59,11,5],
[68,59,11,6],
[68,59,11,8],
[68,59,11,10],
[68,59,12,0],
[68,59,12,1],
[68,59,12,2],
[68,59,12,3],
[68,59,12,4],
[68,59,12,5],
[68,59,12,6],
[68,59,12,8],
[68,59,12,10],
[68,59,13,0],
[68,59,13,1],
[68,59,13,2],
[68,59,13,3],
[68,59,13,4],
[68,59,13,5],
[68,59,13,6],
[68,59,13,8],
[68,59,13,10],
[68,59,13,11],
[68,59,13,12],
[68,59,14,0],
[68,59,14,1],
[68,59,14,2],
[68,59,14,3],
[68,59,14,4],
[68,59,14,5],
[68,59,14,6],
[68,59,14,8],
[68,59,14,10],
[68,59,14,11],
[68,59,14,12],
[68,59,16,0],
[68,59,16,1],
[68,59,16,2],
[68,59,16,3],
[68,59,16,4],
[68,59,16,5],
[68,59,16,6],
[68,59,16,8],
[68,59,16,10],
[68,59,16,11],
[68,59,16,12],
[68,59,16,13],
[68,59,16,14],
[68,59,17,0],
[68,59,17,1],
[68,59,17,2],
[68,59,17,3],
[68,59,17,4],
[68,59,17,5],
[68,59,17,6],
[68,59,17,8],
[68,59,17,10],
[68,59,17,11],
[68,59,17,12],
[68,59,17,13],
[68,59,17,14],
[68,59,19,0],
[68,59,19,1],
[68,59,19,2],
[68,59,19,3],
[68,59,19,4],
[68,59,19,5],
[68,59,19,6],
[68,59,19,8],
[68,59,19,10],
[68,59,19,11],
[68,59,19,12],
[68,59,20,0],
[68,59,20,1],
[68,59,20,2],
[68,59,20,3],
[68,59,20,4],
[68,59,20,5],
[68,59,20,6],
[68,59,20,8],
[68,59,20,11],
[68,59,21,0],
[68,59,21,1],
[68,59,21,2],
[68,59,21,4],
[68,59,22,0],
[68,59,22,1],
[68,59,22,2],
[68,59,22,3],
[68,59,22,4],
[68,59,22,5],
[68,59,22,6],
[68,59,23,0],
[68,59,23,1],
[68,59,23,2],
[68,59,23,3],
[68,59,23,4],
[68,59,23,5],
[68,59,24,0],
[68,59,24,1],
[68,59,24,2],
[68,59,24,3],
[68,59,24,4],
[68,59,24,5],
[68,60,3,0],
[68,60,3,1],
[68,60,3,2],
[68,60,4,0],
[68,60,4,1],
[68,60,4,2],
[68,60,4,3],
[68,60,5,0],
[68,60,5,1],
[68,60,5,2],
[68,60,5,4],
[68,60,6,0],
[68,60,6,1],
[68,60,6,2],
[68,60,8,4],
[68,60,9,3],
[68,60,9,4],
[68,60,9,5],
[68,60,9,6],
[68,60,9,8],
[68,60,10,0],
[68,60,10,1],
[68,60,10,2],
[68,60,10,3],
[68,60,10,4],
[68,60,10,5],
[68,60,10,6],
[68,60,10,8],
[68,60,10,9],
[68,60,11,0],
[68,60,11,1],
[68,60,11,2],
[68,60,11,3],
[68,60,11,4],
[68,60,11,5],
[68,60,11,6],
[68,60,11,8],
[68,60,11,9],
[68,60,11,10],
[68,60,12,0],
[68,60,12,1],
[68,60,12,2],
[68,60,12,3],
[68,60,12,4],
[68,60,12,5],
[68,60,12,6],
[68,60,12,8],
[68,60,12,9],
[68,60,12,10],
[68,60,15,0],
[68,60,15,1],
[68,60,15,2],
[68,60,15,3],
[68,60,15,4],
[68,60,15,5],
[68,60,15,6],
[68,60,15,8],
[68,60,15,10],
[68,60,15,11],
[68,60,15,12],
[68,60,16,0],
[68,60,16,1],
[68,60,16,2],
[68,60,16,3],
[68,60,16,4],
[68,60,16,5],
[68,60,16,6],
[68,60,16,8],
[68,60,16,9],
[68,60,16,10],
[68,60,16,11],
[68,60,16,12],
[68,60,17,0],
[68,60,17,1],
[68,60,17,2],
[68,60,17,3],
[68,60,17,4],
[68,60,17,5],
[68,60,17,6],
[68,60,17,8],
[68,60,17,9],
[68,60,17,10],
[68,60,17,11],
[68,60,17,12],
[68,60,18,0],
[68,60,18,1],
[68,60,18,2],
[68,60,18,3],
[68,60,18,4],
[68,60,18,5],
[68,60,18,6],
[68,60,18,8],
[68,60,18,10],
[68,60,18,11],
[68,60,18,12],
[68,60,20,0],
[68,60,20,1],
[68,60,20,2],
[68,60,20,3],
[68,60,20,4],
[68,60,20,5],
[68,60,20,6],
[68,60,20,8],
[68,60,20,9],
[68,60,21,0],
[68,60,21,1],
[68,60,21,2],
[68,60,21,4],
[68,60,22,0],
[68,60,22,1],
[68,60,22,2],
[68,60,22,3],
[68,60,22,4],
[68,60,22,5],
[68,60,23,0],
[68,60,23,1],
[68,60,23,2],
[68,60,23,3],
[68,60,23,4],
[68,60,24,0],
[68,60,24,1],
[68,60,24,2],
[68,60,24,3],
[68,60,24,4],
[68,61,3,0],
[68,61,3,1],
[68,61,3,2],
[68,61,5,0],
[68,61,5,1],
[68,61,5,2],
[68,61,8,0],
[68,61,8,1],
[68,61,8,2],
[68,61,9,0],
[68,61,9,1],
[68,61,9,2],
[68,61,9,3],
[68,61,9,5],
[68,61,9,6],
[68,61,9,8],
[68,61,10,0],
[68,61,10,1],
[68,61,10,2],
[68,61,10,3],
[68,61,10,5],
[68,61,10,6],
[68,61,10,8],
[68,61,10,9],
[68,61,11,0],
[68,61,11,1],
[68,61,11,2],
[68,61,11,3],
[68,61,11,5],
[68,61,11,6],
[68,61,11,8],
[68,61,11,9],
[68,61,11,10],
[68,61,12,0],
[68,61,12,1],
[68,61,12,2],
[68,61,12,3],
[68,61,12,5],
[68,61,12,6],
[68,61,12,8],
[68,61,12,9],
[68,61,12,10],
[68,61,13,0],
[68,61,13,1],
[68,61,13,2],
[68,61,13,3],
[68,61,13,5],
[68,61,13,6],
[68,61,13,8],
[68,61,13,9],
[68,61,13,10],
[68,61,13,11],
[68,61,13,12],
[68,61,14,0],
[68,61,14,1],
[68,61,14,2],
[68,61,14,3],
[68,61,14,5],
[68,61,14,6],
[68,61,14,8],
[68,61,14,9],
[68,61,14,10],
[68,61,14,11],
[68,61,14,12],
[68,61,15,0],
[68,61,15,1],
[68,61,15,2],
[68,61,15,3],
[68,61,15,5],
[68,61,15,6],
[68,61,15,8],
[68,61,15,10],
[68,61,15,11],
[68,61,15,12],
[68,61,15,13],
[68,61,15,14],
[68,61,16,0],
[68,61,16,1],
[68,61,16,2],
[68,61,16,3],
[68,61,16,5],
[68,61,16,6],
[68,61,16,8],
[68,61,16,9],
[68,61,16,10],
[68,61,16,11],
[68,61,16,12],
[68,61,16,13],
[68,61,17,0],
[68,61,17,1],
[68,61,17,2],
[68,61,17,3],
[68,61,17,5],
[68,61,17,6],
[68,61,17,8],
[68,61,17,9],
[68,61,17,10],
[68,61,17,11],
[68,61,17,12],
[68,61,17,13],
[68,61,18,0],
[68,61,18,1],
[68,61,18,2],
[68,61,18,3],
[68,61,18,5],
[68,61,18,6],
[68,61,18,8],
[68,61,18,10],
[68,61,18,11],
[68,61,19,0],
[68,61,19,1],
[68,61,19,2],
[68,61,19,3],
[68,61,19,5],
[68,61,19,6],
[68,61,19,8],
[68,61,19,9],
[68,61,20,0],
[68,61,20,1],
[68,61,20,2],
[68,61,20,3],
[68,61,20,5],
[68,61,20,6],
[68,61,21,0],
[68,61,21,1],
[68,61,21,2],
[68,61,22,0],
[68,61,22,1],
[68,61,22,2],
[68,61,22,3],
[68,61,23,0],
[68,61,23,1],
[68,61,23,2],
[68,61,24,0],
[68,61,24,1],
[68,61,24,2],
[68,63,3,0],
[68,63,3,1],
[68,63,3,2],
[68,63,4,0],
[68,63,4,1],
[68,63,4,2],
[68,63,5,4],
[68,63,6,0],
[68,63,6,1],
[68,63,6,2],
[68,63,6,4],
[68,63,8,0],
[68,63,8,1],
[68,63,8,2],
[68,63,8,4],
[68,63,10,0],
[68,63,10,1],
[68,63,10,2],
[68,63,10,3],
[68,63,10,4],
[68,63,10,5],
[68,63,10,6],
[68,63,10,8],
[68,63,11,0],
[68,63,11,1],
[68,63,11,2],
[68,63,11,3],
[68,63,11,4],
[68,63,11,5],
[68,63,11,6],
[68,63,11,8],
[68,63,11,10],
[68,63,12,0],
[68,63,12,1],
[68,63,12,2],
[68,63,12,3],
[68,63,12,4],
[68,63,12,5],
[68,63,12,6],
[68,63,12,8],
[68,63,12,10],
[68,63,13,0],
[68,63,13,1],
[68,63,13,2],
[68,63,13,3],
[68,63,13,4],
[68,63,13,5],
[68,63,13,6],
[68,63,13,8],
[68,63,13,10],
[68,63,13,11],
[68,63,13,12],
[68,63,14,0],
[68,63,14,1],
[68,63,14,2],
[68,63,14,3],
[68,63,14,4],
[68,63,14,5],
[68,63,14,6],
[68,63,14,8],
[68,63,14,10],
[68,63,14,11],
[68,63,14,12],
[68,63,16,0],
[68,63,16,1],
[68,63,16,2],
[68,63,16,3],
[68,63,16,4],
[68,63,16,5],
[68,63,16,6],
[68,63,16,8],
[68,63,16,10],
[68,63,16,11],
[68,63,17,0],
[68,63,17,1],
[68,63,17,2],
[68,63,17,3],
[68,63,17,4],
[68,63,17,5],
[68,63,17,6],
[68,63,17,8],
[68,63,17,10],
[68,63,17,11],
[68,63,19,0],
[68,63,19,1],
[68,63,19,2],
[68,63,19,3],
[68,63,19,4],
[68,63,19,5],
[68,63,19,6],
[68,63,20,0],
[68,63,20,1],
[68,63,20,2],
[68,63,20,3],
[68,63,20,4],
[68,63,20,5],
[68,63,21,0],
[68,63,21,1],
[68,63,21,2],
[68,63,21,4],
[68,63,22,0],
[68,63,22,1],
[68,63,22,2],
[68,64,5,0],
[68,64,5,1],
[68,64,5,2],
[68,64,6,0],
[68,64,6,1],
[68,64,6,2],
[68,64,8,0],
[68,64,8,1],
[68,64,8,2],
[68,64,9,0],
[68,64,9,1],
[68,64,9,2],
[68,64,9,3],
[68,64,9,5],
[68,64,9,6],
[68,64,9,8],
[68,64,10,0],
[68,64,10,1],
[68,64,10,2],
[68,64,10,3],
[68,64,10,5],
[68,64,10,6],
[68,64,10,8],
[68,64,10,9],
[68,64,11,0],
[68,64,11,1],
[68,64,11,2],
[68,64,11,3],
[68,64,11,5],
[68,64,11,6],
[68,64,11,8],
[68,64,11,9],
[68,64,11,10],
[68,64,12,0],
[68,64,12,1],
[68,64,12,2],
[68,64,12,3],
[68,64,12,5],
[68,64,12,6],
[68,64,12,8],
[68,64,12,9],
[68,64,12,10],
[68,64,13,0],
[68,64,13,1],
[68,64,13,2],
[68,64,13,3],
[68,64,13,5],
[68,64,13,6],
[68,64,13,8],
[68,64,13,9],
[68,64,13,10],
[68,64,13,11],
[68,64,13,12],
[68,64,14,0],
[68,64,14,1],
[68,64,14,2],
[68,64,14,3],
[68,64,14,5],
[68,64,14,6],
[68,64,14,8],
[68,64,14,9],
[68,64,14,10],
[68,64,14,11],
[68,64,14,12],
[68,64,15,0],
[68,64,15,1],
[68,64,15,2],
[68,64,15,3],
[68,64,15,5],
[68,64,15,6],
[68,64,15,8],
[68,64,15,10],
[68,64,15,11],
[68,64,16,0],
[68,64,16,1],
[68,64,16,2],
[68,64,16,3],
[68,64,16,5],
[68,64,16,6],
[68,64,16,8],
[68,64,16,9],
[68,64,17,0],
[68,64,17,1],
[68,64,17,2],
[68,64,17,3],
[68,64,17,5],
[68,64,17,6],
[68,64,17,8],
[68,64,17,9],
[68,64,18,0],
[68,64,18,1],
[68,64,18,2],
[68,64,18,3],
[68,64,18,5],
[68,64,18,6],
[68,64,19,0],
[68,64,19,1],
[68,64,19,2],
[68,64,19,3],
[68,64,19,5],
[68,64,20,0],
[68,64,20,1],
[68,64,20,2],
[68,64,20,3],
[68,64,21,0],
[68,64,21,1],
[68,64,21,2],
[68,65,3,0],
[68,65,3,1],
[68,65,3,2],
[68,65,4,0],
[68,65,4,1],
[68,65,4,2],
[68,65,4,3],
[68,65,5,0],
[68,65,5,1],
[68,65,5,2],
[68,65,5,4],
[68,65,6,0],
[68,65,6,1],
[68,65,6,2],
[68,65,6,4],
[68,65,8,0],
[68,65,8,1],
[68,65,8,2],
[68,65,8,4],
[68,65,9,0],
[68,65,9,1],
[68,65,9,2],
[68,65,9,3],
[68,65,9,4],
[68,65,9,5],
[68,65,9,6],
[68,65,9,8],
[68,65,10,0],
[68,65,10,1],
[68,65,10,2],
[68,65,10,3],
[68,65,10,4],
[68,65,10,5],
[68,65,10,6],
[68,65,10,8],
[68,65,10,9],
[68,65,11,0],
[68,65,11,1],
[68,65,11,2],
[68,65,11,3],
[68,65,11,4],
[68,65,11,5],
[68,65,11,6],
[68,65,11,8],
[68,65,11,9],
[68,65,11,10],
[68,65,12,0],
[68,65,12,1],
[68,65,12,2],
[68,65,12,3],
[68,65,12,4],
[68,65,12,5],
[68,65,12,6],
[68,65,12,8],
[68,65,12,9],
[68,65,12,10],
[68,65,13,0],
[68,65,13,1],
[68,65,13,2],
[68,65,13,3],
[68,65,13,4],
[68,65,13,5],
[68,65,13,6],
[68,65,13,8],
[68,65,13,9],
[68,65,13,10],
[68,65,13,11],
[68,65,13,12],
[68,65,14,0],
[68,65,14,1],
[68,65,14,2],
[68,65,14,3],
[68,65,14,4],
[68,65,14,5],
[68,65,14,6],
[68,65,14,8],
[68,65,14,9],
[68,65,14,10],
[68,65,14,11],
[68,65,15,0],
[68,65,15,1],
[68,65,15,2],
[68,65,15,3],
[68,65,15,4],
[68,65,15,5],
[68,65,15,6],
[68,65,15,8],
[68,65,16,0],
[68,65,16,1],
[68,65,16,2],
[68,65,16,3],
[68,65,16,4],
[68,65,16,5],
[68,65,16,6],
[68,65,17,0],
[68,65,17,1],
[68,65,17,2],
[68,65,17,3],
[68,65,17,4],
[68,65,17,5],
[68,65,17,6],
[68,65,18,0],
[68,65,18,1],
[68,65,18,2],
[68,65,18,3],
[68,65,18,4],
[68,65,18,5],
[68,65,19,0],
[68,65,19,1],
[68,65,19,2],
[68,65,19,3],
[68,65,19,4],
[68,65,20,0],
[68,65,20,1],
[68,65,20,2],
[69,4,3,0],
[69,4,3,1],
[69,4,3,2],
[69,5,4,0],
[69,5,4,1],
[69,5,4,2],
[69,6,4,0],
[69,6,4,1],
[69,6,4,2],
[69,7,3,0],
[69,7,3,1],
[69,7,3,2],
[69,7,4,0],
[69,7,4,1],
[69,7,4,2],
[69,7,4,3],
[69,7,5,0],
[69,7,5,1],
[69,7,5,2],
[69,7,5,4],
[69,7,6,0],
[69,7,6,1],
[69,7,6,2],
[69,7,6,4],
[69,8,4,0],
[69,8,4,1],
[69,8,4,2],
[69,8,7,0],
[69,8,7,1],
[69,8,7,2],
[69,8,7,4],
[69,10,3,0],
[69,10,3,1],
[69,10,3,2],
[69,10,4,0],
[69,10,4,1],
[69,10,4,2],
[69,10,4,3],
[69,10,5,0],
[69,10,5,1],
[69,10,5,2],
[69,10,5,4],
[69,10,6,0],
[69,10,6,1],
[69,10,6,2],
[69,10,6,4],
[69,10,7,0],
[69,10,7,1],
[69,10,7,2],
[69,10,7,3],
[69,10,7,4],
[69,10,7,5],
[69,10,7,6],
[69,10,8,0],
[69,10,8,1],
[69,10,8,2],
[69,10,8,4],
[69,10,8,7],
[69,11,3,0],
[69,11,3,1],
[69,11,3,2],
[69,11,4,0],
[69,11,4,1],
[69,11,4,2],
[69,11,4,3],
[69,11,5,0],
[69,11,5,1],
[69,11,5,2],
[69,11,5,4],
[69,11,6,0],
[69,11,6,1],
[69,11,6,2],
[69,11,6,4],
[69,11,7,0],
[69,11,7,1],
[69,11,7,2],
[69,11,7,3],
[69,11,7,4],
[69,11,7,5],
[69,11,7,6],
[69,11,8,0],
[69,11,8,1],
[69,11,8,2],
[69,11,8,4],
[69,11,8,7],
[69,11,10,0],
[69,11,10,1],
[69,11,10,2],
[69,11,10,3],
[69,11,10,4],
[69,11,10,5],
[69,11,10,6],
[69,11,10,7],
[69,11,10,8],
[69,12,3,0],
[69,12,3,1],
[69,12,3,2],
[69,12,4,0],
[69,12,4,1],
[69,12,4,2],
[69,12,4,3],
[69,12,5,0],
[69,12,5,1],
[69,12,5,2],
[69,12,5,4],
[69,12,6,0],
[69,12,6,1],
[69,12,6,2],
[69,12,6,4],
[69,12,7,0],
[69,12,7,1],
[69,12,7,2],
[69,12,7,3],
[69,12,7,4],
[69,12,7,5],
[69,12,7,6],
[69,12,8,0],
[69,12,8,1],
[69,12,8,2],
[69,12,8,4],
[69,12,8,7],
[69,12,10,0],
[69,12,10,1],
[69,12,10,2],
[69,12,10,3],
[69,12,10,4],
[69,12,10,5],
[69,12,10,6],
[69,12,10,7],
[69,12,10,8],
[69,13,3,0],
[69,13,3,1],
[69,13,3,2],
[69,13,4,0],
[69,13,4,1],
[69,13,4,2],
[69,13,4,3],
[69,13,5,0],
[69,13,5,1],
[69,13,5,2],
[69,13,5,4],
[69,13,6,0],
[69,13,6,1],
[69,13,6,2],
[69,13,6,4],
[69,13,7,0],
[69,13,7,1],
[69,13,7,2],
[69,13,7,3],
[69,13,7,4],
[69,13,7,5],
[69,13,7,6],
[69,13,8,0],
[69,13,8,1],
[69,13,8,2],
[69,13,8,4],
[69,13,8,7],
[69,13,10,0],
[69,13,10,1],
[69,13,10,2],
[69,13,10,3],
[69,13,10,4],
[69,13,10,5],
[69,13,10,6],
[69,13,10,7],
[69,13,10,8],
[69,13,11,0],
[69,13,11,1],
[69,13,11,2],
[69,13,11,3],
[69,13,11,4],
[69,13,11,5],
[69,13,11,6],
[69,13,11,7],
[69,13,11,8],
[69,13,11,10],
[69,13,12,0],
[69,13,12,1],
[69,13,12,2],
[69,13,12,3],
[69,13,12,4],
[69,13,12,5],
[69,13,12,6],
[69,13,12,7],
[69,13,12,8],
[69,13,12,10],
[69,14,3,0],
[69,14,3,1],
[69,14,3,2],
[69,14,4,0],
[69,14,4,1],
[69,14,4,2],
[69,14,4,3],
[69,14,5,0],
[69,14,5,1],
[69,14,5,2],
[69,14,5,4],
[69,14,6,0],
[69,14,6,1],
[69,14,6,2],
[69,14,6,4],
[69,14,7,0],
[69,14,7,1],
[69,14,7,2],
[69,14,7,3],
[69,14,7,4],
[69,14,7,5],
[69,14,7,6],
[69,14,8,0],
[69,14,8,1],
[69,14,8,2],
[69,14,8,4],
[69,14,8,7],
[69,14,10,0],
[69,14,10,1],
[69,14,10,2],
[69,14,10,3],
[69,14,10,4],
[69,14,10,5],
[69,14,10,6],
[69,14,10,7],
[69,14,10,8],
[69,14,11,0],
[69,14,11,1],
[69,14,11,2],
[69,14,11,3],
[69,14,11,4],
[69,14,11,5],
[69,14,11,6],
[69,14,11,7],
[69,14,11,8],
[69,14,11,10],
[69,14,12,0],
[69,14,12,1],
[69,14,12,2],
[69,14,12,3],
[69,14,12,4],
[69,14,12,5],
[69,14,12,6],
[69,14,12,7],
[69,14,12,8],
[69,14,12,10],
[69,16,3,0],
[69,16,3,1],
[69,16,3,2],
[69,16,4,0],
[69,16,4,1],
[69,16,4,2],
[69,16,4,3],
[69,16,5,0],
[69,16,5,1],
[69,16,5,2],
[69,16,5,4],
[69,16,6,0],
[69,16,6,1],
[69,16,6,2],
[69,16,6,4],
[69,16,7,0],
[69,16,7,1],
[69,16,7,2],
[69,16,7,3],
[69,16,7,4],
[69,16,7,5],
[69,16,7,6],
[69,16,8,0],
[69,16,8,1],
[69,16,8,2],
[69,16,8,4],
[69,16,8,7],
[69,16,10,0],
[69,16,10,1],
[69,16,10,2],
[69,16,10,3],
[69,16,10,4],
[69,16,10,5],
[69,16,10,6],
[69,16,10,7],
[69,16,10,8],
[69,16,11,0],
[69,16,11,1],
[69,16,11,2],
[69,16,11,3],
[69,16,11,4],
[69,16,11,5],
[69,16,11,6],
[69,16,11,7],
[69,16,11,8],
[69,16,11,10],
[69,16,12,0],
[69,16,12,1],
[69,16,12,2],
[69,16,12,3],
[69,16,12,4],
[69,16,12,5],
[69,16,12,6],
[69,16,12,7],
[69,16,12,8],
[69,16,12,10],
[69,16,13,0],
[69,16,13,1],
[69,16,13,2],
[69,16,13,3],
[69,16,13,4],
[69,16,13,5],
[69,16,13,6],
[69,16,13,7],
[69,16,13,8],
[69,16,13,10],
[69,16,13,11],
[69,16,13,12],
[69,16,14,0],
[69,16,14,1],
[69,16,14,2],
[69,16,14,3],
[69,16,14,4],
[69,16,14,5],
[69,16,14,6],
[69,16,14,7],
[69,16,14,8],
[69,16,14,10],
[69,16,14,11],
[69,16,14,12],
[69,17,3,0],
[69,17,3,1],
[69,17,3,2],
[69,17,4,0],
[69,17,4,1],
[69,17,4,2],
[69,17,4,3],
[69,17,5,0],
[69,17,5,1],
[69,17,5,2],
[69,17,5,4],
[69,17,6,0],
[69,17,6,1],
[69,17,6,2],
[69,17,6,4],
[69,17,7,0],
[69,17,7,1],
[69,17,7,2],
[69,17,7,3],
[69,17,7,4],
[69,17,7,5],
[69,17,7,6],
[69,17,8,0],
[69,17,8,1],
[69,17,8,2],
[69,17,8,4],
[69,17,8,7],
[69,17,10,0],
[69,17,10,1],
[69,17,10,2],
[69,17,10,3],
[69,17,10,4],
[69,17,10,5],
[69,17,10,6],
[69,17,10,7],
[69,17,10,8],
[69,17,11,0],
[69,17,11,1],
[69,17,11,2],
[69,17,11,3],
[69,17,11,4],
[69,17,11,5],
[69,17,11,6],
[69,17,11,7],
[69,17,11,8],
[69,17,11,10],
[69,17,12,0],
[69,17,12,1],
[69,17,12,2],
[69,17,12,3],
[69,17,12,4],
[69,17,12,5],
[69,17,12,6],
[69,17,12,7],
[69,17,12,8],
[69,17,12,10],
[69,17,13,0],
[69,17,13,1],
[69,17,13,2],
[69,17,13,3],
[69,17,13,4],
[69,17,13,5],
[69,17,13,6],
[69,17,13,7],
[69,17,13,8],
[69,17,13,10],
[69,17,13,11],
[69,17,13,12],
[69,17,14,0],
[69,17,14,1],
[69,17,14,2],
[69,17,14,3],
[69,17,14,4],
[69,17,14,5],
[69,17,14,6],
[69,17,14,7],
[69,17,14,8],
[69,17,14,10],
[69,17,14,11],
[69,17,14,12],
[69,17,16,0],
[69,17,16,1],
[69,17,16,2],
[69,17,16,3],
[69,17,16,4],
[69,17,16,5],
[69,17,16,6],
[69,17,16,7],
[69,17,16,8],
[69,17,16,10],
[69,17,16,11],
[69,17,16,12],
[69,17,16,13],
[69,17,16,14],
[69,19,3,0],
[69,19,3,1],
[69,19,3,2],
[69,19,4,0],
[69,19,4,1],
[69,19,4,2],
[69,19,4,3],
[69,19,5,0],
[69,19,5,1],
[69,19,5,2],
[69,19,5,4],
[69,19,6,0],
[69,19,6,1],
[69,19,6,2],
[69,19,6,4],
[69,19,7,0],
[69,19,7,1],
[69,19,7,2],
[69,19,7,3],
[69,19,7,4],
[69,19,7,5],
[69,19,7,6],
[69,19,8,0],
[69,19,8,1],
[69,19,8,2],
[69,19,8,4],
[69,19,8,7],
[69,19,10,0],
[69,19,10,1],
[69,19,10,2],
[69,19,10,3],
[69,19,10,4],
[69,19,10,5],
[69,19,10,6],
[69,19,10,7],
[69,19,10,8],
[69,19,11,0],
[69,19,11,1],
[69,19,11,2],
[69,19,11,3],
[69,19,11,4],
[69,19,11,5],
[69,19,11,6],
[69,19,11,7],
[69,19,11,8],
[69,19,11,10],
[69,19,12,0],
[69,19,12,1],
[69,19,12,2],
[69,19,12,3],
[69,19,12,4],
[69,19,12,5],
[69,19,12,6],
[69,19,12,7],
[69,19,12,8],
[69,19,12,10],
[69,19,16,0],
[69,19,16,1],
[69,19,16,2],
[69,19,16,3],
[69,19,16,4],
[69,19,16,5],
[69,19,16,6],
[69,19,16,7],
[69,19,16,8],
[69,19,16,10],
[69,19,16,11],
[69,19,16,12],
[69,19,17,0],
[69,19,17,1],
[69,19,17,2],
[69,19,17,3],
[69,19,17,4],
[69,19,17,5],
[69,19,17,6],
[69,19,17,7],
[69,19,17,8],
[69,19,17,10],
[69,19,17,11],
[69,19,17,12],
[69,19,17,16],
[69,20,3,0],
[69,20,3,1],
[69,20,3,2],
[69,20,4,0],
[69,20,4,1],
[69,20,4,2],
[69,20,4,3],
[69,20,5,0],
[69,20,5,1],
[69,20,5,2],
[69,20,5,4],
[69,20,6,0],
[69,20,6,1],
[69,20,6,2],
[69,20,6,4],
[69,20,7,0],
[69,20,7,1],
[69,20,7,2],
[69,20,7,3],
[69,20,7,4],
[69,20,7,5],
[69,20,7,6],
[69,20,8,0],
[69,20,8,1],
[69,20,8,2],
[69,20,8,4],
[69,20,8,7],
[69,20,11,0],
[69,20,11,1],
[69,20,11,2],
[69,20,11,3],
[69,20,11,4],
[69,20,11,5],
[69,20,11,6],
[69,20,11,7],
[69,20,11,8],
[69,20,12,0],
[69,20,12,1],
[69,20,12,2],
[69,20,12,3],
[69,20,12,4],
[69,20,12,5],
[69,20,12,6],
[69,20,12,7],
[69,20,12,8],
[69,20,13,0],
[69,20,13,1],
[69,20,13,2],
[69,20,13,3],
[69,20,13,4],
[69,20,13,5],
[69,20,13,6],
[69,20,13,7],
[69,20,13,8],
[69,20,13,11],
[69,20,13,12],
[69,20,14,0],
[69,20,14,1],
[69,20,14,2],
[69,20,14,3],
[69,20,14,4],
[69,20,14,5],
[69,20,14,6],
[69,20,14,7],
[69,20,14,8],
[69,20,14,11],
[69,20,14,12],
[69,20,16,0],
[69,20,16,1],
[69,20,16,2],
[69,20,16,3],
[69,20,16,4],
[69,20,16,5],
[69,20,16,6],
[69,20,16,7],
[69,20,16,8],
[69,20,16,11],
[69,20,16,12],
[69,20,16,13],
[69,20,16,14],
[69,20,17,0],
[69,20,17,1],
[69,20,17,2],
[69,20,17,3],
[69,20,17,4],
[69,20,17,5],
[69,20,17,6],
[69,20,17,7],
[69,20,17,8],
[69,20,17,11],
[69,20,17,12],
[69,20,17,13],
[69,20,17,14],
[69,20,17,16],
[69,20,19,0],
[69,20,19,1],
[69,20,19,2],
[69,20,19,3],
[69,20,19,4],
[69,20,19,5],
[69,20,19,6],
[69,20,19,7],
[69,20,19,8],
[69,20,19,11],
[69,20,19,12],
[69,20,19,16],
[69,20,19,17],
[69,21,4,0],
[69,21,4,1],
[69,21,4,2],
[69,21,7,0],
[69,21,7,1],
[69,21,7,2],
[69,21,7,4],
[69,21,10,0],
[69,21,10,1],
[69,21,10,2],
[69,21,10,4],
[69,21,10,7],
[69,21,11,0],
[69,21,11,1],
[69,21,11,2],
[69,21,11,4],
[69,21,11,7],
[69,21,11,10],
[69,21,12,0],
[69,21,12,1],
[69,21,12,2],
[69,21,12,4],
[69,21,12,7],
[69,21,12,10],
[69,21,13,0],
[69,21,13,1],
[69,21,13,2],
[69,21,13,4],
[69,21,13,7],
[69,21,13,10],
[69,21,13,11],
[69,21,13,12],
[69,21,14,0],
[69,21,14,1],
[69,21,14,2],
[69,21,14,4],
[69,21,14,7],
[69,21,14,10],
[69,21,14,11],
[69,21,14,12],
[69,21,16,0],
[69,21,16,1],
[69,21,16,2],
[69,21,16,4],
[69,21,16,7],
[69,21,16,10],
[69,21,16,11],
[69,21,16,12],
[69,21,16,13],
[69,21,16,14],
[69,21,17,0],
[69,21,17,1],
[69,21,17,2],
[69,21,17,4],
[69,21,17,7],
[69,21,17,10],
[69,21,17,11],
[69,21,17,12],
[69,21,17,13],
[69,21,17,14],
[69,21,17,16],
[69,21,19,0],
[69,21,19,1],
[69,21,19,2],
[69,21,19,4],
[69,21,19,7],
[69,21,19,10],
[69,21,19,11],
[69,21,19,12],
[69,21,19,16],
[69,21,19,17],
[69,21,20,0],
[69,21,20,1],
[69,21,20,2],
[69,21,20,4],
[69,21,20,7],
[69,21,20,11],
[69,21,20,12],
[69,21,20,13],
[69,21,20,14],
[69,21,20,16],
[69,21,20,17],
[69,21,20,19],
[69,22,3,0],
[69,22,3,1],
[69,22,3,2],
[69,22,4,0],
[69,22,4,1],
[69,22,4,2],
[69,22,4,3],
[69,22,5,0],
[69,22,5,1],
[69,22,5,2],
[69,22,5,4],
[69,22,6,0],
[69,22,6,1],
[69,22,6,2],
[69,22,6,4],
[69,22,7,0],
[69,22,7,1],
[69,22,7,2],
[69,22,7,3],
[69,22,7,4],
[69,22,7,5],
[69,22,7,6],
[69,22,8,0],
[69,22,8,1],
[69,22,8,2],
[69,22,8,4],
[69,22,8,7],
[69,22,11,0],
[69,22,11,1],
[69,22,11,2],
[69,22,11,3],
[69,22,11,4],
[69,22,11,5],
[69,22,11,6],
[69,22,11,7],
[69,22,11,8],
[69,22,12,0],
[69,22,12,1],
[69,22,12,2],
[69,22,12,3],
[69,22,12,4],
[69,22,12,5],
[69,22,12,6],
[69,22,12,7],
[69,22,12,8],
[69,22,13,0],
[69,22,13,1],
[69,22,13,2],
[69,22,13,3],
[69,22,13,4],
[69,22,13,5],
[69,22,13,6],
[69,22,13,7],
[69,22,13,8],
[69,22,13,11],
[69,22,13,12],
[69,22,14,0],
[69,22,14,1],
[69,22,14,2],
[69,22,14,3],
[69,22,14,4],
[69,22,14,5],
[69,22,14,6],
[69,22,14,7],
[69,22,14,8],
[69,22,14,11],
[69,22,14,12],
[69,22,16,0],
[69,22,16,1],
[69,22,16,2],
[69,22,16,3],
[69,22,16,4],
[69,22,16,5],
[69,22,16,6],
[69,22,16,7],
[69,22,16,8],
[69,22,16,11],
[69,22,16,12],
[69,22,16,13],
[69,22,16,14],
[69,22,17,0],
[69,22,17,1],
[69,22,17,2],
[69,22,17,3],
[69,22,17,4],
[69,22,17,5],
[69,22,17,6],
[69,22,17,7],
[69,22,17,8],
[69,22,17,11],
[69,22,17,12],
[69,22,17,13],
[69,22,17,14],
[69,22,17,16],
[69,22,19,0],
[69,22,19,1],
[69,22,19,2],
[69,22,19,3],
[69,22,19,4],
[69,22,19,5],
[69,22,19,6],
[69,22,19,7],
[69,22,19,8],
[69,22,19,11],
[69,22,19,12],
[69,22,19,16],
[69,22,19,17],
[69,22,21,0],
[69,22,21,1],
[69,22,21,2],
[69,22,21,4],
[69,22,21,7],
[69,22,21,11],
[69,22,21,12],
[69,22,21,13],
[69,22,21,14],
[69,22,21,16],
[69,22,21,17],
[69,22,21,19],
[69,23,3,0],
[69,23,3,1],
[69,23,3,2],
[69,23,4,0],
[69,23,4,1],
[69,23,4,2],
[69,23,4,3],
[69,23,5,0],
[69,23,5,1],
[69,23,5,2],
[69,23,5,4],
[69,23,6,0],
[69,23,6,1],
[69,23,6,2],
[69,23,6,4],
[69,23,7,0],
[69,23,7,1],
[69,23,7,2],
[69,23,7,3],
[69,23,7,4],
[69,23,7,5],
[69,23,7,6],
[69,23,8,0],
[69,23,8,1],
[69,23,8,2],
[69,23,8,4],
[69,23,8,7],
[69,23,10,0],
[69,23,10,1],
[69,23,10,2],
[69,23,10,3],
[69,23,10,4],
[69,23,10,5],
[69,23,10,6],
[69,23,10,7],
[69,23,10,8],
[69,23,11,0],
[69,23,11,1],
[69,23,11,2],
[69,23,11,3],
[69,23,11,4],
[69,23,11,5],
[69,23,11,6],
[69,23,11,7],
[69,23,11,8],
[69,23,11,10],
[69,23,12,0],
[69,23,12,1],
[69,23,12,2],
[69,23,12,3],
[69,23,12,4],
[69,23,12,5],
[69,23,12,6],
[69,23,12,7],
[69,23,12,8],
[69,23,12,10],
[69,23,13,0],
[69,23,13,1],
[69,23,13,2],
[69,23,13,3],
[69,23,13,4],
[69,23,13,5],
[69,23,13,6],
[69,23,13,7],
[69,23,13,8],
[69,23,13,10],
[69,23,13,11],
[69,23,13,12],
[69,23,14,0],
[69,23,14,1],
[69,23,14,2],
[69,23,14,3],
[69,23,14,4],
[69,23,14,5],
[69,23,14,6],
[69,23,14,7],
[69,23,14,8],
[69,23,14,10],
[69,23,14,11],
[69,23,14,12],
[69,23,16,0],
[69,23,16,1],
[69,23,16,2],
[69,23,16,3],
[69,23,16,4],
[69,23,16,5],
[69,23,16,6],
[69,23,16,7],
[69,23,16,8],
[69,23,16,10],
[69,23,16,11],
[69,23,16,12],
[69,23,16,13],
[69,23,16,14],
[69,23,17,0],
[69,23,17,1],
[69,23,17,2],
[69,23,17,3],
[69,23,17,4],
[69,23,17,5],
[69,23,17,6],
[69,23,17,7],
[69,23,17,8],
[69,23,17,10],
[69,23,17,11],
[69,23,17,12],
[69,23,17,13],
[69,23,17,14],
[69,23,17,16],
[69,23,19,0],
[69,23,19,1],
[69,23,19,2],
[69,23,19,3],
[69,23,19,4],
[69,23,19,5],
[69,23,19,6],
[69,23,19,7],
[69,23,19,8],
[69,23,19,10],
[69,23,19,11],
[69,23,19,12],
[69,23,19,16],
[69,23,19,17],
[69,23,20,0],
[69,23,20,1],
[69,23,20,2],
[69,23,20,3],
[69,23,20,4],
[69,23,20,5],
[69,23,20,6],
[69,23,20,7],
[69,23,20,8],
[69,23,20,11],
[69,23,20,12],
[69,23,20,13],
[69,23,20,14],
[69,23,20,16],
[69,23,20,17],
[69,23,20,19],
[69,23,21,0],
[69,23,21,1],
[69,23,21,2],
[69,23,21,4],
[69,23,21,7],
[69,23,21,10],
[69,23,21,11],
[69,23,21,12],
[69,23,21,13],
[69,23,21,14],
[69,23,21,16],
[69,23,21,17],
[69,23,21,19],
[69,23,21,20],
[69,23,22,0],
[69,23,22,1],
[69,23,22,2],
[69,23,22,3],
[69,23,22,4],
[69,23,22,5],
[69,23,22,6],
[69,23,22,7],
[69,23,22,8],
[69,23,22,11],
[69,23,22,12],
[69,23,22,13],
[69,23,22,14],
[69,23,22,16],
[69,23,22,17],
[69,23,22,19],
[69,23,22,21],
[69,24,3,0],
[69,24,3,1],
[69,24,3,2],
[69,24,4,0],
[69,24,4,1],
[69,24,4,2],
[69,24,4,3],
[69,24,5,0],
[69,24,5,1],
[69,24,5,2],
[69,24,5,4],
[69,24,6,0],
[69,24,6,1],
[69,24,6,2],
[69,24,6,4],
[69,24,7,0],
[69,24,7,1],
[69,24,7,2],
[69,24,7,3],
[69,24,7,4],
[69,24,7,5],
[69,24,7,6],
[69,24,8,0],
[69,24,8,1],
[69,24,8,2],
[69,24,8,4],
[69,24,8,7],
[69,24,10,0],
[69,24,10,1],
[69,24,10,2],
[69,24,10,3],
[69,24,10,4],
[69,24,10,5],
[69,24,10,6],
[69,24,10,7],
[69,24,10,8],
[69,24,11,0],
[69,24,11,1],
[69,24,11,2],
[69,24,11,3],
[69,24,11,4],
[69,24,11,5],
[69,24,11,6],
[69,24,11,7],
[69,24,11,8],
[69,24,11,10],
[69,24,12,0],
[69,24,12,1],
[69,24,12,2],
[69,24,12,3],
[69,24,12,4],
[69,24,12,5],
[69,24,12,6],
[69,24,12,7],
[69,24,12,8],
[69,24,12,10],
[69,24,13,0],
[69,24,13,1],
[69,24,13,2],
[69,24,13,3],
[69,24,13,4],
[69,24,13,5],
[69,24,13,6],
[69,24,13,7],
[69,24,13,8],
[69,24,13,10],
[69,24,13,11],
[69,24,13,12],
[69,24,14,0],
[69,24,14,1],
[69,24,14,2],
[69,24,14,3],
[69,24,14,4],
[69,24,14,5],
[69,24,14,6],
[69,24,14,7],
[69,24,14,8],
[69,24,14,10],
[69,24,14,11],
[69,24,14,12],
[69,24,17,0],
[69,24,17,1],
[69,24,17,2],
[69,24,17,3],
[69,24,17,4],
[69,24,17,5],
[69,24,17,6],
[69,24,17,7],
[69,24,17,8],
[69,24,17,10],
[69,24,17,11],
[69,24,17,12],
[69,24,17,13],
[69,24,17,14],
[69,24,19,0],
[69,24,19,1],
[69,24,19,2],
[69,24,19,3],
[69,24,19,4],
[69,24,19,5],
[69,24,19,6],
[69,24,19,7],
[69,24,19,8],
[69,24,19,10],
[69,24,19,11],
[69,24,19,12],
[69,24,19,17],
[69,24,20,0],
[69,24,20,1],
[69,24,20,2],
[69,24,20,3],
[69,24,20,4],
[69,24,20,5],
[69,24,20,6],
[69,24,20,7],
[69,24,20,8],
[69,24,20,11],
[69,24,20,12],
[69,24,20,13],
[69,24,20,14],
[69,24,20,17],
[69,24,20,19],
[69,24,21,0],
[69,24,21,1],
[69,24,21,2],
[69,24,21,4],
[69,24,21,7],
[69,24,21,10],
[69,24,21,11],
[69,24,21,12],
[69,24,21,13],
[69,24,21,14],
[69,24,21,17],
[69,24,21,19],
[69,24,21,20],
[69,24,22,0],
[69,24,22,1],
[69,24,22,2],
[69,24,22,3],
[69,24,22,4],
[69,24,22,5],
[69,24,22,6],
[69,24,22,7],
[69,24,22,8],
[69,24,22,11],
[69,24,22,12],
[69,24,22,13],
[69,24,22,14],
[69,24,22,17],
[69,24,22,19],
[69,24,22,21],
[69,24,23,0],
[69,24,23,1],
[69,24,23,2],
[69,24,23,3],
[69,24,23,4],
[69,24,23,5],
[69,24,23,6],
[69,24,23,7],
[69,24,23,8],
[69,24,23,10],
[69,24,23,11],
[69,24,23,12],
[69,24,23,13],
[69,24,23,14],
[69,24,23,17],
[69,24,23,19],
[69,24,23,20],
[69,24,23,21],
[69,24,23,22],
[69,25,3,0],
[69,25,3,1],
[69,25,3,2],
[69,25,4,0],
[69,25,4,1],
[69,25,4,2],
[69,25,4,3],
[69,25,5,0],
[69,25,5,1],
[69,25,5,2],
[69,25,5,4],
[69,25,6,0],
[69,25,6,1],
[69,25,6,2],
[69,25,6,4],
[69,25,8,0],
[69,25,8,1],
[69,25,8,2],
[69,25,8,4],
[69,25,10,0],
[69,25,10,1],
[69,25,10,2],
[69,25,10,3],
[69,25,10,4],
[69,25,10,5],
[69,25,10,6],
[69,25,10,8],
[69,25,11,0],
[69,25,11,1],
[69,25,11,2],
[69,25,11,3],
[69,25,11,4],
[69,25,11,5],
[69,25,11,6],
[69,25,11,8],
[69,25,11,10],
[69,25,12,0],
[69,25,12,1],
[69,25,12,2],
[69,25,12,3],
[69,25,12,4],
[69,25,12,5],
[69,25,12,6],
[69,25,12,8],
[69,25,12,10],
[69,25,13,0],
[69,25,13,1],
[69,25,13,2],
[69,25,13,3],
[69,25,13,4],
[69,25,13,5],
[69,25,13,6],
[69,25,13,8],
[69,25,13,10],
[69,25,13,11],
[69,25,13,12],
[69,25,14,0],
[69,25,14,1],
[69,25,14,2],
[69,25,14,3],
[69,25,14,4],
[69,25,14,5],
[69,25,14,6],
[69,25,14,8],
[69,25,14,10],
[69,25,14,11],
[69,25,14,12],
[69,25,16,0],
[69,25,16,1],
[69,25,16,2],
[69,25,16,3],
[69,25,16,4],
[69,25,16,5],
[69,25,16,6],
[69,25,16,8],
[69,25,16,10],
[69,25,16,11],
[69,25,16,12],
[69,25,16,13],
[69,25,16,14],
[69,25,17,0],
[69,25,17,1],
[69,25,17,2],
[69,25,17,3],
[69,25,17,4],
[69,25,17,5],
[69,25,17,6],
[69,25,17,8],
[69,25,17,10],
[69,25,17,11],
[69,25,17,12],
[69,25,17,13],
[69,25,17,14],
[69,25,17,16],
[69,25,19,0],
[69,25,19,1],
[69,25,19,2],
[69,25,19,3],
[69,25,19,4],
[69,25,19,5],
[69,25,19,6],
[69,25,19,8],
[69,25,19,10],
[69,25,19,11],
[69,25,19,12],
[69,25,19,16],
[69,25,19,17],
[69,25,20,0],
[69,25,20,1],
[69,25,20,2],
[69,25,20,3],
[69,25,20,4],
[69,25,20,5],
[69,25,20,6],
[69,25,20,8],
[69,25,20,11],
[69,25,20,12],
[69,25,20,13],
[69,25,20,14],
[69,25,20,16],
[69,25,20,17],
[69,25,20,19],
[69,25,21,0],
[69,25,21,1],
[69,25,21,2],
[69,25,21,4],
[69,25,21,10],
[69,25,21,11],
[69,25,21,12],
[69,25,21,13],
[69,25,21,14],
[69,25,21,16],
[69,25,21,17],
[69,25,21,19],
[69,25,21,20],
[69,25,22,0],
[69,25,22,1],
[69,25,22,2],
[69,25,22,3],
[69,25,22,4],
[69,25,22,5],
[69,25,22,6],
[69,25,22,8],
[69,25,22,11],
[69,25,22,12],
[69,25,22,13],
[69,25,22,14],
[69,25,22,16],
[69,25,22,17],
[69,25,22,19],
[69,25,22,21],
[69,25,23,0],
[69,25,23,1],
[69,25,23,2],
[69,25,23,3],
[69,25,23,4],
[69,25,23,5],
[69,25,23,6],
[69,25,23,8],
[69,25,23,10],
[69,25,23,11],
[69,25,23,12],
[69,25,23,13],
[69,25,23,14],
[69,25,23,16],
[69,25,23,17],
[69,25,23,19],
[69,25,23,20],
[69,25,23,22],
[69,25,24,0],
[69,25,24,1],
[69,25,24,2],
[69,25,24,3],
[69,25,24,4],
[69,25,24,5],
[69,25,24,6],
[69,25,24,8],
[69,25,24,10],
[69,25,24,11],
[69,25,24,12],
[69,25,24,13],
[69,25,24,14],
[69,25,24,17],
[69,25,24,19],
[69,25,24,20],
[69,25,24,22],
[69,25,24,23],
[69,27,3,0],
[69,27,3,1],
[69,27,3,2],
[69,27,4,0],
[69,27,4,1],
[69,27,4,2],
[69,27,4,3],
[69,27,5,0],
[69,27,5,1],
[69,27,5,2],
[69,27,5,4],
[69,27,6,0],
[69,27,6,1],
[69,27,6,2],
[69,27,6,4],
[69,27,7,0],
[69,27,7,1],
[69,27,7,2],
[69,27,7,3],
[69,27,7,4],
[69,27,7,5],
[69,27,7,6],
[69,27,8,0],
[69,27,8,1],
[69,27,8,2],
[69,27,8,4],
[69,27,8,7],
[69,27,11,0],
[69,27,11,1],
[69,27,11,2],
[69,27,11,3],
[69,27,11,4],
[69,27,11,5],
[69,27,11,6],
[69,27,11,7],
[69,27,11,8],
[69,27,12,0],
[69,27,12,1],
[69,27,12,2],
[69,27,12,3],
[69,27,12,4],
[69,27,12,5],
[69,27,12,6],
[69,27,12,7],
[69,27,12,8],
[69,27,13,0],
[69,27,13,1],
[69,27,13,2],
[69,27,13,3],
[69,27,13,4],
[69,27,13,5],
[69,27,13,6],
[69,27,13,7],
[69,27,13,8],
[69,27,13,11],
[69,27,13,12],
[69,27,14,0],
[69,27,14,1],
[69,27,14,2],
[69,27,14,3],
[69,27,14,4],
[69,27,14,5],
[69,27,14,6],
[69,27,14,7],
[69,27,14,8],
[69,27,14,11],
[69,27,14,12],
[69,27,16,0],
[69,27,16,1],
[69,27,16,2],
[69,27,16,3],
[69,27,16,4],
[69,27,16,5],
[69,27,16,6],
[69,27,16,7],
[69,27,16,8],
[69,27,16,11],
[69,27,16,12],
[69,27,16,13],
[69,27,16,14],
[69,27,17,0],
[69,27,17,1],
[69,27,17,2],
[69,27,17,3],
[69,27,17,4],
[69,27,17,5],
[69,27,17,6],
[69,27,17,7],
[69,27,17,8],
[69,27,17,11],
[69,27,17,12],
[69,27,17,13],
[69,27,17,14],
[69,27,17,16],
[69,27,19,0],
[69,27,19,1],
[69,27,19,2],
[69,27,19,3],
[69,27,19,4],
[69,27,19,5],
[69,27,19,6],
[69,27,19,7],
[69,27,19,8],
[69,27,19,11],
[69,27,19,12],
[69,27,19,16],
[69,27,19,17],
[69,27,21,0],
[69,27,21,1],
[69,27,21,2],
[69,27,21,4],
[69,27,21,7],
[69,27,21,11],
[69,27,21,12],
[69,27,21,13],
[69,27,21,14],
[69,27,21,16],
[69,27,21,17],
[69,27,21,19],
[69,27,23,0],
[69,27,23,1],
[69,27,23,2],
[69,27,23,3],
[69,27,23,4],
[69,27,23,5],
[69,27,23,6],
[69,27,23,7],
[69,27,23,8],
[69,27,23,11],
[69,27,23,12],
[69,27,23,13],
[69,27,23,14],
[69,27,23,16],
[69,27,23,17],
[69,27,23,21],
[69,27,24,0],
[69,27,24,1],
[69,27,24,2],
[69,27,24,3],
[69,27,24,4],
[69,27,24,5],
[69,27,24,6],
[69,27,24,7],
[69,27,24,8],
[69,27,24,11],
[69,27,24,12],
[69,27,24,13],
[69,27,24,14],
[69,27,24,17],
[69,27,24,21],
[69,27,24,23],
[69,27,25,0],
[69,27,25,1],
[69,27,25,2],
[69,27,25,3],
[69,27,25,4],
[69,27,25,5],
[69,27,25,6],
[69,27,25,8],
[69,27,25,11],
[69,27,25,12],
[69,27,25,13],
[69,27,25,14],
[69,27,25,19],
[69,27,25,21],
[69,27,25,23],
[69,27,25,24],
[69,28,3,0],
[69,28,3,1],
[69,28,3,2],
[69,28,4,0],
[69,28,4,1],
[69,28,4,2],
[69,28,4,3],
[69,28,5,0],
[69,28,5,1],
[69,28,5,2],
[69,28,5,4],
[69,28,6,0],
[69,28,6,1],
[69,28,6,2],
[69,28,6,4],
[69,28,8,0],
[69,28,8,1],
[69,28,8,2],
[69,28,8,4],
[69,28,10,0],
[69,28,10,1],
[69,28,10,2],
[69,28,10,3],
[69,28,10,4],
[69,28,10,5],
[69,28,10,6],
[69,28,10,8],
[69,28,11,0],
[69,28,11,1],
[69,28,11,2],
[69,28,11,3],
[69,28,11,4],
[69,28,11,5],
[69,28,11,6],
[69,28,11,8],
[69,28,11,10],
[69,28,12,0],
[69,28,12,1],
[69,28,12,2],
[69,28,12,3],
[69,28,12,4],
[69,28,12,5],
[69,28,12,6],
[69,28,12,8],
[69,28,12,10],
[69,28,13,0],
[69,28,13,1],
[69,28,13,2],
[69,28,13,3],
[69,28,13,4],
[69,28,13,5],
[69,28,13,6],
[69,28,13,8],
[69,28,13,10],
[69,28,13,11],
[69,28,13,12],
[69,28,14,0],
[69,28,14,1],
[69,28,14,2],
[69,28,14,3],
[69,28,14,4],
[69,28,14,5],
[69,28,14,6],
[69,28,14,8],
[69,28,14,10],
[69,28,14,11],
[69,28,14,12],
[69,28,16,0],
[69,28,16,1],
[69,28,16,2],
[69,28,16,3],
[69,28,16,4],
[69,28,16,5],
[69,28,16,6],
[69,28,16,8],
[69,28,16,10],
[69,28,16,11],
[69,28,16,12],
[69,28,16,13],
[69,28,16,14],
[69,28,17,0],
[69,28,17,1],
[69,28,17,2],
[69,28,17,3],
[69,28,17,4],
[69,28,17,5],
[69,28,17,6],
[69,28,17,8],
[69,28,17,10],
[69,28,17,11],
[69,28,17,12],
[69,28,17,13],
[69,28,17,14],
[69,28,17,16],
[69,28,19,0],
[69,28,19,1],
[69,28,19,2],
[69,28,19,3],
[69,28,19,4],
[69,28,19,5],
[69,28,19,6],
[69,28,19,8],
[69,28,19,10],
[69,28,19,11],
[69,28,19,12],
[69,28,19,16],
[69,28,19,17],
[69,28,20,0],
[69,28,20,1],
[69,28,20,2],
[69,28,20,3],
[69,28,20,4],
[69,28,20,5],
[69,28,20,6],
[69,28,20,8],
[69,28,20,11],
[69,28,20,12],
[69,28,20,13],
[69,28,20,14],
[69,28,20,16],
[69,28,20,17],
[69,28,20,19],
[69,28,21,0],
[69,28,21,1],
[69,28,21,2],
[69,28,21,4],
[69,28,21,10],
[69,28,21,11],
[69,28,21,12],
[69,28,21,13],
[69,28,21,14],
[69,28,21,16],
[69,28,21,17],
[69,28,21,19],
[69,28,22,0],
[69,28,22,1],
[69,28,22,2],
[69,28,22,3],
[69,28,22,4],
[69,28,22,5],
[69,28,22,6],
[69,28,22,8],
[69,28,22,11],
[69,28,22,12],
[69,28,22,13],
[69,28,22,14],
[69,28,22,16],
[69,28,22,17],
[69,28,22,21],
[69,28,23,0],
[69,28,23,1],
[69,28,23,2],
[69,28,23,3],
[69,28,23,4],
[69,28,23,5],
[69,28,23,6],
[69,28,23,8],
[69,28,23,10],
[69,28,23,11],
[69,28,23,12],
[69,28,23,13],
[69,28,23,14],
[69,28,23,16],
[69,28,23,17],
[69,28,23,19],
[69,28,23,20],
[69,28,23,21],
[69,28,23,22],
[69,28,24,0],
[69,28,24,1],
[69,28,24,2],
[69,28,24,3],
[69,28,24,4],
[69,28,24,5],
[69,28,24,6],
[69,28,24,8],
[69,28,24,10],
[69,28,24,11],
[69,28,24,12],
[69,28,24,13],
[69,28,24,14],
[69,28,24,17],
[69,28,24,19],
[69,28,24,20],
[69,28,24,21],
[69,28,24,22],
[69,28,24,23],
[69,28,27,0],
[69,28,27,1],
[69,28,27,2],
[69,28,27,3],
[69,28,27,4],
[69,28,27,5],
[69,28,27,6],
[69,28,27,8],
[69,28,27,11],
[69,28,27,14],
[69,28,27,16],
[69,28,27,17],
[69,28,27,19],
[69,28,27,21],
[69,28,27,23],
[69,28,27,24],
[69,29,3,0],
[69,29,3,1],
[69,29,3,2],
[69,29,4,0],
[69,29,4,1],
[69,29,4,2],
[69,29,4,3],
[69,29,5,0],
[69,29,5,1],
[69,29,5,2],
[69,29,5,4],
[69,29,6,0],
[69,29,6,1],
[69,29,6,2],
[69,29,6,4],
[69,29,7,0],
[69,29,7,1],
[69,29,7,2],
[69,29,7,3],
[69,29,7,4],
[69,29,7,5],
[69,29,7,6],
[69,29,8,0],
[69,29,8,1],
[69,29,8,2],
[69,29,8,4],
[69,29,8,7],
[69,29,10,0],
[69,29,10,1],
[69,29,10,2],
[69,29,10,3],
[69,29,10,4],
[69,29,10,5],
[69,29,10,6],
[69,29,10,7],
[69,29,10,8],
[69,29,13,0],
[69,29,13,1],
[69,29,13,2],
[69,29,13,3],
[69,29,13,4],
[69,29,13,5],
[69,29,13,6],
[69,29,13,7],
[69,29,13,8],
[69,29,13,10],
[69,29,14,0],
[69,29,14,1],
[69,29,14,2],
[69,29,14,3],
[69,29,14,4],
[69,29,14,5],
[69,29,14,6],
[69,29,14,7],
[69,29,14,8],
[69,29,14,10],
[69,29,16,0],
[69,29,16,1],
[69,29,16,2],
[69,29,16,3],
[69,29,16,4],
[69,29,16,5],
[69,29,16,6],
[69,29,16,7],
[69,29,16,8],
[69,29,16,10],
[69,29,16,13],
[69,29,16,14],
[69,29,17,0],
[69,29,17,1],
[69,29,17,2],
[69,29,17,3],
[69,29,17,4],
[69,29,17,5],
[69,29,17,6],
[69,29,17,7],
[69,29,17,8],
[69,29,17,10],
[69,29,17,13],
[69,29,17,14],
[69,29,17,16],
[69,29,19,0],
[69,29,19,1],
[69,29,19,2],
[69,29,19,3],
[69,29,19,4],
[69,29,19,5],
[69,29,19,6],
[69,29,19,7],
[69,29,19,8],
[69,29,19,10],
[69,29,19,16],
[69,29,19,17],
[69,29,20,0],
[69,29,20,1],
[69,29,20,2],
[69,29,20,3],
[69,29,20,4],
[69,29,20,5],
[69,29,20,6],
[69,29,20,7],
[69,29,20,8],
[69,29,20,13],
[69,29,20,14],
[69,29,20,16],
[69,29,20,17],
[69,29,20,19],
[69,29,21,0],
[69,29,21,1],
[69,29,21,2],
[69,29,21,4],
[69,29,21,7],
[69,29,21,10],
[69,29,21,13],
[69,29,21,14],
[69,29,21,16],
[69,29,21,17],
[69,29,21,20],
[69,29,22,0],
[69,29,22,1],
[69,29,22,2],
[69,29,22,3],
[69,29,22,4],
[69,29,22,5],
[69,29,22,6],
[69,29,22,7],
[69,29,22,8],
[69,29,22,13],
[69,29,22,14],
[69,29,22,16],
[69,29,22,17],
[69,29,22,19],
[69,29,22,21],
[69,29,23,0],
[69,29,23,1],
[69,29,23,2],
[69,29,23,3],
[69,29,23,4],
[69,29,23,5],
[69,29,23,6],
[69,29,23,7],
[69,29,23,8],
[69,29,23,10],
[69,29,23,13],
[69,29,23,14],
[69,29,23,19],
[69,29,23,20],
[69,29,23,21],
[69,29,23,22],
[69,29,24,0],
[69,29,24,1],
[69,29,24,2],
[69,29,24,3],
[69,29,24,4],
[69,29,24,5],
[69,29,24,6],
[69,29,24,7],
[69,29,24,8],
[69,29,24,10],
[69,29,24,13],
[69,29,24,14],
[69,29,24,19],
[69,29,24,20],
[69,29,24,21],
[69,29,24,22],
[69,29,24,23],
[69,29,25,0],
[69,29,25,1],
[69,29,25,2],
[69,29,25,3],
[69,29,25,4],
[69,29,25,5],
[69,29,25,6],
[69,29,25,8],
[69,29,25,10],
[69,29,25,13],
[69,29,25,16],
[69,29,25,17],
[69,29,25,19],
[69,29,25,20],
[69,29,25,21],
[69,29,25,22],
[69,29,25,23],
[69,29,25,24],
[69,29,27,0],
[69,29,27,1],
[69,29,27,2],
[69,29,27,3],
[69,29,27,4],
[69,29,27,5],
[69,29,27,6],
[69,29,27,7],
[69,29,27,8],
[69,29,27,13],
[69,29,27,14],
[69,29,27,16],
[69,29,27,17],
[69,29,27,19],
[69,29,27,21],
[69,29,27,23],
[69,29,27,24],
[69,29,27,25],
[69,29,28,0],
[69,29,28,1],
[69,29,28,2],
[69,29,28,3],
[69,29,28,4],
[69,29,28,5],
[69,29,28,6],
[69,29,28,10],
[69,29,28,13],
[69,29,28,14],
[69,29,28,16],
[69,29,28,17],
[69,29,28,19],
[69,29,28,20],
[69,29,28,21],
[69,29,28,22],
[69,29,28,23],
[69,29,28,24],
[69,30,3,0],
[69,30,3,1],
[69,30,3,2],
[69,30,4,0],
[69,30,4,1],
[69,30,4,2],
[69,30,4,3],
[69,30,5,0],
[69,30,5,1],
[69,30,5,2],
[69,30,5,4],
[69,30,6,0],
[69,30,6,1],
[69,30,6,2],
[69,30,6,4],
[69,30,7,0],
[69,30,7,1],
[69,30,7,2],
[69,30,7,3],
[69,30,7,4],
[69,30,7,5],
[69,30,7,6],
[69,30,8,0],
[69,30,8,1],
[69,30,8,2],
[69,30,8,4],
[69,30,8,7],
[69,30,10,0],
[69,30,10,1],
[69,30,10,2],
[69,30,10,3],
[69,30,10,4],
[69,30,10,5],
[69,30,10,6],
[69,30,10,7],
[69,30,10,8],
[69,30,11,0],
[69,30,11,1],
[69,30,11,2],
[69,30,11,3],
[69,30,11,4],
[69,30,11,5],
[69,30,11,6],
[69,30,11,7],
[69,30,11,8],
[69,30,11,10],
[69,30,12,0],
[69,30,12,1],
[69,30,12,2],
[69,30,12,3],
[69,30,12,4],
[69,30,12,5],
[69,30,12,6],
[69,30,12,7],
[69,30,12,8],
[69,30,12,10],
[69,30,13,0],
[69,30,13,1],
[69,30,13,2],
[69,30,13,3],
[69,30,13,4],
[69,30,13,5],
[69,30,13,6],
[69,30,13,7],
[69,30,13,8],
[69,30,13,10],
[69,30,13,11],
[69,30,13,12],
[69,30,14,0],
[69,30,14,1],
[69,30,14,2],
[69,30,14,3],
[69,30,14,4],
[69,30,14,5],
[69,30,14,6],
[69,30,14,7],
[69,30,14,8],
[69,30,14,10],
[69,30,14,11],
[69,30,14,12],
[69,30,16,0],
[69,30,16,1],
[69,30,16,2],
[69,30,16,3],
[69,30,16,4],
[69,30,16,5],
[69,30,16,6],
[69,30,16,7],
[69,30,16,8],
[69,30,16,10],
[69,30,16,11],
[69,30,16,12],
[69,30,16,13],
[69,30,16,14],
[69,30,17,0],
[69,30,17,1],
[69,30,17,2],
[69,30,17,3],
[69,30,17,4],
[69,30,17,5],
[69,30,17,6],
[69,30,17,7],
[69,30,17,8],
[69,30,17,10],
[69,30,17,11],
[69,30,17,12],
[69,30,17,13],
[69,30,17,14],
[69,30,17,16],
[69,30,19,0],
[69,30,19,1],
[69,30,19,2],
[69,30,19,3],
[69,30,19,4],
[69,30,19,5],
[69,30,19,6],
[69,30,19,7],
[69,30,19,8],
[69,30,19,10],
[69,30,19,11],
[69,30,19,12],
[69,30,19,16],
[69,30,19,17],
[69,30,20,0],
[69,30,20,1],
[69,30,20,2],
[69,30,20,3],
[69,30,20,4],
[69,30,20,5],
[69,30,20,6],
[69,30,20,7],
[69,30,20,8],
[69,30,20,11],
[69,30,20,12],
[69,30,20,13],
[69,30,20,14],
[69,30,20,16],
[69,30,20,17],
[69,30,21,0],
[69,30,21,1],
[69,30,21,2],
[69,30,21,4],
[69,30,21,7],
[69,30,21,10],
[69,30,21,11],
[69,30,21,12],
[69,30,21,13],
[69,30,21,14],
[69,30,21,16],
[69,30,21,17],
[69,30,21,19],
[69,30,21,20],
[69,30,22,0],
[69,30,22,1],
[69,30,22,2],
[69,30,22,3],
[69,30,22,4],
[69,30,22,5],
[69,30,22,6],
[69,30,22,7],
[69,30,22,8],
[69,30,22,11],
[69,30,22,12],
[69,30,22,13],
[69,30,22,14],
[69,30,22,19],
[69,30,22,21],
[69,30,24,0],
[69,30,24,1],
[69,30,24,2],
[69,30,24,3],
[69,30,24,4],
[69,30,24,5],
[69,30,24,6],
[69,30,24,7],
[69,30,24,8],
[69,30,24,10],
[69,30,24,11],
[69,30,24,12],
[69,30,24,13],
[69,30,24,14],
[69,30,24,17],
[69,30,24,19],
[69,30,24,20],
[69,30,24,21],
[69,30,24,22],
[69,30,25,0],
[69,30,25,1],
[69,30,25,2],
[69,30,25,3],
[69,30,25,4],
[69,30,25,5],
[69,30,25,6],
[69,30,25,8],
[69,30,25,10],
[69,30,25,11],
[69,30,25,14],
[69,30,25,16],
[69,30,25,17],
[69,30,25,19],
[69,30,25,20],
[69,30,25,21],
[69,30,25,22],
[69,30,25,24],
[69,30,27,0],
[69,30,27,1],
[69,30,27,2],
[69,30,27,3],
[69,30,27,4],
[69,30,27,5],
[69,30,27,6],
[69,30,27,7],
[69,30,27,11],
[69,30,27,12],
[69,30,27,13],
[69,30,27,14],
[69,30,27,16],
[69,30,27,17],
[69,30,27,19],
[69,30,27,21],
[69,30,27,24],
[69,30,28,0],
[69,30,28,1],
[69,30,28,2],
[69,30,28,3],
[69,30,28,4],
[69,30,28,5],
[69,30,28,8],
[69,30,28,10],
[69,30,28,11],
[69,30,28,12],
[69,30,28,13],
[69,30,28,14],
[69,30,28,16],
[69,30,28,17],
[69,30,28,19],
[69,30,28,20],
[69,30,28,21],
[69,30,28,22],
[69,30,28,24],
[69,30,29,0],
[69,30,29,1],
[69,30,29,2],
[69,30,29,3],
[69,30,29,4],
[69,30,29,6],
[69,30,29,7],
[69,30,29,8],
[69,30,29,10],
[69,30,29,13],
[69,30,29,14],
[69,30,29,16],
[69,30,29,17],
[69,30,29,19],
[69,30,29,20],
[69,30,29,21],
[69,30,29,22],
[69,31,3,0],
[69,31,3,1],
[69,31,3,2],
[69,31,4,0],
[69,31,4,1],
[69,31,4,2],
[69,31,4,3],
[69,31,5,0],
[69,31,5,1],
[69,31,5,2],
[69,31,5,4],
[69,31,6,0],
[69,31,6,1],
[69,31,6,2],
[69,31,6,4],
[69,31,7,0],
[69,31,7,1],
[69,31,7,2],
[69,31,7,3],
[69,31,7,4],
[69,31,7,5],
[69,31,7,6],
[69,31,8,0],
[69,31,8,1],
[69,31,8,2],
[69,31,8,4],
[69,31,8,7],
[69,31,10,0],
[69,31,10,1],
[69,31,10,2],
[69,31,10,3],
[69,31,10,4],
[69,31,10,5],
[69,31,10,6],
[69,31,10,7],
[69,31,10,8],
[69,31,11,0],
[69,31,11,1],
[69,31,11,2],
[69,31,11,3],
[69,31,11,4],
[69,31,11,5],
[69,31,11,6],
[69,31,11,7],
[69,31,11,8],
[69,31,11,10],
[69,31,12,0],
[69,31,12,1],
[69,31,12,2],
[69,31,12,3],
[69,31,12,4],
[69,31,12,5],
[69,31,12,6],
[69,31,12,7],
[69,31,12,8],
[69,31,12,10],
[69,31,13,0],
[69,31,13,1],
[69,31,13,2],
[69,31,13,3],
[69,31,13,4],
[69,31,13,5],
[69,31,13,6],
[69,31,13,7],
[69,31,13,8],
[69,31,13,10],
[69,31,13,11],
[69,31,13,12],
[69,31,14,0],
[69,31,14,1],
[69,31,14,2],
[69,31,14,3],
[69,31,14,4],
[69,31,14,5],
[69,31,14,6],
[69,31,14,7],
[69,31,14,8],
[69,31,14,10],
[69,31,14,11],
[69,31,14,12],
[69,31,16,0],
[69,31,16,1],
[69,31,16,2],
[69,31,16,3],
[69,31,16,4],
[69,31,16,5],
[69,31,16,6],
[69,31,16,7],
[69,31,16,8],
[69,31,16,10],
[69,31,16,11],
[69,31,16,12],
[69,31,16,13],
[69,31,16,14],
[69,31,17,0],
[69,31,17,1],
[69,31,17,2],
[69,31,17,3],
[69,31,17,4],
[69,31,17,5],
[69,31,17,6],
[69,31,17,7],
[69,31,17,8],
[69,31,17,10],
[69,31,17,11],
[69,31,17,12],
[69,31,17,13],
[69,31,17,14],
[69,31,17,16],
[69,31,19,0],
[69,31,19,1],
[69,31,19,2],
[69,31,19,3],
[69,31,19,4],
[69,31,19,5],
[69,31,19,6],
[69,31,19,7],
[69,31,19,8],
[69,31,19,10],
[69,31,19,11],
[69,31,19,12],
[69,31,19,16],
[69,31,19,17],
[69,31,20,0],
[69,31,20,1],
[69,31,20,2],
[69,31,20,3],
[69,31,20,4],
[69,31,20,5],
[69,31,20,6],
[69,31,20,7],
[69,31,20,8],
[69,31,20,11],
[69,31,20,12],
[69,31,20,13],
[69,31,20,14],
[69,31,20,16],
[69,31,20,17],
[69,31,20,19],
[69,31,21,0],
[69,31,21,1],
[69,31,21,2],
[69,31,21,4],
[69,31,21,7],
[69,31,21,10],
[69,31,21,11],
[69,31,21,12],
[69,31,21,13],
[69,31,21,14],
[69,31,21,19],
[69,31,21,20],
[69,31,22,0],
[69,31,22,1],
[69,31,22,2],
[69,31,22,3],
[69,31,22,4],
[69,31,22,5],
[69,31,22,6],
[69,31,22,7],
[69,31,22,8],
[69,31,22,11],
[69,31,22,12],
[69,31,22,13],
[69,31,22,14],
[69,31,22,16],
[69,31,22,17],
[69,31,22,19],
[69,31,22,21],
[69,31,24,0],
[69,31,24,1],
[69,31,24,2],
[69,31,24,3],
[69,31,24,4],
[69,31,24,5],
[69,31,24,6],
[69,31,24,7],
[69,31,24,8],
[69,31,24,10],
[69,31,24,11],
[69,31,24,12],
[69,31,24,13],
[69,31,24,17],
[69,31,24,19],
[69,31,24,20],
[69,31,24,21],
[69,31,24,22],
[69,31,25,0],
[69,31,25,1],
[69,31,25,2],
[69,31,25,3],
[69,31,25,4],
[69,31,25,5],
[69,31,25,6],
[69,31,25,8],
[69,31,25,12],
[69,31,25,13],
[69,31,25,14],
[69,31,25,16],
[69,31,25,17],
[69,31,25,19],
[69,31,25,20],
[69,31,25,21],
[69,31,25,22],
[69,31,25,24],
[69,31,27,0],
[69,31,27,1],
[69,31,27,2],
[69,31,27,3],
[69,31,27,4],
[69,31,27,5],
[69,31,27,8],
[69,31,27,11],
[69,31,27,12],
[69,31,27,13],
[69,31,27,14],
[69,31,27,16],
[69,31,27,17],
[69,31,27,19],
[69,31,27,21],
[69,31,27,24],
[69,31,28,0],
[69,31,28,1],
[69,31,28,2],
[69,31,28,3],
[69,31,28,4],
[69,31,28,6],
[69,31,28,8],
[69,31,28,10],
[69,31,28,11],
[69,31,28,12],
[69,31,28,13],
[69,31,28,14],
[69,31,28,16],
[69,31,28,17],
[69,31,28,19],
[69,31,28,20],
[69,31,28,21],
[69,31,28,22],
[69,31,29,0],
[69,31,29,1],
[69,31,29,2],
[69,31,29,5],
[69,31,29,6],
[69,31,29,7],
[69,31,29,8],
[69,31,29,10],
[69,31,29,13],
[69,31,29,14],
[69,31,29,16],
[69,31,29,17],
[69,31,29,19],
[69,31,29,20],
[69,31,29,21],
[69,32,3,0],
[69,32,3,1],
[69,32,3,2],
[69,32,4,0],
[69,32,4,1],
[69,32,4,2],
[69,32,4,3],
[69,32,5,0],
[69,32,5,1],
[69,32,5,2],
[69,32,5,4],
[69,32,6,0],
[69,32,6,1],
[69,32,6,2],
[69,32,6,4],
[69,32,7,0],
[69,32,7,1],
[69,32,7,2],
[69,32,7,3],
[69,32,7,4],
[69,32,7,5],
[69,32,7,6],
[69,32,8,0],
[69,32,8,1],
[69,32,8,2],
[69,32,8,4],
[69,32,8,7],
[69,32,10,0],
[69,32,10,1],
[69,32,10,2],
[69,32,10,3],
[69,32,10,4],
[69,32,10,5],
[69,32,10,6],
[69,32,10,7],
[69,32,10,8],
[69,32,13,0],
[69,32,13,1],
[69,32,13,2],
[69,32,13,3],
[69,32,13,4],
[69,32,13,5],
[69,32,13,6],
[69,32,13,7],
[69,32,13,8],
[69,32,13,10],
[69,32,14,0],
[69,32,14,1],
[69,32,14,2],
[69,32,14,3],
[69,32,14,4],
[69,32,14,5],
[69,32,14,6],
[69,32,14,7],
[69,32,14,8],
[69,32,14,10],
[69,32,16,0],
[69,32,16,1],
[69,32,16,2],
[69,32,16,3],
[69,32,16,4],
[69,32,16,5],
[69,32,16,6],
[69,32,16,7],
[69,32,16,8],
[69,32,16,10],
[69,32,16,13],
[69,32,16,14],
[69,32,17,0],
[69,32,17,1],
[69,32,17,2],
[69,32,17,3],
[69,32,17,4],
[69,32,17,5],
[69,32,17,6],
[69,32,17,7],
[69,32,17,8],
[69,32,17,10],
[69,32,17,13],
[69,32,17,14],
[69,32,17,16],
[69,32,19,0],
[69,32,19,1],
[69,32,19,2],
[69,32,19,3],
[69,32,19,4],
[69,32,19,5],
[69,32,19,6],
[69,32,19,7],
[69,32,19,8],
[69,32,19,10],
[69,32,19,16],
[69,32,19,17],
[69,32,20,0],
[69,32,20,1],
[69,32,20,2],
[69,32,20,3],
[69,32,20,4],
[69,32,20,5],
[69,32,20,6],
[69,32,20,7],
[69,32,20,8],
[69,32,20,13],
[69,32,20,14],
[69,32,20,19],
[69,32,21,0],
[69,32,21,1],
[69,32,21,2],
[69,32,21,4],
[69,32,21,7],
[69,32,21,10],
[69,32,21,13],
[69,32,21,14],
[69,32,21,16],
[69,32,21,17],
[69,32,21,19],
[69,32,21,20],
[69,32,22,0],
[69,32,22,1],
[69,32,22,2],
[69,32,22,3],
[69,32,22,4],
[69,32,22,5],
[69,32,22,6],
[69,32,22,7],
[69,32,22,8],
[69,32,22,13],
[69,32,22,16],
[69,32,22,17],
[69,32,22,19],
[69,32,22,21],
[69,32,23,0],
[69,32,23,1],
[69,32,23,2],
[69,32,23,3],
[69,32,23,4],
[69,32,23,5],
[69,32,23,6],
[69,32,23,7],
[69,32,23,8],
[69,32,23,10],
[69,32,23,14],
[69,32,23,16],
[69,32,23,17],
[69,32,23,19],
[69,32,23,20],
[69,32,23,21],
[69,32,23,22],
[69,32,24,0],
[69,32,24,1],
[69,32,24,2],
[69,32,24,3],
[69,32,24,4],
[69,32,24,5],
[69,32,24,6],
[69,32,24,7],
[69,32,24,8],
[69,32,24,10],
[69,32,24,14],
[69,32,24,17],
[69,32,24,19],
[69,32,24,20],
[69,32,24,21],
[69,32,24,22],
[69,32,24,23],
[69,32,25,0],
[69,32,25,1],
[69,32,25,2],
[69,32,25,3],
[69,32,25,4],
[69,32,25,5],
[69,32,25,6],
[69,32,25,10],
[69,32,25,13],
[69,32,25,14],
[69,32,25,16],
[69,32,25,17],
[69,32,25,19],
[69,32,25,20],
[69,32,25,21],
[69,32,25,22],
[69,32,25,23],
[69,32,25,24],
[69,32,27,0],
[69,32,27,1],
[69,32,27,2],
[69,32,27,3],
[69,32,27,4],
[69,32,27,6],
[69,32,27,7],
[69,32,27,8],
[69,32,27,13],
[69,32,27,14],
[69,32,27,16],
[69,32,27,17],
[69,32,27,19],
[69,32,27,21],
[69,32,28,0],
[69,32,28,1],
[69,32,28,2],
[69,32,28,5],
[69,32,28,6],
[69,32,28,8],
[69,32,28,10],
[69,32,28,13],
[69,32,28,14],
[69,32,28,16],
[69,32,28,17],
[69,32,28,19],
[69,32,28,20],
[69,32,28,21],
[69,32,30,0],
[69,32,30,1],
[69,32,30,2],
[69,32,30,3],
[69,32,30,4],
[69,32,30,5],
[69,32,30,6],
[69,32,30,7],
[69,32,30,8],
[69,32,30,10],
[69,32,30,13],
[69,32,30,14],
[69,32,30,16],
[69,32,30,17],
[69,32,30,19],
[69,32,31,0],
[69,32,31,1],
[69,32,31,2],
[69,32,31,3],
[69,32,31,4],
[69,32,31,5],
[69,32,31,6],
[69,32,31,7],
[69,32,31,8],
[69,32,31,10],
[69,32,31,13],
[69,32,31,14],
[69,32,31,16],
[69,32,31,17],
[69,33,3,0],
[69,33,3,1],
[69,33,3,2],
[69,33,4,0],
[69,33,4,1],
[69,33,4,2],
[69,33,4,3],
[69,33,5,0],
[69,33,5,1],
[69,33,5,2],
[69,33,5,4],
[69,33,6,0],
[69,33,6,1],
[69,33,6,2],
[69,33,6,4],
[69,33,7,0],
[69,33,7,1],
[69,33,7,2],
[69,33,7,3],
[69,33,7,4],
[69,33,7,5],
[69,33,7,6],
[69,33,8,0],
[69,33,8,1],
[69,33,8,2],
[69,33,8,4],
[69,33,8,7],
[69,33,10,0],
[69,33,10,1],
[69,33,10,2],
[69,33,10,3],
[69,33,10,4],
[69,33,10,5],
[69,33,10,6],
[69,33,10,7],
[69,33,10,8],
[69,33,13,0],
[69,33,13,1],
[69,33,13,2],
[69,33,13,3],
[69,33,13,4],
[69,33,13,5],
[69,33,13,6],
[69,33,13,7],
[69,33,13,8],
[69,33,13,10],
[69,33,14,0],
[69,33,14,1],
[69,33,14,2],
[69,33,14,3],
[69,33,14,4],
[69,33,14,5],
[69,33,14,6],
[69,33,14,7],
[69,33,14,8],
[69,33,14,10],
[69,33,16,0],
[69,33,16,1],
[69,33,16,2],
[69,33,16,3],
[69,33,16,4],
[69,33,16,5],
[69,33,16,6],
[69,33,16,7],
[69,33,16,8],
[69,33,16,10],
[69,33,16,13],
[69,33,16,14],
[69,33,17,0],
[69,33,17,1],
[69,33,17,2],
[69,33,17,3],
[69,33,17,4],
[69,33,17,5],
[69,33,17,6],
[69,33,17,7],
[69,33,17,8],
[69,33,17,10],
[69,33,17,13],
[69,33,17,14],
[69,33,17,16],
[69,33,19,0],
[69,33,19,1],
[69,33,19,2],
[69,33,19,3],
[69,33,19,4],
[69,33,19,5],
[69,33,19,6],
[69,33,19,7],
[69,33,19,8],
[69,33,19,10],
[69,33,20,0],
[69,33,20,1],
[69,33,20,2],
[69,33,20,3],
[69,33,20,4],
[69,33,20,5],
[69,33,20,6],
[69,33,20,7],
[69,33,20,8],
[69,33,20,13],
[69,33,20,14],
[69,33,20,16],
[69,33,20,17],
[69,33,20,19],
[69,33,21,0],
[69,33,21,1],
[69,33,21,2],
[69,33,21,4],
[69,33,21,7],
[69,33,21,10],
[69,33,21,13],
[69,33,21,16],
[69,33,21,17],
[69,33,21,19],
[69,33,21,20],
[69,33,22,0],
[69,33,22,1],
[69,33,22,2],
[69,33,22,3],
[69,33,22,4],
[69,33,22,5],
[69,33,22,6],
[69,33,22,7],
[69,33,22,8],
[69,33,22,14],
[69,33,22,16],
[69,33,22,17],
[69,33,22,19],
[69,33,22,21],
[69,33,23,0],
[69,33,23,1],
[69,33,23,2],
[69,33,23,3],
[69,33,23,4],
[69,33,23,5],
[69,33,23,6],
[69,33,23,7],
[69,33,23,8],
[69,33,23,13],
[69,33,23,14],
[69,33,23,16],
[69,33,23,17],
[69,33,23,19],
[69,33,23,20],
[69,33,23,21],
[69,33,23,22],
[69,33,24,0],
[69,33,24,1],
[69,33,24,2],
[69,33,24,3],
[69,33,24,4],
[69,33,24,5],
[69,33,24,6],
[69,33,24,7],
[69,33,24,8],
[69,33,24,13],
[69,33,24,14],
[69,33,24,17],
[69,33,24,19],
[69,33,24,20],
[69,33,24,21],
[69,33,24,22],
[69,33,24,23],
[69,33,25,0],
[69,33,25,1],
[69,33,25,2],
[69,33,25,3],
[69,33,25,4],
[69,33,25,5],
[69,33,25,8],
[69,33,25,10],
[69,33,25,13],
[69,33,25,14],
[69,33,25,16],
[69,33,25,17],
[69,33,25,19],
[69,33,25,20],
[69,33,25,21],
[69,33,25,22],
[69,33,25,23],
[69,33,25,24],
[69,33,27,0],
[69,33,27,1],
[69,33,27,2],
[69,33,27,5],
[69,33,27,6],
[69,33,27,7],
[69,33,27,8],
[69,33,27,13],
[69,33,27,14],
[69,33,27,16],
[69,33,27,17],
[69,33,27,19],
[69,33,27,21],
[69,33,28,3],
[69,33,28,4],
[69,33,28,5],
[69,33,28,6],
[69,33,28,8],
[69,33,28,10],
[69,33,28,13],
[69,33,28,14],
[69,33,28,16],
[69,33,28,17],
[69,33,28,19],
[69,33,28,20],
[69,33,30,0],
[69,33,30,1],
[69,33,30,2],
[69,33,30,3],
[69,33,30,4],
[69,33,30,5],
[69,33,30,6],
[69,33,30,7],
[69,33,30,8],
[69,33,30,10],
[69,33,30,13],
[69,33,30,14],
[69,33,30,16],
[69,33,30,17],
[69,33,31,0],
[69,33,31,1],
[69,33,31,2],
[69,33,31,3],
[69,33,31,4],
[69,33,31,5],
[69,33,31,6],
[69,33,31,7],
[69,33,31,8],
[69,33,31,10],
[69,33,31,13],
[69,33,31,14],
[69,33,31,16],
[69,33,31,17],
[69,34,3,0],
[69,34,3,1],
[69,34,3,2],
[69,34,4,0],
[69,34,4,1],
[69,34,4,2],
[69,34,4,3],
[69,34,5,0],
[69,34,5,1],
[69,34,5,2],
[69,34,5,4],
[69,34,6,0],
[69,34,6,1],
[69,34,6,2],
[69,34,6,4],
[69,34,8,0],
[69,34,8,1],
[69,34,8,2],
[69,34,8,4],
[69,34,10,0],
[69,34,10,1],
[69,34,10,2],
[69,34,10,3],
[69,34,10,4],
[69,34,10,5],
[69,34,10,6],
[69,34,10,8],
[69,34,11,0],
[69,34,11,1],
[69,34,11,2],
[69,34,11,3],
[69,34,11,4],
[69,34,11,5],
[69,34,11,6],
[69,34,11,8],
[69,34,11,10],
[69,34,12,0],
[69,34,12,1],
[69,34,12,2],
[69,34,12,3],
[69,34,12,4],
[69,34,12,5],
[69,34,12,6],
[69,34,12,8],
[69,34,12,10],
[69,34,13,0],
[69,34,13,1],
[69,34,13,2],
[69,34,13,3],
[69,34,13,4],
[69,34,13,5],
[69,34,13,6],
[69,34,13,8],
[69,34,13,10],
[69,34,13,11],
[69,34,13,12],
[69,34,14,0],
[69,34,14,1],
[69,34,14,2],
[69,34,14,3],
[69,34,14,4],
[69,34,14,5],
[69,34,14,6],
[69,34,14,8],
[69,34,14,10],
[69,34,14,11],
[69,34,14,12],
[69,34,16,0],
[69,34,16,1],
[69,34,16,2],
[69,34,16,3],
[69,34,16,4],
[69,34,16,5],
[69,34,16,6],
[69,34,16,8],
[69,34,16,10],
[69,34,16,11],
[69,34,16,12],
[69,34,16,13],
[69,34,16,14],
[69,34,17,0],
[69,34,17,1],
[69,34,17,2],
[69,34,17,3],
[69,34,17,4],
[69,34,17,5],
[69,34,17,6],
[69,34,17,8],
[69,34,17,10],
[69,34,17,11],
[69,34,17,12],
[69,34,17,13],
[69,34,17,14],
[69,34,17,16],
[69,34,19,0],
[69,34,19,1],
[69,34,19,2],
[69,34,19,3],
[69,34,19,4],
[69,34,19,5],
[69,34,19,6],
[69,34,19,8],
[69,34,19,10],
[69,34,19,11],
[69,34,19,12],
[69,34,19,16],
[69,34,19,17],
[69,34,20,0],
[69,34,20,1],
[69,34,20,2],
[69,34,20,3],
[69,34,20,4],
[69,34,20,5],
[69,34,20,6],
[69,34,20,8],
[69,34,20,11],
[69,34,20,12],
[69,34,20,13],
[69,34,20,16],
[69,34,20,17],
[69,34,20,19],
[69,34,21,0],
[69,34,21,1],
[69,34,21,2],
[69,34,21,4],
[69,34,21,10],
[69,34,21,11],
[69,34,21,14],
[69,34,21,16],
[69,34,21,17],
[69,34,21,19],
[69,34,21,20],
[69,34,22,0],
[69,34,22,1],
[69,34,22,2],
[69,34,22,3],
[69,34,22,4],
[69,34,22,5],
[69,34,22,6],
[69,34,22,8],
[69,34,22,12],
[69,34,22,13],
[69,34,22,14],
[69,34,22,16],
[69,34,22,17],
[69,34,22,19],
[69,34,22,21],
[69,34,23,0],
[69,34,23,1],
[69,34,23,2],
[69,34,23,3],
[69,34,23,4],
[69,34,23,5],
[69,34,23,6],
[69,34,23,10],
[69,34,23,11],
[69,34,23,12],
[69,34,23,13],
[69,34,23,14],
[69,34,23,16],
[69,34,23,17],
[69,34,23,19],
[69,34,23,20],
[69,34,23,21],
[69,34,23,22],
[69,34,24,0],
[69,34,24,1],
[69,34,24,2],
[69,34,24,3],
[69,34,24,4],
[69,34,24,5],
[69,34,24,6],
[69,34,24,10],
[69,34,24,11],
[69,34,24,12],
[69,34,24,13],
[69,34,24,14],
[69,34,24,17],
[69,34,24,19],
[69,34,24,20],
[69,34,24,21],
[69,34,24,22],
[69,34,24,23],
[69,34,27,3],
[69,34,27,4],
[69,34,27,5],
[69,34,27,6],
[69,34,27,8],
[69,34,27,11],
[69,34,27,12],
[69,34,27,13],
[69,34,27,14],
[69,34,27,16],
[69,34,27,17],
[69,34,27,19],
[69,34,29,0],
[69,34,29,1],
[69,34,29,2],
[69,34,29,3],
[69,34,29,4],
[69,34,29,5],
[69,34,29,6],
[69,34,29,8],
[69,34,29,10],
[69,34,29,13],
[69,34,29,14],
[69,34,29,16],
[69,34,29,17],
[69,34,30,0],
[69,34,30,1],
[69,34,30,2],
[69,34,30,3],
[69,34,30,4],
[69,34,30,5],
[69,34,30,6],
[69,34,30,8],
[69,34,30,10],
[69,34,30,11],
[69,34,30,12],
[69,34,30,13],
[69,34,30,14],
[69,34,30,16],
[69,34,30,17],
[69,34,31,0],
[69,34,31,1],
[69,34,31,2],
[69,34,31,3],
[69,34,31,4],
[69,34,31,5],
[69,34,31,6],
[69,34,31,8],
[69,34,31,10],
[69,34,31,11],
[69,34,31,12],
[69,34,31,13],
[69,34,31,14],
[69,34,32,0],
[69,34,32,1],
[69,34,32,2],
[69,34,32,3],
[69,34,32,4],
[69,34,32,5],
[69,34,32,6],
[69,34,32,8],
[69,34,32,10],
[69,34,32,13],
[69,34,32,14],
[69,34,33,0],
[69,34,33,1],
[69,34,33,2],
[69,34,33,3],
[69,34,33,4],
[69,34,33,5],
[69,34,33,6],
[69,34,33,8],
[69,34,33,10],
[69,34,33,13],
[69,36,3,0],
[69,36,3,1],
[69,36,3,2],
[69,36,4,0],
[69,36,4,1],
[69,36,4,2],
[69,36,4,3],
[69,36,5,0],
[69,36,5,1],
[69,36,5,2],
[69,36,5,4],
[69,36,6,0],
[69,36,6,1],
[69,36,6,2],
[69,36,6,4],
[69,36,7,0],
[69,36,7,1],
[69,36,7,2],
[69,36,7,3],
[69,36,7,4],
[69,36,7,5],
[69,36,7,6],
[69,36,8,0],
[69,36,8,1],
[69,36,8,2],
[69,36,8,4],
[69,36,8,7],
[69,36,10,0],
[69,36,10,1],
[69,36,10,2],
[69,36,10,3],
[69,36,10,4],
[69,36,10,5],
[69,36,10,6],
[69,36,10,7],
[69,36,10,8],
[69,36,11,0],
[69,36,11,1],
[69,36,11,2],
[69,36,11,3],
[69,36,11,4],
[69,36,11,5],
[69,36,11,6],
[69,36,11,7],
[69,36,11,8],
[69,36,11,10],
[69,36,12,0],
[69,36,12,1],
[69,36,12,2],
[69,36,12,3],
[69,36,12,4],
[69,36,12,5],
[69,36,12,6],
[69,36,12,7],
[69,36,12,8],
[69,36,12,10],
[69,36,16,0],
[69,36,16,1],
[69,36,16,2],
[69,36,16,3],
[69,36,16,4],
[69,36,16,5],
[69,36,16,6],
[69,36,16,7],
[69,36,16,8],
[69,36,16,10],
[69,36,16,11],
[69,36,16,12],
[69,36,17,0],
[69,36,17,1],
[69,36,17,2],
[69,36,17,3],
[69,36,17,4],
[69,36,17,5],
[69,36,17,6],
[69,36,17,7],
[69,36,17,8],
[69,36,17,10],
[69,36,17,11],
[69,36,17,12],
[69,36,17,16],
[69,36,20,0],
[69,36,20,1],
[69,36,20,2],
[69,36,20,3],
[69,36,20,4],
[69,36,20,5],
[69,36,20,6],
[69,36,20,7],
[69,36,20,8],
[69,36,20,12],
[69,36,20,16],
[69,36,20,17],
[69,36,21,0],
[69,36,21,1],
[69,36,21,2],
[69,36,21,4],
[69,36,21,7],
[69,36,21,10],
[69,36,21,11],
[69,36,21,12],
[69,36,21,16],
[69,36,21,17],
[69,36,21,20],
[69,36,22,0],
[69,36,22,1],
[69,36,22,2],
[69,36,22,3],
[69,36,22,4],
[69,36,22,5],
[69,36,22,8],
[69,36,22,11],
[69,36,22,12],
[69,36,22,16],
[69,36,22,17],
[69,36,22,21],
[69,36,23,0],
[69,36,23,1],
[69,36,23,2],
[69,36,23,3],
[69,36,23,4],
[69,36,23,6],
[69,36,23,7],
[69,36,23,8],
[69,36,23,10],
[69,36,23,11],
[69,36,23,12],
[69,36,23,16],
[69,36,23,17],
[69,36,23,20],
[69,36,23,21],
[69,36,23,22],
[69,36,24,0],
[69,36,24,1],
[69,36,24,2],
[69,36,24,3],
[69,36,24,4],
[69,36,24,6],
[69,36,24,7],
[69,36,24,8],
[69,36,24,10],
[69,36,24,11],
[69,36,24,12],
[69,36,24,17],
[69,36,24,20],
[69,36,24,21],
[69,36,24,22],
[69,36,25,3],
[69,36,25,4],
[69,36,25,5],
[69,36,25,6],
[69,36,25,8],
[69,36,25,10],
[69,36,25,11],
[69,36,25,12],
[69,36,25,16],
[69,36,25,17],
[69,36,25,20],
[69,36,27,0],
[69,36,27,1],
[69,36,27,2],
[69,36,27,3],
[69,36,27,4],
[69,36,27,5],
[69,36,27,6],
[69,36,27,7],
[69,36,27,8],
[69,36,27,11],
[69,36,27,12],
[69,36,27,16],
[69,36,27,17],
[69,36,28,0],
[69,36,28,1],
[69,36,28,2],
[69,36,28,3],
[69,36,28,4],
[69,36,28,5],
[69,36,28,6],
[69,36,28,8],
[69,36,28,10],
[69,36,28,11],
[69,36,28,12],
[69,36,28,16],
[69,36,28,17],
[69,36,29,0],
[69,36,29,1],
[69,36,29,2],
[69,36,29,3],
[69,36,29,4],
[69,36,29,5],
[69,36,29,6],
[69,36,29,7],
[69,36,29,8],
[69,36,29,10],
[69,36,30,0],
[69,36,30,1],
[69,36,30,2],
[69,36,30,3],
[69,36,30,4],
[69,36,30,5],
[69,36,30,6],
[69,36,30,7],
[69,36,30,8],
[69,36,30,10],
[69,36,30,11],
[69,36,30,12],
[69,36,31,0],
[69,36,31,1],
[69,36,31,2],
[69,36,31,3],
[69,36,31,4],
[69,36,31,5],
[69,36,31,6],
[69,36,31,7],
[69,36,31,8],
[69,36,31,10],
[69,36,31,11],
[69,36,31,12],
[69,36,32,0],
[69,36,32,1],
[69,36,32,2],
[69,36,32,3],
[69,36,32,4],
[69,36,32,5],
[69,36,32,6],
[69,36,32,7],
[69,36,32,8],
[69,36,32,10],
[69,36,33,0],
[69,36,33,1],
[69,36,33,2],
[69,36,33,3],
[69,36,33,4],
[69,36,33,5],
[69,36,33,6],
[69,36,33,7],
[69,36,33,8],
[69,36,34,0],
[69,36,34,1],
[69,36,34,2],
[69,36,34,3],
[69,36,34,4],
[69,36,34,5],
[69,36,34,6],
[69,37,3,0],
[69,37,3,1],
[69,37,3,2],
[69,37,5,0],
[69,37,5,1],
[69,37,5,2],
[69,37,6,0],
[69,37,6,1],
[69,37,6,2],
[69,37,7,0],
[69,37,7,1],
[69,37,7,2],
[69,37,7,3],
[69,37,7,5],
[69,37,7,6],
[69,37,8,0],
[69,37,8,1],
[69,37,8,2],
[69,37,8,7],
[69,37,10,0],
[69,37,10,1],
[69,37,10,2],
[69,37,10,3],
[69,37,10,5],
[69,37,10,6],
[69,37,10,7],
[69,37,10,8],
[69,37,11,0],
[69,37,11,1],
[69,37,11,2],
[69,37,11,3],
[69,37,11,5],
[69,37,11,6],
[69,37,11,7],
[69,37,11,8],
[69,37,11,10],
[69,37,12,0],
[69,37,12,1],
[69,37,12,2],
[69,37,12,3],
[69,37,12,5],
[69,37,12,6],
[69,37,12,7],
[69,37,12,8],
[69,37,12,10],
[69,37,13,0],
[69,37,13,1],
[69,37,13,2],
[69,37,13,3],
[69,37,13,5],
[69,37,13,6],
[69,37,13,7],
[69,37,13,8],
[69,37,13,10],
[69,37,13,11],
[69,37,13,12],
[69,37,14,0],
[69,37,14,1],
[69,37,14,2],
[69,37,14,3],
[69,37,14,5],
[69,37,14,6],
[69,37,14,7],
[69,37,14,8],
[69,37,14,10],
[69,37,14,11],
[69,37,14,12],
[69,37,16,0],
[69,37,16,1],
[69,37,16,2],
[69,37,16,3],
[69,37,16,5],
[69,37,16,6],
[69,37,16,7],
[69,37,16,8],
[69,37,16,10],
[69,37,16,11],
[69,37,16,12],
[69,37,16,13],
[69,37,17,0],
[69,37,17,1],
[69,37,17,2],
[69,37,17,3],
[69,37,17,5],
[69,37,17,6],
[69,37,17,7],
[69,37,17,8],
[69,37,17,10],
[69,37,17,11],
[69,37,17,12],
[69,37,17,13],
[69,37,17,16],
[69,37,19,0],
[69,37,19,1],
[69,37,19,2],
[69,37,19,3],
[69,37,19,5],
[69,37,19,6],
[69,37,19,7],
[69,37,19,8],
[69,37,19,12],
[69,37,19,16],
[69,37,19,17],
[69,37,20,0],
[69,37,20,1],
[69,37,20,2],
[69,37,20,3],
[69,37,20,5],
[69,37,20,6],
[69,37,20,7],
[69,37,20,11],
[69,37,20,12],
[69,37,20,13],
[69,37,20,14],
[69,37,20,16],
[69,37,20,17],
[69,37,20,19],
[69,37,21,0],
[69,37,21,1],
[69,37,21,2],
[69,37,21,10],
[69,37,21,11],
[69,37,21,12],
[69,37,21,13],
[69,37,21,14],
[69,37,21,16],
[69,37,21,17],
[69,37,21,19],
[69,37,21,20],
[69,37,22,0],
[69,37,22,1],
[69,37,22,2],
[69,37,22,3],
[69,37,22,6],
[69,37,22,7],
[69,37,22,8],
[69,37,22,11],
[69,37,22,12],
[69,37,22,13],
[69,37,22,14],
[69,37,22,16],
[69,37,22,17],
[69,37,22,19],
[69,37,22,21],
[69,37,23,0],
[69,37,23,1],
[69,37,23,2],
[69,37,23,5],
[69,37,23,6],
[69,37,23,7],
[69,37,23,8],
[69,37,23,10],
[69,37,23,11],
[69,37,23,12],
[69,37,23,13],
[69,37,23,14],
[69,37,23,16],
[69,37,23,17],
[69,37,23,19],
[69,37,23,20],
[69,37,23,21],
[69,37,24,0],
[69,37,24,1],
[69,37,24,2],
[69,37,24,5],
[69,37,24,6],
[69,37,24,7],
[69,37,24,8],
[69,37,24,10],
[69,37,24,11],
[69,37,24,12],
[69,37,24,13],
[69,37,24,14],
[69,37,24,17],
[69,37,24,19],
[69,37,24,20],
[69,37,24,21],
[69,37,25,0],
[69,37,25,1],
[69,37,25,2],
[69,37,25,3],
[69,37,25,5],
[69,37,25,6],
[69,37,25,8],
[69,37,25,10],
[69,37,25,11],
[69,37,25,12],
[69,37,25,13],
[69,37,25,14],
[69,37,25,16],
[69,37,25,17],
[69,37,25,19],
[69,37,27,0],
[69,37,27,1],
[69,37,27,2],
[69,37,27,3],
[69,37,27,5],
[69,37,27,6],
[69,37,27,7],
[69,37,27,8],
[69,37,27,11],
[69,37,27,12],
[69,37,27,13],
[69,37,27,14],
[69,37,27,16],
[69,37,27,17],
[69,37,28,0],
[69,37,28,1],
[69,37,28,2],
[69,37,28,3],
[69,37,28,5],
[69,37,28,6],
[69,37,28,8],
[69,37,28,10],
[69,37,28,11],
[69,37,28,12],
[69,37,28,13],
[69,37,28,14],
[69,37,29,0],
[69,37,29,1],
[69,37,29,2],
[69,37,29,3],
[69,37,29,5],
[69,37,29,6],
[69,37,29,7],
[69,37,29,8],
[69,37,29,10],
[69,37,29,13],
[69,37,29,14],
[69,37,30,0],
[69,37,30,1],
[69,37,30,2],
[69,37,30,3],
[69,37,30,5],
[69,37,30,6],
[69,37,30,7],
[69,37,30,8],
[69,37,30,10],
[69,37,30,11],
[69,37,30,12],
[69,37,30,13],
[69,37,31,0],
[69,37,31,1],
[69,37,31,2],
[69,37,31,3],
[69,37,31,5],
[69,37,31,6],
[69,37,31,7],
[69,37,31,8],
[69,37,31,10],
[69,37,31,11],
[69,37,32,0],
[69,37,32,1],
[69,37,32,2],
[69,37,32,3],
[69,37,32,5],
[69,37,32,6],
[69,37,32,7],
[69,37,32,8],
[69,37,33,0],
[69,37,33,1],
[69,37,33,2],
[69,37,33,3],
[69,37,33,5],
[69,37,33,6],
[69,37,33,7],
[69,37,34,0],
[69,37,34,1],
[69,37,34,2],
[69,37,34,3],
[69,37,34,5],
[69,37,36,0],
[69,37,36,1],
[69,37,36,2],
[69,38,3,0],
[69,38,3,1],
[69,38,3,2],
[69,38,4,0],
[69,38,4,1],
[69,38,4,2],
[69,38,4,3],
[69,38,5,0],
[69,38,5,1],
[69,38,5,2],
[69,38,5,4],
[69,38,6,0],
[69,38,6,1],
[69,38,6,2],
[69,38,6,4],
[69,38,8,0],
[69,38,8,1],
[69,38,8,2],
[69,38,8,4],
[69,38,10,0],
[69,38,10,1],
[69,38,10,2],
[69,38,10,3],
[69,38,10,4],
[69,38,10,5],
[69,38,10,6],
[69,38,10,8],
[69,38,11,0],
[69,38,11,1],
[69,38,11,2],
[69,38,11,3],
[69,38,11,4],
[69,38,11,5],
[69,38,11,6],
[69,38,11,8],
[69,38,11,10],
[69,38,12,0],
[69,38,12,1],
[69,38,12,2],
[69,38,12,3],
[69,38,12,4],
[69,38,12,5],
[69,38,12,6],
[69,38,12,8],
[69,38,12,10],
[69,38,13,0],
[69,38,13,1],
[69,38,13,2],
[69,38,13,3],
[69,38,13,4],
[69,38,13,5],
[69,38,13,6],
[69,38,13,8],
[69,38,13,10],
[69,38,13,11],
[69,38,13,12],
[69,38,14,0],
[69,38,14,1],
[69,38,14,2],
[69,38,14,3],
[69,38,14,4],
[69,38,14,5],
[69,38,14,6],
[69,38,14,8],
[69,38,14,10],
[69,38,14,11],
[69,38,14,12],
[69,38,16,0],
[69,38,16,1],
[69,38,16,2],
[69,38,16,3],
[69,38,16,4],
[69,38,16,5],
[69,38,16,6],
[69,38,16,8],
[69,38,16,10],
[69,38,16,11],
[69,38,16,14],
[69,38,17,0],
[69,38,17,1],
[69,38,17,2],
[69,38,17,3],
[69,38,17,4],
[69,38,17,5],
[69,38,17,6],
[69,38,17,8],
[69,38,17,10],
[69,38,17,11],
[69,38,17,14],
[69,38,17,16],
[69,38,19,0],
[69,38,19,1],
[69,38,19,2],
[69,38,19,3],
[69,38,19,4],
[69,38,19,5],
[69,38,19,6],
[69,38,19,10],
[69,38,19,11],
[69,38,19,12],
[69,38,19,16],
[69,38,19,17],
[69,38,20,0],
[69,38,20,1],
[69,38,20,2],
[69,38,20,3],
[69,38,20,4],
[69,38,20,5],
[69,38,20,8],
[69,38,20,11],
[69,38,20,12],
[69,38,20,13],
[69,38,20,14],
[69,38,20,16],
[69,38,20,17],
[69,38,20,19],
[69,38,21,0],
[69,38,21,1],
[69,38,21,2],
[69,38,21,4],
[69,38,21,10],
[69,38,21,11],
[69,38,21,12],
[69,38,21,13],
[69,38,21,14],
[69,38,21,16],
[69,38,21,17],
[69,38,21,19],
[69,38,21,20],
[69,38,22,0],
[69,38,22,1],
[69,38,22,2],
[69,38,22,5],
[69,38,22,6],
[69,38,22,8],
[69,38,22,11],
[69,38,22,12],
[69,38,22,13],
[69,38,22,14],
[69,38,22,16],
[69,38,22,17],
[69,38,22,19],
[69,38,22,21],
[69,38,23,3],
[69,38,23,4],
[69,38,23,5],
[69,38,23,6],
[69,38,23,8],
[69,38,23,10],
[69,38,23,11],
[69,38,23,12],
[69,38,23,13],
[69,38,23,14],
[69,38,23,16],
[69,38,23,17],
[69,38,23,19],
[69,38,23,20],
[69,38,24,3],
[69,38,24,4],
[69,38,24,5],
[69,38,24,6],
[69,38,24,8],
[69,38,24,10],
[69,38,24,11],
[69,38,24,12],
[69,38,24,13],
[69,38,24,14],
[69,38,24,17],
[69,38,24,19],
[69,38,24,20],
[69,38,27,0],
[69,38,27,1],
[69,38,27,2],
[69,38,27,3],
[69,38,27,4],
[69,38,27,5],
[69,38,27,6],
[69,38,27,8],
[69,38,27,11],
[69,38,27,12],
[69,38,27,13],
[69,38,27,14],
[69,38,29,0],
[69,38,29,1],
[69,38,29,2],
[69,38,29,3],
[69,38,29,4],
[69,38,29,5],
[69,38,29,6],
[69,38,29,8],
[69,38,29,10],
[69,38,29,13],
[69,38,30,0],
[69,38,30,1],
[69,38,30,2],
[69,38,30,3],
[69,38,30,4],
[69,38,30,5],
[69,38,30,6],
[69,38,30,8],
[69,38,30,10],
[69,38,30,11],
[69,38,31,0],
[69,38,31,1],
[69,38,31,2],
[69,38,31,3],
[69,38,31,4],
[69,38,31,5],
[69,38,31,6],
[69,38,31,8],
[69,38,32,0],
[69,38,32,1],
[69,38,32,2],
[69,38,32,3],
[69,38,32,4],
[69,38,32,5],
[69,38,32,6],
[69,38,33,0],
[69,38,33,1],
[69,38,33,2],
[69,38,33,3],
[69,38,33,4],
[69,38,33,5],
[69,39,4,0],
[69,39,4,1],
[69,39,4,2],
[69,39,7,0],
[69,39,7,1],
[69,39,7,2],
[69,39,7,4],
[69,39,10,0],
[69,39,10,1],
[69,39,10,2],
[69,39,10,4],
[69,39,10,7],
[69,39,11,0],
[69,39,11,1],
[69,39,11,2],
[69,39,11,4],
[69,39,11,7],
[69,39,11,10],
[69,39,12,0],
[69,39,12,1],
[69,39,12,2],
[69,39,12,4],
[69,39,12,7],
[69,39,12,10],
[69,39,13,0],
[69,39,13,1],
[69,39,13,2],
[69,39,13,4],
[69,39,13,7],
[69,39,13,10],
[69,39,13,11],
[69,39,13,12],
[69,39,14,0],
[69,39,14,1],
[69,39,14,2],
[69,39,14,4],
[69,39,14,7],
[69,39,14,10],
[69,39,14,11],
[69,39,14,12],
[69,39,16,0],
[69,39,16,1],
[69,39,16,2],
[69,39,16,4],
[69,39,16,7],
[69,39,16,12],
[69,39,16,13],
[69,39,16,14],
[69,39,17,0],
[69,39,17,1],
[69,39,17,2],
[69,39,17,4],
[69,39,17,7],
[69,39,17,12],
[69,39,17,13],
[69,39,17,14],
[69,39,17,16],
[69,39,19,0],
[69,39,19,1],
[69,39,19,2],
[69,39,19,4],
[69,39,19,10],
[69,39,19,11],
[69,39,19,12],
[69,39,19,16],
[69,39,19,17],
[69,39,20,0],
[69,39,20,1],
[69,39,20,2],
[69,39,20,4],
[69,39,20,7],
[69,39,20,11],
[69,39,20,12],
[69,39,20,13],
[69,39,20,14],
[69,39,20,16],
[69,39,20,17],
[69,39,20,19],
[69,39,22,4],
[69,39,22,7],
[69,39,22,11],
[69,39,22,12],
[69,39,22,13],
[69,39,22,14],
[69,39,22,16],
[69,39,22,17],
[69,39,22,19],
[69,39,23,0],
[69,39,23,1],
[69,39,23,2],
[69,39,23,4],
[69,39,23,7],
[69,39,23,10],
[69,39,23,11],
[69,39,23,12],
[69,39,23,13],
[69,39,23,14],
[69,39,23,16],
[69,39,23,17],
[69,39,23,19],
[69,39,24,0],
[69,39,24,1],
[69,39,24,2],
[69,39,24,4],
[69,39,24,7],
[69,39,24,10],
[69,39,24,11],
[69,39,24,12],
[69,39,24,13],
[69,39,24,14],
[69,39,24,17],
[69,39,24,19],
[69,39,25,0],
[69,39,25,1],
[69,39,25,2],
[69,39,25,4],
[69,39,25,10],
[69,39,25,11],
[69,39,25,12],
[69,39,25,13],
[69,39,25,14],
[69,39,25,16],
[69,39,25,17],
[69,39,27,0],
[69,39,27,1],
[69,39,27,2],
[69,39,27,4],
[69,39,27,7],
[69,39,27,11],
[69,39,27,12],
[69,39,27,13],
[69,39,27,14],
[69,39,28,0],
[69,39,28,1],
[69,39,28,2],
[69,39,28,4],
[69,39,28,10],
[69,39,28,11],
[69,39,28,12],
[69,39,28,13],
[69,39,29,0],
[69,39,29,1],
[69,39,29,2],
[69,39,29,4],
[69,39,29,7],
[69,39,29,10],
[69,39,30,0],
[69,39,30,1],
[69,39,30,2],
[69,39,30,4],
[69,39,30,7],
[69,39,31,0],
[69,39,31,1],
[69,39,31,2],
[69,39,31,4],
[69,39,31,7],
[69,39,32,0],
[69,39,32,1],
[69,39,32,2],
[69,39,32,4],
[69,39,33,0],
[69,39,33,1],
[69,39,33,2],
[69,39,33,4],
[69,39,34,0],
[69,39,34,1],
[69,39,34,2],
[69,42,3,0],
[69,42,3,1],
[69,42,3,2],
[69,42,4,0],
[69,42,4,1],
[69,42,4,2],
[69,42,4,3],
[69,42,5,0],
[69,42,5,1],
[69,42,5,2],
[69,42,5,4],
[69,42,6,0],
[69,42,6,1],
[69,42,6,2],
[69,42,6,4],
[69,42,7,0],
[69,42,7,1],
[69,42,7,2],
[69,42,7,3],
[69,42,7,4],
[69,42,7,5],
[69,42,7,6],
[69,42,8,0],
[69,42,8,1],
[69,42,8,2],
[69,42,8,4],
[69,42,8,7],
[69,42,11,0],
[69,42,11,1],
[69,42,11,2],
[69,42,11,3],
[69,42,11,4],
[69,42,11,5],
[69,42,11,6],
[69,42,11,7],
[69,42,11,8],
[69,42,12,0],
[69,42,12,1],
[69,42,12,2],
[69,42,12,3],
[69,42,12,4],
[69,42,12,5],
[69,42,12,6],
[69,42,12,7],
[69,42,12,8],
[69,42,13,0],
[69,42,13,1],
[69,42,13,2],
[69,42,13,3],
[69,42,13,4],
[69,42,13,5],
[69,42,13,6],
[69,42,13,7],
[69,42,13,8],
[69,42,13,11],
[69,42,14,0],
[69,42,14,1],
[69,42,14,2],
[69,42,14,3],
[69,42,14,4],
[69,42,14,5],
[69,42,14,6],
[69,42,14,7],
[69,42,14,8],
[69,42,14,12],
[69,42,16,0],
[69,42,16,1],
[69,42,16,2],
[69,42,16,3],
[69,42,16,4],
[69,42,16,5],
[69,42,16,8],
[69,42,16,11],
[69,42,16,12],
[69,42,16,13],
[69,42,16,14],
[69,42,17,0],
[69,42,17,1],
[69,42,17,2],
[69,42,17,3],
[69,42,17,4],
[69,42,17,5],
[69,42,17,8],
[69,42,17,11],
[69,42,17,12],
[69,42,17,13],
[69,42,17,14],
[69,42,17,16],
[69,42,19,0],
[69,42,19,1],
[69,42,19,2],
[69,42,19,5],
[69,42,19,6],
[69,42,19,7],
[69,42,19,8],
[69,42,19,11],
[69,42,19,12],
[69,42,19,16],
[69,42,19,17],
[69,42,21,0],
[69,42,21,1],
[69,42,21,2],
[69,42,21,4],
[69,42,21,7],
[69,42,21,11],
[69,42,21,12],
[69,42,21,13],
[69,42,21,14],
[69,42,21,16],
[69,42,21,17],
[69,42,21,19],
[69,42,23,0],
[69,42,23,1],
[69,42,23,2],
[69,42,23,3],
[69,42,23,4],
[69,42,23,5],
[69,42,23,6],
[69,42,23,7],
[69,42,23,8],
[69,42,23,11],
[69,42,23,12],
[69,42,23,13],
[69,42,23,14],
[69,42,23,16],
[69,42,23,17],
[69,42,24,0],
[69,42,24,1],
[69,42,24,2],
[69,42,24,3],
[69,42,24,4],
[69,42,24,5],
[69,42,24,6],
[69,42,24,7],
[69,42,24,8],
[69,42,24,11],
[69,42,24,12],
[69,42,24,13],
[69,42,24,14],
[69,42,24,17],
[69,42,25,0],
[69,42,25,1],
[69,42,25,2],
[69,42,25,3],
[69,42,25,4],
[69,42,25,5],
[69,42,25,6],
[69,42,25,8],
[69,42,25,11],
[69,42,25,12],
[69,42,25,13],
[69,42,25,14],
[69,42,28,0],
[69,42,28,1],
[69,42,28,2],
[69,42,28,3],
[69,42,28,4],
[69,42,28,5],
[69,42,28,6],
[69,42,28,8],
[69,42,29,0],
[69,42,29,1],
[69,42,29,2],
[69,42,29,3],
[69,42,29,4],
[69,42,29,5],
[69,42,29,6],
[69,42,29,7],
[69,42,30,0],
[69,42,30,1],
[69,42,30,2],
[69,42,30,3],
[69,42,30,4],
[69,42,30,5],
[69,42,31,0],
[69,42,31,1],
[69,42,31,2],
[69,42,31,3],
[69,42,31,4],
[69,42,32,0],
[69,42,32,1],
[69,42,32,2],
[69,43,3,0],
[69,43,3,1],
[69,43,3,2],
[69,43,4,0],
[69,43,4,1],
[69,43,4,2],
[69,43,4,3],
[69,43,5,0],
[69,43,5,1],
[69,43,5,2],
[69,43,5,4],
[69,43,6,0],
[69,43,6,1],
[69,43,6,2],
[69,43,6,4],
[69,43,8,0],
[69,43,8,1],
[69,43,8,2],
[69,43,8,4],
[69,43,10,0],
[69,43,10,1],
[69,43,10,2],
[69,43,10,3],
[69,43,10,4],
[69,43,10,5],
[69,43,10,6],
[69,43,10,8],
[69,43,11,0],
[69,43,11,1],
[69,43,11,2],
[69,43,11,3],
[69,43,11,4],
[69,43,11,5],
[69,43,11,6],
[69,43,11,8],
[69,43,11,10],
[69,43,12,0],
[69,43,12,1],
[69,43,12,2],
[69,43,12,3],
[69,43,12,4],
[69,43,12,5],
[69,43,12,6],
[69,43,12,8],
[69,43,13,0],
[69,43,13,1],
[69,43,13,2],
[69,43,13,3],
[69,43,13,4],
[69,43,13,5],
[69,43,13,6],
[69,43,13,8],
[69,43,13,12],
[69,43,14,0],
[69,43,14,1],
[69,43,14,2],
[69,43,14,3],
[69,43,14,4],
[69,43,14,5],
[69,43,14,6],
[69,43,14,10],
[69,43,14,11],
[69,43,14,12],
[69,43,16,0],
[69,43,16,1],
[69,43,16,2],
[69,43,16,3],
[69,43,16,4],
[69,43,16,6],
[69,43,16,8],
[69,43,16,10],
[69,43,16,11],
[69,43,16,12],
[69,43,16,13],
[69,43,16,14],
[69,43,17,0],
[69,43,17,1],
[69,43,17,2],
[69,43,17,3],
[69,43,17,4],
[69,43,17,6],
[69,43,17,8],
[69,43,17,10],
[69,43,17,11],
[69,43,17,12],
[69,43,17,13],
[69,43,17,14],
[69,43,17,16],
[69,43,19,3],
[69,43,19,4],
[69,43,19,5],
[69,43,19,6],
[69,43,19,8],
[69,43,19,10],
[69,43,19,11],
[69,43,19,12],
[69,43,19,16],
[69,43,19,17],
[69,43,20,0],
[69,43,20,1],
[69,43,20,2],
[69,43,20,3],
[69,43,20,4],
[69,43,20,5],
[69,43,20,6],
[69,43,20,8],
[69,43,20,11],
[69,43,20,12],
[69,43,20,13],
[69,43,20,14],
[69,43,20,16],
[69,43,20,17],
[69,43,20,19],
[69,43,21,0],
[69,43,21,1],
[69,43,21,2],
[69,43,21,4],
[69,43,21,10],
[69,43,21,11],
[69,43,21,12],
[69,43,21,13],
[69,43,21,14],
[69,43,21,16],
[69,43,21,17],
[69,43,22,0],
[69,43,22,1],
[69,43,22,2],
[69,43,22,3],
[69,43,22,4],
[69,43,22,5],
[69,43,22,6],
[69,43,22,8],
[69,43,22,11],
[69,43,22,12],
[69,43,22,13],
[69,43,22,14],
[69,43,22,16],
[69,43,22,17],
[69,43,23,0],
[69,43,23,1],
[69,43,23,2],
[69,43,23,3],
[69,43,23,4],
[69,43,23,5],
[69,43,23,6],
[69,43,23,8],
[69,43,23,10],
[69,43,23,11],
[69,43,23,12],
[69,43,23,13],
[69,43,23,14],
[69,43,24,0],
[69,43,24,1],
[69,43,24,2],
[69,43,24,3],
[69,43,24,4],
[69,43,24,5],
[69,43,24,6],
[69,43,24,8],
[69,43,24,10],
[69,43,24,11],
[69,43,24,12],
[69,43,24,13],
[69,43,24,14],
[69,43,27,0],
[69,43,27,1],
[69,43,27,2],
[69,43,27,3],
[69,43,27,4],
[69,43,27,5],
[69,43,27,6],
[69,43,27,8],
[69,43,29,0],
[69,43,29,1],
[69,43,29,2],
[69,43,29,3],
[69,43,29,4],
[69,43,29,5],
[69,43,30,0],
[69,43,30,1],
[69,43,30,2],
[69,43,30,3],
[69,43,30,4],
[69,43,31,0],
[69,43,31,1],
[69,43,31,2],
[69,44,3,0],
[69,44,3,1],
[69,44,3,2],
[69,44,4,0],
[69,44,4,1],
[69,44,4,2],
[69,44,4,3],
[69,44,5,0],
[69,44,5,1],
[69,44,5,2],
[69,44,5,4],
[69,44,6,0],
[69,44,6,1],
[69,44,6,2],
[69,44,6,4],
[69,44,7,0],
[69,44,7,1],
[69,44,7,2],
[69,44,7,3],
[69,44,7,4],
[69,44,7,5],
[69,44,7,6],
[69,44,8,0],
[69,44,8,1],
[69,44,8,2],
[69,44,8,4],
[69,44,8,7],
[69,44,10,0],
[69,44,10,1],
[69,44,10,2],
[69,44,10,3],
[69,44,10,4],
[69,44,10,5],
[69,44,10,6],
[69,44,10,7],
[69,44,10,8],
[69,44,11,0],
[69,44,11,1],
[69,44,11,2],
[69,44,11,3],
[69,44,11,4],
[69,44,11,5],
[69,44,11,6],
[69,44,11,7],
[69,44,11,8],
[69,44,12,0],
[69,44,12,1],
[69,44,12,2],
[69,44,12,3],
[69,44,12,4],
[69,44,12,5],
[69,44,12,6],
[69,44,12,7],
[69,44,12,10],
[69,44,13,0],
[69,44,13,1],
[69,44,13,2],
[69,44,13,3],
[69,44,13,4],
[69,44,13,5],
[69,44,13,6],
[69,44,13,7],
[69,44,13,10],
[69,44,13,11],
[69,44,13,12],
[69,44,14,0],
[69,44,14,1],
[69,44,14,2],
[69,44,14,3],
[69,44,14,4],
[69,44,14,5],
[69,44,14,8],
[69,44,14,10],
[69,44,14,11],
[69,44,14,12],
[69,44,16,0],
[69,44,16,1],
[69,44,16,2],
[69,44,16,5],
[69,44,16,6],
[69,44,16,7],
[69,44,16,8],
[69,44,16,10],
[69,44,16,11],
[69,44,16,12],
[69,44,16,13],
[69,44,16,14],
[69,44,19,0],
[69,44,19,1],
[69,44,19,2],
[69,44,19,3],
[69,44,19,4],
[69,44,19,5],
[69,44,19,6],
[69,44,19,7],
[69,44,19,8],
[69,44,19,10],
[69,44,19,11],
[69,44,19,12],
[69,44,19,16],
[69,44,20,0],
[69,44,20,1],
[69,44,20,2],
[69,44,20,3],
[69,44,20,4],
[69,44,20,5],
[69,44,20,6],
[69,44,20,7],
[69,44,20,8],
[69,44,20,11],
[69,44,20,12],
[69,44,20,13],
[69,44,20,14],
[69,44,20,16],
[69,44,21,0],
[69,44,21,1],
[69,44,21,2],
[69,44,21,4],
[69,44,21,7],
[69,44,21,10],
[69,44,21,11],
[69,44,21,12],
[69,44,21,13],
[69,44,21,14],
[69,44,21,16],
[69,44,22,0],
[69,44,22,1],
[69,44,22,2],
[69,44,22,3],
[69,44,22,4],
[69,44,22,5],
[69,44,22,6],
[69,44,22,7],
[69,44,22,8],
[69,44,22,11],
[69,44,22,12],
[69,44,22,13],
[69,44,22,14],
[69,44,23,0],
[69,44,23,1],
[69,44,23,2],
[69,44,23,3],
[69,44,23,4],
[69,44,23,5],
[69,44,23,6],
[69,44,23,7],
[69,44,23,8],
[69,44,23,10],
[69,44,23,11],
[69,44,23,12],
[69,44,23,13],
[69,44,23,14],
[69,44,24,0],
[69,44,24,1],
[69,44,24,2],
[69,44,24,3],
[69,44,24,4],
[69,44,24,5],
[69,44,24,6],
[69,44,24,7],
[69,44,24,8],
[69,44,24,10],
[69,44,24,11],
[69,44,24,12],
[69,44,24,13],
[69,44,24,14],
[69,44,25,0],
[69,44,25,1],
[69,44,25,2],
[69,44,25,3],
[69,44,25,4],
[69,44,25,5],
[69,44,25,6],
[69,44,25,8],
[69,44,25,10],
[69,44,25,11],
[69,44,27,0],
[69,44,27,1],
[69,44,27,2],
[69,44,27,3],
[69,44,27,4],
[69,44,27,5],
[69,44,27,6],
[69,44,27,7],
[69,44,28,0],
[69,44,28,1],
[69,44,28,2],
[69,44,28,3],
[69,44,28,4],
[69,44,28,5],
[69,44,29,0],
[69,44,29,1],
[69,44,29,2],
[69,44,29,3],
[69,44,29,4],
[69,44,30,0],
[69,44,30,1],
[69,44,30,2],
[69,47,3,0],
[69,47,3,1],
[69,47,3,2],
[69,47,4,0],
[69,47,4,1],
[69,47,4,2],
[69,47,4,3],
[69,47,5,0],
[69,47,5,1],
[69,47,5,2],
[69,47,5,4],
[69,47,6,0],
[69,47,6,1],
[69,47,6,2],
[69,47,6,4],
[69,47,7,0],
[69,47,7,1],
[69,47,7,2],
[69,47,7,3],
[69,47,7,4],
[69,47,7,5],
[69,47,7,6],
[69,47,8,0],
[69,47,8,1],
[69,47,8,2],
[69,47,8,4],
[69,47,10,0],
[69,47,10,1],
[69,47,10,2],
[69,47,10,3],
[69,47,10,4],
[69,47,10,6],
[69,47,10,7],
[69,47,10,8],
[69,47,11,0],
[69,47,11,1],
[69,47,11,2],
[69,47,11,3],
[69,47,11,4],
[69,47,11,6],
[69,47,11,7],
[69,47,11,8],
[69,47,11,10],
[69,47,12,0],
[69,47,12,1],
[69,47,12,2],
[69,47,12,5],
[69,47,12,6],
[69,47,12,7],
[69,47,12,8],
[69,47,12,10],
[69,47,13,0],
[69,47,13,1],
[69,47,13,2],
[69,47,13,5],
[69,47,13,6],
[69,47,13,7],
[69,47,13,8],
[69,47,13,10],
[69,47,13,11],
[69,47,13,12],
[69,47,14,3],
[69,47,14,4],
[69,47,14,5],
[69,47,14,6],
[69,47,14,7],
[69,47,14,8],
[69,47,14,10],
[69,47,14,11],
[69,47,14,12],
[69,47,16,0],
[69,47,16,1],
[69,47,16,2],
[69,47,16,3],
[69,47,16,4],
[69,47,16,5],
[69,47,16,6],
[69,47,16,7],
[69,47,16,8],
[69,47,16,10],
[69,47,16,11],
[69,47,16,12],
[69,47,16,13],
[69,47,16,14],
[69,47,17,0],
[69,47,17,1],
[69,47,17,2],
[69,47,17,3],
[69,47,17,4],
[69,47,17,5],
[69,47,17,6],
[69,47,17,7],
[69,47,17,8],
[69,47,17,10],
[69,47,17,11],
[69,47,17,12],
[69,47,17,13],
[69,47,17,14],
[69,47,17,16],
[69,47,19,0],
[69,47,19,1],
[69,47,19,2],
[69,47,19,3],
[69,47,19,4],
[69,47,19,5],
[69,47,19,6],
[69,47,19,7],
[69,47,19,8],
[69,47,19,10],
[69,47,19,11],
[69,47,19,12],
[69,47,20,0],
[69,47,20,1],
[69,47,20,2],
[69,47,20,3],
[69,47,20,4],
[69,47,20,5],
[69,47,20,6],
[69,47,20,7],
[69,47,20,8],
[69,47,20,11],
[69,47,20,12],
[69,47,20,13],
[69,47,20,14],
[69,47,21,0],
[69,47,21,1],
[69,47,21,2],
[69,47,21,4],
[69,47,21,7],
[69,47,21,10],
[69,47,21,11],
[69,47,21,12],
[69,47,21,13],
[69,47,22,0],
[69,47,22,1],
[69,47,22,2],
[69,47,22,3],
[69,47,22,4],
[69,47,22,5],
[69,47,22,6],
[69,47,22,7],
[69,47,22,8],
[69,47,22,11],
[69,47,24,0],
[69,47,24,1],
[69,47,24,2],
[69,47,24,3],
[69,47,24,4],
[69,47,24,5],
[69,47,24,6],
[69,47,24,7],
[69,47,24,8],
[69,47,25,0],
[69,47,25,1],
[69,47,25,2],
[69,47,25,3],
[69,47,25,4],
[69,47,25,5],
[69,47,27,0],
[69,47,27,1],
[69,47,27,2],
[69,48,3,0],
[69,48,3,1],
[69,48,3,2],
[69,48,4,0],
[69,48,4,1],
[69,48,4,2],
[69,48,4,3],
[69,48,5,0],
[69,48,5,1],
[69,48,5,2],
[69,48,5,4],
[69,48,6,0],
[69,48,6,1],
[69,48,6,2],
[69,48,6,4],
[69,48,7,0],
[69,48,7,1],
[69,48,7,2],
[69,48,7,3],
[69,48,7,4],
[69,48,7,5],
[69,48,8,0],
[69,48,8,1],
[69,48,8,2],
[69,48,8,4],
[69,48,8,7],
[69,48,10,0],
[69,48,10,1],
[69,48,10,2],
[69,48,10,5],
[69,48,10,6],
[69,48,10,7],
[69,48,10,8],
[69,48,13,3],
[69,48,13,4],
[69,48,13,5],
[69,48,13,6],
[69,48,13,7],
[69,48,13,8],
[69,48,13,10],
[69,48,14,0],
[69,48,14,1],
[69,48,14,2],
[69,48,14,3],
[69,48,14,4],
[69,48,14,5],
[69,48,14,6],
[69,48,14,7],
[69,48,14,8],
[69,48,14,10],
[69,48,16,0],
[69,48,16,1],
[69,48,16,2],
[69,48,16,3],
[69,48,16,4],
[69,48,16,5],
[69,48,16,6],
[69,48,16,7],
[69,48,16,8],
[69,48,16,10],
[69,48,16,13],
[69,48,16,14],
[69,48,17,0],
[69,48,17,1],
[69,48,17,2],
[69,48,17,3],
[69,48,17,4],
[69,48,17,5],
[69,48,17,6],
[69,48,17,7],
[69,48,17,8],
[69,48,17,10],
[69,48,17,13],
[69,48,17,14],
[69,48,17,16],
[69,48,19,0],
[69,48,19,1],
[69,48,19,2],
[69,48,19,3],
[69,48,19,4],
[69,48,19,5],
[69,48,19,6],
[69,48,19,7],
[69,48,19,8],
[69,48,19,10],
[69,48,20,0],
[69,48,20,1],
[69,48,20,2],
[69,48,20,3],
[69,48,20,4],
[69,48,20,5],
[69,48,20,6],
[69,48,20,7],
[69,48,20,8],
[69,48,20,13],
[69,48,21,0],
[69,48,21,1],
[69,48,21,2],
[69,48,21,4],
[69,48,21,7],
[69,48,21,10],
[69,48,22,0],
[69,48,22,1],
[69,48,22,2],
[69,48,22,3],
[69,48,22,4],
[69,48,22,5],
[69,48,22,6],
[69,48,22,7],
[69,48,22,8],
[69,48,23,0],
[69,48,23,1],
[69,48,23,2],
[69,48,23,3],
[69,48,23,4],
[69,48,23,5],
[69,48,23,6],
[69,48,23,7],
[69,48,24,0],
[69,48,24,1],
[69,48,24,2],
[69,48,24,3],
[69,48,24,4],
[69,48,24,5],
[69,48,24,6],
[69,48,24,7],
[69,48,25,0],
[69,48,25,1],
[69,48,25,2],
[69,48,25,3],
[69,48,25,4],
[69,51,4,0],
[69,51,4,1],
[69,51,4,2],
[69,51,7,0],
[69,51,7,1],
[69,51,7,2],
[69,51,7,4],
[69,51,10,0],
[69,51,10,1],
[69,51,10,2],
[69,51,10,7],
[69,51,11,0],
[69,51,11,1],
[69,51,11,2],
[69,51,11,7],
[69,51,11,10],
[69,51,12,4],
[69,51,12,7],
[69,51,12,10],
[69,51,13,4],
[69,51,13,7],
[69,51,13,10],
[69,51,13,11],
[69,51,13,12],
[69,51,14,0],
[69,51,14,1],
[69,51,14,2],
[69,51,14,4],
[69,51,14,7],
[69,51,14,10],
[69,51,14,11],
[69,51,14,12],
[69,51,16,0],
[69,51,16,1],
[69,51,16,2],
[69,51,16,4],
[69,51,16,7],
[69,51,16,10],
[69,51,16,11],
[69,51,16,12],
[69,51,16,13],
[69,51,16,14],
[69,51,17,0],
[69,51,17,1],
[69,51,17,2],
[69,51,17,4],
[69,51,17,7],
[69,51,17,10],
[69,51,17,11],
[69,51,17,12],
[69,51,17,13],
[69,51,17,14],
[69,51,17,16],
[69,51,19,0],
[69,51,19,1],
[69,51,19,2],
[69,51,19,4],
[69,51,19,7],
[69,51,19,10],
[69,51,19,11],
[69,51,19,12],
[69,51,20,0],
[69,51,20,1],
[69,51,20,2],
[69,51,20,4],
[69,51,20,7],
[69,51,20,11],
[69,51,20,12],
[69,51,20,13],
[69,51,22,0],
[69,51,22,1],
[69,51,22,2],
[69,51,22,4],
[69,51,22,7],
[69,51,23,0],
[69,51,23,1],
[69,51,23,2],
[69,51,23,4],
[69,51,23,7],
[69,51,24,0],
[69,51,24,1],
[69,51,24,2],
[69,51,24,4],
[69,51,24,7],
[69,51,25,0],
[69,51,25,1],
[69,51,25,2],
[69,51,25,4],
[69,53,3,0],
[69,53,3,1],
[69,53,3,2],
[69,53,4,0],
[69,53,4,1],
[69,53,4,2],
[69,53,4,3],
[69,53,5,0],
[69,53,5,1],
[69,53,5,2],
[69,53,5,4],
[69,53,6,0],
[69,53,6,1],
[69,53,6,2],
[69,53,6,4],
[69,53,7,0],
[69,53,7,1],
[69,53,7,2],
[69,53,7,3],
[69,53,7,4],
[69,53,7,5],
[69,53,8,0],
[69,53,8,1],
[69,53,8,2],
[69,53,8,4],
[69,53,8,7],
[69,53,11,0],
[69,53,11,1],
[69,53,11,2],
[69,53,11,5],
[69,53,11,6],
[69,53,11,7],
[69,53,11,8],
[69,53,12,3],
[69,53,12,4],
[69,53,12,5],
[69,53,12,6],
[69,53,12,7],
[69,53,12,8],
[69,53,13,3],
[69,53,13,4],
[69,53,13,5],
[69,53,13,6],
[69,53,13,7],
[69,53,13,8],
[69,53,13,11],
[69,53,13,12],
[69,53,14,0],
[69,53,14,1],
[69,53,14,2],
[69,53,14,3],
[69,53,14,4],
[69,53,14,5],
[69,53,14,6],
[69,53,14,7],
[69,53,14,8],
[69,53,14,11],
[69,53,14,12],
[69,53,16,0],
[69,53,16,1],
[69,53,16,2],
[69,53,16,3],
[69,53,16,4],
[69,53,16,5],
[69,53,16,6],
[69,53,16,7],
[69,53,16,8],
[69,53,16,11],
[69,53,16,12],
[69,53,16,13],
[69,53,16,14],
[69,53,17,0],
[69,53,17,1],
[69,53,17,2],
[69,53,17,3],
[69,53,17,4],
[69,53,17,5],
[69,53,17,6],
[69,53,17,7],
[69,53,17,8],
[69,53,17,11],
[69,53,17,12],
[69,53,17,13],
[69,53,17,14],
[69,53,17,16],
[69,53,19,0],
[69,53,19,1],
[69,53,19,2],
[69,53,19,3],
[69,53,19,4],
[69,53,19,5],
[69,53,19,6],
[69,53,19,7],
[69,53,19,8],
[69,53,19,11],
[69,53,19,12],
[69,53,21,0],
[69,53,21,1],
[69,53,21,2],
[69,53,21,4],
[69,53,21,7],
[69,53,21,11],
[69,53,23,0],
[69,53,23,1],
[69,53,23,2],
[69,53,23,3],
[69,53,23,4],
[69,53,23,5],
[69,53,23,6],
[69,53,23,7],
[69,53,24,0],
[69,53,24,1],
[69,53,24,2],
[69,53,24,3],
[69,53,24,4],
[69,53,24,5],
[69,53,24,6],
[69,53,24,7],
[69,53,25,0],
[69,53,25,1],
[69,53,25,2],
[69,53,25,3],
[69,53,25,4],
[69,54,3,0],
[69,54,3,1],
[69,54,3,2],
[69,54,4,0],
[69,54,4,1],
[69,54,4,2],
[69,54,4,3],
[69,54,5,0],
[69,54,5,1],
[69,54,5,2],
[69,54,5,4],
[69,54,6,0],
[69,54,6,1],
[69,54,6,2],
[69,54,6,4],
[69,54,7,0],
[69,54,7,1],
[69,54,7,2],
[69,54,7,3],
[69,54,7,4],
[69,54,7,5],
[69,54,8,0],
[69,54,8,1],
[69,54,8,2],
[69,54,8,4],
[69,54,8,7],
[69,54,11,0],
[69,54,11,1],
[69,54,11,2],
[69,54,11,5],
[69,54,11,6],
[69,54,11,7],
[69,54,11,8],
[69,54,12,3],
[69,54,12,4],
[69,54,12,5],
[69,54,12,6],
[69,54,12,7],
[69,54,12,8],
[69,54,13,3],
[69,54,13,4],
[69,54,13,5],
[69,54,13,6],
[69,54,13,7],
[69,54,13,8],
[69,54,13,11],
[69,54,13,12],
[69,54,14,0],
[69,54,14,1],
[69,54,14,2],
[69,54,14,3],
[69,54,14,4],
[69,54,14,5],
[69,54,14,6],
[69,54,14,7],
[69,54,14,8],
[69,54,14,11],
[69,54,14,12],
[69,54,16,0],
[69,54,16,1],
[69,54,16,2],
[69,54,16,3],
[69,54,16,4],
[69,54,16,5],
[69,54,16,6],
[69,54,16,7],
[69,54,16,8],
[69,54,16,11],
[69,54,16,12],
[69,54,16,13],
[69,54,16,14],
[69,54,17,0],
[69,54,17,1],
[69,54,17,2],
[69,54,17,3],
[69,54,17,4],
[69,54,17,5],
[69,54,17,6],
[69,54,17,7],
[69,54,17,8],
[69,54,17,11],
[69,54,17,12],
[69,54,17,13],
[69,54,17,14],
[69,54,17,16],
[69,54,19,0],
[69,54,19,1],
[69,54,19,2],
[69,54,19,3],
[69,54,19,4],
[69,54,19,5],
[69,54,19,6],
[69,54,19,7],
[69,54,19,8],
[69,54,19,11],
[69,54,19,12],
[69,54,21,0],
[69,54,21,1],
[69,54,21,2],
[69,54,21,4],
[69,54,21,7],
[69,54,21,11],
[69,54,23,0],
[69,54,23,1],
[69,54,23,2],
[69,54,23,3],
[69,54,23,4],
[69,54,23,5],
[69,54,23,6],
[69,54,23,7],
[69,54,24,0],
[69,54,24,1],
[69,54,24,2],
[69,54,24,3],
[69,54,24,4],
[69,54,24,5],
[69,54,24,6],
[69,54,24,7],
[69,54,25,0],
[69,54,25,1],
[69,54,25,2],
[69,54,25,3],
[69,54,25,4],
[69,55,3,0],
[69,55,3,1],
[69,55,3,2],
[69,55,4,0],
[69,55,4,1],
[69,55,4,2],
[69,55,4,3],
[69,55,5,0],
[69,55,5,1],
[69,55,5,2],
[69,55,5,4],
[69,55,6,0],
[69,55,6,1],
[69,55,6,2],
[69,55,6,4],
[69,55,7,0],
[69,55,7,1],
[69,55,7,2],
[69,55,7,3],
[69,55,7,4],
[69,55,7,5],
[69,55,8,0],
[69,55,8,1],
[69,55,8,2],
[69,55,8,4],
[69,55,8,7],
[69,55,11,0],
[69,55,11,1],
[69,55,11,2],
[69,55,11,5],
[69,55,11,6],
[69,55,11,7],
[69,55,11,8],
[69,55,12,3],
[69,55,12,4],
[69,55,12,5],
[69,55,12,6],
[69,55,12,7],
[69,55,12,8],
[69,55,13,3],
[69,55,13,4],
[69,55,13,5],
[69,55,13,6],
[69,55,13,7],
[69,55,13,8],
[69,55,13,11],
[69,55,13,12],
[69,55,14,0],
[69,55,14,1],
[69,55,14,2],
[69,55,14,3],
[69,55,14,4],
[69,55,14,5],
[69,55,14,6],
[69,55,14,7],
[69,55,14,8],
[69,55,14,11],
[69,55,14,12],
[69,55,16,0],
[69,55,16,1],
[69,55,16,2],
[69,55,16,3],
[69,55,16,4],
[69,55,16,5],
[69,55,16,6],
[69,55,16,7],
[69,55,16,8],
[69,55,16,11],
[69,55,16,12],
[69,55,16,13],
[69,55,16,14],
[69,55,17,0],
[69,55,17,1],
[69,55,17,2],
[69,55,17,3],
[69,55,17,4],
[69,55,17,5],
[69,55,17,6],
[69,55,17,7],
[69,55,17,8],
[69,55,17,11],
[69,55,17,12],
[69,55,17,13],
[69,55,17,14],
[69,55,17,16],
[69,55,19,0],
[69,55,19,1],
[69,55,19,2],
[69,55,19,3],
[69,55,19,4],
[69,55,19,5],
[69,55,19,6],
[69,55,19,7],
[69,55,19,8],
[69,55,19,11],
[69,55,19,12],
[69,55,21,0],
[69,55,21,1],
[69,55,21,2],
[69,55,21,4],
[69,55,21,7],
[69,55,21,11],
[69,55,23,0],
[69,55,23,1],
[69,55,23,2],
[69,55,23,3],
[69,55,23,4],
[69,55,23,5],
[69,55,23,6],
[69,55,23,7],
[69,55,24,0],
[69,55,24,1],
[69,55,24,2],
[69,55,24,3],
[69,55,24,4],
[69,55,24,5],
[69,55,24,6],
[69,55,24,7],
[69,55,25,0],
[69,55,25,1],
[69,55,25,2],
[69,55,25,3],
[69,55,25,4],
[69,57,3,0],
[69,57,3,1],
[69,57,3,2],
[69,57,4,0],
[69,57,4,1],
[69,57,4,2],
[69,57,4,3],
[69,57,5,0],
[69,57,5,1],
[69,57,5,2],
[69,57,5,4],
[69,57,6,0],
[69,57,6,1],
[69,57,6,2],
[69,57,6,4],
[69,57,7,0],
[69,57,7,1],
[69,57,7,2],
[69,57,7,3],
[69,57,7,4],
[69,57,7,6],
[69,57,8,0],
[69,57,8,1],
[69,57,8,2],
[69,57,8,7],
[69,57,10,3],
[69,57,10,4],
[69,57,10,5],
[69,57,10,6],
[69,57,10,7],
[69,57,10,8],
[69,57,11,3],
[69,57,11,4],
[69,57,11,5],
[69,57,11,6],
[69,57,11,7],
[69,57,11,8],
[69,57,11,10],
[69,57,12,0],
[69,57,12,1],
[69,57,12,2],
[69,57,12,3],
[69,57,12,4],
[69,57,12,5],
[69,57,12,6],
[69,57,12,7],
[69,57,12,8],
[69,57,12,10],
[69,57,13,0],
[69,57,13,1],
[69,57,13,2],
[69,57,13,3],
[69,57,13,4],
[69,57,13,5],
[69,57,13,6],
[69,57,13,7],
[69,57,13,8],
[69,57,13,10],
[69,57,13,11],
[69,57,13,12],
[69,57,14,0],
[69,57,14,1],
[69,57,14,2],
[69,57,14,3],
[69,57,14,4],
[69,57,14,5],
[69,57,14,6],
[69,57,14,7],
[69,57,14,8],
[69,57,14,10],
[69,57,14,11],
[69,57,14,12],
[69,57,16,0],
[69,57,16,1],
[69,57,16,2],
[69,57,16,3],
[69,57,16,4],
[69,57,16,5],
[69,57,16,6],
[69,57,16,7],
[69,57,16,8],
[69,57,16,10],
[69,57,16,11],
[69,57,16,12],
[69,57,16,13],
[69,57,16,14],
[69,57,17,0],
[69,57,17,1],
[69,57,17,2],
[69,57,17,3],
[69,57,17,4],
[69,57,17,5],
[69,57,17,6],
[69,57,17,7],
[69,57,17,8],
[69,57,17,10],
[69,57,17,11],
[69,57,17,12],
[69,57,17,13],
[69,57,17,14],
[69,57,19,0],
[69,57,19,1],
[69,57,19,2],
[69,57,19,3],
[69,57,19,4],
[69,57,19,5],
[69,57,19,6],
[69,57,19,7],
[69,57,19,8],
[69,57,19,10],
[69,57,19,11],
[69,57,19,12],
[69,57,20,0],
[69,57,20,1],
[69,57,20,2],
[69,57,20,3],
[69,57,20,4],
[69,57,20,5],
[69,57,20,6],
[69,57,20,7],
[69,57,20,8],
[69,57,20,11],
[69,57,21,0],
[69,57,21,1],
[69,57,21,2],
[69,57,21,4],
[69,57,21,7],
[69,57,22,0],
[69,57,22,1],
[69,57,22,2],
[69,57,22,3],
[69,57,22,4],
[69,57,22,5],
[69,57,22,6],
[69,57,22,7],
[69,57,24,0],
[69,57,24,1],
[69,57,24,2],
[69,57,24,3],
[69,57,24,4],
[69,57,24,5],
[69,57,25,0],
[69,57,25,1],
[69,57,25,2],
[69,58,3,0],
[69,58,3,1],
[69,58,3,2],
[69,58,4,0],
[69,58,4,1],
[69,58,4,2],
[69,58,4,3],
[69,58,5,0],
[69,58,5,1],
[69,58,5,2],
[69,58,5,4],
[69,58,6,0],
[69,58,6,1],
[69,58,6,2],
[69,58,6,4],
[69,58,7,0],
[69,58,7,1],
[69,58,7,2],
[69,58,7,3],
[69,58,7,4],
[69,58,7,6],
[69,58,8,0],
[69,58,8,1],
[69,58,8,2],
[69,58,8,7],
[69,58,10,3],
[69,58,10,4],
[69,58,10,5],
[69,58,10,6],
[69,58,10,7],
[69,58,10,8],
[69,58,11,3],
[69,58,11,4],
[69,58,11,5],
[69,58,11,6],
[69,58,11,7],
[69,58,11,8],
[69,58,11,10],
[69,58,12,0],
[69,58,12,1],
[69,58,12,2],
[69,58,12,3],
[69,58,12,4],
[69,58,12,5],
[69,58,12,6],
[69,58,12,7],
[69,58,12,8],
[69,58,12,10],
[69,58,13,0],
[69,58,13,1],
[69,58,13,2],
[69,58,13,3],
[69,58,13,4],
[69,58,13,5],
[69,58,13,6],
[69,58,13,7],
[69,58,13,8],
[69,58,13,10],
[69,58,13,11],
[69,58,13,12],
[69,58,14,0],
[69,58,14,1],
[69,58,14,2],
[69,58,14,3],
[69,58,14,4],
[69,58,14,5],
[69,58,14,6],
[69,58,14,7],
[69,58,14,8],
[69,58,14,10],
[69,58,14,11],
[69,58,14,12],
[69,58,17,0],
[69,58,17,1],
[69,58,17,2],
[69,58,17,3],
[69,58,17,4],
[69,58,17,5],
[69,58,17,6],
[69,58,17,7],
[69,58,17,8],
[69,58,17,10],
[69,58,17,11],
[69,58,17,12],
[69,58,17,13],
[69,58,17,14],
[69,58,19,0],
[69,58,19,1],
[69,58,19,2],
[69,58,19,3],
[69,58,19,4],
[69,58,19,5],
[69,58,19,6],
[69,58,19,7],
[69,58,19,8],
[69,58,19,10],
[69,58,19,11],
[69,58,19,12],
[69,58,20,0],
[69,58,20,1],
[69,58,20,2],
[69,58,20,3],
[69,58,20,4],
[69,58,20,5],
[69,58,20,6],
[69,58,20,7],
[69,58,20,8],
[69,58,20,11],
[69,58,21,0],
[69,58,21,1],
[69,58,21,2],
[69,58,21,4],
[69,58,21,7],
[69,58,22,0],
[69,58,22,1],
[69,58,22,2],
[69,58,22,3],
[69,58,22,4],
[69,58,22,5],
[69,58,22,6],
[69,58,22,7],
[69,58,23,0],
[69,58,23,1],
[69,58,23,2],
[69,58,23,3],
[69,58,23,4],
[69,58,23,5],
[69,58,25,0],
[69,58,25,1],
[69,58,25,2],
[69,60,3,0],
[69,60,3,1],
[69,60,3,2],
[69,60,4,0],
[69,60,4,1],
[69,60,4,2],
[69,60,4,3],
[69,60,5,0],
[69,60,5,1],
[69,60,5,2],
[69,60,6,4],
[69,60,7,3],
[69,60,7,4],
[69,60,7,5],
[69,60,7,6],
[69,60,8,0],
[69,60,8,1],
[69,60,8,2],
[69,60,8,4],
[69,60,8,7],
[69,60,10,0],
[69,60,10,1],
[69,60,10,2],
[69,60,10,3],
[69,60,10,4],
[69,60,10,5],
[69,60,10,6],
[69,60,10,7],
[69,60,10,8],
[69,60,11,0],
[69,60,11,1],
[69,60,11,2],
[69,60,11,3],
[69,60,11,4],
[69,60,11,5],
[69,60,11,6],
[69,60,11,7],
[69,60,11,8],
[69,60,11,10],
[69,60,12,0],
[69,60,12,1],
[69,60,12,2],
[69,60,12,3],
[69,60,12,4],
[69,60,12,5],
[69,60,12,6],
[69,60,12,7],
[69,60,12,8],
[69,60,12,10],
[69,60,16,0],
[69,60,16,1],
[69,60,16,2],
[69,60,16,3],
[69,60,16,4],
[69,60,16,5],
[69,60,16,6],
[69,60,16,7],
[69,60,16,8],
[69,60,16,10],
[69,60,16,11],
[69,60,16,12],
[69,60,17,0],
[69,60,17,1],
[69,60,17,2],
[69,60,17,3],
[69,60,17,4],
[69,60,17,5],
[69,60,17,6],
[69,60,17,7],
[69,60,17,8],
[69,60,17,10],
[69,60,17,11],
[69,60,17,12],
[69,60,20,0],
[69,60,20,1],
[69,60,20,2],
[69,60,20,3],
[69,60,20,4],
[69,60,20,5],
[69,60,20,6],
[69,60,20,7],
[69,60,21,0],
[69,60,21,1],
[69,60,21,2],
[69,60,21,4],
[69,60,22,0],
[69,60,22,1],
[69,60,22,2],
[69,60,22,3],
[69,60,22,4],
[69,60,23,0],
[69,60,23,1],
[69,60,23,2],
[69,60,24,0],
[69,60,24,1],
[69,60,24,2],
[69,61,3,0],
[69,61,3,1],
[69,61,3,2],
[69,61,6,0],
[69,61,6,1],
[69,61,6,2],
[69,61,7,0],
[69,61,7,1],
[69,61,7,2],
[69,61,7,3],
[69,61,7,5],
[69,61,7,6],
[69,61,8,0],
[69,61,8,1],
[69,61,8,2],
[69,61,8,7],
[69,61,10,0],
[69,61,10,1],
[69,61,10,2],
[69,61,10,3],
[69,61,10,5],
[69,61,10,6],
[69,61,10,7],
[69,61,10,8],
[69,61,11,0],
[69,61,11,1],
[69,61,11,2],
[69,61,11,3],
[69,61,11,5],
[69,61,11,6],
[69,61,11,7],
[69,61,11,8],
[69,61,11,10],
[69,61,12,0],
[69,61,12,1],
[69,61,12,2],
[69,61,12,3],
[69,61,12,5],
[69,61,12,6],
[69,61,12,7],
[69,61,12,8],
[69,61,12,10],
[69,61,13,0],
[69,61,13,1],
[69,61,13,2],
[69,61,13,3],
[69,61,13,5],
[69,61,13,6],
[69,61,13,7],
[69,61,13,8],
[69,61,13,10],
[69,61,13,11],
[69,61,13,12],
[69,61,14,0],
[69,61,14,1],
[69,61,14,2],
[69,61,14,3],
[69,61,14,5],
[69,61,14,6],
[69,61,14,7],
[69,61,14,8],
[69,61,14,10],
[69,61,14,11],
[69,61,14,12],
[69,61,16,0],
[69,61,16,1],
[69,61,16,2],
[69,61,16,3],
[69,61,16,5],
[69,61,16,6],
[69,61,16,7],
[69,61,16,8],
[69,61,16,10],
[69,61,16,11],
[69,61,17,0],
[69,61,17,1],
[69,61,17,2],
[69,61,17,3],
[69,61,17,5],
[69,61,17,6],
[69,61,17,7],
[69,61,17,8],
[69,61,17,10],
[69,61,17,11],
[69,61,19,0],
[69,61,19,1],
[69,61,19,2],
[69,61,19,3],
[69,61,19,5],
[69,61,19,6],
[69,61,19,7],
[69,61,20,0],
[69,61,20,1],
[69,61,20,2],
[69,61,20,3],
[69,61,20,5],
[69,61,21,0],
[69,61,21,1],
[69,61,21,2],
[69,61,22,0],
[69,61,22,1],
[69,61,22,2],
[69,62,3,0],
[69,62,3,1],
[69,62,3,2],
[69,62,4,0],
[69,62,4,1],
[69,62,4,2],
[69,62,5,4],
[69,62,6,0],
[69,62,6,1],
[69,62,6,2],
[69,62,6,4],
[69,62,8,0],
[69,62,8,1],
[69,62,8,2],
[69,62,8,4],
[69,62,10,0],
[69,62,10,1],
[69,62,10,2],
[69,62,10,3],
[69,62,10,4],
[69,62,10,5],
[69,62,10,6],
[69,62,10,8],
[69,62,11,0],
[69,62,11,1],
[69,62,11,2],
[69,62,11,3],
[69,62,11,4],
[69,62,11,5],
[69,62,11,6],
[69,62,11,8],
[69,62,11,10],
[69,62,12,0],
[69,62,12,1],
[69,62,12,2],
[69,62,12,3],
[69,62,12,4],
[69,62,12,5],
[69,62,12,6],
[69,62,12,8],
[69,62,12,10],
[69,62,13,0],
[69,62,13,1],
[69,62,13,2],
[69,62,13,3],
[69,62,13,4],
[69,62,13,5],
[69,62,13,6],
[69,62,13,8],
[69,62,13,10],
[69,62,13,11],
[69,62,13,12],
[69,62,14,0],
[69,62,14,1],
[69,62,14,2],
[69,62,14,3],
[69,62,14,4],
[69,62,14,5],
[69,62,14,6],
[69,62,14,8],
[69,62,14,10],
[69,62,14,11],
[69,62,14,12],
[69,62,16,0],
[69,62,16,1],
[69,62,16,2],
[69,62,16,3],
[69,62,16,4],
[69,62,16,5],
[69,62,16,6],
[69,62,16,8],
[69,62,16,10],
[69,62,16,11],
[69,62,17,0],
[69,62,17,1],
[69,62,17,2],
[69,62,17,3],
[69,62,17,4],
[69,62,17,5],
[69,62,17,6],
[69,62,17,8],
[69,62,17,10],
[69,62,17,11],
[69,62,19,0],
[69,62,19,1],
[69,62,19,2],
[69,62,19,3],
[69,62,19,4],
[69,62,19,5],
[69,62,19,6],
[69,62,20,0],
[69,62,20,1],
[69,62,20,2],
[69,62,20,3],
[69,62,20,4],
[69,62,20,5],
[69,62,21,0],
[69,62,21,1],
[69,62,21,2],
[69,62,21,4],
[69,62,22,0],
[69,62,22,1],
[69,62,22,2],
[69,64,3,0],
[69,64,3,1],
[69,64,3,2],
[69,64,5,0],
[69,64,5,1],
[69,64,5,2],
[69,64,6,0],
[69,64,6,1],
[69,64,6,2],
[69,64,7,0],
[69,64,7,1],
[69,64,7,2],
[69,64,7,3],
[69,64,7,5],
[69,64,7,6],
[69,64,8,0],
[69,64,8,1],
[69,64,8,2],
[69,64,8,7],
[69,64,10,0],
[69,64,10,1],
[69,64,10,2],
[69,64,10,3],
[69,64,10,5],
[69,64,10,6],
[69,64,10,7],
[69,64,10,8],
[69,64,11,0],
[69,64,11,1],
[69,64,11,2],
[69,64,11,3],
[69,64,11,5],
[69,64,11,6],
[69,64,11,7],
[69,64,11,8],
[69,64,11,10],
[69,64,12,0],
[69,64,12,1],
[69,64,12,2],
[69,64,12,3],
[69,64,12,5],
[69,64,12,6],
[69,64,12,7],
[69,64,12,8],
[69,64,12,10],
[69,64,13,0],
[69,64,13,1],
[69,64,13,2],
[69,64,13,3],
[69,64,13,5],
[69,64,13,6],
[69,64,13,7],
[69,64,13,8],
[69,64,13,10],
[69,64,13,11],
[69,64,13,12],
[69,64,14,0],
[69,64,14,1],
[69,64,14,2],
[69,64,14,3],
[69,64,14,5],
[69,64,14,6],
[69,64,14,7],
[69,64,14,8],
[69,64,14,10],
[69,64,14,11],
[69,64,16,0],
[69,64,16,1],
[69,64,16,2],
[69,64,16,3],
[69,64,16,5],
[69,64,16,6],
[69,64,16,7],
[69,64,17,0],
[69,64,17,1],
[69,64,17,2],
[69,64,17,3],
[69,64,17,5],
[69,64,17,6],
[69,64,17,7],
[69,64,19,0],
[69,64,19,1],
[69,64,19,2],
[69,64,19,3],
[69,64,20,0],
[69,64,20,1],
[69,64,20,2],
[69,65,3,0],
[69,65,3,1],
[69,65,3,2],
[69,65,4,0],
[69,65,4,1],
[69,65,4,2],
[69,65,4,3],
[69,65,5,0],
[69,65,5,1],
[69,65,5,2],
[69,65,5,4],
[69,65,6,0],
[69,65,6,1],
[69,65,6,2],
[69,65,6,4],
[69,65,7,0],
[69,65,7,1],
[69,65,7,2],
[69,65,7,3],
[69,65,7,4],
[69,65,7,5],
[69,65,7,6],
[69,65,8,0],
[69,65,8,1],
[69,65,8,2],
[69,65,8,4],
[69,65,8,7],
[69,65,10,0],
[69,65,10,1],
[69,65,10,2],
[69,65,10,3],
[69,65,10,4],
[69,65,10,5],
[69,65,10,6],
[69,65,10,7],
[69,65,10,8],
[69,65,11,0],
[69,65,11,1],
[69,65,11,2],
[69,65,11,3],
[69,65,11,4],
[69,65,11,5],
[69,65,11,6],
[69,65,11,7],
[69,65,11,8],
[69,65,11,10],
[69,65,12,0],
[69,65,12,1],
[69,65,12,2],
[69,65,12,3],
[69,65,12,4],
[69,65,12,5],
[69,65,12,6],
[69,65,12,7],
[69,65,12,8],
[69,65,12,10],
[69,65,13,0],
[69,65,13,1],
[69,65,13,2],
[69,65,13,3],
[69,65,13,4],
[69,65,13,5],
[69,65,13,6],
[69,65,13,7],
[69,65,13,8],
[69,65,13,10],
[69,65,13,11],
[69,65,14,0],
[69,65,14,1],
[69,65,14,2],
[69,65,14,3],
[69,65,14,4],
[69,65,14,5],
[69,65,14,6],
[69,65,14,7],
[69,65,14,8],
[69,65,16,0],
[69,65,16,1],
[69,65,16,2],
[69,65,16,3],
[69,65,16,4],
[69,65,16,5],
[69,65,17,0],
[69,65,17,1],
[69,65,17,2],
[69,65,17,3],
[69,65,17,4],
[69,65,17,5],
[69,65,19,0],
[69,65,19,1],
[69,65,19,2],
[69,66,3,0],
[69,66,3,1],
[69,66,3,2],
[69,66,4,0],
[69,66,4,1],
[69,66,4,2],
[69,66,4,3],
[69,66,5,0],
[69,66,5,1],
[69,66,5,2],
[69,66,5,4],
[69,66,6,0],
[69,66,6,1],
[69,66,6,2],
[69,66,6,4],
[69,66,8,0],
[69,66,8,1],
[69,66,8,2],
[69,66,8,4],
[69,66,10,0],
[69,66,10,1],
[69,66,10,2],
[69,66,10,3],
[69,66,10,4],
[69,66,10,5],
[69,66,10,6],
[69,66,10,8],
[69,66,11,0],
[69,66,11,1],
[69,66,11,2],
[69,66,11,3],
[69,66,11,4],
[69,66,11,5],
[69,66,11,6],
[69,66,11,8],
[69,66,11,10],
[69,66,12,0],
[69,66,12,1],
[69,66,12,2],
[69,66,12,3],
[69,66,12,4],
[69,66,12,5],
[69,66,12,6],
[69,66,12,8],
[69,66,13,0],
[69,66,13,1],
[69,66,13,2],
[69,66,13,3],
[69,66,13,4],
[69,66,13,5],
[69,66,13,6],
[69,66,13,8],
[69,66,14,0],
[69,66,14,1],
[69,66,14,2],
[69,66,14,3],
[69,66,14,4],
[69,66,14,5],
[69,66,14,6],
[69,66,16,0],
[69,66,16,1],
[69,66,16,2],
[69,66,16,3],
[69,66,16,4],
[69,66,17,0],
[69,66,17,1],
[69,66,17,2],
[69,66,17,3],
[69,66,17,4],
[69,67,3,0],
[69,67,3,1],
[69,67,3,2],
[69,67,4,0],
[69,67,4,1],
[69,67,4,2],
[69,67,4,3],
[69,67,5,0],
[69,67,5,1],
[69,67,5,2],
[69,67,5,4],
[69,67,6,0],
[69,67,6,1],
[69,67,6,2],
[69,67,6,4],
[69,67,8,0],
[69,67,8,1],
[69,67,8,2],
[69,67,8,4],
[69,67,10,0],
[69,67,10,1],
[69,67,10,2],
[69,67,10,3],
[69,67,10,4],
[69,67,10,5],
[69,67,10,6],
[69,67,10,8],
[69,67,11,0],
[69,67,11,1],
[69,67,11,2],
[69,67,11,3],
[69,67,11,4],
[69,67,11,5],
[69,67,11,6],
[69,67,11,8],
[69,67,12,0],
[69,67,12,1],
[69,67,12,2],
[69,67,12,3],
[69,67,12,4],
[69,67,12,5],
[69,67,12,6],
[69,67,13,0],
[69,67,13,1],
[69,67,13,2],
[69,67,13,3],
[69,67,13,4],
[69,67,13,5],
[69,67,13,6],
[69,67,14,0],
[69,67,14,1],
[69,67,14,2],
[69,67,14,3],
[69,67,14,4],
[69,67,14,5],
[69,67,16,0],
[69,67,16,1],
[69,67,16,2],
[69,67,17,0],
[69,67,17,1],
[69,67,17,2],
[69,68,3,0],
[69,68,3,1],
[69,68,3,2],
[69,68,4,0],
[69,68,4,1],
[69,68,4,2],
[69,68,4,3],
[69,68,5,0],
[69,68,5,1],
[69,68,5,2],
[69,68,5,4],
[69,68,6,0],
[69,68,6,1],
[69,68,6,2],
[69,68,6,4],
[69,68,8,0],
[69,68,8,1],
[69,68,8,2],
[69,68,8,4],
[69,68,10,0],
[69,68,10,1],
[69,68,10,2],
[69,68,10,3],
[69,68,10,4],
[69,68,10,5],
[69,68,10,6],
[69,68,11,0],
[69,68,11,1],
[69,68,11,2],
[69,68,11,3],
[69,68,11,4],
[69,68,11,5],
[69,68,11,6],
[69,68,12,0],
[69,68,12,1],
[69,68,12,2],
[69,68,12,3],
[69,68,12,4],
[69,68,12,5],
[69,68,13,0],
[69,68,13,1],
[69,68,13,2],
[69,68,13,3],
[69,68,13,4],
[69,68,13,5],
[69,68,14,0],
[69,68,14,1],
[69,68,14,2],
[69,68,14,3],
[69,68,14,4],
[70,4,3,0],
[70,4,3,1],
[70,4,3,2],
[70,5,4,0],
[70,5,4,1],
[70,5,4,2],
[70,6,4,0],
[70,6,4,1],
[70,6,4,2],
[70,8,4,0],
[70,8,4,1],
[70,8,4,2],
[70,9,3,0],
[70,9,3,1],
[70,9,3,2],
[70,9,4,0],
[70,9,4,1],
[70,9,4,2],
[70,9,4,3],
[70,9,5,0],
[70,9,5,1],
[70,9,5,2],
[70,9,5,4],
[70,9,6,0],
[70,9,6,1],
[70,9,6,2],
[70,9,6,4],
[70,9,8,0],
[70,9,8,1],
[70,9,8,2],
[70,9,8,4],
[70,10,3,0],
[70,10,3,1],
[70,10,3,2],
[70,10,4,0],
[70,10,4,1],
[70,10,4,2],
[70,10,4,3],
[70,10,5,0],
[70,10,5,1],
[70,10,5,2],
[70,10,5,4],
[70,10,6,0],
[70,10,6,1],
[70,10,6,2],
[70,10,6,4],
[70,10,8,0],
[70,10,8,1],
[70,10,8,2],
[70,10,8,4],
[70,10,9,0],
[70,10,9,1],
[70,10,9,2],
[70,10,9,3],
[70,10,9,4],
[70,10,9,5],
[70,10,9,6],
[70,10,9,8],
[70,11,3,0],
[70,11,3,1],
[70,11,3,2],
[70,11,4,0],
[70,11,4,1],
[70,11,4,2],
[70,11,4,3],
[70,11,5,0],
[70,11,5,1],
[70,11,5,2],
[70,11,5,4],
[70,11,6,0],
[70,11,6,1],
[70,11,6,2],
[70,11,6,4],
[70,11,8,0],
[70,11,8,1],
[70,11,8,2],
[70,11,8,4],
[70,11,9,0],
[70,11,9,1],
[70,11,9,2],
[70,11,9,3],
[70,11,9,4],
[70,11,9,5],
[70,11,9,6],
[70,11,9,8],
[70,11,10,0],
[70,11,10,1],
[70,11,10,2],
[70,11,10,3],
[70,11,10,4],
[70,11,10,5],
[70,11,10,6],
[70,11,10,8],
[70,11,10,9],
[70,12,3,0],
[70,12,3,1],
[70,12,3,2],
[70,12,4,0],
[70,12,4,1],
[70,12,4,2],
[70,12,4,3],
[70,12,5,0],
[70,12,5,1],
[70,12,5,2],
[70,12,5,4],
[70,12,6,0],
[70,12,6,1],
[70,12,6,2],
[70,12,6,4],
[70,12,8,0],
[70,12,8,1],
[70,12,8,2],
[70,12,8,4],
[70,12,9,0],
[70,12,9,1],
[70,12,9,2],
[70,12,9,3],
[70,12,9,4],
[70,12,9,5],
[70,12,9,6],
[70,12,9,8],
[70,12,10,0],
[70,12,10,1],
[70,12,10,2],
[70,12,10,3],
[70,12,10,4],
[70,12,10,5],
[70,12,10,6],
[70,12,10,8],
[70,12,10,9],
[70,13,3,0],
[70,13,3,1],
[70,13,3,2],
[70,13,4,0],
[70,13,4,1],
[70,13,4,2],
[70,13,4,3],
[70,13,5,0],
[70,13,5,1],
[70,13,5,2],
[70,13,5,4],
[70,13,6,0],
[70,13,6,1],
[70,13,6,2],
[70,13,6,4],
[70,13,8,0],
[70,13,8,1],
[70,13,8,2],
[70,13,8,4],
[70,13,9,0],
[70,13,9,1],
[70,13,9,2],
[70,13,9,3],
[70,13,9,4],
[70,13,9,5],
[70,13,9,6],
[70,13,9,8],
[70,13,10,0],
[70,13,10,1],
[70,13,10,2],
[70,13,10,3],
[70,13,10,4],
[70,13,10,5],
[70,13,10,6],
[70,13,10,8],
[70,13,10,9],
[70,13,11,0],
[70,13,11,1],
[70,13,11,2],
[70,13,11,3],
[70,13,11,4],
[70,13,11,5],
[70,13,11,6],
[70,13,11,8],
[70,13,11,9],
[70,13,11,10],
[70,13,12,0],
[70,13,12,1],
[70,13,12,2],
[70,13,12,3],
[70,13,12,4],
[70,13,12,5],
[70,13,12,6],
[70,13,12,8],
[70,13,12,9],
[70,13,12,10],
[70,14,3,0],
[70,14,3,1],
[70,14,3,2],
[70,14,4,0],
[70,14,4,1],
[70,14,4,2],
[70,14,4,3],
[70,14,5,0],
[70,14,5,1],
[70,14,5,2],
[70,14,5,4],
[70,14,6,0],
[70,14,6,1],
[70,14,6,2],
[70,14,6,4],
[70,14,8,0],
[70,14,8,1],
[70,14,8,2],
[70,14,8,4],
[70,14,9,0],
[70,14,9,1],
[70,14,9,2],
[70,14,9,3],
[70,14,9,4],
[70,14,9,5],
[70,14,9,6],
[70,14,9,8],
[70,14,10,0],
[70,14,10,1],
[70,14,10,2],
[70,14,10,3],
[70,14,10,4],
[70,14,10,5],
[70,14,10,6],
[70,14,10,8],
[70,14,10,9],
[70,14,11,0],
[70,14,11,1],
[70,14,11,2],
[70,14,11,3],
[70,14,11,4],
[70,14,11,5],
[70,14,11,6],
[70,14,11,8],
[70,14,11,9],
[70,14,11,10],
[70,14,12,0],
[70,14,12,1],
[70,14,12,2],
[70,14,12,3],
[70,14,12,4],
[70,14,12,5],
[70,14,12,6],
[70,14,12,8],
[70,14,12,9],
[70,14,12,10],
[70,15,3,0],
[70,15,3,1],
[70,15,3,2],
[70,15,4,0],
[70,15,4,1],
[70,15,4,2],
[70,15,4,3],
[70,15,5,0],
[70,15,5,1],
[70,15,5,2],
[70,15,5,4],
[70,15,6,0],
[70,15,6,1],
[70,15,6,2],
[70,15,6,4],
[70,15,8,0],
[70,15,8,1],
[70,15,8,2],
[70,15,8,4],
[70,15,10,0],
[70,15,10,1],
[70,15,10,2],
[70,15,10,3],
[70,15,10,4],
[70,15,10,5],
[70,15,10,6],
[70,15,10,8],
[70,15,11,0],
[70,15,11,1],
[70,15,11,2],
[70,15,11,3],
[70,15,11,4],
[70,15,11,5],
[70,15,11,6],
[70,15,11,8],
[70,15,11,10],
[70,15,12,0],
[70,15,12,1],
[70,15,12,2],
[70,15,12,3],
[70,15,12,4],
[70,15,12,5],
[70,15,12,6],
[70,15,12,8],
[70,15,12,10],
[70,15,13,0],
[70,15,13,1],
[70,15,13,2],
[70,15,13,3],
[70,15,13,4],
[70,15,13,5],
[70,15,13,6],
[70,15,13,8],
[70,15,13,10],
[70,15,13,11],
[70,15,13,12],
[70,15,14,0],
[70,15,14,1],
[70,15,14,2],
[70,15,14,3],
[70,15,14,4],
[70,15,14,5],
[70,15,14,6],
[70,15,14,8],
[70,15,14,10],
[70,15,14,11],
[70,15,14,12],
[70,16,3,0],
[70,16,3,1],
[70,16,3,2],
[70,16,4,0],
[70,16,4,1],
[70,16,4,2],
[70,16,4,3],
[70,16,5,0],
[70,16,5,1],
[70,16,5,2],
[70,16,5,4],
[70,16,6,0],
[70,16,6,1],
[70,16,6,2],
[70,16,6,4],
[70,16,8,0],
[70,16,8,1],
[70,16,8,2],
[70,16,8,4],
[70,16,9,0],
[70,16,9,1],
[70,16,9,2],
[70,16,9,3],
[70,16,9,4],
[70,16,9,5],
[70,16,9,6],
[70,16,9,8],
[70,16,10,0],
[70,16,10,1],
[70,16,10,2],
[70,16,10,3],
[70,16,10,4],
[70,16,10,5],
[70,16,10,6],
[70,16,10,8],
[70,16,10,9],
[70,16,11,0],
[70,16,11,1],
[70,16,11,2],
[70,16,11,3],
[70,16,11,4],
[70,16,11,5],
[70,16,11,6],
[70,16,11,8],
[70,16,11,9],
[70,16,11,10],
[70,16,12,0],
[70,16,12,1],
[70,16,12,2],
[70,16,12,3],
[70,16,12,4],
[70,16,12,5],
[70,16,12,6],
[70,16,12,8],
[70,16,12,9],
[70,16,12,10],
[70,16,13,0],
[70,16,13,1],
[70,16,13,2],
[70,16,13,3],
[70,16,13,4],
[70,16,13,5],
[70,16,13,6],
[70,16,13,8],
[70,16,13,9],
[70,16,13,10],
[70,16,13,11],
[70,16,13,12],
[70,16,14,0],
[70,16,14,1],
[70,16,14,2],
[70,16,14,3],
[70,16,14,4],
[70,16,14,5],
[70,16,14,6],
[70,16,14,8],
[70,16,14,9],
[70,16,14,10],
[70,16,14,11],
[70,16,14,12],
[70,16,15,0],
[70,16,15,1],
[70,16,15,2],
[70,16,15,3],
[70,16,15,4],
[70,16,15,5],
[70,16,15,6],
[70,16,15,8],
[70,16,15,10],
[70,16,15,11],
[70,16,15,12],
[70,16,15,13],
[70,16,15,14],
[70,17,3,0],
[70,17,3,1],
[70,17,3,2],
[70,17,4,0],
[70,17,4,1],
[70,17,4,2],
[70,17,4,3],
[70,17,5,0],
[70,17,5,1],
[70,17,5,2],
[70,17,5,4],
[70,17,6,0],
[70,17,6,1],
[70,17,6,2],
[70,17,6,4],
[70,17,8,0],
[70,17,8,1],
[70,17,8,2],
[70,17,8,4],
[70,17,9,0],
[70,17,9,1],
[70,17,9,2],
[70,17,9,3],
[70,17,9,4],
[70,17,9,5],
[70,17,9,6],
[70,17,9,8],
[70,17,10,0],
[70,17,10,1],
[70,17,10,2],
[70,17,10,3],
[70,17,10,4],
[70,17,10,5],
[70,17,10,6],
[70,17,10,8],
[70,17,10,9],
[70,17,11,0],
[70,17,11,1],
[70,17,11,2],
[70,17,11,3],
[70,17,11,4],
[70,17,11,5],
[70,17,11,6],
[70,17,11,8],
[70,17,11,9],
[70,17,11,10],
[70,17,12,0],
[70,17,12,1],
[70,17,12,2],
[70,17,12,3],
[70,17,12,4],
[70,17,12,5],
[70,17,12,6],
[70,17,12,8],
[70,17,12,9],
[70,17,12,10],
[70,17,13,0],
[70,17,13,1],
[70,17,13,2],
[70,17,13,3],
[70,17,13,4],
[70,17,13,5],
[70,17,13,6],
[70,17,13,8],
[70,17,13,9],
[70,17,13,10],
[70,17,13,11],
[70,17,13,12],
[70,17,14,0],
[70,17,14,1],
[70,17,14,2],
[70,17,14,3],
[70,17,14,4],
[70,17,14,5],
[70,17,14,6],
[70,17,14,8],
[70,17,14,9],
[70,17,14,10],
[70,17,14,11],
[70,17,14,12],
[70,17,15,0],
[70,17,15,1],
[70,17,15,2],
[70,17,15,3],
[70,17,15,4],
[70,17,15,5],
[70,17,15,6],
[70,17,15,8],
[70,17,15,10],
[70,17,15,11],
[70,17,15,12],
[70,17,15,13],
[70,17,15,14],
[70,17,16,0],
[70,17,16,1],
[70,17,16,2],
[70,17,16,3],
[70,17,16,4],
[70,17,16,5],
[70,17,16,6],
[70,17,16,8],
[70,17,16,9],
[70,17,16,10],
[70,17,16,11],
[70,17,16,12],
[70,17,16,13],
[70,17,16,14],
[70,17,16,15],
[70,18,3,0],
[70,18,3,1],
[70,18,3,2],
[70,18,4,0],
[70,18,4,1],
[70,18,4,2],
[70,18,4,3],
[70,18,5,0],
[70,18,5,1],
[70,18,5,2],
[70,18,5,4],
[70,18,6,0],
[70,18,6,1],
[70,18,6,2],
[70,18,6,4],
[70,18,8,0],
[70,18,8,1],
[70,18,8,2],
[70,18,8,4],
[70,18,10,0],
[70,18,10,1],
[70,18,10,2],
[70,18,10,3],
[70,18,10,4],
[70,18,10,5],
[70,18,10,6],
[70,18,10,8],
[70,18,11,0],
[70,18,11,1],
[70,18,11,2],
[70,18,11,3],
[70,18,11,4],
[70,18,11,5],
[70,18,11,6],
[70,18,11,8],
[70,18,11,10],
[70,18,12,0],
[70,18,12,1],
[70,18,12,2],
[70,18,12,3],
[70,18,12,4],
[70,18,12,5],
[70,18,12,6],
[70,18,12,8],
[70,18,12,10],
[70,18,13,0],
[70,18,13,1],
[70,18,13,2],
[70,18,13,3],
[70,18,13,4],
[70,18,13,5],
[70,18,13,6],
[70,18,13,8],
[70,18,13,10],
[70,18,13,11],
[70,18,13,12],
[70,18,14,0],
[70,18,14,1],
[70,18,14,2],
[70,18,14,3],
[70,18,14,4],
[70,18,14,5],
[70,18,14,6],
[70,18,14,8],
[70,18,14,10],
[70,18,14,11],
[70,18,14,12],
[70,18,16,0],
[70,18,16,1],
[70,18,16,2],
[70,18,16,3],
[70,18,16,4],
[70,18,16,5],
[70,18,16,6],
[70,18,16,8],
[70,18,16,10],
[70,18,16,11],
[70,18,16,12],
[70,18,16,13],
[70,18,16,14],
[70,18,17,0],
[70,18,17,1],
[70,18,17,2],
[70,18,17,3],
[70,18,17,4],
[70,18,17,5],
[70,18,17,6],
[70,18,17,8],
[70,18,17,10],
[70,18,17,11],
[70,18,17,12],
[70,18,17,13],
[70,18,17,14],
[70,18,17,16],
[70,19,3,0],
[70,19,3,1],
[70,19,3,2],
[70,19,4,0],
[70,19,4,1],
[70,19,4,2],
[70,19,4,3],
[70,19,5,0],
[70,19,5,1],
[70,19,5,2],
[70,19,5,4],
[70,19,6,0],
[70,19,6,1],
[70,19,6,2],
[70,19,6,4],
[70,19,8,0],
[70,19,8,1],
[70,19,8,2],
[70,19,8,4],
[70,19,9,0],
[70,19,9,1],
[70,19,9,2],
[70,19,9,3],
[70,19,9,4],
[70,19,9,5],
[70,19,9,6],
[70,19,9,8],
[70,19,10,0],
[70,19,10,1],
[70,19,10,2],
[70,19,10,3],
[70,19,10,4],
[70,19,10,5],
[70,19,10,6],
[70,19,10,8],
[70,19,10,9],
[70,19,11,0],
[70,19,11,1],
[70,19,11,2],
[70,19,11,3],
[70,19,11,4],
[70,19,11,5],
[70,19,11,6],
[70,19,11,8],
[70,19,11,9],
[70,19,11,10],
[70,19,12,0],
[70,19,12,1],
[70,19,12,2],
[70,19,12,3],
[70,19,12,4],
[70,19,12,5],
[70,19,12,6],
[70,19,12,8],
[70,19,12,9],
[70,19,12,10],
[70,19,15,0],
[70,19,15,1],
[70,19,15,2],
[70,19,15,3],
[70,19,15,4],
[70,19,15,5],
[70,19,15,6],
[70,19,15,8],
[70,19,15,10],
[70,19,15,11],
[70,19,15,12],
[70,19,16,0],
[70,19,16,1],
[70,19,16,2],
[70,19,16,3],
[70,19,16,4],
[70,19,16,5],
[70,19,16,6],
[70,19,16,8],
[70,19,16,9],
[70,19,16,10],
[70,19,16,11],
[70,19,16,12],
[70,19,16,15],
[70,19,17,0],
[70,19,17,1],
[70,19,17,2],
[70,19,17,3],
[70,19,17,4],
[70,19,17,5],
[70,19,17,6],
[70,19,17,8],
[70,19,17,9],
[70,19,17,10],
[70,19,17,11],
[70,19,17,12],
[70,19,17,15],
[70,19,17,16],
[70,19,18,0],
[70,19,18,1],
[70,19,18,2],
[70,19,18,3],
[70,19,18,4],
[70,19,18,5],
[70,19,18,6],
[70,19,18,8],
[70,19,18,10],
[70,19,18,11],
[70,19,18,12],
[70,19,18,16],
[70,19,18,17],
[70,20,3,0],
[70,20,3,1],
[70,20,3,2],
[70,20,4,0],
[70,20,4,1],
[70,20,4,2],
[70,20,4,3],
[70,20,5,0],
[70,20,5,1],
[70,20,5,2],
[70,20,5,4],
[70,20,6,0],
[70,20,6,1],
[70,20,6,2],
[70,20,6,4],
[70,20,8,0],
[70,20,8,1],
[70,20,8,2],
[70,20,8,4],
[70,20,9,0],
[70,20,9,1],
[70,20,9,2],
[70,20,9,3],
[70,20,9,4],
[70,20,9,5],
[70,20,9,6],
[70,20,9,8],
[70,20,11,0],
[70,20,11,1],
[70,20,11,2],
[70,20,11,3],
[70,20,11,4],
[70,20,11,5],
[70,20,11,6],
[70,20,11,8],
[70,20,11,9],
[70,20,12,0],
[70,20,12,1],
[70,20,12,2],
[70,20,12,3],
[70,20,12,4],
[70,20,12,5],
[70,20,12,6],
[70,20,12,8],
[70,20,12,9],
[70,20,13,0],
[70,20,13,1],
[70,20,13,2],
[70,20,13,3],
[70,20,13,4],
[70,20,13,5],
[70,20,13,6],
[70,20,13,8],
[70,20,13,9],
[70,20,13,11],
[70,20,13,12],
[70,20,14,0],
[70,20,14,1],
[70,20,14,2],
[70,20,14,3],
[70,20,14,4],
[70,20,14,5],
[70,20,14,6],
[70,20,14,8],
[70,20,14,9],
[70,20,14,11],
[70,20,14,12],
[70,20,15,0],
[70,20,15,1],
[70,20,15,2],
[70,20,15,3],
[70,20,15,4],
[70,20,15,5],
[70,20,15,6],
[70,20,15,8],
[70,20,15,11],
[70,20,15,12],
[70,20,15,13],
[70,20,15,14],
[70,20,16,0],
[70,20,16,1],
[70,20,16,2],
[70,20,16,3],
[70,20,16,4],
[70,20,16,5],
[70,20,16,6],
[70,20,16,8],
[70,20,16,9],
[70,20,16,11],
[70,20,16,12],
[70,20,16,13],
[70,20,16,14],
[70,20,16,15],
[70,20,17,0],
[70,20,17,1],
[70,20,17,2],
[70,20,17,3],
[70,20,17,4],
[70,20,17,5],
[70,20,17,6],
[70,20,17,8],
[70,20,17,9],
[70,20,17,11],
[70,20,17,12],
[70,20,17,13],
[70,20,17,14],
[70,20,17,15],
[70,20,17,16],
[70,20,18,0],
[70,20,18,1],
[70,20,18,2],
[70,20,18,3],
[70,20,18,4],
[70,20,18,5],
[70,20,18,6],
[70,20,18,8],
[70,20,18,11],
[70,20,18,12],
[70,20,18,13],
[70,20,18,14],
[70,20,18,16],
[70,20,18,17],
[70,20,19,0],
[70,20,19,1],
[70,20,19,2],
[70,20,19,3],
[70,20,19,4],
[70,20,19,5],
[70,20,19,6],
[70,20,19,8],
[70,20,19,9],
[70,20,19,11],
[70,20,19,12],
[70,20,19,15],
[70,20,19,16],
[70,20,19,17],
[70,20,19,18],
[70,21,4,0],
[70,21,4,1],
[70,21,4,2],
[70,21,9,0],
[70,21,9,1],
[70,21,9,2],
[70,21,9,4],
[70,21,10,0],
[70,21,10,1],
[70,21,10,2],
[70,21,10,4],
[70,21,10,9],
[70,21,11,0],
[70,21,11,1],
[70,21,11,2],
[70,21,11,4],
[70,21,11,9],
[70,21,11,10],
[70,21,12,0],
[70,21,12,1],
[70,21,12,2],
[70,21,12,4],
[70,21,12,9],
[70,21,12,10],
[70,21,13,0],
[70,21,13,1],
[70,21,13,2],
[70,21,13,4],
[70,21,13,9],
[70,21,13,10],
[70,21,13,11],
[70,21,13,12],
[70,21,14,0],
[70,21,14,1],
[70,21,14,2],
[70,21,14,4],
[70,21,14,9],
[70,21,14,10],
[70,21,14,11],
[70,21,14,12],
[70,21,15,0],
[70,21,15,1],
[70,21,15,2],
[70,21,15,4],
[70,21,15,10],
[70,21,15,11],
[70,21,15,12],
[70,21,15,13],
[70,21,15,14],
[70,21,16,0],
[70,21,16,1],
[70,21,16,2],
[70,21,16,4],
[70,21,16,9],
[70,21,16,10],
[70,21,16,11],
[70,21,16,12],
[70,21,16,13],
[70,21,16,14],
[70,21,16,15],
[70,21,17,0],
[70,21,17,1],
[70,21,17,2],
[70,21,17,4],
[70,21,17,9],
[70,21,17,10],
[70,21,17,11],
[70,21,17,12],
[70,21,17,13],
[70,21,17,14],
[70,21,17,15],
[70,21,17,16],
[70,21,18,0],
[70,21,18,1],
[70,21,18,2],
[70,21,18,4],
[70,21,18,10],
[70,21,18,11],
[70,21,18,12],
[70,21,18,13],
[70,21,18,14],
[70,21,18,16],
[70,21,18,17],
[70,21,19,0],
[70,21,19,1],
[70,21,19,2],
[70,21,19,4],
[70,21,19,9],
[70,21,19,10],
[70,21,19,11],
[70,21,19,12],
[70,21,19,15],
[70,21,19,16],
[70,21,19,17],
[70,21,19,18],
[70,21,20,0],
[70,21,20,1],
[70,21,20,2],
[70,21,20,4],
[70,21,20,9],
[70,21,20,11],
[70,21,20,12],
[70,21,20,13],
[70,21,20,14],
[70,21,20,15],
[70,21,20,16],
[70,21,20,17],
[70,21,20,18],
[70,21,20,19],
[70,22,3,0],
[70,22,3,1],
[70,22,3,2],
[70,22,4,0],
[70,22,4,1],
[70,22,4,2],
[70,22,4,3],
[70,22,5,0],
[70,22,5,1],
[70,22,5,2],
[70,22,5,4],
[70,22,6,0],
[70,22,6,1],
[70,22,6,2],
[70,22,6,4],
[70,22,8,0],
[70,22,8,1],
[70,22,8,2],
[70,22,8,4],
[70,22,9,0],
[70,22,9,1],
[70,22,9,2],
[70,22,9,3],
[70,22,9,4],
[70,22,9,5],
[70,22,9,6],
[70,22,9,8],
[70,22,11,0],
[70,22,11,1],
[70,22,11,2],
[70,22,11,3],
[70,22,11,4],
[70,22,11,5],
[70,22,11,6],
[70,22,11,8],
[70,22,11,9],
[70,22,12,0],
[70,22,12,1],
[70,22,12,2],
[70,22,12,3],
[70,22,12,4],
[70,22,12,5],
[70,22,12,6],
[70,22,12,8],
[70,22,12,9],
[70,22,13,0],
[70,22,13,1],
[70,22,13,2],
[70,22,13,3],
[70,22,13,4],
[70,22,13,5],
[70,22,13,6],
[70,22,13,8],
[70,22,13,9],
[70,22,13,11],
[70,22,13,12],
[70,22,14,0],
[70,22,14,1],
[70,22,14,2],
[70,22,14,3],
[70,22,14,4],
[70,22,14,5],
[70,22,14,6],
[70,22,14,8],
[70,22,14,9],
[70,22,14,11],
[70,22,14,12],
[70,22,15,0],
[70,22,15,1],
[70,22,15,2],
[70,22,15,3],
[70,22,15,4],
[70,22,15,5],
[70,22,15,6],
[70,22,15,8],
[70,22,15,11],
[70,22,15,12],
[70,22,15,13],
[70,22,15,14],
[70,22,16,0],
[70,22,16,1],
[70,22,16,2],
[70,22,16,3],
[70,22,16,4],
[70,22,16,5],
[70,22,16,6],
[70,22,16,8],
[70,22,16,9],
[70,22,16,11],
[70,22,16,12],
[70,22,16,13],
[70,22,16,14],
[70,22,16,15],
[70,22,17,0],
[70,22,17,1],
[70,22,17,2],
[70,22,17,3],
[70,22,17,4],
[70,22,17,5],
[70,22,17,6],
[70,22,17,8],
[70,22,17,9],
[70,22,17,11],
[70,22,17,12],
[70,22,17,13],
[70,22,17,14],
[70,22,17,15],
[70,22,17,16],
[70,22,18,0],
[70,22,18,1],
[70,22,18,2],
[70,22,18,3],
[70,22,18,4],
[70,22,18,5],
[70,22,18,6],
[70,22,18,8],
[70,22,18,11],
[70,22,18,12],
[70,22,18,13],
[70,22,18,14],
[70,22,18,16],
[70,22,18,17],
[70,22,19,0],
[70,22,19,1],
[70,22,19,2],
[70,22,19,3],
[70,22,19,4],
[70,22,19,5],
[70,22,19,6],
[70,22,19,8],
[70,22,19,9],
[70,22,19,11],
[70,22,19,12],
[70,22,19,15],
[70,22,19,16],
[70,22,19,17],
[70,22,19,18],
[70,22,21,0],
[70,22,21,1],
[70,22,21,2],
[70,22,21,4],
[70,22,21,9],
[70,22,21,11],
[70,22,21,12],
[70,22,21,13],
[70,22,21,14],
[70,22,21,15],
[70,22,21,16],
[70,22,21,17],
[70,22,21,18],
[70,22,21,19],
[70,23,3,0],
[70,23,3,1],
[70,23,3,2],
[70,23,4,0],
[70,23,4,1],
[70,23,4,2],
[70,23,4,3],
[70,23,5,0],
[70,23,5,1],
[70,23,5,2],
[70,23,5,4],
[70,23,6,0],
[70,23,6,1],
[70,23,6,2],
[70,23,6,4],
[70,23,8,0],
[70,23,8,1],
[70,23,8,2],
[70,23,8,4],
[70,23,9,0],
[70,23,9,1],
[70,23,9,2],
[70,23,9,3],
[70,23,9,4],
[70,23,9,5],
[70,23,9,6],
[70,23,9,8],
[70,23,10,0],
[70,23,10,1],
[70,23,10,2],
[70,23,10,3],
[70,23,10,4],
[70,23,10,5],
[70,23,10,6],
[70,23,10,8],
[70,23,10,9],
[70,23,11,0],
[70,23,11,1],
[70,23,11,2],
[70,23,11,3],
[70,23,11,4],
[70,23,11,5],
[70,23,11,6],
[70,23,11,8],
[70,23,11,9],
[70,23,11,10],
[70,23,12,0],
[70,23,12,1],
[70,23,12,2],
[70,23,12,3],
[70,23,12,4],
[70,23,12,5],
[70,23,12,6],
[70,23,12,8],
[70,23,12,9],
[70,23,12,10],
[70,23,13,0],
[70,23,13,1],
[70,23,13,2],
[70,23,13,3],
[70,23,13,4],
[70,23,13,5],
[70,23,13,6],
[70,23,13,8],
[70,23,13,9],
[70,23,13,10],
[70,23,13,11],
[70,23,13,12],
[70,23,14,0],
[70,23,14,1],
[70,23,14,2],
[70,23,14,3],
[70,23,14,4],
[70,23,14,5],
[70,23,14,6],
[70,23,14,8],
[70,23,14,9],
[70,23,14,10],
[70,23,14,11],
[70,23,14,12],
[70,23,15,0],
[70,23,15,1],
[70,23,15,2],
[70,23,15,3],
[70,23,15,4],
[70,23,15,5],
[70,23,15,6],
[70,23,15,8],
[70,23,15,10],
[70,23,15,11],
[70,23,15,12],
[70,23,15,13],
[70,23,15,14],
[70,23,16,0],
[70,23,16,1],
[70,23,16,2],
[70,23,16,3],
[70,23,16,4],
[70,23,16,5],
[70,23,16,6],
[70,23,16,8],
[70,23,16,9],
[70,23,16,10],
[70,23,16,11],
[70,23,16,12],
[70,23,16,13],
[70,23,16,14],
[70,23,16,15],
[70,23,17,0],
[70,23,17,1],
[70,23,17,2],
[70,23,17,3],
[70,23,17,4],
[70,23,17,5],
[70,23,17,6],
[70,23,17,8],
[70,23,17,9],
[70,23,17,10],
[70,23,17,11],
[70,23,17,12],
[70,23,17,13],
[70,23,17,14],
[70,23,17,15],
[70,23,17,16],
[70,23,18,0],
[70,23,18,1],
[70,23,18,2],
[70,23,18,3],
[70,23,18,4],
[70,23,18,5],
[70,23,18,6],
[70,23,18,8],
[70,23,18,10],
[70,23,18,11],
[70,23,18,12],
[70,23,18,13],
[70,23,18,14],
[70,23,18,16],
[70,23,18,17],
[70,23,19,0],
[70,23,19,1],
[70,23,19,2],
[70,23,19,3],
[70,23,19,4],
[70,23,19,5],
[70,23,19,6],
[70,23,19,8],
[70,23,19,9],
[70,23,19,10],
[70,23,19,11],
[70,23,19,12],
[70,23,19,15],
[70,23,19,16],
[70,23,19,17],
[70,23,19,18],
[70,23,20,0],
[70,23,20,1],
[70,23,20,2],
[70,23,20,3],
[70,23,20,4],
[70,23,20,5],
[70,23,20,6],
[70,23,20,8],
[70,23,20,9],
[70,23,20,11],
[70,23,20,12],
[70,23,20,13],
[70,23,20,14],
[70,23,20,15],
[70,23,20,16],
[70,23,20,17],
[70,23,20,18],
[70,23,20,19],
[70,23,21,0],
[70,23,21,1],
[70,23,21,2],
[70,23,21,4],
[70,23,21,9],
[70,23,21,10],
[70,23,21,11],
[70,23,21,12],
[70,23,21,13],
[70,23,21,14],
[70,23,21,15],
[70,23,21,16],
[70,23,21,17],
[70,23,21,18],
[70,23,21,19],
[70,23,21,20],
[70,23,22,0],
[70,23,22,1],
[70,23,22,2],
[70,23,22,3],
[70,23,22,4],
[70,23,22,5],
[70,23,22,6],
[70,23,22,8],
[70,23,22,9],
[70,23,22,11],
[70,23,22,12],
[70,23,22,13],
[70,23,22,14],
[70,23,22,15],
[70,23,22,16],
[70,23,22,17],
[70,23,22,18],
[70,23,22,19],
[70,23,22,21],
[70,24,3,0],
[70,24,3,1],
[70,24,3,2],
[70,24,4,0],
[70,24,4,1],
[70,24,4,2],
[70,24,4,3],
[70,24,5,0],
[70,24,5,1],
[70,24,5,2],
[70,24,5,4],
[70,24,6,0],
[70,24,6,1],
[70,24,6,2],
[70,24,6,4],
[70,24,8,0],
[70,24,8,1],
[70,24,8,2],
[70,24,8,4],
[70,24,9,0],
[70,24,9,1],
[70,24,9,2],
[70,24,9,3],
[70,24,9,4],
[70,24,9,5],
[70,24,9,6],
[70,24,9,8],
[70,24,10,0],
[70,24,10,1],
[70,24,10,2],
[70,24,10,3],
[70,24,10,4],
[70,24,10,5],
[70,24,10,6],
[70,24,10,8],
[70,24,10,9],
[70,24,11,0],
[70,24,11,1],
[70,24,11,2],
[70,24,11,3],
[70,24,11,4],
[70,24,11,5],
[70,24,11,6],
[70,24,11,8],
[70,24,11,9],
[70,24,11,10],
[70,24,12,0],
[70,24,12,1],
[70,24,12,2],
[70,24,12,3],
[70,24,12,4],
[70,24,12,5],
[70,24,12,6],
[70,24,12,8],
[70,24,12,9],
[70,24,12,10],
[70,24,13,0],
[70,24,13,1],
[70,24,13,2],
[70,24,13,3],
[70,24,13,4],
[70,24,13,5],
[70,24,13,6],
[70,24,13,8],
[70,24,13,9],
[70,24,13,10],
[70,24,13,11],
[70,24,13,12],
[70,24,14,0],
[70,24,14,1],
[70,24,14,2],
[70,24,14,3],
[70,24,14,4],
[70,24,14,5],
[70,24,14,6],
[70,24,14,8],
[70,24,14,9],
[70,24,14,10],
[70,24,14,11],
[70,24,14,12],
[70,24,15,0],
[70,24,15,1],
[70,24,15,2],
[70,24,15,3],
[70,24,15,4],
[70,24,15,5],
[70,24,15,6],
[70,24,15,8],
[70,24,15,10],
[70,24,15,11],
[70,24,15,12],
[70,24,15,13],
[70,24,15,14],
[70,24,17,0],
[70,24,17,1],
[70,24,17,2],
[70,24,17,3],
[70,24,17,4],
[70,24,17,5],
[70,24,17,6],
[70,24,17,8],
[70,24,17,9],
[70,24,17,10],
[70,24,17,11],
[70,24,17,12],
[70,24,17,13],
[70,24,17,14],
[70,24,17,15],
[70,24,18,0],
[70,24,18,1],
[70,24,18,2],
[70,24,18,3],
[70,24,18,4],
[70,24,18,5],
[70,24,18,6],
[70,24,18,8],
[70,24,18,10],
[70,24,18,11],
[70,24,18,12],
[70,24,18,13],
[70,24,18,14],
[70,24,18,17],
[70,24,19,0],
[70,24,19,1],
[70,24,19,2],
[70,24,19,3],
[70,24,19,4],
[70,24,19,5],
[70,24,19,6],
[70,24,19,8],
[70,24,19,9],
[70,24,19,10],
[70,24,19,11],
[70,24,19,12],
[70,24,19,15],
[70,24,19,17],
[70,24,19,18],
[70,24,20,0],
[70,24,20,1],
[70,24,20,2],
[70,24,20,3],
[70,24,20,4],
[70,24,20,5],
[70,24,20,6],
[70,24,20,8],
[70,24,20,9],
[70,24,20,11],
[70,24,20,12],
[70,24,20,13],
[70,24,20,14],
[70,24,20,15],
[70,24,20,17],
[70,24,20,18],
[70,24,20,19],
[70,24,21,0],
[70,24,21,1],
[70,24,21,2],
[70,24,21,4],
[70,24,21,9],
[70,24,21,10],
[70,24,21,11],
[70,24,21,12],
[70,24,21,13],
[70,24,21,14],
[70,24,21,15],
[70,24,21,17],
[70,24,21,18],
[70,24,21,19],
[70,24,21,20],
[70,24,22,0],
[70,24,22,1],
[70,24,22,2],
[70,24,22,3],
[70,24,22,4],
[70,24,22,5],
[70,24,22,6],
[70,24,22,8],
[70,24,22,9],
[70,24,22,11],
[70,24,22,12],
[70,24,22,13],
[70,24,22,14],
[70,24,22,15],
[70,24,22,17],
[70,24,22,18],
[70,24,22,19],
[70,24,22,21],
[70,24,23,0],
[70,24,23,1],
[70,24,23,2],
[70,24,23,3],
[70,24,23,4],
[70,24,23,5],
[70,24,23,6],
[70,24,23,8],
[70,24,23,9],
[70,24,23,10],
[70,24,23,11],
[70,24,23,12],
[70,24,23,13],
[70,24,23,14],
[70,24,23,15],
[70,24,23,17],
[70,24,23,18],
[70,24,23,19],
[70,24,23,20],
[70,24,23,21],
[70,26,3,0],
[70,26,3,1],
[70,26,3,2],
[70,26,4,0],
[70,26,4,1],
[70,26,4,2],
[70,26,4,3],
[70,26,5,0],
[70,26,5,1],
[70,26,5,2],
[70,26,5,4],
[70,26,6,0],
[70,26,6,1],
[70,26,6,2],
[70,26,6,4],
[70,26,8,0],
[70,26,8,1],
[70,26,8,2],
[70,26,8,4],
[70,26,10,0],
[70,26,10,1],
[70,26,10,2],
[70,26,10,3],
[70,26,10,4],
[70,26,10,5],
[70,26,10,6],
[70,26,10,8],
[70,26,11,0],
[70,26,11,1],
[70,26,11,2],
[70,26,11,3],
[70,26,11,4],
[70,26,11,5],
[70,26,11,6],
[70,26,11,8],
[70,26,11,10],
[70,26,12,0],
[70,26,12,1],
[70,26,12,2],
[70,26,12,3],
[70,26,12,4],
[70,26,12,5],
[70,26,12,6],
[70,26,12,8],
[70,26,12,10],
[70,26,13,0],
[70,26,13,1],
[70,26,13,2],
[70,26,13,3],
[70,26,13,4],
[70,26,13,5],
[70,26,13,6],
[70,26,13,8],
[70,26,13,10],
[70,26,13,11],
[70,26,13,12],
[70,26,14,0],
[70,26,14,1],
[70,26,14,2],
[70,26,14,3],
[70,26,14,4],
[70,26,14,5],
[70,26,14,6],
[70,26,14,8],
[70,26,14,10],
[70,26,14,11],
[70,26,14,12],
[70,26,16,0],
[70,26,16,1],
[70,26,16,2],
[70,26,16,3],
[70,26,16,4],
[70,26,16,5],
[70,26,16,6],
[70,26,16,8],
[70,26,16,10],
[70,26,16,11],
[70,26,16,12],
[70,26,16,13],
[70,26,16,14],
[70,26,17,0],
[70,26,17,1],
[70,26,17,2],
[70,26,17,3],
[70,26,17,4],
[70,26,17,5],
[70,26,17,6],
[70,26,17,8],
[70,26,17,10],
[70,26,17,11],
[70,26,17,12],
[70,26,17,13],
[70,26,17,14],
[70,26,17,16],
[70,26,19,0],
[70,26,19,1],
[70,26,19,2],
[70,26,19,3],
[70,26,19,4],
[70,26,19,5],
[70,26,19,6],
[70,26,19,8],
[70,26,19,10],
[70,26,19,11],
[70,26,19,12],
[70,26,19,16],
[70,26,19,17],
[70,26,20,0],
[70,26,20,1],
[70,26,20,2],
[70,26,20,3],
[70,26,20,4],
[70,26,20,5],
[70,26,20,6],
[70,26,20,8],
[70,26,20,11],
[70,26,20,12],
[70,26,20,13],
[70,26,20,14],
[70,26,20,16],
[70,26,20,17],
[70,26,20,19],
[70,26,21,0],
[70,26,21,1],
[70,26,21,2],
[70,26,21,4],
[70,26,21,10],
[70,26,21,11],
[70,26,21,12],
[70,26,21,13],
[70,26,21,14],
[70,26,21,16],
[70,26,21,17],
[70,26,21,19],
[70,26,21,20],
[70,26,22,0],
[70,26,22,1],
[70,26,22,2],
[70,26,22,3],
[70,26,22,4],
[70,26,22,5],
[70,26,22,6],
[70,26,22,8],
[70,26,22,11],
[70,26,22,12],
[70,26,22,13],
[70,26,22,14],
[70,26,22,16],
[70,26,22,17],
[70,26,22,19],
[70,26,22,21],
[70,26,23,0],
[70,26,23,1],
[70,26,23,2],
[70,26,23,3],
[70,26,23,4],
[70,26,23,5],
[70,26,23,6],
[70,26,23,8],
[70,26,23,10],
[70,26,23,11],
[70,26,23,12],
[70,26,23,13],
[70,26,23,14],
[70,26,23,16],
[70,26,23,17],
[70,26,23,20],
[70,26,23,21],
[70,26,23,22],
[70,26,24,0],
[70,26,24,1],
[70,26,24,2],
[70,26,24,3],
[70,26,24,4],
[70,26,24,5],
[70,26,24,6],
[70,26,24,8],
[70,26,24,10],
[70,26,24,11],
[70,26,24,12],
[70,26,24,13],
[70,26,24,14],
[70,26,24,17],
[70,26,24,20],
[70,26,24,21],
[70,26,24,22],
[70,26,24,23],
[70,27,3,0],
[70,27,3,1],
[70,27,3,2],
[70,27,4,0],
[70,27,4,1],
[70,27,4,2],
[70,27,4,3],
[70,27,5,0],
[70,27,5,1],
[70,27,5,2],
[70,27,5,4],
[70,27,6,0],
[70,27,6,1],
[70,27,6,2],
[70,27,6,4],
[70,27,8,0],
[70,27,8,1],
[70,27,8,2],
[70,27,8,4],
[70,27,9,0],
[70,27,9,1],
[70,27,9,2],
[70,27,9,3],
[70,27,9,4],
[70,27,9,5],
[70,27,9,6],
[70,27,9,8],
[70,27,11,0],
[70,27,11,1],
[70,27,11,2],
[70,27,11,3],
[70,27,11,4],
[70,27,11,5],
[70,27,11,6],
[70,27,11,8],
[70,27,11,9],
[70,27,12,0],
[70,27,12,1],
[70,27,12,2],
[70,27,12,3],
[70,27,12,4],
[70,27,12,5],
[70,27,12,6],
[70,27,12,8],
[70,27,12,9],
[70,27,13,0],
[70,27,13,1],
[70,27,13,2],
[70,27,13,3],
[70,27,13,4],
[70,27,13,5],
[70,27,13,6],
[70,27,13,8],
[70,27,13,9],
[70,27,13,11],
[70,27,13,12],
[70,27,14,0],
[70,27,14,1],
[70,27,14,2],
[70,27,14,3],
[70,27,14,4],
[70,27,14,5],
[70,27,14,6],
[70,27,14,8],
[70,27,14,9],
[70,27,14,11],
[70,27,14,12],
[70,27,15,0],
[70,27,15,1],
[70,27,15,2],
[70,27,15,3],
[70,27,15,4],
[70,27,15,5],
[70,27,15,6],
[70,27,15,8],
[70,27,15,11],
[70,27,15,12],
[70,27,15,13],
[70,27,15,14],
[70,27,16,0],
[70,27,16,1],
[70,27,16,2],
[70,27,16,3],
[70,27,16,4],
[70,27,16,5],
[70,27,16,6],
[70,27,16,8],
[70,27,16,9],
[70,27,16,11],
[70,27,16,12],
[70,27,16,13],
[70,27,16,14],
[70,27,16,15],
[70,27,17,0],
[70,27,17,1],
[70,27,17,2],
[70,27,17,3],
[70,27,17,4],
[70,27,17,5],
[70,27,17,6],
[70,27,17,8],
[70,27,17,9],
[70,27,17,11],
[70,27,17,12],
[70,27,17,13],
[70,27,17,14],
[70,27,17,15],
[70,27,17,16],
[70,27,18,0],
[70,27,18,1],
[70,27,18,2],
[70,27,18,3],
[70,27,18,4],
[70,27,18,5],
[70,27,18,6],
[70,27,18,8],
[70,27,18,11],
[70,27,18,12],
[70,27,18,13],
[70,27,18,14],
[70,27,18,16],
[70,27,18,17],
[70,27,19,0],
[70,27,19,1],
[70,27,19,2],
[70,27,19,3],
[70,27,19,4],
[70,27,19,5],
[70,27,19,6],
[70,27,19,8],
[70,27,19,9],
[70,27,19,11],
[70,27,19,12],
[70,27,19,15],
[70,27,19,16],
[70,27,19,17],
[70,27,19,18],
[70,27,21,0],
[70,27,21,1],
[70,27,21,2],
[70,27,21,4],
[70,27,21,9],
[70,27,21,11],
[70,27,21,12],
[70,27,21,13],
[70,27,21,14],
[70,27,21,15],
[70,27,21,16],
[70,27,21,17],
[70,27,21,18],
[70,27,21,19],
[70,27,23,0],
[70,27,23,1],
[70,27,23,2],
[70,27,23,3],
[70,27,23,4],
[70,27,23,5],
[70,27,23,6],
[70,27,23,8],
[70,27,23,9],
[70,27,23,11],
[70,27,23,12],
[70,27,23,13],
[70,27,23,14],
[70,27,23,15],
[70,27,23,16],
[70,27,23,17],
[70,27,23,19],
[70,27,23,21],
[70,27,24,0],
[70,27,24,1],
[70,27,24,2],
[70,27,24,3],
[70,27,24,4],
[70,27,24,5],
[70,27,24,6],
[70,27,24,8],
[70,27,24,9],
[70,27,24,11],
[70,27,24,12],
[70,27,24,13],
[70,27,24,14],
[70,27,24,15],
[70,27,24,17],
[70,27,24,19],
[70,27,24,21],
[70,27,24,23],
[70,27,26,0],
[70,27,26,1],
[70,27,26,2],
[70,27,26,3],
[70,27,26,4],
[70,27,26,5],
[70,27,26,6],
[70,27,26,8],
[70,27,26,11],
[70,27,26,12],
[70,27,26,13],
[70,27,26,16],
[70,27,26,17],
[70,27,26,19],
[70,27,26,21],
[70,27,26,23],
[70,27,26,24],
[70,29,3,0],
[70,29,3,1],
[70,29,3,2],
[70,29,4,0],
[70,29,4,1],
[70,29,4,2],
[70,29,4,3],
[70,29,5,0],
[70,29,5,1],
[70,29,5,2],
[70,29,5,4],
[70,29,6,0],
[70,29,6,1],
[70,29,6,2],
[70,29,6,4],
[70,29,8,0],
[70,29,8,1],
[70,29,8,2],
[70,29,8,4],
[70,29,9,0],
[70,29,9,1],
[70,29,9,2],
[70,29,9,3],
[70,29,9,4],
[70,29,9,5],
[70,29,9,6],
[70,29,9,8],
[70,29,10,0],
[70,29,10,1],
[70,29,10,2],
[70,29,10,3],
[70,29,10,4],
[70,29,10,5],
[70,29,10,6],
[70,29,10,8],
[70,29,10,9],
[70,29,13,0],
[70,29,13,1],
[70,29,13,2],
[70,29,13,3],
[70,29,13,4],
[70,29,13,5],
[70,29,13,6],
[70,29,13,8],
[70,29,13,9],
[70,29,13,10],
[70,29,14,0],
[70,29,14,1],
[70,29,14,2],
[70,29,14,3],
[70,29,14,4],
[70,29,14,5],
[70,29,14,6],
[70,29,14,8],
[70,29,14,9],
[70,29,14,10],
[70,29,15,0],
[70,29,15,1],
[70,29,15,2],
[70,29,15,3],
[70,29,15,4],
[70,29,15,5],
[70,29,15,6],
[70,29,15,8],
[70,29,15,10],
[70,29,15,13],
[70,29,15,14],
[70,29,16,0],
[70,29,16,1],
[70,29,16,2],
[70,29,16,3],
[70,29,16,4],
[70,29,16,5],
[70,29,16,6],
[70,29,16,8],
[70,29,16,9],
[70,29,16,10],
[70,29,16,13],
[70,29,16,14],
[70,29,16,15],
[70,29,17,0],
[70,29,17,1],
[70,29,17,2],
[70,29,17,3],
[70,29,17,4],
[70,29,17,5],
[70,29,17,6],
[70,29,17,8],
[70,29,17,9],
[70,29,17,10],
[70,29,17,13],
[70,29,17,14],
[70,29,17,15],
[70,29,17,16],
[70,29,18,0],
[70,29,18,1],
[70,29,18,2],
[70,29,18,3],
[70,29,18,4],
[70,29,18,5],
[70,29,18,6],
[70,29,18,8],
[70,29,18,10],
[70,29,18,13],
[70,29,18,14],
[70,29,18,16],
[70,29,18,17],
[70,29,19,0],
[70,29,19,1],
[70,29,19,2],
[70,29,19,3],
[70,29,19,4],
[70,29,19,5],
[70,29,19,6],
[70,29,19,8],
[70,29,19,9],
[70,29,19,10],
[70,29,19,15],
[70,29,19,16],
[70,29,19,17],
[70,29,19,18],
[70,29,20,0],
[70,29,20,1],
[70,29,20,2],
[70,29,20,3],
[70,29,20,4],
[70,29,20,5],
[70,29,20,6],
[70,29,20,8],
[70,29,20,9],
[70,29,20,13],
[70,29,20,14],
[70,29,20,15],
[70,29,20,16],
[70,29,20,17],
[70,29,20,18],
[70,29,21,0],
[70,29,21,1],
[70,29,21,2],
[70,29,21,4],
[70,29,21,9],
[70,29,21,10],
[70,29,21,13],
[70,29,21,14],
[70,29,21,15],
[70,29,21,16],
[70,29,21,17],
[70,29,21,19],
[70,29,21,20],
[70,29,22,0],
[70,29,22,1],
[70,29,22,2],
[70,29,22,3],
[70,29,22,4],
[70,29,22,5],
[70,29,22,6],
[70,29,22,8],
[70,29,22,9],
[70,29,22,13],
[70,29,22,14],
[70,29,22,15],
[70,29,22,18],
[70,29,22,19],
[70,29,22,21],
[70,29,23,0],
[70,29,23,1],
[70,29,23,2],
[70,29,23,3],
[70,29,23,4],
[70,29,23,5],
[70,29,23,6],
[70,29,23,8],
[70,29,23,9],
[70,29,23,10],
[70,29,23,13],
[70,29,23,14],
[70,29,23,16],
[70,29,23,17],
[70,29,23,18],
[70,29,23,19],
[70,29,23,20],
[70,29,23,21],
[70,29,23,22],
[70,29,24,0],
[70,29,24,1],
[70,29,24,2],
[70,29,24,3],
[70,29,24,4],
[70,29,24,5],
[70,29,24,6],
[70,29,24,8],
[70,29,24,9],
[70,29,24,10],
[70,29,24,13],
[70,29,24,14],
[70,29,24,17],
[70,29,24,18],
[70,29,24,19],
[70,29,24,20],
[70,29,24,21],
[70,29,24,22],
[70,29,24,23],
[70,29,26,0],
[70,29,26,1],
[70,29,26,2],
[70,29,26,3],
[70,29,26,4],
[70,29,26,5],
[70,29,26,6],
[70,29,26,8],
[70,29,26,13],
[70,29,26,14],
[70,29,26,16],
[70,29,26,17],
[70,29,26,19],
[70,29,26,20],
[70,29,26,21],
[70,29,26,22],
[70,29,26,23],
[70,29,26,24],
[70,29,27,0],
[70,29,27,1],
[70,29,27,2],
[70,29,27,3],
[70,29,27,4],
[70,29,27,5],
[70,29,27,6],
[70,29,27,13],
[70,29,27,14],
[70,29,27,15],
[70,29,27,16],
[70,29,27,17],
[70,29,27,18],
[70,29,27,19],
[70,29,27,21],
[70,29,27,23],
[70,29,27,24],
[70,30,3,0],
[70,30,3,1],
[70,30,3,2],
[70,30,4,0],
[70,30,4,1],
[70,30,4,2],
[70,30,4,3],
[70,30,5,0],
[70,30,5,1],
[70,30,5,2],
[70,30,5,4],
[70,30,6,0],
[70,30,6,1],
[70,30,6,2],
[70,30,6,4],
[70,30,8,0],
[70,30,8,1],
[70,30,8,2],
[70,30,8,4],
[70,30,9,0],
[70,30,9,1],
[70,30,9,2],
[70,30,9,3],
[70,30,9,4],
[70,30,9,5],
[70,30,9,6],
[70,30,9,8],
[70,30,10,0],
[70,30,10,1],
[70,30,10,2],
[70,30,10,3],
[70,30,10,4],
[70,30,10,5],
[70,30,10,6],
[70,30,10,8],
[70,30,10,9],
[70,30,11,0],
[70,30,11,1],
[70,30,11,2],
[70,30,11,3],
[70,30,11,4],
[70,30,11,5],
[70,30,11,6],
[70,30,11,8],
[70,30,11,9],
[70,30,11,10],
[70,30,12,0],
[70,30,12,1],
[70,30,12,2],
[70,30,12,3],
[70,30,12,4],
[70,30,12,5],
[70,30,12,6],
[70,30,12,8],
[70,30,12,9],
[70,30,12,10],
[70,30,13,0],
[70,30,13,1],
[70,30,13,2],
[70,30,13,3],
[70,30,13,4],
[70,30,13,5],
[70,30,13,6],
[70,30,13,8],
[70,30,13,9],
[70,30,13,10],
[70,30,13,11],
[70,30,13,12],
[70,30,14,0],
[70,30,14,1],
[70,30,14,2],
[70,30,14,3],
[70,30,14,4],
[70,30,14,5],
[70,30,14,6],
[70,30,14,8],
[70,30,14,9],
[70,30,14,10],
[70,30,14,11],
[70,30,14,12],
[70,30,15,0],
[70,30,15,1],
[70,30,15,2],
[70,30,15,3],
[70,30,15,4],
[70,30,15,5],
[70,30,15,6],
[70,30,15,8],
[70,30,15,10],
[70,30,15,11],
[70,30,15,12],
[70,30,15,13],
[70,30,15,14],
[70,30,16,0],
[70,30,16,1],
[70,30,16,2],
[70,30,16,3],
[70,30,16,4],
[70,30,16,5],
[70,30,16,6],
[70,30,16,8],
[70,30,16,9],
[70,30,16,10],
[70,30,16,11],
[70,30,16,12],
[70,30,16,13],
[70,30,16,14],
[70,30,16,15],
[70,30,17,0],
[70,30,17,1],
[70,30,17,2],
[70,30,17,3],
[70,30,17,4],
[70,30,17,5],
[70,30,17,6],
[70,30,17,8],
[70,30,17,9],
[70,30,17,10],
[70,30,17,11],
[70,30,17,12],
[70,30,17,13],
[70,30,17,14],
[70,30,17,15],
[70,30,17,16],
[70,30,18,0],
[70,30,18,1],
[70,30,18,2],
[70,30,18,3],
[70,30,18,4],
[70,30,18,5],
[70,30,18,6],
[70,30,18,8],
[70,30,18,10],
[70,30,18,11],
[70,30,18,12],
[70,30,18,13],
[70,30,18,14],
[70,30,18,16],
[70,30,18,17],
[70,30,19,0],
[70,30,19,1],
[70,30,19,2],
[70,30,19,3],
[70,30,19,4],
[70,30,19,5],
[70,30,19,6],
[70,30,19,8],
[70,30,19,9],
[70,30,19,10],
[70,30,19,11],
[70,30,19,12],
[70,30,19,15],
[70,30,19,16],
[70,30,19,17],
[70,30,19,18],
[70,30,20,0],
[70,30,20,1],
[70,30,20,2],
[70,30,20,3],
[70,30,20,4],
[70,30,20,5],
[70,30,20,6],
[70,30,20,8],
[70,30,20,9],
[70,30,20,11],
[70,30,20,12],
[70,30,20,13],
[70,30,20,14],
[70,30,20,15],
[70,30,20,16],
[70,30,20,17],
[70,30,20,19],
[70,30,21,0],
[70,30,21,1],
[70,30,21,2],
[70,30,21,4],
[70,30,21,9],
[70,30,21,10],
[70,30,21,11],
[70,30,21,12],
[70,30,21,13],
[70,30,21,14],
[70,30,21,15],
[70,30,21,18],
[70,30,21,19],
[70,30,21,20],
[70,30,22,0],
[70,30,22,1],
[70,30,22,2],
[70,30,22,3],
[70,30,22,4],
[70,30,22,5],
[70,30,22,6],
[70,30,22,8],
[70,30,22,9],
[70,30,22,11],
[70,30,22,12],
[70,30,22,13],
[70,30,22,14],
[70,30,22,16],
[70,30,22,17],
[70,30,22,18],
[70,30,22,19],
[70,30,22,21],
[70,30,24,0],
[70,30,24,1],
[70,30,24,2],
[70,30,24,3],
[70,30,24,4],
[70,30,24,5],
[70,30,24,6],
[70,30,24,8],
[70,30,24,9],
[70,30,24,10],
[70,30,24,11],
[70,30,24,12],
[70,30,24,13],
[70,30,24,15],
[70,30,24,17],
[70,30,24,18],
[70,30,24,19],
[70,30,24,20],
[70,30,24,21],
[70,30,24,22],
[70,30,26,0],
[70,30,26,1],
[70,30,26,2],
[70,30,26,3],
[70,30,26,4],
[70,30,26,5],
[70,30,26,6],
[70,30,26,10],
[70,30,26,11],
[70,30,26,12],
[70,30,26,13],
[70,30,26,14],
[70,30,26,16],
[70,30,26,17],
[70,30,26,19],
[70,30,26,20],
[70,30,26,21],
[70,30,26,22],
[70,30,26,24],
[70,30,27,0],
[70,30,27,1],
[70,30,27,2],
[70,30,27,3],
[70,30,27,4],
[70,30,27,5],
[70,30,27,8],
[70,30,27,9],
[70,30,27,11],
[70,30,27,12],
[70,30,27,13],
[70,30,27,14],
[70,30,27,15],
[70,30,27,16],
[70,30,27,17],
[70,30,27,18],
[70,30,27,19],
[70,30,27,21],
[70,30,27,24],
[70,30,29,0],
[70,30,29,1],
[70,30,29,2],
[70,30,29,5],
[70,30,29,6],
[70,30,29,8],
[70,30,29,9],
[70,30,29,10],
[70,30,29,13],
[70,30,29,14],
[70,30,29,15],
[70,30,29,16],
[70,30,29,17],
[70,30,29,18],
[70,30,29,19],
[70,30,29,20],
[70,30,29,21],
[70,31,3,0],
[70,31,3,1],
[70,31,3,2],
[70,31,4,0],
[70,31,4,1],
[70,31,4,2],
[70,31,4,3],
[70,31,5,0],
[70,31,5,1],
[70,31,5,2],
[70,31,5,4],
[70,31,6,0],
[70,31,6,1],
[70,31,6,2],
[70,31,6,4],
[70,31,8,0],
[70,31,8,1],
[70,31,8,2],
[70,31,8,4],
[70,31,9,0],
[70,31,9,1],
[70,31,9,2],
[70,31,9,3],
[70,31,9,4],
[70,31,9,5],
[70,31,9,6],
[70,31,9,8],
[70,31,10,0],
[70,31,10,1],
[70,31,10,2],
[70,31,10,3],
[70,31,10,4],
[70,31,10,5],
[70,31,10,6],
[70,31,10,8],
[70,31,10,9],
[70,31,11,0],
[70,31,11,1],
[70,31,11,2],
[70,31,11,3],
[70,31,11,4],
[70,31,11,5],
[70,31,11,6],
[70,31,11,8],
[70,31,11,9],
[70,31,11,10],
[70,31,12,0],
[70,31,12,1],
[70,31,12,2],
[70,31,12,3],
[70,31,12,4],
[70,31,12,5],
[70,31,12,6],
[70,31,12,8],
[70,31,12,9],
[70,31,12,10],
[70,31,13,0],
[70,31,13,1],
[70,31,13,2],
[70,31,13,3],
[70,31,13,4],
[70,31,13,5],
[70,31,13,6],
[70,31,13,8],
[70,31,13,9],
[70,31,13,10],
[70,31,13,11],
[70,31,13,12],
[70,31,14,0],
[70,31,14,1],
[70,31,14,2],
[70,31,14,3],
[70,31,14,4],
[70,31,14,5],
[70,31,14,6],
[70,31,14,8],
[70,31,14,9],
[70,31,14,10],
[70,31,14,11],
[70,31,14,12],
[70,31,15,0],
[70,31,15,1],
[70,31,15,2],
[70,31,15,3],
[70,31,15,4],
[70,31,15,5],
[70,31,15,6],
[70,31,15,8],
[70,31,15,10],
[70,31,15,11],
[70,31,15,12],
[70,31,15,13],
[70,31,15,14],
[70,31,16,0],
[70,31,16,1],
[70,31,16,2],
[70,31,16,3],
[70,31,16,4],
[70,31,16,5],
[70,31,16,6],
[70,31,16,8],
[70,31,16,9],
[70,31,16,10],
[70,31,16,11],
[70,31,16,12],
[70,31,16,13],
[70,31,16,14],
[70,31,16,15],
[70,31,17,0],
[70,31,17,1],
[70,31,17,2],
[70,31,17,3],
[70,31,17,4],
[70,31,17,5],
[70,31,17,6],
[70,31,17,8],
[70,31,17,9],
[70,31,17,10],
[70,31,17,11],
[70,31,17,12],
[70,31,17,13],
[70,31,17,14],
[70,31,17,15],
[70,31,17,16],
[70,31,18,0],
[70,31,18,1],
[70,31,18,2],
[70,31,18,3],
[70,31,18,4],
[70,31,18,5],
[70,31,18,6],
[70,31,18,8],
[70,31,18,10],
[70,31,18,11],
[70,31,18,12],
[70,31,18,13],
[70,31,18,14],
[70,31,18,16],
[70,31,18,17],
[70,31,19,0],
[70,31,19,1],
[70,31,19,2],
[70,31,19,3],
[70,31,19,4],
[70,31,19,5],
[70,31,19,6],
[70,31,19,8],
[70,31,19,9],
[70,31,19,10],
[70,31,19,11],
[70,31,19,12],
[70,31,19,15],
[70,31,19,16],
[70,31,19,17],
[70,31,20,0],
[70,31,20,1],
[70,31,20,2],
[70,31,20,3],
[70,31,20,4],
[70,31,20,5],
[70,31,20,6],
[70,31,20,8],
[70,31,20,9],
[70,31,20,11],
[70,31,20,12],
[70,31,20,13],
[70,31,20,14],
[70,31,20,15],
[70,31,20,18],
[70,31,20,19],
[70,31,21,0],
[70,31,21,1],
[70,31,21,2],
[70,31,21,4],
[70,31,21,9],
[70,31,21,10],
[70,31,21,11],
[70,31,21,12],
[70,31,21,13],
[70,31,21,14],
[70,31,21,16],
[70,31,21,17],
[70,31,21,18],
[70,31,21,19],
[70,31,21,20],
[70,31,22,0],
[70,31,22,1],
[70,31,22,2],
[70,31,22,3],
[70,31,22,4],
[70,31,22,5],
[70,31,22,6],
[70,31,22,8],
[70,31,22,9],
[70,31,22,11],
[70,31,22,12],
[70,31,22,13],
[70,31,22,15],
[70,31,22,16],
[70,31,22,17],
[70,31,22,18],
[70,31,22,19],
[70,31,22,21],
[70,31,24,0],
[70,31,24,1],
[70,31,24,2],
[70,31,24,3],
[70,31,24,4],
[70,31,24,5],
[70,31,24,6],
[70,31,24,8],
[70,31,24,9],
[70,31,24,10],
[70,31,24,11],
[70,31,24,14],
[70,31,24,15],
[70,31,24,17],
[70,31,24,18],
[70,31,24,19],
[70,31,24,20],
[70,31,24,21],
[70,31,24,22],
[70,31,26,0],
[70,31,26,1],
[70,31,26,2],
[70,31,26,3],
[70,31,26,4],
[70,31,26,5],
[70,31,26,8],
[70,31,26,10],
[70,31,26,11],
[70,31,26,12],
[70,31,26,13],
[70,31,26,14],
[70,31,26,16],
[70,31,26,17],
[70,31,26,19],
[70,31,26,20],
[70,31,26,21],
[70,31,26,22],
[70,31,26,24],
[70,31,27,0],
[70,31,27,1],
[70,31,27,2],
[70,31,27,3],
[70,31,27,4],
[70,31,27,6],
[70,31,27,8],
[70,31,27,9],
[70,31,27,11],
[70,31,27,12],
[70,31,27,13],
[70,31,27,14],
[70,31,27,15],
[70,31,27,16],
[70,31,27,17],
[70,31,27,18],
[70,31,27,19],
[70,31,27,21],
[70,31,29,3],
[70,31,29,4],
[70,31,29,5],
[70,31,29,6],
[70,31,29,8],
[70,31,29,9],
[70,31,29,10],
[70,31,29,13],
[70,31,29,14],
[70,31,29,15],
[70,31,29,16],
[70,31,29,17],
[70,31,29,18],
[70,31,29,19],
[70,31,29,20],
[70,32,3,0],
[70,32,3,1],
[70,32,3,2],
[70,32,4,0],
[70,32,4,1],
[70,32,4,2],
[70,32,4,3],
[70,32,5,0],
[70,32,5,1],
[70,32,5,2],
[70,32,5,4],
[70,32,6,0],
[70,32,6,1],
[70,32,6,2],
[70,32,6,4],
[70,32,8,0],
[70,32,8,1],
[70,32,8,2],
[70,32,8,4],
[70,32,9,0],
[70,32,9,1],
[70,32,9,2],
[70,32,9,3],
[70,32,9,4],
[70,32,9,5],
[70,32,9,6],
[70,32,9,8],
[70,32,10,0],
[70,32,10,1],
[70,32,10,2],
[70,32,10,3],
[70,32,10,4],
[70,32,10,5],
[70,32,10,6],
[70,32,10,8],
[70,32,10,9],
[70,32,13,0],
[70,32,13,1],
[70,32,13,2],
[70,32,13,3],
[70,32,13,4],
[70,32,13,5],
[70,32,13,6],
[70,32,13,8],
[70,32,13,9],
[70,32,13,10],
[70,32,14,0],
[70,32,14,1],
[70,32,14,2],
[70,32,14,3],
[70,32,14,4],
[70,32,14,5],
[70,32,14,6],
[70,32,14,8],
[70,32,14,9],
[70,32,14,10],
[70,32,15,0],
[70,32,15,1],
[70,32,15,2],
[70,32,15,3],
[70,32,15,4],
[70,32,15,5],
[70,32,15,6],
[70,32,15,8],
[70,32,15,10],
[70,32,15,13],
[70,32,15,14],
[70,32,16,0],
[70,32,16,1],
[70,32,16,2],
[70,32,16,3],
[70,32,16,4],
[70,32,16,5],
[70,32,16,6],
[70,32,16,8],
[70,32,16,9],
[70,32,16,10],
[70,32,16,13],
[70,32,16,14],
[70,32,16,15],
[70,32,17,0],
[70,32,17,1],
[70,32,17,2],
[70,32,17,3],
[70,32,17,4],
[70,32,17,5],
[70,32,17,6],
[70,32,17,8],
[70,32,17,9],
[70,32,17,10],
[70,32,17,13],
[70,32,17,14],
[70,32,17,15],
[70,32,17,16],
[70,32,18,0],
[70,32,18,1],
[70,32,18,2],
[70,32,18,3],
[70,32,18,4],
[70,32,18,5],
[70,32,18,6],
[70,32,18,8],
[70,32,18,10],
[70,32,18,13],
[70,32,18,14],
[70,32,18,16],
[70,32,18,17],
[70,32,19,0],
[70,32,19,1],
[70,32,19,2],
[70,32,19,3],
[70,32,19,4],
[70,32,19,5],
[70,32,19,6],
[70,32,19,8],
[70,32,19,9],
[70,32,19,10],
[70,32,19,15],
[70,32,19,18],
[70,32,20,0],
[70,32,20,1],
[70,32,20,2],
[70,32,20,3],
[70,32,20,4],
[70,32,20,5],
[70,32,20,6],
[70,32,20,8],
[70,32,20,9],
[70,32,20,13],
[70,32,20,14],
[70,32,20,16],
[70,32,20,17],
[70,32,20,18],
[70,32,20,19],
[70,32,21,0],
[70,32,21,1],
[70,32,21,2],
[70,32,21,4],
[70,32,21,9],
[70,32,21,10],
[70,32,21,13],
[70,32,21,15],
[70,32,21,16],
[70,32,21,17],
[70,32,21,18],
[70,32,21,19],
[70,32,21,20],
[70,32,22,0],
[70,32,22,1],
[70,32,22,2],
[70,32,22,3],
[70,32,22,4],
[70,32,22,5],
[70,32,22,6],
[70,32,22,8],
[70,32,22,9],
[70,32,22,14],
[70,32,22,15],
[70,32,22,16],
[70,32,22,17],
[70,32,22,18],
[70,32,22,19],
[70,32,22,21],
[70,32,23,0],
[70,32,23,1],
[70,32,23,2],
[70,32,23,3],
[70,32,23,4],
[70,32,23,5],
[70,32,23,6],
[70,32,23,8],
[70,32,23,9],
[70,32,23,13],
[70,32,23,14],
[70,32,23,15],
[70,32,23,16],
[70,32,23,17],
[70,32,23,18],
[70,32,23,19],
[70,32,23,20],
[70,32,23,21],
[70,32,23,22],
[70,32,24,0],
[70,32,24,1],
[70,32,24,2],
[70,32,24,3],
[70,32,24,4],
[70,32,24,5],
[70,32,24,6],
[70,32,24,8],
[70,32,24,9],
[70,32,24,13],
[70,32,24,14],
[70,32,24,15],
[70,32,24,17],
[70,32,24,18],
[70,32,24,19],
[70,32,24,20],
[70,32,24,21],
[70,32,24,22],
[70,32,24,23],
[70,32,26,0],
[70,32,26,1],
[70,32,26,2],
[70,32,26,3],
[70,32,26,4],
[70,32,26,6],
[70,32,26,8],
[70,32,26,10],
[70,32,26,13],
[70,32,26,14],
[70,32,26,16],
[70,32,26,17],
[70,32,26,19],
[70,32,26,20],
[70,32,26,21],
[70,32,26,22],
[70,32,27,0],
[70,32,27,1],
[70,32,27,2],
[70,32,27,5],
[70,32,27,6],
[70,32,27,8],
[70,32,27,9],
[70,32,27,13],
[70,32,27,14],
[70,32,27,15],
[70,32,27,16],
[70,32,27,17],
[70,32,27,18],
[70,32,27,19],
[70,32,27,21],
[70,32,30,0],
[70,32,30,1],
[70,32,30,2],
[70,32,30,3],
[70,32,30,4],
[70,32,30,5],
[70,32,30,6],
[70,32,30,8],
[70,32,30,9],
[70,32,30,10],
[70,32,30,13],
[70,32,30,14],
[70,32,30,15],
[70,32,30,16],
[70,32,30,17],
[70,32,30,18],
[70,32,31,0],
[70,32,31,1],
[70,32,31,2],
[70,32,31,3],
[70,32,31,4],
[70,32,31,5],
[70,32,31,6],
[70,32,31,8],
[70,32,31,9],
[70,32,31,10],
[70,32,31,13],
[70,32,31,14],
[70,32,31,15],
[70,32,31,16],
[70,32,31,17],
[70,33,3,0],
[70,33,3,1],
[70,33,3,2],
[70,33,4,0],
[70,33,4,1],
[70,33,4,2],
[70,33,4,3],
[70,33,5,0],
[70,33,5,1],
[70,33,5,2],
[70,33,5,4],
[70,33,6,0],
[70,33,6,1],
[70,33,6,2],
[70,33,6,4],
[70,33,8,0],
[70,33,8,1],
[70,33,8,2],
[70,33,8,4],
[70,33,9,0],
[70,33,9,1],
[70,33,9,2],
[70,33,9,3],
[70,33,9,4],
[70,33,9,5],
[70,33,9,6],
[70,33,9,8],
[70,33,10,0],
[70,33,10,1],
[70,33,10,2],
[70,33,10,3],
[70,33,10,4],
[70,33,10,5],
[70,33,10,6],
[70,33,10,8],
[70,33,10,9],
[70,33,13,0],
[70,33,13,1],
[70,33,13,2],
[70,33,13,3],
[70,33,13,4],
[70,33,13,5],
[70,33,13,6],
[70,33,13,8],
[70,33,13,9],
[70,33,13,10],
[70,33,14,0],
[70,33,14,1],
[70,33,14,2],
[70,33,14,3],
[70,33,14,4],
[70,33,14,5],
[70,33,14,6],
[70,33,14,8],
[70,33,14,9],
[70,33,14,10],
[70,33,15,0],
[70,33,15,1],
[70,33,15,2],
[70,33,15,3],
[70,33,15,4],
[70,33,15,5],
[70,33,15,6],
[70,33,15,8],
[70,33,15,10],
[70,33,15,13],
[70,33,15,14],
[70,33,16,0],
[70,33,16,1],
[70,33,16,2],
[70,33,16,3],
[70,33,16,4],
[70,33,16,5],
[70,33,16,6],
[70,33,16,8],
[70,33,16,9],
[70,33,16,10],
[70,33,16,13],
[70,33,16,14],
[70,33,16,15],
[70,33,17,0],
[70,33,17,1],
[70,33,17,2],
[70,33,17,3],
[70,33,17,4],
[70,33,17,5],
[70,33,17,6],
[70,33,17,8],
[70,33,17,9],
[70,33,17,10],
[70,33,17,13],
[70,33,17,14],
[70,33,17,15],
[70,33,17,16],
[70,33,18,0],
[70,33,18,1],
[70,33,18,2],
[70,33,18,3],
[70,33,18,4],
[70,33,18,5],
[70,33,18,6],
[70,33,18,8],
[70,33,18,10],
[70,33,18,13],
[70,33,18,14],
[70,33,19,0],
[70,33,19,1],
[70,33,19,2],
[70,33,19,3],
[70,33,19,4],
[70,33,19,5],
[70,33,19,6],
[70,33,19,8],
[70,33,19,9],
[70,33,19,10],
[70,33,19,16],
[70,33,19,17],
[70,33,19,18],
[70,33,20,0],
[70,33,20,1],
[70,33,20,2],
[70,33,20,3],
[70,33,20,4],
[70,33,20,5],
[70,33,20,6],
[70,33,20,8],
[70,33,20,9],
[70,33,20,13],
[70,33,20,15],
[70,33,20,16],
[70,33,20,17],
[70,33,20,18],
[70,33,20,19],
[70,33,21,0],
[70,33,21,1],
[70,33,21,2],
[70,33,21,4],
[70,33,21,9],
[70,33,21,10],
[70,33,21,14],
[70,33,21,15],
[70,33,21,16],
[70,33,21,17],
[70,33,21,18],
[70,33,21,19],
[70,33,21,20],
[70,33,22,0],
[70,33,22,1],
[70,33,22,2],
[70,33,22,3],
[70,33,22,4],
[70,33,22,5],
[70,33,22,6],
[70,33,22,8],
[70,33,22,9],
[70,33,22,13],
[70,33,22,14],
[70,33,22,15],
[70,33,22,16],
[70,33,22,17],
[70,33,22,18],
[70,33,22,19],
[70,33,22,21],
[70,33,23,0],
[70,33,23,1],
[70,33,23,2],
[70,33,23,3],
[70,33,23,4],
[70,33,23,5],
[70,33,23,6],
[70,33,23,10],
[70,33,23,13],
[70,33,23,14],
[70,33,23,15],
[70,33,23,16],
[70,33,23,17],
[70,33,23,18],
[70,33,23,19],
[70,33,23,20],
[70,33,23,21],
[70,33,23,22],
[70,33,24,0],
[70,33,24,1],
[70,33,24,2],
[70,33,24,3],
[70,33,24,4],
[70,33,24,5],
[70,33,24,6],
[70,33,24,10],
[70,33,24,13],
[70,33,24,14],
[70,33,24,15],
[70,33,24,17],
[70,33,24,18],
[70,33,24,19],
[70,33,24,20],
[70,33,24,21],
[70,33,24,22],
[70,33,24,23],
[70,33,26,0],
[70,33,26,1],
[70,33,26,2],
[70,33,26,5],
[70,33,26,6],
[70,33,26,8],
[70,33,26,10],
[70,33,26,13],
[70,33,26,14],
[70,33,26,16],
[70,33,26,17],
[70,33,26,19],
[70,33,26,20],
[70,33,26,21],
[70,33,27,3],
[70,33,27,4],
[70,33,27,5],
[70,33,27,6],
[70,33,27,8],
[70,33,27,9],
[70,33,27,13],
[70,33,27,14],
[70,33,27,15],
[70,33,27,16],
[70,33,27,17],
[70,33,27,18],
[70,33,27,19],
[70,33,30,0],
[70,33,30,1],
[70,33,30,2],
[70,33,30,3],
[70,33,30,4],
[70,33,30,5],
[70,33,30,6],
[70,33,30,8],
[70,33,30,9],
[70,33,30,10],
[70,33,30,13],
[70,33,30,14],
[70,33,30,15],
[70,33,30,16],
[70,33,30,17],
[70,33,31,0],
[70,33,31,1],
[70,33,31,2],
[70,33,31,3],
[70,33,31,4],
[70,33,31,5],
[70,33,31,6],
[70,33,31,8],
[70,33,31,9],
[70,33,31,10],
[70,33,31,13],
[70,33,31,14],
[70,33,31,15],
[70,35,3,0],
[70,35,3,1],
[70,35,3,2],
[70,35,4,0],
[70,35,4,1],
[70,35,4,2],
[70,35,4,3],
[70,35,5,0],
[70,35,5,1],
[70,35,5,2],
[70,35,5,4],
[70,35,6,0],
[70,35,6,1],
[70,35,6,2],
[70,35,6,4],
[70,35,8,0],
[70,35,8,1],
[70,35,8,2],
[70,35,8,4],
[70,35,10,0],
[70,35,10,1],
[70,35,10,2],
[70,35,10,3],
[70,35,10,4],
[70,35,10,5],
[70,35,10,6],
[70,35,10,8],
[70,35,11,0],
[70,35,11,1],
[70,35,11,2],
[70,35,11,3],
[70,35,11,4],
[70,35,11,5],
[70,35,11,6],
[70,35,11,8],
[70,35,11,10],
[70,35,12,0],
[70,35,12,1],
[70,35,12,2],
[70,35,12,3],
[70,35,12,4],
[70,35,12,5],
[70,35,12,6],
[70,35,12,8],
[70,35,12,10],
[70,35,13,0],
[70,35,13,1],
[70,35,13,2],
[70,35,13,3],
[70,35,13,4],
[70,35,13,5],
[70,35,13,6],
[70,35,13,8],
[70,35,13,10],
[70,35,13,11],
[70,35,13,12],
[70,35,14,0],
[70,35,14,1],
[70,35,14,2],
[70,35,14,3],
[70,35,14,4],
[70,35,14,5],
[70,35,14,6],
[70,35,14,8],
[70,35,14,10],
[70,35,14,11],
[70,35,14,12],
[70,35,16,0],
[70,35,16,1],
[70,35,16,2],
[70,35,16,3],
[70,35,16,4],
[70,35,16,5],
[70,35,16,6],
[70,35,16,8],
[70,35,16,10],
[70,35,16,11],
[70,35,16,12],
[70,35,16,13],
[70,35,16,14],
[70,35,17,0],
[70,35,17,1],
[70,35,17,2],
[70,35,17,3],
[70,35,17,4],
[70,35,17,5],
[70,35,17,6],
[70,35,17,8],
[70,35,17,10],
[70,35,17,11],
[70,35,17,12],
[70,35,17,13],
[70,35,17,14],
[70,35,17,16],
[70,35,19,0],
[70,35,19,1],
[70,35,19,2],
[70,35,19,3],
[70,35,19,4],
[70,35,19,5],
[70,35,19,6],
[70,35,19,8],
[70,35,19,10],
[70,35,19,11],
[70,35,19,16],
[70,35,19,17],
[70,35,20,0],
[70,35,20,1],
[70,35,20,2],
[70,35,20,3],
[70,35,20,4],
[70,35,20,5],
[70,35,20,6],
[70,35,20,8],
[70,35,20,12],
[70,35,20,13],
[70,35,20,14],
[70,35,20,16],
[70,35,20,17],
[70,35,20,19],
[70,35,21,0],
[70,35,21,1],
[70,35,21,2],
[70,35,21,4],
[70,35,21,10],
[70,35,21,11],
[70,35,21,12],
[70,35,21,13],
[70,35,21,14],
[70,35,21,16],
[70,35,21,17],
[70,35,21,19],
[70,35,21,20],
[70,35,22,0],
[70,35,22,1],
[70,35,22,2],
[70,35,22,3],
[70,35,22,4],
[70,35,22,5],
[70,35,22,8],
[70,35,22,11],
[70,35,22,12],
[70,35,22,13],
[70,35,22,14],
[70,35,22,16],
[70,35,22,17],
[70,35,22,19],
[70,35,22,21],
[70,35,23,0],
[70,35,23,1],
[70,35,23,2],
[70,35,23,3],
[70,35,23,4],
[70,35,23,6],
[70,35,23,8],
[70,35,23,10],
[70,35,23,11],
[70,35,23,12],
[70,35,23,13],
[70,35,23,14],
[70,35,23,16],
[70,35,23,17],
[70,35,23,19],
[70,35,23,20],
[70,35,23,21],
[70,35,23,22],
[70,35,24,0],
[70,35,24,1],
[70,35,24,2],
[70,35,24,3],
[70,35,24,4],
[70,35,24,6],
[70,35,24,8],
[70,35,24,10],
[70,35,24,11],
[70,35,24,12],
[70,35,24,13],
[70,35,24,14],
[70,35,24,17],
[70,35,24,19],
[70,35,24,20],
[70,35,24,21],
[70,35,24,22],
[70,35,27,0],
[70,35,27,1],
[70,35,27,2],
[70,35,27,3],
[70,35,27,4],
[70,35,27,5],
[70,35,27,6],
[70,35,27,8],
[70,35,27,11],
[70,35,27,12],
[70,35,27,13],
[70,35,27,14],
[70,35,27,16],
[70,35,27,17],
[70,35,29,0],
[70,35,29,1],
[70,35,29,2],
[70,35,29,3],
[70,35,29,4],
[70,35,29,5],
[70,35,29,6],
[70,35,29,8],
[70,35,29,10],
[70,35,29,13],
[70,35,29,14],
[70,35,30,0],
[70,35,30,1],
[70,35,30,2],
[70,35,30,3],
[70,35,30,4],
[70,35,30,5],
[70,35,30,6],
[70,35,30,8],
[70,35,30,10],
[70,35,30,11],
[70,35,30,12],
[70,35,30,13],
[70,35,30,14],
[70,35,31,0],
[70,35,31,1],
[70,35,31,2],
[70,35,31,3],
[70,35,31,4],
[70,35,31,5],
[70,35,31,6],
[70,35,31,8],
[70,35,31,10],
[70,35,31,11],
[70,35,31,12],
[70,35,31,13],
[70,35,32,0],
[70,35,32,1],
[70,35,32,2],
[70,35,32,3],
[70,35,32,4],
[70,35,32,5],
[70,35,32,6],
[70,35,32,8],
[70,35,32,10],
[70,35,33,0],
[70,35,33,1],
[70,35,33,2],
[70,35,33,3],
[70,35,33,4],
[70,35,33,5],
[70,35,33,6],
[70,35,33,8],
[70,36,3,0],
[70,36,3,1],
[70,36,3,2],
[70,36,4,0],
[70,36,4,1],
[70,36,4,2],
[70,36,4,3],
[70,36,5,0],
[70,36,5,1],
[70,36,5,2],
[70,36,5,4],
[70,36,6,0],
[70,36,6,1],
[70,36,6,2],
[70,36,6,4],
[70,36,8,0],
[70,36,8,1],
[70,36,8,2],
[70,36,8,4],
[70,36,9,0],
[70,36,9,1],
[70,36,9,2],
[70,36,9,3],
[70,36,9,4],
[70,36,9,5],
[70,36,9,6],
[70,36,9,8],
[70,36,10,0],
[70,36,10,1],
[70,36,10,2],
[70,36,10,3],
[70,36,10,4],
[70,36,10,5],
[70,36,10,6],
[70,36,10,8],
[70,36,10,9],
[70,36,11,0],
[70,36,11,1],
[70,36,11,2],
[70,36,11,3],
[70,36,11,4],
[70,36,11,5],
[70,36,11,6],
[70,36,11,8],
[70,36,11,9],
[70,36,11,10],
[70,36,12,0],
[70,36,12,1],
[70,36,12,2],
[70,36,12,3],
[70,36,12,4],
[70,36,12,5],
[70,36,12,6],
[70,36,12,8],
[70,36,12,9],
[70,36,12,10],
[70,36,15,0],
[70,36,15,1],
[70,36,15,2],
[70,36,15,3],
[70,36,15,4],
[70,36,15,5],
[70,36,15,6],
[70,36,15,8],
[70,36,15,10],
[70,36,15,11],
[70,36,15,12],
[70,36,16,0],
[70,36,16,1],
[70,36,16,2],
[70,36,16,3],
[70,36,16,4],
[70,36,16,5],
[70,36,16,6],
[70,36,16,8],
[70,36,16,9],
[70,36,16,10],
[70,36,16,11],
[70,36,16,12],
[70,36,16,15],
[70,36,17,0],
[70,36,17,1],
[70,36,17,2],
[70,36,17,3],
[70,36,17,4],
[70,36,17,5],
[70,36,17,6],
[70,36,17,8],
[70,36,17,9],
[70,36,17,10],
[70,36,17,11],
[70,36,17,12],
[70,36,17,15],
[70,36,17,16],
[70,36,18,0],
[70,36,18,1],
[70,36,18,2],
[70,36,18,3],
[70,36,18,4],
[70,36,18,5],
[70,36,18,6],
[70,36,18,8],
[70,36,18,10],
[70,36,18,11],
[70,36,18,16],
[70,36,18,17],
[70,36,20,0],
[70,36,20,1],
[70,36,20,2],
[70,36,20,3],
[70,36,20,4],
[70,36,20,5],
[70,36,20,6],
[70,36,20,11],
[70,36,20,12],
[70,36,20,15],
[70,36,20,16],
[70,36,20,17],
[70,36,20,18],
[70,36,21,0],
[70,36,21,1],
[70,36,21,2],
[70,36,21,4],
[70,36,21,9],
[70,36,21,10],
[70,36,21,11],
[70,36,21,12],
[70,36,21,15],
[70,36,21,16],
[70,36,21,17],
[70,36,21,18],
[70,36,21,20],
[70,36,22,0],
[70,36,22,1],
[70,36,22,2],
[70,36,22,3],
[70,36,22,4],
[70,36,22,6],
[70,36,22,8],
[70,36,22,9],
[70,36,22,11],
[70,36,22,12],
[70,36,22,15],
[70,36,22,16],
[70,36,22,17],
[70,36,22,18],
[70,36,22,21],
[70,36,23,0],
[70,36,23,1],
[70,36,23,2],
[70,36,23,5],
[70,36,23,6],
[70,36,23,8],
[70,36,23,9],
[70,36,23,10],
[70,36,23,11],
[70,36,23,12],
[70,36,23,15],
[70,36,23,16],
[70,36,23,17],
[70,36,23,18],
[70,36,23,20],
[70,36,23,21],
[70,36,24,0],
[70,36,24,1],
[70,36,24,2],
[70,36,24,5],
[70,36,24,6],
[70,36,24,8],
[70,36,24,9],
[70,36,24,10],
[70,36,24,11],
[70,36,24,12],
[70,36,24,15],
[70,36,24,17],
[70,36,24,18],
[70,36,24,20],
[70,36,24,21],
[70,36,26,0],
[70,36,26,1],
[70,36,26,2],
[70,36,26,3],
[70,36,26,4],
[70,36,26,5],
[70,36,26,6],
[70,36,26,8],
[70,36,26,10],
[70,36,26,11],
[70,36,26,12],
[70,36,26,16],
[70,36,26,17],
[70,36,27,0],
[70,36,27,1],
[70,36,27,2],
[70,36,27,3],
[70,36,27,4],
[70,36,27,5],
[70,36,27,6],
[70,36,27,8],
[70,36,27,9],
[70,36,27,11],
[70,36,27,12],
[70,36,27,15],
[70,36,27,16],
[70,36,27,17],
[70,36,29,0],
[70,36,29,1],
[70,36,29,2],
[70,36,29,3],
[70,36,29,4],
[70,36,29,5],
[70,36,29,6],
[70,36,29,8],
[70,36,29,9],
[70,36,29,10],
[70,36,30,0],
[70,36,30,1],
[70,36,30,2],
[70,36,30,3],
[70,36,30,4],
[70,36,30,5],
[70,36,30,6],
[70,36,30,8],
[70,36,30,9],
[70,36,30,10],
[70,36,30,11],
[70,36,30,12],
[70,36,31,0],
[70,36,31,1],
[70,36,31,2],
[70,36,31,3],
[70,36,31,4],
[70,36,31,5],
[70,36,31,6],
[70,36,31,8],
[70,36,31,9],
[70,36,31,10],
[70,36,31,11],
[70,36,32,0],
[70,36,32,1],
[70,36,32,2],
[70,36,32,3],
[70,36,32,4],
[70,36,32,5],
[70,36,32,6],
[70,36,32,8],
[70,36,32,9],
[70,36,33,0],
[70,36,33,1],
[70,36,33,2],
[70,36,33,3],
[70,36,33,4],
[70,36,33,5],
[70,36,33,6],
[70,36,35,0],
[70,36,35,1],
[70,36,35,2],
[70,36,35,3],
[70,36,35,4],
[70,37,3,0],
[70,37,3,1],
[70,37,3,2],
[70,37,5,0],
[70,37,5,1],
[70,37,5,2],
[70,37,6,0],
[70,37,6,1],
[70,37,6,2],
[70,37,8,0],
[70,37,8,1],
[70,37,8,2],
[70,37,9,0],
[70,37,9,1],
[70,37,9,2],
[70,37,9,3],
[70,37,9,5],
[70,37,9,6],
[70,37,9,8],
[70,37,10,0],
[70,37,10,1],
[70,37,10,2],
[70,37,10,3],
[70,37,10,5],
[70,37,10,6],
[70,37,10,8],
[70,37,10,9],
[70,37,11,0],
[70,37,11,1],
[70,37,11,2],
[70,37,11,3],
[70,37,11,5],
[70,37,11,6],
[70,37,11,8],
[70,37,11,9],
[70,37,11,10],
[70,37,12,0],
[70,37,12,1],
[70,37,12,2],
[70,37,12,3],
[70,37,12,5],
[70,37,12,6],
[70,37,12,8],
[70,37,12,9],
[70,37,12,10],
[70,37,13,0],
[70,37,13,1],
[70,37,13,2],
[70,37,13,3],
[70,37,13,5],
[70,37,13,6],
[70,37,13,8],
[70,37,13,9],
[70,37,13,10],
[70,37,13,11],
[70,37,13,12],
[70,37,14,0],
[70,37,14,1],
[70,37,14,2],
[70,37,14,3],
[70,37,14,5],
[70,37,14,6],
[70,37,14,8],
[70,37,14,9],
[70,37,14,10],
[70,37,14,11],
[70,37,14,12],
[70,37,15,0],
[70,37,15,1],
[70,37,15,2],
[70,37,15,3],
[70,37,15,5],
[70,37,15,6],
[70,37,15,8],
[70,37,15,10],
[70,37,15,11],
[70,37,15,12],
[70,37,15,13],
[70,37,16,0],
[70,37,16,1],
[70,37,16,2],
[70,37,16,3],
[70,37,16,5],
[70,37,16,6],
[70,37,16,8],
[70,37,16,9],
[70,37,16,10],
[70,37,16,11],
[70,37,16,14],
[70,37,16,15],
[70,37,17,0],
[70,37,17,1],
[70,37,17,2],
[70,37,17,3],
[70,37,17,5],
[70,37,17,6],
[70,37,17,8],
[70,37,17,9],
[70,37,17,10],
[70,37,17,11],
[70,37,17,14],
[70,37,17,15],
[70,37,17,16],
[70,37,18,0],
[70,37,18,1],
[70,37,18,2],
[70,37,18,3],
[70,37,18,5],
[70,37,18,6],
[70,37,18,8],
[70,37,18,12],
[70,37,18,13],
[70,37,18,14],
[70,37,18,16],
[70,37,18,17],
[70,37,19,0],
[70,37,19,1],
[70,37,19,2],
[70,37,19,3],
[70,37,19,5],
[70,37,19,6],
[70,37,19,10],
[70,37,19,11],
[70,37,19,12],
[70,37,19,15],
[70,37,19,16],
[70,37,19,17],
[70,37,19,18],
[70,37,20,0],
[70,37,20,1],
[70,37,20,2],
[70,37,20,3],
[70,37,20,5],
[70,37,20,8],
[70,37,20,9],
[70,37,20,11],
[70,37,20,12],
[70,37,20,13],
[70,37,20,14],
[70,37,20,15],
[70,37,20,16],
[70,37,20,17],
[70,37,20,18],
[70,37,20,19],
[70,37,21,0],
[70,37,21,1],
[70,37,21,2],
[70,37,21,9],
[70,37,21,10],
[70,37,21,11],
[70,37,21,12],
[70,37,21,13],
[70,37,21,14],
[70,37,21,15],
[70,37,21,16],
[70,37,21,17],
[70,37,21,18],
[70,37,21,19],
[70,37,21,20],
[70,37,22,0],
[70,37,22,1],
[70,37,22,2],
[70,37,22,5],
[70,37,22,6],
[70,37,22,8],
[70,37,22,9],
[70,37,22,11],
[70,37,22,12],
[70,37,22,13],
[70,37,22,14],
[70,37,22,15],
[70,37,22,16],
[70,37,22,17],
[70,37,22,18],
[70,37,22,19],
[70,37,22,21],
[70,37,23,3],
[70,37,23,5],
[70,37,23,6],
[70,37,23,8],
[70,37,23,9],
[70,37,23,10],
[70,37,23,11],
[70,37,23,12],
[70,37,23,13],
[70,37,23,14],
[70,37,23,15],
[70,37,23,16],
[70,37,23,17],
[70,37,23,18],
[70,37,23,19],
[70,37,23,20],
[70,37,24,3],
[70,37,24,5],
[70,37,24,6],
[70,37,24,8],
[70,37,24,9],
[70,37,24,10],
[70,37,24,11],
[70,37,24,12],
[70,37,24,13],
[70,37,24,14],
[70,37,24,15],
[70,37,24,17],
[70,37,24,18],
[70,37,24,19],
[70,37,24,20],
[70,37,26,0],
[70,37,26,1],
[70,37,26,2],
[70,37,26,3],
[70,37,26,5],
[70,37,26,6],
[70,37,26,8],
[70,37,26,10],
[70,37,26,11],
[70,37,26,12],
[70,37,26,13],
[70,37,26,14],
[70,37,26,16],
[70,37,26,17],
[70,37,27,0],
[70,37,27,1],
[70,37,27,2],
[70,37,27,3],
[70,37,27,5],
[70,37,27,6],
[70,37,27,8],
[70,37,27,9],
[70,37,27,11],
[70,37,27,12],
[70,37,27,13],
[70,37,27,14],
[70,37,27,15],
[70,37,29,0],
[70,37,29,1],
[70,37,29,2],
[70,37,29,3],
[70,37,29,5],
[70,37,29,6],
[70,37,29,8],
[70,37,29,9],
[70,37,29,10],
[70,37,29,13],
[70,37,30,0],
[70,37,30,1],
[70,37,30,2],
[70,37,30,3],
[70,37,30,5],
[70,37,30,6],
[70,37,30,8],
[70,37,30,9],
[70,37,30,10],
[70,37,30,11],
[70,37,31,0],
[70,37,31,1],
[70,37,31,2],
[70,37,31,3],
[70,37,31,5],
[70,37,31,6],
[70,37,31,8],
[70,37,31,9],
[70,37,32,0],
[70,37,32,1],
[70,37,32,2],
[70,37,32,3],
[70,37,32,5],
[70,37,32,6],
[70,37,33,0],
[70,37,33,1],
[70,37,33,2],
[70,37,33,3],
[70,37,33,5],
[70,37,35,0],
[70,37,35,1],
[70,37,35,2],
[70,39,4,0],
[70,39,4,1],
[70,39,4,2],
[70,39,9,0],
[70,39,9,1],
[70,39,9,2],
[70,39,9,4],
[70,39,10,0],
[70,39,10,1],
[70,39,10,2],
[70,39,10,4],
[70,39,10,9],
[70,39,11,0],
[70,39,11,1],
[70,39,11,2],
[70,39,11,4],
[70,39,11,9],
[70,39,11,10],
[70,39,12,0],
[70,39,12,1],
[70,39,12,2],
[70,39,12,4],
[70,39,12,9],
[70,39,12,10],
[70,39,13,0],
[70,39,13,1],
[70,39,13,2],
[70,39,13,4],
[70,39,13,9],
[70,39,13,10],
[70,39,13,11],
[70,39,13,12],
[70,39,14,0],
[70,39,14,1],
[70,39,14,2],
[70,39,14,4],
[70,39,14,9],
[70,39,14,10],
[70,39,14,11],
[70,39,15,0],
[70,39,15,1],
[70,39,15,2],
[70,39,15,4],
[70,39,15,12],
[70,39,15,13],
[70,39,15,14],
[70,39,16,0],
[70,39,16,1],
[70,39,16,2],
[70,39,16,4],
[70,39,16,10],
[70,39,16,11],
[70,39,16,12],
[70,39,16,13],
[70,39,16,14],
[70,39,16,15],
[70,39,17,0],
[70,39,17,1],
[70,39,17,2],
[70,39,17,4],
[70,39,17,10],
[70,39,17,11],
[70,39,17,12],
[70,39,17,13],
[70,39,17,14],
[70,39,17,15],
[70,39,17,16],
[70,39,18,0],
[70,39,18,1],
[70,39,18,2],
[70,39,18,4],
[70,39,18,10],
[70,39,18,11],
[70,39,18,12],
[70,39,18,13],
[70,39,18,14],
[70,39,18,16],
[70,39,18,17],
[70,39,19,0],
[70,39,19,1],
[70,39,19,2],
[70,39,19,4],
[70,39,19,9],
[70,39,19,10],
[70,39,19,11],
[70,39,19,12],
[70,39,19,15],
[70,39,19,16],
[70,39,19,17],
[70,39,19,18],
[70,39,20,0],
[70,39,20,1],
[70,39,20,2],
[70,39,20,9],
[70,39,20,11],
[70,39,20,12],
[70,39,20,13],
[70,39,20,14],
[70,39,20,15],
[70,39,20,16],
[70,39,20,17],
[70,39,20,18],
[70,39,20,19],
[70,39,22,0],
[70,39,22,1],
[70,39,22,2],
[70,39,22,4],
[70,39,22,9],
[70,39,22,11],
[70,39,22,12],
[70,39,22,13],
[70,39,22,14],
[70,39,22,15],
[70,39,22,16],
[70,39,22,17],
[70,39,22,18],
[70,39,22,19],
[70,39,23,0],
[70,39,23,1],
[70,39,23,2],
[70,39,23,4],
[70,39,23,9],
[70,39,23,10],
[70,39,23,11],
[70,39,23,12],
[70,39,23,13],
[70,39,23,14],
[70,39,23,15],
[70,39,23,16],
[70,39,23,17],
[70,39,23,18],
[70,39,24,0],
[70,39,24,1],
[70,39,24,2],
[70,39,24,4],
[70,39,24,9],
[70,39,24,10],
[70,39,24,11],
[70,39,24,12],
[70,39,24,13],
[70,39,24,14],
[70,39,24,15],
[70,39,24,17],
[70,39,24,18],
[70,39,26,0],
[70,39,26,1],
[70,39,26,2],
[70,39,26,4],
[70,39,26,10],
[70,39,26,11],
[70,39,26,12],
[70,39,26,13],
[70,39,26,14],
[70,39,27,0],
[70,39,27,1],
[70,39,27,2],
[70,39,27,4],
[70,39,27,9],
[70,39,27,11],
[70,39,27,12],
[70,39,27,13],
[70,39,29,0],
[70,39,29,1],
[70,39,29,2],
[70,39,29,4],
[70,39,29,9],
[70,39,30,0],
[70,39,30,1],
[70,39,30,2],
[70,39,30,4],
[70,39,31,0],
[70,39,31,1],
[70,39,31,2],
[70,39,31,4],
[70,39,32,0],
[70,39,32,1],
[70,39,32,2],
[70,39,32,4],
[70,39,33,0],
[70,39,33,1],
[70,39,33,2],
[70,40,3,0],
[70,40,3,1],
[70,40,3,2],
[70,40,4,0],
[70,40,4,1],
[70,40,4,2],
[70,40,4,3],
[70,40,5,0],
[70,40,5,1],
[70,40,5,2],
[70,40,5,4],
[70,40,6,0],
[70,40,6,1],
[70,40,6,2],
[70,40,6,4],
[70,40,8,0],
[70,40,8,1],
[70,40,8,2],
[70,40,8,4],
[70,40,10,0],
[70,40,10,1],
[70,40,10,2],
[70,40,10,3],
[70,40,10,4],
[70,40,10,5],
[70,40,10,6],
[70,40,10,8],
[70,40,11,0],
[70,40,11,1],
[70,40,11,2],
[70,40,11,3],
[70,40,11,4],
[70,40,11,5],
[70,40,11,6],
[70,40,11,8],
[70,40,11,10],
[70,40,12,0],
[70,40,12,1],
[70,40,12,2],
[70,40,12,3],
[70,40,12,4],
[70,40,12,5],
[70,40,12,6],
[70,40,12,8],
[70,40,12,10],
[70,40,13,0],
[70,40,13,1],
[70,40,13,2],
[70,40,13,3],
[70,40,13,4],
[70,40,13,5],
[70,40,13,6],
[70,40,13,8],
[70,40,13,10],
[70,40,13,11],
[70,40,14,0],
[70,40,14,1],
[70,40,14,2],
[70,40,14,3],
[70,40,14,4],
[70,40,14,5],
[70,40,14,6],
[70,40,14,8],
[70,40,14,12],
[70,40,16,0],
[70,40,16,1],
[70,40,16,2],
[70,40,16,3],
[70,40,16,4],
[70,40,16,5],
[70,40,16,8],
[70,40,16,10],
[70,40,16,11],
[70,40,16,12],
[70,40,16,13],
[70,40,16,14],
[70,40,17,0],
[70,40,17,1],
[70,40,17,2],
[70,40,17,3],
[70,40,17,4],
[70,40,17,5],
[70,40,17,8],
[70,40,17,10],
[70,40,17,11],
[70,40,17,12],
[70,40,17,13],
[70,40,17,14],
[70,40,17,16],
[70,40,19,0],
[70,40,19,1],
[70,40,19,2],
[70,40,19,5],
[70,40,19,6],
[70,40,19,8],
[70,40,19,10],
[70,40,19,11],
[70,40,19,12],
[70,40,19,16],
[70,40,19,17],
[70,40,20,3],
[70,40,20,4],
[70,40,20,5],
[70,40,20,6],
[70,40,20,8],
[70,40,20,11],
[70,40,20,12],
[70,40,20,13],
[70,40,20,14],
[70,40,20,16],
[70,40,20,17],
[70,40,20,19],
[70,40,21,0],
[70,40,21,1],
[70,40,21,2],
[70,40,21,4],
[70,40,21,10],
[70,40,21,11],
[70,40,21,12],
[70,40,21,13],
[70,40,21,14],
[70,40,21,16],
[70,40,21,17],
[70,40,21,19],
[70,40,22,0],
[70,40,22,1],
[70,40,22,2],
[70,40,22,3],
[70,40,22,4],
[70,40,22,5],
[70,40,22,6],
[70,40,22,8],
[70,40,22,11],
[70,40,22,12],
[70,40,22,13],
[70,40,22,14],
[70,40,22,16],
[70,40,22,17],
[70,40,23,0],
[70,40,23,1],
[70,40,23,2],
[70,40,23,3],
[70,40,23,4],
[70,40,23,5],
[70,40,23,6],
[70,40,23,8],
[70,40,23,10],
[70,40,23,11],
[70,40,23,12],
[70,40,23,13],
[70,40,23,14],
[70,40,23,16],
[70,40,23,17],
[70,40,24,0],
[70,40,24,1],
[70,40,24,2],
[70,40,24,3],
[70,40,24,4],
[70,40,24,5],
[70,40,24,6],
[70,40,24,8],
[70,40,24,10],
[70,40,24,11],
[70,40,24,12],
[70,40,24,13],
[70,40,24,14],
[70,40,24,17],
[70,40,27,0],
[70,40,27,1],
[70,40,27,2],
[70,40,27,3],
[70,40,27,4],
[70,40,27,5],
[70,40,27,6],
[70,40,27,8],
[70,40,27,11],
[70,40,29,0],
[70,40,29,1],
[70,40,29,2],
[70,40,29,3],
[70,40,29,4],
[70,40,29,5],
[70,40,29,6],
[70,40,30,0],
[70,40,30,1],
[70,40,30,2],
[70,40,30,3],
[70,40,30,4],
[70,40,30,5],
[70,40,31,0],
[70,40,31,1],
[70,40,31,2],
[70,40,31,3],
[70,40,31,4],
[70,40,32,0],
[70,40,32,1],
[70,40,32,2],
[70,41,3,0],
[70,41,3,1],
[70,41,3,2],
[70,41,4,0],
[70,41,4,1],
[70,41,4,2],
[70,41,4,3],
[70,41,5,0],
[70,41,5,1],
[70,41,5,2],
[70,41,5,4],
[70,41,6,0],
[70,41,6,1],
[70,41,6,2],
[70,41,6,4],
[70,41,8,0],
[70,41,8,1],
[70,41,8,2],
[70,41,8,4],
[70,41,10,0],
[70,41,10,1],
[70,41,10,2],
[70,41,10,3],
[70,41,10,4],
[70,41,10,5],
[70,41,10,6],
[70,41,10,8],
[70,41,11,0],
[70,41,11,1],
[70,41,11,2],
[70,41,11,3],
[70,41,11,4],
[70,41,11,5],
[70,41,11,6],
[70,41,11,8],
[70,41,11,10],
[70,41,12,0],
[70,41,12,1],
[70,41,12,2],
[70,41,12,3],
[70,41,12,4],
[70,41,12,5],
[70,41,12,6],
[70,41,12,8],
[70,41,12,10],
[70,41,13,0],
[70,41,13,1],
[70,41,13,2],
[70,41,13,3],
[70,41,13,4],
[70,41,13,5],
[70,41,13,6],
[70,41,13,8],
[70,41,13,10],
[70,41,13,11],
[70,41,14,0],
[70,41,14,1],
[70,41,14,2],
[70,41,14,3],
[70,41,14,4],
[70,41,14,5],
[70,41,14,6],
[70,41,14,8],
[70,41,14,12],
[70,41,16,0],
[70,41,16,1],
[70,41,16,2],
[70,41,16,3],
[70,41,16,4],
[70,41,16,5],
[70,41,16,8],
[70,41,16,10],
[70,41,16,11],
[70,41,16,12],
[70,41,16,13],
[70,41,16,14],
[70,41,17,0],
[70,41,17,1],
[70,41,17,2],
[70,41,17,3],
[70,41,17,4],
[70,41,17,5],
[70,41,17,8],
[70,41,17,10],
[70,41,17,11],
[70,41,17,12],
[70,41,17,13],
[70,41,17,14],
[70,41,17,16],
[70,41,19,0],
[70,41,19,1],
[70,41,19,2],
[70,41,19,5],
[70,41,19,6],
[70,41,19,8],
[70,41,19,10],
[70,41,19,11],
[70,41,19,12],
[70,41,19,16],
[70,41,19,17],
[70,41,20,3],
[70,41,20,4],
[70,41,20,5],
[70,41,20,6],
[70,41,20,8],
[70,41,20,11],
[70,41,20,12],
[70,41,20,13],
[70,41,20,14],
[70,41,20,16],
[70,41,20,17],
[70,41,20,19],
[70,41,21,0],
[70,41,21,1],
[70,41,21,2],
[70,41,21,4],
[70,41,21,10],
[70,41,21,11],
[70,41,21,12],
[70,41,21,13],
[70,41,21,14],
[70,41,21,16],
[70,41,21,17],
[70,41,21,19],
[70,41,22,0],
[70,41,22,1],
[70,41,22,2],
[70,41,22,3],
[70,41,22,4],
[70,41,22,5],
[70,41,22,6],
[70,41,22,8],
[70,41,22,11],
[70,41,22,12],
[70,41,22,13],
[70,41,22,14],
[70,41,22,16],
[70,41,22,17],
[70,41,23,0],
[70,41,23,1],
[70,41,23,2],
[70,41,23,3],
[70,41,23,4],
[70,41,23,5],
[70,41,23,6],
[70,41,23,8],
[70,41,23,10],
[70,41,23,11],
[70,41,23,12],
[70,41,23,13],
[70,41,23,14],
[70,41,23,16],
[70,41,23,17],
[70,41,24,0],
[70,41,24,1],
[70,41,24,2],
[70,41,24,3],
[70,41,24,4],
[70,41,24,5],
[70,41,24,6],
[70,41,24,8],
[70,41,24,10],
[70,41,24,11],
[70,41,24,12],
[70,41,24,13],
[70,41,24,14],
[70,41,24,17],
[70,41,27,0],
[70,41,27,1],
[70,41,27,2],
[70,41,27,3],
[70,41,27,4],
[70,41,27,5],
[70,41,27,6],
[70,41,27,8],
[70,41,27,11],
[70,41,29,0],
[70,41,29,1],
[70,41,29,2],
[70,41,29,3],
[70,41,29,4],
[70,41,29,5],
[70,41,29,6],
[70,41,30,0],
[70,41,30,1],
[70,41,30,2],
[70,41,30,3],
[70,41,30,4],
[70,41,30,5],
[70,41,31,0],
[70,41,31,1],
[70,41,31,2],
[70,41,31,3],
[70,41,31,4],
[70,41,32,0],
[70,41,32,1],
[70,41,32,2],
[70,42,3,0],
[70,42,3,1],
[70,42,3,2],
[70,42,4,0],
[70,42,4,1],
[70,42,4,2],
[70,42,4,3],
[70,42,5,0],
[70,42,5,1],
[70,42,5,2],
[70,42,5,4],
[70,42,6,0],
[70,42,6,1],
[70,42,6,2],
[70,42,6,4],
[70,42,8,0],
[70,42,8,1],
[70,42,8,2],
[70,42,8,4],
[70,42,9,0],
[70,42,9,1],
[70,42,9,2],
[70,42,9,3],
[70,42,9,4],
[70,42,9,5],
[70,42,9,6],
[70,42,9,8],
[70,42,11,0],
[70,42,11,1],
[70,42,11,2],
[70,42,11,3],
[70,42,11,4],
[70,42,11,5],
[70,42,11,6],
[70,42,11,8],
[70,42,11,9],
[70,42,12,0],
[70,42,12,1],
[70,42,12,2],
[70,42,12,3],
[70,42,12,4],
[70,42,12,5],
[70,42,12,6],
[70,42,12,8],
[70,42,12,9],
[70,42,13,0],
[70,42,13,1],
[70,42,13,2],
[70,42,13,3],
[70,42,13,4],
[70,42,13,5],
[70,42,13,6],
[70,42,13,8],
[70,42,13,9],
[70,42,13,12],
[70,42,14,0],
[70,42,14,1],
[70,42,14,2],
[70,42,14,3],
[70,42,14,4],
[70,42,14,5],
[70,42,14,6],
[70,42,14,11],
[70,42,14,12],
[70,42,15,0],
[70,42,15,1],
[70,42,15,2],
[70,42,15,3],
[70,42,15,4],
[70,42,15,5],
[70,42,15,8],
[70,42,15,11],
[70,42,15,12],
[70,42,15,13],
[70,42,15,14],
[70,42,16,0],
[70,42,16,1],
[70,42,16,2],
[70,42,16,3],
[70,42,16,4],
[70,42,16,6],
[70,42,16,8],
[70,42,16,9],
[70,42,16,11],
[70,42,16,12],
[70,42,16,13],
[70,42,16,14],
[70,42,16,15],
[70,42,17,0],
[70,42,17,1],
[70,42,17,2],
[70,42,17,3],
[70,42,17,4],
[70,42,17,6],
[70,42,17,8],
[70,42,17,9],
[70,42,17,11],
[70,42,17,12],
[70,42,17,13],
[70,42,17,14],
[70,42,17,15],
[70,42,17,16],
[70,42,18,0],
[70,42,18,1],
[70,42,18,2],
[70,42,18,5],
[70,42,18,6],
[70,42,18,8],
[70,42,18,11],
[70,42,18,12],
[70,42,18,13],
[70,42,18,14],
[70,42,18,16],
[70,42,18,17],
[70,42,19,3],
[70,42,19,4],
[70,42,19,5],
[70,42,19,6],
[70,42,19,8],
[70,42,19,9],
[70,42,19,11],
[70,42,19,12],
[70,42,19,15],
[70,42,19,16],
[70,42,19,17],
[70,42,19,18],
[70,42,21,0],
[70,42,21,1],
[70,42,21,2],
[70,42,21,4],
[70,42,21,9],
[70,42,21,11],
[70,42,21,12],
[70,42,21,13],
[70,42,21,14],
[70,42,21,15],
[70,42,21,16],
[70,42,21,17],
[70,42,21,18],
[70,42,23,0],
[70,42,23,1],
[70,42,23,2],
[70,42,23,3],
[70,42,23,4],
[70,42,23,5],
[70,42,23,6],
[70,42,23,8],
[70,42,23,9],
[70,42,23,11],
[70,42,23,12],
[70,42,23,13],
[70,42,23,14],
[70,42,23,15],
[70,42,24,0],
[70,42,24,1],
[70,42,24,2],
[70,42,24,3],
[70,42,24,4],
[70,42,24,5],
[70,42,24,6],
[70,42,24,8],
[70,42,24,9],
[70,42,24,11],
[70,42,24,12],
[70,42,24,13],
[70,42,24,14],
[70,42,24,15],
[70,42,26,0],
[70,42,26,1],
[70,42,26,2],
[70,42,26,3],
[70,42,26,4],
[70,42,26,5],
[70,42,26,6],
[70,42,26,8],
[70,42,26,11],
[70,42,29,0],
[70,42,29,1],
[70,42,29,2],
[70,42,29,3],
[70,42,29,4],
[70,42,29,5],
[70,42,30,0],
[70,42,30,1],
[70,42,30,2],
[70,42,30,3],
[70,42,30,4],
[70,42,31,0],
[70,42,31,1],
[70,42,31,2],
[70,44,3,0],
[70,44,3,1],
[70,44,3,2],
[70,44,4,0],
[70,44,4,1],
[70,44,4,2],
[70,44,4,3],
[70,44,5,0],
[70,44,5,1],
[70,44,5,2],
[70,44,5,4],
[70,44,6,0],
[70,44,6,1],
[70,44,6,2],
[70,44,6,4],
[70,44,8,0],
[70,44,8,1],
[70,44,8,2],
[70,44,8,4],
[70,44,9,0],
[70,44,9,1],
[70,44,9,2],
[70,44,9,3],
[70,44,9,4],
[70,44,9,5],
[70,44,9,6],
[70,44,9,8],
[70,44,10,0],
[70,44,10,1],
[70,44,10,2],
[70,44,10,3],
[70,44,10,4],
[70,44,10,5],
[70,44,10,6],
[70,44,11,0],
[70,44,11,1],
[70,44,11,2],
[70,44,11,3],
[70,44,11,4],
[70,44,11,5],
[70,44,11,6],
[70,44,11,10],
[70,44,12,0],
[70,44,12,1],
[70,44,12,2],
[70,44,12,3],
[70,44,12,4],
[70,44,12,5],
[70,44,12,8],
[70,44,12,9],
[70,44,12,10],
[70,44,13,0],
[70,44,13,1],
[70,44,13,2],
[70,44,13,3],
[70,44,13,4],
[70,44,13,5],
[70,44,13,8],
[70,44,13,9],
[70,44,13,10],
[70,44,13,11],
[70,44,13,12],
[70,44,14,0],
[70,44,14,1],
[70,44,14,2],
[70,44,14,3],
[70,44,14,4],
[70,44,14,6],
[70,44,14,8],
[70,44,14,9],
[70,44,14,10],
[70,44,14,11],
[70,44,14,12],
[70,44,15,0],
[70,44,15,1],
[70,44,15,2],
[70,44,15,5],
[70,44,15,6],
[70,44,15,8],
[70,44,15,10],
[70,44,15,11],
[70,44,15,12],
[70,44,15,13],
[70,44,15,14],
[70,44,16,3],
[70,44,16,4],
[70,44,16,5],
[70,44,16,6],
[70,44,16,8],
[70,44,16,9],
[70,44,16,10],
[70,44,16,11],
[70,44,16,12],
[70,44,16,13],
[70,44,16,14],
[70,44,16,15],
[70,44,18,0],
[70,44,18,1],
[70,44,18,2],
[70,44,18,3],
[70,44,18,4],
[70,44,18,5],
[70,44,18,6],
[70,44,18,8],
[70,44,18,10],
[70,44,18,11],
[70,44,18,12],
[70,44,18,13],
[70,44,18,14],
[70,44,18,16],
[70,44,19,0],
[70,44,19,1],
[70,44,19,2],
[70,44,19,3],
[70,44,19,4],
[70,44,19,5],
[70,44,19,6],
[70,44,19,8],
[70,44,19,9],
[70,44,19,10],
[70,44,19,11],
[70,44,19,12],
[70,44,19,15],
[70,44,19,16],
[70,44,19,18],
[70,44,20,0],
[70,44,20,1],
[70,44,20,2],
[70,44,20,3],
[70,44,20,4],
[70,44,20,5],
[70,44,20,6],
[70,44,20,8],
[70,44,20,9],
[70,44,20,11],
[70,44,20,12],
[70,44,20,13],
[70,44,20,14],
[70,44,20,15],
[70,44,20,16],
[70,44,21,0],
[70,44,21,1],
[70,44,21,2],
[70,44,21,4],
[70,44,21,9],
[70,44,21,10],
[70,44,21,11],
[70,44,21,12],
[70,44,21,13],
[70,44,21,14],
[70,44,21,15],
[70,44,22,0],
[70,44,22,1],
[70,44,22,2],
[70,44,22,3],
[70,44,22,4],
[70,44,22,5],
[70,44,22,6],
[70,44,22,8],
[70,44,22,9],
[70,44,22,11],
[70,44,22,12],
[70,44,22,13],
[70,44,22,14],
[70,44,23,0],
[70,44,23,1],
[70,44,23,2],
[70,44,23,3],
[70,44,23,4],
[70,44,23,5],
[70,44,23,6],
[70,44,23,8],
[70,44,23,9],
[70,44,23,10],
[70,44,23,11],
[70,44,23,12],
[70,44,23,13],
[70,44,24,0],
[70,44,24,1],
[70,44,24,2],
[70,44,24,3],
[70,44,24,4],
[70,44,24,5],
[70,44,24,6],
[70,44,24,8],
[70,44,24,9],
[70,44,24,10],
[70,44,24,11],
[70,44,24,12],
[70,44,24,13],
[70,44,26,0],
[70,44,26,1],
[70,44,26,2],
[70,44,26,3],
[70,44,26,4],
[70,44,26,5],
[70,44,26,6],
[70,44,27,0],
[70,44,27,1],
[70,44,27,2],
[70,44,27,3],
[70,44,27,4],
[70,44,27,5],
[70,44,29,0],
[70,44,29,1],
[70,44,29,2],
[70,45,3,0],
[70,45,3,1],
[70,45,3,2],
[70,45,4,0],
[70,45,4,1],
[70,45,4,2],
[70,45,4,3],
[70,45,5,0],
[70,45,5,1],
[70,45,5,2],
[70,45,5,4],
[70,45,6,0],
[70,45,6,1],
[70,45,6,2],
[70,45,6,4],
[70,45,8,0],
[70,45,8,1],
[70,45,8,2],
[70,45,8,4],
[70,45,10,0],
[70,45,10,1],
[70,45,10,2],
[70,45,10,3],
[70,45,10,4],
[70,45,10,5],
[70,45,10,8],
[70,45,11,0],
[70,45,11,1],
[70,45,11,2],
[70,45,11,3],
[70,45,11,4],
[70,45,11,5],
[70,45,11,8],
[70,45,11,10],
[70,45,12,0],
[70,45,12,1],
[70,45,12,2],
[70,45,12,3],
[70,45,12,4],
[70,45,12,6],
[70,45,12,8],
[70,45,12,10],
[70,45,13,0],
[70,45,13,1],
[70,45,13,2],
[70,45,13,3],
[70,45,13,4],
[70,45,13,6],
[70,45,13,8],
[70,45,13,10],
[70,45,13,11],
[70,45,13,12],
[70,45,14,0],
[70,45,14,1],
[70,45,14,2],
[70,45,14,5],
[70,45,14,6],
[70,45,14,8],
[70,45,14,10],
[70,45,14,11],
[70,45,14,12],
[70,45,16,0],
[70,45,16,1],
[70,45,16,2],
[70,45,16,3],
[70,45,16,4],
[70,45,16,5],
[70,45,16,6],
[70,45,16,8],
[70,45,16,10],
[70,45,16,11],
[70,45,16,12],
[70,45,16,13],
[70,45,16,14],
[70,45,17,0],
[70,45,17,1],
[70,45,17,2],
[70,45,17,3],
[70,45,17,4],
[70,45,17,5],
[70,45,17,6],
[70,45,17,8],
[70,45,17,10],
[70,45,17,11],
[70,45,17,12],
[70,45,17,13],
[70,45,17,14],
[70,45,17,16],
[70,45,19,0],
[70,45,19,1],
[70,45,19,2],
[70,45,19,3],
[70,45,19,4],
[70,45,19,5],
[70,45,19,6],
[70,45,19,8],
[70,45,19,10],
[70,45,19,11],
[70,45,19,12],
[70,45,19,16],
[70,45,19,17],
[70,45,20,0],
[70,45,20,1],
[70,45,20,2],
[70,45,20,3],
[70,45,20,4],
[70,45,20,5],
[70,45,20,6],
[70,45,20,8],
[70,45,20,11],
[70,45,20,12],
[70,45,20,13],
[70,45,20,14],
[70,45,21,0],
[70,45,21,1],
[70,45,21,2],
[70,45,21,4],
[70,45,21,10],
[70,45,21,11],
[70,45,21,12],
[70,45,21,13],
[70,45,21,14],
[70,45,22,0],
[70,45,22,1],
[70,45,22,2],
[70,45,22,3],
[70,45,22,4],
[70,45,22,5],
[70,45,22,6],
[70,45,22,8],
[70,45,22,11],
[70,45,22,12],
[70,45,22,13],
[70,45,23,0],
[70,45,23,1],
[70,45,23,2],
[70,45,23,3],
[70,45,23,4],
[70,45,23,5],
[70,45,23,6],
[70,45,23,8],
[70,45,23,10],
[70,45,23,11],
[70,45,24,0],
[70,45,24,1],
[70,45,24,2],
[70,45,24,3],
[70,45,24,4],
[70,45,24,5],
[70,45,24,6],
[70,45,24,8],
[70,45,24,10],
[70,45,24,11],
[70,45,27,0],
[70,45,27,1],
[70,45,27,2],
[70,45,27,3],
[70,45,27,4],
[70,46,3,0],
[70,46,3,1],
[70,46,3,2],
[70,46,4,0],
[70,46,4,1],
[70,46,4,2],
[70,46,4,3],
[70,46,5,0],
[70,46,5,1],
[70,46,5,2],
[70,46,5,4],
[70,46,6,0],
[70,46,6,1],
[70,46,6,2],
[70,46,6,4],
[70,46,8,0],
[70,46,8,1],
[70,46,8,2],
[70,46,8,4],
[70,46,10,0],
[70,46,10,1],
[70,46,10,2],
[70,46,10,3],
[70,46,10,4],
[70,46,10,6],
[70,46,10,8],
[70,46,11,0],
[70,46,11,1],
[70,46,11,2],
[70,46,11,3],
[70,46,11,4],
[70,46,11,6],
[70,46,11,8],
[70,46,11,10],
[70,46,12,0],
[70,46,12,1],
[70,46,12,2],
[70,46,12,5],
[70,46,12,6],
[70,46,12,8],
[70,46,12,10],
[70,46,13,0],
[70,46,13,1],
[70,46,13,2],
[70,46,13,5],
[70,46,13,6],
[70,46,13,8],
[70,46,13,10],
[70,46,13,11],
[70,46,13,12],
[70,46,14,3],
[70,46,14,4],
[70,46,14,5],
[70,46,14,6],
[70,46,14,8],
[70,46,14,10],
[70,46,14,11],
[70,46,14,12],
[70,46,16,0],
[70,46,16,1],
[70,46,16,2],
[70,46,16,3],
[70,46,16,4],
[70,46,16,5],
[70,46,16,6],
[70,46,16,8],
[70,46,16,10],
[70,46,16,11],
[70,46,16,12],
[70,46,16,13],
[70,46,16,14],
[70,46,17,0],
[70,46,17,1],
[70,46,17,2],
[70,46,17,3],
[70,46,17,4],
[70,46,17,5],
[70,46,17,6],
[70,46,17,8],
[70,46,17,10],
[70,46,17,11],
[70,46,17,12],
[70,46,17,13],
[70,46,17,14],
[70,46,17,16],
[70,46,19,0],
[70,46,19,1],
[70,46,19,2],
[70,46,19,3],
[70,46,19,4],
[70,46,19,5],
[70,46,19,6],
[70,46,19,8],
[70,46,19,10],
[70,46,19,11],
[70,46,19,12],
[70,46,20,0],
[70,46,20,1],
[70,46,20,2],
[70,46,20,3],
[70,46,20,4],
[70,46,20,5],
[70,46,20,6],
[70,46,20,8],
[70,46,20,11],
[70,46,20,12],
[70,46,20,13],
[70,46,20,14],
[70,46,21,0],
[70,46,21,1],
[70,46,21,2],
[70,46,21,4],
[70,46,21,10],
[70,46,21,11],
[70,46,21,12],
[70,46,21,13],
[70,46,22,0],
[70,46,22,1],
[70,46,22,2],
[70,46,22,3],
[70,46,22,4],
[70,46,22,5],
[70,46,22,6],
[70,46,22,8],
[70,46,22,11],
[70,46,23,0],
[70,46,23,1],
[70,46,23,2],
[70,46,23,3],
[70,46,23,4],
[70,46,23,5],
[70,46,23,6],
[70,46,23,8],
[70,46,24,0],
[70,46,24,1],
[70,46,24,2],
[70,46,24,3],
[70,46,24,4],
[70,46,24,5],
[70,46,24,6],
[70,46,24,8],
[70,46,27,0],
[70,46,27,1],
[70,46,27,2],
[70,47,3,0],
[70,47,3,1],
[70,47,3,2],
[70,47,4,0],
[70,47,4,1],
[70,47,4,2],
[70,47,4,3],
[70,47,5,0],
[70,47,5,1],
[70,47,5,2],
[70,47,5,4],
[70,47,6,0],
[70,47,6,1],
[70,47,6,2],
[70,47,6,4],
[70,47,8,0],
[70,47,8,1],
[70,47,8,2],
[70,47,8,4],
[70,47,9,0],
[70,47,9,1],
[70,47,9,2],
[70,47,9,3],
[70,47,9,4],
[70,47,9,6],
[70,47,9,8],
[70,47,10,0],
[70,47,10,1],
[70,47,10,2],
[70,47,10,5],
[70,47,10,6],
[70,47,10,8],
[70,47,10,9],
[70,47,11,0],
[70,47,11,1],
[70,47,11,2],
[70,47,11,5],
[70,47,11,6],
[70,47,11,8],
[70,47,11,9],
[70,47,11,10],
[70,47,12,3],
[70,47,12,4],
[70,47,12,5],
[70,47,12,6],
[70,47,12,8],
[70,47,12,9],
[70,47,12,10],
[70,47,13,3],
[70,47,13,4],
[70,47,13,5],
[70,47,13,6],
[70,47,13,8],
[70,47,13,9],
[70,47,13,10],
[70,47,13,11],
[70,47,13,12],
[70,47,14,0],
[70,47,14,1],
[70,47,14,2],
[70,47,14,3],
[70,47,14,4],
[70,47,14,5],
[70,47,14,6],
[70,47,14,8],
[70,47,14,9],
[70,47,14,10],
[70,47,14,11],
[70,47,14,12],
[70,47,15,0],
[70,47,15,1],
[70,47,15,2],
[70,47,15,3],
[70,47,15,4],
[70,47,15,5],
[70,47,15,6],
[70,47,15,8],
[70,47,15,10],
[70,47,15,11],
[70,47,15,12],
[70,47,15,13],
[70,47,15,14],
[70,47,16,0],
[70,47,16,1],
[70,47,16,2],
[70,47,16,3],
[70,47,16,4],
[70,47,16,5],
[70,47,16,6],
[70,47,16,8],
[70,47,16,9],
[70,47,16,10],
[70,47,16,11],
[70,47,16,12],
[70,47,16,13],
[70,47,16,14],
[70,47,16,15],
[70,47,17,0],
[70,47,17,1],
[70,47,17,2],
[70,47,17,3],
[70,47,17,4],
[70,47,17,5],
[70,47,17,6],
[70,47,17,8],
[70,47,17,9],
[70,47,17,10],
[70,47,17,11],
[70,47,17,12],
[70,47,17,13],
[70,47,17,14],
[70,47,17,15],
[70,47,17,16],
[70,47,18,0],
[70,47,18,1],
[70,47,18,2],
[70,47,18,3],
[70,47,18,4],
[70,47,18,5],
[70,47,18,6],
[70,47,18,8],
[70,47,18,10],
[70,47,18,11],
[70,47,18,12],
[70,47,18,13],
[70,47,18,14],
[70,47,19,0],
[70,47,19,1],
[70,47,19,2],
[70,47,19,3],
[70,47,19,4],
[70,47,19,5],
[70,47,19,6],
[70,47,19,8],
[70,47,19,9],
[70,47,19,10],
[70,47,19,11],
[70,47,19,12],
[70,47,20,0],
[70,47,20,1],
[70,47,20,2],
[70,47,20,3],
[70,47,20,4],
[70,47,20,5],
[70,47,20,6],
[70,47,20,8],
[70,47,20,9],
[70,47,20,11],
[70,47,20,12],
[70,47,20,13],
[70,47,21,0],
[70,47,21,1],
[70,47,21,2],
[70,47,21,4],
[70,47,21,9],
[70,47,21,10],
[70,47,21,11],
[70,47,22,0],
[70,47,22,1],
[70,47,22,2],
[70,47,22,3],
[70,47,22,4],
[70,47,22,5],
[70,47,22,6],
[70,47,22,8],
[70,47,22,9],
[70,47,24,0],
[70,47,24,1],
[70,47,24,2],
[70,47,24,3],
[70,47,24,4],
[70,47,24,5],
[70,47,24,6],
[70,47,26,0],
[70,47,26,1],
[70,47,26,2],
[70,48,3,0],
[70,48,3,1],
[70,48,3,2],
[70,48,4,0],
[70,48,4,1],
[70,48,4,2],
[70,48,4,3],
[70,48,5,0],
[70,48,5,1],
[70,48,5,2],
[70,48,5,4],
[70,48,6,0],
[70,48,6,1],
[70,48,6,2],
[70,48,6,4],
[70,48,8,0],
[70,48,8,1],
[70,48,8,2],
[70,48,9,0],
[70,48,9,1],
[70,48,9,2],
[70,48,9,5],
[70,48,9,6],
[70,48,9,8],
[70,48,10,3],
[70,48,10,4],
[70,48,10,5],
[70,48,10,6],
[70,48,10,8],
[70,48,10,9],
[70,48,13,0],
[70,48,13,1],
[70,48,13,2],
[70,48,13,3],
[70,48,13,4],
[70,48,13,5],
[70,48,13,6],
[70,48,13,8],
[70,48,13,9],
[70,48,13,10],
[70,48,14,0],
[70,48,14,1],
[70,48,14,2],
[70,48,14,3],
[70,48,14,4],
[70,48,14,5],
[70,48,14,6],
[70,48,14,8],
[70,48,14,9],
[70,48,14,10],
[70,48,15,0],
[70,48,15,1],
[70,48,15,2],
[70,48,15,3],
[70,48,15,4],
[70,48,15,5],
[70,48,15,6],
[70,48,15,8],
[70,48,15,10],
[70,48,15,13],
[70,48,15,14],
[70,48,16,0],
[70,48,16,1],
[70,48,16,2],
[70,48,16,3],
[70,48,16,4],
[70,48,16,5],
[70,48,16,6],
[70,48,16,8],
[70,48,16,9],
[70,48,16,10],
[70,48,16,13],
[70,48,16,14],
[70,48,16,15],
[70,48,17,0],
[70,48,17,1],
[70,48,17,2],
[70,48,17,3],
[70,48,17,4],
[70,48,17,5],
[70,48,17,6],
[70,48,17,8],
[70,48,17,9],
[70,48,17,10],
[70,48,17,13],
[70,48,17,14],
[70,48,17,15],
[70,48,18,0],
[70,48,18,1],
[70,48,18,2],
[70,48,18,3],
[70,48,18,4],
[70,48,18,5],
[70,48,18,6],
[70,48,18,8],
[70,48,18,10],
[70,48,18,13],
[70,48,18,14],
[70,48,19,0],
[70,48,19,1],
[70,48,19,2],
[70,48,19,3],
[70,48,19,4],
[70,48,19,5],
[70,48,19,6],
[70,48,19,8],
[70,48,19,9],
[70,48,19,10],
[70,48,20,0],
[70,48,20,1],
[70,48,20,2],
[70,48,20,3],
[70,48,20,4],
[70,48,20,5],
[70,48,20,6],
[70,48,20,8],
[70,48,20,9],
[70,48,21,0],
[70,48,21,1],
[70,48,21,2],
[70,48,21,4],
[70,48,21,9],
[70,48,22,0],
[70,48,22,1],
[70,48,22,2],
[70,48,22,3],
[70,48,22,4],
[70,48,22,5],
[70,48,22,6],
[70,48,23,0],
[70,48,23,1],
[70,48,23,2],
[70,48,23,3],
[70,48,23,4],
[70,48,23,5],
[70,48,24,0],
[70,48,24,1],
[70,48,24,2],
[70,48,24,3],
[70,48,24,4],
[70,48,24,5],
[70,49,3,0],
[70,49,3,1],
[70,49,3,2],
[70,49,4,0],
[70,49,4,1],
[70,49,4,2],
[70,49,4,3],
[70,49,5,0],
[70,49,5,1],
[70,49,5,2],
[70,49,5,4],
[70,49,6,0],
[70,49,6,1],
[70,49,6,2],
[70,49,6,4],
[70,49,8,0],
[70,49,8,1],
[70,49,8,2],
[70,49,10,3],
[70,49,10,4],
[70,49,10,5],
[70,49,10,6],
[70,49,10,8],
[70,49,11,3],
[70,49,11,4],
[70,49,11,5],
[70,49,11,6],
[70,49,11,8],
[70,49,11,10],
[70,49,12,0],
[70,49,12,1],
[70,49,12,2],
[70,49,12,3],
[70,49,12,4],
[70,49,12,5],
[70,49,12,6],
[70,49,12,8],
[70,49,12,10],
[70,49,13,0],
[70,49,13,1],
[70,49,13,2],
[70,49,13,3],
[70,49,13,4],
[70,49,13,5],
[70,49,13,6],
[70,49,13,8],
[70,49,13,10],
[70,49,13,11],
[70,49,13,12],
[70,49,14,0],
[70,49,14,1],
[70,49,14,2],
[70,49,14,3],
[70,49,14,4],
[70,49,14,5],
[70,49,14,6],
[70,49,14,8],
[70,49,14,10],
[70,49,14,11],
[70,49,14,12],
[70,49,16,0],
[70,49,16,1],
[70,49,16,2],
[70,49,16,3],
[70,49,16,4],
[70,49,16,5],
[70,49,16,6],
[70,49,16,8],
[70,49,16,10],
[70,49,16,11],
[70,49,16,12],
[70,49,16,13],
[70,49,16,14],
[70,49,17,0],
[70,49,17,1],
[70,49,17,2],
[70,49,17,3],
[70,49,17,4],
[70,49,17,5],
[70,49,17,6],
[70,49,17,8],
[70,49,17,10],
[70,49,17,11],
[70,49,17,12],
[70,49,17,13],
[70,49,17,14],
[70,49,19,0],
[70,49,19,1],
[70,49,19,2],
[70,49,19,3],
[70,49,19,4],
[70,49,19,5],
[70,49,19,6],
[70,49,19,8],
[70,49,19,10],
[70,49,19,11],
[70,49,19,12],
[70,49,20,0],
[70,49,20,1],
[70,49,20,2],
[70,49,20,3],
[70,49,20,4],
[70,49,20,5],
[70,49,20,6],
[70,49,20,8],
[70,49,20,11],
[70,49,21,0],
[70,49,21,1],
[70,49,21,2],
[70,49,21,4],
[70,49,22,0],
[70,49,22,1],
[70,49,22,2],
[70,49,22,3],
[70,49,22,4],
[70,49,22,5],
[70,49,22,6],
[70,49,23,0],
[70,49,23,1],
[70,49,23,2],
[70,49,23,3],
[70,49,23,4],
[70,49,23,5],
[70,49,24,0],
[70,49,24,1],
[70,49,24,2],
[70,49,24,3],
[70,49,24,4],
[70,49,24,5],
[70,50,3,0],
[70,50,3,1],
[70,50,3,2],
[70,50,4,0],
[70,50,4,1],
[70,50,4,2],
[70,50,4,3],
[70,50,5,0],
[70,50,5,1],
[70,50,5,2],
[70,50,5,4],
[70,50,6,0],
[70,50,6,1],
[70,50,6,2],
[70,50,6,4],
[70,50,8,0],
[70,50,8,1],
[70,50,8,2],
[70,50,10,3],
[70,50,10,4],
[70,50,10,5],
[70,50,10,6],
[70,50,10,8],
[70,50,11,3],
[70,50,11,4],
[70,50,11,5],
[70,50,11,6],
[70,50,11,8],
[70,50,11,10],
[70,50,12,0],
[70,50,12,1],
[70,50,12,2],
[70,50,12,3],
[70,50,12,4],
[70,50,12,5],
[70,50,12,6],
[70,50,12,8],
[70,50,12,10],
[70,50,13,0],
[70,50,13,1],
[70,50,13,2],
[70,50,13,3],
[70,50,13,4],
[70,50,13,5],
[70,50,13,6],
[70,50,13,8],
[70,50,13,10],
[70,50,13,11],
[70,50,13,12],
[70,50,14,0],
[70,50,14,1],
[70,50,14,2],
[70,50,14,3],
[70,50,14,4],
[70,50,14,5],
[70,50,14,6],
[70,50,14,8],
[70,50,14,10],
[70,50,14,11],
[70,50,14,12],
[70,50,16,0],
[70,50,16,1],
[70,50,16,2],
[70,50,16,3],
[70,50,16,4],
[70,50,16,5],
[70,50,16,6],
[70,50,16,8],
[70,50,16,10],
[70,50,16,11],
[70,50,16,12],
[70,50,16,13],
[70,50,16,14],
[70,50,17,0],
[70,50,17,1],
[70,50,17,2],
[70,50,17,3],
[70,50,17,4],
[70,50,17,5],
[70,50,17,6],
[70,50,17,8],
[70,50,17,10],
[70,50,17,11],
[70,50,17,12],
[70,50,17,13],
[70,50,17,14],
[70,50,19,0],
[70,50,19,1],
[70,50,19,2],
[70,50,19,3],
[70,50,19,4],
[70,50,19,5],
[70,50,19,6],
[70,50,19,8],
[70,50,19,10],
[70,50,19,11],
[70,50,19,12],
[70,50,20,0],
[70,50,20,1],
[70,50,20,2],
[70,50,20,3],
[70,50,20,4],
[70,50,20,5],
[70,50,20,6],
[70,50,20,8],
[70,50,20,11],
[70,50,21,0],
[70,50,21,1],
[70,50,21,2],
[70,50,21,4],
[70,50,22,0],
[70,50,22,1],
[70,50,22,2],
[70,50,22,3],
[70,50,22,4],
[70,50,22,5],
[70,50,22,6],
[70,50,23,0],
[70,50,23,1],
[70,50,23,2],
[70,50,23,3],
[70,50,23,4],
[70,50,23,5],
[70,50,24,0],
[70,50,24,1],
[70,50,24,2],
[70,50,24,3],
[70,50,24,4],
[70,50,24,5],
[70,51,4,0],
[70,51,4,1],
[70,51,4,2],
[70,51,9,0],
[70,51,9,1],
[70,51,9,2],
[70,51,10,4],
[70,51,10,9],
[70,51,11,4],
[70,51,11,9],
[70,51,11,10],
[70,51,12,0],
[70,51,12,1],
[70,51,12,2],
[70,51,12,4],
[70,51,12,9],
[70,51,12,10],
[70,51,13,0],
[70,51,13,1],
[70,51,13,2],
[70,51,13,4],
[70,51,13,9],
[70,51,13,10],
[70,51,13,11],
[70,51,13,12],
[70,51,14,0],
[70,51,14,1],
[70,51,14,2],
[70,51,14,4],
[70,51,14,9],
[70,51,14,10],
[70,51,14,11],
[70,51,14,12],
[70,51,15,0],
[70,51,15,1],
[70,51,15,2],
[70,51,15,4],
[70,51,15,10],
[70,51,15,11],
[70,51,15,12],
[70,51,15,13],
[70,51,15,14],
[70,51,16,0],
[70,51,16,1],
[70,51,16,2],
[70,51,16,4],
[70,51,16,9],
[70,51,16,10],
[70,51,16,11],
[70,51,16,12],
[70,51,16,13],
[70,51,16,14],
[70,51,16,15],
[70,51,17,0],
[70,51,17,1],
[70,51,17,2],
[70,51,17,4],
[70,51,17,9],
[70,51,17,10],
[70,51,17,11],
[70,51,17,12],
[70,51,17,13],
[70,51,17,14],
[70,51,17,15],
[70,51,18,0],
[70,51,18,1],
[70,51,18,2],
[70,51,18,4],
[70,51,18,10],
[70,51,18,11],
[70,51,18,12],
[70,51,18,13],
[70,51,18,14],
[70,51,19,0],
[70,51,19,1],
[70,51,19,2],
[70,51,19,4],
[70,51,19,9],
[70,51,19,10],
[70,51,19,11],
[70,51,19,12],
[70,51,20,0],
[70,51,20,1],
[70,51,20,2],
[70,51,20,4],
[70,51,20,9],
[70,51,20,11],
[70,51,22,0],
[70,51,22,1],
[70,51,22,2],
[70,51,22,4],
[70,51,23,0],
[70,51,23,1],
[70,51,23,2],
[70,51,23,4],
[70,51,24,0],
[70,51,24,1],
[70,51,24,2],
[70,51,24,4],
[70,52,3,0],
[70,52,3,1],
[70,52,3,2],
[70,52,4,0],
[70,52,4,1],
[70,52,4,2],
[70,52,4,3],
[70,52,5,0],
[70,52,5,1],
[70,52,5,2],
[70,52,5,4],
[70,52,6,0],
[70,52,6,1],
[70,52,6,2],
[70,52,6,4],
[70,52,8,0],
[70,52,8,1],
[70,52,8,2],
[70,52,10,3],
[70,52,10,4],
[70,52,10,5],
[70,52,10,6],
[70,52,10,8],
[70,52,11,3],
[70,52,11,4],
[70,52,11,5],
[70,52,11,6],
[70,52,11,8],
[70,52,11,10],
[70,52,12,0],
[70,52,12,1],
[70,52,12,2],
[70,52,12,3],
[70,52,12,4],
[70,52,12,5],
[70,52,12,6],
[70,52,12,8],
[70,52,12,10],
[70,52,13,0],
[70,52,13,1],
[70,52,13,2],
[70,52,13,3],
[70,52,13,4],
[70,52,13,5],
[70,52,13,6],
[70,52,13,8],
[70,52,13,10],
[70,52,13,11],
[70,52,13,12],
[70,52,14,0],
[70,52,14,1],
[70,52,14,2],
[70,52,14,3],
[70,52,14,4],
[70,52,14,5],
[70,52,14,6],
[70,52,14,8],
[70,52,14,10],
[70,52,14,11],
[70,52,14,12],
[70,52,16,0],
[70,52,16,1],
[70,52,16,2],
[70,52,16,3],
[70,52,16,4],
[70,52,16,5],
[70,52,16,6],
[70,52,16,8],
[70,52,16,10],
[70,52,16,11],
[70,52,16,12],
[70,52,16,13],
[70,52,16,14],
[70,52,17,0],
[70,52,17,1],
[70,52,17,2],
[70,52,17,3],
[70,52,17,4],
[70,52,17,5],
[70,52,17,6],
[70,52,17,8],
[70,52,17,10],
[70,52,17,11],
[70,52,17,12],
[70,52,17,13],
[70,52,17,14],
[70,52,19,0],
[70,52,19,1],
[70,52,19,2],
[70,52,19,3],
[70,52,19,4],
[70,52,19,5],
[70,52,19,6],
[70,52,19,8],
[70,52,19,10],
[70,52,19,11],
[70,52,19,12],
[70,52,20,0],
[70,52,20,1],
[70,52,20,2],
[70,52,20,3],
[70,52,20,4],
[70,52,20,5],
[70,52,20,6],
[70,52,20,8],
[70,52,20,11],
[70,52,21,0],
[70,52,21,1],
[70,52,21,2],
[70,52,21,4],
[70,52,22,0],
[70,52,22,1],
[70,52,22,2],
[70,52,22,3],
[70,52,22,4],
[70,52,22,5],
[70,52,22,6],
[70,52,23,0],
[70,52,23,1],
[70,52,23,2],
[70,52,23,3],
[70,52,23,4],
[70,52,23,5],
[70,52,24,0],
[70,52,24,1],
[70,52,24,2],
[70,52,24,3],
[70,52,24,4],
[70,52,24,5],
[70,53,3,0],
[70,53,3,1],
[70,53,3,2],
[70,53,4,0],
[70,53,4,1],
[70,53,4,2],
[70,53,4,3],
[70,53,5,0],
[70,53,5,1],
[70,53,5,2],
[70,53,5,4],
[70,53,6,0],
[70,53,6,1],
[70,53,6,2],
[70,53,6,4],
[70,53,8,0],
[70,53,8,1],
[70,53,8,2],
[70,53,9,0],
[70,53,9,1],
[70,53,9,2],
[70,53,9,5],
[70,53,9,6],
[70,53,9,8],
[70,53,11,3],
[70,53,11,4],
[70,53,11,5],
[70,53,11,6],
[70,53,11,8],
[70,53,11,9],
[70,53,12,0],
[70,53,12,1],
[70,53,12,2],
[70,53,12,3],
[70,53,12,4],
[70,53,12,5],
[70,53,12,6],
[70,53,12,8],
[70,53,12,9],
[70,53,13,0],
[70,53,13,1],
[70,53,13,2],
[70,53,13,3],
[70,53,13,4],
[70,53,13,5],
[70,53,13,6],
[70,53,13,8],
[70,53,13,9],
[70,53,13,11],
[70,53,13,12],
[70,53,14,0],
[70,53,14,1],
[70,53,14,2],
[70,53,14,3],
[70,53,14,4],
[70,53,14,5],
[70,53,14,6],
[70,53,14,8],
[70,53,14,9],
[70,53,14,11],
[70,53,14,12],
[70,53,15,0],
[70,53,15,1],
[70,53,15,2],
[70,53,15,3],
[70,53,15,4],
[70,53,15,5],
[70,53,15,6],
[70,53,15,8],
[70,53,15,11],
[70,53,15,12],
[70,53,15,13],
[70,53,15,14],
[70,53,16,0],
[70,53,16,1],
[70,53,16,2],
[70,53,16,3],
[70,53,16,4],
[70,53,16,5],
[70,53,16,6],
[70,53,16,8],
[70,53,16,9],
[70,53,16,11],
[70,53,16,12],
[70,53,16,13],
[70,53,16,14],
[70,53,16,15],
[70,53,17,0],
[70,53,17,1],
[70,53,17,2],
[70,53,17,3],
[70,53,17,4],
[70,53,17,5],
[70,53,17,6],
[70,53,17,8],
[70,53,17,9],
[70,53,17,11],
[70,53,17,12],
[70,53,17,13],
[70,53,17,14],
[70,53,17,15],
[70,53,18,0],
[70,53,18,1],
[70,53,18,2],
[70,53,18,3],
[70,53,18,4],
[70,53,18,5],
[70,53,18,6],
[70,53,18,8],
[70,53,18,11],
[70,53,18,12],
[70,53,18,13],
[70,53,18,14],
[70,53,19,0],
[70,53,19,1],
[70,53,19,2],
[70,53,19,3],
[70,53,19,4],
[70,53,19,5],
[70,53,19,6],
[70,53,19,8],
[70,53,19,9],
[70,53,19,11],
[70,53,19,12],
[70,53,21,0],
[70,53,21,1],
[70,53,21,2],
[70,53,21,4],
[70,53,21,9],
[70,53,23,0],
[70,53,23,1],
[70,53,23,2],
[70,53,23,3],
[70,53,23,4],
[70,53,23,5],
[70,53,24,0],
[70,53,24,1],
[70,53,24,2],
[70,53,24,3],
[70,53,24,4],
[70,53,24,5],
[70,54,3,0],
[70,54,3,1],
[70,54,3,2],
[70,54,4,0],
[70,54,4,1],
[70,54,4,2],
[70,54,4,3],
[70,54,5,0],
[70,54,5,1],
[70,54,5,2],
[70,54,5,4],
[70,54,6,0],
[70,54,6,1],
[70,54,6,2],
[70,54,6,4],
[70,54,8,0],
[70,54,8,1],
[70,54,8,2],
[70,54,9,0],
[70,54,9,1],
[70,54,9,2],
[70,54,9,5],
[70,54,9,6],
[70,54,9,8],
[70,54,11,3],
[70,54,11,4],
[70,54,11,5],
[70,54,11,6],
[70,54,11,8],
[70,54,11,9],
[70,54,12,0],
[70,54,12,1],
[70,54,12,2],
[70,54,12,3],
[70,54,12,4],
[70,54,12,5],
[70,54,12,6],
[70,54,12,8],
[70,54,12,9],
[70,54,13,0],
[70,54,13,1],
[70,54,13,2],
[70,54,13,3],
[70,54,13,4],
[70,54,13,5],
[70,54,13,6],
[70,54,13,8],
[70,54,13,9],
[70,54,13,11],
[70,54,13,12],
[70,54,14,0],
[70,54,14,1],
[70,54,14,2],
[70,54,14,3],
[70,54,14,4],
[70,54,14,5],
[70,54,14,6],
[70,54,14,8],
[70,54,14,9],
[70,54,14,11],
[70,54,14,12],
[70,54,15,0],
[70,54,15,1],
[70,54,15,2],
[70,54,15,3],
[70,54,15,4],
[70,54,15,5],
[70,54,15,6],
[70,54,15,8],
[70,54,15,11],
[70,54,15,12],
[70,54,15,13],
[70,54,15,14],
[70,54,16,0],
[70,54,16,1],
[70,54,16,2],
[70,54,16,3],
[70,54,16,4],
[70,54,16,5],
[70,54,16,6],
[70,54,16,8],
[70,54,16,9],
[70,54,16,11],
[70,54,16,12],
[70,54,16,13],
[70,54,16,14],
[70,54,16,15],
[70,54,17,0],
[70,54,17,1],
[70,54,17,2],
[70,54,17,3],
[70,54,17,4],
[70,54,17,5],
[70,54,17,6],
[70,54,17,8],
[70,54,17,9],
[70,54,17,11],
[70,54,17,12],
[70,54,17,13],
[70,54,17,14],
[70,54,17,15],
[70,54,18,0],
[70,54,18,1],
[70,54,18,2],
[70,54,18,3],
[70,54,18,4],
[70,54,18,5],
[70,54,18,6],
[70,54,18,8],
[70,54,18,11],
[70,54,18,12],
[70,54,18,13],
[70,54,18,14],
[70,54,19,0],
[70,54,19,1],
[70,54,19,2],
[70,54,19,3],
[70,54,19,4],
[70,54,19,5],
[70,54,19,6],
[70,54,19,8],
[70,54,19,9],
[70,54,19,11],
[70,54,19,12],
[70,54,21,0],
[70,54,21,1],
[70,54,21,2],
[70,54,21,4],
[70,54,21,9],
[70,54,23,0],
[70,54,23,1],
[70,54,23,2],
[70,54,23,3],
[70,54,23,4],
[70,54,23,5],
[70,54,24,0],
[70,54,24,1],
[70,54,24,2],
[70,54,24,3],
[70,54,24,4],
[70,54,24,5],
[70,55,3,0],
[70,55,3,1],
[70,55,3,2],
[70,55,4,0],
[70,55,4,1],
[70,55,4,2],
[70,55,4,3],
[70,55,5,0],
[70,55,5,1],
[70,55,5,2],
[70,55,5,4],
[70,55,6,0],
[70,55,6,1],
[70,55,6,2],
[70,55,6,4],
[70,55,8,0],
[70,55,8,1],
[70,55,8,2],
[70,55,9,0],
[70,55,9,1],
[70,55,9,2],
[70,55,9,5],
[70,55,9,6],
[70,55,9,8],
[70,55,11,3],
[70,55,11,4],
[70,55,11,5],
[70,55,11,6],
[70,55,11,8],
[70,55,11,9],
[70,55,12,0],
[70,55,12,1],
[70,55,12,2],
[70,55,12,3],
[70,55,12,4],
[70,55,12,5],
[70,55,12,6],
[70,55,12,8],
[70,55,12,9],
[70,55,13,0],
[70,55,13,1],
[70,55,13,2],
[70,55,13,3],
[70,55,13,4],
[70,55,13,5],
[70,55,13,6],
[70,55,13,8],
[70,55,13,9],
[70,55,13,11],
[70,55,13,12],
[70,55,14,0],
[70,55,14,1],
[70,55,14,2],
[70,55,14,3],
[70,55,14,4],
[70,55,14,5],
[70,55,14,6],
[70,55,14,8],
[70,55,14,9],
[70,55,14,11],
[70,55,14,12],
[70,55,15,0],
[70,55,15,1],
[70,55,15,2],
[70,55,15,3],
[70,55,15,4],
[70,55,15,5],
[70,55,15,6],
[70,55,15,8],
[70,55,15,11],
[70,55,15,12],
[70,55,15,13],
[70,55,15,14],
[70,55,16,0],
[70,55,16,1],
[70,55,16,2],
[70,55,16,3],
[70,55,16,4],
[70,55,16,5],
[70,55,16,6],
[70,55,16,8],
[70,55,16,9],
[70,55,16,11],
[70,55,16,12],
[70,55,16,13],
[70,55,16,14],
[70,55,16,15],
[70,55,17,0],
[70,55,17,1],
[70,55,17,2],
[70,55,17,3],
[70,55,17,4],
[70,55,17,5],
[70,55,17,6],
[70,55,17,8],
[70,55,17,9],
[70,55,17,11],
[70,55,17,12],
[70,55,17,13],
[70,55,17,14],
[70,55,17,15],
[70,55,18,0],
[70,55,18,1],
[70,55,18,2],
[70,55,18,3],
[70,55,18,4],
[70,55,18,5],
[70,55,18,6],
[70,55,18,8],
[70,55,18,11],
[70,55,18,12],
[70,55,18,13],
[70,55,18,14],
[70,55,19,0],
[70,55,19,1],
[70,55,19,2],
[70,55,19,3],
[70,55,19,4],
[70,55,19,5],
[70,55,19,6],
[70,55,19,8],
[70,55,19,9],
[70,55,19,11],
[70,55,19,12],
[70,55,21,0],
[70,55,21,1],
[70,55,21,2],
[70,55,21,4],
[70,55,21,9],
[70,55,23,0],
[70,55,23,1],
[70,55,23,2],
[70,55,23,3],
[70,55,23,4],
[70,55,23,5],
[70,55,24,0],
[70,55,24,1],
[70,55,24,2],
[70,55,24,3],
[70,55,24,4],
[70,55,24,5],
[70,56,3,0],
[70,56,3,1],
[70,56,3,2],
[70,56,4,0],
[70,56,4,1],
[70,56,4,2],
[70,56,4,3],
[70,56,5,0],
[70,56,5,1],
[70,56,5,2],
[70,56,5,4],
[70,56,6,0],
[70,56,6,1],
[70,56,6,2],
[70,56,6,4],
[70,56,8,0],
[70,56,8,1],
[70,56,8,2],
[70,56,10,3],
[70,56,10,4],
[70,56,10,5],
[70,56,10,6],
[70,56,10,8],
[70,56,11,3],
[70,56,11,4],
[70,56,11,5],
[70,56,11,6],
[70,56,11,8],
[70,56,11,10],
[70,56,12,0],
[70,56,12,1],
[70,56,12,2],
[70,56,12,3],
[70,56,12,4],
[70,56,12,5],
[70,56,12,6],
[70,56,12,8],
[70,56,12,10],
[70,56,13,0],
[70,56,13,1],
[70,56,13,2],
[70,56,13,3],
[70,56,13,4],
[70,56,13,5],
[70,56,13,6],
[70,56,13,8],
[70,56,13,10],
[70,56,13,11],
[70,56,13,12],
[70,56,14,0],
[70,56,14,1],
[70,56,14,2],
[70,56,14,3],
[70,56,14,4],
[70,56,14,5],
[70,56,14,6],
[70,56,14,8],
[70,56,14,10],
[70,56,14,11],
[70,56,14,12],
[70,56,16,0],
[70,56,16,1],
[70,56,16,2],
[70,56,16,3],
[70,56,16,4],
[70,56,16,5],
[70,56,16,6],
[70,56,16,8],
[70,56,16,10],
[70,56,16,11],
[70,56,16,12],
[70,56,16,13],
[70,56,16,14],
[70,56,17,0],
[70,56,17,1],
[70,56,17,2],
[70,56,17,3],
[70,56,17,4],
[70,56,17,5],
[70,56,17,6],
[70,56,17,8],
[70,56,17,10],
[70,56,17,11],
[70,56,17,12],
[70,56,17,13],
[70,56,17,14],
[70,56,19,0],
[70,56,19,1],
[70,56,19,2],
[70,56,19,3],
[70,56,19,4],
[70,56,19,5],
[70,56,19,6],
[70,56,19,8],
[70,56,19,10],
[70,56,19,11],
[70,56,19,12],
[70,56,20,0],
[70,56,20,1],
[70,56,20,2],
[70,56,20,3],
[70,56,20,4],
[70,56,20,5],
[70,56,20,6],
[70,56,20,8],
[70,56,20,11],
[70,56,21,0],
[70,56,21,1],
[70,56,21,2],
[70,56,21,4],
[70,56,22,0],
[70,56,22,1],
[70,56,22,2],
[70,56,22,3],
[70,56,22,4],
[70,56,22,5],
[70,56,22,6],
[70,56,23,0],
[70,56,23,1],
[70,56,23,2],
[70,56,23,3],
[70,56,23,4],
[70,56,23,5],
[70,56,24,0],
[70,56,24,1],
[70,56,24,2],
[70,56,24,3],
[70,56,24,4],
[70,56,24,5],
[70,57,3,0],
[70,57,3,1],
[70,57,3,2],
[70,57,4,0],
[70,57,4,1],
[70,57,4,2],
[70,57,4,3],
[70,57,5,0],
[70,57,5,1],
[70,57,5,2],
[70,57,5,4],
[70,57,6,0],
[70,57,6,1],
[70,57,6,2],
[70,57,8,4],
[70,57,9,3],
[70,57,9,4],
[70,57,9,5],
[70,57,9,6],
[70,57,9,8],
[70,57,10,0],
[70,57,10,1],
[70,57,10,2],
[70,57,10,3],
[70,57,10,4],
[70,57,10,5],
[70,57,10,6],
[70,57,10,8],
[70,57,10,9],
[70,57,11,0],
[70,57,11,1],
[70,57,11,2],
[70,57,11,3],
[70,57,11,4],
[70,57,11,5],
[70,57,11,6],
[70,57,11,8],
[70,57,11,9],
[70,57,11,10],
[70,57,12,0],
[70,57,12,1],
[70,57,12,2],
[70,57,12,3],
[70,57,12,4],
[70,57,12,5],
[70,57,12,6],
[70,57,12,8],
[70,57,12,9],
[70,57,12,10],
[70,57,13,0],
[70,57,13,1],
[70,57,13,2],
[70,57,13,3],
[70,57,13,4],
[70,57,13,5],
[70,57,13,6],
[70,57,13,8],
[70,57,13,9],
[70,57,13,10],
[70,57,13,11],
[70,57,13,12],
[70,57,14,0],
[70,57,14,1],
[70,57,14,2],
[70,57,14,3],
[70,57,14,4],
[70,57,14,5],
[70,57,14,6],
[70,57,14,8],
[70,57,14,9],
[70,57,14,10],
[70,57,14,11],
[70,57,14,12],
[70,57,15,0],
[70,57,15,1],
[70,57,15,2],
[70,57,15,3],
[70,57,15,4],
[70,57,15,5],
[70,57,15,6],
[70,57,15,8],
[70,57,15,10],
[70,57,15,11],
[70,57,15,12],
[70,57,15,13],
[70,57,15,14],
[70,57,16,0],
[70,57,16,1],
[70,57,16,2],
[70,57,16,3],
[70,57,16,4],
[70,57,16,5],
[70,57,16,6],
[70,57,16,8],
[70,57,16,9],
[70,57,16,10],
[70,57,16,11],
[70,57,16,12],
[70,57,16,13],
[70,57,16,14],
[70,57,17,0],
[70,57,17,1],
[70,57,17,2],
[70,57,17,3],
[70,57,17,4],
[70,57,17,5],
[70,57,17,6],
[70,57,17,8],
[70,57,17,9],
[70,57,17,10],
[70,57,17,11],
[70,57,17,12],
[70,57,17,13],
[70,57,17,14],
[70,57,18,0],
[70,57,18,1],
[70,57,18,2],
[70,57,18,3],
[70,57,18,4],
[70,57,18,5],
[70,57,18,6],
[70,57,18,8],
[70,57,18,10],
[70,57,18,11],
[70,57,18,12],
[70,57,18,13],
[70,57,19,0],
[70,57,19,1],
[70,57,19,2],
[70,57,19,3],
[70,57,19,4],
[70,57,19,5],
[70,57,19,6],
[70,57,19,8],
[70,57,19,9],
[70,57,19,10],
[70,57,19,11],
[70,57,20,0],
[70,57,20,1],
[70,57,20,2],
[70,57,20,3],
[70,57,20,4],
[70,57,20,5],
[70,57,20,6],
[70,57,20,8],
[70,57,20,9],
[70,57,21,0],
[70,57,21,1],
[70,57,21,2],
[70,57,21,4],
[70,57,22,0],
[70,57,22,1],
[70,57,22,2],
[70,57,22,3],
[70,57,22,4],
[70,57,22,5],
[70,57,24,0],
[70,57,24,1],
[70,57,24,2],
[70,57,24,3],
[70,57,24,4],
[70,58,3,0],
[70,58,3,1],
[70,58,3,2],
[70,58,4,0],
[70,58,4,1],
[70,58,4,2],
[70,58,4,3],
[70,58,5,0],
[70,58,5,1],
[70,58,5,2],
[70,58,5,4],
[70,58,6,0],
[70,58,6,1],
[70,58,6,2],
[70,58,8,4],
[70,58,9,3],
[70,58,9,4],
[70,58,9,5],
[70,58,9,6],
[70,58,9,8],
[70,58,10,0],
[70,58,10,1],
[70,58,10,2],
[70,58,10,3],
[70,58,10,4],
[70,58,10,5],
[70,58,10,6],
[70,58,10,8],
[70,58,10,9],
[70,58,11,0],
[70,58,11,1],
[70,58,11,2],
[70,58,11,3],
[70,58,11,4],
[70,58,11,5],
[70,58,11,6],
[70,58,11,8],
[70,58,11,9],
[70,58,11,10],
[70,58,12,0],
[70,58,12,1],
[70,58,12,2],
[70,58,12,3],
[70,58,12,4],
[70,58,12,5],
[70,58,12,6],
[70,58,12,8],
[70,58,12,9],
[70,58,12,10],
[70,58,13,0],
[70,58,13,1],
[70,58,13,2],
[70,58,13,3],
[70,58,13,4],
[70,58,13,5],
[70,58,13,6],
[70,58,13,8],
[70,58,13,9],
[70,58,13,10],
[70,58,13,11],
[70,58,13,12],
[70,58,14,0],
[70,58,14,1],
[70,58,14,2],
[70,58,14,3],
[70,58,14,4],
[70,58,14,5],
[70,58,14,6],
[70,58,14,8],
[70,58,14,9],
[70,58,14,10],
[70,58,14,11],
[70,58,14,12],
[70,58,15,0],
[70,58,15,1],
[70,58,15,2],
[70,58,15,3],
[70,58,15,4],
[70,58,15,5],
[70,58,15,6],
[70,58,15,8],
[70,58,15,10],
[70,58,15,11],
[70,58,15,12],
[70,58,15,13],
[70,58,15,14],
[70,58,17,0],
[70,58,17,1],
[70,58,17,2],
[70,58,17,3],
[70,58,17,4],
[70,58,17,5],
[70,58,17,6],
[70,58,17,8],
[70,58,17,9],
[70,58,17,10],
[70,58,17,11],
[70,58,17,12],
[70,58,17,13],
[70,58,17,14],
[70,58,18,0],
[70,58,18,1],
[70,58,18,2],
[70,58,18,3],
[70,58,18,4],
[70,58,18,5],
[70,58,18,6],
[70,58,18,8],
[70,58,18,10],
[70,58,18,11],
[70,58,18,12],
[70,58,18,13],
[70,58,19,0],
[70,58,19,1],
[70,58,19,2],
[70,58,19,3],
[70,58,19,4],
[70,58,19,5],
[70,58,19,6],
[70,58,19,8],
[70,58,19,9],
[70,58,19,10],
[70,58,19,11],
[70,58,20,0],
[70,58,20,1],
[70,58,20,2],
[70,58,20,3],
[70,58,20,4],
[70,58,20,5],
[70,58,20,6],
[70,58,20,8],
[70,58,20,9],
[70,58,21,0],
[70,58,21,1],
[70,58,21,2],
[70,58,21,4],
[70,58,22,0],
[70,58,22,1],
[70,58,22,2],
[70,58,22,3],
[70,58,22,4],
[70,58,22,5],
[70,58,23,0],
[70,58,23,1],
[70,58,23,2],
[70,58,23,3],
[70,58,23,4],
[70,59,3,0],
[70,59,3,1],
[70,59,3,2],
[70,59,4,0],
[70,59,4,1],
[70,59,4,2],
[70,59,4,3],
[70,59,5,0],
[70,59,5,1],
[70,59,5,2],
[70,59,6,4],
[70,59,8,0],
[70,59,8,1],
[70,59,8,2],
[70,59,8,4],
[70,59,10,0],
[70,59,10,1],
[70,59,10,2],
[70,59,10,3],
[70,59,10,4],
[70,59,10,5],
[70,59,10,6],
[70,59,10,8],
[70,59,11,0],
[70,59,11,1],
[70,59,11,2],
[70,59,11,3],
[70,59,11,4],
[70,59,11,5],
[70,59,11,6],
[70,59,11,8],
[70,59,11,10],
[70,59,12,0],
[70,59,12,1],
[70,59,12,2],
[70,59,12,3],
[70,59,12,4],
[70,59,12,5],
[70,59,12,6],
[70,59,12,8],
[70,59,12,10],
[70,59,13,0],
[70,59,13,1],
[70,59,13,2],
[70,59,13,3],
[70,59,13,4],
[70,59,13,5],
[70,59,13,6],
[70,59,13,8],
[70,59,13,10],
[70,59,13,11],
[70,59,13,12],
[70,59,14,0],
[70,59,14,1],
[70,59,14,2],
[70,59,14,3],
[70,59,14,4],
[70,59,14,5],
[70,59,14,6],
[70,59,14,8],
[70,59,14,10],
[70,59,14,11],
[70,59,14,12],
[70,59,16,0],
[70,59,16,1],
[70,59,16,2],
[70,59,16,3],
[70,59,16,4],
[70,59,16,5],
[70,59,16,6],
[70,59,16,8],
[70,59,16,10],
[70,59,16,11],
[70,59,16,12],
[70,59,16,13],
[70,59,17,0],
[70,59,17,1],
[70,59,17,2],
[70,59,17,3],
[70,59,17,4],
[70,59,17,5],
[70,59,17,6],
[70,59,17,8],
[70,59,17,10],
[70,59,17,11],
[70,59,17,12],
[70,59,17,13],
[70,59,19,0],
[70,59,19,1],
[70,59,19,2],
[70,59,19,3],
[70,59,19,4],
[70,59,19,5],
[70,59,19,6],
[70,59,19,8],
[70,59,20,0],
[70,59,20,1],
[70,59,20,2],
[70,59,20,3],
[70,59,20,4],
[70,59,20,5],
[70,59,20,6],
[70,59,21,0],
[70,59,21,1],
[70,59,21,2],
[70,59,21,4],
[70,59,22,0],
[70,59,22,1],
[70,59,22,2],
[70,59,22,3],
[70,59,22,4],
[70,59,23,0],
[70,59,23,1],
[70,59,23,2],
[70,59,24,0],
[70,59,24,1],
[70,59,24,2],
[70,60,3,0],
[70,60,3,1],
[70,60,3,2],
[70,60,4,0],
[70,60,4,1],
[70,60,4,2],
[70,60,5,4],
[70,60,6,0],
[70,60,6,1],
[70,60,6,2],
[70,60,6,4],
[70,60,8,0],
[70,60,8,1],
[70,60,8,2],
[70,60,8,4],
[70,60,9,0],
[70,60,9,1],
[70,60,9,2],
[70,60,9,3],
[70,60,9,4],
[70,60,9,5],
[70,60,9,6],
[70,60,9,8],
[70,60,10,0],
[70,60,10,1],
[70,60,10,2],
[70,60,10,3],
[70,60,10,4],
[70,60,10,5],
[70,60,10,6],
[70,60,10,8],
[70,60,10,9],
[70,60,11,0],
[70,60,11,1],
[70,60,11,2],
[70,60,11,3],
[70,60,11,4],
[70,60,11,5],
[70,60,11,6],
[70,60,11,8],
[70,60,11,9],
[70,60,11,10],
[70,60,12,0],
[70,60,12,1],
[70,60,12,2],
[70,60,12,3],
[70,60,12,4],
[70,60,12,5],
[70,60,12,6],
[70,60,12,8],
[70,60,12,9],
[70,60,12,10],
[70,60,15,0],
[70,60,15,1],
[70,60,15,2],
[70,60,15,3],
[70,60,15,4],
[70,60,15,5],
[70,60,15,6],
[70,60,15,8],
[70,60,15,10],
[70,60,15,11],
[70,60,15,12],
[70,60,16,0],
[70,60,16,1],
[70,60,16,2],
[70,60,16,3],
[70,60,16,4],
[70,60,16,5],
[70,60,16,6],
[70,60,16,8],
[70,60,16,9],
[70,60,16,10],
[70,60,16,11],
[70,60,17,0],
[70,60,17,1],
[70,60,17,2],
[70,60,17,3],
[70,60,17,4],
[70,60,17,5],
[70,60,17,6],
[70,60,17,8],
[70,60,17,9],
[70,60,17,10],
[70,60,17,11],
[70,60,18,0],
[70,60,18,1],
[70,60,18,2],
[70,60,18,3],
[70,60,18,4],
[70,60,18,5],
[70,60,18,6],
[70,60,18,8],
[70,60,20,0],
[70,60,20,1],
[70,60,20,2],
[70,60,20,3],
[70,60,20,4],
[70,60,20,5],
[70,60,21,0],
[70,60,21,1],
[70,60,21,2],
[70,60,21,4],
[70,60,22,0],
[70,60,22,1],
[70,60,22,2],
[70,61,5,0],
[70,61,5,1],
[70,61,5,2],
[70,61,6,0],
[70,61,6,1],
[70,61,6,2],
[70,61,8,0],
[70,61,8,1],
[70,61,8,2],
[70,61,9,0],
[70,61,9,1],
[70,61,9,2],
[70,61,9,3],
[70,61,9,5],
[70,61,9,6],
[70,61,9,8],
[70,61,10,0],
[70,61,10,1],
[70,61,10,2],
[70,61,10,3],
[70,61,10,5],
[70,61,10,6],
[70,61,10,8],
[70,61,10,9],
[70,61,11,0],
[70,61,11,1],
[70,61,11,2],
[70,61,11,3],
[70,61,11,5],
[70,61,11,6],
[70,61,11,8],
[70,61,11,9],
[70,61,11,10],
[70,61,12,0],
[70,61,12,1],
[70,61,12,2],
[70,61,12,3],
[70,61,12,5],
[70,61,12,6],
[70,61,12,8],
[70,61,12,9],
[70,61,12,10],
[70,61,13,0],
[70,61,13,1],
[70,61,13,2],
[70,61,13,3],
[70,61,13,5],
[70,61,13,6],
[70,61,13,8],
[70,61,13,9],
[70,61,13,10],
[70,61,13,11],
[70,61,13,12],
[70,61,14,0],
[70,61,14,1],
[70,61,14,2],
[70,61,14,3],
[70,61,14,5],
[70,61,14,6],
[70,61,14,8],
[70,61,14,9],
[70,61,14,10],
[70,61,14,11],
[70,61,14,12],
[70,61,15,0],
[70,61,15,1],
[70,61,15,2],
[70,61,15,3],
[70,61,15,5],
[70,61,15,6],
[70,61,15,8],
[70,61,15,10],
[70,61,15,11],
[70,61,16,0],
[70,61,16,1],
[70,61,16,2],
[70,61,16,3],
[70,61,16,5],
[70,61,16,6],
[70,61,16,8],
[70,61,16,9],
[70,61,17,0],
[70,61,17,1],
[70,61,17,2],
[70,61,17,3],
[70,61,17,5],
[70,61,17,6],
[70,61,17,8],
[70,61,17,9],
[70,61,18,0],
[70,61,18,1],
[70,61,18,2],
[70,61,18,3],
[70,61,18,5],
[70,61,18,6],
[70,61,19,0],
[70,61,19,1],
[70,61,19,2],
[70,61,19,3],
[70,61,19,5],
[70,61,20,0],
[70,61,20,1],
[70,61,20,2],
[70,61,20,3],
[70,61,21,0],
[70,61,21,1],
[70,61,21,2],
[70,63,3,0],
[70,63,3,1],
[70,63,3,2],
[70,63,4,0],
[70,63,4,1],
[70,63,4,2],
[70,63,4,3],
[70,63,5,0],
[70,63,5,1],
[70,63,5,2],
[70,63,5,4],
[70,63,6,0],
[70,63,6,1],
[70,63,6,2],
[70,63,6,4],
[70,63,8,0],
[70,63,8,1],
[70,63,8,2],
[70,63,8,4],
[70,63,10,0],
[70,63,10,1],
[70,63,10,2],
[70,63,10,3],
[70,63,10,4],
[70,63,10,5],
[70,63,10,6],
[70,63,10,8],
[70,63,11,0],
[70,63,11,1],
[70,63,11,2],
[70,63,11,3],
[70,63,11,4],
[70,63,11,5],
[70,63,11,6],
[70,63,11,8],
[70,63,11,10],
[70,63,12,0],
[70,63,12,1],
[70,63,12,2],
[70,63,12,3],
[70,63,12,4],
[70,63,12,5],
[70,63,12,6],
[70,63,12,8],
[70,63,12,10],
[70,63,13,0],
[70,63,13,1],
[70,63,13,2],
[70,63,13,3],
[70,63,13,4],
[70,63,13,5],
[70,63,13,6],
[70,63,13,8],
[70,63,13,10],
[70,63,13,11],
[70,63,13,12],
[70,63,14,0],
[70,63,14,1],
[70,63,14,2],
[70,63,14,3],
[70,63,14,4],
[70,63,14,5],
[70,63,14,6],
[70,63,14,8],
[70,63,14,10],
[70,63,14,11],
[70,63,16,0],
[70,63,16,1],
[70,63,16,2],
[70,63,16,3],
[70,63,16,4],
[70,63,16,5],
[70,63,16,6],
[70,63,17,0],
[70,63,17,1],
[70,63,17,2],
[70,63,17,3],
[70,63,17,4],
[70,63,17,5],
[70,63,17,6],
[70,63,19,0],
[70,63,19,1],
[70,63,19,2],
[70,63,19,3],
[70,63,19,4],
[70,63,20,0],
[70,63,20,1],
[70,63,20,2],
[70,64,3,0],
[70,64,3,1],
[70,64,3,2],
[70,64,5,0],
[70,64,5,1],
[70,64,5,2],
[70,64,6,0],
[70,64,6,1],
[70,64,6,2],
[70,64,8,0],
[70,64,8,1],
[70,64,8,2],
[70,64,9,0],
[70,64,9,1],
[70,64,9,2],
[70,64,9,3],
[70,64,9,5],
[70,64,9,6],
[70,64,9,8],
[70,64,10,0],
[70,64,10,1],
[70,64,10,2],
[70,64,10,3],
[70,64,10,5],
[70,64,10,6],
[70,64,10,8],
[70,64,10,9],
[70,64,11,0],
[70,64,11,1],
[70,64,11,2],
[70,64,11,3],
[70,64,11,5],
[70,64,11,6],
[70,64,11,8],
[70,64,11,9],
[70,64,11,10],
[70,64,12,0],
[70,64,12,1],
[70,64,12,2],
[70,64,12,3],
[70,64,12,5],
[70,64,12,6],
[70,64,12,8],
[70,64,12,9],
[70,64,12,10],
[70,64,13,0],
[70,64,13,1],
[70,64,13,2],
[70,64,13,3],
[70,64,13,5],
[70,64,13,6],
[70,64,13,8],
[70,64,13,9],
[70,64,13,10],
[70,64,13,11],
[70,64,14,0],
[70,64,14,1],
[70,64,14,2],
[70,64,14,3],
[70,64,14,5],
[70,64,14,6],
[70,64,14,8],
[70,64,14,9],
[70,64,15,0],
[70,64,15,1],
[70,64,15,2],
[70,64,15,3],
[70,64,15,5],
[70,64,15,6],
[70,64,16,0],
[70,64,16,1],
[70,64,16,2],
[70,64,16,3],
[70,64,16,5],
[70,64,17,0],
[70,64,17,1],
[70,64,17,2],
[70,64,17,3],
[70,64,17,5],
[70,64,18,0],
[70,64,18,1],
[70,64,18,2],
[70,64,18,3],
[70,64,19,0],
[70,64,19,1],
[70,64,19,2],
[70,65,3,0],
[70,65,3,1],
[70,65,3,2],
[70,65,4,0],
[70,65,4,1],
[70,65,4,2],
[70,65,4,3],
[70,65,5,0],
[70,65,5,1],
[70,65,5,2],
[70,65,5,4],
[70,65,6,0],
[70,65,6,1],
[70,65,6,2],
[70,65,6,4],
[70,65,8,0],
[70,65,8,1],
[70,65,8,2],
[70,65,8,4],
[70,65,9,0],
[70,65,9,1],
[70,65,9,2],
[70,65,9,3],
[70,65,9,4],
[70,65,9,5],
[70,65,9,6],
[70,65,9,8],
[70,65,10,0],
[70,65,10,1],
[70,65,10,2],
[70,65,10,3],
[70,65,10,4],
[70,65,10,5],
[70,65,10,6],
[70,65,10,8],
[70,65,10,9],
[70,65,11,0],
[70,65,11,1],
[70,65,11,2],
[70,65,11,3],
[70,65,11,4],
[70,65,11,5],
[70,65,11,6],
[70,65,11,8],
[70,65,11,9],
[70,65,11,10],
[70,65,12,0],
[70,65,12,1],
[70,65,12,2],
[70,65,12,3],
[70,65,12,4],
[70,65,12,5],
[70,65,12,6],
[70,65,12,8],
[70,65,12,9],
[70,65,13,0],
[70,65,13,1],
[70,65,13,2],
[70,65,13,3],
[70,65,13,4],
[70,65,13,5],
[70,65,13,6],
[70,65,13,8],
[70,65,13,9],
[70,65,14,0],
[70,65,14,1],
[70,65,14,2],
[70,65,14,3],
[70,65,14,4],
[70,65,14,5],
[70,65,14,6],
[70,65,15,0],
[70,65,15,1],
[70,65,15,2],
[70,65,15,3],
[70,65,15,4],
[70,65,15,5],
[70,65,16,0],
[70,65,16,1],
[70,65,16,2],
[70,65,16,3],
[70,65,16,4],
[70,65,17,0],
[70,65,17,1],
[70,65,17,2],
[70,65,17,3],
[70,65,17,4],
[70,65,18,0],
[70,65,18,1],
[70,65,18,2],
[70,69,3,0],
[70,69,3,1],
[70,69,3,2],
[70,69,4,0],
[70,69,4,1],
[70,69,4,2],
[70,69,4,3],
[70,69,5,0],
[70,69,5,1],
[70,69,5,2],
[70,69,5,4],
[70,69,6,0],
[70,69,6,1],
[70,69,6,2],
[70,69,6,4],
[70,69,8,0],
[70,69,8,1],
[70,69,8,2],
[70,69,8,4],
[70,69,10,0],
[70,69,10,1],
[70,69,10,2],
[70,69,10,3],
[70,69,10,4],
[70,69,11,0],
[70,69,11,1],
[70,69,11,2],
[70,69,11,3],
[70,69,11,4],
[70,69,12,0],
[70,69,12,1],
[70,69,12,2],
[70,69,13,0],
[70,69,13,1],
[70,69,13,2],
[71,4,3,0],
[71,4,3,1],
[71,4,3,2],
[71,5,4,0],
[71,5,4,1],
[71,5,4,2],
[71,6,4,0],
[71,6,4,1],
[71,6,4,2],
[71,7,3,0],
[71,7,3,1],
[71,7,3,2],
[71,7,4,0],
[71,7,4,1],
[71,7,4,2],
[71,7,4,3],
[71,7,5,0],
[71,7,5,1],
[71,7,5,2],
[71,7,5,4],
[71,7,6,0],
[71,7,6,1],
[71,7,6,2],
[71,7,6,4],
[71,8,4,0],
[71,8,4,1],
[71,8,4,2],
[71,8,7,0],
[71,8,7,1],
[71,8,7,2],
[71,8,7,4],
[71,10,3,0],
[71,10,3,1],
[71,10,3,2],
[71,10,4,0],
[71,10,4,1],
[71,10,4,2],
[71,10,4,3],
[71,10,5,0],
[71,10,5,1],
[71,10,5,2],
[71,10,5,4],
[71,10,6,0],
[71,10,6,1],
[71,10,6,2],
[71,10,6,4],
[71,10,7,0],
[71,10,7,1],
[71,10,7,2],
[71,10,7,3],
[71,10,7,4],
[71,10,7,5],
[71,10,7,6],
[71,10,8,0],
[71,10,8,1],
[71,10,8,2],
[71,10,8,4],
[71,10,8,7],
[71,11,3,0],
[71,11,3,1],
[71,11,3,2],
[71,11,4,0],
[71,11,4,1],
[71,11,4,2],
[71,11,4,3],
[71,11,5,0],
[71,11,5,1],
[71,11,5,2],
[71,11,5,4],
[71,11,6,0],
[71,11,6,1],
[71,11,6,2],
[71,11,6,4],
[71,11,7,0],
[71,11,7,1],
[71,11,7,2],
[71,11,7,3],
[71,11,7,4],
[71,11,7,5],
[71,11,7,6],
[71,11,8,0],
[71,11,8,1],
[71,11,8,2],
[71,11,8,4],
[71,11,8,7],
[71,11,10,0],
[71,11,10,1],
[71,11,10,2],
[71,11,10,3],
[71,11,10,4],
[71,11,10,5],
[71,11,10,6],
[71,11,10,7],
[71,11,10,8],
[71,12,3,0],
[71,12,3,1],
[71,12,3,2],
[71,12,4,0],
[71,12,4,1],
[71,12,4,2],
[71,12,4,3],
[71,12,5,0],
[71,12,5,1],
[71,12,5,2],
[71,12,5,4],
[71,12,6,0],
[71,12,6,1],
[71,12,6,2],
[71,12,6,4],
[71,12,7,0],
[71,12,7,1],
[71,12,7,2],
[71,12,7,3],
[71,12,7,4],
[71,12,7,5],
[71,12,7,6],
[71,12,8,0],
[71,12,8,1],
[71,12,8,2],
[71,12,8,4],
[71,12,8,7],
[71,12,10,0],
[71,12,10,1],
[71,12,10,2],
[71,12,10,3],
[71,12,10,4],
[71,12,10,5],
[71,12,10,6],
[71,12,10,7],
[71,12,10,8],
[71,13,3,0],
[71,13,3,1],
[71,13,3,2],
[71,13,4,0],
[71,13,4,1],
[71,13,4,2],
[71,13,4,3],
[71,13,5,0],
[71,13,5,1],
[71,13,5,2],
[71,13,5,4],
[71,13,6,0],
[71,13,6,1],
[71,13,6,2],
[71,13,6,4],
[71,13,7,0],
[71,13,7,1],
[71,13,7,2],
[71,13,7,3],
[71,13,7,4],
[71,13,7,5],
[71,13,7,6],
[71,13,8,0],
[71,13,8,1],
[71,13,8,2],
[71,13,8,4],
[71,13,8,7],
[71,13,10,0],
[71,13,10,1],
[71,13,10,2],
[71,13,10,3],
[71,13,10,4],
[71,13,10,5],
[71,13,10,6],
[71,13,10,7],
[71,13,10,8],
[71,13,11,0],
[71,13,11,1],
[71,13,11,2],
[71,13,11,3],
[71,13,11,4],
[71,13,11,5],
[71,13,11,6],
[71,13,11,7],
[71,13,11,8],
[71,13,11,10],
[71,13,12,0],
[71,13,12,1],
[71,13,12,2],
[71,13,12,3],
[71,13,12,4],
[71,13,12,5],
[71,13,12,6],
[71,13,12,7],
[71,13,12,8],
[71,13,12,10],
[71,14,3,0],
[71,14,3,1],
[71,14,3,2],
[71,14,4,0],
[71,14,4,1],
[71,14,4,2],
[71,14,4,3],
[71,14,5,0],
[71,14,5,1],
[71,14,5,2],
[71,14,5,4],
[71,14,6,0],
[71,14,6,1],
[71,14,6,2],
[71,14,6,4],
[71,14,7,0],
[71,14,7,1],
[71,14,7,2],
[71,14,7,3],
[71,14,7,4],
[71,14,7,5],
[71,14,7,6],
[71,14,8,0],
[71,14,8,1],
[71,14,8,2],
[71,14,8,4],
[71,14,8,7],
[71,14,10,0],
[71,14,10,1],
[71,14,10,2],
[71,14,10,3],
[71,14,10,4],
[71,14,10,5],
[71,14,10,6],
[71,14,10,7],
[71,14,10,8],
[71,14,11,0],
[71,14,11,1],
[71,14,11,2],
[71,14,11,3],
[71,14,11,4],
[71,14,11,5],
[71,14,11,6],
[71,14,11,7],
[71,14,11,8],
[71,14,11,10],
[71,14,12,0],
[71,14,12,1],
[71,14,12,2],
[71,14,12,3],
[71,14,12,4],
[71,14,12,5],
[71,14,12,6],
[71,14,12,7],
[71,14,12,8],
[71,14,12,10],
[71,16,3,0],
[71,16,3,1],
[71,16,3,2],
[71,16,4,0],
[71,16,4,1],
[71,16,4,2],
[71,16,4,3],
[71,16,5,0],
[71,16,5,1],
[71,16,5,2],
[71,16,5,4],
[71,16,6,0],
[71,16,6,1],
[71,16,6,2],
[71,16,6,4],
[71,16,7,0],
[71,16,7,1],
[71,16,7,2],
[71,16,7,3],
[71,16,7,4],
[71,16,7,5],
[71,16,7,6],
[71,16,8,0],
[71,16,8,1],
[71,16,8,2],
[71,16,8,4],
[71,16,8,7],
[71,16,10,0],
[71,16,10,1],
[71,16,10,2],
[71,16,10,3],
[71,16,10,4],
[71,16,10,5],
[71,16,10,6],
[71,16,10,7],
[71,16,10,8],
[71,16,11,0],
[71,16,11,1],
[71,16,11,2],
[71,16,11,3],
[71,16,11,4],
[71,16,11,5],
[71,16,11,6],
[71,16,11,7],
[71,16,11,8],
[71,16,11,10],
[71,16,12,0],
[71,16,12,1],
[71,16,12,2],
[71,16,12,3],
[71,16,12,4],
[71,16,12,5],
[71,16,12,6],
[71,16,12,7],
[71,16,12,8],
[71,16,12,10],
[71,16,13,0],
[71,16,13,1],
[71,16,13,2],
[71,16,13,3],
[71,16,13,4],
[71,16,13,5],
[71,16,13,6],
[71,16,13,7],
[71,16,13,8],
[71,16,13,10],
[71,16,13,11],
[71,16,13,12],
[71,16,14,0],
[71,16,14,1],
[71,16,14,2],
[71,16,14,3],
[71,16,14,4],
[71,16,14,5],
[71,16,14,6],
[71,16,14,7],
[71,16,14,8],
[71,16,14,10],
[71,16,14,11],
[71,16,14,12],
[71,17,3,0],
[71,17,3,1],
[71,17,3,2],
[71,17,4,0],
[71,17,4,1],
[71,17,4,2],
[71,17,4,3],
[71,17,5,0],
[71,17,5,1],
[71,17,5,2],
[71,17,5,4],
[71,17,6,0],
[71,17,6,1],
[71,17,6,2],
[71,17,6,4],
[71,17,7,0],
[71,17,7,1],
[71,17,7,2],
[71,17,7,3],
[71,17,7,4],
[71,17,7,5],
[71,17,7,6],
[71,17,8,0],
[71,17,8,1],
[71,17,8,2],
[71,17,8,4],
[71,17,8,7],
[71,17,10,0],
[71,17,10,1],
[71,17,10,2],
[71,17,10,3],
[71,17,10,4],
[71,17,10,5],
[71,17,10,6],
[71,17,10,7],
[71,17,10,8],
[71,17,11,0],
[71,17,11,1],
[71,17,11,2],
[71,17,11,3],
[71,17,11,4],
[71,17,11,5],
[71,17,11,6],
[71,17,11,7],
[71,17,11,8],
[71,17,11,10],
[71,17,12,0],
[71,17,12,1],
[71,17,12,2],
[71,17,12,3],
[71,17,12,4],
[71,17,12,5],
[71,17,12,6],
[71,17,12,7],
[71,17,12,8],
[71,17,12,10],
[71,17,13,0],
[71,17,13,1],
[71,17,13,2],
[71,17,13,3],
[71,17,13,4],
[71,17,13,5],
[71,17,13,6],
[71,17,13,7],
[71,17,13,8],
[71,17,13,10],
[71,17,13,11],
[71,17,13,12],
[71,17,14,0],
[71,17,14,1],
[71,17,14,2],
[71,17,14,3],
[71,17,14,4],
[71,17,14,5],
[71,17,14,6],
[71,17,14,7],
[71,17,14,8],
[71,17,14,10],
[71,17,14,11],
[71,17,14,12],
[71,17,16,0],
[71,17,16,1],
[71,17,16,2],
[71,17,16,3],
[71,17,16,4],
[71,17,16,5],
[71,17,16,6],
[71,17,16,7],
[71,17,16,8],
[71,17,16,10],
[71,17,16,11],
[71,17,16,12],
[71,17,16,13],
[71,17,16,14],
[71,19,3,0],
[71,19,3,1],
[71,19,3,2],
[71,19,4,0],
[71,19,4,1],
[71,19,4,2],
[71,19,4,3],
[71,19,5,0],
[71,19,5,1],
[71,19,5,2],
[71,19,5,4],
[71,19,6,0],
[71,19,6,1],
[71,19,6,2],
[71,19,6,4],
[71,19,7,0],
[71,19,7,1],
[71,19,7,2],
[71,19,7,3],
[71,19,7,4],
[71,19,7,5],
[71,19,7,6],
[71,19,8,0],
[71,19,8,1],
[71,19,8,2],
[71,19,8,4],
[71,19,8,7],
[71,19,10,0],
[71,19,10,1],
[71,19,10,2],
[71,19,10,3],
[71,19,10,4],
[71,19,10,5],
[71,19,10,6],
[71,19,10,7],
[71,19,10,8],
[71,19,11,0],
[71,19,11,1],
[71,19,11,2],
[71,19,11,3],
[71,19,11,4],
[71,19,11,5],
[71,19,11,6],
[71,19,11,7],
[71,19,11,8],
[71,19,11,10],
[71,19,12,0],
[71,19,12,1],
[71,19,12,2],
[71,19,12,3],
[71,19,12,4],
[71,19,12,5],
[71,19,12,6],
[71,19,12,7],
[71,19,12,8],
[71,19,12,10],
[71,19,16,0],
[71,19,16,1],
[71,19,16,2],
[71,19,16,3],
[71,19,16,4],
[71,19,16,5],
[71,19,16,6],
[71,19,16,7],
[71,19,16,8],
[71,19,16,10],
[71,19,16,11],
[71,19,16,12],
[71,19,17,0],
[71,19,17,1],
[71,19,17,2],
[71,19,17,3],
[71,19,17,4],
[71,19,17,5],
[71,19,17,6],
[71,19,17,7],
[71,19,17,8],
[71,19,17,10],
[71,19,17,11],
[71,19,17,12],
[71,19,17,16],
[71,20,3,0],
[71,20,3,1],
[71,20,3,2],
[71,20,4,0],
[71,20,4,1],
[71,20,4,2],
[71,20,4,3],
[71,20,5,0],
[71,20,5,1],
[71,20,5,2],
[71,20,5,4],
[71,20,6,0],
[71,20,6,1],
[71,20,6,2],
[71,20,6,4],
[71,20,7,0],
[71,20,7,1],
[71,20,7,2],
[71,20,7,3],
[71,20,7,4],
[71,20,7,5],
[71,20,7,6],
[71,20,8,0],
[71,20,8,1],
[71,20,8,2],
[71,20,8,4],
[71,20,8,7],
[71,20,11,0],
[71,20,11,1],
[71,20,11,2],
[71,20,11,3],
[71,20,11,4],
[71,20,11,5],
[71,20,11,6],
[71,20,11,7],
[71,20,11,8],
[71,20,12,0],
[71,20,12,1],
[71,20,12,2],
[71,20,12,3],
[71,20,12,4],
[71,20,12,5],
[71,20,12,6],
[71,20,12,7],
[71,20,12,8],
[71,20,13,0],
[71,20,13,1],
[71,20,13,2],
[71,20,13,3],
[71,20,13,4],
[71,20,13,5],
[71,20,13,6],
[71,20,13,7],
[71,20,13,8],
[71,20,13,11],
[71,20,13,12],
[71,20,14,0],
[71,20,14,1],
[71,20,14,2],
[71,20,14,3],
[71,20,14,4],
[71,20,14,5],
[71,20,14,6],
[71,20,14,7],
[71,20,14,8],
[71,20,14,11],
[71,20,14,12],
[71,20,16,0],
[71,20,16,1],
[71,20,16,2],
[71,20,16,3],
[71,20,16,4],
[71,20,16,5],
[71,20,16,6],
[71,20,16,7],
[71,20,16,8],
[71,20,16,11],
[71,20,16,12],
[71,20,16,13],
[71,20,16,14],
[71,20,17,0],
[71,20,17,1],
[71,20,17,2],
[71,20,17,3],
[71,20,17,4],
[71,20,17,5],
[71,20,17,6],
[71,20,17,7],
[71,20,17,8],
[71,20,17,11],
[71,20,17,12],
[71,20,17,13],
[71,20,17,14],
[71,20,17,16],
[71,20,19,0],
[71,20,19,1],
[71,20,19,2],
[71,20,19,3],
[71,20,19,4],
[71,20,19,5],
[71,20,19,6],
[71,20,19,7],
[71,20,19,8],
[71,20,19,11],
[71,20,19,12],
[71,20,19,16],
[71,20,19,17],
[71,21,4,0],
[71,21,4,1],
[71,21,4,2],
[71,21,7,0],
[71,21,7,1],
[71,21,7,2],
[71,21,7,4],
[71,21,10,0],
[71,21,10,1],
[71,21,10,2],
[71,21,10,4],
[71,21,10,7],
[71,21,11,0],
[71,21,11,1],
[71,21,11,2],
[71,21,11,4],
[71,21,11,7],
[71,21,11,10],
[71,21,12,0],
[71,21,12,1],
[71,21,12,2],
[71,21,12,4],
[71,21,12,7],
[71,21,12,10],
[71,21,13,0],
[71,21,13,1],
[71,21,13,2],
[71,21,13,4],
[71,21,13,7],
[71,21,13,10],
[71,21,13,11],
[71,21,13,12],
[71,21,14,0],
[71,21,14,1],
[71,21,14,2],
[71,21,14,4],
[71,21,14,7],
[71,21,14,10],
[71,21,14,11],
[71,21,14,12],
[71,21,16,0],
[71,21,16,1],
[71,21,16,2],
[71,21,16,4],
[71,21,16,7],
[71,21,16,10],
[71,21,16,11],
[71,21,16,12],
[71,21,16,13],
[71,21,16,14],
[71,21,17,0],
[71,21,17,1],
[71,21,17,2],
[71,21,17,4],
[71,21,17,7],
[71,21,17,10],
[71,21,17,11],
[71,21,17,12],
[71,21,17,13],
[71,21,17,14],
[71,21,17,16],
[71,21,19,0],
[71,21,19,1],
[71,21,19,2],
[71,21,19,4],
[71,21,19,7],
[71,21,19,10],
[71,21,19,11],
[71,21,19,12],
[71,21,19,16],
[71,21,19,17],
[71,21,20,0],
[71,21,20,1],
[71,21,20,2],
[71,21,20,4],
[71,21,20,7],
[71,21,20,11],
[71,21,20,12],
[71,21,20,13],
[71,21,20,14],
[71,21,20,16],
[71,21,20,17],
[71,21,20,19],
[71,22,3,0],
[71,22,3,1],
[71,22,3,2],
[71,22,4,0],
[71,22,4,1],
[71,22,4,2],
[71,22,4,3],
[71,22,5,0],
[71,22,5,1],
[71,22,5,2],
[71,22,5,4],
[71,22,6,0],
[71,22,6,1],
[71,22,6,2],
[71,22,6,4],
[71,22,7,0],
[71,22,7,1],
[71,22,7,2],
[71,22,7,3],
[71,22,7,4],
[71,22,7,5],
[71,22,7,6],
[71,22,8,0],
[71,22,8,1],
[71,22,8,2],
[71,22,8,4],
[71,22,8,7],
[71,22,11,0],
[71,22,11,1],
[71,22,11,2],
[71,22,11,3],
[71,22,11,4],
[71,22,11,5],
[71,22,11,6],
[71,22,11,7],
[71,22,11,8],
[71,22,12,0],
[71,22,12,1],
[71,22,12,2],
[71,22,12,3],
[71,22,12,4],
[71,22,12,5],
[71,22,12,6],
[71,22,12,7],
[71,22,12,8],
[71,22,13,0],
[71,22,13,1],
[71,22,13,2],
[71,22,13,3],
[71,22,13,4],
[71,22,13,5],
[71,22,13,6],
[71,22,13,7],
[71,22,13,8],
[71,22,13,11],
[71,22,13,12],
[71,22,14,0],
[71,22,14,1],
[71,22,14,2],
[71,22,14,3],
[71,22,14,4],
[71,22,14,5],
[71,22,14,6],
[71,22,14,7],
[71,22,14,8],
[71,22,14,11],
[71,22,14,12],
[71,22,16,0],
[71,22,16,1],
[71,22,16,2],
[71,22,16,3],
[71,22,16,4],
[71,22,16,5],
[71,22,16,6],
[71,22,16,7],
[71,22,16,8],
[71,22,16,11],
[71,22,16,12],
[71,22,16,13],
[71,22,16,14],
[71,22,17,0],
[71,22,17,1],
[71,22,17,2],
[71,22,17,3],
[71,22,17,4],
[71,22,17,5],
[71,22,17,6],
[71,22,17,7],
[71,22,17,8],
[71,22,17,11],
[71,22,17,12],
[71,22,17,13],
[71,22,17,14],
[71,22,17,16],
[71,22,19,0],
[71,22,19,1],
[71,22,19,2],
[71,22,19,3],
[71,22,19,4],
[71,22,19,5],
[71,22,19,6],
[71,22,19,7],
[71,22,19,8],
[71,22,19,11],
[71,22,19,12],
[71,22,19,16],
[71,22,19,17],
[71,22,21,0],
[71,22,21,1],
[71,22,21,2],
[71,22,21,4],
[71,22,21,7],
[71,22,21,11],
[71,22,21,12],
[71,22,21,13],
[71,22,21,14],
[71,22,21,16],
[71,22,21,17],
[71,22,21,19],
[71,23,3,0],
[71,23,3,1],
[71,23,3,2],
[71,23,4,0],
[71,23,4,1],
[71,23,4,2],
[71,23,4,3],
[71,23,5,0],
[71,23,5,1],
[71,23,5,2],
[71,23,5,4],
[71,23,6,0],
[71,23,6,1],
[71,23,6,2],
[71,23,6,4],
[71,23,7,0],
[71,23,7,1],
[71,23,7,2],
[71,23,7,3],
[71,23,7,4],
[71,23,7,5],
[71,23,7,6],
[71,23,8,0],
[71,23,8,1],
[71,23,8,2],
[71,23,8,4],
[71,23,8,7],
[71,23,10,0],
[71,23,10,1],
[71,23,10,2],
[71,23,10,3],
[71,23,10,4],
[71,23,10,5],
[71,23,10,6],
[71,23,10,7],
[71,23,10,8],
[71,23,11,0],
[71,23,11,1],
[71,23,11,2],
[71,23,11,3],
[71,23,11,4],
[71,23,11,5],
[71,23,11,6],
[71,23,11,7],
[71,23,11,8],
[71,23,11,10],
[71,23,12,0],
[71,23,12,1],
[71,23,12,2],
[71,23,12,3],
[71,23,12,4],
[71,23,12,5],
[71,23,12,6],
[71,23,12,7],
[71,23,12,8],
[71,23,12,10],
[71,23,13,0],
[71,23,13,1],
[71,23,13,2],
[71,23,13,3],
[71,23,13,4],
[71,23,13,5],
[71,23,13,6],
[71,23,13,7],
[71,23,13,8],
[71,23,13,10],
[71,23,13,11],
[71,23,13,12],
[71,23,14,0],
[71,23,14,1],
[71,23,14,2],
[71,23,14,3],
[71,23,14,4],
[71,23,14,5],
[71,23,14,6],
[71,23,14,7],
[71,23,14,8],
[71,23,14,10],
[71,23,14,11],
[71,23,14,12],
[71,23,16,0],
[71,23,16,1],
[71,23,16,2],
[71,23,16,3],
[71,23,16,4],
[71,23,16,5],
[71,23,16,6],
[71,23,16,7],
[71,23,16,8],
[71,23,16,10],
[71,23,16,11],
[71,23,16,12],
[71,23,16,13],
[71,23,16,14],
[71,23,17,0],
[71,23,17,1],
[71,23,17,2],
[71,23,17,3],
[71,23,17,4],
[71,23,17,5],
[71,23,17,6],
[71,23,17,7],
[71,23,17,8],
[71,23,17,10],
[71,23,17,11],
[71,23,17,12],
[71,23,17,13],
[71,23,17,14],
[71,23,17,16],
[71,23,19,0],
[71,23,19,1],
[71,23,19,2],
[71,23,19,3],
[71,23,19,4],
[71,23,19,5],
[71,23,19,6],
[71,23,19,7],
[71,23,19,8],
[71,23,19,10],
[71,23,19,11],
[71,23,19,12],
[71,23,19,16],
[71,23,19,17],
[71,23,20,0],
[71,23,20,1],
[71,23,20,2],
[71,23,20,3],
[71,23,20,4],
[71,23,20,5],
[71,23,20,6],
[71,23,20,7],
[71,23,20,8],
[71,23,20,11],
[71,23,20,12],
[71,23,20,13],
[71,23,20,14],
[71,23,20,16],
[71,23,20,17],
[71,23,20,19],
[71,23,21,0],
[71,23,21,1],
[71,23,21,2],
[71,23,21,4],
[71,23,21,7],
[71,23,21,10],
[71,23,21,11],
[71,23,21,12],
[71,23,21,13],
[71,23,21,14],
[71,23,21,16],
[71,23,21,17],
[71,23,21,19],
[71,23,21,20],
[71,23,22,0],
[71,23,22,1],
[71,23,22,2],
[71,23,22,3],
[71,23,22,4],
[71,23,22,5],
[71,23,22,6],
[71,23,22,7],
[71,23,22,8],
[71,23,22,11],
[71,23,22,12],
[71,23,22,13],
[71,23,22,14],
[71,23,22,16],
[71,23,22,17],
[71,23,22,19],
[71,23,22,21],
[71,24,3,0],
[71,24,3,1],
[71,24,3,2],
[71,24,4,0],
[71,24,4,1],
[71,24,4,2],
[71,24,4,3],
[71,24,5,0],
[71,24,5,1],
[71,24,5,2],
[71,24,5,4],
[71,24,6,0],
[71,24,6,1],
[71,24,6,2],
[71,24,6,4],
[71,24,7,0],
[71,24,7,1],
[71,24,7,2],
[71,24,7,3],
[71,24,7,4],
[71,24,7,5],
[71,24,7,6],
[71,24,8,0],
[71,24,8,1],
[71,24,8,2],
[71,24,8,4],
[71,24,8,7],
[71,24,10,0],
[71,24,10,1],
[71,24,10,2],
[71,24,10,3],
[71,24,10,4],
[71,24,10,5],
[71,24,10,6],
[71,24,10,7],
[71,24,10,8],
[71,24,11,0],
[71,24,11,1],
[71,24,11,2],
[71,24,11,3],
[71,24,11,4],
[71,24,11,5],
[71,24,11,6],
[71,24,11,7],
[71,24,11,8],
[71,24,11,10],
[71,24,12,0],
[71,24,12,1],
[71,24,12,2],
[71,24,12,3],
[71,24,12,4],
[71,24,12,5],
[71,24,12,6],
[71,24,12,7],
[71,24,12,8],
[71,24,12,10],
[71,24,13,0],
[71,24,13,1],
[71,24,13,2],
[71,24,13,3],
[71,24,13,4],
[71,24,13,5],
[71,24,13,6],
[71,24,13,7],
[71,24,13,8],
[71,24,13,10],
[71,24,13,11],
[71,24,13,12],
[71,24,14,0],
[71,24,14,1],
[71,24,14,2],
[71,24,14,3],
[71,24,14,4],
[71,24,14,5],
[71,24,14,6],
[71,24,14,7],
[71,24,14,8],
[71,24,14,10],
[71,24,14,11],
[71,24,14,12],
[71,24,17,0],
[71,24,17,1],
[71,24,17,2],
[71,24,17,3],
[71,24,17,4],
[71,24,17,5],
[71,24,17,6],
[71,24,17,7],
[71,24,17,8],
[71,24,17,10],
[71,24,17,11],
[71,24,17,12],
[71,24,17,13],
[71,24,17,14],
[71,24,19,0],
[71,24,19,1],
[71,24,19,2],
[71,24,19,3],
[71,24,19,4],
[71,24,19,5],
[71,24,19,6],
[71,24,19,7],
[71,24,19,8],
[71,24,19,10],
[71,24,19,11],
[71,24,19,12],
[71,24,19,17],
[71,24,20,0],
[71,24,20,1],
[71,24,20,2],
[71,24,20,3],
[71,24,20,4],
[71,24,20,5],
[71,24,20,6],
[71,24,20,7],
[71,24,20,8],
[71,24,20,11],
[71,24,20,12],
[71,24,20,13],
[71,24,20,14],
[71,24,20,17],
[71,24,20,19],
[71,24,21,0],
[71,24,21,1],
[71,24,21,2],
[71,24,21,4],
[71,24,21,7],
[71,24,21,10],
[71,24,21,11],
[71,24,21,12],
[71,24,21,13],
[71,24,21,14],
[71,24,21,17],
[71,24,21,19],
[71,24,21,20],
[71,24,22,0],
[71,24,22,1],
[71,24,22,2],
[71,24,22,3],
[71,24,22,4],
[71,24,22,5],
[71,24,22,6],
[71,24,22,7],
[71,24,22,8],
[71,24,22,11],
[71,24,22,12],
[71,24,22,13],
[71,24,22,14],
[71,24,22,17],
[71,24,22,19],
[71,24,22,21],
[71,24,23,0],
[71,24,23,1],
[71,24,23,2],
[71,24,23,3],
[71,24,23,4],
[71,24,23,5],
[71,24,23,6],
[71,24,23,7],
[71,24,23,8],
[71,24,23,10],
[71,24,23,11],
[71,24,23,12],
[71,24,23,13],
[71,24,23,14],
[71,24,23,17],
[71,24,23,19],
[71,24,23,20],
[71,24,23,22],
[71,25,3,0],
[71,25,3,1],
[71,25,3,2],
[71,25,4,0],
[71,25,4,1],
[71,25,4,2],
[71,25,4,3],
[71,25,5,0],
[71,25,5,1],
[71,25,5,2],
[71,25,5,4],
[71,25,6,0],
[71,25,6,1],
[71,25,6,2],
[71,25,6,4],
[71,25,8,0],
[71,25,8,1],
[71,25,8,2],
[71,25,8,4],
[71,25,10,0],
[71,25,10,1],
[71,25,10,2],
[71,25,10,3],
[71,25,10,4],
[71,25,10,5],
[71,25,10,6],
[71,25,10,8],
[71,25,11,0],
[71,25,11,1],
[71,25,11,2],
[71,25,11,3],
[71,25,11,4],
[71,25,11,5],
[71,25,11,6],
[71,25,11,8],
[71,25,11,10],
[71,25,12,0],
[71,25,12,1],
[71,25,12,2],
[71,25,12,3],
[71,25,12,4],
[71,25,12,5],
[71,25,12,6],
[71,25,12,8],
[71,25,12,10],
[71,25,13,0],
[71,25,13,1],
[71,25,13,2],
[71,25,13,3],
[71,25,13,4],
[71,25,13,5],
[71,25,13,6],
[71,25,13,8],
[71,25,13,10],
[71,25,13,11],
[71,25,13,12],
[71,25,14,0],
[71,25,14,1],
[71,25,14,2],
[71,25,14,3],
[71,25,14,4],
[71,25,14,5],
[71,25,14,6],
[71,25,14,8],
[71,25,14,10],
[71,25,14,11],
[71,25,14,12],
[71,25,16,0],
[71,25,16,1],
[71,25,16,2],
[71,25,16,3],
[71,25,16,4],
[71,25,16,5],
[71,25,16,6],
[71,25,16,8],
[71,25,16,10],
[71,25,16,11],
[71,25,16,12],
[71,25,16,13],
[71,25,16,14],
[71,25,17,0],
[71,25,17,1],
[71,25,17,2],
[71,25,17,3],
[71,25,17,4],
[71,25,17,5],
[71,25,17,6],
[71,25,17,8],
[71,25,17,10],
[71,25,17,11],
[71,25,17,12],
[71,25,17,13],
[71,25,17,14],
[71,25,17,16],
[71,25,19,0],
[71,25,19,1],
[71,25,19,2],
[71,25,19,3],
[71,25,19,4],
[71,25,19,5],
[71,25,19,6],
[71,25,19,8],
[71,25,19,10],
[71,25,19,11],
[71,25,19,12],
[71,25,19,16],
[71,25,19,17],
[71,25,20,0],
[71,25,20,1],
[71,25,20,2],
[71,25,20,3],
[71,25,20,4],
[71,25,20,5],
[71,25,20,6],
[71,25,20,8],
[71,25,20,11],
[71,25,20,12],
[71,25,20,13],
[71,25,20,14],
[71,25,20,16],
[71,25,20,17],
[71,25,20,19],
[71,25,21,0],
[71,25,21,1],
[71,25,21,2],
[71,25,21,4],
[71,25,21,10],
[71,25,21,11],
[71,25,21,12],
[71,25,21,13],
[71,25,21,14],
[71,25,21,16],
[71,25,21,17],
[71,25,21,19],
[71,25,21,20],
[71,25,22,0],
[71,25,22,1],
[71,25,22,2],
[71,25,22,3],
[71,25,22,4],
[71,25,22,5],
[71,25,22,6],
[71,25,22,8],
[71,25,22,11],
[71,25,22,12],
[71,25,22,13],
[71,25,22,14],
[71,25,22,16],
[71,25,22,17],
[71,25,22,19],
[71,25,22,21],
[71,25,23,0],
[71,25,23,1],
[71,25,23,2],
[71,25,23,3],
[71,25,23,4],
[71,25,23,5],
[71,25,23,6],
[71,25,23,8],
[71,25,23,10],
[71,25,23,11],
[71,25,23,12],
[71,25,23,13],
[71,25,23,14],
[71,25,23,16],
[71,25,23,17],
[71,25,23,20],
[71,25,23,21],
[71,25,23,22],
[71,25,24,0],
[71,25,24,1],
[71,25,24,2],
[71,25,24,3],
[71,25,24,4],
[71,25,24,5],
[71,25,24,6],
[71,25,24,8],
[71,25,24,10],
[71,25,24,11],
[71,25,24,12],
[71,25,24,13],
[71,25,24,14],
[71,25,24,17],
[71,25,24,20],
[71,25,24,21],
[71,25,24,22],
[71,25,24,23],
[71,27,3,0],
[71,27,3,1],
[71,27,3,2],
[71,27,4,0],
[71,27,4,1],
[71,27,4,2],
[71,27,4,3],
[71,27,5,0],
[71,27,5,1],
[71,27,5,2],
[71,27,5,4],
[71,27,6,0],
[71,27,6,1],
[71,27,6,2],
[71,27,6,4],
[71,27,7,0],
[71,27,7,1],
[71,27,7,2],
[71,27,7,3],
[71,27,7,4],
[71,27,7,5],
[71,27,7,6],
[71,27,8,0],
[71,27,8,1],
[71,27,8,2],
[71,27,8,4],
[71,27,8,7],
[71,27,11,0],
[71,27,11,1],
[71,27,11,2],
[71,27,11,3],
[71,27,11,4],
[71,27,11,5],
[71,27,11,6],
[71,27,11,7],
[71,27,11,8],
[71,27,12,0],
[71,27,12,1],
[71,27,12,2],
[71,27,12,3],
[71,27,12,4],
[71,27,12,5],
[71,27,12,6],
[71,27,12,7],
[71,27,12,8],
[71,27,13,0],
[71,27,13,1],
[71,27,13,2],
[71,27,13,3],
[71,27,13,4],
[71,27,13,5],
[71,27,13,6],
[71,27,13,7],
[71,27,13,8],
[71,27,13,11],
[71,27,13,12],
[71,27,14,0],
[71,27,14,1],
[71,27,14,2],
[71,27,14,3],
[71,27,14,4],
[71,27,14,5],
[71,27,14,6],
[71,27,14,7],
[71,27,14,8],
[71,27,14,11],
[71,27,14,12],
[71,27,16,0],
[71,27,16,1],
[71,27,16,2],
[71,27,16,3],
[71,27,16,4],
[71,27,16,5],
[71,27,16,6],
[71,27,16,7],
[71,27,16,8],
[71,27,16,11],
[71,27,16,12],
[71,27,16,13],
[71,27,16,14],
[71,27,17,0],
[71,27,17,1],
[71,27,17,2],
[71,27,17,3],
[71,27,17,4],
[71,27,17,5],
[71,27,17,6],
[71,27,17,7],
[71,27,17,8],
[71,27,17,11],
[71,27,17,12],
[71,27,17,13],
[71,27,17,14],
[71,27,17,16],
[71,27,19,0],
[71,27,19,1],
[71,27,19,2],
[71,27,19,3],
[71,27,19,4],
[71,27,19,5],
[71,27,19,6],
[71,27,19,7],
[71,27,19,8],
[71,27,19,11],
[71,27,19,12],
[71,27,19,16],
[71,27,19,17],
[71,27,21,0],
[71,27,21,1],
[71,27,21,2],
[71,27,21,4],
[71,27,21,7],
[71,27,21,11],
[71,27,21,12],
[71,27,21,13],
[71,27,21,14],
[71,27,21,16],
[71,27,21,17],
[71,27,23,0],
[71,27,23,1],
[71,27,23,2],
[71,27,23,3],
[71,27,23,4],
[71,27,23,5],
[71,27,23,6],
[71,27,23,7],
[71,27,23,8],
[71,27,23,11],
[71,27,23,12],
[71,27,23,13],
[71,27,23,14],
[71,27,23,19],
[71,27,23,21],
[71,27,24,0],
[71,27,24,1],
[71,27,24,2],
[71,27,24,3],
[71,27,24,4],
[71,27,24,5],
[71,27,24,6],
[71,27,24,7],
[71,27,24,8],
[71,27,24,11],
[71,27,24,12],
[71,27,24,13],
[71,27,24,14],
[71,27,24,19],
[71,27,24,21],
[71,27,24,23],
[71,27,25,0],
[71,27,25,1],
[71,27,25,2],
[71,27,25,3],
[71,27,25,4],
[71,27,25,5],
[71,27,25,6],
[71,27,25,8],
[71,27,25,11],
[71,27,25,12],
[71,27,25,13],
[71,27,25,16],
[71,27,25,17],
[71,27,25,19],
[71,27,25,21],
[71,27,25,23],
[71,27,25,24],
[71,28,3,0],
[71,28,3,1],
[71,28,3,2],
[71,28,4,0],
[71,28,4,1],
[71,28,4,2],
[71,28,4,3],
[71,28,5,0],
[71,28,5,1],
[71,28,5,2],
[71,28,5,4],
[71,28,6,0],
[71,28,6,1],
[71,28,6,2],
[71,28,6,4],
[71,28,8,0],
[71,28,8,1],
[71,28,8,2],
[71,28,8,4],
[71,28,10,0],
[71,28,10,1],
[71,28,10,2],
[71,28,10,3],
[71,28,10,4],
[71,28,10,5],
[71,28,10,6],
[71,28,10,8],
[71,28,11,0],
[71,28,11,1],
[71,28,11,2],
[71,28,11,3],
[71,28,11,4],
[71,28,11,5],
[71,28,11,6],
[71,28,11,8],
[71,28,11,10],
[71,28,12,0],
[71,28,12,1],
[71,28,12,2],
[71,28,12,3],
[71,28,12,4],
[71,28,12,5],
[71,28,12,6],
[71,28,12,8],
[71,28,12,10],
[71,28,13,0],
[71,28,13,1],
[71,28,13,2],
[71,28,13,3],
[71,28,13,4],
[71,28,13,5],
[71,28,13,6],
[71,28,13,8],
[71,28,13,10],
[71,28,13,11],
[71,28,13,12],
[71,28,14,0],
[71,28,14,1],
[71,28,14,2],
[71,28,14,3],
[71,28,14,4],
[71,28,14,5],
[71,28,14,6],
[71,28,14,8],
[71,28,14,10],
[71,28,14,11],
[71,28,14,12],
[71,28,16,0],
[71,28,16,1],
[71,28,16,2],
[71,28,16,3],
[71,28,16,4],
[71,28,16,5],
[71,28,16,6],
[71,28,16,8],
[71,28,16,10],
[71,28,16,11],
[71,28,16,12],
[71,28,16,13],
[71,28,16,14],
[71,28,17,0],
[71,28,17,1],
[71,28,17,2],
[71,28,17,3],
[71,28,17,4],
[71,28,17,5],
[71,28,17,6],
[71,28,17,8],
[71,28,17,10],
[71,28,17,11],
[71,28,17,12],
[71,28,17,13],
[71,28,17,14],
[71,28,17,16],
[71,28,19,0],
[71,28,19,1],
[71,28,19,2],
[71,28,19,3],
[71,28,19,4],
[71,28,19,5],
[71,28,19,6],
[71,28,19,8],
[71,28,19,10],
[71,28,19,11],
[71,28,19,12],
[71,28,19,16],
[71,28,19,17],
[71,28,20,0],
[71,28,20,1],
[71,28,20,2],
[71,28,20,3],
[71,28,20,4],
[71,28,20,5],
[71,28,20,6],
[71,28,20,8],
[71,28,20,11],
[71,28,20,12],
[71,28,20,13],
[71,28,20,14],
[71,28,20,16],
[71,28,20,17],
[71,28,21,0],
[71,28,21,1],
[71,28,21,2],
[71,28,21,4],
[71,28,21,10],
[71,28,21,11],
[71,28,21,12],
[71,28,21,13],
[71,28,21,14],
[71,28,21,16],
[71,28,21,17],
[71,28,21,19],
[71,28,21,20],
[71,28,22,0],
[71,28,22,1],
[71,28,22,2],
[71,28,22,3],
[71,28,22,4],
[71,28,22,5],
[71,28,22,6],
[71,28,22,8],
[71,28,22,11],
[71,28,22,12],
[71,28,22,13],
[71,28,22,14],
[71,28,22,19],
[71,28,22,21],
[71,28,23,0],
[71,28,23,1],
[71,28,23,2],
[71,28,23,3],
[71,28,23,4],
[71,28,23,5],
[71,28,23,6],
[71,28,23,8],
[71,28,23,10],
[71,28,23,11],
[71,28,23,12],
[71,28,23,13],
[71,28,23,14],
[71,28,23,16],
[71,28,23,17],
[71,28,23,19],
[71,28,23,20],
[71,28,23,21],
[71,28,23,22],
[71,28,24,0],
[71,28,24,1],
[71,28,24,2],
[71,28,24,3],
[71,28,24,4],
[71,28,24,5],
[71,28,24,6],
[71,28,24,8],
[71,28,24,10],
[71,28,24,11],
[71,28,24,12],
[71,28,24,13],
[71,28,24,14],
[71,28,24,17],
[71,28,24,19],
[71,28,24,20],
[71,28,24,21],
[71,28,24,22],
[71,28,24,23],
[71,28,27,0],
[71,28,27,1],
[71,28,27,2],
[71,28,27,3],
[71,28,27,4],
[71,28,27,5],
[71,28,27,6],
[71,28,27,11],
[71,28,27,12],
[71,28,27,13],
[71,28,27,14],
[71,28,27,16],
[71,28,27,17],
[71,28,27,19],
[71,28,27,21],
[71,28,27,23],
[71,28,27,24],
[71,29,3,0],
[71,29,3,1],
[71,29,3,2],
[71,29,4,0],
[71,29,4,1],
[71,29,4,2],
[71,29,4,3],
[71,29,5,0],
[71,29,5,1],
[71,29,5,2],
[71,29,5,4],
[71,29,6,0],
[71,29,6,1],
[71,29,6,2],
[71,29,6,4],
[71,29,7,0],
[71,29,7,1],
[71,29,7,2],
[71,29,7,3],
[71,29,7,4],
[71,29,7,5],
[71,29,7,6],
[71,29,8,0],
[71,29,8,1],
[71,29,8,2],
[71,29,8,4],
[71,29,8,7],
[71,29,10,0],
[71,29,10,1],
[71,29,10,2],
[71,29,10,3],
[71,29,10,4],
[71,29,10,5],
[71,29,10,6],
[71,29,10,7],
[71,29,10,8],
[71,29,13,0],
[71,29,13,1],
[71,29,13,2],
[71,29,13,3],
[71,29,13,4],
[71,29,13,5],
[71,29,13,6],
[71,29,13,7],
[71,29,13,8],
[71,29,13,10],
[71,29,14,0],
[71,29,14,1],
[71,29,14,2],
[71,29,14,3],
[71,29,14,4],
[71,29,14,5],
[71,29,14,6],
[71,29,14,7],
[71,29,14,8],
[71,29,14,10],
[71,29,16,0],
[71,29,16,1],
[71,29,16,2],
[71,29,16,3],
[71,29,16,4],
[71,29,16,5],
[71,29,16,6],
[71,29,16,7],
[71,29,16,8],
[71,29,16,10],
[71,29,16,13],
[71,29,16,14],
[71,29,17,0],
[71,29,17,1],
[71,29,17,2],
[71,29,17,3],
[71,29,17,4],
[71,29,17,5],
[71,29,17,6],
[71,29,17,7],
[71,29,17,8],
[71,29,17,10],
[71,29,17,13],
[71,29,17,14],
[71,29,17,16],
[71,29,19,0],
[71,29,19,1],
[71,29,19,2],
[71,29,19,3],
[71,29,19,4],
[71,29,19,5],
[71,29,19,6],
[71,29,19,7],
[71,29,19,8],
[71,29,19,10],
[71,29,19,16],
[71,29,19,17],
[71,29,20,0],
[71,29,20,1],
[71,29,20,2],
[71,29,20,3],
[71,29,20,4],
[71,29,20,5],
[71,29,20,6],
[71,29,20,7],
[71,29,20,8],
[71,29,20,13],
[71,29,20,14],
[71,29,20,16],
[71,29,20,17],
[71,29,20,19],
[71,29,21,0],
[71,29,21,1],
[71,29,21,2],
[71,29,21,4],
[71,29,21,7],
[71,29,21,10],
[71,29,21,13],
[71,29,21,14],
[71,29,21,19],
[71,29,21,20],
[71,29,22,0],
[71,29,22,1],
[71,29,22,2],
[71,29,22,3],
[71,29,22,4],
[71,29,22,5],
[71,29,22,6],
[71,29,22,7],
[71,29,22,8],
[71,29,22,13],
[71,29,22,14],
[71,29,22,16],
[71,29,22,17],
[71,29,22,19],
[71,29,22,21],
[71,29,23,0],
[71,29,23,1],
[71,29,23,2],
[71,29,23,3],
[71,29,23,4],
[71,29,23,5],
[71,29,23,6],
[71,29,23,7],
[71,29,23,8],
[71,29,23,10],
[71,29,23,13],
[71,29,23,16],
[71,29,23,17],
[71,29,23,19],
[71,29,23,20],
[71,29,23,21],
[71,29,23,22],
[71,29,24,0],
[71,29,24,1],
[71,29,24,2],
[71,29,24,3],
[71,29,24,4],
[71,29,24,5],
[71,29,24,6],
[71,29,24,7],
[71,29,24,8],
[71,29,24,10],
[71,29,24,13],
[71,29,24,17],
[71,29,24,19],
[71,29,24,20],
[71,29,24,21],
[71,29,24,22],
[71,29,24,23],
[71,29,25,0],
[71,29,25,1],
[71,29,25,2],
[71,29,25,3],
[71,29,25,4],
[71,29,25,5],
[71,29,25,6],
[71,29,25,8],
[71,29,25,13],
[71,29,25,14],
[71,29,25,16],
[71,29,25,17],
[71,29,25,19],
[71,29,25,20],
[71,29,25,21],
[71,29,25,22],
[71,29,25,23],
[71,29,25,24],
[71,29,27,0],
[71,29,27,1],
[71,29,27,2],
[71,29,27,3],
[71,29,27,4],
[71,29,27,5],
[71,29,27,8],
[71,29,27,13],
[71,29,27,14],
[71,29,27,16],
[71,29,27,17],
[71,29,27,19],
[71,29,27,21],
[71,29,27,23],
[71,29,27,24],
[71,29,28,0],
[71,29,28,1],
[71,29,28,2],
[71,29,28,3],
[71,29,28,4],
[71,29,28,6],
[71,29,28,8],
[71,29,28,10],
[71,29,28,13],
[71,29,28,14],
[71,29,28,16],
[71,29,28,17],
[71,29,28,19],
[71,29,28,20],
[71,29,28,21],
[71,29,28,22],
[71,30,3,0],
[71,30,3,1],
[71,30,3,2],
[71,30,4,0],
[71,30,4,1],
[71,30,4,2],
[71,30,4,3],
[71,30,5,0],
[71,30,5,1],
[71,30,5,2],
[71,30,5,4],
[71,30,6,0],
[71,30,6,1],
[71,30,6,2],
[71,30,6,4],
[71,30,7,0],
[71,30,7,1],
[71,30,7,2],
[71,30,7,3],
[71,30,7,4],
[71,30,7,5],
[71,30,7,6],
[71,30,8,0],
[71,30,8,1],
[71,30,8,2],
[71,30,8,4],
[71,30,8,7],
[71,30,10,0],
[71,30,10,1],
[71,30,10,2],
[71,30,10,3],
[71,30,10,4],
[71,30,10,5],
[71,30,10,6],
[71,30,10,7],
[71,30,10,8],
[71,30,11,0],
[71,30,11,1],
[71,30,11,2],
[71,30,11,3],
[71,30,11,4],
[71,30,11,5],
[71,30,11,6],
[71,30,11,7],
[71,30,11,8],
[71,30,11,10],
[71,30,12,0],
[71,30,12,1],
[71,30,12,2],
[71,30,12,3],
[71,30,12,4],
[71,30,12,5],
[71,30,12,6],
[71,30,12,7],
[71,30,12,8],
[71,30,12,10],
[71,30,13,0],
[71,30,13,1],
[71,30,13,2],
[71,30,13,3],
[71,30,13,4],
[71,30,13,5],
[71,30,13,6],
[71,30,13,7],
[71,30,13,8],
[71,30,13,10],
[71,30,13,11],
[71,30,13,12],
[71,30,14,0],
[71,30,14,1],
[71,30,14,2],
[71,30,14,3],
[71,30,14,4],
[71,30,14,5],
[71,30,14,6],
[71,30,14,7],
[71,30,14,8],
[71,30,14,10],
[71,30,14,11],
[71,30,14,12],
[71,30,16,0],
[71,30,16,1],
[71,30,16,2],
[71,30,16,3],
[71,30,16,4],
[71,30,16,5],
[71,30,16,6],
[71,30,16,7],
[71,30,16,8],
[71,30,16,10],
[71,30,16,11],
[71,30,16,12],
[71,30,16,13],
[71,30,16,14],
[71,30,17,0],
[71,30,17,1],
[71,30,17,2],
[71,30,17,3],
[71,30,17,4],
[71,30,17,5],
[71,30,17,6],
[71,30,17,7],
[71,30,17,8],
[71,30,17,10],
[71,30,17,11],
[71,30,17,12],
[71,30,17,13],
[71,30,17,14],
[71,30,17,16],
[71,30,19,0],
[71,30,19,1],
[71,30,19,2],
[71,30,19,3],
[71,30,19,4],
[71,30,19,5],
[71,30,19,6],
[71,30,19,7],
[71,30,19,8],
[71,30,19,10],
[71,30,19,11],
[71,30,19,12],
[71,30,19,16],
[71,30,19,17],
[71,30,20,0],
[71,30,20,1],
[71,30,20,2],
[71,30,20,3],
[71,30,20,4],
[71,30,20,5],
[71,30,20,6],
[71,30,20,7],
[71,30,20,8],
[71,30,20,11],
[71,30,20,12],
[71,30,20,13],
[71,30,20,14],
[71,30,20,19],
[71,30,21,0],
[71,30,21,1],
[71,30,21,2],
[71,30,21,4],
[71,30,21,7],
[71,30,21,10],
[71,30,21,11],
[71,30,21,12],
[71,30,21,13],
[71,30,21,14],
[71,30,21,16],
[71,30,21,17],
[71,30,21,19],
[71,30,21,20],
[71,30,22,0],
[71,30,22,1],
[71,30,22,2],
[71,30,22,3],
[71,30,22,4],
[71,30,22,5],
[71,30,22,6],
[71,30,22,7],
[71,30,22,8],
[71,30,22,11],
[71,30,22,12],
[71,30,22,13],
[71,30,22,16],
[71,30,22,17],
[71,30,22,19],
[71,30,22,21],
[71,30,24,0],
[71,30,24,1],
[71,30,24,2],
[71,30,24,3],
[71,30,24,4],
[71,30,24,5],
[71,30,24,6],
[71,30,24,7],
[71,30,24,8],
[71,30,24,10],
[71,30,24,11],
[71,30,24,14],
[71,30,24,17],
[71,30,24,19],
[71,30,24,20],
[71,30,24,21],
[71,30,24,22],
[71,30,25,0],
[71,30,25,1],
[71,30,25,2],
[71,30,25,3],
[71,30,25,4],
[71,30,25,5],
[71,30,25,6],
[71,30,25,10],
[71,30,25,11],
[71,30,25,12],
[71,30,25,13],
[71,30,25,14],
[71,30,25,16],
[71,30,25,17],
[71,30,25,19],
[71,30,25,20],
[71,30,25,21],
[71,30,25,22],
[71,30,25,24],
[71,30,27,0],
[71,30,27,1],
[71,30,27,2],
[71,30,27,3],
[71,30,27,4],
[71,30,27,6],
[71,30,27,7],
[71,30,27,8],
[71,30,27,11],
[71,30,27,12],
[71,30,27,13],
[71,30,27,14],
[71,30,27,16],
[71,30,27,17],
[71,30,27,19],
[71,30,27,21],
[71,30,28,0],
[71,30,28,1],
[71,30,28,2],
[71,30,28,5],
[71,30,28,6],
[71,30,28,8],
[71,30,28,10],
[71,30,28,11],
[71,30,28,12],
[71,30,28,13],
[71,30,28,14],
[71,30,28,16],
[71,30,28,17],
[71,30,28,19],
[71,30,28,20],
[71,30,28,21],
[71,30,29,3],
[71,30,29,4],
[71,30,29,5],
[71,30,29,6],
[71,30,29,7],
[71,30,29,8],
[71,30,29,10],
[71,30,29,13],
[71,30,29,14],
[71,30,29,16],
[71,30,29,17],
[71,30,29,19],
[71,30,29,20],
[71,31,3,0],
[71,31,3,1],
[71,31,3,2],
[71,31,4,0],
[71,31,4,1],
[71,31,4,2],
[71,31,4,3],
[71,31,5,0],
[71,31,5,1],
[71,31,5,2],
[71,31,5,4],
[71,31,6,0],
[71,31,6,1],
[71,31,6,2],
[71,31,6,4],
[71,31,7,0],
[71,31,7,1],
[71,31,7,2],
[71,31,7,3],
[71,31,7,4],
[71,31,7,5],
[71,31,7,6],
[71,31,8,0],
[71,31,8,1],
[71,31,8,2],
[71,31,8,4],
[71,31,8,7],
[71,31,10,0],
[71,31,10,1],
[71,31,10,2],
[71,31,10,3],
[71,31,10,4],
[71,31,10,5],
[71,31,10,6],
[71,31,10,7],
[71,31,10,8],
[71,31,11,0],
[71,31,11,1],
[71,31,11,2],
[71,31,11,3],
[71,31,11,4],
[71,31,11,5],
[71,31,11,6],
[71,31,11,7],
[71,31,11,8],
[71,31,11,10],
[71,31,12,0],
[71,31,12,1],
[71,31,12,2],
[71,31,12,3],
[71,31,12,4],
[71,31,12,5],
[71,31,12,6],
[71,31,12,7],
[71,31,12,8],
[71,31,12,10],
[71,31,13,0],
[71,31,13,1],
[71,31,13,2],
[71,31,13,3],
[71,31,13,4],
[71,31,13,5],
[71,31,13,6],
[71,31,13,7],
[71,31,13,8],
[71,31,13,10],
[71,31,13,11],
[71,31,13,12],
[71,31,14,0],
[71,31,14,1],
[71,31,14,2],
[71,31,14,3],
[71,31,14,4],
[71,31,14,5],
[71,31,14,6],
[71,31,14,7],
[71,31,14,8],
[71,31,14,10],
[71,31,14,11],
[71,31,14,12],
[71,31,16,0],
[71,31,16,1],
[71,31,16,2],
[71,31,16,3],
[71,31,16,4],
[71,31,16,5],
[71,31,16,6],
[71,31,16,7],
[71,31,16,8],
[71,31,16,10],
[71,31,16,11],
[71,31,16,12],
[71,31,16,13],
[71,31,16,14],
[71,31,17,0],
[71,31,17,1],
[71,31,17,2],
[71,31,17,3],
[71,31,17,4],
[71,31,17,5],
[71,31,17,6],
[71,31,17,7],
[71,31,17,8],
[71,31,17,10],
[71,31,17,11],
[71,31,17,12],
[71,31,17,13],
[71,31,17,14],
[71,31,17,16],
[71,31,19,0],
[71,31,19,1],
[71,31,19,2],
[71,31,19,3],
[71,31,19,4],
[71,31,19,5],
[71,31,19,6],
[71,31,19,7],
[71,31,19,8],
[71,31,19,10],
[71,31,19,11],
[71,31,19,12],
[71,31,20,0],
[71,31,20,1],
[71,31,20,2],
[71,31,20,3],
[71,31,20,4],
[71,31,20,5],
[71,31,20,6],
[71,31,20,7],
[71,31,20,8],
[71,31,20,11],
[71,31,20,12],
[71,31,20,13],
[71,31,20,14],
[71,31,20,16],
[71,31,20,17],
[71,31,20,19],
[71,31,21,0],
[71,31,21,1],
[71,31,21,2],
[71,31,21,4],
[71,31,21,7],
[71,31,21,10],
[71,31,21,11],
[71,31,21,12],
[71,31,21,13],
[71,31,21,16],
[71,31,21,17],
[71,31,21,19],
[71,31,21,20],
[71,31,22,0],
[71,31,22,1],
[71,31,22,2],
[71,31,22,3],
[71,31,22,4],
[71,31,22,5],
[71,31,22,6],
[71,31,22,7],
[71,31,22,8],
[71,31,22,11],
[71,31,22,14],
[71,31,22,16],
[71,31,22,17],
[71,31,22,19],
[71,31,22,21],
[71,31,24,0],
[71,31,24,1],
[71,31,24,2],
[71,31,24,3],
[71,31,24,4],
[71,31,24,5],
[71,31,24,6],
[71,31,24,7],
[71,31,24,8],
[71,31,24,12],
[71,31,24,13],
[71,31,24,14],
[71,31,24,17],
[71,31,24,19],
[71,31,24,20],
[71,31,24,21],
[71,31,24,22],
[71,31,25,0],
[71,31,25,1],
[71,31,25,2],
[71,31,25,3],
[71,31,25,4],
[71,31,25,5],
[71,31,25,8],
[71,31,25,10],
[71,31,25,11],
[71,31,25,12],
[71,31,25,13],
[71,31,25,14],
[71,31,25,16],
[71,31,25,17],
[71,31,25,19],
[71,31,25,20],
[71,31,25,21],
[71,31,25,22],
[71,31,25,24],
[71,31,27,0],
[71,31,27,1],
[71,31,27,2],
[71,31,27,5],
[71,31,27,6],
[71,31,27,7],
[71,31,27,8],
[71,31,27,11],
[71,31,27,12],
[71,31,27,13],
[71,31,27,14],
[71,31,27,16],
[71,31,27,17],
[71,31,27,19],
[71,31,27,21],
[71,31,28,3],
[71,31,28,4],
[71,31,28,5],
[71,31,28,6],
[71,31,28,8],
[71,31,28,10],
[71,31,28,11],
[71,31,28,12],
[71,31,28,13],
[71,31,28,14],
[71,31,28,16],
[71,31,28,17],
[71,31,28,19],
[71,31,28,20],
[71,31,29,0],
[71,31,29,1],
[71,31,29,2],
[71,31,29,3],
[71,31,29,4],
[71,31,29,5],
[71,31,29,6],
[71,31,29,7],
[71,31,29,8],
[71,31,29,10],
[71,31,29,13],
[71,31,29,14],
[71,31,29,16],
[71,31,29,17],
[71,31,29,19],
[71,32,3,0],
[71,32,3,1],
[71,32,3,2],
[71,32,4,0],
[71,32,4,1],
[71,32,4,2],
[71,32,4,3],
[71,32,5,0],
[71,32,5,1],
[71,32,5,2],
[71,32,5,4],
[71,32,6,0],
[71,32,6,1],
[71,32,6,2],
[71,32,6,4],
[71,32,7,0],
[71,32,7,1],
[71,32,7,2],
[71,32,7,3],
[71,32,7,4],
[71,32,7,5],
[71,32,7,6],
[71,32,8,0],
[71,32,8,1],
[71,32,8,2],
[71,32,8,4],
[71,32,8,7],
[71,32,10,0],
[71,32,10,1],
[71,32,10,2],
[71,32,10,3],
[71,32,10,4],
[71,32,10,5],
[71,32,10,6],
[71,32,10,7],
[71,32,10,8],
[71,32,13,0],
[71,32,13,1],
[71,32,13,2],
[71,32,13,3],
[71,32,13,4],
[71,32,13,5],
[71,32,13,6],
[71,32,13,7],
[71,32,13,8],
[71,32,13,10],
[71,32,14,0],
[71,32,14,1],
[71,32,14,2],
[71,32,14,3],
[71,32,14,4],
[71,32,14,5],
[71,32,14,6],
[71,32,14,7],
[71,32,14,8],
[71,32,14,10],
[71,32,16,0],
[71,32,16,1],
[71,32,16,2],
[71,32,16,3],
[71,32,16,4],
[71,32,16,5],
[71,32,16,6],
[71,32,16,7],
[71,32,16,8],
[71,32,16,10],
[71,32,16,13],
[71,32,16,14],
[71,32,17,0],
[71,32,17,1],
[71,32,17,2],
[71,32,17,3],
[71,32,17,4],
[71,32,17,5],
[71,32,17,6],
[71,32,17,7],
[71,32,17,8],
[71,32,17,10],
[71,32,17,13],
[71,32,17,14],
[71,32,17,16],
[71,32,19,0],
[71,32,19,1],
[71,32,19,2],
[71,32,19,3],
[71,32,19,4],
[71,32,19,5],
[71,32,19,6],
[71,32,19,7],
[71,32,19,8],
[71,32,19,10],
[71,32,19,16],
[71,32,19,17],
[71,32,20,0],
[71,32,20,1],
[71,32,20,2],
[71,32,20,3],
[71,32,20,4],
[71,32,20,5],
[71,32,20,6],
[71,32,20,7],
[71,32,20,8],
[71,32,20,13],
[71,32,20,16],
[71,32,20,17],
[71,32,20,19],
[71,32,21,0],
[71,32,21,1],
[71,32,21,2],
[71,32,21,4],
[71,32,21,7],
[71,32,21,10],
[71,32,21,14],
[71,32,21,16],
[71,32,21,17],
[71,32,21,19],
[71,32,21,20],
[71,32,22,0],
[71,32,22,1],
[71,32,22,2],
[71,32,22,3],
[71,32,22,4],
[71,32,22,5],
[71,32,22,6],
[71,32,22,7],
[71,32,22,8],
[71,32,22,13],
[71,32,22,14],
[71,32,22,16],
[71,32,22,17],
[71,32,22,19],
[71,32,22,21],
[71,32,23,0],
[71,32,23,1],
[71,32,23,2],
[71,32,23,3],
[71,32,23,4],
[71,32,23,5],
[71,32,23,6],
[71,32,23,7],
[71,32,23,10],
[71,32,23,13],
[71,32,23,14],
[71,32,23,16],
[71,32,23,17],
[71,32,23,19],
[71,32,23,20],
[71,32,23,21],
[71,32,23,22],
[71,32,24,0],
[71,32,24,1],
[71,32,24,2],
[71,32,24,3],
[71,32,24,4],
[71,32,24,5],
[71,32,24,6],
[71,32,24,7],
[71,32,24,10],
[71,32,24,13],
[71,32,24,14],
[71,32,24,17],
[71,32,24,19],
[71,32,24,20],
[71,32,24,21],
[71,32,24,22],
[71,32,24,23],
[71,32,25,0],
[71,32,25,1],
[71,32,25,2],
[71,32,25,3],
[71,32,25,4],
[71,32,25,6],
[71,32,25,8],
[71,32,25,10],
[71,32,25,13],
[71,32,25,14],
[71,32,25,16],
[71,32,25,17],
[71,32,25,19],
[71,32,25,20],
[71,32,25,21],
[71,32,25,22],
[71,32,27,3],
[71,32,27,4],
[71,32,27,5],
[71,32,27,6],
[71,32,27,7],
[71,32,27,8],
[71,32,27,13],
[71,32,27,14],
[71,32,27,16],
[71,32,27,17],
[71,32,27,19],
[71,32,28,0],
[71,32,28,1],
[71,32,28,2],
[71,32,28,3],
[71,32,28,4],
[71,32,28,5],
[71,32,28,6],
[71,32,28,8],
[71,32,28,10],
[71,32,28,13],
[71,32,28,14],
[71,32,28,16],
[71,32,28,17],
[71,32,28,19],
[71,32,30,0],
[71,32,30,1],
[71,32,30,2],
[71,32,30,3],
[71,32,30,4],
[71,32,30,5],
[71,32,30,6],
[71,32,30,7],
[71,32,30,8],
[71,32,30,10],
[71,32,30,13],
[71,32,30,14],
[71,32,30,16],
[71,32,30,17],
[71,32,31,0],
[71,32,31,1],
[71,32,31,2],
[71,32,31,3],
[71,32,31,4],
[71,32,31,5],
[71,32,31,6],
[71,32,31,7],
[71,32,31,8],
[71,32,31,10],
[71,32,31,13],
[71,32,31,14],
[71,33,3,0],
[71,33,3,1],
[71,33,3,2],
[71,33,4,0],
[71,33,4,1],
[71,33,4,2],
[71,33,4,3],
[71,33,5,0],
[71,33,5,1],
[71,33,5,2],
[71,33,5,4],
[71,33,6,0],
[71,33,6,1],
[71,33,6,2],
[71,33,6,4],
[71,33,7,0],
[71,33,7,1],
[71,33,7,2],
[71,33,7,3],
[71,33,7,4],
[71,33,7,5],
[71,33,7,6],
[71,33,8,0],
[71,33,8,1],
[71,33,8,2],
[71,33,8,4],
[71,33,8,7],
[71,33,10,0],
[71,33,10,1],
[71,33,10,2],
[71,33,10,3],
[71,33,10,4],
[71,33,10,5],
[71,33,10,6],
[71,33,10,7],
[71,33,10,8],
[71,33,13,0],
[71,33,13,1],
[71,33,13,2],
[71,33,13,3],
[71,33,13,4],
[71,33,13,5],
[71,33,13,6],
[71,33,13,7],
[71,33,13,8],
[71,33,13,10],
[71,33,14,0],
[71,33,14,1],
[71,33,14,2],
[71,33,14,3],
[71,33,14,4],
[71,33,14,5],
[71,33,14,6],
[71,33,14,7],
[71,33,14,8],
[71,33,14,10],
[71,33,16,0],
[71,33,16,1],
[71,33,16,2],
[71,33,16,3],
[71,33,16,4],
[71,33,16,5],
[71,33,16,6],
[71,33,16,7],
[71,33,16,8],
[71,33,16,10],
[71,33,16,13],
[71,33,16,14],
[71,33,17,0],
[71,33,17,1],
[71,33,17,2],
[71,33,17,3],
[71,33,17,4],
[71,33,17,5],
[71,33,17,6],
[71,33,17,7],
[71,33,17,8],
[71,33,17,10],
[71,33,17,13],
[71,33,17,14],
[71,33,19,0],
[71,33,19,1],
[71,33,19,2],
[71,33,19,3],
[71,33,19,4],
[71,33,19,5],
[71,33,19,6],
[71,33,19,7],
[71,33,19,8],
[71,33,19,10],
[71,33,19,16],
[71,33,19,17],
[71,33,20,0],
[71,33,20,1],
[71,33,20,2],
[71,33,20,3],
[71,33,20,4],
[71,33,20,5],
[71,33,20,6],
[71,33,20,7],
[71,33,20,8],
[71,33,20,14],
[71,33,20,16],
[71,33,20,17],
[71,33,20,19],
[71,33,21,0],
[71,33,21,1],
[71,33,21,2],
[71,33,21,4],
[71,33,21,7],
[71,33,21,13],
[71,33,21,14],
[71,33,21,16],
[71,33,21,17],
[71,33,21,19],
[71,33,21,20],
[71,33,22,0],
[71,33,22,1],
[71,33,22,2],
[71,33,22,3],
[71,33,22,4],
[71,33,22,5],
[71,33,22,6],
[71,33,22,7],
[71,33,22,13],
[71,33,22,14],
[71,33,22,16],
[71,33,22,17],
[71,33,22,19],
[71,33,22,21],
[71,33,23,0],
[71,33,23,1],
[71,33,23,2],
[71,33,23,3],
[71,33,23,4],
[71,33,23,5],
[71,33,23,8],
[71,33,23,10],
[71,33,23,13],
[71,33,23,14],
[71,33,23,16],
[71,33,23,17],
[71,33,23,19],
[71,33,23,20],
[71,33,23,21],
[71,33,23,22],
[71,33,24,0],
[71,33,24,1],
[71,33,24,2],
[71,33,24,3],
[71,33,24,4],
[71,33,24,5],
[71,33,24,8],
[71,33,24,10],
[71,33,24,13],
[71,33,24,14],
[71,33,24,17],
[71,33,24,19],
[71,33,24,20],
[71,33,24,21],
[71,33,24,22],
[71,33,24,23],
[71,33,25,0],
[71,33,25,1],
[71,33,25,2],
[71,33,25,5],
[71,33,25,6],
[71,33,25,8],
[71,33,25,10],
[71,33,25,13],
[71,33,25,14],
[71,33,25,16],
[71,33,25,17],
[71,33,25,19],
[71,33,25,20],
[71,33,25,21],
[71,33,27,0],
[71,33,27,1],
[71,33,27,2],
[71,33,27,3],
[71,33,27,4],
[71,33,27,5],
[71,33,27,6],
[71,33,27,7],
[71,33,27,8],
[71,33,27,13],
[71,33,27,14],
[71,33,27,16],
[71,33,27,17],
[71,33,27,19],
[71,33,28,0],
[71,33,28,1],
[71,33,28,2],
[71,33,28,3],
[71,33,28,4],
[71,33,28,5],
[71,33,28,6],
[71,33,28,8],
[71,33,28,10],
[71,33,28,13],
[71,33,28,14],
[71,33,28,16],
[71,33,28,17],
[71,33,30,0],
[71,33,30,1],
[71,33,30,2],
[71,33,30,3],
[71,33,30,4],
[71,33,30,5],
[71,33,30,6],
[71,33,30,7],
[71,33,30,8],
[71,33,30,10],
[71,33,30,13],
[71,33,30,14],
[71,33,31,0],
[71,33,31,1],
[71,33,31,2],
[71,33,31,3],
[71,33,31,4],
[71,33,31,5],
[71,33,31,6],
[71,33,31,7],
[71,33,31,8],
[71,33,31,10],
[71,33,31,13],
[71,33,31,14],
[71,34,3,0],
[71,34,3,1],
[71,34,3,2],
[71,34,4,0],
[71,34,4,1],
[71,34,4,2],
[71,34,4,3],
[71,34,5,0],
[71,34,5,1],
[71,34,5,2],
[71,34,5,4],
[71,34,6,0],
[71,34,6,1],
[71,34,6,2],
[71,34,6,4],
[71,34,8,0],
[71,34,8,1],
[71,34,8,2],
[71,34,8,4],
[71,34,10,0],
[71,34,10,1],
[71,34,10,2],
[71,34,10,3],
[71,34,10,4],
[71,34,10,5],
[71,34,10,6],
[71,34,10,8],
[71,34,11,0],
[71,34,11,1],
[71,34,11,2],
[71,34,11,3],
[71,34,11,4],
[71,34,11,5],
[71,34,11,6],
[71,34,11,8],
[71,34,11,10],
[71,34,12,0],
[71,34,12,1],
[71,34,12,2],
[71,34,12,3],
[71,34,12,4],
[71,34,12,5],
[71,34,12,6],
[71,34,12,8],
[71,34,12,10],
[71,34,13,0],
[71,34,13,1],
[71,34,13,2],
[71,34,13,3],
[71,34,13,4],
[71,34,13,5],
[71,34,13,6],
[71,34,13,8],
[71,34,13,10],
[71,34,13,11],
[71,34,13,12],
[71,34,14,0],
[71,34,14,1],
[71,34,14,2],
[71,34,14,3],
[71,34,14,4],
[71,34,14,5],
[71,34,14,6],
[71,34,14,8],
[71,34,14,10],
[71,34,14,11],
[71,34,14,12],
[71,34,16,0],
[71,34,16,1],
[71,34,16,2],
[71,34,16,3],
[71,34,16,4],
[71,34,16,5],
[71,34,16,6],
[71,34,16,8],
[71,34,16,10],
[71,34,16,11],
[71,34,16,12],
[71,34,16,13],
[71,34,16,14],
[71,34,17,0],
[71,34,17,1],
[71,34,17,2],
[71,34,17,3],
[71,34,17,4],
[71,34,17,5],
[71,34,17,6],
[71,34,17,8],
[71,34,17,10],
[71,34,17,11],
[71,34,17,12],
[71,34,17,13],
[71,34,17,14],
[71,34,17,16],
[71,34,19,0],
[71,34,19,1],
[71,34,19,2],
[71,34,19,3],
[71,34,19,4],
[71,34,19,5],
[71,34,19,6],
[71,34,19,8],
[71,34,19,10],
[71,34,19,11],
[71,34,19,16],
[71,34,19,17],
[71,34,20,0],
[71,34,20,1],
[71,34,20,2],
[71,34,20,3],
[71,34,20,4],
[71,34,20,5],
[71,34,20,6],
[71,34,20,8],
[71,34,20,12],
[71,34,20,13],
[71,34,20,14],
[71,34,20,16],
[71,34,20,17],
[71,34,20,19],
[71,34,21,0],
[71,34,21,1],
[71,34,21,2],
[71,34,21,4],
[71,34,21,10],
[71,34,21,11],
[71,34,21,12],
[71,34,21,13],
[71,34,21,14],
[71,34,21,16],
[71,34,21,17],
[71,34,21,19],
[71,34,21,20],
[71,34,22,0],
[71,34,22,1],
[71,34,22,2],
[71,34,22,3],
[71,34,22,4],
[71,34,22,5],
[71,34,22,8],
[71,34,22,11],
[71,34,22,12],
[71,34,22,13],
[71,34,22,14],
[71,34,22,16],
[71,34,22,17],
[71,34,22,19],
[71,34,22,21],
[71,34,23,0],
[71,34,23,1],
[71,34,23,2],
[71,34,23,3],
[71,34,23,4],
[71,34,23,6],
[71,34,23,8],
[71,34,23,10],
[71,34,23,11],
[71,34,23,12],
[71,34,23,13],
[71,34,23,14],
[71,34,23,16],
[71,34,23,17],
[71,34,23,19],
[71,34,23,20],
[71,34,23,21],
[71,34,23,22],
[71,34,24,0],
[71,34,24,1],
[71,34,24,2],
[71,34,24,3],
[71,34,24,4],
[71,34,24,6],
[71,34,24,8],
[71,34,24,10],
[71,34,24,11],
[71,34,24,12],
[71,34,24,13],
[71,34,24,14],
[71,34,24,17],
[71,34,24,19],
[71,34,24,20],
[71,34,24,21],
[71,34,24,22],
[71,34,27,0],
[71,34,27,1],
[71,34,27,2],
[71,34,27,3],
[71,34,27,4],
[71,34,27,5],
[71,34,27,6],
[71,34,27,8],
[71,34,27,11],
[71,34,27,12],
[71,34,27,13],
[71,34,27,14],
[71,34,27,16],
[71,34,27,17],
[71,34,29,0],
[71,34,29,1],
[71,34,29,2],
[71,34,29,3],
[71,34,29,4],
[71,34,29,5],
[71,34,29,6],
[71,34,29,8],
[71,34,29,10],
[71,34,29,13],
[71,34,29,14],
[71,34,30,0],
[71,34,30,1],
[71,34,30,2],
[71,34,30,3],
[71,34,30,4],
[71,34,30,5],
[71,34,30,6],
[71,34,30,8],
[71,34,30,10],
[71,34,30,11],
[71,34,30,12],
[71,34,30,13],
[71,34,30,14],
[71,34,31,0],
[71,34,31,1],
[71,34,31,2],
[71,34,31,3],
[71,34,31,4],
[71,34,31,5],
[71,34,31,6],
[71,34,31,8],
[71,34,31,10],
[71,34,31,11],
[71,34,31,12],
[71,34,31,13],
[71,34,32,0],
[71,34,32,1],
[71,34,32,2],
[71,34,32,3],
[71,34,32,4],
[71,34,32,5],
[71,34,32,6],
[71,34,32,8],
[71,34,32,10],
[71,34,33,0],
[71,34,33,1],
[71,34,33,2],
[71,34,33,3],
[71,34,33,4],
[71,34,33,5],
[71,34,33,6],
[71,34,33,8],
[71,36,3,0],
[71,36,3,1],
[71,36,3,2],
[71,36,4,0],
[71,36,4,1],
[71,36,4,2],
[71,36,4,3],
[71,36,5,0],
[71,36,5,1],
[71,36,5,2],
[71,36,5,4],
[71,36,6,0],
[71,36,6,1],
[71,36,6,2],
[71,36,6,4],
[71,36,7,0],
[71,36,7,1],
[71,36,7,2],
[71,36,7,3],
[71,36,7,4],
[71,36,7,5],
[71,36,7,6],
[71,36,8,0],
[71,36,8,1],
[71,36,8,2],
[71,36,8,4],
[71,36,8,7],
[71,36,10,0],
[71,36,10,1],
[71,36,10,2],
[71,36,10,3],
[71,36,10,4],
[71,36,10,5],
[71,36,10,6],
[71,36,10,7],
[71,36,10,8],
[71,36,11,0],
[71,36,11,1],
[71,36,11,2],
[71,36,11,3],
[71,36,11,4],
[71,36,11,5],
[71,36,11,6],
[71,36,11,7],
[71,36,11,8],
[71,36,11,10],
[71,36,12,0],
[71,36,12,1],
[71,36,12,2],
[71,36,12,3],
[71,36,12,4],
[71,36,12,5],
[71,36,12,6],
[71,36,12,7],
[71,36,12,8],
[71,36,12,10],
[71,36,16,0],
[71,36,16,1],
[71,36,16,2],
[71,36,16,3],
[71,36,16,4],
[71,36,16,5],
[71,36,16,6],
[71,36,16,7],
[71,36,16,8],
[71,36,16,10],
[71,36,16,11],
[71,36,17,0],
[71,36,17,1],
[71,36,17,2],
[71,36,17,3],
[71,36,17,4],
[71,36,17,5],
[71,36,17,6],
[71,36,17,7],
[71,36,17,8],
[71,36,17,10],
[71,36,17,11],
[71,36,17,16],
[71,36,20,0],
[71,36,20,1],
[71,36,20,2],
[71,36,20,3],
[71,36,20,4],
[71,36,20,5],
[71,36,20,8],
[71,36,20,11],
[71,36,20,12],
[71,36,20,16],
[71,36,20,17],
[71,36,21,0],
[71,36,21,1],
[71,36,21,2],
[71,36,21,4],
[71,36,21,7],
[71,36,21,10],
[71,36,21,11],
[71,36,21,12],
[71,36,21,16],
[71,36,21,17],
[71,36,21,20],
[71,36,22,0],
[71,36,22,1],
[71,36,22,2],
[71,36,22,5],
[71,36,22,6],
[71,36,22,7],
[71,36,22,8],
[71,36,22,11],
[71,36,22,12],
[71,36,22,16],
[71,36,22,17],
[71,36,22,21],
[71,36,23,3],
[71,36,23,4],
[71,36,23,5],
[71,36,23,6],
[71,36,23,7],
[71,36,23,8],
[71,36,23,10],
[71,36,23,11],
[71,36,23,12],
[71,36,23,16],
[71,36,23,17],
[71,36,23,20],
[71,36,24,3],
[71,36,24,4],
[71,36,24,5],
[71,36,24,6],
[71,36,24,7],
[71,36,24,8],
[71,36,24,10],
[71,36,24,11],
[71,36,24,12],
[71,36,24,17],
[71,36,24,20],
[71,36,25,0],
[71,36,25,1],
[71,36,25,2],
[71,36,25,3],
[71,36,25,4],
[71,36,25,5],
[71,36,25,6],
[71,36,25,8],
[71,36,25,10],
[71,36,25,11],
[71,36,25,12],
[71,36,25,16],
[71,36,25,17],
[71,36,27,0],
[71,36,27,1],
[71,36,27,2],
[71,36,27,3],
[71,36,27,4],
[71,36,27,5],
[71,36,27,6],
[71,36,27,7],
[71,36,27,8],
[71,36,27,11],
[71,36,27,12],
[71,36,28,0],
[71,36,28,1],
[71,36,28,2],
[71,36,28,3],
[71,36,28,4],
[71,36,28,5],
[71,36,28,6],
[71,36,28,8],
[71,36,28,10],
[71,36,28,11],
[71,36,28,12],
[71,36,29,0],
[71,36,29,1],
[71,36,29,2],
[71,36,29,3],
[71,36,29,4],
[71,36,29,5],
[71,36,29,6],
[71,36,29,7],
[71,36,29,8],
[71,36,29,10],
[71,36,30,0],
[71,36,30,1],
[71,36,30,2],
[71,36,30,3],
[71,36,30,4],
[71,36,30,5],
[71,36,30,6],
[71,36,30,7],
[71,36,30,8],
[71,36,30,10],
[71,36,30,11],
[71,36,31,0],
[71,36,31,1],
[71,36,31,2],
[71,36,31,3],
[71,36,31,4],
[71,36,31,5],
[71,36,31,6],
[71,36,31,7],
[71,36,31,8],
[71,36,32,0],
[71,36,32,1],
[71,36,32,2],
[71,36,32,3],
[71,36,32,4],
[71,36,32,5],
[71,36,32,6],
[71,36,32,7],
[71,36,33,0],
[71,36,33,1],
[71,36,33,2],
[71,36,33,3],
[71,36,33,4],
[71,36,33,5],
[71,36,34,0],
[71,36,34,1],
[71,36,34,2],
[71,36,34,3],
[71,36,34,4],
[71,37,3,0],
[71,37,3,1],
[71,37,3,2],
[71,37,5,0],
[71,37,5,1],
[71,37,5,2],
[71,37,6,0],
[71,37,6,1],
[71,37,6,2],
[71,37,7,0],
[71,37,7,1],
[71,37,7,2],
[71,37,7,3],
[71,37,7,5],
[71,37,7,6],
[71,37,8,0],
[71,37,8,1],
[71,37,8,2],
[71,37,8,7],
[71,37,10,0],
[71,37,10,1],
[71,37,10,2],
[71,37,10,3],
[71,37,10,5],
[71,37,10,6],
[71,37,10,7],
[71,37,10,8],
[71,37,11,0],
[71,37,11,1],
[71,37,11,2],
[71,37,11,3],
[71,37,11,5],
[71,37,11,6],
[71,37,11,7],
[71,37,11,8],
[71,37,11,10],
[71,37,12,0],
[71,37,12,1],
[71,37,12,2],
[71,37,12,3],
[71,37,12,5],
[71,37,12,6],
[71,37,12,7],
[71,37,12,8],
[71,37,12,10],
[71,37,13,0],
[71,37,13,1],
[71,37,13,2],
[71,37,13,3],
[71,37,13,5],
[71,37,13,6],
[71,37,13,7],
[71,37,13,8],
[71,37,13,10],
[71,37,13,11],
[71,37,13,12],
[71,37,14,0],
[71,37,14,1],
[71,37,14,2],
[71,37,14,3],
[71,37,14,5],
[71,37,14,6],
[71,37,14,7],
[71,37,14,8],
[71,37,14,10],
[71,37,14,11],
[71,37,14,12],
[71,37,16,0],
[71,37,16,1],
[71,37,16,2],
[71,37,16,3],
[71,37,16,5],
[71,37,16,6],
[71,37,16,7],
[71,37,16,8],
[71,37,16,12],
[71,37,16,13],
[71,37,16,14],
[71,37,17,0],
[71,37,17,1],
[71,37,17,2],
[71,37,17,3],
[71,37,17,5],
[71,37,17,6],
[71,37,17,7],
[71,37,17,8],
[71,37,17,12],
[71,37,17,13],
[71,37,17,14],
[71,37,17,16],
[71,37,19,0],
[71,37,19,1],
[71,37,19,2],
[71,37,19,3],
[71,37,19,5],
[71,37,19,8],
[71,37,19,10],
[71,37,19,11],
[71,37,19,12],
[71,37,19,16],
[71,37,19,17],
[71,37,20,0],
[71,37,20,1],
[71,37,20,2],
[71,37,20,3],
[71,37,20,6],
[71,37,20,7],
[71,37,20,8],
[71,37,20,11],
[71,37,20,12],
[71,37,20,13],
[71,37,20,14],
[71,37,20,16],
[71,37,20,17],
[71,37,20,19],
[71,37,21,0],
[71,37,21,1],
[71,37,21,2],
[71,37,21,7],
[71,37,21,10],
[71,37,21,11],
[71,37,21,12],
[71,37,21,13],
[71,37,21,14],
[71,37,21,16],
[71,37,21,17],
[71,37,21,19],
[71,37,21,20],
[71,37,22,3],
[71,37,22,5],
[71,37,22,6],
[71,37,22,7],
[71,37,22,8],
[71,37,22,11],
[71,37,22,12],
[71,37,22,13],
[71,37,22,14],
[71,37,22,16],
[71,37,22,17],
[71,37,22,19],
[71,37,23,0],
[71,37,23,1],
[71,37,23,2],
[71,37,23,3],
[71,37,23,5],
[71,37,23,6],
[71,37,23,7],
[71,37,23,8],
[71,37,23,10],
[71,37,23,11],
[71,37,23,12],
[71,37,23,13],
[71,37,23,14],
[71,37,23,16],
[71,37,23,17],
[71,37,23,19],
[71,37,24,0],
[71,37,24,1],
[71,37,24,2],
[71,37,24,3],
[71,37,24,5],
[71,37,24,6],
[71,37,24,7],
[71,37,24,8],
[71,37,24,10],
[71,37,24,11],
[71,37,24,12],
[71,37,24,13],
[71,37,24,14],
[71,37,24,17],
[71,37,24,19],
[71,37,25,0],
[71,37,25,1],
[71,37,25,2],
[71,37,25,3],
[71,37,25,5],
[71,37,25,6],
[71,37,25,8],
[71,37,25,10],
[71,37,25,11],
[71,37,25,12],
[71,37,25,13],
[71,37,25,14],
[71,37,25,16],
[71,37,25,17],
[71,37,27,0],
[71,37,27,1],
[71,37,27,2],
[71,37,27,3],
[71,37,27,5],
[71,37,27,6],
[71,37,27,7],
[71,37,27,8],
[71,37,27,11],
[71,37,27,12],
[71,37,27,13],
[71,37,27,14],
[71,37,28,0],
[71,37,28,1],
[71,37,28,2],
[71,37,28,3],
[71,37,28,5],
[71,37,28,6],
[71,37,28,8],
[71,37,28,10],
[71,37,28,11],
[71,37,28,12],
[71,37,28,13],
[71,37,29,0],
[71,37,29,1],
[71,37,29,2],
[71,37,29,3],
[71,37,29,5],
[71,37,29,6],
[71,37,29,7],
[71,37,29,8],
[71,37,29,10],
[71,37,30,0],
[71,37,30,1],
[71,37,30,2],
[71,37,30,3],
[71,37,30,5],
[71,37,30,6],
[71,37,30,7],
[71,37,30,8],
[71,37,31,0],
[71,37,31,1],
[71,37,31,2],
[71,37,31,3],
[71,37,31,5],
[71,37,31,6],
[71,37,31,7],
[71,37,32,0],
[71,37,32,1],
[71,37,32,2],
[71,37,32,3],
[71,37,32,5],
[71,37,33,0],
[71,37,33,1],
[71,37,33,2],
[71,37,33,3],
[71,37,34,0],
[71,37,34,1],
[71,37,34,2],
[71,38,3,0],
[71,38,3,1],
[71,38,3,2],
[71,38,4,0],
[71,38,4,1],
[71,38,4,2],
[71,38,4,3],
[71,38,5,0],
[71,38,5,1],
[71,38,5,2],
[71,38,5,4],
[71,38,6,0],
[71,38,6,1],
[71,38,6,2],
[71,38,6,4],
[71,38,8,0],
[71,38,8,1],
[71,38,8,2],
[71,38,8,4],
[71,38,10,0],
[71,38,10,1],
[71,38,10,2],
[71,38,10,3],
[71,38,10,4],
[71,38,10,5],
[71,38,10,6],
[71,38,10,8],
[71,38,11,0],
[71,38,11,1],
[71,38,11,2],
[71,38,11,3],
[71,38,11,4],
[71,38,11,5],
[71,38,11,6],
[71,38,11,8],
[71,38,11,10],
[71,38,12,0],
[71,38,12,1],
[71,38,12,2],
[71,38,12,3],
[71,38,12,4],
[71,38,12,5],
[71,38,12,6],
[71,38,12,8],
[71,38,12,10],
[71,38,13,0],
[71,38,13,1],
[71,38,13,2],
[71,38,13,3],
[71,38,13,4],
[71,38,13,5],
[71,38,13,6],
[71,38,13,8],
[71,38,13,10],
[71,38,13,11],
[71,38,13,12],
[71,38,14,0],
[71,38,14,1],
[71,38,14,2],
[71,38,14,3],
[71,38,14,4],
[71,38,14,5],
[71,38,14,6],
[71,38,14,8],
[71,38,14,10],
[71,38,14,11],
[71,38,16,0],
[71,38,16,1],
[71,38,16,2],
[71,38,16,3],
[71,38,16,4],
[71,38,16,5],
[71,38,16,6],
[71,38,16,10],
[71,38,16,11],
[71,38,16,12],
[71,38,16,13],
[71,38,16,14],
[71,38,17,0],
[71,38,17,1],
[71,38,17,2],
[71,38,17,3],
[71,38,17,4],
[71,38,17,5],
[71,38,17,6],
[71,38,17,10],
[71,38,17,11],
[71,38,17,12],
[71,38,17,13],
[71,38,17,14],
[71,38,17,16],
[71,38,19,0],
[71,38,19,1],
[71,38,19,2],
[71,38,19,3],
[71,38,19,4],
[71,38,19,6],
[71,38,19,8],
[71,38,19,10],
[71,38,19,11],
[71,38,19,12],
[71,38,19,16],
[71,38,19,17],
[71,38,20,0],
[71,38,20,1],
[71,38,20,2],
[71,38,20,5],
[71,38,20,6],
[71,38,20,8],
[71,38,20,11],
[71,38,20,12],
[71,38,20,13],
[71,38,20,14],
[71,38,20,16],
[71,38,20,17],
[71,38,20,19],
[71,38,21,4],
[71,38,21,10],
[71,38,21,11],
[71,38,21,12],
[71,38,21,13],
[71,38,21,14],
[71,38,21,16],
[71,38,21,17],
[71,38,21,19],
[71,38,21,20],
[71,38,22,0],
[71,38,22,1],
[71,38,22,2],
[71,38,22,3],
[71,38,22,4],
[71,38,22,5],
[71,38,22,6],
[71,38,22,8],
[71,38,22,11],
[71,38,22,12],
[71,38,22,13],
[71,38,22,14],
[71,38,22,16],
[71,38,22,17],
[71,38,22,19],
[71,38,23,0],
[71,38,23,1],
[71,38,23,2],
[71,38,23,3],
[71,38,23,4],
[71,38,23,5],
[71,38,23,6],
[71,38,23,8],
[71,38,23,10],
[71,38,23,11],
[71,38,23,12],
[71,38,23,13],
[71,38,23,14],
[71,38,23,16],
[71,38,23,17],
[71,38,24,0],
[71,38,24,1],
[71,38,24,2],
[71,38,24,3],
[71,38,24,4],
[71,38,24,5],
[71,38,24,6],
[71,38,24,8],
[71,38,24,10],
[71,38,24,11],
[71,38,24,12],
[71,38,24,13],
[71,38,24,14],
[71,38,24,17],
[71,38,27,0],
[71,38,27,1],
[71,38,27,2],
[71,38,27,3],
[71,38,27,4],
[71,38,27,5],
[71,38,27,6],
[71,38,27,8],
[71,38,27,11],
[71,38,27,12],
[71,38,27,13],
[71,38,29,0],
[71,38,29,1],
[71,38,29,2],
[71,38,29,3],
[71,38,29,4],
[71,38,29,5],
[71,38,29,6],
[71,38,29,8],
[71,38,30,0],
[71,38,30,1],
[71,38,30,2],
[71,38,30,3],
[71,38,30,4],
[71,38,30,5],
[71,38,30,6],
[71,38,31,0],
[71,38,31,1],
[71,38,31,2],
[71,38,31,3],
[71,38,31,4],
[71,38,31,5],
[71,38,32,0],
[71,38,32,1],
[71,38,32,2],
[71,38,32,3],
[71,38,32,4],
[71,38,33,0],
[71,38,33,1],
[71,38,33,2],
[71,39,4,0],
[71,39,4,1],
[71,39,4,2],
[71,39,7,0],
[71,39,7,1],
[71,39,7,2],
[71,39,7,4],
[71,39,10,0],
[71,39,10,1],
[71,39,10,2],
[71,39,10,4],
[71,39,10,7],
[71,39,11,0],
[71,39,11,1],
[71,39,11,2],
[71,39,11,4],
[71,39,11,7],
[71,39,11,10],
[71,39,12,0],
[71,39,12,1],
[71,39,12,2],
[71,39,12,4],
[71,39,12,7],
[71,39,12,10],
[71,39,13,0],
[71,39,13,1],
[71,39,13,2],
[71,39,13,4],
[71,39,13,7],
[71,39,13,10],
[71,39,13,11],
[71,39,14,0],
[71,39,14,1],
[71,39,14,2],
[71,39,14,4],
[71,39,14,7],
[71,39,14,12],
[71,39,16,0],
[71,39,16,1],
[71,39,16,2],
[71,39,16,4],
[71,39,16,10],
[71,39,16,11],
[71,39,16,12],
[71,39,16,13],
[71,39,16,14],
[71,39,17,0],
[71,39,17,1],
[71,39,17,2],
[71,39,17,4],
[71,39,17,10],
[71,39,17,11],
[71,39,17,12],
[71,39,17,13],
[71,39,17,14],
[71,39,17,16],
[71,39,19,0],
[71,39,19,1],
[71,39,19,2],
[71,39,19,7],
[71,39,19,10],
[71,39,19,11],
[71,39,19,12],
[71,39,19,16],
[71,39,19,17],
[71,39,20,4],
[71,39,20,7],
[71,39,20,11],
[71,39,20,12],
[71,39,20,13],
[71,39,20,14],
[71,39,20,16],
[71,39,20,17],
[71,39,20,19],
[71,39,22,0],
[71,39,22,1],
[71,39,22,2],
[71,39,22,4],
[71,39,22,7],
[71,39,22,11],
[71,39,22,12],
[71,39,22,13],
[71,39,22,14],
[71,39,22,16],
[71,39,22,17],
[71,39,23,0],
[71,39,23,1],
[71,39,23,2],
[71,39,23,4],
[71,39,23,7],
[71,39,23,10],
[71,39,23,11],
[71,39,23,12],
[71,39,23,13],
[71,39,23,14],
[71,39,23,16],
[71,39,23,17],
[71,39,24,0],
[71,39,24,1],
[71,39,24,2],
[71,39,24,4],
[71,39,24,7],
[71,39,24,10],
[71,39,24,11],
[71,39,24,12],
[71,39,24,13],
[71,39,24,14],
[71,39,24,17],
[71,39,25,0],
[71,39,25,1],
[71,39,25,2],
[71,39,25,4],
[71,39,25,10],
[71,39,25,11],
[71,39,25,12],
[71,39,25,13],
[71,39,25,14],
[71,39,27,0],
[71,39,27,1],
[71,39,27,2],
[71,39,27,4],
[71,39,27,7],
[71,39,27,11],
[71,39,28,0],
[71,39,28,1],
[71,39,28,2],
[71,39,28,4],
[71,39,29,0],
[71,39,29,1],
[71,39,29,2],
[71,39,29,4],
[71,39,29,7],
[71,39,30,0],
[71,39,30,1],
[71,39,30,2],
[71,39,30,4],
[71,39,31,0],
[71,39,31,1],
[71,39,31,2],
[71,39,31,4],
[71,39,32,0],
[71,39,32,1],
[71,39,32,2],
[71,42,3,0],
[71,42,3,1],
[71,42,3,2],
[71,42,4,0],
[71,42,4,1],
[71,42,4,2],
[71,42,4,3],
[71,42,5,0],
[71,42,5,1],
[71,42,5,2],
[71,42,5,4],
[71,42,6,0],
[71,42,6,1],
[71,42,6,2],
[71,42,6,4],
[71,42,7,0],
[71,42,7,1],
[71,42,7,2],
[71,42,7,3],
[71,42,7,4],
[71,42,7,5],
[71,42,7,6],
[71,42,8,0],
[71,42,8,1],
[71,42,8,2],
[71,42,8,4],
[71,42,8,7],
[71,42,11,0],
[71,42,11,1],
[71,42,11,2],
[71,42,11,3],
[71,42,11,4],
[71,42,11,5],
[71,42,11,6],
[71,42,11,7],
[71,42,11,8],
[71,42,12,0],
[71,42,12,1],
[71,42,12,2],
[71,42,12,3],
[71,42,12,4],
[71,42,12,5],
[71,42,12,6],
[71,42,12,7],
[71,42,13,0],
[71,42,13,1],
[71,42,13,2],
[71,42,13,3],
[71,42,13,4],
[71,42,13,5],
[71,42,13,6],
[71,42,13,7],
[71,42,13,11],
[71,42,13,12],
[71,42,14,0],
[71,42,14,1],
[71,42,14,2],
[71,42,14,3],
[71,42,14,4],
[71,42,14,5],
[71,42,14,8],
[71,42,14,11],
[71,42,14,12],
[71,42,16,0],
[71,42,16,1],
[71,42,16,2],
[71,42,16,5],
[71,42,16,6],
[71,42,16,7],
[71,42,16,8],
[71,42,16,11],
[71,42,16,12],
[71,42,16,13],
[71,42,16,14],
[71,42,17,0],
[71,42,17,1],
[71,42,17,2],
[71,42,17,5],
[71,42,17,6],
[71,42,17,7],
[71,42,17,8],
[71,42,17,11],
[71,42,17,12],
[71,42,17,13],
[71,42,17,14],
[71,42,17,16],
[71,42,19,0],
[71,42,19,1],
[71,42,19,2],
[71,42,19,3],
[71,42,19,4],
[71,42,19,5],
[71,42,19,6],
[71,42,19,7],
[71,42,19,8],
[71,42,19,11],
[71,42,19,12],
[71,42,19,16],
[71,42,19,17],
[71,42,21,0],
[71,42,21,1],
[71,42,21,2],
[71,42,21,4],
[71,42,21,7],
[71,42,21,11],
[71,42,21,12],
[71,42,21,13],
[71,42,21,14],
[71,42,21,16],
[71,42,21,17],
[71,42,23,0],
[71,42,23,1],
[71,42,23,2],
[71,42,23,3],
[71,42,23,4],
[71,42,23,5],
[71,42,23,6],
[71,42,23,7],
[71,42,23,8],
[71,42,23,11],
[71,42,23,12],
[71,42,23,13],
[71,42,23,14],
[71,42,24,0],
[71,42,24,1],
[71,42,24,2],
[71,42,24,3],
[71,42,24,4],
[71,42,24,5],
[71,42,24,6],
[71,42,24,7],
[71,42,24,8],
[71,42,24,11],
[71,42,24,12],
[71,42,24,13],
[71,42,24,14],
[71,42,25,0],
[71,42,25,1],
[71,42,25,2],
[71,42,25,3],
[71,42,25,4],
[71,42,25,5],
[71,42,25,6],
[71,42,25,8],
[71,42,25,11],
[71,42,28,0],
[71,42,28,1],
[71,42,28,2],
[71,42,28,3],
[71,42,28,4],
[71,42,28,5],
[71,42,29,0],
[71,42,29,1],
[71,42,29,2],
[71,42,29,3],
[71,42,29,4],
[71,42,30,0],
[71,42,30,1],
[71,42,30,2],
[71,43,3,0],
[71,43,3,1],
[71,43,3,2],
[71,43,4,0],
[71,43,4,1],
[71,43,4,2],
[71,43,4,3],
[71,43,5,0],
[71,43,5,1],
[71,43,5,2],
[71,43,5,4],
[71,43,6,0],
[71,43,6,1],
[71,43,6,2],
[71,43,6,4],
[71,43,8,0],
[71,43,8,1],
[71,43,8,2],
[71,43,8,4],
[71,43,10,0],
[71,43,10,1],
[71,43,10,2],
[71,43,10,3],
[71,43,10,4],
[71,43,10,5],
[71,43,10,6],
[71,43,11,0],
[71,43,11,1],
[71,43,11,2],
[71,43,11,3],
[71,43,11,4],
[71,43,11,5],
[71,43,11,6],
[71,43,11,10],
[71,43,12,0],
[71,43,12,1],
[71,43,12,2],
[71,43,12,3],
[71,43,12,4],
[71,43,12,5],
[71,43,12,8],
[71,43,12,10],
[71,43,13,0],
[71,43,13,1],
[71,43,13,2],
[71,43,13,3],
[71,43,13,4],
[71,43,13,5],
[71,43,13,8],
[71,43,13,10],
[71,43,13,11],
[71,43,13,12],
[71,43,14,0],
[71,43,14,1],
[71,43,14,2],
[71,43,14,3],
[71,43,14,4],
[71,43,14,6],
[71,43,14,8],
[71,43,14,10],
[71,43,14,11],
[71,43,14,12],
[71,43,16,3],
[71,43,16,4],
[71,43,16,5],
[71,43,16,6],
[71,43,16,8],
[71,43,16,10],
[71,43,16,11],
[71,43,16,12],
[71,43,16,13],
[71,43,16,14],
[71,43,17,3],
[71,43,17,4],
[71,43,17,5],
[71,43,17,6],
[71,43,17,8],
[71,43,17,10],
[71,43,17,11],
[71,43,17,12],
[71,43,17,13],
[71,43,17,14],
[71,43,17,16],
[71,43,19,0],
[71,43,19,1],
[71,43,19,2],
[71,43,19,3],
[71,43,19,4],
[71,43,19,5],
[71,43,19,6],
[71,43,19,8],
[71,43,19,10],
[71,43,19,11],
[71,43,19,12],
[71,43,19,16],
[71,43,19,17],
[71,43,20,0],
[71,43,20,1],
[71,43,20,2],
[71,43,20,3],
[71,43,20,4],
[71,43,20,5],
[71,43,20,6],
[71,43,20,8],
[71,43,20,11],
[71,43,20,12],
[71,43,20,13],
[71,43,20,14],
[71,43,20,16],
[71,43,20,17],
[71,43,21,0],
[71,43,21,1],
[71,43,21,2],
[71,43,21,4],
[71,43,21,10],
[71,43,21,11],
[71,43,21,12],
[71,43,21,13],
[71,43,21,14],
[71,43,22,0],
[71,43,22,1],
[71,43,22,2],
[71,43,22,3],
[71,43,22,4],
[71,43,22,5],
[71,43,22,6],
[71,43,22,8],
[71,43,22,11],
[71,43,22,12],
[71,43,22,13],
[71,43,22,14],
[71,43,23,0],
[71,43,23,1],
[71,43,23,2],
[71,43,23,3],
[71,43,23,4],
[71,43,23,5],
[71,43,23,6],
[71,43,23,8],
[71,43,23,10],
[71,43,23,11],
[71,43,23,12],
[71,43,23,13],
[71,43,24,0],
[71,43,24,1],
[71,43,24,2],
[71,43,24,3],
[71,43,24,4],
[71,43,24,5],
[71,43,24,6],
[71,43,24,8],
[71,43,24,10],
[71,43,24,11],
[71,43,24,12],
[71,43,24,13],
[71,43,27,0],
[71,43,27,1],
[71,43,27,2],
[71,43,27,3],
[71,43,27,4],
[71,43,27,5],
[71,43,29,0],
[71,43,29,1],
[71,43,29,2],
[71,44,3,0],
[71,44,3,1],
[71,44,3,2],
[71,44,4,0],
[71,44,4,1],
[71,44,4,2],
[71,44,4,3],
[71,44,5,0],
[71,44,5,1],
[71,44,5,2],
[71,44,5,4],
[71,44,6,0],
[71,44,6,1],
[71,44,6,2],
[71,44,6,4],
[71,44,7,0],
[71,44,7,1],
[71,44,7,2],
[71,44,7,3],
[71,44,7,4],
[71,44,7,5],
[71,44,7,6],
[71,44,8,0],
[71,44,8,1],
[71,44,8,2],
[71,44,8,4],
[71,44,8,7],
[71,44,10,0],
[71,44,10,1],
[71,44,10,2],
[71,44,10,3],
[71,44,10,4],
[71,44,10,5],
[71,44,10,8],
[71,44,11,0],
[71,44,11,1],
[71,44,11,2],
[71,44,11,3],
[71,44,11,4],
[71,44,11,5],
[71,44,11,8],
[71,44,11,10],
[71,44,12,0],
[71,44,12,1],
[71,44,12,2],
[71,44,12,3],
[71,44,12,4],
[71,44,12,6],
[71,44,12,7],
[71,44,12,8],
[71,44,12,10],
[71,44,13,0],
[71,44,13,1],
[71,44,13,2],
[71,44,13,3],
[71,44,13,4],
[71,44,13,6],
[71,44,13,7],
[71,44,13,8],
[71,44,13,10],
[71,44,13,11],
[71,44,13,12],
[71,44,14,0],
[71,44,14,1],
[71,44,14,2],
[71,44,14,5],
[71,44,14,6],
[71,44,14,7],
[71,44,14,8],
[71,44,14,10],
[71,44,14,11],
[71,44,14,12],
[71,44,16,0],
[71,44,16,1],
[71,44,16,2],
[71,44,16,3],
[71,44,16,4],
[71,44,16,5],
[71,44,16,6],
[71,44,16,7],
[71,44,16,8],
[71,44,16,10],
[71,44,16,11],
[71,44,16,12],
[71,44,16,13],
[71,44,16,14],
[71,44,19,0],
[71,44,19,1],
[71,44,19,2],
[71,44,19,3],
[71,44,19,4],
[71,44,19,5],
[71,44,19,6],
[71,44,19,7],
[71,44,19,8],
[71,44,19,10],
[71,44,19,11],
[71,44,19,12],
[71,44,19,16],
[71,44,20,0],
[71,44,20,1],
[71,44,20,2],
[71,44,20,3],
[71,44,20,4],
[71,44,20,5],
[71,44,20,6],
[71,44,20,7],
[71,44,20,8],
[71,44,20,11],
[71,44,20,12],
[71,44,20,13],
[71,44,20,14],
[71,44,21,0],
[71,44,21,1],
[71,44,21,2],
[71,44,21,4],
[71,44,21,7],
[71,44,21,10],
[71,44,21,11],
[71,44,21,12],
[71,44,21,13],
[71,44,21,14],
[71,44,22,0],
[71,44,22,1],
[71,44,22,2],
[71,44,22,3],
[71,44,22,4],
[71,44,22,5],
[71,44,22,6],
[71,44,22,7],
[71,44,22,8],
[71,44,22,11],
[71,44,22,12],
[71,44,22,13],
[71,44,23,0],
[71,44,23,1],
[71,44,23,2],
[71,44,23,3],
[71,44,23,4],
[71,44,23,5],
[71,44,23,6],
[71,44,23,7],
[71,44,23,8],
[71,44,23,10],
[71,44,23,11],
[71,44,24,0],
[71,44,24,1],
[71,44,24,2],
[71,44,24,3],
[71,44,24,4],
[71,44,24,5],
[71,44,24,6],
[71,44,24,7],
[71,44,24,8],
[71,44,24,10],
[71,44,24,11],
[71,44,25,0],
[71,44,25,1],
[71,44,25,2],
[71,44,25,3],
[71,44,25,4],
[71,44,25,5],
[71,44,25,6],
[71,44,27,0],
[71,44,27,1],
[71,44,27,2],
[71,44,27,3],
[71,44,27,4],
[71,44,28,0],
[71,44,28,1],
[71,44,28,2],
[71,47,3,0],
[71,47,3,1],
[71,47,3,2],
[71,47,4,0],
[71,47,4,1],
[71,47,4,2],
[71,47,4,3],
[71,47,5,0],
[71,47,5,1],
[71,47,5,2],
[71,47,5,4],
[71,47,6,0],
[71,47,6,1],
[71,47,6,2],
[71,47,6,4],
[71,47,7,0],
[71,47,7,1],
[71,47,7,2],
[71,47,7,3],
[71,47,7,4],
[71,47,7,6],
[71,47,8,0],
[71,47,8,1],
[71,47,8,2],
[71,47,8,7],
[71,47,10,3],
[71,47,10,4],
[71,47,10,5],
[71,47,10,6],
[71,47,10,7],
[71,47,10,8],
[71,47,11,3],
[71,47,11,4],
[71,47,11,5],
[71,47,11,6],
[71,47,11,7],
[71,47,11,8],
[71,47,11,10],
[71,47,12,0],
[71,47,12,1],
[71,47,12,2],
[71,47,12,3],
[71,47,12,4],
[71,47,12,5],
[71,47,12,6],
[71,47,12,7],
[71,47,12,8],
[71,47,12,10],
[71,47,13,0],
[71,47,13,1],
[71,47,13,2],
[71,47,13,3],
[71,47,13,4],
[71,47,13,5],
[71,47,13,6],
[71,47,13,7],
[71,47,13,8],
[71,47,13,10],
[71,47,13,11],
[71,47,13,12],
[71,47,14,0],
[71,47,14,1],
[71,47,14,2],
[71,47,14,3],
[71,47,14,4],
[71,47,14,5],
[71,47,14,6],
[71,47,14,7],
[71,47,14,8],
[71,47,14,10],
[71,47,14,11],
[71,47,14,12],
[71,47,16,0],
[71,47,16,1],
[71,47,16,2],
[71,47,16,3],
[71,47,16,4],
[71,47,16,5],
[71,47,16,6],
[71,47,16,7],
[71,47,16,8],
[71,47,16,10],
[71,47,16,11],
[71,47,16,12],
[71,47,16,13],
[71,47,16,14],
[71,47,17,0],
[71,47,17,1],
[71,47,17,2],
[71,47,17,3],
[71,47,17,4],
[71,47,17,5],
[71,47,17,6],
[71,47,17,7],
[71,47,17,8],
[71,47,17,10],
[71,47,17,11],
[71,47,17,12],
[71,47,17,13],
[71,47,17,14],
[71,47,19,0],
[71,47,19,1],
[71,47,19,2],
[71,47,19,3],
[71,47,19,4],
[71,47,19,5],
[71,47,19,6],
[71,47,19,7],
[71,47,19,8],
[71,47,19,10],
[71,47,19,11],
[71,47,19,12],
[71,47,20,0],
[71,47,20,1],
[71,47,20,2],
[71,47,20,3],
[71,47,20,4],
[71,47,20,5],
[71,47,20,6],
[71,47,20,7],
[71,47,20,8],
[71,47,20,11],
[71,47,21,0],
[71,47,21,1],
[71,47,21,2],
[71,47,21,4],
[71,47,21,7],
[71,47,22,0],
[71,47,22,1],
[71,47,22,2],
[71,47,22,3],
[71,47,22,4],
[71,47,22,5],
[71,47,22,6],
[71,47,22,7],
[71,47,24,0],
[71,47,24,1],
[71,47,24,2],
[71,47,24,3],
[71,47,24,4],
[71,47,24,5],
[71,47,25,0],
[71,47,25,1],
[71,47,25,2],
[71,48,3,0],
[71,48,3,1],
[71,48,3,2],
[71,48,4,0],
[71,48,4,1],
[71,48,4,2],
[71,48,4,3],
[71,48,5,0],
[71,48,5,1],
[71,48,5,2],
[71,48,5,4],
[71,48,6,0],
[71,48,6,1],
[71,48,6,2],
[71,48,7,0],
[71,48,7,1],
[71,48,7,2],
[71,48,7,5],
[71,48,7,6],
[71,48,8,4],
[71,48,8,7],
[71,48,10,0],
[71,48,10,1],
[71,48,10,2],
[71,48,10,3],
[71,48,10,4],
[71,48,10,5],
[71,48,10,6],
[71,48,10,7],
[71,48,10,8],
[71,48,13,0],
[71,48,13,1],
[71,48,13,2],
[71,48,13,3],
[71,48,13,4],
[71,48,13,5],
[71,48,13,6],
[71,48,13,7],
[71,48,13,8],
[71,48,13,10],
[71,48,14,0],
[71,48,14,1],
[71,48,14,2],
[71,48,14,3],
[71,48,14,4],
[71,48,14,5],
[71,48,14,6],
[71,48,14,7],
[71,48,14,8],
[71,48,14,10],
[71,48,16,0],
[71,48,16,1],
[71,48,16,2],
[71,48,16,3],
[71,48,16,4],
[71,48,16,5],
[71,48,16,6],
[71,48,16,7],
[71,48,16,8],
[71,48,16,10],
[71,48,16,13],
[71,48,16,14],
[71,48,17,0],
[71,48,17,1],
[71,48,17,2],
[71,48,17,3],
[71,48,17,4],
[71,48,17,5],
[71,48,17,6],
[71,48,17,7],
[71,48,17,8],
[71,48,17,10],
[71,48,17,13],
[71,48,17,14],
[71,48,19,0],
[71,48,19,1],
[71,48,19,2],
[71,48,19,3],
[71,48,19,4],
[71,48,19,5],
[71,48,19,6],
[71,48,19,7],
[71,48,19,8],
[71,48,19,10],
[71,48,20,0],
[71,48,20,1],
[71,48,20,2],
[71,48,20,3],
[71,48,20,4],
[71,48,20,5],
[71,48,20,6],
[71,48,20,7],
[71,48,20,8],
[71,48,21,0],
[71,48,21,1],
[71,48,21,2],
[71,48,21,4],
[71,48,21,7],
[71,48,22,0],
[71,48,22,1],
[71,48,22,2],
[71,48,22,3],
[71,48,22,4],
[71,48,22,5],
[71,48,23,0],
[71,48,23,1],
[71,48,23,2],
[71,48,23,3],
[71,48,23,4],
[71,48,24,0],
[71,48,24,1],
[71,48,24,2],
[71,48,24,3],
[71,48,24,4],
[71,51,4,0],
[71,51,4,1],
[71,51,4,2],
[71,51,7,0],
[71,51,7,1],
[71,51,7,2],
[71,51,10,0],
[71,51,10,1],
[71,51,10,2],
[71,51,10,4],
[71,51,10,7],
[71,51,11,0],
[71,51,11,1],
[71,51,11,2],
[71,51,11,4],
[71,51,11,7],
[71,51,11,10],
[71,51,12,0],
[71,51,12,1],
[71,51,12,2],
[71,51,12,4],
[71,51,12,7],
[71,51,12,10],
[71,51,13,0],
[71,51,13,1],
[71,51,13,2],
[71,51,13,4],
[71,51,13,7],
[71,51,13,10],
[71,51,13,11],
[71,51,13,12],
[71,51,14,0],
[71,51,14,1],
[71,51,14,2],
[71,51,14,4],
[71,51,14,7],
[71,51,14,10],
[71,51,14,11],
[71,51,14,12],
[71,51,16,0],
[71,51,16,1],
[71,51,16,2],
[71,51,16,4],
[71,51,16,7],
[71,51,16,10],
[71,51,16,11],
[71,51,16,12],
[71,51,16,13],
[71,51,16,14],
[71,51,17,0],
[71,51,17,1],
[71,51,17,2],
[71,51,17,4],
[71,51,17,7],
[71,51,17,10],
[71,51,17,11],
[71,51,17,12],
[71,51,17,13],
[71,51,17,14],
[71,51,19,0],
[71,51,19,1],
[71,51,19,2],
[71,51,19,4],
[71,51,19,7],
[71,51,19,10],
[71,51,19,11],
[71,51,20,0],
[71,51,20,1],
[71,51,20,2],
[71,51,20,4],
[71,51,20,7],
[71,51,22,0],
[71,51,22,1],
[71,51,22,2],
[71,51,22,4],
[71,51,23,0],
[71,51,23,1],
[71,51,23,2],
[71,51,23,4],
[71,51,24,0],
[71,51,24,1],
[71,51,24,2],
[71,51,24,4],
[71,53,3,0],
[71,53,3,1],
[71,53,3,2],
[71,53,4,0],
[71,53,4,1],
[71,53,4,2],
[71,53,4,3],
[71,53,5,0],
[71,53,5,1],
[71,53,5,2],
[71,53,5,4],
[71,53,6,0],
[71,53,6,1],
[71,53,6,2],
[71,53,7,0],
[71,53,7,1],
[71,53,7,2],
[71,53,7,5],
[71,53,7,6],
[71,53,8,4],
[71,53,8,7],
[71,53,11,0],
[71,53,11,1],
[71,53,11,2],
[71,53,11,3],
[71,53,11,4],
[71,53,11,5],
[71,53,11,6],
[71,53,11,7],
[71,53,11,8],
[71,53,12,0],
[71,53,12,1],
[71,53,12,2],
[71,53,12,3],
[71,53,12,4],
[71,53,12,5],
[71,53,12,6],
[71,53,12,7],
[71,53,12,8],
[71,53,13,0],
[71,53,13,1],
[71,53,13,2],
[71,53,13,3],
[71,53,13,4],
[71,53,13,5],
[71,53,13,6],
[71,53,13,7],
[71,53,13,8],
[71,53,13,11],
[71,53,13,12],
[71,53,14,0],
[71,53,14,1],
[71,53,14,2],
[71,53,14,3],
[71,53,14,4],
[71,53,14,5],
[71,53,14,6],
[71,53,14,7],
[71,53,14,8],
[71,53,14,11],
[71,53,14,12],
[71,53,16,0],
[71,53,16,1],
[71,53,16,2],
[71,53,16,3],
[71,53,16,4],
[71,53,16,5],
[71,53,16,6],
[71,53,16,7],
[71,53,16,8],
[71,53,16,11],
[71,53,16,12],
[71,53,16,13],
[71,53,16,14],
[71,53,17,0],
[71,53,17,1],
[71,53,17,2],
[71,53,17,3],
[71,53,17,4],
[71,53,17,5],
[71,53,17,6],
[71,53,17,7],
[71,53,17,8],
[71,53,17,11],
[71,53,17,12],
[71,53,17,13],
[71,53,17,14],
[71,53,19,0],
[71,53,19,1],
[71,53,19,2],
[71,53,19,3],
[71,53,19,4],
[71,53,19,5],
[71,53,19,6],
[71,53,19,7],
[71,53,19,8],
[71,53,19,11],
[71,53,21,0],
[71,53,21,1],
[71,53,21,2],
[71,53,21,4],
[71,53,21,7],
[71,53,23,0],
[71,53,23,1],
[71,53,23,2],
[71,53,23,3],
[71,53,23,4],
[71,53,24,0],
[71,53,24,1],
[71,53,24,2],
[71,53,24,3],
[71,53,24,4],
[71,54,3,0],
[71,54,3,1],
[71,54,3,2],
[71,54,4,0],
[71,54,4,1],
[71,54,4,2],
[71,54,4,3],
[71,54,5,0],
[71,54,5,1],
[71,54,5,2],
[71,54,5,4],
[71,54,6,0],
[71,54,6,1],
[71,54,6,2],
[71,54,7,0],
[71,54,7,1],
[71,54,7,2],
[71,54,7,5],
[71,54,7,6],
[71,54,8,4],
[71,54,8,7],
[71,54,11,0],
[71,54,11,1],
[71,54,11,2],
[71,54,11,3],
[71,54,11,4],
[71,54,11,5],
[71,54,11,6],
[71,54,11,7],
[71,54,11,8],
[71,54,12,0],
[71,54,12,1],
[71,54,12,2],
[71,54,12,3],
[71,54,12,4],
[71,54,12,5],
[71,54,12,6],
[71,54,12,7],
[71,54,12,8],
[71,54,13,0],
[71,54,13,1],
[71,54,13,2],
[71,54,13,3],
[71,54,13,4],
[71,54,13,5],
[71,54,13,6],
[71,54,13,7],
[71,54,13,8],
[71,54,13,11],
[71,54,13,12],
[71,54,14,0],
[71,54,14,1],
[71,54,14,2],
[71,54,14,3],
[71,54,14,4],
[71,54,14,5],
[71,54,14,6],
[71,54,14,7],
[71,54,14,8],
[71,54,14,11],
[71,54,14,12],
[71,54,16,0],
[71,54,16,1],
[71,54,16,2],
[71,54,16,3],
[71,54,16,4],
[71,54,16,5],
[71,54,16,6],
[71,54,16,7],
[71,54,16,8],
[71,54,16,11],
[71,54,16,12],
[71,54,16,13],
[71,54,16,14],
[71,54,17,0],
[71,54,17,1],
[71,54,17,2],
[71,54,17,3],
[71,54,17,4],
[71,54,17,5],
[71,54,17,6],
[71,54,17,7],
[71,54,17,8],
[71,54,17,11],
[71,54,17,12],
[71,54,17,13],
[71,54,17,14],
[71,54,19,0],
[71,54,19,1],
[71,54,19,2],
[71,54,19,3],
[71,54,19,4],
[71,54,19,5],
[71,54,19,6],
[71,54,19,7],
[71,54,19,8],
[71,54,19,11],
[71,54,21,0],
[71,54,21,1],
[71,54,21,2],
[71,54,21,4],
[71,54,21,7],
[71,54,23,0],
[71,54,23,1],
[71,54,23,2],
[71,54,23,3],
[71,54,23,4],
[71,54,24,0],
[71,54,24,1],
[71,54,24,2],
[71,54,24,3],
[71,54,24,4],
[71,55,3,0],
[71,55,3,1],
[71,55,3,2],
[71,55,4,0],
[71,55,4,1],
[71,55,4,2],
[71,55,4,3],
[71,55,5,0],
[71,55,5,1],
[71,55,5,2],
[71,55,5,4],
[71,55,6,0],
[71,55,6,1],
[71,55,6,2],
[71,55,7,0],
[71,55,7,1],
[71,55,7,2],
[71,55,7,5],
[71,55,7,6],
[71,55,8,4],
[71,55,8,7],
[71,55,11,0],
[71,55,11,1],
[71,55,11,2],
[71,55,11,3],
[71,55,11,4],
[71,55,11,5],
[71,55,11,6],
[71,55,11,7],
[71,55,11,8],
[71,55,12,0],
[71,55,12,1],
[71,55,12,2],
[71,55,12,3],
[71,55,12,4],
[71,55,12,5],
[71,55,12,6],
[71,55,12,7],
[71,55,12,8],
[71,55,13,0],
[71,55,13,1],
[71,55,13,2],
[71,55,13,3],
[71,55,13,4],
[71,55,13,5],
[71,55,13,6],
[71,55,13,7],
[71,55,13,8],
[71,55,13,11],
[71,55,13,12],
[71,55,14,0],
[71,55,14,1],
[71,55,14,2],
[71,55,14,3],
[71,55,14,4],
[71,55,14,5],
[71,55,14,6],
[71,55,14,7],
[71,55,14,8],
[71,55,14,11],
[71,55,14,12],
[71,55,16,0],
[71,55,16,1],
[71,55,16,2],
[71,55,16,3],
[71,55,16,4],
[71,55,16,5],
[71,55,16,6],
[71,55,16,7],
[71,55,16,8],
[71,55,16,11],
[71,55,16,12],
[71,55,16,13],
[71,55,16,14],
[71,55,17,0],
[71,55,17,1],
[71,55,17,2],
[71,55,17,3],
[71,55,17,4],
[71,55,17,5],
[71,55,17,6],
[71,55,17,7],
[71,55,17,8],
[71,55,17,11],
[71,55,17,12],
[71,55,17,13],
[71,55,17,14],
[71,55,19,0],
[71,55,19,1],
[71,55,19,2],
[71,55,19,3],
[71,55,19,4],
[71,55,19,5],
[71,55,19,6],
[71,55,19,7],
[71,55,19,8],
[71,55,19,11],
[71,55,21,0],
[71,55,21,1],
[71,55,21,2],
[71,55,21,4],
[71,55,21,7],
[71,55,23,0],
[71,55,23,1],
[71,55,23,2],
[71,55,23,3],
[71,55,23,4],
[71,55,24,0],
[71,55,24,1],
[71,55,24,2],
[71,55,24,3],
[71,55,24,4],
[71,57,3,0],
[71,57,3,1],
[71,57,3,2],
[71,57,4,0],
[71,57,4,1],
[71,57,4,2],
[71,57,4,3],
[71,57,5,0],
[71,57,5,1],
[71,57,5,2],
[71,57,6,4],
[71,57,7,3],
[71,57,7,4],
[71,57,7,5],
[71,57,7,6],
[71,57,8,0],
[71,57,8,1],
[71,57,8,2],
[71,57,8,4],
[71,57,8,7],
[71,57,10,0],
[71,57,10,1],
[71,57,10,2],
[71,57,10,3],
[71,57,10,4],
[71,57,10,5],
[71,57,10,6],
[71,57,10,7],
[71,57,10,8],
[71,57,11,0],
[71,57,11,1],
[71,57,11,2],
[71,57,11,3],
[71,57,11,4],
[71,57,11,5],
[71,57,11,6],
[71,57,11,7],
[71,57,11,8],
[71,57,11,10],
[71,57,12,0],
[71,57,12,1],
[71,57,12,2],
[71,57,12,3],
[71,57,12,4],
[71,57,12,5],
[71,57,12,6],
[71,57,12,7],
[71,57,12,8],
[71,57,12,10],
[71,57,13,0],
[71,57,13,1],
[71,57,13,2],
[71,57,13,3],
[71,57,13,4],
[71,57,13,5],
[71,57,13,6],
[71,57,13,7],
[71,57,13,8],
[71,57,13,10],
[71,57,13,11],
[71,57,13,12],
[71,57,14,0],
[71,57,14,1],
[71,57,14,2],
[71,57,14,3],
[71,57,14,4],
[71,57,14,5],
[71,57,14,6],
[71,57,14,7],
[71,57,14,8],
[71,57,14,10],
[71,57,14,11],
[71,57,14,12],
[71,57,16,0],
[71,57,16,1],
[71,57,16,2],
[71,57,16,3],
[71,57,16,4],
[71,57,16,5],
[71,57,16,6],
[71,57,16,7],
[71,57,16,8],
[71,57,16,10],
[71,57,16,11],
[71,57,16,12],
[71,57,16,13],
[71,57,17,0],
[71,57,17,1],
[71,57,17,2],
[71,57,17,3],
[71,57,17,4],
[71,57,17,5],
[71,57,17,6],
[71,57,17,7],
[71,57,17,8],
[71,57,17,10],
[71,57,17,11],
[71,57,17,12],
[71,57,17,13],
[71,57,19,0],
[71,57,19,1],
[71,57,19,2],
[71,57,19,3],
[71,57,19,4],
[71,57,19,5],
[71,57,19,6],
[71,57,19,7],
[71,57,19,8],
[71,57,20,0],
[71,57,20,1],
[71,57,20,2],
[71,57,20,3],
[71,57,20,4],
[71,57,20,5],
[71,57,20,6],
[71,57,20,7],
[71,57,21,0],
[71,57,21,1],
[71,57,21,2],
[71,57,21,4],
[71,57,22,0],
[71,57,22,1],
[71,57,22,2],
[71,57,22,3],
[71,57,22,4],
[71,57,24,0],
[71,57,24,1],
[71,57,24,2],
[71,58,3,0],
[71,58,3,1],
[71,58,3,2],
[71,58,4,0],
[71,58,4,1],
[71,58,4,2],
[71,58,4,3],
[71,58,5,0],
[71,58,5,1],
[71,58,5,2],
[71,58,6,4],
[71,58,7,3],
[71,58,7,4],
[71,58,7,5],
[71,58,7,6],
[71,58,8,0],
[71,58,8,1],
[71,58,8,2],
[71,58,8,4],
[71,58,8,7],
[71,58,10,0],
[71,58,10,1],
[71,58,10,2],
[71,58,10,3],
[71,58,10,4],
[71,58,10,5],
[71,58,10,6],
[71,58,10,7],
[71,58,10,8],
[71,58,11,0],
[71,58,11,1],
[71,58,11,2],
[71,58,11,3],
[71,58,11,4],
[71,58,11,5],
[71,58,11,6],
[71,58,11,7],
[71,58,11,8],
[71,58,11,10],
[71,58,12,0],
[71,58,12,1],
[71,58,12,2],
[71,58,12,3],
[71,58,12,4],
[71,58,12,5],
[71,58,12,6],
[71,58,12,7],
[71,58,12,8],
[71,58,12,10],
[71,58,13,0],
[71,58,13,1],
[71,58,13,2],
[71,58,13,3],
[71,58,13,4],
[71,58,13,5],
[71,58,13,6],
[71,58,13,7],
[71,58,13,8],
[71,58,13,10],
[71,58,13,11],
[71,58,13,12],
[71,58,14,0],
[71,58,14,1],
[71,58,14,2],
[71,58,14,3],
[71,58,14,4],
[71,58,14,5],
[71,58,14,6],
[71,58,14,7],
[71,58,14,8],
[71,58,14,10],
[71,58,14,11],
[71,58,14,12],
[71,58,17,0],
[71,58,17,1],
[71,58,17,2],
[71,58,17,3],
[71,58,17,4],
[71,58,17,5],
[71,58,17,6],
[71,58,17,7],
[71,58,17,8],
[71,58,17,10],
[71,58,17,11],
[71,58,17,12],
[71,58,17,13],
[71,58,19,0],
[71,58,19,1],
[71,58,19,2],
[71,58,19,3],
[71,58,19,4],
[71,58,19,5],
[71,58,19,6],
[71,58,19,7],
[71,58,19,8],
[71,58,20,0],
[71,58,20,1],
[71,58,20,2],
[71,58,20,3],
[71,58,20,4],
[71,58,20,5],
[71,58,20,6],
[71,58,20,7],
[71,58,21,0],
[71,58,21,1],
[71,58,21,2],
[71,58,21,4],
[71,58,22,0],
[71,58,22,1],
[71,58,22,2],
[71,58,22,3],
[71,58,22,4],
[71,58,23,0],
[71,58,23,1],
[71,58,23,2],
[71,60,4,3],
[71,60,5,0],
[71,60,5,1],
[71,60,5,2],
[71,60,5,4],
[71,60,6,0],
[71,60,6,1],
[71,60,6,2],
[71,60,6,4],
[71,60,7,0],
[71,60,7,1],
[71,60,7,2],
[71,60,7,3],
[71,60,7,4],
[71,60,7,5],
[71,60,7,6],
[71,60,8,0],
[71,60,8,1],
[71,60,8,2],
[71,60,8,4],
[71,60,8,7],
[71,60,10,0],
[71,60,10,1],
[71,60,10,2],
[71,60,10,3],
[71,60,10,4],
[71,60,10,5],
[71,60,10,6],
[71,60,10,7],
[71,60,10,8],
[71,60,11,0],
[71,60,11,1],
[71,60,11,2],
[71,60,11,3],
[71,60,11,4],
[71,60,11,5],
[71,60,11,6],
[71,60,11,7],
[71,60,11,8],
[71,60,11,10],
[71,60,12,0],
[71,60,12,1],
[71,60,12,2],
[71,60,12,3],
[71,60,12,4],
[71,60,12,5],
[71,60,12,6],
[71,60,12,7],
[71,60,12,8],
[71,60,12,10],
[71,60,16,0],
[71,60,16,1],
[71,60,16,2],
[71,60,16,3],
[71,60,16,4],
[71,60,16,5],
[71,60,16,6],
[71,60,16,7],
[71,60,16,8],
[71,60,17,0],
[71,60,17,1],
[71,60,17,2],
[71,60,17,3],
[71,60,17,4],
[71,60,17,5],
[71,60,17,6],
[71,60,17,7],
[71,60,17,8],
[71,60,20,0],
[71,60,20,1],
[71,60,20,2],
[71,60,20,3],
[71,60,20,4],
[71,60,21,0],
[71,60,21,1],
[71,60,21,2],
[71,61,3,0],
[71,61,3,1],
[71,61,3,2],
[71,61,5,0],
[71,61,5,1],
[71,61,5,2],
[71,61,6,0],
[71,61,6,1],
[71,61,6,2],
[71,61,7,0],
[71,61,7,1],
[71,61,7,2],
[71,61,7,3],
[71,61,7,5],
[71,61,7,6],
[71,61,8,0],
[71,61,8,1],
[71,61,8,2],
[71,61,8,7],
[71,61,10,0],
[71,61,10,1],
[71,61,10,2],
[71,61,10,3],
[71,61,10,5],
[71,61,10,6],
[71,61,10,7],
[71,61,10,8],
[71,61,11,0],
[71,61,11,1],
[71,61,11,2],
[71,61,11,3],
[71,61,11,5],
[71,61,11,6],
[71,61,11,7],
[71,61,11,8],
[71,61,11,10],
[71,61,12,0],
[71,61,12,1],
[71,61,12,2],
[71,61,12,3],
[71,61,12,5],
[71,61,12,6],
[71,61,12,7],
[71,61,12,8],
[71,61,12,10],
[71,61,13,0],
[71,61,13,1],
[71,61,13,2],
[71,61,13,3],
[71,61,13,5],
[71,61,13,6],
[71,61,13,7],
[71,61,13,8],
[71,61,13,10],
[71,61,13,11],
[71,61,13,12],
[71,61,14,0],
[71,61,14,1],
[71,61,14,2],
[71,61,14,3],
[71,61,14,5],
[71,61,14,6],
[71,61,14,7],
[71,61,14,8],
[71,61,14,10],
[71,61,14,11],
[71,61,16,0],
[71,61,16,1],
[71,61,16,2],
[71,61,16,3],
[71,61,16,5],
[71,61,16,6],
[71,61,16,7],
[71,61,17,0],
[71,61,17,1],
[71,61,17,2],
[71,61,17,3],
[71,61,17,5],
[71,61,17,6],
[71,61,17,7],
[71,61,19,0],
[71,61,19,1],
[71,61,19,2],
[71,61,19,3],
[71,61,20,0],
[71,61,20,1],
[71,61,20,2],
[71,62,3,0],
[71,62,3,1],
[71,62,3,2],
[71,62,4,0],
[71,62,4,1],
[71,62,4,2],
[71,62,4,3],
[71,62,5,0],
[71,62,5,1],
[71,62,5,2],
[71,62,5,4],
[71,62,6,0],
[71,62,6,1],
[71,62,6,2],
[71,62,6,4],
[71,62,8,0],
[71,62,8,1],
[71,62,8,2],
[71,62,8,4],
[71,62,10,0],
[71,62,10,1],
[71,62,10,2],
[71,62,10,3],
[71,62,10,4],
[71,62,10,5],
[71,62,10,6],
[71,62,10,8],
[71,62,11,0],
[71,62,11,1],
[71,62,11,2],
[71,62,11,3],
[71,62,11,4],
[71,62,11,5],
[71,62,11,6],
[71,62,11,8],
[71,62,11,10],
[71,62,12,0],
[71,62,12,1],
[71,62,12,2],
[71,62,12,3],
[71,62,12,4],
[71,62,12,5],
[71,62,12,6],
[71,62,12,8],
[71,62,12,10],
[71,62,13,0],
[71,62,13,1],
[71,62,13,2],
[71,62,13,3],
[71,62,13,4],
[71,62,13,5],
[71,62,13,6],
[71,62,13,8],
[71,62,13,10],
[71,62,13,11],
[71,62,13,12],
[71,62,14,0],
[71,62,14,1],
[71,62,14,2],
[71,62,14,3],
[71,62,14,4],
[71,62,14,5],
[71,62,14,6],
[71,62,14,8],
[71,62,14,10],
[71,62,14,11],
[71,62,16,0],
[71,62,16,1],
[71,62,16,2],
[71,62,16,3],
[71,62,16,4],
[71,62,16,5],
[71,62,16,6],
[71,62,17,0],
[71,62,17,1],
[71,62,17,2],
[71,62,17,3],
[71,62,17,4],
[71,62,17,5],
[71,62,17,6],
[71,62,19,0],
[71,62,19,1],
[71,62,19,2],
[71,62,19,3],
[71,62,19,4],
[71,62,20,0],
[71,62,20,1],
[71,62,20,2],
[71,64,3,0],
[71,64,3,1],
[71,64,3,2],
[71,64,5,0],
[71,64,5,1],
[71,64,5,2],
[71,64,6,0],
[71,64,6,1],
[71,64,6,2],
[71,64,7,0],
[71,64,7,1],
[71,64,7,2],
[71,64,7,3],
[71,64,7,5],
[71,64,7,6],
[71,64,8,0],
[71,64,8,1],
[71,64,8,2],
[71,64,8,7],
[71,64,10,0],
[71,64,10,1],
[71,64,10,2],
[71,64,10,3],
[71,64,10,5],
[71,64,10,6],
[71,64,10,7],
[71,64,10,8],
[71,64,11,0],
[71,64,11,1],
[71,64,11,2],
[71,64,11,3],
[71,64,11,5],
[71,64,11,6],
[71,64,11,7],
[71,64,11,8],
[71,64,11,10],
[71,64,12,0],
[71,64,12,1],
[71,64,12,2],
[71,64,12,3],
[71,64,12,5],
[71,64,12,6],
[71,64,12,7],
[71,64,12,8],
[71,64,13,0],
[71,64,13,1],
[71,64,13,2],
[71,64,13,3],
[71,64,13,5],
[71,64,13,6],
[71,64,13,7],
[71,64,13,8],
[71,64,14,0],
[71,64,14,1],
[71,64,14,2],
[71,64,14,3],
[71,64,14,5],
[71,64,14,6],
[71,64,14,7],
[71,64,16,0],
[71,64,16,1],
[71,64,16,2],
[71,64,16,3],
[71,64,17,0],
[71,64,17,1],
[71,64,17,2],
[71,64,17,3],
[71,65,3,0],
[71,65,3,1],
[71,65,3,2],
[71,65,4,0],
[71,65,4,1],
[71,65,4,2],
[71,65,4,3],
[71,65,5,0],
[71,65,5,1],
[71,65,5,2],
[71,65,5,4],
[71,65,6,0],
[71,65,6,1],
[71,65,6,2],
[71,65,6,4],
[71,65,7,0],
[71,65,7,1],
[71,65,7,2],
[71,65,7,3],
[71,65,7,4],
[71,65,7,5],
[71,65,7,6],
[71,65,8,0],
[71,65,8,1],
[71,65,8,2],
[71,65,8,4],
[71,65,8,7],
[71,65,10,0],
[71,65,10,1],
[71,65,10,2],
[71,65,10,3],
[71,65,10,4],
[71,65,10,5],
[71,65,10,6],
[71,65,10,7],
[71,65,10,8],
[71,65,11,0],
[71,65,11,1],
[71,65,11,2],
[71,65,11,3],
[71,65,11,4],
[71,65,11,5],
[71,65,11,6],
[71,65,11,7],
[71,65,11,8],
[71,65,12,0],
[71,65,12,1],
[71,65,12,2],
[71,65,12,3],
[71,65,12,4],
[71,65,12,5],
[71,65,12,6],
[71,65,12,7],
[71,65,13,0],
[71,65,13,1],
[71,65,13,2],
[71,65,13,3],
[71,65,13,4],
[71,65,13,5],
[71,65,13,6],
[71,65,13,7],
[71,65,14,0],
[71,65,14,1],
[71,65,14,2],
[71,65,14,3],
[71,65,14,4],
[71,65,14,5],
[71,65,16,0],
[71,65,16,1],
[71,65,16,2],
[71,65,17,0],
[71,65,17,1],
[71,65,17,2],
[71,66,3,0],
[71,66,3,1],
[71,66,3,2],
[71,66,4,0],
[71,66,4,1],
[71,66,4,2],
[71,66,4,3],
[71,66,5,0],
[71,66,5,1],
[71,66,5,2],
[71,66,5,4],
[71,66,6,0],
[71,66,6,1],
[71,66,6,2],
[71,66,6,4],
[71,66,8,0],
[71,66,8,1],
[71,66,8,2],
[71,66,8,4],
[71,66,10,0],
[71,66,10,1],
[71,66,10,2],
[71,66,10,3],
[71,66,10,4],
[71,66,10,5],
[71,66,10,6],
[71,66,11,0],
[71,66,11,1],
[71,66,11,2],
[71,66,11,3],
[71,66,11,4],
[71,66,11,5],
[71,66,11,6],
[71,66,12,0],
[71,66,12,1],
[71,66,12,2],
[71,66,12,3],
[71,66,12,4],
[71,66,12,5],
[71,66,13,0],
[71,66,13,1],
[71,66,13,2],
[71,66,13,3],
[71,66,13,4],
[71,66,13,5],
[71,66,14,0],
[71,66,14,1],
[71,66,14,2],
[71,66,14,3],
[71,66,14,4],
[71,67,3,0],
[71,67,3,1],
[71,67,3,2],
[71,67,4,0],
[71,67,4,1],
[71,67,4,2],
[71,67,4,3],
[71,67,5,0],
[71,67,5,1],
[71,67,5,2],
[71,67,5,4],
[71,67,6,0],
[71,67,6,1],
[71,67,6,2],
[71,67,6,4],
[71,67,8,0],
[71,67,8,1],
[71,67,8,2],
[71,67,8,4],
[71,67,10,0],
[71,67,10,1],
[71,67,10,2],
[71,67,10,3],
[71,67,10,4],
[71,67,10,5],
[71,67,11,0],
[71,67,11,1],
[71,67,11,2],
[71,67,11,3],
[71,67,11,4],
[71,67,11,5],
[71,67,12,0],
[71,67,12,1],
[71,67,12,2],
[71,67,12,3],
[71,67,12,4],
[71,67,13,0],
[71,67,13,1],
[71,67,13,2],
[71,67,13,3],
[71,67,13,4],
[71,67,14,0],
[71,67,14,1],
[71,67,14,2],
[71,68,3,0],
[71,68,3,1],
[71,68,3,2],
[71,68,4,0],
[71,68,4,1],
[71,68,4,2],
[71,68,4,3],
[71,68,5,0],
[71,68,5,1],
[71,68,5,2],
[71,68,5,4],
[71,68,6,0],
[71,68,6,1],
[71,68,6,2],
[71,68,6,4],
[71,68,8,0],
[71,68,8,1],
[71,68,8,2],
[71,68,8,4],
[71,68,10,0],
[71,68,10,1],
[71,68,10,2],
[71,68,10,3],
[71,68,10,4],
[71,68,11,0],
[71,68,11,1],
[71,68,11,2],
[71,68,11,3],
[71,68,11,4],
[71,68,12,0],
[71,68,12,1],
[71,68,12,2],
[71,68,13,0],
[71,68,13,1],
[71,68,13,2],
[71,70,3,0],
[71,70,3,1],
[71,70,3,2],
[71,70,4,0],
[71,70,4,1],
[71,70,4,2],
[71,70,4,3],
[71,70,5,0],
[71,70,5,1],
[71,70,5,2],
[71,70,5,4],
[71,70,6,0],
[71,70,6,1],
[71,70,6,2],
[71,70,6,4],
[71,70,8,0],
[71,70,8,1],
[71,70,8,2],
[72,4,3,0],
[72,4,3,1],
[72,4,3,2],
[72,5,4,0],
[72,5,4,1],
[72,5,4,2],
[72,6,4,0],
[72,6,4,1],
[72,6,4,2],
[72,7,3,0],
[72,7,3,1],
[72,7,3,2],
[72,7,4,0],
[72,7,4,1],
[72,7,4,2],
[72,7,4,3],
[72,7,5,0],
[72,7,5,1],
[72,7,5,2],
[72,7,5,4],
[72,7,6,0],
[72,7,6,1],
[72,7,6,2],
[72,7,6,4],
[72,8,4,0],
[72,8,4,1],
[72,8,4,2],
[72,8,7,0],
[72,8,7,1],
[72,8,7,2],
[72,8,7,4],
[72,9,3,0],
[72,9,3,1],
[72,9,3,2],
[72,9,4,0],
[72,9,4,1],
[72,9,4,2],
[72,9,4,3],
[72,9,5,0],
[72,9,5,1],
[72,9,5,2],
[72,9,5,4],
[72,9,6,0],
[72,9,6,1],
[72,9,6,2],
[72,9,6,4],
[72,9,7,0],
[72,9,7,1],
[72,9,7,2],
[72,9,7,3],
[72,9,7,4],
[72,9,7,5],
[72,9,7,6],
[72,9,8,0],
[72,9,8,1],
[72,9,8,2],
[72,9,8,4],
[72,9,8,7],
[72,11,3,0],
[72,11,3,1],
[72,11,3,2],
[72,11,4,0],
[72,11,4,1],
[72,11,4,2],
[72,11,4,3],
[72,11,5,0],
[72,11,5,1],
[72,11,5,2],
[72,11,5,4],
[72,11,6,0],
[72,11,6,1],
[72,11,6,2],
[72,11,6,4],
[72,11,7,0],
[72,11,7,1],
[72,11,7,2],
[72,11,7,3],
[72,11,7,4],
[72,11,7,5],
[72,11,7,6],
[72,11,8,0],
[72,11,8,1],
[72,11,8,2],
[72,11,8,4],
[72,11,8,7],
[72,11,9,0],
[72,11,9,1],
[72,11,9,2],
[72,11,9,3],
[72,11,9,4],
[72,11,9,5],
[72,11,9,6],
[72,11,9,7],
[72,11,9,8],
[72,12,3,0],
[72,12,3,1],
[72,12,3,2],
[72,12,4,0],
[72,12,4,1],
[72,12,4,2],
[72,12,4,3],
[72,12,5,0],
[72,12,5,1],
[72,12,5,2],
[72,12,5,4],
[72,12,6,0],
[72,12,6,1],
[72,12,6,2],
[72,12,6,4],
[72,12,7,0],
[72,12,7,1],
[72,12,7,2],
[72,12,7,3],
[72,12,7,4],
[72,12,7,5],
[72,12,7,6],
[72,12,8,0],
[72,12,8,1],
[72,12,8,2],
[72,12,8,4],
[72,12,8,7],
[72,12,9,0],
[72,12,9,1],
[72,12,9,2],
[72,12,9,3],
[72,12,9,4],
[72,12,9,5],
[72,12,9,6],
[72,12,9,7],
[72,12,9,8],
[72,13,3,0],
[72,13,3,1],
[72,13,3,2],
[72,13,4,0],
[72,13,4,1],
[72,13,4,2],
[72,13,4,3],
[72,13,5,0],
[72,13,5,1],
[72,13,5,2],
[72,13,5,4],
[72,13,6,0],
[72,13,6,1],
[72,13,6,2],
[72,13,6,4],
[72,13,7,0],
[72,13,7,1],
[72,13,7,2],
[72,13,7,3],
[72,13,7,4],
[72,13,7,5],
[72,13,7,6],
[72,13,8,0],
[72,13,8,1],
[72,13,8,2],
[72,13,8,4],
[72,13,8,7],
[72,13,9,0],
[72,13,9,1],
[72,13,9,2],
[72,13,9,3],
[72,13,9,4],
[72,13,9,5],
[72,13,9,6],
[72,13,9,7],
[72,13,9,8],
[72,13,11,0],
[72,13,11,1],
[72,13,11,2],
[72,13,11,3],
[72,13,11,4],
[72,13,11,5],
[72,13,11,6],
[72,13,11,7],
[72,13,11,8],
[72,13,11,9],
[72,13,12,0],
[72,13,12,1],
[72,13,12,2],
[72,13,12,3],
[72,13,12,4],
[72,13,12,5],
[72,13,12,6],
[72,13,12,7],
[72,13,12,8],
[72,13,12,9],
[72,14,3,0],
[72,14,3,1],
[72,14,3,2],
[72,14,4,0],
[72,14,4,1],
[72,14,4,2],
[72,14,4,3],
[72,14,5,0],
[72,14,5,1],
[72,14,5,2],
[72,14,5,4],
[72,14,6,0],
[72,14,6,1],
[72,14,6,2],
[72,14,6,4],
[72,14,7,0],
[72,14,7,1],
[72,14,7,2],
[72,14,7,3],
[72,14,7,4],
[72,14,7,5],
[72,14,7,6],
[72,14,8,0],
[72,14,8,1],
[72,14,8,2],
[72,14,8,4],
[72,14,8,7],
[72,14,9,0],
[72,14,9,1],
[72,14,9,2],
[72,14,9,3],
[72,14,9,4],
[72,14,9,5],
[72,14,9,6],
[72,14,9,7],
[72,14,9,8],
[72,14,11,0],
[72,14,11,1],
[72,14,11,2],
[72,14,11,3],
[72,14,11,4],
[72,14,11,5],
[72,14,11,6],
[72,14,11,7],
[72,14,11,8],
[72,14,11,9],
[72,14,12,0],
[72,14,12,1],
[72,14,12,2],
[72,14,12,3],
[72,14,12,4],
[72,14,12,5],
[72,14,12,6],
[72,14,12,7],
[72,14,12,8],
[72,14,12,9],
[72,15,3,0],
[72,15,3,1],
[72,15,3,2],
[72,15,4,0],
[72,15,4,1],
[72,15,4,2],
[72,15,4,3],
[72,15,5,0],
[72,15,5,1],
[72,15,5,2],
[72,15,5,4],
[72,15,6,0],
[72,15,6,1],
[72,15,6,2],
[72,15,6,4],
[72,15,7,0],
[72,15,7,1],
[72,15,7,2],
[72,15,7,3],
[72,15,7,4],
[72,15,7,5],
[72,15,7,6],
[72,15,8,0],
[72,15,8,1],
[72,15,8,2],
[72,15,8,4],
[72,15,8,7],
[72,15,11,0],
[72,15,11,1],
[72,15,11,2],
[72,15,11,3],
[72,15,11,4],
[72,15,11,5],
[72,15,11,6],
[72,15,11,7],
[72,15,11,8],
[72,15,12,0],
[72,15,12,1],
[72,15,12,2],
[72,15,12,3],
[72,15,12,4],
[72,15,12,5],
[72,15,12,6],
[72,15,12,7],
[72,15,12,8],
[72,15,13,0],
[72,15,13,1],
[72,15,13,2],
[72,15,13,3],
[72,15,13,4],
[72,15,13,5],
[72,15,13,6],
[72,15,13,7],
[72,15,13,8],
[72,15,13,11],
[72,15,13,12],
[72,15,14,0],
[72,15,14,1],
[72,15,14,2],
[72,15,14,3],
[72,15,14,4],
[72,15,14,5],
[72,15,14,6],
[72,15,14,7],
[72,15,14,8],
[72,15,14,11],
[72,15,14,12],
[72,16,3,0],
[72,16,3,1],
[72,16,3,2],
[72,16,4,0],
[72,16,4,1],
[72,16,4,2],
[72,16,4,3],
[72,16,5,0],
[72,16,5,1],
[72,16,5,2],
[72,16,5,4],
[72,16,6,0],
[72,16,6,1],
[72,16,6,2],
[72,16,6,4],
[72,16,7,0],
[72,16,7,1],
[72,16,7,2],
[72,16,7,3],
[72,16,7,4],
[72,16,7,5],
[72,16,7,6],
[72,16,8,0],
[72,16,8,1],
[72,16,8,2],
[72,16,8,4],
[72,16,8,7],
[72,16,9,0],
[72,16,9,1],
[72,16,9,2],
[72,16,9,3],
[72,16,9,4],
[72,16,9,5],
[72,16,9,6],
[72,16,9,7],
[72,16,9,8],
[72,16,11,0],
[72,16,11,1],
[72,16,11,2],
[72,16,11,3],
[72,16,11,4],
[72,16,11,5],
[72,16,11,6],
[72,16,11,7],
[72,16,11,8],
[72,16,11,9],
[72,16,12,0],
[72,16,12,1],
[72,16,12,2],
[72,16,12,3],
[72,16,12,4],
[72,16,12,5],
[72,16,12,6],
[72,16,12,7],
[72,16,12,8],
[72,16,12,9],
[72,16,13,0],
[72,16,13,1],
[72,16,13,2],
[72,16,13,3],
[72,16,13,4],
[72,16,13,5],
[72,16,13,6],
[72,16,13,7],
[72,16,13,8],
[72,16,13,9],
[72,16,13,11],
[72,16,13,12],
[72,16,14,0],
[72,16,14,1],
[72,16,14,2],
[72,16,14,3],
[72,16,14,4],
[72,16,14,5],
[72,16,14,6],
[72,16,14,7],
[72,16,14,8],
[72,16,14,9],
[72,16,14,11],
[72,16,14,12],
[72,16,15,0],
[72,16,15,1],
[72,16,15,2],
[72,16,15,3],
[72,16,15,4],
[72,16,15,5],
[72,16,15,6],
[72,16,15,7],
[72,16,15,8],
[72,16,15,11],
[72,16,15,12],
[72,16,15,13],
[72,16,15,14],
[72,17,3,0],
[72,17,3,1],
[72,17,3,2],
[72,17,4,0],
[72,17,4,1],
[72,17,4,2],
[72,17,4,3],
[72,17,5,0],
[72,17,5,1],
[72,17,5,2],
[72,17,5,4],
[72,17,6,0],
[72,17,6,1],
[72,17,6,2],
[72,17,6,4],
[72,17,7,0],
[72,17,7,1],
[72,17,7,2],
[72,17,7,3],
[72,17,7,4],
[72,17,7,5],
[72,17,7,6],
[72,17,8,0],
[72,17,8,1],
[72,17,8,2],
[72,17,8,4],
[72,17,8,7],
[72,17,9,0],
[72,17,9,1],
[72,17,9,2],
[72,17,9,3],
[72,17,9,4],
[72,17,9,5],
[72,17,9,6],
[72,17,9,7],
[72,17,9,8],
[72,17,11,0],
[72,17,11,1],
[72,17,11,2],
[72,17,11,3],
[72,17,11,4],
[72,17,11,5],
[72,17,11,6],
[72,17,11,7],
[72,17,11,8],
[72,17,11,9],
[72,17,12,0],
[72,17,12,1],
[72,17,12,2],
[72,17,12,3],
[72,17,12,4],
[72,17,12,5],
[72,17,12,6],
[72,17,12,7],
[72,17,12,8],
[72,17,12,9],
[72,17,13,0],
[72,17,13,1],
[72,17,13,2],
[72,17,13,3],
[72,17,13,4],
[72,17,13,5],
[72,17,13,6],
[72,17,13,7],
[72,17,13,8],
[72,17,13,9],
[72,17,13,11],
[72,17,13,12],
[72,17,14,0],
[72,17,14,1],
[72,17,14,2],
[72,17,14,3],
[72,17,14,4],
[72,17,14,5],
[72,17,14,6],
[72,17,14,7],
[72,17,14,8],
[72,17,14,9],
[72,17,14,11],
[72,17,14,12],
[72,17,15,0],
[72,17,15,1],
[72,17,15,2],
[72,17,15,3],
[72,17,15,4],
[72,17,15,5],
[72,17,15,6],
[72,17,15,7],
[72,17,15,8],
[72,17,15,11],
[72,17,15,12],
[72,17,15,13],
[72,17,15,14],
[72,17,16,0],
[72,17,16,1],
[72,17,16,2],
[72,17,16,3],
[72,17,16,4],
[72,17,16,5],
[72,17,16,6],
[72,17,16,7],
[72,17,16,8],
[72,17,16,9],
[72,17,16,11],
[72,17,16,12],
[72,17,16,13],
[72,17,16,14],
[72,17,16,15],
[72,18,3,0],
[72,18,3,1],
[72,18,3,2],
[72,18,4,0],
[72,18,4,1],
[72,18,4,2],
[72,18,4,3],
[72,18,5,0],
[72,18,5,1],
[72,18,5,2],
[72,18,5,4],
[72,18,6,0],
[72,18,6,1],
[72,18,6,2],
[72,18,6,4],
[72,18,7,0],
[72,18,7,1],
[72,18,7,2],
[72,18,7,3],
[72,18,7,4],
[72,18,7,5],
[72,18,7,6],
[72,18,8,0],
[72,18,8,1],
[72,18,8,2],
[72,18,8,4],
[72,18,8,7],
[72,18,11,0],
[72,18,11,1],
[72,18,11,2],
[72,18,11,3],
[72,18,11,4],
[72,18,11,5],
[72,18,11,6],
[72,18,11,7],
[72,18,11,8],
[72,18,12,0],
[72,18,12,1],
[72,18,12,2],
[72,18,12,3],
[72,18,12,4],
[72,18,12,5],
[72,18,12,6],
[72,18,12,7],
[72,18,12,8],
[72,18,13,0],
[72,18,13,1],
[72,18,13,2],
[72,18,13,3],
[72,18,13,4],
[72,18,13,5],
[72,18,13,6],
[72,18,13,7],
[72,18,13,8],
[72,18,13,11],
[72,18,13,12],
[72,18,14,0],
[72,18,14,1],
[72,18,14,2],
[72,18,14,3],
[72,18,14,4],
[72,18,14,5],
[72,18,14,6],
[72,18,14,7],
[72,18,14,8],
[72,18,14,11],
[72,18,14,12],
[72,18,16,0],
[72,18,16,1],
[72,18,16,2],
[72,18,16,3],
[72,18,16,4],
[72,18,16,5],
[72,18,16,6],
[72,18,16,7],
[72,18,16,8],
[72,18,16,11],
[72,18,16,12],
[72,18,16,13],
[72,18,16,14],
[72,18,17,0],
[72,18,17,1],
[72,18,17,2],
[72,18,17,3],
[72,18,17,4],
[72,18,17,5],
[72,18,17,6],
[72,18,17,7],
[72,18,17,8],
[72,18,17,11],
[72,18,17,12],
[72,18,17,13],
[72,18,17,14],
[72,18,17,16],
[72,19,3,0],
[72,19,3,1],
[72,19,3,2],
[72,19,4,0],
[72,19,4,1],
[72,19,4,2],
[72,19,4,3],
[72,19,5,0],
[72,19,5,1],
[72,19,5,2],
[72,19,5,4],
[72,19,6,0],
[72,19,6,1],
[72,19,6,2],
[72,19,6,4],
[72,19,7,0],
[72,19,7,1],
[72,19,7,2],
[72,19,7,3],
[72,19,7,4],
[72,19,7,5],
[72,19,7,6],
[72,19,8,0],
[72,19,8,1],
[72,19,8,2],
[72,19,8,4],
[72,19,8,7],
[72,19,9,0],
[72,19,9,1],
[72,19,9,2],
[72,19,9,3],
[72,19,9,4],
[72,19,9,5],
[72,19,9,6],
[72,19,9,7],
[72,19,9,8],
[72,19,11,0],
[72,19,11,1],
[72,19,11,2],
[72,19,11,3],
[72,19,11,4],
[72,19,11,5],
[72,19,11,6],
[72,19,11,7],
[72,19,11,8],
[72,19,11,9],
[72,19,12,0],
[72,19,12,1],
[72,19,12,2],
[72,19,12,3],
[72,19,12,4],
[72,19,12,5],
[72,19,12,6],
[72,19,12,7],
[72,19,12,8],
[72,19,12,9],
[72,19,15,0],
[72,19,15,1],
[72,19,15,2],
[72,19,15,3],
[72,19,15,4],
[72,19,15,5],
[72,19,15,6],
[72,19,15,7],
[72,19,15,8],
[72,19,15,11],
[72,19,15,12],
[72,19,16,0],
[72,19,16,1],
[72,19,16,2],
[72,19,16,3],
[72,19,16,4],
[72,19,16,5],
[72,19,16,6],
[72,19,16,7],
[72,19,16,8],
[72,19,16,9],
[72,19,16,11],
[72,19,16,12],
[72,19,16,15],
[72,19,17,0],
[72,19,17,1],
[72,19,17,2],
[72,19,17,3],
[72,19,17,4],
[72,19,17,5],
[72,19,17,6],
[72,19,17,7],
[72,19,17,8],
[72,19,17,9],
[72,19,17,11],
[72,19,17,12],
[72,19,17,15],
[72,19,17,16],
[72,19,18,0],
[72,19,18,1],
[72,19,18,2],
[72,19,18,3],
[72,19,18,4],
[72,19,18,5],
[72,19,18,6],
[72,19,18,7],
[72,19,18,8],
[72,19,18,11],
[72,19,18,12],
[72,19,18,16],
[72,19,18,17],
[72,21,4,0],
[72,21,4,1],
[72,21,4,2],
[72,21,7,0],
[72,21,7,1],
[72,21,7,2],
[72,21,7,4],
[72,21,9,0],
[72,21,9,1],
[72,21,9,2],
[72,21,9,4],
[72,21,9,7],
[72,21,11,0],
[72,21,11,1],
[72,21,11,2],
[72,21,11,4],
[72,21,11,7],
[72,21,11,9],
[72,21,12,0],
[72,21,12,1],
[72,21,12,2],
[72,21,12,4],
[72,21,12,7],
[72,21,12,9],
[72,21,13,0],
[72,21,13,1],
[72,21,13,2],
[72,21,13,4],
[72,21,13,7],
[72,21,13,9],
[72,21,13,11],
[72,21,13,12],
[72,21,14,0],
[72,21,14,1],
[72,21,14,2],
[72,21,14,4],
[72,21,14,7],
[72,21,14,9],
[72,21,14,11],
[72,21,14,12],
[72,21,15,0],
[72,21,15,1],
[72,21,15,2],
[72,21,15,4],
[72,21,15,7],
[72,21,15,11],
[72,21,15,12],
[72,21,15,13],
[72,21,15,14],
[72,21,16,0],
[72,21,16,1],
[72,21,16,2],
[72,21,16,4],
[72,21,16,7],
[72,21,16,9],
[72,21,16,11],
[72,21,16,12],
[72,21,16,13],
[72,21,16,14],
[72,21,16,15],
[72,21,17,0],
[72,21,17,1],
[72,21,17,2],
[72,21,17,4],
[72,21,17,7],
[72,21,17,9],
[72,21,17,11],
[72,21,17,12],
[72,21,17,13],
[72,21,17,14],
[72,21,17,15],
[72,21,17,16],
[72,21,18,0],
[72,21,18,1],
[72,21,18,2],
[72,21,18,4],
[72,21,18,7],
[72,21,18,11],
[72,21,18,12],
[72,21,18,13],
[72,21,18,14],
[72,21,18,16],
[72,21,18,17],
[72,21,19,0],
[72,21,19,1],
[72,21,19,2],
[72,21,19,4],
[72,21,19,7],
[72,21,19,9],
[72,21,19,11],
[72,21,19,12],
[72,21,19,15],
[72,21,19,16],
[72,21,19,17],
[72,21,19,18],
[72,23,3,0],
[72,23,3,1],
[72,23,3,2],
[72,23,4,0],
[72,23,4,1],
[72,23,4,2],
[72,23,4,3],
[72,23,5,0],
[72,23,5,1],
[72,23,5,2],
[72,23,5,4],
[72,23,6,0],
[72,23,6,1],
[72,23,6,2],
[72,23,6,4],
[72,23,7,0],
[72,23,7,1],
[72,23,7,2],
[72,23,7,3],
[72,23,7,4],
[72,23,7,5],
[72,23,7,6],
[72,23,8,0],
[72,23,8,1],
[72,23,8,2],
[72,23,8,4],
[72,23,8,7],
[72,23,9,0],
[72,23,9,1],
[72,23,9,2],
[72,23,9,3],
[72,23,9,4],
[72,23,9,5],
[72,23,9,6],
[72,23,9,7],
[72,23,9,8],
[72,23,11,0],
[72,23,11,1],
[72,23,11,2],
[72,23,11,3],
[72,23,11,4],
[72,23,11,5],
[72,23,11,6],
[72,23,11,7],
[72,23,11,8],
[72,23,11,9],
[72,23,12,0],
[72,23,12,1],
[72,23,12,2],
[72,23,12,3],
[72,23,12,4],
[72,23,12,5],
[72,23,12,6],
[72,23,12,7],
[72,23,12,8],
[72,23,12,9],
[72,23,13,0],
[72,23,13,1],
[72,23,13,2],
[72,23,13,3],
[72,23,13,4],
[72,23,13,5],
[72,23,13,6],
[72,23,13,7],
[72,23,13,8],
[72,23,13,9],
[72,23,13,11],
[72,23,13,12],
[72,23,14,0],
[72,23,14,1],
[72,23,14,2],
[72,23,14,3],
[72,23,14,4],
[72,23,14,5],
[72,23,14,6],
[72,23,14,7],
[72,23,14,8],
[72,23,14,9],
[72,23,14,11],
[72,23,14,12],
[72,23,15,0],
[72,23,15,1],
[72,23,15,2],
[72,23,15,3],
[72,23,15,4],
[72,23,15,5],
[72,23,15,6],
[72,23,15,7],
[72,23,15,8],
[72,23,15,11],
[72,23,15,12],
[72,23,15,13],
[72,23,15,14],
[72,23,16,0],
[72,23,16,1],
[72,23,16,2],
[72,23,16,3],
[72,23,16,4],
[72,23,16,5],
[72,23,16,6],
[72,23,16,7],
[72,23,16,8],
[72,23,16,9],
[72,23,16,11],
[72,23,16,12],
[72,23,16,13],
[72,23,16,14],
[72,23,16,15],
[72,23,17,0],
[72,23,17,1],
[72,23,17,2],
[72,23,17,3],
[72,23,17,4],
[72,23,17,5],
[72,23,17,6],
[72,23,17,7],
[72,23,17,8],
[72,23,17,9],
[72,23,17,11],
[72,23,17,12],
[72,23,17,13],
[72,23,17,14],
[72,23,17,15],
[72,23,17,16],
[72,23,18,0],
[72,23,18,1],
[72,23,18,2],
[72,23,18,3],
[72,23,18,4],
[72,23,18,5],
[72,23,18,6],
[72,23,18,7],
[72,23,18,8],
[72,23,18,11],
[72,23,18,12],
[72,23,18,13],
[72,23,18,14],
[72,23,18,16],
[72,23,18,17],
[72,23,19,0],
[72,23,19,1],
[72,23,19,2],
[72,23,19,3],
[72,23,19,4],
[72,23,19,5],
[72,23,19,6],
[72,23,19,7],
[72,23,19,8],
[72,23,19,9],
[72,23,19,11],
[72,23,19,12],
[72,23,19,15],
[72,23,19,16],
[72,23,19,17],
[72,23,19,18],
[72,23,21,0],
[72,23,21,1],
[72,23,21,2],
[72,23,21,4],
[72,23,21,7],
[72,23,21,9],
[72,23,21,11],
[72,23,21,12],
[72,23,21,13],
[72,23,21,14],
[72,23,21,15],
[72,23,21,16],
[72,23,21,17],
[72,23,21,18],
[72,23,21,19],
[72,24,3,0],
[72,24,3,1],
[72,24,3,2],
[72,24,4,0],
[72,24,4,1],
[72,24,4,2],
[72,24,4,3],
[72,24,5,0],
[72,24,5,1],
[72,24,5,2],
[72,24,5,4],
[72,24,6,0],
[72,24,6,1],
[72,24,6,2],
[72,24,6,4],
[72,24,7,0],
[72,24,7,1],
[72,24,7,2],
[72,24,7,3],
[72,24,7,4],
[72,24,7,5],
[72,24,7,6],
[72,24,8,0],
[72,24,8,1],
[72,24,8,2],
[72,24,8,4],
[72,24,8,7],
[72,24,9,0],
[72,24,9,1],
[72,24,9,2],
[72,24,9,3],
[72,24,9,4],
[72,24,9,5],
[72,24,9,6],
[72,24,9,7],
[72,24,9,8],
[72,24,11,0],
[72,24,11,1],
[72,24,11,2],
[72,24,11,3],
[72,24,11,4],
[72,24,11,5],
[72,24,11,6],
[72,24,11,7],
[72,24,11,8],
[72,24,11,9],
[72,24,12,0],
[72,24,12,1],
[72,24,12,2],
[72,24,12,3],
[72,24,12,4],
[72,24,12,5],
[72,24,12,6],
[72,24,12,7],
[72,24,12,8],
[72,24,12,9],
[72,24,13,0],
[72,24,13,1],
[72,24,13,2],
[72,24,13,3],
[72,24,13,4],
[72,24,13,5],
[72,24,13,6],
[72,24,13,7],
[72,24,13,8],
[72,24,13,9],
[72,24,13,11],
[72,24,13,12],
[72,24,14,0],
[72,24,14,1],
[72,24,14,2],
[72,24,14,3],
[72,24,14,4],
[72,24,14,5],
[72,24,14,6],
[72,24,14,7],
[72,24,14,8],
[72,24,14,9],
[72,24,14,11],
[72,24,14,12],
[72,24,15,0],
[72,24,15,1],
[72,24,15,2],
[72,24,15,3],
[72,24,15,4],
[72,24,15,5],
[72,24,15,6],
[72,24,15,7],
[72,24,15,8],
[72,24,15,11],
[72,24,15,12],
[72,24,15,13],
[72,24,15,14],
[72,24,17,0],
[72,24,17,1],
[72,24,17,2],
[72,24,17,3],
[72,24,17,4],
[72,24,17,5],
[72,24,17,6],
[72,24,17,7],
[72,24,17,8],
[72,24,17,9],
[72,24,17,11],
[72,24,17,12],
[72,24,17,13],
[72,24,17,14],
[72,24,17,15],
[72,24,18,0],
[72,24,18,1],
[72,24,18,2],
[72,24,18,3],
[72,24,18,4],
[72,24,18,5],
[72,24,18,6],
[72,24,18,7],
[72,24,18,8],
[72,24,18,11],
[72,24,18,12],
[72,24,18,13],
[72,24,18,14],
[72,24,18,17],
[72,24,19,0],
[72,24,19,1],
[72,24,19,2],
[72,24,19,3],
[72,24,19,4],
[72,24,19,5],
[72,24,19,6],
[72,24,19,7],
[72,24,19,8],
[72,24,19,9],
[72,24,19,11],
[72,24,19,12],
[72,24,19,15],
[72,24,19,17],
[72,24,19,18],
[72,24,21,0],
[72,24,21,1],
[72,24,21,2],
[72,24,21,4],
[72,24,21,7],
[72,24,21,9],
[72,24,21,11],
[72,24,21,12],
[72,24,21,13],
[72,24,21,14],
[72,24,21,15],
[72,24,21,17],
[72,24,21,18],
[72,24,21,19],
[72,24,23,0],
[72,24,23,1],
[72,24,23,2],
[72,24,23,3],
[72,24,23,4],
[72,24,23,5],
[72,24,23,6],
[72,24,23,7],
[72,24,23,8],
[72,24,23,9],
[72,24,23,11],
[72,24,23,12],
[72,24,23,13],
[72,24,23,14],
[72,24,23,15],
[72,24,23,17],
[72,24,23,18],
[72,24,23,19],
[72,24,23,21],
[72,25,3,0],
[72,25,3,1],
[72,25,3,2],
[72,25,4,0],
[72,25,4,1],
[72,25,4,2],
[72,25,4,3],
[72,25,5,0],
[72,25,5,1],
[72,25,5,2],
[72,25,5,4],
[72,25,6,0],
[72,25,6,1],
[72,25,6,2],
[72,25,6,4],
[72,25,8,0],
[72,25,8,1],
[72,25,8,2],
[72,25,8,4],
[72,25,9,0],
[72,25,9,1],
[72,25,9,2],
[72,25,9,3],
[72,25,9,4],
[72,25,9,5],
[72,25,9,6],
[72,25,9,8],
[72,25,11,0],
[72,25,11,1],
[72,25,11,2],
[72,25,11,3],
[72,25,11,4],
[72,25,11,5],
[72,25,11,6],
[72,25,11,8],
[72,25,11,9],
[72,25,12,0],
[72,25,12,1],
[72,25,12,2],
[72,25,12,3],
[72,25,12,4],
[72,25,12,5],
[72,25,12,6],
[72,25,12,8],
[72,25,12,9],
[72,25,13,0],
[72,25,13,1],
[72,25,13,2],
[72,25,13,3],
[72,25,13,4],
[72,25,13,5],
[72,25,13,6],
[72,25,13,8],
[72,25,13,9],
[72,25,13,11],
[72,25,13,12],
[72,25,14,0],
[72,25,14,1],
[72,25,14,2],
[72,25,14,3],
[72,25,14,4],
[72,25,14,5],
[72,25,14,6],
[72,25,14,8],
[72,25,14,9],
[72,25,14,11],
[72,25,14,12],
[72,25,15,0],
[72,25,15,1],
[72,25,15,2],
[72,25,15,3],
[72,25,15,4],
[72,25,15,5],
[72,25,15,6],
[72,25,15,8],
[72,25,15,11],
[72,25,15,12],
[72,25,15,13],
[72,25,15,14],
[72,25,16,0],
[72,25,16,1],
[72,25,16,2],
[72,25,16,3],
[72,25,16,4],
[72,25,16,5],
[72,25,16,6],
[72,25,16,8],
[72,25,16,9],
[72,25,16,11],
[72,25,16,12],
[72,25,16,13],
[72,25,16,14],
[72,25,16,15],
[72,25,17,0],
[72,25,17,1],
[72,25,17,2],
[72,25,17,3],
[72,25,17,4],
[72,25,17,5],
[72,25,17,6],
[72,25,17,8],
[72,25,17,9],
[72,25,17,11],
[72,25,17,12],
[72,25,17,13],
[72,25,17,14],
[72,25,17,15],
[72,25,17,16],
[72,25,18,0],
[72,25,18,1],
[72,25,18,2],
[72,25,18,3],
[72,25,18,4],
[72,25,18,5],
[72,25,18,6],
[72,25,18,8],
[72,25,18,11],
[72,25,18,12],
[72,25,18,13],
[72,25,18,14],
[72,25,18,16],
[72,25,18,17],
[72,25,19,0],
[72,25,19,1],
[72,25,19,2],
[72,25,19,3],
[72,25,19,4],
[72,25,19,5],
[72,25,19,6],
[72,25,19,8],
[72,25,19,9],
[72,25,19,11],
[72,25,19,12],
[72,25,19,15],
[72,25,19,16],
[72,25,19,17],
[72,25,19,18],
[72,25,21,0],
[72,25,21,1],
[72,25,21,2],
[72,25,21,4],
[72,25,21,9],
[72,25,21,11],
[72,25,21,12],
[72,25,21,13],
[72,25,21,14],
[72,25,21,15],
[72,25,21,16],
[72,25,21,17],
[72,25,21,18],
[72,25,21,19],
[72,25,23,0],
[72,25,23,1],
[72,25,23,2],
[72,25,23,3],
[72,25,23,4],
[72,25,23,5],
[72,25,23,6],
[72,25,23,8],
[72,25,23,9],
[72,25,23,11],
[72,25,23,12],
[72,25,23,13],
[72,25,23,14],
[72,25,23,15],
[72,25,23,16],
[72,25,23,17],
[72,25,23,19],
[72,25,23,21],
[72,25,24,0],
[72,25,24,1],
[72,25,24,2],
[72,25,24,3],
[72,25,24,4],
[72,25,24,5],
[72,25,24,6],
[72,25,24,8],
[72,25,24,9],
[72,25,24,11],
[72,25,24,12],
[72,25,24,13],
[72,25,24,14],
[72,25,24,15],
[72,25,24,17],
[72,25,24,19],
[72,25,24,21],
[72,25,24,23],
[72,26,3,0],
[72,26,3,1],
[72,26,3,2],
[72,26,4,0],
[72,26,4,1],
[72,26,4,2],
[72,26,4,3],
[72,26,5,0],
[72,26,5,1],
[72,26,5,2],
[72,26,5,4],
[72,26,6,0],
[72,26,6,1],
[72,26,6,2],
[72,26,6,4],
[72,26,7,0],
[72,26,7,1],
[72,26,7,2],
[72,26,7,3],
[72,26,7,4],
[72,26,7,5],
[72,26,7,6],
[72,26,8,0],
[72,26,8,1],
[72,26,8,2],
[72,26,8,4],
[72,26,8,7],
[72,26,11,0],
[72,26,11,1],
[72,26,11,2],
[72,26,11,3],
[72,26,11,4],
[72,26,11,5],
[72,26,11,6],
[72,26,11,7],
[72,26,11,8],
[72,26,12,0],
[72,26,12,1],
[72,26,12,2],
[72,26,12,3],
[72,26,12,4],
[72,26,12,5],
[72,26,12,6],
[72,26,12,7],
[72,26,12,8],
[72,26,13,0],
[72,26,13,1],
[72,26,13,2],
[72,26,13,3],
[72,26,13,4],
[72,26,13,5],
[72,26,13,6],
[72,26,13,7],
[72,26,13,8],
[72,26,13,11],
[72,26,13,12],
[72,26,14,0],
[72,26,14,1],
[72,26,14,2],
[72,26,14,3],
[72,26,14,4],
[72,26,14,5],
[72,26,14,6],
[72,26,14,7],
[72,26,14,8],
[72,26,14,11],
[72,26,14,12],
[72,26,16,0],
[72,26,16,1],
[72,26,16,2],
[72,26,16,3],
[72,26,16,4],
[72,26,16,5],
[72,26,16,6],
[72,26,16,7],
[72,26,16,8],
[72,26,16,11],
[72,26,16,12],
[72,26,16,13],
[72,26,16,14],
[72,26,17,0],
[72,26,17,1],
[72,26,17,2],
[72,26,17,3],
[72,26,17,4],
[72,26,17,5],
[72,26,17,6],
[72,26,17,7],
[72,26,17,8],
[72,26,17,11],
[72,26,17,12],
[72,26,17,13],
[72,26,17,14],
[72,26,17,16],
[72,26,19,0],
[72,26,19,1],
[72,26,19,2],
[72,26,19,3],
[72,26,19,4],
[72,26,19,5],
[72,26,19,6],
[72,26,19,7],
[72,26,19,8],
[72,26,19,11],
[72,26,19,12],
[72,26,19,16],
[72,26,19,17],
[72,26,21,0],
[72,26,21,1],
[72,26,21,2],
[72,26,21,4],
[72,26,21,7],
[72,26,21,11],
[72,26,21,12],
[72,26,21,13],
[72,26,21,14],
[72,26,21,16],
[72,26,21,17],
[72,26,23,0],
[72,26,23,1],
[72,26,23,2],
[72,26,23,3],
[72,26,23,4],
[72,26,23,5],
[72,26,23,6],
[72,26,23,7],
[72,26,23,8],
[72,26,23,11],
[72,26,23,12],
[72,26,23,13],
[72,26,23,14],
[72,26,23,19],
[72,26,23,21],
[72,26,24,0],
[72,26,24,1],
[72,26,24,2],
[72,26,24,3],
[72,26,24,4],
[72,26,24,5],
[72,26,24,6],
[72,26,24,7],
[72,26,24,8],
[72,26,24,11],
[72,26,24,12],
[72,26,24,13],
[72,26,24,14],
[72,26,24,19],
[72,26,24,21],
[72,26,24,23],
[72,26,25,0],
[72,26,25,1],
[72,26,25,2],
[72,26,25,3],
[72,26,25,4],
[72,26,25,5],
[72,26,25,6],
[72,26,25,8],
[72,26,25,11],
[72,26,25,12],
[72,26,25,13],
[72,26,25,16],
[72,26,25,17],
[72,26,25,19],
[72,26,25,21],
[72,26,25,23],
[72,26,25,24],
[72,28,3,0],
[72,28,3,1],
[72,28,3,2],
[72,28,4,0],
[72,28,4,1],
[72,28,4,2],
[72,28,4,3],
[72,28,5,0],
[72,28,5,1],
[72,28,5,2],
[72,28,5,4],
[72,28,6,0],
[72,28,6,1],
[72,28,6,2],
[72,28,6,4],
[72,28,8,0],
[72,28,8,1],
[72,28,8,2],
[72,28,8,4],
[72,28,9,0],
[72,28,9,1],
[72,28,9,2],
[72,28,9,3],
[72,28,9,4],
[72,28,9,5],
[72,28,9,6],
[72,28,9,8],
[72,28,11,0],
[72,28,11,1],
[72,28,11,2],
[72,28,11,3],
[72,28,11,4],
[72,28,11,5],
[72,28,11,6],
[72,28,11,8],
[72,28,11,9],
[72,28,12,0],
[72,28,12,1],
[72,28,12,2],
[72,28,12,3],
[72,28,12,4],
[72,28,12,5],
[72,28,12,6],
[72,28,12,8],
[72,28,12,9],
[72,28,13,0],
[72,28,13,1],
[72,28,13,2],
[72,28,13,3],
[72,28,13,4],
[72,28,13,5],
[72,28,13,6],
[72,28,13,8],
[72,28,13,9],
[72,28,13,11],
[72,28,13,12],
[72,28,14,0],
[72,28,14,1],
[72,28,14,2],
[72,28,14,3],
[72,28,14,4],
[72,28,14,5],
[72,28,14,6],
[72,28,14,8],
[72,28,14,9],
[72,28,14,11],
[72,28,14,12],
[72,28,15,0],
[72,28,15,1],
[72,28,15,2],
[72,28,15,3],
[72,28,15,4],
[72,28,15,5],
[72,28,15,6],
[72,28,15,8],
[72,28,15,11],
[72,28,15,12],
[72,28,15,13],
[72,28,15,14],
[72,28,16,0],
[72,28,16,1],
[72,28,16,2],
[72,28,16,3],
[72,28,16,4],
[72,28,16,5],
[72,28,16,6],
[72,28,16,8],
[72,28,16,9],
[72,28,16,11],
[72,28,16,12],
[72,28,16,13],
[72,28,16,14],
[72,28,16,15],
[72,28,17,0],
[72,28,17,1],
[72,28,17,2],
[72,28,17,3],
[72,28,17,4],
[72,28,17,5],
[72,28,17,6],
[72,28,17,8],
[72,28,17,9],
[72,28,17,11],
[72,28,17,12],
[72,28,17,13],
[72,28,17,14],
[72,28,17,15],
[72,28,17,16],
[72,28,18,0],
[72,28,18,1],
[72,28,18,2],
[72,28,18,3],
[72,28,18,4],
[72,28,18,5],
[72,28,18,6],
[72,28,18,8],
[72,28,18,11],
[72,28,18,12],
[72,28,18,13],
[72,28,18,14],
[72,28,18,16],
[72,28,18,17],
[72,28,19,0],
[72,28,19,1],
[72,28,19,2],
[72,28,19,3],
[72,28,19,4],
[72,28,19,5],
[72,28,19,6],
[72,28,19,8],
[72,28,19,9],
[72,28,19,11],
[72,28,19,12],
[72,28,19,15],
[72,28,19,16],
[72,28,19,17],
[72,28,19,18],
[72,28,21,0],
[72,28,21,1],
[72,28,21,2],
[72,28,21,4],
[72,28,21,9],
[72,28,21,11],
[72,28,21,12],
[72,28,21,13],
[72,28,21,14],
[72,28,21,15],
[72,28,21,18],
[72,28,21,19],
[72,28,23,0],
[72,28,23,1],
[72,28,23,2],
[72,28,23,3],
[72,28,23,4],
[72,28,23,5],
[72,28,23,6],
[72,28,23,8],
[72,28,23,9],
[72,28,23,11],
[72,28,23,12],
[72,28,23,13],
[72,28,23,15],
[72,28,23,16],
[72,28,23,17],
[72,28,23,18],
[72,28,23,19],
[72,28,23,21],
[72,28,24,0],
[72,28,24,1],
[72,28,24,2],
[72,28,24,3],
[72,28,24,4],
[72,28,24,5],
[72,28,24,6],
[72,28,24,8],
[72,28,24,9],
[72,28,24,11],
[72,28,24,12],
[72,28,24,13],
[72,28,24,15],
[72,28,24,17],
[72,28,24,18],
[72,28,24,19],
[72,28,24,21],
[72,28,24,23],
[72,28,26,0],
[72,28,26,1],
[72,28,26,2],
[72,28,26,3],
[72,28,26,4],
[72,28,26,5],
[72,28,26,6],
[72,28,26,11],
[72,28,26,12],
[72,28,26,13],
[72,28,26,14],
[72,28,26,16],
[72,28,26,17],
[72,28,26,19],
[72,28,26,21],
[72,28,26,23],
[72,28,26,24],
[72,29,3,0],
[72,29,3,1],
[72,29,3,2],
[72,29,4,0],
[72,29,4,1],
[72,29,4,2],
[72,29,4,3],
[72,29,5,0],
[72,29,5,1],
[72,29,5,2],
[72,29,5,4],
[72,29,6,0],
[72,29,6,1],
[72,29,6,2],
[72,29,6,4],
[72,29,7,0],
[72,29,7,1],
[72,29,7,2],
[72,29,7,3],
[72,29,7,4],
[72,29,7,5],
[72,29,7,6],
[72,29,8,0],
[72,29,8,1],
[72,29,8,2],
[72,29,8,4],
[72,29,8,7],
[72,29,9,0],
[72,29,9,1],
[72,29,9,2],
[72,29,9,3],
[72,29,9,4],
[72,29,9,5],
[72,29,9,6],
[72,29,9,7],
[72,29,9,8],
[72,29,13,0],
[72,29,13,1],
[72,29,13,2],
[72,29,13,3],
[72,29,13,4],
[72,29,13,5],
[72,29,13,6],
[72,29,13,7],
[72,29,13,8],
[72,29,13,9],
[72,29,14,0],
[72,29,14,1],
[72,29,14,2],
[72,29,14,3],
[72,29,14,4],
[72,29,14,5],
[72,29,14,6],
[72,29,14,7],
[72,29,14,8],
[72,29,14,9],
[72,29,15,0],
[72,29,15,1],
[72,29,15,2],
[72,29,15,3],
[72,29,15,4],
[72,29,15,5],
[72,29,15,6],
[72,29,15,7],
[72,29,15,8],
[72,29,15,13],
[72,29,15,14],
[72,29,16,0],
[72,29,16,1],
[72,29,16,2],
[72,29,16,3],
[72,29,16,4],
[72,29,16,5],
[72,29,16,6],
[72,29,16,7],
[72,29,16,8],
[72,29,16,9],
[72,29,16,13],
[72,29,16,14],
[72,29,16,15],
[72,29,17,0],
[72,29,17,1],
[72,29,17,2],
[72,29,17,3],
[72,29,17,4],
[72,29,17,5],
[72,29,17,6],
[72,29,17,7],
[72,29,17,8],
[72,29,17,9],
[72,29,17,13],
[72,29,17,14],
[72,29,17,15],
[72,29,17,16],
[72,29,18,0],
[72,29,18,1],
[72,29,18,2],
[72,29,18,3],
[72,29,18,4],
[72,29,18,5],
[72,29,18,6],
[72,29,18,7],
[72,29,18,8],
[72,29,18,13],
[72,29,18,14],
[72,29,18,16],
[72,29,18,17],
[72,29,19,0],
[72,29,19,1],
[72,29,19,2],
[72,29,19,3],
[72,29,19,4],
[72,29,19,5],
[72,29,19,6],
[72,29,19,7],
[72,29,19,8],
[72,29,19,9],
[72,29,19,15],
[72,29,19,16],
[72,29,19,17],
[72,29,21,0],
[72,29,21,1],
[72,29,21,2],
[72,29,21,4],
[72,29,21,7],
[72,29,21,9],
[72,29,21,13],
[72,29,21,14],
[72,29,21,16],
[72,29,21,17],
[72,29,21,18],
[72,29,21,19],
[72,29,23,0],
[72,29,23,1],
[72,29,23,2],
[72,29,23,3],
[72,29,23,4],
[72,29,23,5],
[72,29,23,6],
[72,29,23,7],
[72,29,23,8],
[72,29,23,9],
[72,29,23,14],
[72,29,23,15],
[72,29,23,16],
[72,29,23,17],
[72,29,23,18],
[72,29,23,19],
[72,29,23,21],
[72,29,24,0],
[72,29,24,1],
[72,29,24,2],
[72,29,24,3],
[72,29,24,4],
[72,29,24,5],
[72,29,24,6],
[72,29,24,7],
[72,29,24,8],
[72,29,24,9],
[72,29,24,14],
[72,29,24,15],
[72,29,24,17],
[72,29,24,18],
[72,29,24,19],
[72,29,24,21],
[72,29,24,23],
[72,29,25,0],
[72,29,25,1],
[72,29,25,2],
[72,29,25,3],
[72,29,25,4],
[72,29,25,5],
[72,29,25,6],
[72,29,25,13],
[72,29,25,14],
[72,29,25,15],
[72,29,25,16],
[72,29,25,17],
[72,29,25,18],
[72,29,25,19],
[72,29,25,21],
[72,29,25,23],
[72,29,25,24],
[72,29,26,0],
[72,29,26,1],
[72,29,26,2],
[72,29,26,3],
[72,29,26,4],
[72,29,26,5],
[72,29,26,8],
[72,29,26,13],
[72,29,26,14],
[72,29,26,16],
[72,29,26,17],
[72,29,26,19],
[72,29,26,21],
[72,29,26,23],
[72,29,26,24],
[72,29,28,0],
[72,29,28,1],
[72,29,28,2],
[72,29,28,5],
[72,29,28,6],
[72,29,28,8],
[72,29,28,9],
[72,29,28,13],
[72,29,28,14],
[72,29,28,15],
[72,29,28,16],
[72,29,28,17],
[72,29,28,18],
[72,29,28,19],
[72,29,28,21],
[72,30,3,0],
[72,30,3,1],
[72,30,3,2],
[72,30,4,0],
[72,30,4,1],
[72,30,4,2],
[72,30,4,3],
[72,30,5,0],
[72,30,5,1],
[72,30,5,2],
[72,30,5,4],
[72,30,6,0],
[72,30,6,1],
[72,30,6,2],
[72,30,6,4],
[72,30,7,0],
[72,30,7,1],
[72,30,7,2],
[72,30,7,3],
[72,30,7,4],
[72,30,7,5],
[72,30,7,6],
[72,30,8,0],
[72,30,8,1],
[72,30,8,2],
[72,30,8,4],
[72,30,8,7],
[72,30,9,0],
[72,30,9,1],
[72,30,9,2],
[72,30,9,3],
[72,30,9,4],
[72,30,9,5],
[72,30,9,6],
[72,30,9,7],
[72,30,9,8],
[72,30,11,0],
[72,30,11,1],
[72,30,11,2],
[72,30,11,3],
[72,30,11,4],
[72,30,11,5],
[72,30,11,6],
[72,30,11,7],
[72,30,11,8],
[72,30,11,9],
[72,30,12,0],
[72,30,12,1],
[72,30,12,2],
[72,30,12,3],
[72,30,12,4],
[72,30,12,5],
[72,30,12,6],
[72,30,12,7],
[72,30,12,8],
[72,30,12,9],
[72,30,13,0],
[72,30,13,1],
[72,30,13,2],
[72,30,13,3],
[72,30,13,4],
[72,30,13,5],
[72,30,13,6],
[72,30,13,7],
[72,30,13,8],
[72,30,13,9],
[72,30,13,11],
[72,30,13,12],
[72,30,14,0],
[72,30,14,1],
[72,30,14,2],
[72,30,14,3],
[72,30,14,4],
[72,30,14,5],
[72,30,14,6],
[72,30,14,7],
[72,30,14,8],
[72,30,14,9],
[72,30,14,11],
[72,30,14,12],
[72,30,15,0],
[72,30,15,1],
[72,30,15,2],
[72,30,15,3],
[72,30,15,4],
[72,30,15,5],
[72,30,15,6],
[72,30,15,7],
[72,30,15,8],
[72,30,15,11],
[72,30,15,12],
[72,30,15,13],
[72,30,15,14],
[72,30,16,0],
[72,30,16,1],
[72,30,16,2],
[72,30,16,3],
[72,30,16,4],
[72,30,16,5],
[72,30,16,6],
[72,30,16,7],
[72,30,16,8],
[72,30,16,9],
[72,30,16,11],
[72,30,16,12],
[72,30,16,13],
[72,30,16,14],
[72,30,16,15],
[72,30,17,0],
[72,30,17,1],
[72,30,17,2],
[72,30,17,3],
[72,30,17,4],
[72,30,17,5],
[72,30,17,6],
[72,30,17,7],
[72,30,17,8],
[72,30,17,9],
[72,30,17,11],
[72,30,17,12],
[72,30,17,13],
[72,30,17,14],
[72,30,17,15],
[72,30,17,16],
[72,30,18,0],
[72,30,18,1],
[72,30,18,2],
[72,30,18,3],
[72,30,18,4],
[72,30,18,5],
[72,30,18,6],
[72,30,18,7],
[72,30,18,8],
[72,30,18,11],
[72,30,18,12],
[72,30,18,13],
[72,30,18,14],
[72,30,18,16],
[72,30,18,17],
[72,30,19,0],
[72,30,19,1],
[72,30,19,2],
[72,30,19,3],
[72,30,19,4],
[72,30,19,5],
[72,30,19,6],
[72,30,19,7],
[72,30,19,8],
[72,30,19,9],
[72,30,19,11],
[72,30,19,12],
[72,30,19,15],
[72,30,19,18],
[72,30,21,0],
[72,30,21,1],
[72,30,21,2],
[72,30,21,4],
[72,30,21,7],
[72,30,21,9],
[72,30,21,11],
[72,30,21,12],
[72,30,21,13],
[72,30,21,15],
[72,30,21,16],
[72,30,21,17],
[72,30,21,18],
[72,30,21,19],
[72,30,24,0],
[72,30,24,1],
[72,30,24,2],
[72,30,24,3],
[72,30,24,4],
[72,30,24,5],
[72,30,24,6],
[72,30,24,7],
[72,30,24,8],
[72,30,24,9],
[72,30,24,12],
[72,30,24,13],
[72,30,24,14],
[72,30,24,15],
[72,30,24,17],
[72,30,24,18],
[72,30,24,19],
[72,30,24,21],
[72,30,25,0],
[72,30,25,1],
[72,30,25,2],
[72,30,25,3],
[72,30,25,4],
[72,30,25,5],
[72,30,25,8],
[72,30,25,9],
[72,30,25,11],
[72,30,25,12],
[72,30,25,13],
[72,30,25,14],
[72,30,25,15],
[72,30,25,16],
[72,30,25,17],
[72,30,25,18],
[72,30,25,19],
[72,30,25,21],
[72,30,25,24],
[72,30,26,0],
[72,30,26,1],
[72,30,26,2],
[72,30,26,3],
[72,30,26,4],
[72,30,26,6],
[72,30,26,7],
[72,30,26,8],
[72,30,26,11],
[72,30,26,12],
[72,30,26,13],
[72,30,26,14],
[72,30,26,16],
[72,30,26,17],
[72,30,26,19],
[72,30,26,21],
[72,30,28,3],
[72,30,28,4],
[72,30,28,5],
[72,30,28,6],
[72,30,28,8],
[72,30,28,9],
[72,30,28,11],
[72,30,28,12],
[72,30,28,13],
[72,30,28,14],
[72,30,28,15],
[72,30,28,16],
[72,30,28,17],
[72,30,28,18],
[72,30,28,19],
[72,30,29,0],
[72,30,29,1],
[72,30,29,2],
[72,30,29,3],
[72,30,29,4],
[72,30,29,5],
[72,30,29,6],
[72,30,29,7],
[72,30,29,8],
[72,30,29,9],
[72,30,29,13],
[72,30,29,14],
[72,30,29,15],
[72,30,29,16],
[72,30,29,17],
[72,30,29,18],
[72,30,29,19],
[72,31,3,0],
[72,31,3,1],
[72,31,3,2],
[72,31,4,0],
[72,31,4,1],
[72,31,4,2],
[72,31,4,3],
[72,31,5,0],
[72,31,5,1],
[72,31,5,2],
[72,31,5,4],
[72,31,6,0],
[72,31,6,1],
[72,31,6,2],
[72,31,6,4],
[72,31,7,0],
[72,31,7,1],
[72,31,7,2],
[72,31,7,3],
[72,31,7,4],
[72,31,7,5],
[72,31,7,6],
[72,31,8,0],
[72,31,8,1],
[72,31,8,2],
[72,31,8,4],
[72,31,8,7],
[72,31,9,0],
[72,31,9,1],
[72,31,9,2],
[72,31,9,3],
[72,31,9,4],
[72,31,9,5],
[72,31,9,6],
[72,31,9,7],
[72,31,9,8],
[72,31,11,0],
[72,31,11,1],
[72,31,11,2],
[72,31,11,3],
[72,31,11,4],
[72,31,11,5],
[72,31,11,6],
[72,31,11,7],
[72,31,11,8],
[72,31,11,9],
[72,31,12,0],
[72,31,12,1],
[72,31,12,2],
[72,31,12,3],
[72,31,12,4],
[72,31,12,5],
[72,31,12,6],
[72,31,12,7],
[72,31,12,8],
[72,31,12,9],
[72,31,13,0],
[72,31,13,1],
[72,31,13,2],
[72,31,13,3],
[72,31,13,4],
[72,31,13,5],
[72,31,13,6],
[72,31,13,7],
[72,31,13,8],
[72,31,13,9],
[72,31,13,11],
[72,31,13,12],
[72,31,14,0],
[72,31,14,1],
[72,31,14,2],
[72,31,14,3],
[72,31,14,4],
[72,31,14,5],
[72,31,14,6],
[72,31,14,7],
[72,31,14,8],
[72,31,14,9],
[72,31,14,11],
[72,31,14,12],
[72,31,15,0],
[72,31,15,1],
[72,31,15,2],
[72,31,15,3],
[72,31,15,4],
[72,31,15,5],
[72,31,15,6],
[72,31,15,7],
[72,31,15,8],
[72,31,15,11],
[72,31,15,12],
[72,31,15,13],
[72,31,15,14],
[72,31,16,0],
[72,31,16,1],
[72,31,16,2],
[72,31,16,3],
[72,31,16,4],
[72,31,16,5],
[72,31,16,6],
[72,31,16,7],
[72,31,16,8],
[72,31,16,9],
[72,31,16,11],
[72,31,16,12],
[72,31,16,13],
[72,31,16,14],
[72,31,16,15],
[72,31,17,0],
[72,31,17,1],
[72,31,17,2],
[72,31,17,3],
[72,31,17,4],
[72,31,17,5],
[72,31,17,6],
[72,31,17,7],
[72,31,17,8],
[72,31,17,9],
[72,31,17,11],
[72,31,17,12],
[72,31,17,13],
[72,31,17,14],
[72,31,17,15],
[72,31,17,16],
[72,31,18,0],
[72,31,18,1],
[72,31,18,2],
[72,31,18,3],
[72,31,18,4],
[72,31,18,5],
[72,31,18,6],
[72,31,18,7],
[72,31,18,8],
[72,31,18,11],
[72,31,18,12],
[72,31,18,13],
[72,31,18,14],
[72,31,19,0],
[72,31,19,1],
[72,31,19,2],
[72,31,19,3],
[72,31,19,4],
[72,31,19,5],
[72,31,19,6],
[72,31,19,7],
[72,31,19,8],
[72,31,19,9],
[72,31,19,11],
[72,31,19,12],
[72,31,19,16],
[72,31,19,17],
[72,31,19,18],
[72,31,21,0],
[72,31,21,1],
[72,31,21,2],
[72,31,21,4],
[72,31,21,7],
[72,31,21,9],
[72,31,21,11],
[72,31,21,14],
[72,31,21,15],
[72,31,21,16],
[72,31,21,17],
[72,31,21,18],
[72,31,21,19],
[72,31,24,0],
[72,31,24,1],
[72,31,24,2],
[72,31,24,3],
[72,31,24,4],
[72,31,24,5],
[72,31,24,6],
[72,31,24,7],
[72,31,24,11],
[72,31,24,12],
[72,31,24,13],
[72,31,24,14],
[72,31,24,15],
[72,31,24,17],
[72,31,24,18],
[72,31,24,19],
[72,31,24,21],
[72,31,25,0],
[72,31,25,1],
[72,31,25,2],
[72,31,25,3],
[72,31,25,4],
[72,31,25,6],
[72,31,25,8],
[72,31,25,9],
[72,31,25,11],
[72,31,25,12],
[72,31,25,13],
[72,31,25,14],
[72,31,25,15],
[72,31,25,16],
[72,31,25,17],
[72,31,25,18],
[72,31,25,19],
[72,31,25,21],
[72,31,26,0],
[72,31,26,1],
[72,31,26,2],
[72,31,26,5],
[72,31,26,6],
[72,31,26,7],
[72,31,26,8],
[72,31,26,11],
[72,31,26,12],
[72,31,26,13],
[72,31,26,14],
[72,31,26,16],
[72,31,26,17],
[72,31,26,19],
[72,31,26,21],
[72,31,28,0],
[72,31,28,1],
[72,31,28,2],
[72,31,28,3],
[72,31,28,4],
[72,31,28,5],
[72,31,28,6],
[72,31,28,8],
[72,31,28,9],
[72,31,28,11],
[72,31,28,12],
[72,31,28,13],
[72,31,28,14],
[72,31,28,15],
[72,31,28,16],
[72,31,28,17],
[72,31,28,18],
[72,31,28,19],
[72,31,29,0],
[72,31,29,1],
[72,31,29,2],
[72,31,29,3],
[72,31,29,4],
[72,31,29,5],
[72,31,29,6],
[72,31,29,7],
[72,31,29,8],
[72,31,29,9],
[72,31,29,13],
[72,31,29,14],
[72,31,29,15],
[72,31,29,16],
[72,31,29,17],
[72,31,29,18],
[72,32,3,0],
[72,32,3,1],
[72,32,3,2],
[72,32,4,0],
[72,32,4,1],
[72,32,4,2],
[72,32,4,3],
[72,32,5,0],
[72,32,5,1],
[72,32,5,2],
[72,32,5,4],
[72,32,6,0],
[72,32,6,1],
[72,32,6,2],
[72,32,6,4],
[72,32,7,0],
[72,32,7,1],
[72,32,7,2],
[72,32,7,3],
[72,32,7,4],
[72,32,7,5],
[72,32,7,6],
[72,32,8,0],
[72,32,8,1],
[72,32,8,2],
[72,32,8,4],
[72,32,8,7],
[72,32,9,0],
[72,32,9,1],
[72,32,9,2],
[72,32,9,3],
[72,32,9,4],
[72,32,9,5],
[72,32,9,6],
[72,32,9,7],
[72,32,9,8],
[72,32,13,0],
[72,32,13,1],
[72,32,13,2],
[72,32,13,3],
[72,32,13,4],
[72,32,13,5],
[72,32,13,6],
[72,32,13,7],
[72,32,13,8],
[72,32,13,9],
[72,32,14,0],
[72,32,14,1],
[72,32,14,2],
[72,32,14,3],
[72,32,14,4],
[72,32,14,5],
[72,32,14,6],
[72,32,14,7],
[72,32,14,8],
[72,32,14,9],
[72,32,15,0],
[72,32,15,1],
[72,32,15,2],
[72,32,15,3],
[72,32,15,4],
[72,32,15,5],
[72,32,15,6],
[72,32,15,7],
[72,32,15,8],
[72,32,15,13],
[72,32,15,14],
[72,32,16,0],
[72,32,16,1],
[72,32,16,2],
[72,32,16,3],
[72,32,16,4],
[72,32,16,5],
[72,32,16,6],
[72,32,16,7],
[72,32,16,8],
[72,32,16,9],
[72,32,16,13],
[72,32,16,14],
[72,32,16,15],
[72,32,17,0],
[72,32,17,1],
[72,32,17,2],
[72,32,17,3],
[72,32,17,4],
[72,32,17,5],
[72,32,17,6],
[72,32,17,7],
[72,32,17,8],
[72,32,17,9],
[72,32,17,13],
[72,32,17,14],
[72,32,17,15],
[72,32,18,0],
[72,32,18,1],
[72,32,18,2],
[72,32,18,3],
[72,32,18,4],
[72,32,18,5],
[72,32,18,6],
[72,32,18,7],
[72,32,18,8],
[72,32,18,13],
[72,32,18,14],
[72,32,18,16],
[72,32,18,17],
[72,32,19,0],
[72,32,19,1],
[72,32,19,2],
[72,32,19,3],
[72,32,19,4],
[72,32,19,5],
[72,32,19,6],
[72,32,19,7],
[72,32,19,8],
[72,32,19,9],
[72,32,19,15],
[72,32,19,16],
[72,32,19,17],
[72,32,19,18],
[72,32,21,0],
[72,32,21,1],
[72,32,21,2],
[72,32,21,4],
[72,32,21,7],
[72,32,21,9],
[72,32,21,13],
[72,32,21,14],
[72,32,21,15],
[72,32,21,16],
[72,32,21,17],
[72,32,21,18],
[72,32,21,19],
[72,32,23,0],
[72,32,23,1],
[72,32,23,2],
[72,32,23,3],
[72,32,23,4],
[72,32,23,5],
[72,32,23,8],
[72,32,23,9],
[72,32,23,13],
[72,32,23,14],
[72,32,23,15],
[72,32,23,16],
[72,32,23,17],
[72,32,23,18],
[72,32,23,19],
[72,32,23,21],
[72,32,24,0],
[72,32,24,1],
[72,32,24,2],
[72,32,24,3],
[72,32,24,4],
[72,32,24,5],
[72,32,24,8],
[72,32,24,9],
[72,32,24,13],
[72,32,24,14],
[72,32,24,15],
[72,32,24,17],
[72,32,24,18],
[72,32,24,19],
[72,32,24,21],
[72,32,24,23],
[72,32,25,0],
[72,32,25,1],
[72,32,25,2],
[72,32,25,5],
[72,32,25,6],
[72,32,25,8],
[72,32,25,9],
[72,32,25,13],
[72,32,25,14],
[72,32,25,15],
[72,32,25,16],
[72,32,25,17],
[72,32,25,18],
[72,32,25,19],
[72,32,25,21],
[72,32,26,3],
[72,32,26,4],
[72,32,26,5],
[72,32,26,6],
[72,32,26,7],
[72,32,26,8],
[72,32,26,13],
[72,32,26,14],
[72,32,26,16],
[72,32,26,17],
[72,32,26,19],
[72,32,28,0],
[72,32,28,1],
[72,32,28,2],
[72,32,28,3],
[72,32,28,4],
[72,32,28,5],
[72,32,28,6],
[72,32,28,8],
[72,32,28,9],
[72,32,28,13],
[72,32,28,14],
[72,32,28,15],
[72,32,28,16],
[72,32,28,17],
[72,32,28,18],
[72,32,30,0],
[72,32,30,1],
[72,32,30,2],
[72,32,30,3],
[72,32,30,4],
[72,32,30,5],
[72,32,30,6],
[72,32,30,7],
[72,32,30,8],
[72,32,30,9],
[72,32,30,13],
[72,32,30,14],
[72,32,30,15],
[72,32,31,0],
[72,32,31,1],
[72,32,31,2],
[72,32,31,3],
[72,32,31,4],
[72,32,31,5],
[72,32,31,6],
[72,32,31,7],
[72,32,31,8],
[72,32,31,9],
[72,32,31,13],
[72,32,31,14],
[72,33,3,0],
[72,33,3,1],
[72,33,3,2],
[72,33,4,0],
[72,33,4,1],
[72,33,4,2],
[72,33,4,3],
[72,33,5,0],
[72,33,5,1],
[72,33,5,2],
[72,33,5,4],
[72,33,6,0],
[72,33,6,1],
[72,33,6,2],
[72,33,6,4],
[72,33,7,0],
[72,33,7,1],
[72,33,7,2],
[72,33,7,3],
[72,33,7,4],
[72,33,7,5],
[72,33,7,6],
[72,33,8,0],
[72,33,8,1],
[72,33,8,2],
[72,33,8,4],
[72,33,8,7],
[72,33,9,0],
[72,33,9,1],
[72,33,9,2],
[72,33,9,3],
[72,33,9,4],
[72,33,9,5],
[72,33,9,6],
[72,33,9,7],
[72,33,9,8],
[72,33,13,0],
[72,33,13,1],
[72,33,13,2],
[72,33,13,3],
[72,33,13,4],
[72,33,13,5],
[72,33,13,6],
[72,33,13,7],
[72,33,13,8],
[72,33,13,9],
[72,33,14,0],
[72,33,14,1],
[72,33,14,2],
[72,33,14,3],
[72,33,14,4],
[72,33,14,5],
[72,33,14,6],
[72,33,14,7],
[72,33,14,8],
[72,33,14,9],
[72,33,15,0],
[72,33,15,1],
[72,33,15,2],
[72,33,15,3],
[72,33,15,4],
[72,33,15,5],
[72,33,15,6],
[72,33,15,7],
[72,33,15,8],
[72,33,15,13],
[72,33,15,14],
[72,33,16,0],
[72,33,16,1],
[72,33,16,2],
[72,33,16,3],
[72,33,16,4],
[72,33,16,5],
[72,33,16,6],
[72,33,16,7],
[72,33,16,8],
[72,33,16,9],
[72,33,16,13],
[72,33,16,14],
[72,33,17,0],
[72,33,17,1],
[72,33,17,2],
[72,33,17,3],
[72,33,17,4],
[72,33,17,5],
[72,33,17,6],
[72,33,17,7],
[72,33,17,8],
[72,33,17,9],
[72,33,17,13],
[72,33,17,14],
[72,33,17,16],
[72,33,18,0],
[72,33,18,1],
[72,33,18,2],
[72,33,18,3],
[72,33,18,4],
[72,33,18,5],
[72,33,18,6],
[72,33,18,7],
[72,33,18,8],
[72,33,18,13],
[72,33,18,16],
[72,33,18,17],
[72,33,19,0],
[72,33,19,1],
[72,33,19,2],
[72,33,19,3],
[72,33,19,4],
[72,33,19,5],
[72,33,19,6],
[72,33,19,7],
[72,33,19,8],
[72,33,19,9],
[72,33,19,15],
[72,33,19,16],
[72,33,19,17],
[72,33,19,18],
[72,33,21,0],
[72,33,21,1],
[72,33,21,2],
[72,33,21,4],
[72,33,21,7],
[72,33,21,13],
[72,33,21,14],
[72,33,21,15],
[72,33,21,16],
[72,33,21,17],
[72,33,21,18],
[72,33,21,19],
[72,33,23,0],
[72,33,23,1],
[72,33,23,2],
[72,33,23,3],
[72,33,23,4],
[72,33,23,6],
[72,33,23,7],
[72,33,23,8],
[72,33,23,9],
[72,33,23,13],
[72,33,23,14],
[72,33,23,15],
[72,33,23,16],
[72,33,23,17],
[72,33,23,18],
[72,33,23,19],
[72,33,23,21],
[72,33,24,0],
[72,33,24,1],
[72,33,24,2],
[72,33,24,3],
[72,33,24,4],
[72,33,24,6],
[72,33,24,7],
[72,33,24,8],
[72,33,24,9],
[72,33,24,13],
[72,33,24,14],
[72,33,24,15],
[72,33,24,17],
[72,33,24,18],
[72,33,24,19],
[72,33,24,21],
[72,33,25,3],
[72,33,25,4],
[72,33,25,5],
[72,33,25,6],
[72,33,25,8],
[72,33,25,9],
[72,33,25,13],
[72,33,25,14],
[72,33,25,15],
[72,33,25,16],
[72,33,25,17],
[72,33,25,18],
[72,33,25,19],
[72,33,26,0],
[72,33,26,1],
[72,33,26,2],
[72,33,26,3],
[72,33,26,4],
[72,33,26,5],
[72,33,26,6],
[72,33,26,7],
[72,33,26,8],
[72,33,26,13],
[72,33,26,14],
[72,33,26,16],
[72,33,26,17],
[72,33,26,19],
[72,33,28,0],
[72,33,28,1],
[72,33,28,2],
[72,33,28,3],
[72,33,28,4],
[72,33,28,5],
[72,33,28,6],
[72,33,28,8],
[72,33,28,9],
[72,33,28,13],
[72,33,28,14],
[72,33,28,15],
[72,33,28,16],
[72,33,28,17],
[72,33,30,0],
[72,33,30,1],
[72,33,30,2],
[72,33,30,3],
[72,33,30,4],
[72,33,30,5],
[72,33,30,6],
[72,33,30,7],
[72,33,30,8],
[72,33,30,9],
[72,33,30,13],
[72,33,30,14],
[72,33,31,0],
[72,33,31,1],
[72,33,31,2],
[72,33,31,3],
[72,33,31,4],
[72,33,31,5],
[72,33,31,6],
[72,33,31,7],
[72,33,31,8],
[72,33,31,9],
[72,33,31,13],
[72,34,3,0],
[72,34,3,1],
[72,34,3,2],
[72,34,4,0],
[72,34,4,1],
[72,34,4,2],
[72,34,4,3],
[72,34,5,0],
[72,34,5,1],
[72,34,5,2],
[72,34,5,4],
[72,34,6,0],
[72,34,6,1],
[72,34,6,2],
[72,34,6,4],
[72,34,8,0],
[72,34,8,1],
[72,34,8,2],
[72,34,8,4],
[72,34,9,0],
[72,34,9,1],
[72,34,9,2],
[72,34,9,3],
[72,34,9,4],
[72,34,9,5],
[72,34,9,6],
[72,34,9,8],
[72,34,11,0],
[72,34,11,1],
[72,34,11,2],
[72,34,11,3],
[72,34,11,4],
[72,34,11,5],
[72,34,11,6],
[72,34,11,8],
[72,34,11,9],
[72,34,12,0],
[72,34,12,1],
[72,34,12,2],
[72,34,12,3],
[72,34,12,4],
[72,34,12,5],
[72,34,12,6],
[72,34,12,8],
[72,34,12,9],
[72,34,13,0],
[72,34,13,1],
[72,34,13,2],
[72,34,13,3],
[72,34,13,4],
[72,34,13,5],
[72,34,13,6],
[72,34,13,8],
[72,34,13,9],
[72,34,13,11],
[72,34,13,12],
[72,34,14,0],
[72,34,14,1],
[72,34,14,2],
[72,34,14,3],
[72,34,14,4],
[72,34,14,5],
[72,34,14,6],
[72,34,14,8],
[72,34,14,9],
[72,34,14,11],
[72,34,14,12],
[72,34,15,0],
[72,34,15,1],
[72,34,15,2],
[72,34,15,3],
[72,34,15,4],
[72,34,15,5],
[72,34,15,6],
[72,34,15,8],
[72,34,15,11],
[72,34,15,12],
[72,34,15,13],
[72,34,15,14],
[72,34,16,0],
[72,34,16,1],
[72,34,16,2],
[72,34,16,3],
[72,34,16,4],
[72,34,16,5],
[72,34,16,6],
[72,34,16,8],
[72,34,16,9],
[72,34,16,11],
[72,34,16,12],
[72,34,16,13],
[72,34,16,15],
[72,34,17,0],
[72,34,17,1],
[72,34,17,2],
[72,34,17,3],
[72,34,17,4],
[72,34,17,5],
[72,34,17,6],
[72,34,17,8],
[72,34,17,9],
[72,34,17,11],
[72,34,17,12],
[72,34,17,13],
[72,34,17,15],
[72,34,17,16],
[72,34,18,0],
[72,34,18,1],
[72,34,18,2],
[72,34,18,3],
[72,34,18,4],
[72,34,18,5],
[72,34,18,6],
[72,34,18,8],
[72,34,18,11],
[72,34,18,14],
[72,34,18,16],
[72,34,18,17],
[72,34,19,0],
[72,34,19,1],
[72,34,19,2],
[72,34,19,3],
[72,34,19,4],
[72,34,19,5],
[72,34,19,6],
[72,34,19,8],
[72,34,19,9],
[72,34,19,12],
[72,34,19,15],
[72,34,19,16],
[72,34,19,17],
[72,34,19,18],
[72,34,21,0],
[72,34,21,1],
[72,34,21,2],
[72,34,21,4],
[72,34,21,9],
[72,34,21,11],
[72,34,21,12],
[72,34,21,13],
[72,34,21,14],
[72,34,21,15],
[72,34,21,16],
[72,34,21,17],
[72,34,21,18],
[72,34,21,19],
[72,34,23,0],
[72,34,23,1],
[72,34,23,2],
[72,34,23,5],
[72,34,23,6],
[72,34,23,8],
[72,34,23,9],
[72,34,23,11],
[72,34,23,12],
[72,34,23,13],
[72,34,23,14],
[72,34,23,15],
[72,34,23,16],
[72,34,23,17],
[72,34,23,18],
[72,34,23,19],
[72,34,23,21],
[72,34,24,0],
[72,34,24,1],
[72,34,24,2],
[72,34,24,5],
[72,34,24,6],
[72,34,24,8],
[72,34,24,9],
[72,34,24,11],
[72,34,24,12],
[72,34,24,13],
[72,34,24,14],
[72,34,24,15],
[72,34,24,17],
[72,34,24,18],
[72,34,24,19],
[72,34,24,21],
[72,34,26,0],
[72,34,26,1],
[72,34,26,2],
[72,34,26,3],
[72,34,26,4],
[72,34,26,5],
[72,34,26,6],
[72,34,26,8],
[72,34,26,11],
[72,34,26,12],
[72,34,26,13],
[72,34,26,14],
[72,34,26,16],
[72,34,26,17],
[72,34,29,0],
[72,34,29,1],
[72,34,29,2],
[72,34,29,3],
[72,34,29,4],
[72,34,29,5],
[72,34,29,6],
[72,34,29,8],
[72,34,29,9],
[72,34,29,13],
[72,34,29,14],
[72,34,30,0],
[72,34,30,1],
[72,34,30,2],
[72,34,30,3],
[72,34,30,4],
[72,34,30,5],
[72,34,30,6],
[72,34,30,8],
[72,34,30,9],
[72,34,30,11],
[72,34,30,12],
[72,34,30,13],
[72,34,31,0],
[72,34,31,1],
[72,34,31,2],
[72,34,31,3],
[72,34,31,4],
[72,34,31,5],
[72,34,31,6],
[72,34,31,8],
[72,34,31,9],
[72,34,31,11],
[72,34,32,0],
[72,34,32,1],
[72,34,32,2],
[72,34,32,3],
[72,34,32,4],
[72,34,32,5],
[72,34,32,6],
[72,34,32,8],
[72,34,32,9],
[72,34,33,0],
[72,34,33,1],
[72,34,33,2],
[72,34,33,3],
[72,34,33,4],
[72,34,33,5],
[72,34,33,6],
[72,35,3,0],
[72,35,3,1],
[72,35,3,2],
[72,35,4,0],
[72,35,4,1],
[72,35,4,2],
[72,35,4,3],
[72,35,5,0],
[72,35,5,1],
[72,35,5,2],
[72,35,5,4],
[72,35,6,0],
[72,35,6,1],
[72,35,6,2],
[72,35,6,4],
[72,35,7,0],
[72,35,7,1],
[72,35,7,2],
[72,35,7,3],
[72,35,7,4],
[72,35,7,5],
[72,35,7,6],
[72,35,8,0],
[72,35,8,1],
[72,35,8,2],
[72,35,8,4],
[72,35,8,7],
[72,35,11,0],
[72,35,11,1],
[72,35,11,2],
[72,35,11,3],
[72,35,11,4],
[72,35,11,5],
[72,35,11,6],
[72,35,11,7],
[72,35,11,8],
[72,35,12,0],
[72,35,12,1],
[72,35,12,2],
[72,35,12,3],
[72,35,12,4],
[72,35,12,5],
[72,35,12,6],
[72,35,12,7],
[72,35,12,8],
[72,35,13,0],
[72,35,13,1],
[72,35,13,2],
[72,35,13,3],
[72,35,13,4],
[72,35,13,5],
[72,35,13,6],
[72,35,13,7],
[72,35,13,8],
[72,35,13,11],
[72,35,13,12],
[72,35,14,0],
[72,35,14,1],
[72,35,14,2],
[72,35,14,3],
[72,35,14,4],
[72,35,14,5],
[72,35,14,6],
[72,35,14,7],
[72,35,14,8],
[72,35,14,11],
[72,35,14,12],
[72,35,16,0],
[72,35,16,1],
[72,35,16,2],
[72,35,16,3],
[72,35,16,4],
[72,35,16,5],
[72,35,16,6],
[72,35,16,7],
[72,35,16,8],
[72,35,16,11],
[72,35,16,14],
[72,35,17,0],
[72,35,17,1],
[72,35,17,2],
[72,35,17,3],
[72,35,17,4],
[72,35,17,5],
[72,35,17,6],
[72,35,17,7],
[72,35,17,8],
[72,35,17,11],
[72,35,17,14],
[72,35,17,16],
[72,35,19,0],
[72,35,19,1],
[72,35,19,2],
[72,35,19,3],
[72,35,19,4],
[72,35,19,5],
[72,35,19,6],
[72,35,19,7],
[72,35,19,11],
[72,35,19,12],
[72,35,19,16],
[72,35,19,17],
[72,35,21,0],
[72,35,21,1],
[72,35,21,2],
[72,35,21,4],
[72,35,21,7],
[72,35,21,11],
[72,35,21,12],
[72,35,21,13],
[72,35,21,14],
[72,35,21,16],
[72,35,21,17],
[72,35,21,19],
[72,35,23,3],
[72,35,23,4],
[72,35,23,5],
[72,35,23,6],
[72,35,23,7],
[72,35,23,8],
[72,35,23,11],
[72,35,23,12],
[72,35,23,13],
[72,35,23,14],
[72,35,23,16],
[72,35,23,17],
[72,35,23,19],
[72,35,24,3],
[72,35,24,4],
[72,35,24,5],
[72,35,24,6],
[72,35,24,7],
[72,35,24,8],
[72,35,24,11],
[72,35,24,12],
[72,35,24,13],
[72,35,24,14],
[72,35,24,17],
[72,35,24,19],
[72,35,25,0],
[72,35,25,1],
[72,35,25,2],
[72,35,25,3],
[72,35,25,4],
[72,35,25,5],
[72,35,25,6],
[72,35,25,8],
[72,35,25,11],
[72,35,25,12],
[72,35,25,13],
[72,35,25,14],
[72,35,25,16],
[72,35,25,17],
[72,35,28,0],
[72,35,28,1],
[72,35,28,2],
[72,35,28,3],
[72,35,28,4],
[72,35,28,5],
[72,35,28,6],
[72,35,28,8],
[72,35,28,11],
[72,35,28,12],
[72,35,28,13],
[72,35,28,14],
[72,35,29,0],
[72,35,29,1],
[72,35,29,2],
[72,35,29,3],
[72,35,29,4],
[72,35,29,5],
[72,35,29,6],
[72,35,29,7],
[72,35,29,8],
[72,35,29,13],
[72,35,30,0],
[72,35,30,1],
[72,35,30,2],
[72,35,30,3],
[72,35,30,4],
[72,35,30,5],
[72,35,30,6],
[72,35,30,7],
[72,35,30,8],
[72,35,30,11],
[72,35,31,0],
[72,35,31,1],
[72,35,31,2],
[72,35,31,3],
[72,35,31,4],
[72,35,31,5],
[72,35,31,6],
[72,35,31,7],
[72,35,31,8],
[72,35,32,0],
[72,35,32,1],
[72,35,32,2],
[72,35,32,3],
[72,35,32,4],
[72,35,32,5],
[72,35,32,6],
[72,35,32,7],
[72,35,33,0],
[72,35,33,1],
[72,35,33,2],
[72,35,33,3],
[72,35,33,4],
[72,35,33,5],
[72,35,34,0],
[72,35,34,1],
[72,35,34,2],
[72,35,34,3],
[72,35,34,4],
[72,36,3,0],
[72,36,3,1],
[72,36,3,2],
[72,36,4,0],
[72,36,4,1],
[72,36,4,2],
[72,36,4,3],
[72,36,5,0],
[72,36,5,1],
[72,36,5,2],
[72,36,5,4],
[72,36,6,0],
[72,36,6,1],
[72,36,6,2],
[72,36,6,4],
[72,36,7,0],
[72,36,7,1],
[72,36,7,2],
[72,36,7,3],
[72,36,7,4],
[72,36,7,5],
[72,36,7,6],
[72,36,8,0],
[72,36,8,1],
[72,36,8,2],
[72,36,8,4],
[72,36,8,7],
[72,36,9,0],
[72,36,9,1],
[72,36,9,2],
[72,36,9,3],
[72,36,9,4],
[72,36,9,5],
[72,36,9,6],
[72,36,9,7],
[72,36,9,8],
[72,36,11,0],
[72,36,11,1],
[72,36,11,2],
[72,36,11,3],
[72,36,11,4],
[72,36,11,5],
[72,36,11,6],
[72,36,11,7],
[72,36,11,8],
[72,36,11,9],
[72,36,12,0],
[72,36,12,1],
[72,36,12,2],
[72,36,12,3],
[72,36,12,4],
[72,36,12,5],
[72,36,12,6],
[72,36,12,7],
[72,36,12,8],
[72,36,12,9],
[72,36,15,0],
[72,36,15,1],
[72,36,15,2],
[72,36,15,3],
[72,36,15,4],
[72,36,15,5],
[72,36,15,6],
[72,36,15,7],
[72,36,15,8],
[72,36,15,11],
[72,36,16,0],
[72,36,16,1],
[72,36,16,2],
[72,36,16,3],
[72,36,16,4],
[72,36,16,5],
[72,36,16,6],
[72,36,16,7],
[72,36,16,8],
[72,36,16,9],
[72,36,16,12],
[72,36,16,15],
[72,36,17,0],
[72,36,17,1],
[72,36,17,2],
[72,36,17,3],
[72,36,17,4],
[72,36,17,5],
[72,36,17,6],
[72,36,17,7],
[72,36,17,8],
[72,36,17,9],
[72,36,17,12],
[72,36,17,15],
[72,36,17,16],
[72,36,18,0],
[72,36,18,1],
[72,36,18,2],
[72,36,18,3],
[72,36,18,4],
[72,36,18,5],
[72,36,18,6],
[72,36,18,7],
[72,36,18,11],
[72,36,18,12],
[72,36,18,16],
[72,36,18,17],
[72,36,21,0],
[72,36,21,1],
[72,36,21,2],
[72,36,21,7],
[72,36,21,9],
[72,36,21,11],
[72,36,21,12],
[72,36,21,15],
[72,36,21,16],
[72,36,21,17],
[72,36,21,18],
[72,36,23,0],
[72,36,23,1],
[72,36,23,2],
[72,36,23,3],
[72,36,23,4],
[72,36,23,5],
[72,36,23,6],
[72,36,23,7],
[72,36,23,8],
[72,36,23,9],
[72,36,23,11],
[72,36,23,12],
[72,36,23,15],
[72,36,23,16],
[72,36,23,17],
[72,36,23,18],
[72,36,24,0],
[72,36,24,1],
[72,36,24,2],
[72,36,24,3],
[72,36,24,4],
[72,36,24,5],
[72,36,24,6],
[72,36,24,7],
[72,36,24,8],
[72,36,24,9],
[72,36,24,11],
[72,36,24,12],
[72,36,24,15],
[72,36,24,17],
[72,36,24,18],
[72,36,25,0],
[72,36,25,1],
[72,36,25,2],
[72,36,25,3],
[72,36,25,4],
[72,36,25,5],
[72,36,25,6],
[72,36,25,8],
[72,36,25,9],
[72,36,25,11],
[72,36,25,12],
[72,36,25,15],
[72,36,25,16],
[72,36,25,17],
[72,36,26,0],
[72,36,26,1],
[72,36,26,2],
[72,36,26,3],
[72,36,26,4],
[72,36,26,5],
[72,36,26,6],
[72,36,26,7],
[72,36,26,8],
[72,36,26,11],
[72,36,26,12],
[72,36,28,0],
[72,36,28,1],
[72,36,28,2],
[72,36,28,3],
[72,36,28,4],
[72,36,28,5],
[72,36,28,6],
[72,36,28,8],
[72,36,28,9],
[72,36,28,11],
[72,36,28,12],
[72,36,29,0],
[72,36,29,1],
[72,36,29,2],
[72,36,29,3],
[72,36,29,4],
[72,36,29,5],
[72,36,29,6],
[72,36,29,7],
[72,36,29,8],
[72,36,29,9],
[72,36,30,0],
[72,36,30,1],
[72,36,30,2],
[72,36,30,3],
[72,36,30,4],
[72,36,30,5],
[72,36,30,6],
[72,36,30,7],
[72,36,30,8],
[72,36,30,9],
[72,36,31,0],
[72,36,31,1],
[72,36,31,2],
[72,36,31,3],
[72,36,31,4],
[72,36,31,5],
[72,36,31,6],
[72,36,31,7],
[72,36,32,0],
[72,36,32,1],
[72,36,32,2],
[72,36,32,3],
[72,36,32,4],
[72,36,32,5],
[72,36,33,0],
[72,36,33,1],
[72,36,33,2],
[72,36,33,3],
[72,36,33,4],
[72,36,34,0],
[72,36,34,1],
[72,36,34,2],
[72,37,3,0],
[72,37,3,1],
[72,37,3,2],
[72,37,5,0],
[72,37,5,1],
[72,37,5,2],
[72,37,6,0],
[72,37,6,1],
[72,37,6,2],
[72,37,7,0],
[72,37,7,1],
[72,37,7,2],
[72,37,7,3],
[72,37,7,5],
[72,37,7,6],
[72,37,8,0],
[72,37,8,1],
[72,37,8,2],
[72,37,8,7],
[72,37,9,0],
[72,37,9,1],
[72,37,9,2],
[72,37,9,3],
[72,37,9,5],
[72,37,9,6],
[72,37,9,7],
[72,37,9,8],
[72,37,11,0],
[72,37,11,1],
[72,37,11,2],
[72,37,11,3],
[72,37,11,5],
[72,37,11,6],
[72,37,11,7],
[72,37,11,8],
[72,37,11,9],
[72,37,12,0],
[72,37,12,1],
[72,37,12,2],
[72,37,12,3],
[72,37,12,5],
[72,37,12,6],
[72,37,12,7],
[72,37,12,8],
[72,37,12,9],
[72,37,13,0],
[72,37,13,1],
[72,37,13,2],
[72,37,13,3],
[72,37,13,5],
[72,37,13,6],
[72,37,13,7],
[72,37,13,8],
[72,37,13,9],
[72,37,13,11],
[72,37,13,12],
[72,37,14,0],
[72,37,14,1],
[72,37,14,2],
[72,37,14,3],
[72,37,14,5],
[72,37,14,6],
[72,37,14,7],
[72,37,14,8],
[72,37,14,9],
[72,37,14,11],
[72,37,15,0],
[72,37,15,1],
[72,37,15,2],
[72,37,15,3],
[72,37,15,5],
[72,37,15,6],
[72,37,15,7],
[72,37,15,8],
[72,37,15,12],
[72,37,15,13],
[72,37,15,14],
[72,37,16,0],
[72,37,16,1],
[72,37,16,2],
[72,37,16,3],
[72,37,16,5],
[72,37,16,6],
[72,37,16,7],
[72,37,16,11],
[72,37,16,12],
[72,37,16,13],
[72,37,16,14],
[72,37,16,15],
[72,37,17,0],
[72,37,17,1],
[72,37,17,2],
[72,37,17,3],
[72,37,17,5],
[72,37,17,6],
[72,37,17,7],
[72,37,17,11],
[72,37,17,12],
[72,37,17,13],
[72,37,17,14],
[72,37,17,15],
[72,37,17,16],
[72,37,18,0],
[72,37,18,1],
[72,37,18,2],
[72,37,18,3],
[72,37,18,5],
[72,37,18,8],
[72,37,18,11],
[72,37,18,12],
[72,37,18,13],
[72,37,18,14],
[72,37,18,16],
[72,37,18,17],
[72,37,19,0],
[72,37,19,1],
[72,37,19,2],
[72,37,19,3],
[72,37,19,6],
[72,37,19,7],
[72,37,19,8],
[72,37,19,9],
[72,37,19,11],
[72,37,19,12],
[72,37,19,15],
[72,37,19,16],
[72,37,19,17],
[72,37,19,18],
[72,37,21,7],
[72,37,21,9],
[72,37,21,11],
[72,37,21,12],
[72,37,21,13],
[72,37,21,14],
[72,37,21,15],
[72,37,21,16],
[72,37,21,17],
[72,37,21,18],
[72,37,21,19],
[72,37,23,0],
[72,37,23,1],
[72,37,23,2],
[72,37,23,3],
[72,37,23,5],
[72,37,23,6],
[72,37,23,7],
[72,37,23,8],
[72,37,23,9],
[72,37,23,11],
[72,37,23,12],
[72,37,23,13],
[72,37,23,14],
[72,37,23,15],
[72,37,23,16],
[72,37,23,17],
[72,37,23,18],
[72,37,24,0],
[72,37,24,1],
[72,37,24,2],
[72,37,24,3],
[72,37,24,5],
[72,37,24,6],
[72,37,24,7],
[72,37,24,8],
[72,37,24,9],
[72,37,24,11],
[72,37,24,12],
[72,37,24,13],
[72,37,24,14],
[72,37,24,15],
[72,37,24,17],
[72,37,24,18],
[72,37,25,0],
[72,37,25,1],
[72,37,25,2],
[72,37,25,3],
[72,37,25,5],
[72,37,25,6],
[72,37,25,8],
[72,37,25,9],
[72,37,25,11],
[72,37,25,12],
[72,37,25,13],
[72,37,25,14],
[72,37,25,15],
[72,37,26,0],
[72,37,26,1],
[72,37,26,2],
[72,37,26,3],
[72,37,26,5],
[72,37,26,6],
[72,37,26,7],
[72,37,26,8],
[72,37,26,11],
[72,37,26,12],
[72,37,26,13],
[72,37,26,14],
[72,37,28,0],
[72,37,28,1],
[72,37,28,2],
[72,37,28,3],
[72,37,28,5],
[72,37,28,6],
[72,37,28,8],
[72,37,28,9],
[72,37,28,11],
[72,37,29,0],
[72,37,29,1],
[72,37,29,2],
[72,37,29,3],
[72,37,29,5],
[72,37,29,6],
[72,37,29,7],
[72,37,29,8],
[72,37,29,9],
[72,37,30,0],
[72,37,30,1],
[72,37,30,2],
[72,37,30,3],
[72,37,30,5],
[72,37,30,6],
[72,37,30,7],
[72,37,31,0],
[72,37,31,1],
[72,37,31,2],
[72,37,31,3],
[72,37,31,5],
[72,37,32,0],
[72,37,32,1],
[72,37,32,2],
[72,37,32,3],
[72,37,33,0],
[72,37,33,1],
[72,37,33,2],
[72,38,3,0],
[72,38,3,1],
[72,38,3,2],
[72,38,4,0],
[72,38,4,1],
[72,38,4,2],
[72,38,4,3],
[72,38,5,0],
[72,38,5,1],
[72,38,5,2],
[72,38,5,4],
[72,38,6,0],
[72,38,6,1],
[72,38,6,2],
[72,38,6,4],
[72,38,8,0],
[72,38,8,1],
[72,38,8,2],
[72,38,8,4],
[72,38,9,0],
[72,38,9,1],
[72,38,9,2],
[72,38,9,3],
[72,38,9,4],
[72,38,9,5],
[72,38,9,6],
[72,38,9,8],
[72,38,11,0],
[72,38,11,1],
[72,38,11,2],
[72,38,11,3],
[72,38,11,4],
[72,38,11,5],
[72,38,11,6],
[72,38,11,8],
[72,38,11,9],
[72,38,12,0],
[72,38,12,1],
[72,38,12,2],
[72,38,12,3],
[72,38,12,4],
[72,38,12,5],
[72,38,12,6],
[72,38,12,8],
[72,38,12,9],
[72,38,13,0],
[72,38,13,1],
[72,38,13,2],
[72,38,13,3],
[72,38,13,4],
[72,38,13,5],
[72,38,13,6],
[72,38,13,8],
[72,38,13,9],
[72,38,13,11],
[72,38,14,0],
[72,38,14,1],
[72,38,14,2],
[72,38,14,3],
[72,38,14,4],
[72,38,14,5],
[72,38,14,6],
[72,38,14,8],
[72,38,14,9],
[72,38,14,12],
[72,38,15,0],
[72,38,15,1],
[72,38,15,2],
[72,38,15,3],
[72,38,15,4],
[72,38,15,5],
[72,38,15,6],
[72,38,15,11],
[72,38,15,12],
[72,38,15,13],
[72,38,15,14],
[72,38,16,0],
[72,38,16,1],
[72,38,16,2],
[72,38,16,3],
[72,38,16,4],
[72,38,16,5],
[72,38,16,8],
[72,38,16,9],
[72,38,16,11],
[72,38,16,12],
[72,38,16,13],
[72,38,16,14],
[72,38,16,15],
[72,38,17,0],
[72,38,17,1],
[72,38,17,2],
[72,38,17,3],
[72,38,17,4],
[72,38,17,5],
[72,38,17,8],
[72,38,17,9],
[72,38,17,11],
[72,38,17,12],
[72,38,17,13],
[72,38,17,14],
[72,38,17,15],
[72,38,17,16],
[72,38,18,0],
[72,38,18,1],
[72,38,18,2],
[72,38,18,3],
[72,38,18,4],
[72,38,18,6],
[72,38,18,8],
[72,38,18,11],
[72,38,18,12],
[72,38,18,13],
[72,38,18,14],
[72,38,18,16],
[72,38,18,17],
[72,38,19,0],
[72,38,19,1],
[72,38,19,2],
[72,38,19,5],
[72,38,19,6],
[72,38,19,8],
[72,38,19,9],
[72,38,19,11],
[72,38,19,12],
[72,38,19,15],
[72,38,19,16],
[72,38,19,17],
[72,38,19,18],
[72,38,21,0],
[72,38,21,1],
[72,38,21,2],
[72,38,21,4],
[72,38,21,9],
[72,38,21,11],
[72,38,21,12],
[72,38,21,13],
[72,38,21,14],
[72,38,21,15],
[72,38,21,16],
[72,38,21,17],
[72,38,21,18],
[72,38,21,19],
[72,38,23,0],
[72,38,23,1],
[72,38,23,2],
[72,38,23,3],
[72,38,23,4],
[72,38,23,5],
[72,38,23,6],
[72,38,23,8],
[72,38,23,9],
[72,38,23,11],
[72,38,23,12],
[72,38,23,13],
[72,38,23,14],
[72,38,23,15],
[72,38,23,16],
[72,38,23,17],
[72,38,24,0],
[72,38,24,1],
[72,38,24,2],
[72,38,24,3],
[72,38,24,4],
[72,38,24,5],
[72,38,24,6],
[72,38,24,8],
[72,38,24,9],
[72,38,24,11],
[72,38,24,12],
[72,38,24,13],
[72,38,24,14],
[72,38,24,15],
[72,38,24,17],
[72,38,26,0],
[72,38,26,1],
[72,38,26,2],
[72,38,26,3],
[72,38,26,4],
[72,38,26,5],
[72,38,26,6],
[72,38,26,8],
[72,38,26,11],
[72,38,26,12],
[72,38,26,13],
[72,38,29,0],
[72,38,29,1],
[72,38,29,2],
[72,38,29,3],
[72,38,29,4],
[72,38,29,5],
[72,38,29,6],
[72,38,30,0],
[72,38,30,1],
[72,38,30,2],
[72,38,30,3],
[72,38,30,4],
[72,38,30,5],
[72,38,31,0],
[72,38,31,1],
[72,38,31,2],
[72,38,31,3],
[72,38,31,4],
[72,38,32,0],
[72,38,32,1],
[72,38,32,2],
[72,39,4,0],
[72,39,4,1],
[72,39,4,2],
[72,39,7,0],
[72,39,7,1],
[72,39,7,2],
[72,39,7,4],
[72,39,9,0],
[72,39,9,1],
[72,39,9,2],
[72,39,9,4],
[72,39,9,7],
[72,39,11,0],
[72,39,11,1],
[72,39,11,2],
[72,39,11,4],
[72,39,11,7],
[72,39,11,9],
[72,39,12,0],
[72,39,12,1],
[72,39,12,2],
[72,39,12,4],
[72,39,12,7],
[72,39,12,9],
[72,39,13,0],
[72,39,13,1],
[72,39,13,2],
[72,39,13,4],
[72,39,13,7],
[72,39,13,9],
[72,39,13,12],
[72,39,14,0],
[72,39,14,1],
[72,39,14,2],
[72,39,14,4],
[72,39,14,7],
[72,39,14,11],
[72,39,14,12],
[72,39,15,0],
[72,39,15,1],
[72,39,15,2],
[72,39,15,4],
[72,39,15,11],
[72,39,15,12],
[72,39,15,13],
[72,39,15,14],
[72,39,16,0],
[72,39,16,1],
[72,39,16,2],
[72,39,16,4],
[72,39,16,7],
[72,39,16,9],
[72,39,16,11],
[72,39,16,12],
[72,39,16,13],
[72,39,16,14],
[72,39,16,15],
[72,39,17,0],
[72,39,17,1],
[72,39,17,2],
[72,39,17,4],
[72,39,17,7],
[72,39,17,9],
[72,39,17,11],
[72,39,17,12],
[72,39,17,13],
[72,39,17,14],
[72,39,17,15],
[72,39,17,16],
[72,39,18,0],
[72,39,18,1],
[72,39,18,2],
[72,39,18,7],
[72,39,18,11],
[72,39,18,12],
[72,39,18,13],
[72,39,18,14],
[72,39,18,16],
[72,39,18,17],
[72,39,19,4],
[72,39,19,7],
[72,39,19,9],
[72,39,19,11],
[72,39,19,12],
[72,39,19,15],
[72,39,19,16],
[72,39,19,17],
[72,39,19,18],
[72,39,23,0],
[72,39,23,1],
[72,39,23,2],
[72,39,23,4],
[72,39,23,7],
[72,39,23,9],
[72,39,23,11],
[72,39,23,12],
[72,39,23,13],
[72,39,23,14],
[72,39,23,15],
[72,39,24,0],
[72,39,24,1],
[72,39,24,2],
[72,39,24,4],
[72,39,24,7],
[72,39,24,9],
[72,39,24,11],
[72,39,24,12],
[72,39,24,13],
[72,39,24,14],
[72,39,24,15],
[72,39,25,0],
[72,39,25,1],
[72,39,25,2],
[72,39,25,4],
[72,39,25,9],
[72,39,25,11],
[72,39,25,12],
[72,39,25,13],
[72,39,26,0],
[72,39,26,1],
[72,39,26,2],
[72,39,26,4],
[72,39,26,7],
[72,39,26,11],
[72,39,28,0],
[72,39,28,1],
[72,39,28,2],
[72,39,28,4],
[72,39,29,0],
[72,39,29,1],
[72,39,29,2],
[72,39,29,4],
[72,39,30,0],
[72,39,30,1],
[72,39,30,2],
[72,39,30,4],
[72,39,31,0],
[72,39,31,1],
[72,39,31,2],
[72,40,3,0],
[72,40,3,1],
[72,40,3,2],
[72,40,4,0],
[72,40,4,1],
[72,40,4,2],
[72,40,4,3],
[72,40,5,0],
[72,40,5,1],
[72,40,5,2],
[72,40,5,4],
[72,40,6,0],
[72,40,6,1],
[72,40,6,2],
[72,40,6,4],
[72,40,7,0],
[72,40,7,1],
[72,40,7,2],
[72,40,7,3],
[72,40,7,4],
[72,40,7,5],
[72,40,7,6],
[72,40,8,0],
[72,40,8,1],
[72,40,8,2],
[72,40,8,4],
[72,40,8,7],
[72,40,11,0],
[72,40,11,1],
[72,40,11,2],
[72,40,11,3],
[72,40,11,4],
[72,40,11,5],
[72,40,11,6],
[72,40,11,7],
[72,40,11,8],
[72,40,12,0],
[72,40,12,1],
[72,40,12,2],
[72,40,12,3],
[72,40,12,4],
[72,40,12,5],
[72,40,12,6],
[72,40,12,7],
[72,40,13,0],
[72,40,13,1],
[72,40,13,2],
[72,40,13,3],
[72,40,13,4],
[72,40,13,5],
[72,40,13,6],
[72,40,13,7],
[72,40,13,11],
[72,40,13,12],
[72,40,14,0],
[72,40,14,1],
[72,40,14,2],
[72,40,14,3],
[72,40,14,4],
[72,40,14,5],
[72,40,14,8],
[72,40,14,11],
[72,40,14,12],
[72,40,16,0],
[72,40,16,1],
[72,40,16,2],
[72,40,16,5],
[72,40,16,6],
[72,40,16,7],
[72,40,16,8],
[72,40,16,11],
[72,40,16,12],
[72,40,16,13],
[72,40,16,14],
[72,40,17,0],
[72,40,17,1],
[72,40,17,2],
[72,40,17,5],
[72,40,17,6],
[72,40,17,7],
[72,40,17,8],
[72,40,17,11],
[72,40,17,12],
[72,40,17,13],
[72,40,17,14],
[72,40,17,16],
[72,40,19,0],
[72,40,19,1],
[72,40,19,2],
[72,40,19,3],
[72,40,19,4],
[72,40,19,5],
[72,40,19,6],
[72,40,19,7],
[72,40,19,8],
[72,40,19,11],
[72,40,19,12],
[72,40,19,16],
[72,40,19,17],
[72,40,21,0],
[72,40,21,1],
[72,40,21,2],
[72,40,21,4],
[72,40,21,7],
[72,40,21,11],
[72,40,21,12],
[72,40,21,13],
[72,40,21,14],
[72,40,21,16],
[72,40,21,17],
[72,40,23,0],
[72,40,23,1],
[72,40,23,2],
[72,40,23,3],
[72,40,23,4],
[72,40,23,5],
[72,40,23,6],
[72,40,23,7],
[72,40,23,8],
[72,40,23,11],
[72,40,23,12],
[72,40,23,13],
[72,40,23,14],
[72,40,24,0],
[72,40,24,1],
[72,40,24,2],
[72,40,24,3],
[72,40,24,4],
[72,40,24,5],
[72,40,24,6],
[72,40,24,7],
[72,40,24,8],
[72,40,24,11],
[72,40,24,12],
[72,40,24,13],
[72,40,24,14],
[72,40,25,0],
[72,40,25,1],
[72,40,25,2],
[72,40,25,3],
[72,40,25,4],
[72,40,25,5],
[72,40,25,6],
[72,40,25,8],
[72,40,25,11],
[72,40,28,0],
[72,40,28,1],
[72,40,28,2],
[72,40,28,3],
[72,40,28,4],
[72,40,28,5],
[72,40,29,0],
[72,40,29,1],
[72,40,29,2],
[72,40,29,3],
[72,40,29,4],
[72,40,30,0],
[72,40,30,1],
[72,40,30,2],
[72,41,3,0],
[72,41,3,1],
[72,41,3,2],
[72,41,4,0],
[72,41,4,1],
[72,41,4,2],
[72,41,4,3],
[72,41,5,0],
[72,41,5,1],
[72,41,5,2],
[72,41,5,4],
[72,41,6,0],
[72,41,6,1],
[72,41,6,2],
[72,41,6,4],
[72,41,7,0],
[72,41,7,1],
[72,41,7,2],
[72,41,7,3],
[72,41,7,4],
[72,41,7,5],
[72,41,7,6],
[72,41,8,0],
[72,41,8,1],
[72,41,8,2],
[72,41,8,4],
[72,41,8,7],
[72,41,11,0],
[72,41,11,1],
[72,41,11,2],
[72,41,11,3],
[72,41,11,4],
[72,41,11,5],
[72,41,11,6],
[72,41,11,7],
[72,41,11,8],
[72,41,12,0],
[72,41,12,1],
[72,41,12,2],
[72,41,12,3],
[72,41,12,4],
[72,41,12,5],
[72,41,12,6],
[72,41,12,7],
[72,41,13,0],
[72,41,13,1],
[72,41,13,2],
[72,41,13,3],
[72,41,13,4],
[72,41,13,5],
[72,41,13,6],
[72,41,13,7],
[72,41,13,11],
[72,41,13,12],
[72,41,14,0],
[72,41,14,1],
[72,41,14,2],
[72,41,14,3],
[72,41,14,4],
[72,41,14,5],
[72,41,14,8],
[72,41,14,11],
[72,41,14,12],
[72,41,16,0],
[72,41,16,1],
[72,41,16,2],
[72,41,16,5],
[72,41,16,6],
[72,41,16,7],
[72,41,16,8],
[72,41,16,11],
[72,41,16,12],
[72,41,16,13],
[72,41,16,14],
[72,41,17,0],
[72,41,17,1],
[72,41,17,2],
[72,41,17,5],
[72,41,17,6],
[72,41,17,7],
[72,41,17,8],
[72,41,17,11],
[72,41,17,12],
[72,41,17,13],
[72,41,17,14],
[72,41,17,16],
[72,41,19,0],
[72,41,19,1],
[72,41,19,2],
[72,41,19,3],
[72,41,19,4],
[72,41,19,5],
[72,41,19,6],
[72,41,19,7],
[72,41,19,8],
[72,41,19,11],
[72,41,19,12],
[72,41,19,16],
[72,41,19,17],
[72,41,21,0],
[72,41,21,1],
[72,41,21,2],
[72,41,21,4],
[72,41,21,7],
[72,41,21,11],
[72,41,21,12],
[72,41,21,13],
[72,41,21,14],
[72,41,21,16],
[72,41,21,17],
[72,41,23,0],
[72,41,23,1],
[72,41,23,2],
[72,41,23,3],
[72,41,23,4],
[72,41,23,5],
[72,41,23,6],
[72,41,23,7],
[72,41,23,8],
[72,41,23,11],
[72,41,23,12],
[72,41,23,13],
[72,41,23,14],
[72,41,24,0],
[72,41,24,1],
[72,41,24,2],
[72,41,24,3],
[72,41,24,4],
[72,41,24,5],
[72,41,24,6],
[72,41,24,7],
[72,41,24,8],
[72,41,24,11],
[72,41,24,12],
[72,41,24,13],
[72,41,24,14],
[72,41,25,0],
[72,41,25,1],
[72,41,25,2],
[72,41,25,3],
[72,41,25,4],
[72,41,25,5],
[72,41,25,6],
[72,41,25,8],
[72,41,25,11],
[72,41,28,0],
[72,41,28,1],
[72,41,28,2],
[72,41,28,3],
[72,41,28,4],
[72,41,28,5],
[72,41,29,0],
[72,41,29,1],
[72,41,29,2],
[72,41,29,3],
[72,41,29,4],
[72,41,30,0],
[72,41,30,1],
[72,41,30,2],
[72,43,3,0],
[72,43,3,1],
[72,43,3,2],
[72,43,4,0],
[72,43,4,1],
[72,43,4,2],
[72,43,4,3],
[72,43,5,0],
[72,43,5,1],
[72,43,5,2],
[72,43,5,4],
[72,43,6,0],
[72,43,6,1],
[72,43,6,2],
[72,43,6,4],
[72,43,8,0],
[72,43,8,1],
[72,43,8,2],
[72,43,8,4],
[72,43,9,0],
[72,43,9,1],
[72,43,9,2],
[72,43,9,3],
[72,43,9,4],
[72,43,9,5],
[72,43,9,6],
[72,43,11,0],
[72,43,11,1],
[72,43,11,2],
[72,43,11,3],
[72,43,11,4],
[72,43,11,5],
[72,43,11,8],
[72,43,11,9],
[72,43,12,0],
[72,43,12,1],
[72,43,12,2],
[72,43,12,3],
[72,43,12,4],
[72,43,12,6],
[72,43,12,8],
[72,43,12,9],
[72,43,13,0],
[72,43,13,1],
[72,43,13,2],
[72,43,13,3],
[72,43,13,4],
[72,43,13,6],
[72,43,13,8],
[72,43,13,9],
[72,43,13,11],
[72,43,13,12],
[72,43,14,0],
[72,43,14,1],
[72,43,14,2],
[72,43,14,5],
[72,43,14,6],
[72,43,14,8],
[72,43,14,9],
[72,43,14,11],
[72,43,14,12],
[72,43,15,3],
[72,43,15,4],
[72,43,15,5],
[72,43,15,6],
[72,43,15,8],
[72,43,15,11],
[72,43,15,12],
[72,43,15,13],
[72,43,15,14],
[72,43,16,0],
[72,43,16,1],
[72,43,16,2],
[72,43,16,3],
[72,43,16,4],
[72,43,16,5],
[72,43,16,6],
[72,43,16,8],
[72,43,16,9],
[72,43,16,11],
[72,43,16,12],
[72,43,16,13],
[72,43,16,14],
[72,43,16,15],
[72,43,17,0],
[72,43,17,1],
[72,43,17,2],
[72,43,17,3],
[72,43,17,4],
[72,43,17,5],
[72,43,17,6],
[72,43,17,8],
[72,43,17,9],
[72,43,17,11],
[72,43,17,12],
[72,43,17,13],
[72,43,17,14],
[72,43,17,15],
[72,43,17,16],
[72,43,18,0],
[72,43,18,1],
[72,43,18,2],
[72,43,18,3],
[72,43,18,4],
[72,43,18,5],
[72,43,18,6],
[72,43,18,8],
[72,43,18,11],
[72,43,18,12],
[72,43,18,13],
[72,43,18,14],
[72,43,18,16],
[72,43,18,17],
[72,43,19,0],
[72,43,19,1],
[72,43,19,2],
[72,43,19,3],
[72,43,19,4],
[72,43,19,5],
[72,43,19,6],
[72,43,19,8],
[72,43,19,9],
[72,43,19,11],
[72,43,19,12],
[72,43,19,15],
[72,43,19,16],
[72,43,19,17],
[72,43,21,0],
[72,43,21,1],
[72,43,21,2],
[72,43,21,4],
[72,43,21,9],
[72,43,21,11],
[72,43,21,12],
[72,43,21,13],
[72,43,21,14],
[72,43,23,0],
[72,43,23,1],
[72,43,23,2],
[72,43,23,3],
[72,43,23,4],
[72,43,23,5],
[72,43,23,6],
[72,43,23,8],
[72,43,23,9],
[72,43,23,11],
[72,43,24,0],
[72,43,24,1],
[72,43,24,2],
[72,43,24,3],
[72,43,24,4],
[72,43,24,5],
[72,43,24,6],
[72,43,24,8],
[72,43,24,9],
[72,43,24,11],
[72,43,26,0],
[72,43,26,1],
[72,43,26,2],
[72,43,26,3],
[72,43,26,4],
[72,43,26,5],
[72,44,3,0],
[72,44,3,1],
[72,44,3,2],
[72,44,4,0],
[72,44,4,1],
[72,44,4,2],
[72,44,4,3],
[72,44,5,0],
[72,44,5,1],
[72,44,5,2],
[72,44,5,4],
[72,44,6,0],
[72,44,6,1],
[72,44,6,2],
[72,44,6,4],
[72,44,7,0],
[72,44,7,1],
[72,44,7,2],
[72,44,7,3],
[72,44,7,4],
[72,44,7,5],
[72,44,7,6],
[72,44,8,0],
[72,44,8,1],
[72,44,8,2],
[72,44,8,4],
[72,44,9,0],
[72,44,9,1],
[72,44,9,2],
[72,44,9,3],
[72,44,9,4],
[72,44,9,5],
[72,44,9,8],
[72,44,11,0],
[72,44,11,1],
[72,44,11,2],
[72,44,11,3],
[72,44,11,4],
[72,44,11,6],
[72,44,11,7],
[72,44,11,8],
[72,44,11,9],
[72,44,12,0],
[72,44,12,1],
[72,44,12,2],
[72,44,12,5],
[72,44,12,6],
[72,44,12,7],
[72,44,12,8],
[72,44,12,9],
[72,44,13,0],
[72,44,13,1],
[72,44,13,2],
[72,44,13,5],
[72,44,13,6],
[72,44,13,7],
[72,44,13,8],
[72,44,13,9],
[72,44,13,11],
[72,44,13,12],
[72,44,14,3],
[72,44,14,4],
[72,44,14,5],
[72,44,14,6],
[72,44,14,7],
[72,44,14,8],
[72,44,14,9],
[72,44,14,11],
[72,44,14,12],
[72,44,15,0],
[72,44,15,1],
[72,44,15,2],
[72,44,15,3],
[72,44,15,4],
[72,44,15,5],
[72,44,15,6],
[72,44,15,7],
[72,44,15,8],
[72,44,15,11],
[72,44,15,12],
[72,44,15,13],
[72,44,15,14],
[72,44,16,0],
[72,44,16,1],
[72,44,16,2],
[72,44,16,3],
[72,44,16,4],
[72,44,16,5],
[72,44,16,6],
[72,44,16,7],
[72,44,16,8],
[72,44,16,9],
[72,44,16,11],
[72,44,16,12],
[72,44,16,13],
[72,44,16,14],
[72,44,16,15],
[72,44,18,0],
[72,44,18,1],
[72,44,18,2],
[72,44,18,3],
[72,44,18,4],
[72,44,18,5],
[72,44,18,6],
[72,44,18,7],
[72,44,18,8],
[72,44,18,11],
[72,44,18,12],
[72,44,18,13],
[72,44,18,14],
[72,44,18,16],
[72,44,19,0],
[72,44,19,1],
[72,44,19,2],
[72,44,19,3],
[72,44,19,4],
[72,44,19,5],
[72,44,19,6],
[72,44,19,7],
[72,44,19,8],
[72,44,19,9],
[72,44,19,11],
[72,44,19,12],
[72,44,19,15],
[72,44,21,0],
[72,44,21,1],
[72,44,21,2],
[72,44,21,4],
[72,44,21,7],
[72,44,21,9],
[72,44,21,11],
[72,44,21,12],
[72,44,21,13],
[72,44,23,0],
[72,44,23,1],
[72,44,23,2],
[72,44,23,3],
[72,44,23,4],
[72,44,23,5],
[72,44,23,6],
[72,44,23,7],
[72,44,23,8],
[72,44,23,9],
[72,44,24,0],
[72,44,24,1],
[72,44,24,2],
[72,44,24,3],
[72,44,24,4],
[72,44,24,5],
[72,44,24,6],
[72,44,24,7],
[72,44,24,8],
[72,44,24,9],
[72,44,25,0],
[72,44,25,1],
[72,44,25,2],
[72,44,25,3],
[72,44,25,4],
[72,44,25,5],
[72,44,26,0],
[72,44,26,1],
[72,44,26,2],
[72,44,26,3],
[72,44,26,4],
[72,45,3,0],
[72,45,3,1],
[72,45,3,2],
[72,45,4,0],
[72,45,4,1],
[72,45,4,2],
[72,45,4,3],
[72,45,5,0],
[72,45,5,1],
[72,45,5,2],
[72,45,5,4],
[72,45,6,0],
[72,45,6,1],
[72,45,6,2],
[72,45,6,4],
[72,45,7,0],
[72,45,7,1],
[72,45,7,2],
[72,45,7,3],
[72,45,7,4],
[72,45,7,5],
[72,45,8,0],
[72,45,8,1],
[72,45,8,2],
[72,45,8,4],
[72,45,8,7],
[72,45,11,0],
[72,45,11,1],
[72,45,11,2],
[72,45,11,5],
[72,45,11,6],
[72,45,11,7],
[72,45,11,8],
[72,45,12,3],
[72,45,12,4],
[72,45,12,5],
[72,45,12,6],
[72,45,12,7],
[72,45,12,8],
[72,45,13,3],
[72,45,13,4],
[72,45,13,5],
[72,45,13,6],
[72,45,13,7],
[72,45,13,8],
[72,45,13,11],
[72,45,13,12],
[72,45,14,0],
[72,45,14,1],
[72,45,14,2],
[72,45,14,3],
[72,45,14,4],
[72,45,14,5],
[72,45,14,6],
[72,45,14,7],
[72,45,14,8],
[72,45,14,11],
[72,45,14,12],
[72,45,16,0],
[72,45,16,1],
[72,45,16,2],
[72,45,16,3],
[72,45,16,4],
[72,45,16,5],
[72,45,16,6],
[72,45,16,7],
[72,45,16,8],
[72,45,16,11],
[72,45,16,12],
[72,45,16,13],
[72,45,16,14],
[72,45,17,0],
[72,45,17,1],
[72,45,17,2],
[72,45,17,3],
[72,45,17,4],
[72,45,17,5],
[72,45,17,6],
[72,45,17,7],
[72,45,17,8],
[72,45,17,11],
[72,45,17,12],
[72,45,17,13],
[72,45,17,14],
[72,45,17,16],
[72,45,19,0],
[72,45,19,1],
[72,45,19,2],
[72,45,19,3],
[72,45,19,4],
[72,45,19,5],
[72,45,19,6],
[72,45,19,7],
[72,45,19,8],
[72,45,19,11],
[72,45,19,12],
[72,45,21,0],
[72,45,21,1],
[72,45,21,2],
[72,45,21,4],
[72,45,21,7],
[72,45,21,11],
[72,45,23,0],
[72,45,23,1],
[72,45,23,2],
[72,45,23,3],
[72,45,23,4],
[72,45,23,5],
[72,45,23,6],
[72,45,23,7],
[72,45,24,0],
[72,45,24,1],
[72,45,24,2],
[72,45,24,3],
[72,45,24,4],
[72,45,24,5],
[72,45,24,6],
[72,45,24,7],
[72,45,25,0],
[72,45,25,1],
[72,45,25,2],
[72,45,25,3],
[72,45,25,4],
[72,46,3,0],
[72,46,3,1],
[72,46,3,2],
[72,46,4,0],
[72,46,4,1],
[72,46,4,2],
[72,46,4,3],
[72,46,5,0],
[72,46,5,1],
[72,46,5,2],
[72,46,5,4],
[72,46,6,0],
[72,46,6,1],
[72,46,6,2],
[72,46,6,4],
[72,46,7,0],
[72,46,7,1],
[72,46,7,2],
[72,46,7,3],
[72,46,7,4],
[72,46,7,6],
[72,46,8,0],
[72,46,8,1],
[72,46,8,2],
[72,46,8,7],
[72,46,11,3],
[72,46,11,4],
[72,46,11,5],
[72,46,11,6],
[72,46,11,7],
[72,46,11,8],
[72,46,12,0],
[72,46,12,1],
[72,46,12,2],
[72,46,12,3],
[72,46,12,4],
[72,46,12,5],
[72,46,12,6],
[72,46,12,7],
[72,46,12,8],
[72,46,13,0],
[72,46,13,1],
[72,46,13,2],
[72,46,13,3],
[72,46,13,4],
[72,46,13,5],
[72,46,13,6],
[72,46,13,7],
[72,46,13,8],
[72,46,13,11],
[72,46,13,12],
[72,46,14,0],
[72,46,14,1],
[72,46,14,2],
[72,46,14,3],
[72,46,14,4],
[72,46,14,5],
[72,46,14,6],
[72,46,14,7],
[72,46,14,8],
[72,46,14,11],
[72,46,14,12],
[72,46,16,0],
[72,46,16,1],
[72,46,16,2],
[72,46,16,3],
[72,46,16,4],
[72,46,16,5],
[72,46,16,6],
[72,46,16,7],
[72,46,16,8],
[72,46,16,11],
[72,46,16,12],
[72,46,16,13],
[72,46,16,14],
[72,46,17,0],
[72,46,17,1],
[72,46,17,2],
[72,46,17,3],
[72,46,17,4],
[72,46,17,5],
[72,46,17,6],
[72,46,17,7],
[72,46,17,8],
[72,46,17,11],
[72,46,17,12],
[72,46,17,13],
[72,46,17,14],
[72,46,19,0],
[72,46,19,1],
[72,46,19,2],
[72,46,19,3],
[72,46,19,4],
[72,46,19,5],
[72,46,19,6],
[72,46,19,7],
[72,46,19,8],
[72,46,19,11],
[72,46,19,12],
[72,46,21,0],
[72,46,21,1],
[72,46,21,2],
[72,46,21,4],
[72,46,21,7],
[72,46,23,0],
[72,46,23,1],
[72,46,23,2],
[72,46,23,3],
[72,46,23,4],
[72,46,23,5],
[72,46,24,0],
[72,46,24,1],
[72,46,24,2],
[72,46,24,3],
[72,46,24,4],
[72,46,24,5],
[72,46,25,0],
[72,46,25,1],
[72,46,25,2],
[72,47,3,0],
[72,47,3,1],
[72,47,3,2],
[72,47,4,0],
[72,47,4,1],
[72,47,4,2],
[72,47,4,3],
[72,47,5,0],
[72,47,5,1],
[72,47,5,2],
[72,47,5,4],
[72,47,6,0],
[72,47,6,1],
[72,47,6,2],
[72,47,7,0],
[72,47,7,1],
[72,47,7,2],
[72,47,7,5],
[72,47,7,6],
[72,47,8,4],
[72,47,8,7],
[72,47,9,3],
[72,47,9,4],
[72,47,9,5],
[72,47,9,6],
[72,47,9,7],
[72,47,9,8],
[72,47,11,0],
[72,47,11,1],
[72,47,11,2],
[72,47,11,3],
[72,47,11,4],
[72,47,11,5],
[72,47,11,6],
[72,47,11,7],
[72,47,11,8],
[72,47,11,9],
[72,47,12,0],
[72,47,12,1],
[72,47,12,2],
[72,47,12,3],
[72,47,12,4],
[72,47,12,5],
[72,47,12,6],
[72,47,12,7],
[72,47,12,8],
[72,47,12,9],
[72,47,13,0],
[72,47,13,1],
[72,47,13,2],
[72,47,13,3],
[72,47,13,4],
[72,47,13,5],
[72,47,13,6],
[72,47,13,7],
[72,47,13,8],
[72,47,13,9],
[72,47,13,11],
[72,47,13,12],
[72,47,14,0],
[72,47,14,1],
[72,47,14,2],
[72,47,14,3],
[72,47,14,4],
[72,47,14,5],
[72,47,14,6],
[72,47,14,7],
[72,47,14,8],
[72,47,14,9],
[72,47,14,11],
[72,47,14,12],
[72,47,15,0],
[72,47,15,1],
[72,47,15,2],
[72,47,15,3],
[72,47,15,4],
[72,47,15,5],
[72,47,15,6],
[72,47,15,7],
[72,47,15,8],
[72,47,15,11],
[72,47,15,12],
[72,47,15,13],
[72,47,15,14],
[72,47,16,0],
[72,47,16,1],
[72,47,16,2],
[72,47,16,3],
[72,47,16,4],
[72,47,16,5],
[72,47,16,6],
[72,47,16,7],
[72,47,16,8],
[72,47,16,9],
[72,47,16,11],
[72,47,16,12],
[72,47,16,13],
[72,47,16,14],
[72,47,17,0],
[72,47,17,1],
[72,47,17,2],
[72,47,17,3],
[72,47,17,4],
[72,47,17,5],
[72,47,17,6],
[72,47,17,7],
[72,47,17,8],
[72,47,17,9],
[72,47,17,11],
[72,47,17,12],
[72,47,17,13],
[72,47,17,14],
[72,47,18,0],
[72,47,18,1],
[72,47,18,2],
[72,47,18,3],
[72,47,18,4],
[72,47,18,5],
[72,47,18,6],
[72,47,18,7],
[72,47,18,8],
[72,47,18,11],
[72,47,18,12],
[72,47,18,13],
[72,47,19,0],
[72,47,19,1],
[72,47,19,2],
[72,47,19,3],
[72,47,19,4],
[72,47,19,5],
[72,47,19,6],
[72,47,19,7],
[72,47,19,8],
[72,47,19,9],
[72,47,19,11],
[72,47,21,0],
[72,47,21,1],
[72,47,21,2],
[72,47,21,4],
[72,47,21,7],
[72,47,24,0],
[72,47,24,1],
[72,47,24,2],
[72,47,24,3],
[72,47,24,4],
[72,48,3,0],
[72,48,3,1],
[72,48,3,2],
[72,48,4,0],
[72,48,4,1],
[72,48,4,2],
[72,48,4,3],
[72,48,5,0],
[72,48,5,1],
[72,48,5,2],
[72,48,6,4],
[72,48,7,3],
[72,48,7,4],
[72,48,7,5],
[72,48,7,6],
[72,48,8,0],
[72,48,8,1],
[72,48,8,2],
[72,48,8,4],
[72,48,8,7],
[72,48,9,0],
[72,48,9,1],
[72,48,9,2],
[72,48,9,3],
[72,48,9,4],
[72,48,9,5],
[72,48,9,6],
[72,48,9,7],
[72,48,9,8],
[72,48,13,0],
[72,48,13,1],
[72,48,13,2],
[72,48,13,3],
[72,48,13,4],
[72,48,13,5],
[72,48,13,6],
[72,48,13,7],
[72,48,13,8],
[72,48,13,9],
[72,48,14,0],
[72,48,14,1],
[72,48,14,2],
[72,48,14,3],
[72,48,14,4],
[72,48,14,5],
[72,48,14,6],
[72,48,14,7],
[72,48,14,8],
[72,48,14,9],
[72,48,15,0],
[72,48,15,1],
[72,48,15,2],
[72,48,15,3],
[72,48,15,4],
[72,48,15,5],
[72,48,15,6],
[72,48,15,7],
[72,48,15,8],
[72,48,15,13],
[72,48,15,14],
[72,48,16,0],
[72,48,16,1],
[72,48,16,2],
[72,48,16,3],
[72,48,16,4],
[72,48,16,5],
[72,48,16,6],
[72,48,16,7],
[72,48,16,8],
[72,48,16,9],
[72,48,16,13],
[72,48,17,0],
[72,48,17,1],
[72,48,17,2],
[72,48,17,3],
[72,48,17,4],
[72,48,17,5],
[72,48,17,6],
[72,48,17,7],
[72,48,17,8],
[72,48,17,9],
[72,48,17,13],
[72,48,18,0],
[72,48,18,1],
[72,48,18,2],
[72,48,18,3],
[72,48,18,4],
[72,48,18,5],
[72,48,18,6],
[72,48,18,7],
[72,48,18,8],
[72,48,19,0],
[72,48,19,1],
[72,48,19,2],
[72,48,19,3],
[72,48,19,4],
[72,48,19,5],
[72,48,19,6],
[72,48,19,7],
[72,48,19,8],
[72,48,19,9],
[72,48,21,0],
[72,48,21,1],
[72,48,21,2],
[72,48,21,4],
[72,48,23,0],
[72,48,23,1],
[72,48,23,2],
[72,48,24,0],
[72,48,24,1],
[72,48,24,2],
[72,49,3,0],
[72,49,3,1],
[72,49,3,2],
[72,49,4,0],
[72,49,4,1],
[72,49,4,2],
[72,49,4,3],
[72,49,5,0],
[72,49,5,1],
[72,49,5,2],
[72,49,6,4],
[72,49,7,3],
[72,49,7,4],
[72,49,7,5],
[72,49,7,6],
[72,49,8,0],
[72,49,8,1],
[72,49,8,2],
[72,49,8,4],
[72,49,8,7],
[72,49,11,0],
[72,49,11,1],
[72,49,11,2],
[72,49,11,3],
[72,49,11,4],
[72,49,11,5],
[72,49,11,6],
[72,49,11,7],
[72,49,11,8],
[72,49,12,0],
[72,49,12,1],
[72,49,12,2],
[72,49,12,3],
[72,49,12,4],
[72,49,12,5],
[72,49,12,6],
[72,49,12,7],
[72,49,12,8],
[72,49,13,0],
[72,49,13,1],
[72,49,13,2],
[72,49,13,3],
[72,49,13,4],
[72,49,13,5],
[72,49,13,6],
[72,49,13,7],
[72,49,13,8],
[72,49,13,11],
[72,49,13,12],
[72,49,14,0],
[72,49,14,1],
[72,49,14,2],
[72,49,14,3],
[72,49,14,4],
[72,49,14,5],
[72,49,14,6],
[72,49,14,7],
[72,49,14,8],
[72,49,14,11],
[72,49,14,12],
[72,49,16,0],
[72,49,16,1],
[72,49,16,2],
[72,49,16,3],
[72,49,16,4],
[72,49,16,5],
[72,49,16,6],
[72,49,16,7],
[72,49,16,8],
[72,49,16,11],
[72,49,16,12],
[72,49,16,13],
[72,49,17,0],
[72,49,17,1],
[72,49,17,2],
[72,49,17,3],
[72,49,17,4],
[72,49,17,5],
[72,49,17,6],
[72,49,17,7],
[72,49,17,8],
[72,49,17,11],
[72,49,17,12],
[72,49,17,13],
[72,49,19,0],
[72,49,19,1],
[72,49,19,2],
[72,49,19,3],
[72,49,19,4],
[72,49,19,5],
[72,49,19,6],
[72,49,19,7],
[72,49,19,8],
[72,49,21,0],
[72,49,21,1],
[72,49,21,2],
[72,49,21,4],
[72,49,23,0],
[72,49,23,1],
[72,49,23,2],
[72,49,24,0],
[72,49,24,1],
[72,49,24,2],
[72,50,3,0],
[72,50,3,1],
[72,50,3,2],
[72,50,4,0],
[72,50,4,1],
[72,50,4,2],
[72,50,4,3],
[72,50,5,0],
[72,50,5,1],
[72,50,5,2],
[72,50,6,4],
[72,50,7,3],
[72,50,7,4],
[72,50,7,5],
[72,50,7,6],
[72,50,8,0],
[72,50,8,1],
[72,50,8,2],
[72,50,8,4],
[72,50,8,7],
[72,50,11,0],
[72,50,11,1],
[72,50,11,2],
[72,50,11,3],
[72,50,11,4],
[72,50,11,5],
[72,50,11,6],
[72,50,11,7],
[72,50,11,8],
[72,50,12,0],
[72,50,12,1],
[72,50,12,2],
[72,50,12,3],
[72,50,12,4],
[72,50,12,5],
[72,50,12,6],
[72,50,12,7],
[72,50,12,8],
[72,50,13,0],
[72,50,13,1],
[72,50,13,2],
[72,50,13,3],
[72,50,13,4],
[72,50,13,5],
[72,50,13,6],
[72,50,13,7],
[72,50,13,8],
[72,50,13,11],
[72,50,13,12],
[72,50,14,0],
[72,50,14,1],
[72,50,14,2],
[72,50,14,3],
[72,50,14,4],
[72,50,14,5],
[72,50,14,6],
[72,50,14,7],
[72,50,14,8],
[72,50,14,11],
[72,50,14,12],
[72,50,16,0],
[72,50,16,1],
[72,50,16,2],
[72,50,16,3],
[72,50,16,4],
[72,50,16,5],
[72,50,16,6],
[72,50,16,7],
[72,50,16,8],
[72,50,16,11],
[72,50,16,12],
[72,50,16,13],
[72,50,17,0],
[72,50,17,1],
[72,50,17,2],
[72,50,17,3],
[72,50,17,4],
[72,50,17,5],
[72,50,17,6],
[72,50,17,7],
[72,50,17,8],
[72,50,17,11],
[72,50,17,12],
[72,50,17,13],
[72,50,19,0],
[72,50,19,1],
[72,50,19,2],
[72,50,19,3],
[72,50,19,4],
[72,50,19,5],
[72,50,19,6],
[72,50,19,7],
[72,50,19,8],
[72,50,21,0],
[72,50,21,1],
[72,50,21,2],
[72,50,21,4],
[72,50,23,0],
[72,50,23,1],
[72,50,23,2],
[72,50,24,0],
[72,50,24,1],
[72,50,24,2],
[72,51,4,0],
[72,51,4,1],
[72,51,4,2],
[72,51,7,4],
[72,51,9,0],
[72,51,9,1],
[72,51,9,2],
[72,51,9,4],
[72,51,9,7],
[72,51,11,0],
[72,51,11,1],
[72,51,11,2],
[72,51,11,4],
[72,51,11,7],
[72,51,11,9],
[72,51,12,0],
[72,51,12,1],
[72,51,12,2],
[72,51,12,4],
[72,51,12,7],
[72,51,12,9],
[72,51,13,0],
[72,51,13,1],
[72,51,13,2],
[72,51,13,4],
[72,51,13,7],
[72,51,13,9],
[72,51,13,11],
[72,51,13,12],
[72,51,14,0],
[72,51,14,1],
[72,51,14,2],
[72,51,14,4],
[72,51,14,7],
[72,51,14,9],
[72,51,14,11],
[72,51,14,12],
[72,51,15,0],
[72,51,15,1],
[72,51,15,2],
[72,51,15,4],
[72,51,15,7],
[72,51,15,11],
[72,51,15,12],
[72,51,15,13],
[72,51,15,14],
[72,51,16,0],
[72,51,16,1],
[72,51,16,2],
[72,51,16,4],
[72,51,16,7],
[72,51,16,9],
[72,51,16,11],
[72,51,16,12],
[72,51,16,13],
[72,51,17,0],
[72,51,17,1],
[72,51,17,2],
[72,51,17,4],
[72,51,17,7],
[72,51,17,9],
[72,51,17,11],
[72,51,17,12],
[72,51,17,13],
[72,51,18,0],
[72,51,18,1],
[72,51,18,2],
[72,51,18,4],
[72,51,18,7],
[72,51,18,11],
[72,51,19,0],
[72,51,19,1],
[72,51,19,2],
[72,51,19,4],
[72,51,19,7],
[72,51,19,9],
[72,51,23,0],
[72,51,23,1],
[72,51,23,2],
[72,51,24,0],
[72,51,24,1],
[72,51,24,2],
[72,52,3,0],
[72,52,3,1],
[72,52,3,2],
[72,52,4,0],
[72,52,4,1],
[72,52,4,2],
[72,52,4,3],
[72,52,5,0],
[72,52,5,1],
[72,52,5,2],
[72,52,6,4],
[72,52,7,3],
[72,52,7,4],
[72,52,7,5],
[72,52,7,6],
[72,52,8,0],
[72,52,8,1],
[72,52,8,2],
[72,52,8,4],
[72,52,8,7],
[72,52,11,0],
[72,52,11,1],
[72,52,11,2],
[72,52,11,3],
[72,52,11,4],
[72,52,11,5],
[72,52,11,6],
[72,52,11,7],
[72,52,11,8],
[72,52,12,0],
[72,52,12,1],
[72,52,12,2],
[72,52,12,3],
[72,52,12,4],
[72,52,12,5],
[72,52,12,6],
[72,52,12,7],
[72,52,12,8],
[72,52,13,0],
[72,52,13,1],
[72,52,13,2],
[72,52,13,3],
[72,52,13,4],
[72,52,13,5],
[72,52,13,6],
[72,52,13,7],
[72,52,13,8],
[72,52,13,11],
[72,52,13,12],
[72,52,14,0],
[72,52,14,1],
[72,52,14,2],
[72,52,14,3],
[72,52,14,4],
[72,52,14,5],
[72,52,14,6],
[72,52,14,7],
[72,52,14,8],
[72,52,14,11],
[72,52,14,12],
[72,52,16,0],
[72,52,16,1],
[72,52,16,2],
[72,52,16,3],
[72,52,16,4],
[72,52,16,5],
[72,52,16,6],
[72,52,16,7],
[72,52,16,8],
[72,52,16,11],
[72,52,16,12],
[72,52,16,13],
[72,52,17,0],
[72,52,17,1],
[72,52,17,2],
[72,52,17,3],
[72,52,17,4],
[72,52,17,5],
[72,52,17,6],
[72,52,17,7],
[72,52,17,8],
[72,52,17,11],
[72,52,17,12],
[72,52,17,13],
[72,52,19,0],
[72,52,19,1],
[72,52,19,2],
[72,52,19,3],
[72,52,19,4],
[72,52,19,5],
[72,52,19,6],
[72,52,19,7],
[72,52,19,8],
[72,52,21,0],
[72,52,21,1],
[72,52,21,2],
[72,52,21,4],
[72,52,23,0],
[72,52,23,1],
[72,52,23,2],
[72,52,24,0],
[72,52,24,1],
[72,52,24,2],
[72,56,3,0],
[72,56,3,1],
[72,56,3,2],
[72,56,4,0],
[72,56,4,1],
[72,56,4,2],
[72,56,4,3],
[72,56,5,0],
[72,56,5,1],
[72,56,5,2],
[72,56,6,4],
[72,56,7,3],
[72,56,7,4],
[72,56,7,5],
[72,56,7,6],
[72,56,8,0],
[72,56,8,1],
[72,56,8,2],
[72,56,8,4],
[72,56,8,7],
[72,56,11,0],
[72,56,11,1],
[72,56,11,2],
[72,56,11,3],
[72,56,11,4],
[72,56,11,5],
[72,56,11,6],
[72,56,11,7],
[72,56,11,8],
[72,56,12,0],
[72,56,12,1],
[72,56,12,2],
[72,56,12,3],
[72,56,12,4],
[72,56,12,5],
[72,56,12,6],
[72,56,12,7],
[72,56,12,8],
[72,56,13,0],
[72,56,13,1],
[72,56,13,2],
[72,56,13,3],
[72,56,13,4],
[72,56,13,5],
[72,56,13,6],
[72,56,13,7],
[72,56,13,8],
[72,56,13,11],
[72,56,13,12],
[72,56,14,0],
[72,56,14,1],
[72,56,14,2],
[72,56,14,3],
[72,56,14,4],
[72,56,14,5],
[72,56,14,6],
[72,56,14,7],
[72,56,14,8],
[72,56,14,11],
[72,56,14,12],
[72,56,16,0],
[72,56,16,1],
[72,56,16,2],
[72,56,16,3],
[72,56,16,4],
[72,56,16,5],
[72,56,16,6],
[72,56,16,7],
[72,56,16,8],
[72,56,16,11],
[72,56,16,12],
[72,56,16,13],
[72,56,17,0],
[72,56,17,1],
[72,56,17,2],
[72,56,17,3],
[72,56,17,4],
[72,56,17,5],
[72,56,17,6],
[72,56,17,7],
[72,56,17,8],
[72,56,17,11],
[72,56,17,12],
[72,56,17,13],
[72,56,19,0],
[72,56,19,1],
[72,56,19,2],
[72,56,19,3],
[72,56,19,4],
[72,56,19,5],
[72,56,19,6],
[72,56,19,7],
[72,56,19,8],
[72,56,21,0],
[72,56,21,1],
[72,56,21,2],
[72,56,21,4],
[72,56,23,0],
[72,56,23,1],
[72,56,23,2],
[72,56,24,0],
[72,56,24,1],
[72,56,24,2],
[72,57,3,0],
[72,57,3,1],
[72,57,3,2],
[72,57,4,0],
[72,57,4,1],
[72,57,4,2],
[72,57,5,4],
[72,57,6,0],
[72,57,6,1],
[72,57,6,2],
[72,57,6,4],
[72,57,7,0],
[72,57,7,1],
[72,57,7,2],
[72,57,7,3],
[72,57,7,4],
[72,57,7,5],
[72,57,7,6],
[72,57,8,0],
[72,57,8,1],
[72,57,8,2],
[72,57,8,4],
[72,57,8,7],
[72,57,9,0],
[72,57,9,1],
[72,57,9,2],
[72,57,9,3],
[72,57,9,4],
[72,57,9,5],
[72,57,9,6],
[72,57,9,7],
[72,57,9,8],
[72,57,11,0],
[72,57,11,1],
[72,57,11,2],
[72,57,11,3],
[72,57,11,4],
[72,57,11,5],
[72,57,11,6],
[72,57,11,7],
[72,57,11,8],
[72,57,11,9],
[72,57,12,0],
[72,57,12,1],
[72,57,12,2],
[72,57,12,3],
[72,57,12,4],
[72,57,12,5],
[72,57,12,6],
[72,57,12,7],
[72,57,12,8],
[72,57,12,9],
[72,57,13,0],
[72,57,13,1],
[72,57,13,2],
[72,57,13,3],
[72,57,13,4],
[72,57,13,5],
[72,57,13,6],
[72,57,13,7],
[72,57,13,8],
[72,57,13,9],
[72,57,13,11],
[72,57,13,12],
[72,57,14,0],
[72,57,14,1],
[72,57,14,2],
[72,57,14,3],
[72,57,14,4],
[72,57,14,5],
[72,57,14,6],
[72,57,14,7],
[72,57,14,8],
[72,57,14,9],
[72,57,14,11],
[72,57,14,12],
[72,57,15,0],
[72,57,15,1],
[72,57,15,2],
[72,57,15,3],
[72,57,15,4],
[72,57,15,5],
[72,57,15,6],
[72,57,15,7],
[72,57,15,8],
[72,57,15,11],
[72,57,15,12],
[72,57,15,13],
[72,57,16,0],
[72,57,16,1],
[72,57,16,2],
[72,57,16,3],
[72,57,16,4],
[72,57,16,5],
[72,57,16,6],
[72,57,16,7],
[72,57,16,8],
[72,57,16,9],
[72,57,16,11],
[72,57,17,0],
[72,57,17,1],
[72,57,17,2],
[72,57,17,3],
[72,57,17,4],
[72,57,17,5],
[72,57,17,6],
[72,57,17,7],
[72,57,17,8],
[72,57,17,9],
[72,57,17,11],
[72,57,18,0],
[72,57,18,1],
[72,57,18,2],
[72,57,18,3],
[72,57,18,4],
[72,57,18,5],
[72,57,18,6],
[72,57,18,7],
[72,57,18,8],
[72,57,19,0],
[72,57,19,1],
[72,57,19,2],
[72,57,19,3],
[72,57,19,4],
[72,57,19,5],
[72,57,19,6],
[72,57,19,7],
[72,57,21,0],
[72,57,21,1],
[72,57,21,2],
[72,57,21,4],
[72,58,3,0],
[72,58,3,1],
[72,58,3,2],
[72,58,4,0],
[72,58,4,1],
[72,58,4,2],
[72,58,5,4],
[72,58,6,0],
[72,58,6,1],
[72,58,6,2],
[72,58,6,4],
[72,58,7,0],
[72,58,7,1],
[72,58,7,2],
[72,58,7,3],
[72,58,7,4],
[72,58,7,5],
[72,58,7,6],
[72,58,8,0],
[72,58,8,1],
[72,58,8,2],
[72,58,8,4],
[72,58,8,7],
[72,58,9,0],
[72,58,9,1],
[72,58,9,2],
[72,58,9,3],
[72,58,9,4],
[72,58,9,5],
[72,58,9,6],
[72,58,9,7],
[72,58,9,8],
[72,58,11,0],
[72,58,11,1],
[72,58,11,2],
[72,58,11,3],
[72,58,11,4],
[72,58,11,5],
[72,58,11,6],
[72,58,11,7],
[72,58,11,8],
[72,58,11,9],
[72,58,12,0],
[72,58,12,1],
[72,58,12,2],
[72,58,12,3],
[72,58,12,4],
[72,58,12,5],
[72,58,12,6],
[72,58,12,7],
[72,58,12,8],
[72,58,12,9],
[72,58,13,0],
[72,58,13,1],
[72,58,13,2],
[72,58,13,3],
[72,58,13,4],
[72,58,13,5],
[72,58,13,6],
[72,58,13,7],
[72,58,13,8],
[72,58,13,9],
[72,58,13,11],
[72,58,13,12],
[72,58,14,0],
[72,58,14,1],
[72,58,14,2],
[72,58,14,3],
[72,58,14,4],
[72,58,14,5],
[72,58,14,6],
[72,58,14,7],
[72,58,14,8],
[72,58,14,9],
[72,58,14,11],
[72,58,14,12],
[72,58,15,0],
[72,58,15,1],
[72,58,15,2],
[72,58,15,3],
[72,58,15,4],
[72,58,15,5],
[72,58,15,6],
[72,58,15,7],
[72,58,15,8],
[72,58,15,11],
[72,58,15,12],
[72,58,15,13],
[72,58,17,0],
[72,58,17,1],
[72,58,17,2],
[72,58,17,3],
[72,58,17,4],
[72,58,17,5],
[72,58,17,6],
[72,58,17,7],
[72,58,17,8],
[72,58,17,9],
[72,58,17,11],
[72,58,18,0],
[72,58,18,1],
[72,58,18,2],
[72,58,18,3],
[72,58,18,4],
[72,58,18,5],
[72,58,18,6],
[72,58,18,7],
[72,58,18,8],
[72,58,19,0],
[72,58,19,1],
[72,58,19,2],
[72,58,19,3],
[72,58,19,4],
[72,58,19,5],
[72,58,19,6],
[72,58,19,7],
[72,58,21,0],
[72,58,21,1],
[72,58,21,2],
[72,58,21,4],
[72,59,4,3],
[72,59,5,0],
[72,59,5,1],
[72,59,5,2],
[72,59,5,4],
[72,59,6,0],
[72,59,6,1],
[72,59,6,2],
[72,59,6,4],
[72,59,7,0],
[72,59,7,1],
[72,59,7,2],
[72,59,7,3],
[72,59,7,4],
[72,59,7,5],
[72,59,7,6],
[72,59,8,0],
[72,59,8,1],
[72,59,8,2],
[72,59,8,4],
[72,59,8,7],
[72,59,11,0],
[72,59,11,1],
[72,59,11,2],
[72,59,11,3],
[72,59,11,4],
[72,59,11,5],
[72,59,11,6],
[72,59,11,7],
[72,59,11,8],
[72,59,12,0],
[72,59,12,1],
[72,59,12,2],
[72,59,12,3],
[72,59,12,4],
[72,59,12,5],
[72,59,12,6],
[72,59,12,7],
[72,59,12,8],
[72,59,13,0],
[72,59,13,1],
[72,59,13,2],
[72,59,13,3],
[72,59,13,4],
[72,59,13,5],
[72,59,13,6],
[72,59,13,7],
[72,59,13,8],
[72,59,13,11],
[72,59,13,12],
[72,59,14,0],
[72,59,14,1],
[72,59,14,2],
[72,59,14,3],
[72,59,14,4],
[72,59,14,5],
[72,59,14,6],
[72,59,14,7],
[72,59,14,8],
[72,59,14,11],
[72,59,14,12],
[72,59,16,0],
[72,59,16,1],
[72,59,16,2],
[72,59,16,3],
[72,59,16,4],
[72,59,16,5],
[72,59,16,6],
[72,59,16,7],
[72,59,16,8],
[72,59,17,0],
[72,59,17,1],
[72,59,17,2],
[72,59,17,3],
[72,59,17,4],
[72,59,17,5],
[72,59,17,6],
[72,59,17,7],
[72,59,17,8],
[72,59,19,0],
[72,59,19,1],
[72,59,19,2],
[72,59,19,3],
[72,59,19,4],
[72,59,19,5],
[72,59,21,0],
[72,59,21,1],
[72,59,21,2],
[72,60,3,0],
[72,60,3,1],
[72,60,3,2],
[72,60,4,0],
[72,60,4,1],
[72,60,4,2],
[72,60,4,3],
[72,60,5,0],
[72,60,5,1],
[72,60,5,2],
[72,60,5,4],
[72,60,6,0],
[72,60,6,1],
[72,60,6,2],
[72,60,6,4],
[72,60,7,0],
[72,60,7,1],
[72,60,7,2],
[72,60,7,3],
[72,60,7,4],
[72,60,7,5],
[72,60,7,6],
[72,60,8,0],
[72,60,8,1],
[72,60,8,2],
[72,60,8,4],
[72,60,8,7],
[72,60,9,0],
[72,60,9,1],
[72,60,9,2],
[72,60,9,3],
[72,60,9,4],
[72,60,9,5],
[72,60,9,6],
[72,60,9,7],
[72,60,9,8],
[72,60,11,0],
[72,60,11,1],
[72,60,11,2],
[72,60,11,3],
[72,60,11,4],
[72,60,11,5],
[72,60,11,6],
[72,60,11,7],
[72,60,11,8],
[72,60,11,9],
[72,60,12,0],
[72,60,12,1],
[72,60,12,2],
[72,60,12,3],
[72,60,12,4],
[72,60,12,5],
[72,60,12,6],
[72,60,12,7],
[72,60,12,8],
[72,60,12,9],
[72,60,15,0],
[72,60,15,1],
[72,60,15,2],
[72,60,15,3],
[72,60,15,4],
[72,60,15,5],
[72,60,15,6],
[72,60,15,7],
[72,60,15,8],
[72,60,16,0],
[72,60,16,1],
[72,60,16,2],
[72,60,16,3],
[72,60,16,4],
[72,60,16,5],
[72,60,16,6],
[72,60,16,7],
[72,60,17,0],
[72,60,17,1],
[72,60,17,2],
[72,60,17,3],
[72,60,17,4],
[72,60,17,5],
[72,60,17,6],
[72,60,17,7],
[72,60,18,0],
[72,60,18,1],
[72,60,18,2],
[72,60,18,3],
[72,60,18,4],
[72,60,18,5],
[72,61,3,0],
[72,61,3,1],
[72,61,3,2],
[72,61,5,0],
[72,61,5,1],
[72,61,5,2],
[72,61,6,0],
[72,61,6,1],
[72,61,6,2],
[72,61,7,0],
[72,61,7,1],
[72,61,7,2],
[72,61,7,3],
[72,61,7,5],
[72,61,7,6],
[72,61,8,0],
[72,61,8,1],
[72,61,8,2],
[72,61,8,7],
[72,61,9,0],
[72,61,9,1],
[72,61,9,2],
[72,61,9,3],
[72,61,9,5],
[72,61,9,6],
[72,61,9,7],
[72,61,9,8],
[72,61,11,0],
[72,61,11,1],
[72,61,11,2],
[72,61,11,3],
[72,61,11,5],
[72,61,11,6],
[72,61,11,7],
[72,61,11,8],
[72,61,11,9],
[72,61,12,0],
[72,61,12,1],
[72,61,12,2],
[72,61,12,3],
[72,61,12,5],
[72,61,12,6],
[72,61,12,7],
[72,61,12,8],
[72,61,12,9],
[72,61,13,0],
[72,61,13,1],
[72,61,13,2],
[72,61,13,3],
[72,61,13,5],
[72,61,13,6],
[72,61,13,7],
[72,61,13,8],
[72,61,13,9],
[72,61,13,11],
[72,61,14,0],
[72,61,14,1],
[72,61,14,2],
[72,61,14,3],
[72,61,14,5],
[72,61,14,6],
[72,61,14,7],
[72,61,14,8],
[72,61,14,9],
[72,61,15,0],
[72,61,15,1],
[72,61,15,2],
[72,61,15,3],
[72,61,15,5],
[72,61,15,6],
[72,61,15,7],
[72,61,16,0],
[72,61,16,1],
[72,61,16,2],
[72,61,16,3],
[72,61,16,5],
[72,61,17,0],
[72,61,17,1],
[72,61,17,2],
[72,61,17,3],
[72,61,17,5],
[72,61,18,0],
[72,61,18,1],
[72,61,18,2],
[72,61,18,3],
[72,61,19,0],
[72,61,19,1],
[72,61,19,2],
[72,62,3,0],
[72,62,3,1],
[72,62,3,2],
[72,62,4,0],
[72,62,4,1],
[72,62,4,2],
[72,62,4,3],
[72,62,5,0],
[72,62,5,1],
[72,62,5,2],
[72,62,5,4],
[72,62,6,0],
[72,62,6,1],
[72,62,6,2],
[72,62,6,4],
[72,62,8,0],
[72,62,8,1],
[72,62,8,2],
[72,62,8,4],
[72,62,9,0],
[72,62,9,1],
[72,62,9,2],
[72,62,9,3],
[72,62,9,4],
[72,62,9,5],
[72,62,9,6],
[72,62,9,8],
[72,62,11,0],
[72,62,11,1],
[72,62,11,2],
[72,62,11,3],
[72,62,11,4],
[72,62,11,5],
[72,62,11,6],
[72,62,11,8],
[72,62,11,9],
[72,62,12,0],
[72,62,12,1],
[72,62,12,2],
[72,62,12,3],
[72,62,12,4],
[72,62,12,5],
[72,62,12,6],
[72,62,12,8],
[72,62,12,9],
[72,62,13,0],
[72,62,13,1],
[72,62,13,2],
[72,62,13,3],
[72,62,13,4],
[72,62,13,5],
[72,62,13,6],
[72,62,13,8],
[72,62,13,9],
[72,62,13,11],
[72,62,14,0],
[72,62,14,1],
[72,62,14,2],
[72,62,14,3],
[72,62,14,4],
[72,62,14,5],
[72,62,14,6],
[72,62,14,8],
[72,62,14,9],
[72,62,15,0],
[72,62,15,1],
[72,62,15,2],
[72,62,15,3],
[72,62,15,4],
[72,62,15,5],
[72,62,15,6],
[72,62,16,0],
[72,62,16,1],
[72,62,16,2],
[72,62,16,3],
[72,62,16,4],
[72,62,16,5],
[72,62,17,0],
[72,62,17,1],
[72,62,17,2],
[72,62,17,3],
[72,62,17,4],
[72,62,17,5],
[72,62,18,0],
[72,62,18,1],
[72,62,18,2],
[72,62,18,3],
[72,62,18,4],
[72,62,19,0],
[72,62,19,1],
[72,62,19,2],
[72,63,3,0],
[72,63,3,1],
[72,63,3,2],
[72,63,4,0],
[72,63,4,1],
[72,63,4,2],
[72,63,4,3],
[72,63,5,0],
[72,63,5,1],
[72,63,5,2],
[72,63,5,4],
[72,63,6,0],
[72,63,6,1],
[72,63,6,2],
[72,63,6,4],
[72,63,7,0],
[72,63,7,1],
[72,63,7,2],
[72,63,7,3],
[72,63,7,4],
[72,63,7,5],
[72,63,7,6],
[72,63,8,0],
[72,63,8,1],
[72,63,8,2],
[72,63,8,4],
[72,63,8,7],
[72,63,11,0],
[72,63,11,1],
[72,63,11,2],
[72,63,11,3],
[72,63,11,4],
[72,63,11,5],
[72,63,11,6],
[72,63,11,7],
[72,63,11,8],
[72,63,12,0],
[72,63,12,1],
[72,63,12,2],
[72,63,12,3],
[72,63,12,4],
[72,63,12,5],
[72,63,12,6],
[72,63,12,7],
[72,63,12,8],
[72,63,13,0],
[72,63,13,1],
[72,63,13,2],
[72,63,13,3],
[72,63,13,4],
[72,63,13,5],
[72,63,13,6],
[72,63,13,7],
[72,63,13,8],
[72,63,14,0],
[72,63,14,1],
[72,63,14,2],
[72,63,14,3],
[72,63,14,4],
[72,63,14,5],
[72,63,14,6],
[72,63,14,7],
[72,63,16,0],
[72,63,16,1],
[72,63,16,2],
[72,63,16,3],
[72,63,16,4],
[72,63,17,0],
[72,63,17,1],
[72,63,17,2],
[72,63,17,3],
[72,63,17,4],
[72,64,3,0],
[72,64,3,1],
[72,64,3,2],
[72,64,5,0],
[72,64,5,1],
[72,64,5,2],
[72,64,6,0],
[72,64,6,1],
[72,64,6,2],
[72,64,7,0],
[72,64,7,1],
[72,64,7,2],
[72,64,7,3],
[72,64,7,5],
[72,64,7,6],
[72,64,8,0],
[72,64,8,1],
[72,64,8,2],
[72,64,8,7],
[72,64,9,0],
[72,64,9,1],
[72,64,9,2],
[72,64,9,3],
[72,64,9,5],
[72,64,9,6],
[72,64,9,7],
[72,64,9,8],
[72,64,11,0],
[72,64,11,1],
[72,64,11,2],
[72,64,11,3],
[72,64,11,5],
[72,64,11,6],
[72,64,11,7],
[72,64,11,8],
[72,64,11,9],
[72,64,12,0],
[72,64,12,1],
[72,64,12,2],
[72,64,12,3],
[72,64,12,5],
[72,64,12,6],
[72,64,12,7],
[72,64,13,0],
[72,64,13,1],
[72,64,13,2],
[72,64,13,3],
[72,64,13,5],
[72,64,13,6],
[72,64,13,7],
[72,64,14,0],
[72,64,14,1],
[72,64,14,2],
[72,64,14,3],
[72,64,14,5],
[72,64,15,0],
[72,64,15,1],
[72,64,15,2],
[72,64,15,3],
[72,64,16,0],
[72,64,16,1],
[72,64,16,2],
[72,64,17,0],
[72,64,17,1],
[72,64,17,2],
[72,65,3,0],
[72,65,3,1],
[72,65,3,2],
[72,65,4,0],
[72,65,4,1],
[72,65,4,2],
[72,65,4,3],
[72,65,5,0],
[72,65,5,1],
[72,65,5,2],
[72,65,5,4],
[72,65,6,0],
[72,65,6,1],
[72,65,6,2],
[72,65,6,4],
[72,65,7,0],
[72,65,7,1],
[72,65,7,2],
[72,65,7,3],
[72,65,7,4],
[72,65,7,5],
[72,65,7,6],
[72,65,8,0],
[72,65,8,1],
[72,65,8,2],
[72,65,8,4],
[72,65,8,7],
[72,65,9,0],
[72,65,9,1],
[72,65,9,2],
[72,65,9,3],
[72,65,9,4],
[72,65,9,5],
[72,65,9,6],
[72,65,9,7],
[72,65,9,8],
[72,65,11,0],
[72,65,11,1],
[72,65,11,2],
[72,65,11,3],
[72,65,11,4],
[72,65,11,5],
[72,65,11,6],
[72,65,11,7],
[72,65,12,0],
[72,65,12,1],
[72,65,12,2],
[72,65,12,3],
[72,65,12,4],
[72,65,12,5],
[72,65,13,0],
[72,65,13,1],
[72,65,13,2],
[72,65,13,3],
[72,65,13,4],
[72,65,13,5],
[72,65,14,0],
[72,65,14,1],
[72,65,14,2],
[72,65,14,3],
[72,65,14,4],
[72,65,15,0],
[72,65,15,1],
[72,65,15,2],
[72,66,3,0],
[72,66,3,1],
[72,66,3,2],
[72,66,4,0],
[72,66,4,1],
[72,66,4,2],
[72,66,4,3],
[72,66,5,0],
[72,66,5,1],
[72,66,5,2],
[72,66,5,4],
[72,66,6,0],
[72,66,6,1],
[72,66,6,2],
[72,66,6,4],
[72,66,8,0],
[72,66,8,1],
[72,66,8,2],
[72,66,8,4],
[72,66,9,0],
[72,66,9,1],
[72,66,9,2],
[72,66,9,3],
[72,66,9,4],
[72,66,9,5],
[72,66,9,6],
[72,66,11,0],
[72,66,11,1],
[72,66,11,2],
[72,66,11,3],
[72,66,11,4],
[72,66,11,5],
[72,66,12,0],
[72,66,12,1],
[72,66,12,2],
[72,66,12,3],
[72,66,12,4],
[72,66,13,0],
[72,66,13,1],
[72,66,13,2],
[72,66,13,3],
[72,66,13,4],
[72,66,14,0],
[72,66,14,1],
[72,66,14,2],
[72,67,3,0],
[72,67,3,1],
[72,67,3,2],
[72,67,4,0],
[72,67,4,1],
[72,67,4,2],
[72,67,4,3],
[72,67,5,0],
[72,67,5,1],
[72,67,5,2],
[72,67,5,4],
[72,67,6,0],
[72,67,6,1],
[72,67,6,2],
[72,67,6,4],
[72,67,8,0],
[72,67,8,1],
[72,67,8,2],
[72,67,8,4],
[72,67,9,0],
[72,67,9,1],
[72,67,9,2],
[72,67,9,3],
[72,67,9,4],
[72,67,9,5],
[72,67,11,0],
[72,67,11,1],
[72,67,11,2],
[72,67,11,3],
[72,67,11,4],
[72,67,12,0],
[72,67,12,1],
[72,67,12,2],
[72,67,13,0],
[72,67,13,1],
[72,67,13,2],
[72,68,3,0],
[72,68,3,1],
[72,68,3,2],
[72,68,4,0],
[72,68,4,1],
[72,68,4,2],
[72,68,4,3],
[72,68,5,0],
[72,68,5,1],
[72,68,5,2],
[72,68,5,4],
[72,68,6,0],
[72,68,6,1],
[72,68,6,2],
[72,68,6,4],
[72,68,8,0],
[72,68,8,1],
[72,68,8,2],
[72,68,8,4],
[72,68,9,0],
[72,68,9,1],
[72,68,9,2],
[72,68,9,3],
[72,68,9,4],
[72,68,11,0],
[72,68,11,1],
[72,68,11,2],
[72,69,3,0],
[72,69,3,1],
[72,69,3,2],
[72,69,4,0],
[72,69,4,1],
[72,69,4,2],
[72,69,4,3],
[72,69,5,0],
[72,69,5,1],
[72,69,5,2],
[72,69,5,4],
[72,69,6,0],
[72,69,6,1],
[72,69,6,2],
[72,69,6,4],
[72,69,7,0],
[72,69,7,1],
[72,69,7,2],
[72,69,7,3],
[72,69,7,4],
[72,69,8,0],
[72,69,8,1],
[72,69,8,2],
[72,70,3,0],
[72,70,3,1],
[72,70,3,2],
[72,70,4,0],
[72,70,4,1],
[72,70,4,2],
[72,70,4,3],
[72,70,5,0],
[72,70,5,1],
[72,70,5,2],
[72,70,5,4],
[72,70,6,0],
[72,70,6,1],
[72,70,6,2],
[72,71,3,0],
[72,71,3,1],
[72,71,3,2],
[72,71,4,0],
[72,71,4,1],
[72,71,4,2],
[72,71,4,3],
[72,71,5,0],
[72,71,5,1],
[72,71,5,2],
[73,7,4,0],
[73,7,4,1],
[73,7,4,2],
[73,9,4,0],
[73,9,4,1],
[73,9,4,2],
[73,9,7,0],
[73,9,7,1],
[73,9,7,2],
[73,9,7,4],
[73,10,4,0],
[73,10,4,1],
[73,10,4,2],
[73,10,7,0],
[73,10,7,1],
[73,10,7,2],
[73,10,7,4],
[73,10,9,0],
[73,10,9,1],
[73,10,9,2],
[73,10,9,4],
[73,10,9,7],
[73,11,4,0],
[73,11,4,1],
[73,11,4,2],
[73,11,7,0],
[73,11,7,1],
[73,11,7,2],
[73,11,7,4],
[73,11,9,0],
[73,11,9,1],
[73,11,9,2],
[73,11,9,4],
[73,11,9,7],
[73,11,10,0],
[73,11,10,1],
[73,11,10,2],
[73,11,10,4],
[73,11,10,7],
[73,11,10,9],
[73,12,4,0],
[73,12,4,1],
[73,12,4,2],
[73,12,7,0],
[73,12,7,1],
[73,12,7,2],
[73,12,7,4],
[73,12,9,0],
[73,12,9,1],
[73,12,9,2],
[73,12,9,4],
[73,12,9,7],
[73,12,10,0],
[73,12,10,1],
[73,12,10,2],
[73,12,10,4],
[73,12,10,7],
[73,12,10,9],
[73,13,4,0],
[73,13,4,1],
[73,13,4,2],
[73,13,7,0],
[73,13,7,1],
[73,13,7,2],
[73,13,7,4],
[73,13,9,0],
[73,13,9,1],
[73,13,9,2],
[73,13,9,4],
[73,13,9,7],
[73,13,10,0],
[73,13,10,1],
[73,13,10,2],
[73,13,10,4],
[73,13,10,7],
[73,13,10,9],
[73,13,11,0],
[73,13,11,1],
[73,13,11,2],
[73,13,11,4],
[73,13,11,7],
[73,13,11,9],
[73,13,11,10],
[73,13,12,0],
[73,13,12,1],
[73,13,12,2],
[73,13,12,4],
[73,13,12,7],
[73,13,12,9],
[73,13,12,10],
[73,14,4,0],
[73,14,4,1],
[73,14,4,2],
[73,14,7,0],
[73,14,7,1],
[73,14,7,2],
[73,14,7,4],
[73,14,9,0],
[73,14,9,1],
[73,14,9,2],
[73,14,9,4],
[73,14,9,7],
[73,14,10,0],
[73,14,10,1],
[73,14,10,2],
[73,14,10,4],
[73,14,10,7],
[73,14,10,9],
[73,14,11,0],
[73,14,11,1],
[73,14,11,2],
[73,14,11,4],
[73,14,11,7],
[73,14,11,9],
[73,14,11,10],
[73,14,12,0],
[73,14,12,1],
[73,14,12,2],
[73,14,12,4],
[73,14,12,7],
[73,14,12,9],
[73,14,12,10],
[73,15,4,0],
[73,15,4,1],
[73,15,4,2],
[73,15,7,0],
[73,15,7,1],
[73,15,7,2],
[73,15,7,4],
[73,15,10,0],
[73,15,10,1],
[73,15,10,2],
[73,15,10,4],
[73,15,10,7],
[73,15,11,0],
[73,15,11,1],
[73,15,11,2],
[73,15,11,4],
[73,15,11,7],
[73,15,11,10],
[73,15,12,0],
[73,15,12,1],
[73,15,12,2],
[73,15,12,4],
[73,15,12,7],
[73,15,12,10],
[73,15,13,0],
[73,15,13,1],
[73,15,13,2],
[73,15,13,4],
[73,15,13,7],
[73,15,13,10],
[73,15,13,11],
[73,15,13,12],
[73,15,14,0],
[73,15,14,1],
[73,15,14,2],
[73,15,14,4],
[73,15,14,7],
[73,15,14,10],
[73,15,14,11],
[73,15,14,12],
[73,16,4,0],
[73,16,4,1],
[73,16,4,2],
[73,16,7,0],
[73,16,7,1],
[73,16,7,2],
[73,16,7,4],
[73,16,9,0],
[73,16,9,1],
[73,16,9,2],
[73,16,9,4],
[73,16,9,7],
[73,16,10,0],
[73,16,10,1],
[73,16,10,2],
[73,16,10,4],
[73,16,10,7],
[73,16,10,9],
[73,16,11,0],
[73,16,11,1],
[73,16,11,2],
[73,16,11,4],
[73,16,11,7],
[73,16,11,9],
[73,16,11,10],
[73,16,12,0],
[73,16,12,1],
[73,16,12,2],
[73,16,12,4],
[73,16,12,7],
[73,16,12,9],
[73,16,12,10],
[73,16,13,0],
[73,16,13,1],
[73,16,13,2],
[73,16,13,4],
[73,16,13,7],
[73,16,13,9],
[73,16,13,10],
[73,16,13,11],
[73,16,13,12],
[73,16,14,0],
[73,16,14,1],
[73,16,14,2],
[73,16,14,4],
[73,16,14,7],
[73,16,14,9],
[73,16,14,10],
[73,16,14,11],
[73,16,14,12],
[73,16,15,0],
[73,16,15,1],
[73,16,15,2],
[73,16,15,4],
[73,16,15,7],
[73,16,15,10],
[73,16,15,11],
[73,16,15,12],
[73,16,15,13],
[73,16,15,14],
[73,17,4,0],
[73,17,4,1],
[73,17,4,2],
[73,17,7,0],
[73,17,7,1],
[73,17,7,2],
[73,17,7,4],
[73,17,9,0],
[73,17,9,1],
[73,17,9,2],
[73,17,9,4],
[73,17,9,7],
[73,17,10,0],
[73,17,10,1],
[73,17,10,2],
[73,17,10,4],
[73,17,10,7],
[73,17,10,9],
[73,17,11,0],
[73,17,11,1],
[73,17,11,2],
[73,17,11,4],
[73,17,11,7],
[73,17,11,9],
[73,17,11,10],
[73,17,12,0],
[73,17,12,1],
[73,17,12,2],
[73,17,12,4],
[73,17,12,7],
[73,17,12,9],
[73,17,12,10],
[73,17,13,0],
[73,17,13,1],
[73,17,13,2],
[73,17,13,4],
[73,17,13,7],
[73,17,13,9],
[73,17,13,10],
[73,17,13,11],
[73,17,13,12],
[73,17,14,0],
[73,17,14,1],
[73,17,14,2],
[73,17,14,4],
[73,17,14,7],
[73,17,14,9],
[73,17,14,10],
[73,17,14,11],
[73,17,14,12],
[73,17,15,0],
[73,17,15,1],
[73,17,15,2],
[73,17,15,4],
[73,17,15,7],
[73,17,15,10],
[73,17,15,11],
[73,17,15,12],
[73,17,15,13],
[73,17,15,14],
[73,17,16,0],
[73,17,16,1],
[73,17,16,2],
[73,17,16,4],
[73,17,16,7],
[73,17,16,9],
[73,17,16,10],
[73,17,16,11],
[73,17,16,12],
[73,17,16,13],
[73,17,16,14],
[73,17,16,15],
[73,18,4,0],
[73,18,4,1],
[73,18,4,2],
[73,18,7,0],
[73,18,7,1],
[73,18,7,2],
[73,18,7,4],
[73,18,10,0],
[73,18,10,1],
[73,18,10,2],
[73,18,10,4],
[73,18,10,7],
[73,18,11,0],
[73,18,11,1],
[73,18,11,2],
[73,18,11,4],
[73,18,11,7],
[73,18,11,10],
[73,18,12,0],
[73,18,12,1],
[73,18,12,2],
[73,18,12,4],
[73,18,12,7],
[73,18,12,10],
[73,18,13,0],
[73,18,13,1],
[73,18,13,2],
[73,18,13,4],
[73,18,13,7],
[73,18,13,10],
[73,18,13,11],
[73,18,13,12],
[73,18,14,0],
[73,18,14,1],
[73,18,14,2],
[73,18,14,4],
[73,18,14,7],
[73,18,14,10],
[73,18,14,11],
[73,18,14,12],
[73,18,16,0],
[73,18,16,1],
[73,18,16,2],
[73,18,16,4],
[73,18,16,7],
[73,18,16,10],
[73,18,16,11],
[73,18,16,12],
[73,18,16,13],
[73,18,16,14],
[73,18,17,0],
[73,18,17,1],
[73,18,17,2],
[73,18,17,4],
[73,18,17,7],
[73,18,17,10],
[73,18,17,11],
[73,18,17,12],
[73,18,17,13],
[73,18,17,14],
[73,18,17,16],
[73,19,4,0],
[73,19,4,1],
[73,19,4,2],
[73,19,7,0],
[73,19,7,1],
[73,19,7,2],
[73,19,7,4],
[73,19,9,0],
[73,19,9,1],
[73,19,9,2],
[73,19,9,4],
[73,19,9,7],
[73,19,10,0],
[73,19,10,1],
[73,19,10,2],
[73,19,10,4],
[73,19,10,7],
[73,19,10,9],
[73,19,11,0],
[73,19,11,1],
[73,19,11,2],
[73,19,11,4],
[73,19,11,7],
[73,19,11,9],
[73,19,11,10],
[73,19,12,0],
[73,19,12,1],
[73,19,12,2],
[73,19,12,4],
[73,19,12,7],
[73,19,12,9],
[73,19,12,10],
[73,19,15,0],
[73,19,15,1],
[73,19,15,2],
[73,19,15,4],
[73,19,15,7],
[73,19,15,10],
[73,19,15,11],
[73,19,15,12],
[73,19,16,0],
[73,19,16,1],
[73,19,16,2],
[73,19,16,4],
[73,19,16,7],
[73,19,16,9],
[73,19,16,10],
[73,19,16,11],
[73,19,16,12],
[73,19,16,15],
[73,19,17,0],
[73,19,17,1],
[73,19,17,2],
[73,19,17,4],
[73,19,17,7],
[73,19,17,9],
[73,19,17,10],
[73,19,17,11],
[73,19,17,12],
[73,19,17,15],
[73,19,17,16],
[73,19,18,0],
[73,19,18,1],
[73,19,18,2],
[73,19,18,4],
[73,19,18,7],
[73,19,18,10],
[73,19,18,11],
[73,19,18,12],
[73,19,18,16],
[73,19,18,17],
[73,20,4,0],
[73,20,4,1],
[73,20,4,2],
[73,20,7,0],
[73,20,7,1],
[73,20,7,2],
[73,20,7,4],
[73,20,9,0],
[73,20,9,1],
[73,20,9,2],
[73,20,9,4],
[73,20,9,7],
[73,20,11,0],
[73,20,11,1],
[73,20,11,2],
[73,20,11,4],
[73,20,11,7],
[73,20,11,9],
[73,20,12,0],
[73,20,12,1],
[73,20,12,2],
[73,20,12,4],
[73,20,12,7],
[73,20,12,9],
[73,20,13,0],
[73,20,13,1],
[73,20,13,2],
[73,20,13,4],
[73,20,13,7],
[73,20,13,9],
[73,20,13,11],
[73,20,13,12],
[73,20,14,0],
[73,20,14,1],
[73,20,14,2],
[73,20,14,4],
[73,20,14,7],
[73,20,14,9],
[73,20,14,11],
[73,20,14,12],
[73,20,15,0],
[73,20,15,1],
[73,20,15,2],
[73,20,15,4],
[73,20,15,7],
[73,20,15,11],
[73,20,15,12],
[73,20,15,13],
[73,20,15,14],
[73,20,16,0],
[73,20,16,1],
[73,20,16,2],
[73,20,16,4],
[73,20,16,7],
[73,20,16,9],
[73,20,16,11],
[73,20,16,12],
[73,20,16,13],
[73,20,16,14],
[73,20,16,15],
[73,20,17,0],
[73,20,17,1],
[73,20,17,2],
[73,20,17,4],
[73,20,17,7],
[73,20,17,9],
[73,20,17,11],
[73,20,17,12],
[73,20,17,13],
[73,20,17,14],
[73,20,17,15],
[73,20,17,16],
[73,20,18,0],
[73,20,18,1],
[73,20,18,2],
[73,20,18,4],
[73,20,18,7],
[73,20,18,11],
[73,20,18,12],
[73,20,18,13],
[73,20,18,14],
[73,20,18,16],
[73,20,18,17],
[73,20,19,0],
[73,20,19,1],
[73,20,19,2],
[73,20,19,4],
[73,20,19,7],
[73,20,19,9],
[73,20,19,11],
[73,20,19,12],
[73,20,19,15],
[73,20,19,16],
[73,20,19,17],
[73,20,19,18],
[73,22,4,0],
[73,22,4,1],
[73,22,4,2],
[73,22,7,0],
[73,22,7,1],
[73,22,7,2],
[73,22,7,4],
[73,22,9,0],
[73,22,9,1],
[73,22,9,2],
[73,22,9,4],
[73,22,9,7],
[73,22,11,0],
[73,22,11,1],
[73,22,11,2],
[73,22,11,4],
[73,22,11,7],
[73,22,11,9],
[73,22,12,0],
[73,22,12,1],
[73,22,12,2],
[73,22,12,4],
[73,22,12,7],
[73,22,12,9],
[73,22,13,0],
[73,22,13,1],
[73,22,13,2],
[73,22,13,4],
[73,22,13,7],
[73,22,13,9],
[73,22,13,11],
[73,22,13,12],
[73,22,14,0],
[73,22,14,1],
[73,22,14,2],
[73,22,14,4],
[73,22,14,7],
[73,22,14,9],
[73,22,14,11],
[73,22,14,12],
[73,22,15,0],
[73,22,15,1],
[73,22,15,2],
[73,22,15,4],
[73,22,15,7],
[73,22,15,11],
[73,22,15,12],
[73,22,15,13],
[73,22,15,14],
[73,22,16,0],
[73,22,16,1],
[73,22,16,2],
[73,22,16,4],
[73,22,16,7],
[73,22,16,9],
[73,22,16,11],
[73,22,16,12],
[73,22,16,13],
[73,22,16,14],
[73,22,16,15],
[73,22,17,0],
[73,22,17,1],
[73,22,17,2],
[73,22,17,4],
[73,22,17,7],
[73,22,17,9],
[73,22,17,11],
[73,22,17,12],
[73,22,17,13],
[73,22,17,14],
[73,22,17,15],
[73,22,17,16],
[73,22,18,0],
[73,22,18,1],
[73,22,18,2],
[73,22,18,4],
[73,22,18,7],
[73,22,18,11],
[73,22,18,12],
[73,22,18,13],
[73,22,18,14],
[73,22,18,16],
[73,22,18,17],
[73,22,19,0],
[73,22,19,1],
[73,22,19,2],
[73,22,19,4],
[73,22,19,7],
[73,22,19,9],
[73,22,19,11],
[73,22,19,12],
[73,22,19,15],
[73,22,19,16],
[73,22,19,17],
[73,22,19,18],
[73,23,4,0],
[73,23,4,1],
[73,23,4,2],
[73,23,7,0],
[73,23,7,1],
[73,23,7,2],
[73,23,7,4],
[73,23,9,0],
[73,23,9,1],
[73,23,9,2],
[73,23,9,4],
[73,23,9,7],
[73,23,10,0],
[73,23,10,1],
[73,23,10,2],
[73,23,10,4],
[73,23,10,7],
[73,23,10,9],
[73,23,11,0],
[73,23,11,1],
[73,23,11,2],
[73,23,11,4],
[73,23,11,7],
[73,23,11,9],
[73,23,11,10],
[73,23,12,0],
[73,23,12,1],
[73,23,12,2],
[73,23,12,4],
[73,23,12,7],
[73,23,12,9],
[73,23,12,10],
[73,23,13,0],
[73,23,13,1],
[73,23,13,2],
[73,23,13,4],
[73,23,13,7],
[73,23,13,9],
[73,23,13,10],
[73,23,13,11],
[73,23,13,12],
[73,23,14,0],
[73,23,14,1],
[73,23,14,2],
[73,23,14,4],
[73,23,14,7],
[73,23,14,9],
[73,23,14,10],
[73,23,14,11],
[73,23,14,12],
[73,23,15,0],
[73,23,15,1],
[73,23,15,2],
[73,23,15,4],
[73,23,15,7],
[73,23,15,10],
[73,23,15,11],
[73,23,15,12],
[73,23,15,13],
[73,23,15,14],
[73,23,16,0],
[73,23,16,1],
[73,23,16,2],
[73,23,16,4],
[73,23,16,7],
[73,23,16,9],
[73,23,16,10],
[73,23,16,11],
[73,23,16,12],
[73,23,16,13],
[73,23,16,14],
[73,23,16,15],
[73,23,17,0],
[73,23,17,1],
[73,23,17,2],
[73,23,17,4],
[73,23,17,7],
[73,23,17,9],
[73,23,17,10],
[73,23,17,11],
[73,23,17,12],
[73,23,17,13],
[73,23,17,14],
[73,23,17,15],
[73,23,17,16],
[73,23,18,0],
[73,23,18,1],
[73,23,18,2],
[73,23,18,4],
[73,23,18,7],
[73,23,18,10],
[73,23,18,11],
[73,23,18,12],
[73,23,18,13],
[73,23,18,14],
[73,23,18,16],
[73,23,18,17],
[73,23,19,0],
[73,23,19,1],
[73,23,19,2],
[73,23,19,4],
[73,23,19,7],
[73,23,19,9],
[73,23,19,10],
[73,23,19,11],
[73,23,19,12],
[73,23,19,15],
[73,23,19,16],
[73,23,19,17],
[73,23,19,18],
[73,23,20,0],
[73,23,20,1],
[73,23,20,2],
[73,23,20,4],
[73,23,20,7],
[73,23,20,9],
[73,23,20,11],
[73,23,20,12],
[73,23,20,13],
[73,23,20,14],
[73,23,20,15],
[73,23,20,16],
[73,23,20,17],
[73,23,20,18],
[73,23,20,19],
[73,23,22,0],
[73,23,22,1],
[73,23,22,2],
[73,23,22,4],
[73,23,22,7],
[73,23,22,9],
[73,23,22,11],
[73,23,22,12],
[73,23,22,13],
[73,23,22,14],
[73,23,22,15],
[73,23,22,16],
[73,23,22,17],
[73,23,22,18],
[73,23,22,19],
[73,24,4,0],
[73,24,4,1],
[73,24,4,2],
[73,24,7,0],
[73,24,7,1],
[73,24,7,2],
[73,24,7,4],
[73,24,9,0],
[73,24,9,1],
[73,24,9,2],
[73,24,9,4],
[73,24,9,7],
[73,24,10,0],
[73,24,10,1],
[73,24,10,2],
[73,24,10,4],
[73,24,10,7],
[73,24,10,9],
[73,24,11,0],
[73,24,11,1],
[73,24,11,2],
[73,24,11,4],
[73,24,11,7],
[73,24,11,9],
[73,24,11,10],
[73,24,12,0],
[73,24,12,1],
[73,24,12,2],
[73,24,12,4],
[73,24,12,7],
[73,24,12,9],
[73,24,12,10],
[73,24,13,0],
[73,24,13,1],
[73,24,13,2],
[73,24,13,4],
[73,24,13,7],
[73,24,13,9],
[73,24,13,10],
[73,24,13,11],
[73,24,13,12],
[73,24,14,0],
[73,24,14,1],
[73,24,14,2],
[73,24,14,4],
[73,24,14,7],
[73,24,14,9],
[73,24,14,10],
[73,24,14,11],
[73,24,14,12],
[73,24,15,0],
[73,24,15,1],
[73,24,15,2],
[73,24,15,4],
[73,24,15,7],
[73,24,15,10],
[73,24,15,11],
[73,24,15,12],
[73,24,15,13],
[73,24,15,14],
[73,24,17,0],
[73,24,17,1],
[73,24,17,2],
[73,24,17,4],
[73,24,17,7],
[73,24,17,9],
[73,24,17,10],
[73,24,17,11],
[73,24,17,12],
[73,24,17,13],
[73,24,17,14],
[73,24,17,15],
[73,24,18,0],
[73,24,18,1],
[73,24,18,2],
[73,24,18,4],
[73,24,18,7],
[73,24,18,10],
[73,24,18,11],
[73,24,18,12],
[73,24,18,13],
[73,24,18,14],
[73,24,18,17],
[73,24,19,0],
[73,24,19,1],
[73,24,19,2],
[73,24,19,4],
[73,24,19,7],
[73,24,19,9],
[73,24,19,10],
[73,24,19,11],
[73,24,19,12],
[73,24,19,15],
[73,24,19,17],
[73,24,19,18],
[73,24,20,0],
[73,24,20,1],
[73,24,20,2],
[73,24,20,4],
[73,24,20,7],
[73,24,20,9],
[73,24,20,11],
[73,24,20,12],
[73,24,20,13],
[73,24,20,14],
[73,24,20,15],
[73,24,20,17],
[73,24,20,18],
[73,24,20,19],
[73,24,22,0],
[73,24,22,1],
[73,24,22,2],
[73,24,22,4],
[73,24,22,7],
[73,24,22,9],
[73,24,22,11],
[73,24,22,12],
[73,24,22,13],
[73,24,22,14],
[73,24,22,15],
[73,24,22,17],
[73,24,22,18],
[73,24,22,19],
[73,24,23,0],
[73,24,23,1],
[73,24,23,2],
[73,24,23,4],
[73,24,23,7],
[73,24,23,9],
[73,24,23,10],
[73,24,23,11],
[73,24,23,12],
[73,24,23,13],
[73,24,23,14],
[73,24,23,15],
[73,24,23,17],
[73,24,23,18],
[73,24,23,20],
[73,24,23,22],
[73,25,4,0],
[73,25,4,1],
[73,25,4,2],
[73,25,9,0],
[73,25,9,1],
[73,25,9,2],
[73,25,9,4],
[73,25,10,0],
[73,25,10,1],
[73,25,10,2],
[73,25,10,4],
[73,25,10,9],
[73,25,11,0],
[73,25,11,1],
[73,25,11,2],
[73,25,11,4],
[73,25,11,9],
[73,25,11,10],
[73,25,12,0],
[73,25,12,1],
[73,25,12,2],
[73,25,12,4],
[73,25,12,9],
[73,25,12,10],
[73,25,13,0],
[73,25,13,1],
[73,25,13,2],
[73,25,13,4],
[73,25,13,9],
[73,25,13,10],
[73,25,13,11],
[73,25,13,12],
[73,25,14,0],
[73,25,14,1],
[73,25,14,2],
[73,25,14,4],
[73,25,14,9],
[73,25,14,10],
[73,25,14,11],
[73,25,14,12],
[73,25,15,0],
[73,25,15,1],
[73,25,15,2],
[73,25,15,4],
[73,25,15,10],
[73,25,15,11],
[73,25,15,12],
[73,25,15,13],
[73,25,15,14],
[73,25,16,0],
[73,25,16,1],
[73,25,16,2],
[73,25,16,4],
[73,25,16,9],
[73,25,16,10],
[73,25,16,11],
[73,25,16,12],
[73,25,16,13],
[73,25,16,14],
[73,25,16,15],
[73,25,17,0],
[73,25,17,1],
[73,25,17,2],
[73,25,17,4],
[73,25,17,9],
[73,25,17,10],
[73,25,17,11],
[73,25,17,12],
[73,25,17,13],
[73,25,17,14],
[73,25,17,15],
[73,25,17,16],
[73,25,18,0],
[73,25,18,1],
[73,25,18,2],
[73,25,18,4],
[73,25,18,10],
[73,25,18,11],
[73,25,18,12],
[73,25,18,13],
[73,25,18,14],
[73,25,18,16],
[73,25,18,17],
[73,25,19,0],
[73,25,19,1],
[73,25,19,2],
[73,25,19,4],
[73,25,19,9],
[73,25,19,10],
[73,25,19,11],
[73,25,19,12],
[73,25,19,15],
[73,25,19,16],
[73,25,19,17],
[73,25,19,18],
[73,25,20,0],
[73,25,20,1],
[73,25,20,2],
[73,25,20,4],
[73,25,20,9],
[73,25,20,11],
[73,25,20,12],
[73,25,20,13],
[73,25,20,14],
[73,25,20,15],
[73,25,20,16],
[73,25,20,17],
[73,25,20,18],
[73,25,20,19],
[73,25,22,0],
[73,25,22,1],
[73,25,22,2],
[73,25,22,4],
[73,25,22,9],
[73,25,22,11],
[73,25,22,12],
[73,25,22,13],
[73,25,22,14],
[73,25,22,15],
[73,25,22,16],
[73,25,22,17],
[73,25,22,19],
[73,25,23,0],
[73,25,23,1],
[73,25,23,2],
[73,25,23,4],
[73,25,23,9],
[73,25,23,10],
[73,25,23,11],
[73,25,23,12],
[73,25,23,13],
[73,25,23,14],
[73,25,23,15],
[73,25,23,18],
[73,25,23,19],
[73,25,23,20],
[73,25,23,22],
[73,25,24,0],
[73,25,24,1],
[73,25,24,2],
[73,25,24,4],
[73,25,24,9],
[73,25,24,10],
[73,25,24,11],
[73,25,24,12],
[73,25,24,13],
[73,25,24,14],
[73,25,24,15],
[73,25,24,18],
[73,25,24,19],
[73,25,24,20],
[73,25,24,22],
[73,25,24,23],
[73,26,4,0],
[73,26,4,1],
[73,26,4,2],
[73,26,7,0],
[73,26,7,1],
[73,26,7,2],
[73,26,7,4],
[73,26,10,0],
[73,26,10,1],
[73,26,10,2],
[73,26,10,4],
[73,26,10,7],
[73,26,11,0],
[73,26,11,1],
[73,26,11,2],
[73,26,11,4],
[73,26,11,7],
[73,26,11,10],
[73,26,12,0],
[73,26,12,1],
[73,26,12,2],
[73,26,12,4],
[73,26,12,7],
[73,26,12,10],
[73,26,13,0],
[73,26,13,1],
[73,26,13,2],
[73,26,13,4],
[73,26,13,7],
[73,26,13,10],
[73,26,13,11],
[73,26,13,12],
[73,26,14,0],
[73,26,14,1],
[73,26,14,2],
[73,26,14,4],
[73,26,14,7],
[73,26,14,10],
[73,26,14,11],
[73,26,14,12],
[73,26,16,0],
[73,26,16,1],
[73,26,16,2],
[73,26,16,4],
[73,26,16,7],
[73,26,16,10],
[73,26,16,11],
[73,26,16,12],
[73,26,16,13],
[73,26,16,14],
[73,26,17,0],
[73,26,17,1],
[73,26,17,2],
[73,26,17,4],
[73,26,17,7],
[73,26,17,10],
[73,26,17,11],
[73,26,17,12],
[73,26,17,13],
[73,26,17,14],
[73,26,17,16],
[73,26,19,0],
[73,26,19,1],
[73,26,19,2],
[73,26,19,4],
[73,26,19,7],
[73,26,19,10],
[73,26,19,11],
[73,26,19,12],
[73,26,19,16],
[73,26,19,17],
[73,26,20,0],
[73,26,20,1],
[73,26,20,2],
[73,26,20,4],
[73,26,20,7],
[73,26,20,11],
[73,26,20,12],
[73,26,20,13],
[73,26,20,14],
[73,26,20,16],
[73,26,20,17],
[73,26,22,0],
[73,26,22,1],
[73,26,22,2],
[73,26,22,4],
[73,26,22,7],
[73,26,22,11],
[73,26,22,12],
[73,26,22,13],
[73,26,22,14],
[73,26,22,19],
[73,26,23,0],
[73,26,23,1],
[73,26,23,2],
[73,26,23,4],
[73,26,23,7],
[73,26,23,10],
[73,26,23,11],
[73,26,23,12],
[73,26,23,13],
[73,26,23,14],
[73,26,23,16],
[73,26,23,17],
[73,26,23,19],
[73,26,23,20],
[73,26,23,22],
[73,26,24,0],
[73,26,24,1],
[73,26,24,2],
[73,26,24,4],
[73,26,24,7],
[73,26,24,10],
[73,26,24,11],
[73,26,24,12],
[73,26,24,13],
[73,26,24,14],
[73,26,24,17],
[73,26,24,19],
[73,26,24,20],
[73,26,24,22],
[73,26,24,23],
[73,26,25,0],
[73,26,25,1],
[73,26,25,2],
[73,26,25,4],
[73,26,25,10],
[73,26,25,11],
[73,26,25,14],
[73,26,25,16],
[73,26,25,17],
[73,26,25,19],
[73,26,25,20],
[73,26,25,22],
[73,26,25,23],
[73,26,25,24],
[73,27,4,0],
[73,27,4,1],
[73,27,4,2],
[73,27,7,0],
[73,27,7,1],
[73,27,7,2],
[73,27,7,4],
[73,27,9,0],
[73,27,9,1],
[73,27,9,2],
[73,27,9,4],
[73,27,9,7],
[73,27,11,0],
[73,27,11,1],
[73,27,11,2],
[73,27,11,4],
[73,27,11,7],
[73,27,11,9],
[73,27,12,0],
[73,27,12,1],
[73,27,12,2],
[73,27,12,4],
[73,27,12,7],
[73,27,12,9],
[73,27,13,0],
[73,27,13,1],
[73,27,13,2],
[73,27,13,4],
[73,27,13,7],
[73,27,13,9],
[73,27,13,11],
[73,27,13,12],
[73,27,14,0],
[73,27,14,1],
[73,27,14,2],
[73,27,14,4],
[73,27,14,7],
[73,27,14,9],
[73,27,14,11],
[73,27,14,12],
[73,27,15,0],
[73,27,15,1],
[73,27,15,2],
[73,27,15,4],
[73,27,15,7],
[73,27,15,11],
[73,27,15,12],
[73,27,15,13],
[73,27,15,14],
[73,27,16,0],
[73,27,16,1],
[73,27,16,2],
[73,27,16,4],
[73,27,16,7],
[73,27,16,9],
[73,27,16,11],
[73,27,16,12],
[73,27,16,13],
[73,27,16,14],
[73,27,16,15],
[73,27,17,0],
[73,27,17,1],
[73,27,17,2],
[73,27,17,4],
[73,27,17,7],
[73,27,17,9],
[73,27,17,11],
[73,27,17,12],
[73,27,17,13],
[73,27,17,14],
[73,27,17,15],
[73,27,17,16],
[73,27,18,0],
[73,27,18,1],
[73,27,18,2],
[73,27,18,4],
[73,27,18,7],
[73,27,18,11],
[73,27,18,12],
[73,27,18,13],
[73,27,18,14],
[73,27,18,16],
[73,27,18,17],
[73,27,19,0],
[73,27,19,1],
[73,27,19,2],
[73,27,19,4],
[73,27,19,7],
[73,27,19,9],
[73,27,19,11],
[73,27,19,12],
[73,27,19,15],
[73,27,19,16],
[73,27,19,17],
[73,27,19,18],
[73,27,23,0],
[73,27,23,1],
[73,27,23,2],
[73,27,23,4],
[73,27,23,7],
[73,27,23,9],
[73,27,23,11],
[73,27,23,12],
[73,27,23,13],
[73,27,23,15],
[73,27,23,16],
[73,27,23,17],
[73,27,23,18],
[73,27,23,19],
[73,27,24,0],
[73,27,24,1],
[73,27,24,2],
[73,27,24,4],
[73,27,24,7],
[73,27,24,9],
[73,27,24,11],
[73,27,24,12],
[73,27,24,13],
[73,27,24,15],
[73,27,24,17],
[73,27,24,18],
[73,27,24,19],
[73,27,24,23],
[73,27,25,0],
[73,27,25,1],
[73,27,25,2],
[73,27,25,4],
[73,27,25,9],
[73,27,25,12],
[73,27,25,13],
[73,27,25,14],
[73,27,25,15],
[73,27,25,16],
[73,27,25,17],
[73,27,25,18],
[73,27,25,19],
[73,27,25,23],
[73,27,25,24],
[73,27,26,0],
[73,27,26,1],
[73,27,26,2],
[73,27,26,4],
[73,27,26,7],
[73,27,26,11],
[73,27,26,12],
[73,27,26,13],
[73,27,26,14],
[73,27,26,16],
[73,27,26,17],
[73,27,26,19],
[73,27,26,23],
[73,27,26,24],
[73,28,4,0],
[73,28,4,1],
[73,28,4,2],
[73,28,9,0],
[73,28,9,1],
[73,28,9,2],
[73,28,9,4],
[73,28,10,0],
[73,28,10,1],
[73,28,10,2],
[73,28,10,4],
[73,28,10,9],
[73,28,11,0],
[73,28,11,1],
[73,28,11,2],
[73,28,11,4],
[73,28,11,9],
[73,28,11,10],
[73,28,12,0],
[73,28,12,1],
[73,28,12,2],
[73,28,12,4],
[73,28,12,9],
[73,28,12,10],
[73,28,13,0],
[73,28,13,1],
[73,28,13,2],
[73,28,13,4],
[73,28,13,9],
[73,28,13,10],
[73,28,13,11],
[73,28,13,12],
[73,28,14,0],
[73,28,14,1],
[73,28,14,2],
[73,28,14,4],
[73,28,14,9],
[73,28,14,10],
[73,28,14,11],
[73,28,14,12],
[73,28,15,0],
[73,28,15,1],
[73,28,15,2],
[73,28,15,4],
[73,28,15,10],
[73,28,15,11],
[73,28,15,12],
[73,28,15,13],
[73,28,15,14],
[73,28,16,0],
[73,28,16,1],
[73,28,16,2],
[73,28,16,4],
[73,28,16,9],
[73,28,16,10],
[73,28,16,11],
[73,28,16,12],
[73,28,16,13],
[73,28,16,14],
[73,28,16,15],
[73,28,17,0],
[73,28,17,1],
[73,28,17,2],
[73,28,17,4],
[73,28,17,9],
[73,28,17,10],
[73,28,17,11],
[73,28,17,12],
[73,28,17,13],
[73,28,17,14],
[73,28,17,15],
[73,28,17,16],
[73,28,18,0],
[73,28,18,1],
[73,28,18,2],
[73,28,18,4],
[73,28,18,10],
[73,28,18,11],
[73,28,18,12],
[73,28,18,13],
[73,28,18,14],
[73,28,18,16],
[73,28,18,17],
[73,28,19,0],
[73,28,19,1],
[73,28,19,2],
[73,28,19,4],
[73,28,19,9],
[73,28,19,10],
[73,28,19,11],
[73,28,19,12],
[73,28,19,15],
[73,28,19,16],
[73,28,19,17],
[73,28,20,0],
[73,28,20,1],
[73,28,20,2],
[73,28,20,4],
[73,28,20,9],
[73,28,20,11],
[73,28,20,12],
[73,28,20,13],
[73,28,20,14],
[73,28,20,15],
[73,28,20,18],
[73,28,20,19],
[73,28,22,0],
[73,28,22,1],
[73,28,22,2],
[73,28,22,4],
[73,28,22,9],
[73,28,22,11],
[73,28,22,12],
[73,28,22,13],
[73,28,22,15],
[73,28,22,16],
[73,28,22,17],
[73,28,22,18],
[73,28,22,19],
[73,28,23,0],
[73,28,23,1],
[73,28,23,2],
[73,28,23,4],
[73,28,23,9],
[73,28,23,10],
[73,28,23,11],
[73,28,23,14],
[73,28,23,15],
[73,28,23,16],
[73,28,23,17],
[73,28,23,18],
[73,28,23,19],
[73,28,23,20],
[73,28,23,22],
[73,28,24,0],
[73,28,24,1],
[73,28,24,2],
[73,28,24,4],
[73,28,24,9],
[73,28,24,10],
[73,28,24,11],
[73,28,24,14],
[73,28,24,15],
[73,28,24,17],
[73,28,24,18],
[73,28,24,19],
[73,28,24,20],
[73,28,24,22],
[73,28,24,23],
[73,28,26,0],
[73,28,26,1],
[73,28,26,2],
[73,28,26,4],
[73,28,26,10],
[73,28,26,11],
[73,28,26,12],
[73,28,26,13],
[73,28,26,14],
[73,28,26,16],
[73,28,26,17],
[73,28,26,19],
[73,28,26,20],
[73,28,26,22],
[73,28,26,23],
[73,28,26,24],
[73,28,27,0],
[73,28,27,1],
[73,28,27,2],
[73,28,27,4],
[73,28,27,9],
[73,28,27,11],
[73,28,27,12],
[73,28,27,13],
[73,28,27,14],
[73,28,27,15],
[73,28,27,16],
[73,28,27,17],
[73,28,27,18],
[73,28,27,19],
[73,29,4,0],
[73,29,4,1],
[73,29,4,2],
[73,29,7,0],
[73,29,7,1],
[73,29,7,2],
[73,29,7,4],
[73,29,9,0],
[73,29,9,1],
[73,29,9,2],
[73,29,9,4],
[73,29,9,7],
[73,29,10,0],
[73,29,10,1],
[73,29,10,2],
[73,29,10,4],
[73,29,10,7],
[73,29,10,9],
[73,29,13,0],
[73,29,13,1],
[73,29,13,2],
[73,29,13,4],
[73,29,13,7],
[73,29,13,9],
[73,29,13,10],
[73,29,14,0],
[73,29,14,1],
[73,29,14,2],
[73,29,14,4],
[73,29,14,7],
[73,29,14,9],
[73,29,14,10],
[73,29,15,0],
[73,29,15,1],
[73,29,15,2],
[73,29,15,4],
[73,29,15,7],
[73,29,15,10],
[73,29,15,13],
[73,29,15,14],
[73,29,16,0],
[73,29,16,1],
[73,29,16,2],
[73,29,16,4],
[73,29,16,7],
[73,29,16,9],
[73,29,16,10],
[73,29,16,13],
[73,29,16,14],
[73,29,16,15],
[73,29,17,0],
[73,29,17,1],
[73,29,17,2],
[73,29,17,4],
[73,29,17,7],
[73,29,17,9],
[73,29,17,10],
[73,29,17,13],
[73,29,17,14],
[73,29,17,15],
[73,29,17,16],
[73,29,18,0],
[73,29,18,1],
[73,29,18,2],
[73,29,18,4],
[73,29,18,7],
[73,29,18,10],
[73,29,18,13],
[73,29,18,14],
[73,29,18,16],
[73,29,18,17],
[73,29,19,0],
[73,29,19,1],
[73,29,19,2],
[73,29,19,4],
[73,29,19,7],
[73,29,19,9],
[73,29,19,10],
[73,29,19,15],
[73,29,19,18],
[73,29,20,0],
[73,29,20,1],
[73,29,20,2],
[73,29,20,4],
[73,29,20,7],
[73,29,20,9],
[73,29,20,13],
[73,29,20,14],
[73,29,20,16],
[73,29,20,17],
[73,29,20,18],
[73,29,20,19],
[73,29,22,0],
[73,29,22,1],
[73,29,22,2],
[73,29,22,4],
[73,29,22,7],
[73,29,22,9],
[73,29,22,14],
[73,29,22,15],
[73,29,22,16],
[73,29,22,17],
[73,29,22,18],
[73,29,22,19],
[73,29,23,0],
[73,29,23,1],
[73,29,23,2],
[73,29,23,4],
[73,29,23,7],
[73,29,23,9],
[73,29,23,13],
[73,29,23,14],
[73,29,23,15],
[73,29,23,16],
[73,29,23,17],
[73,29,23,18],
[73,29,23,19],
[73,29,23,20],
[73,29,23,22],
[73,29,24,0],
[73,29,24,1],
[73,29,24,2],
[73,29,24,4],
[73,29,24,7],
[73,29,24,9],
[73,29,24,13],
[73,29,24,14],
[73,29,24,15],
[73,29,24,17],
[73,29,24,18],
[73,29,24,19],
[73,29,24,20],
[73,29,24,22],
[73,29,24,23],
[73,29,25,0],
[73,29,25,1],
[73,29,25,2],
[73,29,25,4],
[73,29,25,9],
[73,29,25,10],
[73,29,25,13],
[73,29,25,14],
[73,29,25,15],
[73,29,25,16],
[73,29,25,17],
[73,29,25,18],
[73,29,25,19],
[73,29,25,20],
[73,29,25,22],
[73,29,25,23],
[73,29,25,24],
[73,29,26,0],
[73,29,26,1],
[73,29,26,2],
[73,29,26,4],
[73,29,26,7],
[73,29,26,10],
[73,29,26,13],
[73,29,26,14],
[73,29,26,16],
[73,29,26,17],
[73,29,26,19],
[73,29,26,20],
[73,29,26,22],
[73,29,27,0],
[73,29,27,1],
[73,29,27,2],
[73,29,27,7],
[73,29,27,9],
[73,29,27,13],
[73,29,27,14],
[73,29,27,15],
[73,29,27,16],
[73,29,27,17],
[73,29,27,18],
[73,29,27,19],
[73,29,28,4],
[73,29,28,9],
[73,29,28,10],
[73,29,28,13],
[73,29,28,14],
[73,29,28,15],
[73,29,28,16],
[73,29,28,17],
[73,29,28,18],
[73,29,28,19],
[73,29,28,20],
[73,30,4,0],
[73,30,4,1],
[73,30,4,2],
[73,30,7,0],
[73,30,7,1],
[73,30,7,2],
[73,30,7,4],
[73,30,9,0],
[73,30,9,1],
[73,30,9,2],
[73,30,9,4],
[73,30,9,7],
[73,30,10,0],
[73,30,10,1],
[73,30,10,2],
[73,30,10,4],
[73,30,10,7],
[73,30,10,9],
[73,30,11,0],
[73,30,11,1],
[73,30,11,2],
[73,30,11,4],
[73,30,11,7],
[73,30,11,9],
[73,30,11,10],
[73,30,12,0],
[73,30,12,1],
[73,30,12,2],
[73,30,12,4],
[73,30,12,7],
[73,30,12,9],
[73,30,12,10],
[73,30,13,0],
[73,30,13,1],
[73,30,13,2],
[73,30,13,4],
[73,30,13,7],
[73,30,13,9],
[73,30,13,10],
[73,30,13,11],
[73,30,13,12],
[73,30,14,0],
[73,30,14,1],
[73,30,14,2],
[73,30,14,4],
[73,30,14,7],
[73,30,14,9],
[73,30,14,10],
[73,30,14,11],
[73,30,14,12],
[73,30,15,0],
[73,30,15,1],
[73,30,15,2],
[73,30,15,4],
[73,30,15,7],
[73,30,15,10],
[73,30,15,11],
[73,30,15,12],
[73,30,15,13],
[73,30,15,14],
[73,30,16,0],
[73,30,16,1],
[73,30,16,2],
[73,30,16,4],
[73,30,16,7],
[73,30,16,9],
[73,30,16,10],
[73,30,16,11],
[73,30,16,12],
[73,30,16,13],
[73,30,16,14],
[73,30,16,15],
[73,30,17,0],
[73,30,17,1],
[73,30,17,2],
[73,30,17,4],
[73,30,17,7],
[73,30,17,9],
[73,30,17,10],
[73,30,17,11],
[73,30,17,12],
[73,30,17,13],
[73,30,17,14],
[73,30,17,15],
[73,30,17,16],
[73,30,18,0],
[73,30,18,1],
[73,30,18,2],
[73,30,18,4],
[73,30,18,7],
[73,30,18,10],
[73,30,18,11],
[73,30,18,12],
[73,30,18,13],
[73,30,18,14],
[73,30,19,0],
[73,30,19,1],
[73,30,19,2],
[73,30,19,4],
[73,30,19,7],
[73,30,19,9],
[73,30,19,10],
[73,30,19,11],
[73,30,19,12],
[73,30,19,16],
[73,30,19,17],
[73,30,19,18],
[73,30,20,0],
[73,30,20,1],
[73,30,20,2],
[73,30,20,4],
[73,30,20,7],
[73,30,20,9],
[73,30,20,11],
[73,30,20,12],
[73,30,20,13],
[73,30,20,15],
[73,30,20,16],
[73,30,20,17],
[73,30,20,18],
[73,30,20,19],
[73,30,22,0],
[73,30,22,1],
[73,30,22,2],
[73,30,22,4],
[73,30,22,7],
[73,30,22,9],
[73,30,22,12],
[73,30,22,13],
[73,30,22,14],
[73,30,22,15],
[73,30,22,16],
[73,30,22,17],
[73,30,22,18],
[73,30,22,19],
[73,30,24,0],
[73,30,24,1],
[73,30,24,2],
[73,30,24,4],
[73,30,24,7],
[73,30,24,10],
[73,30,24,11],
[73,30,24,12],
[73,30,24,13],
[73,30,24,14],
[73,30,24,15],
[73,30,24,17],
[73,30,24,18],
[73,30,24,19],
[73,30,24,20],
[73,30,24,22],
[73,30,25,0],
[73,30,25,1],
[73,30,25,2],
[73,30,25,4],
[73,30,25,9],
[73,30,25,10],
[73,30,25,11],
[73,30,25,12],
[73,30,25,13],
[73,30,25,14],
[73,30,25,15],
[73,30,25,16],
[73,30,25,17],
[73,30,25,18],
[73,30,25,19],
[73,30,25,20],
[73,30,25,22],
[73,30,26,0],
[73,30,26,1],
[73,30,26,2],
[73,30,26,7],
[73,30,26,10],
[73,30,26,11],
[73,30,26,12],
[73,30,26,13],
[73,30,26,14],
[73,30,26,16],
[73,30,26,17],
[73,30,26,19],
[73,30,26,20],
[73,30,27,4],
[73,30,27,7],
[73,30,27,9],
[73,30,27,11],
[73,30,27,12],
[73,30,27,13],
[73,30,27,14],
[73,30,27,15],
[73,30,27,16],
[73,30,27,17],
[73,30,27,18],
[73,30,27,19],
[73,30,28,0],
[73,30,28,1],
[73,30,28,2],
[73,30,28,4],
[73,30,28,9],
[73,30,28,10],
[73,30,28,11],
[73,30,28,12],
[73,30,28,13],
[73,30,28,14],
[73,30,28,15],
[73,30,28,16],
[73,30,28,17],
[73,30,28,18],
[73,30,28,19],
[73,30,29,0],
[73,30,29,1],
[73,30,29,2],
[73,30,29,4],
[73,30,29,7],
[73,30,29,9],
[73,30,29,10],
[73,30,29,13],
[73,30,29,14],
[73,30,29,15],
[73,30,29,16],
[73,30,29,17],
[73,30,29,18],
[73,31,4,0],
[73,31,4,1],
[73,31,4,2],
[73,31,7,0],
[73,31,7,1],
[73,31,7,2],
[73,31,7,4],
[73,31,9,0],
[73,31,9,1],
[73,31,9,2],
[73,31,9,4],
[73,31,9,7],
[73,31,10,0],
[73,31,10,1],
[73,31,10,2],
[73,31,10,4],
[73,31,10,7],
[73,31,10,9],
[73,31,11,0],
[73,31,11,1],
[73,31,11,2],
[73,31,11,4],
[73,31,11,7],
[73,31,11,9],
[73,31,11,10],
[73,31,12,0],
[73,31,12,1],
[73,31,12,2],
[73,31,12,4],
[73,31,12,7],
[73,31,12,9],
[73,31,12,10],
[73,31,13,0],
[73,31,13,1],
[73,31,13,2],
[73,31,13,4],
[73,31,13,7],
[73,31,13,9],
[73,31,13,10],
[73,31,13,11],
[73,31,13,12],
[73,31,14,0],
[73,31,14,1],
[73,31,14,2],
[73,31,14,4],
[73,31,14,7],
[73,31,14,9],
[73,31,14,10],
[73,31,14,11],
[73,31,14,12],
[73,31,15,0],
[73,31,15,1],
[73,31,15,2],
[73,31,15,4],
[73,31,15,7],
[73,31,15,10],
[73,31,15,11],
[73,31,15,12],
[73,31,15,13],
[73,31,15,14],
[73,31,16,0],
[73,31,16,1],
[73,31,16,2],
[73,31,16,4],
[73,31,16,7],
[73,31,16,9],
[73,31,16,10],
[73,31,16,11],
[73,31,16,12],
[73,31,16,13],
[73,31,16,14],
[73,31,16,15],
[73,31,17,0],
[73,31,17,1],
[73,31,17,2],
[73,31,17,4],
[73,31,17,7],
[73,31,17,9],
[73,31,17,10],
[73,31,17,11],
[73,31,17,12],
[73,31,17,13],
[73,31,17,14],
[73,31,17,15],
[73,31,18,0],
[73,31,18,1],
[73,31,18,2],
[73,31,18,4],
[73,31,18,7],
[73,31,18,10],
[73,31,18,11],
[73,31,18,12],
[73,31,18,13],
[73,31,18,14],
[73,31,18,16],
[73,31,18,17],
[73,31,19,0],
[73,31,19,1],
[73,31,19,2],
[73,31,19,4],
[73,31,19,7],
[73,31,19,9],
[73,31,19,10],
[73,31,19,11],
[73,31,19,12],
[73,31,19,15],
[73,31,19,16],
[73,31,19,17],
[73,31,19,18],
[73,31,20,0],
[73,31,20,1],
[73,31,20,2],
[73,31,20,4],
[73,31,20,7],
[73,31,20,9],
[73,31,20,11],
[73,31,20,14],
[73,31,20,15],
[73,31,20,16],
[73,31,20,17],
[73,31,20,18],
[73,31,20,19],
[73,31,22,0],
[73,31,22,1],
[73,31,22,2],
[73,31,22,4],
[73,31,22,7],
[73,31,22,11],
[73,31,22,12],
[73,31,22,13],
[73,31,22,14],
[73,31,22,15],
[73,31,22,16],
[73,31,22,17],
[73,31,22,18],
[73,31,22,19],
[73,31,24,0],
[73,31,24,1],
[73,31,24,2],
[73,31,24,4],
[73,31,24,9],
[73,31,24,10],
[73,31,24,11],
[73,31,24,12],
[73,31,24,13],
[73,31,24,14],
[73,31,24,15],
[73,31,24,17],
[73,31,24,18],
[73,31,24,19],
[73,31,24,20],
[73,31,24,22],
[73,31,25,0],
[73,31,25,1],
[73,31,25,2],
[73,31,25,9],
[73,31,25,10],
[73,31,25,11],
[73,31,25,12],
[73,31,25,13],
[73,31,25,14],
[73,31,25,15],
[73,31,25,16],
[73,31,25,17],
[73,31,25,18],
[73,31,25,19],
[73,31,25,20],
[73,31,26,4],
[73,31,26,7],
[73,31,26,10],
[73,31,26,11],
[73,31,26,12],
[73,31,26,13],
[73,31,26,14],
[73,31,26,16],
[73,31,26,17],
[73,31,26,19],
[73,31,26,20],
[73,31,27,0],
[73,31,27,1],
[73,31,27,2],
[73,31,27,4],
[73,31,27,7],
[73,31,27,9],
[73,31,27,11],
[73,31,27,12],
[73,31,27,13],
[73,31,27,14],
[73,31,27,15],
[73,31,27,16],
[73,31,27,17],
[73,31,27,18],
[73,31,27,19],
[73,31,28,0],
[73,31,28,1],
[73,31,28,2],
[73,31,28,4],
[73,31,28,9],
[73,31,28,10],
[73,31,28,11],
[73,31,28,12],
[73,31,28,13],
[73,31,28,14],
[73,31,28,15],
[73,31,28,16],
[73,31,28,17],
[73,31,28,18],
[73,31,29,0],
[73,31,29,1],
[73,31,29,2],
[73,31,29,4],
[73,31,29,7],
[73,31,29,9],
[73,31,29,10],
[73,31,29,13],
[73,31,29,14],
[73,31,29,15],
[73,31,29,16],
[73,31,29,17],
[73,32,4,0],
[73,32,4,1],
[73,32,4,2],
[73,32,7,0],
[73,32,7,1],
[73,32,7,2],
[73,32,7,4],
[73,32,9,0],
[73,32,9,1],
[73,32,9,2],
[73,32,9,4],
[73,32,9,7],
[73,32,10,0],
[73,32,10,1],
[73,32,10,2],
[73,32,10,4],
[73,32,10,7],
[73,32,10,9],
[73,32,13,0],
[73,32,13,1],
[73,32,13,2],
[73,32,13,4],
[73,32,13,7],
[73,32,13,9],
[73,32,13,10],
[73,32,14,0],
[73,32,14,1],
[73,32,14,2],
[73,32,14,4],
[73,32,14,7],
[73,32,14,9],
[73,32,14,10],
[73,32,15,0],
[73,32,15,1],
[73,32,15,2],
[73,32,15,4],
[73,32,15,7],
[73,32,15,10],
[73,32,15,13],
[73,32,15,14],
[73,32,16,0],
[73,32,16,1],
[73,32,16,2],
[73,32,16,4],
[73,32,16,7],
[73,32,16,9],
[73,32,16,10],
[73,32,16,13],
[73,32,16,14],
[73,32,17,0],
[73,32,17,1],
[73,32,17,2],
[73,32,17,4],
[73,32,17,7],
[73,32,17,9],
[73,32,17,10],
[73,32,17,13],
[73,32,17,14],
[73,32,17,16],
[73,32,18,0],
[73,32,18,1],
[73,32,18,2],
[73,32,18,4],
[73,32,18,7],
[73,32,18,10],
[73,32,18,13],
[73,32,18,16],
[73,32,18,17],
[73,32,19,0],
[73,32,19,1],
[73,32,19,2],
[73,32,19,4],
[73,32,19,7],
[73,32,19,9],
[73,32,19,10],
[73,32,19,15],
[73,32,19,16],
[73,32,19,17],
[73,32,19,18],
[73,32,20,0],
[73,32,20,1],
[73,32,20,2],
[73,32,20,4],
[73,32,20,7],
[73,32,20,9],
[73,32,20,13],
[73,32,20,14],
[73,32,20,15],
[73,32,20,16],
[73,32,20,17],
[73,32,20,18],
[73,32,20,19],
[73,32,22,0],
[73,32,22,1],
[73,32,22,2],
[73,32,22,4],
[73,32,22,9],
[73,32,22,13],
[73,32,22,14],
[73,32,22,15],
[73,32,22,16],
[73,32,22,17],
[73,32,22,18],
[73,32,22,19],
[73,32,23,0],
[73,32,23,1],
[73,32,23,2],
[73,32,23,4],
[73,32,23,7],
[73,32,23,9],
[73,32,23,10],
[73,32,23,13],
[73,32,23,14],
[73,32,23,15],
[73,32,23,16],
[73,32,23,17],
[73,32,23,18],
[73,32,23,19],
[73,32,23,20],
[73,32,23,22],
[73,32,24,0],
[73,32,24,1],
[73,32,24,2],
[73,32,24,4],
[73,32,24,7],
[73,32,24,9],
[73,32,24,10],
[73,32,24,13],
[73,32,24,14],
[73,32,24,15],
[73,32,24,17],
[73,32,24,18],
[73,32,24,19],
[73,32,24,20],
[73,32,24,22],
[73,32,25,4],
[73,32,25,9],
[73,32,25,10],
[73,32,25,13],
[73,32,25,14],
[73,32,25,15],
[73,32,25,16],
[73,32,25,17],
[73,32,25,18],
[73,32,25,19],
[73,32,25,20],
[73,32,26,0],
[73,32,26,1],
[73,32,26,2],
[73,32,26,4],
[73,32,26,7],
[73,32,26,10],
[73,32,26,13],
[73,32,26,14],
[73,32,26,16],
[73,32,26,17],
[73,32,26,19],
[73,32,27,0],
[73,32,27,1],
[73,32,27,2],
[73,32,27,4],
[73,32,27,7],
[73,32,27,9],
[73,32,27,13],
[73,32,27,14],
[73,32,27,15],
[73,32,27,16],
[73,32,27,17],
[73,32,27,18],
[73,32,28,0],
[73,32,28,1],
[73,32,28,2],
[73,32,28,4],
[73,32,28,9],
[73,32,28,10],
[73,32,28,13],
[73,32,28,14],
[73,32,28,15],
[73,32,28,16],
[73,32,28,17],
[73,32,30,0],
[73,32,30,1],
[73,32,30,2],
[73,32,30,4],
[73,32,30,7],
[73,32,30,9],
[73,32,30,10],
[73,32,30,13],
[73,32,30,14],
[73,32,31,0],
[73,32,31,1],
[73,32,31,2],
[73,32,31,4],
[73,32,31,7],
[73,32,31,9],
[73,32,31,10],
[73,32,31,13],
[73,33,4,0],
[73,33,4,1],
[73,33,4,2],
[73,33,7,0],
[73,33,7,1],
[73,33,7,2],
[73,33,7,4],
[73,33,9,0],
[73,33,9,1],
[73,33,9,2],
[73,33,9,4],
[73,33,9,7],
[73,33,10,0],
[73,33,10,1],
[73,33,10,2],
[73,33,10,4],
[73,33,10,7],
[73,33,10,9],
[73,33,13,0],
[73,33,13,1],
[73,33,13,2],
[73,33,13,4],
[73,33,13,7],
[73,33,13,9],
[73,33,13,10],
[73,33,14,0],
[73,33,14,1],
[73,33,14,2],
[73,33,14,4],
[73,33,14,7],
[73,33,14,9],
[73,33,14,10],
[73,33,15,0],
[73,33,15,1],
[73,33,15,2],
[73,33,15,4],
[73,33,15,7],
[73,33,15,10],
[73,33,15,13],
[73,33,15,14],
[73,33,16,0],
[73,33,16,1],
[73,33,16,2],
[73,33,16,4],
[73,33,16,7],
[73,33,16,9],
[73,33,16,10],
[73,33,16,13],
[73,33,16,15],
[73,33,17,0],
[73,33,17,1],
[73,33,17,2],
[73,33,17,4],
[73,33,17,7],
[73,33,17,9],
[73,33,17,10],
[73,33,17,13],
[73,33,17,15],
[73,33,17,16],
[73,33,18,0],
[73,33,18,1],
[73,33,18,2],
[73,33,18,4],
[73,33,18,7],
[73,33,18,10],
[73,33,18,14],
[73,33,18,16],
[73,33,18,17],
[73,33,19,0],
[73,33,19,1],
[73,33,19,2],
[73,33,19,4],
[73,33,19,7],
[73,33,19,9],
[73,33,19,15],
[73,33,19,16],
[73,33,19,17],
[73,33,19,18],
[73,33,20,0],
[73,33,20,1],
[73,33,20,2],
[73,33,20,4],
[73,33,20,7],
[73,33,20,13],
[73,33,20,14],
[73,33,20,15],
[73,33,20,16],
[73,33,20,17],
[73,33,20,18],
[73,33,20,19],
[73,33,22,0],
[73,33,22,1],
[73,33,22,2],
[73,33,22,4],
[73,33,22,7],
[73,33,22,9],
[73,33,22,13],
[73,33,22,14],
[73,33,22,15],
[73,33,22,16],
[73,33,22,17],
[73,33,22,18],
[73,33,22,19],
[73,33,23,0],
[73,33,23,1],
[73,33,23,2],
[73,33,23,7],
[73,33,23,9],
[73,33,23,10],
[73,33,23,13],
[73,33,23,14],
[73,33,23,15],
[73,33,23,16],
[73,33,23,17],
[73,33,23,18],
[73,33,23,19],
[73,33,23,20],
[73,33,24,0],
[73,33,24,1],
[73,33,24,2],
[73,33,24,7],
[73,33,24,9],
[73,33,24,10],
[73,33,24,13],
[73,33,24,14],
[73,33,24,15],
[73,33,24,17],
[73,33,24,18],
[73,33,24,19],
[73,33,24,20],
[73,33,25,0],
[73,33,25,1],
[73,33,25,2],
[73,33,25,4],
[73,33,25,9],
[73,33,25,10],
[73,33,25,13],
[73,33,25,14],
[73,33,25,15],
[73,33,25,16],
[73,33,25,17],
[73,33,25,18],
[73,33,25,19],
[73,33,26,0],
[73,33,26,1],
[73,33,26,2],
[73,33,26,4],
[73,33,26,7],
[73,33,26,10],
[73,33,26,13],
[73,33,26,14],
[73,33,26,16],
[73,33,26,17],
[73,33,27,0],
[73,33,27,1],
[73,33,27,2],
[73,33,27,4],
[73,33,27,7],
[73,33,27,9],
[73,33,27,13],
[73,33,27,14],
[73,33,27,15],
[73,33,27,16],
[73,33,27,17],
[73,33,28,0],
[73,33,28,1],
[73,33,28,2],
[73,33,28,4],
[73,33,28,9],
[73,33,28,10],
[73,33,28,13],
[73,33,28,14],
[73,33,28,15],
[73,33,30,0],
[73,33,30,1],
[73,33,30,2],
[73,33,30,4],
[73,33,30,7],
[73,33,30,9],
[73,33,30,10],
[73,33,30,13],
[73,33,31,0],
[73,33,31,1],
[73,33,31,2],
[73,33,31,4],
[73,33,31,7],
[73,33,31,9],
[73,33,31,10],
[73,34,4,0],
[73,34,4,1],
[73,34,4,2],
[73,34,9,0],
[73,34,9,1],
[73,34,9,2],
[73,34,9,4],
[73,34,10,0],
[73,34,10,1],
[73,34,10,2],
[73,34,10,4],
[73,34,10,9],
[73,34,11,0],
[73,34,11,1],
[73,34,11,2],
[73,34,11,4],
[73,34,11,9],
[73,34,11,10],
[73,34,12,0],
[73,34,12,1],
[73,34,12,2],
[73,34,12,4],
[73,34,12,9],
[73,34,12,10],
[73,34,13,0],
[73,34,13,1],
[73,34,13,2],
[73,34,13,4],
[73,34,13,9],
[73,34,13,10],
[73,34,13,11],
[73,34,13,12],
[73,34,14,0],
[73,34,14,1],
[73,34,14,2],
[73,34,14,4],
[73,34,14,9],
[73,34,14,10],
[73,34,14,11],
[73,34,14,12],
[73,34,15,0],
[73,34,15,1],
[73,34,15,2],
[73,34,15,4],
[73,34,15,10],
[73,34,15,11],
[73,34,15,12],
[73,34,15,13],
[73,34,16,0],
[73,34,16,1],
[73,34,16,2],
[73,34,16,4],
[73,34,16,9],
[73,34,16,10],
[73,34,16,11],
[73,34,16,14],
[73,34,16,15],
[73,34,17,0],
[73,34,17,1],
[73,34,17,2],
[73,34,17,4],
[73,34,17,9],
[73,34,17,10],
[73,34,17,11],
[73,34,17,14],
[73,34,17,15],
[73,34,17,16],
[73,34,18,0],
[73,34,18,1],
[73,34,18,2],
[73,34,18,4],
[73,34,18,12],
[73,34,18,13],
[73,34,18,14],
[73,34,18,16],
[73,34,18,17],
[73,34,19,0],
[73,34,19,1],
[73,34,19,2],
[73,34,19,4],
[73,34,19,10],
[73,34,19,11],
[73,34,19,12],
[73,34,19,15],
[73,34,19,16],
[73,34,19,17],
[73,34,19,18],
[73,34,20,0],
[73,34,20,1],
[73,34,20,2],
[73,34,20,4],
[73,34,20,9],
[73,34,20,11],
[73,34,20,12],
[73,34,20,13],
[73,34,20,14],
[73,34,20,15],
[73,34,20,16],
[73,34,20,17],
[73,34,20,18],
[73,34,20,19],
[73,34,22,0],
[73,34,22,1],
[73,34,22,2],
[73,34,22,9],
[73,34,22,11],
[73,34,22,12],
[73,34,22,13],
[73,34,22,14],
[73,34,22,15],
[73,34,22,16],
[73,34,22,17],
[73,34,22,18],
[73,34,22,19],
[73,34,23,4],
[73,34,23,9],
[73,34,23,10],
[73,34,23,11],
[73,34,23,12],
[73,34,23,13],
[73,34,23,14],
[73,34,23,15],
[73,34,23,16],
[73,34,23,17],
[73,34,23,18],
[73,34,23,19],
[73,34,23,20],
[73,34,24,4],
[73,34,24,9],
[73,34,24,10],
[73,34,24,11],
[73,34,24,12],
[73,34,24,13],
[73,34,24,14],
[73,34,24,15],
[73,34,24,17],
[73,34,24,18],
[73,34,24,19],
[73,34,24,20],
[73,34,26,0],
[73,34,26,1],
[73,34,26,2],
[73,34,26,4],
[73,34,26,10],
[73,34,26,11],
[73,34,26,12],
[73,34,26,13],
[73,34,26,14],
[73,34,26,16],
[73,34,26,17],
[73,34,27,0],
[73,34,27,1],
[73,34,27,2],
[73,34,27,4],
[73,34,27,9],
[73,34,27,11],
[73,34,27,12],
[73,34,27,13],
[73,34,27,14],
[73,34,27,15],
[73,34,29,0],
[73,34,29,1],
[73,34,29,2],
[73,34,29,4],
[73,34,29,9],
[73,34,29,10],
[73,34,29,13],
[73,34,30,0],
[73,34,30,1],
[73,34,30,2],
[73,34,30,4],
[73,34,30,9],
[73,34,30,10],
[73,34,30,11],
[73,34,31,0],
[73,34,31,1],
[73,34,31,2],
[73,34,31,4],
[73,34,31,9],
[73,34,32,0],
[73,34,32,1],
[73,34,32,2],
[73,34,32,4],
[73,34,33,0],
[73,34,33,1],
[73,34,33,2],
[73,34,33,4],
[73,35,4,0],
[73,35,4,1],
[73,35,4,2],
[73,35,7,0],
[73,35,7,1],
[73,35,7,2],
[73,35,7,4],
[73,35,10,0],
[73,35,10,1],
[73,35,10,2],
[73,35,10,4],
[73,35,10,7],
[73,35,11,0],
[73,35,11,1],
[73,35,11,2],
[73,35,11,4],
[73,35,11,7],
[73,35,11,10],
[73,35,12,0],
[73,35,12,1],
[73,35,12,2],
[73,35,12,4],
[73,35,12,7],
[73,35,12,10],
[73,35,13,0],
[73,35,13,1],
[73,35,13,2],
[73,35,13,4],
[73,35,13,7],
[73,35,13,10],
[73,35,13,11],
[73,35,13,12],
[73,35,14,0],
[73,35,14,1],
[73,35,14,2],
[73,35,14,4],
[73,35,14,7],
[73,35,14,10],
[73,35,14,11],
[73,35,14,12],
[73,35,16,0],
[73,35,16,1],
[73,35,16,2],
[73,35,16,4],
[73,35,16,7],
[73,35,16,12],
[73,35,16,13],
[73,35,16,14],
[73,35,17,0],
[73,35,17,1],
[73,35,17,2],
[73,35,17,4],
[73,35,17,7],
[73,35,17,12],
[73,35,17,13],
[73,35,17,14],
[73,35,17,16],
[73,35,19,0],
[73,35,19,1],
[73,35,19,2],
[73,35,19,4],
[73,35,19,10],
[73,35,19,11],
[73,35,19,12],
[73,35,19,16],
[73,35,19,17],
[73,35,20,0],
[73,35,20,1],
[73,35,20,2],
[73,35,20,4],
[73,35,20,7],
[73,35,20,11],
[73,35,20,12],
[73,35,20,13],
[73,35,20,14],
[73,35,20,16],
[73,35,20,17],
[73,35,20,19],
[73,35,22,4],
[73,35,22,7],
[73,35,22,11],
[73,35,22,12],
[73,35,22,13],
[73,35,22,14],
[73,35,22,16],
[73,35,22,17],
[73,35,22,19],
[73,35,23,0],
[73,35,23,1],
[73,35,23,2],
[73,35,23,4],
[73,35,23,7],
[73,35,23,10],
[73,35,23,11],
[73,35,23,12],
[73,35,23,13],
[73,35,23,14],
[73,35,23,16],
[73,35,23,17],
[73,35,23,19],
[73,35,24,0],
[73,35,24,1],
[73,35,24,2],
[73,35,24,4],
[73,35,24,7],
[73,35,24,10],
[73,35,24,11],
[73,35,24,12],
[73,35,24,13],
[73,35,24,14],
[73,35,24,17],
[73,35,24,19],
[73,35,25,0],
[73,35,25,1],
[73,35,25,2],
[73,35,25,4],
[73,35,25,10],
[73,35,25,11],
[73,35,25,12],
[73,35,25,13],
[73,35,25,14],
[73,35,25,16],
[73,35,25,17],
[73,35,27,0],
[73,35,27,1],
[73,35,27,2],
[73,35,27,4],
[73,35,27,7],
[73,35,27,11],
[73,35,27,12],
[73,35,27,13],
[73,35,27,14],
[73,35,28,0],
[73,35,28,1],
[73,35,28,2],
[73,35,28,4],
[73,35,28,10],
[73,35,28,11],
[73,35,28,12],
[73,35,28,13],
[73,35,29,0],
[73,35,29,1],
[73,35,29,2],
[73,35,29,4],
[73,35,29,7],
[73,35,29,10],
[73,35,30,0],
[73,35,30,1],
[73,35,30,2],
[73,35,30,4],
[73,35,30,7],
[73,35,31,0],
[73,35,31,1],
[73,35,31,2],
[73,35,31,4],
[73,35,31,7],
[73,35,32,0],
[73,35,32,1],
[73,35,32,2],
[73,35,32,4],
[73,35,33,0],
[73,35,33,1],
[73,35,33,2],
[73,35,33,4],
[73,35,34,0],
[73,35,34,1],
[73,35,34,2],
[73,36,4,0],
[73,36,4,1],
[73,36,4,2],
[73,36,7,0],
[73,36,7,1],
[73,36,7,2],
[73,36,7,4],
[73,36,9,0],
[73,36,9,1],
[73,36,9,2],
[73,36,9,4],
[73,36,9,7],
[73,36,10,0],
[73,36,10,1],
[73,36,10,2],
[73,36,10,4],
[73,36,10,7],
[73,36,10,9],
[73,36,11,0],
[73,36,11,1],
[73,36,11,2],
[73,36,11,4],
[73,36,11,7],
[73,36,11,9],
[73,36,11,10],
[73,36,12,0],
[73,36,12,1],
[73,36,12,2],
[73,36,12,4],
[73,36,12,7],
[73,36,12,9],
[73,36,12,10],
[73,36,15,0],
[73,36,15,1],
[73,36,15,2],
[73,36,15,4],
[73,36,15,7],
[73,36,15,12],
[73,36,16,0],
[73,36,16,1],
[73,36,16,2],
[73,36,16,4],
[73,36,16,7],
[73,36,16,10],
[73,36,16,11],
[73,36,16,12],
[73,36,16,15],
[73,36,17,0],
[73,36,17,1],
[73,36,17,2],
[73,36,17,4],
[73,36,17,7],
[73,36,17,10],
[73,36,17,11],
[73,36,17,12],
[73,36,17,15],
[73,36,17,16],
[73,36,18,0],
[73,36,18,1],
[73,36,18,2],
[73,36,18,4],
[73,36,18,10],
[73,36,18,11],
[73,36,18,12],
[73,36,18,16],
[73,36,18,17],
[73,36,20,0],
[73,36,20,1],
[73,36,20,2],
[73,36,20,7],
[73,36,20,9],
[73,36,20,11],
[73,36,20,12],
[73,36,20,15],
[73,36,20,16],
[73,36,20,17],
[73,36,20,18],
[73,36,22,0],
[73,36,22,1],
[73,36,22,2],
[73,36,22,4],
[73,36,22,7],
[73,36,22,9],
[73,36,22,11],
[73,36,22,12],
[73,36,22,15],
[73,36,22,16],
[73,36,22,17],
[73,36,22,18],
[73,36,23,0],
[73,36,23,1],
[73,36,23,2],
[73,36,23,4],
[73,36,23,7],
[73,36,23,9],
[73,36,23,10],
[73,36,23,11],
[73,36,23,12],
[73,36,23,15],
[73,36,23,16],
[73,36,23,17],
[73,36,23,18],
[73,36,24,0],
[73,36,24,1],
[73,36,24,2],
[73,36,24,4],
[73,36,24,7],
[73,36,24,9],
[73,36,24,10],
[73,36,24,11],
[73,36,24,12],
[73,36,24,15],
[73,36,24,17],
[73,36,24,18],
[73,36,25,0],
[73,36,25,1],
[73,36,25,2],
[73,36,25,4],
[73,36,25,9],
[73,36,25,10],
[73,36,25,11],
[73,36,25,12],
[73,36,25,15],
[73,36,26,0],
[73,36,26,1],
[73,36,26,2],
[73,36,26,4],
[73,36,26,7],
[73,36,26,10],
[73,36,26,11],
[73,36,26,12],
[73,36,27,0],
[73,36,27,1],
[73,36,27,2],
[73,36,27,4],
[73,36,27,7],
[73,36,27,9],
[73,36,27,11],
[73,36,27,12],
[73,36,28,0],
[73,36,28,1],
[73,36,28,2],
[73,36,28,4],
[73,36,28,9],
[73,36,28,10],
[73,36,28,11],
[73,36,29,0],
[73,36,29,1],
[73,36,29,2],
[73,36,29,4],
[73,36,29,7],
[73,36,29,9],
[73,36,30,0],
[73,36,30,1],
[73,36,30,2],
[73,36,30,4],
[73,36,30,7],
[73,36,31,0],
[73,36,31,1],
[73,36,31,2],
[73,36,31,4],
[73,36,32,0],
[73,36,32,1],
[73,36,32,2],
[73,36,32,4],
[73,36,33,0],
[73,36,33,1],
[73,36,33,2],
[73,37,7,0],
[73,37,7,1],
[73,37,7,2],
[73,37,9,0],
[73,37,9,1],
[73,37,9,2],
[73,37,9,7],
[73,37,10,0],
[73,37,10,1],
[73,37,10,2],
[73,37,10,7],
[73,37,10,9],
[73,37,11,0],
[73,37,11,1],
[73,37,11,2],
[73,37,11,7],
[73,37,11,9],
[73,37,11,10],
[73,37,12,0],
[73,37,12,1],
[73,37,12,2],
[73,37,12,7],
[73,37,12,9],
[73,37,12,10],
[73,37,13,0],
[73,37,13,1],
[73,37,13,2],
[73,37,13,7],
[73,37,13,9],
[73,37,13,10],
[73,37,13,11],
[73,37,14,0],
[73,37,14,1],
[73,37,14,2],
[73,37,14,7],
[73,37,14,9],
[73,37,14,12],
[73,37,15,0],
[73,37,15,1],
[73,37,15,2],
[73,37,15,7],
[73,37,15,10],
[73,37,15,11],
[73,37,15,12],
[73,37,15,13],
[73,37,15,14],
[73,37,16,0],
[73,37,16,1],
[73,37,16,2],
[73,37,16,9],
[73,37,16,10],
[73,37,16,11],
[73,37,16,12],
[73,37,16,13],
[73,37,16,14],
[73,37,16,15],
[73,37,17,0],
[73,37,17,1],
[73,37,17,2],
[73,37,17,9],
[73,37,17,10],
[73,37,17,11],
[73,37,17,12],
[73,37,17,13],
[73,37,17,14],
[73,37,17,15],
[73,37,17,16],
[73,37,18,0],
[73,37,18,1],
[73,37,18,2],
[73,37,18,7],
[73,37,18,10],
[73,37,18,11],
[73,37,18,12],
[73,37,18,13],
[73,37,18,14],
[73,37,18,16],
[73,37,18,17],
[73,37,19,0],
[73,37,19,1],
[73,37,19,2],
[73,37,19,7],
[73,37,19,9],
[73,37,19,10],
[73,37,19,11],
[73,37,19,12],
[73,37,19,15],
[73,37,19,16],
[73,37,19,17],
[73,37,19,18],
[73,37,20,7],
[73,37,20,9],
[73,37,20,11],
[73,37,20,12],
[73,37,20,13],
[73,37,20,14],
[73,37,20,15],
[73,37,20,16],
[73,37,20,17],
[73,37,20,18],
[73,37,20,19],
[73,37,22,0],
[73,37,22,1],
[73,37,22,2],
[73,37,22,7],
[73,37,22,9],
[73,37,22,11],
[73,37,22,12],
[73,37,22,13],
[73,37,22,14],
[73,37,22,15],
[73,37,22,16],
[73,37,22,17],
[73,37,22,18],
[73,37,23,0],
[73,37,23,1],
[73,37,23,2],
[73,37,23,7],
[73,37,23,9],
[73,37,23,10],
[73,37,23,11],
[73,37,23,12],
[73,37,23,13],
[73,37,23,14],
[73,37,23,15],
[73,37,23,16],
[73,37,23,17],
[73,37,24,0],
[73,37,24,1],
[73,37,24,2],
[73,37,24,7],
[73,37,24,9],
[73,37,24,10],
[73,37,24,11],
[73,37,24,12],
[73,37,24,13],
[73,37,24,14],
[73,37,24,15],
[73,37,24,17],
[73,37,25,0],
[73,37,25,1],
[73,37,25,2],
[73,37,25,9],
[73,37,25,10],
[73,37,25,11],
[73,37,25,12],
[73,37,25,13],
[73,37,25,14],
[73,37,26,0],
[73,37,26,1],
[73,37,26,2],
[73,37,26,7],
[73,37,26,10],
[73,37,26,11],
[73,37,26,12],
[73,37,26,13],
[73,37,27,0],
[73,37,27,1],
[73,37,27,2],
[73,37,27,7],
[73,37,27,9],
[73,37,27,11],
[73,37,28,0],
[73,37,28,1],
[73,37,28,2],
[73,37,28,9],
[73,37,29,0],
[73,37,29,1],
[73,37,29,2],
[73,37,29,7],
[73,37,30,0],
[73,37,30,1],
[73,37,30,2],
[73,37,31,0],
[73,37,31,1],
[73,37,31,2],
[73,37,32,0],
[73,37,32,1],
[73,37,32,2],
[73,38,4,0],
[73,38,4,1],
[73,38,4,2],
[73,38,9,0],
[73,38,9,1],
[73,38,9,2],
[73,38,9,4],
[73,38,10,0],
[73,38,10,1],
[73,38,10,2],
[73,38,10,4],
[73,38,10,9],
[73,38,11,0],
[73,38,11,1],
[73,38,11,2],
[73,38,11,4],
[73,38,11,9],
[73,38,11,10],
[73,38,12,0],
[73,38,12,1],
[73,38,12,2],
[73,38,12,4],
[73,38,12,9],
[73,38,13,0],
[73,38,13,1],
[73,38,13,2],
[73,38,13,4],
[73,38,13,9],
[73,38,13,12],
[73,38,14,0],
[73,38,14,1],
[73,38,14,2],
[73,38,14,4],
[73,38,14,10],
[73,38,14,11],
[73,38,14,12],
[73,38,15,0],
[73,38,15,1],
[73,38,15,2],
[73,38,15,4],
[73,38,15,10],
[73,38,15,11],
[73,38,15,12],
[73,38,15,13],
[73,38,15,14],
[73,38,16,0],
[73,38,16,1],
[73,38,16,2],
[73,38,16,4],
[73,38,16,9],
[73,38,16,10],
[73,38,16,11],
[73,38,16,12],
[73,38,16,13],
[73,38,16,14],
[73,38,16,15],
[73,38,17,0],
[73,38,17,1],
[73,38,17,2],
[73,38,17,4],
[73,38,17,9],
[73,38,17,10],
[73,38,17,11],
[73,38,17,12],
[73,38,17,13],
[73,38,17,14],
[73,38,17,15],
[73,38,17,16],
[73,38,18,0],
[73,38,18,1],
[73,38,18,2],
[73,38,18,10],
[73,38,18,11],
[73,38,18,12],
[73,38,18,13],
[73,38,18,14],
[73,38,18,16],
[73,38,18,17],
[73,38,19,4],
[73,38,19,9],
[73,38,19,10],
[73,38,19,11],
[73,38,19,12],
[73,38,19,15],
[73,38,19,16],
[73,38,19,17],
[73,38,19,18],
[73,38,20,0],
[73,38,20,1],
[73,38,20,2],
[73,38,20,4],
[73,38,20,9],
[73,38,20,11],
[73,38,20,12],
[73,38,20,13],
[73,38,20,14],
[73,38,20,15],
[73,38,20,16],
[73,38,20,17],
[73,38,20,18],
[73,38,20,19],
[73,38,22,0],
[73,38,22,1],
[73,38,22,2],
[73,38,22,4],
[73,38,22,9],
[73,38,22,11],
[73,38,22,12],
[73,38,22,13],
[73,38,22,14],
[73,38,22,15],
[73,38,22,16],
[73,38,22,17],
[73,38,23,0],
[73,38,23,1],
[73,38,23,2],
[73,38,23,4],
[73,38,23,9],
[73,38,23,10],
[73,38,23,11],
[73,38,23,12],
[73,38,23,13],
[73,38,23,14],
[73,38,23,15],
[73,38,24,0],
[73,38,24,1],
[73,38,24,2],
[73,38,24,4],
[73,38,24,9],
[73,38,24,10],
[73,38,24,11],
[73,38,24,12],
[73,38,24,13],
[73,38,24,14],
[73,38,24,15],
[73,38,26,0],
[73,38,26,1],
[73,38,26,2],
[73,38,26,4],
[73,38,26,10],
[73,38,26,11],
[73,38,27,0],
[73,38,27,1],
[73,38,27,2],
[73,38,27,4],
[73,38,27,9],
[73,38,29,0],
[73,38,29,1],
[73,38,29,2],
[73,38,29,4],
[73,38,30,0],
[73,38,30,1],
[73,38,30,2],
[73,38,30,4],
[73,38,31,0],
[73,38,31,1],
[73,38,31,2],
[73,40,4,0],
[73,40,4,1],
[73,40,4,2],
[73,40,7,0],
[73,40,7,1],
[73,40,7,2],
[73,40,7,4],
[73,40,10,0],
[73,40,10,1],
[73,40,10,2],
[73,40,10,4],
[73,40,10,7],
[73,40,11,0],
[73,40,11,1],
[73,40,11,2],
[73,40,11,4],
[73,40,11,7],
[73,40,11,10],
[73,40,12,0],
[73,40,12,1],
[73,40,12,2],
[73,40,12,4],
[73,40,12,10],
[73,40,13,0],
[73,40,13,1],
[73,40,13,2],
[73,40,13,4],
[73,40,13,10],
[73,40,13,11],
[73,40,13,12],
[73,40,14,0],
[73,40,14,1],
[73,40,14,2],
[73,40,14,4],
[73,40,14,7],
[73,40,14,10],
[73,40,14,11],
[73,40,14,12],
[73,40,16,4],
[73,40,16,7],
[73,40,16,10],
[73,40,16,11],
[73,40,16,12],
[73,40,16,13],
[73,40,16,14],
[73,40,17,4],
[73,40,17,7],
[73,40,17,10],
[73,40,17,11],
[73,40,17,12],
[73,40,17,13],
[73,40,17,14],
[73,40,17,16],
[73,40,19,0],
[73,40,19,1],
[73,40,19,2],
[73,40,19,4],
[73,40,19,7],
[73,40,19,10],
[73,40,19,11],
[73,40,19,12],
[73,40,19,16],
[73,40,19,17],
[73,40,20,0],
[73,40,20,1],
[73,40,20,2],
[73,40,20,4],
[73,40,20,7],
[73,40,20,11],
[73,40,20,12],
[73,40,20,13],
[73,40,20,14],
[73,40,20,16],
[73,40,20,17],
[73,40,22,0],
[73,40,22,1],
[73,40,22,2],
[73,40,22,4],
[73,40,22,7],
[73,40,22,11],
[73,40,22,12],
[73,40,22,13],
[73,40,22,14],
[73,40,23,0],
[73,40,23,1],
[73,40,23,2],
[73,40,23,4],
[73,40,23,7],
[73,40,23,10],
[73,40,23,11],
[73,40,23,12],
[73,40,23,13],
[73,40,24,0],
[73,40,24,1],
[73,40,24,2],
[73,40,24,4],
[73,40,24,7],
[73,40,24,10],
[73,40,24,11],
[73,40,24,12],
[73,40,24,13],
[73,40,25,0],
[73,40,25,1],
[73,40,25,2],
[73,40,25,4],
[73,40,27,0],
[73,40,27,1],
[73,40,27,2],
[73,40,27,4],
[73,40,28,0],
[73,40,28,1],
[73,40,28,2],
[73,40,28,4],
[73,40,29,0],
[73,40,29,1],
[73,40,29,2],
[73,41,4,0],
[73,41,4,1],
[73,41,4,2],
[73,41,7,0],
[73,41,7,1],
[73,41,7,2],
[73,41,7,4],
[73,41,10,0],
[73,41,10,1],
[73,41,10,2],
[73,41,10,4],
[73,41,10,7],
[73,41,11,0],
[73,41,11,1],
[73,41,11,2],
[73,41,11,4],
[73,41,11,7],
[73,41,11,10],
[73,41,12,0],
[73,41,12,1],
[73,41,12,2],
[73,41,12,4],
[73,41,12,10],
[73,41,13,0],
[73,41,13,1],
[73,41,13,2],
[73,41,13,4],
[73,41,13,10],
[73,41,13,11],
[73,41,13,12],
[73,41,14,0],
[73,41,14,1],
[73,41,14,2],
[73,41,14,4],
[73,41,14,7],
[73,41,14,10],
[73,41,14,11],
[73,41,14,12],
[73,41,16,4],
[73,41,16,7],
[73,41,16,10],
[73,41,16,11],
[73,41,16,12],
[73,41,16,13],
[73,41,16,14],
[73,41,17,4],
[73,41,17,7],
[73,41,17,10],
[73,41,17,11],
[73,41,17,12],
[73,41,17,13],
[73,41,17,14],
[73,41,17,16],
[73,41,19,0],
[73,41,19,1],
[73,41,19,2],
[73,41,19,4],
[73,41,19,7],
[73,41,19,10],
[73,41,19,11],
[73,41,19,12],
[73,41,19,16],
[73,41,19,17],
[73,41,20,0],
[73,41,20,1],
[73,41,20,2],
[73,41,20,4],
[73,41,20,7],
[73,41,20,11],
[73,41,20,12],
[73,41,20,13],
[73,41,20,14],
[73,41,20,16],
[73,41,20,17],
[73,41,22,0],
[73,41,22,1],
[73,41,22,2],
[73,41,22,4],
[73,41,22,7],
[73,41,22,11],
[73,41,22,12],
[73,41,22,13],
[73,41,22,14],
[73,41,23,0],
[73,41,23,1],
[73,41,23,2],
[73,41,23,4],
[73,41,23,7],
[73,41,23,10],
[73,41,23,11],
[73,41,23,12],
[73,41,23,13],
[73,41,24,0],
[73,41,24,1],
[73,41,24,2],
[73,41,24,4],
[73,41,24,7],
[73,41,24,10],
[73,41,24,11],
[73,41,24,12],
[73,41,24,13],
[73,41,25,0],
[73,41,25,1],
[73,41,25,2],
[73,41,25,4],
[73,41,27,0],
[73,41,27,1],
[73,41,27,2],
[73,41,27,4],
[73,41,28,0],
[73,41,28,1],
[73,41,28,2],
[73,41,28,4],
[73,41,29,0],
[73,41,29,1],
[73,41,29,2],
[73,42,4,0],
[73,42,4,1],
[73,42,4,2],
[73,42,7,0],
[73,42,7,1],
[73,42,7,2],
[73,42,7,4],
[73,42,9,0],
[73,42,9,1],
[73,42,9,2],
[73,42,9,4],
[73,42,9,7],
[73,42,11,0],
[73,42,11,1],
[73,42,11,2],
[73,42,11,4],
[73,42,11,9],
[73,42,12,0],
[73,42,12,1],
[73,42,12,2],
[73,42,12,4],
[73,42,12,7],
[73,42,12,9],
[73,42,13,0],
[73,42,13,1],
[73,42,13,2],
[73,42,13,4],
[73,42,13,7],
[73,42,13,9],
[73,42,13,11],
[73,42,13,12],
[73,42,14,0],
[73,42,14,1],
[73,42,14,2],
[73,42,14,7],
[73,42,14,9],
[73,42,14,11],
[73,42,14,12],
[73,42,15,4],
[73,42,15,7],
[73,42,15,11],
[73,42,15,12],
[73,42,15,13],
[73,42,15,14],
[73,42,16,0],
[73,42,16,1],
[73,42,16,2],
[73,42,16,4],
[73,42,16,7],
[73,42,16,9],
[73,42,16,11],
[73,42,16,12],
[73,42,16,13],
[73,42,16,14],
[73,42,16,15],
[73,42,17,0],
[73,42,17,1],
[73,42,17,2],
[73,42,17,4],
[73,42,17,7],
[73,42,17,9],
[73,42,17,11],
[73,42,17,12],
[73,42,17,13],
[73,42,17,14],
[73,42,17,15],
[73,42,17,16],
[73,42,18,0],
[73,42,18,1],
[73,42,18,2],
[73,42,18,4],
[73,42,18,7],
[73,42,18,11],
[73,42,18,12],
[73,42,18,13],
[73,42,18,14],
[73,42,18,16],
[73,42,18,17],
[73,42,19,0],
[73,42,19,1],
[73,42,19,2],
[73,42,19,4],
[73,42,19,7],
[73,42,19,9],
[73,42,19,11],
[73,42,19,12],
[73,42,19,15],
[73,42,19,16],
[73,42,19,17],
[73,42,23,0],
[73,42,23,1],
[73,42,23,2],
[73,42,23,4],
[73,42,23,7],
[73,42,23,9],
[73,42,23,11],
[73,42,24,0],
[73,42,24,1],
[73,42,24,2],
[73,42,24,4],
[73,42,24,7],
[73,42,24,9],
[73,42,24,11],
[73,42,25,0],
[73,42,25,1],
[73,42,25,2],
[73,42,25,4],
[73,42,26,0],
[73,42,26,1],
[73,42,26,2],
[73,42,26,4],
[73,42,28,0],
[73,42,28,1],
[73,42,28,2],
[73,43,4,0],
[73,43,4,1],
[73,43,4,2],
[73,43,9,0],
[73,43,9,1],
[73,43,9,2],
[73,43,9,4],
[73,43,10,0],
[73,43,10,1],
[73,43,10,2],
[73,43,10,4],
[73,43,10,9],
[73,43,11,0],
[73,43,11,1],
[73,43,11,2],
[73,43,11,4],
[73,43,11,9],
[73,43,11,10],
[73,43,12,0],
[73,43,12,1],
[73,43,12,2],
[73,43,12,9],
[73,43,12,10],
[73,43,13,0],
[73,43,13,1],
[73,43,13,2],
[73,43,13,9],
[73,43,13,10],
[73,43,13,11],
[73,43,13,12],
[73,43,14,4],
[73,43,14,9],
[73,43,14,10],
[73,43,14,11],
[73,43,14,12],
[73,43,15,0],
[73,43,15,1],
[73,43,15,2],
[73,43,15,4],
[73,43,15,10],
[73,43,15,11],
[73,43,15,12],
[73,43,15,13],
[73,43,15,14],
[73,43,16,0],
[73,43,16,1],
[73,43,16,2],
[73,43,16,4],
[73,43,16,9],
[73,43,16,10],
[73,43,16,11],
[73,43,16,12],
[73,43,16,13],
[73,43,16,14],
[73,43,16,15],
[73,43,17,0],
[73,43,17,1],
[73,43,17,2],
[73,43,17,4],
[73,43,17,9],
[73,43,17,10],
[73,43,17,11],
[73,43,17,12],
[73,43,17,13],
[73,43,17,14],
[73,43,17,15],
[73,43,17,16],
[73,43,18,0],
[73,43,18,1],
[73,43,18,2],
[73,43,18,4],
[73,43,18,10],
[73,43,18,11],
[73,43,18,12],
[73,43,18,13],
[73,43,18,14],
[73,43,18,16],
[73,43,18,17],
[73,43,19,0],
[73,43,19,1],
[73,43,19,2],
[73,43,19,4],
[73,43,19,9],
[73,43,19,10],
[73,43,19,11],
[73,43,19,12],
[73,43,19,15],
[73,43,20,0],
[73,43,20,1],
[73,43,20,2],
[73,43,20,4],
[73,43,20,9],
[73,43,20,11],
[73,43,20,12],
[73,43,20,13],
[73,43,20,14],
[73,43,22,0],
[73,43,22,1],
[73,43,22,2],
[73,43,22,4],
[73,43,22,9],
[73,43,22,11],
[73,43,23,0],
[73,43,23,1],
[73,43,23,2],
[73,43,23,4],
[73,43,23,9],
[73,43,24,0],
[73,43,24,1],
[73,43,24,2],
[73,43,24,4],
[73,43,24,9],
[73,43,26,0],
[73,43,26,1],
[73,43,26,2],
[73,43,26,4],
[73,43,27,0],
[73,43,27,1],
[73,43,27,2],
[73,44,4,0],
[73,44,4,1],
[73,44,4,2],
[73,44,7,0],
[73,44,7,1],
[73,44,7,2],
[73,44,7,4],
[73,44,9,0],
[73,44,9,1],
[73,44,9,2],
[73,44,9,4],
[73,44,9,7],
[73,44,10,0],
[73,44,10,1],
[73,44,10,2],
[73,44,10,7],
[73,44,10,9],
[73,44,11,0],
[73,44,11,1],
[73,44,11,2],
[73,44,11,7],
[73,44,11,9],
[73,44,11,10],
[73,44,12,4],
[73,44,12,7],
[73,44,12,9],
[73,44,12,10],
[73,44,13,4],
[73,44,13,7],
[73,44,13,9],
[73,44,13,10],
[73,44,13,11],
[73,44,13,12],
[73,44,14,0],
[73,44,14,1],
[73,44,14,2],
[73,44,14,4],
[73,44,14,7],
[73,44,14,9],
[73,44,14,10],
[73,44,14,11],
[73,44,14,12],
[73,44,15,0],
[73,44,15,1],
[73,44,15,2],
[73,44,15,4],
[73,44,15,7],
[73,44,15,10],
[73,44,15,11],
[73,44,15,12],
[73,44,15,13],
[73,44,15,14],
[73,44,16,0],
[73,44,16,1],
[73,44,16,2],
[73,44,16,4],
[73,44,16,7],
[73,44,16,9],
[73,44,16,10],
[73,44,16,11],
[73,44,16,12],
[73,44,16,13],
[73,44,16,14],
[73,44,16,15],
[73,44,18,0],
[73,44,18,1],
[73,44,18,2],
[73,44,18,4],
[73,44,18,7],
[73,44,18,10],
[73,44,18,11],
[73,44,18,12],
[73,44,18,13],
[73,44,18,14],
[73,44,19,0],
[73,44,19,1],
[73,44,19,2],
[73,44,19,4],
[73,44,19,7],
[73,44,19,9],
[73,44,19,10],
[73,44,19,11],
[73,44,19,12],
[73,44,20,0],
[73,44,20,1],
[73,44,20,2],
[73,44,20,4],
[73,44,20,7],
[73,44,20,9],
[73,44,20,11],
[73,44,20,12],
[73,44,20,13],
[73,44,22,0],
[73,44,22,1],
[73,44,22,2],
[73,44,22,4],
[73,44,22,7],
[73,44,22,9],
[73,44,23,0],
[73,44,23,1],
[73,44,23,2],
[73,44,23,4],
[73,44,23,7],
[73,44,24,0],
[73,44,24,1],
[73,44,24,2],
[73,44,24,4],
[73,44,24,7],
[73,44,25,0],
[73,44,25,1],
[73,44,25,2],
[73,44,25,4],
[73,44,26,0],
[73,44,26,1],
[73,44,26,2],
[73,45,4,0],
[73,45,4,1],
[73,45,4,2],
[73,45,7,0],
[73,45,7,1],
[73,45,7,2],
[73,45,7,4],
[73,45,10,4],
[73,45,10,7],
[73,45,11,4],
[73,45,11,7],
[73,45,11,10],
[73,45,12,0],
[73,45,12,1],
[73,45,12,2],
[73,45,12,4],
[73,45,12,7],
[73,45,12,10],
[73,45,13,0],
[73,45,13,1],
[73,45,13,2],
[73,45,13,4],
[73,45,13,7],
[73,45,13,10],
[73,45,13,11],
[73,45,13,12],
[73,45,14,0],
[73,45,14,1],
[73,45,14,2],
[73,45,14,4],
[73,45,14,7],
[73,45,14,10],
[73,45,14,11],
[73,45,14,12],
[73,45,16,0],
[73,45,16,1],
[73,45,16,2],
[73,45,16,4],
[73,45,16,7],
[73,45,16,10],
[73,45,16,11],
[73,45,16,12],
[73,45,16,13],
[73,45,16,14],
[73,45,17,0],
[73,45,17,1],
[73,45,17,2],
[73,45,17,4],
[73,45,17,7],
[73,45,17,10],
[73,45,17,11],
[73,45,17,12],
[73,45,17,13],
[73,45,17,14],
[73,45,19,0],
[73,45,19,1],
[73,45,19,2],
[73,45,19,4],
[73,45,19,7],
[73,45,19,10],
[73,45,19,11],
[73,45,19,12],
[73,45,20,0],
[73,45,20,1],
[73,45,20,2],
[73,45,20,4],
[73,45,20,7],
[73,45,20,11],
[73,45,22,0],
[73,45,22,1],
[73,45,22,2],
[73,45,22,4],
[73,45,22,7],
[73,45,23,0],
[73,45,23,1],
[73,45,23,2],
[73,45,23,4],
[73,45,24,0],
[73,45,24,1],
[73,45,24,2],
[73,45,24,4],
[73,45,25,0],
[73,45,25,1],
[73,45,25,2],
[73,46,4,0],
[73,46,4,1],
[73,46,4,2],
[73,46,7,0],
[73,46,7,1],
[73,46,7,2],
[73,46,10,0],
[73,46,10,1],
[73,46,10,2],
[73,46,10,4],
[73,46,10,7],
[73,46,11,0],
[73,46,11,1],
[73,46,11,2],
[73,46,11,4],
[73,46,11,7],
[73,46,11,10],
[73,46,12,0],
[73,46,12,1],
[73,46,12,2],
[73,46,12,4],
[73,46,12,7],
[73,46,12,10],
[73,46,13,0],
[73,46,13,1],
[73,46,13,2],
[73,46,13,4],
[73,46,13,7],
[73,46,13,10],
[73,46,13,11],
[73,46,13,12],
[73,46,14,0],
[73,46,14,1],
[73,46,14,2],
[73,46,14,4],
[73,46,14,7],
[73,46,14,10],
[73,46,14,11],
[73,46,14,12],
[73,46,16,0],
[73,46,16,1],
[73,46,16,2],
[73,46,16,4],
[73,46,16,7],
[73,46,16,10],
[73,46,16,11],
[73,46,16,12],
[73,46,16,13],
[73,46,16,14],
[73,46,17,0],
[73,46,17,1],
[73,46,17,2],
[73,46,17,4],
[73,46,17,7],
[73,46,17,10],
[73,46,17,11],
[73,46,17,12],
[73,46,17,13],
[73,46,17,14],
[73,46,19,0],
[73,46,19,1],
[73,46,19,2],
[73,46,19,4],
[73,46,19,7],
[73,46,19,10],
[73,46,19,11],
[73,46,20,0],
[73,46,20,1],
[73,46,20,2],
[73,46,20,4],
[73,46,20,7],
[73,46,22,0],
[73,46,22,1],
[73,46,22,2],
[73,46,22,4],
[73,46,23,0],
[73,46,23,1],
[73,46,23,2],
[73,46,23,4],
[73,46,24,0],
[73,46,24,1],
[73,46,24,2],
[73,46,24,4],
[73,47,4,0],
[73,47,4,1],
[73,47,4,2],
[73,47,7,4],
[73,47,9,0],
[73,47,9,1],
[73,47,9,2],
[73,47,9,4],
[73,47,9,7],
[73,47,10,0],
[73,47,10,1],
[73,47,10,2],
[73,47,10,4],
[73,47,10,7],
[73,47,10,9],
[73,47,11,0],
[73,47,11,1],
[73,47,11,2],
[73,47,11,4],
[73,47,11,7],
[73,47,11,9],
[73,47,11,10],
[73,47,12,0],
[73,47,12,1],
[73,47,12,2],
[73,47,12,4],
[73,47,12,7],
[73,47,12,9],
[73,47,12,10],
[73,47,13,0],
[73,47,13,1],
[73,47,13,2],
[73,47,13,4],
[73,47,13,7],
[73,47,13,9],
[73,47,13,10],
[73,47,13,11],
[73,47,13,12],
[73,47,14,0],
[73,47,14,1],
[73,47,14,2],
[73,47,14,4],
[73,47,14,7],
[73,47,14,9],
[73,47,14,10],
[73,47,14,11],
[73,47,14,12],
[73,47,15,0],
[73,47,15,1],
[73,47,15,2],
[73,47,15,4],
[73,47,15,7],
[73,47,15,10],
[73,47,15,11],
[73,47,15,12],
[73,47,15,13],
[73,47,15,14],
[73,47,16,0],
[73,47,16,1],
[73,47,16,2],
[73,47,16,4],
[73,47,16,7],
[73,47,16,9],
[73,47,16,10],
[73,47,16,11],
[73,47,16,12],
[73,47,16,13],
[73,47,17,0],
[73,47,17,1],
[73,47,17,2],
[73,47,17,4],
[73,47,17,7],
[73,47,17,9],
[73,47,17,10],
[73,47,17,11],
[73,47,17,12],
[73,47,17,13],
[73,47,18,0],
[73,47,18,1],
[73,47,18,2],
[73,47,18,4],
[73,47,18,7],
[73,47,18,10],
[73,47,18,11],
[73,47,19,0],
[73,47,19,1],
[73,47,19,2],
[73,47,19,4],
[73,47,19,7],
[73,47,19,9],
[73,47,20,0],
[73,47,20,1],
[73,47,20,2],
[73,47,20,4],
[73,47,20,7],
[73,47,22,0],
[73,47,22,1],
[73,47,22,2],
[73,47,22,4],
[73,47,24,0],
[73,47,24,1],
[73,47,24,2],
[73,48,4,0],
[73,48,4,1],
[73,48,4,2],
[73,48,7,0],
[73,48,7,1],
[73,48,7,2],
[73,48,7,4],
[73,48,9,0],
[73,48,9,1],
[73,48,9,2],
[73,48,9,4],
[73,48,9,7],
[73,48,10,0],
[73,48,10,1],
[73,48,10,2],
[73,48,10,4],
[73,48,10,7],
[73,48,10,9],
[73,48,13,0],
[73,48,13,1],
[73,48,13,2],
[73,48,13,4],
[73,48,13,7],
[73,48,13,9],
[73,48,13,10],
[73,48,14,0],
[73,48,14,1],
[73,48,14,2],
[73,48,14,4],
[73,48,14,7],
[73,48,14,9],
[73,48,14,10],
[73,48,15,0],
[73,48,15,1],
[73,48,15,2],
[73,48,15,4],
[73,48,15,7],
[73,48,15,10],
[73,48,15,13],
[73,48,16,0],
[73,48,16,1],
[73,48,16,2],
[73,48,16,4],
[73,48,16,7],
[73,48,16,9],
[73,48,16,10],
[73,48,17,0],
[73,48,17,1],
[73,48,17,2],
[73,48,17,4],
[73,48,17,7],
[73,48,17,9],
[73,48,17,10],
[73,48,18,0],
[73,48,18,1],
[73,48,18,2],
[73,48,18,4],
[73,48,18,7],
[73,48,19,0],
[73,48,19,1],
[73,48,19,2],
[73,48,19,4],
[73,48,19,7],
[73,48,20,0],
[73,48,20,1],
[73,48,20,2],
[73,48,20,4],
[73,48,22,0],
[73,48,22,1],
[73,48,22,2],
[73,49,4,0],
[73,49,4,1],
[73,49,4,2],
[73,49,7,0],
[73,49,7,1],
[73,49,7,2],
[73,49,7,4],
[73,49,10,0],
[73,49,10,1],
[73,49,10,2],
[73,49,10,4],
[73,49,10,7],
[73,49,11,0],
[73,49,11,1],
[73,49,11,2],
[73,49,11,4],
[73,49,11,7],
[73,49,11,10],
[73,49,12,0],
[73,49,12,1],
[73,49,12,2],
[73,49,12,4],
[73,49,12,7],
[73,49,12,10],
[73,49,13,0],
[73,49,13,1],
[73,49,13,2],
[73,49,13,4],
[73,49,13,7],
[73,49,13,10],
[73,49,13,11],
[73,49,13,12],
[73,49,14,0],
[73,49,14,1],
[73,49,14,2],
[73,49,14,4],
[73,49,14,7],
[73,49,14,10],
[73,49,14,11],
[73,49,14,12],
[73,49,16,0],
[73,49,16,1],
[73,49,16,2],
[73,49,16,4],
[73,49,16,7],
[73,49,16,10],
[73,49,16,11],
[73,49,17,0],
[73,49,17,1],
[73,49,17,2],
[73,49,17,4],
[73,49,17,7],
[73,49,17,10],
[73,49,17,11],
[73,49,19,0],
[73,49,19,1],
[73,49,19,2],
[73,49,19,4],
[73,49,19,7],
[73,49,20,0],
[73,49,20,1],
[73,49,20,2],
[73,49,20,4],
[73,49,22,0],
[73,49,22,1],
[73,49,22,2],
[73,50,4,0],
[73,50,4,1],
[73,50,4,2],
[73,50,7,0],
[73,50,7,1],
[73,50,7,2],
[73,50,7,4],
[73,50,10,0],
[73,50,10,1],
[73,50,10,2],
[73,50,10,4],
[73,50,10,7],
[73,50,11,0],
[73,50,11,1],
[73,50,11,2],
[73,50,11,4],
[73,50,11,7],
[73,50,11,10],
[73,50,12,0],
[73,50,12,1],
[73,50,12,2],
[73,50,12,4],
[73,50,12,7],
[73,50,12,10],
[73,50,13,0],
[73,50,13,1],
[73,50,13,2],
[73,50,13,4],
[73,50,13,7],
[73,50,13,10],
[73,50,13,11],
[73,50,13,12],
[73,50,14,0],
[73,50,14,1],
[73,50,14,2],
[73,50,14,4],
[73,50,14,7],
[73,50,14,10],
[73,50,14,11],
[73,50,14,12],
[73,50,16,0],
[73,50,16,1],
[73,50,16,2],
[73,50,16,4],
[73,50,16,7],
[73,50,16,10],
[73,50,16,11],
[73,50,17,0],
[73,50,17,1],
[73,50,17,2],
[73,50,17,4],
[73,50,17,7],
[73,50,17,10],
[73,50,17,11],
[73,50,19,0],
[73,50,19,1],
[73,50,19,2],
[73,50,19,4],
[73,50,19,7],
[73,50,20,0],
[73,50,20,1],
[73,50,20,2],
[73,50,20,4],
[73,50,22,0],
[73,50,22,1],
[73,50,22,2],
[73,52,4,0],
[73,52,4,1],
[73,52,4,2],
[73,52,7,0],
[73,52,7,1],
[73,52,7,2],
[73,52,7,4],
[73,52,10,0],
[73,52,10,1],
[73,52,10,2],
[73,52,10,4],
[73,52,10,7],
[73,52,11,0],
[73,52,11,1],
[73,52,11,2],
[73,52,11,4],
[73,52,11,7],
[73,52,11,10],
[73,52,12,0],
[73,52,12,1],
[73,52,12,2],
[73,52,12,4],
[73,52,12,7],
[73,52,12,10],
[73,52,13,0],
[73,52,13,1],
[73,52,13,2],
[73,52,13,4],
[73,52,13,7],
[73,52,13,10],
[73,52,13,11],
[73,52,13,12],
[73,52,14,0],
[73,52,14,1],
[73,52,14,2],
[73,52,14,4],
[73,52,14,7],
[73,52,14,10],
[73,52,14,11],
[73,52,14,12],
[73,52,16,0],
[73,52,16,1],
[73,52,16,2],
[73,52,16,4],
[73,52,16,7],
[73,52,16,10],
[73,52,16,11],
[73,52,17,0],
[73,52,17,1],
[73,52,17,2],
[73,52,17,4],
[73,52,17,7],
[73,52,17,10],
[73,52,17,11],
[73,52,19,0],
[73,52,19,1],
[73,52,19,2],
[73,52,19,4],
[73,52,19,7],
[73,52,20,0],
[73,52,20,1],
[73,52,20,2],
[73,52,20,4],
[73,52,22,0],
[73,52,22,1],
[73,52,22,2],
[73,53,4,0],
[73,53,4,1],
[73,53,4,2],
[73,53,7,0],
[73,53,7,1],
[73,53,7,2],
[73,53,7,4],
[73,53,9,0],
[73,53,9,1],
[73,53,9,2],
[73,53,9,4],
[73,53,9,7],
[73,53,11,0],
[73,53,11,1],
[73,53,11,2],
[73,53,11,4],
[73,53,11,7],
[73,53,11,9],
[73,53,12,0],
[73,53,12,1],
[73,53,12,2],
[73,53,12,4],
[73,53,12,7],
[73,53,12,9],
[73,53,13,0],
[73,53,13,1],
[73,53,13,2],
[73,53,13,4],
[73,53,13,7],
[73,53,13,9],
[73,53,13,11],
[73,53,13,12],
[73,53,14,0],
[73,53,14,1],
[73,53,14,2],
[73,53,14,4],
[73,53,14,7],
[73,53,14,9],
[73,53,14,11],
[73,53,14,12],
[73,53,15,0],
[73,53,15,1],
[73,53,15,2],
[73,53,15,4],
[73,53,15,7],
[73,53,15,11],
[73,53,15,12],
[73,53,15,13],
[73,53,16,0],
[73,53,16,1],
[73,53,16,2],
[73,53,16,4],
[73,53,16,7],
[73,53,16,9],
[73,53,16,11],
[73,53,17,0],
[73,53,17,1],
[73,53,17,2],
[73,53,17,4],
[73,53,17,7],
[73,53,17,9],
[73,53,17,11],
[73,53,18,0],
[73,53,18,1],
[73,53,18,2],
[73,53,18,4],
[73,53,18,7],
[73,53,19,0],
[73,53,19,1],
[73,53,19,2],
[73,53,19,4],
[73,53,19,7],
[73,54,4,0],
[73,54,4,1],
[73,54,4,2],
[73,54,7,0],
[73,54,7,1],
[73,54,7,2],
[73,54,7,4],
[73,54,9,0],
[73,54,9,1],
[73,54,9,2],
[73,54,9,4],
[73,54,9,7],
[73,54,11,0],
[73,54,11,1],
[73,54,11,2],
[73,54,11,4],
[73,54,11,7],
[73,54,11,9],
[73,54,12,0],
[73,54,12,1],
[73,54,12,2],
[73,54,12,4],
[73,54,12,7],
[73,54,12,9],
[73,54,13,0],
[73,54,13,1],
[73,54,13,2],
[73,54,13,4],
[73,54,13,7],
[73,54,13,9],
[73,54,13,11],
[73,54,13,12],
[73,54,14,0],
[73,54,14,1],
[73,54,14,2],
[73,54,14,4],
[73,54,14,7],
[73,54,14,9],
[73,54,14,11],
[73,54,14,12],
[73,54,15,0],
[73,54,15,1],
[73,54,15,2],
[73,54,15,4],
[73,54,15,7],
[73,54,15,11],
[73,54,15,12],
[73,54,15,13],
[73,54,16,0],
[73,54,16,1],
[73,54,16,2],
[73,54,16,4],
[73,54,16,7],
[73,54,16,9],
[73,54,16,11],
[73,54,17,0],
[73,54,17,1],
[73,54,17,2],
[73,54,17,4],
[73,54,17,7],
[73,54,17,9],
[73,54,17,11],
[73,54,18,0],
[73,54,18,1],
[73,54,18,2],
[73,54,18,4],
[73,54,18,7],
[73,54,19,0],
[73,54,19,1],
[73,54,19,2],
[73,54,19,4],
[73,54,19,7],
[73,55,4,0],
[73,55,4,1],
[73,55,4,2],
[73,55,7,0],
[73,55,7,1],
[73,55,7,2],
[73,55,7,4],
[73,55,9,0],
[73,55,9,1],
[73,55,9,2],
[73,55,9,4],
[73,55,9,7],
[73,55,11,0],
[73,55,11,1],
[73,55,11,2],
[73,55,11,4],
[73,55,11,7],
[73,55,11,9],
[73,55,12,0],
[73,55,12,1],
[73,55,12,2],
[73,55,12,4],
[73,55,12,7],
[73,55,12,9],
[73,55,13,0],
[73,55,13,1],
[73,55,13,2],
[73,55,13,4],
[73,55,13,7],
[73,55,13,9],
[73,55,13,11],
[73,55,13,12],
[73,55,14,0],
[73,55,14,1],
[73,55,14,2],
[73,55,14,4],
[73,55,14,7],
[73,55,14,9],
[73,55,14,11],
[73,55,14,12],
[73,55,15,0],
[73,55,15,1],
[73,55,15,2],
[73,55,15,4],
[73,55,15,7],
[73,55,15,11],
[73,55,15,12],
[73,55,15,13],
[73,55,16,0],
[73,55,16,1],
[73,55,16,2],
[73,55,16,4],
[73,55,16,7],
[73,55,16,9],
[73,55,16,11],
[73,55,17,0],
[73,55,17,1],
[73,55,17,2],
[73,55,17,4],
[73,55,17,7],
[73,55,17,9],
[73,55,17,11],
[73,55,18,0],
[73,55,18,1],
[73,55,18,2],
[73,55,18,4],
[73,55,18,7],
[73,55,19,0],
[73,55,19,1],
[73,55,19,2],
[73,55,19,4],
[73,55,19,7],
[73,56,4,0],
[73,56,4,1],
[73,56,4,2],
[73,56,7,0],
[73,56,7,1],
[73,56,7,2],
[73,56,7,4],
[73,56,10,0],
[73,56,10,1],
[73,56,10,2],
[73,56,10,4],
[73,56,10,7],
[73,56,11,0],
[73,56,11,1],
[73,56,11,2],
[73,56,11,4],
[73,56,11,7],
[73,56,11,10],
[73,56,12,0],
[73,56,12,1],
[73,56,12,2],
[73,56,12,4],
[73,56,12,7],
[73,56,12,10],
[73,56,13,0],
[73,56,13,1],
[73,56,13,2],
[73,56,13,4],
[73,56,13,7],
[73,56,13,10],
[73,56,13,11],
[73,56,13,12],
[73,56,14,0],
[73,56,14,1],
[73,56,14,2],
[73,56,14,4],
[73,56,14,7],
[73,56,14,10],
[73,56,14,11],
[73,56,14,12],
[73,56,16,0],
[73,56,16,1],
[73,56,16,2],
[73,56,16,4],
[73,56,16,7],
[73,56,16,10],
[73,56,16,11],
[73,56,17,0],
[73,56,17,1],
[73,56,17,2],
[73,56,17,4],
[73,56,17,7],
[73,56,17,10],
[73,56,17,11],
[73,56,19,0],
[73,56,19,1],
[73,56,19,2],
[73,56,19,4],
[73,56,19,7],
[73,56,20,0],
[73,56,20,1],
[73,56,20,2],
[73,56,20,4],
[73,56,22,0],
[73,56,22,1],
[73,56,22,2],
[73,57,7,0],
[73,57,7,1],
[73,57,7,2],
[73,57,7,4],
[73,57,9,0],
[73,57,9,1],
[73,57,9,2],
[73,57,9,4],
[73,57,9,7],
[73,57,10,0],
[73,57,10,1],
[73,57,10,2],
[73,57,10,4],
[73,57,10,7],
[73,57,10,9],
[73,57,11,0],
[73,57,11,1],
[73,57,11,2],
[73,57,11,4],
[73,57,11,7],
[73,57,11,9],
[73,57,11,10],
[73,57,12,0],
[73,57,12,1],
[73,57,12,2],
[73,57,12,4],
[73,57,12,7],
[73,57,12,9],
[73,57,12,10],
[73,57,13,0],
[73,57,13,1],
[73,57,13,2],
[73,57,13,4],
[73,57,13,7],
[73,57,13,9],
[73,57,13,10],
[73,57,13,11],
[73,57,13,12],
[73,57,14,0],
[73,57,14,1],
[73,57,14,2],
[73,57,14,4],
[73,57,14,7],
[73,57,14,9],
[73,57,14,10],
[73,57,14,11],
[73,57,14,12],
[73,57,15,0],
[73,57,15,1],
[73,57,15,2],
[73,57,15,4],
[73,57,15,7],
[73,57,15,10],
[73,57,15,11],
[73,57,16,0],
[73,57,16,1],
[73,57,16,2],
[73,57,16,4],
[73,57,16,7],
[73,57,16,9],
[73,57,17,0],
[73,57,17,1],
[73,57,17,2],
[73,57,17,4],
[73,57,17,7],
[73,57,17,9],
[73,57,18,0],
[73,57,18,1],
[73,57,18,2],
[73,57,18,4],
[73,57,18,7],
[73,57,19,0],
[73,57,19,1],
[73,57,19,2],
[73,57,19,4],
[73,57,20,0],
[73,57,20,1],
[73,57,20,2],
[73,57,20,4],
[73,58,7,0],
[73,58,7,1],
[73,58,7,2],
[73,58,7,4],
[73,58,9,0],
[73,58,9,1],
[73,58,9,2],
[73,58,9,4],
[73,58,9,7],
[73,58,10,0],
[73,58,10,1],
[73,58,10,2],
[73,58,10,4],
[73,58,10,7],
[73,58,10,9],
[73,58,11,0],
[73,58,11,1],
[73,58,11,2],
[73,58,11,4],
[73,58,11,7],
[73,58,11,9],
[73,58,11,10],
[73,58,12,0],
[73,58,12,1],
[73,58,12,2],
[73,58,12,4],
[73,58,12,7],
[73,58,12,9],
[73,58,12,10],
[73,58,13,0],
[73,58,13,1],
[73,58,13,2],
[73,58,13,4],
[73,58,13,7],
[73,58,13,9],
[73,58,13,10],
[73,58,13,11],
[73,58,13,12],
[73,58,14,0],
[73,58,14,1],
[73,58,14,2],
[73,58,14,4],
[73,58,14,7],
[73,58,14,9],
[73,58,14,10],
[73,58,14,11],
[73,58,14,12],
[73,58,15,0],
[73,58,15,1],
[73,58,15,2],
[73,58,15,4],
[73,58,15,7],
[73,58,15,10],
[73,58,15,11],
[73,58,17,0],
[73,58,17,1],
[73,58,17,2],
[73,58,17,4],
[73,58,17,7],
[73,58,17,9],
[73,58,18,0],
[73,58,18,1],
[73,58,18,2],
[73,58,18,4],
[73,58,18,7],
[73,58,19,0],
[73,58,19,1],
[73,58,19,2],
[73,58,19,4],
[73,58,20,0],
[73,58,20,1],
[73,58,20,2],
[73,58,20,4],
[73,59,4,0],
[73,59,4,1],
[73,59,4,2],
[73,59,7,0],
[73,59,7,1],
[73,59,7,2],
[73,59,7,4],
[73,59,10,0],
[73,59,10,1],
[73,59,10,2],
[73,59,10,4],
[73,59,10,7],
[73,59,11,0],
[73,59,11,1],
[73,59,11,2],
[73,59,11,4],
[73,59,11,7],
[73,59,11,10],
[73,59,12,0],
[73,59,12,1],
[73,59,12,2],
[73,59,12,4],
[73,59,12,7],
[73,59,12,10],
[73,59,13,0],
[73,59,13,1],
[73,59,13,2],
[73,59,13,4],
[73,59,13,7],
[73,59,13,10],
[73,59,13,11],
[73,59,13,12],
[73,59,14,0],
[73,59,14,1],
[73,59,14,2],
[73,59,14,4],
[73,59,14,7],
[73,59,14,10],
[73,59,14,11],
[73,59,16,0],
[73,59,16,1],
[73,59,16,2],
[73,59,16,4],
[73,59,16,7],
[73,59,17,0],
[73,59,17,1],
[73,59,17,2],
[73,59,17,4],
[73,59,17,7],
[73,59,19,0],
[73,59,19,1],
[73,59,19,2],
[73,59,19,4],
[73,59,20,0],
[73,59,20,1],
[73,59,20,2],
[73,60,4,0],
[73,60,4,1],
[73,60,4,2],
[73,60,7,0],
[73,60,7,1],
[73,60,7,2],
[73,60,7,4],
[73,60,9,0],
[73,60,9,1],
[73,60,9,2],
[73,60,9,4],
[73,60,9,7],
[73,60,10,0],
[73,60,10,1],
[73,60,10,2],
[73,60,10,4],
[73,60,10,7],
[73,60,10,9],
[73,60,11,0],
[73,60,11,1],
[73,60,11,2],
[73,60,11,4],
[73,60,11,7],
[73,60,11,9],
[73,60,11,10],
[73,60,12,0],
[73,60,12,1],
[73,60,12,2],
[73,60,12,4],
[73,60,12,7],
[73,60,12,9],
[73,60,12,10],
[73,60,15,0],
[73,60,15,1],
[73,60,15,2],
[73,60,15,4],
[73,60,15,7],
[73,60,16,0],
[73,60,16,1],
[73,60,16,2],
[73,60,16,4],
[73,60,17,0],
[73,60,17,1],
[73,60,17,2],
[73,60,17,4],
[73,60,18,0],
[73,60,18,1],
[73,60,18,2],
[73,60,18,4],
[73,61,7,0],
[73,61,7,1],
[73,61,7,2],
[73,61,9,0],
[73,61,9,1],
[73,61,9,2],
[73,61,9,7],
[73,61,10,0],
[73,61,10,1],
[73,61,10,2],
[73,61,10,7],
[73,61,10,9],
[73,61,11,0],
[73,61,11,1],
[73,61,11,2],
[73,61,11,7],
[73,61,11,9],
[73,61,11,10],
[73,61,12,0],
[73,61,12,1],
[73,61,12,2],
[73,61,12,7],
[73,61,12,9],
[73,61,13,0],
[73,61,13,1],
[73,61,13,2],
[73,61,13,7],
[73,61,13,9],
[73,61,14,0],
[73,61,14,1],
[73,61,14,2],
[73,61,14,7],
[73,61,15,0],
[73,61,15,1],
[73,61,15,2],
[73,61,16,0],
[73,61,16,1],
[73,61,16,2],
[73,61,17,0],
[73,61,17,1],
[73,61,17,2],
[73,61,18,0],
[73,61,18,1],
[73,61,18,2],
[73,62,4,0],
[73,62,4,1],
[73,62,4,2],
[73,62,9,0],
[73,62,9,1],
[73,62,9,2],
[73,62,9,4],
[73,62,10,0],
[73,62,10,1],
[73,62,10,2],
[73,62,10,4],
[73,62,10,9],
[73,62,11,0],
[73,62,11,1],
[73,62,11,2],
[73,62,11,4],
[73,62,11,9],
[73,62,11,10],
[73,62,12,0],
[73,62,12,1],
[73,62,12,2],
[73,62,12,4],
[73,62,12,9],
[73,62,13,0],
[73,62,13,1],
[73,62,13,2],
[73,62,13,4],
[73,62,13,9],
[73,62,14,0],
[73,62,14,1],
[73,62,14,2],
[73,62,14,4],
[73,62,15,0],
[73,62,15,1],
[73,62,15,2],
[73,62,15,4],
[73,62,16,0],
[73,62,16,1],
[73,62,16,2],
[73,62,16,4],
[73,62,17,0],
[73,62,17,1],
[73,62,17,2],
[73,62,17,4],
[73,62,18,0],
[73,62,18,1],
[73,62,18,2],
[73,63,4,0],
[73,63,4,1],
[73,63,4,2],
[73,63,7,0],
[73,63,7,1],
[73,63,7,2],
[73,63,7,4],
[73,63,10,0],
[73,63,10,1],
[73,63,10,2],
[73,63,10,4],
[73,63,10,7],
[73,63,11,0],
[73,63,11,1],
[73,63,11,2],
[73,63,11,4],
[73,63,11,7],
[73,63,12,0],
[73,63,12,1],
[73,63,12,2],
[73,63,12,4],
[73,63,12,7],
[73,63,13,0],
[73,63,13,1],
[73,63,13,2],
[73,63,13,4],
[73,63,13,7],
[73,63,14,0],
[73,63,14,1],
[73,63,14,2],
[73,63,14,4],
[73,63,16,0],
[73,63,16,1],
[73,63,16,2],
[73,63,17,0],
[73,63,17,1],
[73,63,17,2],
[73,64,7,0],
[73,64,7,1],
[73,64,7,2],
[73,64,9,0],
[73,64,9,1],
[73,64,9,2],
[73,64,9,7],
[73,64,10,0],
[73,64,10,1],
[73,64,10,2],
[73,64,10,7],
[73,64,11,0],
[73,64,11,1],
[73,64,11,2],
[73,64,11,7],
[73,64,12,0],
[73,64,12,1],
[73,64,12,2],
[73,64,13,0],
[73,64,13,1],
[73,64,13,2],
[73,64,14,0],
[73,64,14,1],
[73,64,14,2],
[73,64,15,0],
[73,64,15,1],
[73,64,15,2],
[73,65,4,0],
[73,65,4,1],
[73,65,4,2],
[73,65,7,0],
[73,65,7,1],
[73,65,7,2],
[73,65,7,4],
[73,65,9,0],
[73,65,9,1],
[73,65,9,2],
[73,65,9,4],
[73,65,9,7],
[73,65,10,0],
[73,65,10,1],
[73,65,10,2],
[73,65,10,4],
[73,65,11,0],
[73,65,11,1],
[73,65,11,2],
[73,65,11,4],
[73,65,12,0],
[73,65,12,1],
[73,65,12,2],
[73,65,12,4],
[73,65,13,0],
[73,65,13,1],
[73,65,13,2],
[73,65,13,4],
[73,65,14,0],
[73,65,14,1],
[73,65,14,2],
[73,66,4,0],
[73,66,4,1],
[73,66,4,2],
[73,66,9,0],
[73,66,9,1],
[73,66,9,2],
[73,66,9,4],
[73,66,10,0],
[73,66,10,1],
[73,66,10,2],
[73,66,10,4],
[73,66,11,0],
[73,66,11,1],
[73,66,11,2],
[73,66,11,4],
[73,66,12,0],
[73,66,12,1],
[73,66,12,2],
[73,66,13,0],
[73,66,13,1],
[73,66,13,2],
[73,67,4,0],
[73,67,4,1],
[73,67,4,2],
[73,67,9,0],
[73,67,9,1],
[73,67,9,2],
[73,67,9,4],
[73,67,10,0],
[73,67,10,1],
[73,67,10,2],
[73,67,11,0],
[73,67,11,1],
[73,67,11,2],
[73,68,4,0],
[73,68,4,1],
[73,68,4,2],
[73,68,9,0],
[73,68,9,1],
[73,68,9,2],
[73,69,4,0],
[73,69,4,1],
[73,69,4,2],
[73,69,7,0],
[73,69,7,1],
[73,69,7,2],
[73,70,4,0],
[73,70,4,1],
[73,70,4,2],
[73,71,4,0],
[73,71,4,1],
[73,71,4,2],
[74,4,3,0],
[74,4,3,1],
[74,4,3,2],
[74,5,4,0],
[74,5,4,1],
[74,5,4,2],
[74,6,4,0],
[74,6,4,1],
[74,6,4,2],
[74,7,3,0],
[74,7,3,1],
[74,7,3,2],
[74,7,4,0],
[74,7,4,1],
[74,7,4,2],
[74,7,4,3],
[74,7,5,0],
[74,7,5,1],
[74,7,5,2],
[74,7,5,4],
[74,7,6,0],
[74,7,6,1],
[74,7,6,2],
[74,7,6,4],
[74,8,4,0],
[74,8,4,1],
[74,8,4,2],
[74,8,7,0],
[74,8,7,1],
[74,8,7,2],
[74,8,7,4],
[74,10,3,0],
[74,10,3,1],
[74,10,3,2],
[74,10,4,0],
[74,10,4,1],
[74,10,4,2],
[74,10,4,3],
[74,10,5,0],
[74,10,5,1],
[74,10,5,2],
[74,10,5,4],
[74,10,6,0],
[74,10,6,1],
[74,10,6,2],
[74,10,6,4],
[74,10,7,0],
[74,10,7,1],
[74,10,7,2],
[74,10,7,3],
[74,10,7,4],
[74,10,7,5],
[74,10,7,6],
[74,10,8,0],
[74,10,8,1],
[74,10,8,2],
[74,10,8,4],
[74,10,8,7],
[74,11,3,0],
[74,11,3,1],
[74,11,3,2],
[74,11,4,0],
[74,11,4,1],
[74,11,4,2],
[74,11,4,3],
[74,11,5,0],
[74,11,5,1],
[74,11,5,2],
[74,11,5,4],
[74,11,6,0],
[74,11,6,1],
[74,11,6,2],
[74,11,6,4],
[74,11,7,0],
[74,11,7,1],
[74,11,7,2],
[74,11,7,3],
[74,11,7,4],
[74,11,7,5],
[74,11,7,6],
[74,11,8,0],
[74,11,8,1],
[74,11,8,2],
[74,11,8,4],
[74,11,8,7],
[74,11,10,0],
[74,11,10,1],
[74,11,10,2],
[74,11,10,3],
[74,11,10,4],
[74,11,10,5],
[74,11,10,6],
[74,11,10,7],
[74,11,10,8],
[74,12,3,0],
[74,12,3,1],
[74,12,3,2],
[74,12,4,0],
[74,12,4,1],
[74,12,4,2],
[74,12,4,3],
[74,12,5,0],
[74,12,5,1],
[74,12,5,2],
[74,12,5,4],
[74,12,6,0],
[74,12,6,1],
[74,12,6,2],
[74,12,6,4],
[74,12,7,0],
[74,12,7,1],
[74,12,7,2],
[74,12,7,3],
[74,12,7,4],
[74,12,7,5],
[74,12,7,6],
[74,12,8,0],
[74,12,8,1],
[74,12,8,2],
[74,12,8,4],
[74,12,8,7],
[74,12,10,0],
[74,12,10,1],
[74,12,10,2],
[74,12,10,3],
[74,12,10,4],
[74,12,10,5],
[74,12,10,6],
[74,12,10,7],
[74,12,10,8],
[74,13,3,0],
[74,13,3,1],
[74,13,3,2],
[74,13,4,0],
[74,13,4,1],
[74,13,4,2],
[74,13,4,3],
[74,13,5,0],
[74,13,5,1],
[74,13,5,2],
[74,13,5,4],
[74,13,6,0],
[74,13,6,1],
[74,13,6,2],
[74,13,6,4],
[74,13,7,0],
[74,13,7,1],
[74,13,7,2],
[74,13,7,3],
[74,13,7,4],
[74,13,7,5],
[74,13,7,6],
[74,13,8,0],
[74,13,8,1],
[74,13,8,2],
[74,13,8,4],
[74,13,8,7],
[74,13,10,0],
[74,13,10,1],
[74,13,10,2],
[74,13,10,3],
[74,13,10,4],
[74,13,10,5],
[74,13,10,6],
[74,13,10,7],
[74,13,10,8],
[74,13,11,0],
[74,13,11,1],
[74,13,11,2],
[74,13,11,3],
[74,13,11,4],
[74,13,11,5],
[74,13,11,6],
[74,13,11,7],
[74,13,11,8],
[74,13,11,10],
[74,13,12,0],
[74,13,12,1],
[74,13,12,2],
[74,13,12,3],
[74,13,12,4],
[74,13,12,5],
[74,13,12,6],
[74,13,12,7],
[74,13,12,8],
[74,13,12,10],
[74,14,3,0],
[74,14,3,1],
[74,14,3,2],
[74,14,4,0],
[74,14,4,1],
[74,14,4,2],
[74,14,4,3],
[74,14,5,0],
[74,14,5,1],
[74,14,5,2],
[74,14,5,4],
[74,14,6,0],
[74,14,6,1],
[74,14,6,2],
[74,14,6,4],
[74,14,7,0],
[74,14,7,1],
[74,14,7,2],
[74,14,7,3],
[74,14,7,4],
[74,14,7,5],
[74,14,7,6],
[74,14,8,0],
[74,14,8,1],
[74,14,8,2],
[74,14,8,4],
[74,14,8,7],
[74,14,10,0],
[74,14,10,1],
[74,14,10,2],
[74,14,10,3],
[74,14,10,4],
[74,14,10,5],
[74,14,10,6],
[74,14,10,7],
[74,14,10,8],
[74,14,11,0],
[74,14,11,1],
[74,14,11,2],
[74,14,11,3],
[74,14,11,4],
[74,14,11,5],
[74,14,11,6],
[74,14,11,7],
[74,14,11,8],
[74,14,11,10],
[74,14,12,0],
[74,14,12,1],
[74,14,12,2],
[74,14,12,3],
[74,14,12,4],
[74,14,12,5],
[74,14,12,6],
[74,14,12,7],
[74,14,12,8],
[74,14,12,10],
[74,16,3,0],
[74,16,3,1],
[74,16,3,2],
[74,16,4,0],
[74,16,4,1],
[74,16,4,2],
[74,16,4,3],
[74,16,5,0],
[74,16,5,1],
[74,16,5,2],
[74,16,5,4],
[74,16,6,0],
[74,16,6,1],
[74,16,6,2],
[74,16,6,4],
[74,16,7,0],
[74,16,7,1],
[74,16,7,2],
[74,16,7,3],
[74,16,7,4],
[74,16,7,5],
[74,16,7,6],
[74,16,8,0],
[74,16,8,1],
[74,16,8,2],
[74,16,8,4],
[74,16,8,7],
[74,16,10,0],
[74,16,10,1],
[74,16,10,2],
[74,16,10,3],
[74,16,10,4],
[74,16,10,5],
[74,16,10,6],
[74,16,10,7],
[74,16,10,8],
[74,16,11,0],
[74,16,11,1],
[74,16,11,2],
[74,16,11,3],
[74,16,11,4],
[74,16,11,5],
[74,16,11,6],
[74,16,11,7],
[74,16,11,8],
[74,16,11,10],
[74,16,12,0],
[74,16,12,1],
[74,16,12,2],
[74,16,12,3],
[74,16,12,4],
[74,16,12,5],
[74,16,12,6],
[74,16,12,7],
[74,16,12,8],
[74,16,12,10],
[74,16,13,0],
[74,16,13,1],
[74,16,13,2],
[74,16,13,3],
[74,16,13,4],
[74,16,13,5],
[74,16,13,6],
[74,16,13,7],
[74,16,13,8],
[74,16,13,10],
[74,16,13,11],
[74,16,13,12],
[74,16,14,0],
[74,16,14,1],
[74,16,14,2],
[74,16,14,3],
[74,16,14,4],
[74,16,14,5],
[74,16,14,6],
[74,16,14,7],
[74,16,14,8],
[74,16,14,10],
[74,16,14,11],
[74,16,14,12],
[74,17,3,0],
[74,17,3,1],
[74,17,3,2],
[74,17,4,0],
[74,17,4,1],
[74,17,4,2],
[74,17,4,3],
[74,17,5,0],
[74,17,5,1],
[74,17,5,2],
[74,17,5,4],
[74,17,6,0],
[74,17,6,1],
[74,17,6,2],
[74,17,6,4],
[74,17,7,0],
[74,17,7,1],
[74,17,7,2],
[74,17,7,3],
[74,17,7,4],
[74,17,7,5],
[74,17,7,6],
[74,17,8,0],
[74,17,8,1],
[74,17,8,2],
[74,17,8,4],
[74,17,8,7],
[74,17,10,0],
[74,17,10,1],
[74,17,10,2],
[74,17,10,3],
[74,17,10,4],
[74,17,10,5],
[74,17,10,6],
[74,17,10,7],
[74,17,10,8],
[74,17,11,0],
[74,17,11,1],
[74,17,11,2],
[74,17,11,3],
[74,17,11,4],
[74,17,11,5],
[74,17,11,6],
[74,17,11,7],
[74,17,11,8],
[74,17,11,10],
[74,17,12,0],
[74,17,12,1],
[74,17,12,2],
[74,17,12,3],
[74,17,12,4],
[74,17,12,5],
[74,17,12,6],
[74,17,12,7],
[74,17,12,8],
[74,17,12,10],
[74,17,13,0],
[74,17,13,1],
[74,17,13,2],
[74,17,13,3],
[74,17,13,4],
[74,17,13,5],
[74,17,13,6],
[74,17,13,7],
[74,17,13,8],
[74,17,13,10],
[74,17,13,11],
[74,17,13,12],
[74,17,14,0],
[74,17,14,1],
[74,17,14,2],
[74,17,14,3],
[74,17,14,4],
[74,17,14,5],
[74,17,14,6],
[74,17,14,7],
[74,17,14,8],
[74,17,14,10],
[74,17,14,11],
[74,17,14,12],
[74,17,16,0],
[74,17,16,1],
[74,17,16,2],
[74,17,16,3],
[74,17,16,4],
[74,17,16,5],
[74,17,16,6],
[74,17,16,7],
[74,17,16,8],
[74,17,16,10],
[74,17,16,11],
[74,17,16,12],
[74,17,16,13],
[74,17,16,14],
[74,19,3,0],
[74,19,3,1],
[74,19,3,2],
[74,19,4,0],
[74,19,4,1],
[74,19,4,2],
[74,19,4,3],
[74,19,5,0],
[74,19,5,1],
[74,19,5,2],
[74,19,5,4],
[74,19,6,0],
[74,19,6,1],
[74,19,6,2],
[74,19,6,4],
[74,19,7,0],
[74,19,7,1],
[74,19,7,2],
[74,19,7,3],
[74,19,7,4],
[74,19,7,5],
[74,19,7,6],
[74,19,8,0],
[74,19,8,1],
[74,19,8,2],
[74,19,8,4],
[74,19,8,7],
[74,19,10,0],
[74,19,10,1],
[74,19,10,2],
[74,19,10,3],
[74,19,10,4],
[74,19,10,5],
[74,19,10,6],
[74,19,10,7],
[74,19,10,8],
[74,19,11,0],
[74,19,11,1],
[74,19,11,2],
[74,19,11,3],
[74,19,11,4],
[74,19,11,5],
[74,19,11,6],
[74,19,11,7],
[74,19,11,8],
[74,19,11,10],
[74,19,12,0],
[74,19,12,1],
[74,19,12,2],
[74,19,12,3],
[74,19,12,4],
[74,19,12,5],
[74,19,12,6],
[74,19,12,7],
[74,19,12,8],
[74,19,12,10],
[74,19,16,0],
[74,19,16,1],
[74,19,16,2],
[74,19,16,3],
[74,19,16,4],
[74,19,16,5],
[74,19,16,6],
[74,19,16,7],
[74,19,16,8],
[74,19,16,10],
[74,19,16,11],
[74,19,16,12],
[74,19,17,0],
[74,19,17,1],
[74,19,17,2],
[74,19,17,3],
[74,19,17,4],
[74,19,17,5],
[74,19,17,6],
[74,19,17,7],
[74,19,17,8],
[74,19,17,10],
[74,19,17,11],
[74,19,17,12],
[74,19,17,16],
[74,20,3,0],
[74,20,3,1],
[74,20,3,2],
[74,20,4,0],
[74,20,4,1],
[74,20,4,2],
[74,20,4,3],
[74,20,5,0],
[74,20,5,1],
[74,20,5,2],
[74,20,5,4],
[74,20,6,0],
[74,20,6,1],
[74,20,6,2],
[74,20,6,4],
[74,20,7,0],
[74,20,7,1],
[74,20,7,2],
[74,20,7,3],
[74,20,7,4],
[74,20,7,5],
[74,20,7,6],
[74,20,8,0],
[74,20,8,1],
[74,20,8,2],
[74,20,8,4],
[74,20,8,7],
[74,20,11,0],
[74,20,11,1],
[74,20,11,2],
[74,20,11,3],
[74,20,11,4],
[74,20,11,5],
[74,20,11,6],
[74,20,11,7],
[74,20,11,8],
[74,20,12,0],
[74,20,12,1],
[74,20,12,2],
[74,20,12,3],
[74,20,12,4],
[74,20,12,5],
[74,20,12,6],
[74,20,12,7],
[74,20,12,8],
[74,20,13,0],
[74,20,13,1],
[74,20,13,2],
[74,20,13,3],
[74,20,13,4],
[74,20,13,5],
[74,20,13,6],
[74,20,13,7],
[74,20,13,8],
[74,20,13,11],
[74,20,13,12],
[74,20,14,0],
[74,20,14,1],
[74,20,14,2],
[74,20,14,3],
[74,20,14,4],
[74,20,14,5],
[74,20,14,6],
[74,20,14,7],
[74,20,14,8],
[74,20,14,11],
[74,20,14,12],
[74,20,16,0],
[74,20,16,1],
[74,20,16,2],
[74,20,16,3],
[74,20,16,4],
[74,20,16,5],
[74,20,16,6],
[74,20,16,7],
[74,20,16,8],
[74,20,16,11],
[74,20,16,12],
[74,20,16,13],
[74,20,16,14],
[74,20,17,0],
[74,20,17,1],
[74,20,17,2],
[74,20,17,3],
[74,20,17,4],
[74,20,17,5],
[74,20,17,6],
[74,20,17,7],
[74,20,17,8],
[74,20,17,11],
[74,20,17,12],
[74,20,17,13],
[74,20,17,14],
[74,20,17,16],
[74,20,19,0],
[74,20,19,1],
[74,20,19,2],
[74,20,19,3],
[74,20,19,4],
[74,20,19,5],
[74,20,19,6],
[74,20,19,7],
[74,20,19,8],
[74,20,19,11],
[74,20,19,12],
[74,20,19,16],
[74,20,19,17],
[74,21,4,0],
[74,21,4,1],
[74,21,4,2],
[74,21,7,0],
[74,21,7,1],
[74,21,7,2],
[74,21,7,4],
[74,21,10,0],
[74,21,10,1],
[74,21,10,2],
[74,21,10,4],
[74,21,10,7],
[74,21,11,0],
[74,21,11,1],
[74,21,11,2],
[74,21,11,4],
[74,21,11,7],
[74,21,11,10],
[74,21,12,0],
[74,21,12,1],
[74,21,12,2],
[74,21,12,4],
[74,21,12,7],
[74,21,12,10],
[74,21,13,0],
[74,21,13,1],
[74,21,13,2],
[74,21,13,4],
[74,21,13,7],
[74,21,13,10],
[74,21,13,11],
[74,21,13,12],
[74,21,14,0],
[74,21,14,1],
[74,21,14,2],
[74,21,14,4],
[74,21,14,7],
[74,21,14,10],
[74,21,14,11],
[74,21,14,12],
[74,21,16,0],
[74,21,16,1],
[74,21,16,2],
[74,21,16,4],
[74,21,16,7],
[74,21,16,10],
[74,21,16,11],
[74,21,16,12],
[74,21,16,13],
[74,21,16,14],
[74,21,17,0],
[74,21,17,1],
[74,21,17,2],
[74,21,17,4],
[74,21,17,7],
[74,21,17,10],
[74,21,17,11],
[74,21,17,12],
[74,21,17,13],
[74,21,17,14],
[74,21,17,16],
[74,21,19,0],
[74,21,19,1],
[74,21,19,2],
[74,21,19,4],
[74,21,19,7],
[74,21,19,10],
[74,21,19,11],
[74,21,19,12],
[74,21,19,16],
[74,21,19,17],
[74,21,20,0],
[74,21,20,1],
[74,21,20,2],
[74,21,20,4],
[74,21,20,7],
[74,21,20,11],
[74,21,20,12],
[74,21,20,13],
[74,21,20,14],
[74,21,20,16],
[74,21,20,17],
[74,21,20,19],
[74,22,3,0],
[74,22,3,1],
[74,22,3,2],
[74,22,4,0],
[74,22,4,1],
[74,22,4,2],
[74,22,4,3],
[74,22,5,0],
[74,22,5,1],
[74,22,5,2],
[74,22,5,4],
[74,22,6,0],
[74,22,6,1],
[74,22,6,2],
[74,22,6,4],
[74,22,7,0],
[74,22,7,1],
[74,22,7,2],
[74,22,7,3],
[74,22,7,4],
[74,22,7,5],
[74,22,7,6],
[74,22,8,0],
[74,22,8,1],
[74,22,8,2],
[74,22,8,4],
[74,22,8,7],
[74,22,11,0],
[74,22,11,1],
[74,22,11,2],
[74,22,11,3],
[74,22,11,4],
[74,22,11,5],
[74,22,11,6],
[74,22,11,7],
[74,22,11,8],
[74,22,12,0],
[74,22,12,1],
[74,22,12,2],
[74,22,12,3],
[74,22,12,4],
[74,22,12,5],
[74,22,12,6],
[74,22,12,7],
[74,22,12,8],
[74,22,13,0],
[74,22,13,1],
[74,22,13,2],
[74,22,13,3],
[74,22,13,4],
[74,22,13,5],
[74,22,13,6],
[74,22,13,7],
[74,22,13,8],
[74,22,13,11],
[74,22,13,12],
[74,22,14,0],
[74,22,14,1],
[74,22,14,2],
[74,22,14,3],
[74,22,14,4],
[74,22,14,5],
[74,22,14,6],
[74,22,14,7],
[74,22,14,8],
[74,22,14,11],
[74,22,14,12],
[74,22,16,0],
[74,22,16,1],
[74,22,16,2],
[74,22,16,3],
[74,22,16,4],
[74,22,16,5],
[74,22,16,6],
[74,22,16,7],
[74,22,16,8],
[74,22,16,11],
[74,22,16,12],
[74,22,16,13],
[74,22,16,14],
[74,22,17,0],
[74,22,17,1],
[74,22,17,2],
[74,22,17,3],
[74,22,17,4],
[74,22,17,5],
[74,22,17,6],
[74,22,17,7],
[74,22,17,8],
[74,22,17,11],
[74,22,17,12],
[74,22,17,13],
[74,22,17,14],
[74,22,17,16],
[74,22,19,0],
[74,22,19,1],
[74,22,19,2],
[74,22,19,3],
[74,22,19,4],
[74,22,19,5],
[74,22,19,6],
[74,22,19,7],
[74,22,19,8],
[74,22,19,11],
[74,22,19,12],
[74,22,19,16],
[74,22,19,17],
[74,22,21,0],
[74,22,21,1],
[74,22,21,2],
[74,22,21,4],
[74,22,21,7],
[74,22,21,11],
[74,22,21,12],
[74,22,21,13],
[74,22,21,14],
[74,22,21,16],
[74,22,21,17],
[74,22,21,19],
[74,23,3,0],
[74,23,3,1],
[74,23,3,2],
[74,23,4,0],
[74,23,4,1],
[74,23,4,2],
[74,23,4,3],
[74,23,5,0],
[74,23,5,1],
[74,23,5,2],
[74,23,5,4],
[74,23,6,0],
[74,23,6,1],
[74,23,6,2],
[74,23,6,4],
[74,23,7,0],
[74,23,7,1],
[74,23,7,2],
[74,23,7,3],
[74,23,7,4],
[74,23,7,5],
[74,23,7,6],
[74,23,8,0],
[74,23,8,1],
[74,23,8,2],
[74,23,8,4],
[74,23,8,7],
[74,23,10,0],
[74,23,10,1],
[74,23,10,2],
[74,23,10,3],
[74,23,10,4],
[74,23,10,5],
[74,23,10,6],
[74,23,10,7],
[74,23,10,8],
[74,23,11,0],
[74,23,11,1],
[74,23,11,2],
[74,23,11,3],
[74,23,11,4],
[74,23,11,5],
[74,23,11,6],
[74,23,11,7],
[74,23,11,8],
[74,23,11,10],
[74,23,12,0],
[74,23,12,1],
[74,23,12,2],
[74,23,12,3],
[74,23,12,4],
[74,23,12,5],
[74,23,12,6],
[74,23,12,7],
[74,23,12,8],
[74,23,12,10],
[74,23,13,0],
[74,23,13,1],
[74,23,13,2],
[74,23,13,3],
[74,23,13,4],
[74,23,13,5],
[74,23,13,6],
[74,23,13,7],
[74,23,13,8],
[74,23,13,10],
[74,23,13,11],
[74,23,13,12],
[74,23,14,0],
[74,23,14,1],
[74,23,14,2],
[74,23,14,3],
[74,23,14,4],
[74,23,14,5],
[74,23,14,6],
[74,23,14,7],
[74,23,14,8],
[74,23,14,10],
[74,23,14,11],
[74,23,14,12],
[74,23,16,0],
[74,23,16,1],
[74,23,16,2],
[74,23,16,3],
[74,23,16,4],
[74,23,16,5],
[74,23,16,6],
[74,23,16,7],
[74,23,16,8],
[74,23,16,10],
[74,23,16,11],
[74,23,16,12],
[74,23,16,13],
[74,23,16,14],
[74,23,17,0],
[74,23,17,1],
[74,23,17,2],
[74,23,17,3],
[74,23,17,4],
[74,23,17,5],
[74,23,17,6],
[74,23,17,7],
[74,23,17,8],
[74,23,17,10],
[74,23,17,11],
[74,23,17,12],
[74,23,17,13],
[74,23,17,14],
[74,23,17,16],
[74,23,19,0],
[74,23,19,1],
[74,23,19,2],
[74,23,19,3],
[74,23,19,4],
[74,23,19,5],
[74,23,19,6],
[74,23,19,7],
[74,23,19,8],
[74,23,19,10],
[74,23,19,11],
[74,23,19,12],
[74,23,19,16],
[74,23,19,17],
[74,23,20,0],
[74,23,20,1],
[74,23,20,2],
[74,23,20,3],
[74,23,20,4],
[74,23,20,5],
[74,23,20,6],
[74,23,20,7],
[74,23,20,8],
[74,23,20,11],
[74,23,20,12],
[74,23,20,13],
[74,23,20,14],
[74,23,20,16],
[74,23,20,17],
[74,23,20,19],
[74,23,21,0],
[74,23,21,1],
[74,23,21,2],
[74,23,21,4],
[74,23,21,7],
[74,23,21,10],
[74,23,21,11],
[74,23,21,12],
[74,23,21,13],
[74,23,21,14],
[74,23,21,16],
[74,23,21,17],
[74,23,21,19],
[74,23,22,0],
[74,23,22,1],
[74,23,22,2],
[74,23,22,3],
[74,23,22,4],
[74,23,22,5],
[74,23,22,6],
[74,23,22,7],
[74,23,22,8],
[74,23,22,11],
[74,23,22,12],
[74,23,22,13],
[74,23,22,14],
[74,23,22,16],
[74,23,22,17],
[74,23,22,21],
[74,24,3,0],
[74,24,3,1],
[74,24,3,2],
[74,24,4,0],
[74,24,4,1],
[74,24,4,2],
[74,24,4,3],
[74,24,5,0],
[74,24,5,1],
[74,24,5,2],
[74,24,5,4],
[74,24,6,0],
[74,24,6,1],
[74,24,6,2],
[74,24,6,4],
[74,24,7,0],
[74,24,7,1],
[74,24,7,2],
[74,24,7,3],
[74,24,7,4],
[74,24,7,5],
[74,24,7,6],
[74,24,8,0],
[74,24,8,1],
[74,24,8,2],
[74,24,8,4],
[74,24,8,7],
[74,24,10,0],
[74,24,10,1],
[74,24,10,2],
[74,24,10,3],
[74,24,10,4],
[74,24,10,5],
[74,24,10,6],
[74,24,10,7],
[74,24,10,8],
[74,24,11,0],
[74,24,11,1],
[74,24,11,2],
[74,24,11,3],
[74,24,11,4],
[74,24,11,5],
[74,24,11,6],
[74,24,11,7],
[74,24,11,8],
[74,24,11,10],
[74,24,12,0],
[74,24,12,1],
[74,24,12,2],
[74,24,12,3],
[74,24,12,4],
[74,24,12,5],
[74,24,12,6],
[74,24,12,7],
[74,24,12,8],
[74,24,12,10],
[74,24,13,0],
[74,24,13,1],
[74,24,13,2],
[74,24,13,3],
[74,24,13,4],
[74,24,13,5],
[74,24,13,6],
[74,24,13,7],
[74,24,13,8],
[74,24,13,10],
[74,24,13,11],
[74,24,13,12],
[74,24,14,0],
[74,24,14,1],
[74,24,14,2],
[74,24,14,3],
[74,24,14,4],
[74,24,14,5],
[74,24,14,6],
[74,24,14,7],
[74,24,14,8],
[74,24,14,10],
[74,24,14,11],
[74,24,14,12],
[74,24,17,0],
[74,24,17,1],
[74,24,17,2],
[74,24,17,3],
[74,24,17,4],
[74,24,17,5],
[74,24,17,6],
[74,24,17,7],
[74,24,17,8],
[74,24,17,10],
[74,24,17,11],
[74,24,17,12],
[74,24,17,13],
[74,24,17,14],
[74,24,19,0],
[74,24,19,1],
[74,24,19,2],
[74,24,19,3],
[74,24,19,4],
[74,24,19,5],
[74,24,19,6],
[74,24,19,7],
[74,24,19,8],
[74,24,19,10],
[74,24,19,11],
[74,24,19,12],
[74,24,19,17],
[74,24,20,0],
[74,24,20,1],
[74,24,20,2],
[74,24,20,3],
[74,24,20,4],
[74,24,20,5],
[74,24,20,6],
[74,24,20,7],
[74,24,20,8],
[74,24,20,11],
[74,24,20,12],
[74,24,20,13],
[74,24,20,14],
[74,24,20,17],
[74,24,20,19],
[74,24,21,0],
[74,24,21,1],
[74,24,21,2],
[74,24,21,4],
[74,24,21,7],
[74,24,21,10],
[74,24,21,11],
[74,24,21,12],
[74,24,21,13],
[74,24,21,14],
[74,24,21,17],
[74,24,21,19],
[74,24,22,0],
[74,24,22,1],
[74,24,22,2],
[74,24,22,3],
[74,24,22,4],
[74,24,22,5],
[74,24,22,6],
[74,24,22,7],
[74,24,22,8],
[74,24,22,11],
[74,24,22,12],
[74,24,22,13],
[74,24,22,14],
[74,24,22,17],
[74,24,22,21],
[74,24,23,0],
[74,24,23,1],
[74,24,23,2],
[74,24,23,3],
[74,24,23,4],
[74,24,23,5],
[74,24,23,6],
[74,24,23,7],
[74,24,23,8],
[74,24,23,10],
[74,24,23,11],
[74,24,23,12],
[74,24,23,13],
[74,24,23,14],
[74,24,23,17],
[74,24,23,19],
[74,24,23,20],
[74,24,23,21],
[74,24,23,22],
[74,25,3,0],
[74,25,3,1],
[74,25,3,2],
[74,25,4,0],
[74,25,4,1],
[74,25,4,2],
[74,25,4,3],
[74,25,5,0],
[74,25,5,1],
[74,25,5,2],
[74,25,5,4],
[74,25,6,0],
[74,25,6,1],
[74,25,6,2],
[74,25,6,4],
[74,25,8,0],
[74,25,8,1],
[74,25,8,2],
[74,25,8,4],
[74,25,10,0],
[74,25,10,1],
[74,25,10,2],
[74,25,10,3],
[74,25,10,4],
[74,25,10,5],
[74,25,10,6],
[74,25,10,8],
[74,25,11,0],
[74,25,11,1],
[74,25,11,2],
[74,25,11,3],
[74,25,11,4],
[74,25,11,5],
[74,25,11,6],
[74,25,11,8],
[74,25,11,10],
[74,25,12,0],
[74,25,12,1],
[74,25,12,2],
[74,25,12,3],
[74,25,12,4],
[74,25,12,5],
[74,25,12,6],
[74,25,12,8],
[74,25,12,10],
[74,25,13,0],
[74,25,13,1],
[74,25,13,2],
[74,25,13,3],
[74,25,13,4],
[74,25,13,5],
[74,25,13,6],
[74,25,13,8],
[74,25,13,10],
[74,25,13,11],
[74,25,13,12],
[74,25,14,0],
[74,25,14,1],
[74,25,14,2],
[74,25,14,3],
[74,25,14,4],
[74,25,14,5],
[74,25,14,6],
[74,25,14,8],
[74,25,14,10],
[74,25,14,11],
[74,25,14,12],
[74,25,16,0],
[74,25,16,1],
[74,25,16,2],
[74,25,16,3],
[74,25,16,4],
[74,25,16,5],
[74,25,16,6],
[74,25,16,8],
[74,25,16,10],
[74,25,16,11],
[74,25,16,12],
[74,25,16,13],
[74,25,16,14],
[74,25,17,0],
[74,25,17,1],
[74,25,17,2],
[74,25,17,3],
[74,25,17,4],
[74,25,17,5],
[74,25,17,6],
[74,25,17,8],
[74,25,17,10],
[74,25,17,11],
[74,25,17,12],
[74,25,17,13],
[74,25,17,14],
[74,25,17,16],
[74,25,19,0],
[74,25,19,1],
[74,25,19,2],
[74,25,19,3],
[74,25,19,4],
[74,25,19,5],
[74,25,19,6],
[74,25,19,8],
[74,25,19,10],
[74,25,19,11],
[74,25,19,12],
[74,25,19,16],
[74,25,19,17],
[74,25,20,0],
[74,25,20,1],
[74,25,20,2],
[74,25,20,3],
[74,25,20,4],
[74,25,20,5],
[74,25,20,6],
[74,25,20,8],
[74,25,20,11],
[74,25,20,12],
[74,25,20,13],
[74,25,20,14],
[74,25,20,16],
[74,25,20,17],
[74,25,21,0],
[74,25,21,1],
[74,25,21,2],
[74,25,21,4],
[74,25,21,10],
[74,25,21,11],
[74,25,21,12],
[74,25,21,13],
[74,25,21,14],
[74,25,21,16],
[74,25,21,17],
[74,25,21,19],
[74,25,21,20],
[74,25,22,0],
[74,25,22,1],
[74,25,22,2],
[74,25,22,3],
[74,25,22,4],
[74,25,22,5],
[74,25,22,6],
[74,25,22,8],
[74,25,22,11],
[74,25,22,12],
[74,25,22,13],
[74,25,22,14],
[74,25,22,19],
[74,25,22,21],
[74,25,23,0],
[74,25,23,1],
[74,25,23,2],
[74,25,23,3],
[74,25,23,4],
[74,25,23,5],
[74,25,23,6],
[74,25,23,8],
[74,25,23,10],
[74,25,23,11],
[74,25,23,12],
[74,25,23,13],
[74,25,23,14],
[74,25,23,16],
[74,25,23,17],
[74,25,23,19],
[74,25,23,20],
[74,25,23,21],
[74,25,23,22],
[74,25,24,0],
[74,25,24,1],
[74,25,24,2],
[74,25,24,3],
[74,25,24,4],
[74,25,24,5],
[74,25,24,6],
[74,25,24,8],
[74,25,24,10],
[74,25,24,11],
[74,25,24,12],
[74,25,24,13],
[74,25,24,14],
[74,25,24,17],
[74,25,24,19],
[74,25,24,20],
[74,25,24,21],
[74,25,24,22],
[74,25,24,23],
[74,27,3,0],
[74,27,3,1],
[74,27,3,2],
[74,27,4,0],
[74,27,4,1],
[74,27,4,2],
[74,27,4,3],
[74,27,5,0],
[74,27,5,1],
[74,27,5,2],
[74,27,5,4],
[74,27,6,0],
[74,27,6,1],
[74,27,6,2],
[74,27,6,4],
[74,27,7,0],
[74,27,7,1],
[74,27,7,2],
[74,27,7,3],
[74,27,7,4],
[74,27,7,5],
[74,27,7,6],
[74,27,8,0],
[74,27,8,1],
[74,27,8,2],
[74,27,8,4],
[74,27,8,7],
[74,27,11,0],
[74,27,11,1],
[74,27,11,2],
[74,27,11,3],
[74,27,11,4],
[74,27,11,5],
[74,27,11,6],
[74,27,11,7],
[74,27,11,8],
[74,27,12,0],
[74,27,12,1],
[74,27,12,2],
[74,27,12,3],
[74,27,12,4],
[74,27,12,5],
[74,27,12,6],
[74,27,12,7],
[74,27,12,8],
[74,27,13,0],
[74,27,13,1],
[74,27,13,2],
[74,27,13,3],
[74,27,13,4],
[74,27,13,5],
[74,27,13,6],
[74,27,13,7],
[74,27,13,8],
[74,27,13,11],
[74,27,13,12],
[74,27,14,0],
[74,27,14,1],
[74,27,14,2],
[74,27,14,3],
[74,27,14,4],
[74,27,14,5],
[74,27,14,6],
[74,27,14,7],
[74,27,14,8],
[74,27,14,11],
[74,27,14,12],
[74,27,16,0],
[74,27,16,1],
[74,27,16,2],
[74,27,16,3],
[74,27,16,4],
[74,27,16,5],
[74,27,16,6],
[74,27,16,7],
[74,27,16,8],
[74,27,16,11],
[74,27,16,12],
[74,27,16,13],
[74,27,16,14],
[74,27,17,0],
[74,27,17,1],
[74,27,17,2],
[74,27,17,3],
[74,27,17,4],
[74,27,17,5],
[74,27,17,6],
[74,27,17,7],
[74,27,17,8],
[74,27,17,11],
[74,27,17,12],
[74,27,17,13],
[74,27,17,14],
[74,27,17,16],
[74,27,19,0],
[74,27,19,1],
[74,27,19,2],
[74,27,19,3],
[74,27,19,4],
[74,27,19,5],
[74,27,19,6],
[74,27,19,7],
[74,27,19,8],
[74,27,19,11],
[74,27,19,12],
[74,27,19,16],
[74,27,19,17],
[74,27,21,0],
[74,27,21,1],
[74,27,21,2],
[74,27,21,4],
[74,27,21,7],
[74,27,21,11],
[74,27,21,12],
[74,27,21,13],
[74,27,21,14],
[74,27,21,16],
[74,27,21,17],
[74,27,21,19],
[74,27,23,0],
[74,27,23,1],
[74,27,23,2],
[74,27,23,3],
[74,27,23,4],
[74,27,23,5],
[74,27,23,6],
[74,27,23,7],
[74,27,23,8],
[74,27,23,11],
[74,27,23,14],
[74,27,23,16],
[74,27,23,17],
[74,27,23,19],
[74,27,23,21],
[74,27,24,0],
[74,27,24,1],
[74,27,24,2],
[74,27,24,3],
[74,27,24,4],
[74,27,24,5],
[74,27,24,6],
[74,27,24,7],
[74,27,24,8],
[74,27,24,11],
[74,27,24,14],
[74,27,24,17],
[74,27,24,19],
[74,27,24,21],
[74,27,24,23],
[74,27,25,0],
[74,27,25,1],
[74,27,25,2],
[74,27,25,3],
[74,27,25,4],
[74,27,25,5],
[74,27,25,6],
[74,27,25,11],
[74,27,25,12],
[74,27,25,13],
[74,27,25,14],
[74,27,25,16],
[74,27,25,17],
[74,27,25,19],
[74,27,25,21],
[74,27,25,23],
[74,27,25,24],
[74,28,3,0],
[74,28,3,1],
[74,28,3,2],
[74,28,4,0],
[74,28,4,1],
[74,28,4,2],
[74,28,4,3],
[74,28,5,0],
[74,28,5,1],
[74,28,5,2],
[74,28,5,4],
[74,28,6,0],
[74,28,6,1],
[74,28,6,2],
[74,28,6,4],
[74,28,8,0],
[74,28,8,1],
[74,28,8,2],
[74,28,8,4],
[74,28,10,0],
[74,28,10,1],
[74,28,10,2],
[74,28,10,3],
[74,28,10,4],
[74,28,10,5],
[74,28,10,6],
[74,28,10,8],
[74,28,11,0],
[74,28,11,1],
[74,28,11,2],
[74,28,11,3],
[74,28,11,4],
[74,28,11,5],
[74,28,11,6],
[74,28,11,8],
[74,28,11,10],
[74,28,12,0],
[74,28,12,1],
[74,28,12,2],
[74,28,12,3],
[74,28,12,4],
[74,28,12,5],
[74,28,12,6],
[74,28,12,8],
[74,28,12,10],
[74,28,13,0],
[74,28,13,1],
[74,28,13,2],
[74,28,13,3],
[74,28,13,4],
[74,28,13,5],
[74,28,13,6],
[74,28,13,8],
[74,28,13,10],
[74,28,13,11],
[74,28,13,12],
[74,28,14,0],
[74,28,14,1],
[74,28,14,2],
[74,28,14,3],
[74,28,14,4],
[74,28,14,5],
[74,28,14,6],
[74,28,14,8],
[74,28,14,10],
[74,28,14,11],
[74,28,14,12],
[74,28,16,0],
[74,28,16,1],
[74,28,16,2],
[74,28,16,3],
[74,28,16,4],
[74,28,16,5],
[74,28,16,6],
[74,28,16,8],
[74,28,16,10],
[74,28,16,11],
[74,28,16,12],
[74,28,16,13],
[74,28,16,14],
[74,28,17,0],
[74,28,17,1],
[74,28,17,2],
[74,28,17,3],
[74,28,17,4],
[74,28,17,5],
[74,28,17,6],
[74,28,17,8],
[74,28,17,10],
[74,28,17,11],
[74,28,17,12],
[74,28,17,13],
[74,28,17,14],
[74,28,17,16],
[74,28,19,0],
[74,28,19,1],
[74,28,19,2],
[74,28,19,3],
[74,28,19,4],
[74,28,19,5],
[74,28,19,6],
[74,28,19,8],
[74,28,19,10],
[74,28,19,11],
[74,28,19,12],
[74,28,20,0],
[74,28,20,1],
[74,28,20,2],
[74,28,20,3],
[74,28,20,4],
[74,28,20,5],
[74,28,20,6],
[74,28,20,8],
[74,28,20,11],
[74,28,20,12],
[74,28,20,13],
[74,28,20,14],
[74,28,20,16],
[74,28,20,17],
[74,28,20,19],
[74,28,21,0],
[74,28,21,1],
[74,28,21,2],
[74,28,21,4],
[74,28,21,10],
[74,28,21,11],
[74,28,21,12],
[74,28,21,13],
[74,28,21,16],
[74,28,21,17],
[74,28,21,19],
[74,28,21,20],
[74,28,22,0],
[74,28,22,1],
[74,28,22,2],
[74,28,22,3],
[74,28,22,4],
[74,28,22,5],
[74,28,22,6],
[74,28,22,8],
[74,28,22,11],
[74,28,22,14],
[74,28,22,16],
[74,28,22,17],
[74,28,22,19],
[74,28,22,21],
[74,28,23,0],
[74,28,23,1],
[74,28,23,2],
[74,28,23,3],
[74,28,23,4],
[74,28,23,5],
[74,28,23,6],
[74,28,23,8],
[74,28,23,12],
[74,28,23,13],
[74,28,23,14],
[74,28,23,16],
[74,28,23,17],
[74,28,23,19],
[74,28,23,20],
[74,28,23,21],
[74,28,23,22],
[74,28,24,0],
[74,28,24,1],
[74,28,24,2],
[74,28,24,3],
[74,28,24,4],
[74,28,24,5],
[74,28,24,6],
[74,28,24,8],
[74,28,24,12],
[74,28,24,13],
[74,28,24,14],
[74,28,24,17],
[74,28,24,19],
[74,28,24,20],
[74,28,24,21],
[74,28,24,22],
[74,28,24,23],
[74,28,27,0],
[74,28,27,1],
[74,28,27,2],
[74,28,27,5],
[74,28,27,6],
[74,28,27,8],
[74,28,27,11],
[74,28,27,12],
[74,28,27,13],
[74,28,27,14],
[74,28,27,16],
[74,28,27,17],
[74,28,27,19],
[74,28,27,21],
[74,29,3,0],
[74,29,3,1],
[74,29,3,2],
[74,29,4,0],
[74,29,4,1],
[74,29,4,2],
[74,29,4,3],
[74,29,5,0],
[74,29,5,1],
[74,29,5,2],
[74,29,5,4],
[74,29,6,0],
[74,29,6,1],
[74,29,6,2],
[74,29,6,4],
[74,29,7,0],
[74,29,7,1],
[74,29,7,2],
[74,29,7,3],
[74,29,7,4],
[74,29,7,5],
[74,29,7,6],
[74,29,8,0],
[74,29,8,1],
[74,29,8,2],
[74,29,8,4],
[74,29,8,7],
[74,29,10,0],
[74,29,10,1],
[74,29,10,2],
[74,29,10,3],
[74,29,10,4],
[74,29,10,5],
[74,29,10,6],
[74,29,10,7],
[74,29,10,8],
[74,29,13,0],
[74,29,13,1],
[74,29,13,2],
[74,29,13,3],
[74,29,13,4],
[74,29,13,5],
[74,29,13,6],
[74,29,13,7],
[74,29,13,8],
[74,29,13,10],
[74,29,14,0],
[74,29,14,1],
[74,29,14,2],
[74,29,14,3],
[74,29,14,4],
[74,29,14,5],
[74,29,14,6],
[74,29,14,7],
[74,29,14,8],
[74,29,14,10],
[74,29,16,0],
[74,29,16,1],
[74,29,16,2],
[74,29,16,3],
[74,29,16,4],
[74,29,16,5],
[74,29,16,6],
[74,29,16,7],
[74,29,16,8],
[74,29,16,10],
[74,29,16,13],
[74,29,16,14],
[74,29,17,0],
[74,29,17,1],
[74,29,17,2],
[74,29,17,3],
[74,29,17,4],
[74,29,17,5],
[74,29,17,6],
[74,29,17,7],
[74,29,17,8],
[74,29,17,10],
[74,29,17,13],
[74,29,17,14],
[74,29,17,16],
[74,29,19,0],
[74,29,19,1],
[74,29,19,2],
[74,29,19,3],
[74,29,19,4],
[74,29,19,5],
[74,29,19,6],
[74,29,19,7],
[74,29,19,8],
[74,29,19,10],
[74,29,19,16],
[74,29,19,17],
[74,29,20,0],
[74,29,20,1],
[74,29,20,2],
[74,29,20,3],
[74,29,20,4],
[74,29,20,5],
[74,29,20,6],
[74,29,20,7],
[74,29,20,8],
[74,29,20,13],
[74,29,20,16],
[74,29,20,17],
[74,29,20,19],
[74,29,21,0],
[74,29,21,1],
[74,29,21,2],
[74,29,21,4],
[74,29,21,7],
[74,29,21,10],
[74,29,21,14],
[74,29,21,16],
[74,29,21,17],
[74,29,21,19],
[74,29,21,20],
[74,29,22,0],
[74,29,22,1],
[74,29,22,2],
[74,29,22,3],
[74,29,22,4],
[74,29,22,5],
[74,29,22,6],
[74,29,22,7],
[74,29,22,8],
[74,29,22,13],
[74,29,22,14],
[74,29,22,16],
[74,29,22,17],
[74,29,22,19],
[74,29,22,21],
[74,29,23,0],
[74,29,23,1],
[74,29,23,2],
[74,29,23,3],
[74,29,23,4],
[74,29,23,5],
[74,29,23,6],
[74,29,23,7],
[74,29,23,10],
[74,29,23,13],
[74,29,23,14],
[74,29,23,16],
[74,29,23,17],
[74,29,23,19],
[74,29,23,20],
[74,29,23,21],
[74,29,23,22],
[74,29,24,0],
[74,29,24,1],
[74,29,24,2],
[74,29,24,3],
[74,29,24,4],
[74,29,24,5],
[74,29,24,6],
[74,29,24,7],
[74,29,24,10],
[74,29,24,13],
[74,29,24,14],
[74,29,24,17],
[74,29,24,19],
[74,29,24,20],
[74,29,24,21],
[74,29,24,22],
[74,29,24,23],
[74,29,25,0],
[74,29,25,1],
[74,29,25,2],
[74,29,25,3],
[74,29,25,4],
[74,29,25,6],
[74,29,25,8],
[74,29,25,10],
[74,29,25,13],
[74,29,25,14],
[74,29,25,16],
[74,29,25,17],
[74,29,25,19],
[74,29,25,20],
[74,29,25,21],
[74,29,25,22],
[74,29,27,3],
[74,29,27,4],
[74,29,27,5],
[74,29,27,6],
[74,29,27,7],
[74,29,27,8],
[74,29,27,13],
[74,29,27,14],
[74,29,27,16],
[74,29,27,17],
[74,29,27,19],
[74,29,28,0],
[74,29,28,1],
[74,29,28,2],
[74,29,28,3],
[74,29,28,4],
[74,29,28,5],
[74,29,28,6],
[74,29,28,8],
[74,29,28,10],
[74,29,28,13],
[74,29,28,14],
[74,29,28,16],
[74,29,28,17],
[74,29,28,19],
[74,30,3,0],
[74,30,3,1],
[74,30,3,2],
[74,30,4,0],
[74,30,4,1],
[74,30,4,2],
[74,30,4,3],
[74,30,5,0],
[74,30,5,1],
[74,30,5,2],
[74,30,5,4],
[74,30,6,0],
[74,30,6,1],
[74,30,6,2],
[74,30,6,4],
[74,30,7,0],
[74,30,7,1],
[74,30,7,2],
[74,30,7,3],
[74,30,7,4],
[74,30,7,5],
[74,30,7,6],
[74,30,8,0],
[74,30,8,1],
[74,30,8,2],
[74,30,8,4],
[74,30,8,7],
[74,30,10,0],
[74,30,10,1],
[74,30,10,2],
[74,30,10,3],
[74,30,10,4],
[74,30,10,5],
[74,30,10,6],
[74,30,10,7],
[74,30,10,8],
[74,30,11,0],
[74,30,11,1],
[74,30,11,2],
[74,30,11,3],
[74,30,11,4],
[74,30,11,5],
[74,30,11,6],
[74,30,11,7],
[74,30,11,8],
[74,30,11,10],
[74,30,12,0],
[74,30,12,1],
[74,30,12,2],
[74,30,12,3],
[74,30,12,4],
[74,30,12,5],
[74,30,12,6],
[74,30,12,7],
[74,30,12,8],
[74,30,12,10],
[74,30,13,0],
[74,30,13,1],
[74,30,13,2],
[74,30,13,3],
[74,30,13,4],
[74,30,13,5],
[74,30,13,6],
[74,30,13,7],
[74,30,13,8],
[74,30,13,10],
[74,30,13,11],
[74,30,13,12],
[74,30,14,0],
[74,30,14,1],
[74,30,14,2],
[74,30,14,3],
[74,30,14,4],
[74,30,14,5],
[74,30,14,6],
[74,30,14,7],
[74,30,14,8],
[74,30,14,10],
[74,30,14,11],
[74,30,14,12],
[74,30,16,0],
[74,30,16,1],
[74,30,16,2],
[74,30,16,3],
[74,30,16,4],
[74,30,16,5],
[74,30,16,6],
[74,30,16,7],
[74,30,16,8],
[74,30,16,10],
[74,30,16,11],
[74,30,16,12],
[74,30,16,13],
[74,30,16,14],
[74,30,17,0],
[74,30,17,1],
[74,30,17,2],
[74,30,17,3],
[74,30,17,4],
[74,30,17,5],
[74,30,17,6],
[74,30,17,7],
[74,30,17,8],
[74,30,17,10],
[74,30,17,11],
[74,30,17,12],
[74,30,17,13],
[74,30,17,14],
[74,30,19,0],
[74,30,19,1],
[74,30,19,2],
[74,30,19,3],
[74,30,19,4],
[74,30,19,5],
[74,30,19,6],
[74,30,19,7],
[74,30,19,8],
[74,30,19,10],
[74,30,19,11],
[74,30,19,12],
[74,30,19,16],
[74,30,19,17],
[74,30,20,0],
[74,30,20,1],
[74,30,20,2],
[74,30,20,3],
[74,30,20,4],
[74,30,20,5],
[74,30,20,6],
[74,30,20,7],
[74,30,20,8],
[74,30,20,11],
[74,30,20,14],
[74,30,20,16],
[74,30,20,17],
[74,30,20,19],
[74,30,21,0],
[74,30,21,1],
[74,30,21,2],
[74,30,21,4],
[74,30,21,7],
[74,30,21,12],
[74,30,21,13],
[74,30,21,14],
[74,30,21,16],
[74,30,21,17],
[74,30,21,19],
[74,30,21,20],
[74,30,22,0],
[74,30,22,1],
[74,30,22,2],
[74,30,22,3],
[74,30,22,4],
[74,30,22,5],
[74,30,22,6],
[74,30,22,7],
[74,30,22,11],
[74,30,22,12],
[74,30,22,13],
[74,30,22,14],
[74,30,22,16],
[74,30,22,17],
[74,30,22,19],
[74,30,22,21],
[74,30,24,0],
[74,30,24,1],
[74,30,24,2],
[74,30,24,3],
[74,30,24,4],
[74,30,24,5],
[74,30,24,8],
[74,30,24,10],
[74,30,24,11],
[74,30,24,12],
[74,30,24,13],
[74,30,24,14],
[74,30,24,17],
[74,30,24,19],
[74,30,24,20],
[74,30,24,21],
[74,30,24,22],
[74,30,25,0],
[74,30,25,1],
[74,30,25,2],
[74,30,25,5],
[74,30,25,6],
[74,30,25,8],
[74,30,25,10],
[74,30,25,11],
[74,30,25,12],
[74,30,25,13],
[74,30,25,14],
[74,30,25,16],
[74,30,25,17],
[74,30,25,19],
[74,30,25,20],
[74,30,25,21],
[74,30,27,0],
[74,30,27,1],
[74,30,27,2],
[74,30,27,3],
[74,30,27,4],
[74,30,27,5],
[74,30,27,6],
[74,30,27,7],
[74,30,27,8],
[74,30,27,11],
[74,30,27,12],
[74,30,27,13],
[74,30,27,14],
[74,30,27,16],
[74,30,27,17],
[74,30,27,19],
[74,30,28,0],
[74,30,28,1],
[74,30,28,2],
[74,30,28,3],
[74,30,28,4],
[74,30,28,5],
[74,30,28,6],
[74,30,28,8],
[74,30,28,10],
[74,30,28,11],
[74,30,28,12],
[74,30,28,13],
[74,30,28,14],
[74,30,28,16],
[74,30,28,17],
[74,30,29,0],
[74,30,29,1],
[74,30,29,2],
[74,30,29,3],
[74,30,29,4],
[74,30,29,5],
[74,30,29,6],
[74,30,29,7],
[74,30,29,8],
[74,30,29,10],
[74,30,29,13],
[74,30,29,14],
[74,30,29,16],
[74,30,29,17],
[74,31,3,0],
[74,31,3,1],
[74,31,3,2],
[74,31,4,0],
[74,31,4,1],
[74,31,4,2],
[74,31,4,3],
[74,31,5,0],
[74,31,5,1],
[74,31,5,2],
[74,31,5,4],
[74,31,6,0],
[74,31,6,1],
[74,31,6,2],
[74,31,6,4],
[74,31,7,0],
[74,31,7,1],
[74,31,7,2],
[74,31,7,3],
[74,31,7,4],
[74,31,7,5],
[74,31,7,6],
[74,31,8,0],
[74,31,8,1],
[74,31,8,2],
[74,31,8,4],
[74,31,8,7],
[74,31,10,0],
[74,31,10,1],
[74,31,10,2],
[74,31,10,3],
[74,31,10,4],
[74,31,10,5],
[74,31,10,6],
[74,31,10,7],
[74,31,10,8],
[74,31,11,0],
[74,31,11,1],
[74,31,11,2],
[74,31,11,3],
[74,31,11,4],
[74,31,11,5],
[74,31,11,6],
[74,31,11,7],
[74,31,11,8],
[74,31,11,10],
[74,31,12,0],
[74,31,12,1],
[74,31,12,2],
[74,31,12,3],
[74,31,12,4],
[74,31,12,5],
[74,31,12,6],
[74,31,12,7],
[74,31,12,8],
[74,31,12,10],
[74,31,13,0],
[74,31,13,1],
[74,31,13,2],
[74,31,13,3],
[74,31,13,4],
[74,31,13,5],
[74,31,13,6],
[74,31,13,7],
[74,31,13,8],
[74,31,13,10],
[74,31,13,11],
[74,31,13,12],
[74,31,14,0],
[74,31,14,1],
[74,31,14,2],
[74,31,14,3],
[74,31,14,4],
[74,31,14,5],
[74,31,14,6],
[74,31,14,7],
[74,31,14,8],
[74,31,14,10],
[74,31,14,11],
[74,31,14,12],
[74,31,16,0],
[74,31,16,1],
[74,31,16,2],
[74,31,16,3],
[74,31,16,4],
[74,31,16,5],
[74,31,16,6],
[74,31,16,7],
[74,31,16,8],
[74,31,16,10],
[74,31,16,11],
[74,31,16,12],
[74,31,16,13],
[74,31,16,14],
[74,31,17,0],
[74,31,17,1],
[74,31,17,2],
[74,31,17,3],
[74,31,17,4],
[74,31,17,5],
[74,31,17,6],
[74,31,17,7],
[74,31,17,8],
[74,31,17,10],
[74,31,17,11],
[74,31,17,12],
[74,31,17,13],
[74,31,17,14],
[74,31,17,16],
[74,31,19,0],
[74,31,19,1],
[74,31,19,2],
[74,31,19,3],
[74,31,19,4],
[74,31,19,5],
[74,31,19,6],
[74,31,19,7],
[74,31,19,8],
[74,31,19,10],
[74,31,19,11],
[74,31,19,16],
[74,31,19,17],
[74,31,20,0],
[74,31,20,1],
[74,31,20,2],
[74,31,20,3],
[74,31,20,4],
[74,31,20,5],
[74,31,20,6],
[74,31,20,7],
[74,31,20,8],
[74,31,20,12],
[74,31,20,13],
[74,31,20,14],
[74,31,20,16],
[74,31,20,17],
[74,31,20,19],
[74,31,21,0],
[74,31,21,1],
[74,31,21,2],
[74,31,21,4],
[74,31,21,7],
[74,31,21,10],
[74,31,21,11],
[74,31,21,12],
[74,31,21,13],
[74,31,21,14],
[74,31,21,16],
[74,31,21,17],
[74,31,21,19],
[74,31,21,20],
[74,31,22,0],
[74,31,22,1],
[74,31,22,2],
[74,31,22,3],
[74,31,22,4],
[74,31,22,5],
[74,31,22,8],
[74,31,22,11],
[74,31,22,12],
[74,31,22,13],
[74,31,22,14],
[74,31,22,16],
[74,31,22,17],
[74,31,22,19],
[74,31,22,21],
[74,31,24,0],
[74,31,24,1],
[74,31,24,2],
[74,31,24,3],
[74,31,24,4],
[74,31,24,6],
[74,31,24,7],
[74,31,24,8],
[74,31,24,10],
[74,31,24,11],
[74,31,24,12],
[74,31,24,13],
[74,31,24,14],
[74,31,24,17],
[74,31,24,19],
[74,31,24,20],
[74,31,24,21],
[74,31,24,22],
[74,31,25,3],
[74,31,25,4],
[74,31,25,5],
[74,31,25,6],
[74,31,25,8],
[74,31,25,10],
[74,31,25,11],
[74,31,25,12],
[74,31,25,13],
[74,31,25,14],
[74,31,25,16],
[74,31,25,17],
[74,31,25,19],
[74,31,25,20],
[74,31,27,0],
[74,31,27,1],
[74,31,27,2],
[74,31,27,3],
[74,31,27,4],
[74,31,27,5],
[74,31,27,6],
[74,31,27,7],
[74,31,27,8],
[74,31,27,11],
[74,31,27,12],
[74,31,27,13],
[74,31,27,14],
[74,31,27,16],
[74,31,27,17],
[74,31,28,0],
[74,31,28,1],
[74,31,28,2],
[74,31,28,3],
[74,31,28,4],
[74,31,28,5],
[74,31,28,6],
[74,31,28,8],
[74,31,28,10],
[74,31,28,11],
[74,31,28,12],
[74,31,28,13],
[74,31,28,14],
[74,31,28,16],
[74,31,28,17],
[74,31,29,0],
[74,31,29,1],
[74,31,29,2],
[74,31,29,3],
[74,31,29,4],
[74,31,29,5],
[74,31,29,6],
[74,31,29,7],
[74,31,29,8],
[74,31,29,10],
[74,31,29,13],
[74,31,29,14],
[74,32,3,0],
[74,32,3,1],
[74,32,3,2],
[74,32,4,0],
[74,32,4,1],
[74,32,4,2],
[74,32,4,3],
[74,32,5,0],
[74,32,5,1],
[74,32,5,2],
[74,32,5,4],
[74,32,6,0],
[74,32,6,1],
[74,32,6,2],
[74,32,6,4],
[74,32,7,0],
[74,32,7,1],
[74,32,7,2],
[74,32,7,3],
[74,32,7,4],
[74,32,7,5],
[74,32,7,6],
[74,32,8,0],
[74,32,8,1],
[74,32,8,2],
[74,32,8,4],
[74,32,8,7],
[74,32,10,0],
[74,32,10,1],
[74,32,10,2],
[74,32,10,3],
[74,32,10,4],
[74,32,10,5],
[74,32,10,6],
[74,32,10,7],
[74,32,10,8],
[74,32,13,0],
[74,32,13,1],
[74,32,13,2],
[74,32,13,3],
[74,32,13,4],
[74,32,13,5],
[74,32,13,6],
[74,32,13,7],
[74,32,13,8],
[74,32,13,10],
[74,32,14,0],
[74,32,14,1],
[74,32,14,2],
[74,32,14,3],
[74,32,14,4],
[74,32,14,5],
[74,32,14,6],
[74,32,14,7],
[74,32,14,8],
[74,32,14,10],
[74,32,16,0],
[74,32,16,1],
[74,32,16,2],
[74,32,16,3],
[74,32,16,4],
[74,32,16,5],
[74,32,16,6],
[74,32,16,7],
[74,32,16,8],
[74,32,16,10],
[74,32,16,13],
[74,32,17,0],
[74,32,17,1],
[74,32,17,2],
[74,32,17,3],
[74,32,17,4],
[74,32,17,5],
[74,32,17,6],
[74,32,17,7],
[74,32,17,8],
[74,32,17,10],
[74,32,17,13],
[74,32,17,16],
[74,32,19,0],
[74,32,19,1],
[74,32,19,2],
[74,32,19,3],
[74,32,19,4],
[74,32,19,5],
[74,32,19,6],
[74,32,19,7],
[74,32,19,8],
[74,32,19,16],
[74,32,19,17],
[74,32,20,0],
[74,32,20,1],
[74,32,20,2],
[74,32,20,3],
[74,32,20,4],
[74,32,20,5],
[74,32,20,6],
[74,32,20,7],
[74,32,20,13],
[74,32,20,14],
[74,32,20,16],
[74,32,20,17],
[74,32,20,19],
[74,32,21,0],
[74,32,21,1],
[74,32,21,2],
[74,32,21,4],
[74,32,21,10],
[74,32,21,13],
[74,32,21,14],
[74,32,21,16],
[74,32,21,17],
[74,32,21,19],
[74,32,21,20],
[74,32,22,0],
[74,32,22,1],
[74,32,22,2],
[74,32,22,3],
[74,32,22,4],
[74,32,22,6],
[74,32,22,7],
[74,32,22,8],
[74,32,22,13],
[74,32,22,14],
[74,32,22,16],
[74,32,22,17],
[74,32,22,19],
[74,32,22,21],
[74,32,23,0],
[74,32,23,1],
[74,32,23,2],
[74,32,23,5],
[74,32,23,6],
[74,32,23,7],
[74,32,23,8],
[74,32,23,10],
[74,32,23,13],
[74,32,23,14],
[74,32,23,16],
[74,32,23,17],
[74,32,23,19],
[74,32,23,20],
[74,32,23,21],
[74,32,24,0],
[74,32,24,1],
[74,32,24,2],
[74,32,24,5],
[74,32,24,6],
[74,32,24,7],
[74,32,24,8],
[74,32,24,10],
[74,32,24,13],
[74,32,24,14],
[74,32,24,17],
[74,32,24,19],
[74,32,24,20],
[74,32,24,21],
[74,32,25,0],
[74,32,25,1],
[74,32,25,2],
[74,32,25,3],
[74,32,25,4],
[74,32,25,5],
[74,32,25,6],
[74,32,25,8],
[74,32,25,10],
[74,32,25,13],
[74,32,25,14],
[74,32,25,16],
[74,32,25,17],
[74,32,25,19],
[74,32,27,0],
[74,32,27,1],
[74,32,27,2],
[74,32,27,3],
[74,32,27,4],
[74,32,27,5],
[74,32,27,6],
[74,32,27,7],
[74,32,27,8],
[74,32,27,13],
[74,32,27,14],
[74,32,27,16],
[74,32,27,17],
[74,32,28,0],
[74,32,28,1],
[74,32,28,2],
[74,32,28,3],
[74,32,28,4],
[74,32,28,5],
[74,32,28,6],
[74,32,28,8],
[74,32,28,10],
[74,32,28,13],
[74,32,28,14],
[74,32,30,0],
[74,32,30,1],
[74,32,30,2],
[74,32,30,3],
[74,32,30,4],
[74,32,30,5],
[74,32,30,6],
[74,32,30,7],
[74,32,30,8],
[74,32,30,10],
[74,32,30,13],
[74,32,31,0],
[74,32,31,1],
[74,32,31,2],
[74,32,31,3],
[74,32,31,4],
[74,32,31,5],
[74,32,31,6],
[74,32,31,7],
[74,32,31,8],
[74,32,31,10],
[74,33,3,0],
[74,33,3,1],
[74,33,3,2],
[74,33,4,0],
[74,33,4,1],
[74,33,4,2],
[74,33,4,3],
[74,33,5,0],
[74,33,5,1],
[74,33,5,2],
[74,33,5,4],
[74,33,6,0],
[74,33,6,1],
[74,33,6,2],
[74,33,6,4],
[74,33,7,0],
[74,33,7,1],
[74,33,7,2],
[74,33,7,3],
[74,33,7,4],
[74,33,7,5],
[74,33,7,6],
[74,33,8,0],
[74,33,8,1],
[74,33,8,2],
[74,33,8,4],
[74,33,8,7],
[74,33,10,0],
[74,33,10,1],
[74,33,10,2],
[74,33,10,3],
[74,33,10,4],
[74,33,10,5],
[74,33,10,6],
[74,33,10,7],
[74,33,10,8],
[74,33,13,0],
[74,33,13,1],
[74,33,13,2],
[74,33,13,3],
[74,33,13,4],
[74,33,13,5],
[74,33,13,6],
[74,33,13,7],
[74,33,13,8],
[74,33,13,10],
[74,33,14,0],
[74,33,14,1],
[74,33,14,2],
[74,33,14,3],
[74,33,14,4],
[74,33,14,5],
[74,33,14,6],
[74,33,14,7],
[74,33,14,8],
[74,33,14,10],
[74,33,16,0],
[74,33,16,1],
[74,33,16,2],
[74,33,16,3],
[74,33,16,4],
[74,33,16,5],
[74,33,16,6],
[74,33,16,7],
[74,33,16,8],
[74,33,16,10],
[74,33,16,14],
[74,33,17,0],
[74,33,17,1],
[74,33,17,2],
[74,33,17,3],
[74,33,17,4],
[74,33,17,5],
[74,33,17,6],
[74,33,17,7],
[74,33,17,8],
[74,33,17,10],
[74,33,17,14],
[74,33,17,16],
[74,33,19,0],
[74,33,19,1],
[74,33,19,2],
[74,33,19,3],
[74,33,19,4],
[74,33,19,5],
[74,33,19,6],
[74,33,19,7],
[74,33,19,10],
[74,33,19,16],
[74,33,19,17],
[74,33,20,0],
[74,33,20,1],
[74,33,20,2],
[74,33,20,3],
[74,33,20,4],
[74,33,20,5],
[74,33,20,8],
[74,33,20,13],
[74,33,20,14],
[74,33,20,16],
[74,33,20,17],
[74,33,20,19],
[74,33,21,0],
[74,33,21,1],
[74,33,21,2],
[74,33,21,4],
[74,33,21,7],
[74,33,21,10],
[74,33,21,13],
[74,33,21,14],
[74,33,21,16],
[74,33,21,17],
[74,33,21,19],
[74,33,21,20],
[74,33,22,0],
[74,33,22,1],
[74,33,22,2],
[74,33,22,5],
[74,33,22,6],
[74,33,22,7],
[74,33,22,8],
[74,33,22,13],
[74,33,22,14],
[74,33,22,16],
[74,33,22,17],
[74,33,22,19],
[74,33,22,21],
[74,33,23,3],
[74,33,23,4],
[74,33,23,5],
[74,33,23,6],
[74,33,23,7],
[74,33,23,8],
[74,33,23,10],
[74,33,23,13],
[74,33,23,14],
[74,33,23,16],
[74,33,23,17],
[74,33,23,19],
[74,33,23,20],
[74,33,24,3],
[74,33,24,4],
[74,33,24,5],
[74,33,24,6],
[74,33,24,7],
[74,33,24,8],
[74,33,24,10],
[74,33,24,13],
[74,33,24,14],
[74,33,24,17],
[74,33,24,19],
[74,33,24,20],
[74,33,25,0],
[74,33,25,1],
[74,33,25,2],
[74,33,25,3],
[74,33,25,4],
[74,33,25,5],
[74,33,25,6],
[74,33,25,8],
[74,33,25,10],
[74,33,25,13],
[74,33,25,14],
[74,33,25,16],
[74,33,25,17],
[74,33,27,0],
[74,33,27,1],
[74,33,27,2],
[74,33,27,3],
[74,33,27,4],
[74,33,27,5],
[74,33,27,6],
[74,33,27,7],
[74,33,27,8],
[74,33,27,13],
[74,33,27,14],
[74,33,28,0],
[74,33,28,1],
[74,33,28,2],
[74,33,28,3],
[74,33,28,4],
[74,33,28,5],
[74,33,28,6],
[74,33,28,8],
[74,33,28,10],
[74,33,28,13],
[74,33,28,14],
[74,33,30,0],
[74,33,30,1],
[74,33,30,2],
[74,33,30,3],
[74,33,30,4],
[74,33,30,5],
[74,33,30,6],
[74,33,30,7],
[74,33,30,8],
[74,33,30,10],
[74,33,31,0],
[74,33,31,1],
[74,33,31,2],
[74,33,31,3],
[74,33,31,4],
[74,33,31,5],
[74,33,31,6],
[74,33,31,7],
[74,33,31,8],
[74,34,3,0],
[74,34,3,1],
[74,34,3,2],
[74,34,4,0],
[74,34,4,1],
[74,34,4,2],
[74,34,4,3],
[74,34,5,0],
[74,34,5,1],
[74,34,5,2],
[74,34,5,4],
[74,34,6,0],
[74,34,6,1],
[74,34,6,2],
[74,34,6,4],
[74,34,8,0],
[74,34,8,1],
[74,34,8,2],
[74,34,8,4],
[74,34,10,0],
[74,34,10,1],
[74,34,10,2],
[74,34,10,3],
[74,34,10,4],
[74,34,10,5],
[74,34,10,6],
[74,34,10,8],
[74,34,11,0],
[74,34,11,1],
[74,34,11,2],
[74,34,11,3],
[74,34,11,4],
[74,34,11,5],
[74,34,11,6],
[74,34,11,8],
[74,34,11,10],
[74,34,12,0],
[74,34,12,1],
[74,34,12,2],
[74,34,12,3],
[74,34,12,4],
[74,34,12,5],
[74,34,12,6],
[74,34,12,8],
[74,34,12,10],
[74,34,13,0],
[74,34,13,1],
[74,34,13,2],
[74,34,13,3],
[74,34,13,4],
[74,34,13,5],
[74,34,13,6],
[74,34,13,8],
[74,34,13,10],
[74,34,13,11],
[74,34,13,12],
[74,34,14,0],
[74,34,14,1],
[74,34,14,2],
[74,34,14,3],
[74,34,14,4],
[74,34,14,5],
[74,34,14,6],
[74,34,14,8],
[74,34,14,10],
[74,34,14,11],
[74,34,14,12],
[74,34,16,0],
[74,34,16,1],
[74,34,16,2],
[74,34,16,3],
[74,34,16,4],
[74,34,16,5],
[74,34,16,6],
[74,34,16,8],
[74,34,16,12],
[74,34,16,13],
[74,34,16,14],
[74,34,17,0],
[74,34,17,1],
[74,34,17,2],
[74,34,17,3],
[74,34,17,4],
[74,34,17,5],
[74,34,17,6],
[74,34,17,8],
[74,34,17,12],
[74,34,17,13],
[74,34,17,14],
[74,34,17,16],
[74,34,19,0],
[74,34,19,1],
[74,34,19,2],
[74,34,19,3],
[74,34,19,4],
[74,34,19,5],
[74,34,19,8],
[74,34,19,10],
[74,34,19,11],
[74,34,19,12],
[74,34,19,16],
[74,34,19,17],
[74,34,20,0],
[74,34,20,1],
[74,34,20,2],
[74,34,20,3],
[74,34,20,4],
[74,34,20,6],
[74,34,20,8],
[74,34,20,11],
[74,34,20,12],
[74,34,20,13],
[74,34,20,14],
[74,34,20,16],
[74,34,20,17],
[74,34,20,19],
[74,34,21,0],
[74,34,21,1],
[74,34,21,2],
[74,34,21,10],
[74,34,21,11],
[74,34,21,12],
[74,34,21,13],
[74,34,21,14],
[74,34,21,16],
[74,34,21,17],
[74,34,21,19],
[74,34,21,20],
[74,34,22,3],
[74,34,22,4],
[74,34,22,5],
[74,34,22,6],
[74,34,22,8],
[74,34,22,11],
[74,34,22,12],
[74,34,22,13],
[74,34,22,14],
[74,34,22,16],
[74,34,22,17],
[74,34,22,19],
[74,34,23,0],
[74,34,23,1],
[74,34,23,2],
[74,34,23,3],
[74,34,23,4],
[74,34,23,5],
[74,34,23,6],
[74,34,23,8],
[74,34,23,10],
[74,34,23,11],
[74,34,23,12],
[74,34,23,13],
[74,34,23,14],
[74,34,23,16],
[74,34,23,17],
[74,34,23,19],
[74,34,24,0],
[74,34,24,1],
[74,34,24,2],
[74,34,24,3],
[74,34,24,4],
[74,34,24,5],
[74,34,24,6],
[74,34,24,8],
[74,34,24,10],
[74,34,24,11],
[74,34,24,12],
[74,34,24,13],
[74,34,24,14],
[74,34,24,17],
[74,34,24,19],
[74,34,27,0],
[74,34,27,1],
[74,34,27,2],
[74,34,27,3],
[74,34,27,4],
[74,34,27,5],
[74,34,27,6],
[74,34,27,8],
[74,34,27,11],
[74,34,27,12],
[74,34,27,13],
[74,34,27,14],
[74,34,29,0],
[74,34,29,1],
[74,34,29,2],
[74,34,29,3],
[74,34,29,4],
[74,34,29,5],
[74,34,29,6],
[74,34,29,8],
[74,34,29,10],
[74,34,30,0],
[74,34,30,1],
[74,34,30,2],
[74,34,30,3],
[74,34,30,4],
[74,34,30,5],
[74,34,30,6],
[74,34,30,8],
[74,34,31,0],
[74,34,31,1],
[74,34,31,2],
[74,34,31,3],
[74,34,31,4],
[74,34,31,5],
[74,34,31,6],
[74,34,32,0],
[74,34,32,1],
[74,34,32,2],
[74,34,32,3],
[74,34,32,4],
[74,34,32,5],
[74,34,33,0],
[74,34,33,1],
[74,34,33,2],
[74,34,33,3],
[74,34,33,4],
[74,36,3,0],
[74,36,3,1],
[74,36,3,2],
[74,36,4,0],
[74,36,4,1],
[74,36,4,2],
[74,36,4,3],
[74,36,5,0],
[74,36,5,1],
[74,36,5,2],
[74,36,5,4],
[74,36,6,0],
[74,36,6,1],
[74,36,6,2],
[74,36,6,4],
[74,36,7,0],
[74,36,7,1],
[74,36,7,2],
[74,36,7,3],
[74,36,7,4],
[74,36,7,5],
[74,36,7,6],
[74,36,8,0],
[74,36,8,1],
[74,36,8,2],
[74,36,8,4],
[74,36,8,7],
[74,36,10,0],
[74,36,10,1],
[74,36,10,2],
[74,36,10,3],
[74,36,10,4],
[74,36,10,5],
[74,36,10,6],
[74,36,10,7],
[74,36,10,8],
[74,36,11,0],
[74,36,11,1],
[74,36,11,2],
[74,36,11,3],
[74,36,11,4],
[74,36,11,5],
[74,36,11,6],
[74,36,11,7],
[74,36,11,8],
[74,36,11,10],
[74,36,12,0],
[74,36,12,1],
[74,36,12,2],
[74,36,12,3],
[74,36,12,4],
[74,36,12,5],
[74,36,12,6],
[74,36,12,7],
[74,36,12,8],
[74,36,12,10],
[74,36,16,0],
[74,36,16,1],
[74,36,16,2],
[74,36,16,3],
[74,36,16,4],
[74,36,16,5],
[74,36,16,8],
[74,36,16,10],
[74,36,16,11],
[74,36,16,12],
[74,36,17,0],
[74,36,17,1],
[74,36,17,2],
[74,36,17,3],
[74,36,17,4],
[74,36,17,5],
[74,36,17,8],
[74,36,17,10],
[74,36,17,11],
[74,36,17,12],
[74,36,17,16],
[74,36,20,3],
[74,36,20,4],
[74,36,20,5],
[74,36,20,6],
[74,36,20,7],
[74,36,20,8],
[74,36,20,11],
[74,36,20,12],
[74,36,20,16],
[74,36,20,17],
[74,36,21,0],
[74,36,21,1],
[74,36,21,2],
[74,36,21,4],
[74,36,21,7],
[74,36,21,10],
[74,36,21,11],
[74,36,21,12],
[74,36,21,16],
[74,36,21,17],
[74,36,22,0],
[74,36,22,1],
[74,36,22,2],
[74,36,22,3],
[74,36,22,4],
[74,36,22,5],
[74,36,22,6],
[74,36,22,7],
[74,36,22,8],
[74,36,22,11],
[74,36,22,12],
[74,36,22,16],
[74,36,22,17],
[74,36,23,0],
[74,36,23,1],
[74,36,23,2],
[74,36,23,3],
[74,36,23,4],
[74,36,23,5],
[74,36,23,6],
[74,36,23,7],
[74,36,23,8],
[74,36,23,10],
[74,36,23,11],
[74,36,23,12],
[74,36,23,16],
[74,36,23,17],
[74,36,24,0],
[74,36,24,1],
[74,36,24,2],
[74,36,24,3],
[74,36,24,4],
[74,36,24,5],
[74,36,24,6],
[74,36,24,7],
[74,36,24,8],
[74,36,24,10],
[74,36,24,11],
[74,36,24,12],
[74,36,24,17],
[74,36,25,0],
[74,36,25,1],
[74,36,25,2],
[74,36,25,3],
[74,36,25,4],
[74,36,25,5],
[74,36,25,6],
[74,36,25,8],
[74,36,25,10],
[74,36,25,11],
[74,36,25,12],
[74,36,27,0],
[74,36,27,1],
[74,36,27,2],
[74,36,27,3],
[74,36,27,4],
[74,36,27,5],
[74,36,27,6],
[74,36,27,7],
[74,36,27,8],
[74,36,27,11],
[74,36,28,0],
[74,36,28,1],
[74,36,28,2],
[74,36,28,3],
[74,36,28,4],
[74,36,28,5],
[74,36,28,6],
[74,36,28,8],
[74,36,29,0],
[74,36,29,1],
[74,36,29,2],
[74,36,29,3],
[74,36,29,4],
[74,36,29,5],
[74,36,29,6],
[74,36,29,7],
[74,36,30,0],
[74,36,30,1],
[74,36,30,2],
[74,36,30,3],
[74,36,30,4],
[74,36,30,5],
[74,36,31,0],
[74,36,31,1],
[74,36,31,2],
[74,36,31,3],
[74,36,31,4],
[74,36,32,0],
[74,36,32,1],
[74,36,32,2],
[74,37,3,0],
[74,37,3,1],
[74,37,3,2],
[74,37,5,0],
[74,37,5,1],
[74,37,5,2],
[74,37,6,0],
[74,37,6,1],
[74,37,6,2],
[74,37,7,0],
[74,37,7,1],
[74,37,7,2],
[74,37,7,3],
[74,37,7,5],
[74,37,7,6],
[74,37,8,0],
[74,37,8,1],
[74,37,8,2],
[74,37,8,7],
[74,37,10,0],
[74,37,10,1],
[74,37,10,2],
[74,37,10,3],
[74,37,10,5],
[74,37,10,6],
[74,37,10,7],
[74,37,10,8],
[74,37,11,0],
[74,37,11,1],
[74,37,11,2],
[74,37,11,3],
[74,37,11,5],
[74,37,11,6],
[74,37,11,7],
[74,37,11,8],
[74,37,11,10],
[74,37,12,0],
[74,37,12,1],
[74,37,12,2],
[74,37,12,3],
[74,37,12,5],
[74,37,12,6],
[74,37,12,7],
[74,37,12,8],
[74,37,13,0],
[74,37,13,1],
[74,37,13,2],
[74,37,13,3],
[74,37,13,5],
[74,37,13,6],
[74,37,13,7],
[74,37,13,8],
[74,37,13,12],
[74,37,14,0],
[74,37,14,1],
[74,37,14,2],
[74,37,14,3],
[74,37,14,5],
[74,37,14,6],
[74,37,14,7],
[74,37,14,10],
[74,37,14,11],
[74,37,14,12],
[74,37,16,0],
[74,37,16,1],
[74,37,16,2],
[74,37,16,3],
[74,37,16,6],
[74,37,16,7],
[74,37,16,8],
[74,37,16,10],
[74,37,16,11],
[74,37,16,12],
[74,37,16,13],
[74,37,16,14],
[74,37,17,0],
[74,37,17,1],
[74,37,17,2],
[74,37,17,3],
[74,37,17,6],
[74,37,17,7],
[74,37,17,8],
[74,37,17,10],
[74,37,17,11],
[74,37,17,12],
[74,37,17,13],
[74,37,17,14],
[74,37,17,16],
[74,37,19,3],
[74,37,19,5],
[74,37,19,6],
[74,37,19,7],
[74,37,19,8],
[74,37,19,10],
[74,37,19,11],
[74,37,19,12],
[74,37,19,16],
[74,37,19,17],
[74,37,20,0],
[74,37,20,1],
[74,37,20,2],
[74,37,20,3],
[74,37,20,5],
[74,37,20,6],
[74,37,20,7],
[74,37,20,8],
[74,37,20,11],
[74,37,20,12],
[74,37,20,13],
[74,37,20,14],
[74,37,20,16],
[74,37,20,17],
[74,37,20,19],
[74,37,21,0],
[74,37,21,1],
[74,37,21,2],
[74,37,21,7],
[74,37,21,10],
[74,37,21,11],
[74,37,21,12],
[74,37,21,13],
[74,37,21,14],
[74,37,21,16],
[74,37,21,17],
[74,37,22,0],
[74,37,22,1],
[74,37,22,2],
[74,37,22,3],
[74,37,22,5],
[74,37,22,6],
[74,37,22,7],
[74,37,22,8],
[74,37,22,11],
[74,37,22,12],
[74,37,22,13],
[74,37,22,14],
[74,37,22,16],
[74,37,22,17],
[74,37,23,0],
[74,37,23,1],
[74,37,23,2],
[74,37,23,3],
[74,37,23,5],
[74,37,23,6],
[74,37,23,7],
[74,37,23,8],
[74,37,23,10],
[74,37,23,11],
[74,37,23,12],
[74,37,23,13],
[74,37,23,14],
[74,37,24,0],
[74,37,24,1],
[74,37,24,2],
[74,37,24,3],
[74,37,24,5],
[74,37,24,6],
[74,37,24,7],
[74,37,24,8],
[74,37,24,10],
[74,37,24,11],
[74,37,24,12],
[74,37,24,13],
[74,37,24,14],
[74,37,25,0],
[74,37,25,1],
[74,37,25,2],
[74,37,25,3],
[74,37,25,5],
[74,37,25,6],
[74,37,25,8],
[74,37,25,10],
[74,37,25,11],
[74,37,25,12],
[74,37,25,13],
[74,37,27,0],
[74,37,27,1],
[74,37,27,2],
[74,37,27,3],
[74,37,27,5],
[74,37,27,6],
[74,37,27,7],
[74,37,27,8],
[74,37,28,0],
[74,37,28,1],
[74,37,28,2],
[74,37,28,3],
[74,37,28,5],
[74,37,28,6],
[74,37,29,0],
[74,37,29,1],
[74,37,29,2],
[74,37,29,3],
[74,37,29,5],
[74,37,30,0],
[74,37,30,1],
[74,37,30,2],
[74,37,30,3],
[74,37,31,0],
[74,37,31,1],
[74,37,31,2],
[74,38,3,0],
[74,38,3,1],
[74,38,3,2],
[74,38,4,0],
[74,38,4,1],
[74,38,4,2],
[74,38,4,3],
[74,38,5,0],
[74,38,5,1],
[74,38,5,2],
[74,38,5,4],
[74,38,6,0],
[74,38,6,1],
[74,38,6,2],
[74,38,6,4],
[74,38,8,0],
[74,38,8,1],
[74,38,8,2],
[74,38,8,4],
[74,38,10,0],
[74,38,10,1],
[74,38,10,2],
[74,38,10,3],
[74,38,10,4],
[74,38,10,5],
[74,38,10,6],
[74,38,10,8],
[74,38,11,0],
[74,38,11,1],
[74,38,11,2],
[74,38,11,3],
[74,38,11,4],
[74,38,11,5],
[74,38,11,6],
[74,38,11,8],
[74,38,12,0],
[74,38,12,1],
[74,38,12,2],
[74,38,12,3],
[74,38,12,4],
[74,38,12,5],
[74,38,12,6],
[74,38,12,10],
[74,38,13,0],
[74,38,13,1],
[74,38,13,2],
[74,38,13,3],
[74,38,13,4],
[74,38,13,5],
[74,38,13,6],
[74,38,13,10],
[74,38,13,11],
[74,38,13,12],
[74,38,14,0],
[74,38,14,1],
[74,38,14,2],
[74,38,14,3],
[74,38,14,4],
[74,38,14,5],
[74,38,14,8],
[74,38,14,10],
[74,38,14,11],
[74,38,14,12],
[74,38,16,0],
[74,38,16,1],
[74,38,16,2],
[74,38,16,5],
[74,38,16,6],
[74,38,16,8],
[74,38,16,10],
[74,38,16,11],
[74,38,16,12],
[74,38,16,13],
[74,38,16,14],
[74,38,17,0],
[74,38,17,1],
[74,38,17,2],
[74,38,17,5],
[74,38,17,6],
[74,38,17,8],
[74,38,17,10],
[74,38,17,11],
[74,38,17,12],
[74,38,17,13],
[74,38,17,14],
[74,38,17,16],
[74,38,19,0],
[74,38,19,1],
[74,38,19,2],
[74,38,19,3],
[74,38,19,4],
[74,38,19,5],
[74,38,19,6],
[74,38,19,8],
[74,38,19,10],
[74,38,19,11],
[74,38,19,12],
[74,38,19,16],
[74,38,19,17],
[74,38,20,0],
[74,38,20,1],
[74,38,20,2],
[74,38,20,3],
[74,38,20,4],
[74,38,20,5],
[74,38,20,6],
[74,38,20,8],
[74,38,20,11],
[74,38,20,12],
[74,38,20,13],
[74,38,20,14],
[74,38,20,16],
[74,38,20,17],
[74,38,21,0],
[74,38,21,1],
[74,38,21,2],
[74,38,21,4],
[74,38,21,10],
[74,38,21,11],
[74,38,21,12],
[74,38,21,13],
[74,38,21,14],
[74,38,21,16],
[74,38,21,17],
[74,38,22,0],
[74,38,22,1],
[74,38,22,2],
[74,38,22,3],
[74,38,22,4],
[74,38,22,5],
[74,38,22,6],
[74,38,22,8],
[74,38,22,11],
[74,38,22,12],
[74,38,22,13],
[74,38,22,14],
[74,38,23,0],
[74,38,23,1],
[74,38,23,2],
[74,38,23,3],
[74,38,23,4],
[74,38,23,5],
[74,38,23,6],
[74,38,23,8],
[74,38,23,10],
[74,38,23,11],
[74,38,23,12],
[74,38,23,13],
[74,38,23,14],
[74,38,24,0],
[74,38,24,1],
[74,38,24,2],
[74,38,24,3],
[74,38,24,4],
[74,38,24,5],
[74,38,24,6],
[74,38,24,8],
[74,38,24,10],
[74,38,24,11],
[74,38,24,12],
[74,38,24,13],
[74,38,24,14],
[74,38,27,0],
[74,38,27,1],
[74,38,27,2],
[74,38,27,3],
[74,38,27,4],
[74,38,27,5],
[74,38,27,6],
[74,38,29,0],
[74,38,29,1],
[74,38,29,2],
[74,38,29,3],
[74,38,29,4],
[74,38,30,0],
[74,38,30,1],
[74,38,30,2],
[74,39,4,0],
[74,39,4,1],
[74,39,4,2],
[74,39,7,0],
[74,39,7,1],
[74,39,7,2],
[74,39,7,4],
[74,39,10,0],
[74,39,10,1],
[74,39,10,2],
[74,39,10,4],
[74,39,10,7],
[74,39,11,0],
[74,39,11,1],
[74,39,11,2],
[74,39,11,4],
[74,39,11,7],
[74,39,11,10],
[74,39,12,0],
[74,39,12,1],
[74,39,12,2],
[74,39,12,4],
[74,39,12,10],
[74,39,13,0],
[74,39,13,1],
[74,39,13,2],
[74,39,13,4],
[74,39,13,10],
[74,39,13,11],
[74,39,13,12],
[74,39,14,0],
[74,39,14,1],
[74,39,14,2],
[74,39,14,4],
[74,39,14,7],
[74,39,14,10],
[74,39,14,11],
[74,39,14,12],
[74,39,16,4],
[74,39,16,7],
[74,39,16,10],
[74,39,16,11],
[74,39,16,12],
[74,39,16,13],
[74,39,16,14],
[74,39,17,4],
[74,39,17,7],
[74,39,17,10],
[74,39,17,11],
[74,39,17,12],
[74,39,17,13],
[74,39,17,14],
[74,39,17,16],
[74,39,19,0],
[74,39,19,1],
[74,39,19,2],
[74,39,19,4],
[74,39,19,7],
[74,39,19,10],
[74,39,19,11],
[74,39,19,12],
[74,39,19,16],
[74,39,19,17],
[74,39,20,0],
[74,39,20,1],
[74,39,20,2],
[74,39,20,4],
[74,39,20,7],
[74,39,20,11],
[74,39,20,12],
[74,39,20,13],
[74,39,20,14],
[74,39,20,16],
[74,39,20,17],
[74,39,22,0],
[74,39,22,1],
[74,39,22,2],
[74,39,22,4],
[74,39,22,7],
[74,39,22,11],
[74,39,22,12],
[74,39,22,13],
[74,39,22,14],
[74,39,23,0],
[74,39,23,1],
[74,39,23,2],
[74,39,23,4],
[74,39,23,7],
[74,39,23,10],
[74,39,23,11],
[74,39,23,12],
[74,39,23,13],
[74,39,24,0],
[74,39,24,1],
[74,39,24,2],
[74,39,24,4],
[74,39,24,7],
[74,39,24,10],
[74,39,24,11],
[74,39,24,12],
[74,39,24,13],
[74,39,25,0],
[74,39,25,1],
[74,39,25,2],
[74,39,25,4],
[74,39,27,0],
[74,39,27,1],
[74,39,27,2],
[74,39,27,4],
[74,39,28,0],
[74,39,28,1],
[74,39,28,2],
[74,39,28,4],
[74,39,29,0],
[74,39,29,1],
[74,39,29,2],
[74,42,3,0],
[74,42,3,1],
[74,42,3,2],
[74,42,4,0],
[74,42,4,1],
[74,42,4,2],
[74,42,4,3],
[74,42,5,0],
[74,42,5,1],
[74,42,5,2],
[74,42,5,4],
[74,42,6,0],
[74,42,6,1],
[74,42,6,2],
[74,42,6,4],
[74,42,7,0],
[74,42,7,1],
[74,42,7,2],
[74,42,7,3],
[74,42,7,4],
[74,42,7,5],
[74,42,7,6],
[74,42,8,0],
[74,42,8,1],
[74,42,8,2],
[74,42,8,4],
[74,42,11,0],
[74,42,11,1],
[74,42,11,2],
[74,42,11,3],
[74,42,11,4],
[74,42,11,6],
[74,42,11,7],
[74,42,11,8],
[74,42,12,0],
[74,42,12,1],
[74,42,12,2],
[74,42,12,5],
[74,42,12,6],
[74,42,12,7],
[74,42,12,8],
[74,42,13,0],
[74,42,13,1],
[74,42,13,2],
[74,42,13,5],
[74,42,13,6],
[74,42,13,7],
[74,42,13,8],
[74,42,13,11],
[74,42,13,12],
[74,42,14,3],
[74,42,14,4],
[74,42,14,5],
[74,42,14,6],
[74,42,14,7],
[74,42,14,8],
[74,42,14,11],
[74,42,14,12],
[74,42,16,0],
[74,42,16,1],
[74,42,16,2],
[74,42,16,3],
[74,42,16,4],
[74,42,16,5],
[74,42,16,6],
[74,42,16,7],
[74,42,16,8],
[74,42,16,11],
[74,42,16,12],
[74,42,16,13],
[74,42,16,14],
[74,42,17,0],
[74,42,17,1],
[74,42,17,2],
[74,42,17,3],
[74,42,17,4],
[74,42,17,5],
[74,42,17,6],
[74,42,17,7],
[74,42,17,8],
[74,42,17,11],
[74,42,17,12],
[74,42,17,13],
[74,42,17,14],
[74,42,17,16],
[74,42,19,0],
[74,42,19,1],
[74,42,19,2],
[74,42,19,3],
[74,42,19,4],
[74,42,19,5],
[74,42,19,6],
[74,42,19,7],
[74,42,19,8],
[74,42,19,11],
[74,42,19,12],
[74,42,21,0],
[74,42,21,1],
[74,42,21,2],
[74,42,21,4],
[74,42,21,7],
[74,42,21,11],
[74,42,21,12],
[74,42,21,13],
[74,42,23,0],
[74,42,23,1],
[74,42,23,2],
[74,42,23,3],
[74,42,23,4],
[74,42,23,5],
[74,42,23,6],
[74,42,23,7],
[74,42,23,8],
[74,42,24,0],
[74,42,24,1],
[74,42,24,2],
[74,42,24,3],
[74,42,24,4],
[74,42,24,5],
[74,42,24,6],
[74,42,24,7],
[74,42,24,8],
[74,42,25,0],
[74,42,25,1],
[74,42,25,2],
[74,42,25,3],
[74,42,25,4],
[74,42,25,5],
[74,43,3,0],
[74,43,3,1],
[74,43,3,2],
[74,43,4,0],
[74,43,4,1],
[74,43,4,2],
[74,43,4,3],
[74,43,5,0],
[74,43,5,1],
[74,43,5,2],
[74,43,5,4],
[74,43,6,0],
[74,43,6,1],
[74,43,6,2],
[74,43,6,4],
[74,43,8,0],
[74,43,8,1],
[74,43,8,2],
[74,43,8,4],
[74,43,10,0],
[74,43,10,1],
[74,43,10,2],
[74,43,10,5],
[74,43,10,6],
[74,43,10,8],
[74,43,11,0],
[74,43,11,1],
[74,43,11,2],
[74,43,11,5],
[74,43,11,6],
[74,43,11,8],
[74,43,11,10],
[74,43,12,3],
[74,43,12,4],
[74,43,12,5],
[74,43,12,6],
[74,43,12,8],
[74,43,12,10],
[74,43,13,3],
[74,43,13,4],
[74,43,13,5],
[74,43,13,6],
[74,43,13,8],
[74,43,13,10],
[74,43,13,11],
[74,43,13,12],
[74,43,14,0],
[74,43,14,1],
[74,43,14,2],
[74,43,14,3],
[74,43,14,4],
[74,43,14,5],
[74,43,14,6],
[74,43,14,8],
[74,43,14,10],
[74,43,14,11],
[74,43,14,12],
[74,43,16,0],
[74,43,16,1],
[74,43,16,2],
[74,43,16,3],
[74,43,16,4],
[74,43,16,5],
[74,43,16,6],
[74,43,16,8],
[74,43,16,10],
[74,43,16,11],
[74,43,16,12],
[74,43,16,13],
[74,43,16,14],
[74,43,17,0],
[74,43,17,1],
[74,43,17,2],
[74,43,17,3],
[74,43,17,4],
[74,43,17,5],
[74,43,17,6],
[74,43,17,8],
[74,43,17,10],
[74,43,17,11],
[74,43,17,12],
[74,43,17,13],
[74,43,17,14],
[74,43,17,16],
[74,43,19,0],
[74,43,19,1],
[74,43,19,2],
[74,43,19,3],
[74,43,19,4],
[74,43,19,5],
[74,43,19,6],
[74,43,19,8],
[74,43,19,10],
[74,43,19,11],
[74,43,19,12],
[74,43,20,0],
[74,43,20,1],
[74,43,20,2],
[74,43,20,3],
[74,43,20,4],
[74,43,20,5],
[74,43,20,6],
[74,43,20,8],
[74,43,20,11],
[74,43,20,12],
[74,43,20,13],
[74,43,21,0],
[74,43,21,1],
[74,43,21,2],
[74,43,21,4],
[74,43,21,10],
[74,43,21,11],
[74,43,22,0],
[74,43,22,1],
[74,43,22,2],
[74,43,22,3],
[74,43,22,4],
[74,43,22,5],
[74,43,22,6],
[74,43,22,8],
[74,43,23,0],
[74,43,23,1],
[74,43,23,2],
[74,43,23,3],
[74,43,23,4],
[74,43,23,5],
[74,43,23,6],
[74,43,24,0],
[74,43,24,1],
[74,43,24,2],
[74,43,24,3],
[74,43,24,4],
[74,43,24,5],
[74,43,24,6],
[74,44,3,0],
[74,44,3,1],
[74,44,3,2],
[74,44,4,0],
[74,44,4,1],
[74,44,4,2],
[74,44,4,3],
[74,44,5,0],
[74,44,5,1],
[74,44,5,2],
[74,44,5,4],
[74,44,6,0],
[74,44,6,1],
[74,44,6,2],
[74,44,6,4],
[74,44,7,0],
[74,44,7,1],
[74,44,7,2],
[74,44,7,3],
[74,44,7,4],
[74,44,7,6],
[74,44,8,0],
[74,44,8,1],
[74,44,8,2],
[74,44,8,7],
[74,44,10,3],
[74,44,10,4],
[74,44,10,5],
[74,44,10,6],
[74,44,10,7],
[74,44,10,8],
[74,44,11,3],
[74,44,11,4],
[74,44,11,5],
[74,44,11,6],
[74,44,11,7],
[74,44,11,8],
[74,44,11,10],
[74,44,12,0],
[74,44,12,1],
[74,44,12,2],
[74,44,12,3],
[74,44,12,4],
[74,44,12,5],
[74,44,12,6],
[74,44,12,7],
[74,44,12,8],
[74,44,12,10],
[74,44,13,0],
[74,44,13,1],
[74,44,13,2],
[74,44,13,3],
[74,44,13,4],
[74,44,13,5],
[74,44,13,6],
[74,44,13,7],
[74,44,13,8],
[74,44,13,10],
[74,44,13,11],
[74,44,13,12],
[74,44,14,0],
[74,44,14,1],
[74,44,14,2],
[74,44,14,3],
[74,44,14,4],
[74,44,14,5],
[74,44,14,6],
[74,44,14,7],
[74,44,14,8],
[74,44,14,10],
[74,44,14,11],
[74,44,14,12],
[74,44,16,0],
[74,44,16,1],
[74,44,16,2],
[74,44,16,3],
[74,44,16,4],
[74,44,16,5],
[74,44,16,6],
[74,44,16,7],
[74,44,16,8],
[74,44,16,10],
[74,44,16,11],
[74,44,16,12],
[74,44,16,13],
[74,44,16,14],
[74,44,19,0],
[74,44,19,1],
[74,44,19,2],
[74,44,19,3],
[74,44,19,4],
[74,44,19,5],
[74,44,19,6],
[74,44,19,7],
[74,44,19,8],
[74,44,19,10],
[74,44,19,11],
[74,44,19,12],
[74,44,20,0],
[74,44,20,1],
[74,44,20,2],
[74,44,20,3],
[74,44,20,4],
[74,44,20,5],
[74,44,20,6],
[74,44,20,7],
[74,44,20,8],
[74,44,20,11],
[74,44,21,0],
[74,44,21,1],
[74,44,21,2],
[74,44,21,4],
[74,44,21,7],
[74,44,22,0],
[74,44,22,1],
[74,44,22,2],
[74,44,22,3],
[74,44,22,4],
[74,44,22,5],
[74,44,22,6],
[74,44,22,7],
[74,44,23,0],
[74,44,23,1],
[74,44,23,2],
[74,44,23,3],
[74,44,23,4],
[74,44,23,5],
[74,44,24,0],
[74,44,24,1],
[74,44,24,2],
[74,44,24,3],
[74,44,24,4],
[74,44,24,5],
[74,44,25,0],
[74,44,25,1],
[74,44,25,2],
[74,47,3,0],
[74,47,3,1],
[74,47,3,2],
[74,47,4,0],
[74,47,4,1],
[74,47,4,2],
[74,47,5,4],
[74,47,6,0],
[74,47,6,1],
[74,47,6,2],
[74,47,6,4],
[74,47,7,0],
[74,47,7,1],
[74,47,7,2],
[74,47,7,3],
[74,47,7,4],
[74,47,7,5],
[74,47,7,6],
[74,47,8,0],
[74,47,8,1],
[74,47,8,2],
[74,47,8,4],
[74,47,8,7],
[74,47,10,0],
[74,47,10,1],
[74,47,10,2],
[74,47,10,3],
[74,47,10,4],
[74,47,10,5],
[74,47,10,6],
[74,47,10,7],
[74,47,10,8],
[74,47,11,0],
[74,47,11,1],
[74,47,11,2],
[74,47,11,3],
[74,47,11,4],
[74,47,11,5],
[74,47,11,6],
[74,47,11,7],
[74,47,11,8],
[74,47,11,10],
[74,47,12,0],
[74,47,12,1],
[74,47,12,2],
[74,47,12,3],
[74,47,12,4],
[74,47,12,5],
[74,47,12,6],
[74,47,12,7],
[74,47,12,8],
[74,47,12,10],
[74,47,13,0],
[74,47,13,1],
[74,47,13,2],
[74,47,13,3],
[74,47,13,4],
[74,47,13,5],
[74,47,13,6],
[74,47,13,7],
[74,47,13,8],
[74,47,13,10],
[74,47,13,11],
[74,47,13,12],
[74,47,14,0],
[74,47,14,1],
[74,47,14,2],
[74,47,14,3],
[74,47,14,4],
[74,47,14,5],
[74,47,14,6],
[74,47,14,7],
[74,47,14,8],
[74,47,14,10],
[74,47,14,11],
[74,47,14,12],
[74,47,16,0],
[74,47,16,1],
[74,47,16,2],
[74,47,16,3],
[74,47,16,4],
[74,47,16,5],
[74,47,16,6],
[74,47,16,7],
[74,47,16,8],
[74,47,16,10],
[74,47,16,11],
[74,47,17,0],
[74,47,17,1],
[74,47,17,2],
[74,47,17,3],
[74,47,17,4],
[74,47,17,5],
[74,47,17,6],
[74,47,17,7],
[74,47,17,8],
[74,47,17,10],
[74,47,17,11],
[74,47,19,0],
[74,47,19,1],
[74,47,19,2],
[74,47,19,3],
[74,47,19,4],
[74,47,19,5],
[74,47,19,6],
[74,47,19,7],
[74,47,20,0],
[74,47,20,1],
[74,47,20,2],
[74,47,20,3],
[74,47,20,4],
[74,47,20,5],
[74,47,21,0],
[74,47,21,1],
[74,47,21,2],
[74,47,21,4],
[74,47,22,0],
[74,47,22,1],
[74,47,22,2],
[74,48,4,3],
[74,48,5,0],
[74,48,5,1],
[74,48,5,2],
[74,48,5,4],
[74,48,6,0],
[74,48,6,1],
[74,48,6,2],
[74,48,6,4],
[74,48,7,0],
[74,48,7,1],
[74,48,7,2],
[74,48,7,3],
[74,48,7,4],
[74,48,7,5],
[74,48,7,6],
[74,48,8,0],
[74,48,8,1],
[74,48,8,2],
[74,48,8,4],
[74,48,8,7],
[74,48,10,0],
[74,48,10,1],
[74,48,10,2],
[74,48,10,3],
[74,48,10,4],
[74,48,10,5],
[74,48,10,6],
[74,48,10,7],
[74,48,10,8],
[74,48,13,0],
[74,48,13,1],
[74,48,13,2],
[74,48,13,3],
[74,48,13,4],
[74,48,13,5],
[74,48,13,6],
[74,48,13,7],
[74,48,13,8],
[74,48,13,10],
[74,48,14,0],
[74,48,14,1],
[74,48,14,2],
[74,48,14,3],
[74,48,14,4],
[74,48,14,5],
[74,48,14,6],
[74,48,14,7],
[74,48,14,8],
[74,48,14,10],
[74,48,16,0],
[74,48,16,1],
[74,48,16,2],
[74,48,16,3],
[74,48,16,4],
[74,48,16,5],
[74,48,16,6],
[74,48,16,7],
[74,48,16,8],
[74,48,17,0],
[74,48,17,1],
[74,48,17,2],
[74,48,17,3],
[74,48,17,4],
[74,48,17,5],
[74,48,17,6],
[74,48,17,7],
[74,48,17,8],
[74,48,19,0],
[74,48,19,1],
[74,48,19,2],
[74,48,19,3],
[74,48,19,4],
[74,48,19,5],
[74,48,20,0],
[74,48,20,1],
[74,48,20,2],
[74,48,20,3],
[74,48,20,4],
[74,48,21,0],
[74,48,21,1],
[74,48,21,2],
[74,51,7,0],
[74,51,7,1],
[74,51,7,2],
[74,51,7,4],
[74,51,10,0],
[74,51,10,1],
[74,51,10,2],
[74,51,10,4],
[74,51,10,7],
[74,51,11,0],
[74,51,11,1],
[74,51,11,2],
[74,51,11,4],
[74,51,11,7],
[74,51,11,10],
[74,51,12,0],
[74,51,12,1],
[74,51,12,2],
[74,51,12,4],
[74,51,12,7],
[74,51,12,10],
[74,51,13,0],
[74,51,13,1],
[74,51,13,2],
[74,51,13,4],
[74,51,13,7],
[74,51,13,10],
[74,51,13,11],
[74,51,13,12],
[74,51,14,0],
[74,51,14,1],
[74,51,14,2],
[74,51,14,4],
[74,51,14,7],
[74,51,14,10],
[74,51,14,11],
[74,51,14,12],
[74,51,16,0],
[74,51,16,1],
[74,51,16,2],
[74,51,16,4],
[74,51,16,7],
[74,51,17,0],
[74,51,17,1],
[74,51,17,2],
[74,51,17,4],
[74,51,17,7],
[74,51,19,0],
[74,51,19,1],
[74,51,19,2],
[74,51,19,4],
[74,51,20,0],
[74,51,20,1],
[74,51,20,2],
[74,51,20,4],
[74,53,4,3],
[74,53,5,0],
[74,53,5,1],
[74,53,5,2],
[74,53,5,4],
[74,53,6,0],
[74,53,6,1],
[74,53,6,2],
[74,53,6,4],
[74,53,7,0],
[74,53,7,1],
[74,53,7,2],
[74,53,7,3],
[74,53,7,4],
[74,53,7,5],
[74,53,7,6],
[74,53,8,0],
[74,53,8,1],
[74,53,8,2],
[74,53,8,4],
[74,53,8,7],
[74,53,11,0],
[74,53,11,1],
[74,53,11,2],
[74,53,11,3],
[74,53,11,4],
[74,53,11,5],
[74,53,11,6],
[74,53,11,7],
[74,53,11,8],
[74,53,12,0],
[74,53,12,1],
[74,53,12,2],
[74,53,12,3],
[74,53,12,4],
[74,53,12,5],
[74,53,12,6],
[74,53,12,7],
[74,53,12,8],
[74,53,13,0],
[74,53,13,1],
[74,53,13,2],
[74,53,13,3],
[74,53,13,4],
[74,53,13,5],
[74,53,13,6],
[74,53,13,7],
[74,53,13,8],
[74,53,13,11],
[74,53,13,12],
[74,53,14,0],
[74,53,14,1],
[74,53,14,2],
[74,53,14,3],
[74,53,14,4],
[74,53,14,5],
[74,53,14,6],
[74,53,14,7],
[74,53,14,8],
[74,53,14,11],
[74,53,14,12],
[74,53,16,0],
[74,53,16,1],
[74,53,16,2],
[74,53,16,3],
[74,53,16,4],
[74,53,16,5],
[74,53,16,6],
[74,53,16,7],
[74,53,16,8],
[74,53,17,0],
[74,53,17,1],
[74,53,17,2],
[74,53,17,3],
[74,53,17,4],
[74,53,17,5],
[74,53,17,6],
[74,53,17,7],
[74,53,17,8],
[74,53,19,0],
[74,53,19,1],
[74,53,19,2],
[74,53,19,3],
[74,53,19,4],
[74,53,19,5],
[74,53,21,0],
[74,53,21,1],
[74,53,21,2],
[74,54,4,3],
[74,54,5,0],
[74,54,5,1],
[74,54,5,2],
[74,54,5,4],
[74,54,6,0],
[74,54,6,1],
[74,54,6,2],
[74,54,6,4],
[74,54,7,0],
[74,54,7,1],
[74,54,7,2],
[74,54,7,3],
[74,54,7,4],
[74,54,7,5],
[74,54,7,6],
[74,54,8,0],
[74,54,8,1],
[74,54,8,2],
[74,54,8,4],
[74,54,8,7],
[74,54,11,0],
[74,54,11,1],
[74,54,11,2],
[74,54,11,3],
[74,54,11,4],
[74,54,11,5],
[74,54,11,6],
[74,54,11,7],
[74,54,11,8],
[74,54,12,0],
[74,54,12,1],
[74,54,12,2],
[74,54,12,3],
[74,54,12,4],
[74,54,12,5],
[74,54,12,6],
[74,54,12,7],
[74,54,12,8],
[74,54,13,0],
[74,54,13,1],
[74,54,13,2],
[74,54,13,3],
[74,54,13,4],
[74,54,13,5],
[74,54,13,6],
[74,54,13,7],
[74,54,13,8],
[74,54,13,11],
[74,54,13,12],
[74,54,14,0],
[74,54,14,1],
[74,54,14,2],
[74,54,14,3],
[74,54,14,4],
[74,54,14,5],
[74,54,14,6],
[74,54,14,7],
[74,54,14,8],
[74,54,14,11],
[74,54,14,12],
[74,54,16,0],
[74,54,16,1],
[74,54,16,2],
[74,54,16,3],
[74,54,16,4],
[74,54,16,5],
[74,54,16,6],
[74,54,16,7],
[74,54,16,8],
[74,54,17,0],
[74,54,17,1],
[74,54,17,2],
[74,54,17,3],
[74,54,17,4],
[74,54,17,5],
[74,54,17,6],
[74,54,17,7],
[74,54,17,8],
[74,54,19,0],
[74,54,19,1],
[74,54,19,2],
[74,54,19,3],
[74,54,19,4],
[74,54,19,5],
[74,54,21,0],
[74,54,21,1],
[74,54,21,2],
[74,55,4,3],
[74,55,5,0],
[74,55,5,1],
[74,55,5,2],
[74,55,5,4],
[74,55,6,0],
[74,55,6,1],
[74,55,6,2],
[74,55,6,4],
[74,55,7,0],
[74,55,7,1],
[74,55,7,2],
[74,55,7,3],
[74,55,7,4],
[74,55,7,5],
[74,55,7,6],
[74,55,8,0],
[74,55,8,1],
[74,55,8,2],
[74,55,8,4],
[74,55,8,7],
[74,55,11,0],
[74,55,11,1],
[74,55,11,2],
[74,55,11,3],
[74,55,11,4],
[74,55,11,5],
[74,55,11,6],
[74,55,11,7],
[74,55,11,8],
[74,55,12,0],
[74,55,12,1],
[74,55,12,2],
[74,55,12,3],
[74,55,12,4],
[74,55,12,5],
[74,55,12,6],
[74,55,12,7],
[74,55,12,8],
[74,55,13,0],
[74,55,13,1],
[74,55,13,2],
[74,55,13,3],
[74,55,13,4],
[74,55,13,5],
[74,55,13,6],
[74,55,13,7],
[74,55,13,8],
[74,55,13,11],
[74,55,13,12],
[74,55,14,0],
[74,55,14,1],
[74,55,14,2],
[74,55,14,3],
[74,55,14,4],
[74,55,14,5],
[74,55,14,6],
[74,55,14,7],
[74,55,14,8],
[74,55,14,11],
[74,55,14,12],
[74,55,16,0],
[74,55,16,1],
[74,55,16,2],
[74,55,16,3],
[74,55,16,4],
[74,55,16,5],
[74,55,16,6],
[74,55,16,7],
[74,55,16,8],
[74,55,17,0],
[74,55,17,1],
[74,55,17,2],
[74,55,17,3],
[74,55,17,4],
[74,55,17,5],
[74,55,17,6],
[74,55,17,7],
[74,55,17,8],
[74,55,19,0],
[74,55,19,1],
[74,55,19,2],
[74,55,19,3],
[74,55,19,4],
[74,55,19,5],
[74,55,21,0],
[74,55,21,1],
[74,55,21,2],
[74,57,3,0],
[74,57,3,1],
[74,57,3,2],
[74,57,4,0],
[74,57,4,1],
[74,57,4,2],
[74,57,4,3],
[74,57,5,0],
[74,57,5,1],
[74,57,5,2],
[74,57,5,4],
[74,57,6,0],
[74,57,6,1],
[74,57,6,2],
[74,57,6,4],
[74,57,7,0],
[74,57,7,1],
[74,57,7,2],
[74,57,7,3],
[74,57,7,4],
[74,57,7,5],
[74,57,7,6],
[74,57,8,0],
[74,57,8,1],
[74,57,8,2],
[74,57,8,4],
[74,57,8,7],
[74,57,10,0],
[74,57,10,1],
[74,57,10,2],
[74,57,10,3],
[74,57,10,4],
[74,57,10,5],
[74,57,10,6],
[74,57,10,7],
[74,57,10,8],
[74,57,11,0],
[74,57,11,1],
[74,57,11,2],
[74,57,11,3],
[74,57,11,4],
[74,57,11,5],
[74,57,11,6],
[74,57,11,7],
[74,57,11,8],
[74,57,11,10],
[74,57,12,0],
[74,57,12,1],
[74,57,12,2],
[74,57,12,3],
[74,57,12,4],
[74,57,12,5],
[74,57,12,6],
[74,57,12,7],
[74,57,12,8],
[74,57,12,10],
[74,57,13,0],
[74,57,13,1],
[74,57,13,2],
[74,57,13,3],
[74,57,13,4],
[74,57,13,5],
[74,57,13,6],
[74,57,13,7],
[74,57,13,8],
[74,57,13,10],
[74,57,13,11],
[74,57,13,12],
[74,57,14,0],
[74,57,14,1],
[74,57,14,2],
[74,57,14,3],
[74,57,14,4],
[74,57,14,5],
[74,57,14,6],
[74,57,14,7],
[74,57,14,8],
[74,57,14,10],
[74,57,14,11],
[74,57,16,0],
[74,57,16,1],
[74,57,16,2],
[74,57,16,3],
[74,57,16,4],
[74,57,16,5],
[74,57,16,6],
[74,57,16,7],
[74,57,17,0],
[74,57,17,1],
[74,57,17,2],
[74,57,17,3],
[74,57,17,4],
[74,57,17,5],
[74,57,17,6],
[74,57,17,7],
[74,57,19,0],
[74,57,19,1],
[74,57,19,2],
[74,57,19,3],
[74,57,19,4],
[74,57,20,0],
[74,57,20,1],
[74,57,20,2],
[74,58,3,0],
[74,58,3,1],
[74,58,3,2],
[74,58,4,0],
[74,58,4,1],
[74,58,4,2],
[74,58,4,3],
[74,58,5,0],
[74,58,5,1],
[74,58,5,2],
[74,58,5,4],
[74,58,6,0],
[74,58,6,1],
[74,58,6,2],
[74,58,6,4],
[74,58,7,0],
[74,58,7,1],
[74,58,7,2],
[74,58,7,3],
[74,58,7,4],
[74,58,7,5],
[74,58,7,6],
[74,58,8,0],
[74,58,8,1],
[74,58,8,2],
[74,58,8,4],
[74,58,8,7],
[74,58,10,0],
[74,58,10,1],
[74,58,10,2],
[74,58,10,3],
[74,58,10,4],
[74,58,10,5],
[74,58,10,6],
[74,58,10,7],
[74,58,10,8],
[74,58,11,0],
[74,58,11,1],
[74,58,11,2],
[74,58,11,3],
[74,58,11,4],
[74,58,11,5],
[74,58,11,6],
[74,58,11,7],
[74,58,11,8],
[74,58,11,10],
[74,58,12,0],
[74,58,12,1],
[74,58,12,2],
[74,58,12,3],
[74,58,12,4],
[74,58,12,5],
[74,58,12,6],
[74,58,12,7],
[74,58,12,8],
[74,58,12,10],
[74,58,13,0],
[74,58,13,1],
[74,58,13,2],
[74,58,13,3],
[74,58,13,4],
[74,58,13,5],
[74,58,13,6],
[74,58,13,7],
[74,58,13,8],
[74,58,13,10],
[74,58,13,11],
[74,58,13,12],
[74,58,14,0],
[74,58,14,1],
[74,58,14,2],
[74,58,14,3],
[74,58,14,4],
[74,58,14,5],
[74,58,14,6],
[74,58,14,7],
[74,58,14,8],
[74,58,14,10],
[74,58,14,11],
[74,58,17,0],
[74,58,17,1],
[74,58,17,2],
[74,58,17,3],
[74,58,17,4],
[74,58,17,5],
[74,58,17,6],
[74,58,17,7],
[74,58,19,0],
[74,58,19,1],
[74,58,19,2],
[74,58,19,3],
[74,58,19,4],
[74,58,20,0],
[74,58,20,1],
[74,58,20,2],
[74,60,3,0],
[74,60,3,1],
[74,60,3,2],
[74,60,4,0],
[74,60,4,1],
[74,60,4,2],
[74,60,4,3],
[74,60,5,0],
[74,60,5,1],
[74,60,5,2],
[74,60,5,4],
[74,60,6,0],
[74,60,6,1],
[74,60,6,2],
[74,60,6,4],
[74,60,7,0],
[74,60,7,1],
[74,60,7,2],
[74,60,7,3],
[74,60,7,4],
[74,60,7,5],
[74,60,7,6],
[74,60,8,0],
[74,60,8,1],
[74,60,8,2],
[74,60,8,4],
[74,60,8,7],
[74,60,10,0],
[74,60,10,1],
[74,60,10,2],
[74,60,10,3],
[74,60,10,4],
[74,60,10,5],
[74,60,10,6],
[74,60,10,7],
[74,60,10,8],
[74,60,11,0],
[74,60,11,1],
[74,60,11,2],
[74,60,11,3],
[74,60,11,4],
[74,60,11,5],
[74,60,11,6],
[74,60,11,7],
[74,60,11,8],
[74,60,11,10],
[74,60,12,0],
[74,60,12,1],
[74,60,12,2],
[74,60,12,3],
[74,60,12,4],
[74,60,12,5],
[74,60,12,6],
[74,60,12,7],
[74,60,12,8],
[74,60,16,0],
[74,60,16,1],
[74,60,16,2],
[74,60,16,3],
[74,60,16,4],
[74,60,17,0],
[74,60,17,1],
[74,60,17,2],
[74,60,17,3],
[74,60,17,4],
[74,61,3,0],
[74,61,3,1],
[74,61,3,2],
[74,61,5,0],
[74,61,5,1],
[74,61,5,2],
[74,61,6,0],
[74,61,6,1],
[74,61,6,2],
[74,61,7,0],
[74,61,7,1],
[74,61,7,2],
[74,61,7,3],
[74,61,7,5],
[74,61,7,6],
[74,61,8,0],
[74,61,8,1],
[74,61,8,2],
[74,61,8,7],
[74,61,10,0],
[74,61,10,1],
[74,61,10,2],
[74,61,10,3],
[74,61,10,5],
[74,61,10,6],
[74,61,10,7],
[74,61,10,8],
[74,61,11,0],
[74,61,11,1],
[74,61,11,2],
[74,61,11,3],
[74,61,11,5],
[74,61,11,6],
[74,61,11,7],
[74,61,11,8],
[74,61,12,0],
[74,61,12,1],
[74,61,12,2],
[74,61,12,3],
[74,61,12,5],
[74,61,12,6],
[74,61,12,7],
[74,61,13,0],
[74,61,13,1],
[74,61,13,2],
[74,61,13,3],
[74,61,13,5],
[74,61,13,6],
[74,61,13,7],
[74,61,14,0],
[74,61,14,1],
[74,61,14,2],
[74,61,14,3],
[74,61,14,5],
[74,61,16,0],
[74,61,16,1],
[74,61,16,2],
[74,61,17,0],
[74,61,17,1],
[74,61,17,2],
[74,62,3,0],
[74,62,3,1],
[74,62,3,2],
[74,62,4,0],
[74,62,4,1],
[74,62,4,2],
[74,62,4,3],
[74,62,5,0],
[74,62,5,1],
[74,62,5,2],
[74,62,5,4],
[74,62,6,0],
[74,62,6,1],
[74,62,6,2],
[74,62,6,4],
[74,62,8,0],
[74,62,8,1],
[74,62,8,2],
[74,62,8,4],
[74,62,10,0],
[74,62,10,1],
[74,62,10,2],
[74,62,10,3],
[74,62,10,4],
[74,62,10,5],
[74,62,10,6],
[74,62,10,8],
[74,62,11,0],
[74,62,11,1],
[74,62,11,2],
[74,62,11,3],
[74,62,11,4],
[74,62,11,5],
[74,62,11,6],
[74,62,11,8],
[74,62,12,0],
[74,62,12,1],
[74,62,12,2],
[74,62,12,3],
[74,62,12,4],
[74,62,12,5],
[74,62,12,6],
[74,62,13,0],
[74,62,13,1],
[74,62,13,2],
[74,62,13,3],
[74,62,13,4],
[74,62,13,5],
[74,62,13,6],
[74,62,14,0],
[74,62,14,1],
[74,62,14,2],
[74,62,14,3],
[74,62,14,4],
[74,62,14,5],
[74,62,16,0],
[74,62,16,1],
[74,62,16,2],
[74,62,17,0],
[74,62,17,1],
[74,62,17,2],
[74,64,3,0],
[74,64,3,1],
[74,64,3,2],
[74,64,5,0],
[74,64,5,1],
[74,64,5,2],
[74,64,6,0],
[74,64,6,1],
[74,64,6,2],
[74,64,7,0],
[74,64,7,1],
[74,64,7,2],
[74,64,7,3],
[74,64,7,5],
[74,64,7,6],
[74,64,8,0],
[74,64,8,1],
[74,64,8,2],
[74,64,8,7],
[74,64,10,0],
[74,64,10,1],
[74,64,10,2],
[74,64,10,3],
[74,64,10,5],
[74,64,11,0],
[74,64,11,1],
[74,64,11,2],
[74,64,11,3],
[74,64,11,5],
[74,64,12,0],
[74,64,12,1],
[74,64,12,2],
[74,64,12,3],
[74,64,13,0],
[74,64,13,1],
[74,64,13,2],
[74,64,13,3],
[74,64,14,0],
[74,64,14,1],
[74,64,14,2],
[74,65,3,0],
[74,65,3,1],
[74,65,3,2],
[74,65,4,0],
[74,65,4,1],
[74,65,4,2],
[74,65,4,3],
[74,65,5,0],
[74,65,5,1],
[74,65,5,2],
[74,65,5,4],
[74,65,6,0],
[74,65,6,1],
[74,65,6,2],
[74,65,6,4],
[74,65,7,0],
[74,65,7,1],
[74,65,7,2],
[74,65,7,3],
[74,65,7,4],
[74,65,7,5],
[74,65,7,6],
[74,65,8,0],
[74,65,8,1],
[74,65,8,2],
[74,65,8,4],
[74,65,10,0],
[74,65,10,1],
[74,65,10,2],
[74,65,10,3],
[74,65,10,4],
[74,65,11,0],
[74,65,11,1],
[74,65,11,2],
[74,65,11,3],
[74,65,11,4],
[74,65,12,0],
[74,65,12,1],
[74,65,12,2],
[74,65,13,0],
[74,65,13,1],
[74,65,13,2],
[74,66,3,0],
[74,66,3,1],
[74,66,3,2],
[74,66,4,0],
[74,66,4,1],
[74,66,4,2],
[74,66,4,3],
[74,66,5,0],
[74,66,5,1],
[74,66,5,2],
[74,66,5,4],
[74,66,6,0],
[74,66,6,1],
[74,66,6,2],
[74,66,6,4],
[74,66,8,0],
[74,66,8,1],
[74,66,8,2],
[74,66,8,4],
[74,66,10,0],
[74,66,10,1],
[74,66,10,2],
[74,66,11,0],
[74,66,11,1],
[74,66,11,2],
[74,67,3,0],
[74,67,3,1],
[74,67,3,2],
[74,67,4,0],
[74,67,4,1],
[74,67,4,2],
[74,67,4,3],
[74,67,5,0],
[74,67,5,1],
[74,67,5,2],
[74,67,5,4],
[74,67,6,0],
[74,67,6,1],
[74,67,6,2],
[74,67,6,4],
[74,67,8,0],
[74,67,8,1],
[74,67,8,2],
[74,68,3,0],
[74,68,3,1],
[74,68,3,2],
[74,68,4,0],
[74,68,4,1],
[74,68,4,2],
[74,68,4,3],
[74,68,5,0],
[74,68,5,1],
[74,68,5,2],
[74,68,5,4],
[74,68,6,0],
[74,68,6,1],
[74,68,6,2],
[74,70,3,0],
[74,70,3,1],
[74,70,3,2],
[74,70,4,0],
[74,70,4,1],
[74,70,4,2],
[75,7,4,0],
[75,7,4,1],
[75,7,4,2],
[75,9,4,0],
[75,9,4,1],
[75,9,4,2],
[75,9,7,0],
[75,9,7,1],
[75,9,7,2],
[75,9,7,4],
[75,10,4,0],
[75,10,4,1],
[75,10,4,2],
[75,10,7,0],
[75,10,7,1],
[75,10,7,2],
[75,10,7,4],
[75,10,9,0],
[75,10,9,1],
[75,10,9,2],
[75,10,9,4],
[75,10,9,7],
[75,11,4,0],
[75,11,4,1],
[75,11,4,2],
[75,11,7,0],
[75,11,7,1],
[75,11,7,2],
[75,11,7,4],
[75,11,9,0],
[75,11,9,1],
[75,11,9,2],
[75,11,9,4],
[75,11,9,7],
[75,11,10,0],
[75,11,10,1],
[75,11,10,2],
[75,11,10,4],
[75,11,10,7],
[75,11,10,9],
[75,12,4,0],
[75,12,4,1],
[75,12,4,2],
[75,12,7,0],
[75,12,7,1],
[75,12,7,2],
[75,12,7,4],
[75,12,9,0],
[75,12,9,1],
[75,12,9,2],
[75,12,9,4],
[75,12,9,7],
[75,12,10,0],
[75,12,10,1],
[75,12,10,2],
[75,12,10,4],
[75,12,10,7],
[75,12,10,9],
[75,13,4,0],
[75,13,4,1],
[75,13,4,2],
[75,13,7,0],
[75,13,7,1],
[75,13,7,2],
[75,13,7,4],
[75,13,9,0],
[75,13,9,1],
[75,13,9,2],
[75,13,9,4],
[75,13,9,7],
[75,13,10,0],
[75,13,10,1],
[75,13,10,2],
[75,13,10,4],
[75,13,10,7],
[75,13,10,9],
[75,13,11,0],
[75,13,11,1],
[75,13,11,2],
[75,13,11,4],
[75,13,11,7],
[75,13,11,9],
[75,13,11,10],
[75,13,12,0],
[75,13,12,1],
[75,13,12,2],
[75,13,12,4],
[75,13,12,7],
[75,13,12,9],
[75,13,12,10],
[75,14,4,0],
[75,14,4,1],
[75,14,4,2],
[75,14,7,0],
[75,14,7,1],
[75,14,7,2],
[75,14,7,4],
[75,14,9,0],
[75,14,9,1],
[75,14,9,2],
[75,14,9,4],
[75,14,9,7],
[75,14,10,0],
[75,14,10,1],
[75,14,10,2],
[75,14,10,4],
[75,14,10,7],
[75,14,10,9],
[75,14,11,0],
[75,14,11,1],
[75,14,11,2],
[75,14,11,4],
[75,14,11,7],
[75,14,11,9],
[75,14,11,10],
[75,14,12,0],
[75,14,12,1],
[75,14,12,2],
[75,14,12,4],
[75,14,12,7],
[75,14,12,9],
[75,14,12,10],
[75,15,4,0],
[75,15,4,1],
[75,15,4,2],
[75,15,7,0],
[75,15,7,1],
[75,15,7,2],
[75,15,7,4],
[75,15,10,0],
[75,15,10,1],
[75,15,10,2],
[75,15,10,4],
[75,15,10,7],
[75,15,11,0],
[75,15,11,1],
[75,15,11,2],
[75,15,11,4],
[75,15,11,7],
[75,15,11,10],
[75,15,12,0],
[75,15,12,1],
[75,15,12,2],
[75,15,12,4],
[75,15,12,7],
[75,15,12,10],
[75,15,13,0],
[75,15,13,1],
[75,15,13,2],
[75,15,13,4],
[75,15,13,7],
[75,15,13,10],
[75,15,13,11],
[75,15,13,12],
[75,15,14,0],
[75,15,14,1],
[75,15,14,2],
[75,15,14,4],
[75,15,14,7],
[75,15,14,10],
[75,15,14,11],
[75,15,14,12],
[75,16,4,0],
[75,16,4,1],
[75,16,4,2],
[75,16,7,0],
[75,16,7,1],
[75,16,7,2],
[75,16,7,4],
[75,16,9,0],
[75,16,9,1],
[75,16,9,2],
[75,16,9,4],
[75,16,9,7],
[75,16,10,0],
[75,16,10,1],
[75,16,10,2],
[75,16,10,4],
[75,16,10,7],
[75,16,10,9],
[75,16,11,0],
[75,16,11,1],
[75,16,11,2],
[75,16,11,4],
[75,16,11,7],
[75,16,11,9],
[75,16,11,10],
[75,16,12,0],
[75,16,12,1],
[75,16,12,2],
[75,16,12,4],
[75,16,12,7],
[75,16,12,9],
[75,16,12,10],
[75,16,13,0],
[75,16,13,1],
[75,16,13,2],
[75,16,13,4],
[75,16,13,7],
[75,16,13,9],
[75,16,13,10],
[75,16,13,11],
[75,16,13,12],
[75,16,14,0],
[75,16,14,1],
[75,16,14,2],
[75,16,14,4],
[75,16,14,7],
[75,16,14,9],
[75,16,14,10],
[75,16,14,11],
[75,16,14,12],
[75,16,15,0],
[75,16,15,1],
[75,16,15,2],
[75,16,15,4],
[75,16,15,7],
[75,16,15,10],
[75,16,15,11],
[75,16,15,12],
[75,16,15,13],
[75,16,15,14],
[75,17,4,0],
[75,17,4,1],
[75,17,4,2],
[75,17,7,0],
[75,17,7,1],
[75,17,7,2],
[75,17,7,4],
[75,17,9,0],
[75,17,9,1],
[75,17,9,2],
[75,17,9,4],
[75,17,9,7],
[75,17,10,0],
[75,17,10,1],
[75,17,10,2],
[75,17,10,4],
[75,17,10,7],
[75,17,10,9],
[75,17,11,0],
[75,17,11,1],
[75,17,11,2],
[75,17,11,4],
[75,17,11,7],
[75,17,11,9],
[75,17,11,10],
[75,17,12,0],
[75,17,12,1],
[75,17,12,2],
[75,17,12,4],
[75,17,12,7],
[75,17,12,9],
[75,17,12,10],
[75,17,13,0],
[75,17,13,1],
[75,17,13,2],
[75,17,13,4],
[75,17,13,7],
[75,17,13,9],
[75,17,13,10],
[75,17,13,11],
[75,17,13,12],
[75,17,14,0],
[75,17,14,1],
[75,17,14,2],
[75,17,14,4],
[75,17,14,7],
[75,17,14,9],
[75,17,14,10],
[75,17,14,11],
[75,17,14,12],
[75,17,15,0],
[75,17,15,1],
[75,17,15,2],
[75,17,15,4],
[75,17,15,7],
[75,17,15,10],
[75,17,15,11],
[75,17,15,12],
[75,17,15,13],
[75,17,15,14],
[75,17,16,0],
[75,17,16,1],
[75,17,16,2],
[75,17,16,4],
[75,17,16,7],
[75,17,16,9],
[75,17,16,10],
[75,17,16,11],
[75,17,16,12],
[75,17,16,13],
[75,17,16,14],
[75,17,16,15],
[75,18,4,0],
[75,18,4,1],
[75,18,4,2],
[75,18,7,0],
[75,18,7,1],
[75,18,7,2],
[75,18,7,4],
[75,18,10,0],
[75,18,10,1],
[75,18,10,2],
[75,18,10,4],
[75,18,10,7],
[75,18,11,0],
[75,18,11,1],
[75,18,11,2],
[75,18,11,4],
[75,18,11,7],
[75,18,11,10],
[75,18,12,0],
[75,18,12,1],
[75,18,12,2],
[75,18,12,4],
[75,18,12,7],
[75,18,12,10],
[75,18,13,0],
[75,18,13,1],
[75,18,13,2],
[75,18,13,4],
[75,18,13,7],
[75,18,13,10],
[75,18,13,11],
[75,18,13,12],
[75,18,14,0],
[75,18,14,1],
[75,18,14,2],
[75,18,14,4],
[75,18,14,7],
[75,18,14,10],
[75,18,14,11],
[75,18,14,12],
[75,18,16,0],
[75,18,16,1],
[75,18,16,2],
[75,18,16,4],
[75,18,16,7],
[75,18,16,10],
[75,18,16,11],
[75,18,16,12],
[75,18,16,13],
[75,18,16,14],
[75,18,17,0],
[75,18,17,1],
[75,18,17,2],
[75,18,17,4],
[75,18,17,7],
[75,18,17,10],
[75,18,17,11],
[75,18,17,12],
[75,18,17,13],
[75,18,17,14],
[75,18,17,16],
[75,19,4,0],
[75,19,4,1],
[75,19,4,2],
[75,19,7,0],
[75,19,7,1],
[75,19,7,2],
[75,19,7,4],
[75,19,9,0],
[75,19,9,1],
[75,19,9,2],
[75,19,9,4],
[75,19,9,7],
[75,19,10,0],
[75,19,10,1],
[75,19,10,2],
[75,19,10,4],
[75,19,10,7],
[75,19,10,9],
[75,19,11,0],
[75,19,11,1],
[75,19,11,2],
[75,19,11,4],
[75,19,11,7],
[75,19,11,9],
[75,19,11,10],
[75,19,12,0],
[75,19,12,1],
[75,19,12,2],
[75,19,12,4],
[75,19,12,7],
[75,19,12,9],
[75,19,12,10],
[75,19,15,0],
[75,19,15,1],
[75,19,15,2],
[75,19,15,4],
[75,19,15,7],
[75,19,15,10],
[75,19,15,11],
[75,19,15,12],
[75,19,16,0],
[75,19,16,1],
[75,19,16,2],
[75,19,16,4],
[75,19,16,7],
[75,19,16,9],
[75,19,16,10],
[75,19,16,11],
[75,19,16,12],
[75,19,16,15],
[75,19,17,0],
[75,19,17,1],
[75,19,17,2],
[75,19,17,4],
[75,19,17,7],
[75,19,17,9],
[75,19,17,10],
[75,19,17,11],
[75,19,17,12],
[75,19,17,15],
[75,19,17,16],
[75,19,18,0],
[75,19,18,1],
[75,19,18,2],
[75,19,18,4],
[75,19,18,7],
[75,19,18,10],
[75,19,18,11],
[75,19,18,12],
[75,19,18,16],
[75,19,18,17],
[75,20,4,0],
[75,20,4,1],
[75,20,4,2],
[75,20,7,0],
[75,20,7,1],
[75,20,7,2],
[75,20,7,4],
[75,20,9,0],
[75,20,9,1],
[75,20,9,2],
[75,20,9,4],
[75,20,9,7],
[75,20,11,0],
[75,20,11,1],
[75,20,11,2],
[75,20,11,4],
[75,20,11,7],
[75,20,11,9],
[75,20,12,0],
[75,20,12,1],
[75,20,12,2],
[75,20,12,4],
[75,20,12,7],
[75,20,12,9],
[75,20,13,0],
[75,20,13,1],
[75,20,13,2],
[75,20,13,4],
[75,20,13,7],
[75,20,13,9],
[75,20,13,11],
[75,20,13,12],
[75,20,14,0],
[75,20,14,1],
[75,20,14,2],
[75,20,14,4],
[75,20,14,7],
[75,20,14,9],
[75,20,14,11],
[75,20,14,12],
[75,20,15,0],
[75,20,15,1],
[75,20,15,2],
[75,20,15,4],
[75,20,15,7],
[75,20,15,11],
[75,20,15,12],
[75,20,15,13],
[75,20,15,14],
[75,20,16,0],
[75,20,16,1],
[75,20,16,2],
[75,20,16,4],
[75,20,16,7],
[75,20,16,9],
[75,20,16,11],
[75,20,16,12],
[75,20,16,13],
[75,20,16,14],
[75,20,16,15],
[75,20,17,0],
[75,20,17,1],
[75,20,17,2],
[75,20,17,4],
[75,20,17,7],
[75,20,17,9],
[75,20,17,11],
[75,20,17,12],
[75,20,17,13],
[75,20,17,14],
[75,20,17,15],
[75,20,17,16],
[75,20,18,0],
[75,20,18,1],
[75,20,18,2],
[75,20,18,4],
[75,20,18,7],
[75,20,18,11],
[75,20,18,12],
[75,20,18,13],
[75,20,18,14],
[75,20,18,16],
[75,20,18,17],
[75,20,19,0],
[75,20,19,1],
[75,20,19,2],
[75,20,19,4],
[75,20,19,7],
[75,20,19,9],
[75,20,19,11],
[75,20,19,12],
[75,20,19,15],
[75,20,19,16],
[75,20,19,17],
[75,20,19,18],
[75,22,4,0],
[75,22,4,1],
[75,22,4,2],
[75,22,7,0],
[75,22,7,1],
[75,22,7,2],
[75,22,7,4],
[75,22,9,0],
[75,22,9,1],
[75,22,9,2],
[75,22,9,4],
[75,22,9,7],
[75,22,11,0],
[75,22,11,1],
[75,22,11,2],
[75,22,11,4],
[75,22,11,7],
[75,22,11,9],
[75,22,12,0],
[75,22,12,1],
[75,22,12,2],
[75,22,12,4],
[75,22,12,7],
[75,22,12,9],
[75,22,13,0],
[75,22,13,1],
[75,22,13,2],
[75,22,13,4],
[75,22,13,7],
[75,22,13,9],
[75,22,13,11],
[75,22,13,12],
[75,22,14,0],
[75,22,14,1],
[75,22,14,2],
[75,22,14,4],
[75,22,14,7],
[75,22,14,9],
[75,22,14,11],
[75,22,14,12],
[75,22,15,0],
[75,22,15,1],
[75,22,15,2],
[75,22,15,4],
[75,22,15,7],
[75,22,15,11],
[75,22,15,12],
[75,22,15,13],
[75,22,15,14],
[75,22,16,0],
[75,22,16,1],
[75,22,16,2],
[75,22,16,4],
[75,22,16,7],
[75,22,16,9],
[75,22,16,11],
[75,22,16,12],
[75,22,16,13],
[75,22,16,14],
[75,22,16,15],
[75,22,17,0],
[75,22,17,1],
[75,22,17,2],
[75,22,17,4],
[75,22,17,7],
[75,22,17,9],
[75,22,17,11],
[75,22,17,12],
[75,22,17,13],
[75,22,17,14],
[75,22,17,15],
[75,22,17,16],
[75,22,18,0],
[75,22,18,1],
[75,22,18,2],
[75,22,18,4],
[75,22,18,7],
[75,22,18,11],
[75,22,18,12],
[75,22,18,13],
[75,22,18,14],
[75,22,18,16],
[75,22,18,17],
[75,22,19,0],
[75,22,19,1],
[75,22,19,2],
[75,22,19,4],
[75,22,19,7],
[75,22,19,9],
[75,22,19,11],
[75,22,19,12],
[75,22,19,15],
[75,22,19,16],
[75,22,19,17],
[75,22,19,18],
[75,23,4,0],
[75,23,4,1],
[75,23,4,2],
[75,23,7,0],
[75,23,7,1],
[75,23,7,2],
[75,23,7,4],
[75,23,9,0],
[75,23,9,1],
[75,23,9,2],
[75,23,9,4],
[75,23,9,7],
[75,23,10,0],
[75,23,10,1],
[75,23,10,2],
[75,23,10,4],
[75,23,10,7],
[75,23,10,9],
[75,23,11,0],
[75,23,11,1],
[75,23,11,2],
[75,23,11,4],
[75,23,11,7],
[75,23,11,9],
[75,23,11,10],
[75,23,12,0],
[75,23,12,1],
[75,23,12,2],
[75,23,12,4],
[75,23,12,7],
[75,23,12,9],
[75,23,12,10],
[75,23,13,0],
[75,23,13,1],
[75,23,13,2],
[75,23,13,4],
[75,23,13,7],
[75,23,13,9],
[75,23,13,10],
[75,23,13,11],
[75,23,13,12],
[75,23,14,0],
[75,23,14,1],
[75,23,14,2],
[75,23,14,4],
[75,23,14,7],
[75,23,14,9],
[75,23,14,10],
[75,23,14,11],
[75,23,14,12],
[75,23,15,0],
[75,23,15,1],
[75,23,15,2],
[75,23,15,4],
[75,23,15,7],
[75,23,15,10],
[75,23,15,11],
[75,23,15,12],
[75,23,15,13],
[75,23,15,14],
[75,23,16,0],
[75,23,16,1],
[75,23,16,2],
[75,23,16,4],
[75,23,16,7],
[75,23,16,9],
[75,23,16,10],
[75,23,16,11],
[75,23,16,12],
[75,23,16,13],
[75,23,16,14],
[75,23,16,15],
[75,23,17,0],
[75,23,17,1],
[75,23,17,2],
[75,23,17,4],
[75,23,17,7],
[75,23,17,9],
[75,23,17,10],
[75,23,17,11],
[75,23,17,12],
[75,23,17,13],
[75,23,17,14],
[75,23,17,15],
[75,23,17,16],
[75,23,18,0],
[75,23,18,1],
[75,23,18,2],
[75,23,18,4],
[75,23,18,7],
[75,23,18,10],
[75,23,18,11],
[75,23,18,12],
[75,23,18,13],
[75,23,18,14],
[75,23,18,16],
[75,23,18,17],
[75,23,19,0],
[75,23,19,1],
[75,23,19,2],
[75,23,19,4],
[75,23,19,7],
[75,23,19,9],
[75,23,19,10],
[75,23,19,11],
[75,23,19,12],
[75,23,19,15],
[75,23,19,16],
[75,23,19,17],
[75,23,19,18],
[75,23,20,0],
[75,23,20,1],
[75,23,20,2],
[75,23,20,4],
[75,23,20,7],
[75,23,20,9],
[75,23,20,11],
[75,23,20,12],
[75,23,20,13],
[75,23,20,14],
[75,23,20,15],
[75,23,20,16],
[75,23,20,17],
[75,23,20,18],
[75,23,20,19],
[75,23,22,0],
[75,23,22,1],
[75,23,22,2],
[75,23,22,4],
[75,23,22,7],
[75,23,22,9],
[75,23,22,11],
[75,23,22,12],
[75,23,22,13],
[75,23,22,14],
[75,23,22,15],
[75,23,22,16],
[75,23,22,17],
[75,23,22,19],
[75,24,4,0],
[75,24,4,1],
[75,24,4,2],
[75,24,7,0],
[75,24,7,1],
[75,24,7,2],
[75,24,7,4],
[75,24,9,0],
[75,24,9,1],
[75,24,9,2],
[75,24,9,4],
[75,24,9,7],
[75,24,10,0],
[75,24,10,1],
[75,24,10,2],
[75,24,10,4],
[75,24,10,7],
[75,24,10,9],
[75,24,11,0],
[75,24,11,1],
[75,24,11,2],
[75,24,11,4],
[75,24,11,7],
[75,24,11,9],
[75,24,11,10],
[75,24,12,0],
[75,24,12,1],
[75,24,12,2],
[75,24,12,4],
[75,24,12,7],
[75,24,12,9],
[75,24,12,10],
[75,24,13,0],
[75,24,13,1],
[75,24,13,2],
[75,24,13,4],
[75,24,13,7],
[75,24,13,9],
[75,24,13,10],
[75,24,13,11],
[75,24,13,12],
[75,24,14,0],
[75,24,14,1],
[75,24,14,2],
[75,24,14,4],
[75,24,14,7],
[75,24,14,9],
[75,24,14,10],
[75,24,14,11],
[75,24,14,12],
[75,24,15,0],
[75,24,15,1],
[75,24,15,2],
[75,24,15,4],
[75,24,15,7],
[75,24,15,10],
[75,24,15,11],
[75,24,15,12],
[75,24,15,13],
[75,24,15,14],
[75,24,17,0],
[75,24,17,1],
[75,24,17,2],
[75,24,17,4],
[75,24,17,7],
[75,24,17,9],
[75,24,17,10],
[75,24,17,11],
[75,24,17,12],
[75,24,17,13],
[75,24,17,14],
[75,24,17,15],
[75,24,18,0],
[75,24,18,1],
[75,24,18,2],
[75,24,18,4],
[75,24,18,7],
[75,24,18,10],
[75,24,18,11],
[75,24,18,12],
[75,24,18,13],
[75,24,18,14],
[75,24,18,17],
[75,24,19,0],
[75,24,19,1],
[75,24,19,2],
[75,24,19,4],
[75,24,19,7],
[75,24,19,9],
[75,24,19,10],
[75,24,19,11],
[75,24,19,12],
[75,24,19,15],
[75,24,19,17],
[75,24,19,18],
[75,24,20,0],
[75,24,20,1],
[75,24,20,2],
[75,24,20,4],
[75,24,20,7],
[75,24,20,9],
[75,24,20,11],
[75,24,20,12],
[75,24,20,13],
[75,24,20,14],
[75,24,20,15],
[75,24,20,17],
[75,24,20,18],
[75,24,20,19],
[75,24,22,0],
[75,24,22,1],
[75,24,22,2],
[75,24,22,4],
[75,24,22,7],
[75,24,22,9],
[75,24,22,11],
[75,24,22,12],
[75,24,22,13],
[75,24,22,14],
[75,24,22,15],
[75,24,22,17],
[75,24,22,19],
[75,24,23,0],
[75,24,23,1],
[75,24,23,2],
[75,24,23,4],
[75,24,23,7],
[75,24,23,9],
[75,24,23,10],
[75,24,23,11],
[75,24,23,12],
[75,24,23,13],
[75,24,23,14],
[75,24,23,15],
[75,24,23,18],
[75,24,23,19],
[75,24,23,20],
[75,24,23,22],
[75,25,4,0],
[75,25,4,1],
[75,25,4,2],
[75,25,9,0],
[75,25,9,1],
[75,25,9,2],
[75,25,9,4],
[75,25,10,0],
[75,25,10,1],
[75,25,10,2],
[75,25,10,4],
[75,25,10,9],
[75,25,11,0],
[75,25,11,1],
[75,25,11,2],
[75,25,11,4],
[75,25,11,9],
[75,25,11,10],
[75,25,12,0],
[75,25,12,1],
[75,25,12,2],
[75,25,12,4],
[75,25,12,9],
[75,25,12,10],
[75,25,13,0],
[75,25,13,1],
[75,25,13,2],
[75,25,13,4],
[75,25,13,9],
[75,25,13,10],
[75,25,13,11],
[75,25,13,12],
[75,25,14,0],
[75,25,14,1],
[75,25,14,2],
[75,25,14,4],
[75,25,14,9],
[75,25,14,10],
[75,25,14,11],
[75,25,14,12],
[75,25,15,0],
[75,25,15,1],
[75,25,15,2],
[75,25,15,4],
[75,25,15,10],
[75,25,15,11],
[75,25,15,12],
[75,25,15,13],
[75,25,15,14],
[75,25,16,0],
[75,25,16,1],
[75,25,16,2],
[75,25,16,4],
[75,25,16,9],
[75,25,16,10],
[75,25,16,11],
[75,25,16,12],
[75,25,16,13],
[75,25,16,14],
[75,25,16,15],
[75,25,17,0],
[75,25,17,1],
[75,25,17,2],
[75,25,17,4],
[75,25,17,9],
[75,25,17,10],
[75,25,17,11],
[75,25,17,12],
[75,25,17,13],
[75,25,17,14],
[75,25,17,15],
[75,25,17,16],
[75,25,18,0],
[75,25,18,1],
[75,25,18,2],
[75,25,18,4],
[75,25,18,10],
[75,25,18,11],
[75,25,18,12],
[75,25,18,13],
[75,25,18,14],
[75,25,18,16],
[75,25,18,17],
[75,25,19,0],
[75,25,19,1],
[75,25,19,2],
[75,25,19,4],
[75,25,19,9],
[75,25,19,10],
[75,25,19,11],
[75,25,19,12],
[75,25,19,15],
[75,25,19,16],
[75,25,19,17],
[75,25,19,18],
[75,25,20,0],
[75,25,20,1],
[75,25,20,2],
[75,25,20,4],
[75,25,20,9],
[75,25,20,11],
[75,25,20,12],
[75,25,20,13],
[75,25,20,14],
[75,25,20,15],
[75,25,20,16],
[75,25,20,17],
[75,25,20,19],
[75,25,22,0],
[75,25,22,1],
[75,25,22,2],
[75,25,22,4],
[75,25,22,9],
[75,25,22,11],
[75,25,22,12],
[75,25,22,13],
[75,25,22,14],
[75,25,22,16],
[75,25,22,17],
[75,25,22,18],
[75,25,22,19],
[75,25,23,0],
[75,25,23,1],
[75,25,23,2],
[75,25,23,4],
[75,25,23,9],
[75,25,23,10],
[75,25,23,11],
[75,25,23,12],
[75,25,23,13],
[75,25,23,15],
[75,25,23,16],
[75,25,23,17],
[75,25,23,18],
[75,25,23,19],
[75,25,23,20],
[75,25,23,22],
[75,25,24,0],
[75,25,24,1],
[75,25,24,2],
[75,25,24,4],
[75,25,24,9],
[75,25,24,10],
[75,25,24,11],
[75,25,24,12],
[75,25,24,13],
[75,25,24,15],
[75,25,24,17],
[75,25,24,18],
[75,25,24,19],
[75,25,24,20],
[75,25,24,22],
[75,25,24,23],
[75,26,4,0],
[75,26,4,1],
[75,26,4,2],
[75,26,7,0],
[75,26,7,1],
[75,26,7,2],
[75,26,7,4],
[75,26,10,0],
[75,26,10,1],
[75,26,10,2],
[75,26,10,4],
[75,26,10,7],
[75,26,11,0],
[75,26,11,1],
[75,26,11,2],
[75,26,11,4],
[75,26,11,7],
[75,26,11,10],
[75,26,12,0],
[75,26,12,1],
[75,26,12,2],
[75,26,12,4],
[75,26,12,7],
[75,26,12,10],
[75,26,13,0],
[75,26,13,1],
[75,26,13,2],
[75,26,13,4],
[75,26,13,7],
[75,26,13,10],
[75,26,13,11],
[75,26,13,12],
[75,26,14,0],
[75,26,14,1],
[75,26,14,2],
[75,26,14,4],
[75,26,14,7],
[75,26,14,10],
[75,26,14,11],
[75,26,14,12],
[75,26,16,0],
[75,26,16,1],
[75,26,16,2],
[75,26,16,4],
[75,26,16,7],
[75,26,16,10],
[75,26,16,11],
[75,26,16,12],
[75,26,16,13],
[75,26,16,14],
[75,26,17,0],
[75,26,17,1],
[75,26,17,2],
[75,26,17,4],
[75,26,17,7],
[75,26,17,10],
[75,26,17,11],
[75,26,17,12],
[75,26,17,13],
[75,26,17,14],
[75,26,17,16],
[75,26,19,0],
[75,26,19,1],
[75,26,19,2],
[75,26,19,4],
[75,26,19,7],
[75,26,19,10],
[75,26,19,11],
[75,26,19,12],
[75,26,19,16],
[75,26,19,17],
[75,26,20,0],
[75,26,20,1],
[75,26,20,2],
[75,26,20,4],
[75,26,20,7],
[75,26,20,11],
[75,26,20,12],
[75,26,20,13],
[75,26,20,14],
[75,26,20,19],
[75,26,22,0],
[75,26,22,1],
[75,26,22,2],
[75,26,22,4],
[75,26,22,7],
[75,26,22,11],
[75,26,22,12],
[75,26,22,13],
[75,26,22,16],
[75,26,22,17],
[75,26,22,19],
[75,26,23,0],
[75,26,23,1],
[75,26,23,2],
[75,26,23,4],
[75,26,23,7],
[75,26,23,10],
[75,26,23,11],
[75,26,23,14],
[75,26,23,16],
[75,26,23,17],
[75,26,23,19],
[75,26,23,20],
[75,26,23,22],
[75,26,24,0],
[75,26,24,1],
[75,26,24,2],
[75,26,24,4],
[75,26,24,7],
[75,26,24,10],
[75,26,24,11],
[75,26,24,14],
[75,26,24,17],
[75,26,24,19],
[75,26,24,20],
[75,26,24,22],
[75,26,24,23],
[75,26,25,0],
[75,26,25,1],
[75,26,25,2],
[75,26,25,4],
[75,26,25,10],
[75,26,25,11],
[75,26,25,12],
[75,26,25,13],
[75,26,25,14],
[75,26,25,16],
[75,26,25,17],
[75,26,25,19],
[75,26,25,20],
[75,26,25,22],
[75,26,25,23],
[75,26,25,24],
[75,27,4,0],
[75,27,4,1],
[75,27,4,2],
[75,27,7,0],
[75,27,7,1],
[75,27,7,2],
[75,27,7,4],
[75,27,9,0],
[75,27,9,1],
[75,27,9,2],
[75,27,9,4],
[75,27,9,7],
[75,27,11,0],
[75,27,11,1],
[75,27,11,2],
[75,27,11,4],
[75,27,11,7],
[75,27,11,9],
[75,27,12,0],
[75,27,12,1],
[75,27,12,2],
[75,27,12,4],
[75,27,12,7],
[75,27,12,9],
[75,27,13,0],
[75,27,13,1],
[75,27,13,2],
[75,27,13,4],
[75,27,13,7],
[75,27,13,9],
[75,27,13,11],
[75,27,13,12],
[75,27,14,0],
[75,27,14,1],
[75,27,14,2],
[75,27,14,4],
[75,27,14,7],
[75,27,14,9],
[75,27,14,11],
[75,27,14,12],
[75,27,15,0],
[75,27,15,1],
[75,27,15,2],
[75,27,15,4],
[75,27,15,7],
[75,27,15,11],
[75,27,15,12],
[75,27,15,13],
[75,27,15,14],
[75,27,16,0],
[75,27,16,1],
[75,27,16,2],
[75,27,16,4],
[75,27,16,7],
[75,27,16,9],
[75,27,16,11],
[75,27,16,12],
[75,27,16,13],
[75,27,16,14],
[75,27,16,15],
[75,27,17,0],
[75,27,17,1],
[75,27,17,2],
[75,27,17,4],
[75,27,17,7],
[75,27,17,9],
[75,27,17,11],
[75,27,17,12],
[75,27,17,13],
[75,27,17,14],
[75,27,17,15],
[75,27,17,16],
[75,27,18,0],
[75,27,18,1],
[75,27,18,2],
[75,27,18,4],
[75,27,18,7],
[75,27,18,11],
[75,27,18,12],
[75,27,18,13],
[75,27,18,14],
[75,27,18,16],
[75,27,18,17],
[75,27,19,0],
[75,27,19,1],
[75,27,19,2],
[75,27,19,4],
[75,27,19,7],
[75,27,19,9],
[75,27,19,11],
[75,27,19,12],
[75,27,19,15],
[75,27,19,18],
[75,27,23,0],
[75,27,23,1],
[75,27,23,2],
[75,27,23,4],
[75,27,23,7],
[75,27,23,9],
[75,27,23,12],
[75,27,23,13],
[75,27,23,14],
[75,27,23,15],
[75,27,23,16],
[75,27,23,17],
[75,27,23,18],
[75,27,23,19],
[75,27,24,0],
[75,27,24,1],
[75,27,24,2],
[75,27,24,4],
[75,27,24,7],
[75,27,24,9],
[75,27,24,12],
[75,27,24,13],
[75,27,24,14],
[75,27,24,15],
[75,27,24,17],
[75,27,24,18],
[75,27,24,19],
[75,27,24,23],
[75,27,25,0],
[75,27,25,1],
[75,27,25,2],
[75,27,25,4],
[75,27,25,9],
[75,27,25,11],
[75,27,25,12],
[75,27,25,13],
[75,27,25,14],
[75,27,25,15],
[75,27,25,16],
[75,27,25,17],
[75,27,25,18],
[75,27,25,19],
[75,27,25,23],
[75,27,25,24],
[75,27,26,0],
[75,27,26,1],
[75,27,26,2],
[75,27,26,4],
[75,27,26,7],
[75,27,26,11],
[75,27,26,12],
[75,27,26,13],
[75,27,26,14],
[75,27,26,16],
[75,27,26,17],
[75,27,26,19],
[75,28,4,0],
[75,28,4,1],
[75,28,4,2],
[75,28,9,0],
[75,28,9,1],
[75,28,9,2],
[75,28,9,4],
[75,28,10,0],
[75,28,10,1],
[75,28,10,2],
[75,28,10,4],
[75,28,10,9],
[75,28,11,0],
[75,28,11,1],
[75,28,11,2],
[75,28,11,4],
[75,28,11,9],
[75,28,11,10],
[75,28,12,0],
[75,28,12,1],
[75,28,12,2],
[75,28,12,4],
[75,28,12,9],
[75,28,12,10],
[75,28,13,0],
[75,28,13,1],
[75,28,13,2],
[75,28,13,4],
[75,28,13,9],
[75,28,13,10],
[75,28,13,11],
[75,28,13,12],
[75,28,14,0],
[75,28,14,1],
[75,28,14,2],
[75,28,14,4],
[75,28,14,9],
[75,28,14,10],
[75,28,14,11],
[75,28,14,12],
[75,28,15,0],
[75,28,15,1],
[75,28,15,2],
[75,28,15,4],
[75,28,15,10],
[75,28,15,11],
[75,28,15,12],
[75,28,15,13],
[75,28,15,14],
[75,28,16,0],
[75,28,16,1],
[75,28,16,2],
[75,28,16,4],
[75,28,16,9],
[75,28,16,10],
[75,28,16,11],
[75,28,16,12],
[75,28,16,13],
[75,28,16,14],
[75,28,16,15],
[75,28,17,0],
[75,28,17,1],
[75,28,17,2],
[75,28,17,4],
[75,28,17,9],
[75,28,17,10],
[75,28,17,11],
[75,28,17,12],
[75,28,17,13],
[75,28,17,14],
[75,28,17,15],
[75,28,17,16],
[75,28,18,0],
[75,28,18,1],
[75,28,18,2],
[75,28,18,4],
[75,28,18,10],
[75,28,18,11],
[75,28,18,12],
[75,28,18,13],
[75,28,18,14],
[75,28,19,0],
[75,28,19,1],
[75,28,19,2],
[75,28,19,4],
[75,28,19,9],
[75,28,19,10],
[75,28,19,11],
[75,28,19,12],
[75,28,19,16],
[75,28,19,17],
[75,28,19,18],
[75,28,20,0],
[75,28,20,1],
[75,28,20,2],
[75,28,20,4],
[75,28,20,9],
[75,28,20,11],
[75,28,20,12],
[75,28,20,13],
[75,28,20,15],
[75,28,20,16],
[75,28,20,17],
[75,28,20,18],
[75,28,20,19],
[75,28,22,0],
[75,28,22,1],
[75,28,22,2],
[75,28,22,4],
[75,28,22,9],
[75,28,22,12],
[75,28,22,13],
[75,28,22,14],
[75,28,22,15],
[75,28,22,16],
[75,28,22,17],
[75,28,22,18],
[75,28,22,19],
[75,28,23,0],
[75,28,23,1],
[75,28,23,2],
[75,28,23,4],
[75,28,23,10],
[75,28,23,11],
[75,28,23,12],
[75,28,23,13],
[75,28,23,14],
[75,28,23,15],
[75,28,23,16],
[75,28,23,17],
[75,28,23,18],
[75,28,23,19],
[75,28,23,20],
[75,28,23,22],
[75,28,24,0],
[75,28,24,1],
[75,28,24,2],
[75,28,24,4],
[75,28,24,10],
[75,28,24,11],
[75,28,24,12],
[75,28,24,13],
[75,28,24,14],
[75,28,24,15],
[75,28,24,17],
[75,28,24,18],
[75,28,24,19],
[75,28,24,20],
[75,28,24,22],
[75,28,24,23],
[75,28,26,0],
[75,28,26,1],
[75,28,26,2],
[75,28,26,10],
[75,28,26,11],
[75,28,26,12],
[75,28,26,13],
[75,28,26,14],
[75,28,26,16],
[75,28,26,17],
[75,28,26,19],
[75,28,26,20],
[75,28,27,4],
[75,28,27,9],
[75,28,27,11],
[75,28,27,12],
[75,28,27,13],
[75,28,27,14],
[75,28,27,15],
[75,28,27,16],
[75,28,27,17],
[75,28,27,18],
[75,28,27,19],
[75,29,4,0],
[75,29,4,1],
[75,29,4,2],
[75,29,7,0],
[75,29,7,1],
[75,29,7,2],
[75,29,7,4],
[75,29,9,0],
[75,29,9,1],
[75,29,9,2],
[75,29,9,4],
[75,29,9,7],
[75,29,10,0],
[75,29,10,1],
[75,29,10,2],
[75,29,10,4],
[75,29,10,7],
[75,29,10,9],
[75,29,13,0],
[75,29,13,1],
[75,29,13,2],
[75,29,13,4],
[75,29,13,7],
[75,29,13,9],
[75,29,13,10],
[75,29,14,0],
[75,29,14,1],
[75,29,14,2],
[75,29,14,4],
[75,29,14,7],
[75,29,14,9],
[75,29,14,10],
[75,29,15,0],
[75,29,15,1],
[75,29,15,2],
[75,29,15,4],
[75,29,15,7],
[75,29,15,10],
[75,29,15,13],
[75,29,15,14],
[75,29,16,0],
[75,29,16,1],
[75,29,16,2],
[75,29,16,4],
[75,29,16,7],
[75,29,16,9],
[75,29,16,10],
[75,29,16,13],
[75,29,16,14],
[75,29,16,15],
[75,29,17,0],
[75,29,17,1],
[75,29,17,2],
[75,29,17,4],
[75,29,17,7],
[75,29,17,9],
[75,29,17,10],
[75,29,17,13],
[75,29,17,14],
[75,29,17,15],
[75,29,18,0],
[75,29,18,1],
[75,29,18,2],
[75,29,18,4],
[75,29,18,7],
[75,29,18,10],
[75,29,18,13],
[75,29,18,14],
[75,29,18,16],
[75,29,18,17],
[75,29,19,0],
[75,29,19,1],
[75,29,19,2],
[75,29,19,4],
[75,29,19,7],
[75,29,19,9],
[75,29,19,10],
[75,29,19,15],
[75,29,19,16],
[75,29,19,17],
[75,29,19,18],
[75,29,20,0],
[75,29,20,1],
[75,29,20,2],
[75,29,20,4],
[75,29,20,7],
[75,29,20,9],
[75,29,20,14],
[75,29,20,15],
[75,29,20,16],
[75,29,20,17],
[75,29,20,18],
[75,29,20,19],
[75,29,22,0],
[75,29,22,1],
[75,29,22,2],
[75,29,22,4],
[75,29,22,7],
[75,29,22,13],
[75,29,22,14],
[75,29,22,15],
[75,29,22,16],
[75,29,22,17],
[75,29,22,18],
[75,29,22,19],
[75,29,23,0],
[75,29,23,1],
[75,29,23,2],
[75,29,23,4],
[75,29,23,9],
[75,29,23,10],
[75,29,23,13],
[75,29,23,14],
[75,29,23,15],
[75,29,23,16],
[75,29,23,17],
[75,29,23,18],
[75,29,23,19],
[75,29,23,20],
[75,29,23,22],
[75,29,24,0],
[75,29,24,1],
[75,29,24,2],
[75,29,24,4],
[75,29,24,9],
[75,29,24,10],
[75,29,24,13],
[75,29,24,14],
[75,29,24,15],
[75,29,24,17],
[75,29,24,18],
[75,29,24,19],
[75,29,24,20],
[75,29,24,22],
[75,29,24,23],
[75,29,25,0],
[75,29,25,1],
[75,29,25,2],
[75,29,25,9],
[75,29,25,10],
[75,29,25,13],
[75,29,25,14],
[75,29,25,15],
[75,29,25,16],
[75,29,25,17],
[75,29,25,18],
[75,29,25,19],
[75,29,25,20],
[75,29,26,4],
[75,29,26,7],
[75,29,26,10],
[75,29,26,13],
[75,29,26,14],
[75,29,26,16],
[75,29,26,17],
[75,29,26,19],
[75,29,26,20],
[75,29,27,0],
[75,29,27,1],
[75,29,27,2],
[75,29,27,4],
[75,29,27,7],
[75,29,27,9],
[75,29,27,13],
[75,29,27,14],
[75,29,27,15],
[75,29,27,16],
[75,29,27,17],
[75,29,27,18],
[75,29,27,19],
[75,29,28,0],
[75,29,28,1],
[75,29,28,2],
[75,29,28,4],
[75,29,28,9],
[75,29,28,10],
[75,29,28,13],
[75,29,28,14],
[75,29,28,15],
[75,29,28,16],
[75,29,28,17],
[75,29,28,18],
[75,30,4,0],
[75,30,4,1],
[75,30,4,2],
[75,30,7,0],
[75,30,7,1],
[75,30,7,2],
[75,30,7,4],
[75,30,9,0],
[75,30,9,1],
[75,30,9,2],
[75,30,9,4],
[75,30,9,7],
[75,30,10,0],
[75,30,10,1],
[75,30,10,2],
[75,30,10,4],
[75,30,10,7],
[75,30,10,9],
[75,30,11,0],
[75,30,11,1],
[75,30,11,2],
[75,30,11,4],
[75,30,11,7],
[75,30,11,9],
[75,30,11,10],
[75,30,12,0],
[75,30,12,1],
[75,30,12,2],
[75,30,12,4],
[75,30,12,7],
[75,30,12,9],
[75,30,12,10],
[75,30,13,0],
[75,30,13,1],
[75,30,13,2],
[75,30,13,4],
[75,30,13,7],
[75,30,13,9],
[75,30,13,10],
[75,30,13,11],
[75,30,13,12],
[75,30,14,0],
[75,30,14,1],
[75,30,14,2],
[75,30,14,4],
[75,30,14,7],
[75,30,14,9],
[75,30,14,10],
[75,30,14,11],
[75,30,14,12],
[75,30,15,0],
[75,30,15,1],
[75,30,15,2],
[75,30,15,4],
[75,30,15,7],
[75,30,15,10],
[75,30,15,11],
[75,30,15,12],
[75,30,15,13],
[75,30,15,14],
[75,30,16,0],
[75,30,16,1],
[75,30,16,2],
[75,30,16,4],
[75,30,16,7],
[75,30,16,9],
[75,30,16,10],
[75,30,16,11],
[75,30,16,12],
[75,30,16,13],
[75,30,16,14],
[75,30,17,0],
[75,30,17,1],
[75,30,17,2],
[75,30,17,4],
[75,30,17,7],
[75,30,17,9],
[75,30,17,10],
[75,30,17,11],
[75,30,17,12],
[75,30,17,13],
[75,30,17,14],
[75,30,17,16],
[75,30,18,0],
[75,30,18,1],
[75,30,18,2],
[75,30,18,4],
[75,30,18,7],
[75,30,18,10],
[75,30,18,11],
[75,30,18,12],
[75,30,18,13],
[75,30,18,16],
[75,30,18,17],
[75,30,19,0],
[75,30,19,1],
[75,30,19,2],
[75,30,19,4],
[75,30,19,7],
[75,30,19,9],
[75,30,19,10],
[75,30,19,11],
[75,30,19,15],
[75,30,19,16],
[75,30,19,17],
[75,30,19,18],
[75,30,20,0],
[75,30,20,1],
[75,30,20,2],
[75,30,20,4],
[75,30,20,7],
[75,30,20,9],
[75,30,20,12],
[75,30,20,13],
[75,30,20,14],
[75,30,20,15],
[75,30,20,16],
[75,30,20,17],
[75,30,20,18],
[75,30,20,19],
[75,30,22,0],
[75,30,22,1],
[75,30,22,2],
[75,30,22,4],
[75,30,22,9],
[75,30,22,11],
[75,30,22,12],
[75,30,22,13],
[75,30,22,14],
[75,30,22,15],
[75,30,22,16],
[75,30,22,17],
[75,30,22,18],
[75,30,22,19],
[75,30,24,0],
[75,30,24,1],
[75,30,24,2],
[75,30,24,4],
[75,30,24,7],
[75,30,24,9],
[75,30,24,10],
[75,30,24,11],
[75,30,24,12],
[75,30,24,13],
[75,30,24,14],
[75,30,24,15],
[75,30,24,17],
[75,30,24,18],
[75,30,24,19],
[75,30,24,20],
[75,30,24,22],
[75,30,25,4],
[75,30,25,9],
[75,30,25,10],
[75,30,25,11],
[75,30,25,12],
[75,30,25,13],
[75,30,25,14],
[75,30,25,15],
[75,30,25,16],
[75,30,25,17],
[75,30,25,18],
[75,30,25,19],
[75,30,25,20],
[75,30,26,0],
[75,30,26,1],
[75,30,26,2],
[75,30,26,4],
[75,30,26,7],
[75,30,26,10],
[75,30,26,11],
[75,30,26,12],
[75,30,26,13],
[75,30,26,14],
[75,30,26,16],
[75,30,26,17],
[75,30,26,19],
[75,30,27,0],
[75,30,27,1],
[75,30,27,2],
[75,30,27,4],
[75,30,27,7],
[75,30,27,9],
[75,30,27,11],
[75,30,27,12],
[75,30,27,13],
[75,30,27,14],
[75,30,27,15],
[75,30,27,16],
[75,30,27,17],
[75,30,27,18],
[75,30,28,0],
[75,30,28,1],
[75,30,28,2],
[75,30,28,4],
[75,30,28,9],
[75,30,28,10],
[75,30,28,11],
[75,30,28,12],
[75,30,28,13],
[75,30,28,14],
[75,30,28,15],
[75,30,28,16],
[75,30,28,17],
[75,30,29,0],
[75,30,29,1],
[75,30,29,2],
[75,30,29,4],
[75,30,29,7],
[75,30,29,9],
[75,30,29,10],
[75,30,29,13],
[75,30,29,14],
[75,30,29,15],
[75,31,4,0],
[75,31,4,1],
[75,31,4,2],
[75,31,7,0],
[75,31,7,1],
[75,31,7,2],
[75,31,7,4],
[75,31,9,0],
[75,31,9,1],
[75,31,9,2],
[75,31,9,4],
[75,31,9,7],
[75,31,10,0],
[75,31,10,1],
[75,31,10,2],
[75,31,10,4],
[75,31,10,7],
[75,31,10,9],
[75,31,11,0],
[75,31,11,1],
[75,31,11,2],
[75,31,11,4],
[75,31,11,7],
[75,31,11,9],
[75,31,11,10],
[75,31,12,0],
[75,31,12,1],
[75,31,12,2],
[75,31,12,4],
[75,31,12,7],
[75,31,12,9],
[75,31,12,10],
[75,31,13,0],
[75,31,13,1],
[75,31,13,2],
[75,31,13,4],
[75,31,13,7],
[75,31,13,9],
[75,31,13,10],
[75,31,13,11],
[75,31,13,12],
[75,31,14,0],
[75,31,14,1],
[75,31,14,2],
[75,31,14,4],
[75,31,14,7],
[75,31,14,9],
[75,31,14,10],
[75,31,14,11],
[75,31,14,12],
[75,31,15,0],
[75,31,15,1],
[75,31,15,2],
[75,31,15,4],
[75,31,15,7],
[75,31,15,10],
[75,31,15,11],
[75,31,15,12],
[75,31,15,13],
[75,31,15,14],
[75,31,16,0],
[75,31,16,1],
[75,31,16,2],
[75,31,16,4],
[75,31,16,7],
[75,31,16,9],
[75,31,16,10],
[75,31,16,11],
[75,31,16,12],
[75,31,16,13],
[75,31,16,15],
[75,31,17,0],
[75,31,17,1],
[75,31,17,2],
[75,31,17,4],
[75,31,17,7],
[75,31,17,9],
[75,31,17,10],
[75,31,17,11],
[75,31,17,12],
[75,31,17,13],
[75,31,17,15],
[75,31,17,16],
[75,31,18,0],
[75,31,18,1],
[75,31,18,2],
[75,31,18,4],
[75,31,18,7],
[75,31,18,10],
[75,31,18,11],
[75,31,18,14],
[75,31,18,16],
[75,31,18,17],
[75,31,19,0],
[75,31,19,1],
[75,31,19,2],
[75,31,19,4],
[75,31,19,7],
[75,31,19,9],
[75,31,19,12],
[75,31,19,15],
[75,31,19,16],
[75,31,19,17],
[75,31,19,18],
[75,31,20,0],
[75,31,20,1],
[75,31,20,2],
[75,31,20,4],
[75,31,20,7],
[75,31,20,11],
[75,31,20,12],
[75,31,20,13],
[75,31,20,14],
[75,31,20,15],
[75,31,20,16],
[75,31,20,17],
[75,31,20,18],
[75,31,20,19],
[75,31,22,0],
[75,31,22,1],
[75,31,22,2],
[75,31,22,4],
[75,31,22,7],
[75,31,22,9],
[75,31,22,11],
[75,31,22,12],
[75,31,22,13],
[75,31,22,14],
[75,31,22,15],
[75,31,22,16],
[75,31,22,17],
[75,31,22,18],
[75,31,22,19],
[75,31,24,0],
[75,31,24,1],
[75,31,24,2],
[75,31,24,7],
[75,31,24,9],
[75,31,24,10],
[75,31,24,11],
[75,31,24,12],
[75,31,24,13],
[75,31,24,14],
[75,31,24,15],
[75,31,24,17],
[75,31,24,18],
[75,31,24,19],
[75,31,24,20],
[75,31,25,0],
[75,31,25,1],
[75,31,25,2],
[75,31,25,4],
[75,31,25,9],
[75,31,25,10],
[75,31,25,11],
[75,31,25,12],
[75,31,25,13],
[75,31,25,14],
[75,31,25,15],
[75,31,25,16],
[75,31,25,17],
[75,31,25,18],
[75,31,25,19],
[75,31,26,0],
[75,31,26,1],
[75,31,26,2],
[75,31,26,4],
[75,31,26,7],
[75,31,26,10],
[75,31,26,11],
[75,31,26,12],
[75,31,26,13],
[75,31,26,14],
[75,31,26,16],
[75,31,26,17],
[75,31,27,0],
[75,31,27,1],
[75,31,27,2],
[75,31,27,4],
[75,31,27,7],
[75,31,27,9],
[75,31,27,11],
[75,31,27,12],
[75,31,27,13],
[75,31,27,14],
[75,31,27,15],
[75,31,27,16],
[75,31,27,17],
[75,31,28,0],
[75,31,28,1],
[75,31,28,2],
[75,31,28,4],
[75,31,28,9],
[75,31,28,10],
[75,31,28,11],
[75,31,28,12],
[75,31,28,13],
[75,31,28,14],
[75,31,28,15],
[75,31,29,0],
[75,31,29,1],
[75,31,29,2],
[75,31,29,4],
[75,31,29,7],
[75,31,29,9],
[75,31,29,10],
[75,31,29,13],
[75,31,29,14],
[75,32,4,0],
[75,32,4,1],
[75,32,4,2],
[75,32,7,0],
[75,32,7,1],
[75,32,7,2],
[75,32,7,4],
[75,32,9,0],
[75,32,9,1],
[75,32,9,2],
[75,32,9,4],
[75,32,9,7],
[75,32,10,0],
[75,32,10,1],
[75,32,10,2],
[75,32,10,4],
[75,32,10,7],
[75,32,10,9],
[75,32,13,0],
[75,32,13,1],
[75,32,13,2],
[75,32,13,4],
[75,32,13,7],
[75,32,13,9],
[75,32,13,10],
[75,32,14,0],
[75,32,14,1],
[75,32,14,2],
[75,32,14,4],
[75,32,14,7],
[75,32,14,9],
[75,32,14,10],
[75,32,15,0],
[75,32,15,1],
[75,32,15,2],
[75,32,15,4],
[75,32,15,7],
[75,32,15,10],
[75,32,15,13],
[75,32,16,0],
[75,32,16,1],
[75,32,16,2],
[75,32,16,4],
[75,32,16,7],
[75,32,16,9],
[75,32,16,10],
[75,32,16,14],
[75,32,16,15],
[75,32,17,0],
[75,32,17,1],
[75,32,17,2],
[75,32,17,4],
[75,32,17,7],
[75,32,17,9],
[75,32,17,10],
[75,32,17,14],
[75,32,17,15],
[75,32,17,16],
[75,32,18,0],
[75,32,18,1],
[75,32,18,2],
[75,32,18,4],
[75,32,18,7],
[75,32,18,13],
[75,32,18,14],
[75,32,18,16],
[75,32,18,17],
[75,32,19,0],
[75,32,19,1],
[75,32,19,2],
[75,32,19,4],
[75,32,19,7],
[75,32,19,10],
[75,32,19,15],
[75,32,19,16],
[75,32,19,17],
[75,32,19,18],
[75,32,20,0],
[75,32,20,1],
[75,32,20,2],
[75,32,20,4],
[75,32,20,9],
[75,32,20,13],
[75,32,20,14],
[75,32,20,15],
[75,32,20,16],
[75,32,20,17],
[75,32,20,18],
[75,32,20,19],
[75,32,22,0],
[75,32,22,1],
[75,32,22,2],
[75,32,22,7],
[75,32,22,9],
[75,32,22,13],
[75,32,22,14],
[75,32,22,15],
[75,32,22,16],
[75,32,22,17],
[75,32,22,18],
[75,32,22,19],
[75,32,23,4],
[75,32,23,7],
[75,32,23,9],
[75,32,23,10],
[75,32,23,13],
[75,32,23,14],
[75,32,23,15],
[75,32,23,16],
[75,32,23,17],
[75,32,23,18],
[75,32,23,19],
[75,32,23,20],
[75,32,24,4],
[75,32,24,7],
[75,32,24,9],
[75,32,24,10],
[75,32,24,13],
[75,32,24,14],
[75,32,24,15],
[75,32,24,17],
[75,32,24,18],
[75,32,24,19],
[75,32,24,20],
[75,32,25,0],
[75,32,25,1],
[75,32,25,2],
[75,32,25,4],
[75,32,25,9],
[75,32,25,10],
[75,32,25,13],
[75,32,25,14],
[75,32,25,15],
[75,32,25,16],
[75,32,25,17],
[75,32,25,18],
[75,32,26,0],
[75,32,26,1],
[75,32,26,2],
[75,32,26,4],
[75,32,26,7],
[75,32,26,10],
[75,32,26,13],
[75,32,26,14],
[75,32,26,16],
[75,32,26,17],
[75,32,27,0],
[75,32,27,1],
[75,32,27,2],
[75,32,27,4],
[75,32,27,7],
[75,32,27,9],
[75,32,27,13],
[75,32,27,14],
[75,32,27,15],
[75,32,28,0],
[75,32,28,1],
[75,32,28,2],
[75,32,28,4],
[75,32,28,9],
[75,32,28,10],
[75,32,28,13],
[75,32,28,14],
[75,32,30,0],
[75,32,30,1],
[75,32,30,2],
[75,32,30,4],
[75,32,30,7],
[75,32,30,9],
[75,32,30,10],
[75,32,31,0],
[75,32,31,1],
[75,32,31,2],
[75,32,31,4],
[75,32,31,7],
[75,32,31,9],
[75,33,4,0],
[75,33,4,1],
[75,33,4,2],
[75,33,7,0],
[75,33,7,1],
[75,33,7,2],
[75,33,7,4],
[75,33,9,0],
[75,33,9,1],
[75,33,9,2],
[75,33,9,4],
[75,33,9,7],
[75,33,10,0],
[75,33,10,1],
[75,33,10,2],
[75,33,10,4],
[75,33,10,7],
[75,33,10,9],
[75,33,13,0],
[75,33,13,1],
[75,33,13,2],
[75,33,13,4],
[75,33,13,7],
[75,33,13,9],
[75,33,13,10],
[75,33,14,0],
[75,33,14,1],
[75,33,14,2],
[75,33,14,4],
[75,33,14,7],
[75,33,14,9],
[75,33,14,10],
[75,33,15,0],
[75,33,15,1],
[75,33,15,2],
[75,33,15,4],
[75,33,15,7],
[75,33,15,10],
[75,33,15,14],
[75,33,16,0],
[75,33,16,1],
[75,33,16,2],
[75,33,16,4],
[75,33,16,7],
[75,33,16,9],
[75,33,16,13],
[75,33,16,14],
[75,33,16,15],
[75,33,17,0],
[75,33,17,1],
[75,33,17,2],
[75,33,17,4],
[75,33,17,7],
[75,33,17,9],
[75,33,17,13],
[75,33,17,14],
[75,33,17,15],
[75,33,17,16],
[75,33,18,0],
[75,33,18,1],
[75,33,18,2],
[75,33,18,4],
[75,33,18,7],
[75,33,18,10],
[75,33,18,13],
[75,33,18,14],
[75,33,18,16],
[75,33,18,17],
[75,33,19,0],
[75,33,19,1],
[75,33,19,2],
[75,33,19,4],
[75,33,19,9],
[75,33,19,10],
[75,33,19,15],
[75,33,19,16],
[75,33,19,17],
[75,33,19,18],
[75,33,20,0],
[75,33,20,1],
[75,33,20,2],
[75,33,20,4],
[75,33,20,7],
[75,33,20,9],
[75,33,20,13],
[75,33,20,14],
[75,33,20,15],
[75,33,20,16],
[75,33,20,17],
[75,33,20,18],
[75,33,20,19],
[75,33,22,4],
[75,33,22,7],
[75,33,22,9],
[75,33,22,13],
[75,33,22,14],
[75,33,22,15],
[75,33,22,16],
[75,33,22,17],
[75,33,22,18],
[75,33,22,19],
[75,33,23,0],
[75,33,23,1],
[75,33,23,2],
[75,33,23,4],
[75,33,23,7],
[75,33,23,9],
[75,33,23,10],
[75,33,23,13],
[75,33,23,14],
[75,33,23,15],
[75,33,23,16],
[75,33,23,17],
[75,33,23,18],
[75,33,23,19],
[75,33,24,0],
[75,33,24,1],
[75,33,24,2],
[75,33,24,4],
[75,33,24,7],
[75,33,24,9],
[75,33,24,10],
[75,33,24,13],
[75,33,24,14],
[75,33,24,15],
[75,33,24,17],
[75,33,24,18],
[75,33,24,19],
[75,33,25,0],
[75,33,25,1],
[75,33,25,2],
[75,33,25,4],
[75,33,25,9],
[75,33,25,10],
[75,33,25,13],
[75,33,25,14],
[75,33,25,15],
[75,33,25,16],
[75,33,25,17],
[75,33,26,0],
[75,33,26,1],
[75,33,26,2],
[75,33,26,4],
[75,33,26,7],
[75,33,26,10],
[75,33,26,13],
[75,33,26,14],
[75,33,27,0],
[75,33,27,1],
[75,33,27,2],
[75,33,27,4],
[75,33,27,7],
[75,33,27,9],
[75,33,27,13],
[75,33,27,14],
[75,33,28,0],
[75,33,28,1],
[75,33,28,2],
[75,33,28,4],
[75,33,28,9],
[75,33,28,10],
[75,33,28,13],
[75,33,30,0],
[75,33,30,1],
[75,33,30,2],
[75,33,30,4],
[75,33,30,7],
[75,33,30,9],
[75,33,31,0],
[75,33,31,1],
[75,33,31,2],
[75,33,31,4],
[75,33,31,7],
[75,34,4,0],
[75,34,4,1],
[75,34,4,2],
[75,34,9,0],
[75,34,9,1],
[75,34,9,2],
[75,34,9,4],
[75,34,10,0],
[75,34,10,1],
[75,34,10,2],
[75,34,10,4],
[75,34,10,9],
[75,34,11,0],
[75,34,11,1],
[75,34,11,2],
[75,34,11,4],
[75,34,11,9],
[75,34,11,10],
[75,34,12,0],
[75,34,12,1],
[75,34,12,2],
[75,34,12,4],
[75,34,12,9],
[75,34,12,10],
[75,34,13,0],
[75,34,13,1],
[75,34,13,2],
[75,34,13,4],
[75,34,13,9],
[75,34,13,10],
[75,34,13,11],
[75,34,13,12],
[75,34,14,0],
[75,34,14,1],
[75,34,14,2],
[75,34,14,4],
[75,34,14,9],
[75,34,14,10],
[75,34,14,11],
[75,34,15,0],
[75,34,15,1],
[75,34,15,2],
[75,34,15,4],
[75,34,15,12],
[75,34,15,13],
[75,34,15,14],
[75,34,16,0],
[75,34,16,1],
[75,34,16,2],
[75,34,16,4],
[75,34,16,10],
[75,34,16,11],
[75,34,16,12],
[75,34,16,13],
[75,34,16,14],
[75,34,16,15],
[75,34,17,0],
[75,34,17,1],
[75,34,17,2],
[75,34,17,4],
[75,34,17,10],
[75,34,17,11],
[75,34,17,12],
[75,34,17,13],
[75,34,17,14],
[75,34,17,15],
[75,34,17,16],
[75,34,18,0],
[75,34,18,1],
[75,34,18,2],
[75,34,18,4],
[75,34,18,10],
[75,34,18,11],
[75,34,18,12],
[75,34,18,13],
[75,34,18,14],
[75,34,18,16],
[75,34,18,17],
[75,34,19,0],
[75,34,19,1],
[75,34,19,2],
[75,34,19,4],
[75,34,19,9],
[75,34,19,10],
[75,34,19,11],
[75,34,19,12],
[75,34,19,15],
[75,34,19,16],
[75,34,19,17],
[75,34,19,18],
[75,34,20,0],
[75,34,20,1],
[75,34,20,2],
[75,34,20,9],
[75,34,20,11],
[75,34,20,12],
[75,34,20,13],
[75,34,20,14],
[75,34,20,15],
[75,34,20,16],
[75,34,20,17],
[75,34,20,18],
[75,34,20,19],
[75,34,22,0],
[75,34,22,1],
[75,34,22,2],
[75,34,22,4],
[75,34,22,9],
[75,34,22,11],
[75,34,22,12],
[75,34,22,13],
[75,34,22,14],
[75,34,22,15],
[75,34,22,16],
[75,34,22,17],
[75,34,22,18],
[75,34,22,19],
[75,34,23,0],
[75,34,23,1],
[75,34,23,2],
[75,34,23,4],
[75,34,23,9],
[75,34,23,10],
[75,34,23,11],
[75,34,23,12],
[75,34,23,13],
[75,34,23,14],
[75,34,23,15],
[75,34,23,16],
[75,34,23,17],
[75,34,23,18],
[75,34,24,0],
[75,34,24,1],
[75,34,24,2],
[75,34,24,4],
[75,34,24,9],
[75,34,24,10],
[75,34,24,11],
[75,34,24,12],
[75,34,24,13],
[75,34,24,14],
[75,34,24,15],
[75,34,24,17],
[75,34,24,18],
[75,34,26,0],
[75,34,26,1],
[75,34,26,2],
[75,34,26,4],
[75,34,26,10],
[75,34,26,11],
[75,34,26,12],
[75,34,26,13],
[75,34,26,14],
[75,34,27,0],
[75,34,27,1],
[75,34,27,2],
[75,34,27,4],
[75,34,27,9],
[75,34,27,11],
[75,34,27,12],
[75,34,27,13],
[75,34,29,0],
[75,34,29,1],
[75,34,29,2],
[75,34,29,4],
[75,34,29,9],
[75,34,30,0],
[75,34,30,1],
[75,34,30,2],
[75,34,30,4],
[75,34,31,0],
[75,34,31,1],
[75,34,31,2],
[75,34,31,4],
[75,34,32,0],
[75,34,32,1],
[75,34,32,2],
[75,34,32,4],
[75,34,33,0],
[75,34,33,1],
[75,34,33,2],
[75,35,4,0],
[75,35,4,1],
[75,35,4,2],
[75,35,7,0],
[75,35,7,1],
[75,35,7,2],
[75,35,7,4],
[75,35,10,0],
[75,35,10,1],
[75,35,10,2],
[75,35,10,4],
[75,35,10,7],
[75,35,11,0],
[75,35,11,1],
[75,35,11,2],
[75,35,11,4],
[75,35,11,7],
[75,35,11,10],
[75,35,12,0],
[75,35,12,1],
[75,35,12,2],
[75,35,12,4],
[75,35,12,7],
[75,35,12,10],
[75,35,13,0],
[75,35,13,1],
[75,35,13,2],
[75,35,13,4],
[75,35,13,7],
[75,35,13,10],
[75,35,13,11],
[75,35,14,0],
[75,35,14,1],
[75,35,14,2],
[75,35,14,4],
[75,35,14,7],
[75,35,14,12],
[75,35,16,0],
[75,35,16,1],
[75,35,16,2],
[75,35,16,4],
[75,35,16,10],
[75,35,16,11],
[75,35,16,12],
[75,35,16,13],
[75,35,16,14],
[75,35,17,0],
[75,35,17,1],
[75,35,17,2],
[75,35,17,4],
[75,35,17,10],
[75,35,17,11],
[75,35,17,12],
[75,35,17,13],
[75,35,17,14],
[75,35,17,16],
[75,35,19,0],
[75,35,19,1],
[75,35,19,2],
[75,35,19,7],
[75,35,19,10],
[75,35,19,11],
[75,35,19,12],
[75,35,19,16],
[75,35,19,17],
[75,35,20,4],
[75,35,20,7],
[75,35,20,11],
[75,35,20,12],
[75,35,20,13],
[75,35,20,14],
[75,35,20,16],
[75,35,20,17],
[75,35,20,19],
[75,35,22,0],
[75,35,22,1],
[75,35,22,2],
[75,35,22,4],
[75,35,22,7],
[75,35,22,11],
[75,35,22,12],
[75,35,22,13],
[75,35,22,14],
[75,35,22,16],
[75,35,22,17],
[75,35,23,0],
[75,35,23,1],
[75,35,23,2],
[75,35,23,4],
[75,35,23,7],
[75,35,23,10],
[75,35,23,11],
[75,35,23,12],
[75,35,23,13],
[75,35,23,14],
[75,35,23,16],
[75,35,23,17],
[75,35,24,0],
[75,35,24,1],
[75,35,24,2],
[75,35,24,4],
[75,35,24,7],
[75,35,24,10],
[75,35,24,11],
[75,35,24,12],
[75,35,24,13],
[75,35,24,14],
[75,35,24,17],
[75,35,25,0],
[75,35,25,1],
[75,35,25,2],
[75,35,25,4],
[75,35,25,10],
[75,35,25,11],
[75,35,25,12],
[75,35,25,13],
[75,35,25,14],
[75,35,27,0],
[75,35,27,1],
[75,35,27,2],
[75,35,27,4],
[75,35,27,7],
[75,35,27,11],
[75,35,28,0],
[75,35,28,1],
[75,35,28,2],
[75,35,28,4],
[75,35,29,0],
[75,35,29,1],
[75,35,29,2],
[75,35,29,4],
[75,35,29,7],
[75,35,30,0],
[75,35,30,1],
[75,35,30,2],
[75,35,30,4],
[75,35,31,0],
[75,35,31,1],
[75,35,31,2],
[75,35,31,4],
[75,35,32,0],
[75,35,32,1],
[75,35,32,2],
[75,36,4,0],
[75,36,4,1],
[75,36,4,2],
[75,36,7,0],
[75,36,7,1],
[75,36,7,2],
[75,36,7,4],
[75,36,9,0],
[75,36,9,1],
[75,36,9,2],
[75,36,9,4],
[75,36,9,7],
[75,36,10,0],
[75,36,10,1],
[75,36,10,2],
[75,36,10,4],
[75,36,10,7],
[75,36,10,9],
[75,36,11,0],
[75,36,11,1],
[75,36,11,2],
[75,36,11,4],
[75,36,11,7],
[75,36,11,9],
[75,36,11,10],
[75,36,12,0],
[75,36,12,1],
[75,36,12,2],
[75,36,12,4],
[75,36,12,7],
[75,36,12,9],
[75,36,15,0],
[75,36,15,1],
[75,36,15,2],
[75,36,15,4],
[75,36,15,10],
[75,36,15,11],
[75,36,15,12],
[75,36,16,0],
[75,36,16,1],
[75,36,16,2],
[75,36,16,4],
[75,36,16,7],
[75,36,16,9],
[75,36,16,10],
[75,36,16,11],
[75,36,16,12],
[75,36,16,15],
[75,36,17,0],
[75,36,17,1],
[75,36,17,2],
[75,36,17,4],
[75,36,17,7],
[75,36,17,9],
[75,36,17,10],
[75,36,17,11],
[75,36,17,12],
[75,36,17,15],
[75,36,17,16],
[75,36,18,0],
[75,36,18,1],
[75,36,18,2],
[75,36,18,7],
[75,36,18,10],
[75,36,18,11],
[75,36,18,12],
[75,36,18,16],
[75,36,18,17],
[75,36,20,0],
[75,36,20,1],
[75,36,20,2],
[75,36,20,4],
[75,36,20,7],
[75,36,20,9],
[75,36,20,11],
[75,36,20,12],
[75,36,20,15],
[75,36,20,16],
[75,36,20,17],
[75,36,20,18],
[75,36,22,0],
[75,36,22,1],
[75,36,22,2],
[75,36,22,4],
[75,36,22,7],
[75,36,22,9],
[75,36,22,11],
[75,36,22,12],
[75,36,22,15],
[75,36,22,16],
[75,36,22,17],
[75,36,23,0],
[75,36,23,1],
[75,36,23,2],
[75,36,23,4],
[75,36,23,7],
[75,36,23,9],
[75,36,23,10],
[75,36,23,11],
[75,36,23,12],
[75,36,23,15],
[75,36,24,0],
[75,36,24,1],
[75,36,24,2],
[75,36,24,4],
[75,36,24,7],
[75,36,24,9],
[75,36,24,10],
[75,36,24,11],
[75,36,24,12],
[75,36,24,15],
[75,36,25,0],
[75,36,25,1],
[75,36,25,2],
[75,36,25,4],
[75,36,25,9],
[75,36,25,10],
[75,36,25,11],
[75,36,25,12],
[75,36,26,0],
[75,36,26,1],
[75,36,26,2],
[75,36,26,4],
[75,36,26,7],
[75,36,26,10],
[75,36,26,11],
[75,36,27,0],
[75,36,27,1],
[75,36,27,2],
[75,36,27,4],
[75,36,27,7],
[75,36,27,9],
[75,36,28,0],
[75,36,28,1],
[75,36,28,2],
[75,36,28,4],
[75,36,29,0],
[75,36,29,1],
[75,36,29,2],
[75,36,29,4],
[75,36,30,0],
[75,36,30,1],
[75,36,30,2],
[75,36,30,4],
[75,36,31,0],
[75,36,31,1],
[75,36,31,2],
[75,37,7,0],
[75,37,7,1],
[75,37,7,2],
[75,37,9,0],
[75,37,9,1],
[75,37,9,2],
[75,37,9,7],
[75,37,10,0],
[75,37,10,1],
[75,37,10,2],
[75,37,10,7],
[75,37,10,9],
[75,37,11,0],
[75,37,11,1],
[75,37,11,2],
[75,37,11,7],
[75,37,11,9],
[75,37,12,0],
[75,37,12,1],
[75,37,12,2],
[75,37,12,7],
[75,37,12,10],
[75,37,13,0],
[75,37,13,1],
[75,37,13,2],
[75,37,13,7],
[75,37,13,10],
[75,37,13,11],
[75,37,13,12],
[75,37,14,0],
[75,37,14,1],
[75,37,14,2],
[75,37,14,9],
[75,37,14,10],
[75,37,14,11],
[75,37,14,12],
[75,37,15,0],
[75,37,15,1],
[75,37,15,2],
[75,37,15,7],
[75,37,15,10],
[75,37,15,11],
[75,37,15,12],
[75,37,15,13],
[75,37,15,14],
[75,37,16,0],
[75,37,16,1],
[75,37,16,2],
[75,37,16,7],
[75,37,16,9],
[75,37,16,10],
[75,37,16,11],
[75,37,16,12],
[75,37,16,13],
[75,37,16,14],
[75,37,16,15],
[75,37,17,0],
[75,37,17,1],
[75,37,17,2],
[75,37,17,7],
[75,37,17,9],
[75,37,17,10],
[75,37,17,11],
[75,37,17,12],
[75,37,17,13],
[75,37,17,14],
[75,37,17,15],
[75,37,17,16],
[75,37,18,7],
[75,37,18,10],
[75,37,18,11],
[75,37,18,12],
[75,37,18,13],
[75,37,18,14],
[75,37,18,16],
[75,37,18,17],
[75,37,19,0],
[75,37,19,1],
[75,37,19,2],
[75,37,19,7],
[75,37,19,9],
[75,37,19,10],
[75,37,19,11],
[75,37,19,12],
[75,37,19,15],
[75,37,19,16],
[75,37,19,17],
[75,37,19,18],
[75,37,20,0],
[75,37,20,1],
[75,37,20,2],
[75,37,20,7],
[75,37,20,9],
[75,37,20,11],
[75,37,20,12],
[75,37,20,13],
[75,37,20,14],
[75,37,20,15],
[75,37,20,16],
[75,37,20,17],
[75,37,20,18],
[75,37,22,0],
[75,37,22,1],
[75,37,22,2],
[75,37,22,7],
[75,37,22,9],
[75,37,22,11],
[75,37,22,12],
[75,37,22,13],
[75,37,22,14],
[75,37,22,15],
[75,37,23,0],
[75,37,23,1],
[75,37,23,2],
[75,37,23,7],
[75,37,23,9],
[75,37,23,10],
[75,37,23,11],
[75,37,23,12],
[75,37,23,13],
[75,37,23,14],
[75,37,24,0],
[75,37,24,1],
[75,37,24,2],
[75,37,24,7],
[75,37,24,9],
[75,37,24,10],
[75,37,24,11],
[75,37,24,12],
[75,37,24,13],
[75,37,24,14],
[75,37,25,0],
[75,37,25,1],
[75,37,25,2],
[75,37,25,9],
[75,37,25,10],
[75,37,25,11],
[75,37,26,0],
[75,37,26,1],
[75,37,26,2],
[75,37,26,7],
[75,37,27,0],
[75,37,27,1],
[75,37,27,2],
[75,37,27,7],
[75,37,28,0],
[75,37,28,1],
[75,37,28,2],
[75,37,29,0],
[75,37,29,1],
[75,37,29,2],
[75,37,30,0],
[75,37,30,1],
[75,37,30,2],
[75,38,4,0],
[75,38,4,1],
[75,38,4,2],
[75,38,9,0],
[75,38,9,1],
[75,38,9,2],
[75,38,9,4],
[75,38,10,0],
[75,38,10,1],
[75,38,10,2],
[75,38,10,4],
[75,38,11,0],
[75,38,11,1],
[75,38,11,2],
[75,38,11,4],
[75,38,11,10],
[75,38,12,0],
[75,38,12,1],
[75,38,12,2],
[75,38,12,4],
[75,38,12,9],
[75,38,12,10],
[75,38,13,0],
[75,38,13,1],
[75,38,13,2],
[75,38,13,4],
[75,38,13,9],
[75,38,13,10],
[75,38,13,11],
[75,38,13,12],
[75,38,14,0],
[75,38,14,1],
[75,38,14,2],
[75,38,14,4],
[75,38,14,9],
[75,38,14,10],
[75,38,14,11],
[75,38,14,12],
[75,38,15,0],
[75,38,15,1],
[75,38,15,2],
[75,38,15,10],
[75,38,15,11],
[75,38,15,12],
[75,38,15,13],
[75,38,15,14],
[75,38,16,4],
[75,38,16,9],
[75,38,16,10],
[75,38,16,11],
[75,38,16,12],
[75,38,16,13],
[75,38,16,14],
[75,38,16,15],
[75,38,17,4],
[75,38,17,9],
[75,38,17,10],
[75,38,17,11],
[75,38,17,12],
[75,38,17,13],
[75,38,17,14],
[75,38,17,15],
[75,38,17,16],
[75,38,18,0],
[75,38,18,1],
[75,38,18,2],
[75,38,18,4],
[75,38,18,10],
[75,38,18,11],
[75,38,18,12],
[75,38,18,13],
[75,38,18,14],
[75,38,18,16],
[75,38,18,17],
[75,38,19,0],
[75,38,19,1],
[75,38,19,2],
[75,38,19,4],
[75,38,19,9],
[75,38,19,10],
[75,38,19,11],
[75,38,19,12],
[75,38,19,15],
[75,38,19,16],
[75,38,19,17],
[75,38,19,18],
[75,38,20,0],
[75,38,20,1],
[75,38,20,2],
[75,38,20,4],
[75,38,20,9],
[75,38,20,11],
[75,38,20,12],
[75,38,20,13],
[75,38,20,14],
[75,38,20,15],
[75,38,20,16],
[75,38,20,17],
[75,38,22,0],
[75,38,22,1],
[75,38,22,2],
[75,38,22,4],
[75,38,22,9],
[75,38,22,11],
[75,38,22,12],
[75,38,22,13],
[75,38,22,14],
[75,38,23,0],
[75,38,23,1],
[75,38,23,2],
[75,38,23,4],
[75,38,23,9],
[75,38,23,10],
[75,38,23,11],
[75,38,23,12],
[75,38,23,13],
[75,38,24,0],
[75,38,24,1],
[75,38,24,2],
[75,38,24,4],
[75,38,24,9],
[75,38,24,10],
[75,38,24,11],
[75,38,24,12],
[75,38,24,13],
[75,38,26,0],
[75,38,26,1],
[75,38,26,2],
[75,38,26,4],
[75,38,27,0],
[75,38,27,1],
[75,38,27,2],
[75,38,27,4],
[75,38,29,0],
[75,38,29,1],
[75,38,29,2],
[75,40,4,0],
[75,40,4,1],
[75,40,4,2],
[75,40,7,0],
[75,40,7,1],
[75,40,7,2],
[75,40,7,4],
[75,40,10,0],
[75,40,10,1],
[75,40,10,2],
[75,40,10,4],
[75,40,10,7],
[75,40,11,0],
[75,40,11,1],
[75,40,11,2],
[75,40,11,4],
[75,40,11,7],
[75,40,11,10],
[75,40,12,0],
[75,40,12,1],
[75,40,12,2],
[75,40,12,7],
[75,40,12,10],
[75,40,13,0],
[75,40,13,1],
[75,40,13,2],
[75,40,13,7],
[75,40,13,10],
[75,40,13,11],
[75,40,13,12],
[75,40,14,4],
[75,40,14,7],
[75,40,14,10],
[75,40,14,11],
[75,40,14,12],
[75,40,16,0],
[75,40,16,1],
[75,40,16,2],
[75,40,16,4],
[75,40,16,7],
[75,40,16,10],
[75,40,16,11],
[75,40,16,12],
[75,40,16,13],
[75,40,16,14],
[75,40,17,0],
[75,40,17,1],
[75,40,17,2],
[75,40,17,4],
[75,40,17,7],
[75,40,17,10],
[75,40,17,11],
[75,40,17,12],
[75,40,17,13],
[75,40,17,14],
[75,40,17,16],
[75,40,19,0],
[75,40,19,1],
[75,40,19,2],
[75,40,19,4],
[75,40,19,7],
[75,40,19,10],
[75,40,19,11],
[75,40,19,12],
[75,40,20,0],
[75,40,20,1],
[75,40,20,2],
[75,40,20,4],
[75,40,20,7],
[75,40,20,11],
[75,40,20,12],
[75,40,20,13],
[75,40,20,14],
[75,40,22,0],
[75,40,22,1],
[75,40,22,2],
[75,40,22,4],
[75,40,22,7],
[75,40,22,11],
[75,40,23,0],
[75,40,23,1],
[75,40,23,2],
[75,40,23,4],
[75,40,23,7],
[75,40,24,0],
[75,40,24,1],
[75,40,24,2],
[75,40,24,4],
[75,40,24,7],
[75,40,25,0],
[75,40,25,1],
[75,40,25,2],
[75,40,25,4],
[75,40,27,0],
[75,40,27,1],
[75,40,27,2],
[75,41,4,0],
[75,41,4,1],
[75,41,4,2],
[75,41,7,0],
[75,41,7,1],
[75,41,7,2],
[75,41,7,4],
[75,41,10,0],
[75,41,10,1],
[75,41,10,2],
[75,41,10,4],
[75,41,10,7],
[75,41,11,0],
[75,41,11,1],
[75,41,11,2],
[75,41,11,4],
[75,41,11,7],
[75,41,11,10],
[75,41,12,0],
[75,41,12,1],
[75,41,12,2],
[75,41,12,7],
[75,41,12,10],
[75,41,13,0],
[75,41,13,1],
[75,41,13,2],
[75,41,13,7],
[75,41,13,10],
[75,41,13,11],
[75,41,13,12],
[75,41,14,4],
[75,41,14,7],
[75,41,14,10],
[75,41,14,11],
[75,41,14,12],
[75,41,16,0],
[75,41,16,1],
[75,41,16,2],
[75,41,16,4],
[75,41,16,7],
[75,41,16,10],
[75,41,16,11],
[75,41,16,12],
[75,41,16,13],
[75,41,16,14],
[75,41,17,0],
[75,41,17,1],
[75,41,17,2],
[75,41,17,4],
[75,41,17,7],
[75,41,17,10],
[75,41,17,11],
[75,41,17,12],
[75,41,17,13],
[75,41,17,14],
[75,41,17,16],
[75,41,19,0],
[75,41,19,1],
[75,41,19,2],
[75,41,19,4],
[75,41,19,7],
[75,41,19,10],
[75,41,19,11],
[75,41,19,12],
[75,41,20,0],
[75,41,20,1],
[75,41,20,2],
[75,41,20,4],
[75,41,20,7],
[75,41,20,11],
[75,41,20,12],
[75,41,20,13],
[75,41,20,14],
[75,41,22,0],
[75,41,22,1],
[75,41,22,2],
[75,41,22,4],
[75,41,22,7],
[75,41,22,11],
[75,41,23,0],
[75,41,23,1],
[75,41,23,2],
[75,41,23,4],
[75,41,23,7],
[75,41,24,0],
[75,41,24,1],
[75,41,24,2],
[75,41,24,4],
[75,41,24,7],
[75,41,25,0],
[75,41,25,1],
[75,41,25,2],
[75,41,25,4],
[75,41,27,0],
[75,41,27,1],
[75,41,27,2],
[75,42,4,0],
[75,42,4,1],
[75,42,4,2],
[75,42,7,0],
[75,42,7,1],
[75,42,7,2],
[75,42,7,4],
[75,42,9,0],
[75,42,9,1],
[75,42,9,2],
[75,42,9,4],
[75,42,9,7],
[75,42,11,0],
[75,42,11,1],
[75,42,11,2],
[75,42,11,7],
[75,42,11,9],
[75,42,12,4],
[75,42,12,7],
[75,42,12,9],
[75,42,13,4],
[75,42,13,7],
[75,42,13,9],
[75,42,13,11],
[75,42,13,12],
[75,42,14,0],
[75,42,14,1],
[75,42,14,2],
[75,42,14,4],
[75,42,14,7],
[75,42,14,9],
[75,42,14,11],
[75,42,14,12],
[75,42,15,0],
[75,42,15,1],
[75,42,15,2],
[75,42,15,4],
[75,42,15,7],
[75,42,15,11],
[75,42,15,12],
[75,42,15,13],
[75,42,15,14],
[75,42,16,0],
[75,42,16,1],
[75,42,16,2],
[75,42,16,4],
[75,42,16,7],
[75,42,16,9],
[75,42,16,11],
[75,42,16,12],
[75,42,16,13],
[75,42,16,14],
[75,42,16,15],
[75,42,17,0],
[75,42,17,1],
[75,42,17,2],
[75,42,17,4],
[75,42,17,7],
[75,42,17,9],
[75,42,17,11],
[75,42,17,12],
[75,42,17,13],
[75,42,17,14],
[75,42,17,15],
[75,42,17,16],
[75,42,18,0],
[75,42,18,1],
[75,42,18,2],
[75,42,18,4],
[75,42,18,7],
[75,42,18,11],
[75,42,18,12],
[75,42,18,13],
[75,42,18,14],
[75,42,19,0],
[75,42,19,1],
[75,42,19,2],
[75,42,19,4],
[75,42,19,7],
[75,42,19,9],
[75,42,19,11],
[75,42,19,12],
[75,42,23,0],
[75,42,23,1],
[75,42,23,2],
[75,42,23,4],
[75,42,23,7],
[75,42,24,0],
[75,42,24,1],
[75,42,24,2],
[75,42,24,4],
[75,42,24,7],
[75,42,25,0],
[75,42,25,1],
[75,42,25,2],
[75,42,25,4],
[75,42,26,0],
[75,42,26,1],
[75,42,26,2],
[75,43,4,0],
[75,43,4,1],
[75,43,4,2],
[75,43,9,0],
[75,43,9,1],
[75,43,9,2],
[75,43,10,4],
[75,43,10,9],
[75,43,11,4],
[75,43,11,9],
[75,43,11,10],
[75,43,12,0],
[75,43,12,1],
[75,43,12,2],
[75,43,12,4],
[75,43,12,9],
[75,43,12,10],
[75,43,13,0],
[75,43,13,1],
[75,43,13,2],
[75,43,13,4],
[75,43,13,9],
[75,43,13,10],
[75,43,13,11],
[75,43,13,12],
[75,43,14,0],
[75,43,14,1],
[75,43,14,2],
[75,43,14,4],
[75,43,14,9],
[75,43,14,10],
[75,43,14,11],
[75,43,14,12],
[75,43,15,0],
[75,43,15,1],
[75,43,15,2],
[75,43,15,4],
[75,43,15,10],
[75,43,15,11],
[75,43,15,12],
[75,43,15,13],
[75,43,15,14],
[75,43,16,0],
[75,43,16,1],
[75,43,16,2],
[75,43,16,4],
[75,43,16,9],
[75,43,16,10],
[75,43,16,11],
[75,43,16,12],
[75,43,16,13],
[75,43,16,14],
[75,43,16,15],
[75,43,17,0],
[75,43,17,1],
[75,43,17,2],
[75,43,17,4],
[75,43,17,9],
[75,43,17,10],
[75,43,17,11],
[75,43,17,12],
[75,43,17,13],
[75,43,17,14],
[75,43,17,15],
[75,43,18,0],
[75,43,18,1],
[75,43,18,2],
[75,43,18,4],
[75,43,18,10],
[75,43,18,11],
[75,43,18,12],
[75,43,18,13],
[75,43,18,14],
[75,43,19,0],
[75,43,19,1],
[75,43,19,2],
[75,43,19,4],
[75,43,19,9],
[75,43,19,10],
[75,43,19,11],
[75,43,19,12],
[75,43,20,0],
[75,43,20,1],
[75,43,20,2],
[75,43,20,4],
[75,43,20,9],
[75,43,20,11],
[75,43,22,0],
[75,43,22,1],
[75,43,22,2],
[75,43,22,4],
[75,43,23,0],
[75,43,23,1],
[75,43,23,2],
[75,43,23,4],
[75,43,24,0],
[75,43,24,1],
[75,43,24,2],
[75,43,24,4],
[75,44,4,0],
[75,44,4,1],
[75,44,4,2],
[75,44,7,0],
[75,44,7,1],
[75,44,7,2],
[75,44,9,4],
[75,44,9,7],
[75,44,10,0],
[75,44,10,1],
[75,44,10,2],
[75,44,10,4],
[75,44,10,7],
[75,44,10,9],
[75,44,11,0],
[75,44,11,1],
[75,44,11,2],
[75,44,11,4],
[75,44,11,7],
[75,44,11,9],
[75,44,11,10],
[75,44,12,0],
[75,44,12,1],
[75,44,12,2],
[75,44,12,4],
[75,44,12,7],
[75,44,12,9],
[75,44,12,10],
[75,44,13,0],
[75,44,13,1],
[75,44,13,2],
[75,44,13,4],
[75,44,13,7],
[75,44,13,9],
[75,44,13,10],
[75,44,13,11],
[75,44,13,12],
[75,44,14,0],
[75,44,14,1],
[75,44,14,2],
[75,44,14,4],
[75,44,14,7],
[75,44,14,9],
[75,44,14,10],
[75,44,14,11],
[75,44,14,12],
[75,44,15,0],
[75,44,15,1],
[75,44,15,2],
[75,44,15,4],
[75,44,15,7],
[75,44,15,10],
[75,44,15,11],
[75,44,15,12],
[75,44,15,13],
[75,44,15,14],
[75,44,16,0],
[75,44,16,1],
[75,44,16,2],
[75,44,16,4],
[75,44,16,7],
[75,44,16,9],
[75,44,16,10],
[75,44,16,11],
[75,44,16,12],
[75,44,16,13],
[75,44,16,14],
[75,44,18,0],
[75,44,18,1],
[75,44,18,2],
[75,44,18,4],
[75,44,18,7],
[75,44,18,10],
[75,44,18,11],
[75,44,18,12],
[75,44,18,13],
[75,44,19,0],
[75,44,19,1],
[75,44,19,2],
[75,44,19,4],
[75,44,19,7],
[75,44,19,9],
[75,44,19,10],
[75,44,19,11],
[75,44,20,0],
[75,44,20,1],
[75,44,20,2],
[75,44,20,4],
[75,44,20,7],
[75,44,20,9],
[75,44,22,0],
[75,44,22,1],
[75,44,22,2],
[75,44,22,4],
[75,44,23,0],
[75,44,23,1],
[75,44,23,2],
[75,44,23,4],
[75,44,24,0],
[75,44,24,1],
[75,44,24,2],
[75,44,24,4],
[75,45,4,0],
[75,45,4,1],
[75,45,4,2],
[75,45,7,4],
[75,45,10,0],
[75,45,10,1],
[75,45,10,2],
[75,45,10,4],
[75,45,10,7],
[75,45,11,0],
[75,45,11,1],
[75,45,11,2],
[75,45,11,4],
[75,45,11,7],
[75,45,11,10],
[75,45,12,0],
[75,45,12,1],
[75,45,12,2],
[75,45,12,4],
[75,45,12,7],
[75,45,12,10],
[75,45,13,0],
[75,45,13,1],
[75,45,13,2],
[75,45,13,4],
[75,45,13,7],
[75,45,13,10],
[75,45,13,11],
[75,45,13,12],
[75,45,14,0],
[75,45,14,1],
[75,45,14,2],
[75,45,14,4],
[75,45,14,7],
[75,45,14,10],
[75,45,14,11],
[75,45,14,12],
[75,45,16,0],
[75,45,16,1],
[75,45,16,2],
[75,45,16,4],
[75,45,16,7],
[75,45,16,10],
[75,45,16,11],
[75,45,16,12],
[75,45,16,13],
[75,45,17,0],
[75,45,17,1],
[75,45,17,2],
[75,45,17,4],
[75,45,17,7],
[75,45,17,10],
[75,45,17,11],
[75,45,17,12],
[75,45,17,13],
[75,45,19,0],
[75,45,19,1],
[75,45,19,2],
[75,45,19,4],
[75,45,19,7],
[75,45,20,0],
[75,45,20,1],
[75,45,20,2],
[75,45,20,4],
[75,45,20,7],
[75,45,22,0],
[75,45,22,1],
[75,45,22,2],
[75,45,22,4],
[75,45,23,0],
[75,45,23,1],
[75,45,23,2],
[75,45,24,0],
[75,45,24,1],
[75,45,24,2],
[75,46,4,0],
[75,46,4,1],
[75,46,4,2],
[75,46,7,0],
[75,46,7,1],
[75,46,7,2],
[75,46,7,4],
[75,46,10,0],
[75,46,10,1],
[75,46,10,2],
[75,46,10,4],
[75,46,10,7],
[75,46,11,0],
[75,46,11,1],
[75,46,11,2],
[75,46,11,4],
[75,46,11,7],
[75,46,11,10],
[75,46,12,0],
[75,46,12,1],
[75,46,12,2],
[75,46,12,4],
[75,46,12,7],
[75,46,12,10],
[75,46,13,0],
[75,46,13,1],
[75,46,13,2],
[75,46,13,4],
[75,46,13,7],
[75,46,13,10],
[75,46,13,11],
[75,46,13,12],
[75,46,14,0],
[75,46,14,1],
[75,46,14,2],
[75,46,14,4],
[75,46,14,7],
[75,46,14,10],
[75,46,14,11],
[75,46,14,12],
[75,46,16,0],
[75,46,16,1],
[75,46,16,2],
[75,46,16,4],
[75,46,16,7],
[75,46,16,10],
[75,46,16,11],
[75,46,17,0],
[75,46,17,1],
[75,46,17,2],
[75,46,17,4],
[75,46,17,7],
[75,46,17,10],
[75,46,17,11],
[75,46,19,0],
[75,46,19,1],
[75,46,19,2],
[75,46,19,4],
[75,46,19,7],
[75,46,20,0],
[75,46,20,1],
[75,46,20,2],
[75,46,20,4],
[75,46,22,0],
[75,46,22,1],
[75,46,22,2],
[75,47,7,0],
[75,47,7,1],
[75,47,7,2],
[75,47,7,4],
[75,47,9,0],
[75,47,9,1],
[75,47,9,2],
[75,47,9,4],
[75,47,9,7],
[75,47,10,0],
[75,47,10,1],
[75,47,10,2],
[75,47,10,4],
[75,47,10,7],
[75,47,10,9],
[75,47,11,0],
[75,47,11,1],
[75,47,11,2],
[75,47,11,4],
[75,47,11,7],
[75,47,11,9],
[75,47,11,10],
[75,47,12,0],
[75,47,12,1],
[75,47,12,2],
[75,47,12,4],
[75,47,12,7],
[75,47,12,9],
[75,47,12,10],
[75,47,13,0],
[75,47,13,1],
[75,47,13,2],
[75,47,13,4],
[75,47,13,7],
[75,47,13,9],
[75,47,13,10],
[75,47,13,11],
[75,47,13,12],
[75,47,14,0],
[75,47,14,1],
[75,47,14,2],
[75,47,14,4],
[75,47,14,7],
[75,47,14,9],
[75,47,14,10],
[75,47,14,11],
[75,47,14,12],
[75,47,15,0],
[75,47,15,1],
[75,47,15,2],
[75,47,15,4],
[75,47,15,7],
[75,47,15,10],
[75,47,15,11],
[75,47,16,0],
[75,47,16,1],
[75,47,16,2],
[75,47,16,4],
[75,47,16,7],
[75,47,16,9],
[75,47,17,0],
[75,47,17,1],
[75,47,17,2],
[75,47,17,4],
[75,47,17,7],
[75,47,17,9],
[75,47,18,0],
[75,47,18,1],
[75,47,18,2],
[75,47,18,4],
[75,47,18,7],
[75,47,19,0],
[75,47,19,1],
[75,47,19,2],
[75,47,19,4],
[75,47,20,0],
[75,47,20,1],
[75,47,20,2],
[75,47,20,4],
[75,48,4,0],
[75,48,4,1],
[75,48,4,2],
[75,48,7,0],
[75,48,7,1],
[75,48,7,2],
[75,48,7,4],
[75,48,9,0],
[75,48,9,1],
[75,48,9,2],
[75,48,9,4],
[75,48,9,7],
[75,48,10,0],
[75,48,10,1],
[75,48,10,2],
[75,48,10,4],
[75,48,10,7],
[75,48,10,9],
[75,48,13,0],
[75,48,13,1],
[75,48,13,2],
[75,48,13,4],
[75,48,13,7],
[75,48,13,9],
[75,48,13,10],
[75,48,14,0],
[75,48,14,1],
[75,48,14,2],
[75,48,14,4],
[75,48,14,7],
[75,48,14,9],
[75,48,14,10],
[75,48,15,0],
[75,48,15,1],
[75,48,15,2],
[75,48,15,4],
[75,48,15,7],
[75,48,16,0],
[75,48,16,1],
[75,48,16,2],
[75,48,16,4],
[75,48,16,7],
[75,48,17,0],
[75,48,17,1],
[75,48,17,2],
[75,48,17,4],
[75,48,17,7],
[75,48,18,0],
[75,48,18,1],
[75,48,18,2],
[75,48,18,4],
[75,48,19,0],
[75,48,19,1],
[75,48,19,2],
[75,48,19,4],
[75,48,20,0],
[75,48,20,1],
[75,48,20,2],
[75,49,4,0],
[75,49,4,1],
[75,49,4,2],
[75,49,7,0],
[75,49,7,1],
[75,49,7,2],
[75,49,7,4],
[75,49,10,0],
[75,49,10,1],
[75,49,10,2],
[75,49,10,4],
[75,49,10,7],
[75,49,11,0],
[75,49,11,1],
[75,49,11,2],
[75,49,11,4],
[75,49,11,7],
[75,49,11,10],
[75,49,12,0],
[75,49,12,1],
[75,49,12,2],
[75,49,12,4],
[75,49,12,7],
[75,49,12,10],
[75,49,13,0],
[75,49,13,1],
[75,49,13,2],
[75,49,13,4],
[75,49,13,7],
[75,49,13,10],
[75,49,13,11],
[75,49,13,12],
[75,49,14,0],
[75,49,14,1],
[75,49,14,2],
[75,49,14,4],
[75,49,14,7],
[75,49,14,10],
[75,49,14,11],
[75,49,16,0],
[75,49,16,1],
[75,49,16,2],
[75,49,16,4],
[75,49,16,7],
[75,49,17,0],
[75,49,17,1],
[75,49,17,2],
[75,49,17,4],
[75,49,17,7],
[75,49,19,0],
[75,49,19,1],
[75,49,19,2],
[75,49,19,4],
[75,49,20,0],
[75,49,20,1],
[75,49,20,2],
[75,50,4,0],
[75,50,4,1],
[75,50,4,2],
[75,50,7,0],
[75,50,7,1],
[75,50,7,2],
[75,50,7,4],
[75,50,10,0],
[75,50,10,1],
[75,50,10,2],
[75,50,10,4],
[75,50,10,7],
[75,50,11,0],
[75,50,11,1],
[75,50,11,2],
[75,50,11,4],
[75,50,11,7],
[75,50,11,10],
[75,50,12,0],
[75,50,12,1],
[75,50,12,2],
[75,50,12,4],
[75,50,12,7],
[75,50,12,10],
[75,50,13,0],
[75,50,13,1],
[75,50,13,2],
[75,50,13,4],
[75,50,13,7],
[75,50,13,10],
[75,50,13,11],
[75,50,13,12],
[75,50,14,0],
[75,50,14,1],
[75,50,14,2],
[75,50,14,4],
[75,50,14,7],
[75,50,14,10],
[75,50,14,11],
[75,50,16,0],
[75,50,16,1],
[75,50,16,2],
[75,50,16,4],
[75,50,16,7],
[75,50,17,0],
[75,50,17,1],
[75,50,17,2],
[75,50,17,4],
[75,50,17,7],
[75,50,19,0],
[75,50,19,1],
[75,50,19,2],
[75,50,19,4],
[75,50,20,0],
[75,50,20,1],
[75,50,20,2],
[75,52,4,0],
[75,52,4,1],
[75,52,4,2],
[75,52,7,0],
[75,52,7,1],
[75,52,7,2],
[75,52,7,4],
[75,52,10,0],
[75,52,10,1],
[75,52,10,2],
[75,52,10,4],
[75,52,10,7],
[75,52,11,0],
[75,52,11,1],
[75,52,11,2],
[75,52,11,4],
[75,52,11,7],
[75,52,11,10],
[75,52,12,0],
[75,52,12,1],
[75,52,12,2],
[75,52,12,4],
[75,52,12,7],
[75,52,12,10],
[75,52,13,0],
[75,52,13,1],
[75,52,13,2],
[75,52,13,4],
[75,52,13,7],
[75,52,13,10],
[75,52,13,11],
[75,52,13,12],
[75,52,14,0],
[75,52,14,1],
[75,52,14,2],
[75,52,14,4],
[75,52,14,7],
[75,52,14,10],
[75,52,14,11],
[75,52,16,0],
[75,52,16,1],
[75,52,16,2],
[75,52,16,4],
[75,52,16,7],
[75,52,17,0],
[75,52,17,1],
[75,52,17,2],
[75,52,17,4],
[75,52,17,7],
[75,52,19,0],
[75,52,19,1],
[75,52,19,2],
[75,52,19,4],
[75,52,20,0],
[75,52,20,1],
[75,52,20,2],
[75,53,4,0],
[75,53,4,1],
[75,53,4,2],
[75,53,7,0],
[75,53,7,1],
[75,53,7,2],
[75,53,7,4],
[75,53,9,0],
[75,53,9,1],
[75,53,9,2],
[75,53,9,4],
[75,53,9,7],
[75,53,11,0],
[75,53,11,1],
[75,53,11,2],
[75,53,11,4],
[75,53,11,7],
[75,53,11,9],
[75,53,12,0],
[75,53,12,1],
[75,53,12,2],
[75,53,12,4],
[75,53,12,7],
[75,53,12,9],
[75,53,13,0],
[75,53,13,1],
[75,53,13,2],
[75,53,13,4],
[75,53,13,7],
[75,53,13,9],
[75,53,13,11],
[75,53,13,12],
[75,53,14,0],
[75,53,14,1],
[75,53,14,2],
[75,53,14,4],
[75,53,14,7],
[75,53,14,9],
[75,53,14,11],
[75,53,15,0],
[75,53,15,1],
[75,53,15,2],
[75,53,15,4],
[75,53,15,7],
[75,53,16,0],
[75,53,16,1],
[75,53,16,2],
[75,53,16,4],
[75,53,16,7],
[75,53,17,0],
[75,53,17,1],
[75,53,17,2],
[75,53,17,4],
[75,53,17,7],
[75,53,18,0],
[75,53,18,1],
[75,53,18,2],
[75,53,18,4],
[75,53,19,0],
[75,53,19,1],
[75,53,19,2],
[75,53,19,4],
[75,54,4,0],
[75,54,4,1],
[75,54,4,2],
[75,54,7,0],
[75,54,7,1],
[75,54,7,2],
[75,54,7,4],
[75,54,9,0],
[75,54,9,1],
[75,54,9,2],
[75,54,9,4],
[75,54,9,7],
[75,54,11,0],
[75,54,11,1],
[75,54,11,2],
[75,54,11,4],
[75,54,11,7],
[75,54,11,9],
[75,54,12,0],
[75,54,12,1],
[75,54,12,2],
[75,54,12,4],
[75,54,12,7],
[75,54,12,9],
[75,54,13,0],
[75,54,13,1],
[75,54,13,2],
[75,54,13,4],
[75,54,13,7],
[75,54,13,9],
[75,54,13,11],
[75,54,13,12],
[75,54,14,0],
[75,54,14,1],
[75,54,14,2],
[75,54,14,4],
[75,54,14,7],
[75,54,14,9],
[75,54,14,11],
[75,54,15,0],
[75,54,15,1],
[75,54,15,2],
[75,54,15,4],
[75,54,15,7],
[75,54,16,0],
[75,54,16,1],
[75,54,16,2],
[75,54,16,4],
[75,54,16,7],
[75,54,17,0],
[75,54,17,1],
[75,54,17,2],
[75,54,17,4],
[75,54,17,7],
[75,54,18,0],
[75,54,18,1],
[75,54,18,2],
[75,54,18,4],
[75,54,19,0],
[75,54,19,1],
[75,54,19,2],
[75,54,19,4],
[75,55,4,0],
[75,55,4,1],
[75,55,4,2],
[75,55,7,0],
[75,55,7,1],
[75,55,7,2],
[75,55,7,4],
[75,55,9,0],
[75,55,9,1],
[75,55,9,2],
[75,55,9,4],
[75,55,9,7],
[75,55,11,0],
[75,55,11,1],
[75,55,11,2],
[75,55,11,4],
[75,55,11,7],
[75,55,11,9],
[75,55,12,0],
[75,55,12,1],
[75,55,12,2],
[75,55,12,4],
[75,55,12,7],
[75,55,12,9],
[75,55,13,0],
[75,55,13,1],
[75,55,13,2],
[75,55,13,4],
[75,55,13,7],
[75,55,13,9],
[75,55,13,11],
[75,55,13,12],
[75,55,14,0],
[75,55,14,1],
[75,55,14,2],
[75,55,14,4],
[75,55,14,7],
[75,55,14,9],
[75,55,14,11],
[75,55,15,0],
[75,55,15,1],
[75,55,15,2],
[75,55,15,4],
[75,55,15,7],
[75,55,16,0],
[75,55,16,1],
[75,55,16,2],
[75,55,16,4],
[75,55,16,7],
[75,55,17,0],
[75,55,17,1],
[75,55,17,2],
[75,55,17,4],
[75,55,17,7],
[75,55,18,0],
[75,55,18,1],
[75,55,18,2],
[75,55,18,4],
[75,55,19,0],
[75,55,19,1],
[75,55,19,2],
[75,55,19,4],
[75,56,4,0],
[75,56,4,1],
[75,56,4,2],
[75,56,7,0],
[75,56,7,1],
[75,56,7,2],
[75,56,7,4],
[75,56,10,0],
[75,56,10,1],
[75,56,10,2],
[75,56,10,4],
[75,56,10,7],
[75,56,11,0],
[75,56,11,1],
[75,56,11,2],
[75,56,11,4],
[75,56,11,7],
[75,56,11,10],
[75,56,12,0],
[75,56,12,1],
[75,56,12,2],
[75,56,12,4],
[75,56,12,7],
[75,56,12,10],
[75,56,13,0],
[75,56,13,1],
[75,56,13,2],
[75,56,13,4],
[75,56,13,7],
[75,56,13,10],
[75,56,13,11],
[75,56,13,12],
[75,56,14,0],
[75,56,14,1],
[75,56,14,2],
[75,56,14,4],
[75,56,14,7],
[75,56,14,10],
[75,56,14,11],
[75,56,16,0],
[75,56,16,1],
[75,56,16,2],
[75,56,16,4],
[75,56,16,7],
[75,56,17,0],
[75,56,17,1],
[75,56,17,2],
[75,56,17,4],
[75,56,17,7],
[75,56,19,0],
[75,56,19,1],
[75,56,19,2],
[75,56,19,4],
[75,56,20,0],
[75,56,20,1],
[75,56,20,2],
[75,57,4,0],
[75,57,4,1],
[75,57,4,2],
[75,57,7,0],
[75,57,7,1],
[75,57,7,2],
[75,57,7,4],
[75,57,9,0],
[75,57,9,1],
[75,57,9,2],
[75,57,9,4],
[75,57,9,7],
[75,57,10,0],
[75,57,10,1],
[75,57,10,2],
[75,57,10,4],
[75,57,10,7],
[75,57,10,9],
[75,57,11,0],
[75,57,11,1],
[75,57,11,2],
[75,57,11,4],
[75,57,11,7],
[75,57,11,9],
[75,57,11,10],
[75,57,12,0],
[75,57,12,1],
[75,57,12,2],
[75,57,12,4],
[75,57,12,7],
[75,57,12,9],
[75,57,12,10],
[75,57,13,0],
[75,57,13,1],
[75,57,13,2],
[75,57,13,4],
[75,57,13,7],
[75,57,13,9],
[75,57,13,10],
[75,57,13,11],
[75,57,14,0],
[75,57,14,1],
[75,57,14,2],
[75,57,14,4],
[75,57,14,7],
[75,57,14,9],
[75,57,15,0],
[75,57,15,1],
[75,57,15,2],
[75,57,15,4],
[75,57,15,7],
[75,57,16,0],
[75,57,16,1],
[75,57,16,2],
[75,57,16,4],
[75,57,17,0],
[75,57,17,1],
[75,57,17,2],
[75,57,17,4],
[75,57,18,0],
[75,57,18,1],
[75,57,18,2],
[75,57,18,4],
[75,57,19,0],
[75,57,19,1],
[75,57,19,2],
[75,58,4,0],
[75,58,4,1],
[75,58,4,2],
[75,58,7,0],
[75,58,7,1],
[75,58,7,2],
[75,58,7,4],
[75,58,9,0],
[75,58,9,1],
[75,58,9,2],
[75,58,9,4],
[75,58,9,7],
[75,58,10,0],
[75,58,10,1],
[75,58,10,2],
[75,58,10,4],
[75,58,10,7],
[75,58,10,9],
[75,58,11,0],
[75,58,11,1],
[75,58,11,2],
[75,58,11,4],
[75,58,11,7],
[75,58,11,9],
[75,58,11,10],
[75,58,12,0],
[75,58,12,1],
[75,58,12,2],
[75,58,12,4],
[75,58,12,7],
[75,58,12,9],
[75,58,12,10],
[75,58,13,0],
[75,58,13,1],
[75,58,13,2],
[75,58,13,4],
[75,58,13,7],
[75,58,13,9],
[75,58,13,10],
[75,58,13,11],
[75,58,14,0],
[75,58,14,1],
[75,58,14,2],
[75,58,14,4],
[75,58,14,7],
[75,58,14,9],
[75,58,15,0],
[75,58,15,1],
[75,58,15,2],
[75,58,15,4],
[75,58,15,7],
[75,58,17,0],
[75,58,17,1],
[75,58,17,2],
[75,58,17,4],
[75,58,18,0],
[75,58,18,1],
[75,58,18,2],
[75,58,18,4],
[75,58,19,0],
[75,58,19,1],
[75,58,19,2],
[75,59,4,0],
[75,59,4,1],
[75,59,4,2],
[75,59,7,0],
[75,59,7,1],
[75,59,7,2],
[75,59,7,4],
[75,59,10,0],
[75,59,10,1],
[75,59,10,2],
[75,59,10,4],
[75,59,10,7],
[75,59,11,0],
[75,59,11,1],
[75,59,11,2],
[75,59,11,4],
[75,59,11,7],
[75,59,11,10],
[75,59,12,0],
[75,59,12,1],
[75,59,12,2],
[75,59,12,4],
[75,59,12,7],
[75,59,13,0],
[75,59,13,1],
[75,59,13,2],
[75,59,13,4],
[75,59,13,7],
[75,59,14,0],
[75,59,14,1],
[75,59,14,2],
[75,59,14,4],
[75,59,14,7],
[75,59,16,0],
[75,59,16,1],
[75,59,16,2],
[75,59,16,4],
[75,59,17,0],
[75,59,17,1],
[75,59,17,2],
[75,59,17,4],
[75,60,4,0],
[75,60,4,1],
[75,60,4,2],
[75,60,7,0],
[75,60,7,1],
[75,60,7,2],
[75,60,7,4],
[75,60,9,0],
[75,60,9,1],
[75,60,9,2],
[75,60,9,4],
[75,60,9,7],
[75,60,10,0],
[75,60,10,1],
[75,60,10,2],
[75,60,10,4],
[75,60,10,7],
[75,60,10,9],
[75,60,11,0],
[75,60,11,1],
[75,60,11,2],
[75,60,11,4],
[75,60,11,7],
[75,60,11,9],
[75,60,12,0],
[75,60,12,1],
[75,60,12,2],
[75,60,12,4],
[75,60,12,7],
[75,60,15,0],
[75,60,15,1],
[75,60,15,2],
[75,60,15,4],
[75,60,16,0],
[75,60,16,1],
[75,60,16,2],
[75,60,17,0],
[75,60,17,1],
[75,60,17,2],
[75,61,7,0],
[75,61,7,1],
[75,61,7,2],
[75,61,9,0],
[75,61,9,1],
[75,61,9,2],
[75,61,9,7],
[75,61,10,0],
[75,61,10,1],
[75,61,10,2],
[75,61,10,7],
[75,61,11,0],
[75,61,11,1],
[75,61,11,2],
[75,61,11,7],
[75,61,12,0],
[75,61,12,1],
[75,61,12,2],
[75,61,13,0],
[75,61,13,1],
[75,61,13,2],
[75,61,14,0],
[75,61,14,1],
[75,61,14,2],
[75,61,15,0],
[75,61,15,1],
[75,61,15,2],
[75,62,4,0],
[75,62,4,1],
[75,62,4,2],
[75,62,9,0],
[75,62,9,1],
[75,62,9,2],
[75,62,9,4],
[75,62,10,0],
[75,62,10,1],
[75,62,10,2],
[75,62,10,4],
[75,62,11,0],
[75,62,11,1],
[75,62,11,2],
[75,62,11,4],
[75,62,12,0],
[75,62,12,1],
[75,62,12,2],
[75,62,12,4],
[75,62,13,0],
[75,62,13,1],
[75,62,13,2],
[75,62,13,4],
[75,62,14,0],
[75,62,14,1],
[75,62,14,2],
[75,62,14,4],
[75,62,15,0],
[75,62,15,1],
[75,62,15,2],
[75,63,4,0],
[75,63,4,1],
[75,63,4,2],
[75,63,7,0],
[75,63,7,1],
[75,63,7,2],
[75,63,7,4],
[75,63,10,0],
[75,63,10,1],
[75,63,10,2],
[75,63,10,4],
[75,63,11,0],
[75,63,11,1],
[75,63,11,2],
[75,63,11,4],
[75,63,12,0],
[75,63,12,1],
[75,63,12,2],
[75,63,12,4],
[75,63,13,0],
[75,63,13,1],
[75,63,13,2],
[75,63,13,4],
[75,63,14,0],
[75,63,14,1],
[75,63,14,2],
[75,64,7,0],
[75,64,7,1],
[75,64,7,2],
[75,64,9,0],
[75,64,9,1],
[75,64,9,2],
[75,64,10,0],
[75,64,10,1],
[75,64,10,2],
[75,64,11,0],
[75,64,11,1],
[75,64,11,2],
[75,64,12,0],
[75,64,12,1],
[75,64,12,2],
[75,64,13,0],
[75,64,13,1],
[75,64,13,2],
[75,65,4,0],
[75,65,4,1],
[75,65,4,2],
[75,65,7,0],
[75,65,7,1],
[75,65,7,2],
[75,65,7,4],
[75,65,9,0],
[75,65,9,1],
[75,65,9,2],
[75,65,9,4],
[75,65,10,0],
[75,65,10,1],
[75,65,10,2],
[75,65,11,0],
[75,65,11,1],
[75,65,11,2],
[75,66,4,0],
[75,66,4,1],
[75,66,4,2],
[75,66,9,0],
[75,66,9,1],
[75,66,9,2],
[75,67,4,0],
[75,67,4,1],
[75,67,4,2],
[75,68,4,0],
[75,68,4,1],
[75,68,4,2],
[75,69,4,0],
[75,69,4,1],
[75,69,4,2],
[76,4,3,0],
[76,4,3,1],
[76,4,3,2],
[76,5,4,0],
[76,5,4,1],
[76,5,4,2],
[76,6,4,0],
[76,6,4,1],
[76,6,4,2],
[76,7,3,0],
[76,7,3,1],
[76,7,3,2],
[76,7,4,0],
[76,7,4,1],
[76,7,4,2],
[76,7,4,3],
[76,7,5,0],
[76,7,5,1],
[76,7,5,2],
[76,7,5,4],
[76,7,6,0],
[76,7,6,1],
[76,7,6,2],
[76,7,6,4],
[76,8,4,0],
[76,8,4,1],
[76,8,4,2],
[76,8,7,0],
[76,8,7,1],
[76,8,7,2],
[76,8,7,4],
[76,10,3,0],
[76,10,3,1],
[76,10,3,2],
[76,10,4,0],
[76,10,4,1],
[76,10,4,2],
[76,10,4,3],
[76,10,5,0],
[76,10,5,1],
[76,10,5,2],
[76,10,5,4],
[76,10,6,0],
[76,10,6,1],
[76,10,6,2],
[76,10,6,4],
[76,10,7,0],
[76,10,7,1],
[76,10,7,2],
[76,10,7,3],
[76,10,7,4],
[76,10,7,5],
[76,10,7,6],
[76,10,8,0],
[76,10,8,1],
[76,10,8,2],
[76,10,8,4],
[76,10,8,7],
[76,11,3,0],
[76,11,3,1],
[76,11,3,2],
[76,11,4,0],
[76,11,4,1],
[76,11,4,2],
[76,11,4,3],
[76,11,5,0],
[76,11,5,1],
[76,11,5,2],
[76,11,5,4],
[76,11,6,0],
[76,11,6,1],
[76,11,6,2],
[76,11,6,4],
[76,11,7,0],
[76,11,7,1],
[76,11,7,2],
[76,11,7,3],
[76,11,7,4],
[76,11,7,5],
[76,11,7,6],
[76,11,8,0],
[76,11,8,1],
[76,11,8,2],
[76,11,8,4],
[76,11,8,7],
[76,11,10,0],
[76,11,10,1],
[76,11,10,2],
[76,11,10,3],
[76,11,10,4],
[76,11,10,5],
[76,11,10,6],
[76,11,10,7],
[76,11,10,8],
[76,12,3,0],
[76,12,3,1],
[76,12,3,2],
[76,12,4,0],
[76,12,4,1],
[76,12,4,2],
[76,12,4,3],
[76,12,5,0],
[76,12,5,1],
[76,12,5,2],
[76,12,5,4],
[76,12,6,0],
[76,12,6,1],
[76,12,6,2],
[76,12,6,4],
[76,12,7,0],
[76,12,7,1],
[76,12,7,2],
[76,12,7,3],
[76,12,7,4],
[76,12,7,5],
[76,12,7,6],
[76,12,8,0],
[76,12,8,1],
[76,12,8,2],
[76,12,8,4],
[76,12,8,7],
[76,12,10,0],
[76,12,10,1],
[76,12,10,2],
[76,12,10,3],
[76,12,10,4],
[76,12,10,5],
[76,12,10,6],
[76,12,10,7],
[76,12,10,8],
[76,13,3,0],
[76,13,3,1],
[76,13,3,2],
[76,13,4,0],
[76,13,4,1],
[76,13,4,2],
[76,13,4,3],
[76,13,5,0],
[76,13,5,1],
[76,13,5,2],
[76,13,5,4],
[76,13,6,0],
[76,13,6,1],
[76,13,6,2],
[76,13,6,4],
[76,13,7,0],
[76,13,7,1],
[76,13,7,2],
[76,13,7,3],
[76,13,7,4],
[76,13,7,5],
[76,13,7,6],
[76,13,8,0],
[76,13,8,1],
[76,13,8,2],
[76,13,8,4],
[76,13,8,7],
[76,13,10,0],
[76,13,10,1],
[76,13,10,2],
[76,13,10,3],
[76,13,10,4],
[76,13,10,5],
[76,13,10,6],
[76,13,10,7],
[76,13,10,8],
[76,13,11,0],
[76,13,11,1],
[76,13,11,2],
[76,13,11,3],
[76,13,11,4],
[76,13,11,5],
[76,13,11,6],
[76,13,11,7],
[76,13,11,8],
[76,13,11,10],
[76,13,12,0],
[76,13,12,1],
[76,13,12,2],
[76,13,12,3],
[76,13,12,4],
[76,13,12,5],
[76,13,12,6],
[76,13,12,7],
[76,13,12,8],
[76,13,12,10],
[76,14,3,0],
[76,14,3,1],
[76,14,3,2],
[76,14,4,0],
[76,14,4,1],
[76,14,4,2],
[76,14,4,3],
[76,14,5,0],
[76,14,5,1],
[76,14,5,2],
[76,14,5,4],
[76,14,6,0],
[76,14,6,1],
[76,14,6,2],
[76,14,6,4],
[76,14,7,0],
[76,14,7,1],
[76,14,7,2],
[76,14,7,3],
[76,14,7,4],
[76,14,7,5],
[76,14,7,6],
[76,14,8,0],
[76,14,8,1],
[76,14,8,2],
[76,14,8,4],
[76,14,8,7],
[76,14,10,0],
[76,14,10,1],
[76,14,10,2],
[76,14,10,3],
[76,14,10,4],
[76,14,10,5],
[76,14,10,6],
[76,14,10,7],
[76,14,10,8],
[76,14,11,0],
[76,14,11,1],
[76,14,11,2],
[76,14,11,3],
[76,14,11,4],
[76,14,11,5],
[76,14,11,6],
[76,14,11,7],
[76,14,11,8],
[76,14,11,10],
[76,14,12,0],
[76,14,12,1],
[76,14,12,2],
[76,14,12,3],
[76,14,12,4],
[76,14,12,5],
[76,14,12,6],
[76,14,12,7],
[76,14,12,8],
[76,14,12,10],
[76,16,3,0],
[76,16,3,1],
[76,16,3,2],
[76,16,4,0],
[76,16,4,1],
[76,16,4,2],
[76,16,4,3],
[76,16,5,0],
[76,16,5,1],
[76,16,5,2],
[76,16,5,4],
[76,16,6,0],
[76,16,6,1],
[76,16,6,2],
[76,16,6,4],
[76,16,7,0],
[76,16,7,1],
[76,16,7,2],
[76,16,7,3],
[76,16,7,4],
[76,16,7,5],
[76,16,7,6],
[76,16,8,0],
[76,16,8,1],
[76,16,8,2],
[76,16,8,4],
[76,16,8,7],
[76,16,10,0],
[76,16,10,1],
[76,16,10,2],
[76,16,10,3],
[76,16,10,4],
[76,16,10,5],
[76,16,10,6],
[76,16,10,7],
[76,16,10,8],
[76,16,11,0],
[76,16,11,1],
[76,16,11,2],
[76,16,11,3],
[76,16,11,4],
[76,16,11,5],
[76,16,11,6],
[76,16,11,7],
[76,16,11,8],
[76,16,11,10],
[76,16,12,0],
[76,16,12,1],
[76,16,12,2],
[76,16,12,3],
[76,16,12,4],
[76,16,12,5],
[76,16,12,6],
[76,16,12,7],
[76,16,12,8],
[76,16,12,10],
[76,16,13,0],
[76,16,13,1],
[76,16,13,2],
[76,16,13,3],
[76,16,13,4],
[76,16,13,5],
[76,16,13,6],
[76,16,13,7],
[76,16,13,8],
[76,16,13,10],
[76,16,13,11],
[76,16,13,12],
[76,16,14,0],
[76,16,14,1],
[76,16,14,2],
[76,16,14,3],
[76,16,14,4],
[76,16,14,5],
[76,16,14,6],
[76,16,14,7],
[76,16,14,8],
[76,16,14,10],
[76,16,14,11],
[76,16,14,12],
[76,17,3,0],
[76,17,3,1],
[76,17,3,2],
[76,17,4,0],
[76,17,4,1],
[76,17,4,2],
[76,17,4,3],
[76,17,5,0],
[76,17,5,1],
[76,17,5,2],
[76,17,5,4],
[76,17,6,0],
[76,17,6,1],
[76,17,6,2],
[76,17,6,4],
[76,17,7,0],
[76,17,7,1],
[76,17,7,2],
[76,17,7,3],
[76,17,7,4],
[76,17,7,5],
[76,17,7,6],
[76,17,8,0],
[76,17,8,1],
[76,17,8,2],
[76,17,8,4],
[76,17,8,7],
[76,17,10,0],
[76,17,10,1],
[76,17,10,2],
[76,17,10,3],
[76,17,10,4],
[76,17,10,5],
[76,17,10,6],
[76,17,10,7],
[76,17,10,8],
[76,17,11,0],
[76,17,11,1],
[76,17,11,2],
[76,17,11,3],
[76,17,11,4],
[76,17,11,5],
[76,17,11,6],
[76,17,11,7],
[76,17,11,8],
[76,17,11,10],
[76,17,12,0],
[76,17,12,1],
[76,17,12,2],
[76,17,12,3],
[76,17,12,4],
[76,17,12,5],
[76,17,12,6],
[76,17,12,7],
[76,17,12,8],
[76,17,12,10],
[76,17,13,0],
[76,17,13,1],
[76,17,13,2],
[76,17,13,3],
[76,17,13,4],
[76,17,13,5],
[76,17,13,6],
[76,17,13,7],
[76,17,13,8],
[76,17,13,10],
[76,17,13,11],
[76,17,13,12],
[76,17,14,0],
[76,17,14,1],
[76,17,14,2],
[76,17,14,3],
[76,17,14,4],
[76,17,14,5],
[76,17,14,6],
[76,17,14,7],
[76,17,14,8],
[76,17,14,10],
[76,17,14,11],
[76,17,14,12],
[76,17,16,0],
[76,17,16,1],
[76,17,16,2],
[76,17,16,3],
[76,17,16,4],
[76,17,16,5],
[76,17,16,6],
[76,17,16,7],
[76,17,16,8],
[76,17,16,10],
[76,17,16,11],
[76,17,16,12],
[76,17,16,13],
[76,17,16,14],
[76,19,3,0],
[76,19,3,1],
[76,19,3,2],
[76,19,4,0],
[76,19,4,1],
[76,19,4,2],
[76,19,4,3],
[76,19,5,0],
[76,19,5,1],
[76,19,5,2],
[76,19,5,4],
[76,19,6,0],
[76,19,6,1],
[76,19,6,2],
[76,19,6,4],
[76,19,7,0],
[76,19,7,1],
[76,19,7,2],
[76,19,7,3],
[76,19,7,4],
[76,19,7,5],
[76,19,7,6],
[76,19,8,0],
[76,19,8,1],
[76,19,8,2],
[76,19,8,4],
[76,19,8,7],
[76,19,10,0],
[76,19,10,1],
[76,19,10,2],
[76,19,10,3],
[76,19,10,4],
[76,19,10,5],
[76,19,10,6],
[76,19,10,7],
[76,19,10,8],
[76,19,11,0],
[76,19,11,1],
[76,19,11,2],
[76,19,11,3],
[76,19,11,4],
[76,19,11,5],
[76,19,11,6],
[76,19,11,7],
[76,19,11,8],
[76,19,11,10],
[76,19,12,0],
[76,19,12,1],
[76,19,12,2],
[76,19,12,3],
[76,19,12,4],
[76,19,12,5],
[76,19,12,6],
[76,19,12,7],
[76,19,12,8],
[76,19,12,10],
[76,19,16,0],
[76,19,16,1],
[76,19,16,2],
[76,19,16,3],
[76,19,16,4],
[76,19,16,5],
[76,19,16,6],
[76,19,16,7],
[76,19,16,8],
[76,19,16,10],
[76,19,16,11],
[76,19,16,12],
[76,19,17,0],
[76,19,17,1],
[76,19,17,2],
[76,19,17,3],
[76,19,17,4],
[76,19,17,5],
[76,19,17,6],
[76,19,17,7],
[76,19,17,8],
[76,19,17,10],
[76,19,17,11],
[76,19,17,12],
[76,19,17,16],
[76,20,3,0],
[76,20,3,1],
[76,20,3,2],
[76,20,4,0],
[76,20,4,1],
[76,20,4,2],
[76,20,4,3],
[76,20,5,0],
[76,20,5,1],
[76,20,5,2],
[76,20,5,4],
[76,20,6,0],
[76,20,6,1],
[76,20,6,2],
[76,20,6,4],
[76,20,7,0],
[76,20,7,1],
[76,20,7,2],
[76,20,7,3],
[76,20,7,4],
[76,20,7,5],
[76,20,7,6],
[76,20,8,0],
[76,20,8,1],
[76,20,8,2],
[76,20,8,4],
[76,20,8,7],
[76,20,11,0],
[76,20,11,1],
[76,20,11,2],
[76,20,11,3],
[76,20,11,4],
[76,20,11,5],
[76,20,11,6],
[76,20,11,7],
[76,20,11,8],
[76,20,12,0],
[76,20,12,1],
[76,20,12,2],
[76,20,12,3],
[76,20,12,4],
[76,20,12,5],
[76,20,12,6],
[76,20,12,7],
[76,20,12,8],
[76,20,13,0],
[76,20,13,1],
[76,20,13,2],
[76,20,13,3],
[76,20,13,4],
[76,20,13,5],
[76,20,13,6],
[76,20,13,7],
[76,20,13,8],
[76,20,13,11],
[76,20,13,12],
[76,20,14,0],
[76,20,14,1],
[76,20,14,2],
[76,20,14,3],
[76,20,14,4],
[76,20,14,5],
[76,20,14,6],
[76,20,14,7],
[76,20,14,8],
[76,20,14,11],
[76,20,14,12],
[76,20,16,0],
[76,20,16,1],
[76,20,16,2],
[76,20,16,3],
[76,20,16,4],
[76,20,16,5],
[76,20,16,6],
[76,20,16,7],
[76,20,16,8],
[76,20,16,11],
[76,20,16,12],
[76,20,16,13],
[76,20,16,14],
[76,20,17,0],
[76,20,17,1],
[76,20,17,2],
[76,20,17,3],
[76,20,17,4],
[76,20,17,5],
[76,20,17,6],
[76,20,17,7],
[76,20,17,8],
[76,20,17,11],
[76,20,17,12],
[76,20,17,13],
[76,20,17,14],
[76,20,17,16],
[76,20,19,0],
[76,20,19,1],
[76,20,19,2],
[76,20,19,3],
[76,20,19,4],
[76,20,19,5],
[76,20,19,6],
[76,20,19,7],
[76,20,19,8],
[76,20,19,11],
[76,20,19,12],
[76,20,19,16],
[76,20,19,17],
[76,21,4,0],
[76,21,4,1],
[76,21,4,2],
[76,21,7,0],
[76,21,7,1],
[76,21,7,2],
[76,21,7,4],
[76,21,10,0],
[76,21,10,1],
[76,21,10,2],
[76,21,10,4],
[76,21,10,7],
[76,21,11,0],
[76,21,11,1],
[76,21,11,2],
[76,21,11,4],
[76,21,11,7],
[76,21,11,10],
[76,21,12,0],
[76,21,12,1],
[76,21,12,2],
[76,21,12,4],
[76,21,12,7],
[76,21,12,10],
[76,21,13,0],
[76,21,13,1],
[76,21,13,2],
[76,21,13,4],
[76,21,13,7],
[76,21,13,10],
[76,21,13,11],
[76,21,13,12],
[76,21,14,0],
[76,21,14,1],
[76,21,14,2],
[76,21,14,4],
[76,21,14,7],
[76,21,14,10],
[76,21,14,11],
[76,21,14,12],
[76,21,16,0],
[76,21,16,1],
[76,21,16,2],
[76,21,16,4],
[76,21,16,7],
[76,21,16,10],
[76,21,16,11],
[76,21,16,12],
[76,21,16,13],
[76,21,16,14],
[76,21,17,0],
[76,21,17,1],
[76,21,17,2],
[76,21,17,4],
[76,21,17,7],
[76,21,17,10],
[76,21,17,11],
[76,21,17,12],
[76,21,17,13],
[76,21,17,14],
[76,21,17,16],
[76,21,19,0],
[76,21,19,1],
[76,21,19,2],
[76,21,19,4],
[76,21,19,7],
[76,21,19,10],
[76,21,19,11],
[76,21,19,12],
[76,21,19,16],
[76,21,19,17],
[76,21,20,0],
[76,21,20,1],
[76,21,20,2],
[76,21,20,4],
[76,21,20,7],
[76,21,20,11],
[76,21,20,12],
[76,21,20,13],
[76,21,20,14],
[76,21,20,16],
[76,21,20,17],
[76,21,20,19],
[76,22,3,0],
[76,22,3,1],
[76,22,3,2],
[76,22,4,0],
[76,22,4,1],
[76,22,4,2],
[76,22,4,3],
[76,22,5,0],
[76,22,5,1],
[76,22,5,2],
[76,22,5,4],
[76,22,6,0],
[76,22,6,1],
[76,22,6,2],
[76,22,6,4],
[76,22,7,0],
[76,22,7,1],
[76,22,7,2],
[76,22,7,3],
[76,22,7,4],
[76,22,7,5],
[76,22,7,6],
[76,22,8,0],
[76,22,8,1],
[76,22,8,2],
[76,22,8,4],
[76,22,8,7],
[76,22,11,0],
[76,22,11,1],
[76,22,11,2],
[76,22,11,3],
[76,22,11,4],
[76,22,11,5],
[76,22,11,6],
[76,22,11,7],
[76,22,11,8],
[76,22,12,0],
[76,22,12,1],
[76,22,12,2],
[76,22,12,3],
[76,22,12,4],
[76,22,12,5],
[76,22,12,6],
[76,22,12,7],
[76,22,12,8],
[76,22,13,0],
[76,22,13,1],
[76,22,13,2],
[76,22,13,3],
[76,22,13,4],
[76,22,13,5],
[76,22,13,6],
[76,22,13,7],
[76,22,13,8],
[76,22,13,11],
[76,22,13,12],
[76,22,14,0],
[76,22,14,1],
[76,22,14,2],
[76,22,14,3],
[76,22,14,4],
[76,22,14,5],
[76,22,14,6],
[76,22,14,7],
[76,22,14,8],
[76,22,14,11],
[76,22,14,12],
[76,22,16,0],
[76,22,16,1],
[76,22,16,2],
[76,22,16,3],
[76,22,16,4],
[76,22,16,5],
[76,22,16,6],
[76,22,16,7],
[76,22,16,8],
[76,22,16,11],
[76,22,16,12],
[76,22,16,13],
[76,22,16,14],
[76,22,17,0],
[76,22,17,1],
[76,22,17,2],
[76,22,17,3],
[76,22,17,4],
[76,22,17,5],
[76,22,17,6],
[76,22,17,7],
[76,22,17,8],
[76,22,17,11],
[76,22,17,12],
[76,22,17,13],
[76,22,17,14],
[76,22,17,16],
[76,22,19,0],
[76,22,19,1],
[76,22,19,2],
[76,22,19,3],
[76,22,19,4],
[76,22,19,5],
[76,22,19,6],
[76,22,19,7],
[76,22,19,8],
[76,22,19,11],
[76,22,19,12],
[76,22,19,16],
[76,22,19,17],
[76,22,21,0],
[76,22,21,1],
[76,22,21,2],
[76,22,21,4],
[76,22,21,7],
[76,22,21,11],
[76,22,21,12],
[76,22,21,13],
[76,22,21,14],
[76,22,21,16],
[76,22,21,17],
[76,23,3,0],
[76,23,3,1],
[76,23,3,2],
[76,23,4,0],
[76,23,4,1],
[76,23,4,2],
[76,23,4,3],
[76,23,5,0],
[76,23,5,1],
[76,23,5,2],
[76,23,5,4],
[76,23,6,0],
[76,23,6,1],
[76,23,6,2],
[76,23,6,4],
[76,23,7,0],
[76,23,7,1],
[76,23,7,2],
[76,23,7,3],
[76,23,7,4],
[76,23,7,5],
[76,23,7,6],
[76,23,8,0],
[76,23,8,1],
[76,23,8,2],
[76,23,8,4],
[76,23,8,7],
[76,23,10,0],
[76,23,10,1],
[76,23,10,2],
[76,23,10,3],
[76,23,10,4],
[76,23,10,5],
[76,23,10,6],
[76,23,10,7],
[76,23,10,8],
[76,23,11,0],
[76,23,11,1],
[76,23,11,2],
[76,23,11,3],
[76,23,11,4],
[76,23,11,5],
[76,23,11,6],
[76,23,11,7],
[76,23,11,8],
[76,23,11,10],
[76,23,12,0],
[76,23,12,1],
[76,23,12,2],
[76,23,12,3],
[76,23,12,4],
[76,23,12,5],
[76,23,12,6],
[76,23,12,7],
[76,23,12,8],
[76,23,12,10],
[76,23,13,0],
[76,23,13,1],
[76,23,13,2],
[76,23,13,3],
[76,23,13,4],
[76,23,13,5],
[76,23,13,6],
[76,23,13,7],
[76,23,13,8],
[76,23,13,10],
[76,23,13,11],
[76,23,13,12],
[76,23,14,0],
[76,23,14,1],
[76,23,14,2],
[76,23,14,3],
[76,23,14,4],
[76,23,14,5],
[76,23,14,6],
[76,23,14,7],
[76,23,14,8],
[76,23,14,10],
[76,23,14,11],
[76,23,14,12],
[76,23,16,0],
[76,23,16,1],
[76,23,16,2],
[76,23,16,3],
[76,23,16,4],
[76,23,16,5],
[76,23,16,6],
[76,23,16,7],
[76,23,16,8],
[76,23,16,10],
[76,23,16,11],
[76,23,16,12],
[76,23,16,13],
[76,23,16,14],
[76,23,17,0],
[76,23,17,1],
[76,23,17,2],
[76,23,17,3],
[76,23,17,4],
[76,23,17,5],
[76,23,17,6],
[76,23,17,7],
[76,23,17,8],
[76,23,17,10],
[76,23,17,11],
[76,23,17,12],
[76,23,17,13],
[76,23,17,14],
[76,23,17,16],
[76,23,19,0],
[76,23,19,1],
[76,23,19,2],
[76,23,19,3],
[76,23,19,4],
[76,23,19,5],
[76,23,19,6],
[76,23,19,7],
[76,23,19,8],
[76,23,19,10],
[76,23,19,11],
[76,23,19,12],
[76,23,19,16],
[76,23,19,17],
[76,23,20,0],
[76,23,20,1],
[76,23,20,2],
[76,23,20,3],
[76,23,20,4],
[76,23,20,5],
[76,23,20,6],
[76,23,20,7],
[76,23,20,8],
[76,23,20,11],
[76,23,20,12],
[76,23,20,13],
[76,23,20,14],
[76,23,20,16],
[76,23,20,17],
[76,23,21,0],
[76,23,21,1],
[76,23,21,2],
[76,23,21,4],
[76,23,21,7],
[76,23,21,10],
[76,23,21,11],
[76,23,21,12],
[76,23,21,13],
[76,23,21,14],
[76,23,21,16],
[76,23,21,17],
[76,23,21,19],
[76,23,21,20],
[76,23,22,0],
[76,23,22,1],
[76,23,22,2],
[76,23,22,3],
[76,23,22,4],
[76,23,22,5],
[76,23,22,6],
[76,23,22,7],
[76,23,22,8],
[76,23,22,11],
[76,23,22,12],
[76,23,22,13],
[76,23,22,14],
[76,23,22,19],
[76,23,22,21],
[76,24,3,0],
[76,24,3,1],
[76,24,3,2],
[76,24,4,0],
[76,24,4,1],
[76,24,4,2],
[76,24,4,3],
[76,24,5,0],
[76,24,5,1],
[76,24,5,2],
[76,24,5,4],
[76,24,6,0],
[76,24,6,1],
[76,24,6,2],
[76,24,6,4],
[76,24,7,0],
[76,24,7,1],
[76,24,7,2],
[76,24,7,3],
[76,24,7,4],
[76,24,7,5],
[76,24,7,6],
[76,24,8,0],
[76,24,8,1],
[76,24,8,2],
[76,24,8,4],
[76,24,8,7],
[76,24,10,0],
[76,24,10,1],
[76,24,10,2],
[76,24,10,3],
[76,24,10,4],
[76,24,10,5],
[76,24,10,6],
[76,24,10,7],
[76,24,10,8],
[76,24,11,0],
[76,24,11,1],
[76,24,11,2],
[76,24,11,3],
[76,24,11,4],
[76,24,11,5],
[76,24,11,6],
[76,24,11,7],
[76,24,11,8],
[76,24,11,10],
[76,24,12,0],
[76,24,12,1],
[76,24,12,2],
[76,24,12,3],
[76,24,12,4],
[76,24,12,5],
[76,24,12,6],
[76,24,12,7],
[76,24,12,8],
[76,24,12,10],
[76,24,13,0],
[76,24,13,1],
[76,24,13,2],
[76,24,13,3],
[76,24,13,4],
[76,24,13,5],
[76,24,13,6],
[76,24,13,7],
[76,24,13,8],
[76,24,13,10],
[76,24,13,11],
[76,24,13,12],
[76,24,14,0],
[76,24,14,1],
[76,24,14,2],
[76,24,14,3],
[76,24,14,4],
[76,24,14,5],
[76,24,14,6],
[76,24,14,7],
[76,24,14,8],
[76,24,14,10],
[76,24,14,11],
[76,24,14,12],
[76,24,17,0],
[76,24,17,1],
[76,24,17,2],
[76,24,17,3],
[76,24,17,4],
[76,24,17,5],
[76,24,17,6],
[76,24,17,7],
[76,24,17,8],
[76,24,17,10],
[76,24,17,11],
[76,24,17,12],
[76,24,17,13],
[76,24,17,14],
[76,24,19,0],
[76,24,19,1],
[76,24,19,2],
[76,24,19,3],
[76,24,19,4],
[76,24,19,5],
[76,24,19,6],
[76,24,19,7],
[76,24,19,8],
[76,24,19,10],
[76,24,19,11],
[76,24,19,12],
[76,24,19,17],
[76,24,20,0],
[76,24,20,1],
[76,24,20,2],
[76,24,20,3],
[76,24,20,4],
[76,24,20,5],
[76,24,20,6],
[76,24,20,7],
[76,24,20,8],
[76,24,20,11],
[76,24,20,12],
[76,24,20,13],
[76,24,20,14],
[76,24,20,17],
[76,24,21,0],
[76,24,21,1],
[76,24,21,2],
[76,24,21,4],
[76,24,21,7],
[76,24,21,10],
[76,24,21,11],
[76,24,21,12],
[76,24,21,13],
[76,24,21,14],
[76,24,21,17],
[76,24,21,19],
[76,24,21,20],
[76,24,22,0],
[76,24,22,1],
[76,24,22,2],
[76,24,22,3],
[76,24,22,4],
[76,24,22,5],
[76,24,22,6],
[76,24,22,7],
[76,24,22,8],
[76,24,22,11],
[76,24,22,12],
[76,24,22,13],
[76,24,22,14],
[76,24,22,19],
[76,24,22,21],
[76,24,23,0],
[76,24,23,1],
[76,24,23,2],
[76,24,23,3],
[76,24,23,4],
[76,24,23,5],
[76,24,23,6],
[76,24,23,7],
[76,24,23,8],
[76,24,23,10],
[76,24,23,11],
[76,24,23,12],
[76,24,23,13],
[76,24,23,14],
[76,24,23,17],
[76,24,23,19],
[76,24,23,20],
[76,24,23,21],
[76,24,23,22],
[76,25,3,0],
[76,25,3,1],
[76,25,3,2],
[76,25,4,0],
[76,25,4,1],
[76,25,4,2],
[76,25,4,3],
[76,25,5,0],
[76,25,5,1],
[76,25,5,2],
[76,25,5,4],
[76,25,6,0],
[76,25,6,1],
[76,25,6,2],
[76,25,6,4],
[76,25,8,0],
[76,25,8,1],
[76,25,8,2],
[76,25,8,4],
[76,25,10,0],
[76,25,10,1],
[76,25,10,2],
[76,25,10,3],
[76,25,10,4],
[76,25,10,5],
[76,25,10,6],
[76,25,10,8],
[76,25,11,0],
[76,25,11,1],
[76,25,11,2],
[76,25,11,3],
[76,25,11,4],
[76,25,11,5],
[76,25,11,6],
[76,25,11,8],
[76,25,11,10],
[76,25,12,0],
[76,25,12,1],
[76,25,12,2],
[76,25,12,3],
[76,25,12,4],
[76,25,12,5],
[76,25,12,6],
[76,25,12,8],
[76,25,12,10],
[76,25,13,0],
[76,25,13,1],
[76,25,13,2],
[76,25,13,3],
[76,25,13,4],
[76,25,13,5],
[76,25,13,6],
[76,25,13,8],
[76,25,13,10],
[76,25,13,11],
[76,25,13,12],
[76,25,14,0],
[76,25,14,1],
[76,25,14,2],
[76,25,14,3],
[76,25,14,4],
[76,25,14,5],
[76,25,14,6],
[76,25,14,8],
[76,25,14,10],
[76,25,14,11],
[76,25,14,12],
[76,25,16,0],
[76,25,16,1],
[76,25,16,2],
[76,25,16,3],
[76,25,16,4],
[76,25,16,5],
[76,25,16,6],
[76,25,16,8],
[76,25,16,10],
[76,25,16,11],
[76,25,16,12],
[76,25,16,13],
[76,25,16,14],
[76,25,17,0],
[76,25,17,1],
[76,25,17,2],
[76,25,17,3],
[76,25,17,4],
[76,25,17,5],
[76,25,17,6],
[76,25,17,8],
[76,25,17,10],
[76,25,17,11],
[76,25,17,12],
[76,25,17,13],
[76,25,17,14],
[76,25,17,16],
[76,25,19,0],
[76,25,19,1],
[76,25,19,2],
[76,25,19,3],
[76,25,19,4],
[76,25,19,5],
[76,25,19,6],
[76,25,19,8],
[76,25,19,10],
[76,25,19,11],
[76,25,19,12],
[76,25,19,16],
[76,25,19,17],
[76,25,20,0],
[76,25,20,1],
[76,25,20,2],
[76,25,20,3],
[76,25,20,4],
[76,25,20,5],
[76,25,20,6],
[76,25,20,8],
[76,25,20,11],
[76,25,20,12],
[76,25,20,13],
[76,25,20,14],
[76,25,20,19],
[76,25,21,0],
[76,25,21,1],
[76,25,21,2],
[76,25,21,4],
[76,25,21,10],
[76,25,21,11],
[76,25,21,12],
[76,25,21,13],
[76,25,21,14],
[76,25,21,16],
[76,25,21,17],
[76,25,21,19],
[76,25,21,20],
[76,25,22,0],
[76,25,22,1],
[76,25,22,2],
[76,25,22,3],
[76,25,22,4],
[76,25,22,5],
[76,25,22,6],
[76,25,22,8],
[76,25,22,11],
[76,25,22,12],
[76,25,22,13],
[76,25,22,16],
[76,25,22,17],
[76,25,22,19],
[76,25,22,21],
[76,25,23,0],
[76,25,23,1],
[76,25,23,2],
[76,25,23,3],
[76,25,23,4],
[76,25,23,5],
[76,25,23,6],
[76,25,23,8],
[76,25,23,10],
[76,25,23,11],
[76,25,23,14],
[76,25,23,16],
[76,25,23,17],
[76,25,23,19],
[76,25,23,20],
[76,25,23,21],
[76,25,23,22],
[76,25,24,0],
[76,25,24,1],
[76,25,24,2],
[76,25,24,3],
[76,25,24,4],
[76,25,24,5],
[76,25,24,6],
[76,25,24,8],
[76,25,24,10],
[76,25,24,11],
[76,25,24,14],
[76,25,24,17],
[76,25,24,19],
[76,25,24,20],
[76,25,24,21],
[76,25,24,22],
[76,25,24,23],
[76,27,3,0],
[76,27,3,1],
[76,27,3,2],
[76,27,4,0],
[76,27,4,1],
[76,27,4,2],
[76,27,4,3],
[76,27,5,0],
[76,27,5,1],
[76,27,5,2],
[76,27,5,4],
[76,27,6,0],
[76,27,6,1],
[76,27,6,2],
[76,27,6,4],
[76,27,7,0],
[76,27,7,1],
[76,27,7,2],
[76,27,7,3],
[76,27,7,4],
[76,27,7,5],
[76,27,7,6],
[76,27,8,0],
[76,27,8,1],
[76,27,8,2],
[76,27,8,4],
[76,27,8,7],
[76,27,11,0],
[76,27,11,1],
[76,27,11,2],
[76,27,11,3],
[76,27,11,4],
[76,27,11,5],
[76,27,11,6],
[76,27,11,7],
[76,27,11,8],
[76,27,12,0],
[76,27,12,1],
[76,27,12,2],
[76,27,12,3],
[76,27,12,4],
[76,27,12,5],
[76,27,12,6],
[76,27,12,7],
[76,27,12,8],
[76,27,13,0],
[76,27,13,1],
[76,27,13,2],
[76,27,13,3],
[76,27,13,4],
[76,27,13,5],
[76,27,13,6],
[76,27,13,7],
[76,27,13,8],
[76,27,13,11],
[76,27,13,12],
[76,27,14,0],
[76,27,14,1],
[76,27,14,2],
[76,27,14,3],
[76,27,14,4],
[76,27,14,5],
[76,27,14,6],
[76,27,14,7],
[76,27,14,8],
[76,27,14,11],
[76,27,14,12],
[76,27,16,0],
[76,27,16,1],
[76,27,16,2],
[76,27,16,3],
[76,27,16,4],
[76,27,16,5],
[76,27,16,6],
[76,27,16,7],
[76,27,16,8],
[76,27,16,11],
[76,27,16,12],
[76,27,16,13],
[76,27,16,14],
[76,27,17,0],
[76,27,17,1],
[76,27,17,2],
[76,27,17,3],
[76,27,17,4],
[76,27,17,5],
[76,27,17,6],
[76,27,17,7],
[76,27,17,8],
[76,27,17,11],
[76,27,17,12],
[76,27,17,13],
[76,27,17,14],
[76,27,17,16],
[76,27,19,0],
[76,27,19,1],
[76,27,19,2],
[76,27,19,3],
[76,27,19,4],
[76,27,19,5],
[76,27,19,6],
[76,27,19,7],
[76,27,19,8],
[76,27,19,11],
[76,27,19,12],
[76,27,19,16],
[76,27,19,17],
[76,27,21,0],
[76,27,21,1],
[76,27,21,2],
[76,27,21,4],
[76,27,21,7],
[76,27,21,11],
[76,27,21,14],
[76,27,21,16],
[76,27,21,17],
[76,27,21,19],
[76,27,23,0],
[76,27,23,1],
[76,27,23,2],
[76,27,23,3],
[76,27,23,4],
[76,27,23,5],
[76,27,23,6],
[76,27,23,7],
[76,27,23,11],
[76,27,23,12],
[76,27,23,13],
[76,27,23,14],
[76,27,23,16],
[76,27,23,17],
[76,27,23,19],
[76,27,23,21],
[76,27,24,0],
[76,27,24,1],
[76,27,24,2],
[76,27,24,3],
[76,27,24,4],
[76,27,24,5],
[76,27,24,6],
[76,27,24,7],
[76,27,24,11],
[76,27,24,12],
[76,27,24,13],
[76,27,24,14],
[76,27,24,17],
[76,27,24,19],
[76,27,24,21],
[76,27,24,23],
[76,27,25,0],
[76,27,25,1],
[76,27,25,2],
[76,27,25,3],
[76,27,25,4],
[76,27,25,6],
[76,27,25,8],
[76,27,25,11],
[76,27,25,12],
[76,27,25,13],
[76,27,25,14],
[76,27,25,16],
[76,27,25,17],
[76,27,25,19],
[76,27,25,21],
[76,28,3,0],
[76,28,3,1],
[76,28,3,2],
[76,28,4,0],
[76,28,4,1],
[76,28,4,2],
[76,28,4,3],
[76,28,5,0],
[76,28,5,1],
[76,28,5,2],
[76,28,5,4],
[76,28,6,0],
[76,28,6,1],
[76,28,6,2],
[76,28,6,4],
[76,28,8,0],
[76,28,8,1],
[76,28,8,2],
[76,28,8,4],
[76,28,10,0],
[76,28,10,1],
[76,28,10,2],
[76,28,10,3],
[76,28,10,4],
[76,28,10,5],
[76,28,10,6],
[76,28,10,8],
[76,28,11,0],
[76,28,11,1],
[76,28,11,2],
[76,28,11,3],
[76,28,11,4],
[76,28,11,5],
[76,28,11,6],
[76,28,11,8],
[76,28,11,10],
[76,28,12,0],
[76,28,12,1],
[76,28,12,2],
[76,28,12,3],
[76,28,12,4],
[76,28,12,5],
[76,28,12,6],
[76,28,12,8],
[76,28,12,10],
[76,28,13,0],
[76,28,13,1],
[76,28,13,2],
[76,28,13,3],
[76,28,13,4],
[76,28,13,5],
[76,28,13,6],
[76,28,13,8],
[76,28,13,10],
[76,28,13,11],
[76,28,13,12],
[76,28,14,0],
[76,28,14,1],
[76,28,14,2],
[76,28,14,3],
[76,28,14,4],
[76,28,14,5],
[76,28,14,6],
[76,28,14,8],
[76,28,14,10],
[76,28,14,11],
[76,28,14,12],
[76,28,16,0],
[76,28,16,1],
[76,28,16,2],
[76,28,16,3],
[76,28,16,4],
[76,28,16,5],
[76,28,16,6],
[76,28,16,8],
[76,28,16,10],
[76,28,16,11],
[76,28,16,12],
[76,28,16,13],
[76,28,16,14],
[76,28,17,0],
[76,28,17,1],
[76,28,17,2],
[76,28,17,3],
[76,28,17,4],
[76,28,17,5],
[76,28,17,6],
[76,28,17,8],
[76,28,17,10],
[76,28,17,11],
[76,28,17,12],
[76,28,17,13],
[76,28,17,14],
[76,28,19,0],
[76,28,19,1],
[76,28,19,2],
[76,28,19,3],
[76,28,19,4],
[76,28,19,5],
[76,28,19,6],
[76,28,19,8],
[76,28,19,10],
[76,28,19,11],
[76,28,19,12],
[76,28,19,16],
[76,28,19,17],
[76,28,20,0],
[76,28,20,1],
[76,28,20,2],
[76,28,20,3],
[76,28,20,4],
[76,28,20,5],
[76,28,20,6],
[76,28,20,8],
[76,28,20,11],
[76,28,20,14],
[76,28,20,16],
[76,28,20,17],
[76,28,20,19],
[76,28,21,0],
[76,28,21,1],
[76,28,21,2],
[76,28,21,4],
[76,28,21,12],
[76,28,21,13],
[76,28,21,14],
[76,28,21,16],
[76,28,21,17],
[76,28,21,19],
[76,28,21,20],
[76,28,22,0],
[76,28,22,1],
[76,28,22,2],
[76,28,22,3],
[76,28,22,4],
[76,28,22,5],
[76,28,22,6],
[76,28,22,11],
[76,28,22,12],
[76,28,22,13],
[76,28,22,14],
[76,28,22,16],
[76,28,22,17],
[76,28,22,19],
[76,28,22,21],
[76,28,23,0],
[76,28,23,1],
[76,28,23,2],
[76,28,23,3],
[76,28,23,4],
[76,28,23,5],
[76,28,23,8],
[76,28,23,10],
[76,28,23,11],
[76,28,23,12],
[76,28,23,13],
[76,28,23,14],
[76,28,23,16],
[76,28,23,17],
[76,28,23,19],
[76,28,23,20],
[76,28,23,21],
[76,28,23,22],
[76,28,24,0],
[76,28,24,1],
[76,28,24,2],
[76,28,24,3],
[76,28,24,4],
[76,28,24,5],
[76,28,24,8],
[76,28,24,10],
[76,28,24,11],
[76,28,24,12],
[76,28,24,13],
[76,28,24,14],
[76,28,24,17],
[76,28,24,19],
[76,28,24,20],
[76,28,24,21],
[76,28,24,22],
[76,28,24,23],
[76,28,27,0],
[76,28,27,1],
[76,28,27,2],
[76,28,27,3],
[76,28,27,4],
[76,28,27,5],
[76,28,27,6],
[76,28,27,8],
[76,28,27,11],
[76,28,27,12],
[76,28,27,13],
[76,28,27,14],
[76,28,27,16],
[76,28,27,17],
[76,28,27,19],
[76,29,3,0],
[76,29,3,1],
[76,29,3,2],
[76,29,4,0],
[76,29,4,1],
[76,29,4,2],
[76,29,4,3],
[76,29,5,0],
[76,29,5,1],
[76,29,5,2],
[76,29,5,4],
[76,29,6,0],
[76,29,6,1],
[76,29,6,2],
[76,29,6,4],
[76,29,7,0],
[76,29,7,1],
[76,29,7,2],
[76,29,7,3],
[76,29,7,4],
[76,29,7,5],
[76,29,7,6],
[76,29,8,0],
[76,29,8,1],
[76,29,8,2],
[76,29,8,4],
[76,29,8,7],
[76,29,10,0],
[76,29,10,1],
[76,29,10,2],
[76,29,10,3],
[76,29,10,4],
[76,29,10,5],
[76,29,10,6],
[76,29,10,7],
[76,29,10,8],
[76,29,13,0],
[76,29,13,1],
[76,29,13,2],
[76,29,13,3],
[76,29,13,4],
[76,29,13,5],
[76,29,13,6],
[76,29,13,7],
[76,29,13,8],
[76,29,13,10],
[76,29,14,0],
[76,29,14,1],
[76,29,14,2],
[76,29,14,3],
[76,29,14,4],
[76,29,14,5],
[76,29,14,6],
[76,29,14,7],
[76,29,14,8],
[76,29,14,10],
[76,29,16,0],
[76,29,16,1],
[76,29,16,2],
[76,29,16,3],
[76,29,16,4],
[76,29,16,5],
[76,29,16,6],
[76,29,16,7],
[76,29,16,8],
[76,29,16,10],
[76,29,16,13],
[76,29,16,14],
[76,29,17,0],
[76,29,17,1],
[76,29,17,2],
[76,29,17,3],
[76,29,17,4],
[76,29,17,5],
[76,29,17,6],
[76,29,17,7],
[76,29,17,8],
[76,29,17,10],
[76,29,17,13],
[76,29,17,14],
[76,29,17,16],
[76,29,19,0],
[76,29,19,1],
[76,29,19,2],
[76,29,19,3],
[76,29,19,4],
[76,29,19,5],
[76,29,19,6],
[76,29,19,7],
[76,29,19,8],
[76,29,19,10],
[76,29,19,16],
[76,29,19,17],
[76,29,20,0],
[76,29,20,1],
[76,29,20,2],
[76,29,20,3],
[76,29,20,4],
[76,29,20,5],
[76,29,20,6],
[76,29,20,7],
[76,29,20,8],
[76,29,20,13],
[76,29,20,14],
[76,29,20,16],
[76,29,20,17],
[76,29,20,19],
[76,29,21,0],
[76,29,21,1],
[76,29,21,2],
[76,29,21,4],
[76,29,21,7],
[76,29,21,10],
[76,29,21,13],
[76,29,21,14],
[76,29,21,16],
[76,29,21,17],
[76,29,21,19],
[76,29,21,20],
[76,29,22,0],
[76,29,22,1],
[76,29,22,2],
[76,29,22,3],
[76,29,22,4],
[76,29,22,5],
[76,29,22,8],
[76,29,22,13],
[76,29,22,14],
[76,29,22,16],
[76,29,22,17],
[76,29,22,19],
[76,29,22,21],
[76,29,23,0],
[76,29,23,1],
[76,29,23,2],
[76,29,23,3],
[76,29,23,4],
[76,29,23,6],
[76,29,23,7],
[76,29,23,8],
[76,29,23,10],
[76,29,23,13],
[76,29,23,14],
[76,29,23,16],
[76,29,23,17],
[76,29,23,19],
[76,29,23,20],
[76,29,23,21],
[76,29,23,22],
[76,29,24,0],
[76,29,24,1],
[76,29,24,2],
[76,29,24,3],
[76,29,24,4],
[76,29,24,6],
[76,29,24,7],
[76,29,24,8],
[76,29,24,10],
[76,29,24,13],
[76,29,24,14],
[76,29,24,17],
[76,29,24,19],
[76,29,24,20],
[76,29,24,21],
[76,29,24,22],
[76,29,25,3],
[76,29,25,4],
[76,29,25,5],
[76,29,25,6],
[76,29,25,8],
[76,29,25,10],
[76,29,25,13],
[76,29,25,14],
[76,29,25,16],
[76,29,25,17],
[76,29,25,19],
[76,29,25,20],
[76,29,27,0],
[76,29,27,1],
[76,29,27,2],
[76,29,27,3],
[76,29,27,4],
[76,29,27,5],
[76,29,27,6],
[76,29,27,7],
[76,29,27,8],
[76,29,27,13],
[76,29,27,14],
[76,29,27,16],
[76,29,27,17],
[76,29,28,0],
[76,29,28,1],
[76,29,28,2],
[76,29,28,3],
[76,29,28,4],
[76,29,28,5],
[76,29,28,6],
[76,29,28,8],
[76,29,28,10],
[76,29,28,13],
[76,29,28,14],
[76,29,28,16],
[76,29,28,17],
[76,30,3,0],
[76,30,3,1],
[76,30,3,2],
[76,30,4,0],
[76,30,4,1],
[76,30,4,2],
[76,30,4,3],
[76,30,5,0],
[76,30,5,1],
[76,30,5,2],
[76,30,5,4],
[76,30,6,0],
[76,30,6,1],
[76,30,6,2],
[76,30,6,4],
[76,30,7,0],
[76,30,7,1],
[76,30,7,2],
[76,30,7,3],
[76,30,7,4],
[76,30,7,5],
[76,30,7,6],
[76,30,8,0],
[76,30,8,1],
[76,30,8,2],
[76,30,8,4],
[76,30,8,7],
[76,30,10,0],
[76,30,10,1],
[76,30,10,2],
[76,30,10,3],
[76,30,10,4],
[76,30,10,5],
[76,30,10,6],
[76,30,10,7],
[76,30,10,8],
[76,30,11,0],
[76,30,11,1],
[76,30,11,2],
[76,30,11,3],
[76,30,11,4],
[76,30,11,5],
[76,30,11,6],
[76,30,11,7],
[76,30,11,8],
[76,30,11,10],
[76,30,12,0],
[76,30,12,1],
[76,30,12,2],
[76,30,12,3],
[76,30,12,4],
[76,30,12,5],
[76,30,12,6],
[76,30,12,7],
[76,30,12,8],
[76,30,12,10],
[76,30,13,0],
[76,30,13,1],
[76,30,13,2],
[76,30,13,3],
[76,30,13,4],
[76,30,13,5],
[76,30,13,6],
[76,30,13,7],
[76,30,13,8],
[76,30,13,10],
[76,30,13,11],
[76,30,13,12],
[76,30,14,0],
[76,30,14,1],
[76,30,14,2],
[76,30,14,3],
[76,30,14,4],
[76,30,14,5],
[76,30,14,6],
[76,30,14,7],
[76,30,14,8],
[76,30,14,10],
[76,30,14,11],
[76,30,14,12],
[76,30,16,0],
[76,30,16,1],
[76,30,16,2],
[76,30,16,3],
[76,30,16,4],
[76,30,16,5],
[76,30,16,6],
[76,30,16,7],
[76,30,16,8],
[76,30,16,10],
[76,30,16,11],
[76,30,16,12],
[76,30,16,13],
[76,30,17,0],
[76,30,17,1],
[76,30,17,2],
[76,30,17,3],
[76,30,17,4],
[76,30,17,5],
[76,30,17,6],
[76,30,17,7],
[76,30,17,8],
[76,30,17,10],
[76,30,17,11],
[76,30,17,12],
[76,30,17,13],
[76,30,17,16],
[76,30,19,0],
[76,30,19,1],
[76,30,19,2],
[76,30,19,3],
[76,30,19,4],
[76,30,19,5],
[76,30,19,6],
[76,30,19,7],
[76,30,19,8],
[76,30,19,12],
[76,30,19,16],
[76,30,19,17],
[76,30,20,0],
[76,30,20,1],
[76,30,20,2],
[76,30,20,3],
[76,30,20,4],
[76,30,20,5],
[76,30,20,6],
[76,30,20,7],
[76,30,20,11],
[76,30,20,12],
[76,30,20,13],
[76,30,20,14],
[76,30,20,16],
[76,30,20,17],
[76,30,20,19],
[76,30,21,0],
[76,30,21,1],
[76,30,21,2],
[76,30,21,4],
[76,30,21,10],
[76,30,21,11],
[76,30,21,12],
[76,30,21,13],
[76,30,21,14],
[76,30,21,16],
[76,30,21,17],
[76,30,21,19],
[76,30,21,20],
[76,30,22,0],
[76,30,22,1],
[76,30,22,2],
[76,30,22,3],
[76,30,22,4],
[76,30,22,6],
[76,30,22,7],
[76,30,22,8],
[76,30,22,11],
[76,30,22,12],
[76,30,22,13],
[76,30,22,14],
[76,30,22,16],
[76,30,22,17],
[76,30,22,19],
[76,30,22,21],
[76,30,24,0],
[76,30,24,1],
[76,30,24,2],
[76,30,24,5],
[76,30,24,6],
[76,30,24,7],
[76,30,24,8],
[76,30,24,10],
[76,30,24,11],
[76,30,24,12],
[76,30,24,13],
[76,30,24,14],
[76,30,24,17],
[76,30,24,19],
[76,30,24,20],
[76,30,24,21],
[76,30,25,0],
[76,30,25,1],
[76,30,25,2],
[76,30,25,3],
[76,30,25,4],
[76,30,25,5],
[76,30,25,6],
[76,30,25,8],
[76,30,25,10],
[76,30,25,11],
[76,30,25,12],
[76,30,25,13],
[76,30,25,14],
[76,30,25,16],
[76,30,25,17],
[76,30,25,19],
[76,30,27,0],
[76,30,27,1],
[76,30,27,2],
[76,30,27,3],
[76,30,27,4],
[76,30,27,5],
[76,30,27,6],
[76,30,27,7],
[76,30,27,8],
[76,30,27,11],
[76,30,27,12],
[76,30,27,13],
[76,30,27,14],
[76,30,27,16],
[76,30,27,17],
[76,30,28,0],
[76,30,28,1],
[76,30,28,2],
[76,30,28,3],
[76,30,28,4],
[76,30,28,5],
[76,30,28,6],
[76,30,28,8],
[76,30,28,10],
[76,30,28,11],
[76,30,28,12],
[76,30,28,13],
[76,30,28,14],
[76,30,29,0],
[76,30,29,1],
[76,30,29,2],
[76,30,29,3],
[76,30,29,4],
[76,30,29,5],
[76,30,29,6],
[76,30,29,7],
[76,30,29,8],
[76,30,29,10],
[76,30,29,13],
[76,30,29,14],
[76,31,3,0],
[76,31,3,1],
[76,31,3,2],
[76,31,4,0],
[76,31,4,1],
[76,31,4,2],
[76,31,4,3],
[76,31,5,0],
[76,31,5,1],
[76,31,5,2],
[76,31,5,4],
[76,31,6,0],
[76,31,6,1],
[76,31,6,2],
[76,31,6,4],
[76,31,7,0],
[76,31,7,1],
[76,31,7,2],
[76,31,7,3],
[76,31,7,4],
[76,31,7,5],
[76,31,7,6],
[76,31,8,0],
[76,31,8,1],
[76,31,8,2],
[76,31,8,4],
[76,31,8,7],
[76,31,10,0],
[76,31,10,1],
[76,31,10,2],
[76,31,10,3],
[76,31,10,4],
[76,31,10,5],
[76,31,10,6],
[76,31,10,7],
[76,31,10,8],
[76,31,11,0],
[76,31,11,1],
[76,31,11,2],
[76,31,11,3],
[76,31,11,4],
[76,31,11,5],
[76,31,11,6],
[76,31,11,7],
[76,31,11,8],
[76,31,11,10],
[76,31,12,0],
[76,31,12,1],
[76,31,12,2],
[76,31,12,3],
[76,31,12,4],
[76,31,12,5],
[76,31,12,6],
[76,31,12,7],
[76,31,12,8],
[76,31,12,10],
[76,31,13,0],
[76,31,13,1],
[76,31,13,2],
[76,31,13,3],
[76,31,13,4],
[76,31,13,5],
[76,31,13,6],
[76,31,13,7],
[76,31,13,8],
[76,31,13,10],
[76,31,13,11],
[76,31,13,12],
[76,31,14,0],
[76,31,14,1],
[76,31,14,2],
[76,31,14,3],
[76,31,14,4],
[76,31,14,5],
[76,31,14,6],
[76,31,14,7],
[76,31,14,8],
[76,31,14,10],
[76,31,14,11],
[76,31,14,12],
[76,31,16,0],
[76,31,16,1],
[76,31,16,2],
[76,31,16,3],
[76,31,16,4],
[76,31,16,5],
[76,31,16,6],
[76,31,16,7],
[76,31,16,8],
[76,31,16,10],
[76,31,16,11],
[76,31,16,14],
[76,31,17,0],
[76,31,17,1],
[76,31,17,2],
[76,31,17,3],
[76,31,17,4],
[76,31,17,5],
[76,31,17,6],
[76,31,17,7],
[76,31,17,8],
[76,31,17,10],
[76,31,17,11],
[76,31,17,14],
[76,31,17,16],
[76,31,19,0],
[76,31,19,1],
[76,31,19,2],
[76,31,19,3],
[76,31,19,4],
[76,31,19,5],
[76,31,19,6],
[76,31,19,7],
[76,31,19,10],
[76,31,19,11],
[76,31,19,12],
[76,31,19,16],
[76,31,19,17],
[76,31,20,0],
[76,31,20,1],
[76,31,20,2],
[76,31,20,3],
[76,31,20,4],
[76,31,20,5],
[76,31,20,8],
[76,31,20,11],
[76,31,20,12],
[76,31,20,13],
[76,31,20,14],
[76,31,20,16],
[76,31,20,17],
[76,31,20,19],
[76,31,21,0],
[76,31,21,1],
[76,31,21,2],
[76,31,21,4],
[76,31,21,7],
[76,31,21,10],
[76,31,21,11],
[76,31,21,12],
[76,31,21,13],
[76,31,21,14],
[76,31,21,16],
[76,31,21,17],
[76,31,21,19],
[76,31,21,20],
[76,31,22,0],
[76,31,22,1],
[76,31,22,2],
[76,31,22,5],
[76,31,22,6],
[76,31,22,7],
[76,31,22,8],
[76,31,22,11],
[76,31,22,12],
[76,31,22,13],
[76,31,22,14],
[76,31,22,16],
[76,31,22,17],
[76,31,22,19],
[76,31,22,21],
[76,31,24,3],
[76,31,24,4],
[76,31,24,5],
[76,31,24,6],
[76,31,24,7],
[76,31,24,8],
[76,31,24,10],
[76,31,24,11],
[76,31,24,12],
[76,31,24,13],
[76,31,24,14],
[76,31,24,17],
[76,31,24,19],
[76,31,24,20],
[76,31,25,0],
[76,31,25,1],
[76,31,25,2],
[76,31,25,3],
[76,31,25,4],
[76,31,25,5],
[76,31,25,6],
[76,31,25,8],
[76,31,25,10],
[76,31,25,11],
[76,31,25,12],
[76,31,25,13],
[76,31,25,14],
[76,31,25,16],
[76,31,25,17],
[76,31,27,0],
[76,31,27,1],
[76,31,27,2],
[76,31,27,3],
[76,31,27,4],
[76,31,27,5],
[76,31,27,6],
[76,31,27,7],
[76,31,27,8],
[76,31,27,11],
[76,31,27,12],
[76,31,27,13],
[76,31,27,14],
[76,31,28,0],
[76,31,28,1],
[76,31,28,2],
[76,31,28,3],
[76,31,28,4],
[76,31,28,5],
[76,31,28,6],
[76,31,28,8],
[76,31,28,10],
[76,31,28,11],
[76,31,28,12],
[76,31,28,13],
[76,31,28,14],
[76,31,29,0],
[76,31,29,1],
[76,31,29,2],
[76,31,29,3],
[76,31,29,4],
[76,31,29,5],
[76,31,29,6],
[76,31,29,7],
[76,31,29,8],
[76,31,29,10],
[76,31,29,13],
[76,32,3,0],
[76,32,3,1],
[76,32,3,2],
[76,32,4,0],
[76,32,4,1],
[76,32,4,2],
[76,32,4,3],
[76,32,5,0],
[76,32,5,1],
[76,32,5,2],
[76,32,5,4],
[76,32,6,0],
[76,32,6,1],
[76,32,6,2],
[76,32,6,4],
[76,32,7,0],
[76,32,7,1],
[76,32,7,2],
[76,32,7,3],
[76,32,7,4],
[76,32,7,5],
[76,32,7,6],
[76,32,8,0],
[76,32,8,1],
[76,32,8,2],
[76,32,8,4],
[76,32,8,7],
[76,32,10,0],
[76,32,10,1],
[76,32,10,2],
[76,32,10,3],
[76,32,10,4],
[76,32,10,5],
[76,32,10,6],
[76,32,10,7],
[76,32,10,8],
[76,32,13,0],
[76,32,13,1],
[76,32,13,2],
[76,32,13,3],
[76,32,13,4],
[76,32,13,5],
[76,32,13,6],
[76,32,13,7],
[76,32,13,8],
[76,32,13,10],
[76,32,14,0],
[76,32,14,1],
[76,32,14,2],
[76,32,14,3],
[76,32,14,4],
[76,32,14,5],
[76,32,14,6],
[76,32,14,7],
[76,32,14,8],
[76,32,14,10],
[76,32,16,0],
[76,32,16,1],
[76,32,16,2],
[76,32,16,3],
[76,32,16,4],
[76,32,16,5],
[76,32,16,6],
[76,32,16,7],
[76,32,16,8],
[76,32,16,13],
[76,32,16,14],
[76,32,17,0],
[76,32,17,1],
[76,32,17,2],
[76,32,17,3],
[76,32,17,4],
[76,32,17,5],
[76,32,17,6],
[76,32,17,7],
[76,32,17,8],
[76,32,17,13],
[76,32,17,14],
[76,32,17,16],
[76,32,19,0],
[76,32,19,1],
[76,32,19,2],
[76,32,19,3],
[76,32,19,4],
[76,32,19,5],
[76,32,19,8],
[76,32,19,10],
[76,32,19,16],
[76,32,19,17],
[76,32,20,0],
[76,32,20,1],
[76,32,20,2],
[76,32,20,3],
[76,32,20,4],
[76,32,20,6],
[76,32,20,7],
[76,32,20,8],
[76,32,20,13],
[76,32,20,14],
[76,32,20,16],
[76,32,20,17],
[76,32,20,19],
[76,32,21,0],
[76,32,21,1],
[76,32,21,2],
[76,32,21,7],
[76,32,21,10],
[76,32,21,13],
[76,32,21,14],
[76,32,21,16],
[76,32,21,17],
[76,32,21,19],
[76,32,21,20],
[76,32,22,3],
[76,32,22,4],
[76,32,22,5],
[76,32,22,6],
[76,32,22,7],
[76,32,22,8],
[76,32,22,13],
[76,32,22,14],
[76,32,22,16],
[76,32,22,17],
[76,32,22,19],
[76,32,23,0],
[76,32,23,1],
[76,32,23,2],
[76,32,23,3],
[76,32,23,4],
[76,32,23,5],
[76,32,23,6],
[76,32,23,7],
[76,32,23,8],
[76,32,23,10],
[76,32,23,13],
[76,32,23,14],
[76,32,23,16],
[76,32,23,17],
[76,32,23,19],
[76,32,24,0],
[76,32,24,1],
[76,32,24,2],
[76,32,24,3],
[76,32,24,4],
[76,32,24,5],
[76,32,24,6],
[76,32,24,7],
[76,32,24,8],
[76,32,24,10],
[76,32,24,13],
[76,32,24,14],
[76,32,24,17],
[76,32,24,19],
[76,32,25,0],
[76,32,25,1],
[76,32,25,2],
[76,32,25,3],
[76,32,25,4],
[76,32,25,5],
[76,32,25,6],
[76,32,25,8],
[76,32,25,10],
[76,32,25,13],
[76,32,25,14],
[76,32,25,16],
[76,32,25,17],
[76,32,27,0],
[76,32,27,1],
[76,32,27,2],
[76,32,27,3],
[76,32,27,4],
[76,32,27,5],
[76,32,27,6],
[76,32,27,7],
[76,32,27,8],
[76,32,27,13],
[76,32,27,14],
[76,32,28,0],
[76,32,28,1],
[76,32,28,2],
[76,32,28,3],
[76,32,28,4],
[76,32,28,5],
[76,32,28,6],
[76,32,28,8],
[76,32,28,10],
[76,32,28,13],
[76,32,30,0],
[76,32,30,1],
[76,32,30,2],
[76,32,30,3],
[76,32,30,4],
[76,32,30,5],
[76,32,30,6],
[76,32,30,7],
[76,32,30,8],
[76,32,31,0],
[76,32,31,1],
[76,32,31,2],
[76,32,31,3],
[76,32,31,4],
[76,32,31,5],
[76,32,31,6],
[76,32,31,7],
[76,33,3,0],
[76,33,3,1],
[76,33,3,2],
[76,33,4,0],
[76,33,4,1],
[76,33,4,2],
[76,33,4,3],
[76,33,5,0],
[76,33,5,1],
[76,33,5,2],
[76,33,5,4],
[76,33,6,0],
[76,33,6,1],
[76,33,6,2],
[76,33,6,4],
[76,33,7,0],
[76,33,7,1],
[76,33,7,2],
[76,33,7,3],
[76,33,7,4],
[76,33,7,5],
[76,33,7,6],
[76,33,8,0],
[76,33,8,1],
[76,33,8,2],
[76,33,8,4],
[76,33,8,7],
[76,33,10,0],
[76,33,10,1],
[76,33,10,2],
[76,33,10,3],
[76,33,10,4],
[76,33,10,5],
[76,33,10,6],
[76,33,10,7],
[76,33,10,8],
[76,33,13,0],
[76,33,13,1],
[76,33,13,2],
[76,33,13,3],
[76,33,13,4],
[76,33,13,5],
[76,33,13,6],
[76,33,13,7],
[76,33,13,8],
[76,33,13,10],
[76,33,14,0],
[76,33,14,1],
[76,33,14,2],
[76,33,14,3],
[76,33,14,4],
[76,33,14,5],
[76,33,14,6],
[76,33,14,7],
[76,33,14,8],
[76,33,14,10],
[76,33,16,0],
[76,33,16,1],
[76,33,16,2],
[76,33,16,3],
[76,33,16,4],
[76,33,16,5],
[76,33,16,6],
[76,33,16,7],
[76,33,16,10],
[76,33,16,13],
[76,33,16,14],
[76,33,17,0],
[76,33,17,1],
[76,33,17,2],
[76,33,17,3],
[76,33,17,4],
[76,33,17,5],
[76,33,17,6],
[76,33,17,7],
[76,33,17,10],
[76,33,17,13],
[76,33,17,14],
[76,33,17,16],
[76,33,19,0],
[76,33,19,1],
[76,33,19,2],
[76,33,19,3],
[76,33,19,4],
[76,33,19,6],
[76,33,19,7],
[76,33,19,8],
[76,33,19,10],
[76,33,19,16],
[76,33,19,17],
[76,33,20,0],
[76,33,20,1],
[76,33,20,2],
[76,33,20,5],
[76,33,20,6],
[76,33,20,7],
[76,33,20,8],
[76,33,20,13],
[76,33,20,14],
[76,33,20,16],
[76,33,20,17],
[76,33,20,19],
[76,33,21,4],
[76,33,21,7],
[76,33,21,10],
[76,33,21,13],
[76,33,21,14],
[76,33,21,16],
[76,33,21,17],
[76,33,21,19],
[76,33,21,20],
[76,33,22,0],
[76,33,22,1],
[76,33,22,2],
[76,33,22,3],
[76,33,22,4],
[76,33,22,5],
[76,33,22,6],
[76,33,22,7],
[76,33,22,8],
[76,33,22,13],
[76,33,22,14],
[76,33,22,16],
[76,33,22,17],
[76,33,22,19],
[76,33,23,0],
[76,33,23,1],
[76,33,23,2],
[76,33,23,3],
[76,33,23,4],
[76,33,23,5],
[76,33,23,6],
[76,33,23,7],
[76,33,23,8],
[76,33,23,10],
[76,33,23,13],
[76,33,23,14],
[76,33,23,16],
[76,33,23,17],
[76,33,24,0],
[76,33,24,1],
[76,33,24,2],
[76,33,24,3],
[76,33,24,4],
[76,33,24,5],
[76,33,24,6],
[76,33,24,7],
[76,33,24,8],
[76,33,24,10],
[76,33,24,13],
[76,33,24,14],
[76,33,24,17],
[76,33,25,0],
[76,33,25,1],
[76,33,25,2],
[76,33,25,3],
[76,33,25,4],
[76,33,25,5],
[76,33,25,6],
[76,33,25,8],
[76,33,25,10],
[76,33,25,13],
[76,33,25,14],
[76,33,27,0],
[76,33,27,1],
[76,33,27,2],
[76,33,27,3],
[76,33,27,4],
[76,33,27,5],
[76,33,27,6],
[76,33,27,7],
[76,33,27,8],
[76,33,27,13],
[76,33,28,0],
[76,33,28,1],
[76,33,28,2],
[76,33,28,3],
[76,33,28,4],
[76,33,28,5],
[76,33,28,6],
[76,33,28,8],
[76,33,28,10],
[76,33,30,0],
[76,33,30,1],
[76,33,30,2],
[76,33,30,3],
[76,33,30,4],
[76,33,30,5],
[76,33,30,6],
[76,33,30,7],
[76,33,31,0],
[76,33,31,1],
[76,33,31,2],
[76,33,31,3],
[76,33,31,4],
[76,33,31,5],
[76,34,3,0],
[76,34,3,1],
[76,34,3,2],
[76,34,4,0],
[76,34,4,1],
[76,34,4,2],
[76,34,4,3],
[76,34,5,0],
[76,34,5,1],
[76,34,5,2],
[76,34,5,4],
[76,34,6,0],
[76,34,6,1],
[76,34,6,2],
[76,34,6,4],
[76,34,8,0],
[76,34,8,1],
[76,34,8,2],
[76,34,8,4],
[76,34,10,0],
[76,34,10,1],
[76,34,10,2],
[76,34,10,3],
[76,34,10,4],
[76,34,10,5],
[76,34,10,6],
[76,34,10,8],
[76,34,11,0],
[76,34,11,1],
[76,34,11,2],
[76,34,11,3],
[76,34,11,4],
[76,34,11,5],
[76,34,11,6],
[76,34,11,8],
[76,34,11,10],
[76,34,12,0],
[76,34,12,1],
[76,34,12,2],
[76,34,12,3],
[76,34,12,4],
[76,34,12,5],
[76,34,12,6],
[76,34,12,8],
[76,34,12,10],
[76,34,13,0],
[76,34,13,1],
[76,34,13,2],
[76,34,13,3],
[76,34,13,4],
[76,34,13,5],
[76,34,13,6],
[76,34,13,8],
[76,34,13,10],
[76,34,13,11],
[76,34,14,0],
[76,34,14,1],
[76,34,14,2],
[76,34,14,3],
[76,34,14,4],
[76,34,14,5],
[76,34,14,6],
[76,34,14,8],
[76,34,14,12],
[76,34,16,0],
[76,34,16,1],
[76,34,16,2],
[76,34,16,3],
[76,34,16,4],
[76,34,16,5],
[76,34,16,8],
[76,34,16,10],
[76,34,16,11],
[76,34,16,12],
[76,34,16,13],
[76,34,16,14],
[76,34,17,0],
[76,34,17,1],
[76,34,17,2],
[76,34,17,3],
[76,34,17,4],
[76,34,17,5],
[76,34,17,8],
[76,34,17,10],
[76,34,17,11],
[76,34,17,12],
[76,34,17,13],
[76,34,17,14],
[76,34,17,16],
[76,34,19,0],
[76,34,19,1],
[76,34,19,2],
[76,34,19,5],
[76,34,19,6],
[76,34,19,8],
[76,34,19,10],
[76,34,19,11],
[76,34,19,12],
[76,34,19,16],
[76,34,19,17],
[76,34,20,3],
[76,34,20,4],
[76,34,20,5],
[76,34,20,6],
[76,34,20,8],
[76,34,20,11],
[76,34,20,12],
[76,34,20,13],
[76,34,20,14],
[76,34,20,16],
[76,34,20,17],
[76,34,20,19],
[76,34,21,0],
[76,34,21,1],
[76,34,21,2],
[76,34,21,4],
[76,34,21,10],
[76,34,21,11],
[76,34,21,12],
[76,34,21,13],
[76,34,21,14],
[76,34,21,16],
[76,34,21,17],
[76,34,21,19],
[76,34,22,0],
[76,34,22,1],
[76,34,22,2],
[76,34,22,3],
[76,34,22,4],
[76,34,22,5],
[76,34,22,6],
[76,34,22,8],
[76,34,22,11],
[76,34,22,12],
[76,34,22,13],
[76,34,22,14],
[76,34,22,16],
[76,34,22,17],
[76,34,23,0],
[76,34,23,1],
[76,34,23,2],
[76,34,23,3],
[76,34,23,4],
[76,34,23,5],
[76,34,23,6],
[76,34,23,8],
[76,34,23,10],
[76,34,23,11],
[76,34,23,12],
[76,34,23,13],
[76,34,23,14],
[76,34,23,16],
[76,34,23,17],
[76,34,24,0],
[76,34,24,1],
[76,34,24,2],
[76,34,24,3],
[76,34,24,4],
[76,34,24,5],
[76,34,24,6],
[76,34,24,8],
[76,34,24,10],
[76,34,24,11],
[76,34,24,12],
[76,34,24,13],
[76,34,24,14],
[76,34,24,17],
[76,34,27,0],
[76,34,27,1],
[76,34,27,2],
[76,34,27,3],
[76,34,27,4],
[76,34,27,5],
[76,34,27,6],
[76,34,27,8],
[76,34,27,11],
[76,34,29,0],
[76,34,29,1],
[76,34,29,2],
[76,34,29,3],
[76,34,29,4],
[76,34,29,5],
[76,34,29,6],
[76,34,30,0],
[76,34,30,1],
[76,34,30,2],
[76,34,30,3],
[76,34,30,4],
[76,34,30,5],
[76,34,31,0],
[76,34,31,1],
[76,34,31,2],
[76,34,31,3],
[76,34,31,4],
[76,34,32,0],
[76,34,32,1],
[76,34,32,2],
[76,36,3,0],
[76,36,3,1],
[76,36,3,2],
[76,36,4,0],
[76,36,4,1],
[76,36,4,2],
[76,36,4,3],
[76,36,5,0],
[76,36,5,1],
[76,36,5,2],
[76,36,5,4],
[76,36,6,0],
[76,36,6,1],
[76,36,6,2],
[76,36,6,4],
[76,36,7,0],
[76,36,7,1],
[76,36,7,2],
[76,36,7,3],
[76,36,7,4],
[76,36,7,5],
[76,36,7,6],
[76,36,8,0],
[76,36,8,1],
[76,36,8,2],
[76,36,8,4],
[76,36,8,7],
[76,36,10,0],
[76,36,10,1],
[76,36,10,2],
[76,36,10,3],
[76,36,10,4],
[76,36,10,5],
[76,36,10,6],
[76,36,10,7],
[76,36,10,8],
[76,36,11,0],
[76,36,11,1],
[76,36,11,2],
[76,36,11,3],
[76,36,11,4],
[76,36,11,5],
[76,36,11,6],
[76,36,11,7],
[76,36,11,8],
[76,36,12,0],
[76,36,12,1],
[76,36,12,2],
[76,36,12,3],
[76,36,12,4],
[76,36,12,5],
[76,36,12,6],
[76,36,12,7],
[76,36,12,10],
[76,36,16,0],
[76,36,16,1],
[76,36,16,2],
[76,36,16,5],
[76,36,16,6],
[76,36,16,7],
[76,36,16,8],
[76,36,16,10],
[76,36,16,11],
[76,36,16,12],
[76,36,17,0],
[76,36,17,1],
[76,36,17,2],
[76,36,17,5],
[76,36,17,6],
[76,36,17,7],
[76,36,17,8],
[76,36,17,10],
[76,36,17,11],
[76,36,17,12],
[76,36,17,16],
[76,36,20,0],
[76,36,20,1],
[76,36,20,2],
[76,36,20,3],
[76,36,20,4],
[76,36,20,5],
[76,36,20,6],
[76,36,20,7],
[76,36,20,8],
[76,36,20,11],
[76,36,20,12],
[76,36,20,16],
[76,36,20,17],
[76,36,21,0],
[76,36,21,1],
[76,36,21,2],
[76,36,21,4],
[76,36,21,7],
[76,36,21,10],
[76,36,21,11],
[76,36,21,12],
[76,36,21,16],
[76,36,21,17],
[76,36,22,0],
[76,36,22,1],
[76,36,22,2],
[76,36,22,3],
[76,36,22,4],
[76,36,22,5],
[76,36,22,6],
[76,36,22,7],
[76,36,22,8],
[76,36,22,11],
[76,36,22,12],
[76,36,23,0],
[76,36,23,1],
[76,36,23,2],
[76,36,23,3],
[76,36,23,4],
[76,36,23,5],
[76,36,23,6],
[76,36,23,7],
[76,36,23,8],
[76,36,23,10],
[76,36,23,11],
[76,36,23,12],
[76,36,24,0],
[76,36,24,1],
[76,36,24,2],
[76,36,24,3],
[76,36,24,4],
[76,36,24,5],
[76,36,24,6],
[76,36,24,7],
[76,36,24,8],
[76,36,24,10],
[76,36,24,11],
[76,36,24,12],
[76,36,25,0],
[76,36,25,1],
[76,36,25,2],
[76,36,25,3],
[76,36,25,4],
[76,36,25,5],
[76,36,25,6],
[76,36,25,8],
[76,36,25,10],
[76,36,25,11],
[76,36,27,0],
[76,36,27,1],
[76,36,27,2],
[76,36,27,3],
[76,36,27,4],
[76,36,27,5],
[76,36,27,6],
[76,36,27,7],
[76,36,28,0],
[76,36,28,1],
[76,36,28,2],
[76,36,28,3],
[76,36,28,4],
[76,36,28,5],
[76,36,29,0],
[76,36,29,1],
[76,36,29,2],
[76,36,29,3],
[76,36,29,4],
[76,36,30,0],
[76,36,30,1],
[76,36,30,2],
[76,37,3,0],
[76,37,3,1],
[76,37,3,2],
[76,37,5,0],
[76,37,5,1],
[76,37,5,2],
[76,37,6,0],
[76,37,6,1],
[76,37,6,2],
[76,37,7,0],
[76,37,7,1],
[76,37,7,2],
[76,37,7,3],
[76,37,7,5],
[76,37,7,6],
[76,37,8,0],
[76,37,8,1],
[76,37,8,2],
[76,37,8,7],
[76,37,10,0],
[76,37,10,1],
[76,37,10,2],
[76,37,10,3],
[76,37,10,5],
[76,37,10,6],
[76,37,10,7],
[76,37,11,0],
[76,37,11,1],
[76,37,11,2],
[76,37,11,3],
[76,37,11,5],
[76,37,11,6],
[76,37,11,7],
[76,37,11,10],
[76,37,12,0],
[76,37,12,1],
[76,37,12,2],
[76,37,12,3],
[76,37,12,5],
[76,37,12,8],
[76,37,12,10],
[76,37,13,0],
[76,37,13,1],
[76,37,13,2],
[76,37,13,3],
[76,37,13,5],
[76,37,13,8],
[76,37,13,10],
[76,37,13,11],
[76,37,13,12],
[76,37,14,0],
[76,37,14,1],
[76,37,14,2],
[76,37,14,3],
[76,37,14,6],
[76,37,14,7],
[76,37,14,8],
[76,37,14,10],
[76,37,14,11],
[76,37,14,12],
[76,37,16,3],
[76,37,16,5],
[76,37,16,6],
[76,37,16,7],
[76,37,16,8],
[76,37,16,10],
[76,37,16,11],
[76,37,16,12],
[76,37,16,13],
[76,37,16,14],
[76,37,17,3],
[76,37,17,5],
[76,37,17,6],
[76,37,17,7],
[76,37,17,8],
[76,37,17,10],
[76,37,17,11],
[76,37,17,12],
[76,37,17,13],
[76,37,17,14],
[76,37,17,16],
[76,37,19,0],
[76,37,19,1],
[76,37,19,2],
[76,37,19,3],
[76,37,19,5],
[76,37,19,6],
[76,37,19,7],
[76,37,19,8],
[76,37,19,10],
[76,37,19,11],
[76,37,19,12],
[76,37,19,16],
[76,37,19,17],
[76,37,20,0],
[76,37,20,1],
[76,37,20,2],
[76,37,20,3],
[76,37,20,5],
[76,37,20,6],
[76,37,20,7],
[76,37,20,8],
[76,37,20,11],
[76,37,20,12],
[76,37,20,13],
[76,37,20,14],
[76,37,20,16],
[76,37,20,17],
[76,37,21,0],
[76,37,21,1],
[76,37,21,2],
[76,37,21,7],
[76,37,21,10],
[76,37,21,11],
[76,37,21,12],
[76,37,21,13],
[76,37,21,14],
[76,37,22,0],
[76,37,22,1],
[76,37,22,2],
[76,37,22,3],
[76,37,22,5],
[76,37,22,6],
[76,37,22,7],
[76,37,22,8],
[76,37,22,11],
[76,37,22,12],
[76,37,22,13],
[76,37,22,14],
[76,37,23,0],
[76,37,23,1],
[76,37,23,2],
[76,37,23,3],
[76,37,23,5],
[76,37,23,6],
[76,37,23,7],
[76,37,23,8],
[76,37,23,10],
[76,37,23,11],
[76,37,23,12],
[76,37,23,13],
[76,37,24,0],
[76,37,24,1],
[76,37,24,2],
[76,37,24,3],
[76,37,24,5],
[76,37,24,6],
[76,37,24,7],
[76,37,24,8],
[76,37,24,10],
[76,37,24,11],
[76,37,24,12],
[76,37,24,13],
[76,37,25,0],
[76,37,25,1],
[76,37,25,2],
[76,37,25,3],
[76,37,25,5],
[76,37,25,6],
[76,37,25,8],
[76,37,27,0],
[76,37,27,1],
[76,37,27,2],
[76,37,27,3],
[76,37,27,5],
[76,37,28,0],
[76,37,28,1],
[76,37,28,2],
[76,37,28,3],
[76,37,29,0],
[76,37,29,1],
[76,37,29,2],
[76,38,3,0],
[76,38,3,1],
[76,38,3,2],
[76,38,4,0],
[76,38,4,1],
[76,38,4,2],
[76,38,4,3],
[76,38,5,0],
[76,38,5,1],
[76,38,5,2],
[76,38,5,4],
[76,38,6,0],
[76,38,6,1],
[76,38,6,2],
[76,38,6,4],
[76,38,8,0],
[76,38,8,1],
[76,38,8,2],
[76,38,8,4],
[76,38,10,0],
[76,38,10,1],
[76,38,10,2],
[76,38,10,3],
[76,38,10,4],
[76,38,10,5],
[76,38,10,8],
[76,38,11,0],
[76,38,11,1],
[76,38,11,2],
[76,38,11,3],
[76,38,11,4],
[76,38,11,5],
[76,38,11,8],
[76,38,11,10],
[76,38,12,0],
[76,38,12,1],
[76,38,12,2],
[76,38,12,3],
[76,38,12,4],
[76,38,12,6],
[76,38,12,8],
[76,38,12,10],
[76,38,13,0],
[76,38,13,1],
[76,38,13,2],
[76,38,13,3],
[76,38,13,4],
[76,38,13,6],
[76,38,13,8],
[76,38,13,10],
[76,38,13,11],
[76,38,13,12],
[76,38,14,0],
[76,38,14,1],
[76,38,14,2],
[76,38,14,5],
[76,38,14,6],
[76,38,14,8],
[76,38,14,10],
[76,38,14,11],
[76,38,14,12],
[76,38,16,0],
[76,38,16,1],
[76,38,16,2],
[76,38,16,3],
[76,38,16,4],
[76,38,16,5],
[76,38,16,6],
[76,38,16,8],
[76,38,16,10],
[76,38,16,11],
[76,38,16,12],
[76,38,16,13],
[76,38,16,14],
[76,38,17,0],
[76,38,17,1],
[76,38,17,2],
[76,38,17,3],
[76,38,17,4],
[76,38,17,5],
[76,38,17,6],
[76,38,17,8],
[76,38,17,10],
[76,38,17,11],
[76,38,17,12],
[76,38,17,13],
[76,38,17,14],
[76,38,17,16],
[76,38,19,0],
[76,38,19,1],
[76,38,19,2],
[76,38,19,3],
[76,38,19,4],
[76,38,19,5],
[76,38,19,6],
[76,38,19,8],
[76,38,19,10],
[76,38,19,11],
[76,38,19,12],
[76,38,19,16],
[76,38,19,17],
[76,38,20,0],
[76,38,20,1],
[76,38,20,2],
[76,38,20,3],
[76,38,20,4],
[76,38,20,5],
[76,38,20,6],
[76,38,20,8],
[76,38,20,11],
[76,38,20,12],
[76,38,20,13],
[76,38,20,14],
[76,38,21,0],
[76,38,21,1],
[76,38,21,2],
[76,38,21,4],
[76,38,21,10],
[76,38,21,11],
[76,38,21,12],
[76,38,21,13],
[76,38,21,14],
[76,38,22,0],
[76,38,22,1],
[76,38,22,2],
[76,38,22,3],
[76,38,22,4],
[76,38,22,5],
[76,38,22,6],
[76,38,22,8],
[76,38,22,11],
[76,38,22,12],
[76,38,22,13],
[76,38,23,0],
[76,38,23,1],
[76,38,23,2],
[76,38,23,3],
[76,38,23,4],
[76,38,23,5],
[76,38,23,6],
[76,38,23,8],
[76,38,23,10],
[76,38,23,11],
[76,38,24,0],
[76,38,24,1],
[76,38,24,2],
[76,38,24,3],
[76,38,24,4],
[76,38,24,5],
[76,38,24,6],
[76,38,24,8],
[76,38,24,10],
[76,38,24,11],
[76,38,27,0],
[76,38,27,1],
[76,38,27,2],
[76,38,27,3],
[76,38,27,4],
[76,39,4,0],
[76,39,4,1],
[76,39,4,2],
[76,39,7,0],
[76,39,7,1],
[76,39,7,2],
[76,39,7,4],
[76,39,10,0],
[76,39,10,1],
[76,39,10,2],
[76,39,10,4],
[76,39,10,7],
[76,39,11,0],
[76,39,11,1],
[76,39,11,2],
[76,39,11,4],
[76,39,11,7],
[76,39,11,10],
[76,39,12,0],
[76,39,12,1],
[76,39,12,2],
[76,39,12,7],
[76,39,12,10],
[76,39,13,0],
[76,39,13,1],
[76,39,13,2],
[76,39,13,7],
[76,39,13,10],
[76,39,13,11],
[76,39,13,12],
[76,39,14,4],
[76,39,14,7],
[76,39,14,10],
[76,39,14,11],
[76,39,14,12],
[76,39,16,0],
[76,39,16,1],
[76,39,16,2],
[76,39,16,4],
[76,39,16,7],
[76,39,16,10],
[76,39,16,11],
[76,39,16,12],
[76,39,16,13],
[76,39,16,14],
[76,39,17,0],
[76,39,17,1],
[76,39,17,2],
[76,39,17,4],
[76,39,17,7],
[76,39,17,10],
[76,39,17,11],
[76,39,17,12],
[76,39,17,13],
[76,39,17,14],
[76,39,17,16],
[76,39,19,0],
[76,39,19,1],
[76,39,19,2],
[76,39,19,4],
[76,39,19,7],
[76,39,19,10],
[76,39,19,11],
[76,39,19,12],
[76,39,20,0],
[76,39,20,1],
[76,39,20,2],
[76,39,20,4],
[76,39,20,7],
[76,39,20,11],
[76,39,20,12],
[76,39,20,13],
[76,39,20,14],
[76,39,22,0],
[76,39,22,1],
[76,39,22,2],
[76,39,22,4],
[76,39,22,7],
[76,39,22,11],
[76,39,23,0],
[76,39,23,1],
[76,39,23,2],
[76,39,23,4],
[76,39,23,7],
[76,39,24,0],
[76,39,24,1],
[76,39,24,2],
[76,39,24,4],
[76,39,24,7],
[76,39,25,0],
[76,39,25,1],
[76,39,25,2],
[76,39,25,4],
[76,39,27,0],
[76,39,27,1],
[76,39,27,2],
[76,42,3,0],
[76,42,3,1],
[76,42,3,2],
[76,42,4,0],
[76,42,4,1],
[76,42,4,2],
[76,42,4,3],
[76,42,5,0],
[76,42,5,1],
[76,42,5,2],
[76,42,5,4],
[76,42,6,0],
[76,42,6,1],
[76,42,6,2],
[76,42,6,4],
[76,42,7,0],
[76,42,7,1],
[76,42,7,2],
[76,42,7,3],
[76,42,7,4],
[76,42,7,6],
[76,42,8,0],
[76,42,8,1],
[76,42,8,2],
[76,42,8,7],
[76,42,11,3],
[76,42,11,4],
[76,42,11,5],
[76,42,11,6],
[76,42,11,7],
[76,42,11,8],
[76,42,12,0],
[76,42,12,1],
[76,42,12,2],
[76,42,12,3],
[76,42,12,4],
[76,42,12,5],
[76,42,12,6],
[76,42,12,7],
[76,42,12,8],
[76,42,13,0],
[76,42,13,1],
[76,42,13,2],
[76,42,13,3],
[76,42,13,4],
[76,42,13,5],
[76,42,13,6],
[76,42,13,7],
[76,42,13,8],
[76,42,13,11],
[76,42,13,12],
[76,42,14,0],
[76,42,14,1],
[76,42,14,2],
[76,42,14,3],
[76,42,14,4],
[76,42,14,5],
[76,42,14,6],
[76,42,14,7],
[76,42,14,8],
[76,42,14,11],
[76,42,14,12],
[76,42,16,0],
[76,42,16,1],
[76,42,16,2],
[76,42,16,3],
[76,42,16,4],
[76,42,16,5],
[76,42,16,6],
[76,42,16,7],
[76,42,16,8],
[76,42,16,11],
[76,42,16,12],
[76,42,16,13],
[76,42,16,14],
[76,42,17,0],
[76,42,17,1],
[76,42,17,2],
[76,42,17,3],
[76,42,17,4],
[76,42,17,5],
[76,42,17,6],
[76,42,17,7],
[76,42,17,8],
[76,42,17,11],
[76,42,17,12],
[76,42,17,13],
[76,42,17,14],
[76,42,19,0],
[76,42,19,1],
[76,42,19,2],
[76,42,19,3],
[76,42,19,4],
[76,42,19,5],
[76,42,19,6],
[76,42,19,7],
[76,42,19,8],
[76,42,19,11],
[76,42,19,12],
[76,42,21,0],
[76,42,21,1],
[76,42,21,2],
[76,42,21,4],
[76,42,21,7],
[76,42,23,0],
[76,42,23,1],
[76,42,23,2],
[76,42,23,3],
[76,42,23,4],
[76,42,23,5],
[76,42,24,0],
[76,42,24,1],
[76,42,24,2],
[76,42,24,3],
[76,42,24,4],
[76,42,24,5],
[76,42,25,0],
[76,42,25,1],
[76,42,25,2],
[76,43,3,0],
[76,43,3,1],
[76,43,3,2],
[76,43,4,0],
[76,43,4,1],
[76,43,4,2],
[76,43,4,3],
[76,43,5,0],
[76,43,5,1],
[76,43,5,2],
[76,43,5,4],
[76,43,6,0],
[76,43,6,1],
[76,43,6,2],
[76,43,8,4],
[76,43,10,0],
[76,43,10,1],
[76,43,10,2],
[76,43,10,3],
[76,43,10,4],
[76,43,10,5],
[76,43,10,6],
[76,43,10,8],
[76,43,11,0],
[76,43,11,1],
[76,43,11,2],
[76,43,11,3],
[76,43,11,4],
[76,43,11,5],
[76,43,11,6],
[76,43,11,8],
[76,43,11,10],
[76,43,12,0],
[76,43,12,1],
[76,43,12,2],
[76,43,12,3],
[76,43,12,4],
[76,43,12,5],
[76,43,12,6],
[76,43,12,8],
[76,43,12,10],
[76,43,13,0],
[76,43,13,1],
[76,43,13,2],
[76,43,13,3],
[76,43,13,4],
[76,43,13,5],
[76,43,13,6],
[76,43,13,8],
[76,43,13,10],
[76,43,13,11],
[76,43,13,12],
[76,43,14,0],
[76,43,14,1],
[76,43,14,2],
[76,43,14,3],
[76,43,14,4],
[76,43,14,5],
[76,43,14,6],
[76,43,14,8],
[76,43,14,10],
[76,43,14,11],
[76,43,14,12],
[76,43,16,0],
[76,43,16,1],
[76,43,16,2],
[76,43,16,3],
[76,43,16,4],
[76,43,16,5],
[76,43,16,6],
[76,43,16,8],
[76,43,16,10],
[76,43,16,11],
[76,43,16,12],
[76,43,16,13],
[76,43,16,14],
[76,43,17,0],
[76,43,17,1],
[76,43,17,2],
[76,43,17,3],
[76,43,17,4],
[76,43,17,5],
[76,43,17,6],
[76,43,17,8],
[76,43,17,10],
[76,43,17,11],
[76,43,17,12],
[76,43,17,13],
[76,43,17,14],
[76,43,19,0],
[76,43,19,1],
[76,43,19,2],
[76,43,19,3],
[76,43,19,4],
[76,43,19,5],
[76,43,19,6],
[76,43,19,8],
[76,43,19,10],
[76,43,19,11],
[76,43,20,0],
[76,43,20,1],
[76,43,20,2],
[76,43,20,3],
[76,43,20,4],
[76,43,20,5],
[76,43,20,6],
[76,43,20,8],
[76,43,21,0],
[76,43,21,1],
[76,43,21,2],
[76,43,21,4],
[76,43,22,0],
[76,43,22,1],
[76,43,22,2],
[76,43,22,3],
[76,43,22,4],
[76,43,22,5],
[76,43,23,0],
[76,43,23,1],
[76,43,23,2],
[76,43,23,3],
[76,43,23,4],
[76,43,24,0],
[76,43,24,1],
[76,43,24,2],
[76,43,24,3],
[76,43,24,4],
[76,44,3,0],
[76,44,3,1],
[76,44,3,2],
[76,44,4,0],
[76,44,4,1],
[76,44,4,2],
[76,44,4,3],
[76,44,5,0],
[76,44,5,1],
[76,44,5,2],
[76,44,6,4],
[76,44,7,3],
[76,44,7,4],
[76,44,7,5],
[76,44,7,6],
[76,44,8,0],
[76,44,8,1],
[76,44,8,2],
[76,44,8,4],
[76,44,8,7],
[76,44,10,0],
[76,44,10,1],
[76,44,10,2],
[76,44,10,3],
[76,44,10,4],
[76,44,10,5],
[76,44,10,6],
[76,44,10,7],
[76,44,10,8],
[76,44,11,0],
[76,44,11,1],
[76,44,11,2],
[76,44,11,3],
[76,44,11,4],
[76,44,11,5],
[76,44,11,6],
[76,44,11,7],
[76,44,11,8],
[76,44,11,10],
[76,44,12,0],
[76,44,12,1],
[76,44,12,2],
[76,44,12,3],
[76,44,12,4],
[76,44,12,5],
[76,44,12,6],
[76,44,12,7],
[76,44,12,8],
[76,44,12,10],
[76,44,13,0],
[76,44,13,1],
[76,44,13,2],
[76,44,13,3],
[76,44,13,4],
[76,44,13,5],
[76,44,13,6],
[76,44,13,7],
[76,44,13,8],
[76,44,13,10],
[76,44,13,11],
[76,44,13,12],
[76,44,14,0],
[76,44,14,1],
[76,44,14,2],
[76,44,14,3],
[76,44,14,4],
[76,44,14,5],
[76,44,14,6],
[76,44,14,7],
[76,44,14,8],
[76,44,14,10],
[76,44,14,11],
[76,44,14,12],
[76,44,16,0],
[76,44,16,1],
[76,44,16,2],
[76,44,16,3],
[76,44,16,4],
[76,44,16,5],
[76,44,16,6],
[76,44,16,7],
[76,44,16,8],
[76,44,16,10],
[76,44,16,11],
[76,44,16,12],
[76,44,16,13],
[76,44,19,0],
[76,44,19,1],
[76,44,19,2],
[76,44,19,3],
[76,44,19,4],
[76,44,19,5],
[76,44,19,6],
[76,44,19,7],
[76,44,19,8],
[76,44,20,0],
[76,44,20,1],
[76,44,20,2],
[76,44,20,3],
[76,44,20,4],
[76,44,20,5],
[76,44,20,6],
[76,44,20,7],
[76,44,21,0],
[76,44,21,1],
[76,44,21,2],
[76,44,21,4],
[76,44,22,0],
[76,44,22,1],
[76,44,22,2],
[76,44,22,3],
[76,44,22,4],
[76,44,23,0],
[76,44,23,1],
[76,44,23,2],
[76,44,24,0],
[76,44,24,1],
[76,44,24,2],
[76,47,3,0],
[76,47,3,1],
[76,47,3,2],
[76,47,4,0],
[76,47,4,1],
[76,47,4,2],
[76,47,4,3],
[76,47,5,0],
[76,47,5,1],
[76,47,5,2],
[76,47,5,4],
[76,47,6,0],
[76,47,6,1],
[76,47,6,2],
[76,47,6,4],
[76,47,7,0],
[76,47,7,1],
[76,47,7,2],
[76,47,7,3],
[76,47,7,4],
[76,47,7,5],
[76,47,7,6],
[76,47,8,0],
[76,47,8,1],
[76,47,8,2],
[76,47,8,4],
[76,47,8,7],
[76,47,10,0],
[76,47,10,1],
[76,47,10,2],
[76,47,10,3],
[76,47,10,4],
[76,47,10,5],
[76,47,10,6],
[76,47,10,7],
[76,47,10,8],
[76,47,11,0],
[76,47,11,1],
[76,47,11,2],
[76,47,11,3],
[76,47,11,4],
[76,47,11,5],
[76,47,11,6],
[76,47,11,7],
[76,47,11,8],
[76,47,11,10],
[76,47,12,0],
[76,47,12,1],
[76,47,12,2],
[76,47,12,3],
[76,47,12,4],
[76,47,12,5],
[76,47,12,6],
[76,47,12,7],
[76,47,12,8],
[76,47,12,10],
[76,47,13,0],
[76,47,13,1],
[76,47,13,2],
[76,47,13,3],
[76,47,13,4],
[76,47,13,5],
[76,47,13,6],
[76,47,13,7],
[76,47,13,8],
[76,47,13,10],
[76,47,13,11],
[76,47,13,12],
[76,47,14,0],
[76,47,14,1],
[76,47,14,2],
[76,47,14,3],
[76,47,14,4],
[76,47,14,5],
[76,47,14,6],
[76,47,14,7],
[76,47,14,8],
[76,47,14,10],
[76,47,14,11],
[76,47,16,0],
[76,47,16,1],
[76,47,16,2],
[76,47,16,3],
[76,47,16,4],
[76,47,16,5],
[76,47,16,6],
[76,47,16,7],
[76,47,17,0],
[76,47,17,1],
[76,47,17,2],
[76,47,17,3],
[76,47,17,4],
[76,47,17,5],
[76,47,17,6],
[76,47,17,7],
[76,47,19,0],
[76,47,19,1],
[76,47,19,2],
[76,47,19,3],
[76,47,19,4],
[76,47,20,0],
[76,47,20,1],
[76,47,20,2],
[76,48,3,0],
[76,48,3,1],
[76,48,3,2],
[76,48,4,0],
[76,48,4,1],
[76,48,4,2],
[76,48,4,3],
[76,48,5,0],
[76,48,5,1],
[76,48,5,2],
[76,48,5,4],
[76,48,6,0],
[76,48,6,1],
[76,48,6,2],
[76,48,6,4],
[76,48,7,0],
[76,48,7,1],
[76,48,7,2],
[76,48,7,3],
[76,48,7,4],
[76,48,7,5],
[76,48,7,6],
[76,48,8,0],
[76,48,8,1],
[76,48,8,2],
[76,48,8,4],
[76,48,8,7],
[76,48,10,0],
[76,48,10,1],
[76,48,10,2],
[76,48,10,3],
[76,48,10,4],
[76,48,10,5],
[76,48,10,6],
[76,48,10,7],
[76,48,10,8],
[76,48,13,0],
[76,48,13,1],
[76,48,13,2],
[76,48,13,3],
[76,48,13,4],
[76,48,13,5],
[76,48,13,6],
[76,48,13,7],
[76,48,13,8],
[76,48,13,10],
[76,48,14,0],
[76,48,14,1],
[76,48,14,2],
[76,48,14,3],
[76,48,14,4],
[76,48,14,5],
[76,48,14,6],
[76,48,14,7],
[76,48,14,8],
[76,48,16,0],
[76,48,16,1],
[76,48,16,2],
[76,48,16,3],
[76,48,16,4],
[76,48,16,5],
[76,48,17,0],
[76,48,17,1],
[76,48,17,2],
[76,48,17,3],
[76,48,17,4],
[76,48,17,5],
[76,48,19,0],
[76,48,19,1],
[76,48,19,2],
[76,51,4,0],
[76,51,4,1],
[76,51,4,2],
[76,51,7,0],
[76,51,7,1],
[76,51,7,2],
[76,51,7,4],
[76,51,10,0],
[76,51,10,1],
[76,51,10,2],
[76,51,10,4],
[76,51,10,7],
[76,51,11,0],
[76,51,11,1],
[76,51,11,2],
[76,51,11,4],
[76,51,11,7],
[76,51,11,10],
[76,51,12,0],
[76,51,12,1],
[76,51,12,2],
[76,51,12,4],
[76,51,12,7],
[76,51,12,10],
[76,51,13,0],
[76,51,13,1],
[76,51,13,2],
[76,51,13,4],
[76,51,13,7],
[76,51,13,10],
[76,51,13,11],
[76,51,14,0],
[76,51,14,1],
[76,51,14,2],
[76,51,14,4],
[76,51,14,7],
[76,51,16,0],
[76,51,16,1],
[76,51,16,2],
[76,51,16,4],
[76,51,17,0],
[76,51,17,1],
[76,51,17,2],
[76,51,17,4],
[76,51,19,0],
[76,51,19,1],
[76,51,19,2],
[76,53,3,0],
[76,53,3,1],
[76,53,3,2],
[76,53,4,0],
[76,53,4,1],
[76,53,4,2],
[76,53,4,3],
[76,53,5,0],
[76,53,5,1],
[76,53,5,2],
[76,53,5,4],
[76,53,6,0],
[76,53,6,1],
[76,53,6,2],
[76,53,6,4],
[76,53,7,0],
[76,53,7,1],
[76,53,7,2],
[76,53,7,3],
[76,53,7,4],
[76,53,7,5],
[76,53,7,6],
[76,53,8,0],
[76,53,8,1],
[76,53,8,2],
[76,53,8,4],
[76,53,8,7],
[76,53,11,0],
[76,53,11,1],
[76,53,11,2],
[76,53,11,3],
[76,53,11,4],
[76,53,11,5],
[76,53,11,6],
[76,53,11,7],
[76,53,11,8],
[76,53,12,0],
[76,53,12,1],
[76,53,12,2],
[76,53,12,3],
[76,53,12,4],
[76,53,12,5],
[76,53,12,6],
[76,53,12,7],
[76,53,12,8],
[76,53,13,0],
[76,53,13,1],
[76,53,13,2],
[76,53,13,3],
[76,53,13,4],
[76,53,13,5],
[76,53,13,6],
[76,53,13,7],
[76,53,13,8],
[76,53,13,11],
[76,53,14,0],
[76,53,14,1],
[76,53,14,2],
[76,53,14,3],
[76,53,14,4],
[76,53,14,5],
[76,53,14,6],
[76,53,14,7],
[76,53,14,8],
[76,53,16,0],
[76,53,16,1],
[76,53,16,2],
[76,53,16,3],
[76,53,16,4],
[76,53,16,5],
[76,53,17,0],
[76,53,17,1],
[76,53,17,2],
[76,53,17,3],
[76,53,17,4],
[76,53,17,5],
[76,53,19,0],
[76,53,19,1],
[76,53,19,2],
[76,54,3,0],
[76,54,3,1],
[76,54,3,2],
[76,54,4,0],
[76,54,4,1],
[76,54,4,2],
[76,54,4,3],
[76,54,5,0],
[76,54,5,1],
[76,54,5,2],
[76,54,5,4],
[76,54,6,0],
[76,54,6,1],
[76,54,6,2],
[76,54,6,4],
[76,54,7,0],
[76,54,7,1],
[76,54,7,2],
[76,54,7,3],
[76,54,7,4],
[76,54,7,5],
[76,54,7,6],
[76,54,8,0],
[76,54,8,1],
[76,54,8,2],
[76,54,8,4],
[76,54,8,7],
[76,54,11,0],
[76,54,11,1],
[76,54,11,2],
[76,54,11,3],
[76,54,11,4],
[76,54,11,5],
[76,54,11,6],
[76,54,11,7],
[76,54,11,8],
[76,54,12,0],
[76,54,12,1],
[76,54,12,2],
[76,54,12,3],
[76,54,12,4],
[76,54,12,5],
[76,54,12,6],
[76,54,12,7],
[76,54,12,8],
[76,54,13,0],
[76,54,13,1],
[76,54,13,2],
[76,54,13,3],
[76,54,13,4],
[76,54,13,5],
[76,54,13,6],
[76,54,13,7],
[76,54,13,8],
[76,54,13,11],
[76,54,14,0],
[76,54,14,1],
[76,54,14,2],
[76,54,14,3],
[76,54,14,4],
[76,54,14,5],
[76,54,14,6],
[76,54,14,7],
[76,54,14,8],
[76,54,16,0],
[76,54,16,1],
[76,54,16,2],
[76,54,16,3],
[76,54,16,4],
[76,54,16,5],
[76,54,17,0],
[76,54,17,1],
[76,54,17,2],
[76,54,17,3],
[76,54,17,4],
[76,54,17,5],
[76,54,19,0],
[76,54,19,1],
[76,54,19,2],
[76,55,3,0],
[76,55,3,1],
[76,55,3,2],
[76,55,4,0],
[76,55,4,1],
[76,55,4,2],
[76,55,4,3],
[76,55,5,0],
[76,55,5,1],
[76,55,5,2],
[76,55,5,4],
[76,55,6,0],
[76,55,6,1],
[76,55,6,2],
[76,55,6,4],
[76,55,7,0],
[76,55,7,1],
[76,55,7,2],
[76,55,7,3],
[76,55,7,4],
[76,55,7,5],
[76,55,7,6],
[76,55,8,0],
[76,55,8,1],
[76,55,8,2],
[76,55,8,4],
[76,55,8,7],
[76,55,11,0],
[76,55,11,1],
[76,55,11,2],
[76,55,11,3],
[76,55,11,4],
[76,55,11,5],
[76,55,11,6],
[76,55,11,7],
[76,55,11,8],
[76,55,12,0],
[76,55,12,1],
[76,55,12,2],
[76,55,12,3],
[76,55,12,4],
[76,55,12,5],
[76,55,12,6],
[76,55,12,7],
[76,55,12,8],
[76,55,13,0],
[76,55,13,1],
[76,55,13,2],
[76,55,13,3],
[76,55,13,4],
[76,55,13,5],
[76,55,13,6],
[76,55,13,7],
[76,55,13,8],
[76,55,13,11],
[76,55,14,0],
[76,55,14,1],
[76,55,14,2],
[76,55,14,3],
[76,55,14,4],
[76,55,14,5],
[76,55,14,6],
[76,55,14,7],
[76,55,14,8],
[76,55,16,0],
[76,55,16,1],
[76,55,16,2],
[76,55,16,3],
[76,55,16,4],
[76,55,16,5],
[76,55,17,0],
[76,55,17,1],
[76,55,17,2],
[76,55,17,3],
[76,55,17,4],
[76,55,17,5],
[76,55,19,0],
[76,55,19,1],
[76,55,19,2],
[76,57,3,0],
[76,57,3,1],
[76,57,3,2],
[76,57,4,0],
[76,57,4,1],
[76,57,4,2],
[76,57,4,3],
[76,57,5,0],
[76,57,5,1],
[76,57,5,2],
[76,57,5,4],
[76,57,6,0],
[76,57,6,1],
[76,57,6,2],
[76,57,6,4],
[76,57,7,0],
[76,57,7,1],
[76,57,7,2],
[76,57,7,3],
[76,57,7,4],
[76,57,7,5],
[76,57,7,6],
[76,57,8,0],
[76,57,8,1],
[76,57,8,2],
[76,57,8,4],
[76,57,8,7],
[76,57,10,0],
[76,57,10,1],
[76,57,10,2],
[76,57,10,3],
[76,57,10,4],
[76,57,10,5],
[76,57,10,6],
[76,57,10,7],
[76,57,10,8],
[76,57,11,0],
[76,57,11,1],
[76,57,11,2],
[76,57,11,3],
[76,57,11,4],
[76,57,11,5],
[76,57,11,6],
[76,57,11,7],
[76,57,11,8],
[76,57,11,10],
[76,57,12,0],
[76,57,12,1],
[76,57,12,2],
[76,57,12,3],
[76,57,12,4],
[76,57,12,5],
[76,57,12,6],
[76,57,12,7],
[76,57,12,8],
[76,57,13,0],
[76,57,13,1],
[76,57,13,2],
[76,57,13,3],
[76,57,13,4],
[76,57,13,5],
[76,57,13,6],
[76,57,13,7],
[76,57,13,8],
[76,57,14,0],
[76,57,14,1],
[76,57,14,2],
[76,57,14,3],
[76,57,14,4],
[76,57,14,5],
[76,57,14,6],
[76,57,14,7],
[76,57,16,0],
[76,57,16,1],
[76,57,16,2],
[76,57,16,3],
[76,57,16,4],
[76,57,17,0],
[76,57,17,1],
[76,57,17,2],
[76,57,17,3],
[76,57,17,4],
[76,58,3,0],
[76,58,3,1],
[76,58,3,2],
[76,58,4,0],
[76,58,4,1],
[76,58,4,2],
[76,58,4,3],
[76,58,5,0],
[76,58,5,1],
[76,58,5,2],
[76,58,5,4],
[76,58,6,0],
[76,58,6,1],
[76,58,6,2],
[76,58,6,4],
[76,58,7,0],
[76,58,7,1],
[76,58,7,2],
[76,58,7,3],
[76,58,7,4],
[76,58,7,5],
[76,58,7,6],
[76,58,8,0],
[76,58,8,1],
[76,58,8,2],
[76,58,8,4],
[76,58,8,7],
[76,58,10,0],
[76,58,10,1],
[76,58,10,2],
[76,58,10,3],
[76,58,10,4],
[76,58,10,5],
[76,58,10,6],
[76,58,10,7],
[76,58,10,8],
[76,58,11,0],
[76,58,11,1],
[76,58,11,2],
[76,58,11,3],
[76,58,11,4],
[76,58,11,5],
[76,58,11,6],
[76,58,11,7],
[76,58,11,8],
[76,58,11,10],
[76,58,12,0],
[76,58,12,1],
[76,58,12,2],
[76,58,12,3],
[76,58,12,4],
[76,58,12,5],
[76,58,12,6],
[76,58,12,7],
[76,58,12,8],
[76,58,13,0],
[76,58,13,1],
[76,58,13,2],
[76,58,13,3],
[76,58,13,4],
[76,58,13,5],
[76,58,13,6],
[76,58,13,7],
[76,58,13,8],
[76,58,14,0],
[76,58,14,1],
[76,58,14,2],
[76,58,14,3],
[76,58,14,4],
[76,58,14,5],
[76,58,14,6],
[76,58,14,7],
[76,58,17,0],
[76,58,17,1],
[76,58,17,2],
[76,58,17,3],
[76,58,17,4],
[76,60,3,0],
[76,60,3,1],
[76,60,3,2],
[76,60,4,0],
[76,60,4,1],
[76,60,4,2],
[76,60,4,3],
[76,60,5,0],
[76,60,5,1],
[76,60,5,2],
[76,60,5,4],
[76,60,6,0],
[76,60,6,1],
[76,60,6,2],
[76,60,6,4],
[76,60,7,0],
[76,60,7,1],
[76,60,7,2],
[76,60,7,3],
[76,60,7,4],
[76,60,7,5],
[76,60,7,6],
[76,60,8,0],
[76,60,8,1],
[76,60,8,2],
[76,60,8,4],
[76,60,8,7],
[76,60,10,0],
[76,60,10,1],
[76,60,10,2],
[76,60,10,3],
[76,60,10,4],
[76,60,10,5],
[76,60,10,6],
[76,60,10,7],
[76,60,11,0],
[76,60,11,1],
[76,60,11,2],
[76,60,11,3],
[76,60,11,4],
[76,60,11,5],
[76,60,11,6],
[76,60,11,7],
[76,60,12,0],
[76,60,12,1],
[76,60,12,2],
[76,60,12,3],
[76,60,12,4],
[76,60,12,5],
[76,61,3,0],
[76,61,3,1],
[76,61,3,2],
[76,61,5,0],
[76,61,5,1],
[76,61,5,2],
[76,61,6,0],
[76,61,6,1],
[76,61,6,2],
[76,61,7,0],
[76,61,7,1],
[76,61,7,2],
[76,61,7,3],
[76,61,7,5],
[76,61,7,6],
[76,61,8,0],
[76,61,8,1],
[76,61,8,2],
[76,61,8,7],
[76,61,10,0],
[76,61,10,1],
[76,61,10,2],
[76,61,10,3],
[76,61,10,5],
[76,61,11,0],
[76,61,11,1],
[76,61,11,2],
[76,61,11,3],
[76,61,11,5],
[76,61,12,0],
[76,61,12,1],
[76,61,12,2],
[76,61,12,3],
[76,61,13,0],
[76,61,13,1],
[76,61,13,2],
[76,61,13,3],
[76,61,14,0],
[76,61,14,1],
[76,61,14,2],
[76,62,3,0],
[76,62,3,1],
[76,62,3,2],
[76,62,4,0],
[76,62,4,1],
[76,62,4,2],
[76,62,4,3],
[76,62,5,0],
[76,62,5,1],
[76,62,5,2],
[76,62,5,4],
[76,62,6,0],
[76,62,6,1],
[76,62,6,2],
[76,62,6,4],
[76,62,8,0],
[76,62,8,1],
[76,62,8,2],
[76,62,8,4],
[76,62,10,0],
[76,62,10,1],
[76,62,10,2],
[76,62,10,3],
[76,62,10,4],
[76,62,10,5],
[76,62,11,0],
[76,62,11,1],
[76,62,11,2],
[76,62,11,3],
[76,62,11,4],
[76,62,11,5],
[76,62,12,0],
[76,62,12,1],
[76,62,12,2],
[76,62,12,3],
[76,62,12,4],
[76,62,13,0],
[76,62,13,1],
[76,62,13,2],
[76,62,13,3],
[76,62,13,4],
[76,62,14,0],
[76,62,14,1],
[76,62,14,2],
[76,64,3,0],
[76,64,3,1],
[76,64,3,2],
[76,64,5,0],
[76,64,5,1],
[76,64,5,2],
[76,64,6,0],
[76,64,6,1],
[76,64,6,2],
[76,64,7,0],
[76,64,7,1],
[76,64,7,2],
[76,64,7,3],
[76,64,7,5],
[76,64,8,0],
[76,64,8,1],
[76,64,8,2],
[76,64,10,0],
[76,64,10,1],
[76,64,10,2],
[76,64,11,0],
[76,64,11,1],
[76,64,11,2],
[76,65,3,0],
[76,65,3,1],
[76,65,3,2],
[76,65,4,0],
[76,65,4,1],
[76,65,4,2],
[76,65,4,3],
[76,65,5,0],
[76,65,5,1],
[76,65,5,2],
[76,65,5,4],
[76,65,6,0],
[76,65,6,1],
[76,65,6,2],
[76,65,6,4],
[76,65,7,0],
[76,65,7,1],
[76,65,7,2],
[76,65,7,3],
[76,65,7,4],
[76,65,8,0],
[76,65,8,1],
[76,65,8,2],
[76,66,3,0],
[76,66,3,1],
[76,66,3,2],
[76,66,4,0],
[76,66,4,1],
[76,66,4,2],
[76,66,4,3],
[76,66,5,0],
[76,66,5,1],
[76,66,5,2],
[76,66,5,4],
[76,66,6,0],
[76,66,6,1],
[76,66,6,2],
[76,67,3,0],
[76,67,3,1],
[76,67,3,2],
[76,67,4,0],
[76,67,4,1],
[76,67,4,2],
[76,67,4,3],
[76,67,5,0],
[76,67,5,1],
[76,67,5,2],
[76,68,3,0],
[76,68,3,1],
[76,68,3,2],
[76,68,4,0],
[76,68,4,1],
[76,68,4,2],
[77,4,3,0],
[77,4,3,1],
[77,4,3,2],
[77,5,4,0],
[77,5,4,1],
[77,5,4,2],
[77,6,4,0],
[77,6,4,1],
[77,6,4,2],
[77,7,3,0],
[77,7,3,1],
[77,7,3,2],
[77,7,4,0],
[77,7,4,1],
[77,7,4,2],
[77,7,4,3],
[77,7,5,0],
[77,7,5,1],
[77,7,5,2],
[77,7,5,4],
[77,7,6,0],
[77,7,6,1],
[77,7,6,2],
[77,7,6,4],
[77,8,4,0],
[77,8,4,1],
[77,8,4,2],
[77,8,7,0],
[77,8,7,1],
[77,8,7,2],
[77,8,7,4],
[77,10,3,0],
[77,10,3,1],
[77,10,3,2],
[77,10,4,0],
[77,10,4,1],
[77,10,4,2],
[77,10,4,3],
[77,10,5,0],
[77,10,5,1],
[77,10,5,2],
[77,10,5,4],
[77,10,6,0],
[77,10,6,1],
[77,10,6,2],
[77,10,6,4],
[77,10,7,0],
[77,10,7,1],
[77,10,7,2],
[77,10,7,3],
[77,10,7,4],
[77,10,7,5],
[77,10,7,6],
[77,10,8,0],
[77,10,8,1],
[77,10,8,2],
[77,10,8,4],
[77,10,8,7],
[77,11,3,0],
[77,11,3,1],
[77,11,3,2],
[77,11,4,0],
[77,11,4,1],
[77,11,4,2],
[77,11,4,3],
[77,11,5,0],
[77,11,5,1],
[77,11,5,2],
[77,11,5,4],
[77,11,6,0],
[77,11,6,1],
[77,11,6,2],
[77,11,6,4],
[77,11,7,0],
[77,11,7,1],
[77,11,7,2],
[77,11,7,3],
[77,11,7,4],
[77,11,7,5],
[77,11,7,6],
[77,11,8,0],
[77,11,8,1],
[77,11,8,2],
[77,11,8,4],
[77,11,8,7],
[77,11,10,0],
[77,11,10,1],
[77,11,10,2],
[77,11,10,3],
[77,11,10,4],
[77,11,10,5],
[77,11,10,6],
[77,11,10,7],
[77,11,10,8],
[77,12,3,0],
[77,12,3,1],
[77,12,3,2],
[77,12,4,0],
[77,12,4,1],
[77,12,4,2],
[77,12,4,3],
[77,12,5,0],
[77,12,5,1],
[77,12,5,2],
[77,12,5,4],
[77,12,6,0],
[77,12,6,1],
[77,12,6,2],
[77,12,6,4],
[77,12,7,0],
[77,12,7,1],
[77,12,7,2],
[77,12,7,3],
[77,12,7,4],
[77,12,7,5],
[77,12,7,6],
[77,12,8,0],
[77,12,8,1],
[77,12,8,2],
[77,12,8,4],
[77,12,8,7],
[77,12,10,0],
[77,12,10,1],
[77,12,10,2],
[77,12,10,3],
[77,12,10,4],
[77,12,10,5],
[77,12,10,6],
[77,12,10,7],
[77,12,10,8],
[77,13,3,0],
[77,13,3,1],
[77,13,3,2],
[77,13,4,0],
[77,13,4,1],
[77,13,4,2],
[77,13,4,3],
[77,13,5,0],
[77,13,5,1],
[77,13,5,2],
[77,13,5,4],
[77,13,6,0],
[77,13,6,1],
[77,13,6,2],
[77,13,6,4],
[77,13,7,0],
[77,13,7,1],
[77,13,7,2],
[77,13,7,3],
[77,13,7,4],
[77,13,7,5],
[77,13,7,6],
[77,13,8,0],
[77,13,8,1],
[77,13,8,2],
[77,13,8,4],
[77,13,8,7],
[77,13,10,0],
[77,13,10,1],
[77,13,10,2],
[77,13,10,3],
[77,13,10,4],
[77,13,10,5],
[77,13,10,6],
[77,13,10,7],
[77,13,10,8],
[77,13,11,0],
[77,13,11,1],
[77,13,11,2],
[77,13,11,3],
[77,13,11,4],
[77,13,11,5],
[77,13,11,6],
[77,13,11,7],
[77,13,11,8],
[77,13,11,10],
[77,13,12,0],
[77,13,12,1],
[77,13,12,2],
[77,13,12,3],
[77,13,12,4],
[77,13,12,5],
[77,13,12,6],
[77,13,12,7],
[77,13,12,8],
[77,13,12,10],
[77,14,3,0],
[77,14,3,1],
[77,14,3,2],
[77,14,4,0],
[77,14,4,1],
[77,14,4,2],
[77,14,4,3],
[77,14,5,0],
[77,14,5,1],
[77,14,5,2],
[77,14,5,4],
[77,14,6,0],
[77,14,6,1],
[77,14,6,2],
[77,14,6,4],
[77,14,7,0],
[77,14,7,1],
[77,14,7,2],
[77,14,7,3],
[77,14,7,4],
[77,14,7,5],
[77,14,7,6],
[77,14,8,0],
[77,14,8,1],
[77,14,8,2],
[77,14,8,4],
[77,14,8,7],
[77,14,10,0],
[77,14,10,1],
[77,14,10,2],
[77,14,10,3],
[77,14,10,4],
[77,14,10,5],
[77,14,10,6],
[77,14,10,7],
[77,14,10,8],
[77,14,11,0],
[77,14,11,1],
[77,14,11,2],
[77,14,11,3],
[77,14,11,4],
[77,14,11,5],
[77,14,11,6],
[77,14,11,7],
[77,14,11,8],
[77,14,11,10],
[77,14,12,0],
[77,14,12,1],
[77,14,12,2],
[77,14,12,3],
[77,14,12,4],
[77,14,12,5],
[77,14,12,6],
[77,14,12,7],
[77,14,12,8],
[77,14,12,10],
[77,16,3,0],
[77,16,3,1],
[77,16,3,2],
[77,16,4,0],
[77,16,4,1],
[77,16,4,2],
[77,16,4,3],
[77,16,5,0],
[77,16,5,1],
[77,16,5,2],
[77,16,5,4],
[77,16,6,0],
[77,16,6,1],
[77,16,6,2],
[77,16,6,4],
[77,16,7,0],
[77,16,7,1],
[77,16,7,2],
[77,16,7,3],
[77,16,7,4],
[77,16,7,5],
[77,16,7,6],
[77,16,8,0],
[77,16,8,1],
[77,16,8,2],
[77,16,8,4],
[77,16,8,7],
[77,16,10,0],
[77,16,10,1],
[77,16,10,2],
[77,16,10,3],
[77,16,10,4],
[77,16,10,5],
[77,16,10,6],
[77,16,10,7],
[77,16,10,8],
[77,16,11,0],
[77,16,11,1],
[77,16,11,2],
[77,16,11,3],
[77,16,11,4],
[77,16,11,5],
[77,16,11,6],
[77,16,11,7],
[77,16,11,8],
[77,16,11,10],
[77,16,12,0],
[77,16,12,1],
[77,16,12,2],
[77,16,12,3],
[77,16,12,4],
[77,16,12,5],
[77,16,12,6],
[77,16,12,7],
[77,16,12,8],
[77,16,12,10],
[77,16,13,0],
[77,16,13,1],
[77,16,13,2],
[77,16,13,3],
[77,16,13,4],
[77,16,13,5],
[77,16,13,6],
[77,16,13,7],
[77,16,13,8],
[77,16,13,10],
[77,16,13,11],
[77,16,13,12],
[77,16,14,0],
[77,16,14,1],
[77,16,14,2],
[77,16,14,3],
[77,16,14,4],
[77,16,14,5],
[77,16,14,6],
[77,16,14,7],
[77,16,14,8],
[77,16,14,10],
[77,16,14,11],
[77,16,14,12],
[77,17,3,0],
[77,17,3,1],
[77,17,3,2],
[77,17,4,0],
[77,17,4,1],
[77,17,4,2],
[77,17,4,3],
[77,17,5,0],
[77,17,5,1],
[77,17,5,2],
[77,17,5,4],
[77,17,6,0],
[77,17,6,1],
[77,17,6,2],
[77,17,6,4],
[77,17,7,0],
[77,17,7,1],
[77,17,7,2],
[77,17,7,3],
[77,17,7,4],
[77,17,7,5],
[77,17,7,6],
[77,17,8,0],
[77,17,8,1],
[77,17,8,2],
[77,17,8,4],
[77,17,8,7],
[77,17,10,0],
[77,17,10,1],
[77,17,10,2],
[77,17,10,3],
[77,17,10,4],
[77,17,10,5],
[77,17,10,6],
[77,17,10,7],
[77,17,10,8],
[77,17,11,0],
[77,17,11,1],
[77,17,11,2],
[77,17,11,3],
[77,17,11,4],
[77,17,11,5],
[77,17,11,6],
[77,17,11,7],
[77,17,11,8],
[77,17,11,10],
[77,17,12,0],
[77,17,12,1],
[77,17,12,2],
[77,17,12,3],
[77,17,12,4],
[77,17,12,5],
[77,17,12,6],
[77,17,12,7],
[77,17,12,8],
[77,17,12,10],
[77,17,13,0],
[77,17,13,1],
[77,17,13,2],
[77,17,13,3],
[77,17,13,4],
[77,17,13,5],
[77,17,13,6],
[77,17,13,7],
[77,17,13,8],
[77,17,13,10],
[77,17,13,11],
[77,17,13,12],
[77,17,14,0],
[77,17,14,1],
[77,17,14,2],
[77,17,14,3],
[77,17,14,4],
[77,17,14,5],
[77,17,14,6],
[77,17,14,7],
[77,17,14,8],
[77,17,14,10],
[77,17,14,11],
[77,17,14,12],
[77,17,16,0],
[77,17,16,1],
[77,17,16,2],
[77,17,16,3],
[77,17,16,4],
[77,17,16,5],
[77,17,16,6],
[77,17,16,7],
[77,17,16,8],
[77,17,16,10],
[77,17,16,11],
[77,17,16,12],
[77,17,16,13],
[77,17,16,14],
[77,19,3,0],
[77,19,3,1],
[77,19,3,2],
[77,19,4,0],
[77,19,4,1],
[77,19,4,2],
[77,19,4,3],
[77,19,5,0],
[77,19,5,1],
[77,19,5,2],
[77,19,5,4],
[77,19,6,0],
[77,19,6,1],
[77,19,6,2],
[77,19,6,4],
[77,19,7,0],
[77,19,7,1],
[77,19,7,2],
[77,19,7,3],
[77,19,7,4],
[77,19,7,5],
[77,19,7,6],
[77,19,8,0],
[77,19,8,1],
[77,19,8,2],
[77,19,8,4],
[77,19,8,7],
[77,19,10,0],
[77,19,10,1],
[77,19,10,2],
[77,19,10,3],
[77,19,10,4],
[77,19,10,5],
[77,19,10,6],
[77,19,10,7],
[77,19,10,8],
[77,19,11,0],
[77,19,11,1],
[77,19,11,2],
[77,19,11,3],
[77,19,11,4],
[77,19,11,5],
[77,19,11,6],
[77,19,11,7],
[77,19,11,8],
[77,19,11,10],
[77,19,12,0],
[77,19,12,1],
[77,19,12,2],
[77,19,12,3],
[77,19,12,4],
[77,19,12,5],
[77,19,12,6],
[77,19,12,7],
[77,19,12,8],
[77,19,12,10],
[77,19,16,0],
[77,19,16,1],
[77,19,16,2],
[77,19,16,3],
[77,19,16,4],
[77,19,16,5],
[77,19,16,6],
[77,19,16,7],
[77,19,16,8],
[77,19,16,10],
[77,19,16,11],
[77,19,16,12],
[77,19,17,0],
[77,19,17,1],
[77,19,17,2],
[77,19,17,3],
[77,19,17,4],
[77,19,17,5],
[77,19,17,6],
[77,19,17,7],
[77,19,17,8],
[77,19,17,10],
[77,19,17,11],
[77,19,17,12],
[77,19,17,16],
[77,20,3,0],
[77,20,3,1],
[77,20,3,2],
[77,20,4,0],
[77,20,4,1],
[77,20,4,2],
[77,20,4,3],
[77,20,5,0],
[77,20,5,1],
[77,20,5,2],
[77,20,5,4],
[77,20,6,0],
[77,20,6,1],
[77,20,6,2],
[77,20,6,4],
[77,20,7,0],
[77,20,7,1],
[77,20,7,2],
[77,20,7,3],
[77,20,7,4],
[77,20,7,5],
[77,20,7,6],
[77,20,8,0],
[77,20,8,1],
[77,20,8,2],
[77,20,8,4],
[77,20,8,7],
[77,20,11,0],
[77,20,11,1],
[77,20,11,2],
[77,20,11,3],
[77,20,11,4],
[77,20,11,5],
[77,20,11,6],
[77,20,11,7],
[77,20,11,8],
[77,20,12,0],
[77,20,12,1],
[77,20,12,2],
[77,20,12,3],
[77,20,12,4],
[77,20,12,5],
[77,20,12,6],
[77,20,12,7],
[77,20,12,8],
[77,20,13,0],
[77,20,13,1],
[77,20,13,2],
[77,20,13,3],
[77,20,13,4],
[77,20,13,5],
[77,20,13,6],
[77,20,13,7],
[77,20,13,8],
[77,20,13,11],
[77,20,13,12],
[77,20,14,0],
[77,20,14,1],
[77,20,14,2],
[77,20,14,3],
[77,20,14,4],
[77,20,14,5],
[77,20,14,6],
[77,20,14,7],
[77,20,14,8],
[77,20,14,11],
[77,20,14,12],
[77,20,16,0],
[77,20,16,1],
[77,20,16,2],
[77,20,16,3],
[77,20,16,4],
[77,20,16,5],
[77,20,16,6],
[77,20,16,7],
[77,20,16,8],
[77,20,16,11],
[77,20,16,12],
[77,20,16,13],
[77,20,16,14],
[77,20,17,0],
[77,20,17,1],
[77,20,17,2],
[77,20,17,3],
[77,20,17,4],
[77,20,17,5],
[77,20,17,6],
[77,20,17,7],
[77,20,17,8],
[77,20,17,11],
[77,20,17,12],
[77,20,17,13],
[77,20,17,14],
[77,20,17,16],
[77,20,19,0],
[77,20,19,1],
[77,20,19,2],
[77,20,19,3],
[77,20,19,4],
[77,20,19,5],
[77,20,19,6],
[77,20,19,7],
[77,20,19,8],
[77,20,19,11],
[77,20,19,12],
[77,20,19,16],
[77,20,19,17],
[77,21,4,0],
[77,21,4,1],
[77,21,4,2],
[77,21,7,0],
[77,21,7,1],
[77,21,7,2],
[77,21,7,4],
[77,21,10,0],
[77,21,10,1],
[77,21,10,2],
[77,21,10,4],
[77,21,10,7],
[77,21,11,0],
[77,21,11,1],
[77,21,11,2],
[77,21,11,4],
[77,21,11,7],
[77,21,11,10],
[77,21,12,0],
[77,21,12,1],
[77,21,12,2],
[77,21,12,4],
[77,21,12,7],
[77,21,12,10],
[77,21,13,0],
[77,21,13,1],
[77,21,13,2],
[77,21,13,4],
[77,21,13,7],
[77,21,13,10],
[77,21,13,11],
[77,21,13,12],
[77,21,14,0],
[77,21,14,1],
[77,21,14,2],
[77,21,14,4],
[77,21,14,7],
[77,21,14,10],
[77,21,14,11],
[77,21,14,12],
[77,21,16,0],
[77,21,16,1],
[77,21,16,2],
[77,21,16,4],
[77,21,16,7],
[77,21,16,10],
[77,21,16,11],
[77,21,16,12],
[77,21,16,13],
[77,21,16,14],
[77,21,17,0],
[77,21,17,1],
[77,21,17,2],
[77,21,17,4],
[77,21,17,7],
[77,21,17,10],
[77,21,17,11],
[77,21,17,12],
[77,21,17,13],
[77,21,17,14],
[77,21,17,16],
[77,21,19,0],
[77,21,19,1],
[77,21,19,2],
[77,21,19,4],
[77,21,19,7],
[77,21,19,10],
[77,21,19,11],
[77,21,19,12],
[77,21,19,16],
[77,21,19,17],
[77,21,20,0],
[77,21,20,1],
[77,21,20,2],
[77,21,20,4],
[77,21,20,7],
[77,21,20,11],
[77,21,20,12],
[77,21,20,13],
[77,21,20,14],
[77,21,20,16],
[77,21,20,17],
[77,21,20,19],
[77,22,3,0],
[77,22,3,1],
[77,22,3,2],
[77,22,4,0],
[77,22,4,1],
[77,22,4,2],
[77,22,4,3],
[77,22,5,0],
[77,22,5,1],
[77,22,5,2],
[77,22,5,4],
[77,22,6,0],
[77,22,6,1],
[77,22,6,2],
[77,22,6,4],
[77,22,7,0],
[77,22,7,1],
[77,22,7,2],
[77,22,7,3],
[77,22,7,4],
[77,22,7,5],
[77,22,7,6],
[77,22,8,0],
[77,22,8,1],
[77,22,8,2],
[77,22,8,4],
[77,22,8,7],
[77,22,11,0],
[77,22,11,1],
[77,22,11,2],
[77,22,11,3],
[77,22,11,4],
[77,22,11,5],
[77,22,11,6],
[77,22,11,7],
[77,22,11,8],
[77,22,12,0],
[77,22,12,1],
[77,22,12,2],
[77,22,12,3],
[77,22,12,4],
[77,22,12,5],
[77,22,12,6],
[77,22,12,7],
[77,22,12,8],
[77,22,13,0],
[77,22,13,1],
[77,22,13,2],
[77,22,13,3],
[77,22,13,4],
[77,22,13,5],
[77,22,13,6],
[77,22,13,7],
[77,22,13,8],
[77,22,13,11],
[77,22,13,12],
[77,22,14,0],
[77,22,14,1],
[77,22,14,2],
[77,22,14,3],
[77,22,14,4],
[77,22,14,5],
[77,22,14,6],
[77,22,14,7],
[77,22,14,8],
[77,22,14,11],
[77,22,14,12],
[77,22,16,0],
[77,22,16,1],
[77,22,16,2],
[77,22,16,3],
[77,22,16,4],
[77,22,16,5],
[77,22,16,6],
[77,22,16,7],
[77,22,16,8],
[77,22,16,11],
[77,22,16,12],
[77,22,16,13],
[77,22,16,14],
[77,22,17,0],
[77,22,17,1],
[77,22,17,2],
[77,22,17,3],
[77,22,17,4],
[77,22,17,5],
[77,22,17,6],
[77,22,17,7],
[77,22,17,8],
[77,22,17,11],
[77,22,17,12],
[77,22,17,13],
[77,22,17,14],
[77,22,17,16],
[77,22,19,0],
[77,22,19,1],
[77,22,19,2],
[77,22,19,3],
[77,22,19,4],
[77,22,19,5],
[77,22,19,6],
[77,22,19,7],
[77,22,19,8],
[77,22,19,11],
[77,22,19,12],
[77,22,19,16],
[77,22,19,17],
[77,22,21,0],
[77,22,21,1],
[77,22,21,2],
[77,22,21,4],
[77,22,21,7],
[77,22,21,11],
[77,22,21,12],
[77,22,21,13],
[77,22,21,14],
[77,22,21,16],
[77,22,21,17],
[77,22,21,19],
[77,23,3,0],
[77,23,3,1],
[77,23,3,2],
[77,23,4,0],
[77,23,4,1],
[77,23,4,2],
[77,23,4,3],
[77,23,5,0],
[77,23,5,1],
[77,23,5,2],
[77,23,5,4],
[77,23,6,0],
[77,23,6,1],
[77,23,6,2],
[77,23,6,4],
[77,23,7,0],
[77,23,7,1],
[77,23,7,2],
[77,23,7,3],
[77,23,7,4],
[77,23,7,5],
[77,23,7,6],
[77,23,8,0],
[77,23,8,1],
[77,23,8,2],
[77,23,8,4],
[77,23,8,7],
[77,23,10,0],
[77,23,10,1],
[77,23,10,2],
[77,23,10,3],
[77,23,10,4],
[77,23,10,5],
[77,23,10,6],
[77,23,10,7],
[77,23,10,8],
[77,23,11,0],
[77,23,11,1],
[77,23,11,2],
[77,23,11,3],
[77,23,11,4],
[77,23,11,5],
[77,23,11,6],
[77,23,11,7],
[77,23,11,8],
[77,23,11,10],
[77,23,12,0],
[77,23,12,1],
[77,23,12,2],
[77,23,12,3],
[77,23,12,4],
[77,23,12,5],
[77,23,12,6],
[77,23,12,7],
[77,23,12,8],
[77,23,12,10],
[77,23,13,0],
[77,23,13,1],
[77,23,13,2],
[77,23,13,3],
[77,23,13,4],
[77,23,13,5],
[77,23,13,6],
[77,23,13,7],
[77,23,13,8],
[77,23,13,10],
[77,23,13,11],
[77,23,13,12],
[77,23,14,0],
[77,23,14,1],
[77,23,14,2],
[77,23,14,3],
[77,23,14,4],
[77,23,14,5],
[77,23,14,6],
[77,23,14,7],
[77,23,14,8],
[77,23,14,10],
[77,23,14,11],
[77,23,14,12],
[77,23,16,0],
[77,23,16,1],
[77,23,16,2],
[77,23,16,3],
[77,23,16,4],
[77,23,16,5],
[77,23,16,6],
[77,23,16,7],
[77,23,16,8],
[77,23,16,10],
[77,23,16,11],
[77,23,16,12],
[77,23,16,13],
[77,23,16,14],
[77,23,17,0],
[77,23,17,1],
[77,23,17,2],
[77,23,17,3],
[77,23,17,4],
[77,23,17,5],
[77,23,17,6],
[77,23,17,7],
[77,23,17,8],
[77,23,17,10],
[77,23,17,11],
[77,23,17,12],
[77,23,17,13],
[77,23,17,14],
[77,23,17,16],
[77,23,19,0],
[77,23,19,1],
[77,23,19,2],
[77,23,19,3],
[77,23,19,4],
[77,23,19,5],
[77,23,19,6],
[77,23,19,7],
[77,23,19,8],
[77,23,19,10],
[77,23,19,11],
[77,23,19,12],
[77,23,19,16],
[77,23,19,17],
[77,23,20,0],
[77,23,20,1],
[77,23,20,2],
[77,23,20,3],
[77,23,20,4],
[77,23,20,5],
[77,23,20,6],
[77,23,20,7],
[77,23,20,8],
[77,23,20,11],
[77,23,20,12],
[77,23,20,13],
[77,23,20,14],
[77,23,20,16],
[77,23,20,17],
[77,23,20,19],
[77,23,21,0],
[77,23,21,1],
[77,23,21,2],
[77,23,21,4],
[77,23,21,7],
[77,23,21,10],
[77,23,21,11],
[77,23,21,12],
[77,23,21,13],
[77,23,21,14],
[77,23,21,19],
[77,23,21,20],
[77,23,22,0],
[77,23,22,1],
[77,23,22,2],
[77,23,22,3],
[77,23,22,4],
[77,23,22,5],
[77,23,22,6],
[77,23,22,7],
[77,23,22,8],
[77,23,22,11],
[77,23,22,12],
[77,23,22,13],
[77,23,22,14],
[77,23,22,16],
[77,23,22,17],
[77,23,22,19],
[77,23,22,21],
[77,24,3,0],
[77,24,3,1],
[77,24,3,2],
[77,24,4,0],
[77,24,4,1],
[77,24,4,2],
[77,24,4,3],
[77,24,5,0],
[77,24,5,1],
[77,24,5,2],
[77,24,5,4],
[77,24,6,0],
[77,24,6,1],
[77,24,6,2],
[77,24,6,4],
[77,24,7,0],
[77,24,7,1],
[77,24,7,2],
[77,24,7,3],
[77,24,7,4],
[77,24,7,5],
[77,24,7,6],
[77,24,8,0],
[77,24,8,1],
[77,24,8,2],
[77,24,8,4],
[77,24,8,7],
[77,24,10,0],
[77,24,10,1],
[77,24,10,2],
[77,24,10,3],
[77,24,10,4],
[77,24,10,5],
[77,24,10,6],
[77,24,10,7],
[77,24,10,8],
[77,24,11,0],
[77,24,11,1],
[77,24,11,2],
[77,24,11,3],
[77,24,11,4],
[77,24,11,5],
[77,24,11,6],
[77,24,11,7],
[77,24,11,8],
[77,24,11,10],
[77,24,12,0],
[77,24,12,1],
[77,24,12,2],
[77,24,12,3],
[77,24,12,4],
[77,24,12,5],
[77,24,12,6],
[77,24,12,7],
[77,24,12,8],
[77,24,12,10],
[77,24,13,0],
[77,24,13,1],
[77,24,13,2],
[77,24,13,3],
[77,24,13,4],
[77,24,13,5],
[77,24,13,6],
[77,24,13,7],
[77,24,13,8],
[77,24,13,10],
[77,24,13,11],
[77,24,13,12],
[77,24,14,0],
[77,24,14,1],
[77,24,14,2],
[77,24,14,3],
[77,24,14,4],
[77,24,14,5],
[77,24,14,6],
[77,24,14,7],
[77,24,14,8],
[77,24,14,10],
[77,24,14,11],
[77,24,14,12],
[77,24,17,0],
[77,24,17,1],
[77,24,17,2],
[77,24,17,3],
[77,24,17,4],
[77,24,17,5],
[77,24,17,6],
[77,24,17,7],
[77,24,17,8],
[77,24,17,10],
[77,24,17,11],
[77,24,17,12],
[77,24,17,13],
[77,24,17,14],
[77,24,19,0],
[77,24,19,1],
[77,24,19,2],
[77,24,19,3],
[77,24,19,4],
[77,24,19,5],
[77,24,19,6],
[77,24,19,7],
[77,24,19,8],
[77,24,19,10],
[77,24,19,11],
[77,24,19,12],
[77,24,19,17],
[77,24,20,0],
[77,24,20,1],
[77,24,20,2],
[77,24,20,3],
[77,24,20,4],
[77,24,20,5],
[77,24,20,6],
[77,24,20,7],
[77,24,20,8],
[77,24,20,11],
[77,24,20,12],
[77,24,20,13],
[77,24,20,14],
[77,24,20,17],
[77,24,20,19],
[77,24,21,0],
[77,24,21,1],
[77,24,21,2],
[77,24,21,4],
[77,24,21,7],
[77,24,21,10],
[77,24,21,11],
[77,24,21,12],
[77,24,21,13],
[77,24,21,14],
[77,24,21,19],
[77,24,21,20],
[77,24,22,0],
[77,24,22,1],
[77,24,22,2],
[77,24,22,3],
[77,24,22,4],
[77,24,22,5],
[77,24,22,6],
[77,24,22,7],
[77,24,22,8],
[77,24,22,11],
[77,24,22,12],
[77,24,22,13],
[77,24,22,14],
[77,24,22,17],
[77,24,22,19],
[77,24,22,21],
[77,24,23,0],
[77,24,23,1],
[77,24,23,2],
[77,24,23,3],
[77,24,23,4],
[77,24,23,5],
[77,24,23,6],
[77,24,23,7],
[77,24,23,8],
[77,24,23,10],
[77,24,23,11],
[77,24,23,12],
[77,24,23,13],
[77,24,23,17],
[77,24,23,19],
[77,24,23,20],
[77,24,23,21],
[77,24,23,22],
[77,25,3,0],
[77,25,3,1],
[77,25,3,2],
[77,25,4,0],
[77,25,4,1],
[77,25,4,2],
[77,25,4,3],
[77,25,5,0],
[77,25,5,1],
[77,25,5,2],
[77,25,5,4],
[77,25,6,0],
[77,25,6,1],
[77,25,6,2],
[77,25,6,4],
[77,25,8,0],
[77,25,8,1],
[77,25,8,2],
[77,25,8,4],
[77,25,10,0],
[77,25,10,1],
[77,25,10,2],
[77,25,10,3],
[77,25,10,4],
[77,25,10,5],
[77,25,10,6],
[77,25,10,8],
[77,25,11,0],
[77,25,11,1],
[77,25,11,2],
[77,25,11,3],
[77,25,11,4],
[77,25,11,5],
[77,25,11,6],
[77,25,11,8],
[77,25,11,10],
[77,25,12,0],
[77,25,12,1],
[77,25,12,2],
[77,25,12,3],
[77,25,12,4],
[77,25,12,5],
[77,25,12,6],
[77,25,12,8],
[77,25,12,10],
[77,25,13,0],
[77,25,13,1],
[77,25,13,2],
[77,25,13,3],
[77,25,13,4],
[77,25,13,5],
[77,25,13,6],
[77,25,13,8],
[77,25,13,10],
[77,25,13,11],
[77,25,13,12],
[77,25,14,0],
[77,25,14,1],
[77,25,14,2],
[77,25,14,3],
[77,25,14,4],
[77,25,14,5],
[77,25,14,6],
[77,25,14,8],
[77,25,14,10],
[77,25,14,11],
[77,25,14,12],
[77,25,16,0],
[77,25,16,1],
[77,25,16,2],
[77,25,16,3],
[77,25,16,4],
[77,25,16,5],
[77,25,16,6],
[77,25,16,8],
[77,25,16,10],
[77,25,16,11],
[77,25,16,12],
[77,25,16,13],
[77,25,16,14],
[77,25,17,0],
[77,25,17,1],
[77,25,17,2],
[77,25,17,3],
[77,25,17,4],
[77,25,17,5],
[77,25,17,6],
[77,25,17,8],
[77,25,17,10],
[77,25,17,11],
[77,25,17,12],
[77,25,17,13],
[77,25,17,14],
[77,25,17,16],
[77,25,19,0],
[77,25,19,1],
[77,25,19,2],
[77,25,19,3],
[77,25,19,4],
[77,25,19,5],
[77,25,19,6],
[77,25,19,8],
[77,25,19,10],
[77,25,19,11],
[77,25,19,12],
[77,25,20,0],
[77,25,20,1],
[77,25,20,2],
[77,25,20,3],
[77,25,20,4],
[77,25,20,5],
[77,25,20,6],
[77,25,20,8],
[77,25,20,11],
[77,25,20,12],
[77,25,20,13],
[77,25,20,14],
[77,25,20,16],
[77,25,20,17],
[77,25,20,19],
[77,25,21,0],
[77,25,21,1],
[77,25,21,2],
[77,25,21,4],
[77,25,21,10],
[77,25,21,11],
[77,25,21,12],
[77,25,21,13],
[77,25,21,16],
[77,25,21,17],
[77,25,21,19],
[77,25,21,20],
[77,25,22,0],
[77,25,22,1],
[77,25,22,2],
[77,25,22,3],
[77,25,22,4],
[77,25,22,5],
[77,25,22,6],
[77,25,22,8],
[77,25,22,11],
[77,25,22,14],
[77,25,22,16],
[77,25,22,17],
[77,25,22,19],
[77,25,22,21],
[77,25,23,0],
[77,25,23,1],
[77,25,23,2],
[77,25,23,3],
[77,25,23,4],
[77,25,23,5],
[77,25,23,6],
[77,25,23,8],
[77,25,23,12],
[77,25,23,13],
[77,25,23,14],
[77,25,23,16],
[77,25,23,17],
[77,25,23,19],
[77,25,23,20],
[77,25,23,21],
[77,25,23,22],
[77,25,24,0],
[77,25,24,1],
[77,25,24,2],
[77,25,24,3],
[77,25,24,4],
[77,25,24,5],
[77,25,24,6],
[77,25,24,8],
[77,25,24,12],
[77,25,24,13],
[77,25,24,14],
[77,25,24,17],
[77,25,24,19],
[77,25,24,20],
[77,25,24,21],
[77,25,24,22],
[77,25,24,23],
[77,27,3,0],
[77,27,3,1],
[77,27,3,2],
[77,27,4,0],
[77,27,4,1],
[77,27,4,2],
[77,27,4,3],
[77,27,5,0],
[77,27,5,1],
[77,27,5,2],
[77,27,5,4],
[77,27,6,0],
[77,27,6,1],
[77,27,6,2],
[77,27,6,4],
[77,27,7,0],
[77,27,7,1],
[77,27,7,2],
[77,27,7,3],
[77,27,7,4],
[77,27,7,5],
[77,27,7,6],
[77,27,8,0],
[77,27,8,1],
[77,27,8,2],
[77,27,8,4],
[77,27,8,7],
[77,27,11,0],
[77,27,11,1],
[77,27,11,2],
[77,27,11,3],
[77,27,11,4],
[77,27,11,5],
[77,27,11,6],
[77,27,11,7],
[77,27,11,8],
[77,27,12,0],
[77,27,12,1],
[77,27,12,2],
[77,27,12,3],
[77,27,12,4],
[77,27,12,5],
[77,27,12,6],
[77,27,12,7],
[77,27,12,8],
[77,27,13,0],
[77,27,13,1],
[77,27,13,2],
[77,27,13,3],
[77,27,13,4],
[77,27,13,5],
[77,27,13,6],
[77,27,13,7],
[77,27,13,8],
[77,27,13,11],
[77,27,13,12],
[77,27,14,0],
[77,27,14,1],
[77,27,14,2],
[77,27,14,3],
[77,27,14,4],
[77,27,14,5],
[77,27,14,6],
[77,27,14,7],
[77,27,14,8],
[77,27,14,11],
[77,27,14,12],
[77,27,16,0],
[77,27,16,1],
[77,27,16,2],
[77,27,16,3],
[77,27,16,4],
[77,27,16,5],
[77,27,16,6],
[77,27,16,7],
[77,27,16,8],
[77,27,16,11],
[77,27,16,12],
[77,27,16,13],
[77,27,16,14],
[77,27,17,0],
[77,27,17,1],
[77,27,17,2],
[77,27,17,3],
[77,27,17,4],
[77,27,17,5],
[77,27,17,6],
[77,27,17,7],
[77,27,17,8],
[77,27,17,11],
[77,27,17,12],
[77,27,17,13],
[77,27,17,14],
[77,27,19,0],
[77,27,19,1],
[77,27,19,2],
[77,27,19,3],
[77,27,19,4],
[77,27,19,5],
[77,27,19,6],
[77,27,19,7],
[77,27,19,8],
[77,27,19,11],
[77,27,19,12],
[77,27,19,16],
[77,27,19,17],
[77,27,21,0],
[77,27,21,1],
[77,27,21,2],
[77,27,21,4],
[77,27,21,7],
[77,27,21,12],
[77,27,21,13],
[77,27,21,14],
[77,27,21,16],
[77,27,21,17],
[77,27,21,19],
[77,27,23,0],
[77,27,23,1],
[77,27,23,2],
[77,27,23,3],
[77,27,23,4],
[77,27,23,5],
[77,27,23,8],
[77,27,23,11],
[77,27,23,12],
[77,27,23,13],
[77,27,23,14],
[77,27,23,16],
[77,27,23,17],
[77,27,23,19],
[77,27,23,21],
[77,27,24,0],
[77,27,24,1],
[77,27,24,2],
[77,27,24,3],
[77,27,24,4],
[77,27,24,5],
[77,27,24,8],
[77,27,24,11],
[77,27,24,12],
[77,27,24,13],
[77,27,24,14],
[77,27,24,17],
[77,27,24,19],
[77,27,24,21],
[77,27,24,23],
[77,27,25,0],
[77,27,25,1],
[77,27,25,2],
[77,27,25,5],
[77,27,25,6],
[77,27,25,8],
[77,27,25,11],
[77,27,25,12],
[77,27,25,13],
[77,27,25,14],
[77,27,25,16],
[77,27,25,17],
[77,27,25,19],
[77,27,25,21],
[77,28,3,0],
[77,28,3,1],
[77,28,3,2],
[77,28,4,0],
[77,28,4,1],
[77,28,4,2],
[77,28,4,3],
[77,28,5,0],
[77,28,5,1],
[77,28,5,2],
[77,28,5,4],
[77,28,6,0],
[77,28,6,1],
[77,28,6,2],
[77,28,6,4],
[77,28,8,0],
[77,28,8,1],
[77,28,8,2],
[77,28,8,4],
[77,28,10,0],
[77,28,10,1],
[77,28,10,2],
[77,28,10,3],
[77,28,10,4],
[77,28,10,5],
[77,28,10,6],
[77,28,10,8],
[77,28,11,0],
[77,28,11,1],
[77,28,11,2],
[77,28,11,3],
[77,28,11,4],
[77,28,11,5],
[77,28,11,6],
[77,28,11,8],
[77,28,11,10],
[77,28,12,0],
[77,28,12,1],
[77,28,12,2],
[77,28,12,3],
[77,28,12,4],
[77,28,12,5],
[77,28,12,6],
[77,28,12,8],
[77,28,12,10],
[77,28,13,0],
[77,28,13,1],
[77,28,13,2],
[77,28,13,3],
[77,28,13,4],
[77,28,13,5],
[77,28,13,6],
[77,28,13,8],
[77,28,13,10],
[77,28,13,11],
[77,28,13,12],
[77,28,14,0],
[77,28,14,1],
[77,28,14,2],
[77,28,14,3],
[77,28,14,4],
[77,28,14,5],
[77,28,14,6],
[77,28,14,8],
[77,28,14,10],
[77,28,14,11],
[77,28,14,12],
[77,28,16,0],
[77,28,16,1],
[77,28,16,2],
[77,28,16,3],
[77,28,16,4],
[77,28,16,5],
[77,28,16,6],
[77,28,16,8],
[77,28,16,10],
[77,28,16,11],
[77,28,16,12],
[77,28,16,13],
[77,28,16,14],
[77,28,17,0],
[77,28,17,1],
[77,28,17,2],
[77,28,17,3],
[77,28,17,4],
[77,28,17,5],
[77,28,17,6],
[77,28,17,8],
[77,28,17,10],
[77,28,17,11],
[77,28,17,12],
[77,28,17,13],
[77,28,17,14],
[77,28,17,16],
[77,28,19,0],
[77,28,19,1],
[77,28,19,2],
[77,28,19,3],
[77,28,19,4],
[77,28,19,5],
[77,28,19,6],
[77,28,19,8],
[77,28,19,10],
[77,28,19,11],
[77,28,19,16],
[77,28,19,17],
[77,28,20,0],
[77,28,20,1],
[77,28,20,2],
[77,28,20,3],
[77,28,20,4],
[77,28,20,5],
[77,28,20,6],
[77,28,20,8],
[77,28,20,12],
[77,28,20,13],
[77,28,20,14],
[77,28,20,16],
[77,28,20,17],
[77,28,20,19],
[77,28,21,0],
[77,28,21,1],
[77,28,21,2],
[77,28,21,4],
[77,28,21,10],
[77,28,21,11],
[77,28,21,12],
[77,28,21,13],
[77,28,21,14],
[77,28,21,16],
[77,28,21,17],
[77,28,21,19],
[77,28,21,20],
[77,28,22,0],
[77,28,22,1],
[77,28,22,2],
[77,28,22,3],
[77,28,22,4],
[77,28,22,5],
[77,28,22,8],
[77,28,22,11],
[77,28,22,12],
[77,28,22,13],
[77,28,22,14],
[77,28,22,16],
[77,28,22,17],
[77,28,22,19],
[77,28,22,21],
[77,28,23,0],
[77,28,23,1],
[77,28,23,2],
[77,28,23,3],
[77,28,23,4],
[77,28,23,6],
[77,28,23,8],
[77,28,23,10],
[77,28,23,11],
[77,28,23,12],
[77,28,23,13],
[77,28,23,14],
[77,28,23,16],
[77,28,23,17],
[77,28,23,19],
[77,28,23,20],
[77,28,23,21],
[77,28,23,22],
[77,28,24,0],
[77,28,24,1],
[77,28,24,2],
[77,28,24,3],
[77,28,24,4],
[77,28,24,6],
[77,28,24,8],
[77,28,24,10],
[77,28,24,11],
[77,28,24,12],
[77,28,24,13],
[77,28,24,14],
[77,28,24,17],
[77,28,24,19],
[77,28,24,20],
[77,28,24,21],
[77,28,24,22],
[77,28,27,0],
[77,28,27,1],
[77,28,27,2],
[77,28,27,3],
[77,28,27,4],
[77,28,27,5],
[77,28,27,6],
[77,28,27,8],
[77,28,27,11],
[77,28,27,12],
[77,28,27,13],
[77,28,27,14],
[77,28,27,16],
[77,28,27,17],
[77,29,3,0],
[77,29,3,1],
[77,29,3,2],
[77,29,4,0],
[77,29,4,1],
[77,29,4,2],
[77,29,4,3],
[77,29,5,0],
[77,29,5,1],
[77,29,5,2],
[77,29,5,4],
[77,29,6,0],
[77,29,6,1],
[77,29,6,2],
[77,29,6,4],
[77,29,7,0],
[77,29,7,1],
[77,29,7,2],
[77,29,7,3],
[77,29,7,4],
[77,29,7,5],
[77,29,7,6],
[77,29,8,0],
[77,29,8,1],
[77,29,8,2],
[77,29,8,4],
[77,29,8,7],
[77,29,10,0],
[77,29,10,1],
[77,29,10,2],
[77,29,10,3],
[77,29,10,4],
[77,29,10,5],
[77,29,10,6],
[77,29,10,7],
[77,29,10,8],
[77,29,13,0],
[77,29,13,1],
[77,29,13,2],
[77,29,13,3],
[77,29,13,4],
[77,29,13,5],
[77,29,13,6],
[77,29,13,7],
[77,29,13,8],
[77,29,13,10],
[77,29,14,0],
[77,29,14,1],
[77,29,14,2],
[77,29,14,3],
[77,29,14,4],
[77,29,14,5],
[77,29,14,6],
[77,29,14,7],
[77,29,14,8],
[77,29,14,10],
[77,29,16,0],
[77,29,16,1],
[77,29,16,2],
[77,29,16,3],
[77,29,16,4],
[77,29,16,5],
[77,29,16,6],
[77,29,16,7],
[77,29,16,8],
[77,29,16,10],
[77,29,16,13],
[77,29,17,0],
[77,29,17,1],
[77,29,17,2],
[77,29,17,3],
[77,29,17,4],
[77,29,17,5],
[77,29,17,6],
[77,29,17,7],
[77,29,17,8],
[77,29,17,10],
[77,29,17,13],
[77,29,17,16],
[77,29,19,0],
[77,29,19,1],
[77,29,19,2],
[77,29,19,3],
[77,29,19,4],
[77,29,19,5],
[77,29,19,6],
[77,29,19,7],
[77,29,19,8],
[77,29,19,16],
[77,29,19,17],
[77,29,20,0],
[77,29,20,1],
[77,29,20,2],
[77,29,20,3],
[77,29,20,4],
[77,29,20,5],
[77,29,20,6],
[77,29,20,7],
[77,29,20,13],
[77,29,20,14],
[77,29,20,16],
[77,29,20,17],
[77,29,20,19],
[77,29,21,0],
[77,29,21,1],
[77,29,21,2],
[77,29,21,4],
[77,29,21,10],
[77,29,21,13],
[77,29,21,14],
[77,29,21,16],
[77,29,21,17],
[77,29,21,19],
[77,29,21,20],
[77,29,22,0],
[77,29,22,1],
[77,29,22,2],
[77,29,22,3],
[77,29,22,4],
[77,29,22,6],
[77,29,22,7],
[77,29,22,8],
[77,29,22,13],
[77,29,22,14],
[77,29,22,16],
[77,29,22,17],
[77,29,22,19],
[77,29,22,21],
[77,29,23,0],
[77,29,23,1],
[77,29,23,2],
[77,29,23,5],
[77,29,23,6],
[77,29,23,7],
[77,29,23,8],
[77,29,23,10],
[77,29,23,13],
[77,29,23,14],
[77,29,23,16],
[77,29,23,17],
[77,29,23,19],
[77,29,23,20],
[77,29,23,21],
[77,29,24,0],
[77,29,24,1],
[77,29,24,2],
[77,29,24,5],
[77,29,24,6],
[77,29,24,7],
[77,29,24,8],
[77,29,24,10],
[77,29,24,13],
[77,29,24,14],
[77,29,24,17],
[77,29,24,19],
[77,29,24,20],
[77,29,24,21],
[77,29,25,0],
[77,29,25,1],
[77,29,25,2],
[77,29,25,3],
[77,29,25,4],
[77,29,25,5],
[77,29,25,6],
[77,29,25,8],
[77,29,25,10],
[77,29,25,13],
[77,29,25,14],
[77,29,25,16],
[77,29,25,17],
[77,29,25,19],
[77,29,27,0],
[77,29,27,1],
[77,29,27,2],
[77,29,27,3],
[77,29,27,4],
[77,29,27,5],
[77,29,27,6],
[77,29,27,7],
[77,29,27,8],
[77,29,27,13],
[77,29,27,14],
[77,29,27,16],
[77,29,27,17],
[77,29,28,0],
[77,29,28,1],
[77,29,28,2],
[77,29,28,3],
[77,29,28,4],
[77,29,28,5],
[77,29,28,6],
[77,29,28,8],
[77,29,28,10],
[77,29,28,13],
[77,29,28,14],
[77,30,3,0],
[77,30,3,1],
[77,30,3,2],
[77,30,4,0],
[77,30,4,1],
[77,30,4,2],
[77,30,4,3],
[77,30,5,0],
[77,30,5,1],
[77,30,5,2],
[77,30,5,4],
[77,30,6,0],
[77,30,6,1],
[77,30,6,2],
[77,30,6,4],
[77,30,7,0],
[77,30,7,1],
[77,30,7,2],
[77,30,7,3],
[77,30,7,4],
[77,30,7,5],
[77,30,7,6],
[77,30,8,0],
[77,30,8,1],
[77,30,8,2],
[77,30,8,4],
[77,30,8,7],
[77,30,10,0],
[77,30,10,1],
[77,30,10,2],
[77,30,10,3],
[77,30,10,4],
[77,30,10,5],
[77,30,10,6],
[77,30,10,7],
[77,30,10,8],
[77,30,11,0],
[77,30,11,1],
[77,30,11,2],
[77,30,11,3],
[77,30,11,4],
[77,30,11,5],
[77,30,11,6],
[77,30,11,7],
[77,30,11,8],
[77,30,11,10],
[77,30,12,0],
[77,30,12,1],
[77,30,12,2],
[77,30,12,3],
[77,30,12,4],
[77,30,12,5],
[77,30,12,6],
[77,30,12,7],
[77,30,12,8],
[77,30,12,10],
[77,30,13,0],
[77,30,13,1],
[77,30,13,2],
[77,30,13,3],
[77,30,13,4],
[77,30,13,5],
[77,30,13,6],
[77,30,13,7],
[77,30,13,8],
[77,30,13,10],
[77,30,13,11],
[77,30,13,12],
[77,30,14,0],
[77,30,14,1],
[77,30,14,2],
[77,30,14,3],
[77,30,14,4],
[77,30,14,5],
[77,30,14,6],
[77,30,14,7],
[77,30,14,8],
[77,30,14,10],
[77,30,14,11],
[77,30,14,12],
[77,30,16,0],
[77,30,16,1],
[77,30,16,2],
[77,30,16,3],
[77,30,16,4],
[77,30,16,5],
[77,30,16,6],
[77,30,16,7],
[77,30,16,8],
[77,30,16,10],
[77,30,16,11],
[77,30,16,14],
[77,30,17,0],
[77,30,17,1],
[77,30,17,2],
[77,30,17,3],
[77,30,17,4],
[77,30,17,5],
[77,30,17,6],
[77,30,17,7],
[77,30,17,8],
[77,30,17,10],
[77,30,17,11],
[77,30,17,14],
[77,30,17,16],
[77,30,19,0],
[77,30,19,1],
[77,30,19,2],
[77,30,19,3],
[77,30,19,4],
[77,30,19,5],
[77,30,19,6],
[77,30,19,7],
[77,30,19,10],
[77,30,19,11],
[77,30,19,12],
[77,30,19,16],
[77,30,19,17],
[77,30,20,0],
[77,30,20,1],
[77,30,20,2],
[77,30,20,3],
[77,30,20,4],
[77,30,20,5],
[77,30,20,8],
[77,30,20,11],
[77,30,20,12],
[77,30,20,13],
[77,30,20,14],
[77,30,20,16],
[77,30,20,17],
[77,30,20,19],
[77,30,21,0],
[77,30,21,1],
[77,30,21,2],
[77,30,21,4],
[77,30,21,7],
[77,30,21,10],
[77,30,21,11],
[77,30,21,12],
[77,30,21,13],
[77,30,21,14],
[77,30,21,16],
[77,30,21,17],
[77,30,21,19],
[77,30,21,20],
[77,30,22,0],
[77,30,22,1],
[77,30,22,2],
[77,30,22,5],
[77,30,22,6],
[77,30,22,7],
[77,30,22,8],
[77,30,22,11],
[77,30,22,12],
[77,30,22,13],
[77,30,22,14],
[77,30,22,16],
[77,30,22,17],
[77,30,22,19],
[77,30,22,21],
[77,30,24,3],
[77,30,24,4],
[77,30,24,5],
[77,30,24,6],
[77,30,24,7],
[77,30,24,8],
[77,30,24,10],
[77,30,24,11],
[77,30,24,12],
[77,30,24,13],
[77,30,24,14],
[77,30,24,17],
[77,30,24,19],
[77,30,24,20],
[77,30,25,0],
[77,30,25,1],
[77,30,25,2],
[77,30,25,3],
[77,30,25,4],
[77,30,25,5],
[77,30,25,6],
[77,30,25,8],
[77,30,25,10],
[77,30,25,11],
[77,30,25,12],
[77,30,25,13],
[77,30,25,14],
[77,30,25,16],
[77,30,25,17],
[77,30,27,0],
[77,30,27,1],
[77,30,27,2],
[77,30,27,3],
[77,30,27,4],
[77,30,27,5],
[77,30,27,6],
[77,30,27,7],
[77,30,27,8],
[77,30,27,11],
[77,30,27,12],
[77,30,27,13],
[77,30,27,14],
[77,30,28,0],
[77,30,28,1],
[77,30,28,2],
[77,30,28,3],
[77,30,28,4],
[77,30,28,5],
[77,30,28,6],
[77,30,28,8],
[77,30,28,10],
[77,30,28,11],
[77,30,28,12],
[77,30,28,13],
[77,30,28,14],
[77,30,29,0],
[77,30,29,1],
[77,30,29,2],
[77,30,29,3],
[77,30,29,4],
[77,30,29,5],
[77,30,29,6],
[77,30,29,7],
[77,30,29,8],
[77,30,29,10],
[77,30,29,13],
[77,31,3,0],
[77,31,3,1],
[77,31,3,2],
[77,31,4,0],
[77,31,4,1],
[77,31,4,2],
[77,31,4,3],
[77,31,5,0],
[77,31,5,1],
[77,31,5,2],
[77,31,5,4],
[77,31,6,0],
[77,31,6,1],
[77,31,6,2],
[77,31,6,4],
[77,31,7,0],
[77,31,7,1],
[77,31,7,2],
[77,31,7,3],
[77,31,7,4],
[77,31,7,5],
[77,31,7,6],
[77,31,8,0],
[77,31,8,1],
[77,31,8,2],
[77,31,8,4],
[77,31,8,7],
[77,31,10,0],
[77,31,10,1],
[77,31,10,2],
[77,31,10,3],
[77,31,10,4],
[77,31,10,5],
[77,31,10,6],
[77,31,10,7],
[77,31,10,8],
[77,31,11,0],
[77,31,11,1],
[77,31,11,2],
[77,31,11,3],
[77,31,11,4],
[77,31,11,5],
[77,31,11,6],
[77,31,11,7],
[77,31,11,8],
[77,31,11,10],
[77,31,12,0],
[77,31,12,1],
[77,31,12,2],
[77,31,12,3],
[77,31,12,4],
[77,31,12,5],
[77,31,12,6],
[77,31,12,7],
[77,31,12,8],
[77,31,12,10],
[77,31,13,0],
[77,31,13,1],
[77,31,13,2],
[77,31,13,3],
[77,31,13,4],
[77,31,13,5],
[77,31,13,6],
[77,31,13,7],
[77,31,13,8],
[77,31,13,10],
[77,31,13,11],
[77,31,13,12],
[77,31,14,0],
[77,31,14,1],
[77,31,14,2],
[77,31,14,3],
[77,31,14,4],
[77,31,14,5],
[77,31,14,6],
[77,31,14,7],
[77,31,14,8],
[77,31,14,10],
[77,31,14,11],
[77,31,14,12],
[77,31,16,0],
[77,31,16,1],
[77,31,16,2],
[77,31,16,3],
[77,31,16,4],
[77,31,16,5],
[77,31,16,6],
[77,31,16,7],
[77,31,16,8],
[77,31,16,12],
[77,31,16,13],
[77,31,16,14],
[77,31,17,0],
[77,31,17,1],
[77,31,17,2],
[77,31,17,3],
[77,31,17,4],
[77,31,17,5],
[77,31,17,6],
[77,31,17,7],
[77,31,17,8],
[77,31,17,12],
[77,31,17,13],
[77,31,17,14],
[77,31,17,16],
[77,31,19,0],
[77,31,19,1],
[77,31,19,2],
[77,31,19,3],
[77,31,19,4],
[77,31,19,5],
[77,31,19,8],
[77,31,19,10],
[77,31,19,11],
[77,31,19,12],
[77,31,19,16],
[77,31,19,17],
[77,31,20,0],
[77,31,20,1],
[77,31,20,2],
[77,31,20,3],
[77,31,20,4],
[77,31,20,6],
[77,31,20,7],
[77,31,20,8],
[77,31,20,11],
[77,31,20,12],
[77,31,20,13],
[77,31,20,14],
[77,31,20,16],
[77,31,20,17],
[77,31,20,19],
[77,31,21,0],
[77,31,21,1],
[77,31,21,2],
[77,31,21,7],
[77,31,21,10],
[77,31,21,11],
[77,31,21,12],
[77,31,21,13],
[77,31,21,14],
[77,31,21,16],
[77,31,21,17],
[77,31,21,19],
[77,31,21,20],
[77,31,22,3],
[77,31,22,4],
[77,31,22,5],
[77,31,22,6],
[77,31,22,7],
[77,31,22,8],
[77,31,22,11],
[77,31,22,12],
[77,31,22,13],
[77,31,22,14],
[77,31,22,16],
[77,31,22,17],
[77,31,22,19],
[77,31,24,0],
[77,31,24,1],
[77,31,24,2],
[77,31,24,3],
[77,31,24,4],
[77,31,24,5],
[77,31,24,6],
[77,31,24,7],
[77,31,24,8],
[77,31,24,10],
[77,31,24,11],
[77,31,24,12],
[77,31,24,13],
[77,31,24,14],
[77,31,24,17],
[77,31,24,19],
[77,31,25,0],
[77,31,25,1],
[77,31,25,2],
[77,31,25,3],
[77,31,25,4],
[77,31,25,5],
[77,31,25,6],
[77,31,25,8],
[77,31,25,10],
[77,31,25,11],
[77,31,25,12],
[77,31,25,13],
[77,31,25,14],
[77,31,25,16],
[77,31,25,17],
[77,31,27,0],
[77,31,27,1],
[77,31,27,2],
[77,31,27,3],
[77,31,27,4],
[77,31,27,5],
[77,31,27,6],
[77,31,27,7],
[77,31,27,8],
[77,31,27,11],
[77,31,27,12],
[77,31,27,13],
[77,31,27,14],
[77,31,28,0],
[77,31,28,1],
[77,31,28,2],
[77,31,28,3],
[77,31,28,4],
[77,31,28,5],
[77,31,28,6],
[77,31,28,8],
[77,31,28,10],
[77,31,28,11],
[77,31,28,12],
[77,31,28,13],
[77,31,29,0],
[77,31,29,1],
[77,31,29,2],
[77,31,29,3],
[77,31,29,4],
[77,31,29,5],
[77,31,29,6],
[77,31,29,7],
[77,31,29,8],
[77,31,29,10],
[77,32,3,0],
[77,32,3,1],
[77,32,3,2],
[77,32,4,0],
[77,32,4,1],
[77,32,4,2],
[77,32,4,3],
[77,32,5,0],
[77,32,5,1],
[77,32,5,2],
[77,32,5,4],
[77,32,6,0],
[77,32,6,1],
[77,32,6,2],
[77,32,6,4],
[77,32,7,0],
[77,32,7,1],
[77,32,7,2],
[77,32,7,3],
[77,32,7,4],
[77,32,7,5],
[77,32,7,6],
[77,32,8,0],
[77,32,8,1],
[77,32,8,2],
[77,32,8,4],
[77,32,8,7],
[77,32,10,0],
[77,32,10,1],
[77,32,10,2],
[77,32,10,3],
[77,32,10,4],
[77,32,10,5],
[77,32,10,6],
[77,32,10,7],
[77,32,10,8],
[77,32,13,0],
[77,32,13,1],
[77,32,13,2],
[77,32,13,3],
[77,32,13,4],
[77,32,13,5],
[77,32,13,6],
[77,32,13,7],
[77,32,13,8],
[77,32,13,10],
[77,32,14,0],
[77,32,14,1],
[77,32,14,2],
[77,32,14,3],
[77,32,14,4],
[77,32,14,5],
[77,32,14,6],
[77,32,14,7],
[77,32,14,8],
[77,32,14,10],
[77,32,16,0],
[77,32,16,1],
[77,32,16,2],
[77,32,16,3],
[77,32,16,4],
[77,32,16,5],
[77,32,16,6],
[77,32,16,7],
[77,32,16,10],
[77,32,16,13],
[77,32,16,14],
[77,32,17,0],
[77,32,17,1],
[77,32,17,2],
[77,32,17,3],
[77,32,17,4],
[77,32,17,5],
[77,32,17,6],
[77,32,17,7],
[77,32,17,10],
[77,32,17,13],
[77,32,17,14],
[77,32,17,16],
[77,32,19,0],
[77,32,19,1],
[77,32,19,2],
[77,32,19,3],
[77,32,19,4],
[77,32,19,6],
[77,32,19,7],
[77,32,19,8],
[77,32,19,10],
[77,32,19,16],
[77,32,19,17],
[77,32,20,0],
[77,32,20,1],
[77,32,20,2],
[77,32,20,5],
[77,32,20,6],
[77,32,20,7],
[77,32,20,8],
[77,32,20,13],
[77,32,20,14],
[77,32,20,16],
[77,32,20,17],
[77,32,20,19],
[77,32,21,4],
[77,32,21,7],
[77,32,21,10],
[77,32,21,13],
[77,32,21,14],
[77,32,21,16],
[77,32,21,17],
[77,32,21,19],
[77,32,21,20],
[77,32,22,0],
[77,32,22,1],
[77,32,22,2],
[77,32,22,3],
[77,32,22,4],
[77,32,22,5],
[77,32,22,6],
[77,32,22,7],
[77,32,22,8],
[77,32,22,13],
[77,32,22,14],
[77,32,22,16],
[77,32,22,17],
[77,32,22,19],
[77,32,23,0],
[77,32,23,1],
[77,32,23,2],
[77,32,23,3],
[77,32,23,4],
[77,32,23,5],
[77,32,23,6],
[77,32,23,7],
[77,32,23,8],
[77,32,23,10],
[77,32,23,13],
[77,32,23,14],
[77,32,23,16],
[77,32,23,17],
[77,32,24,0],
[77,32,24,1],
[77,32,24,2],
[77,32,24,3],
[77,32,24,4],
[77,32,24,5],
[77,32,24,6],
[77,32,24,7],
[77,32,24,8],
[77,32,24,10],
[77,32,24,13],
[77,32,24,14],
[77,32,24,17],
[77,32,25,0],
[77,32,25,1],
[77,32,25,2],
[77,32,25,3],
[77,32,25,4],
[77,32,25,5],
[77,32,25,6],
[77,32,25,8],
[77,32,25,10],
[77,32,25,13],
[77,32,25,14],
[77,32,27,0],
[77,32,27,1],
[77,32,27,2],
[77,32,27,3],
[77,32,27,4],
[77,32,27,5],
[77,32,27,6],
[77,32,27,7],
[77,32,27,8],
[77,32,27,13],
[77,32,28,0],
[77,32,28,1],
[77,32,28,2],
[77,32,28,3],
[77,32,28,4],
[77,32,28,5],
[77,32,28,6],
[77,32,28,8],
[77,32,28,10],
[77,32,30,0],
[77,32,30,1],
[77,32,30,2],
[77,32,30,3],
[77,32,30,4],
[77,32,30,5],
[77,32,30,6],
[77,32,30,7],
[77,32,31,0],
[77,32,31,1],
[77,32,31,2],
[77,32,31,3],
[77,32,31,4],
[77,32,31,5],
[77,33,3,0],
[77,33,3,1],
[77,33,3,2],
[77,33,4,0],
[77,33,4,1],
[77,33,4,2],
[77,33,4,3],
[77,33,5,0],
[77,33,5,1],
[77,33,5,2],
[77,33,5,4],
[77,33,6,0],
[77,33,6,1],
[77,33,6,2],
[77,33,6,4],
[77,33,7,0],
[77,33,7,1],
[77,33,7,2],
[77,33,7,3],
[77,33,7,4],
[77,33,7,5],
[77,33,7,6],
[77,33,8,0],
[77,33,8,1],
[77,33,8,2],
[77,33,8,4],
[77,33,8,7],
[77,33,10,0],
[77,33,10,1],
[77,33,10,2],
[77,33,10,3],
[77,33,10,4],
[77,33,10,5],
[77,33,10,6],
[77,33,10,7],
[77,33,10,8],
[77,33,13,0],
[77,33,13,1],
[77,33,13,2],
[77,33,13,3],
[77,33,13,4],
[77,33,13,5],
[77,33,13,6],
[77,33,13,7],
[77,33,13,8],
[77,33,13,10],
[77,33,14,0],
[77,33,14,1],
[77,33,14,2],
[77,33,14,3],
[77,33,14,4],
[77,33,14,5],
[77,33,14,6],
[77,33,14,7],
[77,33,14,8],
[77,33,16,0],
[77,33,16,1],
[77,33,16,2],
[77,33,16,3],
[77,33,16,4],
[77,33,16,5],
[77,33,16,8],
[77,33,16,10],
[77,33,16,13],
[77,33,16,14],
[77,33,17,0],
[77,33,17,1],
[77,33,17,2],
[77,33,17,3],
[77,33,17,4],
[77,33,17,5],
[77,33,17,8],
[77,33,17,10],
[77,33,17,13],
[77,33,17,14],
[77,33,17,16],
[77,33,19,0],
[77,33,19,1],
[77,33,19,2],
[77,33,19,5],
[77,33,19,6],
[77,33,19,7],
[77,33,19,8],
[77,33,19,10],
[77,33,19,16],
[77,33,19,17],
[77,33,20,3],
[77,33,20,4],
[77,33,20,5],
[77,33,20,6],
[77,33,20,7],
[77,33,20,8],
[77,33,20,13],
[77,33,20,14],
[77,33,20,16],
[77,33,20,17],
[77,33,20,19],
[77,33,21,0],
[77,33,21,1],
[77,33,21,2],
[77,33,21,4],
[77,33,21,7],
[77,33,21,10],
[77,33,21,13],
[77,33,21,14],
[77,33,21,16],
[77,33,21,17],
[77,33,21,19],
[77,33,22,0],
[77,33,22,1],
[77,33,22,2],
[77,33,22,3],
[77,33,22,4],
[77,33,22,5],
[77,33,22,6],
[77,33,22,7],
[77,33,22,8],
[77,33,22,13],
[77,33,22,14],
[77,33,22,16],
[77,33,22,17],
[77,33,23,0],
[77,33,23,1],
[77,33,23,2],
[77,33,23,3],
[77,33,23,4],
[77,33,23,5],
[77,33,23,6],
[77,33,23,7],
[77,33,23,8],
[77,33,23,10],
[77,33,23,13],
[77,33,23,14],
[77,33,23,16],
[77,33,23,17],
[77,33,24,0],
[77,33,24,1],
[77,33,24,2],
[77,33,24,3],
[77,33,24,4],
[77,33,24,5],
[77,33,24,6],
[77,33,24,7],
[77,33,24,8],
[77,33,24,10],
[77,33,24,13],
[77,33,24,14],
[77,33,24,17],
[77,33,25,0],
[77,33,25,1],
[77,33,25,2],
[77,33,25,3],
[77,33,25,4],
[77,33,25,5],
[77,33,25,6],
[77,33,25,8],
[77,33,25,10],
[77,33,25,13],
[77,33,25,14],
[77,33,27,0],
[77,33,27,1],
[77,33,27,2],
[77,33,27,3],
[77,33,27,4],
[77,33,27,5],
[77,33,27,6],
[77,33,27,7],
[77,33,27,8],
[77,33,28,0],
[77,33,28,1],
[77,33,28,2],
[77,33,28,3],
[77,33,28,4],
[77,33,28,5],
[77,33,28,6],
[77,33,28,8],
[77,33,30,0],
[77,33,30,1],
[77,33,30,2],
[77,33,30,3],
[77,33,30,4],
[77,33,30,5],
[77,33,31,0],
[77,33,31,1],
[77,33,31,2],
[77,33,31,3],
[77,33,31,4],
[77,34,3,0],
[77,34,3,1],
[77,34,3,2],
[77,34,4,0],
[77,34,4,1],
[77,34,4,2],
[77,34,4,3],
[77,34,5,0],
[77,34,5,1],
[77,34,5,2],
[77,34,5,4],
[77,34,6,0],
[77,34,6,1],
[77,34,6,2],
[77,34,6,4],
[77,34,8,0],
[77,34,8,1],
[77,34,8,2],
[77,34,8,4],
[77,34,10,0],
[77,34,10,1],
[77,34,10,2],
[77,34,10,3],
[77,34,10,4],
[77,34,10,5],
[77,34,10,6],
[77,34,10,8],
[77,34,11,0],
[77,34,11,1],
[77,34,11,2],
[77,34,11,3],
[77,34,11,4],
[77,34,11,5],
[77,34,11,6],
[77,34,11,8],
[77,34,11,10],
[77,34,12,0],
[77,34,12,1],
[77,34,12,2],
[77,34,12,3],
[77,34,12,4],
[77,34,12,5],
[77,34,12,6],
[77,34,12,8],
[77,34,13,0],
[77,34,13,1],
[77,34,13,2],
[77,34,13,3],
[77,34,13,4],
[77,34,13,5],
[77,34,13,6],
[77,34,13,8],
[77,34,13,12],
[77,34,14,0],
[77,34,14,1],
[77,34,14,2],
[77,34,14,3],
[77,34,14,4],
[77,34,14,5],
[77,34,14,6],
[77,34,14,10],
[77,34,14,11],
[77,34,14,12],
[77,34,16,0],
[77,34,16,1],
[77,34,16,2],
[77,34,16,3],
[77,34,16,4],
[77,34,16,6],
[77,34,16,8],
[77,34,16,10],
[77,34,16,11],
[77,34,16,12],
[77,34,16,13],
[77,34,16,14],
[77,34,17,0],
[77,34,17,1],
[77,34,17,2],
[77,34,17,3],
[77,34,17,4],
[77,34,17,6],
[77,34,17,8],
[77,34,17,10],
[77,34,17,11],
[77,34,17,12],
[77,34,17,13],
[77,34,17,14],
[77,34,17,16],
[77,34,19,3],
[77,34,19,4],
[77,34,19,5],
[77,34,19,6],
[77,34,19,8],
[77,34,19,10],
[77,34,19,11],
[77,34,19,12],
[77,34,19,16],
[77,34,19,17],
[77,34,20,0],
[77,34,20,1],
[77,34,20,2],
[77,34,20,3],
[77,34,20,4],
[77,34,20,5],
[77,34,20,6],
[77,34,20,8],
[77,34,20,11],
[77,34,20,12],
[77,34,20,13],
[77,34,20,14],
[77,34,20,16],
[77,34,20,17],
[77,34,20,19],
[77,34,21,0],
[77,34,21,1],
[77,34,21,2],
[77,34,21,4],
[77,34,21,10],
[77,34,21,11],
[77,34,21,12],
[77,34,21,13],
[77,34,21,14],
[77,34,21,16],
[77,34,21,17],
[77,34,22,0],
[77,34,22,1],
[77,34,22,2],
[77,34,22,3],
[77,34,22,4],
[77,34,22,5],
[77,34,22,6],
[77,34,22,8],
[77,34,22,11],
[77,34,22,12],
[77,34,22,13],
[77,34,22,14],
[77,34,22,16],
[77,34,22,17],
[77,34,23,0],
[77,34,23,1],
[77,34,23,2],
[77,34,23,3],
[77,34,23,4],
[77,34,23,5],
[77,34,23,6],
[77,34,23,8],
[77,34,23,10],
[77,34,23,11],
[77,34,23,12],
[77,34,23,13],
[77,34,23,14],
[77,34,24,0],
[77,34,24,1],
[77,34,24,2],
[77,34,24,3],
[77,34,24,4],
[77,34,24,5],
[77,34,24,6],
[77,34,24,8],
[77,34,24,10],
[77,34,24,11],
[77,34,24,12],
[77,34,24,13],
[77,34,24,14],
[77,34,27,0],
[77,34,27,1],
[77,34,27,2],
[77,34,27,3],
[77,34,27,4],
[77,34,27,5],
[77,34,27,6],
[77,34,27,8],
[77,34,29,0],
[77,34,29,1],
[77,34,29,2],
[77,34,29,3],
[77,34,29,4],
[77,34,29,5],
[77,34,30,0],
[77,34,30,1],
[77,34,30,2],
[77,34,30,3],
[77,34,30,4],
[77,34,31,0],
[77,34,31,1],
[77,34,31,2],
[77,36,3,0],
[77,36,3,1],
[77,36,3,2],
[77,36,4,0],
[77,36,4,1],
[77,36,4,2],
[77,36,4,3],
[77,36,5,0],
[77,36,5,1],
[77,36,5,2],
[77,36,5,4],
[77,36,6,0],
[77,36,6,1],
[77,36,6,2],
[77,36,6,4],
[77,36,7,0],
[77,36,7,1],
[77,36,7,2],
[77,36,7,3],
[77,36,7,4],
[77,36,7,5],
[77,36,7,6],
[77,36,8,0],
[77,36,8,1],
[77,36,8,2],
[77,36,8,4],
[77,36,8,7],
[77,36,10,0],
[77,36,10,1],
[77,36,10,2],
[77,36,10,3],
[77,36,10,4],
[77,36,10,5],
[77,36,10,6],
[77,36,10,7],
[77,36,11,0],
[77,36,11,1],
[77,36,11,2],
[77,36,11,3],
[77,36,11,4],
[77,36,11,5],
[77,36,11,6],
[77,36,11,7],
[77,36,11,10],
[77,36,12,0],
[77,36,12,1],
[77,36,12,2],
[77,36,12,3],
[77,36,12,4],
[77,36,12,5],
[77,36,12,8],
[77,36,12,10],
[77,36,16,3],
[77,36,16,4],
[77,36,16,5],
[77,36,16,6],
[77,36,16,7],
[77,36,16,8],
[77,36,16,10],
[77,36,16,11],
[77,36,16,12],
[77,36,17,3],
[77,36,17,4],
[77,36,17,5],
[77,36,17,6],
[77,36,17,7],
[77,36,17,8],
[77,36,17,10],
[77,36,17,11],
[77,36,17,12],
[77,36,17,16],
[77,36,20,0],
[77,36,20,1],
[77,36,20,2],
[77,36,20,3],
[77,36,20,4],
[77,36,20,5],
[77,36,20,6],
[77,36,20,7],
[77,36,20,8],
[77,36,20,11],
[77,36,20,12],
[77,36,20,16],
[77,36,20,17],
[77,36,21,0],
[77,36,21,1],
[77,36,21,2],
[77,36,21,4],
[77,36,21,7],
[77,36,21,10],
[77,36,21,11],
[77,36,21,12],
[77,36,22,0],
[77,36,22,1],
[77,36,22,2],
[77,36,22,3],
[77,36,22,4],
[77,36,22,5],
[77,36,22,6],
[77,36,22,7],
[77,36,22,8],
[77,36,22,11],
[77,36,22,12],
[77,36,23,0],
[77,36,23,1],
[77,36,23,2],
[77,36,23,3],
[77,36,23,4],
[77,36,23,5],
[77,36,23,6],
[77,36,23,7],
[77,36,23,8],
[77,36,23,10],
[77,36,23,11],
[77,36,23,12],
[77,36,24,0],
[77,36,24,1],
[77,36,24,2],
[77,36,24,3],
[77,36,24,4],
[77,36,24,5],
[77,36,24,6],
[77,36,24,7],
[77,36,24,8],
[77,36,24,10],
[77,36,24,11],
[77,36,24,12],
[77,36,25,0],
[77,36,25,1],
[77,36,25,2],
[77,36,25,3],
[77,36,25,4],
[77,36,25,5],
[77,36,25,6],
[77,36,25,8],
[77,36,27,0],
[77,36,27,1],
[77,36,27,2],
[77,36,27,3],
[77,36,27,4],
[77,36,27,5],
[77,36,28,0],
[77,36,28,1],
[77,36,28,2],
[77,36,28,3],
[77,36,28,4],
[77,36,29,0],
[77,36,29,1],
[77,36,29,2],
[77,37,3,0],
[77,37,3,1],
[77,37,3,2],
[77,37,5,0],
[77,37,5,1],
[77,37,5,2],
[77,37,6,0],
[77,37,6,1],
[77,37,6,2],
[77,37,7,0],
[77,37,7,1],
[77,37,7,2],
[77,37,7,3],
[77,37,7,5],
[77,37,7,6],
[77,37,8,0],
[77,37,8,1],
[77,37,8,2],
[77,37,8,7],
[77,37,10,0],
[77,37,10,1],
[77,37,10,2],
[77,37,10,3],
[77,37,10,5],
[77,37,10,8],
[77,37,11,0],
[77,37,11,1],
[77,37,11,2],
[77,37,11,3],
[77,37,11,5],
[77,37,11,8],
[77,37,11,10],
[77,37,12,0],
[77,37,12,1],
[77,37,12,2],
[77,37,12,3],
[77,37,12,6],
[77,37,12,7],
[77,37,12,8],
[77,37,12,10],
[77,37,13,0],
[77,37,13,1],
[77,37,13,2],
[77,37,13,3],
[77,37,13,6],
[77,37,13,7],
[77,37,13,8],
[77,37,13,10],
[77,37,13,11],
[77,37,13,12],
[77,37,14,0],
[77,37,14,1],
[77,37,14,2],
[77,37,14,5],
[77,37,14,6],
[77,37,14,7],
[77,37,14,8],
[77,37,14,10],
[77,37,14,11],
[77,37,14,12],
[77,37,16,0],
[77,37,16,1],
[77,37,16,2],
[77,37,16,3],
[77,37,16,5],
[77,37,16,6],
[77,37,16,7],
[77,37,16,8],
[77,37,16,10],
[77,37,16,11],
[77,37,16,12],
[77,37,16,13],
[77,37,16,14],
[77,37,17,0],
[77,37,17,1],
[77,37,17,2],
[77,37,17,3],
[77,37,17,5],
[77,37,17,6],
[77,37,17,7],
[77,37,17,8],
[77,37,17,10],
[77,37,17,11],
[77,37,17,12],
[77,37,17,13],
[77,37,17,14],
[77,37,17,16],
[77,37,19,0],
[77,37,19,1],
[77,37,19,2],
[77,37,19,3],
[77,37,19,5],
[77,37,19,6],
[77,37,19,7],
[77,37,19,8],
[77,37,19,10],
[77,37,19,11],
[77,37,19,12],
[77,37,19,16],
[77,37,19,17],
[77,37,20,0],
[77,37,20,1],
[77,37,20,2],
[77,37,20,3],
[77,37,20,5],
[77,37,20,6],
[77,37,20,7],
[77,37,20,8],
[77,37,20,11],
[77,37,20,12],
[77,37,20,13],
[77,37,20,14],
[77,37,21,0],
[77,37,21,1],
[77,37,21,2],
[77,37,21,7],
[77,37,21,10],
[77,37,21,11],
[77,37,21,12],
[77,37,21,13],
[77,37,21,14],
[77,37,22,0],
[77,37,22,1],
[77,37,22,2],
[77,37,22,3],
[77,37,22,5],
[77,37,22,6],
[77,37,22,7],
[77,37,22,8],
[77,37,22,11],
[77,37,22,12],
[77,37,22,13],
[77,37,23,0],
[77,37,23,1],
[77,37,23,2],
[77,37,23,3],
[77,37,23,5],
[77,37,23,6],
[77,37,23,7],
[77,37,23,8],
[77,37,23,10],
[77,37,23,11],
[77,37,24,0],
[77,37,24,1],
[77,37,24,2],
[77,37,24,3],
[77,37,24,5],
[77,37,24,6],
[77,37,24,7],
[77,37,24,8],
[77,37,24,10],
[77,37,24,11],
[77,37,25,0],
[77,37,25,1],
[77,37,25,2],
[77,37,25,3],
[77,37,25,5],
[77,37,25,6],
[77,37,27,0],
[77,37,27,1],
[77,37,27,2],
[77,37,27,3],
[77,37,28,0],
[77,37,28,1],
[77,37,28,2],
[77,38,3,0],
[77,38,3,1],
[77,38,3,2],
[77,38,4,0],
[77,38,4,1],
[77,38,4,2],
[77,38,4,3],
[77,38,5,0],
[77,38,5,1],
[77,38,5,2],
[77,38,5,4],
[77,38,6,0],
[77,38,6,1],
[77,38,6,2],
[77,38,6,4],
[77,38,8,0],
[77,38,8,1],
[77,38,8,2],
[77,38,8,4],
[77,38,10,0],
[77,38,10,1],
[77,38,10,2],
[77,38,10,3],
[77,38,10,4],
[77,38,10,6],
[77,38,10,8],
[77,38,11,0],
[77,38,11,1],
[77,38,11,2],
[77,38,11,3],
[77,38,11,4],
[77,38,11,6],
[77,38,11,8],
[77,38,11,10],
[77,38,12,0],
[77,38,12,1],
[77,38,12,2],
[77,38,12,5],
[77,38,12,6],
[77,38,12,8],
[77,38,12,10],
[77,38,13,0],
[77,38,13,1],
[77,38,13,2],
[77,38,13,5],
[77,38,13,6],
[77,38,13,8],
[77,38,13,10],
[77,38,13,11],
[77,38,13,12],
[77,38,14,3],
[77,38,14,4],
[77,38,14,5],
[77,38,14,6],
[77,38,14,8],
[77,38,14,10],
[77,38,14,11],
[77,38,14,12],
[77,38,16,0],
[77,38,16,1],
[77,38,16,2],
[77,38,16,3],
[77,38,16,4],
[77,38,16,5],
[77,38,16,6],
[77,38,16,8],
[77,38,16,10],
[77,38,16,11],
[77,38,16,12],
[77,38,16,13],
[77,38,16,14],
[77,38,17,0],
[77,38,17,1],
[77,38,17,2],
[77,38,17,3],
[77,38,17,4],
[77,38,17,5],
[77,38,17,6],
[77,38,17,8],
[77,38,17,10],
[77,38,17,11],
[77,38,17,12],
[77,38,17,13],
[77,38,17,14],
[77,38,17,16],
[77,38,19,0],
[77,38,19,1],
[77,38,19,2],
[77,38,19,3],
[77,38,19,4],
[77,38,19,5],
[77,38,19,6],
[77,38,19,8],
[77,38,19,10],
[77,38,19,11],
[77,38,19,12],
[77,38,20,0],
[77,38,20,1],
[77,38,20,2],
[77,38,20,3],
[77,38,20,4],
[77,38,20,5],
[77,38,20,6],
[77,38,20,8],
[77,38,20,11],
[77,38,20,12],
[77,38,20,13],
[77,38,20,14],
[77,38,21,0],
[77,38,21,1],
[77,38,21,2],
[77,38,21,4],
[77,38,21,10],
[77,38,21,11],
[77,38,21,12],
[77,38,21,13],
[77,38,22,0],
[77,38,22,1],
[77,38,22,2],
[77,38,22,3],
[77,38,22,4],
[77,38,22,5],
[77,38,22,6],
[77,38,22,8],
[77,38,22,11],
[77,38,23,0],
[77,38,23,1],
[77,38,23,2],
[77,38,23,3],
[77,38,23,4],
[77,38,23,5],
[77,38,23,6],
[77,38,23,8],
[77,38,24,0],
[77,38,24,1],
[77,38,24,2],
[77,38,24,3],
[77,38,24,4],
[77,38,24,5],
[77,38,24,6],
[77,38,24,8],
[77,38,27,0],
[77,38,27,1],
[77,38,27,2],
[77,39,4,0],
[77,39,4,1],
[77,39,4,2],
[77,39,7,0],
[77,39,7,1],
[77,39,7,2],
[77,39,7,4],
[77,39,10,0],
[77,39,10,1],
[77,39,10,2],
[77,39,10,7],
[77,39,11,0],
[77,39,11,1],
[77,39,11,2],
[77,39,11,7],
[77,39,11,10],
[77,39,12,4],
[77,39,12,7],
[77,39,12,10],
[77,39,13,4],
[77,39,13,7],
[77,39,13,10],
[77,39,13,11],
[77,39,13,12],
[77,39,14,0],
[77,39,14,1],
[77,39,14,2],
[77,39,14,4],
[77,39,14,7],
[77,39,14,10],
[77,39,14,11],
[77,39,14,12],
[77,39,16,0],
[77,39,16,1],
[77,39,16,2],
[77,39,16,4],
[77,39,16,7],
[77,39,16,10],
[77,39,16,11],
[77,39,16,12],
[77,39,16,13],
[77,39,16,14],
[77,39,17,0],
[77,39,17,1],
[77,39,17,2],
[77,39,17,4],
[77,39,17,7],
[77,39,17,10],
[77,39,17,11],
[77,39,17,12],
[77,39,17,13],
[77,39,17,14],
[77,39,17,16],
[77,39,19,0],
[77,39,19,1],
[77,39,19,2],
[77,39,19,4],
[77,39,19,7],
[77,39,19,10],
[77,39,19,11],
[77,39,19,12],
[77,39,20,0],
[77,39,20,1],
[77,39,20,2],
[77,39,20,4],
[77,39,20,7],
[77,39,20,11],
[77,39,20,12],
[77,39,20,13],
[77,39,22,0],
[77,39,22,1],
[77,39,22,2],
[77,39,22,4],
[77,39,22,7],
[77,39,23,0],
[77,39,23,1],
[77,39,23,2],
[77,39,23,4],
[77,39,23,7],
[77,39,24,0],
[77,39,24,1],
[77,39,24,2],
[77,39,24,4],
[77,39,24,7],
[77,39,25,0],
[77,39,25,1],
[77,39,25,2],
[77,39,25,4],
[77,42,3,0],
[77,42,3,1],
[77,42,3,2],
[77,42,4,0],
[77,42,4,1],
[77,42,4,2],
[77,42,4,3],
[77,42,5,0],
[77,42,5,1],
[77,42,5,2],
[77,42,5,4],
[77,42,6,0],
[77,42,6,1],
[77,42,6,2],
[77,42,7,0],
[77,42,7,1],
[77,42,7,2],
[77,42,7,5],
[77,42,7,6],
[77,42,8,4],
[77,42,8,7],
[77,42,11,0],
[77,42,11,1],
[77,42,11,2],
[77,42,11,3],
[77,42,11,4],
[77,42,11,5],
[77,42,11,6],
[77,42,11,7],
[77,42,11,8],
[77,42,12,0],
[77,42,12,1],
[77,42,12,2],
[77,42,12,3],
[77,42,12,4],
[77,42,12,5],
[77,42,12,6],
[77,42,12,7],
[77,42,12,8],
[77,42,13,0],
[77,42,13,1],
[77,42,13,2],
[77,42,13,3],
[77,42,13,4],
[77,42,13,5],
[77,42,13,6],
[77,42,13,7],
[77,42,13,8],
[77,42,13,11],
[77,42,13,12],
[77,42,14,0],
[77,42,14,1],
[77,42,14,2],
[77,42,14,3],
[77,42,14,4],
[77,42,14,5],
[77,42,14,6],
[77,42,14,7],
[77,42,14,8],
[77,42,14,11],
[77,42,14,12],
[77,42,16,0],
[77,42,16,1],
[77,42,16,2],
[77,42,16,3],
[77,42,16,4],
[77,42,16,5],
[77,42,16,6],
[77,42,16,7],
[77,42,16,8],
[77,42,16,11],
[77,42,16,12],
[77,42,16,13],
[77,42,16,14],
[77,42,17,0],
[77,42,17,1],
[77,42,17,2],
[77,42,17,3],
[77,42,17,4],
[77,42,17,5],
[77,42,17,6],
[77,42,17,7],
[77,42,17,8],
[77,42,17,11],
[77,42,17,12],
[77,42,17,13],
[77,42,17,14],
[77,42,19,0],
[77,42,19,1],
[77,42,19,2],
[77,42,19,3],
[77,42,19,4],
[77,42,19,5],
[77,42,19,6],
[77,42,19,7],
[77,42,19,8],
[77,42,19,11],
[77,42,21,0],
[77,42,21,1],
[77,42,21,2],
[77,42,21,4],
[77,42,21,7],
[77,42,23,0],
[77,42,23,1],
[77,42,23,2],
[77,42,23,3],
[77,42,23,4],
[77,42,24,0],
[77,42,24,1],
[77,42,24,2],
[77,42,24,3],
[77,42,24,4],
[77,43,3,0],
[77,43,3,1],
[77,43,3,2],
[77,43,4,0],
[77,43,4,1],
[77,43,4,2],
[77,43,4,3],
[77,43,5,0],
[77,43,5,1],
[77,43,5,2],
[77,43,6,4],
[77,43,8,0],
[77,43,8,1],
[77,43,8,2],
[77,43,8,4],
[77,43,10,0],
[77,43,10,1],
[77,43,10,2],
[77,43,10,3],
[77,43,10,4],
[77,43,10,5],
[77,43,10,6],
[77,43,10,8],
[77,43,11,0],
[77,43,11,1],
[77,43,11,2],
[77,43,11,3],
[77,43,11,4],
[77,43,11,5],
[77,43,11,6],
[77,43,11,8],
[77,43,11,10],
[77,43,12,0],
[77,43,12,1],
[77,43,12,2],
[77,43,12,3],
[77,43,12,4],
[77,43,12,5],
[77,43,12,6],
[77,43,12,8],
[77,43,12,10],
[77,43,13,0],
[77,43,13,1],
[77,43,13,2],
[77,43,13,3],
[77,43,13,4],
[77,43,13,5],
[77,43,13,6],
[77,43,13,8],
[77,43,13,10],
[77,43,13,11],
[77,43,13,12],
[77,43,14,0],
[77,43,14,1],
[77,43,14,2],
[77,43,14,3],
[77,43,14,4],
[77,43,14,5],
[77,43,14,6],
[77,43,14,8],
[77,43,14,10],
[77,43,14,11],
[77,43,14,12],
[77,43,16,0],
[77,43,16,1],
[77,43,16,2],
[77,43,16,3],
[77,43,16,4],
[77,43,16,5],
[77,43,16,6],
[77,43,16,8],
[77,43,16,10],
[77,43,16,11],
[77,43,16,12],
[77,43,16,13],
[77,43,17,0],
[77,43,17,1],
[77,43,17,2],
[77,43,17,3],
[77,43,17,4],
[77,43,17,5],
[77,43,17,6],
[77,43,17,8],
[77,43,17,10],
[77,43,17,11],
[77,43,17,12],
[77,43,17,13],
[77,43,19,0],
[77,43,19,1],
[77,43,19,2],
[77,43,19,3],
[77,43,19,4],
[77,43,19,5],
[77,43,19,6],
[77,43,19,8],
[77,43,20,0],
[77,43,20,1],
[77,43,20,2],
[77,43,20,3],
[77,43,20,4],
[77,43,20,5],
[77,43,20,6],
[77,43,21,0],
[77,43,21,1],
[77,43,21,2],
[77,43,21,4],
[77,43,22,0],
[77,43,22,1],
[77,43,22,2],
[77,43,22,3],
[77,43,22,4],
[77,43,23,0],
[77,43,23,1],
[77,43,23,2],
[77,43,24,0],
[77,43,24,1],
[77,43,24,2],
[77,44,3,0],
[77,44,3,1],
[77,44,3,2],
[77,44,4,0],
[77,44,4,1],
[77,44,4,2],
[77,44,5,4],
[77,44,6,0],
[77,44,6,1],
[77,44,6,2],
[77,44,6,4],
[77,44,7,0],
[77,44,7,1],
[77,44,7,2],
[77,44,7,3],
[77,44,7,4],
[77,44,7,5],
[77,44,7,6],
[77,44,8,0],
[77,44,8,1],
[77,44,8,2],
[77,44,8,4],
[77,44,8,7],
[77,44,10,0],
[77,44,10,1],
[77,44,10,2],
[77,44,10,3],
[77,44,10,4],
[77,44,10,5],
[77,44,10,6],
[77,44,10,7],
[77,44,10,8],
[77,44,11,0],
[77,44,11,1],
[77,44,11,2],
[77,44,11,3],
[77,44,11,4],
[77,44,11,5],
[77,44,11,6],
[77,44,11,7],
[77,44,11,8],
[77,44,11,10],
[77,44,12,0],
[77,44,12,1],
[77,44,12,2],
[77,44,12,3],
[77,44,12,4],
[77,44,12,5],
[77,44,12,6],
[77,44,12,7],
[77,44,12,8],
[77,44,12,10],
[77,44,13,0],
[77,44,13,1],
[77,44,13,2],
[77,44,13,3],
[77,44,13,4],
[77,44,13,5],
[77,44,13,6],
[77,44,13,7],
[77,44,13,8],
[77,44,13,10],
[77,44,13,11],
[77,44,13,12],
[77,44,14,0],
[77,44,14,1],
[77,44,14,2],
[77,44,14,3],
[77,44,14,4],
[77,44,14,5],
[77,44,14,6],
[77,44,14,7],
[77,44,14,8],
[77,44,14,10],
[77,44,14,11],
[77,44,14,12],
[77,44,16,0],
[77,44,16,1],
[77,44,16,2],
[77,44,16,3],
[77,44,16,4],
[77,44,16,5],
[77,44,16,6],
[77,44,16,7],
[77,44,16,8],
[77,44,16,10],
[77,44,16,11],
[77,44,19,0],
[77,44,19,1],
[77,44,19,2],
[77,44,19,3],
[77,44,19,4],
[77,44,19,5],
[77,44,19,6],
[77,44,19,7],
[77,44,20,0],
[77,44,20,1],
[77,44,20,2],
[77,44,20,3],
[77,44,20,4],
[77,44,20,5],
[77,44,21,0],
[77,44,21,1],
[77,44,21,2],
[77,44,21,4],
[77,44,22,0],
[77,44,22,1],
[77,44,22,2],
[77,47,3,0],
[77,47,3,1],
[77,47,3,2],
[77,47,4,0],
[77,47,4,1],
[77,47,4,2],
[77,47,4,3],
[77,47,5,0],
[77,47,5,1],
[77,47,5,2],
[77,47,5,4],
[77,47,6,0],
[77,47,6,1],
[77,47,6,2],
[77,47,6,4],
[77,47,7,0],
[77,47,7,1],
[77,47,7,2],
[77,47,7,3],
[77,47,7,4],
[77,47,7,5],
[77,47,7,6],
[77,47,8,0],
[77,47,8,1],
[77,47,8,2],
[77,47,8,4],
[77,47,8,7],
[77,47,10,0],
[77,47,10,1],
[77,47,10,2],
[77,47,10,3],
[77,47,10,4],
[77,47,10,5],
[77,47,10,6],
[77,47,10,7],
[77,47,10,8],
[77,47,11,0],
[77,47,11,1],
[77,47,11,2],
[77,47,11,3],
[77,47,11,4],
[77,47,11,5],
[77,47,11,6],
[77,47,11,7],
[77,47,11,8],
[77,47,11,10],
[77,47,12,0],
[77,47,12,1],
[77,47,12,2],
[77,47,12,3],
[77,47,12,4],
[77,47,12,5],
[77,47,12,6],
[77,47,12,7],
[77,47,12,8],
[77,47,12,10],
[77,47,13,0],
[77,47,13,1],
[77,47,13,2],
[77,47,13,3],
[77,47,13,4],
[77,47,13,5],
[77,47,13,6],
[77,47,13,7],
[77,47,13,8],
[77,47,13,10],
[77,47,13,11],
[77,47,14,0],
[77,47,14,1],
[77,47,14,2],
[77,47,14,3],
[77,47,14,4],
[77,47,14,5],
[77,47,14,6],
[77,47,14,7],
[77,47,14,8],
[77,47,16,0],
[77,47,16,1],
[77,47,16,2],
[77,47,16,3],
[77,47,16,4],
[77,47,16,5],
[77,47,17,0],
[77,47,17,1],
[77,47,17,2],
[77,47,17,3],
[77,47,17,4],
[77,47,17,5],
[77,47,19,0],
[77,47,19,1],
[77,47,19,2],
[77,48,3,0],
[77,48,3,1],
[77,48,3,2],
[77,48,4,0],
[77,48,4,1],
[77,48,4,2],
[77,48,4,3],
[77,48,5,0],
[77,48,5,1],
[77,48,5,2],
[77,48,5,4],
[77,48,6,0],
[77,48,6,1],
[77,48,6,2],
[77,48,6,4],
[77,48,7,0],
[77,48,7,1],
[77,48,7,2],
[77,48,7,3],
[77,48,7,4],
[77,48,7,5],
[77,48,7,6],
[77,48,8,0],
[77,48,8,1],
[77,48,8,2],
[77,48,8,4],
[77,48,8,7],
[77,48,10,0],
[77,48,10,1],
[77,48,10,2],
[77,48,10,3],
[77,48,10,4],
[77,48,10,5],
[77,48,10,6],
[77,48,10,7],
[77,48,10,8],
[77,48,13,0],
[77,48,13,1],
[77,48,13,2],
[77,48,13,3],
[77,48,13,4],
[77,48,13,5],
[77,48,13,6],
[77,48,13,7],
[77,48,13,8],
[77,48,14,0],
[77,48,14,1],
[77,48,14,2],
[77,48,14,3],
[77,48,14,4],
[77,48,14,5],
[77,48,14,6],
[77,48,14,7],
[77,48,16,0],
[77,48,16,1],
[77,48,16,2],
[77,48,16,3],
[77,48,16,4],
[77,48,17,0],
[77,48,17,1],
[77,48,17,2],
[77,48,17,3],
[77,48,17,4],
[77,51,4,0],
[77,51,4,1],
[77,51,4,2],
[77,51,7,0],
[77,51,7,1],
[77,51,7,2],
[77,51,7,4],
[77,51,10,0],
[77,51,10,1],
[77,51,10,2],
[77,51,10,4],
[77,51,10,7],
[77,51,11,0],
[77,51,11,1],
[77,51,11,2],
[77,51,11,4],
[77,51,11,7],
[77,51,11,10],
[77,51,12,0],
[77,51,12,1],
[77,51,12,2],
[77,51,12,4],
[77,51,12,7],
[77,51,13,0],
[77,51,13,1],
[77,51,13,2],
[77,51,13,4],
[77,51,13,7],
[77,51,14,0],
[77,51,14,1],
[77,51,14,2],
[77,51,14,4],
[77,51,14,7],
[77,51,16,0],
[77,51,16,1],
[77,51,16,2],
[77,51,16,4],
[77,51,17,0],
[77,51,17,1],
[77,51,17,2],
[77,51,17,4],
[77,53,3,0],
[77,53,3,1],
[77,53,3,2],
[77,53,4,0],
[77,53,4,1],
[77,53,4,2],
[77,53,4,3],
[77,53,5,0],
[77,53,5,1],
[77,53,5,2],
[77,53,5,4],
[77,53,6,0],
[77,53,6,1],
[77,53,6,2],
[77,53,6,4],
[77,53,7,0],
[77,53,7,1],
[77,53,7,2],
[77,53,7,3],
[77,53,7,4],
[77,53,7,5],
[77,53,7,6],
[77,53,8,0],
[77,53,8,1],
[77,53,8,2],
[77,53,8,4],
[77,53,8,7],
[77,53,11,0],
[77,53,11,1],
[77,53,11,2],
[77,53,11,3],
[77,53,11,4],
[77,53,11,5],
[77,53,11,6],
[77,53,11,7],
[77,53,11,8],
[77,53,12,0],
[77,53,12,1],
[77,53,12,2],
[77,53,12,3],
[77,53,12,4],
[77,53,12,5],
[77,53,12,6],
[77,53,12,7],
[77,53,12,8],
[77,53,13,0],
[77,53,13,1],
[77,53,13,2],
[77,53,13,3],
[77,53,13,4],
[77,53,13,5],
[77,53,13,6],
[77,53,13,7],
[77,53,13,8],
[77,53,14,0],
[77,53,14,1],
[77,53,14,2],
[77,53,14,3],
[77,53,14,4],
[77,53,14,5],
[77,53,14,6],
[77,53,14,7],
[77,53,16,0],
[77,53,16,1],
[77,53,16,2],
[77,53,16,3],
[77,53,16,4],
[77,53,17,0],
[77,53,17,1],
[77,53,17,2],
[77,53,17,3],
[77,53,17,4],
[77,54,3,0],
[77,54,3,1],
[77,54,3,2],
[77,54,4,0],
[77,54,4,1],
[77,54,4,2],
[77,54,4,3],
[77,54,5,0],
[77,54,5,1],
[77,54,5,2],
[77,54,5,4],
[77,54,6,0],
[77,54,6,1],
[77,54,6,2],
[77,54,6,4],
[77,54,7,0],
[77,54,7,1],
[77,54,7,2],
[77,54,7,3],
[77,54,7,4],
[77,54,7,5],
[77,54,7,6],
[77,54,8,0],
[77,54,8,1],
[77,54,8,2],
[77,54,8,4],
[77,54,8,7],
[77,54,11,0],
[77,54,11,1],
[77,54,11,2],
[77,54,11,3],
[77,54,11,4],
[77,54,11,5],
[77,54,11,6],
[77,54,11,7],
[77,54,11,8],
[77,54,12,0],
[77,54,12,1],
[77,54,12,2],
[77,54,12,3],
[77,54,12,4],
[77,54,12,5],
[77,54,12,6],
[77,54,12,7],
[77,54,12,8],
[77,54,13,0],
[77,54,13,1],
[77,54,13,2],
[77,54,13,3],
[77,54,13,4],
[77,54,13,5],
[77,54,13,6],
[77,54,13,7],
[77,54,13,8],
[77,54,14,0],
[77,54,14,1],
[77,54,14,2],
[77,54,14,3],
[77,54,14,4],
[77,54,14,5],
[77,54,14,6],
[77,54,14,7],
[77,54,16,0],
[77,54,16,1],
[77,54,16,2],
[77,54,16,3],
[77,54,16,4],
[77,54,17,0],
[77,54,17,1],
[77,54,17,2],
[77,54,17,3],
[77,54,17,4],
[77,55,3,0],
[77,55,3,1],
[77,55,3,2],
[77,55,4,0],
[77,55,4,1],
[77,55,4,2],
[77,55,4,3],
[77,55,5,0],
[77,55,5,1],
[77,55,5,2],
[77,55,5,4],
[77,55,6,0],
[77,55,6,1],
[77,55,6,2],
[77,55,6,4],
[77,55,7,0],
[77,55,7,1],
[77,55,7,2],
[77,55,7,3],
[77,55,7,4],
[77,55,7,5],
[77,55,7,6],
[77,55,8,0],
[77,55,8,1],
[77,55,8,2],
[77,55,8,4],
[77,55,8,7],
[77,55,11,0],
[77,55,11,1],
[77,55,11,2],
[77,55,11,3],
[77,55,11,4],
[77,55,11,5],
[77,55,11,6],
[77,55,11,7],
[77,55,11,8],
[77,55,12,0],
[77,55,12,1],
[77,55,12,2],
[77,55,12,3],
[77,55,12,4],
[77,55,12,5],
[77,55,12,6],
[77,55,12,7],
[77,55,12,8],
[77,55,13,0],
[77,55,13,1],
[77,55,13,2],
[77,55,13,3],
[77,55,13,4],
[77,55,13,5],
[77,55,13,6],
[77,55,13,7],
[77,55,13,8],
[77,55,14,0],
[77,55,14,1],
[77,55,14,2],
[77,55,14,3],
[77,55,14,4],
[77,55,14,5],
[77,55,14,6],
[77,55,14,7],
[77,55,16,0],
[77,55,16,1],
[77,55,16,2],
[77,55,16,3],
[77,55,16,4],
[77,55,17,0],
[77,55,17,1],
[77,55,17,2],
[77,55,17,3],
[77,55,17,4],
[77,57,3,0],
[77,57,3,1],
[77,57,3,2],
[77,57,4,0],
[77,57,4,1],
[77,57,4,2],
[77,57,4,3],
[77,57,5,0],
[77,57,5,1],
[77,57,5,2],
[77,57,5,4],
[77,57,6,0],
[77,57,6,1],
[77,57,6,2],
[77,57,6,4],
[77,57,7,0],
[77,57,7,1],
[77,57,7,2],
[77,57,7,3],
[77,57,7,4],
[77,57,7,5],
[77,57,7,6],
[77,57,8,0],
[77,57,8,1],
[77,57,8,2],
[77,57,8,4],
[77,57,8,7],
[77,57,10,0],
[77,57,10,1],
[77,57,10,2],
[77,57,10,3],
[77,57,10,4],
[77,57,10,5],
[77,57,10,6],
[77,57,10,7],
[77,57,10,8],
[77,57,11,0],
[77,57,11,1],
[77,57,11,2],
[77,57,11,3],
[77,57,11,4],
[77,57,11,5],
[77,57,11,6],
[77,57,11,7],
[77,57,11,8],
[77,57,12,0],
[77,57,12,1],
[77,57,12,2],
[77,57,12,3],
[77,57,12,4],
[77,57,12,5],
[77,57,12,6],
[77,57,12,7],
[77,57,13,0],
[77,57,13,1],
[77,57,13,2],
[77,57,13,3],
[77,57,13,4],
[77,57,13,5],
[77,57,13,6],
[77,57,13,7],
[77,57,14,0],
[77,57,14,1],
[77,57,14,2],
[77,57,14,3],
[77,57,14,4],
[77,57,14,5],
[77,57,16,0],
[77,57,16,1],
[77,57,16,2],
[77,57,17,0],
[77,57,17,1],
[77,57,17,2],
[77,58,3,0],
[77,58,3,1],
[77,58,3,2],
[77,58,4,0],
[77,58,4,1],
[77,58,4,2],
[77,58,4,3],
[77,58,5,0],
[77,58,5,1],
[77,58,5,2],
[77,58,5,4],
[77,58,6,0],
[77,58,6,1],
[77,58,6,2],
[77,58,6,4],
[77,58,7,0],
[77,58,7,1],
[77,58,7,2],
[77,58,7,3],
[77,58,7,4],
[77,58,7,5],
[77,58,7,6],
[77,58,8,0],
[77,58,8,1],
[77,58,8,2],
[77,58,8,4],
[77,58,8,7],
[77,58,10,0],
[77,58,10,1],
[77,58,10,2],
[77,58,10,3],
[77,58,10,4],
[77,58,10,5],
[77,58,10,6],
[77,58,10,7],
[77,58,10,8],
[77,58,11,0],
[77,58,11,1],
[77,58,11,2],
[77,58,11,3],
[77,58,11,4],
[77,58,11,5],
[77,58,11,6],
[77,58,11,7],
[77,58,11,8],
[77,58,12,0],
[77,58,12,1],
[77,58,12,2],
[77,58,12,3],
[77,58,12,4],
[77,58,12,5],
[77,58,12,6],
[77,58,12,7],
[77,58,13,0],
[77,58,13,1],
[77,58,13,2],
[77,58,13,3],
[77,58,13,4],
[77,58,13,5],
[77,58,13,6],
[77,58,13,7],
[77,58,14,0],
[77,58,14,1],
[77,58,14,2],
[77,58,14,3],
[77,58,14,4],
[77,58,14,5],
[77,58,17,0],
[77,58,17,1],
[77,58,17,2],
[77,60,3,0],
[77,60,3,1],
[77,60,3,2],
[77,60,4,0],
[77,60,4,1],
[77,60,4,2],
[77,60,4,3],
[77,60,5,0],
[77,60,5,1],
[77,60,5,2],
[77,60,5,4],
[77,60,6,0],
[77,60,6,1],
[77,60,6,2],
[77,60,6,4],
[77,60,7,0],
[77,60,7,1],
[77,60,7,2],
[77,60,7,3],
[77,60,7,4],
[77,60,7,5],
[77,60,7,6],
[77,60,8,0],
[77,60,8,1],
[77,60,8,2],
[77,60,8,4],
[77,60,8,7],
[77,60,10,0],
[77,60,10,1],
[77,60,10,2],
[77,60,10,3],
[77,60,10,4],
[77,60,10,5],
[77,60,11,0],
[77,60,11,1],
[77,60,11,2],
[77,60,11,3],
[77,60,11,4],
[77,60,11,5],
[77,60,12,0],
[77,60,12,1],
[77,60,12,2],
[77,60,12,3],
[77,60,12,4],
[77,61,3,0],
[77,61,3,1],
[77,61,3,2],
[77,61,5,0],
[77,61,5,1],
[77,61,5,2],
[77,61,6,0],
[77,61,6,1],
[77,61,6,2],
[77,61,7,0],
[77,61,7,1],
[77,61,7,2],
[77,61,7,3],
[77,61,7,5],
[77,61,7,6],
[77,61,8,0],
[77,61,8,1],
[77,61,8,2],
[77,61,10,0],
[77,61,10,1],
[77,61,10,2],
[77,61,10,3],
[77,61,11,0],
[77,61,11,1],
[77,61,11,2],
[77,61,11,3],
[77,61,12,0],
[77,61,12,1],
[77,61,12,2],
[77,61,13,0],
[77,61,13,1],
[77,61,13,2],
[77,62,3,0],
[77,62,3,1],
[77,62,3,2],
[77,62,4,0],
[77,62,4,1],
[77,62,4,2],
[77,62,4,3],
[77,62,5,0],
[77,62,5,1],
[77,62,5,2],
[77,62,5,4],
[77,62,6,0],
[77,62,6,1],
[77,62,6,2],
[77,62,6,4],
[77,62,8,0],
[77,62,8,1],
[77,62,8,2],
[77,62,8,4],
[77,62,10,0],
[77,62,10,1],
[77,62,10,2],
[77,62,10,3],
[77,62,10,4],
[77,62,11,0],
[77,62,11,1],
[77,62,11,2],
[77,62,11,3],
[77,62,11,4],
[77,62,12,0],
[77,62,12,1],
[77,62,12,2],
[77,62,13,0],
[77,62,13,1],
[77,62,13,2],
[77,64,3,0],
[77,64,3,1],
[77,64,3,2],
[77,64,5,0],
[77,64,5,1],
[77,64,5,2],
[77,64,6,0],
[77,64,6,1],
[77,64,6,2],
[77,64,7,0],
[77,64,7,1],
[77,64,7,2],
[77,64,7,3],
[77,64,8,0],
[77,64,8,1],
[77,64,8,2],
[77,65,3,0],
[77,65,3,1],
[77,65,3,2],
[77,65,4,0],
[77,65,4,1],
[77,65,4,2],
[77,65,4,3],
[77,65,5,0],
[77,65,5,1],
[77,65,5,2],
[77,65,5,4],
[77,65,6,0],
[77,65,6,1],
[77,65,6,2],
[77,65,7,0],
[77,65,7,1],
[77,65,7,2],
[77,66,3,0],
[77,66,3,1],
[77,66,3,2],
[77,66,4,0],
[77,66,4,1],
[77,66,4,2],
[77,66,4,3],
[77,66,5,0],
[77,66,5,1],
[77,66,5,2],
[77,67,3,0],
[77,67,3,1],
[77,67,3,2],
[77,67,4,0],
[77,67,4,1],
[77,67,4,2],
[78,4,3,0],
[78,4,3,1],
[78,4,3,2],
[78,5,4,0],
[78,5,4,1],
[78,5,4,2],
[78,6,4,0],
[78,6,4,1],
[78,6,4,2],
[78,7,3,0],
[78,7,3,1],
[78,7,3,2],
[78,7,4,0],
[78,7,4,1],
[78,7,4,2],
[78,7,4,3],
[78,7,5,0],
[78,7,5,1],
[78,7,5,2],
[78,7,5,4],
[78,7,6,0],
[78,7,6,1],
[78,7,6,2],
[78,7,6,4],
[78,8,4,0],
[78,8,4,1],
[78,8,4,2],
[78,8,7,0],
[78,8,7,1],
[78,8,7,2],
[78,8,7,4],
[78,10,3,0],
[78,10,3,1],
[78,10,3,2],
[78,10,4,0],
[78,10,4,1],
[78,10,4,2],
[78,10,4,3],
[78,10,5,0],
[78,10,5,1],
[78,10,5,2],
[78,10,5,4],
[78,10,6,0],
[78,10,6,1],
[78,10,6,2],
[78,10,6,4],
[78,10,7,0],
[78,10,7,1],
[78,10,7,2],
[78,10,7,3],
[78,10,7,4],
[78,10,7,5],
[78,10,7,6],
[78,10,8,0],
[78,10,8,1],
[78,10,8,2],
[78,10,8,4],
[78,10,8,7],
[78,11,3,0],
[78,11,3,1],
[78,11,3,2],
[78,11,4,0],
[78,11,4,1],
[78,11,4,2],
[78,11,4,3],
[78,11,5,0],
[78,11,5,1],
[78,11,5,2],
[78,11,5,4],
[78,11,6,0],
[78,11,6,1],
[78,11,6,2],
[78,11,6,4],
[78,11,7,0],
[78,11,7,1],
[78,11,7,2],
[78,11,7,3],
[78,11,7,4],
[78,11,7,5],
[78,11,7,6],
[78,11,8,0],
[78,11,8,1],
[78,11,8,2],
[78,11,8,4],
[78,11,8,7],
[78,11,10,0],
[78,11,10,1],
[78,11,10,2],
[78,11,10,3],
[78,11,10,4],
[78,11,10,5],
[78,11,10,6],
[78,11,10,7],
[78,11,10,8],
[78,12,3,0],
[78,12,3,1],
[78,12,3,2],
[78,12,4,0],
[78,12,4,1],
[78,12,4,2],
[78,12,4,3],
[78,12,5,0],
[78,12,5,1],
[78,12,5,2],
[78,12,5,4],
[78,12,6,0],
[78,12,6,1],
[78,12,6,2],
[78,12,6,4],
[78,12,7,0],
[78,12,7,1],
[78,12,7,2],
[78,12,7,3],
[78,12,7,4],
[78,12,7,5],
[78,12,7,6],
[78,12,8,0],
[78,12,8,1],
[78,12,8,2],
[78,12,8,4],
[78,12,8,7],
[78,12,10,0],
[78,12,10,1],
[78,12,10,2],
[78,12,10,3],
[78,12,10,4],
[78,12,10,5],
[78,12,10,6],
[78,12,10,7],
[78,12,10,8],
[78,13,3,0],
[78,13,3,1],
[78,13,3,2],
[78,13,4,0],
[78,13,4,1],
[78,13,4,2],
[78,13,4,3],
[78,13,5,0],
[78,13,5,1],
[78,13,5,2],
[78,13,5,4],
[78,13,6,0],
[78,13,6,1],
[78,13,6,2],
[78,13,6,4],
[78,13,7,0],
[78,13,7,1],
[78,13,7,2],
[78,13,7,3],
[78,13,7,4],
[78,13,7,5],
[78,13,7,6],
[78,13,8,0],
[78,13,8,1],
[78,13,8,2],
[78,13,8,4],
[78,13,8,7],
[78,13,10,0],
[78,13,10,1],
[78,13,10,2],
[78,13,10,3],
[78,13,10,4],
[78,13,10,5],
[78,13,10,6],
[78,13,10,7],
[78,13,10,8],
[78,13,11,0],
[78,13,11,1],
[78,13,11,2],
[78,13,11,3],
[78,13,11,4],
[78,13,11,5],
[78,13,11,6],
[78,13,11,7],
[78,13,11,8],
[78,13,11,10],
[78,13,12,0],
[78,13,12,1],
[78,13,12,2],
[78,13,12,3],
[78,13,12,4],
[78,13,12,5],
[78,13,12,6],
[78,13,12,7],
[78,13,12,8],
[78,13,12,10],
[78,14,3,0],
[78,14,3,1],
[78,14,3,2],
[78,14,4,0],
[78,14,4,1],
[78,14,4,2],
[78,14,4,3],
[78,14,5,0],
[78,14,5,1],
[78,14,5,2],
[78,14,5,4],
[78,14,6,0],
[78,14,6,1],
[78,14,6,2],
[78,14,6,4],
[78,14,7,0],
[78,14,7,1],
[78,14,7,2],
[78,14,7,3],
[78,14,7,4],
[78,14,7,5],
[78,14,7,6],
[78,14,8,0],
[78,14,8,1],
[78,14,8,2],
[78,14,8,4],
[78,14,8,7],
[78,14,10,0],
[78,14,10,1],
[78,14,10,2],
[78,14,10,3],
[78,14,10,4],
[78,14,10,5],
[78,14,10,6],
[78,14,10,7],
[78,14,10,8],
[78,14,11,0],
[78,14,11,1],
[78,14,11,2],
[78,14,11,3],
[78,14,11,4],
[78,14,11,5],
[78,14,11,6],
[78,14,11,7],
[78,14,11,8],
[78,14,11,10],
[78,14,12,0],
[78,14,12,1],
[78,14,12,2],
[78,14,12,3],
[78,14,12,4],
[78,14,12,5],
[78,14,12,6],
[78,14,12,7],
[78,14,12,8],
[78,14,12,10],
[78,16,3,0],
[78,16,3,1],
[78,16,3,2],
[78,16,4,0],
[78,16,4,1],
[78,16,4,2],
[78,16,4,3],
[78,16,5,0],
[78,16,5,1],
[78,16,5,2],
[78,16,5,4],
[78,16,6,0],
[78,16,6,1],
[78,16,6,2],
[78,16,6,4],
[78,16,7,0],
[78,16,7,1],
[78,16,7,2],
[78,16,7,3],
[78,16,7,4],
[78,16,7,5],
[78,16,7,6],
[78,16,8,0],
[78,16,8,1],
[78,16,8,2],
[78,16,8,4],
[78,16,8,7],
[78,16,10,0],
[78,16,10,1],
[78,16,10,2],
[78,16,10,3],
[78,16,10,4],
[78,16,10,5],
[78,16,10,6],
[78,16,10,7],
[78,16,10,8],
[78,16,11,0],
[78,16,11,1],
[78,16,11,2],
[78,16,11,3],
[78,16,11,4],
[78,16,11,5],
[78,16,11,6],
[78,16,11,7],
[78,16,11,8],
[78,16,11,10],
[78,16,12,0],
[78,16,12,1],
[78,16,12,2],
[78,16,12,3],
[78,16,12,4],
[78,16,12,5],
[78,16,12,6],
[78,16,12,7],
[78,16,12,8],
[78,16,12,10],
[78,16,13,0],
[78,16,13,1],
[78,16,13,2],
[78,16,13,3],
[78,16,13,4],
[78,16,13,5],
[78,16,13,6],
[78,16,13,7],
[78,16,13,8],
[78,16,13,10],
[78,16,13,11],
[78,16,13,12],
[78,16,14,0],
[78,16,14,1],
[78,16,14,2],
[78,16,14,3],
[78,16,14,4],
[78,16,14,5],
[78,16,14,6],
[78,16,14,7],
[78,16,14,8],
[78,16,14,10],
[78,16,14,11],
[78,16,14,12],
[78,17,3,0],
[78,17,3,1],
[78,17,3,2],
[78,17,4,0],
[78,17,4,1],
[78,17,4,2],
[78,17,4,3],
[78,17,5,0],
[78,17,5,1],
[78,17,5,2],
[78,17,5,4],
[78,17,6,0],
[78,17,6,1],
[78,17,6,2],
[78,17,6,4],
[78,17,7,0],
[78,17,7,1],
[78,17,7,2],
[78,17,7,3],
[78,17,7,4],
[78,17,7,5],
[78,17,7,6],
[78,17,8,0],
[78,17,8,1],
[78,17,8,2],
[78,17,8,4],
[78,17,8,7],
[78,17,10,0],
[78,17,10,1],
[78,17,10,2],
[78,17,10,3],
[78,17,10,4],
[78,17,10,5],
[78,17,10,6],
[78,17,10,7],
[78,17,10,8],
[78,17,11,0],
[78,17,11,1],
[78,17,11,2],
[78,17,11,3],
[78,17,11,4],
[78,17,11,5],
[78,17,11,6],
[78,17,11,7],
[78,17,11,8],
[78,17,11,10],
[78,17,12,0],
[78,17,12,1],
[78,17,12,2],
[78,17,12,3],
[78,17,12,4],
[78,17,12,5],
[78,17,12,6],
[78,17,12,7],
[78,17,12,8],
[78,17,12,10],
[78,17,13,0],
[78,17,13,1],
[78,17,13,2],
[78,17,13,3],
[78,17,13,4],
[78,17,13,5],
[78,17,13,6],
[78,17,13,7],
[78,17,13,8],
[78,17,13,10],
[78,17,13,11],
[78,17,13,12],
[78,17,14,0],
[78,17,14,1],
[78,17,14,2],
[78,17,14,3],
[78,17,14,4],
[78,17,14,5],
[78,17,14,6],
[78,17,14,7],
[78,17,14,8],
[78,17,14,10],
[78,17,14,11],
[78,17,14,12],
[78,17,16,0],
[78,17,16,1],
[78,17,16,2],
[78,17,16,3],
[78,17,16,4],
[78,17,16,5],
[78,17,16,6],
[78,17,16,7],
[78,17,16,8],
[78,17,16,10],
[78,17,16,11],
[78,17,16,12],
[78,17,16,13],
[78,17,16,14],
[78,19,3,0],
[78,19,3,1],
[78,19,3,2],
[78,19,4,0],
[78,19,4,1],
[78,19,4,2],
[78,19,4,3],
[78,19,5,0],
[78,19,5,1],
[78,19,5,2],
[78,19,5,4],
[78,19,6,0],
[78,19,6,1],
[78,19,6,2],
[78,19,6,4],
[78,19,7,0],
[78,19,7,1],
[78,19,7,2],
[78,19,7,3],
[78,19,7,4],
[78,19,7,5],
[78,19,7,6],
[78,19,8,0],
[78,19,8,1],
[78,19,8,2],
[78,19,8,4],
[78,19,8,7],
[78,19,10,0],
[78,19,10,1],
[78,19,10,2],
[78,19,10,3],
[78,19,10,4],
[78,19,10,5],
[78,19,10,6],
[78,19,10,7],
[78,19,10,8],
[78,19,11,0],
[78,19,11,1],
[78,19,11,2],
[78,19,11,3],
[78,19,11,4],
[78,19,11,5],
[78,19,11,6],
[78,19,11,7],
[78,19,11,8],
[78,19,11,10],
[78,19,12,0],
[78,19,12,1],
[78,19,12,2],
[78,19,12,3],
[78,19,12,4],
[78,19,12,5],
[78,19,12,6],
[78,19,12,7],
[78,19,12,8],
[78,19,12,10],
[78,19,16,0],
[78,19,16,1],
[78,19,16,2],
[78,19,16,3],
[78,19,16,4],
[78,19,16,5],
[78,19,16,6],
[78,19,16,7],
[78,19,16,8],
[78,19,16,10],
[78,19,16,11],
[78,19,16,12],
[78,19,17,0],
[78,19,17,1],
[78,19,17,2],
[78,19,17,3],
[78,19,17,4],
[78,19,17,5],
[78,19,17,6],
[78,19,17,7],
[78,19,17,8],
[78,19,17,10],
[78,19,17,11],
[78,19,17,12],
[78,19,17,16],
[78,20,3,0],
[78,20,3,1],
[78,20,3,2],
[78,20,4,0],
[78,20,4,1],
[78,20,4,2],
[78,20,4,3],
[78,20,5,0],
[78,20,5,1],
[78,20,5,2],
[78,20,5,4],
[78,20,6,0],
[78,20,6,1],
[78,20,6,2],
[78,20,6,4],
[78,20,7,0],
[78,20,7,1],
[78,20,7,2],
[78,20,7,3],
[78,20,7,4],
[78,20,7,5],
[78,20,7,6],
[78,20,8,0],
[78,20,8,1],
[78,20,8,2],
[78,20,8,4],
[78,20,8,7],
[78,20,11,0],
[78,20,11,1],
[78,20,11,2],
[78,20,11,3],
[78,20,11,4],
[78,20,11,5],
[78,20,11,6],
[78,20,11,7],
[78,20,11,8],
[78,20,12,0],
[78,20,12,1],
[78,20,12,2],
[78,20,12,3],
[78,20,12,4],
[78,20,12,5],
[78,20,12,6],
[78,20,12,7],
[78,20,12,8],
[78,20,13,0],
[78,20,13,1],
[78,20,13,2],
[78,20,13,3],
[78,20,13,4],
[78,20,13,5],
[78,20,13,6],
[78,20,13,7],
[78,20,13,8],
[78,20,13,11],
[78,20,13,12],
[78,20,14,0],
[78,20,14,1],
[78,20,14,2],
[78,20,14,3],
[78,20,14,4],
[78,20,14,5],
[78,20,14,6],
[78,20,14,7],
[78,20,14,8],
[78,20,14,11],
[78,20,14,12],
[78,20,16,0],
[78,20,16,1],
[78,20,16,2],
[78,20,16,3],
[78,20,16,4],
[78,20,16,5],
[78,20,16,6],
[78,20,16,7],
[78,20,16,8],
[78,20,16,11],
[78,20,16,12],
[78,20,16,13],
[78,20,16,14],
[78,20,17,0],
[78,20,17,1],
[78,20,17,2],
[78,20,17,3],
[78,20,17,4],
[78,20,17,5],
[78,20,17,6],
[78,20,17,7],
[78,20,17,8],
[78,20,17,11],
[78,20,17,12],
[78,20,17,13],
[78,20,17,14],
[78,20,17,16],
[78,20,19,0],
[78,20,19,1],
[78,20,19,2],
[78,20,19,3],
[78,20,19,4],
[78,20,19,5],
[78,20,19,6],
[78,20,19,7],
[78,20,19,8],
[78,20,19,11],
[78,20,19,12],
[78,20,19,16],
[78,20,19,17],
[78,21,4,0],
[78,21,4,1],
[78,21,4,2],
[78,21,7,0],
[78,21,7,1],
[78,21,7,2],
[78,21,7,4],
[78,21,10,0],
[78,21,10,1],
[78,21,10,2],
[78,21,10,4],
[78,21,10,7],
[78,21,11,0],
[78,21,11,1],
[78,21,11,2],
[78,21,11,4],
[78,21,11,7],
[78,21,11,10],
[78,21,12,0],
[78,21,12,1],
[78,21,12,2],
[78,21,12,4],
[78,21,12,7],
[78,21,12,10],
[78,21,13,0],
[78,21,13,1],
[78,21,13,2],
[78,21,13,4],
[78,21,13,7],
[78,21,13,10],
[78,21,13,11],
[78,21,13,12],
[78,21,14,0],
[78,21,14,1],
[78,21,14,2],
[78,21,14,4],
[78,21,14,7],
[78,21,14,10],
[78,21,14,11],
[78,21,14,12],
[78,21,16,0],
[78,21,16,1],
[78,21,16,2],
[78,21,16,4],
[78,21,16,7],
[78,21,16,10],
[78,21,16,11],
[78,21,16,12],
[78,21,16,13],
[78,21,16,14],
[78,21,17,0],
[78,21,17,1],
[78,21,17,2],
[78,21,17,4],
[78,21,17,7],
[78,21,17,10],
[78,21,17,11],
[78,21,17,12],
[78,21,17,13],
[78,21,17,14],
[78,21,17,16],
[78,21,19,0],
[78,21,19,1],
[78,21,19,2],
[78,21,19,4],
[78,21,19,7],
[78,21,19,10],
[78,21,19,11],
[78,21,19,12],
[78,21,19,16],
[78,21,19,17],
[78,21,20,0],
[78,21,20,1],
[78,21,20,2],
[78,21,20,4],
[78,21,20,7],
[78,21,20,11],
[78,21,20,12],
[78,21,20,13],
[78,21,20,14],
[78,21,20,16],
[78,21,20,17],
[78,22,3,0],
[78,22,3,1],
[78,22,3,2],
[78,22,4,0],
[78,22,4,1],
[78,22,4,2],
[78,22,4,3],
[78,22,5,0],
[78,22,5,1],
[78,22,5,2],
[78,22,5,4],
[78,22,6,0],
[78,22,6,1],
[78,22,6,2],
[78,22,6,4],
[78,22,7,0],
[78,22,7,1],
[78,22,7,2],
[78,22,7,3],
[78,22,7,4],
[78,22,7,5],
[78,22,7,6],
[78,22,8,0],
[78,22,8,1],
[78,22,8,2],
[78,22,8,4],
[78,22,8,7],
[78,22,11,0],
[78,22,11,1],
[78,22,11,2],
[78,22,11,3],
[78,22,11,4],
[78,22,11,5],
[78,22,11,6],
[78,22,11,7],
[78,22,11,8],
[78,22,12,0],
[78,22,12,1],
[78,22,12,2],
[78,22,12,3],
[78,22,12,4],
[78,22,12,5],
[78,22,12,6],
[78,22,12,7],
[78,22,12,8],
[78,22,13,0],
[78,22,13,1],
[78,22,13,2],
[78,22,13,3],
[78,22,13,4],
[78,22,13,5],
[78,22,13,6],
[78,22,13,7],
[78,22,13,8],
[78,22,13,11],
[78,22,13,12],
[78,22,14,0],
[78,22,14,1],
[78,22,14,2],
[78,22,14,3],
[78,22,14,4],
[78,22,14,5],
[78,22,14,6],
[78,22,14,7],
[78,22,14,8],
[78,22,14,11],
[78,22,14,12],
[78,22,16,0],
[78,22,16,1],
[78,22,16,2],
[78,22,16,3],
[78,22,16,4],
[78,22,16,5],
[78,22,16,6],
[78,22,16,7],
[78,22,16,8],
[78,22,16,11],
[78,22,16,12],
[78,22,16,13],
[78,22,16,14],
[78,22,17,0],
[78,22,17,1],
[78,22,17,2],
[78,22,17,3],
[78,22,17,4],
[78,22,17,5],
[78,22,17,6],
[78,22,17,7],
[78,22,17,8],
[78,22,17,11],
[78,22,17,12],
[78,22,17,13],
[78,22,17,14],
[78,22,17,16],
[78,22,19,0],
[78,22,19,1],
[78,22,19,2],
[78,22,19,3],
[78,22,19,4],
[78,22,19,5],
[78,22,19,6],
[78,22,19,7],
[78,22,19,8],
[78,22,19,11],
[78,22,19,12],
[78,22,19,16],
[78,22,19,17],
[78,22,21,0],
[78,22,21,1],
[78,22,21,2],
[78,22,21,4],
[78,22,21,7],
[78,22,21,11],
[78,22,21,12],
[78,22,21,13],
[78,22,21,14],
[78,22,21,19],
[78,23,3,0],
[78,23,3,1],
[78,23,3,2],
[78,23,4,0],
[78,23,4,1],
[78,23,4,2],
[78,23,4,3],
[78,23,5,0],
[78,23,5,1],
[78,23,5,2],
[78,23,5,4],
[78,23,6,0],
[78,23,6,1],
[78,23,6,2],
[78,23,6,4],
[78,23,7,0],
[78,23,7,1],
[78,23,7,2],
[78,23,7,3],
[78,23,7,4],
[78,23,7,5],
[78,23,7,6],
[78,23,8,0],
[78,23,8,1],
[78,23,8,2],
[78,23,8,4],
[78,23,8,7],
[78,23,10,0],
[78,23,10,1],
[78,23,10,2],
[78,23,10,3],
[78,23,10,4],
[78,23,10,5],
[78,23,10,6],
[78,23,10,7],
[78,23,10,8],
[78,23,11,0],
[78,23,11,1],
[78,23,11,2],
[78,23,11,3],
[78,23,11,4],
[78,23,11,5],
[78,23,11,6],
[78,23,11,7],
[78,23,11,8],
[78,23,11,10],
[78,23,12,0],
[78,23,12,1],
[78,23,12,2],
[78,23,12,3],
[78,23,12,4],
[78,23,12,5],
[78,23,12,6],
[78,23,12,7],
[78,23,12,8],
[78,23,12,10],
[78,23,13,0],
[78,23,13,1],
[78,23,13,2],
[78,23,13,3],
[78,23,13,4],
[78,23,13,5],
[78,23,13,6],
[78,23,13,7],
[78,23,13,8],
[78,23,13,10],
[78,23,13,11],
[78,23,13,12],
[78,23,14,0],
[78,23,14,1],
[78,23,14,2],
[78,23,14,3],
[78,23,14,4],
[78,23,14,5],
[78,23,14,6],
[78,23,14,7],
[78,23,14,8],
[78,23,14,10],
[78,23,14,11],
[78,23,14,12],
[78,23,16,0],
[78,23,16,1],
[78,23,16,2],
[78,23,16,3],
[78,23,16,4],
[78,23,16,5],
[78,23,16,6],
[78,23,16,7],
[78,23,16,8],
[78,23,16,10],
[78,23,16,11],
[78,23,16,12],
[78,23,16,13],
[78,23,16,14],
[78,23,17,0],
[78,23,17,1],
[78,23,17,2],
[78,23,17,3],
[78,23,17,4],
[78,23,17,5],
[78,23,17,6],
[78,23,17,7],
[78,23,17,8],
[78,23,17,10],
[78,23,17,11],
[78,23,17,12],
[78,23,17,13],
[78,23,17,14],
[78,23,17,16],
[78,23,19,0],
[78,23,19,1],
[78,23,19,2],
[78,23,19,3],
[78,23,19,4],
[78,23,19,5],
[78,23,19,6],
[78,23,19,7],
[78,23,19,8],
[78,23,19,10],
[78,23,19,11],
[78,23,19,12],
[78,23,19,16],
[78,23,19,17],
[78,23,20,0],
[78,23,20,1],
[78,23,20,2],
[78,23,20,3],
[78,23,20,4],
[78,23,20,5],
[78,23,20,6],
[78,23,20,7],
[78,23,20,8],
[78,23,20,11],
[78,23,20,12],
[78,23,20,13],
[78,23,20,14],
[78,23,20,19],
[78,23,21,0],
[78,23,21,1],
[78,23,21,2],
[78,23,21,4],
[78,23,21,7],
[78,23,21,10],
[78,23,21,11],
[78,23,21,12],
[78,23,21,13],
[78,23,21,14],
[78,23,21,16],
[78,23,21,17],
[78,23,21,19],
[78,23,21,20],
[78,23,22,0],
[78,23,22,1],
[78,23,22,2],
[78,23,22,3],
[78,23,22,4],
[78,23,22,5],
[78,23,22,6],
[78,23,22,7],
[78,23,22,8],
[78,23,22,11],
[78,23,22,12],
[78,23,22,13],
[78,23,22,16],
[78,23,22,17],
[78,23,22,19],
[78,23,22,21],
[78,24,3,0],
[78,24,3,1],
[78,24,3,2],
[78,24,4,0],
[78,24,4,1],
[78,24,4,2],
[78,24,4,3],
[78,24,5,0],
[78,24,5,1],
[78,24,5,2],
[78,24,5,4],
[78,24,6,0],
[78,24,6,1],
[78,24,6,2],
[78,24,6,4],
[78,24,7,0],
[78,24,7,1],
[78,24,7,2],
[78,24,7,3],
[78,24,7,4],
[78,24,7,5],
[78,24,7,6],
[78,24,8,0],
[78,24,8,1],
[78,24,8,2],
[78,24,8,4],
[78,24,8,7],
[78,24,10,0],
[78,24,10,1],
[78,24,10,2],
[78,24,10,3],
[78,24,10,4],
[78,24,10,5],
[78,24,10,6],
[78,24,10,7],
[78,24,10,8],
[78,24,11,0],
[78,24,11,1],
[78,24,11,2],
[78,24,11,3],
[78,24,11,4],
[78,24,11,5],
[78,24,11,6],
[78,24,11,7],
[78,24,11,8],
[78,24,11,10],
[78,24,12,0],
[78,24,12,1],
[78,24,12,2],
[78,24,12,3],
[78,24,12,4],
[78,24,12,5],
[78,24,12,6],
[78,24,12,7],
[78,24,12,8],
[78,24,12,10],
[78,24,13,0],
[78,24,13,1],
[78,24,13,2],
[78,24,13,3],
[78,24,13,4],
[78,24,13,5],
[78,24,13,6],
[78,24,13,7],
[78,24,13,8],
[78,24,13,10],
[78,24,13,11],
[78,24,13,12],
[78,24,14,0],
[78,24,14,1],
[78,24,14,2],
[78,24,14,3],
[78,24,14,4],
[78,24,14,5],
[78,24,14,6],
[78,24,14,7],
[78,24,14,8],
[78,24,14,10],
[78,24,14,11],
[78,24,14,12],
[78,24,17,0],
[78,24,17,1],
[78,24,17,2],
[78,24,17,3],
[78,24,17,4],
[78,24,17,5],
[78,24,17,6],
[78,24,17,7],
[78,24,17,8],
[78,24,17,10],
[78,24,17,11],
[78,24,17,12],
[78,24,17,13],
[78,24,17,14],
[78,24,19,0],
[78,24,19,1],
[78,24,19,2],
[78,24,19,3],
[78,24,19,4],
[78,24,19,5],
[78,24,19,6],
[78,24,19,7],
[78,24,19,8],
[78,24,19,10],
[78,24,19,11],
[78,24,19,12],
[78,24,19,17],
[78,24,20,0],
[78,24,20,1],
[78,24,20,2],
[78,24,20,3],
[78,24,20,4],
[78,24,20,5],
[78,24,20,6],
[78,24,20,7],
[78,24,20,8],
[78,24,20,11],
[78,24,20,12],
[78,24,20,13],
[78,24,20,14],
[78,24,20,19],
[78,24,21,0],
[78,24,21,1],
[78,24,21,2],
[78,24,21,4],
[78,24,21,7],
[78,24,21,10],
[78,24,21,11],
[78,24,21,12],
[78,24,21,13],
[78,24,21,14],
[78,24,21,17],
[78,24,21,19],
[78,24,21,20],
[78,24,22,0],
[78,24,22,1],
[78,24,22,2],
[78,24,22,3],
[78,24,22,4],
[78,24,22,5],
[78,24,22,6],
[78,24,22,7],
[78,24,22,8],
[78,24,22,11],
[78,24,22,12],
[78,24,22,13],
[78,24,22,17],
[78,24,22,19],
[78,24,22,21],
[78,24,23,0],
[78,24,23,1],
[78,24,23,2],
[78,24,23,3],
[78,24,23,4],
[78,24,23,5],
[78,24,23,6],
[78,24,23,7],
[78,24,23,8],
[78,24,23,10],
[78,24,23,11],
[78,24,23,14],
[78,24,23,17],
[78,24,23,19],
[78,24,23,20],
[78,24,23,21],
[78,24,23,22],
[78,25,3,0],
[78,25,3,1],
[78,25,3,2],
[78,25,4,0],
[78,25,4,1],
[78,25,4,2],
[78,25,4,3],
[78,25,5,0],
[78,25,5,1],
[78,25,5,2],
[78,25,5,4],
[78,25,6,0],
[78,25,6,1],
[78,25,6,2],
[78,25,6,4],
[78,25,8,0],
[78,25,8,1],
[78,25,8,2],
[78,25,8,4],
[78,25,10,0],
[78,25,10,1],
[78,25,10,2],
[78,25,10,3],
[78,25,10,4],
[78,25,10,5],
[78,25,10,6],
[78,25,10,8],
[78,25,11,0],
[78,25,11,1],
[78,25,11,2],
[78,25,11,3],
[78,25,11,4],
[78,25,11,5],
[78,25,11,6],
[78,25,11,8],
[78,25,11,10],
[78,25,12,0],
[78,25,12,1],
[78,25,12,2],
[78,25,12,3],
[78,25,12,4],
[78,25,12,5],
[78,25,12,6],
[78,25,12,8],
[78,25,12,10],
[78,25,13,0],
[78,25,13,1],
[78,25,13,2],
[78,25,13,3],
[78,25,13,4],
[78,25,13,5],
[78,25,13,6],
[78,25,13,8],
[78,25,13,10],
[78,25,13,11],
[78,25,13,12],
[78,25,14,0],
[78,25,14,1],
[78,25,14,2],
[78,25,14,3],
[78,25,14,4],
[78,25,14,5],
[78,25,14,6],
[78,25,14,8],
[78,25,14,10],
[78,25,14,11],
[78,25,14,12],
[78,25,16,0],
[78,25,16,1],
[78,25,16,2],
[78,25,16,3],
[78,25,16,4],
[78,25,16,5],
[78,25,16,6],
[78,25,16,8],
[78,25,16,10],
[78,25,16,11],
[78,25,16,12],
[78,25,16,13],
[78,25,16,14],
[78,25,17,0],
[78,25,17,1],
[78,25,17,2],
[78,25,17,3],
[78,25,17,4],
[78,25,17,5],
[78,25,17,6],
[78,25,17,8],
[78,25,17,10],
[78,25,17,11],
[78,25,17,12],
[78,25,17,13],
[78,25,17,14],
[78,25,17,16],
[78,25,19,0],
[78,25,19,1],
[78,25,19,2],
[78,25,19,3],
[78,25,19,4],
[78,25,19,5],
[78,25,19,6],
[78,25,19,8],
[78,25,19,10],
[78,25,19,11],
[78,25,19,12],
[78,25,19,16],
[78,25,19,17],
[78,25,20,0],
[78,25,20,1],
[78,25,20,2],
[78,25,20,3],
[78,25,20,4],
[78,25,20,5],
[78,25,20,6],
[78,25,20,8],
[78,25,20,11],
[78,25,20,12],
[78,25,20,13],
[78,25,20,16],
[78,25,20,17],
[78,25,20,19],
[78,25,21,0],
[78,25,21,1],
[78,25,21,2],
[78,25,21,4],
[78,25,21,10],
[78,25,21,11],
[78,25,21,14],
[78,25,21,16],
[78,25,21,17],
[78,25,21,19],
[78,25,21,20],
[78,25,22,0],
[78,25,22,1],
[78,25,22,2],
[78,25,22,3],
[78,25,22,4],
[78,25,22,5],
[78,25,22,6],
[78,25,22,8],
[78,25,22,12],
[78,25,22,13],
[78,25,22,14],
[78,25,22,16],
[78,25,22,17],
[78,25,22,19],
[78,25,22,21],
[78,25,23,0],
[78,25,23,1],
[78,25,23,2],
[78,25,23,3],
[78,25,23,4],
[78,25,23,5],
[78,25,23,6],
[78,25,23,10],
[78,25,23,11],
[78,25,23,12],
[78,25,23,13],
[78,25,23,14],
[78,25,23,16],
[78,25,23,17],
[78,25,23,19],
[78,25,23,20],
[78,25,23,21],
[78,25,23,22],
[78,25,24,0],
[78,25,24,1],
[78,25,24,2],
[78,25,24,3],
[78,25,24,4],
[78,25,24,5],
[78,25,24,6],
[78,25,24,10],
[78,25,24,11],
[78,25,24,12],
[78,25,24,13],
[78,25,24,14],
[78,25,24,17],
[78,25,24,19],
[78,25,24,20],
[78,25,24,21],
[78,25,24,22],
[78,25,24,23],
[78,27,3,0],
[78,27,3,1],
[78,27,3,2],
[78,27,4,0],
[78,27,4,1],
[78,27,4,2],
[78,27,4,3],
[78,27,5,0],
[78,27,5,1],
[78,27,5,2],
[78,27,5,4],
[78,27,6,0],
[78,27,6,1],
[78,27,6,2],
[78,27,6,4],
[78,27,7,0],
[78,27,7,1],
[78,27,7,2],
[78,27,7,3],
[78,27,7,4],
[78,27,7,5],
[78,27,7,6],
[78,27,8,0],
[78,27,8,1],
[78,27,8,2],
[78,27,8,4],
[78,27,8,7],
[78,27,11,0],
[78,27,11,1],
[78,27,11,2],
[78,27,11,3],
[78,27,11,4],
[78,27,11,5],
[78,27,11,6],
[78,27,11,7],
[78,27,11,8],
[78,27,12,0],
[78,27,12,1],
[78,27,12,2],
[78,27,12,3],
[78,27,12,4],
[78,27,12,5],
[78,27,12,6],
[78,27,12,7],
[78,27,12,8],
[78,27,13,0],
[78,27,13,1],
[78,27,13,2],
[78,27,13,3],
[78,27,13,4],
[78,27,13,5],
[78,27,13,6],
[78,27,13,7],
[78,27,13,8],
[78,27,13,11],
[78,27,13,12],
[78,27,14,0],
[78,27,14,1],
[78,27,14,2],
[78,27,14,3],
[78,27,14,4],
[78,27,14,5],
[78,27,14,6],
[78,27,14,7],
[78,27,14,8],
[78,27,14,11],
[78,27,14,12],
[78,27,16,0],
[78,27,16,1],
[78,27,16,2],
[78,27,16,3],
[78,27,16,4],
[78,27,16,5],
[78,27,16,6],
[78,27,16,7],
[78,27,16,8],
[78,27,16,11],
[78,27,16,12],
[78,27,16,13],
[78,27,16,14],
[78,27,17,0],
[78,27,17,1],
[78,27,17,2],
[78,27,17,3],
[78,27,17,4],
[78,27,17,5],
[78,27,17,6],
[78,27,17,7],
[78,27,17,8],
[78,27,17,11],
[78,27,17,12],
[78,27,17,13],
[78,27,17,14],
[78,27,17,16],
[78,27,19,0],
[78,27,19,1],
[78,27,19,2],
[78,27,19,3],
[78,27,19,4],
[78,27,19,5],
[78,27,19,6],
[78,27,19,7],
[78,27,19,8],
[78,27,19,11],
[78,27,19,16],
[78,27,19,17],
[78,27,21,0],
[78,27,21,1],
[78,27,21,2],
[78,27,21,4],
[78,27,21,7],
[78,27,21,11],
[78,27,21,12],
[78,27,21,13],
[78,27,21,14],
[78,27,21,16],
[78,27,21,17],
[78,27,21,19],
[78,27,23,0],
[78,27,23,1],
[78,27,23,2],
[78,27,23,3],
[78,27,23,4],
[78,27,23,6],
[78,27,23,7],
[78,27,23,8],
[78,27,23,11],
[78,27,23,12],
[78,27,23,13],
[78,27,23,14],
[78,27,23,16],
[78,27,23,17],
[78,27,23,19],
[78,27,23,21],
[78,27,24,0],
[78,27,24,1],
[78,27,24,2],
[78,27,24,3],
[78,27,24,4],
[78,27,24,6],
[78,27,24,7],
[78,27,24,8],
[78,27,24,11],
[78,27,24,12],
[78,27,24,13],
[78,27,24,14],
[78,27,24,17],
[78,27,24,19],
[78,27,24,21],
[78,27,25,3],
[78,27,25,4],
[78,27,25,5],
[78,27,25,6],
[78,27,25,8],
[78,27,25,11],
[78,27,25,12],
[78,27,25,13],
[78,27,25,14],
[78,27,25,16],
[78,27,25,17],
[78,27,25,19],
[78,28,3,0],
[78,28,3,1],
[78,28,3,2],
[78,28,4,0],
[78,28,4,1],
[78,28,4,2],
[78,28,4,3],
[78,28,5,0],
[78,28,5,1],
[78,28,5,2],
[78,28,5,4],
[78,28,6,0],
[78,28,6,1],
[78,28,6,2],
[78,28,6,4],
[78,28,8,0],
[78,28,8,1],
[78,28,8,2],
[78,28,8,4],
[78,28,10,0],
[78,28,10,1],
[78,28,10,2],
[78,28,10,3],
[78,28,10,4],
[78,28,10,5],
[78,28,10,6],
[78,28,10,8],
[78,28,11,0],
[78,28,11,1],
[78,28,11,2],
[78,28,11,3],
[78,28,11,4],
[78,28,11,5],
[78,28,11,6],
[78,28,11,8],
[78,28,11,10],
[78,28,12,0],
[78,28,12,1],
[78,28,12,2],
[78,28,12,3],
[78,28,12,4],
[78,28,12,5],
[78,28,12,6],
[78,28,12,8],
[78,28,12,10],
[78,28,13,0],
[78,28,13,1],
[78,28,13,2],
[78,28,13,3],
[78,28,13,4],
[78,28,13,5],
[78,28,13,6],
[78,28,13,8],
[78,28,13,10],
[78,28,13,11],
[78,28,13,12],
[78,28,14,0],
[78,28,14,1],
[78,28,14,2],
[78,28,14,3],
[78,28,14,4],
[78,28,14,5],
[78,28,14,6],
[78,28,14,8],
[78,28,14,10],
[78,28,14,11],
[78,28,14,12],
[78,28,16,0],
[78,28,16,1],
[78,28,16,2],
[78,28,16,3],
[78,28,16,4],
[78,28,16,5],
[78,28,16,6],
[78,28,16,8],
[78,28,16,10],
[78,28,16,11],
[78,28,16,12],
[78,28,16,13],
[78,28,17,0],
[78,28,17,1],
[78,28,17,2],
[78,28,17,3],
[78,28,17,4],
[78,28,17,5],
[78,28,17,6],
[78,28,17,8],
[78,28,17,10],
[78,28,17,11],
[78,28,17,12],
[78,28,17,13],
[78,28,17,16],
[78,28,19,0],
[78,28,19,1],
[78,28,19,2],
[78,28,19,3],
[78,28,19,4],
[78,28,19,5],
[78,28,19,6],
[78,28,19,8],
[78,28,19,12],
[78,28,19,16],
[78,28,19,17],
[78,28,20,0],
[78,28,20,1],
[78,28,20,2],
[78,28,20,3],
[78,28,20,4],
[78,28,20,5],
[78,28,20,6],
[78,28,20,11],
[78,28,20,12],
[78,28,20,13],
[78,28,20,14],
[78,28,20,16],
[78,28,20,17],
[78,28,20,19],
[78,28,21,0],
[78,28,21,1],
[78,28,21,2],
[78,28,21,4],
[78,28,21,10],
[78,28,21,11],
[78,28,21,12],
[78,28,21,13],
[78,28,21,14],
[78,28,21,16],
[78,28,21,17],
[78,28,21,19],
[78,28,21,20],
[78,28,22,0],
[78,28,22,1],
[78,28,22,2],
[78,28,22,3],
[78,28,22,4],
[78,28,22,6],
[78,28,22,8],
[78,28,22,11],
[78,28,22,12],
[78,28,22,13],
[78,28,22,14],
[78,28,22,16],
[78,28,22,17],
[78,28,22,19],
[78,28,22,21],
[78,28,23,0],
[78,28,23,1],
[78,28,23,2],
[78,28,23,5],
[78,28,23,6],
[78,28,23,8],
[78,28,23,10],
[78,28,23,11],
[78,28,23,12],
[78,28,23,13],
[78,28,23,14],
[78,28,23,16],
[78,28,23,17],
[78,28,23,19],
[78,28,23,20],
[78,28,23,21],
[78,28,24,0],
[78,28,24,1],
[78,28,24,2],
[78,28,24,5],
[78,28,24,6],
[78,28,24,8],
[78,28,24,10],
[78,28,24,11],
[78,28,24,12],
[78,28,24,13],
[78,28,24,14],
[78,28,24,17],
[78,28,24,19],
[78,28,24,20],
[78,28,24,21],
[78,28,27,0],
[78,28,27,1],
[78,28,27,2],
[78,28,27,3],
[78,28,27,4],
[78,28,27,5],
[78,28,27,6],
[78,28,27,8],
[78,28,27,11],
[78,28,27,12],
[78,28,27,13],
[78,28,27,14],
[78,28,27,16],
[78,28,27,17],
[78,29,3,0],
[78,29,3,1],
[78,29,3,2],
[78,29,4,0],
[78,29,4,1],
[78,29,4,2],
[78,29,4,3],
[78,29,5,0],
[78,29,5,1],
[78,29,5,2],
[78,29,5,4],
[78,29,6,0],
[78,29,6,1],
[78,29,6,2],
[78,29,6,4],
[78,29,7,0],
[78,29,7,1],
[78,29,7,2],
[78,29,7,3],
[78,29,7,4],
[78,29,7,5],
[78,29,7,6],
[78,29,8,0],
[78,29,8,1],
[78,29,8,2],
[78,29,8,4],
[78,29,8,7],
[78,29,10,0],
[78,29,10,1],
[78,29,10,2],
[78,29,10,3],
[78,29,10,4],
[78,29,10,5],
[78,29,10,6],
[78,29,10,7],
[78,29,10,8],
[78,29,13,0],
[78,29,13,1],
[78,29,13,2],
[78,29,13,3],
[78,29,13,4],
[78,29,13,5],
[78,29,13,6],
[78,29,13,7],
[78,29,13,8],
[78,29,13,10],
[78,29,14,0],
[78,29,14,1],
[78,29,14,2],
[78,29,14,3],
[78,29,14,4],
[78,29,14,5],
[78,29,14,6],
[78,29,14,7],
[78,29,14,8],
[78,29,14,10],
[78,29,16,0],
[78,29,16,1],
[78,29,16,2],
[78,29,16,3],
[78,29,16,4],
[78,29,16,5],
[78,29,16,6],
[78,29,16,7],
[78,29,16,8],
[78,29,16,10],
[78,29,16,14],
[78,29,17,0],
[78,29,17,1],
[78,29,17,2],
[78,29,17,3],
[78,29,17,4],
[78,29,17,5],
[78,29,17,6],
[78,29,17,7],
[78,29,17,8],
[78,29,17,10],
[78,29,17,14],
[78,29,17,16],
[78,29,19,0],
[78,29,19,1],
[78,29,19,2],
[78,29,19,3],
[78,29,19,4],
[78,29,19,5],
[78,29,19,6],
[78,29,19,7],
[78,29,19,10],
[78,29,19,16],
[78,29,19,17],
[78,29,20,0],
[78,29,20,1],
[78,29,20,2],
[78,29,20,3],
[78,29,20,4],
[78,29,20,5],
[78,29,20,8],
[78,29,20,13],
[78,29,20,14],
[78,29,20,16],
[78,29,20,17],
[78,29,20,19],
[78,29,21,0],
[78,29,21,1],
[78,29,21,2],
[78,29,21,4],
[78,29,21,7],
[78,29,21,10],
[78,29,21,13],
[78,29,21,14],
[78,29,21,16],
[78,29,21,17],
[78,29,21,19],
[78,29,21,20],
[78,29,22,0],
[78,29,22,1],
[78,29,22,2],
[78,29,22,5],
[78,29,22,6],
[78,29,22,7],
[78,29,22,8],
[78,29,22,13],
[78,29,22,14],
[78,29,22,16],
[78,29,22,17],
[78,29,22,19],
[78,29,22,21],
[78,29,23,3],
[78,29,23,4],
[78,29,23,5],
[78,29,23,6],
[78,29,23,7],
[78,29,23,8],
[78,29,23,10],
[78,29,23,13],
[78,29,23,14],
[78,29,23,16],
[78,29,23,17],
[78,29,23,19],
[78,29,23,20],
[78,29,24,3],
[78,29,24,4],
[78,29,24,5],
[78,29,24,6],
[78,29,24,7],
[78,29,24,8],
[78,29,24,10],
[78,29,24,13],
[78,29,24,14],
[78,29,24,17],
[78,29,24,19],
[78,29,24,20],
[78,29,25,0],
[78,29,25,1],
[78,29,25,2],
[78,29,25,3],
[78,29,25,4],
[78,29,25,5],
[78,29,25,6],
[78,29,25,8],
[78,29,25,10],
[78,29,25,13],
[78,29,25,14],
[78,29,25,16],
[78,29,25,17],
[78,29,27,0],
[78,29,27,1],
[78,29,27,2],
[78,29,27,3],
[78,29,27,4],
[78,29,27,5],
[78,29,27,6],
[78,29,27,7],
[78,29,27,8],
[78,29,27,13],
[78,29,27,14],
[78,29,28,0],
[78,29,28,1],
[78,29,28,2],
[78,29,28,3],
[78,29,28,4],
[78,29,28,5],
[78,29,28,6],
[78,29,28,8],
[78,29,28,10],
[78,29,28,13],
[78,29,28,14],
[78,30,3,0],
[78,30,3,1],
[78,30,3,2],
[78,30,4,0],
[78,30,4,1],
[78,30,4,2],
[78,30,4,3],
[78,30,5,0],
[78,30,5,1],
[78,30,5,2],
[78,30,5,4],
[78,30,6,0],
[78,30,6,1],
[78,30,6,2],
[78,30,6,4],
[78,30,7,0],
[78,30,7,1],
[78,30,7,2],
[78,30,7,3],
[78,30,7,4],
[78,30,7,5],
[78,30,7,6],
[78,30,8,0],
[78,30,8,1],
[78,30,8,2],
[78,30,8,4],
[78,30,8,7],
[78,30,10,0],
[78,30,10,1],
[78,30,10,2],
[78,30,10,3],
[78,30,10,4],
[78,30,10,5],
[78,30,10,6],
[78,30,10,7],
[78,30,10,8],
[78,30,11,0],
[78,30,11,1],
[78,30,11,2],
[78,30,11,3],
[78,30,11,4],
[78,30,11,5],
[78,30,11,6],
[78,30,11,7],
[78,30,11,8],
[78,30,11,10],
[78,30,12,0],
[78,30,12,1],
[78,30,12,2],
[78,30,12,3],
[78,30,12,4],
[78,30,12,5],
[78,30,12,6],
[78,30,12,7],
[78,30,12,8],
[78,30,12,10],
[78,30,13,0],
[78,30,13,1],
[78,30,13,2],
[78,30,13,3],
[78,30,13,4],
[78,30,13,5],
[78,30,13,6],
[78,30,13,7],
[78,30,13,8],
[78,30,13,10],
[78,30,13,11],
[78,30,13,12],
[78,30,14,0],
[78,30,14,1],
[78,30,14,2],
[78,30,14,3],
[78,30,14,4],
[78,30,14,5],
[78,30,14,6],
[78,30,14,7],
[78,30,14,8],
[78,30,14,10],
[78,30,14,11],
[78,30,14,12],
[78,30,16,0],
[78,30,16,1],
[78,30,16,2],
[78,30,16,3],
[78,30,16,4],
[78,30,16,5],
[78,30,16,6],
[78,30,16,7],
[78,30,16,8],
[78,30,16,12],
[78,30,16,13],
[78,30,16,14],
[78,30,17,0],
[78,30,17,1],
[78,30,17,2],
[78,30,17,3],
[78,30,17,4],
[78,30,17,5],
[78,30,17,6],
[78,30,17,7],
[78,30,17,8],
[78,30,17,12],
[78,30,17,13],
[78,30,17,14],
[78,30,17,16],
[78,30,19,0],
[78,30,19,1],
[78,30,19,2],
[78,30,19,3],
[78,30,19,4],
[78,30,19,5],
[78,30,19,8],
[78,30,19,10],
[78,30,19,11],
[78,30,19,12],
[78,30,19,16],
[78,30,19,17],
[78,30,20,0],
[78,30,20,1],
[78,30,20,2],
[78,30,20,3],
[78,30,20,4],
[78,30,20,6],
[78,30,20,7],
[78,30,20,8],
[78,30,20,11],
[78,30,20,12],
[78,30,20,13],
[78,30,20,14],
[78,30,20,16],
[78,30,20,17],
[78,30,20,19],
[78,30,21,0],
[78,30,21,1],
[78,30,21,2],
[78,30,21,7],
[78,30,21,10],
[78,30,21,11],
[78,30,21,12],
[78,30,21,13],
[78,30,21,14],
[78,30,21,16],
[78,30,21,17],
[78,30,21,19],
[78,30,21,20],
[78,30,22,3],
[78,30,22,4],
[78,30,22,5],
[78,30,22,6],
[78,30,22,7],
[78,30,22,8],
[78,30,22,11],
[78,30,22,12],
[78,30,22,13],
[78,30,22,14],
[78,30,22,16],
[78,30,22,17],
[78,30,22,19],
[78,30,24,0],
[78,30,24,1],
[78,30,24,2],
[78,30,24,3],
[78,30,24,4],
[78,30,24,5],
[78,30,24,6],
[78,30,24,7],
[78,30,24,8],
[78,30,24,10],
[78,30,24,11],
[78,30,24,12],
[78,30,24,13],
[78,30,24,14],
[78,30,24,17],
[78,30,24,19],
[78,30,25,0],
[78,30,25,1],
[78,30,25,2],
[78,30,25,3],
[78,30,25,4],
[78,30,25,5],
[78,30,25,6],
[78,30,25,8],
[78,30,25,10],
[78,30,25,11],
[78,30,25,12],
[78,30,25,13],
[78,30,25,14],
[78,30,25,16],
[78,30,25,17],
[78,30,27,0],
[78,30,27,1],
[78,30,27,2],
[78,30,27,3],
[78,30,27,4],
[78,30,27,5],
[78,30,27,6],
[78,30,27,7],
[78,30,27,8],
[78,30,27,11],
[78,30,27,12],
[78,30,27,13],
[78,30,27,14],
[78,30,28,0],
[78,30,28,1],
[78,30,28,2],
[78,30,28,3],
[78,30,28,4],
[78,30,28,5],
[78,30,28,6],
[78,30,28,8],
[78,30,28,10],
[78,30,28,11],
[78,30,28,12],
[78,30,28,13],
[78,30,29,0],
[78,30,29,1],
[78,30,29,2],
[78,30,29,3],
[78,30,29,4],
[78,30,29,5],
[78,30,29,6],
[78,30,29,7],
[78,30,29,8],
[78,30,29,10],
[78,31,3,0],
[78,31,3,1],
[78,31,3,2],
[78,31,4,0],
[78,31,4,1],
[78,31,4,2],
[78,31,4,3],
[78,31,5,0],
[78,31,5,1],
[78,31,5,2],
[78,31,5,4],
[78,31,6,0],
[78,31,6,1],
[78,31,6,2],
[78,31,6,4],
[78,31,7,0],
[78,31,7,1],
[78,31,7,2],
[78,31,7,3],
[78,31,7,4],
[78,31,7,5],
[78,31,7,6],
[78,31,8,0],
[78,31,8,1],
[78,31,8,2],
[78,31,8,4],
[78,31,8,7],
[78,31,10,0],
[78,31,10,1],
[78,31,10,2],
[78,31,10,3],
[78,31,10,4],
[78,31,10,5],
[78,31,10,6],
[78,31,10,7],
[78,31,10,8],
[78,31,11,0],
[78,31,11,1],
[78,31,11,2],
[78,31,11,3],
[78,31,11,4],
[78,31,11,5],
[78,31,11,6],
[78,31,11,7],
[78,31,11,8],
[78,31,11,10],
[78,31,12,0],
[78,31,12,1],
[78,31,12,2],
[78,31,12,3],
[78,31,12,4],
[78,31,12,5],
[78,31,12,6],
[78,31,12,7],
[78,31,12,8],
[78,31,12,10],
[78,31,13,0],
[78,31,13,1],
[78,31,13,2],
[78,31,13,3],
[78,31,13,4],
[78,31,13,5],
[78,31,13,6],
[78,31,13,7],
[78,31,13,8],
[78,31,13,10],
[78,31,13,11],
[78,31,13,12],
[78,31,14,0],
[78,31,14,1],
[78,31,14,2],
[78,31,14,3],
[78,31,14,4],
[78,31,14,5],
[78,31,14,6],
[78,31,14,7],
[78,31,14,8],
[78,31,14,10],
[78,31,14,11],
[78,31,16,0],
[78,31,16,1],
[78,31,16,2],
[78,31,16,3],
[78,31,16,4],
[78,31,16,5],
[78,31,16,6],
[78,31,16,7],
[78,31,16,10],
[78,31,16,11],
[78,31,16,12],
[78,31,16,13],
[78,31,16,14],
[78,31,17,0],
[78,31,17,1],
[78,31,17,2],
[78,31,17,3],
[78,31,17,4],
[78,31,17,5],
[78,31,17,6],
[78,31,17,7],
[78,31,17,10],
[78,31,17,11],
[78,31,17,12],
[78,31,17,13],
[78,31,17,14],
[78,31,17,16],
[78,31,19,0],
[78,31,19,1],
[78,31,19,2],
[78,31,19,3],
[78,31,19,4],
[78,31,19,6],
[78,31,19,7],
[78,31,19,8],
[78,31,19,10],
[78,31,19,11],
[78,31,19,12],
[78,31,19,16],
[78,31,19,17],
[78,31,20,0],
[78,31,20,1],
[78,31,20,2],
[78,31,20,5],
[78,31,20,6],
[78,31,20,7],
[78,31,20,8],
[78,31,20,11],
[78,31,20,12],
[78,31,20,13],
[78,31,20,14],
[78,31,20,16],
[78,31,20,17],
[78,31,20,19],
[78,31,21,4],
[78,31,21,7],
[78,31,21,10],
[78,31,21,11],
[78,31,21,12],
[78,31,21,13],
[78,31,21,14],
[78,31,21,16],
[78,31,21,17],
[78,31,21,19],
[78,31,21,20],
[78,31,22,0],
[78,31,22,1],
[78,31,22,2],
[78,31,22,3],
[78,31,22,4],
[78,31,22,5],
[78,31,22,6],
[78,31,22,7],
[78,31,22,8],
[78,31,22,11],
[78,31,22,12],
[78,31,22,13],
[78,31,22,14],
[78,31,22,16],
[78,31,22,17],
[78,31,22,19],
[78,31,24,0],
[78,31,24,1],
[78,31,24,2],
[78,31,24,3],
[78,31,24,4],
[78,31,24,5],
[78,31,24,6],
[78,31,24,7],
[78,31,24,8],
[78,31,24,10],
[78,31,24,11],
[78,31,24,12],
[78,31,24,13],
[78,31,24,14],
[78,31,24,17],
[78,31,25,0],
[78,31,25,1],
[78,31,25,2],
[78,31,25,3],
[78,31,25,4],
[78,31,25,5],
[78,31,25,6],
[78,31,25,8],
[78,31,25,10],
[78,31,25,11],
[78,31,25,12],
[78,31,25,13],
[78,31,25,14],
[78,31,27,0],
[78,31,27,1],
[78,31,27,2],
[78,31,27,3],
[78,31,27,4],
[78,31,27,5],
[78,31,27,6],
[78,31,27,7],
[78,31,27,8],
[78,31,27,11],
[78,31,27,12],
[78,31,27,13],
[78,31,28,0],
[78,31,28,1],
[78,31,28,2],
[78,31,28,3],
[78,31,28,4],
[78,31,28,5],
[78,31,28,6],
[78,31,28,8],
[78,31,28,10],
[78,31,28,11],
[78,31,29,0],
[78,31,29,1],
[78,31,29,2],
[78,31,29,3],
[78,31,29,4],
[78,31,29,5],
[78,31,29,6],
[78,31,29,7],
[78,31,29,8],
[78,32,3,0],
[78,32,3,1],
[78,32,3,2],
[78,32,4,0],
[78,32,4,1],
[78,32,4,2],
[78,32,4,3],
[78,32,5,0],
[78,32,5,1],
[78,32,5,2],
[78,32,5,4],
[78,32,6,0],
[78,32,6,1],
[78,32,6,2],
[78,32,6,4],
[78,32,7,0],
[78,32,7,1],
[78,32,7,2],
[78,32,7,3],
[78,32,7,4],
[78,32,7,5],
[78,32,7,6],
[78,32,8,0],
[78,32,8,1],
[78,32,8,2],
[78,32,8,4],
[78,32,8,7],
[78,32,10,0],
[78,32,10,1],
[78,32,10,2],
[78,32,10,3],
[78,32,10,4],
[78,32,10,5],
[78,32,10,6],
[78,32,10,7],
[78,32,10,8],
[78,32,13,0],
[78,32,13,1],
[78,32,13,2],
[78,32,13,3],
[78,32,13,4],
[78,32,13,5],
[78,32,13,6],
[78,32,13,7],
[78,32,13,8],
[78,32,13,10],
[78,32,14,0],
[78,32,14,1],
[78,32,14,2],
[78,32,14,3],
[78,32,14,4],
[78,32,14,5],
[78,32,14,6],
[78,32,14,7],
[78,32,14,8],
[78,32,16,0],
[78,32,16,1],
[78,32,16,2],
[78,32,16,3],
[78,32,16,4],
[78,32,16,5],
[78,32,16,8],
[78,32,16,10],
[78,32,16,13],
[78,32,16,14],
[78,32,17,0],
[78,32,17,1],
[78,32,17,2],
[78,32,17,3],
[78,32,17,4],
[78,32,17,5],
[78,32,17,8],
[78,32,17,10],
[78,32,17,13],
[78,32,17,14],
[78,32,17,16],
[78,32,19,0],
[78,32,19,1],
[78,32,19,2],
[78,32,19,5],
[78,32,19,6],
[78,32,19,7],
[78,32,19,8],
[78,32,19,10],
[78,32,19,16],
[78,32,19,17],
[78,32,20,3],
[78,32,20,4],
[78,32,20,5],
[78,32,20,6],
[78,32,20,7],
[78,32,20,8],
[78,32,20,13],
[78,32,20,14],
[78,32,20,16],
[78,32,20,17],
[78,32,20,19],
[78,32,21,0],
[78,32,21,1],
[78,32,21,2],
[78,32,21,4],
[78,32,21,7],
[78,32,21,10],
[78,32,21,13],
[78,32,21,14],
[78,32,21,16],
[78,32,21,17],
[78,32,21,19],
[78,32,22,0],
[78,32,22,1],
[78,32,22,2],
[78,32,22,3],
[78,32,22,4],
[78,32,22,5],
[78,32,22,6],
[78,32,22,7],
[78,32,22,8],
[78,32,22,13],
[78,32,22,14],
[78,32,22,16],
[78,32,22,17],
[78,32,23,0],
[78,32,23,1],
[78,32,23,2],
[78,32,23,3],
[78,32,23,4],
[78,32,23,5],
[78,32,23,6],
[78,32,23,7],
[78,32,23,8],
[78,32,23,10],
[78,32,23,13],
[78,32,23,14],
[78,32,23,16],
[78,32,23,17],
[78,32,24,0],
[78,32,24,1],
[78,32,24,2],
[78,32,24,3],
[78,32,24,4],
[78,32,24,5],
[78,32,24,6],
[78,32,24,7],
[78,32,24,8],
[78,32,24,10],
[78,32,24,13],
[78,32,24,14],
[78,32,24,17],
[78,32,25,0],
[78,32,25,1],
[78,32,25,2],
[78,32,25,3],
[78,32,25,4],
[78,32,25,5],
[78,32,25,6],
[78,32,25,8],
[78,32,25,10],
[78,32,25,13],
[78,32,25,14],
[78,32,27,0],
[78,32,27,1],
[78,32,27,2],
[78,32,27,3],
[78,32,27,4],
[78,32,27,5],
[78,32,27,6],
[78,32,27,7],
[78,32,27,8],
[78,32,28,0],
[78,32,28,1],
[78,32,28,2],
[78,32,28,3],
[78,32,28,4],
[78,32,28,5],
[78,32,28,6],
[78,32,28,8],
[78,32,30,0],
[78,32,30,1],
[78,32,30,2],
[78,32,30,3],
[78,32,30,4],
[78,32,30,5],
[78,32,31,0],
[78,32,31,1],
[78,32,31,2],
[78,32,31,3],
[78,32,31,4],
[78,33,3,0],
[78,33,3,1],
[78,33,3,2],
[78,33,4,0],
[78,33,4,1],
[78,33,4,2],
[78,33,4,3],
[78,33,5,0],
[78,33,5,1],
[78,33,5,2],
[78,33,5,4],
[78,33,6,0],
[78,33,6,1],
[78,33,6,2],
[78,33,6,4],
[78,33,7,0],
[78,33,7,1],
[78,33,7,2],
[78,33,7,3],
[78,33,7,4],
[78,33,7,5],
[78,33,7,6],
[78,33,8,0],
[78,33,8,1],
[78,33,8,2],
[78,33,8,4],
[78,33,8,7],
[78,33,10,0],
[78,33,10,1],
[78,33,10,2],
[78,33,10,3],
[78,33,10,4],
[78,33,10,5],
[78,33,10,6],
[78,33,10,7],
[78,33,10,8],
[78,33,13,0],
[78,33,13,1],
[78,33,13,2],
[78,33,13,3],
[78,33,13,4],
[78,33,13,5],
[78,33,13,6],
[78,33,13,7],
[78,33,13,8],
[78,33,14,0],
[78,33,14,1],
[78,33,14,2],
[78,33,14,3],
[78,33,14,4],
[78,33,14,5],
[78,33,14,6],
[78,33,14,7],
[78,33,14,10],
[78,33,16,0],
[78,33,16,1],
[78,33,16,2],
[78,33,16,3],
[78,33,16,4],
[78,33,16,6],
[78,33,16,7],
[78,33,16,8],
[78,33,16,10],
[78,33,16,13],
[78,33,16,14],
[78,33,17,0],
[78,33,17,1],
[78,33,17,2],
[78,33,17,3],
[78,33,17,4],
[78,33,17,6],
[78,33,17,7],
[78,33,17,8],
[78,33,17,10],
[78,33,17,13],
[78,33,17,14],
[78,33,17,16],
[78,33,19,3],
[78,33,19,4],
[78,33,19,5],
[78,33,19,6],
[78,33,19,7],
[78,33,19,8],
[78,33,19,10],
[78,33,19,16],
[78,33,19,17],
[78,33,20,0],
[78,33,20,1],
[78,33,20,2],
[78,33,20,3],
[78,33,20,4],
[78,33,20,5],
[78,33,20,6],
[78,33,20,7],
[78,33,20,8],
[78,33,20,13],
[78,33,20,14],
[78,33,20,16],
[78,33,20,17],
[78,33,20,19],
[78,33,21,0],
[78,33,21,1],
[78,33,21,2],
[78,33,21,4],
[78,33,21,7],
[78,33,21,10],
[78,33,21,13],
[78,33,21,14],
[78,33,21,16],
[78,33,21,17],
[78,33,22,0],
[78,33,22,1],
[78,33,22,2],
[78,33,22,3],
[78,33,22,4],
[78,33,22,5],
[78,33,22,6],
[78,33,22,7],
[78,33,22,8],
[78,33,22,13],
[78,33,22,14],
[78,33,22,16],
[78,33,22,17],
[78,33,23,0],
[78,33,23,1],
[78,33,23,2],
[78,33,23,3],
[78,33,23,4],
[78,33,23,5],
[78,33,23,6],
[78,33,23,7],
[78,33,23,8],
[78,33,23,10],
[78,33,23,13],
[78,33,23,14],
[78,33,24,0],
[78,33,24,1],
[78,33,24,2],
[78,33,24,3],
[78,33,24,4],
[78,33,24,5],
[78,33,24,6],
[78,33,24,7],
[78,33,24,8],
[78,33,24,10],
[78,33,24,13],
[78,33,24,14],
[78,33,25,0],
[78,33,25,1],
[78,33,25,2],
[78,33,25,3],
[78,33,25,4],
[78,33,25,5],
[78,33,25,6],
[78,33,25,8],
[78,33,25,10],
[78,33,25,13],
[78,33,27,0],
[78,33,27,1],
[78,33,27,2],
[78,33,27,3],
[78,33,27,4],
[78,33,27,5],
[78,33,27,6],
[78,33,27,7],
[78,33,27,8],
[78,33,28,0],
[78,33,28,1],
[78,33,28,2],
[78,33,28,3],
[78,33,28,4],
[78,33,28,5],
[78,33,28,6],
[78,33,30,0],
[78,33,30,1],
[78,33,30,2],
[78,33,30,3],
[78,33,30,4],
[78,33,31,0],
[78,33,31,1],
[78,33,31,2],
[78,34,3,0],
[78,34,3,1],
[78,34,3,2],
[78,34,4,0],
[78,34,4,1],
[78,34,4,2],
[78,34,4,3],
[78,34,5,0],
[78,34,5,1],
[78,34,5,2],
[78,34,5,4],
[78,34,6,0],
[78,34,6,1],
[78,34,6,2],
[78,34,6,4],
[78,34,8,0],
[78,34,8,1],
[78,34,8,2],
[78,34,8,4],
[78,34,10,0],
[78,34,10,1],
[78,34,10,2],
[78,34,10,3],
[78,34,10,4],
[78,34,10,5],
[78,34,10,6],
[78,34,10,8],
[78,34,11,0],
[78,34,11,1],
[78,34,11,2],
[78,34,11,3],
[78,34,11,4],
[78,34,11,5],
[78,34,11,6],
[78,34,11,8],
[78,34,12,0],
[78,34,12,1],
[78,34,12,2],
[78,34,12,3],
[78,34,12,4],
[78,34,12,5],
[78,34,12,6],
[78,34,12,10],
[78,34,13,0],
[78,34,13,1],
[78,34,13,2],
[78,34,13,3],
[78,34,13,4],
[78,34,13,5],
[78,34,13,6],
[78,34,13,10],
[78,34,13,11],
[78,34,13,12],
[78,34,14,0],
[78,34,14,1],
[78,34,14,2],
[78,34,14,3],
[78,34,14,4],
[78,34,14,5],
[78,34,14,8],
[78,34,14,10],
[78,34,14,11],
[78,34,14,12],
[78,34,16,0],
[78,34,16,1],
[78,34,16,2],
[78,34,16,5],
[78,34,16,6],
[78,34,16,8],
[78,34,16,10],
[78,34,16,11],
[78,34,16,12],
[78,34,16,13],
[78,34,16,14],
[78,34,17,0],
[78,34,17,1],
[78,34,17,2],
[78,34,17,5],
[78,34,17,6],
[78,34,17,8],
[78,34,17,10],
[78,34,17,11],
[78,34,17,12],
[78,34,17,13],
[78,34,17,14],
[78,34,17,16],
[78,34,19,0],
[78,34,19,1],
[78,34,19,2],
[78,34,19,3],
[78,34,19,4],
[78,34,19,5],
[78,34,19,6],
[78,34,19,8],
[78,34,19,10],
[78,34,19,11],
[78,34,19,12],
[78,34,19,16],
[78,34,19,17],
[78,34,20,0],
[78,34,20,1],
[78,34,20,2],
[78,34,20,3],
[78,34,20,4],
[78,34,20,5],
[78,34,20,6],
[78,34,20,8],
[78,34,20,11],
[78,34,20,12],
[78,34,20,13],
[78,34,20,14],
[78,34,20,16],
[78,34,20,17],
[78,34,21,0],
[78,34,21,1],
[78,34,21,2],
[78,34,21,4],
[78,34,21,10],
[78,34,21,11],
[78,34,21,12],
[78,34,21,13],
[78,34,21,14],
[78,34,21,16],
[78,34,21,17],
[78,34,22,0],
[78,34,22,1],
[78,34,22,2],
[78,34,22,3],
[78,34,22,4],
[78,34,22,5],
[78,34,22,6],
[78,34,22,8],
[78,34,22,11],
[78,34,22,12],
[78,34,22,13],
[78,34,22,14],
[78,34,23,0],
[78,34,23,1],
[78,34,23,2],
[78,34,23,3],
[78,34,23,4],
[78,34,23,5],
[78,34,23,6],
[78,34,23,8],
[78,34,23,10],
[78,34,23,11],
[78,34,23,12],
[78,34,23,13],
[78,34,23,14],
[78,34,24,0],
[78,34,24,1],
[78,34,24,2],
[78,34,24,3],
[78,34,24,4],
[78,34,24,5],
[78,34,24,6],
[78,34,24,8],
[78,34,24,10],
[78,34,24,11],
[78,34,24,12],
[78,34,24,13],
[78,34,24,14],
[78,34,27,0],
[78,34,27,1],
[78,34,27,2],
[78,34,27,3],
[78,34,27,4],
[78,34,27,5],
[78,34,27,6],
[78,34,29,0],
[78,34,29,1],
[78,34,29,2],
[78,34,29,3],
[78,34,29,4],
[78,34,30,0],
[78,34,30,1],
[78,34,30,2],
[78,36,3,0],
[78,36,3,1],
[78,36,3,2],
[78,36,4,0],
[78,36,4,1],
[78,36,4,2],
[78,36,4,3],
[78,36,5,0],
[78,36,5,1],
[78,36,5,2],
[78,36,5,4],
[78,36,6,0],
[78,36,6,1],
[78,36,6,2],
[78,36,6,4],
[78,36,7,0],
[78,36,7,1],
[78,36,7,2],
[78,36,7,3],
[78,36,7,4],
[78,36,7,5],
[78,36,7,6],
[78,36,8,0],
[78,36,8,1],
[78,36,8,2],
[78,36,8,4],
[78,36,8,7],
[78,36,10,0],
[78,36,10,1],
[78,36,10,2],
[78,36,10,3],
[78,36,10,4],
[78,36,10,5],
[78,36,10,8],
[78,36,11,0],
[78,36,11,1],
[78,36,11,2],
[78,36,11,3],
[78,36,11,4],
[78,36,11,5],
[78,36,11,8],
[78,36,11,10],
[78,36,12,0],
[78,36,12,1],
[78,36,12,2],
[78,36,12,3],
[78,36,12,4],
[78,36,12,6],
[78,36,12,7],
[78,36,12,8],
[78,36,12,10],
[78,36,16,0],
[78,36,16,1],
[78,36,16,2],
[78,36,16,3],
[78,36,16,4],
[78,36,16,5],
[78,36,16,6],
[78,36,16,7],
[78,36,16,8],
[78,36,16,10],
[78,36,16,11],
[78,36,16,12],
[78,36,17,0],
[78,36,17,1],
[78,36,17,2],
[78,36,17,3],
[78,36,17,4],
[78,36,17,5],
[78,36,17,6],
[78,36,17,7],
[78,36,17,8],
[78,36,17,10],
[78,36,17,11],
[78,36,17,12],
[78,36,17,16],
[78,36,20,0],
[78,36,20,1],
[78,36,20,2],
[78,36,20,3],
[78,36,20,4],
[78,36,20,5],
[78,36,20,6],
[78,36,20,7],
[78,36,20,8],
[78,36,20,11],
[78,36,20,12],
[78,36,21,0],
[78,36,21,1],
[78,36,21,2],
[78,36,21,4],
[78,36,21,7],
[78,36,21,10],
[78,36,21,11],
[78,36,21,12],
[78,36,22,0],
[78,36,22,1],
[78,36,22,2],
[78,36,22,3],
[78,36,22,4],
[78,36,22,5],
[78,36,22,6],
[78,36,22,7],
[78,36,22,8],
[78,36,22,11],
[78,36,22,12],
[78,36,23,0],
[78,36,23,1],
[78,36,23,2],
[78,36,23,3],
[78,36,23,4],
[78,36,23,5],
[78,36,23,6],
[78,36,23,7],
[78,36,23,8],
[78,36,23,10],
[78,36,23,11],
[78,36,24,0],
[78,36,24,1],
[78,36,24,2],
[78,36,24,3],
[78,36,24,4],
[78,36,24,5],
[78,36,24,6],
[78,36,24,7],
[78,36,24,8],
[78,36,24,10],
[78,36,24,11],
[78,36,25,0],
[78,36,25,1],
[78,36,25,2],
[78,36,25,3],
[78,36,25,4],
[78,36,25,5],
[78,36,25,6],
[78,36,27,0],
[78,36,27,1],
[78,36,27,2],
[78,36,27,3],
[78,36,27,4],
[78,36,28,0],
[78,36,28,1],
[78,36,28,2],
[78,37,3,0],
[78,37,3,1],
[78,37,3,2],
[78,37,5,0],
[78,37,5,1],
[78,37,5,2],
[78,37,6,0],
[78,37,6,1],
[78,37,6,2],
[78,37,7,0],
[78,37,7,1],
[78,37,7,2],
[78,37,7,3],
[78,37,7,5],
[78,37,7,6],
[78,37,8,0],
[78,37,8,1],
[78,37,8,2],
[78,37,10,0],
[78,37,10,1],
[78,37,10,2],
[78,37,10,3],
[78,37,10,6],
[78,37,10,7],
[78,37,10,8],
[78,37,11,0],
[78,37,11,1],
[78,37,11,2],
[78,37,11,3],
[78,37,11,6],
[78,37,11,7],
[78,37,11,8],
[78,37,11,10],
[78,37,12,0],
[78,37,12,1],
[78,37,12,2],
[78,37,12,5],
[78,37,12,6],
[78,37,12,7],
[78,37,12,8],
[78,37,12,10],
[78,37,13,0],
[78,37,13,1],
[78,37,13,2],
[78,37,13,5],
[78,37,13,6],
[78,37,13,7],
[78,37,13,8],
[78,37,13,10],
[78,37,13,11],
[78,37,13,12],
[78,37,14,3],
[78,37,14,5],
[78,37,14,6],
[78,37,14,7],
[78,37,14,8],
[78,37,14,10],
[78,37,14,11],
[78,37,14,12],
[78,37,16,0],
[78,37,16,1],
[78,37,16,2],
[78,37,16,3],
[78,37,16,5],
[78,37,16,6],
[78,37,16,7],
[78,37,16,8],
[78,37,16,10],
[78,37,16,11],
[78,37,16,12],
[78,37,16,13],
[78,37,16,14],
[78,37,17,0],
[78,37,17,1],
[78,37,17,2],
[78,37,17,3],
[78,37,17,5],
[78,37,17,6],
[78,37,17,7],
[78,37,17,8],
[78,37,17,10],
[78,37,17,11],
[78,37,17,12],
[78,37,17,13],
[78,37,17,14],
[78,37,17,16],
[78,37,19,0],
[78,37,19,1],
[78,37,19,2],
[78,37,19,3],
[78,37,19,5],
[78,37,19,6],
[78,37,19,7],
[78,37,19,8],
[78,37,19,10],
[78,37,19,11],
[78,37,19,12],
[78,37,20,0],
[78,37,20,1],
[78,37,20,2],
[78,37,20,3],
[78,37,20,5],
[78,37,20,6],
[78,37,20,7],
[78,37,20,8],
[78,37,20,11],
[78,37,20,12],
[78,37,20,13],
[78,37,20,14],
[78,37,21,0],
[78,37,21,1],
[78,37,21,2],
[78,37,21,7],
[78,37,21,10],
[78,37,21,11],
[78,37,21,12],
[78,37,21,13],
[78,37,22,0],
[78,37,22,1],
[78,37,22,2],
[78,37,22,3],
[78,37,22,5],
[78,37,22,6],
[78,37,22,7],
[78,37,22,8],
[78,37,22,11],
[78,37,23,0],
[78,37,23,1],
[78,37,23,2],
[78,37,23,3],
[78,37,23,5],
[78,37,23,6],
[78,37,23,7],
[78,37,23,8],
[78,37,24,0],
[78,37,24,1],
[78,37,24,2],
[78,37,24,3],
[78,37,24,5],
[78,37,24,6],
[78,37,24,7],
[78,37,24,8],
[78,37,25,0],
[78,37,25,1],
[78,37,25,2],
[78,37,25,3],
[78,37,25,5],
[78,37,27,0],
[78,37,27,1],
[78,37,27,2],
[78,38,3,0],
[78,38,3,1],
[78,38,3,2],
[78,38,4,0],
[78,38,4,1],
[78,38,4,2],
[78,38,4,3],
[78,38,5,0],
[78,38,5,1],
[78,38,5,2],
[78,38,5,4],
[78,38,6,0],
[78,38,6,1],
[78,38,6,2],
[78,38,6,4],
[78,38,8,0],
[78,38,8,1],
[78,38,8,2],
[78,38,8,4],
[78,38,10,0],
[78,38,10,1],
[78,38,10,2],
[78,38,10,5],
[78,38,10,6],
[78,38,10,8],
[78,38,11,0],
[78,38,11,1],
[78,38,11,2],
[78,38,11,5],
[78,38,11,6],
[78,38,11,8],
[78,38,11,10],
[78,38,12,3],
[78,38,12,4],
[78,38,12,5],
[78,38,12,6],
[78,38,12,8],
[78,38,12,10],
[78,38,13,3],
[78,38,13,4],
[78,38,13,5],
[78,38,13,6],
[78,38,13,8],
[78,38,13,10],
[78,38,13,11],
[78,38,13,12],
[78,38,14,0],
[78,38,14,1],
[78,38,14,2],
[78,38,14,3],
[78,38,14,4],
[78,38,14,5],
[78,38,14,6],
[78,38,14,8],
[78,38,14,10],
[78,38,14,11],
[78,38,14,12],
[78,38,16,0],
[78,38,16,1],
[78,38,16,2],
[78,38,16,3],
[78,38,16,4],
[78,38,16,5],
[78,38,16,6],
[78,38,16,8],
[78,38,16,10],
[78,38,16,11],
[78,38,16,12],
[78,38,16,13],
[78,38,16,14],
[78,38,17,0],
[78,38,17,1],
[78,38,17,2],
[78,38,17,3],
[78,38,17,4],
[78,38,17,5],
[78,38,17,6],
[78,38,17,8],
[78,38,17,10],
[78,38,17,11],
[78,38,17,12],
[78,38,17,13],
[78,38,17,14],
[78,38,17,16],
[78,38,19,0],
[78,38,19,1],
[78,38,19,2],
[78,38,19,3],
[78,38,19,4],
[78,38,19,5],
[78,38,19,6],
[78,38,19,8],
[78,38,19,10],
[78,38,19,11],
[78,38,19,12],
[78,38,20,0],
[78,38,20,1],
[78,38,20,2],
[78,38,20,3],
[78,38,20,4],
[78,38,20,5],
[78,38,20,6],
[78,38,20,8],
[78,38,20,11],
[78,38,20,12],
[78,38,20,13],
[78,38,21,0],
[78,38,21,1],
[78,38,21,2],
[78,38,21,4],
[78,38,21,10],
[78,38,21,11],
[78,38,22,0],
[78,38,22,1],
[78,38,22,2],
[78,38,22,3],
[78,38,22,4],
[78,38,22,5],
[78,38,22,6],
[78,38,22,8],
[78,38,23,0],
[78,38,23,1],
[78,38,23,2],
[78,38,23,3],
[78,38,23,4],
[78,38,23,5],
[78,38,23,6],
[78,38,24,0],
[78,38,24,1],
[78,38,24,2],
[78,38,24,3],
[78,38,24,4],
[78,38,24,5],
[78,38,24,6],
[78,39,4,0],
[78,39,4,1],
[78,39,4,2],
[78,39,7,0],
[78,39,7,1],
[78,39,7,2],
[78,39,7,4],
[78,39,10,4],
[78,39,10,7],
[78,39,11,4],
[78,39,11,7],
[78,39,11,10],
[78,39,12,0],
[78,39,12,1],
[78,39,12,2],
[78,39,12,4],
[78,39,12,7],
[78,39,12,10],
[78,39,13,0],
[78,39,13,1],
[78,39,13,2],
[78,39,13,4],
[78,39,13,7],
[78,39,13,10],
[78,39,13,11],
[78,39,13,12],
[78,39,14,0],
[78,39,14,1],
[78,39,14,2],
[78,39,14,4],
[78,39,14,7],
[78,39,14,10],
[78,39,14,11],
[78,39,14,12],
[78,39,16,0],
[78,39,16,1],
[78,39,16,2],
[78,39,16,4],
[78,39,16,7],
[78,39,16,10],
[78,39,16,11],
[78,39,16,12],
[78,39,16,13],
[78,39,16,14],
[78,39,17,0],
[78,39,17,1],
[78,39,17,2],
[78,39,17,4],
[78,39,17,7],
[78,39,17,10],
[78,39,17,11],
[78,39,17,12],
[78,39,17,13],
[78,39,17,14],
[78,39,19,0],
[78,39,19,1],
[78,39,19,2],
[78,39,19,4],
[78,39,19,7],
[78,39,19,10],
[78,39,19,11],
[78,39,19,12],
[78,39,20,0],
[78,39,20,1],
[78,39,20,2],
[78,39,20,4],
[78,39,20,7],
[78,39,20,11],
[78,39,22,0],
[78,39,22,1],
[78,39,22,2],
[78,39,22,4],
[78,39,22,7],
[78,39,23,0],
[78,39,23,1],
[78,39,23,2],
[78,39,23,4],
[78,39,24,0],
[78,39,24,1],
[78,39,24,2],
[78,39,24,4],
[78,39,25,0],
[78,39,25,1],
[78,39,25,2],
[78,42,3,0],
[78,42,3,1],
[78,42,3,2],
[78,42,4,0],
[78,42,4,1],
[78,42,4,2],
[78,42,4,3],
[78,42,5,0],
[78,42,5,1],
[78,42,5,2],
[78,42,6,4],
[78,42,7,3],
[78,42,7,4],
[78,42,7,5],
[78,42,7,6],
[78,42,8,0],
[78,42,8,1],
[78,42,8,2],
[78,42,8,4],
[78,42,8,7],
[78,42,11,0],
[78,42,11,1],
[78,42,11,2],
[78,42,11,3],
[78,42,11,4],
[78,42,11,5],
[78,42,11,6],
[78,42,11,7],
[78,42,11,8],
[78,42,12,0],
[78,42,12,1],
[78,42,12,2],
[78,42,12,3],
[78,42,12,4],
[78,42,12,5],
[78,42,12,6],
[78,42,12,7],
[78,42,12,8],
[78,42,13,0],
[78,42,13,1],
[78,42,13,2],
[78,42,13,3],
[78,42,13,4],
[78,42,13,5],
[78,42,13,6],
[78,42,13,7],
[78,42,13,8],
[78,42,13,11],
[78,42,13,12],
[78,42,14,0],
[78,42,14,1],
[78,42,14,2],
[78,42,14,3],
[78,42,14,4],
[78,42,14,5],
[78,42,14,6],
[78,42,14,7],
[78,42,14,8],
[78,42,14,11],
[78,42,14,12],
[78,42,16,0],
[78,42,16,1],
[78,42,16,2],
[78,42,16,3],
[78,42,16,4],
[78,42,16,5],
[78,42,16,6],
[78,42,16,7],
[78,42,16,8],
[78,42,16,11],
[78,42,16,12],
[78,42,16,13],
[78,42,17,0],
[78,42,17,1],
[78,42,17,2],
[78,42,17,3],
[78,42,17,4],
[78,42,17,5],
[78,42,17,6],
[78,42,17,7],
[78,42,17,8],
[78,42,17,11],
[78,42,17,12],
[78,42,17,13],
[78,42,19,0],
[78,42,19,1],
[78,42,19,2],
[78,42,19,3],
[78,42,19,4],
[78,42,19,5],
[78,42,19,6],
[78,42,19,7],
[78,42,19,8],
[78,42,21,0],
[78,42,21,1],
[78,42,21,2],
[78,42,21,4],
[78,42,23,0],
[78,42,23,1],
[78,42,23,2],
[78,42,24,0],
[78,42,24,1],
[78,42,24,2],
[78,43,3,0],
[78,43,3,1],
[78,43,3,2],
[78,43,4,0],
[78,43,4,1],
[78,43,4,2],
[78,43,5,4],
[78,43,6,0],
[78,43,6,1],
[78,43,6,2],
[78,43,6,4],
[78,43,8,0],
[78,43,8,1],
[78,43,8,2],
[78,43,8,4],
[78,43,10,0],
[78,43,10,1],
[78,43,10,2],
[78,43,10,3],
[78,43,10,4],
[78,43,10,5],
[78,43,10,6],
[78,43,10,8],
[78,43,11,0],
[78,43,11,1],
[78,43,11,2],
[78,43,11,3],
[78,43,11,4],
[78,43,11,5],
[78,43,11,6],
[78,43,11,8],
[78,43,11,10],
[78,43,12,0],
[78,43,12,1],
[78,43,12,2],
[78,43,12,3],
[78,43,12,4],
[78,43,12,5],
[78,43,12,6],
[78,43,12,8],
[78,43,12,10],
[78,43,13,0],
[78,43,13,1],
[78,43,13,2],
[78,43,13,3],
[78,43,13,4],
[78,43,13,5],
[78,43,13,6],
[78,43,13,8],
[78,43,13,10],
[78,43,13,11],
[78,43,13,12],
[78,43,14,0],
[78,43,14,1],
[78,43,14,2],
[78,43,14,3],
[78,43,14,4],
[78,43,14,5],
[78,43,14,6],
[78,43,14,8],
[78,43,14,10],
[78,43,14,11],
[78,43,14,12],
[78,43,16,0],
[78,43,16,1],
[78,43,16,2],
[78,43,16,3],
[78,43,16,4],
[78,43,16,5],
[78,43,16,6],
[78,43,16,8],
[78,43,16,10],
[78,43,16,11],
[78,43,17,0],
[78,43,17,1],
[78,43,17,2],
[78,43,17,3],
[78,43,17,4],
[78,43,17,5],
[78,43,17,6],
[78,43,17,8],
[78,43,17,10],
[78,43,17,11],
[78,43,19,0],
[78,43,19,1],
[78,43,19,2],
[78,43,19,3],
[78,43,19,4],
[78,43,19,5],
[78,43,19,6],
[78,43,20,0],
[78,43,20,1],
[78,43,20,2],
[78,43,20,3],
[78,43,20,4],
[78,43,20,5],
[78,43,21,0],
[78,43,21,1],
[78,43,21,2],
[78,43,21,4],
[78,43,22,0],
[78,43,22,1],
[78,43,22,2],
[78,44,4,3],
[78,44,5,0],
[78,44,5,1],
[78,44,5,2],
[78,44,5,4],
[78,44,6,0],
[78,44,6,1],
[78,44,6,2],
[78,44,6,4],
[78,44,7,0],
[78,44,7,1],
[78,44,7,2],
[78,44,7,3],
[78,44,7,4],
[78,44,7,5],
[78,44,7,6],
[78,44,8,0],
[78,44,8,1],
[78,44,8,2],
[78,44,8,4],
[78,44,8,7],
[78,44,10,0],
[78,44,10,1],
[78,44,10,2],
[78,44,10,3],
[78,44,10,4],
[78,44,10,5],
[78,44,10,6],
[78,44,10,7],
[78,44,10,8],
[78,44,11,0],
[78,44,11,1],
[78,44,11,2],
[78,44,11,3],
[78,44,11,4],
[78,44,11,5],
[78,44,11,6],
[78,44,11,7],
[78,44,11,8],
[78,44,11,10],
[78,44,12,0],
[78,44,12,1],
[78,44,12,2],
[78,44,12,3],
[78,44,12,4],
[78,44,12,5],
[78,44,12,6],
[78,44,12,7],
[78,44,12,8],
[78,44,12,10],
[78,44,13,0],
[78,44,13,1],
[78,44,13,2],
[78,44,13,3],
[78,44,13,4],
[78,44,13,5],
[78,44,13,6],
[78,44,13,7],
[78,44,13,8],
[78,44,13,10],
[78,44,13,11],
[78,44,13,12],
[78,44,14,0],
[78,44,14,1],
[78,44,14,2],
[78,44,14,3],
[78,44,14,4],
[78,44,14,5],
[78,44,14,6],
[78,44,14,7],
[78,44,14,8],
[78,44,14,10],
[78,44,14,11],
[78,44,14,12],
[78,44,16,0],
[78,44,16,1],
[78,44,16,2],
[78,44,16,3],
[78,44,16,4],
[78,44,16,5],
[78,44,16,6],
[78,44,16,7],
[78,44,16,8],
[78,44,19,0],
[78,44,19,1],
[78,44,19,2],
[78,44,19,3],
[78,44,19,4],
[78,44,19,5],
[78,44,20,0],
[78,44,20,1],
[78,44,20,2],
[78,44,20,3],
[78,44,20,4],
[78,44,21,0],
[78,44,21,1],
[78,44,21,2],
[78,47,3,0],
[78,47,3,1],
[78,47,3,2],
[78,47,4,0],
[78,47,4,1],
[78,47,4,2],
[78,47,4,3],
[78,47,5,0],
[78,47,5,1],
[78,47,5,2],
[78,47,5,4],
[78,47,6,0],
[78,47,6,1],
[78,47,6,2],
[78,47,6,4],
[78,47,7,0],
[78,47,7,1],
[78,47,7,2],
[78,47,7,3],
[78,47,7,4],
[78,47,7,5],
[78,47,7,6],
[78,47,8,0],
[78,47,8,1],
[78,47,8,2],
[78,47,8,4],
[78,47,8,7],
[78,47,10,0],
[78,47,10,1],
[78,47,10,2],
[78,47,10,3],
[78,47,10,4],
[78,47,10,5],
[78,47,10,6],
[78,47,10,7],
[78,47,10,8],
[78,47,11,0],
[78,47,11,1],
[78,47,11,2],
[78,47,11,3],
[78,47,11,4],
[78,47,11,5],
[78,47,11,6],
[78,47,11,7],
[78,47,11,8],
[78,47,11,10],
[78,47,12,0],
[78,47,12,1],
[78,47,12,2],
[78,47,12,3],
[78,47,12,4],
[78,47,12,5],
[78,47,12,6],
[78,47,12,7],
[78,47,12,8],
[78,47,13,0],
[78,47,13,1],
[78,47,13,2],
[78,47,13,3],
[78,47,13,4],
[78,47,13,5],
[78,47,13,6],
[78,47,13,7],
[78,47,13,8],
[78,47,14,0],
[78,47,14,1],
[78,47,14,2],
[78,47,14,3],
[78,47,14,4],
[78,47,14,5],
[78,47,14,6],
[78,47,14,7],
[78,47,16,0],
[78,47,16,1],
[78,47,16,2],
[78,47,16,3],
[78,47,16,4],
[78,47,17,0],
[78,47,17,1],
[78,47,17,2],
[78,47,17,3],
[78,47,17,4],
[78,48,3,0],
[78,48,3,1],
[78,48,3,2],
[78,48,4,0],
[78,48,4,1],
[78,48,4,2],
[78,48,4,3],
[78,48,5,0],
[78,48,5,1],
[78,48,5,2],
[78,48,5,4],
[78,48,6,0],
[78,48,6,1],
[78,48,6,2],
[78,48,6,4],
[78,48,7,0],
[78,48,7,1],
[78,48,7,2],
[78,48,7,3],
[78,48,7,4],
[78,48,7,5],
[78,48,7,6],
[78,48,8,0],
[78,48,8,1],
[78,48,8,2],
[78,48,8,4],
[78,48,8,7],
[78,48,10,0],
[78,48,10,1],
[78,48,10,2],
[78,48,10,3],
[78,48,10,4],
[78,48,10,5],
[78,48,10,6],
[78,48,10,7],
[78,48,10,8],
[78,48,13,0],
[78,48,13,1],
[78,48,13,2],
[78,48,13,3],
[78,48,13,4],
[78,48,13,5],
[78,48,13,6],
[78,48,13,7],
[78,48,14,0],
[78,48,14,1],
[78,48,14,2],
[78,48,14,3],
[78,48,14,4],
[78,48,14,5],
[78,48,16,0],
[78,48,16,1],
[78,48,16,2],
[78,48,17,0],
[78,48,17,1],
[78,48,17,2],
[78,51,4,0],
[78,51,4,1],
[78,51,4,2],
[78,51,7,0],
[78,51,7,1],
[78,51,7,2],
[78,51,7,4],
[78,51,10,0],
[78,51,10,1],
[78,51,10,2],
[78,51,10,4],
[78,51,10,7],
[78,51,11,0],
[78,51,11,1],
[78,51,11,2],
[78,51,11,4],
[78,51,11,7],
[78,51,12,0],
[78,51,12,1],
[78,51,12,2],
[78,51,12,4],
[78,51,12,7],
[78,51,13,0],
[78,51,13,1],
[78,51,13,2],
[78,51,13,4],
[78,51,13,7],
[78,51,14,0],
[78,51,14,1],
[78,51,14,2],
[78,51,14,4],
[78,51,16,0],
[78,51,16,1],
[78,51,16,2],
[78,51,17,0],
[78,51,17,1],
[78,51,17,2],
[78,53,3,0],
[78,53,3,1],
[78,53,3,2],
[78,53,4,0],
[78,53,4,1],
[78,53,4,2],
[78,53,4,3],
[78,53,5,0],
[78,53,5,1],
[78,53,5,2],
[78,53,5,4],
[78,53,6,0],
[78,53,6,1],
[78,53,6,2],
[78,53,6,4],
[78,53,7,0],
[78,53,7,1],
[78,53,7,2],
[78,53,7,3],
[78,53,7,4],
[78,53,7,5],
[78,53,7,6],
[78,53,8,0],
[78,53,8,1],
[78,53,8,2],
[78,53,8,4],
[78,53,8,7],
[78,53,11,0],
[78,53,11,1],
[78,53,11,2],
[78,53,11,3],
[78,53,11,4],
[78,53,11,5],
[78,53,11,6],
[78,53,11,7],
[78,53,11,8],
[78,53,12,0],
[78,53,12,1],
[78,53,12,2],
[78,53,12,3],
[78,53,12,4],
[78,53,12,5],
[78,53,12,6],
[78,53,12,7],
[78,53,13,0],
[78,53,13,1],
[78,53,13,2],
[78,53,13,3],
[78,53,13,4],
[78,53,13,5],
[78,53,13,6],
[78,53,13,7],
[78,53,14,0],
[78,53,14,1],
[78,53,14,2],
[78,53,14,3],
[78,53,14,4],
[78,53,14,5],
[78,53,16,0],
[78,53,16,1],
[78,53,16,2],
[78,53,17,0],
[78,53,17,1],
[78,53,17,2],
[78,54,3,0],
[78,54,3,1],
[78,54,3,2],
[78,54,4,0],
[78,54,4,1],
[78,54,4,2],
[78,54,4,3],
[78,54,5,0],
[78,54,5,1],
[78,54,5,2],
[78,54,5,4],
[78,54,6,0],
[78,54,6,1],
[78,54,6,2],
[78,54,6,4],
[78,54,7,0],
[78,54,7,1],
[78,54,7,2],
[78,54,7,3],
[78,54,7,4],
[78,54,7,5],
[78,54,7,6],
[78,54,8,0],
[78,54,8,1],
[78,54,8,2],
[78,54,8,4],
[78,54,8,7],
[78,54,11,0],
[78,54,11,1],
[78,54,11,2],
[78,54,11,3],
[78,54,11,4],
[78,54,11,5],
[78,54,11,6],
[78,54,11,7],
[78,54,11,8],
[78,54,12,0],
[78,54,12,1],
[78,54,12,2],
[78,54,12,3],
[78,54,12,4],
[78,54,12,5],
[78,54,12,6],
[78,54,12,7],
[78,54,13,0],
[78,54,13,1],
[78,54,13,2],
[78,54,13,3],
[78,54,13,4],
[78,54,13,5],
[78,54,13,6],
[78,54,13,7],
[78,54,14,0],
[78,54,14,1],
[78,54,14,2],
[78,54,14,3],
[78,54,14,4],
[78,54,14,5],
[78,54,16,0],
[78,54,16,1],
[78,54,16,2],
[78,54,17,0],
[78,54,17,1],
[78,54,17,2],
[78,55,3,0],
[78,55,3,1],
[78,55,3,2],
[78,55,4,0],
[78,55,4,1],
[78,55,4,2],
[78,55,4,3],
[78,55,5,0],
[78,55,5,1],
[78,55,5,2],
[78,55,5,4],
[78,55,6,0],
[78,55,6,1],
[78,55,6,2],
[78,55,6,4],
[78,55,7,0],
[78,55,7,1],
[78,55,7,2],
[78,55,7,3],
[78,55,7,4],
[78,55,7,5],
[78,55,7,6],
[78,55,8,0],
[78,55,8,1],
[78,55,8,2],
[78,55,8,4],
[78,55,8,7],
[78,55,11,0],
[78,55,11,1],
[78,55,11,2],
[78,55,11,3],
[78,55,11,4],
[78,55,11,5],
[78,55,11,6],
[78,55,11,7],
[78,55,11,8],
[78,55,12,0],
[78,55,12,1],
[78,55,12,2],
[78,55,12,3],
[78,55,12,4],
[78,55,12,5],
[78,55,12,6],
[78,55,12,7],
[78,55,13,0],
[78,55,13,1],
[78,55,13,2],
[78,55,13,3],
[78,55,13,4],
[78,55,13,5],
[78,55,13,6],
[78,55,13,7],
[78,55,14,0],
[78,55,14,1],
[78,55,14,2],
[78,55,14,3],
[78,55,14,4],
[78,55,14,5],
[78,55,16,0],
[78,55,16,1],
[78,55,16,2],
[78,55,17,0],
[78,55,17,1],
[78,55,17,2],
[78,57,3,0],
[78,57,3,1],
[78,57,3,2],
[78,57,4,0],
[78,57,4,1],
[78,57,4,2],
[78,57,4,3],
[78,57,5,0],
[78,57,5,1],
[78,57,5,2],
[78,57,5,4],
[78,57,6,0],
[78,57,6,1],
[78,57,6,2],
[78,57,6,4],
[78,57,7,0],
[78,57,7,1],
[78,57,7,2],
[78,57,7,3],
[78,57,7,4],
[78,57,7,5],
[78,57,7,6],
[78,57,8,0],
[78,57,8,1],
[78,57,8,2],
[78,57,8,4],
[78,57,8,7],
[78,57,10,0],
[78,57,10,1],
[78,57,10,2],
[78,57,10,3],
[78,57,10,4],
[78,57,10,5],
[78,57,10,6],
[78,57,10,7],
[78,57,11,0],
[78,57,11,1],
[78,57,11,2],
[78,57,11,3],
[78,57,11,4],
[78,57,11,5],
[78,57,11,6],
[78,57,11,7],
[78,57,12,0],
[78,57,12,1],
[78,57,12,2],
[78,57,12,3],
[78,57,12,4],
[78,57,12,5],
[78,57,13,0],
[78,57,13,1],
[78,57,13,2],
[78,57,13,3],
[78,57,13,4],
[78,57,13,5],
[78,57,14,0],
[78,57,14,1],
[78,57,14,2],
[78,57,14,3],
[78,57,14,4],
[78,58,3,0],
[78,58,3,1],
[78,58,3,2],
[78,58,4,0],
[78,58,4,1],
[78,58,4,2],
[78,58,4,3],
[78,58,5,0],
[78,58,5,1],
[78,58,5,2],
[78,58,5,4],
[78,58,6,0],
[78,58,6,1],
[78,58,6,2],
[78,58,6,4],
[78,58,7,0],
[78,58,7,1],
[78,58,7,2],
[78,58,7,3],
[78,58,7,4],
[78,58,7,5],
[78,58,7,6],
[78,58,8,0],
[78,58,8,1],
[78,58,8,2],
[78,58,8,4],
[78,58,8,7],
[78,58,10,0],
[78,58,10,1],
[78,58,10,2],
[78,58,10,3],
[78,58,10,4],
[78,58,10,5],
[78,58,10,6],
[78,58,10,7],
[78,58,11,0],
[78,58,11,1],
[78,58,11,2],
[78,58,11,3],
[78,58,11,4],
[78,58,11,5],
[78,58,11,6],
[78,58,11,7],
[78,58,12,0],
[78,58,12,1],
[78,58,12,2],
[78,58,12,3],
[78,58,12,4],
[78,58,12,5],
[78,58,13,0],
[78,58,13,1],
[78,58,13,2],
[78,58,13,3],
[78,58,13,4],
[78,58,13,5],
[78,58,14,0],
[78,58,14,1],
[78,58,14,2],
[78,58,14,3],
[78,58,14,4],
[78,60,3,0],
[78,60,3,1],
[78,60,3,2],
[78,60,4,0],
[78,60,4,1],
[78,60,4,2],
[78,60,4,3],
[78,60,5,0],
[78,60,5,1],
[78,60,5,2],
[78,60,5,4],
[78,60,6,0],
[78,60,6,1],
[78,60,6,2],
[78,60,6,4],
[78,60,7,0],
[78,60,7,1],
[78,60,7,2],
[78,60,7,3],
[78,60,7,4],
[78,60,7,5],
[78,60,7,6],
[78,60,8,0],
[78,60,8,1],
[78,60,8,2],
[78,60,8,4],
[78,60,10,0],
[78,60,10,1],
[78,60,10,2],
[78,60,10,3],
[78,60,10,4],
[78,60,11,0],
[78,60,11,1],
[78,60,11,2],
[78,60,11,3],
[78,60,11,4],
[78,60,12,0],
[78,60,12,1],
[78,60,12,2],
[78,61,3,0],
[78,61,3,1],
[78,61,3,2],
[78,61,5,0],
[78,61,5,1],
[78,61,5,2],
[78,61,6,0],
[78,61,6,1],
[78,61,6,2],
[78,61,7,0],
[78,61,7,1],
[78,61,7,2],
[78,61,7,3],
[78,61,7,5],
[78,61,8,0],
[78,61,8,1],
[78,61,8,2],
[78,61,10,0],
[78,61,10,1],
[78,61,10,2],
[78,61,11,0],
[78,61,11,1],
[78,61,11,2],
[78,62,3,0],
[78,62,3,1],
[78,62,3,2],
[78,62,4,0],
[78,62,4,1],
[78,62,4,2],
[78,62,4,3],
[78,62,5,0],
[78,62,5,1],
[78,62,5,2],
[78,62,5,4],
[78,62,6,0],
[78,62,6,1],
[78,62,6,2],
[78,62,6,4],
[78,62,8,0],
[78,62,8,1],
[78,62,8,2],
[78,62,8,4],
[78,62,10,0],
[78,62,10,1],
[78,62,10,2],
[78,62,11,0],
[78,62,11,1],
[78,62,11,2],
[78,64,3,0],
[78,64,3,1],
[78,64,3,2],
[78,64,5,0],
[78,64,5,1],
[78,64,5,2],
[78,64,6,0],
[78,64,6,1],
[78,64,6,2],
[78,64,7,0],
[78,64,7,1],
[78,64,7,2],
[78,65,3,0],
[78,65,3,1],
[78,65,3,2],
[78,65,4,0],
[78,65,4,1],
[78,65,4,2],
[78,65,4,3],
[78,65,5,0],
[78,65,5,1],
[78,65,5,2],
[78,66,3,0],
[78,66,3,1],
[78,66,3,2],
[78,66,4,0],
[78,66,4,1],
[78,66,4,2],
[79,4,3,0],
[79,4,3,1],
[79,4,3,2],
[79,5,4,0],
[79,5,4,1],
[79,5,4,2],
[79,6,4,0],
[79,6,4,1],
[79,6,4,2],
[79,7,3,0],
[79,7,3,1],
[79,7,3,2],
[79,7,4,0],
[79,7,4,1],
[79,7,4,2],
[79,7,4,3],
[79,7,5,0],
[79,7,5,1],
[79,7,5,2],
[79,7,5,4],
[79,7,6,0],
[79,7,6,1],
[79,7,6,2],
[79,7,6,4],
[79,8,4,0],
[79,8,4,1],
[79,8,4,2],
[79,8,7,0],
[79,8,7,1],
[79,8,7,2],
[79,8,7,4],
[79,10,3,0],
[79,10,3,1],
[79,10,3,2],
[79,10,4,0],
[79,10,4,1],
[79,10,4,2],
[79,10,4,3],
[79,10,5,0],
[79,10,5,1],
[79,10,5,2],
[79,10,5,4],
[79,10,6,0],
[79,10,6,1],
[79,10,6,2],
[79,10,6,4],
[79,10,7,0],
[79,10,7,1],
[79,10,7,2],
[79,10,7,3],
[79,10,7,4],
[79,10,7,5],
[79,10,7,6],
[79,10,8,0],
[79,10,8,1],
[79,10,8,2],
[79,10,8,4],
[79,10,8,7],
[79,11,3,0],
[79,11,3,1],
[79,11,3,2],
[79,11,4,0],
[79,11,4,1],
[79,11,4,2],
[79,11,4,3],
[79,11,5,0],
[79,11,5,1],
[79,11,5,2],
[79,11,5,4],
[79,11,6,0],
[79,11,6,1],
[79,11,6,2],
[79,11,6,4],
[79,11,7,0],
[79,11,7,1],
[79,11,7,2],
[79,11,7,3],
[79,11,7,4],
[79,11,7,5],
[79,11,7,6],
[79,11,8,0],
[79,11,8,1],
[79,11,8,2],
[79,11,8,4],
[79,11,8,7],
[79,11,10,0],
[79,11,10,1],
[79,11,10,2],
[79,11,10,3],
[79,11,10,4],
[79,11,10,5],
[79,11,10,6],
[79,11,10,7],
[79,11,10,8],
[79,12,3,0],
[79,12,3,1],
[79,12,3,2],
[79,12,4,0],
[79,12,4,1],
[79,12,4,2],
[79,12,4,3],
[79,12,5,0],
[79,12,5,1],
[79,12,5,2],
[79,12,5,4],
[79,12,6,0],
[79,12,6,1],
[79,12,6,2],
[79,12,6,4],
[79,12,7,0],
[79,12,7,1],
[79,12,7,2],
[79,12,7,3],
[79,12,7,4],
[79,12,7,5],
[79,12,7,6],
[79,12,8,0],
[79,12,8,1],
[79,12,8,2],
[79,12,8,4],
[79,12,8,7],
[79,12,10,0],
[79,12,10,1],
[79,12,10,2],
[79,12,10,3],
[79,12,10,4],
[79,12,10,5],
[79,12,10,6],
[79,12,10,7],
[79,12,10,8],
[79,13,3,0],
[79,13,3,1],
[79,13,3,2],
[79,13,4,0],
[79,13,4,1],
[79,13,4,2],
[79,13,4,3],
[79,13,5,0],
[79,13,5,1],
[79,13,5,2],
[79,13,5,4],
[79,13,6,0],
[79,13,6,1],
[79,13,6,2],
[79,13,6,4],
[79,13,7,0],
[79,13,7,1],
[79,13,7,2],
[79,13,7,3],
[79,13,7,4],
[79,13,7,5],
[79,13,7,6],
[79,13,8,0],
[79,13,8,1],
[79,13,8,2],
[79,13,8,4],
[79,13,8,7],
[79,13,10,0],
[79,13,10,1],
[79,13,10,2],
[79,13,10,3],
[79,13,10,4],
[79,13,10,5],
[79,13,10,6],
[79,13,10,7],
[79,13,10,8],
[79,13,11,0],
[79,13,11,1],
[79,13,11,2],
[79,13,11,3],
[79,13,11,4],
[79,13,11,5],
[79,13,11,6],
[79,13,11,7],
[79,13,11,8],
[79,13,11,10],
[79,13,12,0],
[79,13,12,1],
[79,13,12,2],
[79,13,12,3],
[79,13,12,4],
[79,13,12,5],
[79,13,12,6],
[79,13,12,7],
[79,13,12,8],
[79,13,12,10],
[79,14,3,0],
[79,14,3,1],
[79,14,3,2],
[79,14,4,0],
[79,14,4,1],
[79,14,4,2],
[79,14,4,3],
[79,14,5,0],
[79,14,5,1],
[79,14,5,2],
[79,14,5,4],
[79,14,6,0],
[79,14,6,1],
[79,14,6,2],
[79,14,6,4],
[79,14,7,0],
[79,14,7,1],
[79,14,7,2],
[79,14,7,3],
[79,14,7,4],
[79,14,7,5],
[79,14,7,6],
[79,14,8,0],
[79,14,8,1],
[79,14,8,2],
[79,14,8,4],
[79,14,8,7],
[79,14,10,0],
[79,14,10,1],
[79,14,10,2],
[79,14,10,3],
[79,14,10,4],
[79,14,10,5],
[79,14,10,6],
[79,14,10,7],
[79,14,10,8],
[79,14,11,0],
[79,14,11,1],
[79,14,11,2],
[79,14,11,3],
[79,14,11,4],
[79,14,11,5],
[79,14,11,6],
[79,14,11,7],
[79,14,11,8],
[79,14,11,10],
[79,14,12,0],
[79,14,12,1],
[79,14,12,2],
[79,14,12,3],
[79,14,12,4],
[79,14,12,5],
[79,14,12,6],
[79,14,12,7],
[79,14,12,8],
[79,14,12,10],
[79,16,3,0],
[79,16,3,1],
[79,16,3,2],
[79,16,4,0],
[79,16,4,1],
[79,16,4,2],
[79,16,4,3],
[79,16,5,0],
[79,16,5,1],
[79,16,5,2],
[79,16,5,4],
[79,16,6,0],
[79,16,6,1],
[79,16,6,2],
[79,16,6,4],
[79,16,7,0],
[79,16,7,1],
[79,16,7,2],
[79,16,7,3],
[79,16,7,4],
[79,16,7,5],
[79,16,7,6],
[79,16,8,0],
[79,16,8,1],
[79,16,8,2],
[79,16,8,4],
[79,16,8,7],
[79,16,10,0],
[79,16,10,1],
[79,16,10,2],
[79,16,10,3],
[79,16,10,4],
[79,16,10,5],
[79,16,10,6],
[79,16,10,7],
[79,16,10,8],
[79,16,11,0],
[79,16,11,1],
[79,16,11,2],
[79,16,11,3],
[79,16,11,4],
[79,16,11,5],
[79,16,11,6],
[79,16,11,7],
[79,16,11,8],
[79,16,11,10],
[79,16,12,0],
[79,16,12,1],
[79,16,12,2],
[79,16,12,3],
[79,16,12,4],
[79,16,12,5],
[79,16,12,6],
[79,16,12,7],
[79,16,12,8],
[79,16,12,10],
[79,16,13,0],
[79,16,13,1],
[79,16,13,2],
[79,16,13,3],
[79,16,13,4],
[79,16,13,5],
[79,16,13,6],
[79,16,13,7],
[79,16,13,8],
[79,16,13,10],
[79,16,13,11],
[79,16,13,12],
[79,16,14,0],
[79,16,14,1],
[79,16,14,2],
[79,16,14,3],
[79,16,14,4],
[79,16,14,5],
[79,16,14,6],
[79,16,14,7],
[79,16,14,8],
[79,16,14,10],
[79,16,14,11],
[79,16,14,12],
[79,17,3,0],
[79,17,3,1],
[79,17,3,2],
[79,17,4,0],
[79,17,4,1],
[79,17,4,2],
[79,17,4,3],
[79,17,5,0],
[79,17,5,1],
[79,17,5,2],
[79,17,5,4],
[79,17,6,0],
[79,17,6,1],
[79,17,6,2],
[79,17,6,4],
[79,17,7,0],
[79,17,7,1],
[79,17,7,2],
[79,17,7,3],
[79,17,7,4],
[79,17,7,5],
[79,17,7,6],
[79,17,8,0],
[79,17,8,1],
[79,17,8,2],
[79,17,8,4],
[79,17,8,7],
[79,17,10,0],
[79,17,10,1],
[79,17,10,2],
[79,17,10,3],
[79,17,10,4],
[79,17,10,5],
[79,17,10,6],
[79,17,10,7],
[79,17,10,8],
[79,17,11,0],
[79,17,11,1],
[79,17,11,2],
[79,17,11,3],
[79,17,11,4],
[79,17,11,5],
[79,17,11,6],
[79,17,11,7],
[79,17,11,8],
[79,17,11,10],
[79,17,12,0],
[79,17,12,1],
[79,17,12,2],
[79,17,12,3],
[79,17,12,4],
[79,17,12,5],
[79,17,12,6],
[79,17,12,7],
[79,17,12,8],
[79,17,12,10],
[79,17,13,0],
[79,17,13,1],
[79,17,13,2],
[79,17,13,3],
[79,17,13,4],
[79,17,13,5],
[79,17,13,6],
[79,17,13,7],
[79,17,13,8],
[79,17,13,10],
[79,17,13,11],
[79,17,13,12],
[79,17,14,0],
[79,17,14,1],
[79,17,14,2],
[79,17,14,3],
[79,17,14,4],
[79,17,14,5],
[79,17,14,6],
[79,17,14,7],
[79,17,14,8],
[79,17,14,10],
[79,17,14,11],
[79,17,14,12],
[79,17,16,0],
[79,17,16,1],
[79,17,16,2],
[79,17,16,3],
[79,17,16,4],
[79,17,16,5],
[79,17,16,6],
[79,17,16,7],
[79,17,16,8],
[79,17,16,10],
[79,17,16,11],
[79,17,16,12],
[79,17,16,13],
[79,17,16,14],
[79,19,3,0],
[79,19,3,1],
[79,19,3,2],
[79,19,4,0],
[79,19,4,1],
[79,19,4,2],
[79,19,4,3],
[79,19,5,0],
[79,19,5,1],
[79,19,5,2],
[79,19,5,4],
[79,19,6,0],
[79,19,6,1],
[79,19,6,2],
[79,19,6,4],
[79,19,7,0],
[79,19,7,1],
[79,19,7,2],
[79,19,7,3],
[79,19,7,4],
[79,19,7,5],
[79,19,7,6],
[79,19,8,0],
[79,19,8,1],
[79,19,8,2],
[79,19,8,4],
[79,19,8,7],
[79,19,10,0],
[79,19,10,1],
[79,19,10,2],
[79,19,10,3],
[79,19,10,4],
[79,19,10,5],
[79,19,10,6],
[79,19,10,7],
[79,19,10,8],
[79,19,11,0],
[79,19,11,1],
[79,19,11,2],
[79,19,11,3],
[79,19,11,4],
[79,19,11,5],
[79,19,11,6],
[79,19,11,7],
[79,19,11,8],
[79,19,11,10],
[79,19,12,0],
[79,19,12,1],
[79,19,12,2],
[79,19,12,3],
[79,19,12,4],
[79,19,12,5],
[79,19,12,6],
[79,19,12,7],
[79,19,12,8],
[79,19,12,10],
[79,19,16,0],
[79,19,16,1],
[79,19,16,2],
[79,19,16,3],
[79,19,16,4],
[79,19,16,5],
[79,19,16,6],
[79,19,16,7],
[79,19,16,8],
[79,19,16,10],
[79,19,16,11],
[79,19,16,12],
[79,19,17,0],
[79,19,17,1],
[79,19,17,2],
[79,19,17,3],
[79,19,17,4],
[79,19,17,5],
[79,19,17,6],
[79,19,17,7],
[79,19,17,8],
[79,19,17,10],
[79,19,17,11],
[79,19,17,12],
[79,19,17,16],
[79,20,3,0],
[79,20,3,1],
[79,20,3,2],
[79,20,4,0],
[79,20,4,1],
[79,20,4,2],
[79,20,4,3],
[79,20,5,0],
[79,20,5,1],
[79,20,5,2],
[79,20,5,4],
[79,20,6,0],
[79,20,6,1],
[79,20,6,2],
[79,20,6,4],
[79,20,7,0],
[79,20,7,1],
[79,20,7,2],
[79,20,7,3],
[79,20,7,4],
[79,20,7,5],
[79,20,7,6],
[79,20,8,0],
[79,20,8,1],
[79,20,8,2],
[79,20,8,4],
[79,20,8,7],
[79,20,11,0],
[79,20,11,1],
[79,20,11,2],
[79,20,11,3],
[79,20,11,4],
[79,20,11,5],
[79,20,11,6],
[79,20,11,7],
[79,20,11,8],
[79,20,12,0],
[79,20,12,1],
[79,20,12,2],
[79,20,12,3],
[79,20,12,4],
[79,20,12,5],
[79,20,12,6],
[79,20,12,7],
[79,20,12,8],
[79,20,13,0],
[79,20,13,1],
[79,20,13,2],
[79,20,13,3],
[79,20,13,4],
[79,20,13,5],
[79,20,13,6],
[79,20,13,7],
[79,20,13,8],
[79,20,13,11],
[79,20,13,12],
[79,20,14,0],
[79,20,14,1],
[79,20,14,2],
[79,20,14,3],
[79,20,14,4],
[79,20,14,5],
[79,20,14,6],
[79,20,14,7],
[79,20,14,8],
[79,20,14,11],
[79,20,14,12],
[79,20,16,0],
[79,20,16,1],
[79,20,16,2],
[79,20,16,3],
[79,20,16,4],
[79,20,16,5],
[79,20,16,6],
[79,20,16,7],
[79,20,16,8],
[79,20,16,11],
[79,20,16,12],
[79,20,16,13],
[79,20,16,14],
[79,20,17,0],
[79,20,17,1],
[79,20,17,2],
[79,20,17,3],
[79,20,17,4],
[79,20,17,5],
[79,20,17,6],
[79,20,17,7],
[79,20,17,8],
[79,20,17,11],
[79,20,17,12],
[79,20,17,13],
[79,20,17,14],
[79,20,17,16],
[79,20,19,0],
[79,20,19,1],
[79,20,19,2],
[79,20,19,3],
[79,20,19,4],
[79,20,19,5],
[79,20,19,6],
[79,20,19,7],
[79,20,19,8],
[79,20,19,11],
[79,20,19,12],
[79,20,19,16],
[79,20,19,17],
[79,21,4,0],
[79,21,4,1],
[79,21,4,2],
[79,21,7,0],
[79,21,7,1],
[79,21,7,2],
[79,21,7,4],
[79,21,10,0],
[79,21,10,1],
[79,21,10,2],
[79,21,10,4],
[79,21,10,7],
[79,21,11,0],
[79,21,11,1],
[79,21,11,2],
[79,21,11,4],
[79,21,11,7],
[79,21,11,10],
[79,21,12,0],
[79,21,12,1],
[79,21,12,2],
[79,21,12,4],
[79,21,12,7],
[79,21,12,10],
[79,21,13,0],
[79,21,13,1],
[79,21,13,2],
[79,21,13,4],
[79,21,13,7],
[79,21,13,10],
[79,21,13,11],
[79,21,13,12],
[79,21,14,0],
[79,21,14,1],
[79,21,14,2],
[79,21,14,4],
[79,21,14,7],
[79,21,14,10],
[79,21,14,11],
[79,21,14,12],
[79,21,16,0],
[79,21,16,1],
[79,21,16,2],
[79,21,16,4],
[79,21,16,7],
[79,21,16,10],
[79,21,16,11],
[79,21,16,12],
[79,21,16,13],
[79,21,16,14],
[79,21,17,0],
[79,21,17,1],
[79,21,17,2],
[79,21,17,4],
[79,21,17,7],
[79,21,17,10],
[79,21,17,11],
[79,21,17,12],
[79,21,17,13],
[79,21,17,14],
[79,21,17,16],
[79,21,19,0],
[79,21,19,1],
[79,21,19,2],
[79,21,19,4],
[79,21,19,7],
[79,21,19,10],
[79,21,19,11],
[79,21,19,12],
[79,21,19,16],
[79,21,19,17],
[79,21,20,0],
[79,21,20,1],
[79,21,20,2],
[79,21,20,4],
[79,21,20,7],
[79,21,20,11],
[79,21,20,12],
[79,21,20,13],
[79,21,20,14],
[79,21,20,16],
[79,21,20,17],
[79,21,20,19],
[79,22,3,0],
[79,22,3,1],
[79,22,3,2],
[79,22,4,0],
[79,22,4,1],
[79,22,4,2],
[79,22,4,3],
[79,22,5,0],
[79,22,5,1],
[79,22,5,2],
[79,22,5,4],
[79,22,6,0],
[79,22,6,1],
[79,22,6,2],
[79,22,6,4],
[79,22,7,0],
[79,22,7,1],
[79,22,7,2],
[79,22,7,3],
[79,22,7,4],
[79,22,7,5],
[79,22,7,6],
[79,22,8,0],
[79,22,8,1],
[79,22,8,2],
[79,22,8,4],
[79,22,8,7],
[79,22,11,0],
[79,22,11,1],
[79,22,11,2],
[79,22,11,3],
[79,22,11,4],
[79,22,11,5],
[79,22,11,6],
[79,22,11,7],
[79,22,11,8],
[79,22,12,0],
[79,22,12,1],
[79,22,12,2],
[79,22,12,3],
[79,22,12,4],
[79,22,12,5],
[79,22,12,6],
[79,22,12,7],
[79,22,12,8],
[79,22,13,0],
[79,22,13,1],
[79,22,13,2],
[79,22,13,3],
[79,22,13,4],
[79,22,13,5],
[79,22,13,6],
[79,22,13,7],
[79,22,13,8],
[79,22,13,11],
[79,22,13,12],
[79,22,14,0],
[79,22,14,1],
[79,22,14,2],
[79,22,14,3],
[79,22,14,4],
[79,22,14,5],
[79,22,14,6],
[79,22,14,7],
[79,22,14,8],
[79,22,14,11],
[79,22,14,12],
[79,22,16,0],
[79,22,16,1],
[79,22,16,2],
[79,22,16,3],
[79,22,16,4],
[79,22,16,5],
[79,22,16,6],
[79,22,16,7],
[79,22,16,8],
[79,22,16,11],
[79,22,16,12],
[79,22,16,13],
[79,22,16,14],
[79,22,17,0],
[79,22,17,1],
[79,22,17,2],
[79,22,17,3],
[79,22,17,4],
[79,22,17,5],
[79,22,17,6],
[79,22,17,7],
[79,22,17,8],
[79,22,17,11],
[79,22,17,12],
[79,22,17,13],
[79,22,17,14],
[79,22,17,16],
[79,22,19,0],
[79,22,19,1],
[79,22,19,2],
[79,22,19,3],
[79,22,19,4],
[79,22,19,5],
[79,22,19,6],
[79,22,19,7],
[79,22,19,8],
[79,22,19,11],
[79,22,19,12],
[79,22,19,16],
[79,22,19,17],
[79,22,21,0],
[79,22,21,1],
[79,22,21,2],
[79,22,21,4],
[79,22,21,7],
[79,22,21,11],
[79,22,21,12],
[79,22,21,13],
[79,22,21,14],
[79,22,21,16],
[79,22,21,17],
[79,22,21,19],
[79,23,3,0],
[79,23,3,1],
[79,23,3,2],
[79,23,4,0],
[79,23,4,1],
[79,23,4,2],
[79,23,4,3],
[79,23,5,0],
[79,23,5,1],
[79,23,5,2],
[79,23,5,4],
[79,23,6,0],
[79,23,6,1],
[79,23,6,2],
[79,23,6,4],
[79,23,7,0],
[79,23,7,1],
[79,23,7,2],
[79,23,7,3],
[79,23,7,4],
[79,23,7,5],
[79,23,7,6],
[79,23,8,0],
[79,23,8,1],
[79,23,8,2],
[79,23,8,4],
[79,23,8,7],
[79,23,10,0],
[79,23,10,1],
[79,23,10,2],
[79,23,10,3],
[79,23,10,4],
[79,23,10,5],
[79,23,10,6],
[79,23,10,7],
[79,23,10,8],
[79,23,11,0],
[79,23,11,1],
[79,23,11,2],
[79,23,11,3],
[79,23,11,4],
[79,23,11,5],
[79,23,11,6],
[79,23,11,7],
[79,23,11,8],
[79,23,11,10],
[79,23,12,0],
[79,23,12,1],
[79,23,12,2],
[79,23,12,3],
[79,23,12,4],
[79,23,12,5],
[79,23,12,6],
[79,23,12,7],
[79,23,12,8],
[79,23,12,10],
[79,23,13,0],
[79,23,13,1],
[79,23,13,2],
[79,23,13,3],
[79,23,13,4],
[79,23,13,5],
[79,23,13,6],
[79,23,13,7],
[79,23,13,8],
[79,23,13,10],
[79,23,13,11],
[79,23,13,12],
[79,23,14,0],
[79,23,14,1],
[79,23,14,2],
[79,23,14,3],
[79,23,14,4],
[79,23,14,5],
[79,23,14,6],
[79,23,14,7],
[79,23,14,8],
[79,23,14,10],
[79,23,14,11],
[79,23,14,12],
[79,23,16,0],
[79,23,16,1],
[79,23,16,2],
[79,23,16,3],
[79,23,16,4],
[79,23,16,5],
[79,23,16,6],
[79,23,16,7],
[79,23,16,8],
[79,23,16,10],
[79,23,16,11],
[79,23,16,12],
[79,23,16,13],
[79,23,16,14],
[79,23,17,0],
[79,23,17,1],
[79,23,17,2],
[79,23,17,3],
[79,23,17,4],
[79,23,17,5],
[79,23,17,6],
[79,23,17,7],
[79,23,17,8],
[79,23,17,10],
[79,23,17,11],
[79,23,17,12],
[79,23,17,13],
[79,23,17,14],
[79,23,17,16],
[79,23,19,0],
[79,23,19,1],
[79,23,19,2],
[79,23,19,3],
[79,23,19,4],
[79,23,19,5],
[79,23,19,6],
[79,23,19,7],
[79,23,19,8],
[79,23,19,10],
[79,23,19,11],
[79,23,19,12],
[79,23,20,0],
[79,23,20,1],
[79,23,20,2],
[79,23,20,3],
[79,23,20,4],
[79,23,20,5],
[79,23,20,6],
[79,23,20,7],
[79,23,20,8],
[79,23,20,11],
[79,23,20,12],
[79,23,20,13],
[79,23,20,14],
[79,23,20,16],
[79,23,20,17],
[79,23,20,19],
[79,23,21,0],
[79,23,21,1],
[79,23,21,2],
[79,23,21,4],
[79,23,21,7],
[79,23,21,10],
[79,23,21,11],
[79,23,21,12],
[79,23,21,13],
[79,23,21,16],
[79,23,21,17],
[79,23,21,19],
[79,23,21,20],
[79,23,22,0],
[79,23,22,1],
[79,23,22,2],
[79,23,22,3],
[79,23,22,4],
[79,23,22,5],
[79,23,22,6],
[79,23,22,7],
[79,23,22,8],
[79,23,22,11],
[79,23,22,14],
[79,23,22,16],
[79,23,22,17],
[79,23,22,19],
[79,23,22,21],
[79,24,3,0],
[79,24,3,1],
[79,24,3,2],
[79,24,4,0],
[79,24,4,1],
[79,24,4,2],
[79,24,4,3],
[79,24,5,0],
[79,24,5,1],
[79,24,5,2],
[79,24,5,4],
[79,24,6,0],
[79,24,6,1],
[79,24,6,2],
[79,24,6,4],
[79,24,7,0],
[79,24,7,1],
[79,24,7,2],
[79,24,7,3],
[79,24,7,4],
[79,24,7,5],
[79,24,7,6],
[79,24,8,0],
[79,24,8,1],
[79,24,8,2],
[79,24,8,4],
[79,24,8,7],
[79,24,10,0],
[79,24,10,1],
[79,24,10,2],
[79,24,10,3],
[79,24,10,4],
[79,24,10,5],
[79,24,10,6],
[79,24,10,7],
[79,24,10,8],
[79,24,11,0],
[79,24,11,1],
[79,24,11,2],
[79,24,11,3],
[79,24,11,4],
[79,24,11,5],
[79,24,11,6],
[79,24,11,7],
[79,24,11,8],
[79,24,11,10],
[79,24,12,0],
[79,24,12,1],
[79,24,12,2],
[79,24,12,3],
[79,24,12,4],
[79,24,12,5],
[79,24,12,6],
[79,24,12,7],
[79,24,12,8],
[79,24,12,10],
[79,24,13,0],
[79,24,13,1],
[79,24,13,2],
[79,24,13,3],
[79,24,13,4],
[79,24,13,5],
[79,24,13,6],
[79,24,13,7],
[79,24,13,8],
[79,24,13,10],
[79,24,13,11],
[79,24,13,12],
[79,24,14,0],
[79,24,14,1],
[79,24,14,2],
[79,24,14,3],
[79,24,14,4],
[79,24,14,5],
[79,24,14,6],
[79,24,14,7],
[79,24,14,8],
[79,24,14,10],
[79,24,14,11],
[79,24,14,12],
[79,24,17,0],
[79,24,17,1],
[79,24,17,2],
[79,24,17,3],
[79,24,17,4],
[79,24,17,5],
[79,24,17,6],
[79,24,17,7],
[79,24,17,8],
[79,24,17,10],
[79,24,17,11],
[79,24,17,12],
[79,24,17,13],
[79,24,17,14],
[79,24,19,0],
[79,24,19,1],
[79,24,19,2],
[79,24,19,3],
[79,24,19,4],
[79,24,19,5],
[79,24,19,6],
[79,24,19,7],
[79,24,19,8],
[79,24,19,10],
[79,24,19,11],
[79,24,19,12],
[79,24,20,0],
[79,24,20,1],
[79,24,20,2],
[79,24,20,3],
[79,24,20,4],
[79,24,20,5],
[79,24,20,6],
[79,24,20,7],
[79,24,20,8],
[79,24,20,11],
[79,24,20,12],
[79,24,20,13],
[79,24,20,14],
[79,24,20,17],
[79,24,20,19],
[79,24,21,0],
[79,24,21,1],
[79,24,21,2],
[79,24,21,4],
[79,24,21,7],
[79,24,21,10],
[79,24,21,11],
[79,24,21,12],
[79,24,21,13],
[79,24,21,17],
[79,24,21,19],
[79,24,21,20],
[79,24,22,0],
[79,24,22,1],
[79,24,22,2],
[79,24,22,3],
[79,24,22,4],
[79,24,22,5],
[79,24,22,6],
[79,24,22,7],
[79,24,22,8],
[79,24,22,11],
[79,24,22,14],
[79,24,22,17],
[79,24,22,19],
[79,24,22,21],
[79,24,23,0],
[79,24,23,1],
[79,24,23,2],
[79,24,23,3],
[79,24,23,4],
[79,24,23,5],
[79,24,23,6],
[79,24,23,7],
[79,24,23,8],
[79,24,23,12],
[79,24,23,13],
[79,24,23,14],
[79,24,23,17],
[79,24,23,19],
[79,24,23,20],
[79,24,23,21],
[79,24,23,22],
[79,25,3,0],
[79,25,3,1],
[79,25,3,2],
[79,25,4,0],
[79,25,4,1],
[79,25,4,2],
[79,25,4,3],
[79,25,5,0],
[79,25,5,1],
[79,25,5,2],
[79,25,5,4],
[79,25,6,0],
[79,25,6,1],
[79,25,6,2],
[79,25,6,4],
[79,25,8,0],
[79,25,8,1],
[79,25,8,2],
[79,25,8,4],
[79,25,10,0],
[79,25,10,1],
[79,25,10,2],
[79,25,10,3],
[79,25,10,4],
[79,25,10,5],
[79,25,10,6],
[79,25,10,8],
[79,25,11,0],
[79,25,11,1],
[79,25,11,2],
[79,25,11,3],
[79,25,11,4],
[79,25,11,5],
[79,25,11,6],
[79,25,11,8],
[79,25,11,10],
[79,25,12,0],
[79,25,12,1],
[79,25,12,2],
[79,25,12,3],
[79,25,12,4],
[79,25,12,5],
[79,25,12,6],
[79,25,12,8],
[79,25,12,10],
[79,25,13,0],
[79,25,13,1],
[79,25,13,2],
[79,25,13,3],
[79,25,13,4],
[79,25,13,5],
[79,25,13,6],
[79,25,13,8],
[79,25,13,10],
[79,25,13,11],
[79,25,13,12],
[79,25,14,0],
[79,25,14,1],
[79,25,14,2],
[79,25,14,3],
[79,25,14,4],
[79,25,14,5],
[79,25,14,6],
[79,25,14,8],
[79,25,14,10],
[79,25,14,11],
[79,25,14,12],
[79,25,16,0],
[79,25,16,1],
[79,25,16,2],
[79,25,16,3],
[79,25,16,4],
[79,25,16,5],
[79,25,16,6],
[79,25,16,8],
[79,25,16,10],
[79,25,16,11],
[79,25,16,12],
[79,25,16,13],
[79,25,16,14],
[79,25,17,0],
[79,25,17,1],
[79,25,17,2],
[79,25,17,3],
[79,25,17,4],
[79,25,17,5],
[79,25,17,6],
[79,25,17,8],
[79,25,17,10],
[79,25,17,11],
[79,25,17,12],
[79,25,17,13],
[79,25,17,14],
[79,25,19,0],
[79,25,19,1],
[79,25,19,2],
[79,25,19,3],
[79,25,19,4],
[79,25,19,5],
[79,25,19,6],
[79,25,19,8],
[79,25,19,10],
[79,25,19,11],
[79,25,19,12],
[79,25,19,16],
[79,25,19,17],
[79,25,20,0],
[79,25,20,1],
[79,25,20,2],
[79,25,20,3],
[79,25,20,4],
[79,25,20,5],
[79,25,20,6],
[79,25,20,8],
[79,25,20,11],
[79,25,20,14],
[79,25,20,16],
[79,25,20,17],
[79,25,20,19],
[79,25,21,0],
[79,25,21,1],
[79,25,21,2],
[79,25,21,4],
[79,25,21,12],
[79,25,21,13],
[79,25,21,14],
[79,25,21,16],
[79,25,21,17],
[79,25,21,19],
[79,25,21,20],
[79,25,22,0],
[79,25,22,1],
[79,25,22,2],
[79,25,22,3],
[79,25,22,4],
[79,25,22,5],
[79,25,22,6],
[79,25,22,11],
[79,25,22,12],
[79,25,22,13],
[79,25,22,14],
[79,25,22,16],
[79,25,22,17],
[79,25,22,19],
[79,25,22,21],
[79,25,23,0],
[79,25,23,1],
[79,25,23,2],
[79,25,23,3],
[79,25,23,4],
[79,25,23,5],
[79,25,23,8],
[79,25,23,10],
[79,25,23,11],
[79,25,23,12],
[79,25,23,13],
[79,25,23,14],
[79,25,23,16],
[79,25,23,17],
[79,25,23,19],
[79,25,23,20],
[79,25,23,21],
[79,25,23,22],
[79,25,24,0],
[79,25,24,1],
[79,25,24,2],
[79,25,24,3],
[79,25,24,4],
[79,25,24,5],
[79,25,24,8],
[79,25,24,10],
[79,25,24,11],
[79,25,24,12],
[79,25,24,13],
[79,25,24,14],
[79,25,24,17],
[79,25,24,19],
[79,25,24,20],
[79,25,24,21],
[79,25,24,22],
[79,25,24,23],
[79,27,3,0],
[79,27,3,1],
[79,27,3,2],
[79,27,4,0],
[79,27,4,1],
[79,27,4,2],
[79,27,4,3],
[79,27,5,0],
[79,27,5,1],
[79,27,5,2],
[79,27,5,4],
[79,27,6,0],
[79,27,6,1],
[79,27,6,2],
[79,27,6,4],
[79,27,7,0],
[79,27,7,1],
[79,27,7,2],
[79,27,7,3],
[79,27,7,4],
[79,27,7,5],
[79,27,7,6],
[79,27,8,0],
[79,27,8,1],
[79,27,8,2],
[79,27,8,4],
[79,27,8,7],
[79,27,11,0],
[79,27,11,1],
[79,27,11,2],
[79,27,11,3],
[79,27,11,4],
[79,27,11,5],
[79,27,11,6],
[79,27,11,7],
[79,27,11,8],
[79,27,12,0],
[79,27,12,1],
[79,27,12,2],
[79,27,12,3],
[79,27,12,4],
[79,27,12,5],
[79,27,12,6],
[79,27,12,7],
[79,27,12,8],
[79,27,13,0],
[79,27,13,1],
[79,27,13,2],
[79,27,13,3],
[79,27,13,4],
[79,27,13,5],
[79,27,13,6],
[79,27,13,7],
[79,27,13,8],
[79,27,13,11],
[79,27,13,12],
[79,27,14,0],
[79,27,14,1],
[79,27,14,2],
[79,27,14,3],
[79,27,14,4],
[79,27,14,5],
[79,27,14,6],
[79,27,14,7],
[79,27,14,8],
[79,27,14,11],
[79,27,14,12],
[79,27,16,0],
[79,27,16,1],
[79,27,16,2],
[79,27,16,3],
[79,27,16,4],
[79,27,16,5],
[79,27,16,6],
[79,27,16,7],
[79,27,16,8],
[79,27,16,11],
[79,27,16,12],
[79,27,16,13],
[79,27,17,0],
[79,27,17,1],
[79,27,17,2],
[79,27,17,3],
[79,27,17,4],
[79,27,17,5],
[79,27,17,6],
[79,27,17,7],
[79,27,17,8],
[79,27,17,11],
[79,27,17,12],
[79,27,17,13],
[79,27,17,16],
[79,27,19,0],
[79,27,19,1],
[79,27,19,2],
[79,27,19,3],
[79,27,19,4],
[79,27,19,5],
[79,27,19,6],
[79,27,19,7],
[79,27,19,8],
[79,27,19,12],
[79,27,19,16],
[79,27,19,17],
[79,27,21,0],
[79,27,21,1],
[79,27,21,2],
[79,27,21,4],
[79,27,21,11],
[79,27,21,12],
[79,27,21,13],
[79,27,21,14],
[79,27,21,16],
[79,27,21,17],
[79,27,21,19],
[79,27,23,0],
[79,27,23,1],
[79,27,23,2],
[79,27,23,5],
[79,27,23,6],
[79,27,23,7],
[79,27,23,8],
[79,27,23,11],
[79,27,23,12],
[79,27,23,13],
[79,27,23,14],
[79,27,23,16],
[79,27,23,17],
[79,27,23,19],
[79,27,23,21],
[79,27,24,0],
[79,27,24,1],
[79,27,24,2],
[79,27,24,5],
[79,27,24,6],
[79,27,24,7],
[79,27,24,8],
[79,27,24,11],
[79,27,24,12],
[79,27,24,13],
[79,27,24,14],
[79,27,24,17],
[79,27,24,19],
[79,27,24,21],
[79,27,25,0],
[79,27,25,1],
[79,27,25,2],
[79,27,25,3],
[79,27,25,4],
[79,27,25,5],
[79,27,25,6],
[79,27,25,8],
[79,27,25,11],
[79,27,25,12],
[79,27,25,13],
[79,27,25,14],
[79,27,25,16],
[79,27,25,17],
[79,27,25,19],
[79,28,3,0],
[79,28,3,1],
[79,28,3,2],
[79,28,4,0],
[79,28,4,1],
[79,28,4,2],
[79,28,4,3],
[79,28,5,0],
[79,28,5,1],
[79,28,5,2],
[79,28,5,4],
[79,28,6,0],
[79,28,6,1],
[79,28,6,2],
[79,28,6,4],
[79,28,8,0],
[79,28,8,1],
[79,28,8,2],
[79,28,8,4],
[79,28,10,0],
[79,28,10,1],
[79,28,10,2],
[79,28,10,3],
[79,28,10,4],
[79,28,10,5],
[79,28,10,6],
[79,28,10,8],
[79,28,11,0],
[79,28,11,1],
[79,28,11,2],
[79,28,11,3],
[79,28,11,4],
[79,28,11,5],
[79,28,11,6],
[79,28,11,8],
[79,28,11,10],
[79,28,12,0],
[79,28,12,1],
[79,28,12,2],
[79,28,12,3],
[79,28,12,4],
[79,28,12,5],
[79,28,12,6],
[79,28,12,8],
[79,28,12,10],
[79,28,13,0],
[79,28,13,1],
[79,28,13,2],
[79,28,13,3],
[79,28,13,4],
[79,28,13,5],
[79,28,13,6],
[79,28,13,8],
[79,28,13,10],
[79,28,13,11],
[79,28,13,12],
[79,28,14,0],
[79,28,14,1],
[79,28,14,2],
[79,28,14,3],
[79,28,14,4],
[79,28,14,5],
[79,28,14,6],
[79,28,14,8],
[79,28,14,10],
[79,28,14,11],
[79,28,14,12],
[79,28,16,0],
[79,28,16,1],
[79,28,16,2],
[79,28,16,3],
[79,28,16,4],
[79,28,16,5],
[79,28,16,6],
[79,28,16,8],
[79,28,16,10],
[79,28,16,11],
[79,28,16,14],
[79,28,17,0],
[79,28,17,1],
[79,28,17,2],
[79,28,17,3],
[79,28,17,4],
[79,28,17,5],
[79,28,17,6],
[79,28,17,8],
[79,28,17,10],
[79,28,17,11],
[79,28,17,14],
[79,28,17,16],
[79,28,19,0],
[79,28,19,1],
[79,28,19,2],
[79,28,19,3],
[79,28,19,4],
[79,28,19,5],
[79,28,19,6],
[79,28,19,10],
[79,28,19,11],
[79,28,19,12],
[79,28,19,16],
[79,28,19,17],
[79,28,20,0],
[79,28,20,1],
[79,28,20,2],
[79,28,20,3],
[79,28,20,4],
[79,28,20,5],
[79,28,20,8],
[79,28,20,11],
[79,28,20,12],
[79,28,20,13],
[79,28,20,14],
[79,28,20,16],
[79,28,20,17],
[79,28,20,19],
[79,28,21,0],
[79,28,21,1],
[79,28,21,2],
[79,28,21,4],
[79,28,21,10],
[79,28,21,11],
[79,28,21,12],
[79,28,21,13],
[79,28,21,14],
[79,28,21,16],
[79,28,21,17],
[79,28,21,19],
[79,28,21,20],
[79,28,22,0],
[79,28,22,1],
[79,28,22,2],
[79,28,22,5],
[79,28,22,6],
[79,28,22,8],
[79,28,22,11],
[79,28,22,12],
[79,28,22,13],
[79,28,22,14],
[79,28,22,16],
[79,28,22,17],
[79,28,22,19],
[79,28,22,21],
[79,28,23,3],
[79,28,23,4],
[79,28,23,5],
[79,28,23,6],
[79,28,23,8],
[79,28,23,10],
[79,28,23,11],
[79,28,23,12],
[79,28,23,13],
[79,28,23,14],
[79,28,23,16],
[79,28,23,17],
[79,28,23,19],
[79,28,23,20],
[79,28,24,3],
[79,28,24,4],
[79,28,24,5],
[79,28,24,6],
[79,28,24,8],
[79,28,24,10],
[79,28,24,11],
[79,28,24,12],
[79,28,24,13],
[79,28,24,14],
[79,28,24,17],
[79,28,24,19],
[79,28,24,20],
[79,28,27,0],
[79,28,27,1],
[79,28,27,2],
[79,28,27,3],
[79,28,27,4],
[79,28,27,5],
[79,28,27,6],
[79,28,27,8],
[79,28,27,11],
[79,28,27,12],
[79,28,27,13],
[79,28,27,14],
[79,29,3,0],
[79,29,3,1],
[79,29,3,2],
[79,29,4,0],
[79,29,4,1],
[79,29,4,2],
[79,29,4,3],
[79,29,5,0],
[79,29,5,1],
[79,29,5,2],
[79,29,5,4],
[79,29,6,0],
[79,29,6,1],
[79,29,6,2],
[79,29,6,4],
[79,29,7,0],
[79,29,7,1],
[79,29,7,2],
[79,29,7,3],
[79,29,7,4],
[79,29,7,5],
[79,29,7,6],
[79,29,8,0],
[79,29,8,1],
[79,29,8,2],
[79,29,8,4],
[79,29,8,7],
[79,29,10,0],
[79,29,10,1],
[79,29,10,2],
[79,29,10,3],
[79,29,10,4],
[79,29,10,5],
[79,29,10,6],
[79,29,10,7],
[79,29,10,8],
[79,29,13,0],
[79,29,13,1],
[79,29,13,2],
[79,29,13,3],
[79,29,13,4],
[79,29,13,5],
[79,29,13,6],
[79,29,13,7],
[79,29,13,8],
[79,29,13,10],
[79,29,14,0],
[79,29,14,1],
[79,29,14,2],
[79,29,14,3],
[79,29,14,4],
[79,29,14,5],
[79,29,14,6],
[79,29,14,7],
[79,29,14,8],
[79,29,14,10],
[79,29,16,0],
[79,29,16,1],
[79,29,16,2],
[79,29,16,3],
[79,29,16,4],
[79,29,16,5],
[79,29,16,6],
[79,29,16,7],
[79,29,16,8],
[79,29,16,13],
[79,29,16,14],
[79,29,17,0],
[79,29,17,1],
[79,29,17,2],
[79,29,17,3],
[79,29,17,4],
[79,29,17,5],
[79,29,17,6],
[79,29,17,7],
[79,29,17,8],
[79,29,17,13],
[79,29,17,14],
[79,29,17,16],
[79,29,19,0],
[79,29,19,1],
[79,29,19,2],
[79,29,19,3],
[79,29,19,4],
[79,29,19,5],
[79,29,19,8],
[79,29,19,10],
[79,29,19,16],
[79,29,19,17],
[79,29,20,0],
[79,29,20,1],
[79,29,20,2],
[79,29,20,3],
[79,29,20,4],
[79,29,20,6],
[79,29,20,7],
[79,29,20,8],
[79,29,20,13],
[79,29,20,14],
[79,29,20,16],
[79,29,20,17],
[79,29,20,19],
[79,29,21,0],
[79,29,21,1],
[79,29,21,2],
[79,29,21,7],
[79,29,21,10],
[79,29,21,13],
[79,29,21,14],
[79,29,21,16],
[79,29,21,17],
[79,29,21,19],
[79,29,21,20],
[79,29,22,3],
[79,29,22,4],
[79,29,22,5],
[79,29,22,6],
[79,29,22,7],
[79,29,22,8],
[79,29,22,13],
[79,29,22,14],
[79,29,22,16],
[79,29,22,17],
[79,29,22,19],
[79,29,23,0],
[79,29,23,1],
[79,29,23,2],
[79,29,23,3],
[79,29,23,4],
[79,29,23,5],
[79,29,23,6],
[79,29,23,7],
[79,29,23,8],
[79,29,23,10],
[79,29,23,13],
[79,29,23,14],
[79,29,23,16],
[79,29,23,17],
[79,29,23,19],
[79,29,24,0],
[79,29,24,1],
[79,29,24,2],
[79,29,24,3],
[79,29,24,4],
[79,29,24,5],
[79,29,24,6],
[79,29,24,7],
[79,29,24,8],
[79,29,24,10],
[79,29,24,13],
[79,29,24,14],
[79,29,24,17],
[79,29,24,19],
[79,29,25,0],
[79,29,25,1],
[79,29,25,2],
[79,29,25,3],
[79,29,25,4],
[79,29,25,5],
[79,29,25,6],
[79,29,25,8],
[79,29,25,10],
[79,29,25,13],
[79,29,25,14],
[79,29,25,16],
[79,29,25,17],
[79,29,27,0],
[79,29,27,1],
[79,29,27,2],
[79,29,27,3],
[79,29,27,4],
[79,29,27,5],
[79,29,27,6],
[79,29,27,7],
[79,29,27,8],
[79,29,27,13],
[79,29,27,14],
[79,29,28,0],
[79,29,28,1],
[79,29,28,2],
[79,29,28,3],
[79,29,28,4],
[79,29,28,5],
[79,29,28,6],
[79,29,28,8],
[79,29,28,10],
[79,29,28,13],
[79,30,3,0],
[79,30,3,1],
[79,30,3,2],
[79,30,4,0],
[79,30,4,1],
[79,30,4,2],
[79,30,4,3],
[79,30,5,0],
[79,30,5,1],
[79,30,5,2],
[79,30,5,4],
[79,30,6,0],
[79,30,6,1],
[79,30,6,2],
[79,30,6,4],
[79,30,7,0],
[79,30,7,1],
[79,30,7,2],
[79,30,7,3],
[79,30,7,4],
[79,30,7,5],
[79,30,7,6],
[79,30,8,0],
[79,30,8,1],
[79,30,8,2],
[79,30,8,4],
[79,30,8,7],
[79,30,10,0],
[79,30,10,1],
[79,30,10,2],
[79,30,10,3],
[79,30,10,4],
[79,30,10,5],
[79,30,10,6],
[79,30,10,7],
[79,30,10,8],
[79,30,11,0],
[79,30,11,1],
[79,30,11,2],
[79,30,11,3],
[79,30,11,4],
[79,30,11,5],
[79,30,11,6],
[79,30,11,7],
[79,30,11,8],
[79,30,11,10],
[79,30,12,0],
[79,30,12,1],
[79,30,12,2],
[79,30,12,3],
[79,30,12,4],
[79,30,12,5],
[79,30,12,6],
[79,30,12,7],
[79,30,12,8],
[79,30,12,10],
[79,30,13,0],
[79,30,13,1],
[79,30,13,2],
[79,30,13,3],
[79,30,13,4],
[79,30,13,5],
[79,30,13,6],
[79,30,13,7],
[79,30,13,8],
[79,30,13,10],
[79,30,13,11],
[79,30,13,12],
[79,30,14,0],
[79,30,14,1],
[79,30,14,2],
[79,30,14,3],
[79,30,14,4],
[79,30,14,5],
[79,30,14,6],
[79,30,14,7],
[79,30,14,8],
[79,30,14,10],
[79,30,14,11],
[79,30,16,0],
[79,30,16,1],
[79,30,16,2],
[79,30,16,3],
[79,30,16,4],
[79,30,16,5],
[79,30,16,6],
[79,30,16,7],
[79,30,16,10],
[79,30,16,11],
[79,30,16,12],
[79,30,16,13],
[79,30,16,14],
[79,30,17,0],
[79,30,17,1],
[79,30,17,2],
[79,30,17,3],
[79,30,17,4],
[79,30,17,5],
[79,30,17,6],
[79,30,17,7],
[79,30,17,10],
[79,30,17,11],
[79,30,17,12],
[79,30,17,13],
[79,30,17,14],
[79,30,17,16],
[79,30,19,0],
[79,30,19,1],
[79,30,19,2],
[79,30,19,3],
[79,30,19,4],
[79,30,19,6],
[79,30,19,7],
[79,30,19,8],
[79,30,19,10],
[79,30,19,11],
[79,30,19,12],
[79,30,19,16],
[79,30,19,17],
[79,30,20,0],
[79,30,20,1],
[79,30,20,2],
[79,30,20,5],
[79,30,20,6],
[79,30,20,7],
[79,30,20,8],
[79,30,20,11],
[79,30,20,12],
[79,30,20,13],
[79,30,20,14],
[79,30,20,16],
[79,30,20,17],
[79,30,20,19],
[79,30,21,4],
[79,30,21,7],
[79,30,21,10],
[79,30,21,11],
[79,30,21,12],
[79,30,21,13],
[79,30,21,14],
[79,30,21,16],
[79,30,21,17],
[79,30,21,19],
[79,30,21,20],
[79,30,22,0],
[79,30,22,1],
[79,30,22,2],
[79,30,22,3],
[79,30,22,4],
[79,30,22,5],
[79,30,22,6],
[79,30,22,7],
[79,30,22,8],
[79,30,22,11],
[79,30,22,12],
[79,30,22,13],
[79,30,22,14],
[79,30,22,16],
[79,30,22,17],
[79,30,22,19],
[79,30,24,0],
[79,30,24,1],
[79,30,24,2],
[79,30,24,3],
[79,30,24,4],
[79,30,24,5],
[79,30,24,6],
[79,30,24,7],
[79,30,24,8],
[79,30,24,10],
[79,30,24,11],
[79,30,24,12],
[79,30,24,13],
[79,30,24,14],
[79,30,24,17],
[79,30,25,0],
[79,30,25,1],
[79,30,25,2],
[79,30,25,3],
[79,30,25,4],
[79,30,25,5],
[79,30,25,6],
[79,30,25,8],
[79,30,25,10],
[79,30,25,11],
[79,30,25,12],
[79,30,25,13],
[79,30,25,14],
[79,30,27,0],
[79,30,27,1],
[79,30,27,2],
[79,30,27,3],
[79,30,27,4],
[79,30,27,5],
[79,30,27,6],
[79,30,27,7],
[79,30,27,8],
[79,30,27,11],
[79,30,27,12],
[79,30,27,13],
[79,30,28,0],
[79,30,28,1],
[79,30,28,2],
[79,30,28,3],
[79,30,28,4],
[79,30,28,5],
[79,30,28,6],
[79,30,28,8],
[79,30,28,10],
[79,30,28,11],
[79,30,29,0],
[79,30,29,1],
[79,30,29,2],
[79,30,29,3],
[79,30,29,4],
[79,30,29,5],
[79,30,29,6],
[79,30,29,7],
[79,30,29,8],
[79,31,3,0],
[79,31,3,1],
[79,31,3,2],
[79,31,4,0],
[79,31,4,1],
[79,31,4,2],
[79,31,4,3],
[79,31,5,0],
[79,31,5,1],
[79,31,5,2],
[79,31,5,4],
[79,31,6,0],
[79,31,6,1],
[79,31,6,2],
[79,31,6,4],
[79,31,7,0],
[79,31,7,1],
[79,31,7,2],
[79,31,7,3],
[79,31,7,4],
[79,31,7,5],
[79,31,7,6],
[79,31,8,0],
[79,31,8,1],
[79,31,8,2],
[79,31,8,4],
[79,31,8,7],
[79,31,10,0],
[79,31,10,1],
[79,31,10,2],
[79,31,10,3],
[79,31,10,4],
[79,31,10,5],
[79,31,10,6],
[79,31,10,7],
[79,31,10,8],
[79,31,11,0],
[79,31,11,1],
[79,31,11,2],
[79,31,11,3],
[79,31,11,4],
[79,31,11,5],
[79,31,11,6],
[79,31,11,7],
[79,31,11,8],
[79,31,11,10],
[79,31,12,0],
[79,31,12,1],
[79,31,12,2],
[79,31,12,3],
[79,31,12,4],
[79,31,12,5],
[79,31,12,6],
[79,31,12,7],
[79,31,12,8],
[79,31,12,10],
[79,31,13,0],
[79,31,13,1],
[79,31,13,2],
[79,31,13,3],
[79,31,13,4],
[79,31,13,5],
[79,31,13,6],
[79,31,13,7],
[79,31,13,8],
[79,31,13,10],
[79,31,13,11],
[79,31,14,0],
[79,31,14,1],
[79,31,14,2],
[79,31,14,3],
[79,31,14,4],
[79,31,14,5],
[79,31,14,6],
[79,31,14,7],
[79,31,14,8],
[79,31,14,12],
[79,31,16,0],
[79,31,16,1],
[79,31,16,2],
[79,31,16,3],
[79,31,16,4],
[79,31,16,5],
[79,31,16,8],
[79,31,16,10],
[79,31,16,11],
[79,31,16,12],
[79,31,16,13],
[79,31,16,14],
[79,31,17,0],
[79,31,17,1],
[79,31,17,2],
[79,31,17,3],
[79,31,17,4],
[79,31,17,5],
[79,31,17,8],
[79,31,17,10],
[79,31,17,11],
[79,31,17,12],
[79,31,17,13],
[79,31,17,14],
[79,31,17,16],
[79,31,19,0],
[79,31,19,1],
[79,31,19,2],
[79,31,19,5],
[79,31,19,6],
[79,31,19,7],
[79,31,19,8],
[79,31,19,10],
[79,31,19,11],
[79,31,19,12],
[79,31,19,16],
[79,31,19,17],
[79,31,20,3],
[79,31,20,4],
[79,31,20,5],
[79,31,20,6],
[79,31,20,7],
[79,31,20,8],
[79,31,20,11],
[79,31,20,12],
[79,31,20,13],
[79,31,20,14],
[79,31,20,16],
[79,31,20,17],
[79,31,20,19],
[79,31,21,0],
[79,31,21,1],
[79,31,21,2],
[79,31,21,4],
[79,31,21,7],
[79,31,21,10],
[79,31,21,11],
[79,31,21,12],
[79,31,21,13],
[79,31,21,14],
[79,31,21,16],
[79,31,21,17],
[79,31,21,19],
[79,31,22,0],
[79,31,22,1],
[79,31,22,2],
[79,31,22,3],
[79,31,22,4],
[79,31,22,5],
[79,31,22,6],
[79,31,22,7],
[79,31,22,8],
[79,31,22,11],
[79,31,22,12],
[79,31,22,13],
[79,31,22,14],
[79,31,22,16],
[79,31,22,17],
[79,31,24,0],
[79,31,24,1],
[79,31,24,2],
[79,31,24,3],
[79,31,24,4],
[79,31,24,5],
[79,31,24,6],
[79,31,24,7],
[79,31,24,8],
[79,31,24,10],
[79,31,24,11],
[79,31,24,12],
[79,31,24,13],
[79,31,24,14],
[79,31,24,17],
[79,31,25,0],
[79,31,25,1],
[79,31,25,2],
[79,31,25,3],
[79,31,25,4],
[79,31,25,5],
[79,31,25,6],
[79,31,25,8],
[79,31,25,10],
[79,31,25,11],
[79,31,25,12],
[79,31,25,13],
[79,31,25,14],
[79,31,27,0],
[79,31,27,1],
[79,31,27,2],
[79,31,27,3],
[79,31,27,4],
[79,31,27,5],
[79,31,27,6],
[79,31,27,7],
[79,31,27,8],
[79,31,27,11],
[79,31,28,0],
[79,31,28,1],
[79,31,28,2],
[79,31,28,3],
[79,31,28,4],
[79,31,28,5],
[79,31,28,6],
[79,31,28,8],
[79,31,29,0],
[79,31,29,1],
[79,31,29,2],
[79,31,29,3],
[79,31,29,4],
[79,31,29,5],
[79,31,29,6],
[79,31,29,7],
[79,32,3,0],
[79,32,3,1],
[79,32,3,2],
[79,32,4,0],
[79,32,4,1],
[79,32,4,2],
[79,32,4,3],
[79,32,5,0],
[79,32,5,1],
[79,32,5,2],
[79,32,5,4],
[79,32,6,0],
[79,32,6,1],
[79,32,6,2],
[79,32,6,4],
[79,32,7,0],
[79,32,7,1],
[79,32,7,2],
[79,32,7,3],
[79,32,7,4],
[79,32,7,5],
[79,32,7,6],
[79,32,8,0],
[79,32,8,1],
[79,32,8,2],
[79,32,8,4],
[79,32,8,7],
[79,32,10,0],
[79,32,10,1],
[79,32,10,2],
[79,32,10,3],
[79,32,10,4],
[79,32,10,5],
[79,32,10,6],
[79,32,10,7],
[79,32,10,8],
[79,32,13,0],
[79,32,13,1],
[79,32,13,2],
[79,32,13,3],
[79,32,13,4],
[79,32,13,5],
[79,32,13,6],
[79,32,13,7],
[79,32,13,8],
[79,32,14,0],
[79,32,14,1],
[79,32,14,2],
[79,32,14,3],
[79,32,14,4],
[79,32,14,5],
[79,32,14,6],
[79,32,14,7],
[79,32,14,10],
[79,32,16,0],
[79,32,16,1],
[79,32,16,2],
[79,32,16,3],
[79,32,16,4],
[79,32,16,6],
[79,32,16,7],
[79,32,16,8],
[79,32,16,10],
[79,32,16,13],
[79,32,16,14],
[79,32,17,0],
[79,32,17,1],
[79,32,17,2],
[79,32,17,3],
[79,32,17,4],
[79,32,17,6],
[79,32,17,7],
[79,32,17,8],
[79,32,17,10],
[79,32,17,13],
[79,32,17,14],
[79,32,17,16],
[79,32,19,3],
[79,32,19,4],
[79,32,19,5],
[79,32,19,6],
[79,32,19,7],
[79,32,19,8],
[79,32,19,10],
[79,32,19,16],
[79,32,19,17],
[79,32,20,0],
[79,32,20,1],
[79,32,20,2],
[79,32,20,3],
[79,32,20,4],
[79,32,20,5],
[79,32,20,6],
[79,32,20,7],
[79,32,20,8],
[79,32,20,13],
[79,32,20,14],
[79,32,20,16],
[79,32,20,17],
[79,32,20,19],
[79,32,21,0],
[79,32,21,1],
[79,32,21,2],
[79,32,21,4],
[79,32,21,7],
[79,32,21,10],
[79,32,21,13],
[79,32,21,14],
[79,32,21,16],
[79,32,21,17],
[79,32,22,0],
[79,32,22,1],
[79,32,22,2],
[79,32,22,3],
[79,32,22,4],
[79,32,22,5],
[79,32,22,6],
[79,32,22,7],
[79,32,22,8],
[79,32,22,13],
[79,32,22,14],
[79,32,22,16],
[79,32,22,17],
[79,32,23,0],
[79,32,23,1],
[79,32,23,2],
[79,32,23,3],
[79,32,23,4],
[79,32,23,5],
[79,32,23,6],
[79,32,23,7],
[79,32,23,8],
[79,32,23,10],
[79,32,23,13],
[79,32,23,14],
[79,32,24,0],
[79,32,24,1],
[79,32,24,2],
[79,32,24,3],
[79,32,24,4],
[79,32,24,5],
[79,32,24,6],
[79,32,24,7],
[79,32,24,8],
[79,32,24,10],
[79,32,24,13],
[79,32,24,14],
[79,32,25,0],
[79,32,25,1],
[79,32,25,2],
[79,32,25,3],
[79,32,25,4],
[79,32,25,5],
[79,32,25,6],
[79,32,25,8],
[79,32,25,10],
[79,32,25,13],
[79,32,27,0],
[79,32,27,1],
[79,32,27,2],
[79,32,27,3],
[79,32,27,4],
[79,32,27,5],
[79,32,27,6],
[79,32,27,7],
[79,32,27,8],
[79,32,28,0],
[79,32,28,1],
[79,32,28,2],
[79,32,28,3],
[79,32,28,4],
[79,32,28,5],
[79,32,28,6],
[79,32,30,0],
[79,32,30,1],
[79,32,30,2],
[79,32,30,3],
[79,32,30,4],
[79,32,31,0],
[79,32,31,1],
[79,32,31,2],
[79,33,3,0],
[79,33,3,1],
[79,33,3,2],
[79,33,4,0],
[79,33,4,1],
[79,33,4,2],
[79,33,4,3],
[79,33,5,0],
[79,33,5,1],
[79,33,5,2],
[79,33,5,4],
[79,33,6,0],
[79,33,6,1],
[79,33,6,2],
[79,33,6,4],
[79,33,7,0],
[79,33,7,1],
[79,33,7,2],
[79,33,7,3],
[79,33,7,4],
[79,33,7,5],
[79,33,7,6],
[79,33,8,0],
[79,33,8,1],
[79,33,8,2],
[79,33,8,4],
[79,33,8,7],
[79,33,10,0],
[79,33,10,1],
[79,33,10,2],
[79,33,10,3],
[79,33,10,4],
[79,33,10,5],
[79,33,10,6],
[79,33,10,7],
[79,33,10,8],
[79,33,13,0],
[79,33,13,1],
[79,33,13,2],
[79,33,13,3],
[79,33,13,4],
[79,33,13,5],
[79,33,13,6],
[79,33,13,7],
[79,33,13,10],
[79,33,14,0],
[79,33,14,1],
[79,33,14,2],
[79,33,14,3],
[79,33,14,4],
[79,33,14,5],
[79,33,14,8],
[79,33,14,10],
[79,33,16,0],
[79,33,16,1],
[79,33,16,2],
[79,33,16,5],
[79,33,16,6],
[79,33,16,7],
[79,33,16,8],
[79,33,16,10],
[79,33,16,13],
[79,33,16,14],
[79,33,17,0],
[79,33,17,1],
[79,33,17,2],
[79,33,17,5],
[79,33,17,6],
[79,33,17,7],
[79,33,17,8],
[79,33,17,10],
[79,33,17,13],
[79,33,17,14],
[79,33,17,16],
[79,33,19,0],
[79,33,19,1],
[79,33,19,2],
[79,33,19,3],
[79,33,19,4],
[79,33,19,5],
[79,33,19,6],
[79,33,19,7],
[79,33,19,8],
[79,33,19,10],
[79,33,19,16],
[79,33,19,17],
[79,33,20,0],
[79,33,20,1],
[79,33,20,2],
[79,33,20,3],
[79,33,20,4],
[79,33,20,5],
[79,33,20,6],
[79,33,20,7],
[79,33,20,8],
[79,33,20,13],
[79,33,20,14],
[79,33,20,16],
[79,33,20,17],
[79,33,21,0],
[79,33,21,1],
[79,33,21,2],
[79,33,21,4],
[79,33,21,7],
[79,33,21,10],
[79,33,21,13],
[79,33,21,14],
[79,33,21,16],
[79,33,21,17],
[79,33,22,0],
[79,33,22,1],
[79,33,22,2],
[79,33,22,3],
[79,33,22,4],
[79,33,22,5],
[79,33,22,6],
[79,33,22,7],
[79,33,22,8],
[79,33,22,13],
[79,33,22,14],
[79,33,23,0],
[79,33,23,1],
[79,33,23,2],
[79,33,23,3],
[79,33,23,4],
[79,33,23,5],
[79,33,23,6],
[79,33,23,7],
[79,33,23,8],
[79,33,23,10],
[79,33,23,13],
[79,33,23,14],
[79,33,24,0],
[79,33,24,1],
[79,33,24,2],
[79,33,24,3],
[79,33,24,4],
[79,33,24,5],
[79,33,24,6],
[79,33,24,7],
[79,33,24,8],
[79,33,24,10],
[79,33,24,13],
[79,33,24,14],
[79,33,25,0],
[79,33,25,1],
[79,33,25,2],
[79,33,25,3],
[79,33,25,4],
[79,33,25,5],
[79,33,25,6],
[79,33,25,8],
[79,33,25,10],
[79,33,27,0],
[79,33,27,1],
[79,33,27,2],
[79,33,27,3],
[79,33,27,4],
[79,33,27,5],
[79,33,27,6],
[79,33,27,7],
[79,33,28,0],
[79,33,28,1],
[79,33,28,2],
[79,33,28,3],
[79,33,28,4],
[79,33,28,5],
[79,33,30,0],
[79,33,30,1],
[79,33,30,2],
[79,34,3,0],
[79,34,3,1],
[79,34,3,2],
[79,34,4,0],
[79,34,4,1],
[79,34,4,2],
[79,34,4,3],
[79,34,5,0],
[79,34,5,1],
[79,34,5,2],
[79,34,5,4],
[79,34,6,0],
[79,34,6,1],
[79,34,6,2],
[79,34,6,4],
[79,34,8,0],
[79,34,8,1],
[79,34,8,2],
[79,34,8,4],
[79,34,10,0],
[79,34,10,1],
[79,34,10,2],
[79,34,10,3],
[79,34,10,4],
[79,34,10,5],
[79,34,10,6],
[79,34,11,0],
[79,34,11,1],
[79,34,11,2],
[79,34,11,3],
[79,34,11,4],
[79,34,11,5],
[79,34,11,6],
[79,34,11,10],
[79,34,12,0],
[79,34,12,1],
[79,34,12,2],
[79,34,12,3],
[79,34,12,4],
[79,34,12,5],
[79,34,12,8],
[79,34,12,10],
[79,34,13,0],
[79,34,13,1],
[79,34,13,2],
[79,34,13,3],
[79,34,13,4],
[79,34,13,5],
[79,34,13,8],
[79,34,13,10],
[79,34,13,11],
[79,34,13,12],
[79,34,14,0],
[79,34,14,1],
[79,34,14,2],
[79,34,14,3],
[79,34,14,4],
[79,34,14,6],
[79,34,14,8],
[79,34,14,10],
[79,34,14,11],
[79,34,14,12],
[79,34,16,3],
[79,34,16,4],
[79,34,16,5],
[79,34,16,6],
[79,34,16,8],
[79,34,16,10],
[79,34,16,11],
[79,34,16,12],
[79,34,16,13],
[79,34,16,14],
[79,34,17,3],
[79,34,17,4],
[79,34,17,5],
[79,34,17,6],
[79,34,17,8],
[79,34,17,10],
[79,34,17,11],
[79,34,17,12],
[79,34,17,13],
[79,34,17,14],
[79,34,17,16],
[79,34,19,0],
[79,34,19,1],
[79,34,19,2],
[79,34,19,3],
[79,34,19,4],
[79,34,19,5],
[79,34,19,6],
[79,34,19,8],
[79,34,19,10],
[79,34,19,11],
[79,34,19,12],
[79,34,19,16],
[79,34,19,17],
[79,34,20,0],
[79,34,20,1],
[79,34,20,2],
[79,34,20,3],
[79,34,20,4],
[79,34,20,5],
[79,34,20,6],
[79,34,20,8],
[79,34,20,11],
[79,34,20,12],
[79,34,20,13],
[79,34,20,14],
[79,34,20,16],
[79,34,20,17],
[79,34,21,0],
[79,34,21,1],
[79,34,21,2],
[79,34,21,4],
[79,34,21,10],
[79,34,21,11],
[79,34,21,12],
[79,34,21,13],
[79,34,21,14],
[79,34,22,0],
[79,34,22,1],
[79,34,22,2],
[79,34,22,3],
[79,34,22,4],
[79,34,22,5],
[79,34,22,6],
[79,34,22,8],
[79,34,22,11],
[79,34,22,12],
[79,34,22,13],
[79,34,22,14],
[79,34,23,0],
[79,34,23,1],
[79,34,23,2],
[79,34,23,3],
[79,34,23,4],
[79,34,23,5],
[79,34,23,6],
[79,34,23,8],
[79,34,23,10],
[79,34,23,11],
[79,34,23,12],
[79,34,23,13],
[79,34,24,0],
[79,34,24,1],
[79,34,24,2],
[79,34,24,3],
[79,34,24,4],
[79,34,24,5],
[79,34,24,6],
[79,34,24,8],
[79,34,24,10],
[79,34,24,11],
[79,34,24,12],
[79,34,24,13],
[79,34,27,0],
[79,34,27,1],
[79,34,27,2],
[79,34,27,3],
[79,34,27,4],
[79,34,27,5],
[79,34,29,0],
[79,34,29,1],
[79,34,29,2],
[79,36,3,0],
[79,36,3,1],
[79,36,3,2],
[79,36,4,0],
[79,36,4,1],
[79,36,4,2],
[79,36,4,3],
[79,36,5,0],
[79,36,5,1],
[79,36,5,2],
[79,36,5,4],
[79,36,6,0],
[79,36,6,1],
[79,36,6,2],
[79,36,6,4],
[79,36,7,0],
[79,36,7,1],
[79,36,7,2],
[79,36,7,3],
[79,36,7,4],
[79,36,7,5],
[79,36,7,6],
[79,36,8,0],
[79,36,8,1],
[79,36,8,2],
[79,36,8,4],
[79,36,10,0],
[79,36,10,1],
[79,36,10,2],
[79,36,10,3],
[79,36,10,4],
[79,36,10,6],
[79,36,10,7],
[79,36,10,8],
[79,36,11,0],
[79,36,11,1],
[79,36,11,2],
[79,36,11,3],
[79,36,11,4],
[79,36,11,6],
[79,36,11,7],
[79,36,11,8],
[79,36,11,10],
[79,36,12,0],
[79,36,12,1],
[79,36,12,2],
[79,36,12,5],
[79,36,12,6],
[79,36,12,7],
[79,36,12,8],
[79,36,12,10],
[79,36,16,0],
[79,36,16,1],
[79,36,16,2],
[79,36,16,3],
[79,36,16,4],
[79,36,16,5],
[79,36,16,6],
[79,36,16,7],
[79,36,16,8],
[79,36,16,10],
[79,36,16,11],
[79,36,16,12],
[79,36,17,0],
[79,36,17,1],
[79,36,17,2],
[79,36,17,3],
[79,36,17,4],
[79,36,17,5],
[79,36,17,6],
[79,36,17,7],
[79,36,17,8],
[79,36,17,10],
[79,36,17,11],
[79,36,17,12],
[79,36,17,16],
[79,36,20,0],
[79,36,20,1],
[79,36,20,2],
[79,36,20,3],
[79,36,20,4],
[79,36,20,5],
[79,36,20,6],
[79,36,20,7],
[79,36,20,8],
[79,36,20,11],
[79,36,20,12],
[79,36,21,0],
[79,36,21,1],
[79,36,21,2],
[79,36,21,4],
[79,36,21,7],
[79,36,21,10],
[79,36,21,11],
[79,36,21,12],
[79,36,22,0],
[79,36,22,1],
[79,36,22,2],
[79,36,22,3],
[79,36,22,4],
[79,36,22,5],
[79,36,22,6],
[79,36,22,7],
[79,36,22,8],
[79,36,22,11],
[79,36,23,0],
[79,36,23,1],
[79,36,23,2],
[79,36,23,3],
[79,36,23,4],
[79,36,23,5],
[79,36,23,6],
[79,36,23,7],
[79,36,23,8],
[79,36,24,0],
[79,36,24,1],
[79,36,24,2],
[79,36,24,3],
[79,36,24,4],
[79,36,24,5],
[79,36,24,6],
[79,36,24,7],
[79,36,24,8],
[79,36,25,0],
[79,36,25,1],
[79,36,25,2],
[79,36,25,3],
[79,36,25,4],
[79,36,25,5],
[79,36,27,0],
[79,36,27,1],
[79,36,27,2],
[79,37,3,0],
[79,37,3,1],
[79,37,3,2],
[79,37,5,0],
[79,37,5,1],
[79,37,5,2],
[79,37,6,0],
[79,37,6,1],
[79,37,6,2],
[79,37,7,0],
[79,37,7,1],
[79,37,7,2],
[79,37,7,3],
[79,37,7,5],
[79,37,8,0],
[79,37,8,1],
[79,37,8,2],
[79,37,8,7],
[79,37,10,0],
[79,37,10,1],
[79,37,10,2],
[79,37,10,5],
[79,37,10,6],
[79,37,10,7],
[79,37,10,8],
[79,37,11,0],
[79,37,11,1],
[79,37,11,2],
[79,37,11,5],
[79,37,11,6],
[79,37,11,7],
[79,37,11,8],
[79,37,11,10],
[79,37,12,3],
[79,37,12,5],
[79,37,12,6],
[79,37,12,7],
[79,37,12,8],
[79,37,12,10],
[79,37,13,3],
[79,37,13,5],
[79,37,13,6],
[79,37,13,7],
[79,37,13,8],
[79,37,13,10],
[79,37,13,11],
[79,37,13,12],
[79,37,14,0],
[79,37,14,1],
[79,37,14,2],
[79,37,14,3],
[79,37,14,5],
[79,37,14,6],
[79,37,14,7],
[79,37,14,8],
[79,37,14,10],
[79,37,14,11],
[79,37,14,12],
[79,37,16,0],
[79,37,16,1],
[79,37,16,2],
[79,37,16,3],
[79,37,16,5],
[79,37,16,6],
[79,37,16,7],
[79,37,16,8],
[79,37,16,10],
[79,37,16,11],
[79,37,16,12],
[79,37,16,13],
[79,37,16,14],
[79,37,17,0],
[79,37,17,1],
[79,37,17,2],
[79,37,17,3],
[79,37,17,5],
[79,37,17,6],
[79,37,17,7],
[79,37,17,8],
[79,37,17,10],
[79,37,17,11],
[79,37,17,12],
[79,37,17,13],
[79,37,17,14],
[79,37,17,16],
[79,37,19,0],
[79,37,19,1],
[79,37,19,2],
[79,37,19,3],
[79,37,19,5],
[79,37,19,6],
[79,37,19,7],
[79,37,19,8],
[79,37,19,10],
[79,37,19,11],
[79,37,19,12],
[79,37,20,0],
[79,37,20,1],
[79,37,20,2],
[79,37,20,3],
[79,37,20,5],
[79,37,20,6],
[79,37,20,7],
[79,37,20,8],
[79,37,20,11],
[79,37,20,12],
[79,37,20,13],
[79,37,21,0],
[79,37,21,1],
[79,37,21,2],
[79,37,21,7],
[79,37,21,10],
[79,37,21,11],
[79,37,22,0],
[79,37,22,1],
[79,37,22,2],
[79,37,22,3],
[79,37,22,5],
[79,37,22,6],
[79,37,22,7],
[79,37,22,8],
[79,37,23,0],
[79,37,23,1],
[79,37,23,2],
[79,37,23,3],
[79,37,23,5],
[79,37,23,6],
[79,37,23,7],
[79,37,24,0],
[79,37,24,1],
[79,37,24,2],
[79,37,24,3],
[79,37,24,5],
[79,37,24,6],
[79,37,24,7],
[79,37,25,0],
[79,37,25,1],
[79,37,25,2],
[79,37,25,3],
[79,38,3,0],
[79,38,3,1],
[79,38,3,2],
[79,38,4,0],
[79,38,4,1],
[79,38,4,2],
[79,38,4,3],
[79,38,5,0],
[79,38,5,1],
[79,38,5,2],
[79,38,5,4],
[79,38,6,0],
[79,38,6,1],
[79,38,6,2],
[79,38,6,4],
[79,38,8,0],
[79,38,8,1],
[79,38,8,2],
[79,38,10,3],
[79,38,10,4],
[79,38,10,5],
[79,38,10,6],
[79,38,10,8],
[79,38,11,3],
[79,38,11,4],
[79,38,11,5],
[79,38,11,6],
[79,38,11,8],
[79,38,11,10],
[79,38,12,0],
[79,38,12,1],
[79,38,12,2],
[79,38,12,3],
[79,38,12,4],
[79,38,12,5],
[79,38,12,6],
[79,38,12,8],
[79,38,12,10],
[79,38,13,0],
[79,38,13,1],
[79,38,13,2],
[79,38,13,3],
[79,38,13,4],
[79,38,13,5],
[79,38,13,6],
[79,38,13,8],
[79,38,13,10],
[79,38,13,11],
[79,38,13,12],
[79,38,14,0],
[79,38,14,1],
[79,38,14,2],
[79,38,14,3],
[79,38,14,4],
[79,38,14,5],
[79,38,14,6],
[79,38,14,8],
[79,38,14,10],
[79,38,14,11],
[79,38,14,12],
[79,38,16,0],
[79,38,16,1],
[79,38,16,2],
[79,38,16,3],
[79,38,16,4],
[79,38,16,5],
[79,38,16,6],
[79,38,16,8],
[79,38,16,10],
[79,38,16,11],
[79,38,16,12],
[79,38,16,13],
[79,38,16,14],
[79,38,17,0],
[79,38,17,1],
[79,38,17,2],
[79,38,17,3],
[79,38,17,4],
[79,38,17,5],
[79,38,17,6],
[79,38,17,8],
[79,38,17,10],
[79,38,17,11],
[79,38,17,12],
[79,38,17,13],
[79,38,17,14],
[79,38,19,0],
[79,38,19,1],
[79,38,19,2],
[79,38,19,3],
[79,38,19,4],
[79,38,19,5],
[79,38,19,6],
[79,38,19,8],
[79,38,19,10],
[79,38,19,11],
[79,38,19,12],
[79,38,20,0],
[79,38,20,1],
[79,38,20,2],
[79,38,20,3],
[79,38,20,4],
[79,38,20,5],
[79,38,20,6],
[79,38,20,8],
[79,38,20,11],
[79,38,21,0],
[79,38,21,1],
[79,38,21,2],
[79,38,21,4],
[79,38,22,0],
[79,38,22,1],
[79,38,22,2],
[79,38,22,3],
[79,38,22,4],
[79,38,22,5],
[79,38,22,6],
[79,38,23,0],
[79,38,23,1],
[79,38,23,2],
[79,38,23,3],
[79,38,23,4],
[79,38,23,5],
[79,38,24,0],
[79,38,24,1],
[79,38,24,2],
[79,38,24,3],
[79,38,24,4],
[79,38,24,5],
[79,39,4,0],
[79,39,4,1],
[79,39,4,2],
[79,39,7,0],
[79,39,7,1],
[79,39,7,2],
[79,39,10,0],
[79,39,10,1],
[79,39,10,2],
[79,39,10,4],
[79,39,10,7],
[79,39,11,0],
[79,39,11,1],
[79,39,11,2],
[79,39,11,4],
[79,39,11,7],
[79,39,11,10],
[79,39,12,0],
[79,39,12,1],
[79,39,12,2],
[79,39,12,4],
[79,39,12,7],
[79,39,12,10],
[79,39,13,0],
[79,39,13,1],
[79,39,13,2],
[79,39,13,4],
[79,39,13,7],
[79,39,13,10],
[79,39,13,11],
[79,39,13,12],
[79,39,14,0],
[79,39,14,1],
[79,39,14,2],
[79,39,14,4],
[79,39,14,7],
[79,39,14,10],
[79,39,14,11],
[79,39,14,12],
[79,39,16,0],
[79,39,16,1],
[79,39,16,2],
[79,39,16,4],
[79,39,16,7],
[79,39,16,10],
[79,39,16,11],
[79,39,16,12],
[79,39,16,13],
[79,39,16,14],
[79,39,17,0],
[79,39,17,1],
[79,39,17,2],
[79,39,17,4],
[79,39,17,7],
[79,39,17,10],
[79,39,17,11],
[79,39,17,12],
[79,39,17,13],
[79,39,17,14],
[79,39,19,0],
[79,39,19,1],
[79,39,19,2],
[79,39,19,4],
[79,39,19,7],
[79,39,19,10],
[79,39,19,11],
[79,39,20,0],
[79,39,20,1],
[79,39,20,2],
[79,39,20,4],
[79,39,20,7],
[79,39,22,0],
[79,39,22,1],
[79,39,22,2],
[79,39,22,4],
[79,39,23,0],
[79,39,23,1],
[79,39,23,2],
[79,39,23,4],
[79,39,24,0],
[79,39,24,1],
[79,39,24,2],
[79,39,24,4],
[79,42,3,0],
[79,42,3,1],
[79,42,3,2],
[79,42,4,0],
[79,42,4,1],
[79,42,4,2],
[79,42,5,4],
[79,42,6,0],
[79,42,6,1],
[79,42,6,2],
[79,42,6,4],
[79,42,7,0],
[79,42,7,1],
[79,42,7,2],
[79,42,7,3],
[79,42,7,4],
[79,42,7,5],
[79,42,7,6],
[79,42,8,0],
[79,42,8,1],
[79,42,8,2],
[79,42,8,4],
[79,42,8,7],
[79,42,11,0],
[79,42,11,1],
[79,42,11,2],
[79,42,11,3],
[79,42,11,4],
[79,42,11,5],
[79,42,11,6],
[79,42,11,7],
[79,42,11,8],
[79,42,12,0],
[79,42,12,1],
[79,42,12,2],
[79,42,12,3],
[79,42,12,4],
[79,42,12,5],
[79,42,12,6],
[79,42,12,7],
[79,42,12,8],
[79,42,13,0],
[79,42,13,1],
[79,42,13,2],
[79,42,13,3],
[79,42,13,4],
[79,42,13,5],
[79,42,13,6],
[79,42,13,7],
[79,42,13,8],
[79,42,13,11],
[79,42,13,12],
[79,42,14,0],
[79,42,14,1],
[79,42,14,2],
[79,42,14,3],
[79,42,14,4],
[79,42,14,5],
[79,42,14,6],
[79,42,14,7],
[79,42,14,8],
[79,42,14,11],
[79,42,14,12],
[79,42,16,0],
[79,42,16,1],
[79,42,16,2],
[79,42,16,3],
[79,42,16,4],
[79,42,16,5],
[79,42,16,6],
[79,42,16,7],
[79,42,16,8],
[79,42,16,11],
[79,42,17,0],
[79,42,17,1],
[79,42,17,2],
[79,42,17,3],
[79,42,17,4],
[79,42,17,5],
[79,42,17,6],
[79,42,17,7],
[79,42,17,8],
[79,42,17,11],
[79,42,19,0],
[79,42,19,1],
[79,42,19,2],
[79,42,19,3],
[79,42,19,4],
[79,42,19,5],
[79,42,19,6],
[79,42,19,7],
[79,42,21,0],
[79,42,21,1],
[79,42,21,2],
[79,42,21,4],
[79,43,4,3],
[79,43,5,0],
[79,43,5,1],
[79,43,5,2],
[79,43,5,4],
[79,43,6,0],
[79,43,6,1],
[79,43,6,2],
[79,43,6,4],
[79,43,8,0],
[79,43,8,1],
[79,43,8,2],
[79,43,8,4],
[79,43,10,0],
[79,43,10,1],
[79,43,10,2],
[79,43,10,3],
[79,43,10,4],
[79,43,10,5],
[79,43,10,6],
[79,43,10,8],
[79,43,11,0],
[79,43,11,1],
[79,43,11,2],
[79,43,11,3],
[79,43,11,4],
[79,43,11,5],
[79,43,11,6],
[79,43,11,8],
[79,43,11,10],
[79,43,12,0],
[79,43,12,1],
[79,43,12,2],
[79,43,12,3],
[79,43,12,4],
[79,43,12,5],
[79,43,12,6],
[79,43,12,8],
[79,43,12,10],
[79,43,13,0],
[79,43,13,1],
[79,43,13,2],
[79,43,13,3],
[79,43,13,4],
[79,43,13,5],
[79,43,13,6],
[79,43,13,8],
[79,43,13,10],
[79,43,13,11],
[79,43,13,12],
[79,43,14,0],
[79,43,14,1],
[79,43,14,2],
[79,43,14,3],
[79,43,14,4],
[79,43,14,5],
[79,43,14,6],
[79,43,14,8],
[79,43,14,10],
[79,43,14,11],
[79,43,14,12],
[79,43,16,0],
[79,43,16,1],
[79,43,16,2],
[79,43,16,3],
[79,43,16,4],
[79,43,16,5],
[79,43,16,6],
[79,43,16,8],
[79,43,17,0],
[79,43,17,1],
[79,43,17,2],
[79,43,17,3],
[79,43,17,4],
[79,43,17,5],
[79,43,17,6],
[79,43,17,8],
[79,43,19,0],
[79,43,19,1],
[79,43,19,2],
[79,43,19,3],
[79,43,19,4],
[79,43,19,5],
[79,43,20,0],
[79,43,20,1],
[79,43,20,2],
[79,43,20,3],
[79,43,20,4],
[79,43,21,0],
[79,43,21,1],
[79,43,21,2],
[79,44,3,0],
[79,44,3,1],
[79,44,3,2],
[79,44,4,0],
[79,44,4,1],
[79,44,4,2],
[79,44,4,3],
[79,44,5,0],
[79,44,5,1],
[79,44,5,2],
[79,44,5,4],
[79,44,6,0],
[79,44,6,1],
[79,44,6,2],
[79,44,6,4],
[79,44,7,0],
[79,44,7,1],
[79,44,7,2],
[79,44,7,3],
[79,44,7,4],
[79,44,7,5],
[79,44,7,6],
[79,44,8,0],
[79,44,8,1],
[79,44,8,2],
[79,44,8,4],
[79,44,8,7],
[79,44,10,0],
[79,44,10,1],
[79,44,10,2],
[79,44,10,3],
[79,44,10,4],
[79,44,10,5],
[79,44,10,6],
[79,44,10,7],
[79,44,10,8],
[79,44,11,0],
[79,44,11,1],
[79,44,11,2],
[79,44,11,3],
[79,44,11,4],
[79,44,11,5],
[79,44,11,6],
[79,44,11,7],
[79,44,11,8],
[79,44,11,10],
[79,44,12,0],
[79,44,12,1],
[79,44,12,2],
[79,44,12,3],
[79,44,12,4],
[79,44,12,5],
[79,44,12,6],
[79,44,12,7],
[79,44,12,8],
[79,44,12,10],
[79,44,13,0],
[79,44,13,1],
[79,44,13,2],
[79,44,13,3],
[79,44,13,4],
[79,44,13,5],
[79,44,13,6],
[79,44,13,7],
[79,44,13,8],
[79,44,13,10],
[79,44,13,11],
[79,44,13,12],
[79,44,14,0],
[79,44,14,1],
[79,44,14,2],
[79,44,14,3],
[79,44,14,4],
[79,44,14,5],
[79,44,14,6],
[79,44,14,7],
[79,44,14,8],
[79,44,14,10],
[79,44,14,11],
[79,44,16,0],
[79,44,16,1],
[79,44,16,2],
[79,44,16,3],
[79,44,16,4],
[79,44,16,5],
[79,44,16,6],
[79,44,16,7],
[79,44,19,0],
[79,44,19,1],
[79,44,19,2],
[79,44,19,3],
[79,44,19,4],
[79,44,20,0],
[79,44,20,1],
[79,44,20,2],
[79,47,3,0],
[79,47,3,1],
[79,47,3,2],
[79,47,4,0],
[79,47,4,1],
[79,47,4,2],
[79,47,4,3],
[79,47,5,0],
[79,47,5,1],
[79,47,5,2],
[79,47,5,4],
[79,47,6,0],
[79,47,6,1],
[79,47,6,2],
[79,47,6,4],
[79,47,7,0],
[79,47,7,1],
[79,47,7,2],
[79,47,7,3],
[79,47,7,4],
[79,47,7,5],
[79,47,7,6],
[79,47,8,0],
[79,47,8,1],
[79,47,8,2],
[79,47,8,4],
[79,47,8,7],
[79,47,10,0],
[79,47,10,1],
[79,47,10,2],
[79,47,10,3],
[79,47,10,4],
[79,47,10,5],
[79,47,10,6],
[79,47,10,7],
[79,47,10,8],
[79,47,11,0],
[79,47,11,1],
[79,47,11,2],
[79,47,11,3],
[79,47,11,4],
[79,47,11,5],
[79,47,11,6],
[79,47,11,7],
[79,47,11,8],
[79,47,12,0],
[79,47,12,1],
[79,47,12,2],
[79,47,12,3],
[79,47,12,4],
[79,47,12,5],
[79,47,12,6],
[79,47,12,7],
[79,47,13,0],
[79,47,13,1],
[79,47,13,2],
[79,47,13,3],
[79,47,13,4],
[79,47,13,5],
[79,47,13,6],
[79,47,13,7],
[79,47,14,0],
[79,47,14,1],
[79,47,14,2],
[79,47,14,3],
[79,47,14,4],
[79,47,14,5],
[79,47,16,0],
[79,47,16,1],
[79,47,16,2],
[79,47,17,0],
[79,47,17,1],
[79,47,17,2],
[79,48,3,0],
[79,48,3,1],
[79,48,3,2],
[79,48,4,0],
[79,48,4,1],
[79,48,4,2],
[79,48,4,3],
[79,48,5,0],
[79,48,5,1],
[79,48,5,2],
[79,48,5,4],
[79,48,6,0],
[79,48,6,1],
[79,48,6,2],
[79,48,6,4],
[79,48,7,0],
[79,48,7,1],
[79,48,7,2],
[79,48,7,3],
[79,48,7,4],
[79,48,7,5],
[79,48,7,6],
[79,48,8,0],
[79,48,8,1],
[79,48,8,2],
[79,48,8,4],
[79,48,8,7],
[79,48,10,0],
[79,48,10,1],
[79,48,10,2],
[79,48,10,3],
[79,48,10,4],
[79,48,10,5],
[79,48,10,6],
[79,48,10,7],
[79,48,13,0],
[79,48,13,1],
[79,48,13,2],
[79,48,13,3],
[79,48,13,4],
[79,48,13,5],
[79,48,14,0],
[79,48,14,1],
[79,48,14,2],
[79,48,14,3],
[79,48,14,4],
[79,51,4,0],
[79,51,4,1],
[79,51,4,2],
[79,51,7,0],
[79,51,7,1],
[79,51,7,2],
[79,51,7,4],
[79,51,10,0],
[79,51,10,1],
[79,51,10,2],
[79,51,10,4],
[79,51,10,7],
[79,51,11,0],
[79,51,11,1],
[79,51,11,2],
[79,51,11,4],
[79,51,11,7],
[79,51,12,0],
[79,51,12,1],
[79,51,12,2],
[79,51,12,4],
[79,51,13,0],
[79,51,13,1],
[79,51,13,2],
[79,51,13,4],
[79,51,14,0],
[79,51,14,1],
[79,51,14,2],
[79,51,14,4],
[79,53,3,0],
[79,53,3,1],
[79,53,3,2],
[79,53,4,0],
[79,53,4,1],
[79,53,4,2],
[79,53,4,3],
[79,53,5,0],
[79,53,5,1],
[79,53,5,2],
[79,53,5,4],
[79,53,6,0],
[79,53,6,1],
[79,53,6,2],
[79,53,6,4],
[79,53,7,0],
[79,53,7,1],
[79,53,7,2],
[79,53,7,3],
[79,53,7,4],
[79,53,7,5],
[79,53,7,6],
[79,53,8,0],
[79,53,8,1],
[79,53,8,2],
[79,53,8,4],
[79,53,8,7],
[79,53,11,0],
[79,53,11,1],
[79,53,11,2],
[79,53,11,3],
[79,53,11,4],
[79,53,11,5],
[79,53,11,6],
[79,53,11,7],
[79,53,12,0],
[79,53,12,1],
[79,53,12,2],
[79,53,12,3],
[79,53,12,4],
[79,53,12,5],
[79,53,13,0],
[79,53,13,1],
[79,53,13,2],
[79,53,13,3],
[79,53,13,4],
[79,53,13,5],
[79,53,14,0],
[79,53,14,1],
[79,53,14,2],
[79,53,14,3],
[79,53,14,4],
[79,54,3,0],
[79,54,3,1],
[79,54,3,2],
[79,54,4,0],
[79,54,4,1],
[79,54,4,2],
[79,54,4,3],
[79,54,5,0],
[79,54,5,1],
[79,54,5,2],
[79,54,5,4],
[79,54,6,0],
[79,54,6,1],
[79,54,6,2],
[79,54,6,4],
[79,54,7,0],
[79,54,7,1],
[79,54,7,2],
[79,54,7,3],
[79,54,7,4],
[79,54,7,5],
[79,54,7,6],
[79,54,8,0],
[79,54,8,1],
[79,54,8,2],
[79,54,8,4],
[79,54,8,7],
[79,54,11,0],
[79,54,11,1],
[79,54,11,2],
[79,54,11,3],
[79,54,11,4],
[79,54,11,5],
[79,54,11,6],
[79,54,11,7],
[79,54,12,0],
[79,54,12,1],
[79,54,12,2],
[79,54,12,3],
[79,54,12,4],
[79,54,12,5],
[79,54,13,0],
[79,54,13,1],
[79,54,13,2],
[79,54,13,3],
[79,54,13,4],
[79,54,13,5],
[79,54,14,0],
[79,54,14,1],
[79,54,14,2],
[79,54,14,3],
[79,54,14,4],
[79,55,3,0],
[79,55,3,1],
[79,55,3,2],
[79,55,4,0],
[79,55,4,1],
[79,55,4,2],
[79,55,4,3],
[79,55,5,0],
[79,55,5,1],
[79,55,5,2],
[79,55,5,4],
[79,55,6,0],
[79,55,6,1],
[79,55,6,2],
[79,55,6,4],
[79,55,7,0],
[79,55,7,1],
[79,55,7,2],
[79,55,7,3],
[79,55,7,4],
[79,55,7,5],
[79,55,7,6],
[79,55,8,0],
[79,55,8,1],
[79,55,8,2],
[79,55,8,4],
[79,55,8,7],
[79,55,11,0],
[79,55,11,1],
[79,55,11,2],
[79,55,11,3],
[79,55,11,4],
[79,55,11,5],
[79,55,11,6],
[79,55,11,7],
[79,55,12,0],
[79,55,12,1],
[79,55,12,2],
[79,55,12,3],
[79,55,12,4],
[79,55,12,5],
[79,55,13,0],
[79,55,13,1],
[79,55,13,2],
[79,55,13,3],
[79,55,13,4],
[79,55,13,5],
[79,55,14,0],
[79,55,14,1],
[79,55,14,2],
[79,55,14,3],
[79,55,14,4],
[79,57,3,0],
[79,57,3,1],
[79,57,3,2],
[79,57,4,0],
[79,57,4,1],
[79,57,4,2],
[79,57,4,3],
[79,57,5,0],
[79,57,5,1],
[79,57,5,2],
[79,57,5,4],
[79,57,6,0],
[79,57,6,1],
[79,57,6,2],
[79,57,6,4],
[79,57,7,0],
[79,57,7,1],
[79,57,7,2],
[79,57,7,3],
[79,57,7,4],
[79,57,7,5],
[79,57,7,6],
[79,57,8,0],
[79,57,8,1],
[79,57,8,2],
[79,57,8,4],
[79,57,8,7],
[79,57,10,0],
[79,57,10,1],
[79,57,10,2],
[79,57,10,3],
[79,57,10,4],
[79,57,10,5],
[79,57,11,0],
[79,57,11,1],
[79,57,11,2],
[79,57,11,3],
[79,57,11,4],
[79,57,11,5],
[79,57,12,0],
[79,57,12,1],
[79,57,12,2],
[79,57,12,3],
[79,57,12,4],
[79,57,13,0],
[79,57,13,1],
[79,57,13,2],
[79,57,13,3],
[79,57,13,4],
[79,57,14,0],
[79,57,14,1],
[79,57,14,2],
[79,58,3,0],
[79,58,3,1],
[79,58,3,2],
[79,58,4,0],
[79,58,4,1],
[79,58,4,2],
[79,58,4,3],
[79,58,5,0],
[79,58,5,1],
[79,58,5,2],
[79,58,5,4],
[79,58,6,0],
[79,58,6,1],
[79,58,6,2],
[79,58,6,4],
[79,58,7,0],
[79,58,7,1],
[79,58,7,2],
[79,58,7,3],
[79,58,7,4],
[79,58,7,5],
[79,58,7,6],
[79,58,8,0],
[79,58,8,1],
[79,58,8,2],
[79,58,8,4],
[79,58,8,7],
[79,58,10,0],
[79,58,10,1],
[79,58,10,2],
[79,58,10,3],
[79,58,10,4],
[79,58,10,5],
[79,58,11,0],
[79,58,11,1],
[79,58,11,2],
[79,58,11,3],
[79,58,11,4],
[79,58,11,5],
[79,58,12,0],
[79,58,12,1],
[79,58,12,2],
[79,58,12,3],
[79,58,12,4],
[79,58,13,0],
[79,58,13,1],
[79,58,13,2],
[79,58,13,3],
[79,58,13,4],
[79,58,14,0],
[79,58,14,1],
[79,58,14,2],
[79,60,3,0],
[79,60,3,1],
[79,60,3,2],
[79,60,4,0],
[79,60,4,1],
[79,60,4,2],
[79,60,4,3],
[79,60,5,0],
[79,60,5,1],
[79,60,5,2],
[79,60,5,4],
[79,60,6,0],
[79,60,6,1],
[79,60,6,2],
[79,60,6,4],
[79,60,7,0],
[79,60,7,1],
[79,60,7,2],
[79,60,7,3],
[79,60,7,4],
[79,60,7,5],
[79,60,8,0],
[79,60,8,1],
[79,60,8,2],
[79,60,8,4],
[79,60,10,0],
[79,60,10,1],
[79,60,10,2],
[79,60,11,0],
[79,60,11,1],
[79,60,11,2],
[79,61,3,0],
[79,61,3,1],
[79,61,3,2],
[79,61,5,0],
[79,61,5,1],
[79,61,5,2],
[79,61,6,0],
[79,61,6,1],
[79,61,6,2],
[79,61,7,0],
[79,61,7,1],
[79,61,7,2],
[79,61,7,3],
[79,61,8,0],
[79,61,8,1],
[79,61,8,2],
[79,62,3,0],
[79,62,3,1],
[79,62,3,2],
[79,62,4,0],
[79,62,4,1],
[79,62,4,2],
[79,62,4,3],
[79,62,5,0],
[79,62,5,1],
[79,62,5,2],
[79,62,5,4],
[79,62,6,0],
[79,62,6,1],
[79,62,6,2],
[79,62,6,4],
[79,62,8,0],
[79,62,8,1],
[79,62,8,2],
[79,64,3,0],
[79,64,3,1],
[79,64,3,2],
[79,64,5,0],
[79,64,5,1],
[79,64,5,2],
[79,65,3,0],
[79,65,3,1],
[79,65,3,2],
[79,65,4,0],
[79,65,4,1],
[79,65,4,2],
[80,4,3,0],
[80,4,3,1],
[80,4,3,2],
[80,5,4,0],
[80,5,4,1],
[80,5,4,2],
[80,6,4,0],
[80,6,4,1],
[80,6,4,2],
[80,7,3,0],
[80,7,3,1],
[80,7,3,2],
[80,7,4,0],
[80,7,4,1],
[80,7,4,2],
[80,7,4,3],
[80,7,5,0],
[80,7,5,1],
[80,7,5,2],
[80,7,5,4],
[80,7,6,0],
[80,7,6,1],
[80,7,6,2],
[80,7,6,4],
[80,8,4,0],
[80,8,4,1],
[80,8,4,2],
[80,8,7,0],
[80,8,7,1],
[80,8,7,2],
[80,8,7,4],
[80,9,3,0],
[80,9,3,1],
[80,9,3,2],
[80,9,4,0],
[80,9,4,1],
[80,9,4,2],
[80,9,4,3],
[80,9,5,0],
[80,9,5,1],
[80,9,5,2],
[80,9,5,4],
[80,9,6,0],
[80,9,6,1],
[80,9,6,2],
[80,9,6,4],
[80,9,7,0],
[80,9,7,1],
[80,9,7,2],
[80,9,7,3],
[80,9,7,4],
[80,9,7,5],
[80,9,7,6],
[80,9,8,0],
[80,9,8,1],
[80,9,8,2],
[80,9,8,4],
[80,9,8,7],
[80,11,3,0],
[80,11,3,1],
[80,11,3,2],
[80,11,4,0],
[80,11,4,1],
[80,11,4,2],
[80,11,4,3],
[80,11,5,0],
[80,11,5,1],
[80,11,5,2],
[80,11,5,4],
[80,11,6,0],
[80,11,6,1],
[80,11,6,2],
[80,11,6,4],
[80,11,7,0],
[80,11,7,1],
[80,11,7,2],
[80,11,7,3],
[80,11,7,4],
[80,11,7,5],
[80,11,7,6],
[80,11,8,0],
[80,11,8,1],
[80,11,8,2],
[80,11,8,4],
[80,11,8,7],
[80,11,9,0],
[80,11,9,1],
[80,11,9,2],
[80,11,9,3],
[80,11,9,4],
[80,11,9,5],
[80,11,9,6],
[80,11,9,7],
[80,11,9,8],
[80,12,3,0],
[80,12,3,1],
[80,12,3,2],
[80,12,4,0],
[80,12,4,1],
[80,12,4,2],
[80,12,4,3],
[80,12,5,0],
[80,12,5,1],
[80,12,5,2],
[80,12,5,4],
[80,12,6,0],
[80,12,6,1],
[80,12,6,2],
[80,12,6,4],
[80,12,7,0],
[80,12,7,1],
[80,12,7,2],
[80,12,7,3],
[80,12,7,4],
[80,12,7,5],
[80,12,7,6],
[80,12,8,0],
[80,12,8,1],
[80,12,8,2],
[80,12,8,4],
[80,12,8,7],
[80,12,9,0],
[80,12,9,1],
[80,12,9,2],
[80,12,9,3],
[80,12,9,4],
[80,12,9,5],
[80,12,9,6],
[80,12,9,7],
[80,12,9,8],
[80,13,3,0],
[80,13,3,1],
[80,13,3,2],
[80,13,4,0],
[80,13,4,1],
[80,13,4,2],
[80,13,4,3],
[80,13,5,0],
[80,13,5,1],
[80,13,5,2],
[80,13,5,4],
[80,13,6,0],
[80,13,6,1],
[80,13,6,2],
[80,13,6,4],
[80,13,7,0],
[80,13,7,1],
[80,13,7,2],
[80,13,7,3],
[80,13,7,4],
[80,13,7,5],
[80,13,7,6],
[80,13,8,0],
[80,13,8,1],
[80,13,8,2],
[80,13,8,4],
[80,13,8,7],
[80,13,9,0],
[80,13,9,1],
[80,13,9,2],
[80,13,9,3],
[80,13,9,4],
[80,13,9,5],
[80,13,9,6],
[80,13,9,7],
[80,13,9,8],
[80,13,11,0],
[80,13,11,1],
[80,13,11,2],
[80,13,11,3],
[80,13,11,4],
[80,13,11,5],
[80,13,11,6],
[80,13,11,7],
[80,13,11,8],
[80,13,11,9],
[80,13,12,0],
[80,13,12,1],
[80,13,12,2],
[80,13,12,3],
[80,13,12,4],
[80,13,12,5],
[80,13,12,6],
[80,13,12,7],
[80,13,12,8],
[80,13,12,9],
[80,14,3,0],
[80,14,3,1],
[80,14,3,2],
[80,14,4,0],
[80,14,4,1],
[80,14,4,2],
[80,14,4,3],
[80,14,5,0],
[80,14,5,1],
[80,14,5,2],
[80,14,5,4],
[80,14,6,0],
[80,14,6,1],
[80,14,6,2],
[80,14,6,4],
[80,14,7,0],
[80,14,7,1],
[80,14,7,2],
[80,14,7,3],
[80,14,7,4],
[80,14,7,5],
[80,14,7,6],
[80,14,8,0],
[80,14,8,1],
[80,14,8,2],
[80,14,8,4],
[80,14,8,7],
[80,14,9,0],
[80,14,9,1],
[80,14,9,2],
[80,14,9,3],
[80,14,9,4],
[80,14,9,5],
[80,14,9,6],
[80,14,9,7],
[80,14,9,8],
[80,14,11,0],
[80,14,11,1],
[80,14,11,2],
[80,14,11,3],
[80,14,11,4],
[80,14,11,5],
[80,14,11,6],
[80,14,11,7],
[80,14,11,8],
[80,14,11,9],
[80,14,12,0],
[80,14,12,1],
[80,14,12,2],
[80,14,12,3],
[80,14,12,4],
[80,14,12,5],
[80,14,12,6],
[80,14,12,7],
[80,14,12,8],
[80,14,12,9],
[80,15,3,0],
[80,15,3,1],
[80,15,3,2],
[80,15,4,0],
[80,15,4,1],
[80,15,4,2],
[80,15,4,3],
[80,15,5,0],
[80,15,5,1],
[80,15,5,2],
[80,15,5,4],
[80,15,6,0],
[80,15,6,1],
[80,15,6,2],
[80,15,6,4],
[80,15,7,0],
[80,15,7,1],
[80,15,7,2],
[80,15,7,3],
[80,15,7,4],
[80,15,7,5],
[80,15,7,6],
[80,15,8,0],
[80,15,8,1],
[80,15,8,2],
[80,15,8,4],
[80,15,8,7],
[80,15,11,0],
[80,15,11,1],
[80,15,11,2],
[80,15,11,3],
[80,15,11,4],
[80,15,11,5],
[80,15,11,6],
[80,15,11,7],
[80,15,11,8],
[80,15,12,0],
[80,15,12,1],
[80,15,12,2],
[80,15,12,3],
[80,15,12,4],
[80,15,12,5],
[80,15,12,6],
[80,15,12,7],
[80,15,12,8],
[80,15,13,0],
[80,15,13,1],
[80,15,13,2],
[80,15,13,3],
[80,15,13,4],
[80,15,13,5],
[80,15,13,6],
[80,15,13,7],
[80,15,13,8],
[80,15,13,11],
[80,15,13,12],
[80,15,14,0],
[80,15,14,1],
[80,15,14,2],
[80,15,14,3],
[80,15,14,4],
[80,15,14,5],
[80,15,14,6],
[80,15,14,7],
[80,15,14,8],
[80,15,14,11],
[80,15,14,12],
[80,16,3,0],
[80,16,3,1],
[80,16,3,2],
[80,16,4,0],
[80,16,4,1],
[80,16,4,2],
[80,16,4,3],
[80,16,5,0],
[80,16,5,1],
[80,16,5,2],
[80,16,5,4],
[80,16,6,0],
[80,16,6,1],
[80,16,6,2],
[80,16,6,4],
[80,16,7,0],
[80,16,7,1],
[80,16,7,2],
[80,16,7,3],
[80,16,7,4],
[80,16,7,5],
[80,16,7,6],
[80,16,8,0],
[80,16,8,1],
[80,16,8,2],
[80,16,8,4],
[80,16,8,7],
[80,16,9,0],
[80,16,9,1],
[80,16,9,2],
[80,16,9,3],
[80,16,9,4],
[80,16,9,5],
[80,16,9,6],
[80,16,9,7],
[80,16,9,8],
[80,16,11,0],
[80,16,11,1],
[80,16,11,2],
[80,16,11,3],
[80,16,11,4],
[80,16,11,5],
[80,16,11,6],
[80,16,11,7],
[80,16,11,8],
[80,16,11,9],
[80,16,12,0],
[80,16,12,1],
[80,16,12,2],
[80,16,12,3],
[80,16,12,4],
[80,16,12,5],
[80,16,12,6],
[80,16,12,7],
[80,16,12,8],
[80,16,12,9],
[80,16,13,0],
[80,16,13,1],
[80,16,13,2],
[80,16,13,3],
[80,16,13,4],
[80,16,13,5],
[80,16,13,6],
[80,16,13,7],
[80,16,13,8],
[80,16,13,9],
[80,16,13,11],
[80,16,13,12],
[80,16,14,0],
[80,16,14,1],
[80,16,14,2],
[80,16,14,3],
[80,16,14,4],
[80,16,14,5],
[80,16,14,6],
[80,16,14,7],
[80,16,14,8],
[80,16,14,9],
[80,16,14,11],
[80,16,14,12],
[80,16,15,0],
[80,16,15,1],
[80,16,15,2],
[80,16,15,3],
[80,16,15,4],
[80,16,15,5],
[80,16,15,6],
[80,16,15,7],
[80,16,15,8],
[80,16,15,11],
[80,16,15,12],
[80,16,15,13],
[80,16,15,14],
[80,17,3,0],
[80,17,3,1],
[80,17,3,2],
[80,17,4,0],
[80,17,4,1],
[80,17,4,2],
[80,17,4,3],
[80,17,5,0],
[80,17,5,1],
[80,17,5,2],
[80,17,5,4],
[80,17,6,0],
[80,17,6,1],
[80,17,6,2],
[80,17,6,4],
[80,17,7,0],
[80,17,7,1],
[80,17,7,2],
[80,17,7,3],
[80,17,7,4],
[80,17,7,5],
[80,17,7,6],
[80,17,8,0],
[80,17,8,1],
[80,17,8,2],
[80,17,8,4],
[80,17,8,7],
[80,17,9,0],
[80,17,9,1],
[80,17,9,2],
[80,17,9,3],
[80,17,9,4],
[80,17,9,5],
[80,17,9,6],
[80,17,9,7],
[80,17,9,8],
[80,17,11,0],
[80,17,11,1],
[80,17,11,2],
[80,17,11,3],
[80,17,11,4],
[80,17,11,5],
[80,17,11,6],
[80,17,11,7],
[80,17,11,8],
[80,17,11,9],
[80,17,12,0],
[80,17,12,1],
[80,17,12,2],
[80,17,12,3],
[80,17,12,4],
[80,17,12,5],
[80,17,12,6],
[80,17,12,7],
[80,17,12,8],
[80,17,12,9],
[80,17,13,0],
[80,17,13,1],
[80,17,13,2],
[80,17,13,3],
[80,17,13,4],
[80,17,13,5],
[80,17,13,6],
[80,17,13,7],
[80,17,13,8],
[80,17,13,9],
[80,17,13,11],
[80,17,13,12],
[80,17,14,0],
[80,17,14,1],
[80,17,14,2],
[80,17,14,3],
[80,17,14,4],
[80,17,14,5],
[80,17,14,6],
[80,17,14,7],
[80,17,14,8],
[80,17,14,9],
[80,17,14,11],
[80,17,14,12],
[80,17,15,0],
[80,17,15,1],
[80,17,15,2],
[80,17,15,3],
[80,17,15,4],
[80,17,15,5],
[80,17,15,6],
[80,17,15,7],
[80,17,15,8],
[80,17,15,11],
[80,17,15,12],
[80,17,15,13],
[80,17,15,14],
[80,17,16,0],
[80,17,16,1],
[80,17,16,2],
[80,17,16,3],
[80,17,16,4],
[80,17,16,5],
[80,17,16,6],
[80,17,16,7],
[80,17,16,8],
[80,17,16,9],
[80,17,16,11],
[80,17,16,12],
[80,17,16,13],
[80,17,16,14],
[80,17,16,15],
[80,18,3,0],
[80,18,3,1],
[80,18,3,2],
[80,18,4,0],
[80,18,4,1],
[80,18,4,2],
[80,18,4,3],
[80,18,5,0],
[80,18,5,1],
[80,18,5,2],
[80,18,5,4],
[80,18,6,0],
[80,18,6,1],
[80,18,6,2],
[80,18,6,4],
[80,18,7,0],
[80,18,7,1],
[80,18,7,2],
[80,18,7,3],
[80,18,7,4],
[80,18,7,5],
[80,18,7,6],
[80,18,8,0],
[80,18,8,1],
[80,18,8,2],
[80,18,8,4],
[80,18,8,7],
[80,18,11,0],
[80,18,11,1],
[80,18,11,2],
[80,18,11,3],
[80,18,11,4],
[80,18,11,5],
[80,18,11,6],
[80,18,11,7],
[80,18,11,8],
[80,18,12,0],
[80,18,12,1],
[80,18,12,2],
[80,18,12,3],
[80,18,12,4],
[80,18,12,5],
[80,18,12,6],
[80,18,12,7],
[80,18,12,8],
[80,18,13,0],
[80,18,13,1],
[80,18,13,2],
[80,18,13,3],
[80,18,13,4],
[80,18,13,5],
[80,18,13,6],
[80,18,13,7],
[80,18,13,8],
[80,18,13,11],
[80,18,13,12],
[80,18,14,0],
[80,18,14,1],
[80,18,14,2],
[80,18,14,3],
[80,18,14,4],
[80,18,14,5],
[80,18,14,6],
[80,18,14,7],
[80,18,14,8],
[80,18,14,11],
[80,18,14,12],
[80,18,16,0],
[80,18,16,1],
[80,18,16,2],
[80,18,16,3],
[80,18,16,4],
[80,18,16,5],
[80,18,16,6],
[80,18,16,7],
[80,18,16,8],
[80,18,16,11],
[80,18,16,12],
[80,18,16,13],
[80,18,16,14],
[80,18,17,0],
[80,18,17,1],
[80,18,17,2],
[80,18,17,3],
[80,18,17,4],
[80,18,17,5],
[80,18,17,6],
[80,18,17,7],
[80,18,17,8],
[80,18,17,11],
[80,18,17,12],
[80,18,17,13],
[80,18,17,14],
[80,18,17,16],
[80,19,3,0],
[80,19,3,1],
[80,19,3,2],
[80,19,4,0],
[80,19,4,1],
[80,19,4,2],
[80,19,4,3],
[80,19,5,0],
[80,19,5,1],
[80,19,5,2],
[80,19,5,4],
[80,19,6,0],
[80,19,6,1],
[80,19,6,2],
[80,19,6,4],
[80,19,7,0],
[80,19,7,1],
[80,19,7,2],
[80,19,7,3],
[80,19,7,4],
[80,19,7,5],
[80,19,7,6],
[80,19,8,0],
[80,19,8,1],
[80,19,8,2],
[80,19,8,4],
[80,19,8,7],
[80,19,9,0],
[80,19,9,1],
[80,19,9,2],
[80,19,9,3],
[80,19,9,4],
[80,19,9,5],
[80,19,9,6],
[80,19,9,7],
[80,19,9,8],
[80,19,11,0],
[80,19,11,1],
[80,19,11,2],
[80,19,11,3],
[80,19,11,4],
[80,19,11,5],
[80,19,11,6],
[80,19,11,7],
[80,19,11,8],
[80,19,11,9],
[80,19,12,0],
[80,19,12,1],
[80,19,12,2],
[80,19,12,3],
[80,19,12,4],
[80,19,12,5],
[80,19,12,6],
[80,19,12,7],
[80,19,12,8],
[80,19,12,9],
[80,19,15,0],
[80,19,15,1],
[80,19,15,2],
[80,19,15,3],
[80,19,15,4],
[80,19,15,5],
[80,19,15,6],
[80,19,15,7],
[80,19,15,8],
[80,19,15,11],
[80,19,15,12],
[80,19,16,0],
[80,19,16,1],
[80,19,16,2],
[80,19,16,3],
[80,19,16,4],
[80,19,16,5],
[80,19,16,6],
[80,19,16,7],
[80,19,16,8],
[80,19,16,9],
[80,19,16,11],
[80,19,16,12],
[80,19,16,15],
[80,19,17,0],
[80,19,17,1],
[80,19,17,2],
[80,19,17,3],
[80,19,17,4],
[80,19,17,5],
[80,19,17,6],
[80,19,17,7],
[80,19,17,8],
[80,19,17,9],
[80,19,17,11],
[80,19,17,12],
[80,19,17,15],
[80,19,17,16],
[80,19,18,0],
[80,19,18,1],
[80,19,18,2],
[80,19,18,3],
[80,19,18,4],
[80,19,18,5],
[80,19,18,6],
[80,19,18,7],
[80,19,18,8],
[80,19,18,11],
[80,19,18,12],
[80,19,18,16],
[80,19,18,17],
[80,21,4,0],
[80,21,4,1],
[80,21,4,2],
[80,21,7,0],
[80,21,7,1],
[80,21,7,2],
[80,21,7,4],
[80,21,9,0],
[80,21,9,1],
[80,21,9,2],
[80,21,9,4],
[80,21,9,7],
[80,21,11,0],
[80,21,11,1],
[80,21,11,2],
[80,21,11,4],
[80,21,11,7],
[80,21,11,9],
[80,21,12,0],
[80,21,12,1],
[80,21,12,2],
[80,21,12,4],
[80,21,12,7],
[80,21,12,9],
[80,21,13,0],
[80,21,13,1],
[80,21,13,2],
[80,21,13,4],
[80,21,13,7],
[80,21,13,9],
[80,21,13,11],
[80,21,13,12],
[80,21,14,0],
[80,21,14,1],
[80,21,14,2],
[80,21,14,4],
[80,21,14,7],
[80,21,14,9],
[80,21,14,11],
[80,21,14,12],
[80,21,15,0],
[80,21,15,1],
[80,21,15,2],
[80,21,15,4],
[80,21,15,7],
[80,21,15,11],
[80,21,15,12],
[80,21,15,13],
[80,21,15,14],
[80,21,16,0],
[80,21,16,1],
[80,21,16,2],
[80,21,16,4],
[80,21,16,7],
[80,21,16,9],
[80,21,16,11],
[80,21,16,12],
[80,21,16,13],
[80,21,16,14],
[80,21,16,15],
[80,21,17,0],
[80,21,17,1],
[80,21,17,2],
[80,21,17,4],
[80,21,17,7],
[80,21,17,9],
[80,21,17,11],
[80,21,17,12],
[80,21,17,13],
[80,21,17,14],
[80,21,17,15],
[80,21,17,16],
[80,21,18,0],
[80,21,18,1],
[80,21,18,2],
[80,21,18,4],
[80,21,18,7],
[80,21,18,11],
[80,21,18,12],
[80,21,18,13],
[80,21,18,14],
[80,21,18,16],
[80,21,18,17],
[80,21,19,0],
[80,21,19,1],
[80,21,19,2],
[80,21,19,4],
[80,21,19,7],
[80,21,19,9],
[80,21,19,11],
[80,21,19,12],
[80,21,19,15],
[80,21,19,16],
[80,21,19,17],
[80,23,3,0],
[80,23,3,1],
[80,23,3,2],
[80,23,4,0],
[80,23,4,1],
[80,23,4,2],
[80,23,4,3],
[80,23,5,0],
[80,23,5,1],
[80,23,5,2],
[80,23,5,4],
[80,23,6,0],
[80,23,6,1],
[80,23,6,2],
[80,23,6,4],
[80,23,7,0],
[80,23,7,1],
[80,23,7,2],
[80,23,7,3],
[80,23,7,4],
[80,23,7,5],
[80,23,7,6],
[80,23,8,0],
[80,23,8,1],
[80,23,8,2],
[80,23,8,4],
[80,23,8,7],
[80,23,9,0],
[80,23,9,1],
[80,23,9,2],
[80,23,9,3],
[80,23,9,4],
[80,23,9,5],
[80,23,9,6],
[80,23,9,7],
[80,23,9,8],
[80,23,11,0],
[80,23,11,1],
[80,23,11,2],
[80,23,11,3],
[80,23,11,4],
[80,23,11,5],
[80,23,11,6],
[80,23,11,7],
[80,23,11,8],
[80,23,11,9],
[80,23,12,0],
[80,23,12,1],
[80,23,12,2],
[80,23,12,3],
[80,23,12,4],
[80,23,12,5],
[80,23,12,6],
[80,23,12,7],
[80,23,12,8],
[80,23,12,9],
[80,23,13,0],
[80,23,13,1],
[80,23,13,2],
[80,23,13,3],
[80,23,13,4],
[80,23,13,5],
[80,23,13,6],
[80,23,13,7],
[80,23,13,8],
[80,23,13,9],
[80,23,13,11],
[80,23,13,12],
[80,23,14,0],
[80,23,14,1],
[80,23,14,2],
[80,23,14,3],
[80,23,14,4],
[80,23,14,5],
[80,23,14,6],
[80,23,14,7],
[80,23,14,8],
[80,23,14,9],
[80,23,14,11],
[80,23,14,12],
[80,23,15,0],
[80,23,15,1],
[80,23,15,2],
[80,23,15,3],
[80,23,15,4],
[80,23,15,5],
[80,23,15,6],
[80,23,15,7],
[80,23,15,8],
[80,23,15,11],
[80,23,15,12],
[80,23,15,13],
[80,23,15,14],
[80,23,16,0],
[80,23,16,1],
[80,23,16,2],
[80,23,16,3],
[80,23,16,4],
[80,23,16,5],
[80,23,16,6],
[80,23,16,7],
[80,23,16,8],
[80,23,16,9],
[80,23,16,11],
[80,23,16,12],
[80,23,16,13],
[80,23,16,14],
[80,23,16,15],
[80,23,17,0],
[80,23,17,1],
[80,23,17,2],
[80,23,17,3],
[80,23,17,4],
[80,23,17,5],
[80,23,17,6],
[80,23,17,7],
[80,23,17,8],
[80,23,17,9],
[80,23,17,11],
[80,23,17,12],
[80,23,17,13],
[80,23,17,14],
[80,23,17,15],
[80,23,17,16],
[80,23,18,0],
[80,23,18,1],
[80,23,18,2],
[80,23,18,3],
[80,23,18,4],
[80,23,18,5],
[80,23,18,6],
[80,23,18,7],
[80,23,18,8],
[80,23,18,11],
[80,23,18,12],
[80,23,18,13],
[80,23,18,14],
[80,23,19,0],
[80,23,19,1],
[80,23,19,2],
[80,23,19,3],
[80,23,19,4],
[80,23,19,5],
[80,23,19,6],
[80,23,19,7],
[80,23,19,8],
[80,23,19,9],
[80,23,19,11],
[80,23,19,12],
[80,23,19,16],
[80,23,19,17],
[80,23,19,18],
[80,23,21,0],
[80,23,21,1],
[80,23,21,2],
[80,23,21,4],
[80,23,21,7],
[80,23,21,9],
[80,23,21,11],
[80,23,21,14],
[80,23,21,15],
[80,23,21,16],
[80,23,21,17],
[80,23,21,18],
[80,23,21,19],
[80,24,3,0],
[80,24,3,1],
[80,24,3,2],
[80,24,4,0],
[80,24,4,1],
[80,24,4,2],
[80,24,4,3],
[80,24,5,0],
[80,24,5,1],
[80,24,5,2],
[80,24,5,4],
[80,24,6,0],
[80,24,6,1],
[80,24,6,2],
[80,24,6,4],
[80,24,7,0],
[80,24,7,1],
[80,24,7,2],
[80,24,7,3],
[80,24,7,4],
[80,24,7,5],
[80,24,7,6],
[80,24,8,0],
[80,24,8,1],
[80,24,8,2],
[80,24,8,4],
[80,24,8,7],
[80,24,9,0],
[80,24,9,1],
[80,24,9,2],
[80,24,9,3],
[80,24,9,4],
[80,24,9,5],
[80,24,9,6],
[80,24,9,7],
[80,24,9,8],
[80,24,11,0],
[80,24,11,1],
[80,24,11,2],
[80,24,11,3],
[80,24,11,4],
[80,24,11,5],
[80,24,11,6],
[80,24,11,7],
[80,24,11,8],
[80,24,11,9],
[80,24,12,0],
[80,24,12,1],
[80,24,12,2],
[80,24,12,3],
[80,24,12,4],
[80,24,12,5],
[80,24,12,6],
[80,24,12,7],
[80,24,12,8],
[80,24,12,9],
[80,24,13,0],
[80,24,13,1],
[80,24,13,2],
[80,24,13,3],
[80,24,13,4],
[80,24,13,5],
[80,24,13,6],
[80,24,13,7],
[80,24,13,8],
[80,24,13,9],
[80,24,13,11],
[80,24,13,12],
[80,24,14,0],
[80,24,14,1],
[80,24,14,2],
[80,24,14,3],
[80,24,14,4],
[80,24,14,5],
[80,24,14,6],
[80,24,14,7],
[80,24,14,8],
[80,24,14,9],
[80,24,14,11],
[80,24,14,12],
[80,24,15,0],
[80,24,15,1],
[80,24,15,2],
[80,24,15,3],
[80,24,15,4],
[80,24,15,5],
[80,24,15,6],
[80,24,15,7],
[80,24,15,8],
[80,24,15,11],
[80,24,15,12],
[80,24,15,13],
[80,24,15,14],
[80,24,17,0],
[80,24,17,1],
[80,24,17,2],
[80,24,17,3],
[80,24,17,4],
[80,24,17,5],
[80,24,17,6],
[80,24,17,7],
[80,24,17,8],
[80,24,17,9],
[80,24,17,11],
[80,24,17,12],
[80,24,17,13],
[80,24,17,14],
[80,24,17,15],
[80,24,18,0],
[80,24,18,1],
[80,24,18,2],
[80,24,18,3],
[80,24,18,4],
[80,24,18,5],
[80,24,18,6],
[80,24,18,7],
[80,24,18,8],
[80,24,18,11],
[80,24,18,12],
[80,24,18,13],
[80,24,18,14],
[80,24,19,0],
[80,24,19,1],
[80,24,19,2],
[80,24,19,3],
[80,24,19,4],
[80,24,19,5],
[80,24,19,6],
[80,24,19,7],
[80,24,19,8],
[80,24,19,9],
[80,24,19,11],
[80,24,19,12],
[80,24,19,17],
[80,24,19,18],
[80,24,21,0],
[80,24,21,1],
[80,24,21,2],
[80,24,21,4],
[80,24,21,7],
[80,24,21,9],
[80,24,21,11],
[80,24,21,14],
[80,24,21,15],
[80,24,21,17],
[80,24,21,18],
[80,24,21,19],
[80,24,23,0],
[80,24,23,1],
[80,24,23,2],
[80,24,23,3],
[80,24,23,4],
[80,24,23,5],
[80,24,23,6],
[80,24,23,7],
[80,24,23,11],
[80,24,23,12],
[80,24,23,13],
[80,24,23,14],
[80,24,23,15],
[80,24,23,17],
[80,24,23,18],
[80,24,23,19],
[80,24,23,21],
[80,25,3,0],
[80,25,3,1],
[80,25,3,2],
[80,25,4,0],
[80,25,4,1],
[80,25,4,2],
[80,25,4,3],
[80,25,5,0],
[80,25,5,1],
[80,25,5,2],
[80,25,5,4],
[80,25,6,0],
[80,25,6,1],
[80,25,6,2],
[80,25,6,4],
[80,25,8,0],
[80,25,8,1],
[80,25,8,2],
[80,25,8,4],
[80,25,9,0],
[80,25,9,1],
[80,25,9,2],
[80,25,9,3],
[80,25,9,4],
[80,25,9,5],
[80,25,9,6],
[80,25,9,8],
[80,25,11,0],
[80,25,11,1],
[80,25,11,2],
[80,25,11,3],
[80,25,11,4],
[80,25,11,5],
[80,25,11,6],
[80,25,11,8],
[80,25,11,9],
[80,25,12,0],
[80,25,12,1],
[80,25,12,2],
[80,25,12,3],
[80,25,12,4],
[80,25,12,5],
[80,25,12,6],
[80,25,12,8],
[80,25,12,9],
[80,25,13,0],
[80,25,13,1],
[80,25,13,2],
[80,25,13,3],
[80,25,13,4],
[80,25,13,5],
[80,25,13,6],
[80,25,13,8],
[80,25,13,9],
[80,25,13,11],
[80,25,13,12],
[80,25,14,0],
[80,25,14,1],
[80,25,14,2],
[80,25,14,3],
[80,25,14,4],
[80,25,14,5],
[80,25,14,6],
[80,25,14,8],
[80,25,14,9],
[80,25,14,11],
[80,25,14,12],
[80,25,15,0],
[80,25,15,1],
[80,25,15,2],
[80,25,15,3],
[80,25,15,4],
[80,25,15,5],
[80,25,15,6],
[80,25,15,8],
[80,25,15,11],
[80,25,15,12],
[80,25,15,13],
[80,25,15,14],
[80,25,16,0],
[80,25,16,1],
[80,25,16,2],
[80,25,16,3],
[80,25,16,4],
[80,25,16,5],
[80,25,16,6],
[80,25,16,8],
[80,25,16,9],
[80,25,16,11],
[80,25,16,12],
[80,25,16,13],
[80,25,16,14],
[80,25,17,0],
[80,25,17,1],
[80,25,17,2],
[80,25,17,3],
[80,25,17,4],
[80,25,17,5],
[80,25,17,6],
[80,25,17,8],
[80,25,17,9],
[80,25,17,11],
[80,25,17,12],
[80,25,17,13],
[80,25,17,14],
[80,25,17,16],
[80,25,18,0],
[80,25,18,1],
[80,25,18,2],
[80,25,18,3],
[80,25,18,4],
[80,25,18,5],
[80,25,18,6],
[80,25,18,8],
[80,25,18,11],
[80,25,18,12],
[80,25,18,13],
[80,25,18,16],
[80,25,18,17],
[80,25,19,0],
[80,25,19,1],
[80,25,19,2],
[80,25,19,3],
[80,25,19,4],
[80,25,19,5],
[80,25,19,6],
[80,25,19,8],
[80,25,19,9],
[80,25,19,11],
[80,25,19,15],
[80,25,19,16],
[80,25,19,17],
[80,25,19,18],
[80,25,21,0],
[80,25,21,1],
[80,25,21,2],
[80,25,21,4],
[80,25,21,11],
[80,25,21,12],
[80,25,21,13],
[80,25,21,14],
[80,25,21,15],
[80,25,21,16],
[80,25,21,17],
[80,25,21,18],
[80,25,21,19],
[80,25,23,0],
[80,25,23,1],
[80,25,23,2],
[80,25,23,3],
[80,25,23,4],
[80,25,23,6],
[80,25,23,8],
[80,25,23,9],
[80,25,23,11],
[80,25,23,12],
[80,25,23,13],
[80,25,23,14],
[80,25,23,15],
[80,25,23,16],
[80,25,23,17],
[80,25,23,18],
[80,25,23,19],
[80,25,23,21],
[80,25,24,0],
[80,25,24,1],
[80,25,24,2],
[80,25,24,3],
[80,25,24,4],
[80,25,24,6],
[80,25,24,8],
[80,25,24,9],
[80,25,24,11],
[80,25,24,12],
[80,25,24,13],
[80,25,24,14],
[80,25,24,15],
[80,25,24,17],
[80,25,24,18],
[80,25,24,19],
[80,25,24,21],
[80,26,3,0],
[80,26,3,1],
[80,26,3,2],
[80,26,4,0],
[80,26,4,1],
[80,26,4,2],
[80,26,4,3],
[80,26,5,0],
[80,26,5,1],
[80,26,5,2],
[80,26,5,4],
[80,26,6,0],
[80,26,6,1],
[80,26,6,2],
[80,26,6,4],
[80,26,7,0],
[80,26,7,1],
[80,26,7,2],
[80,26,7,3],
[80,26,7,4],
[80,26,7,5],
[80,26,7,6],
[80,26,8,0],
[80,26,8,1],
[80,26,8,2],
[80,26,8,4],
[80,26,8,7],
[80,26,11,0],
[80,26,11,1],
[80,26,11,2],
[80,26,11,3],
[80,26,11,4],
[80,26,11,5],
[80,26,11,6],
[80,26,11,7],
[80,26,11,8],
[80,26,12,0],
[80,26,12,1],
[80,26,12,2],
[80,26,12,3],
[80,26,12,4],
[80,26,12,5],
[80,26,12,6],
[80,26,12,7],
[80,26,12,8],
[80,26,13,0],
[80,26,13,1],
[80,26,13,2],
[80,26,13,3],
[80,26,13,4],
[80,26,13,5],
[80,26,13,6],
[80,26,13,7],
[80,26,13,8],
[80,26,13,11],
[80,26,13,12],
[80,26,14,0],
[80,26,14,1],
[80,26,14,2],
[80,26,14,3],
[80,26,14,4],
[80,26,14,5],
[80,26,14,6],
[80,26,14,7],
[80,26,14,8],
[80,26,14,11],
[80,26,14,12],
[80,26,16,0],
[80,26,16,1],
[80,26,16,2],
[80,26,16,3],
[80,26,16,4],
[80,26,16,5],
[80,26,16,6],
[80,26,16,7],
[80,26,16,8],
[80,26,16,11],
[80,26,16,12],
[80,26,16,13],
[80,26,17,0],
[80,26,17,1],
[80,26,17,2],
[80,26,17,3],
[80,26,17,4],
[80,26,17,5],
[80,26,17,6],
[80,26,17,7],
[80,26,17,8],
[80,26,17,11],
[80,26,17,12],
[80,26,17,13],
[80,26,17,16],
[80,26,19,0],
[80,26,19,1],
[80,26,19,2],
[80,26,19,3],
[80,26,19,4],
[80,26,19,5],
[80,26,19,6],
[80,26,19,7],
[80,26,19,8],
[80,26,19,12],
[80,26,19,16],
[80,26,19,17],
[80,26,21,0],
[80,26,21,1],
[80,26,21,2],
[80,26,21,4],
[80,26,21,11],
[80,26,21,12],
[80,26,21,13],
[80,26,21,14],
[80,26,21,16],
[80,26,21,17],
[80,26,21,19],
[80,26,23,0],
[80,26,23,1],
[80,26,23,2],
[80,26,23,5],
[80,26,23,6],
[80,26,23,7],
[80,26,23,8],
[80,26,23,11],
[80,26,23,12],
[80,26,23,13],
[80,26,23,14],
[80,26,23,16],
[80,26,23,17],
[80,26,23,19],
[80,26,23,21],
[80,26,24,0],
[80,26,24,1],
[80,26,24,2],
[80,26,24,5],
[80,26,24,6],
[80,26,24,7],
[80,26,24,8],
[80,26,24,11],
[80,26,24,12],
[80,26,24,13],
[80,26,24,14],
[80,26,24,17],
[80,26,24,19],
[80,26,24,21],
[80,26,25,0],
[80,26,25,1],
[80,26,25,2],
[80,26,25,3],
[80,26,25,4],
[80,26,25,5],
[80,26,25,6],
[80,26,25,8],
[80,26,25,11],
[80,26,25,12],
[80,26,25,13],
[80,26,25,14],
[80,26,25,16],
[80,26,25,17],
[80,26,25,19],
[80,28,3,0],
[80,28,3,1],
[80,28,3,2],
[80,28,4,0],
[80,28,4,1],
[80,28,4,2],
[80,28,4,3],
[80,28,5,0],
[80,28,5,1],
[80,28,5,2],
[80,28,5,4],
[80,28,6,0],
[80,28,6,1],
[80,28,6,2],
[80,28,6,4],
[80,28,8,0],
[80,28,8,1],
[80,28,8,2],
[80,28,8,4],
[80,28,9,0],
[80,28,9,1],
[80,28,9,2],
[80,28,9,3],
[80,28,9,4],
[80,28,9,5],
[80,28,9,6],
[80,28,9,8],
[80,28,11,0],
[80,28,11,1],
[80,28,11,2],
[80,28,11,3],
[80,28,11,4],
[80,28,11,5],
[80,28,11,6],
[80,28,11,8],
[80,28,11,9],
[80,28,12,0],
[80,28,12,1],
[80,28,12,2],
[80,28,12,3],
[80,28,12,4],
[80,28,12,5],
[80,28,12,6],
[80,28,12,8],
[80,28,12,9],
[80,28,13,0],
[80,28,13,1],
[80,28,13,2],
[80,28,13,3],
[80,28,13,4],
[80,28,13,5],
[80,28,13,6],
[80,28,13,8],
[80,28,13,9],
[80,28,13,11],
[80,28,13,12],
[80,28,14,0],
[80,28,14,1],
[80,28,14,2],
[80,28,14,3],
[80,28,14,4],
[80,28,14,5],
[80,28,14,6],
[80,28,14,8],
[80,28,14,9],
[80,28,14,11],
[80,28,14,12],
[80,28,15,0],
[80,28,15,1],
[80,28,15,2],
[80,28,15,3],
[80,28,15,4],
[80,28,15,5],
[80,28,15,6],
[80,28,15,8],
[80,28,15,11],
[80,28,15,14],
[80,28,16,0],
[80,28,16,1],
[80,28,16,2],
[80,28,16,3],
[80,28,16,4],
[80,28,16,5],
[80,28,16,6],
[80,28,16,8],
[80,28,16,9],
[80,28,16,12],
[80,28,16,13],
[80,28,16,14],
[80,28,16,15],
[80,28,17,0],
[80,28,17,1],
[80,28,17,2],
[80,28,17,3],
[80,28,17,4],
[80,28,17,5],
[80,28,17,6],
[80,28,17,8],
[80,28,17,9],
[80,28,17,12],
[80,28,17,13],
[80,28,17,14],
[80,28,17,15],
[80,28,17,16],
[80,28,18,0],
[80,28,18,1],
[80,28,18,2],
[80,28,18,3],
[80,28,18,4],
[80,28,18,5],
[80,28,18,6],
[80,28,18,11],
[80,28,18,12],
[80,28,18,13],
[80,28,18,14],
[80,28,18,16],
[80,28,18,17],
[80,28,19,0],
[80,28,19,1],
[80,28,19,2],
[80,28,19,3],
[80,28,19,4],
[80,28,19,5],
[80,28,19,8],
[80,28,19,9],
[80,28,19,11],
[80,28,19,12],
[80,28,19,15],
[80,28,19,16],
[80,28,19,17],
[80,28,19,18],
[80,28,21,0],
[80,28,21,1],
[80,28,21,2],
[80,28,21,9],
[80,28,21,11],
[80,28,21,12],
[80,28,21,13],
[80,28,21,14],
[80,28,21,15],
[80,28,21,16],
[80,28,21,17],
[80,28,21,18],
[80,28,21,19],
[80,28,23,0],
[80,28,23,1],
[80,28,23,2],
[80,28,23,3],
[80,28,23,4],
[80,28,23,5],
[80,28,23,6],
[80,28,23,8],
[80,28,23,9],
[80,28,23,11],
[80,28,23,12],
[80,28,23,13],
[80,28,23,14],
[80,28,23,15],
[80,28,23,16],
[80,28,23,17],
[80,28,23,18],
[80,28,23,19],
[80,28,24,0],
[80,28,24,1],
[80,28,24,2],
[80,28,24,3],
[80,28,24,4],
[80,28,24,5],
[80,28,24,6],
[80,28,24,8],
[80,28,24,9],
[80,28,24,11],
[80,28,24,12],
[80,28,24,13],
[80,28,24,14],
[80,28,24,15],
[80,28,24,17],
[80,28,24,18],
[80,28,24,19],
[80,28,26,0],
[80,28,26,1],
[80,28,26,2],
[80,28,26,3],
[80,28,26,4],
[80,28,26,5],
[80,28,26,6],
[80,28,26,8],
[80,28,26,11],
[80,28,26,12],
[80,28,26,13],
[80,28,26,14],
[80,29,3,0],
[80,29,3,1],
[80,29,3,2],
[80,29,4,0],
[80,29,4,1],
[80,29,4,2],
[80,29,4,3],
[80,29,5,0],
[80,29,5,1],
[80,29,5,2],
[80,29,5,4],
[80,29,6,0],
[80,29,6,1],
[80,29,6,2],
[80,29,6,4],
[80,29,7,0],
[80,29,7,1],
[80,29,7,2],
[80,29,7,3],
[80,29,7,4],
[80,29,7,5],
[80,29,7,6],
[80,29,8,0],
[80,29,8,1],
[80,29,8,2],
[80,29,8,4],
[80,29,8,7],
[80,29,9,0],
[80,29,9,1],
[80,29,9,2],
[80,29,9,3],
[80,29,9,4],
[80,29,9,5],
[80,29,9,6],
[80,29,9,7],
[80,29,9,8],
[80,29,13,0],
[80,29,13,1],
[80,29,13,2],
[80,29,13,3],
[80,29,13,4],
[80,29,13,5],
[80,29,13,6],
[80,29,13,7],
[80,29,13,8],
[80,29,13,9],
[80,29,14,0],
[80,29,14,1],
[80,29,14,2],
[80,29,14,3],
[80,29,14,4],
[80,29,14,5],
[80,29,14,6],
[80,29,14,7],
[80,29,14,8],
[80,29,14,9],
[80,29,15,0],
[80,29,15,1],
[80,29,15,2],
[80,29,15,3],
[80,29,15,4],
[80,29,15,5],
[80,29,15,6],
[80,29,15,7],
[80,29,15,8],
[80,29,15,13],
[80,29,15,14],
[80,29,16,0],
[80,29,16,1],
[80,29,16,2],
[80,29,16,3],
[80,29,16,4],
[80,29,16,5],
[80,29,16,6],
[80,29,16,7],
[80,29,16,13],
[80,29,16,14],
[80,29,16,15],
[80,29,17,0],
[80,29,17,1],
[80,29,17,2],
[80,29,17,3],
[80,29,17,4],
[80,29,17,5],
[80,29,17,6],
[80,29,17,7],
[80,29,17,13],
[80,29,17,14],
[80,29,17,15],
[80,29,17,16],
[80,29,18,0],
[80,29,18,1],
[80,29,18,2],
[80,29,18,3],
[80,29,18,4],
[80,29,18,5],
[80,29,18,8],
[80,29,18,13],
[80,29,18,14],
[80,29,18,16],
[80,29,18,17],
[80,29,19,0],
[80,29,19,1],
[80,29,19,2],
[80,29,19,3],
[80,29,19,4],
[80,29,19,6],
[80,29,19,7],
[80,29,19,8],
[80,29,19,9],
[80,29,19,15],
[80,29,19,16],
[80,29,19,17],
[80,29,19,18],
[80,29,21,4],
[80,29,21,7],
[80,29,21,9],
[80,29,21,13],
[80,29,21,14],
[80,29,21,15],
[80,29,21,16],
[80,29,21,17],
[80,29,21,18],
[80,29,21,19],
[80,29,23,0],
[80,29,23,1],
[80,29,23,2],
[80,29,23,3],
[80,29,23,4],
[80,29,23,5],
[80,29,23,6],
[80,29,23,7],
[80,29,23,8],
[80,29,23,9],
[80,29,23,13],
[80,29,23,14],
[80,29,23,15],
[80,29,23,16],
[80,29,23,17],
[80,29,23,18],
[80,29,24,0],
[80,29,24,1],
[80,29,24,2],
[80,29,24,3],
[80,29,24,4],
[80,29,24,5],
[80,29,24,6],
[80,29,24,7],
[80,29,24,8],
[80,29,24,9],
[80,29,24,13],
[80,29,24,14],
[80,29,24,15],
[80,29,24,17],
[80,29,24,18],
[80,29,25,0],
[80,29,25,1],
[80,29,25,2],
[80,29,25,3],
[80,29,25,4],
[80,29,25,5],
[80,29,25,6],
[80,29,25,8],
[80,29,25,9],
[80,29,25,13],
[80,29,25,14],
[80,29,25,15],
[80,29,26,0],
[80,29,26,1],
[80,29,26,2],
[80,29,26,3],
[80,29,26,4],
[80,29,26,5],
[80,29,26,6],
[80,29,26,7],
[80,29,26,8],
[80,29,26,13],
[80,29,26,14],
[80,29,28,0],
[80,29,28,1],
[80,29,28,2],
[80,29,28,3],
[80,29,28,4],
[80,29,28,5],
[80,29,28,6],
[80,29,28,8],
[80,29,28,9],
[80,30,3,0],
[80,30,3,1],
[80,30,3,2],
[80,30,4,0],
[80,30,4,1],
[80,30,4,2],
[80,30,4,3],
[80,30,5,0],
[80,30,5,1],
[80,30,5,2],
[80,30,5,4],
[80,30,6,0],
[80,30,6,1],
[80,30,6,2],
[80,30,6,4],
[80,30,7,0],
[80,30,7,1],
[80,30,7,2],
[80,30,7,3],
[80,30,7,4],
[80,30,7,5],
[80,30,7,6],
[80,30,8,0],
[80,30,8,1],
[80,30,8,2],
[80,30,8,4],
[80,30,8,7],
[80,30,9,0],
[80,30,9,1],
[80,30,9,2],
[80,30,9,3],
[80,30,9,4],
[80,30,9,5],
[80,30,9,6],
[80,30,9,7],
[80,30,9,8],
[80,30,11,0],
[80,30,11,1],
[80,30,11,2],
[80,30,11,3],
[80,30,11,4],
[80,30,11,5],
[80,30,11,6],
[80,30,11,7],
[80,30,11,8],
[80,30,11,9],
[80,30,12,0],
[80,30,12,1],
[80,30,12,2],
[80,30,12,3],
[80,30,12,4],
[80,30,12,5],
[80,30,12,6],
[80,30,12,7],
[80,30,12,8],
[80,30,12,9],
[80,30,13,0],
[80,30,13,1],
[80,30,13,2],
[80,30,13,3],
[80,30,13,4],
[80,30,13,5],
[80,30,13,6],
[80,30,13,7],
[80,30,13,8],
[80,30,13,9],
[80,30,13,11],
[80,30,14,0],
[80,30,14,1],
[80,30,14,2],
[80,30,14,3],
[80,30,14,4],
[80,30,14,5],
[80,30,14,6],
[80,30,14,7],
[80,30,14,8],
[80,30,14,9],
[80,30,14,12],
[80,30,15,0],
[80,30,15,1],
[80,30,15,2],
[80,30,15,3],
[80,30,15,4],
[80,30,15,5],
[80,30,15,6],
[80,30,15,7],
[80,30,15,11],
[80,30,15,12],
[80,30,15,13],
[80,30,15,14],
[80,30,16,0],
[80,30,16,1],
[80,30,16,2],
[80,30,16,3],
[80,30,16,4],
[80,30,16,5],
[80,30,16,8],
[80,30,16,9],
[80,30,16,11],
[80,30,16,12],
[80,30,16,13],
[80,30,16,14],
[80,30,16,15],
[80,30,17,0],
[80,30,17,1],
[80,30,17,2],
[80,30,17,3],
[80,30,17,4],
[80,30,17,5],
[80,30,17,8],
[80,30,17,9],
[80,30,17,11],
[80,30,17,12],
[80,30,17,13],
[80,30,17,14],
[80,30,17,15],
[80,30,17,16],
[80,30,18,0],
[80,30,18,1],
[80,30,18,2],
[80,30,18,3],
[80,30,18,4],
[80,30,18,6],
[80,30,18,7],
[80,30,18,8],
[80,30,18,11],
[80,30,18,12],
[80,30,18,13],
[80,30,18,14],
[80,30,18,16],
[80,30,18,17],
[80,30,19,0],
[80,30,19,1],
[80,30,19,2],
[80,30,19,5],
[80,30,19,6],
[80,30,19,7],
[80,30,19,8],
[80,30,19,9],
[80,30,19,11],
[80,30,19,12],
[80,30,19,15],
[80,30,19,16],
[80,30,19,17],
[80,30,19,18],
[80,30,21,0],
[80,30,21,1],
[80,30,21,2],
[80,30,21,4],
[80,30,21,7],
[80,30,21,9],
[80,30,21,11],
[80,30,21,12],
[80,30,21,13],
[80,30,21,14],
[80,30,21,15],
[80,30,21,16],
[80,30,21,17],
[80,30,21,18],
[80,30,21,19],
[80,30,24,0],
[80,30,24,1],
[80,30,24,2],
[80,30,24,3],
[80,30,24,4],
[80,30,24,5],
[80,30,24,6],
[80,30,24,7],
[80,30,24,8],
[80,30,24,9],
[80,30,24,11],
[80,30,24,12],
[80,30,24,13],
[80,30,24,14],
[80,30,24,15],
[80,30,24,17],
[80,30,25,0],
[80,30,25,1],
[80,30,25,2],
[80,30,25,3],
[80,30,25,4],
[80,30,25,5],
[80,30,25,6],
[80,30,25,8],
[80,30,25,9],
[80,30,25,11],
[80,30,25,12],
[80,30,25,13],
[80,30,25,14],
[80,30,26,0],
[80,30,26,1],
[80,30,26,2],
[80,30,26,3],
[80,30,26,4],
[80,30,26,5],
[80,30,26,6],
[80,30,26,7],
[80,30,26,8],
[80,30,26,11],
[80,30,26,12],
[80,30,26,13],
[80,30,28,0],
[80,30,28,1],
[80,30,28,2],
[80,30,28,3],
[80,30,28,4],
[80,30,28,5],
[80,30,28,6],
[80,30,28,8],
[80,30,28,9],
[80,30,29,0],
[80,30,29,1],
[80,30,29,2],
[80,30,29,3],
[80,30,29,4],
[80,30,29,5],
[80,30,29,6],
[80,30,29,7],
[80,31,3,0],
[80,31,3,1],
[80,31,3,2],
[80,31,4,0],
[80,31,4,1],
[80,31,4,2],
[80,31,4,3],
[80,31,5,0],
[80,31,5,1],
[80,31,5,2],
[80,31,5,4],
[80,31,6,0],
[80,31,6,1],
[80,31,6,2],
[80,31,6,4],
[80,31,7,0],
[80,31,7,1],
[80,31,7,2],
[80,31,7,3],
[80,31,7,4],
[80,31,7,5],
[80,31,7,6],
[80,31,8,0],
[80,31,8,1],
[80,31,8,2],
[80,31,8,4],
[80,31,8,7],
[80,31,9,0],
[80,31,9,1],
[80,31,9,2],
[80,31,9,3],
[80,31,9,4],
[80,31,9,5],
[80,31,9,6],
[80,31,9,7],
[80,31,9,8],
[80,31,11,0],
[80,31,11,1],
[80,31,11,2],
[80,31,11,3],
[80,31,11,4],
[80,31,11,5],
[80,31,11,6],
[80,31,11,7],
[80,31,11,8],
[80,31,11,9],
[80,31,12,0],
[80,31,12,1],
[80,31,12,2],
[80,31,12,3],
[80,31,12,4],
[80,31,12,5],
[80,31,12,6],
[80,31,12,7],
[80,31,12,8],
[80,31,12,9],
[80,31,13,0],
[80,31,13,1],
[80,31,13,2],
[80,31,13,3],
[80,31,13,4],
[80,31,13,5],
[80,31,13,6],
[80,31,13,7],
[80,31,13,8],
[80,31,13,9],
[80,31,13,12],
[80,31,14,0],
[80,31,14,1],
[80,31,14,2],
[80,31,14,3],
[80,31,14,4],
[80,31,14,5],
[80,31,14,6],
[80,31,14,7],
[80,31,14,11],
[80,31,14,12],
[80,31,15,0],
[80,31,15,1],
[80,31,15,2],
[80,31,15,3],
[80,31,15,4],
[80,31,15,5],
[80,31,15,8],
[80,31,15,11],
[80,31,15,12],
[80,31,15,13],
[80,31,15,14],
[80,31,16,0],
[80,31,16,1],
[80,31,16,2],
[80,31,16,3],
[80,31,16,4],
[80,31,16,6],
[80,31,16,7],
[80,31,16,8],
[80,31,16,9],
[80,31,16,11],
[80,31,16,12],
[80,31,16,13],
[80,31,16,14],
[80,31,16,15],
[80,31,17,0],
[80,31,17,1],
[80,31,17,2],
[80,31,17,3],
[80,31,17,4],
[80,31,17,6],
[80,31,17,7],
[80,31,17,8],
[80,31,17,9],
[80,31,17,11],
[80,31,17,12],
[80,31,17,13],
[80,31,17,14],
[80,31,17,15],
[80,31,17,16],
[80,31,18,0],
[80,31,18,1],
[80,31,18,2],
[80,31,18,5],
[80,31,18,6],
[80,31,18,7],
[80,31,18,8],
[80,31,18,11],
[80,31,18,12],
[80,31,18,13],
[80,31,18,14],
[80,31,18,16],
[80,31,18,17],
[80,31,19,3],
[80,31,19,4],
[80,31,19,5],
[80,31,19,6],
[80,31,19,7],
[80,31,19,8],
[80,31,19,9],
[80,31,19,11],
[80,31,19,12],
[80,31,19,15],
[80,31,19,16],
[80,31,19,17],
[80,31,19,18],
[80,31,21,0],
[80,31,21,1],
[80,31,21,2],
[80,31,21,4],
[80,31,21,7],
[80,31,21,9],
[80,31,21,11],
[80,31,21,12],
[80,31,21,13],
[80,31,21,14],
[80,31,21,15],
[80,31,21,16],
[80,31,21,17],
[80,31,21,18],
[80,31,24,0],
[80,31,24,1],
[80,31,24,2],
[80,31,24,3],
[80,31,24,4],
[80,31,24,5],
[80,31,24,6],
[80,31,24,7],
[80,31,24,8],
[80,31,24,9],
[80,31,24,11],
[80,31,24,12],
[80,31,24,13],
[80,31,24,14],
[80,31,24,15],
[80,31,25,0],
[80,31,25,1],
[80,31,25,2],
[80,31,25,3],
[80,31,25,4],
[80,31,25,5],
[80,31,25,6],
[80,31,25,8],
[80,31,25,9],
[80,31,25,11],
[80,31,25,12],
[80,31,25,13],
[80,31,26,0],
[80,31,26,1],
[80,31,26,2],
[80,31,26,3],
[80,31,26,4],
[80,31,26,5],
[80,31,26,6],
[80,31,26,7],
[80,31,26,8],
[80,31,26,11],
[80,31,28,0],
[80,31,28,1],
[80,31,28,2],
[80,31,28,3],
[80,31,28,4],
[80,31,28,5],
[80,31,28,6],
[80,31,29,0],
[80,31,29,1],
[80,31,29,2],
[80,31,29,3],
[80,31,29,4],
[80,31,29,5],
[80,32,3,0],
[80,32,3,1],
[80,32,3,2],
[80,32,4,0],
[80,32,4,1],
[80,32,4,2],
[80,32,4,3],
[80,32,5,0],
[80,32,5,1],
[80,32,5,2],
[80,32,5,4],
[80,32,6,0],
[80,32,6,1],
[80,32,6,2],
[80,32,6,4],
[80,32,7,0],
[80,32,7,1],
[80,32,7,2],
[80,32,7,3],
[80,32,7,4],
[80,32,7,5],
[80,32,7,6],
[80,32,8,0],
[80,32,8,1],
[80,32,8,2],
[80,32,8,4],
[80,32,8,7],
[80,32,9,0],
[80,32,9,1],
[80,32,9,2],
[80,32,9,3],
[80,32,9,4],
[80,32,9,5],
[80,32,9,6],
[80,32,9,7],
[80,32,9,8],
[80,32,13,0],
[80,32,13,1],
[80,32,13,2],
[80,32,13,3],
[80,32,13,4],
[80,32,13,5],
[80,32,13,6],
[80,32,13,7],
[80,32,14,0],
[80,32,14,1],
[80,32,14,2],
[80,32,14,3],
[80,32,14,4],
[80,32,14,5],
[80,32,14,8],
[80,32,14,9],
[80,32,15,0],
[80,32,15,1],
[80,32,15,2],
[80,32,15,3],
[80,32,15,4],
[80,32,15,6],
[80,32,15,7],
[80,32,15,8],
[80,32,15,13],
[80,32,15,14],
[80,32,16,0],
[80,32,16,1],
[80,32,16,2],
[80,32,16,5],
[80,32,16,6],
[80,32,16,7],
[80,32,16,8],
[80,32,16,9],
[80,32,16,13],
[80,32,16,14],
[80,32,16,15],
[80,32,17,0],
[80,32,17,1],
[80,32,17,2],
[80,32,17,5],
[80,32,17,6],
[80,32,17,7],
[80,32,17,8],
[80,32,17,9],
[80,32,17,13],
[80,32,17,14],
[80,32,17,15],
[80,32,17,16],
[80,32,18,3],
[80,32,18,4],
[80,32,18,5],
[80,32,18,6],
[80,32,18,7],
[80,32,18,8],
[80,32,18,13],
[80,32,18,14],
[80,32,18,16],
[80,32,18,17],
[80,32,19,0],
[80,32,19,1],
[80,32,19,2],
[80,32,19,3],
[80,32,19,4],
[80,32,19,5],
[80,32,19,6],
[80,32,19,7],
[80,32,19,8],
[80,32,19,9],
[80,32,19,15],
[80,32,19,16],
[80,32,19,17],
[80,32,19,18],
[80,32,21,0],
[80,32,21,1],
[80,32,21,2],
[80,32,21,4],
[80,32,21,7],
[80,32,21,9],
[80,32,21,13],
[80,32,21,14],
[80,32,21,15],
[80,32,21,16],
[80,32,21,17],
[80,32,23,0],
[80,32,23,1],
[80,32,23,2],
[80,32,23,3],
[80,32,23,4],
[80,32,23,5],
[80,32,23,6],
[80,32,23,7],
[80,32,23,8],
[80,32,23,9],
[80,32,23,13],
[80,32,23,14],
[80,32,24,0],
[80,32,24,1],
[80,32,24,2],
[80,32,24,3],
[80,32,24,4],
[80,32,24,5],
[80,32,24,6],
[80,32,24,7],
[80,32,24,8],
[80,32,24,9],
[80,32,24,13],
[80,32,24,14],
[80,32,25,0],
[80,32,25,1],
[80,32,25,2],
[80,32,25,3],
[80,32,25,4],
[80,32,25,5],
[80,32,25,6],
[80,32,25,8],
[80,32,25,9],
[80,32,26,0],
[80,32,26,1],
[80,32,26,2],
[80,32,26,3],
[80,32,26,4],
[80,32,26,5],
[80,32,26,6],
[80,32,26,7],
[80,32,26,8],
[80,32,28,0],
[80,32,28,1],
[80,32,28,2],
[80,32,28,3],
[80,32,28,4],
[80,32,28,5],
[80,32,30,0],
[80,32,30,1],
[80,32,30,2],
[80,33,3,0],
[80,33,3,1],
[80,33,3,2],
[80,33,4,0],
[80,33,4,1],
[80,33,4,2],
[80,33,4,3],
[80,33,5,0],
[80,33,5,1],
[80,33,5,2],
[80,33,5,4],
[80,33,6,0],
[80,33,6,1],
[80,33,6,2],
[80,33,6,4],
[80,33,7,0],
[80,33,7,1],
[80,33,7,2],
[80,33,7,3],
[80,33,7,4],
[80,33,7,5],
[80,33,7,6],
[80,33,8,0],
[80,33,8,1],
[80,33,8,2],
[80,33,8,4],
[80,33,8,7],
[80,33,9,0],
[80,33,9,1],
[80,33,9,2],
[80,33,9,3],
[80,33,9,4],
[80,33,9,5],
[80,33,9,6],
[80,33,9,7],
[80,33,9,8],
[80,33,13,0],
[80,33,13,1],
[80,33,13,2],
[80,33,13,3],
[80,33,13,4],
[80,33,13,5],
[80,33,13,8],
[80,33,13,9],
[80,33,14,0],
[80,33,14,1],
[80,33,14,2],
[80,33,14,3],
[80,33,14,4],
[80,33,14,6],
[80,33,14,7],
[80,33,14,8],
[80,33,14,9],
[80,33,15,0],
[80,33,15,1],
[80,33,15,2],
[80,33,15,5],
[80,33,15,6],
[80,33,15,7],
[80,33,15,8],
[80,33,15,13],
[80,33,15,14],
[80,33,16,3],
[80,33,16,4],
[80,33,16,5],
[80,33,16,6],
[80,33,16,7],
[80,33,16,8],
[80,33,16,9],
[80,33,16,13],
[80,33,16,14],
[80,33,16,15],
[80,33,17,3],
[80,33,17,4],
[80,33,17,5],
[80,33,17,6],
[80,33,17,7],
[80,33,17,8],
[80,33,17,9],
[80,33,17,13],
[80,33,17,14],
[80,33,17,15],
[80,33,17,16],
[80,33,18,0],
[80,33,18,1],
[80,33,18,2],
[80,33,18,3],
[80,33,18,4],
[80,33,18,5],
[80,33,18,6],
[80,33,18,7],
[80,33,18,8],
[80,33,18,13],
[80,33,18,14],
[80,33,18,16],
[80,33,18,17],
[80,33,19,0],
[80,33,19,1],
[80,33,19,2],
[80,33,19,3],
[80,33,19,4],
[80,33,19,5],
[80,33,19,6],
[80,33,19,7],
[80,33,19,8],
[80,33,19,9],
[80,33,19,15],
[80,33,19,16],
[80,33,19,17],
[80,33,19,18],
[80,33,21,0],
[80,33,21,1],
[80,33,21,2],
[80,33,21,4],
[80,33,21,7],
[80,33,21,9],
[80,33,21,13],
[80,33,21,14],
[80,33,21,15],
[80,33,23,0],
[80,33,23,1],
[80,33,23,2],
[80,33,23,3],
[80,33,23,4],
[80,33,23,5],
[80,33,23,6],
[80,33,23,7],
[80,33,23,8],
[80,33,23,9],
[80,33,23,13],
[80,33,24,0],
[80,33,24,1],
[80,33,24,2],
[80,33,24,3],
[80,33,24,4],
[80,33,24,5],
[80,33,24,6],
[80,33,24,7],
[80,33,24,8],
[80,33,24,9],
[80,33,24,13],
[80,33,25,0],
[80,33,25,1],
[80,33,25,2],
[80,33,25,3],
[80,33,25,4],
[80,33,25,5],
[80,33,25,6],
[80,33,25,8],
[80,33,25,9],
[80,33,26,0],
[80,33,26,1],
[80,33,26,2],
[80,33,26,3],
[80,33,26,4],
[80,33,26,5],
[80,33,26,6],
[80,33,26,7],
[80,33,28,0],
[80,33,28,1],
[80,33,28,2],
[80,33,28,3],
[80,33,28,4],
[80,34,3,0],
[80,34,3,1],
[80,34,3,2],
[80,34,4,0],
[80,34,4,1],
[80,34,4,2],
[80,34,4,3],
[80,34,5,0],
[80,34,5,1],
[80,34,5,2],
[80,34,5,4],
[80,34,6,0],
[80,34,6,1],
[80,34,6,2],
[80,34,6,4],
[80,34,8,0],
[80,34,8,1],
[80,34,8,2],
[80,34,8,4],
[80,34,9,0],
[80,34,9,1],
[80,34,9,2],
[80,34,9,3],
[80,34,9,4],
[80,34,9,5],
[80,34,9,6],
[80,34,11,0],
[80,34,11,1],
[80,34,11,2],
[80,34,11,3],
[80,34,11,4],
[80,34,11,5],
[80,34,11,8],
[80,34,11,9],
[80,34,12,0],
[80,34,12,1],
[80,34,12,2],
[80,34,12,3],
[80,34,12,4],
[80,34,12,6],
[80,34,12,8],
[80,34,12,9],
[80,34,13,0],
[80,34,13,1],
[80,34,13,2],
[80,34,13,3],
[80,34,13,4],
[80,34,13,6],
[80,34,13,8],
[80,34,13,9],
[80,34,13,11],
[80,34,13,12],
[80,34,14,0],
[80,34,14,1],
[80,34,14,2],
[80,34,14,5],
[80,34,14,6],
[80,34,14,8],
[80,34,14,9],
[80,34,14,11],
[80,34,14,12],
[80,34,15,3],
[80,34,15,4],
[80,34,15,5],
[80,34,15,6],
[80,34,15,8],
[80,34,15,11],
[80,34,15,12],
[80,34,15,13],
[80,34,15,14],
[80,34,16,0],
[80,34,16,1],
[80,34,16,2],
[80,34,16,3],
[80,34,16,4],
[80,34,16,5],
[80,34,16,6],
[80,34,16,8],
[80,34,16,9],
[80,34,16,11],
[80,34,16,12],
[80,34,16,13],
[80,34,16,14],
[80,34,16,15],
[80,34,17,0],
[80,34,17,1],
[80,34,17,2],
[80,34,17,3],
[80,34,17,4],
[80,34,17,5],
[80,34,17,6],
[80,34,17,8],
[80,34,17,9],
[80,34,17,11],
[80,34,17,12],
[80,34,17,13],
[80,34,17,14],
[80,34,17,15],
[80,34,17,16],
[80,34,18,0],
[80,34,18,1],
[80,34,18,2],
[80,34,18,3],
[80,34,18,4],
[80,34,18,5],
[80,34,18,6],
[80,34,18,8],
[80,34,18,11],
[80,34,18,12],
[80,34,18,13],
[80,34,18,14],
[80,34,18,16],
[80,34,18,17],
[80,34,19,0],
[80,34,19,1],
[80,34,19,2],
[80,34,19,3],
[80,34,19,4],
[80,34,19,5],
[80,34,19,6],
[80,34,19,8],
[80,34,19,9],
[80,34,19,11],
[80,34,19,12],
[80,34,19,15],
[80,34,19,16],
[80,34,19,17],
[80,34,21,0],
[80,34,21,1],
[80,34,21,2],
[80,34,21,4],
[80,34,21,9],
[80,34,21,11],
[80,34,21,12],
[80,34,21,13],
[80,34,21,14],
[80,34,23,0],
[80,34,23,1],
[80,34,23,2],
[80,34,23,3],
[80,34,23,4],
[80,34,23,5],
[80,34,23,6],
[80,34,23,8],
[80,34,23,9],
[80,34,23,11],
[80,34,24,0],
[80,34,24,1],
[80,34,24,2],
[80,34,24,3],
[80,34,24,4],
[80,34,24,5],
[80,34,24,6],
[80,34,24,8],
[80,34,24,9],
[80,34,24,11],
[80,34,26,0],
[80,34,26,1],
[80,34,26,2],
[80,34,26,3],
[80,34,26,4],
[80,34,26,5],
[80,35,3,0],
[80,35,3,1],
[80,35,3,2],
[80,35,4,0],
[80,35,4,1],
[80,35,4,2],
[80,35,4,3],
[80,35,5,0],
[80,35,5,1],
[80,35,5,2],
[80,35,5,4],
[80,35,6,0],
[80,35,6,1],
[80,35,6,2],
[80,35,6,4],
[80,35,7,0],
[80,35,7,1],
[80,35,7,2],
[80,35,7,3],
[80,35,7,4],
[80,35,7,5],
[80,35,7,6],
[80,35,8,0],
[80,35,8,1],
[80,35,8,2],
[80,35,8,4],
[80,35,11,0],
[80,35,11,1],
[80,35,11,2],
[80,35,11,3],
[80,35,11,4],
[80,35,11,6],
[80,35,11,7],
[80,35,11,8],
[80,35,12,0],
[80,35,12,1],
[80,35,12,2],
[80,35,12,5],
[80,35,12,6],
[80,35,12,7],
[80,35,12,8],
[80,35,13,0],
[80,35,13,1],
[80,35,13,2],
[80,35,13,5],
[80,35,13,6],
[80,35,13,7],
[80,35,13,8],
[80,35,13,11],
[80,35,13,12],
[80,35,14,3],
[80,35,14,4],
[80,35,14,5],
[80,35,14,6],
[80,35,14,7],
[80,35,14,8],
[80,35,14,11],
[80,35,14,12],
[80,35,16,0],
[80,35,16,1],
[80,35,16,2],
[80,35,16,3],
[80,35,16,4],
[80,35,16,5],
[80,35,16,6],
[80,35,16,7],
[80,35,16,8],
[80,35,16,11],
[80,35,16,12],
[80,35,16,13],
[80,35,16,14],
[80,35,17,0],
[80,35,17,1],
[80,35,17,2],
[80,35,17,3],
[80,35,17,4],
[80,35,17,5],
[80,35,17,6],
[80,35,17,7],
[80,35,17,8],
[80,35,17,11],
[80,35,17,12],
[80,35,17,13],
[80,35,17,14],
[80,35,17,16],
[80,35,19,0],
[80,35,19,1],
[80,35,19,2],
[80,35,19,3],
[80,35,19,4],
[80,35,19,5],
[80,35,19,6],
[80,35,19,7],
[80,35,19,8],
[80,35,19,11],
[80,35,19,12],
[80,35,21,0],
[80,35,21,1],
[80,35,21,2],
[80,35,21,4],
[80,35,21,7],
[80,35,21,11],
[80,35,21,12],
[80,35,21,13],
[80,35,23,0],
[80,35,23,1],
[80,35,23,2],
[80,35,23,3],
[80,35,23,4],
[80,35,23,5],
[80,35,23,6],
[80,35,23,7],
[80,35,23,8],
[80,35,24,0],
[80,35,24,1],
[80,35,24,2],
[80,35,24,3],
[80,35,24,4],
[80,35,24,5],
[80,35,24,6],
[80,35,24,7],
[80,35,24,8],
[80,35,25,0],
[80,35,25,1],
[80,35,25,2],
[80,35,25,3],
[80,35,25,4],
[80,35,25,5],
[80,36,3,0],
[80,36,3,1],
[80,36,3,2],
[80,36,4,0],
[80,36,4,1],
[80,36,4,2],
[80,36,4,3],
[80,36,5,0],
[80,36,5,1],
[80,36,5,2],
[80,36,5,4],
[80,36,6,0],
[80,36,6,1],
[80,36,6,2],
[80,36,6,4],
[80,36,7,0],
[80,36,7,1],
[80,36,7,2],
[80,36,7,3],
[80,36,7,4],
[80,36,7,5],
[80,36,8,0],
[80,36,8,1],
[80,36,8,2],
[80,36,8,4],
[80,36,8,7],
[80,36,9,0],
[80,36,9,1],
[80,36,9,2],
[80,36,9,3],
[80,36,9,4],
[80,36,9,6],
[80,36,9,7],
[80,36,9,8],
[80,36,11,0],
[80,36,11,1],
[80,36,11,2],
[80,36,11,5],
[80,36,11,6],
[80,36,11,7],
[80,36,11,8],
[80,36,11,9],
[80,36,12,3],
[80,36,12,4],
[80,36,12,5],
[80,36,12,6],
[80,36,12,7],
[80,36,12,8],
[80,36,12,9],
[80,36,15,0],
[80,36,15,1],
[80,36,15,2],
[80,36,15,3],
[80,36,15,4],
[80,36,15,5],
[80,36,15,6],
[80,36,15,7],
[80,36,15,8],
[80,36,15,11],
[80,36,15,12],
[80,36,16,0],
[80,36,16,1],
[80,36,16,2],
[80,36,16,3],
[80,36,16,4],
[80,36,16,5],
[80,36,16,6],
[80,36,16,7],
[80,36,16,8],
[80,36,16,9],
[80,36,16,11],
[80,36,16,12],
[80,36,16,15],
[80,36,17,0],
[80,36,17,1],
[80,36,17,2],
[80,36,17,3],
[80,36,17,4],
[80,36,17,5],
[80,36,17,6],
[80,36,17,7],
[80,36,17,8],
[80,36,17,9],
[80,36,17,11],
[80,36,17,12],
[80,36,17,15],
[80,36,17,16],
[80,36,18,0],
[80,36,18,1],
[80,36,18,2],
[80,36,18,3],
[80,36,18,4],
[80,36,18,5],
[80,36,18,6],
[80,36,18,7],
[80,36,18,8],
[80,36,18,11],
[80,36,18,12],
[80,36,21,0],
[80,36,21,1],
[80,36,21,2],
[80,36,21,4],
[80,36,21,7],
[80,36,21,9],
[80,36,21,11],
[80,36,23,0],
[80,36,23,1],
[80,36,23,2],
[80,36,23,3],
[80,36,23,4],
[80,36,23,5],
[80,36,23,6],
[80,36,23,7],
[80,36,24,0],
[80,36,24,1],
[80,36,24,2],
[80,36,24,3],
[80,36,24,4],
[80,36,24,5],
[80,36,24,6],
[80,36,24,7],
[80,36,25,0],
[80,36,25,1],
[80,36,25,2],
[80,36,25,3],
[80,36,25,4],
[80,36,26,0],
[80,36,26,1],
[80,36,26,2],
[80,37,3,0],
[80,37,3,1],
[80,37,3,2],
[80,37,5,0],
[80,37,5,1],
[80,37,5,2],
[80,37,6,0],
[80,37,6,1],
[80,37,6,2],
[80,37,7,0],
[80,37,7,1],
[80,37,7,2],
[80,37,7,3],
[80,37,7,6],
[80,37,8,0],
[80,37,8,1],
[80,37,8,2],
[80,37,8,7],
[80,37,9,0],
[80,37,9,1],
[80,37,9,2],
[80,37,9,5],
[80,37,9,6],
[80,37,9,7],
[80,37,9,8],
[80,37,11,3],
[80,37,11,5],
[80,37,11,6],
[80,37,11,7],
[80,37,11,8],
[80,37,11,9],
[80,37,12,0],
[80,37,12,1],
[80,37,12,2],
[80,37,12,3],
[80,37,12,5],
[80,37,12,6],
[80,37,12,7],
[80,37,12,8],
[80,37,12,9],
[80,37,13,0],
[80,37,13,1],
[80,37,13,2],
[80,37,13,3],
[80,37,13,5],
[80,37,13,6],
[80,37,13,7],
[80,37,13,8],
[80,37,13,9],
[80,37,13,11],
[80,37,13,12],
[80,37,14,0],
[80,37,14,1],
[80,37,14,2],
[80,37,14,3],
[80,37,14,5],
[80,37,14,6],
[80,37,14,7],
[80,37,14,8],
[80,37,14,9],
[80,37,14,11],
[80,37,14,12],
[80,37,15,0],
[80,37,15,1],
[80,37,15,2],
[80,37,15,3],
[80,37,15,5],
[80,37,15,6],
[80,37,15,7],
[80,37,15,8],
[80,37,15,11],
[80,37,15,12],
[80,37,15,13],
[80,37,15,14],
[80,37,16,0],
[80,37,16,1],
[80,37,16,2],
[80,37,16,3],
[80,37,16,5],
[80,37,16,6],
[80,37,16,7],
[80,37,16,8],
[80,37,16,9],
[80,37,16,11],
[80,37,16,12],
[80,37,16,13],
[80,37,16,14],
[80,37,16,15],
[80,37,17,0],
[80,37,17,1],
[80,37,17,2],
[80,37,17,3],
[80,37,17,5],
[80,37,17,6],
[80,37,17,7],
[80,37,17,8],
[80,37,17,9],
[80,37,17,11],
[80,37,17,12],
[80,37,17,13],
[80,37,17,14],
[80,37,17,15],
[80,37,18,0],
[80,37,18,1],
[80,37,18,2],
[80,37,18,3],
[80,37,18,5],
[80,37,18,6],
[80,37,18,7],
[80,37,18,8],
[80,37,18,11],
[80,37,18,12],
[80,37,18,13],
[80,37,18,14],
[80,37,19,0],
[80,37,19,1],
[80,37,19,2],
[80,37,19,3],
[80,37,19,5],
[80,37,19,6],
[80,37,19,7],
[80,37,19,8],
[80,37,19,9],
[80,37,19,11],
[80,37,19,12],
[80,37,21,0],
[80,37,21,1],
[80,37,21,2],
[80,37,21,7],
[80,37,21,9],
[80,37,23,0],
[80,37,23,1],
[80,37,23,2],
[80,37,23,3],
[80,37,23,5],
[80,37,24,0],
[80,37,24,1],
[80,37,24,2],
[80,37,24,3],
[80,37,24,5],
[80,37,25,0],
[80,37,25,1],
[80,37,25,2],
[80,38,3,0],
[80,38,3,1],
[80,38,3,2],
[80,38,4,0],
[80,38,4,1],
[80,38,4,2],
[80,38,4,3],
[80,38,5,0],
[80,38,5,1],
[80,38,5,2],
[80,38,5,4],
[80,38,6,0],
[80,38,6,1],
[80,38,6,2],
[80,38,8,4],
[80,38,9,3],
[80,38,9,4],
[80,38,9,5],
[80,38,9,6],
[80,38,9,8],
[80,38,11,0],
[80,38,11,1],
[80,38,11,2],
[80,38,11,3],
[80,38,11,4],
[80,38,11,5],
[80,38,11,6],
[80,38,11,8],
[80,38,11,9],
[80,38,12,0],
[80,38,12,1],
[80,38,12,2],
[80,38,12,3],
[80,38,12,4],
[80,38,12,5],
[80,38,12,6],
[80,38,12,8],
[80,38,12,9],
[80,38,13,0],
[80,38,13,1],
[80,38,13,2],
[80,38,13,3],
[80,38,13,4],
[80,38,13,5],
[80,38,13,6],
[80,38,13,8],
[80,38,13,9],
[80,38,13,11],
[80,38,13,12],
[80,38,14,0],
[80,38,14,1],
[80,38,14,2],
[80,38,14,3],
[80,38,14,4],
[80,38,14,5],
[80,38,14,6],
[80,38,14,8],
[80,38,14,9],
[80,38,14,11],
[80,38,14,12],
[80,38,15,0],
[80,38,15,1],
[80,38,15,2],
[80,38,15,3],
[80,38,15,4],
[80,38,15,5],
[80,38,15,6],
[80,38,15,8],
[80,38,15,11],
[80,38,15,12],
[80,38,15,13],
[80,38,15,14],
[80,38,16,0],
[80,38,16,1],
[80,38,16,2],
[80,38,16,3],
[80,38,16,4],
[80,38,16,5],
[80,38,16,6],
[80,38,16,8],
[80,38,16,9],
[80,38,16,11],
[80,38,16,12],
[80,38,16,13],
[80,38,16,14],
[80,38,17,0],
[80,38,17,1],
[80,38,17,2],
[80,38,17,3],
[80,38,17,4],
[80,38,17,5],
[80,38,17,6],
[80,38,17,8],
[80,38,17,9],
[80,38,17,11],
[80,38,17,12],
[80,38,17,13],
[80,38,17,14],
[80,38,18,0],
[80,38,18,1],
[80,38,18,2],
[80,38,18,3],
[80,38,18,4],
[80,38,18,5],
[80,38,18,6],
[80,38,18,8],
[80,38,18,11],
[80,38,18,12],
[80,38,18,13],
[80,38,19,0],
[80,38,19,1],
[80,38,19,2],
[80,38,19,3],
[80,38,19,4],
[80,38,19,5],
[80,38,19,6],
[80,38,19,8],
[80,38,19,9],
[80,38,19,11],
[80,38,21,0],
[80,38,21,1],
[80,38,21,2],
[80,38,21,4],
[80,38,23,0],
[80,38,23,1],
[80,38,23,2],
[80,38,23,3],
[80,38,23,4],
[80,38,24,0],
[80,38,24,1],
[80,38,24,2],
[80,38,24,3],
[80,38,24,4],
[80,39,4,0],
[80,39,4,1],
[80,39,4,2],
[80,39,7,4],
[80,39,9,0],
[80,39,9,1],
[80,39,9,2],
[80,39,9,4],
[80,39,9,7],
[80,39,11,0],
[80,39,11,1],
[80,39,11,2],
[80,39,11,4],
[80,39,11,7],
[80,39,11,9],
[80,39,12,0],
[80,39,12,1],
[80,39,12,2],
[80,39,12,4],
[80,39,12,7],
[80,39,12,9],
[80,39,13,0],
[80,39,13,1],
[80,39,13,2],
[80,39,13,4],
[80,39,13,7],
[80,39,13,9],
[80,39,13,11],
[80,39,13,12],
[80,39,14,0],
[80,39,14,1],
[80,39,14,2],
[80,39,14,4],
[80,39,14,7],
[80,39,14,9],
[80,39,14,11],
[80,39,14,12],
[80,39,15,0],
[80,39,15,1],
[80,39,15,2],
[80,39,15,4],
[80,39,15,7],
[80,39,15,11],
[80,39,15,12],
[80,39,15,13],
[80,39,15,14],
[80,39,16,0],
[80,39,16,1],
[80,39,16,2],
[80,39,16,4],
[80,39,16,7],
[80,39,16,9],
[80,39,16,11],
[80,39,16,12],
[80,39,16,13],
[80,39,17,0],
[80,39,17,1],
[80,39,17,2],
[80,39,17,4],
[80,39,17,7],
[80,39,17,9],
[80,39,17,11],
[80,39,17,12],
[80,39,17,13],
[80,39,18,0],
[80,39,18,1],
[80,39,18,2],
[80,39,18,4],
[80,39,18,7],
[80,39,18,11],
[80,39,19,0],
[80,39,19,1],
[80,39,19,2],
[80,39,19,4],
[80,39,19,7],
[80,39,19,9],
[80,39,23,0],
[80,39,23,1],
[80,39,23,2],
[80,39,24,0],
[80,39,24,1],
[80,39,24,2],
[80,40,3,0],
[80,40,3,1],
[80,40,3,2],
[80,40,4,0],
[80,40,4,1],
[80,40,4,2],
[80,40,5,4],
[80,40,6,0],
[80,40,6,1],
[80,40,6,2],
[80,40,6,4],
[80,40,7,0],
[80,40,7,1],
[80,40,7,2],
[80,40,7,3],
[80,40,7,4],
[80,40,7,5],
[80,40,7,6],
[80,40,8,0],
[80,40,8,1],
[80,40,8,2],
[80,40,8,4],
[80,40,8,7],
[80,40,11,0],
[80,40,11,1],
[80,40,11,2],
[80,40,11,3],
[80,40,11,4],
[80,40,11,5],
[80,40,11,6],
[80,40,11,7],
[80,40,11,8],
[80,40,12,0],
[80,40,12,1],
[80,40,12,2],
[80,40,12,3],
[80,40,12,4],
[80,40,12,5],
[80,40,12,6],
[80,40,12,7],
[80,40,12,8],
[80,40,13,0],
[80,40,13,1],
[80,40,13,2],
[80,40,13,3],
[80,40,13,4],
[80,40,13,5],
[80,40,13,6],
[80,40,13,7],
[80,40,13,8],
[80,40,13,11],
[80,40,13,12],
[80,40,14,0],
[80,40,14,1],
[80,40,14,2],
[80,40,14,3],
[80,40,14,4],
[80,40,14,5],
[80,40,14,6],
[80,40,14,7],
[80,40,14,8],
[80,40,14,11],
[80,40,14,12],
[80,40,16,0],
[80,40,16,1],
[80,40,16,2],
[80,40,16,3],
[80,40,16,4],
[80,40,16,5],
[80,40,16,6],
[80,40,16,7],
[80,40,16,8],
[80,40,16,11],
[80,40,17,0],
[80,40,17,1],
[80,40,17,2],
[80,40,17,3],
[80,40,17,4],
[80,40,17,5],
[80,40,17,6],
[80,40,17,7],
[80,40,17,8],
[80,40,17,11],
[80,40,19,0],
[80,40,19,1],
[80,40,19,2],
[80,40,19,3],
[80,40,19,4],
[80,40,19,5],
[80,40,19,6],
[80,40,19,7],
[80,40,21,0],
[80,40,21,1],
[80,40,21,2],
[80,40,21,4],
[80,41,3,0],
[80,41,3,1],
[80,41,3,2],
[80,41,4,0],
[80,41,4,1],
[80,41,4,2],
[80,41,5,4],
[80,41,6,0],
[80,41,6,1],
[80,41,6,2],
[80,41,6,4],
[80,41,7,0],
[80,41,7,1],
[80,41,7,2],
[80,41,7,3],
[80,41,7,4],
[80,41,7,5],
[80,41,7,6],
[80,41,8,0],
[80,41,8,1],
[80,41,8,2],
[80,41,8,4],
[80,41,8,7],
[80,41,11,0],
[80,41,11,1],
[80,41,11,2],
[80,41,11,3],
[80,41,11,4],
[80,41,11,5],
[80,41,11,6],
[80,41,11,7],
[80,41,11,8],
[80,41,12,0],
[80,41,12,1],
[80,41,12,2],
[80,41,12,3],
[80,41,12,4],
[80,41,12,5],
[80,41,12,6],
[80,41,12,7],
[80,41,12,8],
[80,41,13,0],
[80,41,13,1],
[80,41,13,2],
[80,41,13,3],
[80,41,13,4],
[80,41,13,5],
[80,41,13,6],
[80,41,13,7],
[80,41,13,8],
[80,41,13,11],
[80,41,13,12],
[80,41,14,0],
[80,41,14,1],
[80,41,14,2],
[80,41,14,3],
[80,41,14,4],
[80,41,14,5],
[80,41,14,6],
[80,41,14,7],
[80,41,14,8],
[80,41,14,11],
[80,41,14,12],
[80,41,16,0],
[80,41,16,1],
[80,41,16,2],
[80,41,16,3],
[80,41,16,4],
[80,41,16,5],
[80,41,16,6],
[80,41,16,7],
[80,41,16,8],
[80,41,16,11],
[80,41,17,0],
[80,41,17,1],
[80,41,17,2],
[80,41,17,3],
[80,41,17,4],
[80,41,17,5],
[80,41,17,6],
[80,41,17,7],
[80,41,17,8],
[80,41,17,11],
[80,41,19,0],
[80,41,19,1],
[80,41,19,2],
[80,41,19,3],
[80,41,19,4],
[80,41,19,5],
[80,41,19,6],
[80,41,19,7],
[80,41,21,0],
[80,41,21,1],
[80,41,21,2],
[80,41,21,4],
[80,43,3,0],
[80,43,3,1],
[80,43,3,2],
[80,43,4,0],
[80,43,4,1],
[80,43,4,2],
[80,43,4,3],
[80,43,5,0],
[80,43,5,1],
[80,43,5,2],
[80,43,5,4],
[80,43,6,0],
[80,43,6,1],
[80,43,6,2],
[80,43,6,4],
[80,43,8,0],
[80,43,8,1],
[80,43,8,2],
[80,43,8,4],
[80,43,9,0],
[80,43,9,1],
[80,43,9,2],
[80,43,9,3],
[80,43,9,4],
[80,43,9,5],
[80,43,9,6],
[80,43,9,8],
[80,43,11,0],
[80,43,11,1],
[80,43,11,2],
[80,43,11,3],
[80,43,11,4],
[80,43,11,5],
[80,43,11,6],
[80,43,11,8],
[80,43,11,9],
[80,43,12,0],
[80,43,12,1],
[80,43,12,2],
[80,43,12,3],
[80,43,12,4],
[80,43,12,5],
[80,43,12,6],
[80,43,12,8],
[80,43,12,9],
[80,43,13,0],
[80,43,13,1],
[80,43,13,2],
[80,43,13,3],
[80,43,13,4],
[80,43,13,5],
[80,43,13,6],
[80,43,13,8],
[80,43,13,9],
[80,43,13,11],
[80,43,13,12],
[80,43,14,0],
[80,43,14,1],
[80,43,14,2],
[80,43,14,3],
[80,43,14,4],
[80,43,14,5],
[80,43,14,6],
[80,43,14,8],
[80,43,14,9],
[80,43,14,11],
[80,43,15,0],
[80,43,15,1],
[80,43,15,2],
[80,43,15,3],
[80,43,15,4],
[80,43,15,5],
[80,43,15,6],
[80,43,15,8],
[80,43,16,0],
[80,43,16,1],
[80,43,16,2],
[80,43,16,3],
[80,43,16,4],
[80,43,16,5],
[80,43,16,6],
[80,43,17,0],
[80,43,17,1],
[80,43,17,2],
[80,43,17,3],
[80,43,17,4],
[80,43,17,5],
[80,43,17,6],
[80,43,18,0],
[80,43,18,1],
[80,43,18,2],
[80,43,18,3],
[80,43,18,4],
[80,43,18,5],
[80,43,19,0],
[80,43,19,1],
[80,43,19,2],
[80,43,19,3],
[80,43,19,4],
[80,44,3,0],
[80,44,3,1],
[80,44,3,2],
[80,44,4,0],
[80,44,4,1],
[80,44,4,2],
[80,44,4,3],
[80,44,5,0],
[80,44,5,1],
[80,44,5,2],
[80,44,5,4],
[80,44,6,0],
[80,44,6,1],
[80,44,6,2],
[80,44,6,4],
[80,44,7,0],
[80,44,7,1],
[80,44,7,2],
[80,44,7,3],
[80,44,7,4],
[80,44,7,5],
[80,44,7,6],
[80,44,8,0],
[80,44,8,1],
[80,44,8,2],
[80,44,8,4],
[80,44,8,7],
[80,44,9,0],
[80,44,9,1],
[80,44,9,2],
[80,44,9,3],
[80,44,9,4],
[80,44,9,5],
[80,44,9,6],
[80,44,9,7],
[80,44,9,8],
[80,44,11,0],
[80,44,11,1],
[80,44,11,2],
[80,44,11,3],
[80,44,11,4],
[80,44,11,5],
[80,44,11,6],
[80,44,11,7],
[80,44,11,8],
[80,44,11,9],
[80,44,12,0],
[80,44,12,1],
[80,44,12,2],
[80,44,12,3],
[80,44,12,4],
[80,44,12,5],
[80,44,12,6],
[80,44,12,7],
[80,44,12,8],
[80,44,12,9],
[80,44,13,0],
[80,44,13,1],
[80,44,13,2],
[80,44,13,3],
[80,44,13,4],
[80,44,13,5],
[80,44,13,6],
[80,44,13,7],
[80,44,13,8],
[80,44,13,9],
[80,44,13,11],
[80,44,14,0],
[80,44,14,1],
[80,44,14,2],
[80,44,14,3],
[80,44,14,4],
[80,44,14,5],
[80,44,14,6],
[80,44,14,7],
[80,44,14,8],
[80,44,14,9],
[80,44,15,0],
[80,44,15,1],
[80,44,15,2],
[80,44,15,3],
[80,44,15,4],
[80,44,15,5],
[80,44,15,6],
[80,44,15,7],
[80,44,16,0],
[80,44,16,1],
[80,44,16,2],
[80,44,16,3],
[80,44,16,4],
[80,44,16,5],
[80,44,18,0],
[80,44,18,1],
[80,44,18,2],
[80,44,18,3],
[80,44,18,4],
[80,44,19,0],
[80,44,19,1],
[80,44,19,2],
[80,45,3,0],
[80,45,3,1],
[80,45,3,2],
[80,45,4,0],
[80,45,4,1],
[80,45,4,2],
[80,45,4,3],
[80,45,5,0],
[80,45,5,1],
[80,45,5,2],
[80,45,5,4],
[80,45,6,0],
[80,45,6,1],
[80,45,6,2],
[80,45,6,4],
[80,45,7,0],
[80,45,7,1],
[80,45,7,2],
[80,45,7,3],
[80,45,7,4],
[80,45,7,5],
[80,45,7,6],
[80,45,8,0],
[80,45,8,1],
[80,45,8,2],
[80,45,8,4],
[80,45,8,7],
[80,45,11,0],
[80,45,11,1],
[80,45,11,2],
[80,45,11,3],
[80,45,11,4],
[80,45,11,5],
[80,45,11,6],
[80,45,11,7],
[80,45,11,8],
[80,45,12,0],
[80,45,12,1],
[80,45,12,2],
[80,45,12,3],
[80,45,12,4],
[80,45,12,5],
[80,45,12,6],
[80,45,12,7],
[80,45,12,8],
[80,45,13,0],
[80,45,13,1],
[80,45,13,2],
[80,45,13,3],
[80,45,13,4],
[80,45,13,5],
[80,45,13,6],
[80,45,13,7],
[80,45,13,8],
[80,45,14,0],
[80,45,14,1],
[80,45,14,2],
[80,45,14,3],
[80,45,14,4],
[80,45,14,5],
[80,45,14,6],
[80,45,14,7],
[80,45,16,0],
[80,45,16,1],
[80,45,16,2],
[80,45,16,3],
[80,45,16,4],
[80,45,17,0],
[80,45,17,1],
[80,45,17,2],
[80,45,17,3],
[80,45,17,4],
[80,46,3,0],
[80,46,3,1],
[80,46,3,2],
[80,46,4,0],
[80,46,4,1],
[80,46,4,2],
[80,46,4,3],
[80,46,5,0],
[80,46,5,1],
[80,46,5,2],
[80,46,5,4],
[80,46,6,0],
[80,46,6,1],
[80,46,6,2],
[80,46,6,4],
[80,46,7,0],
[80,46,7,1],
[80,46,7,2],
[80,46,7,3],
[80,46,7,4],
[80,46,7,5],
[80,46,7,6],
[80,46,8,0],
[80,46,8,1],
[80,46,8,2],
[80,46,8,4],
[80,46,8,7],
[80,46,11,0],
[80,46,11,1],
[80,46,11,2],
[80,46,11,3],
[80,46,11,4],
[80,46,11,5],
[80,46,11,6],
[80,46,11,7],
[80,46,11,8],
[80,46,12,0],
[80,46,12,1],
[80,46,12,2],
[80,46,12,3],
[80,46,12,4],
[80,46,12,5],
[80,46,12,6],
[80,46,12,7],
[80,46,13,0],
[80,46,13,1],
[80,46,13,2],
[80,46,13,3],
[80,46,13,4],
[80,46,13,5],
[80,46,13,6],
[80,46,13,7],
[80,46,14,0],
[80,46,14,1],
[80,46,14,2],
[80,46,14,3],
[80,46,14,4],
[80,46,14,5],
[80,46,16,0],
[80,46,16,1],
[80,46,16,2],
[80,46,17,0],
[80,46,17,1],
[80,46,17,2],
[80,47,3,0],
[80,47,3,1],
[80,47,3,2],
[80,47,4,0],
[80,47,4,1],
[80,47,4,2],
[80,47,4,3],
[80,47,5,0],
[80,47,5,1],
[80,47,5,2],
[80,47,5,4],
[80,47,6,0],
[80,47,6,1],
[80,47,6,2],
[80,47,6,4],
[80,47,7,0],
[80,47,7,1],
[80,47,7,2],
[80,47,7,3],
[80,47,7,4],
[80,47,7,5],
[80,47,7,6],
[80,47,8,0],
[80,47,8,1],
[80,47,8,2],
[80,47,8,4],
[80,47,8,7],
[80,47,9,0],
[80,47,9,1],
[80,47,9,2],
[80,47,9,3],
[80,47,9,4],
[80,47,9,5],
[80,47,9,6],
[80,47,9,7],
[80,47,9,8],
[80,47,11,0],
[80,47,11,1],
[80,47,11,2],
[80,47,11,3],
[80,47,11,4],
[80,47,11,5],
[80,47,11,6],
[80,47,11,7],
[80,47,12,0],
[80,47,12,1],
[80,47,12,2],
[80,47,12,3],
[80,47,12,4],
[80,47,12,5],
[80,47,13,0],
[80,47,13,1],
[80,47,13,2],
[80,47,13,3],
[80,47,13,4],
[80,47,13,5],
[80,47,14,0],
[80,47,14,1],
[80,47,14,2],
[80,47,14,3],
[80,47,14,4],
[80,47,15,0],
[80,47,15,1],
[80,47,15,2],
[80,48,3,0],
[80,48,3,1],
[80,48,3,2],
[80,48,4,0],
[80,48,4,1],
[80,48,4,2],
[80,48,4,3],
[80,48,5,0],
[80,48,5,1],
[80,48,5,2],
[80,48,5,4],
[80,48,6,0],
[80,48,6,1],
[80,48,6,2],
[80,48,6,4],
[80,48,7,0],
[80,48,7,1],
[80,48,7,2],
[80,48,7,3],
[80,48,7,4],
[80,48,7,5],
[80,48,7,6],
[80,48,8,0],
[80,48,8,1],
[80,48,8,2],
[80,48,8,4],
[80,48,8,7],
[80,48,9,0],
[80,48,9,1],
[80,48,9,2],
[80,48,9,3],
[80,48,9,4],
[80,48,9,5],
[80,48,9,6],
[80,48,9,7],
[80,48,13,0],
[80,48,13,1],
[80,48,13,2],
[80,48,13,3],
[80,48,13,4],
[80,48,14,0],
[80,48,14,1],
[80,48,14,2],
[80,49,3,0],
[80,49,3,1],
[80,49,3,2],
[80,49,4,0],
[80,49,4,1],
[80,49,4,2],
[80,49,4,3],
[80,49,5,0],
[80,49,5,1],
[80,49,5,2],
[80,49,5,4],
[80,49,6,0],
[80,49,6,1],
[80,49,6,2],
[80,49,6,4],
[80,49,7,0],
[80,49,7,1],
[80,49,7,2],
[80,49,7,3],
[80,49,7,4],
[80,49,7,5],
[80,49,7,6],
[80,49,8,0],
[80,49,8,1],
[80,49,8,2],
[80,49,8,4],
[80,49,8,7],
[80,49,11,0],
[80,49,11,1],
[80,49,11,2],
[80,49,11,3],
[80,49,11,4],
[80,49,11,5],
[80,49,12,0],
[80,49,12,1],
[80,49,12,2],
[80,49,12,3],
[80,49,12,4],
[80,49,13,0],
[80,49,13,1],
[80,49,13,2],
[80,49,13,3],
[80,49,13,4],
[80,49,14,0],
[80,49,14,1],
[80,49,14,2],
[80,50,3,0],
[80,50,3,1],
[80,50,3,2],
[80,50,4,0],
[80,50,4,1],
[80,50,4,2],
[80,50,4,3],
[80,50,5,0],
[80,50,5,1],
[80,50,5,2],
[80,50,5,4],
[80,50,6,0],
[80,50,6,1],
[80,50,6,2],
[80,50,6,4],
[80,50,7,0],
[80,50,7,1],
[80,50,7,2],
[80,50,7,3],
[80,50,7,4],
[80,50,7,5],
[80,50,7,6],
[80,50,8,0],
[80,50,8,1],
[80,50,8,2],
[80,50,8,4],
[80,50,8,7],
[80,50,11,0],
[80,50,11,1],
[80,50,11,2],
[80,50,11,3],
[80,50,11,4],
[80,50,11,5],
[80,50,12,0],
[80,50,12,1],
[80,50,12,2],
[80,50,12,3],
[80,50,12,4],
[80,50,13,0],
[80,50,13,1],
[80,50,13,2],
[80,50,13,3],
[80,50,13,4],
[80,50,14,0],
[80,50,14,1],
[80,50,14,2],
[80,51,4,0],
[80,51,4,1],
[80,51,4,2],
[80,51,7,0],
[80,51,7,1],
[80,51,7,2],
[80,51,7,4],
[80,51,9,0],
[80,51,9,1],
[80,51,9,2],
[80,51,9,4],
[80,51,9,7],
[80,51,11,0],
[80,51,11,1],
[80,51,11,2],
[80,51,11,4],
[80,51,12,0],
[80,51,12,1],
[80,51,12,2],
[80,51,12,4],
[80,51,13,0],
[80,51,13,1],
[80,51,13,2],
[80,51,13,4],
[80,51,14,0],
[80,51,14,1],
[80,51,14,2],
[80,52,3,0],
[80,52,3,1],
[80,52,3,2],
[80,52,4,0],
[80,52,4,1],
[80,52,4,2],
[80,52,4,3],
[80,52,5,0],
[80,52,5,1],
[80,52,5,2],
[80,52,5,4],
[80,52,6,0],
[80,52,6,1],
[80,52,6,2],
[80,52,6,4],
[80,52,7,0],
[80,52,7,1],
[80,52,7,2],
[80,52,7,3],
[80,52,7,4],
[80,52,7,5],
[80,52,7,6],
[80,52,8,0],
[80,52,8,1],
[80,52,8,2],
[80,52,8,4],
[80,52,8,7],
[80,52,11,0],
[80,52,11,1],
[80,52,11,2],
[80,52,11,3],
[80,52,11,4],
[80,52,11,5],
[80,52,12,0],
[80,52,12,1],
[80,52,12,2],
[80,52,12,3],
[80,52,12,4],
[80,52,13,0],
[80,52,13,1],
[80,52,13,2],
[80,52,13,3],
[80,52,13,4],
[80,52,14,0],
[80,52,14,1],
[80,52,14,2],
[80,56,3,0],
[80,56,3,1],
[80,56,3,2],
[80,56,4,0],
[80,56,4,1],
[80,56,4,2],
[80,56,4,3],
[80,56,5,0],
[80,56,5,1],
[80,56,5,2],
[80,56,5,4],
[80,56,6,0],
[80,56,6,1],
[80,56,6,2],
[80,56,6,4],
[80,56,7,0],
[80,56,7,1],
[80,56,7,2],
[80,56,7,3],
[80,56,7,4],
[80,56,7,5],
[80,56,7,6],
[80,56,8,0],
[80,56,8,1],
[80,56,8,2],
[80,56,8,4],
[80,56,8,7],
[80,56,11,0],
[80,56,11,1],
[80,56,11,2],
[80,56,11,3],
[80,56,11,4],
[80,56,11,5],
[80,56,12,0],
[80,56,12,1],
[80,56,12,2],
[80,56,12,3],
[80,56,12,4],
[80,56,13,0],
[80,56,13,1],
[80,56,13,2],
[80,56,13,3],
[80,56,13,4],
[80,56,14,0],
[80,56,14,1],
[80,56,14,2],
[80,57,3,0],
[80,57,3,1],
[80,57,3,2],
[80,57,4,0],
[80,57,4,1],
[80,57,4,2],
[80,57,4,3],
[80,57,5,0],
[80,57,5,1],
[80,57,5,2],
[80,57,5,4],
[80,57,6,0],
[80,57,6,1],
[80,57,6,2],
[80,57,6,4],
[80,57,7,0],
[80,57,7,1],
[80,57,7,2],
[80,57,7,3],
[80,57,7,4],
[80,57,7,5],
[80,57,7,6],
[80,57,8,0],
[80,57,8,1],
[80,57,8,2],
[80,57,8,4],
[80,57,9,0],
[80,57,9,1],
[80,57,9,2],
[80,57,9,3],
[80,57,9,4],
[80,57,9,5],
[80,57,11,0],
[80,57,11,1],
[80,57,11,2],
[80,57,11,3],
[80,57,11,4],
[80,57,12,0],
[80,57,12,1],
[80,57,12,2],
[80,57,13,0],
[80,57,13,1],
[80,57,13,2],
[80,58,3,0],
[80,58,3,1],
[80,58,3,2],
[80,58,4,0],
[80,58,4,1],
[80,58,4,2],
[80,58,4,3],
[80,58,5,0],
[80,58,5,1],
[80,58,5,2],
[80,58,5,4],
[80,58,6,0],
[80,58,6,1],
[80,58,6,2],
[80,58,6,4],
[80,58,7,0],
[80,58,7,1],
[80,58,7,2],
[80,58,7,3],
[80,58,7,4],
[80,58,7,5],
[80,58,7,6],
[80,58,8,0],
[80,58,8,1],
[80,58,8,2],
[80,58,8,4],
[80,58,9,0],
[80,58,9,1],
[80,58,9,2],
[80,58,9,3],
[80,58,9,4],
[80,58,9,5],
[80,58,11,0],
[80,58,11,1],
[80,58,11,2],
[80,58,11,3],
[80,58,11,4],
[80,58,12,0],
[80,58,12,1],
[80,58,12,2],
[80,58,13,0],
[80,58,13,1],
[80,58,13,2],
[80,59,3,0],
[80,59,3,1],
[80,59,3,2],
[80,59,4,0],
[80,59,4,1],
[80,59,4,2],
[80,59,4,3],
[80,59,5,0],
[80,59,5,1],
[80,59,5,2],
[80,59,5,4],
[80,59,6,0],
[80,59,6,1],
[80,59,6,2],
[80,59,6,4],
[80,59,7,0],
[80,59,7,1],
[80,59,7,2],
[80,59,7,3],
[80,59,7,4],
[80,59,7,5],
[80,59,8,0],
[80,59,8,1],
[80,59,8,2],
[80,59,8,4],
[80,59,11,0],
[80,59,11,1],
[80,59,11,2],
[80,60,3,0],
[80,60,3,1],
[80,60,3,2],
[80,60,4,0],
[80,60,4,1],
[80,60,4,2],
[80,60,4,3],
[80,60,5,0],
[80,60,5,1],
[80,60,5,2],
[80,60,5,4],
[80,60,6,0],
[80,60,6,1],
[80,60,6,2],
[80,60,6,4],
[80,60,7,0],
[80,60,7,1],
[80,60,7,2],
[80,60,7,3],
[80,60,7,4],
[80,60,8,0],
[80,60,8,1],
[80,60,8,2],
[80,60,9,0],
[80,60,9,1],
[80,60,9,2],
[80,61,3,0],
[80,61,3,1],
[80,61,3,2],
[80,61,5,0],
[80,61,5,1],
[80,61,5,2],
[80,61,6,0],
[80,61,6,1],
[80,61,6,2],
[80,61,7,0],
[80,61,7,1],
[80,61,7,2],
[80,62,3,0],
[80,62,3,1],
[80,62,3,2],
[80,62,4,0],
[80,62,4,1],
[80,62,4,2],
[80,62,4,3],
[80,62,5,0],
[80,62,5,1],
[80,62,5,2],
[80,62,5,4],
[80,62,6,0],
[80,62,6,1],
[80,62,6,2],
[80,63,3,0],
[80,63,3,1],
[80,63,3,2],
[80,63,4,0],
[80,63,4,1],
[80,63,4,2],
[80,63,4,3],
[80,63,5,0],
[80,63,5,1],
[80,63,5,2],
[80,64,3,0],
[80,64,3,1],
[80,64,3,2],
[81,4,3,0],
[81,4,3,1],
[81,4,3,2],
[81,5,4,0],
[81,5,4,1],
[81,5,4,2],
[81,6,4,0],
[81,6,4,1],
[81,6,4,2],
[81,7,3,0],
[81,7,3,1],
[81,7,3,2],
[81,7,4,0],
[81,7,4,1],
[81,7,4,2],
[81,7,4,3],
[81,7,5,0],
[81,7,5,1],
[81,7,5,2],
[81,7,5,4],
[81,7,6,0],
[81,7,6,1],
[81,7,6,2],
[81,7,6,4],
[81,8,4,0],
[81,8,4,1],
[81,8,4,2],
[81,8,7,0],
[81,8,7,1],
[81,8,7,2],
[81,8,7,4],
[81,9,3,0],
[81,9,3,1],
[81,9,3,2],
[81,9,4,0],
[81,9,4,1],
[81,9,4,2],
[81,9,4,3],
[81,9,5,0],
[81,9,5,1],
[81,9,5,2],
[81,9,5,4],
[81,9,6,0],
[81,9,6,1],
[81,9,6,2],
[81,9,6,4],
[81,9,7,0],
[81,9,7,1],
[81,9,7,2],
[81,9,7,3],
[81,9,7,4],
[81,9,7,5],
[81,9,7,6],
[81,9,8,0],
[81,9,8,1],
[81,9,8,2],
[81,9,8,4],
[81,9,8,7],
[81,10,3,0],
[81,10,3,1],
[81,10,3,2],
[81,10,4,0],
[81,10,4,1],
[81,10,4,2],
[81,10,4,3],
[81,10,5,0],
[81,10,5,1],
[81,10,5,2],
[81,10,5,4],
[81,10,6,0],
[81,10,6,1],
[81,10,6,2],
[81,10,6,4],
[81,10,7,0],
[81,10,7,1],
[81,10,7,2],
[81,10,7,3],
[81,10,7,4],
[81,10,7,5],
[81,10,7,6],
[81,10,8,0],
[81,10,8,1],
[81,10,8,2],
[81,10,8,4],
[81,10,8,7],
[81,10,9,0],
[81,10,9,1],
[81,10,9,2],
[81,10,9,3],
[81,10,9,4],
[81,10,9,5],
[81,10,9,6],
[81,10,9,7],
[81,10,9,8],
[81,11,3,0],
[81,11,3,1],
[81,11,3,2],
[81,11,4,0],
[81,11,4,1],
[81,11,4,2],
[81,11,4,3],
[81,11,5,0],
[81,11,5,1],
[81,11,5,2],
[81,11,5,4],
[81,11,6,0],
[81,11,6,1],
[81,11,6,2],
[81,11,6,4],
[81,11,7,0],
[81,11,7,1],
[81,11,7,2],
[81,11,7,3],
[81,11,7,4],
[81,11,7,5],
[81,11,7,6],
[81,11,8,0],
[81,11,8,1],
[81,11,8,2],
[81,11,8,4],
[81,11,8,7],
[81,11,9,0],
[81,11,9,1],
[81,11,9,2],
[81,11,9,3],
[81,11,9,4],
[81,11,9,5],
[81,11,9,6],
[81,11,9,7],
[81,11,9,8],
[81,11,10,0],
[81,11,10,1],
[81,11,10,2],
[81,11,10,3],
[81,11,10,4],
[81,11,10,5],
[81,11,10,6],
[81,11,10,7],
[81,11,10,8],
[81,11,10,9],
[81,12,3,0],
[81,12,3,1],
[81,12,3,2],
[81,12,4,0],
[81,12,4,1],
[81,12,4,2],
[81,12,4,3],
[81,12,5,0],
[81,12,5,1],
[81,12,5,2],
[81,12,5,4],
[81,12,6,0],
[81,12,6,1],
[81,12,6,2],
[81,12,6,4],
[81,12,7,0],
[81,12,7,1],
[81,12,7,2],
[81,12,7,3],
[81,12,7,4],
[81,12,7,5],
[81,12,7,6],
[81,12,8,0],
[81,12,8,1],
[81,12,8,2],
[81,12,8,4],
[81,12,8,7],
[81,12,9,0],
[81,12,9,1],
[81,12,9,2],
[81,12,9,3],
[81,12,9,4],
[81,12,9,5],
[81,12,9,6],
[81,12,9,7],
[81,12,9,8],
[81,12,10,0],
[81,12,10,1],
[81,12,10,2],
[81,12,10,3],
[81,12,10,4],
[81,12,10,5],
[81,12,10,6],
[81,12,10,7],
[81,12,10,8],
[81,12,10,9],
[81,13,3,0],
[81,13,3,1],
[81,13,3,2],
[81,13,4,0],
[81,13,4,1],
[81,13,4,2],
[81,13,4,3],
[81,13,5,0],
[81,13,5,1],
[81,13,5,2],
[81,13,5,4],
[81,13,6,0],
[81,13,6,1],
[81,13,6,2],
[81,13,6,4],
[81,13,7,0],
[81,13,7,1],
[81,13,7,2],
[81,13,7,3],
[81,13,7,4],
[81,13,7,5],
[81,13,7,6],
[81,13,8,0],
[81,13,8,1],
[81,13,8,2],
[81,13,8,4],
[81,13,8,7],
[81,13,9,0],
[81,13,9,1],
[81,13,9,2],
[81,13,9,3],
[81,13,9,4],
[81,13,9,5],
[81,13,9,6],
[81,13,9,7],
[81,13,9,8],
[81,13,10,0],
[81,13,10,1],
[81,13,10,2],
[81,13,10,3],
[81,13,10,4],
[81,13,10,5],
[81,13,10,6],
[81,13,10,7],
[81,13,10,8],
[81,13,10,9],
[81,13,11,0],
[81,13,11,1],
[81,13,11,2],
[81,13,11,3],
[81,13,11,4],
[81,13,11,5],
[81,13,11,6],
[81,13,11,7],
[81,13,11,8],
[81,13,11,9],
[81,13,11,10],
[81,13,12,0],
[81,13,12,1],
[81,13,12,2],
[81,13,12,3],
[81,13,12,4],
[81,13,12,5],
[81,13,12,6],
[81,13,12,7],
[81,13,12,8],
[81,13,12,9],
[81,13,12,10],
[81,14,3,0],
[81,14,3,1],
[81,14,3,2],
[81,14,4,0],
[81,14,4,1],
[81,14,4,2],
[81,14,4,3],
[81,14,5,0],
[81,14,5,1],
[81,14,5,2],
[81,14,5,4],
[81,14,6,0],
[81,14,6,1],
[81,14,6,2],
[81,14,6,4],
[81,14,7,0],
[81,14,7,1],
[81,14,7,2],
[81,14,7,3],
[81,14,7,4],
[81,14,7,5],
[81,14,7,6],
[81,14,8,0],
[81,14,8,1],
[81,14,8,2],
[81,14,8,4],
[81,14,8,7],
[81,14,9,0],
[81,14,9,1],
[81,14,9,2],
[81,14,9,3],
[81,14,9,4],
[81,14,9,5],
[81,14,9,6],
[81,14,9,7],
[81,14,9,8],
[81,14,10,0],
[81,14,10,1],
[81,14,10,2],
[81,14,10,3],
[81,14,10,4],
[81,14,10,5],
[81,14,10,6],
[81,14,10,7],
[81,14,10,8],
[81,14,10,9],
[81,14,11,0],
[81,14,11,1],
[81,14,11,2],
[81,14,11,3],
[81,14,11,4],
[81,14,11,5],
[81,14,11,6],
[81,14,11,7],
[81,14,11,8],
[81,14,11,9],
[81,14,11,10],
[81,14,12,0],
[81,14,12,1],
[81,14,12,2],
[81,14,12,3],
[81,14,12,4],
[81,14,12,5],
[81,14,12,6],
[81,14,12,7],
[81,14,12,8],
[81,14,12,9],
[81,14,12,10],
[81,15,3,0],
[81,15,3,1],
[81,15,3,2],
[81,15,4,0],
[81,15,4,1],
[81,15,4,2],
[81,15,4,3],
[81,15,5,0],
[81,15,5,1],
[81,15,5,2],
[81,15,5,4],
[81,15,6,0],
[81,15,6,1],
[81,15,6,2],
[81,15,6,4],
[81,15,7,0],
[81,15,7,1],
[81,15,7,2],
[81,15,7,3],
[81,15,7,4],
[81,15,7,5],
[81,15,7,6],
[81,15,8,0],
[81,15,8,1],
[81,15,8,2],
[81,15,8,4],
[81,15,8,7],
[81,15,10,0],
[81,15,10,1],
[81,15,10,2],
[81,15,10,3],
[81,15,10,4],
[81,15,10,5],
[81,15,10,6],
[81,15,10,7],
[81,15,10,8],
[81,15,11,0],
[81,15,11,1],
[81,15,11,2],
[81,15,11,3],
[81,15,11,4],
[81,15,11,5],
[81,15,11,6],
[81,15,11,7],
[81,15,11,8],
[81,15,11,10],
[81,15,12,0],
[81,15,12,1],
[81,15,12,2],
[81,15,12,3],
[81,15,12,4],
[81,15,12,5],
[81,15,12,6],
[81,15,12,7],
[81,15,12,8],
[81,15,12,10],
[81,15,13,0],
[81,15,13,1],
[81,15,13,2],
[81,15,13,3],
[81,15,13,4],
[81,15,13,5],
[81,15,13,6],
[81,15,13,7],
[81,15,13,8],
[81,15,13,10],
[81,15,13,11],
[81,15,13,12],
[81,15,14,0],
[81,15,14,1],
[81,15,14,2],
[81,15,14,3],
[81,15,14,4],
[81,15,14,5],
[81,15,14,6],
[81,15,14,7],
[81,15,14,8],
[81,15,14,10],
[81,15,14,11],
[81,15,14,12],
[81,16,3,0],
[81,16,3,1],
[81,16,3,2],
[81,16,4,0],
[81,16,4,1],
[81,16,4,2],
[81,16,4,3],
[81,16,5,0],
[81,16,5,1],
[81,16,5,2],
[81,16,5,4],
[81,16,6,0],
[81,16,6,1],
[81,16,6,2],
[81,16,6,4],
[81,16,7,0],
[81,16,7,1],
[81,16,7,2],
[81,16,7,3],
[81,16,7,4],
[81,16,7,5],
[81,16,7,6],
[81,16,8,0],
[81,16,8,1],
[81,16,8,2],
[81,16,8,4],
[81,16,8,7],
[81,16,9,0],
[81,16,9,1],
[81,16,9,2],
[81,16,9,3],
[81,16,9,4],
[81,16,9,5],
[81,16,9,6],
[81,16,9,7],
[81,16,9,8],
[81,16,10,0],
[81,16,10,1],
[81,16,10,2],
[81,16,10,3],
[81,16,10,4],
[81,16,10,5],
[81,16,10,6],
[81,16,10,7],
[81,16,10,8],
[81,16,10,9],
[81,16,11,0],
[81,16,11,1],
[81,16,11,2],
[81,16,11,3],
[81,16,11,4],
[81,16,11,5],
[81,16,11,6],
[81,16,11,7],
[81,16,11,8],
[81,16,11,9],
[81,16,11,10],
[81,16,12,0],
[81,16,12,1],
[81,16,12,2],
[81,16,12,3],
[81,16,12,4],
[81,16,12,5],
[81,16,12,6],
[81,16,12,7],
[81,16,12,8],
[81,16,12,9],
[81,16,12,10],
[81,16,13,0],
[81,16,13,1],
[81,16,13,2],
[81,16,13,3],
[81,16,13,4],
[81,16,13,5],
[81,16,13,6],
[81,16,13,7],
[81,16,13,8],
[81,16,13,9],
[81,16,13,10],
[81,16,13,11],
[81,16,13,12],
[81,16,14,0],
[81,16,14,1],
[81,16,14,2],
[81,16,14,3],
[81,16,14,4],
[81,16,14,5],
[81,16,14,6],
[81,16,14,7],
[81,16,14,8],
[81,16,14,9],
[81,16,14,10],
[81,16,14,11],
[81,16,14,12],
[81,16,15,0],
[81,16,15,1],
[81,16,15,2],
[81,16,15,3],
[81,16,15,4],
[81,16,15,5],
[81,16,15,6],
[81,16,15,7],
[81,16,15,8],
[81,16,15,10],
[81,16,15,11],
[81,16,15,12],
[81,16,15,13],
[81,16,15,14],
[81,17,3,0],
[81,17,3,1],
[81,17,3,2],
[81,17,4,0],
[81,17,4,1],
[81,17,4,2],
[81,17,4,3],
[81,17,5,0],
[81,17,5,1],
[81,17,5,2],
[81,17,5,4],
[81,17,6,0],
[81,17,6,1],
[81,17,6,2],
[81,17,6,4],
[81,17,7,0],
[81,17,7,1],
[81,17,7,2],
[81,17,7,3],
[81,17,7,4],
[81,17,7,5],
[81,17,7,6],
[81,17,8,0],
[81,17,8,1],
[81,17,8,2],
[81,17,8,4],
[81,17,8,7],
[81,17,9,0],
[81,17,9,1],
[81,17,9,2],
[81,17,9,3],
[81,17,9,4],
[81,17,9,5],
[81,17,9,6],
[81,17,9,7],
[81,17,9,8],
[81,17,10,0],
[81,17,10,1],
[81,17,10,2],
[81,17,10,3],
[81,17,10,4],
[81,17,10,5],
[81,17,10,6],
[81,17,10,7],
[81,17,10,8],
[81,17,10,9],
[81,17,11,0],
[81,17,11,1],
[81,17,11,2],
[81,17,11,3],
[81,17,11,4],
[81,17,11,5],
[81,17,11,6],
[81,17,11,7],
[81,17,11,8],
[81,17,11,9],
[81,17,11,10],
[81,17,12,0],
[81,17,12,1],
[81,17,12,2],
[81,17,12,3],
[81,17,12,4],
[81,17,12,5],
[81,17,12,6],
[81,17,12,7],
[81,17,12,8],
[81,17,12,9],
[81,17,12,10],
[81,17,13,0],
[81,17,13,1],
[81,17,13,2],
[81,17,13,3],
[81,17,13,4],
[81,17,13,5],
[81,17,13,6],
[81,17,13,7],
[81,17,13,8],
[81,17,13,9],
[81,17,13,10],
[81,17,13,11],
[81,17,13,12],
[81,17,14,0],
[81,17,14,1],
[81,17,14,2],
[81,17,14,3],
[81,17,14,4],
[81,17,14,5],
[81,17,14,6],
[81,17,14,7],
[81,17,14,8],
[81,17,14,9],
[81,17,14,10],
[81,17,14,11],
[81,17,14,12],
[81,17,15,0],
[81,17,15,1],
[81,17,15,2],
[81,17,15,3],
[81,17,15,4],
[81,17,15,5],
[81,17,15,6],
[81,17,15,7],
[81,17,15,8],
[81,17,15,10],
[81,17,15,11],
[81,17,15,12],
[81,17,15,13],
[81,17,15,14],
[81,17,16,0],
[81,17,16,1],
[81,17,16,2],
[81,17,16,3],
[81,17,16,4],
[81,17,16,5],
[81,17,16,6],
[81,17,16,7],
[81,17,16,8],
[81,17,16,9],
[81,17,16,10],
[81,17,16,11],
[81,17,16,12],
[81,17,16,13],
[81,17,16,14],
[81,17,16,15],
[81,18,3,0],
[81,18,3,1],
[81,18,3,2],
[81,18,4,0],
[81,18,4,1],
[81,18,4,2],
[81,18,4,3],
[81,18,5,0],
[81,18,5,1],
[81,18,5,2],
[81,18,5,4],
[81,18,6,0],
[81,18,6,1],
[81,18,6,2],
[81,18,6,4],
[81,18,7,0],
[81,18,7,1],
[81,18,7,2],
[81,18,7,3],
[81,18,7,4],
[81,18,7,5],
[81,18,7,6],
[81,18,8,0],
[81,18,8,1],
[81,18,8,2],
[81,18,8,4],
[81,18,8,7],
[81,18,10,0],
[81,18,10,1],
[81,18,10,2],
[81,18,10,3],
[81,18,10,4],
[81,18,10,5],
[81,18,10,6],
[81,18,10,7],
[81,18,10,8],
[81,18,11,0],
[81,18,11,1],
[81,18,11,2],
[81,18,11,3],
[81,18,11,4],
[81,18,11,5],
[81,18,11,6],
[81,18,11,7],
[81,18,11,8],
[81,18,11,10],
[81,18,12,0],
[81,18,12,1],
[81,18,12,2],
[81,18,12,3],
[81,18,12,4],
[81,18,12,5],
[81,18,12,6],
[81,18,12,7],
[81,18,12,8],
[81,18,12,10],
[81,18,13,0],
[81,18,13,1],
[81,18,13,2],
[81,18,13,3],
[81,18,13,4],
[81,18,13,5],
[81,18,13,6],
[81,18,13,7],
[81,18,13,8],
[81,18,13,10],
[81,18,13,11],
[81,18,13,12],
[81,18,14,0],
[81,18,14,1],
[81,18,14,2],
[81,18,14,3],
[81,18,14,4],
[81,18,14,5],
[81,18,14,6],
[81,18,14,7],
[81,18,14,8],
[81,18,14,10],
[81,18,14,11],
[81,18,14,12],
[81,18,16,0],
[81,18,16,1],
[81,18,16,2],
[81,18,16,3],
[81,18,16,4],
[81,18,16,5],
[81,18,16,6],
[81,18,16,7],
[81,18,16,8],
[81,18,16,10],
[81,18,16,11],
[81,18,16,12],
[81,18,16,13],
[81,18,16,14],
[81,18,17,0],
[81,18,17,1],
[81,18,17,2],
[81,18,17,3],
[81,18,17,4],
[81,18,17,5],
[81,18,17,6],
[81,18,17,7],
[81,18,17,8],
[81,18,17,10],
[81,18,17,11],
[81,18,17,12],
[81,18,17,13],
[81,18,17,14],
[81,18,17,16],
[81,19,3,0],
[81,19,3,1],
[81,19,3,2],
[81,19,4,0],
[81,19,4,1],
[81,19,4,2],
[81,19,4,3],
[81,19,5,0],
[81,19,5,1],
[81,19,5,2],
[81,19,5,4],
[81,19,6,0],
[81,19,6,1],
[81,19,6,2],
[81,19,6,4],
[81,19,7,0],
[81,19,7,1],
[81,19,7,2],
[81,19,7,3],
[81,19,7,4],
[81,19,7,5],
[81,19,7,6],
[81,19,8,0],
[81,19,8,1],
[81,19,8,2],
[81,19,8,4],
[81,19,8,7],
[81,19,9,0],
[81,19,9,1],
[81,19,9,2],
[81,19,9,3],
[81,19,9,4],
[81,19,9,5],
[81,19,9,6],
[81,19,9,7],
[81,19,9,8],
[81,19,10,0],
[81,19,10,1],
[81,19,10,2],
[81,19,10,3],
[81,19,10,4],
[81,19,10,5],
[81,19,10,6],
[81,19,10,7],
[81,19,10,8],
[81,19,10,9],
[81,19,11,0],
[81,19,11,1],
[81,19,11,2],
[81,19,11,3],
[81,19,11,4],
[81,19,11,5],
[81,19,11,6],
[81,19,11,7],
[81,19,11,8],
[81,19,11,9],
[81,19,11,10],
[81,19,12,0],
[81,19,12,1],
[81,19,12,2],
[81,19,12,3],
[81,19,12,4],
[81,19,12,5],
[81,19,12,6],
[81,19,12,7],
[81,19,12,8],
[81,19,12,9],
[81,19,12,10],
[81,19,15,0],
[81,19,15,1],
[81,19,15,2],
[81,19,15,3],
[81,19,15,4],
[81,19,15,5],
[81,19,15,6],
[81,19,15,7],
[81,19,15,8],
[81,19,15,10],
[81,19,15,11],
[81,19,15,12],
[81,19,16,0],
[81,19,16,1],
[81,19,16,2],
[81,19,16,3],
[81,19,16,4],
[81,19,16,5],
[81,19,16,6],
[81,19,16,7],
[81,19,16,8],
[81,19,16,9],
[81,19,16,10],
[81,19,16,11],
[81,19,16,12],
[81,19,16,15],
[81,19,17,0],
[81,19,17,1],
[81,19,17,2],
[81,19,17,3],
[81,19,17,4],
[81,19,17,5],
[81,19,17,6],
[81,19,17,7],
[81,19,17,8],
[81,19,17,9],
[81,19,17,10],
[81,19,17,11],
[81,19,17,12],
[81,19,17,15],
[81,19,17,16],
[81,19,18,0],
[81,19,18,1],
[81,19,18,2],
[81,19,18,3],
[81,19,18,4],
[81,19,18,5],
[81,19,18,6],
[81,19,18,7],
[81,19,18,8],
[81,19,18,10],
[81,19,18,11],
[81,19,18,12],
[81,19,18,16],
[81,19,18,17],
[81,20,3,0],
[81,20,3,1],
[81,20,3,2],
[81,20,4,0],
[81,20,4,1],
[81,20,4,2],
[81,20,4,3],
[81,20,5,0],
[81,20,5,1],
[81,20,5,2],
[81,20,5,4],
[81,20,6,0],
[81,20,6,1],
[81,20,6,2],
[81,20,6,4],
[81,20,7,0],
[81,20,7,1],
[81,20,7,2],
[81,20,7,3],
[81,20,7,4],
[81,20,7,5],
[81,20,7,6],
[81,20,8,0],
[81,20,8,1],
[81,20,8,2],
[81,20,8,4],
[81,20,8,7],
[81,20,9,0],
[81,20,9,1],
[81,20,9,2],
[81,20,9,3],
[81,20,9,4],
[81,20,9,5],
[81,20,9,6],
[81,20,9,7],
[81,20,9,8],
[81,20,11,0],
[81,20,11,1],
[81,20,11,2],
[81,20,11,3],
[81,20,11,4],
[81,20,11,5],
[81,20,11,6],
[81,20,11,7],
[81,20,11,8],
[81,20,11,9],
[81,20,12,0],
[81,20,12,1],
[81,20,12,2],
[81,20,12,3],
[81,20,12,4],
[81,20,12,5],
[81,20,12,6],
[81,20,12,7],
[81,20,12,8],
[81,20,12,9],
[81,20,13,0],
[81,20,13,1],
[81,20,13,2],
[81,20,13,3],
[81,20,13,4],
[81,20,13,5],
[81,20,13,6],
[81,20,13,7],
[81,20,13,8],
[81,20,13,9],
[81,20,13,11],
[81,20,13,12],
[81,20,14,0],
[81,20,14,1],
[81,20,14,2],
[81,20,14,3],
[81,20,14,4],
[81,20,14,5],
[81,20,14,6],
[81,20,14,7],
[81,20,14,8],
[81,20,14,9],
[81,20,14,11],
[81,20,14,12],
[81,20,15,0],
[81,20,15,1],
[81,20,15,2],
[81,20,15,3],
[81,20,15,4],
[81,20,15,5],
[81,20,15,6],
[81,20,15,7],
[81,20,15,8],
[81,20,15,11],
[81,20,15,12],
[81,20,15,13],
[81,20,15,14],
[81,20,16,0],
[81,20,16,1],
[81,20,16,2],
[81,20,16,3],
[81,20,16,4],
[81,20,16,5],
[81,20,16,6],
[81,20,16,7],
[81,20,16,8],
[81,20,16,9],
[81,20,16,11],
[81,20,16,12],
[81,20,16,13],
[81,20,16,14],
[81,20,16,15],
[81,20,17,0],
[81,20,17,1],
[81,20,17,2],
[81,20,17,3],
[81,20,17,4],
[81,20,17,5],
[81,20,17,6],
[81,20,17,7],
[81,20,17,8],
[81,20,17,9],
[81,20,17,11],
[81,20,17,12],
[81,20,17,13],
[81,20,17,14],
[81,20,17,15],
[81,20,17,16],
[81,20,18,0],
[81,20,18,1],
[81,20,18,2],
[81,20,18,3],
[81,20,18,4],
[81,20,18,5],
[81,20,18,6],
[81,20,18,7],
[81,20,18,8],
[81,20,18,11],
[81,20,18,12],
[81,20,18,13],
[81,20,18,14],
[81,20,18,16],
[81,20,18,17],
[81,20,19,0],
[81,20,19,1],
[81,20,19,2],
[81,20,19,3],
[81,20,19,4],
[81,20,19,5],
[81,20,19,6],
[81,20,19,7],
[81,20,19,8],
[81,20,19,9],
[81,20,19,11],
[81,20,19,12],
[81,20,19,15],
[81,20,19,16],
[81,20,19,17],
[81,21,4,0],
[81,21,4,1],
[81,21,4,2],
[81,21,7,0],
[81,21,7,1],
[81,21,7,2],
[81,21,7,4],
[81,21,9,0],
[81,21,9,1],
[81,21,9,2],
[81,21,9,4],
[81,21,9,7],
[81,21,10,0],
[81,21,10,1],
[81,21,10,2],
[81,21,10,4],
[81,21,10,7],
[81,21,10,9],
[81,21,11,0],
[81,21,11,1],
[81,21,11,2],
[81,21,11,4],
[81,21,11,7],
[81,21,11,9],
[81,21,11,10],
[81,21,12,0],
[81,21,12,1],
[81,21,12,2],
[81,21,12,4],
[81,21,12,7],
[81,21,12,9],
[81,21,12,10],
[81,21,13,0],
[81,21,13,1],
[81,21,13,2],
[81,21,13,4],
[81,21,13,7],
[81,21,13,9],
[81,21,13,10],
[81,21,13,11],
[81,21,13,12],
[81,21,14,0],
[81,21,14,1],
[81,21,14,2],
[81,21,14,4],
[81,21,14,7],
[81,21,14,9],
[81,21,14,10],
[81,21,14,11],
[81,21,14,12],
[81,21,15,0],
[81,21,15,1],
[81,21,15,2],
[81,21,15,4],
[81,21,15,7],
[81,21,15,10],
[81,21,15,11],
[81,21,15,12],
[81,21,15,13],
[81,21,15,14],
[81,21,16,0],
[81,21,16,1],
[81,21,16,2],
[81,21,16,4],
[81,21,16,7],
[81,21,16,9],
[81,21,16,10],
[81,21,16,11],
[81,21,16,12],
[81,21,16,13],
[81,21,16,14],
[81,21,16,15],
[81,21,17,0],
[81,21,17,1],
[81,21,17,2],
[81,21,17,4],
[81,21,17,7],
[81,21,17,9],
[81,21,17,10],
[81,21,17,11],
[81,21,17,12],
[81,21,17,13],
[81,21,17,14],
[81,21,17,15],
[81,21,17,16],
[81,21,18,0],
[81,21,18,1],
[81,21,18,2],
[81,21,18,4],
[81,21,18,7],
[81,21,18,10],
[81,21,18,11],
[81,21,18,12],
[81,21,18,13],
[81,21,18,14],
[81,21,18,16],
[81,21,18,17],
[81,21,19,0],
[81,21,19,1],
[81,21,19,2],
[81,21,19,4],
[81,21,19,7],
[81,21,19,9],
[81,21,19,10],
[81,21,19,11],
[81,21,19,12],
[81,21,19,15],
[81,21,19,18],
[81,21,20,0],
[81,21,20,1],
[81,21,20,2],
[81,21,20,4],
[81,21,20,7],
[81,21,20,9],
[81,21,20,11],
[81,21,20,12],
[81,21,20,13],
[81,21,20,14],
[81,21,20,16],
[81,21,20,17],
[81,21,20,18],
[81,21,20,19],
[81,22,3,0],
[81,22,3,1],
[81,22,3,2],
[81,22,4,0],
[81,22,4,1],
[81,22,4,2],
[81,22,4,3],
[81,22,5,0],
[81,22,5,1],
[81,22,5,2],
[81,22,5,4],
[81,22,6,0],
[81,22,6,1],
[81,22,6,2],
[81,22,6,4],
[81,22,7,0],
[81,22,7,1],
[81,22,7,2],
[81,22,7,3],
[81,22,7,4],
[81,22,7,5],
[81,22,7,6],
[81,22,8,0],
[81,22,8,1],
[81,22,8,2],
[81,22,8,4],
[81,22,8,7],
[81,22,9,0],
[81,22,9,1],
[81,22,9,2],
[81,22,9,3],
[81,22,9,4],
[81,22,9,5],
[81,22,9,6],
[81,22,9,7],
[81,22,9,8],
[81,22,11,0],
[81,22,11,1],
[81,22,11,2],
[81,22,11,3],
[81,22,11,4],
[81,22,11,5],
[81,22,11,6],
[81,22,11,7],
[81,22,11,8],
[81,22,11,9],
[81,22,12,0],
[81,22,12,1],
[81,22,12,2],
[81,22,12,3],
[81,22,12,4],
[81,22,12,5],
[81,22,12,6],
[81,22,12,7],
[81,22,12,8],
[81,22,12,9],
[81,22,13,0],
[81,22,13,1],
[81,22,13,2],
[81,22,13,3],
[81,22,13,4],
[81,22,13,5],
[81,22,13,6],
[81,22,13,7],
[81,22,13,8],
[81,22,13,9],
[81,22,13,11],
[81,22,13,12],
[81,22,14,0],
[81,22,14,1],
[81,22,14,2],
[81,22,14,3],
[81,22,14,4],
[81,22,14,5],
[81,22,14,6],
[81,22,14,7],
[81,22,14,8],
[81,22,14,9],
[81,22,14,11],
[81,22,14,12],
[81,22,15,0],
[81,22,15,1],
[81,22,15,2],
[81,22,15,3],
[81,22,15,4],
[81,22,15,5],
[81,22,15,6],
[81,22,15,7],
[81,22,15,8],
[81,22,15,11],
[81,22,15,12],
[81,22,15,13],
[81,22,15,14],
[81,22,16,0],
[81,22,16,1],
[81,22,16,2],
[81,22,16,3],
[81,22,16,4],
[81,22,16,5],
[81,22,16,6],
[81,22,16,7],
[81,22,16,8],
[81,22,16,9],
[81,22,16,11],
[81,22,16,12],
[81,22,16,13],
[81,22,16,14],
[81,22,16,15],
[81,22,17,0],
[81,22,17,1],
[81,22,17,2],
[81,22,17,3],
[81,22,17,4],
[81,22,17,5],
[81,22,17,6],
[81,22,17,7],
[81,22,17,8],
[81,22,17,9],
[81,22,17,11],
[81,22,17,12],
[81,22,17,13],
[81,22,17,14],
[81,22,17,15],
[81,22,17,16],
[81,22,18,0],
[81,22,18,1],
[81,22,18,2],
[81,22,18,3],
[81,22,18,4],
[81,22,18,5],
[81,22,18,6],
[81,22,18,7],
[81,22,18,8],
[81,22,18,11],
[81,22,18,12],
[81,22,18,13],
[81,22,18,14],
[81,22,19,0],
[81,22,19,1],
[81,22,19,2],
[81,22,19,3],
[81,22,19,4],
[81,22,19,5],
[81,22,19,6],
[81,22,19,7],
[81,22,19,8],
[81,22,19,9],
[81,22,19,11],
[81,22,19,12],
[81,22,19,16],
[81,22,19,17],
[81,22,19,18],
[81,22,21,0],
[81,22,21,1],
[81,22,21,2],
[81,22,21,4],
[81,22,21,7],
[81,22,21,9],
[81,22,21,11],
[81,22,21,14],
[81,22,21,15],
[81,22,21,16],
[81,22,21,17],
[81,22,21,18],
[81,22,21,19],
[81,24,3,0],
[81,24,3,1],
[81,24,3,2],
[81,24,4,0],
[81,24,4,1],
[81,24,4,2],
[81,24,4,3],
[81,24,5,0],
[81,24,5,1],
[81,24,5,2],
[81,24,5,4],
[81,24,6,0],
[81,24,6,1],
[81,24,6,2],
[81,24,6,4],
[81,24,7,0],
[81,24,7,1],
[81,24,7,2],
[81,24,7,3],
[81,24,7,4],
[81,24,7,5],
[81,24,7,6],
[81,24,8,0],
[81,24,8,1],
[81,24,8,2],
[81,24,8,4],
[81,24,8,7],
[81,24,9,0],
[81,24,9,1],
[81,24,9,2],
[81,24,9,3],
[81,24,9,4],
[81,24,9,5],
[81,24,9,6],
[81,24,9,7],
[81,24,9,8],
[81,24,10,0],
[81,24,10,1],
[81,24,10,2],
[81,24,10,3],
[81,24,10,4],
[81,24,10,5],
[81,24,10,6],
[81,24,10,7],
[81,24,10,8],
[81,24,10,9],
[81,24,11,0],
[81,24,11,1],
[81,24,11,2],
[81,24,11,3],
[81,24,11,4],
[81,24,11,5],
[81,24,11,6],
[81,24,11,7],
[81,24,11,8],
[81,24,11,9],
[81,24,11,10],
[81,24,12,0],
[81,24,12,1],
[81,24,12,2],
[81,24,12,3],
[81,24,12,4],
[81,24,12,5],
[81,24,12,6],
[81,24,12,7],
[81,24,12,8],
[81,24,12,9],
[81,24,12,10],
[81,24,13,0],
[81,24,13,1],
[81,24,13,2],
[81,24,13,3],
[81,24,13,4],
[81,24,13,5],
[81,24,13,6],
[81,24,13,7],
[81,24,13,8],
[81,24,13,9],
[81,24,13,10],
[81,24,13,11],
[81,24,13,12],
[81,24,14,0],
[81,24,14,1],
[81,24,14,2],
[81,24,14,3],
[81,24,14,4],
[81,24,14,5],
[81,24,14,6],
[81,24,14,7],
[81,24,14,8],
[81,24,14,9],
[81,24,14,10],
[81,24,14,11],
[81,24,14,12],
[81,24,15,0],
[81,24,15,1],
[81,24,15,2],
[81,24,15,3],
[81,24,15,4],
[81,24,15,5],
[81,24,15,6],
[81,24,15,7],
[81,24,15,8],
[81,24,15,10],
[81,24,15,11],
[81,24,15,12],
[81,24,15,13],
[81,24,15,14],
[81,24,17,0],
[81,24,17,1],
[81,24,17,2],
[81,24,17,3],
[81,24,17,4],
[81,24,17,5],
[81,24,17,6],
[81,24,17,7],
[81,24,17,8],
[81,24,17,9],
[81,24,17,10],
[81,24,17,11],
[81,24,17,12],
[81,24,17,13],
[81,24,17,14],
[81,24,17,15],
[81,24,18,0],
[81,24,18,1],
[81,24,18,2],
[81,24,18,3],
[81,24,18,4],
[81,24,18,5],
[81,24,18,6],
[81,24,18,7],
[81,24,18,8],
[81,24,18,10],
[81,24,18,11],
[81,24,18,12],
[81,24,18,13],
[81,24,18,14],
[81,24,18,17],
[81,24,19,0],
[81,24,19,1],
[81,24,19,2],
[81,24,19,3],
[81,24,19,4],
[81,24,19,5],
[81,24,19,6],
[81,24,19,7],
[81,24,19,8],
[81,24,19,9],
[81,24,19,10],
[81,24,19,11],
[81,24,19,12],
[81,24,19,15],
[81,24,19,17],
[81,24,19,18],
[81,24,20,0],
[81,24,20,1],
[81,24,20,2],
[81,24,20,3],
[81,24,20,4],
[81,24,20,5],
[81,24,20,6],
[81,24,20,7],
[81,24,20,8],
[81,24,20,9],
[81,24,20,11],
[81,24,20,14],
[81,24,20,15],
[81,24,20,17],
[81,24,20,18],
[81,24,20,19],
[81,24,21,0],
[81,24,21,1],
[81,24,21,2],
[81,24,21,4],
[81,24,21,7],
[81,24,21,9],
[81,24,21,12],
[81,24,21,13],
[81,24,21,14],
[81,24,21,15],
[81,24,21,17],
[81,24,21,18],
[81,24,21,19],
[81,24,21,20],
[81,24,22,0],
[81,24,22,1],
[81,24,22,2],
[81,24,22,3],
[81,24,22,4],
[81,24,22,5],
[81,24,22,6],
[81,24,22,7],
[81,24,22,11],
[81,24,22,12],
[81,24,22,13],
[81,24,22,14],
[81,24,22,15],
[81,24,22,17],
[81,24,22,18],
[81,24,22,19],
[81,24,22,21],
[81,25,3,0],
[81,25,3,1],
[81,25,3,2],
[81,25,4,0],
[81,25,4,1],
[81,25,4,2],
[81,25,4,3],
[81,25,5,0],
[81,25,5,1],
[81,25,5,2],
[81,25,5,4],
[81,25,6,0],
[81,25,6,1],
[81,25,6,2],
[81,25,6,4],
[81,25,8,0],
[81,25,8,1],
[81,25,8,2],
[81,25,8,4],
[81,25,9,0],
[81,25,9,1],
[81,25,9,2],
[81,25,9,3],
[81,25,9,4],
[81,25,9,5],
[81,25,9,6],
[81,25,9,8],
[81,25,10,0],
[81,25,10,1],
[81,25,10,2],
[81,25,10,3],
[81,25,10,4],
[81,25,10,5],
[81,25,10,6],
[81,25,10,8],
[81,25,10,9],
[81,25,11,0],
[81,25,11,1],
[81,25,11,2],
[81,25,11,3],
[81,25,11,4],
[81,25,11,5],
[81,25,11,6],
[81,25,11,8],
[81,25,11,9],
[81,25,11,10],
[81,25,12,0],
[81,25,12,1],
[81,25,12,2],
[81,25,12,3],
[81,25,12,4],
[81,25,12,5],
[81,25,12,6],
[81,25,12,8],
[81,25,12,9],
[81,25,12,10],
[81,25,13,0],
[81,25,13,1],
[81,25,13,2],
[81,25,13,3],
[81,25,13,4],
[81,25,13,5],
[81,25,13,6],
[81,25,13,8],
[81,25,13,9],
[81,25,13,10],
[81,25,13,11],
[81,25,13,12],
[81,25,14,0],
[81,25,14,1],
[81,25,14,2],
[81,25,14,3],
[81,25,14,4],
[81,25,14,5],
[81,25,14,6],
[81,25,14,8],
[81,25,14,9],
[81,25,14,10],
[81,25,14,11],
[81,25,14,12],
[81,25,15,0],
[81,25,15,1],
[81,25,15,2],
[81,25,15,3],
[81,25,15,4],
[81,25,15,5],
[81,25,15,6],
[81,25,15,8],
[81,25,15,10],
[81,25,15,11],
[81,25,15,12],
[81,25,15,13],
[81,25,15,14],
[81,25,16,0],
[81,25,16,1],
[81,25,16,2],
[81,25,16,3],
[81,25,16,4],
[81,25,16,5],
[81,25,16,6],
[81,25,16,8],
[81,25,16,9],
[81,25,16,10],
[81,25,16,11],
[81,25,16,12],
[81,25,16,13],
[81,25,16,15],
[81,25,17,0],
[81,25,17,1],
[81,25,17,2],
[81,25,17,3],
[81,25,17,4],
[81,25,17,5],
[81,25,17,6],
[81,25,17,8],
[81,25,17,9],
[81,25,17,10],
[81,25,17,11],
[81,25,17,12],
[81,25,17,13],
[81,25,17,15],
[81,25,17,16],
[81,25,18,0],
[81,25,18,1],
[81,25,18,2],
[81,25,18,3],
[81,25,18,4],
[81,25,18,5],
[81,25,18,6],
[81,25,18,8],
[81,25,18,10],
[81,25,18,11],
[81,25,18,14],
[81,25,18,16],
[81,25,18,17],
[81,25,19,0],
[81,25,19,1],
[81,25,19,2],
[81,25,19,3],
[81,25,19,4],
[81,25,19,5],
[81,25,19,6],
[81,25,19,8],
[81,25,19,9],
[81,25,19,12],
[81,25,19,15],
[81,25,19,16],
[81,25,19,17],
[81,25,19,18],
[81,25,20,0],
[81,25,20,1],
[81,25,20,2],
[81,25,20,3],
[81,25,20,4],
[81,25,20,5],
[81,25,20,6],
[81,25,20,11],
[81,25,20,12],
[81,25,20,13],
[81,25,20,14],
[81,25,20,15],
[81,25,20,16],
[81,25,20,17],
[81,25,20,18],
[81,25,20,19],
[81,25,21,0],
[81,25,21,1],
[81,25,21,2],
[81,25,21,4],
[81,25,21,9],
[81,25,21,10],
[81,25,21,11],
[81,25,21,12],
[81,25,21,13],
[81,25,21,14],
[81,25,21,15],
[81,25,21,16],
[81,25,21,17],
[81,25,21,18],
[81,25,21,19],
[81,25,21,20],
[81,25,22,0],
[81,25,22,1],
[81,25,22,2],
[81,25,22,3],
[81,25,22,4],
[81,25,22,6],
[81,25,22,8],
[81,25,22,9],
[81,25,22,11],
[81,25,22,12],
[81,25,22,13],
[81,25,22,14],
[81,25,22,15],
[81,25,22,16],
[81,25,22,17],
[81,25,22,18],
[81,25,22,19],
[81,25,22,21],
[81,25,24,0],
[81,25,24,1],
[81,25,24,2],
[81,25,24,5],
[81,25,24,6],
[81,25,24,8],
[81,25,24,9],
[81,25,24,10],
[81,25,24,11],
[81,25,24,12],
[81,25,24,13],
[81,25,24,14],
[81,25,24,15],
[81,25,24,17],
[81,25,24,18],
[81,25,24,19],
[81,25,24,20],
[81,25,24,21],
[81,26,3,0],
[81,26,3,1],
[81,26,3,2],
[81,26,4,0],
[81,26,4,1],
[81,26,4,2],
[81,26,4,3],
[81,26,5,0],
[81,26,5,1],
[81,26,5,2],
[81,26,5,4],
[81,26,6,0],
[81,26,6,1],
[81,26,6,2],
[81,26,6,4],
[81,26,7,0],
[81,26,7,1],
[81,26,7,2],
[81,26,7,3],
[81,26,7,4],
[81,26,7,5],
[81,26,7,6],
[81,26,8,0],
[81,26,8,1],
[81,26,8,2],
[81,26,8,4],
[81,26,8,7],
[81,26,10,0],
[81,26,10,1],
[81,26,10,2],
[81,26,10,3],
[81,26,10,4],
[81,26,10,5],
[81,26,10,6],
[81,26,10,7],
[81,26,10,8],
[81,26,11,0],
[81,26,11,1],
[81,26,11,2],
[81,26,11,3],
[81,26,11,4],
[81,26,11,5],
[81,26,11,6],
[81,26,11,7],
[81,26,11,8],
[81,26,11,10],
[81,26,12,0],
[81,26,12,1],
[81,26,12,2],
[81,26,12,3],
[81,26,12,4],
[81,26,12,5],
[81,26,12,6],
[81,26,12,7],
[81,26,12,8],
[81,26,12,10],
[81,26,13,0],
[81,26,13,1],
[81,26,13,2],
[81,26,13,3],
[81,26,13,4],
[81,26,13,5],
[81,26,13,6],
[81,26,13,7],
[81,26,13,8],
[81,26,13,10],
[81,26,13,11],
[81,26,13,12],
[81,26,14,0],
[81,26,14,1],
[81,26,14,2],
[81,26,14,3],
[81,26,14,4],
[81,26,14,5],
[81,26,14,6],
[81,26,14,7],
[81,26,14,8],
[81,26,14,10],
[81,26,14,11],
[81,26,14,12],
[81,26,16,0],
[81,26,16,1],
[81,26,16,2],
[81,26,16,3],
[81,26,16,4],
[81,26,16,5],
[81,26,16,6],
[81,26,16,7],
[81,26,16,8],
[81,26,16,10],
[81,26,16,11],
[81,26,16,14],
[81,26,17,0],
[81,26,17,1],
[81,26,17,2],
[81,26,17,3],
[81,26,17,4],
[81,26,17,5],
[81,26,17,6],
[81,26,17,7],
[81,26,17,8],
[81,26,17,10],
[81,26,17,11],
[81,26,17,14],
[81,26,17,16],
[81,26,19,0],
[81,26,19,1],
[81,26,19,2],
[81,26,19,3],
[81,26,19,4],
[81,26,19,5],
[81,26,19,6],
[81,26,19,7],
[81,26,19,10],
[81,26,19,11],
[81,26,19,12],
[81,26,19,16],
[81,26,19,17],
[81,26,20,0],
[81,26,20,1],
[81,26,20,2],
[81,26,20,3],
[81,26,20,4],
[81,26,20,5],
[81,26,20,8],
[81,26,20,11],
[81,26,20,12],
[81,26,20,13],
[81,26,20,14],
[81,26,20,16],
[81,26,20,17],
[81,26,20,19],
[81,26,21,0],
[81,26,21,1],
[81,26,21,2],
[81,26,21,4],
[81,26,21,7],
[81,26,21,10],
[81,26,21,11],
[81,26,21,12],
[81,26,21,13],
[81,26,21,14],
[81,26,21,16],
[81,26,21,17],
[81,26,21,19],
[81,26,21,20],
[81,26,22,0],
[81,26,22,1],
[81,26,22,2],
[81,26,22,5],
[81,26,22,6],
[81,26,22,7],
[81,26,22,8],
[81,26,22,11],
[81,26,22,12],
[81,26,22,13],
[81,26,22,14],
[81,26,22,16],
[81,26,22,17],
[81,26,22,19],
[81,26,22,21],
[81,26,24,3],
[81,26,24,4],
[81,26,24,5],
[81,26,24,6],
[81,26,24,7],
[81,26,24,8],
[81,26,24,10],
[81,26,24,11],
[81,26,24,12],
[81,26,24,13],
[81,26,24,14],
[81,26,24,17],
[81,26,24,19],
[81,26,24,20],
[81,26,25,0],
[81,26,25,1],
[81,26,25,2],
[81,26,25,3],
[81,26,25,4],
[81,26,25,5],
[81,26,25,6],
[81,26,25,8],
[81,26,25,10],
[81,26,25,11],
[81,26,25,12],
[81,26,25,13],
[81,26,25,14],
[81,26,25,16],
[81,26,25,17],
[81,27,3,0],
[81,27,3,1],
[81,27,3,2],
[81,27,4,0],
[81,27,4,1],
[81,27,4,2],
[81,27,4,3],
[81,27,5,0],
[81,27,5,1],
[81,27,5,2],
[81,27,5,4],
[81,27,6,0],
[81,27,6,1],
[81,27,6,2],
[81,27,6,4],
[81,27,7,0],
[81,27,7,1],
[81,27,7,2],
[81,27,7,3],
[81,27,7,4],
[81,27,7,5],
[81,27,7,6],
[81,27,8,0],
[81,27,8,1],
[81,27,8,2],
[81,27,8,4],
[81,27,8,7],
[81,27,9,0],
[81,27,9,1],
[81,27,9,2],
[81,27,9,3],
[81,27,9,4],
[81,27,9,5],
[81,27,9,6],
[81,27,9,7],
[81,27,9,8],
[81,27,11,0],
[81,27,11,1],
[81,27,11,2],
[81,27,11,3],
[81,27,11,4],
[81,27,11,5],
[81,27,11,6],
[81,27,11,7],
[81,27,11,8],
[81,27,11,9],
[81,27,12,0],
[81,27,12,1],
[81,27,12,2],
[81,27,12,3],
[81,27,12,4],
[81,27,12,5],
[81,27,12,6],
[81,27,12,7],
[81,27,12,8],
[81,27,12,9],
[81,27,13,0],
[81,27,13,1],
[81,27,13,2],
[81,27,13,3],
[81,27,13,4],
[81,27,13,5],
[81,27,13,6],
[81,27,13,7],
[81,27,13,8],
[81,27,13,9],
[81,27,13,11],
[81,27,13,12],
[81,27,14,0],
[81,27,14,1],
[81,27,14,2],
[81,27,14,3],
[81,27,14,4],
[81,27,14,5],
[81,27,14,6],
[81,27,14,7],
[81,27,14,8],
[81,27,14,9],
[81,27,14,11],
[81,27,14,12],
[81,27,15,0],
[81,27,15,1],
[81,27,15,2],
[81,27,15,3],
[81,27,15,4],
[81,27,15,5],
[81,27,15,6],
[81,27,15,7],
[81,27,15,8],
[81,27,15,11],
[81,27,15,14],
[81,27,16,0],
[81,27,16,1],
[81,27,16,2],
[81,27,16,3],
[81,27,16,4],
[81,27,16,5],
[81,27,16,6],
[81,27,16,7],
[81,27,16,8],
[81,27,16,9],
[81,27,16,12],
[81,27,16,13],
[81,27,16,14],
[81,27,16,15],
[81,27,17,0],
[81,27,17,1],
[81,27,17,2],
[81,27,17,3],
[81,27,17,4],
[81,27,17,5],
[81,27,17,6],
[81,27,17,7],
[81,27,17,8],
[81,27,17,9],
[81,27,17,12],
[81,27,17,13],
[81,27,17,14],
[81,27,17,15],
[81,27,17,16],
[81,27,18,0],
[81,27,18,1],
[81,27,18,2],
[81,27,18,3],
[81,27,18,4],
[81,27,18,5],
[81,27,18,6],
[81,27,18,7],
[81,27,18,11],
[81,27,18,12],
[81,27,18,13],
[81,27,18,14],
[81,27,18,16],
[81,27,18,17],
[81,27,19,0],
[81,27,19,1],
[81,27,19,2],
[81,27,19,3],
[81,27,19,4],
[81,27,19,5],
[81,27,19,8],
[81,27,19,9],
[81,27,19,11],
[81,27,19,12],
[81,27,19,15],
[81,27,19,16],
[81,27,19,17],
[81,27,19,18],
[81,27,21,0],
[81,27,21,1],
[81,27,21,2],
[81,27,21,7],
[81,27,21,9],
[81,27,21,11],
[81,27,21,12],
[81,27,21,13],
[81,27,21,14],
[81,27,21,15],
[81,27,21,16],
[81,27,21,17],
[81,27,21,18],
[81,27,21,19],
[81,27,24,0],
[81,27,24,1],
[81,27,24,2],
[81,27,24,3],
[81,27,24,4],
[81,27,24,5],
[81,27,24,6],
[81,27,24,7],
[81,27,24,8],
[81,27,24,9],
[81,27,24,11],
[81,27,24,12],
[81,27,24,13],
[81,27,24,14],
[81,27,24,15],
[81,27,24,17],
[81,27,24,18],
[81,27,24,19],
[81,27,25,0],
[81,27,25,1],
[81,27,25,2],
[81,27,25,3],
[81,27,25,4],
[81,27,25,5],
[81,27,25,6],
[81,27,25,8],
[81,27,25,9],
[81,27,25,11],
[81,27,25,12],
[81,27,25,13],
[81,27,25,14],
[81,27,25,15],
[81,27,25,16],
[81,27,25,17],
[81,27,26,0],
[81,27,26,1],
[81,27,26,2],
[81,27,26,3],
[81,27,26,4],
[81,27,26,5],
[81,27,26,6],
[81,27,26,7],
[81,27,26,8],
[81,27,26,11],
[81,27,26,12],
[81,27,26,13],
[81,27,26,14],
[81,28,3,0],
[81,28,3,1],
[81,28,3,2],
[81,28,4,0],
[81,28,4,1],
[81,28,4,2],
[81,28,4,3],
[81,28,5,0],
[81,28,5,1],
[81,28,5,2],
[81,28,5,4],
[81,28,6,0],
[81,28,6,1],
[81,28,6,2],
[81,28,6,4],
[81,28,8,0],
[81,28,8,1],
[81,28,8,2],
[81,28,8,4],
[81,28,9,0],
[81,28,9,1],
[81,28,9,2],
[81,28,9,3],
[81,28,9,4],
[81,28,9,5],
[81,28,9,6],
[81,28,9,8],
[81,28,10,0],
[81,28,10,1],
[81,28,10,2],
[81,28,10,3],
[81,28,10,4],
[81,28,10,5],
[81,28,10,6],
[81,28,10,8],
[81,28,10,9],
[81,28,11,0],
[81,28,11,1],
[81,28,11,2],
[81,28,11,3],
[81,28,11,4],
[81,28,11,5],
[81,28,11,6],
[81,28,11,8],
[81,28,11,9],
[81,28,11,10],
[81,28,12,0],
[81,28,12,1],
[81,28,12,2],
[81,28,12,3],
[81,28,12,4],
[81,28,12,5],
[81,28,12,6],
[81,28,12,8],
[81,28,12,9],
[81,28,12,10],
[81,28,13,0],
[81,28,13,1],
[81,28,13,2],
[81,28,13,3],
[81,28,13,4],
[81,28,13,5],
[81,28,13,6],
[81,28,13,8],
[81,28,13,9],
[81,28,13,10],
[81,28,13,11],
[81,28,13,12],
[81,28,14,0],
[81,28,14,1],
[81,28,14,2],
[81,28,14,3],
[81,28,14,4],
[81,28,14,5],
[81,28,14,6],
[81,28,14,8],
[81,28,14,9],
[81,28,14,10],
[81,28,14,11],
[81,28,15,0],
[81,28,15,1],
[81,28,15,2],
[81,28,15,3],
[81,28,15,4],
[81,28,15,5],
[81,28,15,6],
[81,28,15,8],
[81,28,15,12],
[81,28,15,13],
[81,28,15,14],
[81,28,16,0],
[81,28,16,1],
[81,28,16,2],
[81,28,16,3],
[81,28,16,4],
[81,28,16,5],
[81,28,16,6],
[81,28,16,10],
[81,28,16,11],
[81,28,16,12],
[81,28,16,13],
[81,28,16,14],
[81,28,16,15],
[81,28,17,0],
[81,28,17,1],
[81,28,17,2],
[81,28,17,3],
[81,28,17,4],
[81,28,17,5],
[81,28,17,6],
[81,28,17,10],
[81,28,17,11],
[81,28,17,12],
[81,28,17,13],
[81,28,17,14],
[81,28,17,15],
[81,28,17,16],
[81,28,18,0],
[81,28,18,1],
[81,28,18,2],
[81,28,18,3],
[81,28,18,4],
[81,28,18,5],
[81,28,18,8],
[81,28,18,10],
[81,28,18,11],
[81,28,18,12],
[81,28,18,13],
[81,28,18,14],
[81,28,18,16],
[81,28,18,17],
[81,28,19,0],
[81,28,19,1],
[81,28,19,2],
[81,28,19,3],
[81,28,19,4],
[81,28,19,6],
[81,28,19,8],
[81,28,19,9],
[81,28,19,10],
[81,28,19,11],
[81,28,19,12],
[81,28,19,15],
[81,28,19,16],
[81,28,19,17],
[81,28,19,18],
[81,28,20,0],
[81,28,20,1],
[81,28,20,2],
[81,28,20,5],
[81,28,20,6],
[81,28,20,8],
[81,28,20,9],
[81,28,20,11],
[81,28,20,12],
[81,28,20,13],
[81,28,20,14],
[81,28,20,15],
[81,28,20,16],
[81,28,20,17],
[81,28,20,18],
[81,28,20,19],
[81,28,21,4],
[81,28,21,9],
[81,28,21,10],
[81,28,21,11],
[81,28,21,12],
[81,28,21,13],
[81,28,21,14],
[81,28,21,15],
[81,28,21,16],
[81,28,21,17],
[81,28,21,18],
[81,28,21,19],
[81,28,21,20],
[81,28,22,0],
[81,28,22,1],
[81,28,22,2],
[81,28,22,3],
[81,28,22,4],
[81,28,22,5],
[81,28,22,6],
[81,28,22,8],
[81,28,22,9],
[81,28,22,11],
[81,28,22,12],
[81,28,22,13],
[81,28,22,14],
[81,28,22,15],
[81,28,22,16],
[81,28,22,17],
[81,28,22,18],
[81,28,22,19],
[81,28,24,0],
[81,28,24,1],
[81,28,24,2],
[81,28,24,3],
[81,28,24,4],
[81,28,24,5],
[81,28,24,6],
[81,28,24,8],
[81,28,24,9],
[81,28,24,10],
[81,28,24,11],
[81,28,24,12],
[81,28,24,13],
[81,28,24,14],
[81,28,24,15],
[81,28,24,17],
[81,28,24,18],
[81,28,26,0],
[81,28,26,1],
[81,28,26,2],
[81,28,26,3],
[81,28,26,4],
[81,28,26,5],
[81,28,26,6],
[81,28,26,8],
[81,28,26,10],
[81,28,26,11],
[81,28,26,12],
[81,28,26,13],
[81,28,26,14],
[81,28,27,0],
[81,28,27,1],
[81,28,27,2],
[81,28,27,3],
[81,28,27,4],
[81,28,27,5],
[81,28,27,6],
[81,28,27,8],
[81,28,27,9],
[81,28,27,11],
[81,28,27,12],
[81,28,27,13],
[81,29,3,0],
[81,29,3,1],
[81,29,3,2],
[81,29,4,0],
[81,29,4,1],
[81,29,4,2],
[81,29,4,3],
[81,29,5,0],
[81,29,5,1],
[81,29,5,2],
[81,29,5,4],
[81,29,6,0],
[81,29,6,1],
[81,29,6,2],
[81,29,6,4],
[81,29,7,0],
[81,29,7,1],
[81,29,7,2],
[81,29,7,3],
[81,29,7,4],
[81,29,7,5],
[81,29,7,6],
[81,29,8,0],
[81,29,8,1],
[81,29,8,2],
[81,29,8,4],
[81,29,8,7],
[81,29,9,0],
[81,29,9,1],
[81,29,9,2],
[81,29,9,3],
[81,29,9,4],
[81,29,9,5],
[81,29,9,6],
[81,29,9,7],
[81,29,9,8],
[81,29,10,0],
[81,29,10,1],
[81,29,10,2],
[81,29,10,3],
[81,29,10,4],
[81,29,10,5],
[81,29,10,6],
[81,29,10,7],
[81,29,10,8],
[81,29,10,9],
[81,29,13,0],
[81,29,13,1],
[81,29,13,2],
[81,29,13,3],
[81,29,13,4],
[81,29,13,5],
[81,29,13,6],
[81,29,13,7],
[81,29,13,8],
[81,29,13,9],
[81,29,13,10],
[81,29,14,0],
[81,29,14,1],
[81,29,14,2],
[81,29,14,3],
[81,29,14,4],
[81,29,14,5],
[81,29,14,6],
[81,29,14,7],
[81,29,14,8],
[81,29,14,9],
[81,29,15,0],
[81,29,15,1],
[81,29,15,2],
[81,29,15,3],
[81,29,15,4],
[81,29,15,5],
[81,29,15,6],
[81,29,15,7],
[81,29,15,10],
[81,29,15,13],
[81,29,15,14],
[81,29,16,0],
[81,29,16,1],
[81,29,16,2],
[81,29,16,3],
[81,29,16,4],
[81,29,16,5],
[81,29,16,8],
[81,29,16,9],
[81,29,16,10],
[81,29,16,13],
[81,29,16,14],
[81,29,16,15],
[81,29,17,0],
[81,29,17,1],
[81,29,17,2],
[81,29,17,3],
[81,29,17,4],
[81,29,17,5],
[81,29,17,8],
[81,29,17,9],
[81,29,17,10],
[81,29,17,13],
[81,29,17,14],
[81,29,17,15],
[81,29,17,16],
[81,29,18,0],
[81,29,18,1],
[81,29,18,2],
[81,29,18,3],
[81,29,18,4],
[81,29,18,6],
[81,29,18,7],
[81,29,18,8],
[81,29,18,10],
[81,29,18,13],
[81,29,18,14],
[81,29,18,16],
[81,29,18,17],
[81,29,19,0],
[81,29,19,1],
[81,29,19,2],
[81,29,19,5],
[81,29,19,6],
[81,29,19,7],
[81,29,19,8],
[81,29,19,9],
[81,29,19,10],
[81,29,19,15],
[81,29,19,16],
[81,29,19,17],
[81,29,19,18],
[81,29,20,3],
[81,29,20,4],
[81,29,20,5],
[81,29,20,6],
[81,29,20,7],
[81,29,20,8],
[81,29,20,9],
[81,29,20,13],
[81,29,20,14],
[81,29,20,15],
[81,29,20,16],
[81,29,20,17],
[81,29,20,18],
[81,29,20,19],
[81,29,21,0],
[81,29,21,1],
[81,29,21,2],
[81,29,21,4],
[81,29,21,7],
[81,29,21,9],
[81,29,21,10],
[81,29,21,13],
[81,29,21,14],
[81,29,21,15],
[81,29,21,16],
[81,29,21,17],
[81,29,21,18],
[81,29,21,19],
[81,29,22,0],
[81,29,22,1],
[81,29,22,2],
[81,29,22,3],
[81,29,22,4],
[81,29,22,5],
[81,29,22,6],
[81,29,22,7],
[81,29,22,8],
[81,29,22,9],
[81,29,22,13],
[81,29,22,14],
[81,29,22,15],
[81,29,22,16],
[81,29,22,17],
[81,29,22,18],
[81,29,24,0],
[81,29,24,1],
[81,29,24,2],
[81,29,24,3],
[81,29,24,4],
[81,29,24,5],
[81,29,24,6],
[81,29,24,7],
[81,29,24,8],
[81,29,24,9],
[81,29,24,10],
[81,29,24,13],
[81,29,24,14],
[81,29,24,15],
[81,29,24,17],
[81,29,25,0],
[81,29,25,1],
[81,29,25,2],
[81,29,25,3],
[81,29,25,4],
[81,29,25,5],
[81,29,25,6],
[81,29,25,8],
[81,29,25,9],
[81,29,25,10],
[81,29,25,13],
[81,29,25,14],
[81,29,26,0],
[81,29,26,1],
[81,29,26,2],
[81,29,26,3],
[81,29,26,4],
[81,29,26,5],
[81,29,26,6],
[81,29,26,7],
[81,29,26,8],
[81,29,26,10],
[81,29,26,13],
[81,29,27,0],
[81,29,27,1],
[81,29,27,2],
[81,29,27,3],
[81,29,27,4],
[81,29,27,5],
[81,29,27,6],
[81,29,27,7],
[81,29,27,8],
[81,29,27,9],
[81,29,28,0],
[81,29,28,1],
[81,29,28,2],
[81,29,28,3],
[81,29,28,4],
[81,29,28,5],
[81,29,28,6],
[81,29,28,8],
[81,29,28,9],
[81,32,3,0],
[81,32,3,1],
[81,32,3,2],
[81,32,4,0],
[81,32,4,1],
[81,32,4,2],
[81,32,4,3],
[81,32,5,0],
[81,32,5,1],
[81,32,5,2],
[81,32,5,4],
[81,32,6,0],
[81,32,6,1],
[81,32,6,2],
[81,32,6,4],
[81,32,7,0],
[81,32,7,1],
[81,32,7,2],
[81,32,7,3],
[81,32,7,4],
[81,32,7,5],
[81,32,7,6],
[81,32,8,0],
[81,32,8,1],
[81,32,8,2],
[81,32,8,4],
[81,32,8,7],
[81,32,9,0],
[81,32,9,1],
[81,32,9,2],
[81,32,9,3],
[81,32,9,4],
[81,32,9,5],
[81,32,9,6],
[81,32,9,7],
[81,32,9,8],
[81,32,10,0],
[81,32,10,1],
[81,32,10,2],
[81,32,10,3],
[81,32,10,4],
[81,32,10,5],
[81,32,10,6],
[81,32,10,7],
[81,32,13,0],
[81,32,13,1],
[81,32,13,2],
[81,32,13,3],
[81,32,13,4],
[81,32,13,5],
[81,32,13,8],
[81,32,13,9],
[81,32,13,10],
[81,32,14,0],
[81,32,14,1],
[81,32,14,2],
[81,32,14,3],
[81,32,14,4],
[81,32,14,6],
[81,32,14,7],
[81,32,14,8],
[81,32,14,9],
[81,32,14,10],
[81,32,15,0],
[81,32,15,1],
[81,32,15,2],
[81,32,15,5],
[81,32,15,6],
[81,32,15,7],
[81,32,15,8],
[81,32,15,10],
[81,32,15,13],
[81,32,15,14],
[81,32,16,3],
[81,32,16,4],
[81,32,16,5],
[81,32,16,6],
[81,32,16,7],
[81,32,16,8],
[81,32,16,9],
[81,32,16,10],
[81,32,16,13],
[81,32,16,14],
[81,32,16,15],
[81,32,17,3],
[81,32,17,4],
[81,32,17,5],
[81,32,17,6],
[81,32,17,7],
[81,32,17,8],
[81,32,17,9],
[81,32,17,10],
[81,32,17,13],
[81,32,17,14],
[81,32,17,15],
[81,32,17,16],
[81,32,18,0],
[81,32,18,1],
[81,32,18,2],
[81,32,18,3],
[81,32,18,4],
[81,32,18,5],
[81,32,18,6],
[81,32,18,7],
[81,32,18,8],
[81,32,18,10],
[81,32,18,13],
[81,32,18,14],
[81,32,18,16],
[81,32,18,17],
[81,32,19,0],
[81,32,19,1],
[81,32,19,2],
[81,32,19,3],
[81,32,19,4],
[81,32,19,5],
[81,32,19,6],
[81,32,19,7],
[81,32,19,8],
[81,32,19,9],
[81,32,19,10],
[81,32,19,15],
[81,32,19,16],
[81,32,19,17],
[81,32,19,18],
[81,32,20,0],
[81,32,20,1],
[81,32,20,2],
[81,32,20,3],
[81,32,20,4],
[81,32,20,5],
[81,32,20,6],
[81,32,20,7],
[81,32,20,8],
[81,32,20,9],
[81,32,20,13],
[81,32,20,14],
[81,32,20,15],
[81,32,20,16],
[81,32,20,17],
[81,32,21,0],
[81,32,21,1],
[81,32,21,2],
[81,32,21,4],
[81,32,21,7],
[81,32,21,9],
[81,32,21,10],
[81,32,21,13],
[81,32,21,14],
[81,32,21,15],
[81,32,22,0],
[81,32,22,1],
[81,32,22,2],
[81,32,22,3],
[81,32,22,4],
[81,32,22,5],
[81,32,22,6],
[81,32,22,7],
[81,32,22,8],
[81,32,22,9],
[81,32,22,13],
[81,32,22,14],
[81,32,24,0],
[81,32,24,1],
[81,32,24,2],
[81,32,24,3],
[81,32,24,4],
[81,32,24,5],
[81,32,24,6],
[81,32,24,7],
[81,32,24,8],
[81,32,24,9],
[81,32,24,10],
[81,32,24,13],
[81,32,25,0],
[81,32,25,1],
[81,32,25,2],
[81,32,25,3],
[81,32,25,4],
[81,32,25,5],
[81,32,25,6],
[81,32,25,8],
[81,32,25,9],
[81,32,26,0],
[81,32,26,1],
[81,32,26,2],
[81,32,26,3],
[81,32,26,4],
[81,32,26,5],
[81,32,26,6],
[81,32,26,7],
[81,32,27,0],
[81,32,27,1],
[81,32,27,2],
[81,32,27,3],
[81,32,27,4],
[81,32,27,5],
[81,32,28,0],
[81,32,28,1],
[81,32,28,2],
[81,32,28,3],
[81,32,28,4],
[81,33,3,0],
[81,33,3,1],
[81,33,3,2],
[81,33,4,0],
[81,33,4,1],
[81,33,4,2],
[81,33,4,3],
[81,33,5,0],
[81,33,5,1],
[81,33,5,2],
[81,33,5,4],
[81,33,6,0],
[81,33,6,1],
[81,33,6,2],
[81,33,6,4],
[81,33,7,0],
[81,33,7,1],
[81,33,7,2],
[81,33,7,3],
[81,33,7,4],
[81,33,7,5],
[81,33,7,6],
[81,33,8,0],
[81,33,8,1],
[81,33,8,2],
[81,33,8,4],
[81,33,8,7],
[81,33,9,0],
[81,33,9,1],
[81,33,9,2],
[81,33,9,3],
[81,33,9,4],
[81,33,9,5],
[81,33,9,6],
[81,33,9,7],
[81,33,10,0],
[81,33,10,1],
[81,33,10,2],
[81,33,10,3],
[81,33,10,4],
[81,33,10,5],
[81,33,10,8],
[81,33,10,9],
[81,33,13,0],
[81,33,13,1],
[81,33,13,2],
[81,33,13,3],
[81,33,13,4],
[81,33,13,6],
[81,33,13,7],
[81,33,13,8],
[81,33,13,9],
[81,33,13,10],
[81,33,14,0],
[81,33,14,1],
[81,33,14,2],
[81,33,14,5],
[81,33,14,6],
[81,33,14,7],
[81,33,14,8],
[81,33,14,9],
[81,33,14,10],
[81,33,15,3],
[81,33,15,4],
[81,33,15,5],
[81,33,15,6],
[81,33,15,7],
[81,33,15,8],
[81,33,15,10],
[81,33,15,13],
[81,33,15,14],
[81,33,16,0],
[81,33,16,1],
[81,33,16,2],
[81,33,16,3],
[81,33,16,4],
[81,33,16,5],
[81,33,16,6],
[81,33,16,7],
[81,33,16,8],
[81,33,16,9],
[81,33,16,10],
[81,33,16,13],
[81,33,16,14],
[81,33,16,15],
[81,33,17,0],
[81,33,17,1],
[81,33,17,2],
[81,33,17,3],
[81,33,17,4],
[81,33,17,5],
[81,33,17,6],
[81,33,17,7],
[81,33,17,8],
[81,33,17,9],
[81,33,17,10],
[81,33,17,13],
[81,33,17,14],
[81,33,17,15],
[81,33,17,16],
[81,33,18,0],
[81,33,18,1],
[81,33,18,2],
[81,33,18,3],
[81,33,18,4],
[81,33,18,5],
[81,33,18,6],
[81,33,18,7],
[81,33,18,8],
[81,33,18,10],
[81,33,18,13],
[81,33,18,14],
[81,33,18,16],
[81,33,18,17],
[81,33,19,0],
[81,33,19,1],
[81,33,19,2],
[81,33,19,3],
[81,33,19,4],
[81,33,19,5],
[81,33,19,6],
[81,33,19,7],
[81,33,19,8],
[81,33,19,9],
[81,33,19,10],
[81,33,19,15],
[81,33,19,16],
[81,33,19,17],
[81,33,20,0],
[81,33,20,1],
[81,33,20,2],
[81,33,20,3],
[81,33,20,4],
[81,33,20,5],
[81,33,20,6],
[81,33,20,7],
[81,33,20,8],
[81,33,20,9],
[81,33,20,13],
[81,33,20,14],
[81,33,20,15],
[81,33,21,0],
[81,33,21,1],
[81,33,21,2],
[81,33,21,4],
[81,33,21,7],
[81,33,21,9],
[81,33,21,10],
[81,33,21,13],
[81,33,21,14],
[81,33,22,0],
[81,33,22,1],
[81,33,22,2],
[81,33,22,3],
[81,33,22,4],
[81,33,22,5],
[81,33,22,6],
[81,33,22,7],
[81,33,22,8],
[81,33,22,9],
[81,33,22,13],
[81,33,24,0],
[81,33,24,1],
[81,33,24,2],
[81,33,24,3],
[81,33,24,4],
[81,33,24,5],
[81,33,24,6],
[81,33,24,7],
[81,33,24,8],
[81,33,24,9],
[81,33,24,10],
[81,33,25,0],
[81,33,25,1],
[81,33,25,2],
[81,33,25,3],
[81,33,25,4],
[81,33,25,5],
[81,33,25,6],
[81,33,26,0],
[81,33,26,1],
[81,33,26,2],
[81,33,26,3],
[81,33,26,4],
[81,33,26,5],
[81,33,27,0],
[81,33,27,1],
[81,33,27,2],
[81,33,27,3],
[81,33,27,4],
[81,33,28,0],
[81,33,28,1],
[81,33,28,2],
[81,34,3,0],
[81,34,3,1],
[81,34,3,2],
[81,34,4,0],
[81,34,4,1],
[81,34,4,2],
[81,34,4,3],
[81,34,5,0],
[81,34,5,1],
[81,34,5,2],
[81,34,5,4],
[81,34,6,0],
[81,34,6,1],
[81,34,6,2],
[81,34,6,4],
[81,34,8,0],
[81,34,8,1],
[81,34,8,2],
[81,34,8,4],
[81,34,9,0],
[81,34,9,1],
[81,34,9,2],
[81,34,9,3],
[81,34,9,4],
[81,34,9,5],
[81,34,9,8],
[81,34,10,0],
[81,34,10,1],
[81,34,10,2],
[81,34,10,3],
[81,34,10,4],
[81,34,10,6],
[81,34,10,8],
[81,34,10,9],
[81,34,11,0],
[81,34,11,1],
[81,34,11,2],
[81,34,11,3],
[81,34,11,4],
[81,34,11,6],
[81,34,11,8],
[81,34,11,9],
[81,34,11,10],
[81,34,12,0],
[81,34,12,1],
[81,34,12,2],
[81,34,12,5],
[81,34,12,6],
[81,34,12,8],
[81,34,12,9],
[81,34,12,10],
[81,34,13,0],
[81,34,13,1],
[81,34,13,2],
[81,34,13,5],
[81,34,13,6],
[81,34,13,8],
[81,34,13,9],
[81,34,13,10],
[81,34,13,11],
[81,34,13,12],
[81,34,14,3],
[81,34,14,4],
[81,34,14,5],
[81,34,14,6],
[81,34,14,8],
[81,34,14,9],
[81,34,14,10],
[81,34,14,11],
[81,34,14,12],
[81,34,15,0],
[81,34,15,1],
[81,34,15,2],
[81,34,15,3],
[81,34,15,4],
[81,34,15,5],
[81,34,15,6],
[81,34,15,8],
[81,34,15,10],
[81,34,15,11],
[81,34,15,12],
[81,34,15,13],
[81,34,15,14],
[81,34,16,0],
[81,34,16,1],
[81,34,16,2],
[81,34,16,3],
[81,34,16,4],
[81,34,16,5],
[81,34,16,6],
[81,34,16,8],
[81,34,16,9],
[81,34,16,10],
[81,34,16,11],
[81,34,16,12],
[81,34,16,13],
[81,34,16,14],
[81,34,16,15],
[81,34,17,0],
[81,34,17,1],
[81,34,17,2],
[81,34,17,3],
[81,34,17,4],
[81,34,17,5],
[81,34,17,6],
[81,34,17,8],
[81,34,17,9],
[81,34,17,10],
[81,34,17,11],
[81,34,17,12],
[81,34,17,13],
[81,34,17,14],
[81,34,17,15],
[81,34,17,16],
[81,34,18,0],
[81,34,18,1],
[81,34,18,2],
[81,34,18,3],
[81,34,18,4],
[81,34,18,5],
[81,34,18,6],
[81,34,18,8],
[81,34,18,10],
[81,34,18,11],
[81,34,18,12],
[81,34,18,13],
[81,34,18,14],
[81,34,18,16],
[81,34,18,17],
[81,34,19,0],
[81,34,19,1],
[81,34,19,2],
[81,34,19,3],
[81,34,19,4],
[81,34,19,5],
[81,34,19,6],
[81,34,19,8],
[81,34,19,9],
[81,34,19,10],
[81,34,19,11],
[81,34,19,12],
[81,34,19,15],
[81,34,20,0],
[81,34,20,1],
[81,34,20,2],
[81,34,20,3],
[81,34,20,4],
[81,34,20,5],
[81,34,20,6],
[81,34,20,8],
[81,34,20,9],
[81,34,20,11],
[81,34,20,12],
[81,34,20,13],
[81,34,20,14],
[81,34,21,0],
[81,34,21,1],
[81,34,21,2],
[81,34,21,4],
[81,34,21,9],
[81,34,21,10],
[81,34,21,11],
[81,34,21,12],
[81,34,21,13],
[81,34,22,0],
[81,34,22,1],
[81,34,22,2],
[81,34,22,3],
[81,34,22,4],
[81,34,22,5],
[81,34,22,6],
[81,34,22,8],
[81,34,22,9],
[81,34,22,11],
[81,34,24,0],
[81,34,24,1],
[81,34,24,2],
[81,34,24,3],
[81,34,24,4],
[81,34,24,5],
[81,34,24,6],
[81,34,24,8],
[81,34,24,9],
[81,34,26,0],
[81,34,26,1],
[81,34,26,2],
[81,34,26,3],
[81,34,26,4],
[81,34,27,0],
[81,34,27,1],
[81,34,27,2],
[81,35,3,0],
[81,35,3,1],
[81,35,3,2],
[81,35,4,0],
[81,35,4,1],
[81,35,4,2],
[81,35,4,3],
[81,35,5,0],
[81,35,5,1],
[81,35,5,2],
[81,35,5,4],
[81,35,6,0],
[81,35,6,1],
[81,35,6,2],
[81,35,6,4],
[81,35,7,0],
[81,35,7,1],
[81,35,7,2],
[81,35,7,3],
[81,35,7,4],
[81,35,7,5],
[81,35,8,0],
[81,35,8,1],
[81,35,8,2],
[81,35,8,4],
[81,35,8,7],
[81,35,10,0],
[81,35,10,1],
[81,35,10,2],
[81,35,10,5],
[81,35,10,6],
[81,35,10,7],
[81,35,10,8],
[81,35,11,0],
[81,35,11,1],
[81,35,11,2],
[81,35,11,5],
[81,35,11,6],
[81,35,11,7],
[81,35,11,8],
[81,35,11,10],
[81,35,12,3],
[81,35,12,4],
[81,35,12,5],
[81,35,12,6],
[81,35,12,7],
[81,35,12,8],
[81,35,12,10],
[81,35,13,3],
[81,35,13,4],
[81,35,13,5],
[81,35,13,6],
[81,35,13,7],
[81,35,13,8],
[81,35,13,10],
[81,35,13,11],
[81,35,13,12],
[81,35,14,0],
[81,35,14,1],
[81,35,14,2],
[81,35,14,3],
[81,35,14,4],
[81,35,14,5],
[81,35,14,6],
[81,35,14,7],
[81,35,14,8],
[81,35,14,10],
[81,35,14,11],
[81,35,14,12],
[81,35,16,0],
[81,35,16,1],
[81,35,16,2],
[81,35,16,3],
[81,35,16,4],
[81,35,16,5],
[81,35,16,6],
[81,35,16,7],
[81,35,16,8],
[81,35,16,10],
[81,35,16,11],
[81,35,16,12],
[81,35,16,13],
[81,35,16,14],
[81,35,17,0],
[81,35,17,1],
[81,35,17,2],
[81,35,17,3],
[81,35,17,4],
[81,35,17,5],
[81,35,17,6],
[81,35,17,7],
[81,35,17,8],
[81,35,17,10],
[81,35,17,11],
[81,35,17,12],
[81,35,17,13],
[81,35,17,14],
[81,35,17,16],
[81,35,19,0],
[81,35,19,1],
[81,35,19,2],
[81,35,19,3],
[81,35,19,4],
[81,35,19,5],
[81,35,19,6],
[81,35,19,7],
[81,35,19,8],
[81,35,19,10],
[81,35,19,11],
[81,35,19,12],
[81,35,20,0],
[81,35,20,1],
[81,35,20,2],
[81,35,20,3],
[81,35,20,4],
[81,35,20,5],
[81,35,20,6],
[81,35,20,7],
[81,35,20,8],
[81,35,20,11],
[81,35,20,12],
[81,35,20,13],
[81,35,21,0],
[81,35,21,1],
[81,35,21,2],
[81,35,21,4],
[81,35,21,7],
[81,35,21,10],
[81,35,21,11],
[81,35,22,0],
[81,35,22,1],
[81,35,22,2],
[81,35,22,3],
[81,35,22,4],
[81,35,22,5],
[81,35,22,6],
[81,35,22,7],
[81,35,22,8],
[81,35,24,0],
[81,35,24,1],
[81,35,24,2],
[81,35,24,3],
[81,35,24,4],
[81,35,24,5],
[81,35,24,6],
[81,35,24,7],
[81,35,25,0],
[81,35,25,1],
[81,35,25,2],
[81,35,25,3],
[81,35,25,4],
[81,36,3,0],
[81,36,3,1],
[81,36,3,2],
[81,36,4,0],
[81,36,4,1],
[81,36,4,2],
[81,36,4,3],
[81,36,5,0],
[81,36,5,1],
[81,36,5,2],
[81,36,5,4],
[81,36,6,0],
[81,36,6,1],
[81,36,6,2],
[81,36,6,4],
[81,36,7,0],
[81,36,7,1],
[81,36,7,2],
[81,36,7,3],
[81,36,7,4],
[81,36,7,6],
[81,36,8,0],
[81,36,8,1],
[81,36,8,2],
[81,36,8,7],
[81,36,9,0],
[81,36,9,1],
[81,36,9,2],
[81,36,9,5],
[81,36,9,6],
[81,36,9,7],
[81,36,9,8],
[81,36,10,3],
[81,36,10,4],
[81,36,10,5],
[81,36,10,6],
[81,36,10,7],
[81,36,10,8],
[81,36,10,9],
[81,36,11,3],
[81,36,11,4],
[81,36,11,5],
[81,36,11,6],
[81,36,11,7],
[81,36,11,8],
[81,36,11,9],
[81,36,11,10],
[81,36,12,0],
[81,36,12,1],
[81,36,12,2],
[81,36,12,3],
[81,36,12,4],
[81,36,12,5],
[81,36,12,6],
[81,36,12,7],
[81,36,12,8],
[81,36,12,9],
[81,36,12,10],
[81,36,15,0],
[81,36,15,1],
[81,36,15,2],
[81,36,15,3],
[81,36,15,4],
[81,36,15,5],
[81,36,15,6],
[81,36,15,7],
[81,36,15,8],
[81,36,15,10],
[81,36,15,11],
[81,36,15,12],
[81,36,16,0],
[81,36,16,1],
[81,36,16,2],
[81,36,16,3],
[81,36,16,4],
[81,36,16,5],
[81,36,16,6],
[81,36,16,7],
[81,36,16,8],
[81,36,16,9],
[81,36,16,10],
[81,36,16,11],
[81,36,16,12],
[81,36,16,15],
[81,36,17,0],
[81,36,17,1],
[81,36,17,2],
[81,36,17,3],
[81,36,17,4],
[81,36,17,5],
[81,36,17,6],
[81,36,17,7],
[81,36,17,8],
[81,36,17,9],
[81,36,17,10],
[81,36,17,11],
[81,36,17,12],
[81,36,17,15],
[81,36,18,0],
[81,36,18,1],
[81,36,18,2],
[81,36,18,3],
[81,36,18,4],
[81,36,18,5],
[81,36,18,6],
[81,36,18,7],
[81,36,18,8],
[81,36,18,10],
[81,36,18,11],
[81,36,18,12],
[81,36,20,0],
[81,36,20,1],
[81,36,20,2],
[81,36,20,3],
[81,36,20,4],
[81,36,20,5],
[81,36,20,6],
[81,36,20,7],
[81,36,20,8],
[81,36,20,9],
[81,36,20,11],
[81,36,21,0],
[81,36,21,1],
[81,36,21,2],
[81,36,21,4],
[81,36,21,7],
[81,36,21,9],
[81,36,22,0],
[81,36,22,1],
[81,36,22,2],
[81,36,22,3],
[81,36,22,4],
[81,36,22,5],
[81,36,22,6],
[81,36,22,7],
[81,36,24,0],
[81,36,24,1],
[81,36,24,2],
[81,36,24,3],
[81,36,24,4],
[81,36,24,5],
[81,36,25,0],
[81,36,25,1],
[81,36,25,2],
[81,37,3,0],
[81,37,3,1],
[81,37,3,2],
[81,37,5,0],
[81,37,5,1],
[81,37,5,2],
[81,37,6,0],
[81,37,6,1],
[81,37,6,2],
[81,37,7,0],
[81,37,7,1],
[81,37,7,2],
[81,37,7,5],
[81,37,7,6],
[81,37,8,7],
[81,37,9,3],
[81,37,9,5],
[81,37,9,6],
[81,37,9,7],
[81,37,9,8],
[81,37,10,0],
[81,37,10,1],
[81,37,10,2],
[81,37,10,3],
[81,37,10,5],
[81,37,10,6],
[81,37,10,7],
[81,37,10,8],
[81,37,10,9],
[81,37,11,0],
[81,37,11,1],
[81,37,11,2],
[81,37,11,3],
[81,37,11,5],
[81,37,11,6],
[81,37,11,7],
[81,37,11,8],
[81,37,11,9],
[81,37,11,10],
[81,37,12,0],
[81,37,12,1],
[81,37,12,2],
[81,37,12,3],
[81,37,12,5],
[81,37,12,6],
[81,37,12,7],
[81,37,12,8],
[81,37,12,9],
[81,37,12,10],
[81,37,13,0],
[81,37,13,1],
[81,37,13,2],
[81,37,13,3],
[81,37,13,5],
[81,37,13,6],
[81,37,13,7],
[81,37,13,8],
[81,37,13,9],
[81,37,13,10],
[81,37,13,11],
[81,37,13,12],
[81,37,14,0],
[81,37,14,1],
[81,37,14,2],
[81,37,14,3],
[81,37,14,5],
[81,37,14,6],
[81,37,14,7],
[81,37,14,8],
[81,37,14,9],
[81,37,14,10],
[81,37,14,11],
[81,37,14,12],
[81,37,15,0],
[81,37,15,1],
[81,37,15,2],
[81,37,15,3],
[81,37,15,5],
[81,37,15,6],
[81,37,15,7],
[81,37,15,8],
[81,37,15,10],
[81,37,15,11],
[81,37,15,12],
[81,37,15,13],
[81,37,15,14],
[81,37,16,0],
[81,37,16,1],
[81,37,16,2],
[81,37,16,3],
[81,37,16,5],
[81,37,16,6],
[81,37,16,7],
[81,37,16,8],
[81,37,16,9],
[81,37,16,10],
[81,37,16,11],
[81,37,16,12],
[81,37,16,13],
[81,37,16,14],
[81,37,17,0],
[81,37,17,1],
[81,37,17,2],
[81,37,17,3],
[81,37,17,5],
[81,37,17,6],
[81,37,17,7],
[81,37,17,8],
[81,37,17,9],
[81,37,17,10],
[81,37,17,11],
[81,37,17,12],
[81,37,17,13],
[81,37,17,14],
[81,37,18,0],
[81,37,18,1],
[81,37,18,2],
[81,37,18,3],
[81,37,18,5],
[81,37,18,6],
[81,37,18,7],
[81,37,18,8],
[81,37,18,10],
[81,37,18,11],
[81,37,18,12],
[81,37,18,13],
[81,37,19,0],
[81,37,19,1],
[81,37,19,2],
[81,37,19,3],
[81,37,19,5],
[81,37,19,6],
[81,37,19,7],
[81,37,19,8],
[81,37,19,9],
[81,37,19,10],
[81,37,19,11],
[81,37,20,0],
[81,37,20,1],
[81,37,20,2],
[81,37,20,3],
[81,37,20,5],
[81,37,20,6],
[81,37,20,7],
[81,37,20,8],
[81,37,20,9],
[81,37,21,0],
[81,37,21,1],
[81,37,21,2],
[81,37,21,7],
[81,37,22,0],
[81,37,22,1],
[81,37,22,2],
[81,37,22,3],
[81,37,22,5],
[81,37,24,0],
[81,37,24,1],
[81,37,24,2],
[81,37,24,3],
[81,38,3,0],
[81,38,3,1],
[81,38,3,2],
[81,38,4,0],
[81,38,4,1],
[81,38,4,2],
[81,38,4,3],
[81,38,5,0],
[81,38,5,1],
[81,38,5,2],
[81,38,6,4],
[81,38,8,0],
[81,38,8,1],
[81,38,8,2],
[81,38,8,4],
[81,38,9,0],
[81,38,9,1],
[81,38,9,2],
[81,38,9,3],
[81,38,9,4],
[81,38,9,5],
[81,38,9,6],
[81,38,9,8],
[81,38,10,0],
[81,38,10,1],
[81,38,10,2],
[81,38,10,3],
[81,38,10,4],
[81,38,10,5],
[81,38,10,6],
[81,38,10,8],
[81,38,10,9],
[81,38,11,0],
[81,38,11,1],
[81,38,11,2],
[81,38,11,3],
[81,38,11,4],
[81,38,11,5],
[81,38,11,6],
[81,38,11,8],
[81,38,11,9],
[81,38,11,10],
[81,38,12,0],
[81,38,12,1],
[81,38,12,2],
[81,38,12,3],
[81,38,12,4],
[81,38,12,5],
[81,38,12,6],
[81,38,12,8],
[81,38,12,9],
[81,38,12,10],
[81,38,13,0],
[81,38,13,1],
[81,38,13,2],
[81,38,13,3],
[81,38,13,4],
[81,38,13,5],
[81,38,13,6],
[81,38,13,8],
[81,38,13,9],
[81,38,13,10],
[81,38,13,11],
[81,38,13,12],
[81,38,14,0],
[81,38,14,1],
[81,38,14,2],
[81,38,14,3],
[81,38,14,4],
[81,38,14,5],
[81,38,14,6],
[81,38,14,8],
[81,38,14,9],
[81,38,14,10],
[81,38,14,11],
[81,38,14,12],
[81,38,15,0],
[81,38,15,1],
[81,38,15,2],
[81,38,15,3],
[81,38,15,4],
[81,38,15,5],
[81,38,15,6],
[81,38,15,8],
[81,38,15,10],
[81,38,15,11],
[81,38,15,12],
[81,38,15,13],
[81,38,15,14],
[81,38,16,0],
[81,38,16,1],
[81,38,16,2],
[81,38,16,3],
[81,38,16,4],
[81,38,16,5],
[81,38,16,6],
[81,38,16,8],
[81,38,16,9],
[81,38,16,10],
[81,38,16,11],
[81,38,16,12],
[81,38,16,13],
[81,38,17,0],
[81,38,17,1],
[81,38,17,2],
[81,38,17,3],
[81,38,17,4],
[81,38,17,5],
[81,38,17,6],
[81,38,17,8],
[81,38,17,9],
[81,38,17,10],
[81,38,17,11],
[81,38,17,12],
[81,38,17,13],
[81,38,18,0],
[81,38,18,1],
[81,38,18,2],
[81,38,18,3],
[81,38,18,4],
[81,38,18,5],
[81,38,18,6],
[81,38,18,8],
[81,38,18,10],
[81,38,18,11],
[81,38,19,0],
[81,38,19,1],
[81,38,19,2],
[81,38,19,3],
[81,38,19,4],
[81,38,19,5],
[81,38,19,6],
[81,38,19,8],
[81,38,19,9],
[81,38,20,0],
[81,38,20,1],
[81,38,20,2],
[81,38,20,3],
[81,38,20,4],
[81,38,20,5],
[81,38,20,6],
[81,38,21,0],
[81,38,21,1],
[81,38,21,2],
[81,38,21,4],
[81,38,22,0],
[81,38,22,1],
[81,38,22,2],
[81,38,22,3],
[81,38,22,4],
[81,38,24,0],
[81,38,24,1],
[81,38,24,2],
[81,39,4,0],
[81,39,4,1],
[81,39,4,2],
[81,39,7,0],
[81,39,7,1],
[81,39,7,2],
[81,39,7,4],
[81,39,9,0],
[81,39,9,1],
[81,39,9,2],
[81,39,9,4],
[81,39,9,7],
[81,39,10,0],
[81,39,10,1],
[81,39,10,2],
[81,39,10,4],
[81,39,10,7],
[81,39,10,9],
[81,39,11,0],
[81,39,11,1],
[81,39,11,2],
[81,39,11,4],
[81,39,11,7],
[81,39,11,9],
[81,39,11,10],
[81,39,12,0],
[81,39,12,1],
[81,39,12,2],
[81,39,12,4],
[81,39,12,7],
[81,39,12,9],
[81,39,12,10],
[81,39,13,0],
[81,39,13,1],
[81,39,13,2],
[81,39,13,4],
[81,39,13,7],
[81,39,13,9],
[81,39,13,10],
[81,39,13,11],
[81,39,13,12],
[81,39,14,0],
[81,39,14,1],
[81,39,14,2],
[81,39,14,4],
[81,39,14,7],
[81,39,14,9],
[81,39,14,10],
[81,39,14,11],
[81,39,14,12],
[81,39,15,0],
[81,39,15,1],
[81,39,15,2],
[81,39,15,4],
[81,39,15,7],
[81,39,15,10],
[81,39,15,11],
[81,39,15,12],
[81,39,15,13],
[81,39,16,0],
[81,39,16,1],
[81,39,16,2],
[81,39,16,4],
[81,39,16,7],
[81,39,16,9],
[81,39,16,10],
[81,39,16,11],
[81,39,17,0],
[81,39,17,1],
[81,39,17,2],
[81,39,17,4],
[81,39,17,7],
[81,39,17,9],
[81,39,17,10],
[81,39,17,11],
[81,39,18,0],
[81,39,18,1],
[81,39,18,2],
[81,39,18,4],
[81,39,18,7],
[81,39,19,0],
[81,39,19,1],
[81,39,19,2],
[81,39,19,4],
[81,39,19,7],
[81,39,20,0],
[81,39,20,1],
[81,39,20,2],
[81,39,20,4],
[81,39,22,0],
[81,39,22,1],
[81,39,22,2],
[81,40,4,3],
[81,40,5,0],
[81,40,5,1],
[81,40,5,2],
[81,40,5,4],
[81,40,6,0],
[81,40,6,1],
[81,40,6,2],
[81,40,6,4],
[81,40,7,0],
[81,40,7,1],
[81,40,7,2],
[81,40,7,3],
[81,40,7,4],
[81,40,7,5],
[81,40,7,6],
[81,40,8,0],
[81,40,8,1],
[81,40,8,2],
[81,40,8,4],
[81,40,8,7],
[81,40,10,0],
[81,40,10,1],
[81,40,10,2],
[81,40,10,3],
[81,40,10,4],
[81,40,10,5],
[81,40,10,6],
[81,40,10,7],
[81,40,10,8],
[81,40,11,0],
[81,40,11,1],
[81,40,11,2],
[81,40,11,3],
[81,40,11,4],
[81,40,11,5],
[81,40,11,6],
[81,40,11,7],
[81,40,11,8],
[81,40,11,10],
[81,40,12,0],
[81,40,12,1],
[81,40,12,2],
[81,40,12,3],
[81,40,12,4],
[81,40,12,5],
[81,40,12,6],
[81,40,12,7],
[81,40,12,8],
[81,40,12,10],
[81,40,13,0],
[81,40,13,1],
[81,40,13,2],
[81,40,13,3],
[81,40,13,4],
[81,40,13,5],
[81,40,13,6],
[81,40,13,7],
[81,40,13,8],
[81,40,13,10],
[81,40,13,11],
[81,40,13,12],
[81,40,14,0],
[81,40,14,1],
[81,40,14,2],
[81,40,14,3],
[81,40,14,4],
[81,40,14,5],
[81,40,14,6],
[81,40,14,7],
[81,40,14,8],
[81,40,14,10],
[81,40,14,11],
[81,40,14,12],
[81,40,16,0],
[81,40,16,1],
[81,40,16,2],
[81,40,16,3],
[81,40,16,4],
[81,40,16,5],
[81,40,16,6],
[81,40,16,7],
[81,40,16,8],
[81,40,17,0],
[81,40,17,1],
[81,40,17,2],
[81,40,17,3],
[81,40,17,4],
[81,40,17,5],
[81,40,17,6],
[81,40,17,7],
[81,40,17,8],
[81,40,19,0],
[81,40,19,1],
[81,40,19,2],
[81,40,19,3],
[81,40,19,4],
[81,40,19,5],
[81,40,20,0],
[81,40,20,1],
[81,40,20,2],
[81,40,20,3],
[81,40,20,4],
[81,40,21,0],
[81,40,21,1],
[81,40,21,2],
[81,41,4,3],
[81,41,5,0],
[81,41,5,1],
[81,41,5,2],
[81,41,5,4],
[81,41,6,0],
[81,41,6,1],
[81,41,6,2],
[81,41,6,4],
[81,41,7,0],
[81,41,7,1],
[81,41,7,2],
[81,41,7,3],
[81,41,7,4],
[81,41,7,5],
[81,41,7,6],
[81,41,8,0],
[81,41,8,1],
[81,41,8,2],
[81,41,8,4],
[81,41,8,7],
[81,41,10,0],
[81,41,10,1],
[81,41,10,2],
[81,41,10,3],
[81,41,10,4],
[81,41,10,5],
[81,41,10,6],
[81,41,10,7],
[81,41,10,8],
[81,41,11,0],
[81,41,11,1],
[81,41,11,2],
[81,41,11,3],
[81,41,11,4],
[81,41,11,5],
[81,41,11,6],
[81,41,11,7],
[81,41,11,8],
[81,41,11,10],
[81,41,12,0],
[81,41,12,1],
[81,41,12,2],
[81,41,12,3],
[81,41,12,4],
[81,41,12,5],
[81,41,12,6],
[81,41,12,7],
[81,41,12,8],
[81,41,12,10],
[81,41,13,0],
[81,41,13,1],
[81,41,13,2],
[81,41,13,3],
[81,41,13,4],
[81,41,13,5],
[81,41,13,6],
[81,41,13,7],
[81,41,13,8],
[81,41,13,10],
[81,41,13,11],
[81,41,13,12],
[81,41,14,0],
[81,41,14,1],
[81,41,14,2],
[81,41,14,3],
[81,41,14,4],
[81,41,14,5],
[81,41,14,6],
[81,41,14,7],
[81,41,14,8],
[81,41,14,10],
[81,41,14,11],
[81,41,14,12],
[81,41,16,0],
[81,41,16,1],
[81,41,16,2],
[81,41,16,3],
[81,41,16,4],
[81,41,16,5],
[81,41,16,6],
[81,41,16,7],
[81,41,16,8],
[81,41,17,0],
[81,41,17,1],
[81,41,17,2],
[81,41,17,3],
[81,41,17,4],
[81,41,17,5],
[81,41,17,6],
[81,41,17,7],
[81,41,17,8],
[81,41,19,0],
[81,41,19,1],
[81,41,19,2],
[81,41,19,3],
[81,41,19,4],
[81,41,19,5],
[81,41,20,0],
[81,41,20,1],
[81,41,20,2],
[81,41,20,3],
[81,41,20,4],
[81,41,21,0],
[81,41,21,1],
[81,41,21,2],
[81,42,3,0],
[81,42,3,1],
[81,42,3,2],
[81,42,4,0],
[81,42,4,1],
[81,42,4,2],
[81,42,4,3],
[81,42,5,0],
[81,42,5,1],
[81,42,5,2],
[81,42,5,4],
[81,42,6,0],
[81,42,6,1],
[81,42,6,2],
[81,42,6,4],
[81,42,7,0],
[81,42,7,1],
[81,42,7,2],
[81,42,7,3],
[81,42,7,4],
[81,42,7,5],
[81,42,7,6],
[81,42,8,0],
[81,42,8,1],
[81,42,8,2],
[81,42,8,4],
[81,42,8,7],
[81,42,9,0],
[81,42,9,1],
[81,42,9,2],
[81,42,9,3],
[81,42,9,4],
[81,42,9,5],
[81,42,9,6],
[81,42,9,7],
[81,42,9,8],
[81,42,11,0],
[81,42,11,1],
[81,42,11,2],
[81,42,11,3],
[81,42,11,4],
[81,42,11,5],
[81,42,11,6],
[81,42,11,7],
[81,42,11,8],
[81,42,11,9],
[81,42,12,0],
[81,42,12,1],
[81,42,12,2],
[81,42,12,3],
[81,42,12,4],
[81,42,12,5],
[81,42,12,6],
[81,42,12,7],
[81,42,12,8],
[81,42,12,9],
[81,42,13,0],
[81,42,13,1],
[81,42,13,2],
[81,42,13,3],
[81,42,13,4],
[81,42,13,5],
[81,42,13,6],
[81,42,13,7],
[81,42,13,8],
[81,42,13,9],
[81,42,13,11],
[81,42,13,12],
[81,42,14,0],
[81,42,14,1],
[81,42,14,2],
[81,42,14,3],
[81,42,14,4],
[81,42,14,5],
[81,42,14,6],
[81,42,14,7],
[81,42,14,8],
[81,42,14,9],
[81,42,14,11],
[81,42,15,0],
[81,42,15,1],
[81,42,15,2],
[81,42,15,3],
[81,42,15,4],
[81,42,15,5],
[81,42,15,6],
[81,42,15,7],
[81,42,15,8],
[81,42,16,0],
[81,42,16,1],
[81,42,16,2],
[81,42,16,3],
[81,42,16,4],
[81,42,16,5],
[81,42,16,6],
[81,42,16,7],
[81,42,17,0],
[81,42,17,1],
[81,42,17,2],
[81,42,17,3],
[81,42,17,4],
[81,42,17,5],
[81,42,17,6],
[81,42,17,7],
[81,42,18,0],
[81,42,18,1],
[81,42,18,2],
[81,42,18,3],
[81,42,18,4],
[81,42,18,5],
[81,42,19,0],
[81,42,19,1],
[81,42,19,2],
[81,42,19,3],
[81,42,19,4],
[81,43,3,0],
[81,43,3,1],
[81,43,3,2],
[81,43,4,0],
[81,43,4,1],
[81,43,4,2],
[81,43,4,3],
[81,43,5,0],
[81,43,5,1],
[81,43,5,2],
[81,43,5,4],
[81,43,6,0],
[81,43,6,1],
[81,43,6,2],
[81,43,6,4],
[81,43,8,0],
[81,43,8,1],
[81,43,8,2],
[81,43,8,4],
[81,43,9,0],
[81,43,9,1],
[81,43,9,2],
[81,43,9,3],
[81,43,9,4],
[81,43,9,5],
[81,43,9,6],
[81,43,9,8],
[81,43,10,0],
[81,43,10,1],
[81,43,10,2],
[81,43,10,3],
[81,43,10,4],
[81,43,10,5],
[81,43,10,6],
[81,43,10,8],
[81,43,10,9],
[81,43,11,0],
[81,43,11,1],
[81,43,11,2],
[81,43,11,3],
[81,43,11,4],
[81,43,11,5],
[81,43,11,6],
[81,43,11,8],
[81,43,11,9],
[81,43,11,10],
[81,43,12,0],
[81,43,12,1],
[81,43,12,2],
[81,43,12,3],
[81,43,12,4],
[81,43,12,5],
[81,43,12,6],
[81,43,12,8],
[81,43,12,9],
[81,43,12,10],
[81,43,13,0],
[81,43,13,1],
[81,43,13,2],
[81,43,13,3],
[81,43,13,4],
[81,43,13,5],
[81,43,13,6],
[81,43,13,8],
[81,43,13,9],
[81,43,13,10],
[81,43,13,11],
[81,43,14,0],
[81,43,14,1],
[81,43,14,2],
[81,43,14,3],
[81,43,14,4],
[81,43,14,5],
[81,43,14,6],
[81,43,14,8],
[81,43,14,9],
[81,43,15,0],
[81,43,15,1],
[81,43,15,2],
[81,43,15,3],
[81,43,15,4],
[81,43,15,5],
[81,43,15,6],
[81,43,16,0],
[81,43,16,1],
[81,43,16,2],
[81,43,16,3],
[81,43,16,4],
[81,43,16,5],
[81,43,17,0],
[81,43,17,1],
[81,43,17,2],
[81,43,17,3],
[81,43,17,4],
[81,43,17,5],
[81,43,18,0],
[81,43,18,1],
[81,43,18,2],
[81,43,18,3],
[81,43,18,4],
[81,43,19,0],
[81,43,19,1],
[81,43,19,2],
[81,44,3,0],
[81,44,3,1],
[81,44,3,2],
[81,44,4,0],
[81,44,4,1],
[81,44,4,2],
[81,44,4,3],
[81,44,5,0],
[81,44,5,1],
[81,44,5,2],
[81,44,5,4],
[81,44,6,0],
[81,44,6,1],
[81,44,6,2],
[81,44,6,4],
[81,44,7,0],
[81,44,7,1],
[81,44,7,2],
[81,44,7,3],
[81,44,7,4],
[81,44,7,5],
[81,44,7,6],
[81,44,8,0],
[81,44,8,1],
[81,44,8,2],
[81,44,8,4],
[81,44,8,7],
[81,44,9,0],
[81,44,9,1],
[81,44,9,2],
[81,44,9,3],
[81,44,9,4],
[81,44,9,5],
[81,44,9,6],
[81,44,9,7],
[81,44,9,8],
[81,44,10,0],
[81,44,10,1],
[81,44,10,2],
[81,44,10,3],
[81,44,10,4],
[81,44,10,5],
[81,44,10,6],
[81,44,10,7],
[81,44,10,8],
[81,44,10,9],
[81,44,11,0],
[81,44,11,1],
[81,44,11,2],
[81,44,11,3],
[81,44,11,4],
[81,44,11,5],
[81,44,11,6],
[81,44,11,7],
[81,44,11,8],
[81,44,11,9],
[81,44,11,10],
[81,44,12,0],
[81,44,12,1],
[81,44,12,2],
[81,44,12,3],
[81,44,12,4],
[81,44,12,5],
[81,44,12,6],
[81,44,12,7],
[81,44,12,8],
[81,44,12,9],
[81,44,13,0],
[81,44,13,1],
[81,44,13,2],
[81,44,13,3],
[81,44,13,4],
[81,44,13,5],
[81,44,13,6],
[81,44,13,7],
[81,44,13,8],
[81,44,13,9],
[81,44,14,0],
[81,44,14,1],
[81,44,14,2],
[81,44,14,3],
[81,44,14,4],
[81,44,14,5],
[81,44,14,6],
[81,44,14,7],
[81,44,15,0],
[81,44,15,1],
[81,44,15,2],
[81,44,15,3],
[81,44,15,4],
[81,44,15,5],
[81,44,16,0],
[81,44,16,1],
[81,44,16,2],
[81,44,16,3],
[81,44,16,4],
[81,44,18,0],
[81,44,18,1],
[81,44,18,2],
[81,45,3,0],
[81,45,3,1],
[81,45,3,2],
[81,45,4,0],
[81,45,4,1],
[81,45,4,2],
[81,45,4,3],
[81,45,5,0],
[81,45,5,1],
[81,45,5,2],
[81,45,5,4],
[81,45,6,0],
[81,45,6,1],
[81,45,6,2],
[81,45,6,4],
[81,45,7,0],
[81,45,7,1],
[81,45,7,2],
[81,45,7,3],
[81,45,7,4],
[81,45,7,5],
[81,45,7,6],
[81,45,8,0],
[81,45,8,1],
[81,45,8,2],
[81,45,8,4],
[81,45,8,7],
[81,45,10,0],
[81,45,10,1],
[81,45,10,2],
[81,45,10,3],
[81,45,10,4],
[81,45,10,5],
[81,45,10,6],
[81,45,10,7],
[81,45,10,8],
[81,45,11,0],
[81,45,11,1],
[81,45,11,2],
[81,45,11,3],
[81,45,11,4],
[81,45,11,5],
[81,45,11,6],
[81,45,11,7],
[81,45,11,8],
[81,45,12,0],
[81,45,12,1],
[81,45,12,2],
[81,45,12,3],
[81,45,12,4],
[81,45,12,5],
[81,45,12,6],
[81,45,12,7],
[81,45,13,0],
[81,45,13,1],
[81,45,13,2],
[81,45,13,3],
[81,45,13,4],
[81,45,13,5],
[81,45,13,6],
[81,45,13,7],
[81,45,14,0],
[81,45,14,1],
[81,45,14,2],
[81,45,14,3],
[81,45,14,4],
[81,45,14,5],
[81,45,16,0],
[81,45,16,1],
[81,45,16,2],
[81,45,17,0],
[81,45,17,1],
[81,45,17,2],
[81,46,3,0],
[81,46,3,1],
[81,46,3,2],
[81,46,4,0],
[81,46,4,1],
[81,46,4,2],
[81,46,4,3],
[81,46,5,0],
[81,46,5,1],
[81,46,5,2],
[81,46,5,4],
[81,46,6,0],
[81,46,6,1],
[81,46,6,2],
[81,46,6,4],
[81,46,7,0],
[81,46,7,1],
[81,46,7,2],
[81,46,7,3],
[81,46,7,4],
[81,46,7,5],
[81,46,7,6],
[81,46,8,0],
[81,46,8,1],
[81,46,8,2],
[81,46,8,4],
[81,46,8,7],
[81,46,10,0],
[81,46,10,1],
[81,46,10,2],
[81,46,10,3],
[81,46,10,4],
[81,46,10,5],
[81,46,10,6],
[81,46,10,7],
[81,46,11,0],
[81,46,11,1],
[81,46,11,2],
[81,46,11,3],
[81,46,11,4],
[81,46,11,5],
[81,46,11,6],
[81,46,11,7],
[81,46,12,0],
[81,46,12,1],
[81,46,12,2],
[81,46,12,3],
[81,46,12,4],
[81,46,12,5],
[81,46,13,0],
[81,46,13,1],
[81,46,13,2],
[81,46,13,3],
[81,46,13,4],
[81,46,13,5],
[81,46,14,0],
[81,46,14,1],
[81,46,14,2],
[81,46,14,3],
[81,46,14,4],
[81,48,3,0],
[81,48,3,1],
[81,48,3,2],
[81,48,4,0],
[81,48,4,1],
[81,48,4,2],
[81,48,4,3],
[81,48,5,0],
[81,48,5,1],
[81,48,5,2],
[81,48,5,4],
[81,48,6,0],
[81,48,6,1],
[81,48,6,2],
[81,48,6,4],
[81,48,7,0],
[81,48,7,1],
[81,48,7,2],
[81,48,7,3],
[81,48,7,4],
[81,48,7,5],
[81,48,7,6],
[81,48,8,0],
[81,48,8,1],
[81,48,8,2],
[81,48,8,4],
[81,48,9,0],
[81,48,9,1],
[81,48,9,2],
[81,48,9,3],
[81,48,9,4],
[81,48,9,5],
[81,48,10,0],
[81,48,10,1],
[81,48,10,2],
[81,48,10,3],
[81,48,10,4],
[81,48,13,0],
[81,48,13,1],
[81,48,13,2],
[81,49,3,0],
[81,49,3,1],
[81,49,3,2],
[81,49,4,0],
[81,49,4,1],
[81,49,4,2],
[81,49,4,3],
[81,49,5,0],
[81,49,5,1],
[81,49,5,2],
[81,49,5,4],
[81,49,6,0],
[81,49,6,1],
[81,49,6,2],
[81,49,6,4],
[81,49,7,0],
[81,49,7,1],
[81,49,7,2],
[81,49,7,3],
[81,49,7,4],
[81,49,7,5],
[81,49,7,6],
[81,49,8,0],
[81,49,8,1],
[81,49,8,2],
[81,49,8,4],
[81,49,10,0],
[81,49,10,1],
[81,49,10,2],
[81,49,10,3],
[81,49,10,4],
[81,49,11,0],
[81,49,11,1],
[81,49,11,2],
[81,49,11,3],
[81,49,11,4],
[81,49,12,0],
[81,49,12,1],
[81,49,12,2],
[81,49,13,0],
[81,49,13,1],
[81,49,13,2],
[81,50,3,0],
[81,50,3,1],
[81,50,3,2],
[81,50,4,0],
[81,50,4,1],
[81,50,4,2],
[81,50,4,3],
[81,50,5,0],
[81,50,5,1],
[81,50,5,2],
[81,50,5,4],
[81,50,6,0],
[81,50,6,1],
[81,50,6,2],
[81,50,6,4],
[81,50,7,0],
[81,50,7,1],
[81,50,7,2],
[81,50,7,3],
[81,50,7,4],
[81,50,7,5],
[81,50,7,6],
[81,50,8,0],
[81,50,8,1],
[81,50,8,2],
[81,50,8,4],
[81,50,10,0],
[81,50,10,1],
[81,50,10,2],
[81,50,10,3],
[81,50,10,4],
[81,50,11,0],
[81,50,11,1],
[81,50,11,2],
[81,50,11,3],
[81,50,11,4],
[81,50,12,0],
[81,50,12,1],
[81,50,12,2],
[81,50,13,0],
[81,50,13,1],
[81,50,13,2],
[81,51,4,0],
[81,51,4,1],
[81,51,4,2],
[81,51,7,0],
[81,51,7,1],
[81,51,7,2],
[81,51,7,4],
[81,51,9,0],
[81,51,9,1],
[81,51,9,2],
[81,51,9,4],
[81,51,10,0],
[81,51,10,1],
[81,51,10,2],
[81,51,10,4],
[81,51,11,0],
[81,51,11,1],
[81,51,11,2],
[81,51,11,4],
[81,51,12,0],
[81,51,12,1],
[81,51,12,2],
[81,51,13,0],
[81,51,13,1],
[81,51,13,2],
[81,52,3,0],
[81,52,3,1],
[81,52,3,2],
[81,52,4,0],
[81,52,4,1],
[81,52,4,2],
[81,52,4,3],
[81,52,5,0],
[81,52,5,1],
[81,52,5,2],
[81,52,5,4],
[81,52,6,0],
[81,52,6,1],
[81,52,6,2],
[81,52,6,4],
[81,52,7,0],
[81,52,7,1],
[81,52,7,2],
[81,52,7,3],
[81,52,7,4],
[81,52,7,5],
[81,52,7,6],
[81,52,8,0],
[81,52,8,1],
[81,52,8,2],
[81,52,8,4],
[81,52,10,0],
[81,52,10,1],
[81,52,10,2],
[81,52,10,3],
[81,52,10,4],
[81,52,11,0],
[81,52,11,1],
[81,52,11,2],
[81,52,11,3],
[81,52,11,4],
[81,52,12,0],
[81,52,12,1],
[81,52,12,2],
[81,52,13,0],
[81,52,13,1],
[81,52,13,2],
[81,53,3,0],
[81,53,3,1],
[81,53,3,2],
[81,53,4,0],
[81,53,4,1],
[81,53,4,2],
[81,53,4,3],
[81,53,5,0],
[81,53,5,1],
[81,53,5,2],
[81,53,5,4],
[81,53,6,0],
[81,53,6,1],
[81,53,6,2],
[81,53,6,4],
[81,53,7,0],
[81,53,7,1],
[81,53,7,2],
[81,53,7,3],
[81,53,7,4],
[81,53,7,5],
[81,53,7,6],
[81,53,8,0],
[81,53,8,1],
[81,53,8,2],
[81,53,8,4],
[81,53,9,0],
[81,53,9,1],
[81,53,9,2],
[81,53,9,3],
[81,53,9,4],
[81,53,9,5],
[81,53,11,0],
[81,53,11,1],
[81,53,11,2],
[81,53,11,3],
[81,53,11,4],
[81,53,12,0],
[81,53,12,1],
[81,53,12,2],
[81,53,13,0],
[81,53,13,1],
[81,53,13,2],
[81,54,3,0],
[81,54,3,1],
[81,54,3,2],
[81,54,4,0],
[81,54,4,1],
[81,54,4,2],
[81,54,4,3],
[81,54,5,0],
[81,54,5,1],
[81,54,5,2],
[81,54,5,4],
[81,54,6,0],
[81,54,6,1],
[81,54,6,2],
[81,54,6,4],
[81,54,7,0],
[81,54,7,1],
[81,54,7,2],
[81,54,7,3],
[81,54,7,4],
[81,54,7,5],
[81,54,7,6],
[81,54,8,0],
[81,54,8,1],
[81,54,8,2],
[81,54,8,4],
[81,54,9,0],
[81,54,9,1],
[81,54,9,2],
[81,54,9,3],
[81,54,9,4],
[81,54,9,5],
[81,54,11,0],
[81,54,11,1],
[81,54,11,2],
[81,54,11,3],
[81,54,11,4],
[81,54,12,0],
[81,54,12,1],
[81,54,12,2],
[81,54,13,0],
[81,54,13,1],
[81,54,13,2],
[81,55,3,0],
[81,55,3,1],
[81,55,3,2],
[81,55,4,0],
[81,55,4,1],
[81,55,4,2],
[81,55,4,3],
[81,55,5,0],
[81,55,5,1],
[81,55,5,2],
[81,55,5,4],
[81,55,6,0],
[81,55,6,1],
[81,55,6,2],
[81,55,6,4],
[81,55,7,0],
[81,55,7,1],
[81,55,7,2],
[81,55,7,3],
[81,55,7,4],
[81,55,7,5],
[81,55,7,6],
[81,55,8,0],
[81,55,8,1],
[81,55,8,2],
[81,55,8,4],
[81,55,9,0],
[81,55,9,1],
[81,55,9,2],
[81,55,9,3],
[81,55,9,4],
[81,55,9,5],
[81,55,11,0],
[81,55,11,1],
[81,55,11,2],
[81,55,11,3],
[81,55,11,4],
[81,55,12,0],
[81,55,12,1],
[81,55,12,2],
[81,55,13,0],
[81,55,13,1],
[81,55,13,2],
[81,56,3,0],
[81,56,3,1],
[81,56,3,2],
[81,56,4,0],
[81,56,4,1],
[81,56,4,2],
[81,56,4,3],
[81,56,5,0],
[81,56,5,1],
[81,56,5,2],
[81,56,5,4],
[81,56,6,0],
[81,56,6,1],
[81,56,6,2],
[81,56,6,4],
[81,56,7,0],
[81,56,7,1],
[81,56,7,2],
[81,56,7,3],
[81,56,7,4],
[81,56,7,5],
[81,56,7,6],
[81,56,8,0],
[81,56,8,1],
[81,56,8,2],
[81,56,8,4],
[81,56,10,0],
[81,56,10,1],
[81,56,10,2],
[81,56,10,3],
[81,56,10,4],
[81,56,11,0],
[81,56,11,1],
[81,56,11,2],
[81,56,11,3],
[81,56,11,4],
[81,56,12,0],
[81,56,12,1],
[81,56,12,2],
[81,56,13,0],
[81,56,13,1],
[81,56,13,2],
[81,58,3,0],
[81,58,3,1],
[81,58,3,2],
[81,58,4,0],
[81,58,4,1],
[81,58,4,2],
[81,58,4,3],
[81,58,5,0],
[81,58,5,1],
[81,58,5,2],
[81,58,5,4],
[81,58,6,0],
[81,58,6,1],
[81,58,6,2],
[81,58,6,4],
[81,58,7,0],
[81,58,7,1],
[81,58,7,2],
[81,58,7,3],
[81,58,7,4],
[81,58,7,5],
[81,58,8,0],
[81,58,8,1],
[81,58,8,2],
[81,58,8,4],
[81,58,9,0],
[81,58,9,1],
[81,58,9,2],
[81,58,9,3],
[81,58,9,4],
[81,58,10,0],
[81,58,10,1],
[81,58,10,2],
[81,58,11,0],
[81,58,11,1],
[81,58,11,2],
[81,59,3,0],
[81,59,3,1],
[81,59,3,2],
[81,59,4,0],
[81,59,4,1],
[81,59,4,2],
[81,59,4,3],
[81,59,5,0],
[81,59,5,1],
[81,59,5,2],
[81,59,5,4],
[81,59,6,0],
[81,59,6,1],
[81,59,6,2],
[81,59,6,4],
[81,59,7,0],
[81,59,7,1],
[81,59,7,2],
[81,59,7,3],
[81,59,7,4],
[81,59,8,0],
[81,59,8,1],
[81,59,8,2],
[81,60,3,0],
[81,60,3,1],
[81,60,3,2],
[81,60,4,0],
[81,60,4,1],
[81,60,4,2],
[81,60,4,3],
[81,60,5,0],
[81,60,5,1],
[81,60,5,2],
[81,60,5,4],
[81,60,6,0],
[81,60,6,1],
[81,60,6,2],
[81,60,7,0],
[81,60,7,1],
[81,60,7,2],
[81,61,3,0],
[81,61,3,1],
[81,61,3,2],
[81,61,5,0],
[81,61,5,1],
[81,61,5,2],
[81,62,3,0],
[81,62,3,1],
[81,62,3,2],
[81,62,4,0],
[81,62,4,1],
[81,62,4,2],
[81,62,4,3],
[81,62,5,0],
[81,62,5,1],
[81,62,5,2],
[81,63,3,0],
[81,63,3,1],
[81,63,3,2],
[81,63,4,0],
[81,63,4,1],
[81,63,4,2],
[82,7,4,0],
[82,7,4,1],
[82,7,4,2],
[82,9,4,0],
[82,9,4,1],
[82,9,4,2],
[82,9,7,0],
[82,9,7,1],
[82,9,7,2],
[82,9,7,4],
[82,10,4,0],
[82,10,4,1],
[82,10,4,2],
[82,10,7,0],
[82,10,7,1],
[82,10,7,2],
[82,10,7,4],
[82,10,9,0],
[82,10,9,1],
[82,10,9,2],
[82,10,9,4],
[82,10,9,7],
[82,11,4,0],
[82,11,4,1],
[82,11,4,2],
[82,11,7,0],
[82,11,7,1],
[82,11,7,2],
[82,11,7,4],
[82,11,9,0],
[82,11,9,1],
[82,11,9,2],
[82,11,9,4],
[82,11,9,7],
[82,11,10,0],
[82,11,10,1],
[82,11,10,2],
[82,11,10,4],
[82,11,10,7],
[82,11,10,9],
[82,12,4,0],
[82,12,4,1],
[82,12,4,2],
[82,12,7,0],
[82,12,7,1],
[82,12,7,2],
[82,12,7,4],
[82,12,9,0],
[82,12,9,1],
[82,12,9,2],
[82,12,9,4],
[82,12,9,7],
[82,12,10,0],
[82,12,10,1],
[82,12,10,2],
[82,12,10,4],
[82,12,10,7],
[82,12,10,9],
[82,13,4,0],
[82,13,4,1],
[82,13,4,2],
[82,13,7,0],
[82,13,7,1],
[82,13,7,2],
[82,13,7,4],
[82,13,9,0],
[82,13,9,1],
[82,13,9,2],
[82,13,9,4],
[82,13,9,7],
[82,13,10,0],
[82,13,10,1],
[82,13,10,2],
[82,13,10,4],
[82,13,10,7],
[82,13,10,9],
[82,13,11,0],
[82,13,11,1],
[82,13,11,2],
[82,13,11,4],
[82,13,11,7],
[82,13,11,9],
[82,13,11,10],
[82,13,12,0],
[82,13,12,1],
[82,13,12,2],
[82,13,12,4],
[82,13,12,7],
[82,13,12,9],
[82,13,12,10],
[82,14,4,0],
[82,14,4,1],
[82,14,4,2],
[82,14,7,0],
[82,14,7,1],
[82,14,7,2],
[82,14,7,4],
[82,14,9,0],
[82,14,9,1],
[82,14,9,2],
[82,14,9,4],
[82,14,9,7],
[82,14,10,0],
[82,14,10,1],
[82,14,10,2],
[82,14,10,4],
[82,14,10,7],
[82,14,10,9],
[82,14,11,0],
[82,14,11,1],
[82,14,11,2],
[82,14,11,4],
[82,14,11,7],
[82,14,11,9],
[82,14,11,10],
[82,14,12,0],
[82,14,12,1],
[82,14,12,2],
[82,14,12,4],
[82,14,12,7],
[82,14,12,9],
[82,14,12,10],
[82,15,4,0],
[82,15,4,1],
[82,15,4,2],
[82,15,7,0],
[82,15,7,1],
[82,15,7,2],
[82,15,7,4],
[82,15,10,0],
[82,15,10,1],
[82,15,10,2],
[82,15,10,4],
[82,15,10,7],
[82,15,11,0],
[82,15,11,1],
[82,15,11,2],
[82,15,11,4],
[82,15,11,7],
[82,15,11,10],
[82,15,12,0],
[82,15,12,1],
[82,15,12,2],
[82,15,12,4],
[82,15,12,7],
[82,15,12,10],
[82,15,13,0],
[82,15,13,1],
[82,15,13,2],
[82,15,13,4],
[82,15,13,7],
[82,15,13,10],
[82,15,13,11],
[82,15,13,12],
[82,15,14,0],
[82,15,14,1],
[82,15,14,2],
[82,15,14,4],
[82,15,14,7],
[82,15,14,10],
[82,15,14,11],
[82,15,14,12],
[82,16,4,0],
[82,16,4,1],
[82,16,4,2],
[82,16,7,0],
[82,16,7,1],
[82,16,7,2],
[82,16,7,4],
[82,16,9,0],
[82,16,9,1],
[82,16,9,2],
[82,16,9,4],
[82,16,9,7],
[82,16,10,0],
[82,16,10,1],
[82,16,10,2],
[82,16,10,4],
[82,16,10,7],
[82,16,10,9],
[82,16,11,0],
[82,16,11,1],
[82,16,11,2],
[82,16,11,4],
[82,16,11,7],
[82,16,11,9],
[82,16,11,10],
[82,16,12,0],
[82,16,12,1],
[82,16,12,2],
[82,16,12,4],
[82,16,12,7],
[82,16,12,9],
[82,16,12,10],
[82,16,13,0],
[82,16,13,1],
[82,16,13,2],
[82,16,13,4],
[82,16,13,7],
[82,16,13,9],
[82,16,13,10],
[82,16,13,11],
[82,16,13,12],
[82,16,14,0],
[82,16,14,1],
[82,16,14,2],
[82,16,14,4],
[82,16,14,7],
[82,16,14,9],
[82,16,14,10],
[82,16,14,11],
[82,16,14,12],
[82,16,15,0],
[82,16,15,1],
[82,16,15,2],
[82,16,15,4],
[82,16,15,7],
[82,16,15,10],
[82,16,15,11],
[82,16,15,12],
[82,16,15,13],
[82,16,15,14],
[82,17,4,0],
[82,17,4,1],
[82,17,4,2],
[82,17,7,0],
[82,17,7,1],
[82,17,7,2],
[82,17,7,4],
[82,17,9,0],
[82,17,9,1],
[82,17,9,2],
[82,17,9,4],
[82,17,9,7],
[82,17,10,0],
[82,17,10,1],
[82,17,10,2],
[82,17,10,4],
[82,17,10,7],
[82,17,10,9],
[82,17,11,0],
[82,17,11,1],
[82,17,11,2],
[82,17,11,4],
[82,17,11,7],
[82,17,11,9],
[82,17,11,10],
[82,17,12,0],
[82,17,12,1],
[82,17,12,2],
[82,17,12,4],
[82,17,12,7],
[82,17,12,9],
[82,17,12,10],
[82,17,13,0],
[82,17,13,1],
[82,17,13,2],
[82,17,13,4],
[82,17,13,7],
[82,17,13,9],
[82,17,13,10],
[82,17,13,11],
[82,17,13,12],
[82,17,14,0],
[82,17,14,1],
[82,17,14,2],
[82,17,14,4],
[82,17,14,7],
[82,17,14,9],
[82,17,14,10],
[82,17,14,11],
[82,17,14,12],
[82,17,15,0],
[82,17,15,1],
[82,17,15,2],
[82,17,15,4],
[82,17,15,7],
[82,17,15,10],
[82,17,15,11],
[82,17,15,12],
[82,17,15,13],
[82,17,15,14],
[82,17,16,0],
[82,17,16,1],
[82,17,16,2],
[82,17,16,4],
[82,17,16,7],
[82,17,16,9],
[82,17,16,10],
[82,17,16,11],
[82,17,16,12],
[82,17,16,13],
[82,17,16,14],
[82,17,16,15],
[82,18,4,0],
[82,18,4,1],
[82,18,4,2],
[82,18,7,0],
[82,18,7,1],
[82,18,7,2],
[82,18,7,4],
[82,18,10,0],
[82,18,10,1],
[82,18,10,2],
[82,18,10,4],
[82,18,10,7],
[82,18,11,0],
[82,18,11,1],
[82,18,11,2],
[82,18,11,4],
[82,18,11,7],
[82,18,11,10],
[82,18,12,0],
[82,18,12,1],
[82,18,12,2],
[82,18,12,4],
[82,18,12,7],
[82,18,12,10],
[82,18,13,0],
[82,18,13,1],
[82,18,13,2],
[82,18,13,4],
[82,18,13,7],
[82,18,13,10],
[82,18,13,11],
[82,18,13,12],
[82,18,14,0],
[82,18,14,1],
[82,18,14,2],
[82,18,14,4],
[82,18,14,7],
[82,18,14,10],
[82,18,14,11],
[82,18,14,12],
[82,18,16,0],
[82,18,16,1],
[82,18,16,2],
[82,18,16,4],
[82,18,16,7],
[82,18,16,10],
[82,18,16,11],
[82,18,16,12],
[82,18,16,13],
[82,18,16,14],
[82,18,17,0],
[82,18,17,1],
[82,18,17,2],
[82,18,17,4],
[82,18,17,7],
[82,18,17,10],
[82,18,17,11],
[82,18,17,12],
[82,18,17,13],
[82,18,17,14],
[82,18,17,16],
[82,19,4,0],
[82,19,4,1],
[82,19,4,2],
[82,19,7,0],
[82,19,7,1],
[82,19,7,2],
[82,19,7,4],
[82,19,9,0],
[82,19,9,1],
[82,19,9,2],
[82,19,9,4],
[82,19,9,7],
[82,19,10,0],
[82,19,10,1],
[82,19,10,2],
[82,19,10,4],
[82,19,10,7],
[82,19,10,9],
[82,19,11,0],
[82,19,11,1],
[82,19,11,2],
[82,19,11,4],
[82,19,11,7],
[82,19,11,9],
[82,19,11,10],
[82,19,12,0],
[82,19,12,1],
[82,19,12,2],
[82,19,12,4],
[82,19,12,7],
[82,19,12,9],
[82,19,12,10],
[82,19,15,0],
[82,19,15,1],
[82,19,15,2],
[82,19,15,4],
[82,19,15,7],
[82,19,15,10],
[82,19,15,11],
[82,19,15,12],
[82,19,16,0],
[82,19,16,1],
[82,19,16,2],
[82,19,16,4],
[82,19,16,7],
[82,19,16,9],
[82,19,16,10],
[82,19,16,11],
[82,19,16,12],
[82,19,16,15],
[82,19,17,0],
[82,19,17,1],
[82,19,17,2],
[82,19,17,4],
[82,19,17,7],
[82,19,17,9],
[82,19,17,10],
[82,19,17,11],
[82,19,17,12],
[82,19,17,15],
[82,19,17,16],
[82,19,18,0],
[82,19,18,1],
[82,19,18,2],
[82,19,18,4],
[82,19,18,7],
[82,19,18,10],
[82,19,18,11],
[82,19,18,12],
[82,19,18,16],
[82,19,18,17],
[82,20,4,0],
[82,20,4,1],
[82,20,4,2],
[82,20,7,0],
[82,20,7,1],
[82,20,7,2],
[82,20,7,4],
[82,20,9,0],
[82,20,9,1],
[82,20,9,2],
[82,20,9,4],
[82,20,9,7],
[82,20,11,0],
[82,20,11,1],
[82,20,11,2],
[82,20,11,4],
[82,20,11,7],
[82,20,11,9],
[82,20,12,0],
[82,20,12,1],
[82,20,12,2],
[82,20,12,4],
[82,20,12,7],
[82,20,12,9],
[82,20,13,0],
[82,20,13,1],
[82,20,13,2],
[82,20,13,4],
[82,20,13,7],
[82,20,13,9],
[82,20,13,11],
[82,20,13,12],
[82,20,14,0],
[82,20,14,1],
[82,20,14,2],
[82,20,14,4],
[82,20,14,7],
[82,20,14,9],
[82,20,14,11],
[82,20,14,12],
[82,20,15,0],
[82,20,15,1],
[82,20,15,2],
[82,20,15,4],
[82,20,15,7],
[82,20,15,11],
[82,20,15,12],
[82,20,15,13],
[82,20,15,14],
[82,20,16,0],
[82,20,16,1],
[82,20,16,2],
[82,20,16,4],
[82,20,16,7],
[82,20,16,9],
[82,20,16,11],
[82,20,16,12],
[82,20,16,13],
[82,20,16,14],
[82,20,16,15],
[82,20,17,0],
[82,20,17,1],
[82,20,17,2],
[82,20,17,4],
[82,20,17,7],
[82,20,17,9],
[82,20,17,11],
[82,20,17,12],
[82,20,17,13],
[82,20,17,14],
[82,20,17,15],
[82,20,17,16],
[82,20,18,0],
[82,20,18,1],
[82,20,18,2],
[82,20,18,4],
[82,20,18,7],
[82,20,18,11],
[82,20,18,12],
[82,20,18,13],
[82,20,18,14],
[82,20,18,16],
[82,20,18,17],
[82,20,19,0],
[82,20,19,1],
[82,20,19,2],
[82,20,19,4],
[82,20,19,7],
[82,20,19,9],
[82,20,19,11],
[82,20,19,12],
[82,20,19,15],
[82,20,19,18],
[82,22,4,0],
[82,22,4,1],
[82,22,4,2],
[82,22,7,0],
[82,22,7,1],
[82,22,7,2],
[82,22,7,4],
[82,22,9,0],
[82,22,9,1],
[82,22,9,2],
[82,22,9,4],
[82,22,9,7],
[82,22,11,0],
[82,22,11,1],
[82,22,11,2],
[82,22,11,4],
[82,22,11,7],
[82,22,11,9],
[82,22,12,0],
[82,22,12,1],
[82,22,12,2],
[82,22,12,4],
[82,22,12,7],
[82,22,12,9],
[82,22,13,0],
[82,22,13,1],
[82,22,13,2],
[82,22,13,4],
[82,22,13,7],
[82,22,13,9],
[82,22,13,11],
[82,22,13,12],
[82,22,14,0],
[82,22,14,1],
[82,22,14,2],
[82,22,14,4],
[82,22,14,7],
[82,22,14,9],
[82,22,14,11],
[82,22,14,12],
[82,22,15,0],
[82,22,15,1],
[82,22,15,2],
[82,22,15,4],
[82,22,15,7],
[82,22,15,11],
[82,22,15,12],
[82,22,15,13],
[82,22,15,14],
[82,22,16,0],
[82,22,16,1],
[82,22,16,2],
[82,22,16,4],
[82,22,16,7],
[82,22,16,9],
[82,22,16,11],
[82,22,16,12],
[82,22,16,13],
[82,22,16,14],
[82,22,16,15],
[82,22,17,0],
[82,22,17,1],
[82,22,17,2],
[82,22,17,4],
[82,22,17,7],
[82,22,17,9],
[82,22,17,11],
[82,22,17,12],
[82,22,17,13],
[82,22,17,14],
[82,22,17,15],
[82,22,18,0],
[82,22,18,1],
[82,22,18,2],
[82,22,18,4],
[82,22,18,7],
[82,22,18,11],
[82,22,18,12],
[82,22,18,13],
[82,22,18,14],
[82,22,18,16],
[82,22,18,17],
[82,22,19,0],
[82,22,19,1],
[82,22,19,2],
[82,22,19,4],
[82,22,19,7],
[82,22,19,9],
[82,22,19,11],
[82,22,19,12],
[82,22,19,15],
[82,22,19,16],
[82,22,19,17],
[82,22,19,18],
[82,23,4,0],
[82,23,4,1],
[82,23,4,2],
[82,23,7,0],
[82,23,7,1],
[82,23,7,2],
[82,23,7,4],
[82,23,9,0],
[82,23,9,1],
[82,23,9,2],
[82,23,9,4],
[82,23,9,7],
[82,23,10,0],
[82,23,10,1],
[82,23,10,2],
[82,23,10,4],
[82,23,10,7],
[82,23,10,9],
[82,23,11,0],
[82,23,11,1],
[82,23,11,2],
[82,23,11,4],
[82,23,11,7],
[82,23,11,9],
[82,23,11,10],
[82,23,12,0],
[82,23,12,1],
[82,23,12,2],
[82,23,12,4],
[82,23,12,7],
[82,23,12,9],
[82,23,12,10],
[82,23,13,0],
[82,23,13,1],
[82,23,13,2],
[82,23,13,4],
[82,23,13,7],
[82,23,13,9],
[82,23,13,10],
[82,23,13,11],
[82,23,13,12],
[82,23,14,0],
[82,23,14,1],
[82,23,14,2],
[82,23,14,4],
[82,23,14,7],
[82,23,14,9],
[82,23,14,10],
[82,23,14,11],
[82,23,14,12],
[82,23,15,0],
[82,23,15,1],
[82,23,15,2],
[82,23,15,4],
[82,23,15,7],
[82,23,15,10],
[82,23,15,11],
[82,23,15,12],
[82,23,15,13],
[82,23,15,14],
[82,23,16,0],
[82,23,16,1],
[82,23,16,2],
[82,23,16,4],
[82,23,16,7],
[82,23,16,9],
[82,23,16,10],
[82,23,16,11],
[82,23,16,12],
[82,23,16,13],
[82,23,16,14],
[82,23,17,0],
[82,23,17,1],
[82,23,17,2],
[82,23,17,4],
[82,23,17,7],
[82,23,17,9],
[82,23,17,10],
[82,23,17,11],
[82,23,17,12],
[82,23,17,13],
[82,23,17,14],
[82,23,17,16],
[82,23,18,0],
[82,23,18,1],
[82,23,18,2],
[82,23,18,4],
[82,23,18,7],
[82,23,18,10],
[82,23,18,11],
[82,23,18,12],
[82,23,18,13],
[82,23,18,16],
[82,23,18,17],
[82,23,19,0],
[82,23,19,1],
[82,23,19,2],
[82,23,19,4],
[82,23,19,7],
[82,23,19,9],
[82,23,19,10],
[82,23,19,11],
[82,23,19,15],
[82,23,19,16],
[82,23,19,17],
[82,23,19,18],
[82,23,20,0],
[82,23,20,1],
[82,23,20,2],
[82,23,20,4],
[82,23,20,7],
[82,23,20,9],
[82,23,20,12],
[82,23,20,13],
[82,23,20,14],
[82,23,20,15],
[82,23,20,16],
[82,23,20,17],
[82,23,20,18],
[82,23,20,19],
[82,23,22,0],
[82,23,22,1],
[82,23,22,2],
[82,23,22,4],
[82,23,22,9],
[82,23,22,11],
[82,23,22,12],
[82,23,22,13],
[82,23,22,14],
[82,23,22,15],
[82,23,22,16],
[82,23,22,17],
[82,23,22,18],
[82,23,22,19],
[82,24,4,0],
[82,24,4,1],
[82,24,4,2],
[82,24,7,0],
[82,24,7,1],
[82,24,7,2],
[82,24,7,4],
[82,24,9,0],
[82,24,9,1],
[82,24,9,2],
[82,24,9,4],
[82,24,9,7],
[82,24,10,0],
[82,24,10,1],
[82,24,10,2],
[82,24,10,4],
[82,24,10,7],
[82,24,10,9],
[82,24,11,0],
[82,24,11,1],
[82,24,11,2],
[82,24,11,4],
[82,24,11,7],
[82,24,11,9],
[82,24,11,10],
[82,24,12,0],
[82,24,12,1],
[82,24,12,2],
[82,24,12,4],
[82,24,12,7],
[82,24,12,9],
[82,24,12,10],
[82,24,13,0],
[82,24,13,1],
[82,24,13,2],
[82,24,13,4],
[82,24,13,7],
[82,24,13,9],
[82,24,13,10],
[82,24,13,11],
[82,24,13,12],
[82,24,14,0],
[82,24,14,1],
[82,24,14,2],
[82,24,14,4],
[82,24,14,7],
[82,24,14,9],
[82,24,14,10],
[82,24,14,11],
[82,24,14,12],
[82,24,15,0],
[82,24,15,1],
[82,24,15,2],
[82,24,15,4],
[82,24,15,7],
[82,24,15,10],
[82,24,15,11],
[82,24,15,12],
[82,24,15,13],
[82,24,15,14],
[82,24,17,0],
[82,24,17,1],
[82,24,17,2],
[82,24,17,4],
[82,24,17,7],
[82,24,17,9],
[82,24,17,10],
[82,24,17,11],
[82,24,17,12],
[82,24,17,13],
[82,24,17,14],
[82,24,18,0],
[82,24,18,1],
[82,24,18,2],
[82,24,18,4],
[82,24,18,7],
[82,24,18,10],
[82,24,18,11],
[82,24,18,12],
[82,24,18,13],
[82,24,18,17],
[82,24,19,0],
[82,24,19,1],
[82,24,19,2],
[82,24,19,4],
[82,24,19,7],
[82,24,19,9],
[82,24,19,10],
[82,24,19,11],
[82,24,19,15],
[82,24,19,17],
[82,24,19,18],
[82,24,20,0],
[82,24,20,1],
[82,24,20,2],
[82,24,20,4],
[82,24,20,7],
[82,24,20,9],
[82,24,20,12],
[82,24,20,13],
[82,24,20,14],
[82,24,20,15],
[82,24,20,17],
[82,24,20,18],
[82,24,20,19],
[82,24,22,0],
[82,24,22,1],
[82,24,22,2],
[82,24,22,4],
[82,24,22,9],
[82,24,22,11],
[82,24,22,12],
[82,24,22,13],
[82,24,22,14],
[82,24,22,15],
[82,24,22,17],
[82,24,22,18],
[82,24,22,19],
[82,24,23,0],
[82,24,23,1],
[82,24,23,2],
[82,24,23,4],
[82,24,23,7],
[82,24,23,9],
[82,24,23,10],
[82,24,23,11],
[82,24,23,12],
[82,24,23,13],
[82,24,23,14],
[82,24,23,15],
[82,24,23,17],
[82,24,23,18],
[82,24,23,19],
[82,24,23,20],
[82,24,23,22],
[82,25,4,0],
[82,25,4,1],
[82,25,4,2],
[82,25,9,0],
[82,25,9,1],
[82,25,9,2],
[82,25,9,4],
[82,25,10,0],
[82,25,10,1],
[82,25,10,2],
[82,25,10,4],
[82,25,10,9],
[82,25,11,0],
[82,25,11,1],
[82,25,11,2],
[82,25,11,4],
[82,25,11,9],
[82,25,11,10],
[82,25,12,0],
[82,25,12,1],
[82,25,12,2],
[82,25,12,4],
[82,25,12,9],
[82,25,12,10],
[82,25,13,0],
[82,25,13,1],
[82,25,13,2],
[82,25,13,4],
[82,25,13,9],
[82,25,13,10],
[82,25,13,11],
[82,25,13,12],
[82,25,14,0],
[82,25,14,1],
[82,25,14,2],
[82,25,14,4],
[82,25,14,9],
[82,25,14,10],
[82,25,14,11],
[82,25,14,12],
[82,25,15,0],
[82,25,15,1],
[82,25,15,2],
[82,25,15,4],
[82,25,15,10],
[82,25,15,11],
[82,25,15,12],
[82,25,15,13],
[82,25,16,0],
[82,25,16,1],
[82,25,16,2],
[82,25,16,4],
[82,25,16,9],
[82,25,16,10],
[82,25,16,11],
[82,25,16,14],
[82,25,16,15],
[82,25,17,0],
[82,25,17,1],
[82,25,17,2],
[82,25,17,4],
[82,25,17,9],
[82,25,17,10],
[82,25,17,11],
[82,25,17,14],
[82,25,17,15],
[82,25,17,16],
[82,25,18,0],
[82,25,18,1],
[82,25,18,2],
[82,25,18,4],
[82,25,18,12],
[82,25,18,13],
[82,25,18,14],
[82,25,18,16],
[82,25,18,17],
[82,25,19,0],
[82,25,19,1],
[82,25,19,2],
[82,25,19,4],
[82,25,19,10],
[82,25,19,11],
[82,25,19,12],
[82,25,19,15],
[82,25,19,16],
[82,25,19,17],
[82,25,19,18],
[82,25,20,0],
[82,25,20,1],
[82,25,20,2],
[82,25,20,4],
[82,25,20,9],
[82,25,20,11],
[82,25,20,12],
[82,25,20,13],
[82,25,20,14],
[82,25,20,15],
[82,25,20,16],
[82,25,20,17],
[82,25,20,18],
[82,25,20,19],
[82,25,22,0],
[82,25,22,1],
[82,25,22,2],
[82,25,22,9],
[82,25,22,11],
[82,25,22,12],
[82,25,22,13],
[82,25,22,14],
[82,25,22,15],
[82,25,22,16],
[82,25,22,17],
[82,25,22,18],
[82,25,22,19],
[82,25,23,4],
[82,25,23,9],
[82,25,23,10],
[82,25,23,11],
[82,25,23,12],
[82,25,23,13],
[82,25,23,14],
[82,25,23,15],
[82,25,23,16],
[82,25,23,17],
[82,25,23,18],
[82,25,23,19],
[82,25,23,20],
[82,25,24,4],
[82,25,24,9],
[82,25,24,10],
[82,25,24,11],
[82,25,24,12],
[82,25,24,13],
[82,25,24,14],
[82,25,24,15],
[82,25,24,17],
[82,25,24,18],
[82,25,24,19],
[82,25,24,20],
[82,26,4,0],
[82,26,4,1],
[82,26,4,2],
[82,26,7,0],
[82,26,7,1],
[82,26,7,2],
[82,26,7,4],
[82,26,10,0],
[82,26,10,1],
[82,26,10,2],
[82,26,10,4],
[82,26,10,7],
[82,26,11,0],
[82,26,11,1],
[82,26,11,2],
[82,26,11,4],
[82,26,11,7],
[82,26,11,10],
[82,26,12,0],
[82,26,12,1],
[82,26,12,2],
[82,26,12,4],
[82,26,12,7],
[82,26,12,10],
[82,26,13,0],
[82,26,13,1],
[82,26,13,2],
[82,26,13,4],
[82,26,13,7],
[82,26,13,10],
[82,26,13,11],
[82,26,13,12],
[82,26,14,0],
[82,26,14,1],
[82,26,14,2],
[82,26,14,4],
[82,26,14,7],
[82,26,14,10],
[82,26,14,11],
[82,26,14,12],
[82,26,16,0],
[82,26,16,1],
[82,26,16,2],
[82,26,16,4],
[82,26,16,7],
[82,26,16,12],
[82,26,16,13],
[82,26,16,14],
[82,26,17,0],
[82,26,17,1],
[82,26,17,2],
[82,26,17,4],
[82,26,17,7],
[82,26,17,12],
[82,26,17,13],
[82,26,17,14],
[82,26,17,16],
[82,26,19,0],
[82,26,19,1],
[82,26,19,2],
[82,26,19,4],
[82,26,19,10],
[82,26,19,11],
[82,26,19,12],
[82,26,19,16],
[82,26,19,17],
[82,26,20,0],
[82,26,20,1],
[82,26,20,2],
[82,26,20,4],
[82,26,20,7],
[82,26,20,11],
[82,26,20,12],
[82,26,20,13],
[82,26,20,14],
[82,26,20,16],
[82,26,20,17],
[82,26,20,19],
[82,26,22,4],
[82,26,22,7],
[82,26,22,11],
[82,26,22,12],
[82,26,22,13],
[82,26,22,14],
[82,26,22,16],
[82,26,22,17],
[82,26,22,19],
[82,26,23,0],
[82,26,23,1],
[82,26,23,2],
[82,26,23,4],
[82,26,23,7],
[82,26,23,10],
[82,26,23,11],
[82,26,23,12],
[82,26,23,13],
[82,26,23,14],
[82,26,23,16],
[82,26,23,17],
[82,26,23,19],
[82,26,24,0],
[82,26,24,1],
[82,26,24,2],
[82,26,24,4],
[82,26,24,7],
[82,26,24,10],
[82,26,24,11],
[82,26,24,12],
[82,26,24,13],
[82,26,24,14],
[82,26,24,17],
[82,26,24,19],
[82,26,25,0],
[82,26,25,1],
[82,26,25,2],
[82,26,25,4],
[82,26,25,10],
[82,26,25,11],
[82,26,25,12],
[82,26,25,13],
[82,26,25,14],
[82,26,25,16],
[82,26,25,17],
[82,27,4,0],
[82,27,4,1],
[82,27,4,2],
[82,27,7,0],
[82,27,7,1],
[82,27,7,2],
[82,27,7,4],
[82,27,9,0],
[82,27,9,1],
[82,27,9,2],
[82,27,9,4],
[82,27,9,7],
[82,27,11,0],
[82,27,11,1],
[82,27,11,2],
[82,27,11,4],
[82,27,11,7],
[82,27,11,9],
[82,27,12,0],
[82,27,12,1],
[82,27,12,2],
[82,27,12,4],
[82,27,12,7],
[82,27,12,9],
[82,27,13,0],
[82,27,13,1],
[82,27,13,2],
[82,27,13,4],
[82,27,13,7],
[82,27,13,9],
[82,27,13,11],
[82,27,13,12],
[82,27,14,0],
[82,27,14,1],
[82,27,14,2],
[82,27,14,4],
[82,27,14,7],
[82,27,14,9],
[82,27,14,11],
[82,27,15,0],
[82,27,15,1],
[82,27,15,2],
[82,27,15,4],
[82,27,15,7],
[82,27,15,12],
[82,27,15,13],
[82,27,15,14],
[82,27,16,0],
[82,27,16,1],
[82,27,16,2],
[82,27,16,4],
[82,27,16,7],
[82,27,16,11],
[82,27,16,12],
[82,27,16,13],
[82,27,16,14],
[82,27,16,15],
[82,27,17,0],
[82,27,17,1],
[82,27,17,2],
[82,27,17,4],
[82,27,17,7],
[82,27,17,11],
[82,27,17,12],
[82,27,17,13],
[82,27,17,14],
[82,27,17,15],
[82,27,17,16],
[82,27,18,0],
[82,27,18,1],
[82,27,18,2],
[82,27,18,4],
[82,27,18,11],
[82,27,18,12],
[82,27,18,13],
[82,27,18,14],
[82,27,18,16],
[82,27,18,17],
[82,27,19,0],
[82,27,19,1],
[82,27,19,2],
[82,27,19,4],
[82,27,19,7],
[82,27,19,9],
[82,27,19,11],
[82,27,19,12],
[82,27,19,15],
[82,27,19,16],
[82,27,19,17],
[82,27,19,18],
[82,27,23,0],
[82,27,23,1],
[82,27,23,2],
[82,27,23,4],
[82,27,23,7],
[82,27,23,9],
[82,27,23,11],
[82,27,23,12],
[82,27,23,13],
[82,27,23,14],
[82,27,23,15],
[82,27,23,16],
[82,27,23,17],
[82,27,23,18],
[82,27,24,0],
[82,27,24,1],
[82,27,24,2],
[82,27,24,4],
[82,27,24,7],
[82,27,24,9],
[82,27,24,11],
[82,27,24,12],
[82,27,24,13],
[82,27,24,14],
[82,27,24,15],
[82,27,24,17],
[82,27,24,18],
[82,27,25,0],
[82,27,25,1],
[82,27,25,2],
[82,27,25,4],
[82,27,25,9],
[82,27,25,11],
[82,27,25,12],
[82,27,25,13],
[82,27,25,14],
[82,27,25,15],
[82,27,26,0],
[82,27,26,1],
[82,27,26,2],
[82,27,26,4],
[82,27,26,7],
[82,27,26,11],
[82,27,26,12],
[82,27,26,13],
[82,27,26,14],
[82,28,4,0],
[82,28,4,1],
[82,28,4,2],
[82,28,9,0],
[82,28,9,1],
[82,28,9,2],
[82,28,9,4],
[82,28,10,0],
[82,28,10,1],
[82,28,10,2],
[82,28,10,4],
[82,28,10,9],
[82,28,11,0],
[82,28,11,1],
[82,28,11,2],
[82,28,11,4],
[82,28,11,9],
[82,28,11,10],
[82,28,12,0],
[82,28,12,1],
[82,28,12,2],
[82,28,12,4],
[82,28,12,9],
[82,28,12,10],
[82,28,13,0],
[82,28,13,1],
[82,28,13,2],
[82,28,13,4],
[82,28,13,9],
[82,28,13,10],
[82,28,13,11],
[82,28,14,0],
[82,28,14,1],
[82,28,14,2],
[82,28,14,4],
[82,28,14,9],
[82,28,14,12],
[82,28,15,0],
[82,28,15,1],
[82,28,15,2],
[82,28,15,4],
[82,28,15,10],
[82,28,15,11],
[82,28,15,12],
[82,28,15,13],
[82,28,15,14],
[82,28,16,0],
[82,28,16,1],
[82,28,16,2],
[82,28,16,4],
[82,28,16,9],
[82,28,16,10],
[82,28,16,11],
[82,28,16,12],
[82,28,16,13],
[82,28,16,14],
[82,28,16,15],
[82,28,17,0],
[82,28,17,1],
[82,28,17,2],
[82,28,17,4],
[82,28,17,9],
[82,28,17,10],
[82,28,17,11],
[82,28,17,12],
[82,28,17,13],
[82,28,17,14],
[82,28,17,15],
[82,28,17,16],
[82,28,18,0],
[82,28,18,1],
[82,28,18,2],
[82,28,18,4],
[82,28,18,10],
[82,28,18,11],
[82,28,18,12],
[82,28,18,13],
[82,28,18,14],
[82,28,18,16],
[82,28,18,17],
[82,28,19,0],
[82,28,19,1],
[82,28,19,2],
[82,28,19,9],
[82,28,19,10],
[82,28,19,11],
[82,28,19,12],
[82,28,19,15],
[82,28,19,16],
[82,28,19,17],
[82,28,19,18],
[82,28,20,4],
[82,28,20,9],
[82,28,20,11],
[82,28,20,12],
[82,28,20,13],
[82,28,20,14],
[82,28,20,15],
[82,28,20,16],
[82,28,20,17],
[82,28,20,18],
[82,28,20,19],
[82,28,22,0],
[82,28,22,1],
[82,28,22,2],
[82,28,22,4],
[82,28,22,9],
[82,28,22,11],
[82,28,22,12],
[82,28,22,13],
[82,28,22,14],
[82,28,22,15],
[82,28,22,16],
[82,28,22,17],
[82,28,22,18],
[82,28,23,0],
[82,28,23,1],
[82,28,23,2],
[82,28,23,4],
[82,28,23,9],
[82,28,23,10],
[82,28,23,11],
[82,28,23,12],
[82,28,23,13],
[82,28,23,14],
[82,28,23,15],
[82,28,23,16],
[82,28,23,17],
[82,28,24,0],
[82,28,24,1],
[82,28,24,2],
[82,28,24,4],
[82,28,24,9],
[82,28,24,10],
[82,28,24,11],
[82,28,24,12],
[82,28,24,13],
[82,28,24,14],
[82,28,24,15],
[82,28,24,17],
[82,28,26,0],
[82,28,26,1],
[82,28,26,2],
[82,28,26,4],
[82,28,26,10],
[82,28,26,11],
[82,28,26,12],
[82,28,26,13],
[82,28,27,0],
[82,28,27,1],
[82,28,27,2],
[82,28,27,4],
[82,28,27,9],
[82,28,27,11],
[82,29,4,0],
[82,29,4,1],
[82,29,4,2],
[82,29,7,0],
[82,29,7,1],
[82,29,7,2],
[82,29,7,4],
[82,29,9,0],
[82,29,9,1],
[82,29,9,2],
[82,29,9,4],
[82,29,9,7],
[82,29,10,0],
[82,29,10,1],
[82,29,10,2],
[82,29,10,4],
[82,29,10,7],
[82,29,10,9],
[82,29,13,0],
[82,29,13,1],
[82,29,13,2],
[82,29,13,4],
[82,29,13,7],
[82,29,13,9],
[82,29,14,0],
[82,29,14,1],
[82,29,14,2],
[82,29,14,4],
[82,29,14,7],
[82,29,14,10],
[82,29,15,0],
[82,29,15,1],
[82,29,15,2],
[82,29,15,4],
[82,29,15,10],
[82,29,15,13],
[82,29,15,14],
[82,29,16,0],
[82,29,16,1],
[82,29,16,2],
[82,29,16,4],
[82,29,16,7],
[82,29,16,9],
[82,29,16,10],
[82,29,16,13],
[82,29,16,14],
[82,29,16,15],
[82,29,17,0],
[82,29,17,1],
[82,29,17,2],
[82,29,17,4],
[82,29,17,7],
[82,29,17,9],
[82,29,17,10],
[82,29,17,13],
[82,29,17,14],
[82,29,17,15],
[82,29,17,16],
[82,29,18,0],
[82,29,18,1],
[82,29,18,2],
[82,29,18,7],
[82,29,18,10],
[82,29,18,13],
[82,29,18,14],
[82,29,18,16],
[82,29,18,17],
[82,29,19,4],
[82,29,19,7],
[82,29,19,9],
[82,29,19,10],
[82,29,19,15],
[82,29,19,16],
[82,29,19,17],
[82,29,19,18],
[82,29,20,0],
[82,29,20,1],
[82,29,20,2],
[82,29,20,4],
[82,29,20,7],
[82,29,20,9],
[82,29,20,13],
[82,29,20,14],
[82,29,20,15],
[82,29,20,16],
[82,29,20,17],
[82,29,20,18],
[82,29,20,19],
[82,29,22,0],
[82,29,22,1],
[82,29,22,2],
[82,29,22,4],
[82,29,22,7],
[82,29,22,9],
[82,29,22,13],
[82,29,22,14],
[82,29,22,15],
[82,29,22,16],
[82,29,22,17],
[82,29,23,0],
[82,29,23,1],
[82,29,23,2],
[82,29,23,4],
[82,29,23,7],
[82,29,23,9],
[82,29,23,10],
[82,29,23,13],
[82,29,23,14],
[82,29,23,15],
[82,29,24,0],
[82,29,24,1],
[82,29,24,2],
[82,29,24,4],
[82,29,24,7],
[82,29,24,9],
[82,29,24,10],
[82,29,24,13],
[82,29,24,14],
[82,29,24,15],
[82,29,25,0],
[82,29,25,1],
[82,29,25,2],
[82,29,25,4],
[82,29,25,9],
[82,29,25,10],
[82,29,25,13],
[82,29,26,0],
[82,29,26,1],
[82,29,26,2],
[82,29,26,4],
[82,29,26,7],
[82,29,26,10],
[82,29,27,0],
[82,29,27,1],
[82,29,27,2],
[82,29,27,4],
[82,29,27,7],
[82,29,27,9],
[82,29,28,0],
[82,29,28,1],
[82,29,28,2],
[82,29,28,4],
[82,30,4,0],
[82,30,4,1],
[82,30,4,2],
[82,30,7,0],
[82,30,7,1],
[82,30,7,2],
[82,30,7,4],
[82,30,9,0],
[82,30,9,1],
[82,30,9,2],
[82,30,9,4],
[82,30,9,7],
[82,30,10,0],
[82,30,10,1],
[82,30,10,2],
[82,30,10,4],
[82,30,10,7],
[82,30,10,9],
[82,30,11,0],
[82,30,11,1],
[82,30,11,2],
[82,30,11,4],
[82,30,11,7],
[82,30,11,9],
[82,30,12,0],
[82,30,12,1],
[82,30,12,2],
[82,30,12,4],
[82,30,12,7],
[82,30,12,10],
[82,30,13,0],
[82,30,13,1],
[82,30,13,2],
[82,30,13,4],
[82,30,13,7],
[82,30,13,10],
[82,30,13,11],
[82,30,13,12],
[82,30,14,0],
[82,30,14,1],
[82,30,14,2],
[82,30,14,4],
[82,30,14,9],
[82,30,14,10],
[82,30,14,11],
[82,30,14,12],
[82,30,15,0],
[82,30,15,1],
[82,30,15,2],
[82,30,15,4],
[82,30,15,7],
[82,30,15,10],
[82,30,15,11],
[82,30,15,12],
[82,30,15,13],
[82,30,15,14],
[82,30,16,0],
[82,30,16,1],
[82,30,16,2],
[82,30,16,7],
[82,30,16,9],
[82,30,16,10],
[82,30,16,11],
[82,30,16,12],
[82,30,16,13],
[82,30,16,14],
[82,30,16,15],
[82,30,17,0],
[82,30,17,1],
[82,30,17,2],
[82,30,17,7],
[82,30,17,9],
[82,30,17,10],
[82,30,17,11],
[82,30,17,12],
[82,30,17,13],
[82,30,17,14],
[82,30,17,15],
[82,30,17,16],
[82,30,18,4],
[82,30,18,7],
[82,30,18,10],
[82,30,18,11],
[82,30,18,12],
[82,30,18,13],
[82,30,18,14],
[82,30,18,16],
[82,30,18,17],
[82,30,19,0],
[82,30,19,1],
[82,30,19,2],
[82,30,19,4],
[82,30,19,7],
[82,30,19,9],
[82,30,19,10],
[82,30,19,11],
[82,30,19,12],
[82,30,19,15],
[82,30,19,16],
[82,30,19,17],
[82,30,19,18],
[82,30,20,0],
[82,30,20,1],
[82,30,20,2],
[82,30,20,4],
[82,30,20,7],
[82,30,20,9],
[82,30,20,11],
[82,30,20,12],
[82,30,20,13],
[82,30,20,14],
[82,30,20,15],
[82,30,20,16],
[82,30,20,17],
[82,30,20,18],
[82,30,22,0],
[82,30,22,1],
[82,30,22,2],
[82,30,22,4],
[82,30,22,7],
[82,30,22,9],
[82,30,22,11],
[82,30,22,12],
[82,30,22,13],
[82,30,22,14],
[82,30,22,15],
[82,30,24,0],
[82,30,24,1],
[82,30,24,2],
[82,30,24,4],
[82,30,24,7],
[82,30,24,9],
[82,30,24,10],
[82,30,24,11],
[82,30,24,12],
[82,30,24,13],
[82,30,24,14],
[82,30,25,0],
[82,30,25,1],
[82,30,25,2],
[82,30,25,4],
[82,30,25,9],
[82,30,25,10],
[82,30,25,11],
[82,30,26,0],
[82,30,26,1],
[82,30,26,2],
[82,30,26,4],
[82,30,26,7],
[82,30,27,0],
[82,30,27,1],
[82,30,27,2],
[82,30,27,4],
[82,30,27,7],
[82,30,28,0],
[82,30,28,1],
[82,30,28,2],
[82,30,28,4],
[82,30,29,0],
[82,30,29,1],
[82,30,29,2],
[82,30,29,4],
[82,31,4,0],
[82,31,4,1],
[82,31,4,2],
[82,31,7,0],
[82,31,7,1],
[82,31,7,2],
[82,31,7,4],
[82,31,9,0],
[82,31,9,1],
[82,31,9,2],
[82,31,9,4],
[82,31,9,7],
[82,31,10,0],
[82,31,10,1],
[82,31,10,2],
[82,31,10,4],
[82,31,10,7],
[82,31,11,0],
[82,31,11,1],
[82,31,11,2],
[82,31,11,4],
[82,31,11,7],
[82,31,11,10],
[82,31,12,0],
[82,31,12,1],
[82,31,12,2],
[82,31,12,4],
[82,31,12,9],
[82,31,12,10],
[82,31,13,0],
[82,31,13,1],
[82,31,13,2],
[82,31,13,4],
[82,31,13,9],
[82,31,13,10],
[82,31,13,11],
[82,31,13,12],
[82,31,14,0],
[82,31,14,1],
[82,31,14,2],
[82,31,14,4],
[82,31,14,7],
[82,31,14,9],
[82,31,14,10],
[82,31,14,11],
[82,31,14,12],
[82,31,15,0],
[82,31,15,1],
[82,31,15,2],
[82,31,15,7],
[82,31,15,10],
[82,31,15,11],
[82,31,15,12],
[82,31,15,13],
[82,31,15,14],
[82,31,16,4],
[82,31,16,7],
[82,31,16,9],
[82,31,16,10],
[82,31,16,11],
[82,31,16,12],
[82,31,16,13],
[82,31,16,14],
[82,31,16,15],
[82,31,17,4],
[82,31,17,7],
[82,31,17,9],
[82,31,17,10],
[82,31,17,11],
[82,31,17,12],
[82,31,17,13],
[82,31,17,14],
[82,31,17,15],
[82,31,17,16],
[82,31,18,0],
[82,31,18,1],
[82,31,18,2],
[82,31,18,4],
[82,31,18,7],
[82,31,18,10],
[82,31,18,11],
[82,31,18,12],
[82,31,18,13],
[82,31,18,14],
[82,31,18,16],
[82,31,18,17],
[82,31,19,0],
[82,31,19,1],
[82,31,19,2],
[82,31,19,4],
[82,31,19,7],
[82,31,19,9],
[82,31,19,10],
[82,31,19,11],
[82,31,19,12],
[82,31,19,15],
[82,31,19,16],
[82,31,19,17],
[82,31,19,18],
[82,31,20,0],
[82,31,20,1],
[82,31,20,2],
[82,31,20,4],
[82,31,20,7],
[82,31,20,9],
[82,31,20,11],
[82,31,20,12],
[82,31,20,13],
[82,31,20,14],
[82,31,20,15],
[82,31,20,16],
[82,31,20,17],
[82,31,22,0],
[82,31,22,1],
[82,31,22,2],
[82,31,22,4],
[82,31,22,7],
[82,31,22,9],
[82,31,22,11],
[82,31,22,12],
[82,31,22,13],
[82,31,22,14],
[82,31,24,0],
[82,31,24,1],
[82,31,24,2],
[82,31,24,4],
[82,31,24,7],
[82,31,24,9],
[82,31,24,10],
[82,31,24,11],
[82,31,24,12],
[82,31,24,13],
[82,31,25,0],
[82,31,25,1],
[82,31,25,2],
[82,31,25,4],
[82,31,25,9],
[82,31,26,0],
[82,31,26,1],
[82,31,26,2],
[82,31,26,4],
[82,31,26,7],
[82,31,27,0],
[82,31,27,1],
[82,31,27,2],
[82,31,27,4],
[82,31,28,0],
[82,31,28,1],
[82,31,28,2],
[82,31,28,4],
[82,31,29,0],
[82,31,29,1],
[82,31,29,2],
[82,32,4,0],
[82,32,4,1],
[82,32,4,2],
[82,32,7,0],
[82,32,7,1],
[82,32,7,2],
[82,32,7,4],
[82,32,9,0],
[82,32,9,1],
[82,32,9,2],
[82,32,9,4],
[82,32,9,7],
[82,32,10,0],
[82,32,10,1],
[82,32,10,2],
[82,32,10,4],
[82,32,10,9],
[82,32,13,0],
[82,32,13,1],
[82,32,13,2],
[82,32,13,4],
[82,32,13,7],
[82,32,13,9],
[82,32,13,10],
[82,32,14,0],
[82,32,14,1],
[82,32,14,2],
[82,32,14,7],
[82,32,14,9],
[82,32,14,10],
[82,32,15,4],
[82,32,15,7],
[82,32,15,10],
[82,32,15,13],
[82,32,15,14],
[82,32,16,0],
[82,32,16,1],
[82,32,16,2],
[82,32,16,4],
[82,32,16,7],
[82,32,16,9],
[82,32,16,10],
[82,32,16,13],
[82,32,16,14],
[82,32,16,15],
[82,32,17,0],
[82,32,17,1],
[82,32,17,2],
[82,32,17,4],
[82,32,17,7],
[82,32,17,9],
[82,32,17,10],
[82,32,17,13],
[82,32,17,14],
[82,32,17,15],
[82,32,17,16],
[82,32,18,0],
[82,32,18,1],
[82,32,18,2],
[82,32,18,4],
[82,32,18,7],
[82,32,18,10],
[82,32,18,13],
[82,32,18,14],
[82,32,18,16],
[82,32,18,17],
[82,32,19,0],
[82,32,19,1],
[82,32,19,2],
[82,32,19,4],
[82,32,19,7],
[82,32,19,9],
[82,32,19,10],
[82,32,19,15],
[82,32,19,16],
[82,32,19,17],
[82,32,20,0],
[82,32,20,1],
[82,32,20,2],
[82,32,20,4],
[82,32,20,7],
[82,32,20,9],
[82,32,20,13],
[82,32,20,14],
[82,32,20,15],
[82,32,22,0],
[82,32,22,1],
[82,32,22,2],
[82,32,22,4],
[82,32,22,7],
[82,32,22,9],
[82,32,22,13],
[82,32,23,0],
[82,32,23,1],
[82,32,23,2],
[82,32,23,4],
[82,32,23,7],
[82,32,23,9],
[82,32,23,10],
[82,32,24,0],
[82,32,24,1],
[82,32,24,2],
[82,32,24,4],
[82,32,24,7],
[82,32,24,9],
[82,32,24,10],
[82,32,25,0],
[82,32,25,1],
[82,32,25,2],
[82,32,25,4],
[82,32,26,0],
[82,32,26,1],
[82,32,26,2],
[82,32,26,4],
[82,32,27,0],
[82,32,27,1],
[82,32,27,2],
[82,32,27,4],
[82,32,28,0],
[82,32,28,1],
[82,32,28,2],
[82,33,4,0],
[82,33,4,1],
[82,33,4,2],
[82,33,7,0],
[82,33,7,1],
[82,33,7,2],
[82,33,7,4],
[82,33,9,0],
[82,33,9,1],
[82,33,9,2],
[82,33,9,4],
[82,33,10,0],
[82,33,10,1],
[82,33,10,2],
[82,33,10,4],
[82,33,10,7],
[82,33,10,9],
[82,33,13,0],
[82,33,13,1],
[82,33,13,2],
[82,33,13,7],
[82,33,13,9],
[82,33,13,10],
[82,33,14,4],
[82,33,14,7],
[82,33,14,9],
[82,33,14,10],
[82,33,15,0],
[82,33,15,1],
[82,33,15,2],
[82,33,15,4],
[82,33,15,7],
[82,33,15,10],
[82,33,15,13],
[82,33,15,14],
[82,33,16,0],
[82,33,16,1],
[82,33,16,2],
[82,33,16,4],
[82,33,16,7],
[82,33,16,9],
[82,33,16,10],
[82,33,16,13],
[82,33,16,14],
[82,33,16,15],
[82,33,17,0],
[82,33,17,1],
[82,33,17,2],
[82,33,17,4],
[82,33,17,7],
[82,33,17,9],
[82,33,17,10],
[82,33,17,13],
[82,33,17,14],
[82,33,17,15],
[82,33,17,16],
[82,33,18,0],
[82,33,18,1],
[82,33,18,2],
[82,33,18,4],
[82,33,18,7],
[82,33,18,10],
[82,33,18,13],
[82,33,18,14],
[82,33,18,16],
[82,33,18,17],
[82,33,19,0],
[82,33,19,1],
[82,33,19,2],
[82,33,19,4],
[82,33,19,7],
[82,33,19,9],
[82,33,19,10],
[82,33,19,15],
[82,33,20,0],
[82,33,20,1],
[82,33,20,2],
[82,33,20,4],
[82,33,20,7],
[82,33,20,9],
[82,33,20,13],
[82,33,20,14],
[82,33,22,0],
[82,33,22,1],
[82,33,22,2],
[82,33,22,4],
[82,33,22,7],
[82,33,22,9],
[82,33,23,0],
[82,33,23,1],
[82,33,23,2],
[82,33,23,4],
[82,33,23,7],
[82,33,23,9],
[82,33,24,0],
[82,33,24,1],
[82,33,24,2],
[82,33,24,4],
[82,33,24,7],
[82,33,24,9],
[82,33,25,0],
[82,33,25,1],
[82,33,25,2],
[82,33,25,4],
[82,33,26,0],
[82,33,26,1],
[82,33,26,2],
[82,33,26,4],
[82,33,27,0],
[82,33,27,1],
[82,33,27,2],
[82,34,4,0],
[82,34,4,1],
[82,34,4,2],
[82,34,9,0],
[82,34,9,1],
[82,34,9,2],
[82,34,9,4],
[82,34,10,0],
[82,34,10,1],
[82,34,10,2],
[82,34,10,9],
[82,34,11,0],
[82,34,11,1],
[82,34,11,2],
[82,34,11,9],
[82,34,11,10],
[82,34,12,4],
[82,34,12,9],
[82,34,12,10],
[82,34,13,4],
[82,34,13,9],
[82,34,13,10],
[82,34,13,11],
[82,34,13,12],
[82,34,14,0],
[82,34,14,1],
[82,34,14,2],
[82,34,14,4],
[82,34,14,9],
[82,34,14,10],
[82,34,14,11],
[82,34,14,12],
[82,34,15,0],
[82,34,15,1],
[82,34,15,2],
[82,34,15,4],
[82,34,15,10],
[82,34,15,11],
[82,34,15,12],
[82,34,15,13],
[82,34,15,14],
[82,34,16,0],
[82,34,16,1],
[82,34,16,2],
[82,34,16,4],
[82,34,16,9],
[82,34,16,10],
[82,34,16,11],
[82,34,16,12],
[82,34,16,13],
[82,34,16,14],
[82,34,16,15],
[82,34,17,0],
[82,34,17,1],
[82,34,17,2],
[82,34,17,4],
[82,34,17,9],
[82,34,17,10],
[82,34,17,11],
[82,34,17,12],
[82,34,17,13],
[82,34,17,14],
[82,34,17,15],
[82,34,17,16],
[82,34,18,0],
[82,34,18,1],
[82,34,18,2],
[82,34,18,4],
[82,34,18,10],
[82,34,18,11],
[82,34,18,12],
[82,34,18,13],
[82,34,18,14],
[82,34,19,0],
[82,34,19,1],
[82,34,19,2],
[82,34,19,4],
[82,34,19,9],
[82,34,19,10],
[82,34,19,11],
[82,34,19,12],
[82,34,20,0],
[82,34,20,1],
[82,34,20,2],
[82,34,20,4],
[82,34,20,9],
[82,34,20,11],
[82,34,20,12],
[82,34,20,13],
[82,34,22,0],
[82,34,22,1],
[82,34,22,2],
[82,34,22,4],
[82,34,22,9],
[82,34,23,0],
[82,34,23,1],
[82,34,23,2],
[82,34,23,4],
[82,34,24,0],
[82,34,24,1],
[82,34,24,2],
[82,34,24,4],
[82,34,26,0],
[82,34,26,1],
[82,34,26,2],
[82,35,4,0],
[82,35,4,1],
[82,35,4,2],
[82,35,7,0],
[82,35,7,1],
[82,35,7,2],
[82,35,7,4],
[82,35,10,4],
[82,35,10,7],
[82,35,11,4],
[82,35,11,7],
[82,35,11,10],
[82,35,12,0],
[82,35,12,1],
[82,35,12,2],
[82,35,12,4],
[82,35,12,7],
[82,35,12,10],
[82,35,13,0],
[82,35,13,1],
[82,35,13,2],
[82,35,13,4],
[82,35,13,7],
[82,35,13,10],
[82,35,13,11],
[82,35,13,12],
[82,35,14,0],
[82,35,14,1],
[82,35,14,2],
[82,35,14,4],
[82,35,14,7],
[82,35,14,10],
[82,35,14,11],
[82,35,14,12],
[82,35,16,0],
[82,35,16,1],
[82,35,16,2],
[82,35,16,4],
[82,35,16,7],
[82,35,16,10],
[82,35,16,11],
[82,35,16,12],
[82,35,16,13],
[82,35,16,14],
[82,35,17,0],
[82,35,17,1],
[82,35,17,2],
[82,35,17,4],
[82,35,17,7],
[82,35,17,10],
[82,35,17,11],
[82,35,17,12],
[82,35,17,13],
[82,35,17,14],
[82,35,19,0],
[82,35,19,1],
[82,35,19,2],
[82,35,19,4],
[82,35,19,7],
[82,35,19,10],
[82,35,19,11],
[82,35,19,12],
[82,35,20,0],
[82,35,20,1],
[82,35,20,2],
[82,35,20,4],
[82,35,20,7],
[82,35,20,11],
[82,35,22,0],
[82,35,22,1],
[82,35,22,2],
[82,35,22,4],
[82,35,22,7],
[82,35,23,0],
[82,35,23,1],
[82,35,23,2],
[82,35,23,4],
[82,35,24,0],
[82,35,24,1],
[82,35,24,2],
[82,35,24,4],
[82,35,25,0],
[82,35,25,1],
[82,35,25,2],
[82,36,4,0],
[82,36,4,1],
[82,36,4,2],
[82,36,7,0],
[82,36,7,1],
[82,36,7,2],
[82,36,9,4],
[82,36,9,7],
[82,36,10,0],
[82,36,10,1],
[82,36,10,2],
[82,36,10,4],
[82,36,10,7],
[82,36,10,9],
[82,36,11,0],
[82,36,11,1],
[82,36,11,2],
[82,36,11,4],
[82,36,11,7],
[82,36,11,9],
[82,36,11,10],
[82,36,12,0],
[82,36,12,1],
[82,36,12,2],
[82,36,12,4],
[82,36,12,7],
[82,36,12,9],
[82,36,12,10],
[82,36,15,0],
[82,36,15,1],
[82,36,15,2],
[82,36,15,4],
[82,36,15,7],
[82,36,15,10],
[82,36,15,11],
[82,36,15,12],
[82,36,16,0],
[82,36,16,1],
[82,36,16,2],
[82,36,16,4],
[82,36,16,7],
[82,36,16,9],
[82,36,16,10],
[82,36,16,11],
[82,36,16,12],
[82,36,17,0],
[82,36,17,1],
[82,36,17,2],
[82,36,17,4],
[82,36,17,7],
[82,36,17,9],
[82,36,17,10],
[82,36,17,11],
[82,36,17,12],
[82,36,18,0],
[82,36,18,1],
[82,36,18,2],
[82,36,18,4],
[82,36,18,7],
[82,36,18,10],
[82,36,18,11],
[82,36,18,12],
[82,36,20,0],
[82,36,20,1],
[82,36,20,2],
[82,36,20,4],
[82,36,20,7],
[82,36,20,9],
[82,36,22,0],
[82,36,22,1],
[82,36,22,2],
[82,36,22,4],
[82,36,23,0],
[82,36,23,1],
[82,36,23,2],
[82,36,23,4],
[82,36,24,0],
[82,36,24,1],
[82,36,24,2],
[82,36,24,4],
[82,37,9,0],
[82,37,9,1],
[82,37,9,2],
[82,37,9,7],
[82,37,10,0],
[82,37,10,1],
[82,37,10,2],
[82,37,10,7],
[82,37,10,9],
[82,37,11,0],
[82,37,11,1],
[82,37,11,2],
[82,37,11,7],
[82,37,11,9],
[82,37,11,10],
[82,37,12,0],
[82,37,12,1],
[82,37,12,2],
[82,37,12,7],
[82,37,12,9],
[82,37,12,10],
[82,37,13,0],
[82,37,13,1],
[82,37,13,2],
[82,37,13,7],
[82,37,13,9],
[82,37,13,10],
[82,37,13,11],
[82,37,13,12],
[82,37,14,0],
[82,37,14,1],
[82,37,14,2],
[82,37,14,7],
[82,37,14,9],
[82,37,14,10],
[82,37,14,11],
[82,37,14,12],
[82,37,15,0],
[82,37,15,1],
[82,37,15,2],
[82,37,15,7],
[82,37,15,10],
[82,37,15,11],
[82,37,15,12],
[82,37,15,13],
[82,37,15,14],
[82,37,16,0],
[82,37,16,1],
[82,37,16,2],
[82,37,16,7],
[82,37,16,9],
[82,37,16,10],
[82,37,16,11],
[82,37,16,12],
[82,37,16,13],
[82,37,17,0],
[82,37,17,1],
[82,37,17,2],
[82,37,17,7],
[82,37,17,9],
[82,37,17,10],
[82,37,17,11],
[82,37,17,12],
[82,37,17,13],
[82,37,18,0],
[82,37,18,1],
[82,37,18,2],
[82,37,18,7],
[82,37,18,10],
[82,37,18,11],
[82,37,19,0],
[82,37,19,1],
[82,37,19,2],
[82,37,19,7],
[82,37,19,9],
[82,37,20,0],
[82,37,20,1],
[82,37,20,2],
[82,37,20,7],
[82,37,22,0],
[82,37,22,1],
[82,37,22,2],
[82,37,23,0],
[82,37,23,1],
[82,37,23,2],
[82,37,24,0],
[82,37,24,1],
[82,37,24,2],
[82,38,4,0],
[82,38,4,1],
[82,38,4,2],
[82,38,9,0],
[82,38,9,1],
[82,38,9,2],
[82,38,9,4],
[82,38,10,0],
[82,38,10,1],
[82,38,10,2],
[82,38,10,4],
[82,38,10,9],
[82,38,11,0],
[82,38,11,1],
[82,38,11,2],
[82,38,11,4],
[82,38,11,9],
[82,38,11,10],
[82,38,12,0],
[82,38,12,1],
[82,38,12,2],
[82,38,12,4],
[82,38,12,9],
[82,38,12,10],
[82,38,13,0],
[82,38,13,1],
[82,38,13,2],
[82,38,13,4],
[82,38,13,9],
[82,38,13,10],
[82,38,13,11],
[82,38,13,12],
[82,38,14,0],
[82,38,14,1],
[82,38,14,2],
[82,38,14,4],
[82,38,14,9],
[82,38,14,10],
[82,38,14,11],
[82,38,14,12],
[82,38,15,0],
[82,38,15,1],
[82,38,15,2],
[82,38,15,4],
[82,38,15,10],
[82,38,15,11],
[82,38,15,12],
[82,38,15,13],
[82,38,16,0],
[82,38,16,1],
[82,38,16,2],
[82,38,16,4],
[82,38,16,9],
[82,38,16,10],
[82,38,16,11],
[82,38,17,0],
[82,38,17,1],
[82,38,17,2],
[82,38,17,4],
[82,38,17,9],
[82,38,17,10],
[82,38,17,11],
[82,38,18,0],
[82,38,18,1],
[82,38,18,2],
[82,38,18,4],
[82,38,19,0],
[82,38,19,1],
[82,38,19,2],
[82,38,19,4],
[82,38,20,0],
[82,38,20,1],
[82,38,20,2],
[82,38,20,4],
[82,38,22,0],
[82,38,22,1],
[82,38,22,2],
[82,40,4,0],
[82,40,4,1],
[82,40,4,2],
[82,40,7,0],
[82,40,7,1],
[82,40,7,2],
[82,40,7,4],
[82,40,10,0],
[82,40,10,1],
[82,40,10,2],
[82,40,10,4],
[82,40,10,7],
[82,40,11,0],
[82,40,11,1],
[82,40,11,2],
[82,40,11,4],
[82,40,11,7],
[82,40,11,10],
[82,40,12,0],
[82,40,12,1],
[82,40,12,2],
[82,40,12,4],
[82,40,12,7],
[82,40,12,10],
[82,40,13,0],
[82,40,13,1],
[82,40,13,2],
[82,40,13,4],
[82,40,13,7],
[82,40,13,10],
[82,40,13,11],
[82,40,13,12],
[82,40,14,0],
[82,40,14,1],
[82,40,14,2],
[82,40,14,4],
[82,40,14,7],
[82,40,14,10],
[82,40,14,11],
[82,40,16,0],
[82,40,16,1],
[82,40,16,2],
[82,40,16,4],
[82,40,16,7],
[82,40,17,0],
[82,40,17,1],
[82,40,17,2],
[82,40,17,4],
[82,40,17,7],
[82,40,19,0],
[82,40,19,1],
[82,40,19,2],
[82,40,19,4],
[82,40,20,0],
[82,40,20,1],
[82,40,20,2],
[82,41,4,0],
[82,41,4,1],
[82,41,4,2],
[82,41,7,0],
[82,41,7,1],
[82,41,7,2],
[82,41,7,4],
[82,41,10,0],
[82,41,10,1],
[82,41,10,2],
[82,41,10,4],
[82,41,10,7],
[82,41,11,0],
[82,41,11,1],
[82,41,11,2],
[82,41,11,4],
[82,41,11,7],
[82,41,11,10],
[82,41,12,0],
[82,41,12,1],
[82,41,12,2],
[82,41,12,4],
[82,41,12,7],
[82,41,12,10],
[82,41,13,0],
[82,41,13,1],
[82,41,13,2],
[82,41,13,4],
[82,41,13,7],
[82,41,13,10],
[82,41,13,11],
[82,41,13,12],
[82,41,14,0],
[82,41,14,1],
[82,41,14,2],
[82,41,14,4],
[82,41,14,7],
[82,41,14,10],
[82,41,14,11],
[82,41,16,0],
[82,41,16,1],
[82,41,16,2],
[82,41,16,4],
[82,41,16,7],
[82,41,17,0],
[82,41,17,1],
[82,41,17,2],
[82,41,17,4],
[82,41,17,7],
[82,41,19,0],
[82,41,19,1],
[82,41,19,2],
[82,41,19,4],
[82,41,20,0],
[82,41,20,1],
[82,41,20,2],
[82,42,4,0],
[82,42,4,1],
[82,42,4,2],
[82,42,7,0],
[82,42,7,1],
[82,42,7,2],
[82,42,7,4],
[82,42,9,0],
[82,42,9,1],
[82,42,9,2],
[82,42,9,4],
[82,42,9,7],
[82,42,11,0],
[82,42,11,1],
[82,42,11,2],
[82,42,11,4],
[82,42,11,7],
[82,42,11,9],
[82,42,12,0],
[82,42,12,1],
[82,42,12,2],
[82,42,12,4],
[82,42,12,7],
[82,42,12,9],
[82,42,13,0],
[82,42,13,1],
[82,42,13,2],
[82,42,13,4],
[82,42,13,7],
[82,42,13,9],
[82,42,13,11],
[82,42,14,0],
[82,42,14,1],
[82,42,14,2],
[82,42,14,4],
[82,42,14,7],
[82,42,14,9],
[82,42,15,0],
[82,42,15,1],
[82,42,15,2],
[82,42,15,4],
[82,42,15,7],
[82,42,16,0],
[82,42,16,1],
[82,42,16,2],
[82,42,16,4],
[82,42,17,0],
[82,42,17,1],
[82,42,17,2],
[82,42,17,4],
[82,42,18,0],
[82,42,18,1],
[82,42,18,2],
[82,42,18,4],
[82,42,19,0],
[82,42,19,1],
[82,42,19,2],
[82,43,4,0],
[82,43,4,1],
[82,43,4,2],
[82,43,9,0],
[82,43,9,1],
[82,43,9,2],
[82,43,9,4],
[82,43,10,0],
[82,43,10,1],
[82,43,10,2],
[82,43,10,4],
[82,43,10,9],
[82,43,11,0],
[82,43,11,1],
[82,43,11,2],
[82,43,11,4],
[82,43,11,9],
[82,43,11,10],
[82,43,12,0],
[82,43,12,1],
[82,43,12,2],
[82,43,12,4],
[82,43,12,9],
[82,43,13,0],
[82,43,13,1],
[82,43,13,2],
[82,43,13,4],
[82,43,13,9],
[82,43,14,0],
[82,43,14,1],
[82,43,14,2],
[82,43,14,4],
[82,43,15,0],
[82,43,15,1],
[82,43,15,2],
[82,43,15,4],
[82,43,16,0],
[82,43,16,1],
[82,43,16,2],
[82,43,16,4],
[82,43,17,0],
[82,43,17,1],
[82,43,17,2],
[82,43,17,4],
[82,43,18,0],
[82,43,18,1],
[82,43,18,2],
[82,44,4,0],
[82,44,4,1],
[82,44,4,2],
[82,44,7,0],
[82,44,7,1],
[82,44,7,2],
[82,44,7,4],
[82,44,9,0],
[82,44,9,1],
[82,44,9,2],
[82,44,9,4],
[82,44,9,7],
[82,44,10,0],
[82,44,10,1],
[82,44,10,2],
[82,44,10,4],
[82,44,10,7],
[82,44,10,9],
[82,44,11,0],
[82,44,11,1],
[82,44,11,2],
[82,44,11,4],
[82,44,11,7],
[82,44,11,9],
[82,44,12,0],
[82,44,12,1],
[82,44,12,2],
[82,44,12,4],
[82,44,12,7],
[82,44,13,0],
[82,44,13,1],
[82,44,13,2],
[82,44,13,4],
[82,44,13,7],
[82,44,14,0],
[82,44,14,1],
[82,44,14,2],
[82,44,14,4],
[82,44,15,0],
[82,44,15,1],
[82,44,15,2],
[82,44,15,4],
[82,44,16,0],
[82,44,16,1],
[82,44,16,2],
[82,45,4,0],
[82,45,4,1],
[82,45,4,2],
[82,45,7,0],
[82,45,7,1],
[82,45,7,2],
[82,45,7,4],
[82,45,10,0],
[82,45,10,1],
[82,45,10,2],
[82,45,10,4],
[82,45,10,7],
[82,45,11,0],
[82,45,11,1],
[82,45,11,2],
[82,45,11,4],
[82,45,11,7],
[82,45,12,0],
[82,45,12,1],
[82,45,12,2],
[82,45,12,4],
[82,45,13,0],
[82,45,13,1],
[82,45,13,2],
[82,45,13,4],
[82,45,14,0],
[82,45,14,1],
[82,45,14,2],
[82,45,14,4],
[82,46,4,0],
[82,46,4,1],
[82,46,4,2],
[82,46,7,0],
[82,46,7,1],
[82,46,7,2],
[82,46,7,4],
[82,46,10,0],
[82,46,10,1],
[82,46,10,2],
[82,46,10,4],
[82,46,11,0],
[82,46,11,1],
[82,46,11,2],
[82,46,11,4],
[82,46,12,0],
[82,46,12,1],
[82,46,12,2],
[82,46,12,4],
[82,46,13,0],
[82,46,13,1],
[82,46,13,2],
[82,46,13,4],
[82,46,14,0],
[82,46,14,1],
[82,46,14,2],
[82,47,4,0],
[82,47,4,1],
[82,47,4,2],
[82,47,7,0],
[82,47,7,1],
[82,47,7,2],
[82,47,7,4],
[82,47,9,0],
[82,47,9,1],
[82,47,9,2],
[82,47,9,4],
[82,47,10,0],
[82,47,10,1],
[82,47,10,2],
[82,47,10,4],
[82,47,11,0],
[82,47,11,1],
[82,47,11,2],
[82,47,11,4],
[82,47,12,0],
[82,47,12,1],
[82,47,12,2],
[82,47,13,0],
[82,47,13,1],
[82,47,13,2],
[82,48,4,0],
[82,48,4,1],
[82,48,4,2],
[82,48,7,0],
[82,48,7,1],
[82,48,7,2],
[82,48,7,4],
[82,48,9,0],
[82,48,9,1],
[82,48,9,2],
[82,48,9,4],
[82,48,10,0],
[82,48,10,1],
[82,48,10,2],
[82,49,4,0],
[82,49,4,1],
[82,49,4,2],
[82,49,7,0],
[82,49,7,1],
[82,49,7,2],
[82,49,7,4],
[82,49,10,0],
[82,49,10,1],
[82,49,10,2],
[82,49,11,0],
[82,49,11,1],
[82,49,11,2],
[82,50,4,0],
[82,50,4,1],
[82,50,4,2],
[82,50,7,0],
[82,50,7,1],
[82,50,7,2],
[82,50,7,4],
[82,50,10,0],
[82,50,10,1],
[82,50,10,2],
[82,50,11,0],
[82,50,11,1],
[82,50,11,2],
[82,52,4,0],
[82,52,4,1],
[82,52,4,2],
[82,52,7,0],
[82,52,7,1],
[82,52,7,2],
[82,52,7,4],
[82,52,10,0],
[82,52,10,1],
[82,52,10,2],
[82,52,11,0],
[82,52,11,1],
[82,52,11,2],
[82,53,4,0],
[82,53,4,1],
[82,53,4,2],
[82,53,7,0],
[82,53,7,1],
[82,53,7,2],
[82,53,7,4],
[82,53,9,0],
[82,53,9,1],
[82,53,9,2],
[82,53,9,4],
[82,53,11,0],
[82,53,11,1],
[82,53,11,2],
[82,54,4,0],
[82,54,4,1],
[82,54,4,2],
[82,54,7,0],
[82,54,7,1],
[82,54,7,2],
[82,54,7,4],
[82,54,9,0],
[82,54,9,1],
[82,54,9,2],
[82,54,9,4],
[82,54,11,0],
[82,54,11,1],
[82,54,11,2],
[82,55,4,0],
[82,55,4,1],
[82,55,4,2],
[82,55,7,0],
[82,55,7,1],
[82,55,7,2],
[82,55,7,4],
[82,55,9,0],
[82,55,9,1],
[82,55,9,2],
[82,55,9,4],
[82,55,11,0],
[82,55,11,1],
[82,55,11,2],
[82,56,4,0],
[82,56,4,1],
[82,56,4,2],
[82,56,7,0],
[82,56,7,1],
[82,56,7,2],
[82,56,7,4],
[82,56,10,0],
[82,56,10,1],
[82,56,10,2],
[82,56,11,0],
[82,56,11,1],
[82,56,11,2],
[82,57,4,0],
[82,57,4,1],
[82,57,4,2],
[82,57,7,0],
[82,57,7,1],
[82,57,7,2],
[82,57,7,4],
[82,57,9,0],
[82,57,9,1],
[82,57,9,2],
[82,58,4,0],
[82,58,4,1],
[82,58,4,2],
[82,58,7,0],
[82,58,7,1],
[82,58,7,2],
[82,58,7,4],
[82,58,9,0],
[82,58,9,1],
[82,58,9,2],
[82,59,4,0],
[82,59,4,1],
[82,59,4,2],
[82,59,7,0],
[82,59,7,1],
[82,59,7,2],
[82,60,4,0],
[82,60,4,1],
[82,60,4,2],
[82,62,4,0],
[82,62,4,1],
[82,62,4,2],
[83,7,3,0],
[83,7,3,1],
[83,7,3,2],
[83,7,5,0],
[83,7,5,1],
[83,7,5,2],
[83,7,6,0],
[83,7,6,1],
[83,7,6,2],
[83,8,7,0],
[83,8,7,1],
[83,8,7,2],
[83,9,3,0],
[83,9,3,1],
[83,9,3,2],
[83,9,5,0],
[83,9,5,1],
[83,9,5,2],
[83,9,6,0],
[83,9,6,1],
[83,9,6,2],
[83,9,7,0],
[83,9,7,1],
[83,9,7,2],
[83,9,7,3],
[83,9,7,5],
[83,9,7,6],
[83,9,8,0],
[83,9,8,1],
[83,9,8,2],
[83,9,8,7],
[83,10,3,0],
[83,10,3,1],
[83,10,3,2],
[83,10,5,0],
[83,10,5,1],
[83,10,5,2],
[83,10,6,0],
[83,10,6,1],
[83,10,6,2],
[83,10,7,0],
[83,10,7,1],
[83,10,7,2],
[83,10,7,3],
[83,10,7,5],
[83,10,7,6],
[83,10,8,0],
[83,10,8,1],
[83,10,8,2],
[83,10,8,7],
[83,10,9,0],
[83,10,9,1],
[83,10,9,2],
[83,10,9,3],
[83,10,9,5],
[83,10,9,6],
[83,10,9,7],
[83,10,9,8],
[83,11,3,0],
[83,11,3,1],
[83,11,3,2],
[83,11,5,0],
[83,11,5,1],
[83,11,5,2],
[83,11,6,0],
[83,11,6,1],
[83,11,6,2],
[83,11,7,0],
[83,11,7,1],
[83,11,7,2],
[83,11,7,3],
[83,11,7,5],
[83,11,7,6],
[83,11,8,0],
[83,11,8,1],
[83,11,8,2],
[83,11,8,7],
[83,11,9,0],
[83,11,9,1],
[83,11,9,2],
[83,11,9,3],
[83,11,9,5],
[83,11,9,6],
[83,11,9,7],
[83,11,9,8],
[83,11,10,0],
[83,11,10,1],
[83,11,10,2],
[83,11,10,3],
[83,11,10,5],
[83,11,10,6],
[83,11,10,7],
[83,11,10,8],
[83,11,10,9],
[83,12,3,0],
[83,12,3,1],
[83,12,3,2],
[83,12,5,0],
[83,12,5,1],
[83,12,5,2],
[83,12,6,0],
[83,12,6,1],
[83,12,6,2],
[83,12,7,0],
[83,12,7,1],
[83,12,7,2],
[83,12,7,3],
[83,12,7,5],
[83,12,7,6],
[83,12,8,0],
[83,12,8,1],
[83,12,8,2],
[83,12,8,7],
[83,12,9,0],
[83,12,9,1],
[83,12,9,2],
[83,12,9,3],
[83,12,9,5],
[83,12,9,6],
[83,12,9,7],
[83,12,9,8],
[83,12,10,0],
[83,12,10,1],
[83,12,10,2],
[83,12,10,3],
[83,12,10,5],
[83,12,10,6],
[83,12,10,7],
[83,12,10,8],
[83,12,10,9],
[83,13,3,0],
[83,13,3,1],
[83,13,3,2],
[83,13,5,0],
[83,13,5,1],
[83,13,5,2],
[83,13,6,0],
[83,13,6,1],
[83,13,6,2],
[83,13,7,0],
[83,13,7,1],
[83,13,7,2],
[83,13,7,3],
[83,13,7,5],
[83,13,7,6],
[83,13,8,0],
[83,13,8,1],
[83,13,8,2],
[83,13,8,7],
[83,13,9,0],
[83,13,9,1],
[83,13,9,2],
[83,13,9,3],
[83,13,9,5],
[83,13,9,6],
[83,13,9,7],
[83,13,9,8],
[83,13,10,0],
[83,13,10,1],
[83,13,10,2],
[83,13,10,3],
[83,13,10,5],
[83,13,10,6],
[83,13,10,7],
[83,13,10,8],
[83,13,10,9],
[83,13,11,0],
[83,13,11,1],
[83,13,11,2],
[83,13,11,3],
[83,13,11,5],
[83,13,11,6],
[83,13,11,7],
[83,13,11,8],
[83,13,11,9],
[83,13,11,10],
[83,13,12,0],
[83,13,12,1],
[83,13,12,2],
[83,13,12,3],
[83,13,12,5],
[83,13,12,6],
[83,13,12,7],
[83,13,12,8],
[83,13,12,9],
[83,13,12,10],
[83,14,3,0],
[83,14,3,1],
[83,14,3,2],
[83,14,5,0],
[83,14,5,1],
[83,14,5,2],
[83,14,6,0],
[83,14,6,1],
[83,14,6,2],
[83,14,7,0],
[83,14,7,1],
[83,14,7,2],
[83,14,7,3],
[83,14,7,5],
[83,14,7,6],
[83,14,8,0],
[83,14,8,1],
[83,14,8,2],
[83,14,8,7],
[83,14,9,0],
[83,14,9,1],
[83,14,9,2],
[83,14,9,3],
[83,14,9,5],
[83,14,9,6],
[83,14,9,7],
[83,14,9,8],
[83,14,10,0],
[83,14,10,1],
[83,14,10,2],
[83,14,10,3],
[83,14,10,5],
[83,14,10,6],
[83,14,10,7],
[83,14,10,8],
[83,14,10,9],
[83,14,11,0],
[83,14,11,1],
[83,14,11,2],
[83,14,11,3],
[83,14,11,5],
[83,14,11,6],
[83,14,11,7],
[83,14,11,8],
[83,14,11,9],
[83,14,11,10],
[83,14,12,0],
[83,14,12,1],
[83,14,12,2],
[83,14,12,3],
[83,14,12,5],
[83,14,12,6],
[83,14,12,7],
[83,14,12,8],
[83,14,12,9],
[83,14,12,10],
[83,15,3,0],
[83,15,3,1],
[83,15,3,2],
[83,15,5,0],
[83,15,5,1],
[83,15,5,2],
[83,15,6,0],
[83,15,6,1],
[83,15,6,2],
[83,15,7,0],
[83,15,7,1],
[83,15,7,2],
[83,15,7,3],
[83,15,7,5],
[83,15,7,6],
[83,15,8,0],
[83,15,8,1],
[83,15,8,2],
[83,15,8,7],
[83,15,10,0],
[83,15,10,1],
[83,15,10,2],
[83,15,10,3],
[83,15,10,5],
[83,15,10,6],
[83,15,10,7],
[83,15,10,8],
[83,15,11,0],
[83,15,11,1],
[83,15,11,2],
[83,15,11,3],
[83,15,11,5],
[83,15,11,6],
[83,15,11,7],
[83,15,11,8],
[83,15,11,10],
[83,15,12,0],
[83,15,12,1],
[83,15,12,2],
[83,15,12,3],
[83,15,12,5],
[83,15,12,6],
[83,15,12,7],
[83,15,12,8],
[83,15,12,10],
[83,15,13,0],
[83,15,13,1],
[83,15,13,2],
[83,15,13,3],
[83,15,13,5],
[83,15,13,6],
[83,15,13,7],
[83,15,13,8],
[83,15,13,10],
[83,15,13,11],
[83,15,13,12],
[83,15,14,0],
[83,15,14,1],
[83,15,14,2],
[83,15,14,3],
[83,15,14,5],
[83,15,14,6],
[83,15,14,7],
[83,15,14,8],
[83,15,14,10],
[83,15,14,11],
[83,15,14,12],
[83,16,3,0],
[83,16,3,1],
[83,16,3,2],
[83,16,5,0],
[83,16,5,1],
[83,16,5,2],
[83,16,6,0],
[83,16,6,1],
[83,16,6,2],
[83,16,7,0],
[83,16,7,1],
[83,16,7,2],
[83,16,7,3],
[83,16,7,5],
[83,16,7,6],
[83,16,8,0],
[83,16,8,1],
[83,16,8,2],
[83,16,8,7],
[83,16,9,0],
[83,16,9,1],
[83,16,9,2],
[83,16,9,3],
[83,16,9,5],
[83,16,9,6],
[83,16,9,7],
[83,16,9,8],
[83,16,10,0],
[83,16,10,1],
[83,16,10,2],
[83,16,10,3],
[83,16,10,5],
[83,16,10,6],
[83,16,10,7],
[83,16,10,8],
[83,16,10,9],
[83,16,11,0],
[83,16,11,1],
[83,16,11,2],
[83,16,11,3],
[83,16,11,5],
[83,16,11,6],
[83,16,11,7],
[83,16,11,8],
[83,16,11,9],
[83,16,11,10],
[83,16,12,0],
[83,16,12,1],
[83,16,12,2],
[83,16,12,3],
[83,16,12,5],
[83,16,12,6],
[83,16,12,7],
[83,16,12,8],
[83,16,12,9],
[83,16,12,10],
[83,16,13,0],
[83,16,13,1],
[83,16,13,2],
[83,16,13,3],
[83,16,13,5],
[83,16,13,6],
[83,16,13,7],
[83,16,13,8],
[83,16,13,9],
[83,16,13,10],
[83,16,13,11],
[83,16,13,12],
[83,16,14,0],
[83,16,14,1],
[83,16,14,2],
[83,16,14,3],
[83,16,14,5],
[83,16,14,6],
[83,16,14,7],
[83,16,14,8],
[83,16,14,9],
[83,16,14,10],
[83,16,14,11],
[83,16,14,12],
[83,16,15,0],
[83,16,15,1],
[83,16,15,2],
[83,16,15,3],
[83,16,15,5],
[83,16,15,6],
[83,16,15,7],
[83,16,15,8],
[83,16,15,10],
[83,16,15,11],
[83,16,15,12],
[83,16,15,13],
[83,16,15,14],
[83,17,3,0],
[83,17,3,1],
[83,17,3,2],
[83,17,5,0],
[83,17,5,1],
[83,17,5,2],
[83,17,6,0],
[83,17,6,1],
[83,17,6,2],
[83,17,7,0],
[83,17,7,1],
[83,17,7,2],
[83,17,7,3],
[83,17,7,5],
[83,17,7,6],
[83,17,8,0],
[83,17,8,1],
[83,17,8,2],
[83,17,8,7],
[83,17,9,0],
[83,17,9,1],
[83,17,9,2],
[83,17,9,3],
[83,17,9,5],
[83,17,9,6],
[83,17,9,7],
[83,17,9,8],
[83,17,10,0],
[83,17,10,1],
[83,17,10,2],
[83,17,10,3],
[83,17,10,5],
[83,17,10,6],
[83,17,10,7],
[83,17,10,8],
[83,17,10,9],
[83,17,11,0],
[83,17,11,1],
[83,17,11,2],
[83,17,11,3],
[83,17,11,5],
[83,17,11,6],
[83,17,11,7],
[83,17,11,8],
[83,17,11,9],
[83,17,11,10],
[83,17,12,0],
[83,17,12,1],
[83,17,12,2],
[83,17,12,3],
[83,17,12,5],
[83,17,12,6],
[83,17,12,7],
[83,17,12,8],
[83,17,12,9],
[83,17,12,10],
[83,17,13,0],
[83,17,13,1],
[83,17,13,2],
[83,17,13,3],
[83,17,13,5],
[83,17,13,6],
[83,17,13,7],
[83,17,13,8],
[83,17,13,9],
[83,17,13,10],
[83,17,13,11],
[83,17,13,12],
[83,17,14,0],
[83,17,14,1],
[83,17,14,2],
[83,17,14,3],
[83,17,14,5],
[83,17,14,6],
[83,17,14,7],
[83,17,14,8],
[83,17,14,9],
[83,17,14,10],
[83,17,14,11],
[83,17,14,12],
[83,17,15,0],
[83,17,15,1],
[83,17,15,2],
[83,17,15,3],
[83,17,15,5],
[83,17,15,6],
[83,17,15,7],
[83,17,15,8],
[83,17,15,10],
[83,17,15,11],
[83,17,15,12],
[83,17,15,13],
[83,17,15,14],
[83,17,16,0],
[83,17,16,1],
[83,17,16,2],
[83,17,16,3],
[83,17,16,5],
[83,17,16,6],
[83,17,16,7],
[83,17,16,8],
[83,17,16,9],
[83,17,16,10],
[83,17,16,11],
[83,17,16,12],
[83,17,16,13],
[83,17,16,14],
[83,17,16,15],
[83,18,3,0],
[83,18,3,1],
[83,18,3,2],
[83,18,5,0],
[83,18,5,1],
[83,18,5,2],
[83,18,6,0],
[83,18,6,1],
[83,18,6,2],
[83,18,7,0],
[83,18,7,1],
[83,18,7,2],
[83,18,7,3],
[83,18,7,5],
[83,18,7,6],
[83,18,8,0],
[83,18,8,1],
[83,18,8,2],
[83,18,8,7],
[83,18,10,0],
[83,18,10,1],
[83,18,10,2],
[83,18,10,3],
[83,18,10,5],
[83,18,10,6],
[83,18,10,7],
[83,18,10,8],
[83,18,11,0],
[83,18,11,1],
[83,18,11,2],
[83,18,11,3],
[83,18,11,5],
[83,18,11,6],
[83,18,11,7],
[83,18,11,8],
[83,18,11,10],
[83,18,12,0],
[83,18,12,1],
[83,18,12,2],
[83,18,12,3],
[83,18,12,5],
[83,18,12,6],
[83,18,12,7],
[83,18,12,8],
[83,18,12,10],
[83,18,13,0],
[83,18,13,1],
[83,18,13,2],
[83,18,13,3],
[83,18,13,5],
[83,18,13,6],
[83,18,13,7],
[83,18,13,8],
[83,18,13,10],
[83,18,13,11],
[83,18,13,12],
[83,18,14,0],
[83,18,14,1],
[83,18,14,2],
[83,18,14,3],
[83,18,14,5],
[83,18,14,6],
[83,18,14,7],
[83,18,14,8],
[83,18,14,10],
[83,18,14,11],
[83,18,14,12],
[83,18,16,0],
[83,18,16,1],
[83,18,16,2],
[83,18,16,3],
[83,18,16,5],
[83,18,16,6],
[83,18,16,7],
[83,18,16,8],
[83,18,16,10],
[83,18,16,11],
[83,18,16,12],
[83,18,16,13],
[83,18,16,14],
[83,18,17,0],
[83,18,17,1],
[83,18,17,2],
[83,18,17,3],
[83,18,17,5],
[83,18,17,6],
[83,18,17,7],
[83,18,17,8],
[83,18,17,10],
[83,18,17,11],
[83,18,17,12],
[83,18,17,13],
[83,18,17,14],
[83,18,17,16],
[83,19,3,0],
[83,19,3,1],
[83,19,3,2],
[83,19,5,0],
[83,19,5,1],
[83,19,5,2],
[83,19,6,0],
[83,19,6,1],
[83,19,6,2],
[83,19,7,0],
[83,19,7,1],
[83,19,7,2],
[83,19,7,3],
[83,19,7,5],
[83,19,7,6],
[83,19,8,0],
[83,19,8,1],
[83,19,8,2],
[83,19,8,7],
[83,19,9,0],
[83,19,9,1],
[83,19,9,2],
[83,19,9,3],
[83,19,9,5],
[83,19,9,6],
[83,19,9,7],
[83,19,9,8],
[83,19,10,0],
[83,19,10,1],
[83,19,10,2],
[83,19,10,3],
[83,19,10,5],
[83,19,10,6],
[83,19,10,7],
[83,19,10,8],
[83,19,10,9],
[83,19,11,0],
[83,19,11,1],
[83,19,11,2],
[83,19,11,3],
[83,19,11,5],
[83,19,11,6],
[83,19,11,7],
[83,19,11,8],
[83,19,11,9],
[83,19,11,10],
[83,19,12,0],
[83,19,12,1],
[83,19,12,2],
[83,19,12,3],
[83,19,12,5],
[83,19,12,6],
[83,19,12,7],
[83,19,12,8],
[83,19,12,9],
[83,19,12,10],
[83,19,15,0],
[83,19,15,1],
[83,19,15,2],
[83,19,15,3],
[83,19,15,5],
[83,19,15,6],
[83,19,15,7],
[83,19,15,8],
[83,19,15,10],
[83,19,15,11],
[83,19,15,12],
[83,19,16,0],
[83,19,16,1],
[83,19,16,2],
[83,19,16,3],
[83,19,16,5],
[83,19,16,6],
[83,19,16,7],
[83,19,16,8],
[83,19,16,9],
[83,19,16,10],
[83,19,16,11],
[83,19,16,12],
[83,19,16,15],
[83,19,17,0],
[83,19,17,1],
[83,19,17,2],
[83,19,17,3],
[83,19,17,5],
[83,19,17,6],
[83,19,17,7],
[83,19,17,8],
[83,19,17,9],
[83,19,17,10],
[83,19,17,11],
[83,19,17,12],
[83,19,17,15],
[83,19,17,16],
[83,19,18,0],
[83,19,18,1],
[83,19,18,2],
[83,19,18,3],
[83,19,18,5],
[83,19,18,6],
[83,19,18,7],
[83,19,18,8],
[83,19,18,10],
[83,19,18,11],
[83,19,18,12],
[83,19,18,16],
[83,19,18,17],
[83,20,3,0],
[83,20,3,1],
[83,20,3,2],
[83,20,5,0],
[83,20,5,1],
[83,20,5,2],
[83,20,6,0],
[83,20,6,1],
[83,20,6,2],
[83,20,7,0],
[83,20,7,1],
[83,20,7,2],
[83,20,7,3],
[83,20,7,5],
[83,20,7,6],
[83,20,8,0],
[83,20,8,1],
[83,20,8,2],
[83,20,8,7],
[83,20,9,0],
[83,20,9,1],
[83,20,9,2],
[83,20,9,3],
[83,20,9,5],
[83,20,9,6],
[83,20,9,7],
[83,20,9,8],
[83,20,11,0],
[83,20,11,1],
[83,20,11,2],
[83,20,11,3],
[83,20,11,5],
[83,20,11,6],
[83,20,11,7],
[83,20,11,8],
[83,20,11,9],
[83,20,12,0],
[83,20,12,1],
[83,20,12,2],
[83,20,12,3],
[83,20,12,5],
[83,20,12,6],
[83,20,12,7],
[83,20,12,8],
[83,20,12,9],
[83,20,13,0],
[83,20,13,1],
[83,20,13,2],
[83,20,13,3],
[83,20,13,5],
[83,20,13,6],
[83,20,13,7],
[83,20,13,8],
[83,20,13,9],
[83,20,13,11],
[83,20,13,12],
[83,20,14,0],
[83,20,14,1],
[83,20,14,2],
[83,20,14,3],
[83,20,14,5],
[83,20,14,6],
[83,20,14,7],
[83,20,14,8],
[83,20,14,9],
[83,20,14,11],
[83,20,14,12],
[83,20,15,0],
[83,20,15,1],
[83,20,15,2],
[83,20,15,3],
[83,20,15,5],
[83,20,15,6],
[83,20,15,7],
[83,20,15,8],
[83,20,15,11],
[83,20,15,12],
[83,20,15,13],
[83,20,15,14],
[83,20,16,0],
[83,20,16,1],
[83,20,16,2],
[83,20,16,3],
[83,20,16,5],
[83,20,16,6],
[83,20,16,7],
[83,20,16,8],
[83,20,16,9],
[83,20,16,11],
[83,20,16,12],
[83,20,16,13],
[83,20,16,14],
[83,20,16,15],
[83,20,17,0],
[83,20,17,1],
[83,20,17,2],
[83,20,17,3],
[83,20,17,5],
[83,20,17,6],
[83,20,17,7],
[83,20,17,8],
[83,20,17,9],
[83,20,17,11],
[83,20,17,12],
[83,20,17,13],
[83,20,17,14],
[83,20,17,15],
[83,20,17,16],
[83,20,18,0],
[83,20,18,1],
[83,20,18,2],
[83,20,18,3],
[83,20,18,5],
[83,20,18,6],
[83,20,18,7],
[83,20,18,8],
[83,20,18,11],
[83,20,18,12],
[83,20,18,13],
[83,20,18,14],
[83,20,19,0],
[83,20,19,1],
[83,20,19,2],
[83,20,19,3],
[83,20,19,5],
[83,20,19,6],
[83,20,19,7],
[83,20,19,8],
[83,20,19,9],
[83,20,19,11],
[83,20,19,12],
[83,20,19,16],
[83,20,19,17],
[83,20,19,18],
[83,21,7,0],
[83,21,7,1],
[83,21,7,2],
[83,21,9,0],
[83,21,9,1],
[83,21,9,2],
[83,21,9,7],
[83,21,10,0],
[83,21,10,1],
[83,21,10,2],
[83,21,10,7],
[83,21,10,9],
[83,21,11,0],
[83,21,11,1],
[83,21,11,2],
[83,21,11,7],
[83,21,11,9],
[83,21,11,10],
[83,21,12,0],
[83,21,12,1],
[83,21,12,2],
[83,21,12,7],
[83,21,12,9],
[83,21,12,10],
[83,21,13,0],
[83,21,13,1],
[83,21,13,2],
[83,21,13,7],
[83,21,13,9],
[83,21,13,10],
[83,21,13,11],
[83,21,13,12],
[83,21,14,0],
[83,21,14,1],
[83,21,14,2],
[83,21,14,7],
[83,21,14,9],
[83,21,14,10],
[83,21,14,11],
[83,21,14,12],
[83,21,15,0],
[83,21,15,1],
[83,21,15,2],
[83,21,15,7],
[83,21,15,10],
[83,21,15,11],
[83,21,15,12],
[83,21,15,13],
[83,21,15,14],
[83,21,16,0],
[83,21,16,1],
[83,21,16,2],
[83,21,16,7],
[83,21,16,9],
[83,21,16,10],
[83,21,16,11],
[83,21,16,12],
[83,21,16,13],
[83,21,16,14],
[83,21,16,15],
[83,21,17,0],
[83,21,17,1],
[83,21,17,2],
[83,21,17,7],
[83,21,17,9],
[83,21,17,10],
[83,21,17,11],
[83,21,17,12],
[83,21,17,13],
[83,21,17,14],
[83,21,17,15],
[83,21,18,0],
[83,21,18,1],
[83,21,18,2],
[83,21,18,7],
[83,21,18,10],
[83,21,18,11],
[83,21,18,12],
[83,21,18,13],
[83,21,18,14],
[83,21,18,16],
[83,21,18,17],
[83,21,19,0],
[83,21,19,1],
[83,21,19,2],
[83,21,19,7],
[83,21,19,9],
[83,21,19,10],
[83,21,19,11],
[83,21,19,12],
[83,21,19,15],
[83,21,19,16],
[83,21,19,17],
[83,21,19,18],
[83,21,20,0],
[83,21,20,1],
[83,21,20,2],
[83,21,20,7],
[83,21,20,9],
[83,21,20,11],
[83,21,20,14],
[83,21,20,15],
[83,21,20,16],
[83,21,20,17],
[83,21,20,18],
[83,21,20,19],
[83,22,3,0],
[83,22,3,1],
[83,22,3,2],
[83,22,5,0],
[83,22,5,1],
[83,22,5,2],
[83,22,6,0],
[83,22,6,1],
[83,22,6,2],
[83,22,7,0],
[83,22,7,1],
[83,22,7,2],
[83,22,7,3],
[83,22,7,5],
[83,22,7,6],
[83,22,8,0],
[83,22,8,1],
[83,22,8,2],
[83,22,8,7],
[83,22,9,0],
[83,22,9,1],
[83,22,9,2],
[83,22,9,3],
[83,22,9,5],
[83,22,9,6],
[83,22,9,7],
[83,22,9,8],
[83,22,11,0],
[83,22,11,1],
[83,22,11,2],
[83,22,11,3],
[83,22,11,5],
[83,22,11,6],
[83,22,11,7],
[83,22,11,8],
[83,22,11,9],
[83,22,12,0],
[83,22,12,1],
[83,22,12,2],
[83,22,12,3],
[83,22,12,5],
[83,22,12,6],
[83,22,12,7],
[83,22,12,8],
[83,22,12,9],
[83,22,13,0],
[83,22,13,1],
[83,22,13,2],
[83,22,13,3],
[83,22,13,5],
[83,22,13,6],
[83,22,13,7],
[83,22,13,8],
[83,22,13,9],
[83,22,13,11],
[83,22,13,12],
[83,22,14,0],
[83,22,14,1],
[83,22,14,2],
[83,22,14,3],
[83,22,14,5],
[83,22,14,6],
[83,22,14,7],
[83,22,14,8],
[83,22,14,9],
[83,22,14,11],
[83,22,14,12],
[83,22,15,0],
[83,22,15,1],
[83,22,15,2],
[83,22,15,3],
[83,22,15,5],
[83,22,15,6],
[83,22,15,7],
[83,22,15,8],
[83,22,15,11],
[83,22,15,12],
[83,22,15,13],
[83,22,15,14],
[83,22,16,0],
[83,22,16,1],
[83,22,16,2],
[83,22,16,3],
[83,22,16,5],
[83,22,16,6],
[83,22,16,7],
[83,22,16,8],
[83,22,16,9],
[83,22,16,11],
[83,22,16,12],
[83,22,16,13],
[83,22,16,14],
[83,22,17,0],
[83,22,17,1],
[83,22,17,2],
[83,22,17,3],
[83,22,17,5],
[83,22,17,6],
[83,22,17,7],
[83,22,17,8],
[83,22,17,9],
[83,22,17,11],
[83,22,17,12],
[83,22,17,13],
[83,22,17,14],
[83,22,17,16],
[83,22,18,0],
[83,22,18,1],
[83,22,18,2],
[83,22,18,3],
[83,22,18,5],
[83,22,18,6],
[83,22,18,7],
[83,22,18,8],
[83,22,18,11],
[83,22,18,12],
[83,22,18,13],
[83,22,18,16],
[83,22,18,17],
[83,22,19,0],
[83,22,19,1],
[83,22,19,2],
[83,22,19,3],
[83,22,19,5],
[83,22,19,6],
[83,22,19,7],
[83,22,19,8],
[83,22,19,9],
[83,22,19,11],
[83,22,19,15],
[83,22,19,16],
[83,22,19,17],
[83,22,19,18],
[83,22,21,0],
[83,22,21,1],
[83,22,21,2],
[83,22,21,7],
[83,22,21,11],
[83,22,21,12],
[83,22,21,13],
[83,22,21,14],
[83,22,21,15],
[83,22,21,16],
[83,22,21,17],
[83,22,21,18],
[83,22,21,19],
[83,23,3,0],
[83,23,3,1],
[83,23,3,2],
[83,23,5,0],
[83,23,5,1],
[83,23,5,2],
[83,23,6,0],
[83,23,6,1],
[83,23,6,2],
[83,23,7,0],
[83,23,7,1],
[83,23,7,2],
[83,23,7,3],
[83,23,7,5],
[83,23,7,6],
[83,23,8,0],
[83,23,8,1],
[83,23,8,2],
[83,23,8,7],
[83,23,9,0],
[83,23,9,1],
[83,23,9,2],
[83,23,9,3],
[83,23,9,5],
[83,23,9,6],
[83,23,9,7],
[83,23,9,8],
[83,23,10,0],
[83,23,10,1],
[83,23,10,2],
[83,23,10,3],
[83,23,10,5],
[83,23,10,6],
[83,23,10,7],
[83,23,10,8],
[83,23,10,9],
[83,23,11,0],
[83,23,11,1],
[83,23,11,2],
[83,23,11,3],
[83,23,11,5],
[83,23,11,6],
[83,23,11,7],
[83,23,11,8],
[83,23,11,9],
[83,23,11,10],
[83,23,12,0],
[83,23,12,1],
[83,23,12,2],
[83,23,12,3],
[83,23,12,5],
[83,23,12,6],
[83,23,12,7],
[83,23,12,8],
[83,23,12,9],
[83,23,12,10],
[83,23,13,0],
[83,23,13,1],
[83,23,13,2],
[83,23,13,3],
[83,23,13,5],
[83,23,13,6],
[83,23,13,7],
[83,23,13,8],
[83,23,13,9],
[83,23,13,10],
[83,23,13,11],
[83,23,13,12],
[83,23,14,0],
[83,23,14,1],
[83,23,14,2],
[83,23,14,3],
[83,23,14,5],
[83,23,14,6],
[83,23,14,7],
[83,23,14,8],
[83,23,14,9],
[83,23,14,10],
[83,23,14,11],
[83,23,14,12],
[83,23,15,0],
[83,23,15,1],
[83,23,15,2],
[83,23,15,3],
[83,23,15,5],
[83,23,15,6],
[83,23,15,7],
[83,23,15,8],
[83,23,15,10],
[83,23,15,11],
[83,23,15,12],
[83,23,15,13],
[83,23,15,14],
[83,23,16,0],
[83,23,16,1],
[83,23,16,2],
[83,23,16,3],
[83,23,16,5],
[83,23,16,6],
[83,23,16,7],
[83,23,16,8],
[83,23,16,9],
[83,23,16,10],
[83,23,16,11],
[83,23,16,12],
[83,23,16,13],
[83,23,16,15],
[83,23,17,0],
[83,23,17,1],
[83,23,17,2],
[83,23,17,3],
[83,23,17,5],
[83,23,17,6],
[83,23,17,7],
[83,23,17,8],
[83,23,17,9],
[83,23,17,10],
[83,23,17,11],
[83,23,17,12],
[83,23,17,13],
[83,23,17,15],
[83,23,17,16],
[83,23,18,0],
[83,23,18,1],
[83,23,18,2],
[83,23,18,3],
[83,23,18,5],
[83,23,18,6],
[83,23,18,7],
[83,23,18,8],
[83,23,18,10],
[83,23,18,11],
[83,23,18,14],
[83,23,18,16],
[83,23,18,17],
[83,23,19,0],
[83,23,19,1],
[83,23,19,2],
[83,23,19,3],
[83,23,19,5],
[83,23,19,6],
[83,23,19,7],
[83,23,19,8],
[83,23,19,9],
[83,23,19,12],
[83,23,19,15],
[83,23,19,16],
[83,23,19,17],
[83,23,19,18],
[83,23,20,0],
[83,23,20,1],
[83,23,20,2],
[83,23,20,3],
[83,23,20,5],
[83,23,20,6],
[83,23,20,7],
[83,23,20,11],
[83,23,20,12],
[83,23,20,13],
[83,23,20,14],
[83,23,20,15],
[83,23,20,16],
[83,23,20,17],
[83,23,20,18],
[83,23,20,19],
[83,23,21,0],
[83,23,21,1],
[83,23,21,2],
[83,23,21,9],
[83,23,21,10],
[83,23,21,11],
[83,23,21,12],
[83,23,21,13],
[83,23,21,14],
[83,23,21,15],
[83,23,21,16],
[83,23,21,17],
[83,23,21,18],
[83,23,21,19],
[83,23,21,20],
[83,23,22,0],
[83,23,22,1],
[83,23,22,2],
[83,23,22,3],
[83,23,22,6],
[83,23,22,7],
[83,23,22,8],
[83,23,22,9],
[83,23,22,11],
[83,23,22,12],
[83,23,22,13],
[83,23,22,14],
[83,23,22,15],
[83,23,22,16],
[83,23,22,17],
[83,23,22,18],
[83,23,22,19],
[83,23,22,21],
[83,24,3,0],
[83,24,3,1],
[83,24,3,2],
[83,24,5,0],
[83,24,5,1],
[83,24,5,2],
[83,24,6,0],
[83,24,6,1],
[83,24,6,2],
[83,24,7,0],
[83,24,7,1],
[83,24,7,2],
[83,24,7,3],
[83,24,7,5],
[83,24,7,6],
[83,24,8,0],
[83,24,8,1],
[83,24,8,2],
[83,24,8,7],
[83,24,9,0],
[83,24,9,1],
[83,24,9,2],
[83,24,9,3],
[83,24,9,5],
[83,24,9,6],
[83,24,9,7],
[83,24,9,8],
[83,24,10,0],
[83,24,10,1],
[83,24,10,2],
[83,24,10,3],
[83,24,10,5],
[83,24,10,6],
[83,24,10,7],
[83,24,10,8],
[83,24,10,9],
[83,24,11,0],
[83,24,11,1],
[83,24,11,2],
[83,24,11,3],
[83,24,11,5],
[83,24,11,6],
[83,24,11,7],
[83,24,11,8],
[83,24,11,9],
[83,24,11,10],
[83,24,12,0],
[83,24,12,1],
[83,24,12,2],
[83,24,12,3],
[83,24,12,5],
[83,24,12,6],
[83,24,12,7],
[83,24,12,8],
[83,24,12,9],
[83,24,12,10],
[83,24,13,0],
[83,24,13,1],
[83,24,13,2],
[83,24,13,3],
[83,24,13,5],
[83,24,13,6],
[83,24,13,7],
[83,24,13,8],
[83,24,13,9],
[83,24,13,10],
[83,24,13,11],
[83,24,13,12],
[83,24,14,0],
[83,24,14,1],
[83,24,14,2],
[83,24,14,3],
[83,24,14,5],
[83,24,14,6],
[83,24,14,7],
[83,24,14,8],
[83,24,14,9],
[83,24,14,10],
[83,24,14,11],
[83,24,14,12],
[83,24,15,0],
[83,24,15,1],
[83,24,15,2],
[83,24,15,3],
[83,24,15,5],
[83,24,15,6],
[83,24,15,7],
[83,24,15,8],
[83,24,15,10],
[83,24,15,11],
[83,24,15,12],
[83,24,15,13],
[83,24,15,14],
[83,24,17,0],
[83,24,17,1],
[83,24,17,2],
[83,24,17,3],
[83,24,17,5],
[83,24,17,6],
[83,24,17,7],
[83,24,17,8],
[83,24,17,9],
[83,24,17,10],
[83,24,17,11],
[83,24,17,12],
[83,24,17,13],
[83,24,17,15],
[83,24,18,0],
[83,24,18,1],
[83,24,18,2],
[83,24,18,3],
[83,24,18,5],
[83,24,18,6],
[83,24,18,7],
[83,24,18,8],
[83,24,18,10],
[83,24,18,11],
[83,24,18,14],
[83,24,18,17],
[83,24,19,0],
[83,24,19,1],
[83,24,19,2],
[83,24,19,3],
[83,24,19,5],
[83,24,19,6],
[83,24,19,7],
[83,24,19,8],
[83,24,19,9],
[83,24,19,12],
[83,24,19,15],
[83,24,19,17],
[83,24,19,18],
[83,24,20,0],
[83,24,20,1],
[83,24,20,2],
[83,24,20,3],
[83,24,20,5],
[83,24,20,6],
[83,24,20,7],
[83,24,20,11],
[83,24,20,12],
[83,24,20,13],
[83,24,20,14],
[83,24,20,15],
[83,24,20,17],
[83,24,20,18],
[83,24,20,19],
[83,24,21,0],
[83,24,21,1],
[83,24,21,2],
[83,24,21,9],
[83,24,21,10],
[83,24,21,11],
[83,24,21,12],
[83,24,21,13],
[83,24,21,14],
[83,24,21,15],
[83,24,21,17],
[83,24,21,18],
[83,24,21,19],
[83,24,21,20],
[83,24,22,0],
[83,24,22,1],
[83,24,22,2],
[83,24,22,3],
[83,24,22,6],
[83,24,22,7],
[83,24,22,8],
[83,24,22,9],
[83,24,22,11],
[83,24,22,12],
[83,24,22,13],
[83,24,22,14],
[83,24,22,15],
[83,24,22,17],
[83,24,22,18],
[83,24,22,19],
[83,24,22,21],
[83,24,23,0],
[83,24,23,1],
[83,24,23,2],
[83,24,23,5],
[83,24,23,6],
[83,24,23,7],
[83,24,23,8],
[83,24,23,9],
[83,24,23,10],
[83,24,23,11],
[83,24,23,12],
[83,24,23,13],
[83,24,23,14],
[83,24,23,15],
[83,24,23,17],
[83,24,23,18],
[83,24,23,19],
[83,24,23,20],
[83,24,23,21],
[83,25,3,0],
[83,25,3,1],
[83,25,3,2],
[83,25,5,0],
[83,25,5,1],
[83,25,5,2],
[83,25,6,0],
[83,25,6,1],
[83,25,6,2],
[83,25,8,0],
[83,25,8,1],
[83,25,8,2],
[83,25,9,0],
[83,25,9,1],
[83,25,9,2],
[83,25,9,3],
[83,25,9,5],
[83,25,9,6],
[83,25,9,8],
[83,25,10,0],
[83,25,10,1],
[83,25,10,2],
[83,25,10,3],
[83,25,10,5],
[83,25,10,6],
[83,25,10,8],
[83,25,10,9],
[83,25,11,0],
[83,25,11,1],
[83,25,11,2],
[83,25,11,3],
[83,25,11,5],
[83,25,11,6],
[83,25,11,8],
[83,25,11,9],
[83,25,11,10],
[83,25,12,0],
[83,25,12,1],
[83,25,12,2],
[83,25,12,3],
[83,25,12,5],
[83,25,12,6],
[83,25,12,8],
[83,25,12,9],
[83,25,12,10],
[83,25,13,0],
[83,25,13,1],
[83,25,13,2],
[83,25,13,3],
[83,25,13,5],
[83,25,13,6],
[83,25,13,8],
[83,25,13,9],
[83,25,13,10],
[83,25,13,11],
[83,25,13,12],
[83,25,14,0],
[83,25,14,1],
[83,25,14,2],
[83,25,14,3],
[83,25,14,5],
[83,25,14,6],
[83,25,14,8],
[83,25,14,9],
[83,25,14,10],
[83,25,14,11],
[83,25,14,12],
[83,25,15,0],
[83,25,15,1],
[83,25,15,2],
[83,25,15,3],
[83,25,15,5],
[83,25,15,6],
[83,25,15,8],
[83,25,15,10],
[83,25,15,11],
[83,25,15,14],
[83,25,16,0],
[83,25,16,1],
[83,25,16,2],
[83,25,16,3],
[83,25,16,5],
[83,25,16,6],
[83,25,16,8],
[83,25,16,9],
[83,25,16,12],
[83,25,16,13],
[83,25,16,14],
[83,25,16,15],
[83,25,17,0],
[83,25,17,1],
[83,25,17,2],
[83,25,17,3],
[83,25,17,5],
[83,25,17,6],
[83,25,17,8],
[83,25,17,9],
[83,25,17,12],
[83,25,17,13],
[83,25,17,14],
[83,25,17,15],
[83,25,17,16],
[83,25,18,0],
[83,25,18,1],
[83,25,18,2],
[83,25,18,3],
[83,25,18,5],
[83,25,18,6],
[83,25,18,10],
[83,25,18,11],
[83,25,18,12],
[83,25,18,13],
[83,25,18,14],
[83,25,18,16],
[83,25,18,17],
[83,25,19,0],
[83,25,19,1],
[83,25,19,2],
[83,25,19,3],
[83,25,19,5],
[83,25,19,8],
[83,25,19,9],
[83,25,19,10],
[83,25,19,11],
[83,25,19,12],
[83,25,19,15],
[83,25,19,16],
[83,25,19,17],
[83,25,19,18],
[83,25,20,0],
[83,25,20,1],
[83,25,20,2],
[83,25,20,3],
[83,25,20,6],
[83,25,20,8],
[83,25,20,9],
[83,25,20,11],
[83,25,20,12],
[83,25,20,13],
[83,25,20,14],
[83,25,20,15],
[83,25,20,16],
[83,25,20,17],
[83,25,20,18],
[83,25,20,19],
[83,25,21,0],
[83,25,21,1],
[83,25,21,2],
[83,25,21,9],
[83,25,21,10],
[83,25,21,11],
[83,25,21,12],
[83,25,21,13],
[83,25,21,14],
[83,25,21,15],
[83,25,21,16],
[83,25,21,17],
[83,25,21,18],
[83,25,21,19],
[83,25,21,20],
[83,25,22,3],
[83,25,22,5],
[83,25,22,6],
[83,25,22,8],
[83,25,22,9],
[83,25,22,11],
[83,25,22,12],
[83,25,22,13],
[83,25,22,14],
[83,25,22,15],
[83,25,22,16],
[83,25,22,17],
[83,25,22,18],
[83,25,22,19],
[83,25,23,0],
[83,25,23,1],
[83,25,23,2],
[83,25,23,3],
[83,25,23,5],
[83,25,23,6],
[83,25,23,8],
[83,25,23,9],
[83,25,23,10],
[83,25,23,11],
[83,25,23,12],
[83,25,23,13],
[83,25,23,14],
[83,25,23,15],
[83,25,23,16],
[83,25,23,17],
[83,25,23,18],
[83,25,23,19],
[83,25,24,0],
[83,25,24,1],
[83,25,24,2],
[83,25,24,3],
[83,25,24,5],
[83,25,24,6],
[83,25,24,8],
[83,25,24,9],
[83,25,24,10],
[83,25,24,11],
[83,25,24,12],
[83,25,24,13],
[83,25,24,14],
[83,25,24,15],
[83,25,24,17],
[83,25,24,18],
[83,25,24,19],
[83,26,3,0],
[83,26,3,1],
[83,26,3,2],
[83,26,5,0],
[83,26,5,1],
[83,26,5,2],
[83,26,6,0],
[83,26,6,1],
[83,26,6,2],
[83,26,7,0],
[83,26,7,1],
[83,26,7,2],
[83,26,7,3],
[83,26,7,5],
[83,26,7,6],
[83,26,8,0],
[83,26,8,1],
[83,26,8,2],
[83,26,8,7],
[83,26,10,0],
[83,26,10,1],
[83,26,10,2],
[83,26,10,3],
[83,26,10,5],
[83,26,10,6],
[83,26,10,7],
[83,26,10,8],
[83,26,11,0],
[83,26,11,1],
[83,26,11,2],
[83,26,11,3],
[83,26,11,5],
[83,26,11,6],
[83,26,11,7],
[83,26,11,8],
[83,26,11,10],
[83,26,12,0],
[83,26,12,1],
[83,26,12,2],
[83,26,12,3],
[83,26,12,5],
[83,26,12,6],
[83,26,12,7],
[83,26,12,8],
[83,26,12,10],
[83,26,13,0],
[83,26,13,1],
[83,26,13,2],
[83,26,13,3],
[83,26,13,5],
[83,26,13,6],
[83,26,13,7],
[83,26,13,8],
[83,26,13,10],
[83,26,13,11],
[83,26,13,12],
[83,26,14,0],
[83,26,14,1],
[83,26,14,2],
[83,26,14,3],
[83,26,14,5],
[83,26,14,6],
[83,26,14,7],
[83,26,14,8],
[83,26,14,10],
[83,26,14,11],
[83,26,16,0],
[83,26,16,1],
[83,26,16,2],
[83,26,16,3],
[83,26,16,5],
[83,26,16,6],
[83,26,16,7],
[83,26,16,10],
[83,26,16,11],
[83,26,16,12],
[83,26,16,13],
[83,26,16,14],
[83,26,17,0],
[83,26,17,1],
[83,26,17,2],
[83,26,17,3],
[83,26,17,5],
[83,26,17,6],
[83,26,17,7],
[83,26,17,10],
[83,26,17,11],
[83,26,17,12],
[83,26,17,13],
[83,26,17,14],
[83,26,17,16],
[83,26,19,0],
[83,26,19,1],
[83,26,19,2],
[83,26,19,3],
[83,26,19,6],
[83,26,19,7],
[83,26,19,8],
[83,26,19,10],
[83,26,19,11],
[83,26,19,12],
[83,26,19,16],
[83,26,19,17],
[83,26,20,0],
[83,26,20,1],
[83,26,20,2],
[83,26,20,5],
[83,26,20,6],
[83,26,20,7],
[83,26,20,8],
[83,26,20,11],
[83,26,20,12],
[83,26,20,13],
[83,26,20,14],
[83,26,20,16],
[83,26,20,17],
[83,26,20,19],
[83,26,21,7],
[83,26,21,10],
[83,26,21,11],
[83,26,21,12],
[83,26,21,13],
[83,26,21,14],
[83,26,21,16],
[83,26,21,17],
[83,26,21,19],
[83,26,21,20],
[83,26,22,0],
[83,26,22,1],
[83,26,22,2],
[83,26,22,3],
[83,26,22,5],
[83,26,22,6],
[83,26,22,7],
[83,26,22,8],
[83,26,22,11],
[83,26,22,12],
[83,26,22,13],
[83,26,22,14],
[83,26,22,16],
[83,26,22,17],
[83,26,22,19],
[83,26,23,0],
[83,26,23,1],
[83,26,23,2],
[83,26,23,3],
[83,26,23,5],
[83,26,23,6],
[83,26,23,7],
[83,26,23,8],
[83,26,23,10],
[83,26,23,11],
[83,26,23,12],
[83,26,23,13],
[83,26,23,14],
[83,26,23,16],
[83,26,23,17],
[83,26,24,0],
[83,26,24,1],
[83,26,24,2],
[83,26,24,3],
[83,26,24,5],
[83,26,24,6],
[83,26,24,7],
[83,26,24,8],
[83,26,24,10],
[83,26,24,11],
[83,26,24,12],
[83,26,24,13],
[83,26,24,14],
[83,26,24,17],
[83,26,25,0],
[83,26,25,1],
[83,26,25,2],
[83,26,25,3],
[83,26,25,5],
[83,26,25,6],
[83,26,25,8],
[83,26,25,10],
[83,26,25,11],
[83,26,25,12],
[83,26,25,13],
[83,26,25,14],
[83,27,3,0],
[83,27,3,1],
[83,27,3,2],
[83,27,5,0],
[83,27,5,1],
[83,27,5,2],
[83,27,6,0],
[83,27,6,1],
[83,27,6,2],
[83,27,7,0],
[83,27,7,1],
[83,27,7,2],
[83,27,7,3],
[83,27,7,5],
[83,27,7,6],
[83,27,8,0],
[83,27,8,1],
[83,27,8,2],
[83,27,8,7],
[83,27,9,0],
[83,27,9,1],
[83,27,9,2],
[83,27,9,3],
[83,27,9,5],
[83,27,9,6],
[83,27,9,7],
[83,27,9,8],
[83,27,11,0],
[83,27,11,1],
[83,27,11,2],
[83,27,11,3],
[83,27,11,5],
[83,27,11,6],
[83,27,11,7],
[83,27,11,8],
[83,27,11,9],
[83,27,12,0],
[83,27,12,1],
[83,27,12,2],
[83,27,12,3],
[83,27,12,5],
[83,27,12,6],
[83,27,12,7],
[83,27,12,8],
[83,27,12,9],
[83,27,13,0],
[83,27,13,1],
[83,27,13,2],
[83,27,13,3],
[83,27,13,5],
[83,27,13,6],
[83,27,13,7],
[83,27,13,8],
[83,27,13,9],
[83,27,13,11],
[83,27,14,0],
[83,27,14,1],
[83,27,14,2],
[83,27,14,3],
[83,27,14,5],
[83,27,14,6],
[83,27,14,7],
[83,27,14,8],
[83,27,14,9],
[83,27,14,12],
[83,27,15,0],
[83,27,15,1],
[83,27,15,2],
[83,27,15,3],
[83,27,15,5],
[83,27,15,6],
[83,27,15,7],
[83,27,15,11],
[83,27,15,12],
[83,27,15,13],
[83,27,15,14],
[83,27,16,0],
[83,27,16,1],
[83,27,16,2],
[83,27,16,3],
[83,27,16,5],
[83,27,16,8],
[83,27,16,9],
[83,27,16,11],
[83,27,16,12],
[83,27,16,13],
[83,27,16,14],
[83,27,16,15],
[83,27,17,0],
[83,27,17,1],
[83,27,17,2],
[83,27,17,3],
[83,27,17,5],
[83,27,17,8],
[83,27,17,9],
[83,27,17,11],
[83,27,17,12],
[83,27,17,13],
[83,27,17,14],
[83,27,17,15],
[83,27,17,16],
[83,27,18,0],
[83,27,18,1],
[83,27,18,2],
[83,27,18,3],
[83,27,18,6],
[83,27,18,7],
[83,27,18,8],
[83,27,18,11],
[83,27,18,12],
[83,27,18,13],
[83,27,18,14],
[83,27,18,16],
[83,27,18,17],
[83,27,19,0],
[83,27,19,1],
[83,27,19,2],
[83,27,19,5],
[83,27,19,6],
[83,27,19,7],
[83,27,19,8],
[83,27,19,9],
[83,27,19,11],
[83,27,19,12],
[83,27,19,15],
[83,27,19,16],
[83,27,19,17],
[83,27,19,18],
[83,27,21,0],
[83,27,21,1],
[83,27,21,2],
[83,27,21,7],
[83,27,21,9],
[83,27,21,11],
[83,27,21,12],
[83,27,21,13],
[83,27,21,14],
[83,27,21,15],
[83,27,21,16],
[83,27,21,17],
[83,27,21,18],
[83,27,21,19],
[83,27,23,0],
[83,27,23,1],
[83,27,23,2],
[83,27,23,3],
[83,27,23,5],
[83,27,23,6],
[83,27,23,7],
[83,27,23,8],
[83,27,23,9],
[83,27,23,11],
[83,27,23,12],
[83,27,23,13],
[83,27,23,14],
[83,27,23,15],
[83,27,23,16],
[83,27,23,17],
[83,27,24,0],
[83,27,24,1],
[83,27,24,2],
[83,27,24,3],
[83,27,24,5],
[83,27,24,6],
[83,27,24,7],
[83,27,24,8],
[83,27,24,9],
[83,27,24,11],
[83,27,24,12],
[83,27,24,13],
[83,27,24,14],
[83,27,24,15],
[83,27,24,17],
[83,27,25,0],
[83,27,25,1],
[83,27,25,2],
[83,27,25,3],
[83,27,25,5],
[83,27,25,6],
[83,27,25,8],
[83,27,25,9],
[83,27,25,11],
[83,27,25,12],
[83,27,25,13],
[83,27,25,14],
[83,27,26,0],
[83,27,26,1],
[83,27,26,2],
[83,27,26,3],
[83,27,26,5],
[83,27,26,6],
[83,27,26,7],
[83,27,26,8],
[83,27,26,11],
[83,27,26,12],
[83,27,26,13],
[83,28,3,0],
[83,28,3,1],
[83,28,3,2],
[83,28,5,0],
[83,28,5,1],
[83,28,5,2],
[83,28,6,0],
[83,28,6,1],
[83,28,6,2],
[83,28,8,0],
[83,28,8,1],
[83,28,8,2],
[83,28,9,0],
[83,28,9,1],
[83,28,9,2],
[83,28,9,3],
[83,28,9,5],
[83,28,9,6],
[83,28,9,8],
[83,28,10,0],
[83,28,10,1],
[83,28,10,2],
[83,28,10,3],
[83,28,10,5],
[83,28,10,6],
[83,28,10,8],
[83,28,10,9],
[83,28,11,0],
[83,28,11,1],
[83,28,11,2],
[83,28,11,3],
[83,28,11,5],
[83,28,11,6],
[83,28,11,8],
[83,28,11,9],
[83,28,11,10],
[83,28,12,0],
[83,28,12,1],
[83,28,12,2],
[83,28,12,3],
[83,28,12,5],
[83,28,12,6],
[83,28,12,8],
[83,28,12,9],
[83,28,13,0],
[83,28,13,1],
[83,28,13,2],
[83,28,13,3],
[83,28,13,5],
[83,28,13,6],
[83,28,13,8],
[83,28,13,9],
[83,28,13,12],
[83,28,14,0],
[83,28,14,1],
[83,28,14,2],
[83,28,14,3],
[83,28,14,5],
[83,28,14,6],
[83,28,14,10],
[83,28,14,11],
[83,28,14,12],
[83,28,15,0],
[83,28,15,1],
[83,28,15,2],
[83,28,15,3],
[83,28,15,5],
[83,28,15,8],
[83,28,15,10],
[83,28,15,11],
[83,28,15,12],
[83,28,15,13],
[83,28,15,14],
[83,28,16,0],
[83,28,16,1],
[83,28,16,2],
[83,28,16,3],
[83,28,16,6],
[83,28,16,8],
[83,28,16,9],
[83,28,16,10],
[83,28,16,11],
[83,28,16,12],
[83,28,16,13],
[83,28,16,14],
[83,28,16,15],
[83,28,17,0],
[83,28,17,1],
[83,28,17,2],
[83,28,17,3],
[83,28,17,6],
[83,28,17,8],
[83,28,17,9],
[83,28,17,10],
[83,28,17,11],
[83,28,17,12],
[83,28,17,13],
[83,28,17,14],
[83,28,17,15],
[83,28,17,16],
[83,28,18,0],
[83,28,18,1],
[83,28,18,2],
[83,28,18,5],
[83,28,18,6],
[83,28,18,8],
[83,28,18,10],
[83,28,18,11],
[83,28,18,12],
[83,28,18,13],
[83,28,18,14],
[83,28,18,16],
[83,28,18,17],
[83,28,19,3],
[83,28,19,5],
[83,28,19,6],
[83,28,19,8],
[83,28,19,9],
[83,28,19,10],
[83,28,19,11],
[83,28,19,12],
[83,28,19,15],
[83,28,19,16],
[83,28,19,17],
[83,28,19,18],
[83,28,20,0],
[83,28,20,1],
[83,28,20,2],
[83,28,20,3],
[83,28,20,5],
[83,28,20,6],
[83,28,20,8],
[83,28,20,9],
[83,28,20,11],
[83,28,20,12],
[83,28,20,13],
[83,28,20,14],
[83,28,20,15],
[83,28,20,16],
[83,28,20,17],
[83,28,20,18],
[83,28,20,19],
[83,28,21,0],
[83,28,21,1],
[83,28,21,2],
[83,28,21,9],
[83,28,21,10],
[83,28,21,11],
[83,28,21,12],
[83,28,21,13],
[83,28,21,14],
[83,28,21,15],
[83,28,21,16],
[83,28,21,17],
[83,28,21,18],
[83,28,22,0],
[83,28,22,1],
[83,28,22,2],
[83,28,22,3],
[83,28,22,5],
[83,28,22,6],
[83,28,22,8],
[83,28,22,9],
[83,28,22,11],
[83,28,22,12],
[83,28,22,13],
[83,28,22,14],
[83,28,22,15],
[83,28,22,16],
[83,28,22,17],
[83,28,23,0],
[83,28,23,1],
[83,28,23,2],
[83,28,23,3],
[83,28,23,5],
[83,28,23,6],
[83,28,23,8],
[83,28,23,9],
[83,28,23,10],
[83,28,23,11],
[83,28,23,12],
[83,28,23,13],
[83,28,23,14],
[83,28,23,15],
[83,28,24,0],
[83,28,24,1],
[83,28,24,2],
[83,28,24,3],
[83,28,24,5],
[83,28,24,6],
[83,28,24,8],
[83,28,24,9],
[83,28,24,10],
[83,28,24,11],
[83,28,24,12],
[83,28,24,13],
[83,28,24,14],
[83,28,24,15],
[83,28,26,0],
[83,28,26,1],
[83,28,26,2],
[83,28,26,3],
[83,28,26,5],
[83,28,26,6],
[83,28,26,8],
[83,28,26,10],
[83,28,26,11],
[83,28,27,0],
[83,28,27,1],
[83,28,27,2],
[83,28,27,3],
[83,28,27,5],
[83,28,27,6],
[83,28,27,8],
[83,28,27,9],
[83,29,3,0],
[83,29,3,1],
[83,29,3,2],
[83,29,5,0],
[83,29,5,1],
[83,29,5,2],
[83,29,6,0],
[83,29,6,1],
[83,29,6,2],
[83,29,7,0],
[83,29,7,1],
[83,29,7,2],
[83,29,7,3],
[83,29,7,5],
[83,29,7,6],
[83,29,8,0],
[83,29,8,1],
[83,29,8,2],
[83,29,8,7],
[83,29,9,0],
[83,29,9,1],
[83,29,9,2],
[83,29,9,3],
[83,29,9,5],
[83,29,9,6],
[83,29,9,7],
[83,29,9,8],
[83,29,10,0],
[83,29,10,1],
[83,29,10,2],
[83,29,10,3],
[83,29,10,5],
[83,29,10,6],
[83,29,10,7],
[83,29,10,8],
[83,29,10,9],
[83,29,13,0],
[83,29,13,1],
[83,29,13,2],
[83,29,13,3],
[83,29,13,5],
[83,29,13,6],
[83,29,13,7],
[83,29,13,10],
[83,29,14,0],
[83,29,14,1],
[83,29,14,2],
[83,29,14,3],
[83,29,14,5],
[83,29,14,8],
[83,29,14,9],
[83,29,14,10],
[83,29,15,0],
[83,29,15,1],
[83,29,15,2],
[83,29,15,3],
[83,29,15,6],
[83,29,15,7],
[83,29,15,8],
[83,29,15,10],
[83,29,15,13],
[83,29,15,14],
[83,29,16,0],
[83,29,16,1],
[83,29,16,2],
[83,29,16,5],
[83,29,16,6],
[83,29,16,7],
[83,29,16,8],
[83,29,16,9],
[83,29,16,10],
[83,29,16,13],
[83,29,16,14],
[83,29,16,15],
[83,29,17,0],
[83,29,17,1],
[83,29,17,2],
[83,29,17,5],
[83,29,17,6],
[83,29,17,7],
[83,29,17,8],
[83,29,17,9],
[83,29,17,10],
[83,29,17,13],
[83,29,17,14],
[83,29,17,15],
[83,29,17,16],
[83,29,18,3],
[83,29,18,5],
[83,29,18,6],
[83,29,18,7],
[83,29,18,8],
[83,29,18,10],
[83,29,18,13],
[83,29,18,14],
[83,29,18,16],
[83,29,18,17],
[83,29,19,0],
[83,29,19,1],
[83,29,19,2],
[83,29,19,3],
[83,29,19,5],
[83,29,19,6],
[83,29,19,7],
[83,29,19,8],
[83,29,19,9],
[83,29,19,10],
[83,29,19,15],
[83,29,19,16],
[83,29,19,17],
[83,29,19,18],
[83,29,20,0],
[83,29,20,1],
[83,29,20,2],
[83,29,20,3],
[83,29,20,5],
[83,29,20,6],
[83,29,20,7],
[83,29,20,8],
[83,29,20,9],
[83,29,20,13],
[83,29,20,14],
[83,29,20,15],
[83,29,20,16],
[83,29,20,17],
[83,29,20,18],
[83,29,21,0],
[83,29,21,1],
[83,29,21,2],
[83,29,21,7],
[83,29,21,9],
[83,29,21,10],
[83,29,21,13],
[83,29,21,14],
[83,29,21,15],
[83,29,21,16],
[83,29,21,17],
[83,29,22,0],
[83,29,22,1],
[83,29,22,2],
[83,29,22,3],
[83,29,22,5],
[83,29,22,6],
[83,29,22,7],
[83,29,22,8],
[83,29,22,9],
[83,29,22,13],
[83,29,22,14],
[83,29,22,15],
[83,29,23,0],
[83,29,23,1],
[83,29,23,2],
[83,29,23,3],
[83,29,23,5],
[83,29,23,6],
[83,29,23,7],
[83,29,23,8],
[83,29,23,9],
[83,29,23,10],
[83,29,23,13],
[83,29,23,14],
[83,29,24,0],
[83,29,24,1],
[83,29,24,2],
[83,29,24,3],
[83,29,24,5],
[83,29,24,6],
[83,29,24,7],
[83,29,24,8],
[83,29,24,9],
[83,29,24,10],
[83,29,24,13],
[83,29,24,14],
[83,29,25,0],
[83,29,25,1],
[83,29,25,2],
[83,29,25,3],
[83,29,25,5],
[83,29,25,6],
[83,29,25,8],
[83,29,25,9],
[83,29,25,10],
[83,29,26,0],
[83,29,26,1],
[83,29,26,2],
[83,29,26,3],
[83,29,26,5],
[83,29,26,6],
[83,29,26,7],
[83,29,26,8],
[83,29,27,0],
[83,29,27,1],
[83,29,27,2],
[83,29,27,3],
[83,29,27,5],
[83,29,27,6],
[83,29,27,7],
[83,29,28,0],
[83,29,28,1],
[83,29,28,2],
[83,29,28,3],
[83,29,28,5],
[83,30,3,0],
[83,30,3,1],
[83,30,3,2],
[83,30,5,0],
[83,30,5,1],
[83,30,5,2],
[83,30,6,0],
[83,30,6,1],
[83,30,6,2],
[83,30,7,0],
[83,30,7,1],
[83,30,7,2],
[83,30,7,3],
[83,30,7,5],
[83,30,7,6],
[83,30,8,0],
[83,30,8,1],
[83,30,8,2],
[83,30,8,7],
[83,30,9,0],
[83,30,9,1],
[83,30,9,2],
[83,30,9,3],
[83,30,9,5],
[83,30,9,6],
[83,30,9,7],
[83,30,9,8],
[83,30,10,0],
[83,30,10,1],
[83,30,10,2],
[83,30,10,3],
[83,30,10,5],
[83,30,10,6],
[83,30,10,7],
[83,30,11,0],
[83,30,11,1],
[83,30,11,2],
[83,30,11,3],
[83,30,11,5],
[83,30,11,6],
[83,30,11,7],
[83,30,11,10],
[83,30,12,0],
[83,30,12,1],
[83,30,12,2],
[83,30,12,3],
[83,30,12,5],
[83,30,12,8],
[83,30,12,9],
[83,30,12,10],
[83,30,13,0],
[83,30,13,1],
[83,30,13,2],
[83,30,13,3],
[83,30,13,5],
[83,30,13,8],
[83,30,13,9],
[83,30,13,10],
[83,30,13,11],
[83,30,13,12],
[83,30,14,0],
[83,30,14,1],
[83,30,14,2],
[83,30,14,3],
[83,30,14,6],
[83,30,14,7],
[83,30,14,8],
[83,30,14,9],
[83,30,14,10],
[83,30,14,11],
[83,30,14,12],
[83,30,15,0],
[83,30,15,1],
[83,30,15,2],
[83,30,15,5],
[83,30,15,6],
[83,30,15,7],
[83,30,15,8],
[83,30,15,10],
[83,30,15,11],
[83,30,15,12],
[83,30,15,13],
[83,30,15,14],
[83,30,16,3],
[83,30,16,5],
[83,30,16,6],
[83,30,16,7],
[83,30,16,8],
[83,30,16,9],
[83,30,16,10],
[83,30,16,11],
[83,30,16,12],
[83,30,16,13],
[83,30,16,14],
[83,30,16,15],
[83,30,17,3],
[83,30,17,5],
[83,30,17,6],
[83,30,17,7],
[83,30,17,8],
[83,30,17,9],
[83,30,17,10],
[83,30,17,11],
[83,30,17,12],
[83,30,17,13],
[83,30,17,14],
[83,30,17,15],
[83,30,17,16],
[83,30,18,0],
[83,30,18,1],
[83,30,18,2],
[83,30,18,3],
[83,30,18,5],
[83,30,18,6],
[83,30,18,7],
[83,30,18,8],
[83,30,18,10],
[83,30,18,11],
[83,30,18,12],
[83,30,18,13],
[83,30,18,14],
[83,30,18,16],
[83,30,18,17],
[83,30,19,0],
[83,30,19,1],
[83,30,19,2],
[83,30,19,3],
[83,30,19,5],
[83,30,19,6],
[83,30,19,7],
[83,30,19,8],
[83,30,19,9],
[83,30,19,10],
[83,30,19,11],
[83,30,19,12],
[83,30,19,15],
[83,30,19,16],
[83,30,19,17],
[83,30,19,18],
[83,30,20,0],
[83,30,20,1],
[83,30,20,2],
[83,30,20,3],
[83,30,20,5],
[83,30,20,6],
[83,30,20,7],
[83,30,20,8],
[83,30,20,9],
[83,30,20,11],
[83,30,20,12],
[83,30,20,13],
[83,30,20,14],
[83,30,20,15],
[83,30,20,16],
[83,30,20,17],
[83,30,21,0],
[83,30,21,1],
[83,30,21,2],
[83,30,21,7],
[83,30,21,9],
[83,30,21,10],
[83,30,21,11],
[83,30,21,12],
[83,30,21,13],
[83,30,21,14],
[83,30,21,15],
[83,30,22,0],
[83,30,22,1],
[83,30,22,2],
[83,30,22,3],
[83,30,22,5],
[83,30,22,6],
[83,30,22,7],
[83,30,22,8],
[83,30,22,9],
[83,30,22,11],
[83,30,22,12],
[83,30,22,13],
[83,30,22,14],
[83,30,24,0],
[83,30,24,1],
[83,30,24,2],
[83,30,24,3],
[83,30,24,5],
[83,30,24,6],
[83,30,24,7],
[83,30,24,8],
[83,30,24,9],
[83,30,24,10],
[83,30,24,11],
[83,30,24,12],
[83,30,24,13],
[83,30,25,0],
[83,30,25,1],
[83,30,25,2],
[83,30,25,3],
[83,30,25,5],
[83,30,25,6],
[83,30,25,8],
[83,30,25,9],
[83,30,26,0],
[83,30,26,1],
[83,30,26,2],
[83,30,26,3],
[83,30,26,5],
[83,30,26,6],
[83,30,26,7],
[83,30,27,0],
[83,30,27,1],
[83,30,27,2],
[83,30,27,3],
[83,30,27,5],
[83,30,28,0],
[83,30,28,1],
[83,30,28,2],
[83,30,28,3],
[83,30,29,0],
[83,30,29,1],
[83,30,29,2],
[83,31,3,0],
[83,31,3,1],
[83,31,3,2],
[83,31,5,0],
[83,31,5,1],
[83,31,5,2],
[83,31,6,0],
[83,31,6,1],
[83,31,6,2],
[83,31,7,0],
[83,31,7,1],
[83,31,7,2],
[83,31,7,3],
[83,31,7,5],
[83,31,7,6],
[83,31,8,0],
[83,31,8,1],
[83,31,8,2],
[83,31,8,7],
[83,31,9,0],
[83,31,9,1],
[83,31,9,2],
[83,31,9,3],
[83,31,9,5],
[83,31,9,6],
[83,31,9,7],
[83,31,10,0],
[83,31,10,1],
[83,31,10,2],
[83,31,10,3],
[83,31,10,5],
[83,31,10,8],
[83,31,10,9],
[83,31,11,0],
[83,31,11,1],
[83,31,11,2],
[83,31,11,3],
[83,31,11,5],
[83,31,11,8],
[83,31,11,9],
[83,31,11,10],
[83,31,12,0],
[83,31,12,1],
[83,31,12,2],
[83,31,12,3],
[83,31,12,6],
[83,31,12,7],
[83,31,12,8],
[83,31,12,9],
[83,31,12,10],
[83,31,13,0],
[83,31,13,1],
[83,31,13,2],
[83,31,13,3],
[83,31,13,6],
[83,31,13,7],
[83,31,13,8],
[83,31,13,9],
[83,31,13,10],
[83,31,13,11],
[83,31,13,12],
[83,31,14,0],
[83,31,14,1],
[83,31,14,2],
[83,31,14,5],
[83,31,14,6],
[83,31,14,7],
[83,31,14,8],
[83,31,14,9],
[83,31,14,10],
[83,31,14,11],
[83,31,14,12],
[83,31,15,3],
[83,31,15,5],
[83,31,15,6],
[83,31,15,7],
[83,31,15,8],
[83,31,15,10],
[83,31,15,11],
[83,31,15,12],
[83,31,15,13],
[83,31,15,14],
[83,31,16,0],
[83,31,16,1],
[83,31,16,2],
[83,31,16,3],
[83,31,16,5],
[83,31,16,6],
[83,31,16,7],
[83,31,16,8],
[83,31,16,9],
[83,31,16,10],
[83,31,16,11],
[83,31,16,12],
[83,31,16,13],
[83,31,16,14],
[83,31,16,15],
[83,31,17,0],
[83,31,17,1],
[83,31,17,2],
[83,31,17,3],
[83,31,17,5],
[83,31,17,6],
[83,31,17,7],
[83,31,17,8],
[83,31,17,9],
[83,31,17,10],
[83,31,17,11],
[83,31,17,12],
[83,31,17,13],
[83,31,17,14],
[83,31,17,15],
[83,31,17,16],
[83,31,18,0],
[83,31,18,1],
[83,31,18,2],
[83,31,18,3],
[83,31,18,5],
[83,31,18,6],
[83,31,18,7],
[83,31,18,8],
[83,31,18,10],
[83,31,18,11],
[83,31,18,12],
[83,31,18,13],
[83,31,18,14],
[83,31,18,16],
[83,31,18,17],
[83,31,19,0],
[83,31,19,1],
[83,31,19,2],
[83,31,19,3],
[83,31,19,5],
[83,31,19,6],
[83,31,19,7],
[83,31,19,8],
[83,31,19,9],
[83,31,19,10],
[83,31,19,11],
[83,31,19,12],
[83,31,19,15],
[83,31,19,16],
[83,31,19,17],
[83,31,20,0],
[83,31,20,1],
[83,31,20,2],
[83,31,20,3],
[83,31,20,5],
[83,31,20,6],
[83,31,20,7],
[83,31,20,8],
[83,31,20,9],
[83,31,20,11],
[83,31,20,12],
[83,31,20,13],
[83,31,20,14],
[83,31,20,15],
[83,31,21,0],
[83,31,21,1],
[83,31,21,2],
[83,31,21,7],
[83,31,21,9],
[83,31,21,10],
[83,31,21,11],
[83,31,21,12],
[83,31,21,13],
[83,31,21,14],
[83,31,22,0],
[83,31,22,1],
[83,31,22,2],
[83,31,22,3],
[83,31,22,5],
[83,31,22,6],
[83,31,22,7],
[83,31,22,8],
[83,31,22,9],
[83,31,22,11],
[83,31,22,12],
[83,31,22,13],
[83,31,24,0],
[83,31,24,1],
[83,31,24,2],
[83,31,24,3],
[83,31,24,5],
[83,31,24,6],
[83,31,24,7],
[83,31,24,8],
[83,31,24,9],
[83,31,24,10],
[83,31,24,11],
[83,31,25,0],
[83,31,25,1],
[83,31,25,2],
[83,31,25,3],
[83,31,25,5],
[83,31,25,6],
[83,31,26,0],
[83,31,26,1],
[83,31,26,2],
[83,31,26,3],
[83,31,26,5],
[83,31,27,0],
[83,31,27,1],
[83,31,27,2],
[83,31,27,3],
[83,31,28,0],
[83,31,28,1],
[83,31,28,2],
[83,32,3,0],
[83,32,3,1],
[83,32,3,2],
[83,32,5,0],
[83,32,5,1],
[83,32,5,2],
[83,32,6,0],
[83,32,6,1],
[83,32,6,2],
[83,32,7,0],
[83,32,7,1],
[83,32,7,2],
[83,32,7,3],
[83,32,7,5],
[83,32,7,6],
[83,32,8,0],
[83,32,8,1],
[83,32,8,2],
[83,32,9,0],
[83,32,9,1],
[83,32,9,2],
[83,32,9,3],
[83,32,9,5],
[83,32,9,8],
[83,32,10,0],
[83,32,10,1],
[83,32,10,2],
[83,32,10,3],
[83,32,10,6],
[83,32,10,7],
[83,32,10,8],
[83,32,10,9],
[83,32,13,0],
[83,32,13,1],
[83,32,13,2],
[83,32,13,5],
[83,32,13,6],
[83,32,13,7],
[83,32,13,8],
[83,32,13,9],
[83,32,13,10],
[83,32,14,3],
[83,32,14,5],
[83,32,14,6],
[83,32,14,7],
[83,32,14,8],
[83,32,14,9],
[83,32,14,10],
[83,32,15,0],
[83,32,15,1],
[83,32,15,2],
[83,32,15,3],
[83,32,15,5],
[83,32,15,6],
[83,32,15,7],
[83,32,15,8],
[83,32,15,10],
[83,32,15,13],
[83,32,15,14],
[83,32,16,0],
[83,32,16,1],
[83,32,16,2],
[83,32,16,3],
[83,32,16,5],
[83,32,16,6],
[83,32,16,7],
[83,32,16,8],
[83,32,16,9],
[83,32,16,10],
[83,32,16,13],
[83,32,16,14],
[83,32,16,15],
[83,32,17,0],
[83,32,17,1],
[83,32,17,2],
[83,32,17,3],
[83,32,17,5],
[83,32,17,6],
[83,32,17,7],
[83,32,17,8],
[83,32,17,9],
[83,32,17,10],
[83,32,17,13],
[83,32,17,14],
[83,32,17,15],
[83,32,17,16],
[83,32,18,0],
[83,32,18,1],
[83,32,18,2],
[83,32,18,3],
[83,32,18,5],
[83,32,18,6],
[83,32,18,7],
[83,32,18,8],
[83,32,18,10],
[83,32,18,13],
[83,32,18,14],
[83,32,18,16],
[83,32,18,17],
[83,32,19,0],
[83,32,19,1],
[83,32,19,2],
[83,32,19,3],
[83,32,19,5],
[83,32,19,6],
[83,32,19,7],
[83,32,19,8],
[83,32,19,9],
[83,32,19,10],
[83,32,19,15],
[83,32,20,0],
[83,32,20,1],
[83,32,20,2],
[83,32,20,3],
[83,32,20,5],
[83,32,20,6],
[83,32,20,7],
[83,32,20,8],
[83,32,20,9],
[83,32,20,13],
[83,32,20,14],
[83,32,21,0],
[83,32,21,1],
[83,32,21,2],
[83,32,21,7],
[83,32,21,9],
[83,32,21,10],
[83,32,21,13],
[83,32,22,0],
[83,32,22,1],
[83,32,22,2],
[83,32,22,3],
[83,32,22,5],
[83,32,22,6],
[83,32,22,7],
[83,32,22,8],
[83,32,22,9],
[83,32,23,0],
[83,32,23,1],
[83,32,23,2],
[83,32,23,3],
[83,32,23,5],
[83,32,23,6],
[83,32,23,7],
[83,32,23,8],
[83,32,23,9],
[83,32,24,0],
[83,32,24,1],
[83,32,24,2],
[83,32,24,3],
[83,32,24,5],
[83,32,24,6],
[83,32,24,7],
[83,32,24,8],
[83,32,24,9],
[83,32,25,0],
[83,32,25,1],
[83,32,25,2],
[83,32,25,3],
[83,32,25,5],
[83,32,26,0],
[83,32,26,1],
[83,32,26,2],
[83,32,26,3],
[83,32,27,0],
[83,32,27,1],
[83,32,27,2],
[83,33,3,0],
[83,33,3,1],
[83,33,3,2],
[83,33,5,0],
[83,33,5,1],
[83,33,5,2],
[83,33,6,0],
[83,33,6,1],
[83,33,6,2],
[83,33,7,0],
[83,33,7,1],
[83,33,7,2],
[83,33,7,3],
[83,33,7,5],
[83,33,8,0],
[83,33,8,1],
[83,33,8,2],
[83,33,8,7],
[83,33,9,0],
[83,33,9,1],
[83,33,9,2],
[83,33,9,3],
[83,33,9,6],
[83,33,9,7],
[83,33,9,8],
[83,33,10,0],
[83,33,10,1],
[83,33,10,2],
[83,33,10,5],
[83,33,10,6],
[83,33,10,7],
[83,33,10,8],
[83,33,10,9],
[83,33,13,3],
[83,33,13,5],
[83,33,13,6],
[83,33,13,7],
[83,33,13,8],
[83,33,13,9],
[83,33,13,10],
[83,33,14,0],
[83,33,14,1],
[83,33,14,2],
[83,33,14,3],
[83,33,14,5],
[83,33,14,6],
[83,33,14,7],
[83,33,14,8],
[83,33,14,9],
[83,33,14,10],
[83,33,15,0],
[83,33,15,1],
[83,33,15,2],
[83,33,15,3],
[83,33,15,5],
[83,33,15,6],
[83,33,15,7],
[83,33,15,8],
[83,33,15,10],
[83,33,15,13],
[83,33,15,14],
[83,33,16,0],
[83,33,16,1],
[83,33,16,2],
[83,33,16,3],
[83,33,16,5],
[83,33,16,6],
[83,33,16,7],
[83,33,16,8],
[83,33,16,9],
[83,33,16,10],
[83,33,16,13],
[83,33,16,14],
[83,33,16,15],
[83,33,17,0],
[83,33,17,1],
[83,33,17,2],
[83,33,17,3],
[83,33,17,5],
[83,33,17,6],
[83,33,17,7],
[83,33,17,8],
[83,33,17,9],
[83,33,17,10],
[83,33,17,13],
[83,33,17,14],
[83,33,17,15],
[83,33,17,16],
[83,33,18,0],
[83,33,18,1],
[83,33,18,2],
[83,33,18,3],
[83,33,18,5],
[83,33,18,6],
[83,33,18,7],
[83,33,18,8],
[83,33,18,10],
[83,33,18,13],
[83,33,18,14],
[83,33,19,0],
[83,33,19,1],
[83,33,19,2],
[83,33,19,3],
[83,33,19,5],
[83,33,19,6],
[83,33,19,7],
[83,33,19,8],
[83,33,19,9],
[83,33,19,10],
[83,33,20,0],
[83,33,20,1],
[83,33,20,2],
[83,33,20,3],
[83,33,20,5],
[83,33,20,6],
[83,33,20,7],
[83,33,20,8],
[83,33,20,9],
[83,33,20,13],
[83,33,21,0],
[83,33,21,1],
[83,33,21,2],
[83,33,21,7],
[83,33,21,9],
[83,33,21,10],
[83,33,22,0],
[83,33,22,1],
[83,33,22,2],
[83,33,22,3],
[83,33,22,5],
[83,33,22,6],
[83,33,22,7],
[83,33,22,8],
[83,33,22,9],
[83,33,23,0],
[83,33,23,1],
[83,33,23,2],
[83,33,23,3],
[83,33,23,5],
[83,33,23,6],
[83,33,23,7],
[83,33,24,0],
[83,33,24,1],
[83,33,24,2],
[83,33,24,3],
[83,33,24,5],
[83,33,24,6],
[83,33,24,7],
[83,33,25,0],
[83,33,25,1],
[83,33,25,2],
[83,33,25,3],
[83,33,26,0],
[83,33,26,1],
[83,33,26,2],
[83,34,3,0],
[83,34,3,1],
[83,34,3,2],
[83,34,5,0],
[83,34,5,1],
[83,34,5,2],
[83,34,6,0],
[83,34,6,1],
[83,34,6,2],
[83,34,8,0],
[83,34,8,1],
[83,34,8,2],
[83,34,9,0],
[83,34,9,1],
[83,34,9,2],
[83,34,9,5],
[83,34,9,6],
[83,34,9,8],
[83,34,10,3],
[83,34,10,5],
[83,34,10,6],
[83,34,10,8],
[83,34,10,9],
[83,34,11,3],
[83,34,11,5],
[83,34,11,6],
[83,34,11,8],
[83,34,11,9],
[83,34,11,10],
[83,34,12,0],
[83,34,12,1],
[83,34,12,2],
[83,34,12,3],
[83,34,12,5],
[83,34,12,6],
[83,34,12,8],
[83,34,12,9],
[83,34,12,10],
[83,34,13,0],
[83,34,13,1],
[83,34,13,2],
[83,34,13,3],
[83,34,13,5],
[83,34,13,6],
[83,34,13,8],
[83,34,13,9],
[83,34,13,10],
[83,34,13,11],
[83,34,13,12],
[83,34,14,0],
[83,34,14,1],
[83,34,14,2],
[83,34,14,3],
[83,34,14,5],
[83,34,14,6],
[83,34,14,8],
[83,34,14,9],
[83,34,14,10],
[83,34,14,11],
[83,34,14,12],
[83,34,15,0],
[83,34,15,1],
[83,34,15,2],
[83,34,15,3],
[83,34,15,5],
[83,34,15,6],
[83,34,15,8],
[83,34,15,10],
[83,34,15,11],
[83,34,15,12],
[83,34,15,13],
[83,34,15,14],
[83,34,16,0],
[83,34,16,1],
[83,34,16,2],
[83,34,16,3],
[83,34,16,5],
[83,34,16,6],
[83,34,16,8],
[83,34,16,9],
[83,34,16,10],
[83,34,16,11],
[83,34,16,12],
[83,34,16,13],
[83,34,16,14],
[83,34,16,15],
[83,34,17,0],
[83,34,17,1],
[83,34,17,2],
[83,34,17,3],
[83,34,17,5],
[83,34,17,6],
[83,34,17,8],
[83,34,17,9],
[83,34,17,10],
[83,34,17,11],
[83,34,17,12],
[83,34,17,13],
[83,34,17,14],
[83,34,17,15],
[83,34,18,0],
[83,34,18,1],
[83,34,18,2],
[83,34,18,3],
[83,34,18,5],
[83,34,18,6],
[83,34,18,8],
[83,34,18,10],
[83,34,18,11],
[83,34,18,12],
[83,34,18,13],
[83,34,18,14],
[83,34,19,0],
[83,34,19,1],
[83,34,19,2],
[83,34,19,3],
[83,34,19,5],
[83,34,19,6],
[83,34,19,8],
[83,34,19,9],
[83,34,19,10],
[83,34,19,11],
[83,34,19,12],
[83,34,20,0],
[83,34,20,1],
[83,34,20,2],
[83,34,20,3],
[83,34,20,5],
[83,34,20,6],
[83,34,20,8],
[83,34,20,9],
[83,34,20,11],
[83,34,21,0],
[83,34,21,1],
[83,34,21,2],
[83,34,21,9],
[83,34,22,0],
[83,34,22,1],
[83,34,22,2],
[83,34,22,3],
[83,34,22,5],
[83,34,22,6],
[83,34,23,0],
[83,34,23,1],
[83,34,23,2],
[83,34,23,3],
[83,34,23,5],
[83,34,24,0],
[83,34,24,1],
[83,34,24,2],
[83,34,24,3],
[83,34,24,5],
[83,35,3,0],
[83,35,3,1],
[83,35,3,2],
[83,35,5,0],
[83,35,5,1],
[83,35,5,2],
[83,35,6,0],
[83,35,6,1],
[83,35,6,2],
[83,35,7,0],
[83,35,7,1],
[83,35,7,2],
[83,35,7,5],
[83,35,7,6],
[83,35,8,7],
[83,35,10,0],
[83,35,10,1],
[83,35,10,2],
[83,35,10,3],
[83,35,10,5],
[83,35,10,6],
[83,35,10,7],
[83,35,10,8],
[83,35,11,0],
[83,35,11,1],
[83,35,11,2],
[83,35,11,3],
[83,35,11,5],
[83,35,11,6],
[83,35,11,7],
[83,35,11,8],
[83,35,11,10],
[83,35,12,0],
[83,35,12,1],
[83,35,12,2],
[83,35,12,3],
[83,35,12,5],
[83,35,12,6],
[83,35,12,7],
[83,35,12,8],
[83,35,12,10],
[83,35,13,0],
[83,35,13,1],
[83,35,13,2],
[83,35,13,3],
[83,35,13,5],
[83,35,13,6],
[83,35,13,7],
[83,35,13,8],
[83,35,13,10],
[83,35,13,11],
[83,35,13,12],
[83,35,14,0],
[83,35,14,1],
[83,35,14,2],
[83,35,14,3],
[83,35,14,5],
[83,35,14,6],
[83,35,14,7],
[83,35,14,8],
[83,35,14,10],
[83,35,14,11],
[83,35,14,12],
[83,35,16,0],
[83,35,16,1],
[83,35,16,2],
[83,35,16,3],
[83,35,16,5],
[83,35,16,6],
[83,35,16,7],
[83,35,16,8],
[83,35,16,10],
[83,35,16,11],
[83,35,16,12],
[83,35,16,13],
[83,35,16,14],
[83,35,17,0],
[83,35,17,1],
[83,35,17,2],
[83,35,17,3],
[83,35,17,5],
[83,35,17,6],
[83,35,17,7],
[83,35,17,8],
[83,35,17,10],
[83,35,17,11],
[83,35,17,12],
[83,35,17,13],
[83,35,17,14],
[83,35,19,0],
[83,35,19,1],
[83,35,19,2],
[83,35,19,3],
[83,35,19,5],
[83,35,19,6],
[83,35,19,7],
[83,35,19,8],
[83,35,19,10],
[83,35,19,11],
[83,35,20,0],
[83,35,20,1],
[83,35,20,2],
[83,35,20,3],
[83,35,20,5],
[83,35,20,6],
[83,35,20,7],
[83,35,20,8],
[83,35,21,0],
[83,35,21,1],
[83,35,21,2],
[83,35,21,7],
[83,35,22,0],
[83,35,22,1],
[83,35,22,2],
[83,35,22,3],
[83,35,22,5],
[83,35,23,0],
[83,35,23,1],
[83,35,23,2],
[83,35,23,3],
[83,35,24,0],
[83,35,24,1],
[83,35,24,2],
[83,35,24,3],
[83,36,3,0],
[83,36,3,1],
[83,36,3,2],
[83,36,5,0],
[83,36,5,1],
[83,36,5,2],
[83,36,7,3],
[83,36,7,5],
[83,36,7,6],
[83,36,8,0],
[83,36,8,1],
[83,36,8,2],
[83,36,8,7],
[83,36,9,0],
[83,36,9,1],
[83,36,9,2],
[83,36,9,3],
[83,36,9,5],
[83,36,9,6],
[83,36,9,7],
[83,36,9,8],
[83,36,10,0],
[83,36,10,1],
[83,36,10,2],
[83,36,10,3],
[83,36,10,5],
[83,36,10,6],
[83,36,10,7],
[83,36,10,8],
[83,36,10,9],
[83,36,11,0],
[83,36,11,1],
[83,36,11,2],
[83,36,11,3],
[83,36,11,5],
[83,36,11,6],
[83,36,11,7],
[83,36,11,8],
[83,36,11,9],
[83,36,11,10],
[83,36,12,0],
[83,36,12,1],
[83,36,12,2],
[83,36,12,3],
[83,36,12,5],
[83,36,12,6],
[83,36,12,7],
[83,36,12,8],
[83,36,12,9],
[83,36,12,10],
[83,36,15,0],
[83,36,15,1],
[83,36,15,2],
[83,36,15,3],
[83,36,15,5],
[83,36,15,6],
[83,36,15,7],
[83,36,15,8],
[83,36,15,10],
[83,36,15,11],
[83,36,15,12],
[83,36,16,0],
[83,36,16,1],
[83,36,16,2],
[83,36,16,3],
[83,36,16,5],
[83,36,16,6],
[83,36,16,7],
[83,36,16,8],
[83,36,16,9],
[83,36,16,10],
[83,36,16,11],
[83,36,16,12],
[83,36,17,0],
[83,36,17,1],
[83,36,17,2],
[83,36,17,3],
[83,36,17,5],
[83,36,17,6],
[83,36,17,7],
[83,36,17,8],
[83,36,17,9],
[83,36,17,10],
[83,36,17,11],
[83,36,17,12],
[83,36,18,0],
[83,36,18,1],
[83,36,18,2],
[83,36,18,3],
[83,36,18,5],
[83,36,18,6],
[83,36,18,7],
[83,36,18,8],
[83,36,18,10],
[83,36,18,11],
[83,36,20,0],
[83,36,20,1],
[83,36,20,2],
[83,36,20,3],
[83,36,20,5],
[83,36,20,6],
[83,36,20,7],
[83,36,21,0],
[83,36,21,1],
[83,36,21,2],
[83,36,22,0],
[83,36,22,1],
[83,36,22,2],
[83,36,22,3],
[83,36,23,0],
[83,36,23,1],
[83,36,23,2],
[83,36,24,0],
[83,36,24,1],
[83,36,24,2],
[83,38,5,0],
[83,38,5,1],
[83,38,5,2],
[83,38,6,0],
[83,38,6,1],
[83,38,6,2],
[83,38,8,0],
[83,38,8,1],
[83,38,8,2],
[83,38,9,0],
[83,38,9,1],
[83,38,9,2],
[83,38,9,3],
[83,38,9,5],
[83,38,9,6],
[83,38,9,8],
[83,38,10,0],
[83,38,10,1],
[83,38,10,2],
[83,38,10,3],
[83,38,10,5],
[83,38,10,6],
[83,38,10,8],
[83,38,10,9],
[83,38,11,0],
[83,38,11,1],
[83,38,11,2],
[83,38,11,3],
[83,38,11,5],
[83,38,11,6],
[83,38,11,8],
[83,38,11,9],
[83,38,11,10],
[83,38,12,0],
[83,38,12,1],
[83,38,12,2],
[83,38,12,3],
[83,38,12,5],
[83,38,12,6],
[83,38,12,8],
[83,38,12,9],
[83,38,12,10],
[83,38,13,0],
[83,38,13,1],
[83,38,13,2],
[83,38,13,3],
[83,38,13,5],
[83,38,13,6],
[83,38,13,8],
[83,38,13,9],
[83,38,13,10],
[83,38,13,11],
[83,38,13,12],
[83,38,14,0],
[83,38,14,1],
[83,38,14,2],
[83,38,14,3],
[83,38,14,5],
[83,38,14,6],
[83,38,14,8],
[83,38,14,9],
[83,38,14,10],
[83,38,14,11],
[83,38,14,12],
[83,38,15,0],
[83,38,15,1],
[83,38,15,2],
[83,38,15,3],
[83,38,15,5],
[83,38,15,6],
[83,38,15,8],
[83,38,15,10],
[83,38,15,11],
[83,38,16,0],
[83,38,16,1],
[83,38,16,2],
[83,38,16,3],
[83,38,16,5],
[83,38,16,6],
[83,38,16,8],
[83,38,16,9],
[83,38,17,0],
[83,38,17,1],
[83,38,17,2],
[83,38,17,3],
[83,38,17,5],
[83,38,17,6],
[83,38,17,8],
[83,38,17,9],
[83,38,18,0],
[83,38,18,1],
[83,38,18,2],
[83,38,18,3],
[83,38,18,5],
[83,38,18,6],
[83,38,19,0],
[83,38,19,1],
[83,38,19,2],
[83,38,19,3],
[83,38,19,5],
[83,38,20,0],
[83,38,20,1],
[83,38,20,2],
[83,38,20,3],
[83,38,21,0],
[83,38,21,1],
[83,38,21,2],
[83,39,7,0],
[83,39,7,1],
[83,39,7,2],
[83,39,9,0],
[83,39,9,1],
[83,39,9,2],
[83,39,9,7],
[83,39,10,0],
[83,39,10,1],
[83,39,10,2],
[83,39,10,7],
[83,39,10,9],
[83,39,11,0],
[83,39,11,1],
[83,39,11,2],
[83,39,11,7],
[83,39,11,9],
[83,39,11,10],
[83,39,12,0],
[83,39,12,1],
[83,39,12,2],
[83,39,12,7],
[83,39,12,9],
[83,39,12,10],
[83,39,13,0],
[83,39,13,1],
[83,39,13,2],
[83,39,13,7],
[83,39,13,9],
[83,39,13,10],
[83,39,13,11],
[83,39,13,12],
[83,39,14,0],
[83,39,14,1],
[83,39,14,2],
[83,39,14,7],
[83,39,14,9],
[83,39,14,10],
[83,39,14,11],
[83,39,15,0],
[83,39,15,1],
[83,39,15,2],
[83,39,15,7],
[83,39,16,0],
[83,39,16,1],
[83,39,16,2],
[83,39,16,7],
[83,39,17,0],
[83,39,17,1],
[83,39,17,2],
[83,39,17,7],
[83,39,18,0],
[83,39,18,1],
[83,39,18,2],
[83,39,19,0],
[83,39,19,1],
[83,39,19,2],
[83,39,20,0],
[83,39,20,1],
[83,39,20,2],
[83,40,3,0],
[83,40,3,1],
[83,40,3,2],
[83,40,5,0],
[83,40,5,1],
[83,40,5,2],
[83,40,6,0],
[83,40,6,1],
[83,40,6,2],
[83,40,7,0],
[83,40,7,1],
[83,40,7,2],
[83,40,7,3],
[83,40,7,5],
[83,40,7,6],
[83,40,8,0],
[83,40,8,1],
[83,40,8,2],
[83,40,8,7],
[83,40,10,0],
[83,40,10,1],
[83,40,10,2],
[83,40,10,3],
[83,40,10,5],
[83,40,10,6],
[83,40,10,7],
[83,40,10,8],
[83,40,11,0],
[83,40,11,1],
[83,40,11,2],
[83,40,11,3],
[83,40,11,5],
[83,40,11,6],
[83,40,11,7],
[83,40,11,8],
[83,40,11,10],
[83,40,12,0],
[83,40,12,1],
[83,40,12,2],
[83,40,12,3],
[83,40,12,5],
[83,40,12,6],
[83,40,12,7],
[83,40,12,8],
[83,40,12,10],
[83,40,13,0],
[83,40,13,1],
[83,40,13,2],
[83,40,13,3],
[83,40,13,5],
[83,40,13,6],
[83,40,13,7],
[83,40,13,8],
[83,40,13,10],
[83,40,13,11],
[83,40,14,0],
[83,40,14,1],
[83,40,14,2],
[83,40,14,3],
[83,40,14,5],
[83,40,14,6],
[83,40,14,7],
[83,40,14,8],
[83,40,16,0],
[83,40,16,1],
[83,40,16,2],
[83,40,16,3],
[83,40,16,5],
[83,40,17,0],
[83,40,17,1],
[83,40,17,2],
[83,40,17,3],
[83,40,17,5],
[83,40,19,0],
[83,40,19,1],
[83,40,19,2],
[83,41,3,0],
[83,41,3,1],
[83,41,3,2],
[83,41,5,0],
[83,41,5,1],
[83,41,5,2],
[83,41,6,0],
[83,41,6,1],
[83,41,6,2],
[83,41,7,0],
[83,41,7,1],
[83,41,7,2],
[83,41,7,3],
[83,41,7,5],
[83,41,7,6],
[83,41,8,0],
[83,41,8,1],
[83,41,8,2],
[83,41,8,7],
[83,41,10,0],
[83,41,10,1],
[83,41,10,2],
[83,41,10,3],
[83,41,10,5],
[83,41,10,6],
[83,41,10,7],
[83,41,10,8],
[83,41,11,0],
[83,41,11,1],
[83,41,11,2],
[83,41,11,3],
[83,41,11,5],
[83,41,11,6],
[83,41,11,7],
[83,41,11,8],
[83,41,11,10],
[83,41,12,0],
[83,41,12,1],
[83,41,12,2],
[83,41,12,3],
[83,41,12,5],
[83,41,12,6],
[83,41,12,7],
[83,41,12,8],
[83,41,12,10],
[83,41,13,0],
[83,41,13,1],
[83,41,13,2],
[83,41,13,3],
[83,41,13,5],
[83,41,13,6],
[83,41,13,7],
[83,41,13,8],
[83,41,13,10],
[83,41,13,11],
[83,41,14,0],
[83,41,14,1],
[83,41,14,2],
[83,41,14,3],
[83,41,14,5],
[83,41,14,6],
[83,41,14,7],
[83,41,14,8],
[83,41,16,0],
[83,41,16,1],
[83,41,16,2],
[83,41,16,3],
[83,41,16,5],
[83,41,17,0],
[83,41,17,1],
[83,41,17,2],
[83,41,17,3],
[83,41,17,5],
[83,41,19,0],
[83,41,19,1],
[83,41,19,2],
[83,42,3,0],
[83,42,3,1],
[83,42,3,2],
[83,42,5,0],
[83,42,5,1],
[83,42,5,2],
[83,42,6,0],
[83,42,6,1],
[83,42,6,2],
[83,42,7,0],
[83,42,7,1],
[83,42,7,2],
[83,42,7,3],
[83,42,7,5],
[83,42,7,6],
[83,42,8,0],
[83,42,8,1],
[83,42,8,2],
[83,42,8,7],
[83,42,9,0],
[83,42,9,1],
[83,42,9,2],
[83,42,9,3],
[83,42,9,5],
[83,42,9,6],
[83,42,9,7],
[83,42,9,8],
[83,42,11,0],
[83,42,11,1],
[83,42,11,2],
[83,42,11,3],
[83,42,11,5],
[83,42,11,6],
[83,42,11,7],
[83,42,11,8],
[83,42,11,9],
[83,42,12,0],
[83,42,12,1],
[83,42,12,2],
[83,42,12,3],
[83,42,12,5],
[83,42,12,6],
[83,42,12,7],
[83,42,12,8],
[83,42,12,9],
[83,42,13,0],
[83,42,13,1],
[83,42,13,2],
[83,42,13,3],
[83,42,13,5],
[83,42,13,6],
[83,42,13,7],
[83,42,13,8],
[83,42,13,9],
[83,42,14,0],
[83,42,14,1],
[83,42,14,2],
[83,42,14,3],
[83,42,14,5],
[83,42,14,6],
[83,42,14,7],
[83,42,15,0],
[83,42,15,1],
[83,42,15,2],
[83,42,15,3],
[83,42,15,5],
[83,42,16,0],
[83,42,16,1],
[83,42,16,2],
[83,42,16,3],
[83,42,17,0],
[83,42,17,1],
[83,42,17,2],
[83,42,17,3],
[83,42,18,0],
[83,42,18,1],
[83,42,18,2],
[83,43,3,0],
[83,43,3,1],
[83,43,3,2],
[83,43,5,0],
[83,43,5,1],
[83,43,5,2],
[83,43,6,0],
[83,43,6,1],
[83,43,6,2],
[83,43,8,0],
[83,43,8,1],
[83,43,8,2],
[83,43,9,0],
[83,43,9,1],
[83,43,9,2],
[83,43,9,3],
[83,43,9,5],
[83,43,9,6],
[83,43,9,8],
[83,43,10,0],
[83,43,10,1],
[83,43,10,2],
[83,43,10,3],
[83,43,10,5],
[83,43,10,6],
[83,43,10,8],
[83,43,10,9],
[83,43,11,0],
[83,43,11,1],
[83,43,11,2],
[83,43,11,3],
[83,43,11,5],
[83,43,11,6],
[83,43,11,8],
[83,43,11,9],
[83,43,12,0],
[83,43,12,1],
[83,43,12,2],
[83,43,12,3],
[83,43,12,5],
[83,43,12,6],
[83,43,13,0],
[83,43,13,1],
[83,43,13,2],
[83,43,13,3],
[83,43,13,5],
[83,43,13,6],
[83,43,14,0],
[83,43,14,1],
[83,43,14,2],
[83,43,14,3],
[83,43,14,5],
[83,43,15,0],
[83,43,15,1],
[83,43,15,2],
[83,43,15,3],
[83,43,16,0],
[83,43,16,1],
[83,43,16,2],
[83,43,17,0],
[83,43,17,1],
[83,43,17,2],
[83,44,3,0],
[83,44,3,1],
[83,44,3,2],
[83,44,5,0],
[83,44,5,1],
[83,44,5,2],
[83,44,6,0],
[83,44,6,1],
[83,44,6,2],
[83,44,7,0],
[83,44,7,1],
[83,44,7,2],
[83,44,7,3],
[83,44,7,5],
[83,44,7,6],
[83,44,8,0],
[83,44,8,1],
[83,44,8,2],
[83,44,8,7],
[83,44,9,0],
[83,44,9,1],
[83,44,9,2],
[83,44,9,3],
[83,44,9,5],
[83,44,9,6],
[83,44,9,7],
[83,44,9,8],
[83,44,10,0],
[83,44,10,1],
[83,44,10,2],
[83,44,10,3],
[83,44,10,5],
[83,44,10,6],
[83,44,10,7],
[83,44,11,0],
[83,44,11,1],
[83,44,11,2],
[83,44,11,3],
[83,44,11,5],
[83,44,11,6],
[83,44,11,7],
[83,44,12,0],
[83,44,12,1],
[83,44,12,2],
[83,44,12,3],
[83,44,12,5],
[83,44,13,0],
[83,44,13,1],
[83,44,13,2],
[83,44,13,3],
[83,44,13,5],
[83,44,14,0],
[83,44,14,1],
[83,44,14,2],
[83,44,14,3],
[83,44,15,0],
[83,44,15,1],
[83,44,15,2],
[83,45,3,0],
[83,45,3,1],
[83,45,3,2],
[83,45,5,0],
[83,45,5,1],
[83,45,5,2],
[83,45,6,0],
[83,45,6,1],
[83,45,6,2],
[83,45,7,0],
[83,45,7,1],
[83,45,7,2],
[83,45,7,3],
[83,45,7,5],
[83,45,7,6],
[83,45,8,0],
[83,45,8,1],
[83,45,8,2],
[83,45,8,7],
[83,45,10,0],
[83,45,10,1],
[83,45,10,2],
[83,45,10,3],
[83,45,10,5],
[83,45,11,0],
[83,45,11,1],
[83,45,11,2],
[83,45,11,3],
[83,45,11,5],
[83,45,12,0],
[83,45,12,1],
[83,45,12,2],
[83,45,12,3],
[83,45,13,0],
[83,45,13,1],
[83,45,13,2],
[83,45,13,3],
[83,45,14,0],
[83,45,14,1],
[83,45,14,2],
[83,46,3,0],
[83,46,3,1],
[83,46,3,2],
[83,46,5,0],
[83,46,5,1],
[83,46,5,2],
[83,46,6,0],
[83,46,6,1],
[83,46,6,2],
[83,46,7,0],
[83,46,7,1],
[83,46,7,2],
[83,46,7,3],
[83,46,7,5],
[83,46,7,6],
[83,46,8,0],
[83,46,8,1],
[83,46,8,2],
[83,46,10,0],
[83,46,10,1],
[83,46,10,2],
[83,46,10,3],
[83,46,11,0],
[83,46,11,1],
[83,46,11,2],
[83,46,11,3],
[83,46,12,0],
[83,46,12,1],
[83,46,12,2],
[83,46,13,0],
[83,46,13,1],
[83,46,13,2],
[83,47,3,0],
[83,47,3,1],
[83,47,3,2],
[83,47,5,0],
[83,47,5,1],
[83,47,5,2],
[83,47,6,0],
[83,47,6,1],
[83,47,6,2],
[83,47,7,0],
[83,47,7,1],
[83,47,7,2],
[83,47,7,3],
[83,47,7,5],
[83,47,8,0],
[83,47,8,1],
[83,47,8,2],
[83,47,9,0],
[83,47,9,1],
[83,47,9,2],
[83,47,9,3],
[83,47,10,0],
[83,47,10,1],
[83,47,10,2],
[83,47,11,0],
[83,47,11,1],
[83,47,11,2],
[83,48,3,0],
[83,48,3,1],
[83,48,3,2],
[83,48,5,0],
[83,48,5,1],
[83,48,5,2],
[83,48,6,0],
[83,48,6,1],
[83,48,6,2],
[83,48,7,0],
[83,48,7,1],
[83,48,7,2],
[83,48,7,3],
[83,48,8,0],
[83,48,8,1],
[83,48,8,2],
[83,48,9,0],
[83,48,9,1],
[83,48,9,2],
[83,49,3,0],
[83,49,3,1],
[83,49,3,2],
[83,49,5,0],
[83,49,5,1],
[83,49,5,2],
[83,49,6,0],
[83,49,6,1],
[83,49,6,2],
[83,49,7,0],
[83,49,7,1],
[83,49,7,2],
[83,49,7,3],
[83,49,8,0],
[83,49,8,1],
[83,49,8,2],
[83,50,3,0],
[83,50,3,1],
[83,50,3,2],
[83,50,5,0],
[83,50,5,1],
[83,50,5,2],
[83,50,6,0],
[83,50,6,1],
[83,50,6,2],
[83,50,7,0],
[83,50,7,1],
[83,50,7,2],
[83,50,7,3],
[83,50,8,0],
[83,50,8,1],
[83,50,8,2],
[83,51,7,0],
[83,51,7,1],
[83,51,7,2],
[83,51,9,0],
[83,51,9,1],
[83,51,9,2],
[83,52,3,0],
[83,52,3,1],
[83,52,3,2],
[83,52,5,0],
[83,52,5,1],
[83,52,5,2],
[83,52,6,0],
[83,52,6,1],
[83,52,6,2],
[83,52,7,0],
[83,52,7,1],
[83,52,7,2],
[83,52,7,3],
[83,52,8,0],
[83,52,8,1],
[83,52,8,2],
[83,53,3,0],
[83,53,3,1],
[83,53,3,2],
[83,53,5,0],
[83,53,5,1],
[83,53,5,2],
[83,53,6,0],
[83,53,6,1],
[83,53,6,2],
[83,53,7,0],
[83,53,7,1],
[83,53,7,2],
[83,53,7,3],
[83,53,8,0],
[83,53,8,1],
[83,53,8,2],
[83,53,9,0],
[83,53,9,1],
[83,53,9,2],
[83,54,3,0],
[83,54,3,1],
[83,54,3,2],
[83,54,5,0],
[83,54,5,1],
[83,54,5,2],
[83,54,6,0],
[83,54,6,1],
[83,54,6,2],
[83,54,7,0],
[83,54,7,1],
[83,54,7,2],
[83,54,7,3],
[83,54,8,0],
[83,54,8,1],
[83,54,8,2],
[83,54,9,0],
[83,54,9,1],
[83,54,9,2],
[83,55,3,0],
[83,55,3,1],
[83,55,3,2],
[83,55,5,0],
[83,55,5,1],
[83,55,5,2],
[83,55,6,0],
[83,55,6,1],
[83,55,6,2],
[83,55,7,0],
[83,55,7,1],
[83,55,7,2],
[83,55,7,3],
[83,55,8,0],
[83,55,8,1],
[83,55,8,2],
[83,55,9,0],
[83,55,9,1],
[83,55,9,2],
[83,56,3,0],
[83,56,3,1],
[83,56,3,2],
[83,56,5,0],
[83,56,5,1],
[83,56,5,2],
[83,56,6,0],
[83,56,6,1],
[83,56,6,2],
[83,56,7,0],
[83,56,7,1],
[83,56,7,2],
[83,56,7,3],
[83,56,8,0],
[83,56,8,1],
[83,56,8,2],
[83,57,3,0],
[83,57,3,1],
[83,57,3,2],
[83,57,5,0],
[83,57,5,1],
[83,57,5,2],
[83,57,6,0],
[83,57,6,1],
[83,57,6,2],
[83,57,7,0],
[83,57,7,1],
[83,57,7,2],
[83,58,3,0],
[83,58,3,1],
[83,58,3,2],
[83,58,5,0],
[83,58,5,1],
[83,58,5,2],
[83,58,6,0],
[83,58,6,1],
[83,58,6,2],
[83,58,7,0],
[83,58,7,1],
[83,58,7,2],
[83,59,3,0],
[83,59,3,1],
[83,59,3,2],
[83,59,5,0],
[83,59,5,1],
[83,59,5,2],
[83,60,3,0],
[83,60,3,1],
[83,60,3,2],
[84,7,3,0],
[84,7,3,1],
[84,7,3,2],
[84,7,5,0],
[84,7,5,1],
[84,7,5,2],
[84,7,6,0],
[84,7,6,1],
[84,7,6,2],
[84,8,7,0],
[84,8,7,1],
[84,8,7,2],
[84,9,3,0],
[84,9,3,1],
[84,9,3,2],
[84,9,5,0],
[84,9,5,1],
[84,9,5,2],
[84,9,6,0],
[84,9,6,1],
[84,9,6,2],
[84,9,7,0],
[84,9,7,1],
[84,9,7,2],
[84,9,7,3],
[84,9,7,5],
[84,9,7,6],
[84,9,8,0],
[84,9,8,1],
[84,9,8,2],
[84,9,8,7],
[84,10,3,0],
[84,10,3,1],
[84,10,3,2],
[84,10,5,0],
[84,10,5,1],
[84,10,5,2],
[84,10,6,0],
[84,10,6,1],
[84,10,6,2],
[84,10,7,0],
[84,10,7,1],
[84,10,7,2],
[84,10,7,3],
[84,10,7,5],
[84,10,7,6],
[84,10,8,0],
[84,10,8,1],
[84,10,8,2],
[84,10,8,7],
[84,10,9,0],
[84,10,9,1],
[84,10,9,2],
[84,10,9,3],
[84,10,9,5],
[84,10,9,6],
[84,10,9,7],
[84,10,9,8],
[84,11,3,0],
[84,11,3,1],
[84,11,3,2],
[84,11,5,0],
[84,11,5,1],
[84,11,5,2],
[84,11,6,0],
[84,11,6,1],
[84,11,6,2],
[84,11,7,0],
[84,11,7,1],
[84,11,7,2],
[84,11,7,3],
[84,11,7,5],
[84,11,7,6],
[84,11,8,0],
[84,11,8,1],
[84,11,8,2],
[84,11,8,7],
[84,11,9,0],
[84,11,9,1],
[84,11,9,2],
[84,11,9,3],
[84,11,9,5],
[84,11,9,6],
[84,11,9,7],
[84,11,9,8],
[84,11,10,0],
[84,11,10,1],
[84,11,10,2],
[84,11,10,3],
[84,11,10,5],
[84,11,10,6],
[84,11,10,7],
[84,11,10,8],
[84,11,10,9],
[84,12,3,0],
[84,12,3,1],
[84,12,3,2],
[84,12,5,0],
[84,12,5,1],
[84,12,5,2],
[84,12,6,0],
[84,12,6,1],
[84,12,6,2],
[84,12,7,0],
[84,12,7,1],
[84,12,7,2],
[84,12,7,3],
[84,12,7,5],
[84,12,7,6],
[84,12,8,0],
[84,12,8,1],
[84,12,8,2],
[84,12,8,7],
[84,12,9,0],
[84,12,9,1],
[84,12,9,2],
[84,12,9,3],
[84,12,9,5],
[84,12,9,6],
[84,12,9,7],
[84,12,9,8],
[84,12,10,0],
[84,12,10,1],
[84,12,10,2],
[84,12,10,3],
[84,12,10,5],
[84,12,10,6],
[84,12,10,7],
[84,12,10,8],
[84,12,10,9],
[84,13,3,0],
[84,13,3,1],
[84,13,3,2],
[84,13,5,0],
[84,13,5,1],
[84,13,5,2],
[84,13,6,0],
[84,13,6,1],
[84,13,6,2],
[84,13,7,0],
[84,13,7,1],
[84,13,7,2],
[84,13,7,3],
[84,13,7,5],
[84,13,7,6],
[84,13,8,0],
[84,13,8,1],
[84,13,8,2],
[84,13,8,7],
[84,13,9,0],
[84,13,9,1],
[84,13,9,2],
[84,13,9,3],
[84,13,9,5],
[84,13,9,6],
[84,13,9,7],
[84,13,9,8],
[84,13,10,0],
[84,13,10,1],
[84,13,10,2],
[84,13,10,3],
[84,13,10,5],
[84,13,10,6],
[84,13,10,7],
[84,13,10,8],
[84,13,10,9],
[84,13,11,0],
[84,13,11,1],
[84,13,11,2],
[84,13,11,3],
[84,13,11,5],
[84,13,11,6],
[84,13,11,7],
[84,13,11,8],
[84,13,11,9],
[84,13,11,10],
[84,13,12,0],
[84,13,12,1],
[84,13,12,2],
[84,13,12,3],
[84,13,12,5],
[84,13,12,6],
[84,13,12,7],
[84,13,12,8],
[84,13,12,9],
[84,13,12,10],
[84,14,3,0],
[84,14,3,1],
[84,14,3,2],
[84,14,5,0],
[84,14,5,1],
[84,14,5,2],
[84,14,6,0],
[84,14,6,1],
[84,14,6,2],
[84,14,7,0],
[84,14,7,1],
[84,14,7,2],
[84,14,7,3],
[84,14,7,5],
[84,14,7,6],
[84,14,8,0],
[84,14,8,1],
[84,14,8,2],
[84,14,8,7],
[84,14,9,0],
[84,14,9,1],
[84,14,9,2],
[84,14,9,3],
[84,14,9,5],
[84,14,9,6],
[84,14,9,7],
[84,14,9,8],
[84,14,10,0],
[84,14,10,1],
[84,14,10,2],
[84,14,10,3],
[84,14,10,5],
[84,14,10,6],
[84,14,10,7],
[84,14,10,8],
[84,14,10,9],
[84,14,11,0],
[84,14,11,1],
[84,14,11,2],
[84,14,11,3],
[84,14,11,5],
[84,14,11,6],
[84,14,11,7],
[84,14,11,8],
[84,14,11,9],
[84,14,11,10],
[84,14,12,0],
[84,14,12,1],
[84,14,12,2],
[84,14,12,3],
[84,14,12,5],
[84,14,12,6],
[84,14,12,7],
[84,14,12,8],
[84,14,12,9],
[84,14,12,10],
[84,15,3,0],
[84,15,3,1],
[84,15,3,2],
[84,15,5,0],
[84,15,5,1],
[84,15,5,2],
[84,15,6,0],
[84,15,6,1],
[84,15,6,2],
[84,15,7,0],
[84,15,7,1],
[84,15,7,2],
[84,15,7,3],
[84,15,7,5],
[84,15,7,6],
[84,15,8,0],
[84,15,8,1],
[84,15,8,2],
[84,15,8,7],
[84,15,10,0],
[84,15,10,1],
[84,15,10,2],
[84,15,10,3],
[84,15,10,5],
[84,15,10,6],
[84,15,10,7],
[84,15,10,8],
[84,15,11,0],
[84,15,11,1],
[84,15,11,2],
[84,15,11,3],
[84,15,11,5],
[84,15,11,6],
[84,15,11,7],
[84,15,11,8],
[84,15,11,10],
[84,15,12,0],
[84,15,12,1],
[84,15,12,2],
[84,15,12,3],
[84,15,12,5],
[84,15,12,6],
[84,15,12,7],
[84,15,12,8],
[84,15,12,10],
[84,15,13,0],
[84,15,13,1],
[84,15,13,2],
[84,15,13,3],
[84,15,13,5],
[84,15,13,6],
[84,15,13,7],
[84,15,13,8],
[84,15,13,10],
[84,15,13,11],
[84,15,13,12],
[84,15,14,0],
[84,15,14,1],
[84,15,14,2],
[84,15,14,3],
[84,15,14,5],
[84,15,14,6],
[84,15,14,7],
[84,15,14,8],
[84,15,14,10],
[84,15,14,11],
[84,15,14,12],
[84,16,3,0],
[84,16,3,1],
[84,16,3,2],
[84,16,5,0],
[84,16,5,1],
[84,16,5,2],
[84,16,6,0],
[84,16,6,1],
[84,16,6,2],
[84,16,7,0],
[84,16,7,1],
[84,16,7,2],
[84,16,7,3],
[84,16,7,5],
[84,16,7,6],
[84,16,8,0],
[84,16,8,1],
[84,16,8,2],
[84,16,8,7],
[84,16,9,0],
[84,16,9,1],
[84,16,9,2],
[84,16,9,3],
[84,16,9,5],
[84,16,9,6],
[84,16,9,7],
[84,16,9,8],
[84,16,10,0],
[84,16,10,1],
[84,16,10,2],
[84,16,10,3],
[84,16,10,5],
[84,16,10,6],
[84,16,10,7],
[84,16,10,8],
[84,16,10,9],
[84,16,11,0],
[84,16,11,1],
[84,16,11,2],
[84,16,11,3],
[84,16,11,5],
[84,16,11,6],
[84,16,11,7],
[84,16,11,8],
[84,16,11,9],
[84,16,11,10],
[84,16,12,0],
[84,16,12,1],
[84,16,12,2],
[84,16,12,3],
[84,16,12,5],
[84,16,12,6],
[84,16,12,7],
[84,16,12,8],
[84,16,12,9],
[84,16,12,10],
[84,16,13,0],
[84,16,13,1],
[84,16,13,2],
[84,16,13,3],
[84,16,13,5],
[84,16,13,6],
[84,16,13,7],
[84,16,13,8],
[84,16,13,9],
[84,16,13,10],
[84,16,13,11],
[84,16,13,12],
[84,16,14,0],
[84,16,14,1],
[84,16,14,2],
[84,16,14,3],
[84,16,14,5],
[84,16,14,6],
[84,16,14,7],
[84,16,14,8],
[84,16,14,9],
[84,16,14,10],
[84,16,14,11],
[84,16,14,12],
[84,16,15,0],
[84,16,15,1],
[84,16,15,2],
[84,16,15,3],
[84,16,15,5],
[84,16,15,6],
[84,16,15,7],
[84,16,15,8],
[84,16,15,10],
[84,16,15,11],
[84,16,15,12],
[84,16,15,13],
[84,16,15,14],
[84,17,3,0],
[84,17,3,1],
[84,17,3,2],
[84,17,5,0],
[84,17,5,1],
[84,17,5,2],
[84,17,6,0],
[84,17,6,1],
[84,17,6,2],
[84,17,7,0],
[84,17,7,1],
[84,17,7,2],
[84,17,7,3],
[84,17,7,5],
[84,17,7,6],
[84,17,8,0],
[84,17,8,1],
[84,17,8,2],
[84,17,8,7],
[84,17,9,0],
[84,17,9,1],
[84,17,9,2],
[84,17,9,3],
[84,17,9,5],
[84,17,9,6],
[84,17,9,7],
[84,17,9,8],
[84,17,10,0],
[84,17,10,1],
[84,17,10,2],
[84,17,10,3],
[84,17,10,5],
[84,17,10,6],
[84,17,10,7],
[84,17,10,8],
[84,17,10,9],
[84,17,11,0],
[84,17,11,1],
[84,17,11,2],
[84,17,11,3],
[84,17,11,5],
[84,17,11,6],
[84,17,11,7],
[84,17,11,8],
[84,17,11,9],
[84,17,11,10],
[84,17,12,0],
[84,17,12,1],
[84,17,12,2],
[84,17,12,3],
[84,17,12,5],
[84,17,12,6],
[84,17,12,7],
[84,17,12,8],
[84,17,12,9],
[84,17,12,10],
[84,17,13,0],
[84,17,13,1],
[84,17,13,2],
[84,17,13,3],
[84,17,13,5],
[84,17,13,6],
[84,17,13,7],
[84,17,13,8],
[84,17,13,9],
[84,17,13,10],
[84,17,13,11],
[84,17,13,12],
[84,17,14,0],
[84,17,14,1],
[84,17,14,2],
[84,17,14,3],
[84,17,14,5],
[84,17,14,6],
[84,17,14,7],
[84,17,14,8],
[84,17,14,9],
[84,17,14,10],
[84,17,14,11],
[84,17,14,12],
[84,17,15,0],
[84,17,15,1],
[84,17,15,2],
[84,17,15,3],
[84,17,15,5],
[84,17,15,6],
[84,17,15,7],
[84,17,15,8],
[84,17,15,10],
[84,17,15,11],
[84,17,15,12],
[84,17,15,13],
[84,17,15,14],
[84,17,16,0],
[84,17,16,1],
[84,17,16,2],
[84,17,16,3],
[84,17,16,5],
[84,17,16,6],
[84,17,16,7],
[84,17,16,8],
[84,17,16,9],
[84,17,16,10],
[84,17,16,11],
[84,17,16,12],
[84,17,16,13],
[84,17,16,14],
[84,17,16,15],
[84,18,3,0],
[84,18,3,1],
[84,18,3,2],
[84,18,5,0],
[84,18,5,1],
[84,18,5,2],
[84,18,6,0],
[84,18,6,1],
[84,18,6,2],
[84,18,7,0],
[84,18,7,1],
[84,18,7,2],
[84,18,7,3],
[84,18,7,5],
[84,18,7,6],
[84,18,8,0],
[84,18,8,1],
[84,18,8,2],
[84,18,8,7],
[84,18,10,0],
[84,18,10,1],
[84,18,10,2],
[84,18,10,3],
[84,18,10,5],
[84,18,10,6],
[84,18,10,7],
[84,18,10,8],
[84,18,11,0],
[84,18,11,1],
[84,18,11,2],
[84,18,11,3],
[84,18,11,5],
[84,18,11,6],
[84,18,11,7],
[84,18,11,8],
[84,18,11,10],
[84,18,12,0],
[84,18,12,1],
[84,18,12,2],
[84,18,12,3],
[84,18,12,5],
[84,18,12,6],
[84,18,12,7],
[84,18,12,8],
[84,18,12,10],
[84,18,13,0],
[84,18,13,1],
[84,18,13,2],
[84,18,13,3],
[84,18,13,5],
[84,18,13,6],
[84,18,13,7],
[84,18,13,8],
[84,18,13,10],
[84,18,13,11],
[84,18,13,12],
[84,18,14,0],
[84,18,14,1],
[84,18,14,2],
[84,18,14,3],
[84,18,14,5],
[84,18,14,6],
[84,18,14,7],
[84,18,14,8],
[84,18,14,10],
[84,18,14,11],
[84,18,14,12],
[84,18,16,0],
[84,18,16,1],
[84,18,16,2],
[84,18,16,3],
[84,18,16,5],
[84,18,16,6],
[84,18,16,7],
[84,18,16,8],
[84,18,16,10],
[84,18,16,11],
[84,18,16,12],
[84,18,16,13],
[84,18,16,14],
[84,18,17,0],
[84,18,17,1],
[84,18,17,2],
[84,18,17,3],
[84,18,17,5],
[84,18,17,6],
[84,18,17,7],
[84,18,17,8],
[84,18,17,10],
[84,18,17,11],
[84,18,17,12],
[84,18,17,13],
[84,18,17,14],
[84,18,17,16],
[84,19,3,0],
[84,19,3,1],
[84,19,3,2],
[84,19,5,0],
[84,19,5,1],
[84,19,5,2],
[84,19,6,0],
[84,19,6,1],
[84,19,6,2],
[84,19,7,0],
[84,19,7,1],
[84,19,7,2],
[84,19,7,3],
[84,19,7,5],
[84,19,7,6],
[84,19,8,0],
[84,19,8,1],
[84,19,8,2],
[84,19,8,7],
[84,19,9,0],
[84,19,9,1],
[84,19,9,2],
[84,19,9,3],
[84,19,9,5],
[84,19,9,6],
[84,19,9,7],
[84,19,9,8],
[84,19,10,0],
[84,19,10,1],
[84,19,10,2],
[84,19,10,3],
[84,19,10,5],
[84,19,10,6],
[84,19,10,7],
[84,19,10,8],
[84,19,10,9],
[84,19,11,0],
[84,19,11,1],
[84,19,11,2],
[84,19,11,3],
[84,19,11,5],
[84,19,11,6],
[84,19,11,7],
[84,19,11,8],
[84,19,11,9],
[84,19,11,10],
[84,19,12,0],
[84,19,12,1],
[84,19,12,2],
[84,19,12,3],
[84,19,12,5],
[84,19,12,6],
[84,19,12,7],
[84,19,12,8],
[84,19,12,9],
[84,19,12,10],
[84,19,15,0],
[84,19,15,1],
[84,19,15,2],
[84,19,15,3],
[84,19,15,5],
[84,19,15,6],
[84,19,15,7],
[84,19,15,8],
[84,19,15,10],
[84,19,15,11],
[84,19,15,12],
[84,19,16,0],
[84,19,16,1],
[84,19,16,2],
[84,19,16,3],
[84,19,16,5],
[84,19,16,6],
[84,19,16,7],
[84,19,16,8],
[84,19,16,9],
[84,19,16,10],
[84,19,16,11],
[84,19,16,12],
[84,19,16,15],
[84,19,17,0],
[84,19,17,1],
[84,19,17,2],
[84,19,17,3],
[84,19,17,5],
[84,19,17,6],
[84,19,17,7],
[84,19,17,8],
[84,19,17,9],
[84,19,17,10],
[84,19,17,11],
[84,19,17,12],
[84,19,17,15],
[84,19,17,16],
[84,19,18,0],
[84,19,18,1],
[84,19,18,2],
[84,19,18,3],
[84,19,18,5],
[84,19,18,6],
[84,19,18,7],
[84,19,18,8],
[84,19,18,10],
[84,19,18,11],
[84,19,18,12],
[84,20,3,0],
[84,20,3,1],
[84,20,3,2],
[84,20,5,0],
[84,20,5,1],
[84,20,5,2],
[84,20,6,0],
[84,20,6,1],
[84,20,6,2],
[84,20,7,0],
[84,20,7,1],
[84,20,7,2],
[84,20,7,3],
[84,20,7,5],
[84,20,7,6],
[84,20,8,0],
[84,20,8,1],
[84,20,8,2],
[84,20,8,7],
[84,20,9,0],
[84,20,9,1],
[84,20,9,2],
[84,20,9,3],
[84,20,9,5],
[84,20,9,6],
[84,20,9,7],
[84,20,9,8],
[84,20,11,0],
[84,20,11,1],
[84,20,11,2],
[84,20,11,3],
[84,20,11,5],
[84,20,11,6],
[84,20,11,7],
[84,20,11,8],
[84,20,11,9],
[84,20,12,0],
[84,20,12,1],
[84,20,12,2],
[84,20,12,3],
[84,20,12,5],
[84,20,12,6],
[84,20,12,7],
[84,20,12,8],
[84,20,12,9],
[84,20,13,0],
[84,20,13,1],
[84,20,13,2],
[84,20,13,3],
[84,20,13,5],
[84,20,13,6],
[84,20,13,7],
[84,20,13,8],
[84,20,13,9],
[84,20,13,11],
[84,20,13,12],
[84,20,14,0],
[84,20,14,1],
[84,20,14,2],
[84,20,14,3],
[84,20,14,5],
[84,20,14,6],
[84,20,14,7],
[84,20,14,8],
[84,20,14,9],
[84,20,14,11],
[84,20,14,12],
[84,20,15,0],
[84,20,15,1],
[84,20,15,2],
[84,20,15,3],
[84,20,15,5],
[84,20,15,6],
[84,20,15,7],
[84,20,15,8],
[84,20,15,11],
[84,20,15,12],
[84,20,15,13],
[84,20,15,14],
[84,20,16,0],
[84,20,16,1],
[84,20,16,2],
[84,20,16,3],
[84,20,16,5],
[84,20,16,6],
[84,20,16,7],
[84,20,16,8],
[84,20,16,9],
[84,20,16,11],
[84,20,16,12],
[84,20,16,13],
[84,20,16,14],
[84,20,16,15],
[84,20,17,0],
[84,20,17,1],
[84,20,17,2],
[84,20,17,3],
[84,20,17,5],
[84,20,17,6],
[84,20,17,7],
[84,20,17,8],
[84,20,17,9],
[84,20,17,11],
[84,20,17,12],
[84,20,17,13],
[84,20,17,14],
[84,20,17,15],
[84,20,18,0],
[84,20,18,1],
[84,20,18,2],
[84,20,18,3],
[84,20,18,5],
[84,20,18,6],
[84,20,18,7],
[84,20,18,8],
[84,20,18,11],
[84,20,18,12],
[84,20,18,13],
[84,20,18,14],
[84,20,18,16],
[84,20,18,17],
[84,20,19,0],
[84,20,19,1],
[84,20,19,2],
[84,20,19,3],
[84,20,19,5],
[84,20,19,6],
[84,20,19,7],
[84,20,19,8],
[84,20,19,9],
[84,20,19,11],
[84,20,19,12],
[84,20,19,15],
[84,20,19,16],
[84,20,19,17],
[84,20,19,18],
[84,21,7,0],
[84,21,7,1],
[84,21,7,2],
[84,21,9,0],
[84,21,9,1],
[84,21,9,2],
[84,21,9,7],
[84,21,10,0],
[84,21,10,1],
[84,21,10,2],
[84,21,10,7],
[84,21,10,9],
[84,21,11,0],
[84,21,11,1],
[84,21,11,2],
[84,21,11,7],
[84,21,11,9],
[84,21,11,10],
[84,21,12,0],
[84,21,12,1],
[84,21,12,2],
[84,21,12,7],
[84,21,12,9],
[84,21,12,10],
[84,21,13,0],
[84,21,13,1],
[84,21,13,2],
[84,21,13,7],
[84,21,13,9],
[84,21,13,10],
[84,21,13,11],
[84,21,13,12],
[84,21,14,0],
[84,21,14,1],
[84,21,14,2],
[84,21,14,7],
[84,21,14,9],
[84,21,14,10],
[84,21,14,11],
[84,21,14,12],
[84,21,15,0],
[84,21,15,1],
[84,21,15,2],
[84,21,15,7],
[84,21,15,10],
[84,21,15,11],
[84,21,15,12],
[84,21,15,13],
[84,21,15,14],
[84,21,16,0],
[84,21,16,1],
[84,21,16,2],
[84,21,16,7],
[84,21,16,9],
[84,21,16,10],
[84,21,16,11],
[84,21,16,12],
[84,21,16,13],
[84,21,16,14],
[84,21,17,0],
[84,21,17,1],
[84,21,17,2],
[84,21,17,7],
[84,21,17,9],
[84,21,17,10],
[84,21,17,11],
[84,21,17,12],
[84,21,17,13],
[84,21,17,14],
[84,21,17,16],
[84,21,18,0],
[84,21,18,1],
[84,21,18,2],
[84,21,18,7],
[84,21,18,10],
[84,21,18,11],
[84,21,18,12],
[84,21,18,13],
[84,21,18,16],
[84,21,18,17],
[84,21,19,0],
[84,21,19,1],
[84,21,19,2],
[84,21,19,7],
[84,21,19,9],
[84,21,19,10],
[84,21,19,11],
[84,21,19,15],
[84,21,19,16],
[84,21,19,17],
[84,21,19,18],
[84,21,20,0],
[84,21,20,1],
[84,21,20,2],
[84,21,20,7],
[84,21,20,9],
[84,21,20,12],
[84,21,20,13],
[84,21,20,14],
[84,21,20,15],
[84,21,20,16],
[84,21,20,17],
[84,21,20,18],
[84,21,20,19],
[84,22,3,0],
[84,22,3,1],
[84,22,3,2],
[84,22,5,0],
[84,22,5,1],
[84,22,5,2],
[84,22,6,0],
[84,22,6,1],
[84,22,6,2],
[84,22,7,0],
[84,22,7,1],
[84,22,7,2],
[84,22,7,3],
[84,22,7,5],
[84,22,7,6],
[84,22,8,0],
[84,22,8,1],
[84,22,8,2],
[84,22,8,7],
[84,22,9,0],
[84,22,9,1],
[84,22,9,2],
[84,22,9,3],
[84,22,9,5],
[84,22,9,6],
[84,22,9,7],
[84,22,9,8],
[84,22,11,0],
[84,22,11,1],
[84,22,11,2],
[84,22,11,3],
[84,22,11,5],
[84,22,11,6],
[84,22,11,7],
[84,22,11,8],
[84,22,11,9],
[84,22,12,0],
[84,22,12,1],
[84,22,12,2],
[84,22,12,3],
[84,22,12,5],
[84,22,12,6],
[84,22,12,7],
[84,22,12,8],
[84,22,12,9],
[84,22,13,0],
[84,22,13,1],
[84,22,13,2],
[84,22,13,3],
[84,22,13,5],
[84,22,13,6],
[84,22,13,7],
[84,22,13,8],
[84,22,13,9],
[84,22,13,11],
[84,22,13,12],
[84,22,14,0],
[84,22,14,1],
[84,22,14,2],
[84,22,14,3],
[84,22,14,5],
[84,22,14,6],
[84,22,14,7],
[84,22,14,8],
[84,22,14,9],
[84,22,14,11],
[84,22,14,12],
[84,22,15,0],
[84,22,15,1],
[84,22,15,2],
[84,22,15,3],
[84,22,15,5],
[84,22,15,6],
[84,22,15,7],
[84,22,15,8],
[84,22,15,11],
[84,22,15,12],
[84,22,15,13],
[84,22,15,14],
[84,22,16,0],
[84,22,16,1],
[84,22,16,2],
[84,22,16,3],
[84,22,16,5],
[84,22,16,6],
[84,22,16,7],
[84,22,16,8],
[84,22,16,9],
[84,22,16,11],
[84,22,16,12],
[84,22,16,13],
[84,22,16,15],
[84,22,17,0],
[84,22,17,1],
[84,22,17,2],
[84,22,17,3],
[84,22,17,5],
[84,22,17,6],
[84,22,17,7],
[84,22,17,8],
[84,22,17,9],
[84,22,17,11],
[84,22,17,12],
[84,22,17,13],
[84,22,17,15],
[84,22,17,16],
[84,22,18,0],
[84,22,18,1],
[84,22,18,2],
[84,22,18,3],
[84,22,18,5],
[84,22,18,6],
[84,22,18,7],
[84,22,18,8],
[84,22,18,11],
[84,22,18,14],
[84,22,18,16],
[84,22,18,17],
[84,22,19,0],
[84,22,19,1],
[84,22,19,2],
[84,22,19,3],
[84,22,19,5],
[84,22,19,6],
[84,22,19,7],
[84,22,19,8],
[84,22,19,9],
[84,22,19,12],
[84,22,19,15],
[84,22,19,16],
[84,22,19,17],
[84,22,19,18],
[84,22,21,0],
[84,22,21,1],
[84,22,21,2],
[84,22,21,9],
[84,22,21,11],
[84,22,21,12],
[84,22,21,13],
[84,22,21,14],
[84,22,21,15],
[84,22,21,16],
[84,22,21,17],
[84,22,21,18],
[84,22,21,19],
[84,23,3,0],
[84,23,3,1],
[84,23,3,2],
[84,23,5,0],
[84,23,5,1],
[84,23,5,2],
[84,23,6,0],
[84,23,6,1],
[84,23,6,2],
[84,23,7,0],
[84,23,7,1],
[84,23,7,2],
[84,23,7,3],
[84,23,7,5],
[84,23,7,6],
[84,23,8,0],
[84,23,8,1],
[84,23,8,2],
[84,23,8,7],
[84,23,9,0],
[84,23,9,1],
[84,23,9,2],
[84,23,9,3],
[84,23,9,5],
[84,23,9,6],
[84,23,9,7],
[84,23,9,8],
[84,23,10,0],
[84,23,10,1],
[84,23,10,2],
[84,23,10,3],
[84,23,10,5],
[84,23,10,6],
[84,23,10,7],
[84,23,10,8],
[84,23,10,9],
[84,23,11,0],
[84,23,11,1],
[84,23,11,2],
[84,23,11,3],
[84,23,11,5],
[84,23,11,6],
[84,23,11,7],
[84,23,11,8],
[84,23,11,9],
[84,23,11,10],
[84,23,12,0],
[84,23,12,1],
[84,23,12,2],
[84,23,12,3],
[84,23,12,5],
[84,23,12,6],
[84,23,12,7],
[84,23,12,8],
[84,23,12,9],
[84,23,12,10],
[84,23,13,0],
[84,23,13,1],
[84,23,13,2],
[84,23,13,3],
[84,23,13,5],
[84,23,13,6],
[84,23,13,7],
[84,23,13,8],
[84,23,13,9],
[84,23,13,10],
[84,23,13,11],
[84,23,13,12],
[84,23,14,0],
[84,23,14,1],
[84,23,14,2],
[84,23,14,3],
[84,23,14,5],
[84,23,14,6],
[84,23,14,7],
[84,23,14,8],
[84,23,14,9],
[84,23,14,10],
[84,23,14,11],
[84,23,14,12],
[84,23,15,0],
[84,23,15,1],
[84,23,15,2],
[84,23,15,3],
[84,23,15,5],
[84,23,15,6],
[84,23,15,7],
[84,23,15,8],
[84,23,15,10],
[84,23,15,11],
[84,23,15,12],
[84,23,15,13],
[84,23,16,0],
[84,23,16,1],
[84,23,16,2],
[84,23,16,3],
[84,23,16,5],
[84,23,16,6],
[84,23,16,7],
[84,23,16,8],
[84,23,16,9],
[84,23,16,10],
[84,23,16,11],
[84,23,16,14],
[84,23,16,15],
[84,23,17,0],
[84,23,17,1],
[84,23,17,2],
[84,23,17,3],
[84,23,17,5],
[84,23,17,6],
[84,23,17,7],
[84,23,17,8],
[84,23,17,9],
[84,23,17,10],
[84,23,17,11],
[84,23,17,14],
[84,23,17,15],
[84,23,17,16],
[84,23,18,0],
[84,23,18,1],
[84,23,18,2],
[84,23,18,3],
[84,23,18,5],
[84,23,18,6],
[84,23,18,7],
[84,23,18,8],
[84,23,18,12],
[84,23,18,13],
[84,23,18,14],
[84,23,18,16],
[84,23,18,17],
[84,23,19,0],
[84,23,19,1],
[84,23,19,2],
[84,23,19,3],
[84,23,19,5],
[84,23,19,6],
[84,23,19,7],
[84,23,19,10],
[84,23,19,11],
[84,23,19,12],
[84,23,19,15],
[84,23,19,16],
[84,23,19,17],
[84,23,19,18],
[84,23,20,0],
[84,23,20,1],
[84,23,20,2],
[84,23,20,3],
[84,23,20,5],
[84,23,20,8],
[84,23,20,9],
[84,23,20,11],
[84,23,20,12],
[84,23,20,13],
[84,23,20,14],
[84,23,20,15],
[84,23,20,16],
[84,23,20,17],
[84,23,20,18],
[84,23,20,19],
[84,23,21,0],
[84,23,21,1],
[84,23,21,2],
[84,23,21,7],
[84,23,21,9],
[84,23,21,10],
[84,23,21,11],
[84,23,21,12],
[84,23,21,13],
[84,23,21,14],
[84,23,21,15],
[84,23,21,16],
[84,23,21,17],
[84,23,21,18],
[84,23,21,19],
[84,23,21,20],
[84,23,22,0],
[84,23,22,1],
[84,23,22,2],
[84,23,22,5],
[84,23,22,6],
[84,23,22,7],
[84,23,22,8],
[84,23,22,9],
[84,23,22,11],
[84,23,22,12],
[84,23,22,13],
[84,23,22,14],
[84,23,22,15],
[84,23,22,16],
[84,23,22,17],
[84,23,22,18],
[84,23,22,19],
[84,23,22,21],
[84,24,3,0],
[84,24,3,1],
[84,24,3,2],
[84,24,5,0],
[84,24,5,1],
[84,24,5,2],
[84,24,6,0],
[84,24,6,1],
[84,24,6,2],
[84,24,7,0],
[84,24,7,1],
[84,24,7,2],
[84,24,7,3],
[84,24,7,5],
[84,24,7,6],
[84,24,8,0],
[84,24,8,1],
[84,24,8,2],
[84,24,8,7],
[84,24,9,0],
[84,24,9,1],
[84,24,9,2],
[84,24,9,3],
[84,24,9,5],
[84,24,9,6],
[84,24,9,7],
[84,24,9,8],
[84,24,10,0],
[84,24,10,1],
[84,24,10,2],
[84,24,10,3],
[84,24,10,5],
[84,24,10,6],
[84,24,10,7],
[84,24,10,8],
[84,24,10,9],
[84,24,11,0],
[84,24,11,1],
[84,24,11,2],
[84,24,11,3],
[84,24,11,5],
[84,24,11,6],
[84,24,11,7],
[84,24,11,8],
[84,24,11,9],
[84,24,11,10],
[84,24,12,0],
[84,24,12,1],
[84,24,12,2],
[84,24,12,3],
[84,24,12,5],
[84,24,12,6],
[84,24,12,7],
[84,24,12,8],
[84,24,12,9],
[84,24,12,10],
[84,24,13,0],
[84,24,13,1],
[84,24,13,2],
[84,24,13,3],
[84,24,13,5],
[84,24,13,6],
[84,24,13,7],
[84,24,13,8],
[84,24,13,9],
[84,24,13,10],
[84,24,13,11],
[84,24,13,12],
[84,24,14,0],
[84,24,14,1],
[84,24,14,2],
[84,24,14,3],
[84,24,14,5],
[84,24,14,6],
[84,24,14,7],
[84,24,14,8],
[84,24,14,9],
[84,24,14,10],
[84,24,14,11],
[84,24,14,12],
[84,24,15,0],
[84,24,15,1],
[84,24,15,2],
[84,24,15,3],
[84,24,15,5],
[84,24,15,6],
[84,24,15,7],
[84,24,15,8],
[84,24,15,10],
[84,24,15,11],
[84,24,15,12],
[84,24,15,13],
[84,24,17,0],
[84,24,17,1],
[84,24,17,2],
[84,24,17,3],
[84,24,17,5],
[84,24,17,6],
[84,24,17,7],
[84,24,17,8],
[84,24,17,9],
[84,24,17,10],
[84,24,17,11],
[84,24,17,14],
[84,24,17,15],
[84,24,18,0],
[84,24,18,1],
[84,24,18,2],
[84,24,18,3],
[84,24,18,5],
[84,24,18,6],
[84,24,18,7],
[84,24,18,8],
[84,24,18,12],
[84,24,18,13],
[84,24,18,14],
[84,24,18,17],
[84,24,19,0],
[84,24,19,1],
[84,24,19,2],
[84,24,19,3],
[84,24,19,5],
[84,24,19,6],
[84,24,19,7],
[84,24,19,10],
[84,24,19,11],
[84,24,19,12],
[84,24,19,15],
[84,24,19,17],
[84,24,19,18],
[84,24,20,0],
[84,24,20,1],
[84,24,20,2],
[84,24,20,3],
[84,24,20,5],
[84,24,20,8],
[84,24,20,9],
[84,24,20,11],
[84,24,20,12],
[84,24,20,13],
[84,24,20,14],
[84,24,20,15],
[84,24,20,17],
[84,24,20,18],
[84,24,20,19],
[84,24,21,0],
[84,24,21,1],
[84,24,21,2],
[84,24,21,7],
[84,24,21,9],
[84,24,21,10],
[84,24,21,11],
[84,24,21,12],
[84,24,21,13],
[84,24,21,14],
[84,24,21,15],
[84,24,21,17],
[84,24,21,18],
[84,24,21,19],
[84,24,21,20],
[84,24,22,0],
[84,24,22,1],
[84,24,22,2],
[84,24,22,5],
[84,24,22,6],
[84,24,22,7],
[84,24,22,8],
[84,24,22,9],
[84,24,22,11],
[84,24,22,12],
[84,24,22,13],
[84,24,22,14],
[84,24,22,15],
[84,24,22,17],
[84,24,22,18],
[84,24,22,19],
[84,24,22,21],
[84,24,23,3],
[84,24,23,5],
[84,24,23,6],
[84,24,23,7],
[84,24,23,8],
[84,24,23,9],
[84,24,23,10],
[84,24,23,11],
[84,24,23,12],
[84,24,23,13],
[84,24,23,14],
[84,24,23,15],
[84,24,23,17],
[84,24,23,18],
[84,24,23,19],
[84,24,23,20],
[84,25,3,0],
[84,25,3,1],
[84,25,3,2],
[84,25,5,0],
[84,25,5,1],
[84,25,5,2],
[84,25,6,0],
[84,25,6,1],
[84,25,6,2],
[84,25,8,0],
[84,25,8,1],
[84,25,8,2],
[84,25,9,0],
[84,25,9,1],
[84,25,9,2],
[84,25,9,3],
[84,25,9,5],
[84,25,9,6],
[84,25,9,8],
[84,25,10,0],
[84,25,10,1],
[84,25,10,2],
[84,25,10,3],
[84,25,10,5],
[84,25,10,6],
[84,25,10,8],
[84,25,10,9],
[84,25,11,0],
[84,25,11,1],
[84,25,11,2],
[84,25,11,3],
[84,25,11,5],
[84,25,11,6],
[84,25,11,8],
[84,25,11,9],
[84,25,11,10],
[84,25,12,0],
[84,25,12,1],
[84,25,12,2],
[84,25,12,3],
[84,25,12,5],
[84,25,12,6],
[84,25,12,8],
[84,25,12,9],
[84,25,12,10],
[84,25,13,0],
[84,25,13,1],
[84,25,13,2],
[84,25,13,3],
[84,25,13,5],
[84,25,13,6],
[84,25,13,8],
[84,25,13,9],
[84,25,13,10],
[84,25,13,11],
[84,25,13,12],
[84,25,14,0],
[84,25,14,1],
[84,25,14,2],
[84,25,14,3],
[84,25,14,5],
[84,25,14,6],
[84,25,14,8],
[84,25,14,9],
[84,25,14,10],
[84,25,14,11],
[84,25,15,0],
[84,25,15,1],
[84,25,15,2],
[84,25,15,3],
[84,25,15,5],
[84,25,15,6],
[84,25,15,8],
[84,25,15,12],
[84,25,15,13],
[84,25,15,14],
[84,25,16,0],
[84,25,16,1],
[84,25,16,2],
[84,25,16,3],
[84,25,16,5],
[84,25,16,6],
[84,25,16,10],
[84,25,16,11],
[84,25,16,12],
[84,25,16,13],
[84,25,16,14],
[84,25,16,15],
[84,25,17,0],
[84,25,17,1],
[84,25,17,2],
[84,25,17,3],
[84,25,17,5],
[84,25,17,6],
[84,25,17,10],
[84,25,17,11],
[84,25,17,12],
[84,25,17,13],
[84,25,17,14],
[84,25,17,15],
[84,25,17,16],
[84,25,18,0],
[84,25,18,1],
[84,25,18,2],
[84,25,18,3],
[84,25,18,5],
[84,25,18,8],
[84,25,18,10],
[84,25,18,11],
[84,25,18,12],
[84,25,18,13],
[84,25,18,14],
[84,25,18,16],
[84,25,18,17],
[84,25,19,0],
[84,25,19,1],
[84,25,19,2],
[84,25,19,3],
[84,25,19,6],
[84,25,19,8],
[84,25,19,9],
[84,25,19,10],
[84,25,19,11],
[84,25,19,12],
[84,25,19,15],
[84,25,19,16],
[84,25,19,17],
[84,25,19,18],
[84,25,20,0],
[84,25,20,1],
[84,25,20,2],
[84,25,20,5],
[84,25,20,6],
[84,25,20,8],
[84,25,20,9],
[84,25,20,11],
[84,25,20,12],
[84,25,20,13],
[84,25,20,14],
[84,25,20,15],
[84,25,20,16],
[84,25,20,17],
[84,25,20,18],
[84,25,20,19],
[84,25,21,9],
[84,25,21,10],
[84,25,21,11],
[84,25,21,12],
[84,25,21,13],
[84,25,21,14],
[84,25,21,15],
[84,25,21,16],
[84,25,21,17],
[84,25,21,18],
[84,25,21,19],
[84,25,21,20],
[84,25,22,0],
[84,25,22,1],
[84,25,22,2],
[84,25,22,3],
[84,25,22,5],
[84,25,22,6],
[84,25,22,8],
[84,25,22,9],
[84,25,22,11],
[84,25,22,12],
[84,25,22,13],
[84,25,22,14],
[84,25,22,15],
[84,25,22,16],
[84,25,22,17],
[84,25,22,18],
[84,25,22,19],
[84,25,23,0],
[84,25,23,1],
[84,25,23,2],
[84,25,23,3],
[84,25,23,5],
[84,25,23,6],
[84,25,23,8],
[84,25,23,9],
[84,25,23,10],
[84,25,23,11],
[84,25,23,12],
[84,25,23,13],
[84,25,23,14],
[84,25,23,15],
[84,25,23,16],
[84,25,23,17],
[84,25,23,18],
[84,25,24,0],
[84,25,24,1],
[84,25,24,2],
[84,25,24,3],
[84,25,24,5],
[84,25,24,6],
[84,25,24,8],
[84,25,24,9],
[84,25,24,10],
[84,25,24,11],
[84,25,24,12],
[84,25,24,13],
[84,25,24,14],
[84,25,24,15],
[84,25,24,17],
[84,25,24,18],
[84,26,3,0],
[84,26,3,1],
[84,26,3,2],
[84,26,5,0],
[84,26,5,1],
[84,26,5,2],
[84,26,6,0],
[84,26,6,1],
[84,26,6,2],
[84,26,7,0],
[84,26,7,1],
[84,26,7,2],
[84,26,7,3],
[84,26,7,5],
[84,26,7,6],
[84,26,8,0],
[84,26,8,1],
[84,26,8,2],
[84,26,8,7],
[84,26,10,0],
[84,26,10,1],
[84,26,10,2],
[84,26,10,3],
[84,26,10,5],
[84,26,10,6],
[84,26,10,7],
[84,26,10,8],
[84,26,11,0],
[84,26,11,1],
[84,26,11,2],
[84,26,11,3],
[84,26,11,5],
[84,26,11,6],
[84,26,11,7],
[84,26,11,8],
[84,26,11,10],
[84,26,12,0],
[84,26,12,1],
[84,26,12,2],
[84,26,12,3],
[84,26,12,5],
[84,26,12,6],
[84,26,12,7],
[84,26,12,8],
[84,26,12,10],
[84,26,13,0],
[84,26,13,1],
[84,26,13,2],
[84,26,13,3],
[84,26,13,5],
[84,26,13,6],
[84,26,13,7],
[84,26,13,8],
[84,26,13,10],
[84,26,13,11],
[84,26,14,0],
[84,26,14,1],
[84,26,14,2],
[84,26,14,3],
[84,26,14,5],
[84,26,14,6],
[84,26,14,7],
[84,26,14,8],
[84,26,14,12],
[84,26,16,0],
[84,26,16,1],
[84,26,16,2],
[84,26,16,3],
[84,26,16,5],
[84,26,16,8],
[84,26,16,10],
[84,26,16,11],
[84,26,16,12],
[84,26,16,13],
[84,26,16,14],
[84,26,17,0],
[84,26,17,1],
[84,26,17,2],
[84,26,17,3],
[84,26,17,5],
[84,26,17,8],
[84,26,17,10],
[84,26,17,11],
[84,26,17,12],
[84,26,17,13],
[84,26,17,14],
[84,26,17,16],
[84,26,19,0],
[84,26,19,1],
[84,26,19,2],
[84,26,19,5],
[84,26,19,6],
[84,26,19,7],
[84,26,19,8],
[84,26,19,10],
[84,26,19,11],
[84,26,19,12],
[84,26,19,16],
[84,26,19,17],
[84,26,20,3],
[84,26,20,5],
[84,26,20,6],
[84,26,20,7],
[84,26,20,8],
[84,26,20,11],
[84,26,20,12],
[84,26,20,13],
[84,26,20,14],
[84,26,20,16],
[84,26,20,17],
[84,26,20,19],
[84,26,21,0],
[84,26,21,1],
[84,26,21,2],
[84,26,21,7],
[84,26,21,10],
[84,26,21,11],
[84,26,21,12],
[84,26,21,13],
[84,26,21,14],
[84,26,21,16],
[84,26,21,17],
[84,26,21,19],
[84,26,22,0],
[84,26,22,1],
[84,26,22,2],
[84,26,22,3],
[84,26,22,5],
[84,26,22,6],
[84,26,22,7],
[84,26,22,8],
[84,26,22,11],
[84,26,22,12],
[84,26,22,13],
[84,26,22,14],
[84,26,22,16],
[84,26,22,17],
[84,26,23,0],
[84,26,23,1],
[84,26,23,2],
[84,26,23,3],
[84,26,23,5],
[84,26,23,6],
[84,26,23,7],
[84,26,23,8],
[84,26,23,10],
[84,26,23,11],
[84,26,23,12],
[84,26,23,13],
[84,26,23,14],
[84,26,23,16],
[84,26,23,17],
[84,26,24,0],
[84,26,24,1],
[84,26,24,2],
[84,26,24,3],
[84,26,24,5],
[84,26,24,6],
[84,26,24,7],
[84,26,24,8],
[84,26,24,10],
[84,26,24,11],
[84,26,24,12],
[84,26,24,13],
[84,26,24,14],
[84,26,24,17],
[84,26,25,0],
[84,26,25,1],
[84,26,25,2],
[84,26,25,3],
[84,26,25,5],
[84,26,25,6],
[84,26,25,8],
[84,26,25,10],
[84,26,25,11],
[84,26,25,12],
[84,26,25,13],
[84,26,25,14],
[84,27,3,0],
[84,27,3,1],
[84,27,3,2],
[84,27,5,0],
[84,27,5,1],
[84,27,5,2],
[84,27,6,0],
[84,27,6,1],
[84,27,6,2],
[84,27,7,0],
[84,27,7,1],
[84,27,7,2],
[84,27,7,3],
[84,27,7,5],
[84,27,7,6],
[84,27,8,0],
[84,27,8,1],
[84,27,8,2],
[84,27,8,7],
[84,27,9,0],
[84,27,9,1],
[84,27,9,2],
[84,27,9,3],
[84,27,9,5],
[84,27,9,6],
[84,27,9,7],
[84,27,9,8],
[84,27,11,0],
[84,27,11,1],
[84,27,11,2],
[84,27,11,3],
[84,27,11,5],
[84,27,11,6],
[84,27,11,7],
[84,27,11,8],
[84,27,11,9],
[84,27,12,0],
[84,27,12,1],
[84,27,12,2],
[84,27,12,3],
[84,27,12,5],
[84,27,12,6],
[84,27,12,7],
[84,27,12,8],
[84,27,12,9],
[84,27,13,0],
[84,27,13,1],
[84,27,13,2],
[84,27,13,3],
[84,27,13,5],
[84,27,13,6],
[84,27,13,7],
[84,27,13,8],
[84,27,13,9],
[84,27,13,12],
[84,27,14,0],
[84,27,14,1],
[84,27,14,2],
[84,27,14,3],
[84,27,14,5],
[84,27,14,6],
[84,27,14,7],
[84,27,14,11],
[84,27,14,12],
[84,27,15,0],
[84,27,15,1],
[84,27,15,2],
[84,27,15,3],
[84,27,15,5],
[84,27,15,8],
[84,27,15,11],
[84,27,15,12],
[84,27,15,13],
[84,27,15,14],
[84,27,16,0],
[84,27,16,1],
[84,27,16,2],
[84,27,16,3],
[84,27,16,6],
[84,27,16,7],
[84,27,16,8],
[84,27,16,9],
[84,27,16,11],
[84,27,16,12],
[84,27,16,13],
[84,27,16,14],
[84,27,16,15],
[84,27,17,0],
[84,27,17,1],
[84,27,17,2],
[84,27,17,3],
[84,27,17,6],
[84,27,17,7],
[84,27,17,8],
[84,27,17,9],
[84,27,17,11],
[84,27,17,12],
[84,27,17,13],
[84,27,17,14],
[84,27,17,15],
[84,27,17,16],
[84,27,18,0],
[84,27,18,1],
[84,27,18,2],
[84,27,18,5],
[84,27,18,6],
[84,27,18,7],
[84,27,18,8],
[84,27,18,11],
[84,27,18,12],
[84,27,18,13],
[84,27,18,14],
[84,27,18,16],
[84,27,18,17],
[84,27,19,3],
[84,27,19,5],
[84,27,19,6],
[84,27,19,7],
[84,27,19,8],
[84,27,19,9],
[84,27,19,11],
[84,27,19,12],
[84,27,19,15],
[84,27,19,16],
[84,27,19,17],
[84,27,19,18],
[84,27,21,0],
[84,27,21,1],
[84,27,21,2],
[84,27,21,7],
[84,27,21,9],
[84,27,21,11],
[84,27,21,12],
[84,27,21,13],
[84,27,21,14],
[84,27,21,15],
[84,27,21,16],
[84,27,21,17],
[84,27,21,18],
[84,27,23,0],
[84,27,23,1],
[84,27,23,2],
[84,27,23,3],
[84,27,23,5],
[84,27,23,6],
[84,27,23,7],
[84,27,23,8],
[84,27,23,9],
[84,27,23,11],
[84,27,23,12],
[84,27,23,13],
[84,27,23,14],
[84,27,23,15],
[84,27,24,0],
[84,27,24,1],
[84,27,24,2],
[84,27,24,3],
[84,27,24,5],
[84,27,24,6],
[84,27,24,7],
[84,27,24,8],
[84,27,24,9],
[84,27,24,11],
[84,27,24,12],
[84,27,24,13],
[84,27,24,14],
[84,27,24,15],
[84,27,25,0],
[84,27,25,1],
[84,27,25,2],
[84,27,25,3],
[84,27,25,5],
[84,27,25,6],
[84,27,25,8],
[84,27,25,9],
[84,27,25,11],
[84,27,25,12],
[84,27,25,13],
[84,27,26,0],
[84,27,26,1],
[84,27,26,2],
[84,27,26,3],
[84,27,26,5],
[84,27,26,6],
[84,27,26,7],
[84,27,26,8],
[84,27,26,11],
[84,28,3,0],
[84,28,3,1],
[84,28,3,2],
[84,28,5,0],
[84,28,5,1],
[84,28,5,2],
[84,28,6,0],
[84,28,6,1],
[84,28,6,2],
[84,28,8,0],
[84,28,8,1],
[84,28,8,2],
[84,28,9,0],
[84,28,9,1],
[84,28,9,2],
[84,28,9,3],
[84,28,9,5],
[84,28,9,6],
[84,28,9,8],
[84,28,10,0],
[84,28,10,1],
[84,28,10,2],
[84,28,10,3],
[84,28,10,5],
[84,28,10,6],
[84,28,10,8],
[84,28,10,9],
[84,28,11,0],
[84,28,11,1],
[84,28,11,2],
[84,28,11,3],
[84,28,11,5],
[84,28,11,6],
[84,28,11,8],
[84,28,11,9],
[84,28,12,0],
[84,28,12,1],
[84,28,12,2],
[84,28,12,3],
[84,28,12,5],
[84,28,12,6],
[84,28,12,10],
[84,28,13,0],
[84,28,13,1],
[84,28,13,2],
[84,28,13,3],
[84,28,13,5],
[84,28,13,6],
[84,28,13,10],
[84,28,13,11],
[84,28,13,12],
[84,28,14,0],
[84,28,14,1],
[84,28,14,2],
[84,28,14,3],
[84,28,14,5],
[84,28,14,8],
[84,28,14,9],
[84,28,14,10],
[84,28,14,11],
[84,28,14,12],
[84,28,15,0],
[84,28,15,1],
[84,28,15,2],
[84,28,15,3],
[84,28,15,6],
[84,28,15,8],
[84,28,15,10],
[84,28,15,11],
[84,28,15,12],
[84,28,15,13],
[84,28,15,14],
[84,28,16,0],
[84,28,16,1],
[84,28,16,2],
[84,28,16,5],
[84,28,16,6],
[84,28,16,8],
[84,28,16,9],
[84,28,16,10],
[84,28,16,11],
[84,28,16,12],
[84,28,16,13],
[84,28,16,14],
[84,28,16,15],
[84,28,17,0],
[84,28,17,1],
[84,28,17,2],
[84,28,17,5],
[84,28,17,6],
[84,28,17,8],
[84,28,17,9],
[84,28,17,10],
[84,28,17,11],
[84,28,17,12],
[84,28,17,13],
[84,28,17,14],
[84,28,17,15],
[84,28,17,16],
[84,28,18,3],
[84,28,18,5],
[84,28,18,6],
[84,28,18,8],
[84,28,18,10],
[84,28,18,11],
[84,28,18,12],
[84,28,18,13],
[84,28,18,14],
[84,28,18,16],
[84,28,18,17],
[84,28,19,0],
[84,28,19,1],
[84,28,19,2],
[84,28,19,3],
[84,28,19,5],
[84,28,19,6],
[84,28,19,8],
[84,28,19,9],
[84,28,19,10],
[84,28,19,11],
[84,28,19,12],
[84,28,19,15],
[84,28,19,16],
[84,28,19,17],
[84,28,19,18],
[84,28,20,0],
[84,28,20,1],
[84,28,20,2],
[84,28,20,3],
[84,28,20,5],
[84,28,20,6],
[84,28,20,8],
[84,28,20,9],
[84,28,20,11],
[84,28,20,12],
[84,28,20,13],
[84,28,20,14],
[84,28,20,15],
[84,28,20,16],
[84,28,20,17],
[84,28,20,18],
[84,28,21,0],
[84,28,21,1],
[84,28,21,2],
[84,28,21,9],
[84,28,21,10],
[84,28,21,11],
[84,28,21,12],
[84,28,21,13],
[84,28,21,14],
[84,28,21,15],
[84,28,21,16],
[84,28,21,17],
[84,28,22,0],
[84,28,22,1],
[84,28,22,2],
[84,28,22,3],
[84,28,22,5],
[84,28,22,6],
[84,28,22,8],
[84,28,22,9],
[84,28,22,11],
[84,28,22,12],
[84,28,22,13],
[84,28,22,14],
[84,28,22,15],
[84,28,23,0],
[84,28,23,1],
[84,28,23,2],
[84,28,23,3],
[84,28,23,5],
[84,28,23,6],
[84,28,23,8],
[84,28,23,9],
[84,28,23,10],
[84,28,23,11],
[84,28,23,12],
[84,28,23,13],
[84,28,23,14],
[84,28,24,0],
[84,28,24,1],
[84,28,24,2],
[84,28,24,3],
[84,28,24,5],
[84,28,24,6],
[84,28,24,8],
[84,28,24,9],
[84,28,24,10],
[84,28,24,11],
[84,28,24,12],
[84,28,24,13],
[84,28,24,14],
[84,28,26,0],
[84,28,26,1],
[84,28,26,2],
[84,28,26,3],
[84,28,26,5],
[84,28,26,6],
[84,28,26,8],
[84,28,27,0],
[84,28,27,1],
[84,28,27,2],
[84,28,27,3],
[84,28,27,5],
[84,28,27,6],
[84,29,3,0],
[84,29,3,1],
[84,29,3,2],
[84,29,5,0],
[84,29,5,1],
[84,29,5,2],
[84,29,6,0],
[84,29,6,1],
[84,29,6,2],
[84,29,7,0],
[84,29,7,1],
[84,29,7,2],
[84,29,7,3],
[84,29,7,5],
[84,29,7,6],
[84,29,8,0],
[84,29,8,1],
[84,29,8,2],
[84,29,8,7],
[84,29,9,0],
[84,29,9,1],
[84,29,9,2],
[84,29,9,3],
[84,29,9,5],
[84,29,9,6],
[84,29,9,7],
[84,29,9,8],
[84,29,10,0],
[84,29,10,1],
[84,29,10,2],
[84,29,10,3],
[84,29,10,5],
[84,29,10,6],
[84,29,10,7],
[84,29,13,0],
[84,29,13,1],
[84,29,13,2],
[84,29,13,3],
[84,29,13,5],
[84,29,13,8],
[84,29,13,9],
[84,29,13,10],
[84,29,14,0],
[84,29,14,1],
[84,29,14,2],
[84,29,14,3],
[84,29,14,6],
[84,29,14,7],
[84,29,14,8],
[84,29,14,9],
[84,29,14,10],
[84,29,15,0],
[84,29,15,1],
[84,29,15,2],
[84,29,15,5],
[84,29,15,6],
[84,29,15,7],
[84,29,15,8],
[84,29,15,10],
[84,29,15,13],
[84,29,15,14],
[84,29,16,3],
[84,29,16,5],
[84,29,16,6],
[84,29,16,7],
[84,29,16,8],
[84,29,16,9],
[84,29,16,10],
[84,29,16,13],
[84,29,16,14],
[84,29,16,15],
[84,29,17,3],
[84,29,17,5],
[84,29,17,6],
[84,29,17,7],
[84,29,17,8],
[84,29,17,9],
[84,29,17,10],
[84,29,17,13],
[84,29,17,14],
[84,29,17,15],
[84,29,17,16],
[84,29,18,0],
[84,29,18,1],
[84,29,18,2],
[84,29,18,3],
[84,29,18,5],
[84,29,18,6],
[84,29,18,7],
[84,29,18,8],
[84,29,18,10],
[84,29,18,13],
[84,29,18,14],
[84,29,18,16],
[84,29,18,17],
[84,29,19,0],
[84,29,19,1],
[84,29,19,2],
[84,29,19,3],
[84,29,19,5],
[84,29,19,6],
[84,29,19,7],
[84,29,19,8],
[84,29,19,9],
[84,29,19,10],
[84,29,19,15],
[84,29,19,16],
[84,29,19,17],
[84,29,19,18],
[84,29,20,0],
[84,29,20,1],
[84,29,20,2],
[84,29,20,3],
[84,29,20,5],
[84,29,20,6],
[84,29,20,7],
[84,29,20,8],
[84,29,20,9],
[84,29,20,13],
[84,29,20,14],
[84,29,20,15],
[84,29,20,16],
[84,29,20,17],
[84,29,21,0],
[84,29,21,1],
[84,29,21,2],
[84,29,21,7],
[84,29,21,9],
[84,29,21,10],
[84,29,21,13],
[84,29,21,14],
[84,29,21,15],
[84,29,22,0],
[84,29,22,1],
[84,29,22,2],
[84,29,22,3],
[84,29,22,5],
[84,29,22,6],
[84,29,22,7],
[84,29,22,8],
[84,29,22,9],
[84,29,22,13],
[84,29,22,14],
[84,29,23,0],
[84,29,23,1],
[84,29,23,2],
[84,29,23,3],
[84,29,23,5],
[84,29,23,6],
[84,29,23,7],
[84,29,23,8],
[84,29,23,9],
[84,29,23,10],
[84,29,23,13],
[84,29,24,0],
[84,29,24,1],
[84,29,24,2],
[84,29,24,3],
[84,29,24,5],
[84,29,24,6],
[84,29,24,7],
[84,29,24,8],
[84,29,24,9],
[84,29,24,10],
[84,29,24,13],
[84,29,25,0],
[84,29,25,1],
[84,29,25,2],
[84,29,25,3],
[84,29,25,5],
[84,29,25,6],
[84,29,25,8],
[84,29,25,9],
[84,29,26,0],
[84,29,26,1],
[84,29,26,2],
[84,29,26,3],
[84,29,26,5],
[84,29,26,6],
[84,29,26,7],
[84,29,27,0],
[84,29,27,1],
[84,29,27,2],
[84,29,27,3],
[84,29,27,5],
[84,29,28,0],
[84,29,28,1],
[84,29,28,2],
[84,29,28,3],
[84,30,3,0],
[84,30,3,1],
[84,30,3,2],
[84,30,5,0],
[84,30,5,1],
[84,30,5,2],
[84,30,6,0],
[84,30,6,1],
[84,30,6,2],
[84,30,7,0],
[84,30,7,1],
[84,30,7,2],
[84,30,7,3],
[84,30,7,5],
[84,30,7,6],
[84,30,8,0],
[84,30,8,1],
[84,30,8,2],
[84,30,8,7],
[84,30,9,0],
[84,30,9,1],
[84,30,9,2],
[84,30,9,3],
[84,30,9,5],
[84,30,9,6],
[84,30,9,7],
[84,30,10,0],
[84,30,10,1],
[84,30,10,2],
[84,30,10,3],
[84,30,10,5],
[84,30,10,8],
[84,30,10,9],
[84,30,11,0],
[84,30,11,1],
[84,30,11,2],
[84,30,11,3],
[84,30,11,5],
[84,30,11,8],
[84,30,11,9],
[84,30,11,10],
[84,30,12,0],
[84,30,12,1],
[84,30,12,2],
[84,30,12,3],
[84,30,12,6],
[84,30,12,7],
[84,30,12,8],
[84,30,12,9],
[84,30,12,10],
[84,30,13,0],
[84,30,13,1],
[84,30,13,2],
[84,30,13,3],
[84,30,13,6],
[84,30,13,7],
[84,30,13,8],
[84,30,13,9],
[84,30,13,10],
[84,30,13,11],
[84,30,13,12],
[84,30,14,0],
[84,30,14,1],
[84,30,14,2],
[84,30,14,5],
[84,30,14,6],
[84,30,14,7],
[84,30,14,8],
[84,30,14,9],
[84,30,14,10],
[84,30,14,11],
[84,30,14,12],
[84,30,15,3],
[84,30,15,5],
[84,30,15,6],
[84,30,15,7],
[84,30,15,8],
[84,30,15,10],
[84,30,15,11],
[84,30,15,12],
[84,30,15,13],
[84,30,15,14],
[84,30,16,0],
[84,30,16,1],
[84,30,16,2],
[84,30,16,3],
[84,30,16,5],
[84,30,16,6],
[84,30,16,7],
[84,30,16,8],
[84,30,16,9],
[84,30,16,10],
[84,30,16,11],
[84,30,16,12],
[84,30,16,13],
[84,30,16,14],
[84,30,16,15],
[84,30,17,0],
[84,30,17,1],
[84,30,17,2],
[84,30,17,3],
[84,30,17,5],
[84,30,17,6],
[84,30,17,7],
[84,30,17,8],
[84,30,17,9],
[84,30,17,10],
[84,30,17,11],
[84,30,17,12],
[84,30,17,13],
[84,30,17,14],
[84,30,17,15],
[84,30,17,16],
[84,30,18,0],
[84,30,18,1],
[84,30,18,2],
[84,30,18,3],
[84,30,18,5],
[84,30,18,6],
[84,30,18,7],
[84,30,18,8],
[84,30,18,10],
[84,30,18,11],
[84,30,18,12],
[84,30,18,13],
[84,30,18,14],
[84,30,18,16],
[84,30,18,17],
[84,30,19,0],
[84,30,19,1],
[84,30,19,2],
[84,30,19,3],
[84,30,19,5],
[84,30,19,6],
[84,30,19,7],
[84,30,19,8],
[84,30,19,9],
[84,30,19,10],
[84,30,19,11],
[84,30,19,12],
[84,30,19,15],
[84,30,19,16],
[84,30,19,17],
[84,30,20,0],
[84,30,20,1],
[84,30,20,2],
[84,30,20,3],
[84,30,20,5],
[84,30,20,6],
[84,30,20,7],
[84,30,20,8],
[84,30,20,9],
[84,30,20,11],
[84,30,20,12],
[84,30,20,13],
[84,30,20,14],
[84,30,20,15],
[84,30,21,0],
[84,30,21,1],
[84,30,21,2],
[84,30,21,7],
[84,30,21,9],
[84,30,21,10],
[84,30,21,11],
[84,30,21,12],
[84,30,21,13],
[84,30,21,14],
[84,30,22,0],
[84,30,22,1],
[84,30,22,2],
[84,30,22,3],
[84,30,22,5],
[84,30,22,6],
[84,30,22,7],
[84,30,22,8],
[84,30,22,9],
[84,30,22,11],
[84,30,22,12],
[84,30,22,13],
[84,30,24,0],
[84,30,24,1],
[84,30,24,2],
[84,30,24,3],
[84,30,24,5],
[84,30,24,6],
[84,30,24,7],
[84,30,24,8],
[84,30,24,9],
[84,30,24,10],
[84,30,24,11],
[84,30,25,0],
[84,30,25,1],
[84,30,25,2],
[84,30,25,3],
[84,30,25,5],
[84,30,25,6],
[84,30,26,0],
[84,30,26,1],
[84,30,26,2],
[84,30,26,3],
[84,30,26,5],
[84,30,27,0],
[84,30,27,1],
[84,30,27,2],
[84,30,27,3],
[84,30,28,0],
[84,30,28,1],
[84,30,28,2],
[84,31,3,0],
[84,31,3,1],
[84,31,3,2],
[84,31,5,0],
[84,31,5,1],
[84,31,5,2],
[84,31,6,0],
[84,31,6,1],
[84,31,6,2],
[84,31,7,0],
[84,31,7,1],
[84,31,7,2],
[84,31,7,3],
[84,31,7,5],
[84,31,7,6],
[84,31,8,0],
[84,31,8,1],
[84,31,8,2],
[84,31,9,0],
[84,31,9,1],
[84,31,9,2],
[84,31,9,3],
[84,31,9,5],
[84,31,9,8],
[84,31,10,0],
[84,31,10,1],
[84,31,10,2],
[84,31,10,3],
[84,31,10,6],
[84,31,10,7],
[84,31,10,8],
[84,31,10,9],
[84,31,11,0],
[84,31,11,1],
[84,31,11,2],
[84,31,11,3],
[84,31,11,6],
[84,31,11,7],
[84,31,11,8],
[84,31,11,9],
[84,31,11,10],
[84,31,12,0],
[84,31,12,1],
[84,31,12,2],
[84,31,12,5],
[84,31,12,6],
[84,31,12,7],
[84,31,12,8],
[84,31,12,9],
[84,31,12,10],
[84,31,13,0],
[84,31,13,1],
[84,31,13,2],
[84,31,13,5],
[84,31,13,6],
[84,31,13,7],
[84,31,13,8],
[84,31,13,9],
[84,31,13,10],
[84,31,13,11],
[84,31,13,12],
[84,31,14,3],
[84,31,14,5],
[84,31,14,6],
[84,31,14,7],
[84,31,14,8],
[84,31,14,9],
[84,31,14,10],
[84,31,14,11],
[84,31,14,12],
[84,31,15,0],
[84,31,15,1],
[84,31,15,2],
[84,31,15,3],
[84,31,15,5],
[84,31,15,6],
[84,31,15,7],
[84,31,15,8],
[84,31,15,10],
[84,31,15,11],
[84,31,15,12],
[84,31,15,13],
[84,31,15,14],
[84,31,16,0],
[84,31,16,1],
[84,31,16,2],
[84,31,16,3],
[84,31,16,5],
[84,31,16,6],
[84,31,16,7],
[84,31,16,8],
[84,31,16,9],
[84,31,16,10],
[84,31,16,11],
[84,31,16,12],
[84,31,16,13],
[84,31,16,14],
[84,31,16,15],
[84,31,17,0],
[84,31,17,1],
[84,31,17,2],
[84,31,17,3],
[84,31,17,5],
[84,31,17,6],
[84,31,17,7],
[84,31,17,8],
[84,31,17,9],
[84,31,17,10],
[84,31,17,11],
[84,31,17,12],
[84,31,17,13],
[84,31,17,14],
[84,31,17,15],
[84,31,17,16],
[84,31,18,0],
[84,31,18,1],
[84,31,18,2],
[84,31,18,3],
[84,31,18,5],
[84,31,18,6],
[84,31,18,7],
[84,31,18,8],
[84,31,18,10],
[84,31,18,11],
[84,31,18,12],
[84,31,18,13],
[84,31,18,14],
[84,31,18,16],
[84,31,18,17],
[84,31,19,0],
[84,31,19,1],
[84,31,19,2],
[84,31,19,3],
[84,31,19,5],
[84,31,19,6],
[84,31,19,7],
[84,31,19,8],
[84,31,19,9],
[84,31,19,10],
[84,31,19,11],
[84,31,19,12],
[84,31,19,15],
[84,31,20,0],
[84,31,20,1],
[84,31,20,2],
[84,31,20,3],
[84,31,20,5],
[84,31,20,6],
[84,31,20,7],
[84,31,20,8],
[84,31,20,9],
[84,31,20,11],
[84,31,20,12],
[84,31,20,13],
[84,31,20,14],
[84,31,21,0],
[84,31,21,1],
[84,31,21,2],
[84,31,21,7],
[84,31,21,9],
[84,31,21,10],
[84,31,21,11],
[84,31,21,12],
[84,31,21,13],
[84,31,22,0],
[84,31,22,1],
[84,31,22,2],
[84,31,22,3],
[84,31,22,5],
[84,31,22,6],
[84,31,22,7],
[84,31,22,8],
[84,31,22,9],
[84,31,22,11],
[84,31,24,0],
[84,31,24,1],
[84,31,24,2],
[84,31,24,3],
[84,31,24,5],
[84,31,24,6],
[84,31,24,7],
[84,31,24,8],
[84,31,24,9],
[84,31,25,0],
[84,31,25,1],
[84,31,25,2],
[84,31,25,3],
[84,31,25,5],
[84,31,26,0],
[84,31,26,1],
[84,31,26,2],
[84,31,26,3],
[84,31,27,0],
[84,31,27,1],
[84,31,27,2],
[84,32,3,0],
[84,32,3,1],
[84,32,3,2],
[84,32,5,0],
[84,32,5,1],
[84,32,5,2],
[84,32,6,0],
[84,32,6,1],
[84,32,6,2],
[84,32,7,0],
[84,32,7,1],
[84,32,7,2],
[84,32,7,3],
[84,32,7,5],
[84,32,8,0],
[84,32,8,1],
[84,32,8,2],
[84,32,8,7],
[84,32,9,0],
[84,32,9,1],
[84,32,9,2],
[84,32,9,3],
[84,32,9,6],
[84,32,9,7],
[84,32,9,8],
[84,32,10,0],
[84,32,10,1],
[84,32,10,2],
[84,32,10,5],
[84,32,10,6],
[84,32,10,7],
[84,32,10,8],
[84,32,10,9],
[84,32,13,3],
[84,32,13,5],
[84,32,13,6],
[84,32,13,7],
[84,32,13,8],
[84,32,13,9],
[84,32,13,10],
[84,32,14,0],
[84,32,14,1],
[84,32,14,2],
[84,32,14,3],
[84,32,14,5],
[84,32,14,6],
[84,32,14,7],
[84,32,14,8],
[84,32,14,9],
[84,32,14,10],
[84,32,15,0],
[84,32,15,1],
[84,32,15,2],
[84,32,15,3],
[84,32,15,5],
[84,32,15,6],
[84,32,15,7],
[84,32,15,8],
[84,32,15,10],
[84,32,15,13],
[84,32,15,14],
[84,32,16,0],
[84,32,16,1],
[84,32,16,2],
[84,32,16,3],
[84,32,16,5],
[84,32,16,6],
[84,32,16,7],
[84,32,16,8],
[84,32,16,9],
[84,32,16,10],
[84,32,16,13],
[84,32,16,14],
[84,32,16,15],
[84,32,17,0],
[84,32,17,1],
[84,32,17,2],
[84,32,17,3],
[84,32,17,5],
[84,32,17,6],
[84,32,17,7],
[84,32,17,8],
[84,32,17,9],
[84,32,17,10],
[84,32,17,13],
[84,32,17,14],
[84,32,17,15],
[84,32,17,16],
[84,32,18,0],
[84,32,18,1],
[84,32,18,2],
[84,32,18,3],
[84,32,18,5],
[84,32,18,6],
[84,32,18,7],
[84,32,18,8],
[84,32,18,10],
[84,32,18,13],
[84,32,18,14],
[84,32,19,0],
[84,32,19,1],
[84,32,19,2],
[84,32,19,3],
[84,32,19,5],
[84,32,19,6],
[84,32,19,7],
[84,32,19,8],
[84,32,19,9],
[84,32,19,10],
[84,32,20,0],
[84,32,20,1],
[84,32,20,2],
[84,32,20,3],
[84,32,20,5],
[84,32,20,6],
[84,32,20,7],
[84,32,20,8],
[84,32,20,9],
[84,32,20,13],
[84,32,21,0],
[84,32,21,1],
[84,32,21,2],
[84,32,21,7],
[84,32,21,9],
[84,32,21,10],
[84,32,22,0],
[84,32,22,1],
[84,32,22,2],
[84,32,22,3],
[84,32,22,5],
[84,32,22,6],
[84,32,22,7],
[84,32,22,8],
[84,32,22,9],
[84,32,23,0],
[84,32,23,1],
[84,32,23,2],
[84,32,23,3],
[84,32,23,5],
[84,32,23,6],
[84,32,23,7],
[84,32,24,0],
[84,32,24,1],
[84,32,24,2],
[84,32,24,3],
[84,32,24,5],
[84,32,24,6],
[84,32,24,7],
[84,32,25,0],
[84,32,25,1],
[84,32,25,2],
[84,32,25,3],
[84,32,26,0],
[84,32,26,1],
[84,32,26,2],
[84,33,3,0],
[84,33,3,1],
[84,33,3,2],
[84,33,5,0],
[84,33,5,1],
[84,33,5,2],
[84,33,6,0],
[84,33,6,1],
[84,33,6,2],
[84,33,7,0],
[84,33,7,1],
[84,33,7,2],
[84,33,7,3],
[84,33,7,6],
[84,33,8,0],
[84,33,8,1],
[84,33,8,2],
[84,33,8,7],
[84,33,9,0],
[84,33,9,1],
[84,33,9,2],
[84,33,9,5],
[84,33,9,6],
[84,33,9,7],
[84,33,9,8],
[84,33,10,3],
[84,33,10,5],
[84,33,10,6],
[84,33,10,7],
[84,33,10,8],
[84,33,10,9],
[84,33,13,0],
[84,33,13,1],
[84,33,13,2],
[84,33,13,3],
[84,33,13,5],
[84,33,13,6],
[84,33,13,7],
[84,33,13,8],
[84,33,13,9],
[84,33,13,10],
[84,33,14,0],
[84,33,14,1],
[84,33,14,2],
[84,33,14,3],
[84,33,14,5],
[84,33,14,6],
[84,33,14,7],
[84,33,14,8],
[84,33,14,9],
[84,33,14,10],
[84,33,15,0],
[84,33,15,1],
[84,33,15,2],
[84,33,15,3],
[84,33,15,5],
[84,33,15,6],
[84,33,15,7],
[84,33,15,8],
[84,33,15,10],
[84,33,15,13],
[84,33,15,14],
[84,33,16,0],
[84,33,16,1],
[84,33,16,2],
[84,33,16,3],
[84,33,16,5],
[84,33,16,6],
[84,33,16,7],
[84,33,16,8],
[84,33,16,9],
[84,33,16,10],
[84,33,16,13],
[84,33,16,14],
[84,33,16,15],
[84,33,17,0],
[84,33,17,1],
[84,33,17,2],
[84,33,17,3],
[84,33,17,5],
[84,33,17,6],
[84,33,17,7],
[84,33,17,8],
[84,33,17,9],
[84,33,17,10],
[84,33,17,13],
[84,33,17,14],
[84,33,17,15],
[84,33,18,0],
[84,33,18,1],
[84,33,18,2],
[84,33,18,3],
[84,33,18,5],
[84,33,18,6],
[84,33,18,7],
[84,33,18,8],
[84,33,18,10],
[84,33,18,13],
[84,33,18,14],
[84,33,19,0],
[84,33,19,1],
[84,33,19,2],
[84,33,19,3],
[84,33,19,5],
[84,33,19,6],
[84,33,19,7],
[84,33,19,8],
[84,33,19,9],
[84,33,19,10],
[84,33,20,0],
[84,33,20,1],
[84,33,20,2],
[84,33,20,3],
[84,33,20,5],
[84,33,20,6],
[84,33,20,7],
[84,33,20,8],
[84,33,20,9],
[84,33,21,0],
[84,33,21,1],
[84,33,21,2],
[84,33,21,7],
[84,33,21,9],
[84,33,22,0],
[84,33,22,1],
[84,33,22,2],
[84,33,22,3],
[84,33,22,5],
[84,33,22,6],
[84,33,22,7],
[84,33,23,0],
[84,33,23,1],
[84,33,23,2],
[84,33,23,3],
[84,33,23,5],
[84,33,24,0],
[84,33,24,1],
[84,33,24,2],
[84,33,24,3],
[84,33,24,5],
[84,33,25,0],
[84,33,25,1],
[84,33,25,2],
[84,34,3,0],
[84,34,3,1],
[84,34,3,2],
[84,34,5,0],
[84,34,5,1],
[84,34,5,2],
[84,34,6,0],
[84,34,6,1],
[84,34,6,2],
[84,34,9,3],
[84,34,9,5],
[84,34,9,6],
[84,34,9,8],
[84,34,10,0],
[84,34,10,1],
[84,34,10,2],
[84,34,10,3],
[84,34,10,5],
[84,34,10,6],
[84,34,10,8],
[84,34,10,9],
[84,34,11,0],
[84,34,11,1],
[84,34,11,2],
[84,34,11,3],
[84,34,11,5],
[84,34,11,6],
[84,34,11,8],
[84,34,11,9],
[84,34,11,10],
[84,34,12,0],
[84,34,12,1],
[84,34,12,2],
[84,34,12,3],
[84,34,12,5],
[84,34,12,6],
[84,34,12,8],
[84,34,12,9],
[84,34,12,10],
[84,34,13,0],
[84,34,13,1],
[84,34,13,2],
[84,34,13,3],
[84,34,13,5],
[84,34,13,6],
[84,34,13,8],
[84,34,13,9],
[84,34,13,10],
[84,34,13,11],
[84,34,13,12],
[84,34,14,0],
[84,34,14,1],
[84,34,14,2],
[84,34,14,3],
[84,34,14,5],
[84,34,14,6],
[84,34,14,8],
[84,34,14,9],
[84,34,14,10],
[84,34,14,11],
[84,34,14,12],
[84,34,15,0],
[84,34,15,1],
[84,34,15,2],
[84,34,15,3],
[84,34,15,5],
[84,34,15,6],
[84,34,15,8],
[84,34,15,10],
[84,34,15,11],
[84,34,15,12],
[84,34,15,13],
[84,34,15,14],
[84,34,16,0],
[84,34,16,1],
[84,34,16,2],
[84,34,16,3],
[84,34,16,5],
[84,34,16,6],
[84,34,16,8],
[84,34,16,9],
[84,34,16,10],
[84,34,16,11],
[84,34,16,12],
[84,34,16,13],
[84,34,16,14],
[84,34,17,0],
[84,34,17,1],
[84,34,17,2],
[84,34,17,3],
[84,34,17,5],
[84,34,17,6],
[84,34,17,8],
[84,34,17,9],
[84,34,17,10],
[84,34,17,11],
[84,34,17,12],
[84,34,17,13],
[84,34,17,14],
[84,34,18,0],
[84,34,18,1],
[84,34,18,2],
[84,34,18,3],
[84,34,18,5],
[84,34,18,6],
[84,34,18,8],
[84,34,18,10],
[84,34,18,11],
[84,34,18,12],
[84,34,18,13],
[84,34,19,0],
[84,34,19,1],
[84,34,19,2],
[84,34,19,3],
[84,34,19,5],
[84,34,19,6],
[84,34,19,8],
[84,34,19,9],
[84,34,19,10],
[84,34,19,11],
[84,34,20,0],
[84,34,20,1],
[84,34,20,2],
[84,34,20,3],
[84,34,20,5],
[84,34,20,6],
[84,34,20,8],
[84,34,20,9],
[84,34,21,0],
[84,34,21,1],
[84,34,21,2],
[84,34,22,0],
[84,34,22,1],
[84,34,22,2],
[84,34,22,3],
[84,34,22,5],
[84,34,23,0],
[84,34,23,1],
[84,34,23,2],
[84,34,23,3],
[84,34,24,0],
[84,34,24,1],
[84,34,24,2],
[84,34,24,3],
[84,35,3,0],
[84,35,3,1],
[84,35,3,2],
[84,35,5,0],
[84,35,5,1],
[84,35,5,2],
[84,35,7,3],
[84,35,7,5],
[84,35,7,6],
[84,35,8,0],
[84,35,8,1],
[84,35,8,2],
[84,35,8,7],
[84,35,10,0],
[84,35,10,1],
[84,35,10,2],
[84,35,10,3],
[84,35,10,5],
[84,35,10,6],
[84,35,10,7],
[84,35,10,8],
[84,35,11,0],
[84,35,11,1],
[84,35,11,2],
[84,35,11,3],
[84,35,11,5],
[84,35,11,6],
[84,35,11,7],
[84,35,11,8],
[84,35,11,10],
[84,35,12,0],
[84,35,12,1],
[84,35,12,2],
[84,35,12,3],
[84,35,12,5],
[84,35,12,6],
[84,35,12,7],
[84,35,12,8],
[84,35,12,10],
[84,35,13,0],
[84,35,13,1],
[84,35,13,2],
[84,35,13,3],
[84,35,13,5],
[84,35,13,6],
[84,35,13,7],
[84,35,13,8],
[84,35,13,10],
[84,35,13,11],
[84,35,13,12],
[84,35,14,0],
[84,35,14,1],
[84,35,14,2],
[84,35,14,3],
[84,35,14,5],
[84,35,14,6],
[84,35,14,7],
[84,35,14,8],
[84,35,14,10],
[84,35,14,11],
[84,35,14,12],
[84,35,16,0],
[84,35,16,1],
[84,35,16,2],
[84,35,16,3],
[84,35,16,5],
[84,35,16,6],
[84,35,16,7],
[84,35,16,8],
[84,35,16,10],
[84,35,16,11],
[84,35,16,12],
[84,35,16,13],
[84,35,17,0],
[84,35,17,1],
[84,35,17,2],
[84,35,17,3],
[84,35,17,5],
[84,35,17,6],
[84,35,17,7],
[84,35,17,8],
[84,35,17,10],
[84,35,17,11],
[84,35,17,12],
[84,35,17,13],
[84,35,19,0],
[84,35,19,1],
[84,35,19,2],
[84,35,19,3],
[84,35,19,5],
[84,35,19,6],
[84,35,19,7],
[84,35,19,8],
[84,35,20,0],
[84,35,20,1],
[84,35,20,2],
[84,35,20,3],
[84,35,20,5],
[84,35,20,6],
[84,35,20,7],
[84,35,21,0],
[84,35,21,1],
[84,35,21,2],
[84,35,22,0],
[84,35,22,1],
[84,35,22,2],
[84,35,22,3],
[84,35,23,0],
[84,35,23,1],
[84,35,23,2],
[84,35,24,0],
[84,35,24,1],
[84,35,24,2],
[84,36,3,0],
[84,36,3,1],
[84,36,3,2],
[84,36,6,0],
[84,36,6,1],
[84,36,6,2],
[84,36,7,0],
[84,36,7,1],
[84,36,7,2],
[84,36,7,3],
[84,36,7,5],
[84,36,7,6],
[84,36,8,0],
[84,36,8,1],
[84,36,8,2],
[84,36,8,7],
[84,36,9,0],
[84,36,9,1],
[84,36,9,2],
[84,36,9,3],
[84,36,9,5],
[84,36,9,6],
[84,36,9,7],
[84,36,9,8],
[84,36,10,0],
[84,36,10,1],
[84,36,10,2],
[84,36,10,3],
[84,36,10,5],
[84,36,10,6],
[84,36,10,7],
[84,36,10,8],
[84,36,10,9],
[84,36,11,0],
[84,36,11,1],
[84,36,11,2],
[84,36,11,3],
[84,36,11,5],
[84,36,11,6],
[84,36,11,7],
[84,36,11,8],
[84,36,11,9],
[84,36,11,10],
[84,36,12,0],
[84,36,12,1],
[84,36,12,2],
[84,36,12,3],
[84,36,12,5],
[84,36,12,6],
[84,36,12,7],
[84,36,12,8],
[84,36,12,9],
[84,36,12,10],
[84,36,15,0],
[84,36,15,1],
[84,36,15,2],
[84,36,15,3],
[84,36,15,5],
[84,36,15,6],
[84,36,15,7],
[84,36,15,8],
[84,36,15,10],
[84,36,15,11],
[84,36,15,12],
[84,36,16,0],
[84,36,16,1],
[84,36,16,2],
[84,36,16,3],
[84,36,16,5],
[84,36,16,6],
[84,36,16,7],
[84,36,16,8],
[84,36,16,9],
[84,36,16,10],
[84,36,16,11],
[84,36,17,0],
[84,36,17,1],
[84,36,17,2],
[84,36,17,3],
[84,36,17,5],
[84,36,17,6],
[84,36,17,7],
[84,36,17,8],
[84,36,17,9],
[84,36,17,10],
[84,36,17,11],
[84,36,18,0],
[84,36,18,1],
[84,36,18,2],
[84,36,18,3],
[84,36,18,5],
[84,36,18,6],
[84,36,18,7],
[84,36,18,8],
[84,36,20,0],
[84,36,20,1],
[84,36,20,2],
[84,36,20,3],
[84,36,20,5],
[84,36,21,0],
[84,36,21,1],
[84,36,21,2],
[84,36,22,0],
[84,36,22,1],
[84,36,22,2],
[84,38,3,0],
[84,38,3,1],
[84,38,3,2],
[84,38,5,0],
[84,38,5,1],
[84,38,5,2],
[84,38,6,0],
[84,38,6,1],
[84,38,6,2],
[84,38,8,0],
[84,38,8,1],
[84,38,8,2],
[84,38,9,0],
[84,38,9,1],
[84,38,9,2],
[84,38,9,3],
[84,38,9,5],
[84,38,9,6],
[84,38,9,8],
[84,38,10,0],
[84,38,10,1],
[84,38,10,2],
[84,38,10,3],
[84,38,10,5],
[84,38,10,6],
[84,38,10,8],
[84,38,10,9],
[84,38,11,0],
[84,38,11,1],
[84,38,11,2],
[84,38,11,3],
[84,38,11,5],
[84,38,11,6],
[84,38,11,8],
[84,38,11,9],
[84,38,11,10],
[84,38,12,0],
[84,38,12,1],
[84,38,12,2],
[84,38,12,3],
[84,38,12,5],
[84,38,12,6],
[84,38,12,8],
[84,38,12,9],
[84,38,12,10],
[84,38,13,0],
[84,38,13,1],
[84,38,13,2],
[84,38,13,3],
[84,38,13,5],
[84,38,13,6],
[84,38,13,8],
[84,38,13,9],
[84,38,13,10],
[84,38,13,11],
[84,38,13,12],
[84,38,14,0],
[84,38,14,1],
[84,38,14,2],
[84,38,14,3],
[84,38,14,5],
[84,38,14,6],
[84,38,14,8],
[84,38,14,9],
[84,38,14,10],
[84,38,14,11],
[84,38,15,0],
[84,38,15,1],
[84,38,15,2],
[84,38,15,3],
[84,38,15,5],
[84,38,15,6],
[84,38,15,8],
[84,38,16,0],
[84,38,16,1],
[84,38,16,2],
[84,38,16,3],
[84,38,16,5],
[84,38,16,6],
[84,38,17,0],
[84,38,17,1],
[84,38,17,2],
[84,38,17,3],
[84,38,17,5],
[84,38,17,6],
[84,38,18,0],
[84,38,18,1],
[84,38,18,2],
[84,38,18,3],
[84,38,18,5],
[84,38,19,0],
[84,38,19,1],
[84,38,19,2],
[84,38,19,3],
[84,38,20,0],
[84,38,20,1],
[84,38,20,2],
[84,39,7,0],
[84,39,7,1],
[84,39,7,2],
[84,39,9,0],
[84,39,9,1],
[84,39,9,2],
[84,39,9,7],
[84,39,10,0],
[84,39,10,1],
[84,39,10,2],
[84,39,10,7],
[84,39,10,9],
[84,39,11,0],
[84,39,11,1],
[84,39,11,2],
[84,39,11,7],
[84,39,11,9],
[84,39,11,10],
[84,39,12,0],
[84,39,12,1],
[84,39,12,2],
[84,39,12,7],
[84,39,12,9],
[84,39,12,10],
[84,39,13,0],
[84,39,13,1],
[84,39,13,2],
[84,39,13,7],
[84,39,13,9],
[84,39,13,10],
[84,39,13,11],
[84,39,14,0],
[84,39,14,1],
[84,39,14,2],
[84,39,14,7],
[84,39,14,9],
[84,39,15,0],
[84,39,15,1],
[84,39,15,2],
[84,39,15,7],
[84,39,16,0],
[84,39,16,1],
[84,39,16,2],
[84,39,17,0],
[84,39,17,1],
[84,39,17,2],
[84,39,18,0],
[84,39,18,1],
[84,39,18,2],
[84,39,19,0],
[84,39,19,1],
[84,39,19,2],
[84,40,3,0],
[84,40,3,1],
[84,40,3,2],
[84,40,5,0],
[84,40,5,1],
[84,40,5,2],
[84,40,6,0],
[84,40,6,1],
[84,40,6,2],
[84,40,7,0],
[84,40,7,1],
[84,40,7,2],
[84,40,7,3],
[84,40,7,5],
[84,40,7,6],
[84,40,8,0],
[84,40,8,1],
[84,40,8,2],
[84,40,8,7],
[84,40,10,0],
[84,40,10,1],
[84,40,10,2],
[84,40,10,3],
[84,40,10,5],
[84,40,10,6],
[84,40,10,7],
[84,40,10,8],
[84,40,11,0],
[84,40,11,1],
[84,40,11,2],
[84,40,11,3],
[84,40,11,5],
[84,40,11,6],
[84,40,11,7],
[84,40,11,8],
[84,40,11,10],
[84,40,12,0],
[84,40,12,1],
[84,40,12,2],
[84,40,12,3],
[84,40,12,5],
[84,40,12,6],
[84,40,12,7],
[84,40,12,8],
[84,40,13,0],
[84,40,13,1],
[84,40,13,2],
[84,40,13,3],
[84,40,13,5],
[84,40,13,6],
[84,40,13,7],
[84,40,13,8],
[84,40,14,0],
[84,40,14,1],
[84,40,14,2],
[84,40,14,3],
[84,40,14,5],
[84,40,14,6],
[84,40,14,7],
[84,40,16,0],
[84,40,16,1],
[84,40,16,2],
[84,40,16,3],
[84,40,17,0],
[84,40,17,1],
[84,40,17,2],
[84,40,17,3],
[84,41,3,0],
[84,41,3,1],
[84,41,3,2],
[84,41,5,0],
[84,41,5,1],
[84,41,5,2],
[84,41,6,0],
[84,41,6,1],
[84,41,6,2],
[84,41,7,0],
[84,41,7,1],
[84,41,7,2],
[84,41,7,3],
[84,41,7,5],
[84,41,7,6],
[84,41,8,0],
[84,41,8,1],
[84,41,8,2],
[84,41,8,7],
[84,41,10,0],
[84,41,10,1],
[84,41,10,2],
[84,41,10,3],
[84,41,10,5],
[84,41,10,6],
[84,41,10,7],
[84,41,10,8],
[84,41,11,0],
[84,41,11,1],
[84,41,11,2],
[84,41,11,3],
[84,41,11,5],
[84,41,11,6],
[84,41,11,7],
[84,41,11,8],
[84,41,11,10],
[84,41,12,0],
[84,41,12,1],
[84,41,12,2],
[84,41,12,3],
[84,41,12,5],
[84,41,12,6],
[84,41,12,7],
[84,41,12,8],
[84,41,13,0],
[84,41,13,1],
[84,41,13,2],
[84,41,13,3],
[84,41,13,5],
[84,41,13,6],
[84,41,13,7],
[84,41,13,8],
[84,41,14,0],
[84,41,14,1],
[84,41,14,2],
[84,41,14,3],
[84,41,14,5],
[84,41,14,6],
[84,41,14,7],
[84,41,16,0],
[84,41,16,1],
[84,41,16,2],
[84,41,16,3],
[84,41,17,0],
[84,41,17,1],
[84,41,17,2],
[84,41,17,3],
[84,42,3,0],
[84,42,3,1],
[84,42,3,2],
[84,42,5,0],
[84,42,5,1],
[84,42,5,2],
[84,42,6,0],
[84,42,6,1],
[84,42,6,2],
[84,42,7,0],
[84,42,7,1],
[84,42,7,2],
[84,42,7,3],
[84,42,7,5],
[84,42,7,6],
[84,42,8,0],
[84,42,8,1],
[84,42,8,2],
[84,42,8,7],
[84,42,9,0],
[84,42,9,1],
[84,42,9,2],
[84,42,9,3],
[84,42,9,5],
[84,42,9,6],
[84,42,9,7],
[84,42,9,8],
[84,42,11,0],
[84,42,11,1],
[84,42,11,2],
[84,42,11,3],
[84,42,11,5],
[84,42,11,6],
[84,42,11,7],
[84,42,11,8],
[84,42,11,9],
[84,42,12,0],
[84,42,12,1],
[84,42,12,2],
[84,42,12,3],
[84,42,12,5],
[84,42,12,6],
[84,42,12,7],
[84,42,13,0],
[84,42,13,1],
[84,42,13,2],
[84,42,13,3],
[84,42,13,5],
[84,42,13,6],
[84,42,13,7],
[84,42,14,0],
[84,42,14,1],
[84,42,14,2],
[84,42,14,3],
[84,42,14,5],
[84,42,15,0],
[84,42,15,1],
[84,42,15,2],
[84,42,15,3],
[84,42,16,0],
[84,42,16,1],
[84,42,16,2],
[84,42,17,0],
[84,42,17,1],
[84,42,17,2],
[84,43,3,0],
[84,43,3,1],
[84,43,3,2],
[84,43,5,0],
[84,43,5,1],
[84,43,5,2],
[84,43,6,0],
[84,43,6,1],
[84,43,6,2],
[84,43,8,0],
[84,43,8,1],
[84,43,8,2],
[84,43,9,0],
[84,43,9,1],
[84,43,9,2],
[84,43,9,3],
[84,43,9,5],
[84,43,9,6],
[84,43,9,8],
[84,43,10,0],
[84,43,10,1],
[84,43,10,2],
[84,43,10,3],
[84,43,10,5],
[84,43,10,6],
[84,43,11,0],
[84,43,11,1],
[84,43,11,2],
[84,43,11,3],
[84,43,11,5],
[84,43,11,6],
[84,43,12,0],
[84,43,12,1],
[84,43,12,2],
[84,43,12,3],
[84,43,12,5],
[84,43,13,0],
[84,43,13,1],
[84,43,13,2],
[84,43,13,3],
[84,43,13,5],
[84,43,14,0],
[84,43,14,1],
[84,43,14,2],
[84,43,14,3],
[84,43,15,0],
[84,43,15,1],
[84,43,15,2],
[84,44,3,0],
[84,44,3,1],
[84,44,3,2],
[84,44,5,0],
[84,44,5,1],
[84,44,5,2],
[84,44,6,0],
[84,44,6,1],
[84,44,6,2],
[84,44,7,0],
[84,44,7,1],
[84,44,7,2],
[84,44,7,3],
[84,44,7,5],
[84,44,7,6],
[84,44,8,0],
[84,44,8,1],
[84,44,8,2],
[84,44,8,7],
[84,44,9,0],
[84,44,9,1],
[84,44,9,2],
[84,44,9,3],
[84,44,9,5],
[84,44,9,6],
[84,44,9,7],
[84,44,10,0],
[84,44,10,1],
[84,44,10,2],
[84,44,10,3],
[84,44,10,5],
[84,44,11,0],
[84,44,11,1],
[84,44,11,2],
[84,44,11,3],
[84,44,11,5],
[84,44,12,0],
[84,44,12,1],
[84,44,12,2],
[84,44,12,3],
[84,44,13,0],
[84,44,13,1],
[84,44,13,2],
[84,44,13,3],
[84,44,14,0],
[84,44,14,1],
[84,44,14,2],
[84,45,3,0],
[84,45,3,1],
[84,45,3,2],
[84,45,5,0],
[84,45,5,1],
[84,45,5,2],
[84,45,6,0],
[84,45,6,1],
[84,45,6,2],
[84,45,7,0],
[84,45,7,1],
[84,45,7,2],
[84,45,7,3],
[84,45,7,5],
[84,45,7,6],
[84,45,8,0],
[84,45,8,1],
[84,45,8,2],
[84,45,10,0],
[84,45,10,1],
[84,45,10,2],
[84,45,10,3],
[84,45,11,0],
[84,45,11,1],
[84,45,11,2],
[84,45,11,3],
[84,45,12,0],
[84,45,12,1],
[84,45,12,2],
[84,45,13,0],
[84,45,13,1],
[84,45,13,2],
[84,46,3,0],
[84,46,3,1],
[84,46,3,2],
[84,46,5,0],
[84,46,5,1],
[84,46,5,2],
[84,46,6,0],
[84,46,6,1],
[84,46,6,2],
[84,46,7,0],
[84,46,7,1],
[84,46,7,2],
[84,46,7,3],
[84,46,7,5],
[84,46,8,0],
[84,46,8,1],
[84,46,8,2],
[84,46,10,0],
[84,46,10,1],
[84,46,10,2],
[84,46,11,0],
[84,46,11,1],
[84,46,11,2],
[84,47,3,0],
[84,47,3,1],
[84,47,3,2],
[84,47,5,0],
[84,47,5,1],
[84,47,5,2],
[84,47,6,0],
[84,47,6,1],
[84,47,6,2],
[84,47,7,0],
[84,47,7,1],
[84,47,7,2],
[84,47,7,3],
[84,47,8,0],
[84,47,8,1],
[84,47,8,2],
[84,47,9,0],
[84,47,9,1],
[84,47,9,2],
[84,48,3,0],
[84,48,3,1],
[84,48,3,2],
[84,48,5,0],
[84,48,5,1],
[84,48,5,2],
[84,48,6,0],
[84,48,6,1],
[84,48,6,2],
[84,48,7,0],
[84,48,7,1],
[84,48,7,2],
[84,49,3,0],
[84,49,3,1],
[84,49,3,2],
[84,49,5,0],
[84,49,5,1],
[84,49,5,2],
[84,49,6,0],
[84,49,6,1],
[84,49,6,2],
[84,49,7,0],
[84,49,7,1],
[84,49,7,2],
[84,50,3,0],
[84,50,3,1],
[84,50,3,2],
[84,50,5,0],
[84,50,5,1],
[84,50,5,2],
[84,50,6,0],
[84,50,6,1],
[84,50,6,2],
[84,50,7,0],
[84,50,7,1],
[84,50,7,2],
[84,51,7,0],
[84,51,7,1],
[84,51,7,2],
[84,52,3,0],
[84,52,3,1],
[84,52,3,2],
[84,52,5,0],
[84,52,5,1],
[84,52,5,2],
[84,52,6,0],
[84,52,6,1],
[84,52,6,2],
[84,52,7,0],
[84,52,7,1],
[84,52,7,2],
[84,53,3,0],
[84,53,3,1],
[84,53,3,2],
[84,53,5,0],
[84,53,5,1],
[84,53,5,2],
[84,53,6,0],
[84,53,6,1],
[84,53,6,2],
[84,53,7,0],
[84,53,7,1],
[84,53,7,2],
[84,54,3,0],
[84,54,3,1],
[84,54,3,2],
[84,54,5,0],
[84,54,5,1],
[84,54,5,2],
[84,54,6,0],
[84,54,6,1],
[84,54,6,2],
[84,54,7,0],
[84,54,7,1],
[84,54,7,2],
[84,55,3,0],
[84,55,3,1],
[84,55,3,2],
[84,55,5,0],
[84,55,5,1],
[84,55,5,2],
[84,55,6,0],
[84,55,6,1],
[84,55,6,2],
[84,55,7,0],
[84,55,7,1],
[84,55,7,2],
[84,56,3,0],
[84,56,3,1],
[84,56,3,2],
[84,56,5,0],
[84,56,5,1],
[84,56,5,2],
[84,56,6,0],
[84,56,6,1],
[84,56,6,2],
[84,56,7,0],
[84,56,7,1],
[84,56,7,2],
[84,57,3,0],
[84,57,3,1],
[84,57,3,2],
[84,57,5,0],
[84,57,5,1],
[84,57,5,2],
[84,58,3,0],
[84,58,3,1],
[84,58,3,2],
[84,58,5,0],
[84,58,5,1],
[84,58,5,2],
[84,59,3,0],
[84,59,3,1],
[84,59,3,2],
[85,4,3,0],
[85,4,3,1],
[85,4,3,2],
[85,5,4,0],
[85,5,4,1],
[85,5,4,2],
[85,6,4,0],
[85,6,4,1],
[85,6,4,2],
[85,8,4,0],
[85,8,4,1],
[85,8,4,2],
[85,9,3,0],
[85,9,3,1],
[85,9,3,2],
[85,9,4,0],
[85,9,4,1],
[85,9,4,2],
[85,9,4,3],
[85,9,5,0],
[85,9,5,1],
[85,9,5,2],
[85,9,5,4],
[85,9,6,0],
[85,9,6,1],
[85,9,6,2],
[85,9,6,4],
[85,9,8,0],
[85,9,8,1],
[85,9,8,2],
[85,9,8,4],
[85,10,3,0],
[85,10,3,1],
[85,10,3,2],
[85,10,4,0],
[85,10,4,1],
[85,10,4,2],
[85,10,4,3],
[85,10,5,0],
[85,10,5,1],
[85,10,5,2],
[85,10,5,4],
[85,10,6,0],
[85,10,6,1],
[85,10,6,2],
[85,10,6,4],
[85,10,8,0],
[85,10,8,1],
[85,10,8,2],
[85,10,8,4],
[85,10,9,0],
[85,10,9,1],
[85,10,9,2],
[85,10,9,3],
[85,10,9,4],
[85,10,9,5],
[85,10,9,6],
[85,10,9,8],
[85,11,3,0],
[85,11,3,1],
[85,11,3,2],
[85,11,4,0],
[85,11,4,1],
[85,11,4,2],
[85,11,4,3],
[85,11,5,0],
[85,11,5,1],
[85,11,5,2],
[85,11,5,4],
[85,11,6,0],
[85,11,6,1],
[85,11,6,2],
[85,11,6,4],
[85,11,8,0],
[85,11,8,1],
[85,11,8,2],
[85,11,8,4],
[85,11,9,0],
[85,11,9,1],
[85,11,9,2],
[85,11,9,3],
[85,11,9,4],
[85,11,9,5],
[85,11,9,6],
[85,11,9,8],
[85,11,10,0],
[85,11,10,1],
[85,11,10,2],
[85,11,10,3],
[85,11,10,4],
[85,11,10,5],
[85,11,10,6],
[85,11,10,8],
[85,11,10,9],
[85,12,3,0],
[85,12,3,1],
[85,12,3,2],
[85,12,4,0],
[85,12,4,1],
[85,12,4,2],
[85,12,4,3],
[85,12,5,0],
[85,12,5,1],
[85,12,5,2],
[85,12,5,4],
[85,12,6,0],
[85,12,6,1],
[85,12,6,2],
[85,12,6,4],
[85,12,8,0],
[85,12,8,1],
[85,12,8,2],
[85,12,8,4],
[85,12,9,0],
[85,12,9,1],
[85,12,9,2],
[85,12,9,3],
[85,12,9,4],
[85,12,9,5],
[85,12,9,6],
[85,12,9,8],
[85,12,10,0],
[85,12,10,1],
[85,12,10,2],
[85,12,10,3],
[85,12,10,4],
[85,12,10,5],
[85,12,10,6],
[85,12,10,8],
[85,12,10,9],
[85,13,3,0],
[85,13,3,1],
[85,13,3,2],
[85,13,4,0],
[85,13,4,1],
[85,13,4,2],
[85,13,4,3],
[85,13,5,0],
[85,13,5,1],
[85,13,5,2],
[85,13,5,4],
[85,13,6,0],
[85,13,6,1],
[85,13,6,2],
[85,13,6,4],
[85,13,8,0],
[85,13,8,1],
[85,13,8,2],
[85,13,8,4],
[85,13,9,0],
[85,13,9,1],
[85,13,9,2],
[85,13,9,3],
[85,13,9,4],
[85,13,9,5],
[85,13,9,6],
[85,13,9,8],
[85,13,10,0],
[85,13,10,1],
[85,13,10,2],
[85,13,10,3],
[85,13,10,4],
[85,13,10,5],
[85,13,10,6],
[85,13,10,8],
[85,13,10,9],
[85,13,11,0],
[85,13,11,1],
[85,13,11,2],
[85,13,11,3],
[85,13,11,4],
[85,13,11,5],
[85,13,11,6],
[85,13,11,8],
[85,13,11,9],
[85,13,11,10],
[85,13,12,0],
[85,13,12,1],
[85,13,12,2],
[85,13,12,3],
[85,13,12,4],
[85,13,12,5],
[85,13,12,6],
[85,13,12,8],
[85,13,12,9],
[85,13,12,10],
[85,14,3,0],
[85,14,3,1],
[85,14,3,2],
[85,14,4,0],
[85,14,4,1],
[85,14,4,2],
[85,14,4,3],
[85,14,5,0],
[85,14,5,1],
[85,14,5,2],
[85,14,5,4],
[85,14,6,0],
[85,14,6,1],
[85,14,6,2],
[85,14,6,4],
[85,14,8,0],
[85,14,8,1],
[85,14,8,2],
[85,14,8,4],
[85,14,9,0],
[85,14,9,1],
[85,14,9,2],
[85,14,9,3],
[85,14,9,4],
[85,14,9,5],
[85,14,9,6],
[85,14,9,8],
[85,14,10,0],
[85,14,10,1],
[85,14,10,2],
[85,14,10,3],
[85,14,10,4],
[85,14,10,5],
[85,14,10,6],
[85,14,10,8],
[85,14,10,9],
[85,14,11,0],
[85,14,11,1],
[85,14,11,2],
[85,14,11,3],
[85,14,11,4],
[85,14,11,5],
[85,14,11,6],
[85,14,11,8],
[85,14,11,9],
[85,14,11,10],
[85,14,12,0],
[85,14,12,1],
[85,14,12,2],
[85,14,12,3],
[85,14,12,4],
[85,14,12,5],
[85,14,12,6],
[85,14,12,8],
[85,14,12,9],
[85,14,12,10],
[85,15,3,0],
[85,15,3,1],
[85,15,3,2],
[85,15,4,0],
[85,15,4,1],
[85,15,4,2],
[85,15,4,3],
[85,15,5,0],
[85,15,5,1],
[85,15,5,2],
[85,15,5,4],
[85,15,6,0],
[85,15,6,1],
[85,15,6,2],
[85,15,6,4],
[85,15,8,0],
[85,15,8,1],
[85,15,8,2],
[85,15,8,4],
[85,15,10,0],
[85,15,10,1],
[85,15,10,2],
[85,15,10,3],
[85,15,10,4],
[85,15,10,5],
[85,15,10,6],
[85,15,10,8],
[85,15,11,0],
[85,15,11,1],
[85,15,11,2],
[85,15,11,3],
[85,15,11,4],
[85,15,11,5],
[85,15,11,6],
[85,15,11,8],
[85,15,11,10],
[85,15,12,0],
[85,15,12,1],
[85,15,12,2],
[85,15,12,3],
[85,15,12,4],
[85,15,12,5],
[85,15,12,6],
[85,15,12,8],
[85,15,12,10],
[85,15,13,0],
[85,15,13,1],
[85,15,13,2],
[85,15,13,3],
[85,15,13,4],
[85,15,13,5],
[85,15,13,6],
[85,15,13,8],
[85,15,13,10],
[85,15,13,11],
[85,15,13,12],
[85,15,14,0],
[85,15,14,1],
[85,15,14,2],
[85,15,14,3],
[85,15,14,4],
[85,15,14,5],
[85,15,14,6],
[85,15,14,8],
[85,15,14,10],
[85,15,14,11],
[85,15,14,12],
[85,16,3,0],
[85,16,3,1],
[85,16,3,2],
[85,16,4,0],
[85,16,4,1],
[85,16,4,2],
[85,16,4,3],
[85,16,5,0],
[85,16,5,1],
[85,16,5,2],
[85,16,5,4],
[85,16,6,0],
[85,16,6,1],
[85,16,6,2],
[85,16,6,4],
[85,16,8,0],
[85,16,8,1],
[85,16,8,2],
[85,16,8,4],
[85,16,9,0],
[85,16,9,1],
[85,16,9,2],
[85,16,9,3],
[85,16,9,4],
[85,16,9,5],
[85,16,9,6],
[85,16,9,8],
[85,16,10,0],
[85,16,10,1],
[85,16,10,2],
[85,16,10,3],
[85,16,10,4],
[85,16,10,5],
[85,16,10,6],
[85,16,10,8],
[85,16,10,9],
[85,16,11,0],
[85,16,11,1],
[85,16,11,2],
[85,16,11,3],
[85,16,11,4],
[85,16,11,5],
[85,16,11,6],
[85,16,11,8],
[85,16,11,9],
[85,16,11,10],
[85,16,12,0],
[85,16,12,1],
[85,16,12,2],
[85,16,12,3],
[85,16,12,4],
[85,16,12,5],
[85,16,12,6],
[85,16,12,8],
[85,16,12,9],
[85,16,12,10],
[85,16,13,0],
[85,16,13,1],
[85,16,13,2],
[85,16,13,3],
[85,16,13,4],
[85,16,13,5],
[85,16,13,6],
[85,16,13,8],
[85,16,13,9],
[85,16,13,10],
[85,16,13,11],
[85,16,13,12],
[85,16,14,0],
[85,16,14,1],
[85,16,14,2],
[85,16,14,3],
[85,16,14,4],
[85,16,14,5],
[85,16,14,6],
[85,16,14,8],
[85,16,14,9],
[85,16,14,10],
[85,16,14,11],
[85,16,14,12],
[85,16,15,0],
[85,16,15,1],
[85,16,15,2],
[85,16,15,3],
[85,16,15,4],
[85,16,15,5],
[85,16,15,6],
[85,16,15,8],
[85,16,15,10],
[85,16,15,11],
[85,16,15,12],
[85,16,15,13],
[85,16,15,14],
[85,17,3,0],
[85,17,3,1],
[85,17,3,2],
[85,17,4,0],
[85,17,4,1],
[85,17,4,2],
[85,17,4,3],
[85,17,5,0],
[85,17,5,1],
[85,17,5,2],
[85,17,5,4],
[85,17,6,0],
[85,17,6,1],
[85,17,6,2],
[85,17,6,4],
[85,17,8,0],
[85,17,8,1],
[85,17,8,2],
[85,17,8,4],
[85,17,9,0],
[85,17,9,1],
[85,17,9,2],
[85,17,9,3],
[85,17,9,4],
[85,17,9,5],
[85,17,9,6],
[85,17,9,8],
[85,17,10,0],
[85,17,10,1],
[85,17,10,2],
[85,17,10,3],
[85,17,10,4],
[85,17,10,5],
[85,17,10,6],
[85,17,10,8],
[85,17,10,9],
[85,17,11,0],
[85,17,11,1],
[85,17,11,2],
[85,17,11,3],
[85,17,11,4],
[85,17,11,5],
[85,17,11,6],
[85,17,11,8],
[85,17,11,9],
[85,17,11,10],
[85,17,12,0],
[85,17,12,1],
[85,17,12,2],
[85,17,12,3],
[85,17,12,4],
[85,17,12,5],
[85,17,12,6],
[85,17,12,8],
[85,17,12,9],
[85,17,12,10],
[85,17,13,0],
[85,17,13,1],
[85,17,13,2],
[85,17,13,3],
[85,17,13,4],
[85,17,13,5],
[85,17,13,6],
[85,17,13,8],
[85,17,13,9],
[85,17,13,10],
[85,17,13,11],
[85,17,13,12],
[85,17,14,0],
[85,17,14,1],
[85,17,14,2],
[85,17,14,3],
[85,17,14,4],
[85,17,14,5],
[85,17,14,6],
[85,17,14,8],
[85,17,14,9],
[85,17,14,10],
[85,17,14,11],
[85,17,14,12],
[85,17,15,0],
[85,17,15,1],
[85,17,15,2],
[85,17,15,3],
[85,17,15,4],
[85,17,15,5],
[85,17,15,6],
[85,17,15,8],
[85,17,15,10],
[85,17,15,11],
[85,17,15,12],
[85,17,15,13],
[85,17,15,14],
[85,17,16,0],
[85,17,16,1],
[85,17,16,2],
[85,17,16,3],
[85,17,16,4],
[85,17,16,5],
[85,17,16,6],
[85,17,16,8],
[85,17,16,9],
[85,17,16,10],
[85,17,16,11],
[85,17,16,12],
[85,17,16,13],
[85,17,16,14],
[85,17,16,15],
[85,18,3,0],
[85,18,3,1],
[85,18,3,2],
[85,18,4,0],
[85,18,4,1],
[85,18,4,2],
[85,18,4,3],
[85,18,5,0],
[85,18,5,1],
[85,18,5,2],
[85,18,5,4],
[85,18,6,0],
[85,18,6,1],
[85,18,6,2],
[85,18,6,4],
[85,18,8,0],
[85,18,8,1],
[85,18,8,2],
[85,18,8,4],
[85,18,10,0],
[85,18,10,1],
[85,18,10,2],
[85,18,10,3],
[85,18,10,4],
[85,18,10,5],
[85,18,10,6],
[85,18,10,8],
[85,18,11,0],
[85,18,11,1],
[85,18,11,2],
[85,18,11,3],
[85,18,11,4],
[85,18,11,5],
[85,18,11,6],
[85,18,11,8],
[85,18,11,10],
[85,18,12,0],
[85,18,12,1],
[85,18,12,2],
[85,18,12,3],
[85,18,12,4],
[85,18,12,5],
[85,18,12,6],
[85,18,12,8],
[85,18,12,10],
[85,18,13,0],
[85,18,13,1],
[85,18,13,2],
[85,18,13,3],
[85,18,13,4],
[85,18,13,5],
[85,18,13,6],
[85,18,13,8],
[85,18,13,10],
[85,18,13,11],
[85,18,13,12],
[85,18,14,0],
[85,18,14,1],
[85,18,14,2],
[85,18,14,3],
[85,18,14,4],
[85,18,14,5],
[85,18,14,6],
[85,18,14,8],
[85,18,14,10],
[85,18,14,11],
[85,18,14,12],
[85,18,16,0],
[85,18,16,1],
[85,18,16,2],
[85,18,16,3],
[85,18,16,4],
[85,18,16,5],
[85,18,16,6],
[85,18,16,8],
[85,18,16,10],
[85,18,16,11],
[85,18,16,12],
[85,18,16,13],
[85,18,16,14],
[85,18,17,0],
[85,18,17,1],
[85,18,17,2],
[85,18,17,3],
[85,18,17,4],
[85,18,17,5],
[85,18,17,6],
[85,18,17,8],
[85,18,17,10],
[85,18,17,11],
[85,18,17,12],
[85,18,17,13],
[85,18,17,14],
[85,18,17,16],
[85,19,3,0],
[85,19,3,1],
[85,19,3,2],
[85,19,4,0],
[85,19,4,1],
[85,19,4,2],
[85,19,4,3],
[85,19,5,0],
[85,19,5,1],
[85,19,5,2],
[85,19,5,4],
[85,19,6,0],
[85,19,6,1],
[85,19,6,2],
[85,19,6,4],
[85,19,8,0],
[85,19,8,1],
[85,19,8,2],
[85,19,8,4],
[85,19,9,0],
[85,19,9,1],
[85,19,9,2],
[85,19,9,3],
[85,19,9,4],
[85,19,9,5],
[85,19,9,6],
[85,19,9,8],
[85,19,10,0],
[85,19,10,1],
[85,19,10,2],
[85,19,10,3],
[85,19,10,4],
[85,19,10,5],
[85,19,10,6],
[85,19,10,8],
[85,19,10,9],
[85,19,11,0],
[85,19,11,1],
[85,19,11,2],
[85,19,11,3],
[85,19,11,4],
[85,19,11,5],
[85,19,11,6],
[85,19,11,8],
[85,19,11,9],
[85,19,11,10],
[85,19,12,0],
[85,19,12,1],
[85,19,12,2],
[85,19,12,3],
[85,19,12,4],
[85,19,12,5],
[85,19,12,6],
[85,19,12,8],
[85,19,12,9],
[85,19,12,10],
[85,19,15,0],
[85,19,15,1],
[85,19,15,2],
[85,19,15,3],
[85,19,15,4],
[85,19,15,5],
[85,19,15,6],
[85,19,15,8],
[85,19,15,10],
[85,19,15,11],
[85,19,15,12],
[85,19,16,0],
[85,19,16,1],
[85,19,16,2],
[85,19,16,3],
[85,19,16,4],
[85,19,16,5],
[85,19,16,6],
[85,19,16,8],
[85,19,16,9],
[85,19,16,10],
[85,19,16,11],
[85,19,16,12],
[85,19,16,15],
[85,19,17,0],
[85,19,17,1],
[85,19,17,2],
[85,19,17,3],
[85,19,17,4],
[85,19,17,5],
[85,19,17,6],
[85,19,17,8],
[85,19,17,9],
[85,19,17,10],
[85,19,17,11],
[85,19,17,12],
[85,19,17,15],
[85,19,17,16],
[85,19,18,0],
[85,19,18,1],
[85,19,18,2],
[85,19,18,3],
[85,19,18,4],
[85,19,18,5],
[85,19,18,6],
[85,19,18,8],
[85,19,18,10],
[85,19,18,11],
[85,19,18,12],
[85,20,3,0],
[85,20,3,1],
[85,20,3,2],
[85,20,4,0],
[85,20,4,1],
[85,20,4,2],
[85,20,4,3],
[85,20,5,0],
[85,20,5,1],
[85,20,5,2],
[85,20,5,4],
[85,20,6,0],
[85,20,6,1],
[85,20,6,2],
[85,20,6,4],
[85,20,8,0],
[85,20,8,1],
[85,20,8,2],
[85,20,8,4],
[85,20,9,0],
[85,20,9,1],
[85,20,9,2],
[85,20,9,3],
[85,20,9,4],
[85,20,9,5],
[85,20,9,6],
[85,20,9,8],
[85,20,11,0],
[85,20,11,1],
[85,20,11,2],
[85,20,11,3],
[85,20,11,4],
[85,20,11,5],
[85,20,11,6],
[85,20,11,8],
[85,20,11,9],
[85,20,12,0],
[85,20,12,1],
[85,20,12,2],
[85,20,12,3],
[85,20,12,4],
[85,20,12,5],
[85,20,12,6],
[85,20,12,8],
[85,20,12,9],
[85,20,13,0],
[85,20,13,1],
[85,20,13,2],
[85,20,13,3],
[85,20,13,4],
[85,20,13,5],
[85,20,13,6],
[85,20,13,8],
[85,20,13,9],
[85,20,13,11],
[85,20,13,12],
[85,20,14,0],
[85,20,14,1],
[85,20,14,2],
[85,20,14,3],
[85,20,14,4],
[85,20,14,5],
[85,20,14,6],
[85,20,14,8],
[85,20,14,9],
[85,20,14,11],
[85,20,14,12],
[85,20,15,0],
[85,20,15,1],
[85,20,15,2],
[85,20,15,3],
[85,20,15,4],
[85,20,15,5],
[85,20,15,6],
[85,20,15,8],
[85,20,15,11],
[85,20,15,12],
[85,20,15,13],
[85,20,15,14],
[85,20,16,0],
[85,20,16,1],
[85,20,16,2],
[85,20,16,3],
[85,20,16,4],
[85,20,16,5],
[85,20,16,6],
[85,20,16,8],
[85,20,16,9],
[85,20,16,11],
[85,20,16,12],
[85,20,16,13],
[85,20,16,14],
[85,20,16,15],
[85,20,17,0],
[85,20,17,1],
[85,20,17,2],
[85,20,17,3],
[85,20,17,4],
[85,20,17,5],
[85,20,17,6],
[85,20,17,8],
[85,20,17,9],
[85,20,17,11],
[85,20,17,12],
[85,20,17,13],
[85,20,17,14],
[85,20,17,15],
[85,20,18,0],
[85,20,18,1],
[85,20,18,2],
[85,20,18,3],
[85,20,18,4],
[85,20,18,5],
[85,20,18,6],
[85,20,18,8],
[85,20,18,11],
[85,20,18,12],
[85,20,18,13],
[85,20,18,14],
[85,20,18,16],
[85,20,18,17],
[85,20,19,0],
[85,20,19,1],
[85,20,19,2],
[85,20,19,3],
[85,20,19,4],
[85,20,19,5],
[85,20,19,6],
[85,20,19,8],
[85,20,19,9],
[85,20,19,11],
[85,20,19,12],
[85,20,19,15],
[85,20,19,16],
[85,20,19,17],
[85,20,19,18],
[85,21,4,0],
[85,21,4,1],
[85,21,4,2],
[85,21,9,0],
[85,21,9,1],
[85,21,9,2],
[85,21,9,4],
[85,21,10,0],
[85,21,10,1],
[85,21,10,2],
[85,21,10,4],
[85,21,10,9],
[85,21,11,0],
[85,21,11,1],
[85,21,11,2],
[85,21,11,4],
[85,21,11,9],
[85,21,11,10],
[85,21,12,0],
[85,21,12,1],
[85,21,12,2],
[85,21,12,4],
[85,21,12,9],
[85,21,12,10],
[85,21,13,0],
[85,21,13,1],
[85,21,13,2],
[85,21,13,4],
[85,21,13,9],
[85,21,13,10],
[85,21,13,11],
[85,21,13,12],
[85,21,14,0],
[85,21,14,1],
[85,21,14,2],
[85,21,14,4],
[85,21,14,9],
[85,21,14,10],
[85,21,14,11],
[85,21,14,12],
[85,21,15,0],
[85,21,15,1],
[85,21,15,2],
[85,21,15,4],
[85,21,15,10],
[85,21,15,11],
[85,21,15,12],
[85,21,15,13],
[85,21,15,14],
[85,21,16,0],
[85,21,16,1],
[85,21,16,2],
[85,21,16,4],
[85,21,16,9],
[85,21,16,10],
[85,21,16,11],
[85,21,16,12],
[85,21,16,13],
[85,21,16,14],
[85,21,17,0],
[85,21,17,1],
[85,21,17,2],
[85,21,17,4],
[85,21,17,9],
[85,21,17,10],
[85,21,17,11],
[85,21,17,12],
[85,21,17,13],
[85,21,17,14],
[85,21,17,16],
[85,21,18,0],
[85,21,18,1],
[85,21,18,2],
[85,21,18,4],
[85,21,18,10],
[85,21,18,11],
[85,21,18,12],
[85,21,18,13],
[85,21,18,16],
[85,21,18,17],
[85,21,19,0],
[85,21,19,1],
[85,21,19,2],
[85,21,19,4],
[85,21,19,9],
[85,21,19,10],
[85,21,19,11],
[85,21,19,15],
[85,21,19,16],
[85,21,19,17],
[85,21,19,18],
[85,21,20,0],
[85,21,20,1],
[85,21,20,2],
[85,21,20,4],
[85,21,20,9],
[85,21,20,12],
[85,21,20,13],
[85,21,20,14],
[85,21,20,15],
[85,21,20,16],
[85,21,20,17],
[85,21,20,18],
[85,21,20,19],
[85,22,3,0],
[85,22,3,1],
[85,22,3,2],
[85,22,4,0],
[85,22,4,1],
[85,22,4,2],
[85,22,4,3],
[85,22,5,0],
[85,22,5,1],
[85,22,5,2],
[85,22,5,4],
[85,22,6,0],
[85,22,6,1],
[85,22,6,2],
[85,22,6,4],
[85,22,8,0],
[85,22,8,1],
[85,22,8,2],
[85,22,8,4],
[85,22,9,0],
[85,22,9,1],
[85,22,9,2],
[85,22,9,3],
[85,22,9,4],
[85,22,9,5],
[85,22,9,6],
[85,22,9,8],
[85,22,11,0],
[85,22,11,1],
[85,22,11,2],
[85,22,11,3],
[85,22,11,4],
[85,22,11,5],
[85,22,11,6],
[85,22,11,8],
[85,22,11,9],
[85,22,12,0],
[85,22,12,1],
[85,22,12,2],
[85,22,12,3],
[85,22,12,4],
[85,22,12,5],
[85,22,12,6],
[85,22,12,8],
[85,22,12,9],
[85,22,13,0],
[85,22,13,1],
[85,22,13,2],
[85,22,13,3],
[85,22,13,4],
[85,22,13,5],
[85,22,13,6],
[85,22,13,8],
[85,22,13,9],
[85,22,13,11],
[85,22,13,12],
[85,22,14,0],
[85,22,14,1],
[85,22,14,2],
[85,22,14,3],
[85,22,14,4],
[85,22,14,5],
[85,22,14,6],
[85,22,14,8],
[85,22,14,9],
[85,22,14,11],
[85,22,14,12],
[85,22,15,0],
[85,22,15,1],
[85,22,15,2],
[85,22,15,3],
[85,22,15,4],
[85,22,15,5],
[85,22,15,6],
[85,22,15,8],
[85,22,15,11],
[85,22,15,12],
[85,22,15,13],
[85,22,15,14],
[85,22,16,0],
[85,22,16,1],
[85,22,16,2],
[85,22,16,3],
[85,22,16,4],
[85,22,16,5],
[85,22,16,6],
[85,22,16,8],
[85,22,16,9],
[85,22,16,11],
[85,22,16,12],
[85,22,16,13],
[85,22,16,15],
[85,22,17,0],
[85,22,17,1],
[85,22,17,2],
[85,22,17,3],
[85,22,17,4],
[85,22,17,5],
[85,22,17,6],
[85,22,17,8],
[85,22,17,9],
[85,22,17,11],
[85,22,17,12],
[85,22,17,13],
[85,22,17,15],
[85,22,17,16],
[85,22,18,0],
[85,22,18,1],
[85,22,18,2],
[85,22,18,3],
[85,22,18,4],
[85,22,18,5],
[85,22,18,6],
[85,22,18,8],
[85,22,18,11],
[85,22,18,14],
[85,22,18,16],
[85,22,18,17],
[85,22,19,0],
[85,22,19,1],
[85,22,19,2],
[85,22,19,3],
[85,22,19,4],
[85,22,19,5],
[85,22,19,6],
[85,22,19,8],
[85,22,19,9],
[85,22,19,12],
[85,22,19,15],
[85,22,19,16],
[85,22,19,17],
[85,22,19,18],
[85,22,21,0],
[85,22,21,1],
[85,22,21,2],
[85,22,21,4],
[85,22,21,9],
[85,22,21,11],
[85,22,21,12],
[85,22,21,13],
[85,22,21,14],
[85,22,21,15],
[85,22,21,16],
[85,22,21,17],
[85,22,21,18],
[85,22,21,19],
[85,23,3,0],
[85,23,3,1],
[85,23,3,2],
[85,23,4,0],
[85,23,4,1],
[85,23,4,2],
[85,23,4,3],
[85,23,5,0],
[85,23,5,1],
[85,23,5,2],
[85,23,5,4],
[85,23,6,0],
[85,23,6,1],
[85,23,6,2],
[85,23,6,4],
[85,23,8,0],
[85,23,8,1],
[85,23,8,2],
[85,23,8,4],
[85,23,9,0],
[85,23,9,1],
[85,23,9,2],
[85,23,9,3],
[85,23,9,4],
[85,23,9,5],
[85,23,9,6],
[85,23,9,8],
[85,23,10,0],
[85,23,10,1],
[85,23,10,2],
[85,23,10,3],
[85,23,10,4],
[85,23,10,5],
[85,23,10,6],
[85,23,10,8],
[85,23,10,9],
[85,23,11,0],
[85,23,11,1],
[85,23,11,2],
[85,23,11,3],
[85,23,11,4],
[85,23,11,5],
[85,23,11,6],
[85,23,11,8],
[85,23,11,9],
[85,23,11,10],
[85,23,12,0],
[85,23,12,1],
[85,23,12,2],
[85,23,12,3],
[85,23,12,4],
[85,23,12,5],
[85,23,12,6],
[85,23,12,8],
[85,23,12,9],
[85,23,12,10],
[85,23,13,0],
[85,23,13,1],
[85,23,13,2],
[85,23,13,3],
[85,23,13,4],
[85,23,13,5],
[85,23,13,6],
[85,23,13,8],
[85,23,13,9],
[85,23,13,10],
[85,23,13,11],
[85,23,13,12],
[85,23,14,0],
[85,23,14,1],
[85,23,14,2],
[85,23,14,3],
[85,23,14,4],
[85,23,14,5],
[85,23,14,6],
[85,23,14,8],
[85,23,14,9],
[85,23,14,10],
[85,23,14,11],
[85,23,14,12],
[85,23,15,0],
[85,23,15,1],
[85,23,15,2],
[85,23,15,3],
[85,23,15,4],
[85,23,15,5],
[85,23,15,6],
[85,23,15,8],
[85,23,15,10],
[85,23,15,11],
[85,23,15,12],
[85,23,15,13],
[85,23,16,0],
[85,23,16,1],
[85,23,16,2],
[85,23,16,3],
[85,23,16,4],
[85,23,16,5],
[85,23,16,6],
[85,23,16,8],
[85,23,16,9],
[85,23,16,10],
[85,23,16,11],
[85,23,16,14],
[85,23,16,15],
[85,23,17,0],
[85,23,17,1],
[85,23,17,2],
[85,23,17,3],
[85,23,17,4],
[85,23,17,5],
[85,23,17,6],
[85,23,17,8],
[85,23,17,9],
[85,23,17,10],
[85,23,17,11],
[85,23,17,14],
[85,23,17,15],
[85,23,17,16],
[85,23,18,0],
[85,23,18,1],
[85,23,18,2],
[85,23,18,3],
[85,23,18,4],
[85,23,18,5],
[85,23,18,6],
[85,23,18,8],
[85,23,18,12],
[85,23,18,13],
[85,23,18,14],
[85,23,18,16],
[85,23,18,17],
[85,23,19,0],
[85,23,19,1],
[85,23,19,2],
[85,23,19,3],
[85,23,19,4],
[85,23,19,5],
[85,23,19,6],
[85,23,19,10],
[85,23,19,11],
[85,23,19,12],
[85,23,19,15],
[85,23,19,16],
[85,23,19,17],
[85,23,19,18],
[85,23,20,0],
[85,23,20,1],
[85,23,20,2],
[85,23,20,3],
[85,23,20,4],
[85,23,20,5],
[85,23,20,8],
[85,23,20,9],
[85,23,20,11],
[85,23,20,12],
[85,23,20,13],
[85,23,20,14],
[85,23,20,15],
[85,23,20,16],
[85,23,20,17],
[85,23,20,18],
[85,23,20,19],
[85,23,21,0],
[85,23,21,1],
[85,23,21,2],
[85,23,21,4],
[85,23,21,9],
[85,23,21,10],
[85,23,21,11],
[85,23,21,12],
[85,23,21,13],
[85,23,21,14],
[85,23,21,15],
[85,23,21,16],
[85,23,21,17],
[85,23,21,18],
[85,23,21,19],
[85,23,21,20],
[85,23,22,0],
[85,23,22,1],
[85,23,22,2],
[85,23,22,5],
[85,23,22,6],
[85,23,22,8],
[85,23,22,9],
[85,23,22,11],
[85,23,22,12],
[85,23,22,13],
[85,23,22,14],
[85,23,22,15],
[85,23,22,16],
[85,23,22,17],
[85,23,22,18],
[85,23,22,19],
[85,23,22,21],
[85,24,3,0],
[85,24,3,1],
[85,24,3,2],
[85,24,4,0],
[85,24,4,1],
[85,24,4,2],
[85,24,4,3],
[85,24,5,0],
[85,24,5,1],
[85,24,5,2],
[85,24,5,4],
[85,24,6,0],
[85,24,6,1],
[85,24,6,2],
[85,24,6,4],
[85,24,8,0],
[85,24,8,1],
[85,24,8,2],
[85,24,8,4],
[85,24,9,0],
[85,24,9,1],
[85,24,9,2],
[85,24,9,3],
[85,24,9,4],
[85,24,9,5],
[85,24,9,6],
[85,24,9,8],
[85,24,10,0],
[85,24,10,1],
[85,24,10,2],
[85,24,10,3],
[85,24,10,4],
[85,24,10,5],
[85,24,10,6],
[85,24,10,8],
[85,24,10,9],
[85,24,11,0],
[85,24,11,1],
[85,24,11,2],
[85,24,11,3],
[85,24,11,4],
[85,24,11,5],
[85,24,11,6],
[85,24,11,8],
[85,24,11,9],
[85,24,11,10],
[85,24,12,0],
[85,24,12,1],
[85,24,12,2],
[85,24,12,3],
[85,24,12,4],
[85,24,12,5],
[85,24,12,6],
[85,24,12,8],
[85,24,12,9],
[85,24,12,10],
[85,24,13,0],
[85,24,13,1],
[85,24,13,2],
[85,24,13,3],
[85,24,13,4],
[85,24,13,5],
[85,24,13,6],
[85,24,13,8],
[85,24,13,9],
[85,24,13,10],
[85,24,13,11],
[85,24,13,12],
[85,24,14,0],
[85,24,14,1],
[85,24,14,2],
[85,24,14,3],
[85,24,14,4],
[85,24,14,5],
[85,24,14,6],
[85,24,14,8],
[85,24,14,9],
[85,24,14,10],
[85,24,14,11],
[85,24,14,12],
[85,24,15,0],
[85,24,15,1],
[85,24,15,2],
[85,24,15,3],
[85,24,15,4],
[85,24,15,5],
[85,24,15,6],
[85,24,15,8],
[85,24,15,10],
[85,24,15,11],
[85,24,15,12],
[85,24,15,13],
[85,24,17,0],
[85,24,17,1],
[85,24,17,2],
[85,24,17,3],
[85,24,17,4],
[85,24,17,5],
[85,24,17,6],
[85,24,17,8],
[85,24,17,9],
[85,24,17,10],
[85,24,17,11],
[85,24,17,14],
[85,24,17,15],
[85,24,18,0],
[85,24,18,1],
[85,24,18,2],
[85,24,18,3],
[85,24,18,4],
[85,24,18,5],
[85,24,18,6],
[85,24,18,8],
[85,24,18,12],
[85,24,18,13],
[85,24,18,14],
[85,24,18,17],
[85,24,19,0],
[85,24,19,1],
[85,24,19,2],
[85,24,19,3],
[85,24,19,4],
[85,24,19,5],
[85,24,19,6],
[85,24,19,10],
[85,24,19,11],
[85,24,19,12],
[85,24,19,15],
[85,24,19,17],
[85,24,19,18],
[85,24,20,0],
[85,24,20,1],
[85,24,20,2],
[85,24,20,3],
[85,24,20,4],
[85,24,20,5],
[85,24,20,8],
[85,24,20,9],
[85,24,20,11],
[85,24,20,12],
[85,24,20,13],
[85,24,20,14],
[85,24,20,15],
[85,24,20,17],
[85,24,20,18],
[85,24,20,19],
[85,24,21,0],
[85,24,21,1],
[85,24,21,2],
[85,24,21,4],
[85,24,21,9],
[85,24,21,10],
[85,24,21,11],
[85,24,21,12],
[85,24,21,13],
[85,24,21,14],
[85,24,21,15],
[85,24,21,17],
[85,24,21,18],
[85,24,21,19],
[85,24,21,20],
[85,24,22,0],
[85,24,22,1],
[85,24,22,2],
[85,24,22,5],
[85,24,22,6],
[85,24,22,8],
[85,24,22,9],
[85,24,22,11],
[85,24,22,12],
[85,24,22,13],
[85,24,22,14],
[85,24,22,15],
[85,24,22,17],
[85,24,22,18],
[85,24,22,19],
[85,24,22,21],
[85,24,23,3],
[85,24,23,4],
[85,24,23,5],
[85,24,23,6],
[85,24,23,8],
[85,24,23,9],
[85,24,23,10],
[85,24,23,11],
[85,24,23,12],
[85,24,23,13],
[85,24,23,14],
[85,24,23,15],
[85,24,23,17],
[85,24,23,18],
[85,24,23,19],
[85,24,23,20],
[85,26,3,0],
[85,26,3,1],
[85,26,3,2],
[85,26,4,0],
[85,26,4,1],
[85,26,4,2],
[85,26,4,3],
[85,26,5,0],
[85,26,5,1],
[85,26,5,2],
[85,26,5,4],
[85,26,6,0],
[85,26,6,1],
[85,26,6,2],
[85,26,6,4],
[85,26,8,0],
[85,26,8,1],
[85,26,8,2],
[85,26,8,4],
[85,26,10,0],
[85,26,10,1],
[85,26,10,2],
[85,26,10,3],
[85,26,10,4],
[85,26,10,5],
[85,26,10,6],
[85,26,10,8],
[85,26,11,0],
[85,26,11,1],
[85,26,11,2],
[85,26,11,3],
[85,26,11,4],
[85,26,11,5],
[85,26,11,6],
[85,26,11,8],
[85,26,11,10],
[85,26,12,0],
[85,26,12,1],
[85,26,12,2],
[85,26,12,3],
[85,26,12,4],
[85,26,12,5],
[85,26,12,6],
[85,26,12,8],
[85,26,12,10],
[85,26,13,0],
[85,26,13,1],
[85,26,13,2],
[85,26,13,3],
[85,26,13,4],
[85,26,13,5],
[85,26,13,6],
[85,26,13,8],
[85,26,13,10],
[85,26,13,11],
[85,26,14,0],
[85,26,14,1],
[85,26,14,2],
[85,26,14,3],
[85,26,14,4],
[85,26,14,5],
[85,26,14,6],
[85,26,14,8],
[85,26,14,12],
[85,26,16,0],
[85,26,16,1],
[85,26,16,2],
[85,26,16,3],
[85,26,16,4],
[85,26,16,5],
[85,26,16,8],
[85,26,16,10],
[85,26,16,11],
[85,26,16,12],
[85,26,16,13],
[85,26,16,14],
[85,26,17,0],
[85,26,17,1],
[85,26,17,2],
[85,26,17,3],
[85,26,17,4],
[85,26,17,5],
[85,26,17,8],
[85,26,17,10],
[85,26,17,11],
[85,26,17,12],
[85,26,17,13],
[85,26,17,14],
[85,26,17,16],
[85,26,19,0],
[85,26,19,1],
[85,26,19,2],
[85,26,19,5],
[85,26,19,6],
[85,26,19,8],
[85,26,19,10],
[85,26,19,11],
[85,26,19,12],
[85,26,19,16],
[85,26,19,17],
[85,26,20,3],
[85,26,20,4],
[85,26,20,5],
[85,26,20,6],
[85,26,20,8],
[85,26,20,11],
[85,26,20,12],
[85,26,20,13],
[85,26,20,14],
[85,26,20,16],
[85,26,20,17],
[85,26,20,19],
[85,26,21,0],
[85,26,21,1],
[85,26,21,2],
[85,26,21,4],
[85,26,21,10],
[85,26,21,11],
[85,26,21,12],
[85,26,21,13],
[85,26,21,14],
[85,26,21,16],
[85,26,21,17],
[85,26,21,19],
[85,26,22,0],
[85,26,22,1],
[85,26,22,2],
[85,26,22,3],
[85,26,22,4],
[85,26,22,5],
[85,26,22,6],
[85,26,22,8],
[85,26,22,11],
[85,26,22,12],
[85,26,22,13],
[85,26,22,14],
[85,26,22,16],
[85,26,22,17],
[85,26,23,0],
[85,26,23,1],
[85,26,23,2],
[85,26,23,3],
[85,26,23,4],
[85,26,23,5],
[85,26,23,6],
[85,26,23,8],
[85,26,23,10],
[85,26,23,11],
[85,26,23,12],
[85,26,23,13],
[85,26,23,14],
[85,26,23,16],
[85,26,23,17],
[85,26,24,0],
[85,26,24,1],
[85,26,24,2],
[85,26,24,3],
[85,26,24,4],
[85,26,24,5],
[85,26,24,6],
[85,26,24,8],
[85,26,24,10],
[85,26,24,11],
[85,26,24,12],
[85,26,24,13],
[85,26,24,14],
[85,26,24,17],
[85,27,3,0],
[85,27,3,1],
[85,27,3,2],
[85,27,4,0],
[85,27,4,1],
[85,27,4,2],
[85,27,4,3],
[85,27,5,0],
[85,27,5,1],
[85,27,5,2],
[85,27,5,4],
[85,27,6,0],
[85,27,6,1],
[85,27,6,2],
[85,27,6,4],
[85,27,8,0],
[85,27,8,1],
[85,27,8,2],
[85,27,8,4],
[85,27,9,0],
[85,27,9,1],
[85,27,9,2],
[85,27,9,3],
[85,27,9,4],
[85,27,9,5],
[85,27,9,6],
[85,27,9,8],
[85,27,11,0],
[85,27,11,1],
[85,27,11,2],
[85,27,11,3],
[85,27,11,4],
[85,27,11,5],
[85,27,11,6],
[85,27,11,8],
[85,27,11,9],
[85,27,12,0],
[85,27,12,1],
[85,27,12,2],
[85,27,12,3],
[85,27,12,4],
[85,27,12,5],
[85,27,12,6],
[85,27,12,8],
[85,27,12,9],
[85,27,13,0],
[85,27,13,1],
[85,27,13,2],
[85,27,13,3],
[85,27,13,4],
[85,27,13,5],
[85,27,13,6],
[85,27,13,8],
[85,27,13,9],
[85,27,13,12],
[85,27,14,0],
[85,27,14,1],
[85,27,14,2],
[85,27,14,3],
[85,27,14,4],
[85,27,14,5],
[85,27,14,6],
[85,27,14,11],
[85,27,14,12],
[85,27,15,0],
[85,27,15,1],
[85,27,15,2],
[85,27,15,3],
[85,27,15,4],
[85,27,15,5],
[85,27,15,8],
[85,27,15,11],
[85,27,15,12],
[85,27,15,13],
[85,27,15,14],
[85,27,16,0],
[85,27,16,1],
[85,27,16,2],
[85,27,16,3],
[85,27,16,4],
[85,27,16,6],
[85,27,16,8],
[85,27,16,9],
[85,27,16,11],
[85,27,16,12],
[85,27,16,13],
[85,27,16,14],
[85,27,16,15],
[85,27,17,0],
[85,27,17,1],
[85,27,17,2],
[85,27,17,3],
[85,27,17,4],
[85,27,17,6],
[85,27,17,8],
[85,27,17,9],
[85,27,17,11],
[85,27,17,12],
[85,27,17,13],
[85,27,17,14],
[85,27,17,15],
[85,27,17,16],
[85,27,18,0],
[85,27,18,1],
[85,27,18,2],
[85,27,18,5],
[85,27,18,6],
[85,27,18,8],
[85,27,18,11],
[85,27,18,12],
[85,27,18,13],
[85,27,18,14],
[85,27,18,16],
[85,27,18,17],
[85,27,19,3],
[85,27,19,4],
[85,27,19,5],
[85,27,19,6],
[85,27,19,8],
[85,27,19,9],
[85,27,19,11],
[85,27,19,12],
[85,27,19,15],
[85,27,19,16],
[85,27,19,17],
[85,27,19,18],
[85,27,21,0],
[85,27,21,1],
[85,27,21,2],
[85,27,21,4],
[85,27,21,9],
[85,27,21,11],
[85,27,21,12],
[85,27,21,13],
[85,27,21,14],
[85,27,21,15],
[85,27,21,16],
[85,27,21,17],
[85,27,21,18],
[85,27,23,0],
[85,27,23,1],
[85,27,23,2],
[85,27,23,3],
[85,27,23,4],
[85,27,23,5],
[85,27,23,6],
[85,27,23,8],
[85,27,23,9],
[85,27,23,11],
[85,27,23,12],
[85,27,23,13],
[85,27,23,14],
[85,27,23,15],
[85,27,24,0],
[85,27,24,1],
[85,27,24,2],
[85,27,24,3],
[85,27,24,4],
[85,27,24,5],
[85,27,24,6],
[85,27,24,8],
[85,27,24,9],
[85,27,24,11],
[85,27,24,12],
[85,27,24,13],
[85,27,24,14],
[85,27,24,15],
[85,27,26,0],
[85,27,26,1],
[85,27,26,2],
[85,27,26,3],
[85,27,26,4],
[85,27,26,5],
[85,27,26,6],
[85,27,26,8],
[85,27,26,11],
[85,29,3,0],
[85,29,3,1],
[85,29,3,2],
[85,29,4,0],
[85,29,4,1],
[85,29,4,2],
[85,29,4,3],
[85,29,5,0],
[85,29,5,1],
[85,29,5,2],
[85,29,5,4],
[85,29,6,0],
[85,29,6,1],
[85,29,6,2],
[85,29,6,4],
[85,29,8,0],
[85,29,8,1],
[85,29,8,2],
[85,29,8,4],
[85,29,9,0],
[85,29,9,1],
[85,29,9,2],
[85,29,9,3],
[85,29,9,4],
[85,29,9,5],
[85,29,9,6],
[85,29,9,8],
[85,29,10,0],
[85,29,10,1],
[85,29,10,2],
[85,29,10,3],
[85,29,10,4],
[85,29,10,5],
[85,29,10,6],
[85,29,13,0],
[85,29,13,1],
[85,29,13,2],
[85,29,13,3],
[85,29,13,4],
[85,29,13,5],
[85,29,13,8],
[85,29,13,9],
[85,29,13,10],
[85,29,14,0],
[85,29,14,1],
[85,29,14,2],
[85,29,14,3],
[85,29,14,4],
[85,29,14,6],
[85,29,14,8],
[85,29,14,9],
[85,29,14,10],
[85,29,15,0],
[85,29,15,1],
[85,29,15,2],
[85,29,15,5],
[85,29,15,6],
[85,29,15,8],
[85,29,15,10],
[85,29,15,13],
[85,29,15,14],
[85,29,16,3],
[85,29,16,4],
[85,29,16,5],
[85,29,16,6],
[85,29,16,8],
[85,29,16,9],
[85,29,16,10],
[85,29,16,13],
[85,29,16,14],
[85,29,16,15],
[85,29,17,3],
[85,29,17,4],
[85,29,17,5],
[85,29,17,6],
[85,29,17,8],
[85,29,17,9],
[85,29,17,10],
[85,29,17,13],
[85,29,17,14],
[85,29,17,15],
[85,29,17,16],
[85,29,18,0],
[85,29,18,1],
[85,29,18,2],
[85,29,18,3],
[85,29,18,4],
[85,29,18,5],
[85,29,18,6],
[85,29,18,8],
[85,29,18,10],
[85,29,18,13],
[85,29,18,14],
[85,29,18,16],
[85,29,18,17],
[85,29,19,0],
[85,29,19,1],
[85,29,19,2],
[85,29,19,3],
[85,29,19,4],
[85,29,19,5],
[85,29,19,6],
[85,29,19,8],
[85,29,19,9],
[85,29,19,10],
[85,29,19,15],
[85,29,19,16],
[85,29,19,17],
[85,29,19,18],
[85,29,20,0],
[85,29,20,1],
[85,29,20,2],
[85,29,20,3],
[85,29,20,4],
[85,29,20,5],
[85,29,20,6],
[85,29,20,8],
[85,29,20,9],
[85,29,20,13],
[85,29,20,14],
[85,29,20,15],
[85,29,20,16],
[85,29,20,17],
[85,29,21,0],
[85,29,21,1],
[85,29,21,2],
[85,29,21,4],
[85,29,21,9],
[85,29,21,10],
[85,29,21,13],
[85,29,21,14],
[85,29,21,15],
[85,29,22,0],
[85,29,22,1],
[85,29,22,2],
[85,29,22,3],
[85,29,22,4],
[85,29,22,5],
[85,29,22,6],
[85,29,22,8],
[85,29,22,9],
[85,29,22,13],
[85,29,22,14],
[85,29,23,0],
[85,29,23,1],
[85,29,23,2],
[85,29,23,3],
[85,29,23,4],
[85,29,23,5],
[85,29,23,6],
[85,29,23,8],
[85,29,23,9],
[85,29,23,10],
[85,29,23,13],
[85,29,24,0],
[85,29,24,1],
[85,29,24,2],
[85,29,24,3],
[85,29,24,4],
[85,29,24,5],
[85,29,24,6],
[85,29,24,8],
[85,29,24,9],
[85,29,24,10],
[85,29,24,13],
[85,29,26,0],
[85,29,26,1],
[85,29,26,2],
[85,29,26,3],
[85,29,26,4],
[85,29,26,5],
[85,29,26,6],
[85,29,27,0],
[85,29,27,1],
[85,29,27,2],
[85,29,27,3],
[85,29,27,4],
[85,29,27,5],
[85,30,3,0],
[85,30,3,1],
[85,30,3,2],
[85,30,4,0],
[85,30,4,1],
[85,30,4,2],
[85,30,4,3],
[85,30,5,0],
[85,30,5,1],
[85,30,5,2],
[85,30,5,4],
[85,30,6,0],
[85,30,6,1],
[85,30,6,2],
[85,30,6,4],
[85,30,8,0],
[85,30,8,1],
[85,30,8,2],
[85,30,8,4],
[85,30,9,0],
[85,30,9,1],
[85,30,9,2],
[85,30,9,3],
[85,30,9,4],
[85,30,9,5],
[85,30,9,6],
[85,30,10,0],
[85,30,10,1],
[85,30,10,2],
[85,30,10,3],
[85,30,10,4],
[85,30,10,5],
[85,30,10,8],
[85,30,10,9],
[85,30,11,0],
[85,30,11,1],
[85,30,11,2],
[85,30,11,3],
[85,30,11,4],
[85,30,11,5],
[85,30,11,8],
[85,30,11,9],
[85,30,11,10],
[85,30,12,0],
[85,30,12,1],
[85,30,12,2],
[85,30,12,3],
[85,30,12,4],
[85,30,12,6],
[85,30,12,8],
[85,30,12,9],
[85,30,12,10],
[85,30,13,0],
[85,30,13,1],
[85,30,13,2],
[85,30,13,3],
[85,30,13,4],
[85,30,13,6],
[85,30,13,8],
[85,30,13,9],
[85,30,13,10],
[85,30,13,11],
[85,30,13,12],
[85,30,14,0],
[85,30,14,1],
[85,30,14,2],
[85,30,14,5],
[85,30,14,6],
[85,30,14,8],
[85,30,14,9],
[85,30,14,10],
[85,30,14,11],
[85,30,14,12],
[85,30,15,3],
[85,30,15,4],
[85,30,15,5],
[85,30,15,6],
[85,30,15,8],
[85,30,15,10],
[85,30,15,11],
[85,30,15,12],
[85,30,15,13],
[85,30,15,14],
[85,30,16,0],
[85,30,16,1],
[85,30,16,2],
[85,30,16,3],
[85,30,16,4],
[85,30,16,5],
[85,30,16,6],
[85,30,16,8],
[85,30,16,9],
[85,30,16,10],
[85,30,16,11],
[85,30,16,12],
[85,30,16,13],
[85,30,16,14],
[85,30,16,15],
[85,30,17,0],
[85,30,17,1],
[85,30,17,2],
[85,30,17,3],
[85,30,17,4],
[85,30,17,5],
[85,30,17,6],
[85,30,17,8],
[85,30,17,9],
[85,30,17,10],
[85,30,17,11],
[85,30,17,12],
[85,30,17,13],
[85,30,17,14],
[85,30,17,15],
[85,30,17,16],
[85,30,18,0],
[85,30,18,1],
[85,30,18,2],
[85,30,18,3],
[85,30,18,4],
[85,30,18,5],
[85,30,18,6],
[85,30,18,8],
[85,30,18,10],
[85,30,18,11],
[85,30,18,12],
[85,30,18,13],
[85,30,18,14],
[85,30,18,16],
[85,30,18,17],
[85,30,19,0],
[85,30,19,1],
[85,30,19,2],
[85,30,19,3],
[85,30,19,4],
[85,30,19,5],
[85,30,19,6],
[85,30,19,8],
[85,30,19,9],
[85,30,19,10],
[85,30,19,11],
[85,30,19,12],
[85,30,19,15],
[85,30,19,16],
[85,30,19,17],
[85,30,20,0],
[85,30,20,1],
[85,30,20,2],
[85,30,20,3],
[85,30,20,4],
[85,30,20,5],
[85,30,20,6],
[85,30,20,8],
[85,30,20,9],
[85,30,20,11],
[85,30,20,12],
[85,30,20,13],
[85,30,20,14],
[85,30,20,15],
[85,30,21,0],
[85,30,21,1],
[85,30,21,2],
[85,30,21,4],
[85,30,21,9],
[85,30,21,10],
[85,30,21,11],
[85,30,21,12],
[85,30,21,13],
[85,30,21,14],
[85,30,22,0],
[85,30,22,1],
[85,30,22,2],
[85,30,22,3],
[85,30,22,4],
[85,30,22,5],
[85,30,22,6],
[85,30,22,8],
[85,30,22,9],
[85,30,22,11],
[85,30,22,12],
[85,30,22,13],
[85,30,24,0],
[85,30,24,1],
[85,30,24,2],
[85,30,24,3],
[85,30,24,4],
[85,30,24,5],
[85,30,24,6],
[85,30,24,8],
[85,30,24,9],
[85,30,24,10],
[85,30,24,11],
[85,30,26,0],
[85,30,26,1],
[85,30,26,2],
[85,30,26,3],
[85,30,26,4],
[85,30,26,5],
[85,30,27,0],
[85,30,27,1],
[85,30,27,2],
[85,30,27,3],
[85,30,27,4],
[85,31,3,0],
[85,31,3,1],
[85,31,3,2],
[85,31,4,0],
[85,31,4,1],
[85,31,4,2],
[85,31,4,3],
[85,31,5,0],
[85,31,5,1],
[85,31,5,2],
[85,31,5,4],
[85,31,6,0],
[85,31,6,1],
[85,31,6,2],
[85,31,6,4],
[85,31,8,0],
[85,31,8,1],
[85,31,8,2],
[85,31,8,4],
[85,31,9,0],
[85,31,9,1],
[85,31,9,2],
[85,31,9,3],
[85,31,9,4],
[85,31,9,5],
[85,31,9,8],
[85,31,10,0],
[85,31,10,1],
[85,31,10,2],
[85,31,10,3],
[85,31,10,4],
[85,31,10,6],
[85,31,10,8],
[85,31,10,9],
[85,31,11,0],
[85,31,11,1],
[85,31,11,2],
[85,31,11,3],
[85,31,11,4],
[85,31,11,6],
[85,31,11,8],
[85,31,11,9],
[85,31,11,10],
[85,31,12,0],
[85,31,12,1],
[85,31,12,2],
[85,31,12,5],
[85,31,12,6],
[85,31,12,8],
[85,31,12,9],
[85,31,12,10],
[85,31,13,0],
[85,31,13,1],
[85,31,13,2],
[85,31,13,5],
[85,31,13,6],
[85,31,13,8],
[85,31,13,9],
[85,31,13,10],
[85,31,13,11],
[85,31,13,12],
[85,31,14,3],
[85,31,14,4],
[85,31,14,5],
[85,31,14,6],
[85,31,14,8],
[85,31,14,9],
[85,31,14,10],
[85,31,14,11],
[85,31,14,12],
[85,31,15,0],
[85,31,15,1],
[85,31,15,2],
[85,31,15,3],
[85,31,15,4],
[85,31,15,5],
[85,31,15,6],
[85,31,15,8],
[85,31,15,10],
[85,31,15,11],
[85,31,15,12],
[85,31,15,13],
[85,31,15,14],
[85,31,16,0],
[85,31,16,1],
[85,31,16,2],
[85,31,16,3],
[85,31,16,4],
[85,31,16,5],
[85,31,16,6],
[85,31,16,8],
[85,31,16,9],
[85,31,16,10],
[85,31,16,11],
[85,31,16,12],
[85,31,16,13],
[85,31,16,14],
[85,31,16,15],
[85,31,17,0],
[85,31,17,1],
[85,31,17,2],
[85,31,17,3],
[85,31,17,4],
[85,31,17,5],
[85,31,17,6],
[85,31,17,8],
[85,31,17,9],
[85,31,17,10],
[85,31,17,11],
[85,31,17,12],
[85,31,17,13],
[85,31,17,14],
[85,31,17,15],
[85,31,17,16],
[85,31,18,0],
[85,31,18,1],
[85,31,18,2],
[85,31,18,3],
[85,31,18,4],
[85,31,18,5],
[85,31,18,6],
[85,31,18,8],
[85,31,18,10],
[85,31,18,11],
[85,31,18,12],
[85,31,18,13],
[85,31,18,14],
[85,31,18,16],
[85,31,18,17],
[85,31,19,0],
[85,31,19,1],
[85,31,19,2],
[85,31,19,3],
[85,31,19,4],
[85,31,19,5],
[85,31,19,6],
[85,31,19,8],
[85,31,19,9],
[85,31,19,10],
[85,31,19,11],
[85,31,19,12],
[85,31,19,15],
[85,31,20,0],
[85,31,20,1],
[85,31,20,2],
[85,31,20,3],
[85,31,20,4],
[85,31,20,5],
[85,31,20,6],
[85,31,20,8],
[85,31,20,9],
[85,31,20,11],
[85,31,20,12],
[85,31,20,13],
[85,31,20,14],
[85,31,21,0],
[85,31,21,1],
[85,31,21,2],
[85,31,21,4],
[85,31,21,9],
[85,31,21,10],
[85,31,21,11],
[85,31,21,12],
[85,31,21,13],
[85,31,22,0],
[85,31,22,1],
[85,31,22,2],
[85,31,22,3],
[85,31,22,4],
[85,31,22,5],
[85,31,22,6],
[85,31,22,8],
[85,31,22,9],
[85,31,22,11],
[85,31,24,0],
[85,31,24,1],
[85,31,24,2],
[85,31,24,3],
[85,31,24,4],
[85,31,24,5],
[85,31,24,6],
[85,31,24,8],
[85,31,24,9],
[85,31,26,0],
[85,31,26,1],
[85,31,26,2],
[85,31,26,3],
[85,31,26,4],
[85,31,27,0],
[85,31,27,1],
[85,31,27,2],
[85,32,3,0],
[85,32,3,1],
[85,32,3,2],
[85,32,4,0],
[85,32,4,1],
[85,32,4,2],
[85,32,4,3],
[85,32,5,0],
[85,32,5,1],
[85,32,5,2],
[85,32,5,4],
[85,32,6,0],
[85,32,6,1],
[85,32,6,2],
[85,32,6,4],
[85,32,8,0],
[85,32,8,1],
[85,32,8,2],
[85,32,8,4],
[85,32,9,0],
[85,32,9,1],
[85,32,9,2],
[85,32,9,3],
[85,32,9,4],
[85,32,9,6],
[85,32,9,8],
[85,32,10,0],
[85,32,10,1],
[85,32,10,2],
[85,32,10,5],
[85,32,10,6],
[85,32,10,8],
[85,32,10,9],
[85,32,13,3],
[85,32,13,4],
[85,32,13,5],
[85,32,13,6],
[85,32,13,8],
[85,32,13,9],
[85,32,13,10],
[85,32,14,0],
[85,32,14,1],
[85,32,14,2],
[85,32,14,3],
[85,32,14,4],
[85,32,14,5],
[85,32,14,6],
[85,32,14,8],
[85,32,14,9],
[85,32,14,10],
[85,32,15,0],
[85,32,15,1],
[85,32,15,2],
[85,32,15,3],
[85,32,15,4],
[85,32,15,5],
[85,32,15,6],
[85,32,15,8],
[85,32,15,10],
[85,32,15,13],
[85,32,15,14],
[85,32,16,0],
[85,32,16,1],
[85,32,16,2],
[85,32,16,3],
[85,32,16,4],
[85,32,16,5],
[85,32,16,6],
[85,32,16,8],
[85,32,16,9],
[85,32,16,10],
[85,32,16,13],
[85,32,16,14],
[85,32,16,15],
[85,32,17,0],
[85,32,17,1],
[85,32,17,2],
[85,32,17,3],
[85,32,17,4],
[85,32,17,5],
[85,32,17,6],
[85,32,17,8],
[85,32,17,9],
[85,32,17,10],
[85,32,17,13],
[85,32,17,14],
[85,32,17,15],
[85,32,17,16],
[85,32,18,0],
[85,32,18,1],
[85,32,18,2],
[85,32,18,3],
[85,32,18,4],
[85,32,18,5],
[85,32,18,6],
[85,32,18,8],
[85,32,18,10],
[85,32,18,13],
[85,32,18,14],
[85,32,19,0],
[85,32,19,1],
[85,32,19,2],
[85,32,19,3],
[85,32,19,4],
[85,32,19,5],
[85,32,19,6],
[85,32,19,8],
[85,32,19,9],
[85,32,19,10],
[85,32,20,0],
[85,32,20,1],
[85,32,20,2],
[85,32,20,3],
[85,32,20,4],
[85,32,20,5],
[85,32,20,6],
[85,32,20,8],
[85,32,20,9],
[85,32,20,13],
[85,32,21,0],
[85,32,21,1],
[85,32,21,2],
[85,32,21,4],
[85,32,21,9],
[85,32,21,10],
[85,32,22,0],
[85,32,22,1],
[85,32,22,2],
[85,32,22,3],
[85,32,22,4],
[85,32,22,5],
[85,32,22,6],
[85,32,22,8],
[85,32,22,9],
[85,32,23,0],
[85,32,23,1],
[85,32,23,2],
[85,32,23,3],
[85,32,23,4],
[85,32,23,5],
[85,32,23,6],
[85,32,24,0],
[85,32,24,1],
[85,32,24,2],
[85,32,24,3],
[85,32,24,4],
[85,32,24,5],
[85,32,24,6],
[85,32,26,0],
[85,32,26,1],
[85,32,26,2],
[85,33,3,0],
[85,33,3,1],
[85,33,3,2],
[85,33,4,0],
[85,33,4,1],
[85,33,4,2],
[85,33,4,3],
[85,33,5,0],
[85,33,5,1],
[85,33,5,2],
[85,33,5,4],
[85,33,6,0],
[85,33,6,1],
[85,33,6,2],
[85,33,6,4],
[85,33,8,0],
[85,33,8,1],
[85,33,8,2],
[85,33,9,0],
[85,33,9,1],
[85,33,9,2],
[85,33,9,5],
[85,33,9,6],
[85,33,9,8],
[85,33,10,3],
[85,33,10,4],
[85,33,10,5],
[85,33,10,6],
[85,33,10,8],
[85,33,10,9],
[85,33,13,0],
[85,33,13,1],
[85,33,13,2],
[85,33,13,3],
[85,33,13,4],
[85,33,13,5],
[85,33,13,6],
[85,33,13,8],
[85,33,13,9],
[85,33,13,10],
[85,33,14,0],
[85,33,14,1],
[85,33,14,2],
[85,33,14,3],
[85,33,14,4],
[85,33,14,5],
[85,33,14,6],
[85,33,14,8],
[85,33,14,9],
[85,33,14,10],
[85,33,15,0],
[85,33,15,1],
[85,33,15,2],
[85,33,15,3],
[85,33,15,4],
[85,33,15,5],
[85,33,15,6],
[85,33,15,8],
[85,33,15,10],
[85,33,15,13],
[85,33,15,14],
[85,33,16,0],
[85,33,16,1],
[85,33,16,2],
[85,33,16,3],
[85,33,16,4],
[85,33,16,5],
[85,33,16,6],
[85,33,16,8],
[85,33,16,9],
[85,33,16,10],
[85,33,16,13],
[85,33,16,14],
[85,33,16,15],
[85,33,17,0],
[85,33,17,1],
[85,33,17,2],
[85,33,17,3],
[85,33,17,4],
[85,33,17,5],
[85,33,17,6],
[85,33,17,8],
[85,33,17,9],
[85,33,17,10],
[85,33,17,13],
[85,33,17,14],
[85,33,17,15],
[85,33,18,0],
[85,33,18,1],
[85,33,18,2],
[85,33,18,3],
[85,33,18,4],
[85,33,18,5],
[85,33,18,6],
[85,33,18,8],
[85,33,18,10],
[85,33,18,13],
[85,33,18,14],
[85,33,19,0],
[85,33,19,1],
[85,33,19,2],
[85,33,19,3],
[85,33,19,4],
[85,33,19,5],
[85,33,19,6],
[85,33,19,8],
[85,33,19,9],
[85,33,19,10],
[85,33,20,0],
[85,33,20,1],
[85,33,20,2],
[85,33,20,3],
[85,33,20,4],
[85,33,20,5],
[85,33,20,6],
[85,33,20,8],
[85,33,20,9],
[85,33,21,0],
[85,33,21,1],
[85,33,21,2],
[85,33,21,4],
[85,33,21,9],
[85,33,22,0],
[85,33,22,1],
[85,33,22,2],
[85,33,22,3],
[85,33,22,4],
[85,33,22,5],
[85,33,22,6],
[85,33,23,0],
[85,33,23,1],
[85,33,23,2],
[85,33,23,3],
[85,33,23,4],
[85,33,23,5],
[85,33,24,0],
[85,33,24,1],
[85,33,24,2],
[85,33,24,3],
[85,33,24,4],
[85,33,24,5],
[85,35,3,0],
[85,35,3,1],
[85,35,3,2],
[85,35,4,0],
[85,35,4,1],
[85,35,4,2],
[85,35,4,3],
[85,35,5,0],
[85,35,5,1],
[85,35,5,2],
[85,35,6,4],
[85,35,8,0],
[85,35,8,1],
[85,35,8,2],
[85,35,8,4],
[85,35,10,0],
[85,35,10,1],
[85,35,10,2],
[85,35,10,3],
[85,35,10,4],
[85,35,10,5],
[85,35,10,6],
[85,35,10,8],
[85,35,11,0],
[85,35,11,1],
[85,35,11,2],
[85,35,11,3],
[85,35,11,4],
[85,35,11,5],
[85,35,11,6],
[85,35,11,8],
[85,35,11,10],
[85,35,12,0],
[85,35,12,1],
[85,35,12,2],
[85,35,12,3],
[85,35,12,4],
[85,35,12,5],
[85,35,12,6],
[85,35,12,8],
[85,35,12,10],
[85,35,13,0],
[85,35,13,1],
[85,35,13,2],
[85,35,13,3],
[85,35,13,4],
[85,35,13,5],
[85,35,13,6],
[85,35,13,8],
[85,35,13,10],
[85,35,13,11],
[85,35,13,12],
[85,35,14,0],
[85,35,14,1],
[85,35,14,2],
[85,35,14,3],
[85,35,14,4],
[85,35,14,5],
[85,35,14,6],
[85,35,14,8],
[85,35,14,10],
[85,35,14,11],
[85,35,14,12],
[85,35,16,0],
[85,35,16,1],
[85,35,16,2],
[85,35,16,3],
[85,35,16,4],
[85,35,16,5],
[85,35,16,6],
[85,35,16,8],
[85,35,16,10],
[85,35,16,11],
[85,35,16,12],
[85,35,16,13],
[85,35,17,0],
[85,35,17,1],
[85,35,17,2],
[85,35,17,3],
[85,35,17,4],
[85,35,17,5],
[85,35,17,6],
[85,35,17,8],
[85,35,17,10],
[85,35,17,11],
[85,35,17,12],
[85,35,17,13],
[85,35,19,0],
[85,35,19,1],
[85,35,19,2],
[85,35,19,3],
[85,35,19,4],
[85,35,19,5],
[85,35,19,6],
[85,35,19,8],
[85,35,20,0],
[85,35,20,1],
[85,35,20,2],
[85,35,20,3],
[85,35,20,4],
[85,35,20,5],
[85,35,20,6],
[85,35,21,0],
[85,35,21,1],
[85,35,21,2],
[85,35,21,4],
[85,35,22,0],
[85,35,22,1],
[85,35,22,2],
[85,35,22,3],
[85,35,22,4],
[85,35,23,0],
[85,35,23,1],
[85,35,23,2],
[85,35,24,0],
[85,35,24,1],
[85,35,24,2],
[85,36,3,0],
[85,36,3,1],
[85,36,3,2],
[85,36,4,0],
[85,36,4,1],
[85,36,4,2],
[85,36,5,4],
[85,36,6,0],
[85,36,6,1],
[85,36,6,2],
[85,36,6,4],
[85,36,8,0],
[85,36,8,1],
[85,36,8,2],
[85,36,8,4],
[85,36,9,0],
[85,36,9,1],
[85,36,9,2],
[85,36,9,3],
[85,36,9,4],
[85,36,9,5],
[85,36,9,6],
[85,36,9,8],
[85,36,10,0],
[85,36,10,1],
[85,36,10,2],
[85,36,10,3],
[85,36,10,4],
[85,36,10,5],
[85,36,10,6],
[85,36,10,8],
[85,36,10,9],
[85,36,11,0],
[85,36,11,1],
[85,36,11,2],
[85,36,11,3],
[85,36,11,4],
[85,36,11,5],
[85,36,11,6],
[85,36,11,8],
[85,36,11,9],
[85,36,11,10],
[85,36,12,0],
[85,36,12,1],
[85,36,12,2],
[85,36,12,3],
[85,36,12,4],
[85,36,12,5],
[85,36,12,6],
[85,36,12,8],
[85,36,12,9],
[85,36,12,10],
[85,36,15,0],
[85,36,15,1],
[85,36,15,2],
[85,36,15,3],
[85,36,15,4],
[85,36,15,5],
[85,36,15,6],
[85,36,15,8],
[85,36,15,10],
[85,36,15,11],
[85,36,15,12],
[85,36,16,0],
[85,36,16,1],
[85,36,16,2],
[85,36,16,3],
[85,36,16,4],
[85,36,16,5],
[85,36,16,6],
[85,36,16,8],
[85,36,16,9],
[85,36,16,10],
[85,36,16,11],
[85,36,17,0],
[85,36,17,1],
[85,36,17,2],
[85,36,17,3],
[85,36,17,4],
[85,36,17,5],
[85,36,17,6],
[85,36,17,8],
[85,36,17,9],
[85,36,17,10],
[85,36,17,11],
[85,36,18,0],
[85,36,18,1],
[85,36,18,2],
[85,36,18,3],
[85,36,18,4],
[85,36,18,5],
[85,36,18,6],
[85,36,18,8],
[85,36,20,0],
[85,36,20,1],
[85,36,20,2],
[85,36,20,3],
[85,36,20,4],
[85,36,20,5],
[85,36,21,0],
[85,36,21,1],
[85,36,21,2],
[85,36,21,4],
[85,36,22,0],
[85,36,22,1],
[85,36,22,2],
[85,37,5,0],
[85,37,5,1],
[85,37,5,2],
[85,37,6,0],
[85,37,6,1],
[85,37,6,2],
[85,37,8,0],
[85,37,8,1],
[85,37,8,2],
[85,37,9,0],
[85,37,9,1],
[85,37,9,2],
[85,37,9,3],
[85,37,9,5],
[85,37,9,6],
[85,37,9,8],
[85,37,10,0],
[85,37,10,1],
[85,37,10,2],
[85,37,10,3],
[85,37,10,5],
[85,37,10,6],
[85,37,10,8],
[85,37,10,9],
[85,37,11,0],
[85,37,11,1],
[85,37,11,2],
[85,37,11,3],
[85,37,11,5],
[85,37,11,6],
[85,37,11,8],
[85,37,11,9],
[85,37,11,10],
[85,37,12,0],
[85,37,12,1],
[85,37,12,2],
[85,37,12,3],
[85,37,12,5],
[85,37,12,6],
[85,37,12,8],
[85,37,12,9],
[85,37,12,10],
[85,37,13,0],
[85,37,13,1],
[85,37,13,2],
[85,37,13,3],
[85,37,13,5],
[85,37,13,6],
[85,37,13,8],
[85,37,13,9],
[85,37,13,10],
[85,37,13,11],
[85,37,13,12],
[85,37,14,0],
[85,37,14,1],
[85,37,14,2],
[85,37,14,3],
[85,37,14,5],
[85,37,14,6],
[85,37,14,8],
[85,37,14,9],
[85,37,14,10],
[85,37,14,11],
[85,37,14,12],
[85,37,15,0],
[85,37,15,1],
[85,37,15,2],
[85,37,15,3],
[85,37,15,5],
[85,37,15,6],
[85,37,15,8],
[85,37,15,10],
[85,37,15,11],
[85,37,16,0],
[85,37,16,1],
[85,37,16,2],
[85,37,16,3],
[85,37,16,5],
[85,37,16,6],
[85,37,16,8],
[85,37,16,9],
[85,37,17,0],
[85,37,17,1],
[85,37,17,2],
[85,37,17,3],
[85,37,17,5],
[85,37,17,6],
[85,37,17,8],
[85,37,17,9],
[85,37,18,0],
[85,37,18,1],
[85,37,18,2],
[85,37,18,3],
[85,37,18,5],
[85,37,18,6],
[85,37,19,0],
[85,37,19,1],
[85,37,19,2],
[85,37,19,3],
[85,37,19,5],
[85,37,20,0],
[85,37,20,1],
[85,37,20,2],
[85,37,20,3],
[85,37,21,0],
[85,37,21,1],
[85,37,21,2],
[85,39,4,0],
[85,39,4,1],
[85,39,4,2],
[85,39,9,0],
[85,39,9,1],
[85,39,9,2],
[85,39,9,4],
[85,39,10,0],
[85,39,10,1],
[85,39,10,2],
[85,39,10,4],
[85,39,10,9],
[85,39,11,0],
[85,39,11,1],
[85,39,11,2],
[85,39,11,4],
[85,39,11,9],
[85,39,11,10],
[85,39,12,0],
[85,39,12,1],
[85,39,12,2],
[85,39,12,4],
[85,39,12,9],
[85,39,12,10],
[85,39,13,0],
[85,39,13,1],
[85,39,13,2],
[85,39,13,4],
[85,39,13,9],
[85,39,13,10],
[85,39,13,11],
[85,39,14,0],
[85,39,14,1],
[85,39,14,2],
[85,39,14,4],
[85,39,14,9],
[85,39,15,0],
[85,39,15,1],
[85,39,15,2],
[85,39,15,4],
[85,39,16,0],
[85,39,16,1],
[85,39,16,2],
[85,39,16,4],
[85,39,17,0],
[85,39,17,1],
[85,39,17,2],
[85,39,17,4],
[85,39,18,0],
[85,39,18,1],
[85,39,18,2],
[85,39,18,4],
[85,39,19,0],
[85,39,19,1],
[85,39,19,2],
[85,40,3,0],
[85,40,3,1],
[85,40,3,2],
[85,40,4,0],
[85,40,4,1],
[85,40,4,2],
[85,40,4,3],
[85,40,5,0],
[85,40,5,1],
[85,40,5,2],
[85,40,5,4],
[85,40,6,0],
[85,40,6,1],
[85,40,6,2],
[85,40,6,4],
[85,40,8,0],
[85,40,8,1],
[85,40,8,2],
[85,40,8,4],
[85,40,10,0],
[85,40,10,1],
[85,40,10,2],
[85,40,10,3],
[85,40,10,4],
[85,40,10,5],
[85,40,10,6],
[85,40,10,8],
[85,40,11,0],
[85,40,11,1],
[85,40,11,2],
[85,40,11,3],
[85,40,11,4],
[85,40,11,5],
[85,40,11,6],
[85,40,11,8],
[85,40,11,10],
[85,40,12,0],
[85,40,12,1],
[85,40,12,2],
[85,40,12,3],
[85,40,12,4],
[85,40,12,5],
[85,40,12,6],
[85,40,12,8],
[85,40,13,0],
[85,40,13,1],
[85,40,13,2],
[85,40,13,3],
[85,40,13,4],
[85,40,13,5],
[85,40,13,6],
[85,40,13,8],
[85,40,14,0],
[85,40,14,1],
[85,40,14,2],
[85,40,14,3],
[85,40,14,4],
[85,40,14,5],
[85,40,14,6],
[85,40,16,0],
[85,40,16,1],
[85,40,16,2],
[85,40,16,3],
[85,40,16,4],
[85,40,17,0],
[85,40,17,1],
[85,40,17,2],
[85,40,17,3],
[85,40,17,4],
[85,41,3,0],
[85,41,3,1],
[85,41,3,2],
[85,41,4,0],
[85,41,4,1],
[85,41,4,2],
[85,41,4,3],
[85,41,5,0],
[85,41,5,1],
[85,41,5,2],
[85,41,5,4],
[85,41,6,0],
[85,41,6,1],
[85,41,6,2],
[85,41,6,4],
[85,41,8,0],
[85,41,8,1],
[85,41,8,2],
[85,41,8,4],
[85,41,10,0],
[85,41,10,1],
[85,41,10,2],
[85,41,10,3],
[85,41,10,4],
[85,41,10,5],
[85,41,10,6],
[85,41,10,8],
[85,41,11,0],
[85,41,11,1],
[85,41,11,2],
[85,41,11,3],
[85,41,11,4],
[85,41,11,5],
[85,41,11,6],
[85,41,11,8],
[85,41,11,10],
[85,41,12,0],
[85,41,12,1],
[85,41,12,2],
[85,41,12,3],
[85,41,12,4],
[85,41,12,5],
[85,41,12,6],
[85,41,12,8],
[85,41,13,0],
[85,41,13,1],
[85,41,13,2],
[85,41,13,3],
[85,41,13,4],
[85,41,13,5],
[85,41,13,6],
[85,41,13,8],
[85,41,14,0],
[85,41,14,1],
[85,41,14,2],
[85,41,14,3],
[85,41,14,4],
[85,41,14,5],
[85,41,14,6],
[85,41,16,0],
[85,41,16,1],
[85,41,16,2],
[85,41,16,3],
[85,41,16,4],
[85,41,17,0],
[85,41,17,1],
[85,41,17,2],
[85,41,17,3],
[85,41,17,4],
[85,42,3,0],
[85,42,3,1],
[85,42,3,2],
[85,42,4,0],
[85,42,4,1],
[85,42,4,2],
[85,42,4,3],
[85,42,5,0],
[85,42,5,1],
[85,42,5,2],
[85,42,5,4],
[85,42,6,0],
[85,42,6,1],
[85,42,6,2],
[85,42,6,4],
[85,42,8,0],
[85,42,8,1],
[85,42,8,2],
[85,42,8,4],
[85,42,9,0],
[85,42,9,1],
[85,42,9,2],
[85,42,9,3],
[85,42,9,4],
[85,42,9,5],
[85,42,9,6],
[85,42,9,8],
[85,42,11,0],
[85,42,11,1],
[85,42,11,2],
[85,42,11,3],
[85,42,11,4],
[85,42,11,5],
[85,42,11,6],
[85,42,11,8],
[85,42,11,9],
[85,42,12,0],
[85,42,12,1],
[85,42,12,2],
[85,42,12,3],
[85,42,12,4],
[85,42,12,5],
[85,42,12,6],
[85,42,13,0],
[85,42,13,1],
[85,42,13,2],
[85,42,13,3],
[85,42,13,4],
[85,42,13,5],
[85,42,13,6],
[85,42,14,0],
[85,42,14,1],
[85,42,14,2],
[85,42,14,3],
[85,42,14,4],
[85,42,14,5],
[85,42,15,0],
[85,42,15,1],
[85,42,15,2],
[85,42,15,3],
[85,42,15,4],
[85,42,16,0],
[85,42,16,1],
[85,42,16,2],
[85,42,17,0],
[85,42,17,1],
[85,42,17,2],
[85,44,3,0],
[85,44,3,1],
[85,44,3,2],
[85,44,4,0],
[85,44,4,1],
[85,44,4,2],
[85,44,4,3],
[85,44,5,0],
[85,44,5,1],
[85,44,5,2],
[85,44,5,4],
[85,44,6,0],
[85,44,6,1],
[85,44,6,2],
[85,44,6,4],
[85,44,8,0],
[85,44,8,1],
[85,44,8,2],
[85,44,8,4],
[85,44,9,0],
[85,44,9,1],
[85,44,9,2],
[85,44,9,3],
[85,44,9,4],
[85,44,9,5],
[85,44,9,6],
[85,44,10,0],
[85,44,10,1],
[85,44,10,2],
[85,44,10,3],
[85,44,10,4],
[85,44,10,5],
[85,44,11,0],
[85,44,11,1],
[85,44,11,2],
[85,44,11,3],
[85,44,11,4],
[85,44,11,5],
[85,44,12,0],
[85,44,12,1],
[85,44,12,2],
[85,44,12,3],
[85,44,12,4],
[85,44,13,0],
[85,44,13,1],
[85,44,13,2],
[85,44,13,3],
[85,44,13,4],
[85,44,14,0],
[85,44,14,1],
[85,44,14,2],
[85,45,3,0],
[85,45,3,1],
[85,45,3,2],
[85,45,4,0],
[85,45,4,1],
[85,45,4,2],
[85,45,4,3],
[85,45,5,0],
[85,45,5,1],
[85,45,5,2],
[85,45,5,4],
[85,45,6,0],
[85,45,6,1],
[85,45,6,2],
[85,45,6,4],
[85,45,8,0],
[85,45,8,1],
[85,45,8,2],
[85,45,8,4],
[85,45,10,0],
[85,45,10,1],
[85,45,10,2],
[85,45,10,3],
[85,45,10,4],
[85,45,11,0],
[85,45,11,1],
[85,45,11,2],
[85,45,11,3],
[85,45,11,4],
[85,45,12,0],
[85,45,12,1],
[85,45,12,2],
[85,45,13,0],
[85,45,13,1],
[85,45,13,2],
[85,46,3,0],
[85,46,3,1],
[85,46,3,2],
[85,46,4,0],
[85,46,4,1],
[85,46,4,2],
[85,46,4,3],
[85,46,5,0],
[85,46,5,1],
[85,46,5,2],
[85,46,5,4],
[85,46,6,0],
[85,46,6,1],
[85,46,6,2],
[85,46,6,4],
[85,46,8,0],
[85,46,8,1],
[85,46,8,2],
[85,46,8,4],
[85,46,10,0],
[85,46,10,1],
[85,46,10,2],
[85,46,11,0],
[85,46,11,1],
[85,46,11,2],
[85,47,3,0],
[85,47,3,1],
[85,47,3,2],
[85,47,4,0],
[85,47,4,1],
[85,47,4,2],
[85,47,4,3],
[85,47,5,0],
[85,47,5,1],
[85,47,5,2],
[85,47,5,4],
[85,47,6,0],
[85,47,6,1],
[85,47,6,2],
[85,47,6,4],
[85,47,8,0],
[85,47,8,1],
[85,47,8,2],
[85,47,9,0],
[85,47,9,1],
[85,47,9,2],
[85,48,3,0],
[85,48,3,1],
[85,48,3,2],
[85,48,4,0],
[85,48,4,1],
[85,48,4,2],
[85,48,4,3],
[85,48,5,0],
[85,48,5,1],
[85,48,5,2],
[85,48,5,4],
[85,48,6,0],
[85,48,6,1],
[85,48,6,2],
[85,49,3,0],
[85,49,3,1],
[85,49,3,2],
[85,49,4,0],
[85,49,4,1],
[85,49,4,2],
[85,49,4,3],
[85,49,5,0],
[85,49,5,1],
[85,49,5,2],
[85,49,5,4],
[85,49,6,0],
[85,49,6,1],
[85,49,6,2],
[85,50,3,0],
[85,50,3,1],
[85,50,3,2],
[85,50,4,0],
[85,50,4,1],
[85,50,4,2],
[85,50,4,3],
[85,50,5,0],
[85,50,5,1],
[85,50,5,2],
[85,50,5,4],
[85,50,6,0],
[85,50,6,1],
[85,50,6,2],
[85,51,4,0],
[85,51,4,1],
[85,51,4,2],
[85,52,3,0],
[85,52,3,1],
[85,52,3,2],
[85,52,4,0],
[85,52,4,1],
[85,52,4,2],
[85,52,4,3],
[85,52,5,0],
[85,52,5,1],
[85,52,5,2],
[85,52,5,4],
[85,52,6,0],
[85,52,6,1],
[85,52,6,2],
[85,53,3,0],
[85,53,3,1],
[85,53,3,2],
[85,53,4,0],
[85,53,4,1],
[85,53,4,2],
[85,53,4,3],
[85,53,5,0],
[85,53,5,1],
[85,53,5,2],
[85,53,5,4],
[85,53,6,0],
[85,53,6,1],
[85,53,6,2],
[85,54,3,0],
[85,54,3,1],
[85,54,3,2],
[85,54,4,0],
[85,54,4,1],
[85,54,4,2],
[85,54,4,3],
[85,54,5,0],
[85,54,5,1],
[85,54,5,2],
[85,54,5,4],
[85,54,6,0],
[85,54,6,1],
[85,54,6,2],
[85,55,3,0],
[85,55,3,1],
[85,55,3,2],
[85,55,4,0],
[85,55,4,1],
[85,55,4,2],
[85,55,4,3],
[85,55,5,0],
[85,55,5,1],
[85,55,5,2],
[85,55,5,4],
[85,55,6,0],
[85,55,6,1],
[85,55,6,2],
[85,56,3,0],
[85,56,3,1],
[85,56,3,2],
[85,56,4,0],
[85,56,4,1],
[85,56,4,2],
[85,56,4,3],
[85,56,5,0],
[85,56,5,1],
[85,56,5,2],
[85,56,5,4],
[85,56,6,0],
[85,56,6,1],
[85,56,6,2],
[85,57,3,0],
[85,57,3,1],
[85,57,3,2],
[85,57,4,0],
[85,57,4,1],
[85,57,4,2],
[85,57,4,3],
[85,57,5,0],
[85,57,5,1],
[85,57,5,2],
[85,58,3,0],
[85,58,3,1],
[85,58,3,2],
[85,58,4,0],
[85,58,4,1],
[85,58,4,2],
[85,58,4,3],
[85,58,5,0],
[85,58,5,1],
[85,58,5,2],
[85,59,3,0],
[85,59,3,1],
[85,59,3,2],
[85,59,4,0],
[85,59,4,1],
[85,59,4,2],
[86,4,3,0],
[86,4,3,1],
[86,4,3,2],
[86,5,4,0],
[86,5,4,1],
[86,5,4,2],
[86,6,4,0],
[86,6,4,1],
[86,6,4,2],
[86,7,3,0],
[86,7,3,1],
[86,7,3,2],
[86,7,4,0],
[86,7,4,1],
[86,7,4,2],
[86,7,4,3],
[86,7,5,0],
[86,7,5,1],
[86,7,5,2],
[86,7,5,4],
[86,7,6,0],
[86,7,6,1],
[86,7,6,2],
[86,7,6,4],
[86,8,4,0],
[86,8,4,1],
[86,8,4,2],
[86,8,7,0],
[86,8,7,1],
[86,8,7,2],
[86,8,7,4],
[86,10,3,0],
[86,10,3,1],
[86,10,3,2],
[86,10,4,0],
[86,10,4,1],
[86,10,4,2],
[86,10,4,3],
[86,10,5,0],
[86,10,5,1],
[86,10,5,2],
[86,10,5,4],
[86,10,6,0],
[86,10,6,1],
[86,10,6,2],
[86,10,6,4],
[86,10,7,0],
[86,10,7,1],
[86,10,7,2],
[86,10,7,3],
[86,10,7,4],
[86,10,7,5],
[86,10,7,6],
[86,10,8,0],
[86,10,8,1],
[86,10,8,2],
[86,10,8,4],
[86,10,8,7],
[86,11,3,0],
[86,11,3,1],
[86,11,3,2],
[86,11,4,0],
[86,11,4,1],
[86,11,4,2],
[86,11,4,3],
[86,11,5,0],
[86,11,5,1],
[86,11,5,2],
[86,11,5,4],
[86,11,6,0],
[86,11,6,1],
[86,11,6,2],
[86,11,6,4],
[86,11,7,0],
[86,11,7,1],
[86,11,7,2],
[86,11,7,3],
[86,11,7,4],
[86,11,7,5],
[86,11,7,6],
[86,11,8,0],
[86,11,8,1],
[86,11,8,2],
[86,11,8,4],
[86,11,8,7],
[86,11,10,0],
[86,11,10,1],
[86,11,10,2],
[86,11,10,3],
[86,11,10,4],
[86,11,10,5],
[86,11,10,6],
[86,11,10,7],
[86,11,10,8],
[86,12,3,0],
[86,12,3,1],
[86,12,3,2],
[86,12,4,0],
[86,12,4,1],
[86,12,4,2],
[86,12,4,3],
[86,12,5,0],
[86,12,5,1],
[86,12,5,2],
[86,12,5,4],
[86,12,6,0],
[86,12,6,1],
[86,12,6,2],
[86,12,6,4],
[86,12,7,0],
[86,12,7,1],
[86,12,7,2],
[86,12,7,3],
[86,12,7,4],
[86,12,7,5],
[86,12,7,6],
[86,12,8,0],
[86,12,8,1],
[86,12,8,2],
[86,12,8,4],
[86,12,8,7],
[86,12,10,0],
[86,12,10,1],
[86,12,10,2],
[86,12,10,3],
[86,12,10,4],
[86,12,10,5],
[86,12,10,6],
[86,12,10,7],
[86,12,10,8],
[86,13,3,0],
[86,13,3,1],
[86,13,3,2],
[86,13,4,0],
[86,13,4,1],
[86,13,4,2],
[86,13,4,3],
[86,13,5,0],
[86,13,5,1],
[86,13,5,2],
[86,13,5,4],
[86,13,6,0],
[86,13,6,1],
[86,13,6,2],
[86,13,6,4],
[86,13,7,0],
[86,13,7,1],
[86,13,7,2],
[86,13,7,3],
[86,13,7,4],
[86,13,7,5],
[86,13,7,6],
[86,13,8,0],
[86,13,8,1],
[86,13,8,2],
[86,13,8,4],
[86,13,8,7],
[86,13,10,0],
[86,13,10,1],
[86,13,10,2],
[86,13,10,3],
[86,13,10,4],
[86,13,10,5],
[86,13,10,6],
[86,13,10,7],
[86,13,10,8],
[86,13,11,0],
[86,13,11,1],
[86,13,11,2],
[86,13,11,3],
[86,13,11,4],
[86,13,11,5],
[86,13,11,6],
[86,13,11,7],
[86,13,11,8],
[86,13,11,10],
[86,13,12,0],
[86,13,12,1],
[86,13,12,2],
[86,13,12,3],
[86,13,12,4],
[86,13,12,5],
[86,13,12,6],
[86,13,12,7],
[86,13,12,8],
[86,13,12,10],
[86,14,3,0],
[86,14,3,1],
[86,14,3,2],
[86,14,4,0],
[86,14,4,1],
[86,14,4,2],
[86,14,4,3],
[86,14,5,0],
[86,14,5,1],
[86,14,5,2],
[86,14,5,4],
[86,14,6,0],
[86,14,6,1],
[86,14,6,2],
[86,14,6,4],
[86,14,7,0],
[86,14,7,1],
[86,14,7,2],
[86,14,7,3],
[86,14,7,4],
[86,14,7,5],
[86,14,7,6],
[86,14,8,0],
[86,14,8,1],
[86,14,8,2],
[86,14,8,4],
[86,14,8,7],
[86,14,10,0],
[86,14,10,1],
[86,14,10,2],
[86,14,10,3],
[86,14,10,4],
[86,14,10,5],
[86,14,10,6],
[86,14,10,7],
[86,14,10,8],
[86,14,11,0],
[86,14,11,1],
[86,14,11,2],
[86,14,11,3],
[86,14,11,4],
[86,14,11,5],
[86,14,11,6],
[86,14,11,7],
[86,14,11,8],
[86,14,11,10],
[86,14,12,0],
[86,14,12,1],
[86,14,12,2],
[86,14,12,3],
[86,14,12,4],
[86,14,12,5],
[86,14,12,6],
[86,14,12,7],
[86,14,12,8],
[86,14,12,10],
[86,16,3,0],
[86,16,3,1],
[86,16,3,2],
[86,16,4,0],
[86,16,4,1],
[86,16,4,2],
[86,16,4,3],
[86,16,5,0],
[86,16,5,1],
[86,16,5,2],
[86,16,5,4],
[86,16,6,0],
[86,16,6,1],
[86,16,6,2],
[86,16,6,4],
[86,16,7,0],
[86,16,7,1],
[86,16,7,2],
[86,16,7,3],
[86,16,7,4],
[86,16,7,5],
[86,16,7,6],
[86,16,8,0],
[86,16,8,1],
[86,16,8,2],
[86,16,8,4],
[86,16,8,7],
[86,16,10,0],
[86,16,10,1],
[86,16,10,2],
[86,16,10,3],
[86,16,10,4],
[86,16,10,5],
[86,16,10,6],
[86,16,10,7],
[86,16,10,8],
[86,16,11,0],
[86,16,11,1],
[86,16,11,2],
[86,16,11,3],
[86,16,11,4],
[86,16,11,5],
[86,16,11,6],
[86,16,11,7],
[86,16,11,8],
[86,16,11,10],
[86,16,12,0],
[86,16,12,1],
[86,16,12,2],
[86,16,12,3],
[86,16,12,4],
[86,16,12,5],
[86,16,12,6],
[86,16,12,7],
[86,16,12,8],
[86,16,12,10],
[86,16,13,0],
[86,16,13,1],
[86,16,13,2],
[86,16,13,3],
[86,16,13,4],
[86,16,13,5],
[86,16,13,6],
[86,16,13,7],
[86,16,13,8],
[86,16,13,10],
[86,16,13,11],
[86,16,13,12],
[86,16,14,0],
[86,16,14,1],
[86,16,14,2],
[86,16,14,3],
[86,16,14,4],
[86,16,14,5],
[86,16,14,6],
[86,16,14,7],
[86,16,14,8],
[86,16,14,10],
[86,16,14,11],
[86,16,14,12],
[86,17,3,0],
[86,17,3,1],
[86,17,3,2],
[86,17,4,0],
[86,17,4,1],
[86,17,4,2],
[86,17,4,3],
[86,17,5,0],
[86,17,5,1],
[86,17,5,2],
[86,17,5,4],
[86,17,6,0],
[86,17,6,1],
[86,17,6,2],
[86,17,6,4],
[86,17,7,0],
[86,17,7,1],
[86,17,7,2],
[86,17,7,3],
[86,17,7,4],
[86,17,7,5],
[86,17,7,6],
[86,17,8,0],
[86,17,8,1],
[86,17,8,2],
[86,17,8,4],
[86,17,8,7],
[86,17,10,0],
[86,17,10,1],
[86,17,10,2],
[86,17,10,3],
[86,17,10,4],
[86,17,10,5],
[86,17,10,6],
[86,17,10,7],
[86,17,10,8],
[86,17,11,0],
[86,17,11,1],
[86,17,11,2],
[86,17,11,3],
[86,17,11,4],
[86,17,11,5],
[86,17,11,6],
[86,17,11,7],
[86,17,11,8],
[86,17,11,10],
[86,17,12,0],
[86,17,12,1],
[86,17,12,2],
[86,17,12,3],
[86,17,12,4],
[86,17,12,5],
[86,17,12,6],
[86,17,12,7],
[86,17,12,8],
[86,17,12,10],
[86,17,13,0],
[86,17,13,1],
[86,17,13,2],
[86,17,13,3],
[86,17,13,4],
[86,17,13,5],
[86,17,13,6],
[86,17,13,7],
[86,17,13,8],
[86,17,13,10],
[86,17,13,11],
[86,17,13,12],
[86,17,14,0],
[86,17,14,1],
[86,17,14,2],
[86,17,14,3],
[86,17,14,4],
[86,17,14,5],
[86,17,14,6],
[86,17,14,7],
[86,17,14,8],
[86,17,14,10],
[86,17,14,11],
[86,17,14,12],
[86,17,16,0],
[86,17,16,1],
[86,17,16,2],
[86,17,16,3],
[86,17,16,4],
[86,17,16,5],
[86,17,16,6],
[86,17,16,7],
[86,17,16,8],
[86,17,16,10],
[86,17,16,11],
[86,17,16,12],
[86,17,16,13],
[86,17,16,14],
[86,19,3,0],
[86,19,3,1],
[86,19,3,2],
[86,19,4,0],
[86,19,4,1],
[86,19,4,2],
[86,19,4,3],
[86,19,5,0],
[86,19,5,1],
[86,19,5,2],
[86,19,5,4],
[86,19,6,0],
[86,19,6,1],
[86,19,6,2],
[86,19,6,4],
[86,19,7,0],
[86,19,7,1],
[86,19,7,2],
[86,19,7,3],
[86,19,7,4],
[86,19,7,5],
[86,19,7,6],
[86,19,8,0],
[86,19,8,1],
[86,19,8,2],
[86,19,8,4],
[86,19,8,7],
[86,19,10,0],
[86,19,10,1],
[86,19,10,2],
[86,19,10,3],
[86,19,10,4],
[86,19,10,5],
[86,19,10,6],
[86,19,10,7],
[86,19,10,8],
[86,19,11,0],
[86,19,11,1],
[86,19,11,2],
[86,19,11,3],
[86,19,11,4],
[86,19,11,5],
[86,19,11,6],
[86,19,11,7],
[86,19,11,8],
[86,19,11,10],
[86,19,12,0],
[86,19,12,1],
[86,19,12,2],
[86,19,12,3],
[86,19,12,4],
[86,19,12,5],
[86,19,12,6],
[86,19,12,7],
[86,19,12,8],
[86,19,12,10],
[86,19,16,0],
[86,19,16,1],
[86,19,16,2],
[86,19,16,3],
[86,19,16,4],
[86,19,16,5],
[86,19,16,6],
[86,19,16,7],
[86,19,16,8],
[86,19,16,10],
[86,19,16,11],
[86,19,16,12],
[86,19,17,0],
[86,19,17,1],
[86,19,17,2],
[86,19,17,3],
[86,19,17,4],
[86,19,17,5],
[86,19,17,6],
[86,19,17,7],
[86,19,17,8],
[86,19,17,10],
[86,19,17,11],
[86,19,17,12],
[86,20,3,0],
[86,20,3,1],
[86,20,3,2],
[86,20,4,0],
[86,20,4,1],
[86,20,4,2],
[86,20,4,3],
[86,20,5,0],
[86,20,5,1],
[86,20,5,2],
[86,20,5,4],
[86,20,6,0],
[86,20,6,1],
[86,20,6,2],
[86,20,6,4],
[86,20,7,0],
[86,20,7,1],
[86,20,7,2],
[86,20,7,3],
[86,20,7,4],
[86,20,7,5],
[86,20,7,6],
[86,20,8,0],
[86,20,8,1],
[86,20,8,2],
[86,20,8,4],
[86,20,8,7],
[86,20,11,0],
[86,20,11,1],
[86,20,11,2],
[86,20,11,3],
[86,20,11,4],
[86,20,11,5],
[86,20,11,6],
[86,20,11,7],
[86,20,11,8],
[86,20,12,0],
[86,20,12,1],
[86,20,12,2],
[86,20,12,3],
[86,20,12,4],
[86,20,12,5],
[86,20,12,6],
[86,20,12,7],
[86,20,12,8],
[86,20,13,0],
[86,20,13,1],
[86,20,13,2],
[86,20,13,3],
[86,20,13,4],
[86,20,13,5],
[86,20,13,6],
[86,20,13,7],
[86,20,13,8],
[86,20,13,11],
[86,20,13,12],
[86,20,14,0],
[86,20,14,1],
[86,20,14,2],
[86,20,14,3],
[86,20,14,4],
[86,20,14,5],
[86,20,14,6],
[86,20,14,7],
[86,20,14,8],
[86,20,14,11],
[86,20,14,12],
[86,20,16,0],
[86,20,16,1],
[86,20,16,2],
[86,20,16,3],
[86,20,16,4],
[86,20,16,5],
[86,20,16,6],
[86,20,16,7],
[86,20,16,8],
[86,20,16,11],
[86,20,16,12],
[86,20,16,13],
[86,20,16,14],
[86,20,17,0],
[86,20,17,1],
[86,20,17,2],
[86,20,17,3],
[86,20,17,4],
[86,20,17,5],
[86,20,17,6],
[86,20,17,7],
[86,20,17,8],
[86,20,17,11],
[86,20,17,12],
[86,20,17,13],
[86,20,17,14],
[86,20,17,16],
[86,20,19,0],
[86,20,19,1],
[86,20,19,2],
[86,20,19,3],
[86,20,19,4],
[86,20,19,5],
[86,20,19,6],
[86,20,19,7],
[86,20,19,8],
[86,20,19,11],
[86,20,19,16],
[86,20,19,17],
[86,21,4,0],
[86,21,4,1],
[86,21,4,2],
[86,21,7,0],
[86,21,7,1],
[86,21,7,2],
[86,21,7,4],
[86,21,10,0],
[86,21,10,1],
[86,21,10,2],
[86,21,10,4],
[86,21,10,7],
[86,21,11,0],
[86,21,11,1],
[86,21,11,2],
[86,21,11,4],
[86,21,11,7],
[86,21,11,10],
[86,21,12,0],
[86,21,12,1],
[86,21,12,2],
[86,21,12,4],
[86,21,12,7],
[86,21,12,10],
[86,21,13,0],
[86,21,13,1],
[86,21,13,2],
[86,21,13,4],
[86,21,13,7],
[86,21,13,10],
[86,21,13,11],
[86,21,13,12],
[86,21,14,0],
[86,21,14,1],
[86,21,14,2],
[86,21,14,4],
[86,21,14,7],
[86,21,14,10],
[86,21,14,11],
[86,21,14,12],
[86,21,16,0],
[86,21,16,1],
[86,21,16,2],
[86,21,16,4],
[86,21,16,7],
[86,21,16,10],
[86,21,16,11],
[86,21,16,12],
[86,21,16,13],
[86,21,17,0],
[86,21,17,1],
[86,21,17,2],
[86,21,17,4],
[86,21,17,7],
[86,21,17,10],
[86,21,17,11],
[86,21,17,12],
[86,21,17,13],
[86,21,17,16],
[86,21,19,0],
[86,21,19,1],
[86,21,19,2],
[86,21,19,4],
[86,21,19,7],
[86,21,19,12],
[86,21,19,16],
[86,21,19,17],
[86,21,20,0],
[86,21,20,1],
[86,21,20,2],
[86,21,20,4],
[86,21,20,7],
[86,21,20,11],
[86,21,20,12],
[86,21,20,13],
[86,21,20,14],
[86,21,20,16],
[86,21,20,17],
[86,21,20,19],
[86,22,3,0],
[86,22,3,1],
[86,22,3,2],
[86,22,4,0],
[86,22,4,1],
[86,22,4,2],
[86,22,4,3],
[86,22,5,0],
[86,22,5,1],
[86,22,5,2],
[86,22,5,4],
[86,22,6,0],
[86,22,6,1],
[86,22,6,2],
[86,22,6,4],
[86,22,7,0],
[86,22,7,1],
[86,22,7,2],
[86,22,7,3],
[86,22,7,4],
[86,22,7,5],
[86,22,7,6],
[86,22,8,0],
[86,22,8,1],
[86,22,8,2],
[86,22,8,4],
[86,22,8,7],
[86,22,11,0],
[86,22,11,1],
[86,22,11,2],
[86,22,11,3],
[86,22,11,4],
[86,22,11,5],
[86,22,11,6],
[86,22,11,7],
[86,22,11,8],
[86,22,12,0],
[86,22,12,1],
[86,22,12,2],
[86,22,12,3],
[86,22,12,4],
[86,22,12,5],
[86,22,12,6],
[86,22,12,7],
[86,22,12,8],
[86,22,13,0],
[86,22,13,1],
[86,22,13,2],
[86,22,13,3],
[86,22,13,4],
[86,22,13,5],
[86,22,13,6],
[86,22,13,7],
[86,22,13,8],
[86,22,13,11],
[86,22,13,12],
[86,22,14,0],
[86,22,14,1],
[86,22,14,2],
[86,22,14,3],
[86,22,14,4],
[86,22,14,5],
[86,22,14,6],
[86,22,14,7],
[86,22,14,8],
[86,22,14,11],
[86,22,14,12],
[86,22,16,0],
[86,22,16,1],
[86,22,16,2],
[86,22,16,3],
[86,22,16,4],
[86,22,16,5],
[86,22,16,6],
[86,22,16,7],
[86,22,16,8],
[86,22,16,11],
[86,22,16,14],
[86,22,17,0],
[86,22,17,1],
[86,22,17,2],
[86,22,17,3],
[86,22,17,4],
[86,22,17,5],
[86,22,17,6],
[86,22,17,7],
[86,22,17,8],
[86,22,17,11],
[86,22,17,14],
[86,22,17,16],
[86,22,19,0],
[86,22,19,1],
[86,22,19,2],
[86,22,19,3],
[86,22,19,4],
[86,22,19,5],
[86,22,19,6],
[86,22,19,7],
[86,22,19,11],
[86,22,19,12],
[86,22,19,16],
[86,22,19,17],
[86,22,21,0],
[86,22,21,1],
[86,22,21,2],
[86,22,21,4],
[86,22,21,7],
[86,22,21,11],
[86,22,21,12],
[86,22,21,13],
[86,22,21,14],
[86,22,21,16],
[86,22,21,17],
[86,22,21,19],
[86,23,3,0],
[86,23,3,1],
[86,23,3,2],
[86,23,4,0],
[86,23,4,1],
[86,23,4,2],
[86,23,4,3],
[86,23,5,0],
[86,23,5,1],
[86,23,5,2],
[86,23,5,4],
[86,23,6,0],
[86,23,6,1],
[86,23,6,2],
[86,23,6,4],
[86,23,7,0],
[86,23,7,1],
[86,23,7,2],
[86,23,7,3],
[86,23,7,4],
[86,23,7,5],
[86,23,7,6],
[86,23,8,0],
[86,23,8,1],
[86,23,8,2],
[86,23,8,4],
[86,23,8,7],
[86,23,10,0],
[86,23,10,1],
[86,23,10,2],
[86,23,10,3],
[86,23,10,4],
[86,23,10,5],
[86,23,10,6],
[86,23,10,7],
[86,23,10,8],
[86,23,11,0],
[86,23,11,1],
[86,23,11,2],
[86,23,11,3],
[86,23,11,4],
[86,23,11,5],
[86,23,11,6],
[86,23,11,7],
[86,23,11,8],
[86,23,11,10],
[86,23,12,0],
[86,23,12,1],
[86,23,12,2],
[86,23,12,3],
[86,23,12,4],
[86,23,12,5],
[86,23,12,6],
[86,23,12,7],
[86,23,12,8],
[86,23,12,10],
[86,23,13,0],
[86,23,13,1],
[86,23,13,2],
[86,23,13,3],
[86,23,13,4],
[86,23,13,5],
[86,23,13,6],
[86,23,13,7],
[86,23,13,8],
[86,23,13,10],
[86,23,13,11],
[86,23,13,12],
[86,23,14,0],
[86,23,14,1],
[86,23,14,2],
[86,23,14,3],
[86,23,14,4],
[86,23,14,5],
[86,23,14,6],
[86,23,14,7],
[86,23,14,8],
[86,23,14,10],
[86,23,14,11],
[86,23,14,12],
[86,23,16,0],
[86,23,16,1],
[86,23,16,2],
[86,23,16,3],
[86,23,16,4],
[86,23,16,5],
[86,23,16,6],
[86,23,16,7],
[86,23,16,8],
[86,23,16,12],
[86,23,16,13],
[86,23,16,14],
[86,23,17,0],
[86,23,17,1],
[86,23,17,2],
[86,23,17,3],
[86,23,17,4],
[86,23,17,5],
[86,23,17,6],
[86,23,17,7],
[86,23,17,8],
[86,23,17,12],
[86,23,17,13],
[86,23,17,14],
[86,23,17,16],
[86,23,19,0],
[86,23,19,1],
[86,23,19,2],
[86,23,19,3],
[86,23,19,4],
[86,23,19,5],
[86,23,19,8],
[86,23,19,10],
[86,23,19,11],
[86,23,19,12],
[86,23,19,16],
[86,23,19,17],
[86,23,20,0],
[86,23,20,1],
[86,23,20,2],
[86,23,20,3],
[86,23,20,4],
[86,23,20,6],
[86,23,20,7],
[86,23,20,8],
[86,23,20,11],
[86,23,20,12],
[86,23,20,13],
[86,23,20,14],
[86,23,20,16],
[86,23,20,17],
[86,23,20,19],
[86,23,21,0],
[86,23,21,1],
[86,23,21,2],
[86,23,21,7],
[86,23,21,10],
[86,23,21,11],
[86,23,21,12],
[86,23,21,13],
[86,23,21,14],
[86,23,21,16],
[86,23,21,17],
[86,23,21,19],
[86,23,21,20],
[86,23,22,3],
[86,23,22,4],
[86,23,22,5],
[86,23,22,6],
[86,23,22,7],
[86,23,22,8],
[86,23,22,11],
[86,23,22,12],
[86,23,22,13],
[86,23,22,14],
[86,23,22,16],
[86,23,22,17],
[86,23,22,19],
[86,24,3,0],
[86,24,3,1],
[86,24,3,2],
[86,24,4,0],
[86,24,4,1],
[86,24,4,2],
[86,24,4,3],
[86,24,5,0],
[86,24,5,1],
[86,24,5,2],
[86,24,5,4],
[86,24,6,0],
[86,24,6,1],
[86,24,6,2],
[86,24,6,4],
[86,24,7,0],
[86,24,7,1],
[86,24,7,2],
[86,24,7,3],
[86,24,7,4],
[86,24,7,5],
[86,24,7,6],
[86,24,8,0],
[86,24,8,1],
[86,24,8,2],
[86,24,8,4],
[86,24,8,7],
[86,24,10,0],
[86,24,10,1],
[86,24,10,2],
[86,24,10,3],
[86,24,10,4],
[86,24,10,5],
[86,24,10,6],
[86,24,10,7],
[86,24,10,8],
[86,24,11,0],
[86,24,11,1],
[86,24,11,2],
[86,24,11,3],
[86,24,11,4],
[86,24,11,5],
[86,24,11,6],
[86,24,11,7],
[86,24,11,8],
[86,24,11,10],
[86,24,12,0],
[86,24,12,1],
[86,24,12,2],
[86,24,12,3],
[86,24,12,4],
[86,24,12,5],
[86,24,12,6],
[86,24,12,7],
[86,24,12,8],
[86,24,12,10],
[86,24,13,0],
[86,24,13,1],
[86,24,13,2],
[86,24,13,3],
[86,24,13,4],
[86,24,13,5],
[86,24,13,6],
[86,24,13,7],
[86,24,13,8],
[86,24,13,10],
[86,24,13,11],
[86,24,13,12],
[86,24,14,0],
[86,24,14,1],
[86,24,14,2],
[86,24,14,3],
[86,24,14,4],
[86,24,14,5],
[86,24,14,6],
[86,24,14,7],
[86,24,14,8],
[86,24,14,10],
[86,24,14,11],
[86,24,14,12],
[86,24,17,0],
[86,24,17,1],
[86,24,17,2],
[86,24,17,3],
[86,24,17,4],
[86,24,17,5],
[86,24,17,6],
[86,24,17,7],
[86,24,17,8],
[86,24,17,12],
[86,24,17,13],
[86,24,17,14],
[86,24,19,0],
[86,24,19,1],
[86,24,19,2],
[86,24,19,3],
[86,24,19,4],
[86,24,19,5],
[86,24,19,8],
[86,24,19,10],
[86,24,19,11],
[86,24,19,12],
[86,24,19,17],
[86,24,20,0],
[86,24,20,1],
[86,24,20,2],
[86,24,20,3],
[86,24,20,4],
[86,24,20,6],
[86,24,20,7],
[86,24,20,8],
[86,24,20,11],
[86,24,20,12],
[86,24,20,13],
[86,24,20,14],
[86,24,20,17],
[86,24,20,19],
[86,24,21,0],
[86,24,21,1],
[86,24,21,2],
[86,24,21,7],
[86,24,21,10],
[86,24,21,11],
[86,24,21,12],
[86,24,21,13],
[86,24,21,14],
[86,24,21,17],
[86,24,21,19],
[86,24,21,20],
[86,24,22,3],
[86,24,22,4],
[86,24,22,5],
[86,24,22,6],
[86,24,22,7],
[86,24,22,8],
[86,24,22,11],
[86,24,22,12],
[86,24,22,13],
[86,24,22,14],
[86,24,22,17],
[86,24,22,19],
[86,24,23,0],
[86,24,23,1],
[86,24,23,2],
[86,24,23,3],
[86,24,23,4],
[86,24,23,5],
[86,24,23,6],
[86,24,23,7],
[86,24,23,8],
[86,24,23,10],
[86,24,23,11],
[86,24,23,12],
[86,24,23,13],
[86,24,23,14],
[86,24,23,17],
[86,24,23,19],
[86,25,3,0],
[86,25,3,1],
[86,25,3,2],
[86,25,4,0],
[86,25,4,1],
[86,25,4,2],
[86,25,4,3],
[86,25,5,0],
[86,25,5,1],
[86,25,5,2],
[86,25,5,4],
[86,25,6,0],
[86,25,6,1],
[86,25,6,2],
[86,25,6,4],
[86,25,8,0],
[86,25,8,1],
[86,25,8,2],
[86,25,8,4],
[86,25,10,0],
[86,25,10,1],
[86,25,10,2],
[86,25,10,3],
[86,25,10,4],
[86,25,10,5],
[86,25,10,6],
[86,25,10,8],
[86,25,11,0],
[86,25,11,1],
[86,25,11,2],
[86,25,11,3],
[86,25,11,4],
[86,25,11,5],
[86,25,11,6],
[86,25,11,8],
[86,25,11,10],
[86,25,12,0],
[86,25,12,1],
[86,25,12,2],
[86,25,12,3],
[86,25,12,4],
[86,25,12,5],
[86,25,12,6],
[86,25,12,8],
[86,25,12,10],
[86,25,13,0],
[86,25,13,1],
[86,25,13,2],
[86,25,13,3],
[86,25,13,4],
[86,25,13,5],
[86,25,13,6],
[86,25,13,8],
[86,25,13,10],
[86,25,13,11],
[86,25,14,0],
[86,25,14,1],
[86,25,14,2],
[86,25,14,3],
[86,25,14,4],
[86,25,14,5],
[86,25,14,6],
[86,25,14,8],
[86,25,14,12],
[86,25,16,0],
[86,25,16,1],
[86,25,16,2],
[86,25,16,3],
[86,25,16,4],
[86,25,16,5],
[86,25,16,8],
[86,25,16,10],
[86,25,16,11],
[86,25,16,12],
[86,25,16,13],
[86,25,16,14],
[86,25,17,0],
[86,25,17,1],
[86,25,17,2],
[86,25,17,3],
[86,25,17,4],
[86,25,17,5],
[86,25,17,8],
[86,25,17,10],
[86,25,17,11],
[86,25,17,12],
[86,25,17,13],
[86,25,17,14],
[86,25,17,16],
[86,25,19,0],
[86,25,19,1],
[86,25,19,2],
[86,25,19,5],
[86,25,19,6],
[86,25,19,8],
[86,25,19,10],
[86,25,19,11],
[86,25,19,12],
[86,25,19,16],
[86,25,19,17],
[86,25,20,3],
[86,25,20,4],
[86,25,20,5],
[86,25,20,6],
[86,25,20,8],
[86,25,20,11],
[86,25,20,12],
[86,25,20,13],
[86,25,20,14],
[86,25,20,16],
[86,25,20,17],
[86,25,20,19],
[86,25,21,0],
[86,25,21,1],
[86,25,21,2],
[86,25,21,4],
[86,25,21,10],
[86,25,21,11],
[86,25,21,12],
[86,25,21,13],
[86,25,21,14],
[86,25,21,16],
[86,25,21,17],
[86,25,21,19],
[86,25,22,0],
[86,25,22,1],
[86,25,22,2],
[86,25,22,3],
[86,25,22,4],
[86,25,22,5],
[86,25,22,6],
[86,25,22,8],
[86,25,22,11],
[86,25,22,12],
[86,25,22,13],
[86,25,22,14],
[86,25,22,16],
[86,25,22,17],
[86,25,23,0],
[86,25,23,1],
[86,25,23,2],
[86,25,23,3],
[86,25,23,4],
[86,25,23,5],
[86,25,23,6],
[86,25,23,8],
[86,25,23,10],
[86,25,23,11],
[86,25,23,12],
[86,25,23,13],
[86,25,23,14],
[86,25,23,16],
[86,25,23,17],
[86,25,24,0],
[86,25,24,1],
[86,25,24,2],
[86,25,24,3],
[86,25,24,4],
[86,25,24,5],
[86,25,24,6],
[86,25,24,8],
[86,25,24,10],
[86,25,24,11],
[86,25,24,12],
[86,25,24,13],
[86,25,24,14],
[86,25,24,17],
[86,27,3,0],
[86,27,3,1],
[86,27,3,2],
[86,27,4,0],
[86,27,4,1],
[86,27,4,2],
[86,27,4,3],
[86,27,5,0],
[86,27,5,1],
[86,27,5,2],
[86,27,5,4],
[86,27,6,0],
[86,27,6,1],
[86,27,6,2],
[86,27,6,4],
[86,27,7,0],
[86,27,7,1],
[86,27,7,2],
[86,27,7,3],
[86,27,7,4],
[86,27,7,5],
[86,27,7,6],
[86,27,8,0],
[86,27,8,1],
[86,27,8,2],
[86,27,8,4],
[86,27,8,7],
[86,27,11,0],
[86,27,11,1],
[86,27,11,2],
[86,27,11,3],
[86,27,11,4],
[86,27,11,5],
[86,27,11,6],
[86,27,11,7],
[86,27,11,8],
[86,27,12,0],
[86,27,12,1],
[86,27,12,2],
[86,27,12,3],
[86,27,12,4],
[86,27,12,5],
[86,27,12,6],
[86,27,12,7],
[86,27,13,0],
[86,27,13,1],
[86,27,13,2],
[86,27,13,3],
[86,27,13,4],
[86,27,13,5],
[86,27,13,6],
[86,27,13,7],
[86,27,13,11],
[86,27,13,12],
[86,27,14,0],
[86,27,14,1],
[86,27,14,2],
[86,27,14,3],
[86,27,14,4],
[86,27,14,5],
[86,27,14,8],
[86,27,14,11],
[86,27,14,12],
[86,27,16,0],
[86,27,16,1],
[86,27,16,2],
[86,27,16,5],
[86,27,16,6],
[86,27,16,7],
[86,27,16,8],
[86,27,16,11],
[86,27,16,12],
[86,27,16,13],
[86,27,16,14],
[86,27,17,0],
[86,27,17,1],
[86,27,17,2],
[86,27,17,5],
[86,27,17,6],
[86,27,17,7],
[86,27,17,8],
[86,27,17,11],
[86,27,17,12],
[86,27,17,13],
[86,27,17,14],
[86,27,17,16],
[86,27,19,0],
[86,27,19,1],
[86,27,19,2],
[86,27,19,3],
[86,27,19,4],
[86,27,19,5],
[86,27,19,6],
[86,27,19,7],
[86,27,19,8],
[86,27,19,11],
[86,27,19,12],
[86,27,19,16],
[86,27,19,17],
[86,27,21,0],
[86,27,21,1],
[86,27,21,2],
[86,27,21,4],
[86,27,21,7],
[86,27,21,11],
[86,27,21,12],
[86,27,21,13],
[86,27,21,14],
[86,27,21,16],
[86,27,21,17],
[86,27,23,0],
[86,27,23,1],
[86,27,23,2],
[86,27,23,3],
[86,27,23,4],
[86,27,23,5],
[86,27,23,6],
[86,27,23,7],
[86,27,23,8],
[86,27,23,11],
[86,27,23,12],
[86,27,23,13],
[86,27,23,14],
[86,27,24,0],
[86,27,24,1],
[86,27,24,2],
[86,27,24,3],
[86,27,24,4],
[86,27,24,5],
[86,27,24,6],
[86,27,24,7],
[86,27,24,8],
[86,27,24,11],
[86,27,24,12],
[86,27,24,13],
[86,27,24,14],
[86,27,25,0],
[86,27,25,1],
[86,27,25,2],
[86,27,25,3],
[86,27,25,4],
[86,27,25,5],
[86,27,25,6],
[86,27,25,8],
[86,27,25,11],
[86,28,3,0],
[86,28,3,1],
[86,28,3,2],
[86,28,4,0],
[86,28,4,1],
[86,28,4,2],
[86,28,4,3],
[86,28,5,0],
[86,28,5,1],
[86,28,5,2],
[86,28,5,4],
[86,28,6,0],
[86,28,6,1],
[86,28,6,2],
[86,28,6,4],
[86,28,8,0],
[86,28,8,1],
[86,28,8,2],
[86,28,8,4],
[86,28,10,0],
[86,28,10,1],
[86,28,10,2],
[86,28,10,3],
[86,28,10,4],
[86,28,10,5],
[86,28,10,6],
[86,28,11,0],
[86,28,11,1],
[86,28,11,2],
[86,28,11,3],
[86,28,11,4],
[86,28,11,5],
[86,28,11,6],
[86,28,11,10],
[86,28,12,0],
[86,28,12,1],
[86,28,12,2],
[86,28,12,3],
[86,28,12,4],
[86,28,12,5],
[86,28,12,8],
[86,28,12,10],
[86,28,13,0],
[86,28,13,1],
[86,28,13,2],
[86,28,13,3],
[86,28,13,4],
[86,28,13,5],
[86,28,13,8],
[86,28,13,10],
[86,28,13,11],
[86,28,13,12],
[86,28,14,0],
[86,28,14,1],
[86,28,14,2],
[86,28,14,3],
[86,28,14,4],
[86,28,14,6],
[86,28,14,8],
[86,28,14,10],
[86,28,14,11],
[86,28,14,12],
[86,28,16,3],
[86,28,16,4],
[86,28,16,5],
[86,28,16,6],
[86,28,16,8],
[86,28,16,10],
[86,28,16,11],
[86,28,16,12],
[86,28,16,13],
[86,28,16,14],
[86,28,17,3],
[86,28,17,4],
[86,28,17,5],
[86,28,17,6],
[86,28,17,8],
[86,28,17,10],
[86,28,17,11],
[86,28,17,12],
[86,28,17,13],
[86,28,17,14],
[86,28,17,16],
[86,28,19,0],
[86,28,19,1],
[86,28,19,2],
[86,28,19,3],
[86,28,19,4],
[86,28,19,5],
[86,28,19,6],
[86,28,19,8],
[86,28,19,10],
[86,28,19,11],
[86,28,19,12],
[86,28,19,16],
[86,28,19,17],
[86,28,20,0],
[86,28,20,1],
[86,28,20,2],
[86,28,20,3],
[86,28,20,4],
[86,28,20,5],
[86,28,20,6],
[86,28,20,8],
[86,28,20,11],
[86,28,20,12],
[86,28,20,13],
[86,28,20,14],
[86,28,20,16],
[86,28,20,17],
[86,28,21,0],
[86,28,21,1],
[86,28,21,2],
[86,28,21,4],
[86,28,21,10],
[86,28,21,11],
[86,28,21,12],
[86,28,21,13],
[86,28,21,14],
[86,28,22,0],
[86,28,22,1],
[86,28,22,2],
[86,28,22,3],
[86,28,22,4],
[86,28,22,5],
[86,28,22,6],
[86,28,22,8],
[86,28,22,11],
[86,28,22,12],
[86,28,22,13],
[86,28,22,14],
[86,28,23,0],
[86,28,23,1],
[86,28,23,2],
[86,28,23,3],
[86,28,23,4],
[86,28,23,5],
[86,28,23,6],
[86,28,23,8],
[86,28,23,10],
[86,28,23,11],
[86,28,23,12],
[86,28,23,13],
[86,28,24,0],
[86,28,24,1],
[86,28,24,2],
[86,28,24,3],
[86,28,24,4],
[86,28,24,5],
[86,28,24,6],
[86,28,24,8],
[86,28,24,10],
[86,28,24,11],
[86,28,24,12],
[86,28,24,13],
[86,28,27,0],
[86,28,27,1],
[86,28,27,2],
[86,28,27,3],
[86,28,27,4],
[86,28,27,5],
[86,29,3,0],
[86,29,3,1],
[86,29,3,2],
[86,29,4,0],
[86,29,4,1],
[86,29,4,2],
[86,29,4,3],
[86,29,5,0],
[86,29,5,1],
[86,29,5,2],
[86,29,5,4],
[86,29,6,0],
[86,29,6,1],
[86,29,6,2],
[86,29,6,4],
[86,29,7,0],
[86,29,7,1],
[86,29,7,2],
[86,29,7,3],
[86,29,7,4],
[86,29,7,5],
[86,29,7,6],
[86,29,8,0],
[86,29,8,1],
[86,29,8,2],
[86,29,8,4],
[86,29,8,7],
[86,29,10,0],
[86,29,10,1],
[86,29,10,2],
[86,29,10,3],
[86,29,10,4],
[86,29,10,5],
[86,29,10,8],
[86,29,13,0],
[86,29,13,1],
[86,29,13,2],
[86,29,13,3],
[86,29,13,4],
[86,29,13,6],
[86,29,13,7],
[86,29,13,8],
[86,29,13,10],
[86,29,14,0],
[86,29,14,1],
[86,29,14,2],
[86,29,14,5],
[86,29,14,6],
[86,29,14,7],
[86,29,14,8],
[86,29,14,10],
[86,29,16,0],
[86,29,16,1],
[86,29,16,2],
[86,29,16,3],
[86,29,16,4],
[86,29,16,5],
[86,29,16,6],
[86,29,16,7],
[86,29,16,8],
[86,29,16,10],
[86,29,16,13],
[86,29,16,14],
[86,29,17,0],
[86,29,17,1],
[86,29,17,2],
[86,29,17,3],
[86,29,17,4],
[86,29,17,5],
[86,29,17,6],
[86,29,17,7],
[86,29,17,8],
[86,29,17,10],
[86,29,17,13],
[86,29,17,14],
[86,29,17,16],
[86,29,19,0],
[86,29,19,1],
[86,29,19,2],
[86,29,19,3],
[86,29,19,4],
[86,29,19,5],
[86,29,19,6],
[86,29,19,7],
[86,29,19,8],
[86,29,19,10],
[86,29,19,16],
[86,29,19,17],
[86,29,20,0],
[86,29,20,1],
[86,29,20,2],
[86,29,20,3],
[86,29,20,4],
[86,29,20,5],
[86,29,20,6],
[86,29,20,7],
[86,29,20,8],
[86,29,20,13],
[86,29,20,14],
[86,29,21,0],
[86,29,21,1],
[86,29,21,2],
[86,29,21,4],
[86,29,21,7],
[86,29,21,10],
[86,29,21,13],
[86,29,21,14],
[86,29,22,0],
[86,29,22,1],
[86,29,22,2],
[86,29,22,3],
[86,29,22,4],
[86,29,22,5],
[86,29,22,6],
[86,29,22,7],
[86,29,22,8],
[86,29,22,13],
[86,29,23,0],
[86,29,23,1],
[86,29,23,2],
[86,29,23,3],
[86,29,23,4],
[86,29,23,5],
[86,29,23,6],
[86,29,23,7],
[86,29,23,8],
[86,29,23,10],
[86,29,24,0],
[86,29,24,1],
[86,29,24,2],
[86,29,24,3],
[86,29,24,4],
[86,29,24,5],
[86,29,24,6],
[86,29,24,7],
[86,29,24,8],
[86,29,24,10],
[86,29,25,0],
[86,29,25,1],
[86,29,25,2],
[86,29,25,3],
[86,29,25,4],
[86,29,25,5],
[86,29,25,6],
[86,29,27,0],
[86,29,27,1],
[86,29,27,2],
[86,29,27,3],
[86,29,27,4],
[86,29,28,0],
[86,29,28,1],
[86,29,28,2],
[86,30,3,0],
[86,30,3,1],
[86,30,3,2],
[86,30,4,0],
[86,30,4,1],
[86,30,4,2],
[86,30,4,3],
[86,30,5,0],
[86,30,5,1],
[86,30,5,2],
[86,30,5,4],
[86,30,6,0],
[86,30,6,1],
[86,30,6,2],
[86,30,6,4],
[86,30,7,0],
[86,30,7,1],
[86,30,7,2],
[86,30,7,3],
[86,30,7,4],
[86,30,7,5],
[86,30,7,6],
[86,30,8,0],
[86,30,8,1],
[86,30,8,2],
[86,30,8,4],
[86,30,10,0],
[86,30,10,1],
[86,30,10,2],
[86,30,10,3],
[86,30,10,4],
[86,30,10,6],
[86,30,10,7],
[86,30,10,8],
[86,30,11,0],
[86,30,11,1],
[86,30,11,2],
[86,30,11,3],
[86,30,11,4],
[86,30,11,6],
[86,30,11,7],
[86,30,11,8],
[86,30,11,10],
[86,30,12,0],
[86,30,12,1],
[86,30,12,2],
[86,30,12,5],
[86,30,12,6],
[86,30,12,7],
[86,30,12,8],
[86,30,12,10],
[86,30,13,0],
[86,30,13,1],
[86,30,13,2],
[86,30,13,5],
[86,30,13,6],
[86,30,13,7],
[86,30,13,8],
[86,30,13,10],
[86,30,13,11],
[86,30,13,12],
[86,30,14,3],
[86,30,14,4],
[86,30,14,5],
[86,30,14,6],
[86,30,14,7],
[86,30,14,8],
[86,30,14,10],
[86,30,14,11],
[86,30,14,12],
[86,30,16,0],
[86,30,16,1],
[86,30,16,2],
[86,30,16,3],
[86,30,16,4],
[86,30,16,5],
[86,30,16,6],
[86,30,16,7],
[86,30,16,8],
[86,30,16,10],
[86,30,16,11],
[86,30,16,12],
[86,30,16,13],
[86,30,16,14],
[86,30,17,0],
[86,30,17,1],
[86,30,17,2],
[86,30,17,3],
[86,30,17,4],
[86,30,17,5],
[86,30,17,6],
[86,30,17,7],
[86,30,17,8],
[86,30,17,10],
[86,30,17,11],
[86,30,17,12],
[86,30,17,13],
[86,30,17,14],
[86,30,17,16],
[86,30,19,0],
[86,30,19,1],
[86,30,19,2],
[86,30,19,3],
[86,30,19,4],
[86,30,19,5],
[86,30,19,6],
[86,30,19,7],
[86,30,19,8],
[86,30,19,10],
[86,30,19,11],
[86,30,19,12],
[86,30,20,0],
[86,30,20,1],
[86,30,20,2],
[86,30,20,3],
[86,30,20,4],
[86,30,20,5],
[86,30,20,6],
[86,30,20,7],
[86,30,20,8],
[86,30,20,11],
[86,30,20,12],
[86,30,20,13],
[86,30,20,14],
[86,30,21,0],
[86,30,21,1],
[86,30,21,2],
[86,30,21,4],
[86,30,21,7],
[86,30,21,10],
[86,30,21,11],
[86,30,21,12],
[86,30,21,13],
[86,30,22,0],
[86,30,22,1],
[86,30,22,2],
[86,30,22,3],
[86,30,22,4],
[86,30,22,5],
[86,30,22,6],
[86,30,22,7],
[86,30,22,8],
[86,30,22,11],
[86,30,24,0],
[86,30,24,1],
[86,30,24,2],
[86,30,24,3],
[86,30,24,4],
[86,30,24,5],
[86,30,24,6],
[86,30,24,7],
[86,30,24,8],
[86,30,25,0],
[86,30,25,1],
[86,30,25,2],
[86,30,25,3],
[86,30,25,4],
[86,30,25,5],
[86,30,27,0],
[86,30,27,1],
[86,30,27,2],
[86,31,3,0],
[86,31,3,1],
[86,31,3,2],
[86,31,4,0],
[86,31,4,1],
[86,31,4,2],
[86,31,4,3],
[86,31,5,0],
[86,31,5,1],
[86,31,5,2],
[86,31,5,4],
[86,31,6,0],
[86,31,6,1],
[86,31,6,2],
[86,31,6,4],
[86,31,7,0],
[86,31,7,1],
[86,31,7,2],
[86,31,7,3],
[86,31,7,4],
[86,31,7,5],
[86,31,8,0],
[86,31,8,1],
[86,31,8,2],
[86,31,8,4],
[86,31,8,7],
[86,31,10,0],
[86,31,10,1],
[86,31,10,2],
[86,31,10,5],
[86,31,10,6],
[86,31,10,7],
[86,31,10,8],
[86,31,11,0],
[86,31,11,1],
[86,31,11,2],
[86,31,11,5],
[86,31,11,6],
[86,31,11,7],
[86,31,11,8],
[86,31,11,10],
[86,31,12,3],
[86,31,12,4],
[86,31,12,5],
[86,31,12,6],
[86,31,12,7],
[86,31,12,8],
[86,31,12,10],
[86,31,13,3],
[86,31,13,4],
[86,31,13,5],
[86,31,13,6],
[86,31,13,7],
[86,31,13,8],
[86,31,13,10],
[86,31,13,11],
[86,31,13,12],
[86,31,14,0],
[86,31,14,1],
[86,31,14,2],
[86,31,14,3],
[86,31,14,4],
[86,31,14,5],
[86,31,14,6],
[86,31,14,7],
[86,31,14,8],
[86,31,14,10],
[86,31,14,11],
[86,31,14,12],
[86,31,16,0],
[86,31,16,1],
[86,31,16,2],
[86,31,16,3],
[86,31,16,4],
[86,31,16,5],
[86,31,16,6],
[86,31,16,7],
[86,31,16,8],
[86,31,16,10],
[86,31,16,11],
[86,31,16,12],
[86,31,16,13],
[86,31,16,14],
[86,31,17,0],
[86,31,17,1],
[86,31,17,2],
[86,31,17,3],
[86,31,17,4],
[86,31,17,5],
[86,31,17,6],
[86,31,17,7],
[86,31,17,8],
[86,31,17,10],
[86,31,17,11],
[86,31,17,12],
[86,31,17,13],
[86,31,17,14],
[86,31,17,16],
[86,31,19,0],
[86,31,19,1],
[86,31,19,2],
[86,31,19,3],
[86,31,19,4],
[86,31,19,5],
[86,31,19,6],
[86,31,19,7],
[86,31,19,8],
[86,31,19,10],
[86,31,19,11],
[86,31,19,12],
[86,31,20,0],
[86,31,20,1],
[86,31,20,2],
[86,31,20,3],
[86,31,20,4],
[86,31,20,5],
[86,31,20,6],
[86,31,20,7],
[86,31,20,8],
[86,31,20,11],
[86,31,20,12],
[86,31,20,13],
[86,31,21,0],
[86,31,21,1],
[86,31,21,2],
[86,31,21,4],
[86,31,21,7],
[86,31,21,10],
[86,31,21,11],
[86,31,22,0],
[86,31,22,1],
[86,31,22,2],
[86,31,22,3],
[86,31,22,4],
[86,31,22,5],
[86,31,22,6],
[86,31,22,7],
[86,31,22,8],
[86,31,24,0],
[86,31,24,1],
[86,31,24,2],
[86,31,24,3],
[86,31,24,4],
[86,31,24,5],
[86,31,24,6],
[86,31,24,7],
[86,31,25,0],
[86,31,25,1],
[86,31,25,2],
[86,31,25,3],
[86,31,25,4],
[86,32,3,0],
[86,32,3,1],
[86,32,3,2],
[86,32,4,0],
[86,32,4,1],
[86,32,4,2],
[86,32,4,3],
[86,32,5,0],
[86,32,5,1],
[86,32,5,2],
[86,32,5,4],
[86,32,6,0],
[86,32,6,1],
[86,32,6,2],
[86,32,6,4],
[86,32,7,0],
[86,32,7,1],
[86,32,7,2],
[86,32,7,3],
[86,32,7,4],
[86,32,7,6],
[86,32,8,0],
[86,32,8,1],
[86,32,8,2],
[86,32,8,7],
[86,32,10,3],
[86,32,10,4],
[86,32,10,5],
[86,32,10,6],
[86,32,10,7],
[86,32,10,8],
[86,32,13,0],
[86,32,13,1],
[86,32,13,2],
[86,32,13,3],
[86,32,13,4],
[86,32,13,5],
[86,32,13,6],
[86,32,13,7],
[86,32,13,8],
[86,32,13,10],
[86,32,14,0],
[86,32,14,1],
[86,32,14,2],
[86,32,14,3],
[86,32,14,4],
[86,32,14,5],
[86,32,14,6],
[86,32,14,7],
[86,32,14,8],
[86,32,14,10],
[86,32,16,0],
[86,32,16,1],
[86,32,16,2],
[86,32,16,3],
[86,32,16,4],
[86,32,16,5],
[86,32,16,6],
[86,32,16,7],
[86,32,16,8],
[86,32,16,10],
[86,32,16,13],
[86,32,16,14],
[86,32,17,0],
[86,32,17,1],
[86,32,17,2],
[86,32,17,3],
[86,32,17,4],
[86,32,17,5],
[86,32,17,6],
[86,32,17,7],
[86,32,17,8],
[86,32,17,10],
[86,32,17,13],
[86,32,17,14],
[86,32,19,0],
[86,32,19,1],
[86,32,19,2],
[86,32,19,3],
[86,32,19,4],
[86,32,19,5],
[86,32,19,6],
[86,32,19,7],
[86,32,19,8],
[86,32,19,10],
[86,32,20,0],
[86,32,20,1],
[86,32,20,2],
[86,32,20,3],
[86,32,20,4],
[86,32,20,5],
[86,32,20,6],
[86,32,20,7],
[86,32,20,8],
[86,32,21,0],
[86,32,21,1],
[86,32,21,2],
[86,32,21,4],
[86,32,21,7],
[86,32,22,0],
[86,32,22,1],
[86,32,22,2],
[86,32,22,3],
[86,32,22,4],
[86,32,22,5],
[86,32,22,6],
[86,32,22,7],
[86,32,23,0],
[86,32,23,1],
[86,32,23,2],
[86,32,23,3],
[86,32,23,4],
[86,32,23,5],
[86,32,24,0],
[86,32,24,1],
[86,32,24,2],
[86,32,24,3],
[86,32,24,4],
[86,32,24,5],
[86,32,25,0],
[86,32,25,1],
[86,32,25,2],
[86,33,3,0],
[86,33,3,1],
[86,33,3,2],
[86,33,4,0],
[86,33,4,1],
[86,33,4,2],
[86,33,4,3],
[86,33,5,0],
[86,33,5,1],
[86,33,5,2],
[86,33,5,4],
[86,33,6,0],
[86,33,6,1],
[86,33,6,2],
[86,33,7,0],
[86,33,7,1],
[86,33,7,2],
[86,33,7,5],
[86,33,7,6],
[86,33,8,4],
[86,33,8,7],
[86,33,10,0],
[86,33,10,1],
[86,33,10,2],
[86,33,10,3],
[86,33,10,4],
[86,33,10,5],
[86,33,10,6],
[86,33,10,7],
[86,33,10,8],
[86,33,13,0],
[86,33,13,1],
[86,33,13,2],
[86,33,13,3],
[86,33,13,4],
[86,33,13,5],
[86,33,13,6],
[86,33,13,7],
[86,33,13,8],
[86,33,13,10],
[86,33,14,0],
[86,33,14,1],
[86,33,14,2],
[86,33,14,3],
[86,33,14,4],
[86,33,14,5],
[86,33,14,6],
[86,33,14,7],
[86,33,14,8],
[86,33,14,10],
[86,33,16,0],
[86,33,16,1],
[86,33,16,2],
[86,33,16,3],
[86,33,16,4],
[86,33,16,5],
[86,33,16,6],
[86,33,16,7],
[86,33,16,8],
[86,33,16,10],
[86,33,16,13],
[86,33,16,14],
[86,33,17,0],
[86,33,17,1],
[86,33,17,2],
[86,33,17,3],
[86,33,17,4],
[86,33,17,5],
[86,33,17,6],
[86,33,17,7],
[86,33,17,8],
[86,33,17,10],
[86,33,17,13],
[86,33,17,14],
[86,33,19,0],
[86,33,19,1],
[86,33,19,2],
[86,33,19,3],
[86,33,19,4],
[86,33,19,5],
[86,33,19,6],
[86,33,19,7],
[86,33,19,8],
[86,33,19,10],
[86,33,20,0],
[86,33,20,1],
[86,33,20,2],
[86,33,20,3],
[86,33,20,4],
[86,33,20,5],
[86,33,20,6],
[86,33,20,7],
[86,33,20,8],
[86,33,21,0],
[86,33,21,1],
[86,33,21,2],
[86,33,21,4],
[86,33,21,7],
[86,33,22,0],
[86,33,22,1],
[86,33,22,2],
[86,33,22,3],
[86,33,22,4],
[86,33,22,5],
[86,33,23,0],
[86,33,23,1],
[86,33,23,2],
[86,33,23,3],
[86,33,23,4],
[86,33,24,0],
[86,33,24,1],
[86,33,24,2],
[86,33,24,3],
[86,33,24,4],
[86,34,3,0],
[86,34,3,1],
[86,34,3,2],
[86,34,4,0],
[86,34,4,1],
[86,34,4,2],
[86,34,4,3],
[86,34,5,0],
[86,34,5,1],
[86,34,5,2],
[86,34,6,4],
[86,34,8,0],
[86,34,8,1],
[86,34,8,2],
[86,34,8,4],
[86,34,10,0],
[86,34,10,1],
[86,34,10,2],
[86,34,10,3],
[86,34,10,4],
[86,34,10,5],
[86,34,10,6],
[86,34,10,8],
[86,34,11,0],
[86,34,11,1],
[86,34,11,2],
[86,34,11,3],
[86,34,11,4],
[86,34,11,5],
[86,34,11,6],
[86,34,11,8],
[86,34,11,10],
[86,34,12,0],
[86,34,12,1],
[86,34,12,2],
[86,34,12,3],
[86,34,12,4],
[86,34,12,5],
[86,34,12,6],
[86,34,12,8],
[86,34,12,10],
[86,34,13,0],
[86,34,13,1],
[86,34,13,2],
[86,34,13,3],
[86,34,13,4],
[86,34,13,5],
[86,34,13,6],
[86,34,13,8],
[86,34,13,10],
[86,34,13,11],
[86,34,13,12],
[86,34,14,0],
[86,34,14,1],
[86,34,14,2],
[86,34,14,3],
[86,34,14,4],
[86,34,14,5],
[86,34,14,6],
[86,34,14,8],
[86,34,14,10],
[86,34,14,11],
[86,34,14,12],
[86,34,16,0],
[86,34,16,1],
[86,34,16,2],
[86,34,16,3],
[86,34,16,4],
[86,34,16,5],
[86,34,16,6],
[86,34,16,8],
[86,34,16,10],
[86,34,16,11],
[86,34,16,12],
[86,34,16,13],
[86,34,17,0],
[86,34,17,1],
[86,34,17,2],
[86,34,17,3],
[86,34,17,4],
[86,34,17,5],
[86,34,17,6],
[86,34,17,8],
[86,34,17,10],
[86,34,17,11],
[86,34,17,12],
[86,34,17,13],
[86,34,19,0],
[86,34,19,1],
[86,34,19,2],
[86,34,19,3],
[86,34,19,4],
[86,34,19,5],
[86,34,19,6],
[86,34,19,8],
[86,34,20,0],
[86,34,20,1],
[86,34,20,2],
[86,34,20,3],
[86,34,20,4],
[86,34,20,5],
[86,34,20,6],
[86,34,21,0],
[86,34,21,1],
[86,34,21,2],
[86,34,21,4],
[86,34,22,0],
[86,34,22,1],
[86,34,22,2],
[86,34,22,3],
[86,34,22,4],
[86,34,23,0],
[86,34,23,1],
[86,34,23,2],
[86,34,24,0],
[86,34,24,1],
[86,34,24,2],
[86,36,4,3],
[86,36,5,0],
[86,36,5,1],
[86,36,5,2],
[86,36,5,4],
[86,36,6,0],
[86,36,6,1],
[86,36,6,2],
[86,36,6,4],
[86,36,7,0],
[86,36,7,1],
[86,36,7,2],
[86,36,7,3],
[86,36,7,4],
[86,36,7,5],
[86,36,7,6],
[86,36,8,0],
[86,36,8,1],
[86,36,8,2],
[86,36,8,4],
[86,36,8,7],
[86,36,10,0],
[86,36,10,1],
[86,36,10,2],
[86,36,10,3],
[86,36,10,4],
[86,36,10,5],
[86,36,10,6],
[86,36,10,7],
[86,36,10,8],
[86,36,11,0],
[86,36,11,1],
[86,36,11,2],
[86,36,11,3],
[86,36,11,4],
[86,36,11,5],
[86,36,11,6],
[86,36,11,7],
[86,36,11,8],
[86,36,11,10],
[86,36,12,0],
[86,36,12,1],
[86,36,12,2],
[86,36,12,3],
[86,36,12,4],
[86,36,12,5],
[86,36,12,6],
[86,36,12,7],
[86,36,12,8],
[86,36,12,10],
[86,36,16,0],
[86,36,16,1],
[86,36,16,2],
[86,36,16,3],
[86,36,16,4],
[86,36,16,5],
[86,36,16,6],
[86,36,16,7],
[86,36,16,8],
[86,36,17,0],
[86,36,17,1],
[86,36,17,2],
[86,36,17,3],
[86,36,17,4],
[86,36,17,5],
[86,36,17,6],
[86,36,17,7],
[86,36,17,8],
[86,36,20,0],
[86,36,20,1],
[86,36,20,2],
[86,36,20,3],
[86,36,20,4],
[86,36,21,0],
[86,36,21,1],
[86,36,21,2],
[86,37,3,0],
[86,37,3,1],
[86,37,3,2],
[86,37,5,0],
[86,37,5,1],
[86,37,5,2],
[86,37,6,0],
[86,37,6,1],
[86,37,6,2],
[86,37,7,0],
[86,37,7,1],
[86,37,7,2],
[86,37,7,3],
[86,37,7,5],
[86,37,7,6],
[86,37,8,0],
[86,37,8,1],
[86,37,8,2],
[86,37,8,7],
[86,37,10,0],
[86,37,10,1],
[86,37,10,2],
[86,37,10,3],
[86,37,10,5],
[86,37,10,6],
[86,37,10,7],
[86,37,10,8],
[86,37,11,0],
[86,37,11,1],
[86,37,11,2],
[86,37,11,3],
[86,37,11,5],
[86,37,11,6],
[86,37,11,7],
[86,37,11,8],
[86,37,11,10],
[86,37,12,0],
[86,37,12,1],
[86,37,12,2],
[86,37,12,3],
[86,37,12,5],
[86,37,12,6],
[86,37,12,7],
[86,37,12,8],
[86,37,12,10],
[86,37,13,0],
[86,37,13,1],
[86,37,13,2],
[86,37,13,3],
[86,37,13,5],
[86,37,13,6],
[86,37,13,7],
[86,37,13,8],
[86,37,13,10],
[86,37,13,11],
[86,37,13,12],
[86,37,14,0],
[86,37,14,1],
[86,37,14,2],
[86,37,14,3],
[86,37,14,5],
[86,37,14,6],
[86,37,14,7],
[86,37,14,8],
[86,37,14,10],
[86,37,14,11],
[86,37,16,0],
[86,37,16,1],
[86,37,16,2],
[86,37,16,3],
[86,37,16,5],
[86,37,16,6],
[86,37,16,7],
[86,37,17,0],
[86,37,17,1],
[86,37,17,2],
[86,37,17,3],
[86,37,17,5],
[86,37,17,6],
[86,37,17,7],
[86,37,19,0],
[86,37,19,1],
[86,37,19,2],
[86,37,19,3],
[86,37,20,0],
[86,37,20,1],
[86,37,20,2],
[86,38,3,0],
[86,38,3,1],
[86,38,3,2],
[86,38,4,0],
[86,38,4,1],
[86,38,4,2],
[86,38,4,3],
[86,38,5,0],
[86,38,5,1],
[86,38,5,2],
[86,38,5,4],
[86,38,6,0],
[86,38,6,1],
[86,38,6,2],
[86,38,6,4],
[86,38,8,0],
[86,38,8,1],
[86,38,8,2],
[86,38,8,4],
[86,38,10,0],
[86,38,10,1],
[86,38,10,2],
[86,38,10,3],
[86,38,10,4],
[86,38,10,5],
[86,38,10,6],
[86,38,10,8],
[86,38,11,0],
[86,38,11,1],
[86,38,11,2],
[86,38,11,3],
[86,38,11,4],
[86,38,11,5],
[86,38,11,6],
[86,38,11,8],
[86,38,11,10],
[86,38,12,0],
[86,38,12,1],
[86,38,12,2],
[86,38,12,3],
[86,38,12,4],
[86,38,12,5],
[86,38,12,6],
[86,38,12,8],
[86,38,12,10],
[86,38,13,0],
[86,38,13,1],
[86,38,13,2],
[86,38,13,3],
[86,38,13,4],
[86,38,13,5],
[86,38,13,6],
[86,38,13,8],
[86,38,13,10],
[86,38,13,11],
[86,38,14,0],
[86,38,14,1],
[86,38,14,2],
[86,38,14,3],
[86,38,14,4],
[86,38,14,5],
[86,38,14,6],
[86,38,14,8],
[86,38,16,0],
[86,38,16,1],
[86,38,16,2],
[86,38,16,3],
[86,38,16,4],
[86,38,16,5],
[86,38,17,0],
[86,38,17,1],
[86,38,17,2],
[86,38,17,3],
[86,38,17,4],
[86,38,17,5],
[86,38,19,0],
[86,38,19,1],
[86,38,19,2],
[86,39,4,0],
[86,39,4,1],
[86,39,4,2],
[86,39,7,0],
[86,39,7,1],
[86,39,7,2],
[86,39,7,4],
[86,39,10,0],
[86,39,10,1],
[86,39,10,2],
[86,39,10,4],
[86,39,10,7],
[86,39,11,0],
[86,39,11,1],
[86,39,11,2],
[86,39,11,4],
[86,39,11,7],
[86,39,11,10],
[86,39,12,0],
[86,39,12,1],
[86,39,12,2],
[86,39,12,4],
[86,39,12,7],
[86,39,13,0],
[86,39,13,1],
[86,39,13,2],
[86,39,13,4],
[86,39,13,7],
[86,39,14,0],
[86,39,14,1],
[86,39,14,2],
[86,39,14,4],
[86,39,14,7],
[86,39,16,0],
[86,39,16,1],
[86,39,16,2],
[86,39,16,4],
[86,39,17,0],
[86,39,17,1],
[86,39,17,2],
[86,39,17,4],
[86,42,3,0],
[86,42,3,1],
[86,42,3,2],
[86,42,4,0],
[86,42,4,1],
[86,42,4,2],
[86,42,4,3],
[86,42,5,0],
[86,42,5,1],
[86,42,5,2],
[86,42,5,4],
[86,42,6,0],
[86,42,6,1],
[86,42,6,2],
[86,42,6,4],
[86,42,7,0],
[86,42,7,1],
[86,42,7,2],
[86,42,7,3],
[86,42,7,4],
[86,42,7,5],
[86,42,7,6],
[86,42,8,0],
[86,42,8,1],
[86,42,8,2],
[86,42,8,4],
[86,42,8,7],
[86,42,11,0],
[86,42,11,1],
[86,42,11,2],
[86,42,11,3],
[86,42,11,4],
[86,42,11,5],
[86,42,11,6],
[86,42,11,7],
[86,42,12,0],
[86,42,12,1],
[86,42,12,2],
[86,42,12,3],
[86,42,12,4],
[86,42,12,5],
[86,42,13,0],
[86,42,13,1],
[86,42,13,2],
[86,42,13,3],
[86,42,13,4],
[86,42,13,5],
[86,42,14,0],
[86,42,14,1],
[86,42,14,2],
[86,42,14,3],
[86,42,14,4],
[86,43,3,0],
[86,43,3,1],
[86,43,3,2],
[86,43,4,0],
[86,43,4,1],
[86,43,4,2],
[86,43,4,3],
[86,43,5,0],
[86,43,5,1],
[86,43,5,2],
[86,43,5,4],
[86,43,6,0],
[86,43,6,1],
[86,43,6,2],
[86,43,6,4],
[86,43,8,0],
[86,43,8,1],
[86,43,8,2],
[86,43,8,4],
[86,43,10,0],
[86,43,10,1],
[86,43,10,2],
[86,43,10,3],
[86,43,10,4],
[86,43,10,5],
[86,43,11,0],
[86,43,11,1],
[86,43,11,2],
[86,43,11,3],
[86,43,11,4],
[86,43,11,5],
[86,43,12,0],
[86,43,12,1],
[86,43,12,2],
[86,43,12,3],
[86,43,12,4],
[86,43,13,0],
[86,43,13,1],
[86,43,13,2],
[86,43,13,3],
[86,43,13,4],
[86,43,14,0],
[86,43,14,1],
[86,43,14,2],
[86,44,3,0],
[86,44,3,1],
[86,44,3,2],
[86,44,4,0],
[86,44,4,1],
[86,44,4,2],
[86,44,4,3],
[86,44,5,0],
[86,44,5,1],
[86,44,5,2],
[86,44,5,4],
[86,44,6,0],
[86,44,6,1],
[86,44,6,2],
[86,44,6,4],
[86,44,7,0],
[86,44,7,1],
[86,44,7,2],
[86,44,7,3],
[86,44,7,4],
[86,44,7,5],
[86,44,7,6],
[86,44,8,0],
[86,44,8,1],
[86,44,8,2],
[86,44,8,4],
[86,44,10,0],
[86,44,10,1],
[86,44,10,2],
[86,44,10,3],
[86,44,10,4],
[86,44,11,0],
[86,44,11,1],
[86,44,11,2],
[86,44,11,3],
[86,44,11,4],
[86,44,12,0],
[86,44,12,1],
[86,44,12,2],
[86,44,13,0],
[86,44,13,1],
[86,44,13,2],
[86,47,3,0],
[86,47,3,1],
[86,47,3,2],
[86,47,4,0],
[86,47,4,1],
[86,47,4,2],
[86,47,4,3],
[86,47,5,0],
[86,47,5,1],
[86,47,5,2],
[86,47,5,4],
[86,47,6,0],
[86,47,6,1],
[86,47,6,2],
[86,47,7,0],
[86,47,7,1],
[86,47,7,2],
[86,48,3,0],
[86,48,3,1],
[86,48,3,2],
[86,48,4,0],
[86,48,4,1],
[86,48,4,2],
[86,48,4,3],
[86,48,5,0],
[86,48,5,1],
[86,48,5,2],
[86,51,4,0],
[86,51,4,1],
[86,51,4,2],
[86,53,3,0],
[86,53,3,1],
[86,53,3,2],
[86,53,4,0],
[86,53,4,1],
[86,53,4,2],
[86,53,4,3],
[86,53,5,0],
[86,53,5,1],
[86,53,5,2],
[86,54,3,0],
[86,54,3,1],
[86,54,3,2],
[86,54,4,0],
[86,54,4,1],
[86,54,4,2],
[86,54,4,3],
[86,54,5,0],
[86,54,5,1],
[86,54,5,2],
[86,55,3,0],
[86,55,3,1],
[86,55,3,2],
[86,55,4,0],
[86,55,4,1],
[86,55,4,2],
[86,55,4,3],
[86,55,5,0],
[86,55,5,1],
[86,55,5,2],
[86,57,3,0],
[86,57,3,1],
[86,57,3,2],
[86,57,4,0],
[86,57,4,1],
[86,57,4,2],
[86,58,3,0],
[86,58,3,1],
[86,58,3,2],
[86,58,4,0],
[86,58,4,1],
[86,58,4,2],
[87,4,3,0],
[87,4,3,1],
[87,4,3,2],
[87,5,4,0],
[87,5,4,1],
[87,5,4,2],
[87,6,4,0],
[87,6,4,1],
[87,6,4,2],
[87,7,3,0],
[87,7,3,1],
[87,7,3,2],
[87,7,4,0],
[87,7,4,1],
[87,7,4,2],
[87,7,4,3],
[87,7,5,0],
[87,7,5,1],
[87,7,5,2],
[87,7,5,4],
[87,7,6,0],
[87,7,6,1],
[87,7,6,2],
[87,7,6,4],
[87,8,4,0],
[87,8,4,1],
[87,8,4,2],
[87,8,7,0],
[87,8,7,1],
[87,8,7,2],
[87,8,7,4],
[87,9,3,0],
[87,9,3,1],
[87,9,3,2],
[87,9,4,0],
[87,9,4,1],
[87,9,4,2],
[87,9,4,3],
[87,9,5,0],
[87,9,5,1],
[87,9,5,2],
[87,9,5,4],
[87,9,6,0],
[87,9,6,1],
[87,9,6,2],
[87,9,6,4],
[87,9,7,0],
[87,9,7,1],
[87,9,7,2],
[87,9,7,3],
[87,9,7,4],
[87,9,7,5],
[87,9,7,6],
[87,9,8,0],
[87,9,8,1],
[87,9,8,2],
[87,9,8,4],
[87,9,8,7],
[87,11,3,0],
[87,11,3,1],
[87,11,3,2],
[87,11,4,0],
[87,11,4,1],
[87,11,4,2],
[87,11,4,3],
[87,11,5,0],
[87,11,5,1],
[87,11,5,2],
[87,11,5,4],
[87,11,6,0],
[87,11,6,1],
[87,11,6,2],
[87,11,6,4],
[87,11,7,0],
[87,11,7,1],
[87,11,7,2],
[87,11,7,3],
[87,11,7,4],
[87,11,7,5],
[87,11,7,6],
[87,11,8,0],
[87,11,8,1],
[87,11,8,2],
[87,11,8,4],
[87,11,8,7],
[87,11,9,0],
[87,11,9,1],
[87,11,9,2],
[87,11,9,3],
[87,11,9,4],
[87,11,9,5],
[87,11,9,6],
[87,11,9,7],
[87,11,9,8],
[87,12,3,0],
[87,12,3,1],
[87,12,3,2],
[87,12,4,0],
[87,12,4,1],
[87,12,4,2],
[87,12,4,3],
[87,12,5,0],
[87,12,5,1],
[87,12,5,2],
[87,12,5,4],
[87,12,6,0],
[87,12,6,1],
[87,12,6,2],
[87,12,6,4],
[87,12,7,0],
[87,12,7,1],
[87,12,7,2],
[87,12,7,3],
[87,12,7,4],
[87,12,7,5],
[87,12,7,6],
[87,12,8,0],
[87,12,8,1],
[87,12,8,2],
[87,12,8,4],
[87,12,8,7],
[87,12,9,0],
[87,12,9,1],
[87,12,9,2],
[87,12,9,3],
[87,12,9,4],
[87,12,9,5],
[87,12,9,6],
[87,12,9,7],
[87,12,9,8],
[87,13,3,0],
[87,13,3,1],
[87,13,3,2],
[87,13,4,0],
[87,13,4,1],
[87,13,4,2],
[87,13,4,3],
[87,13,5,0],
[87,13,5,1],
[87,13,5,2],
[87,13,5,4],
[87,13,6,0],
[87,13,6,1],
[87,13,6,2],
[87,13,6,4],
[87,13,7,0],
[87,13,7,1],
[87,13,7,2],
[87,13,7,3],
[87,13,7,4],
[87,13,7,5],
[87,13,7,6],
[87,13,8,0],
[87,13,8,1],
[87,13,8,2],
[87,13,8,4],
[87,13,8,7],
[87,13,9,0],
[87,13,9,1],
[87,13,9,2],
[87,13,9,3],
[87,13,9,4],
[87,13,9,5],
[87,13,9,6],
[87,13,9,7],
[87,13,9,8],
[87,13,11,0],
[87,13,11,1],
[87,13,11,2],
[87,13,11,3],
[87,13,11,4],
[87,13,11,5],
[87,13,11,6],
[87,13,11,7],
[87,13,11,8],
[87,13,11,9],
[87,13,12,0],
[87,13,12,1],
[87,13,12,2],
[87,13,12,3],
[87,13,12,4],
[87,13,12,5],
[87,13,12,6],
[87,13,12,7],
[87,13,12,8],
[87,13,12,9],
[87,14,3,0],
[87,14,3,1],
[87,14,3,2],
[87,14,4,0],
[87,14,4,1],
[87,14,4,2],
[87,14,4,3],
[87,14,5,0],
[87,14,5,1],
[87,14,5,2],
[87,14,5,4],
[87,14,6,0],
[87,14,6,1],
[87,14,6,2],
[87,14,6,4],
[87,14,7,0],
[87,14,7,1],
[87,14,7,2],
[87,14,7,3],
[87,14,7,4],
[87,14,7,5],
[87,14,7,6],
[87,14,8,0],
[87,14,8,1],
[87,14,8,2],
[87,14,8,4],
[87,14,8,7],
[87,14,9,0],
[87,14,9,1],
[87,14,9,2],
[87,14,9,3],
[87,14,9,4],
[87,14,9,5],
[87,14,9,6],
[87,14,9,7],
[87,14,9,8],
[87,14,11,0],
[87,14,11,1],
[87,14,11,2],
[87,14,11,3],
[87,14,11,4],
[87,14,11,5],
[87,14,11,6],
[87,14,11,7],
[87,14,11,8],
[87,14,11,9],
[87,14,12,0],
[87,14,12,1],
[87,14,12,2],
[87,14,12,3],
[87,14,12,4],
[87,14,12,5],
[87,14,12,6],
[87,14,12,7],
[87,14,12,8],
[87,14,12,9],
[87,15,3,0],
[87,15,3,1],
[87,15,3,2],
[87,15,4,0],
[87,15,4,1],
[87,15,4,2],
[87,15,4,3],
[87,15,5,0],
[87,15,5,1],
[87,15,5,2],
[87,15,5,4],
[87,15,6,0],
[87,15,6,1],
[87,15,6,2],
[87,15,6,4],
[87,15,7,0],
[87,15,7,1],
[87,15,7,2],
[87,15,7,3],
[87,15,7,4],
[87,15,7,5],
[87,15,7,6],
[87,15,8,0],
[87,15,8,1],
[87,15,8,2],
[87,15,8,4],
[87,15,8,7],
[87,15,11,0],
[87,15,11,1],
[87,15,11,2],
[87,15,11,3],
[87,15,11,4],
[87,15,11,5],
[87,15,11,6],
[87,15,11,7],
[87,15,11,8],
[87,15,12,0],
[87,15,12,1],
[87,15,12,2],
[87,15,12,3],
[87,15,12,4],
[87,15,12,5],
[87,15,12,6],
[87,15,12,7],
[87,15,12,8],
[87,15,13,0],
[87,15,13,1],
[87,15,13,2],
[87,15,13,3],
[87,15,13,4],
[87,15,13,5],
[87,15,13,6],
[87,15,13,7],
[87,15,13,8],
[87,15,13,11],
[87,15,13,12],
[87,15,14,0],
[87,15,14,1],
[87,15,14,2],
[87,15,14,3],
[87,15,14,4],
[87,15,14,5],
[87,15,14,6],
[87,15,14,7],
[87,15,14,8],
[87,15,14,11],
[87,15,14,12],
[87,16,3,0],
[87,16,3,1],
[87,16,3,2],
[87,16,4,0],
[87,16,4,1],
[87,16,4,2],
[87,16,4,3],
[87,16,5,0],
[87,16,5,1],
[87,16,5,2],
[87,16,5,4],
[87,16,6,0],
[87,16,6,1],
[87,16,6,2],
[87,16,6,4],
[87,16,7,0],
[87,16,7,1],
[87,16,7,2],
[87,16,7,3],
[87,16,7,4],
[87,16,7,5],
[87,16,7,6],
[87,16,8,0],
[87,16,8,1],
[87,16,8,2],
[87,16,8,4],
[87,16,8,7],
[87,16,9,0],
[87,16,9,1],
[87,16,9,2],
[87,16,9,3],
[87,16,9,4],
[87,16,9,5],
[87,16,9,6],
[87,16,9,7],
[87,16,9,8],
[87,16,11,0],
[87,16,11,1],
[87,16,11,2],
[87,16,11,3],
[87,16,11,4],
[87,16,11,5],
[87,16,11,6],
[87,16,11,7],
[87,16,11,8],
[87,16,11,9],
[87,16,12,0],
[87,16,12,1],
[87,16,12,2],
[87,16,12,3],
[87,16,12,4],
[87,16,12,5],
[87,16,12,6],
[87,16,12,7],
[87,16,12,8],
[87,16,12,9],
[87,16,13,0],
[87,16,13,1],
[87,16,13,2],
[87,16,13,3],
[87,16,13,4],
[87,16,13,5],
[87,16,13,6],
[87,16,13,7],
[87,16,13,8],
[87,16,13,9],
[87,16,13,11],
[87,16,13,12],
[87,16,14,0],
[87,16,14,1],
[87,16,14,2],
[87,16,14,3],
[87,16,14,4],
[87,16,14,5],
[87,16,14,6],
[87,16,14,7],
[87,16,14,8],
[87,16,14,9],
[87,16,14,11],
[87,16,14,12],
[87,16,15,0],
[87,16,15,1],
[87,16,15,2],
[87,16,15,3],
[87,16,15,4],
[87,16,15,5],
[87,16,15,6],
[87,16,15,7],
[87,16,15,8],
[87,16,15,11],
[87,16,15,12],
[87,16,15,13],
[87,16,15,14],
[87,17,3,0],
[87,17,3,1],
[87,17,3,2],
[87,17,4,0],
[87,17,4,1],
[87,17,4,2],
[87,17,4,3],
[87,17,5,0],
[87,17,5,1],
[87,17,5,2],
[87,17,5,4],
[87,17,6,0],
[87,17,6,1],
[87,17,6,2],
[87,17,6,4],
[87,17,7,0],
[87,17,7,1],
[87,17,7,2],
[87,17,7,3],
[87,17,7,4],
[87,17,7,5],
[87,17,7,6],
[87,17,8,0],
[87,17,8,1],
[87,17,8,2],
[87,17,8,4],
[87,17,8,7],
[87,17,9,0],
[87,17,9,1],
[87,17,9,2],
[87,17,9,3],
[87,17,9,4],
[87,17,9,5],
[87,17,9,6],
[87,17,9,7],
[87,17,9,8],
[87,17,11,0],
[87,17,11,1],
[87,17,11,2],
[87,17,11,3],
[87,17,11,4],
[87,17,11,5],
[87,17,11,6],
[87,17,11,7],
[87,17,11,8],
[87,17,11,9],
[87,17,12,0],
[87,17,12,1],
[87,17,12,2],
[87,17,12,3],
[87,17,12,4],
[87,17,12,5],
[87,17,12,6],
[87,17,12,7],
[87,17,12,8],
[87,17,12,9],
[87,17,13,0],
[87,17,13,1],
[87,17,13,2],
[87,17,13,3],
[87,17,13,4],
[87,17,13,5],
[87,17,13,6],
[87,17,13,7],
[87,17,13,8],
[87,17,13,9],
[87,17,13,11],
[87,17,13,12],
[87,17,14,0],
[87,17,14,1],
[87,17,14,2],
[87,17,14,3],
[87,17,14,4],
[87,17,14,5],
[87,17,14,6],
[87,17,14,7],
[87,17,14,8],
[87,17,14,9],
[87,17,14,11],
[87,17,14,12],
[87,17,15,0],
[87,17,15,1],
[87,17,15,2],
[87,17,15,3],
[87,17,15,4],
[87,17,15,5],
[87,17,15,6],
[87,17,15,7],
[87,17,15,8],
[87,17,15,11],
[87,17,15,12],
[87,17,15,13],
[87,17,15,14],
[87,17,16,0],
[87,17,16,1],
[87,17,16,2],
[87,17,16,3],
[87,17,16,4],
[87,17,16,5],
[87,17,16,6],
[87,17,16,7],
[87,17,16,8],
[87,17,16,9],
[87,17,16,11],
[87,17,16,12],
[87,17,16,13],
[87,17,16,14],
[87,17,16,15],
[87,18,3,0],
[87,18,3,1],
[87,18,3,2],
[87,18,4,0],
[87,18,4,1],
[87,18,4,2],
[87,18,4,3],
[87,18,5,0],
[87,18,5,1],
[87,18,5,2],
[87,18,5,4],
[87,18,6,0],
[87,18,6,1],
[87,18,6,2],
[87,18,6,4],
[87,18,7,0],
[87,18,7,1],
[87,18,7,2],
[87,18,7,3],
[87,18,7,4],
[87,18,7,5],
[87,18,7,6],
[87,18,8,0],
[87,18,8,1],
[87,18,8,2],
[87,18,8,4],
[87,18,8,7],
[87,18,11,0],
[87,18,11,1],
[87,18,11,2],
[87,18,11,3],
[87,18,11,4],
[87,18,11,5],
[87,18,11,6],
[87,18,11,7],
[87,18,11,8],
[87,18,12,0],
[87,18,12,1],
[87,18,12,2],
[87,18,12,3],
[87,18,12,4],
[87,18,12,5],
[87,18,12,6],
[87,18,12,7],
[87,18,12,8],
[87,18,13,0],
[87,18,13,1],
[87,18,13,2],
[87,18,13,3],
[87,18,13,4],
[87,18,13,5],
[87,18,13,6],
[87,18,13,7],
[87,18,13,8],
[87,18,13,11],
[87,18,13,12],
[87,18,14,0],
[87,18,14,1],
[87,18,14,2],
[87,18,14,3],
[87,18,14,4],
[87,18,14,5],
[87,18,14,6],
[87,18,14,7],
[87,18,14,8],
[87,18,14,11],
[87,18,14,12],
[87,18,16,0],
[87,18,16,1],
[87,18,16,2],
[87,18,16,3],
[87,18,16,4],
[87,18,16,5],
[87,18,16,6],
[87,18,16,7],
[87,18,16,8],
[87,18,16,11],
[87,18,16,12],
[87,18,16,13],
[87,18,16,14],
[87,18,17,0],
[87,18,17,1],
[87,18,17,2],
[87,18,17,3],
[87,18,17,4],
[87,18,17,5],
[87,18,17,6],
[87,18,17,7],
[87,18,17,8],
[87,18,17,11],
[87,18,17,12],
[87,18,17,13],
[87,18,17,14],
[87,19,3,0],
[87,19,3,1],
[87,19,3,2],
[87,19,4,0],
[87,19,4,1],
[87,19,4,2],
[87,19,4,3],
[87,19,5,0],
[87,19,5,1],
[87,19,5,2],
[87,19,5,4],
[87,19,6,0],
[87,19,6,1],
[87,19,6,2],
[87,19,6,4],
[87,19,7,0],
[87,19,7,1],
[87,19,7,2],
[87,19,7,3],
[87,19,7,4],
[87,19,7,5],
[87,19,7,6],
[87,19,8,0],
[87,19,8,1],
[87,19,8,2],
[87,19,8,4],
[87,19,8,7],
[87,19,9,0],
[87,19,9,1],
[87,19,9,2],
[87,19,9,3],
[87,19,9,4],
[87,19,9,5],
[87,19,9,6],
[87,19,9,7],
[87,19,9,8],
[87,19,11,0],
[87,19,11,1],
[87,19,11,2],
[87,19,11,3],
[87,19,11,4],
[87,19,11,5],
[87,19,11,6],
[87,19,11,7],
[87,19,11,8],
[87,19,11,9],
[87,19,12,0],
[87,19,12,1],
[87,19,12,2],
[87,19,12,3],
[87,19,12,4],
[87,19,12,5],
[87,19,12,6],
[87,19,12,7],
[87,19,12,8],
[87,19,12,9],
[87,19,15,0],
[87,19,15,1],
[87,19,15,2],
[87,19,15,3],
[87,19,15,4],
[87,19,15,5],
[87,19,15,6],
[87,19,15,7],
[87,19,15,8],
[87,19,15,11],
[87,19,15,12],
[87,19,16,0],
[87,19,16,1],
[87,19,16,2],
[87,19,16,3],
[87,19,16,4],
[87,19,16,5],
[87,19,16,6],
[87,19,16,7],
[87,19,16,8],
[87,19,16,9],
[87,19,16,11],
[87,19,16,12],
[87,19,17,0],
[87,19,17,1],
[87,19,17,2],
[87,19,17,3],
[87,19,17,4],
[87,19,17,5],
[87,19,17,6],
[87,19,17,7],
[87,19,17,8],
[87,19,17,9],
[87,19,17,11],
[87,19,17,12],
[87,19,17,16],
[87,19,18,0],
[87,19,18,1],
[87,19,18,2],
[87,19,18,3],
[87,19,18,4],
[87,19,18,5],
[87,19,18,6],
[87,19,18,7],
[87,19,18,8],
[87,19,18,11],
[87,19,18,12],
[87,19,18,16],
[87,19,18,17],
[87,21,4,0],
[87,21,4,1],
[87,21,4,2],
[87,21,7,0],
[87,21,7,1],
[87,21,7,2],
[87,21,7,4],
[87,21,9,0],
[87,21,9,1],
[87,21,9,2],
[87,21,9,4],
[87,21,9,7],
[87,21,11,0],
[87,21,11,1],
[87,21,11,2],
[87,21,11,4],
[87,21,11,7],
[87,21,11,9],
[87,21,12,0],
[87,21,12,1],
[87,21,12,2],
[87,21,12,4],
[87,21,12,7],
[87,21,12,9],
[87,21,13,0],
[87,21,13,1],
[87,21,13,2],
[87,21,13,4],
[87,21,13,7],
[87,21,13,9],
[87,21,13,11],
[87,21,13,12],
[87,21,14,0],
[87,21,14,1],
[87,21,14,2],
[87,21,14,4],
[87,21,14,7],
[87,21,14,9],
[87,21,14,11],
[87,21,14,12],
[87,21,15,0],
[87,21,15,1],
[87,21,15,2],
[87,21,15,4],
[87,21,15,7],
[87,21,15,11],
[87,21,15,12],
[87,21,15,13],
[87,21,16,0],
[87,21,16,1],
[87,21,16,2],
[87,21,16,4],
[87,21,16,7],
[87,21,16,9],
[87,21,16,11],
[87,21,16,14],
[87,21,16,15],
[87,21,17,0],
[87,21,17,1],
[87,21,17,2],
[87,21,17,4],
[87,21,17,7],
[87,21,17,9],
[87,21,17,11],
[87,21,17,14],
[87,21,17,15],
[87,21,17,16],
[87,21,18,0],
[87,21,18,1],
[87,21,18,2],
[87,21,18,4],
[87,21,18,7],
[87,21,18,12],
[87,21,18,13],
[87,21,18,14],
[87,21,18,16],
[87,21,18,17],
[87,21,19,0],
[87,21,19,1],
[87,21,19,2],
[87,21,19,4],
[87,21,19,7],
[87,21,19,11],
[87,21,19,12],
[87,21,19,15],
[87,21,19,16],
[87,21,19,17],
[87,21,19,18],
[87,23,3,0],
[87,23,3,1],
[87,23,3,2],
[87,23,4,0],
[87,23,4,1],
[87,23,4,2],
[87,23,4,3],
[87,23,5,0],
[87,23,5,1],
[87,23,5,2],
[87,23,5,4],
[87,23,6,0],
[87,23,6,1],
[87,23,6,2],
[87,23,6,4],
[87,23,7,0],
[87,23,7,1],
[87,23,7,2],
[87,23,7,3],
[87,23,7,4],
[87,23,7,5],
[87,23,7,6],
[87,23,8,0],
[87,23,8,1],
[87,23,8,2],
[87,23,8,4],
[87,23,8,7],
[87,23,9,0],
[87,23,9,1],
[87,23,9,2],
[87,23,9,3],
[87,23,9,4],
[87,23,9,5],
[87,23,9,6],
[87,23,9,7],
[87,23,9,8],
[87,23,11,0],
[87,23,11,1],
[87,23,11,2],
[87,23,11,3],
[87,23,11,4],
[87,23,11,5],
[87,23,11,6],
[87,23,11,7],
[87,23,11,8],
[87,23,11,9],
[87,23,12,0],
[87,23,12,1],
[87,23,12,2],
[87,23,12,3],
[87,23,12,4],
[87,23,12,5],
[87,23,12,6],
[87,23,12,7],
[87,23,12,8],
[87,23,12,9],
[87,23,13,0],
[87,23,13,1],
[87,23,13,2],
[87,23,13,3],
[87,23,13,4],
[87,23,13,5],
[87,23,13,6],
[87,23,13,7],
[87,23,13,8],
[87,23,13,9],
[87,23,13,11],
[87,23,13,12],
[87,23,14,0],
[87,23,14,1],
[87,23,14,2],
[87,23,14,3],
[87,23,14,4],
[87,23,14,5],
[87,23,14,6],
[87,23,14,7],
[87,23,14,8],
[87,23,14,9],
[87,23,14,11],
[87,23,15,0],
[87,23,15,1],
[87,23,15,2],
[87,23,15,3],
[87,23,15,4],
[87,23,15,5],
[87,23,15,6],
[87,23,15,7],
[87,23,15,8],
[87,23,15,12],
[87,23,15,13],
[87,23,15,14],
[87,23,16,0],
[87,23,16,1],
[87,23,16,2],
[87,23,16,3],
[87,23,16,4],
[87,23,16,5],
[87,23,16,6],
[87,23,16,7],
[87,23,16,11],
[87,23,16,12],
[87,23,16,13],
[87,23,16,14],
[87,23,16,15],
[87,23,17,0],
[87,23,17,1],
[87,23,17,2],
[87,23,17,3],
[87,23,17,4],
[87,23,17,5],
[87,23,17,6],
[87,23,17,7],
[87,23,17,11],
[87,23,17,12],
[87,23,17,13],
[87,23,17,14],
[87,23,17,15],
[87,23,17,16],
[87,23,18,0],
[87,23,18,1],
[87,23,18,2],
[87,23,18,3],
[87,23,18,4],
[87,23,18,5],
[87,23,18,8],
[87,23,18,11],
[87,23,18,12],
[87,23,18,13],
[87,23,18,14],
[87,23,18,16],
[87,23,18,17],
[87,23,19,0],
[87,23,19,1],
[87,23,19,2],
[87,23,19,3],
[87,23,19,4],
[87,23,19,6],
[87,23,19,7],
[87,23,19,8],
[87,23,19,9],
[87,23,19,11],
[87,23,19,12],
[87,23,19,15],
[87,23,19,16],
[87,23,19,17],
[87,23,19,18],
[87,23,21,4],
[87,23,21,7],
[87,23,21,9],
[87,23,21,11],
[87,23,21,12],
[87,23,21,13],
[87,23,21,14],
[87,23,21,15],
[87,23,21,16],
[87,23,21,17],
[87,23,21,18],
[87,23,21,19],
[87,24,3,0],
[87,24,3,1],
[87,24,3,2],
[87,24,4,0],
[87,24,4,1],
[87,24,4,2],
[87,24,4,3],
[87,24,5,0],
[87,24,5,1],
[87,24,5,2],
[87,24,5,4],
[87,24,6,0],
[87,24,6,1],
[87,24,6,2],
[87,24,6,4],
[87,24,7,0],
[87,24,7,1],
[87,24,7,2],
[87,24,7,3],
[87,24,7,4],
[87,24,7,5],
[87,24,7,6],
[87,24,8,0],
[87,24,8,1],
[87,24,8,2],
[87,24,8,4],
[87,24,8,7],
[87,24,9,0],
[87,24,9,1],
[87,24,9,2],
[87,24,9,3],
[87,24,9,4],
[87,24,9,5],
[87,24,9,6],
[87,24,9,7],
[87,24,9,8],
[87,24,11,0],
[87,24,11,1],
[87,24,11,2],
[87,24,11,3],
[87,24,11,4],
[87,24,11,5],
[87,24,11,6],
[87,24,11,7],
[87,24,11,8],
[87,24,11,9],
[87,24,12,0],
[87,24,12,1],
[87,24,12,2],
[87,24,12,3],
[87,24,12,4],
[87,24,12,5],
[87,24,12,6],
[87,24,12,7],
[87,24,12,8],
[87,24,12,9],
[87,24,13,0],
[87,24,13,1],
[87,24,13,2],
[87,24,13,3],
[87,24,13,4],
[87,24,13,5],
[87,24,13,6],
[87,24,13,7],
[87,24,13,8],
[87,24,13,9],
[87,24,13,11],
[87,24,13,12],
[87,24,14,0],
[87,24,14,1],
[87,24,14,2],
[87,24,14,3],
[87,24,14,4],
[87,24,14,5],
[87,24,14,6],
[87,24,14,7],
[87,24,14,8],
[87,24,14,9],
[87,24,14,11],
[87,24,15,0],
[87,24,15,1],
[87,24,15,2],
[87,24,15,3],
[87,24,15,4],
[87,24,15,5],
[87,24,15,6],
[87,24,15,7],
[87,24,15,8],
[87,24,15,12],
[87,24,15,13],
[87,24,15,14],
[87,24,17,0],
[87,24,17,1],
[87,24,17,2],
[87,24,17,3],
[87,24,17,4],
[87,24,17,5],
[87,24,17,6],
[87,24,17,7],
[87,24,17,11],
[87,24,17,12],
[87,24,17,13],
[87,24,17,14],
[87,24,17,15],
[87,24,18,0],
[87,24,18,1],
[87,24,18,2],
[87,24,18,3],
[87,24,18,4],
[87,24,18,5],
[87,24,18,8],
[87,24,18,11],
[87,24,18,12],
[87,24,18,13],
[87,24,18,14],
[87,24,18,17],
[87,24,19,0],
[87,24,19,1],
[87,24,19,2],
[87,24,19,3],
[87,24,19,4],
[87,24,19,6],
[87,24,19,7],
[87,24,19,8],
[87,24,19,9],
[87,24,19,11],
[87,24,19,12],
[87,24,19,15],
[87,24,19,17],
[87,24,19,18],
[87,24,21,4],
[87,24,21,7],
[87,24,21,9],
[87,24,21,11],
[87,24,21,12],
[87,24,21,13],
[87,24,21,14],
[87,24,21,15],
[87,24,21,17],
[87,24,21,18],
[87,24,21,19],
[87,24,23,0],
[87,24,23,1],
[87,24,23,2],
[87,24,23,3],
[87,24,23,4],
[87,24,23,5],
[87,24,23,6],
[87,24,23,7],
[87,24,23,8],
[87,24,23,9],
[87,24,23,11],
[87,24,23,12],
[87,24,23,13],
[87,24,23,14],
[87,24,23,15],
[87,24,23,17],
[87,24,23,18],
[87,25,3,0],
[87,25,3,1],
[87,25,3,2],
[87,25,4,0],
[87,25,4,1],
[87,25,4,2],
[87,25,4,3],
[87,25,5,0],
[87,25,5,1],
[87,25,5,2],
[87,25,5,4],
[87,25,6,0],
[87,25,6,1],
[87,25,6,2],
[87,25,6,4],
[87,25,8,0],
[87,25,8,1],
[87,25,8,2],
[87,25,8,4],
[87,25,9,0],
[87,25,9,1],
[87,25,9,2],
[87,25,9,3],
[87,25,9,4],
[87,25,9,5],
[87,25,9,6],
[87,25,9,8],
[87,25,11,0],
[87,25,11,1],
[87,25,11,2],
[87,25,11,3],
[87,25,11,4],
[87,25,11,5],
[87,25,11,6],
[87,25,11,8],
[87,25,11,9],
[87,25,12,0],
[87,25,12,1],
[87,25,12,2],
[87,25,12,3],
[87,25,12,4],
[87,25,12,5],
[87,25,12,6],
[87,25,12,8],
[87,25,12,9],
[87,25,13,0],
[87,25,13,1],
[87,25,13,2],
[87,25,13,3],
[87,25,13,4],
[87,25,13,5],
[87,25,13,6],
[87,25,13,8],
[87,25,13,9],
[87,25,13,12],
[87,25,14,0],
[87,25,14,1],
[87,25,14,2],
[87,25,14,3],
[87,25,14,4],
[87,25,14,5],
[87,25,14,6],
[87,25,14,11],
[87,25,14,12],
[87,25,15,0],
[87,25,15,1],
[87,25,15,2],
[87,25,15,3],
[87,25,15,4],
[87,25,15,5],
[87,25,15,8],
[87,25,15,11],
[87,25,15,12],
[87,25,15,13],
[87,25,15,14],
[87,25,16,0],
[87,25,16,1],
[87,25,16,2],
[87,25,16,3],
[87,25,16,4],
[87,25,16,6],
[87,25,16,8],
[87,25,16,9],
[87,25,16,11],
[87,25,16,12],
[87,25,16,13],
[87,25,16,14],
[87,25,16,15],
[87,25,17,0],
[87,25,17,1],
[87,25,17,2],
[87,25,17,3],
[87,25,17,4],
[87,25,17,6],
[87,25,17,8],
[87,25,17,9],
[87,25,17,11],
[87,25,17,12],
[87,25,17,13],
[87,25,17,14],
[87,25,17,15],
[87,25,17,16],
[87,25,18,0],
[87,25,18,1],
[87,25,18,2],
[87,25,18,5],
[87,25,18,6],
[87,25,18,8],
[87,25,18,11],
[87,25,18,12],
[87,25,18,13],
[87,25,18,14],
[87,25,18,16],
[87,25,18,17],
[87,25,19,3],
[87,25,19,4],
[87,25,19,5],
[87,25,19,6],
[87,25,19,8],
[87,25,19,9],
[87,25,19,11],
[87,25,19,12],
[87,25,19,15],
[87,25,19,16],
[87,25,19,17],
[87,25,19,18],
[87,25,21,0],
[87,25,21,1],
[87,25,21,2],
[87,25,21,4],
[87,25,21,9],
[87,25,21,11],
[87,25,21,12],
[87,25,21,13],
[87,25,21,14],
[87,25,21,15],
[87,25,21,16],
[87,25,21,17],
[87,25,21,18],
[87,25,23,0],
[87,25,23,1],
[87,25,23,2],
[87,25,23,3],
[87,25,23,4],
[87,25,23,5],
[87,25,23,6],
[87,25,23,8],
[87,25,23,9],
[87,25,23,11],
[87,25,23,12],
[87,25,23,13],
[87,25,23,14],
[87,25,23,15],
[87,25,24,0],
[87,25,24,1],
[87,25,24,2],
[87,25,24,3],
[87,25,24,4],
[87,25,24,5],
[87,25,24,6],
[87,25,24,8],
[87,25,24,9],
[87,25,24,11],
[87,25,24,12],
[87,25,24,13],
[87,25,24,14],
[87,25,24,15],
[87,26,3,0],
[87,26,3,1],
[87,26,3,2],
[87,26,4,0],
[87,26,4,1],
[87,26,4,2],
[87,26,4,3],
[87,26,5,0],
[87,26,5,1],
[87,26,5,2],
[87,26,5,4],
[87,26,6,0],
[87,26,6,1],
[87,26,6,2],
[87,26,6,4],
[87,26,7,0],
[87,26,7,1],
[87,26,7,2],
[87,26,7,3],
[87,26,7,4],
[87,26,7,5],
[87,26,7,6],
[87,26,8,0],
[87,26,8,1],
[87,26,8,2],
[87,26,8,4],
[87,26,8,7],
[87,26,11,0],
[87,26,11,1],
[87,26,11,2],
[87,26,11,3],
[87,26,11,4],
[87,26,11,5],
[87,26,11,6],
[87,26,11,7],
[87,26,11,8],
[87,26,12,0],
[87,26,12,1],
[87,26,12,2],
[87,26,12,3],
[87,26,12,4],
[87,26,12,5],
[87,26,12,6],
[87,26,12,7],
[87,26,13,0],
[87,26,13,1],
[87,26,13,2],
[87,26,13,3],
[87,26,13,4],
[87,26,13,5],
[87,26,13,6],
[87,26,13,7],
[87,26,13,11],
[87,26,13,12],
[87,26,14,0],
[87,26,14,1],
[87,26,14,2],
[87,26,14,3],
[87,26,14,4],
[87,26,14,5],
[87,26,14,8],
[87,26,14,11],
[87,26,14,12],
[87,26,16,0],
[87,26,16,1],
[87,26,16,2],
[87,26,16,5],
[87,26,16,6],
[87,26,16,7],
[87,26,16,8],
[87,26,16,11],
[87,26,16,12],
[87,26,16,13],
[87,26,16,14],
[87,26,17,0],
[87,26,17,1],
[87,26,17,2],
[87,26,17,5],
[87,26,17,6],
[87,26,17,7],
[87,26,17,8],
[87,26,17,11],
[87,26,17,12],
[87,26,17,13],
[87,26,17,14],
[87,26,17,16],
[87,26,19,0],
[87,26,19,1],
[87,26,19,2],
[87,26,19,3],
[87,26,19,4],
[87,26,19,5],
[87,26,19,6],
[87,26,19,7],
[87,26,19,8],
[87,26,19,11],
[87,26,19,12],
[87,26,19,16],
[87,26,19,17],
[87,26,21,0],
[87,26,21,1],
[87,26,21,2],
[87,26,21,4],
[87,26,21,7],
[87,26,21,11],
[87,26,21,12],
[87,26,21,13],
[87,26,21,14],
[87,26,21,16],
[87,26,21,17],
[87,26,23,0],
[87,26,23,1],
[87,26,23,2],
[87,26,23,3],
[87,26,23,4],
[87,26,23,5],
[87,26,23,6],
[87,26,23,7],
[87,26,23,8],
[87,26,23,11],
[87,26,23,12],
[87,26,23,13],
[87,26,23,14],
[87,26,24,0],
[87,26,24,1],
[87,26,24,2],
[87,26,24,3],
[87,26,24,4],
[87,26,24,5],
[87,26,24,6],
[87,26,24,7],
[87,26,24,8],
[87,26,24,11],
[87,26,24,12],
[87,26,24,13],
[87,26,24,14],
[87,26,25,0],
[87,26,25,1],
[87,26,25,2],
[87,26,25,3],
[87,26,25,4],
[87,26,25,5],
[87,26,25,6],
[87,26,25,8],
[87,26,25,11],
[87,28,3,0],
[87,28,3,1],
[87,28,3,2],
[87,28,4,0],
[87,28,4,1],
[87,28,4,2],
[87,28,4,3],
[87,28,5,0],
[87,28,5,1],
[87,28,5,2],
[87,28,5,4],
[87,28,6,0],
[87,28,6,1],
[87,28,6,2],
[87,28,6,4],
[87,28,8,0],
[87,28,8,1],
[87,28,8,2],
[87,28,8,4],
[87,28,9,0],
[87,28,9,1],
[87,28,9,2],
[87,28,9,3],
[87,28,9,4],
[87,28,9,5],
[87,28,9,6],
[87,28,11,0],
[87,28,11,1],
[87,28,11,2],
[87,28,11,3],
[87,28,11,4],
[87,28,11,5],
[87,28,11,8],
[87,28,11,9],
[87,28,12,0],
[87,28,12,1],
[87,28,12,2],
[87,28,12,3],
[87,28,12,4],
[87,28,12,6],
[87,28,12,8],
[87,28,12,9],
[87,28,13,0],
[87,28,13,1],
[87,28,13,2],
[87,28,13,3],
[87,28,13,4],
[87,28,13,6],
[87,28,13,8],
[87,28,13,9],
[87,28,13,11],
[87,28,13,12],
[87,28,14,0],
[87,28,14,1],
[87,28,14,2],
[87,28,14,5],
[87,28,14,6],
[87,28,14,8],
[87,28,14,9],
[87,28,14,11],
[87,28,14,12],
[87,28,15,3],
[87,28,15,4],
[87,28,15,5],
[87,28,15,6],
[87,28,15,8],
[87,28,15,11],
[87,28,15,12],
[87,28,15,13],
[87,28,15,14],
[87,28,16,0],
[87,28,16,1],
[87,28,16,2],
[87,28,16,3],
[87,28,16,4],
[87,28,16,5],
[87,28,16,6],
[87,28,16,8],
[87,28,16,9],
[87,28,16,11],
[87,28,16,12],
[87,28,16,13],
[87,28,16,14],
[87,28,16,15],
[87,28,17,0],
[87,28,17,1],
[87,28,17,2],
[87,28,17,3],
[87,28,17,4],
[87,28,17,5],
[87,28,17,6],
[87,28,17,8],
[87,28,17,9],
[87,28,17,11],
[87,28,17,12],
[87,28,17,13],
[87,28,17,14],
[87,28,17,15],
[87,28,17,16],
[87,28,18,0],
[87,28,18,1],
[87,28,18,2],
[87,28,18,3],
[87,28,18,4],
[87,28,18,5],
[87,28,18,6],
[87,28,18,8],
[87,28,18,11],
[87,28,18,12],
[87,28,18,13],
[87,28,18,14],
[87,28,18,16],
[87,28,18,17],
[87,28,19,0],
[87,28,19,1],
[87,28,19,2],
[87,28,19,3],
[87,28,19,4],
[87,28,19,5],
[87,28,19,6],
[87,28,19,8],
[87,28,19,9],
[87,28,19,11],
[87,28,19,12],
[87,28,19,15],
[87,28,19,16],
[87,28,19,17],
[87,28,21,0],
[87,28,21,1],
[87,28,21,2],
[87,28,21,4],
[87,28,21,9],
[87,28,21,11],
[87,28,21,12],
[87,28,21,13],
[87,28,21,14],
[87,28,23,0],
[87,28,23,1],
[87,28,23,2],
[87,28,23,3],
[87,28,23,4],
[87,28,23,5],
[87,28,23,6],
[87,28,23,8],
[87,28,23,9],
[87,28,23,11],
[87,28,24,0],
[87,28,24,1],
[87,28,24,2],
[87,28,24,3],
[87,28,24,4],
[87,28,24,5],
[87,28,24,6],
[87,28,24,8],
[87,28,24,9],
[87,28,24,11],
[87,28,26,0],
[87,28,26,1],
[87,28,26,2],
[87,28,26,3],
[87,28,26,4],
[87,28,26,5],
[87,29,3,0],
[87,29,3,1],
[87,29,3,2],
[87,29,4,0],
[87,29,4,1],
[87,29,4,2],
[87,29,4,3],
[87,29,5,0],
[87,29,5,1],
[87,29,5,2],
[87,29,5,4],
[87,29,6,0],
[87,29,6,1],
[87,29,6,2],
[87,29,6,4],
[87,29,7,0],
[87,29,7,1],
[87,29,7,2],
[87,29,7,3],
[87,29,7,4],
[87,29,7,5],
[87,29,7,6],
[87,29,8,0],
[87,29,8,1],
[87,29,8,2],
[87,29,8,4],
[87,29,9,0],
[87,29,9,1],
[87,29,9,2],
[87,29,9,3],
[87,29,9,4],
[87,29,9,5],
[87,29,9,8],
[87,29,13,0],
[87,29,13,1],
[87,29,13,2],
[87,29,13,5],
[87,29,13,6],
[87,29,13,7],
[87,29,13,8],
[87,29,13,9],
[87,29,14,3],
[87,29,14,4],
[87,29,14,5],
[87,29,14,6],
[87,29,14,7],
[87,29,14,8],
[87,29,14,9],
[87,29,15,0],
[87,29,15,1],
[87,29,15,2],
[87,29,15,3],
[87,29,15,4],
[87,29,15,5],
[87,29,15,6],
[87,29,15,7],
[87,29,15,8],
[87,29,15,13],
[87,29,15,14],
[87,29,16,0],
[87,29,16,1],
[87,29,16,2],
[87,29,16,3],
[87,29,16,4],
[87,29,16,5],
[87,29,16,6],
[87,29,16,7],
[87,29,16,8],
[87,29,16,9],
[87,29,16,13],
[87,29,16,14],
[87,29,16,15],
[87,29,17,0],
[87,29,17,1],
[87,29,17,2],
[87,29,17,3],
[87,29,17,4],
[87,29,17,5],
[87,29,17,6],
[87,29,17,7],
[87,29,17,8],
[87,29,17,9],
[87,29,17,13],
[87,29,17,14],
[87,29,17,15],
[87,29,17,16],
[87,29,18,0],
[87,29,18,1],
[87,29,18,2],
[87,29,18,3],
[87,29,18,4],
[87,29,18,5],
[87,29,18,6],
[87,29,18,7],
[87,29,18,8],
[87,29,18,13],
[87,29,18,14],
[87,29,18,16],
[87,29,18,17],
[87,29,19,0],
[87,29,19,1],
[87,29,19,2],
[87,29,19,3],
[87,29,19,4],
[87,29,19,5],
[87,29,19,6],
[87,29,19,7],
[87,29,19,8],
[87,29,19,9],
[87,29,19,15],
[87,29,21,0],
[87,29,21,1],
[87,29,21,2],
[87,29,21,4],
[87,29,21,7],
[87,29,21,9],
[87,29,21,13],
[87,29,23,0],
[87,29,23,1],
[87,29,23,2],
[87,29,23,3],
[87,29,23,4],
[87,29,23,5],
[87,29,23,6],
[87,29,23,7],
[87,29,23,8],
[87,29,23,9],
[87,29,24,0],
[87,29,24,1],
[87,29,24,2],
[87,29,24,3],
[87,29,24,4],
[87,29,24,5],
[87,29,24,6],
[87,29,24,7],
[87,29,24,8],
[87,29,24,9],
[87,29,25,0],
[87,29,25,1],
[87,29,25,2],
[87,29,25,3],
[87,29,25,4],
[87,29,25,5],
[87,29,26,0],
[87,29,26,1],
[87,29,26,2],
[87,29,26,3],
[87,29,26,4],
[87,30,3,0],
[87,30,3,1],
[87,30,3,2],
[87,30,4,0],
[87,30,4,1],
[87,30,4,2],
[87,30,4,3],
[87,30,5,0],
[87,30,5,1],
[87,30,5,2],
[87,30,5,4],
[87,30,6,0],
[87,30,6,1],
[87,30,6,2],
[87,30,6,4],
[87,30,7,0],
[87,30,7,1],
[87,30,7,2],
[87,30,7,3],
[87,30,7,4],
[87,30,7,5],
[87,30,8,0],
[87,30,8,1],
[87,30,8,2],
[87,30,8,4],
[87,30,8,7],
[87,30,9,0],
[87,30,9,1],
[87,30,9,2],
[87,30,9,3],
[87,30,9,4],
[87,30,9,6],
[87,30,9,7],
[87,30,9,8],
[87,30,11,0],
[87,30,11,1],
[87,30,11,2],
[87,30,11,5],
[87,30,11,6],
[87,30,11,7],
[87,30,11,8],
[87,30,11,9],
[87,30,12,3],
[87,30,12,4],
[87,30,12,5],
[87,30,12,6],
[87,30,12,7],
[87,30,12,8],
[87,30,12,9],
[87,30,13,3],
[87,30,13,4],
[87,30,13,5],
[87,30,13,6],
[87,30,13,7],
[87,30,13,8],
[87,30,13,9],
[87,30,13,11],
[87,30,13,12],
[87,30,14,0],
[87,30,14,1],
[87,30,14,2],
[87,30,14,3],
[87,30,14,4],
[87,30,14,5],
[87,30,14,6],
[87,30,14,7],
[87,30,14,8],
[87,30,14,9],
[87,30,14,11],
[87,30,14,12],
[87,30,15,0],
[87,30,15,1],
[87,30,15,2],
[87,30,15,3],
[87,30,15,4],
[87,30,15,5],
[87,30,15,6],
[87,30,15,7],
[87,30,15,8],
[87,30,15,11],
[87,30,15,12],
[87,30,15,13],
[87,30,15,14],
[87,30,16,0],
[87,30,16,1],
[87,30,16,2],
[87,30,16,3],
[87,30,16,4],
[87,30,16,5],
[87,30,16,6],
[87,30,16,7],
[87,30,16,8],
[87,30,16,9],
[87,30,16,11],
[87,30,16,12],
[87,30,16,13],
[87,30,16,14],
[87,30,16,15],
[87,30,17,0],
[87,30,17,1],
[87,30,17,2],
[87,30,17,3],
[87,30,17,4],
[87,30,17,5],
[87,30,17,6],
[87,30,17,7],
[87,30,17,8],
[87,30,17,9],
[87,30,17,11],
[87,30,17,12],
[87,30,17,13],
[87,30,17,14],
[87,30,17,15],
[87,30,17,16],
[87,30,18,0],
[87,30,18,1],
[87,30,18,2],
[87,30,18,3],
[87,30,18,4],
[87,30,18,5],
[87,30,18,6],
[87,30,18,7],
[87,30,18,8],
[87,30,18,11],
[87,30,18,12],
[87,30,18,13],
[87,30,18,14],
[87,30,19,0],
[87,30,19,1],
[87,30,19,2],
[87,30,19,3],
[87,30,19,4],
[87,30,19,5],
[87,30,19,6],
[87,30,19,7],
[87,30,19,8],
[87,30,19,9],
[87,30,19,11],
[87,30,19,12],
[87,30,21,0],
[87,30,21,1],
[87,30,21,2],
[87,30,21,4],
[87,30,21,7],
[87,30,21,9],
[87,30,21,11],
[87,30,24,0],
[87,30,24,1],
[87,30,24,2],
[87,30,24,3],
[87,30,24,4],
[87,30,24,5],
[87,30,24,6],
[87,30,24,7],
[87,30,25,0],
[87,30,25,1],
[87,30,25,2],
[87,30,25,3],
[87,30,25,4],
[87,30,26,0],
[87,30,26,1],
[87,30,26,2],
[87,31,3,0],
[87,31,3,1],
[87,31,3,2],
[87,31,4,0],
[87,31,4,1],
[87,31,4,2],
[87,31,4,3],
[87,31,5,0],
[87,31,5,1],
[87,31,5,2],
[87,31,5,4],
[87,31,6,0],
[87,31,6,1],
[87,31,6,2],
[87,31,6,4],
[87,31,7,0],
[87,31,7,1],
[87,31,7,2],
[87,31,7,3],
[87,31,7,4],
[87,31,7,6],
[87,31,8,0],
[87,31,8,1],
[87,31,8,2],
[87,31,8,7],
[87,31,9,0],
[87,31,9,1],
[87,31,9,2],
[87,31,9,5],
[87,31,9,6],
[87,31,9,7],
[87,31,9,8],
[87,31,11,3],
[87,31,11,4],
[87,31,11,5],
[87,31,11,6],
[87,31,11,7],
[87,31,11,8],
[87,31,11,9],
[87,31,12,0],
[87,31,12,1],
[87,31,12,2],
[87,31,12,3],
[87,31,12,4],
[87,31,12,5],
[87,31,12,6],
[87,31,12,7],
[87,31,12,8],
[87,31,12,9],
[87,31,13,0],
[87,31,13,1],
[87,31,13,2],
[87,31,13,3],
[87,31,13,4],
[87,31,13,5],
[87,31,13,6],
[87,31,13,7],
[87,31,13,8],
[87,31,13,9],
[87,31,13,11],
[87,31,13,12],
[87,31,14,0],
[87,31,14,1],
[87,31,14,2],
[87,31,14,3],
[87,31,14,4],
[87,31,14,5],
[87,31,14,6],
[87,31,14,7],
[87,31,14,8],
[87,31,14,9],
[87,31,14,11],
[87,31,14,12],
[87,31,15,0],
[87,31,15,1],
[87,31,15,2],
[87,31,15,3],
[87,31,15,4],
[87,31,15,5],
[87,31,15,6],
[87,31,15,7],
[87,31,15,8],
[87,31,15,11],
[87,31,15,12],
[87,31,15,13],
[87,31,15,14],
[87,31,16,0],
[87,31,16,1],
[87,31,16,2],
[87,31,16,3],
[87,31,16,4],
[87,31,16,5],
[87,31,16,6],
[87,31,16,7],
[87,31,16,8],
[87,31,16,9],
[87,31,16,11],
[87,31,16,12],
[87,31,16,13],
[87,31,16,14],
[87,31,16,15],
[87,31,17,0],
[87,31,17,1],
[87,31,17,2],
[87,31,17,3],
[87,31,17,4],
[87,31,17,5],
[87,31,17,6],
[87,31,17,7],
[87,31,17,8],
[87,31,17,9],
[87,31,17,11],
[87,31,17,12],
[87,31,17,13],
[87,31,17,14],
[87,31,17,15],
[87,31,18,0],
[87,31,18,1],
[87,31,18,2],
[87,31,18,3],
[87,31,18,4],
[87,31,18,5],
[87,31,18,6],
[87,31,18,7],
[87,31,18,8],
[87,31,18,11],
[87,31,18,12],
[87,31,18,13],
[87,31,18,14],
[87,31,19,0],
[87,31,19,1],
[87,31,19,2],
[87,31,19,3],
[87,31,19,4],
[87,31,19,5],
[87,31,19,6],
[87,31,19,7],
[87,31,19,8],
[87,31,19,9],
[87,31,19,11],
[87,31,19,12],
[87,31,21,0],
[87,31,21,1],
[87,31,21,2],
[87,31,21,4],
[87,31,21,7],
[87,31,21,9],
[87,31,24,0],
[87,31,24,1],
[87,31,24,2],
[87,31,24,3],
[87,31,24,4],
[87,31,24,5],
[87,31,25,0],
[87,31,25,1],
[87,31,25,2],
[87,32,3,0],
[87,32,3,1],
[87,32,3,2],
[87,32,4,0],
[87,32,4,1],
[87,32,4,2],
[87,32,4,3],
[87,32,5,0],
[87,32,5,1],
[87,32,5,2],
[87,32,5,4],
[87,32,6,0],
[87,32,6,1],
[87,32,6,2],
[87,32,7,0],
[87,32,7,1],
[87,32,7,2],
[87,32,7,5],
[87,32,7,6],
[87,32,8,4],
[87,32,8,7],
[87,32,9,3],
[87,32,9,4],
[87,32,9,5],
[87,32,9,6],
[87,32,9,7],
[87,32,9,8],
[87,32,13,0],
[87,32,13,1],
[87,32,13,2],
[87,32,13,3],
[87,32,13,4],
[87,32,13,5],
[87,32,13,6],
[87,32,13,7],
[87,32,13,8],
[87,32,13,9],
[87,32,14,0],
[87,32,14,1],
[87,32,14,2],
[87,32,14,3],
[87,32,14,4],
[87,32,14,5],
[87,32,14,6],
[87,32,14,7],
[87,32,14,8],
[87,32,14,9],
[87,32,15,0],
[87,32,15,1],
[87,32,15,2],
[87,32,15,3],
[87,32,15,4],
[87,32,15,5],
[87,32,15,6],
[87,32,15,7],
[87,32,15,8],
[87,32,15,13],
[87,32,15,14],
[87,32,16,0],
[87,32,16,1],
[87,32,16,2],
[87,32,16,3],
[87,32,16,4],
[87,32,16,5],
[87,32,16,6],
[87,32,16,7],
[87,32,16,8],
[87,32,16,9],
[87,32,16,13],
[87,32,16,14],
[87,32,17,0],
[87,32,17,1],
[87,32,17,2],
[87,32,17,3],
[87,32,17,4],
[87,32,17,5],
[87,32,17,6],
[87,32,17,7],
[87,32,17,8],
[87,32,17,9],
[87,32,17,13],
[87,32,17,14],
[87,32,18,0],
[87,32,18,1],
[87,32,18,2],
[87,32,18,3],
[87,32,18,4],
[87,32,18,5],
[87,32,18,6],
[87,32,18,7],
[87,32,18,8],
[87,32,18,13],
[87,32,19,0],
[87,32,19,1],
[87,32,19,2],
[87,32,19,3],
[87,32,19,4],
[87,32,19,5],
[87,32,19,6],
[87,32,19,7],
[87,32,19,8],
[87,32,19,9],
[87,32,21,0],
[87,32,21,1],
[87,32,21,2],
[87,32,21,4],
[87,32,21,7],
[87,32,23,0],
[87,32,23,1],
[87,32,23,2],
[87,32,23,3],
[87,32,23,4],
[87,32,24,0],
[87,32,24,1],
[87,32,24,2],
[87,32,24,3],
[87,32,24,4],
[87,33,3,0],
[87,33,3,1],
[87,33,3,2],
[87,33,4,0],
[87,33,4,1],
[87,33,4,2],
[87,33,4,3],
[87,33,5,0],
[87,33,5,1],
[87,33,5,2],
[87,33,6,4],
[87,33,7,3],
[87,33,7,4],
[87,33,7,5],
[87,33,7,6],
[87,33,8,0],
[87,33,8,1],
[87,33,8,2],
[87,33,8,4],
[87,33,8,7],
[87,33,9,0],
[87,33,9,1],
[87,33,9,2],
[87,33,9,3],
[87,33,9,4],
[87,33,9,5],
[87,33,9,6],
[87,33,9,7],
[87,33,9,8],
[87,33,13,0],
[87,33,13,1],
[87,33,13,2],
[87,33,13,3],
[87,33,13,4],
[87,33,13,5],
[87,33,13,6],
[87,33,13,7],
[87,33,13,8],
[87,33,13,9],
[87,33,14,0],
[87,33,14,1],
[87,33,14,2],
[87,33,14,3],
[87,33,14,4],
[87,33,14,5],
[87,33,14,6],
[87,33,14,7],
[87,33,14,8],
[87,33,14,9],
[87,33,15,0],
[87,33,15,1],
[87,33,15,2],
[87,33,15,3],
[87,33,15,4],
[87,33,15,5],
[87,33,15,6],
[87,33,15,7],
[87,33,15,8],
[87,33,15,13],
[87,33,15,14],
[87,33,16,0],
[87,33,16,1],
[87,33,16,2],
[87,33,16,3],
[87,33,16,4],
[87,33,16,5],
[87,33,16,6],
[87,33,16,7],
[87,33,16,8],
[87,33,16,9],
[87,33,16,13],
[87,33,17,0],
[87,33,17,1],
[87,33,17,2],
[87,33,17,3],
[87,33,17,4],
[87,33,17,5],
[87,33,17,6],
[87,33,17,7],
[87,33,17,8],
[87,33,17,9],
[87,33,17,13],
[87,33,18,0],
[87,33,18,1],
[87,33,18,2],
[87,33,18,3],
[87,33,18,4],
[87,33,18,5],
[87,33,18,6],
[87,33,18,7],
[87,33,18,8],
[87,33,19,0],
[87,33,19,1],
[87,33,19,2],
[87,33,19,3],
[87,33,19,4],
[87,33,19,5],
[87,33,19,6],
[87,33,19,7],
[87,33,19,8],
[87,33,19,9],
[87,33,21,0],
[87,33,21,1],
[87,33,21,2],
[87,33,21,4],
[87,33,23,0],
[87,33,23,1],
[87,33,23,2],
[87,33,24,0],
[87,33,24,1],
[87,33,24,2],
[87,34,3,0],
[87,34,3,1],
[87,34,3,2],
[87,34,4,0],
[87,34,4,1],
[87,34,4,2],
[87,34,5,4],
[87,34,6,0],
[87,34,6,1],
[87,34,6,2],
[87,34,6,4],
[87,34,8,0],
[87,34,8,1],
[87,34,8,2],
[87,34,8,4],
[87,34,9,0],
[87,34,9,1],
[87,34,9,2],
[87,34,9,3],
[87,34,9,4],
[87,34,9,5],
[87,34,9,6],
[87,34,9,8],
[87,34,11,0],
[87,34,11,1],
[87,34,11,2],
[87,34,11,3],
[87,34,11,4],
[87,34,11,5],
[87,34,11,6],
[87,34,11,8],
[87,34,11,9],
[87,34,12,0],
[87,34,12,1],
[87,34,12,2],
[87,34,12,3],
[87,34,12,4],
[87,34,12,5],
[87,34,12,6],
[87,34,12,8],
[87,34,12,9],
[87,34,13,0],
[87,34,13,1],
[87,34,13,2],
[87,34,13,3],
[87,34,13,4],
[87,34,13,5],
[87,34,13,6],
[87,34,13,8],
[87,34,13,9],
[87,34,13,11],
[87,34,13,12],
[87,34,14,0],
[87,34,14,1],
[87,34,14,2],
[87,34,14,3],
[87,34,14,4],
[87,34,14,5],
[87,34,14,6],
[87,34,14,8],
[87,34,14,9],
[87,34,14,11],
[87,34,14,12],
[87,34,15,0],
[87,34,15,1],
[87,34,15,2],
[87,34,15,3],
[87,34,15,4],
[87,34,15,5],
[87,34,15,6],
[87,34,15,8],
[87,34,15,11],
[87,34,15,12],
[87,34,15,13],
[87,34,16,0],
[87,34,16,1],
[87,34,16,2],
[87,34,16,3],
[87,34,16,4],
[87,34,16,5],
[87,34,16,6],
[87,34,16,8],
[87,34,16,9],
[87,34,16,11],
[87,34,17,0],
[87,34,17,1],
[87,34,17,2],
[87,34,17,3],
[87,34,17,4],
[87,34,17,5],
[87,34,17,6],
[87,34,17,8],
[87,34,17,9],
[87,34,17,11],
[87,34,18,0],
[87,34,18,1],
[87,34,18,2],
[87,34,18,3],
[87,34,18,4],
[87,34,18,5],
[87,34,18,6],
[87,34,18,8],
[87,34,19,0],
[87,34,19,1],
[87,34,19,2],
[87,34,19,3],
[87,34,19,4],
[87,34,19,5],
[87,34,19,6],
[87,34,21,0],
[87,34,21,1],
[87,34,21,2],
[87,34,21,4],
[87,35,4,3],
[87,35,5,0],
[87,35,5,1],
[87,35,5,2],
[87,35,5,4],
[87,35,6,0],
[87,35,6,1],
[87,35,6,2],
[87,35,6,4],
[87,35,7,0],
[87,35,7,1],
[87,35,7,2],
[87,35,7,3],
[87,35,7,4],
[87,35,7,5],
[87,35,7,6],
[87,35,8,0],
[87,35,8,1],
[87,35,8,2],
[87,35,8,4],
[87,35,8,7],
[87,35,11,0],
[87,35,11,1],
[87,35,11,2],
[87,35,11,3],
[87,35,11,4],
[87,35,11,5],
[87,35,11,6],
[87,35,11,7],
[87,35,11,8],
[87,35,12,0],
[87,35,12,1],
[87,35,12,2],
[87,35,12,3],
[87,35,12,4],
[87,35,12,5],
[87,35,12,6],
[87,35,12,7],
[87,35,12,8],
[87,35,13,0],
[87,35,13,1],
[87,35,13,2],
[87,35,13,3],
[87,35,13,4],
[87,35,13,5],
[87,35,13,6],
[87,35,13,7],
[87,35,13,8],
[87,35,13,11],
[87,35,13,12],
[87,35,14,0],
[87,35,14,1],
[87,35,14,2],
[87,35,14,3],
[87,35,14,4],
[87,35,14,5],
[87,35,14,6],
[87,35,14,7],
[87,35,14,8],
[87,35,14,11],
[87,35,14,12],
[87,35,16,0],
[87,35,16,1],
[87,35,16,2],
[87,35,16,3],
[87,35,16,4],
[87,35,16,5],
[87,35,16,6],
[87,35,16,7],
[87,35,16,8],
[87,35,17,0],
[87,35,17,1],
[87,35,17,2],
[87,35,17,3],
[87,35,17,4],
[87,35,17,5],
[87,35,17,6],
[87,35,17,7],
[87,35,17,8],
[87,35,19,0],
[87,35,19,1],
[87,35,19,2],
[87,35,19,3],
[87,35,19,4],
[87,35,19,5],
[87,35,21,0],
[87,35,21,1],
[87,35,21,2],
[87,36,3,0],
[87,36,3,1],
[87,36,3,2],
[87,36,4,0],
[87,36,4,1],
[87,36,4,2],
[87,36,4,3],
[87,36,5,0],
[87,36,5,1],
[87,36,5,2],
[87,36,5,4],
[87,36,6,0],
[87,36,6,1],
[87,36,6,2],
[87,36,6,4],
[87,36,7,0],
[87,36,7,1],
[87,36,7,2],
[87,36,7,3],
[87,36,7,4],
[87,36,7,5],
[87,36,7,6],
[87,36,8,0],
[87,36,8,1],
[87,36,8,2],
[87,36,8,4],
[87,36,8,7],
[87,36,9,0],
[87,36,9,1],
[87,36,9,2],
[87,36,9,3],
[87,36,9,4],
[87,36,9,5],
[87,36,9,6],
[87,36,9,7],
[87,36,9,8],
[87,36,11,0],
[87,36,11,1],
[87,36,11,2],
[87,36,11,3],
[87,36,11,4],
[87,36,11,5],
[87,36,11,6],
[87,36,11,7],
[87,36,11,8],
[87,36,11,9],
[87,36,12,0],
[87,36,12,1],
[87,36,12,2],
[87,36,12,3],
[87,36,12,4],
[87,36,12,5],
[87,36,12,6],
[87,36,12,7],
[87,36,12,8],
[87,36,12,9],
[87,36,15,0],
[87,36,15,1],
[87,36,15,2],
[87,36,15,3],
[87,36,15,4],
[87,36,15,5],
[87,36,15,6],
[87,36,15,7],
[87,36,15,8],
[87,36,16,0],
[87,36,16,1],
[87,36,16,2],
[87,36,16,3],
[87,36,16,4],
[87,36,16,5],
[87,36,16,6],
[87,36,16,7],
[87,36,17,0],
[87,36,17,1],
[87,36,17,2],
[87,36,17,3],
[87,36,17,4],
[87,36,17,5],
[87,36,17,6],
[87,36,17,7],
[87,36,18,0],
[87,36,18,1],
[87,36,18,2],
[87,36,18,3],
[87,36,18,4],
[87,36,18,5],
[87,37,3,0],
[87,37,3,1],
[87,37,3,2],
[87,37,5,0],
[87,37,5,1],
[87,37,5,2],
[87,37,6,0],
[87,37,6,1],
[87,37,6,2],
[87,37,7,0],
[87,37,7,1],
[87,37,7,2],
[87,37,7,3],
[87,37,7,5],
[87,37,7,6],
[87,37,8,0],
[87,37,8,1],
[87,37,8,2],
[87,37,8,7],
[87,37,9,0],
[87,37,9,1],
[87,37,9,2],
[87,37,9,3],
[87,37,9,5],
[87,37,9,6],
[87,37,9,7],
[87,37,9,8],
[87,37,11,0],
[87,37,11,1],
[87,37,11,2],
[87,37,11,3],
[87,37,11,5],
[87,37,11,6],
[87,37,11,7],
[87,37,11,8],
[87,37,11,9],
[87,37,12,0],
[87,37,12,1],
[87,37,12,2],
[87,37,12,3],
[87,37,12,5],
[87,37,12,6],
[87,37,12,7],
[87,37,12,8],
[87,37,12,9],
[87,37,13,0],
[87,37,13,1],
[87,37,13,2],
[87,37,13,3],
[87,37,13,5],
[87,37,13,6],
[87,37,13,7],
[87,37,13,8],
[87,37,13,9],
[87,37,13,11],
[87,37,14,0],
[87,37,14,1],
[87,37,14,2],
[87,37,14,3],
[87,37,14,5],
[87,37,14,6],
[87,37,14,7],
[87,37,14,8],
[87,37,14,9],
[87,37,15,0],
[87,37,15,1],
[87,37,15,2],
[87,37,15,3],
[87,37,15,5],
[87,37,15,6],
[87,37,15,7],
[87,37,16,0],
[87,37,16,1],
[87,37,16,2],
[87,37,16,3],
[87,37,16,5],
[87,37,17,0],
[87,37,17,1],
[87,37,17,2],
[87,37,17,3],
[87,37,17,5],
[87,37,18,0],
[87,37,18,1],
[87,37,18,2],
[87,37,18,3],
[87,37,19,0],
[87,37,19,1],
[87,37,19,2],
[87,38,3,0],
[87,38,3,1],
[87,38,3,2],
[87,38,4,0],
[87,38,4,1],
[87,38,4,2],
[87,38,4,3],
[87,38,5,0],
[87,38,5,1],
[87,38,5,2],
[87,38,5,4],
[87,38,6,0],
[87,38,6,1],
[87,38,6,2],
[87,38,6,4],
[87,38,8,0],
[87,38,8,1],
[87,38,8,2],
[87,38,8,4],
[87,38,9,0],
[87,38,9,1],
[87,38,9,2],
[87,38,9,3],
[87,38,9,4],
[87,38,9,5],
[87,38,9,6],
[87,38,9,8],
[87,38,11,0],
[87,38,11,1],
[87,38,11,2],
[87,38,11,3],
[87,38,11,4],
[87,38,11,5],
[87,38,11,6],
[87,38,11,8],
[87,38,11,9],
[87,38,12,0],
[87,38,12,1],
[87,38,12,2],
[87,38,12,3],
[87,38,12,4],
[87,38,12,5],
[87,38,12,6],
[87,38,12,8],
[87,38,12,9],
[87,38,13,0],
[87,38,13,1],
[87,38,13,2],
[87,38,13,3],
[87,38,13,4],
[87,38,13,5],
[87,38,13,6],
[87,38,13,8],
[87,38,13,9],
[87,38,14,0],
[87,38,14,1],
[87,38,14,2],
[87,38,14,3],
[87,38,14,4],
[87,38,14,5],
[87,38,14,6],
[87,38,15,0],
[87,38,15,1],
[87,38,15,2],
[87,38,15,3],
[87,38,15,4],
[87,38,15,5],
[87,38,16,0],
[87,38,16,1],
[87,38,16,2],
[87,38,16,3],
[87,38,16,4],
[87,38,17,0],
[87,38,17,1],
[87,38,17,2],
[87,38,17,3],
[87,38,17,4],
[87,38,18,0],
[87,38,18,1],
[87,38,18,2],
[87,39,4,0],
[87,39,4,1],
[87,39,4,2],
[87,39,7,0],
[87,39,7,1],
[87,39,7,2],
[87,39,7,4],
[87,39,9,0],
[87,39,9,1],
[87,39,9,2],
[87,39,9,4],
[87,39,9,7],
[87,39,11,0],
[87,39,11,1],
[87,39,11,2],
[87,39,11,4],
[87,39,11,7],
[87,39,11,9],
[87,39,12,0],
[87,39,12,1],
[87,39,12,2],
[87,39,12,4],
[87,39,12,7],
[87,39,13,0],
[87,39,13,1],
[87,39,13,2],
[87,39,13,4],
[87,39,13,7],
[87,39,14,0],
[87,39,14,1],
[87,39,14,2],
[87,39,14,4],
[87,39,15,0],
[87,39,15,1],
[87,39,15,2],
[87,39,15,4],
[87,39,16,0],
[87,39,16,1],
[87,39,16,2],
[87,39,17,0],
[87,39,17,1],
[87,39,17,2],
[87,40,3,0],
[87,40,3,1],
[87,40,3,2],
[87,40,4,0],
[87,40,4,1],
[87,40,4,2],
[87,40,4,3],
[87,40,5,0],
[87,40,5,1],
[87,40,5,2],
[87,40,5,4],
[87,40,6,0],
[87,40,6,1],
[87,40,6,2],
[87,40,6,4],
[87,40,7,0],
[87,40,7,1],
[87,40,7,2],
[87,40,7,3],
[87,40,7,4],
[87,40,7,5],
[87,40,7,6],
[87,40,8,0],
[87,40,8,1],
[87,40,8,2],
[87,40,8,4],
[87,40,8,7],
[87,40,11,0],
[87,40,11,1],
[87,40,11,2],
[87,40,11,3],
[87,40,11,4],
[87,40,11,5],
[87,40,11,6],
[87,40,11,7],
[87,40,12,0],
[87,40,12,1],
[87,40,12,2],
[87,40,12,3],
[87,40,12,4],
[87,40,12,5],
[87,40,13,0],
[87,40,13,1],
[87,40,13,2],
[87,40,13,3],
[87,40,13,4],
[87,40,13,5],
[87,40,14,0],
[87,40,14,1],
[87,40,14,2],
[87,40,14,3],
[87,40,14,4],
[87,41,3,0],
[87,41,3,1],
[87,41,3,2],
[87,41,4,0],
[87,41,4,1],
[87,41,4,2],
[87,41,4,3],
[87,41,5,0],
[87,41,5,1],
[87,41,5,2],
[87,41,5,4],
[87,41,6,0],
[87,41,6,1],
[87,41,6,2],
[87,41,6,4],
[87,41,7,0],
[87,41,7,1],
[87,41,7,2],
[87,41,7,3],
[87,41,7,4],
[87,41,7,5],
[87,41,7,6],
[87,41,8,0],
[87,41,8,1],
[87,41,8,2],
[87,41,8,4],
[87,41,8,7],
[87,41,11,0],
[87,41,11,1],
[87,41,11,2],
[87,41,11,3],
[87,41,11,4],
[87,41,11,5],
[87,41,11,6],
[87,41,11,7],
[87,41,12,0],
[87,41,12,1],
[87,41,12,2],
[87,41,12,3],
[87,41,12,4],
[87,41,12,5],
[87,41,13,0],
[87,41,13,1],
[87,41,13,2],
[87,41,13,3],
[87,41,13,4],
[87,41,13,5],
[87,41,14,0],
[87,41,14,1],
[87,41,14,2],
[87,41,14,3],
[87,41,14,4],
[87,43,3,0],
[87,43,3,1],
[87,43,3,2],
[87,43,4,0],
[87,43,4,1],
[87,43,4,2],
[87,43,4,3],
[87,43,5,0],
[87,43,5,1],
[87,43,5,2],
[87,43,5,4],
[87,43,6,0],
[87,43,6,1],
[87,43,6,2],
[87,43,6,4],
[87,43,8,0],
[87,43,8,1],
[87,43,8,2],
[87,43,8,4],
[87,43,9,0],
[87,43,9,1],
[87,43,9,2],
[87,43,9,3],
[87,43,9,4],
[87,43,9,5],
[87,43,11,0],
[87,43,11,1],
[87,43,11,2],
[87,43,11,3],
[87,43,11,4],
[87,43,12,0],
[87,43,12,1],
[87,43,12,2],
[87,43,13,0],
[87,43,13,1],
[87,43,13,2],
[87,44,3,0],
[87,44,3,1],
[87,44,3,2],
[87,44,4,0],
[87,44,4,1],
[87,44,4,2],
[87,44,4,3],
[87,44,5,0],
[87,44,5,1],
[87,44,5,2],
[87,44,5,4],
[87,44,6,0],
[87,44,6,1],
[87,44,6,2],
[87,44,6,4],
[87,44,7,0],
[87,44,7,1],
[87,44,7,2],
[87,44,7,3],
[87,44,7,4],
[87,44,7,5],
[87,44,8,0],
[87,44,8,1],
[87,44,8,2],
[87,44,8,4],
[87,44,9,0],
[87,44,9,1],
[87,44,9,2],
[87,44,9,3],
[87,44,9,4],
[87,44,11,0],
[87,44,11,1],
[87,44,11,2],
[87,45,3,0],
[87,45,3,1],
[87,45,3,2],
[87,45,4,0],
[87,45,4,1],
[87,45,4,2],
[87,45,4,3],
[87,45,5,0],
[87,45,5,1],
[87,45,5,2],
[87,45,5,4],
[87,45,6,0],
[87,45,6,1],
[87,45,6,2],
[87,45,6,4],
[87,45,7,0],
[87,45,7,1],
[87,45,7,2],
[87,45,7,3],
[87,45,7,4],
[87,45,8,0],
[87,45,8,1],
[87,45,8,2],
[87,46,3,0],
[87,46,3,1],
[87,46,3,2],
[87,46,4,0],
[87,46,4,1],
[87,46,4,2],
[87,46,4,3],
[87,46,5,0],
[87,46,5,1],
[87,46,5,2],
[87,46,5,4],
[87,46,6,0],
[87,46,6,1],
[87,46,6,2],
[87,46,7,0],
[87,46,7,1],
[87,46,7,2],
[87,47,3,0],
[87,47,3,1],
[87,47,3,2],
[87,47,4,0],
[87,47,4,1],
[87,47,4,2],
[87,47,4,3],
[87,47,5,0],
[87,47,5,1],
[87,47,5,2],
[87,48,3,0],
[87,48,3,1],
[87,48,3,2],
[87,48,4,0],
[87,48,4,1],
[87,48,4,2],
[87,49,3,0],
[87,49,3,1],
[87,49,3,2],
[87,49,4,0],
[87,49,4,1],
[87,49,4,2],
[87,50,3,0],
[87,50,3,1],
[87,50,3,2],
[87,50,4,0],
[87,50,4,1],
[87,50,4,2],
[87,51,4,0],
[87,51,4,1],
[87,51,4,2],
[87,52,3,0],
[87,52,3,1],
[87,52,3,2],
[87,52,4,0],
[87,52,4,1],
[87,52,4,2],
[87,56,3,0],
[87,56,3,1],
[87,56,3,2],
[87,56,4,0],
[87,56,4,1],
[87,56,4,2],
[88,4,3,0],
[88,4,3,1],
[88,4,3,2],
[88,5,4,0],
[88,5,4,1],
[88,5,4,2],
[88,6,4,0],
[88,6,4,1],
[88,6,4,2],
[88,7,3,0],
[88,7,3,1],
[88,7,3,2],
[88,7,4,0],
[88,7,4,1],
[88,7,4,2],
[88,7,4,3],
[88,7,5,0],
[88,7,5,1],
[88,7,5,2],
[88,7,5,4],
[88,7,6,0],
[88,7,6,1],
[88,7,6,2],
[88,7,6,4],
[88,8,4,0],
[88,8,4,1],
[88,8,4,2],
[88,8,7,0],
[88,8,7,1],
[88,8,7,2],
[88,8,7,4],
[88,9,3,0],
[88,9,3,1],
[88,9,3,2],
[88,9,4,0],
[88,9,4,1],
[88,9,4,2],
[88,9,4,3],
[88,9,5,0],
[88,9,5,1],
[88,9,5,2],
[88,9,5,4],
[88,9,6,0],
[88,9,6,1],
[88,9,6,2],
[88,9,6,4],
[88,9,7,0],
[88,9,7,1],
[88,9,7,2],
[88,9,7,3],
[88,9,7,4],
[88,9,7,5],
[88,9,7,6],
[88,9,8,0],
[88,9,8,1],
[88,9,8,2],
[88,9,8,4],
[88,9,8,7],
[88,10,3,0],
[88,10,3,1],
[88,10,3,2],
[88,10,4,0],
[88,10,4,1],
[88,10,4,2],
[88,10,4,3],
[88,10,5,0],
[88,10,5,1],
[88,10,5,2],
[88,10,5,4],
[88,10,6,0],
[88,10,6,1],
[88,10,6,2],
[88,10,6,4],
[88,10,7,0],
[88,10,7,1],
[88,10,7,2],
[88,10,7,3],
[88,10,7,4],
[88,10,7,5],
[88,10,7,6],
[88,10,8,0],
[88,10,8,1],
[88,10,8,2],
[88,10,8,4],
[88,10,8,7],
[88,10,9,0],
[88,10,9,1],
[88,10,9,2],
[88,10,9,3],
[88,10,9,4],
[88,10,9,5],
[88,10,9,6],
[88,10,9,7],
[88,10,9,8],
[88,11,3,0],
[88,11,3,1],
[88,11,3,2],
[88,11,4,0],
[88,11,4,1],
[88,11,4,2],
[88,11,4,3],
[88,11,5,0],
[88,11,5,1],
[88,11,5,2],
[88,11,5,4],
[88,11,6,0],
[88,11,6,1],
[88,11,6,2],
[88,11,6,4],
[88,11,7,0],
[88,11,7,1],
[88,11,7,2],
[88,11,7,3],
[88,11,7,4],
[88,11,7,5],
[88,11,7,6],
[88,11,8,0],
[88,11,8,1],
[88,11,8,2],
[88,11,8,4],
[88,11,8,7],
[88,11,9,0],
[88,11,9,1],
[88,11,9,2],
[88,11,9,3],
[88,11,9,4],
[88,11,9,5],
[88,11,9,6],
[88,11,9,7],
[88,11,9,8],
[88,11,10,0],
[88,11,10,1],
[88,11,10,2],
[88,11,10,3],
[88,11,10,4],
[88,11,10,5],
[88,11,10,6],
[88,11,10,7],
[88,11,10,8],
[88,11,10,9],
[88,12,3,0],
[88,12,3,1],
[88,12,3,2],
[88,12,4,0],
[88,12,4,1],
[88,12,4,2],
[88,12,4,3],
[88,12,5,0],
[88,12,5,1],
[88,12,5,2],
[88,12,5,4],
[88,12,6,0],
[88,12,6,1],
[88,12,6,2],
[88,12,6,4],
[88,12,7,0],
[88,12,7,1],
[88,12,7,2],
[88,12,7,3],
[88,12,7,4],
[88,12,7,5],
[88,12,7,6],
[88,12,8,0],
[88,12,8,1],
[88,12,8,2],
[88,12,8,4],
[88,12,8,7],
[88,12,9,0],
[88,12,9,1],
[88,12,9,2],
[88,12,9,3],
[88,12,9,4],
[88,12,9,5],
[88,12,9,6],
[88,12,9,7],
[88,12,9,8],
[88,12,10,0],
[88,12,10,1],
[88,12,10,2],
[88,12,10,3],
[88,12,10,4],
[88,12,10,5],
[88,12,10,6],
[88,12,10,7],
[88,12,10,8],
[88,12,10,9],
[88,13,3,0],
[88,13,3,1],
[88,13,3,2],
[88,13,4,0],
[88,13,4,1],
[88,13,4,2],
[88,13,4,3],
[88,13,5,0],
[88,13,5,1],
[88,13,5,2],
[88,13,5,4],
[88,13,6,0],
[88,13,6,1],
[88,13,6,2],
[88,13,6,4],
[88,13,7,0],
[88,13,7,1],
[88,13,7,2],
[88,13,7,3],
[88,13,7,4],
[88,13,7,5],
[88,13,7,6],
[88,13,8,0],
[88,13,8,1],
[88,13,8,2],
[88,13,8,4],
[88,13,8,7],
[88,13,9,0],
[88,13,9,1],
[88,13,9,2],
[88,13,9,3],
[88,13,9,4],
[88,13,9,5],
[88,13,9,6],
[88,13,9,7],
[88,13,9,8],
[88,13,10,0],
[88,13,10,1],
[88,13,10,2],
[88,13,10,3],
[88,13,10,4],
[88,13,10,5],
[88,13,10,6],
[88,13,10,7],
[88,13,10,8],
[88,13,10,9],
[88,13,11,0],
[88,13,11,1],
[88,13,11,2],
[88,13,11,3],
[88,13,11,4],
[88,13,11,5],
[88,13,11,6],
[88,13,11,7],
[88,13,11,8],
[88,13,11,9],
[88,13,11,10],
[88,13,12,0],
[88,13,12,1],
[88,13,12,2],
[88,13,12,3],
[88,13,12,4],
[88,13,12,5],
[88,13,12,6],
[88,13,12,7],
[88,13,12,8],
[88,13,12,9],
[88,13,12,10],
[88,14,3,0],
[88,14,3,1],
[88,14,3,2],
[88,14,4,0],
[88,14,4,1],
[88,14,4,2],
[88,14,4,3],
[88,14,5,0],
[88,14,5,1],
[88,14,5,2],
[88,14,5,4],
[88,14,6,0],
[88,14,6,1],
[88,14,6,2],
[88,14,6,4],
[88,14,7,0],
[88,14,7,1],
[88,14,7,2],
[88,14,7,3],
[88,14,7,4],
[88,14,7,5],
[88,14,7,6],
[88,14,8,0],
[88,14,8,1],
[88,14,8,2],
[88,14,8,4],
[88,14,8,7],
[88,14,9,0],
[88,14,9,1],
[88,14,9,2],
[88,14,9,3],
[88,14,9,4],
[88,14,9,5],
[88,14,9,6],
[88,14,9,7],
[88,14,9,8],
[88,14,10,0],
[88,14,10,1],
[88,14,10,2],
[88,14,10,3],
[88,14,10,4],
[88,14,10,5],
[88,14,10,6],
[88,14,10,7],
[88,14,10,8],
[88,14,10,9],
[88,14,11,0],
[88,14,11,1],
[88,14,11,2],
[88,14,11,3],
[88,14,11,4],
[88,14,11,5],
[88,14,11,6],
[88,14,11,7],
[88,14,11,8],
[88,14,11,9],
[88,14,11,10],
[88,14,12,0],
[88,14,12,1],
[88,14,12,2],
[88,14,12,3],
[88,14,12,4],
[88,14,12,5],
[88,14,12,6],
[88,14,12,7],
[88,14,12,8],
[88,14,12,9],
[88,14,12,10],
[88,15,3,0],
[88,15,3,1],
[88,15,3,2],
[88,15,4,0],
[88,15,4,1],
[88,15,4,2],
[88,15,4,3],
[88,15,5,0],
[88,15,5,1],
[88,15,5,2],
[88,15,5,4],
[88,15,6,0],
[88,15,6,1],
[88,15,6,2],
[88,15,6,4],
[88,15,7,0],
[88,15,7,1],
[88,15,7,2],
[88,15,7,3],
[88,15,7,4],
[88,15,7,5],
[88,15,7,6],
[88,15,8,0],
[88,15,8,1],
[88,15,8,2],
[88,15,8,4],
[88,15,8,7],
[88,15,10,0],
[88,15,10,1],
[88,15,10,2],
[88,15,10,3],
[88,15,10,4],
[88,15,10,5],
[88,15,10,6],
[88,15,10,7],
[88,15,10,8],
[88,15,11,0],
[88,15,11,1],
[88,15,11,2],
[88,15,11,3],
[88,15,11,4],
[88,15,11,5],
[88,15,11,6],
[88,15,11,7],
[88,15,11,8],
[88,15,11,10],
[88,15,12,0],
[88,15,12,1],
[88,15,12,2],
[88,15,12,3],
[88,15,12,4],
[88,15,12,5],
[88,15,12,6],
[88,15,12,7],
[88,15,12,8],
[88,15,12,10],
[88,15,13,0],
[88,15,13,1],
[88,15,13,2],
[88,15,13,3],
[88,15,13,4],
[88,15,13,5],
[88,15,13,6],
[88,15,13,7],
[88,15,13,8],
[88,15,13,10],
[88,15,13,11],
[88,15,13,12],
[88,15,14,0],
[88,15,14,1],
[88,15,14,2],
[88,15,14,3],
[88,15,14,4],
[88,15,14,5],
[88,15,14,6],
[88,15,14,7],
[88,15,14,8],
[88,15,14,10],
[88,15,14,11],
[88,15,14,12],
[88,16,3,0],
[88,16,3,1],
[88,16,3,2],
[88,16,4,0],
[88,16,4,1],
[88,16,4,2],
[88,16,4,3],
[88,16,5,0],
[88,16,5,1],
[88,16,5,2],
[88,16,5,4],
[88,16,6,0],
[88,16,6,1],
[88,16,6,2],
[88,16,6,4],
[88,16,7,0],
[88,16,7,1],
[88,16,7,2],
[88,16,7,3],
[88,16,7,4],
[88,16,7,5],
[88,16,7,6],
[88,16,8,0],
[88,16,8,1],
[88,16,8,2],
[88,16,8,4],
[88,16,8,7],
[88,16,9,0],
[88,16,9,1],
[88,16,9,2],
[88,16,9,3],
[88,16,9,4],
[88,16,9,5],
[88,16,9,6],
[88,16,9,7],
[88,16,9,8],
[88,16,10,0],
[88,16,10,1],
[88,16,10,2],
[88,16,10,3],
[88,16,10,4],
[88,16,10,5],
[88,16,10,6],
[88,16,10,7],
[88,16,10,8],
[88,16,10,9],
[88,16,11,0],
[88,16,11,1],
[88,16,11,2],
[88,16,11,3],
[88,16,11,4],
[88,16,11,5],
[88,16,11,6],
[88,16,11,7],
[88,16,11,8],
[88,16,11,9],
[88,16,11,10],
[88,16,12,0],
[88,16,12,1],
[88,16,12,2],
[88,16,12,3],
[88,16,12,4],
[88,16,12,5],
[88,16,12,6],
[88,16,12,7],
[88,16,12,8],
[88,16,12,9],
[88,16,12,10],
[88,16,13,0],
[88,16,13,1],
[88,16,13,2],
[88,16,13,3],
[88,16,13,4],
[88,16,13,5],
[88,16,13,6],
[88,16,13,7],
[88,16,13,8],
[88,16,13,9],
[88,16,13,10],
[88,16,13,11],
[88,16,13,12],
[88,16,14,0],
[88,16,14,1],
[88,16,14,2],
[88,16,14,3],
[88,16,14,4],
[88,16,14,5],
[88,16,14,6],
[88,16,14,7],
[88,16,14,8],
[88,16,14,9],
[88,16,14,10],
[88,16,14,11],
[88,16,14,12],
[88,16,15,0],
[88,16,15,1],
[88,16,15,2],
[88,16,15,3],
[88,16,15,4],
[88,16,15,5],
[88,16,15,6],
[88,16,15,7],
[88,16,15,8],
[88,16,15,10],
[88,16,15,11],
[88,16,15,12],
[88,16,15,13],
[88,16,15,14],
[88,17,3,0],
[88,17,3,1],
[88,17,3,2],
[88,17,4,0],
[88,17,4,1],
[88,17,4,2],
[88,17,4,3],
[88,17,5,0],
[88,17,5,1],
[88,17,5,2],
[88,17,5,4],
[88,17,6,0],
[88,17,6,1],
[88,17,6,2],
[88,17,6,4],
[88,17,7,0],
[88,17,7,1],
[88,17,7,2],
[88,17,7,3],
[88,17,7,4],
[88,17,7,5],
[88,17,7,6],
[88,17,8,0],
[88,17,8,1],
[88,17,8,2],
[88,17,8,4],
[88,17,8,7],
[88,17,9,0],
[88,17,9,1],
[88,17,9,2],
[88,17,9,3],
[88,17,9,4],
[88,17,9,5],
[88,17,9,6],
[88,17,9,7],
[88,17,9,8],
[88,17,10,0],
[88,17,10,1],
[88,17,10,2],
[88,17,10,3],
[88,17,10,4],
[88,17,10,5],
[88,17,10,6],
[88,17,10,7],
[88,17,10,8],
[88,17,10,9],
[88,17,11,0],
[88,17,11,1],
[88,17,11,2],
[88,17,11,3],
[88,17,11,4],
[88,17,11,5],
[88,17,11,6],
[88,17,11,7],
[88,17,11,8],
[88,17,11,9],
[88,17,11,10],
[88,17,12,0],
[88,17,12,1],
[88,17,12,2],
[88,17,12,3],
[88,17,12,4],
[88,17,12,5],
[88,17,12,6],
[88,17,12,7],
[88,17,12,8],
[88,17,12,9],
[88,17,12,10],
[88,17,13,0],
[88,17,13,1],
[88,17,13,2],
[88,17,13,3],
[88,17,13,4],
[88,17,13,5],
[88,17,13,6],
[88,17,13,7],
[88,17,13,8],
[88,17,13,9],
[88,17,13,10],
[88,17,13,11],
[88,17,13,12],
[88,17,14,0],
[88,17,14,1],
[88,17,14,2],
[88,17,14,3],
[88,17,14,4],
[88,17,14,5],
[88,17,14,6],
[88,17,14,7],
[88,17,14,8],
[88,17,14,9],
[88,17,14,10],
[88,17,14,11],
[88,17,14,12],
[88,17,15,0],
[88,17,15,1],
[88,17,15,2],
[88,17,15,3],
[88,17,15,4],
[88,17,15,5],
[88,17,15,6],
[88,17,15,7],
[88,17,15,8],
[88,17,15,10],
[88,17,15,11],
[88,17,15,12],
[88,17,15,13],
[88,17,15,14],
[88,17,16,0],
[88,17,16,1],
[88,17,16,2],
[88,17,16,3],
[88,17,16,4],
[88,17,16,5],
[88,17,16,6],
[88,17,16,7],
[88,17,16,8],
[88,17,16,9],
[88,17,16,10],
[88,17,16,11],
[88,17,16,12],
[88,17,16,13],
[88,17,16,14],
[88,17,16,15],
[88,18,3,0],
[88,18,3,1],
[88,18,3,2],
[88,18,4,0],
[88,18,4,1],
[88,18,4,2],
[88,18,4,3],
[88,18,5,0],
[88,18,5,1],
[88,18,5,2],
[88,18,5,4],
[88,18,6,0],
[88,18,6,1],
[88,18,6,2],
[88,18,6,4],
[88,18,7,0],
[88,18,7,1],
[88,18,7,2],
[88,18,7,3],
[88,18,7,4],
[88,18,7,5],
[88,18,7,6],
[88,18,8,0],
[88,18,8,1],
[88,18,8,2],
[88,18,8,4],
[88,18,8,7],
[88,18,10,0],
[88,18,10,1],
[88,18,10,2],
[88,18,10,3],
[88,18,10,4],
[88,18,10,5],
[88,18,10,6],
[88,18,10,7],
[88,18,10,8],
[88,18,11,0],
[88,18,11,1],
[88,18,11,2],
[88,18,11,3],
[88,18,11,4],
[88,18,11,5],
[88,18,11,6],
[88,18,11,7],
[88,18,11,8],
[88,18,11,10],
[88,18,12,0],
[88,18,12,1],
[88,18,12,2],
[88,18,12,3],
[88,18,12,4],
[88,18,12,5],
[88,18,12,6],
[88,18,12,7],
[88,18,12,8],
[88,18,12,10],
[88,18,13,0],
[88,18,13,1],
[88,18,13,2],
[88,18,13,3],
[88,18,13,4],
[88,18,13,5],
[88,18,13,6],
[88,18,13,7],
[88,18,13,8],
[88,18,13,10],
[88,18,13,11],
[88,18,13,12],
[88,18,14,0],
[88,18,14,1],
[88,18,14,2],
[88,18,14,3],
[88,18,14,4],
[88,18,14,5],
[88,18,14,6],
[88,18,14,7],
[88,18,14,8],
[88,18,14,10],
[88,18,14,11],
[88,18,14,12],
[88,18,16,0],
[88,18,16,1],
[88,18,16,2],
[88,18,16,3],
[88,18,16,4],
[88,18,16,5],
[88,18,16,6],
[88,18,16,7],
[88,18,16,8],
[88,18,16,10],
[88,18,16,11],
[88,18,16,12],
[88,18,16,13],
[88,18,16,14],
[88,18,17,0],
[88,18,17,1],
[88,18,17,2],
[88,18,17,3],
[88,18,17,4],
[88,18,17,5],
[88,18,17,6],
[88,18,17,7],
[88,18,17,8],
[88,18,17,10],
[88,18,17,11],
[88,18,17,12],
[88,18,17,13],
[88,18,17,14],
[88,18,17,16],
[88,19,3,0],
[88,19,3,1],
[88,19,3,2],
[88,19,4,0],
[88,19,4,1],
[88,19,4,2],
[88,19,4,3],
[88,19,5,0],
[88,19,5,1],
[88,19,5,2],
[88,19,5,4],
[88,19,6,0],
[88,19,6,1],
[88,19,6,2],
[88,19,6,4],
[88,19,7,0],
[88,19,7,1],
[88,19,7,2],
[88,19,7,3],
[88,19,7,4],
[88,19,7,5],
[88,19,7,6],
[88,19,8,0],
[88,19,8,1],
[88,19,8,2],
[88,19,8,4],
[88,19,8,7],
[88,19,9,0],
[88,19,9,1],
[88,19,9,2],
[88,19,9,3],
[88,19,9,4],
[88,19,9,5],
[88,19,9,6],
[88,19,9,7],
[88,19,9,8],
[88,19,10,0],
[88,19,10,1],
[88,19,10,2],
[88,19,10,3],
[88,19,10,4],
[88,19,10,5],
[88,19,10,6],
[88,19,10,7],
[88,19,10,8],
[88,19,10,9],
[88,19,11,0],
[88,19,11,1],
[88,19,11,2],
[88,19,11,3],
[88,19,11,4],
[88,19,11,5],
[88,19,11,6],
[88,19,11,7],
[88,19,11,8],
[88,19,11,9],
[88,19,11,10],
[88,19,12,0],
[88,19,12,1],
[88,19,12,2],
[88,19,12,3],
[88,19,12,4],
[88,19,12,5],
[88,19,12,6],
[88,19,12,7],
[88,19,12,8],
[88,19,12,9],
[88,19,12,10],
[88,19,15,0],
[88,19,15,1],
[88,19,15,2],
[88,19,15,3],
[88,19,15,4],
[88,19,15,5],
[88,19,15,6],
[88,19,15,7],
[88,19,15,8],
[88,19,15,10],
[88,19,15,11],
[88,19,15,12],
[88,19,16,0],
[88,19,16,1],
[88,19,16,2],
[88,19,16,3],
[88,19,16,4],
[88,19,16,5],
[88,19,16,6],
[88,19,16,7],
[88,19,16,8],
[88,19,16,9],
[88,19,16,10],
[88,19,16,11],
[88,19,16,12],
[88,19,16,15],
[88,19,17,0],
[88,19,17,1],
[88,19,17,2],
[88,19,17,3],
[88,19,17,4],
[88,19,17,5],
[88,19,17,6],
[88,19,17,7],
[88,19,17,8],
[88,19,17,9],
[88,19,17,10],
[88,19,17,11],
[88,19,17,12],
[88,19,17,15],
[88,19,17,16],
[88,19,18,0],
[88,19,18,1],
[88,19,18,2],
[88,19,18,3],
[88,19,18,4],
[88,19,18,5],
[88,19,18,6],
[88,19,18,7],
[88,19,18,8],
[88,19,18,10],
[88,19,18,11],
[88,19,18,16],
[88,19,18,17],
[88,20,3,0],
[88,20,3,1],
[88,20,3,2],
[88,20,4,0],
[88,20,4,1],
[88,20,4,2],
[88,20,4,3],
[88,20,5,0],
[88,20,5,1],
[88,20,5,2],
[88,20,5,4],
[88,20,6,0],
[88,20,6,1],
[88,20,6,2],
[88,20,6,4],
[88,20,7,0],
[88,20,7,1],
[88,20,7,2],
[88,20,7,3],
[88,20,7,4],
[88,20,7,5],
[88,20,7,6],
[88,20,8,0],
[88,20,8,1],
[88,20,8,2],
[88,20,8,4],
[88,20,8,7],
[88,20,9,0],
[88,20,9,1],
[88,20,9,2],
[88,20,9,3],
[88,20,9,4],
[88,20,9,5],
[88,20,9,6],
[88,20,9,7],
[88,20,9,8],
[88,20,11,0],
[88,20,11,1],
[88,20,11,2],
[88,20,11,3],
[88,20,11,4],
[88,20,11,5],
[88,20,11,6],
[88,20,11,7],
[88,20,11,8],
[88,20,11,9],
[88,20,12,0],
[88,20,12,1],
[88,20,12,2],
[88,20,12,3],
[88,20,12,4],
[88,20,12,5],
[88,20,12,6],
[88,20,12,7],
[88,20,12,8],
[88,20,12,9],
[88,20,13,0],
[88,20,13,1],
[88,20,13,2],
[88,20,13,3],
[88,20,13,4],
[88,20,13,5],
[88,20,13,6],
[88,20,13,7],
[88,20,13,8],
[88,20,13,9],
[88,20,13,11],
[88,20,13,12],
[88,20,14,0],
[88,20,14,1],
[88,20,14,2],
[88,20,14,3],
[88,20,14,4],
[88,20,14,5],
[88,20,14,6],
[88,20,14,7],
[88,20,14,8],
[88,20,14,9],
[88,20,14,11],
[88,20,14,12],
[88,20,15,0],
[88,20,15,1],
[88,20,15,2],
[88,20,15,3],
[88,20,15,4],
[88,20,15,5],
[88,20,15,6],
[88,20,15,7],
[88,20,15,8],
[88,20,15,11],
[88,20,15,12],
[88,20,15,13],
[88,20,16,0],
[88,20,16,1],
[88,20,16,2],
[88,20,16,3],
[88,20,16,4],
[88,20,16,5],
[88,20,16,6],
[88,20,16,7],
[88,20,16,8],
[88,20,16,9],
[88,20,16,11],
[88,20,16,14],
[88,20,16,15],
[88,20,17,0],
[88,20,17,1],
[88,20,17,2],
[88,20,17,3],
[88,20,17,4],
[88,20,17,5],
[88,20,17,6],
[88,20,17,7],
[88,20,17,8],
[88,20,17,9],
[88,20,17,11],
[88,20,17,14],
[88,20,17,15],
[88,20,17,16],
[88,20,18,0],
[88,20,18,1],
[88,20,18,2],
[88,20,18,3],
[88,20,18,4],
[88,20,18,5],
[88,20,18,6],
[88,20,18,7],
[88,20,18,8],
[88,20,18,12],
[88,20,18,13],
[88,20,18,14],
[88,20,18,16],
[88,20,18,17],
[88,20,19,0],
[88,20,19,1],
[88,20,19,2],
[88,20,19,3],
[88,20,19,4],
[88,20,19,5],
[88,20,19,6],
[88,20,19,7],
[88,20,19,11],
[88,20,19,12],
[88,20,19,15],
[88,20,19,16],
[88,20,19,17],
[88,20,19,18],
[88,21,4,0],
[88,21,4,1],
[88,21,4,2],
[88,21,7,0],
[88,21,7,1],
[88,21,7,2],
[88,21,7,4],
[88,21,9,0],
[88,21,9,1],
[88,21,9,2],
[88,21,9,4],
[88,21,9,7],
[88,21,10,0],
[88,21,10,1],
[88,21,10,2],
[88,21,10,4],
[88,21,10,7],
[88,21,10,9],
[88,21,11,0],
[88,21,11,1],
[88,21,11,2],
[88,21,11,4],
[88,21,11,7],
[88,21,11,9],
[88,21,11,10],
[88,21,12,0],
[88,21,12,1],
[88,21,12,2],
[88,21,12,4],
[88,21,12,7],
[88,21,12,9],
[88,21,12,10],
[88,21,13,0],
[88,21,13,1],
[88,21,13,2],
[88,21,13,4],
[88,21,13,7],
[88,21,13,9],
[88,21,13,10],
[88,21,13,11],
[88,21,13,12],
[88,21,14,0],
[88,21,14,1],
[88,21,14,2],
[88,21,14,4],
[88,21,14,7],
[88,21,14,9],
[88,21,14,10],
[88,21,14,11],
[88,21,14,12],
[88,21,15,0],
[88,21,15,1],
[88,21,15,2],
[88,21,15,4],
[88,21,15,7],
[88,21,15,10],
[88,21,15,11],
[88,21,15,14],
[88,21,16,0],
[88,21,16,1],
[88,21,16,2],
[88,21,16,4],
[88,21,16,7],
[88,21,16,9],
[88,21,16,12],
[88,21,16,13],
[88,21,16,14],
[88,21,16,15],
[88,21,17,0],
[88,21,17,1],
[88,21,17,2],
[88,21,17,4],
[88,21,17,7],
[88,21,17,9],
[88,21,17,12],
[88,21,17,13],
[88,21,17,14],
[88,21,17,15],
[88,21,17,16],
[88,21,18,0],
[88,21,18,1],
[88,21,18,2],
[88,21,18,4],
[88,21,18,7],
[88,21,18,10],
[88,21,18,11],
[88,21,18,12],
[88,21,18,13],
[88,21,18,14],
[88,21,18,16],
[88,21,18,17],
[88,21,19,0],
[88,21,19,1],
[88,21,19,2],
[88,21,19,4],
[88,21,19,9],
[88,21,19,10],
[88,21,19,11],
[88,21,19,12],
[88,21,19,15],
[88,21,19,16],
[88,21,19,17],
[88,21,19,18],
[88,21,20,0],
[88,21,20,1],
[88,21,20,2],
[88,21,20,4],
[88,21,20,7],
[88,21,20,9],
[88,21,20,11],
[88,21,20,12],
[88,21,20,13],
[88,21,20,14],
[88,21,20,15],
[88,21,20,16],
[88,21,20,17],
[88,21,20,18],
[88,21,20,19],
[88,22,3,0],
[88,22,3,1],
[88,22,3,2],
[88,22,4,0],
[88,22,4,1],
[88,22,4,2],
[88,22,4,3],
[88,22,5,0],
[88,22,5,1],
[88,22,5,2],
[88,22,5,4],
[88,22,6,0],
[88,22,6,1],
[88,22,6,2],
[88,22,6,4],
[88,22,7,0],
[88,22,7,1],
[88,22,7,2],
[88,22,7,3],
[88,22,7,4],
[88,22,7,5],
[88,22,7,6],
[88,22,8,0],
[88,22,8,1],
[88,22,8,2],
[88,22,8,4],
[88,22,8,7],
[88,22,9,0],
[88,22,9,1],
[88,22,9,2],
[88,22,9,3],
[88,22,9,4],
[88,22,9,5],
[88,22,9,6],
[88,22,9,7],
[88,22,9,8],
[88,22,11,0],
[88,22,11,1],
[88,22,11,2],
[88,22,11,3],
[88,22,11,4],
[88,22,11,5],
[88,22,11,6],
[88,22,11,7],
[88,22,11,8],
[88,22,11,9],
[88,22,12,0],
[88,22,12,1],
[88,22,12,2],
[88,22,12,3],
[88,22,12,4],
[88,22,12,5],
[88,22,12,6],
[88,22,12,7],
[88,22,12,8],
[88,22,12,9],
[88,22,13,0],
[88,22,13,1],
[88,22,13,2],
[88,22,13,3],
[88,22,13,4],
[88,22,13,5],
[88,22,13,6],
[88,22,13,7],
[88,22,13,8],
[88,22,13,9],
[88,22,13,11],
[88,22,13,12],
[88,22,14,0],
[88,22,14,1],
[88,22,14,2],
[88,22,14,3],
[88,22,14,4],
[88,22,14,5],
[88,22,14,6],
[88,22,14,7],
[88,22,14,8],
[88,22,14,9],
[88,22,14,11],
[88,22,15,0],
[88,22,15,1],
[88,22,15,2],
[88,22,15,3],
[88,22,15,4],
[88,22,15,5],
[88,22,15,6],
[88,22,15,7],
[88,22,15,8],
[88,22,15,12],
[88,22,15,13],
[88,22,15,14],
[88,22,16,0],
[88,22,16,1],
[88,22,16,2],
[88,22,16,3],
[88,22,16,4],
[88,22,16,5],
[88,22,16,6],
[88,22,16,7],
[88,22,16,11],
[88,22,16,12],
[88,22,16,13],
[88,22,16,14],
[88,22,16,15],
[88,22,17,0],
[88,22,17,1],
[88,22,17,2],
[88,22,17,3],
[88,22,17,4],
[88,22,17,5],
[88,22,17,6],
[88,22,17,7],
[88,22,17,11],
[88,22,17,12],
[88,22,17,13],
[88,22,17,14],
[88,22,17,15],
[88,22,17,16],
[88,22,18,0],
[88,22,18,1],
[88,22,18,2],
[88,22,18,3],
[88,22,18,4],
[88,22,18,5],
[88,22,18,8],
[88,22,18,11],
[88,22,18,12],
[88,22,18,13],
[88,22,18,14],
[88,22,18,16],
[88,22,18,17],
[88,22,19,0],
[88,22,19,1],
[88,22,19,2],
[88,22,19,3],
[88,22,19,4],
[88,22,19,6],
[88,22,19,7],
[88,22,19,8],
[88,22,19,9],
[88,22,19,11],
[88,22,19,12],
[88,22,19,15],
[88,22,19,16],
[88,22,19,17],
[88,22,19,18],
[88,22,21,4],
[88,22,21,7],
[88,22,21,9],
[88,22,21,11],
[88,22,21,12],
[88,22,21,13],
[88,22,21,14],
[88,22,21,15],
[88,22,21,16],
[88,22,21,17],
[88,22,21,18],
[88,22,21,19],
[88,24,3,0],
[88,24,3,1],
[88,24,3,2],
[88,24,4,0],
[88,24,4,1],
[88,24,4,2],
[88,24,4,3],
[88,24,5,0],
[88,24,5,1],
[88,24,5,2],
[88,24,5,4],
[88,24,6,0],
[88,24,6,1],
[88,24,6,2],
[88,24,6,4],
[88,24,7,0],
[88,24,7,1],
[88,24,7,2],
[88,24,7,3],
[88,24,7,4],
[88,24,7,5],
[88,24,7,6],
[88,24,8,0],
[88,24,8,1],
[88,24,8,2],
[88,24,8,4],
[88,24,8,7],
[88,24,9,0],
[88,24,9,1],
[88,24,9,2],
[88,24,9,3],
[88,24,9,4],
[88,24,9,5],
[88,24,9,6],
[88,24,9,7],
[88,24,9,8],
[88,24,10,0],
[88,24,10,1],
[88,24,10,2],
[88,24,10,3],
[88,24,10,4],
[88,24,10,5],
[88,24,10,6],
[88,24,10,7],
[88,24,10,8],
[88,24,10,9],
[88,24,11,0],
[88,24,11,1],
[88,24,11,2],
[88,24,11,3],
[88,24,11,4],
[88,24,11,5],
[88,24,11,6],
[88,24,11,7],
[88,24,11,8],
[88,24,11,9],
[88,24,11,10],
[88,24,12,0],
[88,24,12,1],
[88,24,12,2],
[88,24,12,3],
[88,24,12,4],
[88,24,12,5],
[88,24,12,6],
[88,24,12,7],
[88,24,12,8],
[88,24,12,9],
[88,24,12,10],
[88,24,13,0],
[88,24,13,1],
[88,24,13,2],
[88,24,13,3],
[88,24,13,4],
[88,24,13,5],
[88,24,13,6],
[88,24,13,7],
[88,24,13,8],
[88,24,13,9],
[88,24,13,10],
[88,24,13,11],
[88,24,14,0],
[88,24,14,1],
[88,24,14,2],
[88,24,14,3],
[88,24,14,4],
[88,24,14,5],
[88,24,14,6],
[88,24,14,7],
[88,24,14,8],
[88,24,14,9],
[88,24,14,12],
[88,24,15,0],
[88,24,15,1],
[88,24,15,2],
[88,24,15,3],
[88,24,15,4],
[88,24,15,5],
[88,24,15,6],
[88,24,15,7],
[88,24,15,10],
[88,24,15,11],
[88,24,15,12],
[88,24,15,13],
[88,24,15,14],
[88,24,17,0],
[88,24,17,1],
[88,24,17,2],
[88,24,17,3],
[88,24,17,4],
[88,24,17,5],
[88,24,17,8],
[88,24,17,9],
[88,24,17,10],
[88,24,17,11],
[88,24,17,12],
[88,24,17,13],
[88,24,17,14],
[88,24,17,15],
[88,24,18,0],
[88,24,18,1],
[88,24,18,2],
[88,24,18,3],
[88,24,18,4],
[88,24,18,6],
[88,24,18,7],
[88,24,18,8],
[88,24,18,10],
[88,24,18,11],
[88,24,18,12],
[88,24,18,13],
[88,24,18,14],
[88,24,18,17],
[88,24,19,0],
[88,24,19,1],
[88,24,19,2],
[88,24,19,5],
[88,24,19,6],
[88,24,19,7],
[88,24,19,8],
[88,24,19,9],
[88,24,19,10],
[88,24,19,11],
[88,24,19,12],
[88,24,19,15],
[88,24,19,17],
[88,24,19,18],
[88,24,20,3],
[88,24,20,4],
[88,24,20,5],
[88,24,20,6],
[88,24,20,7],
[88,24,20,8],
[88,24,20,9],
[88,24,20,11],
[88,24,20,12],
[88,24,20,13],
[88,24,20,14],
[88,24,20,15],
[88,24,20,17],
[88,24,20,18],
[88,24,20,19],
[88,24,21,0],
[88,24,21,1],
[88,24,21,2],
[88,24,21,4],
[88,24,21,7],
[88,24,21,9],
[88,24,21,10],
[88,24,21,11],
[88,24,21,12],
[88,24,21,13],
[88,24,21,14],
[88,24,21,15],
[88,24,21,17],
[88,24,21,18],
[88,24,21,19],
[88,24,22,0],
[88,24,22,1],
[88,24,22,2],
[88,24,22,3],
[88,24,22,4],
[88,24,22,5],
[88,24,22,6],
[88,24,22,7],
[88,24,22,8],
[88,24,22,9],
[88,24,22,11],
[88,24,22,12],
[88,24,22,13],
[88,24,22,14],
[88,24,22,15],
[88,24,22,17],
[88,24,22,18],
[88,25,3,0],
[88,25,3,1],
[88,25,3,2],
[88,25,4,0],
[88,25,4,1],
[88,25,4,2],
[88,25,4,3],
[88,25,5,0],
[88,25,5,1],
[88,25,5,2],
[88,25,5,4],
[88,25,6,0],
[88,25,6,1],
[88,25,6,2],
[88,25,6,4],
[88,25,8,0],
[88,25,8,1],
[88,25,8,2],
[88,25,8,4],
[88,25,9,0],
[88,25,9,1],
[88,25,9,2],
[88,25,9,3],
[88,25,9,4],
[88,25,9,5],
[88,25,9,6],
[88,25,9,8],
[88,25,10,0],
[88,25,10,1],
[88,25,10,2],
[88,25,10,3],
[88,25,10,4],
[88,25,10,5],
[88,25,10,6],
[88,25,10,8],
[88,25,10,9],
[88,25,11,0],
[88,25,11,1],
[88,25,11,2],
[88,25,11,3],
[88,25,11,4],
[88,25,11,5],
[88,25,11,6],
[88,25,11,8],
[88,25,11,9],
[88,25,12,0],
[88,25,12,1],
[88,25,12,2],
[88,25,12,3],
[88,25,12,4],
[88,25,12,5],
[88,25,12,6],
[88,25,12,10],
[88,25,13,0],
[88,25,13,1],
[88,25,13,2],
[88,25,13,3],
[88,25,13,4],
[88,25,13,5],
[88,25,13,6],
[88,25,13,10],
[88,25,13,11],
[88,25,13,12],
[88,25,14,0],
[88,25,14,1],
[88,25,14,2],
[88,25,14,3],
[88,25,14,4],
[88,25,14,5],
[88,25,14,8],
[88,25,14,9],
[88,25,14,10],
[88,25,14,11],
[88,25,14,12],
[88,25,15,0],
[88,25,15,1],
[88,25,15,2],
[88,25,15,3],
[88,25,15,4],
[88,25,15,6],
[88,25,15,8],
[88,25,15,10],
[88,25,15,11],
[88,25,15,12],
[88,25,15,13],
[88,25,15,14],
[88,25,16,0],
[88,25,16,1],
[88,25,16,2],
[88,25,16,5],
[88,25,16,6],
[88,25,16,8],
[88,25,16,9],
[88,25,16,10],
[88,25,16,11],
[88,25,16,12],
[88,25,16,13],
[88,25,16,14],
[88,25,16,15],
[88,25,17,0],
[88,25,17,1],
[88,25,17,2],
[88,25,17,5],
[88,25,17,6],
[88,25,17,8],
[88,25,17,9],
[88,25,17,10],
[88,25,17,11],
[88,25,17,12],
[88,25,17,13],
[88,25,17,14],
[88,25,17,15],
[88,25,17,16],
[88,25,18,3],
[88,25,18,4],
[88,25,18,5],
[88,25,18,6],
[88,25,18,8],
[88,25,18,10],
[88,25,18,11],
[88,25,18,12],
[88,25,18,13],
[88,25,18,14],
[88,25,18,16],
[88,25,18,17],
[88,25,19,0],
[88,25,19,1],
[88,25,19,2],
[88,25,19,3],
[88,25,19,4],
[88,25,19,5],
[88,25,19,6],
[88,25,19,8],
[88,25,19,9],
[88,25,19,10],
[88,25,19,11],
[88,25,19,12],
[88,25,19,15],
[88,25,19,16],
[88,25,19,17],
[88,25,19,18],
[88,25,20,0],
[88,25,20,1],
[88,25,20,2],
[88,25,20,3],
[88,25,20,4],
[88,25,20,5],
[88,25,20,6],
[88,25,20,8],
[88,25,20,9],
[88,25,20,11],
[88,25,20,12],
[88,25,20,13],
[88,25,20,14],
[88,25,20,15],
[88,25,20,16],
[88,25,20,17],
[88,25,20,18],
[88,25,21,0],
[88,25,21,1],
[88,25,21,2],
[88,25,21,4],
[88,25,21,9],
[88,25,21,10],
[88,25,21,11],
[88,25,21,12],
[88,25,21,13],
[88,25,21,14],
[88,25,21,15],
[88,25,21,16],
[88,25,21,17],
[88,25,22,0],
[88,25,22,1],
[88,25,22,2],
[88,25,22,3],
[88,25,22,4],
[88,25,22,5],
[88,25,22,6],
[88,25,22,8],
[88,25,22,9],
[88,25,22,11],
[88,25,22,12],
[88,25,22,13],
[88,25,22,14],
[88,25,22,15],
[88,25,24,0],
[88,25,24,1],
[88,25,24,2],
[88,25,24,3],
[88,25,24,4],
[88,25,24,5],
[88,25,24,6],
[88,25,24,8],
[88,25,24,9],
[88,25,24,10],
[88,25,24,11],
[88,25,24,12],
[88,25,24,13],
[88,25,24,14],
[88,26,3,0],
[88,26,3,1],
[88,26,3,2],
[88,26,4,0],
[88,26,4,1],
[88,26,4,2],
[88,26,4,3],
[88,26,5,0],
[88,26,5,1],
[88,26,5,2],
[88,26,5,4],
[88,26,6,0],
[88,26,6,1],
[88,26,6,2],
[88,26,6,4],
[88,26,7,0],
[88,26,7,1],
[88,26,7,2],
[88,26,7,3],
[88,26,7,4],
[88,26,7,5],
[88,26,7,6],
[88,26,8,0],
[88,26,8,1],
[88,26,8,2],
[88,26,8,4],
[88,26,8,7],
[88,26,10,0],
[88,26,10,1],
[88,26,10,2],
[88,26,10,3],
[88,26,10,4],
[88,26,10,5],
[88,26,10,6],
[88,26,10,7],
[88,26,11,0],
[88,26,11,1],
[88,26,11,2],
[88,26,11,3],
[88,26,11,4],
[88,26,11,5],
[88,26,11,6],
[88,26,11,7],
[88,26,11,10],
[88,26,12,0],
[88,26,12,1],
[88,26,12,2],
[88,26,12,3],
[88,26,12,4],
[88,26,12,5],
[88,26,12,8],
[88,26,12,10],
[88,26,13,0],
[88,26,13,1],
[88,26,13,2],
[88,26,13,3],
[88,26,13,4],
[88,26,13,5],
[88,26,13,8],
[88,26,13,10],
[88,26,13,11],
[88,26,13,12],
[88,26,14,0],
[88,26,14,1],
[88,26,14,2],
[88,26,14,3],
[88,26,14,4],
[88,26,14,6],
[88,26,14,7],
[88,26,14,8],
[88,26,14,10],
[88,26,14,11],
[88,26,14,12],
[88,26,16,3],
[88,26,16,4],
[88,26,16,5],
[88,26,16,6],
[88,26,16,7],
[88,26,16,8],
[88,26,16,10],
[88,26,16,11],
[88,26,16,12],
[88,26,16,13],
[88,26,16,14],
[88,26,17,3],
[88,26,17,4],
[88,26,17,5],
[88,26,17,6],
[88,26,17,7],
[88,26,17,8],
[88,26,17,10],
[88,26,17,11],
[88,26,17,12],
[88,26,17,13],
[88,26,17,14],
[88,26,17,16],
[88,26,19,0],
[88,26,19,1],
[88,26,19,2],
[88,26,19,3],
[88,26,19,4],
[88,26,19,5],
[88,26,19,6],
[88,26,19,7],
[88,26,19,8],
[88,26,19,10],
[88,26,19,11],
[88,26,19,12],
[88,26,19,16],
[88,26,19,17],
[88,26,20,0],
[88,26,20,1],
[88,26,20,2],
[88,26,20,3],
[88,26,20,4],
[88,26,20,5],
[88,26,20,6],
[88,26,20,7],
[88,26,20,8],
[88,26,20,11],
[88,26,20,12],
[88,26,20,13],
[88,26,20,14],
[88,26,20,16],
[88,26,20,17],
[88,26,21,0],
[88,26,21,1],
[88,26,21,2],
[88,26,21,4],
[88,26,21,7],
[88,26,21,10],
[88,26,21,11],
[88,26,21,12],
[88,26,21,13],
[88,26,21,14],
[88,26,22,0],
[88,26,22,1],
[88,26,22,2],
[88,26,22,3],
[88,26,22,4],
[88,26,22,5],
[88,26,22,6],
[88,26,22,7],
[88,26,22,8],
[88,26,22,11],
[88,26,22,12],
[88,26,22,13],
[88,26,22,14],
[88,26,24,0],
[88,26,24,1],
[88,26,24,2],
[88,26,24,3],
[88,26,24,4],
[88,26,24,5],
[88,26,24,6],
[88,26,24,7],
[88,26,24,8],
[88,26,24,10],
[88,26,24,11],
[88,26,24,12],
[88,26,24,13],
[88,26,25,0],
[88,26,25,1],
[88,26,25,2],
[88,26,25,3],
[88,26,25,4],
[88,26,25,5],
[88,26,25,6],
[88,26,25,8],
[88,27,3,0],
[88,27,3,1],
[88,27,3,2],
[88,27,4,0],
[88,27,4,1],
[88,27,4,2],
[88,27,4,3],
[88,27,5,0],
[88,27,5,1],
[88,27,5,2],
[88,27,5,4],
[88,27,6,0],
[88,27,6,1],
[88,27,6,2],
[88,27,6,4],
[88,27,7,0],
[88,27,7,1],
[88,27,7,2],
[88,27,7,3],
[88,27,7,4],
[88,27,7,5],
[88,27,7,6],
[88,27,8,0],
[88,27,8,1],
[88,27,8,2],
[88,27,8,4],
[88,27,8,7],
[88,27,9,0],
[88,27,9,1],
[88,27,9,2],
[88,27,9,3],
[88,27,9,4],
[88,27,9,5],
[88,27,9,6],
[88,27,9,7],
[88,27,11,0],
[88,27,11,1],
[88,27,11,2],
[88,27,11,3],
[88,27,11,4],
[88,27,11,5],
[88,27,11,8],
[88,27,11,9],
[88,27,12,0],
[88,27,12,1],
[88,27,12,2],
[88,27,12,3],
[88,27,12,4],
[88,27,12,6],
[88,27,12,7],
[88,27,12,8],
[88,27,12,9],
[88,27,13,0],
[88,27,13,1],
[88,27,13,2],
[88,27,13,3],
[88,27,13,4],
[88,27,13,6],
[88,27,13,7],
[88,27,13,8],
[88,27,13,9],
[88,27,13,11],
[88,27,13,12],
[88,27,14,0],
[88,27,14,1],
[88,27,14,2],
[88,27,14,5],
[88,27,14,6],
[88,27,14,7],
[88,27,14,8],
[88,27,14,9],
[88,27,14,11],
[88,27,14,12],
[88,27,15,3],
[88,27,15,4],
[88,27,15,5],
[88,27,15,6],
[88,27,15,7],
[88,27,15,8],
[88,27,15,11],
[88,27,15,12],
[88,27,15,13],
[88,27,15,14],
[88,27,16,0],
[88,27,16,1],
[88,27,16,2],
[88,27,16,3],
[88,27,16,4],
[88,27,16,5],
[88,27,16,6],
[88,27,16,7],
[88,27,16,8],
[88,27,16,9],
[88,27,16,11],
[88,27,16,12],
[88,27,16,13],
[88,27,16,14],
[88,27,16,15],
[88,27,17,0],
[88,27,17,1],
[88,27,17,2],
[88,27,17,3],
[88,27,17,4],
[88,27,17,5],
[88,27,17,6],
[88,27,17,7],
[88,27,17,8],
[88,27,17,9],
[88,27,17,11],
[88,27,17,12],
[88,27,17,13],
[88,27,17,14],
[88,27,17,15],
[88,27,17,16],
[88,27,18,0],
[88,27,18,1],
[88,27,18,2],
[88,27,18,3],
[88,27,18,4],
[88,27,18,5],
[88,27,18,6],
[88,27,18,7],
[88,27,18,8],
[88,27,18,11],
[88,27,18,12],
[88,27,18,13],
[88,27,18,14],
[88,27,18,16],
[88,27,18,17],
[88,27,19,0],
[88,27,19,1],
[88,27,19,2],
[88,27,19,3],
[88,27,19,4],
[88,27,19,5],
[88,27,19,6],
[88,27,19,7],
[88,27,19,8],
[88,27,19,9],
[88,27,19,11],
[88,27,19,12],
[88,27,19,15],
[88,27,19,16],
[88,27,19,17],
[88,27,21,0],
[88,27,21,1],
[88,27,21,2],
[88,27,21,4],
[88,27,21,7],
[88,27,21,9],
[88,27,21,11],
[88,27,21,12],
[88,27,21,13],
[88,27,21,14],
[88,27,24,0],
[88,27,24,1],
[88,27,24,2],
[88,27,24,3],
[88,27,24,4],
[88,27,24,5],
[88,27,24,6],
[88,27,24,7],
[88,27,24,8],
[88,27,24,9],
[88,27,24,11],
[88,27,25,0],
[88,27,25,1],
[88,27,25,2],
[88,27,25,3],
[88,27,25,4],
[88,27,25,5],
[88,27,25,6],
[88,27,26,0],
[88,27,26,1],
[88,27,26,2],
[88,27,26,3],
[88,27,26,4],
[88,27,26,5],
[88,28,3,0],
[88,28,3,1],
[88,28,3,2],
[88,28,4,0],
[88,28,4,1],
[88,28,4,2],
[88,28,4,3],
[88,28,5,0],
[88,28,5,1],
[88,28,5,2],
[88,28,5,4],
[88,28,6,0],
[88,28,6,1],
[88,28,6,2],
[88,28,6,4],
[88,28,8,0],
[88,28,8,1],
[88,28,8,2],
[88,28,8,4],
[88,28,9,0],
[88,28,9,1],
[88,28,9,2],
[88,28,9,3],
[88,28,9,4],
[88,28,9,5],
[88,28,9,8],
[88,28,10,0],
[88,28,10,1],
[88,28,10,2],
[88,28,10,3],
[88,28,10,4],
[88,28,10,6],
[88,28,10,8],
[88,28,10,9],
[88,28,11,0],
[88,28,11,1],
[88,28,11,2],
[88,28,11,3],
[88,28,11,4],
[88,28,11,6],
[88,28,11,8],
[88,28,11,9],
[88,28,11,10],
[88,28,12,0],
[88,28,12,1],
[88,28,12,2],
[88,28,12,5],
[88,28,12,6],
[88,28,12,8],
[88,28,12,9],
[88,28,12,10],
[88,28,13,0],
[88,28,13,1],
[88,28,13,2],
[88,28,13,5],
[88,28,13,6],
[88,28,13,8],
[88,28,13,9],
[88,28,13,10],
[88,28,13,11],
[88,28,13,12],
[88,28,14,3],
[88,28,14,4],
[88,28,14,5],
[88,28,14,6],
[88,28,14,8],
[88,28,14,9],
[88,28,14,10],
[88,28,14,11],
[88,28,14,12],
[88,28,15,0],
[88,28,15,1],
[88,28,15,2],
[88,28,15,3],
[88,28,15,4],
[88,28,15,5],
[88,28,15,6],
[88,28,15,8],
[88,28,15,10],
[88,28,15,11],
[88,28,15,12],
[88,28,15,13],
[88,28,15,14],
[88,28,16,0],
[88,28,16,1],
[88,28,16,2],
[88,28,16,3],
[88,28,16,4],
[88,28,16,5],
[88,28,16,6],
[88,28,16,8],
[88,28,16,9],
[88,28,16,10],
[88,28,16,11],
[88,28,16,12],
[88,28,16,13],
[88,28,16,14],
[88,28,16,15],
[88,28,17,0],
[88,28,17,1],
[88,28,17,2],
[88,28,17,3],
[88,28,17,4],
[88,28,17,5],
[88,28,17,6],
[88,28,17,8],
[88,28,17,9],
[88,28,17,10],
[88,28,17,11],
[88,28,17,12],
[88,28,17,13],
[88,28,17,14],
[88,28,17,15],
[88,28,17,16],
[88,28,18,0],
[88,28,18,1],
[88,28,18,2],
[88,28,18,3],
[88,28,18,4],
[88,28,18,5],
[88,28,18,6],
[88,28,18,8],
[88,28,18,10],
[88,28,18,11],
[88,28,18,12],
[88,28,18,13],
[88,28,18,14],
[88,28,18,16],
[88,28,18,17],
[88,28,19,0],
[88,28,19,1],
[88,28,19,2],
[88,28,19,3],
[88,28,19,4],
[88,28,19,5],
[88,28,19,6],
[88,28,19,8],
[88,28,19,9],
[88,28,19,10],
[88,28,19,11],
[88,28,19,12],
[88,28,19,15],
[88,28,20,0],
[88,28,20,1],
[88,28,20,2],
[88,28,20,3],
[88,28,20,4],
[88,28,20,5],
[88,28,20,6],
[88,28,20,8],
[88,28,20,9],
[88,28,20,11],
[88,28,20,12],
[88,28,20,13],
[88,28,20,14],
[88,28,21,0],
[88,28,21,1],
[88,28,21,2],
[88,28,21,4],
[88,28,21,9],
[88,28,21,10],
[88,28,21,11],
[88,28,21,12],
[88,28,21,13],
[88,28,22,0],
[88,28,22,1],
[88,28,22,2],
[88,28,22,3],
[88,28,22,4],
[88,28,22,5],
[88,28,22,6],
[88,28,22,8],
[88,28,22,9],
[88,28,22,11],
[88,28,24,0],
[88,28,24,1],
[88,28,24,2],
[88,28,24,3],
[88,28,24,4],
[88,28,24,5],
[88,28,24,6],
[88,28,24,8],
[88,28,24,9],
[88,28,26,0],
[88,28,26,1],
[88,28,26,2],
[88,28,26,3],
[88,28,26,4],
[88,28,27,0],
[88,28,27,1],
[88,28,27,2],
[88,29,3,0],
[88,29,3,1],
[88,29,3,2],
[88,29,4,0],
[88,29,4,1],
[88,29,4,2],
[88,29,4,3],
[88,29,5,0],
[88,29,5,1],
[88,29,5,2],
[88,29,5,4],
[88,29,6,0],
[88,29,6,1],
[88,29,6,2],
[88,29,6,4],
[88,29,7,0],
[88,29,7,1],
[88,29,7,2],
[88,29,7,3],
[88,29,7,4],
[88,29,7,5],
[88,29,8,0],
[88,29,8,1],
[88,29,8,2],
[88,29,8,4],
[88,29,8,7],
[88,29,9,0],
[88,29,9,1],
[88,29,9,2],
[88,29,9,3],
[88,29,9,4],
[88,29,9,6],
[88,29,9,7],
[88,29,9,8],
[88,29,10,0],
[88,29,10,1],
[88,29,10,2],
[88,29,10,5],
[88,29,10,6],
[88,29,10,7],
[88,29,10,8],
[88,29,10,9],
[88,29,13,3],
[88,29,13,4],
[88,29,13,5],
[88,29,13,6],
[88,29,13,7],
[88,29,13,8],
[88,29,13,9],
[88,29,13,10],
[88,29,14,0],
[88,29,14,1],
[88,29,14,2],
[88,29,14,3],
[88,29,14,4],
[88,29,14,5],
[88,29,14,6],
[88,29,14,7],
[88,29,14,8],
[88,29,14,9],
[88,29,14,10],
[88,29,15,0],
[88,29,15,1],
[88,29,15,2],
[88,29,15,3],
[88,29,15,4],
[88,29,15,5],
[88,29,15,6],
[88,29,15,7],
[88,29,15,8],
[88,29,15,10],
[88,29,15,13],
[88,29,15,14],
[88,29,16,0],
[88,29,16,1],
[88,29,16,2],
[88,29,16,3],
[88,29,16,4],
[88,29,16,5],
[88,29,16,6],
[88,29,16,7],
[88,29,16,8],
[88,29,16,9],
[88,29,16,10],
[88,29,16,13],
[88,29,16,14],
[88,29,16,15],
[88,29,17,0],
[88,29,17,1],
[88,29,17,2],
[88,29,17,3],
[88,29,17,4],
[88,29,17,5],
[88,29,17,6],
[88,29,17,7],
[88,29,17,8],
[88,29,17,9],
[88,29,17,10],
[88,29,17,13],
[88,29,17,14],
[88,29,17,15],
[88,29,17,16],
[88,29,18,0],
[88,29,18,1],
[88,29,18,2],
[88,29,18,3],
[88,29,18,4],
[88,29,18,5],
[88,29,18,6],
[88,29,18,7],
[88,29,18,8],
[88,29,18,10],
[88,29,18,13],
[88,29,18,14],
[88,29,19,0],
[88,29,19,1],
[88,29,19,2],
[88,29,19,3],
[88,29,19,4],
[88,29,19,5],
[88,29,19,6],
[88,29,19,7],
[88,29,19,8],
[88,29,19,9],
[88,29,19,10],
[88,29,20,0],
[88,29,20,1],
[88,29,20,2],
[88,29,20,3],
[88,29,20,4],
[88,29,20,5],
[88,29,20,6],
[88,29,20,7],
[88,29,20,8],
[88,29,20,9],
[88,29,20,13],
[88,29,21,0],
[88,29,21,1],
[88,29,21,2],
[88,29,21,4],
[88,29,21,7],
[88,29,21,9],
[88,29,21,10],
[88,29,22,0],
[88,29,22,1],
[88,29,22,2],
[88,29,22,3],
[88,29,22,4],
[88,29,22,5],
[88,29,22,6],
[88,29,22,7],
[88,29,22,8],
[88,29,22,9],
[88,29,24,0],
[88,29,24,1],
[88,29,24,2],
[88,29,24,3],
[88,29,24,4],
[88,29,24,5],
[88,29,24,6],
[88,29,24,7],
[88,29,25,0],
[88,29,25,1],
[88,29,25,2],
[88,29,25,3],
[88,29,25,4],
[88,29,26,0],
[88,29,26,1],
[88,29,26,2],
[88,32,3,0],
[88,32,3,1],
[88,32,3,2],
[88,32,4,0],
[88,32,4,1],
[88,32,4,2],
[88,32,4,3],
[88,32,5,0],
[88,32,5,1],
[88,32,5,2],
[88,32,6,4],
[88,32,7,3],
[88,32,7,4],
[88,32,7,5],
[88,32,7,6],
[88,32,8,0],
[88,32,8,1],
[88,32,8,2],
[88,32,8,4],
[88,32,8,7],
[88,32,9,0],
[88,32,9,1],
[88,32,9,2],
[88,32,9,3],
[88,32,9,4],
[88,32,9,5],
[88,32,9,6],
[88,32,9,7],
[88,32,9,8],
[88,32,10,0],
[88,32,10,1],
[88,32,10,2],
[88,32,10,3],
[88,32,10,4],
[88,32,10,5],
[88,32,10,6],
[88,32,10,7],
[88,32,10,8],
[88,32,10,9],
[88,32,13,0],
[88,32,13,1],
[88,32,13,2],
[88,32,13,3],
[88,32,13,4],
[88,32,13,5],
[88,32,13,6],
[88,32,13,7],
[88,32,13,8],
[88,32,13,9],
[88,32,13,10],
[88,32,14,0],
[88,32,14,1],
[88,32,14,2],
[88,32,14,3],
[88,32,14,4],
[88,32,14,5],
[88,32,14,6],
[88,32,14,7],
[88,32,14,8],
[88,32,14,9],
[88,32,14,10],
[88,32,15,0],
[88,32,15,1],
[88,32,15,2],
[88,32,15,3],
[88,32,15,4],
[88,32,15,5],
[88,32,15,6],
[88,32,15,7],
[88,32,15,8],
[88,32,15,10],
[88,32,15,13],
[88,32,15,14],
[88,32,16,0],
[88,32,16,1],
[88,32,16,2],
[88,32,16,3],
[88,32,16,4],
[88,32,16,5],
[88,32,16,6],
[88,32,16,7],
[88,32,16,8],
[88,32,16,9],
[88,32,16,10],
[88,32,16,13],
[88,32,17,0],
[88,32,17,1],
[88,32,17,2],
[88,32,17,3],
[88,32,17,4],
[88,32,17,5],
[88,32,17,6],
[88,32,17,7],
[88,32,17,8],
[88,32,17,9],
[88,32,17,10],
[88,32,17,13],
[88,32,18,0],
[88,32,18,1],
[88,32,18,2],
[88,32,18,3],
[88,32,18,4],
[88,32,18,5],
[88,32,18,6],
[88,32,18,7],
[88,32,18,8],
[88,32,18,10],
[88,32,19,0],
[88,32,19,1],
[88,32,19,2],
[88,32,19,3],
[88,32,19,4],
[88,32,19,5],
[88,32,19,6],
[88,32,19,7],
[88,32,19,8],
[88,32,19,9],
[88,32,20,0],
[88,32,20,1],
[88,32,20,2],
[88,32,20,3],
[88,32,20,4],
[88,32,20,5],
[88,32,20,6],
[88,32,20,7],
[88,32,21,0],
[88,32,21,1],
[88,32,21,2],
[88,32,21,4],
[88,32,22,0],
[88,32,22,1],
[88,32,22,2],
[88,32,22,3],
[88,32,22,4],
[88,32,24,0],
[88,32,24,1],
[88,32,24,2],
[88,33,3,0],
[88,33,3,1],
[88,33,3,2],
[88,33,4,0],
[88,33,4,1],
[88,33,4,2],
[88,33,5,4],
[88,33,6,0],
[88,33,6,1],
[88,33,6,2],
[88,33,6,4],
[88,33,7,0],
[88,33,7,1],
[88,33,7,2],
[88,33,7,3],
[88,33,7,4],
[88,33,7,5],
[88,33,7,6],
[88,33,8,0],
[88,33,8,1],
[88,33,8,2],
[88,33,8,4],
[88,33,8,7],
[88,33,9,0],
[88,33,9,1],
[88,33,9,2],
[88,33,9,3],
[88,33,9,4],
[88,33,9,5],
[88,33,9,6],
[88,33,9,7],
[88,33,9,8],
[88,33,10,0],
[88,33,10,1],
[88,33,10,2],
[88,33,10,3],
[88,33,10,4],
[88,33,10,5],
[88,33,10,6],
[88,33,10,7],
[88,33,10,8],
[88,33,10,9],
[88,33,13,0],
[88,33,13,1],
[88,33,13,2],
[88,33,13,3],
[88,33,13,4],
[88,33,13,5],
[88,33,13,6],
[88,33,13,7],
[88,33,13,8],
[88,33,13,9],
[88,33,13,10],
[88,33,14,0],
[88,33,14,1],
[88,33,14,2],
[88,33,14,3],
[88,33,14,4],
[88,33,14,5],
[88,33,14,6],
[88,33,14,7],
[88,33,14,8],
[88,33,14,9],
[88,33,14,10],
[88,33,15,0],
[88,33,15,1],
[88,33,15,2],
[88,33,15,3],
[88,33,15,4],
[88,33,15,5],
[88,33,15,6],
[88,33,15,7],
[88,33,15,8],
[88,33,15,10],
[88,33,15,13],
[88,33,16,0],
[88,33,16,1],
[88,33,16,2],
[88,33,16,3],
[88,33,16,4],
[88,33,16,5],
[88,33,16,6],
[88,33,16,7],
[88,33,16,8],
[88,33,16,9],
[88,33,16,10],
[88,33,17,0],
[88,33,17,1],
[88,33,17,2],
[88,33,17,3],
[88,33,17,4],
[88,33,17,5],
[88,33,17,6],
[88,33,17,7],
[88,33,17,8],
[88,33,17,9],
[88,33,17,10],
[88,33,18,0],
[88,33,18,1],
[88,33,18,2],
[88,33,18,3],
[88,33,18,4],
[88,33,18,5],
[88,33,18,6],
[88,33,18,7],
[88,33,18,8],
[88,33,19,0],
[88,33,19,1],
[88,33,19,2],
[88,33,19,3],
[88,33,19,4],
[88,33,19,5],
[88,33,19,6],
[88,33,19,7],
[88,33,20,0],
[88,33,20,1],
[88,33,20,2],
[88,33,20,3],
[88,33,20,4],
[88,33,20,5],
[88,33,21,0],
[88,33,21,1],
[88,33,21,2],
[88,33,21,4],
[88,33,22,0],
[88,33,22,1],
[88,33,22,2],
[88,34,4,3],
[88,34,5,0],
[88,34,5,1],
[88,34,5,2],
[88,34,5,4],
[88,34,6,0],
[88,34,6,1],
[88,34,6,2],
[88,34,6,4],
[88,34,8,0],
[88,34,8,1],
[88,34,8,2],
[88,34,8,4],
[88,34,9,0],
[88,34,9,1],
[88,34,9,2],
[88,34,9,3],
[88,34,9,4],
[88,34,9,5],
[88,34,9,6],
[88,34,9,8],
[88,34,10,0],
[88,34,10,1],
[88,34,10,2],
[88,34,10,3],
[88,34,10,4],
[88,34,10,5],
[88,34,10,6],
[88,34,10,8],
[88,34,10,9],
[88,34,11,0],
[88,34,11,1],
[88,34,11,2],
[88,34,11,3],
[88,34,11,4],
[88,34,11,5],
[88,34,11,6],
[88,34,11,8],
[88,34,11,9],
[88,34,11,10],
[88,34,12,0],
[88,34,12,1],
[88,34,12,2],
[88,34,12,3],
[88,34,12,4],
[88,34,12,5],
[88,34,12,6],
[88,34,12,8],
[88,34,12,9],
[88,34,12,10],
[88,34,13,0],
[88,34,13,1],
[88,34,13,2],
[88,34,13,3],
[88,34,13,4],
[88,34,13,5],
[88,34,13,6],
[88,34,13,8],
[88,34,13,9],
[88,34,13,10],
[88,34,13,11],
[88,34,13,12],
[88,34,14,0],
[88,34,14,1],
[88,34,14,2],
[88,34,14,3],
[88,34,14,4],
[88,34,14,5],
[88,34,14,6],
[88,34,14,8],
[88,34,14,9],
[88,34,14,10],
[88,34,14,11],
[88,34,14,12],
[88,34,15,0],
[88,34,15,1],
[88,34,15,2],
[88,34,15,3],
[88,34,15,4],
[88,34,15,5],
[88,34,15,6],
[88,34,15,8],
[88,34,15,10],
[88,34,15,11],
[88,34,16,0],
[88,34,16,1],
[88,34,16,2],
[88,34,16,3],
[88,34,16,4],
[88,34,16,5],
[88,34,16,6],
[88,34,16,8],
[88,34,16,9],
[88,34,17,0],
[88,34,17,1],
[88,34,17,2],
[88,34,17,3],
[88,34,17,4],
[88,34,17,5],
[88,34,17,6],
[88,34,17,8],
[88,34,17,9],
[88,34,18,0],
[88,34,18,1],
[88,34,18,2],
[88,34,18,3],
[88,34,18,4],
[88,34,18,5],
[88,34,18,6],
[88,34,19,0],
[88,34,19,1],
[88,34,19,2],
[88,34,19,3],
[88,34,19,4],
[88,34,19,5],
[88,34,20,0],
[88,34,20,1],
[88,34,20,2],
[88,34,20,3],
[88,34,20,4],
[88,34,21,0],
[88,34,21,1],
[88,34,21,2],
[88,35,3,0],
[88,35,3,1],
[88,35,3,2],
[88,35,4,0],
[88,35,4,1],
[88,35,4,2],
[88,35,4,3],
[88,35,5,0],
[88,35,5,1],
[88,35,5,2],
[88,35,5,4],
[88,35,6,0],
[88,35,6,1],
[88,35,6,2],
[88,35,6,4],
[88,35,7,0],
[88,35,7,1],
[88,35,7,2],
[88,35,7,3],
[88,35,7,4],
[88,35,7,5],
[88,35,7,6],
[88,35,8,0],
[88,35,8,1],
[88,35,8,2],
[88,35,8,4],
[88,35,8,7],
[88,35,10,0],
[88,35,10,1],
[88,35,10,2],
[88,35,10,3],
[88,35,10,4],
[88,35,10,5],
[88,35,10,6],
[88,35,10,7],
[88,35,10,8],
[88,35,11,0],
[88,35,11,1],
[88,35,11,2],
[88,35,11,3],
[88,35,11,4],
[88,35,11,5],
[88,35,11,6],
[88,35,11,7],
[88,35,11,8],
[88,35,11,10],
[88,35,12,0],
[88,35,12,1],
[88,35,12,2],
[88,35,12,3],
[88,35,12,4],
[88,35,12,5],
[88,35,12,6],
[88,35,12,7],
[88,35,12,8],
[88,35,12,10],
[88,35,13,0],
[88,35,13,1],
[88,35,13,2],
[88,35,13,3],
[88,35,13,4],
[88,35,13,5],
[88,35,13,6],
[88,35,13,7],
[88,35,13,8],
[88,35,13,10],
[88,35,13,11],
[88,35,13,12],
[88,35,14,0],
[88,35,14,1],
[88,35,14,2],
[88,35,14,3],
[88,35,14,4],
[88,35,14,5],
[88,35,14,6],
[88,35,14,7],
[88,35,14,8],
[88,35,14,10],
[88,35,14,11],
[88,35,16,0],
[88,35,16,1],
[88,35,16,2],
[88,35,16,3],
[88,35,16,4],
[88,35,16,5],
[88,35,16,6],
[88,35,16,7],
[88,35,17,0],
[88,35,17,1],
[88,35,17,2],
[88,35,17,3],
[88,35,17,4],
[88,35,17,5],
[88,35,17,6],
[88,35,17,7],
[88,35,19,0],
[88,35,19,1],
[88,35,19,2],
[88,35,19,3],
[88,35,19,4],
[88,35,20,0],
[88,35,20,1],
[88,35,20,2],
[88,36,3,0],
[88,36,3,1],
[88,36,3,2],
[88,36,4,0],
[88,36,4,1],
[88,36,4,2],
[88,36,4,3],
[88,36,5,0],
[88,36,5,1],
[88,36,5,2],
[88,36,5,4],
[88,36,6,0],
[88,36,6,1],
[88,36,6,2],
[88,36,6,4],
[88,36,7,0],
[88,36,7,1],
[88,36,7,2],
[88,36,7,3],
[88,36,7,4],
[88,36,7,5],
[88,36,7,6],
[88,36,8,0],
[88,36,8,1],
[88,36,8,2],
[88,36,8,4],
[88,36,8,7],
[88,36,9,0],
[88,36,9,1],
[88,36,9,2],
[88,36,9,3],
[88,36,9,4],
[88,36,9,5],
[88,36,9,6],
[88,36,9,7],
[88,36,9,8],
[88,36,10,0],
[88,36,10,1],
[88,36,10,2],
[88,36,10,3],
[88,36,10,4],
[88,36,10,5],
[88,36,10,6],
[88,36,10,7],
[88,36,10,8],
[88,36,10,9],
[88,36,11,0],
[88,36,11,1],
[88,36,11,2],
[88,36,11,3],
[88,36,11,4],
[88,36,11,5],
[88,36,11,6],
[88,36,11,7],
[88,36,11,8],
[88,36,11,9],
[88,36,11,10],
[88,36,12,0],
[88,36,12,1],
[88,36,12,2],
[88,36,12,3],
[88,36,12,4],
[88,36,12,5],
[88,36,12,6],
[88,36,12,7],
[88,36,12,8],
[88,36,12,9],
[88,36,12,10],
[88,36,15,0],
[88,36,15,1],
[88,36,15,2],
[88,36,15,3],
[88,36,15,4],
[88,36,15,5],
[88,36,15,6],
[88,36,15,7],
[88,36,16,0],
[88,36,16,1],
[88,36,16,2],
[88,36,16,3],
[88,36,16,4],
[88,36,16,5],
[88,36,17,0],
[88,36,17,1],
[88,36,17,2],
[88,36,17,3],
[88,36,17,4],
[88,36,17,5],
[88,36,18,0],
[88,36,18,1],
[88,36,18,2],
[88,36,18,3],
[88,36,18,4],
[88,37,3,0],
[88,37,3,1],
[88,37,3,2],
[88,37,5,0],
[88,37,5,1],
[88,37,5,2],
[88,37,6,0],
[88,37,6,1],
[88,37,6,2],
[88,37,7,0],
[88,37,7,1],
[88,37,7,2],
[88,37,7,3],
[88,37,7,5],
[88,37,7,6],
[88,37,8,0],
[88,37,8,1],
[88,37,8,2],
[88,37,8,7],
[88,37,9,0],
[88,37,9,1],
[88,37,9,2],
[88,37,9,3],
[88,37,9,5],
[88,37,9,6],
[88,37,9,7],
[88,37,9,8],
[88,37,10,0],
[88,37,10,1],
[88,37,10,2],
[88,37,10,3],
[88,37,10,5],
[88,37,10,6],
[88,37,10,7],
[88,37,10,8],
[88,37,10,9],
[88,37,11,0],
[88,37,11,1],
[88,37,11,2],
[88,37,11,3],
[88,37,11,5],
[88,37,11,6],
[88,37,11,7],
[88,37,11,8],
[88,37,11,9],
[88,37,11,10],
[88,37,12,0],
[88,37,12,1],
[88,37,12,2],
[88,37,12,3],
[88,37,12,5],
[88,37,12,6],
[88,37,12,7],
[88,37,12,8],
[88,37,12,9],
[88,37,13,0],
[88,37,13,1],
[88,37,13,2],
[88,37,13,3],
[88,37,13,5],
[88,37,13,6],
[88,37,13,7],
[88,37,13,8],
[88,37,13,9],
[88,37,14,0],
[88,37,14,1],
[88,37,14,2],
[88,37,14,3],
[88,37,14,5],
[88,37,14,6],
[88,37,14,7],
[88,37,15,0],
[88,37,15,1],
[88,37,15,2],
[88,37,15,3],
[88,37,15,5],
[88,37,16,0],
[88,37,16,1],
[88,37,16,2],
[88,37,16,3],
[88,37,17,0],
[88,37,17,1],
[88,37,17,2],
[88,37,17,3],
[88,37,18,0],
[88,37,18,1],
[88,37,18,2],
[88,38,3,0],
[88,38,3,1],
[88,38,3,2],
[88,38,4,0],
[88,38,4,1],
[88,38,4,2],
[88,38,4,3],
[88,38,5,0],
[88,38,5,1],
[88,38,5,2],
[88,38,5,4],
[88,38,6,0],
[88,38,6,1],
[88,38,6,2],
[88,38,6,4],
[88,38,8,0],
[88,38,8,1],
[88,38,8,2],
[88,38,8,4],
[88,38,9,0],
[88,38,9,1],
[88,38,9,2],
[88,38,9,3],
[88,38,9,4],
[88,38,9,5],
[88,38,9,6],
[88,38,9,8],
[88,38,10,0],
[88,38,10,1],
[88,38,10,2],
[88,38,10,3],
[88,38,10,4],
[88,38,10,5],
[88,38,10,6],
[88,38,10,8],
[88,38,10,9],
[88,38,11,0],
[88,38,11,1],
[88,38,11,2],
[88,38,11,3],
[88,38,11,4],
[88,38,11,5],
[88,38,11,6],
[88,38,11,8],
[88,38,11,9],
[88,38,12,0],
[88,38,12,1],
[88,38,12,2],
[88,38,12,3],
[88,38,12,4],
[88,38,12,5],
[88,38,12,6],
[88,38,13,0],
[88,38,13,1],
[88,38,13,2],
[88,38,13,3],
[88,38,13,4],
[88,38,13,5],
[88,38,13,6],
[88,38,14,0],
[88,38,14,1],
[88,38,14,2],
[88,38,14,3],
[88,38,14,4],
[88,38,14,5],
[88,38,15,0],
[88,38,15,1],
[88,38,15,2],
[88,38,15,3],
[88,38,15,4],
[88,38,16,0],
[88,38,16,1],
[88,38,16,2],
[88,38,17,0],
[88,38,17,1],
[88,38,17,2],
[88,39,4,0],
[88,39,4,1],
[88,39,4,2],
[88,39,7,0],
[88,39,7,1],
[88,39,7,2],
[88,39,7,4],
[88,39,9,0],
[88,39,9,1],
[88,39,9,2],
[88,39,9,4],
[88,39,9,7],
[88,39,10,0],
[88,39,10,1],
[88,39,10,2],
[88,39,10,4],
[88,39,10,7],
[88,39,11,0],
[88,39,11,1],
[88,39,11,2],
[88,39,11,4],
[88,39,11,7],
[88,39,12,0],
[88,39,12,1],
[88,39,12,2],
[88,39,12,4],
[88,39,13,0],
[88,39,13,1],
[88,39,13,2],
[88,39,13,4],
[88,39,14,0],
[88,39,14,1],
[88,39,14,2],
[88,39,14,4],
[88,39,15,0],
[88,39,15,1],
[88,39,15,2],
[88,40,3,0],
[88,40,3,1],
[88,40,3,2],
[88,40,4,0],
[88,40,4,1],
[88,40,4,2],
[88,40,4,3],
[88,40,5,0],
[88,40,5,1],
[88,40,5,2],
[88,40,5,4],
[88,40,6,0],
[88,40,6,1],
[88,40,6,2],
[88,40,6,4],
[88,40,7,0],
[88,40,7,1],
[88,40,7,2],
[88,40,7,3],
[88,40,7,4],
[88,40,7,5],
[88,40,7,6],
[88,40,8,0],
[88,40,8,1],
[88,40,8,2],
[88,40,8,4],
[88,40,8,7],
[88,40,10,0],
[88,40,10,1],
[88,40,10,2],
[88,40,10,3],
[88,40,10,4],
[88,40,10,5],
[88,40,11,0],
[88,40,11,1],
[88,40,11,2],
[88,40,11,3],
[88,40,11,4],
[88,40,11,5],
[88,40,12,0],
[88,40,12,1],
[88,40,12,2],
[88,40,12,3],
[88,40,12,4],
[88,40,13,0],
[88,40,13,1],
[88,40,13,2],
[88,40,13,3],
[88,40,13,4],
[88,40,14,0],
[88,40,14,1],
[88,40,14,2],
[88,41,3,0],
[88,41,3,1],
[88,41,3,2],
[88,41,4,0],
[88,41,4,1],
[88,41,4,2],
[88,41,4,3],
[88,41,5,0],
[88,41,5,1],
[88,41,5,2],
[88,41,5,4],
[88,41,6,0],
[88,41,6,1],
[88,41,6,2],
[88,41,6,4],
[88,41,7,0],
[88,41,7,1],
[88,41,7,2],
[88,41,7,3],
[88,41,7,4],
[88,41,7,5],
[88,41,7,6],
[88,41,8,0],
[88,41,8,1],
[88,41,8,2],
[88,41,8,4],
[88,41,8,7],
[88,41,10,0],
[88,41,10,1],
[88,41,10,2],
[88,41,10,3],
[88,41,10,4],
[88,41,10,5],
[88,41,11,0],
[88,41,11,1],
[88,41,11,2],
[88,41,11,3],
[88,41,11,4],
[88,41,11,5],
[88,41,12,0],
[88,41,12,1],
[88,41,12,2],
[88,41,12,3],
[88,41,12,4],
[88,41,13,0],
[88,41,13,1],
[88,41,13,2],
[88,41,13,3],
[88,41,13,4],
[88,41,14,0],
[88,41,14,1],
[88,41,14,2],
[88,42,3,0],
[88,42,3,1],
[88,42,3,2],
[88,42,4,0],
[88,42,4,1],
[88,42,4,2],
[88,42,4,3],
[88,42,5,0],
[88,42,5,1],
[88,42,5,2],
[88,42,5,4],
[88,42,6,0],
[88,42,6,1],
[88,42,6,2],
[88,42,6,4],
[88,42,7,0],
[88,42,7,1],
[88,42,7,2],
[88,42,7,3],
[88,42,7,4],
[88,42,7,5],
[88,42,7,6],
[88,42,8,0],
[88,42,8,1],
[88,42,8,2],
[88,42,8,4],
[88,42,9,0],
[88,42,9,1],
[88,42,9,2],
[88,42,9,3],
[88,42,9,4],
[88,42,9,5],
[88,42,11,0],
[88,42,11,1],
[88,42,11,2],
[88,42,11,3],
[88,42,11,4],
[88,42,12,0],
[88,42,12,1],
[88,42,12,2],
[88,42,13,0],
[88,42,13,1],
[88,42,13,2],
[88,43,3,0],
[88,43,3,1],
[88,43,3,2],
[88,43,4,0],
[88,43,4,1],
[88,43,4,2],
[88,43,4,3],
[88,43,5,0],
[88,43,5,1],
[88,43,5,2],
[88,43,5,4],
[88,43,6,0],
[88,43,6,1],
[88,43,6,2],
[88,43,6,4],
[88,43,8,0],
[88,43,8,1],
[88,43,8,2],
[88,43,8,4],
[88,43,9,0],
[88,43,9,1],
[88,43,9,2],
[88,43,9,3],
[88,43,9,4],
[88,43,10,0],
[88,43,10,1],
[88,43,10,2],
[88,43,11,0],
[88,43,11,1],
[88,43,11,2],
[88,44,3,0],
[88,44,3,1],
[88,44,3,2],
[88,44,4,0],
[88,44,4,1],
[88,44,4,2],
[88,44,4,3],
[88,44,5,0],
[88,44,5,1],
[88,44,5,2],
[88,44,5,4],
[88,44,6,0],
[88,44,6,1],
[88,44,6,2],
[88,44,6,4],
[88,44,7,0],
[88,44,7,1],
[88,44,7,2],
[88,44,7,3],
[88,44,7,4],
[88,44,8,0],
[88,44,8,1],
[88,44,8,2],
[88,44,9,0],
[88,44,9,1],
[88,44,9,2],
[88,45,3,0],
[88,45,3,1],
[88,45,3,2],
[88,45,4,0],
[88,45,4,1],
[88,45,4,2],
[88,45,4,3],
[88,45,5,0],
[88,45,5,1],
[88,45,5,2],
[88,45,5,4],
[88,45,6,0],
[88,45,6,1],
[88,45,6,2],
[88,45,7,0],
[88,45,7,1],
[88,45,7,2],
[88,46,3,0],
[88,46,3,1],
[88,46,3,2],
[88,46,4,0],
[88,46,4,1],
[88,46,4,2],
[88,46,4,3],
[88,46,5,0],
[88,46,5,1],
[88,46,5,2],
[89,4,3,0],
[89,4,3,1],
[89,4,3,2],
[89,5,4,0],
[89,5,4,1],
[89,5,4,2],
[89,6,4,0],
[89,6,4,1],
[89,6,4,2],
[89,7,3,0],
[89,7,3,1],
[89,7,3,2],
[89,7,4,0],
[89,7,4,1],
[89,7,4,2],
[89,7,4,3],
[89,7,5,0],
[89,7,5,1],
[89,7,5,2],
[89,7,5,4],
[89,7,6,0],
[89,7,6,1],
[89,7,6,2],
[89,7,6,4],
[89,8,4,0],
[89,8,4,1],
[89,8,4,2],
[89,8,7,0],
[89,8,7,1],
[89,8,7,2],
[89,8,7,4],
[89,10,3,0],
[89,10,3,1],
[89,10,3,2],
[89,10,4,0],
[89,10,4,1],
[89,10,4,2],
[89,10,4,3],
[89,10,5,0],
[89,10,5,1],
[89,10,5,2],
[89,10,5,4],
[89,10,6,0],
[89,10,6,1],
[89,10,6,2],
[89,10,6,4],
[89,10,7,0],
[89,10,7,1],
[89,10,7,2],
[89,10,7,3],
[89,10,7,4],
[89,10,7,5],
[89,10,7,6],
[89,10,8,0],
[89,10,8,1],
[89,10,8,2],
[89,10,8,4],
[89,10,8,7],
[89,11,3,0],
[89,11,3,1],
[89,11,3,2],
[89,11,4,0],
[89,11,4,1],
[89,11,4,2],
[89,11,4,3],
[89,11,5,0],
[89,11,5,1],
[89,11,5,2],
[89,11,5,4],
[89,11,6,0],
[89,11,6,1],
[89,11,6,2],
[89,11,6,4],
[89,11,7,0],
[89,11,7,1],
[89,11,7,2],
[89,11,7,3],
[89,11,7,4],
[89,11,7,5],
[89,11,7,6],
[89,11,8,0],
[89,11,8,1],
[89,11,8,2],
[89,11,8,4],
[89,11,8,7],
[89,11,10,0],
[89,11,10,1],
[89,11,10,2],
[89,11,10,3],
[89,11,10,4],
[89,11,10,5],
[89,11,10,6],
[89,11,10,7],
[89,11,10,8],
[89,12,3,0],
[89,12,3,1],
[89,12,3,2],
[89,12,4,0],
[89,12,4,1],
[89,12,4,2],
[89,12,4,3],
[89,12,5,0],
[89,12,5,1],
[89,12,5,2],
[89,12,5,4],
[89,12,6,0],
[89,12,6,1],
[89,12,6,2],
[89,12,6,4],
[89,12,7,0],
[89,12,7,1],
[89,12,7,2],
[89,12,7,3],
[89,12,7,4],
[89,12,7,5],
[89,12,7,6],
[89,12,8,0],
[89,12,8,1],
[89,12,8,2],
[89,12,8,4],
[89,12,8,7],
[89,12,10,0],
[89,12,10,1],
[89,12,10,2],
[89,12,10,3],
[89,12,10,4],
[89,12,10,5],
[89,12,10,6],
[89,12,10,7],
[89,12,10,8],
[89,13,3,0],
[89,13,3,1],
[89,13,3,2],
[89,13,4,0],
[89,13,4,1],
[89,13,4,2],
[89,13,4,3],
[89,13,5,0],
[89,13,5,1],
[89,13,5,2],
[89,13,5,4],
[89,13,6,0],
[89,13,6,1],
[89,13,6,2],
[89,13,6,4],
[89,13,7,0],
[89,13,7,1],
[89,13,7,2],
[89,13,7,3],
[89,13,7,4],
[89,13,7,5],
[89,13,7,6],
[89,13,8,0],
[89,13,8,1],
[89,13,8,2],
[89,13,8,4],
[89,13,8,7],
[89,13,10,0],
[89,13,10,1],
[89,13,10,2],
[89,13,10,3],
[89,13,10,4],
[89,13,10,5],
[89,13,10,6],
[89,13,10,7],
[89,13,10,8],
[89,13,11,0],
[89,13,11,1],
[89,13,11,2],
[89,13,11,3],
[89,13,11,4],
[89,13,11,5],
[89,13,11,6],
[89,13,11,7],
[89,13,11,8],
[89,13,11,10],
[89,13,12,0],
[89,13,12,1],
[89,13,12,2],
[89,13,12,3],
[89,13,12,4],
[89,13,12,5],
[89,13,12,6],
[89,13,12,7],
[89,13,12,8],
[89,13,12,10],
[89,14,3,0],
[89,14,3,1],
[89,14,3,2],
[89,14,4,0],
[89,14,4,1],
[89,14,4,2],
[89,14,4,3],
[89,14,5,0],
[89,14,5,1],
[89,14,5,2],
[89,14,5,4],
[89,14,6,0],
[89,14,6,1],
[89,14,6,2],
[89,14,6,4],
[89,14,7,0],
[89,14,7,1],
[89,14,7,2],
[89,14,7,3],
[89,14,7,4],
[89,14,7,5],
[89,14,7,6],
[89,14,8,0],
[89,14,8,1],
[89,14,8,2],
[89,14,8,4],
[89,14,8,7],
[89,14,10,0],
[89,14,10,1],
[89,14,10,2],
[89,14,10,3],
[89,14,10,4],
[89,14,10,5],
[89,14,10,6],
[89,14,10,7],
[89,14,10,8],
[89,14,11,0],
[89,14,11,1],
[89,14,11,2],
[89,14,11,3],
[89,14,11,4],
[89,14,11,5],
[89,14,11,6],
[89,14,11,7],
[89,14,11,8],
[89,14,11,10],
[89,14,12,0],
[89,14,12,1],
[89,14,12,2],
[89,14,12,3],
[89,14,12,4],
[89,14,12,5],
[89,14,12,6],
[89,14,12,7],
[89,14,12,8],
[89,14,12,10],
[89,16,3,0],
[89,16,3,1],
[89,16,3,2],
[89,16,4,0],
[89,16,4,1],
[89,16,4,2],
[89,16,4,3],
[89,16,5,0],
[89,16,5,1],
[89,16,5,2],
[89,16,5,4],
[89,16,6,0],
[89,16,6,1],
[89,16,6,2],
[89,16,6,4],
[89,16,7,0],
[89,16,7,1],
[89,16,7,2],
[89,16,7,3],
[89,16,7,4],
[89,16,7,5],
[89,16,7,6],
[89,16,8,0],
[89,16,8,1],
[89,16,8,2],
[89,16,8,4],
[89,16,8,7],
[89,16,10,0],
[89,16,10,1],
[89,16,10,2],
[89,16,10,3],
[89,16,10,4],
[89,16,10,5],
[89,16,10,6],
[89,16,10,7],
[89,16,10,8],
[89,16,11,0],
[89,16,11,1],
[89,16,11,2],
[89,16,11,3],
[89,16,11,4],
[89,16,11,5],
[89,16,11,6],
[89,16,11,7],
[89,16,11,8],
[89,16,11,10],
[89,16,12,0],
[89,16,12,1],
[89,16,12,2],
[89,16,12,3],
[89,16,12,4],
[89,16,12,5],
[89,16,12,6],
[89,16,12,7],
[89,16,12,8],
[89,16,12,10],
[89,16,13,0],
[89,16,13,1],
[89,16,13,2],
[89,16,13,3],
[89,16,13,4],
[89,16,13,5],
[89,16,13,6],
[89,16,13,7],
[89,16,13,8],
[89,16,13,10],
[89,16,13,11],
[89,16,13,12],
[89,16,14,0],
[89,16,14,1],
[89,16,14,2],
[89,16,14,3],
[89,16,14,4],
[89,16,14,5],
[89,16,14,6],
[89,16,14,7],
[89,16,14,8],
[89,16,14,10],
[89,16,14,11],
[89,16,14,12],
[89,17,3,0],
[89,17,3,1],
[89,17,3,2],
[89,17,4,0],
[89,17,4,1],
[89,17,4,2],
[89,17,4,3],
[89,17,5,0],
[89,17,5,1],
[89,17,5,2],
[89,17,5,4],
[89,17,6,0],
[89,17,6,1],
[89,17,6,2],
[89,17,6,4],
[89,17,7,0],
[89,17,7,1],
[89,17,7,2],
[89,17,7,3],
[89,17,7,4],
[89,17,7,5],
[89,17,7,6],
[89,17,8,0],
[89,17,8,1],
[89,17,8,2],
[89,17,8,4],
[89,17,8,7],
[89,17,10,0],
[89,17,10,1],
[89,17,10,2],
[89,17,10,3],
[89,17,10,4],
[89,17,10,5],
[89,17,10,6],
[89,17,10,7],
[89,17,10,8],
[89,17,11,0],
[89,17,11,1],
[89,17,11,2],
[89,17,11,3],
[89,17,11,4],
[89,17,11,5],
[89,17,11,6],
[89,17,11,7],
[89,17,11,8],
[89,17,11,10],
[89,17,12,0],
[89,17,12,1],
[89,17,12,2],
[89,17,12,3],
[89,17,12,4],
[89,17,12,5],
[89,17,12,6],
[89,17,12,7],
[89,17,12,8],
[89,17,12,10],
[89,17,13,0],
[89,17,13,1],
[89,17,13,2],
[89,17,13,3],
[89,17,13,4],
[89,17,13,5],
[89,17,13,6],
[89,17,13,7],
[89,17,13,8],
[89,17,13,10],
[89,17,13,11],
[89,17,13,12],
[89,17,14,0],
[89,17,14,1],
[89,17,14,2],
[89,17,14,3],
[89,17,14,4],
[89,17,14,5],
[89,17,14,6],
[89,17,14,7],
[89,17,14,8],
[89,17,14,10],
[89,17,14,11],
[89,17,14,12],
[89,17,16,0],
[89,17,16,1],
[89,17,16,2],
[89,17,16,3],
[89,17,16,4],
[89,17,16,5],
[89,17,16,6],
[89,17,16,7],
[89,17,16,8],
[89,17,16,10],
[89,17,16,11],
[89,17,16,12],
[89,17,16,13],
[89,17,16,14],
[89,19,3,0],
[89,19,3,1],
[89,19,3,2],
[89,19,4,0],
[89,19,4,1],
[89,19,4,2],
[89,19,4,3],
[89,19,5,0],
[89,19,5,1],
[89,19,5,2],
[89,19,5,4],
[89,19,6,0],
[89,19,6,1],
[89,19,6,2],
[89,19,6,4],
[89,19,7,0],
[89,19,7,1],
[89,19,7,2],
[89,19,7,3],
[89,19,7,4],
[89,19,7,5],
[89,19,7,6],
[89,19,8,0],
[89,19,8,1],
[89,19,8,2],
[89,19,8,4],
[89,19,8,7],
[89,19,10,0],
[89,19,10,1],
[89,19,10,2],
[89,19,10,3],
[89,19,10,4],
[89,19,10,5],
[89,19,10,6],
[89,19,10,7],
[89,19,10,8],
[89,19,11,0],
[89,19,11,1],
[89,19,11,2],
[89,19,11,3],
[89,19,11,4],
[89,19,11,5],
[89,19,11,6],
[89,19,11,7],
[89,19,11,8],
[89,19,11,10],
[89,19,12,0],
[89,19,12,1],
[89,19,12,2],
[89,19,12,3],
[89,19,12,4],
[89,19,12,5],
[89,19,12,6],
[89,19,12,7],
[89,19,12,8],
[89,19,12,10],
[89,19,16,0],
[89,19,16,1],
[89,19,16,2],
[89,19,16,3],
[89,19,16,4],
[89,19,16,5],
[89,19,16,6],
[89,19,16,7],
[89,19,16,8],
[89,19,16,10],
[89,19,16,11],
[89,19,17,0],
[89,19,17,1],
[89,19,17,2],
[89,19,17,3],
[89,19,17,4],
[89,19,17,5],
[89,19,17,6],
[89,19,17,7],
[89,19,17,8],
[89,19,17,10],
[89,19,17,11],
[89,19,17,16],
[89,20,3,0],
[89,20,3,1],
[89,20,3,2],
[89,20,4,0],
[89,20,4,1],
[89,20,4,2],
[89,20,4,3],
[89,20,5,0],
[89,20,5,1],
[89,20,5,2],
[89,20,5,4],
[89,20,6,0],
[89,20,6,1],
[89,20,6,2],
[89,20,6,4],
[89,20,7,0],
[89,20,7,1],
[89,20,7,2],
[89,20,7,3],
[89,20,7,4],
[89,20,7,5],
[89,20,7,6],
[89,20,8,0],
[89,20,8,1],
[89,20,8,2],
[89,20,8,4],
[89,20,8,7],
[89,20,11,0],
[89,20,11,1],
[89,20,11,2],
[89,20,11,3],
[89,20,11,4],
[89,20,11,5],
[89,20,11,6],
[89,20,11,7],
[89,20,11,8],
[89,20,12,0],
[89,20,12,1],
[89,20,12,2],
[89,20,12,3],
[89,20,12,4],
[89,20,12,5],
[89,20,12,6],
[89,20,12,7],
[89,20,12,8],
[89,20,13,0],
[89,20,13,1],
[89,20,13,2],
[89,20,13,3],
[89,20,13,4],
[89,20,13,5],
[89,20,13,6],
[89,20,13,7],
[89,20,13,8],
[89,20,13,11],
[89,20,13,12],
[89,20,14,0],
[89,20,14,1],
[89,20,14,2],
[89,20,14,3],
[89,20,14,4],
[89,20,14,5],
[89,20,14,6],
[89,20,14,7],
[89,20,14,8],
[89,20,14,11],
[89,20,14,12],
[89,20,16,0],
[89,20,16,1],
[89,20,16,2],
[89,20,16,3],
[89,20,16,4],
[89,20,16,5],
[89,20,16,6],
[89,20,16,7],
[89,20,16,8],
[89,20,16,12],
[89,20,16,13],
[89,20,16,14],
[89,20,17,0],
[89,20,17,1],
[89,20,17,2],
[89,20,17,3],
[89,20,17,4],
[89,20,17,5],
[89,20,17,6],
[89,20,17,7],
[89,20,17,8],
[89,20,17,12],
[89,20,17,13],
[89,20,17,14],
[89,20,17,16],
[89,20,19,0],
[89,20,19,1],
[89,20,19,2],
[89,20,19,3],
[89,20,19,4],
[89,20,19,5],
[89,20,19,8],
[89,20,19,11],
[89,20,19,12],
[89,20,19,16],
[89,20,19,17],
[89,21,4,0],
[89,21,4,1],
[89,21,4,2],
[89,21,7,0],
[89,21,7,1],
[89,21,7,2],
[89,21,7,4],
[89,21,10,0],
[89,21,10,1],
[89,21,10,2],
[89,21,10,4],
[89,21,10,7],
[89,21,11,0],
[89,21,11,1],
[89,21,11,2],
[89,21,11,4],
[89,21,11,7],
[89,21,11,10],
[89,21,12,0],
[89,21,12,1],
[89,21,12,2],
[89,21,12,4],
[89,21,12,7],
[89,21,12,10],
[89,21,13,0],
[89,21,13,1],
[89,21,13,2],
[89,21,13,4],
[89,21,13,7],
[89,21,13,10],
[89,21,13,11],
[89,21,13,12],
[89,21,14,0],
[89,21,14,1],
[89,21,14,2],
[89,21,14,4],
[89,21,14,7],
[89,21,14,10],
[89,21,14,11],
[89,21,16,0],
[89,21,16,1],
[89,21,16,2],
[89,21,16,4],
[89,21,16,7],
[89,21,16,10],
[89,21,16,11],
[89,21,16,12],
[89,21,16,13],
[89,21,16,14],
[89,21,17,0],
[89,21,17,1],
[89,21,17,2],
[89,21,17,4],
[89,21,17,7],
[89,21,17,10],
[89,21,17,11],
[89,21,17,12],
[89,21,17,13],
[89,21,17,14],
[89,21,17,16],
[89,21,19,0],
[89,21,19,1],
[89,21,19,2],
[89,21,19,4],
[89,21,19,7],
[89,21,19,10],
[89,21,19,11],
[89,21,19,12],
[89,21,19,16],
[89,21,19,17],
[89,21,20,0],
[89,21,20,1],
[89,21,20,2],
[89,21,20,7],
[89,21,20,11],
[89,21,20,12],
[89,21,20,13],
[89,21,20,14],
[89,21,20,16],
[89,21,20,17],
[89,21,20,19],
[89,22,3,0],
[89,22,3,1],
[89,22,3,2],
[89,22,4,0],
[89,22,4,1],
[89,22,4,2],
[89,22,4,3],
[89,22,5,0],
[89,22,5,1],
[89,22,5,2],
[89,22,5,4],
[89,22,6,0],
[89,22,6,1],
[89,22,6,2],
[89,22,6,4],
[89,22,7,0],
[89,22,7,1],
[89,22,7,2],
[89,22,7,3],
[89,22,7,4],
[89,22,7,5],
[89,22,7,6],
[89,22,8,0],
[89,22,8,1],
[89,22,8,2],
[89,22,8,4],
[89,22,8,7],
[89,22,11,0],
[89,22,11,1],
[89,22,11,2],
[89,22,11,3],
[89,22,11,4],
[89,22,11,5],
[89,22,11,6],
[89,22,11,7],
[89,22,11,8],
[89,22,12,0],
[89,22,12,1],
[89,22,12,2],
[89,22,12,3],
[89,22,12,4],
[89,22,12,5],
[89,22,12,6],
[89,22,12,7],
[89,22,12,8],
[89,22,13,0],
[89,22,13,1],
[89,22,13,2],
[89,22,13,3],
[89,22,13,4],
[89,22,13,5],
[89,22,13,6],
[89,22,13,7],
[89,22,13,8],
[89,22,13,11],
[89,22,14,0],
[89,22,14,1],
[89,22,14,2],
[89,22,14,3],
[89,22,14,4],
[89,22,14,5],
[89,22,14,6],
[89,22,14,7],
[89,22,14,8],
[89,22,14,12],
[89,22,16,0],
[89,22,16,1],
[89,22,16,2],
[89,22,16,3],
[89,22,16,4],
[89,22,16,5],
[89,22,16,8],
[89,22,16,11],
[89,22,16,12],
[89,22,16,13],
[89,22,16,14],
[89,22,17,0],
[89,22,17,1],
[89,22,17,2],
[89,22,17,3],
[89,22,17,4],
[89,22,17,5],
[89,22,17,8],
[89,22,17,11],
[89,22,17,12],
[89,22,17,13],
[89,22,17,14],
[89,22,17,16],
[89,22,19,0],
[89,22,19,1],
[89,22,19,2],
[89,22,19,5],
[89,22,19,6],
[89,22,19,7],
[89,22,19,8],
[89,22,19,11],
[89,22,19,12],
[89,22,19,16],
[89,22,19,17],
[89,22,21,0],
[89,22,21,1],
[89,22,21,2],
[89,22,21,4],
[89,22,21,7],
[89,22,21,11],
[89,22,21,12],
[89,22,21,13],
[89,22,21,14],
[89,22,21,16],
[89,22,21,17],
[89,22,21,19],
[89,23,3,0],
[89,23,3,1],
[89,23,3,2],
[89,23,4,0],
[89,23,4,1],
[89,23,4,2],
[89,23,4,3],
[89,23,5,0],
[89,23,5,1],
[89,23,5,2],
[89,23,5,4],
[89,23,6,0],
[89,23,6,1],
[89,23,6,2],
[89,23,6,4],
[89,23,7,0],
[89,23,7,1],
[89,23,7,2],
[89,23,7,3],
[89,23,7,4],
[89,23,7,5],
[89,23,7,6],
[89,23,8,0],
[89,23,8,1],
[89,23,8,2],
[89,23,8,4],
[89,23,8,7],
[89,23,10,0],
[89,23,10,1],
[89,23,10,2],
[89,23,10,3],
[89,23,10,4],
[89,23,10,5],
[89,23,10,6],
[89,23,10,7],
[89,23,10,8],
[89,23,11,0],
[89,23,11,1],
[89,23,11,2],
[89,23,11,3],
[89,23,11,4],
[89,23,11,5],
[89,23,11,6],
[89,23,11,7],
[89,23,11,8],
[89,23,11,10],
[89,23,12,0],
[89,23,12,1],
[89,23,12,2],
[89,23,12,3],
[89,23,12,4],
[89,23,12,5],
[89,23,12,6],
[89,23,12,7],
[89,23,12,8],
[89,23,13,0],
[89,23,13,1],
[89,23,13,2],
[89,23,13,3],
[89,23,13,4],
[89,23,13,5],
[89,23,13,6],
[89,23,13,7],
[89,23,13,8],
[89,23,13,12],
[89,23,14,0],
[89,23,14,1],
[89,23,14,2],
[89,23,14,3],
[89,23,14,4],
[89,23,14,5],
[89,23,14,6],
[89,23,14,7],
[89,23,14,10],
[89,23,14,11],
[89,23,14,12],
[89,23,16,0],
[89,23,16,1],
[89,23,16,2],
[89,23,16,3],
[89,23,16,4],
[89,23,16,6],
[89,23,16,7],
[89,23,16,8],
[89,23,16,10],
[89,23,16,11],
[89,23,16,12],
[89,23,16,13],
[89,23,16,14],
[89,23,17,0],
[89,23,17,1],
[89,23,17,2],
[89,23,17,3],
[89,23,17,4],
[89,23,17,6],
[89,23,17,7],
[89,23,17,8],
[89,23,17,10],
[89,23,17,11],
[89,23,17,12],
[89,23,17,13],
[89,23,17,14],
[89,23,17,16],
[89,23,19,3],
[89,23,19,4],
[89,23,19,5],
[89,23,19,6],
[89,23,19,7],
[89,23,19,8],
[89,23,19,10],
[89,23,19,11],
[89,23,19,12],
[89,23,19,16],
[89,23,19,17],
[89,23,20,0],
[89,23,20,1],
[89,23,20,2],
[89,23,20,3],
[89,23,20,4],
[89,23,20,5],
[89,23,20,6],
[89,23,20,7],
[89,23,20,8],
[89,23,20,11],
[89,23,20,12],
[89,23,20,13],
[89,23,20,14],
[89,23,20,16],
[89,23,20,17],
[89,23,20,19],
[89,23,21,0],
[89,23,21,1],
[89,23,21,2],
[89,23,21,4],
[89,23,21,7],
[89,23,21,10],
[89,23,21,11],
[89,23,21,12],
[89,23,21,13],
[89,23,21,14],
[89,23,21,16],
[89,23,21,17],
[89,23,22,0],
[89,23,22,1],
[89,23,22,2],
[89,23,22,3],
[89,23,22,4],
[89,23,22,5],
[89,23,22,6],
[89,23,22,7],
[89,23,22,8],
[89,23,22,11],
[89,23,22,12],
[89,23,22,13],
[89,23,22,14],
[89,23,22,16],
[89,23,22,17],
[89,24,3,0],
[89,24,3,1],
[89,24,3,2],
[89,24,4,0],
[89,24,4,1],
[89,24,4,2],
[89,24,4,3],
[89,24,5,0],
[89,24,5,1],
[89,24,5,2],
[89,24,5,4],
[89,24,6,0],
[89,24,6,1],
[89,24,6,2],
[89,24,6,4],
[89,24,7,0],
[89,24,7,1],
[89,24,7,2],
[89,24,7,3],
[89,24,7,4],
[89,24,7,5],
[89,24,7,6],
[89,24,8,0],
[89,24,8,1],
[89,24,8,2],
[89,24,8,4],
[89,24,8,7],
[89,24,10,0],
[89,24,10,1],
[89,24,10,2],
[89,24,10,3],
[89,24,10,4],
[89,24,10,5],
[89,24,10,6],
[89,24,10,7],
[89,24,10,8],
[89,24,11,0],
[89,24,11,1],
[89,24,11,2],
[89,24,11,3],
[89,24,11,4],
[89,24,11,5],
[89,24,11,6],
[89,24,11,7],
[89,24,11,8],
[89,24,11,10],
[89,24,12,0],
[89,24,12,1],
[89,24,12,2],
[89,24,12,3],
[89,24,12,4],
[89,24,12,5],
[89,24,12,6],
[89,24,12,7],
[89,24,12,8],
[89,24,13,0],
[89,24,13,1],
[89,24,13,2],
[89,24,13,3],
[89,24,13,4],
[89,24,13,5],
[89,24,13,6],
[89,24,13,7],
[89,24,13,8],
[89,24,13,12],
[89,24,14,0],
[89,24,14,1],
[89,24,14,2],
[89,24,14,3],
[89,24,14,4],
[89,24,14,5],
[89,24,14,6],
[89,24,14,7],
[89,24,14,10],
[89,24,14,11],
[89,24,14,12],
[89,24,17,0],
[89,24,17,1],
[89,24,17,2],
[89,24,17,3],
[89,24,17,4],
[89,24,17,6],
[89,24,17,7],
[89,24,17,8],
[89,24,17,10],
[89,24,17,11],
[89,24,17,12],
[89,24,17,13],
[89,24,17,14],
[89,24,19,3],
[89,24,19,4],
[89,24,19,5],
[89,24,19,6],
[89,24,19,7],
[89,24,19,8],
[89,24,19,10],
[89,24,19,11],
[89,24,19,12],
[89,24,19,17],
[89,24,20,0],
[89,24,20,1],
[89,24,20,2],
[89,24,20,3],
[89,24,20,4],
[89,24,20,5],
[89,24,20,6],
[89,24,20,7],
[89,24,20,8],
[89,24,20,11],
[89,24,20,12],
[89,24,20,13],
[89,24,20,14],
[89,24,20,17],
[89,24,20,19],
[89,24,21,0],
[89,24,21,1],
[89,24,21,2],
[89,24,21,4],
[89,24,21,7],
[89,24,21,10],
[89,24,21,11],
[89,24,21,12],
[89,24,21,13],
[89,24,21,14],
[89,24,21,17],
[89,24,22,0],
[89,24,22,1],
[89,24,22,2],
[89,24,22,3],
[89,24,22,4],
[89,24,22,5],
[89,24,22,6],
[89,24,22,7],
[89,24,22,8],
[89,24,22,11],
[89,24,22,12],
[89,24,22,13],
[89,24,22,14],
[89,24,22,17],
[89,24,23,0],
[89,24,23,1],
[89,24,23,2],
[89,24,23,3],
[89,24,23,4],
[89,24,23,5],
[89,24,23,6],
[89,24,23,7],
[89,24,23,8],
[89,24,23,10],
[89,24,23,11],
[89,24,23,12],
[89,24,23,13],
[89,24,23,14],
[89,25,3,0],
[89,25,3,1],
[89,25,3,2],
[89,25,4,0],
[89,25,4,1],
[89,25,4,2],
[89,25,4,3],
[89,25,5,0],
[89,25,5,1],
[89,25,5,2],
[89,25,5,4],
[89,25,6,0],
[89,25,6,1],
[89,25,6,2],
[89,25,6,4],
[89,25,8,0],
[89,25,8,1],
[89,25,8,2],
[89,25,8,4],
[89,25,10,0],
[89,25,10,1],
[89,25,10,2],
[89,25,10,3],
[89,25,10,4],
[89,25,10,5],
[89,25,10,6],
[89,25,11,0],
[89,25,11,1],
[89,25,11,2],
[89,25,11,3],
[89,25,11,4],
[89,25,11,5],
[89,25,11,6],
[89,25,11,10],
[89,25,12,0],
[89,25,12,1],
[89,25,12,2],
[89,25,12,3],
[89,25,12,4],
[89,25,12,5],
[89,25,12,8],
[89,25,12,10],
[89,25,13,0],
[89,25,13,1],
[89,25,13,2],
[89,25,13,3],
[89,25,13,4],
[89,25,13,5],
[89,25,13,8],
[89,25,13,10],
[89,25,13,11],
[89,25,13,12],
[89,25,14,0],
[89,25,14,1],
[89,25,14,2],
[89,25,14,3],
[89,25,14,4],
[89,25,14,6],
[89,25,14,8],
[89,25,14,10],
[89,25,14,11],
[89,25,14,12],
[89,25,16,3],
[89,25,16,4],
[89,25,16,5],
[89,25,16,6],
[89,25,16,8],
[89,25,16,10],
[89,25,16,11],
[89,25,16,12],
[89,25,16,13],
[89,25,16,14],
[89,25,17,3],
[89,25,17,4],
[89,25,17,5],
[89,25,17,6],
[89,25,17,8],
[89,25,17,10],
[89,25,17,11],
[89,25,17,12],
[89,25,17,13],
[89,25,17,14],
[89,25,17,16],
[89,25,19,0],
[89,25,19,1],
[89,25,19,2],
[89,25,19,3],
[89,25,19,4],
[89,25,19,5],
[89,25,19,6],
[89,25,19,8],
[89,25,19,10],
[89,25,19,11],
[89,25,19,12],
[89,25,19,16],
[89,25,19,17],
[89,25,20,0],
[89,25,20,1],
[89,25,20,2],
[89,25,20,3],
[89,25,20,4],
[89,25,20,5],
[89,25,20,6],
[89,25,20,8],
[89,25,20,11],
[89,25,20,12],
[89,25,20,13],
[89,25,20,14],
[89,25,20,16],
[89,25,20,17],
[89,25,21,0],
[89,25,21,1],
[89,25,21,2],
[89,25,21,4],
[89,25,21,10],
[89,25,21,11],
[89,25,21,12],
[89,25,21,13],
[89,25,21,14],
[89,25,22,0],
[89,25,22,1],
[89,25,22,2],
[89,25,22,3],
[89,25,22,4],
[89,25,22,5],
[89,25,22,6],
[89,25,22,8],
[89,25,22,11],
[89,25,22,12],
[89,25,22,13],
[89,25,22,14],
[89,25,23,0],
[89,25,23,1],
[89,25,23,2],
[89,25,23,3],
[89,25,23,4],
[89,25,23,5],
[89,25,23,6],
[89,25,23,8],
[89,25,23,10],
[89,25,23,11],
[89,25,23,12],
[89,25,23,13],
[89,25,24,0],
[89,25,24,1],
[89,25,24,2],
[89,25,24,3],
[89,25,24,4],
[89,25,24,5],
[89,25,24,6],
[89,25,24,8],
[89,25,24,10],
[89,25,24,11],
[89,25,24,12],
[89,25,24,13],
[89,27,3,0],
[89,27,3,1],
[89,27,3,2],
[89,27,4,0],
[89,27,4,1],
[89,27,4,2],
[89,27,4,3],
[89,27,5,0],
[89,27,5,1],
[89,27,5,2],
[89,27,5,4],
[89,27,6,0],
[89,27,6,1],
[89,27,6,2],
[89,27,6,4],
[89,27,7,0],
[89,27,7,1],
[89,27,7,2],
[89,27,7,3],
[89,27,7,4],
[89,27,7,5],
[89,27,7,6],
[89,27,8,0],
[89,27,8,1],
[89,27,8,2],
[89,27,8,4],
[89,27,11,0],
[89,27,11,1],
[89,27,11,2],
[89,27,11,3],
[89,27,11,4],
[89,27,11,6],
[89,27,11,7],
[89,27,11,8],
[89,27,12,0],
[89,27,12,1],
[89,27,12,2],
[89,27,12,5],
[89,27,12,6],
[89,27,12,7],
[89,27,12,8],
[89,27,13,0],
[89,27,13,1],
[89,27,13,2],
[89,27,13,5],
[89,27,13,6],
[89,27,13,7],
[89,27,13,8],
[89,27,13,11],
[89,27,13,12],
[89,27,14,3],
[89,27,14,4],
[89,27,14,5],
[89,27,14,6],
[89,27,14,7],
[89,27,14,8],
[89,27,14,11],
[89,27,14,12],
[89,27,16,0],
[89,27,16,1],
[89,27,16,2],
[89,27,16,3],
[89,27,16,4],
[89,27,16,5],
[89,27,16,6],
[89,27,16,7],
[89,27,16,8],
[89,27,16,11],
[89,27,16,12],
[89,27,16,13],
[89,27,16,14],
[89,27,17,0],
[89,27,17,1],
[89,27,17,2],
[89,27,17,3],
[89,27,17,4],
[89,27,17,5],
[89,27,17,6],
[89,27,17,7],
[89,27,17,8],
[89,27,17,11],
[89,27,17,12],
[89,27,17,13],
[89,27,17,14],
[89,27,17,16],
[89,27,19,0],
[89,27,19,1],
[89,27,19,2],
[89,27,19,3],
[89,27,19,4],
[89,27,19,5],
[89,27,19,6],
[89,27,19,7],
[89,27,19,8],
[89,27,19,11],
[89,27,19,12],
[89,27,21,0],
[89,27,21,1],
[89,27,21,2],
[89,27,21,4],
[89,27,21,7],
[89,27,21,11],
[89,27,21,12],
[89,27,21,13],
[89,27,23,0],
[89,27,23,1],
[89,27,23,2],
[89,27,23,3],
[89,27,23,4],
[89,27,23,5],
[89,27,23,6],
[89,27,23,7],
[89,27,23,8],
[89,27,24,0],
[89,27,24,1],
[89,27,24,2],
[89,27,24,3],
[89,27,24,4],
[89,27,24,5],
[89,27,24,6],
[89,27,24,7],
[89,27,24,8],
[89,27,25,0],
[89,27,25,1],
[89,27,25,2],
[89,27,25,3],
[89,27,25,4],
[89,27,25,5],
[89,28,3,0],
[89,28,3,1],
[89,28,3,2],
[89,28,4,0],
[89,28,4,1],
[89,28,4,2],
[89,28,4,3],
[89,28,5,0],
[89,28,5,1],
[89,28,5,2],
[89,28,5,4],
[89,28,6,0],
[89,28,6,1],
[89,28,6,2],
[89,28,6,4],
[89,28,8,0],
[89,28,8,1],
[89,28,8,2],
[89,28,8,4],
[89,28,10,0],
[89,28,10,1],
[89,28,10,2],
[89,28,10,5],
[89,28,10,6],
[89,28,10,8],
[89,28,11,0],
[89,28,11,1],
[89,28,11,2],
[89,28,11,5],
[89,28,11,6],
[89,28,11,8],
[89,28,11,10],
[89,28,12,3],
[89,28,12,4],
[89,28,12,5],
[89,28,12,6],
[89,28,12,8],
[89,28,12,10],
[89,28,13,3],
[89,28,13,4],
[89,28,13,5],
[89,28,13,6],
[89,28,13,8],
[89,28,13,10],
[89,28,13,11],
[89,28,13,12],
[89,28,14,0],
[89,28,14,1],
[89,28,14,2],
[89,28,14,3],
[89,28,14,4],
[89,28,14,5],
[89,28,14,6],
[89,28,14,8],
[89,28,14,10],
[89,28,14,11],
[89,28,14,12],
[89,28,16,0],
[89,28,16,1],
[89,28,16,2],
[89,28,16,3],
[89,28,16,4],
[89,28,16,5],
[89,28,16,6],
[89,28,16,8],
[89,28,16,10],
[89,28,16,11],
[89,28,16,12],
[89,28,16,13],
[89,28,16,14],
[89,28,17,0],
[89,28,17,1],
[89,28,17,2],
[89,28,17,3],
[89,28,17,4],
[89,28,17,5],
[89,28,17,6],
[89,28,17,8],
[89,28,17,10],
[89,28,17,11],
[89,28,17,12],
[89,28,17,13],
[89,28,17,14],
[89,28,17,16],
[89,28,19,0],
[89,28,19,1],
[89,28,19,2],
[89,28,19,3],
[89,28,19,4],
[89,28,19,5],
[89,28,19,6],
[89,28,19,8],
[89,28,19,10],
[89,28,19,11],
[89,28,19,12],
[89,28,20,0],
[89,28,20,1],
[89,28,20,2],
[89,28,20,3],
[89,28,20,4],
[89,28,20,5],
[89,28,20,6],
[89,28,20,8],
[89,28,20,11],
[89,28,20,12],
[89,28,20,13],
[89,28,21,0],
[89,28,21,1],
[89,28,21,2],
[89,28,21,4],
[89,28,21,10],
[89,28,21,11],
[89,28,22,0],
[89,28,22,1],
[89,28,22,2],
[89,28,22,3],
[89,28,22,4],
[89,28,22,5],
[89,28,22,6],
[89,28,22,8],
[89,28,23,0],
[89,28,23,1],
[89,28,23,2],
[89,28,23,3],
[89,28,23,4],
[89,28,23,5],
[89,28,23,6],
[89,28,24,0],
[89,28,24,1],
[89,28,24,2],
[89,28,24,3],
[89,28,24,4],
[89,28,24,5],
[89,28,24,6],
[89,29,3,0],
[89,29,3,1],
[89,29,3,2],
[89,29,4,0],
[89,29,4,1],
[89,29,4,2],
[89,29,4,3],
[89,29,5,0],
[89,29,5,1],
[89,29,5,2],
[89,29,5,4],
[89,29,6,0],
[89,29,6,1],
[89,29,6,2],
[89,29,6,4],
[89,29,7,0],
[89,29,7,1],
[89,29,7,2],
[89,29,7,3],
[89,29,7,4],
[89,29,7,6],
[89,29,8,0],
[89,29,8,1],
[89,29,8,2],
[89,29,8,7],
[89,29,10,3],
[89,29,10,4],
[89,29,10,5],
[89,29,10,6],
[89,29,10,7],
[89,29,10,8],
[89,29,13,0],
[89,29,13,1],
[89,29,13,2],
[89,29,13,3],
[89,29,13,4],
[89,29,13,5],
[89,29,13,6],
[89,29,13,7],
[89,29,13,8],
[89,29,13,10],
[89,29,14,0],
[89,29,14,1],
[89,29,14,2],
[89,29,14,3],
[89,29,14,4],
[89,29,14,5],
[89,29,14,6],
[89,29,14,7],
[89,29,14,8],
[89,29,14,10],
[89,29,16,0],
[89,29,16,1],
[89,29,16,2],
[89,29,16,3],
[89,29,16,4],
[89,29,16,5],
[89,29,16,6],
[89,29,16,7],
[89,29,16,8],
[89,29,16,10],
[89,29,16,13],
[89,29,16,14],
[89,29,17,0],
[89,29,17,1],
[89,29,17,2],
[89,29,17,3],
[89,29,17,4],
[89,29,17,5],
[89,29,17,6],
[89,29,17,7],
[89,29,17,8],
[89,29,17,10],
[89,29,17,13],
[89,29,17,14],
[89,29,19,0],
[89,29,19,1],
[89,29,19,2],
[89,29,19,3],
[89,29,19,4],
[89,29,19,5],
[89,29,19,6],
[89,29,19,7],
[89,29,19,8],
[89,29,19,10],
[89,29,20,0],
[89,29,20,1],
[89,29,20,2],
[89,29,20,3],
[89,29,20,4],
[89,29,20,5],
[89,29,20,6],
[89,29,20,7],
[89,29,20,8],
[89,29,21,0],
[89,29,21,1],
[89,29,21,2],
[89,29,21,4],
[89,29,21,7],
[89,29,22,0],
[89,29,22,1],
[89,29,22,2],
[89,29,22,3],
[89,29,22,4],
[89,29,22,5],
[89,29,22,6],
[89,29,22,7],
[89,29,23,0],
[89,29,23,1],
[89,29,23,2],
[89,29,23,3],
[89,29,23,4],
[89,29,23,5],
[89,29,24,0],
[89,29,24,1],
[89,29,24,2],
[89,29,24,3],
[89,29,24,4],
[89,29,24,5],
[89,29,25,0],
[89,29,25,1],
[89,29,25,2],
[89,30,3,0],
[89,30,3,1],
[89,30,3,2],
[89,30,4,0],
[89,30,4,1],
[89,30,4,2],
[89,30,4,3],
[89,30,5,0],
[89,30,5,1],
[89,30,5,2],
[89,30,5,4],
[89,30,6,0],
[89,30,6,1],
[89,30,6,2],
[89,30,7,0],
[89,30,7,1],
[89,30,7,2],
[89,30,7,5],
[89,30,7,6],
[89,30,8,4],
[89,30,8,7],
[89,30,10,0],
[89,30,10,1],
[89,30,10,2],
[89,30,10,3],
[89,30,10,4],
[89,30,10,5],
[89,30,10,6],
[89,30,10,7],
[89,30,10,8],
[89,30,11,0],
[89,30,11,1],
[89,30,11,2],
[89,30,11,3],
[89,30,11,4],
[89,30,11,5],
[89,30,11,6],
[89,30,11,7],
[89,30,11,8],
[89,30,11,10],
[89,30,12,0],
[89,30,12,1],
[89,30,12,2],
[89,30,12,3],
[89,30,12,4],
[89,30,12,5],
[89,30,12,6],
[89,30,12,7],
[89,30,12,8],
[89,30,12,10],
[89,30,13,0],
[89,30,13,1],
[89,30,13,2],
[89,30,13,3],
[89,30,13,4],
[89,30,13,5],
[89,30,13,6],
[89,30,13,7],
[89,30,13,8],
[89,30,13,10],
[89,30,13,11],
[89,30,13,12],
[89,30,14,0],
[89,30,14,1],
[89,30,14,2],
[89,30,14,3],
[89,30,14,4],
[89,30,14,5],
[89,30,14,6],
[89,30,14,7],
[89,30,14,8],
[89,30,14,10],
[89,30,14,11],
[89,30,14,12],
[89,30,16,0],
[89,30,16,1],
[89,30,16,2],
[89,30,16,3],
[89,30,16,4],
[89,30,16,5],
[89,30,16,6],
[89,30,16,7],
[89,30,16,8],
[89,30,16,10],
[89,30,16,11],
[89,30,16,12],
[89,30,16,13],
[89,30,16,14],
[89,30,17,0],
[89,30,17,1],
[89,30,17,2],
[89,30,17,3],
[89,30,17,4],
[89,30,17,5],
[89,30,17,6],
[89,30,17,7],
[89,30,17,8],
[89,30,17,10],
[89,30,17,11],
[89,30,17,12],
[89,30,17,13],
[89,30,17,14],
[89,30,19,0],
[89,30,19,1],
[89,30,19,2],
[89,30,19,3],
[89,30,19,4],
[89,30,19,5],
[89,30,19,6],
[89,30,19,7],
[89,30,19,8],
[89,30,19,10],
[89,30,19,11],
[89,30,20,0],
[89,30,20,1],
[89,30,20,2],
[89,30,20,3],
[89,30,20,4],
[89,30,20,5],
[89,30,20,6],
[89,30,20,7],
[89,30,20,8],
[89,30,21,0],
[89,30,21,1],
[89,30,21,2],
[89,30,21,4],
[89,30,21,7],
[89,30,22,0],
[89,30,22,1],
[89,30,22,2],
[89,30,22,3],
[89,30,22,4],
[89,30,22,5],
[89,30,24,0],
[89,30,24,1],
[89,30,24,2],
[89,30,24,3],
[89,30,24,4],
[89,31,3,0],
[89,31,3,1],
[89,31,3,2],
[89,31,4,0],
[89,31,4,1],
[89,31,4,2],
[89,31,4,3],
[89,31,5,0],
[89,31,5,1],
[89,31,5,2],
[89,31,6,4],
[89,31,7,3],
[89,31,7,4],
[89,31,7,5],
[89,31,7,6],
[89,31,8,0],
[89,31,8,1],
[89,31,8,2],
[89,31,8,4],
[89,31,8,7],
[89,31,10,0],
[89,31,10,1],
[89,31,10,2],
[89,31,10,3],
[89,31,10,4],
[89,31,10,5],
[89,31,10,6],
[89,31,10,7],
[89,31,10,8],
[89,31,11,0],
[89,31,11,1],
[89,31,11,2],
[89,31,11,3],
[89,31,11,4],
[89,31,11,5],
[89,31,11,6],
[89,31,11,7],
[89,31,11,8],
[89,31,11,10],
[89,31,12,0],
[89,31,12,1],
[89,31,12,2],
[89,31,12,3],
[89,31,12,4],
[89,31,12,5],
[89,31,12,6],
[89,31,12,7],
[89,31,12,8],
[89,31,12,10],
[89,31,13,0],
[89,31,13,1],
[89,31,13,2],
[89,31,13,3],
[89,31,13,4],
[89,31,13,5],
[89,31,13,6],
[89,31,13,7],
[89,31,13,8],
[89,31,13,10],
[89,31,13,11],
[89,31,13,12],
[89,31,14,0],
[89,31,14,1],
[89,31,14,2],
[89,31,14,3],
[89,31,14,4],
[89,31,14,5],
[89,31,14,6],
[89,31,14,7],
[89,31,14,8],
[89,31,14,10],
[89,31,14,11],
[89,31,14,12],
[89,31,16,0],
[89,31,16,1],
[89,31,16,2],
[89,31,16,3],
[89,31,16,4],
[89,31,16,5],
[89,31,16,6],
[89,31,16,7],
[89,31,16,8],
[89,31,16,10],
[89,31,16,11],
[89,31,16,12],
[89,31,16,13],
[89,31,17,0],
[89,31,17,1],
[89,31,17,2],
[89,31,17,3],
[89,31,17,4],
[89,31,17,5],
[89,31,17,6],
[89,31,17,7],
[89,31,17,8],
[89,31,17,10],
[89,31,17,11],
[89,31,17,12],
[89,31,17,13],
[89,31,19,0],
[89,31,19,1],
[89,31,19,2],
[89,31,19,3],
[89,31,19,4],
[89,31,19,5],
[89,31,19,6],
[89,31,19,7],
[89,31,19,8],
[89,31,20,0],
[89,31,20,1],
[89,31,20,2],
[89,31,20,3],
[89,31,20,4],
[89,31,20,5],
[89,31,20,6],
[89,31,20,7],
[89,31,21,0],
[89,31,21,1],
[89,31,21,2],
[89,31,21,4],
[89,31,22,0],
[89,31,22,1],
[89,31,22,2],
[89,31,22,3],
[89,31,22,4],
[89,31,24,0],
[89,31,24,1],
[89,31,24,2],
[89,32,3,0],
[89,32,3,1],
[89,32,3,2],
[89,32,4,0],
[89,32,4,1],
[89,32,4,2],
[89,32,5,4],
[89,32,6,0],
[89,32,6,1],
[89,32,6,2],
[89,32,6,4],
[89,32,7,0],
[89,32,7,1],
[89,32,7,2],
[89,32,7,3],
[89,32,7,4],
[89,32,7,5],
[89,32,7,6],
[89,32,8,0],
[89,32,8,1],
[89,32,8,2],
[89,32,8,4],
[89,32,8,7],
[89,32,10,0],
[89,32,10,1],
[89,32,10,2],
[89,32,10,3],
[89,32,10,4],
[89,32,10,5],
[89,32,10,6],
[89,32,10,7],
[89,32,10,8],
[89,32,13,0],
[89,32,13,1],
[89,32,13,2],
[89,32,13,3],
[89,32,13,4],
[89,32,13,5],
[89,32,13,6],
[89,32,13,7],
[89,32,13,8],
[89,32,13,10],
[89,32,14,0],
[89,32,14,1],
[89,32,14,2],
[89,32,14,3],
[89,32,14,4],
[89,32,14,5],
[89,32,14,6],
[89,32,14,7],
[89,32,14,8],
[89,32,14,10],
[89,32,16,0],
[89,32,16,1],
[89,32,16,2],
[89,32,16,3],
[89,32,16,4],
[89,32,16,5],
[89,32,16,6],
[89,32,16,7],
[89,32,16,8],
[89,32,16,10],
[89,32,17,0],
[89,32,17,1],
[89,32,17,2],
[89,32,17,3],
[89,32,17,4],
[89,32,17,5],
[89,32,17,6],
[89,32,17,7],
[89,32,17,8],
[89,32,17,10],
[89,32,19,0],
[89,32,19,1],
[89,32,19,2],
[89,32,19,3],
[89,32,19,4],
[89,32,19,5],
[89,32,19,6],
[89,32,19,7],
[89,32,20,0],
[89,32,20,1],
[89,32,20,2],
[89,32,20,3],
[89,32,20,4],
[89,32,20,5],
[89,32,21,0],
[89,32,21,1],
[89,32,21,2],
[89,32,21,4],
[89,32,22,0],
[89,32,22,1],
[89,32,22,2],
[89,33,4,3],
[89,33,5,0],
[89,33,5,1],
[89,33,5,2],
[89,33,5,4],
[89,33,6,0],
[89,33,6,1],
[89,33,6,2],
[89,33,6,4],
[89,33,7,0],
[89,33,7,1],
[89,33,7,2],
[89,33,7,3],
[89,33,7,4],
[89,33,7,5],
[89,33,7,6],
[89,33,8,0],
[89,33,8,1],
[89,33,8,2],
[89,33,8,4],
[89,33,8,7],
[89,33,10,0],
[89,33,10,1],
[89,33,10,2],
[89,33,10,3],
[89,33,10,4],
[89,33,10,5],
[89,33,10,6],
[89,33,10,7],
[89,33,10,8],
[89,33,13,0],
[89,33,13,1],
[89,33,13,2],
[89,33,13,3],
[89,33,13,4],
[89,33,13,5],
[89,33,13,6],
[89,33,13,7],
[89,33,13,8],
[89,33,13,10],
[89,33,14,0],
[89,33,14,1],
[89,33,14,2],
[89,33,14,3],
[89,33,14,4],
[89,33,14,5],
[89,33,14,6],
[89,33,14,7],
[89,33,14,8],
[89,33,14,10],
[89,33,16,0],
[89,33,16,1],
[89,33,16,2],
[89,33,16,3],
[89,33,16,4],
[89,33,16,5],
[89,33,16,6],
[89,33,16,7],
[89,33,16,8],
[89,33,17,0],
[89,33,17,1],
[89,33,17,2],
[89,33,17,3],
[89,33,17,4],
[89,33,17,5],
[89,33,17,6],
[89,33,17,7],
[89,33,17,8],
[89,33,19,0],
[89,33,19,1],
[89,33,19,2],
[89,33,19,3],
[89,33,19,4],
[89,33,19,5],
[89,33,20,0],
[89,33,20,1],
[89,33,20,2],
[89,33,20,3],
[89,33,20,4],
[89,33,21,0],
[89,33,21,1],
[89,33,21,2],
[89,34,3,0],
[89,34,3,1],
[89,34,3,2],
[89,34,4,0],
[89,34,4,1],
[89,34,4,2],
[89,34,4,3],
[89,34,5,0],
[89,34,5,1],
[89,34,5,2],
[89,34,5,4],
[89,34,6,0],
[89,34,6,1],
[89,34,6,2],
[89,34,6,4],
[89,34,8,0],
[89,34,8,1],
[89,34,8,2],
[89,34,8,4],
[89,34,10,0],
[89,34,10,1],
[89,34,10,2],
[89,34,10,3],
[89,34,10,4],
[89,34,10,5],
[89,34,10,6],
[89,34,10,8],
[89,34,11,0],
[89,34,11,1],
[89,34,11,2],
[89,34,11,3],
[89,34,11,4],
[89,34,11,5],
[89,34,11,6],
[89,34,11,8],
[89,34,11,10],
[89,34,12,0],
[89,34,12,1],
[89,34,12,2],
[89,34,12,3],
[89,34,12,4],
[89,34,12,5],
[89,34,12,6],
[89,34,12,8],
[89,34,12,10],
[89,34,13,0],
[89,34,13,1],
[89,34,13,2],
[89,34,13,3],
[89,34,13,4],
[89,34,13,5],
[89,34,13,6],
[89,34,13,8],
[89,34,13,10],
[89,34,13,11],
[89,34,13,12],
[89,34,14,0],
[89,34,14,1],
[89,34,14,2],
[89,34,14,3],
[89,34,14,4],
[89,34,14,5],
[89,34,14,6],
[89,34,14,8],
[89,34,14,10],
[89,34,14,11],
[89,34,16,0],
[89,34,16,1],
[89,34,16,2],
[89,34,16,3],
[89,34,16,4],
[89,34,16,5],
[89,34,16,6],
[89,34,17,0],
[89,34,17,1],
[89,34,17,2],
[89,34,17,3],
[89,34,17,4],
[89,34,17,5],
[89,34,17,6],
[89,34,19,0],
[89,34,19,1],
[89,34,19,2],
[89,34,19,3],
[89,34,19,4],
[89,34,20,0],
[89,34,20,1],
[89,34,20,2],
[89,36,3,0],
[89,36,3,1],
[89,36,3,2],
[89,36,4,0],
[89,36,4,1],
[89,36,4,2],
[89,36,4,3],
[89,36,5,0],
[89,36,5,1],
[89,36,5,2],
[89,36,5,4],
[89,36,6,0],
[89,36,6,1],
[89,36,6,2],
[89,36,6,4],
[89,36,7,0],
[89,36,7,1],
[89,36,7,2],
[89,36,7,3],
[89,36,7,4],
[89,36,7,5],
[89,36,7,6],
[89,36,8,0],
[89,36,8,1],
[89,36,8,2],
[89,36,8,4],
[89,36,8,7],
[89,36,10,0],
[89,36,10,1],
[89,36,10,2],
[89,36,10,3],
[89,36,10,4],
[89,36,10,5],
[89,36,10,6],
[89,36,10,7],
[89,36,10,8],
[89,36,11,0],
[89,36,11,1],
[89,36,11,2],
[89,36,11,3],
[89,36,11,4],
[89,36,11,5],
[89,36,11,6],
[89,36,11,7],
[89,36,11,8],
[89,36,11,10],
[89,36,12,0],
[89,36,12,1],
[89,36,12,2],
[89,36,12,3],
[89,36,12,4],
[89,36,12,5],
[89,36,12,6],
[89,36,12,7],
[89,36,12,8],
[89,36,16,0],
[89,36,16,1],
[89,36,16,2],
[89,36,16,3],
[89,36,16,4],
[89,36,17,0],
[89,36,17,1],
[89,36,17,2],
[89,36,17,3],
[89,36,17,4],
[89,37,3,0],
[89,37,3,1],
[89,37,3,2],
[89,37,5,0],
[89,37,5,1],
[89,37,5,2],
[89,37,6,0],
[89,37,6,1],
[89,37,6,2],
[89,37,7,0],
[89,37,7,1],
[89,37,7,2],
[89,37,7,3],
[89,37,7,5],
[89,37,7,6],
[89,37,8,0],
[89,37,8,1],
[89,37,8,2],
[89,37,8,7],
[89,37,10,0],
[89,37,10,1],
[89,37,10,2],
[89,37,10,3],
[89,37,10,5],
[89,37,10,6],
[89,37,10,7],
[89,37,10,8],
[89,37,11,0],
[89,37,11,1],
[89,37,11,2],
[89,37,11,3],
[89,37,11,5],
[89,37,11,6],
[89,37,11,7],
[89,37,11,8],
[89,37,12,0],
[89,37,12,1],
[89,37,12,2],
[89,37,12,3],
[89,37,12,5],
[89,37,12,6],
[89,37,12,7],
[89,37,13,0],
[89,37,13,1],
[89,37,13,2],
[89,37,13,3],
[89,37,13,5],
[89,37,13,6],
[89,37,13,7],
[89,37,14,0],
[89,37,14,1],
[89,37,14,2],
[89,37,14,3],
[89,37,14,5],
[89,37,16,0],
[89,37,16,1],
[89,37,16,2],
[89,37,17,0],
[89,37,17,1],
[89,37,17,2],
[89,38,3,0],
[89,38,3,1],
[89,38,3,2],
[89,38,4,0],
[89,38,4,1],
[89,38,4,2],
[89,38,4,3],
[89,38,5,0],
[89,38,5,1],
[89,38,5,2],
[89,38,5,4],
[89,38,6,0],
[89,38,6,1],
[89,38,6,2],
[89,38,6,4],
[89,38,8,0],
[89,38,8,1],
[89,38,8,2],
[89,38,8,4],
[89,38,10,0],
[89,38,10,1],
[89,38,10,2],
[89,38,10,3],
[89,38,10,4],
[89,38,10,5],
[89,38,10,6],
[89,38,11,0],
[89,38,11,1],
[89,38,11,2],
[89,38,11,3],
[89,38,11,4],
[89,38,11,5],
[89,38,11,6],
[89,38,12,0],
[89,38,12,1],
[89,38,12,2],
[89,38,12,3],
[89,38,12,4],
[89,38,12,5],
[89,38,13,0],
[89,38,13,1],
[89,38,13,2],
[89,38,13,3],
[89,38,13,4],
[89,38,13,5],
[89,38,14,0],
[89,38,14,1],
[89,38,14,2],
[89,38,14,3],
[89,38,14,4],
[89,39,4,0],
[89,39,4,1],
[89,39,4,2],
[89,39,7,0],
[89,39,7,1],
[89,39,7,2],
[89,39,7,4],
[89,39,10,0],
[89,39,10,1],
[89,39,10,2],
[89,39,10,4],
[89,39,11,0],
[89,39,11,1],
[89,39,11,2],
[89,39,11,4],
[89,39,12,0],
[89,39,12,1],
[89,39,12,2],
[89,39,12,4],
[89,39,13,0],
[89,39,13,1],
[89,39,13,2],
[89,39,13,4],
[89,39,14,0],
[89,39,14,1],
[89,39,14,2],
[89,42,3,0],
[89,42,3,1],
[89,42,3,2],
[89,42,4,0],
[89,42,4,1],
[89,42,4,2],
[89,42,4,3],
[89,42,5,0],
[89,42,5,1],
[89,42,5,2],
[89,42,5,4],
[89,42,6,0],
[89,42,6,1],
[89,42,6,2],
[89,42,6,4],
[89,42,7,0],
[89,42,7,1],
[89,42,7,2],
[89,42,7,3],
[89,42,7,4],
[89,42,7,5],
[89,42,8,0],
[89,42,8,1],
[89,42,8,2],
[89,42,8,4],
[89,42,11,0],
[89,42,11,1],
[89,42,11,2],
[89,43,3,0],
[89,43,3,1],
[89,43,3,2],
[89,43,4,0],
[89,43,4,1],
[89,43,4,2],
[89,43,4,3],
[89,43,5,0],
[89,43,5,1],
[89,43,5,2],
[89,43,5,4],
[89,43,6,0],
[89,43,6,1],
[89,43,6,2],
[89,43,6,4],
[89,43,8,0],
[89,43,8,1],
[89,43,8,2],
[89,44,3,0],
[89,44,3,1],
[89,44,3,2],
[89,44,4,0],
[89,44,4,1],
[89,44,4,2],
[89,44,4,3],
[89,44,5,0],
[89,44,5,1],
[89,44,5,2],
[89,44,5,4],
[89,44,6,0],
[89,44,6,1],
[89,44,6,2],
[89,44,7,0],
[89,44,7,1],
[89,44,7,2],
[90,4,3,0],
[90,4,3,1],
[90,4,3,2],
[90,5,4,0],
[90,5,4,1],
[90,5,4,2],
[90,6,4,0],
[90,6,4,1],
[90,6,4,2],
[90,7,3,0],
[90,7,3,1],
[90,7,3,2],
[90,7,4,0],
[90,7,4,1],
[90,7,4,2],
[90,7,4,3],
[90,7,5,0],
[90,7,5,1],
[90,7,5,2],
[90,7,5,4],
[90,7,6,0],
[90,7,6,1],
[90,7,6,2],
[90,7,6,4],
[90,8,4,0],
[90,8,4,1],
[90,8,4,2],
[90,8,7,0],
[90,8,7,1],
[90,8,7,2],
[90,8,7,4],
[90,9,3,0],
[90,9,3,1],
[90,9,3,2],
[90,9,4,0],
[90,9,4,1],
[90,9,4,2],
[90,9,4,3],
[90,9,5,0],
[90,9,5,1],
[90,9,5,2],
[90,9,5,4],
[90,9,6,0],
[90,9,6,1],
[90,9,6,2],
[90,9,6,4],
[90,9,7,0],
[90,9,7,1],
[90,9,7,2],
[90,9,7,3],
[90,9,7,4],
[90,9,7,5],
[90,9,7,6],
[90,9,8,0],
[90,9,8,1],
[90,9,8,2],
[90,9,8,4],
[90,9,8,7],
[90,11,3,0],
[90,11,3,1],
[90,11,3,2],
[90,11,4,0],
[90,11,4,1],
[90,11,4,2],
[90,11,4,3],
[90,11,5,0],
[90,11,5,1],
[90,11,5,2],
[90,11,5,4],
[90,11,6,0],
[90,11,6,1],
[90,11,6,2],
[90,11,6,4],
[90,11,7,0],
[90,11,7,1],
[90,11,7,2],
[90,11,7,3],
[90,11,7,4],
[90,11,7,5],
[90,11,7,6],
[90,11,8,0],
[90,11,8,1],
[90,11,8,2],
[90,11,8,4],
[90,11,8,7],
[90,11,9,0],
[90,11,9,1],
[90,11,9,2],
[90,11,9,3],
[90,11,9,4],
[90,11,9,5],
[90,11,9,6],
[90,11,9,7],
[90,11,9,8],
[90,12,3,0],
[90,12,3,1],
[90,12,3,2],
[90,12,4,0],
[90,12,4,1],
[90,12,4,2],
[90,12,4,3],
[90,12,5,0],
[90,12,5,1],
[90,12,5,2],
[90,12,5,4],
[90,12,6,0],
[90,12,6,1],
[90,12,6,2],
[90,12,6,4],
[90,12,7,0],
[90,12,7,1],
[90,12,7,2],
[90,12,7,3],
[90,12,7,4],
[90,12,7,5],
[90,12,7,6],
[90,12,8,0],
[90,12,8,1],
[90,12,8,2],
[90,12,8,4],
[90,12,8,7],
[90,12,9,0],
[90,12,9,1],
[90,12,9,2],
[90,12,9,3],
[90,12,9,4],
[90,12,9,5],
[90,12,9,6],
[90,12,9,7],
[90,12,9,8],
[90,13,3,0],
[90,13,3,1],
[90,13,3,2],
[90,13,4,0],
[90,13,4,1],
[90,13,4,2],
[90,13,4,3],
[90,13,5,0],
[90,13,5,1],
[90,13,5,2],
[90,13,5,4],
[90,13,6,0],
[90,13,6,1],
[90,13,6,2],
[90,13,6,4],
[90,13,7,0],
[90,13,7,1],
[90,13,7,2],
[90,13,7,3],
[90,13,7,4],
[90,13,7,5],
[90,13,7,6],
[90,13,8,0],
[90,13,8,1],
[90,13,8,2],
[90,13,8,4],
[90,13,8,7],
[90,13,9,0],
[90,13,9,1],
[90,13,9,2],
[90,13,9,3],
[90,13,9,4],
[90,13,9,5],
[90,13,9,6],
[90,13,9,7],
[90,13,9,8],
[90,13,11,0],
[90,13,11,1],
[90,13,11,2],
[90,13,11,3],
[90,13,11,4],
[90,13,11,5],
[90,13,11,6],
[90,13,11,7],
[90,13,11,8],
[90,13,11,9],
[90,13,12,0],
[90,13,12,1],
[90,13,12,2],
[90,13,12,3],
[90,13,12,4],
[90,13,12,5],
[90,13,12,6],
[90,13,12,7],
[90,13,12,8],
[90,13,12,9],
[90,14,3,0],
[90,14,3,1],
[90,14,3,2],
[90,14,4,0],
[90,14,4,1],
[90,14,4,2],
[90,14,4,3],
[90,14,5,0],
[90,14,5,1],
[90,14,5,2],
[90,14,5,4],
[90,14,6,0],
[90,14,6,1],
[90,14,6,2],
[90,14,6,4],
[90,14,7,0],
[90,14,7,1],
[90,14,7,2],
[90,14,7,3],
[90,14,7,4],
[90,14,7,5],
[90,14,7,6],
[90,14,8,0],
[90,14,8,1],
[90,14,8,2],
[90,14,8,4],
[90,14,8,7],
[90,14,9,0],
[90,14,9,1],
[90,14,9,2],
[90,14,9,3],
[90,14,9,4],
[90,14,9,5],
[90,14,9,6],
[90,14,9,7],
[90,14,9,8],
[90,14,11,0],
[90,14,11,1],
[90,14,11,2],
[90,14,11,3],
[90,14,11,4],
[90,14,11,5],
[90,14,11,6],
[90,14,11,7],
[90,14,11,8],
[90,14,11,9],
[90,14,12,0],
[90,14,12,1],
[90,14,12,2],
[90,14,12,3],
[90,14,12,4],
[90,14,12,5],
[90,14,12,6],
[90,14,12,7],
[90,14,12,8],
[90,14,12,9],
[90,15,3,0],
[90,15,3,1],
[90,15,3,2],
[90,15,4,0],
[90,15,4,1],
[90,15,4,2],
[90,15,4,3],
[90,15,5,0],
[90,15,5,1],
[90,15,5,2],
[90,15,5,4],
[90,15,6,0],
[90,15,6,1],
[90,15,6,2],
[90,15,6,4],
[90,15,7,0],
[90,15,7,1],
[90,15,7,2],
[90,15,7,3],
[90,15,7,4],
[90,15,7,5],
[90,15,7,6],
[90,15,8,0],
[90,15,8,1],
[90,15,8,2],
[90,15,8,4],
[90,15,8,7],
[90,15,11,0],
[90,15,11,1],
[90,15,11,2],
[90,15,11,3],
[90,15,11,4],
[90,15,11,5],
[90,15,11,6],
[90,15,11,7],
[90,15,11,8],
[90,15,12,0],
[90,15,12,1],
[90,15,12,2],
[90,15,12,3],
[90,15,12,4],
[90,15,12,5],
[90,15,12,6],
[90,15,12,7],
[90,15,12,8],
[90,15,13,0],
[90,15,13,1],
[90,15,13,2],
[90,15,13,3],
[90,15,13,4],
[90,15,13,5],
[90,15,13,6],
[90,15,13,7],
[90,15,13,8],
[90,15,13,11],
[90,15,13,12],
[90,15,14,0],
[90,15,14,1],
[90,15,14,2],
[90,15,14,3],
[90,15,14,4],
[90,15,14,5],
[90,15,14,6],
[90,15,14,7],
[90,15,14,8],
[90,15,14,11],
[90,15,14,12],
[90,16,3,0],
[90,16,3,1],
[90,16,3,2],
[90,16,4,0],
[90,16,4,1],
[90,16,4,2],
[90,16,4,3],
[90,16,5,0],
[90,16,5,1],
[90,16,5,2],
[90,16,5,4],
[90,16,6,0],
[90,16,6,1],
[90,16,6,2],
[90,16,6,4],
[90,16,7,0],
[90,16,7,1],
[90,16,7,2],
[90,16,7,3],
[90,16,7,4],
[90,16,7,5],
[90,16,7,6],
[90,16,8,0],
[90,16,8,1],
[90,16,8,2],
[90,16,8,4],
[90,16,8,7],
[90,16,9,0],
[90,16,9,1],
[90,16,9,2],
[90,16,9,3],
[90,16,9,4],
[90,16,9,5],
[90,16,9,6],
[90,16,9,7],
[90,16,9,8],
[90,16,11,0],
[90,16,11,1],
[90,16,11,2],
[90,16,11,3],
[90,16,11,4],
[90,16,11,5],
[90,16,11,6],
[90,16,11,7],
[90,16,11,8],
[90,16,11,9],
[90,16,12,0],
[90,16,12,1],
[90,16,12,2],
[90,16,12,3],
[90,16,12,4],
[90,16,12,5],
[90,16,12,6],
[90,16,12,7],
[90,16,12,8],
[90,16,12,9],
[90,16,13,0],
[90,16,13,1],
[90,16,13,2],
[90,16,13,3],
[90,16,13,4],
[90,16,13,5],
[90,16,13,6],
[90,16,13,7],
[90,16,13,8],
[90,16,13,9],
[90,16,13,11],
[90,16,13,12],
[90,16,14,0],
[90,16,14,1],
[90,16,14,2],
[90,16,14,3],
[90,16,14,4],
[90,16,14,5],
[90,16,14,6],
[90,16,14,7],
[90,16,14,8],
[90,16,14,9],
[90,16,14,11],
[90,16,14,12],
[90,16,15,0],
[90,16,15,1],
[90,16,15,2],
[90,16,15,3],
[90,16,15,4],
[90,16,15,5],
[90,16,15,6],
[90,16,15,7],
[90,16,15,8],
[90,16,15,11],
[90,16,15,12],
[90,16,15,13],
[90,16,15,14],
[90,17,3,0],
[90,17,3,1],
[90,17,3,2],
[90,17,4,0],
[90,17,4,1],
[90,17,4,2],
[90,17,4,3],
[90,17,5,0],
[90,17,5,1],
[90,17,5,2],
[90,17,5,4],
[90,17,6,0],
[90,17,6,1],
[90,17,6,2],
[90,17,6,4],
[90,17,7,0],
[90,17,7,1],
[90,17,7,2],
[90,17,7,3],
[90,17,7,4],
[90,17,7,5],
[90,17,7,6],
[90,17,8,0],
[90,17,8,1],
[90,17,8,2],
[90,17,8,4],
[90,17,8,7],
[90,17,9,0],
[90,17,9,1],
[90,17,9,2],
[90,17,9,3],
[90,17,9,4],
[90,17,9,5],
[90,17,9,6],
[90,17,9,7],
[90,17,9,8],
[90,17,11,0],
[90,17,11,1],
[90,17,11,2],
[90,17,11,3],
[90,17,11,4],
[90,17,11,5],
[90,17,11,6],
[90,17,11,7],
[90,17,11,8],
[90,17,11,9],
[90,17,12,0],
[90,17,12,1],
[90,17,12,2],
[90,17,12,3],
[90,17,12,4],
[90,17,12,5],
[90,17,12,6],
[90,17,12,7],
[90,17,12,8],
[90,17,12,9],
[90,17,13,0],
[90,17,13,1],
[90,17,13,2],
[90,17,13,3],
[90,17,13,4],
[90,17,13,5],
[90,17,13,6],
[90,17,13,7],
[90,17,13,8],
[90,17,13,9],
[90,17,13,11],
[90,17,13,12],
[90,17,14,0],
[90,17,14,1],
[90,17,14,2],
[90,17,14,3],
[90,17,14,4],
[90,17,14,5],
[90,17,14,6],
[90,17,14,7],
[90,17,14,8],
[90,17,14,9],
[90,17,14,11],
[90,17,14,12],
[90,17,15,0],
[90,17,15,1],
[90,17,15,2],
[90,17,15,3],
[90,17,15,4],
[90,17,15,5],
[90,17,15,6],
[90,17,15,7],
[90,17,15,8],
[90,17,15,11],
[90,17,15,12],
[90,17,15,13],
[90,17,15,14],
[90,17,16,0],
[90,17,16,1],
[90,17,16,2],
[90,17,16,3],
[90,17,16,4],
[90,17,16,5],
[90,17,16,6],
[90,17,16,7],
[90,17,16,8],
[90,17,16,9],
[90,17,16,11],
[90,17,16,12],
[90,17,16,13],
[90,17,16,14],
[90,18,3,0],
[90,18,3,1],
[90,18,3,2],
[90,18,4,0],
[90,18,4,1],
[90,18,4,2],
[90,18,4,3],
[90,18,5,0],
[90,18,5,1],
[90,18,5,2],
[90,18,5,4],
[90,18,6,0],
[90,18,6,1],
[90,18,6,2],
[90,18,6,4],
[90,18,7,0],
[90,18,7,1],
[90,18,7,2],
[90,18,7,3],
[90,18,7,4],
[90,18,7,5],
[90,18,7,6],
[90,18,8,0],
[90,18,8,1],
[90,18,8,2],
[90,18,8,4],
[90,18,8,7],
[90,18,11,0],
[90,18,11,1],
[90,18,11,2],
[90,18,11,3],
[90,18,11,4],
[90,18,11,5],
[90,18,11,6],
[90,18,11,7],
[90,18,11,8],
[90,18,12,0],
[90,18,12,1],
[90,18,12,2],
[90,18,12,3],
[90,18,12,4],
[90,18,12,5],
[90,18,12,6],
[90,18,12,7],
[90,18,12,8],
[90,18,13,0],
[90,18,13,1],
[90,18,13,2],
[90,18,13,3],
[90,18,13,4],
[90,18,13,5],
[90,18,13,6],
[90,18,13,7],
[90,18,13,8],
[90,18,13,11],
[90,18,13,12],
[90,18,14,0],
[90,18,14,1],
[90,18,14,2],
[90,18,14,3],
[90,18,14,4],
[90,18,14,5],
[90,18,14,6],
[90,18,14,7],
[90,18,14,8],
[90,18,14,11],
[90,18,14,12],
[90,18,16,0],
[90,18,16,1],
[90,18,16,2],
[90,18,16,3],
[90,18,16,4],
[90,18,16,5],
[90,18,16,6],
[90,18,16,7],
[90,18,16,8],
[90,18,16,11],
[90,18,16,12],
[90,18,16,13],
[90,18,17,0],
[90,18,17,1],
[90,18,17,2],
[90,18,17,3],
[90,18,17,4],
[90,18,17,5],
[90,18,17,6],
[90,18,17,7],
[90,18,17,8],
[90,18,17,11],
[90,18,17,12],
[90,18,17,13],
[90,18,17,16],
[90,19,3,0],
[90,19,3,1],
[90,19,3,2],
[90,19,4,0],
[90,19,4,1],
[90,19,4,2],
[90,19,4,3],
[90,19,5,0],
[90,19,5,1],
[90,19,5,2],
[90,19,5,4],
[90,19,6,0],
[90,19,6,1],
[90,19,6,2],
[90,19,6,4],
[90,19,7,0],
[90,19,7,1],
[90,19,7,2],
[90,19,7,3],
[90,19,7,4],
[90,19,7,5],
[90,19,7,6],
[90,19,8,0],
[90,19,8,1],
[90,19,8,2],
[90,19,8,4],
[90,19,8,7],
[90,19,9,0],
[90,19,9,1],
[90,19,9,2],
[90,19,9,3],
[90,19,9,4],
[90,19,9,5],
[90,19,9,6],
[90,19,9,7],
[90,19,9,8],
[90,19,11,0],
[90,19,11,1],
[90,19,11,2],
[90,19,11,3],
[90,19,11,4],
[90,19,11,5],
[90,19,11,6],
[90,19,11,7],
[90,19,11,8],
[90,19,11,9],
[90,19,12,0],
[90,19,12,1],
[90,19,12,2],
[90,19,12,3],
[90,19,12,4],
[90,19,12,5],
[90,19,12,6],
[90,19,12,7],
[90,19,12,8],
[90,19,12,9],
[90,19,15,0],
[90,19,15,1],
[90,19,15,2],
[90,19,15,3],
[90,19,15,4],
[90,19,15,5],
[90,19,15,6],
[90,19,15,7],
[90,19,15,8],
[90,19,15,11],
[90,19,15,12],
[90,19,16,0],
[90,19,16,1],
[90,19,16,2],
[90,19,16,3],
[90,19,16,4],
[90,19,16,5],
[90,19,16,6],
[90,19,16,7],
[90,19,16,8],
[90,19,16,9],
[90,19,16,11],
[90,19,16,15],
[90,19,17,0],
[90,19,17,1],
[90,19,17,2],
[90,19,17,3],
[90,19,17,4],
[90,19,17,5],
[90,19,17,6],
[90,19,17,7],
[90,19,17,8],
[90,19,17,9],
[90,19,17,11],
[90,19,17,15],
[90,19,17,16],
[90,19,18,0],
[90,19,18,1],
[90,19,18,2],
[90,19,18,3],
[90,19,18,4],
[90,19,18,5],
[90,19,18,6],
[90,19,18,7],
[90,19,18,8],
[90,19,18,12],
[90,19,18,16],
[90,19,18,17],
[90,21,4,0],
[90,21,4,1],
[90,21,4,2],
[90,21,7,0],
[90,21,7,1],
[90,21,7,2],
[90,21,7,4],
[90,21,9,0],
[90,21,9,1],
[90,21,9,2],
[90,21,9,4],
[90,21,9,7],
[90,21,11,0],
[90,21,11,1],
[90,21,11,2],
[90,21,11,4],
[90,21,11,7],
[90,21,11,9],
[90,21,12,0],
[90,21,12,1],
[90,21,12,2],
[90,21,12,4],
[90,21,12,7],
[90,21,12,9],
[90,21,13,0],
[90,21,13,1],
[90,21,13,2],
[90,21,13,4],
[90,21,13,7],
[90,21,13,9],
[90,21,13,11],
[90,21,13,12],
[90,21,14,0],
[90,21,14,1],
[90,21,14,2],
[90,21,14,4],
[90,21,14,7],
[90,21,14,9],
[90,21,14,11],
[90,21,15,0],
[90,21,15,1],
[90,21,15,2],
[90,21,15,4],
[90,21,15,7],
[90,21,15,12],
[90,21,15,13],
[90,21,15,14],
[90,21,16,0],
[90,21,16,1],
[90,21,16,2],
[90,21,16,4],
[90,21,16,7],
[90,21,16,11],
[90,21,16,12],
[90,21,16,13],
[90,21,16,14],
[90,21,16,15],
[90,21,17,0],
[90,21,17,1],
[90,21,17,2],
[90,21,17,4],
[90,21,17,7],
[90,21,17,11],
[90,21,17,12],
[90,21,17,13],
[90,21,17,14],
[90,21,17,15],
[90,21,17,16],
[90,21,18,0],
[90,21,18,1],
[90,21,18,2],
[90,21,18,4],
[90,21,18,11],
[90,21,18,12],
[90,21,18,13],
[90,21,18,14],
[90,21,18,16],
[90,21,18,17],
[90,21,19,0],
[90,21,19,1],
[90,21,19,2],
[90,21,19,4],
[90,21,19,7],
[90,21,19,9],
[90,21,19,11],
[90,21,19,12],
[90,21,19,15],
[90,21,19,16],
[90,21,19,17],
[90,21,19,18],
[90,23,3,0],
[90,23,3,1],
[90,23,3,2],
[90,23,4,0],
[90,23,4,1],
[90,23,4,2],
[90,23,4,3],
[90,23,5,0],
[90,23,5,1],
[90,23,5,2],
[90,23,5,4],
[90,23,6,0],
[90,23,6,1],
[90,23,6,2],
[90,23,6,4],
[90,23,7,0],
[90,23,7,1],
[90,23,7,2],
[90,23,7,3],
[90,23,7,4],
[90,23,7,5],
[90,23,7,6],
[90,23,8,0],
[90,23,8,1],
[90,23,8,2],
[90,23,8,4],
[90,23,8,7],
[90,23,9,0],
[90,23,9,1],
[90,23,9,2],
[90,23,9,3],
[90,23,9,4],
[90,23,9,5],
[90,23,9,6],
[90,23,9,7],
[90,23,9,8],
[90,23,11,0],
[90,23,11,1],
[90,23,11,2],
[90,23,11,3],
[90,23,11,4],
[90,23,11,5],
[90,23,11,6],
[90,23,11,7],
[90,23,11,8],
[90,23,11,9],
[90,23,12,0],
[90,23,12,1],
[90,23,12,2],
[90,23,12,3],
[90,23,12,4],
[90,23,12,5],
[90,23,12,6],
[90,23,12,7],
[90,23,12,8],
[90,23,12,9],
[90,23,13,0],
[90,23,13,1],
[90,23,13,2],
[90,23,13,3],
[90,23,13,4],
[90,23,13,5],
[90,23,13,6],
[90,23,13,7],
[90,23,13,8],
[90,23,13,9],
[90,23,13,12],
[90,23,14,0],
[90,23,14,1],
[90,23,14,2],
[90,23,14,3],
[90,23,14,4],
[90,23,14,5],
[90,23,14,6],
[90,23,14,7],
[90,23,14,11],
[90,23,14,12],
[90,23,15,0],
[90,23,15,1],
[90,23,15,2],
[90,23,15,3],
[90,23,15,4],
[90,23,15,5],
[90,23,15,8],
[90,23,15,11],
[90,23,15,12],
[90,23,15,13],
[90,23,15,14],
[90,23,16,0],
[90,23,16,1],
[90,23,16,2],
[90,23,16,3],
[90,23,16,4],
[90,23,16,6],
[90,23,16,7],
[90,23,16,8],
[90,23,16,9],
[90,23,16,11],
[90,23,16,12],
[90,23,16,13],
[90,23,16,14],
[90,23,16,15],
[90,23,17,0],
[90,23,17,1],
[90,23,17,2],
[90,23,17,3],
[90,23,17,4],
[90,23,17,6],
[90,23,17,7],
[90,23,17,8],
[90,23,17,9],
[90,23,17,11],
[90,23,17,12],
[90,23,17,13],
[90,23,17,14],
[90,23,17,15],
[90,23,17,16],
[90,23,18,0],
[90,23,18,1],
[90,23,18,2],
[90,23,18,5],
[90,23,18,6],
[90,23,18,7],
[90,23,18,8],
[90,23,18,11],
[90,23,18,12],
[90,23,18,13],
[90,23,18,14],
[90,23,18,16],
[90,23,18,17],
[90,23,19,3],
[90,23,19,4],
[90,23,19,5],
[90,23,19,6],
[90,23,19,7],
[90,23,19,8],
[90,23,19,9],
[90,23,19,11],
[90,23,19,12],
[90,23,19,15],
[90,23,19,16],
[90,23,19,17],
[90,23,19,18],
[90,23,21,0],
[90,23,21,1],
[90,23,21,2],
[90,23,21,4],
[90,23,21,7],
[90,23,21,9],
[90,23,21,11],
[90,23,21,12],
[90,23,21,13],
[90,23,21,14],
[90,23,21,15],
[90,23,21,16],
[90,23,21,17],
[90,23,21,18],
[90,24,3,0],
[90,24,3,1],
[90,24,3,2],
[90,24,4,0],
[90,24,4,1],
[90,24,4,2],
[90,24,4,3],
[90,24,5,0],
[90,24,5,1],
[90,24,5,2],
[90,24,5,4],
[90,24,6,0],
[90,24,6,1],
[90,24,6,2],
[90,24,6,4],
[90,24,7,0],
[90,24,7,1],
[90,24,7,2],
[90,24,7,3],
[90,24,7,4],
[90,24,7,5],
[90,24,7,6],
[90,24,8,0],
[90,24,8,1],
[90,24,8,2],
[90,24,8,4],
[90,24,8,7],
[90,24,9,0],
[90,24,9,1],
[90,24,9,2],
[90,24,9,3],
[90,24,9,4],
[90,24,9,5],
[90,24,9,6],
[90,24,9,7],
[90,24,9,8],
[90,24,11,0],
[90,24,11,1],
[90,24,11,2],
[90,24,11,3],
[90,24,11,4],
[90,24,11,5],
[90,24,11,6],
[90,24,11,7],
[90,24,11,8],
[90,24,11,9],
[90,24,12,0],
[90,24,12,1],
[90,24,12,2],
[90,24,12,3],
[90,24,12,4],
[90,24,12,5],
[90,24,12,6],
[90,24,12,7],
[90,24,12,8],
[90,24,12,9],
[90,24,13,0],
[90,24,13,1],
[90,24,13,2],
[90,24,13,3],
[90,24,13,4],
[90,24,13,5],
[90,24,13,6],
[90,24,13,7],
[90,24,13,8],
[90,24,13,9],
[90,24,13,12],
[90,24,14,0],
[90,24,14,1],
[90,24,14,2],
[90,24,14,3],
[90,24,14,4],
[90,24,14,5],
[90,24,14,6],
[90,24,14,7],
[90,24,14,11],
[90,24,14,12],
[90,24,15,0],
[90,24,15,1],
[90,24,15,2],
[90,24,15,3],
[90,24,15,4],
[90,24,15,5],
[90,24,15,8],
[90,24,15,11],
[90,24,15,12],
[90,24,15,13],
[90,24,15,14],
[90,24,17,0],
[90,24,17,1],
[90,24,17,2],
[90,24,17,3],
[90,24,17,4],
[90,24,17,6],
[90,24,17,7],
[90,24,17,8],
[90,24,17,9],
[90,24,17,11],
[90,24,17,12],
[90,24,17,13],
[90,24,17,14],
[90,24,17,15],
[90,24,18,0],
[90,24,18,1],
[90,24,18,2],
[90,24,18,5],
[90,24,18,6],
[90,24,18,7],
[90,24,18,8],
[90,24,18,11],
[90,24,18,12],
[90,24,18,13],
[90,24,18,14],
[90,24,18,17],
[90,24,19,3],
[90,24,19,4],
[90,24,19,5],
[90,24,19,6],
[90,24,19,7],
[90,24,19,8],
[90,24,19,9],
[90,24,19,11],
[90,24,19,12],
[90,24,19,15],
[90,24,19,17],
[90,24,19,18],
[90,24,21,0],
[90,24,21,1],
[90,24,21,2],
[90,24,21,4],
[90,24,21,7],
[90,24,21,9],
[90,24,21,11],
[90,24,21,12],
[90,24,21,13],
[90,24,21,14],
[90,24,21,15],
[90,24,21,17],
[90,24,21,18],
[90,24,23,0],
[90,24,23,1],
[90,24,23,2],
[90,24,23,3],
[90,24,23,4],
[90,24,23,5],
[90,24,23,6],
[90,24,23,7],
[90,24,23,8],
[90,24,23,9],
[90,24,23,11],
[90,24,23,12],
[90,24,23,13],
[90,24,23,14],
[90,24,23,15],
[90,25,3,0],
[90,25,3,1],
[90,25,3,2],
[90,25,4,0],
[90,25,4,1],
[90,25,4,2],
[90,25,4,3],
[90,25,5,0],
[90,25,5,1],
[90,25,5,2],
[90,25,5,4],
[90,25,6,0],
[90,25,6,1],
[90,25,6,2],
[90,25,6,4],
[90,25,8,0],
[90,25,8,1],
[90,25,8,2],
[90,25,8,4],
[90,25,9,0],
[90,25,9,1],
[90,25,9,2],
[90,25,9,3],
[90,25,9,4],
[90,25,9,5],
[90,25,9,6],
[90,25,9,8],
[90,25,11,0],
[90,25,11,1],
[90,25,11,2],
[90,25,11,3],
[90,25,11,4],
[90,25,11,5],
[90,25,11,6],
[90,25,12,0],
[90,25,12,1],
[90,25,12,2],
[90,25,12,3],
[90,25,12,4],
[90,25,12,5],
[90,25,12,8],
[90,25,12,9],
[90,25,13,0],
[90,25,13,1],
[90,25,13,2],
[90,25,13,3],
[90,25,13,4],
[90,25,13,5],
[90,25,13,8],
[90,25,13,9],
[90,25,13,11],
[90,25,13,12],
[90,25,14,0],
[90,25,14,1],
[90,25,14,2],
[90,25,14,3],
[90,25,14,4],
[90,25,14,6],
[90,25,14,8],
[90,25,14,9],
[90,25,14,11],
[90,25,14,12],
[90,25,15,0],
[90,25,15,1],
[90,25,15,2],
[90,25,15,5],
[90,25,15,6],
[90,25,15,8],
[90,25,15,11],
[90,25,15,12],
[90,25,15,13],
[90,25,15,14],
[90,25,16,3],
[90,25,16,4],
[90,25,16,5],
[90,25,16,6],
[90,25,16,8],
[90,25,16,9],
[90,25,16,11],
[90,25,16,12],
[90,25,16,13],
[90,25,16,14],
[90,25,16,15],
[90,25,17,3],
[90,25,17,4],
[90,25,17,5],
[90,25,17,6],
[90,25,17,8],
[90,25,17,9],
[90,25,17,11],
[90,25,17,12],
[90,25,17,13],
[90,25,17,14],
[90,25,17,15],
[90,25,17,16],
[90,25,18,0],
[90,25,18,1],
[90,25,18,2],
[90,25,18,3],
[90,25,18,4],
[90,25,18,5],
[90,25,18,6],
[90,25,18,8],
[90,25,18,11],
[90,25,18,12],
[90,25,18,13],
[90,25,18,14],
[90,25,18,16],
[90,25,18,17],
[90,25,19,0],
[90,25,19,1],
[90,25,19,2],
[90,25,19,3],
[90,25,19,4],
[90,25,19,5],
[90,25,19,6],
[90,25,19,8],
[90,25,19,9],
[90,25,19,11],
[90,25,19,12],
[90,25,19,15],
[90,25,19,16],
[90,25,19,17],
[90,25,19,18],
[90,25,21,0],
[90,25,21,1],
[90,25,21,2],
[90,25,21,4],
[90,25,21,9],
[90,25,21,11],
[90,25,21,12],
[90,25,21,13],
[90,25,21,14],
[90,25,21,15],
[90,25,23,0],
[90,25,23,1],
[90,25,23,2],
[90,25,23,3],
[90,25,23,4],
[90,25,23,5],
[90,25,23,6],
[90,25,23,8],
[90,25,23,9],
[90,25,23,11],
[90,25,23,12],
[90,25,23,13],
[90,25,24,0],
[90,25,24,1],
[90,25,24,2],
[90,25,24,3],
[90,25,24,4],
[90,25,24,5],
[90,25,24,6],
[90,25,24,8],
[90,25,24,9],
[90,25,24,11],
[90,25,24,12],
[90,25,24,13],
[90,26,3,0],
[90,26,3,1],
[90,26,3,2],
[90,26,4,0],
[90,26,4,1],
[90,26,4,2],
[90,26,4,3],
[90,26,5,0],
[90,26,5,1],
[90,26,5,2],
[90,26,5,4],
[90,26,6,0],
[90,26,6,1],
[90,26,6,2],
[90,26,6,4],
[90,26,7,0],
[90,26,7,1],
[90,26,7,2],
[90,26,7,3],
[90,26,7,4],
[90,26,7,5],
[90,26,7,6],
[90,26,8,0],
[90,26,8,1],
[90,26,8,2],
[90,26,8,4],
[90,26,8,7],
[90,26,11,0],
[90,26,11,1],
[90,26,11,2],
[90,26,11,3],
[90,26,11,4],
[90,26,11,5],
[90,26,11,8],
[90,26,12,0],
[90,26,12,1],
[90,26,12,2],
[90,26,12,3],
[90,26,12,4],
[90,26,12,6],
[90,26,12,7],
[90,26,12,8],
[90,26,13,0],
[90,26,13,1],
[90,26,13,2],
[90,26,13,3],
[90,26,13,4],
[90,26,13,6],
[90,26,13,7],
[90,26,13,8],
[90,26,13,11],
[90,26,13,12],
[90,26,14,0],
[90,26,14,1],
[90,26,14,2],
[90,26,14,5],
[90,26,14,6],
[90,26,14,7],
[90,26,14,8],
[90,26,14,11],
[90,26,14,12],
[90,26,16,0],
[90,26,16,1],
[90,26,16,2],
[90,26,16,3],
[90,26,16,4],
[90,26,16,5],
[90,26,16,6],
[90,26,16,7],
[90,26,16,8],
[90,26,16,11],
[90,26,16,12],
[90,26,16,13],
[90,26,16,14],
[90,26,17,0],
[90,26,17,1],
[90,26,17,2],
[90,26,17,3],
[90,26,17,4],
[90,26,17,5],
[90,26,17,6],
[90,26,17,7],
[90,26,17,8],
[90,26,17,11],
[90,26,17,12],
[90,26,17,13],
[90,26,17,14],
[90,26,17,16],
[90,26,19,0],
[90,26,19,1],
[90,26,19,2],
[90,26,19,3],
[90,26,19,4],
[90,26,19,5],
[90,26,19,6],
[90,26,19,7],
[90,26,19,8],
[90,26,19,11],
[90,26,19,12],
[90,26,19,16],
[90,26,19,17],
[90,26,21,0],
[90,26,21,1],
[90,26,21,2],
[90,26,21,4],
[90,26,21,7],
[90,26,21,11],
[90,26,21,12],
[90,26,21,13],
[90,26,21,14],
[90,26,23,0],
[90,26,23,1],
[90,26,23,2],
[90,26,23,3],
[90,26,23,4],
[90,26,23,5],
[90,26,23,6],
[90,26,23,7],
[90,26,23,8],
[90,26,23,11],
[90,26,24,0],
[90,26,24,1],
[90,26,24,2],
[90,26,24,3],
[90,26,24,4],
[90,26,24,5],
[90,26,24,6],
[90,26,24,7],
[90,26,24,8],
[90,26,24,11],
[90,26,25,0],
[90,26,25,1],
[90,26,25,2],
[90,26,25,3],
[90,26,25,4],
[90,26,25,5],
[90,26,25,6],
[90,28,3,0],
[90,28,3,1],
[90,28,3,2],
[90,28,4,0],
[90,28,4,1],
[90,28,4,2],
[90,28,4,3],
[90,28,5,0],
[90,28,5,1],
[90,28,5,2],
[90,28,5,4],
[90,28,6,0],
[90,28,6,1],
[90,28,6,2],
[90,28,6,4],
[90,28,8,0],
[90,28,8,1],
[90,28,8,2],
[90,28,8,4],
[90,28,9,0],
[90,28,9,1],
[90,28,9,2],
[90,28,9,3],
[90,28,9,4],
[90,28,9,6],
[90,28,9,8],
[90,28,11,0],
[90,28,11,1],
[90,28,11,2],
[90,28,11,5],
[90,28,11,6],
[90,28,11,8],
[90,28,11,9],
[90,28,12,3],
[90,28,12,4],
[90,28,12,5],
[90,28,12,6],
[90,28,12,8],
[90,28,12,9],
[90,28,13,3],
[90,28,13,4],
[90,28,13,5],
[90,28,13,6],
[90,28,13,8],
[90,28,13,9],
[90,28,13,11],
[90,28,13,12],
[90,28,14,0],
[90,28,14,1],
[90,28,14,2],
[90,28,14,3],
[90,28,14,4],
[90,28,14,5],
[90,28,14,6],
[90,28,14,8],
[90,28,14,9],
[90,28,14,11],
[90,28,14,12],
[90,28,15,0],
[90,28,15,1],
[90,28,15,2],
[90,28,15,3],
[90,28,15,4],
[90,28,15,5],
[90,28,15,6],
[90,28,15,8],
[90,28,15,11],
[90,28,15,12],
[90,28,15,13],
[90,28,15,14],
[90,28,16,0],
[90,28,16,1],
[90,28,16,2],
[90,28,16,3],
[90,28,16,4],
[90,28,16,5],
[90,28,16,6],
[90,28,16,8],
[90,28,16,9],
[90,28,16,11],
[90,28,16,12],
[90,28,16,13],
[90,28,16,14],
[90,28,16,15],
[90,28,17,0],
[90,28,17,1],
[90,28,17,2],
[90,28,17,3],
[90,28,17,4],
[90,28,17,5],
[90,28,17,6],
[90,28,17,8],
[90,28,17,9],
[90,28,17,11],
[90,28,17,12],
[90,28,17,13],
[90,28,17,14],
[90,28,17,15],
[90,28,17,16],
[90,28,18,0],
[90,28,18,1],
[90,28,18,2],
[90,28,18,3],
[90,28,18,4],
[90,28,18,5],
[90,28,18,6],
[90,28,18,8],
[90,28,18,11],
[90,28,18,12],
[90,28,18,13],
[90,28,18,14],
[90,28,19,0],
[90,28,19,1],
[90,28,19,2],
[90,28,19,3],
[90,28,19,4],
[90,28,19,5],
[90,28,19,6],
[90,28,19,8],
[90,28,19,9],
[90,28,19,11],
[90,28,19,12],
[90,28,21,0],
[90,28,21,1],
[90,28,21,2],
[90,28,21,4],
[90,28,21,9],
[90,28,21,11],
[90,28,23,0],
[90,28,23,1],
[90,28,23,2],
[90,28,23,3],
[90,28,23,4],
[90,28,23,5],
[90,28,23,6],
[90,28,24,0],
[90,28,24,1],
[90,28,24,2],
[90,28,24,3],
[90,28,24,4],
[90,28,24,5],
[90,28,24,6],
[90,28,26,0],
[90,28,26,1],
[90,28,26,2],
[90,29,3,0],
[90,29,3,1],
[90,29,3,2],
[90,29,4,0],
[90,29,4,1],
[90,29,4,2],
[90,29,4,3],
[90,29,5,0],
[90,29,5,1],
[90,29,5,2],
[90,29,5,4],
[90,29,6,0],
[90,29,6,1],
[90,29,6,2],
[90,29,6,4],
[90,29,7,0],
[90,29,7,1],
[90,29,7,2],
[90,29,7,3],
[90,29,7,4],
[90,29,7,6],
[90,29,8,0],
[90,29,8,1],
[90,29,8,2],
[90,29,8,7],
[90,29,9,0],
[90,29,9,1],
[90,29,9,2],
[90,29,9,5],
[90,29,9,6],
[90,29,9,7],
[90,29,9,8],
[90,29,13,0],
[90,29,13,1],
[90,29,13,2],
[90,29,13,3],
[90,29,13,4],
[90,29,13,5],
[90,29,13,6],
[90,29,13,7],
[90,29,13,8],
[90,29,13,9],
[90,29,14,0],
[90,29,14,1],
[90,29,14,2],
[90,29,14,3],
[90,29,14,4],
[90,29,14,5],
[90,29,14,6],
[90,29,14,7],
[90,29,14,8],
[90,29,14,9],
[90,29,15,0],
[90,29,15,1],
[90,29,15,2],
[90,29,15,3],
[90,29,15,4],
[90,29,15,5],
[90,29,15,6],
[90,29,15,7],
[90,29,15,8],
[90,29,15,13],
[90,29,15,14],
[90,29,16,0],
[90,29,16,1],
[90,29,16,2],
[90,29,16,3],
[90,29,16,4],
[90,29,16,5],
[90,29,16,6],
[90,29,16,7],
[90,29,16,8],
[90,29,16,9],
[90,29,16,13],
[90,29,16,14],
[90,29,16,15],
[90,29,17,0],
[90,29,17,1],
[90,29,17,2],
[90,29,17,3],
[90,29,17,4],
[90,29,17,5],
[90,29,17,6],
[90,29,17,7],
[90,29,17,8],
[90,29,17,9],
[90,29,17,13],
[90,29,17,14],
[90,29,17,15],
[90,29,18,0],
[90,29,18,1],
[90,29,18,2],
[90,29,18,3],
[90,29,18,4],
[90,29,18,5],
[90,29,18,6],
[90,29,18,7],
[90,29,18,8],
[90,29,18,13],
[90,29,18,14],
[90,29,19,0],
[90,29,19,1],
[90,29,19,2],
[90,29,19,3],
[90,29,19,4],
[90,29,19,5],
[90,29,19,6],
[90,29,19,7],
[90,29,19,8],
[90,29,19,9],
[90,29,21,0],
[90,29,21,1],
[90,29,21,2],
[90,29,21,4],
[90,29,21,7],
[90,29,21,9],
[90,29,23,0],
[90,29,23,1],
[90,29,23,2],
[90,29,23,3],
[90,29,23,4],
[90,29,23,5],
[90,29,24,0],
[90,29,24,1],
[90,29,24,2],
[90,29,24,3],
[90,29,24,4],
[90,29,24,5],
[90,29,25,0],
[90,29,25,1],
[90,29,25,2],
[90,30,3,0],
[90,30,3,1],
[90,30,3,2],
[90,30,4,0],
[90,30,4,1],
[90,30,4,2],
[90,30,4,3],
[90,30,5,0],
[90,30,5,1],
[90,30,5,2],
[90,30,5,4],
[90,30,6,0],
[90,30,6,1],
[90,30,6,2],
[90,30,7,0],
[90,30,7,1],
[90,30,7,2],
[90,30,7,5],
[90,30,7,6],
[90,30,8,4],
[90,30,8,7],
[90,30,9,3],
[90,30,9,4],
[90,30,9,5],
[90,30,9,6],
[90,30,9,7],
[90,30,9,8],
[90,30,11,0],
[90,30,11,1],
[90,30,11,2],
[90,30,11,3],
[90,30,11,4],
[90,30,11,5],
[90,30,11,6],
[90,30,11,7],
[90,30,11,8],
[90,30,11,9],
[90,30,12,0],
[90,30,12,1],
[90,30,12,2],
[90,30,12,3],
[90,30,12,4],
[90,30,12,5],
[90,30,12,6],
[90,30,12,7],
[90,30,12,8],
[90,30,12,9],
[90,30,13,0],
[90,30,13,1],
[90,30,13,2],
[90,30,13,3],
[90,30,13,4],
[90,30,13,5],
[90,30,13,6],
[90,30,13,7],
[90,30,13,8],
[90,30,13,9],
[90,30,13,11],
[90,30,13,12],
[90,30,14,0],
[90,30,14,1],
[90,30,14,2],
[90,30,14,3],
[90,30,14,4],
[90,30,14,5],
[90,30,14,6],
[90,30,14,7],
[90,30,14,8],
[90,30,14,9],
[90,30,14,11],
[90,30,14,12],
[90,30,15,0],
[90,30,15,1],
[90,30,15,2],
[90,30,15,3],
[90,30,15,4],
[90,30,15,5],
[90,30,15,6],
[90,30,15,7],
[90,30,15,8],
[90,30,15,11],
[90,30,15,12],
[90,30,15,13],
[90,30,15,14],
[90,30,16,0],
[90,30,16,1],
[90,30,16,2],
[90,30,16,3],
[90,30,16,4],
[90,30,16,5],
[90,30,16,6],
[90,30,16,7],
[90,30,16,8],
[90,30,16,9],
[90,30,16,11],
[90,30,16,12],
[90,30,16,13],
[90,30,16,14],
[90,30,17,0],
[90,30,17,1],
[90,30,17,2],
[90,30,17,3],
[90,30,17,4],
[90,30,17,5],
[90,30,17,6],
[90,30,17,7],
[90,30,17,8],
[90,30,17,9],
[90,30,17,11],
[90,30,17,12],
[90,30,17,13],
[90,30,17,14],
[90,30,18,0],
[90,30,18,1],
[90,30,18,2],
[90,30,18,3],
[90,30,18,4],
[90,30,18,5],
[90,30,18,6],
[90,30,18,7],
[90,30,18,8],
[90,30,18,11],
[90,30,18,12],
[90,30,18,13],
[90,30,19,0],
[90,30,19,1],
[90,30,19,2],
[90,30,19,3],
[90,30,19,4],
[90,30,19,5],
[90,30,19,6],
[90,30,19,7],
[90,30,19,8],
[90,30,19,9],
[90,30,19,11],
[90,30,21,0],
[90,30,21,1],
[90,30,21,2],
[90,30,21,4],
[90,30,21,7],
[90,30,24,0],
[90,30,24,1],
[90,30,24,2],
[90,30,24,3],
[90,30,24,4],
[90,31,3,0],
[90,31,3,1],
[90,31,3,2],
[90,31,4,0],
[90,31,4,1],
[90,31,4,2],
[90,31,4,3],
[90,31,5,0],
[90,31,5,1],
[90,31,5,2],
[90,31,6,4],
[90,31,7,3],
[90,31,7,4],
[90,31,7,5],
[90,31,7,6],
[90,31,8,0],
[90,31,8,1],
[90,31,8,2],
[90,31,8,4],
[90,31,8,7],
[90,31,9,0],
[90,31,9,1],
[90,31,9,2],
[90,31,9,3],
[90,31,9,4],
[90,31,9,5],
[90,31,9,6],
[90,31,9,7],
[90,31,9,8],
[90,31,11,0],
[90,31,11,1],
[90,31,11,2],
[90,31,11,3],
[90,31,11,4],
[90,31,11,5],
[90,31,11,6],
[90,31,11,7],
[90,31,11,8],
[90,31,11,9],
[90,31,12,0],
[90,31,12,1],
[90,31,12,2],
[90,31,12,3],
[90,31,12,4],
[90,31,12,5],
[90,31,12,6],
[90,31,12,7],
[90,31,12,8],
[90,31,12,9],
[90,31,13,0],
[90,31,13,1],
[90,31,13,2],
[90,31,13,3],
[90,31,13,4],
[90,31,13,5],
[90,31,13,6],
[90,31,13,7],
[90,31,13,8],
[90,31,13,9],
[90,31,13,11],
[90,31,13,12],
[90,31,14,0],
[90,31,14,1],
[90,31,14,2],
[90,31,14,3],
[90,31,14,4],
[90,31,14,5],
[90,31,14,6],
[90,31,14,7],
[90,31,14,8],
[90,31,14,9],
[90,31,14,11],
[90,31,14,12],
[90,31,15,0],
[90,31,15,1],
[90,31,15,2],
[90,31,15,3],
[90,31,15,4],
[90,31,15,5],
[90,31,15,6],
[90,31,15,7],
[90,31,15,8],
[90,31,15,11],
[90,31,15,12],
[90,31,15,13],
[90,31,15,14],
[90,31,16,0],
[90,31,16,1],
[90,31,16,2],
[90,31,16,3],
[90,31,16,4],
[90,31,16,5],
[90,31,16,6],
[90,31,16,7],
[90,31,16,8],
[90,31,16,9],
[90,31,16,11],
[90,31,16,12],
[90,31,16,13],
[90,31,17,0],
[90,31,17,1],
[90,31,17,2],
[90,31,17,3],
[90,31,17,4],
[90,31,17,5],
[90,31,17,6],
[90,31,17,7],
[90,31,17,8],
[90,31,17,9],
[90,31,17,11],
[90,31,17,12],
[90,31,17,13],
[90,31,18,0],
[90,31,18,1],
[90,31,18,2],
[90,31,18,3],
[90,31,18,4],
[90,31,18,5],
[90,31,18,6],
[90,31,18,7],
[90,31,18,8],
[90,31,18,11],
[90,31,19,0],
[90,31,19,1],
[90,31,19,2],
[90,31,19,3],
[90,31,19,4],
[90,31,19,5],
[90,31,19,6],
[90,31,19,7],
[90,31,19,8],
[90,31,19,9],
[90,31,21,0],
[90,31,21,1],
[90,31,21,2],
[90,31,21,4],
[90,31,24,0],
[90,31,24,1],
[90,31,24,2],
[90,32,3,0],
[90,32,3,1],
[90,32,3,2],
[90,32,4,0],
[90,32,4,1],
[90,32,4,2],
[90,32,5,4],
[90,32,6,0],
[90,32,6,1],
[90,32,6,2],
[90,32,6,4],
[90,32,7,0],
[90,32,7,1],
[90,32,7,2],
[90,32,7,3],
[90,32,7,4],
[90,32,7,5],
[90,32,7,6],
[90,32,8,0],
[90,32,8,1],
[90,32,8,2],
[90,32,8,4],
[90,32,8,7],
[90,32,9,0],
[90,32,9,1],
[90,32,9,2],
[90,32,9,3],
[90,32,9,4],
[90,32,9,5],
[90,32,9,6],
[90,32,9,7],
[90,32,9,8],
[90,32,13,0],
[90,32,13,1],
[90,32,13,2],
[90,32,13,3],
[90,32,13,4],
[90,32,13,5],
[90,32,13,6],
[90,32,13,7],
[90,32,13,8],
[90,32,13,9],
[90,32,14,0],
[90,32,14,1],
[90,32,14,2],
[90,32,14,3],
[90,32,14,4],
[90,32,14,5],
[90,32,14,6],
[90,32,14,7],
[90,32,14,8],
[90,32,14,9],
[90,32,15,0],
[90,32,15,1],
[90,32,15,2],
[90,32,15,3],
[90,32,15,4],
[90,32,15,5],
[90,32,15,6],
[90,32,15,7],
[90,32,15,8],
[90,32,15,13],
[90,32,16,0],
[90,32,16,1],
[90,32,16,2],
[90,32,16,3],
[90,32,16,4],
[90,32,16,5],
[90,32,16,6],
[90,32,16,7],
[90,32,16,8],
[90,32,16,9],
[90,32,17,0],
[90,32,17,1],
[90,32,17,2],
[90,32,17,3],
[90,32,17,4],
[90,32,17,5],
[90,32,17,6],
[90,32,17,7],
[90,32,17,8],
[90,32,17,9],
[90,32,18,0],
[90,32,18,1],
[90,32,18,2],
[90,32,18,3],
[90,32,18,4],
[90,32,18,5],
[90,32,18,6],
[90,32,18,7],
[90,32,18,8],
[90,32,19,0],
[90,32,19,1],
[90,32,19,2],
[90,32,19,3],
[90,32,19,4],
[90,32,19,5],
[90,32,19,6],
[90,32,19,7],
[90,32,21,0],
[90,32,21,1],
[90,32,21,2],
[90,32,21,4],
[90,33,4,3],
[90,33,5,0],
[90,33,5,1],
[90,33,5,2],
[90,33,5,4],
[90,33,6,0],
[90,33,6,1],
[90,33,6,2],
[90,33,6,4],
[90,33,7,0],
[90,33,7,1],
[90,33,7,2],
[90,33,7,3],
[90,33,7,4],
[90,33,7,5],
[90,33,7,6],
[90,33,8,0],
[90,33,8,1],
[90,33,8,2],
[90,33,8,4],
[90,33,8,7],
[90,33,9,0],
[90,33,9,1],
[90,33,9,2],
[90,33,9,3],
[90,33,9,4],
[90,33,9,5],
[90,33,9,6],
[90,33,9,7],
[90,33,9,8],
[90,33,13,0],
[90,33,13,1],
[90,33,13,2],
[90,33,13,3],
[90,33,13,4],
[90,33,13,5],
[90,33,13,6],
[90,33,13,7],
[90,33,13,8],
[90,33,13,9],
[90,33,14,0],
[90,33,14,1],
[90,33,14,2],
[90,33,14,3],
[90,33,14,4],
[90,33,14,5],
[90,33,14,6],
[90,33,14,7],
[90,33,14,8],
[90,33,14,9],
[90,33,15,0],
[90,33,15,1],
[90,33,15,2],
[90,33,15,3],
[90,33,15,4],
[90,33,15,5],
[90,33,15,6],
[90,33,15,7],
[90,33,15,8],
[90,33,16,0],
[90,33,16,1],
[90,33,16,2],
[90,33,16,3],
[90,33,16,4],
[90,33,16,5],
[90,33,16,6],
[90,33,16,7],
[90,33,16,8],
[90,33,16,9],
[90,33,17,0],
[90,33,17,1],
[90,33,17,2],
[90,33,17,3],
[90,33,17,4],
[90,33,17,5],
[90,33,17,6],
[90,33,17,7],
[90,33,17,8],
[90,33,17,9],
[90,33,18,0],
[90,33,18,1],
[90,33,18,2],
[90,33,18,3],
[90,33,18,4],
[90,33,18,5],
[90,33,18,6],
[90,33,18,7],
[90,33,19,0],
[90,33,19,1],
[90,33,19,2],
[90,33,19,3],
[90,33,19,4],
[90,33,19,5],
[90,33,21,0],
[90,33,21,1],
[90,33,21,2],
[90,34,3,0],
[90,34,3,1],
[90,34,3,2],
[90,34,4,0],
[90,34,4,1],
[90,34,4,2],
[90,34,4,3],
[90,34,5,0],
[90,34,5,1],
[90,34,5,2],
[90,34,5,4],
[90,34,6,0],
[90,34,6,1],
[90,34,6,2],
[90,34,6,4],
[90,34,8,0],
[90,34,8,1],
[90,34,8,2],
[90,34,8,4],
[90,34,9,0],
[90,34,9,1],
[90,34,9,2],
[90,34,9,3],
[90,34,9,4],
[90,34,9,5],
[90,34,9,6],
[90,34,9,8],
[90,34,11,0],
[90,34,11,1],
[90,34,11,2],
[90,34,11,3],
[90,34,11,4],
[90,34,11,5],
[90,34,11,6],
[90,34,11,8],
[90,34,11,9],
[90,34,12,0],
[90,34,12,1],
[90,34,12,2],
[90,34,12,3],
[90,34,12,4],
[90,34,12,5],
[90,34,12,6],
[90,34,12,8],
[90,34,12,9],
[90,34,13,0],
[90,34,13,1],
[90,34,13,2],
[90,34,13,3],
[90,34,13,4],
[90,34,13,5],
[90,34,13,6],
[90,34,13,8],
[90,34,13,9],
[90,34,13,11],
[90,34,13,12],
[90,34,14,0],
[90,34,14,1],
[90,34,14,2],
[90,34,14,3],
[90,34,14,4],
[90,34,14,5],
[90,34,14,6],
[90,34,14,8],
[90,34,14,9],
[90,34,14,11],
[90,34,15,0],
[90,34,15,1],
[90,34,15,2],
[90,34,15,3],
[90,34,15,4],
[90,34,15,5],
[90,34,15,6],
[90,34,15,8],
[90,34,16,0],
[90,34,16,1],
[90,34,16,2],
[90,34,16,3],
[90,34,16,4],
[90,34,16,5],
[90,34,16,6],
[90,34,17,0],
[90,34,17,1],
[90,34,17,2],
[90,34,17,3],
[90,34,17,4],
[90,34,17,5],
[90,34,17,6],
[90,34,18,0],
[90,34,18,1],
[90,34,18,2],
[90,34,18,3],
[90,34,18,4],
[90,34,18,5],
[90,34,19,0],
[90,34,19,1],
[90,34,19,2],
[90,34,19,3],
[90,34,19,4],
[90,35,3,0],
[90,35,3,1],
[90,35,3,2],
[90,35,4,0],
[90,35,4,1],
[90,35,4,2],
[90,35,4,3],
[90,35,5,0],
[90,35,5,1],
[90,35,5,2],
[90,35,5,4],
[90,35,6,0],
[90,35,6,1],
[90,35,6,2],
[90,35,6,4],
[90,35,7,0],
[90,35,7,1],
[90,35,7,2],
[90,35,7,3],
[90,35,7,4],
[90,35,7,5],
[90,35,7,6],
[90,35,8,0],
[90,35,8,1],
[90,35,8,2],
[90,35,8,4],
[90,35,8,7],
[90,35,11,0],
[90,35,11,1],
[90,35,11,2],
[90,35,11,3],
[90,35,11,4],
[90,35,11,5],
[90,35,11,6],
[90,35,11,7],
[90,35,11,8],
[90,35,12,0],
[90,35,12,1],
[90,35,12,2],
[90,35,12,3],
[90,35,12,4],
[90,35,12,5],
[90,35,12,6],
[90,35,12,7],
[90,35,12,8],
[90,35,13,0],
[90,35,13,1],
[90,35,13,2],
[90,35,13,3],
[90,35,13,4],
[90,35,13,5],
[90,35,13,6],
[90,35,13,7],
[90,35,13,8],
[90,35,13,11],
[90,35,14,0],
[90,35,14,1],
[90,35,14,2],
[90,35,14,3],
[90,35,14,4],
[90,35,14,5],
[90,35,14,6],
[90,35,14,7],
[90,35,14,8],
[90,35,16,0],
[90,35,16,1],
[90,35,16,2],
[90,35,16,3],
[90,35,16,4],
[90,35,16,5],
[90,35,17,0],
[90,35,17,1],
[90,35,17,2],
[90,35,17,3],
[90,35,17,4],
[90,35,17,5],
[90,35,19,0],
[90,35,19,1],
[90,35,19,2],
[90,36,3,0],
[90,36,3,1],
[90,36,3,2],
[90,36,4,0],
[90,36,4,1],
[90,36,4,2],
[90,36,4,3],
[90,36,5,0],
[90,36,5,1],
[90,36,5,2],
[90,36,5,4],
[90,36,6,0],
[90,36,6,1],
[90,36,6,2],
[90,36,6,4],
[90,36,7,0],
[90,36,7,1],
[90,36,7,2],
[90,36,7,3],
[90,36,7,4],
[90,36,7,5],
[90,36,7,6],
[90,36,8,0],
[90,36,8,1],
[90,36,8,2],
[90,36,8,4],
[90,36,8,7],
[90,36,9,0],
[90,36,9,1],
[90,36,9,2],
[90,36,9,3],
[90,36,9,4],
[90,36,9,5],
[90,36,9,6],
[90,36,9,7],
[90,36,9,8],
[90,36,11,0],
[90,36,11,1],
[90,36,11,2],
[90,36,11,3],
[90,36,11,4],
[90,36,11,5],
[90,36,11,6],
[90,36,11,7],
[90,36,11,8],
[90,36,11,9],
[90,36,12,0],
[90,36,12,1],
[90,36,12,2],
[90,36,12,3],
[90,36,12,4],
[90,36,12,5],
[90,36,12,6],
[90,36,12,7],
[90,36,12,8],
[90,36,12,9],
[90,36,15,0],
[90,36,15,1],
[90,36,15,2],
[90,36,15,3],
[90,36,15,4],
[90,36,15,5],
[90,36,16,0],
[90,36,16,1],
[90,36,16,2],
[90,36,16,3],
[90,36,16,4],
[90,36,17,0],
[90,36,17,1],
[90,36,17,2],
[90,36,17,3],
[90,36,17,4],
[90,36,18,0],
[90,36,18,1],
[90,36,18,2],
[90,37,3,0],
[90,37,3,1],
[90,37,3,2],
[90,37,5,0],
[90,37,5,1],
[90,37,5,2],
[90,37,6,0],
[90,37,6,1],
[90,37,6,2],
[90,37,7,0],
[90,37,7,1],
[90,37,7,2],
[90,37,7,3],
[90,37,7,5],
[90,37,7,6],
[90,37,8,0],
[90,37,8,1],
[90,37,8,2],
[90,37,8,7],
[90,37,9,0],
[90,37,9,1],
[90,37,9,2],
[90,37,9,3],
[90,37,9,5],
[90,37,9,6],
[90,37,9,7],
[90,37,9,8],
[90,37,11,0],
[90,37,11,1],
[90,37,11,2],
[90,37,11,3],
[90,37,11,5],
[90,37,11,6],
[90,37,11,7],
[90,37,11,8],
[90,37,11,9],
[90,37,12,0],
[90,37,12,1],
[90,37,12,2],
[90,37,12,3],
[90,37,12,5],
[90,37,12,6],
[90,37,12,7],
[90,37,13,0],
[90,37,13,1],
[90,37,13,2],
[90,37,13,3],
[90,37,13,5],
[90,37,13,6],
[90,37,13,7],
[90,37,14,0],
[90,37,14,1],
[90,37,14,2],
[90,37,14,3],
[90,37,14,5],
[90,37,15,0],
[90,37,15,1],
[90,37,15,2],
[90,37,15,3],
[90,37,16,0],
[90,37,16,1],
[90,37,16,2],
[90,37,17,0],
[90,37,17,1],
[90,37,17,2],
[90,38,3,0],
[90,38,3,1],
[90,38,3,2],
[90,38,4,0],
[90,38,4,1],
[90,38,4,2],
[90,38,4,3],
[90,38,5,0],
[90,38,5,1],
[90,38,5,2],
[90,38,5,4],
[90,38,6,0],
[90,38,6,1],
[90,38,6,2],
[90,38,6,4],
[90,38,8,0],
[90,38,8,1],
[90,38,8,2],
[90,38,8,4],
[90,38,9,0],
[90,38,9,1],
[90,38,9,2],
[90,38,9,3],
[90,38,9,4],
[90,38,9,5],
[90,38,9,6],
[90,38,9,8],
[90,38,11,0],
[90,38,11,1],
[90,38,11,2],
[90,38,11,3],
[90,38,11,4],
[90,38,11,5],
[90,38,11,6],
[90,38,12,0],
[90,38,12,1],
[90,38,12,2],
[90,38,12,3],
[90,38,12,4],
[90,38,12,5],
[90,38,13,0],
[90,38,13,1],
[90,38,13,2],
[90,38,13,3],
[90,38,13,4],
[90,38,13,5],
[90,38,14,0],
[90,38,14,1],
[90,38,14,2],
[90,38,14,3],
[90,38,14,4],
[90,38,15,0],
[90,38,15,1],
[90,38,15,2],
[90,39,4,0],
[90,39,4,1],
[90,39,4,2],
[90,39,7,0],
[90,39,7,1],
[90,39,7,2],
[90,39,7,4],
[90,39,9,0],
[90,39,9,1],
[90,39,9,2],
[90,39,9,4],
[90,39,9,7],
[90,39,11,0],
[90,39,11,1],
[90,39,11,2],
[90,39,11,4],
[90,39,12,0],
[90,39,12,1],
[90,39,12,2],
[90,39,12,4],
[90,39,13,0],
[90,39,13,1],
[90,39,13,2],
[90,39,13,4],
[90,39,14,0],
[90,39,14,1],
[90,39,14,2],
[90,40,3,0],
[90,40,3,1],
[90,40,3,2],
[90,40,4,0],
[90,40,4,1],
[90,40,4,2],
[90,40,4,3],
[90,40,5,0],
[90,40,5,1],
[90,40,5,2],
[90,40,5,4],
[90,40,6,0],
[90,40,6,1],
[90,40,6,2],
[90,40,6,4],
[90,40,7,0],
[90,40,7,1],
[90,40,7,2],
[90,40,7,3],
[90,40,7,4],
[90,40,7,5],
[90,40,7,6],
[90,40,8,0],
[90,40,8,1],
[90,40,8,2],
[90,40,8,4],
[90,40,11,0],
[90,40,11,1],
[90,40,11,2],
[90,40,11,3],
[90,40,11,4],
[90,40,12,0],
[90,40,12,1],
[90,40,12,2],
[90,40,13,0],
[90,40,13,1],
[90,40,13,2],
[90,41,3,0],
[90,41,3,1],
[90,41,3,2],
[90,41,4,0],
[90,41,4,1],
[90,41,4,2],
[90,41,4,3],
[90,41,5,0],
[90,41,5,1],
[90,41,5,2],
[90,41,5,4],
[90,41,6,0],
[90,41,6,1],
[90,41,6,2],
[90,41,6,4],
[90,41,7,0],
[90,41,7,1],
[90,41,7,2],
[90,41,7,3],
[90,41,7,4],
[90,41,7,5],
[90,41,7,6],
[90,41,8,0],
[90,41,8,1],
[90,41,8,2],
[90,41,8,4],
[90,41,11,0],
[90,41,11,1],
[90,41,11,2],
[90,41,11,3],
[90,41,11,4],
[90,41,12,0],
[90,41,12,1],
[90,41,12,2],
[90,41,13,0],
[90,41,13,1],
[90,41,13,2],
[90,43,3,0],
[90,43,3,1],
[90,43,3,2],
[90,43,4,0],
[90,43,4,1],
[90,43,4,2],
[90,43,4,3],
[90,43,5,0],
[90,43,5,1],
[90,43,5,2],
[90,43,5,4],
[90,43,6,0],
[90,43,6,1],
[90,43,6,2],
[90,43,6,4],
[90,43,8,0],
[90,43,8,1],
[90,43,8,2],
[90,43,9,0],
[90,43,9,1],
[90,43,9,2],
[90,44,3,0],
[90,44,3,1],
[90,44,3,2],
[90,44,4,0],
[90,44,4,1],
[90,44,4,2],
[90,44,4,3],
[90,44,5,0],
[90,44,5,1],
[90,44,5,2],
[90,44,5,4],
[90,44,6,0],
[90,44,6,1],
[90,44,6,2],
[90,44,7,0],
[90,44,7,1],
[90,44,7,2],
[90,45,3,0],
[90,45,3,1],
[90,45,3,2],
[90,45,4,0],
[90,45,4,1],
[90,45,4,2],
[90,45,4,3],
[90,45,5,0],
[90,45,5,1],
[90,45,5,2],
[90,46,3,0],
[90,46,3,1],
[90,46,3,2],
[90,46,4,0],
[90,46,4,1],
[90,46,4,2],
[91,4,3,0],
[91,4,3,1],
[91,4,3,2],
[91,5,4,0],
[91,5,4,1],
[91,5,4,2],
[91,6,4,0],
[91,6,4,1],
[91,6,4,2],
[91,7,3,0],
[91,7,3,1],
[91,7,3,2],
[91,7,4,0],
[91,7,4,1],
[91,7,4,2],
[91,7,4,3],
[91,7,5,0],
[91,7,5,1],
[91,7,5,2],
[91,7,5,4],
[91,7,6,0],
[91,7,6,1],
[91,7,6,2],
[91,7,6,4],
[91,8,4,0],
[91,8,4,1],
[91,8,4,2],
[91,8,7,0],
[91,8,7,1],
[91,8,7,2],
[91,8,7,4],
[91,10,3,0],
[91,10,3,1],
[91,10,3,2],
[91,10,4,0],
[91,10,4,1],
[91,10,4,2],
[91,10,4,3],
[91,10,5,0],
[91,10,5,1],
[91,10,5,2],
[91,10,5,4],
[91,10,6,0],
[91,10,6,1],
[91,10,6,2],
[91,10,6,4],
[91,10,7,0],
[91,10,7,1],
[91,10,7,2],
[91,10,7,3],
[91,10,7,4],
[91,10,7,5],
[91,10,7,6],
[91,10,8,0],
[91,10,8,1],
[91,10,8,2],
[91,10,8,4],
[91,10,8,7],
[91,11,3,0],
[91,11,3,1],
[91,11,3,2],
[91,11,4,0],
[91,11,4,1],
[91,11,4,2],
[91,11,4,3],
[91,11,5,0],
[91,11,5,1],
[91,11,5,2],
[91,11,5,4],
[91,11,6,0],
[91,11,6,1],
[91,11,6,2],
[91,11,6,4],
[91,11,7,0],
[91,11,7,1],
[91,11,7,2],
[91,11,7,3],
[91,11,7,4],
[91,11,7,5],
[91,11,7,6],
[91,11,8,0],
[91,11,8,1],
[91,11,8,2],
[91,11,8,4],
[91,11,8,7],
[91,11,10,0],
[91,11,10,1],
[91,11,10,2],
[91,11,10,3],
[91,11,10,4],
[91,11,10,5],
[91,11,10,6],
[91,11,10,7],
[91,11,10,8],
[91,12,3,0],
[91,12,3,1],
[91,12,3,2],
[91,12,4,0],
[91,12,4,1],
[91,12,4,2],
[91,12,4,3],
[91,12,5,0],
[91,12,5,1],
[91,12,5,2],
[91,12,5,4],
[91,12,6,0],
[91,12,6,1],
[91,12,6,2],
[91,12,6,4],
[91,12,7,0],
[91,12,7,1],
[91,12,7,2],
[91,12,7,3],
[91,12,7,4],
[91,12,7,5],
[91,12,7,6],
[91,12,8,0],
[91,12,8,1],
[91,12,8,2],
[91,12,8,4],
[91,12,8,7],
[91,12,10,0],
[91,12,10,1],
[91,12,10,2],
[91,12,10,3],
[91,12,10,4],
[91,12,10,5],
[91,12,10,6],
[91,12,10,7],
[91,12,10,8],
[91,13,3,0],
[91,13,3,1],
[91,13,3,2],
[91,13,4,0],
[91,13,4,1],
[91,13,4,2],
[91,13,4,3],
[91,13,5,0],
[91,13,5,1],
[91,13,5,2],
[91,13,5,4],
[91,13,6,0],
[91,13,6,1],
[91,13,6,2],
[91,13,6,4],
[91,13,7,0],
[91,13,7,1],
[91,13,7,2],
[91,13,7,3],
[91,13,7,4],
[91,13,7,5],
[91,13,7,6],
[91,13,8,0],
[91,13,8,1],
[91,13,8,2],
[91,13,8,4],
[91,13,8,7],
[91,13,10,0],
[91,13,10,1],
[91,13,10,2],
[91,13,10,3],
[91,13,10,4],
[91,13,10,5],
[91,13,10,6],
[91,13,10,7],
[91,13,10,8],
[91,13,11,0],
[91,13,11,1],
[91,13,11,2],
[91,13,11,3],
[91,13,11,4],
[91,13,11,5],
[91,13,11,6],
[91,13,11,7],
[91,13,11,8],
[91,13,11,10],
[91,13,12,0],
[91,13,12,1],
[91,13,12,2],
[91,13,12,3],
[91,13,12,4],
[91,13,12,5],
[91,13,12,6],
[91,13,12,7],
[91,13,12,8],
[91,13,12,10],
[91,14,3,0],
[91,14,3,1],
[91,14,3,2],
[91,14,4,0],
[91,14,4,1],
[91,14,4,2],
[91,14,4,3],
[91,14,5,0],
[91,14,5,1],
[91,14,5,2],
[91,14,5,4],
[91,14,6,0],
[91,14,6,1],
[91,14,6,2],
[91,14,6,4],
[91,14,7,0],
[91,14,7,1],
[91,14,7,2],
[91,14,7,3],
[91,14,7,4],
[91,14,7,5],
[91,14,7,6],
[91,14,8,0],
[91,14,8,1],
[91,14,8,2],
[91,14,8,4],
[91,14,8,7],
[91,14,10,0],
[91,14,10,1],
[91,14,10,2],
[91,14,10,3],
[91,14,10,4],
[91,14,10,5],
[91,14,10,6],
[91,14,10,7],
[91,14,10,8],
[91,14,11,0],
[91,14,11,1],
[91,14,11,2],
[91,14,11,3],
[91,14,11,4],
[91,14,11,5],
[91,14,11,6],
[91,14,11,7],
[91,14,11,8],
[91,14,11,10],
[91,14,12,0],
[91,14,12,1],
[91,14,12,2],
[91,14,12,3],
[91,14,12,4],
[91,14,12,5],
[91,14,12,6],
[91,14,12,7],
[91,14,12,8],
[91,14,12,10],
[91,16,3,0],
[91,16,3,1],
[91,16,3,2],
[91,16,4,0],
[91,16,4,1],
[91,16,4,2],
[91,16,4,3],
[91,16,5,0],
[91,16,5,1],
[91,16,5,2],
[91,16,5,4],
[91,16,6,0],
[91,16,6,1],
[91,16,6,2],
[91,16,6,4],
[91,16,7,0],
[91,16,7,1],
[91,16,7,2],
[91,16,7,3],
[91,16,7,4],
[91,16,7,5],
[91,16,7,6],
[91,16,8,0],
[91,16,8,1],
[91,16,8,2],
[91,16,8,4],
[91,16,8,7],
[91,16,10,0],
[91,16,10,1],
[91,16,10,2],
[91,16,10,3],
[91,16,10,4],
[91,16,10,5],
[91,16,10,6],
[91,16,10,7],
[91,16,10,8],
[91,16,11,0],
[91,16,11,1],
[91,16,11,2],
[91,16,11,3],
[91,16,11,4],
[91,16,11,5],
[91,16,11,6],
[91,16,11,7],
[91,16,11,8],
[91,16,11,10],
[91,16,12,0],
[91,16,12,1],
[91,16,12,2],
[91,16,12,3],
[91,16,12,4],
[91,16,12,5],
[91,16,12,6],
[91,16,12,7],
[91,16,12,8],
[91,16,12,10],
[91,16,13,0],
[91,16,13,1],
[91,16,13,2],
[91,16,13,3],
[91,16,13,4],
[91,16,13,5],
[91,16,13,6],
[91,16,13,7],
[91,16,13,8],
[91,16,13,10],
[91,16,13,11],
[91,16,13,12],
[91,16,14,0],
[91,16,14,1],
[91,16,14,2],
[91,16,14,3],
[91,16,14,4],
[91,16,14,5],
[91,16,14,6],
[91,16,14,7],
[91,16,14,8],
[91,16,14,10],
[91,16,14,11],
[91,16,14,12],
[91,17,3,0],
[91,17,3,1],
[91,17,3,2],
[91,17,4,0],
[91,17,4,1],
[91,17,4,2],
[91,17,4,3],
[91,17,5,0],
[91,17,5,1],
[91,17,5,2],
[91,17,5,4],
[91,17,6,0],
[91,17,6,1],
[91,17,6,2],
[91,17,6,4],
[91,17,7,0],
[91,17,7,1],
[91,17,7,2],
[91,17,7,3],
[91,17,7,4],
[91,17,7,5],
[91,17,7,6],
[91,17,8,0],
[91,17,8,1],
[91,17,8,2],
[91,17,8,4],
[91,17,8,7],
[91,17,10,0],
[91,17,10,1],
[91,17,10,2],
[91,17,10,3],
[91,17,10,4],
[91,17,10,5],
[91,17,10,6],
[91,17,10,7],
[91,17,10,8],
[91,17,11,0],
[91,17,11,1],
[91,17,11,2],
[91,17,11,3],
[91,17,11,4],
[91,17,11,5],
[91,17,11,6],
[91,17,11,7],
[91,17,11,8],
[91,17,11,10],
[91,17,12,0],
[91,17,12,1],
[91,17,12,2],
[91,17,12,3],
[91,17,12,4],
[91,17,12,5],
[91,17,12,6],
[91,17,12,7],
[91,17,12,8],
[91,17,12,10],
[91,17,13,0],
[91,17,13,1],
[91,17,13,2],
[91,17,13,3],
[91,17,13,4],
[91,17,13,5],
[91,17,13,6],
[91,17,13,7],
[91,17,13,8],
[91,17,13,10],
[91,17,13,11],
[91,17,13,12],
[91,17,14,0],
[91,17,14,1],
[91,17,14,2],
[91,17,14,3],
[91,17,14,4],
[91,17,14,5],
[91,17,14,6],
[91,17,14,7],
[91,17,14,8],
[91,17,14,10],
[91,17,14,11],
[91,17,14,12],
[91,17,16,0],
[91,17,16,1],
[91,17,16,2],
[91,17,16,3],
[91,17,16,4],
[91,17,16,5],
[91,17,16,6],
[91,17,16,7],
[91,17,16,8],
[91,17,16,10],
[91,17,16,11],
[91,17,16,12],
[91,17,16,13],
[91,19,3,0],
[91,19,3,1],
[91,19,3,2],
[91,19,4,0],
[91,19,4,1],
[91,19,4,2],
[91,19,4,3],
[91,19,5,0],
[91,19,5,1],
[91,19,5,2],
[91,19,5,4],
[91,19,6,0],
[91,19,6,1],
[91,19,6,2],
[91,19,6,4],
[91,19,7,0],
[91,19,7,1],
[91,19,7,2],
[91,19,7,3],
[91,19,7,4],
[91,19,7,5],
[91,19,7,6],
[91,19,8,0],
[91,19,8,1],
[91,19,8,2],
[91,19,8,4],
[91,19,8,7],
[91,19,10,0],
[91,19,10,1],
[91,19,10,2],
[91,19,10,3],
[91,19,10,4],
[91,19,10,5],
[91,19,10,6],
[91,19,10,7],
[91,19,10,8],
[91,19,11,0],
[91,19,11,1],
[91,19,11,2],
[91,19,11,3],
[91,19,11,4],
[91,19,11,5],
[91,19,11,6],
[91,19,11,7],
[91,19,11,8],
[91,19,11,10],
[91,19,12,0],
[91,19,12,1],
[91,19,12,2],
[91,19,12,3],
[91,19,12,4],
[91,19,12,5],
[91,19,12,6],
[91,19,12,7],
[91,19,12,8],
[91,19,12,10],
[91,19,16,0],
[91,19,16,1],
[91,19,16,2],
[91,19,16,3],
[91,19,16,4],
[91,19,16,5],
[91,19,16,6],
[91,19,16,7],
[91,19,16,8],
[91,19,16,12],
[91,19,17,0],
[91,19,17,1],
[91,19,17,2],
[91,19,17,3],
[91,19,17,4],
[91,19,17,5],
[91,19,17,6],
[91,19,17,7],
[91,19,17,8],
[91,19,17,12],
[91,19,17,16],
[91,20,3,0],
[91,20,3,1],
[91,20,3,2],
[91,20,4,0],
[91,20,4,1],
[91,20,4,2],
[91,20,4,3],
[91,20,5,0],
[91,20,5,1],
[91,20,5,2],
[91,20,5,4],
[91,20,6,0],
[91,20,6,1],
[91,20,6,2],
[91,20,6,4],
[91,20,7,0],
[91,20,7,1],
[91,20,7,2],
[91,20,7,3],
[91,20,7,4],
[91,20,7,5],
[91,20,7,6],
[91,20,8,0],
[91,20,8,1],
[91,20,8,2],
[91,20,8,4],
[91,20,8,7],
[91,20,11,0],
[91,20,11,1],
[91,20,11,2],
[91,20,11,3],
[91,20,11,4],
[91,20,11,5],
[91,20,11,6],
[91,20,11,7],
[91,20,11,8],
[91,20,12,0],
[91,20,12,1],
[91,20,12,2],
[91,20,12,3],
[91,20,12,4],
[91,20,12,5],
[91,20,12,6],
[91,20,12,7],
[91,20,12,8],
[91,20,13,0],
[91,20,13,1],
[91,20,13,2],
[91,20,13,3],
[91,20,13,4],
[91,20,13,5],
[91,20,13,6],
[91,20,13,7],
[91,20,13,8],
[91,20,13,11],
[91,20,13,12],
[91,20,14,0],
[91,20,14,1],
[91,20,14,2],
[91,20,14,3],
[91,20,14,4],
[91,20,14,5],
[91,20,14,6],
[91,20,14,7],
[91,20,14,8],
[91,20,14,11],
[91,20,16,0],
[91,20,16,1],
[91,20,16,2],
[91,20,16,3],
[91,20,16,4],
[91,20,16,5],
[91,20,16,6],
[91,20,16,7],
[91,20,16,11],
[91,20,16,12],
[91,20,16,13],
[91,20,16,14],
[91,20,17,0],
[91,20,17,1],
[91,20,17,2],
[91,20,17,3],
[91,20,17,4],
[91,20,17,5],
[91,20,17,6],
[91,20,17,7],
[91,20,17,11],
[91,20,17,12],
[91,20,17,13],
[91,20,17,14],
[91,20,17,16],
[91,20,19,0],
[91,20,19,1],
[91,20,19,2],
[91,20,19,3],
[91,20,19,4],
[91,20,19,6],
[91,20,19,7],
[91,20,19,8],
[91,20,19,11],
[91,20,19,12],
[91,20,19,16],
[91,20,19,17],
[91,21,4,0],
[91,21,4,1],
[91,21,4,2],
[91,21,7,0],
[91,21,7,1],
[91,21,7,2],
[91,21,7,4],
[91,21,10,0],
[91,21,10,1],
[91,21,10,2],
[91,21,10,4],
[91,21,10,7],
[91,21,11,0],
[91,21,11,1],
[91,21,11,2],
[91,21,11,4],
[91,21,11,7],
[91,21,11,10],
[91,21,12,0],
[91,21,12,1],
[91,21,12,2],
[91,21,12,4],
[91,21,12,7],
[91,21,12,10],
[91,21,13,0],
[91,21,13,1],
[91,21,13,2],
[91,21,13,4],
[91,21,13,7],
[91,21,13,10],
[91,21,13,11],
[91,21,14,0],
[91,21,14,1],
[91,21,14,2],
[91,21,14,4],
[91,21,14,7],
[91,21,14,12],
[91,21,16,0],
[91,21,16,1],
[91,21,16,2],
[91,21,16,4],
[91,21,16,10],
[91,21,16,11],
[91,21,16,12],
[91,21,16,13],
[91,21,16,14],
[91,21,17,0],
[91,21,17,1],
[91,21,17,2],
[91,21,17,4],
[91,21,17,10],
[91,21,17,11],
[91,21,17,12],
[91,21,17,13],
[91,21,17,14],
[91,21,17,16],
[91,21,19,0],
[91,21,19,1],
[91,21,19,2],
[91,21,19,7],
[91,21,19,10],
[91,21,19,11],
[91,21,19,12],
[91,21,19,16],
[91,21,19,17],
[91,21,20,4],
[91,21,20,7],
[91,21,20,11],
[91,21,20,12],
[91,21,20,13],
[91,21,20,14],
[91,21,20,16],
[91,21,20,17],
[91,21,20,19],
[91,22,3,0],
[91,22,3,1],
[91,22,3,2],
[91,22,4,0],
[91,22,4,1],
[91,22,4,2],
[91,22,4,3],
[91,22,5,0],
[91,22,5,1],
[91,22,5,2],
[91,22,5,4],
[91,22,6,0],
[91,22,6,1],
[91,22,6,2],
[91,22,6,4],
[91,22,7,0],
[91,22,7,1],
[91,22,7,2],
[91,22,7,3],
[91,22,7,4],
[91,22,7,5],
[91,22,7,6],
[91,22,8,0],
[91,22,8,1],
[91,22,8,2],
[91,22,8,4],
[91,22,8,7],
[91,22,11,0],
[91,22,11,1],
[91,22,11,2],
[91,22,11,3],
[91,22,11,4],
[91,22,11,5],
[91,22,11,6],
[91,22,11,7],
[91,22,11,8],
[91,22,12,0],
[91,22,12,1],
[91,22,12,2],
[91,22,12,3],
[91,22,12,4],
[91,22,12,5],
[91,22,12,6],
[91,22,12,7],
[91,22,12,8],
[91,22,13,0],
[91,22,13,1],
[91,22,13,2],
[91,22,13,3],
[91,22,13,4],
[91,22,13,5],
[91,22,13,6],
[91,22,13,7],
[91,22,13,8],
[91,22,13,12],
[91,22,14,0],
[91,22,14,1],
[91,22,14,2],
[91,22,14,3],
[91,22,14,4],
[91,22,14,5],
[91,22,14,6],
[91,22,14,7],
[91,22,14,11],
[91,22,14,12],
[91,22,16,0],
[91,22,16,1],
[91,22,16,2],
[91,22,16,3],
[91,22,16,4],
[91,22,16,6],
[91,22,16,7],
[91,22,16,8],
[91,22,16,11],
[91,22,16,12],
[91,22,16,13],
[91,22,16,14],
[91,22,17,0],
[91,22,17,1],
[91,22,17,2],
[91,22,17,3],
[91,22,17,4],
[91,22,17,6],
[91,22,17,7],
[91,22,17,8],
[91,22,17,11],
[91,22,17,12],
[91,22,17,13],
[91,22,17,14],
[91,22,17,16],
[91,22,19,3],
[91,22,19,4],
[91,22,19,5],
[91,22,19,6],
[91,22,19,7],
[91,22,19,8],
[91,22,19,11],
[91,22,19,12],
[91,22,19,16],
[91,22,19,17],
[91,22,21,0],
[91,22,21,1],
[91,22,21,2],
[91,22,21,4],
[91,22,21,7],
[91,22,21,11],
[91,22,21,12],
[91,22,21,13],
[91,22,21,14],
[91,22,21,16],
[91,22,21,17],
[91,23,3,0],
[91,23,3,1],
[91,23,3,2],
[91,23,4,0],
[91,23,4,1],
[91,23,4,2],
[91,23,4,3],
[91,23,5,0],
[91,23,5,1],
[91,23,5,2],
[91,23,5,4],
[91,23,6,0],
[91,23,6,1],
[91,23,6,2],
[91,23,6,4],
[91,23,7,0],
[91,23,7,1],
[91,23,7,2],
[91,23,7,3],
[91,23,7,4],
[91,23,7,5],
[91,23,7,6],
[91,23,8,0],
[91,23,8,1],
[91,23,8,2],
[91,23,8,4],
[91,23,8,7],
[91,23,10,0],
[91,23,10,1],
[91,23,10,2],
[91,23,10,3],
[91,23,10,4],
[91,23,10,5],
[91,23,10,6],
[91,23,10,7],
[91,23,10,8],
[91,23,11,0],
[91,23,11,1],
[91,23,11,2],
[91,23,11,3],
[91,23,11,4],
[91,23,11,5],
[91,23,11,6],
[91,23,11,7],
[91,23,11,8],
[91,23,12,0],
[91,23,12,1],
[91,23,12,2],
[91,23,12,3],
[91,23,12,4],
[91,23,12,5],
[91,23,12,6],
[91,23,12,7],
[91,23,12,10],
[91,23,13,0],
[91,23,13,1],
[91,23,13,2],
[91,23,13,3],
[91,23,13,4],
[91,23,13,5],
[91,23,13,6],
[91,23,13,7],
[91,23,13,10],
[91,23,13,11],
[91,23,13,12],
[91,23,14,0],
[91,23,14,1],
[91,23,14,2],
[91,23,14,3],
[91,23,14,4],
[91,23,14,5],
[91,23,14,8],
[91,23,14,10],
[91,23,14,11],
[91,23,14,12],
[91,23,16,0],
[91,23,16,1],
[91,23,16,2],
[91,23,16,5],
[91,23,16,6],
[91,23,16,7],
[91,23,16,8],
[91,23,16,10],
[91,23,16,11],
[91,23,16,12],
[91,23,16,13],
[91,23,16,14],
[91,23,17,0],
[91,23,17,1],
[91,23,17,2],
[91,23,17,5],
[91,23,17,6],
[91,23,17,7],
[91,23,17,8],
[91,23,17,10],
[91,23,17,11],
[91,23,17,12],
[91,23,17,13],
[91,23,17,14],
[91,23,17,16],
[91,23,19,0],
[91,23,19,1],
[91,23,19,2],
[91,23,19,3],
[91,23,19,4],
[91,23,19,5],
[91,23,19,6],
[91,23,19,7],
[91,23,19,8],
[91,23,19,10],
[91,23,19,11],
[91,23,19,12],
[91,23,19,16],
[91,23,19,17],
[91,23,20,0],
[91,23,20,1],
[91,23,20,2],
[91,23,20,3],
[91,23,20,4],
[91,23,20,5],
[91,23,20,6],
[91,23,20,7],
[91,23,20,8],
[91,23,20,11],
[91,23,20,12],
[91,23,20,13],
[91,23,20,14],
[91,23,20,16],
[91,23,20,17],
[91,23,21,0],
[91,23,21,1],
[91,23,21,2],
[91,23,21,4],
[91,23,21,7],
[91,23,21,10],
[91,23,21,11],
[91,23,21,12],
[91,23,21,13],
[91,23,21,14],
[91,23,21,16],
[91,23,21,17],
[91,23,22,0],
[91,23,22,1],
[91,23,22,2],
[91,23,22,3],
[91,23,22,4],
[91,23,22,5],
[91,23,22,6],
[91,23,22,7],
[91,23,22,8],
[91,23,22,11],
[91,23,22,12],
[91,23,22,13],
[91,23,22,14],
[91,24,3,0],
[91,24,3,1],
[91,24,3,2],
[91,24,4,0],
[91,24,4,1],
[91,24,4,2],
[91,24,4,3],
[91,24,5,0],
[91,24,5,1],
[91,24,5,2],
[91,24,5,4],
[91,24,6,0],
[91,24,6,1],
[91,24,6,2],
[91,24,6,4],
[91,24,7,0],
[91,24,7,1],
[91,24,7,2],
[91,24,7,3],
[91,24,7,4],
[91,24,7,5],
[91,24,7,6],
[91,24,8,0],
[91,24,8,1],
[91,24,8,2],
[91,24,8,4],
[91,24,8,7],
[91,24,10,0],
[91,24,10,1],
[91,24,10,2],
[91,24,10,3],
[91,24,10,4],
[91,24,10,5],
[91,24,10,6],
[91,24,10,7],
[91,24,10,8],
[91,24,11,0],
[91,24,11,1],
[91,24,11,2],
[91,24,11,3],
[91,24,11,4],
[91,24,11,5],
[91,24,11,6],
[91,24,11,7],
[91,24,11,8],
[91,24,12,0],
[91,24,12,1],
[91,24,12,2],
[91,24,12,3],
[91,24,12,4],
[91,24,12,5],
[91,24,12,6],
[91,24,12,7],
[91,24,12,10],
[91,24,13,0],
[91,24,13,1],
[91,24,13,2],
[91,24,13,3],
[91,24,13,4],
[91,24,13,5],
[91,24,13,6],
[91,24,13,7],
[91,24,13,10],
[91,24,13,11],
[91,24,13,12],
[91,24,14,0],
[91,24,14,1],
[91,24,14,2],
[91,24,14,3],
[91,24,14,4],
[91,24,14,5],
[91,24,14,8],
[91,24,14,10],
[91,24,14,11],
[91,24,14,12],
[91,24,17,0],
[91,24,17,1],
[91,24,17,2],
[91,24,17,5],
[91,24,17,6],
[91,24,17,7],
[91,24,17,8],
[91,24,17,10],
[91,24,17,11],
[91,24,17,12],
[91,24,17,13],
[91,24,17,14],
[91,24,19,0],
[91,24,19,1],
[91,24,19,2],
[91,24,19,3],
[91,24,19,4],
[91,24,19,5],
[91,24,19,6],
[91,24,19,7],
[91,24,19,8],
[91,24,19,10],
[91,24,19,11],
[91,24,19,12],
[91,24,19,17],
[91,24,20,0],
[91,24,20,1],
[91,24,20,2],
[91,24,20,3],
[91,24,20,4],
[91,24,20,5],
[91,24,20,6],
[91,24,20,7],
[91,24,20,8],
[91,24,20,11],
[91,24,20,12],
[91,24,20,13],
[91,24,20,14],
[91,24,20,17],
[91,24,21,0],
[91,24,21,1],
[91,24,21,2],
[91,24,21,4],
[91,24,21,7],
[91,24,21,10],
[91,24,21,11],
[91,24,21,12],
[91,24,21,13],
[91,24,21,14],
[91,24,21,17],
[91,24,22,0],
[91,24,22,1],
[91,24,22,2],
[91,24,22,3],
[91,24,22,4],
[91,24,22,5],
[91,24,22,6],
[91,24,22,7],
[91,24,22,8],
[91,24,22,11],
[91,24,22,12],
[91,24,22,13],
[91,24,22,14],
[91,24,23,0],
[91,24,23,1],
[91,24,23,2],
[91,24,23,3],
[91,24,23,4],
[91,24,23,5],
[91,24,23,6],
[91,24,23,7],
[91,24,23,8],
[91,24,23,10],
[91,24,23,11],
[91,24,23,12],
[91,24,23,13],
[91,24,23,14],
[91,25,3,0],
[91,25,3,1],
[91,25,3,2],
[91,25,4,0],
[91,25,4,1],
[91,25,4,2],
[91,25,4,3],
[91,25,5,0],
[91,25,5,1],
[91,25,5,2],
[91,25,5,4],
[91,25,6,0],
[91,25,6,1],
[91,25,6,2],
[91,25,6,4],
[91,25,8,0],
[91,25,8,1],
[91,25,8,2],
[91,25,8,4],
[91,25,10,0],
[91,25,10,1],
[91,25,10,2],
[91,25,10,3],
[91,25,10,4],
[91,25,10,5],
[91,25,10,8],
[91,25,11,0],
[91,25,11,1],
[91,25,11,2],
[91,25,11,3],
[91,25,11,4],
[91,25,11,5],
[91,25,11,8],
[91,25,11,10],
[91,25,12,0],
[91,25,12,1],
[91,25,12,2],
[91,25,12,3],
[91,25,12,4],
[91,25,12,6],
[91,25,12,8],
[91,25,12,10],
[91,25,13,0],
[91,25,13,1],
[91,25,13,2],
[91,25,13,3],
[91,25,13,4],
[91,25,13,6],
[91,25,13,8],
[91,25,13,10],
[91,25,13,11],
[91,25,13,12],
[91,25,14,0],
[91,25,14,1],
[91,25,14,2],
[91,25,14,5],
[91,25,14,6],
[91,25,14,8],
[91,25,14,10],
[91,25,14,11],
[91,25,14,12],
[91,25,16,0],
[91,25,16,1],
[91,25,16,2],
[91,25,16,3],
[91,25,16,4],
[91,25,16,5],
[91,25,16,6],
[91,25,16,8],
[91,25,16,10],
[91,25,16,11],
[91,25,16,12],
[91,25,16,13],
[91,25,16,14],
[91,25,17,0],
[91,25,17,1],
[91,25,17,2],
[91,25,17,3],
[91,25,17,4],
[91,25,17,5],
[91,25,17,6],
[91,25,17,8],
[91,25,17,10],
[91,25,17,11],
[91,25,17,12],
[91,25,17,13],
[91,25,17,14],
[91,25,17,16],
[91,25,19,0],
[91,25,19,1],
[91,25,19,2],
[91,25,19,3],
[91,25,19,4],
[91,25,19,5],
[91,25,19,6],
[91,25,19,8],
[91,25,19,10],
[91,25,19,11],
[91,25,19,12],
[91,25,19,16],
[91,25,19,17],
[91,25,20,0],
[91,25,20,1],
[91,25,20,2],
[91,25,20,3],
[91,25,20,4],
[91,25,20,5],
[91,25,20,6],
[91,25,20,8],
[91,25,20,11],
[91,25,20,12],
[91,25,20,13],
[91,25,20,14],
[91,25,21,0],
[91,25,21,1],
[91,25,21,2],
[91,25,21,4],
[91,25,21,10],
[91,25,21,11],
[91,25,21,12],
[91,25,21,13],
[91,25,21,14],
[91,25,22,0],
[91,25,22,1],
[91,25,22,2],
[91,25,22,3],
[91,25,22,4],
[91,25,22,5],
[91,25,22,6],
[91,25,22,8],
[91,25,22,11],
[91,25,22,12],
[91,25,22,13],
[91,25,23,0],
[91,25,23,1],
[91,25,23,2],
[91,25,23,3],
[91,25,23,4],
[91,25,23,5],
[91,25,23,6],
[91,25,23,8],
[91,25,23,10],
[91,25,23,11],
[91,25,24,0],
[91,25,24,1],
[91,25,24,2],
[91,25,24,3],
[91,25,24,4],
[91,25,24,5],
[91,25,24,6],
[91,25,24,8],
[91,25,24,10],
[91,25,24,11],
[91,27,3,0],
[91,27,3,1],
[91,27,3,2],
[91,27,4,0],
[91,27,4,1],
[91,27,4,2],
[91,27,4,3],
[91,27,5,0],
[91,27,5,1],
[91,27,5,2],
[91,27,5,4],
[91,27,6,0],
[91,27,6,1],
[91,27,6,2],
[91,27,6,4],
[91,27,7,0],
[91,27,7,1],
[91,27,7,2],
[91,27,7,3],
[91,27,7,4],
[91,27,7,5],
[91,27,8,0],
[91,27,8,1],
[91,27,8,2],
[91,27,8,4],
[91,27,8,7],
[91,27,11,0],
[91,27,11,1],
[91,27,11,2],
[91,27,11,5],
[91,27,11,6],
[91,27,11,7],
[91,27,11,8],
[91,27,12,3],
[91,27,12,4],
[91,27,12,5],
[91,27,12,6],
[91,27,12,7],
[91,27,12,8],
[91,27,13,3],
[91,27,13,4],
[91,27,13,5],
[91,27,13,6],
[91,27,13,7],
[91,27,13,8],
[91,27,13,11],
[91,27,13,12],
[91,27,14,0],
[91,27,14,1],
[91,27,14,2],
[91,27,14,3],
[91,27,14,4],
[91,27,14,5],
[91,27,14,6],
[91,27,14,7],
[91,27,14,8],
[91,27,14,11],
[91,27,14,12],
[91,27,16,0],
[91,27,16,1],
[91,27,16,2],
[91,27,16,3],
[91,27,16,4],
[91,27,16,5],
[91,27,16,6],
[91,27,16,7],
[91,27,16,8],
[91,27,16,11],
[91,27,16,12],
[91,27,16,13],
[91,27,16,14],
[91,27,17,0],
[91,27,17,1],
[91,27,17,2],
[91,27,17,3],
[91,27,17,4],
[91,27,17,5],
[91,27,17,6],
[91,27,17,7],
[91,27,17,8],
[91,27,17,11],
[91,27,17,12],
[91,27,17,13],
[91,27,17,14],
[91,27,17,16],
[91,27,19,0],
[91,27,19,1],
[91,27,19,2],
[91,27,19,3],
[91,27,19,4],
[91,27,19,5],
[91,27,19,6],
[91,27,19,7],
[91,27,19,8],
[91,27,19,11],
[91,27,19,12],
[91,27,21,0],
[91,27,21,1],
[91,27,21,2],
[91,27,21,4],
[91,27,21,7],
[91,27,21,11],
[91,27,23,0],
[91,27,23,1],
[91,27,23,2],
[91,27,23,3],
[91,27,23,4],
[91,27,23,5],
[91,27,23,6],
[91,27,23,7],
[91,27,24,0],
[91,27,24,1],
[91,27,24,2],
[91,27,24,3],
[91,27,24,4],
[91,27,24,5],
[91,27,24,6],
[91,27,24,7],
[91,27,25,0],
[91,27,25,1],
[91,27,25,2],
[91,27,25,3],
[91,27,25,4],
[91,28,3,0],
[91,28,3,1],
[91,28,3,2],
[91,28,4,0],
[91,28,4,1],
[91,28,4,2],
[91,28,4,3],
[91,28,5,0],
[91,28,5,1],
[91,28,5,2],
[91,28,5,4],
[91,28,6,0],
[91,28,6,1],
[91,28,6,2],
[91,28,6,4],
[91,28,8,0],
[91,28,8,1],
[91,28,8,2],
[91,28,10,3],
[91,28,10,4],
[91,28,10,5],
[91,28,10,6],
[91,28,10,8],
[91,28,11,3],
[91,28,11,4],
[91,28,11,5],
[91,28,11,6],
[91,28,11,8],
[91,28,11,10],
[91,28,12,0],
[91,28,12,1],
[91,28,12,2],
[91,28,12,3],
[91,28,12,4],
[91,28,12,5],
[91,28,12,6],
[91,28,12,8],
[91,28,12,10],
[91,28,13,0],
[91,28,13,1],
[91,28,13,2],
[91,28,13,3],
[91,28,13,4],
[91,28,13,5],
[91,28,13,6],
[91,28,13,8],
[91,28,13,10],
[91,28,13,11],
[91,28,13,12],
[91,28,14,0],
[91,28,14,1],
[91,28,14,2],
[91,28,14,3],
[91,28,14,4],
[91,28,14,5],
[91,28,14,6],
[91,28,14,8],
[91,28,14,10],
[91,28,14,11],
[91,28,14,12],
[91,28,16,0],
[91,28,16,1],
[91,28,16,2],
[91,28,16,3],
[91,28,16,4],
[91,28,16,5],
[91,28,16,6],
[91,28,16,8],
[91,28,16,10],
[91,28,16,11],
[91,28,16,12],
[91,28,16,13],
[91,28,16,14],
[91,28,17,0],
[91,28,17,1],
[91,28,17,2],
[91,28,17,3],
[91,28,17,4],
[91,28,17,5],
[91,28,17,6],
[91,28,17,8],
[91,28,17,10],
[91,28,17,11],
[91,28,17,12],
[91,28,17,13],
[91,28,17,14],
[91,28,19,0],
[91,28,19,1],
[91,28,19,2],
[91,28,19,3],
[91,28,19,4],
[91,28,19,5],
[91,28,19,6],
[91,28,19,8],
[91,28,19,10],
[91,28,19,11],
[91,28,19,12],
[91,28,20,0],
[91,28,20,1],
[91,28,20,2],
[91,28,20,3],
[91,28,20,4],
[91,28,20,5],
[91,28,20,6],
[91,28,20,8],
[91,28,20,11],
[91,28,21,0],
[91,28,21,1],
[91,28,21,2],
[91,28,21,4],
[91,28,22,0],
[91,28,22,1],
[91,28,22,2],
[91,28,22,3],
[91,28,22,4],
[91,28,22,5],
[91,28,22,6],
[91,28,23,0],
[91,28,23,1],
[91,28,23,2],
[91,28,23,3],
[91,28,23,4],
[91,28,23,5],
[91,28,24,0],
[91,28,24,1],
[91,28,24,2],
[91,28,24,3],
[91,28,24,4],
[91,28,24,5],
[91,29,3,0],
[91,29,3,1],
[91,29,3,2],
[91,29,4,0],
[91,29,4,1],
[91,29,4,2],
[91,29,4,3],
[91,29,5,0],
[91,29,5,1],
[91,29,5,2],
[91,29,5,4],
[91,29,6,0],
[91,29,6,1],
[91,29,6,2],
[91,29,7,0],
[91,29,7,1],
[91,29,7,2],
[91,29,7,5],
[91,29,7,6],
[91,29,8,4],
[91,29,8,7],
[91,29,10,0],
[91,29,10,1],
[91,29,10,2],
[91,29,10,3],
[91,29,10,4],
[91,29,10,5],
[91,29,10,6],
[91,29,10,7],
[91,29,10,8],
[91,29,13,0],
[91,29,13,1],
[91,29,13,2],
[91,29,13,3],
[91,29,13,4],
[91,29,13,5],
[91,29,13,6],
[91,29,13,7],
[91,29,13,8],
[91,29,13,10],
[91,29,14,0],
[91,29,14,1],
[91,29,14,2],
[91,29,14,3],
[91,29,14,4],
[91,29,14,5],
[91,29,14,6],
[91,29,14,7],
[91,29,14,8],
[91,29,14,10],
[91,29,16,0],
[91,29,16,1],
[91,29,16,2],
[91,29,16,3],
[91,29,16,4],
[91,29,16,5],
[91,29,16,6],
[91,29,16,7],
[91,29,16,8],
[91,29,16,10],
[91,29,16,13],
[91,29,16,14],
[91,29,17,0],
[91,29,17,1],
[91,29,17,2],
[91,29,17,3],
[91,29,17,4],
[91,29,17,5],
[91,29,17,6],
[91,29,17,7],
[91,29,17,8],
[91,29,17,10],
[91,29,17,13],
[91,29,17,14],
[91,29,19,0],
[91,29,19,1],
[91,29,19,2],
[91,29,19,3],
[91,29,19,4],
[91,29,19,5],
[91,29,19,6],
[91,29,19,7],
[91,29,19,8],
[91,29,19,10],
[91,29,20,0],
[91,29,20,1],
[91,29,20,2],
[91,29,20,3],
[91,29,20,4],
[91,29,20,5],
[91,29,20,6],
[91,29,20,7],
[91,29,20,8],
[91,29,21,0],
[91,29,21,1],
[91,29,21,2],
[91,29,21,4],
[91,29,21,7],
[91,29,22,0],
[91,29,22,1],
[91,29,22,2],
[91,29,22,3],
[91,29,22,4],
[91,29,22,5],
[91,29,23,0],
[91,29,23,1],
[91,29,23,2],
[91,29,23,3],
[91,29,23,4],
[91,29,24,0],
[91,29,24,1],
[91,29,24,2],
[91,29,24,3],
[91,29,24,4],
[91,30,3,0],
[91,30,3,1],
[91,30,3,2],
[91,30,4,0],
[91,30,4,1],
[91,30,4,2],
[91,30,4,3],
[91,30,5,0],
[91,30,5,1],
[91,30,5,2],
[91,30,6,4],
[91,30,7,3],
[91,30,7,4],
[91,30,7,5],
[91,30,7,6],
[91,30,8,0],
[91,30,8,1],
[91,30,8,2],
[91,30,8,4],
[91,30,8,7],
[91,30,10,0],
[91,30,10,1],
[91,30,10,2],
[91,30,10,3],
[91,30,10,4],
[91,30,10,5],
[91,30,10,6],
[91,30,10,7],
[91,30,10,8],
[91,30,11,0],
[91,30,11,1],
[91,30,11,2],
[91,30,11,3],
[91,30,11,4],
[91,30,11,5],
[91,30,11,6],
[91,30,11,7],
[91,30,11,8],
[91,30,11,10],
[91,30,12,0],
[91,30,12,1],
[91,30,12,2],
[91,30,12,3],
[91,30,12,4],
[91,30,12,5],
[91,30,12,6],
[91,30,12,7],
[91,30,12,8],
[91,30,12,10],
[91,30,13,0],
[91,30,13,1],
[91,30,13,2],
[91,30,13,3],
[91,30,13,4],
[91,30,13,5],
[91,30,13,6],
[91,30,13,7],
[91,30,13,8],
[91,30,13,10],
[91,30,13,11],
[91,30,13,12],
[91,30,14,0],
[91,30,14,1],
[91,30,14,2],
[91,30,14,3],
[91,30,14,4],
[91,30,14,5],
[91,30,14,6],
[91,30,14,7],
[91,30,14,8],
[91,30,14,10],
[91,30,14,11],
[91,30,14,12],
[91,30,16,0],
[91,30,16,1],
[91,30,16,2],
[91,30,16,3],
[91,30,16,4],
[91,30,16,5],
[91,30,16,6],
[91,30,16,7],
[91,30,16,8],
[91,30,16,10],
[91,30,16,11],
[91,30,16,12],
[91,30,16,13],
[91,30,17,0],
[91,30,17,1],
[91,30,17,2],
[91,30,17,3],
[91,30,17,4],
[91,30,17,5],
[91,30,17,6],
[91,30,17,7],
[91,30,17,8],
[91,30,17,10],
[91,30,17,11],
[91,30,17,12],
[91,30,17,13],
[91,30,19,0],
[91,30,19,1],
[91,30,19,2],
[91,30,19,3],
[91,30,19,4],
[91,30,19,5],
[91,30,19,6],
[91,30,19,7],
[91,30,19,8],
[91,30,20,0],
[91,30,20,1],
[91,30,20,2],
[91,30,20,3],
[91,30,20,4],
[91,30,20,5],
[91,30,20,6],
[91,30,20,7],
[91,30,21,0],
[91,30,21,1],
[91,30,21,2],
[91,30,21,4],
[91,30,22,0],
[91,30,22,1],
[91,30,22,2],
[91,30,22,3],
[91,30,22,4],
[91,30,24,0],
[91,30,24,1],
[91,30,24,2],
[91,31,3,0],
[91,31,3,1],
[91,31,3,2],
[91,31,4,0],
[91,31,4,1],
[91,31,4,2],
[91,31,5,4],
[91,31,6,0],
[91,31,6,1],
[91,31,6,2],
[91,31,6,4],
[91,31,7,0],
[91,31,7,1],
[91,31,7,2],
[91,31,7,3],
[91,31,7,4],
[91,31,7,5],
[91,31,7,6],
[91,31,8,0],
[91,31,8,1],
[91,31,8,2],
[91,31,8,4],
[91,31,8,7],
[91,31,10,0],
[91,31,10,1],
[91,31,10,2],
[91,31,10,3],
[91,31,10,4],
[91,31,10,5],
[91,31,10,6],
[91,31,10,7],
[91,31,10,8],
[91,31,11,0],
[91,31,11,1],
[91,31,11,2],
[91,31,11,3],
[91,31,11,4],
[91,31,11,5],
[91,31,11,6],
[91,31,11,7],
[91,31,11,8],
[91,31,11,10],
[91,31,12,0],
[91,31,12,1],
[91,31,12,2],
[91,31,12,3],
[91,31,12,4],
[91,31,12,5],
[91,31,12,6],
[91,31,12,7],
[91,31,12,8],
[91,31,12,10],
[91,31,13,0],
[91,31,13,1],
[91,31,13,2],
[91,31,13,3],
[91,31,13,4],
[91,31,13,5],
[91,31,13,6],
[91,31,13,7],
[91,31,13,8],
[91,31,13,10],
[91,31,13,11],
[91,31,13,12],
[91,31,14,0],
[91,31,14,1],
[91,31,14,2],
[91,31,14,3],
[91,31,14,4],
[91,31,14,5],
[91,31,14,6],
[91,31,14,7],
[91,31,14,8],
[91,31,14,10],
[91,31,14,11],
[91,31,14,12],
[91,31,16,0],
[91,31,16,1],
[91,31,16,2],
[91,31,16,3],
[91,31,16,4],
[91,31,16,5],
[91,31,16,6],
[91,31,16,7],
[91,31,16,8],
[91,31,16,10],
[91,31,16,11],
[91,31,17,0],
[91,31,17,1],
[91,31,17,2],
[91,31,17,3],
[91,31,17,4],
[91,31,17,5],
[91,31,17,6],
[91,31,17,7],
[91,31,17,8],
[91,31,17,10],
[91,31,17,11],
[91,31,19,0],
[91,31,19,1],
[91,31,19,2],
[91,31,19,3],
[91,31,19,4],
[91,31,19,5],
[91,31,19,6],
[91,31,19,7],
[91,31,20,0],
[91,31,20,1],
[91,31,20,2],
[91,31,20,3],
[91,31,20,4],
[91,31,20,5],
[91,31,21,0],
[91,31,21,1],
[91,31,21,2],
[91,31,21,4],
[91,31,22,0],
[91,31,22,1],
[91,31,22,2],
[91,32,4,3],
[91,32,5,0],
[91,32,5,1],
[91,32,5,2],
[91,32,5,4],
[91,32,6,0],
[91,32,6,1],
[91,32,6,2],
[91,32,6,4],
[91,32,7,0],
[91,32,7,1],
[91,32,7,2],
[91,32,7,3],
[91,32,7,4],
[91,32,7,5],
[91,32,7,6],
[91,32,8,0],
[91,32,8,1],
[91,32,8,2],
[91,32,8,4],
[91,32,8,7],
[91,32,10,0],
[91,32,10,1],
[91,32,10,2],
[91,32,10,3],
[91,32,10,4],
[91,32,10,5],
[91,32,10,6],
[91,32,10,7],
[91,32,10,8],
[91,32,13,0],
[91,32,13,1],
[91,32,13,2],
[91,32,13,3],
[91,32,13,4],
[91,32,13,5],
[91,32,13,6],
[91,32,13,7],
[91,32,13,8],
[91,32,13,10],
[91,32,14,0],
[91,32,14,1],
[91,32,14,2],
[91,32,14,3],
[91,32,14,4],
[91,32,14,5],
[91,32,14,6],
[91,32,14,7],
[91,32,14,8],
[91,32,14,10],
[91,32,16,0],
[91,32,16,1],
[91,32,16,2],
[91,32,16,3],
[91,32,16,4],
[91,32,16,5],
[91,32,16,6],
[91,32,16,7],
[91,32,16,8],
[91,32,17,0],
[91,32,17,1],
[91,32,17,2],
[91,32,17,3],
[91,32,17,4],
[91,32,17,5],
[91,32,17,6],
[91,32,17,7],
[91,32,17,8],
[91,32,19,0],
[91,32,19,1],
[91,32,19,2],
[91,32,19,3],
[91,32,19,4],
[91,32,19,5],
[91,32,20,0],
[91,32,20,1],
[91,32,20,2],
[91,32,20,3],
[91,32,20,4],
[91,32,21,0],
[91,32,21,1],
[91,32,21,2],
[91,33,3,0],
[91,33,3,1],
[91,33,3,2],
[91,33,4,0],
[91,33,4,1],
[91,33,4,2],
[91,33,4,3],
[91,33,5,0],
[91,33,5,1],
[91,33,5,2],
[91,33,5,4],
[91,33,6,0],
[91,33,6,1],
[91,33,6,2],
[91,33,6,4],
[91,33,7,0],
[91,33,7,1],
[91,33,7,2],
[91,33,7,3],
[91,33,7,4],
[91,33,7,5],
[91,33,7,6],
[91,33,8,0],
[91,33,8,1],
[91,33,8,2],
[91,33,8,4],
[91,33,8,7],
[91,33,10,0],
[91,33,10,1],
[91,33,10,2],
[91,33,10,3],
[91,33,10,4],
[91,33,10,5],
[91,33,10,6],
[91,33,10,7],
[91,33,10,8],
[91,33,13,0],
[91,33,13,1],
[91,33,13,2],
[91,33,13,3],
[91,33,13,4],
[91,33,13,5],
[91,33,13,6],
[91,33,13,7],
[91,33,13,8],
[91,33,13,10],
[91,33,14,0],
[91,33,14,1],
[91,33,14,2],
[91,33,14,3],
[91,33,14,4],
[91,33,14,5],
[91,33,14,6],
[91,33,14,7],
[91,33,14,8],
[91,33,14,10],
[91,33,16,0],
[91,33,16,1],
[91,33,16,2],
[91,33,16,3],
[91,33,16,4],
[91,33,16,5],
[91,33,16,6],
[91,33,16,7],
[91,33,17,0],
[91,33,17,1],
[91,33,17,2],
[91,33,17,3],
[91,33,17,4],
[91,33,17,5],
[91,33,17,6],
[91,33,17,7],
[91,33,19,0],
[91,33,19,1],
[91,33,19,2],
[91,33,19,3],
[91,33,19,4],
[91,33,20,0],
[91,33,20,1],
[91,33,20,2],
[91,34,3,0],
[91,34,3,1],
[91,34,3,2],
[91,34,4,0],
[91,34,4,1],
[91,34,4,2],
[91,34,4,3],
[91,34,5,0],
[91,34,5,1],
[91,34,5,2],
[91,34,5,4],
[91,34,6,0],
[91,34,6,1],
[91,34,6,2],
[91,34,6,4],
[91,34,8,0],
[91,34,8,1],
[91,34,8,2],
[91,34,8,4],
[91,34,10,0],
[91,34,10,1],
[91,34,10,2],
[91,34,10,3],
[91,34,10,4],
[91,34,10,5],
[91,34,10,6],
[91,34,10,8],
[91,34,11,0],
[91,34,11,1],
[91,34,11,2],
[91,34,11,3],
[91,34,11,4],
[91,34,11,5],
[91,34,11,6],
[91,34,11,8],
[91,34,11,10],
[91,34,12,0],
[91,34,12,1],
[91,34,12,2],
[91,34,12,3],
[91,34,12,4],
[91,34,12,5],
[91,34,12,6],
[91,34,12,8],
[91,34,12,10],
[91,34,13,0],
[91,34,13,1],
[91,34,13,2],
[91,34,13,3],
[91,34,13,4],
[91,34,13,5],
[91,34,13,6],
[91,34,13,8],
[91,34,13,10],
[91,34,13,11],
[91,34,14,0],
[91,34,14,1],
[91,34,14,2],
[91,34,14,3],
[91,34,14,4],
[91,34,14,5],
[91,34,14,6],
[91,34,14,8],
[91,34,16,0],
[91,34,16,1],
[91,34,16,2],
[91,34,16,3],
[91,34,16,4],
[91,34,16,5],
[91,34,17,0],
[91,34,17,1],
[91,34,17,2],
[91,34,17,3],
[91,34,17,4],
[91,34,17,5],
[91,34,19,0],
[91,34,19,1],
[91,34,19,2],
[91,36,3,0],
[91,36,3,1],
[91,36,3,2],
[91,36,4,0],
[91,36,4,1],
[91,36,4,2],
[91,36,4,3],
[91,36,5,0],
[91,36,5,1],
[91,36,5,2],
[91,36,5,4],
[91,36,6,0],
[91,36,6,1],
[91,36,6,2],
[91,36,6,4],
[91,36,7,0],
[91,36,7,1],
[91,36,7,2],
[91,36,7,3],
[91,36,7,4],
[91,36,7,5],
[91,36,7,6],
[91,36,8,0],
[91,36,8,1],
[91,36,8,2],
[91,36,8,4],
[91,36,8,7],
[91,36,10,0],
[91,36,10,1],
[91,36,10,2],
[91,36,10,3],
[91,36,10,4],
[91,36,10,5],
[91,36,10,6],
[91,36,10,7],
[91,36,10,8],
[91,36,11,0],
[91,36,11,1],
[91,36,11,2],
[91,36,11,3],
[91,36,11,4],
[91,36,11,5],
[91,36,11,6],
[91,36,11,7],
[91,36,11,8],
[91,36,12,0],
[91,36,12,1],
[91,36,12,2],
[91,36,12,3],
[91,36,12,4],
[91,36,12,5],
[91,36,12,6],
[91,36,12,7],
[91,36,16,0],
[91,36,16,1],
[91,36,16,2],
[91,36,17,0],
[91,36,17,1],
[91,36,17,2],
[91,37,3,0],
[91,37,3,1],
[91,37,3,2],
[91,37,5,0],
[91,37,5,1],
[91,37,5,2],
[91,37,6,0],
[91,37,6,1],
[91,37,6,2],
[91,37,7,0],
[91,37,7,1],
[91,37,7,2],
[91,37,7,3],
[91,37,7,5],
[91,37,7,6],
[91,37,8,0],
[91,37,8,1],
[91,37,8,2],
[91,37,8,7],
[91,37,10,0],
[91,37,10,1],
[91,37,10,2],
[91,37,10,3],
[91,37,10,5],
[91,37,10,6],
[91,37,10,7],
[91,37,11,0],
[91,37,11,1],
[91,37,11,2],
[91,37,11,3],
[91,37,11,5],
[91,37,11,6],
[91,37,11,7],
[91,37,12,0],
[91,37,12,1],
[91,37,12,2],
[91,37,12,3],
[91,37,12,5],
[91,37,13,0],
[91,37,13,1],
[91,37,13,2],
[91,37,13,3],
[91,37,13,5],
[91,37,14,0],
[91,37,14,1],
[91,37,14,2],
[91,37,14,3],
[91,38,3,0],
[91,38,3,1],
[91,38,3,2],
[91,38,4,0],
[91,38,4,1],
[91,38,4,2],
[91,38,4,3],
[91,38,5,0],
[91,38,5,1],
[91,38,5,2],
[91,38,5,4],
[91,38,6,0],
[91,38,6,1],
[91,38,6,2],
[91,38,6,4],
[91,38,8,0],
[91,38,8,1],
[91,38,8,2],
[91,38,8,4],
[91,38,10,0],
[91,38,10,1],
[91,38,10,2],
[91,38,10,3],
[91,38,10,4],
[91,38,10,5],
[91,38,11,0],
[91,38,11,1],
[91,38,11,2],
[91,38,11,3],
[91,38,11,4],
[91,38,11,5],
[91,38,12,0],
[91,38,12,1],
[91,38,12,2],
[91,38,12,3],
[91,38,12,4],
[91,38,13,0],
[91,38,13,1],
[91,38,13,2],
[91,38,13,3],
[91,38,13,4],
[91,38,14,0],
[91,38,14,1],
[91,38,14,2],
[91,39,4,0],
[91,39,4,1],
[91,39,4,2],
[91,39,7,0],
[91,39,7,1],
[91,39,7,2],
[91,39,7,4],
[91,39,10,0],
[91,39,10,1],
[91,39,10,2],
[91,39,10,4],
[91,39,11,0],
[91,39,11,1],
[91,39,11,2],
[91,39,11,4],
[91,39,12,0],
[91,39,12,1],
[91,39,12,2],
[91,39,13,0],
[91,39,13,1],
[91,39,13,2],
[91,42,3,0],
[91,42,3,1],
[91,42,3,2],
[91,42,4,0],
[91,42,4,1],
[91,42,4,2],
[91,42,4,3],
[91,42,5,0],
[91,42,5,1],
[91,42,5,2],
[91,42,5,4],
[91,42,6,0],
[91,42,6,1],
[91,42,6,2],
[91,42,6,4],
[91,42,7,0],
[91,42,7,1],
[91,42,7,2],
[91,42,7,3],
[91,42,7,4],
[91,42,8,0],
[91,42,8,1],
[91,42,8,2],
[91,43,3,0],
[91,43,3,1],
[91,43,3,2],
[91,43,4,0],
[91,43,4,1],
[91,43,4,2],
[91,43,4,3],
[91,43,5,0],
[91,43,5,1],
[91,43,5,2],
[91,43,5,4],
[91,43,6,0],
[91,43,6,1],
[91,43,6,2],
[91,44,3,0],
[91,44,3,1],
[91,44,3,2],
[91,44,4,0],
[91,44,4,1],
[91,44,4,2],
[91,44,4,3],
[91,44,5,0],
[91,44,5,1],
[91,44,5,2],
[92,4,3,0],
[92,4,3,1],
[92,4,3,2],
[92,5,4,0],
[92,5,4,1],
[92,5,4,2],
[92,6,4,0],
[92,6,4,1],
[92,6,4,2],
[92,8,4,0],
[92,8,4,1],
[92,8,4,2],
[92,9,3,0],
[92,9,3,1],
[92,9,3,2],
[92,9,4,0],
[92,9,4,1],
[92,9,4,2],
[92,9,4,3],
[92,9,5,0],
[92,9,5,1],
[92,9,5,2],
[92,9,5,4],
[92,9,6,0],
[92,9,6,1],
[92,9,6,2],
[92,9,6,4],
[92,9,8,0],
[92,9,8,1],
[92,9,8,2],
[92,9,8,4],
[92,10,3,0],
[92,10,3,1],
[92,10,3,2],
[92,10,4,0],
[92,10,4,1],
[92,10,4,2],
[92,10,4,3],
[92,10,5,0],
[92,10,5,1],
[92,10,5,2],
[92,10,5,4],
[92,10,6,0],
[92,10,6,1],
[92,10,6,2],
[92,10,6,4],
[92,10,8,0],
[92,10,8,1],
[92,10,8,2],
[92,10,8,4],
[92,10,9,0],
[92,10,9,1],
[92,10,9,2],
[92,10,9,3],
[92,10,9,4],
[92,10,9,5],
[92,10,9,6],
[92,10,9,8],
[92,11,3,0],
[92,11,3,1],
[92,11,3,2],
[92,11,4,0],
[92,11,4,1],
[92,11,4,2],
[92,11,4,3],
[92,11,5,0],
[92,11,5,1],
[92,11,5,2],
[92,11,5,4],
[92,11,6,0],
[92,11,6,1],
[92,11,6,2],
[92,11,6,4],
[92,11,8,0],
[92,11,8,1],
[92,11,8,2],
[92,11,8,4],
[92,11,9,0],
[92,11,9,1],
[92,11,9,2],
[92,11,9,3],
[92,11,9,4],
[92,11,9,5],
[92,11,9,6],
[92,11,9,8],
[92,11,10,0],
[92,11,10,1],
[92,11,10,2],
[92,11,10,3],
[92,11,10,4],
[92,11,10,5],
[92,11,10,6],
[92,11,10,8],
[92,11,10,9],
[92,12,3,0],
[92,12,3,1],
[92,12,3,2],
[92,12,4,0],
[92,12,4,1],
[92,12,4,2],
[92,12,4,3],
[92,12,5,0],
[92,12,5,1],
[92,12,5,2],
[92,12,5,4],
[92,12,6,0],
[92,12,6,1],
[92,12,6,2],
[92,12,6,4],
[92,12,8,0],
[92,12,8,1],
[92,12,8,2],
[92,12,8,4],
[92,12,9,0],
[92,12,9,1],
[92,12,9,2],
[92,12,9,3],
[92,12,9,4],
[92,12,9,5],
[92,12,9,6],
[92,12,9,8],
[92,12,10,0],
[92,12,10,1],
[92,12,10,2],
[92,12,10,3],
[92,12,10,4],
[92,12,10,5],
[92,12,10,6],
[92,12,10,8],
[92,12,10,9],
[92,13,3,0],
[92,13,3,1],
[92,13,3,2],
[92,13,4,0],
[92,13,4,1],
[92,13,4,2],
[92,13,4,3],
[92,13,5,0],
[92,13,5,1],
[92,13,5,2],
[92,13,5,4],
[92,13,6,0],
[92,13,6,1],
[92,13,6,2],
[92,13,6,4],
[92,13,8,0],
[92,13,8,1],
[92,13,8,2],
[92,13,8,4],
[92,13,9,0],
[92,13,9,1],
[92,13,9,2],
[92,13,9,3],
[92,13,9,4],
[92,13,9,5],
[92,13,9,6],
[92,13,9,8],
[92,13,10,0],
[92,13,10,1],
[92,13,10,2],
[92,13,10,3],
[92,13,10,4],
[92,13,10,5],
[92,13,10,6],
[92,13,10,8],
[92,13,10,9],
[92,13,11,0],
[92,13,11,1],
[92,13,11,2],
[92,13,11,3],
[92,13,11,4],
[92,13,11,5],
[92,13,11,6],
[92,13,11,8],
[92,13,11,9],
[92,13,11,10],
[92,13,12,0],
[92,13,12,1],
[92,13,12,2],
[92,13,12,3],
[92,13,12,4],
[92,13,12,5],
[92,13,12,6],
[92,13,12,8],
[92,13,12,9],
[92,13,12,10],
[92,14,3,0],
[92,14,3,1],
[92,14,3,2],
[92,14,4,0],
[92,14,4,1],
[92,14,4,2],
[92,14,4,3],
[92,14,5,0],
[92,14,5,1],
[92,14,5,2],
[92,14,5,4],
[92,14,6,0],
[92,14,6,1],
[92,14,6,2],
[92,14,6,4],
[92,14,8,0],
[92,14,8,1],
[92,14,8,2],
[92,14,8,4],
[92,14,9,0],
[92,14,9,1],
[92,14,9,2],
[92,14,9,3],
[92,14,9,4],
[92,14,9,5],
[92,14,9,6],
[92,14,9,8],
[92,14,10,0],
[92,14,10,1],
[92,14,10,2],
[92,14,10,3],
[92,14,10,4],
[92,14,10,5],
[92,14,10,6],
[92,14,10,8],
[92,14,10,9],
[92,14,11,0],
[92,14,11,1],
[92,14,11,2],
[92,14,11,3],
[92,14,11,4],
[92,14,11,5],
[92,14,11,6],
[92,14,11,8],
[92,14,11,9],
[92,14,11,10],
[92,14,12,0],
[92,14,12,1],
[92,14,12,2],
[92,14,12,3],
[92,14,12,4],
[92,14,12,5],
[92,14,12,6],
[92,14,12,8],
[92,14,12,9],
[92,14,12,10],
[92,15,3,0],
[92,15,3,1],
[92,15,3,2],
[92,15,4,0],
[92,15,4,1],
[92,15,4,2],
[92,15,4,3],
[92,15,5,0],
[92,15,5,1],
[92,15,5,2],
[92,15,5,4],
[92,15,6,0],
[92,15,6,1],
[92,15,6,2],
[92,15,6,4],
[92,15,8,0],
[92,15,8,1],
[92,15,8,2],
[92,15,8,4],
[92,15,10,0],
[92,15,10,1],
[92,15,10,2],
[92,15,10,3],
[92,15,10,4],
[92,15,10,5],
[92,15,10,6],
[92,15,10,8],
[92,15,11,0],
[92,15,11,1],
[92,15,11,2],
[92,15,11,3],
[92,15,11,4],
[92,15,11,5],
[92,15,11,6],
[92,15,11,8],
[92,15,11,10],
[92,15,12,0],
[92,15,12,1],
[92,15,12,2],
[92,15,12,3],
[92,15,12,4],
[92,15,12,5],
[92,15,12,6],
[92,15,12,8],
[92,15,12,10],
[92,15,13,0],
[92,15,13,1],
[92,15,13,2],
[92,15,13,3],
[92,15,13,4],
[92,15,13,5],
[92,15,13,6],
[92,15,13,8],
[92,15,13,10],
[92,15,13,11],
[92,15,13,12],
[92,15,14,0],
[92,15,14,1],
[92,15,14,2],
[92,15,14,3],
[92,15,14,4],
[92,15,14,5],
[92,15,14,6],
[92,15,14,8],
[92,15,14,10],
[92,15,14,11],
[92,15,14,12],
[92,16,3,0],
[92,16,3,1],
[92,16,3,2],
[92,16,4,0],
[92,16,4,1],
[92,16,4,2],
[92,16,4,3],
[92,16,5,0],
[92,16,5,1],
[92,16,5,2],
[92,16,5,4],
[92,16,6,0],
[92,16,6,1],
[92,16,6,2],
[92,16,6,4],
[92,16,8,0],
[92,16,8,1],
[92,16,8,2],
[92,16,8,4],
[92,16,9,0],
[92,16,9,1],
[92,16,9,2],
[92,16,9,3],
[92,16,9,4],
[92,16,9,5],
[92,16,9,6],
[92,16,9,8],
[92,16,10,0],
[92,16,10,1],
[92,16,10,2],
[92,16,10,3],
[92,16,10,4],
[92,16,10,5],
[92,16,10,6],
[92,16,10,8],
[92,16,10,9],
[92,16,11,0],
[92,16,11,1],
[92,16,11,2],
[92,16,11,3],
[92,16,11,4],
[92,16,11,5],
[92,16,11,6],
[92,16,11,8],
[92,16,11,9],
[92,16,11,10],
[92,16,12,0],
[92,16,12,1],
[92,16,12,2],
[92,16,12,3],
[92,16,12,4],
[92,16,12,5],
[92,16,12,6],
[92,16,12,8],
[92,16,12,9],
[92,16,12,10],
[92,16,13,0],
[92,16,13,1],
[92,16,13,2],
[92,16,13,3],
[92,16,13,4],
[92,16,13,5],
[92,16,13,6],
[92,16,13,8],
[92,16,13,9],
[92,16,13,10],
[92,16,13,11],
[92,16,13,12],
[92,16,14,0],
[92,16,14,1],
[92,16,14,2],
[92,16,14,3],
[92,16,14,4],
[92,16,14,5],
[92,16,14,6],
[92,16,14,8],
[92,16,14,9],
[92,16,14,10],
[92,16,14,11],
[92,16,14,12],
[92,16,15,0],
[92,16,15,1],
[92,16,15,2],
[92,16,15,3],
[92,16,15,4],
[92,16,15,5],
[92,16,15,6],
[92,16,15,8],
[92,16,15,10],
[92,16,15,11],
[92,16,15,12],
[92,16,15,13],
[92,17,3,0],
[92,17,3,1],
[92,17,3,2],
[92,17,4,0],
[92,17,4,1],
[92,17,4,2],
[92,17,4,3],
[92,17,5,0],
[92,17,5,1],
[92,17,5,2],
[92,17,5,4],
[92,17,6,0],
[92,17,6,1],
[92,17,6,2],
[92,17,6,4],
[92,17,8,0],
[92,17,8,1],
[92,17,8,2],
[92,17,8,4],
[92,17,9,0],
[92,17,9,1],
[92,17,9,2],
[92,17,9,3],
[92,17,9,4],
[92,17,9,5],
[92,17,9,6],
[92,17,9,8],
[92,17,10,0],
[92,17,10,1],
[92,17,10,2],
[92,17,10,3],
[92,17,10,4],
[92,17,10,5],
[92,17,10,6],
[92,17,10,8],
[92,17,10,9],
[92,17,11,0],
[92,17,11,1],
[92,17,11,2],
[92,17,11,3],
[92,17,11,4],
[92,17,11,5],
[92,17,11,6],
[92,17,11,8],
[92,17,11,9],
[92,17,11,10],
[92,17,12,0],
[92,17,12,1],
[92,17,12,2],
[92,17,12,3],
[92,17,12,4],
[92,17,12,5],
[92,17,12,6],
[92,17,12,8],
[92,17,12,9],
[92,17,12,10],
[92,17,13,0],
[92,17,13,1],
[92,17,13,2],
[92,17,13,3],
[92,17,13,4],
[92,17,13,5],
[92,17,13,6],
[92,17,13,8],
[92,17,13,9],
[92,17,13,10],
[92,17,13,11],
[92,17,13,12],
[92,17,14,0],
[92,17,14,1],
[92,17,14,2],
[92,17,14,3],
[92,17,14,4],
[92,17,14,5],
[92,17,14,6],
[92,17,14,8],
[92,17,14,9],
[92,17,14,10],
[92,17,14,11],
[92,17,14,12],
[92,17,15,0],
[92,17,15,1],
[92,17,15,2],
[92,17,15,3],
[92,17,15,4],
[92,17,15,5],
[92,17,15,6],
[92,17,15,8],
[92,17,15,10],
[92,17,15,11],
[92,17,15,12],
[92,17,15,13],
[92,17,16,0],
[92,17,16,1],
[92,17,16,2],
[92,17,16,3],
[92,17,16,4],
[92,17,16,5],
[92,17,16,6],
[92,17,16,8],
[92,17,16,9],
[92,17,16,10],
[92,17,16,11],
[92,17,16,14],
[92,17,16,15],
[92,18,3,0],
[92,18,3,1],
[92,18,3,2],
[92,18,4,0],
[92,18,4,1],
[92,18,4,2],
[92,18,4,3],
[92,18,5,0],
[92,18,5,1],
[92,18,5,2],
[92,18,5,4],
[92,18,6,0],
[92,18,6,1],
[92,18,6,2],
[92,18,6,4],
[92,18,8,0],
[92,18,8,1],
[92,18,8,2],
[92,18,8,4],
[92,18,10,0],
[92,18,10,1],
[92,18,10,2],
[92,18,10,3],
[92,18,10,4],
[92,18,10,5],
[92,18,10,6],
[92,18,10,8],
[92,18,11,0],
[92,18,11,1],
[92,18,11,2],
[92,18,11,3],
[92,18,11,4],
[92,18,11,5],
[92,18,11,6],
[92,18,11,8],
[92,18,11,10],
[92,18,12,0],
[92,18,12,1],
[92,18,12,2],
[92,18,12,3],
[92,18,12,4],
[92,18,12,5],
[92,18,12,6],
[92,18,12,8],
[92,18,12,10],
[92,18,13,0],
[92,18,13,1],
[92,18,13,2],
[92,18,13,3],
[92,18,13,4],
[92,18,13,5],
[92,18,13,6],
[92,18,13,8],
[92,18,13,10],
[92,18,13,11],
[92,18,13,12],
[92,18,14,0],
[92,18,14,1],
[92,18,14,2],
[92,18,14,3],
[92,18,14,4],
[92,18,14,5],
[92,18,14,6],
[92,18,14,8],
[92,18,14,10],
[92,18,14,11],
[92,18,14,12],
[92,18,16,0],
[92,18,16,1],
[92,18,16,2],
[92,18,16,3],
[92,18,16,4],
[92,18,16,5],
[92,18,16,6],
[92,18,16,8],
[92,18,16,12],
[92,18,16,13],
[92,18,16,14],
[92,18,17,0],
[92,18,17,1],
[92,18,17,2],
[92,18,17,3],
[92,18,17,4],
[92,18,17,5],
[92,18,17,6],
[92,18,17,8],
[92,18,17,12],
[92,18,17,13],
[92,18,17,14],
[92,18,17,16],
[92,19,3,0],
[92,19,3,1],
[92,19,3,2],
[92,19,4,0],
[92,19,4,1],
[92,19,4,2],
[92,19,4,3],
[92,19,5,0],
[92,19,5,1],
[92,19,5,2],
[92,19,5,4],
[92,19,6,0],
[92,19,6,1],
[92,19,6,2],
[92,19,6,4],
[92,19,8,0],
[92,19,8,1],
[92,19,8,2],
[92,19,8,4],
[92,19,9,0],
[92,19,9,1],
[92,19,9,2],
[92,19,9,3],
[92,19,9,4],
[92,19,9,5],
[92,19,9,6],
[92,19,9,8],
[92,19,10,0],
[92,19,10,1],
[92,19,10,2],
[92,19,10,3],
[92,19,10,4],
[92,19,10,5],
[92,19,10,6],
[92,19,10,8],
[92,19,10,9],
[92,19,11,0],
[92,19,11,1],
[92,19,11,2],
[92,19,11,3],
[92,19,11,4],
[92,19,11,5],
[92,19,11,6],
[92,19,11,8],
[92,19,11,9],
[92,19,11,10],
[92,19,12,0],
[92,19,12,1],
[92,19,12,2],
[92,19,12,3],
[92,19,12,4],
[92,19,12,5],
[92,19,12,6],
[92,19,12,8],
[92,19,12,9],
[92,19,12,10],
[92,19,15,0],
[92,19,15,1],
[92,19,15,2],
[92,19,15,3],
[92,19,15,4],
[92,19,15,5],
[92,19,15,6],
[92,19,15,8],
[92,19,15,12],
[92,19,16,0],
[92,19,16,1],
[92,19,16,2],
[92,19,16,3],
[92,19,16,4],
[92,19,16,5],
[92,19,16,6],
[92,19,16,10],
[92,19,16,11],
[92,19,16,12],
[92,19,16,15],
[92,19,17,0],
[92,19,17,1],
[92,19,17,2],
[92,19,17,3],
[92,19,17,4],
[92,19,17,5],
[92,19,17,6],
[92,19,17,10],
[92,19,17,11],
[92,19,17,12],
[92,19,17,15],
[92,19,17,16],
[92,19,18,0],
[92,19,18,1],
[92,19,18,2],
[92,19,18,3],
[92,19,18,4],
[92,19,18,5],
[92,19,18,8],
[92,19,18,10],
[92,19,18,11],
[92,19,18,12],
[92,19,18,16],
[92,19,18,17],
[92,20,3,0],
[92,20,3,1],
[92,20,3,2],
[92,20,4,0],
[92,20,4,1],
[92,20,4,2],
[92,20,4,3],
[92,20,5,0],
[92,20,5,1],
[92,20,5,2],
[92,20,5,4],
[92,20,6,0],
[92,20,6,1],
[92,20,6,2],
[92,20,6,4],
[92,20,8,0],
[92,20,8,1],
[92,20,8,2],
[92,20,8,4],
[92,20,9,0],
[92,20,9,1],
[92,20,9,2],
[92,20,9,3],
[92,20,9,4],
[92,20,9,5],
[92,20,9,6],
[92,20,9,8],
[92,20,11,0],
[92,20,11,1],
[92,20,11,2],
[92,20,11,3],
[92,20,11,4],
[92,20,11,5],
[92,20,11,6],
[92,20,11,8],
[92,20,11,9],
[92,20,12,0],
[92,20,12,1],
[92,20,12,2],
[92,20,12,3],
[92,20,12,4],
[92,20,12,5],
[92,20,12,6],
[92,20,12,8],
[92,20,12,9],
[92,20,13,0],
[92,20,13,1],
[92,20,13,2],
[92,20,13,3],
[92,20,13,4],
[92,20,13,5],
[92,20,13,6],
[92,20,13,8],
[92,20,13,9],
[92,20,13,11],
[92,20,14,0],
[92,20,14,1],
[92,20,14,2],
[92,20,14,3],
[92,20,14,4],
[92,20,14,5],
[92,20,14,6],
[92,20,14,8],
[92,20,14,9],
[92,20,14,12],
[92,20,15,0],
[92,20,15,1],
[92,20,15,2],
[92,20,15,3],
[92,20,15,4],
[92,20,15,5],
[92,20,15,6],
[92,20,15,11],
[92,20,15,12],
[92,20,15,13],
[92,20,15,14],
[92,20,16,0],
[92,20,16,1],
[92,20,16,2],
[92,20,16,3],
[92,20,16,4],
[92,20,16,5],
[92,20,16,8],
[92,20,16,9],
[92,20,16,11],
[92,20,16,12],
[92,20,16,13],
[92,20,16,14],
[92,20,16,15],
[92,20,17,0],
[92,20,17,1],
[92,20,17,2],
[92,20,17,3],
[92,20,17,4],
[92,20,17,5],
[92,20,17,8],
[92,20,17,9],
[92,20,17,11],
[92,20,17,12],
[92,20,17,13],
[92,20,17,14],
[92,20,17,15],
[92,20,17,16],
[92,20,18,0],
[92,20,18,1],
[92,20,18,2],
[92,20,18,3],
[92,20,18,4],
[92,20,18,6],
[92,20,18,8],
[92,20,18,11],
[92,20,18,12],
[92,20,18,13],
[92,20,18,14],
[92,20,18,16],
[92,20,18,17],
[92,20,19,0],
[92,20,19,1],
[92,20,19,2],
[92,20,19,5],
[92,20,19,6],
[92,20,19,8],
[92,20,19,9],
[92,20,19,11],
[92,20,19,12],
[92,20,19,15],
[92,20,19,16],
[92,20,19,17],
[92,20,19,18],
[92,21,4,0],
[92,21,4,1],
[92,21,4,2],
[92,21,9,0],
[92,21,9,1],
[92,21,9,2],
[92,21,9,4],
[92,21,10,0],
[92,21,10,1],
[92,21,10,2],
[92,21,10,4],
[92,21,10,9],
[92,21,11,0],
[92,21,11,1],
[92,21,11,2],
[92,21,11,4],
[92,21,11,9],
[92,21,11,10],
[92,21,12,0],
[92,21,12,1],
[92,21,12,2],
[92,21,12,4],
[92,21,12,9],
[92,21,13,0],
[92,21,13,1],
[92,21,13,2],
[92,21,13,4],
[92,21,13,9],
[92,21,13,12],
[92,21,14,0],
[92,21,14,1],
[92,21,14,2],
[92,21,14,4],
[92,21,14,10],
[92,21,14,11],
[92,21,14,12],
[92,21,15,0],
[92,21,15,1],
[92,21,15,2],
[92,21,15,4],
[92,21,15,10],
[92,21,15,11],
[92,21,15,12],
[92,21,15,13],
[92,21,15,14],
[92,21,16,0],
[92,21,16,1],
[92,21,16,2],
[92,21,16,4],
[92,21,16,9],
[92,21,16,10],
[92,21,16,11],
[92,21,16,12],
[92,21,16,13],
[92,21,16,14],
[92,21,16,15],
[92,21,17,0],
[92,21,17,1],
[92,21,17,2],
[92,21,17,4],
[92,21,17,9],
[92,21,17,10],
[92,21,17,11],
[92,21,17,12],
[92,21,17,13],
[92,21,17,14],
[92,21,17,15],
[92,21,17,16],
[92,21,18,0],
[92,21,18,1],
[92,21,18,2],
[92,21,18,10],
[92,21,18,11],
[92,21,18,12],
[92,21,18,13],
[92,21,18,14],
[92,21,18,16],
[92,21,18,17],
[92,21,19,4],
[92,21,19,9],
[92,21,19,10],
[92,21,19,11],
[92,21,19,12],
[92,21,19,15],
[92,21,19,16],
[92,21,19,17],
[92,21,19,18],
[92,21,20,0],
[92,21,20,1],
[92,21,20,2],
[92,21,20,4],
[92,21,20,9],
[92,21,20,11],
[92,21,20,12],
[92,21,20,13],
[92,21,20,14],
[92,21,20,15],
[92,21,20,16],
[92,21,20,17],
[92,21,20,18],
[92,21,20,19],
[92,22,3,0],
[92,22,3,1],
[92,22,3,2],
[92,22,4,0],
[92,22,4,1],
[92,22,4,2],
[92,22,4,3],
[92,22,5,0],
[92,22,5,1],
[92,22,5,2],
[92,22,5,4],
[92,22,6,0],
[92,22,6,1],
[92,22,6,2],
[92,22,6,4],
[92,22,8,0],
[92,22,8,1],
[92,22,8,2],
[92,22,8,4],
[92,22,9,0],
[92,22,9,1],
[92,22,9,2],
[92,22,9,3],
[92,22,9,4],
[92,22,9,5],
[92,22,9,6],
[92,22,9,8],
[92,22,11,0],
[92,22,11,1],
[92,22,11,2],
[92,22,11,3],
[92,22,11,4],
[92,22,11,5],
[92,22,11,6],
[92,22,11,8],
[92,22,11,9],
[92,22,12,0],
[92,22,12,1],
[92,22,12,2],
[92,22,12,3],
[92,22,12,4],
[92,22,12,5],
[92,22,12,6],
[92,22,13,0],
[92,22,13,1],
[92,22,13,2],
[92,22,13,3],
[92,22,13,4],
[92,22,13,5],
[92,22,13,6],
[92,22,13,11],
[92,22,13,12],
[92,22,14,0],
[92,22,14,1],
[92,22,14,2],
[92,22,14,3],
[92,22,14,4],
[92,22,14,5],
[92,22,14,8],
[92,22,14,9],
[92,22,14,11],
[92,22,14,12],
[92,22,15,0],
[92,22,15,1],
[92,22,15,2],
[92,22,15,3],
[92,22,15,4],
[92,22,15,6],
[92,22,15,8],
[92,22,15,11],
[92,22,15,12],
[92,22,15,13],
[92,22,15,14],
[92,22,16,0],
[92,22,16,1],
[92,22,16,2],
[92,22,16,5],
[92,22,16,6],
[92,22,16,8],
[92,22,16,9],
[92,22,16,11],
[92,22,16,12],
[92,22,16,13],
[92,22,16,14],
[92,22,16,15],
[92,22,17,0],
[92,22,17,1],
[92,22,17,2],
[92,22,17,5],
[92,22,17,6],
[92,22,17,8],
[92,22,17,9],
[92,22,17,11],
[92,22,17,12],
[92,22,17,13],
[92,22,17,14],
[92,22,17,15],
[92,22,17,16],
[92,22,18,3],
[92,22,18,4],
[92,22,18,5],
[92,22,18,6],
[92,22,18,8],
[92,22,18,11],
[92,22,18,12],
[92,22,18,13],
[92,22,18,14],
[92,22,18,16],
[92,22,18,17],
[92,22,19,0],
[92,22,19,1],
[92,22,19,2],
[92,22,19,3],
[92,22,19,4],
[92,22,19,5],
[92,22,19,6],
[92,22,19,8],
[92,22,19,9],
[92,22,19,11],
[92,22,19,12],
[92,22,19,15],
[92,22,19,16],
[92,22,19,17],
[92,22,19,18],
[92,22,21,0],
[92,22,21,1],
[92,22,21,2],
[92,22,21,4],
[92,22,21,9],
[92,22,21,11],
[92,22,21,12],
[92,22,21,13],
[92,22,21,14],
[92,22,21,15],
[92,22,21,16],
[92,22,21,17],
[92,23,3,0],
[92,23,3,1],
[92,23,3,2],
[92,23,4,0],
[92,23,4,1],
[92,23,4,2],
[92,23,4,3],
[92,23,5,0],
[92,23,5,1],
[92,23,5,2],
[92,23,5,4],
[92,23,6,0],
[92,23,6,1],
[92,23,6,2],
[92,23,6,4],
[92,23,8,0],
[92,23,8,1],
[92,23,8,2],
[92,23,8,4],
[92,23,9,0],
[92,23,9,1],
[92,23,9,2],
[92,23,9,3],
[92,23,9,4],
[92,23,9,5],
[92,23,9,6],
[92,23,9,8],
[92,23,10,0],
[92,23,10,1],
[92,23,10,2],
[92,23,10,3],
[92,23,10,4],
[92,23,10,5],
[92,23,10,6],
[92,23,11,0],
[92,23,11,1],
[92,23,11,2],
[92,23,11,3],
[92,23,11,4],
[92,23,11,5],
[92,23,11,6],
[92,23,11,10],
[92,23,12,0],
[92,23,12,1],
[92,23,12,2],
[92,23,12,3],
[92,23,12,4],
[92,23,12,5],
[92,23,12,8],
[92,23,12,9],
[92,23,12,10],
[92,23,13,0],
[92,23,13,1],
[92,23,13,2],
[92,23,13,3],
[92,23,13,4],
[92,23,13,5],
[92,23,13,8],
[92,23,13,9],
[92,23,13,10],
[92,23,13,11],
[92,23,13,12],
[92,23,14,0],
[92,23,14,1],
[92,23,14,2],
[92,23,14,3],
[92,23,14,4],
[92,23,14,6],
[92,23,14,8],
[92,23,14,9],
[92,23,14,10],
[92,23,14,11],
[92,23,14,12],
[92,23,15,0],
[92,23,15,1],
[92,23,15,2],
[92,23,15,5],
[92,23,15,6],
[92,23,15,8],
[92,23,15,10],
[92,23,15,11],
[92,23,15,12],
[92,23,15,13],
[92,23,15,14],
[92,23,16,3],
[92,23,16,4],
[92,23,16,5],
[92,23,16,6],
[92,23,16,8],
[92,23,16,9],
[92,23,16,10],
[92,23,16,11],
[92,23,16,12],
[92,23,16,13],
[92,23,16,14],
[92,23,16,15],
[92,23,17,3],
[92,23,17,4],
[92,23,17,5],
[92,23,17,6],
[92,23,17,8],
[92,23,17,9],
[92,23,17,10],
[92,23,17,11],
[92,23,17,12],
[92,23,17,13],
[92,23,17,14],
[92,23,17,15],
[92,23,17,16],
[92,23,18,0],
[92,23,18,1],
[92,23,18,2],
[92,23,18,3],
[92,23,18,4],
[92,23,18,5],
[92,23,18,6],
[92,23,18,8],
[92,23,18,10],
[92,23,18,11],
[92,23,18,12],
[92,23,18,13],
[92,23,18,14],
[92,23,18,16],
[92,23,18,17],
[92,23,19,0],
[92,23,19,1],
[92,23,19,2],
[92,23,19,3],
[92,23,19,4],
[92,23,19,5],
[92,23,19,6],
[92,23,19,8],
[92,23,19,9],
[92,23,19,10],
[92,23,19,11],
[92,23,19,12],
[92,23,19,15],
[92,23,19,16],
[92,23,19,17],
[92,23,19,18],
[92,23,20,0],
[92,23,20,1],
[92,23,20,2],
[92,23,20,3],
[92,23,20,4],
[92,23,20,5],
[92,23,20,6],
[92,23,20,8],
[92,23,20,9],
[92,23,20,11],
[92,23,20,12],
[92,23,20,13],
[92,23,20,14],
[92,23,20,15],
[92,23,20,16],
[92,23,20,17],
[92,23,21,0],
[92,23,21,1],
[92,23,21,2],
[92,23,21,4],
[92,23,21,9],
[92,23,21,10],
[92,23,21,11],
[92,23,21,12],
[92,23,21,13],
[92,23,21,14],
[92,23,21,15],
[92,23,22,0],
[92,23,22,1],
[92,23,22,2],
[92,23,22,3],
[92,23,22,4],
[92,23,22,5],
[92,23,22,6],
[92,23,22,8],
[92,23,22,9],
[92,23,22,11],
[92,23,22,12],
[92,23,22,13],
[92,23,22,14],
[92,24,3,0],
[92,24,3,1],
[92,24,3,2],
[92,24,4,0],
[92,24,4,1],
[92,24,4,2],
[92,24,4,3],
[92,24,5,0],
[92,24,5,1],
[92,24,5,2],
[92,24,5,4],
[92,24,6,0],
[92,24,6,1],
[92,24,6,2],
[92,24,6,4],
[92,24,8,0],
[92,24,8,1],
[92,24,8,2],
[92,24,8,4],
[92,24,9,0],
[92,24,9,1],
[92,24,9,2],
[92,24,9,3],
[92,24,9,4],
[92,24,9,5],
[92,24,9,6],
[92,24,9,8],
[92,24,10,0],
[92,24,10,1],
[92,24,10,2],
[92,24,10,3],
[92,24,10,4],
[92,24,10,5],
[92,24,10,6],
[92,24,11,0],
[92,24,11,1],
[92,24,11,2],
[92,24,11,3],
[92,24,11,4],
[92,24,11,5],
[92,24,11,6],
[92,24,11,10],
[92,24,12,0],
[92,24,12,1],
[92,24,12,2],
[92,24,12,3],
[92,24,12,4],
[92,24,12,5],
[92,24,12,8],
[92,24,12,9],
[92,24,12,10],
[92,24,13,0],
[92,24,13,1],
[92,24,13,2],
[92,24,13,3],
[92,24,13,4],
[92,24,13,5],
[92,24,13,8],
[92,24,13,9],
[92,24,13,10],
[92,24,13,11],
[92,24,13,12],
[92,24,14,0],
[92,24,14,1],
[92,24,14,2],
[92,24,14,3],
[92,24,14,4],
[92,24,14,6],
[92,24,14,8],
[92,24,14,9],
[92,24,14,10],
[92,24,14,11],
[92,24,14,12],
[92,24,15,0],
[92,24,15,1],
[92,24,15,2],
[92,24,15,5],
[92,24,15,6],
[92,24,15,8],
[92,24,15,10],
[92,24,15,11],
[92,24,15,12],
[92,24,15,13],
[92,24,15,14],
[92,24,17,3],
[92,24,17,4],
[92,24,17,5],
[92,24,17,6],
[92,24,17,8],
[92,24,17,9],
[92,24,17,10],
[92,24,17,11],
[92,24,17,12],
[92,24,17,13],
[92,24,17,14],
[92,24,17,15],
[92,24,18,0],
[92,24,18,1],
[92,24,18,2],
[92,24,18,3],
[92,24,18,4],
[92,24,18,5],
[92,24,18,6],
[92,24,18,8],
[92,24,18,10],
[92,24,18,11],
[92,24,18,12],
[92,24,18,13],
[92,24,18,14],
[92,24,18,17],
[92,24,19,0],
[92,24,19,1],
[92,24,19,2],
[92,24,19,3],
[92,24,19,4],
[92,24,19,5],
[92,24,19,6],
[92,24,19,8],
[92,24,19,9],
[92,24,19,10],
[92,24,19,11],
[92,24,19,12],
[92,24,19,15],
[92,24,19,17],
[92,24,19,18],
[92,24,20,0],
[92,24,20,1],
[92,24,20,2],
[92,24,20,3],
[92,24,20,4],
[92,24,20,5],
[92,24,20,6],
[92,24,20,8],
[92,24,20,9],
[92,24,20,11],
[92,24,20,12],
[92,24,20,13],
[92,24,20,14],
[92,24,20,15],
[92,24,20,17],
[92,24,21,0],
[92,24,21,1],
[92,24,21,2],
[92,24,21,4],
[92,24,21,9],
[92,24,21,10],
[92,24,21,11],
[92,24,21,12],
[92,24,21,13],
[92,24,21,14],
[92,24,21,15],
[92,24,22,0],
[92,24,22,1],
[92,24,22,2],
[92,24,22,3],
[92,24,22,4],
[92,24,22,5],
[92,24,22,6],
[92,24,22,8],
[92,24,22,9],
[92,24,22,11],
[92,24,22,12],
[92,24,22,13],
[92,24,22,14],
[92,24,23,0],
[92,24,23,1],
[92,24,23,2],
[92,24,23,3],
[92,24,23,4],
[92,24,23,5],
[92,24,23,6],
[92,24,23,8],
[92,24,23,9],
[92,24,23,10],
[92,24,23,11],
[92,24,23,12],
[92,24,23,13],
[92,26,3,0],
[92,26,3,1],
[92,26,3,2],
[92,26,4,0],
[92,26,4,1],
[92,26,4,2],
[92,26,4,3],
[92,26,5,0],
[92,26,5,1],
[92,26,5,2],
[92,26,5,4],
[92,26,6,0],
[92,26,6,1],
[92,26,6,2],
[92,26,6,4],
[92,26,8,0],
[92,26,8,1],
[92,26,8,2],
[92,26,8,4],
[92,26,10,0],
[92,26,10,1],
[92,26,10,2],
[92,26,10,5],
[92,26,10,6],
[92,26,10,8],
[92,26,11,0],
[92,26,11,1],
[92,26,11,2],
[92,26,11,5],
[92,26,11,6],
[92,26,11,8],
[92,26,11,10],
[92,26,12,3],
[92,26,12,4],
[92,26,12,5],
[92,26,12,6],
[92,26,12,8],
[92,26,12,10],
[92,26,13,3],
[92,26,13,4],
[92,26,13,5],
[92,26,13,6],
[92,26,13,8],
[92,26,13,10],
[92,26,13,11],
[92,26,13,12],
[92,26,14,0],
[92,26,14,1],
[92,26,14,2],
[92,26,14,3],
[92,26,14,4],
[92,26,14,5],
[92,26,14,6],
[92,26,14,8],
[92,26,14,10],
[92,26,14,11],
[92,26,14,12],
[92,26,16,0],
[92,26,16,1],
[92,26,16,2],
[92,26,16,3],
[92,26,16,4],
[92,26,16,5],
[92,26,16,6],
[92,26,16,8],
[92,26,16,10],
[92,26,16,11],
[92,26,16,12],
[92,26,16,13],
[92,26,16,14],
[92,26,17,0],
[92,26,17,1],
[92,26,17,2],
[92,26,17,3],
[92,26,17,4],
[92,26,17,5],
[92,26,17,6],
[92,26,17,8],
[92,26,17,10],
[92,26,17,11],
[92,26,17,12],
[92,26,17,13],
[92,26,17,14],
[92,26,17,16],
[92,26,19,0],
[92,26,19,1],
[92,26,19,2],
[92,26,19,3],
[92,26,19,4],
[92,26,19,5],
[92,26,19,6],
[92,26,19,8],
[92,26,19,10],
[92,26,19,11],
[92,26,19,12],
[92,26,20,0],
[92,26,20,1],
[92,26,20,2],
[92,26,20,3],
[92,26,20,4],
[92,26,20,5],
[92,26,20,6],
[92,26,20,8],
[92,26,20,11],
[92,26,20,12],
[92,26,20,13],
[92,26,21,0],
[92,26,21,1],
[92,26,21,2],
[92,26,21,4],
[92,26,21,10],
[92,26,21,11],
[92,26,22,0],
[92,26,22,1],
[92,26,22,2],
[92,26,22,3],
[92,26,22,4],
[92,26,22,5],
[92,26,22,6],
[92,26,22,8],
[92,26,23,0],
[92,26,23,1],
[92,26,23,2],
[92,26,23,3],
[92,26,23,4],
[92,26,23,5],
[92,26,23,6],
[92,26,24,0],
[92,26,24,1],
[92,26,24,2],
[92,26,24,3],
[92,26,24,4],
[92,26,24,5],
[92,26,24,6],
[92,27,3,0],
[92,27,3,1],
[92,27,3,2],
[92,27,4,0],
[92,27,4,1],
[92,27,4,2],
[92,27,4,3],
[92,27,5,0],
[92,27,5,1],
[92,27,5,2],
[92,27,5,4],
[92,27,6,0],
[92,27,6,1],
[92,27,6,2],
[92,27,6,4],
[92,27,8,0],
[92,27,8,1],
[92,27,8,2],
[92,27,9,0],
[92,27,9,1],
[92,27,9,2],
[92,27,9,5],
[92,27,9,6],
[92,27,9,8],
[92,27,11,3],
[92,27,11,4],
[92,27,11,5],
[92,27,11,6],
[92,27,11,8],
[92,27,11,9],
[92,27,12,0],
[92,27,12,1],
[92,27,12,2],
[92,27,12,3],
[92,27,12,4],
[92,27,12,5],
[92,27,12,6],
[92,27,12,8],
[92,27,12,9],
[92,27,13,0],
[92,27,13,1],
[92,27,13,2],
[92,27,13,3],
[92,27,13,4],
[92,27,13,5],
[92,27,13,6],
[92,27,13,8],
[92,27,13,9],
[92,27,13,11],
[92,27,13,12],
[92,27,14,0],
[92,27,14,1],
[92,27,14,2],
[92,27,14,3],
[92,27,14,4],
[92,27,14,5],
[92,27,14,6],
[92,27,14,8],
[92,27,14,9],
[92,27,14,11],
[92,27,14,12],
[92,27,15,0],
[92,27,15,1],
[92,27,15,2],
[92,27,15,3],
[92,27,15,4],
[92,27,15,5],
[92,27,15,6],
[92,27,15,8],
[92,27,15,11],
[92,27,15,12],
[92,27,15,13],
[92,27,15,14],
[92,27,16,0],
[92,27,16,1],
[92,27,16,2],
[92,27,16,3],
[92,27,16,4],
[92,27,16,5],
[92,27,16,6],
[92,27,16,8],
[92,27,16,9],
[92,27,16,11],
[92,27,16,12],
[92,27,16,13],
[92,27,16,14],
[92,27,16,15],
[92,27,17,0],
[92,27,17,1],
[92,27,17,2],
[92,27,17,3],
[92,27,17,4],
[92,27,17,5],
[92,27,17,6],
[92,27,17,8],
[92,27,17,9],
[92,27,17,11],
[92,27,17,12],
[92,27,17,13],
[92,27,17,14],
[92,27,17,15],
[92,27,18,0],
[92,27,18,1],
[92,27,18,2],
[92,27,18,3],
[92,27,18,4],
[92,27,18,5],
[92,27,18,6],
[92,27,18,8],
[92,27,18,11],
[92,27,18,12],
[92,27,18,13],
[92,27,18,14],
[92,27,19,0],
[92,27,19,1],
[92,27,19,2],
[92,27,19,3],
[92,27,19,4],
[92,27,19,5],
[92,27,19,6],
[92,27,19,8],
[92,27,19,9],
[92,27,19,11],
[92,27,19,12],
[92,27,21,0],
[92,27,21,1],
[92,27,21,2],
[92,27,21,4],
[92,27,21,9],
[92,27,23,0],
[92,27,23,1],
[92,27,23,2],
[92,27,23,3],
[92,27,23,4],
[92,27,23,5],
[92,27,24,0],
[92,27,24,1],
[92,27,24,2],
[92,27,24,3],
[92,27,24,4],
[92,27,24,5],
[92,29,3,0],
[92,29,3,1],
[92,29,3,2],
[92,29,4,0],
[92,29,4,1],
[92,29,4,2],
[92,29,4,3],
[92,29,5,0],
[92,29,5,1],
[92,29,5,2],
[92,29,6,4],
[92,29,8,0],
[92,29,8,1],
[92,29,8,2],
[92,29,8,4],
[92,29,9,0],
[92,29,9,1],
[92,29,9,2],
[92,29,9,3],
[92,29,9,4],
[92,29,9,5],
[92,29,9,6],
[92,29,9,8],
[92,29,10,0],
[92,29,10,1],
[92,29,10,2],
[92,29,10,3],
[92,29,10,4],
[92,29,10,5],
[92,29,10,6],
[92,29,10,8],
[92,29,10,9],
[92,29,13,0],
[92,29,13,1],
[92,29,13,2],
[92,29,13,3],
[92,29,13,4],
[92,29,13,5],
[92,29,13,6],
[92,29,13,8],
[92,29,13,9],
[92,29,13,10],
[92,29,14,0],
[92,29,14,1],
[92,29,14,2],
[92,29,14,3],
[92,29,14,4],
[92,29,14,5],
[92,29,14,6],
[92,29,14,8],
[92,29,14,9],
[92,29,14,10],
[92,29,15,0],
[92,29,15,1],
[92,29,15,2],
[92,29,15,3],
[92,29,15,4],
[92,29,15,5],
[92,29,15,6],
[92,29,15,8],
[92,29,15,10],
[92,29,15,13],
[92,29,15,14],
[92,29,16,0],
[92,29,16,1],
[92,29,16,2],
[92,29,16,3],
[92,29,16,4],
[92,29,16,5],
[92,29,16,6],
[92,29,16,8],
[92,29,16,9],
[92,29,16,10],
[92,29,16,13],
[92,29,17,0],
[92,29,17,1],
[92,29,17,2],
[92,29,17,3],
[92,29,17,4],
[92,29,17,5],
[92,29,17,6],
[92,29,17,8],
[92,29,17,9],
[92,29,17,10],
[92,29,17,13],
[92,29,18,0],
[92,29,18,1],
[92,29,18,2],
[92,29,18,3],
[92,29,18,4],
[92,29,18,5],
[92,29,18,6],
[92,29,18,8],
[92,29,18,10],
[92,29,19,0],
[92,29,19,1],
[92,29,19,2],
[92,29,19,3],
[92,29,19,4],
[92,29,19,5],
[92,29,19,6],
[92,29,19,8],
[92,29,19,9],
[92,29,20,0],
[92,29,20,1],
[92,29,20,2],
[92,29,20,3],
[92,29,20,4],
[92,29,20,5],
[92,29,20,6],
[92,29,21,0],
[92,29,21,1],
[92,29,21,2],
[92,29,21,4],
[92,29,22,0],
[92,29,22,1],
[92,29,22,2],
[92,29,22,3],
[92,29,22,4],
[92,29,23,0],
[92,29,23,1],
[92,29,23,2],
[92,29,24,0],
[92,29,24,1],
[92,29,24,2],
[92,30,3,0],
[92,30,3,1],
[92,30,3,2],
[92,30,4,0],
[92,30,4,1],
[92,30,4,2],
[92,30,5,4],
[92,30,6,0],
[92,30,6,1],
[92,30,6,2],
[92,30,6,4],
[92,30,8,0],
[92,30,8,1],
[92,30,8,2],
[92,30,8,4],
[92,30,9,0],
[92,30,9,1],
[92,30,9,2],
[92,30,9,3],
[92,30,9,4],
[92,30,9,5],
[92,30,9,6],
[92,30,9,8],
[92,30,10,0],
[92,30,10,1],
[92,30,10,2],
[92,30,10,3],
[92,30,10,4],
[92,30,10,5],
[92,30,10,6],
[92,30,10,8],
[92,30,10,9],
[92,30,11,0],
[92,30,11,1],
[92,30,11,2],
[92,30,11,3],
[92,30,11,4],
[92,30,11,5],
[92,30,11,6],
[92,30,11,8],
[92,30,11,9],
[92,30,11,10],
[92,30,12,0],
[92,30,12,1],
[92,30,12,2],
[92,30,12,3],
[92,30,12,4],
[92,30,12,5],
[92,30,12,6],
[92,30,12,8],
[92,30,12,9],
[92,30,12,10],
[92,30,13,0],
[92,30,13,1],
[92,30,13,2],
[92,30,13,3],
[92,30,13,4],
[92,30,13,5],
[92,30,13,6],
[92,30,13,8],
[92,30,13,9],
[92,30,13,10],
[92,30,13,11],
[92,30,13,12],
[92,30,14,0],
[92,30,14,1],
[92,30,14,2],
[92,30,14,3],
[92,30,14,4],
[92,30,14,5],
[92,30,14,6],
[92,30,14,8],
[92,30,14,9],
[92,30,14,10],
[92,30,14,11],
[92,30,14,12],
[92,30,15,0],
[92,30,15,1],
[92,30,15,2],
[92,30,15,3],
[92,30,15,4],
[92,30,15,5],
[92,30,15,6],
[92,30,15,8],
[92,30,15,10],
[92,30,15,11],
[92,30,15,12],
[92,30,15,13],
[92,30,16,0],
[92,30,16,1],
[92,30,16,2],
[92,30,16,3],
[92,30,16,4],
[92,30,16,5],
[92,30,16,6],
[92,30,16,8],
[92,30,16,9],
[92,30,16,10],
[92,30,16,11],
[92,30,17,0],
[92,30,17,1],
[92,30,17,2],
[92,30,17,3],
[92,30,17,4],
[92,30,17,5],
[92,30,17,6],
[92,30,17,8],
[92,30,17,9],
[92,30,17,10],
[92,30,17,11],
[92,30,18,0],
[92,30,18,1],
[92,30,18,2],
[92,30,18,3],
[92,30,18,4],
[92,30,18,5],
[92,30,18,6],
[92,30,18,8],
[92,30,19,0],
[92,30,19,1],
[92,30,19,2],
[92,30,19,3],
[92,30,19,4],
[92,30,19,5],
[92,30,19,6],
[92,30,20,0],
[92,30,20,1],
[92,30,20,2],
[92,30,20,3],
[92,30,20,4],
[92,30,20,5],
[92,30,21,0],
[92,30,21,1],
[92,30,21,2],
[92,30,21,4],
[92,30,22,0],
[92,30,22,1],
[92,30,22,2],
[92,31,4,3],
[92,31,5,0],
[92,31,5,1],
[92,31,5,2],
[92,31,5,4],
[92,31,6,0],
[92,31,6,1],
[92,31,6,2],
[92,31,6,4],
[92,31,8,0],
[92,31,8,1],
[92,31,8,2],
[92,31,8,4],
[92,31,9,0],
[92,31,9,1],
[92,31,9,2],
[92,31,9,3],
[92,31,9,4],
[92,31,9,5],
[92,31,9,6],
[92,31,9,8],
[92,31,10,0],
[92,31,10,1],
[92,31,10,2],
[92,31,10,3],
[92,31,10,4],
[92,31,10,5],
[92,31,10,6],
[92,31,10,8],
[92,31,10,9],
[92,31,11,0],
[92,31,11,1],
[92,31,11,2],
[92,31,11,3],
[92,31,11,4],
[92,31,11,5],
[92,31,11,6],
[92,31,11,8],
[92,31,11,9],
[92,31,11,10],
[92,31,12,0],
[92,31,12,1],
[92,31,12,2],
[92,31,12,3],
[92,31,12,4],
[92,31,12,5],
[92,31,12,6],
[92,31,12,8],
[92,31,12,9],
[92,31,12,10],
[92,31,13,0],
[92,31,13,1],
[92,31,13,2],
[92,31,13,3],
[92,31,13,4],
[92,31,13,5],
[92,31,13,6],
[92,31,13,8],
[92,31,13,9],
[92,31,13,10],
[92,31,13,11],
[92,31,13,12],
[92,31,14,0],
[92,31,14,1],
[92,31,14,2],
[92,31,14,3],
[92,31,14,4],
[92,31,14,5],
[92,31,14,6],
[92,31,14,8],
[92,31,14,9],
[92,31,14,10],
[92,31,14,11],
[92,31,14,12],
[92,31,15,0],
[92,31,15,1],
[92,31,15,2],
[92,31,15,3],
[92,31,15,4],
[92,31,15,5],
[92,31,15,6],
[92,31,15,8],
[92,31,15,10],
[92,31,15,11],
[92,31,16,0],
[92,31,16,1],
[92,31,16,2],
[92,31,16,3],
[92,31,16,4],
[92,31,16,5],
[92,31,16,6],
[92,31,16,8],
[92,31,16,9],
[92,31,17,0],
[92,31,17,1],
[92,31,17,2],
[92,31,17,3],
[92,31,17,4],
[92,31,17,5],
[92,31,17,6],
[92,31,17,8],
[92,31,17,9],
[92,31,18,0],
[92,31,18,1],
[92,31,18,2],
[92,31,18,3],
[92,31,18,4],
[92,31,18,5],
[92,31,18,6],
[92,31,19,0],
[92,31,19,1],
[92,31,19,2],
[92,31,19,3],
[92,31,19,4],
[92,31,19,5],
[92,31,20,0],
[92,31,20,1],
[92,31,20,2],
[92,31,20,3],
[92,31,20,4],
[92,31,21,0],
[92,31,21,1],
[92,31,21,2],
[92,32,3,0],
[92,32,3,1],
[92,32,3,2],
[92,32,4,0],
[92,32,4,1],
[92,32,4,2],
[92,32,4,3],
[92,32,5,0],
[92,32,5,1],
[92,32,5,2],
[92,32,5,4],
[92,32,6,0],
[92,32,6,1],
[92,32,6,2],
[92,32,6,4],
[92,32,8,0],
[92,32,8,1],
[92,32,8,2],
[92,32,8,4],
[92,32,9,0],
[92,32,9,1],
[92,32,9,2],
[92,32,9,3],
[92,32,9,4],
[92,32,9,5],
[92,32,9,6],
[92,32,9,8],
[92,32,10,0],
[92,32,10,1],
[92,32,10,2],
[92,32,10,3],
[92,32,10,4],
[92,32,10,5],
[92,32,10,6],
[92,32,10,8],
[92,32,10,9],
[92,32,13,0],
[92,32,13,1],
[92,32,13,2],
[92,32,13,3],
[92,32,13,4],
[92,32,13,5],
[92,32,13,6],
[92,32,13,8],
[92,32,13,9],
[92,32,13,10],
[92,32,14,0],
[92,32,14,1],
[92,32,14,2],
[92,32,14,3],
[92,32,14,4],
[92,32,14,5],
[92,32,14,6],
[92,32,14,8],
[92,32,14,9],
[92,32,14,10],
[92,32,15,0],
[92,32,15,1],
[92,32,15,2],
[92,32,15,3],
[92,32,15,4],
[92,32,15,5],
[92,32,15,6],
[92,32,15,8],
[92,32,16,0],
[92,32,16,1],
[92,32,16,2],
[92,32,16,3],
[92,32,16,4],
[92,32,16,5],
[92,32,16,6],
[92,32,17,0],
[92,32,17,1],
[92,32,17,2],
[92,32,17,3],
[92,32,17,4],
[92,32,17,5],
[92,32,17,6],
[92,32,18,0],
[92,32,18,1],
[92,32,18,2],
[92,32,18,3],
[92,32,18,4],
[92,32,18,5],
[92,32,19,0],
[92,32,19,1],
[92,32,19,2],
[92,32,19,3],
[92,32,19,4],
[92,32,20,0],
[92,32,20,1],
[92,32,20,2],
[92,33,3,0],
[92,33,3,1],
[92,33,3,2],
[92,33,4,0],
[92,33,4,1],
[92,33,4,2],
[92,33,4,3],
[92,33,5,0],
[92,33,5,1],
[92,33,5,2],
[92,33,5,4],
[92,33,6,0],
[92,33,6,1],
[92,33,6,2],
[92,33,6,4],
[92,33,8,0],
[92,33,8,1],
[92,33,8,2],
[92,33,8,4],
[92,33,9,0],
[92,33,9,1],
[92,33,9,2],
[92,33,9,3],
[92,33,9,4],
[92,33,9,5],
[92,33,9,6],
[92,33,9,8],
[92,33,10,0],
[92,33,10,1],
[92,33,10,2],
[92,33,10,3],
[92,33,10,4],
[92,33,10,5],
[92,33,10,6],
[92,33,10,8],
[92,33,10,9],
[92,33,13,0],
[92,33,13,1],
[92,33,13,2],
[92,33,13,3],
[92,33,13,4],
[92,33,13,5],
[92,33,13,6],
[92,33,13,8],
[92,33,13,9],
[92,33,13,10],
[92,33,14,0],
[92,33,14,1],
[92,33,14,2],
[92,33,14,3],
[92,33,14,4],
[92,33,14,5],
[92,33,14,6],
[92,33,14,8],
[92,33,14,9],
[92,33,15,0],
[92,33,15,1],
[92,33,15,2],
[92,33,15,3],
[92,33,15,4],
[92,33,15,5],
[92,33,15,6],
[92,33,16,0],
[92,33,16,1],
[92,33,16,2],
[92,33,16,3],
[92,33,16,4],
[92,33,16,5],
[92,33,17,0],
[92,33,17,1],
[92,33,17,2],
[92,33,17,3],
[92,33,17,4],
[92,33,17,5],
[92,33,18,0],
[92,33,18,1],
[92,33,18,2],
[92,33,18,3],
[92,33,18,4],
[92,33,19,0],
[92,33,19,1],
[92,33,19,2],
[92,35,3,0],
[92,35,3,1],
[92,35,3,2],
[92,35,4,0],
[92,35,4,1],
[92,35,4,2],
[92,35,4,3],
[92,35,5,0],
[92,35,5,1],
[92,35,5,2],
[92,35,5,4],
[92,35,6,0],
[92,35,6,1],
[92,35,6,2],
[92,35,6,4],
[92,35,8,0],
[92,35,8,1],
[92,35,8,2],
[92,35,8,4],
[92,35,10,0],
[92,35,10,1],
[92,35,10,2],
[92,35,10,3],
[92,35,10,4],
[92,35,10,5],
[92,35,10,6],
[92,35,10,8],
[92,35,11,0],
[92,35,11,1],
[92,35,11,2],
[92,35,11,3],
[92,35,11,4],
[92,35,11,5],
[92,35,11,6],
[92,35,11,8],
[92,35,12,0],
[92,35,12,1],
[92,35,12,2],
[92,35,12,3],
[92,35,12,4],
[92,35,12,5],
[92,35,12,6],
[92,35,13,0],
[92,35,13,1],
[92,35,13,2],
[92,35,13,3],
[92,35,13,4],
[92,35,13,5],
[92,35,13,6],
[92,35,14,0],
[92,35,14,1],
[92,35,14,2],
[92,35,14,3],
[92,35,14,4],
[92,35,14,5],
[92,35,16,0],
[92,35,16,1],
[92,35,16,2],
[92,35,17,0],
[92,35,17,1],
[92,35,17,2],
[92,36,3,0],
[92,36,3,1],
[92,36,3,2],
[92,36,4,0],
[92,36,4,1],
[92,36,4,2],
[92,36,4,3],
[92,36,5,0],
[92,36,5,1],
[92,36,5,2],
[92,36,5,4],
[92,36,6,0],
[92,36,6,1],
[92,36,6,2],
[92,36,6,4],
[92,36,8,0],
[92,36,8,1],
[92,36,8,2],
[92,36,8,4],
[92,36,9,0],
[92,36,9,1],
[92,36,9,2],
[92,36,9,3],
[92,36,9,4],
[92,36,9,5],
[92,36,9,6],
[92,36,9,8],
[92,36,10,0],
[92,36,10,1],
[92,36,10,2],
[92,36,10,3],
[92,36,10,4],
[92,36,10,5],
[92,36,10,6],
[92,36,11,0],
[92,36,11,1],
[92,36,11,2],
[92,36,11,3],
[92,36,11,4],
[92,36,11,5],
[92,36,11,6],
[92,36,12,0],
[92,36,12,1],
[92,36,12,2],
[92,36,12,3],
[92,36,12,4],
[92,36,12,5],
[92,36,15,0],
[92,36,15,1],
[92,36,15,2],
[92,37,3,0],
[92,37,3,1],
[92,37,3,2],
[92,37,5,0],
[92,37,5,1],
[92,37,5,2],
[92,37,6,0],
[92,37,6,1],
[92,37,6,2],
[92,37,8,0],
[92,37,8,1],
[92,37,8,2],
[92,37,9,0],
[92,37,9,1],
[92,37,9,2],
[92,37,9,3],
[92,37,9,5],
[92,37,9,6],
[92,37,10,0],
[92,37,10,1],
[92,37,10,2],
[92,37,10,3],
[92,37,10,5],
[92,37,11,0],
[92,37,11,1],
[92,37,11,2],
[92,37,11,3],
[92,37,11,5],
[92,37,12,0],
[92,37,12,1],
[92,37,12,2],
[92,37,12,3],
[92,37,13,0],
[92,37,13,1],
[92,37,13,2],
[92,37,13,3],
[92,37,14,0],
[92,37,14,1],
[92,37,14,2],
[92,39,4,0],
[92,39,4,1],
[92,39,4,2],
[92,39,9,0],
[92,39,9,1],
[92,39,9,2],
[92,39,9,4],
[92,39,10,0],
[92,39,10,1],
[92,39,10,2],
[92,39,11,0],
[92,39,11,1],
[92,39,11,2],
[92,40,3,0],
[92,40,3,1],
[92,40,3,2],
[92,40,4,0],
[92,40,4,1],
[92,40,4,2],
[92,40,4,3],
[92,40,5,0],
[92,40,5,1],
[92,40,5,2],
[92,40,5,4],
[92,40,6,0],
[92,40,6,1],
[92,40,6,2],
[92,40,6,4],
[92,40,8,0],
[92,40,8,1],
[92,40,8,2],
[92,41,3,0],
[92,41,3,1],
[92,41,3,2],
[92,41,4,0],
[92,41,4,1],
[92,41,4,2],
[92,41,4,3],
[92,41,5,0],
[92,41,5,1],
[92,41,5,2],
[92,41,5,4],
[92,41,6,0],
[92,41,6,1],
[92,41,6,2],
[92,41,6,4],
[92,41,8,0],
[92,41,8,1],
[92,41,8,2],
[92,42,3,0],
[92,42,3,1],
[92,42,3,2],
[92,42,4,0],
[92,42,4,1],
[92,42,4,2],
[92,42,4,3],
[92,42,5,0],
[92,42,5,1],
[92,42,5,2],
[92,42,5,4],
[92,42,6,0],
[92,42,6,1],
[92,42,6,2],
[92,44,3,0],
[92,44,3,1],
[92,44,3,2],
[92,44,4,0],
[92,44,4,1],
[92,44,4,2],
[93,4,3,0],
[93,4,3,1],
[93,4,3,2],
[93,5,4,0],
[93,5,4,1],
[93,5,4,2],
[93,6,4,0],
[93,6,4,1],
[93,6,4,2],
[93,7,3,0],
[93,7,3,1],
[93,7,3,2],
[93,7,4,0],
[93,7,4,1],
[93,7,4,2],
[93,7,4,3],
[93,7,5,0],
[93,7,5,1],
[93,7,5,2],
[93,7,5,4],
[93,7,6,0],
[93,7,6,1],
[93,7,6,2],
[93,7,6,4],
[93,8,4,0],
[93,8,4,1],
[93,8,4,2],
[93,8,7,0],
[93,8,7,1],
[93,8,7,2],
[93,8,7,4],
[93,9,3,0],
[93,9,3,1],
[93,9,3,2],
[93,9,4,0],
[93,9,4,1],
[93,9,4,2],
[93,9,4,3],
[93,9,5,0],
[93,9,5,1],
[93,9,5,2],
[93,9,5,4],
[93,9,6,0],
[93,9,6,1],
[93,9,6,2],
[93,9,6,4],
[93,9,7,0],
[93,9,7,1],
[93,9,7,2],
[93,9,7,3],
[93,9,7,4],
[93,9,7,5],
[93,9,7,6],
[93,9,8,0],
[93,9,8,1],
[93,9,8,2],
[93,9,8,4],
[93,9,8,7],
[93,10,3,0],
[93,10,3,1],
[93,10,3,2],
[93,10,4,0],
[93,10,4,1],
[93,10,4,2],
[93,10,4,3],
[93,10,5,0],
[93,10,5,1],
[93,10,5,2],
[93,10,5,4],
[93,10,6,0],
[93,10,6,1],
[93,10,6,2],
[93,10,6,4],
[93,10,7,0],
[93,10,7,1],
[93,10,7,2],
[93,10,7,3],
[93,10,7,4],
[93,10,7,5],
[93,10,7,6],
[93,10,8,0],
[93,10,8,1],
[93,10,8,2],
[93,10,8,4],
[93,10,8,7],
[93,10,9,0],
[93,10,9,1],
[93,10,9,2],
[93,10,9,3],
[93,10,9,4],
[93,10,9,5],
[93,10,9,6],
[93,10,9,7],
[93,10,9,8],
[93,13,3,0],
[93,13,3,1],
[93,13,3,2],
[93,13,4,0],
[93,13,4,1],
[93,13,4,2],
[93,13,4,3],
[93,13,5,0],
[93,13,5,1],
[93,13,5,2],
[93,13,5,4],
[93,13,6,0],
[93,13,6,1],
[93,13,6,2],
[93,13,6,4],
[93,13,7,0],
[93,13,7,1],
[93,13,7,2],
[93,13,7,3],
[93,13,7,4],
[93,13,7,5],
[93,13,7,6],
[93,13,8,0],
[93,13,8,1],
[93,13,8,2],
[93,13,8,4],
[93,13,8,7],
[93,13,9,0],
[93,13,9,1],
[93,13,9,2],
[93,13,9,3],
[93,13,9,4],
[93,13,9,5],
[93,13,9,6],
[93,13,9,7],
[93,13,9,8],
[93,13,10,0],
[93,13,10,1],
[93,13,10,2],
[93,13,10,3],
[93,13,10,4],
[93,13,10,5],
[93,13,10,6],
[93,13,10,7],
[93,13,10,8],
[93,13,10,9],
[93,14,3,0],
[93,14,3,1],
[93,14,3,2],
[93,14,4,0],
[93,14,4,1],
[93,14,4,2],
[93,14,4,3],
[93,14,5,0],
[93,14,5,1],
[93,14,5,2],
[93,14,5,4],
[93,14,6,0],
[93,14,6,1],
[93,14,6,2],
[93,14,6,4],
[93,14,7,0],
[93,14,7,1],
[93,14,7,2],
[93,14,7,3],
[93,14,7,4],
[93,14,7,5],
[93,14,7,6],
[93,14,8,0],
[93,14,8,1],
[93,14,8,2],
[93,14,8,4],
[93,14,8,7],
[93,14,9,0],
[93,14,9,1],
[93,14,9,2],
[93,14,9,3],
[93,14,9,4],
[93,14,9,5],
[93,14,9,6],
[93,14,9,7],
[93,14,9,8],
[93,14,10,0],
[93,14,10,1],
[93,14,10,2],
[93,14,10,3],
[93,14,10,4],
[93,14,10,5],
[93,14,10,6],
[93,14,10,7],
[93,14,10,8],
[93,14,10,9],
[93,15,3,0],
[93,15,3,1],
[93,15,3,2],
[93,15,4,0],
[93,15,4,1],
[93,15,4,2],
[93,15,4,3],
[93,15,5,0],
[93,15,5,1],
[93,15,5,2],
[93,15,5,4],
[93,15,6,0],
[93,15,6,1],
[93,15,6,2],
[93,15,6,4],
[93,15,7,0],
[93,15,7,1],
[93,15,7,2],
[93,15,7,3],
[93,15,7,4],
[93,15,7,5],
[93,15,7,6],
[93,15,8,0],
[93,15,8,1],
[93,15,8,2],
[93,15,8,4],
[93,15,8,7],
[93,15,10,0],
[93,15,10,1],
[93,15,10,2],
[93,15,10,3],
[93,15,10,4],
[93,15,10,5],
[93,15,10,6],
[93,15,10,7],
[93,15,10,8],
[93,15,13,0],
[93,15,13,1],
[93,15,13,2],
[93,15,13,3],
[93,15,13,4],
[93,15,13,5],
[93,15,13,6],
[93,15,13,7],
[93,15,13,8],
[93,15,13,10],
[93,15,14,0],
[93,15,14,1],
[93,15,14,2],
[93,15,14,3],
[93,15,14,4],
[93,15,14,5],
[93,15,14,6],
[93,15,14,7],
[93,15,14,8],
[93,15,14,10],
[93,16,3,0],
[93,16,3,1],
[93,16,3,2],
[93,16,4,0],
[93,16,4,1],
[93,16,4,2],
[93,16,4,3],
[93,16,5,0],
[93,16,5,1],
[93,16,5,2],
[93,16,5,4],
[93,16,6,0],
[93,16,6,1],
[93,16,6,2],
[93,16,6,4],
[93,16,7,0],
[93,16,7,1],
[93,16,7,2],
[93,16,7,3],
[93,16,7,4],
[93,16,7,5],
[93,16,7,6],
[93,16,8,0],
[93,16,8,1],
[93,16,8,2],
[93,16,8,4],
[93,16,8,7],
[93,16,9,0],
[93,16,9,1],
[93,16,9,2],
[93,16,9,3],
[93,16,9,4],
[93,16,9,5],
[93,16,9,6],
[93,16,9,7],
[93,16,9,8],
[93,16,10,0],
[93,16,10,1],
[93,16,10,2],
[93,16,10,3],
[93,16,10,4],
[93,16,10,5],
[93,16,10,6],
[93,16,10,7],
[93,16,10,8],
[93,16,10,9],
[93,16,13,0],
[93,16,13,1],
[93,16,13,2],
[93,16,13,3],
[93,16,13,4],
[93,16,13,5],
[93,16,13,6],
[93,16,13,7],
[93,16,13,8],
[93,16,13,9],
[93,16,13,10],
[93,16,14,0],
[93,16,14,1],
[93,16,14,2],
[93,16,14,3],
[93,16,14,4],
[93,16,14,5],
[93,16,14,6],
[93,16,14,7],
[93,16,14,8],
[93,16,14,9],
[93,16,14,10],
[93,16,15,0],
[93,16,15,1],
[93,16,15,2],
[93,16,15,3],
[93,16,15,4],
[93,16,15,5],
[93,16,15,6],
[93,16,15,7],
[93,16,15,8],
[93,16,15,10],
[93,16,15,14],
[93,17,3,0],
[93,17,3,1],
[93,17,3,2],
[93,17,4,0],
[93,17,4,1],
[93,17,4,2],
[93,17,4,3],
[93,17,5,0],
[93,17,5,1],
[93,17,5,2],
[93,17,5,4],
[93,17,6,0],
[93,17,6,1],
[93,17,6,2],
[93,17,6,4],
[93,17,7,0],
[93,17,7,1],
[93,17,7,2],
[93,17,7,3],
[93,17,7,4],
[93,17,7,5],
[93,17,7,6],
[93,17,8,0],
[93,17,8,1],
[93,17,8,2],
[93,17,8,4],
[93,17,8,7],
[93,17,9,0],
[93,17,9,1],
[93,17,9,2],
[93,17,9,3],
[93,17,9,4],
[93,17,9,5],
[93,17,9,6],
[93,17,9,7],
[93,17,9,8],
[93,17,10,0],
[93,17,10,1],
[93,17,10,2],
[93,17,10,3],
[93,17,10,4],
[93,17,10,5],
[93,17,10,6],
[93,17,10,7],
[93,17,10,8],
[93,17,10,9],
[93,17,13,0],
[93,17,13,1],
[93,17,13,2],
[93,17,13,3],
[93,17,13,4],
[93,17,13,5],
[93,17,13,6],
[93,17,13,7],
[93,17,13,8],
[93,17,13,9],
[93,17,13,10],
[93,17,14,0],
[93,17,14,1],
[93,17,14,2],
[93,17,14,3],
[93,17,14,4],
[93,17,14,5],
[93,17,14,6],
[93,17,14,7],
[93,17,14,8],
[93,17,14,9],
[93,17,14,10],
[93,17,15,0],
[93,17,15,1],
[93,17,15,2],
[93,17,15,3],
[93,17,15,4],
[93,17,15,5],
[93,17,15,6],
[93,17,15,7],
[93,17,15,8],
[93,17,15,10],
[93,17,15,14],
[93,17,16,0],
[93,17,16,1],
[93,17,16,2],
[93,17,16,3],
[93,17,16,4],
[93,17,16,5],
[93,17,16,6],
[93,17,16,7],
[93,17,16,8],
[93,17,16,9],
[93,17,16,13],
[93,17,16,14],
[93,17,16,15],
[93,18,3,0],
[93,18,3,1],
[93,18,3,2],
[93,18,4,0],
[93,18,4,1],
[93,18,4,2],
[93,18,4,3],
[93,18,5,0],
[93,18,5,1],
[93,18,5,2],
[93,18,5,4],
[93,18,6,0],
[93,18,6,1],
[93,18,6,2],
[93,18,6,4],
[93,18,7,0],
[93,18,7,1],
[93,18,7,2],
[93,18,7,3],
[93,18,7,4],
[93,18,7,5],
[93,18,7,6],
[93,18,8,0],
[93,18,8,1],
[93,18,8,2],
[93,18,8,4],
[93,18,8,7],
[93,18,10,0],
[93,18,10,1],
[93,18,10,2],
[93,18,10,3],
[93,18,10,4],
[93,18,10,5],
[93,18,10,6],
[93,18,10,7],
[93,18,10,8],
[93,18,13,0],
[93,18,13,1],
[93,18,13,2],
[93,18,13,3],
[93,18,13,4],
[93,18,13,5],
[93,18,13,6],
[93,18,13,7],
[93,18,13,8],
[93,18,13,10],
[93,18,14,0],
[93,18,14,1],
[93,18,14,2],
[93,18,14,3],
[93,18,14,4],
[93,18,14,5],
[93,18,14,6],
[93,18,14,7],
[93,18,14,8],
[93,18,14,10],
[93,18,16,0],
[93,18,16,1],
[93,18,16,2],
[93,18,16,3],
[93,18,16,4],
[93,18,16,5],
[93,18,16,6],
[93,18,16,7],
[93,18,16,10],
[93,18,16,13],
[93,18,16,14],
[93,18,17,0],
[93,18,17,1],
[93,18,17,2],
[93,18,17,3],
[93,18,17,4],
[93,18,17,5],
[93,18,17,6],
[93,18,17,7],
[93,18,17,10],
[93,18,17,13],
[93,18,17,14],
[93,18,17,16],
[93,19,3,0],
[93,19,3,1],
[93,19,3,2],
[93,19,4,0],
[93,19,4,1],
[93,19,4,2],
[93,19,4,3],
[93,19,5,0],
[93,19,5,1],
[93,19,5,2],
[93,19,5,4],
[93,19,6,0],
[93,19,6,1],
[93,19,6,2],
[93,19,6,4],
[93,19,7,0],
[93,19,7,1],
[93,19,7,2],
[93,19,7,3],
[93,19,7,4],
[93,19,7,5],
[93,19,7,6],
[93,19,8,0],
[93,19,8,1],
[93,19,8,2],
[93,19,8,4],
[93,19,8,7],
[93,19,9,0],
[93,19,9,1],
[93,19,9,2],
[93,19,9,3],
[93,19,9,4],
[93,19,9,5],
[93,19,9,6],
[93,19,9,7],
[93,19,9,8],
[93,19,10,0],
[93,19,10,1],
[93,19,10,2],
[93,19,10,3],
[93,19,10,4],
[93,19,10,5],
[93,19,10,6],
[93,19,10,7],
[93,19,10,8],
[93,19,10,9],
[93,19,15,0],
[93,19,15,1],
[93,19,15,2],
[93,19,15,3],
[93,19,15,4],
[93,19,15,5],
[93,19,15,6],
[93,19,15,7],
[93,19,15,10],
[93,19,16,0],
[93,19,16,1],
[93,19,16,2],
[93,19,16,3],
[93,19,16,4],
[93,19,16,5],
[93,19,16,8],
[93,19,16,9],
[93,19,16,10],
[93,19,16,15],
[93,19,17,0],
[93,19,17,1],
[93,19,17,2],
[93,19,17,3],
[93,19,17,4],
[93,19,17,5],
[93,19,17,8],
[93,19,17,9],
[93,19,17,10],
[93,19,17,15],
[93,19,17,16],
[93,19,18,0],
[93,19,18,1],
[93,19,18,2],
[93,19,18,3],
[93,19,18,4],
[93,19,18,6],
[93,19,18,7],
[93,19,18,8],
[93,19,18,10],
[93,19,18,16],
[93,19,18,17],
[93,20,3,0],
[93,20,3,1],
[93,20,3,2],
[93,20,4,0],
[93,20,4,1],
[93,20,4,2],
[93,20,4,3],
[93,20,5,0],
[93,20,5,1],
[93,20,5,2],
[93,20,5,4],
[93,20,6,0],
[93,20,6,1],
[93,20,6,2],
[93,20,6,4],
[93,20,7,0],
[93,20,7,1],
[93,20,7,2],
[93,20,7,3],
[93,20,7,4],
[93,20,7,5],
[93,20,7,6],
[93,20,8,0],
[93,20,8,1],
[93,20,8,2],
[93,20,8,4],
[93,20,8,7],
[93,20,9,0],
[93,20,9,1],
[93,20,9,2],
[93,20,9,3],
[93,20,9,4],
[93,20,9,5],
[93,20,9,6],
[93,20,9,7],
[93,20,9,8],
[93,20,13,0],
[93,20,13,1],
[93,20,13,2],
[93,20,13,3],
[93,20,13,4],
[93,20,13,5],
[93,20,13,6],
[93,20,13,7],
[93,20,13,8],
[93,20,13,9],
[93,20,14,0],
[93,20,14,1],
[93,20,14,2],
[93,20,14,3],
[93,20,14,4],
[93,20,14,5],
[93,20,14,6],
[93,20,14,7],
[93,20,15,0],
[93,20,15,1],
[93,20,15,2],
[93,20,15,3],
[93,20,15,4],
[93,20,15,5],
[93,20,15,8],
[93,20,15,13],
[93,20,15,14],
[93,20,16,0],
[93,20,16,1],
[93,20,16,2],
[93,20,16,3],
[93,20,16,4],
[93,20,16,6],
[93,20,16,7],
[93,20,16,8],
[93,20,16,9],
[93,20,16,13],
[93,20,16,14],
[93,20,16,15],
[93,20,17,0],
[93,20,17,1],
[93,20,17,2],
[93,20,17,3],
[93,20,17,4],
[93,20,17,6],
[93,20,17,7],
[93,20,17,8],
[93,20,17,9],
[93,20,17,13],
[93,20,17,14],
[93,20,17,15],
[93,20,17,16],
[93,20,18,0],
[93,20,18,1],
[93,20,18,2],
[93,20,18,5],
[93,20,18,6],
[93,20,18,7],
[93,20,18,8],
[93,20,18,13],
[93,20,18,14],
[93,20,18,16],
[93,20,18,17],
[93,20,19,3],
[93,20,19,4],
[93,20,19,5],
[93,20,19,6],
[93,20,19,7],
[93,20,19,8],
[93,20,19,9],
[93,20,19,15],
[93,20,19,16],
[93,20,19,17],
[93,20,19,18],
[93,21,4,0],
[93,21,4,1],
[93,21,4,2],
[93,21,7,0],
[93,21,7,1],
[93,21,7,2],
[93,21,7,4],
[93,21,9,0],
[93,21,9,1],
[93,21,9,2],
[93,21,9,4],
[93,21,9,7],
[93,21,10,0],
[93,21,10,1],
[93,21,10,2],
[93,21,10,4],
[93,21,10,7],
[93,21,10,9],
[93,21,13,0],
[93,21,13,1],
[93,21,13,2],
[93,21,13,4],
[93,21,13,7],
[93,21,13,10],
[93,21,14,0],
[93,21,14,1],
[93,21,14,2],
[93,21,14,4],
[93,21,14,9],
[93,21,14,10],
[93,21,15,0],
[93,21,15,1],
[93,21,15,2],
[93,21,15,4],
[93,21,15,7],
[93,21,15,10],
[93,21,15,13],
[93,21,15,14],
[93,21,16,0],
[93,21,16,1],
[93,21,16,2],
[93,21,16,7],
[93,21,16,9],
[93,21,16,10],
[93,21,16,13],
[93,21,16,14],
[93,21,16,15],
[93,21,17,0],
[93,21,17,1],
[93,21,17,2],
[93,21,17,7],
[93,21,17,9],
[93,21,17,10],
[93,21,17,13],
[93,21,17,14],
[93,21,17,15],
[93,21,17,16],
[93,21,18,4],
[93,21,18,7],
[93,21,18,10],
[93,21,18,13],
[93,21,18,14],
[93,21,18,16],
[93,21,18,17],
[93,21,19,0],
[93,21,19,1],
[93,21,19,2],
[93,21,19,4],
[93,21,19,7],
[93,21,19,9],
[93,21,19,10],
[93,21,19,15],
[93,21,19,16],
[93,21,19,17],
[93,21,19,18],
[93,21,20,0],
[93,21,20,1],
[93,21,20,2],
[93,21,20,4],
[93,21,20,7],
[93,21,20,9],
[93,21,20,13],
[93,21,20,14],
[93,21,20,15],
[93,21,20,16],
[93,21,20,17],
[93,21,20,18],
[93,22,3,0],
[93,22,3,1],
[93,22,3,2],
[93,22,4,0],
[93,22,4,1],
[93,22,4,2],
[93,22,4,3],
[93,22,5,0],
[93,22,5,1],
[93,22,5,2],
[93,22,5,4],
[93,22,6,0],
[93,22,6,1],
[93,22,6,2],
[93,22,6,4],
[93,22,7,0],
[93,22,7,1],
[93,22,7,2],
[93,22,7,3],
[93,22,7,4],
[93,22,7,5],
[93,22,7,6],
[93,22,8,0],
[93,22,8,1],
[93,22,8,2],
[93,22,8,4],
[93,22,8,7],
[93,22,9,0],
[93,22,9,1],
[93,22,9,2],
[93,22,9,3],
[93,22,9,4],
[93,22,9,5],
[93,22,9,6],
[93,22,9,7],
[93,22,9,8],
[93,22,13,0],
[93,22,13,1],
[93,22,13,2],
[93,22,13,3],
[93,22,13,4],
[93,22,13,5],
[93,22,13,8],
[93,22,13,9],
[93,22,14,0],
[93,22,14,1],
[93,22,14,2],
[93,22,14,3],
[93,22,14,4],
[93,22,14,6],
[93,22,14,7],
[93,22,14,8],
[93,22,14,9],
[93,22,15,0],
[93,22,15,1],
[93,22,15,2],
[93,22,15,5],
[93,22,15,6],
[93,22,15,7],
[93,22,15,8],
[93,22,15,13],
[93,22,15,14],
[93,22,16,3],
[93,22,16,4],
[93,22,16,5],
[93,22,16,6],
[93,22,16,7],
[93,22,16,8],
[93,22,16,9],
[93,22,16,13],
[93,22,16,14],
[93,22,16,15],
[93,22,17,3],
[93,22,17,4],
[93,22,17,5],
[93,22,17,6],
[93,22,17,7],
[93,22,17,8],
[93,22,17,9],
[93,22,17,13],
[93,22,17,14],
[93,22,17,15],
[93,22,17,16],
[93,22,18,0],
[93,22,18,1],
[93,22,18,2],
[93,22,18,3],
[93,22,18,4],
[93,22,18,5],
[93,22,18,6],
[93,22,18,7],
[93,22,18,8],
[93,22,18,13],
[93,22,18,14],
[93,22,18,16],
[93,22,18,17],
[93,22,19,0],
[93,22,19,1],
[93,22,19,2],
[93,22,19,3],
[93,22,19,4],
[93,22,19,5],
[93,22,19,6],
[93,22,19,7],
[93,22,19,8],
[93,22,19,9],
[93,22,19,15],
[93,22,19,16],
[93,22,19,17],
[93,22,19,18],
[93,22,21,0],
[93,22,21,1],
[93,22,21,2],
[93,22,21,4],
[93,22,21,7],
[93,22,21,9],
[93,22,21,13],
[93,22,21,14],
[93,22,21,15],
[93,23,3,0],
[93,23,3,1],
[93,23,3,2],
[93,23,4,0],
[93,23,4,1],
[93,23,4,2],
[93,23,4,3],
[93,23,5,0],
[93,23,5,1],
[93,23,5,2],
[93,23,5,4],
[93,23,6,0],
[93,23,6,1],
[93,23,6,2],
[93,23,6,4],
[93,23,7,0],
[93,23,7,1],
[93,23,7,2],
[93,23,7,3],
[93,23,7,4],
[93,23,7,5],
[93,23,7,6],
[93,23,8,0],
[93,23,8,1],
[93,23,8,2],
[93,23,8,4],
[93,23,8,7],
[93,23,9,0],
[93,23,9,1],
[93,23,9,2],
[93,23,9,3],
[93,23,9,4],
[93,23,9,5],
[93,23,9,6],
[93,23,9,7],
[93,23,10,0],
[93,23,10,1],
[93,23,10,2],
[93,23,10,3],
[93,23,10,4],
[93,23,10,5],
[93,23,10,8],
[93,23,10,9],
[93,23,13,0],
[93,23,13,1],
[93,23,13,2],
[93,23,13,3],
[93,23,13,4],
[93,23,13,6],
[93,23,13,7],
[93,23,13,8],
[93,23,13,9],
[93,23,13,10],
[93,23,14,0],
[93,23,14,1],
[93,23,14,2],
[93,23,14,5],
[93,23,14,6],
[93,23,14,7],
[93,23,14,8],
[93,23,14,9],
[93,23,14,10],
[93,23,15,3],
[93,23,15,4],
[93,23,15,5],
[93,23,15,6],
[93,23,15,7],
[93,23,15,8],
[93,23,15,10],
[93,23,15,13],
[93,23,15,14],
[93,23,16,0],
[93,23,16,1],
[93,23,16,2],
[93,23,16,3],
[93,23,16,4],
[93,23,16,5],
[93,23,16,6],
[93,23,16,7],
[93,23,16,8],
[93,23,16,9],
[93,23,16,10],
[93,23,16,13],
[93,23,16,14],
[93,23,16,15],
[93,23,17,0],
[93,23,17,1],
[93,23,17,2],
[93,23,17,3],
[93,23,17,4],
[93,23,17,5],
[93,23,17,6],
[93,23,17,7],
[93,23,17,8],
[93,23,17,9],
[93,23,17,10],
[93,23,17,13],
[93,23,17,14],
[93,23,17,15],
[93,23,17,16],
[93,23,18,0],
[93,23,18,1],
[93,23,18,2],
[93,23,18,3],
[93,23,18,4],
[93,23,18,5],
[93,23,18,6],
[93,23,18,7],
[93,23,18,8],
[93,23,18,10],
[93,23,18,13],
[93,23,18,14],
[93,23,18,16],
[93,23,18,17],
[93,23,19,0],
[93,23,19,1],
[93,23,19,2],
[93,23,19,3],
[93,23,19,4],
[93,23,19,5],
[93,23,19,6],
[93,23,19,7],
[93,23,19,8],
[93,23,19,9],
[93,23,19,10],
[93,23,19,15],
[93,23,19,16],
[93,23,19,17],
[93,23,20,0],
[93,23,20,1],
[93,23,20,2],
[93,23,20,3],
[93,23,20,4],
[93,23,20,5],
[93,23,20,6],
[93,23,20,7],
[93,23,20,8],
[93,23,20,9],
[93,23,20,13],
[93,23,20,14],
[93,23,20,15],
[93,23,21,0],
[93,23,21,1],
[93,23,21,2],
[93,23,21,4],
[93,23,21,7],
[93,23,21,9],
[93,23,21,10],
[93,23,21,13],
[93,23,21,14],
[93,23,22,0],
[93,23,22,1],
[93,23,22,2],
[93,23,22,3],
[93,23,22,4],
[93,23,22,5],
[93,23,22,6],
[93,23,22,7],
[93,23,22,8],
[93,23,22,9],
[93,23,22,13],
[93,24,3,0],
[93,24,3,1],
[93,24,3,2],
[93,24,4,0],
[93,24,4,1],
[93,24,4,2],
[93,24,4,3],
[93,24,5,0],
[93,24,5,1],
[93,24,5,2],
[93,24,5,4],
[93,24,6,0],
[93,24,6,1],
[93,24,6,2],
[93,24,6,4],
[93,24,7,0],
[93,24,7,1],
[93,24,7,2],
[93,24,7,3],
[93,24,7,4],
[93,24,7,5],
[93,24,7,6],
[93,24,8,0],
[93,24,8,1],
[93,24,8,2],
[93,24,8,4],
[93,24,8,7],
[93,24,9,0],
[93,24,9,1],
[93,24,9,2],
[93,24,9,3],
[93,24,9,4],
[93,24,9,5],
[93,24,9,6],
[93,24,9,7],
[93,24,10,0],
[93,24,10,1],
[93,24,10,2],
[93,24,10,3],
[93,24,10,4],
[93,24,10,5],
[93,24,10,8],
[93,24,10,9],
[93,24,13,0],
[93,24,13,1],
[93,24,13,2],
[93,24,13,3],
[93,24,13,4],
[93,24,13,6],
[93,24,13,7],
[93,24,13,8],
[93,24,13,9],
[93,24,13,10],
[93,24,14,0],
[93,24,14,1],
[93,24,14,2],
[93,24,14,5],
[93,24,14,6],
[93,24,14,7],
[93,24,14,8],
[93,24,14,9],
[93,24,14,10],
[93,24,15,3],
[93,24,15,4],
[93,24,15,5],
[93,24,15,6],
[93,24,15,7],
[93,24,15,8],
[93,24,15,10],
[93,24,15,13],
[93,24,15,14],
[93,24,17,0],
[93,24,17,1],
[93,24,17,2],
[93,24,17,3],
[93,24,17,4],
[93,24,17,5],
[93,24,17,6],
[93,24,17,7],
[93,24,17,8],
[93,24,17,9],
[93,24,17,10],
[93,24,17,13],
[93,24,17,14],
[93,24,17,15],
[93,24,18,0],
[93,24,18,1],
[93,24,18,2],
[93,24,18,3],
[93,24,18,4],
[93,24,18,5],
[93,24,18,6],
[93,24,18,7],
[93,24,18,8],
[93,24,18,10],
[93,24,18,13],
[93,24,18,14],
[93,24,18,17],
[93,24,19,0],
[93,24,19,1],
[93,24,19,2],
[93,24,19,3],
[93,24,19,4],
[93,24,19,5],
[93,24,19,6],
[93,24,19,7],
[93,24,19,8],
[93,24,19,9],
[93,24,19,10],
[93,24,19,15],
[93,24,19,17],
[93,24,20,0],
[93,24,20,1],
[93,24,20,2],
[93,24,20,3],
[93,24,20,4],
[93,24,20,5],
[93,24,20,6],
[93,24,20,7],
[93,24,20,8],
[93,24,20,9],
[93,24,20,13],
[93,24,20,14],
[93,24,20,15],
[93,24,21,0],
[93,24,21,1],
[93,24,21,2],
[93,24,21,4],
[93,24,21,7],
[93,24,21,9],
[93,24,21,10],
[93,24,21,13],
[93,24,21,14],
[93,24,22,0],
[93,24,22,1],
[93,24,22,2],
[93,24,22,3],
[93,24,22,4],
[93,24,22,5],
[93,24,22,6],
[93,24,22,7],
[93,24,22,8],
[93,24,22,9],
[93,24,22,13],
[93,24,23,0],
[93,24,23,1],
[93,24,23,2],
[93,24,23,3],
[93,24,23,4],
[93,24,23,5],
[93,24,23,6],
[93,24,23,7],
[93,24,23,8],
[93,24,23,9],
[93,24,23,10],
[93,25,3,0],
[93,25,3,1],
[93,25,3,2],
[93,25,4,0],
[93,25,4,1],
[93,25,4,2],
[93,25,4,3],
[93,25,5,0],
[93,25,5,1],
[93,25,5,2],
[93,25,5,4],
[93,25,6,0],
[93,25,6,1],
[93,25,6,2],
[93,25,6,4],
[93,25,8,0],
[93,25,8,1],
[93,25,8,2],
[93,25,8,4],
[93,25,9,0],
[93,25,9,1],
[93,25,9,2],
[93,25,9,3],
[93,25,9,4],
[93,25,9,6],
[93,25,9,8],
[93,25,10,0],
[93,25,10,1],
[93,25,10,2],
[93,25,10,5],
[93,25,10,6],
[93,25,10,8],
[93,25,10,9],
[93,25,13,3],
[93,25,13,4],
[93,25,13,5],
[93,25,13,6],
[93,25,13,8],
[93,25,13,9],
[93,25,13,10],
[93,25,14,0],
[93,25,14,1],
[93,25,14,2],
[93,25,14,3],
[93,25,14,4],
[93,25,14,5],
[93,25,14,6],
[93,25,14,8],
[93,25,14,9],
[93,25,14,10],
[93,25,15,0],
[93,25,15,1],
[93,25,15,2],
[93,25,15,3],
[93,25,15,4],
[93,25,15,5],
[93,25,15,6],
[93,25,15,8],
[93,25,15,10],
[93,25,15,13],
[93,25,15,14],
[93,25,16,0],
[93,25,16,1],
[93,25,16,2],
[93,25,16,3],
[93,25,16,4],
[93,25,16,5],
[93,25,16,6],
[93,25,16,8],
[93,25,16,9],
[93,25,16,10],
[93,25,16,13],
[93,25,16,14],
[93,25,16,15],
[93,25,17,0],
[93,25,17,1],
[93,25,17,2],
[93,25,17,3],
[93,25,17,4],
[93,25,17,5],
[93,25,17,6],
[93,25,17,8],
[93,25,17,9],
[93,25,17,10],
[93,25,17,13],
[93,25,17,14],
[93,25,17,15],
[93,25,17,16],
[93,25,18,0],
[93,25,18,1],
[93,25,18,2],
[93,25,18,3],
[93,25,18,4],
[93,25,18,5],
[93,25,18,6],
[93,25,18,8],
[93,25,18,10],
[93,25,18,13],
[93,25,18,14],
[93,25,19,0],
[93,25,19,1],
[93,25,19,2],
[93,25,19,3],
[93,25,19,4],
[93,25,19,5],
[93,25,19,6],
[93,25,19,8],
[93,25,19,9],
[93,25,19,10],
[93,25,20,0],
[93,25,20,1],
[93,25,20,2],
[93,25,20,3],
[93,25,20,4],
[93,25,20,5],
[93,25,20,6],
[93,25,20,8],
[93,25,20,9],
[93,25,20,13],
[93,25,21,0],
[93,25,21,1],
[93,25,21,2],
[93,25,21,4],
[93,25,21,9],
[93,25,21,10],
[93,25,22,0],
[93,25,22,1],
[93,25,22,2],
[93,25,22,3],
[93,25,22,4],
[93,25,22,5],
[93,25,22,6],
[93,25,22,8],
[93,25,22,9],
[93,25,23,0],
[93,25,23,1],
[93,25,23,2],
[93,25,23,3],
[93,25,23,4],
[93,25,23,5],
[93,25,23,6],
[93,25,24,0],
[93,25,24,1],
[93,25,24,2],
[93,25,24,3],
[93,25,24,4],
[93,25,24,5],
[93,25,24,6],
[93,26,3,0],
[93,26,3,1],
[93,26,3,2],
[93,26,4,0],
[93,26,4,1],
[93,26,4,2],
[93,26,4,3],
[93,26,5,0],
[93,26,5,1],
[93,26,5,2],
[93,26,5,4],
[93,26,6,0],
[93,26,6,1],
[93,26,6,2],
[93,26,6,4],
[93,26,7,0],
[93,26,7,1],
[93,26,7,2],
[93,26,7,3],
[93,26,7,4],
[93,26,7,6],
[93,26,8,0],
[93,26,8,1],
[93,26,8,2],
[93,26,8,7],
[93,26,10,3],
[93,26,10,4],
[93,26,10,5],
[93,26,10,6],
[93,26,10,7],
[93,26,10,8],
[93,26,13,0],
[93,26,13,1],
[93,26,13,2],
[93,26,13,3],
[93,26,13,4],
[93,26,13,5],
[93,26,13,6],
[93,26,13,7],
[93,26,13,8],
[93,26,13,10],
[93,26,14,0],
[93,26,14,1],
[93,26,14,2],
[93,26,14,3],
[93,26,14,4],
[93,26,14,5],
[93,26,14,6],
[93,26,14,7],
[93,26,14,8],
[93,26,14,10],
[93,26,16,0],
[93,26,16,1],
[93,26,16,2],
[93,26,16,3],
[93,26,16,4],
[93,26,16,5],
[93,26,16,6],
[93,26,16,7],
[93,26,16,8],
[93,26,16,10],
[93,26,16,13],
[93,26,16,14],
[93,26,17,0],
[93,26,17,1],
[93,26,17,2],
[93,26,17,3],
[93,26,17,4],
[93,26,17,5],
[93,26,17,6],
[93,26,17,7],
[93,26,17,8],
[93,26,17,10],
[93,26,17,13],
[93,26,17,14],
[93,26,19,0],
[93,26,19,1],
[93,26,19,2],
[93,26,19,3],
[93,26,19,4],
[93,26,19,5],
[93,26,19,6],
[93,26,19,7],
[93,26,19,8],
[93,26,19,10],
[93,26,20,0],
[93,26,20,1],
[93,26,20,2],
[93,26,20,3],
[93,26,20,4],
[93,26,20,5],
[93,26,20,6],
[93,26,20,7],
[93,26,20,8],
[93,26,21,0],
[93,26,21,1],
[93,26,21,2],
[93,26,21,4],
[93,26,21,7],
[93,26,22,0],
[93,26,22,1],
[93,26,22,2],
[93,26,22,3],
[93,26,22,4],
[93,26,22,5],
[93,26,22,6],
[93,26,22,7],
[93,26,23,0],
[93,26,23,1],
[93,26,23,2],
[93,26,23,3],
[93,26,23,4],
[93,26,23,5],
[93,26,24,0],
[93,26,24,1],
[93,26,24,2],
[93,26,24,3],
[93,26,24,4],
[93,26,24,5],
[93,26,25,0],
[93,26,25,1],
[93,26,25,2],
[93,27,3,0],
[93,27,3,1],
[93,27,3,2],
[93,27,4,0],
[93,27,4,1],
[93,27,4,2],
[93,27,4,3],
[93,27,5,0],
[93,27,5,1],
[93,27,5,2],
[93,27,5,4],
[93,27,6,0],
[93,27,6,1],
[93,27,6,2],
[93,27,7,0],
[93,27,7,1],
[93,27,7,2],
[93,27,7,5],
[93,27,7,6],
[93,27,8,4],
[93,27,8,7],
[93,27,9,3],
[93,27,9,4],
[93,27,9,5],
[93,27,9,6],
[93,27,9,7],
[93,27,9,8],
[93,27,13,0],
[93,27,13,1],
[93,27,13,2],
[93,27,13,3],
[93,27,13,4],
[93,27,13,5],
[93,27,13,6],
[93,27,13,7],
[93,27,13,8],
[93,27,13,9],
[93,27,14,0],
[93,27,14,1],
[93,27,14,2],
[93,27,14,3],
[93,27,14,4],
[93,27,14,5],
[93,27,14,6],
[93,27,14,7],
[93,27,14,8],
[93,27,14,9],
[93,27,15,0],
[93,27,15,1],
[93,27,15,2],
[93,27,15,3],
[93,27,15,4],
[93,27,15,5],
[93,27,15,6],
[93,27,15,7],
[93,27,15,8],
[93,27,15,13],
[93,27,15,14],
[93,27,16,0],
[93,27,16,1],
[93,27,16,2],
[93,27,16,3],
[93,27,16,4],
[93,27,16,5],
[93,27,16,6],
[93,27,16,7],
[93,27,16,8],
[93,27,16,9],
[93,27,16,13],
[93,27,16,14],
[93,27,17,0],
[93,27,17,1],
[93,27,17,2],
[93,27,17,3],
[93,27,17,4],
[93,27,17,5],
[93,27,17,6],
[93,27,17,7],
[93,27,17,8],
[93,27,17,9],
[93,27,17,13],
[93,27,17,14],
[93,27,18,0],
[93,27,18,1],
[93,27,18,2],
[93,27,18,3],
[93,27,18,4],
[93,27,18,5],
[93,27,18,6],
[93,27,18,7],
[93,27,18,8],
[93,27,18,13],
[93,27,19,0],
[93,27,19,1],
[93,27,19,2],
[93,27,19,3],
[93,27,19,4],
[93,27,19,5],
[93,27,19,6],
[93,27,19,7],
[93,27,19,8],
[93,27,19,9],
[93,27,21,0],
[93,27,21,1],
[93,27,21,2],
[93,27,21,4],
[93,27,21,7],
[93,27,23,0],
[93,27,23,1],
[93,27,23,2],
[93,27,23,3],
[93,27,23,4],
[93,27,24,0],
[93,27,24,1],
[93,27,24,2],
[93,27,24,3],
[93,27,24,4],
[93,28,3,0],
[93,28,3,1],
[93,28,3,2],
[93,28,4,0],
[93,28,4,1],
[93,28,4,2],
[93,28,4,3],
[93,28,5,0],
[93,28,5,1],
[93,28,5,2],
[93,28,6,4],
[93,28,8,0],
[93,28,8,1],
[93,28,8,2],
[93,28,8,4],
[93,28,9,0],
[93,28,9,1],
[93,28,9,2],
[93,28,9,3],
[93,28,9,4],
[93,28,9,5],
[93,28,9,6],
[93,28,9,8],
[93,28,10,0],
[93,28,10,1],
[93,28,10,2],
[93,28,10,3],
[93,28,10,4],
[93,28,10,5],
[93,28,10,6],
[93,28,10,8],
[93,28,10,9],
[93,28,13,0],
[93,28,13,1],
[93,28,13,2],
[93,28,13,3],
[93,28,13,4],
[93,28,13,5],
[93,28,13,6],
[93,28,13,8],
[93,28,13,9],
[93,28,13,10],
[93,28,14,0],
[93,28,14,1],
[93,28,14,2],
[93,28,14,3],
[93,28,14,4],
[93,28,14,5],
[93,28,14,6],
[93,28,14,8],
[93,28,14,9],
[93,28,14,10],
[93,28,15,0],
[93,28,15,1],
[93,28,15,2],
[93,28,15,3],
[93,28,15,4],
[93,28,15,5],
[93,28,15,6],
[93,28,15,8],
[93,28,15,10],
[93,28,15,13],
[93,28,15,14],
[93,28,16,0],
[93,28,16,1],
[93,28,16,2],
[93,28,16,3],
[93,28,16,4],
[93,28,16,5],
[93,28,16,6],
[93,28,16,8],
[93,28,16,9],
[93,28,16,10],
[93,28,16,13],
[93,28,17,0],
[93,28,17,1],
[93,28,17,2],
[93,28,17,3],
[93,28,17,4],
[93,28,17,5],
[93,28,17,6],
[93,28,17,8],
[93,28,17,9],
[93,28,17,10],
[93,28,17,13],
[93,28,18,0],
[93,28,18,1],
[93,28,18,2],
[93,28,18,3],
[93,28,18,4],
[93,28,18,5],
[93,28,18,6],
[93,28,18,8],
[93,28,18,10],
[93,28,19,0],
[93,28,19,1],
[93,28,19,2],
[93,28,19,3],
[93,28,19,4],
[93,28,19,5],
[93,28,19,6],
[93,28,19,8],
[93,28,19,9],
[93,28,20,0],
[93,28,20,1],
[93,28,20,2],
[93,28,20,3],
[93,28,20,4],
[93,28,20,5],
[93,28,20,6],
[93,28,21,0],
[93,28,21,1],
[93,28,21,2],
[93,28,21,4],
[93,28,22,0],
[93,28,22,1],
[93,28,22,2],
[93,28,22,3],
[93,28,22,4],
[93,28,23,0],
[93,28,23,1],
[93,28,23,2],
[93,28,24,0],
[93,28,24,1],
[93,28,24,2],
[93,30,4,3],
[93,30,5,0],
[93,30,5,1],
[93,30,5,2],
[93,30,5,4],
[93,30,6,0],
[93,30,6,1],
[93,30,6,2],
[93,30,6,4],
[93,30,7,0],
[93,30,7,1],
[93,30,7,2],
[93,30,7,3],
[93,30,7,4],
[93,30,7,5],
[93,30,7,6],
[93,30,8,0],
[93,30,8,1],
[93,30,8,2],
[93,30,8,4],
[93,30,8,7],
[93,30,9,0],
[93,30,9,1],
[93,30,9,2],
[93,30,9,3],
[93,30,9,4],
[93,30,9,5],
[93,30,9,6],
[93,30,9,7],
[93,30,9,8],
[93,30,10,0],
[93,30,10,1],
[93,30,10,2],
[93,30,10,3],
[93,30,10,4],
[93,30,10,5],
[93,30,10,6],
[93,30,10,7],
[93,30,10,8],
[93,30,10,9],
[93,30,13,0],
[93,30,13,1],
[93,30,13,2],
[93,30,13,3],
[93,30,13,4],
[93,30,13,5],
[93,30,13,6],
[93,30,13,7],
[93,30,13,8],
[93,30,13,9],
[93,30,13,10],
[93,30,14,0],
[93,30,14,1],
[93,30,14,2],
[93,30,14,3],
[93,30,14,4],
[93,30,14,5],
[93,30,14,6],
[93,30,14,7],
[93,30,14,8],
[93,30,14,9],
[93,30,14,10],
[93,30,15,0],
[93,30,15,1],
[93,30,15,2],
[93,30,15,3],
[93,30,15,4],
[93,30,15,5],
[93,30,15,6],
[93,30,15,7],
[93,30,15,8],
[93,30,15,10],
[93,30,16,0],
[93,30,16,1],
[93,30,16,2],
[93,30,16,3],
[93,30,16,4],
[93,30,16,5],
[93,30,16,6],
[93,30,16,7],
[93,30,16,8],
[93,30,16,9],
[93,30,17,0],
[93,30,17,1],
[93,30,17,2],
[93,30,17,3],
[93,30,17,4],
[93,30,17,5],
[93,30,17,6],
[93,30,17,7],
[93,30,17,8],
[93,30,17,9],
[93,30,18,0],
[93,30,18,1],
[93,30,18,2],
[93,30,18,3],
[93,30,18,4],
[93,30,18,5],
[93,30,18,6],
[93,30,18,7],
[93,30,19,0],
[93,30,19,1],
[93,30,19,2],
[93,30,19,3],
[93,30,19,4],
[93,30,19,5],
[93,30,20,0],
[93,30,20,1],
[93,30,20,2],
[93,30,20,3],
[93,30,20,4],
[93,30,21,0],
[93,30,21,1],
[93,30,21,2],
[93,31,3,0],
[93,31,3,1],
[93,31,3,2],
[93,31,4,0],
[93,31,4,1],
[93,31,4,2],
[93,31,4,3],
[93,31,5,0],
[93,31,5,1],
[93,31,5,2],
[93,31,5,4],
[93,31,6,0],
[93,31,6,1],
[93,31,6,2],
[93,31,6,4],
[93,31,7,0],
[93,31,7,1],
[93,31,7,2],
[93,31,7,3],
[93,31,7,4],
[93,31,7,5],
[93,31,7,6],
[93,31,8,0],
[93,31,8,1],
[93,31,8,2],
[93,31,8,4],
[93,31,8,7],
[93,31,9,0],
[93,31,9,1],
[93,31,9,2],
[93,31,9,3],
[93,31,9,4],
[93,31,9,5],
[93,31,9,6],
[93,31,9,7],
[93,31,9,8],
[93,31,10,0],
[93,31,10,1],
[93,31,10,2],
[93,31,10,3],
[93,31,10,4],
[93,31,10,5],
[93,31,10,6],
[93,31,10,7],
[93,31,10,8],
[93,31,10,9],
[93,31,13,0],
[93,31,13,1],
[93,31,13,2],
[93,31,13,3],
[93,31,13,4],
[93,31,13,5],
[93,31,13,6],
[93,31,13,7],
[93,31,13,8],
[93,31,13,9],
[93,31,13,10],
[93,31,14,0],
[93,31,14,1],
[93,31,14,2],
[93,31,14,3],
[93,31,14,4],
[93,31,14,5],
[93,31,14,6],
[93,31,14,7],
[93,31,14,8],
[93,31,14,9],
[93,31,14,10],
[93,31,15,0],
[93,31,15,1],
[93,31,15,2],
[93,31,15,3],
[93,31,15,4],
[93,31,15,5],
[93,31,15,6],
[93,31,15,7],
[93,31,15,8],
[93,31,16,0],
[93,31,16,1],
[93,31,16,2],
[93,31,16,3],
[93,31,16,4],
[93,31,16,5],
[93,31,16,6],
[93,31,16,7],
[93,31,17,0],
[93,31,17,1],
[93,31,17,2],
[93,31,17,3],
[93,31,17,4],
[93,31,17,5],
[93,31,17,6],
[93,31,17,7],
[93,31,18,0],
[93,31,18,1],
[93,31,18,2],
[93,31,18,3],
[93,31,18,4],
[93,31,18,5],
[93,31,19,0],
[93,31,19,1],
[93,31,19,2],
[93,31,19,3],
[93,31,19,4],
[93,31,20,0],
[93,31,20,1],
[93,31,20,2],
[93,34,3,0],
[93,34,3,1],
[93,34,3,2],
[93,34,4,0],
[93,34,4,1],
[93,34,4,2],
[93,34,4,3],
[93,34,5,0],
[93,34,5,1],
[93,34,5,2],
[93,34,5,4],
[93,34,6,0],
[93,34,6,1],
[93,34,6,2],
[93,34,6,4],
[93,34,8,0],
[93,34,8,1],
[93,34,8,2],
[93,34,8,4],
[93,34,9,0],
[93,34,9,1],
[93,34,9,2],
[93,34,9,3],
[93,34,9,4],
[93,34,9,5],
[93,34,9,6],
[93,34,9,8],
[93,34,10,0],
[93,34,10,1],
[93,34,10,2],
[93,34,10,3],
[93,34,10,4],
[93,34,10,5],
[93,34,10,6],
[93,34,10,8],
[93,34,10,9],
[93,34,13,0],
[93,34,13,1],
[93,34,13,2],
[93,34,13,3],
[93,34,13,4],
[93,34,13,5],
[93,34,13,6],
[93,34,14,0],
[93,34,14,1],
[93,34,14,2],
[93,34,14,3],
[93,34,14,4],
[93,34,14,5],
[93,34,15,0],
[93,34,15,1],
[93,34,15,2],
[93,34,15,3],
[93,34,15,4],
[93,34,16,0],
[93,34,16,1],
[93,34,16,2],
[93,34,17,0],
[93,34,17,1],
[93,34,17,2],
[93,35,3,0],
[93,35,3,1],
[93,35,3,2],
[93,35,4,0],
[93,35,4,1],
[93,35,4,2],
[93,35,4,3],
[93,35,5,0],
[93,35,5,1],
[93,35,5,2],
[93,35,5,4],
[93,35,6,0],
[93,35,6,1],
[93,35,6,2],
[93,35,6,4],
[93,35,7,0],
[93,35,7,1],
[93,35,7,2],
[93,35,7,3],
[93,35,7,4],
[93,35,7,5],
[93,35,7,6],
[93,35,8,0],
[93,35,8,1],
[93,35,8,2],
[93,35,8,4],
[93,35,8,7],
[93,35,10,0],
[93,35,10,1],
[93,35,10,2],
[93,35,10,3],
[93,35,10,4],
[93,35,10,5],
[93,35,10,6],
[93,35,10,7],
[93,35,13,0],
[93,35,13,1],
[93,35,13,2],
[93,35,13,3],
[93,35,13,4],
[93,35,13,5],
[93,35,14,0],
[93,35,14,1],
[93,35,14,2],
[93,35,14,3],
[93,35,14,4],
[93,36,3,0],
[93,36,3,1],
[93,36,3,2],
[93,36,4,0],
[93,36,4,1],
[93,36,4,2],
[93,36,4,3],
[93,36,5,0],
[93,36,5,1],
[93,36,5,2],
[93,36,5,4],
[93,36,6,0],
[93,36,6,1],
[93,36,6,2],
[93,36,6,4],
[93,36,7,0],
[93,36,7,1],
[93,36,7,2],
[93,36,7,3],
[93,36,7,4],
[93,36,7,5],
[93,36,7,6],
[93,36,8,0],
[93,36,8,1],
[93,36,8,2],
[93,36,8,4],
[93,36,8,7],
[93,36,9,0],
[93,36,9,1],
[93,36,9,2],
[93,36,9,3],
[93,36,9,4],
[93,36,9,5],
[93,36,9,6],
[93,36,9,7],
[93,36,10,0],
[93,36,10,1],
[93,36,10,2],
[93,36,10,3],
[93,36,10,4],
[93,36,10,5],
[93,37,3,0],
[93,37,3,1],
[93,37,3,2],
[93,37,5,0],
[93,37,5,1],
[93,37,5,2],
[93,37,6,0],
[93,37,6,1],
[93,37,6,2],
[93,37,7,0],
[93,37,7,1],
[93,37,7,2],
[93,37,7,3],
[93,37,7,5],
[93,37,7,6],
[93,37,8,0],
[93,37,8,1],
[93,37,8,2],
[93,37,9,0],
[93,37,9,1],
[93,37,9,2],
[93,37,9,3],
[93,37,9,5],
[93,37,10,0],
[93,37,10,1],
[93,37,10,2],
[93,37,10,3],
[93,37,13,0],
[93,37,13,1],
[93,37,13,2],
[93,38,3,0],
[93,38,3,1],
[93,38,3,2],
[93,38,4,0],
[93,38,4,1],
[93,38,4,2],
[93,38,4,3],
[93,38,5,0],
[93,38,5,1],
[93,38,5,2],
[93,38,5,4],
[93,38,6,0],
[93,38,6,1],
[93,38,6,2],
[93,38,6,4],
[93,38,8,0],
[93,38,8,1],
[93,38,8,2],
[93,38,8,4],
[93,38,9,0],
[93,38,9,1],
[93,38,9,2],
[93,38,9,3],
[93,38,9,4],
[93,38,10,0],
[93,38,10,1],
[93,38,10,2],
[93,39,4,0],
[93,39,4,1],
[93,39,4,2],
[93,39,7,0],
[93,39,7,1],
[93,39,7,2],
[93,39,7,4],
[93,39,9,0],
[93,39,9,1],
[93,39,9,2],
[93,40,3,0],
[93,40,3,1],
[93,40,3,2],
[93,40,4,0],
[93,40,4,1],
[93,40,4,2],
[93,40,4,3],
[93,40,5,0],
[93,40,5,1],
[93,40,5,2],
[93,40,5,4],
[93,40,6,0],
[93,40,6,1],
[93,40,6,2],
[93,40,7,0],
[93,40,7,1],
[93,40,7,2],
[93,41,3,0],
[93,41,3,1],
[93,41,3,2],
[93,41,4,0],
[93,41,4,1],
[93,41,4,2],
[93,41,4,3],
[93,41,5,0],
[93,41,5,1],
[93,41,5,2],
[93,41,5,4],
[93,41,6,0],
[93,41,6,1],
[93,41,6,2],
[93,41,7,0],
[93,41,7,1],
[93,41,7,2],
[93,42,3,0],
[93,42,3,1],
[93,42,3,2],
[93,42,4,0],
[93,42,4,1],
[93,42,4,2],
[93,42,4,3],
[93,42,5,0],
[93,42,5,1],
[93,42,5,2],
[93,43,3,0],
[93,43,3,1],
[93,43,3,2],
[93,43,4,0],
[93,43,4,1],
[93,43,4,2],
[94,4,3,0],
[94,4,3,1],
[94,4,3,2],
[94,5,4,0],
[94,5,4,1],
[94,5,4,2],
[94,6,4,0],
[94,6,4,1],
[94,6,4,2],
[94,7,3,0],
[94,7,3,1],
[94,7,3,2],
[94,7,4,0],
[94,7,4,1],
[94,7,4,2],
[94,7,4,3],
[94,7,5,0],
[94,7,5,1],
[94,7,5,2],
[94,7,5,4],
[94,7,6,0],
[94,7,6,1],
[94,7,6,2],
[94,7,6,4],
[94,8,4,0],
[94,8,4,1],
[94,8,4,2],
[94,8,7,0],
[94,8,7,1],
[94,8,7,2],
[94,8,7,4],
[94,9,3,0],
[94,9,3,1],
[94,9,3,2],
[94,9,4,0],
[94,9,4,1],
[94,9,4,2],
[94,9,4,3],
[94,9,5,0],
[94,9,5,1],
[94,9,5,2],
[94,9,5,4],
[94,9,6,0],
[94,9,6,1],
[94,9,6,2],
[94,9,6,4],
[94,9,7,0],
[94,9,7,1],
[94,9,7,2],
[94,9,7,3],
[94,9,7,4],
[94,9,7,5],
[94,9,7,6],
[94,9,8,0],
[94,9,8,1],
[94,9,8,2],
[94,9,8,4],
[94,9,8,7],
[94,11,3,0],
[94,11,3,1],
[94,11,3,2],
[94,11,4,0],
[94,11,4,1],
[94,11,4,2],
[94,11,4,3],
[94,11,5,0],
[94,11,5,1],
[94,11,5,2],
[94,11,5,4],
[94,11,6,0],
[94,11,6,1],
[94,11,6,2],
[94,11,6,4],
[94,11,7,0],
[94,11,7,1],
[94,11,7,2],
[94,11,7,3],
[94,11,7,4],
[94,11,7,5],
[94,11,7,6],
[94,11,8,0],
[94,11,8,1],
[94,11,8,2],
[94,11,8,4],
[94,11,8,7],
[94,11,9,0],
[94,11,9,1],
[94,11,9,2],
[94,11,9,3],
[94,11,9,4],
[94,11,9,5],
[94,11,9,6],
[94,11,9,7],
[94,11,9,8],
[94,12,3,0],
[94,12,3,1],
[94,12,3,2],
[94,12,4,0],
[94,12,4,1],
[94,12,4,2],
[94,12,4,3],
[94,12,5,0],
[94,12,5,1],
[94,12,5,2],
[94,12,5,4],
[94,12,6,0],
[94,12,6,1],
[94,12,6,2],
[94,12,6,4],
[94,12,7,0],
[94,12,7,1],
[94,12,7,2],
[94,12,7,3],
[94,12,7,4],
[94,12,7,5],
[94,12,7,6],
[94,12,8,0],
[94,12,8,1],
[94,12,8,2],
[94,12,8,4],
[94,12,8,7],
[94,12,9,0],
[94,12,9,1],
[94,12,9,2],
[94,12,9,3],
[94,12,9,4],
[94,12,9,5],
[94,12,9,6],
[94,12,9,7],
[94,12,9,8],
[94,13,3,0],
[94,13,3,1],
[94,13,3,2],
[94,13,4,0],
[94,13,4,1],
[94,13,4,2],
[94,13,4,3],
[94,13,5,0],
[94,13,5,1],
[94,13,5,2],
[94,13,5,4],
[94,13,6,0],
[94,13,6,1],
[94,13,6,2],
[94,13,6,4],
[94,13,7,0],
[94,13,7,1],
[94,13,7,2],
[94,13,7,3],
[94,13,7,4],
[94,13,7,5],
[94,13,7,6],
[94,13,8,0],
[94,13,8,1],
[94,13,8,2],
[94,13,8,4],
[94,13,8,7],
[94,13,9,0],
[94,13,9,1],
[94,13,9,2],
[94,13,9,3],
[94,13,9,4],
[94,13,9,5],
[94,13,9,6],
[94,13,9,7],
[94,13,9,8],
[94,13,11,0],
[94,13,11,1],
[94,13,11,2],
[94,13,11,3],
[94,13,11,4],
[94,13,11,5],
[94,13,11,6],
[94,13,11,7],
[94,13,11,8],
[94,13,11,9],
[94,13,12,0],
[94,13,12,1],
[94,13,12,2],
[94,13,12,3],
[94,13,12,4],
[94,13,12,5],
[94,13,12,6],
[94,13,12,7],
[94,13,12,8],
[94,13,12,9],
[94,14,3,0],
[94,14,3,1],
[94,14,3,2],
[94,14,4,0],
[94,14,4,1],
[94,14,4,2],
[94,14,4,3],
[94,14,5,0],
[94,14,5,1],
[94,14,5,2],
[94,14,5,4],
[94,14,6,0],
[94,14,6,1],
[94,14,6,2],
[94,14,6,4],
[94,14,7,0],
[94,14,7,1],
[94,14,7,2],
[94,14,7,3],
[94,14,7,4],
[94,14,7,5],
[94,14,7,6],
[94,14,8,0],
[94,14,8,1],
[94,14,8,2],
[94,14,8,4],
[94,14,8,7],
[94,14,9,0],
[94,14,9,1],
[94,14,9,2],
[94,14,9,3],
[94,14,9,4],
[94,14,9,5],
[94,14,9,6],
[94,14,9,7],
[94,14,9,8],
[94,14,11,0],
[94,14,11,1],
[94,14,11,2],
[94,14,11,3],
[94,14,11,4],
[94,14,11,5],
[94,14,11,6],
[94,14,11,7],
[94,14,11,8],
[94,14,11,9],
[94,14,12,0],
[94,14,12,1],
[94,14,12,2],
[94,14,12,3],
[94,14,12,4],
[94,14,12,5],
[94,14,12,6],
[94,14,12,7],
[94,14,12,8],
[94,14,12,9],
[94,15,3,0],
[94,15,3,1],
[94,15,3,2],
[94,15,4,0],
[94,15,4,1],
[94,15,4,2],
[94,15,4,3],
[94,15,5,0],
[94,15,5,1],
[94,15,5,2],
[94,15,5,4],
[94,15,6,0],
[94,15,6,1],
[94,15,6,2],
[94,15,6,4],
[94,15,7,0],
[94,15,7,1],
[94,15,7,2],
[94,15,7,3],
[94,15,7,4],
[94,15,7,5],
[94,15,7,6],
[94,15,8,0],
[94,15,8,1],
[94,15,8,2],
[94,15,8,4],
[94,15,8,7],
[94,15,11,0],
[94,15,11,1],
[94,15,11,2],
[94,15,11,3],
[94,15,11,4],
[94,15,11,5],
[94,15,11,6],
[94,15,11,7],
[94,15,11,8],
[94,15,12,0],
[94,15,12,1],
[94,15,12,2],
[94,15,12,3],
[94,15,12,4],
[94,15,12,5],
[94,15,12,6],
[94,15,12,7],
[94,15,12,8],
[94,15,13,0],
[94,15,13,1],
[94,15,13,2],
[94,15,13,3],
[94,15,13,4],
[94,15,13,5],
[94,15,13,6],
[94,15,13,7],
[94,15,13,8],
[94,15,13,11],
[94,15,13,12],
[94,15,14,0],
[94,15,14,1],
[94,15,14,2],
[94,15,14,3],
[94,15,14,4],
[94,15,14,5],
[94,15,14,6],
[94,15,14,7],
[94,15,14,8],
[94,15,14,11],
[94,15,14,12],
[94,16,3,0],
[94,16,3,1],
[94,16,3,2],
[94,16,4,0],
[94,16,4,1],
[94,16,4,2],
[94,16,4,3],
[94,16,5,0],
[94,16,5,1],
[94,16,5,2],
[94,16,5,4],
[94,16,6,0],
[94,16,6,1],
[94,16,6,2],
[94,16,6,4],
[94,16,7,0],
[94,16,7,1],
[94,16,7,2],
[94,16,7,3],
[94,16,7,4],
[94,16,7,5],
[94,16,7,6],
[94,16,8,0],
[94,16,8,1],
[94,16,8,2],
[94,16,8,4],
[94,16,8,7],
[94,16,9,0],
[94,16,9,1],
[94,16,9,2],
[94,16,9,3],
[94,16,9,4],
[94,16,9,5],
[94,16,9,6],
[94,16,9,7],
[94,16,9,8],
[94,16,11,0],
[94,16,11,1],
[94,16,11,2],
[94,16,11,3],
[94,16,11,4],
[94,16,11,5],
[94,16,11,6],
[94,16,11,7],
[94,16,11,8],
[94,16,11,9],
[94,16,12,0],
[94,16,12,1],
[94,16,12,2],
[94,16,12,3],
[94,16,12,4],
[94,16,12,5],
[94,16,12,6],
[94,16,12,7],
[94,16,12,8],
[94,16,12,9],
[94,16,13,0],
[94,16,13,1],
[94,16,13,2],
[94,16,13,3],
[94,16,13,4],
[94,16,13,5],
[94,16,13,6],
[94,16,13,7],
[94,16,13,8],
[94,16,13,9],
[94,16,13,11],
[94,16,13,12],
[94,16,14,0],
[94,16,14,1],
[94,16,14,2],
[94,16,14,3],
[94,16,14,4],
[94,16,14,5],
[94,16,14,6],
[94,16,14,7],
[94,16,14,8],
[94,16,14,9],
[94,16,14,11],
[94,16,15,0],
[94,16,15,1],
[94,16,15,2],
[94,16,15,3],
[94,16,15,4],
[94,16,15,5],
[94,16,15,6],
[94,16,15,7],
[94,16,15,8],
[94,16,15,12],
[94,16,15,13],
[94,16,15,14],
[94,17,3,0],
[94,17,3,1],
[94,17,3,2],
[94,17,4,0],
[94,17,4,1],
[94,17,4,2],
[94,17,4,3],
[94,17,5,0],
[94,17,5,1],
[94,17,5,2],
[94,17,5,4],
[94,17,6,0],
[94,17,6,1],
[94,17,6,2],
[94,17,6,4],
[94,17,7,0],
[94,17,7,1],
[94,17,7,2],
[94,17,7,3],
[94,17,7,4],
[94,17,7,5],
[94,17,7,6],
[94,17,8,0],
[94,17,8,1],
[94,17,8,2],
[94,17,8,4],
[94,17,8,7],
[94,17,9,0],
[94,17,9,1],
[94,17,9,2],
[94,17,9,3],
[94,17,9,4],
[94,17,9,5],
[94,17,9,6],
[94,17,9,7],
[94,17,9,8],
[94,17,11,0],
[94,17,11,1],
[94,17,11,2],
[94,17,11,3],
[94,17,11,4],
[94,17,11,5],
[94,17,11,6],
[94,17,11,7],
[94,17,11,8],
[94,17,11,9],
[94,17,12,0],
[94,17,12,1],
[94,17,12,2],
[94,17,12,3],
[94,17,12,4],
[94,17,12,5],
[94,17,12,6],
[94,17,12,7],
[94,17,12,8],
[94,17,12,9],
[94,17,13,0],
[94,17,13,1],
[94,17,13,2],
[94,17,13,3],
[94,17,13,4],
[94,17,13,5],
[94,17,13,6],
[94,17,13,7],
[94,17,13,8],
[94,17,13,9],
[94,17,13,11],
[94,17,13,12],
[94,17,14,0],
[94,17,14,1],
[94,17,14,2],
[94,17,14,3],
[94,17,14,4],
[94,17,14,5],
[94,17,14,6],
[94,17,14,7],
[94,17,14,8],
[94,17,14,9],
[94,17,14,11],
[94,17,15,0],
[94,17,15,1],
[94,17,15,2],
[94,17,15,3],
[94,17,15,4],
[94,17,15,5],
[94,17,15,6],
[94,17,15,7],
[94,17,15,8],
[94,17,15,12],
[94,17,15,13],
[94,17,15,14],
[94,17,16,0],
[94,17,16,1],
[94,17,16,2],
[94,17,16,3],
[94,17,16,4],
[94,17,16,5],
[94,17,16,6],
[94,17,16,7],
[94,17,16,11],
[94,17,16,12],
[94,17,16,13],
[94,17,16,14],
[94,17,16,15],
[94,18,3,0],
[94,18,3,1],
[94,18,3,2],
[94,18,4,0],
[94,18,4,1],
[94,18,4,2],
[94,18,4,3],
[94,18,5,0],
[94,18,5,1],
[94,18,5,2],
[94,18,5,4],
[94,18,6,0],
[94,18,6,1],
[94,18,6,2],
[94,18,6,4],
[94,18,7,0],
[94,18,7,1],
[94,18,7,2],
[94,18,7,3],
[94,18,7,4],
[94,18,7,5],
[94,18,7,6],
[94,18,8,0],
[94,18,8,1],
[94,18,8,2],
[94,18,8,4],
[94,18,8,7],
[94,18,11,0],
[94,18,11,1],
[94,18,11,2],
[94,18,11,3],
[94,18,11,4],
[94,18,11,5],
[94,18,11,6],
[94,18,11,7],
[94,18,11,8],
[94,18,12,0],
[94,18,12,1],
[94,18,12,2],
[94,18,12,3],
[94,18,12,4],
[94,18,12,5],
[94,18,12,6],
[94,18,12,7],
[94,18,12,8],
[94,18,13,0],
[94,18,13,1],
[94,18,13,2],
[94,18,13,3],
[94,18,13,4],
[94,18,13,5],
[94,18,13,6],
[94,18,13,7],
[94,18,13,8],
[94,18,13,11],
[94,18,14,0],
[94,18,14,1],
[94,18,14,2],
[94,18,14,3],
[94,18,14,4],
[94,18,14,5],
[94,18,14,6],
[94,18,14,7],
[94,18,14,8],
[94,18,14,12],
[94,18,16,0],
[94,18,16,1],
[94,18,16,2],
[94,18,16,3],
[94,18,16,4],
[94,18,16,5],
[94,18,16,8],
[94,18,16,11],
[94,18,16,12],
[94,18,16,13],
[94,18,16,14],
[94,18,17,0],
[94,18,17,1],
[94,18,17,2],
[94,18,17,3],
[94,18,17,4],
[94,18,17,5],
[94,18,17,8],
[94,18,17,11],
[94,18,17,12],
[94,18,17,13],
[94,18,17,14],
[94,18,17,16],
[94,19,3,0],
[94,19,3,1],
[94,19,3,2],
[94,19,4,0],
[94,19,4,1],
[94,19,4,2],
[94,19,4,3],
[94,19,5,0],
[94,19,5,1],
[94,19,5,2],
[94,19,5,4],
[94,19,6,0],
[94,19,6,1],
[94,19,6,2],
[94,19,6,4],
[94,19,7,0],
[94,19,7,1],
[94,19,7,2],
[94,19,7,3],
[94,19,7,4],
[94,19,7,5],
[94,19,7,6],
[94,19,8,0],
[94,19,8,1],
[94,19,8,2],
[94,19,8,4],
[94,19,8,7],
[94,19,9,0],
[94,19,9,1],
[94,19,9,2],
[94,19,9,3],
[94,19,9,4],
[94,19,9,5],
[94,19,9,6],
[94,19,9,7],
[94,19,9,8],
[94,19,11,0],
[94,19,11,1],
[94,19,11,2],
[94,19,11,3],
[94,19,11,4],
[94,19,11,5],
[94,19,11,6],
[94,19,11,7],
[94,19,11,8],
[94,19,11,9],
[94,19,12,0],
[94,19,12,1],
[94,19,12,2],
[94,19,12,3],
[94,19,12,4],
[94,19,12,5],
[94,19,12,6],
[94,19,12,7],
[94,19,12,8],
[94,19,12,9],
[94,19,15,0],
[94,19,15,1],
[94,19,15,2],
[94,19,15,3],
[94,19,15,4],
[94,19,15,5],
[94,19,15,8],
[94,19,15,11],
[94,19,15,12],
[94,19,16,0],
[94,19,16,1],
[94,19,16,2],
[94,19,16,3],
[94,19,16,4],
[94,19,16,6],
[94,19,16,7],
[94,19,16,8],
[94,19,16,9],
[94,19,16,11],
[94,19,16,12],
[94,19,16,15],
[94,19,17,0],
[94,19,17,1],
[94,19,17,2],
[94,19,17,3],
[94,19,17,4],
[94,19,17,6],
[94,19,17,7],
[94,19,17,8],
[94,19,17,9],
[94,19,17,11],
[94,19,17,12],
[94,19,17,15],
[94,19,17,16],
[94,19,18,0],
[94,19,18,1],
[94,19,18,2],
[94,19,18,5],
[94,19,18,6],
[94,19,18,7],
[94,19,18,8],
[94,19,18,11],
[94,19,18,12],
[94,19,18,16],
[94,19,18,17],
[94,21,4,0],
[94,21,4,1],
[94,21,4,2],
[94,21,7,0],
[94,21,7,1],
[94,21,7,2],
[94,21,7,4],
[94,21,9,0],
[94,21,9,1],
[94,21,9,2],
[94,21,9,4],
[94,21,9,7],
[94,21,11,0],
[94,21,11,1],
[94,21,11,2],
[94,21,11,4],
[94,21,11,7],
[94,21,12,0],
[94,21,12,1],
[94,21,12,2],
[94,21,12,4],
[94,21,12,9],
[94,21,13,0],
[94,21,13,1],
[94,21,13,2],
[94,21,13,4],
[94,21,13,9],
[94,21,13,11],
[94,21,13,12],
[94,21,14,0],
[94,21,14,1],
[94,21,14,2],
[94,21,14,4],
[94,21,14,7],
[94,21,14,9],
[94,21,14,11],
[94,21,14,12],
[94,21,15,0],
[94,21,15,1],
[94,21,15,2],
[94,21,15,7],
[94,21,15,11],
[94,21,15,12],
[94,21,15,13],
[94,21,15,14],
[94,21,16,4],
[94,21,16,7],
[94,21,16,9],
[94,21,16,11],
[94,21,16,12],
[94,21,16,13],
[94,21,16,14],
[94,21,16,15],
[94,21,17,4],
[94,21,17,7],
[94,21,17,9],
[94,21,17,11],
[94,21,17,12],
[94,21,17,13],
[94,21,17,14],
[94,21,17,15],
[94,21,17,16],
[94,21,18,0],
[94,21,18,1],
[94,21,18,2],
[94,21,18,4],
[94,21,18,7],
[94,21,18,11],
[94,21,18,12],
[94,21,18,13],
[94,21,18,14],
[94,21,18,16],
[94,21,18,17],
[94,21,19,0],
[94,21,19,1],
[94,21,19,2],
[94,21,19,4],
[94,21,19,7],
[94,21,19,9],
[94,21,19,11],
[94,21,19,12],
[94,21,19,15],
[94,21,19,16],
[94,21,19,17],
[94,21,19,18],
[94,23,3,0],
[94,23,3,1],
[94,23,3,2],
[94,23,4,0],
[94,23,4,1],
[94,23,4,2],
[94,23,4,3],
[94,23,5,0],
[94,23,5,1],
[94,23,5,2],
[94,23,5,4],
[94,23,6,0],
[94,23,6,1],
[94,23,6,2],
[94,23,6,4],
[94,23,7,0],
[94,23,7,1],
[94,23,7,2],
[94,23,7,3],
[94,23,7,4],
[94,23,7,5],
[94,23,7,6],
[94,23,8,0],
[94,23,8,1],
[94,23,8,2],
[94,23,8,4],
[94,23,9,0],
[94,23,9,1],
[94,23,9,2],
[94,23,9,3],
[94,23,9,4],
[94,23,9,5],
[94,23,9,8],
[94,23,11,0],
[94,23,11,1],
[94,23,11,2],
[94,23,11,3],
[94,23,11,4],
[94,23,11,6],
[94,23,11,7],
[94,23,11,8],
[94,23,11,9],
[94,23,12,0],
[94,23,12,1],
[94,23,12,2],
[94,23,12,5],
[94,23,12,6],
[94,23,12,7],
[94,23,12,8],
[94,23,12,9],
[94,23,13,0],
[94,23,13,1],
[94,23,13,2],
[94,23,13,5],
[94,23,13,6],
[94,23,13,7],
[94,23,13,8],
[94,23,13,9],
[94,23,13,11],
[94,23,13,12],
[94,23,14,3],
[94,23,14,4],
[94,23,14,5],
[94,23,14,6],
[94,23,14,7],
[94,23,14,8],
[94,23,14,9],
[94,23,14,11],
[94,23,14,12],
[94,23,15,0],
[94,23,15,1],
[94,23,15,2],
[94,23,15,3],
[94,23,15,4],
[94,23,15,5],
[94,23,15,6],
[94,23,15,7],
[94,23,15,8],
[94,23,15,11],
[94,23,15,12],
[94,23,15,13],
[94,23,15,14],
[94,23,16,0],
[94,23,16,1],
[94,23,16,2],
[94,23,16,3],
[94,23,16,4],
[94,23,16,5],
[94,23,16,6],
[94,23,16,7],
[94,23,16,8],
[94,23,16,9],
[94,23,16,11],
[94,23,16,12],
[94,23,16,13],
[94,23,16,14],
[94,23,16,15],
[94,23,17,0],
[94,23,17,1],
[94,23,17,2],
[94,23,17,3],
[94,23,17,4],
[94,23,17,5],
[94,23,17,6],
[94,23,17,7],
[94,23,17,8],
[94,23,17,9],
[94,23,17,11],
[94,23,17,12],
[94,23,17,13],
[94,23,17,14],
[94,23,17,15],
[94,23,17,16],
[94,23,18,0],
[94,23,18,1],
[94,23,18,2],
[94,23,18,3],
[94,23,18,4],
[94,23,18,5],
[94,23,18,6],
[94,23,18,7],
[94,23,18,8],
[94,23,18,11],
[94,23,18,12],
[94,23,18,13],
[94,23,18,14],
[94,23,18,16],
[94,23,18,17],
[94,23,19,0],
[94,23,19,1],
[94,23,19,2],
[94,23,19,3],
[94,23,19,4],
[94,23,19,5],
[94,23,19,6],
[94,23,19,7],
[94,23,19,8],
[94,23,19,9],
[94,23,19,11],
[94,23,19,12],
[94,23,19,15],
[94,23,21,0],
[94,23,21,1],
[94,23,21,2],
[94,23,21,4],
[94,23,21,7],
[94,23,21,9],
[94,23,21,11],
[94,23,21,12],
[94,23,21,13],
[94,24,3,0],
[94,24,3,1],
[94,24,3,2],
[94,24,4,0],
[94,24,4,1],
[94,24,4,2],
[94,24,4,3],
[94,24,5,0],
[94,24,5,1],
[94,24,5,2],
[94,24,5,4],
[94,24,6,0],
[94,24,6,1],
[94,24,6,2],
[94,24,6,4],
[94,24,7,0],
[94,24,7,1],
[94,24,7,2],
[94,24,7,3],
[94,24,7,4],
[94,24,7,5],
[94,24,7,6],
[94,24,8,0],
[94,24,8,1],
[94,24,8,2],
[94,24,8,4],
[94,24,9,0],
[94,24,9,1],
[94,24,9,2],
[94,24,9,3],
[94,24,9,4],
[94,24,9,5],
[94,24,9,8],
[94,24,11,0],
[94,24,11,1],
[94,24,11,2],
[94,24,11,3],
[94,24,11,4],
[94,24,11,6],
[94,24,11,7],
[94,24,11,8],
[94,24,11,9],
[94,24,12,0],
[94,24,12,1],
[94,24,12,2],
[94,24,12,5],
[94,24,12,6],
[94,24,12,7],
[94,24,12,8],
[94,24,12,9],
[94,24,13,0],
[94,24,13,1],
[94,24,13,2],
[94,24,13,5],
[94,24,13,6],
[94,24,13,7],
[94,24,13,8],
[94,24,13,9],
[94,24,13,11],
[94,24,13,12],
[94,24,14,3],
[94,24,14,4],
[94,24,14,5],
[94,24,14,6],
[94,24,14,7],
[94,24,14,8],
[94,24,14,9],
[94,24,14,11],
[94,24,14,12],
[94,24,15,0],
[94,24,15,1],
[94,24,15,2],
[94,24,15,3],
[94,24,15,4],
[94,24,15,5],
[94,24,15,6],
[94,24,15,7],
[94,24,15,8],
[94,24,15,11],
[94,24,15,12],
[94,24,15,13],
[94,24,15,14],
[94,24,17,0],
[94,24,17,1],
[94,24,17,2],
[94,24,17,3],
[94,24,17,4],
[94,24,17,5],
[94,24,17,6],
[94,24,17,7],
[94,24,17,8],
[94,24,17,9],
[94,24,17,11],
[94,24,17,12],
[94,24,17,13],
[94,24,17,14],
[94,24,17,15],
[94,24,18,0],
[94,24,18,1],
[94,24,18,2],
[94,24,18,3],
[94,24,18,4],
[94,24,18,5],
[94,24,18,6],
[94,24,18,7],
[94,24,18,8],
[94,24,18,11],
[94,24,18,12],
[94,24,18,13],
[94,24,18,14],
[94,24,18,17],
[94,24,19,0],
[94,24,19,1],
[94,24,19,2],
[94,24,19,3],
[94,24,19,4],
[94,24,19,5],
[94,24,19,6],
[94,24,19,7],
[94,24,19,8],
[94,24,19,9],
[94,24,19,11],
[94,24,19,12],
[94,24,19,15],
[94,24,21,0],
[94,24,21,1],
[94,24,21,2],
[94,24,21,4],
[94,24,21,7],
[94,24,21,9],
[94,24,21,11],
[94,24,21,12],
[94,24,21,13],
[94,24,23,0],
[94,24,23,1],
[94,24,23,2],
[94,24,23,3],
[94,24,23,4],
[94,24,23,5],
[94,24,23,6],
[94,24,23,7],
[94,24,23,8],
[94,24,23,9],
[94,25,3,0],
[94,25,3,1],
[94,25,3,2],
[94,25,4,0],
[94,25,4,1],
[94,25,4,2],
[94,25,4,3],
[94,25,5,0],
[94,25,5,1],
[94,25,5,2],
[94,25,5,4],
[94,25,6,0],
[94,25,6,1],
[94,25,6,2],
[94,25,6,4],
[94,25,8,0],
[94,25,8,1],
[94,25,8,2],
[94,25,9,0],
[94,25,9,1],
[94,25,9,2],
[94,25,9,5],
[94,25,9,6],
[94,25,9,8],
[94,25,11,3],
[94,25,11,4],
[94,25,11,5],
[94,25,11,6],
[94,25,11,8],
[94,25,11,9],
[94,25,12,0],
[94,25,12,1],
[94,25,12,2],
[94,25,12,3],
[94,25,12,4],
[94,25,12,5],
[94,25,12,6],
[94,25,12,8],
[94,25,12,9],
[94,25,13,0],
[94,25,13,1],
[94,25,13,2],
[94,25,13,3],
[94,25,13,4],
[94,25,13,5],
[94,25,13,6],
[94,25,13,8],
[94,25,13,9],
[94,25,13,11],
[94,25,13,12],
[94,25,14,0],
[94,25,14,1],
[94,25,14,2],
[94,25,14,3],
[94,25,14,4],
[94,25,14,5],
[94,25,14,6],
[94,25,14,8],
[94,25,14,9],
[94,25,14,11],
[94,25,14,12],
[94,25,15,0],
[94,25,15,1],
[94,25,15,2],
[94,25,15,3],
[94,25,15,4],
[94,25,15,5],
[94,25,15,6],
[94,25,15,8],
[94,25,15,11],
[94,25,15,12],
[94,25,15,13],
[94,25,15,14],
[94,25,16,0],
[94,25,16,1],
[94,25,16,2],
[94,25,16,3],
[94,25,16,4],
[94,25,16,5],
[94,25,16,6],
[94,25,16,8],
[94,25,16,9],
[94,25,16,11],
[94,25,16,12],
[94,25,16,13],
[94,25,16,14],
[94,25,16,15],
[94,25,17,0],
[94,25,17,1],
[94,25,17,2],
[94,25,17,3],
[94,25,17,4],
[94,25,17,5],
[94,25,17,6],
[94,25,17,8],
[94,25,17,9],
[94,25,17,11],
[94,25,17,12],
[94,25,17,13],
[94,25,17,14],
[94,25,17,15],
[94,25,18,0],
[94,25,18,1],
[94,25,18,2],
[94,25,18,3],
[94,25,18,4],
[94,25,18,5],
[94,25,18,6],
[94,25,18,8],
[94,25,18,11],
[94,25,18,12],
[94,25,18,13],
[94,25,18,14],
[94,25,19,0],
[94,25,19,1],
[94,25,19,2],
[94,25,19,3],
[94,25,19,4],
[94,25,19,5],
[94,25,19,6],
[94,25,19,8],
[94,25,19,9],
[94,25,19,11],
[94,25,19,12],
[94,25,21,0],
[94,25,21,1],
[94,25,21,2],
[94,25,21,4],
[94,25,21,9],
[94,25,23,0],
[94,25,23,1],
[94,25,23,2],
[94,25,23,3],
[94,25,23,4],
[94,25,23,5],
[94,25,24,0],
[94,25,24,1],
[94,25,24,2],
[94,25,24,3],
[94,25,24,4],
[94,25,24,5],
[94,26,3,0],
[94,26,3,1],
[94,26,3,2],
[94,26,4,0],
[94,26,4,1],
[94,26,4,2],
[94,26,4,3],
[94,26,5,0],
[94,26,5,1],
[94,26,5,2],
[94,26,5,4],
[94,26,6,0],
[94,26,6,1],
[94,26,6,2],
[94,26,7,0],
[94,26,7,1],
[94,26,7,2],
[94,26,7,5],
[94,26,7,6],
[94,26,8,4],
[94,26,8,7],
[94,26,11,0],
[94,26,11,1],
[94,26,11,2],
[94,26,11,3],
[94,26,11,4],
[94,26,11,5],
[94,26,11,6],
[94,26,11,7],
[94,26,11,8],
[94,26,12,0],
[94,26,12,1],
[94,26,12,2],
[94,26,12,3],
[94,26,12,4],
[94,26,12,5],
[94,26,12,6],
[94,26,12,7],
[94,26,12,8],
[94,26,13,0],
[94,26,13,1],
[94,26,13,2],
[94,26,13,3],
[94,26,13,4],
[94,26,13,5],
[94,26,13,6],
[94,26,13,7],
[94,26,13,8],
[94,26,13,11],
[94,26,13,12],
[94,26,14,0],
[94,26,14,1],
[94,26,14,2],
[94,26,14,3],
[94,26,14,4],
[94,26,14,5],
[94,26,14,6],
[94,26,14,7],
[94,26,14,8],
[94,26,14,11],
[94,26,14,12],
[94,26,16,0],
[94,26,16,1],
[94,26,16,2],
[94,26,16,3],
[94,26,16,4],
[94,26,16,5],
[94,26,16,6],
[94,26,16,7],
[94,26,16,8],
[94,26,16,11],
[94,26,16,12],
[94,26,16,13],
[94,26,16,14],
[94,26,17,0],
[94,26,17,1],
[94,26,17,2],
[94,26,17,3],
[94,26,17,4],
[94,26,17,5],
[94,26,17,6],
[94,26,17,7],
[94,26,17,8],
[94,26,17,11],
[94,26,17,12],
[94,26,17,13],
[94,26,17,14],
[94,26,19,0],
[94,26,19,1],
[94,26,19,2],
[94,26,19,3],
[94,26,19,4],
[94,26,19,5],
[94,26,19,6],
[94,26,19,7],
[94,26,19,8],
[94,26,19,11],
[94,26,21,0],
[94,26,21,1],
[94,26,21,2],
[94,26,21,4],
[94,26,21,7],
[94,26,23,0],
[94,26,23,1],
[94,26,23,2],
[94,26,23,3],
[94,26,23,4],
[94,26,24,0],
[94,26,24,1],
[94,26,24,2],
[94,26,24,3],
[94,26,24,4],
[94,28,3,0],
[94,28,3,1],
[94,28,3,2],
[94,28,4,0],
[94,28,4,1],
[94,28,4,2],
[94,28,5,4],
[94,28,6,0],
[94,28,6,1],
[94,28,6,2],
[94,28,6,4],
[94,28,8,0],
[94,28,8,1],
[94,28,8,2],
[94,28,8,4],
[94,28,9,0],
[94,28,9,1],
[94,28,9,2],
[94,28,9,3],
[94,28,9,4],
[94,28,9,5],
[94,28,9,6],
[94,28,9,8],
[94,28,11,0],
[94,28,11,1],
[94,28,11,2],
[94,28,11,3],
[94,28,11,4],
[94,28,11,5],
[94,28,11,6],
[94,28,11,8],
[94,28,11,9],
[94,28,12,0],
[94,28,12,1],
[94,28,12,2],
[94,28,12,3],
[94,28,12,4],
[94,28,12,5],
[94,28,12,6],
[94,28,12,8],
[94,28,12,9],
[94,28,13,0],
[94,28,13,1],
[94,28,13,2],
[94,28,13,3],
[94,28,13,4],
[94,28,13,5],
[94,28,13,6],
[94,28,13,8],
[94,28,13,9],
[94,28,13,11],
[94,28,13,12],
[94,28,14,0],
[94,28,14,1],
[94,28,14,2],
[94,28,14,3],
[94,28,14,4],
[94,28,14,5],
[94,28,14,6],
[94,28,14,8],
[94,28,14,9],
[94,28,14,11],
[94,28,14,12],
[94,28,15,0],
[94,28,15,1],
[94,28,15,2],
[94,28,15,3],
[94,28,15,4],
[94,28,15,5],
[94,28,15,6],
[94,28,15,8],
[94,28,15,11],
[94,28,15,12],
[94,28,15,13],
[94,28,16,0],
[94,28,16,1],
[94,28,16,2],
[94,28,16,3],
[94,28,16,4],
[94,28,16,5],
[94,28,16,6],
[94,28,16,8],
[94,28,16,9],
[94,28,16,11],
[94,28,17,0],
[94,28,17,1],
[94,28,17,2],
[94,28,17,3],
[94,28,17,4],
[94,28,17,5],
[94,28,17,6],
[94,28,17,8],
[94,28,17,9],
[94,28,17,11],
[94,28,18,0],
[94,28,18,1],
[94,28,18,2],
[94,28,18,3],
[94,28,18,4],
[94,28,18,5],
[94,28,18,6],
[94,28,18,8],
[94,28,19,0],
[94,28,19,1],
[94,28,19,2],
[94,28,19,3],
[94,28,19,4],
[94,28,19,5],
[94,28,19,6],
[94,28,21,0],
[94,28,21,1],
[94,28,21,2],
[94,28,21,4],
[94,29,4,3],
[94,29,5,0],
[94,29,5,1],
[94,29,5,2],
[94,29,5,4],
[94,29,6,0],
[94,29,6,1],
[94,29,6,2],
[94,29,6,4],
[94,29,7,0],
[94,29,7,1],
[94,29,7,2],
[94,29,7,3],
[94,29,7,4],
[94,29,7,5],
[94,29,7,6],
[94,29,8,0],
[94,29,8,1],
[94,29,8,2],
[94,29,8,4],
[94,29,8,7],
[94,29,9,0],
[94,29,9,1],
[94,29,9,2],
[94,29,9,3],
[94,29,9,4],
[94,29,9,5],
[94,29,9,6],
[94,29,9,7],
[94,29,9,8],
[94,29,13,0],
[94,29,13,1],
[94,29,13,2],
[94,29,13,3],
[94,29,13,4],
[94,29,13,5],
[94,29,13,6],
[94,29,13,7],
[94,29,13,8],
[94,29,13,9],
[94,29,14,0],
[94,29,14,1],
[94,29,14,2],
[94,29,14,3],
[94,29,14,4],
[94,29,14,5],
[94,29,14,6],
[94,29,14,7],
[94,29,14,8],
[94,29,14,9],
[94,29,15,0],
[94,29,15,1],
[94,29,15,2],
[94,29,15,3],
[94,29,15,4],
[94,29,15,5],
[94,29,15,6],
[94,29,15,7],
[94,29,15,8],
[94,29,16,0],
[94,29,16,1],
[94,29,16,2],
[94,29,16,3],
[94,29,16,4],
[94,29,16,5],
[94,29,16,6],
[94,29,16,7],
[94,29,16,8],
[94,29,16,9],
[94,29,17,0],
[94,29,17,1],
[94,29,17,2],
[94,29,17,3],
[94,29,17,4],
[94,29,17,5],
[94,29,17,6],
[94,29,17,7],
[94,29,17,8],
[94,29,17,9],
[94,29,18,0],
[94,29,18,1],
[94,29,18,2],
[94,29,18,3],
[94,29,18,4],
[94,29,18,5],
[94,29,18,6],
[94,29,18,7],
[94,29,19,0],
[94,29,19,1],
[94,29,19,2],
[94,29,19,3],
[94,29,19,4],
[94,29,19,5],
[94,29,21,0],
[94,29,21,1],
[94,29,21,2],
[94,30,3,0],
[94,30,3,1],
[94,30,3,2],
[94,30,4,0],
[94,30,4,1],
[94,30,4,2],
[94,30,4,3],
[94,30,5,0],
[94,30,5,1],
[94,30,5,2],
[94,30,5,4],
[94,30,6,0],
[94,30,6,1],
[94,30,6,2],
[94,30,6,4],
[94,30,7,0],
[94,30,7,1],
[94,30,7,2],
[94,30,7,3],
[94,30,7,4],
[94,30,7,5],
[94,30,7,6],
[94,30,8,0],
[94,30,8,1],
[94,30,8,2],
[94,30,8,4],
[94,30,8,7],
[94,30,9,0],
[94,30,9,1],
[94,30,9,2],
[94,30,9,3],
[94,30,9,4],
[94,30,9,5],
[94,30,9,6],
[94,30,9,7],
[94,30,9,8],
[94,30,11,0],
[94,30,11,1],
[94,30,11,2],
[94,30,11,3],
[94,30,11,4],
[94,30,11,5],
[94,30,11,6],
[94,30,11,7],
[94,30,11,8],
[94,30,11,9],
[94,30,12,0],
[94,30,12,1],
[94,30,12,2],
[94,30,12,3],
[94,30,12,4],
[94,30,12,5],
[94,30,12,6],
[94,30,12,7],
[94,30,12,8],
[94,30,12,9],
[94,30,13,0],
[94,30,13,1],
[94,30,13,2],
[94,30,13,3],
[94,30,13,4],
[94,30,13,5],
[94,30,13,6],
[94,30,13,7],
[94,30,13,8],
[94,30,13,9],
[94,30,13,11],
[94,30,13,12],
[94,30,14,0],
[94,30,14,1],
[94,30,14,2],
[94,30,14,3],
[94,30,14,4],
[94,30,14,5],
[94,30,14,6],
[94,30,14,7],
[94,30,14,8],
[94,30,14,9],
[94,30,14,11],
[94,30,15,0],
[94,30,15,1],
[94,30,15,2],
[94,30,15,3],
[94,30,15,4],
[94,30,15,5],
[94,30,15,6],
[94,30,15,7],
[94,30,15,8],
[94,30,16,0],
[94,30,16,1],
[94,30,16,2],
[94,30,16,3],
[94,30,16,4],
[94,30,16,5],
[94,30,16,6],
[94,30,16,7],
[94,30,17,0],
[94,30,17,1],
[94,30,17,2],
[94,30,17,3],
[94,30,17,4],
[94,30,17,5],
[94,30,17,6],
[94,30,17,7],
[94,30,18,0],
[94,30,18,1],
[94,30,18,2],
[94,30,18,3],
[94,30,18,4],
[94,30,18,5],
[94,30,19,0],
[94,30,19,1],
[94,30,19,2],
[94,30,19,3],
[94,30,19,4],
[94,31,3,0],
[94,31,3,1],
[94,31,3,2],
[94,31,4,0],
[94,31,4,1],
[94,31,4,2],
[94,31,4,3],
[94,31,5,0],
[94,31,5,1],
[94,31,5,2],
[94,31,5,4],
[94,31,6,0],
[94,31,6,1],
[94,31,6,2],
[94,31,6,4],
[94,31,7,0],
[94,31,7,1],
[94,31,7,2],
[94,31,7,3],
[94,31,7,4],
[94,31,7,5],
[94,31,7,6],
[94,31,8,0],
[94,31,8,1],
[94,31,8,2],
[94,31,8,4],
[94,31,8,7],
[94,31,9,0],
[94,31,9,1],
[94,31,9,2],
[94,31,9,3],
[94,31,9,4],
[94,31,9,5],
[94,31,9,6],
[94,31,9,7],
[94,31,9,8],
[94,31,11,0],
[94,31,11,1],
[94,31,11,2],
[94,31,11,3],
[94,31,11,4],
[94,31,11,5],
[94,31,11,6],
[94,31,11,7],
[94,31,11,8],
[94,31,11,9],
[94,31,12,0],
[94,31,12,1],
[94,31,12,2],
[94,31,12,3],
[94,31,12,4],
[94,31,12,5],
[94,31,12,6],
[94,31,12,7],
[94,31,12,8],
[94,31,12,9],
[94,31,13,0],
[94,31,13,1],
[94,31,13,2],
[94,31,13,3],
[94,31,13,4],
[94,31,13,5],
[94,31,13,6],
[94,31,13,7],
[94,31,13,8],
[94,31,13,9],
[94,31,13,11],
[94,31,14,0],
[94,31,14,1],
[94,31,14,2],
[94,31,14,3],
[94,31,14,4],
[94,31,14,5],
[94,31,14,6],
[94,31,14,7],
[94,31,14,8],
[94,31,14,9],
[94,31,15,0],
[94,31,15,1],
[94,31,15,2],
[94,31,15,3],
[94,31,15,4],
[94,31,15,5],
[94,31,15,6],
[94,31,15,7],
[94,31,16,0],
[94,31,16,1],
[94,31,16,2],
[94,31,16,3],
[94,31,16,4],
[94,31,16,5],
[94,31,17,0],
[94,31,17,1],
[94,31,17,2],
[94,31,17,3],
[94,31,17,4],
[94,31,17,5],
[94,31,18,0],
[94,31,18,1],
[94,31,18,2],
[94,31,18,3],
[94,31,18,4],
[94,31,19,0],
[94,31,19,1],
[94,31,19,2],
[94,32,3,0],
[94,32,3,1],
[94,32,3,2],
[94,32,4,0],
[94,32,4,1],
[94,32,4,2],
[94,32,4,3],
[94,32,5,0],
[94,32,5,1],
[94,32,5,2],
[94,32,5,4],
[94,32,6,0],
[94,32,6,1],
[94,32,6,2],
[94,32,6,4],
[94,32,7,0],
[94,32,7,1],
[94,32,7,2],
[94,32,7,3],
[94,32,7,4],
[94,32,7,5],
[94,32,7,6],
[94,32,8,0],
[94,32,8,1],
[94,32,8,2],
[94,32,8,4],
[94,32,8,7],
[94,32,9,0],
[94,32,9,1],
[94,32,9,2],
[94,32,9,3],
[94,32,9,4],
[94,32,9,5],
[94,32,9,6],
[94,32,9,7],
[94,32,9,8],
[94,32,13,0],
[94,32,13,1],
[94,32,13,2],
[94,32,13,3],
[94,32,13,4],
[94,32,13,5],
[94,32,13,6],
[94,32,13,7],
[94,32,13,8],
[94,32,13,9],
[94,32,14,0],
[94,32,14,1],
[94,32,14,2],
[94,32,14,3],
[94,32,14,4],
[94,32,14,5],
[94,32,14,6],
[94,32,14,7],
[94,32,15,0],
[94,32,15,1],
[94,32,15,2],
[94,32,15,3],
[94,32,15,4],
[94,32,15,5],
[94,32,16,0],
[94,32,16,1],
[94,32,16,2],
[94,32,16,3],
[94,32,16,4],
[94,32,17,0],
[94,32,17,1],
[94,32,17,2],
[94,32,17,3],
[94,32,17,4],
[94,32,18,0],
[94,32,18,1],
[94,32,18,2],
[94,33,3,0],
[94,33,3,1],
[94,33,3,2],
[94,33,4,0],
[94,33,4,1],
[94,33,4,2],
[94,33,4,3],
[94,33,5,0],
[94,33,5,1],
[94,33,5,2],
[94,33,5,4],
[94,33,6,0],
[94,33,6,1],
[94,33,6,2],
[94,33,6,4],
[94,33,7,0],
[94,33,7,1],
[94,33,7,2],
[94,33,7,3],
[94,33,7,4],
[94,33,7,5],
[94,33,7,6],
[94,33,8,0],
[94,33,8,1],
[94,33,8,2],
[94,33,8,4],
[94,33,8,7],
[94,33,9,0],
[94,33,9,1],
[94,33,9,2],
[94,33,9,3],
[94,33,9,4],
[94,33,9,5],
[94,33,9,6],
[94,33,9,7],
[94,33,9,8],
[94,33,13,0],
[94,33,13,1],
[94,33,13,2],
[94,33,13,3],
[94,33,13,4],
[94,33,13,5],
[94,33,13,6],
[94,33,13,7],
[94,33,14,0],
[94,33,14,1],
[94,33,14,2],
[94,33,14,3],
[94,33,14,4],
[94,33,14,5],
[94,33,15,0],
[94,33,15,1],
[94,33,15,2],
[94,33,15,3],
[94,33,15,4],
[94,33,16,0],
[94,33,16,1],
[94,33,16,2],
[94,33,17,0],
[94,33,17,1],
[94,33,17,2],
[94,34,3,0],
[94,34,3,1],
[94,34,3,2],
[94,34,4,0],
[94,34,4,1],
[94,34,4,2],
[94,34,4,3],
[94,34,5,0],
[94,34,5,1],
[94,34,5,2],
[94,34,5,4],
[94,34,6,0],
[94,34,6,1],
[94,34,6,2],
[94,34,6,4],
[94,34,8,0],
[94,34,8,1],
[94,34,8,2],
[94,34,8,4],
[94,34,9,0],
[94,34,9,1],
[94,34,9,2],
[94,34,9,3],
[94,34,9,4],
[94,34,9,5],
[94,34,9,6],
[94,34,9,8],
[94,34,11,0],
[94,34,11,1],
[94,34,11,2],
[94,34,11,3],
[94,34,11,4],
[94,34,11,5],
[94,34,11,6],
[94,34,12,0],
[94,34,12,1],
[94,34,12,2],
[94,34,12,3],
[94,34,12,4],
[94,34,12,5],
[94,34,13,0],
[94,34,13,1],
[94,34,13,2],
[94,34,13,3],
[94,34,13,4],
[94,34,13,5],
[94,34,14,0],
[94,34,14,1],
[94,34,14,2],
[94,34,14,3],
[94,34,14,4],
[94,34,15,0],
[94,34,15,1],
[94,34,15,2],
[94,35,3,0],
[94,35,3,1],
[94,35,3,2],
[94,35,4,0],
[94,35,4,1],
[94,35,4,2],
[94,35,4,3],
[94,35,5,0],
[94,35,5,1],
[94,35,5,2],
[94,35,5,4],
[94,35,6,0],
[94,35,6,1],
[94,35,6,2],
[94,35,6,4],
[94,35,7,0],
[94,35,7,1],
[94,35,7,2],
[94,35,7,3],
[94,35,7,4],
[94,35,7,5],
[94,35,7,6],
[94,35,8,0],
[94,35,8,1],
[94,35,8,2],
[94,35,8,4],
[94,35,8,7],
[94,35,11,0],
[94,35,11,1],
[94,35,11,2],
[94,35,11,3],
[94,35,11,4],
[94,35,11,5],
[94,35,12,0],
[94,35,12,1],
[94,35,12,2],
[94,35,12,3],
[94,35,12,4],
[94,35,13,0],
[94,35,13,1],
[94,35,13,2],
[94,35,13,3],
[94,35,13,4],
[94,35,14,0],
[94,35,14,1],
[94,35,14,2],
[94,36,3,0],
[94,36,3,1],
[94,36,3,2],
[94,36,4,0],
[94,36,4,1],
[94,36,4,2],
[94,36,4,3],
[94,36,5,0],
[94,36,5,1],
[94,36,5,2],
[94,36,5,4],
[94,36,6,0],
[94,36,6,1],
[94,36,6,2],
[94,36,6,4],
[94,36,7,0],
[94,36,7,1],
[94,36,7,2],
[94,36,7,3],
[94,36,7,4],
[94,36,7,5],
[94,36,7,6],
[94,36,8,0],
[94,36,8,1],
[94,36,8,2],
[94,36,8,4],
[94,36,9,0],
[94,36,9,1],
[94,36,9,2],
[94,36,9,3],
[94,36,9,4],
[94,36,9,5],
[94,36,11,0],
[94,36,11,1],
[94,36,11,2],
[94,36,11,3],
[94,36,11,4],
[94,36,12,0],
[94,36,12,1],
[94,36,12,2],
[94,37,3,0],
[94,37,3,1],
[94,37,3,2],
[94,37,5,0],
[94,37,5,1],
[94,37,5,2],
[94,37,6,0],
[94,37,6,1],
[94,37,6,2],
[94,37,7,0],
[94,37,7,1],
[94,37,7,2],
[94,37,7,3],
[94,37,7,5],
[94,37,8,0],
[94,37,8,1],
[94,37,8,2],
[94,37,9,0],
[94,37,9,1],
[94,37,9,2],
[94,37,9,3],
[94,37,11,0],
[94,37,11,1],
[94,37,11,2],
[94,38,3,0],
[94,38,3,1],
[94,38,3,2],
[94,38,4,0],
[94,38,4,1],
[94,38,4,2],
[94,38,4,3],
[94,38,5,0],
[94,38,5,1],
[94,38,5,2],
[94,38,5,4],
[94,38,6,0],
[94,38,6,1],
[94,38,6,2],
[94,38,6,4],
[94,38,8,0],
[94,38,8,1],
[94,38,8,2],
[94,38,9,0],
[94,38,9,1],
[94,38,9,2],
[94,39,4,0],
[94,39,4,1],
[94,39,4,2],
[94,39,7,0],
[94,39,7,1],
[94,39,7,2],
[94,40,3,0],
[94,40,3,1],
[94,40,3,2],
[94,40,4,0],
[94,40,4,1],
[94,40,4,2],
[94,40,4,3],
[94,40,5,0],
[94,40,5,1],
[94,40,5,2],
[94,41,3,0],
[94,41,3,1],
[94,41,3,2],
[94,41,4,0],
[94,41,4,1],
[94,41,4,2],
[94,41,4,3],
[94,41,5,0],
[94,41,5,1],
[94,41,5,2],
[95,4,3,0],
[95,4,3,1],
[95,4,3,2],
[95,5,4,0],
[95,5,4,1],
[95,5,4,2],
[95,6,4,0],
[95,6,4,1],
[95,6,4,2],
[95,7,3,0],
[95,7,3,1],
[95,7,3,2],
[95,7,4,0],
[95,7,4,1],
[95,7,4,2],
[95,7,4,3],
[95,7,5,0],
[95,7,5,1],
[95,7,5,2],
[95,7,5,4],
[95,7,6,0],
[95,7,6,1],
[95,7,6,2],
[95,7,6,4],
[95,8,4,0],
[95,8,4,1],
[95,8,4,2],
[95,8,7,0],
[95,8,7,1],
[95,8,7,2],
[95,8,7,4],
[95,9,3,0],
[95,9,3,1],
[95,9,3,2],
[95,9,4,0],
[95,9,4,1],
[95,9,4,2],
[95,9,4,3],
[95,9,5,0],
[95,9,5,1],
[95,9,5,2],
[95,9,5,4],
[95,9,6,0],
[95,9,6,1],
[95,9,6,2],
[95,9,6,4],
[95,9,7,0],
[95,9,7,1],
[95,9,7,2],
[95,9,7,3],
[95,9,7,4],
[95,9,7,5],
[95,9,7,6],
[95,9,8,0],
[95,9,8,1],
[95,9,8,2],
[95,9,8,4],
[95,9,8,7],
[95,10,3,0],
[95,10,3,1],
[95,10,3,2],
[95,10,4,0],
[95,10,4,1],
[95,10,4,2],
[95,10,4,3],
[95,10,5,0],
[95,10,5,1],
[95,10,5,2],
[95,10,5,4],
[95,10,6,0],
[95,10,6,1],
[95,10,6,2],
[95,10,6,4],
[95,10,7,0],
[95,10,7,1],
[95,10,7,2],
[95,10,7,3],
[95,10,7,4],
[95,10,7,5],
[95,10,7,6],
[95,10,8,0],
[95,10,8,1],
[95,10,8,2],
[95,10,8,4],
[95,10,8,7],
[95,10,9,0],
[95,10,9,1],
[95,10,9,2],
[95,10,9,3],
[95,10,9,4],
[95,10,9,5],
[95,10,9,6],
[95,10,9,7],
[95,10,9,8],
[95,13,3,0],
[95,13,3,1],
[95,13,3,2],
[95,13,4,0],
[95,13,4,1],
[95,13,4,2],
[95,13,4,3],
[95,13,5,0],
[95,13,5,1],
[95,13,5,2],
[95,13,5,4],
[95,13,6,0],
[95,13,6,1],
[95,13,6,2],
[95,13,6,4],
[95,13,7,0],
[95,13,7,1],
[95,13,7,2],
[95,13,7,3],
[95,13,7,4],
[95,13,7,5],
[95,13,7,6],
[95,13,8,0],
[95,13,8,1],
[95,13,8,2],
[95,13,8,4],
[95,13,8,7],
[95,13,9,0],
[95,13,9,1],
[95,13,9,2],
[95,13,9,3],
[95,13,9,4],
[95,13,9,5],
[95,13,9,6],
[95,13,9,7],
[95,13,9,8],
[95,13,10,0],
[95,13,10,1],
[95,13,10,2],
[95,13,10,3],
[95,13,10,4],
[95,13,10,5],
[95,13,10,6],
[95,13,10,7],
[95,13,10,8],
[95,13,10,9],
[95,14,3,0],
[95,14,3,1],
[95,14,3,2],
[95,14,4,0],
[95,14,4,1],
[95,14,4,2],
[95,14,4,3],
[95,14,5,0],
[95,14,5,1],
[95,14,5,2],
[95,14,5,4],
[95,14,6,0],
[95,14,6,1],
[95,14,6,2],
[95,14,6,4],
[95,14,7,0],
[95,14,7,1],
[95,14,7,2],
[95,14,7,3],
[95,14,7,4],
[95,14,7,5],
[95,14,7,6],
[95,14,8,0],
[95,14,8,1],
[95,14,8,2],
[95,14,8,4],
[95,14,8,7],
[95,14,9,0],
[95,14,9,1],
[95,14,9,2],
[95,14,9,3],
[95,14,9,4],
[95,14,9,5],
[95,14,9,6],
[95,14,9,7],
[95,14,9,8],
[95,14,10,0],
[95,14,10,1],
[95,14,10,2],
[95,14,10,3],
[95,14,10,4],
[95,14,10,5],
[95,14,10,6],
[95,14,10,7],
[95,14,10,8],
[95,14,10,9],
[95,15,3,0],
[95,15,3,1],
[95,15,3,2],
[95,15,4,0],
[95,15,4,1],
[95,15,4,2],
[95,15,4,3],
[95,15,5,0],
[95,15,5,1],
[95,15,5,2],
[95,15,5,4],
[95,15,6,0],
[95,15,6,1],
[95,15,6,2],
[95,15,6,4],
[95,15,7,0],
[95,15,7,1],
[95,15,7,2],
[95,15,7,3],
[95,15,7,4],
[95,15,7,5],
[95,15,7,6],
[95,15,8,0],
[95,15,8,1],
[95,15,8,2],
[95,15,8,4],
[95,15,8,7],
[95,15,10,0],
[95,15,10,1],
[95,15,10,2],
[95,15,10,3],
[95,15,10,4],
[95,15,10,5],
[95,15,10,6],
[95,15,10,7],
[95,15,10,8],
[95,15,13,0],
[95,15,13,1],
[95,15,13,2],
[95,15,13,3],
[95,15,13,4],
[95,15,13,5],
[95,15,13,6],
[95,15,13,7],
[95,15,13,8],
[95,15,13,10],
[95,15,14,0],
[95,15,14,1],
[95,15,14,2],
[95,15,14,3],
[95,15,14,4],
[95,15,14,5],
[95,15,14,6],
[95,15,14,7],
[95,15,14,8],
[95,15,14,10],
[95,16,3,0],
[95,16,3,1],
[95,16,3,2],
[95,16,4,0],
[95,16,4,1],
[95,16,4,2],
[95,16,4,3],
[95,16,5,0],
[95,16,5,1],
[95,16,5,2],
[95,16,5,4],
[95,16,6,0],
[95,16,6,1],
[95,16,6,2],
[95,16,6,4],
[95,16,7,0],
[95,16,7,1],
[95,16,7,2],
[95,16,7,3],
[95,16,7,4],
[95,16,7,5],
[95,16,7,6],
[95,16,8,0],
[95,16,8,1],
[95,16,8,2],
[95,16,8,4],
[95,16,8,7],
[95,16,9,0],
[95,16,9,1],
[95,16,9,2],
[95,16,9,3],
[95,16,9,4],
[95,16,9,5],
[95,16,9,6],
[95,16,9,7],
[95,16,9,8],
[95,16,10,0],
[95,16,10,1],
[95,16,10,2],
[95,16,10,3],
[95,16,10,4],
[95,16,10,5],
[95,16,10,6],
[95,16,10,7],
[95,16,10,8],
[95,16,10,9],
[95,16,13,0],
[95,16,13,1],
[95,16,13,2],
[95,16,13,3],
[95,16,13,4],
[95,16,13,5],
[95,16,13,6],
[95,16,13,7],
[95,16,13,8],
[95,16,13,9],
[95,16,13,10],
[95,16,14,0],
[95,16,14,1],
[95,16,14,2],
[95,16,14,3],
[95,16,14,4],
[95,16,14,5],
[95,16,14,6],
[95,16,14,7],
[95,16,14,8],
[95,16,14,9],
[95,16,14,10],
[95,16,15,0],
[95,16,15,1],
[95,16,15,2],
[95,16,15,3],
[95,16,15,4],
[95,16,15,5],
[95,16,15,6],
[95,16,15,7],
[95,16,15,8],
[95,16,15,13],
[95,16,15,14],
[95,17,3,0],
[95,17,3,1],
[95,17,3,2],
[95,17,4,0],
[95,17,4,1],
[95,17,4,2],
[95,17,4,3],
[95,17,5,0],
[95,17,5,1],
[95,17,5,2],
[95,17,5,4],
[95,17,6,0],
[95,17,6,1],
[95,17,6,2],
[95,17,6,4],
[95,17,7,0],
[95,17,7,1],
[95,17,7,2],
[95,17,7,3],
[95,17,7,4],
[95,17,7,5],
[95,17,7,6],
[95,17,8,0],
[95,17,8,1],
[95,17,8,2],
[95,17,8,4],
[95,17,8,7],
[95,17,9,0],
[95,17,9,1],
[95,17,9,2],
[95,17,9,3],
[95,17,9,4],
[95,17,9,5],
[95,17,9,6],
[95,17,9,7],
[95,17,9,8],
[95,17,10,0],
[95,17,10,1],
[95,17,10,2],
[95,17,10,3],
[95,17,10,4],
[95,17,10,5],
[95,17,10,6],
[95,17,10,7],
[95,17,10,8],
[95,17,10,9],
[95,17,13,0],
[95,17,13,1],
[95,17,13,2],
[95,17,13,3],
[95,17,13,4],
[95,17,13,5],
[95,17,13,6],
[95,17,13,7],
[95,17,13,8],
[95,17,13,9],
[95,17,13,10],
[95,17,14,0],
[95,17,14,1],
[95,17,14,2],
[95,17,14,3],
[95,17,14,4],
[95,17,14,5],
[95,17,14,6],
[95,17,14,7],
[95,17,14,8],
[95,17,14,9],
[95,17,14,10],
[95,17,15,0],
[95,17,15,1],
[95,17,15,2],
[95,17,15,3],
[95,17,15,4],
[95,17,15,5],
[95,17,15,6],
[95,17,15,7],
[95,17,15,8],
[95,17,15,13],
[95,17,15,14],
[95,17,16,0],
[95,17,16,1],
[95,17,16,2],
[95,17,16,3],
[95,17,16,4],
[95,17,16,5],
[95,17,16,6],
[95,17,16,7],
[95,17,16,10],
[95,17,16,13],
[95,17,16,14],
[95,17,16,15],
[95,18,3,0],
[95,18,3,1],
[95,18,3,2],
[95,18,4,0],
[95,18,4,1],
[95,18,4,2],
[95,18,4,3],
[95,18,5,0],
[95,18,5,1],
[95,18,5,2],
[95,18,5,4],
[95,18,6,0],
[95,18,6,1],
[95,18,6,2],
[95,18,6,4],
[95,18,7,0],
[95,18,7,1],
[95,18,7,2],
[95,18,7,3],
[95,18,7,4],
[95,18,7,5],
[95,18,7,6],
[95,18,8,0],
[95,18,8,1],
[95,18,8,2],
[95,18,8,4],
[95,18,8,7],
[95,18,10,0],
[95,18,10,1],
[95,18,10,2],
[95,18,10,3],
[95,18,10,4],
[95,18,10,5],
[95,18,10,6],
[95,18,10,7],
[95,18,10,8],
[95,18,13,0],
[95,18,13,1],
[95,18,13,2],
[95,18,13,3],
[95,18,13,4],
[95,18,13,5],
[95,18,13,6],
[95,18,13,7],
[95,18,13,8],
[95,18,13,10],
[95,18,14,0],
[95,18,14,1],
[95,18,14,2],
[95,18,14,3],
[95,18,14,4],
[95,18,14,5],
[95,18,14,6],
[95,18,14,7],
[95,18,14,8],
[95,18,16,0],
[95,18,16,1],
[95,18,16,2],
[95,18,16,3],
[95,18,16,4],
[95,18,16,5],
[95,18,16,8],
[95,18,16,10],
[95,18,16,13],
[95,18,16,14],
[95,18,17,0],
[95,18,17,1],
[95,18,17,2],
[95,18,17,3],
[95,18,17,4],
[95,18,17,5],
[95,18,17,8],
[95,18,17,10],
[95,18,17,13],
[95,18,17,14],
[95,18,17,16],
[95,19,3,0],
[95,19,3,1],
[95,19,3,2],
[95,19,4,0],
[95,19,4,1],
[95,19,4,2],
[95,19,4,3],
[95,19,5,0],
[95,19,5,1],
[95,19,5,2],
[95,19,5,4],
[95,19,6,0],
[95,19,6,1],
[95,19,6,2],
[95,19,6,4],
[95,19,7,0],
[95,19,7,1],
[95,19,7,2],
[95,19,7,3],
[95,19,7,4],
[95,19,7,5],
[95,19,7,6],
[95,19,8,0],
[95,19,8,1],
[95,19,8,2],
[95,19,8,4],
[95,19,8,7],
[95,19,9,0],
[95,19,9,1],
[95,19,9,2],
[95,19,9,3],
[95,19,9,4],
[95,19,9,5],
[95,19,9,6],
[95,19,9,7],
[95,19,9,8],
[95,19,10,0],
[95,19,10,1],
[95,19,10,2],
[95,19,10,3],
[95,19,10,4],
[95,19,10,5],
[95,19,10,6],
[95,19,10,7],
[95,19,10,8],
[95,19,10,9],
[95,19,15,0],
[95,19,15,1],
[95,19,15,2],
[95,19,15,3],
[95,19,15,4],
[95,19,15,5],
[95,19,15,8],
[95,19,15,10],
[95,19,16,0],
[95,19,16,1],
[95,19,16,2],
[95,19,16,3],
[95,19,16,4],
[95,19,16,6],
[95,19,16,7],
[95,19,16,8],
[95,19,16,9],
[95,19,16,10],
[95,19,16,15],
[95,19,17,0],
[95,19,17,1],
[95,19,17,2],
[95,19,17,3],
[95,19,17,4],
[95,19,17,6],
[95,19,17,7],
[95,19,17,8],
[95,19,17,9],
[95,19,17,10],
[95,19,17,15],
[95,19,17,16],
[95,19,18,0],
[95,19,18,1],
[95,19,18,2],
[95,19,18,5],
[95,19,18,6],
[95,19,18,7],
[95,19,18,8],
[95,19,18,10],
[95,19,18,16],
[95,19,18,17],
[95,20,3,0],
[95,20,3,1],
[95,20,3,2],
[95,20,4,0],
[95,20,4,1],
[95,20,4,2],
[95,20,4,3],
[95,20,5,0],
[95,20,5,1],
[95,20,5,2],
[95,20,5,4],
[95,20,6,0],
[95,20,6,1],
[95,20,6,2],
[95,20,6,4],
[95,20,7,0],
[95,20,7,1],
[95,20,7,2],
[95,20,7,3],
[95,20,7,4],
[95,20,7,5],
[95,20,7,6],
[95,20,8,0],
[95,20,8,1],
[95,20,8,2],
[95,20,8,4],
[95,20,8,7],
[95,20,9,0],
[95,20,9,1],
[95,20,9,2],
[95,20,9,3],
[95,20,9,4],
[95,20,9,5],
[95,20,9,6],
[95,20,9,7],
[95,20,9,8],
[95,20,13,0],
[95,20,13,1],
[95,20,13,2],
[95,20,13,3],
[95,20,13,4],
[95,20,13,5],
[95,20,13,6],
[95,20,13,7],
[95,20,14,0],
[95,20,14,1],
[95,20,14,2],
[95,20,14,3],
[95,20,14,4],
[95,20,14,5],
[95,20,14,8],
[95,20,14,9],
[95,20,15,0],
[95,20,15,1],
[95,20,15,2],
[95,20,15,3],
[95,20,15,4],
[95,20,15,6],
[95,20,15,7],
[95,20,15,8],
[95,20,15,13],
[95,20,15,14],
[95,20,16,0],
[95,20,16,1],
[95,20,16,2],
[95,20,16,5],
[95,20,16,6],
[95,20,16,7],
[95,20,16,8],
[95,20,16,9],
[95,20,16,13],
[95,20,16,14],
[95,20,16,15],
[95,20,17,0],
[95,20,17,1],
[95,20,17,2],
[95,20,17,5],
[95,20,17,6],
[95,20,17,7],
[95,20,17,8],
[95,20,17,9],
[95,20,17,13],
[95,20,17,14],
[95,20,17,15],
[95,20,17,16],
[95,20,18,3],
[95,20,18,4],
[95,20,18,5],
[95,20,18,6],
[95,20,18,7],
[95,20,18,8],
[95,20,18,13],
[95,20,18,14],
[95,20,18,16],
[95,20,18,17],
[95,20,19,0],
[95,20,19,1],
[95,20,19,2],
[95,20,19,3],
[95,20,19,4],
[95,20,19,5],
[95,20,19,6],
[95,20,19,7],
[95,20,19,8],
[95,20,19,9],
[95,20,19,15],
[95,20,19,16],
[95,20,19,17],
[95,20,19,18],
[95,21,4,0],
[95,21,4,1],
[95,21,4,2],
[95,21,7,0],
[95,21,7,1],
[95,21,7,2],
[95,21,7,4],
[95,21,9,0],
[95,21,9,1],
[95,21,9,2],
[95,21,9,4],
[95,21,9,7],
[95,21,10,0],
[95,21,10,1],
[95,21,10,2],
[95,21,10,4],
[95,21,10,7],
[95,21,13,0],
[95,21,13,1],
[95,21,13,2],
[95,21,13,4],
[95,21,13,9],
[95,21,13,10],
[95,21,14,0],
[95,21,14,1],
[95,21,14,2],
[95,21,14,4],
[95,21,14,7],
[95,21,14,9],
[95,21,14,10],
[95,21,15,0],
[95,21,15,1],
[95,21,15,2],
[95,21,15,7],
[95,21,15,10],
[95,21,15,13],
[95,21,15,14],
[95,21,16,4],
[95,21,16,7],
[95,21,16,9],
[95,21,16,10],
[95,21,16,13],
[95,21,16,14],
[95,21,16,15],
[95,21,17,4],
[95,21,17,7],
[95,21,17,9],
[95,21,17,10],
[95,21,17,13],
[95,21,17,14],
[95,21,17,15],
[95,21,17,16],
[95,21,18,0],
[95,21,18,1],
[95,21,18,2],
[95,21,18,4],
[95,21,18,7],
[95,21,18,10],
[95,21,18,13],
[95,21,18,14],
[95,21,18,16],
[95,21,18,17],
[95,21,19,0],
[95,21,19,1],
[95,21,19,2],
[95,21,19,4],
[95,21,19,7],
[95,21,19,9],
[95,21,19,10],
[95,21,19,15],
[95,21,19,16],
[95,21,19,17],
[95,21,19,18],
[95,21,20,0],
[95,21,20,1],
[95,21,20,2],
[95,21,20,4],
[95,21,20,7],
[95,21,20,9],
[95,21,20,13],
[95,21,20,14],
[95,21,20,15],
[95,21,20,16],
[95,21,20,17],
[95,22,3,0],
[95,22,3,1],
[95,22,3,2],
[95,22,4,0],
[95,22,4,1],
[95,22,4,2],
[95,22,4,3],
[95,22,5,0],
[95,22,5,1],
[95,22,5,2],
[95,22,5,4],
[95,22,6,0],
[95,22,6,1],
[95,22,6,2],
[95,22,6,4],
[95,22,7,0],
[95,22,7,1],
[95,22,7,2],
[95,22,7,3],
[95,22,7,4],
[95,22,7,5],
[95,22,7,6],
[95,22,8,0],
[95,22,8,1],
[95,22,8,2],
[95,22,8,4],
[95,22,8,7],
[95,22,9,0],
[95,22,9,1],
[95,22,9,2],
[95,22,9,3],
[95,22,9,4],
[95,22,9,5],
[95,22,9,6],
[95,22,9,7],
[95,22,13,0],
[95,22,13,1],
[95,22,13,2],
[95,22,13,3],
[95,22,13,4],
[95,22,13,6],
[95,22,13,7],
[95,22,13,8],
[95,22,13,9],
[95,22,14,0],
[95,22,14,1],
[95,22,14,2],
[95,22,14,5],
[95,22,14,6],
[95,22,14,7],
[95,22,14,8],
[95,22,14,9],
[95,22,15,3],
[95,22,15,4],
[95,22,15,5],
[95,22,15,6],
[95,22,15,7],
[95,22,15,8],
[95,22,15,13],
[95,22,15,14],
[95,22,16,0],
[95,22,16,1],
[95,22,16,2],
[95,22,16,3],
[95,22,16,4],
[95,22,16,5],
[95,22,16,6],
[95,22,16,7],
[95,22,16,8],
[95,22,16,9],
[95,22,16,13],
[95,22,16,14],
[95,22,16,15],
[95,22,17,0],
[95,22,17,1],
[95,22,17,2],
[95,22,17,3],
[95,22,17,4],
[95,22,17,5],
[95,22,17,6],
[95,22,17,7],
[95,22,17,8],
[95,22,17,9],
[95,22,17,13],
[95,22,17,14],
[95,22,17,15],
[95,22,17,16],
[95,22,18,0],
[95,22,18,1],
[95,22,18,2],
[95,22,18,3],
[95,22,18,4],
[95,22,18,5],
[95,22,18,6],
[95,22,18,7],
[95,22,18,8],
[95,22,18,13],
[95,22,18,14],
[95,22,18,16],
[95,22,18,17],
[95,22,19,0],
[95,22,19,1],
[95,22,19,2],
[95,22,19,3],
[95,22,19,4],
[95,22,19,5],
[95,22,19,6],
[95,22,19,7],
[95,22,19,8],
[95,22,19,9],
[95,22,19,15],
[95,22,19,16],
[95,22,19,17],
[95,22,21,0],
[95,22,21,1],
[95,22,21,2],
[95,22,21,4],
[95,22,21,7],
[95,22,21,9],
[95,22,21,13],
[95,22,21,14],
[95,23,3,0],
[95,23,3,1],
[95,23,3,2],
[95,23,4,0],
[95,23,4,1],
[95,23,4,2],
[95,23,4,3],
[95,23,5,0],
[95,23,5,1],
[95,23,5,2],
[95,23,5,4],
[95,23,6,0],
[95,23,6,1],
[95,23,6,2],
[95,23,6,4],
[95,23,7,0],
[95,23,7,1],
[95,23,7,2],
[95,23,7,3],
[95,23,7,4],
[95,23,7,5],
[95,23,7,6],
[95,23,8,0],
[95,23,8,1],
[95,23,8,2],
[95,23,8,4],
[95,23,9,0],
[95,23,9,1],
[95,23,9,2],
[95,23,9,3],
[95,23,9,4],
[95,23,9,5],
[95,23,9,8],
[95,23,10,0],
[95,23,10,1],
[95,23,10,2],
[95,23,10,3],
[95,23,10,4],
[95,23,10,6],
[95,23,10,7],
[95,23,10,8],
[95,23,10,9],
[95,23,13,0],
[95,23,13,1],
[95,23,13,2],
[95,23,13,5],
[95,23,13,6],
[95,23,13,7],
[95,23,13,8],
[95,23,13,9],
[95,23,13,10],
[95,23,14,3],
[95,23,14,4],
[95,23,14,5],
[95,23,14,6],
[95,23,14,7],
[95,23,14,8],
[95,23,14,9],
[95,23,14,10],
[95,23,15,0],
[95,23,15,1],
[95,23,15,2],
[95,23,15,3],
[95,23,15,4],
[95,23,15,5],
[95,23,15,6],
[95,23,15,7],
[95,23,15,8],
[95,23,15,10],
[95,23,15,13],
[95,23,15,14],
[95,23,16,0],
[95,23,16,1],
[95,23,16,2],
[95,23,16,3],
[95,23,16,4],
[95,23,16,5],
[95,23,16,6],
[95,23,16,7],
[95,23,16,8],
[95,23,16,9],
[95,23,16,10],
[95,23,16,13],
[95,23,16,14],
[95,23,16,15],
[95,23,17,0],
[95,23,17,1],
[95,23,17,2],
[95,23,17,3],
[95,23,17,4],
[95,23,17,5],
[95,23,17,6],
[95,23,17,7],
[95,23,17,8],
[95,23,17,9],
[95,23,17,10],
[95,23,17,13],
[95,23,17,14],
[95,23,17,15],
[95,23,17,16],
[95,23,18,0],
[95,23,18,1],
[95,23,18,2],
[95,23,18,3],
[95,23,18,4],
[95,23,18,5],
[95,23,18,6],
[95,23,18,7],
[95,23,18,8],
[95,23,18,10],
[95,23,18,13],
[95,23,18,14],
[95,23,18,16],
[95,23,18,17],
[95,23,19,0],
[95,23,19,1],
[95,23,19,2],
[95,23,19,3],
[95,23,19,4],
[95,23,19,5],
[95,23,19,6],
[95,23,19,7],
[95,23,19,8],
[95,23,19,9],
[95,23,19,10],
[95,23,19,15],
[95,23,20,0],
[95,23,20,1],
[95,23,20,2],
[95,23,20,3],
[95,23,20,4],
[95,23,20,5],
[95,23,20,6],
[95,23,20,7],
[95,23,20,8],
[95,23,20,9],
[95,23,20,13],
[95,23,20,14],
[95,23,21,0],
[95,23,21,1],
[95,23,21,2],
[95,23,21,4],
[95,23,21,7],
[95,23,21,9],
[95,23,21,10],
[95,23,21,13],
[95,23,22,0],
[95,23,22,1],
[95,23,22,2],
[95,23,22,3],
[95,23,22,4],
[95,23,22,5],
[95,23,22,6],
[95,23,22,7],
[95,23,22,8],
[95,23,22,9],
[95,24,3,0],
[95,24,3,1],
[95,24,3,2],
[95,24,4,0],
[95,24,4,1],
[95,24,4,2],
[95,24,4,3],
[95,24,5,0],
[95,24,5,1],
[95,24,5,2],
[95,24,5,4],
[95,24,6,0],
[95,24,6,1],
[95,24,6,2],
[95,24,6,4],
[95,24,7,0],
[95,24,7,1],
[95,24,7,2],
[95,24,7,3],
[95,24,7,4],
[95,24,7,5],
[95,24,7,6],
[95,24,8,0],
[95,24,8,1],
[95,24,8,2],
[95,24,8,4],
[95,24,9,0],
[95,24,9,1],
[95,24,9,2],
[95,24,9,3],
[95,24,9,4],
[95,24,9,5],
[95,24,9,8],
[95,24,10,0],
[95,24,10,1],
[95,24,10,2],
[95,24,10,3],
[95,24,10,4],
[95,24,10,6],
[95,24,10,7],
[95,24,10,8],
[95,24,10,9],
[95,24,13,0],
[95,24,13,1],
[95,24,13,2],
[95,24,13,5],
[95,24,13,6],
[95,24,13,7],
[95,24,13,8],
[95,24,13,9],
[95,24,13,10],
[95,24,14,3],
[95,24,14,4],
[95,24,14,5],
[95,24,14,6],
[95,24,14,7],
[95,24,14,8],
[95,24,14,9],
[95,24,14,10],
[95,24,15,0],
[95,24,15,1],
[95,24,15,2],
[95,24,15,3],
[95,24,15,4],
[95,24,15,5],
[95,24,15,6],
[95,24,15,7],
[95,24,15,8],
[95,24,15,10],
[95,24,15,13],
[95,24,15,14],
[95,24,17,0],
[95,24,17,1],
[95,24,17,2],
[95,24,17,3],
[95,24,17,4],
[95,24,17,5],
[95,24,17,6],
[95,24,17,7],
[95,24,17,8],
[95,24,17,9],
[95,24,17,10],
[95,24,17,13],
[95,24,17,14],
[95,24,17,15],
[95,24,18,0],
[95,24,18,1],
[95,24,18,2],
[95,24,18,3],
[95,24,18,4],
[95,24,18,5],
[95,24,18,6],
[95,24,18,7],
[95,24,18,8],
[95,24,18,10],
[95,24,18,13],
[95,24,18,14],
[95,24,18,17],
[95,24,19,0],
[95,24,19,1],
[95,24,19,2],
[95,24,19,3],
[95,24,19,4],
[95,24,19,5],
[95,24,19,6],
[95,24,19,7],
[95,24,19,8],
[95,24,19,9],
[95,24,19,10],
[95,24,19,15],
[95,24,20,0],
[95,24,20,1],
[95,24,20,2],
[95,24,20,3],
[95,24,20,4],
[95,24,20,5],
[95,24,20,6],
[95,24,20,7],
[95,24,20,8],
[95,24,20,9],
[95,24,20,13],
[95,24,20,14],
[95,24,21,0],
[95,24,21,1],
[95,24,21,2],
[95,24,21,4],
[95,24,21,7],
[95,24,21,9],
[95,24,21,10],
[95,24,21,13],
[95,24,22,0],
[95,24,22,1],
[95,24,22,2],
[95,24,22,3],
[95,24,22,4],
[95,24,22,5],
[95,24,22,6],
[95,24,22,7],
[95,24,22,8],
[95,24,22,9],
[95,24,23,0],
[95,24,23,1],
[95,24,23,2],
[95,24,23,3],
[95,24,23,4],
[95,24,23,5],
[95,24,23,6],
[95,24,23,7],
[95,24,23,8],
[95,24,23,9],
[95,25,3,0],
[95,25,3,1],
[95,25,3,2],
[95,25,4,0],
[95,25,4,1],
[95,25,4,2],
[95,25,4,3],
[95,25,5,0],
[95,25,5,1],
[95,25,5,2],
[95,25,5,4],
[95,25,6,0],
[95,25,6,1],
[95,25,6,2],
[95,25,6,4],
[95,25,8,0],
[95,25,8,1],
[95,25,8,2],
[95,25,9,0],
[95,25,9,1],
[95,25,9,2],
[95,25,9,5],
[95,25,9,6],
[95,25,9,8],
[95,25,10,3],
[95,25,10,4],
[95,25,10,5],
[95,25,10,6],
[95,25,10,8],
[95,25,10,9],
[95,25,13,0],
[95,25,13,1],
[95,25,13,2],
[95,25,13,3],
[95,25,13,4],
[95,25,13,5],
[95,25,13,6],
[95,25,13,8],
[95,25,13,9],
[95,25,13,10],
[95,25,14,0],
[95,25,14,1],
[95,25,14,2],
[95,25,14,3],
[95,25,14,4],
[95,25,14,5],
[95,25,14,6],
[95,25,14,8],
[95,25,14,9],
[95,25,14,10],
[95,25,15,0],
[95,25,15,1],
[95,25,15,2],
[95,25,15,3],
[95,25,15,4],
[95,25,15,5],
[95,25,15,6],
[95,25,15,8],
[95,25,15,10],
[95,25,15,13],
[95,25,15,14],
[95,25,16,0],
[95,25,16,1],
[95,25,16,2],
[95,25,16,3],
[95,25,16,4],
[95,25,16,5],
[95,25,16,6],
[95,25,16,8],
[95,25,16,9],
[95,25,16,10],
[95,25,16,13],
[95,25,16,14],
[95,25,16,15],
[95,25,17,0],
[95,25,17,1],
[95,25,17,2],
[95,25,17,3],
[95,25,17,4],
[95,25,17,5],
[95,25,17,6],
[95,25,17,8],
[95,25,17,9],
[95,25,17,10],
[95,25,17,13],
[95,25,17,14],
[95,25,17,15],
[95,25,18,0],
[95,25,18,1],
[95,25,18,2],
[95,25,18,3],
[95,25,18,4],
[95,25,18,5],
[95,25,18,6],
[95,25,18,8],
[95,25,18,10],
[95,25,18,13],
[95,25,18,14],
[95,25,19,0],
[95,25,19,1],
[95,25,19,2],
[95,25,19,3],
[95,25,19,4],
[95,25,19,5],
[95,25,19,6],
[95,25,19,8],
[95,25,19,9],
[95,25,19,10],
[95,25,20,0],
[95,25,20,1],
[95,25,20,2],
[95,25,20,3],
[95,25,20,4],
[95,25,20,5],
[95,25,20,6],
[95,25,20,8],
[95,25,20,9],
[95,25,21,0],
[95,25,21,1],
[95,25,21,2],
[95,25,21,4],
[95,25,21,9],
[95,25,22,0],
[95,25,22,1],
[95,25,22,2],
[95,25,22,3],
[95,25,22,4],
[95,25,22,5],
[95,25,22,6],
[95,25,23,0],
[95,25,23,1],
[95,25,23,2],
[95,25,23,3],
[95,25,23,4],
[95,25,23,5],
[95,25,24,0],
[95,25,24,1],
[95,25,24,2],
[95,25,24,3],
[95,25,24,4],
[95,25,24,5],
[95,26,3,0],
[95,26,3,1],
[95,26,3,2],
[95,26,4,0],
[95,26,4,1],
[95,26,4,2],
[95,26,4,3],
[95,26,5,0],
[95,26,5,1],
[95,26,5,2],
[95,26,5,4],
[95,26,6,0],
[95,26,6,1],
[95,26,6,2],
[95,26,7,0],
[95,26,7,1],
[95,26,7,2],
[95,26,7,5],
[95,26,7,6],
[95,26,8,4],
[95,26,8,7],
[95,26,10,0],
[95,26,10,1],
[95,26,10,2],
[95,26,10,3],
[95,26,10,4],
[95,26,10,5],
[95,26,10,6],
[95,26,10,7],
[95,26,10,8],
[95,26,13,0],
[95,26,13,1],
[95,26,13,2],
[95,26,13,3],
[95,26,13,4],
[95,26,13,5],
[95,26,13,6],
[95,26,13,7],
[95,26,13,8],
[95,26,13,10],
[95,26,14,0],
[95,26,14,1],
[95,26,14,2],
[95,26,14,3],
[95,26,14,4],
[95,26,14,5],
[95,26,14,6],
[95,26,14,7],
[95,26,14,8],
[95,26,14,10],
[95,26,16,0],
[95,26,16,1],
[95,26,16,2],
[95,26,16,3],
[95,26,16,4],
[95,26,16,5],
[95,26,16,6],
[95,26,16,7],
[95,26,16,8],
[95,26,16,10],
[95,26,16,13],
[95,26,16,14],
[95,26,17,0],
[95,26,17,1],
[95,26,17,2],
[95,26,17,3],
[95,26,17,4],
[95,26,17,5],
[95,26,17,6],
[95,26,17,7],
[95,26,17,8],
[95,26,17,10],
[95,26,17,13],
[95,26,17,14],
[95,26,19,0],
[95,26,19,1],
[95,26,19,2],
[95,26,19,3],
[95,26,19,4],
[95,26,19,5],
[95,26,19,6],
[95,26,19,7],
[95,26,19,8],
[95,26,19,10],
[95,26,20,0],
[95,26,20,1],
[95,26,20,2],
[95,26,20,3],
[95,26,20,4],
[95,26,20,5],
[95,26,20,6],
[95,26,20,7],
[95,26,20,8],
[95,26,21,0],
[95,26,21,1],
[95,26,21,2],
[95,26,21,4],
[95,26,21,7],
[95,26,22,0],
[95,26,22,1],
[95,26,22,2],
[95,26,22,3],
[95,26,22,4],
[95,26,22,5],
[95,26,23,0],
[95,26,23,1],
[95,26,23,2],
[95,26,23,3],
[95,26,23,4],
[95,26,24,0],
[95,26,24,1],
[95,26,24,2],
[95,26,24,3],
[95,26,24,4],
[95,27,3,0],
[95,27,3,1],
[95,27,3,2],
[95,27,4,0],
[95,27,4,1],
[95,27,4,2],
[95,27,4,3],
[95,27,5,0],
[95,27,5,1],
[95,27,5,2],
[95,27,6,4],
[95,27,7,3],
[95,27,7,4],
[95,27,7,5],
[95,27,7,6],
[95,27,8,0],
[95,27,8,1],
[95,27,8,2],
[95,27,8,4],
[95,27,8,7],
[95,27,9,0],
[95,27,9,1],
[95,27,9,2],
[95,27,9,3],
[95,27,9,4],
[95,27,9,5],
[95,27,9,6],
[95,27,9,7],
[95,27,9,8],
[95,27,13,0],
[95,27,13,1],
[95,27,13,2],
[95,27,13,3],
[95,27,13,4],
[95,27,13,5],
[95,27,13,6],
[95,27,13,7],
[95,27,13,8],
[95,27,13,9],
[95,27,14,0],
[95,27,14,1],
[95,27,14,2],
[95,27,14,3],
[95,27,14,4],
[95,27,14,5],
[95,27,14,6],
[95,27,14,7],
[95,27,14,8],
[95,27,14,9],
[95,27,15,0],
[95,27,15,1],
[95,27,15,2],
[95,27,15,3],
[95,27,15,4],
[95,27,15,5],
[95,27,15,6],
[95,27,15,7],
[95,27,15,8],
[95,27,15,13],
[95,27,15,14],
[95,27,16,0],
[95,27,16,1],
[95,27,16,2],
[95,27,16,3],
[95,27,16,4],
[95,27,16,5],
[95,27,16,6],
[95,27,16,7],
[95,27,16,8],
[95,27,16,9],
[95,27,16,13],
[95,27,17,0],
[95,27,17,1],
[95,27,17,2],
[95,27,17,3],
[95,27,17,4],
[95,27,17,5],
[95,27,17,6],
[95,27,17,7],
[95,27,17,8],
[95,27,17,9],
[95,27,17,13],
[95,27,18,0],
[95,27,18,1],
[95,27,18,2],
[95,27,18,3],
[95,27,18,4],
[95,27,18,5],
[95,27,18,6],
[95,27,18,7],
[95,27,18,8],
[95,27,19,0],
[95,27,19,1],
[95,27,19,2],
[95,27,19,3],
[95,27,19,4],
[95,27,19,5],
[95,27,19,6],
[95,27,19,7],
[95,27,19,8],
[95,27,19,9],
[95,27,21,0],
[95,27,21,1],
[95,27,21,2],
[95,27,21,4],
[95,27,23,0],
[95,27,23,1],
[95,27,23,2],
[95,27,24,0],
[95,27,24,1],
[95,27,24,2],
[95,28,3,0],
[95,28,3,1],
[95,28,3,2],
[95,28,4,0],
[95,28,4,1],
[95,28,4,2],
[95,28,5,4],
[95,28,6,0],
[95,28,6,1],
[95,28,6,2],
[95,28,6,4],
[95,28,8,0],
[95,28,8,1],
[95,28,8,2],
[95,28,8,4],
[95,28,9,0],
[95,28,9,1],
[95,28,9,2],
[95,28,9,3],
[95,28,9,4],
[95,28,9,5],
[95,28,9,6],
[95,28,9,8],
[95,28,10,0],
[95,28,10,1],
[95,28,10,2],
[95,28,10,3],
[95,28,10,4],
[95,28,10,5],
[95,28,10,6],
[95,28,10,8],
[95,28,10,9],
[95,28,13,0],
[95,28,13,1],
[95,28,13,2],
[95,28,13,3],
[95,28,13,4],
[95,28,13,5],
[95,28,13,6],
[95,28,13,8],
[95,28,13,9],
[95,28,13,10],
[95,28,14,0],
[95,28,14,1],
[95,28,14,2],
[95,28,14,3],
[95,28,14,4],
[95,28,14,5],
[95,28,14,6],
[95,28,14,8],
[95,28,14,9],
[95,28,14,10],
[95,28,15,0],
[95,28,15,1],
[95,28,15,2],
[95,28,15,3],
[95,28,15,4],
[95,28,15,5],
[95,28,15,6],
[95,28,15,8],
[95,28,15,10],
[95,28,15,13],
[95,28,16,0],
[95,28,16,1],
[95,28,16,2],
[95,28,16,3],
[95,28,16,4],
[95,28,16,5],
[95,28,16,6],
[95,28,16,8],
[95,28,16,9],
[95,28,16,10],
[95,28,17,0],
[95,28,17,1],
[95,28,17,2],
[95,28,17,3],
[95,28,17,4],
[95,28,17,5],
[95,28,17,6],
[95,28,17,8],
[95,28,17,9],
[95,28,17,10],
[95,28,18,0],
[95,28,18,1],
[95,28,18,2],
[95,28,18,3],
[95,28,18,4],
[95,28,18,5],
[95,28,18,6],
[95,28,18,8],
[95,28,19,0],
[95,28,19,1],
[95,28,19,2],
[95,28,19,3],
[95,28,19,4],
[95,28,19,5],
[95,28,19,6],
[95,28,20,0],
[95,28,20,1],
[95,28,20,2],
[95,28,20,3],
[95,28,20,4],
[95,28,20,5],
[95,28,21,0],
[95,28,21,1],
[95,28,21,2],
[95,28,21,4],
[95,28,22,0],
[95,28,22,1],
[95,28,22,2],
[95,30,3,0],
[95,30,3,1],
[95,30,3,2],
[95,30,4,0],
[95,30,4,1],
[95,30,4,2],
[95,30,4,3],
[95,30,5,0],
[95,30,5,1],
[95,30,5,2],
[95,30,5,4],
[95,30,6,0],
[95,30,6,1],
[95,30,6,2],
[95,30,6,4],
[95,30,7,0],
[95,30,7,1],
[95,30,7,2],
[95,30,7,3],
[95,30,7,4],
[95,30,7,5],
[95,30,7,6],
[95,30,8,0],
[95,30,8,1],
[95,30,8,2],
[95,30,8,4],
[95,30,8,7],
[95,30,9,0],
[95,30,9,1],
[95,30,9,2],
[95,30,9,3],
[95,30,9,4],
[95,30,9,5],
[95,30,9,6],
[95,30,9,7],
[95,30,9,8],
[95,30,10,0],
[95,30,10,1],
[95,30,10,2],
[95,30,10,3],
[95,30,10,4],
[95,30,10,5],
[95,30,10,6],
[95,30,10,7],
[95,30,10,8],
[95,30,10,9],
[95,30,13,0],
[95,30,13,1],
[95,30,13,2],
[95,30,13,3],
[95,30,13,4],
[95,30,13,5],
[95,30,13,6],
[95,30,13,7],
[95,30,13,8],
[95,30,13,9],
[95,30,13,10],
[95,30,14,0],
[95,30,14,1],
[95,30,14,2],
[95,30,14,3],
[95,30,14,4],
[95,30,14,5],
[95,30,14,6],
[95,30,14,7],
[95,30,14,8],
[95,30,14,9],
[95,30,14,10],
[95,30,15,0],
[95,30,15,1],
[95,30,15,2],
[95,30,15,3],
[95,30,15,4],
[95,30,15,5],
[95,30,15,6],
[95,30,15,7],
[95,30,15,8],
[95,30,16,0],
[95,30,16,1],
[95,30,16,2],
[95,30,16,3],
[95,30,16,4],
[95,30,16,5],
[95,30,16,6],
[95,30,16,7],
[95,30,17,0],
[95,30,17,1],
[95,30,17,2],
[95,30,17,3],
[95,30,17,4],
[95,30,17,5],
[95,30,17,6],
[95,30,17,7],
[95,30,18,0],
[95,30,18,1],
[95,30,18,2],
[95,30,18,3],
[95,30,18,4],
[95,30,18,5],
[95,30,19,0],
[95,30,19,1],
[95,30,19,2],
[95,30,19,3],
[95,30,19,4],
[95,30,20,0],
[95,30,20,1],
[95,30,20,2],
[95,31,3,0],
[95,31,3,1],
[95,31,3,2],
[95,31,4,0],
[95,31,4,1],
[95,31,4,2],
[95,31,4,3],
[95,31,5,0],
[95,31,5,1],
[95,31,5,2],
[95,31,5,4],
[95,31,6,0],
[95,31,6,1],
[95,31,6,2],
[95,31,6,4],
[95,31,7,0],
[95,31,7,1],
[95,31,7,2],
[95,31,7,3],
[95,31,7,4],
[95,31,7,5],
[95,31,7,6],
[95,31,8,0],
[95,31,8,1],
[95,31,8,2],
[95,31,8,4],
[95,31,8,7],
[95,31,9,0],
[95,31,9,1],
[95,31,9,2],
[95,31,9,3],
[95,31,9,4],
[95,31,9,5],
[95,31,9,6],
[95,31,9,7],
[95,31,9,8],
[95,31,10,0],
[95,31,10,1],
[95,31,10,2],
[95,31,10,3],
[95,31,10,4],
[95,31,10,5],
[95,31,10,6],
[95,31,10,7],
[95,31,10,8],
[95,31,10,9],
[95,31,13,0],
[95,31,13,1],
[95,31,13,2],
[95,31,13,3],
[95,31,13,4],
[95,31,13,5],
[95,31,13,6],
[95,31,13,7],
[95,31,13,8],
[95,31,13,9],
[95,31,13,10],
[95,31,14,0],
[95,31,14,1],
[95,31,14,2],
[95,31,14,3],
[95,31,14,4],
[95,31,14,5],
[95,31,14,6],
[95,31,14,7],
[95,31,14,8],
[95,31,14,9],
[95,31,15,0],
[95,31,15,1],
[95,31,15,2],
[95,31,15,3],
[95,31,15,4],
[95,31,15,5],
[95,31,15,6],
[95,31,15,7],
[95,31,16,0],
[95,31,16,1],
[95,31,16,2],
[95,31,16,3],
[95,31,16,4],
[95,31,16,5],
[95,31,17,0],
[95,31,17,1],
[95,31,17,2],
[95,31,17,3],
[95,31,17,4],
[95,31,17,5],
[95,31,18,0],
[95,31,18,1],
[95,31,18,2],
[95,31,18,3],
[95,31,18,4],
[95,31,19,0],
[95,31,19,1],
[95,31,19,2],
[95,34,3,0],
[95,34,3,1],
[95,34,3,2],
[95,34,4,0],
[95,34,4,1],
[95,34,4,2],
[95,34,4,3],
[95,34,5,0],
[95,34,5,1],
[95,34,5,2],
[95,34,5,4],
[95,34,6,0],
[95,34,6,1],
[95,34,6,2],
[95,34,6,4],
[95,34,8,0],
[95,34,8,1],
[95,34,8,2],
[95,34,8,4],
[95,34,9,0],
[95,34,9,1],
[95,34,9,2],
[95,34,9,3],
[95,34,9,4],
[95,34,9,5],
[95,34,9,6],
[95,34,9,8],
[95,34,10,0],
[95,34,10,1],
[95,34,10,2],
[95,34,10,3],
[95,34,10,4],
[95,34,10,5],
[95,34,10,6],
[95,34,13,0],
[95,34,13,1],
[95,34,13,2],
[95,34,13,3],
[95,34,13,4],
[95,34,13,5],
[95,34,14,0],
[95,34,14,1],
[95,34,14,2],
[95,34,14,3],
[95,34,14,4],
[95,34,15,0],
[95,34,15,1],
[95,34,15,2],
[95,35,3,0],
[95,35,3,1],
[95,35,3,2],
[95,35,4,0],
[95,35,4,1],
[95,35,4,2],
[95,35,4,3],
[95,35,5,0],
[95,35,5,1],
[95,35,5,2],
[95,35,5,4],
[95,35,6,0],
[95,35,6,1],
[95,35,6,2],
[95,35,6,4],
[95,35,7,0],
[95,35,7,1],
[95,35,7,2],
[95,35,7,3],
[95,35,7,4],
[95,35,7,5],
[95,35,7,6],
[95,35,8,0],
[95,35,8,1],
[95,35,8,2],
[95,35,8,4],
[95,35,8,7],
[95,35,10,0],
[95,35,10,1],
[95,35,10,2],
[95,35,10,3],
[95,35,10,4],
[95,35,10,5],
[95,35,13,0],
[95,35,13,1],
[95,35,13,2],
[95,35,13,3],
[95,35,13,4],
[95,35,14,0],
[95,35,14,1],
[95,35,14,2],
[95,36,3,0],
[95,36,3,1],
[95,36,3,2],
[95,36,4,0],
[95,36,4,1],
[95,36,4,2],
[95,36,4,3],
[95,36,5,0],
[95,36,5,1],
[95,36,5,2],
[95,36,5,4],
[95,36,6,0],
[95,36,6,1],
[95,36,6,2],
[95,36,6,4],
[95,36,7,0],
[95,36,7,1],
[95,36,7,2],
[95,36,7,3],
[95,36,7,4],
[95,36,7,5],
[95,36,7,6],
[95,36,8,0],
[95,36,8,1],
[95,36,8,2],
[95,36,8,4],
[95,36,9,0],
[95,36,9,1],
[95,36,9,2],
[95,36,9,3],
[95,36,9,4],
[95,36,9,5],
[95,36,10,0],
[95,36,10,1],
[95,36,10,2],
[95,36,10,3],
[95,36,10,4],
[95,37,3,0],
[95,37,3,1],
[95,37,3,2],
[95,37,5,0],
[95,37,5,1],
[95,37,5,2],
[95,37,6,0],
[95,37,6,1],
[95,37,6,2],
[95,37,7,0],
[95,37,7,1],
[95,37,7,2],
[95,37,7,3],
[95,37,7,5],
[95,37,8,0],
[95,37,8,1],
[95,37,8,2],
[95,37,9,0],
[95,37,9,1],
[95,37,9,2],
[95,37,9,3],
[95,37,10,0],
[95,37,10,1],
[95,37,10,2],
[95,38,3,0],
[95,38,3,1],
[95,38,3,2],
[95,38,4,0],
[95,38,4,1],
[95,38,4,2],
[95,38,4,3],
[95,38,5,0],
[95,38,5,1],
[95,38,5,2],
[95,38,5,4],
[95,38,6,0],
[95,38,6,1],
[95,38,6,2],
[95,38,6,4],
[95,38,8,0],
[95,38,8,1],
[95,38,8,2],
[95,38,9,0],
[95,38,9,1],
[95,38,9,2],
[95,39,4,0],
[95,39,4,1],
[95,39,4,2],
[95,39,7,0],
[95,39,7,1],
[95,39,7,2],
[95,40,3,0],
[95,40,3,1],
[95,40,3,2],
[95,40,4,0],
[95,40,4,1],
[95,40,4,2],
[95,40,4,3],
[95,40,5,0],
[95,40,5,1],
[95,40,5,2],
[95,41,3,0],
[95,41,3,1],
[95,41,3,2],
[95,41,4,0],
[95,41,4,1],
[95,41,4,2],
[95,41,4,3],
[95,41,5,0],
[95,41,5,1],
[95,41,5,2],
[95,42,3,0],
[95,42,3,1],
[95,42,3,2],
[95,42,4,0],
[95,42,4,1],
[95,42,4,2],
[96,4,3,0],
[96,4,3,1],
[96,4,3,2],
[96,5,4,0],
[96,5,4,1],
[96,5,4,2],
[96,6,4,0],
[96,6,4,1],
[96,6,4,2],
[96,7,3,0],
[96,7,3,1],
[96,7,3,2],
[96,7,4,0],
[96,7,4,1],
[96,7,4,2],
[96,7,4,3],
[96,7,5,0],
[96,7,5,1],
[96,7,5,2],
[96,7,5,4],
[96,7,6,0],
[96,7,6,1],
[96,7,6,2],
[96,7,6,4],
[96,8,4,0],
[96,8,4,1],
[96,8,4,2],
[96,8,7,0],
[96,8,7,1],
[96,8,7,2],
[96,8,7,4],
[96,9,3,0],
[96,9,3,1],
[96,9,3,2],
[96,9,4,0],
[96,9,4,1],
[96,9,4,2],
[96,9,4,3],
[96,9,5,0],
[96,9,5,1],
[96,9,5,2],
[96,9,5,4],
[96,9,6,0],
[96,9,6,1],
[96,9,6,2],
[96,9,6,4],
[96,9,7,0],
[96,9,7,1],
[96,9,7,2],
[96,9,7,3],
[96,9,7,4],
[96,9,7,5],
[96,9,7,6],
[96,9,8,0],
[96,9,8,1],
[96,9,8,2],
[96,9,8,4],
[96,9,8,7],
[96,10,3,0],
[96,10,3,1],
[96,10,3,2],
[96,10,4,0],
[96,10,4,1],
[96,10,4,2],
[96,10,4,3],
[96,10,5,0],
[96,10,5,1],
[96,10,5,2],
[96,10,5,4],
[96,10,6,0],
[96,10,6,1],
[96,10,6,2],
[96,10,6,4],
[96,10,7,0],
[96,10,7,1],
[96,10,7,2],
[96,10,7,3],
[96,10,7,4],
[96,10,7,5],
[96,10,7,6],
[96,10,8,0],
[96,10,8,1],
[96,10,8,2],
[96,10,8,4],
[96,10,8,7],
[96,10,9,0],
[96,10,9,1],
[96,10,9,2],
[96,10,9,3],
[96,10,9,4],
[96,10,9,5],
[96,10,9,6],
[96,10,9,7],
[96,10,9,8],
[96,11,3,0],
[96,11,3,1],
[96,11,3,2],
[96,11,4,0],
[96,11,4,1],
[96,11,4,2],
[96,11,4,3],
[96,11,5,0],
[96,11,5,1],
[96,11,5,2],
[96,11,5,4],
[96,11,6,0],
[96,11,6,1],
[96,11,6,2],
[96,11,6,4],
[96,11,7,0],
[96,11,7,1],
[96,11,7,2],
[96,11,7,3],
[96,11,7,4],
[96,11,7,5],
[96,11,7,6],
[96,11,8,0],
[96,11,8,1],
[96,11,8,2],
[96,11,8,4],
[96,11,8,7],
[96,11,9,0],
[96,11,9,1],
[96,11,9,2],
[96,11,9,3],
[96,11,9,4],
[96,11,9,5],
[96,11,9,6],
[96,11,9,7],
[96,11,9,8],
[96,11,10,0],
[96,11,10,1],
[96,11,10,2],
[96,11,10,3],
[96,11,10,4],
[96,11,10,5],
[96,11,10,6],
[96,11,10,7],
[96,11,10,8],
[96,11,10,9],
[96,12,3,0],
[96,12,3,1],
[96,12,3,2],
[96,12,4,0],
[96,12,4,1],
[96,12,4,2],
[96,12,4,3],
[96,12,5,0],
[96,12,5,1],
[96,12,5,2],
[96,12,5,4],
[96,12,6,0],
[96,12,6,1],
[96,12,6,2],
[96,12,6,4],
[96,12,7,0],
[96,12,7,1],
[96,12,7,2],
[96,12,7,3],
[96,12,7,4],
[96,12,7,5],
[96,12,7,6],
[96,12,8,0],
[96,12,8,1],
[96,12,8,2],
[96,12,8,4],
[96,12,8,7],
[96,12,9,0],
[96,12,9,1],
[96,12,9,2],
[96,12,9,3],
[96,12,9,4],
[96,12,9,5],
[96,12,9,6],
[96,12,9,7],
[96,12,9,8],
[96,12,10,0],
[96,12,10,1],
[96,12,10,2],
[96,12,10,3],
[96,12,10,4],
[96,12,10,5],
[96,12,10,6],
[96,12,10,7],
[96,12,10,8],
[96,12,10,9],
[96,15,3,0],
[96,15,3,1],
[96,15,3,2],
[96,15,4,0],
[96,15,4,1],
[96,15,4,2],
[96,15,4,3],
[96,15,5,0],
[96,15,5,1],
[96,15,5,2],
[96,15,5,4],
[96,15,6,0],
[96,15,6,1],
[96,15,6,2],
[96,15,6,4],
[96,15,7,0],
[96,15,7,1],
[96,15,7,2],
[96,15,7,3],
[96,15,7,4],
[96,15,7,5],
[96,15,7,6],
[96,15,8,0],
[96,15,8,1],
[96,15,8,2],
[96,15,8,4],
[96,15,8,7],
[96,15,10,0],
[96,15,10,1],
[96,15,10,2],
[96,15,10,3],
[96,15,10,4],
[96,15,10,5],
[96,15,10,6],
[96,15,10,7],
[96,15,10,8],
[96,15,11,0],
[96,15,11,1],
[96,15,11,2],
[96,15,11,3],
[96,15,11,4],
[96,15,11,5],
[96,15,11,6],
[96,15,11,7],
[96,15,11,8],
[96,15,11,10],
[96,15,12,0],
[96,15,12,1],
[96,15,12,2],
[96,15,12,3],
[96,15,12,4],
[96,15,12,5],
[96,15,12,6],
[96,15,12,7],
[96,15,12,8],
[96,15,12,10],
[96,16,3,0],
[96,16,3,1],
[96,16,3,2],
[96,16,4,0],
[96,16,4,1],
[96,16,4,2],
[96,16,4,3],
[96,16,5,0],
[96,16,5,1],
[96,16,5,2],
[96,16,5,4],
[96,16,6,0],
[96,16,6,1],
[96,16,6,2],
[96,16,6,4],
[96,16,7,0],
[96,16,7,1],
[96,16,7,2],
[96,16,7,3],
[96,16,7,4],
[96,16,7,5],
[96,16,7,6],
[96,16,8,0],
[96,16,8,1],
[96,16,8,2],
[96,16,8,4],
[96,16,8,7],
[96,16,9,0],
[96,16,9,1],
[96,16,9,2],
[96,16,9,3],
[96,16,9,4],
[96,16,9,5],
[96,16,9,6],
[96,16,9,7],
[96,16,9,8],
[96,16,10,0],
[96,16,10,1],
[96,16,10,2],
[96,16,10,3],
[96,16,10,4],
[96,16,10,5],
[96,16,10,6],
[96,16,10,7],
[96,16,10,8],
[96,16,10,9],
[96,16,11,0],
[96,16,11,1],
[96,16,11,2],
[96,16,11,3],
[96,16,11,4],
[96,16,11,5],
[96,16,11,6],
[96,16,11,7],
[96,16,11,8],
[96,16,11,9],
[96,16,11,10],
[96,16,12,0],
[96,16,12,1],
[96,16,12,2],
[96,16,12,3],
[96,16,12,4],
[96,16,12,5],
[96,16,12,6],
[96,16,12,7],
[96,16,12,8],
[96,16,12,9],
[96,16,12,10],
[96,16,15,0],
[96,16,15,1],
[96,16,15,2],
[96,16,15,3],
[96,16,15,4],
[96,16,15,5],
[96,16,15,6],
[96,16,15,7],
[96,16,15,10],
[96,16,15,11],
[96,16,15,12],
[96,17,3,0],
[96,17,3,1],
[96,17,3,2],
[96,17,4,0],
[96,17,4,1],
[96,17,4,2],
[96,17,4,3],
[96,17,5,0],
[96,17,5,1],
[96,17,5,2],
[96,17,5,4],
[96,17,6,0],
[96,17,6,1],
[96,17,6,2],
[96,17,6,4],
[96,17,7,0],
[96,17,7,1],
[96,17,7,2],
[96,17,7,3],
[96,17,7,4],
[96,17,7,5],
[96,17,7,6],
[96,17,8,0],
[96,17,8,1],
[96,17,8,2],
[96,17,8,4],
[96,17,8,7],
[96,17,9,0],
[96,17,9,1],
[96,17,9,2],
[96,17,9,3],
[96,17,9,4],
[96,17,9,5],
[96,17,9,6],
[96,17,9,7],
[96,17,9,8],
[96,17,10,0],
[96,17,10,1],
[96,17,10,2],
[96,17,10,3],
[96,17,10,4],
[96,17,10,5],
[96,17,10,6],
[96,17,10,7],
[96,17,10,8],
[96,17,10,9],
[96,17,11,0],
[96,17,11,1],
[96,17,11,2],
[96,17,11,3],
[96,17,11,4],
[96,17,11,5],
[96,17,11,6],
[96,17,11,7],
[96,17,11,8],
[96,17,11,9],
[96,17,11,10],
[96,17,12,0],
[96,17,12,1],
[96,17,12,2],
[96,17,12,3],
[96,17,12,4],
[96,17,12,5],
[96,17,12,6],
[96,17,12,7],
[96,17,12,8],
[96,17,12,9],
[96,17,12,10],
[96,17,15,0],
[96,17,15,1],
[96,17,15,2],
[96,17,15,3],
[96,17,15,4],
[96,17,15,5],
[96,17,15,6],
[96,17,15,7],
[96,17,15,10],
[96,17,15,11],
[96,17,15,12],
[96,17,16,0],
[96,17,16,1],
[96,17,16,2],
[96,17,16,3],
[96,17,16,4],
[96,17,16,5],
[96,17,16,8],
[96,17,16,9],
[96,17,16,10],
[96,17,16,11],
[96,17,16,12],
[96,17,16,15],
[96,18,3,0],
[96,18,3,1],
[96,18,3,2],
[96,18,4,0],
[96,18,4,1],
[96,18,4,2],
[96,18,4,3],
[96,18,5,0],
[96,18,5,1],
[96,18,5,2],
[96,18,5,4],
[96,18,6,0],
[96,18,6,1],
[96,18,6,2],
[96,18,6,4],
[96,18,7,0],
[96,18,7,1],
[96,18,7,2],
[96,18,7,3],
[96,18,7,4],
[96,18,7,5],
[96,18,7,6],
[96,18,8,0],
[96,18,8,1],
[96,18,8,2],
[96,18,8,4],
[96,18,8,7],
[96,18,10,0],
[96,18,10,1],
[96,18,10,2],
[96,18,10,3],
[96,18,10,4],
[96,18,10,5],
[96,18,10,6],
[96,18,10,7],
[96,18,10,8],
[96,18,11,0],
[96,18,11,1],
[96,18,11,2],
[96,18,11,3],
[96,18,11,4],
[96,18,11,5],
[96,18,11,6],
[96,18,11,7],
[96,18,11,8],
[96,18,11,10],
[96,18,12,0],
[96,18,12,1],
[96,18,12,2],
[96,18,12,3],
[96,18,12,4],
[96,18,12,5],
[96,18,12,6],
[96,18,12,7],
[96,18,12,8],
[96,18,16,0],
[96,18,16,1],
[96,18,16,2],
[96,18,16,3],
[96,18,16,4],
[96,18,16,6],
[96,18,16,7],
[96,18,16,8],
[96,18,16,10],
[96,18,16,11],
[96,18,16,12],
[96,18,17,0],
[96,18,17,1],
[96,18,17,2],
[96,18,17,3],
[96,18,17,4],
[96,18,17,6],
[96,18,17,7],
[96,18,17,8],
[96,18,17,10],
[96,18,17,11],
[96,18,17,12],
[96,18,17,16],
[96,20,3,0],
[96,20,3,1],
[96,20,3,2],
[96,20,4,0],
[96,20,4,1],
[96,20,4,2],
[96,20,4,3],
[96,20,5,0],
[96,20,5,1],
[96,20,5,2],
[96,20,5,4],
[96,20,6,0],
[96,20,6,1],
[96,20,6,2],
[96,20,6,4],
[96,20,7,0],
[96,20,7,1],
[96,20,7,2],
[96,20,7,3],
[96,20,7,4],
[96,20,7,5],
[96,20,7,6],
[96,20,8,0],
[96,20,8,1],
[96,20,8,2],
[96,20,8,4],
[96,20,8,7],
[96,20,9,0],
[96,20,9,1],
[96,20,9,2],
[96,20,9,3],
[96,20,9,4],
[96,20,9,5],
[96,20,9,6],
[96,20,9,7],
[96,20,9,8],
[96,20,11,0],
[96,20,11,1],
[96,20,11,2],
[96,20,11,3],
[96,20,11,4],
[96,20,11,5],
[96,20,11,6],
[96,20,11,7],
[96,20,12,0],
[96,20,12,1],
[96,20,12,2],
[96,20,12,3],
[96,20,12,4],
[96,20,12,5],
[96,20,12,8],
[96,20,12,9],
[96,20,15,0],
[96,20,15,1],
[96,20,15,2],
[96,20,15,5],
[96,20,15,6],
[96,20,15,7],
[96,20,15,8],
[96,20,15,11],
[96,20,15,12],
[96,20,16,3],
[96,20,16,4],
[96,20,16,5],
[96,20,16,6],
[96,20,16,7],
[96,20,16,8],
[96,20,16,9],
[96,20,16,11],
[96,20,16,12],
[96,20,16,15],
[96,20,17,3],
[96,20,17,4],
[96,20,17,5],
[96,20,17,6],
[96,20,17,7],
[96,20,17,8],
[96,20,17,9],
[96,20,17,11],
[96,20,17,12],
[96,20,17,15],
[96,20,17,16],
[96,20,18,0],
[96,20,18,1],
[96,20,18,2],
[96,20,18,3],
[96,20,18,4],
[96,20,18,5],
[96,20,18,6],
[96,20,18,7],
[96,20,18,8],
[96,20,18,11],
[96,20,18,12],
[96,20,18,16],
[96,20,18,17],
[96,21,4,0],
[96,21,4,1],
[96,21,4,2],
[96,21,7,0],
[96,21,7,1],
[96,21,7,2],
[96,21,7,4],
[96,21,9,0],
[96,21,9,1],
[96,21,9,2],
[96,21,9,4],
[96,21,9,7],
[96,21,10,0],
[96,21,10,1],
[96,21,10,2],
[96,21,10,4],
[96,21,10,9],
[96,21,11,0],
[96,21,11,1],
[96,21,11,2],
[96,21,11,4],
[96,21,11,9],
[96,21,11,10],
[96,21,12,0],
[96,21,12,1],
[96,21,12,2],
[96,21,12,4],
[96,21,12,7],
[96,21,12,9],
[96,21,12,10],
[96,21,15,4],
[96,21,15,7],
[96,21,15,10],
[96,21,15,11],
[96,21,15,12],
[96,21,16,0],
[96,21,16,1],
[96,21,16,2],
[96,21,16,4],
[96,21,16,7],
[96,21,16,9],
[96,21,16,10],
[96,21,16,11],
[96,21,16,12],
[96,21,16,15],
[96,21,17,0],
[96,21,17,1],
[96,21,17,2],
[96,21,17,4],
[96,21,17,7],
[96,21,17,9],
[96,21,17,10],
[96,21,17,11],
[96,21,17,12],
[96,21,17,15],
[96,21,17,16],
[96,21,18,0],
[96,21,18,1],
[96,21,18,2],
[96,21,18,4],
[96,21,18,7],
[96,21,18,10],
[96,21,18,11],
[96,21,18,12],
[96,21,18,16],
[96,21,18,17],
[96,21,20,0],
[96,21,20,1],
[96,21,20,2],
[96,21,20,4],
[96,21,20,7],
[96,21,20,9],
[96,21,20,11],
[96,21,20,12],
[96,21,20,15],
[96,22,3,0],
[96,22,3,1],
[96,22,3,2],
[96,22,4,0],
[96,22,4,1],
[96,22,4,2],
[96,22,4,3],
[96,22,5,0],
[96,22,5,1],
[96,22,5,2],
[96,22,5,4],
[96,22,6,0],
[96,22,6,1],
[96,22,6,2],
[96,22,6,4],
[96,22,7,0],
[96,22,7,1],
[96,22,7,2],
[96,22,7,3],
[96,22,7,4],
[96,22,7,5],
[96,22,7,6],
[96,22,8,0],
[96,22,8,1],
[96,22,8,2],
[96,22,8,4],
[96,22,9,0],
[96,22,9,1],
[96,22,9,2],
[96,22,9,3],
[96,22,9,4],
[96,22,9,5],
[96,22,9,8],
[96,22,11,0],
[96,22,11,1],
[96,22,11,2],
[96,22,11,3],
[96,22,11,4],
[96,22,11,6],
[96,22,11,7],
[96,22,11,8],
[96,22,11,9],
[96,22,12,0],
[96,22,12,1],
[96,22,12,2],
[96,22,12,5],
[96,22,12,6],
[96,22,12,7],
[96,22,12,8],
[96,22,12,9],
[96,22,15,0],
[96,22,15,1],
[96,22,15,2],
[96,22,15,3],
[96,22,15,4],
[96,22,15,5],
[96,22,15,6],
[96,22,15,7],
[96,22,15,8],
[96,22,15,11],
[96,22,15,12],
[96,22,16,0],
[96,22,16,1],
[96,22,16,2],
[96,22,16,3],
[96,22,16,4],
[96,22,16,5],
[96,22,16,6],
[96,22,16,7],
[96,22,16,8],
[96,22,16,9],
[96,22,16,11],
[96,22,16,12],
[96,22,16,15],
[96,22,17,0],
[96,22,17,1],
[96,22,17,2],
[96,22,17,3],
[96,22,17,4],
[96,22,17,5],
[96,22,17,6],
[96,22,17,7],
[96,22,17,8],
[96,22,17,9],
[96,22,17,11],
[96,22,17,12],
[96,22,17,15],
[96,22,17,16],
[96,22,18,0],
[96,22,18,1],
[96,22,18,2],
[96,22,18,3],
[96,22,18,4],
[96,22,18,5],
[96,22,18,6],
[96,22,18,7],
[96,22,18,8],
[96,22,18,11],
[96,22,18,12],
[96,22,18,16],
[96,22,18,17],
[96,22,21,0],
[96,22,21,1],
[96,22,21,2],
[96,22,21,4],
[96,22,21,7],
[96,22,21,9],
[96,22,21,11],
[96,22,21,12],
[96,23,3,0],
[96,23,3,1],
[96,23,3,2],
[96,23,4,0],
[96,23,4,1],
[96,23,4,2],
[96,23,4,3],
[96,23,5,0],
[96,23,5,1],
[96,23,5,2],
[96,23,5,4],
[96,23,6,0],
[96,23,6,1],
[96,23,6,2],
[96,23,6,4],
[96,23,7,0],
[96,23,7,1],
[96,23,7,2],
[96,23,7,3],
[96,23,7,4],
[96,23,7,5],
[96,23,8,0],
[96,23,8,1],
[96,23,8,2],
[96,23,8,4],
[96,23,8,7],
[96,23,9,0],
[96,23,9,1],
[96,23,9,2],
[96,23,9,3],
[96,23,9,4],
[96,23,9,6],
[96,23,9,7],
[96,23,9,8],
[96,23,10,0],
[96,23,10,1],
[96,23,10,2],
[96,23,10,5],
[96,23,10,6],
[96,23,10,7],
[96,23,10,8],
[96,23,10,9],
[96,23,11,0],
[96,23,11,1],
[96,23,11,2],
[96,23,11,5],
[96,23,11,6],
[96,23,11,7],
[96,23,11,8],
[96,23,11,9],
[96,23,11,10],
[96,23,12,3],
[96,23,12,4],
[96,23,12,5],
[96,23,12,6],
[96,23,12,7],
[96,23,12,8],
[96,23,12,9],
[96,23,12,10],
[96,23,15,0],
[96,23,15,1],
[96,23,15,2],
[96,23,15,3],
[96,23,15,4],
[96,23,15,5],
[96,23,15,6],
[96,23,15,7],
[96,23,15,8],
[96,23,15,10],
[96,23,15,11],
[96,23,15,12],
[96,23,16,0],
[96,23,16,1],
[96,23,16,2],
[96,23,16,3],
[96,23,16,4],
[96,23,16,5],
[96,23,16,6],
[96,23,16,7],
[96,23,16,8],
[96,23,16,9],
[96,23,16,10],
[96,23,16,11],
[96,23,16,12],
[96,23,16,15],
[96,23,17,0],
[96,23,17,1],
[96,23,17,2],
[96,23,17,3],
[96,23,17,4],
[96,23,17,5],
[96,23,17,6],
[96,23,17,7],
[96,23,17,8],
[96,23,17,9],
[96,23,17,10],
[96,23,17,11],
[96,23,17,12],
[96,23,17,15],
[96,23,17,16],
[96,23,18,0],
[96,23,18,1],
[96,23,18,2],
[96,23,18,3],
[96,23,18,4],
[96,23,18,5],
[96,23,18,6],
[96,23,18,7],
[96,23,18,8],
[96,23,18,10],
[96,23,18,11],
[96,23,18,12],
[96,23,20,0],
[96,23,20,1],
[96,23,20,2],
[96,23,20,3],
[96,23,20,4],
[96,23,20,5],
[96,23,20,6],
[96,23,20,7],
[96,23,20,8],
[96,23,20,9],
[96,23,20,11],
[96,23,20,12],
[96,23,21,0],
[96,23,21,1],
[96,23,21,2],
[96,23,21,4],
[96,23,21,7],
[96,23,21,9],
[96,23,21,10],
[96,23,21,11],
[96,23,22,0],
[96,23,22,1],
[96,23,22,2],
[96,23,22,3],
[96,23,22,4],
[96,23,22,5],
[96,23,22,6],
[96,23,22,7],
[96,23,22,8],
[96,23,22,9],
[96,24,3,0],
[96,24,3,1],
[96,24,3,2],
[96,24,4,0],
[96,24,4,1],
[96,24,4,2],
[96,24,4,3],
[96,24,5,0],
[96,24,5,1],
[96,24,5,2],
[96,24,5,4],
[96,24,6,0],
[96,24,6,1],
[96,24,6,2],
[96,24,6,4],
[96,24,7,0],
[96,24,7,1],
[96,24,7,2],
[96,24,7,3],
[96,24,7,4],
[96,24,7,5],
[96,24,8,0],
[96,24,8,1],
[96,24,8,2],
[96,24,8,4],
[96,24,8,7],
[96,24,9,0],
[96,24,9,1],
[96,24,9,2],
[96,24,9,3],
[96,24,9,4],
[96,24,9,6],
[96,24,9,7],
[96,24,9,8],
[96,24,10,0],
[96,24,10,1],
[96,24,10,2],
[96,24,10,5],
[96,24,10,6],
[96,24,10,7],
[96,24,10,8],
[96,24,10,9],
[96,24,11,0],
[96,24,11,1],
[96,24,11,2],
[96,24,11,5],
[96,24,11,6],
[96,24,11,7],
[96,24,11,8],
[96,24,11,9],
[96,24,11,10],
[96,24,12,3],
[96,24,12,4],
[96,24,12,5],
[96,24,12,6],
[96,24,12,7],
[96,24,12,8],
[96,24,12,9],
[96,24,12,10],
[96,24,15,0],
[96,24,15,1],
[96,24,15,2],
[96,24,15,3],
[96,24,15,4],
[96,24,15,5],
[96,24,15,6],
[96,24,15,7],
[96,24,15,8],
[96,24,15,10],
[96,24,15,11],
[96,24,15,12],
[96,24,17,0],
[96,24,17,1],
[96,24,17,2],
[96,24,17,3],
[96,24,17,4],
[96,24,17,5],
[96,24,17,6],
[96,24,17,7],
[96,24,17,8],
[96,24,17,9],
[96,24,17,10],
[96,24,17,11],
[96,24,17,12],
[96,24,17,15],
[96,24,18,0],
[96,24,18,1],
[96,24,18,2],
[96,24,18,3],
[96,24,18,4],
[96,24,18,5],
[96,24,18,6],
[96,24,18,7],
[96,24,18,8],
[96,24,18,10],
[96,24,18,11],
[96,24,18,12],
[96,24,20,0],
[96,24,20,1],
[96,24,20,2],
[96,24,20,3],
[96,24,20,4],
[96,24,20,5],
[96,24,20,6],
[96,24,20,7],
[96,24,20,8],
[96,24,20,9],
[96,24,20,11],
[96,24,20,12],
[96,24,21,0],
[96,24,21,1],
[96,24,21,2],
[96,24,21,4],
[96,24,21,7],
[96,24,21,9],
[96,24,21,10],
[96,24,21,11],
[96,24,22,0],
[96,24,22,1],
[96,24,22,2],
[96,24,22,3],
[96,24,22,4],
[96,24,22,5],
[96,24,22,6],
[96,24,22,7],
[96,24,22,8],
[96,24,22,9],
[96,24,23,0],
[96,24,23,1],
[96,24,23,2],
[96,24,23,3],
[96,24,23,4],
[96,24,23,5],
[96,24,23,6],
[96,24,23,7],
[96,25,3,0],
[96,25,3,1],
[96,25,3,2],
[96,25,4,0],
[96,25,4,1],
[96,25,4,2],
[96,25,4,3],
[96,25,5,0],
[96,25,5,1],
[96,25,5,2],
[96,25,5,4],
[96,25,6,0],
[96,25,6,1],
[96,25,6,2],
[96,25,8,4],
[96,25,9,3],
[96,25,9,4],
[96,25,9,5],
[96,25,9,6],
[96,25,9,8],
[96,25,10,0],
[96,25,10,1],
[96,25,10,2],
[96,25,10,3],
[96,25,10,4],
[96,25,10,5],
[96,25,10,6],
[96,25,10,8],
[96,25,10,9],
[96,25,11,0],
[96,25,11,1],
[96,25,11,2],
[96,25,11,3],
[96,25,11,4],
[96,25,11,5],
[96,25,11,6],
[96,25,11,8],
[96,25,11,9],
[96,25,11,10],
[96,25,12,0],
[96,25,12,1],
[96,25,12,2],
[96,25,12,3],
[96,25,12,4],
[96,25,12,5],
[96,25,12,6],
[96,25,12,8],
[96,25,12,9],
[96,25,12,10],
[96,25,15,0],
[96,25,15,1],
[96,25,15,2],
[96,25,15,3],
[96,25,15,4],
[96,25,15,5],
[96,25,15,6],
[96,25,15,8],
[96,25,15,10],
[96,25,15,11],
[96,25,15,12],
[96,25,16,0],
[96,25,16,1],
[96,25,16,2],
[96,25,16,3],
[96,25,16,4],
[96,25,16,5],
[96,25,16,6],
[96,25,16,8],
[96,25,16,9],
[96,25,16,10],
[96,25,16,11],
[96,25,16,12],
[96,25,17,0],
[96,25,17,1],
[96,25,17,2],
[96,25,17,3],
[96,25,17,4],
[96,25,17,5],
[96,25,17,6],
[96,25,17,8],
[96,25,17,9],
[96,25,17,10],
[96,25,17,11],
[96,25,17,12],
[96,25,18,0],
[96,25,18,1],
[96,25,18,2],
[96,25,18,3],
[96,25,18,4],
[96,25,18,5],
[96,25,18,6],
[96,25,18,8],
[96,25,18,10],
[96,25,18,11],
[96,25,18,12],
[96,25,20,0],
[96,25,20,1],
[96,25,20,2],
[96,25,20,3],
[96,25,20,4],
[96,25,20,5],
[96,25,20,6],
[96,25,20,8],
[96,25,20,9],
[96,25,21,0],
[96,25,21,1],
[96,25,21,2],
[96,25,21,4],
[96,25,22,0],
[96,25,22,1],
[96,25,22,2],
[96,25,22,3],
[96,25,22,4],
[96,25,22,5],
[96,25,23,0],
[96,25,23,1],
[96,25,23,2],
[96,25,23,3],
[96,25,23,4],
[96,25,24,0],
[96,25,24,1],
[96,25,24,2],
[96,25,24,3],
[96,25,24,4],
[96,26,3,0],
[96,26,3,1],
[96,26,3,2],
[96,26,4,0],
[96,26,4,1],
[96,26,4,2],
[96,26,4,3],
[96,26,5,0],
[96,26,5,1],
[96,26,5,2],
[96,26,6,4],
[96,26,7,3],
[96,26,7,4],
[96,26,7,5],
[96,26,7,6],
[96,26,8,0],
[96,26,8,1],
[96,26,8,2],
[96,26,8,4],
[96,26,8,7],
[96,26,10,0],
[96,26,10,1],
[96,26,10,2],
[96,26,10,3],
[96,26,10,4],
[96,26,10,5],
[96,26,10,6],
[96,26,10,7],
[96,26,10,8],
[96,26,11,0],
[96,26,11,1],
[96,26,11,2],
[96,26,11,3],
[96,26,11,4],
[96,26,11,5],
[96,26,11,6],
[96,26,11,7],
[96,26,11,8],
[96,26,11,10],
[96,26,12,0],
[96,26,12,1],
[96,26,12,2],
[96,26,12,3],
[96,26,12,4],
[96,26,12,5],
[96,26,12,6],
[96,26,12,7],
[96,26,12,8],
[96,26,12,10],
[96,26,16,0],
[96,26,16,1],
[96,26,16,2],
[96,26,16,3],
[96,26,16,4],
[96,26,16,5],
[96,26,16,6],
[96,26,16,7],
[96,26,16,8],
[96,26,16,10],
[96,26,16,11],
[96,26,16,12],
[96,26,17,0],
[96,26,17,1],
[96,26,17,2],
[96,26,17,3],
[96,26,17,4],
[96,26,17,5],
[96,26,17,6],
[96,26,17,7],
[96,26,17,8],
[96,26,17,10],
[96,26,17,11],
[96,26,17,12],
[96,26,20,0],
[96,26,20,1],
[96,26,20,2],
[96,26,20,3],
[96,26,20,4],
[96,26,20,5],
[96,26,20,6],
[96,26,20,7],
[96,26,21,0],
[96,26,21,1],
[96,26,21,2],
[96,26,21,4],
[96,26,22,0],
[96,26,22,1],
[96,26,22,2],
[96,26,22,3],
[96,26,22,4],
[96,26,23,0],
[96,26,23,1],
[96,26,23,2],
[96,26,24,0],
[96,26,24,1],
[96,26,24,2],
[96,27,3,0],
[96,27,3,1],
[96,27,3,2],
[96,27,4,0],
[96,27,4,1],
[96,27,4,2],
[96,27,5,4],
[96,27,6,0],
[96,27,6,1],
[96,27,6,2],
[96,27,6,4],
[96,27,7,0],
[96,27,7,1],
[96,27,7,2],
[96,27,7,3],
[96,27,7,4],
[96,27,7,5],
[96,27,7,6],
[96,27,8,0],
[96,27,8,1],
[96,27,8,2],
[96,27,8,4],
[96,27,8,7],
[96,27,9,0],
[96,27,9,1],
[96,27,9,2],
[96,27,9,3],
[96,27,9,4],
[96,27,9,5],
[96,27,9,6],
[96,27,9,7],
[96,27,9,8],
[96,27,11,0],
[96,27,11,1],
[96,27,11,2],
[96,27,11,3],
[96,27,11,4],
[96,27,11,5],
[96,27,11,6],
[96,27,11,7],
[96,27,11,8],
[96,27,11,9],
[96,27,12,0],
[96,27,12,1],
[96,27,12,2],
[96,27,12,3],
[96,27,12,4],
[96,27,12,5],
[96,27,12,6],
[96,27,12,7],
[96,27,12,8],
[96,27,12,9],
[96,27,15,0],
[96,27,15,1],
[96,27,15,2],
[96,27,15,3],
[96,27,15,4],
[96,27,15,5],
[96,27,15,6],
[96,27,15,7],
[96,27,15,8],
[96,27,15,11],
[96,27,15,12],
[96,27,16,0],
[96,27,16,1],
[96,27,16,2],
[96,27,16,3],
[96,27,16,4],
[96,27,16,5],
[96,27,16,6],
[96,27,16,7],
[96,27,16,8],
[96,27,16,9],
[96,27,16,11],
[96,27,17,0],
[96,27,17,1],
[96,27,17,2],
[96,27,17,3],
[96,27,17,4],
[96,27,17,5],
[96,27,17,6],
[96,27,17,7],
[96,27,17,8],
[96,27,17,9],
[96,27,17,11],
[96,27,18,0],
[96,27,18,1],
[96,27,18,2],
[96,27,18,3],
[96,27,18,4],
[96,27,18,5],
[96,27,18,6],
[96,27,18,7],
[96,27,18,8],
[96,27,21,0],
[96,27,21,1],
[96,27,21,2],
[96,27,21,4],
[96,28,4,3],
[96,28,5,0],
[96,28,5,1],
[96,28,5,2],
[96,28,5,4],
[96,28,6,0],
[96,28,6,1],
[96,28,6,2],
[96,28,6,4],
[96,28,8,0],
[96,28,8,1],
[96,28,8,2],
[96,28,8,4],
[96,28,9,0],
[96,28,9,1],
[96,28,9,2],
[96,28,9,3],
[96,28,9,4],
[96,28,9,5],
[96,28,9,6],
[96,28,9,8],
[96,28,10,0],
[96,28,10,1],
[96,28,10,2],
[96,28,10,3],
[96,28,10,4],
[96,28,10,5],
[96,28,10,6],
[96,28,10,8],
[96,28,10,9],
[96,28,11,0],
[96,28,11,1],
[96,28,11,2],
[96,28,11,3],
[96,28,11,4],
[96,28,11,5],
[96,28,11,6],
[96,28,11,8],
[96,28,11,9],
[96,28,11,10],
[96,28,12,0],
[96,28,12,1],
[96,28,12,2],
[96,28,12,3],
[96,28,12,4],
[96,28,12,5],
[96,28,12,6],
[96,28,12,8],
[96,28,12,9],
[96,28,12,10],
[96,28,15,0],
[96,28,15,1],
[96,28,15,2],
[96,28,15,3],
[96,28,15,4],
[96,28,15,5],
[96,28,15,6],
[96,28,15,8],
[96,28,15,10],
[96,28,15,11],
[96,28,16,0],
[96,28,16,1],
[96,28,16,2],
[96,28,16,3],
[96,28,16,4],
[96,28,16,5],
[96,28,16,6],
[96,28,16,8],
[96,28,16,9],
[96,28,17,0],
[96,28,17,1],
[96,28,17,2],
[96,28,17,3],
[96,28,17,4],
[96,28,17,5],
[96,28,17,6],
[96,28,17,8],
[96,28,17,9],
[96,28,18,0],
[96,28,18,1],
[96,28,18,2],
[96,28,18,3],
[96,28,18,4],
[96,28,18,5],
[96,28,18,6],
[96,28,20,0],
[96,28,20,1],
[96,28,20,2],
[96,28,20,3],
[96,28,20,4],
[96,28,21,0],
[96,28,21,1],
[96,28,21,2],
[96,29,3,0],
[96,29,3,1],
[96,29,3,2],
[96,29,4,0],
[96,29,4,1],
[96,29,4,2],
[96,29,4,3],
[96,29,5,0],
[96,29,5,1],
[96,29,5,2],
[96,29,5,4],
[96,29,6,0],
[96,29,6,1],
[96,29,6,2],
[96,29,6,4],
[96,29,7,0],
[96,29,7,1],
[96,29,7,2],
[96,29,7,3],
[96,29,7,4],
[96,29,7,5],
[96,29,7,6],
[96,29,8,0],
[96,29,8,1],
[96,29,8,2],
[96,29,8,4],
[96,29,8,7],
[96,29,9,0],
[96,29,9,1],
[96,29,9,2],
[96,29,9,3],
[96,29,9,4],
[96,29,9,5],
[96,29,9,6],
[96,29,9,7],
[96,29,9,8],
[96,29,10,0],
[96,29,10,1],
[96,29,10,2],
[96,29,10,3],
[96,29,10,4],
[96,29,10,5],
[96,29,10,6],
[96,29,10,7],
[96,29,10,8],
[96,29,10,9],
[96,29,15,0],
[96,29,15,1],
[96,29,15,2],
[96,29,15,3],
[96,29,15,4],
[96,29,15,5],
[96,29,15,6],
[96,29,15,7],
[96,29,15,8],
[96,29,16,0],
[96,29,16,1],
[96,29,16,2],
[96,29,16,3],
[96,29,16,4],
[96,29,16,5],
[96,29,16,6],
[96,29,16,7],
[96,29,17,0],
[96,29,17,1],
[96,29,17,2],
[96,29,17,3],
[96,29,17,4],
[96,29,17,5],
[96,29,17,6],
[96,29,17,7],
[96,29,18,0],
[96,29,18,1],
[96,29,18,2],
[96,29,18,3],
[96,29,18,4],
[96,29,18,5],
[96,29,20,0],
[96,29,20,1],
[96,29,20,2],
[96,30,3,0],
[96,30,3,1],
[96,30,3,2],
[96,30,4,0],
[96,30,4,1],
[96,30,4,2],
[96,30,4,3],
[96,30,5,0],
[96,30,5,1],
[96,30,5,2],
[96,30,5,4],
[96,30,6,0],
[96,30,6,1],
[96,30,6,2],
[96,30,6,4],
[96,30,7,0],
[96,30,7,1],
[96,30,7,2],
[96,30,7,3],
[96,30,7,4],
[96,30,7,5],
[96,30,7,6],
[96,30,8,0],
[96,30,8,1],
[96,30,8,2],
[96,30,8,4],
[96,30,8,7],
[96,30,9,0],
[96,30,9,1],
[96,30,9,2],
[96,30,9,3],
[96,30,9,4],
[96,30,9,5],
[96,30,9,6],
[96,30,9,7],
[96,30,9,8],
[96,30,10,0],
[96,30,10,1],
[96,30,10,2],
[96,30,10,3],
[96,30,10,4],
[96,30,10,5],
[96,30,10,6],
[96,30,10,7],
[96,30,10,8],
[96,30,10,9],
[96,30,11,0],
[96,30,11,1],
[96,30,11,2],
[96,30,11,3],
[96,30,11,4],
[96,30,11,5],
[96,30,11,6],
[96,30,11,7],
[96,30,11,8],
[96,30,11,9],
[96,30,11,10],
[96,30,12,0],
[96,30,12,1],
[96,30,12,2],
[96,30,12,3],
[96,30,12,4],
[96,30,12,5],
[96,30,12,6],
[96,30,12,7],
[96,30,12,8],
[96,30,12,9],
[96,30,12,10],
[96,30,15,0],
[96,30,15,1],
[96,30,15,2],
[96,30,15,3],
[96,30,15,4],
[96,30,15,5],
[96,30,15,6],
[96,30,15,7],
[96,30,16,0],
[96,30,16,1],
[96,30,16,2],
[96,30,16,3],
[96,30,16,4],
[96,30,16,5],
[96,30,17,0],
[96,30,17,1],
[96,30,17,2],
[96,30,17,3],
[96,30,17,4],
[96,30,17,5],
[96,30,18,0],
[96,30,18,1],
[96,30,18,2],
[96,30,18,3],
[96,30,18,4],
[96,31,3,0],
[96,31,3,1],
[96,31,3,2],
[96,31,4,0],
[96,31,4,1],
[96,31,4,2],
[96,31,4,3],
[96,31,5,0],
[96,31,5,1],
[96,31,5,2],
[96,31,5,4],
[96,31,6,0],
[96,31,6,1],
[96,31,6,2],
[96,31,6,4],
[96,31,7,0],
[96,31,7,1],
[96,31,7,2],
[96,31,7,3],
[96,31,7,4],
[96,31,7,5],
[96,31,7,6],
[96,31,8,0],
[96,31,8,1],
[96,31,8,2],
[96,31,8,4],
[96,31,8,7],
[96,31,9,0],
[96,31,9,1],
[96,31,9,2],
[96,31,9,3],
[96,31,9,4],
[96,31,9,5],
[96,31,9,6],
[96,31,9,7],
[96,31,9,8],
[96,31,10,0],
[96,31,10,1],
[96,31,10,2],
[96,31,10,3],
[96,31,10,4],
[96,31,10,5],
[96,31,10,6],
[96,31,10,7],
[96,31,10,8],
[96,31,10,9],
[96,31,11,0],
[96,31,11,1],
[96,31,11,2],
[96,31,11,3],
[96,31,11,4],
[96,31,11,5],
[96,31,11,6],
[96,31,11,7],
[96,31,11,8],
[96,31,11,9],
[96,31,11,10],
[96,31,12,0],
[96,31,12,1],
[96,31,12,2],
[96,31,12,3],
[96,31,12,4],
[96,31,12,5],
[96,31,12,6],
[96,31,12,7],
[96,31,12,8],
[96,31,12,9],
[96,31,15,0],
[96,31,15,1],
[96,31,15,2],
[96,31,15,3],
[96,31,15,4],
[96,31,15,5],
[96,31,16,0],
[96,31,16,1],
[96,31,16,2],
[96,31,16,3],
[96,31,16,4],
[96,31,17,0],
[96,31,17,1],
[96,31,17,2],
[96,31,17,3],
[96,31,17,4],
[96,31,18,0],
[96,31,18,1],
[96,31,18,2],
[96,32,3,0],
[96,32,3,1],
[96,32,3,2],
[96,32,4,0],
[96,32,4,1],
[96,32,4,2],
[96,32,4,3],
[96,32,5,0],
[96,32,5,1],
[96,32,5,2],
[96,32,5,4],
[96,32,6,0],
[96,32,6,1],
[96,32,6,2],
[96,32,6,4],
[96,32,7,0],
[96,32,7,1],
[96,32,7,2],
[96,32,7,3],
[96,32,7,4],
[96,32,7,5],
[96,32,7,6],
[96,32,8,0],
[96,32,8,1],
[96,32,8,2],
[96,32,8,4],
[96,32,8,7],
[96,32,9,0],
[96,32,9,1],
[96,32,9,2],
[96,32,9,3],
[96,32,9,4],
[96,32,9,5],
[96,32,9,6],
[96,32,9,7],
[96,32,9,8],
[96,32,10,0],
[96,32,10,1],
[96,32,10,2],
[96,32,10,3],
[96,32,10,4],
[96,32,10,5],
[96,32,10,6],
[96,32,10,7],
[96,32,10,8],
[96,32,10,9],
[96,32,15,0],
[96,32,15,1],
[96,32,15,2],
[96,32,15,3],
[96,32,15,4],
[96,32,16,0],
[96,32,16,1],
[96,32,16,2],
[96,32,17,0],
[96,32,17,1],
[96,32,17,2],
[96,33,3,0],
[96,33,3,1],
[96,33,3,2],
[96,33,4,0],
[96,33,4,1],
[96,33,4,2],
[96,33,4,3],
[96,33,5,0],
[96,33,5,1],
[96,33,5,2],
[96,33,5,4],
[96,33,6,0],
[96,33,6,1],
[96,33,6,2],
[96,33,6,4],
[96,33,7,0],
[96,33,7,1],
[96,33,7,2],
[96,33,7,3],
[96,33,7,4],
[96,33,7,5],
[96,33,7,6],
[96,33,8,0],
[96,33,8,1],
[96,33,8,2],
[96,33,8,4],
[96,33,8,7],
[96,33,9,0],
[96,33,9,1],
[96,33,9,2],
[96,33,9,3],
[96,33,9,4],
[96,33,9,5],
[96,33,9,6],
[96,33,9,7],
[96,33,9,8],
[96,33,10,0],
[96,33,10,1],
[96,33,10,2],
[96,33,10,3],
[96,33,10,4],
[96,33,10,5],
[96,33,10,6],
[96,33,10,7],
[96,33,15,0],
[96,33,15,1],
[96,33,15,2],
[96,34,3,0],
[96,34,3,1],
[96,34,3,2],
[96,34,4,0],
[96,34,4,1],
[96,34,4,2],
[96,34,4,3],
[96,34,5,0],
[96,34,5,1],
[96,34,5,2],
[96,34,5,4],
[96,34,6,0],
[96,34,6,1],
[96,34,6,2],
[96,34,6,4],
[96,34,8,0],
[96,34,8,1],
[96,34,8,2],
[96,34,8,4],
[96,34,9,0],
[96,34,9,1],
[96,34,9,2],
[96,34,9,3],
[96,34,9,4],
[96,34,9,5],
[96,34,9,6],
[96,34,10,0],
[96,34,10,1],
[96,34,10,2],
[96,34,10,3],
[96,34,10,4],
[96,34,10,5],
[96,34,11,0],
[96,34,11,1],
[96,34,11,2],
[96,34,11,3],
[96,34,11,4],
[96,34,11,5],
[96,34,12,0],
[96,34,12,1],
[96,34,12,2],
[96,34,12,3],
[96,34,12,4],
[96,35,3,0],
[96,35,3,1],
[96,35,3,2],
[96,35,4,0],
[96,35,4,1],
[96,35,4,2],
[96,35,4,3],
[96,35,5,0],
[96,35,5,1],
[96,35,5,2],
[96,35,5,4],
[96,35,6,0],
[96,35,6,1],
[96,35,6,2],
[96,35,6,4],
[96,35,7,0],
[96,35,7,1],
[96,35,7,2],
[96,35,7,3],
[96,35,7,4],
[96,35,7,5],
[96,35,7,6],
[96,35,8,0],
[96,35,8,1],
[96,35,8,2],
[96,35,8,4],
[96,35,10,0],
[96,35,10,1],
[96,35,10,2],
[96,35,10,3],
[96,35,10,4],
[96,35,11,0],
[96,35,11,1],
[96,35,11,2],
[96,35,11,3],
[96,35,11,4],
[96,35,12,0],
[96,35,12,1],
[96,35,12,2],
[96,37,3,0],
[96,37,3,1],
[96,37,3,2],
[96,37,5,0],
[96,37,5,1],
[96,37,5,2],
[96,37,6,0],
[96,37,6,1],
[96,37,6,2],
[96,37,7,0],
[96,37,7,1],
[96,37,7,2],
[96,37,7,3],
[96,37,8,0],
[96,37,8,1],
[96,37,8,2],
[96,37,9,0],
[96,37,9,1],
[96,37,9,2],
[96,38,3,0],
[96,38,3,1],
[96,38,3,2],
[96,38,4,0],
[96,38,4,1],
[96,38,4,2],
[96,38,4,3],
[96,38,5,0],
[96,38,5,1],
[96,38,5,2],
[96,38,5,4],
[96,38,6,0],
[96,38,6,1],
[96,38,6,2],
[96,39,4,0],
[96,39,4,1],
[96,39,4,2],
[96,40,3,0],
[96,40,3,1],
[96,40,3,2],
[96,40,4,0],
[96,40,4,1],
[96,40,4,2],
[96,41,3,0],
[96,41,3,1],
[96,41,3,2],
[96,41,4,0],
[96,41,4,1],
[96,41,4,2],
[97,4,3,0],
[97,4,3,1],
[97,4,3,2],
[97,5,4,0],
[97,5,4,1],
[97,5,4,2],
[97,6,4,0],
[97,6,4,1],
[97,6,4,2],
[97,8,4,0],
[97,8,4,1],
[97,8,4,2],
[97,9,3,0],
[97,9,3,1],
[97,9,3,2],
[97,9,4,0],
[97,9,4,1],
[97,9,4,2],
[97,9,4,3],
[97,9,5,0],
[97,9,5,1],
[97,9,5,2],
[97,9,5,4],
[97,9,6,0],
[97,9,6,1],
[97,9,6,2],
[97,9,6,4],
[97,9,8,0],
[97,9,8,1],
[97,9,8,2],
[97,9,8,4],
[97,10,3,0],
[97,10,3,1],
[97,10,3,2],
[97,10,4,0],
[97,10,4,1],
[97,10,4,2],
[97,10,4,3],
[97,10,5,0],
[97,10,5,1],
[97,10,5,2],
[97,10,5,4],
[97,10,6,0],
[97,10,6,1],
[97,10,6,2],
[97,10,6,4],
[97,10,8,0],
[97,10,8,1],
[97,10,8,2],
[97,10,8,4],
[97,10,9,0],
[97,10,9,1],
[97,10,9,2],
[97,10,9,3],
[97,10,9,4],
[97,10,9,5],
[97,10,9,6],
[97,10,9,8],
[97,11,3,0],
[97,11,3,1],
[97,11,3,2],
[97,11,4,0],
[97,11,4,1],
[97,11,4,2],
[97,11,4,3],
[97,11,5,0],
[97,11,5,1],
[97,11,5,2],
[97,11,5,4],
[97,11,6,0],
[97,11,6,1],
[97,11,6,2],
[97,11,6,4],
[97,11,8,0],
[97,11,8,1],
[97,11,8,2],
[97,11,8,4],
[97,11,9,0],
[97,11,9,1],
[97,11,9,2],
[97,11,9,3],
[97,11,9,4],
[97,11,9,5],
[97,11,9,6],
[97,11,9,8],
[97,11,10,0],
[97,11,10,1],
[97,11,10,2],
[97,11,10,3],
[97,11,10,4],
[97,11,10,5],
[97,11,10,6],
[97,11,10,8],
[97,11,10,9],
[97,12,3,0],
[97,12,3,1],
[97,12,3,2],
[97,12,4,0],
[97,12,4,1],
[97,12,4,2],
[97,12,4,3],
[97,12,5,0],
[97,12,5,1],
[97,12,5,2],
[97,12,5,4],
[97,12,6,0],
[97,12,6,1],
[97,12,6,2],
[97,12,6,4],
[97,12,8,0],
[97,12,8,1],
[97,12,8,2],
[97,12,8,4],
[97,12,9,0],
[97,12,9,1],
[97,12,9,2],
[97,12,9,3],
[97,12,9,4],
[97,12,9,5],
[97,12,9,6],
[97,12,9,8],
[97,12,10,0],
[97,12,10,1],
[97,12,10,2],
[97,12,10,3],
[97,12,10,4],
[97,12,10,5],
[97,12,10,6],
[97,12,10,8],
[97,12,10,9],
[97,13,3,0],
[97,13,3,1],
[97,13,3,2],
[97,13,4,0],
[97,13,4,1],
[97,13,4,2],
[97,13,4,3],
[97,13,5,0],
[97,13,5,1],
[97,13,5,2],
[97,13,5,4],
[97,13,6,0],
[97,13,6,1],
[97,13,6,2],
[97,13,6,4],
[97,13,8,0],
[97,13,8,1],
[97,13,8,2],
[97,13,8,4],
[97,13,9,0],
[97,13,9,1],
[97,13,9,2],
[97,13,9,3],
[97,13,9,4],
[97,13,9,5],
[97,13,9,6],
[97,13,9,8],
[97,13,10,0],
[97,13,10,1],
[97,13,10,2],
[97,13,10,3],
[97,13,10,4],
[97,13,10,5],
[97,13,10,6],
[97,13,10,8],
[97,13,10,9],
[97,13,11,0],
[97,13,11,1],
[97,13,11,2],
[97,13,11,3],
[97,13,11,4],
[97,13,11,5],
[97,13,11,6],
[97,13,11,8],
[97,13,11,9],
[97,13,11,10],
[97,13,12,0],
[97,13,12,1],
[97,13,12,2],
[97,13,12,3],
[97,13,12,4],
[97,13,12,5],
[97,13,12,6],
[97,13,12,8],
[97,13,12,9],
[97,13,12,10],
[97,14,3,0],
[97,14,3,1],
[97,14,3,2],
[97,14,4,0],
[97,14,4,1],
[97,14,4,2],
[97,14,4,3],
[97,14,5,0],
[97,14,5,1],
[97,14,5,2],
[97,14,5,4],
[97,14,6,0],
[97,14,6,1],
[97,14,6,2],
[97,14,6,4],
[97,14,8,0],
[97,14,8,1],
[97,14,8,2],
[97,14,8,4],
[97,14,9,0],
[97,14,9,1],
[97,14,9,2],
[97,14,9,3],
[97,14,9,4],
[97,14,9,5],
[97,14,9,6],
[97,14,9,8],
[97,14,10,0],
[97,14,10,1],
[97,14,10,2],
[97,14,10,3],
[97,14,10,4],
[97,14,10,5],
[97,14,10,6],
[97,14,10,8],
[97,14,10,9],
[97,14,11,0],
[97,14,11,1],
[97,14,11,2],
[97,14,11,3],
[97,14,11,4],
[97,14,11,5],
[97,14,11,6],
[97,14,11,8],
[97,14,11,9],
[97,14,11,10],
[97,14,12,0],
[97,14,12,1],
[97,14,12,2],
[97,14,12,3],
[97,14,12,4],
[97,14,12,5],
[97,14,12,6],
[97,14,12,8],
[97,14,12,9],
[97,14,12,10],
[97,15,3,0],
[97,15,3,1],
[97,15,3,2],
[97,15,4,0],
[97,15,4,1],
[97,15,4,2],
[97,15,4,3],
[97,15,5,0],
[97,15,5,1],
[97,15,5,2],
[97,15,5,4],
[97,15,6,0],
[97,15,6,1],
[97,15,6,2],
[97,15,6,4],
[97,15,8,0],
[97,15,8,1],
[97,15,8,2],
[97,15,8,4],
[97,15,10,0],
[97,15,10,1],
[97,15,10,2],
[97,15,10,3],
[97,15,10,4],
[97,15,10,5],
[97,15,10,6],
[97,15,10,8],
[97,15,11,0],
[97,15,11,1],
[97,15,11,2],
[97,15,11,3],
[97,15,11,4],
[97,15,11,5],
[97,15,11,6],
[97,15,11,8],
[97,15,11,10],
[97,15,12,0],
[97,15,12,1],
[97,15,12,2],
[97,15,12,3],
[97,15,12,4],
[97,15,12,5],
[97,15,12,6],
[97,15,12,8],
[97,15,12,10],
[97,15,13,0],
[97,15,13,1],
[97,15,13,2],
[97,15,13,3],
[97,15,13,4],
[97,15,13,5],
[97,15,13,6],
[97,15,13,8],
[97,15,13,10],
[97,15,13,11],
[97,15,14,0],
[97,15,14,1],
[97,15,14,2],
[97,15,14,3],
[97,15,14,4],
[97,15,14,5],
[97,15,14,6],
[97,15,14,8],
[97,15,14,12],
[97,16,3,0],
[97,16,3,1],
[97,16,3,2],
[97,16,4,0],
[97,16,4,1],
[97,16,4,2],
[97,16,4,3],
[97,16,5,0],
[97,16,5,1],
[97,16,5,2],
[97,16,5,4],
[97,16,6,0],
[97,16,6,1],
[97,16,6,2],
[97,16,6,4],
[97,16,8,0],
[97,16,8,1],
[97,16,8,2],
[97,16,8,4],
[97,16,9,0],
[97,16,9,1],
[97,16,9,2],
[97,16,9,3],
[97,16,9,4],
[97,16,9,5],
[97,16,9,6],
[97,16,9,8],
[97,16,10,0],
[97,16,10,1],
[97,16,10,2],
[97,16,10,3],
[97,16,10,4],
[97,16,10,5],
[97,16,10,6],
[97,16,10,8],
[97,16,10,9],
[97,16,11,0],
[97,16,11,1],
[97,16,11,2],
[97,16,11,3],
[97,16,11,4],
[97,16,11,5],
[97,16,11,6],
[97,16,11,8],
[97,16,11,9],
[97,16,11,10],
[97,16,12,0],
[97,16,12,1],
[97,16,12,2],
[97,16,12,3],
[97,16,12,4],
[97,16,12,5],
[97,16,12,6],
[97,16,12,8],
[97,16,12,9],
[97,16,13,0],
[97,16,13,1],
[97,16,13,2],
[97,16,13,3],
[97,16,13,4],
[97,16,13,5],
[97,16,13,6],
[97,16,13,8],
[97,16,13,9],
[97,16,13,12],
[97,16,14,0],
[97,16,14,1],
[97,16,14,2],
[97,16,14,3],
[97,16,14,4],
[97,16,14,5],
[97,16,14,6],
[97,16,14,10],
[97,16,14,11],
[97,16,14,12],
[97,16,15,0],
[97,16,15,1],
[97,16,15,2],
[97,16,15,3],
[97,16,15,4],
[97,16,15,5],
[97,16,15,8],
[97,16,15,10],
[97,16,15,11],
[97,16,15,12],
[97,16,15,13],
[97,16,15,14],
[97,17,3,0],
[97,17,3,1],
[97,17,3,2],
[97,17,4,0],
[97,17,4,1],
[97,17,4,2],
[97,17,4,3],
[97,17,5,0],
[97,17,5,1],
[97,17,5,2],
[97,17,5,4],
[97,17,6,0],
[97,17,6,1],
[97,17,6,2],
[97,17,6,4],
[97,17,8,0],
[97,17,8,1],
[97,17,8,2],
[97,17,8,4],
[97,17,9,0],
[97,17,9,1],
[97,17,9,2],
[97,17,9,3],
[97,17,9,4],
[97,17,9,5],
[97,17,9,6],
[97,17,9,8],
[97,17,10,0],
[97,17,10,1],
[97,17,10,2],
[97,17,10,3],
[97,17,10,4],
[97,17,10,5],
[97,17,10,6],
[97,17,10,8],
[97,17,10,9],
[97,17,11,0],
[97,17,11,1],
[97,17,11,2],
[97,17,11,3],
[97,17,11,4],
[97,17,11,5],
[97,17,11,6],
[97,17,11,8],
[97,17,11,9],
[97,17,11,10],
[97,17,12,0],
[97,17,12,1],
[97,17,12,2],
[97,17,12,3],
[97,17,12,4],
[97,17,12,5],
[97,17,12,6],
[97,17,12,8],
[97,17,12,9],
[97,17,13,0],
[97,17,13,1],
[97,17,13,2],
[97,17,13,3],
[97,17,13,4],
[97,17,13,5],
[97,17,13,6],
[97,17,13,8],
[97,17,13,9],
[97,17,13,12],
[97,17,14,0],
[97,17,14,1],
[97,17,14,2],
[97,17,14,3],
[97,17,14,4],
[97,17,14,5],
[97,17,14,6],
[97,17,14,10],
[97,17,14,11],
[97,17,14,12],
[97,17,15,0],
[97,17,15,1],
[97,17,15,2],
[97,17,15,3],
[97,17,15,4],
[97,17,15,5],
[97,17,15,8],
[97,17,15,10],
[97,17,15,11],
[97,17,15,12],
[97,17,15,13],
[97,17,15,14],
[97,17,16,0],
[97,17,16,1],
[97,17,16,2],
[97,17,16,3],
[97,17,16,4],
[97,17,16,6],
[97,17,16,8],
[97,17,16,9],
[97,17,16,10],
[97,17,16,11],
[97,17,16,12],
[97,17,16,13],
[97,17,16,14],
[97,17,16,15],
[97,18,3,0],
[97,18,3,1],
[97,18,3,2],
[97,18,4,0],
[97,18,4,1],
[97,18,4,2],
[97,18,4,3],
[97,18,5,0],
[97,18,5,1],
[97,18,5,2],
[97,18,5,4],
[97,18,6,0],
[97,18,6,1],
[97,18,6,2],
[97,18,6,4],
[97,18,8,0],
[97,18,8,1],
[97,18,8,2],
[97,18,8,4],
[97,18,10,0],
[97,18,10,1],
[97,18,10,2],
[97,18,10,3],
[97,18,10,4],
[97,18,10,5],
[97,18,10,6],
[97,18,10,8],
[97,18,11,0],
[97,18,11,1],
[97,18,11,2],
[97,18,11,3],
[97,18,11,4],
[97,18,11,5],
[97,18,11,6],
[97,18,11,8],
[97,18,12,0],
[97,18,12,1],
[97,18,12,2],
[97,18,12,3],
[97,18,12,4],
[97,18,12,5],
[97,18,12,6],
[97,18,12,10],
[97,18,13,0],
[97,18,13,1],
[97,18,13,2],
[97,18,13,3],
[97,18,13,4],
[97,18,13,5],
[97,18,13,6],
[97,18,13,10],
[97,18,13,11],
[97,18,13,12],
[97,18,14,0],
[97,18,14,1],
[97,18,14,2],
[97,18,14,3],
[97,18,14,4],
[97,18,14,5],
[97,18,14,8],
[97,18,14,10],
[97,18,14,11],
[97,18,14,12],
[97,18,16,0],
[97,18,16,1],
[97,18,16,2],
[97,18,16,5],
[97,18,16,6],
[97,18,16,8],
[97,18,16,10],
[97,18,16,11],
[97,18,16,12],
[97,18,16,13],
[97,18,16,14],
[97,18,17,0],
[97,18,17,1],
[97,18,17,2],
[97,18,17,5],
[97,18,17,6],
[97,18,17,8],
[97,18,17,10],
[97,18,17,11],
[97,18,17,12],
[97,18,17,13],
[97,18,17,14],
[97,18,17,16],
[97,19,3,0],
[97,19,3,1],
[97,19,3,2],
[97,19,4,0],
[97,19,4,1],
[97,19,4,2],
[97,19,4,3],
[97,19,5,0],
[97,19,5,1],
[97,19,5,2],
[97,19,5,4],
[97,19,6,0],
[97,19,6,1],
[97,19,6,2],
[97,19,6,4],
[97,19,8,0],
[97,19,8,1],
[97,19,8,2],
[97,19,8,4],
[97,19,9,0],
[97,19,9,1],
[97,19,9,2],
[97,19,9,3],
[97,19,9,4],
[97,19,9,5],
[97,19,9,6],
[97,19,9,8],
[97,19,10,0],
[97,19,10,1],
[97,19,10,2],
[97,19,10,3],
[97,19,10,4],
[97,19,10,5],
[97,19,10,6],
[97,19,11,0],
[97,19,11,1],
[97,19,11,2],
[97,19,11,3],
[97,19,11,4],
[97,19,11,5],
[97,19,11,6],
[97,19,11,10],
[97,19,12,0],
[97,19,12,1],
[97,19,12,2],
[97,19,12,3],
[97,19,12,4],
[97,19,12,5],
[97,19,12,8],
[97,19,12,9],
[97,19,12,10],
[97,19,15,0],
[97,19,15,1],
[97,19,15,2],
[97,19,15,5],
[97,19,15,6],
[97,19,15,8],
[97,19,15,10],
[97,19,15,11],
[97,19,15,12],
[97,19,16,3],
[97,19,16,4],
[97,19,16,5],
[97,19,16,6],
[97,19,16,8],
[97,19,16,9],
[97,19,16,10],
[97,19,16,11],
[97,19,16,12],
[97,19,16,15],
[97,19,17,3],
[97,19,17,4],
[97,19,17,5],
[97,19,17,6],
[97,19,17,8],
[97,19,17,9],
[97,19,17,10],
[97,19,17,11],
[97,19,17,12],
[97,19,17,15],
[97,19,17,16],
[97,19,18,0],
[97,19,18,1],
[97,19,18,2],
[97,19,18,3],
[97,19,18,4],
[97,19,18,5],
[97,19,18,6],
[97,19,18,8],
[97,19,18,10],
[97,19,18,11],
[97,19,18,12],
[97,19,18,16],
[97,19,18,17],
[97,20,3,0],
[97,20,3,1],
[97,20,3,2],
[97,20,4,0],
[97,20,4,1],
[97,20,4,2],
[97,20,4,3],
[97,20,5,0],
[97,20,5,1],
[97,20,5,2],
[97,20,5,4],
[97,20,6,0],
[97,20,6,1],
[97,20,6,2],
[97,20,6,4],
[97,20,8,0],
[97,20,8,1],
[97,20,8,2],
[97,20,8,4],
[97,20,9,0],
[97,20,9,1],
[97,20,9,2],
[97,20,9,3],
[97,20,9,4],
[97,20,9,5],
[97,20,9,6],
[97,20,11,0],
[97,20,11,1],
[97,20,11,2],
[97,20,11,3],
[97,20,11,4],
[97,20,11,5],
[97,20,11,8],
[97,20,11,9],
[97,20,12,0],
[97,20,12,1],
[97,20,12,2],
[97,20,12,3],
[97,20,12,4],
[97,20,12,6],
[97,20,12,8],
[97,20,12,9],
[97,20,13,0],
[97,20,13,1],
[97,20,13,2],
[97,20,13,3],
[97,20,13,4],
[97,20,13,6],
[97,20,13,8],
[97,20,13,9],
[97,20,13,11],
[97,20,13,12],
[97,20,14,0],
[97,20,14,1],
[97,20,14,2],
[97,20,14,5],
[97,20,14,6],
[97,20,14,8],
[97,20,14,9],
[97,20,14,11],
[97,20,14,12],
[97,20,15,3],
[97,20,15,4],
[97,20,15,5],
[97,20,15,6],
[97,20,15,8],
[97,20,15,11],
[97,20,15,12],
[97,20,15,13],
[97,20,15,14],
[97,20,16,0],
[97,20,16,1],
[97,20,16,2],
[97,20,16,3],
[97,20,16,4],
[97,20,16,5],
[97,20,16,6],
[97,20,16,8],
[97,20,16,9],
[97,20,16,11],
[97,20,16,12],
[97,20,16,13],
[97,20,16,14],
[97,20,16,15],
[97,20,17,0],
[97,20,17,1],
[97,20,17,2],
[97,20,17,3],
[97,20,17,4],
[97,20,17,5],
[97,20,17,6],
[97,20,17,8],
[97,20,17,9],
[97,20,17,11],
[97,20,17,12],
[97,20,17,13],
[97,20,17,14],
[97,20,17,15],
[97,20,17,16],
[97,20,18,0],
[97,20,18,1],
[97,20,18,2],
[97,20,18,3],
[97,20,18,4],
[97,20,18,5],
[97,20,18,6],
[97,20,18,8],
[97,20,18,11],
[97,20,18,12],
[97,20,18,13],
[97,20,18,14],
[97,20,18,16],
[97,20,18,17],
[97,20,19,0],
[97,20,19,1],
[97,20,19,2],
[97,20,19,3],
[97,20,19,4],
[97,20,19,5],
[97,20,19,6],
[97,20,19,8],
[97,20,19,9],
[97,20,19,11],
[97,20,19,12],
[97,20,19,15],
[97,20,19,16],
[97,20,19,17],
[97,21,4,0],
[97,21,4,1],
[97,21,4,2],
[97,21,9,0],
[97,21,9,1],
[97,21,9,2],
[97,21,9,4],
[97,21,10,0],
[97,21,10,1],
[97,21,10,2],
[97,21,10,4],
[97,21,10,9],
[97,21,11,0],
[97,21,11,1],
[97,21,11,2],
[97,21,11,4],
[97,21,11,9],
[97,21,11,10],
[97,21,12,0],
[97,21,12,1],
[97,21,12,2],
[97,21,12,9],
[97,21,12,10],
[97,21,13,0],
[97,21,13,1],
[97,21,13,2],
[97,21,13,9],
[97,21,13,10],
[97,21,13,11],
[97,21,13,12],
[97,21,14,4],
[97,21,14,9],
[97,21,14,10],
[97,21,14,11],
[97,21,14,12],
[97,21,15,0],
[97,21,15,1],
[97,21,15,2],
[97,21,15,4],
[97,21,15,10],
[97,21,15,11],
[97,21,15,12],
[97,21,15,13],
[97,21,15,14],
[97,21,16,0],
[97,21,16,1],
[97,21,16,2],
[97,21,16,4],
[97,21,16,9],
[97,21,16,10],
[97,21,16,11],
[97,21,16,12],
[97,21,16,13],
[97,21,16,14],
[97,21,16,15],
[97,21,17,0],
[97,21,17,1],
[97,21,17,2],
[97,21,17,4],
[97,21,17,9],
[97,21,17,10],
[97,21,17,11],
[97,21,17,12],
[97,21,17,13],
[97,21,17,14],
[97,21,17,15],
[97,21,17,16],
[97,21,18,0],
[97,21,18,1],
[97,21,18,2],
[97,21,18,4],
[97,21,18,10],
[97,21,18,11],
[97,21,18,12],
[97,21,18,13],
[97,21,18,14],
[97,21,18,16],
[97,21,18,17],
[97,21,19,0],
[97,21,19,1],
[97,21,19,2],
[97,21,19,4],
[97,21,19,9],
[97,21,19,10],
[97,21,19,11],
[97,21,19,12],
[97,21,19,15],
[97,21,20,0],
[97,21,20,1],
[97,21,20,2],
[97,21,20,4],
[97,21,20,9],
[97,21,20,11],
[97,21,20,12],
[97,21,20,13],
[97,21,20,14],
[97,22,3,0],
[97,22,3,1],
[97,22,3,2],
[97,22,4,0],
[97,22,4,1],
[97,22,4,2],
[97,22,4,3],
[97,22,5,0],
[97,22,5,1],
[97,22,5,2],
[97,22,5,4],
[97,22,6,0],
[97,22,6,1],
[97,22,6,2],
[97,22,6,4],
[97,22,8,0],
[97,22,8,1],
[97,22,8,2],
[97,22,8,4],
[97,22,9,0],
[97,22,9,1],
[97,22,9,2],
[97,22,9,3],
[97,22,9,4],
[97,22,9,6],
[97,22,9,8],
[97,22,11,0],
[97,22,11,1],
[97,22,11,2],
[97,22,11,5],
[97,22,11,6],
[97,22,11,8],
[97,22,11,9],
[97,22,12,3],
[97,22,12,4],
[97,22,12,5],
[97,22,12,6],
[97,22,12,8],
[97,22,12,9],
[97,22,13,3],
[97,22,13,4],
[97,22,13,5],
[97,22,13,6],
[97,22,13,8],
[97,22,13,9],
[97,22,13,11],
[97,22,13,12],
[97,22,14,0],
[97,22,14,1],
[97,22,14,2],
[97,22,14,3],
[97,22,14,4],
[97,22,14,5],
[97,22,14,6],
[97,22,14,8],
[97,22,14,9],
[97,22,14,11],
[97,22,14,12],
[97,22,15,0],
[97,22,15,1],
[97,22,15,2],
[97,22,15,3],
[97,22,15,4],
[97,22,15,5],
[97,22,15,6],
[97,22,15,8],
[97,22,15,11],
[97,22,15,12],
[97,22,15,13],
[97,22,15,14],
[97,22,16,0],
[97,22,16,1],
[97,22,16,2],
[97,22,16,3],
[97,22,16,4],
[97,22,16,5],
[97,22,16,6],
[97,22,16,8],
[97,22,16,9],
[97,22,16,11],
[97,22,16,12],
[97,22,16,13],
[97,22,16,14],
[97,22,16,15],
[97,22,17,0],
[97,22,17,1],
[97,22,17,2],
[97,22,17,3],
[97,22,17,4],
[97,22,17,5],
[97,22,17,6],
[97,22,17,8],
[97,22,17,9],
[97,22,17,11],
[97,22,17,12],
[97,22,17,13],
[97,22,17,14],
[97,22,17,15],
[97,22,17,16],
[97,22,18,0],
[97,22,18,1],
[97,22,18,2],
[97,22,18,3],
[97,22,18,4],
[97,22,18,5],
[97,22,18,6],
[97,22,18,8],
[97,22,18,11],
[97,22,18,12],
[97,22,18,13],
[97,22,18,14],
[97,22,19,0],
[97,22,19,1],
[97,22,19,2],
[97,22,19,3],
[97,22,19,4],
[97,22,19,5],
[97,22,19,6],
[97,22,19,8],
[97,22,19,9],
[97,22,19,11],
[97,22,19,12],
[97,22,21,0],
[97,22,21,1],
[97,22,21,2],
[97,22,21,4],
[97,22,21,9],
[97,22,21,11],
[97,23,3,0],
[97,23,3,1],
[97,23,3,2],
[97,23,4,0],
[97,23,4,1],
[97,23,4,2],
[97,23,4,3],
[97,23,5,0],
[97,23,5,1],
[97,23,5,2],
[97,23,5,4],
[97,23,6,0],
[97,23,6,1],
[97,23,6,2],
[97,23,6,4],
[97,23,8,0],
[97,23,8,1],
[97,23,8,2],
[97,23,9,0],
[97,23,9,1],
[97,23,9,2],
[97,23,9,5],
[97,23,9,6],
[97,23,9,8],
[97,23,10,3],
[97,23,10,4],
[97,23,10,5],
[97,23,10,6],
[97,23,10,8],
[97,23,10,9],
[97,23,11,3],
[97,23,11,4],
[97,23,11,5],
[97,23,11,6],
[97,23,11,8],
[97,23,11,9],
[97,23,11,10],
[97,23,12,0],
[97,23,12,1],
[97,23,12,2],
[97,23,12,3],
[97,23,12,4],
[97,23,12,5],
[97,23,12,6],
[97,23,12,8],
[97,23,12,9],
[97,23,12,10],
[97,23,13,0],
[97,23,13,1],
[97,23,13,2],
[97,23,13,3],
[97,23,13,4],
[97,23,13,5],
[97,23,13,6],
[97,23,13,8],
[97,23,13,9],
[97,23,13,10],
[97,23,13,11],
[97,23,13,12],
[97,23,14,0],
[97,23,14,1],
[97,23,14,2],
[97,23,14,3],
[97,23,14,4],
[97,23,14,5],
[97,23,14,6],
[97,23,14,8],
[97,23,14,9],
[97,23,14,10],
[97,23,14,11],
[97,23,14,12],
[97,23,15,0],
[97,23,15,1],
[97,23,15,2],
[97,23,15,3],
[97,23,15,4],
[97,23,15,5],
[97,23,15,6],
[97,23,15,8],
[97,23,15,10],
[97,23,15,11],
[97,23,15,12],
[97,23,15,13],
[97,23,15,14],
[97,23,16,0],
[97,23,16,1],
[97,23,16,2],
[97,23,16,3],
[97,23,16,4],
[97,23,16,5],
[97,23,16,6],
[97,23,16,8],
[97,23,16,9],
[97,23,16,10],
[97,23,16,11],
[97,23,16,12],
[97,23,16,13],
[97,23,16,14],
[97,23,16,15],
[97,23,17,0],
[97,23,17,1],
[97,23,17,2],
[97,23,17,3],
[97,23,17,4],
[97,23,17,5],
[97,23,17,6],
[97,23,17,8],
[97,23,17,9],
[97,23,17,10],
[97,23,17,11],
[97,23,17,12],
[97,23,17,13],
[97,23,17,14],
[97,23,17,15],
[97,23,18,0],
[97,23,18,1],
[97,23,18,2],
[97,23,18,3],
[97,23,18,4],
[97,23,18,5],
[97,23,18,6],
[97,23,18,8],
[97,23,18,10],
[97,23,18,11],
[97,23,18,12],
[97,23,18,13],
[97,23,18,14],
[97,23,19,0],
[97,23,19,1],
[97,23,19,2],
[97,23,19,3],
[97,23,19,4],
[97,23,19,5],
[97,23,19,6],
[97,23,19,8],
[97,23,19,9],
[97,23,19,10],
[97,23,19,11],
[97,23,19,12],
[97,23,20,0],
[97,23,20,1],
[97,23,20,2],
[97,23,20,3],
[97,23,20,4],
[97,23,20,5],
[97,23,20,6],
[97,23,20,8],
[97,23,20,9],
[97,23,20,11],
[97,23,21,0],
[97,23,21,1],
[97,23,21,2],
[97,23,21,4],
[97,23,21,9],
[97,23,22,0],
[97,23,22,1],
[97,23,22,2],
[97,23,22,3],
[97,23,22,4],
[97,23,22,5],
[97,23,22,6],
[97,24,3,0],
[97,24,3,1],
[97,24,3,2],
[97,24,4,0],
[97,24,4,1],
[97,24,4,2],
[97,24,4,3],
[97,24,5,0],
[97,24,5,1],
[97,24,5,2],
[97,24,5,4],
[97,24,6,0],
[97,24,6,1],
[97,24,6,2],
[97,24,6,4],
[97,24,8,0],
[97,24,8,1],
[97,24,8,2],
[97,24,9,0],
[97,24,9,1],
[97,24,9,2],
[97,24,9,5],
[97,24,9,6],
[97,24,9,8],
[97,24,10,3],
[97,24,10,4],
[97,24,10,5],
[97,24,10,6],
[97,24,10,8],
[97,24,10,9],
[97,24,11,3],
[97,24,11,4],
[97,24,11,5],
[97,24,11,6],
[97,24,11,8],
[97,24,11,9],
[97,24,11,10],
[97,24,12,0],
[97,24,12,1],
[97,24,12,2],
[97,24,12,3],
[97,24,12,4],
[97,24,12,5],
[97,24,12,6],
[97,24,12,8],
[97,24,12,9],
[97,24,12,10],
[97,24,13,0],
[97,24,13,1],
[97,24,13,2],
[97,24,13,3],
[97,24,13,4],
[97,24,13,5],
[97,24,13,6],
[97,24,13,8],
[97,24,13,9],
[97,24,13,10],
[97,24,13,11],
[97,24,13,12],
[97,24,14,0],
[97,24,14,1],
[97,24,14,2],
[97,24,14,3],
[97,24,14,4],
[97,24,14,5],
[97,24,14,6],
[97,24,14,8],
[97,24,14,9],
[97,24,14,10],
[97,24,14,11],
[97,24,14,12],
[97,24,15,0],
[97,24,15,1],
[97,24,15,2],
[97,24,15,3],
[97,24,15,4],
[97,24,15,5],
[97,24,15,6],
[97,24,15,8],
[97,24,15,10],
[97,24,15,11],
[97,24,15,12],
[97,24,15,13],
[97,24,15,14],
[97,24,17,0],
[97,24,17,1],
[97,24,17,2],
[97,24,17,3],
[97,24,17,4],
[97,24,17,5],
[97,24,17,6],
[97,24,17,8],
[97,24,17,9],
[97,24,17,10],
[97,24,17,11],
[97,24,17,12],
[97,24,17,13],
[97,24,17,14],
[97,24,17,15],
[97,24,18,0],
[97,24,18,1],
[97,24,18,2],
[97,24,18,3],
[97,24,18,4],
[97,24,18,5],
[97,24,18,6],
[97,24,18,8],
[97,24,18,10],
[97,24,18,11],
[97,24,18,12],
[97,24,18,13],
[97,24,18,14],
[97,24,19,0],
[97,24,19,1],
[97,24,19,2],
[97,24,19,3],
[97,24,19,4],
[97,24,19,5],
[97,24,19,6],
[97,24,19,8],
[97,24,19,9],
[97,24,19,10],
[97,24,19,11],
[97,24,19,12],
[97,24,20,0],
[97,24,20,1],
[97,24,20,2],
[97,24,20,3],
[97,24,20,4],
[97,24,20,5],
[97,24,20,6],
[97,24,20,8],
[97,24,20,9],
[97,24,20,11],
[97,24,21,0],
[97,24,21,1],
[97,24,21,2],
[97,24,21,4],
[97,24,21,9],
[97,24,22,0],
[97,24,22,1],
[97,24,22,2],
[97,24,22,3],
[97,24,22,4],
[97,24,22,5],
[97,24,22,6],
[97,24,23,0],
[97,24,23,1],
[97,24,23,2],
[97,24,23,3],
[97,24,23,4],
[97,24,23,5],
[97,26,3,0],
[97,26,3,1],
[97,26,3,2],
[97,26,4,0],
[97,26,4,1],
[97,26,4,2],
[97,26,5,4],
[97,26,6,0],
[97,26,6,1],
[97,26,6,2],
[97,26,6,4],
[97,26,8,0],
[97,26,8,1],
[97,26,8,2],
[97,26,8,4],
[97,26,10,0],
[97,26,10,1],
[97,26,10,2],
[97,26,10,3],
[97,26,10,4],
[97,26,10,5],
[97,26,10,6],
[97,26,10,8],
[97,26,11,0],
[97,26,11,1],
[97,26,11,2],
[97,26,11,3],
[97,26,11,4],
[97,26,11,5],
[97,26,11,6],
[97,26,11,8],
[97,26,11,10],
[97,26,12,0],
[97,26,12,1],
[97,26,12,2],
[97,26,12,3],
[97,26,12,4],
[97,26,12,5],
[97,26,12,6],
[97,26,12,8],
[97,26,12,10],
[97,26,13,0],
[97,26,13,1],
[97,26,13,2],
[97,26,13,3],
[97,26,13,4],
[97,26,13,5],
[97,26,13,6],
[97,26,13,8],
[97,26,13,10],
[97,26,13,11],
[97,26,13,12],
[97,26,14,0],
[97,26,14,1],
[97,26,14,2],
[97,26,14,3],
[97,26,14,4],
[97,26,14,5],
[97,26,14,6],
[97,26,14,8],
[97,26,14,10],
[97,26,14,11],
[97,26,14,12],
[97,26,16,0],
[97,26,16,1],
[97,26,16,2],
[97,26,16,3],
[97,26,16,4],
[97,26,16,5],
[97,26,16,6],
[97,26,16,8],
[97,26,16,10],
[97,26,16,11],
[97,26,17,0],
[97,26,17,1],
[97,26,17,2],
[97,26,17,3],
[97,26,17,4],
[97,26,17,5],
[97,26,17,6],
[97,26,17,8],
[97,26,17,10],
[97,26,17,11],
[97,26,19,0],
[97,26,19,1],
[97,26,19,2],
[97,26,19,3],
[97,26,19,4],
[97,26,19,5],
[97,26,19,6],
[97,26,20,0],
[97,26,20,1],
[97,26,20,2],
[97,26,20,3],
[97,26,20,4],
[97,26,20,5],
[97,26,21,0],
[97,26,21,1],
[97,26,21,2],
[97,26,21,4],
[97,26,22,0],
[97,26,22,1],
[97,26,22,2],
[97,27,4,3],
[97,27,5,0],
[97,27,5,1],
[97,27,5,2],
[97,27,5,4],
[97,27,6,0],
[97,27,6,1],
[97,27,6,2],
[97,27,6,4],
[97,27,8,0],
[97,27,8,1],
[97,27,8,2],
[97,27,8,4],
[97,27,9,0],
[97,27,9,1],
[97,27,9,2],
[97,27,9,3],
[97,27,9,4],
[97,27,9,5],
[97,27,9,6],
[97,27,9,8],
[97,27,11,0],
[97,27,11,1],
[97,27,11,2],
[97,27,11,3],
[97,27,11,4],
[97,27,11,5],
[97,27,11,6],
[97,27,11,8],
[97,27,11,9],
[97,27,12,0],
[97,27,12,1],
[97,27,12,2],
[97,27,12,3],
[97,27,12,4],
[97,27,12,5],
[97,27,12,6],
[97,27,12,8],
[97,27,12,9],
[97,27,13,0],
[97,27,13,1],
[97,27,13,2],
[97,27,13,3],
[97,27,13,4],
[97,27,13,5],
[97,27,13,6],
[97,27,13,8],
[97,27,13,9],
[97,27,13,11],
[97,27,13,12],
[97,27,14,0],
[97,27,14,1],
[97,27,14,2],
[97,27,14,3],
[97,27,14,4],
[97,27,14,5],
[97,27,14,6],
[97,27,14,8],
[97,27,14,9],
[97,27,14,11],
[97,27,14,12],
[97,27,15,0],
[97,27,15,1],
[97,27,15,2],
[97,27,15,3],
[97,27,15,4],
[97,27,15,5],
[97,27,15,6],
[97,27,15,8],
[97,27,15,11],
[97,27,16,0],
[97,27,16,1],
[97,27,16,2],
[97,27,16,3],
[97,27,16,4],
[97,27,16,5],
[97,27,16,6],
[97,27,16,8],
[97,27,16,9],
[97,27,17,0],
[97,27,17,1],
[97,27,17,2],
[97,27,17,3],
[97,27,17,4],
[97,27,17,5],
[97,27,17,6],
[97,27,17,8],
[97,27,17,9],
[97,27,18,0],
[97,27,18,1],
[97,27,18,2],
[97,27,18,3],
[97,27,18,4],
[97,27,18,5],
[97,27,18,6],
[97,27,19,0],
[97,27,19,1],
[97,27,19,2],
[97,27,19,3],
[97,27,19,4],
[97,27,19,5],
[97,27,21,0],
[97,27,21,1],
[97,27,21,2],
[97,29,3,0],
[97,29,3,1],
[97,29,3,2],
[97,29,4,0],
[97,29,4,1],
[97,29,4,2],
[97,29,4,3],
[97,29,5,0],
[97,29,5,1],
[97,29,5,2],
[97,29,5,4],
[97,29,6,0],
[97,29,6,1],
[97,29,6,2],
[97,29,6,4],
[97,29,8,0],
[97,29,8,1],
[97,29,8,2],
[97,29,8,4],
[97,29,9,0],
[97,29,9,1],
[97,29,9,2],
[97,29,9,3],
[97,29,9,4],
[97,29,9,5],
[97,29,9,6],
[97,29,9,8],
[97,29,10,0],
[97,29,10,1],
[97,29,10,2],
[97,29,10,3],
[97,29,10,4],
[97,29,10,5],
[97,29,10,6],
[97,29,10,8],
[97,29,10,9],
[97,29,13,0],
[97,29,13,1],
[97,29,13,2],
[97,29,13,3],
[97,29,13,4],
[97,29,13,5],
[97,29,13,6],
[97,29,13,8],
[97,29,13,9],
[97,29,13,10],
[97,29,14,0],
[97,29,14,1],
[97,29,14,2],
[97,29,14,3],
[97,29,14,4],
[97,29,14,5],
[97,29,14,6],
[97,29,14,8],
[97,29,14,9],
[97,29,15,0],
[97,29,15,1],
[97,29,15,2],
[97,29,15,3],
[97,29,15,4],
[97,29,15,5],
[97,29,15,6],
[97,29,16,0],
[97,29,16,1],
[97,29,16,2],
[97,29,16,3],
[97,29,16,4],
[97,29,16,5],
[97,29,17,0],
[97,29,17,1],
[97,29,17,2],
[97,29,17,3],
[97,29,17,4],
[97,29,17,5],
[97,29,18,0],
[97,29,18,1],
[97,29,18,2],
[97,29,18,3],
[97,29,18,4],
[97,29,19,0],
[97,29,19,1],
[97,29,19,2],
[97,30,3,0],
[97,30,3,1],
[97,30,3,2],
[97,30,4,0],
[97,30,4,1],
[97,30,4,2],
[97,30,4,3],
[97,30,5,0],
[97,30,5,1],
[97,30,5,2],
[97,30,5,4],
[97,30,6,0],
[97,30,6,1],
[97,30,6,2],
[97,30,6,4],
[97,30,8,0],
[97,30,8,1],
[97,30,8,2],
[97,30,8,4],
[97,30,9,0],
[97,30,9,1],
[97,30,9,2],
[97,30,9,3],
[97,30,9,4],
[97,30,9,5],
[97,30,9,6],
[97,30,9,8],
[97,30,10,0],
[97,30,10,1],
[97,30,10,2],
[97,30,10,3],
[97,30,10,4],
[97,30,10,5],
[97,30,10,6],
[97,30,10,8],
[97,30,10,9],
[97,30,11,0],
[97,30,11,1],
[97,30,11,2],
[97,30,11,3],
[97,30,11,4],
[97,30,11,5],
[97,30,11,6],
[97,30,11,8],
[97,30,11,9],
[97,30,11,10],
[97,30,12,0],
[97,30,12,1],
[97,30,12,2],
[97,30,12,3],
[97,30,12,4],
[97,30,12,5],
[97,30,12,6],
[97,30,12,8],
[97,30,12,9],
[97,30,13,0],
[97,30,13,1],
[97,30,13,2],
[97,30,13,3],
[97,30,13,4],
[97,30,13,5],
[97,30,13,6],
[97,30,13,8],
[97,30,13,9],
[97,30,14,0],
[97,30,14,1],
[97,30,14,2],
[97,30,14,3],
[97,30,14,4],
[97,30,14,5],
[97,30,14,6],
[97,30,15,0],
[97,30,15,1],
[97,30,15,2],
[97,30,15,3],
[97,30,15,4],
[97,30,15,5],
[97,30,16,0],
[97,30,16,1],
[97,30,16,2],
[97,30,16,3],
[97,30,16,4],
[97,30,17,0],
[97,30,17,1],
[97,30,17,2],
[97,30,17,3],
[97,30,17,4],
[97,30,18,0],
[97,30,18,1],
[97,30,18,2],
[97,31,3,0],
[97,31,3,1],
[97,31,3,2],
[97,31,4,0],
[97,31,4,1],
[97,31,4,2],
[97,31,4,3],
[97,31,5,0],
[97,31,5,1],
[97,31,5,2],
[97,31,5,4],
[97,31,6,0],
[97,31,6,1],
[97,31,6,2],
[97,31,6,4],
[97,31,8,0],
[97,31,8,1],
[97,31,8,2],
[97,31,8,4],
[97,31,9,0],
[97,31,9,1],
[97,31,9,2],
[97,31,9,3],
[97,31,9,4],
[97,31,9,5],
[97,31,9,6],
[97,31,9,8],
[97,31,10,0],
[97,31,10,1],
[97,31,10,2],
[97,31,10,3],
[97,31,10,4],
[97,31,10,5],
[97,31,10,6],
[97,31,10,8],
[97,31,10,9],
[97,31,11,0],
[97,31,11,1],
[97,31,11,2],
[97,31,11,3],
[97,31,11,4],
[97,31,11,5],
[97,31,11,6],
[97,31,11,8],
[97,31,11,9],
[97,31,12,0],
[97,31,12,1],
[97,31,12,2],
[97,31,12,3],
[97,31,12,4],
[97,31,12,5],
[97,31,12,6],
[97,31,13,0],
[97,31,13,1],
[97,31,13,2],
[97,31,13,3],
[97,31,13,4],
[97,31,13,5],
[97,31,13,6],
[97,31,14,0],
[97,31,14,1],
[97,31,14,2],
[97,31,14,3],
[97,31,14,4],
[97,31,14,5],
[97,31,15,0],
[97,31,15,1],
[97,31,15,2],
[97,31,15,3],
[97,31,15,4],
[97,31,16,0],
[97,31,16,1],
[97,31,16,2],
[97,31,17,0],
[97,31,17,1],
[97,31,17,2],
[97,32,3,0],
[97,32,3,1],
[97,32,3,2],
[97,32,4,0],
[97,32,4,1],
[97,32,4,2],
[97,32,4,3],
[97,32,5,0],
[97,32,5,1],
[97,32,5,2],
[97,32,5,4],
[97,32,6,0],
[97,32,6,1],
[97,32,6,2],
[97,32,6,4],
[97,32,8,0],
[97,32,8,1],
[97,32,8,2],
[97,32,8,4],
[97,32,9,0],
[97,32,9,1],
[97,32,9,2],
[97,32,9,3],
[97,32,9,4],
[97,32,9,5],
[97,32,9,6],
[97,32,9,8],
[97,32,10,0],
[97,32,10,1],
[97,32,10,2],
[97,32,10,3],
[97,32,10,4],
[97,32,10,5],
[97,32,10,6],
[97,32,13,0],
[97,32,13,1],
[97,32,13,2],
[97,32,13,3],
[97,32,13,4],
[97,32,13,5],
[97,32,14,0],
[97,32,14,1],
[97,32,14,2],
[97,32,14,3],
[97,32,14,4],
[97,32,15,0],
[97,32,15,1],
[97,32,15,2],
[97,33,3,0],
[97,33,3,1],
[97,33,3,2],
[97,33,4,0],
[97,33,4,1],
[97,33,4,2],
[97,33,4,3],
[97,33,5,0],
[97,33,5,1],
[97,33,5,2],
[97,33,5,4],
[97,33,6,0],
[97,33,6,1],
[97,33,6,2],
[97,33,6,4],
[97,33,8,0],
[97,33,8,1],
[97,33,8,2],
[97,33,8,4],
[97,33,9,0],
[97,33,9,1],
[97,33,9,2],
[97,33,9,3],
[97,33,9,4],
[97,33,9,5],
[97,33,9,6],
[97,33,10,0],
[97,33,10,1],
[97,33,10,2],
[97,33,10,3],
[97,33,10,4],
[97,33,10,5],
[97,33,13,0],
[97,33,13,1],
[97,33,13,2],
[97,33,13,3],
[97,33,13,4],
[97,33,14,0],
[97,33,14,1],
[97,33,14,2],
[97,35,3,0],
[97,35,3,1],
[97,35,3,2],
[97,35,4,0],
[97,35,4,1],
[97,35,4,2],
[97,35,4,3],
[97,35,5,0],
[97,35,5,1],
[97,35,5,2],
[97,35,5,4],
[97,35,6,0],
[97,35,6,1],
[97,35,6,2],
[97,35,6,4],
[97,35,8,0],
[97,35,8,1],
[97,35,8,2],
[97,35,8,4],
[97,35,10,0],
[97,35,10,1],
[97,35,10,2],
[97,35,11,0],
[97,35,11,1],
[97,35,11,2],
[97,36,3,0],
[97,36,3,1],
[97,36,3,2],
[97,36,4,0],
[97,36,4,1],
[97,36,4,2],
[97,36,4,3],
[97,36,5,0],
[97,36,5,1],
[97,36,5,2],
[97,36,5,4],
[97,36,6,0],
[97,36,6,1],
[97,36,6,2],
[97,36,6,4],
[97,36,8,0],
[97,36,8,1],
[97,36,8,2],
[97,36,9,0],
[97,36,9,1],
[97,36,9,2],
[97,37,3,0],
[97,37,3,1],
[97,37,3,2],
[97,37,5,0],
[97,37,5,1],
[97,37,5,2],
[97,37,6,0],
[97,37,6,1],
[97,37,6,2],
[97,39,4,0],
[97,39,4,1],
[97,39,4,2],
[98,4,3,0],
[98,4,3,1],
[98,4,3,2],
[98,5,4,0],
[98,5,4,1],
[98,5,4,2],
[98,6,4,0],
[98,6,4,1],
[98,6,4,2],
[98,7,3,0],
[98,7,3,1],
[98,7,3,2],
[98,7,4,0],
[98,7,4,1],
[98,7,4,2],
[98,7,4,3],
[98,7,5,0],
[98,7,5,1],
[98,7,5,2],
[98,7,5,4],
[98,7,6,0],
[98,7,6,1],
[98,7,6,2],
[98,7,6,4],
[98,8,4,0],
[98,8,4,1],
[98,8,4,2],
[98,8,7,0],
[98,8,7,1],
[98,8,7,2],
[98,8,7,4],
[98,9,3,0],
[98,9,3,1],
[98,9,3,2],
[98,9,4,0],
[98,9,4,1],
[98,9,4,2],
[98,9,4,3],
[98,9,5,0],
[98,9,5,1],
[98,9,5,2],
[98,9,5,4],
[98,9,6,0],
[98,9,6,1],
[98,9,6,2],
[98,9,6,4],
[98,9,7,0],
[98,9,7,1],
[98,9,7,2],
[98,9,7,3],
[98,9,7,4],
[98,9,7,5],
[98,9,7,6],
[98,9,8,0],
[98,9,8,1],
[98,9,8,2],
[98,9,8,4],
[98,9,8,7],
[98,10,3,0],
[98,10,3,1],
[98,10,3,2],
[98,10,4,0],
[98,10,4,1],
[98,10,4,2],
[98,10,4,3],
[98,10,5,0],
[98,10,5,1],
[98,10,5,2],
[98,10,5,4],
[98,10,6,0],
[98,10,6,1],
[98,10,6,2],
[98,10,6,4],
[98,10,7,0],
[98,10,7,1],
[98,10,7,2],
[98,10,7,3],
[98,10,7,4],
[98,10,7,5],
[98,10,7,6],
[98,10,8,0],
[98,10,8,1],
[98,10,8,2],
[98,10,8,4],
[98,10,8,7],
[98,10,9,0],
[98,10,9,1],
[98,10,9,2],
[98,10,9,3],
[98,10,9,4],
[98,10,9,5],
[98,10,9,6],
[98,10,9,7],
[98,10,9,8],
[98,11,3,0],
[98,11,3,1],
[98,11,3,2],
[98,11,4,0],
[98,11,4,1],
[98,11,4,2],
[98,11,4,3],
[98,11,5,0],
[98,11,5,1],
[98,11,5,2],
[98,11,5,4],
[98,11,6,0],
[98,11,6,1],
[98,11,6,2],
[98,11,6,4],
[98,11,7,0],
[98,11,7,1],
[98,11,7,2],
[98,11,7,3],
[98,11,7,4],
[98,11,7,5],
[98,11,7,6],
[98,11,8,0],
[98,11,8,1],
[98,11,8,2],
[98,11,8,4],
[98,11,8,7],
[98,11,9,0],
[98,11,9,1],
[98,11,9,2],
[98,11,9,3],
[98,11,9,4],
[98,11,9,5],
[98,11,9,6],
[98,11,9,7],
[98,11,9,8],
[98,11,10,0],
[98,11,10,1],
[98,11,10,2],
[98,11,10,3],
[98,11,10,4],
[98,11,10,5],
[98,11,10,6],
[98,11,10,7],
[98,11,10,8],
[98,11,10,9],
[98,12,3,0],
[98,12,3,1],
[98,12,3,2],
[98,12,4,0],
[98,12,4,1],
[98,12,4,2],
[98,12,4,3],
[98,12,5,0],
[98,12,5,1],
[98,12,5,2],
[98,12,5,4],
[98,12,6,0],
[98,12,6,1],
[98,12,6,2],
[98,12,6,4],
[98,12,7,0],
[98,12,7,1],
[98,12,7,2],
[98,12,7,3],
[98,12,7,4],
[98,12,7,5],
[98,12,7,6],
[98,12,8,0],
[98,12,8,1],
[98,12,8,2],
[98,12,8,4],
[98,12,8,7],
[98,12,9,0],
[98,12,9,1],
[98,12,9,2],
[98,12,9,3],
[98,12,9,4],
[98,12,9,5],
[98,12,9,6],
[98,12,9,7],
[98,12,9,8],
[98,12,10,0],
[98,12,10,1],
[98,12,10,2],
[98,12,10,3],
[98,12,10,4],
[98,12,10,5],
[98,12,10,6],
[98,12,10,7],
[98,12,10,8],
[98,12,10,9],
[98,15,3,0],
[98,15,3,1],
[98,15,3,2],
[98,15,4,0],
[98,15,4,1],
[98,15,4,2],
[98,15,4,3],
[98,15,5,0],
[98,15,5,1],
[98,15,5,2],
[98,15,5,4],
[98,15,6,0],
[98,15,6,1],
[98,15,6,2],
[98,15,6,4],
[98,15,7,0],
[98,15,7,1],
[98,15,7,2],
[98,15,7,3],
[98,15,7,4],
[98,15,7,5],
[98,15,7,6],
[98,15,8,0],
[98,15,8,1],
[98,15,8,2],
[98,15,8,4],
[98,15,8,7],
[98,15,10,0],
[98,15,10,1],
[98,15,10,2],
[98,15,10,3],
[98,15,10,4],
[98,15,10,5],
[98,15,10,6],
[98,15,10,7],
[98,15,10,8],
[98,15,11,0],
[98,15,11,1],
[98,15,11,2],
[98,15,11,3],
[98,15,11,4],
[98,15,11,5],
[98,15,11,6],
[98,15,11,7],
[98,15,11,8],
[98,15,11,10],
[98,15,12,0],
[98,15,12,1],
[98,15,12,2],
[98,15,12,3],
[98,15,12,4],
[98,15,12,5],
[98,15,12,6],
[98,15,12,7],
[98,15,12,8],
[98,16,3,0],
[98,16,3,1],
[98,16,3,2],
[98,16,4,0],
[98,16,4,1],
[98,16,4,2],
[98,16,4,3],
[98,16,5,0],
[98,16,5,1],
[98,16,5,2],
[98,16,5,4],
[98,16,6,0],
[98,16,6,1],
[98,16,6,2],
[98,16,6,4],
[98,16,7,0],
[98,16,7,1],
[98,16,7,2],
[98,16,7,3],
[98,16,7,4],
[98,16,7,5],
[98,16,7,6],
[98,16,8,0],
[98,16,8,1],
[98,16,8,2],
[98,16,8,4],
[98,16,8,7],
[98,16,9,0],
[98,16,9,1],
[98,16,9,2],
[98,16,9,3],
[98,16,9,4],
[98,16,9,5],
[98,16,9,6],
[98,16,9,7],
[98,16,9,8],
[98,16,10,0],
[98,16,10,1],
[98,16,10,2],
[98,16,10,3],
[98,16,10,4],
[98,16,10,5],
[98,16,10,6],
[98,16,10,7],
[98,16,10,8],
[98,16,10,9],
[98,16,11,0],
[98,16,11,1],
[98,16,11,2],
[98,16,11,3],
[98,16,11,4],
[98,16,11,5],
[98,16,11,6],
[98,16,11,7],
[98,16,11,8],
[98,16,11,9],
[98,16,12,0],
[98,16,12,1],
[98,16,12,2],
[98,16,12,3],
[98,16,12,4],
[98,16,12,5],
[98,16,12,6],
[98,16,12,7],
[98,16,12,10],
[98,16,15,0],
[98,16,15,1],
[98,16,15,2],
[98,16,15,3],
[98,16,15,4],
[98,16,15,6],
[98,16,15,7],
[98,16,15,8],
[98,16,15,10],
[98,16,15,11],
[98,16,15,12],
[98,17,3,0],
[98,17,3,1],
[98,17,3,2],
[98,17,4,0],
[98,17,4,1],
[98,17,4,2],
[98,17,4,3],
[98,17,5,0],
[98,17,5,1],
[98,17,5,2],
[98,17,5,4],
[98,17,6,0],
[98,17,6,1],
[98,17,6,2],
[98,17,6,4],
[98,17,7,0],
[98,17,7,1],
[98,17,7,2],
[98,17,7,3],
[98,17,7,4],
[98,17,7,5],
[98,17,7,6],
[98,17,8,0],
[98,17,8,1],
[98,17,8,2],
[98,17,8,4],
[98,17,8,7],
[98,17,9,0],
[98,17,9,1],
[98,17,9,2],
[98,17,9,3],
[98,17,9,4],
[98,17,9,5],
[98,17,9,6],
[98,17,9,7],
[98,17,9,8],
[98,17,10,0],
[98,17,10,1],
[98,17,10,2],
[98,17,10,3],
[98,17,10,4],
[98,17,10,5],
[98,17,10,6],
[98,17,10,7],
[98,17,10,8],
[98,17,10,9],
[98,17,11,0],
[98,17,11,1],
[98,17,11,2],
[98,17,11,3],
[98,17,11,4],
[98,17,11,5],
[98,17,11,6],
[98,17,11,7],
[98,17,11,8],
[98,17,11,9],
[98,17,12,0],
[98,17,12,1],
[98,17,12,2],
[98,17,12,3],
[98,17,12,4],
[98,17,12,5],
[98,17,12,6],
[98,17,12,7],
[98,17,12,10],
[98,17,15,0],
[98,17,15,1],
[98,17,15,2],
[98,17,15,3],
[98,17,15,4],
[98,17,15,6],
[98,17,15,7],
[98,17,15,8],
[98,17,15,10],
[98,17,15,11],
[98,17,15,12],
[98,17,16,0],
[98,17,16,1],
[98,17,16,2],
[98,17,16,5],
[98,17,16,6],
[98,17,16,7],
[98,17,16,8],
[98,17,16,9],
[98,17,16,10],
[98,17,16,11],
[98,17,16,12],
[98,17,16,15],
[98,18,3,0],
[98,18,3,1],
[98,18,3,2],
[98,18,4,0],
[98,18,4,1],
[98,18,4,2],
[98,18,4,3],
[98,18,5,0],
[98,18,5,1],
[98,18,5,2],
[98,18,5,4],
[98,18,6,0],
[98,18,6,1],
[98,18,6,2],
[98,18,6,4],
[98,18,7,0],
[98,18,7,1],
[98,18,7,2],
[98,18,7,3],
[98,18,7,4],
[98,18,7,5],
[98,18,7,6],
[98,18,8,0],
[98,18,8,1],
[98,18,8,2],
[98,18,8,4],
[98,18,8,7],
[98,18,10,0],
[98,18,10,1],
[98,18,10,2],
[98,18,10,3],
[98,18,10,4],
[98,18,10,5],
[98,18,10,6],
[98,18,10,7],
[98,18,11,0],
[98,18,11,1],
[98,18,11,2],
[98,18,11,3],
[98,18,11,4],
[98,18,11,5],
[98,18,11,6],
[98,18,11,7],
[98,18,11,10],
[98,18,12,0],
[98,18,12,1],
[98,18,12,2],
[98,18,12,3],
[98,18,12,4],
[98,18,12,5],
[98,18,12,8],
[98,18,12,10],
[98,18,16,3],
[98,18,16,4],
[98,18,16,5],
[98,18,16,6],
[98,18,16,7],
[98,18,16,8],
[98,18,16,10],
[98,18,16,11],
[98,18,16,12],
[98,18,17,3],
[98,18,17,4],
[98,18,17,5],
[98,18,17,6],
[98,18,17,7],
[98,18,17,8],
[98,18,17,10],
[98,18,17,11],
[98,18,17,12],
[98,18,17,16],
[98,20,3,0],
[98,20,3,1],
[98,20,3,2],
[98,20,4,0],
[98,20,4,1],
[98,20,4,2],
[98,20,4,3],
[98,20,5,0],
[98,20,5,1],
[98,20,5,2],
[98,20,5,4],
[98,20,6,0],
[98,20,6,1],
[98,20,6,2],
[98,20,6,4],
[98,20,7,0],
[98,20,7,1],
[98,20,7,2],
[98,20,7,3],
[98,20,7,4],
[98,20,7,5],
[98,20,7,6],
[98,20,8,0],
[98,20,8,1],
[98,20,8,2],
[98,20,8,4],
[98,20,9,0],
[98,20,9,1],
[98,20,9,2],
[98,20,9,3],
[98,20,9,4],
[98,20,9,5],
[98,20,9,8],
[98,20,11,0],
[98,20,11,1],
[98,20,11,2],
[98,20,11,3],
[98,20,11,4],
[98,20,11,6],
[98,20,11,7],
[98,20,11,8],
[98,20,11,9],
[98,20,12,0],
[98,20,12,1],
[98,20,12,2],
[98,20,12,5],
[98,20,12,6],
[98,20,12,7],
[98,20,12,8],
[98,20,12,9],
[98,20,15,0],
[98,20,15,1],
[98,20,15,2],
[98,20,15,3],
[98,20,15,4],
[98,20,15,5],
[98,20,15,6],
[98,20,15,7],
[98,20,15,8],
[98,20,15,11],
[98,20,15,12],
[98,20,16,0],
[98,20,16,1],
[98,20,16,2],
[98,20,16,3],
[98,20,16,4],
[98,20,16,5],
[98,20,16,6],
[98,20,16,7],
[98,20,16,8],
[98,20,16,9],
[98,20,16,11],
[98,20,16,12],
[98,20,16,15],
[98,20,17,0],
[98,20,17,1],
[98,20,17,2],
[98,20,17,3],
[98,20,17,4],
[98,20,17,5],
[98,20,17,6],
[98,20,17,7],
[98,20,17,8],
[98,20,17,9],
[98,20,17,11],
[98,20,17,12],
[98,20,17,15],
[98,20,17,16],
[98,20,18,0],
[98,20,18,1],
[98,20,18,2],
[98,20,18,3],
[98,20,18,4],
[98,20,18,5],
[98,20,18,6],
[98,20,18,7],
[98,20,18,8],
[98,20,18,11],
[98,20,18,12],
[98,20,18,16],
[98,20,18,17],
[98,21,4,0],
[98,21,4,1],
[98,21,4,2],
[98,21,7,0],
[98,21,7,1],
[98,21,7,2],
[98,21,7,4],
[98,21,9,0],
[98,21,9,1],
[98,21,9,2],
[98,21,9,4],
[98,21,9,7],
[98,21,10,0],
[98,21,10,1],
[98,21,10,2],
[98,21,10,7],
[98,21,10,9],
[98,21,11,0],
[98,21,11,1],
[98,21,11,2],
[98,21,11,7],
[98,21,11,9],
[98,21,11,10],
[98,21,12,4],
[98,21,12,7],
[98,21,12,9],
[98,21,12,10],
[98,21,15,0],
[98,21,15,1],
[98,21,15,2],
[98,21,15,4],
[98,21,15,7],
[98,21,15,10],
[98,21,15,11],
[98,21,15,12],
[98,21,16,0],
[98,21,16,1],
[98,21,16,2],
[98,21,16,4],
[98,21,16,7],
[98,21,16,9],
[98,21,16,10],
[98,21,16,11],
[98,21,16,12],
[98,21,16,15],
[98,21,17,0],
[98,21,17,1],
[98,21,17,2],
[98,21,17,4],
[98,21,17,7],
[98,21,17,9],
[98,21,17,10],
[98,21,17,11],
[98,21,17,12],
[98,21,17,15],
[98,21,17,16],
[98,21,18,0],
[98,21,18,1],
[98,21,18,2],
[98,21,18,4],
[98,21,18,7],
[98,21,18,10],
[98,21,18,11],
[98,21,18,12],
[98,21,20,0],
[98,21,20,1],
[98,21,20,2],
[98,21,20,4],
[98,21,20,7],
[98,21,20,9],
[98,21,20,11],
[98,21,20,12],
[98,22,3,0],
[98,22,3,1],
[98,22,3,2],
[98,22,4,0],
[98,22,4,1],
[98,22,4,2],
[98,22,4,3],
[98,22,5,0],
[98,22,5,1],
[98,22,5,2],
[98,22,5,4],
[98,22,6,0],
[98,22,6,1],
[98,22,6,2],
[98,22,6,4],
[98,22,7,0],
[98,22,7,1],
[98,22,7,2],
[98,22,7,3],
[98,22,7,4],
[98,22,7,6],
[98,22,8,0],
[98,22,8,1],
[98,22,8,2],
[98,22,8,7],
[98,22,9,0],
[98,22,9,1],
[98,22,9,2],
[98,22,9,5],
[98,22,9,6],
[98,22,9,7],
[98,22,9,8],
[98,22,11,3],
[98,22,11,4],
[98,22,11,5],
[98,22,11,6],
[98,22,11,7],
[98,22,11,8],
[98,22,11,9],
[98,22,12,0],
[98,22,12,1],
[98,22,12,2],
[98,22,12,3],
[98,22,12,4],
[98,22,12,5],
[98,22,12,6],
[98,22,12,7],
[98,22,12,8],
[98,22,12,9],
[98,22,15,0],
[98,22,15,1],
[98,22,15,2],
[98,22,15,3],
[98,22,15,4],
[98,22,15,5],
[98,22,15,6],
[98,22,15,7],
[98,22,15,8],
[98,22,15,11],
[98,22,15,12],
[98,22,16,0],
[98,22,16,1],
[98,22,16,2],
[98,22,16,3],
[98,22,16,4],
[98,22,16,5],
[98,22,16,6],
[98,22,16,7],
[98,22,16,8],
[98,22,16,9],
[98,22,16,11],
[98,22,16,12],
[98,22,16,15],
[98,22,17,0],
[98,22,17,1],
[98,22,17,2],
[98,22,17,3],
[98,22,17,4],
[98,22,17,5],
[98,22,17,6],
[98,22,17,7],
[98,22,17,8],
[98,22,17,9],
[98,22,17,11],
[98,22,17,12],
[98,22,17,15],
[98,22,18,0],
[98,22,18,1],
[98,22,18,2],
[98,22,18,3],
[98,22,18,4],
[98,22,18,5],
[98,22,18,6],
[98,22,18,7],
[98,22,18,8],
[98,22,18,11],
[98,22,18,12],
[98,22,21,0],
[98,22,21,1],
[98,22,21,2],
[98,22,21,4],
[98,22,21,7],
[98,22,21,9],
[98,23,3,0],
[98,23,3,1],
[98,23,3,2],
[98,23,4,0],
[98,23,4,1],
[98,23,4,2],
[98,23,4,3],
[98,23,5,0],
[98,23,5,1],
[98,23,5,2],
[98,23,5,4],
[98,23,6,0],
[98,23,6,1],
[98,23,6,2],
[98,23,7,0],
[98,23,7,1],
[98,23,7,2],
[98,23,7,5],
[98,23,7,6],
[98,23,8,4],
[98,23,8,7],
[98,23,9,3],
[98,23,9,4],
[98,23,9,5],
[98,23,9,6],
[98,23,9,7],
[98,23,9,8],
[98,23,10,0],
[98,23,10,1],
[98,23,10,2],
[98,23,10,3],
[98,23,10,4],
[98,23,10,5],
[98,23,10,6],
[98,23,10,7],
[98,23,10,8],
[98,23,10,9],
[98,23,11,0],
[98,23,11,1],
[98,23,11,2],
[98,23,11,3],
[98,23,11,4],
[98,23,11,5],
[98,23,11,6],
[98,23,11,7],
[98,23,11,8],
[98,23,11,9],
[98,23,11,10],
[98,23,12,0],
[98,23,12,1],
[98,23,12,2],
[98,23,12,3],
[98,23,12,4],
[98,23,12,5],
[98,23,12,6],
[98,23,12,7],
[98,23,12,8],
[98,23,12,9],
[98,23,12,10],
[98,23,15,0],
[98,23,15,1],
[98,23,15,2],
[98,23,15,3],
[98,23,15,4],
[98,23,15,5],
[98,23,15,6],
[98,23,15,7],
[98,23,15,8],
[98,23,15,10],
[98,23,15,11],
[98,23,15,12],
[98,23,16,0],
[98,23,16,1],
[98,23,16,2],
[98,23,16,3],
[98,23,16,4],
[98,23,16,5],
[98,23,16,6],
[98,23,16,7],
[98,23,16,8],
[98,23,16,9],
[98,23,16,10],
[98,23,16,11],
[98,23,16,12],
[98,23,17,0],
[98,23,17,1],
[98,23,17,2],
[98,23,17,3],
[98,23,17,4],
[98,23,17,5],
[98,23,17,6],
[98,23,17,7],
[98,23,17,8],
[98,23,17,9],
[98,23,17,10],
[98,23,17,11],
[98,23,17,12],
[98,23,18,0],
[98,23,18,1],
[98,23,18,2],
[98,23,18,3],
[98,23,18,4],
[98,23,18,5],
[98,23,18,6],
[98,23,18,7],
[98,23,18,8],
[98,23,18,10],
[98,23,18,11],
[98,23,18,12],
[98,23,20,0],
[98,23,20,1],
[98,23,20,2],
[98,23,20,3],
[98,23,20,4],
[98,23,20,5],
[98,23,20,6],
[98,23,20,7],
[98,23,20,8],
[98,23,20,9],
[98,23,21,0],
[98,23,21,1],
[98,23,21,2],
[98,23,21,4],
[98,23,21,7],
[98,23,22,0],
[98,23,22,1],
[98,23,22,2],
[98,23,22,3],
[98,23,22,4],
[98,23,22,5],
[98,24,3,0],
[98,24,3,1],
[98,24,3,2],
[98,24,4,0],
[98,24,4,1],
[98,24,4,2],
[98,24,4,3],
[98,24,5,0],
[98,24,5,1],
[98,24,5,2],
[98,24,5,4],
[98,24,6,0],
[98,24,6,1],
[98,24,6,2],
[98,24,7,0],
[98,24,7,1],
[98,24,7,2],
[98,24,7,5],
[98,24,7,6],
[98,24,8,4],
[98,24,8,7],
[98,24,9,3],
[98,24,9,4],
[98,24,9,5],
[98,24,9,6],
[98,24,9,7],
[98,24,9,8],
[98,24,10,0],
[98,24,10,1],
[98,24,10,2],
[98,24,10,3],
[98,24,10,4],
[98,24,10,5],
[98,24,10,6],
[98,24,10,7],
[98,24,10,8],
[98,24,10,9],
[98,24,11,0],
[98,24,11,1],
[98,24,11,2],
[98,24,11,3],
[98,24,11,4],
[98,24,11,5],
[98,24,11,6],
[98,24,11,7],
[98,24,11,8],
[98,24,11,9],
[98,24,11,10],
[98,24,12,0],
[98,24,12,1],
[98,24,12,2],
[98,24,12,3],
[98,24,12,4],
[98,24,12,5],
[98,24,12,6],
[98,24,12,7],
[98,24,12,8],
[98,24,12,9],
[98,24,12,10],
[98,24,15,0],
[98,24,15,1],
[98,24,15,2],
[98,24,15,3],
[98,24,15,4],
[98,24,15,5],
[98,24,15,6],
[98,24,15,7],
[98,24,15,8],
[98,24,15,10],
[98,24,15,11],
[98,24,15,12],
[98,24,17,0],
[98,24,17,1],
[98,24,17,2],
[98,24,17,3],
[98,24,17,4],
[98,24,17,5],
[98,24,17,6],
[98,24,17,7],
[98,24,17,8],
[98,24,17,9],
[98,24,17,10],
[98,24,17,11],
[98,24,17,12],
[98,24,18,0],
[98,24,18,1],
[98,24,18,2],
[98,24,18,3],
[98,24,18,4],
[98,24,18,5],
[98,24,18,6],
[98,24,18,7],
[98,24,18,8],
[98,24,18,10],
[98,24,18,11],
[98,24,18,12],
[98,24,20,0],
[98,24,20,1],
[98,24,20,2],
[98,24,20,3],
[98,24,20,4],
[98,24,20,5],
[98,24,20,6],
[98,24,20,7],
[98,24,20,8],
[98,24,20,9],
[98,24,21,0],
[98,24,21,1],
[98,24,21,2],
[98,24,21,4],
[98,24,21,7],
[98,24,22,0],
[98,24,22,1],
[98,24,22,2],
[98,24,22,3],
[98,24,22,4],
[98,24,22,5],
[98,24,23,0],
[98,24,23,1],
[98,24,23,2],
[98,24,23,3],
[98,24,23,4],
[98,25,3,0],
[98,25,3,1],
[98,25,3,2],
[98,25,4,0],
[98,25,4,1],
[98,25,4,2],
[98,25,5,4],
[98,25,6,0],
[98,25,6,1],
[98,25,6,2],
[98,25,6,4],
[98,25,8,0],
[98,25,8,1],
[98,25,8,2],
[98,25,8,4],
[98,25,9,0],
[98,25,9,1],
[98,25,9,2],
[98,25,9,3],
[98,25,9,4],
[98,25,9,5],
[98,25,9,6],
[98,25,9,8],
[98,25,10,0],
[98,25,10,1],
[98,25,10,2],
[98,25,10,3],
[98,25,10,4],
[98,25,10,5],
[98,25,10,6],
[98,25,10,8],
[98,25,10,9],
[98,25,11,0],
[98,25,11,1],
[98,25,11,2],
[98,25,11,3],
[98,25,11,4],
[98,25,11,5],
[98,25,11,6],
[98,25,11,8],
[98,25,11,9],
[98,25,11,10],
[98,25,12,0],
[98,25,12,1],
[98,25,12,2],
[98,25,12,3],
[98,25,12,4],
[98,25,12,5],
[98,25,12,6],
[98,25,12,8],
[98,25,12,9],
[98,25,12,10],
[98,25,15,0],
[98,25,15,1],
[98,25,15,2],
[98,25,15,3],
[98,25,15,4],
[98,25,15,5],
[98,25,15,6],
[98,25,15,8],
[98,25,15,10],
[98,25,15,11],
[98,25,15,12],
[98,25,16,0],
[98,25,16,1],
[98,25,16,2],
[98,25,16,3],
[98,25,16,4],
[98,25,16,5],
[98,25,16,6],
[98,25,16,8],
[98,25,16,9],
[98,25,16,10],
[98,25,16,11],
[98,25,17,0],
[98,25,17,1],
[98,25,17,2],
[98,25,17,3],
[98,25,17,4],
[98,25,17,5],
[98,25,17,6],
[98,25,17,8],
[98,25,17,9],
[98,25,17,10],
[98,25,17,11],
[98,25,18,0],
[98,25,18,1],
[98,25,18,2],
[98,25,18,3],
[98,25,18,4],
[98,25,18,5],
[98,25,18,6],
[98,25,18,8],
[98,25,20,0],
[98,25,20,1],
[98,25,20,2],
[98,25,20,3],
[98,25,20,4],
[98,25,20,5],
[98,25,21,0],
[98,25,21,1],
[98,25,21,2],
[98,25,21,4],
[98,25,22,0],
[98,25,22,1],
[98,25,22,2],
[98,26,4,3],
[98,26,5,0],
[98,26,5,1],
[98,26,5,2],
[98,26,5,4],
[98,26,6,0],
[98,26,6,1],
[98,26,6,2],
[98,26,6,4],
[98,26,7,0],
[98,26,7,1],
[98,26,7,2],
[98,26,7,3],
[98,26,7,4],
[98,26,7,5],
[98,26,7,6],
[98,26,8,0],
[98,26,8,1],
[98,26,8,2],
[98,26,8,4],
[98,26,8,7],
[98,26,10,0],
[98,26,10,1],
[98,26,10,2],
[98,26,10,3],
[98,26,10,4],
[98,26,10,5],
[98,26,10,6],
[98,26,10,7],
[98,26,10,8],
[98,26,11,0],
[98,26,11,1],
[98,26,11,2],
[98,26,11,3],
[98,26,11,4],
[98,26,11,5],
[98,26,11,6],
[98,26,11,7],
[98,26,11,8],
[98,26,11,10],
[98,26,12,0],
[98,26,12,1],
[98,26,12,2],
[98,26,12,3],
[98,26,12,4],
[98,26,12,5],
[98,26,12,6],
[98,26,12,7],
[98,26,12,8],
[98,26,12,10],
[98,26,16,0],
[98,26,16,1],
[98,26,16,2],
[98,26,16,3],
[98,26,16,4],
[98,26,16,5],
[98,26,16,6],
[98,26,16,7],
[98,26,16,8],
[98,26,17,0],
[98,26,17,1],
[98,26,17,2],
[98,26,17,3],
[98,26,17,4],
[98,26,17,5],
[98,26,17,6],
[98,26,17,7],
[98,26,17,8],
[98,26,20,0],
[98,26,20,1],
[98,26,20,2],
[98,26,20,3],
[98,26,20,4],
[98,26,21,0],
[98,26,21,1],
[98,26,21,2],
[98,27,3,0],
[98,27,3,1],
[98,27,3,2],
[98,27,4,0],
[98,27,4,1],
[98,27,4,2],
[98,27,4,3],
[98,27,5,0],
[98,27,5,1],
[98,27,5,2],
[98,27,5,4],
[98,27,6,0],
[98,27,6,1],
[98,27,6,2],
[98,27,6,4],
[98,27,7,0],
[98,27,7,1],
[98,27,7,2],
[98,27,7,3],
[98,27,7,4],
[98,27,7,5],
[98,27,7,6],
[98,27,8,0],
[98,27,8,1],
[98,27,8,2],
[98,27,8,4],
[98,27,8,7],
[98,27,9,0],
[98,27,9,1],
[98,27,9,2],
[98,27,9,3],
[98,27,9,4],
[98,27,9,5],
[98,27,9,6],
[98,27,9,7],
[98,27,9,8],
[98,27,11,0],
[98,27,11,1],
[98,27,11,2],
[98,27,11,3],
[98,27,11,4],
[98,27,11,5],
[98,27,11,6],
[98,27,11,7],
[98,27,11,8],
[98,27,11,9],
[98,27,12,0],
[98,27,12,1],
[98,27,12,2],
[98,27,12,3],
[98,27,12,4],
[98,27,12,5],
[98,27,12,6],
[98,27,12,7],
[98,27,12,8],
[98,27,12,9],
[98,27,15,0],
[98,27,15,1],
[98,27,15,2],
[98,27,15,3],
[98,27,15,4],
[98,27,15,5],
[98,27,15,6],
[98,27,15,7],
[98,27,15,8],
[98,27,16,0],
[98,27,16,1],
[98,27,16,2],
[98,27,16,3],
[98,27,16,4],
[98,27,16,5],
[98,27,16,6],
[98,27,16,7],
[98,27,17,0],
[98,27,17,1],
[98,27,17,2],
[98,27,17,3],
[98,27,17,4],
[98,27,17,5],
[98,27,17,6],
[98,27,17,7],
[98,27,18,0],
[98,27,18,1],
[98,27,18,2],
[98,27,18,3],
[98,27,18,4],
[98,27,18,5],
[98,28,3,0],
[98,28,3,1],
[98,28,3,2],
[98,28,4,0],
[98,28,4,1],
[98,28,4,2],
[98,28,4,3],
[98,28,5,0],
[98,28,5,1],
[98,28,5,2],
[98,28,5,4],
[98,28,6,0],
[98,28,6,1],
[98,28,6,2],
[98,28,6,4],
[98,28,8,0],
[98,28,8,1],
[98,28,8,2],
[98,28,8,4],
[98,28,9,0],
[98,28,9,1],
[98,28,9,2],
[98,28,9,3],
[98,28,9,4],
[98,28,9,5],
[98,28,9,6],
[98,28,9,8],
[98,28,10,0],
[98,28,10,1],
[98,28,10,2],
[98,28,10,3],
[98,28,10,4],
[98,28,10,5],
[98,28,10,6],
[98,28,10,8],
[98,28,10,9],
[98,28,11,0],
[98,28,11,1],
[98,28,11,2],
[98,28,11,3],
[98,28,11,4],
[98,28,11,5],
[98,28,11,6],
[98,28,11,8],
[98,28,11,9],
[98,28,11,10],
[98,28,12,0],
[98,28,12,1],
[98,28,12,2],
[98,28,12,3],
[98,28,12,4],
[98,28,12,5],
[98,28,12,6],
[98,28,12,8],
[98,28,12,9],
[98,28,12,10],
[98,28,15,0],
[98,28,15,1],
[98,28,15,2],
[98,28,15,3],
[98,28,15,4],
[98,28,15,5],
[98,28,15,6],
[98,28,16,0],
[98,28,16,1],
[98,28,16,2],
[98,28,16,3],
[98,28,16,4],
[98,28,16,5],
[98,28,17,0],
[98,28,17,1],
[98,28,17,2],
[98,28,17,3],
[98,28,17,4],
[98,28,17,5],
[98,28,18,0],
[98,28,18,1],
[98,28,18,2],
[98,28,18,3],
[98,28,18,4],
[98,29,3,0],
[98,29,3,1],
[98,29,3,2],
[98,29,4,0],
[98,29,4,1],
[98,29,4,2],
[98,29,4,3],
[98,29,5,0],
[98,29,5,1],
[98,29,5,2],
[98,29,5,4],
[98,29,6,0],
[98,29,6,1],
[98,29,6,2],
[98,29,6,4],
[98,29,7,0],
[98,29,7,1],
[98,29,7,2],
[98,29,7,3],
[98,29,7,4],
[98,29,7,5],
[98,29,7,6],
[98,29,8,0],
[98,29,8,1],
[98,29,8,2],
[98,29,8,4],
[98,29,8,7],
[98,29,9,0],
[98,29,9,1],
[98,29,9,2],
[98,29,9,3],
[98,29,9,4],
[98,29,9,5],
[98,29,9,6],
[98,29,9,7],
[98,29,9,8],
[98,29,10,0],
[98,29,10,1],
[98,29,10,2],
[98,29,10,3],
[98,29,10,4],
[98,29,10,5],
[98,29,10,6],
[98,29,10,7],
[98,29,10,8],
[98,29,10,9],
[98,29,15,0],
[98,29,15,1],
[98,29,15,2],
[98,29,15,3],
[98,29,15,4],
[98,29,15,5],
[98,29,16,0],
[98,29,16,1],
[98,29,16,2],
[98,29,16,3],
[98,29,16,4],
[98,29,17,0],
[98,29,17,1],
[98,29,17,2],
[98,29,17,3],
[98,29,17,4],
[98,29,18,0],
[98,29,18,1],
[98,29,18,2],
[98,30,3,0],
[98,30,3,1],
[98,30,3,2],
[98,30,4,0],
[98,30,4,1],
[98,30,4,2],
[98,30,4,3],
[98,30,5,0],
[98,30,5,1],
[98,30,5,2],
[98,30,5,4],
[98,30,6,0],
[98,30,6,1],
[98,30,6,2],
[98,30,6,4],
[98,30,7,0],
[98,30,7,1],
[98,30,7,2],
[98,30,7,3],
[98,30,7,4],
[98,30,7,5],
[98,30,7,6],
[98,30,8,0],
[98,30,8,1],
[98,30,8,2],
[98,30,8,4],
[98,30,8,7],
[98,30,9,0],
[98,30,9,1],
[98,30,9,2],
[98,30,9,3],
[98,30,9,4],
[98,30,9,5],
[98,30,9,6],
[98,30,9,7],
[98,30,9,8],
[98,30,10,0],
[98,30,10,1],
[98,30,10,2],
[98,30,10,3],
[98,30,10,4],
[98,30,10,5],
[98,30,10,6],
[98,30,10,7],
[98,30,10,8],
[98,30,10,9],
[98,30,11,0],
[98,30,11,1],
[98,30,11,2],
[98,30,11,3],
[98,30,11,4],
[98,30,11,5],
[98,30,11,6],
[98,30,11,7],
[98,30,11,8],
[98,30,11,9],
[98,30,12,0],
[98,30,12,1],
[98,30,12,2],
[98,30,12,3],
[98,30,12,4],
[98,30,12,5],
[98,30,12,6],
[98,30,12,7],
[98,30,15,0],
[98,30,15,1],
[98,30,15,2],
[98,30,15,3],
[98,30,15,4],
[98,30,16,0],
[98,30,16,1],
[98,30,16,2],
[98,30,17,0],
[98,30,17,1],
[98,30,17,2],
[98,31,3,0],
[98,31,3,1],
[98,31,3,2],
[98,31,4,0],
[98,31,4,1],
[98,31,4,2],
[98,31,4,3],
[98,31,5,0],
[98,31,5,1],
[98,31,5,2],
[98,31,5,4],
[98,31,6,0],
[98,31,6,1],
[98,31,6,2],
[98,31,6,4],
[98,31,7,0],
[98,31,7,1],
[98,31,7,2],
[98,31,7,3],
[98,31,7,4],
[98,31,7,5],
[98,31,7,6],
[98,31,8,0],
[98,31,8,1],
[98,31,8,2],
[98,31,8,4],
[98,31,8,7],
[98,31,9,0],
[98,31,9,1],
[98,31,9,2],
[98,31,9,3],
[98,31,9,4],
[98,31,9,5],
[98,31,9,6],
[98,31,9,7],
[98,31,9,8],
[98,31,10,0],
[98,31,10,1],
[98,31,10,2],
[98,31,10,3],
[98,31,10,4],
[98,31,10,5],
[98,31,10,6],
[98,31,10,7],
[98,31,11,0],
[98,31,11,1],
[98,31,11,2],
[98,31,11,3],
[98,31,11,4],
[98,31,11,5],
[98,31,11,6],
[98,31,11,7],
[98,31,12,0],
[98,31,12,1],
[98,31,12,2],
[98,31,12,3],
[98,31,12,4],
[98,31,12,5],
[98,31,15,0],
[98,31,15,1],
[98,31,15,2],
[98,32,3,0],
[98,32,3,1],
[98,32,3,2],
[98,32,4,0],
[98,32,4,1],
[98,32,4,2],
[98,32,4,3],
[98,32,5,0],
[98,32,5,1],
[98,32,5,2],
[98,32,5,4],
[98,32,6,0],
[98,32,6,1],
[98,32,6,2],
[98,32,6,4],
[98,32,7,0],
[98,32,7,1],
[98,32,7,2],
[98,32,7,3],
[98,32,7,4],
[98,32,7,5],
[98,32,7,6],
[98,32,8,0],
[98,32,8,1],
[98,32,8,2],
[98,32,8,4],
[98,32,8,7],
[98,32,9,0],
[98,32,9,1],
[98,32,9,2],
[98,32,9,3],
[98,32,9,4],
[98,32,9,5],
[98,32,9,6],
[98,32,9,7],
[98,32,10,0],
[98,32,10,1],
[98,32,10,2],
[98,32,10,3],
[98,32,10,4],
[98,32,10,5],
[98,33,3,0],
[98,33,3,1],
[98,33,3,2],
[98,33,4,0],
[98,33,4,1],
[98,33,4,2],
[98,33,4,3],
[98,33,5,0],
[98,33,5,1],
[98,33,5,2],
[98,33,5,4],
[98,33,6,0],
[98,33,6,1],
[98,33,6,2],
[98,33,6,4],
[98,33,7,0],
[98,33,7,1],
[98,33,7,2],
[98,33,7,3],
[98,33,7,4],
[98,33,7,5],
[98,33,7,6],
[98,33,8,0],
[98,33,8,1],
[98,33,8,2],
[98,33,8,4],
[98,33,9,0],
[98,33,9,1],
[98,33,9,2],
[98,33,9,3],
[98,33,9,4],
[98,33,9,5],
[98,33,10,0],
[98,33,10,1],
[98,33,10,2],
[98,33,10,3],
[98,33,10,4],
[98,34,3,0],
[98,34,3,1],
[98,34,3,2],
[98,34,4,0],
[98,34,4,1],
[98,34,4,2],
[98,34,4,3],
[98,34,5,0],
[98,34,5,1],
[98,34,5,2],
[98,34,5,4],
[98,34,6,0],
[98,34,6,1],
[98,34,6,2],
[98,34,6,4],
[98,34,8,0],
[98,34,8,1],
[98,34,8,2],
[98,34,8,4],
[98,34,9,0],
[98,34,9,1],
[98,34,9,2],
[98,34,9,3],
[98,34,9,4],
[98,34,10,0],
[98,34,10,1],
[98,34,10,2],
[98,34,11,0],
[98,34,11,1],
[98,34,11,2],
[98,35,3,0],
[98,35,3,1],
[98,35,3,2],
[98,35,4,0],
[98,35,4,1],
[98,35,4,2],
[98,35,4,3],
[98,35,5,0],
[98,35,5,1],
[98,35,5,2],
[98,35,5,4],
[98,35,6,0],
[98,35,6,1],
[98,35,6,2],
[98,35,6,4],
[98,35,7,0],
[98,35,7,1],
[98,35,7,2],
[98,35,7,3],
[98,35,7,4],
[98,35,8,0],
[98,35,8,1],
[98,35,8,2],
[98,37,3,0],
[98,37,3,1],
[98,37,3,2],
[98,37,5,0],
[98,37,5,1],
[98,37,5,2],
[98,38,3,0],
[98,38,3,1],
[98,38,3,2],
[98,38,4,0],
[98,38,4,1],
[98,38,4,2],
[99,4,3,0],
[99,4,3,1],
[99,4,3,2],
[99,5,4,0],
[99,5,4,1],
[99,5,4,2],
[99,6,4,0],
[99,6,4,1],
[99,6,4,2],
[99,7,3,0],
[99,7,3,1],
[99,7,3,2],
[99,7,4,0],
[99,7,4,1],
[99,7,4,2],
[99,7,4,3],
[99,7,5,0],
[99,7,5,1],
[99,7,5,2],
[99,7,5,4],
[99,7,6,0],
[99,7,6,1],
[99,7,6,2],
[99,7,6,4],
[99,8,4,0],
[99,8,4,1],
[99,8,4,2],
[99,8,7,0],
[99,8,7,1],
[99,8,7,2],
[99,8,7,4],
[99,9,3,0],
[99,9,3,1],
[99,9,3,2],
[99,9,4,0],
[99,9,4,1],
[99,9,4,2],
[99,9,4,3],
[99,9,5,0],
[99,9,5,1],
[99,9,5,2],
[99,9,5,4],
[99,9,6,0],
[99,9,6,1],
[99,9,6,2],
[99,9,6,4],
[99,9,7,0],
[99,9,7,1],
[99,9,7,2],
[99,9,7,3],
[99,9,7,4],
[99,9,7,5],
[99,9,7,6],
[99,9,8,0],
[99,9,8,1],
[99,9,8,2],
[99,9,8,4],
[99,9,8,7],
[99,10,3,0],
[99,10,3,1],
[99,10,3,2],
[99,10,4,0],
[99,10,4,1],
[99,10,4,2],
[99,10,4,3],
[99,10,5,0],
[99,10,5,1],
[99,10,5,2],
[99,10,5,4],
[99,10,6,0],
[99,10,6,1],
[99,10,6,2],
[99,10,6,4],
[99,10,7,0],
[99,10,7,1],
[99,10,7,2],
[99,10,7,3],
[99,10,7,4],
[99,10,7,5],
[99,10,7,6],
[99,10,8,0],
[99,10,8,1],
[99,10,8,2],
[99,10,8,4],
[99,10,8,7],
[99,10,9,0],
[99,10,9,1],
[99,10,9,2],
[99,10,9,3],
[99,10,9,4],
[99,10,9,5],
[99,10,9,6],
[99,10,9,7],
[99,10,9,8],
[99,11,3,0],
[99,11,3,1],
[99,11,3,2],
[99,11,4,0],
[99,11,4,1],
[99,11,4,2],
[99,11,4,3],
[99,11,5,0],
[99,11,5,1],
[99,11,5,2],
[99,11,5,4],
[99,11,6,0],
[99,11,6,1],
[99,11,6,2],
[99,11,6,4],
[99,11,7,0],
[99,11,7,1],
[99,11,7,2],
[99,11,7,3],
[99,11,7,4],
[99,11,7,5],
[99,11,7,6],
[99,11,8,0],
[99,11,8,1],
[99,11,8,2],
[99,11,8,4],
[99,11,8,7],
[99,11,9,0],
[99,11,9,1],
[99,11,9,2],
[99,11,9,3],
[99,11,9,4],
[99,11,9,5],
[99,11,9,6],
[99,11,9,7],
[99,11,9,8],
[99,11,10,0],
[99,11,10,1],
[99,11,10,2],
[99,11,10,3],
[99,11,10,4],
[99,11,10,5],
[99,11,10,6],
[99,11,10,7],
[99,11,10,8],
[99,11,10,9],
[99,12,3,0],
[99,12,3,1],
[99,12,3,2],
[99,12,4,0],
[99,12,4,1],
[99,12,4,2],
[99,12,4,3],
[99,12,5,0],
[99,12,5,1],
[99,12,5,2],
[99,12,5,4],
[99,12,6,0],
[99,12,6,1],
[99,12,6,2],
[99,12,6,4],
[99,12,7,0],
[99,12,7,1],
[99,12,7,2],
[99,12,7,3],
[99,12,7,4],
[99,12,7,5],
[99,12,7,6],
[99,12,8,0],
[99,12,8,1],
[99,12,8,2],
[99,12,8,4],
[99,12,8,7],
[99,12,9,0],
[99,12,9,1],
[99,12,9,2],
[99,12,9,3],
[99,12,9,4],
[99,12,9,5],
[99,12,9,6],
[99,12,9,7],
[99,12,9,8],
[99,12,10,0],
[99,12,10,1],
[99,12,10,2],
[99,12,10,3],
[99,12,10,4],
[99,12,10,5],
[99,12,10,6],
[99,12,10,7],
[99,12,10,8],
[99,12,10,9],
[99,13,3,0],
[99,13,3,1],
[99,13,3,2],
[99,13,4,0],
[99,13,4,1],
[99,13,4,2],
[99,13,4,3],
[99,13,5,0],
[99,13,5,1],
[99,13,5,2],
[99,13,5,4],
[99,13,6,0],
[99,13,6,1],
[99,13,6,2],
[99,13,6,4],
[99,13,7,0],
[99,13,7,1],
[99,13,7,2],
[99,13,7,3],
[99,13,7,4],
[99,13,7,5],
[99,13,7,6],
[99,13,8,0],
[99,13,8,1],
[99,13,8,2],
[99,13,8,4],
[99,13,8,7],
[99,13,9,0],
[99,13,9,1],
[99,13,9,2],
[99,13,9,3],
[99,13,9,4],
[99,13,9,5],
[99,13,9,6],
[99,13,9,7],
[99,13,9,8],
[99,13,10,0],
[99,13,10,1],
[99,13,10,2],
[99,13,10,3],
[99,13,10,4],
[99,13,10,5],
[99,13,10,6],
[99,13,10,7],
[99,13,10,8],
[99,13,10,9],
[99,13,11,0],
[99,13,11,1],
[99,13,11,2],
[99,13,11,3],
[99,13,11,4],
[99,13,11,5],
[99,13,11,6],
[99,13,11,7],
[99,13,11,8],
[99,13,11,9],
[99,13,11,10],
[99,13,12,0],
[99,13,12,1],
[99,13,12,2],
[99,13,12,3],
[99,13,12,4],
[99,13,12,5],
[99,13,12,6],
[99,13,12,7],
[99,13,12,8],
[99,13,12,9],
[99,13,12,10],
[99,14,3,0],
[99,14,3,1],
[99,14,3,2],
[99,14,4,0],
[99,14,4,1],
[99,14,4,2],
[99,14,4,3],
[99,14,5,0],
[99,14,5,1],
[99,14,5,2],
[99,14,5,4],
[99,14,6,0],
[99,14,6,1],
[99,14,6,2],
[99,14,6,4],
[99,14,7,0],
[99,14,7,1],
[99,14,7,2],
[99,14,7,3],
[99,14,7,4],
[99,14,7,5],
[99,14,7,6],
[99,14,8,0],
[99,14,8,1],
[99,14,8,2],
[99,14,8,4],
[99,14,8,7],
[99,14,9,0],
[99,14,9,1],
[99,14,9,2],
[99,14,9,3],
[99,14,9,4],
[99,14,9,5],
[99,14,9,6],
[99,14,9,7],
[99,14,9,8],
[99,14,10,0],
[99,14,10,1],
[99,14,10,2],
[99,14,10,3],
[99,14,10,4],
[99,14,10,5],
[99,14,10,6],
[99,14,10,7],
[99,14,10,8],
[99,14,10,9],
[99,14,11,0],
[99,14,11,1],
[99,14,11,2],
[99,14,11,3],
[99,14,11,4],
[99,14,11,5],
[99,14,11,6],
[99,14,11,7],
[99,14,11,8],
[99,14,11,9],
[99,14,11,10],
[99,14,12,0],
[99,14,12,1],
[99,14,12,2],
[99,14,12,3],
[99,14,12,4],
[99,14,12,5],
[99,14,12,6],
[99,14,12,7],
[99,14,12,8],
[99,14,12,9],
[99,15,3,0],
[99,15,3,1],
[99,15,3,2],
[99,15,4,0],
[99,15,4,1],
[99,15,4,2],
[99,15,4,3],
[99,15,5,0],
[99,15,5,1],
[99,15,5,2],
[99,15,5,4],
[99,15,6,0],
[99,15,6,1],
[99,15,6,2],
[99,15,6,4],
[99,15,7,0],
[99,15,7,1],
[99,15,7,2],
[99,15,7,3],
[99,15,7,4],
[99,15,7,5],
[99,15,7,6],
[99,15,8,0],
[99,15,8,1],
[99,15,8,2],
[99,15,8,4],
[99,15,8,7],
[99,15,10,0],
[99,15,10,1],
[99,15,10,2],
[99,15,10,3],
[99,15,10,4],
[99,15,10,5],
[99,15,10,6],
[99,15,10,7],
[99,15,10,8],
[99,15,11,0],
[99,15,11,1],
[99,15,11,2],
[99,15,11,3],
[99,15,11,4],
[99,15,11,5],
[99,15,11,6],
[99,15,11,7],
[99,15,11,8],
[99,15,12,0],
[99,15,12,1],
[99,15,12,2],
[99,15,12,3],
[99,15,12,4],
[99,15,12,5],
[99,15,12,6],
[99,15,12,7],
[99,15,12,10],
[99,15,13,0],
[99,15,13,1],
[99,15,13,2],
[99,15,13,3],
[99,15,13,4],
[99,15,13,5],
[99,15,13,6],
[99,15,13,7],
[99,15,13,10],
[99,15,13,11],
[99,15,13,12],
[99,15,14,0],
[99,15,14,1],
[99,15,14,2],
[99,15,14,3],
[99,15,14,4],
[99,15,14,5],
[99,15,14,8],
[99,15,14,10],
[99,15,14,11],
[99,15,14,12],
[99,16,3,0],
[99,16,3,1],
[99,16,3,2],
[99,16,4,0],
[99,16,4,1],
[99,16,4,2],
[99,16,4,3],
[99,16,5,0],
[99,16,5,1],
[99,16,5,2],
[99,16,5,4],
[99,16,6,0],
[99,16,6,1],
[99,16,6,2],
[99,16,6,4],
[99,16,7,0],
[99,16,7,1],
[99,16,7,2],
[99,16,7,3],
[99,16,7,4],
[99,16,7,5],
[99,16,7,6],
[99,16,8,0],
[99,16,8,1],
[99,16,8,2],
[99,16,8,4],
[99,16,8,7],
[99,16,9,0],
[99,16,9,1],
[99,16,9,2],
[99,16,9,3],
[99,16,9,4],
[99,16,9,5],
[99,16,9,6],
[99,16,9,7],
[99,16,9,8],
[99,16,10,0],
[99,16,10,1],
[99,16,10,2],
[99,16,10,3],
[99,16,10,4],
[99,16,10,5],
[99,16,10,6],
[99,16,10,7],
[99,16,11,0],
[99,16,11,1],
[99,16,11,2],
[99,16,11,3],
[99,16,11,4],
[99,16,11,5],
[99,16,11,6],
[99,16,11,7],
[99,16,11,10],
[99,16,12,0],
[99,16,12,1],
[99,16,12,2],
[99,16,12,3],
[99,16,12,4],
[99,16,12,5],
[99,16,12,8],
[99,16,12,9],
[99,16,12,10],
[99,16,13,0],
[99,16,13,1],
[99,16,13,2],
[99,16,13,3],
[99,16,13,4],
[99,16,13,5],
[99,16,13,8],
[99,16,13,9],
[99,16,13,10],
[99,16,13,11],
[99,16,13,12],
[99,16,14,0],
[99,16,14,1],
[99,16,14,2],
[99,16,14,3],
[99,16,14,4],
[99,16,14,6],
[99,16,14,7],
[99,16,14,8],
[99,16,14,9],
[99,16,14,10],
[99,16,14,11],
[99,16,14,12],
[99,16,15,0],
[99,16,15,1],
[99,16,15,2],
[99,16,15,5],
[99,16,15,6],
[99,16,15,7],
[99,16,15,8],
[99,16,15,10],
[99,16,15,11],
[99,16,15,12],
[99,16,15,13],
[99,16,15,14],
[99,17,3,0],
[99,17,3,1],
[99,17,3,2],
[99,17,4,0],
[99,17,4,1],
[99,17,4,2],
[99,17,4,3],
[99,17,5,0],
[99,17,5,1],
[99,17,5,2],
[99,17,5,4],
[99,17,6,0],
[99,17,6,1],
[99,17,6,2],
[99,17,6,4],
[99,17,7,0],
[99,17,7,1],
[99,17,7,2],
[99,17,7,3],
[99,17,7,4],
[99,17,7,5],
[99,17,7,6],
[99,17,8,0],
[99,17,8,1],
[99,17,8,2],
[99,17,8,4],
[99,17,8,7],
[99,17,9,0],
[99,17,9,1],
[99,17,9,2],
[99,17,9,3],
[99,17,9,4],
[99,17,9,5],
[99,17,9,6],
[99,17,9,7],
[99,17,9,8],
[99,17,10,0],
[99,17,10,1],
[99,17,10,2],
[99,17,10,3],
[99,17,10,4],
[99,17,10,5],
[99,17,10,6],
[99,17,10,7],
[99,17,11,0],
[99,17,11,1],
[99,17,11,2],
[99,17,11,3],
[99,17,11,4],
[99,17,11,5],
[99,17,11,6],
[99,17,11,7],
[99,17,11,10],
[99,17,12,0],
[99,17,12,1],
[99,17,12,2],
[99,17,12,3],
[99,17,12,4],
[99,17,12,5],
[99,17,12,8],
[99,17,12,9],
[99,17,12,10],
[99,17,13,0],
[99,17,13,1],
[99,17,13,2],
[99,17,13,3],
[99,17,13,4],
[99,17,13,5],
[99,17,13,8],
[99,17,13,9],
[99,17,13,10],
[99,17,13,11],
[99,17,13,12],
[99,17,14,0],
[99,17,14,1],
[99,17,14,2],
[99,17,14,3],
[99,17,14,4],
[99,17,14,6],
[99,17,14,7],
[99,17,14,8],
[99,17,14,9],
[99,17,14,10],
[99,17,14,11],
[99,17,14,12],
[99,17,15,0],
[99,17,15,1],
[99,17,15,2],
[99,17,15,5],
[99,17,15,6],
[99,17,15,7],
[99,17,15,8],
[99,17,15,10],
[99,17,15,11],
[99,17,15,12],
[99,17,15,13],
[99,17,15,14],
[99,17,16,3],
[99,17,16,4],
[99,17,16,5],
[99,17,16,6],
[99,17,16,7],
[99,17,16,8],
[99,17,16,9],
[99,17,16,10],
[99,17,16,11],
[99,17,16,12],
[99,17,16,13],
[99,17,16,14],
[99,17,16,15],
[99,18,3,0],
[99,18,3,1],
[99,18,3,2],
[99,18,4,0],
[99,18,4,1],
[99,18,4,2],
[99,18,4,3],
[99,18,5,0],
[99,18,5,1],
[99,18,5,2],
[99,18,5,4],
[99,18,6,0],
[99,18,6,1],
[99,18,6,2],
[99,18,6,4],
[99,18,7,0],
[99,18,7,1],
[99,18,7,2],
[99,18,7,3],
[99,18,7,4],
[99,18,7,5],
[99,18,7,6],
[99,18,8,0],
[99,18,8,1],
[99,18,8,2],
[99,18,8,4],
[99,18,8,7],
[99,18,10,0],
[99,18,10,1],
[99,18,10,2],
[99,18,10,3],
[99,18,10,4],
[99,18,10,5],
[99,18,10,8],
[99,18,11,0],
[99,18,11,1],
[99,18,11,2],
[99,18,11,3],
[99,18,11,4],
[99,18,11,5],
[99,18,11,8],
[99,18,11,10],
[99,18,12,0],
[99,18,12,1],
[99,18,12,2],
[99,18,12,3],
[99,18,12,4],
[99,18,12,6],
[99,18,12,7],
[99,18,12,8],
[99,18,12,10],
[99,18,13,0],
[99,18,13,1],
[99,18,13,2],
[99,18,13,3],
[99,18,13,4],
[99,18,13,6],
[99,18,13,7],
[99,18,13,8],
[99,18,13,10],
[99,18,13,11],
[99,18,13,12],
[99,18,14,0],
[99,18,14,1],
[99,18,14,2],
[99,18,14,5],
[99,18,14,6],
[99,18,14,7],
[99,18,14,8],
[99,18,14,10],
[99,18,14,11],
[99,18,14,12],
[99,18,16,0],
[99,18,16,1],
[99,18,16,2],
[99,18,16,3],
[99,18,16,4],
[99,18,16,5],
[99,18,16,6],
[99,18,16,7],
[99,18,16,8],
[99,18,16,10],
[99,18,16,11],
[99,18,16,12],
[99,18,16,13],
[99,18,16,14],
[99,18,17,0],
[99,18,17,1],
[99,18,17,2],
[99,18,17,3],
[99,18,17,4],
[99,18,17,5],
[99,18,17,6],
[99,18,17,7],
[99,18,17,8],
[99,18,17,10],
[99,18,17,11],
[99,18,17,12],
[99,18,17,13],
[99,18,17,14],
[99,18,17,16],
[99,19,3,0],
[99,19,3,1],
[99,19,3,2],
[99,19,4,0],
[99,19,4,1],
[99,19,4,2],
[99,19,4,3],
[99,19,5,0],
[99,19,5,1],
[99,19,5,2],
[99,19,5,4],
[99,19,6,0],
[99,19,6,1],
[99,19,6,2],
[99,19,6,4],
[99,19,7,0],
[99,19,7,1],
[99,19,7,2],
[99,19,7,3],
[99,19,7,4],
[99,19,7,5],
[99,19,7,6],
[99,19,8,0],
[99,19,8,1],
[99,19,8,2],
[99,19,8,4],
[99,19,9,0],
[99,19,9,1],
[99,19,9,2],
[99,19,9,3],
[99,19,9,4],
[99,19,9,5],
[99,19,9,8],
[99,19,10,0],
[99,19,10,1],
[99,19,10,2],
[99,19,10,3],
[99,19,10,4],
[99,19,10,6],
[99,19,10,7],
[99,19,10,8],
[99,19,10,9],
[99,19,11,0],
[99,19,11,1],
[99,19,11,2],
[99,19,11,3],
[99,19,11,4],
[99,19,11,6],
[99,19,11,7],
[99,19,11,8],
[99,19,11,9],
[99,19,11,10],
[99,19,12,0],
[99,19,12,1],
[99,19,12,2],
[99,19,12,5],
[99,19,12,6],
[99,19,12,7],
[99,19,12,8],
[99,19,12,9],
[99,19,12,10],
[99,19,15,0],
[99,19,15,1],
[99,19,15,2],
[99,19,15,3],
[99,19,15,4],
[99,19,15,5],
[99,19,15,6],
[99,19,15,7],
[99,19,15,8],
[99,19,15,10],
[99,19,15,11],
[99,19,15,12],
[99,19,16,0],
[99,19,16,1],
[99,19,16,2],
[99,19,16,3],
[99,19,16,4],
[99,19,16,5],
[99,19,16,6],
[99,19,16,7],
[99,19,16,8],
[99,19,16,9],
[99,19,16,10],
[99,19,16,11],
[99,19,16,12],
[99,19,16,15],
[99,19,17,0],
[99,19,17,1],
[99,19,17,2],
[99,19,17,3],
[99,19,17,4],
[99,19,17,5],
[99,19,17,6],
[99,19,17,7],
[99,19,17,8],
[99,19,17,9],
[99,19,17,10],
[99,19,17,11],
[99,19,17,12],
[99,19,17,15],
[99,19,17,16],
[99,19,18,0],
[99,19,18,1],
[99,19,18,2],
[99,19,18,3],
[99,19,18,4],
[99,19,18,5],
[99,19,18,6],
[99,19,18,7],
[99,19,18,8],
[99,19,18,10],
[99,19,18,11],
[99,19,18,12],
[99,19,18,16],
[99,19,18,17],
[99,20,3,0],
[99,20,3,1],
[99,20,3,2],
[99,20,4,0],
[99,20,4,1],
[99,20,4,2],
[99,20,4,3],
[99,20,5,0],
[99,20,5,1],
[99,20,5,2],
[99,20,5,4],
[99,20,6,0],
[99,20,6,1],
[99,20,6,2],
[99,20,6,4],
[99,20,7,0],
[99,20,7,1],
[99,20,7,2],
[99,20,7,3],
[99,20,7,4],
[99,20,7,5],
[99,20,8,0],
[99,20,8,1],
[99,20,8,2],
[99,20,8,4],
[99,20,8,7],
[99,20,9,0],
[99,20,9,1],
[99,20,9,2],
[99,20,9,3],
[99,20,9,4],
[99,20,9,6],
[99,20,9,7],
[99,20,9,8],
[99,20,11,0],
[99,20,11,1],
[99,20,11,2],
[99,20,11,5],
[99,20,11,6],
[99,20,11,7],
[99,20,11,8],
[99,20,11,9],
[99,20,12,3],
[99,20,12,4],
[99,20,12,5],
[99,20,12,6],
[99,20,12,7],
[99,20,12,8],
[99,20,12,9],
[99,20,13,3],
[99,20,13,4],
[99,20,13,5],
[99,20,13,6],
[99,20,13,7],
[99,20,13,8],
[99,20,13,9],
[99,20,13,11],
[99,20,13,12],
[99,20,14,0],
[99,20,14,1],
[99,20,14,2],
[99,20,14,3],
[99,20,14,4],
[99,20,14,5],
[99,20,14,6],
[99,20,14,7],
[99,20,14,8],
[99,20,14,9],
[99,20,14,11],
[99,20,14,12],
[99,20,15,0],
[99,20,15,1],
[99,20,15,2],
[99,20,15,3],
[99,20,15,4],
[99,20,15,5],
[99,20,15,6],
[99,20,15,7],
[99,20,15,8],
[99,20,15,11],
[99,20,15,12],
[99,20,15,13],
[99,20,15,14],
[99,20,16,0],
[99,20,16,1],
[99,20,16,2],
[99,20,16,3],
[99,20,16,4],
[99,20,16,5],
[99,20,16,6],
[99,20,16,7],
[99,20,16,8],
[99,20,16,9],
[99,20,16,11],
[99,20,16,12],
[99,20,16,13],
[99,20,16,14],
[99,20,16,15],
[99,20,17,0],
[99,20,17,1],
[99,20,17,2],
[99,20,17,3],
[99,20,17,4],
[99,20,17,5],
[99,20,17,6],
[99,20,17,7],
[99,20,17,8],
[99,20,17,9],
[99,20,17,11],
[99,20,17,12],
[99,20,17,13],
[99,20,17,14],
[99,20,17,15],
[99,20,17,16],
[99,20,18,0],
[99,20,18,1],
[99,20,18,2],
[99,20,18,3],
[99,20,18,4],
[99,20,18,5],
[99,20,18,6],
[99,20,18,7],
[99,20,18,8],
[99,20,18,11],
[99,20,18,12],
[99,20,18,13],
[99,20,18,14],
[99,20,19,0],
[99,20,19,1],
[99,20,19,2],
[99,20,19,3],
[99,20,19,4],
[99,20,19,5],
[99,20,19,6],
[99,20,19,7],
[99,20,19,8],
[99,20,19,9],
[99,20,19,11],
[99,20,19,12],
[99,21,4,0],
[99,21,4,1],
[99,21,4,2],
[99,21,7,0],
[99,21,7,1],
[99,21,7,2],
[99,21,7,4],
[99,21,9,0],
[99,21,9,1],
[99,21,9,2],
[99,21,9,7],
[99,21,10,4],
[99,21,10,7],
[99,21,10,9],
[99,21,11,4],
[99,21,11,7],
[99,21,11,9],
[99,21,11,10],
[99,21,12,0],
[99,21,12,1],
[99,21,12,2],
[99,21,12,4],
[99,21,12,7],
[99,21,12,9],
[99,21,12,10],
[99,21,13,0],
[99,21,13,1],
[99,21,13,2],
[99,21,13,4],
[99,21,13,7],
[99,21,13,9],
[99,21,13,10],
[99,21,13,11],
[99,21,13,12],
[99,21,14,0],
[99,21,14,1],
[99,21,14,2],
[99,21,14,4],
[99,21,14,7],
[99,21,14,9],
[99,21,14,10],
[99,21,14,11],
[99,21,14,12],
[99,21,15,0],
[99,21,15,1],
[99,21,15,2],
[99,21,15,4],
[99,21,15,7],
[99,21,15,10],
[99,21,15,11],
[99,21,15,12],
[99,21,15,13],
[99,21,15,14],
[99,21,16,0],
[99,21,16,1],
[99,21,16,2],
[99,21,16,4],
[99,21,16,7],
[99,21,16,9],
[99,21,16,10],
[99,21,16,11],
[99,21,16,12],
[99,21,16,13],
[99,21,16,14],
[99,21,16,15],
[99,21,17,0],
[99,21,17,1],
[99,21,17,2],
[99,21,17,4],
[99,21,17,7],
[99,21,17,9],
[99,21,17,10],
[99,21,17,11],
[99,21,17,12],
[99,21,17,13],
[99,21,17,14],
[99,21,17,15],
[99,21,18,0],
[99,21,18,1],
[99,21,18,2],
[99,21,18,4],
[99,21,18,7],
[99,21,18,10],
[99,21,18,11],
[99,21,18,12],
[99,21,18,13],
[99,21,18,14],
[99,21,19,0],
[99,21,19,1],
[99,21,19,2],
[99,21,19,4],
[99,21,19,7],
[99,21,19,9],
[99,21,19,10],
[99,21,19,11],
[99,21,19,12],
[99,21,20,0],
[99,21,20,1],
[99,21,20,2],
[99,21,20,4],
[99,21,20,7],
[99,21,20,9],
[99,21,20,11],
[99,22,3,0],
[99,22,3,1],
[99,22,3,2],
[99,22,4,0],
[99,22,4,1],
[99,22,4,2],
[99,22,4,3],
[99,22,5,0],
[99,22,5,1],
[99,22,5,2],
[99,22,5,4],
[99,22,6,0],
[99,22,6,1],
[99,22,6,2],
[99,22,7,0],
[99,22,7,1],
[99,22,7,2],
[99,22,7,5],
[99,22,7,6],
[99,22,8,4],
[99,22,8,7],
[99,22,9,3],
[99,22,9,4],
[99,22,9,5],
[99,22,9,6],
[99,22,9,7],
[99,22,9,8],
[99,22,11,0],
[99,22,11,1],
[99,22,11,2],
[99,22,11,3],
[99,22,11,4],
[99,22,11,5],
[99,22,11,6],
[99,22,11,7],
[99,22,11,8],
[99,22,11,9],
[99,22,12,0],
[99,22,12,1],
[99,22,12,2],
[99,22,12,3],
[99,22,12,4],
[99,22,12,5],
[99,22,12,6],
[99,22,12,7],
[99,22,12,8],
[99,22,12,9],
[99,22,13,0],
[99,22,13,1],
[99,22,13,2],
[99,22,13,3],
[99,22,13,4],
[99,22,13,5],
[99,22,13,6],
[99,22,13,7],
[99,22,13,8],
[99,22,13,9],
[99,22,13,11],
[99,22,13,12],
[99,22,14,0],
[99,22,14,1],
[99,22,14,2],
[99,22,14,3],
[99,22,14,4],
[99,22,14,5],
[99,22,14,6],
[99,22,14,7],
[99,22,14,8],
[99,22,14,9],
[99,22,14,11],
[99,22,14,12],
[99,22,15,0],
[99,22,15,1],
[99,22,15,2],
[99,22,15,3],
[99,22,15,4],
[99,22,15,5],
[99,22,15,6],
[99,22,15,7],
[99,22,15,8],
[99,22,15,11],
[99,22,15,12],
[99,22,15,13],
[99,22,15,14],
[99,22,16,0],
[99,22,16,1],
[99,22,16,2],
[99,22,16,3],
[99,22,16,4],
[99,22,16,5],
[99,22,16,6],
[99,22,16,7],
[99,22,16,8],
[99,22,16,9],
[99,22,16,11],
[99,22,16,12],
[99,22,16,13],
[99,22,16,14],
[99,22,17,0],
[99,22,17,1],
[99,22,17,2],
[99,22,17,3],
[99,22,17,4],
[99,22,17,5],
[99,22,17,6],
[99,22,17,7],
[99,22,17,8],
[99,22,17,9],
[99,22,17,11],
[99,22,17,12],
[99,22,17,13],
[99,22,17,14],
[99,22,18,0],
[99,22,18,1],
[99,22,18,2],
[99,22,18,3],
[99,22,18,4],
[99,22,18,5],
[99,22,18,6],
[99,22,18,7],
[99,22,18,8],
[99,22,18,11],
[99,22,18,12],
[99,22,18,13],
[99,22,19,0],
[99,22,19,1],
[99,22,19,2],
[99,22,19,3],
[99,22,19,4],
[99,22,19,5],
[99,22,19,6],
[99,22,19,7],
[99,22,19,8],
[99,22,19,9],
[99,22,19,11],
[99,22,21,0],
[99,22,21,1],
[99,22,21,2],
[99,22,21,4],
[99,22,21,7],
[99,23,3,0],
[99,23,3,1],
[99,23,3,2],
[99,23,4,0],
[99,23,4,1],
[99,23,4,2],
[99,23,4,3],
[99,23,5,0],
[99,23,5,1],
[99,23,5,2],
[99,23,6,4],
[99,23,7,3],
[99,23,7,4],
[99,23,7,5],
[99,23,7,6],
[99,23,8,0],
[99,23,8,1],
[99,23,8,2],
[99,23,8,4],
[99,23,8,7],
[99,23,9,0],
[99,23,9,1],
[99,23,9,2],
[99,23,9,3],
[99,23,9,4],
[99,23,9,5],
[99,23,9,6],
[99,23,9,7],
[99,23,9,8],
[99,23,10,0],
[99,23,10,1],
[99,23,10,2],
[99,23,10,3],
[99,23,10,4],
[99,23,10,5],
[99,23,10,6],
[99,23,10,7],
[99,23,10,8],
[99,23,10,9],
[99,23,11,0],
[99,23,11,1],
[99,23,11,2],
[99,23,11,3],
[99,23,11,4],
[99,23,11,5],
[99,23,11,6],
[99,23,11,7],
[99,23,11,8],
[99,23,11,9],
[99,23,11,10],
[99,23,12,0],
[99,23,12,1],
[99,23,12,2],
[99,23,12,3],
[99,23,12,4],
[99,23,12,5],
[99,23,12,6],
[99,23,12,7],
[99,23,12,8],
[99,23,12,9],
[99,23,12,10],
[99,23,13,0],
[99,23,13,1],
[99,23,13,2],
[99,23,13,3],
[99,23,13,4],
[99,23,13,5],
[99,23,13,6],
[99,23,13,7],
[99,23,13,8],
[99,23,13,9],
[99,23,13,10],
[99,23,13,11],
[99,23,13,12],
[99,23,14,0],
[99,23,14,1],
[99,23,14,2],
[99,23,14,3],
[99,23,14,4],
[99,23,14,5],
[99,23,14,6],
[99,23,14,7],
[99,23,14,8],
[99,23,14,9],
[99,23,14,10],
[99,23,14,11],
[99,23,14,12],
[99,23,15,0],
[99,23,15,1],
[99,23,15,2],
[99,23,15,3],
[99,23,15,4],
[99,23,15,5],
[99,23,15,6],
[99,23,15,7],
[99,23,15,8],
[99,23,15,10],
[99,23,15,11],
[99,23,15,12],
[99,23,15,13],
[99,23,15,14],
[99,23,16,0],
[99,23,16,1],
[99,23,16,2],
[99,23,16,3],
[99,23,16,4],
[99,23,16,5],
[99,23,16,6],
[99,23,16,7],
[99,23,16,8],
[99,23,16,9],
[99,23,16,10],
[99,23,16,11],
[99,23,16,12],
[99,23,16,13],
[99,23,17,0],
[99,23,17,1],
[99,23,17,2],
[99,23,17,3],
[99,23,17,4],
[99,23,17,5],
[99,23,17,6],
[99,23,17,7],
[99,23,17,8],
[99,23,17,9],
[99,23,17,10],
[99,23,17,11],
[99,23,17,12],
[99,23,17,13],
[99,23,18,0],
[99,23,18,1],
[99,23,18,2],
[99,23,18,3],
[99,23,18,4],
[99,23,18,5],
[99,23,18,6],
[99,23,18,7],
[99,23,18,8],
[99,23,18,10],
[99,23,18,11],
[99,23,19,0],
[99,23,19,1],
[99,23,19,2],
[99,23,19,3],
[99,23,19,4],
[99,23,19,5],
[99,23,19,6],
[99,23,19,7],
[99,23,19,8],
[99,23,19,9],
[99,23,20,0],
[99,23,20,1],
[99,23,20,2],
[99,23,20,3],
[99,23,20,4],
[99,23,20,5],
[99,23,20,6],
[99,23,20,7],
[99,23,21,0],
[99,23,21,1],
[99,23,21,2],
[99,23,21,4],
[99,23,22,0],
[99,23,22,1],
[99,23,22,2],
[99,23,22,3],
[99,23,22,4],
[99,24,3,0],
[99,24,3,1],
[99,24,3,2],
[99,24,4,0],
[99,24,4,1],
[99,24,4,2],
[99,24,4,3],
[99,24,5,0],
[99,24,5,1],
[99,24,5,2],
[99,24,6,4],
[99,24,7,3],
[99,24,7,4],
[99,24,7,5],
[99,24,7,6],
[99,24,8,0],
[99,24,8,1],
[99,24,8,2],
[99,24,8,4],
[99,24,8,7],
[99,24,9,0],
[99,24,9,1],
[99,24,9,2],
[99,24,9,3],
[99,24,9,4],
[99,24,9,5],
[99,24,9,6],
[99,24,9,7],
[99,24,9,8],
[99,24,10,0],
[99,24,10,1],
[99,24,10,2],
[99,24,10,3],
[99,24,10,4],
[99,24,10,5],
[99,24,10,6],
[99,24,10,7],
[99,24,10,8],
[99,24,10,9],
[99,24,11,0],
[99,24,11,1],
[99,24,11,2],
[99,24,11,3],
[99,24,11,4],
[99,24,11,5],
[99,24,11,6],
[99,24,11,7],
[99,24,11,8],
[99,24,11,9],
[99,24,11,10],
[99,24,12,0],
[99,24,12,1],
[99,24,12,2],
[99,24,12,3],
[99,24,12,4],
[99,24,12,5],
[99,24,12,6],
[99,24,12,7],
[99,24,12,8],
[99,24,12,9],
[99,24,12,10],
[99,24,13,0],
[99,24,13,1],
[99,24,13,2],
[99,24,13,3],
[99,24,13,4],
[99,24,13,5],
[99,24,13,6],
[99,24,13,7],
[99,24,13,8],
[99,24,13,9],
[99,24,13,10],
[99,24,13,11],
[99,24,13,12],
[99,24,14,0],
[99,24,14,1],
[99,24,14,2],
[99,24,14,3],
[99,24,14,4],
[99,24,14,5],
[99,24,14,6],
[99,24,14,7],
[99,24,14,8],
[99,24,14,9],
[99,24,14,10],
[99,24,14,11],
[99,24,14,12],
[99,24,15,0],
[99,24,15,1],
[99,24,15,2],
[99,24,15,3],
[99,24,15,4],
[99,24,15,5],
[99,24,15,6],
[99,24,15,7],
[99,24,15,8],
[99,24,15,10],
[99,24,15,11],
[99,24,15,12],
[99,24,15,13],
[99,24,15,14],
[99,24,17,0],
[99,24,17,1],
[99,24,17,2],
[99,24,17,3],
[99,24,17,4],
[99,24,17,5],
[99,24,17,6],
[99,24,17,7],
[99,24,17,8],
[99,24,17,9],
[99,24,17,10],
[99,24,17,11],
[99,24,17,12],
[99,24,17,13],
[99,24,18,0],
[99,24,18,1],
[99,24,18,2],
[99,24,18,3],
[99,24,18,4],
[99,24,18,5],
[99,24,18,6],
[99,24,18,7],
[99,24,18,8],
[99,24,18,10],
[99,24,18,11],
[99,24,19,0],
[99,24,19,1],
[99,24,19,2],
[99,24,19,3],
[99,24,19,4],
[99,24,19,5],
[99,24,19,6],
[99,24,19,7],
[99,24,19,8],
[99,24,19,9],
[99,24,20,0],
[99,24,20,1],
[99,24,20,2],
[99,24,20,3],
[99,24,20,4],
[99,24,20,5],
[99,24,20,6],
[99,24,20,7],
[99,24,21,0],
[99,24,21,1],
[99,24,21,2],
[99,24,21,4],
[99,24,22,0],
[99,24,22,1],
[99,24,22,2],
[99,24,22,3],
[99,24,22,4],
[99,24,23,0],
[99,24,23,1],
[99,24,23,2],
[99,25,4,3],
[99,25,5,0],
[99,25,5,1],
[99,25,5,2],
[99,25,5,4],
[99,25,6,0],
[99,25,6,1],
[99,25,6,2],
[99,25,6,4],
[99,25,8,0],
[99,25,8,1],
[99,25,8,2],
[99,25,8,4],
[99,25,9,0],
[99,25,9,1],
[99,25,9,2],
[99,25,9,3],
[99,25,9,4],
[99,25,9,5],
[99,25,9,6],
[99,25,9,8],
[99,25,10,0],
[99,25,10,1],
[99,25,10,2],
[99,25,10,3],
[99,25,10,4],
[99,25,10,5],
[99,25,10,6],
[99,25,10,8],
[99,25,10,9],
[99,25,11,0],
[99,25,11,1],
[99,25,11,2],
[99,25,11,3],
[99,25,11,4],
[99,25,11,5],
[99,25,11,6],
[99,25,11,8],
[99,25,11,9],
[99,25,11,10],
[99,25,12,0],
[99,25,12,1],
[99,25,12,2],
[99,25,12,3],
[99,25,12,4],
[99,25,12,5],
[99,25,12,6],
[99,25,12,8],
[99,25,12,9],
[99,25,12,10],
[99,25,13,0],
[99,25,13,1],
[99,25,13,2],
[99,25,13,3],
[99,25,13,4],
[99,25,13,5],
[99,25,13,6],
[99,25,13,8],
[99,25,13,9],
[99,25,13,10],
[99,25,13,11],
[99,25,13,12],
[99,25,14,0],
[99,25,14,1],
[99,25,14,2],
[99,25,14,3],
[99,25,14,4],
[99,25,14,5],
[99,25,14,6],
[99,25,14,8],
[99,25,14,9],
[99,25,14,10],
[99,25,14,11],
[99,25,14,12],
[99,25,15,0],
[99,25,15,1],
[99,25,15,2],
[99,25,15,3],
[99,25,15,4],
[99,25,15,5],
[99,25,15,6],
[99,25,15,8],
[99,25,15,10],
[99,25,15,11],
[99,25,16,0],
[99,25,16,1],
[99,25,16,2],
[99,25,16,3],
[99,25,16,4],
[99,25,16,5],
[99,25,16,6],
[99,25,16,8],
[99,25,16,9],
[99,25,17,0],
[99,25,17,1],
[99,25,17,2],
[99,25,17,3],
[99,25,17,4],
[99,25,17,5],
[99,25,17,6],
[99,25,17,8],
[99,25,17,9],
[99,25,18,0],
[99,25,18,1],
[99,25,18,2],
[99,25,18,3],
[99,25,18,4],
[99,25,18,5],
[99,25,18,6],
[99,25,19,0],
[99,25,19,1],
[99,25,19,2],
[99,25,19,3],
[99,25,19,4],
[99,25,19,5],
[99,25,20,0],
[99,25,20,1],
[99,25,20,2],
[99,25,20,3],
[99,25,20,4],
[99,25,21,0],
[99,25,21,1],
[99,25,21,2],
[99,26,3,0],
[99,26,3,1],
[99,26,3,2],
[99,26,4,0],
[99,26,4,1],
[99,26,4,2],
[99,26,4,3],
[99,26,5,0],
[99,26,5,1],
[99,26,5,2],
[99,26,5,4],
[99,26,6,0],
[99,26,6,1],
[99,26,6,2],
[99,26,6,4],
[99,26,7,0],
[99,26,7,1],
[99,26,7,2],
[99,26,7,3],
[99,26,7,4],
[99,26,7,5],
[99,26,7,6],
[99,26,8,0],
[99,26,8,1],
[99,26,8,2],
[99,26,8,4],
[99,26,8,7],
[99,26,10,0],
[99,26,10,1],
[99,26,10,2],
[99,26,10,3],
[99,26,10,4],
[99,26,10,5],
[99,26,10,6],
[99,26,10,7],
[99,26,10,8],
[99,26,11,0],
[99,26,11,1],
[99,26,11,2],
[99,26,11,3],
[99,26,11,4],
[99,26,11,5],
[99,26,11,6],
[99,26,11,7],
[99,26,11,8],
[99,26,11,10],
[99,26,12,0],
[99,26,12,1],
[99,26,12,2],
[99,26,12,3],
[99,26,12,4],
[99,26,12,5],
[99,26,12,6],
[99,26,12,7],
[99,26,12,8],
[99,26,12,10],
[99,26,13,0],
[99,26,13,1],
[99,26,13,2],
[99,26,13,3],
[99,26,13,4],
[99,26,13,5],
[99,26,13,6],
[99,26,13,7],
[99,26,13,8],
[99,26,13,10],
[99,26,13,11],
[99,26,13,12],
[99,26,14,0],
[99,26,14,1],
[99,26,14,2],
[99,26,14,3],
[99,26,14,4],
[99,26,14,5],
[99,26,14,6],
[99,26,14,7],
[99,26,14,8],
[99,26,14,10],
[99,26,14,11],
[99,26,16,0],
[99,26,16,1],
[99,26,16,2],
[99,26,16,3],
[99,26,16,4],
[99,26,16,5],
[99,26,16,6],
[99,26,16,7],
[99,26,17,0],
[99,26,17,1],
[99,26,17,2],
[99,26,17,3],
[99,26,17,4],
[99,26,17,5],
[99,26,17,6],
[99,26,17,7],
[99,26,19,0],
[99,26,19,1],
[99,26,19,2],
[99,26,19,3],
[99,26,19,4],
[99,26,20,0],
[99,26,20,1],
[99,26,20,2],
[99,27,3,0],
[99,27,3,1],
[99,27,3,2],
[99,27,4,0],
[99,27,4,1],
[99,27,4,2],
[99,27,4,3],
[99,27,5,0],
[99,27,5,1],
[99,27,5,2],
[99,27,5,4],
[99,27,6,0],
[99,27,6,1],
[99,27,6,2],
[99,27,6,4],
[99,27,7,0],
[99,27,7,1],
[99,27,7,2],
[99,27,7,3],
[99,27,7,4],
[99,27,7,5],
[99,27,7,6],
[99,27,8,0],
[99,27,8,1],
[99,27,8,2],
[99,27,8,4],
[99,27,8,7],
[99,27,9,0],
[99,27,9,1],
[99,27,9,2],
[99,27,9,3],
[99,27,9,4],
[99,27,9,5],
[99,27,9,6],
[99,27,9,7],
[99,27,9,8],
[99,27,11,0],
[99,27,11,1],
[99,27,11,2],
[99,27,11,3],
[99,27,11,4],
[99,27,11,5],
[99,27,11,6],
[99,27,11,7],
[99,27,11,8],
[99,27,11,9],
[99,27,12,0],
[99,27,12,1],
[99,27,12,2],
[99,27,12,3],
[99,27,12,4],
[99,27,12,5],
[99,27,12,6],
[99,27,12,7],
[99,27,12,8],
[99,27,12,9],
[99,27,13,0],
[99,27,13,1],
[99,27,13,2],
[99,27,13,3],
[99,27,13,4],
[99,27,13,5],
[99,27,13,6],
[99,27,13,7],
[99,27,13,8],
[99,27,13,9],
[99,27,13,11],
[99,27,14,0],
[99,27,14,1],
[99,27,14,2],
[99,27,14,3],
[99,27,14,4],
[99,27,14,5],
[99,27,14,6],
[99,27,14,7],
[99,27,14,8],
[99,27,14,9],
[99,27,15,0],
[99,27,15,1],
[99,27,15,2],
[99,27,15,3],
[99,27,15,4],
[99,27,15,5],
[99,27,15,6],
[99,27,15,7],
[99,27,16,0],
[99,27,16,1],
[99,27,16,2],
[99,27,16,3],
[99,27,16,4],
[99,27,16,5],
[99,27,17,0],
[99,27,17,1],
[99,27,17,2],
[99,27,17,3],
[99,27,17,4],
[99,27,17,5],
[99,27,18,0],
[99,27,18,1],
[99,27,18,2],
[99,27,18,3],
[99,27,18,4],
[99,27,19,0],
[99,27,19,1],
[99,27,19,2],
[99,28,3,0],
[99,28,3,1],
[99,28,3,2],
[99,28,4,0],
[99,28,4,1],
[99,28,4,2],
[99,28,4,3],
[99,28,5,0],
[99,28,5,1],
[99,28,5,2],
[99,28,5,4],
[99,28,6,0],
[99,28,6,1],
[99,28,6,2],
[99,28,6,4],
[99,28,8,0],
[99,28,8,1],
[99,28,8,2],
[99,28,8,4],
[99,28,9,0],
[99,28,9,1],
[99,28,9,2],
[99,28,9,3],
[99,28,9,4],
[99,28,9,5],
[99,28,9,6],
[99,28,9,8],
[99,28,10,0],
[99,28,10,1],
[99,28,10,2],
[99,28,10,3],
[99,28,10,4],
[99,28,10,5],
[99,28,10,6],
[99,28,10,8],
[99,28,10,9],
[99,28,11,0],
[99,28,11,1],
[99,28,11,2],
[99,28,11,3],
[99,28,11,4],
[99,28,11,5],
[99,28,11,6],
[99,28,11,8],
[99,28,11,9],
[99,28,11,10],
[99,28,12,0],
[99,28,12,1],
[99,28,12,2],
[99,28,12,3],
[99,28,12,4],
[99,28,12,5],
[99,28,12,6],
[99,28,12,8],
[99,28,12,9],
[99,28,13,0],
[99,28,13,1],
[99,28,13,2],
[99,28,13,3],
[99,28,13,4],
[99,28,13,5],
[99,28,13,6],
[99,28,13,8],
[99,28,13,9],
[99,28,14,0],
[99,28,14,1],
[99,28,14,2],
[99,28,14,3],
[99,28,14,4],
[99,28,14,5],
[99,28,14,6],
[99,28,15,0],
[99,28,15,1],
[99,28,15,2],
[99,28,15,3],
[99,28,15,4],
[99,28,15,5],
[99,28,16,0],
[99,28,16,1],
[99,28,16,2],
[99,28,16,3],
[99,28,16,4],
[99,28,17,0],
[99,28,17,1],
[99,28,17,2],
[99,28,17,3],
[99,28,17,4],
[99,28,18,0],
[99,28,18,1],
[99,28,18,2],
[99,29,3,0],
[99,29,3,1],
[99,29,3,2],
[99,29,4,0],
[99,29,4,1],
[99,29,4,2],
[99,29,4,3],
[99,29,5,0],
[99,29,5,1],
[99,29,5,2],
[99,29,5,4],
[99,29,6,0],
[99,29,6,1],
[99,29,6,2],
[99,29,6,4],
[99,29,7,0],
[99,29,7,1],
[99,29,7,2],
[99,29,7,3],
[99,29,7,4],
[99,29,7,5],
[99,29,7,6],
[99,29,8,0],
[99,29,8,1],
[99,29,8,2],
[99,29,8,4],
[99,29,8,7],
[99,29,9,0],
[99,29,9,1],
[99,29,9,2],
[99,29,9,3],
[99,29,9,4],
[99,29,9,5],
[99,29,9,6],
[99,29,9,7],
[99,29,9,8],
[99,29,10,0],
[99,29,10,1],
[99,29,10,2],
[99,29,10,3],
[99,29,10,4],
[99,29,10,5],
[99,29,10,6],
[99,29,10,7],
[99,29,10,8],
[99,29,10,9],
[99,29,13,0],
[99,29,13,1],
[99,29,13,2],
[99,29,13,3],
[99,29,13,4],
[99,29,13,5],
[99,29,13,6],
[99,29,13,7],
[99,29,14,0],
[99,29,14,1],
[99,29,14,2],
[99,29,14,3],
[99,29,14,4],
[99,29,14,5],
[99,29,15,0],
[99,29,15,1],
[99,29,15,2],
[99,29,15,3],
[99,29,15,4],
[99,29,16,0],
[99,29,16,1],
[99,29,16,2],
[99,29,17,0],
[99,29,17,1],
[99,29,17,2],
[99,30,3,0],
[99,30,3,1],
[99,30,3,2],
[99,30,4,0],
[99,30,4,1],
[99,30,4,2],
[99,30,4,3],
[99,30,5,0],
[99,30,5,1],
[99,30,5,2],
[99,30,5,4],
[99,30,6,0],
[99,30,6,1],
[99,30,6,2],
[99,30,6,4],
[99,30,7,0],
[99,30,7,1],
[99,30,7,2],
[99,30,7,3],
[99,30,7,4],
[99,30,7,5],
[99,30,7,6],
[99,30,8,0],
[99,30,8,1],
[99,30,8,2],
[99,30,8,4],
[99,30,8,7],
[99,30,9,0],
[99,30,9,1],
[99,30,9,2],
[99,30,9,3],
[99,30,9,4],
[99,30,9,5],
[99,30,9,6],
[99,30,9,7],
[99,30,9,8],
[99,30,10,0],
[99,30,10,1],
[99,30,10,2],
[99,30,10,3],
[99,30,10,4],
[99,30,10,5],
[99,30,10,6],
[99,30,10,7],
[99,30,11,0],
[99,30,11,1],
[99,30,11,2],
[99,30,11,3],
[99,30,11,4],
[99,30,11,5],
[99,30,11,6],
[99,30,11,7],
[99,30,12,0],
[99,30,12,1],
[99,30,12,2],
[99,30,12,3],
[99,30,12,4],
[99,30,12,5],
[99,30,13,0],
[99,30,13,1],
[99,30,13,2],
[99,30,13,3],
[99,30,13,4],
[99,30,13,5],
[99,30,14,0],
[99,30,14,1],
[99,30,14,2],
[99,30,14,3],
[99,30,14,4],
[99,30,15,0],
[99,30,15,1],
[99,30,15,2],
[99,31,3,0],
[99,31,3,1],
[99,31,3,2],
[99,31,4,0],
[99,31,4,1],
[99,31,4,2],
[99,31,4,3],
[99,31,5,0],
[99,31,5,1],
[99,31,5,2],
[99,31,5,4],
[99,31,6,0],
[99,31,6,1],
[99,31,6,2],
[99,31,6,4],
[99,31,7,0],
[99,31,7,1],
[99,31,7,2],
[99,31,7,3],
[99,31,7,4],
[99,31,7,5],
[99,31,7,6],
[99,31,8,0],
[99,31,8,1],
[99,31,8,2],
[99,31,8,4],
[99,31,8,7],
[99,31,9,0],
[99,31,9,1],
[99,31,9,2],
[99,31,9,3],
[99,31,9,4],
[99,31,9,5],
[99,31,9,6],
[99,31,9,7],
[99,31,10,0],
[99,31,10,1],
[99,31,10,2],
[99,31,10,3],
[99,31,10,4],
[99,31,10,5],
[99,31,11,0],
[99,31,11,1],
[99,31,11,2],
[99,31,11,3],
[99,31,11,4],
[99,31,11,5],
[99,31,12,0],
[99,31,12,1],
[99,31,12,2],
[99,31,12,3],
[99,31,12,4],
[99,31,13,0],
[99,31,13,1],
[99,31,13,2],
[99,31,13,3],
[99,31,13,4],
[99,31,14,0],
[99,31,14,1],
[99,31,14,2],
[99,32,3,0],
[99,32,3,1],
[99,32,3,2],
[99,32,4,0],
[99,32,4,1],
[99,32,4,2],
[99,32,4,3],
[99,32,5,0],
[99,32,5,1],
[99,32,5,2],
[99,32,5,4],
[99,32,6,0],
[99,32,6,1],
[99,32,6,2],
[99,32,6,4],
[99,32,7,0],
[99,32,7,1],
[99,32,7,2],
[99,32,7,3],
[99,32,7,4],
[99,32,7,5],
[99,32,7,6],
[99,32,8,0],
[99,32,8,1],
[99,32,8,2],
[99,32,8,4],
[99,32,9,0],
[99,32,9,1],
[99,32,9,2],
[99,32,9,3],
[99,32,9,4],
[99,32,9,5],
[99,32,10,0],
[99,32,10,1],
[99,32,10,2],
[99,32,10,3],
[99,32,10,4],
[99,32,13,0],
[99,32,13,1],
[99,32,13,2],
[99,33,3,0],
[99,33,3,1],
[99,33,3,2],
[99,33,4,0],
[99,33,4,1],
[99,33,4,2],
[99,33,4,3],
[99,33,5,0],
[99,33,5,1],
[99,33,5,2],
[99,33,5,4],
[99,33,6,0],
[99,33,6,1],
[99,33,6,2],
[99,33,6,4],
[99,33,7,0],
[99,33,7,1],
[99,33,7,2],
[99,33,7,3],
[99,33,7,4],
[99,33,7,5],
[99,33,8,0],
[99,33,8,1],
[99,33,8,2],
[99,33,8,4],
[99,33,9,0],
[99,33,9,1],
[99,33,9,2],
[99,33,9,3],
[99,33,9,4],
[99,33,10,0],
[99,33,10,1],
[99,33,10,2],
[99,34,3,0],
[99,34,3,1],
[99,34,3,2],
[99,34,4,0],
[99,34,4,1],
[99,34,4,2],
[99,34,4,3],
[99,34,5,0],
[99,34,5,1],
[99,34,5,2],
[99,34,5,4],
[99,34,6,0],
[99,34,6,1],
[99,34,6,2],
[99,34,6,4],
[99,34,8,0],
[99,34,8,1],
[99,34,8,2],
[99,34,9,0],
[99,34,9,1],
[99,34,9,2],
[99,35,3,0],
[99,35,3,1],
[99,35,3,2],
[99,35,4,0],
[99,35,4,1],
[99,35,4,2],
[99,35,4,3],
[99,35,5,0],
[99,35,5,1],
[99,35,5,2],
[99,35,5,4],
[99,35,6,0],
[99,35,6,1],
[99,35,6,2],
[99,35,7,0],
[99,35,7,1],
[99,35,7,2],
[99,36,3,0],
[99,36,3,1],
[99,36,3,2],
[99,36,4,0],
[99,36,4,1],
[99,36,4,2],
[99,36,4,3],
[99,36,5,0],
[99,36,5,1],
[99,36,5,2],
[99,37,3,0],
[99,37,3,1],
[99,37,3,2],
[100,7,4,0],
[100,7,4,1],
[100,7,4,2],
[100,9,4,0],
[100,9,4,1],
[100,9,4,2],
[100,9,7,0],
[100,9,7,1],
[100,9,7,2],
[100,9,7,4],
[100,10,4,0],
[100,10,4,1],
[100,10,4,2],
[100,10,7,0],
[100,10,7,1],
[100,10,7,2],
[100,10,7,4],
[100,10,9,0],
[100,10,9,1],
[100,10,9,2],
[100,10,9,4],
[100,10,9,7],
[100,11,4,0],
[100,11,4,1],
[100,11,4,2],
[100,11,7,0],
[100,11,7,1],
[100,11,7,2],
[100,11,7,4],
[100,11,9,0],
[100,11,9,1],
[100,11,9,2],
[100,11,9,4],
[100,11,9,7],
[100,11,10,0],
[100,11,10,1],
[100,11,10,2],
[100,11,10,4],
[100,11,10,7],
[100,11,10,9],
[100,12,4,0],
[100,12,4,1],
[100,12,4,2],
[100,12,7,0],
[100,12,7,1],
[100,12,7,2],
[100,12,7,4],
[100,12,9,0],
[100,12,9,1],
[100,12,9,2],
[100,12,9,4],
[100,12,9,7],
[100,12,10,0],
[100,12,10,1],
[100,12,10,2],
[100,12,10,4],
[100,12,10,7],
[100,12,10,9],
[100,13,4,0],
[100,13,4,1],
[100,13,4,2],
[100,13,7,0],
[100,13,7,1],
[100,13,7,2],
[100,13,7,4],
[100,13,9,0],
[100,13,9,1],
[100,13,9,2],
[100,13,9,4],
[100,13,9,7],
[100,13,10,0],
[100,13,10,1],
[100,13,10,2],
[100,13,10,4],
[100,13,10,7],
[100,13,10,9],
[100,13,11,0],
[100,13,11,1],
[100,13,11,2],
[100,13,11,4],
[100,13,11,7],
[100,13,11,9],
[100,13,11,10],
[100,13,12,0],
[100,13,12,1],
[100,13,12,2],
[100,13,12,4],
[100,13,12,7],
[100,13,12,9],
[100,14,4,0],
[100,14,4,1],
[100,14,4,2],
[100,14,7,0],
[100,14,7,1],
[100,14,7,2],
[100,14,7,4],
[100,14,9,0],
[100,14,9,1],
[100,14,9,2],
[100,14,9,4],
[100,14,9,7],
[100,14,10,0],
[100,14,10,1],
[100,14,10,2],
[100,14,10,4],
[100,14,10,7],
[100,14,10,9],
[100,14,11,0],
[100,14,11,1],
[100,14,11,2],
[100,14,11,4],
[100,14,11,7],
[100,14,11,9],
[100,14,12,0],
[100,14,12,1],
[100,14,12,2],
[100,14,12,4],
[100,14,12,7],
[100,14,12,10],
[100,15,4,0],
[100,15,4,1],
[100,15,4,2],
[100,15,7,0],
[100,15,7,1],
[100,15,7,2],
[100,15,7,4],
[100,15,10,0],
[100,15,10,1],
[100,15,10,2],
[100,15,10,4],
[100,15,10,7],
[100,15,11,0],
[100,15,11,1],
[100,15,11,2],
[100,15,11,4],
[100,15,11,7],
[100,15,11,10],
[100,15,12,0],
[100,15,12,1],
[100,15,12,2],
[100,15,12,4],
[100,15,12,10],
[100,15,13,0],
[100,15,13,1],
[100,15,13,2],
[100,15,13,4],
[100,15,13,10],
[100,15,13,11],
[100,15,13,12],
[100,15,14,0],
[100,15,14,1],
[100,15,14,2],
[100,15,14,4],
[100,15,14,7],
[100,15,14,10],
[100,15,14,11],
[100,15,14,12],
[100,16,4,0],
[100,16,4,1],
[100,16,4,2],
[100,16,7,0],
[100,16,7,1],
[100,16,7,2],
[100,16,7,4],
[100,16,9,0],
[100,16,9,1],
[100,16,9,2],
[100,16,9,4],
[100,16,9,7],
[100,16,10,0],
[100,16,10,1],
[100,16,10,2],
[100,16,10,4],
[100,16,10,9],
[100,16,11,0],
[100,16,11,1],
[100,16,11,2],
[100,16,11,4],
[100,16,11,9],
[100,16,11,10],
[100,16,12,0],
[100,16,12,1],
[100,16,12,2],
[100,16,12,4],
[100,16,12,7],
[100,16,12,9],
[100,16,12,10],
[100,16,13,0],
[100,16,13,1],
[100,16,13,2],
[100,16,13,4],
[100,16,13,7],
[100,16,13,9],
[100,16,13,10],
[100,16,13,11],
[100,16,13,12],
[100,16,14,0],
[100,16,14,1],
[100,16,14,2],
[100,16,14,7],
[100,16,14,9],
[100,16,14,10],
[100,16,14,11],
[100,16,14,12],
[100,16,15,4],
[100,16,15,7],
[100,16,15,10],
[100,16,15,11],
[100,16,15,12],
[100,16,15,13],
[100,16,15,14],
[100,17,4,0],
[100,17,4,1],
[100,17,4,2],
[100,17,7,0],
[100,17,7,1],
[100,17,7,2],
[100,17,7,4],
[100,17,9,0],
[100,17,9,1],
[100,17,9,2],
[100,17,9,4],
[100,17,9,7],
[100,17,10,0],
[100,17,10,1],
[100,17,10,2],
[100,17,10,4],
[100,17,10,9],
[100,17,11,0],
[100,17,11,1],
[100,17,11,2],
[100,17,11,4],
[100,17,11,9],
[100,17,11,10],
[100,17,12,0],
[100,17,12,1],
[100,17,12,2],
[100,17,12,4],
[100,17,12,7],
[100,17,12,9],
[100,17,12,10],
[100,17,13,0],
[100,17,13,1],
[100,17,13,2],
[100,17,13,4],
[100,17,13,7],
[100,17,13,9],
[100,17,13,10],
[100,17,13,11],
[100,17,13,12],
[100,17,14,0],
[100,17,14,1],
[100,17,14,2],
[100,17,14,7],
[100,17,14,9],
[100,17,14,10],
[100,17,14,11],
[100,17,14,12],
[100,17,15,4],
[100,17,15,7],
[100,17,15,10],
[100,17,15,11],
[100,17,15,12],
[100,17,15,13],
[100,17,15,14],
[100,17,16,0],
[100,17,16,1],
[100,17,16,2],
[100,17,16,4],
[100,17,16,7],
[100,17,16,9],
[100,17,16,10],
[100,17,16,11],
[100,17,16,12],
[100,17,16,13],
[100,17,16,14],
[100,17,16,15],
[100,18,4,0],
[100,18,4,1],
[100,18,4,2],
[100,18,7,0],
[100,18,7,1],
[100,18,7,2],
[100,18,7,4],
[100,18,10,0],
[100,18,10,1],
[100,18,10,2],
[100,18,10,4],
[100,18,10,7],
[100,18,11,0],
[100,18,11,1],
[100,18,11,2],
[100,18,11,4],
[100,18,11,7],
[100,18,11,10],
[100,18,12,0],
[100,18,12,1],
[100,18,12,2],
[100,18,12,7],
[100,18,12,10],
[100,18,13,0],
[100,18,13,1],
[100,18,13,2],
[100,18,13,7],
[100,18,13,10],
[100,18,13,11],
[100,18,13,12],
[100,18,14,4],
[100,18,14,7],
[100,18,14,10],
[100,18,14,11],
[100,18,14,12],
[100,18,16,0],
[100,18,16,1],
[100,18,16,2],
[100,18,16,4],
[100,18,16,7],
[100,18,16,10],
[100,18,16,11],
[100,18,16,12],
[100,18,16,13],
[100,18,16,14],
[100,18,17,0],
[100,18,17,1],
[100,18,17,2],
[100,18,17,4],
[100,18,17,7],
[100,18,17,10],
[100,18,17,11],
[100,18,17,12],
[100,18,17,13],
[100,18,17,14],
[100,18,17,16],
[100,19,4,0],
[100,19,4,1],
[100,19,4,2],
[100,19,7,0],
[100,19,7,1],
[100,19,7,2],
[100,19,7,4],
[100,19,9,0],
[100,19,9,1],
[100,19,9,2],
[100,19,9,4],
[100,19,9,7],
[100,19,10,0],
[100,19,10,1],
[100,19,10,2],
[100,19,10,7],
[100,19,10,9],
[100,19,11,0],
[100,19,11,1],
[100,19,11,2],
[100,19,11,7],
[100,19,11,9],
[100,19,11,10],
[100,19,12,4],
[100,19,12,7],
[100,19,12,9],
[100,19,12,10],
[100,19,15,0],
[100,19,15,1],
[100,19,15,2],
[100,19,15,4],
[100,19,15,7],
[100,19,15,10],
[100,19,15,11],
[100,19,15,12],
[100,19,16,0],
[100,19,16,1],
[100,19,16,2],
[100,19,16,4],
[100,19,16,7],
[100,19,16,9],
[100,19,16,10],
[100,19,16,11],
[100,19,16,12],
[100,19,16,15],
[100,19,17,0],
[100,19,17,1],
[100,19,17,2],
[100,19,17,4],
[100,19,17,7],
[100,19,17,9],
[100,19,17,10],
[100,19,17,11],
[100,19,17,12],
[100,19,17,15],
[100,19,17,16],
[100,19,18,0],
[100,19,18,1],
[100,19,18,2],
[100,19,18,4],
[100,19,18,7],
[100,19,18,10],
[100,19,18,11],
[100,19,18,12],
[100,20,4,0],
[100,20,4,1],
[100,20,4,2],
[100,20,7,0],
[100,20,7,1],
[100,20,7,2],
[100,20,7,4],
[100,20,9,0],
[100,20,9,1],
[100,20,9,2],
[100,20,9,7],
[100,20,11,4],
[100,20,11,7],
[100,20,11,9],
[100,20,12,0],
[100,20,12,1],
[100,20,12,2],
[100,20,12,4],
[100,20,12,7],
[100,20,12,9],
[100,20,13,0],
[100,20,13,1],
[100,20,13,2],
[100,20,13,4],
[100,20,13,7],
[100,20,13,9],
[100,20,13,11],
[100,20,13,12],
[100,20,14,0],
[100,20,14,1],
[100,20,14,2],
[100,20,14,4],
[100,20,14,7],
[100,20,14,9],
[100,20,14,11],
[100,20,14,12],
[100,20,15,0],
[100,20,15,1],
[100,20,15,2],
[100,20,15,4],
[100,20,15,7],
[100,20,15,11],
[100,20,15,12],
[100,20,15,13],
[100,20,15,14],
[100,20,16,0],
[100,20,16,1],
[100,20,16,2],
[100,20,16,4],
[100,20,16,7],
[100,20,16,9],
[100,20,16,11],
[100,20,16,12],
[100,20,16,13],
[100,20,16,14],
[100,20,16,15],
[100,20,17,0],
[100,20,17,1],
[100,20,17,2],
[100,20,17,4],
[100,20,17,7],
[100,20,17,9],
[100,20,17,11],
[100,20,17,12],
[100,20,17,13],
[100,20,17,14],
[100,20,17,15],
[100,20,18,0],
[100,20,18,1],
[100,20,18,2],
[100,20,18,4],
[100,20,18,7],
[100,20,18,11],
[100,20,18,12],
[100,20,18,13],
[100,20,18,14],
[100,20,19,0],
[100,20,19,1],
[100,20,19,2],
[100,20,19,4],
[100,20,19,7],
[100,20,19,9],
[100,20,19,11],
[100,20,19,12],
[100,22,4,0],
[100,22,4,1],
[100,22,4,2],
[100,22,7,4],
[100,22,9,0],
[100,22,9,1],
[100,22,9,2],
[100,22,9,4],
[100,22,9,7],
[100,22,11,0],
[100,22,11,1],
[100,22,11,2],
[100,22,11,4],
[100,22,11,7],
[100,22,11,9],
[100,22,12,0],
[100,22,12,1],
[100,22,12,2],
[100,22,12,4],
[100,22,12,7],
[100,22,12,9],
[100,22,13,0],
[100,22,13,1],
[100,22,13,2],
[100,22,13,4],
[100,22,13,7],
[100,22,13,9],
[100,22,13,11],
[100,22,13,12],
[100,22,14,0],
[100,22,14,1],
[100,22,14,2],
[100,22,14,4],
[100,22,14,7],
[100,22,14,9],
[100,22,14,11],
[100,22,14,12],
[100,22,15,0],
[100,22,15,1],
[100,22,15,2],
[100,22,15,4],
[100,22,15,7],
[100,22,15,11],
[100,22,15,12],
[100,22,15,13],
[100,22,15,14],
[100,22,16,0],
[100,22,16,1],
[100,22,16,2],
[100,22,16,4],
[100,22,16,7],
[100,22,16,9],
[100,22,16,11],
[100,22,16,12],
[100,22,16,13],
[100,22,17,0],
[100,22,17,1],
[100,22,17,2],
[100,22,17,4],
[100,22,17,7],
[100,22,17,9],
[100,22,17,11],
[100,22,17,12],
[100,22,17,13],
[100,22,18,0],
[100,22,18,1],
[100,22,18,2],
[100,22,18,4],
[100,22,18,7],
[100,22,18,11],
[100,22,19,0],
[100,22,19,1],
[100,22,19,2],
[100,22,19,4],
[100,22,19,7],
[100,22,19,9],
[100,23,4,0],
[100,23,4,1],
[100,23,4,2],
[100,23,7,0],
[100,23,7,1],
[100,23,7,2],
[100,23,7,4],
[100,23,9,0],
[100,23,9,1],
[100,23,9,2],
[100,23,9,4],
[100,23,9,7],
[100,23,10,0],
[100,23,10,1],
[100,23,10,2],
[100,23,10,4],
[100,23,10,7],
[100,23,10,9],
[100,23,11,0],
[100,23,11,1],
[100,23,11,2],
[100,23,11,4],
[100,23,11,7],
[100,23,11,9],
[100,23,11,10],
[100,23,12,0],
[100,23,12,1],
[100,23,12,2],
[100,23,12,4],
[100,23,12,7],
[100,23,12,9],
[100,23,12,10],
[100,23,13,0],
[100,23,13,1],
[100,23,13,2],
[100,23,13,4],
[100,23,13,7],
[100,23,13,9],
[100,23,13,10],
[100,23,13,11],
[100,23,13,12],
[100,23,14,0],
[100,23,14,1],
[100,23,14,2],
[100,23,14,4],
[100,23,14,7],
[100,23,14,9],
[100,23,14,10],
[100,23,14,11],
[100,23,14,12],
[100,23,15,0],
[100,23,15,1],
[100,23,15,2],
[100,23,15,4],
[100,23,15,7],
[100,23,15,10],
[100,23,15,11],
[100,23,15,12],
[100,23,15,13],
[100,23,16,0],
[100,23,16,1],
[100,23,16,2],
[100,23,16,4],
[100,23,16,7],
[100,23,16,9],
[100,23,16,10],
[100,23,16,11],
[100,23,17,0],
[100,23,17,1],
[100,23,17,2],
[100,23,17,4],
[100,23,17,7],
[100,23,17,9],
[100,23,17,10],
[100,23,17,11],
[100,23,18,0],
[100,23,18,1],
[100,23,18,2],
[100,23,18,4],
[100,23,18,7],
[100,23,19,0],
[100,23,19,1],
[100,23,19,2],
[100,23,19,4],
[100,23,19,7],
[100,23,20,0],
[100,23,20,1],
[100,23,20,2],
[100,23,20,4],
[100,23,22,0],
[100,23,22,1],
[100,23,22,2],
[100,24,4,0],
[100,24,4,1],
[100,24,4,2],
[100,24,7,0],
[100,24,7,1],
[100,24,7,2],
[100,24,7,4],
[100,24,9,0],
[100,24,9,1],
[100,24,9,2],
[100,24,9,4],
[100,24,9,7],
[100,24,10,0],
[100,24,10,1],
[100,24,10,2],
[100,24,10,4],
[100,24,10,7],
[100,24,10,9],
[100,24,11,0],
[100,24,11,1],
[100,24,11,2],
[100,24,11,4],
[100,24,11,7],
[100,24,11,9],
[100,24,11,10],
[100,24,12,0],
[100,24,12,1],
[100,24,12,2],
[100,24,12,4],
[100,24,12,7],
[100,24,12,9],
[100,24,12,10],
[100,24,13,0],
[100,24,13,1],
[100,24,13,2],
[100,24,13,4],
[100,24,13,7],
[100,24,13,9],
[100,24,13,10],
[100,24,13,11],
[100,24,13,12],
[100,24,14,0],
[100,24,14,1],
[100,24,14,2],
[100,24,14,4],
[100,24,14,7],
[100,24,14,9],
[100,24,14,10],
[100,24,14,11],
[100,24,14,12],
[100,24,15,0],
[100,24,15,1],
[100,24,15,2],
[100,24,15,4],
[100,24,15,7],
[100,24,15,10],
[100,24,15,11],
[100,24,15,12],
[100,24,15,13],
[100,24,17,0],
[100,24,17,1],
[100,24,17,2],
[100,24,17,4],
[100,24,17,7],
[100,24,17,9],
[100,24,17,10],
[100,24,17,11],
[100,24,18,0],
[100,24,18,1],
[100,24,18,2],
[100,24,18,4],
[100,24,18,7],
[100,24,19,0],
[100,24,19,1],
[100,24,19,2],
[100,24,19,4],
[100,24,19,7],
[100,24,20,0],
[100,24,20,1],
[100,24,20,2],
[100,24,20,4],
[100,24,22,0],
[100,24,22,1],
[100,24,22,2],
[100,25,4,0],
[100,25,4,1],
[100,25,4,2],
[100,25,9,0],
[100,25,9,1],
[100,25,9,2],
[100,25,9,4],
[100,25,10,0],
[100,25,10,1],
[100,25,10,2],
[100,25,10,4],
[100,25,10,9],
[100,25,11,0],
[100,25,11,1],
[100,25,11,2],
[100,25,11,4],
[100,25,11,9],
[100,25,11,10],
[100,25,12,0],
[100,25,12,1],
[100,25,12,2],
[100,25,12,4],
[100,25,12,9],
[100,25,12,10],
[100,25,13,0],
[100,25,13,1],
[100,25,13,2],
[100,25,13,4],
[100,25,13,9],
[100,25,13,10],
[100,25,13,11],
[100,25,13,12],
[100,25,14,0],
[100,25,14,1],
[100,25,14,2],
[100,25,14,4],
[100,25,14,9],
[100,25,14,10],
[100,25,14,11],
[100,25,15,0],
[100,25,15,1],
[100,25,15,2],
[100,25,15,4],
[100,25,16,0],
[100,25,16,1],
[100,25,16,2],
[100,25,16,4],
[100,25,17,0],
[100,25,17,1],
[100,25,17,2],
[100,25,17,4],
[100,25,18,0],
[100,25,18,1],
[100,25,18,2],
[100,25,18,4],
[100,25,19,0],
[100,25,19,1],
[100,25,19,2],
[100,25,19,4],
[100,25,20,0],
[100,25,20,1],
[100,25,20,2],
[100,26,4,0],
[100,26,4,1],
[100,26,4,2],
[100,26,7,0],
[100,26,7,1],
[100,26,7,2],
[100,26,7,4],
[100,26,10,0],
[100,26,10,1],
[100,26,10,2],
[100,26,10,4],
[100,26,10,7],
[100,26,11,0],
[100,26,11,1],
[100,26,11,2],
[100,26,11,4],
[100,26,11,7],
[100,26,11,10],
[100,26,12,0],
[100,26,12,1],
[100,26,12,2],
[100,26,12,4],
[100,26,12,7],
[100,26,12,10],
[100,26,13,0],
[100,26,13,1],
[100,26,13,2],
[100,26,13,4],
[100,26,13,7],
[100,26,13,10],
[100,26,13,11],
[100,26,14,0],
[100,26,14,1],
[100,26,14,2],
[100,26,14,4],
[100,26,14,7],
[100,26,16,0],
[100,26,16,1],
[100,26,16,2],
[100,26,16,4],
[100,26,17,0],
[100,26,17,1],
[100,26,17,2],
[100,26,17,4],
[100,26,19,0],
[100,26,19,1],
[100,26,19,2],
[100,27,4,0],
[100,27,4,1],
[100,27,4,2],
[100,27,7,0],
[100,27,7,1],
[100,27,7,2],
[100,27,7,4],
[100,27,9,0],
[100,27,9,1],
[100,27,9,2],
[100,27,9,4],
[100,27,9,7],
[100,27,11,0],
[100,27,11,1],
[100,27,11,2],
[100,27,11,4],
[100,27,11,7],
[100,27,11,9],
[100,27,12,0],
[100,27,12,1],
[100,27,12,2],
[100,27,12,4],
[100,27,12,7],
[100,27,12,9],
[100,27,13,0],
[100,27,13,1],
[100,27,13,2],
[100,27,13,4],
[100,27,13,7],
[100,27,13,9],
[100,27,14,0],
[100,27,14,1],
[100,27,14,2],
[100,27,14,4],
[100,27,14,7],
[100,27,15,0],
[100,27,15,1],
[100,27,15,2],
[100,27,15,4],
[100,27,16,0],
[100,27,16,1],
[100,27,16,2],
[100,27,16,4],
[100,27,17,0],
[100,27,17,1],
[100,27,17,2],
[100,27,17,4],
[100,27,18,0],
[100,27,18,1],
[100,27,18,2],
[100,28,4,0],
[100,28,4,1],
[100,28,4,2],
[100,28,9,0],
[100,28,9,1],
[100,28,9,2],
[100,28,9,4],
[100,28,10,0],
[100,28,10,1],
[100,28,10,2],
[100,28,10,4],
[100,28,10,9],
[100,28,11,0],
[100,28,11,1],
[100,28,11,2],
[100,28,11,4],
[100,28,11,9],
[100,28,12,0],
[100,28,12,1],
[100,28,12,2],
[100,28,12,4],
[100,28,13,0],
[100,28,13,1],
[100,28,13,2],
[100,28,13,4],
[100,28,14,0],
[100,28,14,1],
[100,28,14,2],
[100,28,14,4],
[100,28,15,0],
[100,28,15,1],
[100,28,15,2],
[100,28,15,4],
[100,28,16,0],
[100,28,16,1],
[100,28,16,2],
[100,28,17,0],
[100,28,17,1],
[100,28,17,2],
[100,29,4,0],
[100,29,4,1],
[100,29,4,2],
[100,29,7,0],
[100,29,7,1],
[100,29,7,2],
[100,29,7,4],
[100,29,9,0],
[100,29,9,1],
[100,29,9,2],
[100,29,9,4],
[100,29,9,7],
[100,29,10,0],
[100,29,10,1],
[100,29,10,2],
[100,29,10,4],
[100,29,10,7],
[100,29,13,0],
[100,29,13,1],
[100,29,13,2],
[100,29,13,4],
[100,29,14,0],
[100,29,14,1],
[100,29,14,2],
[100,29,14,4],
[100,29,15,0],
[100,29,15,1],
[100,29,15,2],
[100,30,4,0],
[100,30,4,1],
[100,30,4,2],
[100,30,7,0],
[100,30,7,1],
[100,30,7,2],
[100,30,7,4],
[100,30,9,0],
[100,30,9,1],
[100,30,9,2],
[100,30,9,4],
[100,30,9,7],
[100,30,10,0],
[100,30,10,1],
[100,30,10,2],
[100,30,10,4],
[100,30,11,0],
[100,30,11,1],
[100,30,11,2],
[100,30,11,4],
[100,30,12,0],
[100,30,12,1],
[100,30,12,2],
[100,30,12,4],
[100,30,13,0],
[100,30,13,1],
[100,30,13,2],
[100,30,13,4],
[100,30,14,0],
[100,30,14,1],
[100,30,14,2],
[100,31,4,0],
[100,31,4,1],
[100,31,4,2],
[100,31,7,0],
[100,31,7,1],
[100,31,7,2],
[100,31,7,4],
[100,31,9,0],
[100,31,9,1],
[100,31,9,2],
[100,31,9,4],
[100,31,10,0],
[100,31,10,1],
[100,31,10,2],
[100,31,10,4],
[100,31,11,0],
[100,31,11,1],
[100,31,11,2],
[100,31,11,4],
[100,31,12,0],
[100,31,12,1],
[100,31,12,2],
[100,31,13,0],
[100,31,13,1],
[100,31,13,2],
[100,32,4,0],
[100,32,4,1],
[100,32,4,2],
[100,32,7,0],
[100,32,7,1],
[100,32,7,2],
[100,32,7,4],
[100,32,9,0],
[100,32,9,1],
[100,32,9,2],
[100,32,9,4],
[100,32,10,0],
[100,32,10,1],
[100,32,10,2],
[100,33,4,0],
[100,33,4,1],
[100,33,4,2],
[100,33,7,0],
[100,33,7,1],
[100,33,7,2],
[100,33,7,4],
[100,33,9,0],
[100,33,9,1],
[100,33,9,2],
[100,34,4,0],
[100,34,4,1],
[100,34,4,2],
[100,35,4,0],
[100,35,4,1],
[100,35,4,2],
[100,36,4,0],
[100,36,4,1],
[100,36,4,2],
[101,4,3,0],
[101,4,3,1],
[101,4,3,2],
[101,5,4,0],
[101,5,4,1],
[101,5,4,2],
[101,6,4,0],
[101,6,4,1],
[101,6,4,2],
[101,7,3,0],
[101,7,3,1],
[101,7,3,2],
[101,7,4,0],
[101,7,4,1],
[101,7,4,2],
[101,7,4,3],
[101,7,5,0],
[101,7,5,1],
[101,7,5,2],
[101,7,5,4],
[101,7,6,0],
[101,7,6,1],
[101,7,6,2],
[101,7,6,4],
[101,8,4,0],
[101,8,4,1],
[101,8,4,2],
[101,8,7,0],
[101,8,7,1],
[101,8,7,2],
[101,8,7,4],
[101,10,3,0],
[101,10,3,1],
[101,10,3,2],
[101,10,4,0],
[101,10,4,1],
[101,10,4,2],
[101,10,4,3],
[101,10,5,0],
[101,10,5,1],
[101,10,5,2],
[101,10,5,4],
[101,10,6,0],
[101,10,6,1],
[101,10,6,2],
[101,10,6,4],
[101,10,7,0],
[101,10,7,1],
[101,10,7,2],
[101,10,7,3],
[101,10,7,4],
[101,10,7,5],
[101,10,7,6],
[101,10,8,0],
[101,10,8,1],
[101,10,8,2],
[101,10,8,4],
[101,10,8,7],
[101,11,3,0],
[101,11,3,1],
[101,11,3,2],
[101,11,4,0],
[101,11,4,1],
[101,11,4,2],
[101,11,4,3],
[101,11,5,0],
[101,11,5,1],
[101,11,5,2],
[101,11,5,4],
[101,11,6,0],
[101,11,6,1],
[101,11,6,2],
[101,11,6,4],
[101,11,7,0],
[101,11,7,1],
[101,11,7,2],
[101,11,7,3],
[101,11,7,4],
[101,11,7,5],
[101,11,7,6],
[101,11,8,0],
[101,11,8,1],
[101,11,8,2],
[101,11,8,4],
[101,11,8,7],
[101,11,10,0],
[101,11,10,1],
[101,11,10,2],
[101,11,10,3],
[101,11,10,4],
[101,11,10,5],
[101,11,10,6],
[101,11,10,7],
[101,11,10,8],
[101,12,3,0],
[101,12,3,1],
[101,12,3,2],
[101,12,4,0],
[101,12,4,1],
[101,12,4,2],
[101,12,4,3],
[101,12,5,0],
[101,12,5,1],
[101,12,5,2],
[101,12,5,4],
[101,12,6,0],
[101,12,6,1],
[101,12,6,2],
[101,12,6,4],
[101,12,7,0],
[101,12,7,1],
[101,12,7,2],
[101,12,7,3],
[101,12,7,4],
[101,12,7,5],
[101,12,7,6],
[101,12,8,0],
[101,12,8,1],
[101,12,8,2],
[101,12,8,4],
[101,12,8,7],
[101,12,10,0],
[101,12,10,1],
[101,12,10,2],
[101,12,10,3],
[101,12,10,4],
[101,12,10,5],
[101,12,10,6],
[101,12,10,7],
[101,12,10,8],
[101,13,3,0],
[101,13,3,1],
[101,13,3,2],
[101,13,4,0],
[101,13,4,1],
[101,13,4,2],
[101,13,4,3],
[101,13,5,0],
[101,13,5,1],
[101,13,5,2],
[101,13,5,4],
[101,13,6,0],
[101,13,6,1],
[101,13,6,2],
[101,13,6,4],
[101,13,7,0],
[101,13,7,1],
[101,13,7,2],
[101,13,7,3],
[101,13,7,4],
[101,13,7,5],
[101,13,7,6],
[101,13,8,0],
[101,13,8,1],
[101,13,8,2],
[101,13,8,4],
[101,13,8,7],
[101,13,10,0],
[101,13,10,1],
[101,13,10,2],
[101,13,10,3],
[101,13,10,4],
[101,13,10,5],
[101,13,10,6],
[101,13,10,7],
[101,13,10,8],
[101,13,11,0],
[101,13,11,1],
[101,13,11,2],
[101,13,11,3],
[101,13,11,4],
[101,13,11,5],
[101,13,11,6],
[101,13,11,7],
[101,13,11,8],
[101,13,12,0],
[101,13,12,1],
[101,13,12,2],
[101,13,12,3],
[101,13,12,4],
[101,13,12,5],
[101,13,12,6],
[101,13,12,7],
[101,13,12,10],
[101,14,3,0],
[101,14,3,1],
[101,14,3,2],
[101,14,4,0],
[101,14,4,1],
[101,14,4,2],
[101,14,4,3],
[101,14,5,0],
[101,14,5,1],
[101,14,5,2],
[101,14,5,4],
[101,14,6,0],
[101,14,6,1],
[101,14,6,2],
[101,14,6,4],
[101,14,7,0],
[101,14,7,1],
[101,14,7,2],
[101,14,7,3],
[101,14,7,4],
[101,14,7,5],
[101,14,7,6],
[101,14,8,0],
[101,14,8,1],
[101,14,8,2],
[101,14,8,4],
[101,14,8,7],
[101,14,10,0],
[101,14,10,1],
[101,14,10,2],
[101,14,10,3],
[101,14,10,4],
[101,14,10,5],
[101,14,10,6],
[101,14,10,7],
[101,14,11,0],
[101,14,11,1],
[101,14,11,2],
[101,14,11,3],
[101,14,11,4],
[101,14,11,5],
[101,14,11,6],
[101,14,11,7],
[101,14,11,10],
[101,14,12,0],
[101,14,12,1],
[101,14,12,2],
[101,14,12,3],
[101,14,12,4],
[101,14,12,5],
[101,14,12,8],
[101,14,12,10],
[101,16,3,0],
[101,16,3,1],
[101,16,3,2],
[101,16,4,0],
[101,16,4,1],
[101,16,4,2],
[101,16,4,3],
[101,16,5,0],
[101,16,5,1],
[101,16,5,2],
[101,16,5,4],
[101,16,6,0],
[101,16,6,1],
[101,16,6,2],
[101,16,6,4],
[101,16,7,0],
[101,16,7,1],
[101,16,7,2],
[101,16,7,3],
[101,16,7,4],
[101,16,7,5],
[101,16,7,6],
[101,16,8,0],
[101,16,8,1],
[101,16,8,2],
[101,16,8,4],
[101,16,10,0],
[101,16,10,1],
[101,16,10,2],
[101,16,10,3],
[101,16,10,4],
[101,16,10,6],
[101,16,10,7],
[101,16,10,8],
[101,16,11,0],
[101,16,11,1],
[101,16,11,2],
[101,16,11,3],
[101,16,11,4],
[101,16,11,6],
[101,16,11,7],
[101,16,11,8],
[101,16,11,10],
[101,16,12,0],
[101,16,12,1],
[101,16,12,2],
[101,16,12,5],
[101,16,12,6],
[101,16,12,7],
[101,16,12,8],
[101,16,12,10],
[101,16,13,0],
[101,16,13,1],
[101,16,13,2],
[101,16,13,5],
[101,16,13,6],
[101,16,13,7],
[101,16,13,8],
[101,16,13,10],
[101,16,13,11],
[101,16,13,12],
[101,16,14,3],
[101,16,14,4],
[101,16,14,5],
[101,16,14,6],
[101,16,14,7],
[101,16,14,8],
[101,16,14,10],
[101,16,14,11],
[101,16,14,12],
[101,17,3,0],
[101,17,3,1],
[101,17,3,2],
[101,17,4,0],
[101,17,4,1],
[101,17,4,2],
[101,17,4,3],
[101,17,5,0],
[101,17,5,1],
[101,17,5,2],
[101,17,5,4],
[101,17,6,0],
[101,17,6,1],
[101,17,6,2],
[101,17,6,4],
[101,17,7,0],
[101,17,7,1],
[101,17,7,2],
[101,17,7,3],
[101,17,7,4],
[101,17,7,5],
[101,17,7,6],
[101,17,8,0],
[101,17,8,1],
[101,17,8,2],
[101,17,8,4],
[101,17,10,0],
[101,17,10,1],
[101,17,10,2],
[101,17,10,3],
[101,17,10,4],
[101,17,10,6],
[101,17,10,7],
[101,17,10,8],
[101,17,11,0],
[101,17,11,1],
[101,17,11,2],
[101,17,11,3],
[101,17,11,4],
[101,17,11,6],
[101,17,11,7],
[101,17,11,8],
[101,17,11,10],
[101,17,12,0],
[101,17,12,1],
[101,17,12,2],
[101,17,12,5],
[101,17,12,6],
[101,17,12,7],
[101,17,12,8],
[101,17,12,10],
[101,17,13,0],
[101,17,13,1],
[101,17,13,2],
[101,17,13,5],
[101,17,13,6],
[101,17,13,7],
[101,17,13,8],
[101,17,13,10],
[101,17,13,11],
[101,17,13,12],
[101,17,14,3],
[101,17,14,4],
[101,17,14,5],
[101,17,14,6],
[101,17,14,7],
[101,17,14,8],
[101,17,14,10],
[101,17,14,11],
[101,17,14,12],
[101,17,16,0],
[101,17,16,1],
[101,17,16,2],
[101,17,16,3],
[101,17,16,4],
[101,17,16,5],
[101,17,16,6],
[101,17,16,7],
[101,17,16,8],
[101,17,16,10],
[101,17,16,11],
[101,17,16,12],
[101,17,16,13],
[101,17,16,14],
[101,19,3,0],
[101,19,3,1],
[101,19,3,2],
[101,19,4,0],
[101,19,4,1],
[101,19,4,2],
[101,19,4,3],
[101,19,5,0],
[101,19,5,1],
[101,19,5,2],
[101,19,5,4],
[101,19,6,0],
[101,19,6,1],
[101,19,6,2],
[101,19,6,4],
[101,19,7,0],
[101,19,7,1],
[101,19,7,2],
[101,19,7,3],
[101,19,7,4],
[101,19,7,6],
[101,19,8,0],
[101,19,8,1],
[101,19,8,2],
[101,19,8,7],
[101,19,10,3],
[101,19,10,4],
[101,19,10,5],
[101,19,10,6],
[101,19,10,7],
[101,19,10,8],
[101,19,11,3],
[101,19,11,4],
[101,19,11,5],
[101,19,11,6],
[101,19,11,7],
[101,19,11,8],
[101,19,11,10],
[101,19,12,0],
[101,19,12,1],
[101,19,12,2],
[101,19,12,3],
[101,19,12,4],
[101,19,12,5],
[101,19,12,6],
[101,19,12,7],
[101,19,12,8],
[101,19,12,10],
[101,19,16,0],
[101,19,16,1],
[101,19,16,2],
[101,19,16,3],
[101,19,16,4],
[101,19,16,5],
[101,19,16,6],
[101,19,16,7],
[101,19,16,8],
[101,19,16,10],
[101,19,16,11],
[101,19,16,12],
[101,19,17,0],
[101,19,17,1],
[101,19,17,2],
[101,19,17,3],
[101,19,17,4],
[101,19,17,5],
[101,19,17,6],
[101,19,17,7],
[101,19,17,8],
[101,19,17,10],
[101,19,17,11],
[101,19,17,12],
[101,20,3,0],
[101,20,3,1],
[101,20,3,2],
[101,20,4,0],
[101,20,4,1],
[101,20,4,2],
[101,20,4,3],
[101,20,5,0],
[101,20,5,1],
[101,20,5,2],
[101,20,5,4],
[101,20,6,0],
[101,20,6,1],
[101,20,6,2],
[101,20,7,0],
[101,20,7,1],
[101,20,7,2],
[101,20,7,5],
[101,20,7,6],
[101,20,8,4],
[101,20,8,7],
[101,20,11,0],
[101,20,11,1],
[101,20,11,2],
[101,20,11,3],
[101,20,11,4],
[101,20,11,5],
[101,20,11,6],
[101,20,11,7],
[101,20,11,8],
[101,20,12,0],
[101,20,12,1],
[101,20,12,2],
[101,20,12,3],
[101,20,12,4],
[101,20,12,5],
[101,20,12,6],
[101,20,12,7],
[101,20,12,8],
[101,20,13,0],
[101,20,13,1],
[101,20,13,2],
[101,20,13,3],
[101,20,13,4],
[101,20,13,5],
[101,20,13,6],
[101,20,13,7],
[101,20,13,8],
[101,20,13,11],
[101,20,13,12],
[101,20,14,0],
[101,20,14,1],
[101,20,14,2],
[101,20,14,3],
[101,20,14,4],
[101,20,14,5],
[101,20,14,6],
[101,20,14,7],
[101,20,14,8],
[101,20,14,11],
[101,20,14,12],
[101,20,16,0],
[101,20,16,1],
[101,20,16,2],
[101,20,16,3],
[101,20,16,4],
[101,20,16,5],
[101,20,16,6],
[101,20,16,7],
[101,20,16,8],
[101,20,16,11],
[101,20,16,12],
[101,20,16,13],
[101,20,16,14],
[101,20,17,0],
[101,20,17,1],
[101,20,17,2],
[101,20,17,3],
[101,20,17,4],
[101,20,17,5],
[101,20,17,6],
[101,20,17,7],
[101,20,17,8],
[101,20,17,11],
[101,20,17,12],
[101,20,17,13],
[101,20,17,14],
[101,20,19,0],
[101,20,19,1],
[101,20,19,2],
[101,20,19,3],
[101,20,19,4],
[101,20,19,5],
[101,20,19,6],
[101,20,19,7],
[101,20,19,8],
[101,20,19,11],
[101,21,4,0],
[101,21,4,1],
[101,21,4,2],
[101,21,7,4],
[101,21,10,0],
[101,21,10,1],
[101,21,10,2],
[101,21,10,4],
[101,21,10,7],
[101,21,11,0],
[101,21,11,1],
[101,21,11,2],
[101,21,11,4],
[101,21,11,7],
[101,21,11,10],
[101,21,12,0],
[101,21,12,1],
[101,21,12,2],
[101,21,12,4],
[101,21,12,7],
[101,21,12,10],
[101,21,13,0],
[101,21,13,1],
[101,21,13,2],
[101,21,13,4],
[101,21,13,7],
[101,21,13,10],
[101,21,13,11],
[101,21,13,12],
[101,21,14,0],
[101,21,14,1],
[101,21,14,2],
[101,21,14,4],
[101,21,14,7],
[101,21,14,10],
[101,21,14,11],
[101,21,14,12],
[101,21,16,0],
[101,21,16,1],
[101,21,16,2],
[101,21,16,4],
[101,21,16,7],
[101,21,16,10],
[101,21,16,11],
[101,21,16,12],
[101,21,16,13],
[101,21,17,0],
[101,21,17,1],
[101,21,17,2],
[101,21,17,4],
[101,21,17,7],
[101,21,17,10],
[101,21,17,11],
[101,21,17,12],
[101,21,17,13],
[101,21,19,0],
[101,21,19,1],
[101,21,19,2],
[101,21,19,4],
[101,21,19,7],
[101,21,20,0],
[101,21,20,1],
[101,21,20,2],
[101,21,20,4],
[101,21,20,7],
[101,22,3,0],
[101,22,3,1],
[101,22,3,2],
[101,22,4,0],
[101,22,4,1],
[101,22,4,2],
[101,22,5,4],
[101,22,6,0],
[101,22,6,1],
[101,22,6,2],
[101,22,6,4],
[101,22,7,0],
[101,22,7,1],
[101,22,7,2],
[101,22,7,3],
[101,22,7,4],
[101,22,7,5],
[101,22,7,6],
[101,22,8,0],
[101,22,8,1],
[101,22,8,2],
[101,22,8,4],
[101,22,8,7],
[101,22,11,0],
[101,22,11,1],
[101,22,11,2],
[101,22,11,3],
[101,22,11,4],
[101,22,11,5],
[101,22,11,6],
[101,22,11,7],
[101,22,11,8],
[101,22,12,0],
[101,22,12,1],
[101,22,12,2],
[101,22,12,3],
[101,22,12,4],
[101,22,12,5],
[101,22,12,6],
[101,22,12,7],
[101,22,12,8],
[101,22,13,0],
[101,22,13,1],
[101,22,13,2],
[101,22,13,3],
[101,22,13,4],
[101,22,13,5],
[101,22,13,6],
[101,22,13,7],
[101,22,13,8],
[101,22,13,11],
[101,22,13,12],
[101,22,14,0],
[101,22,14,1],
[101,22,14,2],
[101,22,14,3],
[101,22,14,4],
[101,22,14,5],
[101,22,14,6],
[101,22,14,7],
[101,22,14,8],
[101,22,14,11],
[101,22,14,12],
[101,22,16,0],
[101,22,16,1],
[101,22,16,2],
[101,22,16,3],
[101,22,16,4],
[101,22,16,5],
[101,22,16,6],
[101,22,16,7],
[101,22,16,8],
[101,22,16,11],
[101,22,17,0],
[101,22,17,1],
[101,22,17,2],
[101,22,17,3],
[101,22,17,4],
[101,22,17,5],
[101,22,17,6],
[101,22,17,7],
[101,22,17,8],
[101,22,17,11],
[101,22,19,0],
[101,22,19,1],
[101,22,19,2],
[101,22,19,3],
[101,22,19,4],
[101,22,19,5],
[101,22,19,6],
[101,22,19,7],
[101,22,21,0],
[101,22,21,1],
[101,22,21,2],
[101,22,21,4],
[101,23,4,3],
[101,23,5,0],
[101,23,5,1],
[101,23,5,2],
[101,23,5,4],
[101,23,6,0],
[101,23,6,1],
[101,23,6,2],
[101,23,6,4],
[101,23,7,0],
[101,23,7,1],
[101,23,7,2],
[101,23,7,3],
[101,23,7,4],
[101,23,7,5],
[101,23,7,6],
[101,23,8,0],
[101,23,8,1],
[101,23,8,2],
[101,23,8,4],
[101,23,8,7],
[101,23,10,0],
[101,23,10,1],
[101,23,10,2],
[101,23,10,3],
[101,23,10,4],
[101,23,10,5],
[101,23,10,6],
[101,23,10,7],
[101,23,10,8],
[101,23,11,0],
[101,23,11,1],
[101,23,11,2],
[101,23,11,3],
[101,23,11,4],
[101,23,11,5],
[101,23,11,6],
[101,23,11,7],
[101,23,11,8],
[101,23,11,10],
[101,23,12,0],
[101,23,12,1],
[101,23,12,2],
[101,23,12,3],
[101,23,12,4],
[101,23,12,5],
[101,23,12,6],
[101,23,12,7],
[101,23,12,8],
[101,23,12,10],
[101,23,13,0],
[101,23,13,1],
[101,23,13,2],
[101,23,13,3],
[101,23,13,4],
[101,23,13,5],
[101,23,13,6],
[101,23,13,7],
[101,23,13,8],
[101,23,13,10],
[101,23,13,11],
[101,23,13,12],
[101,23,14,0],
[101,23,14,1],
[101,23,14,2],
[101,23,14,3],
[101,23,14,4],
[101,23,14,5],
[101,23,14,6],
[101,23,14,7],
[101,23,14,8],
[101,23,14,10],
[101,23,14,11],
[101,23,14,12],
[101,23,16,0],
[101,23,16,1],
[101,23,16,2],
[101,23,16,3],
[101,23,16,4],
[101,23,16,5],
[101,23,16,6],
[101,23,16,7],
[101,23,16,8],
[101,23,17,0],
[101,23,17,1],
[101,23,17,2],
[101,23,17,3],
[101,23,17,4],
[101,23,17,5],
[101,23,17,6],
[101,23,17,7],
[101,23,17,8],
[101,23,19,0],
[101,23,19,1],
[101,23,19,2],
[101,23,19,3],
[101,23,19,4],
[101,23,19,5],
[101,23,20,0],
[101,23,20,1],
[101,23,20,2],
[101,23,20,3],
[101,23,20,4],
[101,23,21,0],
[101,23,21,1],
[101,23,21,2],
[101,24,4,3],
[101,24,5,0],
[101,24,5,1],
[101,24,5,2],
[101,24,5,4],
[101,24,6,0],
[101,24,6,1],
[101,24,6,2],
[101,24,6,4],
[101,24,7,0],
[101,24,7,1],
[101,24,7,2],
[101,24,7,3],
[101,24,7,4],
[101,24,7,5],
[101,24,7,6],
[101,24,8,0],
[101,24,8,1],
[101,24,8,2],
[101,24,8,4],
[101,24,8,7],
[101,24,10,0],
[101,24,10,1],
[101,24,10,2],
[101,24,10,3],
[101,24,10,4],
[101,24,10,5],
[101,24,10,6],
[101,24,10,7],
[101,24,10,8],
[101,24,11,0],
[101,24,11,1],
[101,24,11,2],
[101,24,11,3],
[101,24,11,4],
[101,24,11,5],
[101,24,11,6],
[101,24,11,7],
[101,24,11,8],
[101,24,11,10],
[101,24,12,0],
[101,24,12,1],
[101,24,12,2],
[101,24,12,3],
[101,24,12,4],
[101,24,12,5],
[101,24,12,6],
[101,24,12,7],
[101,24,12,8],
[101,24,12,10],
[101,24,13,0],
[101,24,13,1],
[101,24,13,2],
[101,24,13,3],
[101,24,13,4],
[101,24,13,5],
[101,24,13,6],
[101,24,13,7],
[101,24,13,8],
[101,24,13,10],
[101,24,13,11],
[101,24,13,12],
[101,24,14,0],
[101,24,14,1],
[101,24,14,2],
[101,24,14,3],
[101,24,14,4],
[101,24,14,5],
[101,24,14,6],
[101,24,14,7],
[101,24,14,8],
[101,24,14,10],
[101,24,14,11],
[101,24,14,12],
[101,24,17,0],
[101,24,17,1],
[101,24,17,2],
[101,24,17,3],
[101,24,17,4],
[101,24,17,5],
[101,24,17,6],
[101,24,17,7],
[101,24,17,8],
[101,24,19,0],
[101,24,19,1],
[101,24,19,2],
[101,24,19,3],
[101,24,19,4],
[101,24,19,5],
[101,24,20,0],
[101,24,20,1],
[101,24,20,2],
[101,24,20,3],
[101,24,20,4],
[101,24,21,0],
[101,24,21,1],
[101,24,21,2],
[101,25,3,0],
[101,25,3,1],
[101,25,3,2],
[101,25,4,0],
[101,25,4,1],
[101,25,4,2],
[101,25,4,3],
[101,25,5,0],
[101,25,5,1],
[101,25,5,2],
[101,25,5,4],
[101,25,6,0],
[101,25,6,1],
[101,25,6,2],
[101,25,6,4],
[101,25,8,0],
[101,25,8,1],
[101,25,8,2],
[101,25,8,4],
[101,25,10,0],
[101,25,10,1],
[101,25,10,2],
[101,25,10,3],
[101,25,10,4],
[101,25,10,5],
[101,25,10,6],
[101,25,10,8],
[101,25,11,0],
[101,25,11,1],
[101,25,11,2],
[101,25,11,3],
[101,25,11,4],
[101,25,11,5],
[101,25,11,6],
[101,25,11,8],
[101,25,11,10],
[101,25,12,0],
[101,25,12,1],
[101,25,12,2],
[101,25,12,3],
[101,25,12,4],
[101,25,12,5],
[101,25,12,6],
[101,25,12,8],
[101,25,12,10],
[101,25,13,0],
[101,25,13,1],
[101,25,13,2],
[101,25,13,3],
[101,25,13,4],
[101,25,13,5],
[101,25,13,6],
[101,25,13,8],
[101,25,13,10],
[101,25,13,11],
[101,25,14,0],
[101,25,14,1],
[101,25,14,2],
[101,25,14,3],
[101,25,14,4],
[101,25,14,5],
[101,25,14,6],
[101,25,14,8],
[101,25,16,0],
[101,25,16,1],
[101,25,16,2],
[101,25,16,3],
[101,25,16,4],
[101,25,16,5],
[101,25,17,0],
[101,25,17,1],
[101,25,17,2],
[101,25,17,3],
[101,25,17,4],
[101,25,17,5],
[101,25,19,0],
[101,25,19,1],
[101,25,19,2],
[101,27,3,0],
[101,27,3,1],
[101,27,3,2],
[101,27,4,0],
[101,27,4,1],
[101,27,4,2],
[101,27,4,3],
[101,27,5,0],
[101,27,5,1],
[101,27,5,2],
[101,27,5,4],
[101,27,6,0],
[101,27,6,1],
[101,27,6,2],
[101,27,6,4],
[101,27,7,0],
[101,27,7,1],
[101,27,7,2],
[101,27,7,3],
[101,27,7,4],
[101,27,7,5],
[101,27,7,6],
[101,27,8,0],
[101,27,8,1],
[101,27,8,2],
[101,27,8,4],
[101,27,8,7],
[101,27,11,0],
[101,27,11,1],
[101,27,11,2],
[101,27,11,3],
[101,27,11,4],
[101,27,11,5],
[101,27,11,6],
[101,27,11,7],
[101,27,11,8],
[101,27,12,0],
[101,27,12,1],
[101,27,12,2],
[101,27,12,3],
[101,27,12,4],
[101,27,12,5],
[101,27,12,6],
[101,27,12,7],
[101,27,13,0],
[101,27,13,1],
[101,27,13,2],
[101,27,13,3],
[101,27,13,4],
[101,27,13,5],
[101,27,13,6],
[101,27,13,7],
[101,27,14,0],
[101,27,14,1],
[101,27,14,2],
[101,27,14,3],
[101,27,14,4],
[101,27,14,5],
[101,27,16,0],
[101,27,16,1],
[101,27,16,2],
[101,27,17,0],
[101,27,17,1],
[101,27,17,2],
[101,28,3,0],
[101,28,3,1],
[101,28,3,2],
[101,28,4,0],
[101,28,4,1],
[101,28,4,2],
[101,28,4,3],
[101,28,5,0],
[101,28,5,1],
[101,28,5,2],
[101,28,5,4],
[101,28,6,0],
[101,28,6,1],
[101,28,6,2],
[101,28,6,4],
[101,28,8,0],
[101,28,8,1],
[101,28,8,2],
[101,28,8,4],
[101,28,10,0],
[101,28,10,1],
[101,28,10,2],
[101,28,10,3],
[101,28,10,4],
[101,28,10,5],
[101,28,10,6],
[101,28,11,0],
[101,28,11,1],
[101,28,11,2],
[101,28,11,3],
[101,28,11,4],
[101,28,11,5],
[101,28,11,6],
[101,28,12,0],
[101,28,12,1],
[101,28,12,2],
[101,28,12,3],
[101,28,12,4],
[101,28,12,5],
[101,28,13,0],
[101,28,13,1],
[101,28,13,2],
[101,28,13,3],
[101,28,13,4],
[101,28,13,5],
[101,28,14,0],
[101,28,14,1],
[101,28,14,2],
[101,28,14,3],
[101,28,14,4],
[101,29,3,0],
[101,29,3,1],
[101,29,3,2],
[101,29,4,0],
[101,29,4,1],
[101,29,4,2],
[101,29,4,3],
[101,29,5,0],
[101,29,5,1],
[101,29,5,2],
[101,29,5,4],
[101,29,6,0],
[101,29,6,1],
[101,29,6,2],
[101,29,6,4],
[101,29,7,0],
[101,29,7,1],
[101,29,7,2],
[101,29,7,3],
[101,29,7,4],
[101,29,7,5],
[101,29,7,6],
[101,29,8,0],
[101,29,8,1],
[101,29,8,2],
[101,29,8,4],
[101,29,8,7],
[101,29,10,0],
[101,29,10,1],
[101,29,10,2],
[101,29,10,3],
[101,29,10,4],
[101,29,10,5],
[101,29,13,0],
[101,29,13,1],
[101,29,13,2],
[101,29,13,3],
[101,29,13,4],
[101,29,14,0],
[101,29,14,1],
[101,29,14,2],
[101,30,3,0],
[101,30,3,1],
[101,30,3,2],
[101,30,4,0],
[101,30,4,1],
[101,30,4,2],
[101,30,4,3],
[101,30,5,0],
[101,30,5,1],
[101,30,5,2],
[101,30,5,4],
[101,30,6,0],
[101,30,6,1],
[101,30,6,2],
[101,30,6,4],
[101,30,7,0],
[101,30,7,1],
[101,30,7,2],
[101,30,7,3],
[101,30,7,4],
[101,30,7,5],
[101,30,7,6],
[101,30,8,0],
[101,30,8,1],
[101,30,8,2],
[101,30,8,4],
[101,30,10,0],
[101,30,10,1],
[101,30,10,2],
[101,30,10,3],
[101,30,10,4],
[101,30,11,0],
[101,30,11,1],
[101,30,11,2],
[101,30,11,3],
[101,30,11,4],
[101,30,12,0],
[101,30,12,1],
[101,30,12,2],
[101,30,13,0],
[101,30,13,1],
[101,30,13,2],
[101,31,3,0],
[101,31,3,1],
[101,31,3,2],
[101,31,4,0],
[101,31,4,1],
[101,31,4,2],
[101,31,4,3],
[101,31,5,0],
[101,31,5,1],
[101,31,5,2],
[101,31,5,4],
[101,31,6,0],
[101,31,6,1],
[101,31,6,2],
[101,31,6,4],
[101,31,7,0],
[101,31,7,1],
[101,31,7,2],
[101,31,7,3],
[101,31,7,4],
[101,31,7,5],
[101,31,8,0],
[101,31,8,1],
[101,31,8,2],
[101,31,8,4],
[101,31,10,0],
[101,31,10,1],
[101,31,10,2],
[101,31,11,0],
[101,31,11,1],
[101,31,11,2],
[101,32,3,0],
[101,32,3,1],
[101,32,3,2],
[101,32,4,0],
[101,32,4,1],
[101,32,4,2],
[101,32,4,3],
[101,32,5,0],
[101,32,5,1],
[101,32,5,2],
[101,32,5,4],
[101,32,6,0],
[101,32,6,1],
[101,32,6,2],
[101,32,6,4],
[101,32,7,0],
[101,32,7,1],
[101,32,7,2],
[101,32,7,3],
[101,32,7,4],
[101,32,8,0],
[101,32,8,1],
[101,32,8,2],
[101,33,3,0],
[101,33,3,1],
[101,33,3,2],
[101,33,4,0],
[101,33,4,1],
[101,33,4,2],
[101,33,4,3],
[101,33,5,0],
[101,33,5,1],
[101,33,5,2],
[101,33,5,4],
[101,33,6,0],
[101,33,6,1],
[101,33,6,2],
[101,33,7,0],
[101,33,7,1],
[101,33,7,2],
[101,34,3,0],
[101,34,3,1],
[101,34,3,2],
[101,34,4,0],
[101,34,4,1],
[101,34,4,2],
[101,34,4,3],
[101,34,5,0],
[101,34,5,1],
[101,34,5,2],
[102,4,3,0],
[102,4,3,1],
[102,4,3,2],
[102,5,4,0],
[102,5,4,1],
[102,5,4,2],
[102,6,4,0],
[102,6,4,1],
[102,6,4,2],
[102,7,3,0],
[102,7,3,1],
[102,7,3,2],
[102,7,4,0],
[102,7,4,1],
[102,7,4,2],
[102,7,4,3],
[102,7,5,0],
[102,7,5,1],
[102,7,5,2],
[102,7,5,4],
[102,7,6,0],
[102,7,6,1],
[102,7,6,2],
[102,7,6,4],
[102,8,4,0],
[102,8,4,1],
[102,8,4,2],
[102,8,7,0],
[102,8,7,1],
[102,8,7,2],
[102,8,7,4],
[102,10,3,0],
[102,10,3,1],
[102,10,3,2],
[102,10,4,0],
[102,10,4,1],
[102,10,4,2],
[102,10,4,3],
[102,10,5,0],
[102,10,5,1],
[102,10,5,2],
[102,10,5,4],
[102,10,6,0],
[102,10,6,1],
[102,10,6,2],
[102,10,6,4],
[102,10,7,0],
[102,10,7,1],
[102,10,7,2],
[102,10,7,3],
[102,10,7,4],
[102,10,7,5],
[102,10,7,6],
[102,10,8,0],
[102,10,8,1],
[102,10,8,2],
[102,10,8,4],
[102,10,8,7],
[102,11,3,0],
[102,11,3,1],
[102,11,3,2],
[102,11,4,0],
[102,11,4,1],
[102,11,4,2],
[102,11,4,3],
[102,11,5,0],
[102,11,5,1],
[102,11,5,2],
[102,11,5,4],
[102,11,6,0],
[102,11,6,1],
[102,11,6,2],
[102,11,6,4],
[102,11,7,0],
[102,11,7,1],
[102,11,7,2],
[102,11,7,3],
[102,11,7,4],
[102,11,7,5],
[102,11,7,6],
[102,11,8,0],
[102,11,8,1],
[102,11,8,2],
[102,11,8,4],
[102,11,8,7],
[102,11,10,0],
[102,11,10,1],
[102,11,10,2],
[102,11,10,3],
[102,11,10,4],
[102,11,10,5],
[102,11,10,6],
[102,11,10,7],
[102,11,10,8],
[102,12,3,0],
[102,12,3,1],
[102,12,3,2],
[102,12,4,0],
[102,12,4,1],
[102,12,4,2],
[102,12,4,3],
[102,12,5,0],
[102,12,5,1],
[102,12,5,2],
[102,12,5,4],
[102,12,6,0],
[102,12,6,1],
[102,12,6,2],
[102,12,6,4],
[102,12,7,0],
[102,12,7,1],
[102,12,7,2],
[102,12,7,3],
[102,12,7,4],
[102,12,7,5],
[102,12,7,6],
[102,12,8,0],
[102,12,8,1],
[102,12,8,2],
[102,12,8,4],
[102,12,8,7],
[102,12,10,0],
[102,12,10,1],
[102,12,10,2],
[102,12,10,3],
[102,12,10,4],
[102,12,10,5],
[102,12,10,6],
[102,12,10,7],
[102,13,3,0],
[102,13,3,1],
[102,13,3,2],
[102,13,4,0],
[102,13,4,1],
[102,13,4,2],
[102,13,4,3],
[102,13,5,0],
[102,13,5,1],
[102,13,5,2],
[102,13,5,4],
[102,13,6,0],
[102,13,6,1],
[102,13,6,2],
[102,13,6,4],
[102,13,7,0],
[102,13,7,1],
[102,13,7,2],
[102,13,7,3],
[102,13,7,4],
[102,13,7,5],
[102,13,7,6],
[102,13,8,0],
[102,13,8,1],
[102,13,8,2],
[102,13,8,4],
[102,13,8,7],
[102,13,10,0],
[102,13,10,1],
[102,13,10,2],
[102,13,10,3],
[102,13,10,4],
[102,13,10,5],
[102,13,10,6],
[102,13,10,7],
[102,13,11,0],
[102,13,11,1],
[102,13,11,2],
[102,13,11,3],
[102,13,11,4],
[102,13,11,5],
[102,13,11,6],
[102,13,11,7],
[102,13,11,10],
[102,13,12,0],
[102,13,12,1],
[102,13,12,2],
[102,13,12,3],
[102,13,12,4],
[102,13,12,5],
[102,13,12,8],
[102,13,12,10],
[102,14,3,0],
[102,14,3,1],
[102,14,3,2],
[102,14,4,0],
[102,14,4,1],
[102,14,4,2],
[102,14,4,3],
[102,14,5,0],
[102,14,5,1],
[102,14,5,2],
[102,14,5,4],
[102,14,6,0],
[102,14,6,1],
[102,14,6,2],
[102,14,6,4],
[102,14,7,0],
[102,14,7,1],
[102,14,7,2],
[102,14,7,3],
[102,14,7,4],
[102,14,7,5],
[102,14,7,6],
[102,14,8,0],
[102,14,8,1],
[102,14,8,2],
[102,14,8,4],
[102,14,8,7],
[102,14,10,0],
[102,14,10,1],
[102,14,10,2],
[102,14,10,3],
[102,14,10,4],
[102,14,10,5],
[102,14,10,8],
[102,14,11,0],
[102,14,11,1],
[102,14,11,2],
[102,14,11,3],
[102,14,11,4],
[102,14,11,5],
[102,14,11,8],
[102,14,11,10],
[102,14,12,0],
[102,14,12,1],
[102,14,12,2],
[102,14,12,3],
[102,14,12,4],
[102,14,12,6],
[102,14,12,7],
[102,14,12,8],
[102,14,12,10],
[102,16,3,0],
[102,16,3,1],
[102,16,3,2],
[102,16,4,0],
[102,16,4,1],
[102,16,4,2],
[102,16,4,3],
[102,16,5,0],
[102,16,5,1],
[102,16,5,2],
[102,16,5,4],
[102,16,6,0],
[102,16,6,1],
[102,16,6,2],
[102,16,6,4],
[102,16,7,0],
[102,16,7,1],
[102,16,7,2],
[102,16,7,3],
[102,16,7,4],
[102,16,7,5],
[102,16,8,0],
[102,16,8,1],
[102,16,8,2],
[102,16,8,4],
[102,16,8,7],
[102,16,10,0],
[102,16,10,1],
[102,16,10,2],
[102,16,10,5],
[102,16,10,6],
[102,16,10,7],
[102,16,10,8],
[102,16,11,0],
[102,16,11,1],
[102,16,11,2],
[102,16,11,5],
[102,16,11,6],
[102,16,11,7],
[102,16,11,8],
[102,16,11,10],
[102,16,12,3],
[102,16,12,4],
[102,16,12,5],
[102,16,12,6],
[102,16,12,7],
[102,16,12,8],
[102,16,12,10],
[102,16,13,3],
[102,16,13,4],
[102,16,13,5],
[102,16,13,6],
[102,16,13,7],
[102,16,13,8],
[102,16,13,10],
[102,16,13,11],
[102,16,13,12],
[102,16,14,0],
[102,16,14,1],
[102,16,14,2],
[102,16,14,3],
[102,16,14,4],
[102,16,14,5],
[102,16,14,6],
[102,16,14,7],
[102,16,14,8],
[102,16,14,10],
[102,16,14,11],
[102,16,14,12],
[102,17,3,0],
[102,17,3,1],
[102,17,3,2],
[102,17,4,0],
[102,17,4,1],
[102,17,4,2],
[102,17,4,3],
[102,17,5,0],
[102,17,5,1],
[102,17,5,2],
[102,17,5,4],
[102,17,6,0],
[102,17,6,1],
[102,17,6,2],
[102,17,6,4],
[102,17,7,0],
[102,17,7,1],
[102,17,7,2],
[102,17,7,3],
[102,17,7,4],
[102,17,7,5],
[102,17,8,0],
[102,17,8,1],
[102,17,8,2],
[102,17,8,4],
[102,17,8,7],
[102,17,10,0],
[102,17,10,1],
[102,17,10,2],
[102,17,10,5],
[102,17,10,6],
[102,17,10,7],
[102,17,10,8],
[102,17,11,0],
[102,17,11,1],
[102,17,11,2],
[102,17,11,5],
[102,17,11,6],
[102,17,11,7],
[102,17,11,8],
[102,17,11,10],
[102,17,12,3],
[102,17,12,4],
[102,17,12,5],
[102,17,12,6],
[102,17,12,7],
[102,17,12,8],
[102,17,12,10],
[102,17,13,3],
[102,17,13,4],
[102,17,13,5],
[102,17,13,6],
[102,17,13,7],
[102,17,13,8],
[102,17,13,10],
[102,17,13,11],
[102,17,13,12],
[102,17,14,0],
[102,17,14,1],
[102,17,14,2],
[102,17,14,3],
[102,17,14,4],
[102,17,14,5],
[102,17,14,6],
[102,17,14,7],
[102,17,14,8],
[102,17,14,10],
[102,17,14,11],
[102,17,14,12],
[102,17,16,0],
[102,17,16,1],
[102,17,16,2],
[102,17,16,3],
[102,17,16,4],
[102,17,16,5],
[102,17,16,6],
[102,17,16,7],
[102,17,16,8],
[102,17,16,10],
[102,17,16,11],
[102,17,16,12],
[102,17,16,13],
[102,17,16,14],
[102,19,3,0],
[102,19,3,1],
[102,19,3,2],
[102,19,4,0],
[102,19,4,1],
[102,19,4,2],
[102,19,4,3],
[102,19,5,0],
[102,19,5,1],
[102,19,5,2],
[102,19,5,4],
[102,19,6,0],
[102,19,6,1],
[102,19,6,2],
[102,19,7,0],
[102,19,7,1],
[102,19,7,2],
[102,19,7,5],
[102,19,7,6],
[102,19,8,4],
[102,19,8,7],
[102,19,10,0],
[102,19,10,1],
[102,19,10,2],
[102,19,10,3],
[102,19,10,4],
[102,19,10,5],
[102,19,10,6],
[102,19,10,7],
[102,19,10,8],
[102,19,11,0],
[102,19,11,1],
[102,19,11,2],
[102,19,11,3],
[102,19,11,4],
[102,19,11,5],
[102,19,11,6],
[102,19,11,7],
[102,19,11,8],
[102,19,11,10],
[102,19,12,0],
[102,19,12,1],
[102,19,12,2],
[102,19,12,3],
[102,19,12,4],
[102,19,12,5],
[102,19,12,6],
[102,19,12,7],
[102,19,12,8],
[102,19,12,10],
[102,19,16,0],
[102,19,16,1],
[102,19,16,2],
[102,19,16,3],
[102,19,16,4],
[102,19,16,5],
[102,19,16,6],
[102,19,16,7],
[102,19,16,8],
[102,19,16,10],
[102,19,16,11],
[102,19,16,12],
[102,19,17,0],
[102,19,17,1],
[102,19,17,2],
[102,19,17,3],
[102,19,17,4],
[102,19,17,5],
[102,19,17,6],
[102,19,17,7],
[102,19,17,8],
[102,19,17,10],
[102,19,17,11],
[102,19,17,12],
[102,20,3,0],
[102,20,3,1],
[102,20,3,2],
[102,20,4,0],
[102,20,4,1],
[102,20,4,2],
[102,20,4,3],
[102,20,5,0],
[102,20,5,1],
[102,20,5,2],
[102,20,6,4],
[102,20,7,3],
[102,20,7,4],
[102,20,7,5],
[102,20,7,6],
[102,20,8,0],
[102,20,8,1],
[102,20,8,2],
[102,20,8,4],
[102,20,8,7],
[102,20,11,0],
[102,20,11,1],
[102,20,11,2],
[102,20,11,3],
[102,20,11,4],
[102,20,11,5],
[102,20,11,6],
[102,20,11,7],
[102,20,11,8],
[102,20,12,0],
[102,20,12,1],
[102,20,12,2],
[102,20,12,3],
[102,20,12,4],
[102,20,12,5],
[102,20,12,6],
[102,20,12,7],
[102,20,12,8],
[102,20,13,0],
[102,20,13,1],
[102,20,13,2],
[102,20,13,3],
[102,20,13,4],
[102,20,13,5],
[102,20,13,6],
[102,20,13,7],
[102,20,13,8],
[102,20,13,11],
[102,20,13,12],
[102,20,14,0],
[102,20,14,1],
[102,20,14,2],
[102,20,14,3],
[102,20,14,4],
[102,20,14,5],
[102,20,14,6],
[102,20,14,7],
[102,20,14,8],
[102,20,14,11],
[102,20,14,12],
[102,20,16,0],
[102,20,16,1],
[102,20,16,2],
[102,20,16,3],
[102,20,16,4],
[102,20,16,5],
[102,20,16,6],
[102,20,16,7],
[102,20,16,8],
[102,20,16,11],
[102,20,16,12],
[102,20,16,13],
[102,20,17,0],
[102,20,17,1],
[102,20,17,2],
[102,20,17,3],
[102,20,17,4],
[102,20,17,5],
[102,20,17,6],
[102,20,17,7],
[102,20,17,8],
[102,20,17,11],
[102,20,17,12],
[102,20,17,13],
[102,20,19,0],
[102,20,19,1],
[102,20,19,2],
[102,20,19,3],
[102,20,19,4],
[102,20,19,5],
[102,20,19,6],
[102,20,19,7],
[102,20,19,8],
[102,21,4,0],
[102,21,4,1],
[102,21,4,2],
[102,21,7,0],
[102,21,7,1],
[102,21,7,2],
[102,21,7,4],
[102,21,10,0],
[102,21,10,1],
[102,21,10,2],
[102,21,10,4],
[102,21,10,7],
[102,21,11,0],
[102,21,11,1],
[102,21,11,2],
[102,21,11,4],
[102,21,11,7],
[102,21,11,10],
[102,21,12,0],
[102,21,12,1],
[102,21,12,2],
[102,21,12,4],
[102,21,12,7],
[102,21,12,10],
[102,21,13,0],
[102,21,13,1],
[102,21,13,2],
[102,21,13,4],
[102,21,13,7],
[102,21,13,10],
[102,21,13,11],
[102,21,13,12],
[102,21,14,0],
[102,21,14,1],
[102,21,14,2],
[102,21,14,4],
[102,21,14,7],
[102,21,14,10],
[102,21,14,11],
[102,21,14,12],
[102,21,16,0],
[102,21,16,1],
[102,21,16,2],
[102,21,16,4],
[102,21,16,7],
[102,21,16,10],
[102,21,16,11],
[102,21,17,0],
[102,21,17,1],
[102,21,17,2],
[102,21,17,4],
[102,21,17,7],
[102,21,17,10],
[102,21,17,11],
[102,21,19,0],
[102,21,19,1],
[102,21,19,2],
[102,21,19,4],
[102,21,19,7],
[102,21,20,0],
[102,21,20,1],
[102,21,20,2],
[102,21,20,4],
[102,22,4,3],
[102,22,5,0],
[102,22,5,1],
[102,22,5,2],
[102,22,5,4],
[102,22,6,0],
[102,22,6,1],
[102,22,6,2],
[102,22,6,4],
[102,22,7,0],
[102,22,7,1],
[102,22,7,2],
[102,22,7,3],
[102,22,7,4],
[102,22,7,5],
[102,22,7,6],
[102,22,8,0],
[102,22,8,1],
[102,22,8,2],
[102,22,8,4],
[102,22,8,7],
[102,22,11,0],
[102,22,11,1],
[102,22,11,2],
[102,22,11,3],
[102,22,11,4],
[102,22,11,5],
[102,22,11,6],
[102,22,11,7],
[102,22,11,8],
[102,22,12,0],
[102,22,12,1],
[102,22,12,2],
[102,22,12,3],
[102,22,12,4],
[102,22,12,5],
[102,22,12,6],
[102,22,12,7],
[102,22,12,8],
[102,22,13,0],
[102,22,13,1],
[102,22,13,2],
[102,22,13,3],
[102,22,13,4],
[102,22,13,5],
[102,22,13,6],
[102,22,13,7],
[102,22,13,8],
[102,22,13,11],
[102,22,13,12],
[102,22,14,0],
[102,22,14,1],
[102,22,14,2],
[102,22,14,3],
[102,22,14,4],
[102,22,14,5],
[102,22,14,6],
[102,22,14,7],
[102,22,14,8],
[102,22,14,11],
[102,22,14,12],
[102,22,16,0],
[102,22,16,1],
[102,22,16,2],
[102,22,16,3],
[102,22,16,4],
[102,22,16,5],
[102,22,16,6],
[102,22,16,7],
[102,22,16,8],
[102,22,17,0],
[102,22,17,1],
[102,22,17,2],
[102,22,17,3],
[102,22,17,4],
[102,22,17,5],
[102,22,17,6],
[102,22,17,7],
[102,22,17,8],
[102,22,19,0],
[102,22,19,1],
[102,22,19,2],
[102,22,19,3],
[102,22,19,4],
[102,22,19,5],
[102,22,21,0],
[102,22,21,1],
[102,22,21,2],
[102,23,3,0],
[102,23,3,1],
[102,23,3,2],
[102,23,4,0],
[102,23,4,1],
[102,23,4,2],
[102,23,4,3],
[102,23,5,0],
[102,23,5,1],
[102,23,5,2],
[102,23,5,4],
[102,23,6,0],
[102,23,6,1],
[102,23,6,2],
[102,23,6,4],
[102,23,7,0],
[102,23,7,1],
[102,23,7,2],
[102,23,7,3],
[102,23,7,4],
[102,23,7,5],
[102,23,7,6],
[102,23,8,0],
[102,23,8,1],
[102,23,8,2],
[102,23,8,4],
[102,23,8,7],
[102,23,10,0],
[102,23,10,1],
[102,23,10,2],
[102,23,10,3],
[102,23,10,4],
[102,23,10,5],
[102,23,10,6],
[102,23,10,7],
[102,23,10,8],
[102,23,11,0],
[102,23,11,1],
[102,23,11,2],
[102,23,11,3],
[102,23,11,4],
[102,23,11,5],
[102,23,11,6],
[102,23,11,7],
[102,23,11,8],
[102,23,11,10],
[102,23,12,0],
[102,23,12,1],
[102,23,12,2],
[102,23,12,3],
[102,23,12,4],
[102,23,12,5],
[102,23,12,6],
[102,23,12,7],
[102,23,12,8],
[102,23,12,10],
[102,23,13,0],
[102,23,13,1],
[102,23,13,2],
[102,23,13,3],
[102,23,13,4],
[102,23,13,5],
[102,23,13,6],
[102,23,13,7],
[102,23,13,8],
[102,23,13,10],
[102,23,13,11],
[102,23,13,12],
[102,23,14,0],
[102,23,14,1],
[102,23,14,2],
[102,23,14,3],
[102,23,14,4],
[102,23,14,5],
[102,23,14,6],
[102,23,14,7],
[102,23,14,8],
[102,23,14,10],
[102,23,14,11],
[102,23,16,0],
[102,23,16,1],
[102,23,16,2],
[102,23,16,3],
[102,23,16,4],
[102,23,16,5],
[102,23,16,6],
[102,23,16,7],
[102,23,17,0],
[102,23,17,1],
[102,23,17,2],
[102,23,17,3],
[102,23,17,4],
[102,23,17,5],
[102,23,17,6],
[102,23,17,7],
[102,23,19,0],
[102,23,19,1],
[102,23,19,2],
[102,23,19,3],
[102,23,19,4],
[102,23,20,0],
[102,23,20,1],
[102,23,20,2],
[102,24,3,0],
[102,24,3,1],
[102,24,3,2],
[102,24,4,0],
[102,24,4,1],
[102,24,4,2],
[102,24,4,3],
[102,24,5,0],
[102,24,5,1],
[102,24,5,2],
[102,24,5,4],
[102,24,6,0],
[102,24,6,1],
[102,24,6,2],
[102,24,6,4],
[102,24,7,0],
[102,24,7,1],
[102,24,7,2],
[102,24,7,3],
[102,24,7,4],
[102,24,7,5],
[102,24,7,6],
[102,24,8,0],
[102,24,8,1],
[102,24,8,2],
[102,24,8,4],
[102,24,8,7],
[102,24,10,0],
[102,24,10,1],
[102,24,10,2],
[102,24,10,3],
[102,24,10,4],
[102,24,10,5],
[102,24,10,6],
[102,24,10,7],
[102,24,10,8],
[102,24,11,0],
[102,24,11,1],
[102,24,11,2],
[102,24,11,3],
[102,24,11,4],
[102,24,11,5],
[102,24,11,6],
[102,24,11,7],
[102,24,11,8],
[102,24,11,10],
[102,24,12,0],
[102,24,12,1],
[102,24,12,2],
[102,24,12,3],
[102,24,12,4],
[102,24,12,5],
[102,24,12,6],
[102,24,12,7],
[102,24,12,8],
[102,24,12,10],
[102,24,13,0],
[102,24,13,1],
[102,24,13,2],
[102,24,13,3],
[102,24,13,4],
[102,24,13,5],
[102,24,13,6],
[102,24,13,7],
[102,24,13,8],
[102,24,13,10],
[102,24,13,11],
[102,24,13,12],
[102,24,14,0],
[102,24,14,1],
[102,24,14,2],
[102,24,14,3],
[102,24,14,4],
[102,24,14,5],
[102,24,14,6],
[102,24,14,7],
[102,24,14,8],
[102,24,14,10],
[102,24,14,11],
[102,24,17,0],
[102,24,17,1],
[102,24,17,2],
[102,24,17,3],
[102,24,17,4],
[102,24,17,5],
[102,24,17,6],
[102,24,17,7],
[102,24,19,0],
[102,24,19,1],
[102,24,19,2],
[102,24,19,3],
[102,24,19,4],
[102,24,20,0],
[102,24,20,1],
[102,24,20,2],
[102,25,3,0],
[102,25,3,1],
[102,25,3,2],
[102,25,4,0],
[102,25,4,1],
[102,25,4,2],
[102,25,4,3],
[102,25,5,0],
[102,25,5,1],
[102,25,5,2],
[102,25,5,4],
[102,25,6,0],
[102,25,6,1],
[102,25,6,2],
[102,25,6,4],
[102,25,8,0],
[102,25,8,1],
[102,25,8,2],
[102,25,8,4],
[102,25,10,0],
[102,25,10,1],
[102,25,10,2],
[102,25,10,3],
[102,25,10,4],
[102,25,10,5],
[102,25,10,6],
[102,25,10,8],
[102,25,11,0],
[102,25,11,1],
[102,25,11,2],
[102,25,11,3],
[102,25,11,4],
[102,25,11,5],
[102,25,11,6],
[102,25,11,8],
[102,25,11,10],
[102,25,12,0],
[102,25,12,1],
[102,25,12,2],
[102,25,12,3],
[102,25,12,4],
[102,25,12,5],
[102,25,12,6],
[102,25,12,8],
[102,25,13,0],
[102,25,13,1],
[102,25,13,2],
[102,25,13,3],
[102,25,13,4],
[102,25,13,5],
[102,25,13,6],
[102,25,13,8],
[102,25,14,0],
[102,25,14,1],
[102,25,14,2],
[102,25,14,3],
[102,25,14,4],
[102,25,14,5],
[102,25,14,6],
[102,25,16,0],
[102,25,16,1],
[102,25,16,2],
[102,25,16,3],
[102,25,16,4],
[102,25,17,0],
[102,25,17,1],
[102,25,17,2],
[102,25,17,3],
[102,25,17,4],
[102,27,3,0],
[102,27,3,1],
[102,27,3,2],
[102,27,4,0],
[102,27,4,1],
[102,27,4,2],
[102,27,4,3],
[102,27,5,0],
[102,27,5,1],
[102,27,5,2],
[102,27,5,4],
[102,27,6,0],
[102,27,6,1],
[102,27,6,2],
[102,27,6,4],
[102,27,7,0],
[102,27,7,1],
[102,27,7,2],
[102,27,7,3],
[102,27,7,4],
[102,27,7,5],
[102,27,7,6],
[102,27,8,0],
[102,27,8,1],
[102,27,8,2],
[102,27,8,4],
[102,27,8,7],
[102,27,11,0],
[102,27,11,1],
[102,27,11,2],
[102,27,11,3],
[102,27,11,4],
[102,27,11,5],
[102,27,11,6],
[102,27,11,7],
[102,27,12,0],
[102,27,12,1],
[102,27,12,2],
[102,27,12,3],
[102,27,12,4],
[102,27,12,5],
[102,27,13,0],
[102,27,13,1],
[102,27,13,2],
[102,27,13,3],
[102,27,13,4],
[102,27,13,5],
[102,27,14,0],
[102,27,14,1],
[102,27,14,2],
[102,27,14,3],
[102,27,14,4],
[102,28,3,0],
[102,28,3,1],
[102,28,3,2],
[102,28,4,0],
[102,28,4,1],
[102,28,4,2],
[102,28,4,3],
[102,28,5,0],
[102,28,5,1],
[102,28,5,2],
[102,28,5,4],
[102,28,6,0],
[102,28,6,1],
[102,28,6,2],
[102,28,6,4],
[102,28,8,0],
[102,28,8,1],
[102,28,8,2],
[102,28,8,4],
[102,28,10,0],
[102,28,10,1],
[102,28,10,2],
[102,28,10,3],
[102,28,10,4],
[102,28,10,5],
[102,28,11,0],
[102,28,11,1],
[102,28,11,2],
[102,28,11,3],
[102,28,11,4],
[102,28,11,5],
[102,28,12,0],
[102,28,12,1],
[102,28,12,2],
[102,28,12,3],
[102,28,12,4],
[102,28,13,0],
[102,28,13,1],
[102,28,13,2],
[102,28,13,3],
[102,28,13,4],
[102,28,14,0],
[102,28,14,1],
[102,28,14,2],
[102,29,3,0],
[102,29,3,1],
[102,29,3,2],
[102,29,4,0],
[102,29,4,1],
[102,29,4,2],
[102,29,4,3],
[102,29,5,0],
[102,29,5,1],
[102,29,5,2],
[102,29,5,4],
[102,29,6,0],
[102,29,6,1],
[102,29,6,2],
[102,29,6,4],
[102,29,7,0],
[102,29,7,1],
[102,29,7,2],
[102,29,7,3],
[102,29,7,4],
[102,29,7,5],
[102,29,7,6],
[102,29,8,0],
[102,29,8,1],
[102,29,8,2],
[102,29,8,4],
[102,29,10,0],
[102,29,10,1],
[102,29,10,2],
[102,29,10,3],
[102,29,10,4],
[102,29,13,0],
[102,29,13,1],
[102,29,13,2],
[102,30,3,0],
[102,30,3,1],
[102,30,3,2],
[102,30,4,0],
[102,30,4,1],
[102,30,4,2],
[102,30,4,3],
[102,30,5,0],
[102,30,5,1],
[102,30,5,2],
[102,30,5,4],
[102,30,6,0],
[102,30,6,1],
[102,30,6,2],
[102,30,6,4],
[102,30,7,0],
[102,30,7,1],
[102,30,7,2],
[102,30,7,3],
[102,30,7,4],
[102,30,7,5],
[102,30,8,0],
[102,30,8,1],
[102,30,8,2],
[102,30,8,4],
[102,30,10,0],
[102,30,10,1],
[102,30,10,2],
[102,30,11,0],
[102,30,11,1],
[102,30,11,2],
[102,31,3,0],
[102,31,3,1],
[102,31,3,2],
[102,31,4,0],
[102,31,4,1],
[102,31,4,2],
[102,31,4,3],
[102,31,5,0],
[102,31,5,1],
[102,31,5,2],
[102,31,5,4],
[102,31,6,0],
[102,31,6,1],
[102,31,6,2],
[102,31,6,4],
[102,31,7,0],
[102,31,7,1],
[102,31,7,2],
[102,31,7,3],
[102,31,7,4],
[102,31,8,0],
[102,31,8,1],
[102,31,8,2],
[102,32,3,0],
[102,32,3,1],
[102,32,3,2],
[102,32,4,0],
[102,32,4,1],
[102,32,4,2],
[102,32,4,3],
[102,32,5,0],
[102,32,5,1],
[102,32,5,2],
[102,32,5,4],
[102,32,6,0],
[102,32,6,1],
[102,32,6,2],
[102,32,7,0],
[102,32,7,1],
[102,32,7,2],
[102,33,3,0],
[102,33,3,1],
[102,33,3,2],
[102,33,4,0],
[102,33,4,1],
[102,33,4,2],
[102,33,4,3],
[102,33,5,0],
[102,33,5,1],
[102,33,5,2],
[102,34,3,0],
[102,34,3,1],
[102,34,3,2],
[102,34,4,0],
[102,34,4,1],
[102,34,4,2],
[103,4,3,0],
[103,4,3,1],
[103,4,3,2],
[103,5,4,0],
[103,5,4,1],
[103,5,4,2],
[103,6,4,0],
[103,6,4,1],
[103,6,4,2],
[103,7,3,0],
[103,7,3,1],
[103,7,3,2],
[103,7,4,0],
[103,7,4,1],
[103,7,4,2],
[103,7,4,3],
[103,7,5,0],
[103,7,5,1],
[103,7,5,2],
[103,7,5,4],
[103,7,6,0],
[103,7,6,1],
[103,7,6,2],
[103,7,6,4],
[103,8,4,0],
[103,8,4,1],
[103,8,4,2],
[103,8,7,0],
[103,8,7,1],
[103,8,7,2],
[103,8,7,4],
[103,9,3,0],
[103,9,3,1],
[103,9,3,2],
[103,9,4,0],
[103,9,4,1],
[103,9,4,2],
[103,9,4,3],
[103,9,5,0],
[103,9,5,1],
[103,9,5,2],
[103,9,5,4],
[103,9,6,0],
[103,9,6,1],
[103,9,6,2],
[103,9,6,4],
[103,9,7,0],
[103,9,7,1],
[103,9,7,2],
[103,9,7,3],
[103,9,7,4],
[103,9,7,5],
[103,9,7,6],
[103,9,8,0],
[103,9,8,1],
[103,9,8,2],
[103,9,8,4],
[103,9,8,7],
[103,11,3,0],
[103,11,3,1],
[103,11,3,2],
[103,11,4,0],
[103,11,4,1],
[103,11,4,2],
[103,11,4,3],
[103,11,5,0],
[103,11,5,1],
[103,11,5,2],
[103,11,5,4],
[103,11,6,0],
[103,11,6,1],
[103,11,6,2],
[103,11,6,4],
[103,11,7,0],
[103,11,7,1],
[103,11,7,2],
[103,11,7,3],
[103,11,7,4],
[103,11,7,5],
[103,11,7,6],
[103,11,8,0],
[103,11,8,1],
[103,11,8,2],
[103,11,8,4],
[103,11,8,7],
[103,11,9,0],
[103,11,9,1],
[103,11,9,2],
[103,11,9,3],
[103,11,9,4],
[103,11,9,5],
[103,11,9,6],
[103,11,9,7],
[103,11,9,8],
[103,12,3,0],
[103,12,3,1],
[103,12,3,2],
[103,12,4,0],
[103,12,4,1],
[103,12,4,2],
[103,12,4,3],
[103,12,5,0],
[103,12,5,1],
[103,12,5,2],
[103,12,5,4],
[103,12,6,0],
[103,12,6,1],
[103,12,6,2],
[103,12,6,4],
[103,12,7,0],
[103,12,7,1],
[103,12,7,2],
[103,12,7,3],
[103,12,7,4],
[103,12,7,5],
[103,12,7,6],
[103,12,8,0],
[103,12,8,1],
[103,12,8,2],
[103,12,8,4],
[103,12,8,7],
[103,12,9,0],
[103,12,9,1],
[103,12,9,2],
[103,12,9,3],
[103,12,9,4],
[103,12,9,5],
[103,12,9,6],
[103,12,9,7],
[103,12,9,8],
[103,13,3,0],
[103,13,3,1],
[103,13,3,2],
[103,13,4,0],
[103,13,4,1],
[103,13,4,2],
[103,13,4,3],
[103,13,5,0],
[103,13,5,1],
[103,13,5,2],
[103,13,5,4],
[103,13,6,0],
[103,13,6,1],
[103,13,6,2],
[103,13,6,4],
[103,13,7,0],
[103,13,7,1],
[103,13,7,2],
[103,13,7,3],
[103,13,7,4],
[103,13,7,5],
[103,13,7,6],
[103,13,8,0],
[103,13,8,1],
[103,13,8,2],
[103,13,8,4],
[103,13,8,7],
[103,13,9,0],
[103,13,9,1],
[103,13,9,2],
[103,13,9,3],
[103,13,9,4],
[103,13,9,5],
[103,13,9,6],
[103,13,9,7],
[103,13,9,8],
[103,13,11,0],
[103,13,11,1],
[103,13,11,2],
[103,13,11,3],
[103,13,11,4],
[103,13,11,5],
[103,13,11,6],
[103,13,11,7],
[103,13,12,0],
[103,13,12,1],
[103,13,12,2],
[103,13,12,3],
[103,13,12,4],
[103,13,12,5],
[103,13,12,8],
[103,13,12,9],
[103,14,3,0],
[103,14,3,1],
[103,14,3,2],
[103,14,4,0],
[103,14,4,1],
[103,14,4,2],
[103,14,4,3],
[103,14,5,0],
[103,14,5,1],
[103,14,5,2],
[103,14,5,4],
[103,14,6,0],
[103,14,6,1],
[103,14,6,2],
[103,14,6,4],
[103,14,7,0],
[103,14,7,1],
[103,14,7,2],
[103,14,7,3],
[103,14,7,4],
[103,14,7,5],
[103,14,7,6],
[103,14,8,0],
[103,14,8,1],
[103,14,8,2],
[103,14,8,4],
[103,14,8,7],
[103,14,9,0],
[103,14,9,1],
[103,14,9,2],
[103,14,9,3],
[103,14,9,4],
[103,14,9,5],
[103,14,9,6],
[103,14,9,7],
[103,14,11,0],
[103,14,11,1],
[103,14,11,2],
[103,14,11,3],
[103,14,11,4],
[103,14,11,5],
[103,14,11,8],
[103,14,11,9],
[103,14,12,0],
[103,14,12,1],
[103,14,12,2],
[103,14,12,3],
[103,14,12,4],
[103,14,12,6],
[103,14,12,7],
[103,14,12,8],
[103,14,12,9],
[103,15,3,0],
[103,15,3,1],
[103,15,3,2],
[103,15,4,0],
[103,15,4,1],
[103,15,4,2],
[103,15,4,3],
[103,15,5,0],
[103,15,5,1],
[103,15,5,2],
[103,15,5,4],
[103,15,6,0],
[103,15,6,1],
[103,15,6,2],
[103,15,6,4],
[103,15,7,0],
[103,15,7,1],
[103,15,7,2],
[103,15,7,3],
[103,15,7,4],
[103,15,7,5],
[103,15,7,6],
[103,15,8,0],
[103,15,8,1],
[103,15,8,2],
[103,15,8,4],
[103,15,11,0],
[103,15,11,1],
[103,15,11,2],
[103,15,11,3],
[103,15,11,4],
[103,15,11,6],
[103,15,11,7],
[103,15,11,8],
[103,15,12,0],
[103,15,12,1],
[103,15,12,2],
[103,15,12,5],
[103,15,12,6],
[103,15,12,7],
[103,15,12,8],
[103,15,13,0],
[103,15,13,1],
[103,15,13,2],
[103,15,13,5],
[103,15,13,6],
[103,15,13,7],
[103,15,13,8],
[103,15,13,11],
[103,15,13,12],
[103,15,14,3],
[103,15,14,4],
[103,15,14,5],
[103,15,14,6],
[103,15,14,7],
[103,15,14,8],
[103,15,14,11],
[103,15,14,12],
[103,16,3,0],
[103,16,3,1],
[103,16,3,2],
[103,16,4,0],
[103,16,4,1],
[103,16,4,2],
[103,16,4,3],
[103,16,5,0],
[103,16,5,1],
[103,16,5,2],
[103,16,5,4],
[103,16,6,0],
[103,16,6,1],
[103,16,6,2],
[103,16,6,4],
[103,16,7,0],
[103,16,7,1],
[103,16,7,2],
[103,16,7,3],
[103,16,7,4],
[103,16,7,5],
[103,16,8,0],
[103,16,8,1],
[103,16,8,2],
[103,16,8,4],
[103,16,8,7],
[103,16,9,0],
[103,16,9,1],
[103,16,9,2],
[103,16,9,3],
[103,16,9,4],
[103,16,9,6],
[103,16,9,7],
[103,16,9,8],
[103,16,11,0],
[103,16,11,1],
[103,16,11,2],
[103,16,11,5],
[103,16,11,6],
[103,16,11,7],
[103,16,11,8],
[103,16,11,9],
[103,16,12,3],
[103,16,12,4],
[103,16,12,5],
[103,16,12,6],
[103,16,12,7],
[103,16,12,8],
[103,16,12,9],
[103,16,13,3],
[103,16,13,4],
[103,16,13,5],
[103,16,13,6],
[103,16,13,7],
[103,16,13,8],
[103,16,13,9],
[103,16,13,11],
[103,16,13,12],
[103,16,14,0],
[103,16,14,1],
[103,16,14,2],
[103,16,14,3],
[103,16,14,4],
[103,16,14,5],
[103,16,14,6],
[103,16,14,7],
[103,16,14,8],
[103,16,14,9],
[103,16,14,11],
[103,16,14,12],
[103,16,15,0],
[103,16,15,1],
[103,16,15,2],
[103,16,15,3],
[103,16,15,4],
[103,16,15,5],
[103,16,15,6],
[103,16,15,7],
[103,16,15,8],
[103,16,15,11],
[103,16,15,12],
[103,16,15,13],
[103,16,15,14],
[103,17,3,0],
[103,17,3,1],
[103,17,3,2],
[103,17,4,0],
[103,17,4,1],
[103,17,4,2],
[103,17,4,3],
[103,17,5,0],
[103,17,5,1],
[103,17,5,2],
[103,17,5,4],
[103,17,6,0],
[103,17,6,1],
[103,17,6,2],
[103,17,6,4],
[103,17,7,0],
[103,17,7,1],
[103,17,7,2],
[103,17,7,3],
[103,17,7,4],
[103,17,7,5],
[103,17,8,0],
[103,17,8,1],
[103,17,8,2],
[103,17,8,4],
[103,17,8,7],
[103,17,9,0],
[103,17,9,1],
[103,17,9,2],
[103,17,9,3],
[103,17,9,4],
[103,17,9,6],
[103,17,9,7],
[103,17,9,8],
[103,17,11,0],
[103,17,11,1],
[103,17,11,2],
[103,17,11,5],
[103,17,11,6],
[103,17,11,7],
[103,17,11,8],
[103,17,11,9],
[103,17,12,3],
[103,17,12,4],
[103,17,12,5],
[103,17,12,6],
[103,17,12,7],
[103,17,12,8],
[103,17,12,9],
[103,17,13,3],
[103,17,13,4],
[103,17,13,5],
[103,17,13,6],
[103,17,13,7],
[103,17,13,8],
[103,17,13,9],
[103,17,13,11],
[103,17,13,12],
[103,17,14,0],
[103,17,14,1],
[103,17,14,2],
[103,17,14,3],
[103,17,14,4],
[103,17,14,5],
[103,17,14,6],
[103,17,14,7],
[103,17,14,8],
[103,17,14,9],
[103,17,14,11],
[103,17,14,12],
[103,17,15,0],
[103,17,15,1],
[103,17,15,2],
[103,17,15,3],
[103,17,15,4],
[103,17,15,5],
[103,17,15,6],
[103,17,15,7],
[103,17,15,8],
[103,17,15,11],
[103,17,15,12],
[103,17,15,13],
[103,17,15,14],
[103,17,16,0],
[103,17,16,1],
[103,17,16,2],
[103,17,16,3],
[103,17,16,4],
[103,17,16,5],
[103,17,16,6],
[103,17,16,7],
[103,17,16,8],
[103,17,16,9],
[103,17,16,11],
[103,17,16,12],
[103,17,16,13],
[103,17,16,14],
[103,17,16,15],
[103,18,3,0],
[103,18,3,1],
[103,18,3,2],
[103,18,4,0],
[103,18,4,1],
[103,18,4,2],
[103,18,4,3],
[103,18,5,0],
[103,18,5,1],
[103,18,5,2],
[103,18,5,4],
[103,18,6,0],
[103,18,6,1],
[103,18,6,2],
[103,18,6,4],
[103,18,7,0],
[103,18,7,1],
[103,18,7,2],
[103,18,7,3],
[103,18,7,4],
[103,18,7,6],
[103,18,8,0],
[103,18,8,1],
[103,18,8,2],
[103,18,8,7],
[103,18,11,3],
[103,18,11,4],
[103,18,11,5],
[103,18,11,6],
[103,18,11,7],
[103,18,11,8],
[103,18,12,0],
[103,18,12,1],
[103,18,12,2],
[103,18,12,3],
[103,18,12,4],
[103,18,12,5],
[103,18,12,6],
[103,18,12,7],
[103,18,12,8],
[103,18,13,0],
[103,18,13,1],
[103,18,13,2],
[103,18,13,3],
[103,18,13,4],
[103,18,13,5],
[103,18,13,6],
[103,18,13,7],
[103,18,13,8],
[103,18,13,11],
[103,18,13,12],
[103,18,14,0],
[103,18,14,1],
[103,18,14,2],
[103,18,14,3],
[103,18,14,4],
[103,18,14,5],
[103,18,14,6],
[103,18,14,7],
[103,18,14,8],
[103,18,14,11],
[103,18,14,12],
[103,18,16,0],
[103,18,16,1],
[103,18,16,2],
[103,18,16,3],
[103,18,16,4],
[103,18,16,5],
[103,18,16,6],
[103,18,16,7],
[103,18,16,8],
[103,18,16,11],
[103,18,16,12],
[103,18,16,13],
[103,18,16,14],
[103,18,17,0],
[103,18,17,1],
[103,18,17,2],
[103,18,17,3],
[103,18,17,4],
[103,18,17,5],
[103,18,17,6],
[103,18,17,7],
[103,18,17,8],
[103,18,17,11],
[103,18,17,12],
[103,18,17,13],
[103,18,17,14],
[103,19,3,0],
[103,19,3,1],
[103,19,3,2],
[103,19,4,0],
[103,19,4,1],
[103,19,4,2],
[103,19,4,3],
[103,19,5,0],
[103,19,5,1],
[103,19,5,2],
[103,19,5,4],
[103,19,6,0],
[103,19,6,1],
[103,19,6,2],
[103,19,7,0],
[103,19,7,1],
[103,19,7,2],
[103,19,7,5],
[103,19,7,6],
[103,19,8,4],
[103,19,8,7],
[103,19,9,3],
[103,19,9,4],
[103,19,9,5],
[103,19,9,6],
[103,19,9,7],
[103,19,9,8],
[103,19,11,0],
[103,19,11,1],
[103,19,11,2],
[103,19,11,3],
[103,19,11,4],
[103,19,11,5],
[103,19,11,6],
[103,19,11,7],
[103,19,11,8],
[103,19,11,9],
[103,19,12,0],
[103,19,12,1],
[103,19,12,2],
[103,19,12,3],
[103,19,12,4],
[103,19,12,5],
[103,19,12,6],
[103,19,12,7],
[103,19,12,8],
[103,19,12,9],
[103,19,15,0],
[103,19,15,1],
[103,19,15,2],
[103,19,15,3],
[103,19,15,4],
[103,19,15,5],
[103,19,15,6],
[103,19,15,7],
[103,19,15,8],
[103,19,15,11],
[103,19,15,12],
[103,19,16,0],
[103,19,16,1],
[103,19,16,2],
[103,19,16,3],
[103,19,16,4],
[103,19,16,5],
[103,19,16,6],
[103,19,16,7],
[103,19,16,8],
[103,19,16,9],
[103,19,16,11],
[103,19,16,12],
[103,19,17,0],
[103,19,17,1],
[103,19,17,2],
[103,19,17,3],
[103,19,17,4],
[103,19,17,5],
[103,19,17,6],
[103,19,17,7],
[103,19,17,8],
[103,19,17,9],
[103,19,17,11],
[103,19,17,12],
[103,19,18,0],
[103,19,18,1],
[103,19,18,2],
[103,19,18,3],
[103,19,18,4],
[103,19,18,5],
[103,19,18,6],
[103,19,18,7],
[103,19,18,8],
[103,19,18,11],
[103,19,18,12],
[103,21,4,0],
[103,21,4,1],
[103,21,4,2],
[103,21,7,0],
[103,21,7,1],
[103,21,7,2],
[103,21,7,4],
[103,21,9,0],
[103,21,9,1],
[103,21,9,2],
[103,21,9,4],
[103,21,9,7],
[103,21,11,0],
[103,21,11,1],
[103,21,11,2],
[103,21,11,4],
[103,21,11,7],
[103,21,11,9],
[103,21,12,0],
[103,21,12,1],
[103,21,12,2],
[103,21,12,4],
[103,21,12,7],
[103,21,12,9],
[103,21,13,0],
[103,21,13,1],
[103,21,13,2],
[103,21,13,4],
[103,21,13,7],
[103,21,13,9],
[103,21,13,11],
[103,21,13,12],
[103,21,14,0],
[103,21,14,1],
[103,21,14,2],
[103,21,14,4],
[103,21,14,7],
[103,21,14,9],
[103,21,14,11],
[103,21,14,12],
[103,21,15,0],
[103,21,15,1],
[103,21,15,2],
[103,21,15,4],
[103,21,15,7],
[103,21,15,11],
[103,21,15,12],
[103,21,15,13],
[103,21,16,0],
[103,21,16,1],
[103,21,16,2],
[103,21,16,4],
[103,21,16,7],
[103,21,16,9],
[103,21,16,11],
[103,21,17,0],
[103,21,17,1],
[103,21,17,2],
[103,21,17,4],
[103,21,17,7],
[103,21,17,9],
[103,21,17,11],
[103,21,18,0],
[103,21,18,1],
[103,21,18,2],
[103,21,18,4],
[103,21,18,7],
[103,21,19,0],
[103,21,19,1],
[103,21,19,2],
[103,21,19,4],
[103,21,19,7],
[103,23,3,0],
[103,23,3,1],
[103,23,3,2],
[103,23,4,0],
[103,23,4,1],
[103,23,4,2],
[103,23,4,3],
[103,23,5,0],
[103,23,5,1],
[103,23,5,2],
[103,23,5,4],
[103,23,6,0],
[103,23,6,1],
[103,23,6,2],
[103,23,6,4],
[103,23,7,0],
[103,23,7,1],
[103,23,7,2],
[103,23,7,3],
[103,23,7,4],
[103,23,7,5],
[103,23,7,6],
[103,23,8,0],
[103,23,8,1],
[103,23,8,2],
[103,23,8,4],
[103,23,8,7],
[103,23,9,0],
[103,23,9,1],
[103,23,9,2],
[103,23,9,3],
[103,23,9,4],
[103,23,9,5],
[103,23,9,6],
[103,23,9,7],
[103,23,9,8],
[103,23,11,0],
[103,23,11,1],
[103,23,11,2],
[103,23,11,3],
[103,23,11,4],
[103,23,11,5],
[103,23,11,6],
[103,23,11,7],
[103,23,11,8],
[103,23,11,9],
[103,23,12,0],
[103,23,12,1],
[103,23,12,2],
[103,23,12,3],
[103,23,12,4],
[103,23,12,5],
[103,23,12,6],
[103,23,12,7],
[103,23,12,8],
[103,23,12,9],
[103,23,13,0],
[103,23,13,1],
[103,23,13,2],
[103,23,13,3],
[103,23,13,4],
[103,23,13,5],
[103,23,13,6],
[103,23,13,7],
[103,23,13,8],
[103,23,13,9],
[103,23,13,11],
[103,23,13,12],
[103,23,14,0],
[103,23,14,1],
[103,23,14,2],
[103,23,14,3],
[103,23,14,4],
[103,23,14,5],
[103,23,14,6],
[103,23,14,7],
[103,23,14,8],
[103,23,14,9],
[103,23,14,11],
[103,23,15,0],
[103,23,15,1],
[103,23,15,2],
[103,23,15,3],
[103,23,15,4],
[103,23,15,5],
[103,23,15,6],
[103,23,15,7],
[103,23,15,8],
[103,23,16,0],
[103,23,16,1],
[103,23,16,2],
[103,23,16,3],
[103,23,16,4],
[103,23,16,5],
[103,23,16,6],
[103,23,16,7],
[103,23,17,0],
[103,23,17,1],
[103,23,17,2],
[103,23,17,3],
[103,23,17,4],
[103,23,17,5],
[103,23,17,6],
[103,23,17,7],
[103,23,18,0],
[103,23,18,1],
[103,23,18,2],
[103,23,18,3],
[103,23,18,4],
[103,23,18,5],
[103,23,19,0],
[103,23,19,1],
[103,23,19,2],
[103,23,19,3],
[103,23,19,4],
[103,24,3,0],
[103,24,3,1],
[103,24,3,2],
[103,24,4,0],
[103,24,4,1],
[103,24,4,2],
[103,24,4,3],
[103,24,5,0],
[103,24,5,1],
[103,24,5,2],
[103,24,5,4],
[103,24,6,0],
[103,24,6,1],
[103,24,6,2],
[103,24,6,4],
[103,24,7,0],
[103,24,7,1],
[103,24,7,2],
[103,24,7,3],
[103,24,7,4],
[103,24,7,5],
[103,24,7,6],
[103,24,8,0],
[103,24,8,1],
[103,24,8,2],
[103,24,8,4],
[103,24,8,7],
[103,24,9,0],
[103,24,9,1],
[103,24,9,2],
[103,24,9,3],
[103,24,9,4],
[103,24,9,5],
[103,24,9,6],
[103,24,9,7],
[103,24,9,8],
[103,24,11,0],
[103,24,11,1],
[103,24,11,2],
[103,24,11,3],
[103,24,11,4],
[103,24,11,5],
[103,24,11,6],
[103,24,11,7],
[103,24,11,8],
[103,24,11,9],
[103,24,12,0],
[103,24,12,1],
[103,24,12,2],
[103,24,12,3],
[103,24,12,4],
[103,24,12,5],
[103,24,12,6],
[103,24,12,7],
[103,24,12,8],
[103,24,12,9],
[103,24,13,0],
[103,24,13,1],
[103,24,13,2],
[103,24,13,3],
[103,24,13,4],
[103,24,13,5],
[103,24,13,6],
[103,24,13,7],
[103,24,13,8],
[103,24,13,9],
[103,24,13,11],
[103,24,13,12],
[103,24,14,0],
[103,24,14,1],
[103,24,14,2],
[103,24,14,3],
[103,24,14,4],
[103,24,14,5],
[103,24,14,6],
[103,24,14,7],
[103,24,14,8],
[103,24,14,9],
[103,24,14,11],
[103,24,15,0],
[103,24,15,1],
[103,24,15,2],
[103,24,15,3],
[103,24,15,4],
[103,24,15,5],
[103,24,15,6],
[103,24,15,7],
[103,24,15,8],
[103,24,17,0],
[103,24,17,1],
[103,24,17,2],
[103,24,17,3],
[103,24,17,4],
[103,24,17,5],
[103,24,17,6],
[103,24,17,7],
[103,24,18,0],
[103,24,18,1],
[103,24,18,2],
[103,24,18,3],
[103,24,18,4],
[103,24,18,5],
[103,24,19,0],
[103,24,19,1],
[103,24,19,2],
[103,24,19,3],
[103,24,19,4],
[103,25,3,0],
[103,25,3,1],
[103,25,3,2],
[103,25,4,0],
[103,25,4,1],
[103,25,4,2],
[103,25,4,3],
[103,25,5,0],
[103,25,5,1],
[103,25,5,2],
[103,25,5,4],
[103,25,6,0],
[103,25,6,1],
[103,25,6,2],
[103,25,6,4],
[103,25,8,0],
[103,25,8,1],
[103,25,8,2],
[103,25,8,4],
[103,25,9,0],
[103,25,9,1],
[103,25,9,2],
[103,25,9,3],
[103,25,9,4],
[103,25,9,5],
[103,25,9,6],
[103,25,9,8],
[103,25,11,0],
[103,25,11,1],
[103,25,11,2],
[103,25,11,3],
[103,25,11,4],
[103,25,11,5],
[103,25,11,6],
[103,25,11,8],
[103,25,11,9],
[103,25,12,0],
[103,25,12,1],
[103,25,12,2],
[103,25,12,3],
[103,25,12,4],
[103,25,12,5],
[103,25,12,6],
[103,25,12,8],
[103,25,12,9],
[103,25,13,0],
[103,25,13,1],
[103,25,13,2],
[103,25,13,3],
[103,25,13,4],
[103,25,13,5],
[103,25,13,6],
[103,25,13,8],
[103,25,13,9],
[103,25,14,0],
[103,25,14,1],
[103,25,14,2],
[103,25,14,3],
[103,25,14,4],
[103,25,14,5],
[103,25,14,6],
[103,25,15,0],
[103,25,15,1],
[103,25,15,2],
[103,25,15,3],
[103,25,15,4],
[103,25,15,5],
[103,25,16,0],
[103,25,16,1],
[103,25,16,2],
[103,25,16,3],
[103,25,16,4],
[103,25,17,0],
[103,25,17,1],
[103,25,17,2],
[103,25,17,3],
[103,25,17,4],
[103,25,18,0],
[103,25,18,1],
[103,25,18,2],
[103,26,3,0],
[103,26,3,1],
[103,26,3,2],
[103,26,4,0],
[103,26,4,1],
[103,26,4,2],
[103,26,4,3],
[103,26,5,0],
[103,26,5,1],
[103,26,5,2],
[103,26,5,4],
[103,26,6,0],
[103,26,6,1],
[103,26,6,2],
[103,26,6,4],
[103,26,7,0],
[103,26,7,1],
[103,26,7,2],
[103,26,7,3],
[103,26,7,4],
[103,26,7,5],
[103,26,7,6],
[103,26,8,0],
[103,26,8,1],
[103,26,8,2],
[103,26,8,4],
[103,26,8,7],
[103,26,11,0],
[103,26,11,1],
[103,26,11,2],
[103,26,11,3],
[103,26,11,4],
[103,26,11,5],
[103,26,11,6],
[103,26,11,7],
[103,26,11,8],
[103,26,12,0],
[103,26,12,1],
[103,26,12,2],
[103,26,12,3],
[103,26,12,4],
[103,26,12,5],
[103,26,12,6],
[103,26,12,7],
[103,26,13,0],
[103,26,13,1],
[103,26,13,2],
[103,26,13,3],
[103,26,13,4],
[103,26,13,5],
[103,26,13,6],
[103,26,13,7],
[103,26,14,0],
[103,26,14,1],
[103,26,14,2],
[103,26,14,3],
[103,26,14,4],
[103,26,14,5],
[103,26,16,0],
[103,26,16,1],
[103,26,16,2],
[103,26,17,0],
[103,26,17,1],
[103,26,17,2],
[103,28,3,0],
[103,28,3,1],
[103,28,3,2],
[103,28,4,0],
[103,28,4,1],
[103,28,4,2],
[103,28,4,3],
[103,28,5,0],
[103,28,5,1],
[103,28,5,2],
[103,28,5,4],
[103,28,6,0],
[103,28,6,1],
[103,28,6,2],
[103,28,6,4],
[103,28,8,0],
[103,28,8,1],
[103,28,8,2],
[103,28,8,4],
[103,28,9,0],
[103,28,9,1],
[103,28,9,2],
[103,28,9,3],
[103,28,9,4],
[103,28,9,5],
[103,28,9,6],
[103,28,11,0],
[103,28,11,1],
[103,28,11,2],
[103,28,11,3],
[103,28,11,4],
[103,28,11,5],
[103,28,12,0],
[103,28,12,1],
[103,28,12,2],
[103,28,12,3],
[103,28,12,4],
[103,28,13,0],
[103,28,13,1],
[103,28,13,2],
[103,28,13,3],
[103,28,13,4],
[103,28,14,0],
[103,28,14,1],
[103,28,14,2],
[103,29,3,0],
[103,29,3,1],
[103,29,3,2],
[103,29,4,0],
[103,29,4,1],
[103,29,4,2],
[103,29,4,3],
[103,29,5,0],
[103,29,5,1],
[103,29,5,2],
[103,29,5,4],
[103,29,6,0],
[103,29,6,1],
[103,29,6,2],
[103,29,6,4],
[103,29,7,0],
[103,29,7,1],
[103,29,7,2],
[103,29,7,3],
[103,29,7,4],
[103,29,7,5],
[103,29,7,6],
[103,29,8,0],
[103,29,8,1],
[103,29,8,2],
[103,29,8,4],
[103,29,9,0],
[103,29,9,1],
[103,29,9,2],
[103,29,9,3],
[103,29,9,4],
[103,29,9,5],
[103,29,13,0],
[103,29,13,1],
[103,29,13,2],
[103,30,3,0],
[103,30,3,1],
[103,30,3,2],
[103,30,4,0],
[103,30,4,1],
[103,30,4,2],
[103,30,4,3],
[103,30,5,0],
[103,30,5,1],
[103,30,5,2],
[103,30,5,4],
[103,30,6,0],
[103,30,6,1],
[103,30,6,2],
[103,30,6,4],
[103,30,7,0],
[103,30,7,1],
[103,30,7,2],
[103,30,7,3],
[103,30,7,4],
[103,30,7,5],
[103,30,8,0],
[103,30,8,1],
[103,30,8,2],
[103,30,8,4],
[103,30,9,0],
[103,30,9,1],
[103,30,9,2],
[103,30,9,3],
[103,30,9,4],
[103,30,11,0],
[103,30,11,1],
[103,30,11,2],
[103,31,3,0],
[103,31,3,1],
[103,31,3,2],
[103,31,4,0],
[103,31,4,1],
[103,31,4,2],
[103,31,4,3],
[103,31,5,0],
[103,31,5,1],
[103,31,5,2],
[103,31,5,4],
[103,31,6,0],
[103,31,6,1],
[103,31,6,2],
[103,31,6,4],
[103,31,7,0],
[103,31,7,1],
[103,31,7,2],
[103,31,7,3],
[103,31,7,4],
[103,31,8,0],
[103,31,8,1],
[103,31,8,2],
[103,31,9,0],
[103,31,9,1],
[103,31,9,2],
[103,32,3,0],
[103,32,3,1],
[103,32,3,2],
[103,32,4,0],
[103,32,4,1],
[103,32,4,2],
[103,32,4,3],
[103,32,5,0],
[103,32,5,1],
[103,32,5,2],
[103,32,5,4],
[103,32,6,0],
[103,32,6,1],
[103,32,6,2],
[103,32,7,0],
[103,32,7,1],
[103,32,7,2],
[103,33,3,0],
[103,33,3,1],
[103,33,3,2],
[103,33,4,0],
[103,33,4,1],
[103,33,4,2],
[103,33,4,3],
[103,33,5,0],
[103,33,5,1],
[103,33,5,2],
[103,34,3,0],
[103,34,3,1],
[103,34,3,2],
[103,34,4,0],
[103,34,4,1],
[103,34,4,2],
[104,4,3,0],
[104,4,3,1],
[104,4,3,2],
[104,5,4,0],
[104,5,4,1],
[104,5,4,2],
[104,6,4,0],
[104,6,4,1],
[104,6,4,2],
[104,7,3,0],
[104,7,3,1],
[104,7,3,2],
[104,7,4,0],
[104,7,4,1],
[104,7,4,2],
[104,7,4,3],
[104,7,5,0],
[104,7,5,1],
[104,7,5,2],
[104,7,5,4],
[104,7,6,0],
[104,7,6,1],
[104,7,6,2],
[104,7,6,4],
[104,8,4,0],
[104,8,4,1],
[104,8,4,2],
[104,8,7,0],
[104,8,7,1],
[104,8,7,2],
[104,8,7,4],
[104,9,3,0],
[104,9,3,1],
[104,9,3,2],
[104,9,4,0],
[104,9,4,1],
[104,9,4,2],
[104,9,4,3],
[104,9,5,0],
[104,9,5,1],
[104,9,5,2],
[104,9,5,4],
[104,9,6,0],
[104,9,6,1],
[104,9,6,2],
[104,9,6,4],
[104,9,7,0],
[104,9,7,1],
[104,9,7,2],
[104,9,7,3],
[104,9,7,4],
[104,9,7,5],
[104,9,7,6],
[104,9,8,0],
[104,9,8,1],
[104,9,8,2],
[104,9,8,4],
[104,9,8,7],
[104,10,3,0],
[104,10,3,1],
[104,10,3,2],
[104,10,4,0],
[104,10,4,1],
[104,10,4,2],
[104,10,4,3],
[104,10,5,0],
[104,10,5,1],
[104,10,5,2],
[104,10,5,4],
[104,10,6,0],
[104,10,6,1],
[104,10,6,2],
[104,10,6,4],
[104,10,7,0],
[104,10,7,1],
[104,10,7,2],
[104,10,7,3],
[104,10,7,4],
[104,10,7,5],
[104,10,7,6],
[104,10,8,0],
[104,10,8,1],
[104,10,8,2],
[104,10,8,4],
[104,10,8,7],
[104,10,9,0],
[104,10,9,1],
[104,10,9,2],
[104,10,9,3],
[104,10,9,4],
[104,10,9,5],
[104,10,9,6],
[104,10,9,7],
[104,10,9,8],
[104,11,3,0],
[104,11,3,1],
[104,11,3,2],
[104,11,4,0],
[104,11,4,1],
[104,11,4,2],
[104,11,4,3],
[104,11,5,0],
[104,11,5,1],
[104,11,5,2],
[104,11,5,4],
[104,11,6,0],
[104,11,6,1],
[104,11,6,2],
[104,11,6,4],
[104,11,7,0],
[104,11,7,1],
[104,11,7,2],
[104,11,7,3],
[104,11,7,4],
[104,11,7,5],
[104,11,7,6],
[104,11,8,0],
[104,11,8,1],
[104,11,8,2],
[104,11,8,4],
[104,11,8,7],
[104,11,9,0],
[104,11,9,1],
[104,11,9,2],
[104,11,9,3],
[104,11,9,4],
[104,11,9,5],
[104,11,9,6],
[104,11,9,7],
[104,11,9,8],
[104,11,10,0],
[104,11,10,1],
[104,11,10,2],
[104,11,10,3],
[104,11,10,4],
[104,11,10,5],
[104,11,10,6],
[104,11,10,7],
[104,12,3,0],
[104,12,3,1],
[104,12,3,2],
[104,12,4,0],
[104,12,4,1],
[104,12,4,2],
[104,12,4,3],
[104,12,5,0],
[104,12,5,1],
[104,12,5,2],
[104,12,5,4],
[104,12,6,0],
[104,12,6,1],
[104,12,6,2],
[104,12,6,4],
[104,12,7,0],
[104,12,7,1],
[104,12,7,2],
[104,12,7,3],
[104,12,7,4],
[104,12,7,5],
[104,12,7,6],
[104,12,8,0],
[104,12,8,1],
[104,12,8,2],
[104,12,8,4],
[104,12,8,7],
[104,12,9,0],
[104,12,9,1],
[104,12,9,2],
[104,12,9,3],
[104,12,9,4],
[104,12,9,5],
[104,12,9,6],
[104,12,9,7],
[104,12,10,0],
[104,12,10,1],
[104,12,10,2],
[104,12,10,3],
[104,12,10,4],
[104,12,10,5],
[104,12,10,8],
[104,12,10,9],
[104,13,3,0],
[104,13,3,1],
[104,13,3,2],
[104,13,4,0],
[104,13,4,1],
[104,13,4,2],
[104,13,4,3],
[104,13,5,0],
[104,13,5,1],
[104,13,5,2],
[104,13,5,4],
[104,13,6,0],
[104,13,6,1],
[104,13,6,2],
[104,13,6,4],
[104,13,7,0],
[104,13,7,1],
[104,13,7,2],
[104,13,7,3],
[104,13,7,4],
[104,13,7,5],
[104,13,7,6],
[104,13,8,0],
[104,13,8,1],
[104,13,8,2],
[104,13,8,4],
[104,13,8,7],
[104,13,9,0],
[104,13,9,1],
[104,13,9,2],
[104,13,9,3],
[104,13,9,4],
[104,13,9,5],
[104,13,9,6],
[104,13,9,7],
[104,13,10,0],
[104,13,10,1],
[104,13,10,2],
[104,13,10,3],
[104,13,10,4],
[104,13,10,5],
[104,13,10,8],
[104,13,10,9],
[104,13,11,0],
[104,13,11,1],
[104,13,11,2],
[104,13,11,3],
[104,13,11,4],
[104,13,11,5],
[104,13,11,8],
[104,13,11,9],
[104,13,11,10],
[104,13,12,0],
[104,13,12,1],
[104,13,12,2],
[104,13,12,3],
[104,13,12,4],
[104,13,12,6],
[104,13,12,7],
[104,13,12,8],
[104,13,12,9],
[104,13,12,10],
[104,14,3,0],
[104,14,3,1],
[104,14,3,2],
[104,14,4,0],
[104,14,4,1],
[104,14,4,2],
[104,14,4,3],
[104,14,5,0],
[104,14,5,1],
[104,14,5,2],
[104,14,5,4],
[104,14,6,0],
[104,14,6,1],
[104,14,6,2],
[104,14,6,4],
[104,14,7,0],
[104,14,7,1],
[104,14,7,2],
[104,14,7,3],
[104,14,7,4],
[104,14,7,5],
[104,14,7,6],
[104,14,8,0],
[104,14,8,1],
[104,14,8,2],
[104,14,8,4],
[104,14,9,0],
[104,14,9,1],
[104,14,9,2],
[104,14,9,3],
[104,14,9,4],
[104,14,9,5],
[104,14,9,8],
[104,14,10,0],
[104,14,10,1],
[104,14,10,2],
[104,14,10,3],
[104,14,10,4],
[104,14,10,6],
[104,14,10,7],
[104,14,10,8],
[104,14,10,9],
[104,14,11,0],
[104,14,11,1],
[104,14,11,2],
[104,14,11,3],
[104,14,11,4],
[104,14,11,6],
[104,14,11,7],
[104,14,11,8],
[104,14,11,9],
[104,14,11,10],
[104,14,12,0],
[104,14,12,1],
[104,14,12,2],
[104,14,12,5],
[104,14,12,6],
[104,14,12,7],
[104,14,12,8],
[104,14,12,9],
[104,14,12,10],
[104,15,3,0],
[104,15,3,1],
[104,15,3,2],
[104,15,4,0],
[104,15,4,1],
[104,15,4,2],
[104,15,4,3],
[104,15,5,0],
[104,15,5,1],
[104,15,5,2],
[104,15,5,4],
[104,15,6,0],
[104,15,6,1],
[104,15,6,2],
[104,15,6,4],
[104,15,7,0],
[104,15,7,1],
[104,15,7,2],
[104,15,7,3],
[104,15,7,4],
[104,15,7,5],
[104,15,8,0],
[104,15,8,1],
[104,15,8,2],
[104,15,8,4],
[104,15,8,7],
[104,15,10,0],
[104,15,10,1],
[104,15,10,2],
[104,15,10,5],
[104,15,10,6],
[104,15,10,7],
[104,15,10,8],
[104,15,11,0],
[104,15,11,1],
[104,15,11,2],
[104,15,11,5],
[104,15,11,6],
[104,15,11,7],
[104,15,11,8],
[104,15,11,10],
[104,15,12,3],
[104,15,12,4],
[104,15,12,5],
[104,15,12,6],
[104,15,12,7],
[104,15,12,8],
[104,15,12,10],
[104,15,13,3],
[104,15,13,4],
[104,15,13,5],
[104,15,13,6],
[104,15,13,7],
[104,15,13,8],
[104,15,13,10],
[104,15,13,11],
[104,15,13,12],
[104,15,14,0],
[104,15,14,1],
[104,15,14,2],
[104,15,14,3],
[104,15,14,4],
[104,15,14,5],
[104,15,14,6],
[104,15,14,7],
[104,15,14,8],
[104,15,14,10],
[104,15,14,11],
[104,15,14,12],
[104,16,3,0],
[104,16,3,1],
[104,16,3,2],
[104,16,4,0],
[104,16,4,1],
[104,16,4,2],
[104,16,4,3],
[104,16,5,0],
[104,16,5,1],
[104,16,5,2],
[104,16,5,4],
[104,16,6,0],
[104,16,6,1],
[104,16,6,2],
[104,16,6,4],
[104,16,7,0],
[104,16,7,1],
[104,16,7,2],
[104,16,7,3],
[104,16,7,4],
[104,16,7,6],
[104,16,8,0],
[104,16,8,1],
[104,16,8,2],
[104,16,8,7],
[104,16,9,0],
[104,16,9,1],
[104,16,9,2],
[104,16,9,5],
[104,16,9,6],
[104,16,9,7],
[104,16,9,8],
[104,16,10,3],
[104,16,10,4],
[104,16,10,5],
[104,16,10,6],
[104,16,10,7],
[104,16,10,8],
[104,16,10,9],
[104,16,11,3],
[104,16,11,4],
[104,16,11,5],
[104,16,11,6],
[104,16,11,7],
[104,16,11,8],
[104,16,11,9],
[104,16,11,10],
[104,16,12,0],
[104,16,12,1],
[104,16,12,2],
[104,16,12,3],
[104,16,12,4],
[104,16,12,5],
[104,16,12,6],
[104,16,12,7],
[104,16,12,8],
[104,16,12,9],
[104,16,12,10],
[104,16,13,0],
[104,16,13,1],
[104,16,13,2],
[104,16,13,3],
[104,16,13,4],
[104,16,13,5],
[104,16,13,6],
[104,16,13,7],
[104,16,13,8],
[104,16,13,9],
[104,16,13,10],
[104,16,13,11],
[104,16,13,12],
[104,16,14,0],
[104,16,14,1],
[104,16,14,2],
[104,16,14,3],
[104,16,14,4],
[104,16,14,5],
[104,16,14,6],
[104,16,14,7],
[104,16,14,8],
[104,16,14,9],
[104,16,14,10],
[104,16,14,11],
[104,16,14,12],
[104,16,15,0],
[104,16,15,1],
[104,16,15,2],
[104,16,15,3],
[104,16,15,4],
[104,16,15,5],
[104,16,15,6],
[104,16,15,7],
[104,16,15,8],
[104,16,15,10],
[104,16,15,11],
[104,16,15,12],
[104,16,15,13],
[104,16,15,14],
[104,18,3,0],
[104,18,3,1],
[104,18,3,2],
[104,18,4,0],
[104,18,4,1],
[104,18,4,2],
[104,18,4,3],
[104,18,5,0],
[104,18,5,1],
[104,18,5,2],
[104,18,5,4],
[104,18,6,0],
[104,18,6,1],
[104,18,6,2],
[104,18,7,0],
[104,18,7,1],
[104,18,7,2],
[104,18,7,5],
[104,18,7,6],
[104,18,8,4],
[104,18,8,7],
[104,18,10,0],
[104,18,10,1],
[104,18,10,2],
[104,18,10,3],
[104,18,10,4],
[104,18,10,5],
[104,18,10,6],
[104,18,10,7],
[104,18,10,8],
[104,18,11,0],
[104,18,11,1],
[104,18,11,2],
[104,18,11,3],
[104,18,11,4],
[104,18,11,5],
[104,18,11,6],
[104,18,11,7],
[104,18,11,8],
[104,18,11,10],
[104,18,12,0],
[104,18,12,1],
[104,18,12,2],
[104,18,12,3],
[104,18,12,4],
[104,18,12,5],
[104,18,12,6],
[104,18,12,7],
[104,18,12,8],
[104,18,12,10],
[104,18,13,0],
[104,18,13,1],
[104,18,13,2],
[104,18,13,3],
[104,18,13,4],
[104,18,13,5],
[104,18,13,6],
[104,18,13,7],
[104,18,13,8],
[104,18,13,10],
[104,18,13,11],
[104,18,13,12],
[104,18,14,0],
[104,18,14,1],
[104,18,14,2],
[104,18,14,3],
[104,18,14,4],
[104,18,14,5],
[104,18,14,6],
[104,18,14,7],
[104,18,14,8],
[104,18,14,10],
[104,18,14,11],
[104,18,14,12],
[104,18,16,0],
[104,18,16,1],
[104,18,16,2],
[104,18,16,3],
[104,18,16,4],
[104,18,16,5],
[104,18,16,6],
[104,18,16,7],
[104,18,16,8],
[104,18,16,10],
[104,18,16,11],
[104,18,16,12],
[104,18,16,13],
[104,18,16,14],
[104,19,3,0],
[104,19,3,1],
[104,19,3,2],
[104,19,4,0],
[104,19,4,1],
[104,19,4,2],
[104,19,4,3],
[104,19,5,0],
[104,19,5,1],
[104,19,5,2],
[104,19,6,4],
[104,19,7,3],
[104,19,7,4],
[104,19,7,5],
[104,19,7,6],
[104,19,8,0],
[104,19,8,1],
[104,19,8,2],
[104,19,8,4],
[104,19,8,7],
[104,19,9,0],
[104,19,9,1],
[104,19,9,2],
[104,19,9,3],
[104,19,9,4],
[104,19,9,5],
[104,19,9,6],
[104,19,9,7],
[104,19,9,8],
[104,19,10,0],
[104,19,10,1],
[104,19,10,2],
[104,19,10,3],
[104,19,10,4],
[104,19,10,5],
[104,19,10,6],
[104,19,10,7],
[104,19,10,8],
[104,19,10,9],
[104,19,11,0],
[104,19,11,1],
[104,19,11,2],
[104,19,11,3],
[104,19,11,4],
[104,19,11,5],
[104,19,11,6],
[104,19,11,7],
[104,19,11,8],
[104,19,11,9],
[104,19,11,10],
[104,19,12,0],
[104,19,12,1],
[104,19,12,2],
[104,19,12,3],
[104,19,12,4],
[104,19,12,5],
[104,19,12,6],
[104,19,12,7],
[104,19,12,8],
[104,19,12,9],
[104,19,12,10],
[104,19,15,0],
[104,19,15,1],
[104,19,15,2],
[104,19,15,3],
[104,19,15,4],
[104,19,15,5],
[104,19,15,6],
[104,19,15,7],
[104,19,15,8],
[104,19,15,10],
[104,19,15,11],
[104,19,15,12],
[104,19,16,0],
[104,19,16,1],
[104,19,16,2],
[104,19,16,3],
[104,19,16,4],
[104,19,16,5],
[104,19,16,6],
[104,19,16,7],
[104,19,16,8],
[104,19,16,9],
[104,19,16,10],
[104,19,16,11],
[104,19,16,12],
[104,19,18,0],
[104,19,18,1],
[104,19,18,2],
[104,19,18,3],
[104,19,18,4],
[104,19,18,5],
[104,19,18,6],
[104,19,18,7],
[104,19,18,8],
[104,19,18,10],
[104,19,18,11],
[104,20,3,0],
[104,20,3,1],
[104,20,3,2],
[104,20,4,0],
[104,20,4,1],
[104,20,4,2],
[104,20,5,4],
[104,20,6,0],
[104,20,6,1],
[104,20,6,2],
[104,20,6,4],
[104,20,7,0],
[104,20,7,1],
[104,20,7,2],
[104,20,7,3],
[104,20,7,4],
[104,20,7,5],
[104,20,7,6],
[104,20,8,0],
[104,20,8,1],
[104,20,8,2],
[104,20,8,4],
[104,20,8,7],
[104,20,9,0],
[104,20,9,1],
[104,20,9,2],
[104,20,9,3],
[104,20,9,4],
[104,20,9,5],
[104,20,9,6],
[104,20,9,7],
[104,20,9,8],
[104,20,11,0],
[104,20,11,1],
[104,20,11,2],
[104,20,11,3],
[104,20,11,4],
[104,20,11,5],
[104,20,11,6],
[104,20,11,7],
[104,20,11,8],
[104,20,11,9],
[104,20,12,0],
[104,20,12,1],
[104,20,12,2],
[104,20,12,3],
[104,20,12,4],
[104,20,12,5],
[104,20,12,6],
[104,20,12,7],
[104,20,12,8],
[104,20,12,9],
[104,20,13,0],
[104,20,13,1],
[104,20,13,2],
[104,20,13,3],
[104,20,13,4],
[104,20,13,5],
[104,20,13,6],
[104,20,13,7],
[104,20,13,8],
[104,20,13,9],
[104,20,13,11],
[104,20,13,12],
[104,20,14,0],
[104,20,14,1],
[104,20,14,2],
[104,20,14,3],
[104,20,14,4],
[104,20,14,5],
[104,20,14,6],
[104,20,14,7],
[104,20,14,8],
[104,20,14,9],
[104,20,14,11],
[104,20,14,12],
[104,20,15,0],
[104,20,15,1],
[104,20,15,2],
[104,20,15,3],
[104,20,15,4],
[104,20,15,5],
[104,20,15,6],
[104,20,15,7],
[104,20,15,8],
[104,20,15,11],
[104,20,15,12],
[104,20,15,13],
[104,20,16,0],
[104,20,16,1],
[104,20,16,2],
[104,20,16,3],
[104,20,16,4],
[104,20,16,5],
[104,20,16,6],
[104,20,16,7],
[104,20,16,8],
[104,20,16,9],
[104,20,16,11],
[104,20,18,0],
[104,20,18,1],
[104,20,18,2],
[104,20,18,3],
[104,20,18,4],
[104,20,18,5],
[104,20,18,6],
[104,20,18,7],
[104,20,18,8],
[104,20,19,0],
[104,20,19,1],
[104,20,19,2],
[104,20,19,3],
[104,20,19,4],
[104,20,19,5],
[104,20,19,6],
[104,20,19,7],
[104,21,7,0],
[104,21,7,1],
[104,21,7,2],
[104,21,7,4],
[104,21,9,0],
[104,21,9,1],
[104,21,9,2],
[104,21,9,4],
[104,21,9,7],
[104,21,10,0],
[104,21,10,1],
[104,21,10,2],
[104,21,10,4],
[104,21,10,7],
[104,21,10,9],
[104,21,11,0],
[104,21,11,1],
[104,21,11,2],
[104,21,11,4],
[104,21,11,7],
[104,21,11,9],
[104,21,11,10],
[104,21,12,0],
[104,21,12,1],
[104,21,12,2],
[104,21,12,4],
[104,21,12,7],
[104,21,12,9],
[104,21,12,10],
[104,21,13,0],
[104,21,13,1],
[104,21,13,2],
[104,21,13,4],
[104,21,13,7],
[104,21,13,9],
[104,21,13,10],
[104,21,13,11],
[104,21,13,12],
[104,21,14,0],
[104,21,14,1],
[104,21,14,2],
[104,21,14,4],
[104,21,14,7],
[104,21,14,9],
[104,21,14,10],
[104,21,14,11],
[104,21,14,12],
[104,21,15,0],
[104,21,15,1],
[104,21,15,2],
[104,21,15,4],
[104,21,15,7],
[104,21,15,10],
[104,21,15,11],
[104,21,16,0],
[104,21,16,1],
[104,21,16,2],
[104,21,16,4],
[104,21,16,7],
[104,21,16,9],
[104,21,18,0],
[104,21,18,1],
[104,21,18,2],
[104,21,18,4],
[104,21,18,7],
[104,21,19,0],
[104,21,19,1],
[104,21,19,2],
[104,21,19,4],
[104,21,20,0],
[104,21,20,1],
[104,21,20,2],
[104,21,20,4],
[104,22,3,0],
[104,22,3,1],
[104,22,3,2],
[104,22,4,0],
[104,22,4,1],
[104,22,4,2],
[104,22,4,3],
[104,22,5,0],
[104,22,5,1],
[104,22,5,2],
[104,22,5,4],
[104,22,6,0],
[104,22,6,1],
[104,22,6,2],
[104,22,6,4],
[104,22,7,0],
[104,22,7,1],
[104,22,7,2],
[104,22,7,3],
[104,22,7,4],
[104,22,7,5],
[104,22,7,6],
[104,22,8,0],
[104,22,8,1],
[104,22,8,2],
[104,22,8,4],
[104,22,8,7],
[104,22,9,0],
[104,22,9,1],
[104,22,9,2],
[104,22,9,3],
[104,22,9,4],
[104,22,9,5],
[104,22,9,6],
[104,22,9,7],
[104,22,9,8],
[104,22,11,0],
[104,22,11,1],
[104,22,11,2],
[104,22,11,3],
[104,22,11,4],
[104,22,11,5],
[104,22,11,6],
[104,22,11,7],
[104,22,11,8],
[104,22,11,9],
[104,22,12,0],
[104,22,12,1],
[104,22,12,2],
[104,22,12,3],
[104,22,12,4],
[104,22,12,5],
[104,22,12,6],
[104,22,12,7],
[104,22,12,8],
[104,22,12,9],
[104,22,13,0],
[104,22,13,1],
[104,22,13,2],
[104,22,13,3],
[104,22,13,4],
[104,22,13,5],
[104,22,13,6],
[104,22,13,7],
[104,22,13,8],
[104,22,13,9],
[104,22,13,11],
[104,22,13,12],
[104,22,14,0],
[104,22,14,1],
[104,22,14,2],
[104,22,14,3],
[104,22,14,4],
[104,22,14,5],
[104,22,14,6],
[104,22,14,7],
[104,22,14,8],
[104,22,14,9],
[104,22,14,11],
[104,22,15,0],
[104,22,15,1],
[104,22,15,2],
[104,22,15,3],
[104,22,15,4],
[104,22,15,5],
[104,22,15,6],
[104,22,15,7],
[104,22,15,8],
[104,22,16,0],
[104,22,16,1],
[104,22,16,2],
[104,22,16,3],
[104,22,16,4],
[104,22,16,5],
[104,22,16,6],
[104,22,16,7],
[104,22,18,0],
[104,22,18,1],
[104,22,18,2],
[104,22,18,3],
[104,22,18,4],
[104,22,18,5],
[104,22,19,0],
[104,22,19,1],
[104,22,19,2],
[104,22,19,3],
[104,22,19,4],
[104,23,3,0],
[104,23,3,1],
[104,23,3,2],
[104,23,4,0],
[104,23,4,1],
[104,23,4,2],
[104,23,4,3],
[104,23,5,0],
[104,23,5,1],
[104,23,5,2],
[104,23,5,4],
[104,23,6,0],
[104,23,6,1],
[104,23,6,2],
[104,23,6,4],
[104,23,7,0],
[104,23,7,1],
[104,23,7,2],
[104,23,7,3],
[104,23,7,4],
[104,23,7,5],
[104,23,7,6],
[104,23,8,0],
[104,23,8,1],
[104,23,8,2],
[104,23,8,4],
[104,23,8,7],
[104,23,9,0],
[104,23,9,1],
[104,23,9,2],
[104,23,9,3],
[104,23,9,4],
[104,23,9,5],
[104,23,9,6],
[104,23,9,7],
[104,23,9,8],
[104,23,10,0],
[104,23,10,1],
[104,23,10,2],
[104,23,10,3],
[104,23,10,4],
[104,23,10,5],
[104,23,10,6],
[104,23,10,7],
[104,23,10,8],
[104,23,10,9],
[104,23,11,0],
[104,23,11,1],
[104,23,11,2],
[104,23,11,3],
[104,23,11,4],
[104,23,11,5],
[104,23,11,6],
[104,23,11,7],
[104,23,11,8],
[104,23,11,9],
[104,23,11,10],
[104,23,12,0],
[104,23,12,1],
[104,23,12,2],
[104,23,12,3],
[104,23,12,4],
[104,23,12,5],
[104,23,12,6],
[104,23,12,7],
[104,23,12,8],
[104,23,12,9],
[104,23,12,10],
[104,23,13,0],
[104,23,13,1],
[104,23,13,2],
[104,23,13,3],
[104,23,13,4],
[104,23,13,5],
[104,23,13,6],
[104,23,13,7],
[104,23,13,8],
[104,23,13,9],
[104,23,13,10],
[104,23,13,11],
[104,23,14,0],
[104,23,14,1],
[104,23,14,2],
[104,23,14,3],
[104,23,14,4],
[104,23,14,5],
[104,23,14,6],
[104,23,14,7],
[104,23,14,8],
[104,23,14,9],
[104,23,15,0],
[104,23,15,1],
[104,23,15,2],
[104,23,15,3],
[104,23,15,4],
[104,23,15,5],
[104,23,15,6],
[104,23,15,7],
[104,23,16,0],
[104,23,16,1],
[104,23,16,2],
[104,23,16,3],
[104,23,16,4],
[104,23,16,5],
[104,23,18,0],
[104,23,18,1],
[104,23,18,2],
[104,23,18,3],
[104,23,18,4],
[104,23,19,0],
[104,23,19,1],
[104,23,19,2],
[104,24,3,0],
[104,24,3,1],
[104,24,3,2],
[104,24,4,0],
[104,24,4,1],
[104,24,4,2],
[104,24,4,3],
[104,24,5,0],
[104,24,5,1],
[104,24,5,2],
[104,24,5,4],
[104,24,6,0],
[104,24,6,1],
[104,24,6,2],
[104,24,6,4],
[104,24,7,0],
[104,24,7,1],
[104,24,7,2],
[104,24,7,3],
[104,24,7,4],
[104,24,7,5],
[104,24,7,6],
[104,24,8,0],
[104,24,8,1],
[104,24,8,2],
[104,24,8,4],
[104,24,8,7],
[104,24,9,0],
[104,24,9,1],
[104,24,9,2],
[104,24,9,3],
[104,24,9,4],
[104,24,9,5],
[104,24,9,6],
[104,24,9,7],
[104,24,9,8],
[104,24,10,0],
[104,24,10,1],
[104,24,10,2],
[104,24,10,3],
[104,24,10,4],
[104,24,10,5],
[104,24,10,6],
[104,24,10,7],
[104,24,10,8],
[104,24,10,9],
[104,24,11,0],
[104,24,11,1],
[104,24,11,2],
[104,24,11,3],
[104,24,11,4],
[104,24,11,5],
[104,24,11,6],
[104,24,11,7],
[104,24,11,8],
[104,24,11,9],
[104,24,11,10],
[104,24,12,0],
[104,24,12,1],
[104,24,12,2],
[104,24,12,3],
[104,24,12,4],
[104,24,12,5],
[104,24,12,6],
[104,24,12,7],
[104,24,12,8],
[104,24,12,9],
[104,24,12,10],
[104,24,13,0],
[104,24,13,1],
[104,24,13,2],
[104,24,13,3],
[104,24,13,4],
[104,24,13,5],
[104,24,13,6],
[104,24,13,7],
[104,24,13,8],
[104,24,13,9],
[104,24,13,10],
[104,24,13,11],
[104,24,14,0],
[104,24,14,1],
[104,24,14,2],
[104,24,14,3],
[104,24,14,4],
[104,24,14,5],
[104,24,14,6],
[104,24,14,7],
[104,24,14,8],
[104,24,14,9],
[104,24,15,0],
[104,24,15,1],
[104,24,15,2],
[104,24,15,3],
[104,24,15,4],
[104,24,15,5],
[104,24,15,6],
[104,24,15,7],
[104,24,18,0],
[104,24,18,1],
[104,24,18,2],
[104,24,18,3],
[104,24,18,4],
[104,24,19,0],
[104,24,19,1],
[104,24,19,2],
[104,25,3,0],
[104,25,3,1],
[104,25,3,2],
[104,25,4,0],
[104,25,4,1],
[104,25,4,2],
[104,25,4,3],
[104,25,5,0],
[104,25,5,1],
[104,25,5,2],
[104,25,5,4],
[104,25,6,0],
[104,25,6,1],
[104,25,6,2],
[104,25,6,4],
[104,25,8,0],
[104,25,8,1],
[104,25,8,2],
[104,25,8,4],
[104,25,9,0],
[104,25,9,1],
[104,25,9,2],
[104,25,9,3],
[104,25,9,4],
[104,25,9,5],
[104,25,9,6],
[104,25,9,8],
[104,25,10,0],
[104,25,10,1],
[104,25,10,2],
[104,25,10,3],
[104,25,10,4],
[104,25,10,5],
[104,25,10,6],
[104,25,10,8],
[104,25,10,9],
[104,25,11,0],
[104,25,11,1],
[104,25,11,2],
[104,25,11,3],
[104,25,11,4],
[104,25,11,5],
[104,25,11,6],
[104,25,11,8],
[104,25,11,9],
[104,25,12,0],
[104,25,12,1],
[104,25,12,2],
[104,25,12,3],
[104,25,12,4],
[104,25,12,5],
[104,25,12,6],
[104,25,13,0],
[104,25,13,1],
[104,25,13,2],
[104,25,13,3],
[104,25,13,4],
[104,25,13,5],
[104,25,13,6],
[104,25,14,0],
[104,25,14,1],
[104,25,14,2],
[104,25,14,3],
[104,25,14,4],
[104,25,14,5],
[104,25,15,0],
[104,25,15,1],
[104,25,15,2],
[104,25,15,3],
[104,25,15,4],
[104,25,16,0],
[104,25,16,1],
[104,25,16,2],
[104,26,3,0],
[104,26,3,1],
[104,26,3,2],
[104,26,4,0],
[104,26,4,1],
[104,26,4,2],
[104,26,4,3],
[104,26,5,0],
[104,26,5,1],
[104,26,5,2],
[104,26,5,4],
[104,26,6,0],
[104,26,6,1],
[104,26,6,2],
[104,26,6,4],
[104,26,7,0],
[104,26,7,1],
[104,26,7,2],
[104,26,7,3],
[104,26,7,4],
[104,26,7,5],
[104,26,7,6],
[104,26,8,0],
[104,26,8,1],
[104,26,8,2],
[104,26,8,4],
[104,26,8,7],
[104,26,10,0],
[104,26,10,1],
[104,26,10,2],
[104,26,10,3],
[104,26,10,4],
[104,26,10,5],
[104,26,10,6],
[104,26,10,7],
[104,26,11,0],
[104,26,11,1],
[104,26,11,2],
[104,26,11,3],
[104,26,11,4],
[104,26,11,5],
[104,26,11,6],
[104,26,11,7],
[104,26,12,0],
[104,26,12,1],
[104,26,12,2],
[104,26,12,3],
[104,26,12,4],
[104,26,12,5],
[104,26,13,0],
[104,26,13,1],
[104,26,13,2],
[104,26,13,3],
[104,26,13,4],
[104,26,13,5],
[104,26,14,0],
[104,26,14,1],
[104,26,14,2],
[104,26,14,3],
[104,26,14,4],
[104,27,3,0],
[104,27,3,1],
[104,27,3,2],
[104,27,4,0],
[104,27,4,1],
[104,27,4,2],
[104,27,4,3],
[104,27,5,0],
[104,27,5,1],
[104,27,5,2],
[104,27,5,4],
[104,27,6,0],
[104,27,6,1],
[104,27,6,2],
[104,27,6,4],
[104,27,7,0],
[104,27,7,1],
[104,27,7,2],
[104,27,7,3],
[104,27,7,4],
[104,27,7,5],
[104,27,7,6],
[104,27,8,0],
[104,27,8,1],
[104,27,8,2],
[104,27,8,4],
[104,27,8,7],
[104,27,9,0],
[104,27,9,1],
[104,27,9,2],
[104,27,9,3],
[104,27,9,4],
[104,27,9,5],
[104,27,9,6],
[104,27,9,7],
[104,27,11,0],
[104,27,11,1],
[104,27,11,2],
[104,27,11,3],
[104,27,11,4],
[104,27,11,5],
[104,27,12,0],
[104,27,12,1],
[104,27,12,2],
[104,27,12,3],
[104,27,12,4],
[104,27,13,0],
[104,27,13,1],
[104,27,13,2],
[104,27,13,3],
[104,27,13,4],
[104,27,14,0],
[104,27,14,1],
[104,27,14,2],
[104,28,3,0],
[104,28,3,1],
[104,28,3,2],
[104,28,4,0],
[104,28,4,1],
[104,28,4,2],
[104,28,4,3],
[104,28,5,0],
[104,28,5,1],
[104,28,5,2],
[104,28,5,4],
[104,28,6,0],
[104,28,6,1],
[104,28,6,2],
[104,28,6,4],
[104,28,8,0],
[104,28,8,1],
[104,28,8,2],
[104,28,8,4],
[104,28,9,0],
[104,28,9,1],
[104,28,9,2],
[104,28,9,3],
[104,28,9,4],
[104,28,9,5],
[104,28,10,0],
[104,28,10,1],
[104,28,10,2],
[104,28,10,3],
[104,28,10,4],
[104,28,11,0],
[104,28,11,1],
[104,28,11,2],
[104,28,11,3],
[104,28,11,4],
[104,28,12,0],
[104,28,12,1],
[104,28,12,2],
[104,28,13,0],
[104,28,13,1],
[104,28,13,2],
[104,29,3,0],
[104,29,3,1],
[104,29,3,2],
[104,29,4,0],
[104,29,4,1],
[104,29,4,2],
[104,29,4,3],
[104,29,5,0],
[104,29,5,1],
[104,29,5,2],
[104,29,5,4],
[104,29,6,0],
[104,29,6,1],
[104,29,6,2],
[104,29,6,4],
[104,29,7,0],
[104,29,7,1],
[104,29,7,2],
[104,29,7,3],
[104,29,7,4],
[104,29,7,5],
[104,29,8,0],
[104,29,8,1],
[104,29,8,2],
[104,29,8,4],
[104,29,9,0],
[104,29,9,1],
[104,29,9,2],
[104,29,9,3],
[104,29,9,4],
[104,29,10,0],
[104,29,10,1],
[104,29,10,2],
[104,30,3,0],
[104,30,3,1],
[104,30,3,2],
[104,30,4,0],
[104,30,4,1],
[104,30,4,2],
[104,30,4,3],
[104,30,5,0],
[104,30,5,1],
[104,30,5,2],
[104,30,5,4],
[104,30,6,0],
[104,30,6,1],
[104,30,6,2],
[104,30,6,4],
[104,30,7,0],
[104,30,7,1],
[104,30,7,2],
[104,30,7,3],
[104,30,7,4],
[104,30,8,0],
[104,30,8,1],
[104,30,8,2],
[104,30,9,0],
[104,30,9,1],
[104,30,9,2],
[104,31,3,0],
[104,31,3,1],
[104,31,3,2],
[104,31,4,0],
[104,31,4,1],
[104,31,4,2],
[104,31,4,3],
[104,31,5,0],
[104,31,5,1],
[104,31,5,2],
[104,31,5,4],
[104,31,6,0],
[104,31,6,1],
[104,31,6,2],
[104,31,7,0],
[104,31,7,1],
[104,31,7,2],
[104,32,3,0],
[104,32,3,1],
[104,32,3,2],
[104,32,4,0],
[104,32,4,1],
[104,32,4,2],
[104,32,4,3],
[104,32,5,0],
[104,32,5,1],
[104,32,5,2],
[104,33,3,0],
[104,33,3,1],
[104,33,3,2],
[104,33,4,0],
[104,33,4,1],
[104,33,4,2],
[105,4,3,0],
[105,4,3,1],
[105,4,3,2],
[105,5,4,0],
[105,5,4,1],
[105,5,4,2],
[105,6,4,0],
[105,6,4,1],
[105,6,4,2],
[105,7,3,0],
[105,7,3,1],
[105,7,3,2],
[105,7,4,0],
[105,7,4,1],
[105,7,4,2],
[105,7,4,3],
[105,7,5,0],
[105,7,5,1],
[105,7,5,2],
[105,7,5,4],
[105,7,6,0],
[105,7,6,1],
[105,7,6,2],
[105,7,6,4],
[105,8,4,0],
[105,8,4,1],
[105,8,4,2],
[105,8,7,0],
[105,8,7,1],
[105,8,7,2],
[105,8,7,4],
[105,10,3,0],
[105,10,3,1],
[105,10,3,2],
[105,10,4,0],
[105,10,4,1],
[105,10,4,2],
[105,10,4,3],
[105,10,5,0],
[105,10,5,1],
[105,10,5,2],
[105,10,5,4],
[105,10,6,0],
[105,10,6,1],
[105,10,6,2],
[105,10,6,4],
[105,10,7,0],
[105,10,7,1],
[105,10,7,2],
[105,10,7,3],
[105,10,7,4],
[105,10,7,5],
[105,10,7,6],
[105,10,8,0],
[105,10,8,1],
[105,10,8,2],
[105,10,8,4],
[105,10,8,7],
[105,11,3,0],
[105,11,3,1],
[105,11,3,2],
[105,11,4,0],
[105,11,4,1],
[105,11,4,2],
[105,11,4,3],
[105,11,5,0],
[105,11,5,1],
[105,11,5,2],
[105,11,5,4],
[105,11,6,0],
[105,11,6,1],
[105,11,6,2],
[105,11,6,4],
[105,11,7,0],
[105,11,7,1],
[105,11,7,2],
[105,11,7,3],
[105,11,7,4],
[105,11,7,5],
[105,11,7,6],
[105,11,8,0],
[105,11,8,1],
[105,11,8,2],
[105,11,8,4],
[105,11,8,7],
[105,11,10,0],
[105,11,10,1],
[105,11,10,2],
[105,11,10,3],
[105,11,10,4],
[105,11,10,5],
[105,11,10,8],
[105,12,3,0],
[105,12,3,1],
[105,12,3,2],
[105,12,4,0],
[105,12,4,1],
[105,12,4,2],
[105,12,4,3],
[105,12,5,0],
[105,12,5,1],
[105,12,5,2],
[105,12,5,4],
[105,12,6,0],
[105,12,6,1],
[105,12,6,2],
[105,12,6,4],
[105,12,7,0],
[105,12,7,1],
[105,12,7,2],
[105,12,7,3],
[105,12,7,4],
[105,12,7,5],
[105,12,7,6],
[105,12,8,0],
[105,12,8,1],
[105,12,8,2],
[105,12,8,4],
[105,12,10,0],
[105,12,10,1],
[105,12,10,2],
[105,12,10,3],
[105,12,10,4],
[105,12,10,6],
[105,12,10,7],
[105,12,10,8],
[105,13,3,0],
[105,13,3,1],
[105,13,3,2],
[105,13,4,0],
[105,13,4,1],
[105,13,4,2],
[105,13,4,3],
[105,13,5,0],
[105,13,5,1],
[105,13,5,2],
[105,13,5,4],
[105,13,6,0],
[105,13,6,1],
[105,13,6,2],
[105,13,6,4],
[105,13,7,0],
[105,13,7,1],
[105,13,7,2],
[105,13,7,3],
[105,13,7,4],
[105,13,7,5],
[105,13,7,6],
[105,13,8,0],
[105,13,8,1],
[105,13,8,2],
[105,13,8,4],
[105,13,10,0],
[105,13,10,1],
[105,13,10,2],
[105,13,10,3],
[105,13,10,4],
[105,13,10,6],
[105,13,10,7],
[105,13,10,8],
[105,13,11,0],
[105,13,11,1],
[105,13,11,2],
[105,13,11,3],
[105,13,11,4],
[105,13,11,6],
[105,13,11,7],
[105,13,11,8],
[105,13,11,10],
[105,13,12,0],
[105,13,12,1],
[105,13,12,2],
[105,13,12,5],
[105,13,12,6],
[105,13,12,7],
[105,13,12,8],
[105,13,12,10],
[105,14,3,0],
[105,14,3,1],
[105,14,3,2],
[105,14,4,0],
[105,14,4,1],
[105,14,4,2],
[105,14,4,3],
[105,14,5,0],
[105,14,5,1],
[105,14,5,2],
[105,14,5,4],
[105,14,6,0],
[105,14,6,1],
[105,14,6,2],
[105,14,6,4],
[105,14,7,0],
[105,14,7,1],
[105,14,7,2],
[105,14,7,3],
[105,14,7,4],
[105,14,7,5],
[105,14,8,0],
[105,14,8,1],
[105,14,8,2],
[105,14,8,4],
[105,14,8,7],
[105,14,10,0],
[105,14,10,1],
[105,14,10,2],
[105,14,10,5],
[105,14,10,6],
[105,14,10,7],
[105,14,10,8],
[105,14,11,0],
[105,14,11,1],
[105,14,11,2],
[105,14,11,5],
[105,14,11,6],
[105,14,11,7],
[105,14,11,8],
[105,14,11,10],
[105,14,12,3],
[105,14,12,4],
[105,14,12,5],
[105,14,12,6],
[105,14,12,7],
[105,14,12,8],
[105,14,12,10],
[105,16,3,0],
[105,16,3,1],
[105,16,3,2],
[105,16,4,0],
[105,16,4,1],
[105,16,4,2],
[105,16,4,3],
[105,16,5,0],
[105,16,5,1],
[105,16,5,2],
[105,16,5,4],
[105,16,6,0],
[105,16,6,1],
[105,16,6,2],
[105,16,7,0],
[105,16,7,1],
[105,16,7,2],
[105,16,7,5],
[105,16,7,6],
[105,16,8,4],
[105,16,8,7],
[105,16,10,0],
[105,16,10,1],
[105,16,10,2],
[105,16,10,3],
[105,16,10,4],
[105,16,10,5],
[105,16,10,6],
[105,16,10,7],
[105,16,10,8],
[105,16,11,0],
[105,16,11,1],
[105,16,11,2],
[105,16,11,3],
[105,16,11,4],
[105,16,11,5],
[105,16,11,6],
[105,16,11,7],
[105,16,11,8],
[105,16,11,10],
[105,16,12,0],
[105,16,12,1],
[105,16,12,2],
[105,16,12,3],
[105,16,12,4],
[105,16,12,5],
[105,16,12,6],
[105,16,12,7],
[105,16,12,8],
[105,16,12,10],
[105,16,13,0],
[105,16,13,1],
[105,16,13,2],
[105,16,13,3],
[105,16,13,4],
[105,16,13,5],
[105,16,13,6],
[105,16,13,7],
[105,16,13,8],
[105,16,13,10],
[105,16,13,11],
[105,16,13,12],
[105,16,14,0],
[105,16,14,1],
[105,16,14,2],
[105,16,14,3],
[105,16,14,4],
[105,16,14,5],
[105,16,14,6],
[105,16,14,7],
[105,16,14,8],
[105,16,14,10],
[105,16,14,11],
[105,16,14,12],
[105,17,3,0],
[105,17,3,1],
[105,17,3,2],
[105,17,4,0],
[105,17,4,1],
[105,17,4,2],
[105,17,4,3],
[105,17,5,0],
[105,17,5,1],
[105,17,5,2],
[105,17,5,4],
[105,17,6,0],
[105,17,6,1],
[105,17,6,2],
[105,17,7,0],
[105,17,7,1],
[105,17,7,2],
[105,17,7,5],
[105,17,7,6],
[105,17,8,4],
[105,17,8,7],
[105,17,10,0],
[105,17,10,1],
[105,17,10,2],
[105,17,10,3],
[105,17,10,4],
[105,17,10,5],
[105,17,10,6],
[105,17,10,7],
[105,17,10,8],
[105,17,11,0],
[105,17,11,1],
[105,17,11,2],
[105,17,11,3],
[105,17,11,4],
[105,17,11,5],
[105,17,11,6],
[105,17,11,7],
[105,17,11,8],
[105,17,11,10],
[105,17,12,0],
[105,17,12,1],
[105,17,12,2],
[105,17,12,3],
[105,17,12,4],
[105,17,12,5],
[105,17,12,6],
[105,17,12,7],
[105,17,12,8],
[105,17,12,10],
[105,17,13,0],
[105,17,13,1],
[105,17,13,2],
[105,17,13,3],
[105,17,13,4],
[105,17,13,5],
[105,17,13,6],
[105,17,13,7],
[105,17,13,8],
[105,17,13,10],
[105,17,13,11],
[105,17,13,12],
[105,17,14,0],
[105,17,14,1],
[105,17,14,2],
[105,17,14,3],
[105,17,14,4],
[105,17,14,5],
[105,17,14,6],
[105,17,14,7],
[105,17,14,8],
[105,17,14,10],
[105,17,14,11],
[105,17,14,12],
[105,17,16,0],
[105,17,16,1],
[105,17,16,2],
[105,17,16,3],
[105,17,16,4],
[105,17,16,5],
[105,17,16,6],
[105,17,16,7],
[105,17,16,8],
[105,17,16,10],
[105,17,16,11],
[105,17,16,12],
[105,17,16,13],
[105,17,16,14],
[105,19,3,0],
[105,19,3,1],
[105,19,3,2],
[105,19,4,0],
[105,19,4,1],
[105,19,4,2],
[105,19,5,4],
[105,19,6,0],
[105,19,6,1],
[105,19,6,2],
[105,19,6,4],
[105,19,7,0],
[105,19,7,1],
[105,19,7,2],
[105,19,7,3],
[105,19,7,4],
[105,19,7,5],
[105,19,7,6],
[105,19,8,0],
[105,19,8,1],
[105,19,8,2],
[105,19,8,4],
[105,19,8,7],
[105,19,10,0],
[105,19,10,1],
[105,19,10,2],
[105,19,10,3],
[105,19,10,4],
[105,19,10,5],
[105,19,10,6],
[105,19,10,7],
[105,19,10,8],
[105,19,11,0],
[105,19,11,1],
[105,19,11,2],
[105,19,11,3],
[105,19,11,4],
[105,19,11,5],
[105,19,11,6],
[105,19,11,7],
[105,19,11,8],
[105,19,11,10],
[105,19,12,0],
[105,19,12,1],
[105,19,12,2],
[105,19,12,3],
[105,19,12,4],
[105,19,12,5],
[105,19,12,6],
[105,19,12,7],
[105,19,12,8],
[105,19,12,10],
[105,19,16,0],
[105,19,16,1],
[105,19,16,2],
[105,19,16,3],
[105,19,16,4],
[105,19,16,5],
[105,19,16,6],
[105,19,16,7],
[105,19,16,8],
[105,19,16,10],
[105,19,16,11],
[105,19,17,0],
[105,19,17,1],
[105,19,17,2],
[105,19,17,3],
[105,19,17,4],
[105,19,17,5],
[105,19,17,6],
[105,19,17,7],
[105,19,17,8],
[105,19,17,10],
[105,19,17,11],
[105,20,4,3],
[105,20,5,0],
[105,20,5,1],
[105,20,5,2],
[105,20,5,4],
[105,20,6,0],
[105,20,6,1],
[105,20,6,2],
[105,20,6,4],
[105,20,7,0],
[105,20,7,1],
[105,20,7,2],
[105,20,7,3],
[105,20,7,4],
[105,20,7,5],
[105,20,7,6],
[105,20,8,0],
[105,20,8,1],
[105,20,8,2],
[105,20,8,4],
[105,20,8,7],
[105,20,11,0],
[105,20,11,1],
[105,20,11,2],
[105,20,11,3],
[105,20,11,4],
[105,20,11,5],
[105,20,11,6],
[105,20,11,7],
[105,20,11,8],
[105,20,12,0],
[105,20,12,1],
[105,20,12,2],
[105,20,12,3],
[105,20,12,4],
[105,20,12,5],
[105,20,12,6],
[105,20,12,7],
[105,20,12,8],
[105,20,13,0],
[105,20,13,1],
[105,20,13,2],
[105,20,13,3],
[105,20,13,4],
[105,20,13,5],
[105,20,13,6],
[105,20,13,7],
[105,20,13,8],
[105,20,13,11],
[105,20,13,12],
[105,20,14,0],
[105,20,14,1],
[105,20,14,2],
[105,20,14,3],
[105,20,14,4],
[105,20,14,5],
[105,20,14,6],
[105,20,14,7],
[105,20,14,8],
[105,20,14,11],
[105,20,14,12],
[105,20,16,0],
[105,20,16,1],
[105,20,16,2],
[105,20,16,3],
[105,20,16,4],
[105,20,16,5],
[105,20,16,6],
[105,20,16,7],
[105,20,16,8],
[105,20,17,0],
[105,20,17,1],
[105,20,17,2],
[105,20,17,3],
[105,20,17,4],
[105,20,17,5],
[105,20,17,6],
[105,20,17,7],
[105,20,17,8],
[105,20,19,0],
[105,20,19,1],
[105,20,19,2],
[105,20,19,3],
[105,20,19,4],
[105,20,19,5],
[105,21,4,0],
[105,21,4,1],
[105,21,4,2],
[105,21,7,0],
[105,21,7,1],
[105,21,7,2],
[105,21,7,4],
[105,21,10,0],
[105,21,10,1],
[105,21,10,2],
[105,21,10,4],
[105,21,10,7],
[105,21,11,0],
[105,21,11,1],
[105,21,11,2],
[105,21,11,4],
[105,21,11,7],
[105,21,11,10],
[105,21,12,0],
[105,21,12,1],
[105,21,12,2],
[105,21,12,4],
[105,21,12,7],
[105,21,12,10],
[105,21,13,0],
[105,21,13,1],
[105,21,13,2],
[105,21,13,4],
[105,21,13,7],
[105,21,13,10],
[105,21,13,11],
[105,21,13,12],
[105,21,14,0],
[105,21,14,1],
[105,21,14,2],
[105,21,14,4],
[105,21,14,7],
[105,21,14,10],
[105,21,14,11],
[105,21,16,0],
[105,21,16,1],
[105,21,16,2],
[105,21,16,4],
[105,21,16,7],
[105,21,17,0],
[105,21,17,1],
[105,21,17,2],
[105,21,17,4],
[105,21,17,7],
[105,21,19,0],
[105,21,19,1],
[105,21,19,2],
[105,21,19,4],
[105,21,20,0],
[105,21,20,1],
[105,21,20,2],
[105,22,3,0],
[105,22,3,1],
[105,22,3,2],
[105,22,4,0],
[105,22,4,1],
[105,22,4,2],
[105,22,4,3],
[105,22,5,0],
[105,22,5,1],
[105,22,5,2],
[105,22,5,4],
[105,22,6,0],
[105,22,6,1],
[105,22,6,2],
[105,22,6,4],
[105,22,7,0],
[105,22,7,1],
[105,22,7,2],
[105,22,7,3],
[105,22,7,4],
[105,22,7,5],
[105,22,7,6],
[105,22,8,0],
[105,22,8,1],
[105,22,8,2],
[105,22,8,4],
[105,22,8,7],
[105,22,11,0],
[105,22,11,1],
[105,22,11,2],
[105,22,11,3],
[105,22,11,4],
[105,22,11,5],
[105,22,11,6],
[105,22,11,7],
[105,22,11,8],
[105,22,12,0],
[105,22,12,1],
[105,22,12,2],
[105,22,12,3],
[105,22,12,4],
[105,22,12,5],
[105,22,12,6],
[105,22,12,7],
[105,22,12,8],
[105,22,13,0],
[105,22,13,1],
[105,22,13,2],
[105,22,13,3],
[105,22,13,4],
[105,22,13,5],
[105,22,13,6],
[105,22,13,7],
[105,22,13,8],
[105,22,13,11],
[105,22,14,0],
[105,22,14,1],
[105,22,14,2],
[105,22,14,3],
[105,22,14,4],
[105,22,14,5],
[105,22,14,6],
[105,22,14,7],
[105,22,14,8],
[105,22,16,0],
[105,22,16,1],
[105,22,16,2],
[105,22,16,3],
[105,22,16,4],
[105,22,16,5],
[105,22,17,0],
[105,22,17,1],
[105,22,17,2],
[105,22,17,3],
[105,22,17,4],
[105,22,17,5],
[105,22,19,0],
[105,22,19,1],
[105,22,19,2],
[105,23,3,0],
[105,23,3,1],
[105,23,3,2],
[105,23,4,0],
[105,23,4,1],
[105,23,4,2],
[105,23,4,3],
[105,23,5,0],
[105,23,5,1],
[105,23,5,2],
[105,23,5,4],
[105,23,6,0],
[105,23,6,1],
[105,23,6,2],
[105,23,6,4],
[105,23,7,0],
[105,23,7,1],
[105,23,7,2],
[105,23,7,3],
[105,23,7,4],
[105,23,7,5],
[105,23,7,6],
[105,23,8,0],
[105,23,8,1],
[105,23,8,2],
[105,23,8,4],
[105,23,8,7],
[105,23,10,0],
[105,23,10,1],
[105,23,10,2],
[105,23,10,3],
[105,23,10,4],
[105,23,10,5],
[105,23,10,6],
[105,23,10,7],
[105,23,10,8],
[105,23,11,0],
[105,23,11,1],
[105,23,11,2],
[105,23,11,3],
[105,23,11,4],
[105,23,11,5],
[105,23,11,6],
[105,23,11,7],
[105,23,11,8],
[105,23,11,10],
[105,23,12,0],
[105,23,12,1],
[105,23,12,2],
[105,23,12,3],
[105,23,12,4],
[105,23,12,5],
[105,23,12,6],
[105,23,12,7],
[105,23,12,8],
[105,23,13,0],
[105,23,13,1],
[105,23,13,2],
[105,23,13,3],
[105,23,13,4],
[105,23,13,5],
[105,23,13,6],
[105,23,13,7],
[105,23,13,8],
[105,23,14,0],
[105,23,14,1],
[105,23,14,2],
[105,23,14,3],
[105,23,14,4],
[105,23,14,5],
[105,23,14,6],
[105,23,14,7],
[105,23,16,0],
[105,23,16,1],
[105,23,16,2],
[105,23,16,3],
[105,23,16,4],
[105,23,17,0],
[105,23,17,1],
[105,23,17,2],
[105,23,17,3],
[105,23,17,4],
[105,24,3,0],
[105,24,3,1],
[105,24,3,2],
[105,24,4,0],
[105,24,4,1],
[105,24,4,2],
[105,24,4,3],
[105,24,5,0],
[105,24,5,1],
[105,24,5,2],
[105,24,5,4],
[105,24,6,0],
[105,24,6,1],
[105,24,6,2],
[105,24,6,4],
[105,24,7,0],
[105,24,7,1],
[105,24,7,2],
[105,24,7,3],
[105,24,7,4],
[105,24,7,5],
[105,24,7,6],
[105,24,8,0],
[105,24,8,1],
[105,24,8,2],
[105,24,8,4],
[105,24,8,7],
[105,24,10,0],
[105,24,10,1],
[105,24,10,2],
[105,24,10,3],
[105,24,10,4],
[105,24,10,5],
[105,24,10,6],
[105,24,10,7],
[105,24,10,8],
[105,24,11,0],
[105,24,11,1],
[105,24,11,2],
[105,24,11,3],
[105,24,11,4],
[105,24,11,5],
[105,24,11,6],
[105,24,11,7],
[105,24,11,8],
[105,24,11,10],
[105,24,12,0],
[105,24,12,1],
[105,24,12,2],
[105,24,12,3],
[105,24,12,4],
[105,24,12,5],
[105,24,12,6],
[105,24,12,7],
[105,24,12,8],
[105,24,13,0],
[105,24,13,1],
[105,24,13,2],
[105,24,13,3],
[105,24,13,4],
[105,24,13,5],
[105,24,13,6],
[105,24,13,7],
[105,24,13,8],
[105,24,14,0],
[105,24,14,1],
[105,24,14,2],
[105,24,14,3],
[105,24,14,4],
[105,24,14,5],
[105,24,14,6],
[105,24,14,7],
[105,24,17,0],
[105,24,17,1],
[105,24,17,2],
[105,24,17,3],
[105,24,17,4],
[105,25,3,0],
[105,25,3,1],
[105,25,3,2],
[105,25,4,0],
[105,25,4,1],
[105,25,4,2],
[105,25,4,3],
[105,25,5,0],
[105,25,5,1],
[105,25,5,2],
[105,25,5,4],
[105,25,6,0],
[105,25,6,1],
[105,25,6,2],
[105,25,6,4],
[105,25,8,0],
[105,25,8,1],
[105,25,8,2],
[105,25,8,4],
[105,25,10,0],
[105,25,10,1],
[105,25,10,2],
[105,25,10,3],
[105,25,10,4],
[105,25,10,5],
[105,25,10,6],
[105,25,11,0],
[105,25,11,1],
[105,25,11,2],
[105,25,11,3],
[105,25,11,4],
[105,25,11,5],
[105,25,11,6],
[105,25,12,0],
[105,25,12,1],
[105,25,12,2],
[105,25,12,3],
[105,25,12,4],
[105,25,12,5],
[105,25,13,0],
[105,25,13,1],
[105,25,13,2],
[105,25,13,3],
[105,25,13,4],
[105,25,13,5],
[105,25,14,0],
[105,25,14,1],
[105,25,14,2],
[105,25,14,3],
[105,25,14,4],
[105,27,3,0],
[105,27,3,1],
[105,27,3,2],
[105,27,4,0],
[105,27,4,1],
[105,27,4,2],
[105,27,4,3],
[105,27,5,0],
[105,27,5,1],
[105,27,5,2],
[105,27,5,4],
[105,27,6,0],
[105,27,6,1],
[105,27,6,2],
[105,27,6,4],
[105,27,7,0],
[105,27,7,1],
[105,27,7,2],
[105,27,7,3],
[105,27,7,4],
[105,27,7,5],
[105,27,7,6],
[105,27,8,0],
[105,27,8,1],
[105,27,8,2],
[105,27,8,4],
[105,27,11,0],
[105,27,11,1],
[105,27,11,2],
[105,27,11,3],
[105,27,11,4],
[105,27,12,0],
[105,27,12,1],
[105,27,12,2],
[105,27,13,0],
[105,27,13,1],
[105,27,13,2],
[105,28,3,0],
[105,28,3,1],
[105,28,3,2],
[105,28,4,0],
[105,28,4,1],
[105,28,4,2],
[105,28,4,3],
[105,28,5,0],
[105,28,5,1],
[105,28,5,2],
[105,28,5,4],
[105,28,6,0],
[105,28,6,1],
[105,28,6,2],
[105,28,6,4],
[105,28,8,0],
[105,28,8,1],
[105,28,8,2],
[105,28,8,4],
[105,28,10,0],
[105,28,10,1],
[105,28,10,2],
[105,28,11,0],
[105,28,11,1],
[105,28,11,2],
[105,29,3,0],
[105,29,3,1],
[105,29,3,2],
[105,29,4,0],
[105,29,4,1],
[105,29,4,2],
[105,29,4,3],
[105,29,5,0],
[105,29,5,1],
[105,29,5,2],
[105,29,5,4],
[105,29,6,0],
[105,29,6,1],
[105,29,6,2],
[105,29,6,4],
[105,29,7,0],
[105,29,7,1],
[105,29,7,2],
[105,29,7,3],
[105,29,7,4],
[105,29,8,0],
[105,29,8,1],
[105,29,8,2],
[105,30,3,0],
[105,30,3,1],
[105,30,3,2],
[105,30,4,0],
[105,30,4,1],
[105,30,4,2],
[105,30,4,3],
[105,30,5,0],
[105,30,5,1],
[105,30,5,2],
[105,30,5,4],
[105,30,6,0],
[105,30,6,1],
[105,30,6,2],
[105,30,7,0],
[105,30,7,1],
[105,30,7,2],
[105,31,3,0],
[105,31,3,1],
[105,31,3,2],
[105,31,4,0],
[105,31,4,1],
[105,31,4,2],
[105,31,4,3],
[105,31,5,0],
[105,31,5,1],
[105,31,5,2],
[105,32,3,0],
[105,32,3,1],
[105,32,3,2],
[105,32,4,0],
[105,32,4,1],
[105,32,4,2],
[106,7,4,0],
[106,7,4,1],
[106,7,4,2],
[106,9,4,0],
[106,9,4,1],
[106,9,4,2],
[106,9,7,0],
[106,9,7,1],
[106,9,7,2],
[106,9,7,4],
[106,10,4,0],
[106,10,4,1],
[106,10,4,2],
[106,10,7,0],
[106,10,7,1],
[106,10,7,2],
[106,10,7,4],
[106,10,9,0],
[106,10,9,1],
[106,10,9,2],
[106,10,9,4],
[106,11,4,0],
[106,11,4,1],
[106,11,4,2],
[106,11,7,0],
[106,11,7,1],
[106,11,7,2],
[106,11,7,4],
[106,11,9,0],
[106,11,9,1],
[106,11,9,2],
[106,11,9,4],
[106,11,10,0],
[106,11,10,1],
[106,11,10,2],
[106,11,10,4],
[106,11,10,7],
[106,11,10,9],
[106,12,4,0],
[106,12,4,1],
[106,12,4,2],
[106,12,7,0],
[106,12,7,1],
[106,12,7,2],
[106,12,7,4],
[106,12,9,0],
[106,12,9,1],
[106,12,9,2],
[106,12,9,4],
[106,12,9,7],
[106,12,10,0],
[106,12,10,1],
[106,12,10,2],
[106,12,10,7],
[106,12,10,9],
[106,13,4,0],
[106,13,4,1],
[106,13,4,2],
[106,13,7,0],
[106,13,7,1],
[106,13,7,2],
[106,13,7,4],
[106,13,9,0],
[106,13,9,1],
[106,13,9,2],
[106,13,9,4],
[106,13,9,7],
[106,13,10,0],
[106,13,10,1],
[106,13,10,2],
[106,13,10,7],
[106,13,10,9],
[106,13,11,0],
[106,13,11,1],
[106,13,11,2],
[106,13,11,7],
[106,13,11,9],
[106,13,11,10],
[106,13,12,4],
[106,13,12,7],
[106,13,12,9],
[106,13,12,10],
[106,14,4,0],
[106,14,4,1],
[106,14,4,2],
[106,14,7,0],
[106,14,7,1],
[106,14,7,2],
[106,14,7,4],
[106,14,9,0],
[106,14,9,1],
[106,14,9,2],
[106,14,9,7],
[106,14,10,4],
[106,14,10,7],
[106,14,10,9],
[106,14,11,4],
[106,14,11,7],
[106,14,11,9],
[106,14,11,10],
[106,14,12,0],
[106,14,12,1],
[106,14,12,2],
[106,14,12,4],
[106,14,12,7],
[106,14,12,9],
[106,14,12,10],
[106,15,4,0],
[106,15,4,1],
[106,15,4,2],
[106,15,7,0],
[106,15,7,1],
[106,15,7,2],
[106,15,10,0],
[106,15,10,1],
[106,15,10,2],
[106,15,10,4],
[106,15,10,7],
[106,15,11,0],
[106,15,11,1],
[106,15,11,2],
[106,15,11,4],
[106,15,11,7],
[106,15,11,10],
[106,15,12,0],
[106,15,12,1],
[106,15,12,2],
[106,15,12,4],
[106,15,12,7],
[106,15,12,10],
[106,15,13,0],
[106,15,13,1],
[106,15,13,2],
[106,15,13,4],
[106,15,13,7],
[106,15,13,10],
[106,15,13,11],
[106,15,13,12],
[106,15,14,0],
[106,15,14,1],
[106,15,14,2],
[106,15,14,4],
[106,15,14,7],
[106,15,14,10],
[106,15,14,11],
[106,15,14,12],
[106,16,4,0],
[106,16,4,1],
[106,16,4,2],
[106,16,7,4],
[106,16,9,0],
[106,16,9,1],
[106,16,9,2],
[106,16,9,4],
[106,16,9,7],
[106,16,10,0],
[106,16,10,1],
[106,16,10,2],
[106,16,10,4],
[106,16,10,7],
[106,16,10,9],
[106,16,11,0],
[106,16,11,1],
[106,16,11,2],
[106,16,11,4],
[106,16,11,7],
[106,16,11,9],
[106,16,11,10],
[106,16,12,0],
[106,16,12,1],
[106,16,12,2],
[106,16,12,4],
[106,16,12,7],
[106,16,12,9],
[106,16,12,10],
[106,16,13,0],
[106,16,13,1],
[106,16,13,2],
[106,16,13,4],
[106,16,13,7],
[106,16,13,9],
[106,16,13,10],
[106,16,13,11],
[106,16,13,12],
[106,16,14,0],
[106,16,14,1],
[106,16,14,2],
[106,16,14,4],
[106,16,14,7],
[106,16,14,9],
[106,16,14,10],
[106,16,14,11],
[106,16,14,12],
[106,16,15,0],
[106,16,15,1],
[106,16,15,2],
[106,16,15,4],
[106,16,15,7],
[106,16,15,10],
[106,16,15,11],
[106,16,15,12],
[106,16,15,13],
[106,16,15,14],
[106,17,4,0],
[106,17,4,1],
[106,17,4,2],
[106,17,7,4],
[106,17,9,0],
[106,17,9,1],
[106,17,9,2],
[106,17,9,4],
[106,17,9,7],
[106,17,10,0],
[106,17,10,1],
[106,17,10,2],
[106,17,10,4],
[106,17,10,7],
[106,17,10,9],
[106,17,11,0],
[106,17,11,1],
[106,17,11,2],
[106,17,11,4],
[106,17,11,7],
[106,17,11,9],
[106,17,11,10],
[106,17,12,0],
[106,17,12,1],
[106,17,12,2],
[106,17,12,4],
[106,17,12,7],
[106,17,12,9],
[106,17,12,10],
[106,17,13,0],
[106,17,13,1],
[106,17,13,2],
[106,17,13,4],
[106,17,13,7],
[106,17,13,9],
[106,17,13,10],
[106,17,13,11],
[106,17,13,12],
[106,17,14,0],
[106,17,14,1],
[106,17,14,2],
[106,17,14,4],
[106,17,14,7],
[106,17,14,9],
[106,17,14,10],
[106,17,14,11],
[106,17,14,12],
[106,17,15,0],
[106,17,15,1],
[106,17,15,2],
[106,17,15,4],
[106,17,15,7],
[106,17,15,10],
[106,17,15,11],
[106,17,15,12],
[106,17,15,13],
[106,17,15,14],
[106,17,16,0],
[106,17,16,1],
[106,17,16,2],
[106,17,16,4],
[106,17,16,7],
[106,17,16,9],
[106,17,16,10],
[106,17,16,11],
[106,17,16,12],
[106,17,16,13],
[106,18,4,0],
[106,18,4,1],
[106,18,4,2],
[106,18,7,0],
[106,18,7,1],
[106,18,7,2],
[106,18,7,4],
[106,18,10,0],
[106,18,10,1],
[106,18,10,2],
[106,18,10,4],
[106,18,10,7],
[106,18,11,0],
[106,18,11,1],
[106,18,11,2],
[106,18,11,4],
[106,18,11,7],
[106,18,11,10],
[106,18,12,0],
[106,18,12,1],
[106,18,12,2],
[106,18,12,4],
[106,18,12,7],
[106,18,12,10],
[106,18,13,0],
[106,18,13,1],
[106,18,13,2],
[106,18,13,4],
[106,18,13,7],
[106,18,13,10],
[106,18,13,11],
[106,18,13,12],
[106,18,14,0],
[106,18,14,1],
[106,18,14,2],
[106,18,14,4],
[106,18,14,7],
[106,18,14,10],
[106,18,14,11],
[106,18,14,12],
[106,18,16,0],
[106,18,16,1],
[106,18,16,2],
[106,18,16,4],
[106,18,16,7],
[106,18,16,10],
[106,18,16,11],
[106,18,17,0],
[106,18,17,1],
[106,18,17,2],
[106,18,17,4],
[106,18,17,7],
[106,18,17,10],
[106,18,17,11],
[106,19,7,0],
[106,19,7,1],
[106,19,7,2],
[106,19,7,4],
[106,19,9,0],
[106,19,9,1],
[106,19,9,2],
[106,19,9,4],
[106,19,9,7],
[106,19,10,0],
[106,19,10,1],
[106,19,10,2],
[106,19,10,4],
[106,19,10,7],
[106,19,10,9],
[106,19,11,0],
[106,19,11,1],
[106,19,11,2],
[106,19,11,4],
[106,19,11,7],
[106,19,11,9],
[106,19,11,10],
[106,19,12,0],
[106,19,12,1],
[106,19,12,2],
[106,19,12,4],
[106,19,12,7],
[106,19,12,9],
[106,19,12,10],
[106,19,15,0],
[106,19,15,1],
[106,19,15,2],
[106,19,15,4],
[106,19,15,7],
[106,19,15,10],
[106,19,15,11],
[106,19,16,0],
[106,19,16,1],
[106,19,16,2],
[106,19,16,4],
[106,19,16,7],
[106,19,16,9],
[106,19,17,0],
[106,19,17,1],
[106,19,17,2],
[106,19,17,4],
[106,19,17,7],
[106,19,17,9],
[106,19,18,0],
[106,19,18,1],
[106,19,18,2],
[106,19,18,4],
[106,19,18,7],
[106,20,4,0],
[106,20,4,1],
[106,20,4,2],
[106,20,7,0],
[106,20,7,1],
[106,20,7,2],
[106,20,7,4],
[106,20,9,0],
[106,20,9,1],
[106,20,9,2],
[106,20,9,4],
[106,20,9,7],
[106,20,11,0],
[106,20,11,1],
[106,20,11,2],
[106,20,11,4],
[106,20,11,7],
[106,20,11,9],
[106,20,12,0],
[106,20,12,1],
[106,20,12,2],
[106,20,12,4],
[106,20,12,7],
[106,20,12,9],
[106,20,13,0],
[106,20,13,1],
[106,20,13,2],
[106,20,13,4],
[106,20,13,7],
[106,20,13,9],
[106,20,13,11],
[106,20,13,12],
[106,20,14,0],
[106,20,14,1],
[106,20,14,2],
[106,20,14,4],
[106,20,14,7],
[106,20,14,9],
[106,20,14,11],
[106,20,15,0],
[106,20,15,1],
[106,20,15,2],
[106,20,15,4],
[106,20,15,7],
[106,20,16,0],
[106,20,16,1],
[106,20,16,2],
[106,20,16,4],
[106,20,16,7],
[106,20,17,0],
[106,20,17,1],
[106,20,17,2],
[106,20,17,4],
[106,20,17,7],
[106,20,18,0],
[106,20,18,1],
[106,20,18,2],
[106,20,18,4],
[106,20,19,0],
[106,20,19,1],
[106,20,19,2],
[106,20,19,4],
[106,22,4,0],
[106,22,4,1],
[106,22,4,2],
[106,22,7,0],
[106,22,7,1],
[106,22,7,2],
[106,22,7,4],
[106,22,9,0],
[106,22,9,1],
[106,22,9,2],
[106,22,9,4],
[106,22,9,7],
[106,22,11,0],
[106,22,11,1],
[106,22,11,2],
[106,22,11,4],
[106,22,11,7],
[106,22,11,9],
[106,22,12,0],
[106,22,12,1],
[106,22,12,2],
[106,22,12,4],
[106,22,12,7],
[106,22,12,9],
[106,22,13,0],
[106,22,13,1],
[106,22,13,2],
[106,22,13,4],
[106,22,13,7],
[106,22,13,9],
[106,22,14,0],
[106,22,14,1],
[106,22,14,2],
[106,22,14,4],
[106,22,14,7],
[106,22,15,0],
[106,22,15,1],
[106,22,15,2],
[106,22,15,4],
[106,22,16,0],
[106,22,16,1],
[106,22,16,2],
[106,22,16,4],
[106,22,17,0],
[106,22,17,1],
[106,22,17,2],
[106,22,17,4],
[106,22,18,0],
[106,22,18,1],
[106,22,18,2],
[106,23,4,0],
[106,23,4,1],
[106,23,4,2],
[106,23,7,0],
[106,23,7,1],
[106,23,7,2],
[106,23,7,4],
[106,23,9,0],
[106,23,9,1],
[106,23,9,2],
[106,23,9,4],
[106,23,9,7],
[106,23,10,0],
[106,23,10,1],
[106,23,10,2],
[106,23,10,4],
[106,23,10,7],
[106,23,10,9],
[106,23,11,0],
[106,23,11,1],
[106,23,11,2],
[106,23,11,4],
[106,23,11,7],
[106,23,11,9],
[106,23,12,0],
[106,23,12,1],
[106,23,12,2],
[106,23,12,4],
[106,23,12,7],
[106,23,13,0],
[106,23,13,1],
[106,23,13,2],
[106,23,13,4],
[106,23,13,7],
[106,23,14,0],
[106,23,14,1],
[106,23,14,2],
[106,23,14,4],
[106,23,15,0],
[106,23,15,1],
[106,23,15,2],
[106,23,15,4],
[106,23,16,0],
[106,23,16,1],
[106,23,16,2],
[106,23,17,0],
[106,23,17,1],
[106,23,17,2],
[106,24,4,0],
[106,24,4,1],
[106,24,4,2],
[106,24,7,0],
[106,24,7,1],
[106,24,7,2],
[106,24,7,4],
[106,24,9,0],
[106,24,9,1],
[106,24,9,2],
[106,24,9,4],
[106,24,9,7],
[106,24,10,0],
[106,24,10,1],
[106,24,10,2],
[106,24,10,4],
[106,24,10,7],
[106,24,10,9],
[106,24,11,0],
[106,24,11,1],
[106,24,11,2],
[106,24,11,4],
[106,24,11,7],
[106,24,11,9],
[106,24,12,0],
[106,24,12,1],
[106,24,12,2],
[106,24,12,4],
[106,24,12,7],
[106,24,13,0],
[106,24,13,1],
[106,24,13,2],
[106,24,13,4],
[106,24,13,7],
[106,24,14,0],
[106,24,14,1],
[106,24,14,2],
[106,24,14,4],
[106,24,15,0],
[106,24,15,1],
[106,24,15,2],
[106,24,15,4],
[106,24,17,0],
[106,24,17,1],
[106,24,17,2],
[106,25,4,0],
[106,25,4,1],
[106,25,4,2],
[106,25,9,0],
[106,25,9,1],
[106,25,9,2],
[106,25,9,4],
[106,25,10,0],
[106,25,10,1],
[106,25,10,2],
[106,25,10,4],
[106,25,11,0],
[106,25,11,1],
[106,25,11,2],
[106,25,11,4],
[106,25,12,0],
[106,25,12,1],
[106,25,12,2],
[106,25,12,4],
[106,25,13,0],
[106,25,13,1],
[106,25,13,2],
[106,25,13,4],
[106,25,14,0],
[106,25,14,1],
[106,25,14,2],
[106,26,4,0],
[106,26,4,1],
[106,26,4,2],
[106,26,7,0],
[106,26,7,1],
[106,26,7,2],
[106,26,7,4],
[106,26,10,0],
[106,26,10,1],
[106,26,10,2],
[106,26,10,4],
[106,26,11,0],
[106,26,11,1],
[106,26,11,2],
[106,26,11,4],
[106,26,12,0],
[106,26,12,1],
[106,26,12,2],
[106,26,13,0],
[106,26,13,1],
[106,26,13,2],
[106,27,4,0],
[106,27,4,1],
[106,27,4,2],
[106,27,7,0],
[106,27,7,1],
[106,27,7,2],
[106,27,7,4],
[106,27,9,0],
[106,27,9,1],
[106,27,9,2],
[106,27,9,4],
[106,27,11,0],
[106,27,11,1],
[106,27,11,2],
[106,28,4,0],
[106,28,4,1],
[106,28,4,2],
[106,28,9,0],
[106,28,9,1],
[106,28,9,2],
[106,29,4,0],
[106,29,4,1],
[106,29,4,2],
[106,29,7,0],
[106,29,7,1],
[106,29,7,2],
[106,30,4,0],
[106,30,4,1],
[106,30,4,2],
[106,31,4,0],
[106,31,4,1],
[106,31,4,2],
[107,4,3,0],
[107,4,3,1],
[107,4,3,2],
[107,5,4,0],
[107,5,4,1],
[107,5,4,2],
[107,6,4,0],
[107,6,4,1],
[107,6,4,2],
[107,7,3,0],
[107,7,3,1],
[107,7,3,2],
[107,7,4,0],
[107,7,4,1],
[107,7,4,2],
[107,7,4,3],
[107,7,5,0],
[107,7,5,1],
[107,7,5,2],
[107,7,5,4],
[107,7,6,0],
[107,7,6,1],
[107,7,6,2],
[107,7,6,4],
[107,8,4,0],
[107,8,4,1],
[107,8,4,2],
[107,8,7,0],
[107,8,7,1],
[107,8,7,2],
[107,8,7,4],
[107,9,3,0],
[107,9,3,1],
[107,9,3,2],
[107,9,4,0],
[107,9,4,1],
[107,9,4,2],
[107,9,4,3],
[107,9,5,0],
[107,9,5,1],
[107,9,5,2],
[107,9,5,4],
[107,9,6,0],
[107,9,6,1],
[107,9,6,2],
[107,9,6,4],
[107,9,7,0],
[107,9,7,1],
[107,9,7,2],
[107,9,7,3],
[107,9,7,4],
[107,9,7,5],
[107,9,7,6],
[107,9,8,0],
[107,9,8,1],
[107,9,8,2],
[107,9,8,4],
[107,10,3,0],
[107,10,3,1],
[107,10,3,2],
[107,10,4,0],
[107,10,4,1],
[107,10,4,2],
[107,10,4,3],
[107,10,5,0],
[107,10,5,1],
[107,10,5,2],
[107,10,5,4],
[107,10,6,0],
[107,10,6,1],
[107,10,6,2],
[107,10,6,4],
[107,10,7,0],
[107,10,7,1],
[107,10,7,2],
[107,10,7,3],
[107,10,7,4],
[107,10,7,5],
[107,10,8,0],
[107,10,8,1],
[107,10,8,2],
[107,10,8,4],
[107,10,8,7],
[107,10,9,0],
[107,10,9,1],
[107,10,9,2],
[107,10,9,3],
[107,10,9,4],
[107,10,9,6],
[107,10,9,7],
[107,10,9,8],
[107,11,3,0],
[107,11,3,1],
[107,11,3,2],
[107,11,4,0],
[107,11,4,1],
[107,11,4,2],
[107,11,4,3],
[107,11,5,0],
[107,11,5,1],
[107,11,5,2],
[107,11,5,4],
[107,11,6,0],
[107,11,6,1],
[107,11,6,2],
[107,11,6,4],
[107,11,7,0],
[107,11,7,1],
[107,11,7,2],
[107,11,7,3],
[107,11,7,4],
[107,11,7,5],
[107,11,8,0],
[107,11,8,1],
[107,11,8,2],
[107,11,8,4],
[107,11,8,7],
[107,11,9,0],
[107,11,9,1],
[107,11,9,2],
[107,11,9,3],
[107,11,9,4],
[107,11,9,6],
[107,11,9,7],
[107,11,9,8],
[107,11,10,0],
[107,11,10,1],
[107,11,10,2],
[107,11,10,5],
[107,11,10,6],
[107,11,10,7],
[107,11,10,8],
[107,11,10,9],
[107,12,3,0],
[107,12,3,1],
[107,12,3,2],
[107,12,4,0],
[107,12,4,1],
[107,12,4,2],
[107,12,4,3],
[107,12,5,0],
[107,12,5,1],
[107,12,5,2],
[107,12,5,4],
[107,12,6,0],
[107,12,6,1],
[107,12,6,2],
[107,12,6,4],
[107,12,7,0],
[107,12,7,1],
[107,12,7,2],
[107,12,7,3],
[107,12,7,4],
[107,12,7,6],
[107,12,8,0],
[107,12,8,1],
[107,12,8,2],
[107,12,8,7],
[107,12,9,0],
[107,12,9,1],
[107,12,9,2],
[107,12,9,5],
[107,12,9,6],
[107,12,9,7],
[107,12,9,8],
[107,12,10,3],
[107,12,10,4],
[107,12,10,5],
[107,12,10,6],
[107,12,10,7],
[107,12,10,8],
[107,12,10,9],
[107,13,3,0],
[107,13,3,1],
[107,13,3,2],
[107,13,4,0],
[107,13,4,1],
[107,13,4,2],
[107,13,4,3],
[107,13,5,0],
[107,13,5,1],
[107,13,5,2],
[107,13,5,4],
[107,13,6,0],
[107,13,6,1],
[107,13,6,2],
[107,13,6,4],
[107,13,7,0],
[107,13,7,1],
[107,13,7,2],
[107,13,7,3],
[107,13,7,4],
[107,13,7,6],
[107,13,8,0],
[107,13,8,1],
[107,13,8,2],
[107,13,8,7],
[107,13,9,0],
[107,13,9,1],
[107,13,9,2],
[107,13,9,5],
[107,13,9,6],
[107,13,9,7],
[107,13,9,8],
[107,13,10,3],
[107,13,10,4],
[107,13,10,5],
[107,13,10,6],
[107,13,10,7],
[107,13,10,8],
[107,13,10,9],
[107,13,11,3],
[107,13,11,4],
[107,13,11,5],
[107,13,11,6],
[107,13,11,7],
[107,13,11,8],
[107,13,11,9],
[107,13,11,10],
[107,13,12,0],
[107,13,12,1],
[107,13,12,2],
[107,13,12,3],
[107,13,12,4],
[107,13,12,5],
[107,13,12,6],
[107,13,12,7],
[107,13,12,8],
[107,13,12,9],
[107,13,12,10],
[107,14,3,0],
[107,14,3,1],
[107,14,3,2],
[107,14,4,0],
[107,14,4,1],
[107,14,4,2],
[107,14,4,3],
[107,14,5,0],
[107,14,5,1],
[107,14,5,2],
[107,14,5,4],
[107,14,6,0],
[107,14,6,1],
[107,14,6,2],
[107,14,7,0],
[107,14,7,1],
[107,14,7,2],
[107,14,7,5],
[107,14,7,6],
[107,14,8,4],
[107,14,8,7],
[107,14,9,3],
[107,14,9,4],
[107,14,9,5],
[107,14,9,6],
[107,14,9,7],
[107,14,9,8],
[107,14,10,0],
[107,14,10,1],
[107,14,10,2],
[107,14,10,3],
[107,14,10,4],
[107,14,10,5],
[107,14,10,6],
[107,14,10,7],
[107,14,10,8],
[107,14,10,9],
[107,14,11,0],
[107,14,11,1],
[107,14,11,2],
[107,14,11,3],
[107,14,11,4],
[107,14,11,5],
[107,14,11,6],
[107,14,11,7],
[107,14,11,8],
[107,14,11,9],
[107,14,11,10],
[107,14,12,0],
[107,14,12,1],
[107,14,12,2],
[107,14,12,3],
[107,14,12,4],
[107,14,12,5],
[107,14,12,6],
[107,14,12,7],
[107,14,12,8],
[107,14,12,9],
[107,14,12,10],
[107,15,3,0],
[107,15,3,1],
[107,15,3,2],
[107,15,4,0],
[107,15,4,1],
[107,15,4,2],
[107,15,4,3],
[107,15,5,0],
[107,15,5,1],
[107,15,5,2],
[107,15,6,4],
[107,15,7,3],
[107,15,7,4],
[107,15,7,5],
[107,15,7,6],
[107,15,8,0],
[107,15,8,1],
[107,15,8,2],
[107,15,8,4],
[107,15,8,7],
[107,15,10,0],
[107,15,10,1],
[107,15,10,2],
[107,15,10,3],
[107,15,10,4],
[107,15,10,5],
[107,15,10,6],
[107,15,10,7],
[107,15,10,8],
[107,15,11,0],
[107,15,11,1],
[107,15,11,2],
[107,15,11,3],
[107,15,11,4],
[107,15,11,5],
[107,15,11,6],
[107,15,11,7],
[107,15,11,8],
[107,15,11,10],
[107,15,12,0],
[107,15,12,1],
[107,15,12,2],
[107,15,12,3],
[107,15,12,4],
[107,15,12,5],
[107,15,12,6],
[107,15,12,7],
[107,15,12,8],
[107,15,12,10],
[107,15,13,0],
[107,15,13,1],
[107,15,13,2],
[107,15,13,3],
[107,15,13,4],
[107,15,13,5],
[107,15,13,6],
[107,15,13,7],
[107,15,13,8],
[107,15,13,10],
[107,15,13,11],
[107,15,13,12],
[107,15,14,0],
[107,15,14,1],
[107,15,14,2],
[107,15,14,3],
[107,15,14,4],
[107,15,14,5],
[107,15,14,6],
[107,15,14,7],
[107,15,14,8],
[107,15,14,10],
[107,15,14,11],
[107,15,14,12],
[107,16,3,0],
[107,16,3,1],
[107,16,3,2],
[107,16,4,0],
[107,16,4,1],
[107,16,4,2],
[107,16,5,4],
[107,16,6,0],
[107,16,6,1],
[107,16,6,2],
[107,16,6,4],
[107,16,7,0],
[107,16,7,1],
[107,16,7,2],
[107,16,7,3],
[107,16,7,4],
[107,16,7,5],
[107,16,7,6],
[107,16,8,0],
[107,16,8,1],
[107,16,8,2],
[107,16,8,4],
[107,16,8,7],
[107,16,9,0],
[107,16,9,1],
[107,16,9,2],
[107,16,9,3],
[107,16,9,4],
[107,16,9,5],
[107,16,9,6],
[107,16,9,7],
[107,16,9,8],
[107,16,10,0],
[107,16,10,1],
[107,16,10,2],
[107,16,10,3],
[107,16,10,4],
[107,16,10,5],
[107,16,10,6],
[107,16,10,7],
[107,16,10,8],
[107,16,10,9],
[107,16,11,0],
[107,16,11,1],
[107,16,11,2],
[107,16,11,3],
[107,16,11,4],
[107,16,11,5],
[107,16,11,6],
[107,16,11,7],
[107,16,11,8],
[107,16,11,9],
[107,16,11,10],
[107,16,12,0],
[107,16,12,1],
[107,16,12,2],
[107,16,12,3],
[107,16,12,4],
[107,16,12,5],
[107,16,12,6],
[107,16,12,7],
[107,16,12,8],
[107,16,12,9],
[107,16,12,10],
[107,16,13,0],
[107,16,13,1],
[107,16,13,2],
[107,16,13,3],
[107,16,13,4],
[107,16,13,5],
[107,16,13,6],
[107,16,13,7],
[107,16,13,8],
[107,16,13,9],
[107,16,13,10],
[107,16,13,11],
[107,16,13,12],
[107,16,14,0],
[107,16,14,1],
[107,16,14,2],
[107,16,14,3],
[107,16,14,4],
[107,16,14,5],
[107,16,14,6],
[107,16,14,7],
[107,16,14,8],
[107,16,14,9],
[107,16,14,10],
[107,16,14,11],
[107,16,14,12],
[107,16,15,0],
[107,16,15,1],
[107,16,15,2],
[107,16,15,3],
[107,16,15,4],
[107,16,15,5],
[107,16,15,6],
[107,16,15,7],
[107,16,15,8],
[107,16,15,10],
[107,16,15,11],
[107,16,15,12],
[107,16,15,13],
[107,17,3,0],
[107,17,3,1],
[107,17,3,2],
[107,17,4,0],
[107,17,4,1],
[107,17,4,2],
[107,17,5,4],
[107,17,6,0],
[107,17,6,1],
[107,17,6,2],
[107,17,6,4],
[107,17,7,0],
[107,17,7,1],
[107,17,7,2],
[107,17,7,3],
[107,17,7,4],
[107,17,7,5],
[107,17,7,6],
[107,17,8,0],
[107,17,8,1],
[107,17,8,2],
[107,17,8,4],
[107,17,8,7],
[107,17,9,0],
[107,17,9,1],
[107,17,9,2],
[107,17,9,3],
[107,17,9,4],
[107,17,9,5],
[107,17,9,6],
[107,17,9,7],
[107,17,9,8],
[107,17,10,0],
[107,17,10,1],
[107,17,10,2],
[107,17,10,3],
[107,17,10,4],
[107,17,10,5],
[107,17,10,6],
[107,17,10,7],
[107,17,10,8],
[107,17,10,9],
[107,17,11,0],
[107,17,11,1],
[107,17,11,2],
[107,17,11,3],
[107,17,11,4],
[107,17,11,5],
[107,17,11,6],
[107,17,11,7],
[107,17,11,8],
[107,17,11,9],
[107,17,11,10],
[107,17,12,0],
[107,17,12,1],
[107,17,12,2],
[107,17,12,3],
[107,17,12,4],
[107,17,12,5],
[107,17,12,6],
[107,17,12,7],
[107,17,12,8],
[107,17,12,9],
[107,17,12,10],
[107,17,13,0],
[107,17,13,1],
[107,17,13,2],
[107,17,13,3],
[107,17,13,4],
[107,17,13,5],
[107,17,13,6],
[107,17,13,7],
[107,17,13,8],
[107,17,13,9],
[107,17,13,10],
[107,17,13,11],
[107,17,13,12],
[107,17,14,0],
[107,17,14,1],
[107,17,14,2],
[107,17,14,3],
[107,17,14,4],
[107,17,14,5],
[107,17,14,6],
[107,17,14,7],
[107,17,14,8],
[107,17,14,9],
[107,17,14,10],
[107,17,14,11],
[107,17,14,12],
[107,17,15,0],
[107,17,15,1],
[107,17,15,2],
[107,17,15,3],
[107,17,15,4],
[107,17,15,5],
[107,17,15,6],
[107,17,15,7],
[107,17,15,8],
[107,17,15,10],
[107,17,15,11],
[107,17,15,12],
[107,17,15,13],
[107,17,16,0],
[107,17,16,1],
[107,17,16,2],
[107,17,16,3],
[107,17,16,4],
[107,17,16,5],
[107,17,16,6],
[107,17,16,7],
[107,17,16,8],
[107,17,16,9],
[107,17,16,10],
[107,17,16,11],
[107,18,4,3],
[107,18,5,0],
[107,18,5,1],
[107,18,5,2],
[107,18,5,4],
[107,18,6,0],
[107,18,6,1],
[107,18,6,2],
[107,18,6,4],
[107,18,7,0],
[107,18,7,1],
[107,18,7,2],
[107,18,7,3],
[107,18,7,4],
[107,18,7,5],
[107,18,7,6],
[107,18,8,0],
[107,18,8,1],
[107,18,8,2],
[107,18,8,4],
[107,18,8,7],
[107,18,10,0],
[107,18,10,1],
[107,18,10,2],
[107,18,10,3],
[107,18,10,4],
[107,18,10,5],
[107,18,10,6],
[107,18,10,7],
[107,18,10,8],
[107,18,11,0],
[107,18,11,1],
[107,18,11,2],
[107,18,11,3],
[107,18,11,4],
[107,18,11,5],
[107,18,11,6],
[107,18,11,7],
[107,18,11,8],
[107,18,11,10],
[107,18,12,0],
[107,18,12,1],
[107,18,12,2],
[107,18,12,3],
[107,18,12,4],
[107,18,12,5],
[107,18,12,6],
[107,18,12,7],
[107,18,12,8],
[107,18,12,10],
[107,18,13,0],
[107,18,13,1],
[107,18,13,2],
[107,18,13,3],
[107,18,13,4],
[107,18,13,5],
[107,18,13,6],
[107,18,13,7],
[107,18,13,8],
[107,18,13,10],
[107,18,13,11],
[107,18,13,12],
[107,18,14,0],
[107,18,14,1],
[107,18,14,2],
[107,18,14,3],
[107,18,14,4],
[107,18,14,5],
[107,18,14,6],
[107,18,14,7],
[107,18,14,8],
[107,18,14,10],
[107,18,14,11],
[107,18,14,12],
[107,18,16,0],
[107,18,16,1],
[107,18,16,2],
[107,18,16,3],
[107,18,16,4],
[107,18,16,5],
[107,18,16,6],
[107,18,16,7],
[107,18,16,8],
[107,18,17,0],
[107,18,17,1],
[107,18,17,2],
[107,18,17,3],
[107,18,17,4],
[107,18,17,5],
[107,18,17,6],
[107,18,17,7],
[107,18,17,8],
[107,19,3,0],
[107,19,3,1],
[107,19,3,2],
[107,19,4,0],
[107,19,4,1],
[107,19,4,2],
[107,19,4,3],
[107,19,5,0],
[107,19,5,1],
[107,19,5,2],
[107,19,5,4],
[107,19,6,0],
[107,19,6,1],
[107,19,6,2],
[107,19,6,4],
[107,19,7,0],
[107,19,7,1],
[107,19,7,2],
[107,19,7,3],
[107,19,7,4],
[107,19,7,5],
[107,19,7,6],
[107,19,8,0],
[107,19,8,1],
[107,19,8,2],
[107,19,8,4],
[107,19,8,7],
[107,19,9,0],
[107,19,9,1],
[107,19,9,2],
[107,19,9,3],
[107,19,9,4],
[107,19,9,5],
[107,19,9,6],
[107,19,9,7],
[107,19,9,8],
[107,19,10,0],
[107,19,10,1],
[107,19,10,2],
[107,19,10,3],
[107,19,10,4],
[107,19,10,5],
[107,19,10,6],
[107,19,10,7],
[107,19,10,8],
[107,19,10,9],
[107,19,11,0],
[107,19,11,1],
[107,19,11,2],
[107,19,11,3],
[107,19,11,4],
[107,19,11,5],
[107,19,11,6],
[107,19,11,7],
[107,19,11,8],
[107,19,11,9],
[107,19,11,10],
[107,19,12,0],
[107,19,12,1],
[107,19,12,2],
[107,19,12,3],
[107,19,12,4],
[107,19,12,5],
[107,19,12,6],
[107,19,12,7],
[107,19,12,8],
[107,19,12,9],
[107,19,12,10],
[107,19,15,0],
[107,19,15,1],
[107,19,15,2],
[107,19,15,3],
[107,19,15,4],
[107,19,15,5],
[107,19,15,6],
[107,19,15,7],
[107,19,15,8],
[107,19,16,0],
[107,19,16,1],
[107,19,16,2],
[107,19,16,3],
[107,19,16,4],
[107,19,16,5],
[107,19,16,6],
[107,19,16,7],
[107,19,17,0],
[107,19,17,1],
[107,19,17,2],
[107,19,17,3],
[107,19,17,4],
[107,19,17,5],
[107,19,17,6],
[107,19,17,7],
[107,19,18,0],
[107,19,18,1],
[107,19,18,2],
[107,19,18,3],
[107,19,18,4],
[107,19,18,5],
[107,20,3,0],
[107,20,3,1],
[107,20,3,2],
[107,20,4,0],
[107,20,4,1],
[107,20,4,2],
[107,20,4,3],
[107,20,5,0],
[107,20,5,1],
[107,20,5,2],
[107,20,5,4],
[107,20,6,0],
[107,20,6,1],
[107,20,6,2],
[107,20,6,4],
[107,20,7,0],
[107,20,7,1],
[107,20,7,2],
[107,20,7,3],
[107,20,7,4],
[107,20,7,5],
[107,20,7,6],
[107,20,8,0],
[107,20,8,1],
[107,20,8,2],
[107,20,8,4],
[107,20,8,7],
[107,20,9,0],
[107,20,9,1],
[107,20,9,2],
[107,20,9,3],
[107,20,9,4],
[107,20,9,5],
[107,20,9,6],
[107,20,9,7],
[107,20,9,8],
[107,20,11,0],
[107,20,11,1],
[107,20,11,2],
[107,20,11,3],
[107,20,11,4],
[107,20,11,5],
[107,20,11,6],
[107,20,11,7],
[107,20,11,8],
[107,20,11,9],
[107,20,12,0],
[107,20,12,1],
[107,20,12,2],
[107,20,12,3],
[107,20,12,4],
[107,20,12,5],
[107,20,12,6],
[107,20,12,7],
[107,20,12,8],
[107,20,12,9],
[107,20,13,0],
[107,20,13,1],
[107,20,13,2],
[107,20,13,3],
[107,20,13,4],
[107,20,13,5],
[107,20,13,6],
[107,20,13,7],
[107,20,13,8],
[107,20,13,9],
[107,20,13,11],
[107,20,14,0],
[107,20,14,1],
[107,20,14,2],
[107,20,14,3],
[107,20,14,4],
[107,20,14,5],
[107,20,14,6],
[107,20,14,7],
[107,20,14,8],
[107,20,14,9],
[107,20,15,0],
[107,20,15,1],
[107,20,15,2],
[107,20,15,3],
[107,20,15,4],
[107,20,15,5],
[107,20,15,6],
[107,20,15,7],
[107,20,16,0],
[107,20,16,1],
[107,20,16,2],
[107,20,16,3],
[107,20,16,4],
[107,20,16,5],
[107,20,17,0],
[107,20,17,1],
[107,20,17,2],
[107,20,17,3],
[107,20,17,4],
[107,20,17,5],
[107,20,18,0],
[107,20,18,1],
[107,20,18,2],
[107,20,18,3],
[107,20,18,4],
[107,20,19,0],
[107,20,19,1],
[107,20,19,2],
[107,21,4,0],
[107,21,4,1],
[107,21,4,2],
[107,21,7,0],
[107,21,7,1],
[107,21,7,2],
[107,21,7,4],
[107,21,9,0],
[107,21,9,1],
[107,21,9,2],
[107,21,9,4],
[107,21,9,7],
[107,21,10,0],
[107,21,10,1],
[107,21,10,2],
[107,21,10,4],
[107,21,10,7],
[107,21,10,9],
[107,21,11,0],
[107,21,11,1],
[107,21,11,2],
[107,21,11,4],
[107,21,11,7],
[107,21,11,9],
[107,21,11,10],
[107,21,12,0],
[107,21,12,1],
[107,21,12,2],
[107,21,12,4],
[107,21,12,7],
[107,21,12,9],
[107,21,13,0],
[107,21,13,1],
[107,21,13,2],
[107,21,13,4],
[107,21,13,7],
[107,21,13,9],
[107,21,14,0],
[107,21,14,1],
[107,21,14,2],
[107,21,14,4],
[107,21,14,7],
[107,21,15,0],
[107,21,15,1],
[107,21,15,2],
[107,21,15,4],
[107,21,16,0],
[107,21,16,1],
[107,21,16,2],
[107,21,16,4],
[107,21,17,0],
[107,21,17,1],
[107,21,17,2],
[107,21,17,4],
[107,21,18,0],
[107,21,18,1],
[107,21,18,2],
[107,22,3,0],
[107,22,3,1],
[107,22,3,2],
[107,22,4,0],
[107,22,4,1],
[107,22,4,2],
[107,22,4,3],
[107,22,5,0],
[107,22,5,1],
[107,22,5,2],
[107,22,5,4],
[107,22,6,0],
[107,22,6,1],
[107,22,6,2],
[107,22,6,4],
[107,22,7,0],
[107,22,7,1],
[107,22,7,2],
[107,22,7,3],
[107,22,7,4],
[107,22,7,5],
[107,22,7,6],
[107,22,8,0],
[107,22,8,1],
[107,22,8,2],
[107,22,8,4],
[107,22,8,7],
[107,22,9,0],
[107,22,9,1],
[107,22,9,2],
[107,22,9,3],
[107,22,9,4],
[107,22,9,5],
[107,22,9,6],
[107,22,9,7],
[107,22,9,8],
[107,22,11,0],
[107,22,11,1],
[107,22,11,2],
[107,22,11,3],
[107,22,11,4],
[107,22,11,5],
[107,22,11,6],
[107,22,11,7],
[107,22,11,8],
[107,22,11,9],
[107,22,12,0],
[107,22,12,1],
[107,22,12,2],
[107,22,12,3],
[107,22,12,4],
[107,22,12,5],
[107,22,12,6],
[107,22,12,7],
[107,22,13,0],
[107,22,13,1],
[107,22,13,2],
[107,22,13,3],
[107,22,13,4],
[107,22,13,5],
[107,22,13,6],
[107,22,13,7],
[107,22,14,0],
[107,22,14,1],
[107,22,14,2],
[107,22,14,3],
[107,22,14,4],
[107,22,14,5],
[107,22,15,0],
[107,22,15,1],
[107,22,15,2],
[107,22,15,3],
[107,22,15,4],
[107,22,16,0],
[107,22,16,1],
[107,22,16,2],
[107,22,17,0],
[107,22,17,1],
[107,22,17,2],
[107,23,3,0],
[107,23,3,1],
[107,23,3,2],
[107,23,4,0],
[107,23,4,1],
[107,23,4,2],
[107,23,4,3],
[107,23,5,0],
[107,23,5,1],
[107,23,5,2],
[107,23,5,4],
[107,23,6,0],
[107,23,6,1],
[107,23,6,2],
[107,23,6,4],
[107,23,7,0],
[107,23,7,1],
[107,23,7,2],
[107,23,7,3],
[107,23,7,4],
[107,23,7,5],
[107,23,7,6],
[107,23,8,0],
[107,23,8,1],
[107,23,8,2],
[107,23,8,4],
[107,23,8,7],
[107,23,9,0],
[107,23,9,1],
[107,23,9,2],
[107,23,9,3],
[107,23,9,4],
[107,23,9,5],
[107,23,9,6],
[107,23,9,7],
[107,23,9,8],
[107,23,10,0],
[107,23,10,1],
[107,23,10,2],
[107,23,10,3],
[107,23,10,4],
[107,23,10,5],
[107,23,10,6],
[107,23,10,7],
[107,23,11,0],
[107,23,11,1],
[107,23,11,2],
[107,23,11,3],
[107,23,11,4],
[107,23,11,5],
[107,23,11,6],
[107,23,11,7],
[107,23,12,0],
[107,23,12,1],
[107,23,12,2],
[107,23,12,3],
[107,23,12,4],
[107,23,12,5],
[107,23,13,0],
[107,23,13,1],
[107,23,13,2],
[107,23,13,3],
[107,23,13,4],
[107,23,13,5],
[107,23,14,0],
[107,23,14,1],
[107,23,14,2],
[107,23,14,3],
[107,23,14,4],
[107,23,15,0],
[107,23,15,1],
[107,23,15,2],
[107,24,3,0],
[107,24,3,1],
[107,24,3,2],
[107,24,4,0],
[107,24,4,1],
[107,24,4,2],
[107,24,4,3],
[107,24,5,0],
[107,24,5,1],
[107,24,5,2],
[107,24,5,4],
[107,24,6,0],
[107,24,6,1],
[107,24,6,2],
[107,24,6,4],
[107,24,7,0],
[107,24,7,1],
[107,24,7,2],
[107,24,7,3],
[107,24,7,4],
[107,24,7,5],
[107,24,7,6],
[107,24,8,0],
[107,24,8,1],
[107,24,8,2],
[107,24,8,4],
[107,24,8,7],
[107,24,9,0],
[107,24,9,1],
[107,24,9,2],
[107,24,9,3],
[107,24,9,4],
[107,24,9,5],
[107,24,9,6],
[107,24,9,7],
[107,24,9,8],
[107,24,10,0],
[107,24,10,1],
[107,24,10,2],
[107,24,10,3],
[107,24,10,4],
[107,24,10,5],
[107,24,10,6],
[107,24,10,7],
[107,24,11,0],
[107,24,11,1],
[107,24,11,2],
[107,24,11,3],
[107,24,11,4],
[107,24,11,5],
[107,24,11,6],
[107,24,11,7],
[107,24,12,0],
[107,24,12,1],
[107,24,12,2],
[107,24,12,3],
[107,24,12,4],
[107,24,12,5],
[107,24,13,0],
[107,24,13,1],
[107,24,13,2],
[107,24,13,3],
[107,24,13,4],
[107,24,13,5],
[107,24,14,0],
[107,24,14,1],
[107,24,14,2],
[107,24,14,3],
[107,24,14,4],
[107,24,15,0],
[107,24,15,1],
[107,24,15,2],
[107,25,3,0],
[107,25,3,1],
[107,25,3,2],
[107,25,4,0],
[107,25,4,1],
[107,25,4,2],
[107,25,4,3],
[107,25,5,0],
[107,25,5,1],
[107,25,5,2],
[107,25,5,4],
[107,25,6,0],
[107,25,6,1],
[107,25,6,2],
[107,25,6,4],
[107,25,8,0],
[107,25,8,1],
[107,25,8,2],
[107,25,8,4],
[107,25,9,0],
[107,25,9,1],
[107,25,9,2],
[107,25,9,3],
[107,25,9,4],
[107,25,9,5],
[107,25,10,0],
[107,25,10,1],
[107,25,10,2],
[107,25,10,3],
[107,25,10,4],
[107,25,11,0],
[107,25,11,1],
[107,25,11,2],
[107,25,11,3],
[107,25,11,4],
[107,25,12,0],
[107,25,12,1],
[107,25,12,2],
[107,25,13,0],
[107,25,13,1],
[107,25,13,2],
[107,26,3,0],
[107,26,3,1],
[107,26,3,2],
[107,26,4,0],
[107,26,4,1],
[107,26,4,2],
[107,26,4,3],
[107,26,5,0],
[107,26,5,1],
[107,26,5,2],
[107,26,5,4],
[107,26,6,0],
[107,26,6,1],
[107,26,6,2],
[107,26,6,4],
[107,26,7,0],
[107,26,7,1],
[107,26,7,2],
[107,26,7,3],
[107,26,7,4],
[107,26,7,5],
[107,26,8,0],
[107,26,8,1],
[107,26,8,2],
[107,26,8,4],
[107,26,10,0],
[107,26,10,1],
[107,26,10,2],
[107,26,11,0],
[107,26,11,1],
[107,26,11,2],
[107,27,3,0],
[107,27,3,1],
[107,27,3,2],
[107,27,4,0],
[107,27,4,1],
[107,27,4,2],
[107,27,4,3],
[107,27,5,0],
[107,27,5,1],
[107,27,5,2],
[107,27,5,4],
[107,27,6,0],
[107,27,6,1],
[107,27,6,2],
[107,27,6,4],
[107,27,7,0],
[107,27,7,1],
[107,27,7,2],
[107,27,7,3],
[107,27,7,4],
[107,27,8,0],
[107,27,8,1],
[107,27,8,2],
[107,27,9,0],
[107,27,9,1],
[107,27,9,2],
[107,28,3,0],
[107,28,3,1],
[107,28,3,2],
[107,28,4,0],
[107,28,4,1],
[107,28,4,2],
[107,28,4,3],
[107,28,5,0],
[107,28,5,1],
[107,28,5,2],
[107,28,5,4],
[107,28,6,0],
[107,28,6,1],
[107,28,6,2],
[107,29,3,0],
[107,29,3,1],
[107,29,3,2],
[107,29,4,0],
[107,29,4,1],
[107,29,4,2],
[107,29,4,3],
[107,29,5,0],
[107,29,5,1],
[107,29,5,2],
[107,30,3,0],
[107,30,3,1],
[107,30,3,2],
[107,30,4,0],
[107,30,4,1],
[107,30,4,2],
[108,4,3,0],
[108,4,3,1],
[108,4,3,2],
[108,5,4,0],
[108,5,4,1],
[108,5,4,2],
[108,6,4,0],
[108,6,4,1],
[108,6,4,2],
[108,7,3,0],
[108,7,3,1],
[108,7,3,2],
[108,7,4,0],
[108,7,4,1],
[108,7,4,2],
[108,7,4,3],
[108,7,5,0],
[108,7,5,1],
[108,7,5,2],
[108,7,5,4],
[108,7,6,0],
[108,7,6,1],
[108,7,6,2],
[108,7,6,4],
[108,8,4,0],
[108,8,4,1],
[108,8,4,2],
[108,8,7,0],
[108,8,7,1],
[108,8,7,2],
[108,8,7,4],
[108,10,3,0],
[108,10,3,1],
[108,10,3,2],
[108,10,4,0],
[108,10,4,1],
[108,10,4,2],
[108,10,4,3],
[108,10,5,0],
[108,10,5,1],
[108,10,5,2],
[108,10,5,4],
[108,10,6,0],
[108,10,6,1],
[108,10,6,2],
[108,10,6,4],
[108,10,7,0],
[108,10,7,1],
[108,10,7,2],
[108,10,7,3],
[108,10,7,4],
[108,10,7,6],
[108,10,8,0],
[108,10,8,1],
[108,10,8,2],
[108,10,8,7],
[108,11,3,0],
[108,11,3,1],
[108,11,3,2],
[108,11,4,0],
[108,11,4,1],
[108,11,4,2],
[108,11,4,3],
[108,11,5,0],
[108,11,5,1],
[108,11,5,2],
[108,11,5,4],
[108,11,6,0],
[108,11,6,1],
[108,11,6,2],
[108,11,6,4],
[108,11,7,0],
[108,11,7,1],
[108,11,7,2],
[108,11,7,3],
[108,11,7,4],
[108,11,7,6],
[108,11,8,0],
[108,11,8,1],
[108,11,8,2],
[108,11,8,7],
[108,11,10,3],
[108,11,10,4],
[108,11,10,5],
[108,11,10,6],
[108,11,10,7],
[108,11,10,8],
[108,12,3,0],
[108,12,3,1],
[108,12,3,2],
[108,12,4,0],
[108,12,4,1],
[108,12,4,2],
[108,12,4,3],
[108,12,5,0],
[108,12,5,1],
[108,12,5,2],
[108,12,5,4],
[108,12,6,0],
[108,12,6,1],
[108,12,6,2],
[108,12,7,0],
[108,12,7,1],
[108,12,7,2],
[108,12,7,5],
[108,12,7,6],
[108,12,8,4],
[108,12,8,7],
[108,12,10,0],
[108,12,10,1],
[108,12,10,2],
[108,12,10,3],
[108,12,10,4],
[108,12,10,5],
[108,12,10,6],
[108,12,10,7],
[108,12,10,8],
[108,13,3,0],
[108,13,3,1],
[108,13,3,2],
[108,13,4,0],
[108,13,4,1],
[108,13,4,2],
[108,13,4,3],
[108,13,5,0],
[108,13,5,1],
[108,13,5,2],
[108,13,5,4],
[108,13,6,0],
[108,13,6,1],
[108,13,6,2],
[108,13,7,0],
[108,13,7,1],
[108,13,7,2],
[108,13,7,5],
[108,13,7,6],
[108,13,8,4],
[108,13,8,7],
[108,13,10,0],
[108,13,10,1],
[108,13,10,2],
[108,13,10,3],
[108,13,10,4],
[108,13,10,5],
[108,13,10,6],
[108,13,10,7],
[108,13,10,8],
[108,13,11,0],
[108,13,11,1],
[108,13,11,2],
[108,13,11,3],
[108,13,11,4],
[108,13,11,5],
[108,13,11,6],
[108,13,11,7],
[108,13,11,8],
[108,13,11,10],
[108,13,12,0],
[108,13,12,1],
[108,13,12,2],
[108,13,12,3],
[108,13,12,4],
[108,13,12,5],
[108,13,12,6],
[108,13,12,7],
[108,13,12,8],
[108,13,12,10],
[108,14,3,0],
[108,14,3,1],
[108,14,3,2],
[108,14,4,0],
[108,14,4,1],
[108,14,4,2],
[108,14,4,3],
[108,14,5,0],
[108,14,5,1],
[108,14,5,2],
[108,14,6,4],
[108,14,7,3],
[108,14,7,4],
[108,14,7,5],
[108,14,7,6],
[108,14,8,0],
[108,14,8,1],
[108,14,8,2],
[108,14,8,4],
[108,14,8,7],
[108,14,10,0],
[108,14,10,1],
[108,14,10,2],
[108,14,10,3],
[108,14,10,4],
[108,14,10,5],
[108,14,10,6],
[108,14,10,7],
[108,14,10,8],
[108,14,11,0],
[108,14,11,1],
[108,14,11,2],
[108,14,11,3],
[108,14,11,4],
[108,14,11,5],
[108,14,11,6],
[108,14,11,7],
[108,14,11,8],
[108,14,11,10],
[108,14,12,0],
[108,14,12,1],
[108,14,12,2],
[108,14,12,3],
[108,14,12,4],
[108,14,12,5],
[108,14,12,6],
[108,14,12,7],
[108,14,12,8],
[108,14,12,10],
[108,16,4,3],
[108,16,5,0],
[108,16,5,1],
[108,16,5,2],
[108,16,5,4],
[108,16,6,0],
[108,16,6,1],
[108,16,6,2],
[108,16,6,4],
[108,16,7,0],
[108,16,7,1],
[108,16,7,2],
[108,16,7,3],
[108,16,7,4],
[108,16,7,5],
[108,16,7,6],
[108,16,8,0],
[108,16,8,1],
[108,16,8,2],
[108,16,8,4],
[108,16,8,7],
[108,16,10,0],
[108,16,10,1],
[108,16,10,2],
[108,16,10,3],
[108,16,10,4],
[108,16,10,5],
[108,16,10,6],
[108,16,10,7],
[108,16,10,8],
[108,16,11,0],
[108,16,11,1],
[108,16,11,2],
[108,16,11,3],
[108,16,11,4],
[108,16,11,5],
[108,16,11,6],
[108,16,11,7],
[108,16,11,8],
[108,16,11,10],
[108,16,12,0],
[108,16,12,1],
[108,16,12,2],
[108,16,12,3],
[108,16,12,4],
[108,16,12,5],
[108,16,12,6],
[108,16,12,7],
[108,16,12,8],
[108,16,12,10],
[108,16,13,0],
[108,16,13,1],
[108,16,13,2],
[108,16,13,3],
[108,16,13,4],
[108,16,13,5],
[108,16,13,6],
[108,16,13,7],
[108,16,13,8],
[108,16,13,10],
[108,16,13,11],
[108,16,13,12],
[108,16,14,0],
[108,16,14,1],
[108,16,14,2],
[108,16,14,3],
[108,16,14,4],
[108,16,14,5],
[108,16,14,6],
[108,16,14,7],
[108,16,14,8],
[108,16,14,10],
[108,16,14,11],
[108,16,14,12],
[108,17,4,3],
[108,17,5,0],
[108,17,5,1],
[108,17,5,2],
[108,17,5,4],
[108,17,6,0],
[108,17,6,1],
[108,17,6,2],
[108,17,6,4],
[108,17,7,0],
[108,17,7,1],
[108,17,7,2],
[108,17,7,3],
[108,17,7,4],
[108,17,7,5],
[108,17,7,6],
[108,17,8,0],
[108,17,8,1],
[108,17,8,2],
[108,17,8,4],
[108,17,8,7],
[108,17,10,0],
[108,17,10,1],
[108,17,10,2],
[108,17,10,3],
[108,17,10,4],
[108,17,10,5],
[108,17,10,6],
[108,17,10,7],
[108,17,10,8],
[108,17,11,0],
[108,17,11,1],
[108,17,11,2],
[108,17,11,3],
[108,17,11,4],
[108,17,11,5],
[108,17,11,6],
[108,17,11,7],
[108,17,11,8],
[108,17,11,10],
[108,17,12,0],
[108,17,12,1],
[108,17,12,2],
[108,17,12,3],
[108,17,12,4],
[108,17,12,5],
[108,17,12,6],
[108,17,12,7],
[108,17,12,8],
[108,17,12,10],
[108,17,13,0],
[108,17,13,1],
[108,17,13,2],
[108,17,13,3],
[108,17,13,4],
[108,17,13,5],
[108,17,13,6],
[108,17,13,7],
[108,17,13,8],
[108,17,13,10],
[108,17,13,11],
[108,17,13,12],
[108,17,14,0],
[108,17,14,1],
[108,17,14,2],
[108,17,14,3],
[108,17,14,4],
[108,17,14,5],
[108,17,14,6],
[108,17,14,7],
[108,17,14,8],
[108,17,14,10],
[108,17,14,11],
[108,17,14,12],
[108,17,16,0],
[108,17,16,1],
[108,17,16,2],
[108,17,16,3],
[108,17,16,4],
[108,17,16,5],
[108,17,16,6],
[108,17,16,7],
[108,17,16,8],
[108,19,3,0],
[108,19,3,1],
[108,19,3,2],
[108,19,4,0],
[108,19,4,1],
[108,19,4,2],
[108,19,4,3],
[108,19,5,0],
[108,19,5,1],
[108,19,5,2],
[108,19,5,4],
[108,19,6,0],
[108,19,6,1],
[108,19,6,2],
[108,19,6,4],
[108,19,7,0],
[108,19,7,1],
[108,19,7,2],
[108,19,7,3],
[108,19,7,4],
[108,19,7,5],
[108,19,7,6],
[108,19,8,0],
[108,19,8,1],
[108,19,8,2],
[108,19,8,4],
[108,19,8,7],
[108,19,10,0],
[108,19,10,1],
[108,19,10,2],
[108,19,10,3],
[108,19,10,4],
[108,19,10,5],
[108,19,10,6],
[108,19,10,7],
[108,19,10,8],
[108,19,11,0],
[108,19,11,1],
[108,19,11,2],
[108,19,11,3],
[108,19,11,4],
[108,19,11,5],
[108,19,11,6],
[108,19,11,7],
[108,19,11,8],
[108,19,11,10],
[108,19,12,0],
[108,19,12,1],
[108,19,12,2],
[108,19,12,3],
[108,19,12,4],
[108,19,12,5],
[108,19,12,6],
[108,19,12,7],
[108,19,12,8],
[108,19,12,10],
[108,19,16,0],
[108,19,16,1],
[108,19,16,2],
[108,19,16,3],
[108,19,16,4],
[108,19,16,5],
[108,19,17,0],
[108,19,17,1],
[108,19,17,2],
[108,19,17,3],
[108,19,17,4],
[108,19,17,5],
[108,20,3,0],
[108,20,3,1],
[108,20,3,2],
[108,20,4,0],
[108,20,4,1],
[108,20,4,2],
[108,20,4,3],
[108,20,5,0],
[108,20,5,1],
[108,20,5,2],
[108,20,5,4],
[108,20,6,0],
[108,20,6,1],
[108,20,6,2],
[108,20,6,4],
[108,20,7,0],
[108,20,7,1],
[108,20,7,2],
[108,20,7,3],
[108,20,7,4],
[108,20,7,5],
[108,20,7,6],
[108,20,8,0],
[108,20,8,1],
[108,20,8,2],
[108,20,8,4],
[108,20,8,7],
[108,20,11,0],
[108,20,11,1],
[108,20,11,2],
[108,20,11,3],
[108,20,11,4],
[108,20,11,5],
[108,20,11,6],
[108,20,11,7],
[108,20,11,8],
[108,20,12,0],
[108,20,12,1],
[108,20,12,2],
[108,20,12,3],
[108,20,12,4],
[108,20,12,5],
[108,20,12,6],
[108,20,12,7],
[108,20,12,8],
[108,20,13,0],
[108,20,13,1],
[108,20,13,2],
[108,20,13,3],
[108,20,13,4],
[108,20,13,5],
[108,20,13,6],
[108,20,13,7],
[108,20,13,8],
[108,20,14,0],
[108,20,14,1],
[108,20,14,2],
[108,20,14,3],
[108,20,14,4],
[108,20,14,5],
[108,20,14,6],
[108,20,14,7],
[108,20,16,0],
[108,20,16,1],
[108,20,16,2],
[108,20,16,3],
[108,20,16,4],
[108,20,17,0],
[108,20,17,1],
[108,20,17,2],
[108,20,17,3],
[108,20,17,4],
[108,21,4,0],
[108,21,4,1],
[108,21,4,2],
[108,21,7,0],
[108,21,7,1],
[108,21,7,2],
[108,21,7,4],
[108,21,10,0],
[108,21,10,1],
[108,21,10,2],
[108,21,10,4],
[108,21,10,7],
[108,21,11,0],
[108,21,11,1],
[108,21,11,2],
[108,21,11,4],
[108,21,11,7],
[108,21,12,0],
[108,21,12,1],
[108,21,12,2],
[108,21,12,4],
[108,21,12,7],
[108,21,13,0],
[108,21,13,1],
[108,21,13,2],
[108,21,13,4],
[108,21,13,7],
[108,21,14,0],
[108,21,14,1],
[108,21,14,2],
[108,21,14,4],
[108,21,16,0],
[108,21,16,1],
[108,21,16,2],
[108,21,17,0],
[108,21,17,1],
[108,21,17,2],
[108,22,3,0],
[108,22,3,1],
[108,22,3,2],
[108,22,4,0],
[108,22,4,1],
[108,22,4,2],
[108,22,4,3],
[108,22,5,0],
[108,22,5,1],
[108,22,5,2],
[108,22,5,4],
[108,22,6,0],
[108,22,6,1],
[108,22,6,2],
[108,22,6,4],
[108,22,7,0],
[108,22,7,1],
[108,22,7,2],
[108,22,7,3],
[108,22,7,4],
[108,22,7,5],
[108,22,7,6],
[108,22,8,0],
[108,22,8,1],
[108,22,8,2],
[108,22,8,4],
[108,22,8,7],
[108,22,11,0],
[108,22,11,1],
[108,22,11,2],
[108,22,11,3],
[108,22,11,4],
[108,22,11,5],
[108,22,11,6],
[108,22,11,7],
[108,22,12,0],
[108,22,12,1],
[108,22,12,2],
[108,22,12,3],
[108,22,12,4],
[108,22,12,5],
[108,22,13,0],
[108,22,13,1],
[108,22,13,2],
[108,22,13,3],
[108,22,13,4],
[108,22,13,5],
[108,22,14,0],
[108,22,14,1],
[108,22,14,2],
[108,22,14,3],
[108,22,14,4],
[108,23,3,0],
[108,23,3,1],
[108,23,3,2],
[108,23,4,0],
[108,23,4,1],
[108,23,4,2],
[108,23,4,3],
[108,23,5,0],
[108,23,5,1],
[108,23,5,2],
[108,23,5,4],
[108,23,6,0],
[108,23,6,1],
[108,23,6,2],
[108,23,6,4],
[108,23,7,0],
[108,23,7,1],
[108,23,7,2],
[108,23,7,3],
[108,23,7,4],
[108,23,7,5],
[108,23,7,6],
[108,23,8,0],
[108,23,8,1],
[108,23,8,2],
[108,23,8,4],
[108,23,8,7],
[108,23,10,0],
[108,23,10,1],
[108,23,10,2],
[108,23,10,3],
[108,23,10,4],
[108,23,10,5],
[108,23,11,0],
[108,23,11,1],
[108,23,11,2],
[108,23,11,3],
[108,23,11,4],
[108,23,11,5],
[108,23,12,0],
[108,23,12,1],
[108,23,12,2],
[108,23,12,3],
[108,23,12,4],
[108,23,13,0],
[108,23,13,1],
[108,23,13,2],
[108,23,13,3],
[108,23,13,4],
[108,23,14,0],
[108,23,14,1],
[108,23,14,2],
[108,24,3,0],
[108,24,3,1],
[108,24,3,2],
[108,24,4,0],
[108,24,4,1],
[108,24,4,2],
[108,24,4,3],
[108,24,5,0],
[108,24,5,1],
[108,24,5,2],
[108,24,5,4],
[108,24,6,0],
[108,24,6,1],
[108,24,6,2],
[108,24,6,4],
[108,24,7,0],
[108,24,7,1],
[108,24,7,2],
[108,24,7,3],
[108,24,7,4],
[108,24,7,5],
[108,24,7,6],
[108,24,8,0],
[108,24,8,1],
[108,24,8,2],
[108,24,8,4],
[108,24,8,7],
[108,24,10,0],
[108,24,10,1],
[108,24,10,2],
[108,24,10,3],
[108,24,10,4],
[108,24,10,5],
[108,24,11,0],
[108,24,11,1],
[108,24,11,2],
[108,24,11,3],
[108,24,11,4],
[108,24,11,5],
[108,24,12,0],
[108,24,12,1],
[108,24,12,2],
[108,24,12,3],
[108,24,12,4],
[108,24,13,0],
[108,24,13,1],
[108,24,13,2],
[108,24,13,3],
[108,24,13,4],
[108,24,14,0],
[108,24,14,1],
[108,24,14,2],
[108,25,3,0],
[108,25,3,1],
[108,25,3,2],
[108,25,4,0],
[108,25,4,1],
[108,25,4,2],
[108,25,4,3],
[108,25,5,0],
[108,25,5,1],
[108,25,5,2],
[108,25,5,4],
[108,25,6,0],
[108,25,6,1],
[108,25,6,2],
[108,25,6,4],
[108,25,8,0],
[108,25,8,1],
[108,25,8,2],
[108,25,8,4],
[108,25,10,0],
[108,25,10,1],
[108,25,10,2],
[108,25,11,0],
[108,25,11,1],
[108,25,11,2],
[108,27,3,0],
[108,27,3,1],
[108,27,3,2],
[108,27,4,0],
[108,27,4,1],
[108,27,4,2],
[108,27,4,3],
[108,27,5,0],
[108,27,5,1],
[108,27,5,2],
[108,27,5,4],
[108,27,6,0],
[108,27,6,1],
[108,27,6,2],
[108,27,7,0],
[108,27,7,1],
[108,27,7,2],
[108,28,3,0],
[108,28,3,1],
[108,28,3,2],
[108,28,4,0],
[108,28,4,1],
[108,28,4,2],
[108,28,4,3],
[108,28,5,0],
[108,28,5,1],
[108,28,5,2],
[108,29,3,0],
[108,29,3,1],
[108,29,3,2],
[108,29,4,0],
[108,29,4,1],
[108,29,4,2],
[109,4,3,0],
[109,4,3,1],
[109,4,3,2],
[109,5,4,0],
[109,5,4,1],
[109,5,4,2],
[109,6,4,0],
[109,6,4,1],
[109,6,4,2],
[109,7,3,0],
[109,7,3,1],
[109,7,3,2],
[109,7,4,0],
[109,7,4,1],
[109,7,4,2],
[109,7,4,3],
[109,7,5,0],
[109,7,5,1],
[109,7,5,2],
[109,7,5,4],
[109,7,6,0],
[109,7,6,1],
[109,7,6,2],
[109,7,6,4],
[109,8,4,0],
[109,8,4,1],
[109,8,4,2],
[109,8,7,0],
[109,8,7,1],
[109,8,7,2],
[109,8,7,4],
[109,10,3,0],
[109,10,3,1],
[109,10,3,2],
[109,10,4,0],
[109,10,4,1],
[109,10,4,2],
[109,10,4,3],
[109,10,5,0],
[109,10,5,1],
[109,10,5,2],
[109,10,5,4],
[109,10,6,0],
[109,10,6,1],
[109,10,6,2],
[109,10,7,0],
[109,10,7,1],
[109,10,7,2],
[109,10,7,5],
[109,10,7,6],
[109,10,8,4],
[109,10,8,7],
[109,11,3,0],
[109,11,3,1],
[109,11,3,2],
[109,11,4,0],
[109,11,4,1],
[109,11,4,2],
[109,11,4,3],
[109,11,5,0],
[109,11,5,1],
[109,11,5,2],
[109,11,5,4],
[109,11,6,0],
[109,11,6,1],
[109,11,6,2],
[109,11,7,0],
[109,11,7,1],
[109,11,7,2],
[109,11,7,5],
[109,11,7,6],
[109,11,8,4],
[109,11,8,7],
[109,11,10,0],
[109,11,10,1],
[109,11,10,2],
[109,11,10,3],
[109,11,10,4],
[109,11,10,5],
[109,11,10,6],
[109,11,10,7],
[109,11,10,8],
[109,12,3,0],
[109,12,3,1],
[109,12,3,2],
[109,12,4,0],
[109,12,4,1],
[109,12,4,2],
[109,12,4,3],
[109,12,5,0],
[109,12,5,1],
[109,12,5,2],
[109,12,6,4],
[109,12,7,3],
[109,12,7,4],
[109,12,7,5],
[109,12,7,6],
[109,12,8,0],
[109,12,8,1],
[109,12,8,2],
[109,12,8,4],
[109,12,8,7],
[109,12,10,0],
[109,12,10,1],
[109,12,10,2],
[109,12,10,3],
[109,12,10,4],
[109,12,10,5],
[109,12,10,6],
[109,12,10,7],
[109,12,10,8],
[109,13,3,0],
[109,13,3,1],
[109,13,3,2],
[109,13,4,0],
[109,13,4,1],
[109,13,4,2],
[109,13,4,3],
[109,13,5,0],
[109,13,5,1],
[109,13,5,2],
[109,13,6,4],
[109,13,7,3],
[109,13,7,4],
[109,13,7,5],
[109,13,7,6],
[109,13,8,0],
[109,13,8,1],
[109,13,8,2],
[109,13,8,4],
[109,13,8,7],
[109,13,10,0],
[109,13,10,1],
[109,13,10,2],
[109,13,10,3],
[109,13,10,4],
[109,13,10,5],
[109,13,10,6],
[109,13,10,7],
[109,13,10,8],
[109,13,11,0],
[109,13,11,1],
[109,13,11,2],
[109,13,11,3],
[109,13,11,4],
[109,13,11,5],
[109,13,11,6],
[109,13,11,7],
[109,13,11,8],
[109,13,11,10],
[109,13,12,0],
[109,13,12,1],
[109,13,12,2],
[109,13,12,3],
[109,13,12,4],
[109,13,12,5],
[109,13,12,6],
[109,13,12,7],
[109,13,12,8],
[109,13,12,10],
[109,14,3,0],
[109,14,3,1],
[109,14,3,2],
[109,14,4,0],
[109,14,4,1],
[109,14,4,2],
[109,14,5,4],
[109,14,6,0],
[109,14,6,1],
[109,14,6,2],
[109,14,6,4],
[109,14,7,0],
[109,14,7,1],
[109,14,7,2],
[109,14,7,3],
[109,14,7,4],
[109,14,7,5],
[109,14,7,6],
[109,14,8,0],
[109,14,8,1],
[109,14,8,2],
[109,14,8,4],
[109,14,8,7],
[109,14,10,0],
[109,14,10,1],
[109,14,10,2],
[109,14,10,3],
[109,14,10,4],
[109,14,10,5],
[109,14,10,6],
[109,14,10,7],
[109,14,10,8],
[109,14,11,0],
[109,14,11,1],
[109,14,11,2],
[109,14,11,3],
[109,14,11,4],
[109,14,11,5],
[109,14,11,6],
[109,14,11,7],
[109,14,11,8],
[109,14,11,10],
[109,14,12,0],
[109,14,12,1],
[109,14,12,2],
[109,14,12,3],
[109,14,12,4],
[109,14,12,5],
[109,14,12,6],
[109,14,12,7],
[109,14,12,8],
[109,14,12,10],
[109,16,3,0],
[109,16,3,1],
[109,16,3,2],
[109,16,4,0],
[109,16,4,1],
[109,16,4,2],
[109,16,4,3],
[109,16,5,0],
[109,16,5,1],
[109,16,5,2],
[109,16,5,4],
[109,16,6,0],
[109,16,6,1],
[109,16,6,2],
[109,16,6,4],
[109,16,7,0],
[109,16,7,1],
[109,16,7,2],
[109,16,7,3],
[109,16,7,4],
[109,16,7,5],
[109,16,7,6],
[109,16,8,0],
[109,16,8,1],
[109,16,8,2],
[109,16,8,4],
[109,16,8,7],
[109,16,10,0],
[109,16,10,1],
[109,16,10,2],
[109,16,10,3],
[109,16,10,4],
[109,16,10,5],
[109,16,10,6],
[109,16,10,7],
[109,16,10,8],
[109,16,11,0],
[109,16,11,1],
[109,16,11,2],
[109,16,11,3],
[109,16,11,4],
[109,16,11,5],
[109,16,11,6],
[109,16,11,7],
[109,16,11,8],
[109,16,11,10],
[109,16,12,0],
[109,16,12,1],
[109,16,12,2],
[109,16,12,3],
[109,16,12,4],
[109,16,12,5],
[109,16,12,6],
[109,16,12,7],
[109,16,12,8],
[109,16,12,10],
[109,16,13,0],
[109,16,13,1],
[109,16,13,2],
[109,16,13,3],
[109,16,13,4],
[109,16,13,5],
[109,16,13,6],
[109,16,13,7],
[109,16,13,8],
[109,16,13,10],
[109,16,13,11],
[109,16,13,12],
[109,16,14,0],
[109,16,14,1],
[109,16,14,2],
[109,16,14,3],
[109,16,14,4],
[109,16,14,5],
[109,16,14,6],
[109,16,14,7],
[109,16,14,8],
[109,16,14,10],
[109,16,14,11],
[109,17,3,0],
[109,17,3,1],
[109,17,3,2],
[109,17,4,0],
[109,17,4,1],
[109,17,4,2],
[109,17,4,3],
[109,17,5,0],
[109,17,5,1],
[109,17,5,2],
[109,17,5,4],
[109,17,6,0],
[109,17,6,1],
[109,17,6,2],
[109,17,6,4],
[109,17,7,0],
[109,17,7,1],
[109,17,7,2],
[109,17,7,3],
[109,17,7,4],
[109,17,7,5],
[109,17,7,6],
[109,17,8,0],
[109,17,8,1],
[109,17,8,2],
[109,17,8,4],
[109,17,8,7],
[109,17,10,0],
[109,17,10,1],
[109,17,10,2],
[109,17,10,3],
[109,17,10,4],
[109,17,10,5],
[109,17,10,6],
[109,17,10,7],
[109,17,10,8],
[109,17,11,0],
[109,17,11,1],
[109,17,11,2],
[109,17,11,3],
[109,17,11,4],
[109,17,11,5],
[109,17,11,6],
[109,17,11,7],
[109,17,11,8],
[109,17,11,10],
[109,17,12,0],
[109,17,12,1],
[109,17,12,2],
[109,17,12,3],
[109,17,12,4],
[109,17,12,5],
[109,17,12,6],
[109,17,12,7],
[109,17,12,8],
[109,17,12,10],
[109,17,13,0],
[109,17,13,1],
[109,17,13,2],
[109,17,13,3],
[109,17,13,4],
[109,17,13,5],
[109,17,13,6],
[109,17,13,7],
[109,17,13,8],
[109,17,13,10],
[109,17,13,11],
[109,17,13,12],
[109,17,14,0],
[109,17,14,1],
[109,17,14,2],
[109,17,14,3],
[109,17,14,4],
[109,17,14,5],
[109,17,14,6],
[109,17,14,7],
[109,17,14,8],
[109,17,14,10],
[109,17,14,11],
[109,17,16,0],
[109,17,16,1],
[109,17,16,2],
[109,17,16,3],
[109,17,16,4],
[109,17,16,5],
[109,17,16,6],
[109,17,16,7],
[109,19,3,0],
[109,19,3,1],
[109,19,3,2],
[109,19,4,0],
[109,19,4,1],
[109,19,4,2],
[109,19,4,3],
[109,19,5,0],
[109,19,5,1],
[109,19,5,2],
[109,19,5,4],
[109,19,6,0],
[109,19,6,1],
[109,19,6,2],
[109,19,6,4],
[109,19,7,0],
[109,19,7,1],
[109,19,7,2],
[109,19,7,3],
[109,19,7,4],
[109,19,7,5],
[109,19,7,6],
[109,19,8,0],
[109,19,8,1],
[109,19,8,2],
[109,19,8,4],
[109,19,8,7],
[109,19,10,0],
[109,19,10,1],
[109,19,10,2],
[109,19,10,3],
[109,19,10,4],
[109,19,10,5],
[109,19,10,6],
[109,19,10,7],
[109,19,10,8],
[109,19,11,0],
[109,19,11,1],
[109,19,11,2],
[109,19,11,3],
[109,19,11,4],
[109,19,11,5],
[109,19,11,6],
[109,19,11,7],
[109,19,11,8],
[109,19,11,10],
[109,19,12,0],
[109,19,12,1],
[109,19,12,2],
[109,19,12,3],
[109,19,12,4],
[109,19,12,5],
[109,19,12,6],
[109,19,12,7],
[109,19,12,8],
[109,19,16,0],
[109,19,16,1],
[109,19,16,2],
[109,19,16,3],
[109,19,16,4],
[109,19,17,0],
[109,19,17,1],
[109,19,17,2],
[109,19,17,3],
[109,19,17,4],
[109,20,3,0],
[109,20,3,1],
[109,20,3,2],
[109,20,4,0],
[109,20,4,1],
[109,20,4,2],
[109,20,4,3],
[109,20,5,0],
[109,20,5,1],
[109,20,5,2],
[109,20,5,4],
[109,20,6,0],
[109,20,6,1],
[109,20,6,2],
[109,20,6,4],
[109,20,7,0],
[109,20,7,1],
[109,20,7,2],
[109,20,7,3],
[109,20,7,4],
[109,20,7,5],
[109,20,7,6],
[109,20,8,0],
[109,20,8,1],
[109,20,8,2],
[109,20,8,4],
[109,20,8,7],
[109,20,11,0],
[109,20,11,1],
[109,20,11,2],
[109,20,11,3],
[109,20,11,4],
[109,20,11,5],
[109,20,11,6],
[109,20,11,7],
[109,20,11,8],
[109,20,12,0],
[109,20,12,1],
[109,20,12,2],
[109,20,12,3],
[109,20,12,4],
[109,20,12,5],
[109,20,12,6],
[109,20,12,7],
[109,20,13,0],
[109,20,13,1],
[109,20,13,2],
[109,20,13,3],
[109,20,13,4],
[109,20,13,5],
[109,20,13,6],
[109,20,13,7],
[109,20,14,0],
[109,20,14,1],
[109,20,14,2],
[109,20,14,3],
[109,20,14,4],
[109,20,14,5],
[109,20,16,0],
[109,20,16,1],
[109,20,16,2],
[109,20,17,0],
[109,20,17,1],
[109,20,17,2],
[109,21,4,0],
[109,21,4,1],
[109,21,4,2],
[109,21,7,0],
[109,21,7,1],
[109,21,7,2],
[109,21,7,4],
[109,21,10,0],
[109,21,10,1],
[109,21,10,2],
[109,21,10,4],
[109,21,10,7],
[109,21,11,0],
[109,21,11,1],
[109,21,11,2],
[109,21,11,4],
[109,21,11,7],
[109,21,12,0],
[109,21,12,1],
[109,21,12,2],
[109,21,12,4],
[109,21,13,0],
[109,21,13,1],
[109,21,13,2],
[109,21,13,4],
[109,21,14,0],
[109,21,14,1],
[109,21,14,2],
[109,21,14,4],
[109,22,3,0],
[109,22,3,1],
[109,22,3,2],
[109,22,4,0],
[109,22,4,1],
[109,22,4,2],
[109,22,4,3],
[109,22,5,0],
[109,22,5,1],
[109,22,5,2],
[109,22,5,4],
[109,22,6,0],
[109,22,6,1],
[109,22,6,2],
[109,22,6,4],
[109,22,7,0],
[109,22,7,1],
[109,22,7,2],
[109,22,7,3],
[109,22,7,4],
[109,22,7,5],
[109,22,7,6],
[109,22,8,0],
[109,22,8,1],
[109,22,8,2],
[109,22,8,4],
[109,22,8,7],
[109,22,11,0],
[109,22,11,1],
[109,22,11,2],
[109,22,11,3],
[109,22,11,4],
[109,22,11,5],
[109,22,12,0],
[109,22,12,1],
[109,22,12,2],
[109,22,12,3],
[109,22,12,4],
[109,22,13,0],
[109,22,13,1],
[109,22,13,2],
[109,22,13,3],
[109,22,13,4],
[109,22,14,0],
[109,22,14,1],
[109,22,14,2],
[109,23,3,0],
[109,23,3,1],
[109,23,3,2],
[109,23,4,0],
[109,23,4,1],
[109,23,4,2],
[109,23,4,3],
[109,23,5,0],
[109,23,5,1],
[109,23,5,2],
[109,23,5,4],
[109,23,6,0],
[109,23,6,1],
[109,23,6,2],
[109,23,6,4],
[109,23,7,0],
[109,23,7,1],
[109,23,7,2],
[109,23,7,3],
[109,23,7,4],
[109,23,7,5],
[109,23,7,6],
[109,23,8,0],
[109,23,8,1],
[109,23,8,2],
[109,23,8,4],
[109,23,10,0],
[109,23,10,1],
[109,23,10,2],
[109,23,10,3],
[109,23,10,4],
[109,23,11,0],
[109,23,11,1],
[109,23,11,2],
[109,23,11,3],
[109,23,11,4],
[109,23,12,0],
[109,23,12,1],
[109,23,12,2],
[109,23,13,0],
[109,23,13,1],
[109,23,13,2],
[109,24,3,0],
[109,24,3,1],
[109,24,3,2],
[109,24,4,0],
[109,24,4,1],
[109,24,4,2],
[109,24,4,3],
[109,24,5,0],
[109,24,5,1],
[109,24,5,2],
[109,24,5,4],
[109,24,6,0],
[109,24,6,1],
[109,24,6,2],
[109,24,6,4],
[109,24,7,0],
[109,24,7,1],
[109,24,7,2],
[109,24,7,3],
[109,24,7,4],
[109,24,7,5],
[109,24,7,6],
[109,24,8,0],
[109,24,8,1],
[109,24,8,2],
[109,24,8,4],
[109,24,10,0],
[109,24,10,1],
[109,24,10,2],
[109,24,10,3],
[109,24,10,4],
[109,24,11,0],
[109,24,11,1],
[109,24,11,2],
[109,24,11,3],
[109,24,11,4],
[109,24,12,0],
[109,24,12,1],
[109,24,12,2],
[109,24,13,0],
[109,24,13,1],
[109,24,13,2],
[109,25,3,0],
[109,25,3,1],
[109,25,3,2],
[109,25,4,0],
[109,25,4,1],
[109,25,4,2],
[109,25,4,3],
[109,25,5,0],
[109,25,5,1],
[109,25,5,2],
[109,25,5,4],
[109,25,6,0],
[109,25,6,1],
[109,25,6,2],
[109,25,6,4],
[109,25,8,0],
[109,25,8,1],
[109,25,8,2],
[109,27,3,0],
[109,27,3,1],
[109,27,3,2],
[109,27,4,0],
[109,27,4,1],
[109,27,4,2],
[109,27,4,3],
[109,27,5,0],
[109,27,5,1],
[109,27,5,2],
[109,28,3,0],
[109,28,3,1],
[109,28,3,2],
[109,28,4,0],
[109,28,4,1],
[109,28,4,2],
[110,4,3,0],
[110,4,3,1],
[110,4,3,2],
[110,5,4,0],
[110,5,4,1],
[110,5,4,2],
[110,6,4,0],
[110,6,4,1],
[110,6,4,2],
[110,7,3,0],
[110,7,3,1],
[110,7,3,2],
[110,7,4,0],
[110,7,4,1],
[110,7,4,2],
[110,7,4,3],
[110,7,5,0],
[110,7,5,1],
[110,7,5,2],
[110,7,5,4],
[110,7,6,0],
[110,7,6,1],
[110,7,6,2],
[110,7,6,4],
[110,8,4,0],
[110,8,4,1],
[110,8,4,2],
[110,8,7,0],
[110,8,7,1],
[110,8,7,2],
[110,9,3,0],
[110,9,3,1],
[110,9,3,2],
[110,9,4,0],
[110,9,4,1],
[110,9,4,2],
[110,9,4,3],
[110,9,5,0],
[110,9,5,1],
[110,9,5,2],
[110,9,5,4],
[110,9,6,0],
[110,9,6,1],
[110,9,6,2],
[110,9,7,0],
[110,9,7,1],
[110,9,7,2],
[110,9,7,5],
[110,9,7,6],
[110,9,8,4],
[110,9,8,7],
[110,10,3,0],
[110,10,3,1],
[110,10,3,2],
[110,10,4,0],
[110,10,4,1],
[110,10,4,2],
[110,10,4,3],
[110,10,5,0],
[110,10,5,1],
[110,10,5,2],
[110,10,6,4],
[110,10,7,3],
[110,10,7,4],
[110,10,7,5],
[110,10,7,6],
[110,10,8,0],
[110,10,8,1],
[110,10,8,2],
[110,10,8,4],
[110,10,8,7],
[110,10,9,0],
[110,10,9,1],
[110,10,9,2],
[110,10,9,3],
[110,10,9,4],
[110,10,9,5],
[110,10,9,6],
[110,10,9,7],
[110,10,9,8],
[110,11,3,0],
[110,11,3,1],
[110,11,3,2],
[110,11,4,0],
[110,11,4,1],
[110,11,4,2],
[110,11,4,3],
[110,11,5,0],
[110,11,5,1],
[110,11,5,2],
[110,11,6,4],
[110,11,7,3],
[110,11,7,4],
[110,11,7,5],
[110,11,7,6],
[110,11,8,0],
[110,11,8,1],
[110,11,8,2],
[110,11,8,4],
[110,11,8,7],
[110,11,9,0],
[110,11,9,1],
[110,11,9,2],
[110,11,9,3],
[110,11,9,4],
[110,11,9,5],
[110,11,9,6],
[110,11,9,7],
[110,11,9,8],
[110,11,10,0],
[110,11,10,1],
[110,11,10,2],
[110,11,10,3],
[110,11,10,4],
[110,11,10,5],
[110,11,10,6],
[110,11,10,7],
[110,11,10,8],
[110,11,10,9],
[110,12,3,0],
[110,12,3,1],
[110,12,3,2],
[110,12,4,0],
[110,12,4,1],
[110,12,4,2],
[110,12,5,4],
[110,12,6,0],
[110,12,6,1],
[110,12,6,2],
[110,12,6,4],
[110,12,7,0],
[110,12,7,1],
[110,12,7,2],
[110,12,7,3],
[110,12,7,4],
[110,12,7,5],
[110,12,7,6],
[110,12,8,0],
[110,12,8,1],
[110,12,8,2],
[110,12,8,4],
[110,12,8,7],
[110,12,9,0],
[110,12,9,1],
[110,12,9,2],
[110,12,9,3],
[110,12,9,4],
[110,12,9,5],
[110,12,9,6],
[110,12,9,7],
[110,12,9,8],
[110,12,10,0],
[110,12,10,1],
[110,12,10,2],
[110,12,10,3],
[110,12,10,4],
[110,12,10,5],
[110,12,10,6],
[110,12,10,7],
[110,12,10,8],
[110,12,10,9],
[110,13,3,0],
[110,13,3,1],
[110,13,3,2],
[110,13,4,0],
[110,13,4,1],
[110,13,4,2],
[110,13,5,4],
[110,13,6,0],
[110,13,6,1],
[110,13,6,2],
[110,13,6,4],
[110,13,7,0],
[110,13,7,1],
[110,13,7,2],
[110,13,7,3],
[110,13,7,4],
[110,13,7,5],
[110,13,7,6],
[110,13,8,0],
[110,13,8,1],
[110,13,8,2],
[110,13,8,4],
[110,13,8,7],
[110,13,9,0],
[110,13,9,1],
[110,13,9,2],
[110,13,9,3],
[110,13,9,4],
[110,13,9,5],
[110,13,9,6],
[110,13,9,7],
[110,13,9,8],
[110,13,10,0],
[110,13,10,1],
[110,13,10,2],
[110,13,10,3],
[110,13,10,4],
[110,13,10,5],
[110,13,10,6],
[110,13,10,7],
[110,13,10,8],
[110,13,10,9],
[110,13,11,0],
[110,13,11,1],
[110,13,11,2],
[110,13,11,3],
[110,13,11,4],
[110,13,11,5],
[110,13,11,6],
[110,13,11,7],
[110,13,11,8],
[110,13,11,9],
[110,13,11,10],
[110,13,12,0],
[110,13,12,1],
[110,13,12,2],
[110,13,12,3],
[110,13,12,4],
[110,13,12,5],
[110,13,12,6],
[110,13,12,7],
[110,13,12,8],
[110,13,12,9],
[110,13,12,10],
[110,14,4,3],
[110,14,5,0],
[110,14,5,1],
[110,14,5,2],
[110,14,5,4],
[110,14,6,0],
[110,14,6,1],
[110,14,6,2],
[110,14,6,4],
[110,14,7,0],
[110,14,7,1],
[110,14,7,2],
[110,14,7,3],
[110,14,7,4],
[110,14,7,5],
[110,14,7,6],
[110,14,8,0],
[110,14,8,1],
[110,14,8,2],
[110,14,8,4],
[110,14,8,7],
[110,14,9,0],
[110,14,9,1],
[110,14,9,2],
[110,14,9,3],
[110,14,9,4],
[110,14,9,5],
[110,14,9,6],
[110,14,9,7],
[110,14,9,8],
[110,14,10,0],
[110,14,10,1],
[110,14,10,2],
[110,14,10,3],
[110,14,10,4],
[110,14,10,5],
[110,14,10,6],
[110,14,10,7],
[110,14,10,8],
[110,14,10,9],
[110,14,11,0],
[110,14,11,1],
[110,14,11,2],
[110,14,11,3],
[110,14,11,4],
[110,14,11,5],
[110,14,11,6],
[110,14,11,7],
[110,14,11,8],
[110,14,11,9],
[110,14,11,10],
[110,14,12,0],
[110,14,12,1],
[110,14,12,2],
[110,14,12,3],
[110,14,12,4],
[110,14,12,5],
[110,14,12,6],
[110,14,12,7],
[110,14,12,8],
[110,14,12,9],
[110,14,12,10],
[110,15,3,0],
[110,15,3,1],
[110,15,3,2],
[110,15,4,0],
[110,15,4,1],
[110,15,4,2],
[110,15,4,3],
[110,15,5,0],
[110,15,5,1],
[110,15,5,2],
[110,15,5,4],
[110,15,6,0],
[110,15,6,1],
[110,15,6,2],
[110,15,6,4],
[110,15,7,0],
[110,15,7,1],
[110,15,7,2],
[110,15,7,3],
[110,15,7,4],
[110,15,7,5],
[110,15,7,6],
[110,15,8,0],
[110,15,8,1],
[110,15,8,2],
[110,15,8,4],
[110,15,8,7],
[110,15,10,0],
[110,15,10,1],
[110,15,10,2],
[110,15,10,3],
[110,15,10,4],
[110,15,10,5],
[110,15,10,6],
[110,15,10,7],
[110,15,10,8],
[110,15,11,0],
[110,15,11,1],
[110,15,11,2],
[110,15,11,3],
[110,15,11,4],
[110,15,11,5],
[110,15,11,6],
[110,15,11,7],
[110,15,11,8],
[110,15,11,10],
[110,15,12,0],
[110,15,12,1],
[110,15,12,2],
[110,15,12,3],
[110,15,12,4],
[110,15,12,5],
[110,15,12,6],
[110,15,12,7],
[110,15,12,8],
[110,15,12,10],
[110,15,13,0],
[110,15,13,1],
[110,15,13,2],
[110,15,13,3],
[110,15,13,4],
[110,15,13,5],
[110,15,13,6],
[110,15,13,7],
[110,15,13,8],
[110,15,13,10],
[110,15,13,11],
[110,15,13,12],
[110,15,14,0],
[110,15,14,1],
[110,15,14,2],
[110,15,14,3],
[110,15,14,4],
[110,15,14,5],
[110,15,14,6],
[110,15,14,7],
[110,15,14,8],
[110,15,14,10],
[110,15,14,11],
[110,16,3,0],
[110,16,3,1],
[110,16,3,2],
[110,16,4,0],
[110,16,4,1],
[110,16,4,2],
[110,16,4,3],
[110,16,5,0],
[110,16,5,1],
[110,16,5,2],
[110,16,5,4],
[110,16,6,0],
[110,16,6,1],
[110,16,6,2],
[110,16,6,4],
[110,16,7,0],
[110,16,7,1],
[110,16,7,2],
[110,16,7,3],
[110,16,7,4],
[110,16,7,5],
[110,16,7,6],
[110,16,8,0],
[110,16,8,1],
[110,16,8,2],
[110,16,8,4],
[110,16,8,7],
[110,16,9,0],
[110,16,9,1],
[110,16,9,2],
[110,16,9,3],
[110,16,9,4],
[110,16,9,5],
[110,16,9,6],
[110,16,9,7],
[110,16,9,8],
[110,16,10,0],
[110,16,10,1],
[110,16,10,2],
[110,16,10,3],
[110,16,10,4],
[110,16,10,5],
[110,16,10,6],
[110,16,10,7],
[110,16,10,8],
[110,16,10,9],
[110,16,11,0],
[110,16,11,1],
[110,16,11,2],
[110,16,11,3],
[110,16,11,4],
[110,16,11,5],
[110,16,11,6],
[110,16,11,7],
[110,16,11,8],
[110,16,11,9],
[110,16,11,10],
[110,16,12,0],
[110,16,12,1],
[110,16,12,2],
[110,16,12,3],
[110,16,12,4],
[110,16,12,5],
[110,16,12,6],
[110,16,12,7],
[110,16,12,8],
[110,16,12,9],
[110,16,12,10],
[110,16,13,0],
[110,16,13,1],
[110,16,13,2],
[110,16,13,3],
[110,16,13,4],
[110,16,13,5],
[110,16,13,6],
[110,16,13,7],
[110,16,13,8],
[110,16,13,9],
[110,16,13,10],
[110,16,13,11],
[110,16,14,0],
[110,16,14,1],
[110,16,14,2],
[110,16,14,3],
[110,16,14,4],
[110,16,14,5],
[110,16,14,6],
[110,16,14,7],
[110,16,14,8],
[110,16,14,9],
[110,16,15,0],
[110,16,15,1],
[110,16,15,2],
[110,16,15,3],
[110,16,15,4],
[110,16,15,5],
[110,16,15,6],
[110,16,15,7],
[110,17,3,0],
[110,17,3,1],
[110,17,3,2],
[110,17,4,0],
[110,17,4,1],
[110,17,4,2],
[110,17,4,3],
[110,17,5,0],
[110,17,5,1],
[110,17,5,2],
[110,17,5,4],
[110,17,6,0],
[110,17,6,1],
[110,17,6,2],
[110,17,6,4],
[110,17,7,0],
[110,17,7,1],
[110,17,7,2],
[110,17,7,3],
[110,17,7,4],
[110,17,7,5],
[110,17,7,6],
[110,17,8,0],
[110,17,8,1],
[110,17,8,2],
[110,17,8,4],
[110,17,8,7],
[110,17,9,0],
[110,17,9,1],
[110,17,9,2],
[110,17,9,3],
[110,17,9,4],
[110,17,9,5],
[110,17,9,6],
[110,17,9,7],
[110,17,9,8],
[110,17,10,0],
[110,17,10,1],
[110,17,10,2],
[110,17,10,3],
[110,17,10,4],
[110,17,10,5],
[110,17,10,6],
[110,17,10,7],
[110,17,10,8],
[110,17,10,9],
[110,17,11,0],
[110,17,11,1],
[110,17,11,2],
[110,17,11,3],
[110,17,11,4],
[110,17,11,5],
[110,17,11,6],
[110,17,11,7],
[110,17,11,8],
[110,17,11,9],
[110,17,11,10],
[110,17,12,0],
[110,17,12,1],
[110,17,12,2],
[110,17,12,3],
[110,17,12,4],
[110,17,12,5],
[110,17,12,6],
[110,17,12,7],
[110,17,12,8],
[110,17,12,9],
[110,17,12,10],
[110,17,13,0],
[110,17,13,1],
[110,17,13,2],
[110,17,13,3],
[110,17,13,4],
[110,17,13,5],
[110,17,13,6],
[110,17,13,7],
[110,17,13,8],
[110,17,13,9],
[110,17,13,10],
[110,17,13,11],
[110,17,14,0],
[110,17,14,1],
[110,17,14,2],
[110,17,14,3],
[110,17,14,4],
[110,17,14,5],
[110,17,14,6],
[110,17,14,7],
[110,17,14,8],
[110,17,14,9],
[110,17,15,0],
[110,17,15,1],
[110,17,15,2],
[110,17,15,3],
[110,17,15,4],
[110,17,15,5],
[110,17,15,6],
[110,17,15,7],
[110,17,16,0],
[110,17,16,1],
[110,17,16,2],
[110,17,16,3],
[110,17,16,4],
[110,17,16,5],
[110,18,3,0],
[110,18,3,1],
[110,18,3,2],
[110,18,4,0],
[110,18,4,1],
[110,18,4,2],
[110,18,4,3],
[110,18,5,0],
[110,18,5,1],
[110,18,5,2],
[110,18,5,4],
[110,18,6,0],
[110,18,6,1],
[110,18,6,2],
[110,18,6,4],
[110,18,7,0],
[110,18,7,1],
[110,18,7,2],
[110,18,7,3],
[110,18,7,4],
[110,18,7,5],
[110,18,7,6],
[110,18,8,0],
[110,18,8,1],
[110,18,8,2],
[110,18,8,4],
[110,18,8,7],
[110,18,10,0],
[110,18,10,1],
[110,18,10,2],
[110,18,10,3],
[110,18,10,4],
[110,18,10,5],
[110,18,10,6],
[110,18,10,7],
[110,18,10,8],
[110,18,11,0],
[110,18,11,1],
[110,18,11,2],
[110,18,11,3],
[110,18,11,4],
[110,18,11,5],
[110,18,11,6],
[110,18,11,7],
[110,18,11,8],
[110,18,11,10],
[110,18,12,0],
[110,18,12,1],
[110,18,12,2],
[110,18,12,3],
[110,18,12,4],
[110,18,12,5],
[110,18,12,6],
[110,18,12,7],
[110,18,12,8],
[110,18,13,0],
[110,18,13,1],
[110,18,13,2],
[110,18,13,3],
[110,18,13,4],
[110,18,13,5],
[110,18,13,6],
[110,18,13,7],
[110,18,13,8],
[110,18,14,0],
[110,18,14,1],
[110,18,14,2],
[110,18,14,3],
[110,18,14,4],
[110,18,14,5],
[110,18,14,6],
[110,18,14,7],
[110,18,16,0],
[110,18,16,1],
[110,18,16,2],
[110,18,16,3],
[110,18,16,4],
[110,18,17,0],
[110,18,17,1],
[110,18,17,2],
[110,18,17,3],
[110,18,17,4],
[110,19,3,0],
[110,19,3,1],
[110,19,3,2],
[110,19,4,0],
[110,19,4,1],
[110,19,4,2],
[110,19,4,3],
[110,19,5,0],
[110,19,5,1],
[110,19,5,2],
[110,19,5,4],
[110,19,6,0],
[110,19,6,1],
[110,19,6,2],
[110,19,6,4],
[110,19,7,0],
[110,19,7,1],
[110,19,7,2],
[110,19,7,3],
[110,19,7,4],
[110,19,7,5],
[110,19,7,6],
[110,19,8,0],
[110,19,8,1],
[110,19,8,2],
[110,19,8,4],
[110,19,8,7],
[110,19,9,0],
[110,19,9,1],
[110,19,9,2],
[110,19,9,3],
[110,19,9,4],
[110,19,9,5],
[110,19,9,6],
[110,19,9,7],
[110,19,9,8],
[110,19,10,0],
[110,19,10,1],
[110,19,10,2],
[110,19,10,3],
[110,19,10,4],
[110,19,10,5],
[110,19,10,6],
[110,19,10,7],
[110,19,10,8],
[110,19,10,9],
[110,19,11,0],
[110,19,11,1],
[110,19,11,2],
[110,19,11,3],
[110,19,11,4],
[110,19,11,5],
[110,19,11,6],
[110,19,11,7],
[110,19,11,8],
[110,19,11,9],
[110,19,12,0],
[110,19,12,1],
[110,19,12,2],
[110,19,12,3],
[110,19,12,4],
[110,19,12,5],
[110,19,12,6],
[110,19,12,7],
[110,19,15,0],
[110,19,15,1],
[110,19,15,2],
[110,19,15,3],
[110,19,15,4],
[110,19,16,0],
[110,19,16,1],
[110,19,16,2],
[110,19,17,0],
[110,19,17,1],
[110,19,17,2],
[110,20,3,0],
[110,20,3,1],
[110,20,3,2],
[110,20,4,0],
[110,20,4,1],
[110,20,4,2],
[110,20,4,3],
[110,20,5,0],
[110,20,5,1],
[110,20,5,2],
[110,20,5,4],
[110,20,6,0],
[110,20,6,1],
[110,20,6,2],
[110,20,6,4],
[110,20,7,0],
[110,20,7,1],
[110,20,7,2],
[110,20,7,3],
[110,20,7,4],
[110,20,7,5],
[110,20,7,6],
[110,20,8,0],
[110,20,8,1],
[110,20,8,2],
[110,20,8,4],
[110,20,8,7],
[110,20,9,0],
[110,20,9,1],
[110,20,9,2],
[110,20,9,3],
[110,20,9,4],
[110,20,9,5],
[110,20,9,6],
[110,20,9,7],
[110,20,9,8],
[110,20,11,0],
[110,20,11,1],
[110,20,11,2],
[110,20,11,3],
[110,20,11,4],
[110,20,11,5],
[110,20,11,6],
[110,20,11,7],
[110,20,12,0],
[110,20,12,1],
[110,20,12,2],
[110,20,12,3],
[110,20,12,4],
[110,20,12,5],
[110,20,13,0],
[110,20,13,1],
[110,20,13,2],
[110,20,13,3],
[110,20,13,4],
[110,20,13,5],
[110,20,14,0],
[110,20,14,1],
[110,20,14,2],
[110,20,14,3],
[110,20,14,4],
[110,20,15,0],
[110,20,15,1],
[110,20,15,2],
[110,21,4,0],
[110,21,4,1],
[110,21,4,2],
[110,21,7,0],
[110,21,7,1],
[110,21,7,2],
[110,21,7,4],
[110,21,9,0],
[110,21,9,1],
[110,21,9,2],
[110,21,9,4],
[110,21,9,7],
[110,21,10,0],
[110,21,10,1],
[110,21,10,2],
[110,21,10,4],
[110,21,11,0],
[110,21,11,1],
[110,21,11,2],
[110,21,11,4],
[110,21,12,0],
[110,21,12,1],
[110,21,12,2],
[110,21,12,4],
[110,21,13,0],
[110,21,13,1],
[110,21,13,2],
[110,21,13,4],
[110,21,14,0],
[110,21,14,1],
[110,21,14,2],
[110,22,3,0],
[110,22,3,1],
[110,22,3,2],
[110,22,4,0],
[110,22,4,1],
[110,22,4,2],
[110,22,4,3],
[110,22,5,0],
[110,22,5,1],
[110,22,5,2],
[110,22,5,4],
[110,22,6,0],
[110,22,6,1],
[110,22,6,2],
[110,22,6,4],
[110,22,7,0],
[110,22,7,1],
[110,22,7,2],
[110,22,7,3],
[110,22,7,4],
[110,22,7,5],
[110,22,7,6],
[110,22,8,0],
[110,22,8,1],
[110,22,8,2],
[110,22,8,4],
[110,22,9,0],
[110,22,9,1],
[110,22,9,2],
[110,22,9,3],
[110,22,9,4],
[110,22,9,5],
[110,22,11,0],
[110,22,11,1],
[110,22,11,2],
[110,22,11,3],
[110,22,11,4],
[110,22,12,0],
[110,22,12,1],
[110,22,12,2],
[110,22,13,0],
[110,22,13,1],
[110,22,13,2],
[110,24,3,0],
[110,24,3,1],
[110,24,3,2],
[110,24,4,0],
[110,24,4,1],
[110,24,4,2],
[110,24,4,3],
[110,24,5,0],
[110,24,5,1],
[110,24,5,2],
[110,24,5,4],
[110,24,6,0],
[110,24,6,1],
[110,24,6,2],
[110,24,6,4],
[110,24,7,0],
[110,24,7,1],
[110,24,7,2],
[110,24,7,3],
[110,24,7,4],
[110,24,7,5],
[110,24,8,0],
[110,24,8,1],
[110,24,8,2],
[110,24,8,4],
[110,24,9,0],
[110,24,9,1],
[110,24,9,2],
[110,24,9,3],
[110,24,9,4],
[110,24,10,0],
[110,24,10,1],
[110,24,10,2],
[110,24,11,0],
[110,24,11,1],
[110,24,11,2],
[110,25,3,0],
[110,25,3,1],
[110,25,3,2],
[110,25,4,0],
[110,25,4,1],
[110,25,4,2],
[110,25,4,3],
[110,25,5,0],
[110,25,5,1],
[110,25,5,2],
[110,25,5,4],
[110,25,6,0],
[110,25,6,1],
[110,25,6,2],
[110,26,3,0],
[110,26,3,1],
[110,26,3,2],
[110,26,4,0],
[110,26,4,1],
[110,26,4,2],
[110,26,4,3],
[110,26,5,0],
[110,26,5,1],
[110,26,5,2],
[110,27,3,0],
[110,27,3,1],
[110,27,3,2],
[110,27,4,0],
[110,27,4,1],
[110,27,4,2],
[111,4,3,0],
[111,4,3,1],
[111,4,3,2],
[111,5,4,0],
[111,5,4,1],
[111,5,4,2],
[111,6,4,0],
[111,6,4,1],
[111,6,4,2],
[111,7,3,0],
[111,7,3,1],
[111,7,3,2],
[111,7,4,0],
[111,7,4,1],
[111,7,4,2],
[111,7,4,3],
[111,7,5,0],
[111,7,5,1],
[111,7,5,2],
[111,7,5,4],
[111,7,6,0],
[111,7,6,1],
[111,7,6,2],
[111,8,4,0],
[111,8,4,1],
[111,8,4,2],
[111,8,7,4],
[111,9,3,0],
[111,9,3,1],
[111,9,3,2],
[111,9,4,0],
[111,9,4,1],
[111,9,4,2],
[111,9,4,3],
[111,9,5,0],
[111,9,5,1],
[111,9,5,2],
[111,9,6,4],
[111,9,7,3],
[111,9,7,4],
[111,9,7,5],
[111,9,7,6],
[111,9,8,0],
[111,9,8,1],
[111,9,8,2],
[111,9,8,4],
[111,9,8,7],
[111,10,3,0],
[111,10,3,1],
[111,10,3,2],
[111,10,4,0],
[111,10,4,1],
[111,10,4,2],
[111,10,5,4],
[111,10,6,0],
[111,10,6,1],
[111,10,6,2],
[111,10,6,4],
[111,10,7,0],
[111,10,7,1],
[111,10,7,2],
[111,10,7,3],
[111,10,7,4],
[111,10,7,5],
[111,10,7,6],
[111,10,8,0],
[111,10,8,1],
[111,10,8,2],
[111,10,8,4],
[111,10,8,7],
[111,10,9,0],
[111,10,9,1],
[111,10,9,2],
[111,10,9,3],
[111,10,9,4],
[111,10,9,5],
[111,10,9,6],
[111,10,9,7],
[111,10,9,8],
[111,11,3,0],
[111,11,3,1],
[111,11,3,2],
[111,11,4,0],
[111,11,4,1],
[111,11,4,2],
[111,11,5,4],
[111,11,6,0],
[111,11,6,1],
[111,11,6,2],
[111,11,6,4],
[111,11,7,0],
[111,11,7,1],
[111,11,7,2],
[111,11,7,3],
[111,11,7,4],
[111,11,7,5],
[111,11,7,6],
[111,11,8,0],
[111,11,8,1],
[111,11,8,2],
[111,11,8,4],
[111,11,8,7],
[111,11,9,0],
[111,11,9,1],
[111,11,9,2],
[111,11,9,3],
[111,11,9,4],
[111,11,9,5],
[111,11,9,6],
[111,11,9,7],
[111,11,9,8],
[111,11,10,0],
[111,11,10,1],
[111,11,10,2],
[111,11,10,3],
[111,11,10,4],
[111,11,10,5],
[111,11,10,6],
[111,11,10,7],
[111,11,10,8],
[111,11,10,9],
[111,12,4,3],
[111,12,5,0],
[111,12,5,1],
[111,12,5,2],
[111,12,5,4],
[111,12,6,0],
[111,12,6,1],
[111,12,6,2],
[111,12,6,4],
[111,12,7,0],
[111,12,7,1],
[111,12,7,2],
[111,12,7,3],
[111,12,7,4],
[111,12,7,5],
[111,12,7,6],
[111,12,8,0],
[111,12,8,1],
[111,12,8,2],
[111,12,8,4],
[111,12,8,7],
[111,12,9,0],
[111,12,9,1],
[111,12,9,2],
[111,12,9,3],
[111,12,9,4],
[111,12,9,5],
[111,12,9,6],
[111,12,9,7],
[111,12,9,8],
[111,12,10,0],
[111,12,10,1],
[111,12,10,2],
[111,12,10,3],
[111,12,10,4],
[111,12,10,5],
[111,12,10,6],
[111,12,10,7],
[111,12,10,8],
[111,12,10,9],
[111,13,4,3],
[111,13,5,0],
[111,13,5,1],
[111,13,5,2],
[111,13,5,4],
[111,13,6,0],
[111,13,6,1],
[111,13,6,2],
[111,13,6,4],
[111,13,7,0],
[111,13,7,1],
[111,13,7,2],
[111,13,7,3],
[111,13,7,4],
[111,13,7,5],
[111,13,7,6],
[111,13,8,0],
[111,13,8,1],
[111,13,8,2],
[111,13,8,4],
[111,13,8,7],
[111,13,9,0],
[111,13,9,1],
[111,13,9,2],
[111,13,9,3],
[111,13,9,4],
[111,13,9,5],
[111,13,9,6],
[111,13,9,7],
[111,13,9,8],
[111,13,10,0],
[111,13,10,1],
[111,13,10,2],
[111,13,10,3],
[111,13,10,4],
[111,13,10,5],
[111,13,10,6],
[111,13,10,7],
[111,13,10,8],
[111,13,10,9],
[111,13,11,0],
[111,13,11,1],
[111,13,11,2],
[111,13,11,3],
[111,13,11,4],
[111,13,11,5],
[111,13,11,6],
[111,13,11,7],
[111,13,11,8],
[111,13,11,9],
[111,13,11,10],
[111,13,12,0],
[111,13,12,1],
[111,13,12,2],
[111,13,12,3],
[111,13,12,4],
[111,13,12,5],
[111,13,12,6],
[111,13,12,7],
[111,13,12,8],
[111,13,12,9],
[111,13,12,10],
[111,14,3,0],
[111,14,3,1],
[111,14,3,2],
[111,14,4,0],
[111,14,4,1],
[111,14,4,2],
[111,14,4,3],
[111,14,5,0],
[111,14,5,1],
[111,14,5,2],
[111,14,5,4],
[111,14,6,0],
[111,14,6,1],
[111,14,6,2],
[111,14,6,4],
[111,14,7,0],
[111,14,7,1],
[111,14,7,2],
[111,14,7,3],
[111,14,7,4],
[111,14,7,5],
[111,14,7,6],
[111,14,8,0],
[111,14,8,1],
[111,14,8,2],
[111,14,8,4],
[111,14,8,7],
[111,14,9,0],
[111,14,9,1],
[111,14,9,2],
[111,14,9,3],
[111,14,9,4],
[111,14,9,5],
[111,14,9,6],
[111,14,9,7],
[111,14,9,8],
[111,14,10,0],
[111,14,10,1],
[111,14,10,2],
[111,14,10,3],
[111,14,10,4],
[111,14,10,5],
[111,14,10,6],
[111,14,10,7],
[111,14,10,8],
[111,14,10,9],
[111,14,11,0],
[111,14,11,1],
[111,14,11,2],
[111,14,11,3],
[111,14,11,4],
[111,14,11,5],
[111,14,11,6],
[111,14,11,7],
[111,14,11,8],
[111,14,11,9],
[111,14,11,10],
[111,14,12,0],
[111,14,12,1],
[111,14,12,2],
[111,14,12,3],
[111,14,12,4],
[111,14,12,5],
[111,14,12,6],
[111,14,12,7],
[111,14,12,8],
[111,14,12,9],
[111,14,12,10],
[111,15,3,0],
[111,15,3,1],
[111,15,3,2],
[111,15,4,0],
[111,15,4,1],
[111,15,4,2],
[111,15,4,3],
[111,15,5,0],
[111,15,5,1],
[111,15,5,2],
[111,15,5,4],
[111,15,6,0],
[111,15,6,1],
[111,15,6,2],
[111,15,6,4],
[111,15,7,0],
[111,15,7,1],
[111,15,7,2],
[111,15,7,3],
[111,15,7,4],
[111,15,7,5],
[111,15,7,6],
[111,15,8,0],
[111,15,8,1],
[111,15,8,2],
[111,15,8,4],
[111,15,8,7],
[111,15,10,0],
[111,15,10,1],
[111,15,10,2],
[111,15,10,3],
[111,15,10,4],
[111,15,10,5],
[111,15,10,6],
[111,15,10,7],
[111,15,10,8],
[111,15,11,0],
[111,15,11,1],
[111,15,11,2],
[111,15,11,3],
[111,15,11,4],
[111,15,11,5],
[111,15,11,6],
[111,15,11,7],
[111,15,11,8],
[111,15,11,10],
[111,15,12,0],
[111,15,12,1],
[111,15,12,2],
[111,15,12,3],
[111,15,12,4],
[111,15,12,5],
[111,15,12,6],
[111,15,12,7],
[111,15,12,8],
[111,15,12,10],
[111,15,13,0],
[111,15,13,1],
[111,15,13,2],
[111,15,13,3],
[111,15,13,4],
[111,15,13,5],
[111,15,13,6],
[111,15,13,7],
[111,15,13,8],
[111,15,13,10],
[111,15,13,11],
[111,15,14,0],
[111,15,14,1],
[111,15,14,2],
[111,15,14,3],
[111,15,14,4],
[111,15,14,5],
[111,15,14,6],
[111,15,14,7],
[111,15,14,8],
[111,16,3,0],
[111,16,3,1],
[111,16,3,2],
[111,16,4,0],
[111,16,4,1],
[111,16,4,2],
[111,16,4,3],
[111,16,5,0],
[111,16,5,1],
[111,16,5,2],
[111,16,5,4],
[111,16,6,0],
[111,16,6,1],
[111,16,6,2],
[111,16,6,4],
[111,16,7,0],
[111,16,7,1],
[111,16,7,2],
[111,16,7,3],
[111,16,7,4],
[111,16,7,5],
[111,16,7,6],
[111,16,8,0],
[111,16,8,1],
[111,16,8,2],
[111,16,8,4],
[111,16,8,7],
[111,16,9,0],
[111,16,9,1],
[111,16,9,2],
[111,16,9,3],
[111,16,9,4],
[111,16,9,5],
[111,16,9,6],
[111,16,9,7],
[111,16,9,8],
[111,16,10,0],
[111,16,10,1],
[111,16,10,2],
[111,16,10,3],
[111,16,10,4],
[111,16,10,5],
[111,16,10,6],
[111,16,10,7],
[111,16,10,8],
[111,16,10,9],
[111,16,11,0],
[111,16,11,1],
[111,16,11,2],
[111,16,11,3],
[111,16,11,4],
[111,16,11,5],
[111,16,11,6],
[111,16,11,7],
[111,16,11,8],
[111,16,11,9],
[111,16,11,10],
[111,16,12,0],
[111,16,12,1],
[111,16,12,2],
[111,16,12,3],
[111,16,12,4],
[111,16,12,5],
[111,16,12,6],
[111,16,12,7],
[111,16,12,8],
[111,16,12,9],
[111,16,13,0],
[111,16,13,1],
[111,16,13,2],
[111,16,13,3],
[111,16,13,4],
[111,16,13,5],
[111,16,13,6],
[111,16,13,7],
[111,16,13,8],
[111,16,13,9],
[111,16,14,0],
[111,16,14,1],
[111,16,14,2],
[111,16,14,3],
[111,16,14,4],
[111,16,14,5],
[111,16,14,6],
[111,16,14,7],
[111,16,15,0],
[111,16,15,1],
[111,16,15,2],
[111,16,15,3],
[111,16,15,4],
[111,16,15,5],
[111,17,3,0],
[111,17,3,1],
[111,17,3,2],
[111,17,4,0],
[111,17,4,1],
[111,17,4,2],
[111,17,4,3],
[111,17,5,0],
[111,17,5,1],
[111,17,5,2],
[111,17,5,4],
[111,17,6,0],
[111,17,6,1],
[111,17,6,2],
[111,17,6,4],
[111,17,7,0],
[111,17,7,1],
[111,17,7,2],
[111,17,7,3],
[111,17,7,4],
[111,17,7,5],
[111,17,7,6],
[111,17,8,0],
[111,17,8,1],
[111,17,8,2],
[111,17,8,4],
[111,17,8,7],
[111,17,9,0],
[111,17,9,1],
[111,17,9,2],
[111,17,9,3],
[111,17,9,4],
[111,17,9,5],
[111,17,9,6],
[111,17,9,7],
[111,17,9,8],
[111,17,10,0],
[111,17,10,1],
[111,17,10,2],
[111,17,10,3],
[111,17,10,4],
[111,17,10,5],
[111,17,10,6],
[111,17,10,7],
[111,17,10,8],
[111,17,10,9],
[111,17,11,0],
[111,17,11,1],
[111,17,11,2],
[111,17,11,3],
[111,17,11,4],
[111,17,11,5],
[111,17,11,6],
[111,17,11,7],
[111,17,11,8],
[111,17,11,9],
[111,17,11,10],
[111,17,12,0],
[111,17,12,1],
[111,17,12,2],
[111,17,12,3],
[111,17,12,4],
[111,17,12,5],
[111,17,12,6],
[111,17,12,7],
[111,17,12,8],
[111,17,12,9],
[111,17,13,0],
[111,17,13,1],
[111,17,13,2],
[111,17,13,3],
[111,17,13,4],
[111,17,13,5],
[111,17,13,6],
[111,17,13,7],
[111,17,13,8],
[111,17,13,9],
[111,17,14,0],
[111,17,14,1],
[111,17,14,2],
[111,17,14,3],
[111,17,14,4],
[111,17,14,5],
[111,17,14,6],
[111,17,14,7],
[111,17,15,0],
[111,17,15,1],
[111,17,15,2],
[111,17,15,3],
[111,17,15,4],
[111,17,15,5],
[111,17,16,0],
[111,17,16,1],
[111,17,16,2],
[111,17,16,3],
[111,17,16,4],
[111,18,3,0],
[111,18,3,1],
[111,18,3,2],
[111,18,4,0],
[111,18,4,1],
[111,18,4,2],
[111,18,4,3],
[111,18,5,0],
[111,18,5,1],
[111,18,5,2],
[111,18,5,4],
[111,18,6,0],
[111,18,6,1],
[111,18,6,2],
[111,18,6,4],
[111,18,7,0],
[111,18,7,1],
[111,18,7,2],
[111,18,7,3],
[111,18,7,4],
[111,18,7,5],
[111,18,7,6],
[111,18,8,0],
[111,18,8,1],
[111,18,8,2],
[111,18,8,4],
[111,18,8,7],
[111,18,10,0],
[111,18,10,1],
[111,18,10,2],
[111,18,10,3],
[111,18,10,4],
[111,18,10,5],
[111,18,10,6],
[111,18,10,7],
[111,18,10,8],
[111,18,11,0],
[111,18,11,1],
[111,18,11,2],
[111,18,11,3],
[111,18,11,4],
[111,18,11,5],
[111,18,11,6],
[111,18,11,7],
[111,18,11,8],
[111,18,12,0],
[111,18,12,1],
[111,18,12,2],
[111,18,12,3],
[111,18,12,4],
[111,18,12,5],
[111,18,12,6],
[111,18,12,7],
[111,18,13,0],
[111,18,13,1],
[111,18,13,2],
[111,18,13,3],
[111,18,13,4],
[111,18,13,5],
[111,18,13,6],
[111,18,13,7],
[111,18,14,0],
[111,18,14,1],
[111,18,14,2],
[111,18,14,3],
[111,18,14,4],
[111,18,14,5],
[111,18,16,0],
[111,18,16,1],
[111,18,16,2],
[111,18,17,0],
[111,18,17,1],
[111,18,17,2],
[111,19,3,0],
[111,19,3,1],
[111,19,3,2],
[111,19,4,0],
[111,19,4,1],
[111,19,4,2],
[111,19,4,3],
[111,19,5,0],
[111,19,5,1],
[111,19,5,2],
[111,19,5,4],
[111,19,6,0],
[111,19,6,1],
[111,19,6,2],
[111,19,6,4],
[111,19,7,0],
[111,19,7,1],
[111,19,7,2],
[111,19,7,3],
[111,19,7,4],
[111,19,7,5],
[111,19,7,6],
[111,19,8,0],
[111,19,8,1],
[111,19,8,2],
[111,19,8,4],
[111,19,8,7],
[111,19,9,0],
[111,19,9,1],
[111,19,9,2],
[111,19,9,3],
[111,19,9,4],
[111,19,9,5],
[111,19,9,6],
[111,19,9,7],
[111,19,9,8],
[111,19,10,0],
[111,19,10,1],
[111,19,10,2],
[111,19,10,3],
[111,19,10,4],
[111,19,10,5],
[111,19,10,6],
[111,19,10,7],
[111,19,11,0],
[111,19,11,1],
[111,19,11,2],
[111,19,11,3],
[111,19,11,4],
[111,19,11,5],
[111,19,11,6],
[111,19,11,7],
[111,19,12,0],
[111,19,12,1],
[111,19,12,2],
[111,19,12,3],
[111,19,12,4],
[111,19,12,5],
[111,19,15,0],
[111,19,15,1],
[111,19,15,2],
[111,20,3,0],
[111,20,3,1],
[111,20,3,2],
[111,20,4,0],
[111,20,4,1],
[111,20,4,2],
[111,20,4,3],
[111,20,5,0],
[111,20,5,1],
[111,20,5,2],
[111,20,5,4],
[111,20,6,0],
[111,20,6,1],
[111,20,6,2],
[111,20,6,4],
[111,20,7,0],
[111,20,7,1],
[111,20,7,2],
[111,20,7,3],
[111,20,7,4],
[111,20,7,5],
[111,20,7,6],
[111,20,8,0],
[111,20,8,1],
[111,20,8,2],
[111,20,8,4],
[111,20,8,7],
[111,20,9,0],
[111,20,9,1],
[111,20,9,2],
[111,20,9,3],
[111,20,9,4],
[111,20,9,5],
[111,20,9,6],
[111,20,9,7],
[111,20,11,0],
[111,20,11,1],
[111,20,11,2],
[111,20,11,3],
[111,20,11,4],
[111,20,11,5],
[111,20,12,0],
[111,20,12,1],
[111,20,12,2],
[111,20,12,3],
[111,20,12,4],
[111,20,13,0],
[111,20,13,1],
[111,20,13,2],
[111,20,13,3],
[111,20,13,4],
[111,20,14,0],
[111,20,14,1],
[111,20,14,2],
[111,21,4,0],
[111,21,4,1],
[111,21,4,2],
[111,21,7,0],
[111,21,7,1],
[111,21,7,2],
[111,21,7,4],
[111,21,9,0],
[111,21,9,1],
[111,21,9,2],
[111,21,9,4],
[111,21,10,0],
[111,21,10,1],
[111,21,10,2],
[111,21,10,4],
[111,21,11,0],
[111,21,11,1],
[111,21,11,2],
[111,21,11,4],
[111,21,12,0],
[111,21,12,1],
[111,21,12,2],
[111,21,13,0],
[111,21,13,1],
[111,21,13,2],
[111,22,3,0],
[111,22,3,1],
[111,22,3,2],
[111,22,4,0],
[111,22,4,1],
[111,22,4,2],
[111,22,4,3],
[111,22,5,0],
[111,22,5,1],
[111,22,5,2],
[111,22,5,4],
[111,22,6,0],
[111,22,6,1],
[111,22,6,2],
[111,22,6,4],
[111,22,7,0],
[111,22,7,1],
[111,22,7,2],
[111,22,7,3],
[111,22,7,4],
[111,22,7,5],
[111,22,8,0],
[111,22,8,1],
[111,22,8,2],
[111,22,8,4],
[111,22,9,0],
[111,22,9,1],
[111,22,9,2],
[111,22,9,3],
[111,22,9,4],
[111,22,11,0],
[111,22,11,1],
[111,22,11,2],
[111,24,3,0],
[111,24,3,1],
[111,24,3,2],
[111,24,4,0],
[111,24,4,1],
[111,24,4,2],
[111,24,4,3],
[111,24,5,0],
[111,24,5,1],
[111,24,5,2],
[111,24,5,4],
[111,24,6,0],
[111,24,6,1],
[111,24,6,2],
[111,24,6,4],
[111,24,7,0],
[111,24,7,1],
[111,24,7,2],
[111,24,7,3],
[111,24,7,4],
[111,24,8,0],
[111,24,8,1],
[111,24,8,2],
[111,24,9,0],
[111,24,9,1],
[111,24,9,2],
[111,25,3,0],
[111,25,3,1],
[111,25,3,2],
[111,25,4,0],
[111,25,4,1],
[111,25,4,2],
[111,25,4,3],
[111,25,5,0],
[111,25,5,1],
[111,25,5,2],
[111,26,3,0],
[111,26,3,1],
[111,26,3,2],
[111,26,4,0],
[111,26,4,1],
[111,26,4,2],
[112,4,3,0],
[112,4,3,1],
[112,4,3,2],
[112,5,4,0],
[112,5,4,1],
[112,5,4,2],
[112,6,4,0],
[112,6,4,1],
[112,6,4,2],
[112,7,3,0],
[112,7,3,1],
[112,7,3,2],
[112,7,4,0],
[112,7,4,1],
[112,7,4,2],
[112,7,4,3],
[112,7,5,0],
[112,7,5,1],
[112,7,5,2],
[112,7,5,4],
[112,7,6,0],
[112,7,6,1],
[112,7,6,2],
[112,8,4,0],
[112,8,4,1],
[112,8,4,2],
[112,8,7,4],
[112,9,3,0],
[112,9,3,1],
[112,9,3,2],
[112,9,4,0],
[112,9,4,1],
[112,9,4,2],
[112,9,4,3],
[112,9,5,0],
[112,9,5,1],
[112,9,5,2],
[112,9,6,4],
[112,9,7,3],
[112,9,7,4],
[112,9,7,5],
[112,9,7,6],
[112,9,8,0],
[112,9,8,1],
[112,9,8,2],
[112,9,8,4],
[112,9,8,7],
[112,10,3,0],
[112,10,3,1],
[112,10,3,2],
[112,10,4,0],
[112,10,4,1],
[112,10,4,2],
[112,10,5,4],
[112,10,6,0],
[112,10,6,1],
[112,10,6,2],
[112,10,6,4],
[112,10,7,0],
[112,10,7,1],
[112,10,7,2],
[112,10,7,3],
[112,10,7,4],
[112,10,7,5],
[112,10,7,6],
[112,10,8,0],
[112,10,8,1],
[112,10,8,2],
[112,10,8,4],
[112,10,8,7],
[112,10,9,0],
[112,10,9,1],
[112,10,9,2],
[112,10,9,3],
[112,10,9,4],
[112,10,9,5],
[112,10,9,6],
[112,10,9,7],
[112,10,9,8],
[112,11,3,0],
[112,11,3,1],
[112,11,3,2],
[112,11,4,0],
[112,11,4,1],
[112,11,4,2],
[112,11,5,4],
[112,11,6,0],
[112,11,6,1],
[112,11,6,2],
[112,11,6,4],
[112,11,7,0],
[112,11,7,1],
[112,11,7,2],
[112,11,7,3],
[112,11,7,4],
[112,11,7,5],
[112,11,7,6],
[112,11,8,0],
[112,11,8,1],
[112,11,8,2],
[112,11,8,4],
[112,11,8,7],
[112,11,9,0],
[112,11,9,1],
[112,11,9,2],
[112,11,9,3],
[112,11,9,4],
[112,11,9,5],
[112,11,9,6],
[112,11,9,7],
[112,11,9,8],
[112,11,10,0],
[112,11,10,1],
[112,11,10,2],
[112,11,10,3],
[112,11,10,4],
[112,11,10,5],
[112,11,10,6],
[112,11,10,7],
[112,11,10,8],
[112,11,10,9],
[112,12,4,3],
[112,12,5,0],
[112,12,5,1],
[112,12,5,2],
[112,12,5,4],
[112,12,6,0],
[112,12,6,1],
[112,12,6,2],
[112,12,6,4],
[112,12,7,0],
[112,12,7,1],
[112,12,7,2],
[112,12,7,3],
[112,12,7,4],
[112,12,7,5],
[112,12,7,6],
[112,12,8,0],
[112,12,8,1],
[112,12,8,2],
[112,12,8,4],
[112,12,8,7],
[112,12,9,0],
[112,12,9,1],
[112,12,9,2],
[112,12,9,3],
[112,12,9,4],
[112,12,9,5],
[112,12,9,6],
[112,12,9,7],
[112,12,9,8],
[112,12,10,0],
[112,12,10,1],
[112,12,10,2],
[112,12,10,3],
[112,12,10,4],
[112,12,10,5],
[112,12,10,6],
[112,12,10,7],
[112,12,10,8],
[112,12,10,9],
[112,13,4,3],
[112,13,5,0],
[112,13,5,1],
[112,13,5,2],
[112,13,5,4],
[112,13,6,0],
[112,13,6,1],
[112,13,6,2],
[112,13,6,4],
[112,13,7,0],
[112,13,7,1],
[112,13,7,2],
[112,13,7,3],
[112,13,7,4],
[112,13,7,5],
[112,13,7,6],
[112,13,8,0],
[112,13,8,1],
[112,13,8,2],
[112,13,8,4],
[112,13,8,7],
[112,13,9,0],
[112,13,9,1],
[112,13,9,2],
[112,13,9,3],
[112,13,9,4],
[112,13,9,5],
[112,13,9,6],
[112,13,9,7],
[112,13,9,8],
[112,13,10,0],
[112,13,10,1],
[112,13,10,2],
[112,13,10,3],
[112,13,10,4],
[112,13,10,5],
[112,13,10,6],
[112,13,10,7],
[112,13,10,8],
[112,13,10,9],
[112,13,11,0],
[112,13,11,1],
[112,13,11,2],
[112,13,11,3],
[112,13,11,4],
[112,13,11,5],
[112,13,11,6],
[112,13,11,7],
[112,13,11,8],
[112,13,11,9],
[112,13,11,10],
[112,13,12,0],
[112,13,12,1],
[112,13,12,2],
[112,13,12,3],
[112,13,12,4],
[112,13,12,5],
[112,13,12,6],
[112,13,12,7],
[112,13,12,8],
[112,13,12,9],
[112,13,12,10],
[112,14,3,0],
[112,14,3,1],
[112,14,3,2],
[112,14,4,0],
[112,14,4,1],
[112,14,4,2],
[112,14,4,3],
[112,14,5,0],
[112,14,5,1],
[112,14,5,2],
[112,14,5,4],
[112,14,6,0],
[112,14,6,1],
[112,14,6,2],
[112,14,6,4],
[112,14,7,0],
[112,14,7,1],
[112,14,7,2],
[112,14,7,3],
[112,14,7,4],
[112,14,7,5],
[112,14,7,6],
[112,14,8,0],
[112,14,8,1],
[112,14,8,2],
[112,14,8,4],
[112,14,8,7],
[112,14,9,0],
[112,14,9,1],
[112,14,9,2],
[112,14,9,3],
[112,14,9,4],
[112,14,9,5],
[112,14,9,6],
[112,14,9,7],
[112,14,9,8],
[112,14,10,0],
[112,14,10,1],
[112,14,10,2],
[112,14,10,3],
[112,14,10,4],
[112,14,10,5],
[112,14,10,6],
[112,14,10,7],
[112,14,10,8],
[112,14,10,9],
[112,14,11,0],
[112,14,11,1],
[112,14,11,2],
[112,14,11,3],
[112,14,11,4],
[112,14,11,5],
[112,14,11,6],
[112,14,11,7],
[112,14,11,8],
[112,14,11,9],
[112,14,11,10],
[112,14,12,0],
[112,14,12,1],
[112,14,12,2],
[112,14,12,3],
[112,14,12,4],
[112,14,12,5],
[112,14,12,6],
[112,14,12,7],
[112,14,12,8],
[112,14,12,9],
[112,14,12,10],
[112,15,3,0],
[112,15,3,1],
[112,15,3,2],
[112,15,4,0],
[112,15,4,1],
[112,15,4,2],
[112,15,4,3],
[112,15,5,0],
[112,15,5,1],
[112,15,5,2],
[112,15,5,4],
[112,15,6,0],
[112,15,6,1],
[112,15,6,2],
[112,15,6,4],
[112,15,7,0],
[112,15,7,1],
[112,15,7,2],
[112,15,7,3],
[112,15,7,4],
[112,15,7,5],
[112,15,7,6],
[112,15,8,0],
[112,15,8,1],
[112,15,8,2],
[112,15,8,4],
[112,15,8,7],
[112,15,10,0],
[112,15,10,1],
[112,15,10,2],
[112,15,10,3],
[112,15,10,4],
[112,15,10,5],
[112,15,10,6],
[112,15,10,7],
[112,15,10,8],
[112,15,11,0],
[112,15,11,1],
[112,15,11,2],
[112,15,11,3],
[112,15,11,4],
[112,15,11,5],
[112,15,11,6],
[112,15,11,7],
[112,15,11,8],
[112,15,11,10],
[112,15,12,0],
[112,15,12,1],
[112,15,12,2],
[112,15,12,3],
[112,15,12,4],
[112,15,12,5],
[112,15,12,6],
[112,15,12,7],
[112,15,12,8],
[112,15,12,10],
[112,15,13,0],
[112,15,13,1],
[112,15,13,2],
[112,15,13,3],
[112,15,13,4],
[112,15,13,5],
[112,15,13,6],
[112,15,13,7],
[112,15,13,8],
[112,15,13,10],
[112,15,13,11],
[112,15,14,0],
[112,15,14,1],
[112,15,14,2],
[112,15,14,3],
[112,15,14,4],
[112,15,14,5],
[112,15,14,6],
[112,15,14,7],
[112,15,14,8],
[112,17,3,0],
[112,17,3,1],
[112,17,3,2],
[112,17,4,0],
[112,17,4,1],
[112,17,4,2],
[112,17,4,3],
[112,17,5,0],
[112,17,5,1],
[112,17,5,2],
[112,17,5,4],
[112,17,6,0],
[112,17,6,1],
[112,17,6,2],
[112,17,6,4],
[112,17,7,0],
[112,17,7,1],
[112,17,7,2],
[112,17,7,3],
[112,17,7,4],
[112,17,7,5],
[112,17,7,6],
[112,17,8,0],
[112,17,8,1],
[112,17,8,2],
[112,17,8,4],
[112,17,8,7],
[112,17,9,0],
[112,17,9,1],
[112,17,9,2],
[112,17,9,3],
[112,17,9,4],
[112,17,9,5],
[112,17,9,6],
[112,17,9,7],
[112,17,9,8],
[112,17,10,0],
[112,17,10,1],
[112,17,10,2],
[112,17,10,3],
[112,17,10,4],
[112,17,10,5],
[112,17,10,6],
[112,17,10,7],
[112,17,10,8],
[112,17,10,9],
[112,17,11,0],
[112,17,11,1],
[112,17,11,2],
[112,17,11,3],
[112,17,11,4],
[112,17,11,5],
[112,17,11,6],
[112,17,11,7],
[112,17,11,8],
[112,17,11,9],
[112,17,11,10],
[112,17,12,0],
[112,17,12,1],
[112,17,12,2],
[112,17,12,3],
[112,17,12,4],
[112,17,12,5],
[112,17,12,6],
[112,17,12,7],
[112,17,12,8],
[112,17,12,9],
[112,17,13,0],
[112,17,13,1],
[112,17,13,2],
[112,17,13,3],
[112,17,13,4],
[112,17,13,5],
[112,17,13,6],
[112,17,13,7],
[112,17,13,8],
[112,17,13,9],
[112,17,14,0],
[112,17,14,1],
[112,17,14,2],
[112,17,14,3],
[112,17,14,4],
[112,17,14,5],
[112,17,14,6],
[112,17,14,7],
[112,17,15,0],
[112,17,15,1],
[112,17,15,2],
[112,17,15,3],
[112,17,15,4],
[112,17,15,5],
[112,18,3,0],
[112,18,3,1],
[112,18,3,2],
[112,18,4,0],
[112,18,4,1],
[112,18,4,2],
[112,18,4,3],
[112,18,5,0],
[112,18,5,1],
[112,18,5,2],
[112,18,5,4],
[112,18,6,0],
[112,18,6,1],
[112,18,6,2],
[112,18,6,4],
[112,18,7,0],
[112,18,7,1],
[112,18,7,2],
[112,18,7,3],
[112,18,7,4],
[112,18,7,5],
[112,18,7,6],
[112,18,8,0],
[112,18,8,1],
[112,18,8,2],
[112,18,8,4],
[112,18,8,7],
[112,18,10,0],
[112,18,10,1],
[112,18,10,2],
[112,18,10,3],
[112,18,10,4],
[112,18,10,5],
[112,18,10,6],
[112,18,10,7],
[112,18,10,8],
[112,18,11,0],
[112,18,11,1],
[112,18,11,2],
[112,18,11,3],
[112,18,11,4],
[112,18,11,5],
[112,18,11,6],
[112,18,11,7],
[112,18,11,8],
[112,18,12,0],
[112,18,12,1],
[112,18,12,2],
[112,18,12,3],
[112,18,12,4],
[112,18,12,5],
[112,18,12,6],
[112,18,12,7],
[112,18,13,0],
[112,18,13,1],
[112,18,13,2],
[112,18,13,3],
[112,18,13,4],
[112,18,13,5],
[112,18,13,6],
[112,18,13,7],
[112,18,14,0],
[112,18,14,1],
[112,18,14,2],
[112,18,14,3],
[112,18,14,4],
[112,18,14,5],
[112,18,17,0],
[112,18,17,1],
[112,18,17,2],
[112,19,3,0],
[112,19,3,1],
[112,19,3,2],
[112,19,4,0],
[112,19,4,1],
[112,19,4,2],
[112,19,4,3],
[112,19,5,0],
[112,19,5,1],
[112,19,5,2],
[112,19,5,4],
[112,19,6,0],
[112,19,6,1],
[112,19,6,2],
[112,19,6,4],
[112,19,7,0],
[112,19,7,1],
[112,19,7,2],
[112,19,7,3],
[112,19,7,4],
[112,19,7,5],
[112,19,7,6],
[112,19,8,0],
[112,19,8,1],
[112,19,8,2],
[112,19,8,4],
[112,19,8,7],
[112,19,9,0],
[112,19,9,1],
[112,19,9,2],
[112,19,9,3],
[112,19,9,4],
[112,19,9,5],
[112,19,9,6],
[112,19,9,7],
[112,19,9,8],
[112,19,10,0],
[112,19,10,1],
[112,19,10,2],
[112,19,10,3],
[112,19,10,4],
[112,19,10,5],
[112,19,10,6],
[112,19,10,7],
[112,19,11,0],
[112,19,11,1],
[112,19,11,2],
[112,19,11,3],
[112,19,11,4],
[112,19,11,5],
[112,19,11,6],
[112,19,11,7],
[112,19,12,0],
[112,19,12,1],
[112,19,12,2],
[112,19,12,3],
[112,19,12,4],
[112,19,12,5],
[112,19,15,0],
[112,19,15,1],
[112,19,15,2],
[112,20,3,0],
[112,20,3,1],
[112,20,3,2],
[112,20,4,0],
[112,20,4,1],
[112,20,4,2],
[112,20,4,3],
[112,20,5,0],
[112,20,5,1],
[112,20,5,2],
[112,20,5,4],
[112,20,6,0],
[112,20,6,1],
[112,20,6,2],
[112,20,6,4],
[112,20,7,0],
[112,20,7,1],
[112,20,7,2],
[112,20,7,3],
[112,20,7,4],
[112,20,7,5],
[112,20,7,6],
[112,20,8,0],
[112,20,8,1],
[112,20,8,2],
[112,20,8,4],
[112,20,8,7],
[112,20,9,0],
[112,20,9,1],
[112,20,9,2],
[112,20,9,3],
[112,20,9,4],
[112,20,9,5],
[112,20,9,6],
[112,20,9,7],
[112,20,11,0],
[112,20,11,1],
[112,20,11,2],
[112,20,11,3],
[112,20,11,4],
[112,20,11,5],
[112,20,12,0],
[112,20,12,1],
[112,20,12,2],
[112,20,12,3],
[112,20,12,4],
[112,20,13,0],
[112,20,13,1],
[112,20,13,2],
[112,20,13,3],
[112,20,13,4],
[112,20,14,0],
[112,20,14,1],
[112,20,14,2],
[112,21,4,0],
[112,21,4,1],
[112,21,4,2],
[112,21,7,0],
[112,21,7,1],
[112,21,7,2],
[112,21,7,4],
[112,21,9,0],
[112,21,9,1],
[112,21,9,2],
[112,21,9,4],
[112,21,10,0],
[112,21,10,1],
[112,21,10,2],
[112,21,10,4],
[112,21,11,0],
[112,21,11,1],
[112,21,11,2],
[112,21,11,4],
[112,21,12,0],
[112,21,12,1],
[112,21,12,2],
[112,21,13,0],
[112,21,13,1],
[112,21,13,2],
[112,22,3,0],
[112,22,3,1],
[112,22,3,2],
[112,22,4,0],
[112,22,4,1],
[112,22,4,2],
[112,22,4,3],
[112,22,5,0],
[112,22,5,1],
[112,22,5,2],
[112,22,5,4],
[112,22,6,0],
[112,22,6,1],
[112,22,6,2],
[112,22,6,4],
[112,22,7,0],
[112,22,7,1],
[112,22,7,2],
[112,22,7,3],
[112,22,7,4],
[112,22,7,5],
[112,22,8,0],
[112,22,8,1],
[112,22,8,2],
[112,22,8,4],
[112,22,9,0],
[112,22,9,1],
[112,22,9,2],
[112,22,9,3],
[112,22,9,4],
[112,22,11,0],
[112,22,11,1],
[112,22,11,2],
[112,23,3,0],
[112,23,3,1],
[112,23,3,2],
[112,23,4,0],
[112,23,4,1],
[112,23,4,2],
[112,23,4,3],
[112,23,5,0],
[112,23,5,1],
[112,23,5,2],
[112,23,5,4],
[112,23,6,0],
[112,23,6,1],
[112,23,6,2],
[112,23,6,4],
[112,23,7,0],
[112,23,7,1],
[112,23,7,2],
[112,23,7,3],
[112,23,7,4],
[112,23,8,0],
[112,23,8,1],
[112,23,8,2],
[112,23,9,0],
[112,23,9,1],
[112,23,9,2],
[112,25,3,0],
[112,25,3,1],
[112,25,3,2],
[112,25,4,0],
[112,25,4,1],
[112,25,4,2],
[112,25,4,3],
[112,25,5,0],
[112,25,5,1],
[112,25,5,2],
[112,26,3,0],
[112,26,3,1],
[112,26,3,2],
[112,26,4,0],
[112,26,4,1],
[112,26,4,2],
[113,4,3,0],
[113,4,3,1],
[113,4,3,2],
[113,5,4,0],
[113,5,4,1],
[113,5,4,2],
[113,6,4,0],
[113,6,4,1],
[113,6,4,2],
[113,7,3,0],
[113,7,3,1],
[113,7,3,2],
[113,7,4,0],
[113,7,4,1],
[113,7,4,2],
[113,7,4,3],
[113,7,5,0],
[113,7,5,1],
[113,7,5,2],
[113,7,6,4],
[113,8,4,0],
[113,8,4,1],
[113,8,4,2],
[113,8,7,0],
[113,8,7,1],
[113,8,7,2],
[113,8,7,4],
[113,9,3,0],
[113,9,3,1],
[113,9,3,2],
[113,9,4,0],
[113,9,4,1],
[113,9,4,2],
[113,9,5,4],
[113,9,6,0],
[113,9,6,1],
[113,9,6,2],
[113,9,6,4],
[113,9,7,0],
[113,9,7,1],
[113,9,7,2],
[113,9,7,3],
[113,9,7,4],
[113,9,7,5],
[113,9,7,6],
[113,9,8,0],
[113,9,8,1],
[113,9,8,2],
[113,9,8,4],
[113,9,8,7],
[113,10,4,3],
[113,10,5,0],
[113,10,5,1],
[113,10,5,2],
[113,10,5,4],
[113,10,6,0],
[113,10,6,1],
[113,10,6,2],
[113,10,6,4],
[113,10,7,0],
[113,10,7,1],
[113,10,7,2],
[113,10,7,3],
[113,10,7,4],
[113,10,7,5],
[113,10,7,6],
[113,10,8,0],
[113,10,8,1],
[113,10,8,2],
[113,10,8,4],
[113,10,8,7],
[113,10,9,0],
[113,10,9,1],
[113,10,9,2],
[113,10,9,3],
[113,10,9,4],
[113,10,9,5],
[113,10,9,6],
[113,10,9,7],
[113,10,9,8],
[113,11,4,3],
[113,11,5,0],
[113,11,5,1],
[113,11,5,2],
[113,11,5,4],
[113,11,6,0],
[113,11,6,1],
[113,11,6,2],
[113,11,6,4],
[113,11,7,0],
[113,11,7,1],
[113,11,7,2],
[113,11,7,3],
[113,11,7,4],
[113,11,7,5],
[113,11,7,6],
[113,11,8,0],
[113,11,8,1],
[113,11,8,2],
[113,11,8,4],
[113,11,8,7],
[113,11,9,0],
[113,11,9,1],
[113,11,9,2],
[113,11,9,3],
[113,11,9,4],
[113,11,9,5],
[113,11,9,6],
[113,11,9,7],
[113,11,9,8],
[113,11,10,0],
[113,11,10,1],
[113,11,10,2],
[113,11,10,3],
[113,11,10,4],
[113,11,10,5],
[113,11,10,6],
[113,11,10,7],
[113,11,10,8],
[113,11,10,9],
[113,12,3,0],
[113,12,3,1],
[113,12,3,2],
[113,12,4,0],
[113,12,4,1],
[113,12,4,2],
[113,12,4,3],
[113,12,5,0],
[113,12,5,1],
[113,12,5,2],
[113,12,5,4],
[113,12,6,0],
[113,12,6,1],
[113,12,6,2],
[113,12,6,4],
[113,12,7,0],
[113,12,7,1],
[113,12,7,2],
[113,12,7,3],
[113,12,7,4],
[113,12,7,5],
[113,12,7,6],
[113,12,8,0],
[113,12,8,1],
[113,12,8,2],
[113,12,8,4],
[113,12,8,7],
[113,12,9,0],
[113,12,9,1],
[113,12,9,2],
[113,12,9,3],
[113,12,9,4],
[113,12,9,5],
[113,12,9,6],
[113,12,9,7],
[113,12,9,8],
[113,12,10,0],
[113,12,10,1],
[113,12,10,2],
[113,12,10,3],
[113,12,10,4],
[113,12,10,5],
[113,12,10,6],
[113,12,10,7],
[113,12,10,8],
[113,12,10,9],
[113,15,3,0],
[113,15,3,1],
[113,15,3,2],
[113,15,4,0],
[113,15,4,1],
[113,15,4,2],
[113,15,4,3],
[113,15,5,0],
[113,15,5,1],
[113,15,5,2],
[113,15,5,4],
[113,15,6,0],
[113,15,6,1],
[113,15,6,2],
[113,15,6,4],
[113,15,7,0],
[113,15,7,1],
[113,15,7,2],
[113,15,7,3],
[113,15,7,4],
[113,15,7,5],
[113,15,7,6],
[113,15,8,0],
[113,15,8,1],
[113,15,8,2],
[113,15,8,4],
[113,15,8,7],
[113,15,10,0],
[113,15,10,1],
[113,15,10,2],
[113,15,10,3],
[113,15,10,4],
[113,15,10,5],
[113,15,10,6],
[113,15,10,7],
[113,15,10,8],
[113,15,11,0],
[113,15,11,1],
[113,15,11,2],
[113,15,11,3],
[113,15,11,4],
[113,15,11,5],
[113,15,11,6],
[113,15,11,7],
[113,15,11,8],
[113,15,11,10],
[113,15,12,0],
[113,15,12,1],
[113,15,12,2],
[113,15,12,3],
[113,15,12,4],
[113,15,12,5],
[113,15,12,6],
[113,15,12,7],
[113,15,12,8],
[113,16,3,0],
[113,16,3,1],
[113,16,3,2],
[113,16,4,0],
[113,16,4,1],
[113,16,4,2],
[113,16,4,3],
[113,16,5,0],
[113,16,5,1],
[113,16,5,2],
[113,16,5,4],
[113,16,6,0],
[113,16,6,1],
[113,16,6,2],
[113,16,6,4],
[113,16,7,0],
[113,16,7,1],
[113,16,7,2],
[113,16,7,3],
[113,16,7,4],
[113,16,7,5],
[113,16,7,6],
[113,16,8,0],
[113,16,8,1],
[113,16,8,2],
[113,16,8,4],
[113,16,8,7],
[113,16,9,0],
[113,16,9,1],
[113,16,9,2],
[113,16,9,3],
[113,16,9,4],
[113,16,9,5],
[113,16,9,6],
[113,16,9,7],
[113,16,9,8],
[113,16,10,0],
[113,16,10,1],
[113,16,10,2],
[113,16,10,3],
[113,16,10,4],
[113,16,10,5],
[113,16,10,6],
[113,16,10,7],
[113,16,10,8],
[113,16,10,9],
[113,16,11,0],
[113,16,11,1],
[113,16,11,2],
[113,16,11,3],
[113,16,11,4],
[113,16,11,5],
[113,16,11,6],
[113,16,11,7],
[113,16,11,8],
[113,16,11,9],
[113,16,12,0],
[113,16,12,1],
[113,16,12,2],
[113,16,12,3],
[113,16,12,4],
[113,16,12,5],
[113,16,12,6],
[113,16,12,7],
[113,16,15,0],
[113,16,15,1],
[113,16,15,2],
[113,16,15,3],
[113,16,15,4],
[113,17,3,0],
[113,17,3,1],
[113,17,3,2],
[113,17,4,0],
[113,17,4,1],
[113,17,4,2],
[113,17,4,3],
[113,17,5,0],
[113,17,5,1],
[113,17,5,2],
[113,17,5,4],
[113,17,6,0],
[113,17,6,1],
[113,17,6,2],
[113,17,6,4],
[113,17,7,0],
[113,17,7,1],
[113,17,7,2],
[113,17,7,3],
[113,17,7,4],
[113,17,7,5],
[113,17,7,6],
[113,17,8,0],
[113,17,8,1],
[113,17,8,2],
[113,17,8,4],
[113,17,8,7],
[113,17,9,0],
[113,17,9,1],
[113,17,9,2],
[113,17,9,3],
[113,17,9,4],
[113,17,9,5],
[113,17,9,6],
[113,17,9,7],
[113,17,9,8],
[113,17,10,0],
[113,17,10,1],
[113,17,10,2],
[113,17,10,3],
[113,17,10,4],
[113,17,10,5],
[113,17,10,6],
[113,17,10,7],
[113,17,10,8],
[113,17,10,9],
[113,17,11,0],
[113,17,11,1],
[113,17,11,2],
[113,17,11,3],
[113,17,11,4],
[113,17,11,5],
[113,17,11,6],
[113,17,11,7],
[113,17,11,8],
[113,17,11,9],
[113,17,12,0],
[113,17,12,1],
[113,17,12,2],
[113,17,12,3],
[113,17,12,4],
[113,17,12,5],
[113,17,12,6],
[113,17,12,7],
[113,17,15,0],
[113,17,15,1],
[113,17,15,2],
[113,17,15,3],
[113,17,15,4],
[113,17,16,0],
[113,17,16,1],
[113,17,16,2],
[113,18,3,0],
[113,18,3,1],
[113,18,3,2],
[113,18,4,0],
[113,18,4,1],
[113,18,4,2],
[113,18,4,3],
[113,18,5,0],
[113,18,5,1],
[113,18,5,2],
[113,18,5,4],
[113,18,6,0],
[113,18,6,1],
[113,18,6,2],
[113,18,6,4],
[113,18,7,0],
[113,18,7,1],
[113,18,7,2],
[113,18,7,3],
[113,18,7,4],
[113,18,7,5],
[113,18,7,6],
[113,18,8,0],
[113,18,8,1],
[113,18,8,2],
[113,18,8,4],
[113,18,8,7],
[113,18,10,0],
[113,18,10,1],
[113,18,10,2],
[113,18,10,3],
[113,18,10,4],
[113,18,10,5],
[113,18,10,6],
[113,18,10,7],
[113,18,11,0],
[113,18,11,1],
[113,18,11,2],
[113,18,11,3],
[113,18,11,4],
[113,18,11,5],
[113,18,11,6],
[113,18,11,7],
[113,18,12,0],
[113,18,12,1],
[113,18,12,2],
[113,18,12,3],
[113,18,12,4],
[113,18,12,5],
[113,20,3,0],
[113,20,3,1],
[113,20,3,2],
[113,20,4,0],
[113,20,4,1],
[113,20,4,2],
[113,20,4,3],
[113,20,5,0],
[113,20,5,1],
[113,20,5,2],
[113,20,5,4],
[113,20,6,0],
[113,20,6,1],
[113,20,6,2],
[113,20,6,4],
[113,20,7,0],
[113,20,7,1],
[113,20,7,2],
[113,20,7,3],
[113,20,7,4],
[113,20,7,5],
[113,20,7,6],
[113,20,8,0],
[113,20,8,1],
[113,20,8,2],
[113,20,8,4],
[113,20,9,0],
[113,20,9,1],
[113,20,9,2],
[113,20,9,3],
[113,20,9,4],
[113,20,9,5],
[113,20,11,0],
[113,20,11,1],
[113,20,11,2],
[113,20,11,3],
[113,20,11,4],
[113,20,12,0],
[113,20,12,1],
[113,20,12,2],
[113,21,4,0],
[113,21,4,1],
[113,21,4,2],
[113,21,7,0],
[113,21,7,1],
[113,21,7,2],
[113,21,7,4],
[113,21,9,0],
[113,21,9,1],
[113,21,9,2],
[113,21,9,4],
[113,21,10,0],
[113,21,10,1],
[113,21,10,2],
[113,21,11,0],
[113,21,11,1],
[113,21,11,2],
[113,22,3,0],
[113,22,3,1],
[113,22,3,2],
[113,22,4,0],
[113,22,4,1],
[113,22,4,2],
[113,22,4,3],
[113,22,5,0],
[113,22,5,1],
[113,22,5,2],
[113,22,5,4],
[113,22,6,0],
[113,22,6,1],
[113,22,6,2],
[113,22,6,4],
[113,22,7,0],
[113,22,7,1],
[113,22,7,2],
[113,22,7,3],
[113,22,7,4],
[113,22,8,0],
[113,22,8,1],
[113,22,8,2],
[113,22,9,0],
[113,22,9,1],
[113,22,9,2],
[113,23,3,0],
[113,23,3,1],
[113,23,3,2],
[113,23,4,0],
[113,23,4,1],
[113,23,4,2],
[113,23,4,3],
[113,23,5,0],
[113,23,5,1],
[113,23,5,2],
[113,23,5,4],
[113,23,6,0],
[113,23,6,1],
[113,23,6,2],
[113,23,7,0],
[113,23,7,1],
[113,23,7,2],
[113,24,3,0],
[113,24,3,1],
[113,24,3,2],
[113,24,4,0],
[113,24,4,1],
[113,24,4,2],
[113,24,4,3],
[113,24,5,0],
[113,24,5,1],
[113,24,5,2],
[113,24,5,4],
[113,24,6,0],
[113,24,6,1],
[113,24,6,2],
[113,24,7,0],
[113,24,7,1],
[113,24,7,2],
[113,25,3,0],
[113,25,3,1],
[113,25,3,2],
[113,25,4,0],
[113,25,4,1],
[113,25,4,2],
[114,4,3,0],
[114,4,3,1],
[114,4,3,2],
[114,5,4,0],
[114,5,4,1],
[114,5,4,2],
[114,6,4,0],
[114,6,4,1],
[114,6,4,2],
[114,7,3,0],
[114,7,3,1],
[114,7,3,2],
[114,7,4,0],
[114,7,4,1],
[114,7,4,2],
[114,7,5,4],
[114,7,6,0],
[114,7,6,1],
[114,7,6,2],
[114,7,6,4],
[114,8,7,0],
[114,8,7,1],
[114,8,7,2],
[114,8,7,4],
[114,9,4,3],
[114,9,5,0],
[114,9,5,1],
[114,9,5,2],
[114,9,5,4],
[114,9,6,0],
[114,9,6,1],
[114,9,6,2],
[114,9,6,4],
[114,9,7,0],
[114,9,7,1],
[114,9,7,2],
[114,9,7,3],
[114,9,7,4],
[114,9,7,5],
[114,9,7,6],
[114,9,8,0],
[114,9,8,1],
[114,9,8,2],
[114,9,8,4],
[114,9,8,7],
[114,10,3,0],
[114,10,3,1],
[114,10,3,2],
[114,10,4,0],
[114,10,4,1],
[114,10,4,2],
[114,10,4,3],
[114,10,5,0],
[114,10,5,1],
[114,10,5,2],
[114,10,5,4],
[114,10,6,0],
[114,10,6,1],
[114,10,6,2],
[114,10,6,4],
[114,10,7,0],
[114,10,7,1],
[114,10,7,2],
[114,10,7,3],
[114,10,7,4],
[114,10,7,5],
[114,10,7,6],
[114,10,8,0],
[114,10,8,1],
[114,10,8,2],
[114,10,8,4],
[114,10,8,7],
[114,10,9,0],
[114,10,9,1],
[114,10,9,2],
[114,10,9,3],
[114,10,9,4],
[114,10,9,5],
[114,10,9,6],
[114,10,9,7],
[114,10,9,8],
[114,11,3,0],
[114,11,3,1],
[114,11,3,2],
[114,11,4,0],
[114,11,4,1],
[114,11,4,2],
[114,11,4,3],
[114,11,5,0],
[114,11,5,1],
[114,11,5,2],
[114,11,5,4],
[114,11,6,0],
[114,11,6,1],
[114,11,6,2],
[114,11,6,4],
[114,11,7,0],
[114,11,7,1],
[114,11,7,2],
[114,11,7,3],
[114,11,7,4],
[114,11,7,5],
[114,11,7,6],
[114,11,8,0],
[114,11,8,1],
[114,11,8,2],
[114,11,8,4],
[114,11,8,7],
[114,11,9,0],
[114,11,9,1],
[114,11,9,2],
[114,11,9,3],
[114,11,9,4],
[114,11,9,5],
[114,11,9,6],
[114,11,9,7],
[114,11,9,8],
[114,11,10,0],
[114,11,10,1],
[114,11,10,2],
[114,11,10,3],
[114,11,10,4],
[114,11,10,5],
[114,11,10,6],
[114,11,10,7],
[114,11,10,8],
[114,11,10,9],
[114,12,3,0],
[114,12,3,1],
[114,12,3,2],
[114,12,4,0],
[114,12,4,1],
[114,12,4,2],
[114,12,4,3],
[114,12,5,0],
[114,12,5,1],
[114,12,5,2],
[114,12,5,4],
[114,12,6,0],
[114,12,6,1],
[114,12,6,2],
[114,12,6,4],
[114,12,7,0],
[114,12,7,1],
[114,12,7,2],
[114,12,7,3],
[114,12,7,4],
[114,12,7,5],
[114,12,7,6],
[114,12,8,0],
[114,12,8,1],
[114,12,8,2],
[114,12,8,4],
[114,12,8,7],
[114,12,9,0],
[114,12,9,1],
[114,12,9,2],
[114,12,9,3],
[114,12,9,4],
[114,12,9,5],
[114,12,9,6],
[114,12,9,7],
[114,12,9,8],
[114,12,10,0],
[114,12,10,1],
[114,12,10,2],
[114,12,10,3],
[114,12,10,4],
[114,12,10,5],
[114,12,10,6],
[114,12,10,7],
[114,12,10,8],
[114,12,10,9],
[114,13,3,0],
[114,13,3,1],
[114,13,3,2],
[114,13,4,0],
[114,13,4,1],
[114,13,4,2],
[114,13,4,3],
[114,13,5,0],
[114,13,5,1],
[114,13,5,2],
[114,13,5,4],
[114,13,6,0],
[114,13,6,1],
[114,13,6,2],
[114,13,6,4],
[114,13,7,0],
[114,13,7,1],
[114,13,7,2],
[114,13,7,3],
[114,13,7,4],
[114,13,7,5],
[114,13,7,6],
[114,13,8,0],
[114,13,8,1],
[114,13,8,2],
[114,13,8,4],
[114,13,8,7],
[114,13,9,0],
[114,13,9,1],
[114,13,9,2],
[114,13,9,3],
[114,13,9,4],
[114,13,9,5],
[114,13,9,6],
[114,13,9,7],
[114,13,9,8],
[114,13,10,0],
[114,13,10,1],
[114,13,10,2],
[114,13,10,3],
[114,13,10,4],
[114,13,10,5],
[114,13,10,6],
[114,13,10,7],
[114,13,10,8],
[114,13,10,9],
[114,13,11,0],
[114,13,11,1],
[114,13,11,2],
[114,13,11,3],
[114,13,11,4],
[114,13,11,5],
[114,13,11,6],
[114,13,11,7],
[114,13,11,8],
[114,13,11,9],
[114,13,11,10],
[114,13,12,0],
[114,13,12,1],
[114,13,12,2],
[114,13,12,3],
[114,13,12,4],
[114,13,12,5],
[114,13,12,6],
[114,13,12,7],
[114,13,12,8],
[114,13,12,9],
[114,13,12,10],
[114,14,3,0],
[114,14,3,1],
[114,14,3,2],
[114,14,4,0],
[114,14,4,1],
[114,14,4,2],
[114,14,4,3],
[114,14,5,0],
[114,14,5,1],
[114,14,5,2],
[114,14,5,4],
[114,14,6,0],
[114,14,6,1],
[114,14,6,2],
[114,14,6,4],
[114,14,7,0],
[114,14,7,1],
[114,14,7,2],
[114,14,7,3],
[114,14,7,4],
[114,14,7,5],
[114,14,7,6],
[114,14,8,0],
[114,14,8,1],
[114,14,8,2],
[114,14,8,4],
[114,14,8,7],
[114,14,9,0],
[114,14,9,1],
[114,14,9,2],
[114,14,9,3],
[114,14,9,4],
[114,14,9,5],
[114,14,9,6],
[114,14,9,7],
[114,14,9,8],
[114,14,10,0],
[114,14,10,1],
[114,14,10,2],
[114,14,10,3],
[114,14,10,4],
[114,14,10,5],
[114,14,10,6],
[114,14,10,7],
[114,14,10,8],
[114,14,10,9],
[114,14,11,0],
[114,14,11,1],
[114,14,11,2],
[114,14,11,3],
[114,14,11,4],
[114,14,11,5],
[114,14,11,6],
[114,14,11,7],
[114,14,11,8],
[114,14,11,9],
[114,14,11,10],
[114,14,12,0],
[114,14,12,1],
[114,14,12,2],
[114,14,12,3],
[114,14,12,4],
[114,14,12,5],
[114,14,12,6],
[114,14,12,7],
[114,14,12,8],
[114,14,12,9],
[114,15,3,0],
[114,15,3,1],
[114,15,3,2],
[114,15,4,0],
[114,15,4,1],
[114,15,4,2],
[114,15,4,3],
[114,15,5,0],
[114,15,5,1],
[114,15,5,2],
[114,15,5,4],
[114,15,6,0],
[114,15,6,1],
[114,15,6,2],
[114,15,6,4],
[114,15,7,0],
[114,15,7,1],
[114,15,7,2],
[114,15,7,3],
[114,15,7,4],
[114,15,7,5],
[114,15,7,6],
[114,15,8,0],
[114,15,8,1],
[114,15,8,2],
[114,15,8,4],
[114,15,8,7],
[114,15,10,0],
[114,15,10,1],
[114,15,10,2],
[114,15,10,3],
[114,15,10,4],
[114,15,10,5],
[114,15,10,6],
[114,15,10,7],
[114,15,10,8],
[114,15,11,0],
[114,15,11,1],
[114,15,11,2],
[114,15,11,3],
[114,15,11,4],
[114,15,11,5],
[114,15,11,6],
[114,15,11,7],
[114,15,11,8],
[114,15,12,0],
[114,15,12,1],
[114,15,12,2],
[114,15,12,3],
[114,15,12,4],
[114,15,12,5],
[114,15,12,6],
[114,15,12,7],
[114,15,13,0],
[114,15,13,1],
[114,15,13,2],
[114,15,13,3],
[114,15,13,4],
[114,15,13,5],
[114,15,13,6],
[114,15,13,7],
[114,15,14,0],
[114,15,14,1],
[114,15,14,2],
[114,15,14,3],
[114,15,14,4],
[114,15,14,5],
[114,16,3,0],
[114,16,3,1],
[114,16,3,2],
[114,16,4,0],
[114,16,4,1],
[114,16,4,2],
[114,16,4,3],
[114,16,5,0],
[114,16,5,1],
[114,16,5,2],
[114,16,5,4],
[114,16,6,0],
[114,16,6,1],
[114,16,6,2],
[114,16,6,4],
[114,16,7,0],
[114,16,7,1],
[114,16,7,2],
[114,16,7,3],
[114,16,7,4],
[114,16,7,5],
[114,16,7,6],
[114,16,8,0],
[114,16,8,1],
[114,16,8,2],
[114,16,8,4],
[114,16,8,7],
[114,16,9,0],
[114,16,9,1],
[114,16,9,2],
[114,16,9,3],
[114,16,9,4],
[114,16,9,5],
[114,16,9,6],
[114,16,9,7],
[114,16,9,8],
[114,16,10,0],
[114,16,10,1],
[114,16,10,2],
[114,16,10,3],
[114,16,10,4],
[114,16,10,5],
[114,16,10,6],
[114,16,10,7],
[114,16,11,0],
[114,16,11,1],
[114,16,11,2],
[114,16,11,3],
[114,16,11,4],
[114,16,11,5],
[114,16,11,6],
[114,16,11,7],
[114,16,12,0],
[114,16,12,1],
[114,16,12,2],
[114,16,12,3],
[114,16,12,4],
[114,16,12,5],
[114,16,13,0],
[114,16,13,1],
[114,16,13,2],
[114,16,13,3],
[114,16,13,4],
[114,16,13,5],
[114,16,14,0],
[114,16,14,1],
[114,16,14,2],
[114,16,14,3],
[114,16,14,4],
[114,16,15,0],
[114,16,15,1],
[114,16,15,2],
[114,17,3,0],
[114,17,3,1],
[114,17,3,2],
[114,17,4,0],
[114,17,4,1],
[114,17,4,2],
[114,17,4,3],
[114,17,5,0],
[114,17,5,1],
[114,17,5,2],
[114,17,5,4],
[114,17,6,0],
[114,17,6,1],
[114,17,6,2],
[114,17,6,4],
[114,17,7,0],
[114,17,7,1],
[114,17,7,2],
[114,17,7,3],
[114,17,7,4],
[114,17,7,5],
[114,17,7,6],
[114,17,8,0],
[114,17,8,1],
[114,17,8,2],
[114,17,8,4],
[114,17,8,7],
[114,17,9,0],
[114,17,9,1],
[114,17,9,2],
[114,17,9,3],
[114,17,9,4],
[114,17,9,5],
[114,17,9,6],
[114,17,9,7],
[114,17,9,8],
[114,17,10,0],
[114,17,10,1],
[114,17,10,2],
[114,17,10,3],
[114,17,10,4],
[114,17,10,5],
[114,17,10,6],
[114,17,10,7],
[114,17,11,0],
[114,17,11,1],
[114,17,11,2],
[114,17,11,3],
[114,17,11,4],
[114,17,11,5],
[114,17,11,6],
[114,17,11,7],
[114,17,12,0],
[114,17,12,1],
[114,17,12,2],
[114,17,12,3],
[114,17,12,4],
[114,17,12,5],
[114,17,13,0],
[114,17,13,1],
[114,17,13,2],
[114,17,13,3],
[114,17,13,4],
[114,17,13,5],
[114,17,14,0],
[114,17,14,1],
[114,17,14,2],
[114,17,14,3],
[114,17,14,4],
[114,17,15,0],
[114,17,15,1],
[114,17,15,2],
[114,18,3,0],
[114,18,3,1],
[114,18,3,2],
[114,18,4,0],
[114,18,4,1],
[114,18,4,2],
[114,18,4,3],
[114,18,5,0],
[114,18,5,1],
[114,18,5,2],
[114,18,5,4],
[114,18,6,0],
[114,18,6,1],
[114,18,6,2],
[114,18,6,4],
[114,18,7,0],
[114,18,7,1],
[114,18,7,2],
[114,18,7,3],
[114,18,7,4],
[114,18,7,5],
[114,18,7,6],
[114,18,8,0],
[114,18,8,1],
[114,18,8,2],
[114,18,8,4],
[114,18,8,7],
[114,18,10,0],
[114,18,10,1],
[114,18,10,2],
[114,18,10,3],
[114,18,10,4],
[114,18,10,5],
[114,18,11,0],
[114,18,11,1],
[114,18,11,2],
[114,18,11,3],
[114,18,11,4],
[114,18,11,5],
[114,18,12,0],
[114,18,12,1],
[114,18,12,2],
[114,18,12,3],
[114,18,12,4],
[114,18,13,0],
[114,18,13,1],
[114,18,13,2],
[114,18,13,3],
[114,18,13,4],
[114,18,14,0],
[114,18,14,1],
[114,18,14,2],
[114,19,3,0],
[114,19,3,1],
[114,19,3,2],
[114,19,4,0],
[114,19,4,1],
[114,19,4,2],
[114,19,4,3],
[114,19,5,0],
[114,19,5,1],
[114,19,5,2],
[114,19,5,4],
[114,19,6,0],
[114,19,6,1],
[114,19,6,2],
[114,19,6,4],
[114,19,7,0],
[114,19,7,1],
[114,19,7,2],
[114,19,7,3],
[114,19,7,4],
[114,19,7,5],
[114,19,7,6],
[114,19,8,0],
[114,19,8,1],
[114,19,8,2],
[114,19,8,4],
[114,19,9,0],
[114,19,9,1],
[114,19,9,2],
[114,19,9,3],
[114,19,9,4],
[114,19,9,5],
[114,19,10,0],
[114,19,10,1],
[114,19,10,2],
[114,19,10,3],
[114,19,10,4],
[114,19,11,0],
[114,19,11,1],
[114,19,11,2],
[114,19,11,3],
[114,19,11,4],
[114,19,12,0],
[114,19,12,1],
[114,19,12,2],
[114,20,3,0],
[114,20,3,1],
[114,20,3,2],
[114,20,4,0],
[114,20,4,1],
[114,20,4,2],
[114,20,4,3],
[114,20,5,0],
[114,20,5,1],
[114,20,5,2],
[114,20,5,4],
[114,20,6,0],
[114,20,6,1],
[114,20,6,2],
[114,20,6,4],
[114,20,7,0],
[114,20,7,1],
[114,20,7,2],
[114,20,7,3],
[114,20,7,4],
[114,20,7,5],
[114,20,8,0],
[114,20,8,1],
[114,20,8,2],
[114,20,8,4],
[114,20,9,0],
[114,20,9,1],
[114,20,9,2],
[114,20,9,3],
[114,20,9,4],
[114,20,11,0],
[114,20,11,1],
[114,20,11,2],
[114,21,4,0],
[114,21,4,1],
[114,21,4,2],
[114,21,7,0],
[114,21,7,1],
[114,21,7,2],
[114,21,7,4],
[114,21,9,0],
[114,21,9,1],
[114,21,9,2],
[114,22,3,0],
[114,22,3,1],
[114,22,3,2],
[114,22,4,0],
[114,22,4,1],
[114,22,4,2],
[114,22,4,3],
[114,22,5,0],
[114,22,5,1],
[114,22,5,2],
[114,22,5,4],
[114,22,6,0],
[114,22,6,1],
[114,22,6,2],
[114,22,7,0],
[114,22,7,1],
[114,22,7,2],
[114,24,3,0],
[114,24,3,1],
[114,24,3,2],
[114,24,4,0],
[114,24,4,1],
[114,24,4,2],
[114,24,4,3],
[114,24,5,0],
[114,24,5,1],
[114,24,5,2],
[115,4,3,0],
[115,4,3,1],
[115,4,3,2],
[115,5,4,0],
[115,5,4,1],
[115,5,4,2],
[115,7,4,3],
[115,7,5,0],
[115,7,5,1],
[115,7,5,2],
[115,7,5,4],
[115,7,6,0],
[115,7,6,1],
[115,7,6,2],
[115,7,6,4],
[115,8,4,0],
[115,8,4,1],
[115,8,4,2],
[115,8,7,0],
[115,8,7,1],
[115,8,7,2],
[115,8,7,4],
[115,10,3,0],
[115,10,3,1],
[115,10,3,2],
[115,10,4,0],
[115,10,4,1],
[115,10,4,2],
[115,10,4,3],
[115,10,5,0],
[115,10,5,1],
[115,10,5,2],
[115,10,5,4],
[115,10,6,0],
[115,10,6,1],
[115,10,6,2],
[115,10,6,4],
[115,10,7,0],
[115,10,7,1],
[115,10,7,2],
[115,10,7,3],
[115,10,7,4],
[115,10,7,5],
[115,10,7,6],
[115,10,8,0],
[115,10,8,1],
[115,10,8,2],
[115,10,8,4],
[115,10,8,7],
[115,11,3,0],
[115,11,3,1],
[115,11,3,2],
[115,11,4,0],
[115,11,4,1],
[115,11,4,2],
[115,11,4,3],
[115,11,5,0],
[115,11,5,1],
[115,11,5,2],
[115,11,5,4],
[115,11,6,0],
[115,11,6,1],
[115,11,6,2],
[115,11,6,4],
[115,11,7,0],
[115,11,7,1],
[115,11,7,2],
[115,11,7,3],
[115,11,7,4],
[115,11,7,5],
[115,11,7,6],
[115,11,8,0],
[115,11,8,1],
[115,11,8,2],
[115,11,8,4],
[115,11,8,7],
[115,11,10,0],
[115,11,10,1],
[115,11,10,2],
[115,11,10,3],
[115,11,10,4],
[115,11,10,5],
[115,11,10,6],
[115,11,10,7],
[115,11,10,8],
[115,12,3,0],
[115,12,3,1],
[115,12,3,2],
[115,12,4,0],
[115,12,4,1],
[115,12,4,2],
[115,12,4,3],
[115,12,5,0],
[115,12,5,1],
[115,12,5,2],
[115,12,5,4],
[115,12,6,0],
[115,12,6,1],
[115,12,6,2],
[115,12,6,4],
[115,12,7,0],
[115,12,7,1],
[115,12,7,2],
[115,12,7,3],
[115,12,7,4],
[115,12,7,5],
[115,12,7,6],
[115,12,8,0],
[115,12,8,1],
[115,12,8,2],
[115,12,8,4],
[115,12,8,7],
[115,12,10,0],
[115,12,10,1],
[115,12,10,2],
[115,12,10,3],
[115,12,10,4],
[115,12,10,5],
[115,12,10,6],
[115,12,10,7],
[115,12,10,8],
[115,13,3,0],
[115,13,3,1],
[115,13,3,2],
[115,13,4,0],
[115,13,4,1],
[115,13,4,2],
[115,13,4,3],
[115,13,5,0],
[115,13,5,1],
[115,13,5,2],
[115,13,5,4],
[115,13,6,0],
[115,13,6,1],
[115,13,6,2],
[115,13,6,4],
[115,13,7,0],
[115,13,7,1],
[115,13,7,2],
[115,13,7,3],
[115,13,7,4],
[115,13,7,5],
[115,13,7,6],
[115,13,8,0],
[115,13,8,1],
[115,13,8,2],
[115,13,8,4],
[115,13,8,7],
[115,13,10,0],
[115,13,10,1],
[115,13,10,2],
[115,13,10,3],
[115,13,10,4],
[115,13,10,5],
[115,13,10,6],
[115,13,10,7],
[115,13,10,8],
[115,13,11,0],
[115,13,11,1],
[115,13,11,2],
[115,13,11,3],
[115,13,11,4],
[115,13,11,5],
[115,13,11,6],
[115,13,11,7],
[115,13,11,8],
[115,13,11,10],
[115,13,12,0],
[115,13,12,1],
[115,13,12,2],
[115,13,12,3],
[115,13,12,4],
[115,13,12,5],
[115,13,12,6],
[115,13,12,7],
[115,13,12,8],
[115,14,3,0],
[115,14,3,1],
[115,14,3,2],
[115,14,4,0],
[115,14,4,1],
[115,14,4,2],
[115,14,4,3],
[115,14,5,0],
[115,14,5,1],
[115,14,5,2],
[115,14,5,4],
[115,14,6,0],
[115,14,6,1],
[115,14,6,2],
[115,14,6,4],
[115,14,7,0],
[115,14,7,1],
[115,14,7,2],
[115,14,7,3],
[115,14,7,4],
[115,14,7,5],
[115,14,7,6],
[115,14,8,0],
[115,14,8,1],
[115,14,8,2],
[115,14,8,4],
[115,14,8,7],
[115,14,10,0],
[115,14,10,1],
[115,14,10,2],
[115,14,10,3],
[115,14,10,4],
[115,14,10,5],
[115,14,10,6],
[115,14,10,7],
[115,14,10,8],
[115,14,11,0],
[115,14,11,1],
[115,14,11,2],
[115,14,11,3],
[115,14,11,4],
[115,14,11,5],
[115,14,11,6],
[115,14,11,7],
[115,14,11,8],
[115,14,12,0],
[115,14,12,1],
[115,14,12,2],
[115,14,12,3],
[115,14,12,4],
[115,14,12,5],
[115,14,12,6],
[115,14,12,7],
[115,16,3,0],
[115,16,3,1],
[115,16,3,2],
[115,16,4,0],
[115,16,4,1],
[115,16,4,2],
[115,16,4,3],
[115,16,5,0],
[115,16,5,1],
[115,16,5,2],
[115,16,5,4],
[115,16,6,0],
[115,16,6,1],
[115,16,6,2],
[115,16,6,4],
[115,16,7,0],
[115,16,7,1],
[115,16,7,2],
[115,16,7,3],
[115,16,7,4],
[115,16,7,5],
[115,16,7,6],
[115,16,8,0],
[115,16,8,1],
[115,16,8,2],
[115,16,8,4],
[115,16,8,7],
[115,16,10,0],
[115,16,10,1],
[115,16,10,2],
[115,16,10,3],
[115,16,10,4],
[115,16,10,5],
[115,16,11,0],
[115,16,11,1],
[115,16,11,2],
[115,16,11,3],
[115,16,11,4],
[115,16,11,5],
[115,16,12,0],
[115,16,12,1],
[115,16,12,2],
[115,16,12,3],
[115,16,12,4],
[115,16,13,0],
[115,16,13,1],
[115,16,13,2],
[115,16,13,3],
[115,16,13,4],
[115,16,14,0],
[115,16,14,1],
[115,16,14,2],
[115,17,3,0],
[115,17,3,1],
[115,17,3,2],
[115,17,4,0],
[115,17,4,1],
[115,17,4,2],
[115,17,4,3],
[115,17,5,0],
[115,17,5,1],
[115,17,5,2],
[115,17,5,4],
[115,17,6,0],
[115,17,6,1],
[115,17,6,2],
[115,17,6,4],
[115,17,7,0],
[115,17,7,1],
[115,17,7,2],
[115,17,7,3],
[115,17,7,4],
[115,17,7,5],
[115,17,7,6],
[115,17,8,0],
[115,17,8,1],
[115,17,8,2],
[115,17,8,4],
[115,17,8,7],
[115,17,10,0],
[115,17,10,1],
[115,17,10,2],
[115,17,10,3],
[115,17,10,4],
[115,17,10,5],
[115,17,11,0],
[115,17,11,1],
[115,17,11,2],
[115,17,11,3],
[115,17,11,4],
[115,17,11,5],
[115,17,12,0],
[115,17,12,1],
[115,17,12,2],
[115,17,12,3],
[115,17,12,4],
[115,17,13,0],
[115,17,13,1],
[115,17,13,2],
[115,17,13,3],
[115,17,13,4],
[115,17,14,0],
[115,17,14,1],
[115,17,14,2],
[115,19,3,0],
[115,19,3,1],
[115,19,3,2],
[115,19,4,0],
[115,19,4,1],
[115,19,4,2],
[115,19,4,3],
[115,19,5,0],
[115,19,5,1],
[115,19,5,2],
[115,19,5,4],
[115,19,6,0],
[115,19,6,1],
[115,19,6,2],
[115,19,6,4],
[115,19,7,0],
[115,19,7,1],
[115,19,7,2],
[115,19,7,3],
[115,19,7,4],
[115,19,7,5],
[115,19,8,0],
[115,19,8,1],
[115,19,8,2],
[115,19,8,4],
[115,19,10,0],
[115,19,10,1],
[115,19,10,2],
[115,19,11,0],
[115,19,11,1],
[115,19,11,2],
[115,20,3,0],
[115,20,3,1],
[115,20,3,2],
[115,20,4,0],
[115,20,4,1],
[115,20,4,2],
[115,20,4,3],
[115,20,5,0],
[115,20,5,1],
[115,20,5,2],
[115,20,5,4],
[115,20,6,0],
[115,20,6,1],
[115,20,6,2],
[115,20,6,4],
[115,20,7,0],
[115,20,7,1],
[115,20,7,2],
[115,20,7,3],
[115,20,7,4],
[115,20,8,0],
[115,20,8,1],
[115,20,8,2],
[115,21,4,0],
[115,21,4,1],
[115,21,4,2],
[115,21,7,0],
[115,21,7,1],
[115,21,7,2],
[115,22,3,0],
[115,22,3,1],
[115,22,3,2],
[115,22,4,0],
[115,22,4,1],
[115,22,4,2],
[115,22,4,3],
[115,22,5,0],
[115,22,5,1],
[115,22,5,2],
[115,23,3,0],
[115,23,3,1],
[115,23,3,2],
[115,23,4,0],
[115,23,4,1],
[115,23,4,2],
[115,24,3,0],
[115,24,3,1],
[115,24,3,2],
[115,24,4,0],
[115,24,4,1],
[115,24,4,2],
[116,4,3,0],
[116,4,3,1],
[116,4,3,2],
[116,6,4,0],
[116,6,4,1],
[116,6,4,2],
[116,7,3,0],
[116,7,3,1],
[116,7,3,2],
[116,7,4,0],
[116,7,4,1],
[116,7,4,2],
[116,7,4,3],
[116,7,5,0],
[116,7,5,1],
[116,7,5,2],
[116,7,5,4],
[116,7,6,0],
[116,7,6,1],
[116,7,6,2],
[116,7,6,4],
[116,8,4,0],
[116,8,4,1],
[116,8,4,2],
[116,8,7,0],
[116,8,7,1],
[116,8,7,2],
[116,8,7,4],
[116,9,3,0],
[116,9,3,1],
[116,9,3,2],
[116,9,4,0],
[116,9,4,1],
[116,9,4,2],
[116,9,4,3],
[116,9,5,0],
[116,9,5,1],
[116,9,5,2],
[116,9,5,4],
[116,9,6,0],
[116,9,6,1],
[116,9,6,2],
[116,9,6,4],
[116,9,7,0],
[116,9,7,1],
[116,9,7,2],
[116,9,7,3],
[116,9,7,4],
[116,9,7,5],
[116,9,7,6],
[116,9,8,0],
[116,9,8,1],
[116,9,8,2],
[116,9,8,4],
[116,9,8,7],
[116,10,3,0],
[116,10,3,1],
[116,10,3,2],
[116,10,4,0],
[116,10,4,1],
[116,10,4,2],
[116,10,4,3],
[116,10,5,0],
[116,10,5,1],
[116,10,5,2],
[116,10,5,4],
[116,10,6,0],
[116,10,6,1],
[116,10,6,2],
[116,10,6,4],
[116,10,7,0],
[116,10,7,1],
[116,10,7,2],
[116,10,7,3],
[116,10,7,4],
[116,10,7,5],
[116,10,7,6],
[116,10,8,0],
[116,10,8,1],
[116,10,8,2],
[116,10,8,4],
[116,10,8,7],
[116,10,9,0],
[116,10,9,1],
[116,10,9,2],
[116,10,9,3],
[116,10,9,4],
[116,10,9,5],
[116,10,9,6],
[116,10,9,7],
[116,10,9,8],
[116,11,3,0],
[116,11,3,1],
[116,11,3,2],
[116,11,4,0],
[116,11,4,1],
[116,11,4,2],
[116,11,4,3],
[116,11,5,0],
[116,11,5,1],
[116,11,5,2],
[116,11,5,4],
[116,11,6,0],
[116,11,6,1],
[116,11,6,2],
[116,11,6,4],
[116,11,7,0],
[116,11,7,1],
[116,11,7,2],
[116,11,7,3],
[116,11,7,4],
[116,11,7,5],
[116,11,7,6],
[116,11,8,0],
[116,11,8,1],
[116,11,8,2],
[116,11,8,4],
[116,11,8,7],
[116,11,9,0],
[116,11,9,1],
[116,11,9,2],
[116,11,9,3],
[116,11,9,4],
[116,11,9,5],
[116,11,9,6],
[116,11,9,7],
[116,11,9,8],
[116,11,10,0],
[116,11,10,1],
[116,11,10,2],
[116,11,10,3],
[116,11,10,4],
[116,11,10,5],
[116,11,10,6],
[116,11,10,7],
[116,11,10,8],
[116,11,10,9],
[116,12,3,0],
[116,12,3,1],
[116,12,3,2],
[116,12,4,0],
[116,12,4,1],
[116,12,4,2],
[116,12,4,3],
[116,12,5,0],
[116,12,5,1],
[116,12,5,2],
[116,12,5,4],
[116,12,6,0],
[116,12,6,1],
[116,12,6,2],
[116,12,6,4],
[116,12,7,0],
[116,12,7,1],
[116,12,7,2],
[116,12,7,3],
[116,12,7,4],
[116,12,7,5],
[116,12,7,6],
[116,12,8,0],
[116,12,8,1],
[116,12,8,2],
[116,12,8,4],
[116,12,8,7],
[116,12,9,0],
[116,12,9,1],
[116,12,9,2],
[116,12,9,3],
[116,12,9,4],
[116,12,9,5],
[116,12,9,6],
[116,12,9,7],
[116,12,9,8],
[116,12,10,0],
[116,12,10,1],
[116,12,10,2],
[116,12,10,3],
[116,12,10,4],
[116,12,10,5],
[116,12,10,6],
[116,12,10,7],
[116,12,10,8],
[116,12,10,9],
[116,13,3,0],
[116,13,3,1],
[116,13,3,2],
[116,13,4,0],
[116,13,4,1],
[116,13,4,2],
[116,13,4,3],
[116,13,5,0],
[116,13,5,1],
[116,13,5,2],
[116,13,5,4],
[116,13,6,0],
[116,13,6,1],
[116,13,6,2],
[116,13,6,4],
[116,13,7,0],
[116,13,7,1],
[116,13,7,2],
[116,13,7,3],
[116,13,7,4],
[116,13,7,5],
[116,13,7,6],
[116,13,8,0],
[116,13,8,1],
[116,13,8,2],
[116,13,8,4],
[116,13,8,7],
[116,13,9,0],
[116,13,9,1],
[116,13,9,2],
[116,13,9,3],
[116,13,9,4],
[116,13,9,5],
[116,13,9,6],
[116,13,9,7],
[116,13,9,8],
[116,13,10,0],
[116,13,10,1],
[116,13,10,2],
[116,13,10,3],
[116,13,10,4],
[116,13,10,5],
[116,13,10,6],
[116,13,10,7],
[116,13,10,8],
[116,13,10,9],
[116,13,11,0],
[116,13,11,1],
[116,13,11,2],
[116,13,11,3],
[116,13,11,4],
[116,13,11,5],
[116,13,11,6],
[116,13,11,7],
[116,13,11,8],
[116,13,11,9],
[116,13,12,0],
[116,13,12,1],
[116,13,12,2],
[116,13,12,3],
[116,13,12,4],
[116,13,12,5],
[116,13,12,6],
[116,13,12,7],
[116,14,3,0],
[116,14,3,1],
[116,14,3,2],
[116,14,4,0],
[116,14,4,1],
[116,14,4,2],
[116,14,4,3],
[116,14,5,0],
[116,14,5,1],
[116,14,5,2],
[116,14,5,4],
[116,14,6,0],
[116,14,6,1],
[116,14,6,2],
[116,14,6,4],
[116,14,7,0],
[116,14,7,1],
[116,14,7,2],
[116,14,7,3],
[116,14,7,4],
[116,14,7,5],
[116,14,7,6],
[116,14,8,0],
[116,14,8,1],
[116,14,8,2],
[116,14,8,4],
[116,14,8,7],
[116,14,9,0],
[116,14,9,1],
[116,14,9,2],
[116,14,9,3],
[116,14,9,4],
[116,14,9,5],
[116,14,9,6],
[116,14,9,7],
[116,14,9,8],
[116,14,10,0],
[116,14,10,1],
[116,14,10,2],
[116,14,10,3],
[116,14,10,4],
[116,14,10,5],
[116,14,10,6],
[116,14,10,7],
[116,14,11,0],
[116,14,11,1],
[116,14,11,2],
[116,14,11,3],
[116,14,11,4],
[116,14,11,5],
[116,14,11,6],
[116,14,11,7],
[116,14,12,0],
[116,14,12,1],
[116,14,12,2],
[116,14,12,3],
[116,14,12,4],
[116,14,12,5],
[116,15,3,0],
[116,15,3,1],
[116,15,3,2],
[116,15,4,0],
[116,15,4,1],
[116,15,4,2],
[116,15,4,3],
[116,15,5,0],
[116,15,5,1],
[116,15,5,2],
[116,15,5,4],
[116,15,6,0],
[116,15,6,1],
[116,15,6,2],
[116,15,6,4],
[116,15,7,0],
[116,15,7,1],
[116,15,7,2],
[116,15,7,3],
[116,15,7,4],
[116,15,7,5],
[116,15,7,6],
[116,15,8,0],
[116,15,8,1],
[116,15,8,2],
[116,15,8,4],
[116,15,8,7],
[116,15,10,0],
[116,15,10,1],
[116,15,10,2],
[116,15,10,3],
[116,15,10,4],
[116,15,10,5],
[116,15,11,0],
[116,15,11,1],
[116,15,11,2],
[116,15,11,3],
[116,15,11,4],
[116,15,11,5],
[116,15,12,0],
[116,15,12,1],
[116,15,12,2],
[116,15,12,3],
[116,15,12,4],
[116,15,13,0],
[116,15,13,1],
[116,15,13,2],
[116,15,13,3],
[116,15,13,4],
[116,15,14,0],
[116,15,14,1],
[116,15,14,2],
[116,17,3,0],
[116,17,3,1],
[116,17,3,2],
[116,17,4,0],
[116,17,4,1],
[116,17,4,2],
[116,17,4,3],
[116,17,5,0],
[116,17,5,1],
[116,17,5,2],
[116,17,5,4],
[116,17,6,0],
[116,17,6,1],
[116,17,6,2],
[116,17,6,4],
[116,17,7,0],
[116,17,7,1],
[116,17,7,2],
[116,17,7,3],
[116,17,7,4],
[116,17,7,5],
[116,17,7,6],
[116,17,8,0],
[116,17,8,1],
[116,17,8,2],
[116,17,8,4],
[116,17,9,0],
[116,17,9,1],
[116,17,9,2],
[116,17,9,3],
[116,17,9,4],
[116,17,9,5],
[116,17,10,0],
[116,17,10,1],
[116,17,10,2],
[116,17,10,3],
[116,17,10,4],
[116,17,11,0],
[116,17,11,1],
[116,17,11,2],
[116,17,11,3],
[116,17,11,4],
[116,17,12,0],
[116,17,12,1],
[116,17,12,2],
[116,17,13,0],
[116,17,13,1],
[116,17,13,2],
[116,18,3,0],
[116,18,3,1],
[116,18,3,2],
[116,18,4,0],
[116,18,4,1],
[116,18,4,2],
[116,18,4,3],
[116,18,5,0],
[116,18,5,1],
[116,18,5,2],
[116,18,5,4],
[116,18,6,0],
[116,18,6,1],
[116,18,6,2],
[116,18,6,4],
[116,18,7,0],
[116,18,7,1],
[116,18,7,2],
[116,18,7,3],
[116,18,7,4],
[116,18,7,5],
[116,18,8,0],
[116,18,8,1],
[116,18,8,2],
[116,18,8,4],
[116,18,10,0],
[116,18,10,1],
[116,18,10,2],
[116,18,11,0],
[116,18,11,1],
[116,18,11,2],
[116,19,3,0],
[116,19,3,1],
[116,19,3,2],
[116,19,4,0],
[116,19,4,1],
[116,19,4,2],
[116,19,4,3],
[116,19,5,0],
[116,19,5,1],
[116,19,5,2],
[116,19,5,4],
[116,19,6,0],
[116,19,6,1],
[116,19,6,2],
[116,19,6,4],
[116,19,7,0],
[116,19,7,1],
[116,19,7,2],
[116,19,7,3],
[116,19,7,4],
[116,19,8,0],
[116,19,8,1],
[116,19,8,2],
[116,19,9,0],
[116,19,9,1],
[116,19,9,2],
[116,20,3,0],
[116,20,3,1],
[116,20,3,2],
[116,20,4,0],
[116,20,4,1],
[116,20,4,2],
[116,20,4,3],
[116,20,5,0],
[116,20,5,1],
[116,20,5,2],
[116,20,5,4],
[116,20,6,0],
[116,20,6,1],
[116,20,6,2],
[116,20,7,0],
[116,20,7,1],
[116,20,7,2],
[116,21,4,0],
[116,21,4,1],
[116,21,4,2],
[116,22,3,0],
[116,22,3,1],
[116,22,3,2],
[116,22,4,0],
[116,22,4,1],
[116,22,4,2],
[117,4,3,0],
[117,4,3,1],
[117,4,3,2],
[117,6,4,0],
[117,6,4,1],
[117,6,4,2],
[117,7,3,0],
[117,7,3,1],
[117,7,3,2],
[117,7,4,0],
[117,7,4,1],
[117,7,4,2],
[117,7,4,3],
[117,7,5,0],
[117,7,5,1],
[117,7,5,2],
[117,7,5,4],
[117,7,6,0],
[117,7,6,1],
[117,7,6,2],
[117,7,6,4],
[117,8,4,0],
[117,8,4,1],
[117,8,4,2],
[117,8,7,0],
[117,8,7,1],
[117,8,7,2],
[117,8,7,4],
[117,9,3,0],
[117,9,3,1],
[117,9,3,2],
[117,9,4,0],
[117,9,4,1],
[117,9,4,2],
[117,9,4,3],
[117,9,5,0],
[117,9,5,1],
[117,9,5,2],
[117,9,5,4],
[117,9,6,0],
[117,9,6,1],
[117,9,6,2],
[117,9,6,4],
[117,9,7,0],
[117,9,7,1],
[117,9,7,2],
[117,9,7,3],
[117,9,7,4],
[117,9,7,5],
[117,9,7,6],
[117,9,8,0],
[117,9,8,1],
[117,9,8,2],
[117,9,8,4],
[117,9,8,7],
[117,10,3,0],
[117,10,3,1],
[117,10,3,2],
[117,10,4,0],
[117,10,4,1],
[117,10,4,2],
[117,10,4,3],
[117,10,5,0],
[117,10,5,1],
[117,10,5,2],
[117,10,5,4],
[117,10,6,0],
[117,10,6,1],
[117,10,6,2],
[117,10,6,4],
[117,10,7,0],
[117,10,7,1],
[117,10,7,2],
[117,10,7,3],
[117,10,7,4],
[117,10,7,5],
[117,10,7,6],
[117,10,8,0],
[117,10,8,1],
[117,10,8,2],
[117,10,8,4],
[117,10,8,7],
[117,10,9,0],
[117,10,9,1],
[117,10,9,2],
[117,10,9,3],
[117,10,9,4],
[117,10,9,5],
[117,10,9,6],
[117,10,9,7],
[117,10,9,8],
[117,11,3,0],
[117,11,3,1],
[117,11,3,2],
[117,11,4,0],
[117,11,4,1],
[117,11,4,2],
[117,11,4,3],
[117,11,5,0],
[117,11,5,1],
[117,11,5,2],
[117,11,5,4],
[117,11,6,0],
[117,11,6,1],
[117,11,6,2],
[117,11,6,4],
[117,11,7,0],
[117,11,7,1],
[117,11,7,2],
[117,11,7,3],
[117,11,7,4],
[117,11,7,5],
[117,11,7,6],
[117,11,8,0],
[117,11,8,1],
[117,11,8,2],
[117,11,8,4],
[117,11,8,7],
[117,11,9,0],
[117,11,9,1],
[117,11,9,2],
[117,11,9,3],
[117,11,9,4],
[117,11,9,5],
[117,11,9,6],
[117,11,9,7],
[117,11,9,8],
[117,11,10,0],
[117,11,10,1],
[117,11,10,2],
[117,11,10,3],
[117,11,10,4],
[117,11,10,5],
[117,11,10,6],
[117,11,10,7],
[117,11,10,8],
[117,11,10,9],
[117,12,3,0],
[117,12,3,1],
[117,12,3,2],
[117,12,4,0],
[117,12,4,1],
[117,12,4,2],
[117,12,4,3],
[117,12,5,0],
[117,12,5,1],
[117,12,5,2],
[117,12,5,4],
[117,12,6,0],
[117,12,6,1],
[117,12,6,2],
[117,12,6,4],
[117,12,7,0],
[117,12,7,1],
[117,12,7,2],
[117,12,7,3],
[117,12,7,4],
[117,12,7,5],
[117,12,7,6],
[117,12,8,0],
[117,12,8,1],
[117,12,8,2],
[117,12,8,4],
[117,12,8,7],
[117,12,9,0],
[117,12,9,1],
[117,12,9,2],
[117,12,9,3],
[117,12,9,4],
[117,12,9,5],
[117,12,9,6],
[117,12,9,7],
[117,12,9,8],
[117,12,10,0],
[117,12,10,1],
[117,12,10,2],
[117,12,10,3],
[117,12,10,4],
[117,12,10,5],
[117,12,10,6],
[117,12,10,7],
[117,12,10,8],
[117,12,10,9],
[117,13,3,0],
[117,13,3,1],
[117,13,3,2],
[117,13,4,0],
[117,13,4,1],
[117,13,4,2],
[117,13,4,3],
[117,13,5,0],
[117,13,5,1],
[117,13,5,2],
[117,13,5,4],
[117,13,6,0],
[117,13,6,1],
[117,13,6,2],
[117,13,6,4],
[117,13,7,0],
[117,13,7,1],
[117,13,7,2],
[117,13,7,3],
[117,13,7,4],
[117,13,7,5],
[117,13,7,6],
[117,13,8,0],
[117,13,8,1],
[117,13,8,2],
[117,13,8,4],
[117,13,8,7],
[117,13,9,0],
[117,13,9,1],
[117,13,9,2],
[117,13,9,3],
[117,13,9,4],
[117,13,9,5],
[117,13,9,6],
[117,13,9,7],
[117,13,9,8],
[117,13,10,0],
[117,13,10,1],
[117,13,10,2],
[117,13,10,3],
[117,13,10,4],
[117,13,10,5],
[117,13,10,6],
[117,13,10,7],
[117,13,10,8],
[117,13,10,9],
[117,13,11,0],
[117,13,11,1],
[117,13,11,2],
[117,13,11,3],
[117,13,11,4],
[117,13,11,5],
[117,13,11,6],
[117,13,11,7],
[117,13,11,8],
[117,13,11,9],
[117,13,12,0],
[117,13,12,1],
[117,13,12,2],
[117,13,12,3],
[117,13,12,4],
[117,13,12,5],
[117,13,12,6],
[117,13,12,7],
[117,14,3,0],
[117,14,3,1],
[117,14,3,2],
[117,14,4,0],
[117,14,4,1],
[117,14,4,2],
[117,14,4,3],
[117,14,5,0],
[117,14,5,1],
[117,14,5,2],
[117,14,5,4],
[117,14,6,0],
[117,14,6,1],
[117,14,6,2],
[117,14,6,4],
[117,14,7,0],
[117,14,7,1],
[117,14,7,2],
[117,14,7,3],
[117,14,7,4],
[117,14,7,5],
[117,14,7,6],
[117,14,8,0],
[117,14,8,1],
[117,14,8,2],
[117,14,8,4],
[117,14,8,7],
[117,14,9,0],
[117,14,9,1],
[117,14,9,2],
[117,14,9,3],
[117,14,9,4],
[117,14,9,5],
[117,14,9,6],
[117,14,9,7],
[117,14,9,8],
[117,14,10,0],
[117,14,10,1],
[117,14,10,2],
[117,14,10,3],
[117,14,10,4],
[117,14,10,5],
[117,14,10,6],
[117,14,10,7],
[117,14,11,0],
[117,14,11,1],
[117,14,11,2],
[117,14,11,3],
[117,14,11,4],
[117,14,11,5],
[117,14,11,6],
[117,14,11,7],
[117,14,12,0],
[117,14,12,1],
[117,14,12,2],
[117,14,12,3],
[117,14,12,4],
[117,14,12,5],
[117,15,3,0],
[117,15,3,1],
[117,15,3,2],
[117,15,4,0],
[117,15,4,1],
[117,15,4,2],
[117,15,4,3],
[117,15,5,0],
[117,15,5,1],
[117,15,5,2],
[117,15,5,4],
[117,15,6,0],
[117,15,6,1],
[117,15,6,2],
[117,15,6,4],
[117,15,7,0],
[117,15,7,1],
[117,15,7,2],
[117,15,7,3],
[117,15,7,4],
[117,15,7,5],
[117,15,7,6],
[117,15,8,0],
[117,15,8,1],
[117,15,8,2],
[117,15,8,4],
[117,15,8,7],
[117,15,10,0],
[117,15,10,1],
[117,15,10,2],
[117,15,10,3],
[117,15,10,4],
[117,15,10,5],
[117,15,11,0],
[117,15,11,1],
[117,15,11,2],
[117,15,11,3],
[117,15,11,4],
[117,15,11,5],
[117,15,12,0],
[117,15,12,1],
[117,15,12,2],
[117,15,12,3],
[117,15,12,4],
[117,15,13,0],
[117,15,13,1],
[117,15,13,2],
[117,15,13,3],
[117,15,13,4],
[117,15,14,0],
[117,15,14,1],
[117,15,14,2],
[117,16,3,0],
[117,16,3,1],
[117,16,3,2],
[117,16,4,0],
[117,16,4,1],
[117,16,4,2],
[117,16,4,3],
[117,16,5,0],
[117,16,5,1],
[117,16,5,2],
[117,16,5,4],
[117,16,6,0],
[117,16,6,1],
[117,16,6,2],
[117,16,6,4],
[117,16,7,0],
[117,16,7,1],
[117,16,7,2],
[117,16,7,3],
[117,16,7,4],
[117,16,7,5],
[117,16,7,6],
[117,16,8,0],
[117,16,8,1],
[117,16,8,2],
[117,16,8,4],
[117,16,9,0],
[117,16,9,1],
[117,16,9,2],
[117,16,9,3],
[117,16,9,4],
[117,16,9,5],
[117,16,10,0],
[117,16,10,1],
[117,16,10,2],
[117,16,10,3],
[117,16,10,4],
[117,16,11,0],
[117,16,11,1],
[117,16,11,2],
[117,16,11,3],
[117,16,11,4],
[117,16,12,0],
[117,16,12,1],
[117,16,12,2],
[117,16,13,0],
[117,16,13,1],
[117,16,13,2],
[117,18,3,0],
[117,18,3,1],
[117,18,3,2],
[117,18,4,0],
[117,18,4,1],
[117,18,4,2],
[117,18,4,3],
[117,18,5,0],
[117,18,5,1],
[117,18,5,2],
[117,18,5,4],
[117,18,6,0],
[117,18,6,1],
[117,18,6,2],
[117,18,6,4],
[117,18,7,0],
[117,18,7,1],
[117,18,7,2],
[117,18,7,3],
[117,18,7,4],
[117,18,7,5],
[117,18,8,0],
[117,18,8,1],
[117,18,8,2],
[117,18,8,4],
[117,18,10,0],
[117,18,10,1],
[117,18,10,2],
[117,18,11,0],
[117,18,11,1],
[117,18,11,2],
[117,19,3,0],
[117,19,3,1],
[117,19,3,2],
[117,19,4,0],
[117,19,4,1],
[117,19,4,2],
[117,19,4,3],
[117,19,5,0],
[117,19,5,1],
[117,19,5,2],
[117,19,5,4],
[117,19,6,0],
[117,19,6,1],
[117,19,6,2],
[117,19,6,4],
[117,19,7,0],
[117,19,7,1],
[117,19,7,2],
[117,19,7,3],
[117,19,7,4],
[117,19,8,0],
[117,19,8,1],
[117,19,8,2],
[117,19,9,0],
[117,19,9,1],
[117,19,9,2],
[117,20,3,0],
[117,20,3,1],
[117,20,3,2],
[117,20,4,0],
[117,20,4,1],
[117,20,4,2],
[117,20,4,3],
[117,20,5,0],
[117,20,5,1],
[117,20,5,2],
[117,20,5,4],
[117,20,6,0],
[117,20,6,1],
[117,20,6,2],
[117,20,7,0],
[117,20,7,1],
[117,20,7,2],
[117,21,4,0],
[117,21,4,1],
[117,21,4,2],
[117,22,3,0],
[117,22,3,1],
[117,22,3,2],
[117,22,4,0],
[117,22,4,1],
[117,22,4,2],
[118,5,4,0],
[118,5,4,1],
[118,5,4,2],
[118,6,4,0],
[118,6,4,1],
[118,6,4,2],
[118,7,3,0],
[118,7,3,1],
[118,7,3,2],
[118,7,4,0],
[118,7,4,1],
[118,7,4,2],
[118,7,4,3],
[118,7,5,0],
[118,7,5,1],
[118,7,5,2],
[118,7,5,4],
[118,7,6,0],
[118,7,6,1],
[118,7,6,2],
[118,7,6,4],
[118,8,4,0],
[118,8,4,1],
[118,8,4,2],
[118,8,7,0],
[118,8,7,1],
[118,8,7,2],
[118,8,7,4],
[118,9,3,0],
[118,9,3,1],
[118,9,3,2],
[118,9,4,0],
[118,9,4,1],
[118,9,4,2],
[118,9,4,3],
[118,9,5,0],
[118,9,5,1],
[118,9,5,2],
[118,9,5,4],
[118,9,6,0],
[118,9,6,1],
[118,9,6,2],
[118,9,6,4],
[118,9,7,0],
[118,9,7,1],
[118,9,7,2],
[118,9,7,3],
[118,9,7,4],
[118,9,7,5],
[118,9,7,6],
[118,9,8,0],
[118,9,8,1],
[118,9,8,2],
[118,9,8,4],
[118,9,8,7],
[118,10,3,0],
[118,10,3,1],
[118,10,3,2],
[118,10,4,0],
[118,10,4,1],
[118,10,4,2],
[118,10,4,3],
[118,10,5,0],
[118,10,5,1],
[118,10,5,2],
[118,10,5,4],
[118,10,6,0],
[118,10,6,1],
[118,10,6,2],
[118,10,6,4],
[118,10,7,0],
[118,10,7,1],
[118,10,7,2],
[118,10,7,3],
[118,10,7,4],
[118,10,7,5],
[118,10,7,6],
[118,10,8,0],
[118,10,8,1],
[118,10,8,2],
[118,10,8,4],
[118,10,8,7],
[118,10,9,0],
[118,10,9,1],
[118,10,9,2],
[118,10,9,3],
[118,10,9,4],
[118,10,9,5],
[118,10,9,6],
[118,10,9,7],
[118,10,9,8],
[118,11,3,0],
[118,11,3,1],
[118,11,3,2],
[118,11,4,0],
[118,11,4,1],
[118,11,4,2],
[118,11,4,3],
[118,11,5,0],
[118,11,5,1],
[118,11,5,2],
[118,11,5,4],
[118,11,6,0],
[118,11,6,1],
[118,11,6,2],
[118,11,6,4],
[118,11,7,0],
[118,11,7,1],
[118,11,7,2],
[118,11,7,3],
[118,11,7,4],
[118,11,7,5],
[118,11,7,6],
[118,11,8,0],
[118,11,8,1],
[118,11,8,2],
[118,11,8,4],
[118,11,8,7],
[118,11,9,0],
[118,11,9,1],
[118,11,9,2],
[118,11,9,3],
[118,11,9,4],
[118,11,9,5],
[118,11,9,6],
[118,11,9,7],
[118,11,9,8],
[118,11,10,0],
[118,11,10,1],
[118,11,10,2],
[118,11,10,3],
[118,11,10,4],
[118,11,10,5],
[118,11,10,6],
[118,11,10,7],
[118,11,10,8],
[118,11,10,9],
[118,12,3,0],
[118,12,3,1],
[118,12,3,2],
[118,12,4,0],
[118,12,4,1],
[118,12,4,2],
[118,12,4,3],
[118,12,5,0],
[118,12,5,1],
[118,12,5,2],
[118,12,5,4],
[118,12,6,0],
[118,12,6,1],
[118,12,6,2],
[118,12,6,4],
[118,12,7,0],
[118,12,7,1],
[118,12,7,2],
[118,12,7,3],
[118,12,7,4],
[118,12,7,5],
[118,12,7,6],
[118,12,8,0],
[118,12,8,1],
[118,12,8,2],
[118,12,8,4],
[118,12,8,7],
[118,12,9,0],
[118,12,9,1],
[118,12,9,2],
[118,12,9,3],
[118,12,9,4],
[118,12,9,5],
[118,12,9,6],
[118,12,9,7],
[118,12,9,8],
[118,12,10,0],
[118,12,10,1],
[118,12,10,2],
[118,12,10,3],
[118,12,10,4],
[118,12,10,5],
[118,12,10,6],
[118,12,10,7],
[118,13,3,0],
[118,13,3,1],
[118,13,3,2],
[118,13,4,0],
[118,13,4,1],
[118,13,4,2],
[118,13,4,3],
[118,13,5,0],
[118,13,5,1],
[118,13,5,2],
[118,13,5,4],
[118,13,6,0],
[118,13,6,1],
[118,13,6,2],
[118,13,6,4],
[118,13,7,0],
[118,13,7,1],
[118,13,7,2],
[118,13,7,3],
[118,13,7,4],
[118,13,7,5],
[118,13,7,6],
[118,13,8,0],
[118,13,8,1],
[118,13,8,2],
[118,13,8,4],
[118,13,8,7],
[118,13,9,0],
[118,13,9,1],
[118,13,9,2],
[118,13,9,3],
[118,13,9,4],
[118,13,9,5],
[118,13,9,6],
[118,13,9,7],
[118,13,9,8],
[118,13,10,0],
[118,13,10,1],
[118,13,10,2],
[118,13,10,3],
[118,13,10,4],
[118,13,10,5],
[118,13,10,6],
[118,13,10,7],
[118,13,11,0],
[118,13,11,1],
[118,13,11,2],
[118,13,11,3],
[118,13,11,4],
[118,13,11,5],
[118,13,11,6],
[118,13,11,7],
[118,13,12,0],
[118,13,12,1],
[118,13,12,2],
[118,13,12,3],
[118,13,12,4],
[118,13,12,5],
[118,14,3,0],
[118,14,3,1],
[118,14,3,2],
[118,14,4,0],
[118,14,4,1],
[118,14,4,2],
[118,14,4,3],
[118,14,5,0],
[118,14,5,1],
[118,14,5,2],
[118,14,5,4],
[118,14,6,0],
[118,14,6,1],
[118,14,6,2],
[118,14,6,4],
[118,14,7,0],
[118,14,7,1],
[118,14,7,2],
[118,14,7,3],
[118,14,7,4],
[118,14,7,5],
[118,14,7,6],
[118,14,8,0],
[118,14,8,1],
[118,14,8,2],
[118,14,8,4],
[118,14,8,7],
[118,14,9,0],
[118,14,9,1],
[118,14,9,2],
[118,14,9,3],
[118,14,9,4],
[118,14,9,5],
[118,14,9,6],
[118,14,9,7],
[118,14,10,0],
[118,14,10,1],
[118,14,10,2],
[118,14,10,3],
[118,14,10,4],
[118,14,10,5],
[118,14,11,0],
[118,14,11,1],
[118,14,11,2],
[118,14,11,3],
[118,14,11,4],
[118,14,11,5],
[118,14,12,0],
[118,14,12,1],
[118,14,12,2],
[118,14,12,3],
[118,14,12,4],
[118,15,3,0],
[118,15,3,1],
[118,15,3,2],
[118,15,4,0],
[118,15,4,1],
[118,15,4,2],
[118,15,4,3],
[118,15,5,0],
[118,15,5,1],
[118,15,5,2],
[118,15,5,4],
[118,15,6,0],
[118,15,6,1],
[118,15,6,2],
[118,15,6,4],
[118,15,7,0],
[118,15,7,1],
[118,15,7,2],
[118,15,7,3],
[118,15,7,4],
[118,15,7,5],
[118,15,7,6],
[118,15,8,0],
[118,15,8,1],
[118,15,8,2],
[118,15,8,4],
[118,15,10,0],
[118,15,10,1],
[118,15,10,2],
[118,15,10,3],
[118,15,10,4],
[118,15,11,0],
[118,15,11,1],
[118,15,11,2],
[118,15,11,3],
[118,15,11,4],
[118,15,12,0],
[118,15,12,1],
[118,15,12,2],
[118,15,13,0],
[118,15,13,1],
[118,15,13,2],
[118,16,3,0],
[118,16,3,1],
[118,16,3,2],
[118,16,4,0],
[118,16,4,1],
[118,16,4,2],
[118,16,4,3],
[118,16,5,0],
[118,16,5,1],
[118,16,5,2],
[118,16,5,4],
[118,16,6,0],
[118,16,6,1],
[118,16,6,2],
[118,16,6,4],
[118,16,7,0],
[118,16,7,1],
[118,16,7,2],
[118,16,7,3],
[118,16,7,4],
[118,16,7,5],
[118,16,8,0],
[118,16,8,1],
[118,16,8,2],
[118,16,8,4],
[118,16,9,0],
[118,16,9,1],
[118,16,9,2],
[118,16,9,3],
[118,16,9,4],
[118,16,10,0],
[118,16,10,1],
[118,16,10,2],
[118,16,11,0],
[118,16,11,1],
[118,16,11,2],
[118,17,3,0],
[118,17,3,1],
[118,17,3,2],
[118,17,4,0],
[118,17,4,1],
[118,17,4,2],
[118,17,4,3],
[118,17,5,0],
[118,17,5,1],
[118,17,5,2],
[118,17,5,4],
[118,17,6,0],
[118,17,6,1],
[118,17,6,2],
[118,17,6,4],
[118,17,7,0],
[118,17,7,1],
[118,17,7,2],
[118,17,7,3],
[118,17,7,4],
[118,17,7,5],
[118,17,8,0],
[118,17,8,1],
[118,17,8,2],
[118,17,8,4],
[118,17,9,0],
[118,17,9,1],
[118,17,9,2],
[118,17,9,3],
[118,17,9,4],
[118,17,10,0],
[118,17,10,1],
[118,17,10,2],
[118,17,11,0],
[118,17,11,1],
[118,17,11,2],
[118,18,3,0],
[118,18,3,1],
[118,18,3,2],
[118,18,4,0],
[118,18,4,1],
[118,18,4,2],
[118,18,4,3],
[118,18,5,0],
[118,18,5,1],
[118,18,5,2],
[118,18,5,4],
[118,18,6,0],
[118,18,6,1],
[118,18,6,2],
[118,18,6,4],
[118,18,7,0],
[118,18,7,1],
[118,18,7,2],
[118,18,7,3],
[118,18,7,4],
[118,18,8,0],
[118,18,8,1],
[118,18,8,2],
[118,19,3,0],
[118,19,3,1],
[118,19,3,2],
[118,19,4,0],
[118,19,4,1],
[118,19,4,2],
[118,19,4,3],
[118,19,5,0],
[118,19,5,1],
[118,19,5,2],
[118,19,5,4],
[118,19,6,0],
[118,19,6,1],
[118,19,6,2],
[118,19,7,0],
[118,19,7,1],
[118,19,7,2],
[118,20,3,0],
[118,20,3,1],
[118,20,3,2],
[118,20,4,0],
[118,20,4,1],
[118,20,4,2],
[118,20,4,3],
[118,20,5,0],
[118,20,5,1],
[118,20,5,2],
[118,21,4,0],
[118,21,4,1],
[118,21,4,2],
[119,4,3,0],
[119,4,3,1],
[119,4,3,2],
[119,5,4,0],
[119,5,4,1],
[119,5,4,2],
[119,6,4,0],
[119,6,4,1],
[119,6,4,2],
[119,7,3,0],
[119,7,3,1],
[119,7,3,2],
[119,7,4,0],
[119,7,4,1],
[119,7,4,2],
[119,7,4,3],
[119,7,5,0],
[119,7,5,1],
[119,7,5,2],
[119,7,5,4],
[119,7,6,0],
[119,7,6,1],
[119,7,6,2],
[119,7,6,4],
[119,8,4,0],
[119,8,4,1],
[119,8,4,2],
[119,8,7,0],
[119,8,7,1],
[119,8,7,2],
[119,8,7,4],
[119,9,3,0],
[119,9,3,1],
[119,9,3,2],
[119,9,4,0],
[119,9,4,1],
[119,9,4,2],
[119,9,4,3],
[119,9,5,0],
[119,9,5,1],
[119,9,5,2],
[119,9,5,4],
[119,9,6,0],
[119,9,6,1],
[119,9,6,2],
[119,9,6,4],
[119,9,7,0],
[119,9,7,1],
[119,9,7,2],
[119,9,7,3],
[119,9,7,4],
[119,9,7,5],
[119,9,7,6],
[119,9,8,0],
[119,9,8,1],
[119,9,8,2],
[119,9,8,4],
[119,9,8,7],
[119,11,3,0],
[119,11,3,1],
[119,11,3,2],
[119,11,4,0],
[119,11,4,1],
[119,11,4,2],
[119,11,4,3],
[119,11,5,0],
[119,11,5,1],
[119,11,5,2],
[119,11,5,4],
[119,11,6,0],
[119,11,6,1],
[119,11,6,2],
[119,11,6,4],
[119,11,7,0],
[119,11,7,1],
[119,11,7,2],
[119,11,7,3],
[119,11,7,4],
[119,11,7,5],
[119,11,7,6],
[119,11,8,0],
[119,11,8,1],
[119,11,8,2],
[119,11,8,4],
[119,11,8,7],
[119,11,9,0],
[119,11,9,1],
[119,11,9,2],
[119,11,9,3],
[119,11,9,4],
[119,11,9,5],
[119,11,9,6],
[119,11,9,7],
[119,11,9,8],
[119,12,3,0],
[119,12,3,1],
[119,12,3,2],
[119,12,4,0],
[119,12,4,1],
[119,12,4,2],
[119,12,4,3],
[119,12,5,0],
[119,12,5,1],
[119,12,5,2],
[119,12,5,4],
[119,12,6,0],
[119,12,6,1],
[119,12,6,2],
[119,12,6,4],
[119,12,7,0],
[119,12,7,1],
[119,12,7,2],
[119,12,7,3],
[119,12,7,4],
[119,12,7,5],
[119,12,7,6],
[119,12,8,0],
[119,12,8,1],
[119,12,8,2],
[119,12,8,4],
[119,12,8,7],
[119,12,9,0],
[119,12,9,1],
[119,12,9,2],
[119,12,9,3],
[119,12,9,4],
[119,12,9,5],
[119,12,9,6],
[119,12,9,7],
[119,13,3,0],
[119,13,3,1],
[119,13,3,2],
[119,13,4,0],
[119,13,4,1],
[119,13,4,2],
[119,13,4,3],
[119,13,5,0],
[119,13,5,1],
[119,13,5,2],
[119,13,5,4],
[119,13,6,0],
[119,13,6,1],
[119,13,6,2],
[119,13,6,4],
[119,13,7,0],
[119,13,7,1],
[119,13,7,2],
[119,13,7,3],
[119,13,7,4],
[119,13,7,5],
[119,13,7,6],
[119,13,8,0],
[119,13,8,1],
[119,13,8,2],
[119,13,8,4],
[119,13,8,7],
[119,13,9,0],
[119,13,9,1],
[119,13,9,2],
[119,13,9,3],
[119,13,9,4],
[119,13,9,5],
[119,13,9,6],
[119,13,9,7],
[119,13,11,0],
[119,13,11,1],
[119,13,11,2],
[119,13,11,3],
[119,13,11,4],
[119,13,11,5],
[119,13,12,0],
[119,13,12,1],
[119,13,12,2],
[119,13,12,3],
[119,13,12,4],
[119,14,3,0],
[119,14,3,1],
[119,14,3,2],
[119,14,4,0],
[119,14,4,1],
[119,14,4,2],
[119,14,4,3],
[119,14,5,0],
[119,14,5,1],
[119,14,5,2],
[119,14,5,4],
[119,14,6,0],
[119,14,6,1],
[119,14,6,2],
[119,14,6,4],
[119,14,7,0],
[119,14,7,1],
[119,14,7,2],
[119,14,7,3],
[119,14,7,4],
[119,14,7,5],
[119,14,7,6],
[119,14,8,0],
[119,14,8,1],
[119,14,8,2],
[119,14,8,4],
[119,14,9,0],
[119,14,9,1],
[119,14,9,2],
[119,14,9,3],
[119,14,9,4],
[119,14,9,5],
[119,14,11,0],
[119,14,11,1],
[119,14,11,2],
[119,14,11,3],
[119,14,11,4],
[119,14,12,0],
[119,14,12,1],
[119,14,12,2],
[119,15,3,0],
[119,15,3,1],
[119,15,3,2],
[119,15,4,0],
[119,15,4,1],
[119,15,4,2],
[119,15,4,3],
[119,15,5,0],
[119,15,5,1],
[119,15,5,2],
[119,15,5,4],
[119,15,6,0],
[119,15,6,1],
[119,15,6,2],
[119,15,6,4],
[119,15,7,0],
[119,15,7,1],
[119,15,7,2],
[119,15,7,3],
[119,15,7,4],
[119,15,7,5],
[119,15,8,0],
[119,15,8,1],
[119,15,8,2],
[119,15,8,4],
[119,15,11,0],
[119,15,11,1],
[119,15,11,2],
[119,16,3,0],
[119,16,3,1],
[119,16,3,2],
[119,16,4,0],
[119,16,4,1],
[119,16,4,2],
[119,16,4,3],
[119,16,5,0],
[119,16,5,1],
[119,16,5,2],
[119,16,5,4],
[119,16,6,0],
[119,16,6,1],
[119,16,6,2],
[119,16,6,4],
[119,16,7,0],
[119,16,7,1],
[119,16,7,2],
[119,16,7,3],
[119,16,7,4],
[119,16,8,0],
[119,16,8,1],
[119,16,8,2],
[119,16,9,0],
[119,16,9,1],
[119,16,9,2],
[119,17,3,0],
[119,17,3,1],
[119,17,3,2],
[119,17,4,0],
[119,17,4,1],
[119,17,4,2],
[119,17,4,3],
[119,17,5,0],
[119,17,5,1],
[119,17,5,2],
[119,17,5,4],
[119,17,6,0],
[119,17,6,1],
[119,17,6,2],
[119,17,6,4],
[119,17,7,0],
[119,17,7,1],
[119,17,7,2],
[119,17,7,3],
[119,17,7,4],
[119,17,8,0],
[119,17,8,1],
[119,17,8,2],
[119,17,9,0],
[119,17,9,1],
[119,17,9,2],
[119,18,3,0],
[119,18,3,1],
[119,18,3,2],
[119,18,4,0],
[119,18,4,1],
[119,18,4,2],
[119,18,4,3],
[119,18,5,0],
[119,18,5,1],
[119,18,5,2],
[119,18,5,4],
[119,18,6,0],
[119,18,6,1],
[119,18,6,2],
[119,18,7,0],
[119,18,7,1],
[119,18,7,2],
[119,19,3,0],
[119,19,3,1],
[119,19,3,2],
[119,19,4,0],
[119,19,4,1],
[119,19,4,2],
[119,19,4,3],
[119,19,5,0],
[119,19,5,1],
[119,19,5,2],
[120,7,3,0],
[120,7,3,1],
[120,7,3,2],
[120,7,5,0],
[120,7,5,1],
[120,7,5,2],
[120,7,6,0],
[120,7,6,1],
[120,7,6,2],
[120,8,7,0],
[120,8,7,1],
[120,8,7,2],
[120,9,3,0],
[120,9,3,1],
[120,9,3,2],
[120,9,5,0],
[120,9,5,1],
[120,9,5,2],
[120,9,6,0],
[120,9,6,1],
[120,9,6,2],
[120,9,7,0],
[120,9,7,1],
[120,9,7,2],
[120,9,7,3],
[120,9,7,5],
[120,9,7,6],
[120,9,8,0],
[120,9,8,1],
[120,9,8,2],
[120,9,8,7],
[120,10,3,0],
[120,10,3,1],
[120,10,3,2],
[120,10,5,0],
[120,10,5,1],
[120,10,5,2],
[120,10,6,0],
[120,10,6,1],
[120,10,6,2],
[120,10,7,0],
[120,10,7,1],
[120,10,7,2],
[120,10,7,3],
[120,10,7,5],
[120,10,7,6],
[120,10,8,0],
[120,10,8,1],
[120,10,8,2],
[120,10,9,0],
[120,10,9,1],
[120,10,9,2],
[120,10,9,3],
[120,10,9,5],
[120,11,3,0],
[120,11,3,1],
[120,11,3,2],
[120,11,5,0],
[120,11,5,1],
[120,11,5,2],
[120,11,6,0],
[120,11,6,1],
[120,11,6,2],
[120,11,7,0],
[120,11,7,1],
[120,11,7,2],
[120,11,7,3],
[120,11,7,5],
[120,11,7,6],
[120,11,8,0],
[120,11,8,1],
[120,11,8,2],
[120,11,9,0],
[120,11,9,1],
[120,11,9,2],
[120,11,9,3],
[120,11,9,5],
[120,11,10,0],
[120,11,10,1],
[120,11,10,2],
[120,11,10,3],
[120,12,3,0],
[120,12,3,1],
[120,12,3,2],
[120,12,5,0],
[120,12,5,1],
[120,12,5,2],
[120,12,6,0],
[120,12,6,1],
[120,12,6,2],
[120,12,7,0],
[120,12,7,1],
[120,12,7,2],
[120,12,7,3],
[120,12,7,5],
[120,12,8,0],
[120,12,8,1],
[120,12,8,2],
[120,12,9,0],
[120,12,9,1],
[120,12,9,2],
[120,12,9,3],
[120,12,10,0],
[120,12,10,1],
[120,12,10,2],
[120,13,3,0],
[120,13,3,1],
[120,13,3,2],
[120,13,5,0],
[120,13,5,1],
[120,13,5,2],
[120,13,6,0],
[120,13,6,1],
[120,13,6,2],
[120,13,7,0],
[120,13,7,1],
[120,13,7,2],
[120,13,7,3],
[120,13,7,5],
[120,13,8,0],
[120,13,8,1],
[120,13,8,2],
[120,13,9,0],
[120,13,9,1],
[120,13,9,2],
[120,13,9,3],
[120,13,10,0],
[120,13,10,1],
[120,13,10,2],
[120,13,11,0],
[120,13,11,1],
[120,13,11,2],
[120,14,3,0],
[120,14,3,1],
[120,14,3,2],
[120,14,5,0],
[120,14,5,1],
[120,14,5,2],
[120,14,6,0],
[120,14,6,1],
[120,14,6,2],
[120,14,7,0],
[120,14,7,1],
[120,14,7,2],
[120,14,7,3],
[120,14,8,0],
[120,14,8,1],
[120,14,8,2],
[120,14,9,0],
[120,14,9,1],
[120,14,9,2],
[120,15,3,0],
[120,15,3,1],
[120,15,3,2],
[120,15,5,0],
[120,15,5,1],
[120,15,5,2],
[120,15,6,0],
[120,15,6,1],
[120,15,6,2],
[120,15,7,0],
[120,15,7,1],
[120,15,7,2],
[120,16,3,0],
[120,16,3,1],
[120,16,3,2],
[120,16,5,0],
[120,16,5,1],
[120,16,5,2],
[120,17,3,0],
[120,17,3,1],
[120,17,3,2],
[120,17,5,0],
[120,17,5,1],
[120,17,5,2],
[120,18,3,0],
[120,18,3,1],
[120,18,3,2],
[121,4,3,0],
[121,4,3,1],
[121,4,3,2],
[121,5,4,0],
[121,5,4,1],
[121,5,4,2],
[121,6,4,0],
[121,6,4,1],
[121,6,4,2],
[121,8,4,0],
[121,8,4,1],
[121,8,4,2],
[121,9,3,0],
[121,9,3,1],
[121,9,3,2],
[121,9,4,0],
[121,9,4,1],
[121,9,4,2],
[121,9,4,3],
[121,9,5,0],
[121,9,5,1],
[121,9,5,2],
[121,9,5,4],
[121,9,6,0],
[121,9,6,1],
[121,9,6,2],
[121,9,6,4],
[121,9,8,0],
[121,9,8,1],
[121,9,8,2],
[121,9,8,4],
[121,10,3,0],
[121,10,3,1],
[121,10,3,2],
[121,10,4,0],
[121,10,4,1],
[121,10,4,2],
[121,10,4,3],
[121,10,5,0],
[121,10,5,1],
[121,10,5,2],
[121,10,5,4],
[121,10,6,0],
[121,10,6,1],
[121,10,6,2],
[121,10,6,4],
[121,10,8,0],
[121,10,8,1],
[121,10,8,2],
[121,10,8,4],
[121,10,9,0],
[121,10,9,1],
[121,10,9,2],
[121,10,9,3],
[121,10,9,4],
[121,10,9,5],
[121,11,3,0],
[121,11,3,1],
[121,11,3,2],
[121,11,4,0],
[121,11,4,1],
[121,11,4,2],
[121,11,4,3],
[121,11,5,0],
[121,11,5,1],
[121,11,5,2],
[121,11,5,4],
[121,11,6,0],
[121,11,6,1],
[121,11,6,2],
[121,11,6,4],
[121,11,8,0],
[121,11,8,1],
[121,11,8,2],
[121,11,8,4],
[121,11,9,0],
[121,11,9,1],
[121,11,9,2],
[121,11,9,3],
[121,11,9,4],
[121,11,9,5],
[121,11,10,0],
[121,11,10,1],
[121,11,10,2],
[121,11,10,3],
[121,11,10,4],
[121,12,3,0],
[121,12,3,1],
[121,12,3,2],
[121,12,4,0],
[121,12,4,1],
[121,12,4,2],
[121,12,4,3],
[121,12,5,0],
[121,12,5,1],
[121,12,5,2],
[121,12,5,4],
[121,12,6,0],
[121,12,6,1],
[121,12,6,2],
[121,12,6,4],
[121,12,8,0],
[121,12,8,1],
[121,12,8,2],
[121,12,8,4],
[121,12,9,0],
[121,12,9,1],
[121,12,9,2],
[121,12,9,3],
[121,12,9,4],
[121,12,10,0],
[121,12,10,1],
[121,12,10,2],
[121,13,3,0],
[121,13,3,1],
[121,13,3,2],
[121,13,4,0],
[121,13,4,1],
[121,13,4,2],
[121,13,4,3],
[121,13,5,0],
[121,13,5,1],
[121,13,5,2],
[121,13,5,4],
[121,13,6,0],
[121,13,6,1],
[121,13,6,2],
[121,13,6,4],
[121,13,8,0],
[121,13,8,1],
[121,13,8,2],
[121,13,8,4],
[121,13,9,0],
[121,13,9,1],
[121,13,9,2],
[121,13,9,3],
[121,13,9,4],
[121,13,10,0],
[121,13,10,1],
[121,13,10,2],
[121,13,11,0],
[121,13,11,1],
[121,13,11,2],
[121,14,3,0],
[121,14,3,1],
[121,14,3,2],
[121,14,4,0],
[121,14,4,1],
[121,14,4,2],
[121,14,4,3],
[121,14,5,0],
[121,14,5,1],
[121,14,5,2],
[121,14,5,4],
[121,14,6,0],
[121,14,6,1],
[121,14,6,2],
[121,14,6,4],
[121,14,8,0],
[121,14,8,1],
[121,14,8,2],
[121,14,9,0],
[121,14,9,1],
[121,14,9,2],
[121,15,3,0],
[121,15,3,1],
[121,15,3,2],
[121,15,4,0],
[121,15,4,1],
[121,15,4,2],
[121,15,4,3],
[121,15,5,0],
[121,15,5,1],
[121,15,5,2],
[121,15,5,4],
[121,15,6,0],
[121,15,6,1],
[121,15,6,2],
[121,16,3,0],
[121,16,3,1],
[121,16,3,2],
[121,16,4,0],
[121,16,4,1],
[121,16,4,2],
[121,16,4,3],
[121,16,5,0],
[121,16,5,1],
[121,16,5,2],
[121,17,3,0],
[121,17,3,1],
[121,17,3,2],
[121,17,4,0],
[121,17,4,1],
[121,17,4,2],
[121,17,4,3],
[121,17,5,0],
[121,17,5,1],
[121,17,5,2],
[121,18,3,0],
[121,18,3,1],
[121,18,3,2],
[121,18,4,0],
[121,18,4,1],
[121,18,4,2],
[122,4,3,0],
[122,4,3,1],
[122,4,3,2],
[122,5,4,0],
[122,5,4,1],
[122,5,4,2],
[122,6,4,0],
[122,6,4,1],
[122,6,4,2],
[122,7,3,0],
[122,7,3,1],
[122,7,3,2],
[122,7,4,0],
[122,7,4,1],
[122,7,4,2],
[122,7,4,3],
[122,7,5,0],
[122,7,5,1],
[122,7,5,2],
[122,7,5,4],
[122,7,6,0],
[122,7,6,1],
[122,7,6,2],
[122,7,6,4],
[122,8,4,0],
[122,8,4,1],
[122,8,4,2],
[122,8,7,0],
[122,8,7,1],
[122,8,7,2],
[122,8,7,4],
[122,9,3,0],
[122,9,3,1],
[122,9,3,2],
[122,9,4,0],
[122,9,4,1],
[122,9,4,2],
[122,9,4,3],
[122,9,5,0],
[122,9,5,1],
[122,9,5,2],
[122,9,5,4],
[122,9,6,0],
[122,9,6,1],
[122,9,6,2],
[122,9,6,4],
[122,9,7,0],
[122,9,7,1],
[122,9,7,2],
[122,9,7,3],
[122,9,7,4],
[122,9,7,5],
[122,9,7,6],
[122,9,8,0],
[122,9,8,1],
[122,9,8,2],
[122,9,8,4],
[122,10,3,0],
[122,10,3,1],
[122,10,3,2],
[122,10,4,0],
[122,10,4,1],
[122,10,4,2],
[122,10,4,3],
[122,10,5,0],
[122,10,5,1],
[122,10,5,2],
[122,10,5,4],
[122,10,6,0],
[122,10,6,1],
[122,10,6,2],
[122,10,6,4],
[122,10,7,0],
[122,10,7,1],
[122,10,7,2],
[122,10,7,3],
[122,10,7,4],
[122,10,7,5],
[122,10,8,0],
[122,10,8,1],
[122,10,8,2],
[122,10,8,4],
[122,10,9,0],
[122,10,9,1],
[122,10,9,2],
[122,10,9,3],
[122,10,9,4],
[122,11,3,0],
[122,11,3,1],
[122,11,3,2],
[122,11,4,0],
[122,11,4,1],
[122,11,4,2],
[122,11,4,3],
[122,11,5,0],
[122,11,5,1],
[122,11,5,2],
[122,11,5,4],
[122,11,6,0],
[122,11,6,1],
[122,11,6,2],
[122,11,6,4],
[122,11,7,0],
[122,11,7,1],
[122,11,7,2],
[122,11,7,3],
[122,11,7,4],
[122,11,7,5],
[122,11,8,0],
[122,11,8,1],
[122,11,8,2],
[122,11,8,4],
[122,11,9,0],
[122,11,9,1],
[122,11,9,2],
[122,11,9,3],
[122,11,9,4],
[122,11,10,0],
[122,11,10,1],
[122,11,10,2],
[122,12,3,0],
[122,12,3,1],
[122,12,3,2],
[122,12,4,0],
[122,12,4,1],
[122,12,4,2],
[122,12,4,3],
[122,12,5,0],
[122,12,5,1],
[122,12,5,2],
[122,12,5,4],
[122,12,6,0],
[122,12,6,1],
[122,12,6,2],
[122,12,6,4],
[122,12,7,0],
[122,12,7,1],
[122,12,7,2],
[122,12,7,3],
[122,12,7,4],
[122,12,8,0],
[122,12,8,1],
[122,12,8,2],
[122,12,9,0],
[122,12,9,1],
[122,12,9,2],
[122,13,3,0],
[122,13,3,1],
[122,13,3,2],
[122,13,4,0],
[122,13,4,1],
[122,13,4,2],
[122,13,4,3],
[122,13,5,0],
[122,13,5,1],
[122,13,5,2],
[122,13,5,4],
[122,13,6,0],
[122,13,6,1],
[122,13,6,2],
[122,13,6,4],
[122,13,7,0],
[122,13,7,1],
[122,13,7,2],
[122,13,7,3],
[122,13,7,4],
[122,13,8,0],
[122,13,8,1],
[122,13,8,2],
[122,13,9,0],
[122,13,9,1],
[122,13,9,2],
[122,14,3,0],
[122,14,3,1],
[122,14,3,2],
[122,14,4,0],
[122,14,4,1],
[122,14,4,2],
[122,14,4,3],
[122,14,5,0],
[122,14,5,1],
[122,14,5,2],
[122,14,5,4],
[122,14,6,0],
[122,14,6,1],
[122,14,6,2],
[122,14,7,0],
[122,14,7,1],
[122,14,7,2],
[122,15,3,0],
[122,15,3,1],
[122,15,3,2],
[122,15,4,0],
[122,15,4,1],
[122,15,4,2],
[122,15,4,3],
[122,15,5,0],
[122,15,5,1],
[122,15,5,2],
[122,16,3,0],
[122,16,3,1],
[122,16,3,2],
[122,16,4,0],
[122,16,4,1],
[122,16,4,2],
[122,17,3,0],
[122,17,3,1],
[122,17,3,2],
[122,17,4,0],
[122,17,4,1],
[122,17,4,2],
[123,4,3,0],
[123,4,3,1],
[123,4,3,2],
[123,5,4,0],
[123,5,4,1],
[123,5,4,2],
[123,6,4,0],
[123,6,4,1],
[123,6,4,2],
[123,7,3,0],
[123,7,3,1],
[123,7,3,2],
[123,7,4,0],
[123,7,4,1],
[123,7,4,2],
[123,7,4,3],
[123,7,5,0],
[123,7,5,1],
[123,7,5,2],
[123,7,5,4],
[123,7,6,0],
[123,7,6,1],
[123,7,6,2],
[123,7,6,4],
[123,8,4,0],
[123,8,4,1],
[123,8,4,2],
[123,8,7,0],
[123,8,7,1],
[123,8,7,2],
[123,8,7,4],
[123,10,3,0],
[123,10,3,1],
[123,10,3,2],
[123,10,4,0],
[123,10,4,1],
[123,10,4,2],
[123,10,4,3],
[123,10,5,0],
[123,10,5,1],
[123,10,5,2],
[123,10,5,4],
[123,10,6,0],
[123,10,6,1],
[123,10,6,2],
[123,10,6,4],
[123,10,7,0],
[123,10,7,1],
[123,10,7,2],
[123,10,7,3],
[123,10,7,4],
[123,10,8,0],
[123,10,8,1],
[123,10,8,2],
[123,11,3,0],
[123,11,3,1],
[123,11,3,2],
[123,11,4,0],
[123,11,4,1],
[123,11,4,2],
[123,11,4,3],
[123,11,5,0],
[123,11,5,1],
[123,11,5,2],
[123,11,5,4],
[123,11,6,0],
[123,11,6,1],
[123,11,6,2],
[123,11,6,4],
[123,11,7,0],
[123,11,7,1],
[123,11,7,2],
[123,11,7,3],
[123,11,7,4],
[123,11,8,0],
[123,11,8,1],
[123,11,8,2],
[123,12,3,0],
[123,12,3,1],
[123,12,3,2],
[123,12,4,0],
[123,12,4,1],
[123,12,4,2],
[123,12,4,3],
[123,12,5,0],
[123,12,5,1],
[123,12,5,2],
[123,12,5,4],
[123,12,6,0],
[123,12,6,1],
[123,12,6,2],
[123,12,7,0],
[123,12,7,1],
[123,12,7,2],
[123,13,3,0],
[123,13,3,1],
[123,13,3,2],
[123,13,4,0],
[123,13,4,1],
[123,13,4,2],
[123,13,4,3],
[123,13,5,0],
[123,13,5,1],
[123,13,5,2],
[123,13,5,4],
[123,13,6,0],
[123,13,6,1],
[123,13,6,2],
[123,13,7,0],
[123,13,7,1],
[123,13,7,2],
[123,14,3,0],
[123,14,3,1],
[123,14,3,2],
[123,14,4,0],
[123,14,4,1],
[123,14,4,2],
[123,14,4,3],
[123,14,5,0],
[123,14,5,1],
[123,14,5,2],
[124,4,3,0],
[124,4,3,1],
[124,4,3,2],
[124,5,4,0],
[124,5,4,1],
[124,5,4,2],
[124,6,4,0],
[124,6,4,1],
[124,6,4,2],
[124,7,3,0],
[124,7,3,1],
[124,7,3,2],
[124,7,4,0],
[124,7,4,1],
[124,7,4,2],
[124,7,4,3],
[124,7,5,0],
[124,7,5,1],
[124,7,5,2],
[124,7,5,4],
[124,7,6,0],
[124,7,6,1],
[124,7,6,2],
[124,7,6,4],
[124,8,4,0],
[124,8,4,1],
[124,8,4,2],
[124,8,7,0],
[124,8,7,1],
[124,8,7,2],
[124,8,7,4],
[124,9,3,0],
[124,9,3,1],
[124,9,3,2],
[124,9,4,0],
[124,9,4,1],
[124,9,4,2],
[124,9,4,3],
[124,9,5,0],
[124,9,5,1],
[124,9,5,2],
[124,9,5,4],
[124,9,6,0],
[124,9,6,1],
[124,9,6,2],
[124,9,6,4],
[124,9,7,0],
[124,9,7,1],
[124,9,7,2],
[124,9,7,3],
[124,9,7,4],
[124,9,8,0],
[124,9,8,1],
[124,9,8,2],
[124,10,3,0],
[124,10,3,1],
[124,10,3,2],
[124,10,4,0],
[124,10,4,1],
[124,10,4,2],
[124,10,4,3],
[124,10,5,0],
[124,10,5,1],
[124,10,5,2],
[124,10,5,4],
[124,10,6,0],
[124,10,6,1],
[124,10,6,2],
[124,10,7,0],
[124,10,7,1],
[124,10,7,2],
[124,11,3,0],
[124,11,3,1],
[124,11,3,2],
[124,11,4,0],
[124,11,4,1],
[124,11,4,2],
[124,11,4,3],
[124,11,5,0],
[124,11,5,1],
[124,11,5,2],
[124,11,5,4],
[124,11,6,0],
[124,11,6,1],
[124,11,6,2],
[124,11,7,0],
[124,11,7,1],
[124,11,7,2],
[124,12,3,0],
[124,12,3,1],
[124,12,3,2],
[124,12,4,0],
[124,12,4,1],
[124,12,4,2],
[124,12,4,3],
[124,12,5,0],
[124,12,5,1],
[124,12,5,2],
[124,13,3,0],
[124,13,3,1],
[124,13,3,2],
[124,13,4,0],
[124,13,4,1],
[124,13,4,2],
[124,13,4,3],
[124,13,5,0],
[124,13,5,1],
[124,13,5,2],
[124,14,3,0],
[124,14,3,1],
[124,14,3,2],
[124,14,4,0],
[124,14,4,1],
[124,14,4,2],
[125,4,3,0],
[125,4,3,1],
[125,4,3,2],
[125,5,4,0],
[125,5,4,1],
[125,5,4,2],
[125,6,4,0],
[125,6,4,1],
[125,6,4,2],
[125,7,3,0],
[125,7,3,1],
[125,7,3,2],
[125,7,4,0],
[125,7,4,1],
[125,7,4,2],
[125,7,4,3],
[125,7,5,0],
[125,7,5,1],
[125,7,5,2],
[125,7,5,4],
[125,7,6,0],
[125,7,6,1],
[125,7,6,2],
[125,7,6,4],
[125,8,4,0],
[125,8,4,1],
[125,8,4,2],
[125,8,7,0],
[125,8,7,1],
[125,8,7,2],
[125,8,7,4],
[125,9,3,0],
[125,9,3,1],
[125,9,3,2],
[125,9,4,0],
[125,9,4,1],
[125,9,4,2],
[125,9,4,3],
[125,9,5,0],
[125,9,5,1],
[125,9,5,2],
[125,9,5,4],
[125,9,6,0],
[125,9,6,1],
[125,9,6,2],
[125,9,6,4],
[125,9,7,0],
[125,9,7,1],
[125,9,7,2],
[125,9,7,3],
[125,9,7,4],
[125,9,8,0],
[125,9,8,1],
[125,9,8,2],
[125,10,3,0],
[125,10,3,1],
[125,10,3,2],
[125,10,4,0],
[125,10,4,1],
[125,10,4,2],
[125,10,4,3],
[125,10,5,0],
[125,10,5,1],
[125,10,5,2],
[125,10,5,4],
[125,10,6,0],
[125,10,6,1],
[125,10,6,2],
[125,10,7,0],
[125,10,7,1],
[125,10,7,2],
[125,11,3,0],
[125,11,3,1],
[125,11,3,2],
[125,11,4,0],
[125,11,4,1],
[125,11,4,2],
[125,11,4,3],
[125,11,5,0],
[125,11,5,1],
[125,11,5,2],
[125,11,5,4],
[125,11,6,0],
[125,11,6,1],
[125,11,6,2],
[125,11,7,0],
[125,11,7,1],
[125,11,7,2],
[125,12,3,0],
[125,12,3,1],
[125,12,3,2],
[125,12,4,0],
[125,12,4,1],
[125,12,4,2],
[125,12,4,3],
[125,12,5,0],
[125,12,5,1],
[125,12,5,2],
[125,13,3,0],
[125,13,3,1],
[125,13,3,2],
[125,13,4,0],
[125,13,4,1],
[125,13,4,2],
[125,13,4,3],
[125,13,5,0],
[125,13,5,1],
[125,13,5,2],
[125,14,3,0],
[125,14,3,1],
[125,14,3,2],
[125,14,4,0],
[125,14,4,1],
[125,14,4,2],
[126,7,4,0],
[126,7,4,1],
[126,7,4,2],
[126,9,4,0],
[126,9,4,1],
[126,9,4,2],
[126,9,7,0],
[126,9,7,1],
[126,9,7,2],
[126,10,4,0],
[126,10,4,1],
[126,10,4,2],
[126,11,4,0],
[126,11,4,1],
[126,11,4,2],
[126,12,4,0],
[126,12,4,1],
[126,12,4,2],
[126,13,4,0],
[126,13,4,1],
[126,13,4,2],
[127,4,3,0],
[127,4,3,1],
[127,4,3,2],
[127,5,4,0],
[127,5,4,1],
[127,5,4,2],
[127,6,4,0],
[127,6,4,1],
[127,6,4,2],
[127,7,3,0],
[127,7,3,1],
[127,7,3,2],
[127,7,4,0],
[127,7,4,1],
[127,7,4,2],
[127,7,4,3],
[127,7,5,0],
[127,7,5,1],
[127,7,5,2],
[127,7,5,4],
[127,7,6,0],
[127,7,6,1],
[127,7,6,2],
[127,8,4,0],
[127,8,4,1],
[127,8,4,2],
[127,10,3,0],
[127,10,3,1],
[127,10,3,2],
[127,10,4,0],
[127,10,4,1],
[127,10,4,2],
[127,11,3,0],
[127,11,3,1],
[127,11,3,2],
[127,11,4,0],
[127,11,4,1],
[127,11,4,2],
[128,4,3,0],
[128,4,3,1],
[128,4,3,2],
[128,5,4,0],
[128,5,4,1],
[128,5,4,2],
[128,6,4,0],
[128,6,4,1],
[128,6,4,2],
[128,7,3,0],
[128,7,3,1],
[128,7,3,2],
[128,7,4,0],
[128,7,4,1],
[128,7,4,2],
[128,7,4,3],
[128,7,5,0],
[128,7,5,1],
[128,7,5,2],
[128,7,5,4],
[128,7,6,0],
[128,7,6,1],
[128,7,6,2],
[128,8,4,0],
[128,8,4,1],
[128,8,4,2],
[128,9,3,0],
[128,9,3,1],
[128,9,3,2],
[128,9,4,0],
[128,9,4,1],
[128,9,4,2],
[128,9,4,3],
[128,9,5,0],
[128,9,5,1],
[128,9,5,2],
[128,11,3,0],
[128,11,3,1],
[128,11,3,2],
[128,11,4,0],
[128,11,4,1],
[128,11,4,2],
[129,4,3,0],
[129,4,3,1],
[129,4,3,2],
[129,5,4,0],
[129,5,4,1],
[129,5,4,2],
[129,6,4,0],
[129,6,4,1],
[129,6,4,2],
[129,7,3,0],
[129,7,3,1],
[129,7,3,2],
[129,7,4,0],
[129,7,4,1],
[129,7,4,2],
[129,7,4,3],
[129,7,5,0],
[129,7,5,1],
[129,7,5,2],
[129,8,4,0],
[129,8,4,1],
[129,8,4,2],
[130,4,3,0],
[130,4,3,1],
[130,4,3,2],
[130,5,4,0],
[130,5,4,1],
[130,5,4,2],
[130,6,4,0],
[130,6,4,1],
[130,6,4,2],
[130,7,3,0],
[130,7,3,1],
[130,7,3,2],
[130,7,4,0],
[130,7,4,1],
[130,7,4,2],
[130,7,4,3],
[130,7,5,0],
[130,7,5,1],
[130,7,5,2],
[130,8,4,0],
[130,8,4,1],
[130,8,4,2],
[130,9,3,0],
[130,9,3,1],
[130,9,3,2],
[130,9,4,0],
[130,9,4,1],
[130,9,4,2],
[131,4,3,0],
[131,4,3,1],
[131,4,3,2],
[131,5,4,0],
[131,5,4,1],
[131,5,4,2],
[131,6,4,0],
[131,6,4,1],
[131,6,4,2],
[131,7,3,0],
[131,7,3,1],
[131,7,3,2],
[131,7,4,0],
[131,7,4,1],
[131,7,4,2],
[132,4,3,0],
[132,4,3,1],
[132,4,3,2],
[132,5,4,0],
[132,5,4,1],
[132,5,4,2],
[132,6,4,0],
[132,6,4,1],
[132,6,4,2],
[132,7,3,0],
[132,7,3,1],
[132,7,3,2],
[132,7,4,0],
[132,7,4,1],
[132,7,4,2],
[133,4,3,0],
[133,4,3,1],
[133,4,3,2],
[133,5,4,0],
[133,5,4,1],
[133,5,4,2],
[134,4,3,0],
[134,4,3,1],
[134,4,3,2],
[134,5,4,0],
[134,5,4,1],
[134,5,4,2],
[135,4,3,0],
[135,4,3,1],
[135,4,3,2],
[136,4,3,0],
[136,4,3,1],
[136,4,3,2]
]`);

export default datas;
