import { useEffect, useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faBomb,
  faExclamationTriangle,
  faFilter,
  faGem,
  faGhost,
  faSearch,
  faSpinner,
  faStar,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import './App.css';
import BorderLayout from './components/BorderLayout';

import datas from './datas';

const Bottom = () => (
  <div className="pt-3 d-flex align-items-center justify-content-center" style={{ color: 'gray', fontSize: 12, backgroundColor: '#1d6a9620' }}>
    <div className="d-flex flex-column align-items-center justify-content-center me-3 pe-3 border-secondary border-end">
      <a href="https://neko-note.org/">「それなら猫の手で」</a>
      <div className="mb-1">Copyright © 2021</div>
      <small>
        <div className="text-center mb-1">
          <a href="https://neko-note.org/privacy-policy">免責事項</a>
        </div>
      </small>
    </div>
    <div>
      <div>
        <a href="https://yugiohdb-cards.neko-note.org/">遊戯王OCG カード検索</a>
      </div>
      <div>
        <a href="https://duel-links-cards.neko-note.org/">遊戯王DL カード検索</a>
      </div>
      <div>
        <a href="https://duel-links-draw-calc.neko-note.org/">遊戯王DL ドロー確率計算機</a>
      </div>
    </div>
  </div>
);

const numbersMap = [
  { name: 'FNo.0 未来皇ホープ', num: 0, rank: 0 },
  { name: 'FNo.0 未来皇ホープ－フューチャー・スラッシュ', num: 0, rank: 0 },
  { name: 'FNo.0 未来龍皇ホープ', num: 0, rank: 0 },
  // { name: 'SNo.0 ホープ・ゼアル', num: 0, rank: 0 },
  { name: 'No.1 ゲート・オブ・ヌメロン－エーカム', num: 1, rank: 1 },
  { name: 'CNo.1 ゲート・オブ・カオス・ヌメロン－シニューニャ', num: 1, rank: 2 },
  { name: 'No.2 ゲート・オブ・ヌメロン－ドゥヴェー', num: 2, rank: 1 },
  { name: 'No.3 ゲート・オブ・ヌメロン－トゥリーニ', num: 3, rank: 1 },
  { name: 'No.3 地獄蝉王ローカスト・キング', num: 3, rank: 3 },
  { name: 'No.4 ゲート・オブ・ヌメロン－チャトゥヴァーリ', num: 4, rank: 1 },
  { name: 'No.4 猛毒刺胞ステルス・クラーゲン', num: 4, rank: 4 },
  { name: 'No.5 亡朧竜 デス・キマイラ・ドラゴン', num: 5, rank: 5 },
  { name: 'CNo.5 亡朧龍 カオス・キマイラ・ドラゴン', num: 5, rank: 6 },
  { name: 'No.6 先史遺産アトランタル', num: 6, rank: 6 },
  { name: 'CNo.6 先史遺産カオス・アトランタル', num: 6, rank: 7 },
  { name: 'No.7 ラッキー・ストライプ', num: 7, rank: 7 },
  { name: 'No.8 紋章王ゲノム・ヘリター', num: 8, rank: 4 },
  { name: 'No.9 天蓋星ダイソン・スフィア', num: 9, rank: 9 },
  { name: 'CNo.9 天蓋妖星カオス・ダイソン・スフィア', num: 9, rank: 10 },
  { name: 'No.10 白輝士イルミネーター', num: 10, rank: 4 },
  { name: 'No.11 ビッグ・アイ', num: 11, rank: 7 },
  { name: 'No.12 機甲忍者クリムゾン・シャドー', num: 12, rank: 5 },
  { name: 'No.13 ケインズ・デビル', num: 13, rank: 1 },
  { name: 'No.14 強欲のサラメーヤ', num: 14, rank: 5 },
  { name: 'No.15 ギミック・パペット－ジャイアントキラー', num: 15, rank: 8 },
  { name: 'CNo.15 ギミック・パペット－シリアルキラー', num: 15, rank: 9 },
  // { name: 'No.16 色の支配者ショック・ルーラー', num: 16, rank: 4 },
  { name: 'No.17 リバイス・ドラゴン', num: 17, rank: 3 },
  { name: 'No.18 紋章祖プレイン・コート', num: 18, rank: 4 },
  { name: 'No.19 フリーザードン', num: 19, rank: 5 },
  { name: 'No.20 蟻岩土ブリリアント', num: 20, rank: 3 },
  { name: 'No.21 氷結のレディ・ジャスティス', num: 21, rank: 6 },
  { name: 'No.22 不乱健', num: 22, rank: 8 },
  { name: 'No.23 冥界の霊騎士ランスロット', num: 23, rank: 8 },
  { name: 'No.24 竜血鬼ドラギュラス', num: 24, rank: 6 },
  { name: 'No.25 重装光学撮影機フォーカス・フォース', num: 25, rank: 6 },
  { name: 'No.26 次元孔路オクトバイパス', num: 26, rank: 3 },
  { name: 'No.27 弩級戦艦－ドレッドノイド', num: 27, rank: 4 },
  { name: 'No.28 タイタニック・モス', num: 28, rank: 7 },
  { name: 'No.29 マネキンキャット', num: 29, rank: 2 },
  { name: 'No.30 破滅のアシッド・ゴーレム', num: 30, rank: 3 },
  { name: 'No.31 アベルズ・デビル', num: 31, rank: 1 },
  { name: 'No.32 海咬龍シャーク・ドレイク', num: 32, rank: 4 },
  { name: 'CNo.32 海咬龍シャーク・ドレイク・バイス', num: 32, rank: 4 },
  { name: 'No.33 先史遺産－超兵器マシュ＝マック', num: 33, rank: 5 },
  { name: 'No.34 電算機獣テラ・バイト', num: 34, rank: 3 },
  { name: 'No.35 ラベノス・タランチュラ', num: 35, rank: 10 },
  { name: 'No.36 先史遺産－超機関フォーク＝ヒューク', num: 36, rank: 4 },
  { name: 'No.37 希望織竜スパイダー・シャーク', num: 37, rank: 4 },
  { name: 'No.38 希望魁竜タイタニック・ギャラクシー', num: 38, rank: 8 },
  { name: 'No.39 希望皇ビヨンド・ザ・ホープ', num: 39, rank: 6 },
  { name: 'No.39 希望皇ホープ', num: 39, rank: 4 },
  { name: 'No.39 希望皇ホープ・ダブル', num: 39, rank: 4 },
  { name: 'No.39 希望皇ホープ・ルーツ', num: 39, rank: 1 },
  { name: 'CNo.39 希望皇ホープレイ', num: 39, rank: 4 },
  { name: 'CNo.39 希望皇ホープレイ・ヴィクトリー', num: 39, rank: 5 },
  { name: 'CNo.39 希望皇ホープレイV', num: 39, rank: 5 },
  { name: 'SNo.39 希望皇ホープ・ザ・ライトニング', num: 39, rank: 5 },
  { name: 'SNo.39 希望皇ホープONE', num: 39, rank: 4 },
  { name: 'No.40 ギミック・パペット－ヘブンズ・ストリングス', num: 40, rank: 8 },
  { name: 'CNo.40 ギミック・パペット－デビルズ・ストリングス', num: 40, rank: 9 },
  { name: 'No.41 泥睡魔獣バグースカ', num: 41, rank: 4 },
  { name: 'No.42 スターシップ・ギャラクシー・トマホーク', num: 42, rank: 7 },
  { name: 'No.43 魂魄傀儡鬼ソウル・マリオネッター', num: 43, rank: 2 },
  { name: 'CNo.43 魂魄傀儡鬼神カオス・マリオネッター', num: 43, rank: 3 },
  { name: 'No.44 白天馬スカイ・ペガサス', num: 44, rank: 4 },
  { name: 'No.45 滅亡の予言者 クランブル・ロゴス', num: 45, rank: 2 },
  { name: 'No.46 神影龍ドラッグルーオン', num: 46, rank: 8 },
  { name: 'No.47 ナイトメア・シャーク', num: 47, rank: 3 },
  { name: 'No.48 シャドー・リッチ', num: 48, rank: 3 },
  { name: 'No.49 秘鳥フォーチュンチュン', num: 49, rank: 3 },
  { name: 'No.50 ブラック・コーン号', num: 50, rank: 4 },
  { name: 'No.51 怪腕のフィニッシュ・ホールド', num: 51, rank: 3 },
  { name: 'No.52 ダイヤモンド・クラブ・キング', num: 52, rank: 4 },
  { name: 'No.53 偽骸神 Heart－eartH', num: 53, rank: 5 },
  { name: 'No.54 反骨の闘士ライオンハート', num: 54, rank: 1 },
  { name: 'No.55 ゴゴゴゴライアス', num: 55, rank: 4 },
  { name: 'No.56 ゴールドラット', num: 56, rank: 1 },
  { name: 'No.57 奮迅竜トレスラグーン', num: 57, rank: 4 },
  { name: 'No.58 炎圧鬼バーナー・バイサー', num: 58, rank: 4 },
  { name: 'No.59 背反の料理人', num: 59, rank: 4 },
  { name: 'No.60 刻不知のデュガレス', num: 60, rank: 4 },
  { name: 'No.61 ヴォルカザウルス', num: 61, rank: 5 },
  { name: 'No.62 銀河眼の光子竜皇', num: 62, rank: 8 },
  { name: 'No.63 おしゃもじソルジャー', num: 63, rank: 1 },
  { name: 'No.64 古狸三太夫', num: 64, rank: 2 },
  { name: 'No.65 裁断魔人ジャッジ・バスター', num: 65, rank: 2 },
  { name: 'CNo.65 裁断魔王ジャッジ・デビル', num: 65, rank: 3 },
  { name: 'No.66 覇鍵甲虫マスター・キー・ビートル', num: 66, rank: 4 },
  { name: 'No.67 パラダイスマッシャー', num: 67, rank: 5 },
  { name: 'No.68 魔天牢サンダルフォン', num: 68, rank: 8 },
  { name: 'No.69 紋章神コート・オブ・アームズ', num: 69, rank: 4 },
  { name: 'CNo.69 紋章死神カオス・オブ・アームズ', num: 69, rank: 5 },
  { name: 'No.70 デッドリー・シン', num: 70, rank: 4 },
  { name: 'No.71 リバリアン・シャーク', num: 71, rank: 3 },
  { name: 'No.72 ラインモンスター チャリオッツ・飛車', num: 72, rank: 6 },
  { name: 'No.73 激瀧神アビス・スプラッシュ', num: 73, rank: 5 },
  { name: 'CNo.73 激瀧瀑神アビス・スープラ', num: 73, rank: 6 },
  { name: 'No.74 マジカル・クラウン－ミッシング・ソード', num: 74, rank: 7 },
  { name: 'No.75 惑乱のゴシップ・シャドー', num: 75, rank: 3 },
  { name: 'No.76 諧調光師グラディエール', num: 76, rank: 7 },
  { name: 'No.77 ザ・セブン・シンズ', num: 77, rank: 12 },
  { name: 'No.78 ナンバーズ・アーカイブ', num: 78, rank: 1 },
  { name: 'No.79 BK 新星のカイザー', num: 79, rank: 4 },
  { name: 'No.80 狂装覇王ラプソディ・イン・バーサーク', num: 80, rank: 4 },
  { name: 'CNo.80 葬装覇王レクイエム・イン・バーサーク', num: 80, rank: 5 },
  { name: 'No.81 超弩級砲塔列車スペリオル・ドーラ', num: 81, rank: 10 },
  { name: 'No.82 ハートランドラコ', num: 82, rank: 4 },
  { name: 'No.83 ギャラクシー・クィーン', num: 83, rank: 1 },
  { name: 'No.84 ペイン・ゲイナー', num: 84, rank: 11 },
  { name: 'No.85 クレイジー・ボックス', num: 85, rank: 4 },
  { name: 'No.86 H－C ロンゴミアント', num: 86, rank: 4 },
  { name: 'No.87 雪月花美神クイーン・オブ・ナイツ', num: 87, rank: 8 },
  { name: 'No.88 ギミック・パペット－デステニー・レオ', num: 88, rank: 8 },
  { name: 'CNo.88 ギミック・パペット－ディザスター・レオ', num: 88, rank: 9 },
  { name: 'No.89 電脳獣ディアブロシス', num: 89, rank: 7 },
  { name: 'No.90 銀河眼の光子卿', num: 90, rank: 8 },
  { name: 'No.91 サンダー・スパーク・ドラゴン', num: 91, rank: 4 },
  { name: 'No.92 偽骸神龍 Heart－eartH Dragon', num: 92, rank: 9 },
  { name: 'CNo.92 偽骸虚龍 Heart－eartH Chaos Dragon', num: 92, rank: 10 },
  { name: 'No.93 希望皇ホープ・カイザー', num: 93, rank: 12 },
  { name: 'No.94 極氷姫クリスタル・ゼロ', num: 94, rank: 5 },
  // { name: 'No.95 ギャラクシーアイズ・ダークマター・ドラゴン', num: 95, rank: 9 },
  { name: 'No.96 ブラック・ミスト', num: 96, rank: 2 },
  { name: 'CNo.96 ブラック・ストーム', num: 96, rank: 3 },
  { name: 'No.97 龍影神ドラッグラビオン', num: 97, rank: 8 },
  { name: 'No.98 絶望皇ホープレス', num: 98, rank: 4 },
  { name: 'No.99 希望皇ホープドラグナー', num: 99, rank: 12 },
  { name: 'No.99 希望皇龍ホープドラグーン', num: 99, rank: 10 },
  { name: 'No.100 ヌメロン・ドラゴン', num: 100, rank: 1 },
  { name: 'No.101 S・H・Ark Knight', num: 101, rank: 4 },
  { name: 'CNo.101 S・H・Dark Knight', num: 101, rank: 5 },
  { name: 'No.102 光天使グローリアス・ヘイロー', num: 102, rank: 4 },
  { name: 'CNo.102 光堕天使ノーブル・デーモン', num: 102, rank: 5 },
  { name: 'No.103 神葬零嬢ラグナ・ゼロ', num: 103, rank: 4 },
  { name: 'CNo.103 神葬零嬢ラグナ・インフィニティ', num: 103, rank: 5 },
  { name: 'No.104 仮面魔踏士シャイニング', num: 104, rank: 4 },
  { name: 'CNo.104 仮面魔踏士アンブラル', num: 104, rank: 5 },
  { name: 'No.105 BK 流星のセスタス', num: 105, rank: 4 },
  { name: 'CNo.105 BK 彗星のカエストス', num: 105, rank: 5 },
  { name: 'No.106 巨岩掌ジャイアント・ハンド', num: 106, rank: 4 },
  { name: 'CNo.106 溶岩掌ジャイアント・ハンド・レッド', num: 106, rank: 5 },
  { name: 'No.107 銀河眼の時空竜', num: 107, rank: 8 },
  { name: 'CNo.107 超銀河眼の時空龍', num: 107, rank: 9 },
  // { name: 'CNo.1000 夢幻虚神ヌメロニアス', num: 1000, rank: 12 },
  // { name: 'CiNo.1000 夢幻虚光神ヌメロニアス・ヌメロニア', num: 1000, rank: 13 },
];

const createEx = (p, c) => {
  if (p.length === 0) {
    return c.map(x => [x]);
  }
  return p.reduce((a, v1) => {
    const last = v1[v1.length - 1];
    Array.prototype.push.apply(a, c.filter(v2 => v2 < last).map(v2 => v1.concat(v2)));
    return a;
  }, [])
    .filter(pattern => {
      const lastRank = numbersMap[pattern[pattern.length - 1]].rank;
      return pattern.filter(x => lastRank === numbersMap[x].rank).length === 1;
    });
};

const createCombinationEx = (...arr) => {
  return arr.reduce(createEx, []);
};

const create = (p, c) => {
  if (p.length === 0) {
    return c.map(x => [x]);
  }
  return p.reduce((a, v1) => {
    const last = v1[v1.length - 1];
    Array.prototype.push.apply(a, c.filter(v2 => v2 < last).map(v2 => v1.concat(v2)));
    return a;
  }, []);
};

const createCombination = (...arr) => {
  return arr.reduce(create, []);
};

const uniq = (array) => array.filter((elem, index, self) => self.indexOf(elem) === index);

const evaillePatterns = datas;
// const evaillePatterns = createCombinationEx(...Array(4).fill([...Array(numbersMap.length)].map((x, i) => i)))
//   .filter(pattern => {
//     const sum = pattern.reduce((p, c) => p + numbersMap[c].num, 0);
//     return numbersMap.find(x => x.num === sum);
//   })

// console.log(JSON.stringify(evaillePatterns));

const SelectNumbers = (props) => {
  const {
    items,
    state,
    setState,
  } = props;

  return (
    <div className="pb-2">
      {
        items.map((x, i) => {
          if (i <= 9 || i === 112) {
            return undefined;
          }
          return (
            <div key={x.name} className="px-2 pt-1">
              <Button
                size="sm"
                variant={(state && state[i]) ? "primary" : "outline-primary"}
                style={{
                  fontSize: 12,
                  opacity: 0.96,
                  color: (state && state[i]) ? "white" : "rgb(29, 32, 72)",
                  backgroundColor: (state && state[i]) ? "rgb(29, 32, 72)" : "transparent",
                }}
                onClick={() => {
                  const a = [...state];
                  a[i] = !state[i];
                  setState(a);
                }} >
                {x.name}
              </Button>
            </div>
          );
        })
      }
    </div>
  );
};

function App() {

  const [selectedNumbers, setSelectedNumbers] = useState([...Array(numbersMap.length)].map(() => false));
  const [deckPatterns, setDeckPatterns] = useState([]);
  const [savedSelectedNumbers, setSavedSelectedNumbers] = useState([...Array(numbersMap.length)].map(() => false));
  const [loading, setLoading] = useState(false);

  const handleCalculate = () => {
    setLoading(true);
  }

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        const hopes = selectedNumbers.map((x, i) => x ? i : undefined).filter(x => x !== undefined);
        const p = hopes
          .map(hope => {
            const x = numbersMap[hope];
            return evaillePatterns
              .filter(pattern => x.num === pattern.reduce((p, c) => p + numbersMap[c].num, 0))
              .map(xx => xx.concat([hope]))
          })
          .reduce((p, c) => {
            if (p.length === 0) {
              return c;
            }
            let n = 0;
            return p.reduce((p2, c2, i) => {
              const sets = c.map(xx => uniq(c2.concat(xx)));

              const max = Math.max(...(sets.map((xx, i) => c2.length + c[i].length - xx.length)));
              if (n < max) {
                n = max;
                p2 = [];
              }

              const arr = sets.filter((xx, i) => xx.length <= c2.length + c[i].length - n);
              Array.prototype.push.apply(p2, arr);
              return p2;
            }, []);

          }, [])
          .sort((a, b) => {
            if (a.length > b.length) {
              return 1;
            }
            if (a.length < b.length) {
              return -1;
            }
            return 0;
          })
        // console.log(p.length);
        // console.log(p[0].map(x => numbersMap[x]));
        // console.log(p.map(x => x.map(xx => numbersMap[xx].num)));
        setSavedSelectedNumbers(selectedNumbers);
        setDeckPatterns(p);
        setLoading(false);
      }, 500);
    }
  }, [loading])

  const center = (
    <div className="main d-flex flex-column">
      <Container fluid className="px-0">
        <Row className="mx-0">
          <Col xs={12} className="px-0">
            <div className="ps-3 py-3 text-white d-flex align-items-center" style={{ backgroundColor: '#00838Fd0' }}>
              <b className="ms-2">② 計算ボタンを押して、結果を確認する</b>
            </div>
            <div className="py-3 d-flex flex-column justify-content-center align-items-center">
              <div className="text-secondary pb-2 px-4">
                <small>最小枚数での構成が多くあるパターンでは、計算量がふえて時間がかかる場合があります。</small>
              </div>
              <Button
                className="px-4"
                variant="primary"
                style={{
                  opacity: 0.8125,
                }}
                onClick={handleCalculate}
              >
                {
                  loading
                    ? (
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    )
                    : "計算"
                }
              </Button>
            </div>
            {
              deckPatterns.length > 0
                ? (
                  <div className="d-flex flex-column px-2">
                    <div className="align-self-center text-center">
                      <small>おそらく デッキを</small> <b className="text-dl-dark">{deckPatterns[0].length}枚</b> <small>占有します</small><br />
                      <small>ざっくり</small> <b className="text-dl-dark">{deckPatterns.length}パターン</b> <small>の構築ができます</small>
                    </div>
                    <div>
                      {
                        deckPatterns.slice(0, 100).map((x, i) => {
                          return (
                            <Card className="my-1">
                              <Card.Body>
                                <div className="d-lg-flex">
                                  <div className="flex-grow-1">
                                    {
                                      x
                                        .sort((a, b) => {
                                          if (a > b) {
                                            return -1;
                                          }
                                          if (a < b) {
                                            return 1;
                                          }
                                          return 0;
                                        })
                                        .map(xx => {
                                          const card = numbersMap[xx];
                                          return (
                                            <div className="d-flex align-items-center px-2">
                                              <Badge className="me-2" bg="secondary" style={{ marginBottom: 2, width: 48 }}>
                                                {card.num}
                                              </Badge>
                                              <div style={{ width: 48 }}>
                                                <FontAwesomeIcon className="rounded-pill me-2" icon={faStar} style={{ marginBottom: 1, padding: 2, width: 16, height: 16, fontSize: 12, color: "#ffc107", backgroundColor: "#dc3545" }} />
                                                <b style={{ color: "#dc3545" }}>{card.rank}</b>
                                              </div>
                                              <div style={{ fontSize: '66%', opacity: 0.96 }}>{card.name}</div>
                                            </div>
                                          );
                                        })
                                    }
                                  </div>
                                  <div className="d-none d-lg-block border-start"></div>
                                  <div className="d-block d-lg-none">
                                    <hr />
                                  </div>
                                  <div className="ps-2">
                                    <sup>Ex.</sup>
                                    {
                                      savedSelectedNumbers
                                        .map((x, i) => x ? i : undefined)
                                        .filter(x => x !== undefined)
                                        .map(x => numbersMap[x])
                                        .reverse()
                                        .map(n => {
                                          return (
                                            <div className="py-2 d-flex justify-content-center">
                                              <Badge bg={""} className="mx-1" style={{ width: 48, backgroundColor: "rgb(29, 32, 72)" }}>{n.num}</Badge>
                                              <small>=</small>
                                              {
                                                (createCombination(...Array(4).fill([...Array(x.length)].map((x, i) => i)))
                                                  .find(i => i.map(n => numbersMap[x[n]].num).reduce((p, c) => p + c, 0) === n.num) || [])
                                                  .map((n, i) => (
                                                    <>
                                                      <Badge className="mx-1" bg="secondary" style={{ width: 48 }}>{numbersMap[x[n]].num}</Badge>
                                                      {i !== 3 && (
                                                        <small>+</small>
                                                      )}
                                                    </>
                                                  ))
                                              }
                                            </div>
                                          );
                                        })
                                    }
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          );
                        })
                      }
                    </div>
                    {
                      deckPatterns.length > 100 && (
                        <div className="my-3 text-secondary d-flex justify-content-center">
                          <sub>※ たくさんあるので 100パターン だけ表示されています。</sub>
                        </div>
                      )
                    }
                  </div>
                )
                : (
                  <div className="p-4 d-flex justify-content-center text-secondary">構築できるパターンが見つからなかったか、まだ計算してないです✨</div>
                )
            }
          </Col>
        </Row>
      </Container>
    </div >
  );

  const right = (
    <div className="main">
      <div className="d-block d-sm-none">
        <Accordion defaultActiveKey="-1" flush>
          <Accordion.Item eventKey="0" className="border-0">
            <Accordion.Header>
              <div className="d-flex align-items-center" style={{ width: '100%' }}>
                <b className="ms-2">① 特殊召喚したいカードを選ぶ</b>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <SelectNumbers items={numbersMap} state={selectedNumbers} setState={setSelectedNumbers} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="d-none d-sm-block">
        <div className="ps-3 py-3 text-white d-flex align-items-center" style={{ backgroundColor: '#00838Fd0' }}>
          <b className="ms-2">① 特殊召喚したいカードをポチる</b>
        </div>
        <SelectNumbers items={numbersMap} state={selectedNumbers} setState={setSelectedNumbers} />
      </div>
    </div>
  );

  return (
    <div>
      <BorderLayout
        top={
          <div className="text-light bg-primary pt-4 pb-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h1>遊戯王 エヴァイユ計算機 | デッキ構築用</h1>
            </div>
            <div className="mt-3 mx-4">
              <div className="d-flex justify-content-center align-items-baseline">
                <div>ナンバーズ・エヴァイユ の利用をサポートします。</div>
              </div>
            </div>
          </div>
        }
        left={right}
        center={center}
        bottom={<Bottom />}
      />
    </div>
  );
}

export default App;
